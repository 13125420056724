// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const Keyword = ({ selectedKeyword, handleSelectChange }) => {
//   const keywordOptions = [
    // { label: 'Abm', value: 'Abm' },
    // { label: 'Absolute', value: 'Absolute' },
    // { label: 'Academic', value: 'Academic' },
    // { label: 'Acceleration', value: 'Acceleration' },
    // { label: 'Access', value: 'Access' },
    // { label: 'Accident', value: 'Accident' },
    // { label: 'Accompanist', value: 'Accompanist' },
    // { label: 'Account', value: 'Account' },
    // { label: 'Accountability', value: 'Accountability' },
    // { label: 'Accountant', value: 'Accountant' },
    // { label: 'Accountantoffice', value: 'Accountantoffice' },
    // { label: 'Accounting', value: 'Accounting' },
    // { label: 'Accounts', value: 'Accounts' },
    // { label: 'Achieving', value: 'Achieving' },
    // { label: 'Acquisition', value: 'Acquisition' },
    // { label: 'Acquisitions', value: 'Acquisitions' },
    // { label: 'Acting', value: 'Acting' },
    // { label: 'Activation', value: 'Activation' },
    // { label: 'Active', value: 'Active' },
    // { label: 'Activity', value: 'Activity' },
    // { label: 'Actor', value: 'Actor' },
    // { label: 'Actuarial', value: 'Actuarial' },
    // { label: 'Actuary', value: 'Actuary' },
    // { label: 'Acute', value: 'Acute' },
    // { label: 'Adin', value: 'Adin' },
    // { label: 'Adjunct', value: 'Adjunct' },
    // { label: 'Adjuster', value: 'Adjuster' },
    // { label: 'Administration', value: 'Administration' },
    // { label: 'Administrative', value: 'Administrative' },
    // { label: 'Administrator', value: 'Administrator' },
    // { label: 'Admissions', value: 'Admissions' },
    // { label: 'Adult', value: 'Adult' },
    // { label: 'Advanced', value: 'Advanced' },
    // { label: 'Advertisement', value: 'Advertisement' },
    // { label: 'Advertising', value: 'Advertising' },
    // { label: 'Advi', value: 'Advi' },
    // { label: 'Advisor', value: 'Advisor' },
    // { label: 'Advisory', value: 'Advisory' },
    // { label: 'Advocacy', value: 'Advocacy' },
    // { label: 'Advocate', value: 'Advocate' },
    // { label: 'Aep', value: 'Aep' },
    // { label: 'Aerospace', value: 'Aerospace' },
    // { label: 'Aetna', value: 'Aetna' },
    // { label: 'Affairs', value: 'Affairs' },
    // { label: 'Affiliate', value: 'Affiliate' },
    // { label: 'Agen', value: 'Agen' },
    // { label: 'Agency', value: 'Agency' },
    // { label: 'Agent', value: 'Agent' },
    // { label: 'Agile', value: 'Agile' },
    // { label: 'Agricultural', value: 'Agricultural' },
    // { label: 'Aide', value: 'Aide' },
    // { label: 'Air', value: 'Air' },
    // { label: 'Aircraft', value: 'Aircraft' },
    // { label: 'Ait', value: 'Ait' },
    // { label: 'Alabama', value: 'Alabama' },
    // { label: 'Allegro', value: 'Allegro' },
    // { label: 'Alliance', value: 'Alliance' },
    // { label: 'Alliances', value: 'Alliances' },
    // { label: 'Allied', value: 'Allied' },
    // { label: 'Alpa', value: 'Alpa' },
    // { label: 'Alternative', value: 'Alternative' },
    // { label: 'Alumni', value: 'Alumni' },
    // { label: 'Amazon', value: 'Amazon' },
    // { label: 'Ambassador', value: 'Ambassador' },
    // { label: 'America', value: 'America' },
    // { label: 'American', value: 'American' },
    // { label: 'Americas', value: 'Americas' },
    // { label: 'Amoa', value: 'Amoa' },
    // { label: 'An', value: 'An' },
    // { label: 'Analysis', value: 'Analysis' },
    // { label: 'Analysist', value: 'Analysist' },
    // { label: 'Analyst', value: 'Analyst' },
    // { label: 'Analytical', value: 'Analytical' },
    // { label: 'Analytics', value: 'Analytics' },
    // { label: 'And', value: 'And' },
    // { label: 'Anesthesia', value: 'Anesthesia' },
    // { label: 'Anesthesiology', value: 'Anesthesiology' },
    // { label: 'Animal', value: 'Animal' },
    // { label: 'Annuity', value: 'Annuity' },
    // { label: 'Anrp', value: 'Anrp' },
    // { label: 'Apa', value: 'Apa' },
    // { label: 'Apg', value: 'Apg' },
    // { label: 'Application', value: 'Application' },
    // { label: 'Applications', value: 'Applications' },
    // { label: 'Appraiser', value: 'Appraiser' },
    // { label: 'Approval', value: 'Approval' },
    // { label: 'Apps', value: 'Apps' },
    // { label: 'Arborist', value: 'Arborist' },
    // { label: 'Architect', value: 'Architect' },
    // { label: 'Architectural', value: 'Architectural' },
    // { label: 'Architecture', value: 'Architecture' },
    // { label: 'Architect-Workplace', value: 'Architect-Workplace' },
    // { label: 'Archival', value: 'Archival' },
    // { label: 'Archives', value: 'Archives' },
    // { label: 'Archivist', value: 'Archivist' },
    // { label: 'Area', value: 'Area' },
    // { label: 'Argos', value: 'Argos' },
    // { label: 'Armadillo', value: 'Armadillo' },
    // { label: 'Armed', value: 'Armed' },
    // { label: 'Army', value: 'Army' },
    // { label: 'Art', value: 'Art' },
    // { label: 'Artist', value: 'Artist' },
    // { label: 'As', value: 'As' },
    // { label: 'Assembly', value: 'Assembly' },
    // { label: 'Assessment', value: 'Assessment' },
    // { label: 'Asset', value: 'Asset' },
    // { label: 'Assistant', value: 'Assistant' },
    // { label: 'Assistingcoding', value: 'Assistingcoding' },
    // { label: 'Assistive', value: 'Assistive' },
    // { label: 'Associate', value: 'Associate' },
    // { label: 'Associatedirector', value: 'Associatedirector' },
    // { label: 'Association', value: 'Association' },
    // { label: 'Assurance', value: 'Assurance' },
    // { label: 'At', value: 'At' },
    // { label: 'Athletic', value: 'Athletic' },
    // { label: 'Atlantic', value: 'Atlantic' },
    // { label: 'Attorney', value: 'Attorney' },
    // { label: 'Attorneys', value: 'Attorneys' },
    // { label: 'Audi', value: 'Audi' },
    // { label: 'Audio', value: 'Audio' },
    // { label: 'Audit', value: 'Audit' },
    // { label: 'Auditor', value: 'Auditor' },
    // { label: 'Audits', value: 'Audits' },
    // { label: 'Austin', value: 'Austin' },
    // { label: 'Author', value: 'Author' },
    // { label: 'Auto', value: 'Auto' },
    // { label: 'Automation', value: 'Automation' },
    // { label: 'Automotive', value: 'Automotive' },
    // { label: 'Aviation', value: 'Aviation' },
    // { label: 'Avidwireless', value: 'Avidwireless' },
    // { label: 'Avionics', value: 'Avionics' },
    // { label: 'Avp', value: 'Avp' },
    // { label: 'Award', value: 'Award' },
    // { label: 'B', value: 'B' },
    // { label: 'Baker', value: 'Baker' },
    // { label: 'Bakery', value: 'Bakery' },
    // { label: 'Bank', value: 'Bank' },
    // { label: 'Banker', value: 'Banker' },
    // { label: 'Banking', value: 'Banking' },
    // { label: 'Baptist', value: 'Baptist' },
    // { label: 'Barista', value: 'Barista' },
    // { label: 'Bas', value: 'Bas' },
    // { label: 'Base', value: 'Base' },
    // { label: 'Based', value: 'Based' },
    // { label: 'Basis', value: 'Basis' },
    // { label: 'Bassett', value: 'Bassett' },
    // { label: 'Bde', value: 'Bde' },
    // { label: 'Beauty', value: 'Beauty' },
    // { label: 'Behavior', value: 'Behavior' },
    // { label: 'Behavioral', value: 'Behavioral' },
    // { label: 'Being', value: 'Being' },
    // { label: 'Benefit', value: 'Benefit' },
    // { label: 'Benefits', value: 'Benefits' },
    // { label: 'Big', value: 'Big' },
    // { label: 'Bill', value: 'Bill' },
    // { label: 'Billabong', value: 'Billabong' },
    // { label: 'Biller', value: 'Biller' },
    // { label: 'Billing', value: 'Billing' },
    // { label: 'Bioanalytical', value: 'Bioanalytical' },
    // { label: 'Biological', value: 'Biological' },
    // { label: 'Biology', value: 'Biology' },
    // { label: 'Biomedical', value: 'Biomedical' },
    // { label: 'Biopharma', value: 'Biopharma' },
    // { label: 'Biosafety', value: 'Biosafety' },
    // { label: 'Biospheric', value: 'Biospheric' },
    // { label: 'Biostatistics', value: 'Biostatistics' },
    // { label: 'Biotech', value: 'Biotech' },
    // { label: 'Black', value: 'Black' },
    // { label: 'Bleached', value: 'Bleached' },
    // { label: 'Blood', value: 'Blood' },
    // { label: 'Bluelab', value: 'Bluelab' },
    // { label: 'Bn', value: 'Bn' },
    // { label: 'Board', value: 'Board' },
    // { label: 'Bobbi', value: 'Bobbi' },
    // { label: 'Bodily', value: 'Bodily' },
    // { label: 'Body', value: 'Body' },
    // { label: 'Book', value: 'Book' },
    // { label: 'Bookkeeper', value: 'Bookkeeper' },
    // { label: 'Bottle', value: 'Bottle' },
    // { label: 'Bottlewasher', value: 'Bottlewasher' },
    // { label: 'Boutique', value: 'Boutique' },
    // { label: 'Bpo', value: 'Bpo' },
    // { label: 'Brain', value: 'Brain' },
    // { label: 'Branch', value: 'Branch' },
    // { label: 'Brand', value: 'Brand' },
    // { label: 'Branding', value: 'Branding' },
    // { label: 'Brands', value: 'Brands' },
    // { label: 'Brian', value: 'Brian' },
    // { label: 'Briot', value: 'Briot' },
    // { label: 'Broadcast', value: 'Broadcast' },
    // { label: 'Broadcasting', value: 'Broadcasting' },
    // { label: 'Broker', value: 'Broker' },
    // { label: 'Brown', value: 'Brown' },
    // { label: 'Bsa', value: 'Bsa' },
    // { label: 'Budget', value: 'Budget' },
    // { label: 'Build', value: 'Build' },
    // { label: 'Builder', value: 'Builder' },
    // { label: 'Building', value: 'Building' },
    // { label: 'Bureau', value: 'Bureau' },
    // { label: 'Bus', value: 'Bus' },
    // { label: 'Business', value: 'Business' },
    // { label: 'Buyer', value: 'Buyer' },
    // { label: 'Buyingproduction', value: 'Buyingproduction' },
    // { label: 'C#', value: 'C#' },
    // { label: 'Cad', value: 'Cad' },
    // { label: 'Call', value: 'Call' },
    // { label: 'Camp', value: 'Camp' },
    // { label: 'Campaign', value: 'Campaign' },
    // { label: 'Campus', value: 'Campus' },
    // { label: 'Capital', value: 'Capital' },
    // { label: 'Captain', value: 'Captain' },
    // { label: 'Cardiac', value: 'Cardiac' },
    // { label: 'Cardiology', value: 'Cardiology' },
    // { label: 'Care', value: 'Care' },
    // { label: 'Carecredit', value: 'Carecredit' },
    // { label: 'Career', value: 'Career' },
    // { label: 'Carolina', value: 'Carolina' },
    // { label: 'Carpenter', value: 'Carpenter' },
    // { label: 'Case', value: 'Case' },
    // { label: 'Cashier', value: 'Cashier' },
    // { label: 'Cass', value: 'Cass' },
    // { label: 'Cath', value: 'Cath' },
    // { label: 'Catherization', value: 'Catherization' },
    // { label: 'Catheterization', value: 'Catheterization' },
    // { label: 'Cbo', value: 'Cbo' },
    // { label: 'Ccdo', value: 'Ccdo' },
    // { label: 'Cch', value: 'Cch' },
    // { label: 'Cco', value: 'Cco' },
    // { label: 'Cdo', value: 'Cdo' },
    // { label: 'Center', value: 'Center' },
    // { label: 'Centered', value: 'Centered' },
    // { label: 'Central', value: 'Central' },
    // { label: 'Ceo/Training', value: 'Ceo/Training' },
    // { label: 'Certificate', value: 'Certificate' },
    // { label: 'Certified', value: 'Certified' },
    // { label: 'Chain', value: 'Chain' },
    // { label: 'Chair', value: 'Chair' },
    // { label: 'Chancellor', value: 'Chancellor' },
    // { label: 'Change', value: 'Change' },
    // { label: 'Channel', value: 'Channel' },
    // { label: 'Channels', value: 'Channels' },
    // { label: 'Chapter', value: 'Chapter' },
    // { label: 'Charge', value: 'Charge' },
    // { label: 'Charles', value: 'Charles' },
    // { label: 'Chef', value: 'Chef' },
    // { label: 'Chemical', value: 'Chemical' },
    // { label: 'Chemist', value: 'Chemist' },
    // { label: 'Chemistry', value: 'Chemistry' },
    // { label: 'Chemometrician', value: 'Chemometrician' },
    // { label: 'Chevrolet', value: 'Chevrolet' },
    // { label: 'China', value: 'China' },
    // { label: 'Christi', value: 'Christi' },
    // { label: 'City', value: 'City' },
    // { label: 'Civil', value: 'Civil' },
    // { label: 'Claim', value: 'Claim' },
    // { label: 'Claims', value: 'Claims' },
    // { label: 'Class', value: 'Class' },
    // { label: 'Clayton', value: 'Clayton' },
    // { label: 'Clearcase', value: 'Clearcase' },
    // { label: 'Clements', value: 'Clements' },
    // { label: 'Clerk', value: 'Clerk' },
    // { label: 'Client', value: 'Client' },
    // { label: 'Clinic', value: 'Clinic' },
    // { label: 'Clinical', value: 'Clinical' },
    // { label: 'Clinicals', value: 'Clinicals' },
    // { label: 'Clinician', value: 'Clinician' },
    // { label: 'Closer', value: 'Closer' },
    // { label: 'Cloud', value: 'Cloud' },
    // { label: 'Club', value: 'Club' },
    // { label: 'Cluster', value: 'Cluster' },
    // { label: 'Cmsd', value: 'Cmsd' },
    // { label: 'Cna', value: 'Cna' },
    // { label: 'Cno', value: 'Cno' },
    // { label: 'Co', value: 'Co' },
    // { label: 'Coach', value: 'Coach' },
    // { label: 'Coaching', value: 'Coaching' },
    // { label: 'Coast', value: 'Coast' },
    // { label: 'Code', value: 'Code' },
    // { label: 'Coder', value: 'Coder' },
    // { label: 'Coding', value: 'Coding' },
    // { label: 'Coe', value: 'Coe' },
    // { label: 'Cofounder', value: 'Cofounder' },
    // { label: 'Co-Founder', value: 'Co-Founder' },
    // { label: 'Collaborators', value: 'Collaborators' },
    // { label: 'Collections', value: 'Collections' },
    // { label: 'College', value: 'College' },
    // { label: 'Colonies', value: 'Colonies' },
    // { label: 'Com', value: 'Com' },
    // { label: 'Commercial', value: 'Commercial' },
    // { label: 'Commissioner', value: 'Commissioner' },
    // { label: 'Commissioners', value: 'Commissioners' },
    // { label: 'Committee', value: 'Committee' },
    // { label: 'Committees', value: 'Committees' },
    // { label: 'Commodity', value: 'Commodity' },
    // { label: 'Commonwealth', value: 'Commonwealth' },
    // { label: 'Communication', value: 'Communication' },
    // { label: 'Communications', value: 'Communications' },
    // { label: 'Community', value: 'Community' },
    // { label: 'Companies', value: 'Companies' },
    // { label: 'Company', value: 'Company' },
    // { label: 'Compensation', value: 'Compensation' },
    // { label: 'Complete', value: 'Complete' },
    // { label: 'Compliance', value: 'Compliance' },
    // { label: 'Component', value: 'Component' },
    // { label: 'Computer', value: 'Computer' },
    // { label: 'Concepts', value: 'Concepts' },
    // { label: 'Concert', value: 'Concert' },
    // { label: 'Conduct', value: 'Conduct' },
    // { label: 'Conductor', value: 'Conductor' },
    // { label: 'Coney', value: 'Coney' },
    // { label: 'Conference', value: 'Conference' },
    // { label: 'Configuration', value: 'Configuration' },
    // { label: 'Connection', value: 'Connection' },
    // { label: 'Connectivity', value: 'Connectivity' },
    // { label: 'Conservation', value: 'Conservation' },
    // { label: 'Construction', value: 'Construction' },
    // { label: 'Consultant', value: 'Consultant' },
    // { label: 'Consultants', value: 'Consultants' },
    // { label: 'Consulting', value: 'Consulting' },
    // { label: 'Consumable', value: 'Consumable' },
    // { label: 'Consumer', value: 'Consumer' },
    // { label: 'Content', value: 'Content' },
    // { label: 'Continuous', value: 'Continuous' },
    // { label: 'Contract', value: 'Contract' },
    // { label: 'Contracting', value: 'Contracting' },
    // { label: 'Contractor', value: 'Contractor' },
    // { label: 'Contractor-Quality', value: 'Contractor-Quality' },
    // { label: 'Contractors', value: 'Contractors' },
    // { label: 'Contracts', value: 'Contracts' },
    // { label: 'Control', value: 'Control' },
    // { label: 'Controller', value: 'Controller' },
    // { label: 'Controls', value: 'Controls' },
    // { label: 'Conversion', value: 'Conversion' },
    // { label: 'Cook', value: 'Cook' },
    // { label: 'Cookie', value: 'Cookie' },
    // { label: 'Coordinator', value: 'Coordinator' },
    // { label: 'Copc', value: 'Copc' },
    // { label: 'Copy', value: 'Copy' },
    // { label: 'Copywriter', value: 'Copywriter' },
    // { label: 'Cornerstone', value: 'Cornerstone' },
    // { label: 'Corp', value: 'Corp' },
    // { label: 'Corp)', value: 'Corp)' },
    // { label: 'Corporate', value: 'Corporate' },
    // { label: 'Correctional', value: 'Correctional' },
    // { label: 'Cost', value: 'Cost' },
    // { label: 'Council', value: 'Council' },
    // { label: 'Counsel', value: 'Counsel' },
    // { label: 'Counselor', value: 'Counselor' },
    // { label: 'Counter', value: 'Counter' },
    // { label: 'Country', value: 'Country' },
    // { label: 'County', value: 'County' },
    // { label: 'Court', value: 'Court' },
    // { label: 'Courtroom', value: 'Courtroom' },
    // { label: 'Covid', value: 'Covid' },
    // { label: 'Cpep', value: 'Cpep' },
    // { label: 'Cpl', value: 'Cpl' },
    // { label: 'Cpo', value: 'Cpo' },
    // { label: 'Cqo', value: 'Cqo' },
    // { label: 'Crawford', value: 'Crawford' },
    // { label: 'Creative', value: 'Creative' },
    // { label: 'Credit', value: 'Credit' },
    // { label: 'Crestech', value: 'Crestech' },
    // { label: 'Crew', value: 'Crew' },
    // { label: 'Critical', value: 'Critical' },
    // { label: 'Crm', value: 'Crm' },
    // { label: 'Cross', value: 'Cross' },
    // { label: 'Crs', value: 'Crs' },
    // { label: 'Cs', value: 'Cs' },
    // { label: 'Csa', value: 'Csa' },
    // { label: 'Csr', value: 'Csr' },
    // { label: 'Csv', value: 'Csv' },
    // { label: 'Ct', value: 'Ct' },
    // { label: 'Culture', value: 'Culture' },
    // { label: 'Current', value: 'Current' },
    // { label: 'Curriculum', value: 'Curriculum' },
    // { label: 'Custodian', value: 'Custodian' },
    // { label: 'Customer', value: 'Customer' },
    // { label: 'Cvs', value: 'Cvs' },
    // { label: 'Cx', value: 'Cx' },
    // { label: 'Cyber', value: 'Cyber' },
    // { label: 'Cybersecurity', value: 'Cybersecurity' },
    // { label: 'Cycle', value: 'Cycle' },
    // { label: 'D', value: 'D' },
    // { label: 'Damage', value: 'Damage' },
    // { label: 'Data', value: 'Data' },
    // { label: 'Database', value: 'Database' },
    // { label: 'Dba', value: 'Dba' },
    // { label: 'Dc', value: 'Dc' },
    // { label: 'De', value: 'De' },
    // { label: 'Dean', value: 'Dean' },
    // { label: 'Decisions', value: 'Decisions' },
    // { label: 'Defense', value: 'Defense' },
    // { label: 'Del', value: 'Del' },
    // { label: 'Deli', value: 'Deli' },
    // { label: 'Delivered', value: 'Delivered' },
    // { label: 'Delivery', value: 'Delivery' },
    // { label: 'Demand', value: 'Demand' },
    // { label: 'Department', value: 'Department' },
    // { label: 'Dept', value: 'Dept' },
    // { label: 'Deputy', value: 'Deputy' },
    // { label: 'Derm', value: 'Derm' },
    // { label: 'Design', value: 'Design' },
    // { label: 'Designated', value: 'Designated' },
    // { label: 'Designer', value: 'Designer' },
    // { label: 'Desk', value: 'Desk' },
    // { label: 'Desktop', value: 'Desktop' },
    // { label: 'Desmond', value: 'Desmond' },
    // { label: 'Detailer', value: 'Detailer' },
    // { label: 'Detective', value: 'Detective' },
    // { label: 'Developement', value: 'Developement' },
    // { label: 'Developer', value: 'Developer' },
    // { label: 'Development', value: 'Development' },
    // { label: 'Development1', value: 'Development1' },
    // { label: 'Developmentquality', value: 'Developmentquality' },
    // { label: 'Device', value: 'Device' },
    // { label: 'Devices', value: 'Devices' },
    // { label: 'Devops', value: 'Devops' },
    // { label: 'Dhs', value: 'Dhs' },
    // { label: 'Diagnostic', value: 'Diagnostic' },
    // { label: 'Diener', value: 'Diener' },
    // { label: 'Diesel', value: 'Diesel' },
    // { label: 'Dietitian', value: 'Dietitian' },
    // { label: 'Digital', value: 'Digital' },
    // { label: 'Digitization', value: 'Digitization' },
    // { label: 'Dilisi', value: 'Dilisi' },
    // { label: 'Direct', value: 'Direct' },
    // { label: 'Direction', value: 'Direction' },
    // { label: 'Disability', value: 'Disability' },
    // { label: 'Disease', value: 'Disease' },
    // { label: 'Disorders', value: 'Disorders' },
    // { label: 'Distinguished', value: 'Distinguished' },
    // { label: 'Distribution', value: 'Distribution' },
    // { label: 'Distributor', value: 'Distributor' },
    // { label: 'District', value: 'District' },
    // { label: 'Diversegy', value: 'Diversegy' },
    // { label: 'Diversity', value: 'Diversity' },
    // { label: 'Division', value: 'Division' },
    // { label: 'Divisional', value: 'Divisional' },
    // { label: 'Divison', value: 'Divison' },
    // { label: 'Doctor', value: 'Doctor' },
    // { label: 'Documents', value: 'Documents' },
    // { label: 'Donor', value: 'Donor' },
    // { label: 'Drafter', value: 'Drafter' },
    // { label: 'Draftsman', value: 'Draftsman' },
    // { label: 'Dragon', value: 'Dragon' },
    // { label: 'Drilling', value: 'Drilling' },
    // { label: 'Driveline', value: 'Driveline' },
    // { label: 'Driver', value: 'Driver' },
    // { label: 'Drug', value: 'Drug' },
    // { label: 'Dt', value: 'Dt' },
    // { label: 'Duluth', value: 'Duluth' },
    // { label: 'Duty', value: 'Duty' },
    // { label: 'Dvp', value: 'Dvp' },
    // { label: 'Dynamics', value: 'Dynamics' },
    // { label: 'E', value: 'E' },
    // { label: 'E3', value: 'E3' },
    // { label: 'Early', value: 'Early' },
    // { label: 'Earth', value: 'Earth' },
    // { label: 'East', value: 'East' },
    // { label: 'Ebs', value: 'Ebs' },
    // { label: 'Ecomm', value: 'Ecomm' },
    // { label: 'Ecommerce', value: 'Ecommerce' },
    // { label: 'Economic', value: 'Economic' },
    // { label: 'Economics', value: 'Economics' },
    // { label: 'Economist', value: 'Economist' },
    // { label: 'Editor', value: 'Editor' },
    // { label: 'Editorial', value: 'Editorial' },
    // { label: 'Education', value: 'Education' },
    // { label: 'Educational', value: 'Educational' },
    // { label: 'Educator', value: 'Educator' },
    // { label: 'Effectiveness', value: 'Effectiveness' },
    // { label: 'Elc', value: 'Elc' },
    // { label: 'Electrical', value: 'Electrical' },
    // { label: 'Electrician', value: 'Electrician' },
    // { label: 'Electro', value: 'Electro' },
    // { label: 'Electronic', value: 'Electronic' },
    // { label: 'Electronics', value: 'Electronics' },
    // { label: 'Elementary', value: 'Elementary' },
    // { label: 'Ell', value: 'Ell' },
    // { label: 'Elt', value: 'Elt' },
    // { label: 'Emd', value: 'Emd' },
    // { label: 'Emea', value: 'Emea' },
    // { label: 'Emergency', value: 'Emergency' },
    // { label: 'Emerging', value: 'Emerging' },
    // { label: 'Emeritus', value: 'Emeritus' },
    // { label: 'Emory', value: 'Emory' },
    // { label: 'Employee', value: 'Employee' },
    // { label: 'Employment', value: 'Employment' },
    // { label: 'Emr', value: 'Emr' },
    // { label: 'Ems', value: 'Ems' },
    // { label: 'Enablement', value: 'Enablement' },
    // { label: 'Enabler', value: 'Enabler' },
    // { label: 'End', value: 'End' },
    // { label: 'Endowment', value: 'Endowment' },
    // { label: 'Energy', value: 'Energy' },
    // { label: 'Enforcement', value: 'Enforcement' },
    // { label: 'Eng', value: 'Eng' },
    // { label: 'Engagement', value: 'Engagement' },
    // { label: 'Engeeniring', value: 'Engeeniring' },
    // { label: 'Engeneer', value: 'Engeneer' },
    // { label: 'Engieer', value: 'Engieer' },
    // { label: 'Engine', value: 'Engine' },
    // { label: 'Enginear', value: 'Enginear' },
    // { label: 'Enginee', value: 'Enginee' },
    // { label: 'Engineeer', value: 'Engineeer' },
    // { label: 'Engineer', value: 'Engineer' },
    // { label: 'Engineering', value: 'Engineering' },
    // { label: 'Engineerng', value: 'Engineerng' },
    // { label: 'Engineerproject', value: 'Engineerproject' },
    // { label: 'Engineers', value: 'Engineers' },
    // { label: 'Engineet', value: 'Engineet' },
    // { label: 'Enginer', value: 'Enginer' },
    // { label: 'Enginerr', value: 'Enginerr' },
    // { label: 'Enginner', value: 'Enginner' },
    // { label: 'English', value: 'English' },
    // { label: 'Enrollment', value: 'Enrollment' },
    // { label: 'Ent', value: 'Ent' },
    // { label: 'Enterprise', value: 'Enterprise' },
    // { label: 'Enthusiast', value: 'Enthusiast' },
    // { label: 'Entry', value: 'Entry' },
    // { label: 'Environment', value: 'Environment' },
    // { label: 'Environmental', value: 'Environmental' },
    // { label: 'Equal', value: 'Equal' },
    // { label: 'Equity', value: 'Equity' },
    // { label: 'Er', value: 'Er' },
    // { label: 'Erp', value: 'Erp' },
    // { label: 'Escrow', value: 'Escrow' },
    // { label: 'Espn', value: 'Espn' },
    // { label: 'Estate', value: 'Estate' },
    // { label: 'Estimator', value: 'Estimator' },
    // { label: 'Ethics', value: 'Ethics' },
    // { label: 'Etl', value: 'Etl' },
    // { label: 'Eugene', value: 'Eugene' },
    // { label: 'Europe', value: 'Europe' },
    // { label: 'European', value: 'European' },
    // { label: 'Evaluator', value: 'Evaluator' },
    // { label: 'Evangelist', value: 'Evangelist' },
    // { label: 'Event', value: 'Event' },
    // { label: 'Events', value: 'Events' },
    // { label: 'Evidence-Based', value: 'Evidence-Based' },
    // { label: 'Evraz', value: 'Evraz' },
    // { label: 'Examiner', value: 'Examiner' },
    // { label: 'Excellence', value: 'Excellence' },
    // { label: 'Executive', value: 'Executive' },
    // { label: 'Executives', value: 'Executives' },
    // { label: 'Exellence', value: 'Exellence' },
    // { label: 'Expeditor', value: 'Expeditor' },
    // { label: 'Experience', value: 'Experience' },
    // { label: 'Experienced', value: 'Experienced' },
    // { label: 'Expert', value: 'Expert' },
    // { label: 'Extern', value: 'Extern' },
    // { label: 'External', value: 'External' },
    // { label: 'Faa', value: 'Faa' },
    // { label: 'Facilitator', value: 'Facilitator' },
    // { label: 'Facilities', value: 'Facilities' },
    // { label: 'Facility', value: 'Facility' },
    // { label: 'Faculty', value: 'Faculty' },
    // { label: 'Fam0', value: 'Fam0' },
    // { label: 'Family', value: 'Family' },
    // { label: 'Fanatics', value: 'Fanatics' },
    // { label: 'Fastpitch', value: 'Fastpitch' },
    // { label: 'Federal', value: 'Federal' },
    // { label: 'Fellow', value: 'Fellow' },
    // { label: 'Fenix', value: 'Fenix' },
    // { label: 'Fico', value: 'Fico' },
    // { label: 'Field', value: 'Field' },
    // { label: 'Fields', value: 'Fields' },
    // { label: 'File', value: 'File' },
    // { label: 'Fill', value: 'Fill' },
    // { label: 'Finance', value: 'Finance' },
    // { label: 'Financial', value: 'Financial' },
    // { label: 'Fire', value: 'Fire' },
    // { label: 'Firearms', value: 'Firearms' },
    // { label: 'Firefighter', value: 'Firefighter' },
    // { label: 'Fireman', value: 'Fireman' },
    // { label: 'Firm', value: 'Firm' },
    // { label: 'First', value: 'First' },
    // { label: 'Fixed', value: 'Fixed' },
    // { label: 'Fl', value: 'Fl' },
    // { label: 'Fleet', value: 'Fleet' },
    // { label: 'Flight', value: 'Flight' },
    // { label: 'Float', value: 'Float' },
    // { label: 'Florida', value: 'Florida' },
    // { label: 'Food', value: 'Food' },
    // { label: 'Football', value: 'Football' },
    // { label: 'For', value: 'For' },
    // { label: 'Forces', value: 'Forces' },
    // { label: 'Foreman', value: 'Foreman' },
    // { label: 'Forensic', value: 'Forensic' },
    // { label: 'Forensics', value: 'Forensics' },
    // { label: 'Forest', value: 'Forest' },
    // { label: 'Forester', value: 'Forester' },
    // { label: 'Former', value: 'Former' },
    // { label: 'Formulation', value: 'Formulation' },
    // { label: 'Forums', value: 'Forums' },
    // { label: 'Foster', value: 'Foster' },
    // { label: 'Foundation', value: 'Foundation' },
    // { label: 'Founder', value: 'Founder' },
    // { label: 'Founding', value: 'Founding' },
    // { label: 'Fp', value: 'Fp' },
    // { label: 'Franchise', value: 'Franchise' },
    // { label: 'Fraud', value: 'Fraud' },
    // { label: 'Freelance', value: 'Freelance' },
    // { label: 'Frequency', value: 'Frequency' },
    // { label: 'Front', value: 'Front' },
    // { label: 'Frontend', value: 'Frontend' },
    // { label: 'Fulfillment', value: 'Fulfillment' },
    // { label: 'Full', value: 'Full' },
    // { label: 'Fund', value: 'Fund' },
    // { label: 'G', value: 'G' },
    // { label: 'Ga', value: 'Ga' },
    // { label: 'Gallery', value: 'Gallery' },
    // { label: 'Gaming', value: 'Gaming' },
    // { label: 'Garden', value: 'Garden' },
    // { label: 'Gas', value: 'Gas' },
    // { label: 'Gcg', value: 'Gcg' },
    // { label: 'Gcx', value: 'Gcx' },
    // { label: 'Gen', value: 'Gen' },
    // { label: 'General', value: 'General' },
    // { label: 'Generalist', value: 'Generalist' },
    // { label: 'Generation', value: 'Generation' },
    // { label: 'Generator', value: 'Generator' },
    // { label: 'Geographic', value: 'Geographic' },
    // { label: 'Geologist', value: 'Geologist' },
    // { label: 'Geophysicist', value: 'Geophysicist' },
    // { label: 'Girl', value: 'Girl' },
    // { label: 'Girls', value: 'Girls' },
    // { label: 'Glj', value: 'Glj' },
    // { label: 'Global', value: 'Global' },
    // { label: 'Gm', value: 'Gm' },
    // { label: 'Godwin', value: 'Godwin' },
    // { label: 'Governance', value: 'Governance' },
    // { label: 'Government', value: 'Government' },
    // { label: 'Governmental', value: 'Governmental' },
    // { label: 'Governors', value: 'Governors' },
    // { label: 'Gpsc', value: 'Gpsc' },
    // { label: 'Graduate', value: 'Graduate' },
    // { label: 'Graphic', value: 'Graphic' },
    // { label: 'Grassroots', value: 'Grassroots' },
    // { label: 'Greatest', value: 'Greatest' },
    // { label: 'Grocery', value: 'Grocery' },
    // { label: 'Ground', value: 'Ground' },
    // { label: 'Group', value: 'Group' },
    // { label: 'Groups', value: 'Groups' },
    // { label: 'Growth', value: 'Growth' },
    // { label: 'Grp', value: 'Grp' },
    // { label: 'Gs', value: 'Gs' },
    // { label: 'Gto', value: 'Gto' },
    // { label: 'Gts', value: 'Gts' },
    // { label: 'Guard', value: 'Guard' },
    // { label: 'Guest', value: 'Guest' },
    // { label: 'Guidance', value: 'Guidance' },
    // { label: 'Gunner', value: 'Gunner' },
    // { label: 'H', value: 'H' },
    // { label: 'Hana', value: 'Hana' },
    // { label: 'Handler', value: 'Handler' },
    // { label: 'Harborview', value: 'Harborview' },
    // { label: 'Hardware', value: 'Hardware' },
    // { label: 'Harvesting', value: 'Harvesting' },
    // { label: 'Hcc', value: 'Hcc' },
    // { label: 'Head', value: 'Head' },
    // { label: 'Health', value: 'Health' },
    // { label: 'Healthcare', value: 'Healthcare' },
    // { label: 'Healthfitness', value: 'Healthfitness' },
    // { label: 'Heath', value: 'Heath' },
    // { label: 'Help', value: 'Help' },
    // { label: 'Helpdesk', value: 'Helpdesk' },
    // { label: 'Hero911', value: 'Hero911' },
    // { label: 'Hfn', value: 'Hfn' },
    // { label: 'Higher', value: 'Higher' },
    // { label: 'Hipaa', value: 'Hipaa' },
    // { label: 'Hire', value: 'Hire' },
    // { label: 'History', value: 'History' },
    // { label: 'Hitter', value: 'Hitter' },
    // { label: 'Hod', value: 'Hod' },
    // { label: 'Home', value: 'Home' },
    // { label: 'Honda', value: 'Honda' },
    // { label: 'Horizon', value: 'Horizon' },
    // { label: 'Hospital', value: 'Hospital' },
    // { label: 'Hospitality', value: 'Hospitality' },
    // { label: 'House', value: 'House' },
    // { label: 'Hpe', value: 'Hpe' },
    // { label: 'Hr', value: 'Hr' },
    // { label: 'Hra', value: 'Hra' },
    // { label: 'Hris', value: 'Hris' },
    // { label: 'Hse', value: 'Hse' },
    // { label: 'Human', value: 'Human' },
    // { label: 'Hvac', value: 'Hvac' },
    // { label: 'Hydrospheric', value: 'Hydrospheric' },
    // { label: 'Hygiene', value: 'Hygiene' },
    // { label: 'Hygienist', value: 'Hygienist' },
    // { label: 'Hyland', value: 'Hyland' },
    // { label: 'I', value: 'I' },
    // { label: 'Icr', value: 'Icr' },
    // { label: 'Ies', value: 'Ies' },
    // { label: 'Ig', value: 'Ig' },
    // { label: 'Ii', value: 'Ii' },
    // { label: 'Iii', value: 'Iii' },
    // { label: 'Immediate', value: 'Immediate' },
    // { label: 'Immigration', value: 'Immigration' },
    // { label: 'Immunology', value: 'Immunology' },
    // { label: 'Implementation', value: 'Implementation' },
    // { label: 'Implementations', value: 'Implementations' },
    // { label: 'Improvement', value: 'Improvement' },
    // { label: 'In', value: 'In' },
    // { label: 'Inc', value: 'Inc' },
    // { label: 'Inclusion', value: 'Inclusion' },
    // { label: 'Income', value: 'Income' },
    // { label: 'Independant', value: 'Independant' },
    // { label: 'Independent', value: 'Independent' },
    // { label: 'Indirect', value: 'Indirect' },
    // { label: 'Individual', value: 'Individual' },
    // { label: 'Industrial', value: 'Industrial' },
    // { label: 'Industry', value: 'Industry' },
    // { label: 'Infant', value: 'Infant' },
    // { label: 'Influencer', value: 'Influencer' },
    // { label: 'Informatics', value: 'Informatics' },
    // { label: 'Information', value: 'Information' },
    // { label: 'Infrastructure', value: 'Infrastructure' },
    // { label: 'Infusion', value: 'Infusion' },
    // { label: 'Ingeniero', value: 'Ingeniero' },
    // { label: 'Initiatives', value: 'Initiatives' },
    // { label: 'Injury', value: 'Injury' },
    // { label: 'Innovation', value: 'Innovation' },
    // { label: 'Innovations', value: 'Innovations' },
    // { label: 'Inns', value: 'Inns' },
    // { label: 'Inpatient', value: 'Inpatient' },
    // { label: 'Inside', value: 'Inside' },
    // { label: 'Insights', value: 'Insights' },
    // { label: 'Inspector', value: 'Inspector' },
    // { label: 'Institute', value: 'Institute' },
    // { label: 'Institutional', value: 'Institutional' },
    // { label: 'Instruction', value: 'Instruction' },
    // { label: 'Instructional', value: 'Instructional' },
    // { label: 'Instructor', value: 'Instructor' },
    // { label: 'Instrument', value: 'Instrument' },
    // { label: 'Insurance', value: 'Insurance' },
    // { label: 'Integration', value: 'Integration' },
    // { label: 'Integrator', value: 'Integrator' },
    // { label: 'Integrity', value: 'Integrity' },
    // { label: 'Intellectual', value: 'Intellectual' },
    // { label: 'Intelligence', value: 'Intelligence' },
    // { label: 'Intensive', value: 'Intensive' },
    // { label: 'Intercept', value: 'Intercept' },
    // { label: 'Interest', value: 'Interest' },
    // { label: 'Interface', value: 'Interface' },
    // { label: 'Interim', value: 'Interim' },
    // { label: 'Interior', value: 'Interior' },
    // { label: 'Internal', value: 'Internal' },
    // { label: 'International', value: 'International' },
    // { label: 'Internet', value: 'Internet' },
    // { label: 'Interpreter', value: 'Interpreter' },
    // { label: 'Interviewer', value: 'Interviewer' },
    // { label: 'Intimate', value: 'Intimate' },
    // { label: 'Inventory', value: 'Inventory' },
    // { label: 'Investigator', value: 'Investigator' },
    // { label: 'Investing', value: 'Investing' },
    // { label: 'Investment', value: 'Investment' },
    // { label: 'Investments', value: 'Investments' },
    // { label: 'Investor', value: 'Investor' },
    // { label: 'Ireland', value: 'Ireland' },
    // { label: 'Irma', value: 'Irma' },
    // { label: 'Is', value: 'Is' },
    // { label: 'Isd', value: 'Isd' },
    // { label: 'Island', value: 'Island' },
    // { label: 'Ispor', value: 'Ispor' },
    // { label: 'Iss', value: 'Iss' },
    // { label: 'Isso', value: 'Isso' },
    // { label: 'It', value: 'It' },
    // { label: 'It-Medical', value: 'It-Medical' },
    // { label: 'Its', value: 'Its' },
    // { label: 'Iv', value: 'Iv' },
    // { label: 'Jb', value: 'Jb' },
    // { label: 'Jewelry', value: 'Jewelry' },
    // { label: 'Jmo', value: 'Jmo' },
    // { label: 'Job', value: 'Job' },
    // { label: 'John', value: 'John' },
    // { label: 'Journalist', value: 'Journalist' },
    // { label: 'Judge', value: 'Judge' },
    // { label: 'Jugo', value: 'Jugo' },
    // { label: 'Junior', value: 'Junior' },
    // { label: 'K', value: 'K' },
    // { label: 'Kay', value: 'Kay' },
    // { label: 'Kc', value: 'Kc' },
    // { label: 'Kentucky', value: 'Kentucky' },
    // { label: 'Kichler', value: 'Kichler' },
    // { label: 'Kmi', value: 'Kmi' },
    // { label: 'Knowledge', value: 'Knowledge' },
    // { label: 'La', value: 'La' },
    // { label: 'Lab', value: 'Lab' },
    // { label: 'Labor', value: 'Labor' },
    // { label: 'Laboratory', value: 'Laboratory' },
    // { label: 'Laborer', value: 'Laborer' },
    // { label: 'Laboror', value: 'Laboror' },
    // { label: 'Landowners', value: 'Landowners' },
    // { label: 'Landscape', value: 'Landscape' },
    // { label: 'Language', value: 'Language' },
    // { label: 'Late', value: 'Late' },
    // { label: 'Law', value: 'Law' },
    // { label: 'Lawyer', value: 'Lawyer' },
    // { label: 'Lead', value: 'Lead' },
    // { label: 'Leader', value: 'Leader' },
    // { label: 'Leadership', value: 'Leadership' },
    // { label: 'Leading', value: 'Leading' },
    // { label: 'Learning', value: 'Learning' },
    // { label: 'Lease', value: 'Lease' },
    // { label: 'Leasing', value: 'Leasing' },
    // { label: 'Leatherstocking', value: 'Leatherstocking' },
    // { label: 'Lecturer', value: 'Lecturer' },
    // { label: 'Legal', value: 'Legal' },
    // { label: 'Legislative', value: 'Legislative' },
    // { label: 'Lender', value: 'Lender' },
    // { label: 'Lending', value: 'Lending' },
    // { label: 'Lerma', value: 'Lerma' },
    // { label: 'Liaison', value: 'Liaison' },
    // { label: 'Librarian', value: 'Librarian' },
    // { label: 'Library', value: 'Library' },
    // { label: 'Licensed', value: 'Licensed' },
    // { label: 'Licensing', value: 'Licensing' },
    // { label: 'Licesend', value: 'Licesend' },
    // { label: 'Lieutenant', value: 'Lieutenant' },
    // { label: 'Life', value: 'Life' },
    // { label: 'Lifecycle', value: 'Lifecycle' },
    // { label: 'Lifestyle', value: 'Lifestyle' },
    // { label: 'Lighting', value: 'Lighting' },
    // { label: 'Lincoln', value: 'Lincoln' },
    // { label: 'Line', value: 'Line' },
    // { label: 'Litigation', value: 'Litigation' },
    // { label: 'Lives', value: 'Lives' },
    // { label: 'Llc', value: 'Llc' },
    // { label: 'Llead', value: 'Llead' },
    // { label: 'Loan', value: 'Loan' },
    // { label: 'Loans', value: 'Loans' },
    // { label: 'Lobby', value: 'Lobby' },
    // { label: 'Lobbyist', value: 'Lobbyist' },
    // { label: 'Local', value: 'Local' },
    // { label: 'Locomotive', value: 'Locomotive' },
    // { label: 'Logistic', value: 'Logistic' },
    // { label: 'Logistics', value: 'Logistics' },
    // { label: 'Long', value: 'Long' },
    // { label: 'Loss', value: 'Loss' },
    // { label: 'Lpl', value: 'Lpl' },
    // { label: 'Lpn', value: 'Lpn' },
    // { label: 'Lumber', value: 'Lumber' },
    // { label: 'Luo', value: 'Luo' },
    // { label: 'Luxury', value: 'Luxury' },
    // { label: 'Lyrical', value: 'Lyrical' },
    // { label: 'M', value: 'M' },
    // { label: 'Machine', value: 'Machine' },
    // { label: 'Machinery', value: 'Machinery' },
    // { label: 'Mail', value: 'Mail' },
    // { label: 'Maintainer', value: 'Maintainer' },
    // { label: 'Maintenance', value: 'Maintenance' },
    // { label: 'Mammography', value: 'Mammography' },
    // { label: 'Manageability', value: 'Manageability' },
    // { label: 'Management', value: 'Management' },
    // { label: 'Managercombat', value: 'Managercombat' },
    // { label: 'Managerconsultant', value: 'Managerconsultant' },
    // { label: 'Managers', value: 'Managers' },
    // { label: 'Managing', value: 'Managing' },
    // { label: 'Manufacturing', value: 'Manufacturing' },
    // { label: 'Mar', value: 'Mar' },
    // { label: 'Marine', value: 'Marine' },
    // { label: 'Maritime', value: 'Maritime' },
    // { label: 'Market', value: 'Market' },
    // { label: 'Marketer', value: 'Marketer' },
    // { label: 'Marketers', value: 'Marketers' },
    // { label: 'Marketing', value: 'Marketing' },
    // { label: 'Markets', value: 'Markets' },
    // { label: 'Marshal', value: 'Marshal' },
    // { label: 'Mary', value: 'Mary' },
    // { label: 'Master', value: 'Master' },
    // { label: 'Material', value: 'Material' },
    // { label: 'Materials', value: 'Materials' },
    // { label: 'Mathematics', value: 'Mathematics' },
    // { label: 'Matter', value: 'Matter' },
    // { label: 'Mayor', value: 'Mayor' },
    // { label: 'Mayoral', value: 'Mayoral' },
    // { label: 'Mayordomo', value: 'Mayordomo' },
    // { label: 'Mbb', value: 'Mbb' },
    // { label: 'Meat', value: 'Meat' },
    // { label: 'Mechanic', value: 'Mechanic' },
    // { label: 'Mechanical', value: 'Mechanical' },
    // { label: 'Med', value: 'Med' },
    // { label: 'Media', value: 'Media' },
    // { label: 'Medicaid', value: 'Medicaid' },
    // { label: 'Medical', value: 'Medical' },
    // { label: 'Medicine', value: 'Medicine' },
    // { label: 'Medicmedical', value: 'Medicmedical' },
    // { label: 'Member', value: 'Member' },
    // { label: 'Membership', value: 'Membership' },
    // { label: 'Mental', value: 'Mental' },
    // { label: 'Mentor', value: 'Mentor' },
    // { label: 'Mer', value: 'Mer' },
    // { label: 'Merchandiser', value: 'Merchandiser' },
    // { label: 'Merchandising', value: 'Merchandising' },
    // { label: 'Merck', value: 'Merck' },
    // { label: 'Merger', value: 'Merger' },
    // { label: 'Mergers', value: 'Mergers' },
    // { label: 'Messaging', value: 'Messaging' },
    // { label: 'Metadata', value: 'Metadata' },
    // { label: 'Metro', value: 'Metro' },
    // { label: 'Metrocard', value: 'Metrocard' },
    // { label: 'Mgmt', value: 'Mgmt' },
    // { label: 'Mhealthy', value: 'Mhealthy' },
    // { label: 'Microbiologist', value: 'Microbiologist' },
    // { label: 'Midwest', value: 'Midwest' },
    // { label: 'Military', value: 'Military' },
    // { label: 'Millennial', value: 'Millennial' },
    // { label: 'Mimacom', value: 'Mimacom' },
    // { label: 'Mis', value: 'Mis' },
    // { label: 'Mitigation', value: 'Mitigation' },
    // { label: 'Mjh', value: 'Mjh' },
    // { label: 'Mn', value: 'Mn' },
    // { label: 'Mobile', value: 'Mobile' },
    // { label: 'Mock', value: 'Mock' },
    // { label: 'Model', value: 'Model' },
    // { label: 'Modeler', value: 'Modeler' },
    // { label: 'Models', value: 'Models' },
    // { label: 'Modernization', value: 'Modernization' },
    // { label: 'Monitor', value: 'Monitor' },
    // { label: 'Mortgage', value: 'Mortgage' },
    // { label: 'Motor', value: 'Motor' },
    // { label: 'Mri', value: 'Mri' },
    // { label: 'Muir', value: 'Muir' },
    // { label: 'Multi', value: 'Multi' },
    // { label: 'Multichannel', value: 'Multichannel' },
    // { label: 'Multimedia', value: 'Multimedia' },
    // { label: 'Municipal', value: 'Municipal' },
    // { label: 'Music', value: 'Music' },
    // { label: 'N', value: 'N' },
    // { label: 'N6', value: 'N6' },
    // { label: 'National', value: 'National' },
    // { label: 'Natural', value: 'Natural' },
    // { label: 'Naturallyspeaking', value: 'Naturallyspeaking' },
    // { label: 'Navigator', value: 'Navigator' },
    // { label: 'Navihealth', value: 'Navihealth' },
    // { label: 'Nc', value: 'Nc' },
    // { label: 'Ncal', value: 'Ncal' },
    // { label: 'Nco', value: 'Nco' },
    // { label: 'Neck', value: 'Neck' },
    // { label: 'Negotiator', value: 'Negotiator' },
    // { label: 'Nemt', value: 'Nemt' },
    // { label: 'Netapp', value: 'Netapp' },
    // { label: 'Network', value: 'Network' },
    // { label: 'Networking', value: 'Networking' },
    // { label: 'Neurology', value: 'Neurology' },
    // { label: 'Neuropsychologist', value: 'Neuropsychologist' },
    // { label: 'Neuroscience', value: 'Neuroscience' },
    // { label: 'New', value: 'New' },
    // { label: 'News', value: 'News' },
    // { label: 'Nga', value: 'Nga' },
    // { label: 'Night', value: 'Night' },
    // { label: 'Nmls', value: 'Nmls' },
    // { label: 'Noc', value: 'Noc' },
    // { label: 'Nonclinical', value: 'Nonclinical' },
    // { label: 'North', value: 'North' },
    // { label: 'Northeast', value: 'Northeast' },
    // { label: 'Northwest', value: 'Northwest' },
    // { label: 'Nrr', value: 'Nrr' },
    // { label: 'Nuclear', value: 'Nuclear' },
    // { label: 'Nurse', value: 'Nurse' },
    // { label: 'Nursery', value: 'Nursery' },
    // { label: 'Nursing', value: 'Nursing' },
    // { label: 'Nutrition', value: 'Nutrition' },
    // { label: 'Occupational', value: 'Occupational' },
    // { label: 'Off', value: 'Off' },
    // { label: 'Offers', value: 'Offers' },
    // { label: 'Office', value: 'Office' },
    // { label: 'Officer', value: 'Officer' },
    // { label: 'Officerhealth', value: 'Officerhealth' },
    // { label: 'Official', value: 'Official' },
    // { label: 'Ohio', value: 'Ohio' },
    // { label: 'Old', value: 'Old' },
    // { label: 'Omnichannel', value: 'Omnichannel' },
    // { label: 'On', value: 'On' },
    // { label: 'Onboarding', value: 'Onboarding' },
    // { label: 'Onc', value: 'Onc' },
    // { label: 'Oncology', value: 'Oncology' },
    // { label: 'Online', value: 'Online' },
    // { label: 'Op', value: 'Op' },
    // { label: 'Oper', value: 'Oper' },
    // { label: 'Operating', value: 'Operating' },
    // { label: 'Operation', value: 'Operation' },
    // { label: 'Operational', value: 'Operational' },
    // { label: 'Operations', value: 'Operations' },
    // { label: 'Operato', value: 'Operato' },
    // { label: 'Operator', value: 'Operator' },
    // { label: 'Oph', value: 'Oph' },
    // { label: 'Opm', value: 'Opm' },
    // { label: 'Opportunity', value: 'Opportunity' },
    // { label: 'Ops', value: 'Ops' },
    // { label: 'Or', value: 'Or' },
    // { label: 'Oracle', value: 'Oracle' },
    // { label: 'Orcad', value: 'Orcad' },
    // { label: 'Order', value: 'Order' },
    // { label: 'Ordnance', value: 'Ordnance' },
    // { label: 'Organizing', value: 'Organizing' },
    // { label: 'Originator', value: 'Originator' },
    // { label: 'Ortho', value: 'Ortho' },
    // { label: 'Otolaryngologist', value: 'Otolaryngologist' },
    // { label: 'Otolaryngology', value: 'Otolaryngology' },
    // { label: 'Out', value: 'Out' },
    // { label: 'Outcomes', value: 'Outcomes' },
    // { label: 'Outpatient', value: 'Outpatient' },
    // { label: 'Outreach', value: 'Outreach' },
    // { label: 'Outside', value: 'Outside' },
    // { label: 'Outsourcing', value: 'Outsourcing' },
    // { label: 'Overnight', value: 'Overnight' },
    // { label: 'Oversight', value: 'Oversight' },
    // { label: 'Owner', value: 'Owner' },
    // { label: 'Pa', value: 'Pa' },
    // { label: 'Pain', value: 'Pain' },
    // { label: 'Painter', value: 'Painter' },
    // { label: 'Paralegal', value: 'Paralegal' },
    // { label: 'Paramedic', value: 'Paramedic' },
    // { label: 'Parent', value: 'Parent' },
    // { label: 'Partner', value: 'Partner' },
    // { label: 'Partnering', value: 'Partnering' },
    // { label: 'Partners', value: 'Partners' },
    // { label: 'Partnership', value: 'Partnership' },
    // { label: 'Partnerships', value: 'Partnerships' },
    // { label: 'Parts', value: 'Parts' },
    // { label: 'Past', value: 'Past' },
    // { label: 'Pastry', value: 'Pastry' },
    // { label: 'Pathologist', value: 'Pathologist' },
    // { label: 'Pathology', value: 'Pathology' },
    // { label: 'Patient', value: 'Patient' },
    // { label: 'Pay', value: 'Pay' },
    // { label: 'Payable', value: 'Payable' },
    // { label: 'Payroll', value: 'Payroll' },
    // { label: 'Pbm', value: 'Pbm' },
    // { label: 'Pcs', value: 'Pcs' },
    // { label: 'Peacehealth', value: 'Peacehealth' },
    // { label: 'Pediatrics', value: 'Pediatrics' },
    // { label: 'Peer', value: 'Peer' },
    // { label: 'Penetration', value: 'Penetration' },
    // { label: 'People', value: 'People' },
    // { label: 'Performance', value: 'Performance' },
    // { label: 'Peri', value: 'Peri' },
    // { label: 'Personal', value: 'Personal' },
    // { label: 'Personnel', value: 'Personnel' },
    // { label: 'Petroleum', value: 'Petroleum' },
    // { label: 'Petty', value: 'Petty' },
    // { label: 'Pharma', value: 'Pharma' },
    // { label: 'Pharmaceutical', value: 'Pharmaceutical' },
    // { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
    // { label: 'Pharmacist', value: 'Pharmacist' },
    // { label: 'Pharmacovigilance', value: 'Pharmacovigilance' },
    // { label: 'Pharmacy', value: 'Pharmacy' },
    // { label: 'Phlebotomist', value: 'Phlebotomist' },
    // { label: 'Phlebotomy', value: 'Phlebotomy' },
    // { label: 'Photo', value: 'Photo' },
    // { label: 'Photographer', value: 'Photographer' },
    // { label: 'Photojournalist', value: 'Photojournalist' },
    // { label: 'Physical', value: 'Physical' },
    // { label: 'Physician', value: 'Physician' },
    // { label: 'Physicist', value: 'Physicist' },
    // { label: 'Piano', value: 'Piano' },
    // { label: 'Pilot', value: 'Pilot' },
    // { label: 'Pilotowner', value: 'Pilotowner' },
    // { label: 'Plan', value: 'Plan' },
    // { label: 'Planner', value: 'Planner' },
    // { label: 'Planning', value: 'Planning' },
    // { label: 'Plans', value: 'Plans' },
    // { label: 'Plant', value: 'Plant' },
    // { label: 'Plastics', value: 'Plastics' },
    // { label: 'Platform', value: 'Platform' },
    // { label: 'Platforms', value: 'Platforms' },
    // { label: 'Player', value: 'Player' },
    // { label: 'Pmf', value: 'Pmf' },
    // { label: 'Pmo', value: 'Pmo' },
    // { label: 'Podcasts', value: 'Podcasts' },
    // { label: 'Police', value: 'Police' },
    // { label: 'Policy', value: 'Policy' },
    // { label: 'Political', value: 'Political' },
    // { label: 'Porsche', value: 'Porsche' },
    // { label: 'Portfolio', value: 'Portfolio' },
    // { label: 'Portfolios', value: 'Portfolios' },
    // { label: 'Positions', value: 'Positions' },
    // { label: 'Post', value: 'Post' },
    // { label: 'Postdoctoral', value: 'Postdoctoral' },
    // { label: 'Poultry', value: 'Poultry' },
    // { label: 'Power', value: 'Power' },
    // { label: 'Powerade', value: 'Powerade' },
    // { label: 'Powershift', value: 'Powershift' },
    // { label: 'Powerstore', value: 'Powerstore' },
    // { label: 'Ppd', value: 'Ppd' },
    // { label: 'Pr', value: 'Pr' },
    // { label: 'Practical', value: 'Practical' },
    // { label: 'Practice', value: 'Practice' },
    // { label: 'Practitioner', value: 'Practitioner' },
    // { label: 'Pre', value: 'Pre' },
    // { label: 'Preclinical', value: 'Preclinical' },
    // { label: 'Preconstruction', value: 'Preconstruction' },
    // { label: 'Premiere', value: 'Premiere' },
    // { label: 'Prep', value: 'Prep' },
    // { label: 'Prepaid', value: 'Prepaid' },
    // { label: 'Preschool', value: 'Preschool' },
    // { label: 'Preserve', value: 'Preserve' },
    // { label: 'President', value: 'President' },
    // { label: 'President)', value: 'President)' },
    // { label: 'President,', value: 'President,' },
    // { label: 'President-Analyst', value: 'President-Analyst' },
    // { label: 'Presidential', value: 'Presidential' },
    // { label: 'Presidentmarketing', value: 'Presidentmarketing' },
    // { label: 'Presidents', value: 'Presidents' },
    // { label: 'President-Senior', value: 'President-Senior' },
    // { label: 'Presiding', value: 'Presiding' },
    // { label: 'Press', value: 'Press' },
    // { label: 'Prevention', value: 'Prevention' },
    // { label: 'Price', value: 'Price' },
    // { label: 'Pricing', value: 'Pricing' },
    // { label: 'Pride', value: 'Pride' },
    // { label: 'Primary', value: 'Primary' },
    // { label: 'Primeclaims', value: 'Primeclaims' },
    // { label: 'Principal', value: 'Principal' },
    // { label: 'Privacy', value: 'Privacy' },
    // { label: 'Private', value: 'Private' },
    // { label: 'Prn', value: 'Prn' },
    // { label: 'Pro', value: 'Pro' },
    // { label: 'Probation', value: 'Probation' },
    // { label: 'Process', value: 'Process' },
    // { label: 'Processing', value: 'Processing' },
    // { label: 'Processor', value: 'Processor' },
    // { label: 'Procurement', value: 'Procurement' },
    // { label: 'Prod', value: 'Prod' },
    // { label: 'Produce', value: 'Produce' },
    // { label: 'Producer', value: 'Producer' },
    // { label: 'Product', value: 'Product' },
    // { label: 'Production', value: 'Production' },
    // { label: 'Productivity', value: 'Productivity' },
    // { label: 'Products', value: 'Products' },
    // { label: 'Professional', value: 'Professional' },
    // { label: 'Professionals', value: 'Professionals' },
    // { label: 'Professor', value: 'Professor' },
    // { label: 'Progra', value: 'Progra' },
    // { label: 'Program', value: 'Program' },
    // { label: 'Programmatic', value: 'Programmatic' },
    // { label: 'Programmer', value: 'Programmer' },
    // { label: 'Programs', value: 'Programs' },
    // { label: 'Project', value: 'Project' },
    // { label: 'Projects', value: 'Projects' },
    // { label: 'Property', value: 'Property' },
    // { label: 'Prospective', value: 'Prospective' },
    // { label: 'Protection', value: 'Protection' },
    // { label: 'Provider', value: 'Provider' },
    // { label: 'Providers', value: 'Providers' },
    // { label: 'Provost', value: 'Provost' },
    // { label: 'Psr', value: 'Psr' },
    // { label: 'Psychiatry', value: 'Psychiatry' },
    // { label: 'Psychologist', value: 'Psychologist' },
    // { label: 'Pt', value: 'Pt' },
    // { label: 'Public', value: 'Public' },
    // { label: 'Publisher', value: 'Publisher' },
    // { label: 'Purchasing', value: 'Purchasing' },
    // { label: 'Qa', value: 'Qa' },
    // { label: 'Qc', value: 'Qc' },
    // { label: 'Qms', value: 'Qms' },
    // { label: 'Quality', value: 'Quality' },
    // { label: 'R', value: 'R' },
    // { label: 'R&D', value: 'R&D' },
    // { label: 'Ra', value: 'Ra' },
    // { label: 'Rachel', value: 'Rachel' },
    // { label: 'Rad', value: 'Rad' },
    // { label: 'Radiation', value: 'Radiation' },
    // { label: 'Radio', value: 'Radio' },
    // { label: 'Radiocom', value: 'Radiocom' },
    // { label: 'Radiologic', value: 'Radiologic' },
    // { label: 'Radiology', value: 'Radiology' },
    // { label: 'Rainmaker', value: 'Rainmaker' },
    // { label: 'Rangel', value: 'Rangel' },
    // { label: 'Rangeland', value: 'Rangeland' },
    // { label: 'Rapid', value: 'Rapid' },
    // { label: 'Rare', value: 'Rare' },
    // { label: 'Real', value: 'Real' },
    // { label: 'Reality', value: 'Reality' },
    // { label: 'Realtor', value: 'Realtor' },
    // { label: 'Realty', value: 'Realty' },
    // { label: 'Receivable', value: 'Receivable' },
    // { label: 'Receiving', value: 'Receiving' },
    // { label: 'Recitalist', value: 'Recitalist' },
    // { label: 'Record', value: 'Record' },
    // { label: 'Records', value: 'Records' },
    // { label: 'Recovery', value: 'Recovery' },
    // { label: 'Recruit', value: 'Recruit' },
    // { label: 'Recruiter', value: 'Recruiter' },
    // { label: 'Recruiting', value: 'Recruiting' },
    // { label: 'Recruitment', value: 'Recruitment' },
    // { label: 'Redevelopment', value: 'Redevelopment' },
    // { label: 'Reduction', value: 'Reduction' },
    // { label: 'Region', value: 'Region' },
    // { label: 'Regional', value: 'Regional' },
    // { label: 'Registered', value: 'Registered' },
    // { label: 'Regression', value: 'Regression' },
    // { label: 'Regulatory', value: 'Regulatory' },
    // { label: 'Rehabilitation', value: 'Rehabilitation' },
    // { label: 'Relations', value: 'Relations' },
    // { label: 'Relationship', value: 'Relationship' },
    // { label: 'Release', value: 'Release' },
    // { label: 'Releases', value: 'Releases' },
    // { label: 'Remote', value: 'Remote' },
    // { label: 'Reporter', value: 'Reporter' },
    // { label: 'Reporting', value: 'Reporting' },
    // { label: 'Represenative', value: 'Represenative' },
    // { label: 'Representative', value: 'Representative' },
    // { label: 'Republic', value: 'Republic' },
    // { label: 'Research', value: 'Research' },
    // { label: 'Researcher', value: 'Researcher' },
    // { label: 'Reserve', value: 'Reserve' },
    // { label: 'Resident', value: 'Resident' },
    // { label: 'Residential', value: 'Residential' },
    // { label: 'Resolution', value: 'Resolution' },
    // { label: 'Resource', value: 'Resource' },
    // { label: 'Resources', value: 'Resources' },
    // { label: 'Respiratory', value: 'Respiratory' },
    // { label: 'Restructuring', value: 'Restructuring' },
    // { label: 'Retail', value: 'Retail' },
    // { label: 'Retirement', value: 'Retirement' },
    // { label: 'Revenue', value: 'Revenue' },
    // { label: 'Review', value: 'Review' },
    // { label: 'Rewards', value: 'Rewards' },
    // { label: 'Risk', value: 'Risk' },
    // { label: 'Rn', value: 'Rn' },
    // { label: 'Robert', value: 'Robert' },
    // { label: 'Rocket', value: 'Rocket' },
    // { label: 'Rodan', value: 'Rodan' },
    // { label: 'Rotors', value: 'Rotors' },
    // { label: 'Rs', value: 'Rs' },
    // { label: 'S', value: 'S' },
    // { label: 'Saas', value: 'Saas' },
    // { label: 'Safety', value: 'Safety' },
    // { label: 'Sales', value: 'Sales' },
    // { label: 'Sales)', value: 'Sales)' },
    // { label: 'Salesforce', value: 'Salesforce' },
    // { label: 'Salesman', value: 'Salesman' },
    // { label: 'Salesperson', value: 'Salesperson' },
    // { label: 'Samsung', value: 'Samsung' },
    // { label: 'Sap', value: 'Sap' },
    // { label: 'Satellite', value: 'Satellite' },
    // { label: 'Satisfaction', value: 'Satisfaction' },
    // { label: 'Scentsy', value: 'Scentsy' },
    // { label: 'Scheduler', value: 'Scheduler' },
    // { label: 'Scholar', value: 'Scholar' },
    // { label: 'School', value: 'School' },
    // { label: 'Science', value: 'Science' },
    // { label: 'Sciences', value: 'Sciences' },
    // { label: 'Scientific', value: 'Scientific' },
    // { label: 'Scientist', value: 'Scientist' },
    // { label: 'Scout', value: 'Scout' },
    // { label: 'Scrum', value: 'Scrum' },
    // { label: 'Sct', value: 'Sct' },
    // { label: 'Seafood', value: 'Seafood' },
    // { label: 'Search', value: 'Search' },
    // { label: 'Second', value: 'Second' },
    // { label: 'Secondary', value: 'Secondary' },
    // { label: 'Secretary', value: 'Secretary' },
    // { label: 'Section', value: 'Section' },
    // { label: 'Sector', value: 'Sector' },
    // { label: 'Security', value: 'Security' },
    // { label: 'Segment', value: 'Segment' },
    // { label: 'Select', value: 'Select' },
    // { label: 'Seller', value: 'Seller' },
    // { label: 'Semene', value: 'Semene' },
    // { label: 'Senate', value: 'Senate' },
    // { label: 'Senior', value: 'Senior' },
    // { label: 'Ser', value: 'Ser' },
    // { label: 'Sergeant', value: 'Sergeant' },
    // { label: 'Series', value: 'Series' },
    // { label: 'Server', value: 'Server' },
    // { label: 'Servic', value: 'Servic' },
    // { label: 'Service', value: 'Service' },
    // { label: 'Services', value: 'Services' },
    // { label: 'Servicing', value: 'Servicing' },
    // { label: 'Sessional', value: 'Sessional' },
    // { label: 'Shared', value: 'Shared' },
    // { label: 'Shareholder', value: 'Shareholder' },
    // { label: 'Sheriff', value: 'Sheriff' },
    // { label: 'Shift', value: 'Shift' },
    // { label: 'Shipping', value: 'Shipping' },
    // { label: 'Shire', value: 'Shire' },
    // { label: 'Show', value: 'Show' },
    // { label: 'Showroom', value: 'Showroom' },
    // { label: 'Signal', value: 'Signal' },
    // { label: 'Singletree', value: 'Singletree' },
    // { label: 'Site', value: 'Site' },
    // { label: 'Smyly', value: 'Smyly' },
    // { label: 'Soc', value: 'Soc' },
    // { label: 'Social', value: 'Social' },
    // { label: 'Society', value: 'Society' },
    // { label: 'Softball', value: 'Softball' },
    // { label: 'Software', value: 'Software' },
    // { label: 'Solution', value: 'Solution' },
    // { label: 'Solutions', value: 'Solutions' },
    // { label: 'Songwriter', value: 'Songwriter' },
    // { label: 'Sourcer', value: 'Sourcer' },
    // { label: 'Sourcing', value: 'Sourcing' },
    // { label: 'Sous', value: 'Sous' },
    // { label: 'South', value: 'South' },
    // { label: 'Space', value: 'Space' },
    // { label: 'Spd', value: 'Spd' },
    // { label: 'Special', value: 'Special' },
    // { label: 'Specialist', value: 'Specialist' },
    // { label: 'Speciality', value: 'Speciality' },
    // { label: 'Specialty', value: 'Specialty' },
    // { label: 'Speech', value: 'Speech' },
    // { label: 'Sponsor', value: 'Sponsor' },
    // { label: 'Spring', value: 'Spring' },
    // { label: 'Springfield', value: 'Springfield' },
    // { label: 'Sprinkler', value: 'Sprinkler' },
    // { label: 'Sqa', value: 'Sqa' },
    // { label: 'Sql', value: 'Sql' },
    // { label: 'Square', value: 'Square' },
    // { label: 'Stack', value: 'Stack' },
    // { label: 'Staff', value: 'Staff' },
    // { label: 'Staffing', value: 'Staffing' },
    // { label: 'Standards', value: 'Standards' },
    // { label: 'Starseed', value: 'Starseed' },
    // { label: 'State', value: 'State' },
    // { label: 'States', value: 'States' },
    // { label: 'Steward', value: 'Steward' },
    // { label: 'Stock', value: 'Stock' },
    // { label: 'Stocker', value: 'Stocker' },
    // { label: 'Stop', value: 'Stop' },
    // { label: 'Storage', value: 'Storage' },
    // { label: 'Store', value: 'Store' },
    // { label: 'Strategic', value: 'Strategic' },
    // { label: 'Strategies', value: 'Strategies' },
    // { label: 'Strategist', value: 'Strategist' },
    // { label: 'Strategy', value: 'Strategy' },
    // { label: 'Structural', value: 'Structural' },
    // { label: 'Structures', value: 'Structures' },
    // { label: 'Student', value: 'Student' },
    // { label: 'Students', value: 'Students' },
    // { label: 'Studio', value: 'Studio' },
    // { label: 'Studios', value: 'Studios' },
    // { label: 'Subcontractor', value: 'Subcontractor' },
    // { label: 'Substitute', value: 'Substitute' },
    // { label: 'Success', value: 'Success' },
    // { label: 'Summer', value: 'Summer' },
    // { label: 'Sunflower', value: 'Sunflower' },
    // { label: 'Superintendent', value: 'Superintendent' },
    // { label: 'Supervising', value: 'Supervising' },
    // { label: 'Supplier', value: 'Supplier' },
    // { label: 'Supply', value: 'Supply' },
    // { label: 'Support', value: 'Support' },
    // { label: 'Surg', value: 'Surg' },
    // { label: 'Surgeon', value: 'Surgeon' },
    // { label: 'Surgery', value: 'Surgery' },
    // { label: 'Surgical', value: 'Surgical' },
    // { label: 'Surveillance', value: 'Surveillance' },
    // { label: 'Survey', value: 'Survey' },
    // { label: 'Sustainability', value: 'Sustainability' },
    // { label: 'Svp', value: 'Svp' },
    // { label: 'Sw', value: 'Sw' },
    // { label: 'Switch', value: 'Switch' },
    // { label: 'Switchboard', value: 'Switchboard' },
    // { label: 'Sys', value: 'Sys' },
    // { label: 'System', value: 'System' },
    // { label: 'Systems', value: 'Systems' },
    // { label: 'Systems-Your', value: 'Systems-Your' },
    // { label: 'T', value: 'T' },
    // { label: 'Tacsat', value: 'Tacsat' },
    // { label: 'Tactical', value: 'Tactical' },
    // { label: 'Talent', value: 'Talent' },
    // { label: 'Tampa', value: 'Tampa' },
    // { label: 'Tax', value: 'Tax' },
    // { label: 'Teacher', value: 'Teacher' },
    // { label: 'Teaching', value: 'Teaching' },
    // { label: 'Team', value: 'Team' },
    // { label: 'Tec', value: 'Tec' },
    // { label: 'Tech', value: 'Tech' },
    // { label: 'Technical', value: 'Technical' },
    // { label: 'Technicial', value: 'Technicial' },
    // { label: 'Technician', value: 'Technician' },
    // { label: 'Technolo', value: 'Technolo' },
    // { label: 'Technologies', value: 'Technologies' },
    // { label: 'Technologist', value: 'Technologist' },
    // { label: 'Technology', value: 'Technology' },
    // { label: 'Teladoc', value: 'Teladoc' },
    // { label: 'Teladochealth', value: 'Teladochealth' },
    // { label: 'Telecom', value: 'Telecom' },
    // { label: 'Telecomm', value: 'Telecomm' },
    // { label: 'Telecommu', value: 'Telecommu' },
    // { label: 'Telecommunicat', value: 'Telecommunicat' },
    // { label: 'Telecommunication', value: 'Telecommunication' },
    // { label: 'Telecommunications', value: 'Telecommunications' },
    // { label: 'Telecommunicator', value: 'Telecommunicator' },
    // { label: 'Telemarketing', value: 'Telemarketing' },
    // { label: 'Telematics', value: 'Telematics' },
    // { label: 'Teller', value: 'Teller' },
    // { label: 'Tem', value: 'Tem' },
    // { label: 'Temp', value: 'Temp' },
    // { label: 'Term', value: 'Term' },
    // { label: 'Territory', value: 'Territory' },
    // { label: 'Test', value: 'Test' },
    // { label: 'Tested', value: 'Tested' },
    // { label: 'Tester', value: 'Tester' },
    // { label: 'Testing', value: 'Testing' },
    // { label: 'Texas', value: 'Texas' },
    // { label: 'The', value: 'The' },
    // { label: 'Therapist', value: 'Therapist' },
    // { label: 'Therapy', value: 'Therapy' },
    // { label: 'Third', value: 'Third' },
    // { label: 'Time', value: 'Time' },
    // { label: 'Timekeeping', value: 'Timekeeping' },
    // { label: 'Tizor', value: 'Tizor' },
    // { label: 'Tn', value: 'Tn' },
    // { label: 'To', value: 'To' },
    // { label: 'Tobacco', value: 'Tobacco' },
    // { label: 'Toddler', value: 'Toddler' },
    // { label: 'Tools', value: 'Tools' },
    // { label: 'Topic', value: 'Topic' },
    // { label: 'Total', value: 'Total' },
    // { label: 'Trade', value: 'Trade' },
    // { label: 'Trader', value: 'Trader' },
    // { label: 'Trades', value: 'Trades' },
    // { label: 'Traditional', value: 'Traditional' },
    // { label: 'Traffic', value: 'Traffic' },
    // { label: 'Trainee', value: 'Trainee' },
    // { label: 'Trainer', value: 'Trainer' },
    // { label: 'Training', value: 'Training' },
    // { label: 'Transcriptionist', value: 'Transcriptionist' },
    // { label: 'Transfer', value: 'Transfer' },
    // { label: 'Transition', value: 'Transition' },
    // { label: 'Transmission', value: 'Transmission' },
    // { label: 'Transmissions', value: 'Transmissions' },
    // { label: 'Transport', value: 'Transport' },
    // { label: 'Transportation', value: 'Transportation' },
    // { label: 'Trauma', value: 'Trauma' },
    // { label: 'Travel', value: 'Travel' },
    // { label: 'Treasurer', value: 'Treasurer' },
    // { label: 'Treasury', value: 'Treasury' },
    // { label: 'Trial', value: 'Trial' },
    // { label: 'Trials', value: 'Trials' },
    // { label: 'Trucks', value: 'Trucks' },
    // { label: 'Trust', value: 'Trust' },
    // { label: 'Trusted', value: 'Trusted' },
    // { label: 'Trustee', value: 'Trustee' },
    // { label: 'Trustees', value: 'Trustees' },
    // { label: 'Trxade', value: 'Trxade' },
    // { label: 'Tutor', value: 'Tutor' },
    // { label: 'Ucsf', value: 'Ucsf' },
    // { label: 'Udg', value: 'Udg' },
    // { label: 'Uk', value: 'Uk' },
    // { label: 'Ultrasound', value: 'Ultrasound' },
    // { label: 'Undergraduate', value: 'Undergraduate' },
    // { label: 'Underwriter', value: 'Underwriter' },
    // { label: 'Underwriting', value: 'Underwriting' },
    // { label: 'Unified', value: 'Unified' },
    // { label: 'Union', value: 'Union' },
    // { label: 'Unit', value: 'Unit' },
    // { label: 'United', value: 'United' },
    // { label: 'Universal', value: 'Universal' },
    // { label: 'University', value: 'University' },
    // { label: 'Upgrades', value: 'Upgrades' },
    // { label: 'Us', value: 'Us' },
    // { label: 'Usa', value: 'Usa' },
    // { label: 'Uscg', value: 'Uscg' },
    // { label: 'Usec', value: 'Usec' },
    // { label: 'User', value: 'User' },
    // { label: 'Utility', value: 'Utility' },
    // { label: 'Utilization', value: 'Utilization' },
    // { label: 'Uw', value: 'Uw' },
    // { label: 'V', value: 'V' },
    // { label: 'Va', value: 'Va' },
    // { label: 'Vaccines', value: 'Vaccines' },
    // { label: 'Validation', value: 'Validation' },
    // { label: 'Valle', value: 'Valle' },
    // { label: 'Value', value: 'Value' },
    // { label: 'Vascular', value: 'Vascular' },
    // { label: 'Vemma', value: 'Vemma' },
    // { label: 'Vendor', value: 'Vendor' },
    // { label: 'Venture', value: 'Venture' },
    // { label: 'Ventures', value: 'Ventures' },
    // { label: 'Veterans', value: 'Veterans' },
    // { label: 'Veterinary', value: 'Veterinary' },
    // { label: 'Via', value: 'Via' },
    // { label: 'Video', value: 'Video' },
    // { label: 'Videographer', value: 'Videographer' },
    // { label: 'Village', value: 'Village' },
    // { label: 'Vinyl', value: 'Vinyl' },
    // { label: 'Virtual', value: 'Virtual' },
    // { label: 'Virtualization', value: 'Virtualization' },
    // { label: 'Visionary', value: 'Visionary' },
    // { label: 'Visionix', value: 'Visionix' },
    // { label: 'Visits', value: 'Visits' },
    // { label: 'Visual', value: 'Visual' },
    // { label: 'Volleyball', value: 'Volleyball' },
    // { label: 'Volunteer', value: 'Volunteer' },
    // { label: 'Volvo', value: 'Volvo' },
    // { label: 'Vr', value: 'Vr' },
    // { label: 'W', value: 'W' },
    // { label: 'Waiter', value: 'Waiter' },
    // { label: 'Waitress', value: 'Waitress' },
    // { label: 'Warden', value: 'Warden' },
    // { label: 'Warehouse', value: 'Warehouse' },
    // { label: 'Warehousing', value: 'Warehousing' },
    // { label: 'Washer', value: 'Washer' },
    // { label: 'Wasser', value: 'Wasser' },
    // { label: 'Water', value: 'Water' },
    // { label: 'Wealth', value: 'Wealth' },
    // { label: 'Web', value: 'Web' },
    // { label: 'Webmaster', value: 'Webmaster' },
    // { label: 'Website', value: 'Website' },
    // { label: 'Weeks', value: 'Weeks' },
    // { label: 'Welfare', value: 'Welfare' },
    // { label: 'Well', value: 'Well' },
    // { label: 'Wellness', value: 'Wellness' },
    // { label: 'West', value: 'West' },
    // { label: 'Western', value: 'Western' },
    // { label: 'Westpac', value: 'Westpac' },
    // { label: 'White', value: 'White' },
    // { label: 'Wind', value: 'Wind' },
    // { label: 'Wireless', value: 'Wireless' },
    // { label: 'Wisconsin', value: 'Wisconsin' },
    // { label: 'With', value: 'With' },
    // { label: 'Wordpress', value: 'Wordpress' },
    // { label: 'Work', value: 'Work' },
    // { label: 'Worker', value: 'Worker' },
    // { label: 'Workers', value: 'Workers' },
    // { label: 'Workforce', value: 'Workforce' },
    // { label: 'Workplace', value: 'Workplace' },
    // { label: 'Worldwide', value: 'Worldwide' },
    // { label: 'Woven', value: 'Woven' },
    // { label: 'Writer', value: 'Writer' },
    // { label: 'Writing', value: 'Writing' },
    // { label: 'York', value: 'York' },
    // { label: 'Young', value: 'Young' },
    // { label: 'Youth', value: 'Youth' },
    // { label: 'Zelis', value: 'Zelis' },
    
//   ];

//   const filteredSelectedKeyword = selectedKeyword.filter(keyword => keyword !== null && keyword !== undefined);

//   return (
//     <div>
//       <Select
//         isMulti
//         value={filteredSelectedKeyword}
//         onChange={handleSelectChange}
//         options={keywordOptions}
//         placeholder="Select Keywords..."
//         components={makeAnimated()}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default Keyword;



// Keyword.js
// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const Keyword = ({ selectedKeyword, handleSelectChange }) => {
//   const keywordOptions = [
//     { label: 'Abm', value: 'Abm' },
//     { label: 'Absolute', value: 'Absolute' },
//     { label: 'Academic', value: 'Academic' },
//     { label: 'Acceleration', value: 'Acceleration' },
//     { label: 'Access', value: 'Access' },
//     { label: 'Accident', value: 'Accident' },
//     { label: 'Accompanist', value: 'Accompanist' },
//     { label: 'Account', value: 'Account' },
//     { label: 'Accountability', value: 'Accountability' },
//     { label: 'Accountant', value: 'Accountant' },
//     { label: 'Accountantoffice', value: 'Accountantoffice' },
//     { label: 'Accounting', value: 'Accounting' },
//     { label: 'Accounts', value: 'Accounts' },
//     { label: 'Achieving', value: 'Achieving' },
//     { label: 'Acquisition', value: 'Acquisition' },
//     { label: 'Acquisitions', value: 'Acquisitions' },
//     { label: 'Acting', value: 'Acting' },
//     { label: 'Activation', value: 'Activation' },
//     { label: 'Active', value: 'Active' },
//     { label: 'Activity', value: 'Activity' },
//     { label: 'Actor', value: 'Actor' },
//     { label: 'Actuarial', value: 'Actuarial' },
//     { label: 'Actuary', value: 'Actuary' },
//     { label: 'Acute', value: 'Acute' },
//     { label: 'Adin', value: 'Adin' },
//     { label: 'Adjunct', value: 'Adjunct' },
//     { label: 'Adjuster', value: 'Adjuster' },
//     { label: 'Administration', value: 'Administration' },
//     { label: 'Administrative', value: 'Administrative' },
//     { label: 'Administrator', value: 'Administrator' },
//     { label: 'Admissions', value: 'Admissions' },
//     { label: 'Adult', value: 'Adult' },
//     { label: 'Advanced', value: 'Advanced' },
//     { label: 'Advertisement', value: 'Advertisement' },
//     { label: 'Advertising', value: 'Advertising' },
//     { label: 'Advi', value: 'Advi' },
//     { label: 'Advisor', value: 'Advisor' },
//     { label: 'Advisory', value: 'Advisory' },
//     { label: 'Advocacy', value: 'Advocacy' },
//     { label: 'Advocate', value: 'Advocate' },
//     { label: 'Aep', value: 'Aep' },
//     { label: 'Aerospace', value: 'Aerospace' },
//     { label: 'Aetna', value: 'Aetna' },
//     { label: 'Affairs', value: 'Affairs' },
//     { label: 'Affiliate', value: 'Affiliate' },
//     { label: 'Agen', value: 'Agen' },
//     { label: 'Agency', value: 'Agency' },
//     { label: 'Agent', value: 'Agent' },
//     { label: 'Agile', value: 'Agile' },
//     { label: 'Agricultural', value: 'Agricultural' },
//     { label: 'Aide', value: 'Aide' },
//     { label: 'Air', value: 'Air' },
//     { label: 'Aircraft', value: 'Aircraft' },
//     { label: 'Ait', value: 'Ait' },
//     { label: 'Alabama', value: 'Alabama' },
//     { label: 'Allegro', value: 'Allegro' },
//     { label: 'Alliance', value: 'Alliance' },
//     { label: 'Alliances', value: 'Alliances' },
//     { label: 'Allied', value: 'Allied' },
//     { label: 'Alpa', value: 'Alpa' },
//     { label: 'Alternative', value: 'Alternative' },
//     { label: 'Alumni', value: 'Alumni' },
//     { label: 'Amazon', value: 'Amazon' },
//     { label: 'Ambassador', value: 'Ambassador' },
//     { label: 'America', value: 'America' },
//     { label: 'American', value: 'American' },
//     { label: 'Americas', value: 'Americas' },
//     { label: 'Amoa', value: 'Amoa' },
//     { label: 'An', value: 'An' },
//     { label: 'Analysis', value: 'Analysis' },
//     { label: 'Analysist', value: 'Analysist' },
//     { label: 'Analyst', value: 'Analyst' },
//     { label: 'Analytical', value: 'Analytical' },
//     { label: 'Analytics', value: 'Analytics' },
//     { label: 'And', value: 'And' },
//     { label: 'Anesthesia', value: 'Anesthesia' },
//     { label: 'Anesthesiology', value: 'Anesthesiology' },
//     { label: 'Animal', value: 'Animal' },
//     { label: 'Annuity', value: 'Annuity' },
//     { label: 'Anrp', value: 'Anrp' },
//     { label: 'Apa', value: 'Apa' },
//     { label: 'Apg', value: 'Apg' },
//     { label: 'Application', value: 'Application' },
//     { label: 'Applications', value: 'Applications' },
//     { label: 'Appraiser', value: 'Appraiser' },
//     { label: 'Approval', value: 'Approval' },
//     { label: 'Apps', value: 'Apps' },
//     { label: 'Arborist', value: 'Arborist' },
//     { label: 'Architect', value: 'Architect' },
//     { label: 'Architectural', value: 'Architectural' },
//     { label: 'Architecture', value: 'Architecture' },
//     { label: 'Architect-Workplace', value: 'Architect-Workplace' },
//     { label: 'Archival', value: 'Archival' },
//     { label: 'Archives', value: 'Archives' },
//     { label: 'Archivist', value: 'Archivist' },
//     { label: 'Area', value: 'Area' },
//     { label: 'Argos', value: 'Argos' },
//     { label: 'Armadillo', value: 'Armadillo' },
//     { label: 'Armed', value: 'Armed' },
//     { label: 'Army', value: 'Army' },
//     { label: 'Art', value: 'Art' },
//     { label: 'Artist', value: 'Artist' },
//     { label: 'As', value: 'As' },
//     { label: 'Assembly', value: 'Assembly' },
//     { label: 'Assessment', value: 'Assessment' },
//     { label: 'Asset', value: 'Asset' },
//     { label: 'Assistant', value: 'Assistant' },
//     { label: 'Assistingcoding', value: 'Assistingcoding' },
//     { label: 'Assistive', value: 'Assistive' },
//     { label: 'Associate', value: 'Associate' },
//     { label: 'Associatedirector', value: 'Associatedirector' },
//     { label: 'Association', value: 'Association' },
//     { label: 'Assurance', value: 'Assurance' },
//     { label: 'At', value: 'At' },
//     { label: 'Athletic', value: 'Athletic' },
//     { label: 'Atlantic', value: 'Atlantic' },
//     { label: 'Attorney', value: 'Attorney' },
//     { label: 'Attorneys', value: 'Attorneys' },
//     { label: 'Audi', value: 'Audi' },
//     { label: 'Audio', value: 'Audio' },
//     { label: 'Audit', value: 'Audit' },
//     { label: 'Auditor', value: 'Auditor' },
//     { label: 'Audits', value: 'Audits' },
//     { label: 'Austin', value: 'Austin' },
//     { label: 'Author', value: 'Author' },
//     { label: 'Auto', value: 'Auto' },
//     { label: 'Automation', value: 'Automation' },
//     { label: 'Automotive', value: 'Automotive' },
//     { label: 'Aviation', value: 'Aviation' },
//     { label: 'Avidwireless', value: 'Avidwireless' },
//     { label: 'Avionics', value: 'Avionics' },
//     { label: 'Avp', value: 'Avp' },
//     { label: 'Award', value: 'Award' },
//     { label: 'B', value: 'B' },
//     { label: 'Baker', value: 'Baker' },
//     { label: 'Bakery', value: 'Bakery' },
//     { label: 'Bank', value: 'Bank' },
//     { label: 'Banker', value: 'Banker' },
//     { label: 'Banking', value: 'Banking' },
//     { label: 'Baptist', value: 'Baptist' },
//     { label: 'Barista', value: 'Barista' },
//     { label: 'Bas', value: 'Bas' },
//     { label: 'Base', value: 'Base' },
//     { label: 'Based', value: 'Based' },
//     { label: 'Basis', value: 'Basis' },
//     { label: 'Bassett', value: 'Bassett' },
//     { label: 'Bde', value: 'Bde' },
//     { label: 'Beauty', value: 'Beauty' },
//     { label: 'Behavior', value: 'Behavior' },
//     { label: 'Behavioral', value: 'Behavioral' },
//     { label: 'Being', value: 'Being' },
//     { label: 'Benefit', value: 'Benefit' },
//     { label: 'Benefits', value: 'Benefits' },
//     { label: 'Big', value: 'Big' },
//     { label: 'Bill', value: 'Bill' },
//     { label: 'Billabong', value: 'Billabong' },
//     { label: 'Biller', value: 'Biller' },
//     { label: 'Billing', value: 'Billing' },
//     { label: 'Bioanalytical', value: 'Bioanalytical' },
//     { label: 'Biological', value: 'Biological' },
//     { label: 'Biology', value: 'Biology' },
//     { label: 'Biomedical', value: 'Biomedical' },
//     { label: 'Biopharma', value: 'Biopharma' },
//     { label: 'Biosafety', value: 'Biosafety' },
//     { label: 'Biospheric', value: 'Biospheric' },
//     { label: 'Biostatistics', value: 'Biostatistics' },
//     { label: 'Biotech', value: 'Biotech' },
//     { label: 'Black', value: 'Black' },
//     { label: 'Bleached', value: 'Bleached' },
//     { label: 'Blood', value: 'Blood' },
//     { label: 'Bluelab', value: 'Bluelab' },
//     { label: 'Bn', value: 'Bn' },
//     { label: 'Board', value: 'Board' },
//     { label: 'Bobbi', value: 'Bobbi' },
//     { label: 'Bodily', value: 'Bodily' },
//     { label: 'Body', value: 'Body' },
//     { label: 'Book', value: 'Book' },
//     { label: 'Bookkeeper', value: 'Bookkeeper' },
//     { label: 'Bottle', value: 'Bottle' },
//     { label: 'Bottlewasher', value: 'Bottlewasher' },
//     { label: 'Boutique', value: 'Boutique' },
//     { label: 'Bpo', value: 'Bpo' },
//     { label: 'Brain', value: 'Brain' },
//     { label: 'Branch', value: 'Branch' },
//     { label: 'Brand', value: 'Brand' },
//     { label: 'Branding', value: 'Branding' },
//     { label: 'Brands', value: 'Brands' },
//     { label: 'Brian', value: 'Brian' },
//     { label: 'Briot', value: 'Briot' },
//     { label: 'Broadcast', value: 'Broadcast' },
//     { label: 'Broadcasting', value: 'Broadcasting' },
//     { label: 'Broker', value: 'Broker' },
//     { label: 'Brown', value: 'Brown' },
//     { label: 'Bsa', value: 'Bsa' },
//     { label: 'Budget', value: 'Budget' },
//     { label: 'Build', value: 'Build' },
//     { label: 'Builder', value: 'Builder' },
//     { label: 'Building', value: 'Building' },
//     { label: 'Bureau', value: 'Bureau' },
//     { label: 'Bus', value: 'Bus' },
//     { label: 'Business', value: 'Business' },
//     { label: 'Buyer', value: 'Buyer' },
//     { label: 'Buyingproduction', value: 'Buyingproduction' },
//     { label: 'C#', value: 'C#' },
//     { label: 'Cad', value: 'Cad' },
//     { label: 'Call', value: 'Call' },
//     { label: 'Camp', value: 'Camp' },
//     { label: 'Campaign', value: 'Campaign' },
//     { label: 'Campus', value: 'Campus' },
//     { label: 'Capital', value: 'Capital' },
//     { label: 'Captain', value: 'Captain' },
//     { label: 'Cardiac', value: 'Cardiac' },
//     { label: 'Cardiology', value: 'Cardiology' },
//     { label: 'Care', value: 'Care' },
//     { label: 'Carecredit', value: 'Carecredit' },
//     { label: 'Career', value: 'Career' },
//     { label: 'Carolina', value: 'Carolina' },
//     { label: 'Carpenter', value: 'Carpenter' },
//     { label: 'Case', value: 'Case' },
//     { label: 'Cashier', value: 'Cashier' },
//     { label: 'Cass', value: 'Cass' },
//     { label: 'Cath', value: 'Cath' },
//     { label: 'Catherization', value: 'Catherization' },
//     { label: 'Catheterization', value: 'Catheterization' },
//     { label: 'Cbo', value: 'Cbo' },
//     { label: 'Ccdo', value: 'Ccdo' },
//     { label: 'Cch', value: 'Cch' },
//     { label: 'Cco', value: 'Cco' },
//     { label: 'Cdo', value: 'Cdo' },
//     { label: 'Center', value: 'Center' },
//     { label: 'Centered', value: 'Centered' },
//     { label: 'Central', value: 'Central' },
//     { label: 'Ceo/Training', value: 'Ceo/Training' },
//     { label: 'Certificate', value: 'Certificate' },
//     { label: 'Certified', value: 'Certified' },
//     { label: 'Chain', value: 'Chain' },
//     { label: 'Chair', value: 'Chair' },
//     { label: 'Chancellor', value: 'Chancellor' },
//     { label: 'Change', value: 'Change' },
//     { label: 'Channel', value: 'Channel' },
//     { label: 'Channels', value: 'Channels' },
//     { label: 'Chapter', value: 'Chapter' },
//     { label: 'Charge', value: 'Charge' },
//     { label: 'Charles', value: 'Charles' },
//     { label: 'Chef', value: 'Chef' },
//     { label: 'Chemical', value: 'Chemical' },
//     { label: 'Chemist', value: 'Chemist' },
//     { label: 'Chemistry', value: 'Chemistry' },
//     { label: 'Chemometrician', value: 'Chemometrician' },
//     { label: 'Chevrolet', value: 'Chevrolet' },
//     { label: 'China', value: 'China' },
//     { label: 'Christi', value: 'Christi' },
//     { label: 'City', value: 'City' },
//     { label: 'Civil', value: 'Civil' },
//     { label: 'Claim', value: 'Claim' },
//     { label: 'Claims', value: 'Claims' },
//     { label: 'Class', value: 'Class' },
//     { label: 'Clayton', value: 'Clayton' },
//     { label: 'Clearcase', value: 'Clearcase' },
//     { label: 'Clements', value: 'Clements' },
//     { label: 'Clerk', value: 'Clerk' },
//     { label: 'Client', value: 'Client' },
//     { label: 'Clinic', value: 'Clinic' },
//     { label: 'Clinical', value: 'Clinical' },
//     { label: 'Clinicals', value: 'Clinicals' },
//     { label: 'Clinician', value: 'Clinician' },
//     { label: 'Closer', value: 'Closer' },
//     { label: 'Cloud', value: 'Cloud' },
//     { label: 'Club', value: 'Club' },
//     { label: 'Cluster', value: 'Cluster' },
//     { label: 'Cmsd', value: 'Cmsd' },
//     { label: 'Cna', value: 'Cna' },
//     { label: 'Cno', value: 'Cno' },
//     { label: 'Co', value: 'Co' },
//     { label: 'Coach', value: 'Coach' },
//     { label: 'Coaching', value: 'Coaching' },
//     { label: 'Coast', value: 'Coast' },
//     { label: 'Code', value: 'Code' },
//     { label: 'Coder', value: 'Coder' },
//     { label: 'Coding', value: 'Coding' },
//     { label: 'Coe', value: 'Coe' },
//     { label: 'Cofounder', value: 'Cofounder' },
//     { label: 'Co-Founder', value: 'Co-Founder' },
//     { label: 'Collaborators', value: 'Collaborators' },
//     { label: 'Collections', value: 'Collections' },
//     { label: 'College', value: 'College' },
//     { label: 'Colonies', value: 'Colonies' },
//     { label: 'Com', value: 'Com' },
//     { label: 'Commercial', value: 'Commercial' },
//     { label: 'Commissioner', value: 'Commissioner' },
//     { label: 'Commissioners', value: 'Commissioners' },
//     { label: 'Committee', value: 'Committee' },
//     { label: 'Committees', value: 'Committees' },
//     { label: 'Commodity', value: 'Commodity' },
//     { label: 'Commonwealth', value: 'Commonwealth' },
//     { label: 'Communication', value: 'Communication' },
//     { label: 'Communications', value: 'Communications' },
//     { label: 'Community', value: 'Community' },
//     { label: 'Companies', value: 'Companies' },
//     { label: 'Company', value: 'Company' },
//     { label: 'Compensation', value: 'Compensation' },
//     { label: 'Complete', value: 'Complete' },
//     { label: 'Compliance', value: 'Compliance' },
//     { label: 'Component', value: 'Component' },
//     { label: 'Computer', value: 'Computer' },
//     { label: 'Concepts', value: 'Concepts' },
//     { label: 'Concert', value: 'Concert' },
//     { label: 'Conduct', value: 'Conduct' },
//     { label: 'Conductor', value: 'Conductor' },
//     { label: 'Coney', value: 'Coney' },
//     { label: 'Conference', value: 'Conference' },
//     { label: 'Configuration', value: 'Configuration' },
//     { label: 'Connection', value: 'Connection' },
//     { label: 'Connectivity', value: 'Connectivity' },
//     { label: 'Conservation', value: 'Conservation' },
//     { label: 'Construction', value: 'Construction' },
//     { label: 'Consultant', value: 'Consultant' },
//     { label: 'Consultants', value: 'Consultants' },
//     { label: 'Consulting', value: 'Consulting' },
//     { label: 'Consumable', value: 'Consumable' },
//     { label: 'Consumer', value: 'Consumer' },
//     { label: 'Content', value: 'Content' },
//     { label: 'Continuous', value: 'Continuous' },
//     { label: 'Contract', value: 'Contract' },
//     { label: 'Contracting', value: 'Contracting' },
//     { label: 'Contractor', value: 'Contractor' },
//     { label: 'Contractor-Quality', value: 'Contractor-Quality' },
//     { label: 'Contractors', value: 'Contractors' },
//     { label: 'Contracts', value: 'Contracts' },
//     { label: 'Control', value: 'Control' },
//     { label: 'Controller', value: 'Controller' },
//     { label: 'Controls', value: 'Controls' },
//     { label: 'Conversion', value: 'Conversion' },
//     { label: 'Cook', value: 'Cook' },
//     { label: 'Cookie', value: 'Cookie' },
//     { label: 'Coordinator', value: 'Coordinator' },
//     { label: 'Copc', value: 'Copc' },
//     { label: 'Copy', value: 'Copy' },
//     { label: 'Copywriter', value: 'Copywriter' },
//     { label: 'Cornerstone', value: 'Cornerstone' },
//     { label: 'Corp', value: 'Corp' },
//     { label: 'Corp)', value: 'Corp)' },
//     { label: 'Corporate', value: 'Corporate' },
//     { label: 'Correctional', value: 'Correctional' },
//     { label: 'Cost', value: 'Cost' },
//     { label: 'Council', value: 'Council' },
//     { label: 'Counsel', value: 'Counsel' },
//     { label: 'Counselor', value: 'Counselor' },
//     { label: 'Counter', value: 'Counter' },
//     { label: 'Country', value: 'Country' },
//     { label: 'County', value: 'County' },
//     { label: 'Court', value: 'Court' },
//     { label: 'Courtroom', value: 'Courtroom' },
//     { label: 'Covid', value: 'Covid' },
//     { label: 'Cpep', value: 'Cpep' },
//     { label: 'Cpl', value: 'Cpl' },
//     { label: 'Cpo', value: 'Cpo' },
//     { label: 'Cqo', value: 'Cqo' },
//     { label: 'Crawford', value: 'Crawford' },
//     { label: 'Creative', value: 'Creative' },
//     { label: 'Credit', value: 'Credit' },
//     { label: 'Crestech', value: 'Crestech' },
//     { label: 'Crew', value: 'Crew' },
//     { label: 'Critical', value: 'Critical' },
//     { label: 'Crm', value: 'Crm' },
//     { label: 'Cross', value: 'Cross' },
//     { label: 'Crs', value: 'Crs' },
//     { label: 'Cs', value: 'Cs' },
//     { label: 'Csa', value: 'Csa' },
//     { label: 'Csr', value: 'Csr' },
//     { label: 'Csv', value: 'Csv' },
//     { label: 'Ct', value: 'Ct' },
//     { label: 'Culture', value: 'Culture' },
//     { label: 'Current', value: 'Current' },
//     { label: 'Curriculum', value: 'Curriculum' },
//     { label: 'Custodian', value: 'Custodian' },
//     { label: 'Customer', value: 'Customer' },
//     { label: 'Cvs', value: 'Cvs' },
//     { label: 'Cx', value: 'Cx' },
//     { label: 'Cyber', value: 'Cyber' },
//     { label: 'Cybersecurity', value: 'Cybersecurity' },
//     { label: 'Cycle', value: 'Cycle' },
//     { label: 'D', value: 'D' },
//     { label: 'Damage', value: 'Damage' },
//     { label: 'Data', value: 'Data' },
//     { label: 'Database', value: 'Database' },
//     { label: 'Dba', value: 'Dba' },
//     { label: 'Dc', value: 'Dc' },
//     { label: 'De', value: 'De' },
//     { label: 'Dean', value: 'Dean' },
//     { label: 'Decisions', value: 'Decisions' },
//     { label: 'Defense', value: 'Defense' },
//     { label: 'Del', value: 'Del' },
//     { label: 'Deli', value: 'Deli' },
//     { label: 'Delivered', value: 'Delivered' },
//     { label: 'Delivery', value: 'Delivery' },
//     { label: 'Demand', value: 'Demand' },
//     { label: 'Department', value: 'Department' },
//     { label: 'Dept', value: 'Dept' },
//     { label: 'Deputy', value: 'Deputy' },
//     { label: 'Derm', value: 'Derm' },
//     { label: 'Design', value: 'Design' },
//     { label: 'Designated', value: 'Designated' },
//     { label: 'Designer', value: 'Designer' },
//     { label: 'Desk', value: 'Desk' },
//     { label: 'Desktop', value: 'Desktop' },
//     { label: 'Desmond', value: 'Desmond' },
//     { label: 'Detailer', value: 'Detailer' },
//     { label: 'Detective', value: 'Detective' },
//     { label: 'Developement', value: 'Developement' },
//     { label: 'Developer', value: 'Developer' },
//     { label: 'Development', value: 'Development' },
//     { label: 'Development1', value: 'Development1' },
//     { label: 'Developmentquality', value: 'Developmentquality' },
//     { label: 'Device', value: 'Device' },
//     { label: 'Devices', value: 'Devices' },
//     { label: 'Devops', value: 'Devops' },
//     { label: 'Dhs', value: 'Dhs' },
//     { label: 'Diagnostic', value: 'Diagnostic' },
//     { label: 'Diener', value: 'Diener' },
//     { label: 'Diesel', value: 'Diesel' },
//     { label: 'Dietitian', value: 'Dietitian' },
//     { label: 'Digital', value: 'Digital' },
//     { label: 'Digitization', value: 'Digitization' },
//     { label: 'Dilisi', value: 'Dilisi' },
//     { label: 'Direct', value: 'Direct' },
//     { label: 'Direction', value: 'Direction' },
//     { label: 'Disability', value: 'Disability' },
//     { label: 'Disease', value: 'Disease' },
//     { label: 'Disorders', value: 'Disorders' },
//     { label: 'Distinguished', value: 'Distinguished' },
//     { label: 'Distribution', value: 'Distribution' },
//     { label: 'Distributor', value: 'Distributor' },
//     { label: 'District', value: 'District' },
//     { label: 'Diversegy', value: 'Diversegy' },
//     { label: 'Diversity', value: 'Diversity' },
//     { label: 'Division', value: 'Division' },
//     { label: 'Divisional', value: 'Divisional' },
//     { label: 'Divison', value: 'Divison' },
//     { label: 'Doctor', value: 'Doctor' },
//     { label: 'Documents', value: 'Documents' },
//     { label: 'Donor', value: 'Donor' },
//     { label: 'Drafter', value: 'Drafter' },
//     { label: 'Draftsman', value: 'Draftsman' },
//     { label: 'Dragon', value: 'Dragon' },
//     { label: 'Drilling', value: 'Drilling' },
//     { label: 'Driveline', value: 'Driveline' },
//     { label: 'Driver', value: 'Driver' },
//     { label: 'Drug', value: 'Drug' },
//     { label: 'Dt', value: 'Dt' },
//     { label: 'Duluth', value: 'Duluth' },
//     { label: 'Duty', value: 'Duty' },
//     { label: 'Dvp', value: 'Dvp' },
//     { label: 'Dynamics', value: 'Dynamics' },
//     { label: 'E', value: 'E' },
//     { label: 'E3', value: 'E3' },
//     { label: 'Early', value: 'Early' },
//     { label: 'Earth', value: 'Earth' },
//     { label: 'East', value: 'East' },
//     { label: 'Ebs', value: 'Ebs' },
//     { label: 'Ecomm', value: 'Ecomm' },
//     { label: 'Ecommerce', value: 'Ecommerce' },
//     { label: 'Economic', value: 'Economic' },
//     { label: 'Economics', value: 'Economics' },
//     { label: 'Economist', value: 'Economist' },
//     { label: 'Editor', value: 'Editor' },
//     { label: 'Editorial', value: 'Editorial' },
//     { label: 'Education', value: 'Education' },
//     { label: 'Educational', value: 'Educational' },
//     { label: 'Educator', value: 'Educator' },
//     { label: 'Effectiveness', value: 'Effectiveness' },
//     { label: 'Elc', value: 'Elc' },
//     { label: 'Electrical', value: 'Electrical' },
//     { label: 'Electrician', value: 'Electrician' },
//     { label: 'Electro', value: 'Electro' },
//     { label: 'Electronic', value: 'Electronic' },
//     { label: 'Electronics', value: 'Electronics' },
//     { label: 'Elementary', value: 'Elementary' },
//     { label: 'Ell', value: 'Ell' },
//     { label: 'Elt', value: 'Elt' },
//     { label: 'Emd', value: 'Emd' },
//     { label: 'Emea', value: 'Emea' },
//     { label: 'Emergency', value: 'Emergency' },
//     { label: 'Emerging', value: 'Emerging' },
//     { label: 'Emeritus', value: 'Emeritus' },
//     { label: 'Emory', value: 'Emory' },
//     { label: 'Employee', value: 'Employee' },
//     { label: 'Employment', value: 'Employment' },
//     { label: 'Emr', value: 'Emr' },
//     { label: 'Ems', value: 'Ems' },
//     { label: 'Enablement', value: 'Enablement' },
//     { label: 'Enabler', value: 'Enabler' },
//     { label: 'End', value: 'End' },
//     { label: 'Endowment', value: 'Endowment' },
//     { label: 'Energy', value: 'Energy' },
//     { label: 'Enforcement', value: 'Enforcement' },
//     { label: 'Eng', value: 'Eng' },
//     { label: 'Engagement', value: 'Engagement' },
//     { label: 'Engeeniring', value: 'Engeeniring' },
//     { label: 'Engeneer', value: 'Engeneer' },
//     { label: 'Engieer', value: 'Engieer' },
//     { label: 'Engine', value: 'Engine' },
//     { label: 'Enginear', value: 'Enginear' },
//     { label: 'Enginee', value: 'Enginee' },
//     { label: 'Engineeer', value: 'Engineeer' },
//     { label: 'Engineer', value: 'Engineer' },
//     { label: 'Engineering', value: 'Engineering' },
//     { label: 'Engineerng', value: 'Engineerng' },
//     { label: 'Engineerproject', value: 'Engineerproject' },
//     { label: 'Engineers', value: 'Engineers' },
//     { label: 'Engineet', value: 'Engineet' },
//     { label: 'Enginer', value: 'Enginer' },
//     { label: 'Enginerr', value: 'Enginerr' },
//     { label: 'Enginner', value: 'Enginner' },
//     { label: 'English', value: 'English' },
//     { label: 'Enrollment', value: 'Enrollment' },
//     { label: 'Ent', value: 'Ent' },
//     { label: 'Enterprise', value: 'Enterprise' },
//     { label: 'Enthusiast', value: 'Enthusiast' },
//     { label: 'Entry', value: 'Entry' },
//     { label: 'Environment', value: 'Environment' },
//     { label: 'Environmental', value: 'Environmental' },
//     { label: 'Equal', value: 'Equal' },
//     { label: 'Equity', value: 'Equity' },
//     { label: 'Er', value: 'Er' },
//     { label: 'Erp', value: 'Erp' },
//     { label: 'Escrow', value: 'Escrow' },
//     { label: 'Espn', value: 'Espn' },
//     { label: 'Estate', value: 'Estate' },
//     { label: 'Estimator', value: 'Estimator' },
//     { label: 'Ethics', value: 'Ethics' },
//     { label: 'Etl', value: 'Etl' },
//     { label: 'Eugene', value: 'Eugene' },
//     { label: 'Europe', value: 'Europe' },
//     { label: 'European', value: 'European' },
//     { label: 'Evaluator', value: 'Evaluator' },
//     { label: 'Evangelist', value: 'Evangelist' },
//     { label: 'Event', value: 'Event' },
//     { label: 'Events', value: 'Events' },
//     { label: 'Evidence-Based', value: 'Evidence-Based' },
//     { label: 'Evraz', value: 'Evraz' },
//     { label: 'Examiner', value: 'Examiner' },
//     { label: 'Excellence', value: 'Excellence' },
//     { label: 'Executive', value: 'Executive' },
//     { label: 'Executives', value: 'Executives' },
//     { label: 'Exellence', value: 'Exellence' },
//     { label: 'Expeditor', value: 'Expeditor' },
//     { label: 'Experience', value: 'Experience' },
//     { label: 'Experienced', value: 'Experienced' },
//     { label: 'Expert', value: 'Expert' },
//     { label: 'Extern', value: 'Extern' },
//     { label: 'External', value: 'External' },
//     { label: 'Faa', value: 'Faa' },
//     { label: 'Facilitator', value: 'Facilitator' },
//     { label: 'Facilities', value: 'Facilities' },
//     { label: 'Facility', value: 'Facility' },
//     { label: 'Faculty', value: 'Faculty' },
//     { label: 'Fam0', value: 'Fam0' },
//     { label: 'Family', value: 'Family' },
//     { label: 'Fanatics', value: 'Fanatics' },
//     { label: 'Fastpitch', value: 'Fastpitch' },
//     { label: 'Federal', value: 'Federal' },
//     { label: 'Fellow', value: 'Fellow' },
//     { label: 'Fenix', value: 'Fenix' },
//     { label: 'Fico', value: 'Fico' },
//     { label: 'Field', value: 'Field' },
//     { label: 'Fields', value: 'Fields' },
//     { label: 'File', value: 'File' },
//     { label: 'Fill', value: 'Fill' },
//     { label: 'Finance', value: 'Finance' },
//     { label: 'Financial', value: 'Financial' },
//     { label: 'Fire', value: 'Fire' },
//     { label: 'Firearms', value: 'Firearms' },
//     { label: 'Firefighter', value: 'Firefighter' },
//     { label: 'Fireman', value: 'Fireman' },
//     { label: 'Firm', value: 'Firm' },
//     { label: 'First', value: 'First' },
//     { label: 'Fixed', value: 'Fixed' },
//     { label: 'Fl', value: 'Fl' },
//     { label: 'Fleet', value: 'Fleet' },
//     { label: 'Flight', value: 'Flight' },
//     { label: 'Float', value: 'Float' },
//     { label: 'Florida', value: 'Florida' },
//     { label: 'Food', value: 'Food' },
//     { label: 'Football', value: 'Football' },
//     { label: 'For', value: 'For' },
//     { label: 'Forces', value: 'Forces' },
//     { label: 'Foreman', value: 'Foreman' },
//     { label: 'Forensic', value: 'Forensic' },
//     { label: 'Forensics', value: 'Forensics' },
//     { label: 'Forest', value: 'Forest' },
//     { label: 'Forester', value: 'Forester' },
//     { label: 'Former', value: 'Former' },
//     { label: 'Formulation', value: 'Formulation' },
//     { label: 'Forums', value: 'Forums' },
//     { label: 'Foster', value: 'Foster' },
//     { label: 'Foundation', value: 'Foundation' },
//     { label: 'Founder', value: 'Founder' },
//     { label: 'Founding', value: 'Founding' },
//     { label: 'Fp', value: 'Fp' },
//     { label: 'Franchise', value: 'Franchise' },
//     { label: 'Fraud', value: 'Fraud' },
//     { label: 'Freelance', value: 'Freelance' },
//     { label: 'Frequency', value: 'Frequency' },
//     { label: 'Front', value: 'Front' },
//     { label: 'Frontend', value: 'Frontend' },
//     { label: 'Fulfillment', value: 'Fulfillment' },
//     { label: 'Full', value: 'Full' },
//     { label: 'Fund', value: 'Fund' },
//     { label: 'G', value: 'G' },
//     { label: 'Ga', value: 'Ga' },
//     { label: 'Gallery', value: 'Gallery' },
//     { label: 'Gaming', value: 'Gaming' },
//     { label: 'Garden', value: 'Garden' },
//     { label: 'Gas', value: 'Gas' },
//     { label: 'Gcg', value: 'Gcg' },
//     { label: 'Gcx', value: 'Gcx' },
//     { label: 'Gen', value: 'Gen' },
//     { label: 'General', value: 'General' },
//     { label: 'Generalist', value: 'Generalist' },
//     { label: 'Generation', value: 'Generation' },
//     { label: 'Generator', value: 'Generator' },
//     { label: 'Geographic', value: 'Geographic' },
//     { label: 'Geologist', value: 'Geologist' },
//     { label: 'Geophysicist', value: 'Geophysicist' },
//     { label: 'Girl', value: 'Girl' },
//     { label: 'Girls', value: 'Girls' },
//     { label: 'Glj', value: 'Glj' },
//     { label: 'Global', value: 'Global' },
//     { label: 'Gm', value: 'Gm' },
//     { label: 'Godwin', value: 'Godwin' },
//     { label: 'Governance', value: 'Governance' },
//     { label: 'Government', value: 'Government' },
//     { label: 'Governmental', value: 'Governmental' },
//     { label: 'Governors', value: 'Governors' },
//     { label: 'Gpsc', value: 'Gpsc' },
//     { label: 'Graduate', value: 'Graduate' },
//     { label: 'Graphic', value: 'Graphic' },
//     { label: 'Grassroots', value: 'Grassroots' },
//     { label: 'Greatest', value: 'Greatest' },
//     { label: 'Grocery', value: 'Grocery' },
//     { label: 'Ground', value: 'Ground' },
//     { label: 'Group', value: 'Group' },
//     { label: 'Groups', value: 'Groups' },
//     { label: 'Growth', value: 'Growth' },
//     { label: 'Grp', value: 'Grp' },
//     { label: 'Gs', value: 'Gs' },
//     { label: 'Gto', value: 'Gto' },
//     { label: 'Gts', value: 'Gts' },
//     { label: 'Guard', value: 'Guard' },
//     { label: 'Guest', value: 'Guest' },
//     { label: 'Guidance', value: 'Guidance' },
//     { label: 'Gunner', value: 'Gunner' },
//     { label: 'H', value: 'H' },
//     { label: 'Hana', value: 'Hana' },
//     { label: 'Handler', value: 'Handler' },
//     { label: 'Harborview', value: 'Harborview' },
//     { label: 'Hardware', value: 'Hardware' },
//     { label: 'Harvesting', value: 'Harvesting' },
//     { label: 'Hcc', value: 'Hcc' },
//     { label: 'Head', value: 'Head' },
//     { label: 'Health', value: 'Health' },
//     { label: 'Healthcare', value: 'Healthcare' },
//     { label: 'Healthfitness', value: 'Healthfitness' },
//     { label: 'Heath', value: 'Heath' },
//     { label: 'Help', value: 'Help' },
//     { label: 'Helpdesk', value: 'Helpdesk' },
//     { label: 'Hero911', value: 'Hero911' },
//     { label: 'Hfn', value: 'Hfn' },
//     { label: 'Higher', value: 'Higher' },
//     { label: 'Hipaa', value: 'Hipaa' },
//     { label: 'Hire', value: 'Hire' },
//     { label: 'History', value: 'History' },
//     { label: 'Hitter', value: 'Hitter' },
//     { label: 'Hod', value: 'Hod' },
//     { label: 'Home', value: 'Home' },
//     { label: 'Honda', value: 'Honda' },
//     { label: 'Horizon', value: 'Horizon' },
//     { label: 'Hospital', value: 'Hospital' },
//     { label: 'Hospitality', value: 'Hospitality' },
//     { label: 'House', value: 'House' },
//     { label: 'Hpe', value: 'Hpe' },
//     { label: 'Hr', value: 'Hr' },
//     { label: 'Hra', value: 'Hra' },
//     { label: 'Hris', value: 'Hris' },
//     { label: 'Hse', value: 'Hse' },
//     { label: 'Human', value: 'Human' },
//     { label: 'Hvac', value: 'Hvac' },
//     { label: 'Hydrospheric', value: 'Hydrospheric' },
//     { label: 'Hygiene', value: 'Hygiene' },
//     { label: 'Hygienist', value: 'Hygienist' },
//     { label: 'Hyland', value: 'Hyland' },
//     { label: 'I', value: 'I' },
//     { label: 'Icr', value: 'Icr' },
//     { label: 'Ies', value: 'Ies' },
//     { label: 'Ig', value: 'Ig' },
//     { label: 'Ii', value: 'Ii' },
//     { label: 'Iii', value: 'Iii' },
//     { label: 'Immediate', value: 'Immediate' },
//     { label: 'Immigration', value: 'Immigration' },
//     { label: 'Immunology', value: 'Immunology' },
//     { label: 'Implementation', value: 'Implementation' },
//     { label: 'Implementations', value: 'Implementations' },
//     { label: 'Improvement', value: 'Improvement' },
//     { label: 'In', value: 'In' },
//     { label: 'Inc', value: 'Inc' },
//     { label: 'Inclusion', value: 'Inclusion' },
//     { label: 'Income', value: 'Income' },
//     { label: 'Independant', value: 'Independant' },
//     { label: 'Independent', value: 'Independent' },
//     { label: 'Indirect', value: 'Indirect' },
//     { label: 'Individual', value: 'Individual' },
//     { label: 'Industrial', value: 'Industrial' },
//     { label: 'Industry', value: 'Industry' },
//     { label: 'Infant', value: 'Infant' },
//     { label: 'Influencer', value: 'Influencer' },
//     { label: 'Informatics', value: 'Informatics' },
//     { label: 'Information', value: 'Information' },
//     { label: 'Infrastructure', value: 'Infrastructure' },
//     { label: 'Infusion', value: 'Infusion' },
//     { label: 'Ingeniero', value: 'Ingeniero' },
//     { label: 'Initiatives', value: 'Initiatives' },
//     { label: 'Injury', value: 'Injury' },
//     { label: 'Innovation', value: 'Innovation' },
//     { label: 'Innovations', value: 'Innovations' },
//     { label: 'Inns', value: 'Inns' },
//     { label: 'Inpatient', value: 'Inpatient' },
//     { label: 'Inside', value: 'Inside' },
//     { label: 'Insights', value: 'Insights' },
//     { label: 'Inspector', value: 'Inspector' },
//     { label: 'Institute', value: 'Institute' },
//     { label: 'Institutional', value: 'Institutional' },
//     { label: 'Instruction', value: 'Instruction' },
//     { label: 'Instructional', value: 'Instructional' },
//     { label: 'Instructor', value: 'Instructor' },
//     { label: 'Instrument', value: 'Instrument' },
//     { label: 'Insurance', value: 'Insurance' },
//     { label: 'Integration', value: 'Integration' },
//     { label: 'Integrator', value: 'Integrator' },
//     { label: 'Integrity', value: 'Integrity' },
//     { label: 'Intellectual', value: 'Intellectual' },
//     { label: 'Intelligence', value: 'Intelligence' },
//     { label: 'Intensive', value: 'Intensive' },
//     { label: 'Intercept', value: 'Intercept' },
//     { label: 'Interest', value: 'Interest' },
//     { label: 'Interface', value: 'Interface' },
//     { label: 'Interim', value: 'Interim' },
//     { label: 'Interior', value: 'Interior' },
//     { label: 'Internal', value: 'Internal' },
//     { label: 'International', value: 'International' },
//     { label: 'Internet', value: 'Internet' },
//     { label: 'Interpreter', value: 'Interpreter' },
//     { label: 'Interviewer', value: 'Interviewer' },
//     { label: 'Intimate', value: 'Intimate' },
//     { label: 'Inventory', value: 'Inventory' },
//     { label: 'Investigator', value: 'Investigator' },
//     { label: 'Investing', value: 'Investing' },
//     { label: 'Investment', value: 'Investment' },
//     { label: 'Investments', value: 'Investments' },
//     { label: 'Investor', value: 'Investor' },
//     { label: 'Ireland', value: 'Ireland' },
//     { label: 'Irma', value: 'Irma' },
//     { label: 'Is', value: 'Is' },
//     { label: 'Isd', value: 'Isd' },
//     { label: 'Island', value: 'Island' },
//     { label: 'Ispor', value: 'Ispor' },
//     { label: 'Iss', value: 'Iss' },
//     { label: 'Isso', value: 'Isso' },
//     { label: 'It', value: 'It' },
//     { label: 'It-Medical', value: 'It-Medical' },
//     { label: 'Its', value: 'Its' },
//     { label: 'Iv', value: 'Iv' },
//     { label: 'Jb', value: 'Jb' },
//     { label: 'Jewelry', value: 'Jewelry' },
//     { label: 'Jmo', value: 'Jmo' },
//     { label: 'Job', value: 'Job' },
//     { label: 'John', value: 'John' },
//     { label: 'Journalist', value: 'Journalist' },
//     { label: 'Judge', value: 'Judge' },
//     { label: 'Jugo', value: 'Jugo' },
//     { label: 'Junior', value: 'Junior' },
//     { label: 'K', value: 'K' },
//     { label: 'Kay', value: 'Kay' },
//     { label: 'Kc', value: 'Kc' },
//     { label: 'Kentucky', value: 'Kentucky' },
//     { label: 'Kichler', value: 'Kichler' },
//     { label: 'Kmi', value: 'Kmi' },
//     { label: 'Knowledge', value: 'Knowledge' },
//     { label: 'La', value: 'La' },
//     { label: 'Lab', value: 'Lab' },
//     { label: 'Labor', value: 'Labor' },
//     { label: 'Laboratory', value: 'Laboratory' },
//     { label: 'Laborer', value: 'Laborer' },
//     { label: 'Laboror', value: 'Laboror' },
//     { label: 'Landowners', value: 'Landowners' },
//     { label: 'Landscape', value: 'Landscape' },
//     { label: 'Language', value: 'Language' },
//     { label: 'Late', value: 'Late' },
//     { label: 'Law', value: 'Law' },
//     { label: 'Lawyer', value: 'Lawyer' },
//     { label: 'Lead', value: 'Lead' },
//     { label: 'Leader', value: 'Leader' },
//     { label: 'Leadership', value: 'Leadership' },
//     { label: 'Leading', value: 'Leading' },
//     { label: 'Learning', value: 'Learning' },
//     { label: 'Lease', value: 'Lease' },
//     { label: 'Leasing', value: 'Leasing' },
//     { label: 'Leatherstocking', value: 'Leatherstocking' },
//     { label: 'Lecturer', value: 'Lecturer' },
//     { label: 'Legal', value: 'Legal' },
//     { label: 'Legislative', value: 'Legislative' },
//     { label: 'Lender', value: 'Lender' },
//     { label: 'Lending', value: 'Lending' },
//     { label: 'Lerma', value: 'Lerma' },
//     { label: 'Liaison', value: 'Liaison' },
//     { label: 'Librarian', value: 'Librarian' },
//     { label: 'Library', value: 'Library' },
//     { label: 'Licensed', value: 'Licensed' },
//     { label: 'Licensing', value: 'Licensing' },
//     { label: 'Licesend', value: 'Licesend' },
//     { label: 'Lieutenant', value: 'Lieutenant' },
//     { label: 'Life', value: 'Life' },
//     { label: 'Lifecycle', value: 'Lifecycle' },
//     { label: 'Lifestyle', value: 'Lifestyle' },
//     { label: 'Lighting', value: 'Lighting' },
//     { label: 'Lincoln', value: 'Lincoln' },
//     { label: 'Line', value: 'Line' },
//     { label: 'Litigation', value: 'Litigation' },
//     { label: 'Lives', value: 'Lives' },
//     { label: 'Llc', value: 'Llc' },
//     { label: 'Llead', value: 'Llead' },
//     { label: 'Loan', value: 'Loan' },
//     { label: 'Loans', value: 'Loans' },
//     { label: 'Lobby', value: 'Lobby' },
//     { label: 'Lobbyist', value: 'Lobbyist' },
//     { label: 'Local', value: 'Local' },
//     { label: 'Locomotive', value: 'Locomotive' },
//     { label: 'Logistic', value: 'Logistic' },
//     { label: 'Logistics', value: 'Logistics' },
//     { label: 'Long', value: 'Long' },
//     { label: 'Loss', value: 'Loss' },
//     { label: 'Lpl', value: 'Lpl' },
//     { label: 'Lpn', value: 'Lpn' },
//     { label: 'Lumber', value: 'Lumber' },
//     { label: 'Luo', value: 'Luo' },
//     { label: 'Luxury', value: 'Luxury' },
//     { label: 'Lyrical', value: 'Lyrical' },
//     { label: 'M', value: 'M' },
//     { label: 'Machine', value: 'Machine' },
//     { label: 'Machinery', value: 'Machinery' },
//     { label: 'Mail', value: 'Mail' },
//     { label: 'Maintainer', value: 'Maintainer' },
//     { label: 'Maintenance', value: 'Maintenance' },
//     { label: 'Mammography', value: 'Mammography' },
//     { label: 'Manageability', value: 'Manageability' },
//     { label: 'Management', value: 'Management' },
//     { label: 'Managercombat', value: 'Managercombat' },
//     { label: 'Managerconsultant', value: 'Managerconsultant' },
//     { label: 'Managers', value: 'Managers' },
//     { label: 'Managing', value: 'Managing' },
//     { label: 'Manufacturing', value: 'Manufacturing' },
//     { label: 'Mar', value: 'Mar' },
//     { label: 'Marine', value: 'Marine' },
//     { label: 'Maritime', value: 'Maritime' },
//     { label: 'Market', value: 'Market' },
//     { label: 'Marketer', value: 'Marketer' },
//     { label: 'Marketers', value: 'Marketers' },
//     { label: 'Marketing', value: 'Marketing' },
//     { label: 'Markets', value: 'Markets' },
//     { label: 'Marshal', value: 'Marshal' },
//     { label: 'Mary', value: 'Mary' },
//     { label: 'Master', value: 'Master' },
//     { label: 'Material', value: 'Material' },
//     { label: 'Materials', value: 'Materials' },
//     { label: 'Mathematics', value: 'Mathematics' },
//     { label: 'Matter', value: 'Matter' },
//     { label: 'Mayor', value: 'Mayor' },
//     { label: 'Mayoral', value: 'Mayoral' },
//     { label: 'Mayordomo', value: 'Mayordomo' },
//     { label: 'Mbb', value: 'Mbb' },
//     { label: 'Meat', value: 'Meat' },
//     { label: 'Mechanic', value: 'Mechanic' },
//     { label: 'Mechanical', value: 'Mechanical' },
//     { label: 'Med', value: 'Med' },
//     { label: 'Media', value: 'Media' },
//     { label: 'Medicaid', value: 'Medicaid' },
//     { label: 'Medical', value: 'Medical' },
//     { label: 'Medicine', value: 'Medicine' },
//     { label: 'Medicmedical', value: 'Medicmedical' },
//     { label: 'Member', value: 'Member' },
//     { label: 'Membership', value: 'Membership' },
//     { label: 'Mental', value: 'Mental' },
//     { label: 'Mentor', value: 'Mentor' },
//     { label: 'Mer', value: 'Mer' },
//     { label: 'Merchandiser', value: 'Merchandiser' },
//     { label: 'Merchandising', value: 'Merchandising' },
//     { label: 'Merck', value: 'Merck' },
//     { label: 'Merger', value: 'Merger' },
//     { label: 'Mergers', value: 'Mergers' },
//     { label: 'Messaging', value: 'Messaging' },
//     { label: 'Metadata', value: 'Metadata' },
//     { label: 'Metro', value: 'Metro' },
//     { label: 'Metrocard', value: 'Metrocard' },
//     { label: 'Mgmt', value: 'Mgmt' },
//     { label: 'Mhealthy', value: 'Mhealthy' },
//     { label: 'Microbiologist', value: 'Microbiologist' },
//     { label: 'Midwest', value: 'Midwest' },
//     { label: 'Military', value: 'Military' },
//     { label: 'Millennial', value: 'Millennial' },
//     { label: 'Mimacom', value: 'Mimacom' },
//     { label: 'Mis', value: 'Mis' },
//     { label: 'Mitigation', value: 'Mitigation' },
//     { label: 'Mjh', value: 'Mjh' },
//     { label: 'Mn', value: 'Mn' },
//     { label: 'Mobile', value: 'Mobile' },
//     { label: 'Mock', value: 'Mock' },
//     { label: 'Model', value: 'Model' },
//     { label: 'Modeler', value: 'Modeler' },
//     { label: 'Models', value: 'Models' },
//     { label: 'Modernization', value: 'Modernization' },
//     { label: 'Monitor', value: 'Monitor' },
//     { label: 'Mortgage', value: 'Mortgage' },
//     { label: 'Motor', value: 'Motor' },
//     { label: 'Mri', value: 'Mri' },
//     { label: 'Muir', value: 'Muir' },
//     { label: 'Multi', value: 'Multi' },
//     { label: 'Multichannel', value: 'Multichannel' },
//     { label: 'Multimedia', value: 'Multimedia' },
//     { label: 'Municipal', value: 'Municipal' },
//     { label: 'Music', value: 'Music' },
//     { label: 'N', value: 'N' },
//     { label: 'N6', value: 'N6' },
//     { label: 'National', value: 'National' },
//     { label: 'Natural', value: 'Natural' },
//     { label: 'Naturallyspeaking', value: 'Naturallyspeaking' },
//     { label: 'Navigator', value: 'Navigator' },
//     { label: 'Navihealth', value: 'Navihealth' },
//     { label: 'Nc', value: 'Nc' },
//     { label: 'Ncal', value: 'Ncal' },
//     { label: 'Nco', value: 'Nco' },
//     { label: 'Neck', value: 'Neck' },
//     { label: 'Negotiator', value: 'Negotiator' },
//     { label: 'Nemt', value: 'Nemt' },
//     { label: 'Netapp', value: 'Netapp' },
//     { label: 'Network', value: 'Network' },
//     { label: 'Networking', value: 'Networking' },
//     { label: 'Neurology', value: 'Neurology' },
//     { label: 'Neuropsychologist', value: 'Neuropsychologist' },
//     { label: 'Neuroscience', value: 'Neuroscience' },
//     { label: 'New', value: 'New' },
//     { label: 'News', value: 'News' },
//     { label: 'Nga', value: 'Nga' },
//     { label: 'Night', value: 'Night' },
//     { label: 'Nmls', value: 'Nmls' },
//     { label: 'Noc', value: 'Noc' },
//     { label: 'Nonclinical', value: 'Nonclinical' },
//     { label: 'North', value: 'North' },
//     { label: 'Northeast', value: 'Northeast' },
//     { label: 'Northwest', value: 'Northwest' },
//     { label: 'Nrr', value: 'Nrr' },
//     { label: 'Nuclear', value: 'Nuclear' },
//     { label: 'Nurse', value: 'Nurse' },
//     { label: 'Nursery', value: 'Nursery' },
//     { label: 'Nursing', value: 'Nursing' },
//     { label: 'Nutrition', value: 'Nutrition' },
//     { label: 'Occupational', value: 'Occupational' },
//     { label: 'Off', value: 'Off' },
//     { label: 'Offers', value: 'Offers' },
//     { label: 'Office', value: 'Office' },
//     { label: 'Officer', value: 'Officer' },
//     { label: 'Officerhealth', value: 'Officerhealth' },
//     { label: 'Official', value: 'Official' },
//     { label: 'Ohio', value: 'Ohio' },
//     { label: 'Old', value: 'Old' },
//     { label: 'Omnichannel', value: 'Omnichannel' },
//     { label: 'On', value: 'On' },
//     { label: 'Onboarding', value: 'Onboarding' },
//     { label: 'Onc', value: 'Onc' },
//     { label: 'Oncology', value: 'Oncology' },
//     { label: 'Online', value: 'Online' },
//     { label: 'Op', value: 'Op' },
//     { label: 'Oper', value: 'Oper' },
//     { label: 'Operating', value: 'Operating' },
//     { label: 'Operation', value: 'Operation' },
//     { label: 'Operational', value: 'Operational' },
//     { label: 'Operations', value: 'Operations' },
//     { label: 'Operato', value: 'Operato' },
//     { label: 'Operator', value: 'Operator' },
//     { label: 'Oph', value: 'Oph' },
//     { label: 'Opm', value: 'Opm' },
//     { label: 'Opportunity', value: 'Opportunity' },
//     { label: 'Ops', value: 'Ops' },
//     { label: 'Or', value: 'Or' },
//     { label: 'Oracle', value: 'Oracle' },
//     { label: 'Orcad', value: 'Orcad' },
//     { label: 'Order', value: 'Order' },
//     { label: 'Ordnance', value: 'Ordnance' },
//     { label: 'Organizing', value: 'Organizing' },
//     { label: 'Originator', value: 'Originator' },
//     { label: 'Ortho', value: 'Ortho' },
//     { label: 'Otolaryngologist', value: 'Otolaryngologist' },
//     { label: 'Otolaryngology', value: 'Otolaryngology' },
//     { label: 'Out', value: 'Out' },
//     { label: 'Outcomes', value: 'Outcomes' },
//     { label: 'Outpatient', value: 'Outpatient' },
//     { label: 'Outreach', value: 'Outreach' },
//     { label: 'Outside', value: 'Outside' },
//     { label: 'Outsourcing', value: 'Outsourcing' },
//     { label: 'Overnight', value: 'Overnight' },
//     { label: 'Oversight', value: 'Oversight' },
//     { label: 'Owner', value: 'Owner' },
//     { label: 'Pa', value: 'Pa' },
//     { label: 'Pain', value: 'Pain' },
//     { label: 'Painter', value: 'Painter' },
//     { label: 'Paralegal', value: 'Paralegal' },
//     { label: 'Paramedic', value: 'Paramedic' },
//     { label: 'Parent', value: 'Parent' },
//     { label: 'Partner', value: 'Partner' },
//     { label: 'Partnering', value: 'Partnering' },
//     { label: 'Partners', value: 'Partners' },
//     { label: 'Partnership', value: 'Partnership' },
//     { label: 'Partnerships', value: 'Partnerships' },
//     { label: 'Parts', value: 'Parts' },
//     { label: 'Past', value: 'Past' },
//     { label: 'Pastry', value: 'Pastry' },
//     { label: 'Pathologist', value: 'Pathologist' },
//     { label: 'Pathology', value: 'Pathology' },
//     { label: 'Patient', value: 'Patient' },
//     { label: 'Pay', value: 'Pay' },
//     { label: 'Payable', value: 'Payable' },
//     { label: 'Payroll', value: 'Payroll' },
//     { label: 'Pbm', value: 'Pbm' },
//     { label: 'Pcs', value: 'Pcs' },
//     { label: 'Peacehealth', value: 'Peacehealth' },
//     { label: 'Pediatrics', value: 'Pediatrics' },
//     { label: 'Peer', value: 'Peer' },
//     { label: 'Penetration', value: 'Penetration' },
//     { label: 'People', value: 'People' },
//     { label: 'Performance', value: 'Performance' },
//     { label: 'Peri', value: 'Peri' },
//     { label: 'Personal', value: 'Personal' },
//     { label: 'Personnel', value: 'Personnel' },
//     { label: 'Petroleum', value: 'Petroleum' },
//     { label: 'Petty', value: 'Petty' },
//     { label: 'Pharma', value: 'Pharma' },
//     { label: 'Pharmaceutical', value: 'Pharmaceutical' },
//     { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
//     { label: 'Pharmacist', value: 'Pharmacist' },
//     { label: 'Pharmacovigilance', value: 'Pharmacovigilance' },
//     { label: 'Pharmacy', value: 'Pharmacy' },
//     { label: 'Phlebotomist', value: 'Phlebotomist' },
//     { label: 'Phlebotomy', value: 'Phlebotomy' },
//     { label: 'Photo', value: 'Photo' },
//     { label: 'Photographer', value: 'Photographer' },
//     { label: 'Photojournalist', value: 'Photojournalist' },
//     { label: 'Physical', value: 'Physical' },
//     { label: 'Physician', value: 'Physician' },
//     { label: 'Physicist', value: 'Physicist' },
//     { label: 'Piano', value: 'Piano' },
//     { label: 'Pilot', value: 'Pilot' },
//     { label: 'Pilotowner', value: 'Pilotowner' },
//     { label: 'Plan', value: 'Plan' },
//     { label: 'Planner', value: 'Planner' },
//     { label: 'Planning', value: 'Planning' },
//     { label: 'Plans', value: 'Plans' },
//     { label: 'Plant', value: 'Plant' },
//     { label: 'Plastics', value: 'Plastics' },
//     { label: 'Platform', value: 'Platform' },
//     { label: 'Platforms', value: 'Platforms' },
//     { label: 'Player', value: 'Player' },
//     { label: 'Pmf', value: 'Pmf' },
//     { label: 'Pmo', value: 'Pmo' },
//     { label: 'Podcasts', value: 'Podcasts' },
//     { label: 'Police', value: 'Police' },
//     { label: 'Policy', value: 'Policy' },
//     { label: 'Political', value: 'Political' },
//     { label: 'Porsche', value: 'Porsche' },
//     { label: 'Portfolio', value: 'Portfolio' },
//     { label: 'Portfolios', value: 'Portfolios' },
//     { label: 'Positions', value: 'Positions' },
//     { label: 'Post', value: 'Post' },
//     { label: 'Postdoctoral', value: 'Postdoctoral' },
//     { label: 'Poultry', value: 'Poultry' },
//     { label: 'Power', value: 'Power' },
//     { label: 'Powerade', value: 'Powerade' },
//     { label: 'Powershift', value: 'Powershift' },
//     { label: 'Powerstore', value: 'Powerstore' },
//     { label: 'Ppd', value: 'Ppd' },
//     { label: 'Pr', value: 'Pr' },
//     { label: 'Practical', value: 'Practical' },
//     { label: 'Practice', value: 'Practice' },
//     { label: 'Practitioner', value: 'Practitioner' },
//     { label: 'Pre', value: 'Pre' },
//     { label: 'Preclinical', value: 'Preclinical' },
//     { label: 'Preconstruction', value: 'Preconstruction' },
//     { label: 'Premiere', value: 'Premiere' },
//     { label: 'Prep', value: 'Prep' },
//     { label: 'Prepaid', value: 'Prepaid' },
//     { label: 'Preschool', value: 'Preschool' },
//     { label: 'Preserve', value: 'Preserve' },
//     { label: 'President', value: 'President' },
//     { label: 'President)', value: 'President)' },
//     { label: 'President,', value: 'President,' },
//     { label: 'President-Analyst', value: 'President-Analyst' },
//     { label: 'Presidential', value: 'Presidential' },
//     { label: 'Presidentmarketing', value: 'Presidentmarketing' },
//     { label: 'Presidents', value: 'Presidents' },
//     { label: 'President-Senior', value: 'President-Senior' },
//     { label: 'Presiding', value: 'Presiding' },
//     { label: 'Press', value: 'Press' },
//     { label: 'Prevention', value: 'Prevention' },
//     { label: 'Price', value: 'Price' },
//     { label: 'Pricing', value: 'Pricing' },
//     { label: 'Pride', value: 'Pride' },
//     { label: 'Primary', value: 'Primary' },
//     { label: 'Primeclaims', value: 'Primeclaims' },
//     { label: 'Principal', value: 'Principal' },
//     { label: 'Privacy', value: 'Privacy' },
//     { label: 'Private', value: 'Private' },
//     { label: 'Prn', value: 'Prn' },
//     { label: 'Pro', value: 'Pro' },
//     { label: 'Probation', value: 'Probation' },
//     { label: 'Process', value: 'Process' },
//     { label: 'Processing', value: 'Processing' },
//     { label: 'Processor', value: 'Processor' },
//     { label: 'Procurement', value: 'Procurement' },
//     { label: 'Prod', value: 'Prod' },
//     { label: 'Produce', value: 'Produce' },
//     { label: 'Producer', value: 'Producer' },
//     { label: 'Product', value: 'Product' },
//     { label: 'Production', value: 'Production' },
//     { label: 'Productivity', value: 'Productivity' },
//     { label: 'Products', value: 'Products' },
//     { label: 'Professional', value: 'Professional' },
//     { label: 'Professionals', value: 'Professionals' },
//     { label: 'Professor', value: 'Professor' },
//     { label: 'Progra', value: 'Progra' },
//     { label: 'Program', value: 'Program' },
//     { label: 'Programmatic', value: 'Programmatic' },
//     { label: 'Programmer', value: 'Programmer' },
//     { label: 'Programs', value: 'Programs' },
//     { label: 'Project', value: 'Project' },
//     { label: 'Projects', value: 'Projects' },
//     { label: 'Property', value: 'Property' },
//     { label: 'Prospective', value: 'Prospective' },
//     { label: 'Protection', value: 'Protection' },
//     { label: 'Provider', value: 'Provider' },
//     { label: 'Providers', value: 'Providers' },
//     { label: 'Provost', value: 'Provost' },
//     { label: 'Psr', value: 'Psr' },
//     { label: 'Psychiatry', value: 'Psychiatry' },
//     { label: 'Psychologist', value: 'Psychologist' },
//     { label: 'Pt', value: 'Pt' },
//     { label: 'Public', value: 'Public' },
//     { label: 'Publisher', value: 'Publisher' },
//     { label: 'Purchasing', value: 'Purchasing' },
//     { label: 'Qa', value: 'Qa' },
//     { label: 'Qc', value: 'Qc' },
//     { label: 'Qms', value: 'Qms' },
//     { label: 'Quality', value: 'Quality' },
//     { label: 'R', value: 'R' },
//     { label: 'R&D', value: 'R&D' },
//     { label: 'Ra', value: 'Ra' },
//     { label: 'Rachel', value: 'Rachel' },
//     { label: 'Rad', value: 'Rad' },
//     { label: 'Radiation', value: 'Radiation' },
//     { label: 'Radio', value: 'Radio' },
//     { label: 'Radiocom', value: 'Radiocom' },
//     { label: 'Radiologic', value: 'Radiologic' },
//     { label: 'Radiology', value: 'Radiology' },
//     { label: 'Rainmaker', value: 'Rainmaker' },
//     { label: 'Rangel', value: 'Rangel' },
//     { label: 'Rangeland', value: 'Rangeland' },
//     { label: 'Rapid', value: 'Rapid' },
//     { label: 'Rare', value: 'Rare' },
//     { label: 'Real', value: 'Real' },
//     { label: 'Reality', value: 'Reality' },
//     { label: 'Realtor', value: 'Realtor' },
//     { label: 'Realty', value: 'Realty' },
//     { label: 'Receivable', value: 'Receivable' },
//     { label: 'Receiving', value: 'Receiving' },
//     { label: 'Recitalist', value: 'Recitalist' },
//     { label: 'Record', value: 'Record' },
//     { label: 'Records', value: 'Records' },
//     { label: 'Recovery', value: 'Recovery' },
//     { label: 'Recruit', value: 'Recruit' },
//     { label: 'Recruiter', value: 'Recruiter' },
//     { label: 'Recruiting', value: 'Recruiting' },
//     { label: 'Recruitment', value: 'Recruitment' },
//     { label: 'Redevelopment', value: 'Redevelopment' },
//     { label: 'Reduction', value: 'Reduction' },
//     { label: 'Region', value: 'Region' },
//     { label: 'Regional', value: 'Regional' },
//     { label: 'Registered', value: 'Registered' },
//     { label: 'Regression', value: 'Regression' },
//     { label: 'Regulatory', value: 'Regulatory' },
//     { label: 'Rehabilitation', value: 'Rehabilitation' },
//     { label: 'Relations', value: 'Relations' },
//     { label: 'Relationship', value: 'Relationship' },
//     { label: 'Release', value: 'Release' },
//     { label: 'Releases', value: 'Releases' },
//     { label: 'Remote', value: 'Remote' },
//     { label: 'Reporter', value: 'Reporter' },
//     { label: 'Reporting', value: 'Reporting' },
//     { label: 'Represenative', value: 'Represenative' },
//     { label: 'Representative', value: 'Representative' },
//     { label: 'Republic', value: 'Republic' },
//     { label: 'Research', value: 'Research' },
//     { label: 'Researcher', value: 'Researcher' },
//     { label: 'Reserve', value: 'Reserve' },
//     { label: 'Resident', value: 'Resident' },
//     { label: 'Residential', value: 'Residential' },
//     { label: 'Resolution', value: 'Resolution' },
//     { label: 'Resource', value: 'Resource' },
//     { label: 'Resources', value: 'Resources' },
//     { label: 'Respiratory', value: 'Respiratory' },
//     { label: 'Restructuring', value: 'Restructuring' },
//     { label: 'Retail', value: 'Retail' },
//     { label: 'Retirement', value: 'Retirement' },
//     { label: 'Revenue', value: 'Revenue' },
//     { label: 'Review', value: 'Review' },
//     { label: 'Rewards', value: 'Rewards' },
//     { label: 'Risk', value: 'Risk' },
//     { label: 'Rn', value: 'Rn' },
//     { label: 'Robert', value: 'Robert' },
//     { label: 'Rocket', value: 'Rocket' },
//     { label: 'Rodan', value: 'Rodan' },
//     { label: 'Rotors', value: 'Rotors' },
//     { label: 'Rs', value: 'Rs' },
//     { label: 'S', value: 'S' },
//     { label: 'Saas', value: 'Saas' },
//     { label: 'Safety', value: 'Safety' },
//     { label: 'Sales', value: 'Sales' },
//     { label: 'Sales)', value: 'Sales)' },
//     { label: 'Salesforce', value: 'Salesforce' },
//     { label: 'Salesman', value: 'Salesman' },
//     { label: 'Salesperson', value: 'Salesperson' },
//     { label: 'Samsung', value: 'Samsung' },
//     { label: 'Sap', value: 'Sap' },
//     { label: 'Satellite', value: 'Satellite' },
//     { label: 'Satisfaction', value: 'Satisfaction' },
//     { label: 'Scentsy', value: 'Scentsy' },
//     { label: 'Scheduler', value: 'Scheduler' },
//     { label: 'Scholar', value: 'Scholar' },
//     { label: 'School', value: 'School' },
//     { label: 'Science', value: 'Science' },
//     { label: 'Sciences', value: 'Sciences' },
//     { label: 'Scientific', value: 'Scientific' },
//     { label: 'Scientist', value: 'Scientist' },
//     { label: 'Scout', value: 'Scout' },
//     { label: 'Scrum', value: 'Scrum' },
//     { label: 'Sct', value: 'Sct' },
//     { label: 'Seafood', value: 'Seafood' },
//     { label: 'Search', value: 'Search' },
//     { label: 'Second', value: 'Second' },
//     { label: 'Secondary', value: 'Secondary' },
//     { label: 'Secretary', value: 'Secretary' },
//     { label: 'Section', value: 'Section' },
//     { label: 'Sector', value: 'Sector' },
//     { label: 'Security', value: 'Security' },
//     { label: 'Segment', value: 'Segment' },
//     { label: 'Select', value: 'Select' },
//     { label: 'Seller', value: 'Seller' },
//     { label: 'Semene', value: 'Semene' },
//     { label: 'Senate', value: 'Senate' },
//     { label: 'Senior', value: 'Senior' },
//     { label: 'Ser', value: 'Ser' },
//     { label: 'Sergeant', value: 'Sergeant' },
//     { label: 'Series', value: 'Series' },
//     { label: 'Server', value: 'Server' },
//     { label: 'Servic', value: 'Servic' },
//     { label: 'Service', value: 'Service' },
//     { label: 'Services', value: 'Services' },
//     { label: 'Servicing', value: 'Servicing' },
//     { label: 'Sessional', value: 'Sessional' },
//     { label: 'Shared', value: 'Shared' },
//     { label: 'Shareholder', value: 'Shareholder' },
//     { label: 'Sheriff', value: 'Sheriff' },
//     { label: 'Shift', value: 'Shift' },
//     { label: 'Shipping', value: 'Shipping' },
//     { label: 'Shire', value: 'Shire' },
//     { label: 'Show', value: 'Show' },
//     { label: 'Showroom', value: 'Showroom' },
//     { label: 'Signal', value: 'Signal' },
//     { label: 'Singletree', value: 'Singletree' },
//     { label: 'Site', value: 'Site' },
//     { label: 'Smyly', value: 'Smyly' },
//     { label: 'Soc', value: 'Soc' },
//     { label: 'Social', value: 'Social' },
//     { label: 'Society', value: 'Society' },
//     { label: 'Softball', value: 'Softball' },
//     { label: 'Software', value: 'Software' },
//     { label: 'Solution', value: 'Solution' },
//     { label: 'Solutions', value: 'Solutions' },
//     { label: 'Songwriter', value: 'Songwriter' },
//     { label: 'Sourcer', value: 'Sourcer' },
//     { label: 'Sourcing', value: 'Sourcing' },
//     { label: 'Sous', value: 'Sous' },
//     { label: 'South', value: 'South' },
//     { label: 'Space', value: 'Space' },
//     { label: 'Spd', value: 'Spd' },
//     { label: 'Special', value: 'Special' },
//     { label: 'Specialist', value: 'Specialist' },
//     { label: 'Speciality', value: 'Speciality' },
//     { label: 'Specialty', value: 'Specialty' },
//     { label: 'Speech', value: 'Speech' },
//     { label: 'Sponsor', value: 'Sponsor' },
//     { label: 'Spring', value: 'Spring' },
//     { label: 'Springfield', value: 'Springfield' },
//     { label: 'Sprinkler', value: 'Sprinkler' },
//     { label: 'Sqa', value: 'Sqa' },
//     { label: 'Sql', value: 'Sql' },
//     { label: 'Square', value: 'Square' },
//     { label: 'Stack', value: 'Stack' },
//     { label: 'Staff', value: 'Staff' },
//     { label: 'Staffing', value: 'Staffing' },
//     { label: 'Standards', value: 'Standards' },
//     { label: 'Starseed', value: 'Starseed' },
//     { label: 'State', value: 'State' },
//     { label: 'States', value: 'States' },
//     { label: 'Steward', value: 'Steward' },
//     { label: 'Stock', value: 'Stock' },
//     { label: 'Stocker', value: 'Stocker' },
//     { label: 'Stop', value: 'Stop' },
//     { label: 'Storage', value: 'Storage' },
//     { label: 'Store', value: 'Store' },
//     { label: 'Strategic', value: 'Strategic' },
//     { label: 'Strategies', value: 'Strategies' },
//     { label: 'Strategist', value: 'Strategist' },
//     { label: 'Strategy', value: 'Strategy' },
//     { label: 'Structural', value: 'Structural' },
//     { label: 'Structures', value: 'Structures' },
//     { label: 'Student', value: 'Student' },
//     { label: 'Students', value: 'Students' },
//     { label: 'Studio', value: 'Studio' },
//     { label: 'Studios', value: 'Studios' },
//     { label: 'Subcontractor', value: 'Subcontractor' },
//     { label: 'Substitute', value: 'Substitute' },
//     { label: 'Success', value: 'Success' },
//     { label: 'Summer', value: 'Summer' },
//     { label: 'Sunflower', value: 'Sunflower' },
//     { label: 'Superintendent', value: 'Superintendent' },
//     { label: 'Supervising', value: 'Supervising' },
//     { label: 'Supplier', value: 'Supplier' },
//     { label: 'Supply', value: 'Supply' },
//     { label: 'Support', value: 'Support' },
//     { label: 'Surg', value: 'Surg' },
//     { label: 'Surgeon', value: 'Surgeon' },
//     { label: 'Surgery', value: 'Surgery' },
//     { label: 'Surgical', value: 'Surgical' },
//     { label: 'Surveillance', value: 'Surveillance' },
//     { label: 'Survey', value: 'Survey' },
//     { label: 'Sustainability', value: 'Sustainability' },
//     { label: 'Svp', value: 'Svp' },
//     { label: 'Sw', value: 'Sw' },
//     { label: 'Switch', value: 'Switch' },
//     { label: 'Switchboard', value: 'Switchboard' },
//     { label: 'Sys', value: 'Sys' },
//     { label: 'System', value: 'System' },
//     { label: 'Systems', value: 'Systems' },
//     { label: 'Systems-Your', value: 'Systems-Your' },
//     { label: 'T', value: 'T' },
//     { label: 'Tacsat', value: 'Tacsat' },
//     { label: 'Tactical', value: 'Tactical' },
//     { label: 'Talent', value: 'Talent' },
//     { label: 'Tampa', value: 'Tampa' },
//     { label: 'Tax', value: 'Tax' },
//     { label: 'Teacher', value: 'Teacher' },
//     { label: 'Teaching', value: 'Teaching' },
//     { label: 'Team', value: 'Team' },
//     { label: 'Tec', value: 'Tec' },
//     { label: 'Tech', value: 'Tech' },
//     { label: 'Technical', value: 'Technical' },
//     { label: 'Technicial', value: 'Technicial' },
//     { label: 'Technician', value: 'Technician' },
//     { label: 'Technolo', value: 'Technolo' },
//     { label: 'Technologies', value: 'Technologies' },
//     { label: 'Technologist', value: 'Technologist' },
//     { label: 'Technology', value: 'Technology' },
//     { label: 'Teladoc', value: 'Teladoc' },
//     { label: 'Teladochealth', value: 'Teladochealth' },
//     { label: 'Telecom', value: 'Telecom' },
//     { label: 'Telecomm', value: 'Telecomm' },
//     { label: 'Telecommu', value: 'Telecommu' },
//     { label: 'Telecommunicat', value: 'Telecommunicat' },
//     { label: 'Telecommunication', value: 'Telecommunication' },
//     { label: 'Telecommunications', value: 'Telecommunications' },
//     { label: 'Telecommunicator', value: 'Telecommunicator' },
//     { label: 'Telemarketing', value: 'Telemarketing' },
//     { label: 'Telematics', value: 'Telematics' },
//     { label: 'Teller', value: 'Teller' },
//     { label: 'Tem', value: 'Tem' },
//     { label: 'Temp', value: 'Temp' },
//     { label: 'Term', value: 'Term' },
//     { label: 'Territory', value: 'Territory' },
//     { label: 'Test', value: 'Test' },
//     { label: 'Tested', value: 'Tested' },
//     { label: 'Tester', value: 'Tester' },
//     { label: 'Testing', value: 'Testing' },
//     { label: 'Texas', value: 'Texas' },
//     { label: 'The', value: 'The' },
//     { label: 'Therapist', value: 'Therapist' },
//     { label: 'Therapy', value: 'Therapy' },
//     { label: 'Third', value: 'Third' },
//     { label: 'Time', value: 'Time' },
//     { label: 'Timekeeping', value: 'Timekeeping' },
//     { label: 'Tizor', value: 'Tizor' },
//     { label: 'Tn', value: 'Tn' },
//     { label: 'To', value: 'To' },
//     { label: 'Tobacco', value: 'Tobacco' },
//     { label: 'Toddler', value: 'Toddler' },
//     { label: 'Tools', value: 'Tools' },
//     { label: 'Topic', value: 'Topic' },
//     { label: 'Total', value: 'Total' },
//     { label: 'Trade', value: 'Trade' },
//     { label: 'Trader', value: 'Trader' },
//     { label: 'Trades', value: 'Trades' },
//     { label: 'Traditional', value: 'Traditional' },
//     { label: 'Traffic', value: 'Traffic' },
//     { label: 'Trainee', value: 'Trainee' },
//     { label: 'Trainer', value: 'Trainer' },
//     { label: 'Training', value: 'Training' },
//     { label: 'Transcriptionist', value: 'Transcriptionist' },
//     { label: 'Transfer', value: 'Transfer' },
//     { label: 'Transition', value: 'Transition' },
//     { label: 'Transmission', value: 'Transmission' },
//     { label: 'Transmissions', value: 'Transmissions' },
//     { label: 'Transport', value: 'Transport' },
//     { label: 'Transportation', value: 'Transportation' },
//     { label: 'Trauma', value: 'Trauma' },
//     { label: 'Travel', value: 'Travel' },
//     { label: 'Treasurer', value: 'Treasurer' },
//     { label: 'Treasury', value: 'Treasury' },
//     { label: 'Trial', value: 'Trial' },
//     { label: 'Trials', value: 'Trials' },
//     { label: 'Trucks', value: 'Trucks' },
//     { label: 'Trust', value: 'Trust' },
//     { label: 'Trusted', value: 'Trusted' },
//     { label: 'Trustee', value: 'Trustee' },
//     { label: 'Trustees', value: 'Trustees' },
//     { label: 'Trxade', value: 'Trxade' },
//     { label: 'Tutor', value: 'Tutor' },
//     { label: 'Ucsf', value: 'Ucsf' },
//     { label: 'Udg', value: 'Udg' },
//     { label: 'Uk', value: 'Uk' },
//     { label: 'Ultrasound', value: 'Ultrasound' },
//     { label: 'Undergraduate', value: 'Undergraduate' },
//     { label: 'Underwriter', value: 'Underwriter' },
//     { label: 'Underwriting', value: 'Underwriting' },
//     { label: 'Unified', value: 'Unified' },
//     { label: 'Union', value: 'Union' },
//     { label: 'Unit', value: 'Unit' },
//     { label: 'United', value: 'United' },
//     { label: 'Universal', value: 'Universal' },
//     { label: 'University', value: 'University' },
//     { label: 'Upgrades', value: 'Upgrades' },
//     { label: 'Us', value: 'Us' },
//     { label: 'Usa', value: 'Usa' },
//     { label: 'Uscg', value: 'Uscg' },
//     { label: 'Usec', value: 'Usec' },
//     { label: 'User', value: 'User' },
//     { label: 'Utility', value: 'Utility' },
//     { label: 'Utilization', value: 'Utilization' },
//     { label: 'Uw', value: 'Uw' },
//     { label: 'V', value: 'V' },
//     { label: 'Va', value: 'Va' },
//     { label: 'Vaccines', value: 'Vaccines' },
//     { label: 'Validation', value: 'Validation' },
//     { label: 'Valle', value: 'Valle' },
//     { label: 'Value', value: 'Value' },
//     { label: 'Vascular', value: 'Vascular' },
//     { label: 'Vemma', value: 'Vemma' },
//     { label: 'Vendor', value: 'Vendor' },
//     { label: 'Venture', value: 'Venture' },
//     { label: 'Ventures', value: 'Ventures' },
//     { label: 'Veterans', value: 'Veterans' },
//     { label: 'Veterinary', value: 'Veterinary' },
//     { label: 'Via', value: 'Via' },
//     { label: 'Video', value: 'Video' },
//     { label: 'Videographer', value: 'Videographer' },
//     { label: 'Village', value: 'Village' },
//     { label: 'Vinyl', value: 'Vinyl' },
//     { label: 'Virtual', value: 'Virtual' },
//     { label: 'Virtualization', value: 'Virtualization' },
//     { label: 'Visionary', value: 'Visionary' },
//     { label: 'Visionix', value: 'Visionix' },
//     { label: 'Visits', value: 'Visits' },
//     { label: 'Visual', value: 'Visual' },
//     { label: 'Volleyball', value: 'Volleyball' },
//     { label: 'Volunteer', value: 'Volunteer' },
//     { label: 'Volvo', value: 'Volvo' },
//     { label: 'Vr', value: 'Vr' },
//     { label: 'W', value: 'W' },
//     { label: 'Waiter', value: 'Waiter' },
//     { label: 'Waitress', value: 'Waitress' },
//     { label: 'Warden', value: 'Warden' },
//     { label: 'Warehouse', value: 'Warehouse' },
//     { label: 'Warehousing', value: 'Warehousing' },
//     { label: 'Washer', value: 'Washer' },
//     { label: 'Wasser', value: 'Wasser' },
//     { label: 'Water', value: 'Water' },
//     { label: 'Wealth', value: 'Wealth' },
//     { label: 'Web', value: 'Web' },
//     { label: 'Webmaster', value: 'Webmaster' },
//     { label: 'Website', value: 'Website' },
//     { label: 'Weeks', value: 'Weeks' },
//     { label: 'Welfare', value: 'Welfare' },
//     { label: 'Well', value: 'Well' },
//     { label: 'Wellness', value: 'Wellness' },
//     { label: 'West', value: 'West' },
//     { label: 'Western', value: 'Western' },
//     { label: 'Westpac', value: 'Westpac' },
//     { label: 'White', value: 'White' },
//     { label: 'Wind', value: 'Wind' },
//     { label: 'Wireless', value: 'Wireless' },
//     { label: 'Wisconsin', value: 'Wisconsin' },
//     { label: 'With', value: 'With' },
//     { label: 'Wordpress', value: 'Wordpress' },
//     { label: 'Work', value: 'Work' },
//     { label: 'Worker', value: 'Worker' },
//     { label: 'Workers', value: 'Workers' },
//     { label: 'Workforce', value: 'Workforce' },
//     { label: 'Workplace', value: 'Workplace' },
//     { label: 'Worldwide', value: 'Worldwide' },
//     { label: 'Woven', value: 'Woven' },
//     { label: 'Writer', value: 'Writer' },
//     { label: 'Writing', value: 'Writing' },
//     { label: 'York', value: 'York' },
//     { label: 'Young', value: 'Young' },
//     { label: 'Youth', value: 'Youth' },
//     { label: 'Zelis', value: 'Zelis' },
//   ];

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedKeyword}
//         onChange={handleSelectChange}
//         options={keywordOptions}
//         placeholder="Select Keywords..."
//         components={makeAnimated()}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default Keyword;



//For Predefined options
// import React, { useState } from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const Keyword = ({ selectedKeyword, handleSelectChange }) => {
//   const [newKeyword, setNewKeyword] = useState('');
//   const [keywordOptions, setKeywordOptions] = useState([
//     // your existing keyword options...
//   ]);

//   const handleAddNewKeyword = (newKeyword) => {
//     if (newKeyword.trim() !== '') {
//       const newOption = { label: newKeyword, value: newKeyword };
//       setKeywordOptions([...keywordOptions, newOption]);
//       handleSelectChange([...selectedKeyword, newOption]);
//       setNewKeyword('');
//     }
//   };

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter') {
//       handleAddNewKeyword(newKeyword);
//     }
//   };

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedKeyword}
//         onChange={handleSelectChange}
//         options={keywordOptions}
//         placeholder="Select Keywords..."
//         components={makeAnimated()}
//         closeMenuOnSelect={false}
//       />
//       <input
//         type="text"
//         value={newKeyword}
//         onChange={(event) => setNewKeyword(event.target.value)}
//         onKeyDown={handleKeyDown}
//         placeholder="Type and press Enter to add new keyword"
//       />
//     </div>
//   );
// };

// export default Keyword;


import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const Keyword = ({ selectedKeyword, handleSelectChange }) => {
  const [newKeyword, setNewKeyword] = useState('');
  const [keywordOptions, setKeywordOptions] = useState([]);

  const handleAddNewKeyword = () => {
    if (newKeyword.trim() !== '') {
      const newOption = { label: newKeyword, value: newKeyword };
      setKeywordOptions([...keywordOptions, newOption]);
      handleSelectChange([...selectedKeyword, newOption]);
      setNewKeyword('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const inputValue = newKeyword.trim();
      if (inputValue !== '') {
        const newOptions = inputValue.split(',').map((keyword) => ({
          label: keyword.trim(),
          value: keyword.trim(),
        }));
        setKeywordOptions([...keywordOptions, ...newOptions]);
        handleSelectChange([...selectedKeyword, ...newOptions]);
        setNewKeyword('');
      }
    }
  };

  return (
    <div>
      <Select
        isMulti
        value={selectedKeyword}
        onChange={handleSelectChange}
        options={keywordOptions}
        placeholder="Type and press Enter to add a keyword"
        components={makeAnimated()}
        closeMenuOnSelect={false}
        menuIsOpen={false}
        styles={{
          control: (base) => ({
            ...base,
            width: '100%',
          }),
        }}
        onInputChange={(inputValue) => {
          setNewKeyword(inputValue);
        }}
        onKeyDown={handleKeyDown}
        inputValue={newKeyword}
      />
    </div>
  );
};

export default Keyword;