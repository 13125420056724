// import React from 'react'
// import Select from 'react-select'
// import makeAnimated from 'react-select/animated'

// // Making animation component
// const animatedComponents = makeAnimated();

// export default function EmployeeSize() {
//     return(
//         <Select 
//             isMulti
//             options={empSize}
//             placeholder={'Select Employee Size...'}
//             components={animatedComponents}
//         />
//     )
  
// }

// //Employee Size
// const empSize = [
//     { label: '1-10', value: '1-10' },
//     { label: '11-50', value: '11-50' },
//     { label: '51-200', value: '51-200' },
//     { label: '201-500', value: '201-500' },
//     { label: '501-1000', value: '501-1000' },
//     { label: '1001-5000', value: '1001-5000' },
//     { label: '5001-9999', value: '5001-9999' },
//     { label: '10000-49999', value: '10000-49999' },
//     { label: '50000-100000', value: '50000-100000' },
//     { label: '100000+', value: '100000+' },

// ]

// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const EmployeeSize = ({ filteredData, selectedEmployeeSize, handleSelectChange }) => {
//   const employeeSizeOptions = [
//     { label: '1-10', value: '1-10' },
//     { label: '11-50', value: '11-50' },
//     { label: '51-200', value: '51-200' },
//     { label: '201-500', value: '201-500' },
//     { label: '501-1000', value: '501-1000' },
//     { label: '1001-5000', value: '1001-5000' },
//     { label: '5001-10000', value: '5001-10000' },
//     { label: '10001-50000', value: '10001-50000' },
//     { label: '50001-100000', value: '50001-100000' },
//     { label: '100000+', value: '100000+' },
//   ];

//   const filterData = (empSizeRange) => {
//     const [minValue, maxValue] = empSizeRange.value.replace(/,/g, '').split('-').map(Number);
//     return filteredData.filter((item) => {
//       const empSize = parseInt(item.EmployeeSize.replace(/,/g, ''));
//       return empSize >= minValue && empSize <= maxValue;
//     });
//   };

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedEmployeeSize}
//         onChange={handleSelectChange}
//         options={employeeSizeOptions}
//         placeholder="Select Employee Size..."
//         components={makeAnimated()}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default EmployeeSize;


import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const EmployeeSize = ({ filteredData, selectedEmployeeSize, handleSelectChange }) => {
  const employeeSizeOptions = [
    { label: '1-10', value: '1-10' },
    { label: '11-50', value: '11-50' },
    { label: '51-200', value: '51-200' },
    { label: '201-500', value: '201-500' },
    { label: '501-1000', value: '501-1000' },
    { label: '1001-5000', value: '1001-5000' },
    { label: '5001-10000', value: '5001-10000' },
    { label: '10001-50000', value: '10001-50000' },
    { label: '50001-100000', value: '50001-100000' },
    { label: '100000+', value: '100000+' },

    
  ];

  return (
    <Select
      components={makeAnimated()}
      isMulti
      options={employeeSizeOptions}
      value={selectedEmployeeSize}
      onChange={handleSelectChange}
      placeholder="Select Employee Size..."
      closeMenuOnSelect={false}
    />
  );
};

export default EmployeeSize;