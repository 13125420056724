import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const NAICS = ({ selectedNaicsCode, handleSelectChange }) => {
  const [newNaicsCode, setNewNaicsCode] = useState('');
  const [naicsCodeOptions, setNaicsCodeOptions] = useState([]);

  const handleAddNewNaicsCode = () => {
    if (newNaicsCode.trim() !== '') {
      const newOption = { label: newNaicsCode, value: newNaicsCode };
      setNaicsCodeOptions([...naicsCodeOptions, newOption]);
      handleSelectChange([...selectedNaicsCode, newOption]);
      setNewNaicsCode('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddNewNaicsCode();
    }
  };

  return (
    <div>
      <Select
        isMulti
        value={selectedNaicsCode}
        onChange={handleSelectChange}
        options={naicsCodeOptions}
        placeholder="Type and press Enter to add a NAICS code"
        components={makeAnimated()}
        closeMenuOnSelect={false}
        menuIsOpen={false}
        styles={{
          control: (base) => ({
            ...base,
            width: '100%',
          }),
        }}
        onInputChange={(inputValue) => {
          setNewNaicsCode(inputValue);
        }}
        onKeyDown={handleKeyDown}
        inputValue={newNaicsCode}
      />
    </div>
  );
};

export default NAICS;



// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const naicsCode = [
//   { label: '111110', value: '111110' },
//   { label: '111120', value: '111120' },
//   { label: '111130', value: '111130' },
//   // Add more NAICS codes here...
//   { label: 'Other', value: 'Other' }
// ];

// const NAICS = ({ selectedNaicsCode, handleSelectChange }) => {
//   const animatedComponents = makeAnimated();

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedNaicsCode}
//         onChange={handleSelectChange}
//         options={naicsCode}
//         placeholder="Select NAICS Code..."
//         components={animatedComponents}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default NAICS;



// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const NAICS = ({ data, selectedNaicsCode, handleSelectChange }) => {
//   const naicsCode = [...new Set(data.map((item) => item.NAICS || ''))]
//     .filter(naics => naics !== '')
//     .map((naics) => ({
//       value: naics,
//       label: naics,
//     }));

//   // Sort the city names in alphabetical order
//   naicsCode.sort((a, b) => a.label.localeCompare(b.label));

//   const animatedComponents = makeAnimated();   

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedNaicsCode}
//         onChange={handleSelectChange}
//         options={naicsCode}
//         placeholder="Select NAICS Code..."
//         components={animatedComponents}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default NAICS;