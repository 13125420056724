// import React from 'react'
// import Select from 'react-select'
// import makeAnimated from 'react-select/animated';


// // Making animation component
// const animatedComponents = makeAnimated();

// export default function Geography() {
//     return(

//         <Select 
//             isMulti
//             options={geo}
//             placeholder={'Select Geo...'}
//             components={animatedComponents}
//         />
//     )
// }

//   //Geo locations
// const geo = [
  //  { label: 'Afghanistan', value: 'Afghanistan' },
  //  { label: 'Albania', value: 'Albania' },
  //  { label: 'Algeria', value: 'Algeria' },
  //  { label: 'American Samoa', value: 'American Samoa' },
  //  { label: 'Andorra', value: 'Andorra' },
  //  { label: 'Angola', value: 'Angola' },
  //  { label: 'Anguilla', value: 'Anguilla' },
  //  { label: 'Antarctica', value: 'Antarctica' },
  //  { label: 'Antigua And Barbuda', value: 'Antigua And Barbuda' },
  //  { label: 'Argentina', value: 'Argentina' },
  //  { label: 'Armenia', value: 'Armenia' },
  //  { label: 'Aruba', value: 'Aruba' },
  //  { label: 'Australia', value: 'Australia' },
  //  { label: 'Austria', value: 'Austria' },
  //  { label: 'Azerbaijan', value: 'Azerbaijan' },
  //  { label: 'Bahamas', value: 'Bahamas' },
  //  { label: 'Bahrain', value: 'Bahrain' },
  //  { label: 'Bangladesh', value: 'Bangladesh' },
  //  { label: 'Barbados', value: 'Barbados' },
  //  { label: 'Belarus', value: 'Belarus' },
  //  { label: 'Belgium', value: 'Belgium' },
  //  { label: 'Belize', value: 'Belize' },
  //  { label: 'Benin', value: 'Benin' },
  //  { label: 'Bermuda', value: 'Bermuda' },
  //  { label: 'Bhutan', value: 'Bhutan' },
  //  { label: 'Bolivia', value: 'Bolivia' },
  //  { label: 'Bosnia And Herzegovina', value: 'Bosnia And Herzegovina' },
  //  { label: 'Botswana', value: 'Botswana' },
  //  { label: 'Brazil', value: 'Brazil' },
  //  { label: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory' },
  //  { label: 'British Virgin Islands', value: 'British Virgin Islands' },
  //  { label: 'Brunei', value: 'Brunei' },
  //  { label: 'Bulgaria', value: 'Bulgaria' },
  //  { label: 'Burkina Faso', value: 'Burkina Faso' },
  //  { label: 'Burundi', value: 'Burundi' },
  //  { label: 'Cambodia', value: 'Cambodia' },
  //  { label: 'Cameroon', value: 'Cameroon' },
  //  { label: 'Canada', value: 'Canada' },
  //  { label: 'Cape Verde', value: 'Cape Verde' },
  //  { label: 'Cayman Islands', value: 'Cayman Islands' },
  //  { label: 'Central African Republic', value: 'Central African Republic' },
  //  { label: 'Chad', value: 'Chad' },
  //  { label: 'Chile', value: 'Chile' },
  //  { label: 'China', value: 'China' },
  //  { label: 'Christmas Island', value: 'Christmas Island' },
  //  { label: 'Cocos Islands', value: 'Cocos Islands' },
  //  { label: 'Colombia', value: 'Colombia' },
  //  { label: 'Comoros', value: 'Comoros' },
  //  { label: 'Cook Islands', value: 'Cook Islands' },
  //  { label: 'Costa Rica', value: 'Costa Rica' },
  //  { label: 'Croatia', value: 'Croatia' },
  //  { label: 'Cuba', value: 'Cuba' },
  //  { label: 'Curacao', value: 'Curacao' },
  //  { label: 'Cyprus', value: 'Cyprus' },
  //  { label: 'Czech Republic', value: 'Czech Republic' },
  //  { label: 'Democratic Republic Of The Congo', value: 'Democratic Republic Of The Congo' },
  //  { label: 'Denmark', value: 'Denmark' },
  //  { label: 'Djibouti', value: 'Djibouti' },
  //  { label: 'Dominica', value: 'Dominica' },
  //  { label: 'Dominican Republic', value: 'Dominican Republic' },
  //  { label: 'East Timor', value: 'East Timor' },
  //  { label: 'Ecuador', value: 'Ecuador' },
  //  { label: 'Egypt', value: 'Egypt' },
  //  { label: 'El Salvador', value: 'El Salvador' },
  //  { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  //  { label: 'Eritrea', value: 'Eritrea' },
  //  { label: 'Estonia', value: 'Estonia' },
  //  { label: 'Ethiopia', value: 'Ethiopia' },
  //  { label: 'Falkland Islands', value: 'Falkland Islands' },
  //  { label: 'Faroe Islands', value: 'Faroe Islands' },
  //  { label: 'Fiji', value: 'Fiji' },
  //  { label: 'Finland', value: 'Finland' },
  //  { label: 'France', value: 'France' },
  //  { label: 'French Polynesia', value: 'French Polynesia' },
  //  { label: 'Gabon', value: 'Gabon' },
  //  { label: 'Gambia', value: 'Gambia' },
  //  { label: 'Georgia', value: 'Georgia' },
  //  { label: 'Germany', value: 'Germany' },
  //  { label: 'Ghana', value: 'Ghana' },
  //  { label: 'Gibraltar', value: 'Gibraltar' },
  //  { label: 'Greece', value: 'Greece' },
  //  { label: 'Greenland', value: 'Greenland' },
  //  { label: 'Grenada', value: 'Grenada' },
  //  { label: 'Guam', value: 'Guam' },
  //  { label: 'Guatemala', value: 'Guatemala' },
  //  { label: 'Guernsey', value: 'Guernsey' },
  //  { label: 'Guinea', value: 'Guinea' },
  //  { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  //  { label: 'Guyana', value: 'Guyana' },
  //  { label: 'Haiti', value: 'Haiti' },
  //  { label: 'Honduras', value: 'Honduras' },
  //  { label: 'Hong Kong', value: 'Hong Kong' },
  //  { label: 'Hungary', value: 'Hungary' },
  //  { label: 'Iceland', value: 'Iceland' },
  //  { label: 'India', value: 'India' },
  //  { label: 'Indonesia', value: 'Indonesia' },
  //  { label: 'Iran', value: 'Iran' },
  //  { label: 'Iraq', value: 'Iraq' },
  //  { label: 'Ireland', value: 'Ireland' },
  //  { label: 'Isle Of Man', value: 'Isle Of Man' },
  //  { label: 'Israel', value: 'Israel' },
  //  { label: 'Italy', value: 'Italy' },
  //  { label: 'Ivory Coast', value: 'Ivory Coast' },
  //  { label: 'Jamaica', value: 'Jamaica' },
  //  { label: 'Japan', value: 'Japan' },
  //  { label: 'Jersey', value: 'Jersey' },
  //  { label: 'Jordan', value: 'Jordan' },
  //  { label: 'Kazakhstan', value: 'Kazakhstan' },
  //  { label: 'Kenya', value: 'Kenya' },
  //  { label: 'Kiribati', value: 'Kiribati' },
  //  { label: 'Kosovo', value: 'Kosovo' },
  //  { label: 'Kuwait', value: 'Kuwait' },
  //  { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  //  { label: 'Laos', value: 'Laos' },
  //  { label: 'Latvia', value: 'Latvia' },
  //  { label: 'Lebanon', value: 'Lebanon' },
  //  { label: 'Lesotho', value: 'Lesotho' },
  //  { label: 'Liberia', value: 'Liberia' },
  //  { label: 'Libya', value: 'Libya' },
  //  { label: 'Liechtenstein', value: 'Liechtenstein' },
  //  { label: 'Lithuania', value: 'Lithuania' },
  //  { label: 'Luxembourg', value: 'Luxembourg' },
  //  { label: 'Macau', value: 'Macau' },
  //  { label: 'Macedonia', value: 'Macedonia' },
  //  { label: 'Madagascar', value: 'Madagascar' },
  //  { label: 'Malawi', value: 'Malawi' },
  //  { label: 'Malaysia', value: 'Malaysia' },
  //  { label: 'Maldives', value: 'Maldives' },
  //  { label: 'Mali', value: 'Mali' },
  //  { label: 'Malta', value: 'Malta' },
  //  { label: 'Marshall Islands', value: 'Marshall Islands' },
  //  { label: 'Mauritania', value: 'Mauritania' },
  //  { label: 'Mauritius', value: 'Mauritius' },
  //  { label: 'Mayotte', value: 'Mayotte' },
  //  { label: 'Mexico', value: 'Mexico' },
  //  { label: 'Micronesia', value: 'Micronesia' },
  //  { label: 'Moldova', value: 'Moldova' },
  //  { label: 'Monaco', value: 'Monaco' },
  //  { label: 'Mongolia', value: 'Mongolia' },
  //  { label: 'Montenegro', value: 'Montenegro' },
  //  { label: 'Montserrat', value: 'Montserrat' },
  //  { label: 'Morocco', value: 'Morocco' },
  //  { label: 'Mozambique', value: 'Mozambique' },
  //  { label: 'Myanmar', value: 'Myanmar' },
  //  { label: 'Namibia', value: 'Namibia' },
  //  { label: 'Nauru', value: 'Nauru' },
  //  { label: 'Nepal', value: 'Nepal' },
  //  { label: 'Netherlands', value: 'Netherlands' },
  //  { label: 'Netherlands Antilles', value: 'Netherlands Antilles' },
  //  { label: 'New Caledonia', value: 'New Caledonia' },
  //  { label: 'New Zealand', value: 'New Zealand' },
  //  { label: 'Nicaragua', value: 'Nicaragua' },
  //  { label: 'Niger', value: 'Niger' },
  //  { label: 'Nigeria', value: 'Nigeria' },
  //  { label: 'Niue', value: 'Niue' },
  //  { label: 'North Korea', value: 'North Korea' },
  //  { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  //  { label: 'Norway', value: 'Norway' },
  //  { label: 'Oman', value: 'Oman' },
  //  { label: 'Pakistan', value: 'Pakistan' },
  //  { label: 'Palau', value: 'Palau' },
  //  { label: 'Palestine', value: 'Palestine' },
  //  { label: 'Panama', value: 'Panama' },
  //  { label: 'Papua New Guinea', value: 'Papua New Guinea' },
  //  { label: 'Paraguay', value: 'Paraguay' },
  //  { label: 'Peru', value: 'Peru' },
  //  { label: 'Philippines', value: 'Philippines' },
  //  { label: 'Pitcairn', value: 'Pitcairn' },
  //  { label: 'Poland', value: 'Poland' },
  //  { label: 'Portugal', value: 'Portugal' },
  //  { label: 'Puerto Rico', value: 'Puerto Rico' },
  //  { label: 'Qatar', value: 'Qatar' },
  //  { label: 'Republic Of The Congo', value: 'Republic Of The Congo' },
  //  { label: 'Reunion', value: 'Reunion' },
  //  { label: 'Romania', value: 'Romania' },
  //  { label: 'Russia', value: 'Russia' },
  //  { label: 'Rwanda', value: 'Rwanda' },
  //  { label: 'Saint Barthelemy', value: 'Saint Barthelemy' },
  //  { label: 'Saint Helena', value: 'Saint Helena' },
  //  { label: 'Saint Kitts And Nevis', value: 'Saint Kitts And Nevis' },
  //  { label: 'Saint Lucia', value: 'Saint Lucia' },
  //  { label: 'Saint Martin', value: 'Saint Martin' },
  //  { label: 'Saint Pierre And Miquelon', value: 'Saint Pierre And Miquelon' },
  //  { label: 'Saint Vincent And The Grenadines', value: 'Saint Vincent And The Grenadines' },
  //  { label: 'Samoa', value: 'Samoa' },
  //  { label: 'San Marino', value: 'San Marino' },
  //  { label: 'Sao Tome And Principe', value: 'Sao Tome And Principe' },
  //  { label: 'Saudi Arabia', value: 'Saudi Arabia' },
  //  { label: 'Senegal', value: 'Senegal' },
  //  { label: 'Serbia', value: 'Serbia' },
  //  { label: 'Seychelles', value: 'Seychelles' },
  //  { label: 'Sierra Leone', value: 'Sierra Leone' },
  //  { label: 'Singapore', value: 'Singapore' },
  //  { label: 'Sint Maarten', value: 'Sint Maarten' },
  //  { label: 'Slovakia', value: 'Slovakia' },
  //  { label: 'Slovenia', value: 'Slovenia' },
  //  { label: 'Solomon Islands', value: 'Solomon Islands' },
  //  { label: 'Somalia', value: 'Somalia' },
  //  { label: 'South Africa', value: 'South Africa' },
  //  { label: 'South Korea', value: 'South Korea' },
  //  { label: 'South Sudan', value: 'South Sudan' },
  //  { label: 'Spain', value: 'Spain' },
  //  { label: 'Sri Lanka', value: 'Sri Lanka' },
  //  { label: 'Sudan', value: 'Sudan' },
  //  { label: 'Suriname', value: 'Suriname' },
  //  { label: 'Svalbard And Jan Mayen', value: 'Svalbard And Jan Mayen' },
  //  { label: 'Swaziland', value: 'Swaziland' },
  //  { label: 'Sweden', value: 'Sweden' },
  //  { label: 'Switzerland', value: 'Switzerland' },
  //  { label: 'Syria', value: 'Syria' },
  //  { label: 'Taiwan', value: 'Taiwan' },
  //  { label: 'Tajikistan', value: 'Tajikistan' },
  //  { label: 'Tanzania', value: 'Tanzania' },
  //  { label: 'Thailand', value: 'Thailand' },
  //  { label: 'Togo', value: 'Togo' },
  //  { label: 'Tokelau', value: 'Tokelau' },
  //  { label: 'Tonga', value: 'Tonga' },
  //  { label: 'Trinidad And Tobago', value: 'Trinidad And Tobago' },
  //  { label: 'Tunisia', value: 'Tunisia' },
  //  { label: 'Turkey', value: 'Turkey' },
  //  { label: 'Turkmenistan', value: 'Turkmenistan' },
  //  { label: 'Turks And Caicos Islands', value: 'Turks And Caicos Islands' },
  //  { label: 'Tuvalu', value: 'Tuvalu' },
  //  { label: 'U.S. Virgin Islands', value: 'U.S. Virgin Islands' },
  //  { label: 'Uganda', value: 'Uganda' },
  //  { label: 'Ukraine', value: 'Ukraine' },
  //  { label: 'United Arab Emirates', value: 'United Arab Emirates' },
  //  { label: 'United Kingdom', value: 'United Kingdom' },
  //  { label: 'United States', value: 'United States' },
  //  { label: 'Uruguay', value: 'Uruguay' },
  //  { label: 'Uzbekistan', value: 'Uzbekistan' },
  //  { label: 'Vanuatu', value: 'Vanuatu' },
  //  { label: 'Vatican', value: 'Vatican' },
  //  { label: 'Venezuela', value: 'Venezuela' },
  //  { label: 'Vietnam', value: 'Vietnam' },
  //  { label: 'Wallis And Futuna', value: 'Wallis And Futuna' },
  //  { label: 'Western Sahara', value: 'Western Sahara' },
  //  { label: 'Yemen', value: 'Yemen' },
  //  { label: 'Zambia', value: 'Zambia' },
  //  { label: 'Zimbabwe', value: 'Zimbabwe' }

// ]



import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const geoNames = [
  { label: 'Afghanistan', value: 'Afghanistan' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Algeria', value: 'Algeria' },
  { label: 'American Samoa', value: 'American Samoa' },
  { label: 'Andorra', value: 'Andorra' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Anguilla', value: 'Anguilla' },
  { label: 'Antarctica', value: 'Antarctica' },
  { label: 'Antigua And Barbuda', value: 'Antigua And Barbuda' },
  { label: 'Argentina', value: 'Argentina' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Aruba', value: 'Aruba' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Austria', value: 'Austria' },
  { label: 'Azerbaijan', value: 'Azerbaijan' },
  { label: 'Bahamas', value: 'Bahamas' },
  { label: 'Bahrain', value: 'Bahrain' },
  { label: 'Bangladesh', value: 'Bangladesh' },
  { label: 'Barbados', value: 'Barbados' },
  { label: 'Belarus', value: 'Belarus' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'Belize', value: 'Belize' },
  { label: 'Benin', value: 'Benin' },
  { label: 'Bermuda', value: 'Bermuda' },
  { label: 'Bhutan', value: 'Bhutan' },
  { label: 'Bolivia', value: 'Bolivia' },
  { label: 'Bosnia And Herzegovina', value: 'Bosnia And Herzegovina' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'Brazil', value: 'Brazil' },
  { label: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory' },
  { label: 'British Virgin Islands', value: 'British Virgin Islands' },
  { label: 'Brunei', value: 'Brunei' },
  { label: 'Bulgaria', value: 'Bulgaria' },
  { label: 'Burkina Faso', value: 'Burkina Faso' },
  { label: 'Burundi', value: 'Burundi' },
  { label: 'Cambodia', value: 'Cambodia' },
  { label: 'Cameroon', value: 'Cameroon' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Cape Verde', value: 'Cape Verde' },
  { label: 'Cayman Islands', value: 'Cayman Islands' },
  { label: 'Central African Republic', value: 'Central African Republic' },
  { label: 'Chad', value: 'Chad' },
  { label: 'Chile', value: 'Chile' },
  { label: 'China', value: 'China' },
  { label: 'Christmas Island', value: 'Christmas Island' },
  { label: 'Cocos Islands', value: 'Cocos Islands' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Comoros', value: 'Comoros' },
  { label: 'Cook Islands', value: 'Cook Islands' },
  { label: 'Costa Rica', value: 'Costa Rica' },
  { label: 'Croatia', value: 'Croatia' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Curacao', value: 'Curacao' },
  { label: 'Cyprus', value: 'Cyprus' },
  { label: 'Czech Republic', value: 'Czech Republic' },
  { label: 'Democratic Republic Of The Congo', value: 'Democratic Republic Of The Congo' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Djibouti', value: 'Djibouti' },
  { label: 'Dominica', value: 'Dominica' },
  { label: 'Dominican Republic', value: 'Dominican Republic' },
  { label: 'East Timor', value: 'East Timor' },
  { label: 'Ecuador', value: 'Ecuador' },
  { label: 'Egypt', value: 'Egypt' },
  { label: 'El Salvador', value: 'El Salvador' },
  { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { label: 'Eritrea', value: 'Eritrea' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Ethiopia', value: 'Ethiopia' },
  { label: 'Falkland Islands', value: 'Falkland Islands' },
  { label: 'Faroe Islands', value: 'Faroe Islands' },
  { label: 'Fiji', value: 'Fiji' },
  { label: 'Finland', value: 'Finland' },
  { label: 'France', value: 'France' },
  { label: 'French Polynesia', value: 'French Polynesia' },
  { label: 'Gabon', value: 'Gabon' },
  { label: 'Gambia', value: 'Gambia' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Ghana', value: 'Ghana' },
  { label: 'Gibraltar', value: 'Gibraltar' },
  { label: 'Greece', value: 'Greece' },
  { label: 'Greenland', value: 'Greenland' },
  { label: 'Grenada', value: 'Grenada' },
  { label: 'Guam', value: 'Guam' },
  { label: 'Guatemala', value: 'Guatemala' },
  { label: 'Guernsey', value: 'Guernsey' },
  { label: 'Guinea', value: 'Guinea' },
  { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { label: 'Guyana', value: 'Guyana' },
  { label: 'Haiti', value: 'Haiti' },
  { label: 'Honduras', value: 'Honduras' },
  { label: 'Hong Kong', value: 'Hong Kong' },
  { label: 'Hungary', value: 'Hungary' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'India', value: 'India' },
  { label: 'Indonesia', value: 'Indonesia' },
  { label: 'Iran', value: 'Iran' },
  { label: 'Iraq', value: 'Iraq' },
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Isle Of Man', value: 'Isle Of Man' },
  { label: 'Israel', value: 'Israel' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Ivory Coast', value: 'Ivory Coast' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Japan', value: 'Japan' },
  { label: 'Jersey', value: 'Jersey' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Kazakhstan', value: 'Kazakhstan' },
  { label: 'Kenya', value: 'Kenya' },
  { label: 'Kiribati', value: 'Kiribati' },
  { label: 'Kosovo', value: 'Kosovo' },
  { label: 'Kuwait', value: 'Kuwait' },
  { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { label: 'Laos', value: 'Laos' },
  { label: 'Latvia', value: 'Latvia' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lesotho', value: 'Lesotho' },
  { label: 'Liberia', value: 'Liberia' },
  { label: 'Libya', value: 'Libya' },
  { label: 'Liechtenstein', value: 'Liechtenstein' },
  { label: 'Lithuania', value: 'Lithuania' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Macau', value: 'Macau' },
  { label: 'Macedonia', value: 'Macedonia' },
  { label: 'Madagascar', value: 'Madagascar' },
  { label: 'Malawi', value: 'Malawi' },
  { label: 'Malaysia', value: 'Malaysia' },
  { label: 'Maldives', value: 'Maldives' },
  { label: 'Mali', value: 'Mali' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Marshall Islands', value: 'Marshall Islands' },
  { label: 'Mauritania', value: 'Mauritania' },
  { label: 'Mauritius', value: 'Mauritius' },
  { label: 'Mayotte', value: 'Mayotte' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Micronesia', value: 'Micronesia' },
  { label: 'Moldova', value: 'Moldova' },
  { label: 'Monaco', value: 'Monaco' },
  { label: 'Mongolia', value: 'Mongolia' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'Montserrat', value: 'Montserrat' },
  { label: 'Morocco', value: 'Morocco' },
  { label: 'Mozambique', value: 'Mozambique' },
  { label: 'Myanmar', value: 'Myanmar' },
  { label: 'Namibia', value: 'Namibia' },
  { label: 'Nauru', value: 'Nauru' },
  { label: 'Nepal', value: 'Nepal' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'Netherlands Antilles', value: 'Netherlands Antilles' },
  { label: 'New Caledonia', value: 'New Caledonia' },
  { label: 'New Zealand', value: 'New Zealand' },
  { label: 'Nicaragua', value: 'Nicaragua' },
  { label: 'Niger', value: 'Niger' },
  { label: 'Nigeria', value: 'Nigeria' },
  { label: 'Niue', value: 'Niue' },
  { label: 'North Korea', value: 'North Korea' },
  { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { label: 'Norway', value: 'Norway' },
  { label: 'Oman', value: 'Oman' },
  { label: 'Pakistan', value: 'Pakistan' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Palestine', value: 'Palestine' },
  { label: 'Panama', value: 'Panama' },
  { label: 'Papua New Guinea', value: 'Papua New Guinea' },
  { label: 'Paraguay', value: 'Paraguay' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Philippines', value: 'Philippines' },
  { label: 'Pitcairn', value: 'Pitcairn' },
  { label: 'Poland', value: 'Poland' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Qatar', value: 'Qatar' },
  { label: 'Republic Of The Congo', value: 'Republic Of The Congo' },
  { label: 'Reunion', value: 'Reunion' },
  { label: 'Romania', value: 'Romania' },
  { label: 'Russia', value: 'Russia' },
  { label: 'Rwanda', value: 'Rwanda' },
  { label: 'Saint Barthelemy', value: 'Saint Barthelemy' },
  { label: 'Saint Helena', value: 'Saint Helena' },
  { label: 'Saint Kitts And Nevis', value: 'Saint Kitts And Nevis' },
  { label: 'Saint Lucia', value: 'Saint Lucia' },
  { label: 'Saint Martin', value: 'Saint Martin' },
  { label: 'Saint Pierre And Miquelon', value: 'Saint Pierre And Miquelon' },
  { label: 'Saint Vincent And The Grenadines', value: 'Saint Vincent And The Grenadines' },
  { label: 'Samoa', value: 'Samoa' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'Sao Tome And Principe', value: 'Sao Tome And Principe' },
  { label: 'Saudi Arabia', value: 'Saudi Arabia' },
  { label: 'Senegal', value: 'Senegal' },
  { label: 'Serbia', value: 'Serbia' },
  { label: 'Seychelles', value: 'Seychelles' },
  { label: 'Sierra Leone', value: 'Sierra Leone' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Sint Maarten', value: 'Sint Maarten' },
  { label: 'Slovakia', value: 'Slovakia' },
  { label: 'Slovenia', value: 'Slovenia' },
  { label: 'Solomon Islands', value: 'Solomon Islands' },
  { label: 'Somalia', value: 'Somalia' },
  { label: 'South Africa', value: 'South Africa' },
  { label: 'South Korea', value: 'South Korea' },
  { label: 'South Sudan', value: 'South Sudan' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Sri Lanka', value: 'Sri Lanka' },
  { label: 'Sudan', value: 'Sudan' },
  { label: 'Suriname', value: 'Suriname' },
  { label: 'Svalbard And Jan Mayen', value: 'Svalbard And Jan Mayen' },
  { label: 'Swaziland', value: 'Swaziland' },
  { label: 'Sweden', value: 'Sweden' },
  { label: 'Switzerland', value: 'Switzerland' },
  { label: 'Syria', value: 'Syria' },
  { label: 'Taiwan', value: 'Taiwan' },
  { label: 'Tajikistan', value: 'Tajikistan' },
  { label: 'Tanzania', value: 'Tanzania' },
  { label: 'Thailand', value: 'Thailand' },
  { label: 'Togo', value: 'Togo' },
  { label: 'Tokelau', value: 'Tokelau' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Trinidad And Tobago', value: 'Trinidad And Tobago' },
  { label: 'Tunisia', value: 'Tunisia' },
  { label: 'Turkey', value: 'Turkey' },
  { label: 'Turkmenistan', value: 'Turkmenistan' },
  { label: 'Turks And Caicos Islands', value: 'Turks And Caicos Islands' },
  { label: 'Tuvalu', value: 'Tuvalu' },
  { label: 'U.S. Virgin Islands', value: 'U.S. Virgin Islands' },
  { label: 'Uganda', value: 'Uganda' },
  { label: 'Ukraine', value: 'Ukraine' },
  { label: 'United Arab Emirates', value: 'United Arab Emirates' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'United States', value: 'United States' },
  { label: 'Uruguay', value: 'Uruguay' },
  { label: 'Uzbekistan', value: 'Uzbekistan' },
  { label: 'Vanuatu', value: 'Vanuatu' },
  { label: 'Vatican', value: 'Vatican' },
  { label: 'Venezuela', value: 'Venezuela' },
  { label: 'Vietnam', value: 'Vietnam' },
  { label: 'Wallis And Futuna', value: 'Wallis And Futuna' },
  { label: 'Western Sahara', value: 'Western Sahara' },
  { label: 'Yemen', value: 'Yemen' },
  { label: 'Zambia', value: 'Zambia' },
  { label: 'Zimbabwe', value: 'Zimbabwe' }
];

const Geo = ({ selectedGeo, handleSelectChange }) => {
  const animatedComponents = makeAnimated();

  return (
    <div>
      <Select
        isMulti
        value={selectedGeo}
        onChange={handleSelectChange}
        options={geoNames}
        placeholder="Select Country..."
        components={animatedComponents}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default Geo;










// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const Geo = ({ data, selectedGeo, handleSelectChange }) => {
//   const geoNames = [...new Set(data.map((item) => item.Country))].map((geo) => ({
//     value: geo,
//     label: geo,
//   }));

//   // Sort the city names in alphabetical order
//   geoNames.sort((a, b) => a.label.localeCompare(b.label));  

// const animatedComponents = makeAnimated();  

//   return (
//     <div>
      // <Select
      //   isMulti
      //   value={selectedGeo}
      //   onChange={handleSelectChange}
      //   options={geoNames}
      //   placeholder="Select Country..."
      //   components={animatedComponents}
      //   closeMenuOnSelect={false}
      // />
//     </div>
//   );
// };

// export default Geo;
