// import React, { useState, useEffect } from 'react';
// import { utils, write } from 'xlsx';
// import { Buffer } from 'buffer';
// import axios from 'axios'
// import CountUp from 'react-countup';
// import Login from './Components/Login';
// import Logout from './Components/Logout';
// import './App.css';

// import Navbar from './Components/Navbar';
// import JobLevel from './Components/Joblevel';
// import StateNames from './Components/StateNames';
// import Keyword from './Components/Keyword';
// import CityNames from './Components/CityNames';
// import Geo from './Components/Geo';
// import JobFunction from './Components/JobFunction';
// import EmployeeSize from './Components/EmployeeSize';
// import Industry from './Components/Industry';
// import SubIndustry from './Components/SubIndustry';
// import SIC from './Components/Sic';
// import NAICS from './Components/NAICSCode';
// import Revenue from './Components/Revenue';
// import DateRangeFilter from './Components/DateRangeFilter';

// const App = () => {
//   const [data, setData] = useState([]);
//   const [selectedKeyword, setSelectedKeyword] = useState([]);
//   const [jobLevelOptions, setJobLevelOptions] = useState([]);
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [selectedCities, setSelectedCities] = useState([]);
//   const [selectedGeo, setSelectedGeo] = useState([]);
//   const [selectedJobFunction, setSelectedJobFunction] = useState([]);
//   const [selectedEmployeeSize, setSelectedEmployeeSize] = useState([]);
//   const [selectedIndustry, setSelectedIndustry] = useState([]);
//   const [selectedSubIndustry, setSelectedSubIndustry] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [selectedSicCode, setSelectedSicCode] = useState([]);
//   const [selectedNaicsCode, setSelectedNaicsCode] = useState([]);
//   const [selectedRevenue, setSelectedRevenue] = useState([]); 
  
  
//   const [isLoggedIn, setIsLoggedIn] = useState(false); // new state to track login status
//   const [username, setUsername] = useState(''); // new state to store username
//   const [password, setPassword] = useState(''); // new state to store password
//   const [loginError, setLoginError] = useState('');
//   const [userRole, setUserRole] = useState('');

//   const [totalDataCount, setTotalDataCount] = useState(0);
//   const [count, setCount] = useState(0);
//   const [previousCount, setPreviousCount] = useState(0);
//   const [exportDisabled, setExportDisabled] = useState(false); // new state to track export button disable
//   const [exportMessage, setExportMessage] = useState(''); // new state to track export message


//   useEffect(() => {
//     fetch('http://localhost:3002/')
//       .then(response => {
//         if (!response.ok) {
//           throw new Error(`Error fetching data: ${response.status}`);
//         }
//         return response.json();
//       })
//       .then(data => {
//         setData(data);
//         setTotalDataCount(data.length);
//       })
//       .catch(error => {
//         console.error('Error parsing JSON:', error);
//       });
//   }, []);


//   // useEffect(() => {
//   //   fetch('http://localhost:3002/data')
//   //     .then(response => response.json())
//   //     .then(data => {
//   //       setData(data);
//   //       setTotalDataCount(data.length);
//   //     });
//   // }, []);


//   useEffect(() => {
//     let filteredData = data;
  
//     if (selectedKeyword.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedKeyword.some((option) => item.Original_Job_Title !== null && item.Original_Job_Title.includes(option.value));
//       });
//     }
  
//     if (jobLevelOptions.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return jobLevelOptions.some((option) => item.JobLevel !== null && item.JobLevel.includes(option.value));
//       });
//     }
  
//     if (selectedJobFunction.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedJobFunction.some((option) => item.JobFunction !== null && item.JobFunction.includes(option.value));
//       });
//     }
  
//     if (selectedGeo.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedGeo.some((option) => item.Country !== null && item.Country.includes(option.value));
//       });
//     }
  
//     if (selectedStates.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedStates.some((option) => item.State !== null && item.State.includes(option.value));
//       });
//     }
  
//     if (selectedCities.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedCities.some((option) => item.City !== null && item.City.includes(option.value));
//       });
//     }
  
//     if (selectedIndustry.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedIndustry.some((option) => item.OriginalIndustry !== null && item.OriginalIndustry.includes(option.value));
//       });
//     }
  
//     if (selectedSubIndustry.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedSubIndustry.some((option) => item.SubIndustry !== null && item.SubIndustry.includes(option.value));
//       });
//     }
  
//     if (selectedSicCode.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedSicCode.some((option) => item.SIC !== null && item.SIC.includes(option.value));
//       });
//     }
  
//     if (selectedNaicsCode.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedNaicsCode.some((option) => item.NAICS !== null && item.NAICS.includes(option.value));
//       });
//     }
  
//     if (selectedEmployeeSize.length > 0) {

//       filteredData = selectedEmployeeSize.reduce((acc, curr) => {
//         const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
//         return acc.concat(filteredData.filter((item) => {
//           const empSize = parseInt(item.Employee_Size.replace(/,/g, ''));
//           return empSize >= minValue && empSize <= maxValue;
//         }));
//       }, []);
  
//     }
  
  
//     if (selectedRevenue.length > 0) {
//       filteredData = selectedRevenue.flatMap((curr) => {
//         const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
//         return data.filter((item) => {
//           if (item.Revenue_Size !== null && item.Revenue_Size !== undefined) {
//             const revenueSizeString = item.Revenue_Size;
//             const revenueSize = parseFloat(revenueSizeString.replace('$', '').replace(' Million', 'e6').replace(' Billion', 'e9'));
//             return revenueSize >= minValue && revenueSize <= maxValue;
//           } else {
//             return false; // or some other logic to handle this case
//           }
//         });
//       });
//     }
  
//     setFilteredData(filteredData);
//     setCount(filteredData.length);
  
//     // check if filtered records exceed 1 lakh
//     if (count > 100000) {
//       setExportDisabled(true);
//       setExportMessage('Data cannot be exported, Please contact Admin');
//     } else {
//       setExportDisabled(false);
//       setExportMessage('');
//     }
//   }, [selectedKeyword, jobLevelOptions, selectedStates, selectedCities, selectedGeo, selectedJobFunction, selectedEmployeeSize, selectedIndustry, selectedSubIndustry, selectedSicCode, selectedNaicsCode, selectedRevenue, data]);

//   const handleKeywordChange = (options) => {
//     setSelectedKeyword(options);
//   };

//   const handleJobLevelChange = (options) => {
//     setJobLevelOptions(options);
//   };

//   const handleJobFunctionChange = (options) => {
//     setSelectedJobFunction(options);
//   }; 

//   const handleGeoChange = (options) => {
//     setSelectedGeo(options);
//   };  

//   const handleStateChange = (options) => {
//     setSelectedStates(options);
//   };

//   const handleCityChange = (options) => {
//     setSelectedCities(options);
//   };

//   const handleEmployeeSizeChange = (options) => {
//     setSelectedEmployeeSize(options);
//   };

//   const handleIndustryChange = (options) => {
//     setSelectedIndustry(options);
//   };

//   const handleSubIndustryChange = (options) => {
//     setSelectedSubIndustry(options);
//   };

//   const handleSicCodeChange = (options) => {
//     setSelectedSicCode(options);
//   };

//   const handleNaicsCodeChange = (options) => {
//     setSelectedNaicsCode(options);
//   };

//   const handleRevenueChange = (options) => {
//     setSelectedRevenue(options);
//   };

//   const handleExportToExcel = () => {
//     if (!exportDisabled) {
//       const worksheet = utils.json_to_sheet(filteredData);
//       const workbook = utils.book_new();
//       utils.book_append_sheet(workbook, worksheet, 'Data');
//       const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'buffer' });
//       const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${Buffer.from(excelBuffer).toString('base64')}`;
//       const link = document.createElement('a');
//       link.href = dataURI;
//       link.download = 'Export_data.xlsx';
//       link.click();
//     }
//   };


//   const handleReset = () => {
//     setSelectedKeyword([]);
//     setJobLevelOptions([]);
//     setSelectedStates([]);
//     setSelectedCities([]);
//     setSelectedGeo([]);
//     setSelectedJobFunction([]);
//     setSelectedEmployeeSize([]);
//     setSelectedIndustry([]);
//     setSelectedSubIndustry([]);
//     setSelectedSicCode([]);
//     setSelectedNaicsCode([]);
//     setSelectedRevenue([]);
//   };

//   const handleLogin = (username, password) => {
//     if (username === 'admin' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('admin');
//     } else if (username === 'operations' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('operations');
//     } else if (username === 'sales' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('sales');
//     } else {
//       setLoginError('Incorrect ID or Password. Please contact admin');
//     }
//   };

//   const handleLogout = () => {setIsLoggedIn(false);};



//   return (
//     <div>
//       {isLoggedIn ? (
//         <div>
//           <Navbar title="DM Database" logout={<Logout handleLogout={handleLogout} />} />
//           <div className='top-container'>
//             <div className='columns-container' style={{
//               display: 'grid',
//               gridTemplateColumns: '1fr 1fr',
//               justifyContent: 'center',
//               alignItems: 'center',
//               textAlign: 'center',
//               maxWidth: 800, /* add max-width to match the width of other filters */
//             }}>
//               <div className='column total-records'>
//                 <p>
//                   <strong style={{ fontSize: 18, marginRight: 10 }}>TOTAL RECORDS :</strong>
//                   <span style={{ fontSize: 24, fontWeight: 500, }}>{totalDataCount}</span>
//                 </p>
//               </div>
//               <div className='column filtered-records'>
//                 <p>
//                   <strong style={{ fontSize: 18, marginRight: 10 }}>FILTERED RECORDS :</strong>
//                   <CountUp
//                     key={count} // re-render with new value
//                     start={previousCount} // set starting value to previous count
//                     end={count}
//                     duration={2}
//                     separator=","
//                     decimals={0}
//                     prefix=""
//                     suffix=""
//                     onEnd={() => {
//                       setPreviousCount(count); // update previous count
//                       console.log("Finished counting");
//                     }}
//                     style={{ fontSize: 24, fontWeight: 500, color: count > 100000 ? 'red' : '#F4EBD0' }}
//                   />
//                 </p>
//               </div>
//             </div>
//           </div>
  
//           <div className='container'>
//             <div style={{ marginBottom: 20 }}>
//               <Keyword 
//                 data={data} 
//                 selectedKeyword={selectedKeyword} 
//                 handleSelectChange={handleKeywordChange} 
//               />
//             </div>
            
//             <div style={{ marginBottom: 20 }}>
//               <JobFunction 
//                 data={data} 
//                 selectedJobFunction={selectedJobFunction} 
//                 handleSelectChange={handleJobFunctionChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <JobLevel 
//                 data={data} 
//                 jobLevelOptions={jobLevelOptions} 
//                 handleSelectChange={handleJobLevelChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <Industry 
//                 data={data} 
//                 selectedIndustry={selectedIndustry} 
//                 handleSelectChange={handleIndustryChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <SubIndustry 
//                 data={data} 
//                 selectedSubIndustry={selectedSubIndustry} 
//                 handleSelectChange={handleSubIndustryChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <Geo 
//                 data={data} 
//                 selectedGeo={selectedGeo} 
//                 handleSelectChange={handleGeoChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <StateNames 
//                 data={data} 
//                 selectedStates={selectedStates} 
//                 handleSelectChange={handleStateChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <CityNames 
//                 data={data} 
//                 selectedCities={selectedCities} 
//                 handleSelectChange={handleCityChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <EmployeeSize 
//                 data={data} 
//                 selectedEmployeeSize={selectedEmployeeSize} 
//                 handleSelectChange={handleEmployeeSizeChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <SIC
//                 data={data} 
//                 selectedSicCode={selectedSicCode} 
//                 handleSelectChange={handleSicCodeChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <NAICS
//                 data={data} 
//                 selectedNaicsCode={selectedNaicsCode} 
//                 handleSelectChange={handleNaicsCodeChange} 
//               />
//             </div>   
//             <div style={{ marginBottom: 40 }}>
//               <Revenue
//                 data={data} 
//                 selectedRevenue={selectedRevenue} 
//                 handleSelectChange={handleRevenueChange} 
//               />
//             </div>       

//             <div style={{ textAlign: 'right' }}>
//             <button button style={{ marginRight: 10 }} onClick={handleReset}><b>Reset Filters</b></button>
//               {exportDisabled ? (
//                 <p style={{ color: 'red' }}>{exportMessage}</p>
//             ) : (
//               userRole !== 'sales' && (
//             <button onClick={handleExportToExcel}><b>Export to Excel</b></button>
//     )
//   )}
// </div>
//           </div>
//         </div>
//       ) : (
//         <Login handleLogin={handleLogin} loginError={loginError} />
//       )}
//     </div>
//   );
// };

// export default App;








//Data filter code filter
// import React, { useState, useEffect } from 'react';
// import { utils, write } from 'xlsx';
// import { Buffer } from 'buffer';
// import axios from 'axios'
// import CountUp from 'react-countup';
// import Login from './Components/Login';
// import Logout from './Components/Logout';
// import './App.css';
// import useUploadSuppression from './Components/suppression';

// import Navbar from './Components/Navbar';
// import JobLevel from './Components/Joblevel';
// import StateNames from './Components/StateNames';
// import Keyword from './Components/Keyword';
// import CityNames from './Components/CityNames';
// import Geo from './Components/Geo';
// import JobFunction from './Components/JobFunction';
// import EmployeeSize from './Components/EmployeeSize';
// import Industry from './Components/Industry';
// import SubIndustry from './Components/SubIndustry';
// import SIC from './Components/Sic';
// import NAICS from './Components/NAICSCode';
// import Revenue from './Components/Revenue';
// import DateRangeFilter from './Components/DateRangeFilter';


// const App = () => {
//   const [data, setData] = useState([]);
//   const [selectedKeyword, setSelectedKeyword] = useState([]);
//   const [jobLevelOptions, setJobLevelOptions] = useState([]);
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [selectedCities, setSelectedCities] = useState([]);
//   const [selectedGeo, setSelectedGeo] = useState([]);
//   const [selectedJobFunction, setSelectedJobFunction] = useState([]);
//   const [selectedEmployeeSize, setSelectedEmployeeSize] = useState([]);
//   const [selectedIndustry, setSelectedIndustry] = useState([]);
//   const [selectedSubIndustry, setSelectedSubIndustry] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [selectedSicCode, setSelectedSicCode] = useState([]);
//   const [selectedNaicsCode, setSelectedNaicsCode] = useState([]);
//   const [selectedRevenue, setSelectedRevenue] = useState([]); 
//   const [selectedDateRange, setSelectedDateRange] = useState([]);
//   const [resetFilters, setResetFilters] = useState(false);

//   const { file, handleFileChange, applySuppression } = useUploadSuppression();
//   const [ setFile] = useState(null);
  
//   const [isLoggedIn, setIsLoggedIn] = useState(false); // new state to track login status
//   const [username, setUsername] = useState(''); // new state to store username
//   const [password, setPassword] = useState(''); // new state to store password
//   const [loginError, setLoginError] = useState('');
//   const [userRole, setUserRole] = useState('');

//   const [totalDataCount, setTotalDataCount] = useState(0);
//   const [count, setCount] = useState(0);
//   const [previousCount, setPreviousCount] = useState(0);
//   const [exportDisabled, setExportDisabled] = useState(false); // new state to track export button disable
//   const [exportMessage, setExportMessage] = useState(''); // new state to track export message




//   useEffect(() => {
//     fetch('http://localhost:3002/')
//       .then(response => {
//         if (!response.ok) {
//           throw new Error(`Error fetching data: ${response.status}`);
//         }
//         return response.json();
//       })
//       .then(data => {
//         setData(data);
//         setTotalDataCount(data.length);
//       })
//       .catch(error => {
//         console.error('Error parsing JSON:', error);
//       });
//   }, []);


//   // useEffect(() => {
//   //   fetch('http://localhost:3002/data')
//   //     .then(response => response.json())
//   //     .then(data => {
//   //       setData(data);
//   //       setTotalDataCount(data.length);
//   //     });
//   // }, []);


//   useEffect(() => {
//     let filteredData = data;
  
//     if (selectedKeyword.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedKeyword.some((option) => item.Original_Job_Title !== null && item.Original_Job_Title.includes(option.value));
//       });
//     }
  
//     if (jobLevelOptions.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return jobLevelOptions.some((option) => item.JobLevel !== null && item.JobLevel.includes(option.value));
//       });
//     }
  
//     if (selectedJobFunction.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedJobFunction.some((option) => item.JobFunction !== null && item.JobFunction.includes(option.value));
//       });
//     }
  
//     if (selectedGeo.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedGeo.some((option) => item.Country !== null && item.Country.includes(option.value));
//       });
//     }
  
//     if (selectedStates.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedStates.some((option) => item.State !== null && item.State.includes(option.value));
//       });
//     }
  
//     if (selectedCities.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedCities.some((option) => item.City !== null && item.City.includes(option.value));
//       });
//     }
  
//     if (selectedIndustry.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedIndustry.some((option) => item.OriginalIndustry !== null && item.OriginalIndustry.includes(option.value));
//       });
//     }
  
//     if (selectedSubIndustry.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedSubIndustry.some((option) => item.SubIndustry !== null && item.SubIndustry.includes(option.value));
//       });
//     }
  
//     if (selectedSicCode.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedSicCode.some((option) => item.SIC !== null && item.SIC.includes(option.value));
//       });
//     }
  
//     if (selectedNaicsCode.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedNaicsCode.some((option) => item.NAICS !== null && item.NAICS.includes(option.value));
//       });
//     }

//     if (selectedDateRange.length > 0) {

//       filteredData = selectedDateRange;
//     }
  
//     if (selectedEmployeeSize.length > 0) {

//       filteredData = selectedEmployeeSize.reduce((acc, curr) => {
//         const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
//         return acc.concat(filteredData.filter((item) => {
//           const empSize = parseInt(item.Employee_Size.replace(/,/g, ''));
//           return empSize >= minValue && empSize <= maxValue;
//         }));
//       }, []);
  
//     }
  
  
//     if (selectedRevenue.length > 0) {
//       filteredData = selectedRevenue.flatMap((curr) => {
//         const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
//         return data.filter((item) => {
//           if (item.Revenue_Size !== null && item.Revenue_Size !== undefined) {
//             const revenueSizeString = item.Revenue_Size;
//             const revenueSize = parseFloat(revenueSizeString.replace('$', '').replace(' Million', 'e6').replace(' Billion', 'e9'));
//             return revenueSize >= minValue && revenueSize <= maxValue;
//           } else {
//             return false; // or some other logic to handle this case
//           }
//         });
//       });
//     }

//     if (file) {
//       filteredData = applySuppression(filteredData);
//     }
  
//     setFilteredData(filteredData);
//     setCount(filteredData.length);
  
//     // check if filtered records exceed 1 lakh
//     if (count > 100000) {
//       setExportDisabled(true);
//       setExportMessage('Data cannot be exported, Please contact Admin');
//     } else {
//       setExportDisabled(false);
//       setExportMessage('');
//     }
//   }, [selectedKeyword, jobLevelOptions, selectedStates, selectedCities, selectedGeo, selectedJobFunction, selectedEmployeeSize, selectedIndustry, selectedSubIndustry, selectedSicCode, selectedNaicsCode, selectedRevenue,selectedDateRange, data, file, applySuppression]);

//   const handleKeywordChange = (options) => {
//     setSelectedKeyword(options);
//   };

//   const handleJobLevelChange = (options) => {
//     setJobLevelOptions(options);
//   };

//   const handleJobFunctionChange = (options) => {
//     setSelectedJobFunction(options);
//   }; 

//   const handleGeoChange = (options) => {
//     setSelectedGeo(options);
//   };  

//   const handleStateChange = (options) => {
//     setSelectedStates(options);
//   };

//   const handleCityChange = (options) => {
//     setSelectedCities(options);
//   };

//   const handleEmployeeSizeChange = (options) => {
//     setSelectedEmployeeSize(options);
//   };

//   const handleIndustryChange = (options) => {
//     setSelectedIndustry(options);
//   };

//   const handleSubIndustryChange = (options) => {
//     setSelectedSubIndustry(options);
//   };

//   const handleSicCodeChange = (options) => {
//     setSelectedSicCode(options);
//   };

//   const handleNaicsCodeChange = (options) => {
//     setSelectedNaicsCode(options);
//   };

//   const handleRevenueChange = (options) => {
//     setSelectedRevenue(options);
//   };

//   const handleDateRangeChange = (filteredData) => {
//     setSelectedDateRange(filteredData);
//   };

//   const handleExportToExcel = () => {
//     if (!exportDisabled) {
//       const worksheet = utils.json_to_sheet(filteredData);
//       const workbook = utils.book_new();
//       utils.book_append_sheet(workbook, worksheet, 'Data');
//       const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'buffer' });
//       const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${Buffer.from(excelBuffer).toString('base64')}`;
//       const link = document.createElement('a');
//       link.href = dataURI;
//       link.download = 'Export_data.xlsx';
//       link.click();
//     }
//   };

//   const handleResetFiltersClick = () => {
//     setResetFilters(true);
//   };

//   const handleReset = () => {
//     setSelectedKeyword([]);
//     setJobLevelOptions([]);
//     setSelectedStates([]);
//     setSelectedCities([]);
//     setSelectedGeo([]);
//     setSelectedJobFunction([]);
//     setSelectedEmployeeSize([]);
//     setSelectedIndustry([]);
//     setSelectedSubIndustry([]);
//     setSelectedSicCode([]);
//     setSelectedNaicsCode([]);
//     setSelectedRevenue([]);
//     setSelectedDateRange([]);
//     setFile(null); // Reset the file state variable
//     setResetFilters(false); // Reset the resetFilters state variable
//   };

//   const handleLogin = (username, password) => {
//     if (username === 'admin' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('admin');
//     } else if (username === 'operations' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('operations');
//     } else if (username === 'sales' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('sales');
//     } else {
//       setLoginError('Incorrect ID or Password. Please contact admin');
//     }
//   };

//   const handleLogout = () => {setIsLoggedIn(false);};


//   return (
//     <div>
//       {isLoggedIn ? (
//         <div>
//           <Navbar title="DM Database" logout={<Logout handleLogout={handleLogout} />} />
//           <div className='top-container'>
//             <div className='columns-container' style={{
//               display: 'grid',
//               gridTemplateColumns: '1fr 1fr',
//               justifyContent: 'center',
//               alignItems: 'center',
//               textAlign: 'center',
//               maxWidth: 800, /* add max-width to match the width of other filters */
//             }}>
//               <div className='column total-records'>
//                 <p>
//                   <strong style={{ fontSize: 18, marginRight: 10 }}>TOTAL RECORDS :</strong>
//                   <span style={{ fontSize: 24, fontWeight: 500, }}>{totalDataCount}</span>
//                 </p>
//               </div>
//               <div className='column filtered-records'>
//                 <p>
//                   <strong style={{ fontSize: 18, marginRight: 10 }}>FILTERED RECORDS :</strong>
//                   <CountUp
//                     key={count} // re-render with new value
//                     start={previousCount} // set starting value to previous count
//                     end={count}
//                     duration={2}
//                     separator=","
//                     decimals={0}
//                     prefix=""
//                     suffix=""
//                     onEnd={() => {
//                       setPreviousCount(count); // update previous count
//                       console.log("Finished counting");
//                     }}
//                     style={{ fontSize: 24, fontWeight: 500, color: count > 100000 ? 'red' : '#F4EBD0' }}
//                   />
//                 </p>
//               </div>
//             </div>
//           </div>
  
//           <div className='container'>

//             <div style={{ marginBottom: 20 }}>
//               <DateRangeFilter 
//                 data={data} 
//                 handleSelectChange={handleDateRangeChange} 
//                 />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Keywords :</b></label>
//               <Keyword 
//                 data={data} 
//                 selectedKeyword={selectedKeyword} 
//                 handleSelectChange={handleKeywordChange} 
//               />
//             </div>
            
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Job Function :</b></label>
//               <JobFunction 
//                 data={data} 
//                 selectedJobFunction={selectedJobFunction} 
//                 handleSelectChange={handleJobFunctionChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Job Level :</b></label>
//               <JobLevel 
//                 data={data} 
//                 jobLevelOptions={jobLevelOptions} 
//                 handleSelectChange={handleJobLevelChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Industry :</b></label>
//               <Industry 
//                 data={data} 
//                 selectedIndustry={selectedIndustry} 
//                 handleSelectChange={handleIndustryChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>SubIndustry :</b></label>
//               <SubIndustry 
//                 data={data} 
//                 selectedSubIndustry={selectedSubIndustry} 
//                 handleSelectChange={handleSubIndustryChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Country :</b></label>
//               <Geo 
//                 data={data} 
//                 selectedGeo={selectedGeo} 
//                 handleSelectChange={handleGeoChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>States :</b></label>
//               <StateNames 
//                 data={data} 
//                 selectedStates={selectedStates} 
//                 handleSelectChange={handleStateChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>City :</b></label>
//               <CityNames 
//                 data={data} 
//                 selectedCities={selectedCities} 
//                 handleSelectChange={handleCityChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Employee Size :</b></label>
//               <EmployeeSize 
//                 data={data} 
//                 selectedEmployeeSize={selectedEmployeeSize} 
//                 handleSelectChange={handleEmployeeSizeChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>SIC :</b></label>
//               <SIC
//                 data={data} 
//                 selectedSicCode={selectedSicCode} 
//                 handleSelectChange={handleSicCodeChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>NAICS :</b></label>
//               <NAICS
//                 data={data} 
//                 selectedNaicsCode={selectedNaicsCode} 
//                 handleSelectChange={handleNaicsCodeChange} 
//               />
//             </div>   
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Revenue :</b></label>
//               <Revenue
//                 data={data} 
//                 selectedRevenue={selectedRevenue} 
//                 handleSelectChange={handleRevenueChange} 
//               />
//             </div>       
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Upload Suppression:</b></label>
//               <input type="file" onChange={(e) => handleFileChange(e.target.files[0])} />
//             </div>

//             <div style={{ backgroundColor: 'white', padding:10, marginBottom:20, borderRadius: 10}}>
//           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//   <div>

//   </div>
//   <div>

//   </div>
// </div>
// </div>      
// <div style={{ textAlign: 'right' }}>
//   <button style={{ marginRight: 10 }} onClick={() => { handleReset([]); }}>
//     <b>Reset Filters</b>
//   </button>

//   {exportDisabled ? (
//     <p style={{ color: 'red' }}>{exportMessage}</p>
//   ) : (
//     userRole !== 'sales' && (
//       <button onClick={handleExportToExcel}><b>Export to Excel</b></button>
//     )
//   )}
  
// </div>
//         </div>
//       </div>
//       ) : (
//         <Login handleLogin={handleLogin} loginError={loginError} />
//       )}
//     </div>
//   );
  
// };

// export default App;







//Currently Using - Version 1
// import React, { useState, useEffect, useCallback } from 'react';
// import { utils, write } from 'xlsx';
// import { Buffer } from 'buffer';
// import axios from 'axios'
// import CountUp from 'react-countup';
// import Login from './Components/Login';
// import Logout from './Components/Logout';
// import './App.css';
// import useUploadSuppression from './Components/suppression';
// import useUploadTAL from './Components/TAL';

// import Navbar from './Components/Navbar';
// import JobLevel from './Components/Joblevel';
// import StateNames from './Components/StateNames';
// import Keyword from './Components/Keyword';
// import CityNames from './Components/CityNames';
// import Geo from './Components/Geo';
// import JobFunction from './Components/JobFunction';
// import EmployeeSize from './Components/EmployeeSize';
// import Industry from './Components/Industry';
// import SubIndustry from './Components/SubIndustry';
// import SIC from './Components/Sic';
// import NAICS from './Components/NAICSCode';
// import Revenue from './Components/Revenue';
// import DateRangeFilter from './Components/DateRangeFilter';
// import ProgressBar from 'react-progressbar';
// import debounce from 'lodash.debounce';
// import XLSX from 'xlsx';
// import LZString from 'lz-string';



// const App = () => {
//   const [data, setData] = useState([]);
//   const [selectedKeyword, setSelectedKeyword] = useState([]);
//   const [jobLevelOptions, setJobLevelOptions] = useState([]);
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [selectedCities, setSelectedCities] = useState([]);
//   const [selectedGeo, setSelectedGeo] = useState([]);
//   const [selectedJobFunction, setSelectedJobFunction] = useState([]);
//   const [selectedEmployeeSize, setSelectedEmployeeSize] = useState([]);
//   const [selectedIndustry, setSelectedIndustry] = useState([]);
//   const [selectedSubIndustry, setSelectedSubIndustry] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [selectedSicCode, setSelectedSicCode] = useState([]);
//   const [selectedNaicsCode, setSelectedNaicsCode] = useState([]);
//   const [selectedRevenue, setSelectedRevenue] = useState([]); 
//   const [selectedDateRange, setSelectedDateRange] = useState([]);
//   const [resetFilters, setResetFilters] = useState(false);
//   const [exporting, setExporting] = useState(false);
//   const [error, setError] = useState(null); 

//   const [ setFile] = useState(null);
//   const { file: suppressionFile, handleFileChange: handleSuppressionFileChange, applySuppression } = useUploadSuppression();
//   const { file: talFile, handleFileChange: handleTALFileChange, applyTAL } = useUploadTAL();

//   const [isLoggedIn, setIsLoggedIn] = useState(false); // new state to track login status
//   const [username, setUsername] = useState(''); // new state to store username
//   const [password, setPassword] = useState(''); // new state to store password
//   const [loginError, setLoginError] = useState('');
//   const [userRole, setUserRole] = useState('');

//   const [totalDataCount, setTotalDataCount] = useState(0);
//   const [count, setCount] = useState(0);
//   const [previousCount, setPreviousCount] = useState(0);
//   const [exportDisabled, setExportDisabled] = useState(false); // new state to track export button disable
//   const [exportMessage, setExportMessage] = useState(''); // new state to track export message

//   const filterData = useCallback((data) => {
//     let filteredData = data;

//     if (selectedKeyword.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedKeyword.some((option) => item.OriginalJobTitle !== null && item.OriginalJobTitle.includes(option.value));
//       });
//     }

//     if (jobLevelOptions.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return jobLevelOptions.some((option) => item.JobLevel !== null && item.JobLevel.includes(option.value));
//       });
//     }

//     if (selectedJobFunction.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedJobFunction.some((option) => item.JobFunction !== null && item.JobFunction.includes(option.value));
//       });
//     }

//     if (selectedGeo.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedGeo.some((option) => item.Country !== null && item.Country.includes(option.value));
//       });
//     }

//     if (selectedStates.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedStates.some((option) => item.State !== null && item.State.includes(option.value));
//       });
//     }

//     if (selectedCities.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedCities.some((option) => item.City !== null && item.City.includes(option.value));
//       });
//     }

//     if (selectedIndustry.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedIndustry.some((option) => item.OriginalIndustry !== null && item.OriginalIndustry.includes(option.value));
//       });
//     }

//     if (selectedSubIndustry.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedSubIndustry.some((option) => item.SubIndustry !== null && item.SubIndustry.includes(option.value));
//       });
//     }

//     if (selectedSicCode.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedSicCode.some((option) => item.SIC !== null && item.SIC.includes(option.value));
//       });
//     }

//     if (selectedNaicsCode.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedNaicsCode.some((option) => item.NAICS !== null && item.NAICS.includes(option.value));
//       });
//     }

//     if (selectedDateRange.length > 0) {
//       filteredData = selectedDateRange;
//     }

//     if (selectedEmployeeSize.length > 0) {
//       filteredData = selectedEmployeeSize.reduce((acc, curr) => {
//         const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
//         return acc.concat(filteredData.filter((item) => {
//           const empSize = parseInt(item.EmployeeSize.replace(/,/g, ''));
//           return empSize >= minValue && empSize <= maxValue;
//         }));
//       }, []);
//     }

//     if (selectedRevenue.length > 0) {
//       filteredData = selectedRevenue.flatMap((curr) => {
//         const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
//         return data.filter((item) => {
//           if (item.Revenue !== null && item.Revenue !== undefined) {
//             const revenueSizeString = item.Revenue;
//             const revenueSize = parseFloat(revenueSizeString.replace('$', '').replace(' Million', 'e6').replace(' Billion', 'e9'));
//             return revenueSize >= minValue && revenueSize <= maxValue;
//           } else {
//             return false; // or some other logic to handle this case
//           }
//         });
//       });
//     }

//     if (suppressionFile) {
//       filteredData = applySuppression(filteredData);
//     }

//     if (talFile) {
//       filteredData = applyTAL(filteredData);
//     }

//     return filteredData;
//   }, [data, selectedKeyword, jobLevelOptions, selectedStates, selectedCities, selectedGeo, selectedJobFunction, selectedEmployeeSize, selectedIndustry, selectedSubIndustry, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedDateRange, suppressionFile, talFile, applySuppression, applyTAL]);



//   useEffect(() => {
//     const storedData = localStorage.getItem('data');
//     if (storedData) {
//       const data = JSON.parse(storedData);
//       setData(data);
//       setTotalDataCount(data.length);
//     } else {
//       const fetchData = async () => {
//         try {
//           const response = await axios.get('http://localhost:3002/data');
//           const compressedData = response.data;
//           const decompressedData = compressedData.map((row) => {
//             Object.keys(row).forEach((key) => {
//               if (key === 'Revenue_Size_Link' || key === 'Employee_Size_Link' || key === 'ContactProspect_Link') {
//                 const decompressedLink = LZString.decompress(row[key]);
//                 row[key] = decompressedLink.length > 150 ? decompressedLink.substring(0, 150) : decompressedLink;
//               } else {
//                 row[key] = LZString.decompress(row[key]);
//               }
//             });
//             return row;
//           });
//           setData(decompressedData);
//           setTotalDataCount(decompressedData.length);
//           localStorage.setItem('data', JSON.stringify(decompressedData));
//         } catch (error) {
//           console.error('Error fetching data:', error);
//           setError('Error fetching data. Please try again later.');
//         }
//       };
//       fetchData();
//     }
//   }, []);



//   useEffect(() => {
//     const filteredData = filterData(data);
//     setFilteredData(filteredData);
//     setCount(filteredData.length);
//   }, [data, selectedKeyword, jobLevelOptions, selectedStates, selectedCities, selectedGeo, selectedJobFunction, selectedEmployeeSize, selectedIndustry, selectedSubIndustry, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedDateRange, suppressionFile, talFile]);
  

//   useEffect(() => {
//     if (count > 100000) {
//       setExportDisabled(true);
//       setExportMessage('Data cannot be exported, Please contact Admin');
//     } else {
//       setExportDisabled(false);
//       setExportMessage('');
//     }
//   }, [count]);

//   const handleKeywordChange = (options) => {
//     setSelectedKeyword(options);
//   };

//   const handleJobLevelChange = (options) => {
//     setJobLevelOptions(options);
//   };

//   const handleJobFunctionChange = (options) => {
//     setSelectedJobFunction(options);
//   }; 

//   const handleGeoChange = (options) => {
//     setSelectedGeo(options);
//   };  

//   const handleStateChange = (options) => {
//     setSelectedStates(options);
//   };

//   const handleCityChange = (options) => {
//     setSelectedCities(options);
//   };

//   const handleEmployeeSizeChange = (options) => {
//     setSelectedEmployeeSize(options);
//   };

//   const handleIndustryChange = (options) => {
//     setSelectedIndustry(options);
//   };

//   const handleSubIndustryChange = (options) => {
//     setSelectedSubIndustry(options);
//   };

//   const handleSicCodeChange = (options) => {
//     setSelectedSicCode(options);
//   };

//   const handleNaicsCodeChange = (options) => {
//     setSelectedNaicsCode(options);
//   };

//   const handleRevenueChange = (options) => {
//     setSelectedRevenue(options);
//   };

//   const handleDateRangeChange = (filteredData) => {
//     setSelectedDateRange(filteredData);
//   };


//   //   const handleExportToExcel = () => {
//   //   if (!exportDisabled) {
//   //     const worksheet = utils.json_to_sheet(filteredData);
//   //     const workbook = utils.book_new();
//   //     utils.book_append_sheet(workbook, worksheet, 'Data');
//   //     const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'buffer' });
//   //     const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${Buffer.from(excelBuffer).toString('base64')}`;
//   //     const link = document.createElement('a');
//   //     link.href = dataURI;
//   //     link.download = 'Export_data.xlsx';
//   //     link.click();
//   //   }
//   // };




//   const handleExportToExcel = () => {
//     if (!exportDisabled) {
//       const worksheet = utils.json_to_sheet(filteredData);
//       const workbook = utils.book_new();
//       utils.book_append_sheet(workbook, worksheet, 'Data');
//       const csvBuffer = write(workbook, { bookType: 'csv', type: 'buffer' });
//       const dataURI = `data:text/csv;base64,${Buffer.from(csvBuffer).toString('base64')}`;
//       const link = document.createElement('a');
//       link.href = dataURI;
//       link.download = 'Export_data.csv';
//       link.click();
//     }
//   };



//   // const handleExportToExcel = async () => {
//   //   if (!exportDisabled) {
//   //     const emailAddresses = filteredData.map(item => item.Email_Address);
//   //     const chunkSize = 100; // adjust this value based on your needs
//   //     const chunks = [];
  
//   //     for (let i = 0; i < emailAddresses.length; i += chunkSize) {
//   //       chunks.push(emailAddresses.slice(i, i + chunkSize));
//   //     }
  
//   //     const excelData = [];
  
//   //     chunks.forEach((chunk, index) => {
//   //       fetch('http://localhost:3002/fetchRemainingColumns', {
//   //         method: 'POST',
//   //         headers: { 'Content-Type': 'application/json' },
//   //         body: JSON.stringify({ emailAddresses: chunk }),
//   //       })
//   //         .then(response => response.json())
//   //         .then(data => {
//   //           excelData.push(...data.recordset); // Access the recordset property
//   //           if (index === chunks.length - 1) {
//   //             const workbook = utils.book_new();
//   //             const worksheet = utils.json_to_sheet(excelData);
//   //             utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
//   //             const excelBuffer = utils.write(workbook, { type: 'buffer', bookType: 'xlsx' });
//   //             const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//   //             const url = URL.createObjectURL(blob);
//   //             const link = document.createElement('a');
//   //             link.href = url;
//   //             link.download = `Export_data.xlsx`;
//   //             link.click();
//   //           }
//   //         })
//   //         .catch(error => console.error('Error exporting to Excel:', error));
//   //     });
//   //   }
//   // };



//   const handleResetFiltersClick = () => {
//     setResetFilters(true);
//   };

//   const handleReset = () => {
//     setSelectedKeyword([]);
//     setJobLevelOptions([]);
//     setSelectedStates([]);
//     setSelectedCities([]);
//     setSelectedGeo([]);
//     setSelectedJobFunction([]);
//     setSelectedEmployeeSize([]);
//     setSelectedIndustry([]);
//     setSelectedSubIndustry([]);
//     setSelectedSicCode([]);
//     setSelectedNaicsCode([]);
//     setSelectedRevenue([]);
//     setSelectedDateRange([]);
//     setResetFilters(false); // Reset the resetFilters state variable
//   };

//   const downloadTemplate = (templateType) => {
//     const templateName = templateType === 'suppression' ? 'suppression Sample File.xlsx' : 'TAL Sample File.xlsx';
//     const link = document.createElement('a');
//     link.href = `${process.env.PUBLIC_URL}/${templateName}`;
//     link.download = templateName;
//     link.click();
//   };

//   const handleLogin = (username, password) => {
//     if (username === 'admin' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('admin');
//     } else if (username === 'operations' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('operations');
//     } else if (username === 'sales' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('sales');
//     } else {
//       setLoginError('Incorrect ID or Password. Please contact admin');
//     }
//   };

//   const handleLogout = () => {
//     setIsLoggedIn(false);
//   };

//   return (
//     <div>
//       {isLoggedIn ? (
//         <div>
//           < Navbar title="DM Database" logout={<Logout handleLogout={handleLogout} />} />
//           <div className='top-container'>
//             <div className='columns-container' style={{
//               display: 'grid',
//               gridTemplateColumns: '1fr 1fr',
//               justifyContent: 'center',
//               alignItems: 'center',
//               textAlign: 'center',
//               maxWidth: 800, /* add max-width to match the width of other filters */
//             }}>
//               <div className='column total-records'>
//                 <p>
//                   <strong style={{ fontSize: 18, marginRight: 10 }}>TOTAL RECORDS :</strong>
//                   <span style={{ fontSize: 24, fontWeight: 500, }}>{totalDataCount}</span>
//                 </p>
//               </div>
//               <div className='column filtered-records'>
//                 <p>
//                   <strong style={{ fontSize: 18, marginRight: 10 }}>FILTERED RECORDS :</strong>
//                   <CountUp
//                     key={count} // re-render with new value
//                     start={previousCount} // set starting value to previous count
//                     end={count}
//                     duration={2}
//                     separator=","
//                     decimals={0}
//                     prefix=""
//                     suffix=""
//                     onEnd={() => {
//                       setPreviousCount(count); // update previous count
//                       console.log("Finished counting");
//                     }}
//                     style={{ fontSize: 24, fontWeight: 500, color: count > 100000 ? 'red' : '#F4EBD0' }}
//                   />
//                 </p>
//               </div>
//             </div>
//           </div>

//           <div className='container'>

//             {/* <div style={{ marginBottom: 20 }}>
//               <DateRangeFilter 
//                 data={data} 
//                 handleSelectChange={handleDateRangeChange} 
//                 />
//             </div> */}
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Keywords :</b></label>
//               <Keyword 
//                 data={data} 
//                 selectedKeyword={selectedKeyword} 
//                 handleSelectChange={handleKeywordChange} 
//               />
//             </div>
            
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Job Function :</b></label>
//               <JobFunction 
//                 data={data} 
//                 selectedJobFunction={selectedJobFunction} 
//                 handleSelectChange={handleJobFunctionChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Job Level :</b></label>
//               <JobLevel 
//                 data={data} 
//                 jobLevelOptions={jobLevelOptions} 
//                 handleSelectChange={handleJobLevelChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Industry :</b></label>
//               <Industry 
//                 data={data} 
//                 selectedIndustry={selectedIndustry} 
//                 handleSelectChange={handleIndustryChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>SubIndustry :</b></label>
//               <SubIndustry 
//                 data={data} 
//                 selectedSubIndustry={selectedSubIndustry} 
//                 handleSelectChange={handleSubIndustryChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Country :</b></label>
//               <Geo 
//                 data={data} 
//                 selectedGeo={selectedGeo} 
//                 handleSelectChange={handleGeoChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>States :</b></label>
//               <StateNames 
//                 data={data} 
//                 selectedStates={selectedStates} 
//                 handleSelectChange={handleStateChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>City :</b></label>
//               <CityNames 
//                 data={data} 
//                 selectedCities={selectedCities} 
//                 handleSelectChange={handleCityChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Employee Size :</b></label>
//               <EmployeeSize 
//                 data={data} 
//                 selectedEmployeeSize={selectedEmployeeSize} 
//                 handleSelectChange={handleEmployeeSizeChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>SIC :</b></label>
//               <SIC
//                 data={data} 
//                 selectedSicCode={selectedSicCode} 
//                 handleSelectChange={handleSicCodeChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>NAICS :</b></label>
//               <NAICS
//                 data={data} 
//                 selectedNaicsCode={selectedNaicsCode} 
//                 handleSelectChange={handleNaicsCodeChange} 
//               />
//             </div>   
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Revenue :</b></label>
//               <Revenue
//                 data={data} 
//                 selectedRevenue={selectedRevenue} 
//                 handleSelectChange={handleRevenueChange} 
//               />
//             </div>       
//             <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
//   <label><b>Upload Suppression:</b></label>
//   <div style={{ display: 'flex', alignItems: 'center' }}>
//     <div style={{ textAlign: 'center' }}>
//       <input type="file" onChange={(e) => handleSuppressionFileChange(e.target.files[0])} />
//       {suppressionFile && (
//         <button style={{ marginLeft: 10, padding: '5px 10px', fontSize: 12 }} onClick={() => handleSuppressionFileChange(null)}>
//           <b>Remove</b>
//         </button>
//       )}
//     </div>
//     <button style={{ marginLeft: 10, padding: '5px 10px', fontSize: 12 }} onClick={() => downloadTemplate('suppression')}>
//       <b>Download Suppression Template</b>
//     </button>
//   </div>
// </div>

// <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
//   <label><b>Upload TAL:</b></label>
//   <div style={{ display: 'flex', alignItems: 'center' }}>
//     <div style={{ textAlign: 'center', }}>
//       <input type="file" onChange={(e) => handleTALFileChange(e.target.files[0])} />
//       {talFile && (
//         <button style={{ marginLeft: 10, padding: '5px 10px', fontSize: 12 }} onClick={() => handleTALFileChange(null)}>
//           <b>Remove</b>
//         </button>
//       )}
//     </div>
//     <button style={{ marginLeft: 10, padding: '5px 10px', fontSize: 12 }} onClick={() => downloadTemplate('tal')}>
//       <b>Download TAL Template</b>
//     </button>
//   </div>
// </div>
            
                
// <div style={{textAlign: 'right' }}>
//   <button style={{ marginRight: 10 }} onClick={() => { handleReset([]); }}>
//     <b>Reset Filters</b>
//   </button>

//   {exportDisabled ? (
//     <p style={{ color: 'red' }}>{exportMessage}</p>
//   ) : (
//     userRole !== 'sales' && (
//       <button onClick={handleExportToExcel}>
//   <b>Export Data</b>
// </button>
//     )
//   )}
  
// </div>
//         </div>
//       </div>
//       ) : (
//         <Login handleLogin={handleLogin} loginError={loginError} />
//       )}
//     </div>
//   );
  
// };

// export default App;










// import React, { useState, useEffect, useCallback } from 'react';
// import { utils, write } from 'xlsx';
// import { Buffer } from 'buffer';
// import axios from 'axios'
// import CountUp from 'react-countup';
// import Login from './Components/Login';
// import Logout from './Components/Logout';
// import './App.css';
// import useUploadSuppression from './Components/suppression';
// import useUploadTAL from './Components/TAL';

// import Navbar from './Components/Navbar';
// import JobLevel from './Components/Joblevel';
// import StateNames from './Components/StateNames';
// import Keyword from './Components/Keyword';
// import CityNames from './Components/CityNames';
// import Geo from './Components/Geo';
// import JobFunction from './Components/JobFunction';
// import EmployeeSize from './Components/EmployeeSize';
// import Industry from './Components/Industry';
// import SubIndustry from './Components/SubIndustry';
// import SIC from './Components/Sic';
// import NAICS from './Components/Naics';
// import Revenue from './Components/Revenue';
// import DateRangeFilter from './Components/DateRangeFilter';
// import ProgressBar from 'react-progressbar';
// import debounce from 'lodash.debounce';
// import XLSX from 'xlsx';
// import LZString from 'lz-string';
// import { useBeforeunload } from 'react-beforeunload';




// const App = () => {
//   const [data, setData] = useState([]);
//   const [selectedKeyword, setSelectedKeyword] = useState([]);
//   const [jobLevelOptions, setJobLevelOptions] = useState([]);
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [selectedCities, setSelectedCities] = useState([]);
//   const [selectedGeo, setSelectedGeo] = useState([]);
//   const [selectedJobFunction, setSelectedJobFunction] = useState([]);
//   const [selectedEmployeeSize, setSelectedEmployeeSize] = useState([]);
//   const [selectedIndustry, setSelectedIndustry] = useState([]);
//   const [selectedSubIndustry, setSelectedSubIndustry] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [selectedSicCode, setSelectedSicCode] = useState([]);
//   const [selectedNaicsCode, setSelectedNaicsCode] = useState([]);
//   const [selectedRevenue, setSelectedRevenue] = useState([]); 
//   const [selectedDateRange, setSelectedDateRange] = useState([]);
//   const [resetFilters, setResetFilters] = useState(false);
//   const [exporting, setExporting] = useState(false);
//   const [error, setError] = useState(null); 
//   const [isSubmitting, setIsSubmitting] = useState(false);  
//   const [shouldSubmitFilters, setShouldSubmitFilters] = useState(false);

//   const [ setFile] = useState(null);
//   const { file: suppressionFile, handleFileChange: handleSuppressionFileChange, applySuppression } = useUploadSuppression();
//   const { file: talFile, handleFileChange: handleTALFileChange, applyTAL } = useUploadTAL();

//   const [isLoggedIn, setIsLoggedIn] = useState(false); // new state to track login status
//   const [username, setUsername] = useState(''); // new state to store username
//   const [password, setPassword] = useState(''); // new state to store password
//   const [loginError, setLoginError] = useState('');
//   const [userRole, setUserRole] = useState('');

//   const [totalDataCount, setTotalDataCount] = useState(0);
//   const [count, setCount] = useState(0);
//   const [previousCount, setPreviousCount] = useState(0);
//   const [exportDisabled, setExportDisabled] = useState(false); // new state to track export button disable
//   const [exportMessage, setExportMessage] = useState(''); // new state to track export message



// useEffect(() => {
//   const handleBeforeUnload = (event) => {
//     const message = 'Do you really want to refresh? NOTE: Refreshing the app again will lead to loading of data again which might take few minutes. Consider resetting the filters.';
//     (event || window.event).returnValue = message;
//     return message;
//   };

//   window.addEventListener('beforeunload', handleBeforeUnload);

//   return () => {
//     window.removeEventListener('beforeunload', handleBeforeUnload);
//   };
// }, []);


//   const filterData = useCallback((data) => {
//     let filteredData = data;

//     if (selectedKeyword.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedKeyword.some((option) => item.OriginalJobTitle !== null && item.OriginalJobTitle.includes(option.value));
//       });
//     }

//     if (jobLevelOptions.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return jobLevelOptions.some((option) => item.JobLevel !== null && item.JobLevel.includes(option.value));
//       });
//     }

//     if (selectedJobFunction.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedJobFunction.some((option) => item.JobFunction !== null && item.JobFunction.includes(option.value));
//       });
//     }

//     if (selectedGeo.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedGeo.some((option) => item.Country !== null && item.Country.includes(option.value));
//       });
//     }

//     if (selectedStates.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedStates.some((option) => item.State !== null && item.State.includes(option.value));
//       });
//     }

//     if (selectedCities.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedCities.some((option) => item.City !== null && item.City.includes(option.value));
//       });
//     }

//     if (selectedIndustry.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedIndustry.some((option) => item.OriginalIndustry !== null && item.OriginalIndustry.includes(option.value));
//       });
//     }

//     if (selectedSubIndustry.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedSubIndustry.some((option) => item.SubIndustry !== null && item.SubIndustry.includes(option.value));
//       });
//     }

//     if (selectedSicCode.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedSicCode.some((option) => item.SIC !== null && item.SIC.includes(option.value));
//       });
//     }

//     if (selectedNaicsCode.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedNaicsCode.some((option) => item.NAICS !== null && item.NAICS.includes(option.value));
//       });
//     }

//     if (selectedDateRange.length > 0) {
//       filteredData = selectedDateRange;
//     }

//     if (selectedEmployeeSize.length > 0) {
//       filteredData = selectedEmployeeSize.reduce((acc, curr) => {
//         const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
//         return acc.concat(filteredData.filter((item) => {
//           const empSize = parseInt(item.EmployeeSize.replace(/,/g, ''));
//           return empSize >= minValue && empSize <= maxValue;
//         }));
//       }, []);
//     }

//     if (selectedRevenue.length > 0) {
//       filteredData = selectedRevenue.flatMap((curr) => {
//         const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
//         return data.filter((item) => {
//           if (item.Revenue !== null && item.Revenue !== undefined) {
//             const revenueSizeString = item.Revenue;
//             const revenueSize = parseFloat(revenueSizeString.replace('$', '').replace(' Million', 'e6').replace(' Billion', 'e9'));
//             return revenueSize >= minValue && revenueSize <= maxValue;
//           } else {
//             return false; // or some other logic to handle this case
//           }
//         });
//       });
//     }

//     if (suppressionFile) {
//       filteredData = applySuppression(filteredData);
//     }

//     if (talFile) {
//       filteredData = applyTAL(filteredData);
//     }

//     return filteredData;
//   }, [data, selectedKeyword, jobLevelOptions, selectedStates, selectedCities, selectedGeo, selectedJobFunction, selectedEmployeeSize, selectedIndustry, selectedSubIndustry, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedDateRange, suppressionFile, talFile, applySuppression, applyTAL]);


// // Version1
//     // useEffect(() => {
//     //   const storedData = localStorage.getItem('data');
//     //   if (storedData) {
//     //     const data = JSON.parse(storedData);
//     //     setData(data);
//     //     setTotalDataCount(data.length);
//     //   } else {
//     //     const fetchData = async () => {
//     //       try {
//     //         const response = await axios.get('http://localhost:3002/data');
//     //         const compressedData = response.data;
//     //         const decompressedData = compressedData.map((row) => {
//     //           Object.keys(row).forEach((key) => {
//     //             if (key === 'Revenue_Size_Link' || key === 'Employee_Size_Link' || key === 'ContactProspect_Link') {
//     //               const decompressedLink = LZString.decompress(row[key]);
//     //               row[key] = decompressedLink.length > 150 ? decompressedLink.substring(0, 150) : decompressedLink;
//     //             } else {
//     //               row[key] = LZString.decompress(row[key]);
//     //             }
//     //           });
//     //           return row;
//     //         });
//     //         setData(decompressedData);
//     //         setTotalDataCount(decompressedData.length);
//     //         localStorage.setItem('data', JSON.stringify(decompressedData));
//     //       } catch (error) {
//     //         console.error('Error fetching data:', error);
//     //         setError('Error fetching data. Please try again later.');
//     //       }
//     //     };
//     //     fetchData();
//     //   }
//     // }, []);



// //Final - Using Decompression
//     useEffect(() => {
//       const fetchTotalCount = async () => {
//         try {
//           const response = await axios.get('http://localhost:3002/data');
//           setTotalDataCount(response.data.totalRecords);
//         } catch (error) {
//           console.error('Error fetching total count:', error);
//         }
//       };
    
//       fetchTotalCount();
//     }, []);
    
//     // App.js    
//     useEffect(() => {
//       if (shouldSubmitFilters) {
//         const fetchFilteredData = async () => {
//           const filters = {
//             Keywords: selectedKeyword.map((keyword) => keyword.value),
//             JobFunction: selectedJobFunction.map((jobFunctions) => jobFunctions.value),
//             JobLevel: jobLevelOptions.map((option) => option.value),
//             Industry: selectedIndustry.map((industry) => industry.value),
//             SubIndustry: selectedSubIndustry.map((subIndustry) => subIndustry.value),
//             Geo: selectedGeo.map((geoNames) => geoNames.value),
//             StateNames: selectedStates.map((stateNames) => stateNames.value),
//             CityNames: selectedCities.map((cityNames) => cityNames.value),
//             EmployeeSize: selectedEmployeeSize.map((employeeSizeOptions) => employeeSizeOptions.value),
//             SIC: selectedSicCode.map((sic) => sic.value),
//             NAICS: selectedNaicsCode.map((naicsCode) => naicsCode.value), 
//             Revenue: selectedRevenue.map((revenueOptions) => revenueOptions.value), 
//           };
    
//           try {
//             const response = await axios.post('http://localhost:3002/filteredData', filters);
//             const decompressedData = response.data.map((row) => {
//               Object.keys(row).forEach((key) => {
//                 row[key] = LZString.decompress(row[key]);
//               });
//               return row;
//             });
//             setFilteredData(decompressedData);
//             setCount(decompressedData.length);
//           } catch (error) {
//             console.error('Error fetching filtered data:', error);
//           }
//         };
    
//         fetchFilteredData();
//         setShouldSubmitFilters(false);
//       }
//     }, [jobLevelOptions, selectedIndustry, selectedSubIndustry, selectedJobFunction, selectedKeyword, selectedGeo, selectedStates, selectedCities, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedEmployeeSize ,shouldSubmitFilters]); 


  
//     // useEffect(() => {
//     //   const fetchTotalCount = async () => {
//     //     try {
//     //       const response = await axios.get('http://localhost:3002/data');
//     //       setTotalDataCount(response.data.totalRecords);
//     //     } catch (error) {
//     //       console.error('Error fetching total count:', error);
//     //     }
//     //   };
    
//     //   fetchTotalCount();
//     // }, []);
    
//     // useEffect(() => {
//     //   const fetchFilteredData = async () => {
//     //     if (jobLevelOptions.length > 0) {
//     //       try {
//     //         const response = await axios.post('http://localhost:3002/filteredData', {
//     //           JobLevel: jobLevelOptions.map(option => option.value)
//     //         });
//     //         console.log('Received response data:', response.data); // Add this line
//     //         setFilteredData(response.data);
//     //         setCount(response.data.length); // Update the count state
//     //       } catch (error) {
//     //         console.error('Error fetching filtered data:', error);
//     //       }
//     //     } else {
//     //       setFilteredData([]);
//     //       setCount(0); // Reset the count state
//     //     }
//     //   };
    
//     //   fetchFilteredData();
//     // }, [jobLevelOptions]);



//   // useEffect(() => {
//   //   const storedData = localStorage.getItem('data');
//   //   if (storedData) {
//   //     const data = JSON.parse(storedData);
//   //     setData(data);
//   //     setTotalDataCount(data.length);
//   //   } else {
//   //     const fetchData = async () => {
//   //       try {
//   //         const response = await fetch('http://localhost:3002/data');
//   //         const reader = response.body.getReader();
//   //         const decompressedData = [];
  
//   //         let isFirstRow = true;
//   //         while (true) {
//   //           const { done, value } = await reader.read();
//   //           if (done) break;
  
//   //           const chunk = value.toString();
//   //           if (isFirstRow) {
//   //             const startIndex = chunk.indexOf('[');
//   //             if (startIndex !== -1) {
//   //               const endIndex = chunk.indexOf(']');
//   //               if (endIndex !== -1) {
//   //                 const rows = JSON.parse(chunk.substring(startIndex, endIndex + 1));
//   //                 rows.forEach((row) => {
//   //                   const decompressedRow = decompressRow(row);
//   //                   decompressedData.push(decompressedRow);
//   //                 });
//   //               } else {
//   //                 const rows = JSON.parse(chunk.substring(startIndex));
//   //                 rows.forEach((row) => {
//   //                   const decompressedRow = decompressRow(row);
//   //                   decompressedData.push(decompressedRow);
//   //                 });
//   //               }
//   //               isFirstRow = false;
//   //             }
//   //           } else {
//   //             const rows = chunk.split(',').filter((row) => row !== '');
//   //             rows.forEach((row) => {
//   //               try {
//   //                 const compressedRow = JSON.parse(row);
//   //                 const decompressedRow = decompressRow(compressedRow);
//   //                 decompressedData.push(decompressedRow);
//   //               } catch (error) {
//   //                 console.error('Error parsing row:', error);
//   //               }
//   //             });
//   //           }
//   //         }
  
//   //         setData(decompressedData);
//   //         setTotalDataCount(decompressedData.length);
//   //         localStorage.setItem('data', JSON.stringify(decompressedData));
//   //       } catch (error) {
//   //         console.error('Error fetching data:', error);
//   //         setError('Error fetching data. Please try again later.');
//   //       }
//   //     };
//   //     fetchData();
//   //   }
//   // }, []);
  
//   // function decompressRow(row) {
//   //   Object.keys(row).forEach((key) => {
//   //     if (key === 'Revenue_Size_Link' || key === 'Employee_Size_Link' || key === 'ContactProspect_Link') {
//   //       const decompressedLink = LZString.decompress(row[key]);
//   //       row[key] = decompressedLink.length > 150 ? decompressedLink.substring(0, 150) : decompressedLink;
//   //     } else {
//   //       row[key] = LZString.decompress(row[key]);
//   //     }
//   //   });
//   //   return row;
//   // }


  


//   // useEffect(() => {
//   //   const filteredData = filterData(data);
//   //   setFilteredData(filteredData);
//   //   setCount(filteredData.length);
//   // }, [data, selectedKeyword, jobLevelOptions, selectedStates, selectedCities, selectedGeo, selectedJobFunction, selectedEmployeeSize, selectedIndustry, selectedSubIndustry, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedDateRange, suppressionFile, talFile]);
  

//   useEffect(() => {
//     if (shouldSubmitFilters) {
//       const filteredData = filterData(data);
//       setFilteredData(filteredData);
//       setCount(filteredData.length);
//       setShouldSubmitFilters(false);
//     }
//   }, [data, selectedKeyword, selectedStates, selectedCities, selectedGeo, selectedJobFunction, selectedEmployeeSize, selectedIndustry, selectedSubIndustry, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedDateRange, suppressionFile, talFile, shouldSubmitFilters]);



//   useEffect(() => {
//     if (count > 100000) {
//       setExportDisabled(true);
//       setExportMessage('Data cannot be exported, Please contact Admin');
//     } else {
//       setExportDisabled(false);
//       setExportMessage('');
//     }
//   }, [count]);

//   const handleKeywordChange = (options) => {
//     setSelectedKeyword(options);
//   };

//   const handleJobLevelChange = (options) => {
//     setJobLevelOptions(options);
//   };

//   const handleJobFunctionChange = (options) => {
//     setSelectedJobFunction(options);
//   }; 

//   const handleGeoChange = (options) => {
//     setSelectedGeo(options);
//   };  

//   const handleStateChange = (options) => {
//     setSelectedStates(options);
//   };

//   const handleCityChange = (options) => {
//     setSelectedCities(options);
//   };

//   const handleEmployeeSizeChange = (options) => {
//     setSelectedEmployeeSize(options);
//   };

//   const handleIndustryChange = (options) => {
//     setSelectedIndustry(options);
//   };

//   const handleSubIndustryChange = (options) => {
//     setSelectedSubIndustry(options);
//   };

//   const handleSicCodeChange = (options) => {
//     setSelectedSicCode(options);
//   };

//   const handleNaicsCodeChange = (options) => {
//     setSelectedNaicsCode(options);
//   };

//   const handleRevenueChange = (options) => {
//     setSelectedRevenue(options);
//   };

//   const handleDateRangeChange = (filteredData) => {
//     setSelectedDateRange(filteredData);
//   };

//   const handleSubmit = async () => {
//     setShouldSubmitFilters(true);
//   };




//   //   const handleExportToExcel = () => {
//   //   if (!exportDisabled) {
//   //     const worksheet = utils.json_to_sheet(filteredData);
//   //     const workbook = utils.book_new();
//   //     utils.book_append_sheet(workbook, worksheet, 'Data');
//   //     const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'buffer' });
//   //     const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${Buffer.from(excelBuffer).toString('base64')}`;
//   //     const link = document.createElement('a');
//   //     link.href = dataURI;
//   //     link.download = 'Export_data.xlsx';
//   //     link.click();
//   //   }
//   // };



//   const handleExportToExcel = () => {
//     if (!exportDisabled) {
//       const worksheet = utils.json_to_sheet(filteredData);
//       const workbook = utils.book_new();
//       utils.book_append_sheet(workbook, worksheet, 'Data');
//       const csvBuffer = write(workbook, { bookType: 'csv', type: 'buffer' });
//       const dataURI = `data:text/csv;base64,${Buffer.from(csvBuffer).toString('base64')}`;
//       const link = document.createElement('a');
//       link.href = dataURI;
//       link.download = 'Export_data.csv';
//       link.click();
//     }
//   };



//   // const handleExportToExcel = async () => {
//   //   if (!exportDisabled) {
//   //     const emailAddresses = filteredData.map(item => item.Email_Address);
//   //     const chunkSize = 100; // adjust this value based on your needs
//   //     const chunks = [];
  
//   //     for (let i = 0; i < emailAddresses.length; i += chunkSize) {
//   //       chunks.push(emailAddresses.slice(i, i + chunkSize));
//   //     }
  
//   //     const excelData = [];
  
//   //     chunks.forEach((chunk, index) => {
//   //       fetch('http://localhost:3002/fetchRemainingColumns', {
//   //         method: 'POST',
//   //         headers: { 'Content-Type': 'application/json' },
//   //         body: JSON.stringify({ emailAddresses: chunk }),
//   //       })
//   //         .then(response => response.json())
//   //         .then(data => {
//   //           excelData.push(...data.recordset); // Access the recordset property
//   //           if (index === chunks.length - 1) {
//   //             const workbook = utils.book_new();
//   //             const worksheet = utils.json_to_sheet(excelData);
//   //             utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
//   //             const excelBuffer = utils.write(workbook, { type: 'buffer', bookType: 'xlsx' });
//   //             const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//   //             const url = URL.createObjectURL(blob);
//   //             const link = document.createElement('a');
//   //             link.href = url;
//   //             link.download = `Export_data.xlsx`;
//   //             link.click();
//   //           }
//   //         })
//   //         .catch(error => console.error('Error exporting to Excel:', error));
//   //     });
//   //   }
//   // };



//   const handleResetFiltersClick = () => {
//     setResetFilters(true);
//   };

//   const handleReset = () => {
//     setSelectedKeyword([]);
//     setJobLevelOptions([]);
//     setSelectedStates([]);
//     setSelectedCities([]);
//     setSelectedGeo([]);
//     setSelectedJobFunction([]);
//     setSelectedEmployeeSize([]);
//     setSelectedIndustry([]);
//     setSelectedSubIndustry([]);
//     setSelectedSicCode([]);
//     setSelectedNaicsCode([]);
//     setSelectedRevenue([]);
//     setSelectedDateRange([]);
//     setResetFilters(false); // Reset the resetFilters state variable
//   };

//   const downloadTemplate = (templateType) => {
//     const templateName = templateType === 'suppression' ? 'suppression Sample File.xlsx' : 'TAL Sample File.xlsx';
//     const link = document.createElement('a');
//     link.href = `${process.env.PUBLIC_URL}/${templateName}`;
//     link.download = templateName;
//     link.click();
//   };

//   const handleLogin = (username, password) => {
//     if (username === 'admin' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('admin');
//     } else if (username === 'operations' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('operations');
//     } else if (username === 'sales' && password === 'password') {
//       setIsLoggedIn(true);
//       setUserRole('sales');
//     } else {
//       setLoginError('Incorrect ID or Password. Please contact admin');
//     }
//   };

//   const handleLogout = () => {
//     setIsLoggedIn(false);
//   };

//   return (
//     <div>
//       {isLoggedIn ? (
//         <div>
//           < Navbar title="DM Database" logout={<Logout handleLogout={handleLogout} />} />
//           <div className='top-container'>
//             <div className='columns-container' style={{
//               display: 'grid',
//               gridTemplateColumns: '1fr 1fr',
//               justifyContent: 'center',
//               alignItems: 'center',
//               textAlign: 'center',
//               maxWidth: 800, /* add max-width to match the width of other filters */
//             }}>
//               <div className='column total-records'>
//                 <p>
//                   <strong style={{ fontSize: 18, marginRight: 10 }}>TOTAL RECORDS :</strong>
//                   <span style={{ fontSize: 24, fontWeight: 500, }}>{totalDataCount}</span>
//                 </p>
//               </div>
//               <div className='column filtered-records'>
//                 <p>
//                   <strong style={{ fontSize: 18, marginRight: 10 }}>FILTERED RECORDS :</strong>
//                   <CountUp
//                     key={count} // re-render with new value
//                     start={previousCount} // set starting value to previous count
//                     end={count}
//                     duration={2}
//                     separator=","
//                     decimals={0}
//                     prefix=""
//                     suffix=""
//                     onEnd={() => {
//                       setPreviousCount(count); // update previous count
//                       console.log("Finished counting");
//                     }}
//                     style={{ fontSize: 24, fontWeight: 500, color: count > 100000 ? 'red' : '#F4EBD0' }}
//                   />
//                 </p>
//               </div>
//             </div>
//           </div>

//           <div className='container'>

//             {/* <div style={{ marginBottom: 20 }}>
//               <DateRangeFilter 
//                 data={data} 
//                 handleSelectChange={handleDateRangeChange} 
//                 />
//             </div> */}
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Keywords :</b></label>
//               <Keyword 
//                 data={data} 
//                 selectedKeyword={selectedKeyword} 
//                 handleSelectChange={handleKeywordChange} 
//               />
//             </div>
            
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Job Function :</b></label>
//               <JobFunction 
//                 data={data} 
//                 selectedJobFunction={selectedJobFunction} 
//                 handleSelectChange={handleJobFunctionChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Job Level :</b></label>
//               <JobLevel 
//                 data={data} 
//                 jobLevelOptions={jobLevelOptions} 
//                 handleSelectChange={handleJobLevelChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Industry :</b></label>
//               <Industry 
//                 data={data} 
//                 selectedIndustry={selectedIndustry} 
//                 handleSelectChange={handleIndustryChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>SubIndustry :</b></label>
//               <SubIndustry 
//                 data={data} 
//                 selectedSubIndustry={selectedSubIndustry} 
//                 handleSelectChange={handleSubIndustryChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Country :</b></label>
//               <Geo 
//                 data={data} 
//                 selectedGeo={selectedGeo} 
//                 handleSelectChange={handleGeoChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>States :</b></label>
//               <StateNames 
//                 data={data} 
//                 selectedStates={selectedStates} 
//                 handleSelectChange={handleStateChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>City :</b></label>
//               <CityNames 
//                 data={data} 
//                 selectedCities={selectedCities} 
//                 handleSelectChange={handleCityChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Employee Size :</b></label>
//               <EmployeeSize 
//                 data={data} 
//                 selectedEmployeeSize={selectedEmployeeSize} 
//                 handleSelectChange={handleEmployeeSizeChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>SIC :</b></label>
//               <SIC
//                 data={data} 
//                 selectedSicCode={selectedSicCode} 
//                 handleSelectChange={handleSicCodeChange} 
//               />
//             </div>
//             <div style={{ marginBottom: 20 }}>
//               <label><b>NAICS :</b></label>
//               <NAICS
//                 data={data} 
//                 selectedNaicsCode={selectedNaicsCode} 
//                 handleSelectChange={handleNaicsCodeChange} 
//               />
//             </div>   
//             <div style={{ marginBottom: 20 }}>
//               <label><b>Revenue :</b></label>
//               <Revenue
//                 data={data} 
//                 selectedRevenue={selectedRevenue} 
//                 handleSelectChange={handleRevenueChange} 
//               />
//             </div>       
//             <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
//   <label><b>Upload Suppression:</b></label>
//   <div style={{ display: 'flex', alignItems: 'center' }}>
//     <div style={{ textAlign: 'center' }}>
//       <input type="file" onChange={(e) => handleSuppressionFileChange(e.target.files[0])} />
//       {suppressionFile && (
//         <button style={{ marginLeft: 10, padding: '5px 10px', fontSize: 12 }} onClick={() => handleSuppressionFileChange(null)}>
//           <b>Remove</b>
//         </button>
//       )}
//     </div>
//     <button style={{ marginLeft: 10, padding: '5px 10px', fontSize: 12 }} onClick={() => downloadTemplate('suppression')}>
//       <b>Download Suppression Template</b>
//     </button>
//   </div>
// </div>

// <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
//   <label><b>Upload TAL:</b></label>
//   <div style={{ display: 'flex', alignItems: 'center' }}>
//     <div style={{ textAlign: 'center', }}>
//       <input type="file" onChange={(e) => handleTALFileChange(e.target.files[0])} />
//       {talFile && (
//         <button style={{ marginLeft: 10, padding: '5px 10px', fontSize: 12 }} onClick={() => handleTALFileChange(null)}>
//           <b>Remove</b>
//         </button>
//       )}
//     </div>
//     <button style={{ marginLeft: 10, padding: '5px 10px', fontSize: 12 }} onClick={() => downloadTemplate('tal')}>
//       <b>Download TAL Template</b>
//     </button>
//   </div>
// </div>
            

// <div style={{textAlign: 'right' }}>
//   <button style={{ marginRight: 10 }} onClick={() => { handleReset([]); }}>
//     <b>Reset Filters</b>
//   </button>
//   <button style={{ marginRight: 10 }} onClick={handleSubmit} disabled={isSubmitting}>
//     <b>Submit Filters</b>
//   </button>
//   {exportDisabled ? (
//     <p style={{ color: 'red' }}>{exportMessage}</p>
//   ) : (
//     userRole !== 'sales' && (
//       <button onClick={handleExportToExcel}>
//         <b>Export Data</b>
//       </button>
//     )
//   )}
// </div>
//         </div>
//       </div>
//       ) : (
//         <Login handleLogin={handleLogin} loginError={loginError} />
//       )}
//     </div>
//   );
  
// };

// export default App;


















// import React, { useState, useEffect, useCallback } from 'react';
// import { utils, write } from 'xlsx';
// import { Buffer } from 'buffer';
// import axios from 'axios'
// import CountUp from 'react-countup';
// import Login from './Components/Login';
// import Logout from './Components/Logout';
// import './App.css';
// import useUploadSuppression from './Components/suppression';
// import { applySuppression } from './Components/suppression';
// import useUploadTAL from './Components/TAL';

// import Navbar from './Components/Navbar';
// import JobLevel from './Components/Joblevel';
// import StateNames from './Components/StateNames';
// import Keyword from './Components/Keyword';
// import CityNames from './Components/CityNames';
// import Geo from './Components/Geo';
// import JobFunction from './Components/JobFunction';
// import EmployeeSize from './Components/EmployeeSize';
// import Industry from './Components/Industry';
// import SubIndustry from './Components/SubIndustry';
// import SIC from './Components/Sic';
// import NAICS from './Components/Naics';
// import Revenue from './Components/Revenue';
// import DateRangeFilter from './Components/DateRangeFilter';
// import ProgressBar from 'react-progressbar';
// import debounce from 'lodash.debounce';
// import XLSX from 'xlsx';
// import LZString from 'lz-string';
// import { useBeforeunload } from 'react-beforeunload';
// import Loader from 'react-loader-spinner';
// import PuffLoader from 'react-loader-spinner';
// import { Puff } from 'react-loader-spinner';




// const App = () => {
//   const [data, setData] = useState([]);
//   const [selectedKeyword, setSelectedKeyword] = useState([]);
//   const [jobLevelOptions, setJobLevelOptions] = useState([]);
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [selectedCities, setSelectedCities] = useState([]);
//   const [selectedGeo, setSelectedGeo] = useState([]);
//   const [selectedJobFunction, setSelectedJobFunction] = useState([]);
//   const [selectedEmployeeSize, setSelectedEmployeeSize] = useState([]);
//   const [selectedIndustry, setSelectedIndustry] = useState([]);
//   const [selectedSubIndustry, setSelectedSubIndustry] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [selectedSicCode, setSelectedSicCode] = useState([]);
//   const [selectedNaicsCode, setSelectedNaicsCode] = useState([]);
//   const [selectedRevenue, setSelectedRevenue] = useState([]); 
//   const [selectedDateRange, setSelectedDateRange] = useState([]);
//   const [resetFilters, setResetFilters] = useState(false);
//   const [exporting, setExporting] = useState(false);
//   const [error, setError] = useState(null); 
//   const [isSubmitting, setIsSubmitting] = useState(false);  
//   const [shouldSubmitFilters, setShouldSubmitFilters] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);

//   const [ setFile] = useState(null);
//   // const { file: suppressionFile, handleFileChange: handleSuppressionFileChange, applySuppression } = useUploadSuppression();
//   const [suppressionFile, setSuppressionFile] = useState(null);
//   const { file, handleFileChange, handleApplySuppression, applied, applySuppression, suppressionData } = useUploadSuppression(filteredData, setFilteredData);

//   const { talFile, handleTALFileChange, talData, applyTAL, setTalFile } = useUploadTAL(filteredData, setFilteredData);

//   // const { file: talFile, handleFileChange: handleTALFileChange, applyTAL } = useUploadTAL();

//   const [isLoggedIn, setIsLoggedIn] = useState(false); // new state to track login status
//   const [username, setUsername] = useState(''); // new state to store username
//   const [password, setPassword] = useState(''); // new state to store password
//   const [loginError, setLoginError] = useState('');
//   const [userRole, setUserRole] = useState('');

//   const [totalDataCount, setTotalDataCount] = useState(0);
//   const [count, setCount] = useState(0);
//   const [previousCount, setPreviousCount] = useState(0);
//   const [exportDisabled, setExportDisabled] = useState(false); // new state to track export button disable
//   const [exportMessage, setExportMessage] = useState(''); // new state to track export message



// useEffect(() => {
//   const handleBeforeUnload = (event) => {
//     const message = 'Do you really want to refresh? NOTE: Refreshing the app again will lead to loading of data again which might take few minutes. Consider resetting the filters.';
//     (event || window.event).returnValue = message;
//     return message;
//   };

//   window.addEventListener('beforeunload', handleBeforeUnload);

//   return () => {
//     window.removeEventListener('beforeunload', handleBeforeUnload);
//   };
// }, []);


//   const filterData = useCallback((data) => {
//     let filteredData = data;

//     if (selectedKeyword.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedKeyword.some((option) => item.OriginalJobTitle !== null && item.OriginalJobTitle.includes(option.value));
//       });
//     }

//     if (jobLevelOptions.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return jobLevelOptions.some((option) => item.JobLevel !== null && item.JobLevel.includes(option.value));
//       });
//     }

//     if (selectedJobFunction.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedJobFunction.some((option) => item.JobFunction !== null && item.JobFunction.includes(option.value));
//       });
//     }

//     if (selectedGeo.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedGeo.some((option) => item.Country !== null && item.Country.includes(option.value));
//       });
//     }

//     if (selectedStates.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedStates.some((option) => item.State !== null && item.State.includes(option.value));
//       });
//     }

//     if (selectedCities.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedCities.some((option) => item.City !== null && item.City.includes(option.value));
//       });
//     }

//     if (selectedIndustry.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedIndustry.some((option) => item.OriginalIndustry !== null && item.OriginalIndustry.includes(option.value));
//       });
//     }

//     if (selectedSubIndustry.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedSubIndustry.some((option) => item.SubIndustry !== null && item.SubIndustry.includes(option.value));
//       });
//     }

//     if (selectedSicCode.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedSicCode.some((option) => item.SIC !== null && item.SIC.includes(option.value));
//       });
//     }

//     if (selectedNaicsCode.length > 0) {
//       filteredData = filteredData.filter((item) => {
//         return selectedNaicsCode.some((option) => item.NAICS !== null && item.NAICS.includes(option.value));
//       });
//     }

//     if (selectedDateRange.length > 0) {
//       filteredData = selectedDateRange;
//     }

//     if (selectedEmployeeSize.length > 0) {
//       filteredData = selectedEmployeeSize.reduce((acc, curr) => {
//         const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
//         return acc.concat(filteredData.filter((item) => {
//           const empSize = parseInt(item.EmployeeSize.replace(/,/g, ''));
//           return empSize >= minValue && empSize <= maxValue;
//         }));
//       }, []);
//     }

//     if (selectedRevenue.length > 0) {
//       filteredData = selectedRevenue.flatMap((curr) => {
//         const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
//         return data.filter((item) => {
//           if (item.Revenue !== null && item.Revenue !== undefined) {
//             const revenueSizeString = item.Revenue;
//             const revenueSize = parseFloat(revenueSizeString.replace('$', '').replace(' Million', 'e6').replace(' Billion', 'e9'));
//             return revenueSize >= minValue && revenueSize <= maxValue;
//           } else {
//             return false; // or some other logic to handle this case
//           }
//         });
//       });
//     }


// if (suppressionFile) {
//   filteredData = applySuppression(filteredData);
// }

// if (talFile) {
//   filteredData = applyTAL(filteredData);
// }

// if (talFile) {
//   console.log('filteredData before applyTAL:', filteredData);
//   if (talData.CompanyName.length > 0 && talData.Domain.length > 0) {
//     filteredData = applyTAL(filteredData);
//   } else {
//     console.log('talData is empty, skipping filter');
//   }
//   console.log('compressedData after applyTAL:', filteredData);
// }

// return filteredData;
// }, [data, selectedKeyword, jobLevelOptions, selectedStates, selectedCities, selectedGeo, selectedJobFunction, selectedEmployeeSize, selectedIndustry, selectedSubIndustry, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedDateRange, suppressionFile, talFile, applySuppression, applyTAL, suppressionData]);



// //Final - Using Decompression
//     // useEffect(() => {
//     //   const fetchTotalCount = async () => {
//     //     try {
//     //       const response = await axios.get('http://localhost:3002/data');
//     //       setTotalDataCount(response.data.totalRecords);
//     //     } catch (error) {
//     //       console.error('Error fetching total count:', error);
//     //     }
//     //   };
    
//     //   fetchTotalCount();
//     // }, []);
    
//     // // App.js    
//     // useEffect(() => {
//     //   if (shouldSubmitFilters) {
//     //     setIsLoading(true); // Set isLoading to true here
//     //     const fetchFilteredData = async () => {
//     //       const filters = {
//     //         Keywords: selectedKeyword.map((keyword) => keyword.value),
//     //         JobFunction: selectedJobFunction.map((jobFunctions) => jobFunctions.value),
//     //         JobLevel: jobLevelOptions.map((option) => option.value),
//     //         Industry: selectedIndustry.map((industry) => industry.value),
//     //         SubIndustry: selectedSubIndustry.map((subIndustry) => subIndustry.value),
//     //         Geo: selectedGeo.map((geoNames) => geoNames.value),
//     //         StateNames: selectedStates.map((stateNames) => stateNames.value),
//     //         CityNames: selectedCities.map((cityNames) => cityNames.value),
//     //         EmployeeSize: selectedEmployeeSize.map((employeeSizeOptions) => employeeSizeOptions.value),
//     //         SIC: selectedSicCode.map((sic) => sic.value),
//     //         NAICS: selectedNaicsCode.map((naicsCode) => naicsCode.value), 
//     //         Revenue: selectedRevenue.map((revenueOptions) => revenueOptions.value), 
//     //         SuppressionData: suppressionData,
//     //         TALData: talData,
//     //       };
        
//     //       try {
//     //         const response = await axios.post('http://localhost:3002/filteredData', filters);
//     //         const decompressedData = response.data.map((row) => {
//     //           Object.keys(row).forEach((key) => {
//     //             row[key] = LZString.decompress(row[key]);
//     //           });
//     //           return row;
//     //         });
//     //         setFilteredData(decompressedData);
//     //         setCount(decompressedData.length);
//     //       } catch (error) {
//     //         console.error('Error fetching filtered data:', error);
//     //       } finally {
//     //         setIsLoading(false); // Set isLoading to false here
//     //       }
//     //     };
        
//     //     fetchFilteredData();
//     //     setShouldSubmitFilters(false);
//     //   }
//     // }, [jobLevelOptions, selectedIndustry, selectedSubIndustry, selectedJobFunction, selectedKeyword, selectedGeo, selectedStates, selectedCities, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedEmployeeSize , suppressionData, talData, shouldSubmitFilters]); 
  


//     useEffect(() => {
//       const fetchTotalCount = async () => {
//         try {
//           const response = await axios.get('https://sqldatabase.ngrok.app/data');
//           // const response = await axios.get('http://localhost:3002/data');
//           console.log('Response:', response);
//           const totalRecords = response.data.totalRecords;
//           console.log('Total records:', totalRecords);
//           setTotalDataCount(totalRecords);
//         } catch (error) {
//           console.error('Error fetching total records:', error);
//         }
//       };
    
//       fetchTotalCount();
//     }, []);


    
//     // App.js    
//     useEffect(() => {
//       if (shouldSubmitFilters) {
//         setIsLoading(true); // Set isLoading to true here
//         const fetchFilteredData = async () => {
//           const filters = {
//             Keywords: selectedKeyword.map((keyword) => keyword.value),
//             JobFunction: selectedJobFunction.map((jobFunctions) => jobFunctions.value),
//             JobLevel: jobLevelOptions.map((option) => option.value),
//             Industry: selectedIndustry.map((industry) => industry.value),
//             SubIndustry: selectedSubIndustry.map((subIndustry) => subIndustry.value),
//             Geo: selectedGeo.map((geoNames) => geoNames.value),
//             StateNames: selectedStates.map((stateNames) => stateNames.value),
//             CityNames: selectedCities.map((cityNames) => cityNames.value),
//             EmployeeSize: selectedEmployeeSize.map((employeeSizeOptions) => employeeSizeOptions.value),
//             SIC: selectedSicCode.map((sic) => sic.value),
//             NAICS: selectedNaicsCode.map((naicsCode) => naicsCode.value), 
//             Revenue: selectedRevenue.map((revenueOptions) => revenueOptions.value), 
//             SuppressionData: suppressionData,
//             TALData: talData,
//           };
        
//           try {
//             const response = await axios.post('https://sqldatabase.ngrok.app/filteredData', filters);
//             // const response = await axios.post('http://localhost:3002/filteredData', filters);
//             const decompressedData = response.data.map((row) => {
//               Object.keys(row).forEach((key) => {
//                 row[key] = LZString.decompress(row[key]);
//               });
//               return row;
//             });
//             setFilteredData(decompressedData);
//             setCount(decompressedData.length);
//           } catch (error) {
//             console.error('Error fetching filtered data:', error);
//           } finally {
//             setIsLoading(false); // Set isLoading to false here
//           }
//         };
        
//         fetchFilteredData();
//         setShouldSubmitFilters(false);
//       }
//     }, [jobLevelOptions, selectedIndustry, selectedSubIndustry, selectedJobFunction, selectedKeyword, selectedGeo, selectedStates, selectedCities, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedEmployeeSize , suppressionData, talData, shouldSubmitFilters]); 

  


//   useEffect(() => {
//     if (shouldSubmitFilters) {
//       const filteredData = filterData(data);
//       setFilteredData(filteredData);
//       setCount(filteredData.length);
//       setShouldSubmitFilters(false);
//     }
//   }, [data, selectedKeyword, selectedStates, selectedCities, selectedGeo, selectedJobFunction, selectedEmployeeSize, selectedIndustry, selectedSubIndustry, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedDateRange, suppressionFile, talFile, shouldSubmitFilters]);



//   useEffect(() => {
//     if (count > 100000) {
//       setExportDisabled(true);
//       setExportMessage('Data cannot be exported, Please contact Admin');
//     } else {
//       setExportDisabled(false);
//       setExportMessage('');
//     }
//   }, [count]);

//   const handleKeywordChange = (options) => {
//     setSelectedKeyword(options);
//   };

//   const handleJobLevelChange = (options) => {
//     setJobLevelOptions(options);
//   };

//   const handleJobFunctionChange = (options) => {
//     setSelectedJobFunction(options);
//   }; 

//   const handleGeoChange = (options) => {
//     setSelectedGeo(options);
//   };  

//   const handleStateChange = (options) => {
//     setSelectedStates(options);
//   };

//   const handleCityChange = (options) => {
//     setSelectedCities(options);
//   };

//   const handleEmployeeSizeChange = (options) => {
//     setSelectedEmployeeSize(options);
//   };

//   const handleIndustryChange = (options) => {
//     setSelectedIndustry(options);
//   };

//   const handleSubIndustryChange = (options) => {
//     setSelectedSubIndustry(options);
//   };

//   const handleSicCodeChange = (options) => {
//     setSelectedSicCode(options);
//   };

//   const handleNaicsCodeChange = (options) => {
//     setSelectedNaicsCode(options);
//   };

//   const handleRevenueChange = (options) => {
//     setSelectedRevenue(options);
//   };

//   const handleDateRangeChange = (filteredData) => {
//     setSelectedDateRange(filteredData);
//   };

//   const handleSubmit = async () => {
//     setIsLoading(true);
//     setShouldSubmitFilters(true);
//   };




//   const handleExportToExcel = () => {
//     if (!exportDisabled) {
//       const worksheet = utils.json_to_sheet(filteredData);
//       const workbook = utils.book_new();
//       utils.book_append_sheet(workbook, worksheet, 'Data');
//       const csvBuffer = write(workbook, { bookType: 'csv', type: 'buffer' });
//       const dataURI = `data:text/csv;base64,${Buffer.from(csvBuffer).toString('base64')}`;
//       const link = document.createElement('a');
//       link.href = dataURI;
//       link.download = 'Export_data.csv';
//       link.click();
//     }
//   };




//   const handleResetFiltersClick = () => {
//     setResetFilters(true);
//   };

//   const handleReset = () => {
//     setSelectedKeyword([]);
//     setJobLevelOptions([]);
//     setSelectedStates([]);
//     setSelectedCities([]);
//     setSelectedGeo([]);
//     setSelectedJobFunction([]);
//     setSelectedEmployeeSize([]);
//     setSelectedIndustry([]);
//     setSelectedSubIndustry([]);
//     setSelectedSicCode([]);
//     setSelectedNaicsCode([]);
//     setSelectedRevenue([]);
//     setSelectedDateRange([]);
//     setResetFilters(false); // Reset the resetFilters state variable
//     setCount(0); // Reset the count to 0
//     setSuppressionFile(null); // Reset the suppressionFile state variable
//     setTalFile(null); // Reset the talFile state variable
  
//     // Create a new file input element
//     const newTalFileInput = document.createElement('input');
//     newTalFileInput.type = 'file';
//     newTalFileInput.id = 'talFileInput';
//     newTalFileInput.name = 'talFileInput';
  
//     // Get the existing file input element
//     const talFileInput = document.getElementById('talFileInput');
  
//     // Check if the element exists before trying to access its parentNode property
//     if (talFileInput) {
//       talFileInput.parentNode.replaceChild(newTalFileInput, talFileInput);
//     } else {
//       console.log("Element with ID 'talFileInput' not found");
//     }
//   };


//   const downloadTemplate = (templateType) => {
//     const templateName = templateType === 'suppression' ? 'Suppression Template.xlsm' : 'TAL Template.xlsm';
//     const link = document.createElement('a');
//     link.href = `${process.env.PUBLIC_URL}/${templateName}`;
//     link.download = templateName;
//     link.click();
//   };

//   const handleLogin = (username, password) => {
//     if (username === 'admin' && password === 'DemandMedia@2025') {
//       setIsLoggedIn(true);
//       setUserRole('admin');

//     } else if (username === 'Venkat.s' && password === 'a6UsO2F7Xr5x32YX') {
//       setIsLoggedIn(true);
//       setUserRole('Venkat.s');

//     } else if (username === 'Majid.s' && password === 'AjUHt2O9Tv75nM2v') {
//       setIsLoggedIn(true);
//       setUserRole('Majid.s');

//     }  else if (username === 'Arshad.b' && password === 'XB9B7xxZa0XJ1MyE') {
//       setIsLoggedIn(true);
//       setUserRole('Arshad.b');

//     }  else if (username === 'Huzefa.s' && password === '4Je1G0ahUKKrXwwq') {
//       setIsLoggedIn(true);
//       setUserRole('Huzefa.s');

//     }  else if (username === 'Harry.p' && password === 'ct8wgU4wLT8P1AnE') {
//       setIsLoggedIn(true);
//       setUserRole('Harry.p');

//     }  else if (username === 'Sachin.t' && password === '1wlAXo85jkTWzVrC') {
//       setIsLoggedIn(true);
//       setUserRole('Sachin.t');

//     }  else if (username === 'Shreeram.k' && password === 'MYLe38v0Th3cI7FO') {
//       setIsLoggedIn(true);
//       setUserRole('Shreeram.k');

//     }  else if (username === 'Basavraj.m' && password === '5jbbCZ15Mi2jAW23') {
//       setIsLoggedIn(true);
//       setUserRole('Basavraj.m');

//     }  else {
//       setLoginError('Incorrect ID or Password. Please contact admin');
//     }
//   };


  
//   const handleLogout = () => {
//     setIsLoggedIn(false);
//     setCount(0); // Reset the count to 0 when logging out
//   };

//   return (
//     <div>
//       {isLoggedIn ? (
//         <div>
//           {isLoading ? (
//               <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//               <Puff color="#00BFFF" height={100} width={100} />
//             </div>
//           ) : (
//             <div>
//               <Navbar title="CM360" logout={<Logout handleLogout={handleLogout} />} />
//               <div className='top-container'>
//                 <div className='columns-container' style={{
//                   display: 'grid',
//                   gridTemplateColumns: '1fr 1fr',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   textAlign: 'center',
//                   maxWidth: 800, /* add max-width to match the width of other filters */
//                 }}>
//                   <div className='column total-records'>
//                     <p>
//                       <strong style={{ fontSize: 18, marginRight: 10, color: '#19A79C' }}>TOTAL RECORDS : </strong>
//                       <span style={{ fontSize: 24, fontWeight: 500, color: '#19A79C' }}>{totalDataCount}</span>
//                     </p>
//                   </div>
//                   <div className='column filtered-records'>
//                     <p>
//                       <strong style={{ fontSize: 18, marginRight: 10, color: '#19A79C' }}>FILTERED RECORDS :</strong>
//                       <CountUp
//                         key={count} // re-render with new value
//                         start={previousCount} // set starting value to previous count
//                         end={count}
//                         duration={2}
//                         separator=","
//                         decimals={0}
//                         prefix=""
//                         suffix=""
//                         onEnd={() => {
//                           setPreviousCount(count); // update previous count
//                           console.log("Finished counting");
//                         }}
//                         style={{ fontSize: 24, fontWeight: 500, color: count > 100000 ? 'red' : '#19A79C' }}
//                       />
//                     </p>
//                   </div>
//                 </div>
//               </div>
  
//               <div className='container'>
  
//                 {/* <div style={{ marginBottom: 20 }}>
//                   <DateRangeFilter 
//                     data={data} 
//                     handleSelectChange={handleDateRangeChange} 
//                     />
//                 </div> */}
//                 <div style={{ marginBottom: 20 }}>
//                   <label style={{color:'white'}}><b>Keywords :</b></label>
//                   <Keyword 
//                     data={data} 
//                     selectedKeyword={selectedKeyword} 
//                     handleSelectChange={handleKeywordChange} 
//                   />
//                 </div>
                
//                 <div style={{ marginBottom: 20 }}>
//                   <label style={{color:'white'}}><b>Job Function :</b></label>
//                   <JobFunction 
//                     data={data} 
//                     selectedJobFunction={selectedJobFunction} 
//                     handleSelectChange={handleJobFunctionChange} 
//                   />
//                 </div>
//                 <div style={{ marginBottom: 20 }}>
//                   <label style={{color:'white'}}><b>Job Level :</b></label>
//                   <JobLevel 
//                     data={data} 
//                     jobLevelOptions={jobLevelOptions} 
//                     handleSelectChange={handleJobLevelChange} 
//                   />
//                 </div>
//                 <div style={{ marginBottom: 20 }}>
//                   <label style={{color:'white'}}><b>Industry :</b></label>
//                   <Industry 
//                     data={data} 
//                     selectedIndustry={selectedIndustry} 
//                     handleSelectChange={handleIndustryChange} 
//                   />
//                 </div>
//                 <div style={{ marginBottom: 20 }}>
//                   <label style={{color:'white'}}><b>SubIndustry :</b></label>
//                   <SubIndustry 
//                     data={data} 
//                     selectedSubIndustry={selectedSubIndustry} 
//                     handleSelectChange={handleSubIndustryChange} 
//                   />
//                 </div>
//                 <div style={{ marginBottom: 20 }}>
//                   <label style={{color:'white'}}><b>Country :</b></label>
//                   <Geo 
//                     data={data} 
//                     selectedGeo={selectedGeo} 
//                     handleSelectChange={handleGeoChange} 
//                   />
//                 </div>
//                 <div style={{ marginBottom: 20 }}>
//                   <label style={{color:'white'}}><b>States :</b></label>
//                   <StateNames 
//                     data={data} 
//                     selectedStates={selectedStates} 
//                     handleSelectChange={handleStateChange} 
//                   />
//                 </div>
//                 <div style={{ marginBottom: 20 }}>
//                   <label style={{color:'white'}}><b>City :</b></label>
//                   <CityNames 
//                     data={data} 
//                     selectedCities={selectedCities} 
//                     handleSelectChange={handleCityChange} 
//                   />
//                 </div>
//                 <div style={{ marginBottom: 20 }}>
//                   <label style={{color:'white'}}><b>Employee Size :</b></label>
//                   <EmployeeSize 
//                     data={data} 
//                     selectedEmployeeSize={selectedEmployeeSize } 
//                     handleSelectChange={handleEmployeeSizeChange} 
//                   />
//                 </div>
//                 <div style={{ marginBottom: 20 }}>
//                   <label style={{color:'white'}}><b>SIC :</b></label>
//                   <SIC
//                     data={data} 
//                     selectedSicCode={selectedSicCode} 
//                     handleSelectChange={handleSicCodeChange} 
//                   />
//                 </div>
//                 <div style={{ marginBottom: 20 }}>
//                   <label style={{color:'white'}}><b>NAICS :</b></label>
//                   <NAICS
//                     data={data} 
//                     selectedNaicsCode={selectedNaicsCode} 
//                     handleSelectChange={handleNaicsCodeChange} 
//                   />
//                 </div>   
//                 <div style={{ marginBottom: 40 }}>
//                   <label style={{color:'white'}}><b>Revenue :</b></label>
//                   <Revenue
//                     data={data} 
//                     selectedRevenue={selectedRevenue} 
//                     handleSelectChange={handleRevenueChange} 
//                   />
//                 </div>    

//                 <div style={{ display: 'flex', justifyContent: 'space-between', margin: 'auto', maxWidth: '60%' }}>
//   <div style={{ backgroundColor: '#FAF9F6', padding: 25, width: '50%', marginRight: 20, borderRadius: '20px' }}>
//     <label style={{ color: '#1EAA9D', fontSize: 18 }}><b>Upload Suppression:</b></label>
//     <div style={{ marginTop: 10 }}>
//       <input type="file" onChange={(e) => handleFileChange(e.target.files[0])} />
//       {/* {suppressionFile && (
//         <div>
//           <p>File uploaded: {suppressionFile.name}</p>
//           <button style={{ padding: '5px 10px', fontSize: 12 }} onClick={() => setSuppressionFile(null)}><b>Remove</b></button>
//         </div>
//       )} */}
//     </div>
//     <div style={{ marginTop: 10 }}>
//       <button style={{ padding: '5px 10px', fontSize: 12 }} onClick={() => downloadTemplate('suppression')}><b>Download Suppression Template</b></button>
//     </div>
//   </div>
//   <div style={{ backgroundColor: '#FAF9F6', padding: 25, width: '50%', borderRadius: '20px'}}>
//     <label style={{ color: '#1EAA9D', fontSize: 18 }}><b>Upload TAL:</b></label>
//     <div style={{ marginTop: 10 }}>
//       <input type="file" onChange={(e) => handleTALFileChange(e.target.files[0])} />
//       {/* {talFile && (
//         <div>
//           <p>File uploaded: {talFile.name}</p>
//           <button style={{ padding: '5px 10px', fontSize: 12 }} onClick={() => setTalFile(null)}><b>Remove</b></button>
//         </div>
//       )} */}
//     </div>
//     <div style={{ marginTop: 10 }}>
//       <button style={{ padding: '5px 10px', fontSize: 12 }} onClick={() => downloadTemplate('tal')}><b>Download TAL Template</b></button>
//     </div>
//   </div>
// </div>
  
//                 <div style={{textAlign: 'center', margin: '40px'}}>
//                   <button style={{ marginRight: 10 }} onClick={() => { handleReset([]); }}>
//                     <b>Reset Filters</b>
//                   </button>
//                   <button style={{ marginRight: 10 }} onClick={handleSubmit} disabled={isSubmitting}>
//                     <b>Submit Filters</b>
//                   </button>
//                   {exportDisabled ? (
//                       <p style={{ color: 'red' }}>{exportMessage}</p>
//                     ) : (

//                       userRole === 'Basavraj.m' ||
//                       userRole === 'Shreeram.k' ||
//                       userRole === 'Sachin.t' ||
//                       userRole === 'Harry.p' ? null : (
//                         <button onClick={handleExportToExcel}>
//                           <b>Export Data</b>
//                         </button>
//                       )
//                     )}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       ) : (
//         <Login handleLogin={handleLogin} loginError={loginError} />
//       )}
//     </div>
//   );
  
// };

// export default App;

//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect, useCallback } from 'react';
import { utils, write } from 'xlsx';
import { Buffer } from 'buffer';
import axios from 'axios'
import CountUp from 'react-countup';
import Login from './Components/Login';
import Logout from './Components/Logout';
import './App.css';
import useUploadSuppression from './Components/suppression';
import { applySuppression } from './Components/suppression';
import useUploadTAL from './Components/TAL';

import Navbar from './Components/Navbar';
import JobLevel from './Components/Joblevel';
import StateNames from './Components/StateNames';
import Keyword from './Components/Keyword';
import CityNames from './Components/CityNames';
import Geo from './Components/Geo';
import JobFunction from './Components/JobFunction';
import EmployeeSize from './Components/EmployeeSize';
import Industry from './Components/Industry';
import SubIndustry from './Components/SubIndustry';
import SIC from './Components/Sic';
import NAICS from './Components/Naics';
import Revenue from './Components/Revenue';
import DateRangeFilter from './Components/DateRangeFilter';
import ProgressBar from 'react-progressbar';
import debounce from 'lodash.debounce';
import XLSX from 'xlsx';
import LZString from 'lz-string';
import { useBeforeunload } from 'react-beforeunload';
import Loader from 'react-loader-spinner';
import PuffLoader from 'react-loader-spinner';
import { Puff } from 'react-loader-spinner';




const App = () => {
  const [data, setData] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [jobLevelOptions, setJobLevelOptions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedGeo, setSelectedGeo] = useState([]);
  const [selectedJobFunction, setSelectedJobFunction] = useState([]);
  const [selectedEmployeeSize, setSelectedEmployeeSize] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedSubIndustry, setSelectedSubIndustry] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSicCode, setSelectedSicCode] = useState([]);
  const [selectedNaicsCode, setSelectedNaicsCode] = useState([]);
  const [selectedRevenue, setSelectedRevenue] = useState([]); 
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [resetFilters, setResetFilters] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [error, setError] = useState(null); 
  const [isSubmitting, setIsSubmitting] = useState(false);  
  const [shouldSubmitFilters, setShouldSubmitFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [ setFile] = useState(null);
  // const { file: suppressionFile, handleFileChange: handleSuppressionFileChange, applySuppression } = useUploadSuppression();
  const [suppressionFile, setSuppressionFile] = useState(null);
  const { file, handleFileChange, handleApplySuppression, applied, applySuppression, suppressionData } = useUploadSuppression(filteredData, setFilteredData);

  const { talFile, handleTALFileChange, talData, applyTAL, setTalFile } = useUploadTAL(filteredData, setFilteredData);

  // const { file: talFile, handleFileChange: handleTALFileChange, applyTAL } = useUploadTAL();

  const [currentUserEmail, setCurrentUserEmail] = useState(''); // State to store the logged-in user's email
  const [isLoggedIn, setIsLoggedIn] = useState(false); // new state to track login status
  const [username, setUsername] = useState(''); // new state to store username
  const [password, setPassword] = useState(''); // new state to store password
  const [loginError, setLoginError] = useState('');
  const [userRole, setUserRole] = useState('');

  const [totalDataCount, setTotalDataCount] = useState(0);
  const [count, setCount] = useState(0);
  const [previousCount, setPreviousCount] = useState(0);
  const [exportDisabled, setExportDisabled] = useState(false); // new state to track export button disable
  const [exportMessage, setExportMessage] = useState(''); // new state to track export message



useEffect(() => {
  const handleBeforeUnload = (event) => {
    const message = 'Do you really want to refresh? NOTE: Refreshing the app again will lead to loading of data again which might take few minutes. Consider resetting the filters.';
    (event || window.event).returnValue = message;
    return message;
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, []);


  const filterData = useCallback((data) => {
    let filteredData = data;

    if (selectedKeyword.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedKeyword.some((option) => item.OriginalJobTitle !== null && item.OriginalJobTitle.includes(option.value));
      });
    }

    if (jobLevelOptions.length > 0) {
      filteredData = filteredData.filter((item) => {
        return jobLevelOptions.some((option) => item.JobLevel !== null && item.JobLevel.includes(option.value));
      });
    }

    if (selectedJobFunction.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedJobFunction.some((option) => item.JobFunction !== null && item.JobFunction.includes(option.value));
      });
    }

    if (selectedGeo.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedGeo.some((option) => item.Country !== null && item.Country.includes(option.value));
      });
    }

    if (selectedStates.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedStates.some((option) => item.State !== null && item.State.includes(option.value));
      });
    }

    if (selectedCities.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedCities.some((option) => item.City !== null && item.City.includes(option.value));
      });
    }

    if (selectedIndustry.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedIndustry.some((option) => item.OriginalIndustry !== null && item.OriginalIndustry.includes(option.value));
      });
    }

    if (selectedSubIndustry.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedSubIndustry.some((option) => item.SubIndustry !== null && item.SubIndustry.includes(option.value));
      });
    }

    if (selectedSicCode.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedSicCode.some((option) => item.SIC !== null && item.SIC.includes(option.value));
      });
    }

    if (selectedNaicsCode.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedNaicsCode.some((option) => item.NAICS !== null && item.NAICS.includes(option.value));
      });
    }

    if (selectedDateRange.length > 0) {
      filteredData = selectedDateRange;
    }

    if (selectedEmployeeSize.length > 0) {
      filteredData = selectedEmployeeSize.reduce((acc, curr) => {
        const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
        return acc.concat(filteredData.filter((item) => {
          const empSize = parseInt(item.EmployeeSize.replace(/,/g, ''));
          return empSize >= minValue && empSize <= maxValue;
        }));
      }, []);
    }

    if (selectedRevenue.length > 0) {
      filteredData = selectedRevenue.flatMap((curr) => {
        const [minValue, maxValue] = curr.value.replace(/,/g, '').split('-').map(Number);
        return data.filter((item) => {
          if (item.Revenue !== null && item.Revenue !== undefined) {
            const revenueSizeString = item.Revenue;
            const revenueSize = parseFloat(revenueSizeString.replace('$', '').replace(' Million', 'e6').replace(' Billion', 'e9'));
            return revenueSize >= minValue && revenueSize <= maxValue;
          } else {
            return false; // or some other logic to handle this case
          }
        });
      });
    }


if (suppressionFile) {
  filteredData = applySuppression(filteredData);
}

if (talFile) {
  filteredData = applyTAL(filteredData);
}

if (talFile) {
  console.log('filteredData before applyTAL:', filteredData);
  if (talData.CompanyName.length > 0 && talData.Domain.length > 0) {
    filteredData = applyTAL(filteredData);
  } else {
    console.log('talData is empty, skipping filter');
  }
  console.log('compressedData after applyTAL:', filteredData);
}

return filteredData;
}, [data, selectedKeyword, jobLevelOptions, selectedStates, selectedCities, selectedGeo, selectedJobFunction, selectedEmployeeSize, selectedIndustry, selectedSubIndustry, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedDateRange, suppressionFile, talFile, applySuppression, applyTAL, suppressionData]);


    useEffect(() => {
      const fetchTotalCount = async () => {
        try {
          const response = await axios.get('https://sqldatabase.ngrok.app/data');
          console.log('Response:', response);
          const totalRecords = response.data.totalRecords;
          console.log('Total records:', totalRecords);
          setTotalDataCount(totalRecords);
        } catch (error) {
          console.error('Error fetching total records:', error);
        }
      };
    
      fetchTotalCount();
    }, []);


    
    // App.js    
    useEffect(() => {
      if (shouldSubmitFilters) {
        setIsLoading(true); // Set isLoading to true here
        const fetchFilteredData = async () => {
          const filters = {
            Keywords: selectedKeyword.map((keyword) => keyword.value),
            JobFunction: selectedJobFunction.map((jobFunctions) => jobFunctions.value),
            JobLevel: jobLevelOptions.map((option) => option.value),
            Industry: selectedIndustry.map((industry) => industry.value),
            SubIndustry: selectedSubIndustry.map((subIndustry) => subIndustry.value),
            Geo: selectedGeo.map((geoNames) => geoNames.value),
            StateNames: selectedStates.map((stateNames) => stateNames.value),
            CityNames: selectedCities.map((cityNames) => cityNames.value),
            EmployeeSize: selectedEmployeeSize.map((employeeSizeOptions) => employeeSizeOptions.value),
            SIC: selectedSicCode.map((sic) => sic.value),
            NAICS: selectedNaicsCode.map((naicsCode) => naicsCode.value), 
            Revenue: selectedRevenue.map((revenueOptions) => revenueOptions.value), 
            SuppressionData: suppressionData,
            TALData: talData,
          };
        
          try {
            const response = await axios.post('https://sqldatabase.ngrok.app/filteredData', filters);
            const decompressedData = response.data.map((row) => {
              Object.keys(row).forEach((key) => {
                row[key] = LZString.decompress(row[key]);
              });
              return row;
            });
            setFilteredData(decompressedData);
            setCount(decompressedData.length);
          } catch (error) {
            console.error('Error fetching filtered data:', error);
          } finally {
            setIsLoading(false); // Set isLoading to false here
          }
        };
        
        fetchFilteredData();
        setShouldSubmitFilters(false);
      }
    }, [jobLevelOptions, selectedIndustry, selectedSubIndustry, selectedJobFunction, selectedKeyword, selectedGeo, selectedStates, selectedCities, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedEmployeeSize , suppressionData, talData, shouldSubmitFilters]); 

  


  useEffect(() => {
    if (shouldSubmitFilters) {
      const filteredData = filterData(data);
      setFilteredData(filteredData);
      setCount(filteredData.length);
      setShouldSubmitFilters(false);
    }
  }, [data, selectedKeyword, selectedStates, selectedCities, selectedGeo, selectedJobFunction, selectedEmployeeSize, selectedIndustry, selectedSubIndustry, selectedSicCode, selectedNaicsCode, selectedRevenue, selectedDateRange, suppressionFile, talFile, shouldSubmitFilters]);



  useEffect(() => {
    if (count > 100000) {
      setExportDisabled(true);
      setExportMessage('Data cannot be exported, Please contact Admin');
    } else {
      setExportDisabled(false);
      setExportMessage('');
    }
  }, [count]);

  const handleKeywordChange = (options) => {
    setSelectedKeyword(options);
  };

  const handleJobLevelChange = (options) => {
    setJobLevelOptions(options);
  };

  const handleJobFunctionChange = (options) => {
    setSelectedJobFunction(options);
  }; 

  const handleGeoChange = (options) => {
    setSelectedGeo(options);
  };  

  const handleStateChange = (options) => {
    setSelectedStates(options);
  };

  const handleCityChange = (options) => {
    setSelectedCities(options);
  };

  const handleEmployeeSizeChange = (options) => {
    setSelectedEmployeeSize(options);
  };

  const handleIndustryChange = (options) => {
    setSelectedIndustry(options);
  };

  const handleSubIndustryChange = (options) => {
    setSelectedSubIndustry(options);
  };

  const handleSicCodeChange = (options) => {
    setSelectedSicCode(options);
  };

  const handleNaicsCodeChange = (options) => {
    setSelectedNaicsCode(options);
  };

  const handleRevenueChange = (options) => {
    setSelectedRevenue(options);
  };

  const handleDateRangeChange = (filteredData) => {
    setSelectedDateRange(filteredData);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setShouldSubmitFilters(true);
  };




  const handleExportToExcel = () => {
    if (!exportDisabled) {
      const worksheet = utils.json_to_sheet(filteredData);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Data');
      const csvBuffer = write(workbook, { bookType: 'csv', type: 'buffer' });
      const dataURI = `data:text/csv;base64,${Buffer.from(csvBuffer).toString('base64')}`;
      const link = document.createElement('a');
      link.href = dataURI;
      link.download = 'Export_data.csv';
      link.click();
    }
  };




  const handleResetFiltersClick = () => {
    setResetFilters(true);
  };

  const handleReset = () => {
    setSelectedKeyword([]);
    setJobLevelOptions([]);
    setSelectedStates([]);
    setSelectedCities([]);
    setSelectedGeo([]);
    setSelectedJobFunction([]);
    setSelectedEmployeeSize([]);
    setSelectedIndustry([]);
    setSelectedSubIndustry([]);
    setSelectedSicCode([]);
    setSelectedNaicsCode([]);
    setSelectedRevenue([]);
    setSelectedDateRange([]);
    setResetFilters(false); // Reset the resetFilters state variable
    setCount(0); // Reset the count to 0
    setSuppressionFile(null); // Reset the suppressionFile state variable
    setTalFile(null); // Reset the talFile state variable
  
    // Create a new file input element
    const newTalFileInput = document.createElement('input');
    newTalFileInput.type = 'file';
    newTalFileInput.id = 'talFileInput';
    newTalFileInput.name = 'talFileInput';
  
    // Get the existing file input element
    const talFileInput = document.getElementById('talFileInput');
  
    // Check if the element exists before trying to access its parentNode property
    if (talFileInput) {
      talFileInput.parentNode.replaceChild(newTalFileInput, talFileInput);
    } else {
      console.log("Element with ID 'talFileInput' not found");
    }
  };


  const downloadTemplate = (templateType) => {
    const templateName = templateType === 'suppression' ? 'Suppression Template.xlsm' : 'TAL Template.xlsm';
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/${templateName}`;
    link.download = templateName;
    link.click();
  };

  const handleLogin = (username, password) => {
    if (username === 'admin' && password === 'DemandMediaSaNaYa@2025') {
      setIsLoggedIn(true);
      setUserRole('admin');
      setCurrentUserEmail('nadeem.m@demandmediabpm.com');

    } else if (username === 'Venkat.s' && password === 'a6UsO2F7Xr5x32YX') {
      setIsLoggedIn(true);
      setUserRole('Venkat.s');
      setCurrentUserEmail('venkat.s@demandmediabpm.com');

    } else if (username === 'Majid.s' && password === 'AjUHt2O9Tv75nM2v') {
      setIsLoggedIn(true);
      setUserRole('Majid.s');
      setCurrentUserEmail('majid.s@demandmediabpm.com');

    }  else if (username === 'Arshad.b' && password === 'XB9B7xxZa0XJ1MyE') {
      setIsLoggedIn(true);
      setUserRole('Arshad.b');
      setCurrentUserEmail('arshad.b@demandmediabpm.com');

    }  else if (username === 'Huzefa.s' && password === '4Je1G0ahUKKrXwwq') {
      setIsLoggedIn(true);
      setUserRole('Huzefa.s');
      setCurrentUserEmail('huzefa.s@demandmediabpm.com');

    }  else if (username === 'Harry.p' && password === 'ct8wgU4wLT8P1AnE') {
      setIsLoggedIn(true);
      setUserRole('Harry.p');

    }  else if (username === 'Sachin.t' && password === '1wlAXo85jkTWzVrC') {
      setIsLoggedIn(true);
      setUserRole('Sachin.t');

    }  else if (username === 'Shreeram.k' && password === 'MYLe38v0Th3cI7FO') {
      setIsLoggedIn(true);
      setUserRole('Shreeram.k');

    }  else if (username === 'Basavraj.m' && password === '5jbbCZ15Mi2jAW23') {
      setIsLoggedIn(true);
      setUserRole('Basavraj.m');

    }  else {
      setLoginError('Incorrect ID or Password. Please contact admin');
    }
  };



  const handleExportToEmail = async () => {
    if (!exportDisabled && currentUserEmail) {
        try {
            // Convert filtered data to CSV format
            const worksheet = utils.json_to_sheet(filteredData);
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, 'Filtered Data');

            // Write the workbook to a CSV string
            const csvBuffer = write(workbook, { bookType: 'csv', type: 'buffer' });

            // Create a FormData object to send the file
            const formData = new FormData();
            const file = new Blob([csvBuffer], { type: 'text/csv' });
            formData.append('file', file, 'FilteredData.csv'); // Ensure the key matches
            formData.append('email', currentUserEmail); // Ensure email is included

            // Include the count in the subject
            const subject = `Exported Data - Count: ${count}`; // Assuming 'count' is the count of filteredData
            formData.append('subject', subject); // Add subject to the form data

            // Send the email with the CSV file
            const response = await axios.post('https://sqldatabase.ngrok.app/sendExportedData', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Important for file uploads
                }
            });

            alert(response.data.message);
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send email. Please try again.');
        }
    } else {
        alert('Export is disabled or user is not logged in.');
    }
};


  const handleLogout = () => {
    setIsLoggedIn(false);
    setCount(0); // Reset the count to 0 when logging out
  };



  return (
    <div>
      {isLoggedIn ? (
        <div>
          {isLoading ? (
              <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Puff color="#00BFFF" height={100} width={100} />
            </div>
          ) : (
            <div>
              <Navbar title="CM360" logout={<Logout handleLogout={handleLogout} />} />
              <div className='top-container'>
                <div className='columns-container' style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  maxWidth: 800, /* add max-width to match the width of other filters */
                }}>
                  <div className='column total-records'>
                    <p>
                      <strong style={{ fontSize: 18, marginRight: 10, color: '#19A79C' }}>TOTAL RECORDS : </strong>
                      <span style={{ fontSize: 24, fontWeight: 500, color: '#19A79C' }}>{totalDataCount}</span>
                    </p>
                  </div>
                  <div className='column filtered-records'>
                    <p>
                      <strong style={{ fontSize: 18, marginRight: 10, color: '#19A79C' }}>FILTERED RECORDS :</strong>
                      <CountUp
                        key={count} // re-render with new value
                        start={previousCount} // set starting value to previous count
                        end={count}
                        duration={2}
                        separator=","
                        decimals={0}
                        prefix=""
                        suffix=""
                        onEnd={() => {
                          setPreviousCount(count); // update previous count
                          console.log("Finished counting");
                        }}
                        style={{ fontSize: 24, fontWeight: 500, color: count > 100000 ? 'red' : '#19A79C' }}
                      />
                    </p>
                  </div>
                </div>
              </div>
  
              <div className='container'>
  
                {/* <div style={{ marginBottom: 20 }}>
                  <DateRangeFilter 
                    data={data} 
                    handleSelectChange={handleDateRangeChange} 
                    />
                </div> */}
                <div style={{ marginBottom: 20 }}>
                  <label style={{color:'white'}}><b>Keywords :</b></label>
                  <Keyword 
                    data={data} 
                    selectedKeyword={selectedKeyword} 
                    handleSelectChange={handleKeywordChange} 
                  />
                </div>
                
                <div style={{ marginBottom: 20 }}>
                  <label style={{color:'white'}}><b>Job Function :</b></label>
                  <JobFunction 
                    data={data} 
                    selectedJobFunction={selectedJobFunction} 
                    handleSelectChange={handleJobFunctionChange} 
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <label style={{color:'white'}}><b>Job Level :</b></label>
                  <JobLevel 
                    data={data} 
                    jobLevelOptions={jobLevelOptions} 
                    handleSelectChange={handleJobLevelChange} 
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <label style={{color:'white'}}><b>Industry :</b></label>
                  <Industry 
                    data={data} 
                    selectedIndustry={selectedIndustry} 
                    handleSelectChange={handleIndustryChange} 
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <label style={{color:'white'}}><b>SubIndustry :</b></label>
                  <SubIndustry 
                    data={data} 
                    selectedSubIndustry={selectedSubIndustry} 
                    handleSelectChange={handleSubIndustryChange} 
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <label style={{color:'white'}}><b>Country :</b></label>
                  <Geo 
                    data={data} 
                    selectedGeo={selectedGeo} 
                    handleSelectChange={handleGeoChange} 
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <label style={{color:'white'}}><b>States :</b></label>
                  <StateNames 
                    data={data} 
                    selectedStates={selectedStates} 
                    handleSelectChange={handleStateChange} 
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <label style={{color:'white'}}><b>City :</b></label>
                  <CityNames 
                    data={data} 
                    selectedCities={selectedCities} 
                    handleSelectChange={handleCityChange} 
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <label style={{color:'white'}}><b>Employee Size :</b></label>
                  <EmployeeSize 
                    data={data} 
                    selectedEmployeeSize={selectedEmployeeSize } 
                    handleSelectChange={handleEmployeeSizeChange} 
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <label style={{color:'white'}}><b>SIC :</b></label>
                  <SIC
                    data={data} 
                    selectedSicCode={selectedSicCode} 
                    handleSelectChange={handleSicCodeChange} 
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <label style={{color:'white'}}><b>NAICS :</b></label>
                  <NAICS
                    data={data} 
                    selectedNaicsCode={selectedNaicsCode} 
                    handleSelectChange={handleNaicsCodeChange} 
                  />
                </div>   
                <div style={{ marginBottom: 40 }}>
                  <label style={{color:'white'}}><b>Revenue :</b></label>
                  <Revenue
                    data={data} 
                    selectedRevenue={selectedRevenue} 
                    handleSelectChange={handleRevenueChange} 
                  />
                </div>    

                <div style={{ display: 'flex', justifyContent: 'space-between', margin: 'auto', maxWidth: '60%' }}>
  <div style={{ backgroundColor: '#FAF9F6', padding: 25, width: '50%', marginRight: 20, borderRadius: '20px' }}>
    <label style={{ color: '#1EAA9D', fontSize: 18 }}><b>Upload Suppression:</b></label>
    <div style={{ marginTop: 10 }}>
      <input type="file" onChange={(e) => handleFileChange(e.target.files[0])} />
      {/* {suppressionFile && (
        <div>
          <p>File uploaded: {suppressionFile.name}</p>
          <button style={{ padding: '5px 10px', fontSize: 12 }} onClick={() => setSuppressionFile(null)}><b>Remove</b></button>
        </div>
      )} */}
    </div>
    <div style={{ marginTop: 10 }}>
      <button style={{ padding: '5px 10px', fontSize: 12 }} onClick={() => downloadTemplate('suppression')}><b>Download Suppression Template</b></button>
    </div>
  </div>
  <div style={{ backgroundColor: '#FAF9F6', padding: 25, width: '50%', borderRadius: '20px'}}>
    <label style={{ color: '#1EAA9D', fontSize: 18 }}><b>Upload TAL:</b></label>
    <div style={{ marginTop: 10 }}>
      <input type="file" onChange={(e) => handleTALFileChange(e.target.files[0])} />
      {/* {talFile && (
        <div>
          <p>File uploaded: {talFile.name}</p>
          <button style={{ padding: '5px 10px', fontSize: 12 }} onClick={() => setTalFile(null)}><b>Remove</b></button>
        </div>
      )} */}
    </div>
    <div style={{ marginTop: 10 }}>
      <button style={{ padding: '5px 10px', fontSize: 12 }} onClick={() => downloadTemplate('tal')}><b>Download TAL Template</b></button>
    </div>
  </div>
</div>
  
                <div style={{textAlign: 'center', margin: '40px'}}>
                  <button style={{ marginRight: 10 }} onClick={() => { handleReset([]); }}>
                    <b>Reset Filters</b>
                  </button>
                  <button style={{ marginRight: 10 }} onClick={handleSubmit} disabled={isSubmitting}>
                    <b>Submit Filters</b>
                  </button>
                  {exportDisabled ? (
                      <p style={{ color: 'red' }}>{exportMessage}</p>
                    ) : (
                      userRole === 'Basavraj.m' ||
                      userRole === 'Shreeram.k' ||
                      userRole === 'Sachin.t' ||
                      userRole === 'Harry.p' ? null : (
                        <button onClick={handleExportToEmail}>
                          <b>Export Data on Email</b>
                        </button>
                      )
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Login handleLogin={handleLogin} loginError={loginError} />
      )}
    </div>
  );
  
};

export default App;
