import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const jobFunctions = [
  { label: 'Consulting', value: 'Consulting' },
  { label: 'Customer Experience', value: 'Customer Experience' },
  { label: 'Engineering (non-software)', value: 'Engineering (non-software)' },
  { label: 'Finance', value: 'Finance' },
  { label: 'General Management', value: 'General Management' },
  { label: 'Government and Education', value: 'Government and Education' },
  { label: 'Human Resources', value: 'Human Resources' },
  { label: 'Information Technology', value: 'Information Technology' },
  { label: 'Legal', value: 'Legal' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Medical & Health', value: 'Medical & Health' },
  { label: 'Operations', value: 'Operations' },
  { label: 'Other Professions', value: 'Other Professions' },
  // { label: 'Product Management and Innovation', value: 'Product Management and Innovation' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Software Development', value: 'Software Development' },
  { label: 'Content Production', value: 'Content Production' },
  { label: 'Design', value: 'Design' },
  { label: 'Education', value: 'Education' },
  { label: 'Food Services', value: 'Food Services' },
  { label: 'Logistics', value: 'Logistics' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Other Functions', value: 'Other Functions' },
  { label: 'Procurement', value: 'Procurement' },
  { label: 'Product Management, Research, & Innovation', value: 'Product Management, Research, & Innovation' },
  { label: 'Property Management', value: 'Property Management' },
  { label: 'Protective Services', value: 'Protective Services' },
  { label: 'Public Relations', value: 'Public Relations' },
];

const JobFunction = ({ selectedJobFunction, handleSelectChange }) => {
  const animatedComponents = makeAnimated();

  return (
    <div>
      <Select
        isMulti
        value={selectedJobFunction}
        onChange={handleSelectChange}
        options={jobFunctions}
        placeholder="Select Job Function..."
        components={animatedComponents}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default JobFunction;


// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const JobFunction = ({ data, selectedJobFunction, handleSelectChange }) => {
//   const jobFunction = [...new Set(data.map((item) => item.JobFunction))].map((jobFunc) => ({
//     value: jobFunc,
//     label: jobFunc,
//   }));

//   // Sort the city names in alphabetical order
//   jobFunction.sort((a, b) => a.label.localeCompare(b.label));  

//   const animatedComponents = makeAnimated();  

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedJobFunction}
//         onChange={handleSelectChange}
//         options={jobFunction}
//         placeholder="Select Job Function..."
//         components={animatedComponents}
//         closeMenuOnSelect={false} // Add this prop
//       />
//     </div>
//   );
// };

// export default JobFunction;