import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const stateNames = [
  { label: 'A Corusna', value: 'A Corusna' },
  { label: 'A Alovci Municipality', value: 'A Alovci Municipality' },
  { label: 'A Avnik Municipality', value: 'A Avnik Municipality' },
  { label: 'A Empetera€“Vrtojba Municipality', value: 'A Empetera€“Vrtojba Municipality' },
  { label: 'A EnaUr Municipality', value: 'A EnaUr Municipality' },
  { label: 'A Entilj Municipality', value: 'A Entilj Municipality' },
  { label: 'A Entjernej Municipality', value: 'A Entjernej Municipality' },
  { label: 'A Entjur Municipality', value: 'A Entjur Municipality' },
  { label: 'A Entrupert Municipality', value: 'A Entrupert Municipality' },
  { label: 'A Iauliai City Municipality', value: 'A Iauliai City Municipality' },
  { label: 'A Ibenik-Knin', value: 'A Ibenik-Knin' },
  { label: 'A Kocjan Municipality', value: 'A Kocjan Municipality' },
  { label: 'A Kofja Loka Municipality', value: 'A Kofja Loka Municipality' },
  { label: 'A Marje Pri Jelaaah Municipality', value: 'A Marje Pri Jelaaah Municipality' },
  { label: 'A Marjeaake Toplice Municipality', value: 'A Marjeaake Toplice Municipality' },
  { label: 'A Martno Ob Paki Municipality', value: 'A Martno Ob Paki Municipality' },
  { label: 'A Martno Pri Litiji Municipality', value: 'A Martno Pri Litiji Municipality' },
  { label: 'A Oaatanj Municipality', value: 'A Oaatanj Municipality' },
  { label: 'A Tip Municipality', value: 'A Tip Municipality' },
  { label: 'A Tore Municipality', value: 'A Tore Municipality' },
  { label: 'A Uto Orizari Municipality', value: 'A Uto Orizari Municipality' },
  { label: 'A˜Oldaƒnea™Ti District', value: 'A˜Oldaƒnea™Ti District' },
  { label: 'A˜Tefan Vodaƒ District', value: 'A˜Tefan Vodaƒ District' },
  { label: 'A’Rgaui Municipality', value: 'A’Rgaui Municipality' },
  { label: 'A»Abbar', value: 'A»Abbar' },
  { label: 'A»Ebbuaa Gozo', value: 'A»Ebbuaa Gozo' },
  { label: 'A»Ebbuaa Malta', value: 'A»Ebbuaa Malta' },
  { label: 'A»Ejtun', value: 'A»Ejtun' },
  { label: 'A»Eua', value: 'A»Eua' },
  { label: 'A»Urrieq', value: 'A»Urrieq' },
  { label: 'A°Stanbul', value: 'A°Stanbul' },
  { label: 'A°Zmir', value: 'A°Zmir' },
  { label: 'AA»“Ng Nai', value: 'AA»“Ng Nai' },
  { label: 'AA»“Ng Thsap', value: 'AA»“Ng Thsap' },
  { label: 'AAu¯K Lau¯K', value: 'AAu¯K Lau¯K' },
  { label: 'AAu¯K Nsong', value: 'AAu¯K Nsong' },
  { label: 'AIa»‡N Bisen', value: 'AIa»‡N Bisen' },
  { label: 'AS  Nauµng', value: 'AS  Nauµng' },
  { label: 'ASodau Voivodeship', value: 'ASodau Voivodeship' },
  { label: 'A½Abljak Municipality', value: 'A½Abljak Municipality' },
  { label: 'A½Alec Municipality', value: 'A½Alec Municipality' },
  { label: 'A½Elezniki Municipality', value: 'A½Elezniki Municipality' },
  { label: 'A½Elino Municipality', value: 'A½Elino Municipality' },
  { label: 'A½Etale Municipality', value: 'A½Etale Municipality' },
  { label: 'A½Ilina Region', value: 'A½Ilina Region' },
  { label: 'A½Iri Municipality', value: 'A½Iri Municipality' },
  { label: 'A½Irovnica Municipality', value: 'A½Irovnica Municipality' },
  { label: 'A½Ua¾Emberk Municipality', value: 'A½Ua¾Emberk Municipality' },
  { label: 'AAna', value: 'AAna' },
  { label: 'Aargau', value: 'Aargau' },
  { label: 'Aauran', value: 'Aauran' },
  { label: 'Abia', value: 'Abia' },
  { label: 'Abidjan', value: 'Abidjan' },
  { label: 'Abkhazia', value: 'Abkhazia' },
  { label: 'Abra', value: 'Abra' },
  { label: 'Abruzzo', value: 'Abruzzo' },
  { label: 'Absheron District', value: 'Absheron District' },
  { label: 'Abu Dhabi Emirate', value: 'Abu Dhabi Emirate' },
  { label: 'Abuja Federal Capital Territory', value: 'Abuja Federal Capital Territory' },
  { label: 'Abyan', value: 'Abyan' },
  { label: 'Aceh', value: 'Aceh' },
  { label: 'Acquaviva', value: 'Acquaviva' },
  { label: 'Acre', value: 'Acre' },
  { label: 'Ad Dakhiliyah', value: 'Ad Dakhiliyah' },
  { label: 'Ad Dhahirah', value: 'Ad Dhahirah' },
  { label: 'Adamawa', value: 'Adamawa' },
  { label: 'Adan', value: 'Adan' },
  { label: 'Adana', value: 'Adana' },
  { label: 'Adanyaman', value: 'Adanyaman' },
  { label: 'Addis Ababa', value: 'Addis Ababa' },
  { label: 'Addu Atoll', value: 'Addu Atoll' },
  { label: 'Adjara', value: 'Adjara' },
  { label: 'Adjuntas', value: 'Adjuntas' },
  { label: 'Adrar', value: 'Adrar' },
  { label: 'Afar Region', value: 'Afar Region' },
  { label: 'Afyonkarahisar', value: 'Afyonkarahisar' },
  { label: 'Agadez Region', value: 'Agadez Region' },
  { label: 'Agalega Islands', value: 'Agalega Islands' },
  { label: 'Agdam District', value: 'Agdam District' },
  { label: 'Agdash District', value: 'Agdash District' },
  { label: 'Agder', value: 'Agder' },
  { label: 'Aghjabadi District', value: 'Aghjabadi District' },
  { label: 'Aglona Municipality', value: 'Aglona Municipality' },
  { label: 'Agstafa District', value: 'Agstafa District' },
  { label: 'Agsu District', value: 'Agsu District' },
  { label: 'Aguada', value: 'Aguada' },
  { label: 'Aguadilla', value: 'Aguadilla' },
  { label: 'Aguas Buenas', value: 'Aguas Buenas' },
  { label: 'Aguascalientes', value: 'Aguascalientes' },
  { label: 'Agusan Del Norte', value: 'Agusan Del Norte' },
  { label: 'Agusan Del Sur', value: 'Agusan Del Sur' },
  { label: 'Ahafo', value: 'Ahafo' },
  { label: 'Ahal Region', value: 'Ahal Region' },
  { label: 'Ahuachapsan Department', value: 'Ahuachapsan Department' },
  { label: 'Aibonito', value: 'Aibonito' },
  { label: 'Aichi Prefecture', value: 'Aichi Prefecture' },
  { label: 'Aiga-I-Le-Tai', value: 'Aiga-I-Le-Tai' },
  { label: 'Aileu Municipality', value: 'Aileu Municipality' },
  { label: 'Aimeliik', value: 'Aimeliik' },
  { label: 'Ainaro Municipality', value: 'Ainaro Municipality' },
  { label: 'Airai', value: 'Airai' },
  { label: 'Aissen Del General Carlos Ibasnez Del Campo', value: 'Aissen Del General Carlos Ibasnez Del Campo' },
  { label: 'Aiwo District', value: 'Aiwo District' },
  { label: 'Aizkraukle Municipality', value: 'Aizkraukle Municipality' },
  { label: 'Aizpute Municipality', value: 'Aizpute Municipality' },
  { label: 'AjdovaaaIna Municipality', value: 'AjdovaaaIna Municipality' },
  { label: 'Ajloun', value: 'Ajloun' },
  { label: 'Ajman Emirate', value: 'Ajman Emirate' },
  { label: 'Akita Prefecture', value: 'Akita Prefecture' },
  { label: 'Akkar', value: 'Akkar' },
  { label: 'Akmola Region', value: 'Akmola Region' },
  { label: 'Aknaeste Municipality', value: 'Aknaeste Municipality' },
  { label: 'Aksaray', value: 'Aksaray' },
  { label: 'Aktobe Region', value: 'Aktobe Region' },
  { label: 'Akwa Ibom', value: 'Akwa Ibom' },
  { label: 'Al Ahmadi', value: 'Al Ahmadi' },
  { label: 'Al Anbar', value: 'Al Anbar' },
  { label: 'Al Bahah', value: 'Al Bahah' },
  { label: 'Al Batinah North', value: 'Al Batinah North' },
  { label: 'Al Batinah Region', value: 'Al Batinah Region' },
  { label: 'Al Bayda', value: 'Al Bayda' },
  { label: 'Al Buraimi', value: 'Al Buraimi' },
  { label: 'Al Farwaniyah', value: 'Al Farwaniyah' },
  { label: 'Al Hudaydah', value: 'Al Hudaydah' },
  { label: 'Al Jahra', value: 'Al Jahra' },
  { label: 'Al Jawf', value: 'Al Jawf' },
  { label: 'Al Jazirah', value: 'Al Jazirah' },
  { label: 'Al Khor', value: 'Al Khor' },
  { label: 'Al Madinah', value: 'Al Madinah' },
  { label: 'Al Mahrah', value: 'Al Mahrah' },
  { label: 'Al Mahwit', value: 'Al Mahwit' },
  { label: 'Al Muthanna', value: 'Al Muthanna' },
  { label: 'Al Qadarif', value: 'Al Qadarif' },
  { label: 'Al Rayyan Municipality', value: 'Al Rayyan Municipality' },
  { label: 'Al Wahat District', value: 'Al Wahat District' },
  { label: 'Al Wakrah', value: 'Al Wakrah' },
  { label: 'Al Wusta', value: 'Al Wusta' },
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alaeksne Municipality', value: 'Alaeksne Municipality' },
  { label: 'Alagoas', value: 'Alagoas' },
  { label: 'Alajuela Province', value: 'Alajuela Province' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Alba', value: 'Alba' },
  { label: 'Albacete', value: 'Albacete' },
  { label: 'Albay', value: 'Albay' },
  { label: 'Alberta', value: 'Alberta' },
  { label: 'Alborz', value: 'Alborz' },
  { label: 'Aleppo', value: 'Aleppo' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Algiers', value: 'Algiers' },
  { label: 'Al-Hasakah', value: 'Al-Hasakah' },
  { label: 'Ali Sabieh Region', value: 'Ali Sabieh Region' },
  { label: 'Alibori Department', value: 'Alibori Department' },
  { label: 'Alicante', value: 'Alicante' },
  { label: 'Almaty', value: 'Almaty' },
  { label: 'Almaty Region', value: 'Almaty Region' },
  { label: 'Almeria', value: 'Almeria' },
  { label: 'Aloja Municipality', value: 'Aloja Municipality' },
  { label: 'Al-QaDisiyyah', value: 'Al-QaDisiyyah' },
  { label: 'Al-Qassim', value: 'Al-Qassim' },
  { label: 'Al-Raqqah', value: 'Al-Raqqah' },
  { label: 'Al-Shahaniya', value: 'Al-Shahaniya' },
  { label: 'Alsunga Municipality', value: 'Alsunga Municipality' },
  { label: 'Alta Verapaz Department', value: 'Alta Verapaz Department' },
  { label: 'Altai Krai', value: 'Altai Krai' },
  { label: 'Altai Republic', value: 'Altai Republic' },
  { label: 'Alto Paraguay Department', value: 'Alto Paraguay Department' },
  { label: 'Alto Paransa Department', value: 'Alto Paransa Department' },
  { label: 'Alytus City Municipality', value: 'Alytus City Municipality' },
  { label: 'Amambay Department', value: 'Amambay Department' },
  { label: 'Amapsa', value: 'Amapsa' },
  { label: 'Amasya', value: 'Amasya' },
  { label: 'Amazonas', value: 'Amazonas' },
  { label: 'Amhara Region', value: 'Amhara Region' },
  { label: 'Amman', value: 'Amman' },
  { label: 'Amnat Charoen', value: 'Amnat Charoen' },
  { label: 'Amran', value: 'Amran' },
  { label: 'Amrun', value: 'Amrun' },
  { label: 'Amur Oblast', value: 'Amur Oblast' },
  { label: 'An Giang', value: 'An Giang' },
  { label: 'Anabar District', value: 'Anabar District' },
  { label: 'Anambra', value: 'Anambra' },
  { label: 'Andaman And Nicobar Islands', value: 'Andaman And Nicobar Islands' },
  { label: 'Andhra Pradesh', value: 'Andhra Pradesh' },
  { label: 'Andijan Region', value: 'Andijan Region' },
  { label: 'Andorra La Vella', value: 'Andorra La Vella' },
  { label: 'Andrijevica Municipality', value: 'Andrijevica Municipality' },
  { label: 'Anenii Noi District', value: 'Anenii Noi District' },
  { label: 'Ang Thong', value: 'Ang Thong' },
  { label: 'Angaur', value: 'Angaur' },
  { label: 'Anhui', value: 'Anhui' },
  { label: 'Anjouan', value: 'Anjouan' },
  { label: 'Ankara', value: 'Ankara' },
  { label: 'Ankaran Municipality', value: 'Ankaran Municipality' },
  { label: 'Annaba', value: 'Annaba' },
  { label: 'Annobson Province', value: 'Annobson Province' },
  { label: 'Anse Boileau', value: 'Anse Boileau' },
  { label: 'Anse La Raye Quarter', value: 'Anse La Raye Quarter' },
  { label: 'Anse Royale', value: 'Anse Royale' },
  { label: 'Anseba Region', value: 'Anseba Region' },
  { label: 'Antalya', value: 'Antalya' },
  { label: 'Antioquia', value: 'Antioquia' },
  { label: 'Antique', value: 'Antique' },
  { label: 'Antofagasta', value: 'Antofagasta' },
  { label: 'Antsiranana Province', value: 'Antsiranana Province' },
  { label: 'Anzosategui', value: 'Anzosategui' },
  { label: 'Aœaaaka Municipality', value: 'Aœaaaka Municipality' },
  { label: 'Aœair Municipality', value: 'Aœair Municipality' },
  { label: 'Aœeaainovo-Obleaaevo Municipality', value: 'Aœeaainovo-Obleaaevo Municipality' },
  { label: 'Aoegums Municipality', value: 'Aoegums Municipality' },
  { label: 'Aœita Prefecture', value: 'Aœita Prefecture' },
  { label: 'Aoekava Municipality', value: 'Aoekava Municipality' },
  { label: 'Aœrenaaovci Municipality', value: 'Aœrenaaovci Municipality' },
  { label: 'Aœrna Na Koroaakem Municipality', value: 'Aœrna Na Koroaakem Municipality' },
  { label: 'Aœrnomelj Municipality', value: 'Aœrnomelj Municipality' },
  { label: 'Aœsaka Prefecture', value: 'Aœsaka Prefecture' },
  { label: 'AœuaEr-Sandevo Municipality', value: 'AœuaEr-Sandevo Municipality' },
  { label: 'Aomori Prefecture', value: 'Aomori Prefecture' },
  { label: 'Aosta Valley', value: 'Aosta Valley' },
  { label: 'Ape Municipality', value: 'Ape Municipality' },
  { label: 'Appenzell Ausserrhoden', value: 'Appenzell Ausserrhoden' },
  { label: 'Appenzell Innerrhoden', value: 'Appenzell Innerrhoden' },
  { label: 'Apulia', value: 'Apulia' },
  { label: 'Apure', value: 'Apure' },
  { label: 'Apurs­Mac', value: 'Apurs­Mac' },
  { label: 'Aqaba', value: 'Aqaba' },
  { label: 'AraaInovo Municipality', value: 'AraaInovo Municipality' },
  { label: 'Araba', value: 'Araba' },
  { label: 'Arad County', value: 'Arad County' },
  { label: 'Aragatsotn Region', value: 'Aragatsotn Region' },
  { label: 'Aragua', value: 'Aragua' },
  { label: 'Ararat Province', value: 'Ararat Province' },
  { label: 'Arauca', value: 'Arauca' },
  { label: 'Archipiselago De San Andrses, Providencia Y Santa Catalina', value: 'Archipiselago De San Andrses, Providencia Y Santa Catalina' },
  { label: 'Ardabil', value: 'Ardabil' },
  { label: 'Ardahan', value: 'Ardahan' },
  { label: 'Arecibo', value: 'Arecibo' },
  { label: 'Arequipa', value: 'Arequipa' },
  { label: 'Arges', value: 'Arges' },
  { label: 'Ariana', value: 'Ariana' },
  { label: 'Arica Y Parinacota', value: 'Arica Y Parinacota' },
  { label: 'Arima', value: 'Arima' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'Arkhangai Province', value: 'Arkhangai Province' },
  { label: 'Arkhangelsk', value: 'Arkhangelsk' },
  { label: 'Armavir Region', value: 'Armavir Region' },
  { label: 'Arroyo', value: 'Arroyo' },
  { label: 'Arta Region', value: 'Arta Region' },
  { label: 'Artemisa Province', value: 'Artemisa Province' },
  { label: 'Artibonite', value: 'Artibonite' },
  { label: 'Artigas', value: 'Artigas' },
  { label: 'Artvin', value: 'Artvin' },
  { label: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
  { label: 'Arusha', value: 'Arusha' },
  { label: 'As¯N Defla', value: 'As¯N Defla' },
  { label: 'As¯N Tsemouchent', value: 'As¯N Tsemouchent' },
  { label: 'As§Ores', value: 'As§Ores' },
  { label: 'Ash Sharqiyah Region', value: 'Ash Sharqiyah Region' },
  { label: 'Ashanti', value: 'Ashanti' },
  { label: 'Ashgabat', value: 'Ashgabat' },
  { label: 'Asir', value: 'Asir' },
  { label: 'Asnasco', value: 'Asnasco' },
  { label: 'Assaba', value: 'Assaba' },
  { label: 'Assam', value: 'Assam' },
  { label: 'Assa-Zag (Eh-Partial)', value: 'Assa-Zag (Eh-Partial)' },
  { label: 'As-Suwayda', value: 'As-Suwayda' },
  { label: 'Astara District', value: 'Astara District' },
  { label: 'Astrakhan Oblast', value: 'Astrakhan Oblast' },
  { label: 'Asturias', value: 'Asturias' },
  { label: 'Aswan', value: 'Aswan' },
  { label: 'Aswia™Tokrzyskie Voivodeship', value: 'Aswia™Tokrzyskie Voivodeship' },
  { label: 'Asyut', value: 'Asyut' },
  { label: 'Atacama', value: 'Atacama' },
  { label: 'Atakora Department', value: 'Atakora Department' },
  { label: 'Atlantique Department', value: 'Atlantique Department' },
  { label: 'Atlsantico', value: 'Atlsantico' },
  { label: 'Atlsantida Department', value: 'Atlsantida Department' },
  { label: 'Attapeu Province', value: 'Attapeu Province' },
  { label: 'Attard', value: 'Attard' },
  { label: 'Attica Region', value: 'Attica Region' },
  { label: 'Atua', value: 'Atua' },
  { label: 'Atyrau Region', value: 'Atyrau Region' },
  { label: 'Auce Municipality', value: 'Auce Municipality' },
  { label: 'Auckland Region', value: 'Auckland Region' },
  { label: 'Australian Capital Territory', value: 'Australian Capital Territory' },
  { label: 'Autonomous Region In Muslim Mindanao', value: 'Autonomous Region In Muslim Mindanao' },
  { label: 'Autonomous Republic Of Crimea', value: 'Autonomous Republic Of Crimea' },
  { label: 'Auvergne-Rhsone-Alpes', value: 'Auvergne-Rhsone-Alpes' },
  { label: 'Aveiro', value: 'Aveiro' },
  { label: 'Ayacucho', value: 'Ayacucho' },
  { label: 'Aydann', value: 'Aydann' },
  { label: 'Ayeyarwady Region', value: 'Ayeyarwady Region' },
  { label: 'Azad Kashmir', value: 'Azad Kashmir' },
  { label: 'Azanlanurfa', value: 'Azanlanurfa' },
  { label: 'Azanrnak', value: 'Azanrnak' },
  { label: 'Azua Province', value: 'Azua Province' },
  { label: 'Azuay', value: 'Azuay' },
  { label: 'Baalbek-Hermel', value: 'Baalbek-Hermel' },
  { label: 'Babaete Municipality', value: 'Babaete Municipality' },
  { label: 'Babylon', value: 'Babylon' },
  { label: 'Bacaƒu County', value: 'Bacaƒu County' },
  { label: 'Badajoz', value: 'Badajoz' },
  { label: 'Badakhshan', value: 'Badakhshan' },
  { label: 'Baden-Wsurttemberg', value: 'Baden-Wsurttemberg' },
  { label: 'Badghis', value: 'Badghis' },
  { label: 'Bafatsa', value: 'Bafatsa' },
  { label: 'Baƒla›I Municipality', value: 'Baƒla›I Municipality' },
  { label: 'Baghdad', value: 'Baghdad' },
  { label: 'Baghlan', value: 'Baghlan' },
  { label: 'Bago', value: 'Bago' },
  { label: 'Bahia', value: 'Bahia' },
  { label: 'Bahr El Gazel', value: 'Bahr El Gazel' },
  { label: 'Baikonur', value: 'Baikonur' },
  { label: 'Baiti District', value: 'Baiti District' },
  { label: 'Baja California', value: 'Baja California' },
  { label: 'Baja California Sur', value: 'Baja California Sur' },
  { label: 'Baja Verapaz Department', value: 'Baja Verapaz Department' },
  { label: 'Bakool', value: 'Bakool' },
  { label: 'Baku', value: 'Baku' },
  { label: 'Balakan District', value: 'Balakan District' },
  { label: 'Balankesir', value: 'Balankesir' },
  { label: 'Baldone Municipality', value: 'Baldone Municipality' },
  { label: 'Bali', value: 'Bali' },
  { label: 'Balkan Region', value: 'Balkan Region' },
  { label: 'Balkh', value: 'Balkh' },
  { label: 'Balochistan', value: 'Balochistan' },
  { label: 'Balqa', value: 'Balqa' },
  { label: 'Baltinava Municipality', value: 'Baltinava Municipality' },
  { label: 'Balvi Municipality', value: 'Balvi Municipality' },
  { label: 'Balzan', value: 'Balzan' },
  { label: 'Balzers', value: 'Balzers' },
  { label: 'Bamako', value: 'Bamako' },
  { label: 'Bamingui-Bangoran Prefecture', value: 'Bamingui-Bangoran Prefecture' },
  { label: 'Bamyan', value: 'Bamyan' },
  { label: 'Banaadir', value: 'Banaadir' },
  { label: 'Bangkok', value: 'Bangkok' },
  { label: 'Bangui', value: 'Bangui' },
  { label: 'Banjul', value: 'Banjul' },
  { label: 'Bansksa Bystrica Region', value: 'Bansksa Bystrica Region' },
  { label: 'Banteay Meanchey', value: 'Banteay Meanchey' },
  { label: 'Banten', value: 'Banten' },
  { label: 'Baoruco Province', value: 'Baoruco Province' },
  { label: 'Bar Municipality', value: 'Bar Municipality' },
  { label: 'Barahona Province', value: 'Barahona Province' },
  { label: 'Baranya', value: 'Baranya' },
  { label: 'Barbuda', value: 'Barbuda' },
  { label: 'Barcelona', value: 'Barcelona' },
  { label: 'Barceloneta', value: 'Barceloneta' },
  { label: 'Barda District', value: 'Barda District' },
  { label: 'Bari', value: 'Bari' },
  { label: 'Barima-Waini', value: 'Barima-Waini' },
  { label: 'Barinas', value: 'Barinas' },
  { label: 'Baringo', value: 'Baringo' },
  { label: 'Barisal District', value: 'Barisal District' },
  { label: 'Barletta-Andria-Trani', value: 'Barletta-Andria-Trani' },
  { label: 'Barranquitas', value: 'Barranquitas' },
  { label: 'Bartann', value: 'Bartann' },
  { label: 'Basarabeasca District', value: 'Basarabeasca District' },
  { label: 'Basel-Land', value: 'Basel-Land' },
  { label: 'Basel-Stadt', value: 'Basel-Stadt' },
  { label: 'Basilicata', value: 'Basilicata' },
  { label: 'Basra', value: 'Basra' },
  { label: 'Bas-Sassandra District', value: 'Bas-Sassandra District' },
  { label: 'Basse-Kotto Prefecture', value: 'Basse-Kotto Prefecture' },
  { label: 'Bas-Uselse', value: 'Bas-Uselse' },
  { label: 'Bataan', value: 'Bataan' },
  { label: 'Batanes', value: 'Batanes' },
  { label: 'Batha', value: 'Batha' },
  { label: 'Batken Region', value: 'Batken Region' },
  { label: 'Batman', value: 'Batman' },
  { label: 'Batna', value: 'Batna' },
  { label: 'Battambang', value: 'Battambang' },
  { label: 'Bau¯C Giang', value: 'Bau¯C Giang' },
  { label: 'Bau¯C Kauan', value: 'Bau¯C Kauan' },
  { label: 'Bau¯C Ninh', value: 'Bau¯C Ninh' },
  { label: 'Bau¿N Tre', value: 'Bau¿N Tre' },
  { label: 'Bauac Liseu', value: 'Bauac Liseu' },
  { label: 'Baucau Municipality', value: 'Baucau Municipality' },
  { label: 'Bauchi', value: 'Bauchi' },
  { label: 'Bauska Municipality', value: 'Bauska Municipality' },
  { label: 'Bavaria', value: 'Bavaria' },
  { label: 'Bay', value: 'Bay' },
  { label: 'Bay Islands Department', value: 'Bay Islands Department' },
  { label: 'Bay Of Plenty Region', value: 'Bay Of Plenty Region' },
  { label: 'Bayamson', value: 'Bayamson' },
  { label: 'Bayankhongor Province', value: 'Bayankhongor Province' },
  { label: 'Bayan-S–Lgii Province', value: 'Bayan-S–Lgii Province' },
  { label: 'Bayburt', value: 'Bayburt' },
  { label: 'Bayelsa', value: 'Bayelsa' },
  { label: 'Beau Vallon', value: 'Beau Vallon' },
  { label: 'Beheira', value: 'Beheira' },
  { label: 'Beijing', value: 'Beijing' },
  { label: 'Beirut', value: 'Beirut' },
  { label: 'Beja', value: 'Beja' },
  { label: 'Bel Ombre', value: 'Bel Ombre' },
  { label: 'Belait District', value: 'Belait District' },
  { label: 'Belgorod Oblast', value: 'Belgorod Oblast' },
  { label: 'Belize District', value: 'Belize District' },
  { label: 'Beltinci Municipality', value: 'Beltinci Municipality' },
  { label: 'Ben Arous', value: 'Ben Arous' },
  { label: 'Bender Municipality', value: 'Bender Municipality' },
  { label: 'Benedikt Municipality', value: 'Benedikt Municipality' },
  { label: 'Benghazi', value: 'Benghazi' },
  { label: 'Bengkulu', value: 'Bengkulu' },
  { label: 'Bengo Province', value: 'Bengo Province' },
  { label: 'Benguela Province', value: 'Benguela Province' },
  { label: 'Benguet', value: 'Benguet' },
  { label: 'Beni Department', value: 'Beni Department' },
  { label: 'Beni Suef', value: 'Beni Suef' },
  { label: 'Benishangul-Gumuz Region', value: 'Benishangul-Gumuz Region' },
  { label: 'Benue', value: 'Benue' },
  { label: 'Beqaa', value: 'Beqaa' },
  { label: 'Berane Municipality', value: 'Berane Municipality' },
  { label: 'Berat District', value: 'Berat District' },
  { label: 'Berea District', value: 'Berea District' },
  { label: 'Berlin', value: 'Berlin' },
  { label: 'Bern', value: 'Bern' },
  { label: 'Berovo Municipality', value: 'Berovo Municipality' },
  { label: 'Beveraena Municipality', value: 'Beveraena Municipality' },
  { label: 'Beylagan District', value: 'Beylagan District' },
  { label: 'Bihar', value: 'Bihar' },
  { label: 'Bihor County', value: 'Bihor County' },
  { label: 'Bijelo Polje Municipality', value: 'Bijelo Polje Municipality' },
  { label: 'Bilasuvar District', value: 'Bilasuvar District' },
  { label: 'Bilecik', value: 'Bilecik' },
  { label: 'Bimini', value: 'Bimini' },
  { label: 'Bingsol', value: 'Bingsol' },
  { label: 'Biobs­O', value: 'Biobs­O' },
  { label: 'Bioko Norte Province', value: 'Bioko Norte Province' },
  { label: 'Bioko Sur Province', value: 'Bioko Sur Province' },
  { label: 'Biombo Region', value: 'Biombo Region' },
  { label: 'Birauebbuaaa', value: 'Birauebbuaaa' },
  { label: 'Birgu', value: 'Birgu' },
  { label: 'Birkirkara', value: 'Birkirkara' },
  { label: 'Bise Province', value: 'Bise Province' },
  { label: 'Bishkek', value: 'Bishkek' },
  { label: 'Biskra', value: 'Biskra' },
  { label: 'Bistria›A-Naƒsaƒud County', value: 'Bistria›A-Naƒsaƒud County' },
  { label: 'Bistrica Ob Sotli Municipality', value: 'Bistrica Ob Sotli Municipality' },
  { label: 'Bitlis', value: 'Bitlis' },
  { label: 'Bitola Municipality', value: 'Bitola Municipality' },
  { label: 'Bizerte', value: 'Bizerte' },
  { label: 'Bizkaia', value: 'Bizkaia' },
  { label: 'Bjelovar-Bilogora', value: 'Bjelovar-Bilogora' },
  { label: 'Black River', value: 'Black River' },
  { label: 'Blagoevgrad Province', value: 'Blagoevgrad Province' },
  { label: 'Bled Municipality', value: 'Bled Municipality' },
  { label: 'Blekinge County', value: 'Blekinge County' },
  { label: 'Blida', value: 'Blida' },
  { label: 'Bloke Municipality', value: 'Bloke Municipality' },
  { label: 'Blue Nile', value: 'Blue Nile' },
  { label: 'Boa Vista', value: 'Boa Vista' },
  { label: 'Boaco', value: 'Boaco' },
  { label: 'Bobonaro Municipality', value: 'Bobonaro Municipality' },
  { label: 'Bocas Del Toro Province', value: 'Bocas Del Toro Province' },
  { label: 'Bogdanci Municipality', value: 'Bogdanci Municipality' },
  { label: 'Bogotsa D.C.', value: 'Bogotsa D.C.' },
  { label: 'Bogovinje Municipality', value: 'Bogovinje Municipality' },
  { label: 'Bohinj Municipality', value: 'Bohinj Municipality' },
  { label: 'Bohol', value: 'Bohol' },
  { label: 'Bokeo Province', value: 'Bokeo Province' },
  { label: 'Bokse Region', value: 'Bokse Region' },
  { label: 'Bolama Region', value: 'Bolama Region' },
  { label: 'Bolikhamsai Province', value: 'Bolikhamsai Province' },
  { label: 'Bols­Var', value: 'Bols­Var' },
  { label: 'Bolu', value: 'Bolu' },
  { label: 'Bomet', value: 'Bomet' },
  { label: 'Bomi County', value: 'Bomi County' },
  { label: 'Bonaire', value: 'Bonaire' },
  { label: 'Bong County', value: 'Bong County' },
  { label: 'Bono', value: 'Bono' },
  { label: 'Bono East', value: 'Bono East' },
  { label: 'Boquerson Department', value: 'Boquerson Department' },
  { label: 'Bordj Bou Arrseridj', value: 'Bordj Bou Arrseridj' },
  { label: 'Borgo Maggiore', value: 'Borgo Maggiore' },
  { label: 'Borgou Department', value: 'Borgou Department' },
  { label: 'Borkou', value: 'Borkou' },
  { label: 'Borno', value: 'Borno' },
  { label: 'Borovnica Municipality', value: 'Borovnica Municipality' },
  { label: 'Borsod-Abasuj-Zemplsen', value: 'Borsod-Abasuj-Zemplsen' },
  { label: 'Bosilovo Municipality', value: 'Bosilovo Municipality' },
  { label: 'Botoa™Ani County', value: 'Botoa™Ani County' },
  { label: 'Boucle Du Mouhoun Region', value: 'Boucle Du Mouhoun Region' },
  { label: 'Bouenza Department', value: 'Bouenza Department' },
  { label: 'Bougainville', value: 'Bougainville' },
  { label: 'Boumerdses', value: 'Boumerdses' },
  { label: 'Bourgogne-Franche-Comtse', value: 'Bourgogne-Franche-Comtse' },
  { label: 'Bous¯Ra', value: 'Bous¯Ra' },
  { label: 'Bovec Municipality', value: 'Bovec Municipality' },
  { label: 'Boyacsa', value: 'Boyacsa' },
  { label: 'BraKo District', value: 'BraKo District' },
  { label: 'Braa™Ov County', value: 'Braa™Ov County' },
  { label: 'Braga', value: 'Braga' },
  { label: 'Bragans§A', value: 'Bragans§A' },
  { label: 'Braila', value: 'Braila' },
  { label: 'Brakna', value: 'Brakna' },
  { label: 'Brandenburg', value: 'Brandenburg' },
  { label: 'BraslovaE Municipality', value: 'BraslovaE Municipality' },
  { label: 'Bratislava Region', value: 'Bratislava Region' },
  { label: 'Brava', value: 'Brava' },
  { label: 'Brazzaville', value: 'Brazzaville' },
  { label: 'Brda Municipality', value: 'Brda Municipality' },
  { label: 'Brea¾Ice Municipality', value: 'Brea¾Ice Municipality' },
  { label: 'Bremen', value: 'Bremen' },
  { label: 'Brest Region', value: 'Brest Region' },
  { label: 'Bretagne', value: 'Bretagne' },
  { label: 'Brezovica Municipality', value: 'Brezovica Municipality' },
  { label: 'Briceni District', value: 'Briceni District' },
  { label: 'British Columbia', value: 'British Columbia' },
  { label: 'Broca“Ni Municipality', value: 'Broca“Ni Municipality' },
  { label: 'Brod-Posavina', value: 'Brod-Posavina' },
  { label: 'Brokopondo District', value: 'Brokopondo District' },
  { label: 'Brunei-Muara District', value: 'Brunei-Muara District' },
  { label: 'Brussels-Capital Region', value: 'Brussels-Capital Region' },
  { label: 'Brvenica Municipality', value: 'Brvenica Municipality' },
  { label: 'Bryansk Oblast', value: 'Bryansk Oblast' },
  { label: 'Bs  Ra»‹A-Vaeng Ts U', value: 'Bs  Ra»‹A-Vaeng Ts U' },
  { label: 'Bs¬Nh AA»‹Nh', value: 'Bs¬Nh AA»‹Nh' },
  { label: 'Bs¬Nh Da°Aang', value: 'Bs¬Nh Da°Aang' },
  { label: 'Bs¬Nh Pha°A»›C', value: 'Bs¬Nh Pha°A»›C' },
  { label: 'Bs¬Nh Thuau­N', value: 'Bs¬Nh Thuau­N' },
  { label: 'Bsacs-Kiskun', value: 'Bsacs-Kiskun' },
  { label: 'Bsechar', value: 'Bsechar' },
  { label: 'Bseja', value: 'Bseja' },
  { label: 'Bsejas¯A', value: 'Bsejas¯A' },
  { label: 'Bsekses', value: 'Bsekses' },
  { label: 'Bubanza Province', value: 'Bubanza Province' },
  { label: 'Bucharest', value: 'Bucharest' },
  { label: 'Budapest', value: 'Budapest' },
  { label: 'Budva Municipality', value: 'Budva Municipality' },
  { label: 'Bueng Kan', value: 'Bueng Kan' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Bujumbura Mairie Province', value: 'Bujumbura Mairie Province' },
  { label: 'Bukhara Region', value: 'Bukhara Region' },
  { label: 'Bukidnon', value: 'Bukidnon' },
  { label: 'Bulacan', value: 'Bulacan' },
  { label: 'Bulawayo Province', value: 'Bulawayo Province' },
  { label: 'Bulgan Province', value: 'Bulgan Province' },
  { label: 'Bumthang District', value: 'Bumthang District' },
  { label: 'Bungoma', value: 'Bungoma' },
  { label: 'Burdur', value: 'Burdur' },
  { label: 'Burgas Province', value: 'Burgas Province' },
  { label: 'Burgenland', value: 'Burgenland' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Buri Ram', value: 'Buri Ram' },
  { label: 'Bursa', value: 'Bursa' },
  { label: 'Bururi Province', value: 'Bururi Province' },
  { label: 'Busan', value: 'Busan' },
  { label: 'Bushehr', value: 'Bushehr' },
  { label: 'Busia', value: 'Busia' },
  { label: 'Butel Municipality', value: 'Butel Municipality' },
  { label: 'Butha-Buthe District', value: 'Butha-Buthe District' },
  { label: 'Buzaƒu County', value: 'Buzaƒu County' },
  { label: 'Ca“Sis Municipality', value: 'Ca“Sis Municipality' },
  { label: 'Caaguazsu', value: 'Caaguazsu' },
  { label: 'Caazapsa', value: 'Caazapsa' },
  { label: 'Cabasnas Department', value: 'Cabasnas Department' },
  { label: 'Cabinda Province', value: 'Cabinda Province' },
  { label: 'Cabo Delgado Province', value: 'Cabo Delgado Province' },
  { label: 'Cabo Rojo', value: 'Cabo Rojo' },
  { label: 'Caceres', value: 'Caceres' },
  { label: 'Cacheu Region', value: 'Cacheu Region' },
  { label: 'Caƒlaƒraa™I County', value: 'Caƒlaƒraa™I County' },
  { label: 'Caƒlaƒraa™I District', value: 'Caƒlaƒraa™I District' },
  { label: 'Caƒua™Eni District', value: 'Caƒua™Eni District' },
  { label: 'Cagayan', value: 'Cagayan' },
  { label: 'Cagayan Valley', value: 'Cagayan Valley' },
  { label: 'Caguas', value: 'Caguas' },
  { label: 'Cahul District', value: 'Cahul District' },
  { label: 'Cairo', value: 'Cairo' },
  { label: 'Cajamarca', value: 'Cajamarca' },
  { label: 'Calabria', value: 'Calabria' },
  { label: 'Caldas', value: 'Caldas' },
  { label: 'California', value: 'California' },
  { label: 'Callao', value: 'Callao' },
  { label: 'Camagsuey Province', value: 'Camagsuey Province' },
  { label: 'Camarines Norte', value: 'Camarines Norte' },
  { label: 'Campania', value: 'Campania' },
  { label: 'Campeche', value: 'Campeche' },
  { label: 'Camuy', value: 'Camuy' },
  { label: 'Canarias', value: 'Canarias' },
  { label: 'Canaries', value: 'Canaries' },
  { label: 'Canelones', value: 'Canelones' },
  { label: 'Canillo', value: 'Canillo' },
  { label: 'Canindeysu', value: 'Canindeysu' },
  { label: 'Cankova Municipality', value: 'Cankova Municipality' },
  { label: 'Cankuzo Province', value: 'Cankuzo Province' },
  { label: 'Cansovanas', value: 'Cansovanas' },
  { label: 'Cantabria', value: 'Cantabria' },
  { label: 'Cantemir District', value: 'Cantemir District' },
  { label: 'Canterbury Region', value: 'Canterbury Region' },
  { label: 'Canton Of Capellen', value: 'Canton Of Capellen' },
  { label: 'Canton Of Clervaux', value: 'Canton Of Clervaux' },
  { label: 'Canton Of Diekirch', value: 'Canton Of Diekirch' },
  { label: 'Canton Of Echternach', value: 'Canton Of Echternach' },
  { label: 'Canton Of Esch-Sur-Alzette', value: 'Canton Of Esch-Sur-Alzette' },
  { label: 'Canton Of Grevenmacher', value: 'Canton Of Grevenmacher' },
  { label: 'Canton Of Luxembourg', value: 'Canton Of Luxembourg' },
  { label: 'Canton Of Mersch', value: 'Canton Of Mersch' },
  { label: 'Canton Of Redange', value: 'Canton Of Redange' },
  { label: 'Canton Of Remich', value: 'Canton Of Remich' },
  { label: 'Canton Of Vianden', value: 'Canton Of Vianden' },
  { label: 'Canton Of Wiltz', value: 'Canton Of Wiltz' },
  { label: 'Cao Baunng', value: 'Cao Baunng' },
  { label: 'Capital', value: 'Capital' },
  { label: 'Capital Region', value: 'Capital Region' },
  { label: 'Capital Region Of Denmark', value: 'Capital Region Of Denmark' },
  { label: 'Caquetsa', value: 'Caquetsa' },
  { label: 'Caraa™-Severin County', value: 'Caraa™-Severin County' },
  { label: 'Carabobo', value: 'Carabobo' },
  { label: 'Caraga', value: 'Caraga' },
  { label: 'Carazo', value: 'Carazo' },
  { label: 'Carchi', value: 'Carchi' },
  { label: 'Carinthia', value: 'Carinthia' },
  { label: 'Carnikava Municipality', value: 'Carnikava Municipality' },
  { label: 'Carolina', value: 'Carolina' },
  { label: 'Carriacou And Petite Martinique', value: 'Carriacou And Petite Martinique' },
  { label: 'Casablanca-Settat', value: 'Casablanca-Settat' },
  { label: 'Casanare', value: 'Casanare' },
  { label: 'Cascade', value: 'Cascade' },
  { label: 'Cascades Region', value: 'Cascades Region' },
  { label: 'Casnar', value: 'Casnar' },
  { label: 'Castellson', value: 'Castellson' },
  { label: 'Castelo Branco', value: 'Castelo Branco' },
  { label: 'Castries Quarter', value: 'Castries Quarter' },
  { label: 'Cat Island', value: 'Cat Island' },
  { label: 'Catamarca', value: 'Catamarca' },
  { label: 'Catasno', value: 'Catasno' },
  { label: 'Cau§N Thaa', value: 'Cau§N Thaa' },
  { label: 'Cauca', value: 'Cauca' },
  { label: 'Cayey', value: 'Cayey' },
  { label: 'Cayo District', value: 'Cayo District' },
  { label: 'Cearsa', value: 'Cearsa' },
  { label: 'Ceiba', value: 'Ceiba' },
  { label: 'Centar A½Upa Municipality', value: 'Centar A½Upa Municipality' },
  { label: 'Central', value: 'Central' },
  { label: 'Central Abaco', value: 'Central Abaco' },
  { label: 'Central And Western', value: 'Central And Western' },
  { label: 'Central Banat District', value: 'Central Banat District' },
  { label: 'Central Darfur', value: 'Central Darfur' },
  { label: 'Central Denmark Region', value: 'Central Denmark Region' },
  { label: 'Central Department', value: 'Central Department' },
  { label: 'Central District', value: 'Central District' },
  { label: 'Central Division', value: 'Central Division' },
  { label: 'Central Finland', value: 'Central Finland' },
  { label: 'Central Greece Region', value: 'Central Greece Region' },
  { label: 'Central Luzon', value: 'Central Luzon' },
  { label: 'Central Macedonia', value: 'Central Macedonia' },
  { label: 'Central Ostrobothnia', value: 'Central Ostrobothnia' },
  { label: 'Central Province', value: 'Central Province' },
  { label: 'Central Region', value: 'Central Region' },
  { label: 'Central River Division', value: 'Central River Division' },
  { label: 'Central Singapore', value: 'Central Singapore' },
  { label: 'Centrale Region', value: 'Centrale Region' },
  { label: 'Centre', value: 'Centre' },
  { label: 'Centre-Est Region', value: 'Centre-Est Region' },
  { label: 'Centre-Nord Region', value: 'Centre-Nord Region' },
  { label: 'Centre-Ouest Region', value: 'Centre-Ouest Region' },
  { label: 'Centre-Sud Region', value: 'Centre-Sud Region' },
  { label: 'Centre-Val De Loire', value: 'Centre-Val De Loire' },
  { label: 'Centro Sur Province', value: 'Centro Sur Province' },
  { label: 'Cerklje Na Gorenjskem Municipality', value: 'Cerklje Na Gorenjskem Municipality' },
  { label: 'Cerknica Municipality', value: 'Cerknica Municipality' },
  { label: 'Cerkno Municipality', value: 'Cerkno Municipality' },
  { label: 'Cerkvenjak Municipality', value: 'Cerkvenjak Municipality' },
  { label: 'Cerro Largo', value: 'Cerro Largo' },
  { label: 'Cesar', value: 'Cesar' },
  { label: 'Cesvaine Municipality', value: 'Cesvaine Municipality' },
  { label: 'Chachoengsao', value: 'Chachoengsao' },
  { label: 'Chaco', value: 'Chaco' },
  { label: 'Chagang Province', value: 'Chagang Province' },
  { label: 'Chaguanas', value: 'Chaguanas' },
  { label: 'Chaharmahal And Bakhtiari', value: 'Chaharmahal And Bakhtiari' },
  { label: 'Chai Nat', value: 'Chai Nat' },
  { label: 'Chaiyaphum', value: 'Chaiyaphum' },
  { label: 'Chalatenango Department', value: 'Chalatenango Department' },
  { label: 'Champasak Province', value: 'Champasak Province' },
  { label: 'Chandigarh', value: 'Chandigarh' },
  { label: 'Changhua', value: 'Changhua' },
  { label: 'Chanthaburi', value: 'Chanthaburi' },
  { label: 'Chari-Baguirmi', value: 'Chari-Baguirmi' },
  { label: 'Charlotte Parish', value: 'Charlotte Parish' },
  { label: 'Chatham Islands', value: 'Chatham Islands' },
  { label: 'Chechen Republic', value: 'Chechen Republic' },
  { label: 'Chelyabinsk Oblast', value: 'Chelyabinsk Oblast' },
  { label: 'Cherkaska Oblast', value: 'Cherkaska Oblast' },
  { label: 'Chernihivska Oblast', value: 'Chernihivska Oblast' },
  { label: 'Chernivetska Oblast', value: 'Chernivetska Oblast' },
  { label: 'Chhattisgarh', value: 'Chhattisgarh' },
  { label: 'Chiang Mai', value: 'Chiang Mai' },
  { label: 'Chiang Rai', value: 'Chiang Rai' },
  { label: 'Chiapas', value: 'Chiapas' },
  { label: 'Chia™Inaƒu Municipality', value: 'Chia™Inaƒu Municipality' },
  { label: 'Chiayi', value: 'Chiayi' },
  { label: 'Chiba Prefecture', value: 'Chiba Prefecture' },
  { label: 'Chiesanuova', value: 'Chiesanuova' },
  { label: 'Chihuahua', value: 'Chihuahua' },
  { label: 'Chimaltenango Department', value: 'Chimaltenango Department' },
  { label: 'Chimborazo', value: 'Chimborazo' },
  { label: 'Chimbu Province', value: 'Chimbu Province' },
  { label: 'Chin State', value: 'Chin State' },
  { label: 'Chinandega', value: 'Chinandega' },
  { label: 'Chiquimula Department', value: 'Chiquimula Department' },
  { label: 'Chiriqus­ Province', value: 'Chiriqus­ Province' },
  { label: 'Chittagong Division', value: 'Chittagong Division' },
  { label: 'Chlef', value: 'Chlef' },
  { label: 'Chocso', value: 'Chocso' },
  { label: 'Choiseul Quarter', value: 'Choiseul Quarter' },
  { label: 'Choluteca Department', value: 'Choluteca Department' },
  { label: 'Chon Buri', value: 'Chon Buri' },
  { label: 'Chongqing', value: 'Chongqing' },
  { label: 'Chontales', value: 'Chontales' },
  { label: 'Christ Church', value: 'Christ Church' },
  { label: 'Christ Church Nichola Town Parish', value: 'Christ Church Nichola Town Parish' },
  { label: 'Chubut', value: 'Chubut' },
  { label: 'Chukha District', value: 'Chukha District' },
  { label: 'Chukotka Autonomous Okrug', value: 'Chukotka Autonomous Okrug' },
  { label: 'Chumphon', value: 'Chumphon' },
  { label: 'Chuquisaca Department', value: 'Chuquisaca Department' },
  { label: 'Chuuk State', value: 'Chuuk State' },
  { label: 'Chuvash Republic', value: 'Chuvash Republic' },
  { label: 'Chuy Region', value: 'Chuy Region' },
  { label: 'Ciales', value: 'Ciales' },
  { label: 'Cibitoke Province', value: 'Cibitoke Province' },
  { label: 'Cibla Municipality', value: 'Cibla Municipality' },
  { label: 'Cidra', value: 'Cidra' },
  { label: 'Ciego De SVila Province', value: 'Ciego De SVila Province' },
  { label: 'Cienfuegos Province', value: 'Cienfuegos Province' },
  { label: 'Cimia™Lia District', value: 'Cimia™Lia District' },
  { label: 'City Municipality Of Celje', value: 'City Municipality Of Celje' },
  { label: 'City Municipality Of Novo Mesto', value: 'City Municipality Of Novo Mesto' },
  { label: 'Ciudad De Msexico', value: 'Ciudad De Msexico' },
  { label: 'Ciudad Real', value: 'Ciudad Real' },
  { label: 'Clarendon Parish', value: 'Clarendon Parish' },
  { label: 'Cluj County', value: 'Cluj County' },
  { label: 'Coahuila De Zaragoza', value: 'Coahuila De Zaragoza' },
  { label: 'Coamo', value: 'Coamo' },
  { label: 'Cochabamba Department', value: 'Cochabamba Department' },
  { label: 'Coclse Province', value: 'Coclse Province' },
  { label: 'Coimbra', value: 'Coimbra' },
  { label: 'Cojedes', value: 'Cojedes' },
  { label: 'Colima', value: 'Colima' },
  { label: 'Collines Department', value: 'Collines Department' },
  { label: 'Colombo District', value: 'Colombo District' },
  { label: 'Colonia', value: 'Colonia' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Colson Department', value: 'Colson Department' },
  { label: 'Colson Province', value: 'Colson Province' },
  { label: 'Comayagua Department', value: 'Comayagua Department' },
  { label: 'Comers­O', value: 'Comers­O' },
  { label: 'Commewijne District', value: 'Commewijne District' },
  { label: 'Comose District', value: 'Comose District' },
  { label: 'Conakry', value: 'Conakry' },
  { label: 'Concepcison Department', value: 'Concepcison Department' },
  { label: 'Connacht', value: 'Connacht' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Constana›A County', value: 'Constana›A County' },
  { label: 'Constantine', value: 'Constantine' },
  { label: 'Copperbelt Province', value: 'Copperbelt Province' },
  { label: 'Copsan Department', value: 'Copsan Department' },
  { label: 'Coquimbo', value: 'Coquimbo' },
  { label: 'Cordillera Administrative', value: 'Cordillera Administrative' },
  { label: 'Cordillera Department', value: 'Cordillera Department' },
  { label: 'Corfu Prefecture', value: 'Corfu Prefecture' },
  { label: 'Coronie District', value: 'Coronie District' },
  { label: 'Corozal', value: 'Corozal' },
  { label: 'Corozal District', value: 'Corozal District' },
  { label: 'Corrientes', value: 'Corrientes' },
  { label: 'Corse', value: 'Corse' },
  { label: 'Cortses Department', value: 'Cortses Department' },
  { label: 'Cospicua', value: 'Cospicua' },
  { label: 'Cotopaxi', value: 'Cotopaxi' },
  { label: 'Couva-Tabaquite-Talparo Regional Corporation', value: 'Couva-Tabaquite-Talparo Regional Corporation' },
  { label: 'Cova Lima Municipality', value: 'Cova Lima Municipality' },
  { label: 'Covasna County', value: 'Covasna County' },
  { label: 'Crete Region', value: 'Crete Region' },
  { label: 'Criuleni District', value: 'Criuleni District' },
  { label: 'Crooked Island', value: 'Crooked Island' },
  { label: 'Cross River', value: 'Cross River' },
  { label: 'Cs  Mau', value: 'Cs  Mau' },
  { label: 'Csadiz', value: 'Csadiz' },
  { label: 'Csongrsad County', value: 'Csongrsad County' },
  { label: 'Csordoba', value: 'Csordoba' },
  { label: 'Cuando Cubango Province', value: 'Cuando Cubango Province' },
  { label: 'Cuanza Norte Province', value: 'Cuanza Norte Province' },
  { label: 'Cuanza Sul', value: 'Cuanza Sul' },
  { label: 'Cuenca', value: 'Cuenca' },
  { label: 'Culebra', value: 'Culebra' },
  { label: 'Cundinamarca', value: 'Cundinamarca' },
  { label: 'Cunene Province', value: 'Cunene Province' },
  { label: 'Cuscatlsan Department', value: 'Cuscatlsan Department' },
  { label: 'Cusco', value: 'Cusco' },
  { label: 'Cuvette Department', value: 'Cuvette Department' },
  { label: 'Cuvette-Ouest Department', value: 'Cuvette-Ouest Department' },
  { label: 'Cuyuni-Mazaruni', value: 'Cuyuni-Mazaruni' },
  { label: 'Daauoguz Region', value: 'Daauoguz Region' },
  { label: 'Dadra And Nagar Haveli And Daman And Diu', value: 'Dadra And Nagar Haveli And Daman And Diu' },
  { label: 'Daegu', value: 'Daegu' },
  { label: 'Daejeon', value: 'Daejeon' },
  { label: 'Dagana District', value: 'Dagana District' },
  { label: 'Dagda Municipality', value: 'Dagda Municipality' },
  { label: 'Dajabson Province', value: 'Dajabson Province' },
  { label: 'Dakahlia', value: 'Dakahlia' },
  { label: 'Dakar', value: 'Dakar' },
  { label: 'Dakhlet Nouadhibou', value: 'Dakhlet Nouadhibou' },
  { label: 'Dalarna County', value: 'Dalarna County' },
  { label: 'Damascus', value: 'Damascus' },
  { label: 'Damietta', value: 'Damietta' },
  { label: 'Danilovgrad Municipality', value: 'Danilovgrad Municipality' },
  { label: 'Dar Es Salaam', value: 'Dar Es Salaam' },
  { label: 'Daraa', value: 'Daraa' },
  { label: 'Darisen Province', value: 'Darisen Province' },
  { label: 'Darkhan-Uul Province', value: 'Darkhan-Uul Province' },
  { label: 'Dashkasan District', value: 'Dashkasan District' },
  { label: 'Daugavpils Municipality', value: 'Daugavpils Municipality' },
  { label: 'Davao', value: 'Davao' },
  { label: 'Davao Occidental', value: 'Davao Occidental' },
  { label: 'Daykundi', value: 'Daykundi' },
  { label: 'Debarca Municipality', value: 'Debarca Municipality' },
  { label: 'Debub Region', value: 'Debub Region' },
  { label: 'Deir Ez-Zor', value: 'Deir Ez-Zor' },
  { label: 'DelaEvo Municipality', value: 'DelaEvo Municipality' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Delhi', value: 'Delhi' },
  { label: 'Delta', value: 'Delta' },
  { label: 'Delta Amacuro', value: 'Delta Amacuro' },
  { label: 'Demerara-Mahaica', value: 'Demerara-Mahaica' },
  { label: 'Demir Hisar Municipality', value: 'Demir Hisar Municipality' },
  { label: 'Demir Kapija Municipality', value: 'Demir Kapija Municipality' },
  { label: 'Denguselse District', value: 'Denguselse District' },
  { label: 'Denizli', value: 'Denizli' },
  { label: 'Dennery Quarter', value: 'Dennery Quarter' },
  { label: 'Derna District', value: 'Derna District' },
  { label: 'Destrnik Municipality', value: 'Destrnik Municipality' },
  { label: 'Devonshire', value: 'Devonshire' },
  { label: 'Dhaalu Atoll', value: 'Dhaalu Atoll' },
  { label: 'Dhaka District', value: 'Dhaka District' },
  { label: 'Dhamar', value: 'Dhamar' },
  { label: 'Dhi Qar', value: 'Dhi Qar' },
  { label: 'Dhofar', value: 'Dhofar' },
  { label: 'Di Yogyakarta', value: 'Di Yogyakarta' },
  { label: 'Dibser County', value: 'Dibser County' },
  { label: 'Diego Martin Regional Corporation', value: 'Diego Martin Regional Corporation' },
  { label: 'Diffa Region', value: 'Diffa Region' },
  { label: 'Dikhil Region', value: 'Dikhil Region' },
  { label: 'Dili Municipality', value: 'Dili Municipality' },
  { label: 'Dingli', value: 'Dingli' },
  { label: 'Diourbel Region', value: 'Diourbel Region' },
  { label: 'Dire Dawa', value: 'Dire Dawa' },
  { label: 'District Of Columbia', value: 'District Of Columbia' },
  { label: 'Districts Of Republican Subordination', value: 'Districts Of Republican Subordination' },
  { label: 'Distrito Capital', value: 'Distrito Capital' },
  { label: 'Distrito Federal', value: 'Distrito Federal' },
  { label: 'Distrito Nacional', value: 'Distrito Nacional' },
  { label: 'DivaaA Municipality', value: 'DivaaA Municipality' },
  { label: 'Dix-Huit Montagnes', value: 'Dix-Huit Montagnes' },
  { label: 'Diyala', value: 'Diyala' },
  { label: 'Diyarbakanr', value: 'Diyarbakanr' },
  { label: 'Djelfa', value: 'Djelfa' },
  { label: 'Djibouti', value: 'Djibouti' },
  { label: 'Dki Jakarta', value: 'Dki Jakarta' },
  { label: 'Dnipropetrovska Oblast', value: 'Dnipropetrovska Oblast' },
  { label: 'Dobele Municipality', value: 'Dobele Municipality' },
  { label: 'Dobje Municipality', value: 'Dobje Municipality' },
  { label: 'Dobrepolje Municipality', value: 'Dobrepolje Municipality' },
  { label: 'Dobrich Province', value: 'Dobrich Province' },
  { label: 'Dobrna Municipality', value: 'Dobrna Municipality' },
  { label: 'Dobrovaa€“Polhov Gradec Municipality', value: 'Dobrovaa€“Polhov Gradec Municipality' },
  { label: 'Dobrovnik Municipality', value: 'Dobrovnik Municipality' },
  { label: 'Dodoma', value: 'Dodoma' },
  { label: 'Doha', value: 'Doha' },
  { label: 'Dohuk', value: 'Dohuk' },
  { label: 'Dojran Municipality', value: 'Dojran Municipality' },
  { label: 'Dol Pri Ljubljani Municipality', value: 'Dol Pri Ljubljani Municipality' },
  { label: 'Dolenjske Toplice Municipality', value: 'Dolenjske Toplice Municipality' },
  { label: 'Dolj County', value: 'Dolj County' },
  { label: 'Dolneni Municipality', value: 'Dolneni Municipality' },
  { label: 'Doma¾Ale Municipality', value: 'Doma¾Ale Municipality' },
  { label: 'Domagnano', value: 'Domagnano' },
  { label: 'Dondua™Eni District', value: 'Dondua™Eni District' },
  { label: 'Donetska Oblast', value: 'Donetska Oblast' },
  { label: 'Donga Department', value: 'Donga Department' },
  { label: 'Dorado', value: 'Dorado' },
  { label: 'Dornava Municipality', value: 'Dornava Municipality' },
  { label: 'Dornod Province', value: 'Dornod Province' },
  { label: 'Dosso Region', value: 'Dosso Region' },
  { label: 'Dravograd Municipality', value: 'Dravograd Municipality' },
  { label: 'Drenthe', value: 'Drenthe' },
  { label: 'Drochia District', value: 'Drochia District' },
  { label: 'Drsaa-Tafilalet', value: 'Drsaa-Tafilalet' },
  { label: 'Dsambovia›A County', value: 'Dsambovia›A County' },
  { label: 'Dsuzce', value: 'Dsuzce' },
  { label: 'Duarte Province', value: 'Duarte Province' },
  { label: 'Dubaƒsari District', value: 'Dubaƒsari District' },
  { label: 'Dubai', value: 'Dubai' },
  { label: 'Dubrovnik-Neretva', value: 'Dubrovnik-Neretva' },
  { label: 'Dundaga Municipality', value: 'Dundaga Municipality' },
  { label: 'Dundgovi Province', value: 'Dundgovi Province' },
  { label: 'Duplek Municipality', value: 'Duplek Municipality' },
  { label: 'Durango', value: 'Durango' },
  { label: 'Durazno', value: 'Durazno' },
  { label: 'Durbe Municipality', value: 'Durbe Municipality' },
  { label: 'Durrses District', value: 'Durrses District' },
  { label: 'East', value: 'East' },
  { label: 'East Attica Regional Unit', value: 'East Attica Regional Unit' },
  { label: 'East Azerbaijan', value: 'East Azerbaijan' },
  { label: 'East Berbice-Corentyne', value: 'East Berbice-Corentyne' },
  { label: 'East Darfur', value: 'East Darfur' },
  { label: 'East Grand Bahama', value: 'East Grand Bahama' },
  { label: 'East Kazakhstan Region', value: 'East Kazakhstan Region' },
  { label: 'East New Britain', value: 'East New Britain' },
  { label: 'Eastern', value: 'Eastern' },
  { label: 'Eastern Cape', value: 'Eastern Cape' },
  { label: 'Eastern Development Region', value: 'Eastern Development Region' },
  { label: 'Eastern Division', value: 'Eastern Division' },
  { label: 'Eastern Highlands Province', value: 'Eastern Highlands Province' },
  { label: 'Eastern Province', value: 'Eastern Province' },
  { label: 'Eastern Region', value: 'Eastern Region' },
  { label: 'Eastern Tobago', value: 'Eastern Tobago' },
  { label: 'Eastern Visayas', value: 'Eastern Visayas' },
  { label: 'Ebonyi', value: 'Ebonyi' },
  { label: 'Edinea› District', value: 'Edinea› District' },
  { label: 'Edirne', value: 'Edirne' },
  { label: 'Edo', value: 'Edo' },
  { label: 'Ehime Prefecture', value: 'Ehime Prefecture' },
  { label: 'Ekiti', value: 'Ekiti' },
  { label: 'El Bayadh', value: 'El Bayadh' },
  { label: 'El Oro', value: 'El Oro' },
  { label: 'El Oued', value: 'El Oued' },
  { label: 'El Paras­So Department', value: 'El Paras­So Department' },
  { label: 'El Progreso Department', value: 'El Progreso Department' },
  { label: 'El Seibo Province', value: 'El Seibo Province' },
  { label: 'El Tarf', value: 'El Tarf' },
  { label: 'Elazanau', value: 'Elazanau' },
  { label: 'Embersa-Wounaan Comarca', value: 'Embersa-Wounaan Comarca' },
  { label: 'Embu', value: 'Embu' },
  { label: 'Emilia-Romagna', value: 'Emilia-Romagna' },
  { label: 'Encamp', value: 'Encamp' },
  { label: 'Enga Province', value: 'Enga Province' },
  { label: 'England', value: 'England' },
  { label: 'Engure Municipality', value: 'Engure Municipality' },
  { label: 'Ennedi-Ouest', value: 'Ennedi-Ouest' },
  { label: 'Entre Rs­Os', value: 'Entre Rs­Os' },
  { label: 'Enugu', value: 'Enugu' },
  { label: 'Epirus Region', value: 'Epirus Region' },
  { label: 'Erbil', value: 'Erbil' },
  { label: 'Ermera District', value: 'Ermera District' },
  { label: 'Erongo Region', value: 'Erongo Region' },
  { label: 'Erzincan', value: 'Erzincan' },
  { label: 'Erzurum', value: 'Erzurum' },
  { label: 'Escaldes-Engordany', value: 'Escaldes-Engordany' },
  { label: 'Eschen', value: 'Eschen' },
  { label: 'Escuintla Department', value: 'Escuintla Department' },
  { label: 'Eskiauehir', value: 'Eskiauehir' },
  { label: 'Esmeraldas', value: 'Esmeraldas' },
  { label: 'Espaillat Province', value: 'Espaillat Province' },
  { label: 'Esps­Rito Santo', value: 'Esps­Rito Santo' },
  { label: 'Essequibo Islands-West Demerara', value: 'Essequibo Islands-West Demerara' },
  { label: 'Est Region', value: 'Est Region' },
  { label: 'Estado De Msexico', value: 'Estado De Msexico' },
  { label: 'Estels­', value: 'Estels­' },
  { label: 'Estuaire Province', value: 'Estuaire Province' },
  { label: 'Exuma', value: 'Exuma' },
  { label: 'Faetano', value: 'Faetano' },
  { label: 'Faƒlea™Ti District', value: 'Faƒlea™Ti District' },
  { label: 'Faiyum', value: 'Faiyum' },
  { label: 'Fajardo', value: 'Fajardo' },
  { label: 'Falcson', value: 'Falcson' },
  { label: 'Famagusta District (Maauusa)', value: 'Famagusta District (Maauusa)' },
  { label: 'Far North', value: 'Far North' },
  { label: 'Farah', value: 'Farah' },
  { label: 'Faro', value: 'Faro' },
  { label: 'Fars', value: 'Fars' },
  { label: 'Far-Western Development Region', value: 'Far-Western Development Region' },
  { label: 'Faryab', value: 'Faryab' },
  { label: 'Fatick', value: 'Fatick' },
  { label: 'Federally Administered Tribal Areas', value: 'Federally Administered Tribal Areas' },
  { label: 'Federation Of Bosnia And Herzegovina', value: 'Federation Of Bosnia And Herzegovina' },
  { label: 'Fejser County', value: 'Fejser County' },
  { label: 'Fergana Region', value: 'Fergana Region' },
  { label: 'Fgura', value: 'Fgura' },
  { label: 'Fier County', value: 'Fier County' },
  { label: 'Finland Proper', value: 'Finland Proper' },
  { label: 'Fiorentino', value: 'Fiorentino' },
  { label: 'Fizuli District', value: 'Fizuli District' },
  { label: 'Flacq', value: 'Flacq' },
  { label: 'Flanders', value: 'Flanders' },
  { label: 'Flevoland', value: 'Flevoland' },
  { label: 'Florea™Ti District', value: 'Florea™Ti District' },
  { label: 'Flores', value: 'Flores' },
  { label: 'Floriana', value: 'Floriana' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Fontana', value: 'Fontana' },
  { label: 'Formosa', value: 'Formosa' },
  { label: 'Francisco Morazsan Department', value: 'Francisco Morazsan Department' },
  { label: 'Free State', value: 'Free State' },
  { label: 'Freeport', value: 'Freeport' },
  { label: 'Fribourg', value: 'Fribourg' },
  { label: 'Friesland', value: 'Friesland' },
  { label: 'Friulia€“Venezia Giulia', value: 'Friulia€“Venezia Giulia' },
  { label: 'Fses-Meknses', value: 'Fses-Meknses' },
  { label: 'Fujairah', value: 'Fujairah' },
  { label: 'Fujian', value: 'Fujian' },
  { label: 'Fukui Prefecture', value: 'Fukui Prefecture' },
  { label: 'Fukuoka Prefecture', value: 'Fukuoka Prefecture' },
  { label: 'Fukushima Prefecture', value: 'Fukushima Prefecture' },
  { label: 'Funafuti', value: 'Funafuti' },
  { label: 'Ga§Ajnsielem', value: 'Ga§Ajnsielem' },
  { label: 'Ga§Arga§Ur', value: 'Ga§Arga§Ur' },
  { label: 'Ga§Asri', value: 'Ga§Asri' },
  { label: 'Ga§Axaq', value: 'Ga§Axaq' },
  { label: 'Gaafu Alif Atoll', value: 'Gaafu Alif Atoll' },
  { label: 'Gaafu Dhaalu Atoll', value: 'Gaafu Dhaalu Atoll' },
  { label: 'Gabrovo Province', value: 'Gabrovo Province' },
  { label: 'Gabses', value: 'Gabses' },
  { label: 'Gabsu Region', value: 'Gabsu Region' },
  { label: 'Gafsa', value: 'Gafsa' },
  { label: 'Gaga Ifomauga', value: 'Gaga Ifomauga' },
  { label: 'Gagauzia', value: 'Gagauzia' },
  { label: 'Galaa›I County', value: 'Galaa›I County' },
  { label: 'Galguduud', value: 'Galguduud' },
  { label: 'Galsapagos', value: 'Galsapagos' },
  { label: 'Gambela Region', value: 'Gambela Region' },
  { label: 'Gamprin', value: 'Gamprin' },
  { label: 'Gangwon Province', value: 'Gangwon Province' },
  { label: 'Ganja', value: 'Ganja' },
  { label: 'Gansu', value: 'Gansu' },
  { label: 'Gao Region', value: 'Gao Region' },
  { label: 'Garissa', value: 'Garissa' },
  { label: 'Garkalne Municipality', value: 'Garkalne Municipality' },
  { label: 'Gasa District', value: 'Gasa District' },
  { label: 'Gash-Barka Region', value: 'Gash-Barka Region' },
  { label: 'Ga™Da™Ba™Y', value: 'Ga™Da™Ba™Y' },
  { label: 'Gauira', value: 'Gauira' },
  { label: 'Gauteng', value: 'Gauteng' },
  { label: 'Gaza Province', value: 'Gaza Province' },
  { label: 'Gazi Baba Municipality', value: 'Gazi Baba Municipality' },
  { label: 'Gaziantep', value: 'Gaziantep' },
  { label: 'Gbarpolu County', value: 'Gbarpolu County' },
  { label: 'Gedo', value: 'Gedo' },
  { label: 'Gegharkunik Province', value: 'Gegharkunik Province' },
  { label: 'Geita', value: 'Geita' },
  { label: 'Gelderland', value: 'Gelderland' },
  { label: 'Geneva', value: 'Geneva' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Gevgelija Municipality', value: 'Gevgelija Municipality' },
  { label: 'GhaR', value: 'GhaR' },
  { label: 'Ghanzi District', value: 'Ghanzi District' },
  { label: 'Gharbia', value: 'Gharbia' },
  { label: 'Ghardas¯A', value: 'Ghardas¯A' },
  { label: 'Ghat District', value: 'Ghat District' },
  { label: 'Ghazni', value: 'Ghazni' },
  { label: 'Gia Lai', value: 'Gia Lai' },
  { label: 'Gifu Prefecture', value: 'Gifu Prefecture' },
  { label: 'Gilan', value: 'Gilan' },
  { label: 'Gilbert Islands', value: 'Gilbert Islands' },
  { label: 'Gilgit-Baltistan', value: 'Gilgit-Baltistan' },
  { label: 'Gipuzkoa', value: 'Gipuzkoa' },
  { label: 'Giresun', value: 'Giresun' },
  { label: 'Girona', value: 'Girona' },
  { label: 'Gisborne District', value: 'Gisborne District' },
  { label: 'Gitega Province', value: 'Gitega Province' },
  { label: 'Giurgiu County', value: 'Giurgiu County' },
  { label: 'Giza', value: 'Giza' },
  { label: 'Gjirokastser District', value: 'Gjirokastser District' },
  { label: 'GjoraE Petrov Municipality', value: 'GjoraE Petrov Municipality' },
  { label: 'Glarus', value: 'Glarus' },
  { label: 'Glodeni District', value: 'Glodeni District' },
  { label: 'Gnaviyani Atoll', value: 'Gnaviyani Atoll' },
  { label: 'Goa', value: 'Goa' },
  { label: 'Gobustan District', value: 'Gobustan District' },
  { label: 'Goisas', value: 'Goisas' },
  { label: 'Golestan', value: 'Golestan' },
  { label: 'Gombe', value: 'Gombe' },
  { label: 'Gomel Region', value: 'Gomel Region' },
  { label: 'Goranboy District', value: 'Goranboy District' },
  { label: 'Gorenja Vasa€“Poljane Municipality', value: 'Gorenja Vasa€“Poljane Municipality' },
  { label: 'Gorgol', value: 'Gorgol' },
  { label: 'Goriaanica Municipality', value: 'Goriaanica Municipality' },
  { label: 'Gorj County', value: 'Gorj County' },
  { label: 'Gornja Radgona Municipality', value: 'Gornja Radgona Municipality' },
  { label: 'Gornji Grad Municipality', value: 'Gornji Grad Municipality' },
  { label: 'Gornji Petrovci Municipality', value: 'Gornji Petrovci Municipality' },
  { label: 'Gorno-Badakhshan Autonomous Province', value: 'Gorno-Badakhshan Autonomous Province' },
  { label: 'Gorontalo', value: 'Gorontalo' },
  { label: 'Gostivar Municipality', value: 'Gostivar Municipality' },
  { label: 'Gotland County', value: 'Gotland County' },
  { label: 'Govi-Altai Province', value: 'Govi-Altai Province' },
  { label: 'Govissumber Province', value: 'Govissumber Province' },
  { label: 'Goychay', value: 'Goychay' },
  { label: 'Goygol District', value: 'Goygol District' },
  { label: 'Gracias A Dios Department', value: 'Gracias A Dios Department' },
  { label: 'Grad Municipality', value: 'Grad Municipality' },
  { label: 'Gradsko Municipality', value: 'Gradsko Municipality' },
  { label: 'Granada', value: 'Granada' },
  { label: 'Grand Bassa County', value: 'Grand Bassa County' },
  { label: 'Grand Cape Mount County', value: 'Grand Cape Mount County' },
  { label: 'Grand Gedeh County', value: 'Grand Gedeh County' },
  { label: 'Grand Kru County', value: 'Grand Kru County' },
  { label: 'Grand Port', value: 'Grand Port' },
  { label: 'Grand Anse', value: 'Grand Anse' },
  { label: 'Grande Comore', value: 'Grande Comore' },
  { label: 'Grand-Est', value: 'Grand-Est' },
  { label: 'Granma Province', value: 'Granma Province' },
  { label: 'Graubsunden', value: 'Graubsunden' },
  { label: 'Greater Accra', value: 'Greater Accra' },
  { label: 'Greater Poland Voivodeship', value: 'Greater Poland Voivodeship' },
  { label: 'Greater Skopje', value: 'Greater Skopje' },
  { label: 'Grenadines Parish', value: 'Grenadines Parish' },
  { label: 'Grobia†A Municipality', value: 'Grobia†A Municipality' },
  { label: 'Grodno Region', value: 'Grodno Region' },
  { label: 'Groningen', value: 'Groningen' },
  { label: 'Gros Islet Quarter', value: 'Gros Islet Quarter' },
  { label: 'Grosuplje Municipality', value: 'Grosuplje Municipality' },
  { label: 'Gsavleborg County', value: 'Gsavleborg County' },
  { label: 'Gsoh-Djiboua District', value: 'Gsoh-Djiboua District' },
  { label: 'Gsumsuauhane', value: 'Gsumsuauhane' },
  { label: 'Guadalajara', value: 'Guadalajara' },
  { label: 'Guadalcanal Province', value: 'Guadalcanal Province' },
  { label: 'Guains­A', value: 'Guains­A' },
  { label: 'Guairsa Department', value: 'Guairsa Department' },
  { label: 'Guanacaste Province', value: 'Guanacaste Province' },
  { label: 'Guanajuato', value: 'Guanajuato' },
  { label: 'Guangdong', value: 'Guangdong' },
  { label: 'Guangxi Zhuang', value: 'Guangxi Zhuang' },
  { label: 'Guantsanamo Province', value: 'Guantsanamo Province' },
  { label: 'Guarda', value: 'Guarda' },
  { label: 'Guatemala Department', value: 'Guatemala Department' },
  { label: 'Guaviare', value: 'Guaviare' },
  { label: 'Guayama', value: 'Guayama' },
  { label: 'Guayanilla', value: 'Guayanilla' },
  { label: 'Guayas', value: 'Guayas' },
  { label: 'Guaynabo', value: 'Guaynabo' },
  { label: 'Guelma', value: 'Guelma' },
  { label: 'Guelmim-Oued Noun (Eh-Partial)', value: 'Guelmim-Oued Noun (Eh-Partial)' },
  { label: 'Guerrero', value: 'Guerrero' },
  { label: 'Guidimaka', value: 'Guidimaka' },
  { label: 'Guizhou', value: 'Guizhou' },
  { label: 'Gujarat', value: 'Gujarat' },
  { label: 'Gulbene Municipality', value: 'Gulbene Municipality' },
  { label: 'Gulf', value: 'Gulf' },
  { label: 'Guna Yala', value: 'Guna Yala' },
  { label: 'Gunma Prefecture', value: 'Gunma Prefecture' },
  { label: 'Gurabo', value: 'Gurabo' },
  { label: 'Guria', value: 'Guria' },
  { label: 'Gusanica', value: 'Gusanica' },
  { label: 'Gusarico', value: 'Gusarico' },
  { label: 'Gusera', value: 'Gusera' },
  { label: 'Gusinje Municipality', value: 'Gusinje Municipality' },
  { label: 'Gwangju', value: 'Gwangju' },
  { label: 'Gya‘R-Moson-Sopron County', value: 'Gya‘R-Moson-Sopron County' },
  { label: 'Gyeonggi Province', value: 'Gyeonggi Province' },
  { label: 'Ha»“ Chs­ Minh', value: 'Ha»“ Chs­ Minh' },
  { label: 'Ha°Ng Ysen', value: 'Ha°Ng Ysen' },
  { label: 'Haa Alif Atoll', value: 'Haa Alif Atoll' },
  { label: 'Haa Dhaalu Atoll', value: 'Haa Dhaalu Atoll' },
  { label: 'Haa District', value: 'Haa District' },
  { label: 'Haa»Apai', value: 'Haa»Apai' },
  { label: 'Hadhramaut', value: 'Hadhramaut' },
  { label: 'Hadjer-Lamis', value: 'Hadjer-Lamis' },
  { label: 'Haifa District', value: 'Haifa District' },
  { label: 'Ha Il', value: 'Ha Il' },
  { label: 'Hainan', value: 'Hainan' },
  { label: 'Hajdina Municipality', value: 'Hajdina Municipality' },
  { label: 'Hajdsu-Bihar County', value: 'Hajdsu-Bihar County' },
  { label: 'Hajigabul District', value: 'Hajigabul District' },
  { label: 'Hajjah', value: 'Hajjah' },
  { label: 'Hakksari', value: 'Hakksari' },
  { label: 'Halland County', value: 'Halland County' },
  { label: 'Hama', value: 'Hama' },
  { label: 'Hamadan', value: 'Hamadan' },
  { label: 'Hamburg', value: 'Hamburg' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hanover Parish', value: 'Hanover Parish' },
  { label: 'Harare Province', value: 'Harare Province' },
  { label: 'Harari Region', value: 'Harari Region' },
  { label: 'Harbour Island', value: 'Harbour Island' },
  { label: 'Hardap Region', value: 'Hardap Region' },
  { label: 'Harghita County', value: 'Harghita County' },
  { label: 'Harju County', value: 'Harju County' },
  { label: 'Haryana', value: 'Haryana' },
  { label: 'Haskovo Province', value: 'Haskovo Province' },
  { label: 'Hatay', value: 'Hatay' },
  { label: 'Hatillo', value: 'Hatillo' },
  { label: 'Hato Mayor Province', value: 'Hato Mayor Province' },
  { label: 'Hatohobei', value: 'Hatohobei' },
  { label: 'Hau£I Da°Aang', value: 'Hau£I Da°Aang' },
  { label: 'Hau£I Phsong', value: 'Hau£I Phsong' },
  { label: 'Haute-Kotto Prefecture', value: 'Haute-Kotto Prefecture' },
  { label: 'Haut-Katanga', value: 'Haut-Katanga' },
  { label: 'Haut-Lomami', value: 'Haut-Lomami' },
  { label: 'Haut-Mbomou Prefecture', value: 'Haut-Mbomou Prefecture' },
  { label: 'Haut-Ogoouse Province', value: 'Haut-Ogoouse Province' },
  { label: 'Hauts-Bassins Region', value: 'Hauts-Bassins Region' },
  { label: 'Hauts-De-France', value: 'Hauts-De-France' },
  { label: 'Haut-Uselse', value: 'Haut-Uselse' },
  { label: 'Hau­U Giang', value: 'Hau­U Giang' },
  { label: 'Havana Province', value: 'Havana Province' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Hawalli', value: 'Hawalli' },
  { label: 'Hawke S Bay Region', value: 'Hawke S Bay Region' },
  { label: 'Hebei', value: 'Hebei' },
  { label: 'Heilongjiang', value: 'Heilongjiang' },
  { label: 'Hela', value: 'Hela' },
  { label: 'Helmand', value: 'Helmand' },
  { label: 'Henan', value: 'Henan' },
  { label: 'Herat', value: 'Herat' },
  { label: 'Heredia Province', value: 'Heredia Province' },
  { label: 'Hermanas Mirabal Province', value: 'Hermanas Mirabal Province' },
  { label: 'Herrera Province', value: 'Herrera Province' },
  { label: 'Hesse', value: 'Hesse' },
  { label: 'Heves County', value: 'Heves County' },
  { label: 'Hhohho District', value: 'Hhohho District' },
  { label: 'Hidalgo', value: 'Hidalgo' },
  { label: 'Hiiu County', value: 'Hiiu County' },
  { label: 'Himachal Pradesh', value: 'Himachal Pradesh' },
  { label: 'Hiran', value: 'Hiran' },
  { label: 'Hiroshima Prefecture', value: 'Hiroshima Prefecture' },
  { label: 'HoaEa€“Slivnica Municipality', value: 'HoaEa€“Slivnica Municipality' },
  { label: 'Hodh Ech Chargui', value: 'Hodh Ech Chargui' },
  { label: 'Hodh El Gharbi', value: 'Hodh El Gharbi' },
  { label: 'Hodoaa Municipality', value: 'Hodoaa Municipality' },
  { label: 'Hokkaida Prefecture', value: 'Hokkaida Prefecture' },
  { label: 'Holgus­N Province', value: 'Holgus­N Province' },
  { label: 'Homa Bay', value: 'Homa Bay' },
  { label: 'Homs', value: 'Homs' },
  { label: 'Horjul Municipality', value: 'Horjul Municipality' },
  { label: 'Hormigueros', value: 'Hormigueros' },
  { label: 'Hormozgan', value: 'Hormozgan' },
  { label: 'Houaphanh Province', value: 'Houaphanh Province' },
  { label: 'Hrastnik Municipality', value: 'Hrastnik Municipality' },
  { label: 'Hrpeljea€“Kozina Municipality', value: 'Hrpeljea€“Kozina Municipality' },
  { label: 'Hs  Giang', value: 'Hs  Giang' },
  { label: 'Hs  Na»™I', value: 'Hs  Na»™I' },
  { label: 'Hs  Nam', value: 'Hs  Nam' },
  { label: 'Hs  Taenh', value: 'Hs  Taenh' },
  { label: 'Hs®Ncea™Ti District', value: 'Hs®Ncea™Ti District' },
  { label: 'Hsinchu', value: 'Hsinchu' },
  { label: 'Hsoa Bs¬Nh', value: 'Hsoa Bs¬Nh' },
  { label: 'Hualien', value: 'Hualien' },
  { label: 'Huambo Province', value: 'Huambo Province' },
  { label: 'Huancavelica', value: 'Huancavelica' },
  { label: 'Huanuco', value: 'Huanuco' },
  { label: 'Hubei', value: 'Hubei' },
  { label: 'Huehuetenango Department', value: 'Huehuetenango Department' },
  { label: 'Huelva', value: 'Huelva' },
  { label: 'Huesca', value: 'Huesca' },
  { label: 'Huila', value: 'Huila' },
  { label: 'Humacao', value: 'Humacao' },
  { label: 'Hunan', value: 'Hunan' },
  { label: 'Hunedoara County', value: 'Hunedoara County' },
  { label: 'Hus­La Province', value: 'Hus­La Province' },
  { label: 'HyaGo Prefecture', value: 'HyaGo Prefecture' },
  { label: 'Iaa™I County', value: 'Iaa™I County' },
  { label: 'Ialomia›A County', value: 'Ialomia›A County' },
  { label: 'Ialoveni District', value: 'Ialoveni District' },
  { label: 'Iaudanr', value: 'Iaudanr' },
  { label: 'Ibaraki Prefecture', value: 'Ibaraki Prefecture' },
  { label: 'Ibb', value: 'Ibb' },
  { label: 'Ica', value: 'Ica' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'Ida-Viru County', value: 'Ida-Viru County' },
  { label: 'Idlib', value: 'Idlib' },
  { label: 'Idrija Municipality', value: 'Idrija Municipality' },
  { label: 'Iecava Municipality', value: 'Iecava Municipality' },
  { label: 'Ig Municipality', value: 'Ig Municipality' },
  { label: 'Ijuw District', value: 'Ijuw District' },
  { label: 'Ikaaa·Ile Municipality', value: 'Ikaaa·Ile Municipality' },
  { label: 'Iklin', value: 'Iklin' },
  { label: 'Ilaekste Municipality', value: 'Ilaekste Municipality' },
  { label: 'Ilam', value: 'Ilam' },
  { label: 'Ilfov County', value: 'Ilfov County' },
  { label: 'Ilinden Municipality', value: 'Ilinden Municipality' },
  { label: 'Illinois', value: 'Illinois' },
  { label: 'Illizi', value: 'Illizi' },
  { label: 'Ilocos', value: 'Ilocos' },
  { label: 'Imbabura', value: 'Imbabura' },
  { label: 'Imereti', value: 'Imereti' },
  { label: 'Imishli District', value: 'Imishli District' },
  { label: 'Imo', value: 'Imo' },
  { label: 'InaUkalns Municipality', value: 'InaUkalns Municipality' },
  { label: 'Inagua', value: 'Inagua' },
  { label: 'Incheon', value: 'Incheon' },
  { label: 'Inchiri', value: 'Inchiri' },
  { label: 'Independencia', value: 'Independencia' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Inhambane Province', value: 'Inhambane Province' },
  { label: 'Inner Mongolia', value: 'Inner Mongolia' },
  { label: 'Innlandet', value: 'Innlandet' },
  { label: 'Intibucsa Department', value: 'Intibucsa Department' },
  { label: 'Ionian Islands Region', value: 'Ionian Islands Region' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Irbid', value: 'Irbid' },
  { label: 'Iringa', value: 'Iringa' },
  { label: 'Isabel Province', value: 'Isabel Province' },
  { label: 'Isabela', value: 'Isabela' },
  { label: 'Isfahan', value: 'Isfahan' },
  { label: 'Ishikawa Prefecture', value: 'Ishikawa Prefecture' },
  { label: 'Isiolo', value: 'Isiolo' },
  { label: 'Isla De La Juventud', value: 'Isla De La Juventud' },
  { label: 'Islamabad Capital Territory', value: 'Islamabad Capital Territory' },
  { label: 'Islands', value: 'Islands' },
  { label: 'Islas Baleares', value: 'Islas Baleares' },
  { label: 'Ismailia', value: 'Ismailia' },
  { label: 'Ismailli District', value: 'Ismailli District' },
  { label: 'Isparta', value: 'Isparta' },
  { label: 'Issyk-Kul Region', value: 'Issyk-Kul Region' },
  { label: 'Istria', value: 'Istria' },
  { label: 'Itapsua', value: 'Itapsua' },
  { label: 'Ituri', value: 'Ituri' },
  { label: 'IvanaNa Gorica Municipality', value: 'IvanaNa Gorica Municipality' },
  { label: 'Ivano-Frankivska Oblast', value: 'Ivano-Frankivska Oblast' },
  { label: 'Ivanovo Oblast', value: 'Ivanovo Oblast' },
  { label: 'Iwate Prefecture', value: 'Iwate Prefecture' },
  { label: 'Izabal Department', value: 'Izabal Department' },
  { label: 'Izola Municipality', value: 'Izola Municipality' },
  { label: 'Ja“Kabpils Municipality', value: 'Ja“Kabpils Municipality' },
  { label: 'Jabal Al Akhdar', value: 'Jabal Al Akhdar' },
  { label: 'Jabal Al Gharbi District', value: 'Jabal Al Gharbi District' },
  { label: 'Jabrayil District', value: 'Jabrayil District' },
  { label: 'Jaermala', value: 'Jaermala' },
  { label: 'Jafara', value: 'Jafara' },
  { label: 'Jalal-Abad Region', value: 'Jalal-Abad Region' },
  { label: 'Jalapa Department', value: 'Jalapa Department' },
  { label: 'Jalilabad District', value: 'Jalilabad District' },
  { label: 'Jalisco', value: 'Jalisco' },
  { label: 'Jambi', value: 'Jambi' },
  { label: 'Jambyl Region', value: 'Jambyl Region' },
  { label: 'Jammu And Kashmir', value: 'Jammu And Kashmir' },
  { label: 'Jasen', value: 'Jasen' },
  { label: 'Jaunjelgava Municipality', value: 'Jaunjelgava Municipality' },
  { label: 'Jaunpils Municipality', value: 'Jaunpils Municipality' },
  { label: 'Jawa Barat', value: 'Jawa Barat' },
  { label: 'Jawa Tengah', value: 'Jawa Tengah' },
  { label: 'Jawa Timur', value: 'Jawa Timur' },
  { label: 'Jayuya', value: 'Jayuya' },
  { label: 'Jegunovce Municipality', value: 'Jegunovce Municipality' },
  { label: 'Jeju', value: 'Jeju' },
  { label: 'Jelgava', value: 'Jelgava' },
  { label: 'Jelgava Municipality', value: 'Jelgava Municipality' },
  { label: 'Jendouba', value: 'Jendouba' },
  { label: 'Jerash', value: 'Jerash' },
  { label: 'Jerusalem District', value: 'Jerusalem District' },
  { label: 'Jesenice Municipality', value: 'Jesenice Municipality' },
  { label: 'Jewish Autonomous Oblast', value: 'Jewish Autonomous Oblast' },
  { label: 'Jezersko Municipality', value: 'Jezersko Municipality' },
  { label: 'Jharkhand', value: 'Jharkhand' },
  { label: 'Jiangsu', value: 'Jiangsu' },
  { label: 'Jiangxi', value: 'Jiangxi' },
  { label: 'Jigawa', value: 'Jigawa' },
  { label: 'JihoaEsks½ Kraj', value: 'JihoaEsks½ Kraj' },
  { label: 'Jihomoravsks½ Kraj', value: 'Jihomoravsks½ Kraj' },
  { label: 'Jijel', value: 'Jijel' },
  { label: 'Jilin', value: 'Jilin' },
  { label: 'Jinotega', value: 'Jinotega' },
  { label: 'Jiwaka Province', value: 'Jiwaka Province' },
  { label: 'Jizan', value: 'Jizan' },
  { label: 'Jizzakh Region', value: 'Jizzakh Region' },
  { label: 'Johor', value: 'Johor' },
  { label: 'Jowzjan', value: 'Jowzjan' },
  { label: 'Jsµgeva County', value: 'Jsµgeva County' },
  { label: 'Jsarva County', value: 'Jsarva County' },
  { label: 'Jsasz-Nagykun-Szolnok County', value: 'Jsasz-Nagykun-Szolnok County' },
  { label: 'Jsonksoping County', value: 'Jsonksoping County' },
  { label: 'Juana Ds­Az', value: 'Juana Ds­Az' },
  { label: 'Jufra', value: 'Jufra' },
  { label: 'Jujuy', value: 'Jujuy' },
  { label: 'Juncos', value: 'Juncos' },
  { label: 'Juns­N', value: 'Juns­N' },
  { label: 'Jura', value: 'Jura' },
  { label: 'Juraainci Municipality', value: 'Juraainci Municipality' },
  { label: 'Jutiapa Department', value: 'Jutiapa Department' },
  { label: 'KaChi Prefecture', value: 'KaChi Prefecture' },
  { label: 'KaRsava Municipality', value: 'KaRsava Municipality' },
  { label: 'Kaafu Atoll', value: 'Kaafu Atoll' },
  { label: 'Kabardino-Balkar Republic', value: 'Kabardino-Balkar Republic' },
  { label: 'Kabul', value: 'Kabul' },
  { label: 'Kachin State', value: 'Kachin State' },
  { label: 'Kaduna', value: 'Kaduna' },
  { label: 'Kaffrine', value: 'Kaffrine' },
  { label: 'Kafr El-Sheikh', value: 'Kafr El-Sheikh' },
  { label: 'Kagawa Prefecture', value: 'Kagawa Prefecture' },
  { label: 'Kagera', value: 'Kagera' },
  { label: 'Kagoshima Prefecture', value: 'Kagoshima Prefecture' },
  { label: 'Kahramanmaraau', value: 'Kahramanmaraau' },
  { label: 'Kainuu', value: 'Kainuu' },
  { label: 'Kairouan', value: 'Kairouan' },
  { label: 'Kajiado', value: 'Kajiado' },
  { label: 'Kakamega', value: 'Kakamega' },
  { label: 'Kakheti', value: 'Kakheti' },
  { label: 'Kalasin', value: 'Kalasin' },
  { label: 'Kalbajar District', value: 'Kalbajar District' },
  { label: 'Kalimantan Barat', value: 'Kalimantan Barat' },
  { label: 'Kalimantan Selatan', value: 'Kalimantan Selatan' },
  { label: 'Kalimantan Tengah', value: 'Kalimantan Tengah' },
  { label: 'Kalimantan Timur', value: 'Kalimantan Timur' },
  { label: 'Kalimantan Utara', value: 'Kalimantan Utara' },
  { label: 'Kaliningrad', value: 'Kaliningrad' },
  { label: 'Kalkara', value: 'Kalkara' },
  { label: 'Kalmar County', value: 'Kalmar County' },
  { label: 'Kaluga Oblast', value: 'Kaluga Oblast' },
  { label: 'Kamchatka Krai', value: 'Kamchatka Krai' },
  { label: 'Kamnik Municipality', value: 'Kamnik Municipality' },
  { label: 'Kamphaeng Phet', value: 'Kamphaeng Phet' },
  { label: 'Kampong Cham', value: 'Kampong Cham' },
  { label: 'Kampong Chhnang', value: 'Kampong Chhnang' },
  { label: 'Kampong Speu', value: 'Kampong Speu' },
  { label: 'Kampot', value: 'Kampot' },
  { label: 'Kanagawa Prefecture', value: 'Kanagawa Prefecture' },
  { label: 'Kanal Ob SoaI Municipality', value: 'Kanal Ob SoaI Municipality' },
  { label: 'Kanchanaburi', value: 'Kanchanaburi' },
  { label: 'Kandahar', value: 'Kandahar' },
  { label: 'Kandal', value: 'Kandal' },
  { label: 'Kandava Municipality', value: 'Kandava Municipality' },
  { label: 'Kandy District', value: 'Kandy District' },
  { label: 'Kanem', value: 'Kanem' },
  { label: 'Kangwon Province', value: 'Kangwon Province' },
  { label: 'Kankan Region', value: 'Kankan Region' },
  { label: 'Kano', value: 'Kano' },
  { label: 'Kanrankkale', value: 'Kanrankkale' },
  { label: 'Kanrauehir', value: 'Kanrauehir' },
  { label: 'Kanrklareli', value: 'Kanrklareli' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kaohsiung', value: 'Kaohsiung' },
  { label: 'Kaolack', value: 'Kaolack' },
  { label: 'Kapisa', value: 'Kapisa' },
  { label: 'Kara Region', value: 'Kara Region' },
  { label: 'Karabsuk', value: 'Karabsuk' },
  { label: 'Karachay-Cherkess Republic', value: 'Karachay-Cherkess Republic' },
  { label: 'Karaganda Region', value: 'Karaganda Region' },
  { label: 'Karak', value: 'Karak' },
  { label: 'Karakalpakstan', value: 'Karakalpakstan' },
  { label: 'Karaman', value: 'Karaman' },
  { label: 'Karas Region', value: 'Karas Region' },
  { label: 'Karbala', value: 'Karbala' },
  { label: 'Karbinci', value: 'Karbinci' },
  { label: 'Kardzhali Province', value: 'Kardzhali Province' },
  { label: 'Karlovarsks½ Kraj', value: 'Karlovarsks½ Kraj' },
  { label: 'Karnataka', value: 'Karnataka' },
  { label: 'Karpoaa Municipality', value: 'Karpoaa Municipality' },
  { label: 'Kars', value: 'Kars' },
  { label: 'Karuzi Province', value: 'Karuzi Province' },
  { label: 'Kasas¯', value: 'Kasas¯' },
  { label: 'Kasas¯ Oriental', value: 'Kasas¯ Oriental' },
  { label: 'Kassala', value: 'Kassala' },
  { label: 'Kasserine', value: 'Kasserine' },
  { label: 'Kastamonu', value: 'Kastamonu' },
  { label: 'Katavi', value: 'Katavi' },
  { label: 'Katsina', value: 'Katsina' },
  { label: 'Kaunas City Municipality', value: 'Kaunas City Municipality' },
  { label: 'Kavadarci Municipality', value: 'Kavadarci Municipality' },
  { label: 'Kavango East Region', value: 'Kavango East Region' },
  { label: 'Kayah State', value: 'Kayah State' },
  { label: 'Kayangel', value: 'Kayangel' },
  { label: 'Kayanza Province', value: 'Kayanza Province' },
  { label: 'Kayes Region', value: 'Kayes Region' },
  { label: 'Kayin State', value: 'Kayin State' },
  { label: 'Kayseri', value: 'Kayseri' },
  { label: 'Kebbi', value: 'Kebbi' },
  { label: 'Kebili', value: 'Kebili' },
  { label: 'Kedah', value: 'Kedah' },
  { label: 'Kef', value: 'Kef' },
  { label: 'Kefalonia Prefecture', value: 'Kefalonia Prefecture' },
  { label: 'Kelantan', value: 'Kelantan' },
  { label: 'Kemerovo Oblast', value: 'Kemerovo Oblast' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Kep', value: 'Kep' },
  { label: 'Kepulauan Bangka Belitung', value: 'Kepulauan Bangka Belitung' },
  { label: 'Kepulauan Riau', value: 'Kepulauan Riau' },
  { label: 'Kera‹Em', value: 'Kera‹Em' },
  { label: 'Kerala', value: 'Kerala' },
  { label: 'Kericho', value: 'Kericho' },
  { label: 'Kerman', value: 'Kerman' },
  { label: 'Kermanshah', value: 'Kermanshah' },
  { label: 'Kgalagadi District', value: 'Kgalagadi District' },
  { label: 'Kgatleng District', value: 'Kgatleng District' },
  { label: 'Khabarovsk Krai', value: 'Khabarovsk Krai' },
  { label: 'Khachmaz District', value: 'Khachmaz District' },
  { label: 'Khammouane Province', value: 'Khammouane Province' },
  { label: 'Khanty-Mansi Autonomous Okrug', value: 'Khanty-Mansi Autonomous Okrug' },
  { label: 'Kharkivska Oblast', value: 'Kharkivska Oblast' },
  { label: 'Khartoum', value: 'Khartoum' },
  { label: 'Khatlon Province', value: 'Khatlon Province' },
  { label: 'Khenchela', value: 'Khenchela' },
  { label: 'Khentii Province', value: 'Khentii Province' },
  { label: 'Khersonska Oblast', value: 'Khersonska Oblast' },
  { label: 'Khizi District', value: 'Khizi District' },
  { label: 'Khmelnytska Oblast', value: 'Khmelnytska Oblast' },
  { label: 'Khojali District', value: 'Khojali District' },
  { label: 'Khomas Region', value: 'Khomas Region' },
  { label: 'Khon Kaen', value: 'Khon Kaen' },
  { label: 'Khost', value: 'Khost' },
  { label: 'Khovd Province', value: 'Khovd Province' },
  { label: 'Khsanh Hsoa', value: 'Khsanh Hsoa' },
  { label: 'Khsenifra', value: 'Khsenifra' },
  { label: 'Khsovsgsol Province', value: 'Khsovsgsol Province' },
  { label: 'Khulna District', value: 'Khulna District' },
  { label: 'Khuzestan', value: 'Khuzestan' },
  { label: 'Khyber Pakhtunkhwa', value: 'Khyber Pakhtunkhwa' },
  { label: 'KiaEvo Municipality', value: 'KiaEvo Municipality' },
  { label: 'Kiambu', value: 'Kiambu' },
  { label: 'Kidal Region', value: 'Kidal Region' },
  { label: 'KidriaEvo Municipality', value: 'KidriaEvo Municipality' },
  { label: 'Kigali District', value: 'Kigali District' },
  { label: 'Kigoma', value: 'Kigoma' },
  { label: 'Kilifi', value: 'Kilifi' },
  { label: 'Kilimanjaro', value: 'Kilimanjaro' },
  { label: 'Kilis', value: 'Kilis' },
  { label: 'Kindia Region', value: 'Kindia Region' },
  { label: 'Kingston Parish', value: 'Kingston Parish' },
  { label: 'Kinmen', value: 'Kinmen' },
  { label: 'Kinshasa', value: 'Kinshasa' },
  { label: 'Kirinyaga', value: 'Kirinyaga' },
  { label: 'Kirkop', value: 'Kirkop' },
  { label: 'Kirkuk', value: 'Kirkuk' },
  { label: 'Kirov Oblast', value: 'Kirov Oblast' },
  { label: 'Kirovohradska Oblast', value: 'Kirovohradska Oblast' },
  { label: 'Kirundo Province', value: 'Kirundo Province' },
  { label: 'Kisela Voda Municipality', value: 'Kisela Voda Municipality' },
  { label: 'Kisen Giang', value: 'Kisen Giang' },
  { label: 'Kise-Ntem Province', value: 'Kise-Ntem Province' },
  { label: 'Kisii', value: 'Kisii' },
  { label: 'Kisumu', value: 'Kisumu' },
  { label: 'Kitui', value: 'Kitui' },
  { label: 'Klaipa—Da District Municipality', value: 'Klaipa—Da District Municipality' },
  { label: 'KoaAni Municipality', value: 'KoaAni Municipality' },
  { label: 'KoaEvje Municipality', value: 'KoaEvje Municipality' },
  { label: 'Koaaice Region', value: 'Koaaice Region' },
  { label: 'Kobarid Municipality', value: 'Kobarid Municipality' },
  { label: 'Kobilje Municipality', value: 'Kobilje Municipality' },
  { label: 'Koca“Ni Municipality', value: 'Koca“Ni Municipality' },
  { label: 'Kocaeli', value: 'Kocaeli' },
  { label: 'Kogi', value: 'Kogi' },
  { label: 'Koh Kong', value: 'Koh Kong' },
  { label: 'Kohgiluyeh And Boyer-Ahmad', value: 'Kohgiluyeh And Boyer-Ahmad' },
  { label: 'Koknese Municipality', value: 'Koknese Municipality' },
  { label: 'Kolaaain Municipality', value: 'Kolaaain Municipality' },
  { label: 'Kolda', value: 'Kolda' },
  { label: 'Komen Municipality', value: 'Komen Municipality' },
  { label: 'Komenda Municipality', value: 'Komenda Municipality' },
  { label: 'Komi Republic', value: 'Komi Republic' },
  { label: 'Komsarom-Esztergom', value: 'Komsarom-Esztergom' },
  { label: 'Kon Tum', value: 'Kon Tum' },
  { label: 'KonaE Municipality', value: 'KonaE Municipality' },
  { label: 'Kongo Central', value: 'Kongo Central' },
  { label: 'Konya', value: 'Konya' },
  { label: 'Koper City Municipality', value: 'Koper City Municipality' },
  { label: 'Koprivnica-Kria¾Evci', value: 'Koprivnica-Kria¾Evci' },
  { label: 'Koror', value: 'Koror' },
  { label: 'Kors§Se County', value: 'Kors§Se County' },
  { label: 'Kosrae State', value: 'Kosrae State' },
  { label: 'Kostanay Region', value: 'Kostanay Region' },
  { label: 'Kostel Municipality', value: 'Kostel Municipality' },
  { label: 'Kostroma Oblast', value: 'Kostroma Oblast' },
  { label: 'Kotayk Region', value: 'Kotayk Region' },
  { label: 'Kotor Municipality', value: 'Kotor Municipality' },
  { label: 'Kouffo Department', value: 'Kouffo Department' },
  { label: 'Koulikoro Region', value: 'Koulikoro Region' },
  { label: 'Kowloon City', value: 'Kowloon City' },
  { label: 'Kozje Municipality', value: 'Kozje Municipality' },
  { label: 'KraSlava Municipality', value: 'KraSlava Municipality' },
  { label: 'Krabi', value: 'Krabi' },
  { label: 'Kraj VysoaIna', value: 'Kraj VysoaIna' },
  { label: 'Kranj City Municipality', value: 'Kranj City Municipality' },
  { label: 'Kranjska Gora Municipality', value: 'Kranjska Gora Municipality' },
  { label: 'Krapina-Zagorje', value: 'Krapina-Zagorje' },
  { label: 'Krasnodar Krai', value: 'Krasnodar Krai' },
  { label: 'Krasnoyarsk Krai', value: 'Krasnoyarsk Krai' },
  { label: 'Kratie', value: 'Kratie' },
  { label: 'Kratovo Municipality', value: 'Kratovo Municipality' },
  { label: 'Kria¾Evci Municipality', value: 'Kria¾Evci Municipality' },
  { label: 'Kriva Palanka Municipality', value: 'Kriva Palanka Municipality' },
  { label: 'Krivogaaatani Municipality', value: 'Krivogaaatani Municipality' },
  { label: 'Kronoberg County', value: 'Kronoberg County' },
  { label: 'Krsalovsehradecks½ Kraj', value: 'Krsalovsehradecks½ Kraj' },
  { label: 'Kruaaevo Municipality', value: 'Kruaaevo Municipality' },
  { label: 'Ksedougou', value: 'Ksedougou' },
  { label: 'Ksemo Prefecture', value: 'Ksemo Prefecture' },
  { label: 'Ksenitra', value: 'Ksenitra' },
  { label: 'Ksutahya', value: 'Ksutahya' },
  { label: 'Kuala Lumpur', value: 'Kuala Lumpur' },
  { label: 'Kufra District', value: 'Kufra District' },
  { label: 'Kukses District', value: 'Kukses District' },
  { label: 'Kuldaega Municipality', value: 'Kuldaega Municipality' },
  { label: 'Kumamoto Prefecture', value: 'Kumamoto Prefecture' },
  { label: 'Kumanovo Municipality', value: 'Kumanovo Municipality' },
  { label: 'Kunar', value: 'Kunar' },
  { label: 'Kunduz Province', value: 'Kunduz Province' },
  { label: 'Kunene Region', value: 'Kunene Region' },
  { label: 'Kungota', value: 'Kungota' },
  { label: 'Kurdamir District', value: 'Kurdamir District' },
  { label: 'Kurdistan', value: 'Kurdistan' },
  { label: 'Kurgan Oblast', value: 'Kurgan Oblast' },
  { label: 'Kursk Oblast', value: 'Kursk Oblast' },
  { label: 'Kuyavian-Pomeranian Voivodeship', value: 'Kuyavian-Pomeranian Voivodeship' },
  { label: 'Kuzma Municipality', value: 'Kuzma Municipality' },
  { label: 'Kvemo Kartli', value: 'Kvemo Kartli' },
  { label: 'Kwale', value: 'Kwale' },
  { label: 'Kwango', value: 'Kwango' },
  { label: 'Kwara', value: 'Kwara' },
  { label: 'Kwazulu-Natal', value: 'Kwazulu-Natal' },
  { label: 'Kweneng District', value: 'Kweneng District' },
  { label: 'Kwilu', value: 'Kwilu' },
  { label: 'Kwun Tong', value: 'Kwun Tong' },
  { label: 'KyaTo Prefecture', value: 'KyaTo Prefecture' },
  { label: 'Kyiv', value: 'Kyiv' },
  { label: 'Kyivska Oblast', value: 'Kyivska Oblast' },
  { label: 'Kymenlaakso', value: 'Kymenlaakso' },
  { label: 'Kyrenia District (Keryneia)', value: 'Kyrenia District (Keryneia)' },
  { label: 'Kyustendil Province', value: 'Kyustendil Province' },
  { label: 'Kyzylorda Region', value: 'Kyzylorda Region' },
  { label: 'La Altagracia Province', value: 'La Altagracia Province' },
  { label: 'La Araucans­A', value: 'La Araucans­A' },
  { label: 'La Guaira', value: 'La Guaira' },
  { label: 'La Guajira', value: 'La Guajira' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad Department', value: 'La Libertad Department' },
  { label: 'La Massana', value: 'La Massana' },
  { label: 'La Pampa', value: 'La Pampa' },
  { label: 'La Paz Department', value: 'La Paz Department' },
  { label: 'La Rioja', value: 'La Rioja' },
  { label: 'La Rivisere Anglaise', value: 'La Rivisere Anglaise' },
  { label: 'La Romana Province', value: 'La Romana Province' },
  { label: 'La Unison Department', value: 'La Unison Department' },
  { label: 'La Vega Province', value: 'La Vega Province' },
  { label: 'Laaako Municipality', value: 'Laaako Municipality' },
  { label: 'Laamu Atoll', value: 'Laamu Atoll' },
  { label: 'Laborie Quarter', value: 'Laborie Quarter' },
  { label: 'Labse Region', value: 'Labse Region' },
  { label: 'Labuan', value: 'Labuan' },
  { label: 'Lac', value: 'Lac' },
  { label: 'Lachin District', value: 'Lachin District' },
  { label: 'Lacs Region', value: 'Lacs Region' },
  { label: 'Ladakh', value: 'Ladakh' },
  { label: 'Laegatne Municipality', value: 'Laegatne Municipality' },
  { label: 'LaevaNi Municipality', value: 'LaevaNi Municipality' },
  { label: 'Laghman', value: 'Laghman' },
  { label: 'Laghouat', value: 'Laghouat' },
  { label: 'Lagos', value: 'Lagos' },
  { label: 'Lagunes Region', value: 'Lagunes Region' },
  { label: 'Lahij', value: 'Lahij' },
  { label: 'Lai Chsau', value: 'Lai Chsau' },
  { label: 'Laikipia', value: 'Laikipia' },
  { label: 'Lajas', value: 'Lajas' },
  { label: 'Lakes', value: 'Lakes' },
  { label: 'Lakshadweep', value: 'Lakshadweep' },
  { label: 'Lambayeque', value: 'Lambayeque' },
  { label: 'Lampang', value: 'Lampang' },
  { label: 'Lamphun', value: 'Lamphun' },
  { label: 'Lampung', value: 'Lampung' },
  { label: 'Lamu', value: 'Lamu' },
  { label: 'Lankaran District', value: 'Lankaran District' },
  { label: 'Lapland', value: 'Lapland' },
  { label: 'Lara', value: 'Lara' },
  { label: 'Lares', value: 'Lares' },
  { label: 'Larnaca District (Larnaka)', value: 'Larnaca District (Larnaka)' },
  { label: 'Las Mars­As', value: 'Las Mars­As' },
  { label: 'Las Palmas', value: 'Las Palmas' },
  { label: 'Las Piedras', value: 'Las Piedras' },
  { label: 'Las Tunas Province', value: 'Las Tunas Province' },
  { label: 'Lasayoune-Sakia El Hamra (Eh-Partial)', value: 'Lasayoune-Sakia El Hamra (Eh-Partial)' },
  { label: 'Latakia', value: 'Latakia' },
  { label: 'Lauang Saan', value: 'Lauang Saan' },
  { label: 'Lautsem Municipality', value: 'Lautsem Municipality' },
  { label: 'Lavalleja', value: 'Lavalleja' },
  { label: 'Lazio', value: 'Lazio' },
  { label: 'Lebap Region', value: 'Lebap Region' },
  { label: 'Lefkada Regional Unit', value: 'Lefkada Regional Unit' },
  { label: 'Leinster', value: 'Leinster' },
  { label: 'Leiria', value: 'Leiria' },
  { label: 'Lempira Department', value: 'Lempira Department' },
  { label: 'Lenart Municipality', value: 'Lenart Municipality' },
  { label: 'Lendava Municipality', value: 'Lendava Municipality' },
  { label: 'Leningrad Oblast', value: 'Leningrad Oblast' },
  { label: 'Leribe District', value: 'Leribe District' },
  { label: 'Lerik District', value: 'Lerik District' },
  { label: 'Leson', value: 'Leson' },
  { label: 'Lesser Poland Voivodeship', value: 'Lesser Poland Voivodeship' },
  { label: 'Lezhse County', value: 'Lezhse County' },
  { label: 'Lhaviyani Atoll', value: 'Lhaviyani Atoll' },
  { label: 'Lhuntse District', value: 'Lhuntse District' },
  { label: 'Liaoning', value: 'Liaoning' },
  { label: 'Liberecks½ Kraj', value: 'Liberecks½ Kraj' },
  { label: 'Libertador General Bernardo O Higgins', value: 'Libertador General Bernardo O Higgins' },
  { label: 'LielvaRde Municipality', value: 'LielvaRde Municipality' },
  { label: 'Lienchiang', value: 'Lienchiang' },
  { label: 'LiepaJa', value: 'LiepaJa' },
  { label: 'Liguria', value: 'Liguria' },
  { label: 'Lija', value: 'Lija' },
  { label: 'Lika-Senj', value: 'Lika-Senj' },
  { label: 'Likouala Department', value: 'Likouala Department' },
  { label: 'Lima', value: 'Lima' },
  { label: 'Limassol District (Leymasun)', value: 'Limassol District (Leymasun)' },
  { label: 'Limbaa¾I Municipality', value: 'Limbaa¾I Municipality' },
  { label: 'Limburg', value: 'Limburg' },
  { label: 'Limpopo', value: 'Limpopo' },
  { label: 'Limson Province', value: 'Limson Province' },
  { label: 'Lindi', value: 'Lindi' },
  { label: 'Line Islands', value: 'Line Islands' },
  { label: 'Lipetsk Oblast', value: 'Lipetsk Oblast' },
  { label: 'Lipkovo Municipality', value: 'Lipkovo Municipality' },
  { label: 'Liquis§Sa Municipality', value: 'Liquis§Sa Municipality' },
  { label: 'Lisbon', value: 'Lisbon' },
  { label: 'Litija Municipality', value: 'Litija Municipality' },
  { label: 'Litoral Province', value: 'Litoral Province' },
  { label: 'Littoral', value: 'Littoral' },
  { label: 'Littoral Department', value: 'Littoral Department' },
  { label: 'Ljubljana City Municipality', value: 'Ljubljana City Municipality' },
  { label: 'Ljubno Municipality', value: 'Ljubno Municipality' },
  { label: 'Ljutomer Municipality', value: 'Ljutomer Municipality' },
  { label: 'Lleida', value: 'Lleida' },
  { label: 'Loaaka Dolina Municipality', value: 'Loaaka Dolina Municipality' },
  { label: 'Loaaki Potok Municipality', value: 'Loaaki Potok Municipality' },
  { label: 'Lobaye Prefecture', value: 'Lobaye Prefecture' },
  { label: 'Loei', value: 'Loei' },
  { label: 'Lofa County', value: 'Lofa County' },
  { label: 'Logar', value: 'Logar' },
  { label: 'Logatec Municipality', value: 'Logatec Municipality' },
  { label: 'Logone Occidental', value: 'Logone Occidental' },
  { label: 'Logone Oriental', value: 'Logone Oriental' },
  { label: 'Loja', value: 'Loja' },
  { label: 'Lomami', value: 'Lomami' },
  { label: 'Lombardy', value: 'Lombardy' },
  { label: 'Long An', value: 'Long An' },
  { label: 'Long Island', value: 'Long Island' },
  { label: 'Lop Buri', value: 'Lop Buri' },
  { label: 'Lorestan', value: 'Lorestan' },
  { label: 'Loreto', value: 'Loreto' },
  { label: 'Lori Region', value: 'Lori Region' },
  { label: 'L Oriental', value: 'L Oriental' },
  { label: 'Los Lagos', value: 'Los Lagos' },
  { label: 'Los Rs­Os', value: 'Los Rs­Os' },
  { label: 'Los Santos Province', value: 'Los Santos Province' },
  { label: 'Los­Za', value: 'Los­Za' },
  { label: 'Louga', value: 'Louga' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Lovech Province', value: 'Lovech Province' },
  { label: 'Lovrenc Na Pohorju Municipality', value: 'Lovrenc Na Pohorju Municipality' },
  { label: 'Lower Austria', value: 'Lower Austria' },
  { label: 'Lower Juba', value: 'Lower Juba' },
  { label: 'Lower River Division', value: 'Lower River Division' },
  { label: 'Lower Saxony', value: 'Lower Saxony' },
  { label: 'Lower Shebelle', value: 'Lower Shebelle' },
  { label: 'Lower Silesian Voivodeship', value: 'Lower Silesian Voivodeship' },
  { label: 'Loyalty Islands Province', value: 'Loyalty Islands Province' },
  { label: 'Lozovo Municipality', value: 'Lozovo Municipality' },
  { label: 'Ls O Cai', value: 'Ls O Cai' },
  { label: 'Lsam AA»“Ng', value: 'Lsam AA»“Ng' },
  { label: 'Lsasane County', value: 'Lsasane County' },
  { label: 'Lsasane-Viru County', value: 'Lsasane-Viru County' },
  { label: 'Lsekoumou Department', value: 'Lsekoumou Department' },
  { label: 'LuaE Municipality', value: 'LuaE Municipality' },
  { label: 'Luanda Province', value: 'Luanda Province' },
  { label: 'Luang Namtha Province', value: 'Luang Namtha Province' },
  { label: 'Luang Prabang Province', value: 'Luang Prabang Province' },
  { label: 'Luapula Province', value: 'Luapula Province' },
  { label: 'LubaNa Municipality', value: 'LubaNa Municipality' },
  { label: 'Lublin Voivodeship', value: 'Lublin Voivodeship' },
  { label: 'Lubombo District', value: 'Lubombo District' },
  { label: 'Lubusz Voivodeship', value: 'Lubusz Voivodeship' },
  { label: 'Lucerne', value: 'Lucerne' },
  { label: 'Ludza Municipality', value: 'Ludza Municipality' },
  { label: 'Lugo', value: 'Lugo' },
  { label: 'Luhanska Oblast', value: 'Luhanska Oblast' },
  { label: 'Lukovica Municipality', value: 'Lukovica Municipality' },
  { label: 'Lunda Norte Province', value: 'Lunda Norte Province' },
  { label: 'Lunda Sul Province', value: 'Lunda Sul Province' },
  { label: 'Luqa', value: 'Luqa' },
  { label: 'Luquillo', value: 'Luquillo' },
  { label: 'Lusaka Province', value: 'Lusaka Province' },
  { label: 'Luxor', value: 'Luxor' },
  { label: 'Lvivska Oblast', value: 'Lvivska Oblast' },
  { label: 'MaLpils Municipality', value: 'MaLpils Municipality' },
  { label: 'MaRupe Municipality', value: 'MaRupe Municipality' },
  { label: 'Maaarr', value: 'Maaarr' },
  { label: 'Ma An', value: 'Ma An' },
  { label: 'Machakos', value: 'Machakos' },
  { label: 'Madaba', value: 'Madaba' },
  { label: 'Madang Province', value: 'Madang Province' },
  { label: 'Madeira', value: 'Madeira' },
  { label: 'Madhya Pradesh', value: 'Madhya Pradesh' },
  { label: 'Madinat Ash Shamal', value: 'Madinat Ash Shamal' },
  { label: 'Madona Municipality', value: 'Madona Municipality' },
  { label: 'Madre De Dios', value: 'Madre De Dios' },
  { label: 'Madrid', value: 'Madrid' },
  { label: 'Madriz', value: 'Madriz' },
  { label: 'Mae Hong Son', value: 'Mae Hong Son' },
  { label: 'Maekel Region', value: 'Maekel Region' },
  { label: 'Mafeteng District', value: 'Mafeteng District' },
  { label: 'Mafraq', value: 'Mafraq' },
  { label: 'Magadan Oblast', value: 'Magadan Oblast' },
  { label: 'Magallanes Y De La Antsartica Chilena', value: 'Magallanes Y De La Antsartica Chilena' },
  { label: 'Magdalena', value: 'Magdalena' },
  { label: 'Magway Region', value: 'Magway Region' },
  { label: 'Maha Sarakham', value: 'Maha Sarakham' },
  { label: 'Mahaica-Berbice', value: 'Mahaica-Berbice' },
  { label: 'Maharashtra', value: 'Maharashtra' },
  { label: 'Mahdia', value: 'Mahdia' },
  { label: 'Mai-Ndombe', value: 'Mai-Ndombe' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Maio Municipality', value: 'Maio Municipality' },
  { label: 'Majaaperk Municipality', value: 'Majaaperk Municipality' },
  { label: 'Makamba Province', value: 'Makamba Province' },
  { label: 'Makedonska Kamenica Municipality', value: 'Makedonska Kamenica Municipality' },
  { label: 'Makedonski Brod Municipality', value: 'Makedonski Brod Municipality' },
  { label: 'Makira-Ulawa Province', value: 'Makira-Ulawa Province' },
  { label: 'Makkah', value: 'Makkah' },
  { label: 'Makueni', value: 'Makueni' },
  { label: 'Malacca', value: 'Malacca' },
  { label: 'Malaita Province', value: 'Malaita Province' },
  { label: 'Malampa', value: 'Malampa' },
  { label: 'Malanje Province', value: 'Malanje Province' },
  { label: 'Malatya', value: 'Malatya' },
  { label: 'Maldonado', value: 'Maldonado' },
  { label: 'Maluku', value: 'Maluku' },
  { label: 'Maluku Utara', value: 'Maluku Utara' },
  { label: 'Mambserse-Kadses¯', value: 'Mambserse-Kadses¯' },
  { label: 'Mamou Region', value: 'Mamou Region' },
  { label: 'Manabs­', value: 'Manabs­' },
  { label: 'Managua', value: 'Managua' },
  { label: 'Manats­', value: 'Manats­' },
  { label: 'Manatuto District', value: 'Manatuto District' },
  { label: 'Manawatu-Wanganui Region', value: 'Manawatu-Wanganui Region' },
  { label: 'Manchester Parish', value: 'Manchester Parish' },
  { label: 'Mandalay Region', value: 'Mandalay Region' },
  { label: 'Mandera', value: 'Mandera' },
  { label: 'Mandoul', value: 'Mandoul' },
  { label: 'Mangystau Region', value: 'Mangystau Region' },
  { label: 'Manica Province', value: 'Manica Province' },
  { label: 'Manicaland', value: 'Manicaland' },
  { label: 'Maniema', value: 'Maniema' },
  { label: 'Manipur', value: 'Manipur' },
  { label: 'Manisa', value: 'Manisa' },
  { label: 'Manitoba', value: 'Manitoba' },
  { label: 'Manouba', value: 'Manouba' },
  { label: 'Manufahi Municipality', value: 'Manufahi Municipality' },
  { label: 'Manus Province', value: 'Manus Province' },
  { label: 'Manyara', value: 'Manyara' },
  { label: 'Manzini District', value: 'Manzini District' },
  { label: 'Maputo', value: 'Maputo' },
  { label: 'Maputo Province', value: 'Maputo Province' },
  { label: 'Mara', value: 'Mara' },
  { label: 'Maradi Region', value: 'Maradi Region' },
  { label: 'Maramurea™ County', value: 'Maramurea™ County' },
  { label: 'Maranhs£O', value: 'Maranhs£O' },
  { label: 'Marche', value: 'Marche' },
  { label: 'Mardin', value: 'Mardin' },
  { label: 'Margibi County', value: 'Margibi County' },
  { label: 'Mari El Republic', value: 'Mari El Republic' },
  { label: 'Ma Rib', value: 'Ma Rib' },
  { label: 'Maribor City Municipality', value: 'Maribor City Municipality' },
  { label: 'Maricao', value: 'Maricao' },
  { label: 'Marijampola— Municipality', value: 'Marijampola— Municipality' },
  { label: 'Maritime', value: 'Maritime' },
  { label: 'Marj District', value: 'Marj District' },
  { label: 'Markazi', value: 'Markazi' },
  { label: 'Markovci Municipality', value: 'Markovci Municipality' },
  { label: 'Marlborough Region', value: 'Marlborough Region' },
  { label: 'Marowijne District', value: 'Marowijne District' },
  { label: 'Marsa', value: 'Marsa' },
  { label: 'Mars­A Trinidad Ssanchez Province', value: 'Mars­A Trinidad Ssanchez Province' },
  { label: 'Marsabit', value: 'Marsabit' },
  { label: 'Marsaskala', value: 'Marsaskala' },
  { label: 'Marsaxlokk', value: 'Marsaxlokk' },
  { label: 'Martuni', value: 'Martuni' },
  { label: 'Mary Region', value: 'Mary Region' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Maryland County', value: 'Maryland County' },
  { label: 'Masally District', value: 'Masally District' },
  { label: 'Masaya', value: 'Masaya' },
  { label: 'Mascara', value: 'Mascara' },
  { label: 'Maseru District', value: 'Maseru District' },
  { label: 'Mashonaland Central Province', value: 'Mashonaland Central Province' },
  { label: 'Mashonaland East Province', value: 'Mashonaland East Province' },
  { label: 'Mashonaland West Province', value: 'Mashonaland West Province' },
  { label: 'Masovian Voivodeship', value: 'Masovian Voivodeship' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Masvingo Province', value: 'Masvingo Province' },
  { label: 'Matabeleland North Province', value: 'Matabeleland North Province' },
  { label: 'Matabeleland South Province', value: 'Matabeleland South Province' },
  { label: 'Matagalpa', value: 'Matagalpa' },
  { label: 'Matam', value: 'Matam' },
  { label: 'Matanzas Province', value: 'Matanzas Province' },
  { label: 'Mato Grosso', value: 'Mato Grosso' },
  { label: 'Mato Grosso Do Sul', value: 'Mato Grosso Do Sul' },
  { label: 'Matrouh', value: 'Matrouh' },
  { label: 'Maule', value: 'Maule' },
  { label: 'Maunabo', value: 'Maunabo' },
  { label: 'Mauren', value: 'Mauren' },
  { label: 'Mavrovo And Rostuaaa Municipality', value: 'Mavrovo And Rostuaaa Municipality' },
  { label: 'Mayabeque Province', value: 'Mayabeque Province' },
  { label: 'Mayagsuez', value: 'Mayagsuez' },
  { label: 'Mayaguana District', value: 'Mayaguana District' },
  { label: 'Mayo-Kebbi Est', value: 'Mayo-Kebbi Est' },
  { label: 'Mayo-Kebbi Ouest', value: 'Mayo-Kebbi Ouest' },
  { label: 'Maysan', value: 'Maysan' },
  { label: 'Mazandaran', value: 'Mazandaran' },
  { label: 'Mazsalaca Municipality', value: 'Mazsalaca Municipality' },
  { label: 'Mbeya', value: 'Mbeya' },
  { label: 'Mbomou Prefecture', value: 'Mbomou Prefecture' },
  { label: 'Mea‘Imurje', value: 'Mea‘Imurje' },
  { label: 'Mea¾Ica Municipality', value: 'Mea¾Ica Municipality' },
  { label: 'Mecklenburg-Vorpommern', value: 'Mecklenburg-Vorpommern' },
  { label: 'Medenine', value: 'Medenine' },
  { label: 'Medvode Municipality', value: 'Medvode Municipality' },
  { label: 'Meemu Atoll', value: 'Meemu Atoll' },
  { label: 'Meghalaya', value: 'Meghalaya' },
  { label: 'Mehedina›I County', value: 'Mehedina›I County' },
  { label: 'Melekeok', value: 'Melekeok' },
  { label: 'Mendoza', value: 'Mendoza' },
  { label: 'Meneng District', value: 'Meneng District' },
  { label: 'Mengeaa Municipality', value: 'Mengeaa Municipality' },
  { label: 'Mersin', value: 'Mersin' },
  { label: 'Meru', value: 'Meru' },
  { label: 'Meta', value: 'Meta' },
  { label: 'Metlika Municipality', value: 'Metlika Municipality' },
  { label: 'Metro Manila', value: 'Metro Manila' },
  { label: 'Miaoli', value: 'Miaoli' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Michoacsan De Ocampo', value: 'Michoacsan De Ocampo' },
  { label: 'Micoud Quarter', value: 'Micoud Quarter' },
  { label: 'Middle Juba', value: 'Middle Juba' },
  { label: 'Middle Shebelle', value: 'Middle Shebelle' },
  { label: 'Midlands Province', value: 'Midlands Province' },
  { label: 'Mid-Western Region', value: 'Mid-Western Region' },
  { label: 'Mie Prefecture', value: 'Mie Prefecture' },
  { label: 'Migori', value: 'Migori' },
  { label: 'Miklava¾ Na Dravskem Polju Municipality', value: 'Miklava¾ Na Dravskem Polju Municipality' },
  { label: 'Mila', value: 'Mila' },
  { label: 'Milne Bay Province', value: 'Milne Bay Province' },
  { label: 'Minas Gerais', value: 'Minas Gerais' },
  { label: 'Mingachevir', value: 'Mingachevir' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Minsk', value: 'Minsk' },
  { label: 'Minsk Region', value: 'Minsk Region' },
  { label: 'Minya', value: 'Minya' },
  { label: 'Miranda', value: 'Miranda' },
  { label: 'Mirena€“Kostanjevica Municipality', value: 'Mirena€“Kostanjevica Municipality' },
  { label: 'Mirna Pea Municipality', value: 'Mirna Pea Municipality' },
  { label: 'Misiones', value: 'Misiones' },
  { label: 'Misiones Department', value: 'Misiones Department' },
  { label: 'Mislinja Municipality', value: 'Mislinja Municipality' },
  { label: 'Misrata District', value: 'Misrata District' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Miyagi Prefecture', value: 'Miyagi Prefecture' },
  { label: 'Miyazaki Prefecture', value: 'Miyazaki Prefecture' },
  { label: 'Mizoram', value: 'Mizoram' },
  { label: 'Moca', value: 'Moca' },
  { label: 'Mogila Municipality', value: 'Mogila Municipality' },
  { label: 'Mogilev Region', value: 'Mogilev Region' },
  { label: 'Mohale S Hoek District', value: 'Mohale S Hoek District' },
  { label: 'Mohseli', value: 'Mohseli' },
  { label: 'Mojkovac Municipality', value: 'Mojkovac Municipality' },
  { label: 'Moka', value: 'Moka' },
  { label: 'Mokhotlong District', value: 'Mokhotlong District' },
  { label: 'Molise', value: 'Molise' },
  { label: 'Mombasa', value: 'Mombasa' },
  { label: 'Mon State', value: 'Mon State' },
  { label: 'Monagas', value: 'Monagas' },
  { label: 'Monastir', value: 'Monastir' },
  { label: 'Mondulkiri', value: 'Mondulkiri' },
  { label: 'Mongala', value: 'Mongala' },
  { label: 'Mongar District', value: 'Mongar District' },
  { label: 'Mono Department', value: 'Mono Department' },
  { label: 'Monsesnor Nouel Province', value: 'Monsesnor Nouel Province' },
  { label: 'Montana', value: 'Montana' },
  { label: 'Montana Province', value: 'Montana Province' },
  { label: 'Monte Cristi Province', value: 'Monte Cristi Province' },
  { label: 'Monte Plata Province', value: 'Monte Plata Province' },
  { label: 'Montegiardino', value: 'Montegiardino' },
  { label: 'Montevideo', value: 'Montevideo' },
  { label: 'Montserrado County', value: 'Montserrado County' },
  { label: 'Monufia', value: 'Monufia' },
  { label: 'Mopti Region', value: 'Mopti Region' },
  { label: 'Moquegua', value: 'Moquegua' },
  { label: 'MoravaE Municipality', value: 'MoravaE Municipality' },
  { label: 'Moravske Toplice Municipality', value: 'Moravske Toplice Municipality' },
  { label: 'Moravskoslezsks½ Kraj', value: 'Moravskoslezsks½ Kraj' },
  { label: 'Morazsan Department', value: 'Morazsan Department' },
  { label: 'Morelos', value: 'Morelos' },
  { label: 'Morobe Province', value: 'Morobe Province' },
  { label: 'Morogoro', value: 'Morogoro' },
  { label: 'Morona-Santiago', value: 'Morona-Santiago' },
  { label: 'Morovis', value: 'Morovis' },
  { label: 'Moscow', value: 'Moscow' },
  { label: 'Moscow Oblast', value: 'Moscow Oblast' },
  { label: 'Mostaganem', value: 'Mostaganem' },
  { label: 'Mosteiros', value: 'Mosteiros' },
  { label: 'Mount Lebanon', value: 'Mount Lebanon' },
  { label: 'Moxico Province', value: 'Moxico Province' },
  { label: 'Moyen-Chari', value: 'Moyen-Chari' },
  { label: 'Moyen-Ogoouse Province', value: 'Moyen-Ogoouse Province' },
  { label: 'Mozirje Municipality', value: 'Mozirje Municipality' },
  { label: 'Mpumalanga', value: 'Mpumalanga' },
  { label: 'Msalaga', value: 'Msalaga' },
  { label: 'Msedsea', value: 'Msedsea' },
  { label: 'Mserida', value: 'Mserida' },
  { label: 'Msida', value: 'Msida' },
  { label: 'M Sila', value: 'M Sila' },
  { label: 'Msore Og Romsdal', value: 'Msore Og Romsdal' },
  { label: 'Mtskheta-Mtianeti', value: 'Mtskheta-Mtianeti' },
  { label: 'Mtwara', value: 'Mtwara' },
  { label: 'Muau', value: 'Muau' },
  { label: 'Muaula', value: 'Muaula' },
  { label: 'Mubarak Al-Kabeer', value: 'Mubarak Al-Kabeer' },
  { label: 'Muchinga Province', value: 'Muchinga Province' },
  { label: 'Mudug', value: 'Mudug' },
  { label: 'Muharraq', value: 'Muharraq' },
  { label: 'Mukdahan', value: 'Mukdahan' },
  { label: 'Municipality Of A Kofljica', value: 'Municipality Of A Kofljica' },
  { label: 'Municipality Of ApaaE', value: 'Municipality Of ApaaE' },
  { label: 'Municipality Of Ilirska Bistrica', value: 'Municipality Of Ilirska Bistrica' },
  { label: 'Municipality Of Kraako', value: 'Municipality Of Kraako' },
  { label: 'Munster', value: 'Munster' },
  { label: 'Munxar', value: 'Munxar' },
  { label: 'Muramvya Province', value: 'Muramvya Province' },
  { label: 'Murang A', value: 'Murang A' },
  { label: 'Murcia', value: 'Murcia' },
  { label: 'Murea™ County', value: 'Murea™ County' },
  { label: 'Murmansk Oblast', value: 'Murmansk Oblast' },
  { label: 'Murqub', value: 'Murqub' },
  { label: 'Murska Sobota City Municipality', value: 'Murska Sobota City Municipality' },
  { label: 'Murzuq District', value: 'Murzuq District' },
  { label: 'Musandam', value: 'Musandam' },
  { label: 'Muscat', value: 'Muscat' },
  { label: 'Muta Municipality', value: 'Muta Municipality' },
  { label: 'Muyinga Province', value: 'Muyinga Province' },
  { label: 'Mwanza', value: 'Mwanza' },
  { label: 'Mwaro Province', value: 'Mwaro Province' },
  { label: 'Mykolaivska Oblast', value: 'Mykolaivska Oblast' },
  { label: 'Mymensingh District', value: 'Mymensingh District' },
  { label: 'Naama', value: 'Naama' },
  { label: 'Nabatieh', value: 'Nabatieh' },
  { label: 'Nabeul', value: 'Nabeul' },
  { label: 'Nadur', value: 'Nadur' },
  { label: 'Naeca Municipality', value: 'Naeca Municipality' },
  { label: 'Nagaland', value: 'Nagaland' },
  { label: 'Nagano Prefecture', value: 'Nagano Prefecture' },
  { label: 'Nagasaki Prefecture', value: 'Nagasaki Prefecture' },
  { label: 'Naguabo', value: 'Naguabo' },
  { label: 'Nairobi City', value: 'Nairobi City' },
  { label: 'Najaf', value: 'Najaf' },
  { label: 'Najran', value: 'Najran' },
  { label: 'Nakhchivan Autonomous Republic', value: 'Nakhchivan Autonomous Republic' },
  { label: 'Nakhon Nayok', value: 'Nakhon Nayok' },
  { label: 'Nakhon Pathom', value: 'Nakhon Pathom' },
  { label: 'Nakhon Phanom', value: 'Nakhon Phanom' },
  { label: 'Nakhon Ratchasima', value: 'Nakhon Ratchasima' },
  { label: 'Nakhon Sawan', value: 'Nakhon Sawan' },
  { label: 'Nakhon Si Thammarat', value: 'Nakhon Si Thammarat' },
  { label: 'Naklo Municipality', value: 'Naklo Municipality' },
  { label: 'Nakuru', value: 'Nakuru' },
  { label: 'Nalut District', value: 'Nalut District' },
  { label: 'Nam AA»‹Nh', value: 'Nam AA»‹Nh' },
  { label: 'Namangan Region', value: 'Namangan Region' },
  { label: 'Nampula Province', value: 'Nampula Province' },
  { label: 'Nan', value: 'Nan' },
  { label: 'Nana-Grsebizi Economic Prefecture', value: 'Nana-Grsebizi Economic Prefecture' },
  { label: 'Nana-Mambserse Prefecture', value: 'Nana-Mambserse Prefecture' },
  { label: 'Nandi', value: 'Nandi' },
  { label: 'Nangarhar', value: 'Nangarhar' },
  { label: 'Nantou', value: 'Nantou' },
  { label: 'Nanumanga', value: 'Nanumanga' },
  { label: 'Napo', value: 'Napo' },
  { label: 'Nara Prefecture', value: 'Nara Prefecture' },
  { label: 'Naranjito', value: 'Naranjito' },
  { label: 'Narathiwat', value: 'Narathiwat' },
  { label: 'Narisno', value: 'Narisno' },
  { label: 'Narok', value: 'Narok' },
  { label: 'Naryn Region', value: 'Naryn Region' },
  { label: 'Nasarawa', value: 'Nasarawa' },
  { label: 'Naukaaa“Ni Municipality', value: 'Naukaaa“Ni Municipality' },
  { label: 'Navarra', value: 'Navarra' },
  { label: 'Navoiy Region', value: 'Navoiy Region' },
  { label: 'Naxxar', value: 'Naxxar' },
  { label: 'Nayarit', value: 'Nayarit' },
  { label: 'Naypyidaw Union Territory', value: 'Naypyidaw Union Territory' },
  { label: 'Nazarje Municipality', value: 'Nazarje Municipality' },
  { label: 'N Djamena', value: 'N Djamena' },
  { label: 'Neama› County', value: 'Neama› County' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'Neftchala District', value: 'Neftchala District' },
  { label: 'Negeri Sembilan', value: 'Negeri Sembilan' },
  { label: 'Negotino Municipality', value: 'Negotino Municipality' },
  { label: 'Nelson Region', value: 'Nelson Region' },
  { label: 'Nenets Autonomous Okrug', value: 'Nenets Autonomous Okrug' },
  { label: 'Nereta Municipality', value: 'Nereta Municipality' },
  { label: 'Neuchsatel', value: 'Neuchsatel' },
  { label: 'Neuqusen', value: 'Neuqusen' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'Nevauehir', value: 'Nevauehir' },
  { label: 'New Brunswick', value: 'New Brunswick' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Ireland Province', value: 'New Ireland Province' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'New Providence', value: 'New Providence' },
  { label: 'New South Wales', value: 'New South Wales' },
  { label: 'New Valley', value: 'New Valley' },
  { label: 'New York', value: 'New York' },
  { label: 'Newfoundland And Labrador', value: 'Newfoundland And Labrador' },
  { label: 'Ngaraard', value: 'Ngaraard' },
  { label: 'Ngarchelong', value: 'Ngarchelong' },
  { label: 'Ngardmau', value: 'Ngardmau' },
  { label: 'Ngchesar', value: 'Ngchesar' },
  { label: 'Ngeremlengui', value: 'Ngeremlengui' },
  { label: 'Ngha»‡ An', value: 'Ngha»‡ An' },
  { label: 'Ngiwal', value: 'Ngiwal' },
  { label: 'Ngounise Province', value: 'Ngounise Province' },
  { label: 'Ngozi Province', value: 'Ngozi Province' },
  { label: 'Ngsobe-Buglse Comarca', value: 'Ngsobe-Buglse Comarca' },
  { label: 'Niari Department', value: 'Niari Department' },
  { label: 'Niassa Province', value: 'Niassa Province' },
  { label: 'Niaude', value: 'Niaude' },
  { label: 'Nickerie District', value: 'Nickerie District' },
  { label: 'Nicosia District (Lefkoaua)', value: 'Nicosia District (Lefkoaua)' },
  { label: 'Nidwalden', value: 'Nidwalden' },
  { label: 'Niger', value: 'Niger' },
  { label: 'Niigata Prefecture', value: 'Niigata Prefecture' },
  { label: 'Nikaaia‡ Municipality', value: 'Nikaaia‡ Municipality' },
  { label: 'Nimba', value: 'Nimba' },
  { label: 'Nimruz', value: 'Nimruz' },
  { label: 'Nineveh', value: 'Nineveh' },
  { label: 'Ningxia Huizu', value: 'Ningxia Huizu' },
  { label: 'Ninh Bs¬Nh', value: 'Ninh Bs¬Nh' },
  { label: 'Ninh Thuau­N', value: 'Ninh Thuau­N' },
  { label: 'Nippes', value: 'Nippes' },
  { label: 'Nisporeni District', value: 'Nisporeni District' },
  { label: 'Nitra Region', value: 'Nitra Region' },
  { label: 'Niuas', value: 'Niuas' },
  { label: 'Niutao Island Council', value: 'Niutao Island Council' },
  { label: 'Nizhny Novgorod Oblast', value: 'Nizhny Novgorod Oblast' },
  { label: 'Njombe', value: 'Njombe' },
  { label: 'Nong Bua Lam Phu', value: 'Nong Bua Lam Phu' },
  { label: 'Nong Khai', value: 'Nong Khai' },
  { label: 'Nonthaburi', value: 'Nonthaburi' },
  { label: 'Noonu Atoll', value: 'Noonu Atoll' },
  { label: 'Nord', value: 'Nord' },
  { label: 'Nord Region, Burkina Faso', value: 'Nord Region, Burkina Faso' },
  { label: 'Nord-Est', value: 'Nord-Est' },
  { label: 'Nord-Kivu', value: 'Nord-Kivu' },
  { label: 'Nordland', value: 'Nordland' },
  { label: 'Nord-Ouest', value: 'Nord-Ouest' },
  { label: 'Nord-Ubangi', value: 'Nord-Ubangi' },
  { label: 'Normandie', value: 'Normandie' },
  { label: 'Norrbotten County', value: 'Norrbotten County' },
  { label: 'Norte De Santander', value: 'Norte De Santander' },
  { label: 'North', value: 'North' },
  { label: 'North Abaco', value: 'North Abaco' },
  { label: 'North Andros', value: 'North Andros' },
  { label: 'North Bank Division', value: 'North Bank Division' },
  { label: 'North Brabant', value: 'North Brabant' },
  { label: 'North Caribbean Coast', value: 'North Caribbean Coast' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Central Province', value: 'North Central Province' },
  { label: 'North Chungcheong Province', value: 'North Chungcheong Province' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'North Darfur', value: 'North Darfur' },
  { label: 'North Denmark Region', value: 'North Denmark Region' },
  { label: 'North East', value: 'North East' },
  { label: 'North Gyeongsang Province', value: 'North Gyeongsang Province' },
  { label: 'North Hamgyong Province', value: 'North Hamgyong Province' },
  { label: 'North Holland', value: 'North Holland' },
  { label: 'North Hwanghae Province', value: 'North Hwanghae Province' },
  { label: 'North Jeolla Province', value: 'North Jeolla Province' },
  { label: 'North Karelia', value: 'North Karelia' },
  { label: 'North Kazakhstan Region', value: 'North Kazakhstan Region' },
  { label: 'North Khorasan', value: 'North Khorasan' },
  { label: 'North Kordofan', value: 'North Kordofan' },
  { label: 'North Province', value: 'North Province' },
  { label: 'North Pyongan Province', value: 'North Pyongan Province' },
  { label: 'North Rhine-Westphalia', value: 'North Rhine-Westphalia' },
  { label: 'North Sinai', value: 'North Sinai' },
  { label: 'North West', value: 'North West' },
  { label: 'North Western Province', value: 'North Western Province' },
  { label: 'North-East District', value: 'North-East District' },
  { label: 'Northeastern Region', value: 'Northeastern Region' },
  { label: 'Northern', value: 'Northern' },
  { label: 'Northern Borders', value: 'Northern Borders' },
  { label: 'Northern Cape', value: 'Northern Cape' },
  { label: 'Northern District', value: 'Northern District' },
  { label: 'Northern Division', value: 'Northern Division' },
  { label: 'Northern Ireland', value: 'Northern Ireland' },
  { label: 'Northern Ostrobothnia', value: 'Northern Ostrobothnia' },
  { label: 'Northern Province', value: 'Northern Province' },
  { label: 'Northern Red Sea Region', value: 'Northern Red Sea Region' },
  { label: 'Northern Region', value: 'Northern Region' },
  { label: 'Northern Territory', value: 'Northern Territory' },
  { label: 'Northland Region', value: 'Northland Region' },
  { label: 'Northwest', value: 'Northwest' },
  { label: 'North-West District', value: 'North-West District' },
  { label: 'Northwest Territories', value: 'Northwest Territories' },
  { label: 'Northwestern Province', value: 'Northwestern Province' },
  { label: 'Northwestern Region', value: 'Northwestern Region' },
  { label: 'Nouakchott-Nord', value: 'Nouakchott-Nord' },
  { label: 'Nouakchott-Ouest', value: 'Nouakchott-Ouest' },
  { label: 'Nouakchott-Sud', value: 'Nouakchott-Sud' },
  { label: 'Nouvelle-Aquitaine', value: 'Nouvelle-Aquitaine' },
  { label: 'Nova Gorica City Municipality', value: 'Nova Gorica City Municipality' },
  { label: 'Nova Scotia', value: 'Nova Scotia' },
  { label: 'Novaci Municipality', value: 'Novaci Municipality' },
  { label: 'Novgorod Oblast', value: 'Novgorod Oblast' },
  { label: 'Novo Selo Municipality', value: 'Novo Selo Municipality' },
  { label: 'Novosibirsk', value: 'Novosibirsk' },
  { label: 'Nsogrsad County', value: 'Nsogrsad County' },
  { label: 'Nueva Esparta', value: 'Nueva Esparta' },
  { label: 'Nueva Segovia', value: 'Nueva Segovia' },
  { label: 'Nuevo Leson', value: 'Nuevo Leson' },
  { label: 'Nugal', value: 'Nugal' },
  { label: 'Nui', value: 'Nui' },
  { label: 'Nukufetau', value: 'Nukufetau' },
  { label: 'Nunavut', value: 'Nunavut' },
  { label: 'Nuqat Al Khams', value: 'Nuqat Al Khams' },
  { label: 'Nuristan', value: 'Nuristan' },
  { label: 'Nur-Sultan', value: 'Nur-Sultan' },
  { label: 'Nusa Tenggara Barat', value: 'Nusa Tenggara Barat' },
  { label: 'Nusa Tenggara Timur', value: 'Nusa Tenggara Timur' },
  { label: 'Nyamira', value: 'Nyamira' },
  { label: 'Nyandarua', value: 'Nyandarua' },
  { label: 'Nyanga Province', value: 'Nyanga Province' },
  { label: 'Nyeri', value: 'Nyeri' },
  { label: 'Nzsersekorse Region', value: 'Nzsersekorse Region' },
  { label: 'Oaxaca', value: 'Oaxaca' },
  { label: 'Obock Region', value: 'Obock Region' },
  { label: 'Obwalden', value: 'Obwalden' },
  { label: 'Occidental Mindoro', value: 'Occidental Mindoro' },
  { label: 'Occitanie', value: 'Occitanie' },
  { label: 'Ocnia›A District', value: 'Ocnia›A District' },
  { label: 'Ocotepeque Department', value: 'Ocotepeque Department' },
  { label: 'Oddar Meanchey', value: 'Oddar Meanchey' },
  { label: 'Odeska Oblast', value: 'Odeska Oblast' },
  { label: 'Odisha', value: 'Odisha' },
  { label: 'Odranci Municipality', value: 'Odranci Municipality' },
  { label: 'Oghuz District', value: 'Oghuz District' },
  { label: 'Ogoouse-Ivindo Province', value: 'Ogoouse-Ivindo Province' },
  { label: 'Ogoouse-Lolo Province', value: 'Ogoouse-Lolo Province' },
  { label: 'Ogoouse-Maritime Province', value: 'Ogoouse-Maritime Province' },
  { label: 'Ogre Municipality', value: 'Ogre Municipality' },
  { label: 'Ogun', value: 'Ogun' },
  { label: 'Ohangwena Region', value: 'Ohangwena Region' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Ohrid Municipality', value: 'Ohrid Municipality' },
  { label: 'Oio Region', value: 'Oio Region' },
  { label: 'Okayama Prefecture', value: 'Okayama Prefecture' },
  { label: 'Okinawa Prefecture', value: 'Okinawa Prefecture' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Olaine Municipality', value: 'Olaine Municipality' },
  { label: 'Olancho Department', value: 'Olancho Department' },
  { label: 'Old Royal Capital Cetinje', value: 'Old Royal Capital Cetinje' },
  { label: 'Olomoucks½ Kraj', value: 'Olomoucks½ Kraj' },
  { label: 'Olt County', value: 'Olt County' },
  { label: 'Omaheke Region', value: 'Omaheke Region' },
  { label: 'Ombella-M Poko Prefecture', value: 'Ombella-M Poko Prefecture' },
  { label: 'Omsk Oblast', value: 'Omsk Oblast' },
  { label: 'Omusati Region', value: 'Omusati Region' },
  { label: 'Ondo', value: 'Ondo' },
  { label: 'Ontario', value: 'Ontario' },
  { label: 'Oplotnica', value: 'Oplotnica' },
  { label: 'Opole Voivodeship', value: 'Opole Voivodeship' },
  { label: 'Oran', value: 'Oran' },
  { label: 'Orange Walk District', value: 'Orange Walk District' },
  { label: 'Ordino', value: 'Ordino' },
  { label: 'Ordu', value: 'Ordu' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Orellana', value: 'Orellana' },
  { label: 'Orenburg Oblast', value: 'Orenburg Oblast' },
  { label: 'Orhei District', value: 'Orhei District' },
  { label: 'Oriental Mindoro', value: 'Oriental Mindoro' },
  { label: 'Orkhon Province', value: 'Orkhon Province' },
  { label: 'Ormoa¾ Municipality', value: 'Ormoa¾ Municipality' },
  { label: 'Oro Province', value: 'Oro Province' },
  { label: 'Orocovis', value: 'Orocovis' },
  { label: 'Oromia Region', value: 'Oromia Region' },
  { label: 'Oruro Department', value: 'Oruro Department' },
  { label: 'Oryol Oblast', value: 'Oryol Oblast' },
  { label: 'Osh Region', value: 'Osh Region' },
  { label: 'Oshana Region', value: 'Oshana Region' },
  { label: 'Oshikoto Region', value: 'Oshikoto Region' },
  { label: 'Osijek-Baranja', value: 'Osijek-Baranja' },
  { label: 'Osilnica Municipality', value: 'Osilnica Municipality' },
  { label: 'Oslo', value: 'Oslo' },
  { label: 'Osmaniye', value: 'Osmaniye' },
  { label: 'Ostrobothnia', value: 'Ostrobothnia' },
  { label: 'Osun', value: 'Osun' },
  { label: 'Otago Region', value: 'Otago Region' },
  { label: 'Oti', value: 'Oti' },
  { label: 'Otjozondjupa Region', value: 'Otjozondjupa Region' },
  { label: 'Ouaddas¯', value: 'Ouaddas¯' },
  { label: 'Ouaka Prefecture', value: 'Ouaka Prefecture' },
  { label: 'Ouargla', value: 'Ouargla' },
  { label: 'Oudomxay Province', value: 'Oudomxay Province' },
  { label: 'Oued Ed-Dahab (Eh)', value: 'Oued Ed-Dahab (Eh)' },
  { label: 'Ouest', value: 'Ouest' },
  { label: 'Ouham Prefecture', value: 'Ouham Prefecture' },
  { label: 'Ouham-Pendse Prefecture', value: 'Ouham-Pendse Prefecture' },
  { label: 'Oum El Bouaghi', value: 'Oum El Bouaghi' },
  { label: 'Ourense', value: 'Ourense' },
  { label: 'Ousemse Department', value: 'Ousemse Department' },
  { label: 'Overijssel', value: 'Overijssel' },
  { label: 'Oyo', value: 'Oyo' },
  { label: 'Ozolnieki Municipality', value: 'Ozolnieki Municipality' },
  { label: 'PaRgauja Municipality', value: 'PaRgauja Municipality' },
  { label: 'PaVilosta Municipality', value: 'PaVilosta Municipality' },
  { label: 'Paget', value: 'Paget' },
  { label: 'Pahang', value: 'Pahang' },
  { label: 'Pailin', value: 'Pailin' },
  { label: 'Paktia', value: 'Paktia' },
  { label: 'Paktika', value: 'Paktika' },
  { label: 'Palauli', value: 'Palauli' },
  { label: 'Palencia', value: 'Palencia' },
  { label: 'Pamplemousses', value: 'Pamplemousses' },
  { label: 'Panamsa Oeste Province', value: 'Panamsa Oeste Province' },
  { label: 'Panamsa Province', value: 'Panamsa Province' },
  { label: 'Pando Department', value: 'Pando Department' },
  { label: 'Paneva—A¾Ys District Municipality', value: 'Paneva—A¾Ys District Municipality' },
  { label: 'Panjshir', value: 'Panjshir' },
  { label: 'Paola', value: 'Paola' },
  { label: 'Paphos District (Pafos)', value: 'Paphos District (Pafos)' },
  { label: 'Papua', value: 'Papua' },
  { label: 'Papua Barat', value: 'Papua Barat' },
  { label: 'Para District', value: 'Para District' },
  { label: 'Paraguars­ Department', value: 'Paraguars­ Department' },
  { label: 'Paramaribo District', value: 'Paramaribo District' },
  { label: 'Paransa', value: 'Paransa' },
  { label: 'Paras­Ba', value: 'Paras­Ba' },
  { label: 'Pardubicks½ Kraj', value: 'Pardubicks½ Kraj' },
  { label: 'Paro District', value: 'Paro District' },
  { label: 'Parsa', value: 'Parsa' },
  { label: 'Parwan', value: 'Parwan' },
  { label: 'Pasco', value: 'Pasco' },
  { label: 'Pastaza', value: 'Pastaza' },
  { label: 'Pathum Thani', value: 'Pathum Thani' },
  { label: 'Patillas', value: 'Patillas' },
  { label: 'Pattani', value: 'Pattani' },
  { label: 'Pauavia†As Municipality', value: 'Pauavia†As Municipality' },
  { label: 'Paul', value: 'Paul' },
  { label: 'Pavlodar Region', value: 'Pavlodar Region' },
  { label: 'Paysandsu', value: 'Paysandsu' },
  { label: 'Pays-De-La-Loire', value: 'Pays-De-La-Loire' },
  { label: 'Pazardzhik Province', value: 'Pazardzhik Province' },
  { label: 'Pedernales Province', value: 'Pedernales Province' },
  { label: 'PehaEvo Municipality', value: 'PehaEvo Municipality' },
  { label: 'Peleliu', value: 'Peleliu' },
  { label: 'Peloponnese Region', value: 'Peloponnese Region' },
  { label: 'Pemagatshel District', value: 'Pemagatshel District' },
  { label: 'Pemba North', value: 'Pemba North' },
  { label: 'Pemba South', value: 'Pemba South' },
  { label: 'Pembroke', value: 'Pembroke' },
  { label: 'Penal-Debe Regional Corporation', value: 'Penal-Debe Regional Corporation' },
  { label: 'Penang', value: 'Penang' },
  { label: 'Penghu', value: 'Penghu' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Penza Oblast', value: 'Penza Oblast' },
  { label: 'Perak', value: 'Perak' },
  { label: 'Peravia Province', value: 'Peravia Province' },
  { label: 'Perlis', value: 'Perlis' },
  { label: 'Perm Krai', value: 'Perm Krai' },
  { label: 'Pernambuco', value: 'Pernambuco' },
  { label: 'Pernik Province', value: 'Pernik Province' },
  { label: 'Pesnica Municipality', value: 'Pesnica Municipality' },
  { label: 'Pesnuelas', value: 'Pesnuelas' },
  { label: 'Pest County', value: 'Pest County' },
  { label: 'Petrovec Municipality', value: 'Petrovec Municipality' },
  { label: 'Petsen Department', value: 'Petsen Department' },
  { label: 'Phangnga', value: 'Phangnga' },
  { label: 'Phatthalung', value: 'Phatthalung' },
  { label: 'Phayao', value: 'Phayao' },
  { label: 'Phetchabun', value: 'Phetchabun' },
  { label: 'Phetchaburi', value: 'Phetchaburi' },
  { label: 'Phichit', value: 'Phichit' },
  { label: 'Phitsanulok', value: 'Phitsanulok' },
  { label: 'Phnom Penh', value: 'Phnom Penh' },
  { label: 'Phoenix Islands', value: 'Phoenix Islands' },
  { label: 'Phongsaly Province', value: 'Phongsaly Province' },
  { label: 'Phra Nakhon Si Ayutthaya', value: 'Phra Nakhon Si Ayutthaya' },
  { label: 'Phrae', value: 'Phrae' },
  { label: 'Phsu Tha»', value: 'Phsu Tha»' },
  { label: 'Phsu Ysen', value: 'Phsu Ysen' },
  { label: 'Phuket', value: 'Phuket' },
  { label: 'Piaus­', value: 'Piaus­' },
  { label: 'Pichincha', value: 'Pichincha' },
  { label: 'Piedmont', value: 'Piedmont' },
  { label: 'Piets ', value: 'Piets ' },
  { label: 'Pinar Del Rs­O Province', value: 'Pinar Del Rs­O Province' },
  { label: 'Pingtung', value: 'Pingtung' },
  { label: 'Piran Municipality', value: 'Piran Municipality' },
  { label: 'Pirkanmaa', value: 'Pirkanmaa' },
  { label: 'Piura', value: 'Piura' },
  { label: 'Pivka Municipality', value: 'Pivka Municipality' },
  { label: 'Plaines Wilhems', value: 'Plaines Wilhems' },
  { label: 'Planken', value: 'Planken' },
  { label: 'Plasnica Municipality', value: 'Plasnica Municipality' },
  { label: 'Plateau', value: 'Plateau' },
  { label: 'Plateau Department', value: 'Plateau Department' },
  { label: 'Plateau-Central Region', value: 'Plateau-Central Region' },
  { label: 'Plateaux Department', value: 'Plateaux Department' },
  { label: 'Plateaux Region', value: 'Plateaux Region' },
  { label: 'Plav Municipality', value: 'Plav Municipality' },
  { label: 'Pleven Province', value: 'Pleven Province' },
  { label: 'Pljevlja Municipality', value: 'Pljevlja Municipality' },
  { label: 'Plovdiv Province', value: 'Plovdiv Province' },
  { label: 'Plua¾Ine Municipality', value: 'Plua¾Ine Municipality' },
  { label: 'Plzeaˆ-Jih', value: 'Plzeaˆ-Jih' },
  { label: 'Poa¾Ega-Slavonia', value: 'Poa¾Ega-Slavonia' },
  { label: 'PodaEtrtek Municipality', value: 'PodaEtrtek Municipality' },
  { label: 'Podgorica Municipality', value: 'Podgorica Municipality' },
  { label: 'Podkarpackie Voivodeship', value: 'Podkarpackie Voivodeship' },
  { label: 'Podlaskie Voivodeship', value: 'Podlaskie Voivodeship' },
  { label: 'Podlehnik Municipality', value: 'Podlehnik Municipality' },
  { label: 'Podvelka Municipality', value: 'Podvelka Municipality' },
  { label: 'Pohnpei State', value: 'Pohnpei State' },
  { label: 'Point Fortin', value: 'Point Fortin' },
  { label: 'Pointe-Noire', value: 'Pointe-Noire' },
  { label: 'Poltavska Oblast', value: 'Poltavska Oblast' },
  { label: 'Polzela Municipality', value: 'Polzela Municipality' },
  { label: 'Pomeranian Voivodeship', value: 'Pomeranian Voivodeship' },
  { label: 'Pomeroon-Supenaam', value: 'Pomeroon-Supenaam' },
  { label: 'Ponce', value: 'Ponce' },
  { label: 'Pontevedra', value: 'Pontevedra' },
  { label: 'Pool Department', value: 'Pool Department' },
  { label: 'Port Glaud', value: 'Port Glaud' },
  { label: 'Port Louis', value: 'Port Louis' },
  { label: 'Port Moresby', value: 'Port Moresby' },
  { label: 'Port Of Spain', value: 'Port Of Spain' },
  { label: 'Port Said', value: 'Port Said' },
  { label: 'Portalegre', value: 'Portalegre' },
  { label: 'Portland Parish', value: 'Portland Parish' },
  { label: 'Porto', value: 'Porto' },
  { label: 'Porto Novo', value: 'Porto Novo' },
  { label: 'Portuguesa', value: 'Portuguesa' },
  { label: 'Postojna Municipality', value: 'Postojna Municipality' },
  { label: 'Potaro-Siparuni', value: 'Potaro-Siparuni' },
  { label: 'Potoss­ Department', value: 'Potoss­ Department' },
  { label: 'Prachin Buri', value: 'Prachin Buri' },
  { label: 'Prachuap Khiri Khan', value: 'Prachuap Khiri Khan' },
  { label: 'Praha, Hlavns­ Ma›Sto', value: 'Praha, Hlavns­ Ma›Sto' },
  { label: 'Prahova County', value: 'Prahova County' },
  { label: 'Praia', value: 'Praia' },
  { label: 'Preaaov Region', value: 'Preaaov Region' },
  { label: 'Preah Vihear', value: 'Preah Vihear' },
  { label: 'Prebold Municipality', value: 'Prebold Municipality' },
  { label: 'Preddvor Municipality', value: 'Preddvor Municipality' },
  { label: 'Preiaui Municipality', value: 'Preiaui Municipality' },
  { label: 'Presidente Hayes Department', value: 'Presidente Hayes Department' },
  { label: 'Prevalje Municipality', value: 'Prevalje Municipality' },
  { label: 'Prey Veng', value: 'Prey Veng' },
  { label: 'Priekuaui Municipality', value: 'Priekuaui Municipality' },
  { label: 'Priekule Municipality', value: 'Priekule Municipality' },
  { label: 'Prilep Municipality', value: 'Prilep Municipality' },
  { label: 'Primorje-Gorski Kotar', value: 'Primorje-Gorski Kotar' },
  { label: 'Primorsky Krai', value: 'Primorsky Krai' },
  { label: 'Prince Edward Island', value: 'Prince Edward Island' },
  { label: 'Princes Town Regional Corporation', value: 'Princes Town Regional Corporation' },
  { label: 'Probiaatip Municipality', value: 'Probiaatip Municipality' },
  { label: 'Provence-Alpes-Csote-Da€™Azur', value: 'Provence-Alpes-Csote-Da€™Azur' },
  { label: 'Provincia De Cartago', value: 'Provincia De Cartago' },
  { label: 'Prs­Ncipe Province', value: 'Prs­Ncipe Province' },
  { label: 'Psµlva County', value: 'Psµlva County' },
  { label: 'Psaijsanne Tavastia', value: 'Psaijsanne Tavastia' },
  { label: 'Psarnu County', value: 'Psarnu County' },
  { label: 'Pskov Oblast', value: 'Pskov Oblast' },
  { label: 'Ptuj City Municipality', value: 'Ptuj City Municipality' },
  { label: 'Puconci Municipality', value: 'Puconci Municipality' },
  { label: 'Puducherry', value: 'Puducherry' },
  { label: 'Puebla', value: 'Puebla' },
  { label: 'Puerto Plata Province', value: 'Puerto Plata Province' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Punakha District', value: 'Punakha District' },
  { label: 'Punjab', value: 'Punjab' },
  { label: 'Puno', value: 'Puno' },
  { label: 'Puntarenas Province', value: 'Puntarenas Province' },
  { label: 'Pursat', value: 'Pursat' },
  { label: 'Putrajaya', value: 'Putrajaya' },
  { label: 'Putumayo', value: 'Putumayo' },
  { label: 'Pwani', value: 'Pwani' },
  { label: 'Pyongyang', value: 'Pyongyang' },
  { label: 'Qabala District', value: 'Qabala District' },
  { label: 'Qacha S Nek District', value: 'Qacha S Nek District' },
  { label: 'Qakh District', value: 'Qakh District' },
  { label: 'Qala', value: 'Qala' },
  { label: 'Qalyubia', value: 'Qalyubia' },
  { label: 'Qashqadaryo Region', value: 'Qashqadaryo Region' },
  { label: 'Qazakh District', value: 'Qazakh District' },
  { label: 'Qazvin', value: 'Qazvin' },
  { label: 'Qena', value: 'Qena' },
  { label: 'Qinghai', value: 'Qinghai' },
  { label: 'Qom', value: 'Qom' },
  { label: 'Qormi', value: 'Qormi' },
  { label: 'Qrendi', value: 'Qrendi' },
  { label: 'Quau£Ng Bs¬Nh', value: 'Quau£Ng Bs¬Nh' },
  { label: 'Quau£Ng Nam', value: 'Quau£Ng Nam' },
  { label: 'Quau£Ng Ngs£I', value: 'Quau£Ng Ngs£I' },
  { label: 'Quau£Ng Ninh', value: 'Quau£Ng Ninh' },
  { label: 'Quau£Ng Tra»‹', value: 'Quau£Ng Tra»‹' },
  { label: 'Quba District', value: 'Quba District' },
  { label: 'Qubadli District', value: 'Qubadli District' },
  { label: 'Quebec', value: 'Quebec' },
  { label: 'Quebradillas', value: 'Quebradillas' },
  { label: 'Queensland', value: 'Queensland' },
  { label: 'Quersetaro', value: 'Quersetaro' },
  { label: 'Quetzaltenango Department', value: 'Quetzaltenango Department' },
  { label: 'Quichse Department', value: 'Quichse Department' },
  { label: 'Quinara Region', value: 'Quinara Region' },
  { label: 'Quinds­O', value: 'Quinds­O' },
  { label: 'Quintana Roo', value: 'Quintana Roo' },
  { label: 'Quneitra', value: 'Quneitra' },
  { label: 'Qusar District', value: 'Qusar District' },
  { label: 'Quthing District', value: 'Quthing District' },
  { label: 'Ra“Zekne', value: 'Ra“Zekne' },
  { label: 'Raa Atoll', value: 'Raa Atoll' },
  { label: 'RaaEa€“Fram Municipality', value: 'RaaEa€“Fram Municipality' },
  { label: 'Racha-Lechkhumi And Kvemo Svaneti', value: 'Racha-Lechkhumi And Kvemo Svaneti' },
  { label: 'RadeaE Municipality', value: 'RadeaE Municipality' },
  { label: 'Radenci Municipality', value: 'Radenci Municipality' },
  { label: 'Radlje Ob Dravi Municipality', value: 'Radlje Ob Dravi Municipality' },
  { label: 'Radoviaa Municipality', value: 'Radoviaa Municipality' },
  { label: 'Radovljica Municipality', value: 'Radovljica Municipality' },
  { label: 'Raejiena Municipality', value: 'Raejiena Municipality' },
  { label: 'Ragged Island', value: 'Ragged Island' },
  { label: 'Rajasthan', value: 'Rajasthan' },
  { label: 'Rajshahi District', value: 'Rajshahi District' },
  { label: 'Rakhine State', value: 'Rakhine State' },
  { label: 'Rangpur District', value: 'Rangpur District' },
  { label: 'Rankovce Municipality', value: 'Rankovce Municipality' },
  { label: 'Ranong', value: 'Ranong' },
  { label: 'Rapla County', value: 'Rapla County' },
  { label: 'Ras Al-Khaimah', value: 'Ras Al-Khaimah' },
  { label: 'Rason', value: 'Rason' },
  { label: 'Ratanakiri', value: 'Ratanakiri' },
  { label: 'Ratchaburi', value: 'Ratchaburi' },
  { label: 'Rauna Municipality', value: 'Rauna Municipality' },
  { label: 'Ravenna', value: 'Ravenna' },
  { label: 'Ravne Na Koroaakem Municipality', value: 'Ravne Na Koroaakem Municipality' },
  { label: 'Raymah', value: 'Raymah' },
  { label: 'Rayong', value: 'Rayong' },
  { label: 'Razavi Khorasan', value: 'Razavi Khorasan' },
  { label: 'Razgrad Province', value: 'Razgrad Province' },
  { label: 'Razkria¾Je Municipality', value: 'Razkria¾Je Municipality' },
  { label: 'Red Sea', value: 'Red Sea' },
  { label: 'Region Of Southern Denmark', value: 'Region Of Southern Denmark' },
  { label: 'Region Zealand', value: 'Region Zealand' },
  { label: 'Regison Metropolitana De Santiago', value: 'Regison Metropolitana De Santiago' },
  { label: 'Relizane', value: 'Relizane' },
  { label: 'RenaEa€“Vogrsko Municipality', value: 'RenaEa€“Vogrsko Municipality' },
  { label: 'Republic Of Adygea', value: 'Republic Of Adygea' },
  { label: 'Republic Of Bashkortostan', value: 'Republic Of Bashkortostan' },
  { label: 'Republic Of Buryatia', value: 'Republic Of Buryatia' },
  { label: 'Republic Of Dagestan', value: 'Republic Of Dagestan' },
  { label: 'Republic Of Ingushetia', value: 'Republic Of Ingushetia' },
  { label: 'Republic Of Kalmykia', value: 'Republic Of Kalmykia' },
  { label: 'Republic Of Karelia', value: 'Republic Of Karelia' },
  { label: 'Republic Of Khakassia', value: 'Republic Of Khakassia' },
  { label: 'Republic Of Mordovia', value: 'Republic Of Mordovia' },
  { label: 'Republic Of North Ossetia-Alania', value: 'Republic Of North Ossetia-Alania' },
  { label: 'Republic Of Tatarstan', value: 'Republic Of Tatarstan' },
  { label: 'Republika Srpska', value: 'Republika Srpska' },
  { label: 'Resen Municipality', value: 'Resen Municipality' },
  { label: 'Retalhuleu Department', value: 'Retalhuleu Department' },
  { label: 'Rezina District', value: 'Rezina District' },
  { label: 'Rhineland-Palatinate', value: 'Rhineland-Palatinate' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'Riau', value: 'Riau' },
  { label: 'Ribeira Brava Municipality', value: 'Ribeira Brava Municipality' },
  { label: 'Ribeira Grande', value: 'Ribeira Grande' },
  { label: 'Ribeira Grande De Santiago', value: 'Ribeira Grande De Santiago' },
  { label: 'Ribnica Municipality', value: 'Ribnica Municipality' },
  { label: 'Ribnica Na Pohorju Municipality', value: 'Ribnica Na Pohorju Municipality' },
  { label: 'Riebia†I Municipality', value: 'Riebia†I Municipality' },
  { label: 'Rif Dimashq', value: 'Rif Dimashq' },
  { label: 'Riga', value: 'Riga' },
  { label: 'Rincson', value: 'Rincson' },
  { label: 'Rio De Janeiro', value: 'Rio De Janeiro' },
  { label: 'Rio Grande Do Norte', value: 'Rio Grande Do Norte' },
  { label: 'Rio Grande Do Sul', value: 'Rio Grande Do Sul' },
  { label: 'Risaralda', value: 'Risaralda' },
  { label: 'Rivas', value: 'Rivas' },
  { label: 'River Cess County', value: 'River Cess County' },
  { label: 'River Gee County', value: 'River Gee County' },
  { label: 'River Nile', value: 'River Nile' },
  { label: 'Rivera', value: 'Rivera' },
  { label: 'Rivers', value: 'Rivers' },
  { label: 'Rivisere Du Rempart', value: 'Rivisere Du Rempart' },
  { label: 'Rivnenska Oblast', value: 'Rivnenska Oblast' },
  { label: 'Riyadh', value: 'Riyadh' },
  { label: 'Rize', value: 'Rize' },
  { label: 'Roa¾Aje Municipality', value: 'Roa¾Aje Municipality' },
  { label: 'Rocha', value: 'Rocha' },
  { label: 'Rodrigues Island', value: 'Rodrigues Island' },
  { label: 'Rogaaaka Slatina Municipality', value: 'Rogaaaka Slatina Municipality' },
  { label: 'Rogaaaovci Municipality', value: 'Rogaaaovci Municipality' },
  { label: 'Rogaland', value: 'Rogaland' },
  { label: 'Rogatec Municipality', value: 'Rogatec Municipality' },
  { label: 'Roi Et', value: 'Roi Et' },
  { label: 'Roja Municipality', value: 'Roja Municipality' },
  { label: 'Rondsonia', value: 'Rondsonia' },
  { label: 'Ropaa¾I Municipality', value: 'Ropaa¾I Municipality' },
  { label: 'Roraima', value: 'Roraima' },
  { label: 'Rosoman Municipality', value: 'Rosoman Municipality' },
  { label: 'Rostov Oblast', value: 'Rostov Oblast' },
  { label: 'Rs®A™Cani District', value: 'Rs®A™Cani District' },
  { label: 'Rs­O Grande', value: 'Rs­O Grande' },
  { label: 'Rs­O Negro', value: 'Rs­O Negro' },
  { label: 'Rs­O San Juan', value: 'Rs­O San Juan' },
  { label: 'Ruaae Municipality', value: 'Ruaae Municipality' },
  { label: 'Rucava Municipality', value: 'Rucava Municipality' },
  { label: 'RugaJi Municipality', value: 'RugaJi Municipality' },
  { label: 'Ruggell', value: 'Ruggell' },
  { label: 'Rukwa', value: 'Rukwa' },
  { label: 'Rum Cay District', value: 'Rum Cay District' },
  { label: 'Rumonge Province', value: 'Rumonge Province' },
  { label: 'RundaLe Municipality', value: 'RundaLe Municipality' },
  { label: 'Ruse Province', value: 'Ruse Province' },
  { label: 'Rutana Province', value: 'Rutana Province' },
  { label: 'Ruvuma', value: 'Ruvuma' },
  { label: 'Ruyigi Province', value: 'Ruyigi Province' },
  { label: 'Ryanggang Province', value: 'Ryanggang Province' },
  { label: 'Ryazan Oblast', value: 'Ryazan Oblast' },
  { label: 'S‘Eembucsu Department', value: 'S‘Eembucsu Department' },
  { label: 'S‘Uble', value: 'S‘Uble' },
  { label: 'S…Land Islands', value: 'S…Land Islands' },
  { label: 'S‡Anakkale', value: 'S‡Anakkale' },
  { label: 'S‡Ankanran', value: 'S‡Ankanran' },
  { label: 'S‡Orum', value: 'S‡Orum' },
  { label: 'SNcash', value: 'SNcash' },
  { label: 'SVila', value: 'SVila' },
  { label: 'Sa Kaeo', value: 'Sa Kaeo' },
  { label: 'Sa“Ja Municipality', value: 'Sa“Ja Municipality' },
  { label: 'Saada', value: 'Saada' },
  { label: 'Saan La', value: 'Saan La' },
  { label: 'Saare County', value: 'Saare County' },
  { label: 'Saarland', value: 'Saarland' },
  { label: 'Saatly District', value: 'Saatly District' },
  { label: 'Sabah', value: 'Sabah' },
  { label: 'Sabana Grande', value: 'Sabana Grande' },
  { label: 'Sabaragamuwa Province', value: 'Sabaragamuwa Province' },
  { label: 'Sabha District', value: 'Sabha District' },
  { label: 'Sabirabad District', value: 'Sabirabad District' },
  { label: 'Sacatepsequez Department', value: 'Sacatepsequez Department' },
  { label: 'Safi', value: 'Safi' },
  { label: 'Saƒlaj County', value: 'Saƒlaj County' },
  { label: 'Saga Prefecture', value: 'Saga Prefecture' },
  { label: 'Sagaing Region', value: 'Sagaing Region' },
  { label: 'Sahel Region', value: 'Sahel Region' },
  { label: 'Sai Kung', value: 'Sai Kung' },
  { label: 'Saint Andrew', value: 'Saint Andrew' },
  { label: 'Saint Andrew Parish', value: 'Saint Andrew Parish' },
  { label: 'Saint Ann Parish', value: 'Saint Ann Parish' },
  { label: 'Saint Anne Sandy Point Parish', value: 'Saint Anne Sandy Point Parish' },
  { label: 'Saint Brandon Islands', value: 'Saint Brandon Islands' },
  { label: 'Saint Catherine Parish', value: 'Saint Catherine Parish' },
  { label: 'Saint Croix', value: 'Saint Croix' },
  { label: 'Saint David Parish', value: 'Saint David Parish' },
  { label: 'Saint Elizabeth Parish', value: 'Saint Elizabeth Parish' },
  { label: 'Saint George Gingerland Parish', value: 'Saint George Gingerland Parish' },
  { label: 'Saint George Parish', value: 'Saint George Parish' },
  { label: 'Saint George S', value: 'Saint George S' },
  { label: 'Saint James', value: 'Saint James' },
  { label: 'Saint James Parish', value: 'Saint James Parish' },
  { label: 'Saint James Windward Parish', value: 'Saint James Windward Parish' },
  { label: 'Saint John', value: 'Saint John' },
  { label: 'Saint John Capisterre Parish', value: 'Saint John Capisterre Parish' },
  { label: 'Saint John Figtree Parish', value: 'Saint John Figtree Parish' },
  { label: 'Saint John Parish', value: 'Saint John Parish' },
  { label: 'Saint Joseph', value: 'Saint Joseph' },
  { label: 'Saint Joseph Parish', value: 'Saint Joseph Parish' },
  { label: 'Saint Lawrence', value: 'Saint Lawrence' },
  { label: 'Saint Luke Parish', value: 'Saint Luke Parish' },
  { label: 'Saint Mark Parish', value: 'Saint Mark Parish' },
  { label: 'Saint Mary Cayon Parish', value: 'Saint Mary Cayon Parish' },
  { label: 'Saint Mary Parish', value: 'Saint Mary Parish' },
  { label: 'Saint Michael', value: 'Saint Michael' },
  { label: 'Saint Patrick Parish', value: 'Saint Patrick Parish' },
  { label: 'Saint Paul Capisterre Parish', value: 'Saint Paul Capisterre Parish' },
  { label: 'Saint Paul Charlestown Parish', value: 'Saint Paul Charlestown Parish' },
  { label: 'Saint Paul Parish', value: 'Saint Paul Parish' },
  { label: 'Saint Peter', value: 'Saint Peter' },
  { label: 'Saint Peter Basseterre Parish', value: 'Saint Peter Basseterre Parish' },
  { label: 'Saint Peter Parish', value: 'Saint Peter Parish' },
  { label: 'Saint Petersburg', value: 'Saint Petersburg' },
  { label: 'Saint Philip', value: 'Saint Philip' },
  { label: 'Saint Thomas', value: 'Saint Thomas' },
  { label: 'Saint Thomas Lowland Parish', value: 'Saint Thomas Lowland Parish' },
  { label: 'Saint Thomas Middle Island Parish', value: 'Saint Thomas Middle Island Parish' },
  { label: 'Saint Thomas Parish', value: 'Saint Thomas Parish' },
  { label: 'Saint-Louis', value: 'Saint-Louis' },
  { label: 'Sainyabuli Province', value: 'Sainyabuli Province' },
  { label: 'Saitama Prefecture', value: 'Saitama Prefecture' },
  { label: 'Sakarya', value: 'Sakarya' },
  { label: 'Sakha Republic', value: 'Sakha Republic' },
  { label: 'Sakhalin', value: 'Sakhalin' },
  { label: 'Sakon Nakhon', value: 'Sakon Nakhon' },
  { label: 'Sal', value: 'Sal' },
  { label: 'Salacgraeva Municipality', value: 'Salacgraeva Municipality' },
  { label: 'Saladin', value: 'Saladin' },
  { label: 'Salamanca', value: 'Salamanca' },
  { label: 'Salamat', value: 'Salamat' },
  { label: 'Salaspils Municipality', value: 'Salaspils Municipality' },
  { label: 'Salavan Province', value: 'Salavan Province' },
  { label: 'Saldus Municipality', value: 'Saldus Municipality' },
  { label: 'Salinas', value: 'Salinas' },
  { label: 'Salta', value: 'Salta' },
  { label: 'Salto', value: 'Salto' },
  { label: 'Salyan District', value: 'Salyan District' },
  { label: 'Salzburg', value: 'Salzburg' },
  { label: 'Samangan', value: 'Samangan' },
  { label: 'Samansa Province', value: 'Samansa Province' },
  { label: 'Samara Oblast', value: 'Samara Oblast' },
  { label: 'Samarqand Region', value: 'Samarqand Region' },
  { label: 'Samburu', value: 'Samburu' },
  { label: 'Samdrup Jongkhar District', value: 'Samdrup Jongkhar District' },
  { label: 'Samegrelo-Zemo Svaneti', value: 'Samegrelo-Zemo Svaneti' },
  { label: 'Samsun', value: 'Samsun' },
  { label: 'Samtse District', value: 'Samtse District' },
  { label: 'Samtskhe-Javakheti', value: 'Samtskhe-Javakheti' },
  { label: 'Samukh District', value: 'Samukh District' },
  { label: 'Samut Prakan', value: 'Samut Prakan' },
  { label: 'Samut Sakhon', value: 'Samut Sakhon' },
  { label: 'Samut Songkhram', value: 'Samut Songkhram' },
  { label: 'San A Wann', value: 'San A Wann' },
  { label: 'San Cristsobal Province', value: 'San Cristsobal Province' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Germsan', value: 'San Germsan' },
  { label: 'San Josse', value: 'San Josse' },
  { label: 'San Josse De Ocoa Province', value: 'San Josse De Ocoa Province' },
  { label: 'San Josse Province', value: 'San Josse Province' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan Province', value: 'San Juan Province' },
  { label: 'San Juan-Laventille Regional Corporation', value: 'San Juan-Laventille Regional Corporation' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis Potoss­', value: 'San Luis Potoss­' },
  { label: 'San Marcos Department', value: 'San Marcos Department' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'San Marts­N', value: 'San Marts­N' },
  { label: 'San Miguel Department', value: 'San Miguel Department' },
  { label: 'San Pedro De Macors­S', value: 'San Pedro De Macors­S' },
  { label: 'San Pedro Department', value: 'San Pedro Department' },
  { label: 'San Salvador Department', value: 'San Salvador Department' },
  { label: 'San Salvador Island', value: 'San Salvador Island' },
  { label: 'San Sebastisan', value: 'San Sebastisan' },
  { label: 'San Vicente Department', value: 'San Vicente Department' },
  { label: 'Sana A', value: 'Sana A' },
  { label: 'Sanaag Region', value: 'Sanaag Region' },
  { label: 'Sancti Sps­Ritus Province', value: 'Sancti Sps­Ritus Province' },
  { label: 'Sandaun Province', value: 'Sandaun Province' },
  { label: 'Sandys', value: 'Sandys' },
  { label: 'Sangha Department', value: 'Sangha Department' },
  { label: 'Sangha-Mbaserse', value: 'Sangha-Mbaserse' },
  { label: 'Sangre Grande Regional Corporation', value: 'Sangre Grande Regional Corporation' },
  { label: 'Sankuru', value: 'Sankuru' },
  { label: 'Sanma', value: 'Sanma' },
  { label: 'Sannat', value: 'Sannat' },
  { label: 'Sant Julis  De Lsoria', value: 'Sant Julis  De Lsoria' },
  { label: 'Santa Ana Department', value: 'Santa Ana Department' },
  { label: 'Santa Bsarbara Department', value: 'Santa Bsarbara Department' },
  { label: 'Santa Catarina', value: 'Santa Catarina' },
  { label: 'Santa Catarina Do Fogo', value: 'Santa Catarina Do Fogo' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz De Tenerife', value: 'Santa Cruz De Tenerife' },
  { label: 'Santa Cruz Department', value: 'Santa Cruz Department' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Lua‹Ija', value: 'Santa Lua‹Ija' },
  { label: 'Santa Rosa Department', value: 'Santa Rosa Department' },
  { label: 'Santander', value: 'Santander' },
  { label: 'Santarsem', value: 'Santarsem' },
  { label: 'Santiago De Cuba Province', value: 'Santiago De Cuba Province' },
  { label: 'Santiago Del Estero', value: 'Santiago Del Estero' },
  { label: 'Santiago Province', value: 'Santiago Province' },
  { label: 'Santiago Rodrs­Guez Province', value: 'Santiago Rodrs­Guez Province' },
  { label: 'Santo Domingo De Los Tssachilas', value: 'Santo Domingo De Los Tssachilas' },
  { label: 'Santo Domingo Province', value: 'Santo Domingo Province' },
  { label: 'Saraburi', value: 'Saraburi' },
  { label: 'Saraj Municipality', value: 'Saraj Municipality' },
  { label: 'Saramacca District', value: 'Saramacca District' },
  { label: 'Saratov Oblast', value: 'Saratov Oblast' },
  { label: 'Sarawak', value: 'Sarawak' },
  { label: 'Sardinia', value: 'Sardinia' },
  { label: 'Sar-E Pol', value: 'Sar-E Pol' },
  { label: 'Sarpang District', value: 'Sarpang District' },
  { label: 'Sas¯Da', value: 'Sas¯Da' },
  { label: 'Saskatchewan', value: 'Saskatchewan' },
  { label: 'Sassandra-Marahouse District', value: 'Sassandra-Marahouse District' },
  { label: 'Satakunta', value: 'Satakunta' },
  { label: 'Satu Mare County', value: 'Satu Mare County' },
  { label: 'Satun', value: 'Satun' },
  { label: 'Saulkrasti Municipality', value: 'Saulkrasti Municipality' },
  { label: 'Savanes Region', value: 'Savanes Region' },
  { label: 'Savannah', value: 'Savannah' },
  { label: 'Savannakhet Province', value: 'Savannakhet Province' },
  { label: 'Savanne', value: 'Savanne' },
  { label: 'Saxony', value: 'Saxony' },
  { label: 'Saxony-Anhalt', value: 'Saxony-Anhalt' },
  { label: 'Schaan', value: 'Schaan' },
  { label: 'Schaffhausen', value: 'Schaffhausen' },
  { label: 'Schellenberg', value: 'Schellenberg' },
  { label: 'Schleswig-Holstein', value: 'Schleswig-Holstein' },
  { label: 'Schwyz', value: 'Schwyz' },
  { label: 'Scotland', value: 'Scotland' },
  { label: 'Sea¾Ana Municipality', value: 'Sea¾Ana Municipality' },
  { label: 'Segovia', value: 'Segovia' },
  { label: 'Sejong City', value: 'Sejong City' },
  { label: 'Sekong Province', value: 'Sekong Province' },
  { label: 'Selangor', value: 'Selangor' },
  { label: 'Selenge Province', value: 'Selenge Province' },
  { label: 'Selnica Ob Dravi Municipality', value: 'Selnica Ob Dravi Municipality' },
  { label: 'Semia Municipality', value: 'Semia Municipality' },
  { label: 'Semnan', value: 'Semnan' },
  { label: 'Senglea', value: 'Senglea' },
  { label: 'Sennar', value: 'Sennar' },
  { label: 'Seoul', value: 'Seoul' },
  { label: 'Sequateur', value: 'Sequateur' },
  { label: 'Sergipe', value: 'Sergipe' },
  { label: 'Serravalle', value: 'Serravalle' },
  { label: 'Setsubal', value: 'Setsubal' },
  { label: 'Sevastopol', value: 'Sevastopol' },
  { label: 'Sevilla', value: 'Sevilla' },
  { label: 'Sevnica Municipality', value: 'Sevnica Municipality' },
  { label: 'Sevora', value: 'Sevora' },
  { label: 'Sfax', value: 'Sfax' },
  { label: 'Sha Tin', value: 'Sha Tin' },
  { label: 'Shaanxi', value: 'Shaanxi' },
  { label: 'Shabran District', value: 'Shabran District' },
  { label: 'Shabwah', value: 'Shabwah' },
  { label: 'Shaki', value: 'Shaki' },
  { label: 'Shaki District', value: 'Shaki District' },
  { label: 'Sham Shui Po', value: 'Sham Shui Po' },
  { label: 'Shamakhi District', value: 'Shamakhi District' },
  { label: 'Shamkir District', value: 'Shamkir District' },
  { label: 'Shan State', value: 'Shan State' },
  { label: 'Shandong', value: 'Shandong' },
  { label: 'Shanghai', value: 'Shanghai' },
  { label: 'Shanxi', value: 'Shanxi' },
  { label: 'Sharjah Emirate', value: 'Sharjah Emirate' },
  { label: 'Sharqia', value: 'Sharqia' },
  { label: 'Shaviyani Atoll', value: 'Shaviyani Atoll' },
  { label: 'Shefa', value: 'Shefa' },
  { label: 'Shida Kartli', value: 'Shida Kartli' },
  { label: 'Shiga Prefecture', value: 'Shiga Prefecture' },
  { label: 'Shimane Prefecture', value: 'Shimane Prefecture' },
  { label: 'Shinyanga', value: 'Shinyanga' },
  { label: 'Shirak Region', value: 'Shirak Region' },
  { label: 'Shirvan', value: 'Shirvan' },
  { label: 'Shiselweni District', value: 'Shiselweni District' },
  { label: 'Shizuoka Prefecture', value: 'Shizuoka Prefecture' },
  { label: 'Shkodser District', value: 'Shkodser District' },
  { label: 'Shumen', value: 'Shumen' },
  { label: 'Shusha District', value: 'Shusha District' },
  { label: 'Si Sa Ket', value: 'Si Sa Ket' },
  { label: 'Siaaaaiewi', value: 'Siaaaaiewi' },
  { label: 'Siaya', value: 'Siaya' },
  { label: 'Siazan District', value: 'Siazan District' },
  { label: 'Sibiu County', value: 'Sibiu County' },
  { label: 'Sichuan', value: 'Sichuan' },
  { label: 'Sicily', value: 'Sicily' },
  { label: 'Sidi Bel Abbses', value: 'Sidi Bel Abbses' },
  { label: 'Sidi Bouzid', value: 'Sidi Bouzid' },
  { label: 'Siem Reap', value: 'Siem Reap' },
  { label: 'Sigulda Municipality', value: 'Sigulda Municipality' },
  { label: 'Sihanoukville', value: 'Sihanoukville' },
  { label: 'Siirt', value: 'Siirt' },
  { label: 'Sikasso Region', value: 'Sikasso Region' },
  { label: 'Sikkim', value: 'Sikkim' },
  { label: 'Sila', value: 'Sila' },
  { label: 'Silesian Voivodeship', value: 'Silesian Voivodeship' },
  { label: 'Siliana', value: 'Siliana' },
  { label: 'Silistra Province', value: 'Silistra Province' },
  { label: 'Simiyu', value: 'Simiyu' },
  { label: 'Sinaloa', value: 'Sinaloa' },
  { label: 'Sindh', value: 'Sindh' },
  { label: 'Sing Buri', value: 'Sing Buri' },
  { label: 'Singida', value: 'Singida' },
  { label: 'Sinoe County', value: 'Sinoe County' },
  { label: 'Sinop', value: 'Sinop' },
  { label: 'Siparia Regional Corporation', value: 'Siparia Regional Corporation' },
  { label: 'Sirdaryo Region', value: 'Sirdaryo Region' },
  { label: 'Sirte District', value: 'Sirte District' },
  { label: 'Sisak-Moslavina', value: 'Sisak-Moslavina' },
  { label: 'Sistan And Baluchestan', value: 'Sistan And Baluchestan' },
  { label: 'Sivas', value: 'Sivas' },
  { label: 'Skikda', value: 'Skikda' },
  { label: 'Skraeveri Municipality', value: 'Skraeveri Municipality' },
  { label: 'Skrunda Municipality', value: 'Skrunda Municipality' },
  { label: 'Sksane County', value: 'Sksane County' },
  { label: 'Sliema', value: 'Sliema' },
  { label: 'Sliven Province', value: 'Sliven Province' },
  { label: 'Slovenj Gradec City Municipality', value: 'Slovenj Gradec City Municipality' },
  { label: 'Slovenska Bistrica Municipality', value: 'Slovenska Bistrica Municipality' },
  { label: 'Slovenske Konjice Municipality', value: 'Slovenske Konjice Municipality' },
  { label: 'Smiltene Municipality', value: 'Smiltene Municipality' },
  { label: 'Smith S', value: 'Smith S' },
  { label: 'S–Mnsogovi Province', value: 'S–Mnsogovi Province' },
  { label: 'Smolensk Oblast', value: 'Smolensk Oblast' },
  { label: 'Smolyan Province', value: 'Smolyan Province' },
  { label: 'Soccsksargen', value: 'Soccsksargen' },
  { label: 'Socotra', value: 'Socotra' },
  { label: 'Sodraa¾Ica Municipality', value: 'Sodraa¾Ica Municipality' },
  { label: 'Sofala Province', value: 'Sofala Province' },
  { label: 'Sofia City Province', value: 'Sofia City Province' },
  { label: 'Sofia Province', value: 'Sofia Province' },
  { label: 'Sohag', value: 'Sohag' },
  { label: 'Sokoto', value: 'Sokoto' },
  { label: 'SolaAva Municipality', value: 'SolaAva Municipality' },
  { label: 'Sololsa Department', value: 'Sololsa Department' },
  { label: 'Solothurn', value: 'Solothurn' },
  { label: 'Somali Region', value: 'Somali Region' },
  { label: 'Somogy County', value: 'Somogy County' },
  { label: 'Songkhla', value: 'Songkhla' },
  { label: 'Sonora', value: 'Sonora' },
  { label: 'Sonsonate Department', value: 'Sonsonate Department' },
  { label: 'Sonsorol', value: 'Sonsorol' },
  { label: 'Sopiaate Municipality', value: 'Sopiaate Municipality' },
  { label: 'Soria', value: 'Soria' },
  { label: 'Soriano', value: 'Soriano' },
  { label: 'Soroca District', value: 'Soroca District' },
  { label: 'Soufrisere Quarter', value: 'Soufrisere Quarter' },
  { label: 'Souk Ahras', value: 'Souk Ahras' },
  { label: 'Sousse', value: 'Sousse' },
  { label: 'South', value: 'South' },
  { label: 'South Aegean', value: 'South Aegean' },
  { label: 'South Australia', value: 'South Australia' },
  { label: 'South Caribbean Coast', value: 'South Caribbean Coast' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Central Province', value: 'South Central Province' },
  { label: 'South Chungcheong Province', value: 'South Chungcheong Province' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'South Darfur', value: 'South Darfur' },
  { label: 'South East', value: 'South East' },
  { label: 'South Gyeongsang Province', value: 'South Gyeongsang Province' },
  { label: 'South Hamgyong Province', value: 'South Hamgyong Province' },
  { label: 'South Holland', value: 'South Holland' },
  { label: 'South Hwanghae Province', value: 'South Hwanghae Province' },
  { label: 'South Jeolla Province', value: 'South Jeolla Province' },
  { label: 'South Karelia', value: 'South Karelia' },
  { label: 'South Khorasan', value: 'South Khorasan' },
  { label: 'South Kordofan', value: 'South Kordofan' },
  { label: 'South Province', value: 'South Province' },
  { label: 'South Pyongan Province', value: 'South Pyongan Province' },
  { label: 'South Sinai', value: 'South Sinai' },
  { label: 'South West', value: 'South West' },
  { label: 'Southampton', value: 'Southampton' },
  { label: 'South-East District', value: 'South-East District' },
  { label: 'Southern', value: 'Southern' },
  { label: 'Southern District', value: 'Southern District' },
  { label: 'Southern Highlands Province', value: 'Southern Highlands Province' },
  { label: 'Southern Nations, Nationalities, And Peoples Region', value: 'Southern Nations, Nationalities, And Peoples Region' },
  { label: 'Southern Ostrobothnia', value: 'Southern Ostrobothnia' },
  { label: 'Southern Peninsula Region', value: 'Southern Peninsula Region' },
  { label: 'Southern Province', value: 'Southern Province' },
  { label: 'Southern Red Sea Region', value: 'Southern Red Sea Region' },
  { label: 'Southern Region', value: 'Southern Region' },
  { label: 'Southern Savonia', value: 'Southern Savonia' },
  { label: 'Southland Region', value: 'Southland Region' },
  { label: 'Southwest', value: 'Southwest' },
  { label: 'Spanish Wells', value: 'Spanish Wells' },
  { label: 'Split-Dalmatia', value: 'Split-Dalmatia' },
  { label: 'S–Rebro County', value: 'S–Rebro County' },
  { label: 'SrediaaaE Ob Dravi', value: 'SrediaaaE Ob Dravi' },
  { label: 'Ss£O Domingos', value: 'Ss£O Domingos' },
  { label: 'Ss£O Filipe', value: 'Ss£O Filipe' },
  { label: 'Ss£O Lourens§O Dos S“Rgs£Os', value: 'Ss£O Lourens§O Dos S“Rgs£Os' },
  { label: 'Ss£O Miguel', value: 'Ss£O Miguel' },
  { label: 'Ss£O Paulo', value: 'Ss£O Paulo' },
  { label: 'Ss£O Tomse Province', value: 'Ss£O Tomse Province' },
  { label: 'Ss£O Vicente', value: 'Ss£O Vicente' },
  { label: 'Ss®Ngerei District', value: 'Ss®Ngerei District' },
  { label: 'Ssanchez Rams­Rez Province', value: 'Ssanchez Rams­Rez Province' },
  { label: 'Ssedhiou', value: 'Ssedhiou' },
  { label: 'Ssegou Region', value: 'Ssegou Region' },
  { label: 'Ssetif', value: 'Ssetif' },
  { label: 'Ssoc Traƒng', value: 'Ssoc Traƒng' },
  { label: 'Ssodermanland County', value: 'Ssodermanland County' },
  { label: 'Ssstecks½ Kraj', value: 'Ssstecks½ Kraj' },
  { label: 'S–Stergsotland County', value: 'S–Stergsotland County' },
  { label: 'Ssukhbaatar Province', value: 'Ssukhbaatar Province' },
  { label: 'St. Gallen', value: 'St. Gallen' },
  { label: 'St. Julian S', value: 'St. Julian S' },
  { label: 'St. Paul S Bay', value: 'St. Paul S Bay' },
  { label: 'Stann Creek District', value: 'Stann Creek District' },
  { label: 'Stara Zagora Province', value: 'Stara Zagora Province' },
  { label: 'Staraae Municipality', value: 'Staraae Municipality' },
  { label: 'Staro NagoriaAne Municipality', value: 'Staro NagoriaAne Municipality' },
  { label: 'State_Name', value: 'State_Name' },
  { label: 'Sta™EdoaEsks½ Kraj', value: 'Sta™EdoaEsks½ Kraj' },
  { label: 'Stavropol Krai', value: 'Stavropol Krai' },
  { label: 'Stockholm County', value: 'Stockholm County' },
  { label: 'Stopia†I Municipality', value: 'Stopia†I Municipality' },
  { label: 'Straa¾A Municipality', value: 'Straa¾A Municipality' },
  { label: 'Straƒa™Eni District', value: 'Straƒa™Eni District' },
  { label: 'StrenaI Municipality', value: 'StrenaI Municipality' },
  { label: 'Struga Municipality', value: 'Struga Municipality' },
  { label: 'Strumica Municipality', value: 'Strumica Municipality' },
  { label: 'StudeniaAni Municipality', value: 'StudeniaAni Municipality' },
  { label: 'Stung Treng', value: 'Stung Treng' },
  { label: 'Styria', value: 'Styria' },
  { label: 'Suceava County', value: 'Suceava County' },
  { label: 'Suchitepsequez Department', value: 'Suchitepsequez Department' },
  { label: 'Sucre', value: 'Sucre' },
  { label: 'Sucumbs­Os', value: 'Sucumbs­Os' },
  { label: 'Sud', value: 'Sud' },
  { label: 'Sud-Est', value: 'Sud-Est' },
  { label: 'Sud-Kivu', value: 'Sud-Kivu' },
  { label: 'Sud-Ouest Region', value: 'Sud-Ouest Region' },
  { label: 'Sud-Ubangi', value: 'Sud-Ubangi' },
  { label: 'Suez', value: 'Suez' },
  { label: 'Sughd Province', value: 'Sughd Province' },
  { label: 'Sukhothai', value: 'Sukhothai' },
  { label: 'Sulawesi Barat', value: 'Sulawesi Barat' },
  { label: 'Sulawesi Selatan', value: 'Sulawesi Selatan' },
  { label: 'Sulawesi Tengah', value: 'Sulawesi Tengah' },
  { label: 'Sulawesi Tenggara', value: 'Sulawesi Tenggara' },
  { label: 'Sulawesi Utara', value: 'Sulawesi Utara' },
  { label: 'Sulaymaniyah', value: 'Sulaymaniyah' },
  { label: 'Sumatera Barat', value: 'Sumatera Barat' },
  { label: 'Sumatera Selatan', value: 'Sumatera Selatan' },
  { label: 'Sumatera Utara', value: 'Sumatera Utara' },
  { label: 'Sumqayit', value: 'Sumqayit' },
  { label: 'Sumska Oblast', value: 'Sumska Oblast' },
  { label: 'Suphan Buri', value: 'Suphan Buri' },
  { label: 'Surat Thani', value: 'Surat Thani' },
  { label: 'Surin', value: 'Surin' },
  { label: 'Surxondaryo Region', value: 'Surxondaryo Region' },
  { label: 'Svay Rieng', value: 'Svay Rieng' },
  { label: 'Sverdlovsk', value: 'Sverdlovsk' },
  { label: 'Sveta Ana Municipality', value: 'Sveta Ana Municipality' },
  { label: 'Sveta Trojica V Slovenskih Goricah Municipality', value: 'Sveta Trojica V Slovenskih Goricah Municipality' },
  { label: 'Sveti Andraa¾ V Slovenskih Goricah Municipality', value: 'Sveti Andraa¾ V Slovenskih Goricah Municipality' },
  { label: 'Sveti Jurij V Slovenskih Goricah Municipality', value: 'Sveti Jurij V Slovenskih Goricah Municipality' },
  { label: 'Sveti Nikole Municipality', value: 'Sveti Nikole Municipality' },
  { label: 'Sveti Tomaa¾ Municipality', value: 'Sveti Tomaa¾ Municipality' },
  { label: 'S–Vsorkhangai Province', value: 'S–Vsorkhangai Province' },
  { label: 'Swieqi', value: 'Swieqi' },
  { label: 'Sylhet District', value: 'Sylhet District' },
  { label: 'Syunik Province', value: 'Syunik Province' },
  { label: 'Szabolcs-Szatmsar-Bereg County', value: 'Szabolcs-Szatmsar-Bereg County' },
  { label: 'Szle-De-France', value: 'Szle-De-France' },
  { label: 'Ta Xbiex', value: 'Ta Xbiex' },
  { label: 'Ta“Rvete Municipality', value: 'Ta“Rvete Municipality' },
  { label: 'Tabasco', value: 'Tabasco' },
  { label: 'Tabor Municipality', value: 'Tabor Municipality' },
  { label: 'Tabora', value: 'Tabora' },
  { label: 'Tabuk', value: 'Tabuk' },
  { label: 'Tacna', value: 'Tacna' },
  { label: 'Tacuarembso', value: 'Tacuarembso' },
  { label: 'Tadjourah Region', value: 'Tadjourah Region' },
  { label: 'Tafea', value: 'Tafea' },
  { label: 'Tafilah', value: 'Tafilah' },
  { label: 'Tahoua Region', value: 'Tahoua Region' },
  { label: 'Tai Po', value: 'Tai Po' },
  { label: 'Taichung', value: 'Taichung' },
  { label: 'Tainan', value: 'Tainan' },
  { label: 'Taipei', value: 'Taipei' },
  { label: 'Taitung', value: 'Taitung' },
  { label: 'Taiwan', value: 'Taiwan' },
  { label: 'TaIzz', value: 'TaIzz' },
  { label: 'Tak', value: 'Tak' },
  { label: 'Takamaka', value: 'Takamaka' },
  { label: 'Takeo', value: 'Takeo' },
  { label: 'Takhar', value: 'Takhar' },
  { label: 'Talas Region', value: 'Talas Region' },
  { label: 'Talsi Municipality', value: 'Talsi Municipality' },
  { label: 'Tamanghasset', value: 'Tamanghasset' },
  { label: 'Tamaulipas', value: 'Tamaulipas' },
  { label: 'Tambacounda Region', value: 'Tambacounda Region' },
  { label: 'Tambov Oblast', value: 'Tambov Oblast' },
  { label: 'Tamil Nadu', value: 'Tamil Nadu' },
  { label: 'Tana River', value: 'Tana River' },
  { label: 'Tandjilse', value: 'Tandjilse' },
  { label: 'Tanga', value: 'Tanga' },
  { label: 'Tanganyika', value: 'Tanganyika' },
  { label: 'Tanger-Tsetouan-Al Hoces¯Ma', value: 'Tanger-Tsetouan-Al Hoces¯Ma' },
  { label: 'Tanintharyi Region', value: 'Tanintharyi Region' },
  { label: 'Taoyuan', value: 'Taoyuan' },
  { label: 'Taraba', value: 'Taraba' },
  { label: 'Taraclia District', value: 'Taraclia District' },
  { label: 'Taranaki Region', value: 'Taranaki Region' },
  { label: 'Tarapacsa', value: 'Tarapacsa' },
  { label: 'Targovishte Province', value: 'Targovishte Province' },
  { label: 'Tarija Department', value: 'Tarija Department' },
  { label: 'Tarrafal', value: 'Tarrafal' },
  { label: 'Tarrafal De Ss£O Nicolau', value: 'Tarrafal De Ss£O Nicolau' },
  { label: 'Tarragona', value: 'Tarragona' },
  { label: 'Tartar District', value: 'Tartar District' },
  { label: 'Tartu County', value: 'Tartu County' },
  { label: 'Tartus', value: 'Tartus' },
  { label: 'Tarxien', value: 'Tarxien' },
  { label: 'Tashkent', value: 'Tashkent' },
  { label: 'Tashkent Region', value: 'Tashkent Region' },
  { label: 'Tasman District', value: 'Tasman District' },
  { label: 'Tasmania', value: 'Tasmania' },
  { label: 'Tataouine', value: 'Tataouine' },
  { label: 'Tauraga— County', value: 'Tauraga— County' },
  { label: 'Tavastia Proper', value: 'Tavastia Proper' },
  { label: 'Tavush Region', value: 'Tavush Region' },
  { label: 'Tbilisi', value: 'Tbilisi' },
  { label: 'Tearce Municipality', value: 'Tearce Municipality' },
  { label: 'Tehran', value: 'Tehran' },
  { label: 'Tekirdaau', value: 'Tekirdaau' },
  { label: 'Tel Aviv District', value: 'Tel Aviv District' },
  { label: 'Telaaiai District Municipality', value: 'Telaaiai District Municipality' },
  { label: 'Telangana', value: 'Telangana' },
  { label: 'Telenea™Ti District', value: 'Telenea™Ti District' },
  { label: 'Teleorman County', value: 'Teleorman County' },
  { label: 'Temburong District', value: 'Temburong District' },
  { label: 'Temotu Province', value: 'Temotu Province' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Terengganu', value: 'Terengganu' },
  { label: 'Ternopilska Oblast', value: 'Ternopilska Oblast' },
  { label: 'Teruel', value: 'Teruel' },
  { label: 'Tete Province', value: 'Tete Province' },
  { label: 'Tetovo Municipality', value: 'Tetovo Municipality' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Tha»Ea Thisen-Huau¿', value: 'Tha»Ea Thisen-Huau¿' },
  { label: 'Thaa Atoll', value: 'Thaa Atoll' },
  { label: 'Thaba-Tseka District', value: 'Thaba-Tseka District' },
  { label: 'Thanh Hsoa', value: 'Thanh Hsoa' },
  { label: 'Tharaka-Nithi', value: 'Tharaka-Nithi' },
  { label: 'Thimphu District', value: 'Thimphu District' },
  { label: 'Thises Region', value: 'Thises Region' },
  { label: 'Thsai Bs¬Nh', value: 'Thsai Bs¬Nh' },
  { label: 'Thsai Nguysen', value: 'Thsai Nguysen' },
  { label: 'Thurgau', value: 'Thurgau' },
  { label: 'Thuringia', value: 'Thuringia' },
  { label: 'Tia»N Giang', value: 'Tia»N Giang' },
  { label: 'Tiaaina Municipality', value: 'Tiaaina Municipality' },
  { label: 'Tianjin', value: 'Tianjin' },
  { label: 'Tiaret', value: 'Tiaret' },
  { label: 'Tibesti', value: 'Tibesti' },
  { label: 'Ticino', value: 'Ticino' },
  { label: 'Tierra Del Fuego', value: 'Tierra Del Fuego' },
  { label: 'Tigray Region', value: 'Tigray Region' },
  { label: 'Tillabseri Region', value: 'Tillabseri Region' },
  { label: 'Timia™ County', value: 'Timia™ County' },
  { label: 'Tindouf', value: 'Tindouf' },
  { label: 'Tipasa', value: 'Tipasa' },
  { label: 'Tirana District', value: 'Tirana District' },
  { label: 'Tiris Zemmour', value: 'Tiris Zemmour' },
  { label: 'Tissemsilt', value: 'Tissemsilt' },
  { label: 'Tivat Municipality', value: 'Tivat Municipality' },
  { label: 'Tizi Ouzou', value: 'Tizi Ouzou' },
  { label: 'Tlaxcala', value: 'Tlaxcala' },
  { label: 'Tlemcen', value: 'Tlemcen' },
  { label: 'Toa Alta', value: 'Toa Alta' },
  { label: 'Toa Baja', value: 'Toa Baja' },
  { label: 'Tocantins', value: 'Tocantins' },
  { label: 'Tochigi Prefecture', value: 'Tochigi Prefecture' },
  { label: 'Togdheer Region', value: 'Togdheer Region' },
  { label: 'Tokat', value: 'Tokat' },
  { label: 'Tokushima Prefecture', value: 'Tokushima Prefecture' },
  { label: 'Tokyo', value: 'Tokyo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo District', value: 'Toledo District' },
  { label: 'Tolima', value: 'Tolima' },
  { label: 'Tolmin Municipality', value: 'Tolmin Municipality' },
  { label: 'Tolna County', value: 'Tolna County' },
  { label: 'Tombali Region', value: 'Tombali Region' },
  { label: 'Tombouctou Region', value: 'Tombouctou Region' },
  { label: 'Tomsk Oblast', value: 'Tomsk Oblast' },
  { label: 'Tongatapu', value: 'Tongatapu' },
  { label: 'Torba', value: 'Torba' },
  { label: 'Totonicapsan Department', value: 'Totonicapsan Department' },
  { label: 'Tottori Prefecture', value: 'Tottori Prefecture' },
  { label: 'Tovuz District', value: 'Tovuz District' },
  { label: 'Toyama Prefecture', value: 'Toyama Prefecture' },
  { label: 'Tozeur', value: 'Tozeur' },
  { label: 'Tra¾Ia Municipality', value: 'Tra¾Ia Municipality' },
  { label: 'Trabzon', value: 'Trabzon' },
  { label: 'Trang', value: 'Trang' },
  { label: 'Trans Nzoia', value: 'Trans Nzoia' },
  { label: 'Transnistria Autonomous Territorial Unit', value: 'Transnistria Autonomous Territorial Unit' },
  { label: 'Trarza', value: 'Trarza' },
  { label: 'Trashigang District', value: 'Trashigang District' },
  { label: 'Trat', value: 'Trat' },
  { label: 'Trbovlje Municipality', value: 'Trbovlje Municipality' },
  { label: 'Trebnje Municipality', value: 'Trebnje Municipality' },
  { label: 'Treinta Y Tres', value: 'Treinta Y Tres' },
  { label: 'Trelawny Parish', value: 'Trelawny Parish' },
  { label: 'TrenaS­N Region', value: 'TrenaS­N Region' },
  { label: 'Trentino-South Tyrol', value: 'Trentino-South Tyrol' },
  { label: 'Triesen', value: 'Triesen' },
  { label: 'Triesenberg', value: 'Triesenberg' },
  { label: 'Trinity Palmetto Point Parish', value: 'Trinity Palmetto Point Parish' },
  { label: 'Tripoli District', value: 'Tripoli District' },
  { label: 'Tripura', value: 'Tripura' },
  { label: 'Trnava Region', value: 'Trnava Region' },
  { label: 'Trnovska Vas Municipality', value: 'Trnovska Vas Municipality' },
  { label: 'Troms Og Finnmark', value: 'Troms Og Finnmark' },
  { label: 'Trs  Vinh', value: 'Trs  Vinh' },
  { label: 'Trsondelag', value: 'Trsondelag' },
  { label: 'Trujillo', value: 'Trujillo' },
  { label: 'Trujillo Alto', value: 'Trujillo Alto' },
  { label: 'Trzin Municipality', value: 'Trzin Municipality' },
  { label: 'Tsachira', value: 'Tsachira' },
  { label: 'Tsay Ninh', value: 'Tsay Ninh' },
  { label: 'Tsebessa', value: 'Tsebessa' },
  { label: 'Tshopo', value: 'Tshopo' },
  { label: 'Tshuapa', value: 'Tshuapa' },
  { label: 'Tsirang District', value: 'Tsirang District' },
  { label: 'Tsov Province', value: 'Tsov Province' },
  { label: 'Tsuen Wan', value: 'Tsuen Wan' },
  { label: 'Tuamasaga', value: 'Tuamasaga' },
  { label: 'Tucumsan', value: 'Tucumsan' },
  { label: 'Tula Oblast', value: 'Tula Oblast' },
  { label: 'Tulcea County', value: 'Tulcea County' },
  { label: 'Tumbes', value: 'Tumbes' },
  { label: 'Tunapuna-Piarco Regional Corporation', value: 'Tunapuna-Piarco Regional Corporation' },
  { label: 'Tunceli', value: 'Tunceli' },
  { label: 'Tungurahua', value: 'Tungurahua' },
  { label: 'Tunis', value: 'Tunis' },
  { label: 'Turkana', value: 'Turkana' },
  { label: 'Turkestan Region', value: 'Turkestan Region' },
  { label: 'TurniaaaE Municipality', value: 'TurniaaaE Municipality' },
  { label: 'Tuscany', value: 'Tuscany' },
  { label: 'Tutong District', value: 'Tutong District' },
  { label: 'Tuva Republic', value: 'Tuva Republic' },
  { label: 'Tuysen Quang', value: 'Tuysen Quang' },
  { label: 'Tver Oblast', value: 'Tver Oblast' },
  { label: 'Tyrol', value: 'Tyrol' },
  { label: 'Tyumen Oblast', value: 'Tyumen Oblast' },
  { label: 'Uaboe District', value: 'Uaboe District' },
  { label: 'Uasin Gishu', value: 'Uasin Gishu' },
  { label: 'Uauak', value: 'Uauak' },
  { label: 'Ubon Ratchathani', value: 'Ubon Ratchathani' },
  { label: 'Ucayali', value: 'Ucayali' },
  { label: 'Udmurt Republic', value: 'Udmurt Republic' },
  { label: 'Udon Thani', value: 'Udon Thani' },
  { label: 'Ujar District', value: 'Ujar District' },
  { label: 'Ulcinj Municipality', value: 'Ulcinj Municipality' },
  { label: 'Ulsan', value: 'Ulsan' },
  { label: 'Ulster', value: 'Ulster' },
  { label: 'Ulyanovsk Oblast', value: 'Ulyanovsk Oblast' },
  { label: 'Umbria', value: 'Umbria' },
  { label: 'Umm Al-Quwain', value: 'Umm Al-Quwain' },
  { label: 'Umm Salal Municipality', value: 'Umm Salal Municipality' },
  { label: 'Ungheni District', value: 'Ungheni District' },
  { label: 'Upper Austria', value: 'Upper Austria' },
  { label: 'Upper Demerara-Berbice', value: 'Upper Demerara-Berbice' },
  { label: 'Upper East', value: 'Upper East' },
  { label: 'Upper River Division', value: 'Upper River Division' },
  { label: 'Upper Takutu-Upper Essequibo', value: 'Upper Takutu-Upper Essequibo' },
  { label: 'Upper West', value: 'Upper West' },
  { label: 'Uppsala County', value: 'Uppsala County' },
  { label: 'Uri', value: 'Uri' },
  { label: 'Urozgan', value: 'Urozgan' },
  { label: 'Us­Ge Province', value: 'Us­Ge Province' },
  { label: 'Usulutsan Department', value: 'Usulutsan Department' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Utena County', value: 'Utena County' },
  { label: 'Uthai Thani', value: 'Uthai Thani' },
  { label: 'Utrecht', value: 'Utrecht' },
  { label: 'Uttar Pradesh', value: 'Uttar Pradesh' },
  { label: 'Uttaradit', value: 'Uttaradit' },
  { label: 'Uttarakhand', value: 'Uttarakhand' },
  { label: 'Utuado', value: 'Utuado' },
  { label: 'Uusimaa', value: 'Uusimaa' },
  { label: 'Uva Province', value: 'Uva Province' },
  { label: 'Uvs Province', value: 'Uvs Province' },
  { label: 'VaRkava Municipality', value: 'VaRkava Municipality' },
  { label: 'VaA-O-Fonoti', value: 'VaA-O-Fonoti' },
  { label: 'Vaavu Atoll', value: 'Vaavu Atoll' },
  { label: 'Vaduz', value: 'Vaduz' },
  { label: 'Vaenh Long', value: 'Vaenh Long' },
  { label: 'Vaenh Phsuc', value: 'Vaenh Phsuc' },
  { label: 'Vaia†Ode Municipality', value: 'Vaia†Ode Municipality' },
  { label: 'Vaisigano', value: 'Vaisigano' },
  { label: 'Vaitupu', value: 'Vaitupu' },
  { label: 'Vakaga Prefecture', value: 'Vakaga Prefecture' },
  { label: 'Valais', value: 'Valais' },
  { label: 'Valandovo Municipality', value: 'Valandovo Municipality' },
  { label: 'Valencia', value: 'Valencia' },
  { label: 'Valga County', value: 'Valga County' },
  { label: 'Valka Municipality', value: 'Valka Municipality' },
  { label: 'Valladolid', value: 'Valladolid' },
  { label: 'Valle Del Cauca', value: 'Valle Del Cauca' },
  { label: 'Valle Department', value: 'Valle Department' },
  { label: 'Valletta', value: 'Valletta' },
  { label: 'Vallsee Du Bandama Region', value: 'Vallsee Du Bandama Region' },
  { label: 'Valmiera', value: 'Valmiera' },
  { label: 'Valparas­So', value: 'Valparas­So' },
  { label: 'Valverde Province', value: 'Valverde Province' },
  { label: 'Van', value: 'Van' },
  { label: 'Varaa¾Din', value: 'Varaa¾Din' },
  { label: 'VarakauaNi Municipality', value: 'VarakauaNi Municipality' },
  { label: 'Varna Province', value: 'Varna Province' },
  { label: 'Vas County', value: 'Vas County' },
  { label: 'Vasilevo Municipality', value: 'Vasilevo Municipality' },
  { label: 'Vaslui County', value: 'Vaslui County' },
  { label: 'Vaud', value: 'Vaud' },
  { label: 'Vaupses', value: 'Vaupses' },
  { label: 'Vavaa»U', value: 'Vavaa»U' },
  { label: 'Vayots Dzor Region', value: 'Vayots Dzor Region' },
  { label: 'Vecpiebalga Municipality', value: 'Vecpiebalga Municipality' },
  { label: 'Vecumnieki Municipality', value: 'Vecumnieki Municipality' },
  { label: 'Vega Alta', value: 'Vega Alta' },
  { label: 'Vega Baja', value: 'Vega Baja' },
  { label: 'Veles Municipality', value: 'Veles Municipality' },
  { label: 'Velika Polana Municipality', value: 'Velika Polana Municipality' },
  { label: 'Velike LaaaaE Municipality', value: 'Velike LaaaaE Municipality' },
  { label: 'Veliko Tarnovo Province', value: 'Veliko Tarnovo Province' },
  { label: 'Veneto', value: 'Veneto' },
  { label: 'Ventspils', value: 'Ventspils' },
  { label: 'Ventspils Municipality', value: 'Ventspils Municipality' },
  { label: 'Vera¾Ej Municipality', value: 'Vera¾Ej Municipality' },
  { label: 'Veracruz De Ignacio De La Llave', value: 'Veracruz De Ignacio De La Llave' },
  { label: 'Veraguas Province', value: 'Veraguas Province' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Vestfold Og Telemark', value: 'Vestfold Og Telemark' },
  { label: 'Vestland', value: 'Vestland' },
  { label: 'Veszprsem County', value: 'Veszprsem County' },
  { label: 'VevaAni Municipality', value: 'VevaAni Municipality' },
  { label: 'Viana Do Castelo', value: 'Viana Do Castelo' },
  { label: 'ViauaNi Municipality', value: 'ViauaNi Municipality' },
  { label: 'Viauaka Municipality', value: 'Viauaka Municipality' },
  { label: 'Vichada', value: 'Vichada' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Videm Municipality', value: 'Videm Municipality' },
  { label: 'Vidin Province', value: 'Vidin Province' },
  { label: 'Vienna', value: 'Vienna' },
  { label: 'Vientiane Prefecture', value: 'Vientiane Prefecture' },
  { label: 'Vientiane Province', value: 'Vientiane Province' },
  { label: 'Vieques', value: 'Vieques' },
  { label: 'Viesaete Municipality', value: 'Viesaete Municipality' },
  { label: 'Vieux Fort Quarter', value: 'Vieux Fort Quarter' },
  { label: 'Viken', value: 'Viken' },
  { label: 'Vila Real', value: 'Vila Real' },
  { label: 'Viljandi County', value: 'Viljandi County' },
  { label: 'Villa Clara Province', value: 'Villa Clara Province' },
  { label: 'Villalba', value: 'Villalba' },
  { label: 'Vilnius City Municipality', value: 'Vilnius City Municipality' },
  { label: 'Vinica Municipality', value: 'Vinica Municipality' },
  { label: 'Vinnytska Oblast', value: 'Vinnytska Oblast' },
  { label: 'Vipava Municipality', value: 'Vipava Municipality' },
  { label: 'Viqueque Municipality', value: 'Viqueque Municipality' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Virovitica-Podravina', value: 'Virovitica-Podravina' },
  { label: 'Viseu', value: 'Viseu' },
  { label: 'Vitanje Municipality', value: 'Vitanje Municipality' },
  { label: 'Vitebsk Region', value: 'Vitebsk Region' },
  { label: 'Vladimir Oblast', value: 'Vladimir Oblast' },
  { label: 'Vlorse County', value: 'Vlorse County' },
  { label: 'Vodice Municipality', value: 'Vodice Municipality' },
  { label: 'Vojnik Municipality', value: 'Vojnik Municipality' },
  { label: 'Vojvodina', value: 'Vojvodina' },
  { label: 'Volgograd Oblast', value: 'Volgograd Oblast' },
  { label: 'Vologda Oblast', value: 'Vologda Oblast' },
  { label: 'Volta', value: 'Volta' },
  { label: 'Volynska Oblast', value: 'Volynska Oblast' },
  { label: 'Vorarlberg', value: 'Vorarlberg' },
  { label: 'Voronezh Oblast', value: 'Voronezh Oblast' },
  { label: 'Vrancea County', value: 'Vrancea County' },
  { label: 'Vransko Municipality', value: 'Vransko Municipality' },
  { label: 'VrapaIaate Municipality', value: 'VrapaIaate Municipality' },
  { label: 'Vratsa Province', value: 'Vratsa Province' },
  { label: 'Vrhnika Municipality', value: 'Vrhnika Municipality' },
  { label: 'Vsµru County', value: 'Vsµru County' },
  { label: 'Vsalcea County', value: 'Vsalcea County' },
  { label: 'Vsarmland County', value: 'Vsarmland County' },
  { label: 'Vsasterbotten County', value: 'Vsasterbotten County' },
  { label: 'Vsasternorrland County', value: 'Vsasternorrland County' },
  { label: 'Vsastmanland County', value: 'Vsastmanland County' },
  { label: 'Vsastra Gsotaland County', value: 'Vsastra Gsotaland County' },
  { label: 'Vukovar-Syrmia', value: 'Vukovar-Syrmia' },
  { label: 'Vuzenica Municipality', value: 'Vuzenica Municipality' },
  { label: 'Wadi Al Hayaa District', value: 'Wadi Al Hayaa District' },
  { label: 'Wadi Al Shatii District', value: 'Wadi Al Shatii District' },
  { label: 'Wadi Fira', value: 'Wadi Fira' },
  { label: 'Waikato Region', value: 'Waikato Region' },
  { label: 'Wajir', value: 'Wajir' },
  { label: 'Wakayama Prefecture', value: 'Wakayama Prefecture' },
  { label: 'Wales', value: 'Wales' },
  { label: 'Wallonia', value: 'Wallonia' },
  { label: 'Wan Chai', value: 'Wan Chai' },
  { label: 'Wangdue Phodrang District', value: 'Wangdue Phodrang District' },
  { label: 'Wanica District', value: 'Wanica District' },
  { label: 'Warmian-Masurian Voivodeship', value: 'Warmian-Masurian Voivodeship' },
  { label: 'Warwick', value: 'Warwick' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Wasit', value: 'Wasit' },
  { label: 'Wele-Nzas Province', value: 'Wele-Nzas Province' },
  { label: 'Wellington Region', value: 'Wellington Region' },
  { label: 'West', value: 'West' },
  { label: 'West Azarbaijan', value: 'West Azarbaijan' },
  { label: 'West Bengal', value: 'West Bengal' },
  { label: 'West Coast Division', value: 'West Coast Division' },
  { label: 'West Coast Region', value: 'West Coast Region' },
  { label: 'West Darfur', value: 'West Darfur' },
  { label: 'West Grand Bahama', value: 'West Grand Bahama' },
  { label: 'West Greece Region', value: 'West Greece Region' },
  { label: 'West Kazakhstan Province', value: 'West Kazakhstan Province' },
  { label: 'West Kordofan', value: 'West Kordofan' },
  { label: 'West Macedonia Region', value: 'West Macedonia Region' },
  { label: 'West New Britain Province', value: 'West New Britain Province' },
  { label: 'West Pokot', value: 'West Pokot' },
  { label: 'West Pomeranian Voivodeship', value: 'West Pomeranian Voivodeship' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Western', value: 'Western' },
  { label: 'Western Area', value: 'Western Area' },
  { label: 'Western Australia', value: 'Western Australia' },
  { label: 'Western Cape', value: 'Western Cape' },
  { label: 'Western Division', value: 'Western Division' },
  { label: 'Western Highlands Province', value: 'Western Highlands Province' },
  { label: 'Western North', value: 'Western North' },
  { label: 'Western Province', value: 'Western Province' },
  { label: 'Western Region', value: 'Western Region' },
  { label: 'Western Tobago', value: 'Western Tobago' },
  { label: 'Westfjords', value: 'Westfjords' },
  { label: 'Westmoreland Parish', value: 'Westmoreland Parish' },
  { label: 'White Nile', value: 'White Nile' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'Woleu-Ntem Province', value: 'Woleu-Ntem Province' },
  { label: 'Wong Tai Sin', value: 'Wong Tai Sin' },
  { label: 'Woroba District', value: 'Woroba District' },
  { label: 'Wyoming', value: 'Wyoming' },
  { label: 'Xaga§Ra', value: 'Xaga§Ra' },
  { label: 'Xaisomboun Province', value: 'Xaisomboun Province' },
  { label: 'Xewkija', value: 'Xewkija' },
  { label: 'Xga§Ajra', value: 'Xga§Ajra' },
  { label: 'Xiangkhouang Province', value: 'Xiangkhouang Province' },
  { label: 'Xinjiang', value: 'Xinjiang' },
  { label: 'Xizang', value: 'Xizang' },
  { label: 'Xorazm Region', value: 'Xorazm Region' },
  { label: 'Yabucoa', value: 'Yabucoa' },
  { label: 'Yala', value: 'Yala' },
  { label: 'Yalova', value: 'Yalova' },
  { label: 'Yamagata Prefecture', value: 'Yamagata Prefecture' },
  { label: 'Yamaguchi Prefecture', value: 'Yamaguchi Prefecture' },
  { label: 'Yamalo-Nenets Autonomous Okrug', value: 'Yamalo-Nenets Autonomous Okrug' },
  { label: 'Yamanashi Prefecture', value: 'Yamanashi Prefecture' },
  { label: 'Yambol Province', value: 'Yambol Province' },
  { label: 'Yangon Region', value: 'Yangon Region' },
  { label: 'Yap State', value: 'Yap State' },
  { label: 'Yaracuy', value: 'Yaracuy' },
  { label: 'Yardymli District', value: 'Yardymli District' },
  { label: 'Yaren District', value: 'Yaren District' },
  { label: 'Yaroslavl Oblast', value: 'Yaroslavl Oblast' },
  { label: 'Yasothon', value: 'Yasothon' },
  { label: 'Yau Tsim Mong', value: 'Yau Tsim Mong' },
  { label: 'Yauco', value: 'Yauco' },
  { label: 'Yazd', value: 'Yazd' },
  { label: 'Yerevan', value: 'Yerevan' },
  { label: 'Yevlakh', value: 'Yevlakh' },
  { label: 'Yevlakh District', value: 'Yevlakh District' },
  { label: 'Yilan', value: 'Yilan' },
  { label: 'Yobe', value: 'Yobe' },
  { label: 'Yoro Department', value: 'Yoro Department' },
  { label: 'Yozgat', value: 'Yozgat' },
  { label: 'Ysen Bsai', value: 'Ysen Bsai' },
  { label: 'Yucatsan', value: 'Yucatsan' },
  { label: 'Yuen Long', value: 'Yuen Long' },
  { label: 'Yukon', value: 'Yukon' },
  { label: 'Yunlin', value: 'Yunlin' },
  { label: 'Yunnan', value: 'Yunnan' },
  { label: 'Zabaykalsky Krai', value: 'Zabaykalsky Krai' },
  { label: 'Zabul', value: 'Zabul' },
  { label: 'Zacatecas', value: 'Zacatecas' },
  { label: 'Zadar', value: 'Zadar' },
  { label: 'Zaghouan', value: 'Zaghouan' },
  { label: 'Zagorje Ob Savi Municipality', value: 'Zagorje Ob Savi Municipality' },
  { label: 'Zagreb', value: 'Zagreb' },
  { label: 'Zaire Province', value: 'Zaire Province' },
  { label: 'Zakarpatska Oblast', value: 'Zakarpatska Oblast' },
  { label: 'Zala County', value: 'Zala County' },
  { label: 'Zambezi Region', value: 'Zambezi Region' },
  { label: 'Zambezia Province', value: 'Zambezia Province' },
  { label: 'Zamboanga Sibugay', value: 'Zamboanga Sibugay' },
  { label: 'Zamfara', value: 'Zamfara' },
  { label: 'Zamora', value: 'Zamora' },
  { label: 'Zamora Chinchipe', value: 'Zamora Chinchipe' },
  { label: 'Zangilan District', value: 'Zangilan District' },
  { label: 'Zanjan', value: 'Zanjan' },
  { label: 'Zanzan Region', value: 'Zanzan Region' },
  { label: 'Zanzibar North', value: 'Zanzibar North' },
  { label: 'Zanzibar South', value: 'Zanzibar South' },
  { label: 'Zanzibar West', value: 'Zanzibar West' },
  { label: 'Zaporizka Oblast', value: 'Zaporizka Oblast' },
  { label: 'Zaqatala District', value: 'Zaqatala District' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zardab District', value: 'Zardab District' },
  { label: 'Zarqa', value: 'Zarqa' },
  { label: 'Zavkhan Province', value: 'Zavkhan Province' },
  { label: 'Zavra Municipality', value: 'Zavra Municipality' },
  { label: 'Zawiya District', value: 'Zawiya District' },
  { label: 'Zeeland', value: 'Zeeland' },
  { label: 'Zelenikovo Municipality', value: 'Zelenikovo Municipality' },
  { label: 'Zhejiang', value: 'Zhejiang' },
  { label: 'Zhemgang District', value: 'Zhemgang District' },
  { label: 'Zhytomyrska Oblast', value: 'Zhytomyrska Oblast' },
  { label: 'Ziguinchor', value: 'Ziguinchor' },
  { label: 'Zilupe Municipality', value: 'Zilupe Municipality' },
  { label: 'Zinder Region', value: 'Zinder Region' },
  { label: 'Zls­N', value: 'Zls­N' },
  { label: 'Zonguldak', value: 'Zonguldak' },
  { label: 'Zou Department', value: 'Zou Department' },
  { label: 'ZreaE Municipality', value: 'ZreaE Municipality' },
  { label: 'Zrnovci Municipality', value: 'Zrnovci Municipality' },
  { label: 'Zsurich', value: 'Zsurich' },
  { label: 'Zug', value: 'Zug' },
  { label: 'Zulia', value: 'Zulia' },
];

const StateNames = ({ selectedStates, handleSelectChange }) => {
  const animatedComponents = makeAnimated();

  return (
    <div>
      <Select
        isMulti
        value={selectedStates}
        onChange={handleSelectChange}
        options={stateNames}
        placeholder="Select States..."
        components={animatedComponents}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default StateNames;







// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const StateNames = ({ data, selectedStates, handleSelectChange }) => {
//   const stateNames = [...new Set(data.map((item) => item.State || ''))] // Add null check here
//     .filter(state => state !== '') // Remove empty strings
//     .map((state) => ({
//       value: state,
//       label: state,
//     }));

//   // Sort the city names in alphabetical order
//   stateNames.sort((a, b) => a.label.localeCompare(b.label));

//   const animatedComponents = makeAnimated(); 

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedStates}
//         onChange={handleSelectChange}
//         options={stateNames}
//         placeholder="Select States..."
//         components={animatedComponents}
//       />
//     </div>
//   );
// };

// export default StateNames;


