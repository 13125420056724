import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const jobLevels = [
  { label: 'C-Level', value: 'C-Level' },
  { label: 'Founder', value: 'Founder' },
  { label: 'Director Level', value: 'Director Level' },
  { label: 'Individual Contributor', value: 'Individual Contributor' },
  { label: 'Manager Level', value: 'Manager Level'},
  { label: 'VP Level', value: 'VP Level'},
  { label: 'Other', value: 'Other'}
];

const JobLevel = ({ jobLevelOptions, handleSelectChange }) => {
  const animatedComponents = makeAnimated();

  return (
    <div>
      <Select
        isMulti
        value={jobLevelOptions}
        onChange={handleSelectChange}
        options={jobLevels}
        placeholder="Select Job Levels..."
        components={animatedComponents}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default JobLevel;



// JobLevel.js
// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const JobLevel = ({ data, jobLevelOptions, handleSelectChange }) => {
//   const jobLevels = [...new Set(data.map((item) => item.JobLevel))].map((level) => ({
//     value: level,
//     label: level,
//   }));

//   // Sort the city names in alphabetical order
//   jobLevels.sort((a, b) => a.label.localeCompare(b.label));  

//   const animatedComponents = makeAnimated();  

//   return (
//     <div>
//       <Select
//         isMulti
//         value={jobLevelOptions}
//         onChange={handleSelectChange}
//         options={jobLevels}
//         placeholder="Select Job Levels..."
//         components={animatedComponents}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default JobLevel;


