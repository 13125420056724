// import React from 'react'
// import Select from 'react-select'
// import makeAnimated from 'react-select/animated'


// // Making animation component
// const animatedComponents = makeAnimated();


// export default function Revenue() {
//   return(
//         <Select
//             isMulti
//             options={rev}
//             placeholder={'Select Revenue Size...'}
//             components={animatedComponents}
//         />
//   )
// }

// const rev = [
//     { label: '0-1M', value: '0-1M' },
//     { label: '2-10M ', value: '2-10M ' },
//     { label: '11-50M ', value: '11-50M ' },
//     { label: '51-100M ', value: '51-100M ' },
//     { label: '101-250M', value: '101-250M' },
//     { label: '251-500M ', value: '251-500M ' },
//     { label: '501-1B ', value: '501-1B ' },
//     { label: '1B-10B', value: '1B-10B' },
//     { label: '10B+', value: '10B+' }

// ]







// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const Revenue = ({ data, selectedRevenue, handleSelectChange }) => {
//   const revenueOptions = [
//     { label: '0-1M', value: '0-1e6' },
//     { label: '2-10M ', value: '2e6-10e6' },
//     { label: '11-50M ', value: '11e6-50e6' },
//     { label: '51-100M ', value: '51e6-100e6' },
//     { label: '101-250M', value: '101e6-250e6' },
//     { label: '251-500M ', value: '251e6-500e6' },
//     { label: '501-1B ', value: '501e6-1e9' },
//     { label: '1B-10B', value: '1e9-10e9' },
//     { label: '10B+', value: '10e9-100e9' }
//   ];

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedRevenue}
//         onChange={handleSelectChange}
//         options={revenueOptions}
//         placeholder="Select Revenue Size..."
//         components={makeAnimated()}
//       />
//     </div>
//   );
// };

// export default Revenue;



// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const Revenue = ({ data, selectedRevenue, handleSelectChange }) => {
//   const revenueOptions = [
//     { label: '0-1M', value: '0-1e6' },
//     { label: '2-10M ', value: '2e6-10e6' },
//     { label: '11-50M ', value: '11e6-50e6' },
//     { label: '51-100M ', value: '51e6-100e6' },
//     { label: '101-250M', value: '101e6-250e6' },
//     { label: '251-500M ', value: '251e6-500e6' },
//     { label: '501-1B ', value: '501e6-1e9' },
//     { label: '1B-10B', value: '1e9-10e9' },
//     { label: '10B+', value: '10e9-100e9' }
  
//   ];
  

//   const filteredSelectedRevenue = selectedRevenue.filter(revenue => revenue !== null && revenue !== undefined);

//   return (
//     <div>
//       <Select
//         isMulti
//         value={filteredSelectedRevenue}
//         onChange={handleSelectChange}
//         options={revenueOptions}
//         placeholder="Select Revenue Size..."
//         components={makeAnimated()}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default Revenue;

import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const Revenue = ({ data, selectedRevenue, handleSelectChange }) => {
  const revenueOptions = [
    { label: '0-1M', value: '0-1000000' },
    { label: '2-10M ', value: '2000000-10000000' },
    { label: '11-50M ', value: '11000000-50000000' },
    { label: '51-100M ', value: '51000000-100000000' },
    { label: '101-250M', value: '101000000-250000000' },
    { label: '251-500M ', value: '251000000-500000000' },
    { label: '501-1B ', value: '501000000-1000000000' },
    { label: '1B-10B', value: '1000000000-10000000000' },
    { label: '10B+', value: '10000000000-100000000000' }
  
  ];
  

  const filteredSelectedRevenue = selectedRevenue.filter(revenue => revenue !== null && revenue !== undefined);

  return (
    <div>
      <Select
        isMulti
        value={filteredSelectedRevenue}
        onChange={handleSelectChange}
        options={revenueOptions}
        placeholder="Select Revenue Size..."
        components={makeAnimated()}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default Revenue;
