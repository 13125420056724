
// import { useState, useCallback } from 'react';
// import * as XLSX from 'xlsx';

// const useUploadTAL = (filteredData, setFilteredData) => {
//   const [talFile, setTalFile] = useState(null);
//   const [talData, setTalData] = useState({
//     CompanyName: [],
//     Domain: [],
//   });
//   const [applied, setApplied] = useState(false);

//   const handleTALFileChange = (file) => {
//     setTalFile(file);
//     const reader = new FileReader();
//     reader.onload = () => {
//       const workbook = XLSX.read(reader.result, { type: 'array' });
//       const worksheet = workbook.Sheets['Sheet1'];
//       const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
//       const talData = {
//         CompanyName: [],
//         Domain: [],
//       };
//       for (let i = 1; i < data.length; i++) {
//         if (data[i][0] !== '' && data[i][1] !== '') {
//           talData.CompanyName.push(data[i][0]);
//           talData.Domain.push(data[i][1]);
//         }
//       }
//       setTalData(talData);
//     };
//     reader.readAsArrayBuffer(file);
//   };

//   const applyTAL = useCallback((filteredData) => {
//     if (talData.CompanyName.length > 0 && talData.Domain.length > 0) {
//       return filteredData.filter((item) => {
//         return (talData.CompanyName.includes(item.CompanyName) || talData.CompanyName.length === 0) && (talData.Domain.includes(item.Domain) || talData.Domain.length === 0);
//       });
//     } else {
//       console.log('talData is empty, skipping filter');
//       return filteredData;
//     }
//   }, [talData]);

//   return { talFile, handleTALFileChange, talData, applyTAL };
// };

// export default useUploadTAL;



import { useState, useCallback } from 'react';
import * as XLSX from 'xlsx';

const useUploadTAL = (filteredData, setFilteredData) => {
  const [talFile, setTalFile] = useState(null);
  const [talData, setTalData] = useState({
    CompanyName: [],
    Domain: [],
  });
  const [applied, setApplied] = useState(false);

  const handleTALFileChange = (file) => {
    if (file) {
      setTalFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        const workbook = XLSX.read(reader.result, { type: 'array' });
        const worksheet = workbook.Sheets['Sheet1'];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const talData = {
          CompanyName: [],
          Domain: [],
        };
        for (let i = 1; i < data.length; i++) {
          if (data[i][0] !== '' && data[i][1] !== '') {
            talData.CompanyName.push(data[i][0]);
            talData.Domain.push(data[i][1]);
          }
        }
        setTalData(talData);
      };
      reader.readAsArrayBuffer(file);
    } else {
      setTalFile(null);
      setTalData({
        CompanyName: [],
        Domain: [],
      });
    }
  };

  const applyTAL = useCallback((filteredData) => {
    if (talData.CompanyName.length > 0 && talData.Domain.length > 0) {
      return filteredData.filter((item) => {
        return (talData.CompanyName.includes(item.CompanyName) || talData.CompanyName.length === 0) && (talData.Domain.includes(item.Domain) || talData.Domain.length === 0);
      });
    } else {
      console.log('talData is empty, skipping filter');
      return filteredData;
    }
  }, [talData]);

  return { talFile, handleTALFileChange, talData, applyTAL, setTalFile };
};

export default useUploadTAL;