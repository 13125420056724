// import { useState, useEffect } from 'react';
// import { readFileSync } from 'xlsx';
// import * as XLSX from 'xlsx';

// const useUploadSuppression = () => {
//     const [file, setFile] = useState(null);
//     const [suppressionData, setSuppressionData] = useState([]);
  
//     const handleFileChange = (file) => {
//         setFile(file);
//         const reader = new FileReader();
//         reader.onload = () => {
//           const workbook = XLSX.read(reader.result, { type: 'array' });
//           const worksheet = workbook.Sheets['Sheet1'];
//           const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
//           const suppressionData = [];
//           for (let i = 1; i < data.length; i++) {
//             suppressionData.push({
//               CompanyName: data[i][0],
//               EmailAddress: data[i][1],
//               Domain: data[i][2],
//             });
//           }
//           setSuppressionData(suppressionData);
//         };
//         reader.readAsArrayBuffer(file);
//       };
  
//     const applySuppression = (filteredData) => {
//       return filteredData.filter((item) => {
//         return !suppressionData.some((suppressionItem) => {
//           return (
//             (item.CompanyName && item.CompanyName === suppressionItem.CompanyName) ||
//             (item.EmailAddress && item.EmailAddress === suppressionItem.EmailAddress) ||
//             (item.Domain && item.Domain === suppressionItem.Domain)
//           );
//         });
//       });
//     };
  
//     return { file, handleFileChange, applySuppression };
//   };

// export default useUploadSuppression;



// import { useState, useCallback } from 'react';
// import * as XLSX from 'xlsx';

// const useUploadSuppression = (filteredData, setFilteredData) => {
//   const [file, setFile] = useState(null);
//   const [suppressionData, setSuppressionData] = useState([]);
//   const [applied, setApplied] = useState(false);

//   const handleFileChange = (file) => {
//     setFile(file);
//     const reader = new FileReader();
//     reader.onload = () => {
//       const workbook = XLSX.read(reader.result, { type: 'array' });
//       const worksheet = workbook.Sheets['Sheet1'];
//       const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
//       const suppressionData = [];
//       for (let i = 1; i < data.length; i++) {
//         suppressionData.push({
//           CompanyName: data[i][0],
//           EmailAddress: data[i][1],
//           Domain: data[i][2],
//         });
//       }
//       setSuppressionData(suppressionData);
//     };
//     reader.readAsArrayBuffer(file);
//   };

//   const applySuppression = useCallback((filteredData) => {
//     return filteredData.filter((item) => {
//       return !suppressionData.some((suppressionItem) => {
//         return (
//           (item.CompanyName && item.CompanyName === suppressionItem.CompanyName) ||
//           (item.EmailAddress && item.EmailAddress === suppressionItem.EmailAddress) ||
//           (item.Domain && item.Domain === suppressionItem.Domain)
//         );
//       });
//     });
//   }, [suppressionData]);

//   const handleApplySuppression = () => {
//     if (suppressionData.length > 0) {
//       const suppressedData = applySuppression(filteredData);
//       setFilteredData(suppressedData);
//       setApplied(true);
//     }
//   };

//   return { file, handleFileChange, handleApplySuppression, applied, applySuppression, suppressionData };
// };

// export default useUploadSuppression;


import { useState, useCallback } from 'react';
import * as XLSX from 'xlsx';

const useUploadSuppression = (filteredData, setFilteredData) => {
  const [file, setFile] = useState(null);
  const [suppressionData, setSuppressionData] = useState({
    CompanyName: [],
    EmailAddress: [],
    Domain: [],
  });
  const [applied, setApplied] = useState(false);

  const handleFileChange = (file) => {
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const workbook = XLSX.read(reader.result, { type: 'array' });
      const worksheet = workbook.Sheets['Sheet1'];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const suppressionData = {
        CompanyName: [],
        Domain: [],
        EmailAddress: [],
        EmailAddress: [],
        EmailAddress: [],
        EmailAddress: [],
        EmailAddress: [],
      };
      for (let i = 1; i < data.length; i++) {
        suppressionData.CompanyName.push(data[i][0]);
        suppressionData.Domain.push(data[i][1]);
        suppressionData.EmailAddress.push(data[i][2]);
        suppressionData.EmailAddress.push(data[i][3]);
        suppressionData.EmailAddress.push(data[i][4]);
        suppressionData.EmailAddress.push(data[i][5]);
        suppressionData.EmailAddress.push(data[i][6]);
      }
      setSuppressionData(suppressionData);
    };
    reader.readAsArrayBuffer(file);
  };

  return { file, handleFileChange, suppressionData };
};

export default useUploadSuppression;