// import React from 'react'
// import PropTypes from 'prop-types'


// export default function Navbar(props) {
//   return (
//     // Navbar 
//     <nav className="navbar navbar-expand-lg bg-body-tertiary">
//   <div className="container-fluid">
//     <a className="navbar-brand" href="/">{props.title}</a>
//     {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//       <span className="navbar-toggler-icon"></span>
//     </button> */}
//     {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
//       <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//         <li className="nav-item">
//           <a className="nav-link active" aria-current="page" href="/">Home</a>
//         </li>
//         <li className="nav-item">
//           <a className="nav-link" href="/">Documentation</a>
//         </li>
//       </ul>
//         </div> */}
//       </div>
//     </nav>
//   )
// }


// Navbar.propTypes = {
//     title: PropTypes.string.isRequired
// }

// Navbar.defaultProps = {
//     title: 'DM Database'
// }

import React from 'react';
import PropTypes from 'prop-types';

export default function Navbar(props) {
  return (
    // Navbar 
    <nav style={{ backgroundColor: 'rgba(255,255,255,0.5)' }} className="navbar navbar-expand-lg"> 
      <div className="container-fluid">
        <a className="navbar-brand" href="/">{props.title}</a>
        <div className="nav-item ml-auto">
          {props.logout && (
            <div className="nav-link">
              {props.logout}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  logout: PropTypes.node, // Add logout prop type
};

Navbar.defaultProps = {
  title: 'CM360',
};