import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const SIC = ({ selectedSicCode, handleSelectChange }) => {
  const [newSicCode, setNewSicCode] = useState('');
  const [sicCodeOptions, setSicCodeOptions] = useState([]);

  const handleAddNewSicCode = () => {
    if (newSicCode.trim() !== '') {
      const newOption = { label: newSicCode, value: newSicCode };
      setSicCodeOptions([...sicCodeOptions, newOption]);
      handleSelectChange([...selectedSicCode, newOption]);
      setNewSicCode('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddNewSicCode();
    }
  };

  return (
    <div>
      <Select
        isMulti
        value={selectedSicCode}
        onChange={handleSelectChange}
        options={sicCodeOptions}
        placeholder="Type and press Enter to add a SIC code"
        components={makeAnimated()}
        closeMenuOnSelect={false}
        menuIsOpen={false}
        styles={{
          control: (base) => ({
            ...base,
            width: '100%',
          }),
        }}
        onInputChange={(inputValue) => {
          setNewSicCode(inputValue);
        }}
        onKeyDown={handleKeyDown}
        inputValue={newSicCode}
      />
    </div>
  );
};

export default SIC;





// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const sicCode = [
//   { label: '0111', value: '0111' },
//   { label: '0112', value: '0112' },
//   { label: '0113', value: '0113' },
//   // Add more SIC codes here...
//   { label: 'Other', value: 'Other' }
// ];

// const SIC = ({ selectedSicCode, handleSelectChange }) => {
//   const animatedComponents = makeAnimated();

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedSicCode}
//         onChange={handleSelectChange}
//         options={sicCode}
//         placeholder="Select SIC Code..."
//         components={animatedComponents}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default SIC;





// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const SIC = ({ data, selectedSicCode, handleSelectChange }) => {
//   const sicCode = [...new Set(data.map((item) => item.SIC || ''))] // Add null check here
//     .filter(sic => sic !== '') // Remove empty strings
//     .map((sic) => ({
//       value: sic,
//       label: sic,
//     }));

//   // Sort the city names in alphabetical order
//   sicCode.sort((a, b) => a.label.localeCompare(b.label));

//   const animatedComponents = makeAnimated();   

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedSicCode}
//         onChange={handleSelectChange}
//         options={sicCode}
//         placeholder="Select SIC Code..."
//         components={animatedComponents}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default SIC;