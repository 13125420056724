import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const cityNames = [
  { label: 'Name', value: 'Name' },
  { label: 'A Dos Cunhados', value: 'A Dos Cunhados' },
  { label: 'A Dos Francos', value: 'A Dos Francos' },
  { label: 'Å I Åfjord', value: 'Å I Åfjord' },
  { label: 'Aabenraa', value: 'Aabenraa' },
  { label: 'Aabenraa Kommune', value: 'Aabenraa Kommune' },
  { label: 'Aach', value: 'Aach' },
  { label: 'Aach', value: 'Aach' },
  { label: 'Aachen', value: 'Aachen' },
  { label: 'Aadorf', value: 'Aadorf' },
  { label: 'Aagtdorp', value: 'Aagtdorp' },
  { label: 'Aagtekerke', value: 'Aagtekerke' },
  { label: 'Aalborg', value: 'Aalborg' },
  { label: 'Aalburg', value: 'Aalburg' },
  { label: 'Aalden', value: 'Aalden' },
  { label: 'Aalen', value: 'Aalen' },
  { label: 'Aalsmeer', value: 'Aalsmeer' },
  { label: 'Aalsmeerderbrug', value: 'Aalsmeerderbrug' },
  { label: 'Aalst', value: 'Aalst' },
  { label: 'Aalst', value: 'Aalst' },
  { label: 'Aalst', value: 'Aalst' },
  { label: 'Aalten', value: 'Aalten' },
  { label: 'Aalter', value: 'Aalter' },
  { label: 'Äänekoski', value: 'Äänekoski' },
  { label: 'Aanislag', value: 'Aanislag' },
  { label: 'Aanjar', value: 'Aanjar' },
  { label: 'Aarau', value: 'Aarau' },
  { label: 'Aarberg', value: 'Aarberg' },
  { label: 'Aarburg', value: 'Aarburg' },
  { label: 'Aardenburg', value: 'Aardenburg' },
  { label: 'Aarhus', value: 'Aarhus' },
  { label: 'Aarhus Kommune', value: 'Aarhus Kommune' },
  { label: 'Aarle-Rixtel', value: 'Aarle-Rixtel' },
  { label: 'Aars', value: 'Aars' },
  { label: 'Aarschot', value: 'Aarschot' },
  { label: 'Aartselaar', value: 'Aartselaar' },
  { label: 'Aartswoud', value: 'Aartswoud' },
  { label: 'Aarwangen', value: 'Aarwangen' },
  { label: 'Ab Meshkin', value: 'Ab Meshkin' },
  { label: 'Ab Pakhsh', value: 'Ab Pakhsh' },
  { label: 'Aba', value: 'Aba' },
  { label: 'Aba', value: 'Aba' },
  { label: 'Aba Zangzu Qiangzu Zizhizhou', value: 'Aba Zangzu Qiangzu Zizhizhou' },
  { label: 'Ababuj', value: 'Ababuj' },
  { label: 'Abaca', value: 'Abaca' },
  { label: 'Abaca', value: 'Abaca' },
  { label: 'Abad', value: 'Abad' },
  { label: 'Abadan', value: 'Abadan' },
  { label: 'Abadan', value: 'Abadan' },
  { label: 'Abadeh', value: 'Abadeh' },
  { label: 'Abadeh Tashk', value: 'Abadeh Tashk' },
  { label: 'Abades', value: 'Abades' },
  { label: 'Abadía', value: 'Abadía' },
  { label: 'Abadia De Goiás', value: 'Abadia De Goiás' },
  { label: 'Abadia Dos Dourados', value: 'Abadia Dos Dourados' },
  { label: 'Abadiânia', value: 'Abadiânia' },
  { label: 'Abadín', value: 'Abadín' },
  { label: 'Abadiño', value: 'Abadiño' },
  { label: 'Abadou', value: 'Abadou' },
  { label: 'Abádszalók', value: 'Abádszalók' },
  { label: 'Abadzekhskaya', value: 'Abadzekhskaya' },
  { label: 'Abaeté', value: 'Abaeté' },
  { label: 'Abaetetuba', value: 'Abaetetuba' },
  { label: 'Abagur', value: 'Abagur' },
  { label: 'Abaí', value: 'Abaí' },
  { label: 'Abaiang', value: 'Abaiang' },
  { label: 'Abaiara', value: 'Abaiara' },
  { label: 'Abáigar', value: 'Abáigar' },
  { label: 'Abaíra', value: 'Abaíra' },
  { label: 'Abajas', value: 'Abajas' },
  { label: 'Abakaliki', value: 'Abakaliki' },
  { label: 'Abakan', value: 'Abakan' },
  { label: 'Abakan Gorod', value: 'Abakan Gorod' },
  { label: 'Abala', value: 'Abala' },
  { label: 'Abalak', value: 'Abalak' },
  { label: 'Abalak', value: 'Abalak' },
  { label: 'Abalama', value: 'Abalama' },
  { label: 'Abali', value: 'Abali' },
  { label: 'Ábalos', value: 'Ábalos' },
  { label: 'Abaltzisketa', value: 'Abaltzisketa' },
  { label: 'Aban', value: 'Aban' },
  { label: 'Abana', value: 'Abana' },
  { label: 'Abánades', value: 'Abánades' },
  { label: 'Abancay', value: 'Abancay' },
  { label: 'Abangares', value: 'Abangares' },
  { label: 'Abangay', value: 'Abangay' },
  { label: 'Abanilla', value: 'Abanilla' },
  { label: 'Abano Terme', value: 'Abano Terme' },
  { label: 'Abanskiy Rayon', value: 'Abanskiy Rayon' },
  { label: 'Abanto', value: 'Abanto' },
  { label: 'Abanto Y Ciérvana-Abanto Zierbena', value: 'Abanto Y Ciérvana-Abanto Zierbena' },
  { label: 'Abapó', value: 'Abapó' },
  { label: 'Abarán', value: 'Abarán' },
  { label: 'Abarca De Campos', value: 'Abarca De Campos' },
  { label: 'Abaré', value: 'Abaré' },
  { label: 'Abarkooh', value: 'Abarkooh' },
  { label: 'Abarkuh', value: 'Abarkuh' },
  { label: 'Abarlaq-E Sofla', value: 'Abarlaq-E Sofla' },
  { label: 'Abárzuza/Abartzuza', value: 'Abárzuza/Abartzuza' },
  { label: 'Abasár', value: 'Abasár' },
  { label: 'Abasha', value: 'Abasha' },
  { label: 'Abashiri', value: 'Abashiri' },
  { label: 'Abashiri Shi', value: 'Abashiri Shi' },
  { label: 'Abasolo', value: 'Abasolo' },
  { label: 'Abasolo', value: 'Abasolo' },
  { label: 'Abasolo', value: 'Abasolo' },
  { label: 'Abasolo', value: 'Abasolo' },
  { label: 'Abasolo', value: 'Abasolo' },
  { label: 'Abasolo Del Valle', value: 'Abasolo Del Valle' },
  { label: 'Abatiá', value: 'Abatiá' },
  { label: 'Abatskoye', value: 'Abatskoye' },
  { label: 'Abau', value: 'Abau' },
  { label: 'Abaújszántó', value: 'Abaújszántó' },
  { label: 'Abaurregaina/Abaurrea Alta', value: 'Abaurregaina/Abaurrea Alta' },
  { label: 'Abaurrepea/Abaurrea Baja', value: 'Abaurrepea/Abaurrea Baja' },
  { label: 'Abay', value: 'Abay' },
  { label: 'Abay Qalasy', value: 'Abay Qalasy' },
  { label: 'Abaza', value: 'Abaza' },
  { label: 'Abbadia Cerreto', value: 'Abbadia Cerreto' },
  { label: 'Abbadia Lariana', value: 'Abbadia Lariana' },
  { label: 'Abbadia San Salvatore', value: 'Abbadia San Salvatore' },
  { label: 'Abbar', value: 'Abbar' },
  { label: 'Abbaretz', value: 'Abbaretz' },
  { label: 'Abbasabad', value: 'Abbasabad' },
  { label: 'Abbasanta', value: 'Abbasanta' },
  { label: 'Abbateggio', value: 'Abbateggio' },
  { label: 'Abbazia', value: 'Abbazia' },
  { label: 'Abbazia Pisani', value: 'Abbazia Pisani' },
  { label: 'Abbekerk', value: 'Abbekerk' },
  { label: 'Abbenes', value: 'Abbenes' },
  { label: 'Abbesbüttel', value: 'Abbesbüttel' },
  { label: 'Abbeville', value: 'Abbeville' },
  { label: 'Abbeville', value: 'Abbeville' },
  { label: 'Abbeville', value: 'Abbeville' },
  { label: 'Abbeville', value: 'Abbeville' },
  { label: 'Abbeville', value: 'Abbeville' },
  { label: 'Abbeville County', value: 'Abbeville County' },
  { label: 'Abbey', value: 'Abbey' },
  { label: 'Abbey Wood', value: 'Abbey Wood' },
  { label: 'Abbeyfeale', value: 'Abbeyfeale' },
  { label: 'Abbeyleix', value: 'Abbeyleix' },
  { label: 'Abbiategrasso', value: 'Abbiategrasso' },
  { label: 'Abborrberget', value: 'Abborrberget' },
  { label: 'Abbots Bromley', value: 'Abbots Bromley' },
  { label: 'Abbots Langley', value: 'Abbots Langley' },
  { label: 'Abbotsbury', value: 'Abbotsbury' },
  { label: 'Abbotsford', value: 'Abbotsford' },
  { label: 'Abbotsford', value: 'Abbotsford' },
  { label: 'Abbotsford', value: 'Abbotsford' },
  { label: 'Abbotsford', value: 'Abbotsford' },
  { label: 'Abbotskerswell', value: 'Abbotskerswell' },
  { label: 'Abbottabad', value: 'Abbottabad' },
  { label: 'Abbotts Ann', value: 'Abbotts Ann' },
  { label: 'Abbottstown', value: 'Abbottstown' },
  { label: 'Abcoude', value: 'Abcoude' },
  { label: 'Abcoven', value: 'Abcoven' },
  { label: 'Abda', value: 'Abda' },
  { label: 'Abdalan', value: 'Abdalan' },
  { label: 'Abdanan', value: 'Abdanan' },
  { label: 'Abdenago C. García', value: 'Abdenago C. García' },
  { label: 'Abdissenbosch', value: 'Abdissenbosch' },
  { label: 'Abdol Rahim', value: 'Abdol Rahim' },
  { label: 'Abdon Batista', value: 'Abdon Batista' },
  { label: 'Abdulino', value: 'Abdulino' },
  { label: 'Abdurahmoni Jomí', value: 'Abdurahmoni Jomí' },
  { label: 'Abéché', value: 'Abéché' },
  { label: 'Abegondo', value: 'Abegondo' },
  { label: 'Abeïbara', value: 'Abeïbara' },
  { label: 'Abeilhan', value: 'Abeilhan' },
  { label: 'Abejar', value: 'Abejar' },
  { label: 'Abejorral', value: 'Abejorral' },
  { label: 'Abejuela', value: 'Abejuela' },
  { label: 'Abel Figueiredo', value: 'Abel Figueiredo' },
  { label: 'Abelardo L. Rodríguez', value: 'Abelardo L. Rodríguez' },
  { label: 'Abelardo L. Rodríguez', value: 'Abelardo L. Rodríguez' },
  { label: 'Abelardo Luz', value: 'Abelardo Luz' },
  { label: 'Abella De La Conca', value: 'Abella De La Conca' },
  { label: 'Abemama', value: 'Abemama' },
  { label: 'Abenberg', value: 'Abenberg' },
  { label: 'Abengibre', value: 'Abengibre' },
  { label: 'Abengourou', value: 'Abengourou' },
  { label: 'Abenójar', value: 'Abenójar' },
  { label: 'Abensberg', value: 'Abensberg' },
  { label: 'Abeokuta', value: 'Abeokuta' },
  { label: 'Abepura', value: 'Abepura' },
  { label: 'Aberaeron', value: 'Aberaeron' },
  { label: 'Abercanaid', value: 'Abercanaid' },
  { label: 'Abercarn', value: 'Abercarn' },
  { label: 'Aberchirder', value: 'Aberchirder' },
  { label: 'Abercrombie', value: 'Abercrombie' },
  { label: 'Abercynon', value: 'Abercynon' },
  { label: 'Aberdare', value: 'Aberdare' },
  { label: 'Aberdare', value: 'Aberdare' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen', value: 'Aberdeen' },
  { label: 'Aberdeen City', value: 'Aberdeen City' },
  { label: 'Aberdeen Proving Ground', value: 'Aberdeen Proving Ground' },
  { label: 'Aberdeenshire', value: 'Aberdeenshire' },
  { label: 'Aberdour', value: 'Aberdour' },
  { label: 'Aberfan', value: 'Aberfan' },
  { label: 'Aberfeldie', value: 'Aberfeldie' },
  { label: 'Aberfeldy', value: 'Aberfeldy' },
  { label: 'Aberford', value: 'Aberford' },
  { label: 'Aberfoyle Park', value: 'Aberfoyle Park' },
  { label: 'Abergavenny', value: 'Abergavenny' },
  { label: 'Abergele', value: 'Abergele' },
  { label: 'Aberglasslyn', value: 'Aberglasslyn' },
  { label: 'Aberin', value: 'Aberin' },
  { label: 'Aberkenfig', value: 'Aberkenfig' },
  { label: 'Aberlady', value: 'Aberlady' },
  { label: 'Abermain', value: 'Abermain' },
  { label: 'Abernathy', value: 'Abernathy' },
  { label: 'Abernethy', value: 'Abernethy' },
  { label: 'Aberporth', value: 'Aberporth' },
  { label: 'Abertamy', value: 'Abertamy' },
  { label: 'Abertillery', value: 'Abertillery' },
  { label: 'Abertridwr', value: 'Abertridwr' },
  { label: 'Abertura', value: 'Abertura' },
  { label: 'Aberystwyth', value: 'Aberystwyth' },
  { label: 'Abeshahmad', value: 'Abeshahmad' },
  { label: 'Abetone', value: 'Abetone' },
  { label: 'Abezames', value: 'Abezames' },
  { label: 'Abezhdan', value: 'Abezhdan' },
  { label: 'Abfaltersbach', value: 'Abfaltersbach' },
  { label: 'Abgarm', value: 'Abgarm' },
  { label: 'Abha', value: 'Abha' },
  { label: 'Abhaneri', value: 'Abhaneri' },
  { label: 'Abhar', value: 'Abhar' },
  { label: 'Abhayapuri', value: 'Abhayapuri' },
  { label: 'Abia De La Obispalía', value: 'Abia De La Obispalía' },
  { label: 'Abia De Las Torres', value: 'Abia De Las Torres' },
  { label: 'Abibeiglou', value: 'Abibeiglou' },
  { label: 'Abidjan', value: 'Abidjan' },
  { label: 'Abiego', value: 'Abiego' },
  { label: 'Abiera', value: 'Abiera' },
  { label: 'Abiko', value: 'Abiko' },
  { label: 'Abiko-Shi', value: 'Abiko-Shi' },
  { label: 'Abilay', value: 'Abilay' },
  { label: 'Abilene', value: 'Abilene' },
  { label: 'Abilene', value: 'Abilene' },
  { label: 'Abilly', value: 'Abilly' },
  { label: 'Abingdon', value: 'Abingdon' },
  { label: 'Abingdon', value: 'Abingdon' },
  { label: 'Abingdon', value: 'Abingdon' },
  { label: 'Abington', value: 'Abington' },
  { label: 'Abinsk', value: 'Abinsk' },
  { label: 'Abinskiy Rayon', value: 'Abinskiy Rayon' },
  { label: 'Abiramam', value: 'Abiramam' },
  { label: 'Abis', value: 'Abis' },
  { label: 'Abita Springs', value: 'Abita Springs' },
  { label: 'Abitibi-Témiscamingue', value: 'Abitibi-Témiscamingue' },
  { label: 'Abizanda', value: 'Abizanda' },
  { label: 'Abja-Paluoja', value: 'Abja-Paluoja' },
  { label: 'Abla', value: 'Abla' },
  { label: 'Ablain-Saint-Nazaire', value: 'Ablain-Saint-Nazaire' },
  { label: 'Ablanque', value: 'Ablanque' },
  { label: 'Ableiges', value: 'Ableiges' },
  { label: 'Ablis', value: 'Ablis' },
  { label: 'Ablitas', value: 'Ablitas' },
  { label: 'Ablon', value: 'Ablon' },
  { label: 'Ablon-Sur-Seine', value: 'Ablon-Sur-Seine' },
  { label: 'Abnūb', value: 'Abnūb' },
  { label: 'Abobo', value: 'Abobo' },
  { label: 'Abocho', value: 'Abocho' },
  { label: 'Abohar', value: 'Abohar' },
  { label: 'Aboisso', value: 'Aboisso' },
  { label: 'Abomey', value: 'Abomey' },
  { label: 'Abomey-Calavi', value: 'Abomey-Calavi' },
  { label: 'Abomsa', value: 'Abomsa' },
  { label: 'Abondance', value: 'Abondance' },
  { label: 'Abondant', value: 'Abondant' },
  { label: 'Abong Mbang', value: 'Abong Mbang' },
  { label: 'Abonnema', value: 'Abonnema' },
  { label: 'Abony', value: 'Abony' },
  { label: 'Aborlan', value: 'Aborlan' },
  { label: 'Aboso', value: 'Aboso' },
  { label: 'Abou El Hassan', value: 'Abou El Hassan' },
  { label: 'Abou Homeyzeh', value: 'Abou Homeyzeh' },
  { label: 'Aboukir', value: 'Aboukir' },
  { label: 'Abouzeidabad', value: 'Abouzeidabad' },
  { label: 'Above Rocks', value: 'Above Rocks' },
  { label: 'Abovyan', value: 'Abovyan' },
  { label: 'Abovyan', value: 'Abovyan' },
  { label: 'Aboyne', value: 'Aboyne' },
  { label: 'Abqaiq', value: 'Abqaiq' },
  { label: 'Abra De Ilog', value: 'Abra De Ilog' },
  { label: 'Abra Pampa', value: 'Abra Pampa' },
  { label: 'Abraham’S Bay', value: 'Abraham’S Bay' },
  { label: 'Abraka', value: 'Abraka' },
  { label: 'Abram', value: 'Abram' },
  { label: 'Abram', value: 'Abram' },
  { label: 'Abram', value: 'Abram' },
  { label: 'Abram Mys', value: 'Abram Mys' },
  { label: 'Abrama', value: 'Abrama' },
  { label: 'Abramok', value: 'Abramok' },
  { label: 'Abramovka', value: 'Abramovka' },
  { label: 'Abramów', value: 'Abramów' },
  { label: 'Abrămuţ', value: 'Abrămuţ' },
  { label: 'Abrantes', value: 'Abrantes' },
  { label: 'Abrau-Dyurso', value: 'Abrau-Dyurso' },
  { label: 'Abraveses', value: 'Abraveses' },
  { label: 'Abre Campo', value: 'Abre Campo' },
  { label: 'Abrego', value: 'Abrego' },
  { label: 'Abrera', value: 'Abrera' },
  { label: 'Abreschviller', value: 'Abreschviller' },
  { label: 'Abrest', value: 'Abrest' },
  { label: 'Abreu E Lima', value: 'Abreu E Lima' },
  { label: 'Abreulândia', value: 'Abreulândia' },
  { label: 'Abreus', value: 'Abreus' },
  { label: 'Abrevadero', value: 'Abrevadero' },
  { label: 'Abriaquí', value: 'Abriaquí' },
  { label: 'Abridge', value: 'Abridge' },
  { label: 'Abrigada', value: 'Abrigada' },
  { label: 'Abrigada', value: 'Abrigada' },
  { label: 'Abrikosovka', value: 'Abrikosovka' },
  { label: 'Abrikosovo', value: 'Abrikosovo' },
  { label: 'Abriola', value: 'Abriola' },
  { label: 'Abrisham', value: 'Abrisham' },
  { label: 'Abrucena', value: 'Abrucena' },
  { label: 'Abrud', value: 'Abrud' },
  { label: 'Abrud-Sat', value: 'Abrud-Sat' },
  { label: 'Abrumand', value: 'Abrumand' },
  { label: 'Abs', value: 'Abs' },
  { label: 'Absam', value: 'Absam' },
  { label: 'Absard', value: 'Absard' },
  { label: 'Absarokee', value: 'Absarokee' },
  { label: 'Absberg', value: 'Absberg' },
  { label: 'Abscon', value: 'Abscon' },
  { label: 'Absdorf', value: 'Absdorf' },
  { label: 'Absecon', value: 'Absecon' },
  { label: 'Abshineh', value: 'Abshineh' },
  { label: 'Abstatt', value: 'Abstatt' },
  { label: 'Abtenau', value: 'Abtenau' },
  { label: 'Abtissendorf', value: 'Abtissendorf' },
  { label: 'Abtsdorf', value: 'Abtsdorf' },
  { label: 'Abtsgmünd', value: 'Abtsgmünd' },
  { label: 'Abu', value: 'Abu' },
  { label: 'Abu `Urwah', value: 'Abu `Urwah' },
  { label: 'Abū ‘Arīsh', value: 'Abū ‘Arīsh' },
  { label: 'Abu Al Hasaniya', value: 'Abu Al Hasaniya' },
  { label: 'Abū Al Maţāmīr', value: 'Abū Al Maţāmīr' },
  { label: 'Abu Dhabi Island And Internal Islands City', value: 'Abu Dhabi Island And Internal Islands City' },
  { label: 'Abu Dhabi Municipality', value: 'Abu Dhabi Municipality' },
  { label: 'Abu Fatira', value: 'Abu Fatira' },
  { label: 'Abū Ghaush', value: 'Abū Ghaush' },
  { label: 'Abu Ghraib District', value: 'Abu Ghraib District' },
  { label: 'Abū Ghurayb', value: 'Abū Ghurayb' },
  { label: 'Abu Hisani', value: 'Abu Hisani' },
  { label: 'Abu Jibeha', value: 'Abu Jibeha' },
  { label: 'Abu Musa', value: 'Abu Musa' },
  { label: 'Abu Ol Fathabad', value: 'Abu Ol Fathabad' },
  { label: 'Abu Qir', value: 'Abu Qir' },
  { label: 'Abu Qirfah', value: 'Abu Qirfah' },
  { label: 'Abū Qurqāş', value: 'Abū Qurqāş' },
  { label: 'Abu Radif', value: 'Abu Radif' },
  { label: 'Abu Road', value: 'Abu Road' },
  { label: 'Abū Shayţānah', value: 'Abū Shayţānah' },
  { label: 'Abu Shu`Ayb', value: 'Abu Shu`Ayb' },
  { label: 'Abu Simbel', value: 'Abu Simbel' },
  { label: 'Abū Tīj', value: 'Abū Tīj' },
  { label: 'Abū Zabad', value: 'Abū Zabad' },
  { label: 'Abucay', value: 'Abucay' },
  { label: 'Abucay', value: 'Abucay' },
  { label: 'Abucayan', value: 'Abucayan' },
  { label: 'Abuja', value: 'Abuja' },
  { label: 'Abuko', value: 'Abuko' },
  { label: 'Abule Egba', value: 'Abule Egba' },
  { label: 'Abulug', value: 'Abulug' },
  { label: 'Abumusa ', value: 'Abumusa ' },
  { label: 'Aburi', value: 'Aburi' },
  { label: 'Abuș', value: 'Abuș' },
  { label: 'Abusejo', value: 'Abusejo' },
  { label: 'Abut', value: 'Abut' },
  { label: 'Abut', value: 'Abut' },
  { label: 'Abuyog', value: 'Abuyog' },
  { label: 'Abuyog', value: 'Abuyog' },
  { label: 'Abuyog', value: 'Abuyog' },
  { label: 'Abuyon', value: 'Abuyon' },
  { label: 'Abwinden', value: 'Abwinden' },
  { label: 'Åby', value: 'Åby' },
  { label: 'Abyar Ali', value: 'Abyar Ali' },
  { label: 'Åbybro', value: 'Åbybro' },
  { label: 'Abyek', value: 'Abyek' },
  { label: 'Abyek', value: 'Abyek' },
  { label: 'Abyysky District', value: 'Abyysky District' },
  { label: 'Abzac', value: 'Abzac' },
  { label: 'Abzakovo', value: 'Abzakovo' },
  { label: 'Abzelilovskiy Rayon', value: 'Abzelilovskiy Rayon' },
  { label: 'Acachuén', value: 'Acachuén' },
  { label: 'Acacia Gardens', value: 'Acacia Gardens' },
  { label: 'Acacia Ridge', value: 'Acacia Ridge' },
  { label: 'Acacías', value: 'Acacías' },
  { label: 'Acacoyagua', value: 'Acacoyagua' },
  { label: 'Acadia Parish', value: 'Acadia Parish' },
  { label: 'Acahay', value: 'Acahay' },
  { label: 'Acahuasco', value: 'Acahuasco' },
  { label: 'Acahuizotla', value: 'Acahuizotla' },
  { label: 'Acaiaca', value: 'Acaiaca' },
  { label: 'Açailândia', value: 'Açailândia' },
  { label: 'Acajete', value: 'Acajete' },
  { label: 'Acajete', value: 'Acajete' },
  { label: 'Acajutiba', value: 'Acajutiba' },
  { label: 'Acajutla', value: 'Acajutla' },
  { label: 'Acala', value: 'Acala' },
  { label: 'Acalanes Ridge', value: 'Acalanes Ridge' },
  { label: 'Acalco', value: 'Acalco' },
  { label: 'Acalpican De Morelos', value: 'Acalpican De Morelos' },
  { label: 'Acámbaro', value: 'Acámbaro' },
  { label: 'Acambay', value: 'Acambay' },
  { label: 'Acamilpa', value: 'Acamilpa' },
  { label: 'Acamixtla', value: 'Acamixtla' },
  { label: 'Acanceh', value: 'Acanceh' },
  { label: 'Acandí', value: 'Acandí' },
  { label: 'Acao', value: 'Acao' },
  { label: 'Acapetahua', value: 'Acapetahua' },
  { label: 'Acapetlahuaya', value: 'Acapetlahuaya' },
  { label: 'Acaponeta', value: 'Acaponeta' },
  { label: 'Acapulco De Juárez', value: 'Acapulco De Juárez' },
  { label: 'Acará', value: 'Acará' },
  { label: 'Acarape', value: 'Acarape' },
  { label: 'Acaraú', value: 'Acaraú' },
  { label: 'Acari', value: 'Acari' },
  { label: 'Acarí', value: 'Acarí' },
  { label: 'Acarigua', value: 'Acarigua' },
  { label: 'Acâş', value: 'Acâş' },
  { label: 'Acățari', value: 'Acățari' },
  { label: 'Acate', value: 'Acate' },
  { label: 'Acatempa', value: 'Acatempa' },
  { label: 'Acatempan', value: 'Acatempan' },
  { label: 'Acatenango', value: 'Acatenango' },
  { label: 'Acateno', value: 'Acateno' },
  { label: 'Acatepec', value: 'Acatepec' },
  { label: 'Acatepec', value: 'Acatepec' },
  { label: 'Acatepec', value: 'Acatepec' },
  { label: 'Acatla', value: 'Acatla' },
  { label: 'Acatlán', value: 'Acatlán' },
  { label: 'Acatlán', value: 'Acatlán' },
  { label: 'Acatlán', value: 'Acatlán' },
  { label: 'Acatlán', value: 'Acatlán' },
  { label: 'Acatlán De Juárez Municipality', value: 'Acatlán De Juárez Municipality' },
  { label: 'Acatlán De Osorio', value: 'Acatlán De Osorio' },
  { label: 'Acatlán De Pérez Figueroa', value: 'Acatlán De Pérez Figueroa' },
  { label: 'Acatzingo', value: 'Acatzingo' },
  { label: 'Acatzingo (Acatzingo De La Piedra)', value: 'Acatzingo (Acatzingo De La Piedra)' },
  { label: 'Acauã', value: 'Acauã' },
  { label: 'Acaxochitlán', value: 'Acaxochitlán' },
  { label: 'Acaxtlahuacán De Albino Zertuche', value: 'Acaxtlahuacán De Albino Zertuche' },
  { label: 'Acayuca', value: 'Acayuca' },
  { label: 'Acayucan', value: 'Acayucan' },
  { label: 'Acazónica', value: 'Acazónica' },
  { label: 'Accadia', value: 'Accadia' },
  { label: 'Acceglio', value: 'Acceglio' },
  { label: 'Accettura', value: 'Accettura' },
  { label: 'Acciano', value: 'Acciano' },
  { label: 'Accokeek', value: 'Accokeek' },
  { label: 'Accomac', value: 'Accomac' },
  { label: 'Accomack County', value: 'Accomack County' },
  { label: 'Accompong', value: 'Accompong' },
  { label: 'Acconia', value: 'Acconia' },
  { label: 'Accra', value: 'Accra' },
  { label: 'Accrington', value: 'Accrington' },
  { label: 'Accumoli', value: 'Accumoli' },
  { label: 'Accusilian', value: 'Accusilian' },
  { label: 'Accusilian', value: 'Accusilian' },
  { label: 'Acebeda La', value: 'Acebeda La' },
  { label: 'Acebedo', value: 'Acebedo' },
  { label: 'Acebo', value: 'Acebo' },
  { label: 'Acebrón El', value: 'Acebrón El' },
  { label: 'Acedera', value: 'Acedera' },
  { label: 'Aceguá', value: 'Aceguá' },
  { label: 'Aceguá', value: 'Aceguá' },
  { label: 'Acehúche', value: 'Acehúche' },
  { label: 'Aceituna', value: 'Aceituna' },
  { label: 'Acered', value: 'Acered' },
  { label: 'Acerenza', value: 'Acerenza' },
  { label: 'Acerno', value: 'Acerno' },
  { label: 'Acerra', value: 'Acerra' },
  { label: 'Aceuchal', value: 'Aceuchal' },
  { label: 'Acevedo', value: 'Acevedo' },
  { label: 'Achacachi', value: 'Achacachi' },
  { label: 'Achachi', value: 'Achachi' },
  { label: 'Achalpur', value: 'Achalpur' },
  { label: 'Acharávi', value: 'Acharávi' },
  { label: 'Acharnés', value: 'Acharnés' },
  { label: 'Achau', value: 'Achau' },
  { label: 'Acheng', value: 'Acheng' },
  { label: 'Achenheim', value: 'Achenheim' },
  { label: 'Achenkirch', value: 'Achenkirch' },
  { label: 'Achères', value: 'Achères' },
  { label: 'Achères-La-Forêt', value: 'Achères-La-Forêt' },
  { label: 'Acherítou', value: 'Acherítou' },
  { label: 'Achern', value: 'Achern' },
  { label: 'Achham ', value: 'Achham ' },
  { label: 'Achhnera', value: 'Achhnera' },
  { label: 'Achi', value: 'Achi' },
  { label: 'Achí', value: 'Achí' },
  { label: 'Achichipico', value: 'Achichipico' },
  { label: 'Achicourt', value: 'Achicourt' },
  { label: 'Achiet-Le-Grand', value: 'Achiet-Le-Grand' },
  { label: 'Achikulak', value: 'Achikulak' },
  { label: 'Achim', value: 'Achim' },
  { label: 'Achinsk', value: 'Achinsk' },
  { label: 'Achinskiy Rayon', value: 'Achinskiy Rayon' },
  { label: 'Achiras', value: 'Achiras' },
  { label: 'Achisu', value: 'Achisu' },
  { label: 'Achit', value: 'Achit' },
  { label: 'Achkhoy-Martan', value: 'Achkhoy-Martan' },
  { label: 'Achkhoy-Martanovskiy Rayon', value: 'Achkhoy-Martanovskiy Rayon' },
  { label: 'Áchna', value: 'Áchna' },
  { label: 'Achotal De Moreno', value: 'Achotal De Moreno' },
  { label: 'Achslach', value: 'Achslach' },
  { label: 'Achstetten', value: 'Achstetten' },
  { label: 'Achtrup', value: 'Achtrup' },
  { label: 'Achuapa', value: 'Achuapa' },
  { label: 'Achutupo', value: 'Achutupo' },
  { label: 'Aci Bonaccorsi', value: 'Aci Bonaccorsi' },
  { label: 'Aci Castello', value: 'Aci Castello' },
  { label: 'Aci Catena', value: 'Aci Catena' },
  { label: 'Aci Santantonio', value: 'Aci Santantonio' },
  { label: 'Aci Trezza', value: 'Aci Trezza' },
  { label: 'Acigné', value: 'Acigné' },
  { label: 'Acıgöl', value: 'Acıgöl' },
  { label: 'Acilia-Castel Fusano-Ostia Antica', value: 'Acilia-Castel Fusano-Ostia Antica' },
  { label: 'Acıpayam', value: 'Acıpayam' },
  { label: 'Acireale', value: 'Acireale' },
  { label: 'Acitrezza', value: 'Acitrezza' },
  { label: 'Ackerman', value: 'Ackerman' },
  { label: 'Ackley', value: 'Ackley' },
  { label: 'Acklington', value: 'Acklington' },
  { label: 'Acle', value: 'Acle' },
  { label: 'Acli', value: 'Acli' },
  { label: 'Acli', value: 'Acli' },
  { label: 'Acoapa', value: 'Acoapa' },
  { label: 'Acocks Green', value: 'Acocks Green' },
  { label: 'Acolla', value: 'Acolla' },
  { label: 'Acolman De Netzahualcóyotl', value: 'Acolman De Netzahualcóyotl' },
  { label: 'Acomb', value: 'Acomb' },
  { label: 'Aconchi', value: 'Aconchi' },
  { label: 'Aconibe', value: 'Aconibe' },
  { label: 'Acontitla', value: 'Acontitla' },
  { label: 'Acopiara', value: 'Acopiara' },
  { label: 'Acopinalco Del Peñón', value: 'Acopinalco Del Peñón' },
  { label: 'Açores', value: 'Açores' },
  { label: 'Acorizal', value: 'Acorizal' },
  { label: 'Acosta', value: 'Acosta' },
  { label: 'Acoxcatlán', value: 'Acoxcatlán' },
  { label: 'Acoyapa', value: 'Acoyapa' },
  { label: 'Acoyotla', value: 'Acoyotla' },
  { label: 'Acquafondata', value: 'Acquafondata' },
  { label: 'Acquaformosa', value: 'Acquaformosa' },
  { label: 'Acquafredda', value: 'Acquafredda' },
  { label: 'Acqualagna', value: 'Acqualagna' },
  { label: 'Acquanegra Cremonese', value: 'Acquanegra Cremonese' },
  { label: 'Acquanegra Sul Chiese', value: 'Acquanegra Sul Chiese' },
  { label: 'Acquapendente', value: 'Acquapendente' },
  { label: 'Acquappesa', value: 'Acquappesa' },
  { label: 'Acquarica Del Capo', value: 'Acquarica Del Capo' },
  { label: 'Acquaro', value: 'Acquaro' },
  { label: 'Acquarossa', value: 'Acquarossa' },
  { label: 'Acquasanta Terme', value: 'Acquasanta Terme' },
  { label: 'Acquasparta', value: 'Acquasparta' },
  { label: 'Acquaviva', value: 'Acquaviva' },
  { label: 'Acquaviva', value: 'Acquaviva' },
  { label: 'Acquaviva Collecroce', value: 'Acquaviva Collecroce' },
  { label: 'Acquaviva Delle Fonti', value: 'Acquaviva Delle Fonti' },
  { label: 'Acquaviva Disernia', value: 'Acquaviva Disernia' },
  { label: 'Acquaviva Picena', value: 'Acquaviva Picena' },
  { label: 'Acquaviva Platani', value: 'Acquaviva Platani' },
  { label: 'Acquedolci', value: 'Acquedolci' },
  { label: 'Acqui Terme', value: 'Acqui Terme' },
  { label: 'Acquigny', value: 'Acquigny' },
  { label: 'Acre', value: 'Acre' },
  { label: 'Acrelândia', value: 'Acrelândia' },
  { label: 'Acres Green', value: 'Acres Green' },
  { label: 'Acreúna', value: 'Acreúna' },
  { label: 'Acri', value: 'Acri' },
  { label: 'Acriș', value: 'Acriș' },
  { label: 'Ács', value: 'Ács' },
  { label: 'Acsa', value: 'Acsa' },
  { label: 'Acteopan', value: 'Acteopan' },
  { label: 'Actipan', value: 'Actipan' },
  { label: 'Actipan De Morelos', value: 'Actipan De Morelos' },
  { label: 'Active Hill', value: 'Active Hill' },
  { label: 'Acton', value: 'Acton' },
  { label: 'Acton', value: 'Acton' },
  { label: 'Acton', value: 'Acton' },
  { label: 'Acton', value: 'Acton' },
  { label: 'Acton', value: 'Acton' },
  { label: 'Acton Park', value: 'Acton Park' },
  { label: 'Acton Vale', value: 'Acton Vale' },
  { label: 'Actopan', value: 'Actopan' },
  { label: 'Actopan', value: 'Actopan' },
  { label: 'Açu', value: 'Açu' },
  { label: 'Acuaco', value: 'Acuaco' },
  { label: 'Acuamanala', value: 'Acuamanala' },
  { label: 'Açucena', value: 'Açucena' },
  { label: 'Acueducto Fraccionamiento', value: 'Acueducto Fraccionamiento' },
  { label: 'Acuexcomac', value: 'Acuexcomac' },
  { label: 'Acuitlapan', value: 'Acuitlapan' },
  { label: 'Acuitlapilco', value: 'Acuitlapilco' },
  { label: 'Acuitlapilco', value: 'Acuitlapilco' },
  { label: 'Acuitzio', value: 'Acuitzio' },
  { label: 'Acuítzio Del Canje', value: 'Acuítzio Del Canje' },
  { label: 'Acul Du Nord', value: 'Acul Du Nord' },
  { label: 'Acula', value: 'Acula' },
  { label: 'Aculco De Espinoza', value: 'Aculco De Espinoza' },
  { label: 'Acultzingo', value: 'Acultzingo' },
  { label: 'Acurenam', value: 'Acurenam' },
  { label: 'Acushnet', value: 'Acushnet' },
  { label: 'Acushnet Center', value: 'Acushnet Center' },
  { label: 'Acuto', value: 'Acuto' },
  { label: 'Acworth', value: 'Acworth' },
  { label: 'Acxotla Del Monte', value: 'Acxotla Del Monte' },
  { label: 'Acxotla Del Río', value: 'Acxotla Del Río' },
  { label: 'Ad Dabbah', value: 'Ad Dabbah' },
  { label: 'Ad Dabbah', value: 'Ad Dabbah' },
  { label: 'Ad Dahi', value: 'Ad Dahi' },
  { label: 'Ad Dānā', value: 'Ad Dānā' },
  { label: 'Ad Darb', value: 'Ad Darb' },
  { label: 'Ad Darbāsīyah', value: 'Ad Darbāsīyah' },
  { label: 'Ad Dasmah', value: 'Ad Dasmah' },
  { label: 'Ad Dawādimī', value: 'Ad Dawādimī' },
  { label: 'Ad Dawh', value: 'Ad Dawh' },
  { label: 'Ad Dilam', value: 'Ad Dilam' },
  { label: 'Ad Dilinjāt', value: 'Ad Dilinjāt' },
  { label: 'Ad Dindar', value: 'Ad Dindar' },
  { label: 'Ad Dis', value: 'Ad Dis' },
  { label: 'Ad Dīs Ash Sharqīyah', value: 'Ad Dīs Ash Sharqīyah' },
  { label: 'Ad Dīwānīyah', value: 'Ad Dīwānīyah' },
  { label: 'Ad Douiem', value: 'Ad Douiem' },
  { label: 'Ad Dujayl', value: 'Ad Dujayl' },
  { label: 'Ad Dulu`', value: 'Ad Dulu`' },
  { label: 'Ad Dur', value: 'Ad Dur' },
  { label: 'Ad Dur`Iyah', value: 'Ad Dur`Iyah' },
  { label: 'Ad Durayhimi', value: 'Ad Durayhimi' },
  { label: 'Ad Duraykīsh', value: 'Ad Duraykīsh' },
  { label: 'Ada', value: 'Ada' },
  { label: 'Ada', value: 'Ada' },
  { label: 'Ada', value: 'Ada' },
  { label: 'Ada County', value: 'Ada County' },
  { label: 'Adachi Ku', value: 'Adachi Ku' },
  { label: 'Adács', value: 'Adács' },
  { label: 'Adahuesca', value: 'Adahuesca' },
  { label: 'Adair County', value: 'Adair County' },
  { label: 'Adair County', value: 'Adair County' },
  { label: 'Adair County', value: 'Adair County' },
  { label: 'Adair County', value: 'Adair County' },
  { label: 'Adairsville', value: 'Adairsville' },
  { label: 'Adaklı', value: 'Adaklı' },
  { label: 'Adalaj', value: 'Adalaj' },
  { label: 'Adalar', value: 'Adalar' },
  { label: 'Adalia', value: 'Adalia' },
  { label: 'Adam', value: 'Adam' },
  { label: 'Adamantina', value: 'Adamantina' },
  { label: 'Adámas', value: 'Adámas' },
  { label: 'Adamclisi', value: 'Adamclisi' },
  { label: 'Adamivka', value: 'Adamivka' },
  { label: 'Adamiyah', value: 'Adamiyah' },
  { label: 'Adamov', value: 'Adamov' },
  { label: 'Adamove', value: 'Adamove' },
  { label: 'Adamovka', value: 'Adamovka' },
  { label: 'Adamów', value: 'Adamów' },
  { label: 'Adamówka', value: 'Adamówka' },
  { label: 'Adampur', value: 'Adampur' },
  { label: 'Adams', value: 'Adams' },
  { label: 'Adams', value: 'Adams' },
  { label: 'Adams', value: 'Adams' },
  { label: 'Adams', value: 'Adams' },
  { label: 'Adams Center', value: 'Adams Center' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams County', value: 'Adams County' },
  { label: 'Adams Morgan', value: 'Adams Morgan' },
  { label: 'Adamstown', value: 'Adamstown' },
  { label: 'Adamstown', value: 'Adamstown' },
  { label: 'Adamstown', value: 'Adamstown' },
  { label: 'Adamstown Heights', value: 'Adamstown Heights' },
  { label: 'Adamsville', value: 'Adamsville' },
  { label: 'Adamsville', value: 'Adamsville' },
  { label: 'Adămuș', value: 'Adămuș' },
  { label: 'Adamuz', value: 'Adamuz' },
  { label: 'Adana', value: 'Adana' },
  { label: 'Adânca', value: 'Adânca' },
  { label: 'Adâncata', value: 'Adâncata' },
  { label: 'Adâncata', value: 'Adâncata' },
  { label: 'Ádánd', value: 'Ádánd' },
  { label: 'Adanero', value: 'Adanero' },
  { label: 'Adani', value: 'Adani' },
  { label: 'Adão', value: 'Adão' },
  { label: 'Adapazarı', value: 'Adapazarı' },
  { label: 'Adare', value: 'Adare' },
  { label: 'Adășeni', value: 'Adășeni' },
  { label: 'Adassil', value: 'Adassil' },
  { label: 'Adaúfe', value: 'Adaúfe' },
  { label: 'Adawad', value: 'Adawad' },
  { label: 'Adda-Douéni', value: 'Adda-Douéni' },
  { label: 'Ad-Damazin', value: 'Ad-Damazin' },
  { label: 'Addanki', value: 'Addanki' },
  { label: 'Adderbury', value: 'Adderbury' },
  { label: 'Addiebrownhill', value: 'Addiebrownhill' },
  { label: 'Addiet Canna', value: 'Addiet Canna' },
  { label: 'Addingham', value: 'Addingham' },
  { label: 'Addis', value: 'Addis' },
  { label: 'Addis Ababa', value: 'Addis Ababa' },
  { label: 'Addison', value: 'Addison' },
  { label: 'Addison', value: 'Addison' },
  { label: 'Addison', value: 'Addison' },
  { label: 'Addison', value: 'Addison' },
  { label: 'Addison', value: 'Addison' },
  { label: 'Addison', value: 'Addison' },
  { label: 'Addison County', value: 'Addison County' },
  { label: 'Addlestone', value: 'Addlestone' },
  { label: 'Adea', value: 'Adea' },
  { label: 'Adéane', value: 'Adéane' },
  { label: 'Adegeest', value: 'Adegeest' },
  { label: 'Adeje', value: 'Adeje' },
  { label: 'Adel', value: 'Adel' },
  { label: 'Adel', value: 'Adel' },
  { label: 'Adela', value: 'Adela' },
  { label: 'Adelaide', value: 'Adelaide' },
  { label: 'Adelaide', value: 'Adelaide' },
  { label: 'Adelaide City Centre', value: 'Adelaide City Centre' },
  { label: 'Adelaide Hills', value: 'Adelaide Hills' },
  { label: 'Adelândia', value: 'Adelândia' },
  { label: 'Adelanto', value: 'Adelanto' },
  { label: 'Adelberg', value: 'Adelberg' },
  { label: 'Adelboden', value: 'Adelboden' },
  { label: 'Adelebsen', value: 'Adelebsen' },
  { label: 'Adelfia', value: 'Adelfia' },
  { label: 'Adelheidsdorf', value: 'Adelheidsdorf' },
  { label: 'Adelia María', value: 'Adelia María' },
  { label: 'Adelmannsfelden', value: 'Adelmannsfelden' },
  { label: 'Adelphi', value: 'Adelphi' },
  { label: 'Adelphi', value: 'Adelphi' },
  { label: 'Adelsdorf', value: 'Adelsdorf' },
  { label: 'Adelsheim', value: 'Adelsheim' },
  { label: 'Adelshofen', value: 'Adelshofen' },
  { label: 'Adelsried', value: 'Adelsried' },
  { label: 'Adelzhausen', value: 'Adelzhausen' },
  { label: 'Ademuz', value: 'Ademuz' },
  { label: 'Aden', value: 'Aden' },
  { label: 'Adenau', value: 'Adenau' },
  { label: 'Adenbüttel', value: 'Adenbüttel' },
  { label: 'Adendorf', value: 'Adendorf' },
  { label: 'Ádendro', value: 'Ádendro' },
  { label: 'Adenstedt', value: 'Adenstedt' },
  { label: 'Aderklaa', value: 'Aderklaa' },
  { label: 'Adh Dhagharir', value: 'Adh Dhagharir' },
  { label: 'Adh Dhayd', value: 'Adh Dhayd' },
  { label: 'Adh Dhibiyah', value: 'Adh Dhibiyah' },
  { label: 'Adh Dhliaah', value: 'Adh Dhliaah' },
  { label: 'Adi Keyh', value: 'Adi Keyh' },
  { label: 'Adiaké', value: 'Adiaké' },
  { label: 'Adigeni', value: 'Adigeni' },
  { label: 'Adigeni Municipality', value: 'Adigeni Municipality' },
  { label: 'Ādīgrat', value: 'Ādīgrat' },
  { label: 'Adil’-Yangiyurt', value: 'Adil’-Yangiyurt' },
  { label: 'Adilabad', value: 'Adilabad' },
  { label: 'Adilcevaz', value: 'Adilcevaz' },
  { label: 'Adilpur', value: 'Adilpur' },
  { label: 'Adimi', value: 'Adimi' },
  { label: 'Adiós', value: 'Adiós' },
  { label: 'Adir', value: 'Adir' },
  { label: 'Adirampattinam', value: 'Adirampattinam' },
  { label: 'Ādīs ‘Alem', value: 'Ādīs ‘Alem' },
  { label: 'Ādīs Zemen', value: 'Ādīs Zemen' },
  { label: 'Adiwerna', value: 'Adiwerna' },
  { label: 'Adjud', value: 'Adjud' },
  { label: 'Adjudeni', value: 'Adjudeni' },
  { label: 'Adjudu Vechi', value: 'Adjudu Vechi' },
  { label: 'Adjumani', value: 'Adjumani' },
  { label: 'Adjuntas', value: 'Adjuntas' },
  { label: 'Adjuntas', value: 'Adjuntas' },
  { label: 'Adjuntas Del Río', value: 'Adjuntas Del Río' },
  { label: 'Adlaon', value: 'Adlaon' },
  { label: 'Adlay', value: 'Adlay' },
  { label: 'Adler', value: 'Adler' },
  { label: 'Adlershof', value: 'Adlershof' },
  { label: 'Adligenswil', value: 'Adligenswil' },
  { label: 'Adlington', value: 'Adlington' },
  { label: 'Adliswil', value: 'Adliswil' },
  { label: 'Adliswil / Adliswil (Stadtkern)', value: 'Adliswil / Adliswil (Stadtkern)' },
  { label: 'Adliswil / Hündli-Zopf', value: 'Adliswil / Hündli-Zopf' },
  { label: 'Adliswil / Oberleimbach', value: 'Adliswil / Oberleimbach' },
  { label: 'Adliswil / Sonnenberg', value: 'Adliswil / Sonnenberg' },
  { label: 'Adliswil / Sood', value: 'Adliswil / Sood' },
  { label: 'Adliswil / Tal', value: 'Adliswil / Tal' },
  { label: 'Adlkofen', value: 'Adlkofen' },
  { label: 'Adlwang', value: 'Adlwang' },
  { label: 'Admannshagen-Bargeshagen', value: 'Admannshagen-Bargeshagen' },
  { label: 'Admiralteisky', value: 'Admiralteisky' },
  { label: 'Admiralty', value: 'Admiralty' },
  { label: 'Admont', value: 'Admont' },
  { label: 'Adnet', value: 'Adnet' },
  { label: 'Ado Odo', value: 'Ado Odo' },
  { label: 'Adobes', value: 'Adobes' },
  { label: 'Ado-Ekiti', value: 'Ado-Ekiti' },
  { label: 'Adolfo', value: 'Adolfo' },
  { label: 'Adolfo López Mateos', value: 'Adolfo López Mateos' },
  { label: 'Adolfo López Mateos', value: 'Adolfo López Mateos' },
  { label: 'Adolfo López Mateos', value: 'Adolfo López Mateos' },
  { label: 'Adolfo López Mateos (El Tamarindo)', value: 'Adolfo López Mateos (El Tamarindo)' },
  { label: 'Adolfo Moreno', value: 'Adolfo Moreno' },
  { label: 'Adolfo Ruiz Cortines', value: 'Adolfo Ruiz Cortines' },
  { label: 'Adolfo Ruíz Cortines', value: 'Adolfo Ruíz Cortines' },
  { label: 'Adoni', value: 'Adoni' },
  { label: 'Adony', value: 'Adony' },
  { label: 'Ador', value: 'Ador' },
  { label: 'Adorf', value: 'Adorf' },
  { label: 'Adorjan', value: 'Adorjan' },
  { label: 'Adoru', value: 'Adoru' },
  { label: 'Adra', value: 'Adra' },
  { label: 'Adra', value: 'Adra' },
  { label: 'Adrada De Haza', value: 'Adrada De Haza' },
  { label: 'Adrada De Pirón', value: 'Adrada De Pirón' },
  { label: 'Adrada La', value: 'Adrada La' },
  { label: 'Adradas', value: 'Adradas' },
  { label: 'Adrados', value: 'Adrados' },
  { label: 'Adrano', value: 'Adrano' },
  { label: 'Adrar', value: 'Adrar' },
  { label: 'Adrara San Martino', value: 'Adrara San Martino' },
  { label: 'Adrara San Rocco', value: 'Adrara San Rocco' },
  { label: 'Adrasmon', value: 'Adrasmon' },
  { label: 'Adré', value: 'Adré' },
  { label: 'Adria', value: 'Adria' },
  { label: 'Adrian', value: 'Adrian' },
  { label: 'Adrian', value: 'Adrian' },
  { label: 'Adrian', value: 'Adrian' },
  { label: 'Adrian', value: 'Adrian' },
  { label: 'Adrianópolis', value: 'Adrianópolis' },
  { label: 'Adrianu Mare', value: 'Adrianu Mare' },
  { label: 'Adrianu Mic', value: 'Adrianu Mic' },
  { label: 'Adro', value: 'Adro' },
  { label: 'Adstock', value: 'Adstock' },
  { label: 'Adsubia', value: 'Adsubia' },
  { label: 'Adtugan', value: 'Adtugan' },
  { label: 'Aduana Del Sásabe', value: 'Aduana Del Sásabe' },
  { label: 'Aduard', value: 'Aduard' },
  { label: 'Aduna', value: 'Aduna' },
  { label: 'Adunaţi', value: 'Adunaţi' },
  { label: 'Adunații-Copăceni', value: 'Adunații-Copăceni' },
  { label: 'Adur', value: 'Adur' },
  { label: 'Adustina', value: 'Adustina' },
  { label: 'Aduthurai', value: 'Aduthurai' },
  { label: 'Advance', value: 'Advance' },
  { label: 'Advance', value: 'Advance' },
  { label: 'Adwick Le Street', value: 'Adwick Le Street' },
  { label: 'Adwolf', value: 'Adwolf' },
  { label: 'Adyge-Khabl’', value: 'Adyge-Khabl’' },
  { label: 'Adygeysk', value: 'Adygeysk' },
  { label: 'Adygeysk Republican Urban Okrug', value: 'Adygeysk Republican Urban Okrug' },
  { label: 'Adzaneta', value: 'Adzaneta' },
  { label: 'Adzhamka', value: 'Adzhamka' },
  { label: 'Adzopé', value: 'Adzopé' },
  { label: 'Aegina', value: 'Aegina' },
  { label: 'Aenon Town', value: 'Aenon Town' },
  { label: 'Ærø Kommune', value: 'Ærø Kommune' },
  { label: 'Aeroflotskiy', value: 'Aeroflotskiy' },
  { label: 'Ærøskøbing', value: 'Ærøskøbing' },
  { label: 'Aerzen', value: 'Aerzen' },
  { label: 'Aesch', value: 'Aesch' },
  { label: 'Aesch', value: 'Aesch' },
  { label: 'Aeschi B. Spiez', value: 'Aeschi B. Spiez' },
  { label: 'Äetsä', value: 'Äetsä' },
  { label: 'Afa', value: 'Afa' },
  { label: 'Afak', value: 'Afak' },
  { label: 'Afanas’Yeva', value: 'Afanas’Yeva' },
  { label: 'Afanas’Yevskiy Rayon', value: 'Afanas’Yevskiy Rayon' },
  { label: 'Afántou', value: 'Afántou' },
  { label: 'Afder Zone', value: 'Afder Zone' },
  { label: 'Afega', value: 'Afega' },
  { label: 'Affalterbach', value: 'Affalterbach' },
  { label: 'Affeltrangen', value: 'Affeltrangen' },
  { label: 'Afferden', value: 'Afferden' },
  { label: 'Affery', value: 'Affery' },
  { label: 'Affi', value: 'Affi' },
  { label: 'Affile', value: 'Affile' },
  { label: 'Affing', value: 'Affing' },
  { label: 'Affoltern / Hasenbüel', value: 'Affoltern / Hasenbüel' },
  { label: 'Affoltern / Oberdorf', value: 'Affoltern / Oberdorf' },
  { label: 'Affoltern / Sonnenberg', value: 'Affoltern / Sonnenberg' },
  { label: 'Affoltern / Unterdorf', value: 'Affoltern / Unterdorf' },
  { label: 'Affoltern Am Albis', value: 'Affoltern Am Albis' },
  { label: 'Affton', value: 'Affton' },
  { label: 'Afgooye', value: 'Afgooye' },
  { label: 'Afidnés', value: 'Afidnés' },
  { label: 'Afiesl', value: 'Afiesl' },
  { label: 'Afif', value: 'Afif' },
  { label: 'Afikpo', value: 'Afikpo' },
  { label: 'Afipskiy', value: 'Afipskiy' },
  { label: 'Åfjord', value: 'Åfjord' },
  { label: 'Aflah Al Yaman', value: 'Aflah Al Yaman' },
  { label: 'Aflah Ash Shawm', value: 'Aflah Ash Shawm' },
  { label: 'Aflao', value: 'Aflao' },
  { label: 'Aflenz Kurort', value: 'Aflenz Kurort' },
  { label: 'Aflou', value: 'Aflou' },
  { label: 'Afogados Da Ingazeira', value: 'Afogados Da Ingazeira' },
  { label: 'Afonino', value: 'Afonino' },
  { label: 'Afonso Bezerra', value: 'Afonso Bezerra' },
  { label: 'Afonso Cláudio', value: 'Afonso Cláudio' },
  { label: 'Afonso Cunha', value: 'Afonso Cunha' },
  { label: 'Afragola', value: 'Afragola' },
  { label: 'Afrânio', value: 'Afrânio' },
  { label: 'Afrátion', value: 'Afrátion' },
  { label: 'Africo Nuovo', value: 'Africo Nuovo' },
  { label: 'Africo Vecchio', value: 'Africo Vecchio' },
  { label: 'Afrikanda', value: 'Afrikanda' },
  { label: 'Afrīn', value: 'Afrīn' },
  { label: 'Afrin District', value: 'Afrin District' },
  { label: 'Afşin', value: 'Afşin' },
  { label: 'Afton', value: 'Afton' },
  { label: 'Afton', value: 'Afton' },
  { label: 'Afton', value: 'Afton' },
  { label: 'Afuá', value: 'Afuá' },
  { label: 'Afula', value: 'Afula' },
  { label: 'Afumaţi', value: 'Afumaţi' },
  { label: 'Afumaţi', value: 'Afumaţi' },
  { label: 'Afus', value: 'Afus' },
  { label: 'Afusing Centro', value: 'Afusing Centro' },
  { label: 'Afusing Centro', value: 'Afusing Centro' },
  { label: 'Áfytos', value: 'Áfytos' },
  { label: 'Afzalgarh', value: 'Afzalgarh' },
  { label: 'Afzalpur', value: 'Afzalpur' },
  { label: 'Aga', value: 'Aga' },
  { label: 'Ağaçören', value: 'Ağaçören' },
  { label: 'Agadez', value: 'Agadez' },
  { label: 'Agadir', value: 'Agadir' },
  { label: 'Agadir Melloul', value: 'Agadir Melloul' },
  { label: 'Agadir-Ida-Ou-Tnan', value: 'Agadir-Ida-Ou-Tnan' },
  { label: 'Agaete', value: 'Agaete' },
  { label: 'Agalatovo', value: 'Agalatovo' },
  { label: 'Agallas', value: 'Agallas' },
  { label: 'Agalteca', value: 'Agalteca' },
  { label: 'Ag-Ambulong', value: 'Ag-Ambulong' },
  { label: 'Agami', value: 'Agami' },
  { label: 'Aganan', value: 'Aganan' },
  { label: 'Agano Shi', value: 'Agano Shi' },
  { label: 'Agapia', value: 'Agapia' },
  { label: 'Agapovka', value: 'Agapovka' },
  { label: 'Agapovskiy Rayon', value: 'Agapovskiy Rayon' },
  { label: 'Agar', value: 'Agar' },
  { label: 'Agar Panchaitan', value: 'Agar Panchaitan' },
  { label: 'Agara', value: 'Agara' },
  { label: 'Agarak', value: 'Agarak' },
  { label: 'Agarak', value: 'Agarak' },
  { label: 'Agarakadzor', value: 'Agarakadzor' },
  { label: 'Agarakavan', value: 'Agarakavan' },
  { label: 'Agârbiciu', value: 'Agârbiciu' },
  { label: 'Agard Lands/Morne Dudon', value: 'Agard Lands/Morne Dudon' },
  { label: 'Agareb', value: 'Agareb' },
  { label: 'Āgaro', value: 'Āgaro' },
  { label: 'Agartala', value: 'Agartala' },
  { label: 'Agăş', value: 'Agăş' },
  { label: 'Ágasegyháza', value: 'Ágasegyháza' },
  { label: 'Agassiz', value: 'Agassiz' },
  { label: 'Agathenburg', value: 'Agathenburg' },
  { label: 'Agativka', value: 'Agativka' },
  { label: 'Agatsuma-Gun', value: 'Agatsuma-Gun' },
  { label: 'Agawam', value: 'Agawam' },
  { label: 'Agay', value: 'Agay' },
  { label: 'Agay', value: 'Agay' },
  { label: 'Agazzano', value: 'Agazzano' },
  { label: 'Agbabu', value: 'Agbabu' },
  { label: 'Agbannawag', value: 'Agbannawag' },
  { label: 'Agbannawag', value: 'Agbannawag' },
  { label: 'Agbannawag', value: 'Agbannawag' },
  { label: 'Agbor', value: 'Agbor' },
  { label: 'Agboville', value: 'Agboville' },
  { label: 'Agcogon', value: 'Agcogon' },
  { label: 'Ağdam', value: 'Ağdam' },
  { label: 'Agdangan', value: 'Agdangan' },
  { label: 'Ağdaş', value: 'Ağdaş' },
  { label: 'Agde', value: 'Agde' },
  { label: 'Agdenes', value: 'Agdenes' },
  { label: 'Agdz', value: 'Agdz' },
  { label: 'Agdzhabedy', value: 'Agdzhabedy' },
  { label: 'Agege', value: 'Agege' },
  { label: 'Agematsu', value: 'Agematsu' },
  { label: 'Agen', value: 'Agen' },
  { label: 'Agenebode', value: 'Agenebode' },
  { label: 'Ageo Shi', value: 'Ageo Shi' },
  { label: 'Ageoshimo', value: 'Ageoshimo' },
  { label: 'Àger', value: 'Àger' },
  { label: 'Agerola', value: 'Agerola' },
  { label: 'Ageyevo', value: 'Ageyevo' },
  { label: 'Ágfalva', value: 'Ágfalva' },
  { label: 'Aggius', value: 'Aggius' },
  { label: 'Aggsbach', value: 'Aggsbach' },
  { label: 'Aggugaddah', value: 'Aggugaddah' },
  { label: 'Aghada', value: 'Aghada' },
  { label: 'Aghajari', value: 'Aghajari' },
  { label: 'Aghavnadzor', value: 'Aghavnadzor' },
  { label: 'Aghavnadzor', value: 'Aghavnadzor' },
  { label: 'Aghavnatun', value: 'Aghavnatun' },
  { label: 'Aghcheh Qayah', value: 'Aghcheh Qayah' },
  { label: 'Aghios Panteleímon', value: 'Aghios Panteleímon' },
  { label: 'Aghireș', value: 'Aghireș' },
  { label: 'Aghireșu', value: 'Aghireșu' },
  { label: 'Aghireșu-Fabrici', value: 'Aghireșu-Fabrici' },
  { label: 'Aghstafa', value: 'Aghstafa' },
  { label: 'Aghsu', value: 'Aghsu' },
  { label: 'Agía Foteiní', value: 'Agía Foteiní' },
  { label: 'Agía Galíni', value: 'Agía Galíni' },
  { label: 'Agía Kyriakí', value: 'Agía Kyriakí' },
  { label: 'Agía Marína', value: 'Agía Marína' },
  { label: 'Agía Marína', value: 'Agía Marína' },
  { label: 'Agía Marína', value: 'Agía Marína' },
  { label: 'Agía Paraskeví', value: 'Agía Paraskeví' },
  { label: 'Agía Paraskeví', value: 'Agía Paraskeví' },
  { label: 'Agía Triáda', value: 'Agía Triáda' },
  { label: 'Agía Triáda', value: 'Agía Triáda' },
  { label: 'Agía Triáda', value: 'Agía Triáda' },
  { label: 'Agía Varvára', value: 'Agía Varvára' },
  { label: 'Agía Varvára', value: 'Agía Varvára' },
  { label: 'Agiabampo Uno', value: 'Agiabampo Uno' },
  { label: 'Agidel’', value: 'Agidel’' },
  { label: 'Agigea', value: 'Agigea' },
  { label: 'Agighiol', value: 'Agighiol' },
  { label: 'Ağın', value: 'Ağın' },
  { label: 'Aginskoye', value: 'Aginskoye' },
  { label: 'Aginskoye', value: 'Aginskoye' },
  { label: 'Ágio Pnévma', value: 'Ágio Pnévma' },
  { label: 'Ágioi Anárgyroi', value: 'Ágioi Anárgyroi' },
  { label: 'Ágioi Déka', value: 'Ágioi Déka' },
  { label: 'Ágioi Theódoroi', value: 'Ágioi Theódoroi' },
  { label: 'Ágios Andréas', value: 'Ágios Andréas' },
  { label: 'Ágios Athanásios', value: 'Ágios Athanásios' },
  { label: 'Ágios Athanásios', value: 'Ágios Athanásios' },
  { label: 'Agios Dimitrios', value: 'Agios Dimitrios' },
  { label: 'Agios Dimítrios Kropiás', value: 'Agios Dimítrios Kropiás' },
  { label: 'Ágios Geórgios', value: 'Ágios Geórgios' },
  { label: 'Ágios Geórgios', value: 'Ágios Geórgios' },
  { label: 'Agios Georgis', value: 'Agios Georgis' },
  { label: 'Agios Ioannis Rentis', value: 'Agios Ioannis Rentis' },
  { label: 'Ágios Loukás', value: 'Ágios Loukás' },
  { label: 'Ágios Matthaíos', value: 'Ágios Matthaíos' },
  { label: 'Ágios Nikólaos', value: 'Ágios Nikólaos' },
  { label: 'Ágios Nikólaos', value: 'Ágios Nikólaos' },
  { label: 'Ágios Pávlos', value: 'Ágios Pávlos' },
  { label: 'Ágios Pétros', value: 'Ágios Pétros' },
  { label: 'Ágios Spyrídon', value: 'Ágios Spyrídon' },
  { label: 'Ágios Stéfanos', value: 'Ágios Stéfanos' },
  { label: 'Ágios Tomás', value: 'Ágios Tomás' },
  { label: 'Ágios Týchon', value: 'Ágios Týchon' },
  { label: 'Ágios Vasíleios', value: 'Ágios Vasíleios' },
  { label: 'Agira', value: 'Agira' },
  { label: 'Agirish', value: 'Agirish' },
  { label: 'Agkathiá', value: 'Agkathiá' },
  { label: 'Aglalana', value: 'Aglalana' },
  { label: 'Aglasterhausen', value: 'Aglasterhausen' },
  { label: 'Ağlasun', value: 'Ağlasun' },
  { label: 'Aglayan', value: 'Aglayan' },
  { label: 'Ağlı', value: 'Ağlı' },
  { label: 'Agliana', value: 'Agliana' },
  { label: 'Agliandroni-Paternella', value: 'Agliandroni-Paternella' },
  { label: 'Agliano', value: 'Agliano' },
  { label: 'Agliano Terme', value: 'Agliano Terme' },
  { label: 'Agliè', value: 'Agliè' },
  { label: 'Aglientu', value: 'Aglientu' },
  { label: 'Aglipay', value: 'Aglipay' },
  { label: 'Aglona', value: 'Aglona' },
  { label: 'Agna', value: 'Agna' },
  { label: 'Agnadello', value: 'Agnadello' },
  { label: 'Agnana Calabra', value: 'Agnana Calabra' },
  { label: 'Agnanteró', value: 'Agnanteró' },
  { label: 'Agneaux', value: 'Agneaux' },
  { label: 'Agnéby-Tiassa', value: 'Agnéby-Tiassa' },
  { label: 'Agnes Water', value: 'Agnes Water' },
  { label: 'Agnetz', value: 'Agnetz' },
  { label: 'Agnibilékrou', value: 'Agnibilékrou' },
  { label: 'Agnita', value: 'Agnita' },
  { label: 'Agno', value: 'Agno' },
  { label: 'Agno', value: 'Agno' },
  { label: 'Agno', value: 'Agno' },
  { label: 'Agnone', value: 'Agnone' },
  { label: 'Agnosine', value: 'Agnosine' },
  { label: 'Agny', value: 'Agny' },
  { label: 'Ago Are', value: 'Ago Are' },
  { label: 'Agogo', value: 'Agogo' },
  { label: 'Agol', value: 'Agol' },
  { label: 'Agolada', value: 'Agolada' },
  { label: 'Agón', value: 'Agón' },
  { label: 'Agonac', value: 'Agonac' },
  { label: 'Agoncillo', value: 'Agoncillo' },
  { label: 'Agoncillo', value: 'Agoncillo' },
  { label: 'Agon-Coutainville', value: 'Agon-Coutainville' },
  { label: 'Agoo', value: 'Agoo' },
  { label: 'Agoo', value: 'Agoo' },
  { label: 'Agordo', value: 'Agordo' },
  { label: 'Agos', value: 'Agos' },
  { label: 'Agost', value: 'Agost' },
  { label: 'Agosta', value: 'Agosta' },
  { label: 'Agostitlán', value: 'Agostitlán' },
  { label: 'Ågotnes', value: 'Ågotnes' },
  { label: 'Agoura', value: 'Agoura' },
  { label: 'Agoura Hills', value: 'Agoura Hills' },
  { label: 'Agoy', value: 'Agoy' },
  { label: 'Agpangi', value: 'Agpangi' },
  { label: 'Agra', value: 'Agra' },
  { label: 'Agra', value: 'Agra' },
  { label: 'Agramunt', value: 'Agramunt' },
  { label: 'Agrarnoye', value: 'Agrarnoye' },
  { label: 'Agrate', value: 'Agrate' },
  { label: 'Agrate Brianza', value: 'Agrate Brianza' },
  { label: 'Agrate Conturbia', value: 'Agrate Conturbia' },
  { label: 'Ágreda', value: 'Ágreda' },
  { label: 'Agres', value: 'Agres' },
  { label: 'Agrestina', value: 'Agrestina' },
  { label: 'Agriá', value: 'Agriá' },
  { label: 'Agrícola Lázaro Cárdenas', value: 'Agrícola Lázaro Cárdenas' },
  { label: 'Agricolândia', value: 'Agricolândia' },
  { label: 'Agrieș', value: 'Agrieș' },
  { label: 'Agrigento', value: 'Agrigento' },
  { label: 'Agrij', value: 'Agrij' },
  { label: 'Agrínio', value: 'Agrínio' },
  { label: 'Agriș', value: 'Agriș' },
  { label: 'Agrișteu', value: 'Agrișteu' },
  { label: 'Agrișu Mare', value: 'Agrișu Mare' },
  { label: 'Agrolândia', value: 'Agrolândia' },
  { label: 'Agrón', value: 'Agrón' },
  { label: 'Agronom', value: 'Agronom' },
  { label: 'Agronômica', value: 'Agronômica' },
  { label: 'Agrónomos Mexicanos', value: 'Agrónomos Mexicanos' },
  { label: 'Agropoli', value: 'Agropoli' },
  { label: 'Agryz', value: 'Agryz' },
  { label: 'Agryzskiy Rayon', value: 'Agryzskiy Rayon' },
  { label: 'Agsungot', value: 'Agsungot' },
  { label: 'Agua Azul', value: 'Agua Azul' },
  { label: 'Agua Azul', value: 'Agua Azul' },
  { label: 'Agua Azul', value: 'Agua Azul' },
  { label: 'Água Azul Do Norte', value: 'Água Azul Do Norte' },
  { label: 'Agua Azul Rancho', value: 'Agua Azul Rancho' },
  { label: 'Agua Bermeja', value: 'Agua Bermeja' },
  { label: 'Agua Blanca', value: 'Agua Blanca' },
  { label: 'Agua Blanca', value: 'Agua Blanca' },
  { label: 'Agua Blanca Serranía', value: 'Agua Blanca Serranía' },
  { label: 'Agua Blanca Sur', value: 'Agua Blanca Sur' },
  { label: 'Água Boa', value: 'Água Boa' },
  { label: 'Água Boa', value: 'Água Boa' },
  { label: 'Água Branca', value: 'Água Branca' },
  { label: 'Água Branca', value: 'Água Branca' },
  { label: 'Água Branca', value: 'Água Branca' },
  { label: 'Agua Buena', value: 'Agua Buena' },
  { label: 'Agua Buena', value: 'Agua Buena' },
  { label: 'Agua Caliente', value: 'Agua Caliente' },
  { label: 'Agua Caliente', value: 'Agua Caliente' },
  { label: 'Agua Caliente', value: 'Agua Caliente' },
  { label: 'Agua Caliente', value: 'Agua Caliente' },
  { label: 'Agua Caliente Grande (De Gastélum)', value: 'Agua Caliente Grande (De Gastélum)' },
  { label: 'Água Clara', value: 'Água Clara' },
  { label: 'Agua Colorada', value: 'Agua Colorada' },
  { label: 'Água Comprida', value: 'Água Comprida' },
  { label: 'Agua De Dios', value: 'Agua De Dios' },
  { label: 'Agua De Oro', value: 'Agua De Oro' },
  { label: 'Água De Pau', value: 'Água De Pau' },
  { label: 'Água De Pena', value: 'Água De Pena' },
  { label: 'Agua Del Espino', value: 'Agua Del Espino' },
  { label: 'Agua Delgada', value: 'Agua Delgada' },
  { label: 'Água Doce', value: 'Água Doce' },
  { label: 'Água Doce Do Maranhão', value: 'Água Doce Do Maranhão' },
  { label: 'Água Doce Do Norte', value: 'Água Doce Do Norte' },
  { label: 'Agua Dulce', value: 'Agua Dulce' },
  { label: 'Agua Dulce', value: 'Agua Dulce' },
  { label: 'Agua Dulce', value: 'Agua Dulce' },
  { label: 'Agua Escondida', value: 'Agua Escondida' },
  { label: 'Agua Escondida', value: 'Agua Escondida' },
  { label: 'Agua Fria', value: 'Agua Fria' },
  { label: 'Agua Fría', value: 'Agua Fría' },
  { label: 'Agua Fría', value: 'Agua Fría' },
  { label: 'Agua Fría', value: 'Agua Fría' },
  { label: 'Água Fria', value: 'Água Fria' },
  { label: 'Água Fria De Goiás', value: 'Água Fria De Goiás' },
  { label: 'Agua Gorda', value: 'Agua Gorda' },
  { label: 'Água Limpa', value: 'Água Limpa' },
  { label: 'Água Longa', value: 'Água Longa' },
  { label: 'Água Nova', value: 'Água Nova' },
  { label: 'Agua Nueva', value: 'Agua Nueva' },
  { label: 'Agua Nueva', value: 'Agua Nueva' },
  { label: 'Agua Paloma', value: 'Agua Paloma' },
  { label: 'Água Preta', value: 'Água Preta' },
  { label: 'Agua Prieta', value: 'Agua Prieta' },
  { label: 'Agua Prieta', value: 'Agua Prieta' },
  { label: 'Agua Rica', value: 'Agua Rica' },
  { label: 'Agua Salada', value: 'Agua Salada' },
  { label: 'Água Santa', value: 'Água Santa' },
  { label: 'Agua Santa Del Yuna', value: 'Agua Santa Del Yuna' },
  { label: 'Agua Señora', value: 'Agua Señora' },
  { label: 'Agua Verde', value: 'Agua Verde' },
  { label: 'Agua Zarca', value: 'Agua Zarca' },
  { label: 'Agua Zarca', value: 'Agua Zarca' },
  { label: 'Agua Zarca', value: 'Agua Zarca' },
  { label: 'Agua Zarca', value: 'Agua Zarca' },
  { label: 'Aguacatán', value: 'Aguacatán' },
  { label: 'Aguacatenango', value: 'Aguacatenango' },
  { label: 'Aguachica', value: 'Aguachica' },
  { label: 'Aguada', value: 'Aguada' },
  { label: 'Aguada', value: 'Aguada' },
  { label: 'Aguada', value: 'Aguada' },
  { label: 'Aguada', value: 'Aguada' },
  { label: 'Aguada De Cima', value: 'Aguada De Cima' },
  { label: 'Aguada De Pasajeros', value: 'Aguada De Pasajeros' },
  { label: 'Aguadas', value: 'Aguadas' },
  { label: 'Aguadilla', value: 'Aguadilla' },
  { label: 'Aguadilla', value: 'Aguadilla' },
  { label: 'Aguadulce', value: 'Aguadulce' },
  { label: 'Aguadulce', value: 'Aguadulce' },
  { label: 'Aguaí', value: 'Aguaí' },
  { label: 'Agualeguas', value: 'Agualeguas' },
  { label: 'Agualeguas Nuevo León', value: 'Agualeguas Nuevo León' },
  { label: 'Agualote', value: 'Agualote' },
  { label: 'Agualva', value: 'Agualva' },
  { label: 'Aguanga', value: 'Aguanga' },
  { label: 'Aguanil', value: 'Aguanil' },
  { label: 'Aguanqueterique', value: 'Aguanqueterique' },
  { label: 'Aguarón', value: 'Aguarón' },
  { label: 'Águas Belas', value: 'Águas Belas' },
  { label: 'Águas Belas', value: 'Águas Belas' },
  { label: 'Aguas Blancas', value: 'Aguas Blancas' },
  { label: 'Aguas Blancas', value: 'Aguas Blancas' },
  { label: 'Aguas Buenas', value: 'Aguas Buenas' },
  { label: 'Aguas Buenas', value: 'Aguas Buenas' },
  { label: 'Aguas Buenas', value: 'Aguas Buenas' },
  { label: 'Aguas Calientes', value: 'Aguas Calientes' },
  { label: 'Aguas Cándidas', value: 'Aguas Cándidas' },
  { label: 'Aguas Claras', value: 'Aguas Claras' },
  { label: 'Aguas Corrientes', value: 'Aguas Corrientes' },
  { label: 'Águas Da Prata', value: 'Águas Da Prata' },
  { label: 'Águas De Chapecó', value: 'Águas De Chapecó' },
  { label: 'Águas De Lindóia', value: 'Águas De Lindóia' },
  { label: 'Águas De Santa Bárbara', value: 'Águas De Santa Bárbara' },
  { label: 'Águas De São Pedro', value: 'Águas De São Pedro' },
  { label: 'Aguas Del Padre', value: 'Aguas Del Padre' },
  { label: 'Águas Formosas', value: 'Águas Formosas' },
  { label: 'Águas Frias', value: 'Águas Frias' },
  { label: 'Águas Lindas De Goiás', value: 'Águas Lindas De Goiás' },
  { label: 'Águas Mornas', value: 'Águas Mornas' },
  { label: 'Águas Santas', value: 'Águas Santas' },
  { label: 'Aguas Verdes', value: 'Aguas Verdes' },
  { label: 'Águas Vermelhas', value: 'Águas Vermelhas' },
  { label: 'Aguasal', value: 'Aguasal' },
  { label: 'Aguascalientes', value: 'Aguascalientes' },
  { label: 'Aguatón', value: 'Aguatón' },
  { label: 'Aguaviva', value: 'Aguaviva' },
  { label: 'Aguazul', value: 'Aguazul' },
  { label: 'Aguçadoura', value: 'Aguçadoura' },
  { label: 'Agudo', value: 'Agudo' },
  { label: 'Agudo', value: 'Agudo' },
  { label: 'Agudos', value: 'Agudos' },
  { label: 'Agudos Do Sul', value: 'Agudos Do Sul' },
  { label: 'Águeda', value: 'Águeda' },
  { label: 'Aguelmous', value: 'Aguelmous' },
  { label: 'Agüero', value: 'Agüero' },
  { label: 'Agugliano', value: 'Agugliano' },
  { label: 'Agugliaro', value: 'Agugliaro' },
  { label: 'Águia Branca', value: 'Águia Branca' },
  { label: 'Aguiar', value: 'Aguiar' },
  { label: 'Aguiar Da Beira', value: 'Aguiar Da Beira' },
  { label: 'Aguiarnópolis', value: 'Aguiarnópolis' },
  { label: 'Aguié', value: 'Aguié' },
  { label: 'Aguilafuente', value: 'Aguilafuente' },
  { label: 'Aguilar', value: 'Aguilar' },
  { label: 'Aguilar', value: 'Aguilar' },
  { label: 'Aguilar De Bureba', value: 'Aguilar De Bureba' },
  { label: 'Aguilar De Campoo', value: 'Aguilar De Campoo' },
  { label: 'Aguilar De Campos', value: 'Aguilar De Campos' },
  { label: 'Aguilar De Codés', value: 'Aguilar De Codés' },
  { label: 'Aguilar De La Frontera', value: 'Aguilar De La Frontera' },
  { label: 'Aguilar De Segarra', value: 'Aguilar De Segarra' },
  { label: 'Aguilar Del Alfambra', value: 'Aguilar Del Alfambra' },
  { label: 'Aguilar Del Río Alhama', value: 'Aguilar Del Río Alhama' },
  { label: 'Aguilares', value: 'Aguilares' },
  { label: 'Aguilares', value: 'Aguilares' },
  { label: 'Águilas', value: 'Águilas' },
  { label: 'Aguilera', value: 'Aguilera' },
  { label: 'Aguililla', value: 'Aguililla' },
  { label: 'Aguilita', value: 'Aguilita' },
  { label: 'Aguilón', value: 'Aguilón' },
  { label: 'Agüimes', value: 'Agüimes' },
  { label: 'Aguining', value: 'Aguining' },
  { label: 'Aguisan', value: 'Aguisan' },
  { label: 'Agul’Skiy Rayon', value: 'Agul’Skiy Rayon' },
  { label: 'Agullana', value: 'Agullana' },
  { label: 'Agullent', value: 'Agullent' },
  { label: 'Agulo', value: 'Agulo' },
  { label: 'Agulu', value: 'Agulu' },
  { label: 'Agupit', value: 'Agupit' },
  { label: 'Agusan', value: 'Agusan' },
  { label: 'Agustín Codazzi', value: 'Agustín Codazzi' },
  { label: 'Agustín De Iturbide', value: 'Agustín De Iturbide' },
  { label: 'Agutaya', value: 'Agutaya' },
  { label: 'Agvali', value: 'Agvali' },
  { label: 'Aham', value: 'Aham' },
  { label: 'Ahar', value: 'Ahar' },
  { label: 'Ahaus', value: 'Ahaus' },
  { label: 'Ahausen', value: 'Ahausen' },
  { label: 'Ahel', value: 'Ahel' },
  { label: 'Aheloy', value: 'Aheloy' },
  { label: 'Aheri', value: 'Aheri' },
  { label: 'Ahero', value: 'Ahero' },
  { label: 'Ahetze', value: 'Ahetze' },
  { label: 'Ahfir', value: 'Ahfir' },
  { label: 'Ahigal', value: 'Ahigal' },
  { label: 'Ahigal De Los Aceiteros', value: 'Ahigal De Los Aceiteros' },
  { label: 'Ahigal De Villarino', value: 'Ahigal De Villarino' },
  { label: 'Ahillones', value: 'Ahillones' },
  { label: 'Ahipara', value: 'Ahipara' },
  { label: 'Ahırlı', value: 'Ahırlı' },
  { label: 'Ahlat', value: 'Ahlat' },
  { label: 'Ahlbeck', value: 'Ahlbeck' },
  { label: 'Ahlden', value: 'Ahlden' },
  { label: 'Ahlen', value: 'Ahlen' },
  { label: 'Ahlerstedt', value: 'Ahlerstedt' },
  { label: 'Ahlsdorf', value: 'Ahlsdorf' },
  { label: 'Ahmadabad', value: 'Ahmadabad' },
  { label: 'Ahmadabad-E Mostowfi', value: 'Ahmadabad-E Mostowfi' },
  { label: 'Ahmadabad-E Tappeh', value: 'Ahmadabad-E Tappeh' },
  { label: 'Ahmad-Abad-E-Solat', value: 'Ahmad-Abad-E-Solat' },
  { label: 'Ahmadpur', value: 'Ahmadpur' },
  { label: 'Ahmadpur Sial', value: 'Ahmadpur Sial' },
  { label: 'Ahmadsargurab', value: 'Ahmadsargurab' },
  { label: 'Ahmedabad', value: 'Ahmedabad' },
  { label: 'Ahmednagar', value: 'Ahmednagar' },
  { label: 'Ahmedpur', value: 'Ahmedpur' },
  { label: 'Ahmedpur East', value: 'Ahmedpur East' },
  { label: 'Ahmetli', value: 'Ahmetli' },
  { label: 'Ahnsbeck', value: 'Ahnsbeck' },
  { label: 'Ahnsen', value: 'Ahnsen' },
  { label: 'Ahoada', value: 'Ahoada' },
  { label: 'Ahoghill', value: 'Ahoghill' },
  { label: 'Aholfing', value: 'Aholfing' },
  { label: 'Aholming', value: 'Aholming' },
  { label: 'Ahome', value: 'Ahome' },
  { label: 'Ahorn', value: 'Ahorn' },
  { label: 'Ahorn', value: 'Ahorn' },
  { label: 'Ahoskie', value: 'Ahoskie' },
  { label: 'Ahram', value: 'Ahram' },
  { label: 'Ahraura', value: 'Ahraura' },
  { label: 'Ahrbrück', value: 'Ahrbrück' },
  { label: 'Ahrensbök', value: 'Ahrensbök' },
  { label: 'Ahrensburg', value: 'Ahrensburg' },
  { label: 'Ahsen-Oetzen', value: 'Ahsen-Oetzen' },
  { label: 'Ahtanum', value: 'Ahtanum' },
  { label: 'Ähtäri', value: 'Ähtäri' },
  { label: 'Ahtopol', value: 'Ahtopol' },
  { label: 'Ahu Tappeh', value: 'Ahu Tappeh' },
  { label: 'Ahuacachahue (Ndogyo Itún Tichi)', value: 'Ahuacachahue (Ndogyo Itún Tichi)' },
  { label: 'Ahuacatán', value: 'Ahuacatán' },
  { label: 'Ahuacatitlán', value: 'Ahuacatitlán' },
  { label: 'Ahuacatitlán Cuarto Y Quinto Cuartel', value: 'Ahuacatitlán Cuarto Y Quinto Cuartel' },
  { label: 'Ahuacatlán', value: 'Ahuacatlán' },
  { label: 'Ahuacatlán', value: 'Ahuacatlán' },
  { label: 'Ahuacatlán', value: 'Ahuacatlán' },
  { label: 'Ahuacatlán De Guadalupe', value: 'Ahuacatlán De Guadalupe' },
  { label: 'Ahuachapán', value: 'Ahuachapán' },
  { label: 'Ahuacuotzingo', value: 'Ahuacuotzingo' },
  { label: 'Ahuajutla', value: 'Ahuajutla' },
  { label: 'Ahualulco De Mercado Municipality', value: 'Ahualulco De Mercado Municipality' },
  { label: 'Ahualulco Del Sonido Trece', value: 'Ahualulco Del Sonido Trece' },
  { label: 'Ahuas', value: 'Ahuas' },
  { label: 'Ahuatempan', value: 'Ahuatempan' },
  { label: 'Ahuateno', value: 'Ahuateno' },
  { label: 'Ahuatepec', value: 'Ahuatepec' },
  { label: 'Ahuatepec De Camino', value: 'Ahuatepec De Camino' },
  { label: 'Ahuatepec Pueblo', value: 'Ahuatepec Pueblo' },
  { label: 'Ahuatitla', value: 'Ahuatitla' },
  { label: 'Ahuatlán', value: 'Ahuatlán' },
  { label: 'Ahuatlán', value: 'Ahuatlán' },
  { label: 'Ahuaxintitla', value: 'Ahuaxintitla' },
  { label: 'Ahuazotepec', value: 'Ahuazotepec' },
  { label: 'Ahuehuepan', value: 'Ahuehuepan' },
  { label: 'Ahuehuetitla', value: 'Ahuehuetitla' },
  { label: 'Ahuehuetitla', value: 'Ahuehuetitla' },
  { label: 'Ahuehuetitlán', value: 'Ahuehuetitlán' },
  { label: 'Ahuehuetzingo', value: 'Ahuehuetzingo' },
  { label: 'Ahuehueyo Primero Centro', value: 'Ahuehueyo Primero Centro' },
  { label: 'Ahuetita De Abajo', value: 'Ahuetita De Abajo' },
  { label: 'Ahuexotitlán', value: 'Ahuexotitlán' },
  { label: 'Ahueyahualco', value: 'Ahueyahualco' },
  { label: 'Ahuihuiyuco', value: 'Ahuihuiyuco' },
  { label: 'Ahuillé', value: 'Ahuillé' },
  { label: 'Āhuimanu', value: 'Āhuimanu' },
  { label: 'Ahuirán', value: 'Ahuirán' },
  { label: 'Ahuisculco', value: 'Ahuisculco' },
  { label: 'Ahumada', value: 'Ahumada' },
  { label: 'Ahun', value: 'Ahun' },
  { label: 'Åhus', value: 'Åhus' },
  { label: 'Ahuy', value: 'Ahuy' },
  { label: 'Ahvaz', value: 'Ahvaz' },
  { label: 'Ahwa', value: 'Ahwa' },
  { label: 'Ahwahnee', value: 'Ahwahnee' },
  { label: 'Ahwar', value: 'Ahwar' },
  { label: 'Ahwatukee Foothills', value: 'Ahwatukee Foothills' },
  { label: 'Aia', value: 'Aia' },
  { label: 'Aianí', value: 'Aianí' },
  { label: 'Aiánteio', value: 'Aiánteio' },
  { label: 'Aībak', value: 'Aībak' },
  { label: 'Aibar/Oibar', value: 'Aibar/Oibar' },
  { label: 'Aibl', value: 'Aibl' },
  { label: 'Aibonito', value: 'Aibonito' },
  { label: 'Aibonito', value: 'Aibonito' },
  { label: 'Aichach', value: 'Aichach' },
  { label: 'Aichelberg', value: 'Aichelberg' },
  { label: 'Aichen', value: 'Aichen' },
  { label: 'Aichhalden', value: 'Aichhalden' },
  { label: 'Aichkirchen', value: 'Aichkirchen' },
  { label: 'Aichstetten', value: 'Aichstetten' },
  { label: 'Aicurzio', value: 'Aicurzio' },
  { label: 'Aidalpur', value: 'Aidalpur' },
  { label: 'Aidenbach', value: 'Aidenbach' },
  { label: 'Aidhausen', value: 'Aidhausen' },
  { label: 'Aidipsós', value: 'Aidipsós' },
  { label: 'Aidlingen', value: 'Aidlingen' },
  { label: 'Aidomaggiore', value: 'Aidomaggiore' },
  { label: 'Aidone', value: 'Aidone' },
  { label: 'Aiea', value: 'Aiea' },
  { label: 'Aielli', value: 'Aielli' },
  { label: 'Aiello Calabro', value: 'Aiello Calabro' },
  { label: 'Aiello Del Friuli', value: 'Aiello Del Friuli' },
  { label: 'Aiello Del Sabato', value: 'Aiello Del Sabato' },
  { label: 'Aielo De Malferit', value: 'Aielo De Malferit' },
  { label: 'Aielo De Rugat', value: 'Aielo De Rugat' },
  { label: 'Aieta', value: 'Aieta' },
  { label: 'Aiffres', value: 'Aiffres' },
  { label: 'Aigáleo', value: 'Aigáleo' },
  { label: 'Aigen Im Ennstal', value: 'Aigen Im Ennstal' },
  { label: 'Aigen Im Mühlkreis', value: 'Aigen Im Mühlkreis' },
  { label: 'Aigínio', value: 'Aigínio' },
  { label: 'Aígio', value: 'Aígio' },
  { label: 'Aigle', value: 'Aigle' },
  { label: 'Aigle District', value: 'Aigle District' },
  { label: 'Aiglemont', value: 'Aiglemont' },
  { label: 'Aiglsbach', value: 'Aiglsbach' },
  { label: 'Aigné', value: 'Aigné' },
  { label: 'Aigre', value: 'Aigre' },
  { label: 'Aigrefeuille-Sur-Maine', value: 'Aigrefeuille-Sur-Maine' },
  { label: 'Aiguá', value: 'Aiguá' },
  { label: 'Aiguafreda', value: 'Aiguafreda' },
  { label: 'Aiguamúrcia', value: 'Aiguamúrcia' },
  { label: 'Aiguaviva', value: 'Aiguaviva' },
  { label: 'Aigueblanche', value: 'Aigueblanche' },
  { label: 'Aiguefonde', value: 'Aiguefonde' },
  { label: 'Aigueperse', value: 'Aigueperse' },
  { label: 'Aigües', value: 'Aigües' },
  { label: 'Aigues-Mortes', value: 'Aigues-Mortes' },
  { label: 'Aigues-Vives', value: 'Aigues-Vives' },
  { label: 'Aiguillon', value: 'Aiguillon' },
  { label: 'Aigurande', value: 'Aigurande' },
  { label: 'Aiken', value: 'Aiken' },
  { label: 'Aiken County', value: 'Aiken County' },
  { label: 'Ailan Mubage', value: 'Ailan Mubage' },
  { label: 'Ailano', value: 'Ailano' },
  { label: 'Aileu', value: 'Aileu' },
  { label: 'Ailigandí', value: 'Ailigandí' },
  { label: 'Aillant-Sur-Tholon', value: 'Aillant-Sur-Tholon' },
  { label: 'Aillevillers-Et-Lyaumont', value: 'Aillevillers-Et-Lyaumont' },
  { label: 'Ailly-Sur-Noye', value: 'Ailly-Sur-Noye' },
  { label: 'Ailly-Sur-Somme', value: 'Ailly-Sur-Somme' },
  { label: 'Ailoche', value: 'Ailoche' },
  { label: 'Aimala', value: 'Aimala' },
  { label: 'Aimargues', value: 'Aimargues' },
  { label: 'Aime', value: 'Aime' },
  { label: 'Aimorés', value: 'Aimorés' },
  { label: 'Ain', value: 'Ain' },
  { label: 'Aín', value: 'Aín' },
  { label: 'Aïn Abid', value: 'Aïn Abid' },
  { label: 'Ain Albaraha', value: 'Ain Albaraha' },
  { label: 'Aïn Arnat', value: 'Aïn Arnat' },
  { label: 'Aïn Beïda', value: 'Aïn Beïda' },
  { label: 'Ain Ben Tili', value: 'Ain Ben Tili' },
  { label: 'Aïn Beni Mathar', value: 'Aïn Beni Mathar' },
  { label: 'Aïn Benian', value: 'Aïn Benian' },
  { label: 'Aïn Bessem', value: 'Aïn Bessem' },
  { label: 'Aïn Boucif', value: 'Aïn Boucif' },
  { label: 'Aïn Defla', value: 'Aïn Defla' },
  { label: 'Aïn Deheb', value: 'Aïn Deheb' },
  { label: 'Ain Ebel', value: 'Ain Ebel' },
  { label: 'Aïn El Bell', value: 'Aïn El Bell' },
  { label: 'Aïn El Berd District', value: 'Aïn El Berd District' },
  { label: 'Aïn El Bya', value: 'Aïn El Bya' },
  { label: 'Aïn El Hadjar', value: 'Aïn El Hadjar' },
  { label: 'Aïn El Hadjel', value: 'Aïn El Hadjel' },
  { label: 'Aïn El Hammam', value: 'Aïn El Hammam' },
  { label: 'Aïn El Melh', value: 'Aïn El Melh' },
  { label: 'Aïn El Turk', value: 'Aïn El Turk' },
  { label: 'Aïn Fakroun', value: 'Aïn Fakroun' },
  { label: 'Aïn Kercha', value: 'Aïn Kercha' },
  { label: 'Aïn Leuh', value: 'Aïn Leuh' },
  { label: 'Aïn Merane', value: 'Aïn Merane' },
  { label: 'Aïn Oussera', value: 'Aïn Oussera' },
  { label: 'Aïn Sefra', value: 'Aïn Sefra' },
  { label: 'Aïn Smara', value: 'Aïn Smara' },
  { label: 'Ain Sukhna', value: 'Ain Sukhna' },
  { label: 'Aïn Taya', value: 'Aïn Taya' },
  { label: 'Aïn Temouchent', value: 'Aïn Temouchent' },
  { label: 'Aïn Touta', value: 'Aïn Touta' },
  { label: 'Ainaloa', value: 'Ainaloa' },
  { label: 'Ainaro', value: 'Ainaro' },
  { label: 'Ainay-Le-Château', value: 'Ainay-Le-Château' },
  { label: 'Ainaži', value: 'Ainaži' },
  { label: 'Aindling', value: 'Aindling' },
  { label: 'Ainet', value: 'Ainet' },
  { label: 'Ainring', value: 'Ainring' },
  { label: 'Aínsa', value: 'Aínsa' },
  { label: 'Aínsa-Sobrarbe', value: 'Aínsa-Sobrarbe' },
  { label: 'Ainslie', value: 'Ainslie' },
  { label: 'Ainsworth', value: 'Ainsworth' },
  { label: 'Ainzón', value: 'Ainzón' },
  { label: 'Aioi', value: 'Aioi' },
  { label: 'Aioi Shi', value: 'Aioi Shi' },
  { label: 'Aioun', value: 'Aioun' },
  { label: 'Aipe', value: 'Aipe' },
  { label: 'Aiquara', value: 'Aiquara' },
  { label: 'Aiquile', value: 'Aiquile' },
  { label: 'Air Force Academy', value: 'Air Force Academy' },
  { label: 'Aira Shi', value: 'Aira Shi' },
  { label: 'Airaines', value: 'Airaines' },
  { label: 'Airali', value: 'Airali' },
  { label: 'Airasca', value: 'Airasca' },
  { label: 'Airdrie', value: 'Airdrie' },
  { label: 'Airdrie', value: 'Airdrie' },
  { label: 'Airds', value: 'Airds' },
  { label: 'Aire-La-Ville', value: 'Aire-La-Ville' },
  { label: 'Aires Puros', value: 'Aires Puros' },
  { label: 'Aire-Sur-La-Lys', value: 'Aire-Sur-La-Lys' },
  { label: 'Airlie Beach', value: 'Airlie Beach' },
  { label: 'Airmont', value: 'Airmont' },
  { label: 'Airmyn', value: 'Airmyn' },
  { label: 'Airola', value: 'Airola' },
  { label: 'Airole', value: 'Airole' },
  { label: 'Airoli', value: 'Airoli' },
  { label: 'Airolo', value: 'Airolo' },
  { label: 'Airport West', value: 'Airport West' },
  { label: 'Airth', value: 'Airth' },
  { label: 'Airuno', value: 'Airuno' },
  { label: 'Airvault', value: 'Airvault' },
  { label: 'Airwa', value: 'Airwa' },
  { label: 'Airway Heights', value: 'Airway Heights' },
  { label: 'Airy Castle', value: 'Airy Castle' },
  { label: 'Aisa', value: 'Aisa' },
  { label: 'Aisai-Shi', value: 'Aisai-Shi' },
  { label: 'Aiseau', value: 'Aiseau' },
  { label: 'Aiserey', value: 'Aiserey' },
  { label: 'Aislingen', value: 'Aislingen' },
  { label: 'Aisone', value: 'Aisone' },
  { label: 'Aistala', value: 'Aistala' },
  { label: 'Aistersheim', value: 'Aistersheim' },
  { label: 'Aita Mare', value: 'Aita Mare' },
  { label: 'Aitape', value: 'Aitape' },
  { label: 'Aitape Lumi', value: 'Aitape Lumi' },
  { label: 'Aitkenvale', value: 'Aitkenvale' },
  { label: 'Aitkin', value: 'Aitkin' },
  { label: 'Aitkin County', value: 'Aitkin County' },
  { label: 'Aitolikó', value: 'Aitolikó' },
  { label: 'Aiton', value: 'Aiton' },
  { label: 'Aiton', value: 'Aiton' },
  { label: 'Aitona', value: 'Aitona' },
  { label: 'Aitoo', value: 'Aitoo' },
  { label: 'Aitrach', value: 'Aitrach' },
  { label: 'Aitrang', value: 'Aitrang' },
  { label: 'Aiuaba', value: 'Aiuaba' },
  { label: 'Aiud', value: 'Aiud' },
  { label: 'Aiudul De Sus', value: 'Aiudul De Sus' },
  { label: 'Aiuruoca', value: 'Aiuruoca' },
  { label: 'Aix-En-Othe', value: 'Aix-En-Othe' },
  { label: 'Aix-En-Provence', value: 'Aix-En-Provence' },
  { label: 'Aixe-Sur-Vienne', value: 'Aixe-Sur-Vienne' },
  { label: 'Aix-Les-Bains', value: 'Aix-Les-Bains' },
  { label: 'Aix-Noulette', value: 'Aix-Noulette' },
  { label: 'Aíyira', value: 'Aíyira' },
  { label: 'Aizarnazabal', value: 'Aizarnazabal' },
  { label: 'Aizawl', value: 'Aizawl' },
  { label: 'Aizenay', value: 'Aizenay' },
  { label: 'Aizkraukle', value: 'Aizkraukle' },
  { label: 'Aizpute', value: 'Aizpute' },
  { label: 'Aizu-Wakamatsu Shi', value: 'Aizu-Wakamatsu Shi' },
  { label: 'Ajā', value: 'Ajā' },
  { label: 'Ajabshir', value: 'Ajabshir' },
  { label: 'Ajaccio', value: 'Ajaccio' },
  { label: 'Ajacuba', value: 'Ajacuba' },
  { label: 'Ajaigarh', value: 'Ajaigarh' },
  { label: 'Ajain', value: 'Ajain' },
  { label: 'Ajak', value: 'Ajak' },
  { label: 'Ajalpan', value: 'Ajalpan' },
  { label: 'Ajalvir', value: 'Ajalvir' },
  { label: 'Ajamil', value: 'Ajamil' },
  { label: 'Ajamil De Cameros', value: 'Ajamil De Cameros' },
  { label: 'Ajangiz', value: 'Ajangiz' },
  { label: 'Ajara', value: 'Ajara' },
  { label: 'Ajasse Ipo', value: 'Ajasse Ipo' },
  { label: 'Ajax', value: 'Ajax' },
  { label: 'Ajdabiya', value: 'Ajdabiya' },
  { label: 'Ajdovščina', value: 'Ajdovščina' },
  { label: 'Ajijic', value: 'Ajijic' },
  { label: 'Ajilhó', value: 'Ajilhó' },
  { label: 'Ajin', value: 'Ajin' },
  { label: 'Ajitgarh', value: 'Ajitgarh' },
  { label: 'Ajjampur', value: 'Ajjampur' },
  { label: 'Ajka', value: 'Ajka' },
  { label: 'Ajkai Járás', value: 'Ajkai Járás' },
  { label: 'Ajlūn', value: 'Ajlūn' },
  { label: 'Ajman', value: 'Ajman' },
  { label: 'Ajman City', value: 'Ajman City' },
  { label: 'Ajmer', value: 'Ajmer' },
  { label: 'Ajmiyah', value: 'Ajmiyah' },
  { label: 'Ajnala', value: 'Ajnala' },
  { label: 'Ajo', value: 'Ajo' },
  { label: 'Ajofrín', value: 'Ajofrín' },
  { label: 'Ajorlu', value: 'Ajorlu' },
  { label: 'Ajuchitlán', value: 'Ajuchitlán' },
  { label: 'Ajuchitlán Del Progreso', value: 'Ajuchitlán Del Progreso' },
  { label: 'Ajuchitlancito', value: 'Ajuchitlancito' },
  { label: 'Ajuda', value: 'Ajuda' },
  { label: 'Ajuno', value: 'Ajuno' },
  { label: 'Ajuricaba', value: 'Ajuricaba' },
  { label: 'Ajuterique', value: 'Ajuterique' },
  { label: 'Ajuy', value: 'Ajuy' },
  { label: 'Ak’Ordat', value: 'Ak’Ordat' },
  { label: 'Ak”Yar', value: 'Ak”Yar' },
  { label: 'Aka', value: 'Aka' },
  { label: 'Akaa', value: 'Akaa' },
  { label: 'Akabira', value: 'Akabira' },
  { label: 'Akabira-Shi', value: 'Akabira-Shi' },
  { label: 'Akademgorodok', value: 'Akademgorodok' },
  { label: 'Akademicheskoe', value: 'Akademicheskoe' },
  { label: 'Akademija (Kaunas)', value: 'Akademija (Kaunas)' },
  { label: 'Akaiwa Shi', value: 'Akaiwa Shi' },
  { label: 'Akáki', value: 'Akáki' },
  { label: 'Akalgarh', value: 'Akalgarh' },
  { label: 'Akalkot', value: 'Akalkot' },
  { label: 'Akalla', value: 'Akalla' },
  { label: 'Akaltara', value: 'Akaltara' },
  { label: 'Akankpa', value: 'Akankpa' },
  { label: 'Akanlu', value: 'Akanlu' },
  { label: 'Åkarp', value: 'Åkarp' },
  { label: 'Åkarp', value: 'Åkarp' },
  { label: 'Akasahebpet', value: 'Akasahebpet' },
  { label: 'Akashi', value: 'Akashi' },
  { label: 'Akashi Shi', value: 'Akashi Shi' },
  { label: 'Akasztó', value: 'Akasztó' },
  { label: 'Akbarabad', value: 'Akbarabad' },
  { label: 'Akbarpur', value: 'Akbarpur' },
  { label: 'Akbou', value: 'Akbou' },
  { label: 'Akçaabat', value: 'Akçaabat' },
  { label: 'Akçadağ', value: 'Akçadağ' },
  { label: 'Akçakale', value: 'Akçakale' },
  { label: 'Akçakent', value: 'Akçakent' },
  { label: 'Akçakoca', value: 'Akçakoca' },
  { label: 'Akdağmadeni', value: 'Akdağmadeni' },
  { label: 'Akdeniz', value: 'Akdeniz' },
  { label: 'Akdepe', value: 'Akdepe' },
  { label: 'Ak-Dovurak', value: 'Ak-Dovurak' },
  { label: 'Ake-Eze', value: 'Ake-Eze' },
  { label: 'Aken', value: 'Aken' },
  { label: 'Åkers Styckebruk', value: 'Åkers Styckebruk' },
  { label: 'Åkersberga', value: 'Åkersberga' },
  { label: 'Akersloot', value: 'Akersloot' },
  { label: 'Akert', value: 'Akert' },
  { label: 'Aketi', value: 'Aketi' },
  { label: 'Akhaldaba', value: 'Akhaldaba' },
  { label: 'Akhaldaba', value: 'Akhaldaba' },
  { label: 'Akhalgori', value: 'Akhalgori' },
  { label: 'Akhalk’Alak’I', value: 'Akhalk’Alak’I' },
  { label: 'Akhaltsikhe', value: 'Akhaltsikhe' },
  { label: 'Akhaltsikhis Munitsip’Alit’Et’I', value: 'Akhaltsikhis Munitsip’Alit’Et’I' },
  { label: 'Akhfennir', value: 'Akhfennir' },
  { label: 'Akhisar', value: 'Akhisar' },
  { label: 'Akhmet’A', value: 'Akhmet’A' },
  { label: 'Akhmet’Is Munitsip’Alit’Et’I', value: 'Akhmet’Is Munitsip’Alit’Et’I' },
  { label: 'Akhmīm', value: 'Akhmīm' },
  { label: 'Akhnur', value: 'Akhnur' },
  { label: 'Akht’Ala', value: 'Akht’Ala' },
  { label: 'Akhtanizovskaya', value: 'Akhtanizovskaya' },
  { label: 'Akhtubinsk', value: 'Akhtubinsk' },
  { label: 'Akhtubinskiy Rayon', value: 'Akhtubinskiy Rayon' },
  { label: 'Akhty', value: 'Akhty' },
  { label: 'Akhtynskiy Rayon', value: 'Akhtynskiy Rayon' },
  { label: 'Akhtyrskiy', value: 'Akhtyrskiy' },
  { label: 'Akhunovo', value: 'Akhunovo' },
  { label: 'Akhuryan', value: 'Akhuryan' },
  { label: 'Akhvakhskiy Rayon', value: 'Akhvakhskiy Rayon' },
  { label: 'Aki', value: 'Aki' },
  { label: 'Akil', value: 'Akil' },
  { label: 'Akim Oda', value: 'Akim Oda' },
  { label: 'Akim Swedru', value: 'Akim Swedru' },
  { label: 'Akimovka', value: 'Akimovka' },
  { label: 'Akıncılar', value: 'Akıncılar' },
  { label: 'Åkirkeby', value: 'Åkirkeby' },
  { label: 'Akiruno-Shi', value: 'Akiruno-Shi' },
  { label: 'Akishima-Shi', value: 'Akishima-Shi' },
  { label: 'Akita', value: 'Akita' },
  { label: 'Akita Shi', value: 'Akita Shi' },
  { label: 'Aki-Takata Shi', value: 'Aki-Takata Shi' },
  { label: 'Akividu', value: 'Akividu' },
  { label: 'Akjoujt', value: 'Akjoujt' },
  { label: 'Akkarampalle', value: 'Akkarampalle' },
  { label: 'Akkışla', value: 'Akkışla' },
  { label: 'Akko', value: 'Akko' },
  { label: 'Akkol', value: 'Akkol' },
  { label: 'Akkol’', value: 'Akkol’' },
  { label: 'Akkol’', value: 'Akkol’' },
  { label: 'Akkrum', value: 'Akkrum' },
  { label: 'Akkuş', value: 'Akkuş' },
  { label: 'Akle', value: 'Akle' },
  { label: 'Akle', value: 'Akle' },
  { label: 'Akleh', value: 'Akleh' },
  { label: 'Aklera', value: 'Aklera' },
  { label: 'Akluj', value: 'Akluj' },
  { label: 'Akmenė', value: 'Akmenė' },
  { label: 'Aknada', value: 'Aknada' },
  { label: 'Aknalich', value: 'Aknalich' },
  { label: 'Aknapur', value: 'Aknapur' },
  { label: 'Aknashen', value: 'Aknashen' },
  { label: 'Akner', value: 'Akner' },
  { label: 'Aknīste', value: 'Aknīste' },
  { label: 'Aknoul', value: 'Aknoul' },
  { label: 'Akodia', value: 'Akodia' },
  { label: 'Akola', value: 'Akola' },
  { label: 'Akola', value: 'Akola' },
  { label: 'Akolner', value: 'Akolner' },
  { label: 'Akom Ii', value: 'Akom Ii' },
  { label: 'Akono', value: 'Akono' },
  { label: 'Akonolinga', value: 'Akonolinga' },
  { label: 'Akora', value: 'Akora' },
  { label: 'Akören', value: 'Akören' },
  { label: 'Akot', value: 'Akot' },
  { label: 'Akouda', value: 'Akouda' },
  { label: 'Akoupé', value: 'Akoupé' },
  { label: 'Akpınar', value: 'Akpınar' },
  { label: 'Akrahreppur', value: 'Akrahreppur' },
  { label: 'Akraifnía', value: 'Akraifnía' },
  { label: 'Akranes', value: 'Akranes' },
  { label: 'Akrani', value: 'Akrani' },
  { label: 'Akráta', value: 'Akráta' },
  { label: 'Åkrehamn', value: 'Åkrehamn' },
  { label: 'Akriní', value: 'Akriní' },
  { label: 'Akrolímni', value: 'Akrolímni' },
  { label: 'Akron', value: 'Akron' },
  { label: 'Akron', value: 'Akron' },
  { label: 'Akron', value: 'Akron' },
  { label: 'Akron', value: 'Akron' },
  { label: 'Akron', value: 'Akron' },
  { label: 'Akron', value: 'Akron' },
  { label: 'Akropong', value: 'Akropong' },
  { label: 'Akrund', value: 'Akrund' },
  { label: 'Aksakovo', value: 'Aksakovo' },
  { label: 'Aksakovo', value: 'Aksakovo' },
  { label: 'Aksarayskiy', value: 'Aksarayskiy' },
  { label: 'Aksarka', value: 'Aksarka' },
  { label: 'Aksay', value: 'Aksay' },
  { label: 'Aksay', value: 'Aksay' },
  { label: 'Akşehir', value: 'Akşehir' },
  { label: 'Akseki', value: 'Akseki' },
  { label: 'Aksha', value: 'Aksha' },
  { label: 'Akshinskiy Rayon', value: 'Akshinskiy Rayon' },
  { label: 'Aksu', value: 'Aksu' },
  { label: 'Aksu', value: 'Aksu' },
  { label: 'Aksu', value: 'Aksu' },
  { label: 'Aksu', value: 'Aksu' },
  { label: 'Aksu', value: 'Aksu' },
  { label: 'Aksu Diqu', value: 'Aksu Diqu' },
  { label: 'Aksu-Ayuly', value: 'Aksu-Ayuly' },
  { label: 'Aksubayevskiy Rayon', value: 'Aksubayevskiy Rayon' },
  { label: 'Ak-Suu', value: 'Ak-Suu' },
  { label: 'Aktaío', value: 'Aktaío' },
  { label: 'Aktanysh', value: 'Aktanysh' },
  { label: 'Aktanyshskiy Rayon', value: 'Aktanyshskiy Rayon' },
  { label: 'Aktas', value: 'Aktas' },
  { label: 'Aktash', value: 'Aktash' },
  { label: 'Aktau', value: 'Aktau' },
  { label: 'Aktau', value: 'Aktau' },
  { label: 'Aktobe', value: 'Aktobe' },
  { label: 'Aktogay', value: 'Aktogay' },
  { label: 'Aktsyabrski', value: 'Aktsyabrski' },
  { label: 'Aktyubinskiy', value: 'Aktyubinskiy' },
  { label: 'Aku', value: 'Aku' },
  { label: 'Akumal', value: 'Akumal' },
  { label: 'Akune', value: 'Akune' },
  { label: 'Akune Shi', value: 'Akune Shi' },
  { label: 'Akunk’', value: 'Akunk’' },
  { label: 'Akunk’', value: 'Akunk’' },
  { label: 'Akurana', value: 'Akurana' },
  { label: 'Akure', value: 'Akure' },
  { label: 'Akureyri', value: 'Akureyri' },
  { label: 'Akusha', value: 'Akusha' },
  { label: 'Akushinskiy Rayon', value: 'Akushinskiy Rayon' },
  { label: 'Akutan', value: 'Akutan' },
  { label: 'Akwatia', value: 'Akwatia' },
  { label: 'Akyaka', value: 'Akyaka' },
  { label: 'Akyazı', value: 'Akyazı' },
  { label: 'Akyurt', value: 'Akyurt' },
  { label: 'Ål', value: 'Ål' },
  { label: 'Al  Hawtah', value: 'Al  Hawtah' },
  { label: 'Al `Awali', value: 'Al `Awali' },
  { label: 'Al `Ulayin', value: 'Al `Ulayin' },
  { label: 'Al `Uqul', value: 'Al `Uqul' },
  { label: 'Al `Usaylah', value: 'Al `Usaylah' },
  { label: 'Al ‘Alamayn', value: 'Al ‘Alamayn' },
  { label: 'Al ‘Amādīyah', value: 'Al ‘Amādīyah' },
  { label: 'Al ‘Amārah', value: 'Al ‘Amārah' },
  { label: 'Al ‘Āqir', value: 'Al ‘Āqir' },
  { label: 'Al ‘Ayyāţ', value: 'Al ‘Ayyāţ' },
  { label: 'Al ‘Azīzīyah', value: 'Al ‘Azīzīyah' },
  { label: 'Al ‘Azīzīyah', value: 'Al ‘Azīzīyah' },
  { label: 'Al ‘Inān', value: 'Al ‘Inān' },
  { label: 'Al ‘Udayn', value: 'Al ‘Udayn' },
  { label: 'Al Aaroui', value: 'Al Aaroui' },
  { label: 'Al Ābār', value: 'Al Ābār' },
  { label: 'Al Abdiyah', value: 'Al Abdiyah' },
  { label: 'Al Abr', value: 'Al Abr' },
  { label: 'Al Abyār', value: 'Al Abyār' },
  { label: 'Al Adel', value: 'Al Adel' },
  { label: 'Al Adl', value: 'Al Adl' },
  { label: 'Al Aḩmadī', value: 'Al Aḩmadī' },
  { label: 'Al Ain City', value: 'Al Ain City' },
  { label: 'Al Ain Municipality', value: 'Al Ain Municipality' },
  { label: 'Al Ajaylat', value: 'Al Ajaylat' },
  { label: 'Al Akhal', value: 'Al Akhal' },
  { label: 'Al Arsh', value: 'Al Arsh' },
  { label: 'Al Arţāwīyah', value: 'Al Arţāwīyah' },
  { label: 'Al Ashah', value: 'Al Ashah' },
  { label: 'Al Ashraf', value: 'Al Ashraf' },
  { label: 'Al Atārib', value: 'Al Atārib' },
  { label: 'Al Awjām', value: 'Al Awjām' },
  { label: 'Al Azraq Ash Shamālī', value: 'Al Azraq Ash Shamālī' },
  { label: 'Al Bāb', value: 'Al Bāb' },
  { label: 'Al Badārī', value: 'Al Badārī' },
  { label: 'Al Badawi', value: 'Al Badawi' },
  { label: 'Al Bahah', value: 'Al Bahah' },
  { label: 'Al Bājūr', value: 'Al Bājūr' },
  { label: 'Al Balad', value: 'Al Balad' },
  { label: 'Al Baladiyat', value: 'Al Baladiyat' },
  { label: 'Al Balyanā', value: 'Al Balyanā' },
  { label: 'Al Barabir', value: 'Al Barabir' },
  { label: 'Al Bardiyah', value: 'Al Bardiyah' },
  { label: 'Al Başrah Al Qadīmah', value: 'Al Başrah Al Qadīmah' },
  { label: 'Al Batayih', value: 'Al Batayih' },
  { label: 'Al Baţţālīyah', value: 'Al Baţţālīyah' },
  { label: 'Al Bawīţī', value: 'Al Bawīţī' },
  { label: 'Al Bayda', value: 'Al Bayda' },
  { label: 'Al Bayda City', value: 'Al Bayda City' },
  { label: 'Al Bayḑā’', value: 'Al Bayḑā’' },
  { label: 'Al Bayḑā’', value: 'Al Bayḑā’' },
  { label: 'Al Biar', value: 'Al Biar' },
  { label: 'Al Biqa', value: 'Al Biqa' },
  { label: 'Al Bir', value: 'Al Bir' },
  { label: 'Al Birk', value: 'Al Birk' },
  { label: 'Al Bukayrīyah', value: 'Al Bukayrīyah' },
  { label: 'Al Buraiqeh', value: 'Al Buraiqeh' },
  { label: 'Al Buraykah', value: 'Al Buraykah' },
  { label: 'Al Buraymī', value: 'Al Buraymī' },
  { label: 'Al Burayqah', value: 'Al Burayqah' },
  { label: 'Al Bustan', value: 'Al Bustan' },
  { label: 'Al Dhafra', value: 'Al Dhafra' },
  { label: 'Al Dhaher', value: 'Al Dhaher' },
  { label: 'Al Dhihar', value: 'Al Dhihar' },
  { label: 'Al Faḩāḩīl', value: 'Al Faḩāḩīl' },
  { label: 'Al Fallūjah', value: 'Al Fallūjah' },
  { label: 'Al Faqirah', value: 'Al Faqirah' },
  { label: 'Al Farwānīyah', value: 'Al Farwānīyah' },
  { label: 'Al Fashn', value: 'Al Fashn' },
  { label: 'Al Fāw', value: 'Al Fāw' },
  { label: 'Al Fawwarah', value: 'Al Fawwarah' },
  { label: 'Al Faydah', value: 'Al Faydah' },
  { label: 'Al Fayyūm', value: 'Al Fayyūm' },
  { label: 'Al Fazz', value: 'Al Fazz' },
  { label: 'Al Finţās', value: 'Al Finţās' },
  { label: 'Al Fqih Ben Çalah', value: 'Al Fqih Ben Çalah' },
  { label: 'Al Fujairah City', value: 'Al Fujairah City' },
  { label: 'Al Fujairah Municipality', value: 'Al Fujairah Municipality' },
  { label: 'Al Fūlah', value: 'Al Fūlah' },
  { label: 'Al Funayţīs', value: 'Al Funayţīs' },
  { label: 'Al Furaysh', value: 'Al Furaysh' },
  { label: 'Al Fuwayliq', value: 'Al Fuwayliq' },
  { label: 'Al Garrahi', value: 'Al Garrahi' },
  { label: 'Al Ghanţū', value: 'Al Ghanţū' },
  { label: 'Al Gharith', value: 'Al Gharith' },
  { label: 'Al Ghassalah', value: 'Al Ghassalah' },
  { label: 'Al Ghaydah', value: 'Al Ghaydah' },
  { label: 'Al Ghayl', value: 'Al Ghayl' },
  { label: 'Al Ghayz̧Ah', value: 'Al Ghayz̧Ah' },
  { label: 'Al Ghulah', value: 'Al Ghulah' },
  { label: 'Al Ghuwayrīyah', value: 'Al Ghuwayrīyah' },
  { label: 'Al Ḩabīlayn', value: 'Al Ḩabīlayn' },
  { label: 'Al Had', value: 'Al Had' },
  { label: 'Al Hada', value: 'Al Hada' },
  { label: 'Al Hadā', value: 'Al Hadā' },
  { label: 'Al Ḩadd', value: 'Al Ḩadd' },
  { label: 'Al Hadrur', value: 'Al Hadrur' },
  { label: 'Al Hair', value: 'Al Hair' },
  { label: 'Al Hajjaylah', value: 'Al Hajjaylah' },
  { label: 'Al Halaqah', value: 'Al Halaqah' },
  { label: 'Al Hali', value: 'Al Hali' },
  { label: 'Al Ḩamdī', value: 'Al Ḩamdī' },
  { label: 'Al Hamimah', value: 'Al Hamimah' },
  { label: 'Al Ḩamrā’', value: 'Al Ḩamrā’' },
  { label: 'Al Hamriyah', value: 'Al Hamriyah' },
  { label: 'Al Ḩāmūl', value: 'Al Ḩāmūl' },
  { label: 'Al Hanashah', value: 'Al Hanashah' },
  { label: 'Al Ḩarāk', value: 'Al Ḩarāk' },
  { label: 'Al Harani', value: 'Al Harani' },
  { label: 'Al Hārithah', value: 'Al Hārithah' },
  { label: 'Al Harra Makkah', value: 'Al Harra Makkah' },
  { label: 'Al Hasaheisa', value: 'Al Hasaheisa' },
  { label: 'Al Ḩasakah', value: 'Al Ḩasakah' },
  { label: 'Al Hasamah', value: 'Al Hasamah' },
  { label: 'Al Hashwah', value: 'Al Hashwah' },
  { label: 'Al Hawak', value: 'Al Hawak' },
  { label: 'Al Ḩawāmidīyah', value: 'Al Ḩawāmidīyah' },
  { label: 'Al Ḩawātah', value: 'Al Ḩawātah' },
  { label: 'Al Hawiyah', value: 'Al Hawiyah' },
  { label: 'Al Haymah Ad Dakhiliyah', value: 'Al Haymah Ad Dakhiliyah' },
  { label: 'Al Haymah Al Kharijiyah', value: 'Al Haymah Al Kharijiyah' },
  { label: 'Al Ḩayy', value: 'Al Ḩayy' },
  { label: 'Al Hazm', value: 'Al Hazm' },
  { label: 'Al Hijfar', value: 'Al Hijfar' },
  { label: 'Al Hilāliyya', value: 'Al Hilāliyya' },
  { label: 'Al Ḩillah', value: 'Al Ḩillah' },
  { label: 'Al Hindīyah', value: 'Al Hindīyah' },
  { label: 'Al Hoceïma', value: 'Al Hoceïma' },
  { label: 'Al Ḩudaydah', value: 'Al Ḩudaydah' },
  { label: 'Al Hufūf', value: 'Al Hufūf' },
  { label: 'Al Humaydat', value: 'Al Humaydat' },
  { label: 'Al Husn', value: 'Al Husn' },
  { label: 'Al Isawiyah', value: 'Al Isawiyah' },
  { label: 'Al Iskan', value: 'Al Iskan' },
  { label: 'Al Jabin', value: 'Al Jabin' },
  { label: 'Al Jabriyah', value: 'Al Jabriyah' },
  { label: 'Al Jadi', value: 'Al Jadi' },
  { label: 'Al Jadīd', value: 'Al Jadīd' },
  { label: 'Al Jadidah', value: 'Al Jadidah' },
  { label: 'Al Jafariyah', value: 'Al Jafariyah' },
  { label: 'Al Jafr', value: 'Al Jafr' },
  { label: 'Al Jafr', value: 'Al Jafr' },
  { label: 'Al Jahrā’', value: 'Al Jahrā’' },
  { label: 'Al Jamaa', value: 'Al Jamaa' },
  { label: 'Al Jami`Ah', value: 'Al Jami`Ah' },
  { label: 'Al Jamimah', value: 'Al Jamimah' },
  { label: 'Al Jammālīyah', value: 'Al Jammālīyah' },
  { label: 'Al Jarādīyah', value: 'Al Jarādīyah' },
  { label: 'Al Jawah', value: 'Al Jawah' },
  { label: 'Al Jawf', value: 'Al Jawf' },
  { label: 'Al Jid`', value: 'Al Jid`' },
  { label: 'Al Jirbah', value: 'Al Jirbah' },
  { label: 'Al Jissah', value: 'Al Jissah' },
  { label: 'Al Jīzah', value: 'Al Jīzah' },
  { label: 'Al Ju`Ranah', value: 'Al Ju`Ranah' },
  { label: 'Al Jubah', value: 'Al Jubah' },
  { label: 'Al Jubayhah', value: 'Al Jubayhah' },
  { label: 'Al Jubayl', value: 'Al Jubayl' },
  { label: 'Al Jumaylīyah', value: 'Al Jumaylīyah' },
  { label: 'Al Jumūm', value: 'Al Jumūm' },
  { label: 'Al Jurayfah', value: 'Al Jurayfah' },
  { label: 'Al Karāmah', value: 'Al Karāmah' },
  { label: 'Al Karbus', value: 'Al Karbus' },
  { label: 'Al Kawa', value: 'Al Kawa' },
  { label: 'Al Kawahilah', value: 'Al Kawahilah' },
  { label: 'Al Khabt', value: 'Al Khabt' },
  { label: 'Al Khābūrah', value: 'Al Khābūrah' },
  { label: 'Al Khadra Jizan', value: 'Al Khadra Jizan' },
  { label: 'Al Khadra Makkah', value: 'Al Khadra Makkah' },
  { label: 'Al Khafjī', value: 'Al Khafjī' },
  { label: 'Al Khalas', value: 'Al Khalas' },
  { label: 'Al Khalq', value: 'Al Khalq' },
  { label: 'Al Khamrah', value: 'Al Khamrah' },
  { label: 'Al Khāniq', value: 'Al Khāniq' },
  { label: 'Al Khānkah', value: 'Al Khānkah' },
  { label: 'Al Kharabah Jizan', value: 'Al Kharabah Jizan' },
  { label: 'Al Kharadilah', value: 'Al Kharadilah' },
  { label: 'Al Khārijah', value: 'Al Khārijah' },
  { label: 'Al Kharj', value: 'Al Kharj' },
  { label: 'Al Kharma', value: 'Al Kharma' },
  { label: 'Al Khashabiyah', value: 'Al Khashabiyah' },
  { label: 'Al Khawr', value: 'Al Khawr' },
  { label: 'Al Khaydar', value: 'Al Khaydar' },
  { label: 'Al Khayf', value: 'Al Khayf' },
  { label: 'Al Khinzīrah', value: 'Al Khinzīrah' },
  { label: 'Al Khubah', value: 'Al Khubah' },
  { label: 'Al Khulasah', value: 'Al Khulasah' },
  { label: 'Al Khums', value: 'Al Khums' },
  { label: 'Al Kidwah', value: 'Al Kidwah' },
  { label: 'Al Kiremit Al ‘Arakiyyīn', value: 'Al Kiremit Al ‘Arakiyyīn' },
  { label: 'Al Kirs', value: 'Al Kirs' },
  { label: 'Al Kiswah', value: 'Al Kiswah' },
  { label: 'Al Kittah', value: 'Al Kittah' },
  { label: 'Al Kura`', value: 'Al Kura`' },
  { label: 'Al Kūt', value: 'Al Kūt' },
  { label: 'Al Lagowa', value: 'Al Lagowa' },
  { label: 'Al Luqiyah', value: 'Al Luqiyah' },
  { label: 'Al Ma`Ayin', value: 'Al Ma`Ayin' },
  { label: 'Al Ma`Rash', value: 'Al Ma`Rash' },
  { label: 'Al Maafer', value: 'Al Maafer' },
  { label: 'Al Madam', value: 'Al Madam' },
  { label: 'Al Madan', value: 'Al Madan' },
  { label: 'Al Madaribah Wa Al Arah', value: 'Al Madaribah Wa Al Arah' },
  { label: 'Al Madaya', value: 'Al Madaya' },
  { label: 'Al Madiq Makkah', value: 'Al Madiq Makkah' },
  { label: 'Al Maflahy', value: 'Al Maflahy' },
  { label: 'Al Maghal', value: 'Al Maghal' },
  { label: 'Al Maghrabah', value: 'Al Maghrabah' },
  { label: 'Al Mahabishah', value: 'Al Mahabishah' },
  { label: 'Al Maḩallah Al Kubrá', value: 'Al Maḩallah Al Kubrá' },
  { label: 'Al Mahbūlah', value: 'Al Mahbūlah' },
  { label: 'Al Mahfad', value: 'Al Mahfad' },
  { label: 'Al Mahjar', value: 'Al Mahjar' },
  { label: 'Al Mahwait', value: 'Al Mahwait' },
  { label: 'Al Maḩwīt', value: 'Al Maḩwīt' },
  { label: 'Al Majāridah', value: 'Al Majāridah' },
  { label: 'Al Makhādir', value: 'Al Makhādir' },
  { label: 'Al Malagim', value: 'Al Malagim' },
  { label: 'Al Malbanah', value: 'Al Malbanah' },
  { label: 'Al Mali', value: 'Al Mali' },
  { label: 'Al Mālikīyah', value: 'Al Mālikīyah' },
  { label: 'Al Manāqil', value: 'Al Manāqil' },
  { label: 'Al Manar', value: 'Al Manar' },
  { label: 'Al Manqaf', value: 'Al Manqaf' },
  { label: 'Al Manshāh', value: 'Al Manshāh' },
  { label: 'Al Mansura', value: 'Al Mansura' },
  { label: 'Al Manşūrah', value: 'Al Manşūrah' },
  { label: 'Al Mansuriyah', value: 'Al Mansuriyah' },
  { label: 'Al Manzalah', value: 'Al Manzalah' },
  { label: 'Al Maqatirah', value: 'Al Maqatirah' },
  { label: 'Al Maqrah', value: 'Al Maqrah' },
  { label: 'Al Marawiah', value: 'Al Marawiah' },
  { label: 'Al Marj', value: 'Al Marj' },
  { label: 'Al Markaz', value: 'Al Markaz' },
  { label: 'Al Marsá', value: 'Al Marsá' },
  { label: 'Al Masallamiyya', value: 'Al Masallamiyya' },
  { label: 'Al Masarrah', value: 'Al Masarrah' },
  { label: 'Al Masfalah', value: 'Al Masfalah' },
  { label: 'Al Mashannah', value: 'Al Mashannah' },
  { label: 'Al Mashayikh', value: 'Al Mashayikh' },
  { label: 'Al Masilah', value: 'Al Masilah' },
  { label: 'Al Maslub', value: 'Al Maslub' },
  { label: 'Al Matammah', value: 'Al Matammah' },
  { label: 'Al Maţarīyah', value: 'Al Maţarīyah' },
  { label: 'Al Mathnah', value: 'Al Mathnah' },
  { label: 'Al Matlīn', value: 'Al Matlīn' },
  { label: 'Al Maton', value: 'Al Maton' },
  { label: 'Al Mawasit', value: 'Al Mawasit' },
  { label: 'Al Mawşil Al Jadīdah', value: 'Al Mawşil Al Jadīdah' },
  { label: 'Al Mayādīn', value: 'Al Mayādīn' },
  { label: 'Al Mayasam', value: 'Al Mayasam' },
  { label: 'Al Mazār Al Janūbī', value: 'Al Mazār Al Janūbī' },
  { label: 'Al Miftah', value: 'Al Miftah' },
  { label: 'Al Mighlaf', value: 'Al Mighlaf' },
  { label: 'Al Mijlad', value: 'Al Mijlad' },
  { label: 'Al Milah', value: 'Al Milah' },
  { label: 'Al Mina', value: 'Al Mina' },
  { label: 'Al Mindak', value: 'Al Mindak' },
  { label: 'Al Minyā', value: 'Al Minyā' },
  { label: 'Al Miqdādīyah', value: 'Al Miqdādīyah' },
  { label: 'Al Mishkhāb', value: 'Al Mishkhāb' },
  { label: 'Al Misrakh', value: 'Al Misrakh' },
  { label: 'Al Mithnab', value: 'Al Mithnab' },
  { label: 'Al Mualla', value: 'Al Mualla' },
  { label: 'Al Mubarak', value: 'Al Mubarak' },
  { label: 'Al Mubarraz', value: 'Al Mubarraz' },
  { label: 'Al Mudawwarah', value: 'Al Mudawwarah' },
  { label: 'Al Mudhaffar', value: 'Al Mudhaffar' },
  { label: 'Al Mufrihat', value: 'Al Mufrihat' },
  { label: 'Al Muharraq', value: 'Al Muharraq' },
  { label: 'Al Mukalla', value: 'Al Mukalla' },
  { label: 'Al Mukalla City', value: 'Al Mukalla City' },
  { label: 'Al Mukhā’', value: 'Al Mukhā’' },
  { label: 'Al Mulayha', value: 'Al Mulayha' },
  { label: 'Al Multasa', value: 'Al Multasa' },
  { label: 'Al Munayzilah', value: 'Al Munayzilah' },
  { label: 'Al Mundassah', value: 'Al Mundassah' },
  { label: 'Al Munirah', value: 'Al Munirah' },
  { label: 'Al Muqayti`', value: 'Al Muqayti`' },
  { label: 'Al Muqr', value: 'Al Muqr' },
  { label: 'Al Musayjid', value: 'Al Musayjid' },
  { label: 'Al Musaymir', value: 'Al Musaymir' },
  { label: 'Al Musayyib', value: 'Al Musayyib' },
  { label: 'Al Muţayrifī', value: 'Al Muţayrifī' },
  { label: 'Al Muwayh', value: 'Al Muwayh' },
  { label: 'Al Muzayrīb', value: 'Al Muzayrīb' },
  { label: 'Al Qabbaytah', value: 'Al Qabbaytah' },
  { label: 'Al Qadarif', value: 'Al Qadarif' },
  { label: 'Al Qadimah', value: 'Al Qadimah' },
  { label: 'Al Qaf', value: 'Al Qaf' },
  { label: 'Al Qaflah', value: 'Al Qaflah' },
  { label: 'Al Qafr', value: 'Al Qafr' },
  { label: 'Al Qahab', value: 'Al Qahab' },
  { label: 'Al Qahirah', value: 'Al Qahirah' },
  { label: 'Al Qaim', value: 'Al Qaim' },
  { label: 'Al Qāmishlī', value: 'Al Qāmishlī' },
  { label: 'Al Qanāţir Al Khayrīyah', value: 'Al Qanāţir Al Khayrīyah' },
  { label: 'Al Qanawis', value: 'Al Qanawis' },
  { label: 'Al Qārah', value: 'Al Qārah' },
  { label: 'Al Qararah', value: 'Al Qararah' },
  { label: 'Al Qaryat', value: 'Al Qaryat' },
  { label: 'Al Qaryatayn', value: 'Al Qaryatayn' },
  { label: 'Al Qaşr', value: 'Al Qaşr' },
  { label: 'Al Qaţīf', value: 'Al Qaţīf' },
  { label: 'Al Qatn', value: 'Al Qatn' },
  { label: 'Al Qaţrūn', value: 'Al Qaţrūn' },
  { label: 'Al Qawba`Iyah', value: 'Al Qawba`Iyah' },
  { label: 'Al Qirshan', value: 'Al Qirshan' },
  { label: 'Al Qiţena', value: 'Al Qiţena' },
  { label: 'Al Qu`Tubah', value: 'Al Qu`Tubah' },
  { label: 'Al Qubbah', value: 'Al Qubbah' },
  { label: 'Al Qufayf', value: 'Al Qufayf' },
  { label: 'Al Quful', value: 'Al Quful' },
  { label: 'Al Qunayţirah', value: 'Al Qunayţirah' },
  { label: 'Al Quraishyah', value: 'Al Quraishyah' },
  { label: 'Al Qurayb', value: 'Al Qurayb' },
  { label: 'Al Qurayn', value: 'Al Qurayn' },
  { label: 'Al Qurein', value: 'Al Qurein' },
  { label: 'Al Quşayr', value: 'Al Quşayr' },
  { label: 'Al Quşayr', value: 'Al Quşayr' },
  { label: 'Al Qushashiyah', value: 'Al Qushashiyah' },
  { label: 'Al Qūşīyah', value: 'Al Qūşīyah' },
  { label: 'Al Quţayfah', value: 'Al Quţayfah' },
  { label: 'Al Quwah', value: 'Al Quwah' },
  { label: 'Al Quwayrah', value: 'Al Quwayrah' },
  { label: 'Al Saadoon Park', value: 'Al Saadoon Park' },
  { label: 'Al Salhiah', value: 'Al Salhiah' },
  { label: 'Al Shamkhah City', value: 'Al Shamkhah City' },
  { label: 'Al Talh', value: 'Al Talh' },
  { label: 'Al Thybiyah', value: 'Al Thybiyah' },
  { label: 'Al Ubaylah', value: 'Al Ubaylah' },
  { label: 'Al Ukhaydir', value: 'Al Ukhaydir' },
  { label: 'Al Wadea', value: 'Al Wadea' },
  { label: 'Al Wafrah', value: 'Al Wafrah' },
  { label: 'Al Waht', value: 'Al Waht' },
  { label: 'Al Wajh', value: 'Al Wajh' },
  { label: 'Al Wakrah', value: 'Al Wakrah' },
  { label: 'Al Wasili', value: 'Al Wasili' },
  { label: 'Al Wāsiţah', value: 'Al Wāsiţah' },
  { label: 'Al Waziiyah', value: 'Al Waziiyah' },
  { label: 'Al Wuday', value: 'Al Wuday' },
  { label: 'Al Wukayr', value: 'Al Wukayr' },
  { label: 'Al Zafraniya', value: 'Al Zafraniya' },
  { label: 'Al’Keyevskiy Rayon', value: 'Al’Keyevskiy Rayon' },
  { label: 'Al’Met’Yevsk', value: 'Al’Met’Yevsk' },
  { label: 'Al’Met’Yevskiy Rayon', value: 'Al’Met’Yevskiy Rayon' },
  { label: 'Al’Sheyevskiy Rayon', value: 'Al’Sheyevskiy Rayon' },
  { label: 'Ala', value: 'Ala' },
  { label: 'Alà Dei Sardi', value: 'Alà Dei Sardi' },
  { label: 'Ala Di Stura', value: 'Ala Di Stura' },
  { label: 'Alaba Special Wereda', value: 'Alaba Special Wereda' },
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alabaster', value: 'Alabaster' },
  { label: 'Alabat', value: 'Alabat' },
  { label: 'Alabel', value: 'Alabel' },
  { label: 'Alabug', value: 'Alabug' },
  { label: 'Alabug', value: 'Alabug' },
  { label: 'Ala-Buka', value: 'Ala-Buka' },
  { label: 'Alabushevo', value: 'Alabushevo' },
  { label: 'Alac', value: 'Alac' },
  { label: 'Alac', value: 'Alac' },
  { label: 'Alaca', value: 'Alaca' },
  { label: 'Alacakaya', value: 'Alacakaya' },
  { label: 'Alaçam', value: 'Alaçam' },
  { label: 'Alacaygan', value: 'Alacaygan' },
  { label: 'Alachua', value: 'Alachua' },
  { label: 'Alachua County', value: 'Alachua County' },
  { label: 'Alacón', value: 'Alacón' },
  { label: 'Alacranes', value: 'Alacranes' },
  { label: 'Alad', value: 'Alad' },
  { label: 'Aladağ', value: 'Aladağ' },
  { label: 'Aladrén', value: 'Aladrén' },
  { label: 'Alae', value: 'Alae' },
  { label: 'Alaejos', value: 'Alaejos' },
  { label: 'Alafaya', value: 'Alafaya' },
  { label: 'Alafors', value: 'Alafors' },
  { label: 'Alagapuram', value: 'Alagapuram' },
  { label: 'Alaghsas', value: 'Alaghsas' },
  { label: 'Alagir', value: 'Alagir' },
  { label: 'Alagirskiy Rayon', value: 'Alagirskiy Rayon' },
  { label: 'Alagna', value: 'Alagna' },
  { label: 'Alagna Valsesia', value: 'Alagna Valsesia' },
  { label: 'Alagoa', value: 'Alagoa' },
  { label: 'Alagoa Grande', value: 'Alagoa Grande' },
  { label: 'Alagoa Nova', value: 'Alagoa Nova' },
  { label: 'Alagoinha', value: 'Alagoinha' },
  { label: 'Alagoinha', value: 'Alagoinha' },
  { label: 'Alagoinha Do Piauí', value: 'Alagoinha Do Piauí' },
  { label: 'Alagoinhas', value: 'Alagoinhas' },
  { label: 'Alagón', value: 'Alagón' },
  { label: 'Alagón Del Río', value: 'Alagón Del Río' },
  { label: 'Alahärmä', value: 'Alahärmä' },
  { label: 'Alaïli Ḏaḏḏa‘', value: 'Alaïli Ḏaḏḏa‘' },
  { label: 'Alaior', value: 'Alaior' },
  { label: 'Alájar', value: 'Alájar' },
  { label: 'Alajärvi', value: 'Alajärvi' },
  { label: 'Alajeró', value: 'Alajeró' },
  { label: 'Alajuela', value: 'Alajuela' },
  { label: 'Alajuelita', value: 'Alajuelita' },
  { label: 'Alakurtti', value: 'Alakurtti' },
  { label: 'Alamada', value: 'Alamada' },
  { label: 'Alamada', value: 'Alamada' },
  { label: 'Alamance County', value: 'Alamance County' },
  { label: 'Alamar', value: 'Alamar' },
  { label: 'Alamarvdasht', value: 'Alamarvdasht' },
  { label: 'Alambari', value: 'Alambari' },
  { label: 'Alameda', value: 'Alameda' },
  { label: 'Alameda', value: 'Alameda' },
  { label: 'Alameda County', value: 'Alameda County' },
  { label: 'Alameda De Gardón La', value: 'Alameda De Gardón La' },
  { label: 'Alameda De La Sagra', value: 'Alameda De La Sagra' },
  { label: 'Alameda Del Valle', value: 'Alameda Del Valle' },
  { label: 'Alamedilla', value: 'Alamedilla' },
  { label: 'Alamedilla La', value: 'Alamedilla La' },
  { label: 'Alamillo', value: 'Alamillo' },
  { label: 'Alaminos', value: 'Alaminos' },
  { label: 'Alaminos', value: 'Alaminos' },
  { label: 'Alaminos', value: 'Alaminos' },
  { label: 'Alaminos', value: 'Alaminos' },
  { label: 'Alamo', value: 'Alamo' },
  { label: 'Alamo', value: 'Alamo' },
  { label: 'Alamo', value: 'Alamo' },
  { label: 'Alamo', value: 'Alamo' },
  { label: 'Alamo', value: 'Alamo' },
  { label: 'Alamo', value: 'Alamo' },
  { label: 'Álamo', value: 'Álamo' },
  { label: 'Álamo El', value: 'Álamo El' },
  { label: 'Alamo Heights', value: 'Alamo Heights' },
  { label: 'Álamo Temapache', value: 'Álamo Temapache' },
  { label: 'Alamogordo', value: 'Alamogordo' },
  { label: 'Alămor', value: 'Alămor' },
  { label: 'Álamos', value: 'Álamos' },
  { label: 'Álamos Tepetitlán', value: 'Álamos Tepetitlán' },
  { label: 'Alamosa', value: 'Alamosa' },
  { label: 'Alamosa County', value: 'Alamosa County' },
  { label: 'Alamosa East', value: 'Alamosa East' },
  { label: 'Alámpra', value: 'Alámpra' },
  { label: 'Alampur', value: 'Alampur' },
  { label: 'Alampur', value: 'Alampur' },
  { label: 'Alamudunskiy Rayon', value: 'Alamudunskiy Rayon' },
  { label: 'Alamús Els', value: 'Alamús Els' },
  { label: 'Aland', value: 'Aland' },
  { label: 'Alandi', value: 'Alandi' },
  { label: 'Alandroal', value: 'Alandroal' },
  { label: 'Alandskoye', value: 'Alandskoye' },
  { label: 'Alandur', value: 'Alandur' },
  { label: 'Alan-E Sofla', value: 'Alan-E Sofla' },
  { label: 'Alangalang', value: 'Alangalang' },
  { label: 'Alangalang', value: 'Alangalang' },
  { label: 'Alanganallur', value: 'Alanganallur' },
  { label: 'Alangayam', value: 'Alangayam' },
  { label: 'Alange', value: 'Alange' },
  { label: 'Alangilan', value: 'Alangilan' },
  { label: 'Alangilanan', value: 'Alangilanan' },
  { label: 'Alangudi', value: 'Alangudi' },
  { label: 'Alangulam', value: 'Alangulam' },
  { label: 'Alanib', value: 'Alanib' },
  { label: 'Alanís', value: 'Alanís' },
  { label: 'Alanje', value: 'Alanje' },
  { label: 'Alanjeh', value: 'Alanjeh' },
  { label: 'Alannay', value: 'Alannay' },
  { label: 'Alannay', value: 'Alannay' },
  { label: 'Alanno', value: 'Alanno' },
  { label: 'Alano Di Piave', value: 'Alano Di Piave' },
  { label: 'Alanya', value: 'Alanya' },
  { label: 'Alap', value: 'Alap' },
  { label: 'Alapa', value: 'Alapa' },
  { label: 'Alapayevsk', value: 'Alapayevsk' },
  { label: 'Alapi Village', value: 'Alapi Village' },
  { label: 'Alaplı', value: 'Alaplı' },
  { label: 'Alappakkam', value: 'Alappakkam' },
  { label: 'Alappuzha', value: 'Alappuzha' },
  { label: 'Alāqahdārī Dīshū', value: 'Alāqahdārī Dīshū' },
  { label: 'Alaquàs', value: 'Alaquàs' },
  { label: 'Alaquines', value: 'Alaquines' },
  { label: 'Alar Del Rey', value: 'Alar Del Rey' },
  { label: 'Alaraz', value: 'Alaraz' },
  { label: 'Alarba', value: 'Alarba' },
  { label: 'Alarcón', value: 'Alarcón' },
  { label: 'Alarilla', value: 'Alarilla' },
  { label: 'Alaró', value: 'Alaró' },
  { label: 'Alarskiy Rayon', value: 'Alarskiy Rayon' },
  { label: 'Alas', value: 'Alas' },
  { label: 'Alàs I Cerc', value: 'Alàs I Cerc' },
  { label: 'Alaşehir', value: 'Alaşehir' },
  { label: 'Alashkert', value: 'Alashkert' },
  { label: 'Alasht', value: 'Alasht' },
  { label: 'Alassio', value: 'Alassio' },
  { label: 'Alastaro', value: 'Alastaro' },
  { label: 'Alata', value: 'Alata' },
  { label: 'Alatoz', value: 'Alatoz' },
  { label: 'Alatri', value: 'Alatri' },
  { label: 'Alatskivi', value: 'Alatskivi' },
  { label: 'Alattyán', value: 'Alattyán' },
  { label: 'Alatyr’', value: 'Alatyr’' },
  { label: 'Alatyrskiy Rayon', value: 'Alatyrskiy Rayon' },
  { label: 'Alauca', value: 'Alauca' },
  { label: 'Alausí', value: 'Alausí' },
  { label: 'Alaverdi', value: 'Alaverdi' },
  { label: 'Alavieska', value: 'Alavieska' },
  { label: 'Alavijeh', value: 'Alavijeh' },
  { label: 'Alavus', value: 'Alavus' },
  { label: 'Alawa', value: 'Alawa' },
  { label: 'Alawalpur', value: 'Alawalpur' },
  { label: 'Al-Awamiyah', value: 'Al-Awamiyah' },
  { label: 'Alawatugoda', value: 'Alawatugoda' },
  { label: 'Al-Awjam', value: 'Al-Awjam' },
  { label: 'Alayao', value: 'Alayao' },
  { label: 'Alba', value: 'Alba' },
  { label: 'Alba', value: 'Alba' },
  { label: 'Alba', value: 'Alba' },
  { label: 'Alba Adriatica', value: 'Alba Adriatica' },
  { label: 'Alba De Cerrato', value: 'Alba De Cerrato' },
  { label: 'Alba De Tormes', value: 'Alba De Tormes' },
  { label: 'Alba De Yeltes', value: 'Alba De Yeltes' },
  { label: 'Alba Iulia', value: 'Alba Iulia' },
  { label: 'Alba Posse', value: 'Alba Posse' },
  { label: 'Al-Bab District', value: 'Al-Bab District' },
  { label: 'Albac', value: 'Albac' },
  { label: 'Albacete', value: 'Albacete' },
  { label: 'Albaching', value: 'Albaching' },
  { label: 'Albagés L', value: 'Albagés L' },
  { label: 'Albagiara', value: 'Albagiara' },
  { label: 'Albaida', value: 'Albaida' },
  { label: 'Albaida Del Aljarafe', value: 'Albaida Del Aljarafe' },
  { label: 'Albairate', value: 'Albairate' },
  { label: 'Albal', value: 'Albal' },
  { label: 'Albalá', value: 'Albalá' },
  { label: 'Albaladejo', value: 'Albaladejo' },
  { label: 'Albaladejo Del Cuende', value: 'Albaladejo Del Cuende' },
  { label: 'Alba-La-Romaine', value: 'Alba-La-Romaine' },
  { label: 'Albalat De La Ribera', value: 'Albalat De La Ribera' },
  { label: 'Albalat Dels Sorells', value: 'Albalat Dels Sorells' },
  { label: 'Albalat Dels Tarongers', value: 'Albalat Dels Tarongers' },
  { label: 'Albalate De Cinca', value: 'Albalate De Cinca' },
  { label: 'Albalate De Las Nogueras', value: 'Albalate De Las Nogueras' },
  { label: 'Albalate De Zorita', value: 'Albalate De Zorita' },
  { label: 'Albalate Del Arzobispo', value: 'Albalate Del Arzobispo' },
  { label: 'Albalatillo', value: 'Albalatillo' },
  { label: 'Albán', value: 'Albán' },
  { label: 'Albánchez', value: 'Albánchez' },
  { label: 'Albanchez De Mágina', value: 'Albanchez De Mágina' },
  { label: 'Albanel', value: 'Albanel' },
  { label: 'Albanella', value: 'Albanella' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Albano Di Lucania', value: 'Albano Di Lucania' },
  { label: 'Albano Laziale', value: 'Albano Laziale' },
  { label: 'Albano Santalessandro', value: 'Albano Santalessandro' },
  { label: 'Albano Vercellese', value: 'Albano Vercellese' },
  { label: 'Albanvale', value: 'Albanvale' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany', value: 'Albany' },
  { label: 'Albany City Centre', value: 'Albany City Centre' },
  { label: 'Albany County', value: 'Albany County' },
  { label: 'Albany County', value: 'Albany County' },
  { label: 'Albany Creek', value: 'Albany Creek' },
  { label: 'Albany Park', value: 'Albany Park' },
  { label: 'Albanyà', value: 'Albanyà' },
  { label: 'Albardo', value: 'Albardo' },
  { label: 'Albardón', value: 'Albardón' },
  { label: 'Albaredo', value: 'Albaredo' },
  { label: 'Albaredo', value: 'Albaredo' },
  { label: 'Albaredo Arnaboldi', value: 'Albaredo Arnaboldi' },
  { label: 'Albaredo Dadige', value: 'Albaredo Dadige' },
  { label: 'Albares', value: 'Albares' },
  { label: 'Albareto', value: 'Albareto' },
  { label: 'Albaretto Della Torre', value: 'Albaretto Della Torre' },
  { label: 'Albarracín', value: 'Albarracín' },
  { label: 'Albarradas', value: 'Albarradas' },
  { label: 'Albarreal De Tajo', value: 'Albarreal De Tajo' },
  { label: 'Albatana', value: 'Albatana' },
  { label: 'Albatàrrec', value: 'Albatàrrec' },
  { label: 'Albatera', value: 'Albatera' },
  { label: 'Albavilla', value: 'Albavilla' },
  { label: 'Albbruck', value: 'Albbruck' },
  { label: 'Albeck', value: 'Albeck' },
  { label: 'Albelda', value: 'Albelda' },
  { label: 'Albelda De Iregua', value: 'Albelda De Iregua' },
  { label: 'Albemarle', value: 'Albemarle' },
  { label: 'Albemarle County', value: 'Albemarle County' },
  { label: 'Albendea', value: 'Albendea' },
  { label: 'Albendiego', value: 'Albendiego' },
  { label: 'Albenga', value: 'Albenga' },
  { label: 'Albeni', value: 'Albeni' },
  { label: 'Albens', value: 'Albens' },
  { label: 'Albentosa', value: 'Albentosa' },
  { label: 'Albera Ligure', value: 'Albera Ligure' },
  { label: 'Alberca De Záncara La', value: 'Alberca De Záncara La' },
  { label: 'Alberca La', value: 'Alberca La' },
  { label: 'Albergaria-A-Velha', value: 'Albergaria-A-Velha' },
  { label: 'Alberguería De Argañán La', value: 'Alberguería De Argañán La' },
  { label: 'Alberi', value: 'Alberi' },
  { label: 'Alberic', value: 'Alberic' },
  { label: 'Alberite', value: 'Alberite' },
  { label: 'Alberite De San Juan', value: 'Alberite De San Juan' },
  { label: 'Alberndorf In Der Riedmark', value: 'Alberndorf In Der Riedmark' },
  { label: 'Albero Alto', value: 'Albero Alto' },
  { label: 'Albero Bajo', value: 'Albero Bajo' },
  { label: 'Alberobello', value: 'Alberobello' },
  { label: 'Alberona', value: 'Alberona' },
  { label: 'Albers', value: 'Albers' },
  { label: 'Alberschwende', value: 'Alberschwende' },
  { label: 'Albersdorf', value: 'Albersdorf' },
  { label: 'Albersdorf', value: 'Albersdorf' },
  { label: 'Albershausen', value: 'Albershausen' },
  { label: 'Albersweiler', value: 'Albersweiler' },
  { label: 'Albert', value: 'Albert' },
  { label: 'Albert Lea', value: 'Albert Lea' },
  { label: 'Albert Park', value: 'Albert Park' },
  { label: 'Albert Park', value: 'Albert Park' },
  { label: 'Albert Town', value: 'Albert Town' },
  { label: 'Albertina', value: 'Albertina' },
  { label: 'Albertina', value: 'Albertina' },
  { label: 'Albertirsa', value: 'Albertirsa' },
  { label: 'Alberto Carrera Torres', value: 'Alberto Carrera Torres' },
  { label: 'Alberto Villarreal', value: 'Alberto Villarreal' },
  { label: 'Alberton', value: 'Alberton' },
  { label: 'Alberton', value: 'Alberton' },
  { label: 'Alberton', value: 'Alberton' },
  { label: 'Albertshofen', value: 'Albertshofen' },
  { label: 'Albertslund', value: 'Albertslund' },
  { label: 'Albertslund Kommune', value: 'Albertslund Kommune' },
  { label: 'Albertson', value: 'Albertson' },
  { label: 'Albertstadt', value: 'Albertstadt' },
  { label: 'Albertville', value: 'Albertville' },
  { label: 'Albertville', value: 'Albertville' },
  { label: 'Albertville', value: 'Albertville' },
  { label: 'Alberuela De Tubo', value: 'Alberuela De Tubo' },
  { label: 'Albesa', value: 'Albesa' },
  { label: 'Albese Con Cassano', value: 'Albese Con Cassano' },
  { label: 'Albeşti', value: 'Albeşti' },
  { label: 'Albeşti', value: 'Albeşti' },
  { label: 'Albeşti', value: 'Albeşti' },
  { label: 'Albeşti', value: 'Albeşti' },
  { label: 'Albeşti', value: 'Albeşti' },
  { label: 'Albești', value: 'Albești' },
  { label: 'Albeştii Pământeni', value: 'Albeştii Pământeni' },
  { label: 'Albeștii Ungureni', value: 'Albeștii Ungureni' },
  { label: 'Albești-Muru', value: 'Albești-Muru' },
  { label: 'Albeşti-Paleologu', value: 'Albeşti-Paleologu' },
  { label: 'Albeta', value: 'Albeta' },
  { label: 'Albettone', value: 'Albettone' },
  { label: 'Albi', value: 'Albi' },
  { label: 'Albi', value: 'Albi' },
  { label: 'Albi L', value: 'Albi L' },
  { label: 'Albia', value: 'Albia' },
  { label: 'Albia', value: 'Albia' },
  { label: 'Albiano', value: 'Albiano' },
  { label: 'Albiano Divrea', value: 'Albiano Divrea' },
  { label: 'Albiano Magra', value: 'Albiano Magra' },
  { label: 'Albias', value: 'Albias' },
  { label: 'Albiate', value: 'Albiate' },
  { label: 'Albidona', value: 'Albidona' },
  { label: 'Albig', value: 'Albig' },
  { label: 'Albignano', value: 'Albignano' },
  { label: 'Albignasego', value: 'Albignasego' },
  { label: 'Albigny-Sur-Saône', value: 'Albigny-Sur-Saône' },
  { label: 'Albigowa', value: 'Albigowa' },
  { label: 'Albillos', value: 'Albillos' },
  { label: 'Albina', value: 'Albina' },
  { label: 'Albinea', value: 'Albinea' },
  { label: 'Albinia', value: 'Albinia' },
  { label: 'Albino', value: 'Albino' },
  { label: 'Albino Zertuche', value: 'Albino Zertuche' },
  { label: 'Albinyana', value: 'Albinyana' },
  { label: 'Albiol', value: 'Albiol' },
  { label: 'Albiolo', value: 'Albiolo' },
  { label: 'Albion', value: 'Albion' },
  { label: 'Albion', value: 'Albion' },
  { label: 'Albion', value: 'Albion' },
  { label: 'Albion', value: 'Albion' },
  { label: 'Albion', value: 'Albion' },
  { label: 'Albion', value: 'Albion' },
  { label: 'Albion', value: 'Albion' },
  { label: 'Albion', value: 'Albion' },
  { label: 'Albion', value: 'Albion' },
  { label: 'Albion', value: 'Albion' },
  { label: 'Albion', value: 'Albion' },
  { label: 'Albion Park', value: 'Albion Park' },
  { label: 'Albion Park Rail', value: 'Albion Park Rail' },
  { label: 'Albiș', value: 'Albiș' },
  { label: 'Albisheim', value: 'Albisheim' },
  { label: 'Albisola Marina', value: 'Albisola Marina' },
  { label: 'Albisola Superiore', value: 'Albisola Superiore' },
  { label: 'Albitreccia', value: 'Albitreccia' },
  { label: 'Albiztur', value: 'Albiztur' },
  { label: 'Albizzate', value: 'Albizzate' },
  { label: 'Alblasserdam', value: 'Alblasserdam' },
  { label: 'Albocàsser', value: 'Albocàsser' },
  { label: 'Alboloduy', value: 'Alboloduy' },
  { label: 'Albolote', value: 'Albolote' },
  { label: 'Albondón', value: 'Albondón' },
  { label: 'Albonese', value: 'Albonese' },
  { label: 'Albons', value: 'Albons' },
  { label: 'Alborache', value: 'Alborache' },
  { label: 'Alborada', value: 'Alborada' },
  { label: 'Alborada Jaltenco', value: 'Alborada Jaltenco' },
  { label: 'Alboraya', value: 'Alboraya' },
  { label: 'Alborea', value: 'Alborea' },
  { label: 'Ålborg Kommune', value: 'Ålborg Kommune' },
  { label: 'Alborge', value: 'Alborge' },
  { label: 'Albornos', value: 'Albornos' },
  { label: 'Alborz', value: 'Alborz' },
  { label: 'Albosaggia', value: 'Albosaggia' },
  { label: 'Albota', value: 'Albota' },
  { label: 'Albota De Jos', value: 'Albota De Jos' },
  { label: 'Albox', value: 'Albox' },
  { label: 'Albrechtice', value: 'Albrechtice' },
  { label: 'Albrechtice Nad Orlicí', value: 'Albrechtice Nad Orlicí' },
  { label: 'Albrighton', value: 'Albrighton' },
  { label: 'Albshausen', value: 'Albshausen' },
  { label: 'Albstadt', value: 'Albstadt' },
  { label: 'Ālbū Kamāl', value: 'Ālbū Kamāl' },
  { label: 'Albuccione', value: 'Albuccione' },
  { label: 'Albudeite', value: 'Albudeite' },
  { label: 'Albuera', value: 'Albuera' },
  { label: 'Albuera', value: 'Albuera' },
  { label: 'Albuera La', value: 'Albuera La' },
  { label: 'Albufeira', value: 'Albufeira' },
  { label: 'Albugnano', value: 'Albugnano' },
  { label: 'Albuixech', value: 'Albuixech' },
  { label: 'Albuñán', value: 'Albuñán' },
  { label: 'Albuñol', value: 'Albuñol' },
  { label: 'Albuñuelas', value: 'Albuñuelas' },
  { label: 'Albuquerque', value: 'Albuquerque' },
  { label: 'Alburquerque', value: 'Alburquerque' },
  { label: 'Alburquerque', value: 'Alburquerque' },
  { label: 'Alburtis', value: 'Alburtis' },
  { label: 'Albury', value: 'Albury' },
  { label: 'Albury Municipality', value: 'Albury Municipality' },
  { label: 'Albuzzano', value: 'Albuzzano' },
  { label: 'Alby', value: 'Alby' },
  { label: 'Alby-Sur-Chéran', value: 'Alby-Sur-Chéran' },
  { label: 'Alcabideche', value: 'Alcabideche' },
  { label: 'Alcabideche', value: 'Alcabideche' },
  { label: 'Alcabón', value: 'Alcabón' },
  { label: 'Alcácer Do Sal', value: 'Alcácer Do Sal' },
  { label: 'Alcadozo', value: 'Alcadozo' },
  { label: 'Alcaine', value: 'Alcaine' },
  { label: 'Alcains', value: 'Alcains' },
  { label: 'Alcala', value: 'Alcala' },
  { label: 'Alcala', value: 'Alcala' },
  { label: 'Alcala', value: 'Alcala' },
  { label: 'Alcala', value: 'Alcala' },
  { label: 'Alcalá', value: 'Alcalá' },
  { label: 'Alcalá De Ebro', value: 'Alcalá De Ebro' },
  { label: 'Alcalá De Guadaira', value: 'Alcalá De Guadaira' },
  { label: 'Alcalá De Gurrea', value: 'Alcalá De Gurrea' },
  { label: 'Alcalá De Henares', value: 'Alcalá De Henares' },
  { label: 'Alcalá De La Selva', value: 'Alcalá De La Selva' },
  { label: 'Alcalá De La Vega', value: 'Alcalá De La Vega' },
  { label: 'Alcalá De Los Gazules', value: 'Alcalá De Los Gazules' },
  { label: 'Alcalá De Moncayo', value: 'Alcalá De Moncayo' },
  { label: 'Alcalà De Xivert', value: 'Alcalà De Xivert' },
  { label: 'Alcalá Del Júcar', value: 'Alcalá Del Júcar' },
  { label: 'Alcalá Del Obispo', value: 'Alcalá Del Obispo' },
  { label: 'Alcalá Del Río', value: 'Alcalá Del Río' },
  { label: 'Alcalá Del Valle', value: 'Alcalá Del Valle' },
  { label: 'Alcalá La Real', value: 'Alcalá La Real' },
  { label: 'Alcalalí', value: 'Alcalalí' },
  { label: 'Alcalde Díaz', value: 'Alcalde Díaz' },
  { label: 'Alcamo', value: 'Alcamo' },
  { label: 'Alcampell', value: 'Alcampell' },
  { label: 'Alcanadre', value: 'Alcanadre' },
  { label: 'Alcanar', value: 'Alcanar' },
  { label: 'Alcanede', value: 'Alcanede' },
  { label: 'Alcanena', value: 'Alcanena' },
  { label: 'Alcanhões', value: 'Alcanhões' },
  { label: 'Alcañices', value: 'Alcañices' },
  { label: 'Alcañiz', value: 'Alcañiz' },
  { label: 'Alcañizo', value: 'Alcañizo' },
  { label: 'Alcanó', value: 'Alcanó' },
  { label: 'Alcantara', value: 'Alcantara' },
  { label: 'Alcantara', value: 'Alcantara' },
  { label: 'Alcántara', value: 'Alcántara' },
  { label: 'Alcântara', value: 'Alcântara' },
  { label: 'Alcântara', value: 'Alcântara' },
  { label: 'Alcântaras', value: 'Alcântaras' },
  { label: 'Alcantarilha', value: 'Alcantarilha' },
  { label: 'Alcantarilla', value: 'Alcantarilla' },
  { label: 'Alcàntera De Xúquer', value: 'Alcàntera De Xúquer' },
  { label: 'Alcantil', value: 'Alcantil' },
  { label: 'Alcantud', value: 'Alcantud' },
  { label: 'Alcara Li Fusi', value: 'Alcara Li Fusi' },
  { label: 'Alcaracejos', value: 'Alcaracejos' },
  { label: 'Alcaraces', value: 'Alcaraces' },
  { label: 'Alcaraz', value: 'Alcaraz' },
  { label: 'Alcarràs', value: 'Alcarràs' },
  { label: 'Alcàsser', value: 'Alcàsser' },
  { label: 'Alcaucín', value: 'Alcaucín' },
  { label: 'Alcaudete', value: 'Alcaudete' },
  { label: 'Alcaudete De La Jara', value: 'Alcaudete De La Jara' },
  { label: 'Alcázar De San Juan', value: 'Alcázar De San Juan' },
  { label: 'Alcázar Del Rey', value: 'Alcázar Del Rey' },
  { label: 'Alcazarén', value: 'Alcazarén' },
  { label: 'Alcázares Los', value: 'Alcázares Los' },
  { label: 'Alcester', value: 'Alcester' },
  { label: 'Alchevs’K', value: 'Alchevs’K' },
  { label: 'Alchevs’Ka Mis’Krada', value: 'Alchevs’Ka Mis’Krada' },
  { label: 'Alcholoa', value: 'Alcholoa' },
  { label: 'Alcinópolis', value: 'Alcinópolis' },
  { label: 'Alcoa', value: 'Alcoa' },
  { label: 'Alcoba', value: 'Alcoba' },
  { label: 'Alcobaça', value: 'Alcobaça' },
  { label: 'Alcobaça', value: 'Alcobaça' },
  { label: 'Alcobendas', value: 'Alcobendas' },
  { label: 'Alcocéber', value: 'Alcocéber' },
  { label: 'Alcocer', value: 'Alcocer' },
  { label: 'Alcocer', value: 'Alcocer' },
  { label: 'Alcocer De Planes', value: 'Alcocer De Planes' },
  { label: 'Alcocero De Mola', value: 'Alcocero De Mola' },
  { label: 'Alcochete', value: 'Alcochete' },
  { label: 'Alcoentre', value: 'Alcoentre' },
  { label: 'Alcoentre', value: 'Alcoentre' },
  { label: 'Alcohujate', value: 'Alcohujate' },
  { label: 'Alcolea', value: 'Alcolea' },
  { label: 'Alcolea De Calatrava', value: 'Alcolea De Calatrava' },
  { label: 'Alcolea De Cinca', value: 'Alcolea De Cinca' },
  { label: 'Alcolea De Las Peñas', value: 'Alcolea De Las Peñas' },
  { label: 'Alcolea De Tajo', value: 'Alcolea De Tajo' },
  { label: 'Alcolea Del Pinar', value: 'Alcolea Del Pinar' },
  { label: 'Alcolea Del Río', value: 'Alcolea Del Río' },
  { label: 'Alcoleja', value: 'Alcoleja' },
  { label: 'Alcoletge', value: 'Alcoletge' },
  { label: 'Alcollarín', value: 'Alcollarín' },
  { label: 'Alcona County', value: 'Alcona County' },
  { label: 'Alconaba', value: 'Alconaba' },
  { label: 'Alconada', value: 'Alconada' },
  { label: 'Alconada De Maderuelo', value: 'Alconada De Maderuelo' },
  { label: 'Alconbury', value: 'Alconbury' },
  { label: 'Alconchel', value: 'Alconchel' },
  { label: 'Alconchel De Ariza', value: 'Alconchel De Ariza' },
  { label: 'Alconchel De La Estrella', value: 'Alconchel De La Estrella' },
  { label: 'Alconera', value: 'Alconera' },
  { label: 'Alcóntar', value: 'Alcóntar' },
  { label: 'Alcora L', value: 'Alcora L' },
  { label: 'Alcorcón', value: 'Alcorcón' },
  { label: 'Alcorisa', value: 'Alcorisa' },
  { label: 'Alcorn County', value: 'Alcorn County' },
  { label: 'Alcoroches', value: 'Alcoroches' },
  { label: 'Alcoutim', value: 'Alcoutim' },
  { label: 'Alcover', value: 'Alcover' },
  { label: 'Alcoy', value: 'Alcoy' },
  { label: 'Alcoy', value: 'Alcoy' },
  { label: 'Alcoy/Alcoi', value: 'Alcoy/Alcoi' },
  { label: 'Alcozacán', value: 'Alcozacán' },
  { label: 'Alcozauca De Guerrero', value: 'Alcozauca De Guerrero' },
  { label: 'Alcubierre', value: 'Alcubierre' },
  { label: 'Alcubilla De Avellaneda', value: 'Alcubilla De Avellaneda' },
  { label: 'Alcubilla De Las Peñas', value: 'Alcubilla De Las Peñas' },
  { label: 'Alcubilla De Nogales', value: 'Alcubilla De Nogales' },
  { label: 'Alcubillas', value: 'Alcubillas' },
  { label: 'Alcublas', value: 'Alcublas' },
  { label: 'Alcúdia', value: 'Alcúdia' },
  { label: 'Alcúdia De Crespins L', value: 'Alcúdia De Crespins L' },
  { label: 'Alcudia De Monteagud', value: 'Alcudia De Monteagud' },
  { label: 'Alcudia De Veo', value: 'Alcudia De Veo' },
  { label: 'Alcúdia L', value: 'Alcúdia L' },
  { label: 'Alcuéscar', value: 'Alcuéscar' },
  { label: 'Aldaia', value: 'Aldaia' },
  { label: 'Aldama', value: 'Aldama' },
  { label: 'Aldama', value: 'Aldama' },
  { label: 'Aldama', value: 'Aldama' },
  { label: 'Aldama', value: 'Aldama' },
  { label: 'Aldama Estación', value: 'Aldama Estación' },
  { label: 'Aldan', value: 'Aldan' },
  { label: 'Aldan', value: 'Aldan' },
  { label: 'Aldana', value: 'Aldana' },
  { label: 'Aldavilla', value: 'Aldavilla' },
  { label: 'Aldbourne', value: 'Aldbourne' },
  { label: 'Aldbrough', value: 'Aldbrough' },
  { label: 'Aldea De San Miguel', value: 'Aldea De San Miguel' },
  { label: 'Aldea De San Nicolás La', value: 'Aldea De San Nicolás La' },
  { label: 'Aldea Del Cano', value: 'Aldea Del Cano' },
  { label: 'Aldea Del Fresno', value: 'Aldea Del Fresno' },
  { label: 'Aldea Del Obispo', value: 'Aldea Del Obispo' },
  { label: 'Aldea Del Obispo La', value: 'Aldea Del Obispo La' },
  { label: 'Aldea Del Rey', value: 'Aldea Del Rey' },
  { label: 'Aldea En Cabo', value: 'Aldea En Cabo' },
  { label: 'Aldea L', value: 'Aldea L' },
  { label: 'Aldea Real', value: 'Aldea Real' },
  { label: 'Aldea San Antonio', value: 'Aldea San Antonio' },
  { label: 'Aldeacentenera', value: 'Aldeacentenera' },
  { label: 'Aldeacipreste', value: 'Aldeacipreste' },
  { label: 'Aldeadávila De La Ribera', value: 'Aldeadávila De La Ribera' },
  { label: 'Aldealafuente', value: 'Aldealafuente' },
  { label: 'Aldealcorvo', value: 'Aldealcorvo' },
  { label: 'Aldealengua', value: 'Aldealengua' },
  { label: 'Aldealengua De Pedraza', value: 'Aldealengua De Pedraza' },
  { label: 'Aldealengua De Santa María', value: 'Aldealengua De Santa María' },
  { label: 'Aldealices', value: 'Aldealices' },
  { label: 'Aldealpozo', value: 'Aldealpozo' },
  { label: 'Aldealseñor', value: 'Aldealseñor' },
  { label: 'Aldeamayor De San Martín', value: 'Aldeamayor De San Martín' },
  { label: 'Aldeanueva De Barbarroya', value: 'Aldeanueva De Barbarroya' },
  { label: 'Aldeanueva De Ebro', value: 'Aldeanueva De Ebro' },
  { label: 'Aldeanueva De Figueroa', value: 'Aldeanueva De Figueroa' },
  { label: 'Aldeanueva De Guadalajara', value: 'Aldeanueva De Guadalajara' },
  { label: 'Aldeanueva De La Serrezuela', value: 'Aldeanueva De La Serrezuela' },
  { label: 'Aldeanueva De La Sierra', value: 'Aldeanueva De La Sierra' },
  { label: 'Aldeanueva De La Vera', value: 'Aldeanueva De La Vera' },
  { label: 'Aldeanueva De San Bartolomé', value: 'Aldeanueva De San Bartolomé' },
  { label: 'Aldeanueva De Santa Cruz', value: 'Aldeanueva De Santa Cruz' },
  { label: 'Aldeanueva Del Camino', value: 'Aldeanueva Del Camino' },
  { label: 'Aldeanueva Del Codonal', value: 'Aldeanueva Del Codonal' },
  { label: 'Aldeaquemada', value: 'Aldeaquemada' },
  { label: 'Aldearrodrigo', value: 'Aldearrodrigo' },
  { label: 'Aldearrubia', value: 'Aldearrubia' },
  { label: 'Aldeaseca', value: 'Aldeaseca' },
  { label: 'Aldeaseca De Alba', value: 'Aldeaseca De Alba' },
  { label: 'Aldeaseca De La Frontera', value: 'Aldeaseca De La Frontera' },
  { label: 'Aldeasoña', value: 'Aldeasoña' },
  { label: 'Aldeatejada', value: 'Aldeatejada' },
  { label: 'Aldeavieja De Tormes', value: 'Aldeavieja De Tormes' },
  { label: 'Aldeboarn', value: 'Aldeboarn' },
  { label: 'Aldeburgh', value: 'Aldeburgh' },
  { label: 'Aldehorno', value: 'Aldehorno' },
  { label: 'Aldehuela De Jerte', value: 'Aldehuela De Jerte' },
  { label: 'Aldehuela De La Bóveda', value: 'Aldehuela De La Bóveda' },
  { label: 'Aldehuela De Liestos', value: 'Aldehuela De Liestos' },
  { label: 'Aldehuela De Periáñez', value: 'Aldehuela De Periáñez' },
  { label: 'Aldehuela De Yeltes', value: 'Aldehuela De Yeltes' },
  { label: 'Aldehuela Del Codonal', value: 'Aldehuela Del Codonal' },
  { label: 'Aldehuela La', value: 'Aldehuela La' },
  { label: 'Aldehuelas Las', value: 'Aldehuelas Las' },
  { label: 'Aldeia Da Ponte', value: 'Aldeia Da Ponte' },
  { label: 'Aldeia Da Ribeira', value: 'Aldeia Da Ribeira' },
  { label: 'Aldeia De Joanes', value: 'Aldeia De Joanes' },
  { label: 'Aldeia De Paio Pires', value: 'Aldeia De Paio Pires' },
  { label: 'Aldeia De Santo António', value: 'Aldeia De Santo António' },
  { label: 'Aldeia Do Bispo', value: 'Aldeia Do Bispo' },
  { label: 'Aldeia Galega Da Merceana', value: 'Aldeia Galega Da Merceana' },
  { label: 'Aldeia Gavinha', value: 'Aldeia Gavinha' },
  { label: 'Aldeia Nova De São Bento', value: 'Aldeia Nova De São Bento' },
  { label: 'Aldeia Nova Miranda Do Douro', value: 'Aldeia Nova Miranda Do Douro' },
  { label: 'Aldeia Velha', value: 'Aldeia Velha' },
  { label: 'Aldeia Viçosa', value: 'Aldeia Viçosa' },
  { label: 'Aldeias', value: 'Aldeias' },
  { label: 'Aldeias Altas', value: 'Aldeias Altas' },
  { label: 'Aldeire', value: 'Aldeire' },
  { label: 'Alden', value: 'Alden' },
  { label: 'Aldenhoven', value: 'Aldenhoven' },
  { label: 'Aldeno', value: 'Aldeno' },
  { label: 'Aldeonte', value: 'Aldeonte' },
  { label: 'Alderbury', value: 'Alderbury' },
  { label: 'Alderetes', value: 'Alderetes' },
  { label: 'Aldergrove', value: 'Aldergrove' },
  { label: 'Aldergrove East', value: 'Aldergrove East' },
  { label: 'Alderholt', value: 'Alderholt' },
  { label: 'Alderley', value: 'Alderley' },
  { label: 'Alderley Edge', value: 'Alderley Edge' },
  { label: 'Aldersbach', value: 'Aldersbach' },
  { label: 'Aldershot', value: 'Aldershot' },
  { label: 'Aldershot', value: 'Aldershot' },
  { label: 'Alderson', value: 'Alderson' },
  { label: 'Alderton', value: 'Alderton' },
  { label: 'Alderton', value: 'Alderton' },
  { label: 'Alderwood Manor', value: 'Alderwood Manor' },
  { label: 'Aldford', value: 'Aldford' },
  { label: 'Aldgate', value: 'Aldgate' },
  { label: 'Aldine', value: 'Aldine' },
  { label: 'Aldinga Beach', value: 'Aldinga Beach' },
  { label: 'Aldingen', value: 'Aldingen' },
  { label: 'Aldino', value: 'Aldino' },
  { label: 'Aldlân-Oost', value: 'Aldlân-Oost' },
  { label: 'Aldona', value: 'Aldona' },
  { label: 'Aldover', value: 'Aldover' },
  { label: 'Aldrans', value: 'Aldrans' },
  { label: 'Aldridge', value: 'Aldridge' },
  { label: 'Ale', value: 'Ale' },
  { label: 'Alecrim', value: 'Alecrim' },
  { label: 'Åled', value: 'Åled' },
  { label: 'Aledo', value: 'Aledo' },
  { label: 'Aledo', value: 'Aledo' },
  { label: 'Aledo', value: 'Aledo' },
  { label: 'Aleg', value: 'Aleg' },
  { label: 'Alegia', value: 'Alegia' },
  { label: 'Alegre', value: 'Alegre' },
  { label: 'Alegrete', value: 'Alegrete' },
  { label: 'Alegrete Do Piauí', value: 'Alegrete Do Piauí' },
  { label: 'Alegria', value: 'Alegria' },
  { label: 'Alegria', value: 'Alegria' },
  { label: 'Alegria', value: 'Alegria' },
  { label: 'Alegria', value: 'Alegria' },
  { label: 'Alegria', value: 'Alegria' },
  { label: 'Alegria', value: 'Alegria' },
  { label: 'Alegría-Dulantzi', value: 'Alegría-Dulantzi' },
  { label: 'Aleixar', value: 'Aleixar' },
  { label: 'Alejal', value: 'Alejal' },
  { label: 'Alejandría', value: 'Alejandría' },
  { label: 'Alejandro Gallinal', value: 'Alejandro Gallinal' },
  { label: 'Alejandro Roca', value: 'Alejandro Roca' },
  { label: 'Alejo González (Bilbao)', value: 'Alejo González (Bilbao)' },
  { label: 'Alejo Ledesma', value: 'Alejo Ledesma' },
  { label: 'Aleksandriya', value: 'Aleksandriya' },
  { label: 'Aleksandriyskaya', value: 'Aleksandriyskaya' },
  { label: 'Aleksandro-Nevskiy', value: 'Aleksandro-Nevskiy' },
  { label: 'Aleksandrov', value: 'Aleksandrov' },
  { label: 'Aleksandrov Gay', value: 'Aleksandrov Gay' },
  { label: 'Aleksandrovka', value: 'Aleksandrovka' },
  { label: 'Aleksandrovo', value: 'Aleksandrovo' },
  { label: 'Aleksandrovo-Zavodskiy Rayon', value: 'Aleksandrovo-Zavodskiy Rayon' },
  { label: 'Aleksandrovsk', value: 'Aleksandrovsk' },
  { label: 'Aleksandrovskaya', value: 'Aleksandrovskaya' },
  { label: 'Aleksandrovskaya', value: 'Aleksandrovskaya' },
  { label: 'Aleksandrovskiy Rayon', value: 'Aleksandrovskiy Rayon' },
  { label: 'Aleksandrovskiy Zavod', value: 'Aleksandrovskiy Zavod' },
  { label: 'Aleksandrovskoye', value: 'Aleksandrovskoye' },
  { label: 'Aleksandrovskoye', value: 'Aleksandrovskoye' },
  { label: 'Aleksandrovsk-Sakhalinskiy', value: 'Aleksandrovsk-Sakhalinskiy' },
  { label: 'Aleksandrów', value: 'Aleksandrów' },
  { label: 'Aleksandrów', value: 'Aleksandrów' },
  { label: 'Aleksandrów Kujawski', value: 'Aleksandrów Kujawski' },
  { label: 'Aleksandrów Łódzki', value: 'Aleksandrów Łódzki' },
  { label: 'Alekseyevka', value: 'Alekseyevka' },
  { label: 'Alekseyevka', value: 'Alekseyevka' },
  { label: 'Alekseyevka', value: 'Alekseyevka' },
  { label: 'Alekseyevka', value: 'Alekseyevka' },
  { label: 'Alekseyevka', value: 'Alekseyevka' },
  { label: 'Alekseyevskaya', value: 'Alekseyevskaya' },
  { label: 'Alekseyevskaya', value: 'Alekseyevskaya' },
  { label: 'Alekseyevskiy Rayon', value: 'Alekseyevskiy Rayon' },
  { label: 'Alekseyevskoye', value: 'Alekseyevskoye' },
  { label: 'Aleksin', value: 'Aleksin' },
  { label: 'Aleksinskiy Rayon', value: 'Aleksinskiy Rayon' },
  { label: 'Aleksotas', value: 'Aleksotas' },
  { label: 'Alella', value: 'Alella' },
  { label: 'Além Paraíba', value: 'Além Paraíba' },
  { label: 'Alemanguan', value: 'Alemanguan' },
  { label: 'Alençon', value: 'Alençon' },
  { label: 'Alenquer', value: 'Alenquer' },
  { label: 'Alenquer', value: 'Alenquer' },
  { label: 'Alenquer', value: 'Alenquer' },
  { label: 'Alentisque', value: 'Alentisque' },
  { label: 'Alénya', value: 'Alénya' },
  { label: 'Alepoú', value: 'Alepoú' },
  { label: 'Aleppo', value: 'Aleppo' },
  { label: 'Alerheim', value: 'Alerheim' },
  { label: 'Aléria', value: 'Aléria' },
  { label: 'Alerre', value: 'Alerre' },
  { label: 'Ales', value: 'Ales' },
  { label: 'Alès', value: 'Alès' },
  { label: 'Alesanco', value: 'Alesanco' },
  { label: 'Aleşd', value: 'Aleşd' },
  { label: 'Alesheim', value: 'Alesheim' },
  { label: 'Aleshtar', value: 'Aleshtar' },
  { label: 'Alesón', value: 'Alesón' },
  { label: 'Alessandria', value: 'Alessandria' },
  { label: 'Alessandria Del Carretto', value: 'Alessandria Del Carretto' },
  { label: 'Alessandria Della Rocca', value: 'Alessandria Della Rocca' },
  { label: 'Alessano', value: 'Alessano' },
  { label: 'Ålestrup', value: 'Ålestrup' },
  { label: 'Ålesund', value: 'Ålesund' },
  { label: 'Aletshausen', value: 'Aletshausen' },
  { label: 'Aleutians East Borough', value: 'Aleutians East Borough' },
  { label: 'Aleutians West Census Area', value: 'Aleutians West Census Area' },
  { label: 'Aleutskiy Rayon', value: 'Aleutskiy Rayon' },
  { label: 'Alexander', value: 'Alexander' },
  { label: 'Alexander City', value: 'Alexander City' },
  { label: 'Alexander County', value: 'Alexander County' },
  { label: 'Alexander County', value: 'Alexander County' },
  { label: 'Alexander Heights', value: 'Alexander Heights' },
  { label: 'Alexandra', value: 'Alexandra' },
  { label: 'Alexandra Headland', value: 'Alexandra Headland' },
  { label: 'Alexandra Hills', value: 'Alexandra Hills' },
  { label: 'Alexándreia', value: 'Alexándreia' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Alexandria Bay', value: 'Alexandria Bay' },
  { label: 'Alexandrina', value: 'Alexandrina' },
  { label: 'Alexandroupoli', value: 'Alexandroupoli' },
  { label: 'Alexandru I. Cuza', value: 'Alexandru I. Cuza' },
  { label: 'Alexandru Odobescu', value: 'Alexandru Odobescu' },
  { label: 'Alexandru Vlăhuţă', value: 'Alexandru Vlăhuţă' },
  { label: 'Alexânia', value: 'Alexânia' },
  { label: 'Alexeni', value: 'Alexeni' },
  { label: 'Aleysk', value: 'Aleysk' },
  { label: 'Aleyskiy Rayon', value: 'Aleyskiy Rayon' },
  { label: 'Alezio', value: 'Alezio' },
  { label: 'Alfacar', value: 'Alfacar' },
  { label: 'Alfafar', value: 'Alfafar' },
  { label: 'Alfafara', value: 'Alfafara' },
  { label: 'Alfaiates', value: 'Alfaiates' },
  { label: 'Alfajarín', value: 'Alfajarín' },
  { label: 'Alfajayucan', value: 'Alfajayucan' },
  { label: 'Alfajayucan', value: 'Alfajayucan' },
  { label: 'Alfalfa County', value: 'Alfalfa County' },
  { label: 'Alfambra', value: 'Alfambra' },
  { label: 'Alfamén', value: 'Alfamén' },
  { label: 'Alfândega Da Fé', value: 'Alfândega Da Fé' },
  { label: 'Alfano', value: 'Alfano' },
  { label: 'Alfántega', value: 'Alfántega' },
  { label: 'Alfara De Algimia', value: 'Alfara De Algimia' },
  { label: 'Alfara De Carles', value: 'Alfara De Carles' },
  { label: 'Alfara De La Baronia', value: 'Alfara De La Baronia' },
  { label: 'Alfara Del Patriarca', value: 'Alfara Del Patriarca' },
  { label: 'Alfaraz De Sayago', value: 'Alfaraz De Sayago' },
  { label: 'Alfarelos', value: 'Alfarelos' },
  { label: 'Alfarnate', value: 'Alfarnate' },
  { label: 'Alfarnatejo', value: 'Alfarnatejo' },
  { label: 'Alfaro', value: 'Alfaro' },
  { label: 'Alfaro', value: 'Alfaro' },
  { label: 'Alfarp', value: 'Alfarp' },
  { label: 'Alfarràs', value: 'Alfarràs' },
  { label: 'Alfarrasí', value: 'Alfarrasí' },
  { label: 'Alfàs Del Pi L', value: 'Alfàs Del Pi L' },
  { label: 'Alfatar', value: 'Alfatar' },
  { label: 'Alfauir', value: 'Alfauir' },
  { label: 'Alfavut', value: 'Alfavut' },
  { label: 'Alfdorf', value: 'Alfdorf' },
  { label: 'Alfedena', value: 'Alfedena' },
  { label: 'Alfeioúsa', value: 'Alfeioúsa' },
  { label: 'Alfeizerão', value: 'Alfeizerão' },
  { label: 'Alfeld', value: 'Alfeld' },
  { label: 'Alfeld', value: 'Alfeld' },
  { label: 'Alfena', value: 'Alfena' },
  { label: 'Alfenas', value: 'Alfenas' },
  { label: 'Alferrarede', value: 'Alferrarede' },
  { label: 'Alfés', value: 'Alfés' },
  { label: 'Alfhausen', value: 'Alfhausen' },
  { label: 'Alfianello', value: 'Alfianello' },
  { label: 'Alfiano Natta', value: 'Alfiano Natta' },
  { label: 'Alfondeguilla', value: 'Alfondeguilla' },
  { label: 'Alfonsine', value: 'Alfonsine' },
  { label: 'Alfonso', value: 'Alfonso' },
  { label: 'Alfonso Castañeda', value: 'Alfonso Castañeda' },
  { label: 'Alfonso G. Calderón (Poblado Siete)', value: 'Alfonso G. Calderón (Poblado Siete)' },
  { label: 'Alfonso G. Calderón Velarde', value: 'Alfonso G. Calderón Velarde' },
  { label: 'Alfonso Garzón [Granjas Familiares]', value: 'Alfonso Garzón [Granjas Familiares]' },
  { label: 'Alfonso Moguel', value: 'Alfonso Moguel' },
  { label: 'Alfonso Reyes', value: 'Alfonso Reyes' },
  { label: 'Alford', value: 'Alford' },
  { label: 'Alford', value: 'Alford' },
  { label: 'Alfords Point', value: 'Alfords Point' },
  { label: 'Alforja', value: 'Alforja' },
  { label: 'Alfornelos', value: 'Alfornelos' },
  { label: 'Alforque', value: 'Alforque' },
  { label: 'Alfortville', value: 'Alfortville' },
  { label: 'Alfoz', value: 'Alfoz' },
  { label: 'Alfoz De Bricia', value: 'Alfoz De Bricia' },
  { label: 'Alfoz De Lloredo', value: 'Alfoz De Lloredo' },
  { label: 'Alfoz De Quintanadueñas', value: 'Alfoz De Quintanadueñas' },
  { label: 'Alfoz De Santa Gadea', value: 'Alfoz De Santa Gadea' },
  { label: 'Alfragide', value: 'Alfragide' },
  { label: 'Alfragide', value: 'Alfragide' },
  { label: 'Alfred', value: 'Alfred' },
  { label: 'Alfred', value: 'Alfred' },
  { label: 'Alfred Cove', value: 'Alfred Cove' },
  { label: 'Alfred Nzo District Municipality', value: 'Alfred Nzo District Municipality' },
  { label: 'Alfredo Baquerizo Moreno', value: 'Alfredo Baquerizo Moreno' },
  { label: 'Alfredo Chaves', value: 'Alfredo Chaves' },
  { label: 'Alfredo Marcondes', value: 'Alfredo Marcondes' },
  { label: 'Alfredo V. Bonfil', value: 'Alfredo V. Bonfil' },
  { label: 'Alfredo V. Bonfil', value: 'Alfredo V. Bonfil' },
  { label: 'Alfredo V. Bonfil', value: 'Alfredo V. Bonfil' },
  { label: 'Alfredo V. Bonfil', value: 'Alfredo V. Bonfil' },
  { label: 'Alfredo V. Bonfil (Chacampalco)', value: 'Alfredo V. Bonfil (Chacampalco)' },
  { label: 'Alfredo V. Bonfil (Siete Ejidos)', value: 'Alfredo V. Bonfil (Siete Ejidos)' },
  { label: 'Alfredo Vasconcelos', value: 'Alfredo Vasconcelos' },
  { label: 'Alfredo Wagner', value: 'Alfredo Wagner' },
  { label: 'Alfredton', value: 'Alfredton' },
  { label: 'Alfreton', value: 'Alfreton' },
  { label: 'Alfta', value: 'Alfta' },
  { label: 'Álftanes', value: 'Álftanes' },
  { label: 'Alfter', value: 'Alfter' },
  { label: 'Algaba La', value: 'Algaba La' },
  { label: 'Algadefe', value: 'Algadefe' },
  { label: 'Algaida', value: 'Algaida' },
  { label: 'Algámitas', value: 'Algámitas' },
  { label: 'Algar', value: 'Algar' },
  { label: 'Algar De Mesa', value: 'Algar De Mesa' },
  { label: 'Algar De Palancia', value: 'Algar De Palancia' },
  { label: 'Algarinejo', value: 'Algarinejo' },
  { label: 'Algarra', value: 'Algarra' },
  { label: 'Algarrobo', value: 'Algarrobo' },
  { label: 'Algarrobo', value: 'Algarrobo' },
  { label: 'Algarrobo', value: 'Algarrobo' },
  { label: 'Algarrobos Arriba', value: 'Algarrobos Arriba' },
  { label: 'Algatocín', value: 'Algatocín' },
  { label: 'Algatuy', value: 'Algatuy' },
  { label: 'Algeciras', value: 'Algeciras' },
  { label: 'Algeciras', value: 'Algeciras' },
  { label: 'Algeciras', value: 'Algeciras' },
  { label: 'Algemesí', value: 'Algemesí' },
  { label: 'Alger County', value: 'Alger County' },
  { label: 'Algermissen', value: 'Algermissen' },
  { label: 'Algerri', value: 'Algerri' },
  { label: 'Algés', value: 'Algés' },
  { label: 'Algés', value: 'Algés' },
  { label: 'Algester', value: 'Algester' },
  { label: 'Algete', value: 'Algete' },
  { label: 'Alghero', value: 'Alghero' },
  { label: 'Algiers', value: 'Algiers' },
  { label: 'Algimia De Alfara', value: 'Algimia De Alfara' },
  { label: 'Algimia De Almonacid', value: 'Algimia De Almonacid' },
  { label: 'Alginet', value: 'Alginet' },
  { label: 'Algodão De Jandaíra', value: 'Algodão De Jandaíra' },
  { label: 'Algodonales', value: 'Algodonales' },
  { label: 'Algodre', value: 'Algodre' },
  { label: 'Algodres', value: 'Algodres' },
  { label: 'Algolsheim', value: 'Algolsheim' },
  { label: 'Algoma', value: 'Algoma' },
  { label: 'Algoma', value: 'Algoma' },
  { label: 'Algona', value: 'Algona' },
  { label: 'Algona', value: 'Algona' },
  { label: 'Algonac', value: 'Algonac' },
  { label: 'Algonquin', value: 'Algonquin' },
  { label: 'Algonquin', value: 'Algonquin' },
  { label: 'Algood', value: 'Algood' },
  { label: 'Algora', value: 'Algora' },
  { label: 'Algorfa', value: 'Algorfa' },
  { label: 'Algoz', value: 'Algoz' },
  { label: 'Algrange', value: 'Algrange' },
  { label: 'Algua', value: 'Algua' },
  { label: 'Alguaire', value: 'Alguaire' },
  { label: 'Alguazas', value: 'Alguazas' },
  { label: 'Alguber', value: 'Alguber' },
  { label: 'Algueirão', value: 'Algueirão' },
  { label: 'Algueirão', value: 'Algueirão' },
  { label: 'Algueirão–Mem Martins', value: 'Algueirão–Mem Martins' },
  { label: 'Algueña', value: 'Algueña' },
  { label: 'Algyő', value: 'Algyő' },
  { label: 'Alhabia', value: 'Alhabia' },
  { label: 'Alhadas', value: 'Alhadas' },
  { label: 'Al-Haditha', value: 'Al-Haditha' },
  { label: 'Al-Haffah District', value: 'Al-Haffah District' },
  { label: 'Alhama De Almería', value: 'Alhama De Almería' },
  { label: 'Alhama De Aragón', value: 'Alhama De Aragón' },
  { label: 'Alhama De Granada', value: 'Alhama De Granada' },
  { label: 'Alhama De Murcia', value: 'Alhama De Murcia' },
  { label: 'Alhambra', value: 'Alhambra' },
  { label: 'Alhambra', value: 'Alhambra' },
  { label: 'Alhambra', value: 'Alhambra' },
  { label: 'Al-Hamdaniya', value: 'Al-Hamdaniya' },
  { label: 'Alhandra', value: 'Alhandra' },
  { label: 'Alhandra', value: 'Alhandra' },
  { label: 'Alhandra', value: 'Alhandra' },
  { label: 'Al-Haouz', value: 'Al-Haouz' },
  { label: 'Alhaurín De La Torre', value: 'Alhaurín De La Torre' },
  { label: 'Alhaurín El Grande', value: 'Alhaurín El Grande' },
  { label: 'Alheim', value: 'Alheim' },
  { label: 'Alhendín', value: 'Alhendín' },
  { label: 'Al-Hoceima', value: 'Al-Hoceima' },
  { label: 'Alhóndiga', value: 'Alhóndiga' },
  { label: 'Alhos Vedros', value: 'Alhos Vedros' },
  { label: 'Alhuaca', value: 'Alhuaca' },
  { label: 'Alhué', value: 'Alhué' },
  { label: 'Alhuey', value: 'Alhuey' },
  { label: 'Alì', value: 'Alì' },
  { label: 'Alī Al Gharbī', value: 'Alī Al Gharbī' },
  { label: 'Ali Sabieh', value: 'Ali Sabieh' },
  { label: 'Alì Terme', value: 'Alì Terme' },
  { label: 'Alia', value: 'Alia' },
  { label: 'Alía', value: 'Alía' },
  { label: 'Aliabad', value: 'Aliabad' },
  { label: 'Aliabad-E Posht Shahr', value: 'Aliabad-E Posht Shahr' },
  { label: 'Aliabad-E-Katul', value: 'Aliabad-E-Katul' },
  { label: 'Aliade', value: 'Aliade' },
  { label: 'Aliaga', value: 'Aliaga' },
  { label: 'Aliaga', value: 'Aliaga' },
  { label: 'Aliaga', value: 'Aliaga' },
  { label: 'Aliağa', value: 'Aliağa' },
  { label: 'Aliaguilla', value: 'Aliaguilla' },
  { label: 'Aliança', value: 'Aliança' },
  { label: 'Aliança Do Tocantins', value: 'Aliança Do Tocantins' },
  { label: 'Aliang', value: 'Aliang' },
  { label: 'Aliano', value: 'Aliano' },
  { label: 'Alianza', value: 'Alianza' },
  { label: 'Alianza Real', value: 'Alianza Real' },
  { label: 'Alíartos', value: 'Alíartos' },
  { label: 'Alibag', value: 'Alibag' },
  { label: 'Alibago', value: 'Alibago' },
  { label: 'Ali-Berdukovskiy', value: 'Ali-Berdukovskiy' },
  { label: 'Alibug', value: 'Alibug' },
  { label: 'Alibunan', value: 'Alibunan' },
  { label: 'Alibunar', value: 'Alibunar' },
  { label: 'Alicante', value: 'Alicante' },
  { label: 'Alicante', value: 'Alicante' },
  { label: 'Alicante/Alacant', value: 'Alicante/Alacant' },
  { label: 'Alice', value: 'Alice' },
  { label: 'Alice', value: 'Alice' },
  { label: 'Alice Bel Colle', value: 'Alice Bel Colle' },
  { label: 'Alice Castello', value: 'Alice Castello' },
  { label: 'Alice River', value: 'Alice River' },
  { label: 'Alice Springs', value: 'Alice Springs' },
  { label: 'Alice Superiore', value: 'Alice Superiore' },
  { label: 'Alice Town', value: 'Alice Town' },
  { label: 'Aliceville', value: 'Aliceville' },
  { label: 'Alicia', value: 'Alicia' },
  { label: 'Alicia', value: 'Alicia' },
  { label: 'Alicia', value: 'Alicia' },
  { label: 'Alicia', value: 'Alicia' },
  { label: 'Alicún', value: 'Alicún' },
  { label: 'Alicún De Ortega', value: 'Alicún De Ortega' },
  { label: 'Alief', value: 'Alief' },
  { label: 'Alife', value: 'Alife' },
  { label: 'Aliganj', value: 'Aliganj' },
  { label: 'Aligarh', value: 'Aligarh' },
  { label: 'Alignan-Du-Vent', value: 'Alignan-Du-Vent' },
  { label: 'Aligudarz', value: 'Aligudarz' },
  { label: 'Alija Del Infantado', value: 'Alija Del Infantado' },
  { label: 'Alijis', value: 'Alijis' },
  { label: 'Alijó', value: 'Alijó' },
  { label: 'Alik Ghund', value: 'Alik Ghund' },
  { label: 'Alikalia', value: 'Alikalia' },
  { label: 'Alikovo', value: 'Alikovo' },
  { label: 'Alikovskiy Rayon', value: 'Alikovskiy Rayon' },
  { label: 'Alilem', value: 'Alilem' },
  { label: 'Alilem', value: 'Alilem' },
  { label: 'Alim', value: 'Alim' },
  { label: 'Aliman', value: 'Aliman' },
  { label: 'Alimănești', value: 'Alimănești' },
  { label: 'Alimena', value: 'Alimena' },
  { label: 'Aliminusa', value: 'Aliminusa' },
  { label: 'Alimodian', value: 'Alimodian' },
  { label: 'Alimono', value: 'Alimono' },
  { label: 'Álimos', value: 'Álimos' },
  { label: 'Alimosho', value: 'Alimosho' },
  { label: 'Alimov-Lyubimovsky', value: 'Alimov-Lyubimovsky' },
  { label: 'Alimpeşti', value: 'Alimpeşti' },
  { label: 'Alindao', value: 'Alindao' },
  { label: 'Alingsås', value: 'Alingsås' },
  { label: 'Alins', value: 'Alins' },
  { label: 'Alió', value: 'Alió' },
  { label: 'Alipur', value: 'Alipur' },
  { label: 'Alipur Chatha', value: 'Alipur Chatha' },
  { label: 'Alipurduar', value: 'Alipurduar' },
  { label: 'Alique', value: 'Alique' },
  { label: 'Aliquippa', value: 'Aliquippa' },
  { label: 'Alirajpur', value: 'Alirajpur' },
  { label: 'Aliseda', value: 'Aliseda' },
  { label: 'Alishahr', value: 'Alishahr' },
  { label: 'Aliso Viejo', value: 'Aliso Viejo' },
  { label: 'Alissas', value: 'Alissas' },
  { label: 'Alista', value: 'Alista' },
  { label: 'Alistráti', value: 'Alistráti' },
  { label: 'Alitagtag', value: 'Alitagtag' },
  { label: 'Aliud', value: 'Aliud' },
  { label: 'Alivéri', value: 'Alivéri' },
  { label: 'Aliwal North', value: 'Aliwal North' },
  { label: 'Alixan', value: 'Alixan' },
  { label: 'Ali-Yurt', value: 'Ali-Yurt' },
  { label: 'Alizai', value: 'Alizai' },
  { label: 'Alizay', value: 'Alizay' },
  { label: 'Al-Jafr', value: 'Al-Jafr' },
  { label: 'Aljaraque', value: 'Aljaraque' },
  { label: 'Aljezur', value: 'Aljezur' },
  { label: 'Aljojuca', value: 'Aljojuca' },
  { label: 'Aljubarrota', value: 'Aljubarrota' },
  { label: 'Aljucén', value: 'Aljucén' },
  { label: 'Aljustrel', value: 'Aljustrel' },
  { label: 'Alken', value: 'Alken' },
  { label: 'Alkhan-Churt', value: 'Alkhan-Churt' },
  { label: 'Alkhan-Kala', value: 'Alkhan-Kala' },
  { label: 'Alkhan-Yurt', value: 'Alkhan-Yurt' },
  { label: 'Al-Khashā Upper', value: 'Al-Khashā Upper' },
  { label: 'Alkhazurovo', value: 'Alkhazurovo' },
  { label: 'Alkimos', value: 'Alkimos' },
  { label: 'Alkiza', value: 'Alkiza' },
  { label: 'Alkmaar', value: 'Alkmaar' },
  { label: 'Alkoven', value: 'Alkoven' },
  { label: 'Alkuti', value: 'Alkuti' },
  { label: 'All Saints', value: 'All Saints' },
  { label: 'Allacapan', value: 'Allacapan' },
  { label: 'Allacapan', value: 'Allacapan' },
  { label: 'Allada', value: 'Allada' },
  { label: 'Allahabad', value: 'Allahabad' },
  { label: 'Allahganj', value: 'Allahganj' },
  { label: 'Allai', value: 'Allai' },
  { label: 'Allaikhovskiy Rayon', value: 'Allaikhovskiy Rayon' },
  { label: 'Allaire', value: 'Allaire' },
  { label: 'Allamakee County', value: 'Allamakee County' },
  { label: 'Allambie Heights', value: 'Allambie Heights' },
  { label: 'Allan', value: 'Allan' },
  { label: 'Allanche', value: 'Allanche' },
  { label: 'Alland', value: 'Alland' },
  { label: 'Allande', value: 'Allande' },
  { label: 'Allangigan Primero', value: 'Allangigan Primero' },
  { label: 'Allangigan Primero', value: 'Allangigan Primero' },
  { label: 'Allanridge', value: 'Allanridge' },
  { label: 'Allansford', value: 'Allansford' },
  { label: 'Allanton', value: 'Allanton' },
  { label: 'Allapalli', value: 'Allapalli' },
  { label: 'Allapattah', value: 'Allapattah' },
  { label: 'Allariz', value: 'Allariz' },
  { label: 'Allassac', value: 'Allassac' },
  { label: 'Allauch', value: 'Allauch' },
  { label: 'Allawah', value: 'Allawah' },
  { label: 'Alle', value: 'Alle' },
  { label: 'Allegan', value: 'Allegan' },
  { label: 'Allegan County', value: 'Allegan County' },
  { label: 'Allegany', value: 'Allegany' },
  { label: 'Allegany County', value: 'Allegany County' },
  { label: 'Allegany County', value: 'Allegany County' },
  { label: 'Alleghany County', value: 'Alleghany County' },
  { label: 'Alleghany County', value: 'Alleghany County' },
  { label: 'Alleghe', value: 'Alleghe' },
  { label: 'Allegheny County', value: 'Allegheny County' },
  { label: 'Alleghenyville', value: 'Alleghenyville' },
  { label: 'Allègre', value: 'Allègre' },
  { label: 'Allein', value: 'Allein' },
  { label: 'Alleins', value: 'Alleins' },
  { label: 'Allen', value: 'Allen' },
  { label: 'Allen', value: 'Allen' },
  { label: 'Allen', value: 'Allen' },
  { label: 'Allen', value: 'Allen' },
  { label: 'Allen County', value: 'Allen County' },
  { label: 'Allen County', value: 'Allen County' },
  { label: 'Allen County', value: 'Allen County' },
  { label: 'Allen County', value: 'Allen County' },
  { label: 'Allen Parish', value: 'Allen Parish' },
  { label: 'Allen Park', value: 'Allen Park' },
  { label: 'Allenby Gardens', value: 'Allenby Gardens' },
  { label: 'Allendale', value: 'Allendale' },
  { label: 'Allendale', value: 'Allendale' },
  { label: 'Allendale', value: 'Allendale' },
  { label: 'Allendale', value: 'Allendale' },
  { label: 'Allendale County', value: 'Allendale County' },
  { label: 'Allende', value: 'Allende' },
  { label: 'Allende', value: 'Allende' },
  { label: 'Allende', value: 'Allende' },
  { label: 'Allende', value: 'Allende' },
  { label: 'Allende', value: 'Allende' },
  { label: 'Allende', value: 'Allende' },
  { label: 'Allendorf', value: 'Allendorf' },
  { label: 'Allendorf An Der Lahn', value: 'Allendorf An Der Lahn' },
  { label: 'Allennes-Les-Marais', value: 'Allennes-Les-Marais' },
  { label: 'Allensbach', value: 'Allensbach' },
  { label: 'Allenstown', value: 'Allenstown' },
  { label: 'Allentown', value: 'Allentown' },
  { label: 'Allentown', value: 'Allentown' },
  { label: 'Allentsteig', value: 'Allentsteig' },
  { label: 'Allepuz', value: 'Allepuz' },
  { label: 'Aller', value: 'Aller' },
  { label: 'Allerheiligen Bei Wildon', value: 'Allerheiligen Bei Wildon' },
  { label: 'Allerheiligen Im Mühlkreis', value: 'Allerheiligen Im Mühlkreis' },
  { label: 'Allerheiligen Im Mürztal', value: 'Allerheiligen Im Mürztal' },
  { label: 'Allerød Kommune', value: 'Allerød Kommune' },
  { label: 'Allerona', value: 'Allerona' },
  { label: 'Alleroy', value: 'Alleroy' },
  { label: 'Allersberg', value: 'Allersberg' },
  { label: 'Allershausen', value: 'Allershausen' },
  { label: 'Allevard', value: 'Allevard' },
  { label: 'Allex', value: 'Allex' },
  { label: 'Alley', value: 'Alley' },
  { label: 'Allhallows', value: 'Allhallows' },
  { label: 'Allhaming', value: 'Allhaming' },
  { label: 'Allhartsberg', value: 'Allhartsberg' },
  { label: 'Álli Meriá', value: 'Álli Meriá' },
  { label: 'Alliance', value: 'Alliance' },
  { label: 'Alliance', value: 'Alliance' },
  { label: 'Allières-Et-Risset', value: 'Allières-Et-Risset' },
  { label: 'Alligator Creek', value: 'Alligator Creek' },
  { label: 'Alligator Pond', value: 'Alligator Pond' },
  { label: 'Alligator Pond', value: 'Alligator Pond' },
  { label: 'Allín/Allin', value: 'Allín/Allin' },
  { label: 'Alling', value: 'Alling' },
  { label: 'Allingåbro', value: 'Allingåbro' },
  { label: 'Allinges', value: 'Allinges' },
  { label: 'Allison', value: 'Allison' },
  { label: 'Allison Park', value: 'Allison Park' },
  { label: 'Alliste', value: 'Alliste' },
  { label: 'Alliston', value: 'Alliston' },
  { label: 'Allman Town', value: 'Allman Town' },
  { label: 'Allmendingen', value: 'Allmendingen' },
  { label: 'Allmersbach Im Tal', value: 'Allmersbach Im Tal' },
  { label: 'Allo', value: 'Allo' },
  { label: 'Alloa', value: 'Alloa' },
  { label: 'Allonne', value: 'Allonne' },
  { label: 'Allonnes', value: 'Allonnes' },
  { label: 'Allonzier-La-Caille', value: 'Allonzier-La-Caille' },
  { label: 'Allora', value: 'Allora' },
  { label: 'Allouagne', value: 'Allouagne' },
  { label: 'Allouez', value: 'Allouez' },
  { label: 'Alloway', value: 'Alloway' },
  { label: 'Alloza', value: 'Alloza' },
  { label: 'Allschwil', value: 'Allschwil' },
  { label: 'Allstedt', value: 'Allstedt' },
  { label: 'Allueva', value: 'Allueva' },
  { label: 'Alluheyah', value: 'Alluheyah' },
  { label: 'Allumiere', value: 'Allumiere' },
  { label: 'Allyn', value: 'Allyn' },
  { label: 'Alma', value: 'Alma' },
  { label: 'Alma', value: 'Alma' },
  { label: 'Alma', value: 'Alma' },
  { label: 'Alma', value: 'Alma' },
  { label: 'Alma', value: 'Alma' },
  { label: 'Alma', value: 'Alma' },
  { label: 'Alma', value: 'Alma' },
  { label: 'Alma', value: 'Alma' },
  { label: 'Almacelles', value: 'Almacelles' },
  { label: 'Almáchar', value: 'Almáchar' },
  { label: 'Almada', value: 'Almada' },
  { label: 'Almadén', value: 'Almadén' },
  { label: 'Almadén De La Plata', value: 'Almadén De La Plata' },
  { label: 'Almadenejos', value: 'Almadenejos' },
  { label: 'Almadina', value: 'Almadina' },
  { label: 'Almadrones', value: 'Almadrones' },
  { label: 'Almafuerte', value: 'Almafuerte' },
  { label: 'Almafuerte', value: 'Almafuerte' },
  { label: 'Almagres', value: 'Almagres' },
  { label: 'Almagro', value: 'Almagro' },
  { label: 'Almagro', value: 'Almagro' },
  { label: 'Almagro', value: 'Almagro' },
  { label: 'Almaguer', value: 'Almaguer' },
  { label: 'Almaguer North', value: 'Almaguer North' },
  { label: 'Almaguer North', value: 'Almaguer North' },
  { label: 'Almăj', value: 'Almăj' },
  { label: 'Almajano', value: 'Almajano' },
  { label: 'Al-Malikiyah District', value: 'Al-Malikiyah District' },
  { label: 'Almaluez', value: 'Almaluez' },
  { label: 'Almancil', value: 'Almancil' },
  { label: 'Almansa', value: 'Almansa' },
  { label: 'Almanza', value: 'Almanza' },
  { label: 'Almaraz', value: 'Almaraz' },
  { label: 'Almaraz De Duero', value: 'Almaraz De Duero' },
  { label: 'Almarcha La', value: 'Almarcha La' },
  { label: 'Almargem', value: 'Almargem' },
  { label: 'Almargem', value: 'Almargem' },
  { label: 'Almargem Do Bispo', value: 'Almargem Do Bispo' },
  { label: 'Almargen', value: 'Almargen' },
  { label: 'Almarza', value: 'Almarza' },
  { label: 'Almarza De Cameros', value: 'Almarza De Cameros' },
  { label: 'Almas', value: 'Almas' },
  { label: 'Almaş', value: 'Almaş' },
  { label: 'Al-Masayel', value: 'Al-Masayel' },
  { label: 'Almásfüzitő', value: 'Almásfüzitő' },
  { label: 'Almàssera', value: 'Almàssera' },
  { label: 'Almassora', value: 'Almassora' },
  { label: 'Almaşu', value: 'Almaşu' },
  { label: 'Almaşu Mare', value: 'Almaşu Mare' },
  { label: 'Almatret', value: 'Almatret' },
  { label: 'Almaty', value: 'Almaty' },
  { label: 'Almazán', value: 'Almazán' },
  { label: 'Almaznyy', value: 'Almaznyy' },
  { label: 'Almaznyy', value: 'Almaznyy' },
  { label: 'Almazora/Almassora', value: 'Almazora/Almassora' },
  { label: 'Almazul', value: 'Almazul' },
  { label: 'Almè', value: 'Almè' },
  { label: 'Almecatla', value: 'Almecatla' },
  { label: 'Almedia', value: 'Almedia' },
  { label: 'Almedíjar', value: 'Almedíjar' },
  { label: 'Almedina', value: 'Almedina' },
  { label: 'Almedinilla', value: 'Almedinilla' },
  { label: 'Al-Medy Village قرية المدي', value: 'Al-Medy Village قرية المدي' },
  { label: 'Almegíjar', value: 'Almegíjar' },
  { label: 'Almeida', value: 'Almeida' },
  { label: 'Almeida', value: 'Almeida' },
  { label: 'Almeida De Sayago', value: 'Almeida De Sayago' },
  { label: 'Almeirim', value: 'Almeirim' },
  { label: 'Almeirim', value: 'Almeirim' },
  { label: 'Al-Mejar Al-Kabi District', value: 'Al-Mejar Al-Kabi District' },
  { label: 'Almelo', value: 'Almelo' },
  { label: 'Almenar', value: 'Almenar' },
  { label: 'Almenar De Soria', value: 'Almenar De Soria' },
  { label: 'Almenara', value: 'Almenara' },
  { label: 'Almenara', value: 'Almenara' },
  { label: 'Almenara De Adaja', value: 'Almenara De Adaja' },
  { label: 'Almenara De Tormes', value: 'Almenara De Tormes' },
  { label: 'Almendra', value: 'Almendra' },
  { label: 'Almendra', value: 'Almendra' },
  { label: 'Almendral', value: 'Almendral' },
  { label: 'Almendral De La Cañada', value: 'Almendral De La Cañada' },
  { label: 'Almendralejo', value: 'Almendralejo' },
  { label: 'Almendras', value: 'Almendras' },
  { label: 'Almendro El', value: 'Almendro El' },
  { label: 'Almendros', value: 'Almendros' },
  { label: 'Almenno San Bartolomeo', value: 'Almenno San Bartolomeo' },
  { label: 'Almenno San Salvatore', value: 'Almenno San Salvatore' },
  { label: 'Almensilla', value: 'Almensilla' },
  { label: 'Almere Stad', value: 'Almere Stad' },
  { label: 'Almeria', value: 'Almeria' },
  { label: 'Almeria', value: 'Almeria' },
  { label: 'Almería', value: 'Almería' },
  { label: 'Almese-Rivera', value: 'Almese-Rivera' },
  { label: 'Älmhult', value: 'Älmhult' },
  { label: 'Almino Afonso', value: 'Almino Afonso' },
  { label: 'Almirante', value: 'Almirante' },
  { label: 'Almirante Tamandaré', value: 'Almirante Tamandaré' },
  { label: 'Almirante Tamandaré Do Sul', value: 'Almirante Tamandaré Do Sul' },
  { label: 'Almis Marmoucha', value: 'Almis Marmoucha' },
  { label: 'Almiserà', value: 'Almiserà' },
  { label: 'Almkerk', value: 'Almkerk' },
  { label: 'Almochuel', value: 'Almochuel' },
  { label: 'Almócita', value: 'Almócita' },
  { label: 'Almodôvar', value: 'Almodôvar' },
  { label: 'Almodóvar Del Campo', value: 'Almodóvar Del Campo' },
  { label: 'Almodóvar Del Pinar', value: 'Almodóvar Del Pinar' },
  { label: 'Almodóvar Del Río', value: 'Almodóvar Del Río' },
  { label: 'Almofala', value: 'Almofala' },
  { label: 'Almogía', value: 'Almogía' },
  { label: 'Almograve', value: 'Almograve' },
  { label: 'Almoguera', value: 'Almoguera' },
  { label: 'Almohaja', value: 'Almohaja' },
  { label: 'Almoharín', value: 'Almoharín' },
  { label: 'Almoines', value: 'Almoines' },
  { label: 'Almolda La', value: 'Almolda La' },
  { label: 'Almolonga', value: 'Almolonga' },
  { label: 'Almolonga', value: 'Almolonga' },
  { label: 'Almolonga', value: 'Almolonga' },
  { label: 'Almolonga', value: 'Almolonga' },
  { label: 'Almoloya', value: 'Almoloya' },
  { label: 'Almoloya De Alquisiras', value: 'Almoloya De Alquisiras' },
  { label: 'Almoloya Del Río', value: 'Almoloya Del Río' },
  { label: 'Almonacid De La Cuba', value: 'Almonacid De La Cuba' },
  { label: 'Almonacid De La Sierra', value: 'Almonacid De La Sierra' },
  { label: 'Almonacid De Toledo', value: 'Almonacid De Toledo' },
  { label: 'Almonacid De Zorita', value: 'Almonacid De Zorita' },
  { label: 'Almonacid Del Marquesado', value: 'Almonacid Del Marquesado' },
  { label: 'Almonaster La Real', value: 'Almonaster La Real' },
  { label: 'Almondale', value: 'Almondale' },
  { label: 'Almondbank', value: 'Almondbank' },
  { label: 'Almondsbury', value: 'Almondsbury' },
  { label: 'Almont', value: 'Almont' },
  { label: 'Almonte', value: 'Almonte' },
  { label: 'Almora', value: 'Almora' },
  { label: 'Almoradí', value: 'Almoradí' },
  { label: 'Almorox', value: 'Almorox' },
  { label: 'Almoster', value: 'Almoster' },
  { label: 'Almozara', value: 'Almozara' },
  { label: 'Älmsta', value: 'Älmsta' },
  { label: 'Al-Mubarraz', value: 'Al-Mubarraz' },
  { label: 'Almudaina', value: 'Almudaina' },
  { label: 'Almudévar', value: 'Almudévar' },
  { label: 'Almuñécar', value: 'Almuñécar' },
  { label: 'Almunia De Doña Godina La', value: 'Almunia De Doña Godina La' },
  { label: 'Almunia De San Juan', value: 'Almunia De San Juan' },
  { label: 'Almuniente', value: 'Almuniente' },
  { label: 'Almuradiel', value: 'Almuradiel' },
  { label: 'Almus', value: 'Almus' },
  { label: 'Almussafes', value: 'Almussafes' },
  { label: 'Almyrós', value: 'Almyrós' },
  { label: 'Alnashi', value: 'Alnashi' },
  { label: 'Alnavar', value: 'Alnavar' },
  { label: 'Alness', value: 'Alness' },
  { label: 'Alnif', value: 'Alnif' },
  { label: 'Alnwick', value: 'Alnwick' },
  { label: 'Aloândia', value: 'Aloândia' },
  { label: 'Alobras', value: 'Alobras' },
  { label: 'Alocén', value: 'Alocén' },
  { label: 'Aloguinsan', value: 'Aloguinsan' },
  { label: 'Aloha', value: 'Aloha' },
  { label: 'Aloja', value: 'Aloja' },
  { label: 'Aloleng', value: 'Aloleng' },
  { label: 'Aloleng', value: 'Aloleng' },
  { label: 'Alondra Park', value: 'Alondra Park' },
  { label: 'Along', value: 'Along' },
  { label: 'Alonsotegi', value: 'Alonsotegi' },
  { label: 'Alonte', value: 'Alonte' },
  { label: 'Alor Gajah', value: 'Alor Gajah' },
  { label: 'Alor Setar', value: 'Alor Setar' },
  { label: 'Alora', value: 'Alora' },
  { label: 'Alorton', value: 'Alorton' },
  { label: 'Alòs De Balaguer', value: 'Alòs De Balaguer' },
  { label: 'Alosno', value: 'Alosno' },
  { label: 'Alot', value: 'Alot' },
  { label: 'Alotau', value: 'Alotau' },
  { label: 'Alotenango', value: 'Alotenango' },
  { label: 'Alovera', value: 'Alovera' },
  { label: 'Alozaina', value: 'Alozaina' },
  { label: 'Alp', value: 'Alp' },
  { label: 'Alpachiri', value: 'Alpachiri' },
  { label: 'Alpaco', value: 'Alpaco' },
  { label: 'Alpandeire', value: 'Alpandeire' },
  { label: 'Alpanseque', value: 'Alpanseque' },
  { label: 'Alparea', value: 'Alparea' },
  { label: 'Alpartir', value: 'Alpartir' },
  { label: 'Alpatláhuac', value: 'Alpatláhuac' },
  { label: 'Alpatovo', value: 'Alpatovo' },
  { label: 'Alpaugh', value: 'Alpaugh' },
  { label: 'Alpbach', value: 'Alpbach' },
  { label: 'Alpedrete', value: 'Alpedrete' },
  { label: 'Alpen', value: 'Alpen' },
  { label: 'Alpena', value: 'Alpena' },
  { label: 'Alpena County', value: 'Alpena County' },
  { label: 'Alpendurada', value: 'Alpendurada' },
  { label: 'Alpeñés', value: 'Alpeñés' },
  { label: 'Alpenrod', value: 'Alpenrod' },
  { label: 'Alpens', value: 'Alpens' },
  { label: 'Alpera', value: 'Alpera' },
  { label: 'Alpercata', value: 'Alpercata' },
  { label: 'Alpes-De-Haute-Provence', value: 'Alpes-De-Haute-Provence' },
  { label: 'Alpes-Maritimes', value: 'Alpes-Maritimes' },
  { label: 'Alpestre', value: 'Alpestre' },
  { label: 'Alpette', value: 'Alpette' },
  { label: 'Alpha', value: 'Alpha' },
  { label: 'Alpharetta', value: 'Alpharetta' },
  { label: 'Alphen', value: 'Alphen' },
  { label: 'Alphen Aan Den Rijn', value: 'Alphen Aan Den Rijn' },
  { label: 'Alphington', value: 'Alphington' },
  { label: 'Alpiarça', value: 'Alpiarça' },
  { label: 'Alpicat', value: 'Alpicat' },
  { label: 'Alpignano', value: 'Alpignano' },
  { label: 'Alpine', value: 'Alpine' },
  { label: 'Alpine', value: 'Alpine' },
  { label: 'Alpine', value: 'Alpine' },
  { label: 'Alpine', value: 'Alpine' },
  { label: 'Alpine', value: 'Alpine' },
  { label: 'Alpine County', value: 'Alpine County' },
  { label: 'Alpinópolis', value: 'Alpinópolis' },
  { label: 'Alpirsbach', value: 'Alpirsbach' },
  { label: 'Alpnach', value: 'Alpnach' },
  { label: 'Alpo', value: 'Alpo' },
  { label: 'Alpoyeca', value: 'Alpoyeca' },
  { label: 'Alps', value: 'Alps' },
  { label: 'Alpu', value: 'Alpu' },
  { label: 'Alpuente', value: 'Alpuente' },
  { label: 'Alpujarra', value: 'Alpujarra' },
  { label: 'Alpujarra De La Sierra', value: 'Alpujarra De La Sierra' },
  { label: 'Alpuyeca', value: 'Alpuyeca' },
  { label: 'Alpuyecancingo De Las Montañas', value: 'Alpuyecancingo De Las Montañas' },
  { label: 'Alqueria Dasnar L', value: 'Alqueria Dasnar L' },
  { label: 'Alqueria De La Comtessa L', value: 'Alqueria De La Comtessa L' },
  { label: 'Alquerías Del Niño Perdido', value: 'Alquerías Del Niño Perdido' },
  { label: 'Alquézar', value: 'Alquézar' },
  { label: 'Alquife', value: 'Alquife' },
  { label: 'Alquízar', value: 'Alquízar' },
  { label: 'Al-Rastan District', value: 'Al-Rastan District' },
  { label: 'Alresford', value: 'Alresford' },
  { label: 'Alrewas', value: 'Alrewas' },
  { label: 'Alsager', value: 'Alsager' },
  { label: 'Al-Salamiyah District', value: 'Al-Salamiyah District' },
  { label: 'Al-Sanamayn District', value: 'Al-Sanamayn District' },
  { label: 'Alsbach-Hähnlein', value: 'Alsbach-Hähnlein' },
  { label: 'Alsdorf', value: 'Alsdorf' },
  { label: 'Alsdorf', value: 'Alsdorf' },
  { label: 'Alseno', value: 'Alseno' },
  { label: 'Alsenz', value: 'Alsenz' },
  { label: 'Alserio', value: 'Alserio' },
  { label: 'Alsfeld', value: 'Alsfeld' },
  { label: 'Alsheim', value: 'Alsheim' },
  { label: 'Alsike', value: 'Alsike' },
  { label: 'Alsip', value: 'Alsip' },
  { label: 'Alsleben', value: 'Alsleben' },
  { label: 'Alsodux', value: 'Alsodux' },
  { label: 'Alsónémedi', value: 'Alsónémedi' },
  { label: 'Alsózsolca', value: 'Alsózsolca' },
  { label: 'Alstahaug', value: 'Alstahaug' },
  { label: 'Alstead', value: 'Alstead' },
  { label: 'Alsterdorf', value: 'Alsterdorf' },
  { label: 'Alsting', value: 'Alsting' },
  { label: 'Alston', value: 'Alston' },
  { label: 'Alston', value: 'Alston' },
  { label: 'Alstonville', value: 'Alstonville' },
  { label: 'Alsunga', value: 'Alsunga' },
  { label: 'Alt Àneu', value: 'Alt Àneu' },
  { label: 'Alt Duvenstedt', value: 'Alt Duvenstedt' },
  { label: 'Alt Meteln', value: 'Alt Meteln' },
  { label: 'Alt Tucheband', value: 'Alt Tucheband' },
  { label: 'Alt Wallmoden', value: 'Alt Wallmoden' },
  { label: 'Alta', value: 'Alta' },
  { label: 'Alta', value: 'Alta' },
  { label: 'Älta', value: 'Älta' },
  { label: 'Alta Floresta', value: 'Alta Floresta' },
  { label: 'Alta Floresta Doeste', value: 'Alta Floresta Doeste' },
  { label: 'Alta Gracia', value: 'Alta Gracia' },
  { label: 'Alta Italia', value: 'Alta Italia' },
  { label: 'Alta Sierra', value: 'Alta Sierra' },
  { label: 'Altable', value: 'Altable' },
  { label: 'Altach', value: 'Altach' },
  { label: 'Altadena', value: 'Altadena' },
  { label: 'Altafulla', value: 'Altafulla' },
  { label: 'Altagracia', value: 'Altagracia' },
  { label: 'Altagracia De Orituco', value: 'Altagracia De Orituco' },
  { label: 'Altai', value: 'Altai' },
  { label: 'Altair', value: 'Altair' },
  { label: 'Altamira', value: 'Altamira' },
  { label: 'Altamira', value: 'Altamira' },
  { label: 'Altamira', value: 'Altamira' },
  { label: 'Altamira', value: 'Altamira' },
  { label: 'Altamira', value: 'Altamira' },
  { label: 'Altamira', value: 'Altamira' },
  { label: 'Altamira De Zináparo', value: 'Altamira De Zináparo' },
  { label: 'Altamira Do Maranhão', value: 'Altamira Do Maranhão' },
  { label: 'Altamira Do Paraná', value: 'Altamira Do Paraná' },
  { label: 'Altamirano', value: 'Altamirano' },
  { label: 'Altamont', value: 'Altamont' },
  { label: 'Altamont', value: 'Altamont' },
  { label: 'Altamont', value: 'Altamont' },
  { label: 'Altamont', value: 'Altamont' },
  { label: 'Altamont', value: 'Altamont' },
  { label: 'Altamonte Springs', value: 'Altamonte Springs' },
  { label: 'Altamura', value: 'Altamura' },
  { label: 'Alțâna', value: 'Alțâna' },
  { label: 'Altaneira', value: 'Altaneira' },
  { label: 'Altãnia', value: 'Altãnia' },
  { label: 'Altare', value: 'Altare' },
  { label: 'Altarejos', value: 'Altarejos' },
  { label: 'Altata', value: 'Altata' },
  { label: 'Altaussee', value: 'Altaussee' },
  { label: 'Altavas', value: 'Altavas' },
  { label: 'Altavilla Irpina', value: 'Altavilla Irpina' },
  { label: 'Altavilla Milicia', value: 'Altavilla Milicia' },
  { label: 'Altavilla Monferrato', value: 'Altavilla Monferrato' },
  { label: 'Altavilla Silentina', value: 'Altavilla Silentina' },
  { label: 'Altavilla Vicentina', value: 'Altavilla Vicentina' },
  { label: 'Altavista', value: 'Altavista' },
  { label: 'Altavista De Ramos', value: 'Altavista De Ramos' },
  { label: 'Altay', value: 'Altay' },
  { label: 'Altay', value: 'Altay' },
  { label: 'Altay Diqu', value: 'Altay Diqu' },
  { label: 'Altayskiy', value: 'Altayskiy' },
  { label: 'Altayskiy Rayon', value: 'Altayskiy Rayon' },
  { label: 'Altayskoye', value: 'Altayskoye' },
  { label: 'Altbach', value: 'Altbach' },
  { label: 'Altdöbern', value: 'Altdöbern' },
  { label: 'Altdorf', value: 'Altdorf' },
  { label: 'Altdorf', value: 'Altdorf' },
  { label: 'Altdorf', value: 'Altdorf' },
  { label: 'Altea', value: 'Altea' },
  { label: 'Altedo', value: 'Altedo' },
  { label: 'Altefähr', value: 'Altefähr' },
  { label: 'Alteglofsheim', value: 'Alteglofsheim' },
  { label: 'Alten Buseck', value: 'Alten Buseck' },
  { label: 'Altena', value: 'Altena' },
  { label: 'Altenahr', value: 'Altenahr' },
  { label: 'Altenau', value: 'Altenau' },
  { label: 'Altenbeken', value: 'Altenbeken' },
  { label: 'Altenberg', value: 'Altenberg' },
  { label: 'Altenberg Bei Linz', value: 'Altenberg Bei Linz' },
  { label: 'Altenberge', value: 'Altenberge' },
  { label: 'Altenbuch', value: 'Altenbuch' },
  { label: 'Altenbüren', value: 'Altenbüren' },
  { label: 'Altenburg', value: 'Altenburg' },
  { label: 'Altenburg', value: 'Altenburg' },
  { label: 'Altendiez', value: 'Altendiez' },
  { label: 'Altendorf', value: 'Altendorf' },
  { label: 'Altendorf', value: 'Altendorf' },
  { label: 'Altendorf', value: 'Altendorf' },
  { label: 'Altenfeld', value: 'Altenfeld' },
  { label: 'Altenfelden', value: 'Altenfelden' },
  { label: 'Altenglan', value: 'Altenglan' },
  { label: 'Altengottern', value: 'Altengottern' },
  { label: 'Altenholz', value: 'Altenholz' },
  { label: 'Altenkirchen', value: 'Altenkirchen' },
  { label: 'Altenkirchen', value: 'Altenkirchen' },
  { label: 'Altenkrempe', value: 'Altenkrempe' },
  { label: 'Altenkunstadt', value: 'Altenkunstadt' },
  { label: 'Altenmarkt', value: 'Altenmarkt' },
  { label: 'Altenmarkt An Der Triesting', value: 'Altenmarkt An Der Triesting' },
  { label: 'Altenmarkt Bei Fürstenfeld', value: 'Altenmarkt Bei Fürstenfeld' },
  { label: 'Altenmarkt Bei Sankt Gallen', value: 'Altenmarkt Bei Sankt Gallen' },
  { label: 'Altenmarkt Im Pongau', value: 'Altenmarkt Im Pongau' },
  { label: 'Altenmedingen', value: 'Altenmedingen' },
  { label: 'Altenmünster', value: 'Altenmünster' },
  { label: 'Altenpleen', value: 'Altenpleen' },
  { label: 'Altenriet', value: 'Altenriet' },
  { label: 'Altenstadt', value: 'Altenstadt' },
  { label: 'Altenstadt', value: 'Altenstadt' },
  { label: 'Altensteig', value: 'Altensteig' },
  { label: 'Altenthann', value: 'Altenthann' },
  { label: 'Altentreptow', value: 'Altentreptow' },
  { label: 'Altepexi', value: 'Altepexi' },
  { label: 'Alter Do Chão', value: 'Alter Do Chão' },
  { label: 'Alterhofen', value: 'Alterhofen' },
  { label: 'Alterosa', value: 'Alterosa' },
  { label: 'Alterswil', value: 'Alterswil' },
  { label: 'Altes Lager', value: 'Altes Lager' },
  { label: 'Altfraunhofen', value: 'Altfraunhofen' },
  { label: 'Altglienicke', value: 'Altglienicke' },
  { label: 'Al-Thawrah District', value: 'Al-Thawrah District' },
  { label: 'Althegnenberg', value: 'Althegnenberg' },
  { label: 'Altheim', value: 'Altheim' },
  { label: 'Althen-Des-Paluds', value: 'Althen-Des-Paluds' },
  { label: 'Althengstett', value: 'Althengstett' },
  { label: 'Althofen', value: 'Althofen' },
  { label: 'Alt-Hohenschönhausen', value: 'Alt-Hohenschönhausen' },
  { label: 'Althorne', value: 'Althorne' },
  { label: 'Althütte', value: 'Althütte' },
  { label: 'Altıağac', value: 'Altıağac' },
  { label: 'Altidona', value: 'Altidona' },
  { label: 'Altıeylül', value: 'Altıeylül' },
  { label: 'Altilia', value: 'Altilia' },
  { label: 'Altındağ', value: 'Altındağ' },
  { label: 'Altınekin', value: 'Altınekin' },
  { label: 'Altinho', value: 'Altinho' },
  { label: 'Altino', value: 'Altino' },
  { label: 'Altinópolis', value: 'Altinópolis' },
  { label: 'Altınordu', value: 'Altınordu' },
  { label: 'Altınova', value: 'Altınova' },
  { label: 'Altınözü', value: 'Altınözü' },
  { label: 'Altıntaş', value: 'Altıntaş' },
  { label: 'Altınyayla', value: 'Altınyayla' },
  { label: 'Altınyayla', value: 'Altınyayla' },
  { label: 'Altishofen', value: 'Altishofen' },
  { label: 'Altissimo', value: 'Altissimo' },
  { label: 'Altivole', value: 'Altivole' },
  { label: 'Altkirch', value: 'Altkirch' },
  { label: 'Altkirchen', value: 'Altkirchen' },
  { label: 'Altlandsberg', value: 'Altlandsberg' },
  { label: 'Altleiningen', value: 'Altleiningen' },
  { label: 'Altlengbach', value: 'Altlengbach' },
  { label: 'Altlichtenberg', value: 'Altlichtenberg' },
  { label: 'Altlichtenwarth', value: 'Altlichtenwarth' },
  { label: 'Altlußheim', value: 'Altlußheim' },
  { label: 'Altmelon', value: 'Altmelon' },
  { label: 'Altmittweida', value: 'Altmittweida' },
  { label: 'Altmünster', value: 'Altmünster' },
  { label: 'Altnau', value: 'Altnau' },
  { label: 'Alto', value: 'Alto' },
  { label: 'Alto', value: 'Alto' },
  { label: 'Alto', value: 'Alto' },
  { label: 'Alto', value: 'Alto' },
  { label: 'Alto Alegre', value: 'Alto Alegre' },
  { label: 'Alto Alegre', value: 'Alto Alegre' },
  { label: 'Alto Alegre Do Maranhão', value: 'Alto Alegre Do Maranhão' },
  { label: 'Alto Alegre Do Pindaré', value: 'Alto Alegre Do Pindaré' },
  { label: 'Alto Alegre Dos Parecis', value: 'Alto Alegre Dos Parecis' },
  { label: 'Alto Araguaia', value: 'Alto Araguaia' },
  { label: 'Alto Barinas', value: 'Alto Barinas' },
  { label: 'Alto Baudó', value: 'Alto Baudó' },
  { label: 'Alto Bela Vista', value: 'Alto Bela Vista' },
  { label: 'Alto Biobío', value: 'Alto Biobío' },
  { label: 'Alto Boa Vista', value: 'Alto Boa Vista' },
  { label: 'Alto Boquete', value: 'Alto Boquete' },
  { label: 'Alto Caparaó', value: 'Alto Caparaó' },
  { label: 'Alto De Jesús', value: 'Alto De Jesús' },
  { label: 'Alto De La Estancia', value: 'Alto De La Estancia' },
  { label: 'Alto Del Carmen', value: 'Alto Del Carmen' },
  { label: 'Alto Del Espino', value: 'Alto Del Espino' },
  { label: 'Alto Do Pina', value: 'Alto Do Pina' },
  { label: 'Alto Do Rodrigues', value: 'Alto Do Rodrigues' },
  { label: 'Alto Feliz', value: 'Alto Feliz' },
  { label: 'Alto Garças', value: 'Alto Garças' },
  { label: 'Alto Horizonte', value: 'Alto Horizonte' },
  { label: 'Alto Hospicio', value: 'Alto Hospicio' },
  { label: 'Alto Jequitibá', value: 'Alto Jequitibá' },
  { label: 'Alto Longá', value: 'Alto Longá' },
  { label: 'Alto Lucero', value: 'Alto Lucero' },
  { label: 'Alto Molócuè', value: 'Alto Molócuè' },
  { label: 'Alto Paraguai', value: 'Alto Paraguai' },
  { label: 'Alto Paraíso', value: 'Alto Paraíso' },
  { label: 'Alto Paraíso', value: 'Alto Paraíso' },
  { label: 'Alto Paraíso De Goiás', value: 'Alto Paraíso De Goiás' },
  { label: 'Alto Paraná', value: 'Alto Paraná' },
  { label: 'Alto Parnaíba', value: 'Alto Parnaíba' },
  { label: 'Alto Piquiri', value: 'Alto Piquiri' },
  { label: 'Alto Rio Doce', value: 'Alto Rio Doce' },
  { label: 'Alto Rio Novo', value: 'Alto Rio Novo' },
  { label: 'Alto Río Senguer', value: 'Alto Río Senguer' },
  { label: 'Alto Santo', value: 'Alto Santo' },
  { label: 'Alto Taquari', value: 'Alto Taquari' },
  { label: 'Altofonte', value: 'Altofonte' },
  { label: 'Altomonte', value: 'Altomonte' },
  { label: 'Altomünster', value: 'Altomünster' },
  { label: 'Alton', value: 'Alton' },
  { label: 'Alton', value: 'Alton' },
  { label: 'Alton', value: 'Alton' },
  { label: 'Alton', value: 'Alton' },
  { label: 'Alton', value: 'Alton' },
  { label: 'Alton Downs', value: 'Alton Downs' },
  { label: 'Alton North (Historical)', value: 'Alton North (Historical)' },
  { label: 'Altona', value: 'Altona' },
  { label: 'Altona', value: 'Altona' },
  { label: 'Altona', value: 'Altona' },
  { label: 'Altona Meadows', value: 'Altona Meadows' },
  { label: 'Altona North', value: 'Altona North' },
  { label: 'Altônia', value: 'Altônia' },
  { label: 'Altoona', value: 'Altoona' },
  { label: 'Altoona', value: 'Altoona' },
  { label: 'Altoona', value: 'Altoona' },
  { label: 'Altopascio', value: 'Altopascio' },
  { label: 'Altorf', value: 'Altorf' },
  { label: 'Altorricón', value: 'Altorricón' },
  { label: 'Altos', value: 'Altos' },
  { label: 'Altos', value: 'Altos' },
  { label: 'Altos De Chipión', value: 'Altos De Chipión' },
  { label: 'Altos De San Francisco', value: 'Altos De San Francisco' },
  { label: 'Altos Del Rosario', value: 'Altos Del Rosario' },
  { label: 'Altos Los', value: 'Altos Los' },
  { label: 'Altotonga', value: 'Altotonga' },
  { label: 'Altötting', value: 'Altötting' },
  { label: 'Altrich', value: 'Altrich' },
  { label: 'Altrincham', value: 'Altrincham' },
  { label: 'Altrip', value: 'Altrip' },
  { label: 'Alt-Sanitz', value: 'Alt-Sanitz' },
  { label: 'Altsasu', value: 'Altsasu' },
  { label: 'Altsasu/Alsasua', value: 'Altsasu/Alsasua' },
  { label: 'Altshausen', value: 'Altshausen' },
  { label: 'Altstadt', value: 'Altstadt' },
  { label: 'Altstadt Nord', value: 'Altstadt Nord' },
  { label: 'Altstadt Sud', value: 'Altstadt Sud' },
  { label: 'Altstätten', value: 'Altstätten' },
  { label: 'Alt-Treptow', value: 'Alt-Treptow' },
  { label: 'Altud', value: 'Altud' },
  { label: 'Altuf’Yevskiy', value: 'Altuf’Yevskiy' },
  { label: 'Altukhovo', value: 'Altukhovo' },
  { label: 'Altunhisar', value: 'Altunhisar' },
  { label: 'Altura', value: 'Altura' },
  { label: 'Altura', value: 'Altura' },
  { label: 'Alturas', value: 'Alturas' },
  { label: 'Alturas', value: 'Alturas' },
  { label: 'Altus', value: 'Altus' },
  { label: 'Altus Bosques', value: 'Altus Bosques' },
  { label: 'Altusried', value: 'Altusried' },
  { label: 'Altzaga', value: 'Altzaga' },
  { label: 'Altzayanca', value: 'Altzayanca' },
  { label: 'Altzo', value: 'Altzo' },
  { label: 'Alua', value: 'Alua' },
  { label: 'Alua', value: 'Alua' },
  { label: 'Al-Ubaidi', value: 'Al-Ubaidi' },
  { label: 'Alubarén', value: 'Alubarén' },
  { label: 'Alubijid', value: 'Alubijid' },
  { label: 'Alubijid', value: 'Alubijid' },
  { label: 'Alubijid', value: 'Alubijid' },
  { label: 'Alucra', value: 'Alucra' },
  { label: 'Alugan', value: 'Alugan' },
  { label: 'Alugan', value: 'Alugan' },
  { label: 'Alūksne', value: 'Alūksne' },
  { label: 'Al-Ula', value: 'Al-Ula' },
  { label: 'Alum Creek', value: 'Alum Creek' },
  { label: 'Alum Rock', value: 'Alum Rock' },
  { label: 'Aluminé', value: 'Aluminé' },
  { label: 'Alumínio', value: 'Alumínio' },
  { label: 'Alunda', value: 'Alunda' },
  { label: 'Aluni', value: 'Aluni' },
  { label: 'Aluniş', value: 'Aluniş' },
  { label: 'Aluniş', value: 'Aluniş' },
  { label: 'Aluniș', value: 'Aluniș' },
  { label: 'Alunișu', value: 'Alunișu' },
  { label: 'Alunișu', value: 'Alunișu' },
  { label: 'Alunu', value: 'Alunu' },
  { label: 'Alupay', value: 'Alupay' },
  { label: 'Alupka', value: 'Alupka' },
  { label: 'Alur', value: 'Alur' },
  { label: 'Alushta', value: 'Alushta' },
  { label: 'Alustante', value: 'Alustante' },
  { label: 'Aluva', value: 'Aluva' },
  { label: 'Alva', value: 'Alva' },
  { label: 'Alva', value: 'Alva' },
  { label: 'Alva', value: 'Alva' },
  { label: 'Alva', value: 'Alva' },
  { label: 'Alvaiázere', value: 'Alvaiázere' },
  { label: 'Alvalade', value: 'Alvalade' },
  { label: 'Alvalade', value: 'Alvalade' },
  { label: 'Alvan', value: 'Alvan' },
  { label: 'Alvand', value: 'Alvand' },
  { label: 'Alvand', value: 'Alvand' },
  { label: 'Älvängen', value: 'Älvängen' },
  { label: 'Alvarado', value: 'Alvarado' },
  { label: 'Alvarado', value: 'Alvarado' },
  { label: 'Alvarado', value: 'Alvarado' },
  { label: 'Alvarado', value: 'Alvarado' },
  { label: 'Alvarães', value: 'Alvarães' },
  { label: 'Alvarenga', value: 'Alvarenga' },
  { label: 'Álvares Florence', value: 'Álvares Florence' },
  { label: 'Álvares Machado', value: 'Álvares Machado' },
  { label: 'Álvaro De Carvalho', value: 'Álvaro De Carvalho' },
  { label: 'Álvaro Obregón', value: 'Álvaro Obregón' },
  { label: 'Álvaro Obregón', value: 'Álvaro Obregón' },
  { label: 'Álvaro Obregón', value: 'Álvaro Obregón' },
  { label: 'Álvaro Obregón', value: 'Álvaro Obregón' },
  { label: 'Álvaro Obregón', value: 'Álvaro Obregón' },
  { label: 'Álvaro Obregón', value: 'Álvaro Obregón' },
  { label: 'Álvaro Obregón', value: 'Álvaro Obregón' },
  { label: 'Álvaro Obregón', value: 'Álvaro Obregón' },
  { label: 'Álvaro Obregón (Santa Cruz)', value: 'Álvaro Obregón (Santa Cruz)' },
  { label: 'Alvdal', value: 'Alvdal' },
  { label: 'Älvdalen', value: 'Älvdalen' },
  { label: 'Alvear', value: 'Alvear' },
  { label: 'Alvechurch', value: 'Alvechurch' },
  { label: 'Alveley', value: 'Alveley' },
  { label: 'Alvendre', value: 'Alvendre' },
  { label: 'Alverca Da Beira', value: 'Alverca Da Beira' },
  { label: 'Alverca Do Ribatejo', value: 'Alverca Do Ribatejo' },
  { label: 'Alveringem', value: 'Alveringem' },
  { label: 'Alverna', value: 'Alverna' },
  { label: 'Alveslohe', value: 'Alveslohe' },
  { label: 'Alvesta', value: 'Alvesta' },
  { label: 'Alveston', value: 'Alveston' },
  { label: 'Alviano', value: 'Alviano' },
  { label: 'Alvignano', value: 'Alvignano' },
  { label: 'Alvin', value: 'Alvin' },
  { label: 'Alvinlândia', value: 'Alvinlândia' },
  { label: 'Alvinópolis', value: 'Alvinópolis' },
  { label: 'Alvito', value: 'Alvito' },
  { label: 'Alvito', value: 'Alvito' },
  { label: 'Älvkarleby', value: 'Älvkarleby' },
  { label: 'Alvoco Da Serra', value: 'Alvoco Da Serra' },
  { label: 'Alvor', value: 'Alvor' },
  { label: 'Alvorada', value: 'Alvorada' },
  { label: 'Alvorada', value: 'Alvorada' },
  { label: 'Alvorada De Minas', value: 'Alvorada De Minas' },
  { label: 'Alvorada Do Gurguéia', value: 'Alvorada Do Gurguéia' },
  { label: 'Alvorada Do Norte', value: 'Alvorada Do Norte' },
  { label: 'Alvorada Do Sul', value: 'Alvorada Do Sul' },
  { label: 'Alvorada Doeste', value: 'Alvorada Doeste' },
  { label: 'Alvord', value: 'Alvord' },
  { label: 'Alvorninha', value: 'Alvorninha' },
  { label: 'Älvsbyn', value: 'Älvsbyn' },
  { label: 'Alwa Tirunagari', value: 'Alwa Tirunagari' },
  { label: 'Al-Wahda', value: 'Al-Wahda' },
  { label: 'Alwar', value: 'Alwar' },
  { label: 'Alwaye', value: 'Alwaye' },
  { label: 'Alwernia', value: 'Alwernia' },
  { label: 'Alya', value: 'Alya' },
  { label: 'Alyangula', value: 'Alyangula' },
  { label: 'Alyth', value: 'Alyth' },
  { label: 'Alytus', value: 'Alytus' },
  { label: 'Al-Zabadani District', value: 'Al-Zabadani District' },
  { label: 'Alzamay', value: 'Alzamay' },
  { label: 'Alzano Lombardo', value: 'Alzano Lombardo' },
  { label: 'Alzano Scrivia', value: 'Alzano Scrivia' },
  { label: 'Alzate Brianza', value: 'Alzate Brianza' },
  { label: 'Alzenau In Unterfranken', value: 'Alzenau In Unterfranken' },
  { label: 'Alzey', value: 'Alzey' },
  { label: 'Alzingen', value: 'Alzingen' },
  { label: 'Alzira', value: 'Alzira' },
  { label: 'Alzonne', value: 'Alzonne' },
  { label: 'Am Timan', value: 'Am Timan' },
  { label: 'Ama', value: 'Ama' },
  { label: 'Amacalan', value: 'Amacalan' },
  { label: 'Amacalan', value: 'Amacalan' },
  { label: 'Amacuautitlán', value: 'Amacuautitlán' },
  { label: 'Amacueca Municipality', value: 'Amacueca Municipality' },
  { label: 'Amacuitlapilco', value: 'Amacuitlapilco' },
  { label: 'Amacuzac', value: 'Amacuzac' },
  { label: 'Amadeo', value: 'Amadeo' },
  { label: 'Amado Gómez', value: 'Amado Gómez' },
  { label: 'Amado Nervo', value: 'Amado Nervo' },
  { label: 'Amador County', value: 'Amador County' },
  { label: 'Amadora', value: 'Amadora' },
  { label: 'Amadora', value: 'Amadora' },
  { label: 'Amaga', value: 'Amaga' },
  { label: 'Amaga', value: 'Amaga' },
  { label: 'Amagá', value: 'Amagá' },
  { label: 'Amagansett', value: 'Amagansett' },
  { label: 'Amagasaki Shi', value: 'Amagasaki Shi' },
  { label: 'Amagbagan', value: 'Amagbagan' },
  { label: 'Amagbagan', value: 'Amagbagan' },
  { label: 'Amagi', value: 'Amagi' },
  { label: 'Ama-Gun', value: 'Ama-Gun' },
  { label: 'Amagunze', value: 'Amagunze' },
  { label: 'Amahai', value: 'Amahai' },
  { label: 'Amaigbo', value: 'Amaigbo' },
  { label: 'Amajaquillo', value: 'Amajaquillo' },
  { label: 'Amajari', value: 'Amajari' },
  { label: 'Amajuba District Municipality', value: 'Amajuba District Municipality' },
  { label: 'Amakusa Gun', value: 'Amakusa Gun' },
  { label: 'Amakusa Shi', value: 'Amakusa Shi' },
  { label: 'Åmål', value: 'Åmål' },
  { label: 'Amalapuram', value: 'Amalapuram' },
  { label: 'Amalfi', value: 'Amalfi' },
  { label: 'Amalfi', value: 'Amalfi' },
  { label: 'Amaliáda', value: 'Amaliáda' },
  { label: 'Amalner', value: 'Amalner' },
  { label: 'Amambai', value: 'Amambai' },
  { label: 'Amami', value: 'Amami' },
  { label: 'Amami Shi', value: 'Amami Shi' },
  { label: 'Aman Garh', value: 'Aman Garh' },
  { label: 'Amanalco De Becerra', value: 'Amanalco De Becerra' },
  { label: 'Amancio', value: 'Amancio' },
  { label: 'Amancy', value: 'Amancy' },
  { label: 'Amandola', value: 'Amandola' },
  { label: 'Amanganj', value: 'Amanganj' },
  { label: 'Amanlis', value: 'Amanlis' },
  { label: 'Amanpur', value: 'Amanpur' },
  { label: 'Amantea', value: 'Amantea' },
  { label: 'Amanvillers', value: 'Amanvillers' },
  { label: 'Amapa', value: 'Amapa' },
  { label: 'Amapá', value: 'Amapá' },
  { label: 'Amapá Do Maranhão', value: 'Amapá Do Maranhão' },
  { label: 'Amapala', value: 'Amapala' },
  { label: 'Amaporã', value: 'Amaporã' },
  { label: 'Amara', value: 'Amara' },
  { label: 'Amara', value: 'Amara' },
  { label: 'Amaraji', value: 'Amaraji' },
  { label: 'Amaral Ferrador', value: 'Amaral Ferrador' },
  { label: 'Amaralina', value: 'Amaralina' },
  { label: 'Amarante', value: 'Amarante' },
  { label: 'Amarante', value: 'Amarante' },
  { label: 'Amarante Do Maranhão', value: 'Amarante Do Maranhão' },
  { label: 'Amărăşti', value: 'Amărăşti' },
  { label: 'Amărăştii De Jos', value: 'Amărăştii De Jos' },
  { label: 'Amărăştii De Sus', value: 'Amărăştii De Sus' },
  { label: 'Amares', value: 'Amares' },
  { label: 'Amarete', value: 'Amarete' },
  { label: 'Amargosa', value: 'Amargosa' },
  { label: 'Amarillo', value: 'Amarillo' },
  { label: 'Amarkantak', value: 'Amarkantak' },
  { label: 'Amarnath', value: 'Amarnath' },
  { label: 'Amaro', value: 'Amaro' },
  { label: 'Amaroni', value: 'Amaroni' },
  { label: 'Amaroo', value: 'Amaroo' },
  { label: 'Amarpatan', value: 'Amarpatan' },
  { label: 'Amarpur', value: 'Amarpur' },
  { label: 'Amarpur', value: 'Amarpur' },
  { label: 'Amaru', value: 'Amaru' },
  { label: 'Amarwara', value: 'Amarwara' },
  { label: 'Amárynthos', value: 'Amárynthos' },
  { label: 'Amas', value: 'Amas' },
  { label: 'Amas', value: 'Amas' },
  { label: 'Amaseno', value: 'Amaseno' },
  { label: 'Amasia', value: 'Amasia' },
  { label: 'Amasra', value: 'Amasra' },
  { label: 'Amassoma', value: 'Amassoma' },
  { label: 'Amatán', value: 'Amatán' },
  { label: 'Amatanejo', value: 'Amatanejo' },
  { label: 'Amatenango De La Frontera', value: 'Amatenango De La Frontera' },
  { label: 'Amatenango Del Valle', value: 'Amatenango Del Valle' },
  { label: 'Amatepec', value: 'Amatepec' },
  { label: 'Amathole District Municipality', value: 'Amathole District Municipality' },
  { label: 'Amatillo', value: 'Amatillo' },
  { label: 'Amatitán', value: 'Amatitán' },
  { label: 'Amatitlán', value: 'Amatitlán' },
  { label: 'Amatitlán', value: 'Amatitlán' },
  { label: 'Amatitlán De Azueta', value: 'Amatitlán De Azueta' },
  { label: 'Amatlán', value: 'Amatlán' },
  { label: 'Amatlán De Cañas', value: 'Amatlán De Cañas' },
  { label: 'Amatlán De Los Reyes', value: 'Amatlán De Los Reyes' },
  { label: 'Amatlán De Quetzalcoatl', value: 'Amatlán De Quetzalcoatl' },
  { label: 'Amato', value: 'Amato' },
  { label: 'Amatrice', value: 'Amatrice' },
  { label: 'Amaturá', value: 'Amaturá' },
  { label: 'Amauli', value: 'Amauli' },
  { label: 'Amaury', value: 'Amaury' },
  { label: 'Amavida', value: 'Amavida' },
  { label: 'Amaxac De Guerrero', value: 'Amaxac De Guerrero' },
  { label: 'Amay', value: 'Amay' },
  { label: 'Amayuca', value: 'Amayuca' },
  { label: 'Amayuelas De Arriba', value: 'Amayuelas De Arriba' },
  { label: 'Amazar', value: 'Amazar' },
  { label: 'Amazcala', value: 'Amazcala' },
  { label: 'Ambad', value: 'Ambad' },
  { label: 'Ambagarh Chauki', value: 'Ambagarh Chauki' },
  { label: 'Ambaguio', value: 'Ambaguio' },
  { label: 'Ambah', value: 'Ambah' },
  { label: 'Ambahta', value: 'Ambahta' },
  { label: 'Ambajogai', value: 'Ambajogai' },
  { label: 'Ambala', value: 'Ambala' },
  { label: 'Ambalangoda', value: 'Ambalangoda' },
  { label: 'Ambalema', value: 'Ambalema' },
  { label: 'Ambam', value: 'Ambam' },
  { label: 'Ambarawa', value: 'Ambarawa' },
  { label: 'Ambarès-Et-Lagrave', value: 'Ambarès-Et-Lagrave' },
  { label: 'Ambarita', value: 'Ambarita' },
  { label: 'Ambarvale', value: 'Ambarvale' },
  { label: 'Ambasa', value: 'Ambasa' },
  { label: 'Ambasamudram', value: 'Ambasamudram' },
  { label: 'Ambatenna', value: 'Ambatenna' },
  { label: 'Ambato', value: 'Ambato' },
  { label: 'Ambattur', value: 'Ambattur' },
  { label: 'Ambazac', value: 'Ambazac' },
  { label: 'Ambedkar Nagar', value: 'Ambedkar Nagar' },
  { label: 'Ambegaon', value: 'Ambegaon' },
  { label: 'Ambel', value: 'Ambel' },
  { label: 'Ambelókipoi', value: 'Ambelókipoi' },
  { label: 'Amberg', value: 'Amberg' },
  { label: 'Ambérieu-En-Bugey', value: 'Ambérieu-En-Bugey' },
  { label: 'Ambérieux-En-Dombes', value: 'Ambérieux-En-Dombes' },
  { label: 'Amberley', value: 'Amberley' },
  { label: 'Amberley', value: 'Amberley' },
  { label: 'Ambernath', value: 'Ambernath' },
  { label: 'Ambert', value: 'Ambert' },
  { label: 'Ambès', value: 'Ambès' },
  { label: 'Ambía', value: 'Ambía' },
  { label: 'Ambierle', value: 'Ambierle' },
  { label: 'Ambikapur', value: 'Ambikapur' },
  { label: 'Ambillou', value: 'Ambillou' },
  { label: 'Ambite', value: 'Ambite' },
  { label: 'Ambivere', value: 'Ambivere' },
  { label: 'Amblainville', value: 'Amblainville' },
  { label: 'Amblar', value: 'Amblar' },
  { label: 'Amble', value: 'Amble' },
  { label: 'Ambleny', value: 'Ambleny' },
  { label: 'Ambler', value: 'Ambler' },
  { label: 'Ambleside', value: 'Ambleside' },
  { label: 'Ambleteuse', value: 'Ambleteuse' },
  { label: 'Amblève', value: 'Amblève' },
  { label: 'Ambo', value: 'Ambo' },
  { label: 'Ambo Village', value: 'Ambo Village' },
  { label: 'Amboise', value: 'Amboise' },
  { label: 'Ambon', value: 'Ambon' },
  { label: 'Ambon', value: 'Ambon' },
  { label: 'Amboy', value: 'Amboy' },
  { label: 'Amboy', value: 'Amboy' },
  { label: 'Ambra', value: 'Ambra' },
  { label: 'Ambridge', value: 'Ambridge' },
  { label: 'Ambrières-Les-Vallées', value: 'Ambrières-Les-Vallées' },
  { label: 'Ambriz', value: 'Ambriz' },
  { label: 'Ambrolauri', value: 'Ambrolauri' },
  { label: 'Ambrolauris Munitsip’Alit’Et’I', value: 'Ambrolauris Munitsip’Alit’Et’I' },
  { label: 'Ambronay', value: 'Ambronay' },
  { label: 'Ambrosden', value: 'Ambrosden' },
  { label: 'Ambrosio', value: 'Ambrosio' },
  { label: 'Ambuclao', value: 'Ambuclao' },
  { label: 'Ambuclao', value: 'Ambuclao' },
  { label: 'Ambulong', value: 'Ambulong' },
  { label: 'Ambulong', value: 'Ambulong' },
  { label: 'Ambur', value: 'Ambur' },
  { label: 'Amby', value: 'Amby' },
  { label: 'Amd', value: 'Amd' },
  { label: 'Amden', value: 'Amden' },
  { label: 'Amdos', value: 'Amdos' },
  { label: 'Amdoun', value: 'Amdoun' },
  { label: 'Amealco', value: 'Amealco' },
  { label: 'Ameca', value: 'Ameca' },
  { label: 'Ameca Municipality', value: 'Ameca Municipality' },
  { label: 'Amecameca', value: 'Amecameca' },
  { label: 'Ameche', value: 'Ameche' },
  { label: 'Ameglia', value: 'Ameglia' },
  { label: 'Ameixoeira', value: 'Ameixoeira' },
  { label: 'Amelia', value: 'Amelia' },
  { label: 'Amelia', value: 'Amelia' },
  { label: 'Amelia', value: 'Amelia' },
  { label: 'Amelia County', value: 'Amelia County' },
  { label: 'Amelia Court House', value: 'Amelia Court House' },
  { label: 'Amélia Rodrigues', value: 'Amélia Rodrigues' },
  { label: 'Amelinghausen', value: 'Amelinghausen' },
  { label: 'Ameluca', value: 'Ameluca' },
  { label: 'Amendingen', value: 'Amendingen' },
  { label: 'Amendolara', value: 'Amendolara' },
  { label: 'Ameno', value: 'Ameno' },
  { label: 'Amer', value: 'Amer' },
  { label: 'Amerang', value: 'Amerang' },
  { label: 'Ameria', value: 'Ameria' },
  { label: 'America', value: 'America' },
  { label: 'América Dourada', value: 'América Dourada' },
  { label: 'América Libre', value: 'América Libre' },
  { label: 'American Canyon', value: 'American Canyon' },
  { label: 'American Falls', value: 'American Falls' },
  { label: 'American Fork', value: 'American Fork' },
  { label: 'Americana', value: 'Americana' },
  { label: 'Americano Do Brasil', value: 'Americano Do Brasil' },
  { label: 'Américo Brasiliense', value: 'Américo Brasiliense' },
  { label: 'Américo De Campos', value: 'Américo De Campos' },
  { label: 'Americus', value: 'Americus' },
  { label: 'Amerongen', value: 'Amerongen' },
  { label: 'Amersfoort', value: 'Amersfoort' },
  { label: 'Amersham', value: 'Amersham' },
  { label: 'Amersham On The Hill', value: 'Amersham On The Hill' },
  { label: 'Amery', value: 'Amery' },
  { label: 'Ames', value: 'Ames' },
  { label: 'Ames', value: 'Ames' },
  { label: 'Amés', value: 'Amés' },
  { label: 'Ames Lake', value: 'Ames Lake' },
  { label: 'Amesbury', value: 'Amesbury' },
  { label: 'Amesbury', value: 'Amesbury' },
  { label: 'Améscoa Baja', value: 'Améscoa Baja' },
  { label: 'Amesti', value: 'Amesti' },
  { label: 'Amet', value: 'Amet' },
  { label: 'Amethi', value: 'Amethi' },
  { label: 'Ametista Do Sul', value: 'Ametista Do Sul' },
  { label: 'Ametlla De Mar', value: 'Ametlla De Mar' },
  { label: 'Ametlla Del Vallès L', value: 'Ametlla Del Vallès L' },
  { label: 'Ameyugo', value: 'Ameyugo' },
  { label: 'Amezketa', value: 'Amezketa' },
  { label: 'Amfíkleia', value: 'Amfíkleia' },
  { label: 'Amfilochía', value: 'Amfilochía' },
  { label: 'Ámfissa', value: 'Ámfissa' },
  { label: 'Amfreville', value: 'Amfreville' },
  { label: 'Amfreville-La-Mi-Voie', value: 'Amfreville-La-Mi-Voie' },
  { label: 'Amga', value: 'Amga' },
  { label: 'Amgaon', value: 'Amgaon' },
  { label: 'Amguri', value: 'Amguri' },
  { label: 'Amherst', value: 'Amherst' },
  { label: 'Amherst', value: 'Amherst' },
  { label: 'Amherst', value: 'Amherst' },
  { label: 'Amherst', value: 'Amherst' },
  { label: 'Amherst', value: 'Amherst' },
  { label: 'Amherst', value: 'Amherst' },
  { label: 'Amherst Center', value: 'Amherst Center' },
  { label: 'Amherst County', value: 'Amherst County' },
  { label: 'Amherstburg', value: 'Amherstburg' },
  { label: 'Ami', value: 'Ami' },
  { label: 'Amiães De Baixo', value: 'Amiães De Baixo' },
  { label: 'Amica', value: 'Amica' },
  { label: 'Amidon', value: 'Amidon' },
  { label: 'Amiel Town', value: 'Amiel Town' },
  { label: 'Amiens', value: 'Amiens' },
  { label: 'Amieva', value: 'Amieva' },
  { label: 'Amigo Beach', value: 'Amigo Beach' },
  { label: 'Amil District', value: 'Amil District' },
  { label: 'Amilcingo', value: 'Amilcingo' },
  { label: 'Amilly', value: 'Amilly' },
  { label: 'Amin', value: 'Amin' },
  { label: 'Amin’Yevo', value: 'Amin’Yevo' },
  { label: 'Amina', value: 'Amina' },
  { label: 'Aminshahr ', value: 'Aminshahr ' },
  { label: 'Amio', value: 'Amio' },
  { label: 'Amir Kala', value: 'Amir Kala' },
  { label: 'Amirabad', value: 'Amirabad' },
  { label: 'Amirabad-E Kord', value: 'Amirabad-E Kord' },
  { label: 'Amirdzhan', value: 'Amirdzhan' },
  { label: 'Amiriyeh', value: 'Amiriyeh' },
  { label: 'Amite', value: 'Amite' },
  { label: 'Amite County', value: 'Amite County' },
  { label: 'Amitié Gokoola', value: 'Amitié Gokoola' },
  { label: 'Amity', value: 'Amity' },
  { label: 'Amity', value: 'Amity' },
  { label: 'Amity Gardens', value: 'Amity Gardens' },
  { label: 'Amity Hall', value: 'Amity Hall' },
  { label: 'Amityville', value: 'Amityville' },
  { label: 'Amixtlán', value: 'Amixtlán' },
  { label: 'Amizour', value: 'Amizour' },
  { label: 'Amla', value: 'Amla' },
  { label: 'Amlach', value: 'Amlach' },
  { label: 'Amlagora', value: 'Amlagora' },
  { label: 'Amlamé', value: 'Amlamé' },
  { label: 'Amlapura', value: 'Amlapura' },
  { label: 'Amlapura City', value: 'Amlapura City' },
  { label: 'Amlash', value: 'Amlash' },
  { label: 'Amli', value: 'Amli' },
  { label: 'Amlimay', value: 'Amlimay' },
  { label: 'Amlimay', value: 'Amlimay' },
  { label: 'Amloh', value: 'Amloh' },
  { label: 'Amlwch', value: 'Amlwch' },
  { label: 'Amman', value: 'Amman' },
  { label: 'Ammanford', value: 'Ammanford' },
  { label: 'Ammapettai', value: 'Ammapettai' },
  { label: 'Ammerndorf', value: 'Ammerndorf' },
  { label: 'Ammerschwihr', value: 'Ammerschwihr' },
  { label: 'Ammerstol', value: 'Ammerstol' },
  { label: 'Ammerthal', value: 'Ammerthal' },
  { label: 'Ammerzoden', value: 'Ammerzoden' },
  { label: 'Ammi Moussa', value: 'Ammi Moussa' },
  { label: 'Ammochóri', value: 'Ammochóri' },
  { label: 'Ammochostos Municipality', value: 'Ammochostos Municipality' },
  { label: 'Ammon', value: 'Ammon' },
  { label: 'Amnat Charoen', value: 'Amnat Charoen' },
  { label: 'Amnéville', value: 'Amnéville' },
  { label: 'Amochayevsky', value: 'Amochayevsky' },
  { label: 'Amod', value: 'Amod' },
  { label: 'Amodio-Massariola', value: 'Amodio-Massariola' },
  { label: 'Amoeiro', value: 'Amoeiro' },
  { label: 'Amol', value: 'Amol' },
  { label: 'Amöneburg', value: 'Amöneburg' },
  { label: 'Amontada', value: 'Amontada' },
  { label: 'Amor', value: 'Amor' },
  { label: 'Amora', value: 'Amora' },
  { label: 'Amorebieta-Etxano', value: 'Amorebieta-Etxano' },
  { label: 'Amoreira', value: 'Amoreira' },
  { label: 'Amorgós', value: 'Amorgós' },
  { label: 'Amorim', value: 'Amorim' },
  { label: 'Amorinópolis', value: 'Amorinópolis' },
  { label: 'Amorosi', value: 'Amorosi' },
  { label: 'Amoroto', value: 'Amoroto' },
  { label: 'Amory', value: 'Amory' },
  { label: 'Amos', value: 'Amos' },
  { label: 'Åmot', value: 'Åmot' },
  { label: 'Åmotfors', value: 'Åmotfors' },
  { label: 'Amou', value: 'Amou' },
  { label: 'Amozoc', value: 'Amozoc' },
  { label: 'Amozoc De Mota', value: 'Amozoc De Mota' },
  { label: 'Ampang', value: 'Ampang' },
  { label: 'Ampara', value: 'Ampara' },
  { label: 'Ampara District', value: 'Ampara District' },
  { label: 'Amparo', value: 'Amparo' },
  { label: 'Amparo', value: 'Amparo' },
  { label: 'Amparo Da Serra', value: 'Amparo Da Serra' },
  { label: 'Amparo De São Francisco', value: 'Amparo De São Francisco' },
  { label: 'Ampasimanolotra', value: 'Ampasimanolotra' },
  { label: 'Ampass', value: 'Ampass' },
  { label: 'Ampatuan', value: 'Ampatuan' },
  { label: 'Ampatuan', value: 'Ampatuan' },
  { label: 'Ampelákia', value: 'Ampelákia' },
  { label: 'Ampeleíes', value: 'Ampeleíes' },
  { label: 'Ampelókipoi', value: 'Ampelókipoi' },
  { label: 'Amper', value: 'Amper' },
  { label: 'Ampére', value: 'Ampére' },
  { label: 'Ampezzo', value: 'Ampezzo' },
  { label: 'Ampfing', value: 'Ampfing' },
  { label: 'Ampflwang', value: 'Ampflwang' },
  { label: 'Amphoe Akat Amnuai', value: 'Amphoe Akat Amnuai' },
  { label: 'Amphoe Amphawa', value: 'Amphoe Amphawa' },
  { label: 'Amphoe Ao Luek', value: 'Amphoe Ao Luek' },
  { label: 'Amphoe Aranyaprathet', value: 'Amphoe Aranyaprathet' },
  { label: 'Amphoe At Samat', value: 'Amphoe At Samat' },
  { label: 'Amphoe Ba Cho', value: 'Amphoe Ba Cho' },
  { label: 'Amphoe Ban Bueng', value: 'Amphoe Ban Bueng' },
  { label: 'Amphoe Ban Chang', value: 'Amphoe Ban Chang' },
  { label: 'Amphoe Ban Dan', value: 'Amphoe Ban Dan' },
  { label: 'Amphoe Ban Dan Lan Hoi', value: 'Amphoe Ban Dan Lan Hoi' },
  { label: 'Amphoe Ban Dung', value: 'Amphoe Ban Dung' },
  { label: 'Amphoe Ban Fang', value: 'Amphoe Ban Fang' },
  { label: 'Amphoe Ban Haet', value: 'Amphoe Ban Haet' },
  { label: 'Amphoe Ban Hong', value: 'Amphoe Ban Hong' },
  { label: 'Amphoe Ban Kha', value: 'Amphoe Ban Kha' },
  { label: 'Amphoe Ban Khai', value: 'Amphoe Ban Khai' },
  { label: 'Amphoe Ban Khok', value: 'Amphoe Ban Khok' },
  { label: 'Amphoe Ban Kruat', value: 'Amphoe Ban Kruat' },
  { label: 'Amphoe Ban Laem', value: 'Amphoe Ban Laem' },
  { label: 'Amphoe Ban Lat', value: 'Amphoe Ban Lat' },
  { label: 'Amphoe Ban Luang', value: 'Amphoe Ban Luang' },
  { label: 'Amphoe Ban Lueam', value: 'Amphoe Ban Lueam' },
  { label: 'Amphoe Ban Mai Chaiyaphot', value: 'Amphoe Ban Mai Chaiyaphot' },
  { label: 'Amphoe Ban Mi', value: 'Amphoe Ban Mi' },
  { label: 'Amphoe Ban Mo', value: 'Amphoe Ban Mo' },
  { label: 'Amphoe Ban Muang', value: 'Amphoe Ban Muang' },
  { label: 'Amphoe Ban Na', value: 'Amphoe Ban Na' },
  { label: 'Amphoe Ban Na Doem', value: 'Amphoe Ban Na Doem' },
  { label: 'Amphoe Ban Na San', value: 'Amphoe Ban Na San' },
  { label: 'Amphoe Ban Phaeng', value: 'Amphoe Ban Phaeng' },
  { label: 'Amphoe Ban Phaeo', value: 'Amphoe Ban Phaeo' },
  { label: 'Amphoe Ban Phai', value: 'Amphoe Ban Phai' },
  { label: 'Amphoe Ban Pho', value: 'Amphoe Ban Pho' },
  { label: 'Amphoe Ban Phraek', value: 'Amphoe Ban Phraek' },
  { label: 'Amphoe Ban Phue', value: 'Amphoe Ban Phue' },
  { label: 'Amphoe Ban Pong', value: 'Amphoe Ban Pong' },
  { label: 'Amphoe Ban Rai', value: 'Amphoe Ban Rai' },
  { label: 'Amphoe Ban Sang', value: 'Amphoe Ban Sang' },
  { label: 'Amphoe Ban Tak', value: 'Amphoe Ban Tak' },
  { label: 'Amphoe Ban Takhun', value: 'Amphoe Ban Takhun' },
  { label: 'Amphoe Ban Thi', value: 'Amphoe Ban Thi' },
  { label: 'Amphoe Bang Ban', value: 'Amphoe Bang Ban' },
  { label: 'Amphoe Bang Bo', value: 'Amphoe Bang Bo' },
  { label: 'Amphoe Bang Bua Thong', value: 'Amphoe Bang Bua Thong' },
  { label: 'Amphoe Bang Kaeo', value: 'Amphoe Bang Kaeo' },
  { label: 'Amphoe Bang Khan', value: 'Amphoe Bang Khan' },
  { label: 'Amphoe Bang Khla', value: 'Amphoe Bang Khla' },
  { label: 'Amphoe Bang Khonthi', value: 'Amphoe Bang Khonthi' },
  { label: 'Amphoe Bang Klam', value: 'Amphoe Bang Klam' },
  { label: 'Amphoe Bang Krathum', value: 'Amphoe Bang Krathum' },
  { label: 'Amphoe Bang Kruai', value: 'Amphoe Bang Kruai' },
  { label: 'Amphoe Bang Len', value: 'Amphoe Bang Len' },
  { label: 'Amphoe Bang Mun Nak', value: 'Amphoe Bang Mun Nak' },
  { label: 'Amphoe Bang Nam Priao', value: 'Amphoe Bang Nam Priao' },
  { label: 'Amphoe Bang Pahan', value: 'Amphoe Bang Pahan' },
  { label: 'Amphoe Bang Pa-In', value: 'Amphoe Bang Pa-In' },
  { label: 'Amphoe Bang Pakong', value: 'Amphoe Bang Pakong' },
  { label: 'Amphoe Bang Phae', value: 'Amphoe Bang Phae' },
  { label: 'Amphoe Bang Phli', value: 'Amphoe Bang Phli' },
  { label: 'Amphoe Bang Pla Ma', value: 'Amphoe Bang Pla Ma' },
  { label: 'Amphoe Bang Rachan', value: 'Amphoe Bang Rachan' },
  { label: 'Amphoe Bang Rakam', value: 'Amphoe Bang Rakam' },
  { label: 'Amphoe Bang Sai', value: 'Amphoe Bang Sai' },
  { label: 'Amphoe Bang Sao Thong', value: 'Amphoe Bang Sao Thong' },
  { label: 'Amphoe Bang Saphan', value: 'Amphoe Bang Saphan' },
  { label: 'Amphoe Bang Saphan Noi', value: 'Amphoe Bang Saphan Noi' },
  { label: 'Amphoe Bang Yai', value: 'Amphoe Bang Yai' },
  { label: 'Amphoe Bannang Sata', value: 'Amphoe Bannang Sata' },
  { label: 'Amphoe Banphot Phisai', value: 'Amphoe Banphot Phisai' },
  { label: 'Amphoe Benchalak', value: 'Amphoe Benchalak' },
  { label: 'Amphoe Betong', value: 'Amphoe Betong' },
  { label: 'Amphoe Bo Kluea', value: 'Amphoe Bo Kluea' },
  { label: 'Amphoe Bo Phloi', value: 'Amphoe Bo Phloi' },
  { label: 'Amphoe Bo Rai', value: 'Amphoe Bo Rai' },
  { label: 'Amphoe Bo Thong', value: 'Amphoe Bo Thong' },
  { label: 'Amphoe Borabue', value: 'Amphoe Borabue' },
  { label: 'Amphoe Bua Chet', value: 'Amphoe Bua Chet' },
  { label: 'Amphoe Bua Lai', value: 'Amphoe Bua Lai' },
  { label: 'Amphoe Bua Yai', value: 'Amphoe Bua Yai' },
  { label: 'Amphoe Bueng Bun', value: 'Amphoe Bueng Bun' },
  { label: 'Amphoe Bueng Khong Long', value: 'Amphoe Bueng Khong Long' },
  { label: 'Amphoe Bueng Na Rang', value: 'Amphoe Bueng Na Rang' },
  { label: 'Amphoe Bueng Sam Phan', value: 'Amphoe Bueng Sam Phan' },
  { label: 'Amphoe Bueng Samakkhi', value: 'Amphoe Bueng Samakkhi' },
  { label: 'Amphoe Bung Khla', value: 'Amphoe Bung Khla' },
  { label: 'Amphoe Buntharik', value: 'Amphoe Buntharik' },
  { label: 'Amphoe Cha-Am', value: 'Amphoe Cha-Am' },
  { label: 'Amphoe Chae Hom', value: 'Amphoe Chae Hom' },
  { label: 'Amphoe Chai Badan', value: 'Amphoe Chai Badan' },
  { label: 'Amphoe Chai Buri', value: 'Amphoe Chai Buri' },
  { label: 'Amphoe Chai Prakan', value: 'Amphoe Chai Prakan' },
  { label: 'Amphoe Chai Wan', value: 'Amphoe Chai Wan' },
  { label: 'Amphoe Chaiya', value: 'Amphoe Chaiya' },
  { label: 'Amphoe Chaiyo', value: 'Amphoe Chaiyo' },
  { label: 'Amphoe Chakkarat', value: 'Amphoe Chakkarat' },
  { label: 'Amphoe Chaloem Phra Kiat', value: 'Amphoe Chaloem Phra Kiat' },
  { label: 'Amphoe Chaloem Phra Kiat', value: 'Amphoe Chaloem Phra Kiat' },
  { label: 'Amphoe Chaloem Phra Kiat', value: 'Amphoe Chaloem Phra Kiat' },
  { label: 'Amphoe Chaloem Phra Kiat', value: 'Amphoe Chaloem Phra Kiat' },
  { label: 'Amphoe Chaloem Phra Kiat', value: 'Amphoe Chaloem Phra Kiat' },
  { label: 'Amphoe Chamni', value: 'Amphoe Chamni' },
  { label: 'Amphoe Chana', value: 'Amphoe Chana' },
  { label: 'Amphoe Chanae', value: 'Amphoe Chanae' },
  { label: 'Amphoe Chang Klang', value: 'Amphoe Chang Klang' },
  { label: 'Amphoe Changhan', value: 'Amphoe Changhan' },
  { label: 'Amphoe Chanuman', value: 'Amphoe Chanuman' },
  { label: 'Amphoe Charoen Sin', value: 'Amphoe Charoen Sin' },
  { label: 'Amphoe Chat Trakan', value: 'Amphoe Chat Trakan' },
  { label: 'Amphoe Chaturaphak Phiman', value: 'Amphoe Chaturaphak Phiman' },
  { label: 'Amphoe Cha-Uat', value: 'Amphoe Cha-Uat' },
  { label: 'Amphoe Chawang', value: 'Amphoe Chawang' },
  { label: 'Amphoe Chian Yai', value: 'Amphoe Chian Yai' },
  { label: 'Amphoe Chiang Dao', value: 'Amphoe Chiang Dao' },
  { label: 'Amphoe Chiang Kham', value: 'Amphoe Chiang Kham' },
  { label: 'Amphoe Chiang Khan', value: 'Amphoe Chiang Khan' },
  { label: 'Amphoe Chiang Khong', value: 'Amphoe Chiang Khong' },
  { label: 'Amphoe Chiang Khwan', value: 'Amphoe Chiang Khwan' },
  { label: 'Amphoe Chiang Klang', value: 'Amphoe Chiang Klang' },
  { label: 'Amphoe Chiang Muan', value: 'Amphoe Chiang Muan' },
  { label: 'Amphoe Chiang Saen', value: 'Amphoe Chiang Saen' },
  { label: 'Amphoe Chiang Yuen', value: 'Amphoe Chiang Yuen' },
  { label: 'Amphoe Cho-Airong', value: 'Amphoe Cho-Airong' },
  { label: 'Amphoe Chok Chai', value: 'Amphoe Chok Chai' },
  { label: 'Amphoe Chom Bueng', value: 'Amphoe Chom Bueng' },
  { label: 'Amphoe Chom Phra', value: 'Amphoe Chom Phra' },
  { label: 'Amphoe Chom Thong', value: 'Amphoe Chom Thong' },
  { label: 'Amphoe Chon Daen', value: 'Amphoe Chon Daen' },
  { label: 'Amphoe Chonnabot', value: 'Amphoe Chonnabot' },
  { label: 'Amphoe Chuen Chom', value: 'Amphoe Chuen Chom' },
  { label: 'Amphoe Chulabhorn', value: 'Amphoe Chulabhorn' },
  { label: 'Amphoe Chum Phae', value: 'Amphoe Chum Phae' },
  { label: 'Amphoe Chum Phuang', value: 'Amphoe Chum Phuang' },
  { label: 'Amphoe Chum Ta Bong', value: 'Amphoe Chum Ta Bong' },
  { label: 'Amphoe Chumphon Buri', value: 'Amphoe Chumphon Buri' },
  { label: 'Amphoe Chumsaeng', value: 'Amphoe Chumsaeng' },
  { label: 'Amphoe Chun', value: 'Amphoe Chun' },
  { label: 'Amphoe Damnoen Saduak', value: 'Amphoe Damnoen Saduak' },
  { label: 'Amphoe Dan Chang', value: 'Amphoe Dan Chang' },
  { label: 'Amphoe Dan Khun Thot', value: 'Amphoe Dan Khun Thot' },
  { label: 'Amphoe Dan Makham Tia', value: 'Amphoe Dan Makham Tia' },
  { label: 'Amphoe Dan Sai', value: 'Amphoe Dan Sai' },
  { label: 'Amphoe Den Chai', value: 'Amphoe Den Chai' },
  { label: 'Amphoe Det Udom', value: 'Amphoe Det Udom' },
  { label: 'Amphoe Doembang Nangbuat', value: 'Amphoe Doembang Nangbuat' },
  { label: 'Amphoe Doi Lo', value: 'Amphoe Doi Lo' },
  { label: 'Amphoe Doi Luang', value: 'Amphoe Doi Luang' },
  { label: 'Amphoe Doi Saket', value: 'Amphoe Doi Saket' },
  { label: 'Amphoe Doi Tao', value: 'Amphoe Doi Tao' },
  { label: 'Amphoe Dok Kham Tai', value: 'Amphoe Dok Kham Tai' },
  { label: 'Amphoe Don Chan', value: 'Amphoe Don Chan' },
  { label: 'Amphoe Don Chedi', value: 'Amphoe Don Chedi' },
  { label: 'Amphoe Don Mot Daeng', value: 'Amphoe Don Mot Daeng' },
  { label: 'Amphoe Don Phut', value: 'Amphoe Don Phut' },
  { label: 'Amphoe Don Sak', value: 'Amphoe Don Sak' },
  { label: 'Amphoe Don Tan', value: 'Amphoe Don Tan' },
  { label: 'Amphoe Don Tum', value: 'Amphoe Don Tum' },
  { label: 'Amphoe Dong Charoen', value: 'Amphoe Dong Charoen' },
  { label: 'Amphoe Dong Luang', value: 'Amphoe Dong Luang' },
  { label: 'Amphoe Erawan', value: 'Amphoe Erawan' },
  { label: 'Amphoe Fak Tha', value: 'Amphoe Fak Tha' },
  { label: 'Amphoe Fang', value: 'Amphoe Fang' },
  { label: 'Amphoe Fao Rai', value: 'Amphoe Fao Rai' },
  { label: 'Amphoe Galyani Vadhana', value: 'Amphoe Galyani Vadhana' },
  { label: 'Amphoe Hang Chat', value: 'Amphoe Hang Chat' },
  { label: 'Amphoe Hang Dong', value: 'Amphoe Hang Dong' },
  { label: 'Amphoe Hankha', value: 'Amphoe Hankha' },
  { label: 'Amphoe Hat Samran', value: 'Amphoe Hat Samran' },
  { label: 'Amphoe Hat Yai', value: 'Amphoe Hat Yai' },
  { label: 'Amphoe Hot', value: 'Amphoe Hot' },
  { label: 'Amphoe Hua Hin', value: 'Amphoe Hua Hin' },
  { label: 'Amphoe Hua Sai', value: 'Amphoe Hua Sai' },
  { label: 'Amphoe Hua Taphan', value: 'Amphoe Hua Taphan' },
  { label: 'Amphoe Huai Khot', value: 'Amphoe Huai Khot' },
  { label: 'Amphoe Huai Krachao', value: 'Amphoe Huai Krachao' },
  { label: 'Amphoe Huai Mek', value: 'Amphoe Huai Mek' },
  { label: 'Amphoe Huai Phueng', value: 'Amphoe Huai Phueng' },
  { label: 'Amphoe Huai Rat', value: 'Amphoe Huai Rat' },
  { label: 'Amphoe Huai Thalaeng', value: 'Amphoe Huai Thalaeng' },
  { label: 'Amphoe Huai Thap Than', value: 'Amphoe Huai Thap Than' },
  { label: 'Amphoe Huai Yot', value: 'Amphoe Huai Yot' },
  { label: 'Amphoe In Buri', value: 'Amphoe In Buri' },
  { label: 'Amphoe Kabang', value: 'Amphoe Kabang' },
  { label: 'Amphoe Kabin Buri', value: 'Amphoe Kabin Buri' },
  { label: 'Amphoe Kae Dam', value: 'Amphoe Kae Dam' },
  { label: 'Amphoe Kaeng Hang Maeo', value: 'Amphoe Kaeng Hang Maeo' },
  { label: 'Amphoe Kaeng Khoi', value: 'Amphoe Kaeng Khoi' },
  { label: 'Amphoe Kaeng Krachan', value: 'Amphoe Kaeng Krachan' },
  { label: 'Amphoe Kaeng Sanam Nang', value: 'Amphoe Kaeng Sanam Nang' },
  { label: 'Amphoe Kamalasai', value: 'Amphoe Kamalasai' },
  { label: 'Amphoe Kanchanadit', value: 'Amphoe Kanchanadit' },
  { label: 'Amphoe Kantang', value: 'Amphoe Kantang' },
  { label: 'Amphoe Kantharalak', value: 'Amphoe Kantharalak' },
  { label: 'Amphoe Kanthararom', value: 'Amphoe Kanthararom' },
  { label: 'Amphoe Kantharawichai', value: 'Amphoe Kantharawichai' },
  { label: 'Amphoe Kao Liao', value: 'Amphoe Kao Liao' },
  { label: 'Amphoe Kap Choeng', value: 'Amphoe Kap Choeng' },
  { label: 'Amphoe Kapho', value: 'Amphoe Kapho' },
  { label: 'Amphoe Kapoe', value: 'Amphoe Kapoe' },
  { label: 'Amphoe Kapong', value: 'Amphoe Kapong' },
  { label: 'Amphoe Kaset Wisai', value: 'Amphoe Kaset Wisai' },
  { label: 'Amphoe Kathu', value: 'Amphoe Kathu' },
  { label: 'Amphoe Khaen Dong', value: 'Amphoe Khaen Dong' },
  { label: 'Amphoe Khai Bang Rachan', value: 'Amphoe Khai Bang Rachan' },
  { label: 'Amphoe Kham Khuan Kaeo', value: 'Amphoe Kham Khuan Kaeo' },
  { label: 'Amphoe Kham Muang', value: 'Amphoe Kham Muang' },
  { label: 'Amphoe Kham Sakae Saeng', value: 'Amphoe Kham Sakae Saeng' },
  { label: 'Amphoe Kham Ta Kla', value: 'Amphoe Kham Ta Kla' },
  { label: 'Amphoe Kham Thale So', value: 'Amphoe Kham Thale So' },
  { label: 'Amphoe Khamcha-I', value: 'Amphoe Khamcha-I' },
  { label: 'Amphoe Khanom', value: 'Amphoe Khanom' },
  { label: 'Amphoe Khanu Woralaksaburi', value: 'Amphoe Khanu Woralaksaburi' },
  { label: 'Amphoe Khao Chaison', value: 'Amphoe Khao Chaison' },
  { label: 'Amphoe Khao Chakan', value: 'Amphoe Khao Chakan' },
  { label: 'Amphoe Khao Chamao', value: 'Amphoe Khao Chamao' },
  { label: 'Amphoe Khao Khitchakut', value: 'Amphoe Khao Khitchakut' },
  { label: 'Amphoe Khao Kho', value: 'Amphoe Khao Kho' },
  { label: 'Amphoe Khao Phanom', value: 'Amphoe Khao Phanom' },
  { label: 'Amphoe Khao Saming', value: 'Amphoe Khao Saming' },
  { label: 'Amphoe Khao Suan Kwang', value: 'Amphoe Khao Suan Kwang' },
  { label: 'Amphoe Khao Wong', value: 'Amphoe Khao Wong' },
  { label: 'Amphoe Khao Yoi', value: 'Amphoe Khao Yoi' },
  { label: 'Amphoe Khemmarat', value: 'Amphoe Khemmarat' },
  { label: 'Amphoe Khian Sa', value: 'Amphoe Khian Sa' },
  { label: 'Amphoe Khiri Mat', value: 'Amphoe Khiri Mat' },
  { label: 'Amphoe Khiri Rat Nikhom', value: 'Amphoe Khiri Rat Nikhom' },
  { label: 'Amphoe Khlong Hat', value: 'Amphoe Khlong Hat' },
  { label: 'Amphoe Khlong Hoi Khong', value: 'Amphoe Khlong Hoi Khong' },
  { label: 'Amphoe Khlong Khlung', value: 'Amphoe Khlong Khlung' },
  { label: 'Amphoe Khlong Khuean', value: 'Amphoe Khlong Khuean' },
  { label: 'Amphoe Khlong Lan', value: 'Amphoe Khlong Lan' },
  { label: 'Amphoe Khlong Luang', value: 'Amphoe Khlong Luang' },
  { label: 'Amphoe Khlong Thom', value: 'Amphoe Khlong Thom' },
  { label: 'Amphoe Khlong Yai', value: 'Amphoe Khlong Yai' },
  { label: 'Amphoe Khlung', value: 'Amphoe Khlung' },
  { label: 'Amphoe Kho Wang', value: 'Amphoe Kho Wang' },
  { label: 'Amphoe Khok Charoen', value: 'Amphoe Khok Charoen' },
  { label: 'Amphoe Khok Pho', value: 'Amphoe Khok Pho' },
  { label: 'Amphoe Khok Pho Chai', value: 'Amphoe Khok Pho Chai' },
  { label: 'Amphoe Khok Samrong', value: 'Amphoe Khok Samrong' },
  { label: 'Amphoe Khok Si Suphan', value: 'Amphoe Khok Si Suphan' },
  { label: 'Amphoe Khok Sung', value: 'Amphoe Khok Sung' },
  { label: 'Amphoe Khon Buri', value: 'Amphoe Khon Buri' },
  { label: 'Amphoe Khong', value: 'Amphoe Khong' },
  { label: 'Amphoe Khong Chai', value: 'Amphoe Khong Chai' },
  { label: 'Amphoe Khong Chiam', value: 'Amphoe Khong Chiam' },
  { label: 'Amphoe Khu Mueang', value: 'Amphoe Khu Mueang' },
  { label: 'Amphoe Khuan Don', value: 'Amphoe Khuan Don' },
  { label: 'Amphoe Khuan Kalong', value: 'Amphoe Khuan Kalong' },
  { label: 'Amphoe Khuan Khanun', value: 'Amphoe Khuan Khanun' },
  { label: 'Amphoe Khuan Niang', value: 'Amphoe Khuan Niang' },
  { label: 'Amphoe Khueang Nai', value: 'Amphoe Khueang Nai' },
  { label: 'Amphoe Khukhan', value: 'Amphoe Khukhan' },
  { label: 'Amphoe Khun Han', value: 'Amphoe Khun Han' },
  { label: 'Amphoe Khun Tan', value: 'Amphoe Khun Tan' },
  { label: 'Amphoe Khun Yuam', value: 'Amphoe Khun Yuam' },
  { label: 'Amphoe Khura Buri', value: 'Amphoe Khura Buri' },
  { label: 'Amphoe Khwao Sinarin', value: 'Amphoe Khwao Sinarin' },
  { label: 'Amphoe Klaeng', value: 'Amphoe Klaeng' },
  { label: 'Amphoe Ko Chan', value: 'Amphoe Ko Chan' },
  { label: 'Amphoe Ko Chang', value: 'Amphoe Ko Chang' },
  { label: 'Amphoe Ko Kha', value: 'Amphoe Ko Kha' },
  { label: 'Amphoe Ko Kut', value: 'Amphoe Ko Kut' },
  { label: 'Amphoe Ko Lanta', value: 'Amphoe Ko Lanta' },
  { label: 'Amphoe Ko Pha-Ngan', value: 'Amphoe Ko Pha-Ngan' },
  { label: 'Amphoe Ko Samui', value: 'Amphoe Ko Samui' },
  { label: 'Amphoe Ko Si Chang', value: 'Amphoe Ko Si Chang' },
  { label: 'Amphoe Ko Yao', value: 'Amphoe Ko Yao' },
  { label: 'Amphoe Kong Krailat', value: 'Amphoe Kong Krailat' },
  { label: 'Amphoe Kong Ra', value: 'Amphoe Kong Ra' },
  { label: 'Amphoe Kosamphi Nakhon', value: 'Amphoe Kosamphi Nakhon' },
  { label: 'Amphoe Kosum Phisai', value: 'Amphoe Kosum Phisai' },
  { label: 'Amphoe Kra Buri', value: 'Amphoe Kra Buri' },
  { label: 'Amphoe Kranuan', value: 'Amphoe Kranuan' },
  { label: 'Amphoe Krasae Sin', value: 'Amphoe Krasae Sin' },
  { label: 'Amphoe Krasang', value: 'Amphoe Krasang' },
  { label: 'Amphoe Krathum Baen', value: 'Amphoe Krathum Baen' },
  { label: 'Amphoe Krok Phra', value: 'Amphoe Krok Phra' },
  { label: 'Amphoe Krong Pinang', value: 'Amphoe Krong Pinang' },
  { label: 'Amphoe Ku Kaeo', value: 'Amphoe Ku Kaeo' },
  { label: 'Amphoe Kuchinarai', value: 'Amphoe Kuchinarai' },
  { label: 'Amphoe Kui Buri', value: 'Amphoe Kui Buri' },
  { label: 'Amphoe Kumphawapi', value: 'Amphoe Kumphawapi' },
  { label: 'Amphoe Kusuman', value: 'Amphoe Kusuman' },
  { label: 'Amphoe Kut Bak', value: 'Amphoe Kut Bak' },
  { label: 'Amphoe Kut Chap', value: 'Amphoe Kut Chap' },
  { label: 'Amphoe Kut Chum', value: 'Amphoe Kut Chum' },
  { label: 'Amphoe Kut Khaopun', value: 'Amphoe Kut Khaopun' },
  { label: 'Amphoe Kut Rang', value: 'Amphoe Kut Rang' },
  { label: 'Amphoe Laem Ngop', value: 'Amphoe Laem Ngop' },
  { label: 'Amphoe Laem Sing', value: 'Amphoe Laem Sing' },
  { label: 'Amphoe Lahan Sai', value: 'Amphoe Lahan Sai' },
  { label: 'Amphoe Lam Luk Ka', value: 'Amphoe Lam Luk Ka' },
  { label: 'Amphoe Lam Plai Mat', value: 'Amphoe Lam Plai Mat' },
  { label: 'Amphoe Lam Sonthi', value: 'Amphoe Lam Sonthi' },
  { label: 'Amphoe Lam Thamen Chai', value: 'Amphoe Lam Thamen Chai' },
  { label: 'Amphoe Lam Thap', value: 'Amphoe Lam Thap' },
  { label: 'Amphoe Lamae', value: 'Amphoe Lamae' },
  { label: 'Amphoe Lamduan', value: 'Amphoe Lamduan' },
  { label: 'Amphoe Lan Krabue', value: 'Amphoe Lan Krabue' },
  { label: 'Amphoe Lan Sak', value: 'Amphoe Lan Sak' },
  { label: 'Amphoe Lan Saka', value: 'Amphoe Lan Saka' },
  { label: 'Amphoe Lang Suan', value: 'Amphoe Lang Suan' },
  { label: 'Amphoe La-Ngu', value: 'Amphoe La-Ngu' },
  { label: 'Amphoe Lao Khwan', value: 'Amphoe Lao Khwan' },
  { label: 'Amphoe Lao Suea Kok', value: 'Amphoe Lao Suea Kok' },
  { label: 'Amphoe Lap Lae', value: 'Amphoe Lap Lae' },
  { label: 'Amphoe Lat Bua Luang', value: 'Amphoe Lat Bua Luang' },
  { label: 'Amphoe Lat Lum Kaeo', value: 'Amphoe Lat Lum Kaeo' },
  { label: 'Amphoe Lat Yao', value: 'Amphoe Lat Yao' },
  { label: 'Amphoe La-Un', value: 'Amphoe La-Un' },
  { label: 'Amphoe Li', value: 'Amphoe Li' },
  { label: 'Amphoe Loeng Nok Tha', value: 'Amphoe Loeng Nok Tha' },
  { label: 'Amphoe Lom Kao', value: 'Amphoe Lom Kao' },
  { label: 'Amphoe Lom Sak', value: 'Amphoe Lom Sak' },
  { label: 'Amphoe Long', value: 'Amphoe Long' },
  { label: 'Amphoe Lue Amnat', value: 'Amphoe Lue Amnat' },
  { label: 'Amphoe Mae Ai', value: 'Amphoe Mae Ai' },
  { label: 'Amphoe Mae Chaem', value: 'Amphoe Mae Chaem' },
  { label: 'Amphoe Mae Chai', value: 'Amphoe Mae Chai' },
  { label: 'Amphoe Mae Chan', value: 'Amphoe Mae Chan' },
  { label: 'Amphoe Mae Charim', value: 'Amphoe Mae Charim' },
  { label: 'Amphoe Mae Fa Luang', value: 'Amphoe Mae Fa Luang' },
  { label: 'Amphoe Mae La Noi', value: 'Amphoe Mae La Noi' },
  { label: 'Amphoe Mae Lan', value: 'Amphoe Mae Lan' },
  { label: 'Amphoe Mae Lao', value: 'Amphoe Mae Lao' },
  { label: 'Amphoe Mae Mo', value: 'Amphoe Mae Mo' },
  { label: 'Amphoe Mae On', value: 'Amphoe Mae On' },
  { label: 'Amphoe Mae Phrik', value: 'Amphoe Mae Phrik' },
  { label: 'Amphoe Mae Poen', value: 'Amphoe Mae Poen' },
  { label: 'Amphoe Mae Ramat', value: 'Amphoe Mae Ramat' },
  { label: 'Amphoe Mae Rim', value: 'Amphoe Mae Rim' },
  { label: 'Amphoe Mae Sai', value: 'Amphoe Mae Sai' },
  { label: 'Amphoe Mae Sariang', value: 'Amphoe Mae Sariang' },
  { label: 'Amphoe Mae Sot', value: 'Amphoe Mae Sot' },
  { label: 'Amphoe Mae Suai', value: 'Amphoe Mae Suai' },
  { label: 'Amphoe Mae Taeng', value: 'Amphoe Mae Taeng' },
  { label: 'Amphoe Mae Tha', value: 'Amphoe Mae Tha' },
  { label: 'Amphoe Mae Tha', value: 'Amphoe Mae Tha' },
  { label: 'Amphoe Mae Wang', value: 'Amphoe Mae Wang' },
  { label: 'Amphoe Mae Wong', value: 'Amphoe Mae Wong' },
  { label: 'Amphoe Maha Chana Chai', value: 'Amphoe Maha Chana Chai' },
  { label: 'Amphoe Maha Rat', value: 'Amphoe Maha Rat' },
  { label: 'Amphoe Mai Kaen', value: 'Amphoe Mai Kaen' },
  { label: 'Amphoe Makham', value: 'Amphoe Makham' },
  { label: 'Amphoe Manang', value: 'Amphoe Manang' },
  { label: 'Amphoe Mancha Khiri', value: 'Amphoe Mancha Khiri' },
  { label: 'Amphoe Manorom', value: 'Amphoe Manorom' },
  { label: 'Amphoe Mayo', value: 'Amphoe Mayo' },
  { label: 'Amphoe Moeiwadi', value: 'Amphoe Moeiwadi' },
  { label: 'Amphoe Muak Lek', value: 'Amphoe Muak Lek' },
  { label: 'Amphoe Muang Sam Sip', value: 'Amphoe Muang Sam Sip' },
  { label: 'Amphoe Mueang Amnat Charoen', value: 'Amphoe Mueang Amnat Charoen' },
  { label: 'Amphoe Mueang Ang Thong', value: 'Amphoe Mueang Ang Thong' },
  { label: 'Amphoe Mueang Bueng Kan', value: 'Amphoe Mueang Bueng Kan' },
  { label: 'Amphoe Mueang Buriram', value: 'Amphoe Mueang Buriram' },
  { label: 'Amphoe Mueang Chachoengsao', value: 'Amphoe Mueang Chachoengsao' },
  { label: 'Amphoe Mueang Chainat', value: 'Amphoe Mueang Chainat' },
  { label: 'Amphoe Mueang Chan', value: 'Amphoe Mueang Chan' },
  { label: 'Amphoe Mueang Chanthaburi', value: 'Amphoe Mueang Chanthaburi' },
  { label: 'Amphoe Mueang Chiang Mai', value: 'Amphoe Mueang Chiang Mai' },
  { label: 'Amphoe Mueang Chiang Rai', value: 'Amphoe Mueang Chiang Rai' },
  { label: 'Amphoe Mueang Chumphon', value: 'Amphoe Mueang Chumphon' },
  { label: 'Amphoe Mueang Kalasin', value: 'Amphoe Mueang Kalasin' },
  { label: 'Amphoe Mueang Kamphaeng Phet', value: 'Amphoe Mueang Kamphaeng Phet' },
  { label: 'Amphoe Mueang Kanchanaburi', value: 'Amphoe Mueang Kanchanaburi' },
  { label: 'Amphoe Mueang Khon Kaen', value: 'Amphoe Mueang Khon Kaen' },
  { label: 'Amphoe Mueang Krabi', value: 'Amphoe Mueang Krabi' },
  { label: 'Amphoe Mueang Lampang', value: 'Amphoe Mueang Lampang' },
  { label: 'Amphoe Mueang Lamphun', value: 'Amphoe Mueang Lamphun' },
  { label: 'Amphoe Mueang Loei', value: 'Amphoe Mueang Loei' },
  { label: 'Amphoe Mueang Mae Hong Son', value: 'Amphoe Mueang Mae Hong Son' },
  { label: 'Amphoe Mueang Maha Sarakham', value: 'Amphoe Mueang Maha Sarakham' },
  { label: 'Amphoe Mueang Mukdahan', value: 'Amphoe Mueang Mukdahan' },
  { label: 'Amphoe Mueang Nakhon Nayok', value: 'Amphoe Mueang Nakhon Nayok' },
  { label: 'Amphoe Mueang Nakhon Pathom', value: 'Amphoe Mueang Nakhon Pathom' },
  { label: 'Amphoe Mueang Nakhon Phanom', value: 'Amphoe Mueang Nakhon Phanom' },
  { label: 'Amphoe Mueang Nakhon Ratchasima', value: 'Amphoe Mueang Nakhon Ratchasima' },
  { label: 'Amphoe Mueang Nakhon Sawan', value: 'Amphoe Mueang Nakhon Sawan' },
  { label: 'Amphoe Mueang Nakhon Si Thammarat', value: 'Amphoe Mueang Nakhon Si Thammarat' },
  { label: 'Amphoe Mueang Nan', value: 'Amphoe Mueang Nan' },
  { label: 'Amphoe Mueang Narathiwat', value: 'Amphoe Mueang Narathiwat' },
  { label: 'Amphoe Mueang Nong Bua Lamphu', value: 'Amphoe Mueang Nong Bua Lamphu' },
  { label: 'Amphoe Mueang Nong Khai', value: 'Amphoe Mueang Nong Khai' },
  { label: 'Amphoe Mueang Nonthaburi', value: 'Amphoe Mueang Nonthaburi' },
  { label: 'Amphoe Mueang Pan', value: 'Amphoe Mueang Pan' },
  { label: 'Amphoe Mueang Pathum Thani', value: 'Amphoe Mueang Pathum Thani' },
  { label: 'Amphoe Mueang Pattani', value: 'Amphoe Mueang Pattani' },
  { label: 'Amphoe Mueang Phangnga', value: 'Amphoe Mueang Phangnga' },
  { label: 'Amphoe Mueang Phatthalung', value: 'Amphoe Mueang Phatthalung' },
  { label: 'Amphoe Mueang Phayao', value: 'Amphoe Mueang Phayao' },
  { label: 'Amphoe Mueang Phetchabun', value: 'Amphoe Mueang Phetchabun' },
  { label: 'Amphoe Mueang Phetchaburi', value: 'Amphoe Mueang Phetchaburi' },
  { label: 'Amphoe Mueang Phichit', value: 'Amphoe Mueang Phichit' },
  { label: 'Amphoe Mueang Phitsanulok', value: 'Amphoe Mueang Phitsanulok' },
  { label: 'Amphoe Mueang Phrae', value: 'Amphoe Mueang Phrae' },
  { label: 'Amphoe Mueang Phuket', value: 'Amphoe Mueang Phuket' },
  { label: 'Amphoe Mueang Prachin Buri', value: 'Amphoe Mueang Prachin Buri' },
  { label: 'Amphoe Mueang Prachuap Khiri Khan', value: 'Amphoe Mueang Prachuap Khiri Khan' },
  { label: 'Amphoe Mueang Ranong', value: 'Amphoe Mueang Ranong' },
  { label: 'Amphoe Mueang Ratchaburi', value: 'Amphoe Mueang Ratchaburi' },
  { label: 'Amphoe Mueang Rayong', value: 'Amphoe Mueang Rayong' },
  { label: 'Amphoe Mueang Roi Et', value: 'Amphoe Mueang Roi Et' },
  { label: 'Amphoe Mueang Sa Kaeo', value: 'Amphoe Mueang Sa Kaeo' },
  { label: 'Amphoe Mueang Sakon Nakhon', value: 'Amphoe Mueang Sakon Nakhon' },
  { label: 'Amphoe Mueang Samut Prakan', value: 'Amphoe Mueang Samut Prakan' },
  { label: 'Amphoe Mueang Samut Sakhon', value: 'Amphoe Mueang Samut Sakhon' },
  { label: 'Amphoe Mueang Samut Songkhram', value: 'Amphoe Mueang Samut Songkhram' },
  { label: 'Amphoe Mueang Sara Buri', value: 'Amphoe Mueang Sara Buri' },
  { label: 'Amphoe Mueang Satun', value: 'Amphoe Mueang Satun' },
  { label: 'Amphoe Mueang Sing Buri', value: 'Amphoe Mueang Sing Buri' },
  { label: 'Amphoe Mueang Sisaket', value: 'Amphoe Mueang Sisaket' },
  { label: 'Amphoe Mueang Songkhla', value: 'Amphoe Mueang Songkhla' },
  { label: 'Amphoe Mueang Suang', value: 'Amphoe Mueang Suang' },
  { label: 'Amphoe Mueang Sukhothai', value: 'Amphoe Mueang Sukhothai' },
  { label: 'Amphoe Mueang Suphan Buri', value: 'Amphoe Mueang Suphan Buri' },
  { label: 'Amphoe Mueang Surat Thani', value: 'Amphoe Mueang Surat Thani' },
  { label: 'Amphoe Mueang Surin', value: 'Amphoe Mueang Surin' },
  { label: 'Amphoe Mueang Tak', value: 'Amphoe Mueang Tak' },
  { label: 'Amphoe Mueang Trang', value: 'Amphoe Mueang Trang' },
  { label: 'Amphoe Mueang Trat', value: 'Amphoe Mueang Trat' },
  { label: 'Amphoe Mueang Ubon Ratchathani', value: 'Amphoe Mueang Ubon Ratchathani' },
  { label: 'Amphoe Mueang Udon Thani', value: 'Amphoe Mueang Udon Thani' },
  { label: 'Amphoe Mueang Uthai Thani', value: 'Amphoe Mueang Uthai Thani' },
  { label: 'Amphoe Mueang Uttaradit', value: 'Amphoe Mueang Uttaradit' },
  { label: 'Amphoe Mueang Yala', value: 'Amphoe Mueang Yala' },
  { label: 'Amphoe Mueang Yang', value: 'Amphoe Mueang Yang' },
  { label: 'Amphoe Mueang Yasothon', value: 'Amphoe Mueang Yasothon' },
  { label: 'Amphoe Na Bon', value: 'Amphoe Na Bon' },
  { label: 'Amphoe Na Chaluai', value: 'Amphoe Na Chaluai' },
  { label: 'Amphoe Na Chueak', value: 'Amphoe Na Chueak' },
  { label: 'Amphoe Na Di', value: 'Amphoe Na Di' },
  { label: 'Amphoe Na Duang', value: 'Amphoe Na Duang' },
  { label: 'Amphoe Na Dun', value: 'Amphoe Na Dun' },
  { label: 'Amphoe Na Haeo', value: 'Amphoe Na Haeo' },
  { label: 'Amphoe Na Kae', value: 'Amphoe Na Kae' },
  { label: 'Amphoe Na Khu', value: 'Amphoe Na Khu' },
  { label: 'Amphoe Na Klang', value: 'Amphoe Na Klang' },
  { label: 'Amphoe Na Mom', value: 'Amphoe Na Mom' },
  { label: 'Amphoe Na Mon', value: 'Amphoe Na Mon' },
  { label: 'Amphoe Na Muen', value: 'Amphoe Na Muen' },
  { label: 'Amphoe Na Noi', value: 'Amphoe Na Noi' },
  { label: 'Amphoe Na Pho', value: 'Amphoe Na Pho' },
  { label: 'Amphoe Na Tan', value: 'Amphoe Na Tan' },
  { label: 'Amphoe Na Thawi', value: 'Amphoe Na Thawi' },
  { label: 'Amphoe Na Thom', value: 'Amphoe Na Thom' },
  { label: 'Amphoe Na Wa', value: 'Amphoe Na Wa' },
  { label: 'Amphoe Na Wang', value: 'Amphoe Na Wang' },
  { label: 'Amphoe Na Yai Am', value: 'Amphoe Na Yai Am' },
  { label: 'Amphoe Na Yia', value: 'Amphoe Na Yia' },
  { label: 'Amphoe Na Yong', value: 'Amphoe Na Yong' },
  { label: 'Amphoe Na Yung', value: 'Amphoe Na Yung' },
  { label: 'Amphoe Nakhon Chai Si', value: 'Amphoe Nakhon Chai Si' },
  { label: 'Amphoe Nakhon Luang', value: 'Amphoe Nakhon Luang' },
  { label: 'Amphoe Nakhon Thai', value: 'Amphoe Nakhon Thai' },
  { label: 'Amphoe Nam Khun', value: 'Amphoe Nam Khun' },
  { label: 'Amphoe Nam Kliang', value: 'Amphoe Nam Kliang' },
  { label: 'Amphoe Nam Nao', value: 'Amphoe Nam Nao' },
  { label: 'Amphoe Nam Pat', value: 'Amphoe Nam Pat' },
  { label: 'Amphoe Nam Phong', value: 'Amphoe Nam Phong' },
  { label: 'Amphoe Nam Som', value: 'Amphoe Nam Som' },
  { label: 'Amphoe Nam Yuen', value: 'Amphoe Nam Yuen' },
  { label: 'Amphoe Nang Rong', value: 'Amphoe Nang Rong' },
  { label: 'Amphoe Ngao', value: 'Amphoe Ngao' },
  { label: 'Amphoe Nikhom Kham Soi', value: 'Amphoe Nikhom Kham Soi' },
  { label: 'Amphoe Nikhom Nam Un', value: 'Amphoe Nikhom Nam Un' },
  { label: 'Amphoe Nikhom Phattana', value: 'Amphoe Nikhom Phattana' },
  { label: 'Amphoe Noen Kham', value: 'Amphoe Noen Kham' },
  { label: 'Amphoe Noen Maprang', value: 'Amphoe Noen Maprang' },
  { label: 'Amphoe Non Daeng', value: 'Amphoe Non Daeng' },
  { label: 'Amphoe Non Din Daeng', value: 'Amphoe Non Din Daeng' },
  { label: 'Amphoe Non Khun', value: 'Amphoe Non Khun' },
  { label: 'Amphoe Non Narai', value: 'Amphoe Non Narai' },
  { label: 'Amphoe Non Sa-At', value: 'Amphoe Non Sa-At' },
  { label: 'Amphoe Non Sang', value: 'Amphoe Non Sang' },
  { label: 'Amphoe Non Sila', value: 'Amphoe Non Sila' },
  { label: 'Amphoe Non Sung', value: 'Amphoe Non Sung' },
  { label: 'Amphoe Non Suwan', value: 'Amphoe Non Suwan' },
  { label: 'Amphoe Non Thai', value: 'Amphoe Non Thai' },
  { label: 'Amphoe Nong Bua', value: 'Amphoe Nong Bua' },
  { label: 'Amphoe Nong Bun Mak', value: 'Amphoe Nong Bun Mak' },
  { label: 'Amphoe Nong Chang', value: 'Amphoe Nong Chang' },
  { label: 'Amphoe Nong Chik', value: 'Amphoe Nong Chik' },
  { label: 'Amphoe Nong Don', value: 'Amphoe Nong Don' },
  { label: 'Amphoe Nong Han', value: 'Amphoe Nong Han' },
  { label: 'Amphoe Nong Hi', value: 'Amphoe Nong Hi' },
  { label: 'Amphoe Nong Hin', value: 'Amphoe Nong Hin' },
  { label: 'Amphoe Nong Hong', value: 'Amphoe Nong Hong' },
  { label: 'Amphoe Nong Khae', value: 'Amphoe Nong Khae' },
  { label: 'Amphoe Nong Khayang', value: 'Amphoe Nong Khayang' },
  { label: 'Amphoe Nong Ki', value: 'Amphoe Nong Ki' },
  { label: 'Amphoe Nong Kung Si', value: 'Amphoe Nong Kung Si' },
  { label: 'Amphoe Nong Mamong', value: 'Amphoe Nong Mamong' },
  { label: 'Amphoe Nong Muang', value: 'Amphoe Nong Muang' },
  { label: 'Amphoe Nong Muang Khai', value: 'Amphoe Nong Muang Khai' },
  { label: 'Amphoe Nong Na Kham', value: 'Amphoe Nong Na Kham' },
  { label: 'Amphoe Nong Phai', value: 'Amphoe Nong Phai' },
  { label: 'Amphoe Nong Phok', value: 'Amphoe Nong Phok' },
  { label: 'Amphoe Nong Prue', value: 'Amphoe Nong Prue' },
  { label: 'Amphoe Nong Ruea', value: 'Amphoe Nong Ruea' },
  { label: 'Amphoe Nong Saeng', value: 'Amphoe Nong Saeng' },
  { label: 'Amphoe Nong Saeng', value: 'Amphoe Nong Saeng' },
  { label: 'Amphoe Nong Song Hong', value: 'Amphoe Nong Song Hong' },
  { label: 'Amphoe Nong Suea', value: 'Amphoe Nong Suea' },
  { label: 'Amphoe Nong Sung', value: 'Amphoe Nong Sung' },
  { label: 'Amphoe Nong Wua So', value: 'Amphoe Nong Wua So' },
  { label: 'Amphoe Nong Ya Plong', value: 'Amphoe Nong Ya Plong' },
  { label: 'Amphoe Nong Yai', value: 'Amphoe Nong Yai' },
  { label: 'Amphoe Nong Yasai', value: 'Amphoe Nong Yasai' },
  { label: 'Amphoe Nopphitam', value: 'Amphoe Nopphitam' },
  { label: 'Amphoe Nuea Khlong', value: 'Amphoe Nuea Khlong' },
  { label: 'Amphoe Om Koi', value: 'Amphoe Om Koi' },
  { label: 'Amphoe Ongkharak', value: 'Amphoe Ongkharak' },
  { label: 'Amphoe Pa Bon', value: 'Amphoe Pa Bon' },
  { label: 'Amphoe Pa Daet', value: 'Amphoe Pa Daet' },
  { label: 'Amphoe Pa Kham', value: 'Amphoe Pa Kham' },
  { label: 'Amphoe Pa Mok', value: 'Amphoe Pa Mok' },
  { label: 'Amphoe Pa Phayom', value: 'Amphoe Pa Phayom' },
  { label: 'Amphoe Pa Sang', value: 'Amphoe Pa Sang' },
  { label: 'Amphoe Pa Tio', value: 'Amphoe Pa Tio' },
  { label: 'Amphoe Pai', value: 'Amphoe Pai' },
  { label: 'Amphoe Pak Chom', value: 'Amphoe Pak Chom' },
  { label: 'Amphoe Pak Chong', value: 'Amphoe Pak Chong' },
  { label: 'Amphoe Pak Khat', value: 'Amphoe Pak Khat' },
  { label: 'Amphoe Pak Kret', value: 'Amphoe Pak Kret' },
  { label: 'Amphoe Pak Phanang', value: 'Amphoe Pak Phanang' },
  { label: 'Amphoe Pak Phayun', value: 'Amphoe Pak Phayun' },
  { label: 'Amphoe Pak Phli', value: 'Amphoe Pak Phli' },
  { label: 'Amphoe Pak Tho', value: 'Amphoe Pak Tho' },
  { label: 'Amphoe Pak Thong Chai', value: 'Amphoe Pak Thong Chai' },
  { label: 'Amphoe Palian', value: 'Amphoe Palian' },
  { label: 'Amphoe Panare', value: 'Amphoe Panare' },
  { label: 'Amphoe Pang Mapha', value: 'Amphoe Pang Mapha' },
  { label: 'Amphoe Pang Sila Thong', value: 'Amphoe Pang Sila Thong' },
  { label: 'Amphoe Pathio', value: 'Amphoe Pathio' },
  { label: 'Amphoe Pathum Rat', value: 'Amphoe Pathum Rat' },
  { label: 'Amphoe Pathum Ratchawongsa', value: 'Amphoe Pathum Ratchawongsa' },
  { label: 'Amphoe Pha Khao', value: 'Amphoe Pha Khao' },
  { label: 'Amphoe Phachi', value: 'Amphoe Phachi' },
  { label: 'Amphoe Phai Sali', value: 'Amphoe Phai Sali' },
  { label: 'Amphoe Phak Hai', value: 'Amphoe Phak Hai' },
  { label: 'Amphoe Phan', value: 'Amphoe Phan' },
  { label: 'Amphoe Phan Thong', value: 'Amphoe Phan Thong' },
  { label: 'Amphoe Phana', value: 'Amphoe Phana' },
  { label: 'Amphoe Phanat Nikhom', value: 'Amphoe Phanat Nikhom' },
  { label: 'Amphoe Phang Khon', value: 'Amphoe Phang Khon' },
  { label: 'Amphoe Phanna Nikhom', value: 'Amphoe Phanna Nikhom' },
  { label: 'Amphoe Phanom', value: 'Amphoe Phanom' },
  { label: 'Amphoe Phanom Dong Rak', value: 'Amphoe Phanom Dong Rak' },
  { label: 'Amphoe Phanom Phrai', value: 'Amphoe Phanom Phrai' },
  { label: 'Amphoe Phanom Sarakham', value: 'Amphoe Phanom Sarakham' },
  { label: 'Amphoe Phanom Thuan', value: 'Amphoe Phanom Thuan' },
  { label: 'Amphoe Phato', value: 'Amphoe Phato' },
  { label: 'Amphoe Phatthana Nikhom', value: 'Amphoe Phatthana Nikhom' },
  { label: 'Amphoe Phaya Mengrai', value: 'Amphoe Phaya Mengrai' },
  { label: 'Amphoe Phayakkhaphum Phisai', value: 'Amphoe Phayakkhaphum Phisai' },
  { label: 'Amphoe Phayu', value: 'Amphoe Phayu' },
  { label: 'Amphoe Phayuha Khiri', value: 'Amphoe Phayuha Khiri' },
  { label: 'Amphoe Phen', value: 'Amphoe Phen' },
  { label: 'Amphoe Phibun Mangsahan', value: 'Amphoe Phibun Mangsahan' },
  { label: 'Amphoe Phibun Rak', value: 'Amphoe Phibun Rak' },
  { label: 'Amphoe Phichai', value: 'Amphoe Phichai' },
  { label: 'Amphoe Phimai', value: 'Amphoe Phimai' },
  { label: 'Amphoe Phipun', value: 'Amphoe Phipun' },
  { label: 'Amphoe Phlapphla Chai', value: 'Amphoe Phlapphla Chai' },
  { label: 'Amphoe Pho Chai', value: 'Amphoe Pho Chai' },
  { label: 'Amphoe Pho Prathap Chang', value: 'Amphoe Pho Prathap Chang' },
  { label: 'Amphoe Pho Sai', value: 'Amphoe Pho Sai' },
  { label: 'Amphoe Pho Si Suwan', value: 'Amphoe Pho Si Suwan' },
  { label: 'Amphoe Pho Tak', value: 'Amphoe Pho Tak' },
  { label: 'Amphoe Pho Thale', value: 'Amphoe Pho Thale' },
  { label: 'Amphoe Pho Thong', value: 'Amphoe Pho Thong' },
  { label: 'Amphoe Phon', value: 'Amphoe Phon' },
  { label: 'Amphoe Phon Charoen', value: 'Amphoe Phon Charoen' },
  { label: 'Amphoe Phon Na Kaeo', value: 'Amphoe Phon Na Kaeo' },
  { label: 'Amphoe Phon Sai', value: 'Amphoe Phon Sai' },
  { label: 'Amphoe Phon Sawan', value: 'Amphoe Phon Sawan' },
  { label: 'Amphoe Phon Thong', value: 'Amphoe Phon Thong' },
  { label: 'Amphoe Phonphisai', value: 'Amphoe Phonphisai' },
  { label: 'Amphoe Phop Phra', value: 'Amphoe Phop Phra' },
  { label: 'Amphoe Photharam', value: 'Amphoe Photharam' },
  { label: 'Amphoe Phra Nakhon Si Ayutthaya', value: 'Amphoe Phra Nakhon Si Ayutthaya' },
  { label: 'Amphoe Phra Phrom', value: 'Amphoe Phra Phrom' },
  { label: 'Amphoe Phra Phutthabat', value: 'Amphoe Phra Phutthabat' },
  { label: 'Amphoe Phra Pradaeng', value: 'Amphoe Phra Pradaeng' },
  { label: 'Amphoe Phra Samut Chedi', value: 'Amphoe Phra Samut Chedi' },
  { label: 'Amphoe Phra Thong Kham', value: 'Amphoe Phra Thong Kham' },
  { label: 'Amphoe Phra Yuen', value: 'Amphoe Phra Yuen' },
  { label: 'Amphoe Phrai Bueng', value: 'Amphoe Phrai Bueng' },
  { label: 'Amphoe Phran Kratai', value: 'Amphoe Phran Kratai' },
  { label: 'Amphoe Phrao', value: 'Amphoe Phrao' },
  { label: 'Amphoe Phrasaeng', value: 'Amphoe Phrasaeng' },
  { label: 'Amphoe Phrom Buri', value: 'Amphoe Phrom Buri' },
  { label: 'Amphoe Phrom Khiri', value: 'Amphoe Phrom Khiri' },
  { label: 'Amphoe Phrom Phiram', value: 'Amphoe Phrom Phiram' },
  { label: 'Amphoe Phu Kam Yao', value: 'Amphoe Phu Kam Yao' },
  { label: 'Amphoe Phu Kradueng', value: 'Amphoe Phu Kradueng' },
  { label: 'Amphoe Phu Luang', value: 'Amphoe Phu Luang' },
  { label: 'Amphoe Phu Pha Man', value: 'Amphoe Phu Pha Man' },
  { label: 'Amphoe Phu Phan', value: 'Amphoe Phu Phan' },
  { label: 'Amphoe Phu Phiang', value: 'Amphoe Phu Phiang' },
  { label: 'Amphoe Phu Ruea', value: 'Amphoe Phu Ruea' },
  { label: 'Amphoe Phu Sang', value: 'Amphoe Phu Sang' },
  { label: 'Amphoe Phu Sing', value: 'Amphoe Phu Sing' },
  { label: 'Amphoe Phu Wiang', value: 'Amphoe Phu Wiang' },
  { label: 'Amphoe Phunphin', value: 'Amphoe Phunphin' },
  { label: 'Amphoe Phutthaisong', value: 'Amphoe Phutthaisong' },
  { label: 'Amphoe Phutthamonthon', value: 'Amphoe Phutthamonthon' },
  { label: 'Amphoe Pla Pak', value: 'Amphoe Pla Pak' },
  { label: 'Amphoe Plaeng Yao', value: 'Amphoe Plaeng Yao' },
  { label: 'Amphoe Plai Phraya', value: 'Amphoe Plai Phraya' },
  { label: 'Amphoe Pluak Daeng', value: 'Amphoe Pluak Daeng' },
  { label: 'Amphoe Pong', value: 'Amphoe Pong' },
  { label: 'Amphoe Pong Nam Ron', value: 'Amphoe Pong Nam Ron' },
  { label: 'Amphoe Prachak Sinlapakhom', value: 'Amphoe Prachak Sinlapakhom' },
  { label: 'Amphoe Prachantakham', value: 'Amphoe Prachantakham' },
  { label: 'Amphoe Prakhon Chai', value: 'Amphoe Prakhon Chai' },
  { label: 'Amphoe Pran Buri', value: 'Amphoe Pran Buri' },
  { label: 'Amphoe Prang Ku', value: 'Amphoe Prang Ku' },
  { label: 'Amphoe Prasat', value: 'Amphoe Prasat' },
  { label: 'Amphoe Prathai', value: 'Amphoe Prathai' },
  { label: 'Amphoe Pua', value: 'Amphoe Pua' },
  { label: 'Amphoe Pueai Noi', value: 'Amphoe Pueai Noi' },
  { label: 'Amphoe Raman', value: 'Amphoe Raman' },
  { label: 'Amphoe Ra-Ngae', value: 'Amphoe Ra-Ngae' },
  { label: 'Amphoe Ranot', value: 'Amphoe Ranot' },
  { label: 'Amphoe Rasi Salai', value: 'Amphoe Rasi Salai' },
  { label: 'Amphoe Ratchasan', value: 'Amphoe Ratchasan' },
  { label: 'Amphoe Ratsada', value: 'Amphoe Ratsada' },
  { label: 'Amphoe Rattanaburi', value: 'Amphoe Rattanaburi' },
  { label: 'Amphoe Rattanawapi', value: 'Amphoe Rattanawapi' },
  { label: 'Amphoe Rattaphum', value: 'Amphoe Rattaphum' },
  { label: 'Amphoe Renu Nakhon', value: 'Amphoe Renu Nakhon' },
  { label: 'Amphoe Ron Phibun', value: 'Amphoe Ron Phibun' },
  { label: 'Amphoe Rong Kham', value: 'Amphoe Rong Kham' },
  { label: 'Amphoe Rong Kwang', value: 'Amphoe Rong Kwang' },
  { label: 'Amphoe Rueso', value: 'Amphoe Rueso' },
  { label: 'Amphoe Sa Bot', value: 'Amphoe Sa Bot' },
  { label: 'Amphoe Sa Khrai', value: 'Amphoe Sa Khrai' },
  { label: 'Amphoe Saba Yoi', value: 'Amphoe Saba Yoi' },
  { label: 'Amphoe Sadao', value: 'Amphoe Sadao' },
  { label: 'Amphoe Sahatsakhan', value: 'Amphoe Sahatsakhan' },
  { label: 'Amphoe Sai Buri', value: 'Amphoe Sai Buri' },
  { label: 'Amphoe Sai Mun', value: 'Amphoe Sai Mun' },
  { label: 'Amphoe Sai Ngam', value: 'Amphoe Sai Ngam' },
  { label: 'Amphoe Sai Noi', value: 'Amphoe Sai Noi' },
  { label: 'Amphoe Sai Thong Watthana', value: 'Amphoe Sai Thong Watthana' },
  { label: 'Amphoe Sai Yok', value: 'Amphoe Sai Yok' },
  { label: 'Amphoe Sak Lek', value: 'Amphoe Sak Lek' },
  { label: 'Amphoe Sam Chai', value: 'Amphoe Sam Chai' },
  { label: 'Amphoe Sam Phran', value: 'Amphoe Sam Phran' },
  { label: 'Amphoe Sam Roi Yot', value: 'Amphoe Sam Roi Yot' },
  { label: 'Amphoe Sam Sung', value: 'Amphoe Sam Sung' },
  { label: 'Amphoe Samoeng', value: 'Amphoe Samoeng' },
  { label: 'Amphoe Samrong', value: 'Amphoe Samrong' },
  { label: 'Amphoe Samrong Thap', value: 'Amphoe Samrong Thap' },
  { label: 'Amphoe San Kamphaeng', value: 'Amphoe San Kamphaeng' },
  { label: 'Amphoe San Pa Tong', value: 'Amphoe San Pa Tong' },
  { label: 'Amphoe San Sai', value: 'Amphoe San Sai' },
  { label: 'Amphoe Sanam Chai Khet', value: 'Amphoe Sanam Chai Khet' },
  { label: 'Amphoe Sang Khom', value: 'Amphoe Sang Khom' },
  { label: 'Amphoe Sangkha', value: 'Amphoe Sangkha' },
  { label: 'Amphoe Sangkhla Buri', value: 'Amphoe Sangkhla Buri' },
  { label: 'Amphoe Sangkhom', value: 'Amphoe Sangkhom' },
  { label: 'Amphoe Sankhaburi', value: 'Amphoe Sankhaburi' },
  { label: 'Amphoe Sanom', value: 'Amphoe Sanom' },
  { label: 'Amphoe Santi Suk', value: 'Amphoe Santi Suk' },
  { label: 'Amphoe Sao Hai', value: 'Amphoe Sao Hai' },
  { label: 'Amphoe Sapphaya', value: 'Amphoe Sapphaya' },
  { label: 'Amphoe Saraphi', value: 'Amphoe Saraphi' },
  { label: 'Amphoe Sathing Phra', value: 'Amphoe Sathing Phra' },
  { label: 'Amphoe Sattahip', value: 'Amphoe Sattahip' },
  { label: 'Amphoe Satuek', value: 'Amphoe Satuek' },
  { label: 'Amphoe Sawaeng Ha', value: 'Amphoe Sawaeng Ha' },
  { label: 'Amphoe Sawang Arom', value: 'Amphoe Sawang Arom' },
  { label: 'Amphoe Sawang Daen Din', value: 'Amphoe Sawang Daen Din' },
  { label: 'Amphoe Sawang Wirawong', value: 'Amphoe Sawang Wirawong' },
  { label: 'Amphoe Sawankhalok', value: 'Amphoe Sawankhalok' },
  { label: 'Amphoe Sawi', value: 'Amphoe Sawi' },
  { label: 'Amphoe Seka', value: 'Amphoe Seka' },
  { label: 'Amphoe Selaphum', value: 'Amphoe Selaphum' },
  { label: 'Amphoe Sena', value: 'Amphoe Sena' },
  { label: 'Amphoe Senangkhanikhom', value: 'Amphoe Senangkhanikhom' },
  { label: 'Amphoe Si Banphot', value: 'Amphoe Si Banphot' },
  { label: 'Amphoe Si Bun Rueang', value: 'Amphoe Si Bun Rueang' },
  { label: 'Amphoe Si Chiang Mai', value: 'Amphoe Si Chiang Mai' },
  { label: 'Amphoe Si Chom Phu', value: 'Amphoe Si Chom Phu' },
  { label: 'Amphoe Si Khoraphum', value: 'Amphoe Si Khoraphum' },
  { label: 'Amphoe Si Maha Phot', value: 'Amphoe Si Maha Phot' },
  { label: 'Amphoe Si Mahosot', value: 'Amphoe Si Mahosot' },
  { label: 'Amphoe Si Mueang Mai', value: 'Amphoe Si Mueang Mai' },
  { label: 'Amphoe Si Nakhon', value: 'Amphoe Si Nakhon' },
  { label: 'Amphoe Si Narong', value: 'Amphoe Si Narong' },
  { label: 'Amphoe Si Prachan', value: 'Amphoe Si Prachan' },
  { label: 'Amphoe Si Racha', value: 'Amphoe Si Racha' },
  { label: 'Amphoe Si Rattana', value: 'Amphoe Si Rattana' },
  { label: 'Amphoe Si Sakhon', value: 'Amphoe Si Sakhon' },
  { label: 'Amphoe Si Samrong', value: 'Amphoe Si Samrong' },
  { label: 'Amphoe Si Satchanalai', value: 'Amphoe Si Satchanalai' },
  { label: 'Amphoe Si Sawat', value: 'Amphoe Si Sawat' },
  { label: 'Amphoe Si Somdet', value: 'Amphoe Si Somdet' },
  { label: 'Amphoe Si Songkhram', value: 'Amphoe Si Songkhram' },
  { label: 'Amphoe Si That', value: 'Amphoe Si That' },
  { label: 'Amphoe Si Thep', value: 'Amphoe Si Thep' },
  { label: 'Amphoe Si Wilai', value: 'Amphoe Si Wilai' },
  { label: 'Amphoe Sichon', value: 'Amphoe Sichon' },
  { label: 'Amphoe Sida', value: 'Amphoe Sida' },
  { label: 'Amphoe Sikao', value: 'Amphoe Sikao' },
  { label: 'Amphoe Sikhio', value: 'Amphoe Sikhio' },
  { label: 'Amphoe Sikhiu', value: 'Amphoe Sikhiu' },
  { label: 'Amphoe Sila Lat', value: 'Amphoe Sila Lat' },
  { label: 'Amphoe Singhanakhon', value: 'Amphoe Singhanakhon' },
  { label: 'Amphoe Sirindhorn', value: 'Amphoe Sirindhorn' },
  { label: 'Amphoe So Phisai', value: 'Amphoe So Phisai' },
  { label: 'Amphoe Soem Ngam', value: 'Amphoe Soem Ngam' },
  { label: 'Amphoe Soeng Sang', value: 'Amphoe Soeng Sang' },
  { label: 'Amphoe Soi Dao', value: 'Amphoe Soi Dao' },
  { label: 'Amphoe Somdet', value: 'Amphoe Somdet' },
  { label: 'Amphoe Song', value: 'Amphoe Song' },
  { label: 'Amphoe Song Dao', value: 'Amphoe Song Dao' },
  { label: 'Amphoe Song Khwae', value: 'Amphoe Song Khwae' },
  { label: 'Amphoe Song Phi Nong', value: 'Amphoe Song Phi Nong' },
  { label: 'Amphoe Sop Moei', value: 'Amphoe Sop Moei' },
  { label: 'Amphoe Sop Prap', value: 'Amphoe Sop Prap' },
  { label: 'Amphoe Srinagarindra', value: 'Amphoe Srinagarindra' },
  { label: 'Amphoe Suan Phueng', value: 'Amphoe Suan Phueng' },
  { label: 'Amphoe Suk Samran', value: 'Amphoe Suk Samran' },
  { label: 'Amphoe Sukhirin', value: 'Amphoe Sukhirin' },
  { label: 'Amphoe Sung Men', value: 'Amphoe Sung Men' },
  { label: 'Amphoe Sung Noen', value: 'Amphoe Sung Noen' },
  { label: 'Amphoe Su-Ngai Kolok', value: 'Amphoe Su-Ngai Kolok' },
  { label: 'Amphoe Su-Ngai Padi', value: 'Amphoe Su-Ngai Padi' },
  { label: 'Amphoe Suwannakhuha', value: 'Amphoe Suwannakhuha' },
  { label: 'Amphoe Suwannaphum', value: 'Amphoe Suwannaphum' },
  { label: 'Amphoe Ta Phraya', value: 'Amphoe Ta Phraya' },
  { label: 'Amphoe Tak Bai', value: 'Amphoe Tak Bai' },
  { label: 'Amphoe Tak Fa', value: 'Amphoe Tak Fa' },
  { label: 'Amphoe Takhli', value: 'Amphoe Takhli' },
  { label: 'Amphoe Takua Pa', value: 'Amphoe Takua Pa' },
  { label: 'Amphoe Takua Thung', value: 'Amphoe Takua Thung' },
  { label: 'Amphoe Tamot', value: 'Amphoe Tamot' },
  { label: 'Amphoe Tan Sum', value: 'Amphoe Tan Sum' },
  { label: 'Amphoe Tao Ngoi', value: 'Amphoe Tao Ngoi' },
  { label: 'Amphoe Taphan Hin', value: 'Amphoe Taphan Hin' },
  { label: 'Amphoe Tha Bo', value: 'Amphoe Tha Bo' },
  { label: 'Amphoe Tha Chana', value: 'Amphoe Tha Chana' },
  { label: 'Amphoe Tha Chang', value: 'Amphoe Tha Chang' },
  { label: 'Amphoe Tha Chang', value: 'Amphoe Tha Chang' },
  { label: 'Amphoe Tha Khantho', value: 'Amphoe Tha Khantho' },
  { label: 'Amphoe Tha Li', value: 'Amphoe Tha Li' },
  { label: 'Amphoe Tha Luang', value: 'Amphoe Tha Luang' },
  { label: 'Amphoe Tha Mai', value: 'Amphoe Tha Mai' },
  { label: 'Amphoe Tha Maka', value: 'Amphoe Tha Maka' },
  { label: 'Amphoe Tha Muang', value: 'Amphoe Tha Muang' },
  { label: 'Amphoe Tha Phae', value: 'Amphoe Tha Phae' },
  { label: 'Amphoe Tha Pla', value: 'Amphoe Tha Pla' },
  { label: 'Amphoe Tha Ruea', value: 'Amphoe Tha Ruea' },
  { label: 'Amphoe Tha Sae', value: 'Amphoe Tha Sae' },
  { label: 'Amphoe Tha Sala', value: 'Amphoe Tha Sala' },
  { label: 'Amphoe Tha Song Yang', value: 'Amphoe Tha Song Yang' },
  { label: 'Amphoe Tha Takiap', value: 'Amphoe Tha Takiap' },
  { label: 'Amphoe Tha Tako', value: 'Amphoe Tha Tako' },
  { label: 'Amphoe Tha Tum', value: 'Amphoe Tha Tum' },
  { label: 'Amphoe Tha Uthen', value: 'Amphoe Tha Uthen' },
  { label: 'Amphoe Tha Wang Pha', value: 'Amphoe Tha Wang Pha' },
  { label: 'Amphoe Tha Wung', value: 'Amphoe Tha Wung' },
  { label: 'Amphoe Tha Yang', value: 'Amphoe Tha Yang' },
  { label: 'Amphoe Thai Charoen', value: 'Amphoe Thai Charoen' },
  { label: 'Amphoe Thai Mueang', value: 'Amphoe Thai Mueang' },
  { label: 'Amphoe Thalang', value: 'Amphoe Thalang' },
  { label: 'Amphoe Tham Phannara', value: 'Amphoe Tham Phannara' },
  { label: 'Amphoe Than To', value: 'Amphoe Than To' },
  { label: 'Amphoe Thanyaburi', value: 'Amphoe Thanyaburi' },
  { label: 'Amphoe Thap Khlo', value: 'Amphoe Thap Khlo' },
  { label: 'Amphoe Thap Put', value: 'Amphoe Thap Put' },
  { label: 'Amphoe Thap Sakae', value: 'Amphoe Thap Sakae' },
  { label: 'Amphoe Thap Than', value: 'Amphoe Thap Than' },
  { label: 'Amphoe That Phanom', value: 'Amphoe That Phanom' },
  { label: 'Amphoe Thawatchaburi', value: 'Amphoe Thawatchaburi' },
  { label: 'Amphoe Thepha', value: 'Amphoe Thepha' },
  { label: 'Amphoe Thepharak', value: 'Amphoe Thepharak' },
  { label: 'Amphoe Thoen', value: 'Amphoe Thoen' },
  { label: 'Amphoe Thoeng', value: 'Amphoe Thoeng' },
  { label: 'Amphoe Thong Pha Phum', value: 'Amphoe Thong Pha Phum' },
  { label: 'Amphoe Thong Saen Khan', value: 'Amphoe Thong Saen Khan' },
  { label: 'Amphoe Thung Chang', value: 'Amphoe Thung Chang' },
  { label: 'Amphoe Thung Fon', value: 'Amphoe Thung Fon' },
  { label: 'Amphoe Thung Hua Chang', value: 'Amphoe Thung Hua Chang' },
  { label: 'Amphoe Thung Khao Luang', value: 'Amphoe Thung Khao Luang' },
  { label: 'Amphoe Thung Saliam', value: 'Amphoe Thung Saliam' },
  { label: 'Amphoe Thung Si Udom', value: 'Amphoe Thung Si Udom' },
  { label: 'Amphoe Thung Song', value: 'Amphoe Thung Song' },
  { label: 'Amphoe Thung Tako', value: 'Amphoe Thung Tako' },
  { label: 'Amphoe Thung Wa', value: 'Amphoe Thung Wa' },
  { label: 'Amphoe Thung Yai', value: 'Amphoe Thung Yai' },
  { label: 'Amphoe Thung Yang Daeng', value: 'Amphoe Thung Yang Daeng' },
  { label: 'Amphoe Trakan Phuet Phon', value: 'Amphoe Trakan Phuet Phon' },
  { label: 'Amphoe Tron', value: 'Amphoe Tron' },
  { label: 'Amphoe U Thong', value: 'Amphoe U Thong' },
  { label: 'Amphoe Ubolratana', value: 'Amphoe Ubolratana' },
  { label: 'Amphoe Umphang', value: 'Amphoe Umphang' },
  { label: 'Amphoe Uthai', value: 'Amphoe Uthai' },
  { label: 'Amphoe Uthumphon Phisai', value: 'Amphoe Uthumphon Phisai' },
  { label: 'Amphoe Wachira Barami', value: 'Amphoe Wachira Barami' },
  { label: 'Amphoe Waeng', value: 'Amphoe Waeng' },
  { label: 'Amphoe Waeng Noi', value: 'Amphoe Waeng Noi' },
  { label: 'Amphoe Waeng Yai', value: 'Amphoe Waeng Yai' },
  { label: 'Amphoe Wan Yai', value: 'Amphoe Wan Yai' },
  { label: 'Amphoe Wang Chan', value: 'Amphoe Wang Chan' },
  { label: 'Amphoe Wang Chao', value: 'Amphoe Wang Chao' },
  { label: 'Amphoe Wang Chin', value: 'Amphoe Wang Chin' },
  { label: 'Amphoe Wang Hin', value: 'Amphoe Wang Hin' },
  { label: 'Amphoe Wang Muang', value: 'Amphoe Wang Muang' },
  { label: 'Amphoe Wang Nam Khiao', value: 'Amphoe Wang Nam Khiao' },
  { label: 'Amphoe Wang Nam Yen', value: 'Amphoe Wang Nam Yen' },
  { label: 'Amphoe Wang Noi', value: 'Amphoe Wang Noi' },
  { label: 'Amphoe Wang Nuea', value: 'Amphoe Wang Nuea' },
  { label: 'Amphoe Wang Pong', value: 'Amphoe Wang Pong' },
  { label: 'Amphoe Wang Sai Phun', value: 'Amphoe Wang Sai Phun' },
  { label: 'Amphoe Wang Sam Mo', value: 'Amphoe Wang Sam Mo' },
  { label: 'Amphoe Wang Saphung', value: 'Amphoe Wang Saphung' },
  { label: 'Amphoe Wang Sombun', value: 'Amphoe Wang Sombun' },
  { label: 'Amphoe Wang Thong', value: 'Amphoe Wang Thong' },
  { label: 'Amphoe Wang Wiset', value: 'Amphoe Wang Wiset' },
  { label: 'Amphoe Wangyang', value: 'Amphoe Wangyang' },
  { label: 'Amphoe Wanon Niwat', value: 'Amphoe Wanon Niwat' },
  { label: 'Amphoe Wapi Pathum', value: 'Amphoe Wapi Pathum' },
  { label: 'Amphoe Warin Chamrap', value: 'Amphoe Warin Chamrap' },
  { label: 'Amphoe Waritchaphum', value: 'Amphoe Waritchaphum' },
  { label: 'Amphoe Wat Bot', value: 'Amphoe Wat Bot' },
  { label: 'Amphoe Wat Phleng', value: 'Amphoe Wat Phleng' },
  { label: 'Amphoe Wat Sing', value: 'Amphoe Wat Sing' },
  { label: 'Amphoe Watthana Nakhon', value: 'Amphoe Watthana Nakhon' },
  { label: 'Amphoe Wiang Chai', value: 'Amphoe Wiang Chai' },
  { label: 'Amphoe Wiang Chiang Rung', value: 'Amphoe Wiang Chiang Rung' },
  { label: 'Amphoe Wiang Haeng', value: 'Amphoe Wiang Haeng' },
  { label: 'Amphoe Wiang Kaen', value: 'Amphoe Wiang Kaen' },
  { label: 'Amphoe Wiang Kao', value: 'Amphoe Wiang Kao' },
  { label: 'Amphoe Wiang Nong Long', value: 'Amphoe Wiang Nong Long' },
  { label: 'Amphoe Wiang Pa Pao', value: 'Amphoe Wiang Pa Pao' },
  { label: 'Amphoe Wiang Sa', value: 'Amphoe Wiang Sa' },
  { label: 'Amphoe Wiang Sa', value: 'Amphoe Wiang Sa' },
  { label: 'Amphoe Wichian Buri', value: 'Amphoe Wichian Buri' },
  { label: 'Amphoe Wihan Daeng', value: 'Amphoe Wihan Daeng' },
  { label: 'Amphoe Wiphawadi', value: 'Amphoe Wiphawadi' },
  { label: 'Amphoe Wiset Chai Chan', value: 'Amphoe Wiset Chai Chan' },
  { label: 'Amphoe Yaha', value: 'Amphoe Yaha' },
  { label: 'Amphoe Yan Ta Khao', value: 'Amphoe Yan Ta Khao' },
  { label: 'Amphoe Yang Chum Noi', value: 'Amphoe Yang Chum Noi' },
  { label: 'Amphoe Yang Si Surat', value: 'Amphoe Yang Si Surat' },
  { label: 'Amphoe Yang Talat', value: 'Amphoe Yang Talat' },
  { label: 'Amphoe Yarang', value: 'Amphoe Yarang' },
  { label: 'Amphoe Yaring', value: 'Amphoe Yaring' },
  { label: 'Amphoe Yi-Ngo', value: 'Amphoe Yi-Ngo' },
  { label: 'Amplepuis', value: 'Amplepuis' },
  { label: 'Ampliación Colonia Lázaro Cárdenas', value: 'Ampliación Colonia Lázaro Cárdenas' },
  { label: 'Ampliación De La Laguna', value: 'Ampliación De La Laguna' },
  { label: 'Ampliación La Hincada', value: 'Ampliación La Hincada' },
  { label: 'Ampliación San Mateo (Colonia Solidaridad)', value: 'Ampliación San Mateo (Colonia Solidaridad)' },
  { label: 'Ampliación Tezoyuca', value: 'Ampliación Tezoyuca' },
  { label: 'Ampolla L', value: 'Ampolla L' },
  { label: 'Amposta', value: 'Amposta' },
  { label: 'Ampthill', value: 'Ampthill' },
  { label: 'Ampudia', value: 'Ampudia' },
  { label: 'Ampuero', value: 'Ampuero' },
  { label: 'Ampuis', value: 'Ampuis' },
  { label: 'Ampusungan', value: 'Ampusungan' },
  { label: 'Ampusungan', value: 'Ampusungan' },
  { label: 'Amqui', value: 'Amqui' },
  { label: 'Amrān', value: 'Amrān' },
  { label: 'Amras', value: 'Amras' },
  { label: 'Amravati', value: 'Amravati' },
  { label: 'Amreli', value: 'Amreli' },
  { label: 'Amriswil', value: 'Amriswil' },
  { label: 'Amritsar', value: 'Amritsar' },
  { label: 'Amroha', value: 'Amroha' },
  { label: 'Amroli', value: 'Amroli' },
  { label: 'Amstelveen', value: 'Amstelveen' },
  { label: 'Amstenrade', value: 'Amstenrade' },
  { label: 'Amsterdam', value: 'Amsterdam' },
  { label: 'Amsterdam', value: 'Amsterdam' },
  { label: 'Amsterdam-Zuidoost', value: 'Amsterdam-Zuidoost' },
  { label: 'Amstetten', value: 'Amstetten' },
  { label: 'Amstetten', value: 'Amstetten' },
  { label: 'Amta', value: 'Amta' },
  { label: 'Amtala', value: 'Amtala' },
  { label: 'Amtzell', value: 'Amtzell' },
  { label: 'Amucao', value: 'Amucao' },
  { label: 'Amucao', value: 'Amucao' },
  { label: 'Amuco De La Reforma', value: 'Amuco De La Reforma' },
  { label: 'Amudalavalasa', value: 'Amudalavalasa' },
  { label: 'Amudat', value: 'Amudat' },
  { label: 'Amude', value: 'Amude' },
  { label: 'Amulung', value: 'Amulung' },
  { label: 'Amulung', value: 'Amulung' },
  { label: 'Amuñgan', value: 'Amuñgan' },
  { label: 'Amuñgan', value: 'Amuñgan' },
  { label: 'Amuntai', value: 'Amuntai' },
  { label: 'Amuri', value: 'Amuri' },
  { label: 'Amurrio', value: 'Amurrio' },
  { label: 'Amursk', value: 'Amursk' },
  { label: 'Amurskoye', value: 'Amurskoye' },
  { label: 'Amurzet', value: 'Amurzet' },
  { label: 'Amusco', value: 'Amusco' },
  { label: 'Amusquillo', value: 'Amusquillo' },
  { label: 'Amuwo-Odofin', value: 'Amuwo-Odofin' },
  { label: 'Amvrosiivka Raion', value: 'Amvrosiivka Raion' },
  { label: 'Amvrosiyivka', value: 'Amvrosiyivka' },
  { label: 'Amygdaleónas', value: 'Amygdaleónas' },
  { label: 'Amýntaio', value: 'Amýntaio' },
  { label: 'Amzacea', value: 'Amzacea' },
  { label: 'Amzajerd', value: 'Amzajerd' },
  { label: 'Amzya', value: 'Amzya' },
  { label: 'An Cabhán', value: 'An Cabhán' },
  { label: 'An Clár', value: 'An Clár' },
  { label: 'An Iarmhí', value: 'An Iarmhí' },
  { label: 'An Longfort', value: 'An Longfort' },
  { label: 'An Mhí', value: 'An Mhí' },
  { label: 'An Muileann Gcearr', value: 'An Muileann Gcearr' },
  { label: 'An Nabk', value: 'An Nabk' },
  { label: 'An Nādirah', value: 'An Nādirah' },
  { label: 'An Najamiyah', value: 'An Najamiyah' },
  { label: 'An Nuhūd', value: 'An Nuhūd' },
  { label: 'An Ros', value: 'An Ros' },
  { label: 'Anabad', value: 'Anabad' },
  { label: 'Anabar', value: 'Anabar' },
  { label: 'Anabarskiy Rayon', value: 'Anabarskiy Rayon' },
  { label: 'Anacapri', value: 'Anacapri' },
  { label: 'Anaco', value: 'Anaco' },
  { label: 'Anaconda', value: 'Anaconda' },
  { label: 'Anacortes', value: 'Anacortes' },
  { label: 'Anadarko', value: 'Anadarko' },
  { label: 'Anadia', value: 'Anadia' },
  { label: 'Anadia', value: 'Anadia' },
  { label: 'Anadón', value: 'Anadón' },
  { label: 'Anadyr', value: 'Anadyr' },
  { label: 'Anadyrskiy Rayon', value: 'Anadyrskiy Rayon' },
  { label: 'Anáfi', value: 'Anáfi' },
  { label: 'Anagé', value: 'Anagé' },
  { label: 'Anagni', value: 'Anagni' },
  { label: 'Anah', value: 'Anah' },
  { label: 'Anahawan', value: 'Anahawan' },
  { label: 'Anahawan', value: 'Anahawan' },
  { label: 'Anaheim', value: 'Anaheim' },
  { label: 'Anahola', value: 'Anahola' },
  { label: 'Anahuac', value: 'Anahuac' },
  { label: 'Anahuac', value: 'Anahuac' },
  { label: 'Anáhuac', value: 'Anáhuac' },
  { label: 'Anáhuac', value: 'Anáhuac' },
  { label: 'Anáhuac', value: 'Anáhuac' },
  { label: 'Anahy', value: 'Anahy' },
  { label: 'Anajás', value: 'Anajás' },
  { label: 'Anajatuba', value: 'Anajatuba' },
  { label: 'Anak', value: 'Anak' },
  { label: 'Anakan', value: 'Anakan' },
  { label: 'Anakapalle', value: 'Anakapalle' },
  { label: 'Anakasiá', value: 'Anakasiá' },
  { label: 'Analândia', value: 'Analândia' },
  { label: 'Analco', value: 'Analco' },
  { label: 'Analco De Ponciano Arriaga (Santa Cruz Analco)', value: 'Analco De Ponciano Arriaga (Santa Cruz Analco)' },
  { label: 'Anamã', value: 'Anamã' },
  { label: 'Anamalais', value: 'Anamalais' },
  { label: 'Anambongan', value: 'Anambongan' },
  { label: 'Anambongan', value: 'Anambongan' },
  { label: 'Anamorós', value: 'Anamorós' },
  { label: 'Anamosa', value: 'Anamosa' },
  { label: 'Anamur', value: 'Anamur' },
  { label: 'Anan', value: 'Anan' },
  { label: 'Anan Shi', value: 'Anan Shi' },
  { label: 'Añana', value: 'Añana' },
  { label: 'Ananás', value: 'Ananás' },
  { label: 'Anand', value: 'Anand' },
  { label: 'Anandnagar', value: 'Anandnagar' },
  { label: 'Anandpur Sahib', value: 'Anandpur Sahib' },
  { label: 'Anangu Pitjantjatjara', value: 'Anangu Pitjantjatjara' },
  { label: 'Ananindeua', value: 'Ananindeua' },
  { label: 'Anantapur', value: 'Anantapur' },
  { label: 'Anantnag', value: 'Anantnag' },
  { label: 'Anao', value: 'Anao' },
  { label: 'Anao', value: 'Anao' },
  { label: 'Anapa', value: 'Anapa' },
  { label: 'Anapoima', value: 'Anapoima' },
  { label: 'Anápolis', value: 'Anápolis' },
  { label: 'Anapskaya', value: 'Anapskaya' },
  { label: 'Anapu', value: 'Anapu' },
  { label: 'Anapurus', value: 'Anapurus' },
  { label: 'Anar', value: 'Anar' },
  { label: 'Anaráchi', value: 'Anaráchi' },
  { label: 'Anarak', value: 'Anarak' },
  { label: 'Anarcs', value: 'Anarcs' },
  { label: 'Anarestan', value: 'Anarestan' },
  { label: 'Añasco', value: 'Añasco' },
  { label: 'Añasco', value: 'Añasco' },
  { label: 'Anastácio', value: 'Anastácio' },
  { label: 'Anastasiyevka', value: 'Anastasiyevka' },
  { label: 'Anastasiyevskaya', value: 'Anastasiyevskaya' },
  { label: 'Anat Al Qadīmah', value: 'Anat Al Qadīmah' },
  { label: 'Anatolí', value: 'Anatolí' },
  { label: 'Anatolikó', value: 'Anatolikó' },
  { label: 'Añatuya', value: 'Añatuya' },
  { label: 'Anaurilândia', value: 'Anaurilândia' },
  { label: 'Anávra', value: 'Anávra' },
  { label: 'Anávyssos', value: 'Anávyssos' },
  { label: 'Anaya', value: 'Anaya' },
  { label: 'Anaya De Alba', value: 'Anaya De Alba' },
  { label: 'Anbar Olum', value: 'Anbar Olum' },
  { label: 'Anbarabad', value: 'Anbarabad' },
  { label: 'Anbaran', value: 'Anbaran' },
  { label: 'Anbu', value: 'Anbu' },
  { label: 'Anbyŏn-Ŭp', value: 'Anbyŏn-Ŭp' },
  { label: 'Ançã', value: 'Ançã' },
  { label: 'Ancarano', value: 'Ancarano' },
  { label: 'Ancaster', value: 'Ancaster' },
  { label: 'Ancaster', value: 'Ancaster' },
  { label: 'Ancasti', value: 'Ancasti' },
  { label: 'Ancenis', value: 'Ancenis' },
  { label: 'Ancerville', value: 'Ancerville' },
  { label: 'Anchau', value: 'Anchau' },
  { label: 'Anchieta', value: 'Anchieta' },
  { label: 'Anchieta', value: 'Anchieta' },
  { label: 'Anchione', value: 'Anchione' },
  { label: 'Anchor Point', value: 'Anchor Point' },
  { label: 'Anchorage', value: 'Anchorage' },
  { label: 'Anchorage', value: 'Anchorage' },
  { label: 'Anchorage Municipality', value: 'Anchorage Municipality' },
  { label: 'Anchovy', value: 'Anchovy' },
  { label: 'Anchuelo', value: 'Anchuelo' },
  { label: 'Anchuras', value: 'Anchuras' },
  { label: 'Ancient Oaks', value: 'Ancient Oaks' },
  { label: 'Ancín', value: 'Ancín' },
  { label: 'Ancín/Antzin', value: 'Ancín/Antzin' },
  { label: 'Ancón', value: 'Ancón' },
  { label: 'Ancona', value: 'Ancona' },
  { label: 'Ancône', value: 'Ancône' },
  { label: 'Ancud', value: 'Ancud' },
  { label: 'Ancuya', value: 'Ancuya' },
  { label: 'Ancy-Le-Franc', value: 'Ancy-Le-Franc' },
  { label: 'Ancy-Sur-Moselle', value: 'Ancy-Sur-Moselle' },
  { label: 'Anda', value: 'Anda' },
  { label: 'Anda', value: 'Anda' },
  { label: 'Anda', value: 'Anda' },
  { label: 'Anda', value: 'Anda' },
  { label: 'Andacollo', value: 'Andacollo' },
  { label: 'Andacollo', value: 'Andacollo' },
  { label: 'Andahuaylas', value: 'Andahuaylas' },
  { label: 'Andal', value: 'Andal' },
  { label: 'Andalan', value: 'Andalan' },
  { label: 'Andalan', value: 'Andalan' },
  { label: 'Andalen', value: 'Andalen' },
  { label: 'Andalgalá', value: 'Andalgalá' },
  { label: 'Andali', value: 'Andali' },
  { label: 'Andalo', value: 'Andalo' },
  { label: 'Andalo Valtellino', value: 'Andalo Valtellino' },
  { label: 'Åndalsnes', value: 'Åndalsnes' },
  { label: 'Andalucía', value: 'Andalucía' },
  { label: 'Andalusia', value: 'Andalusia' },
  { label: 'Andalusia', value: 'Andalusia' },
  { label: 'Andance', value: 'Andance' },
  { label: 'Andancette', value: 'Andancette' },
  { label: 'Andaraí', value: 'Andaraí' },
  { label: 'Andard', value: 'Andard' },
  { label: 'Andau', value: 'Andau' },
  { label: 'Andavías', value: 'Andavías' },
  { label: 'Andé', value: 'Andé' },
  { label: 'Andechs', value: 'Andechs' },
  { label: 'Andel', value: 'Andel' },
  { label: 'Andelfingen', value: 'Andelfingen' },
  { label: 'Andelnans', value: 'Andelnans' },
  { label: 'Andelsbuch', value: 'Andelsbuch' },
  { label: 'Andelst', value: 'Andelst' },
  { label: 'Andenes', value: 'Andenes' },
  { label: 'Andenne', value: 'Andenne' },
  { label: 'Andergrove', value: 'Andergrove' },
  { label: 'Anderlues', value: 'Anderlues' },
  { label: 'Andermatt', value: 'Andermatt' },
  { label: 'Andernach', value: 'Andernach' },
  { label: 'Andernos-Les-Bains', value: 'Andernos-Les-Bains' },
  { label: 'Anderslöv', value: 'Anderslöv' },
  { label: 'Anderson', value: 'Anderson' },
  { label: 'Anderson', value: 'Anderson' },
  { label: 'Anderson', value: 'Anderson' },
  { label: 'Anderson', value: 'Anderson' },
  { label: 'Anderson', value: 'Anderson' },
  { label: 'Anderson County', value: 'Anderson County' },
  { label: 'Anderson County', value: 'Anderson County' },
  { label: 'Anderson County', value: 'Anderson County' },
  { label: 'Anderson County', value: 'Anderson County' },
  { label: 'Anderson County', value: 'Anderson County' },
  { label: 'Anderson Mill', value: 'Anderson Mill' },
  { label: 'Anderstorp', value: 'Anderstorp' },
  { label: 'Anderstorp', value: 'Anderstorp' },
  { label: 'Andes', value: 'Andes' },
  { label: 'Andeville', value: 'Andeville' },
  { label: 'Andezeno', value: 'Andezeno' },
  { label: 'Andheri', value: 'Andheri' },
  { label: 'Andi', value: 'Andi' },
  { label: 'Andijk', value: 'Andijk' },
  { label: 'Andijon', value: 'Andijon' },
  { label: 'Andijon Tumani', value: 'Andijon Tumani' },
  { label: 'Andika', value: 'Andika' },
  { label: 'Andili', value: 'Andili' },
  { label: 'Andili', value: 'Andili' },
  { label: 'Andili', value: 'Andili' },
  { label: 'Andilla', value: 'Andilla' },
  { label: 'Andilly', value: 'Andilly' },
  { label: 'Andilly', value: 'Andilly' },
  { label: 'Andimeshk', value: 'Andimeshk' },
  { label: 'Andippatti', value: 'Andippatti' },
  { label: 'Andirá', value: 'Andirá' },
  { label: 'Andırın', value: 'Andırın' },
  { label: 'Andisheh', value: 'Andisheh' },
  { label: 'Andkhoy', value: 'Andkhoy' },
  { label: 'Andlau', value: 'Andlau' },
  { label: 'Andlersdorf', value: 'Andlersdorf' },
  { label: 'Andoain', value: 'Andoain' },
  { label: 'Andoas', value: 'Andoas' },
  { label: 'Andol', value: 'Andol' },
  { label: 'Andolsheim', value: 'Andolsheim' },
  { label: 'Andong', value: 'Andong' },
  { label: 'Andong-Si', value: 'Andong-Si' },
  { label: 'Andop', value: 'Andop' },
  { label: 'Andop', value: 'Andop' },
  { label: 'Andop', value: 'Andop' },
  { label: 'Andora', value: 'Andora' },
  { label: 'Andorf', value: 'Andorf' },
  { label: 'Andorinha', value: 'Andorinha' },
  { label: 'Andornaktálya', value: 'Andornaktálya' },
  { label: 'Andorno Cacciorna', value: 'Andorno Cacciorna' },
  { label: 'Andorra', value: 'Andorra' },
  { label: 'Andorra La Vella', value: 'Andorra La Vella' },
  { label: 'Andosilla', value: 'Andosilla' },
  { label: 'Andouhjerd', value: 'Andouhjerd' },
  { label: 'Andouillé', value: 'Andouillé' },
  { label: 'Andover', value: 'Andover' },
  { label: 'Andover', value: 'Andover' },
  { label: 'Andover', value: 'Andover' },
  { label: 'Andover', value: 'Andover' },
  { label: 'Andover', value: 'Andover' },
  { label: 'Andover', value: 'Andover' },
  { label: 'Andover', value: 'Andover' },
  { label: 'Andover', value: 'Andover' },
  { label: 'Andovoranto', value: 'Andovoranto' },
  { label: 'Andøy', value: 'Andøy' },
  { label: 'Andra', value: 'Andra' },
  { label: 'Andradas', value: 'Andradas' },
  { label: 'Andradina', value: 'Andradina' },
  { label: 'Andrano', value: 'Andrano' },
  { label: 'Andrăşeşti', value: 'Andrăşeşti' },
  { label: 'Andrate', value: 'Andrate' },
  { label: 'Andratx', value: 'Andratx' },
  { label: 'Andravída', value: 'Andravída' },
  { label: 'André Da Rocha', value: 'André Da Rocha' },
  { label: 'Andreapol’', value: 'Andreapol’' },
  { label: 'Andreapol’Skiy Rayon', value: 'Andreapol’Skiy Rayon' },
  { label: 'Andreești', value: 'Andreești' },
  { label: 'Andrei Șaguna', value: 'Andrei Șaguna' },
  { label: 'Andreiaşu De Jos', value: 'Andreiaşu De Jos' },
  { label: 'Andreis', value: 'Andreis' },
  { label: 'Andrelândia', value: 'Andrelândia' },
  { label: 'Andreneasa', value: 'Andreneasa' },
  { label: 'Andreotta', value: 'Andreotta' },
  { label: 'Andres', value: 'Andres' },
  { label: 'Andres Bonifacio', value: 'Andres Bonifacio' },
  { label: 'Andrés Quintana Roo', value: 'Andrés Quintana Roo' },
  { label: 'Andrespol', value: 'Andrespol' },
  { label: 'Andrest', value: 'Andrest' },
  { label: 'Andrésy', value: 'Andrésy' },
  { label: 'Andretta-Mattinella', value: 'Andretta-Mattinella' },
  { label: 'Andrew County', value: 'Andrew County' },
  { label: 'Andrews', value: 'Andrews' },
  { label: 'Andrews', value: 'Andrews' },
  { label: 'Andrews', value: 'Andrews' },
  { label: 'Andrews', value: 'Andrews' },
  { label: 'Andrews Afb', value: 'Andrews Afb' },
  { label: 'Andrews County', value: 'Andrews County' },
  { label: 'Andrews Farm', value: 'Andrews Farm' },
  { label: 'Andreyevka', value: 'Andreyevka' },
  { label: 'Andreyevka', value: 'Andreyevka' },
  { label: 'Andreyevo', value: 'Andreyevo' },
  { label: 'Andreyevskoye', value: 'Andreyevskoye' },
  { label: 'Andrezé', value: 'Andrezé' },
  { label: 'Andrézieux-Bouthéon', value: 'Andrézieux-Bouthéon' },
  { label: 'Andria', value: 'Andria' },
  { label: 'Andriano', value: 'Andriano' },
  { label: 'Andrid', value: 'Andrid' },
  { label: 'Andrieşeni', value: 'Andrieşeni' },
  { label: 'Andrijaševci', value: 'Andrijaševci' },
  { label: 'Andrijevica', value: 'Andrijevica' },
  { label: 'Andritz', value: 'Andritz' },
  { label: 'Andriyivka', value: 'Andriyivka' },
  { label: 'Andros', value: 'Andros' },
  { label: 'Andros Town', value: 'Andros Town' },
  { label: 'Androscoggin County', value: 'Androscoggin County' },
  { label: 'Andrushivka', value: 'Andrushivka' },
  { label: 'Andrushivka', value: 'Andrushivka' },
  { label: 'Andrushivs’Kyy Rayon', value: 'Andrushivs’Kyy Rayon' },
  { label: 'Andrychów', value: 'Andrychów' },
  { label: 'Anduins', value: 'Anduins' },
  { label: 'Andújar', value: 'Andújar' },
  { label: 'Andura', value: 'Andura' },
  { label: 'Anduze', value: 'Anduze' },
  { label: 'Andwil', value: 'Andwil' },
  { label: 'Andzhiyevskiy', value: 'Andzhiyevskiy' },
  { label: 'Añe', value: 'Añe' },
  { label: 'Aneby', value: 'Aneby' },
  { label: 'Åneby', value: 'Åneby' },
  { label: 'Aného', value: 'Aného' },
  { label: 'Anekal', value: 'Anekal' },
  { label: 'Anela', value: 'Anela' },
  { label: 'Añelo', value: 'Añelo' },
  { label: 'Anenecuilco', value: 'Anenecuilco' },
  { label: 'Anenii Noi', value: 'Anenii Noi' },
  { label: 'Anento', value: 'Anento' },
  { label: 'Anet', value: 'Anet' },
  { label: 'Anetz', value: 'Anetz' },
  { label: 'Anéza', value: 'Anéza' },
  { label: 'Anfo', value: 'Anfo' },
  { label: 'Ang Mo Kio', value: 'Ang Mo Kio' },
  { label: 'Ang Thong', value: 'Ang Thong' },
  { label: 'Angad', value: 'Angad' },
  { label: 'Angad', value: 'Angad' },
  { label: 'Angadanan', value: 'Angadanan' },
  { label: 'Angadanan', value: 'Angadanan' },
  { label: 'Angahuán', value: 'Angahuán' },
  { label: 'Angalimp South Wahgi', value: 'Angalimp South Wahgi' },
  { label: 'Angamacutiro De La Unión', value: 'Angamacutiro De La Unión' },
  { label: 'Angamali', value: 'Angamali' },
  { label: 'Angarsk', value: 'Angarsk' },
  { label: 'Angarskiy Rayon', value: 'Angarskiy Rayon' },
  { label: 'Angaston', value: 'Angaston' },
  { label: 'Angat', value: 'Angat' },
  { label: 'Angat', value: 'Angat' },
  { label: 'Angatel', value: 'Angatel' },
  { label: 'Angatel', value: 'Angatel' },
  { label: 'Angath', value: 'Angath' },
  { label: 'Angatuba', value: 'Angatuba' },
  { label: 'Angaur State', value: 'Angaur State' },
  { label: 'Ånge', value: 'Ånge' },
  { label: 'Ange-Gardien', value: 'Ange-Gardien' },
  { label: 'Angeghakot’', value: 'Angeghakot’' },
  { label: 'Ángel Albino Corzo', value: 'Ángel Albino Corzo' },
  { label: 'Ángel Albino Corzo (Guadalupe)', value: 'Ángel Albino Corzo (Guadalupe)' },
  { label: 'Angel Fire', value: 'Angel Fire' },
  { label: 'Angel R. Cabada', value: 'Angel R. Cabada' },
  { label: 'Angelândia', value: 'Angelândia' },
  { label: 'Angeles', value: 'Angeles' },
  { label: 'Ángeles', value: 'Ángeles' },
  { label: 'Angeles City', value: 'Angeles City' },
  { label: 'Ängelholm', value: 'Ängelholm' },
  { label: 'Angélica', value: 'Angélica' },
  { label: 'Angelim', value: 'Angelim' },
  { label: 'Angelina', value: 'Angelina' },
  { label: 'Angelina County', value: 'Angelina County' },
  { label: 'Angelochóri', value: 'Angelochóri' },
  { label: 'Angelókastro', value: 'Angelókastro' },
  { label: 'Angelópolis', value: 'Angelópolis' },
  { label: 'Angels Camp', value: 'Angels Camp' },
  { label: 'Angelslo', value: 'Angelslo' },
  { label: 'Anger', value: 'Anger' },
  { label: 'Anger', value: 'Anger' },
  { label: 'Angera', value: 'Angera' },
  { label: 'Angerberg', value: 'Angerberg' },
  { label: 'Angered', value: 'Angered' },
  { label: 'Angeren', value: 'Angeren' },
  { label: 'Angerlo', value: 'Angerlo' },
  { label: 'Angermünde', value: 'Angermünde' },
  { label: 'Angern', value: 'Angern' },
  { label: 'Angern An Der March', value: 'Angern An Der March' },
  { label: 'Angers', value: 'Angers' },
  { label: 'Angersdorf', value: 'Angersdorf' },
  { label: 'Angerville', value: 'Angerville' },
  { label: 'Angerville-L’Orcher', value: 'Angerville-L’Orcher' },
  { label: 'Angervilliers', value: 'Angervilliers' },
  { label: 'Angevillers', value: 'Angevillers' },
  { label: 'Anghelești', value: 'Anghelești' },
  { label: 'Anghiari', value: 'Anghiari' },
  { label: 'Angiari', value: 'Angiari' },
  { label: 'Angical', value: 'Angical' },
  { label: 'Angical Do Piauí', value: 'Angical Do Piauí' },
  { label: 'Angico', value: 'Angico' },
  { label: 'Angicos', value: 'Angicos' },
  { label: 'Angicourt', value: 'Angicourt' },
  { label: 'Angier', value: 'Angier' },
  { label: 'Angkor Chey', value: 'Angkor Chey' },
  { label: 'Angle Park', value: 'Angle Park' },
  { label: 'Angle Vale', value: 'Angle Vale' },
  { label: 'Angles', value: 'Angles' },
  { label: 'Anglès', value: 'Anglès' },
  { label: 'Anglesea', value: 'Anglesea' },
  { label: 'Anglesey', value: 'Anglesey' },
  { label: 'Anglesola', value: 'Anglesola' },
  { label: 'Anglet', value: 'Anglet' },
  { label: 'Angleton', value: 'Angleton' },
  { label: 'Angofa', value: 'Angofa' },
  { label: 'Angol', value: 'Angol' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Angola On The Lake', value: 'Angola On The Lake' },
  { label: 'Angolo Terme', value: 'Angolo Terme' },
  { label: 'Angón', value: 'Angón' },
  { label: 'Angono', value: 'Angono' },
  { label: 'Angoshteh', value: 'Angoshteh' },
  { label: 'Angostura', value: 'Angostura' },
  { label: 'Angostura', value: 'Angostura' },
  { label: 'Angoulême', value: 'Angoulême' },
  { label: 'Angoulins', value: 'Angoulins' },
  { label: 'Angra Do Heroísmo', value: 'Angra Do Heroísmo' },
  { label: 'Angra Dos Reis', value: 'Angra Dos Reis' },
  { label: 'Angren', value: 'Angren' },
  { label: 'Angres', value: 'Angres' },
  { label: 'Angresse', value: 'Angresse' },
  { label: 'Angri', value: 'Angri' },
  { label: 'Angrogna', value: 'Angrogna' },
  { label: 'Anguciana', value: 'Anguciana' },
  { label: 'Anguera', value: 'Anguera' },
  { label: 'Angüés', value: 'Angüés' },
  { label: 'Anguiano', value: 'Anguiano' },
  { label: 'Anguil', value: 'Anguil' },
  { label: 'Anguillara Sabazia', value: 'Anguillara Sabazia' },
  { label: 'Anguillara Veneta', value: 'Anguillara Veneta' },
  { label: 'Anguita', value: 'Anguita' },
  { label: 'Anguix', value: 'Anguix' },
  { label: 'Angul', value: 'Angul' },
  { label: 'Angul District', value: 'Angul District' },
  { label: 'Ângulo', value: 'Ângulo' },
  { label: 'Angus', value: 'Angus' },
  { label: 'Angus', value: 'Angus' },
  { label: 'Angústias', value: 'Angústias' },
  { label: 'Angwin', value: 'Angwin' },
  { label: 'Angy', value: 'Angy' },
  { label: 'Anhanguera', value: 'Anhanguera' },
  { label: 'Anhausen', value: 'Anhausen' },
  { label: 'Anhée', value: 'Anhée' },
  { label: 'Anhembi', value: 'Anhembi' },
  { label: 'Anhiers', value: 'Anhiers' },
  { label: 'Anhumas', value: 'Anhumas' },
  { label: 'Aniane', value: 'Aniane' },
  { label: 'Anibongan', value: 'Anibongan' },
  { label: 'Anibongan', value: 'Anibongan' },
  { label: 'Anibongan', value: 'Anibongan' },
  { label: 'Aniche', value: 'Aniche' },
  { label: 'Anicuns', value: 'Anicuns' },
  { label: 'Ani-E', value: 'Ani-E' },
  { label: 'Anières', value: 'Anières' },
  { label: 'Anieș', value: 'Anieș' },
  { label: 'Anievas', value: 'Anievas' },
  { label: 'Anif', value: 'Anif' },
  { label: 'Anija Vald', value: 'Anija Vald' },
  { label: 'Anilao', value: 'Anilao' },
  { label: 'Animas', value: 'Animas' },
  { label: 'Animas Trujano', value: 'Animas Trujano' },
  { label: 'Anina', value: 'Anina' },
  { label: 'Anini-Y', value: 'Anini-Y' },
  { label: 'Aninoasa', value: 'Aninoasa' },
  { label: 'Aninoasa', value: 'Aninoasa' },
  { label: 'Aninoasa', value: 'Aninoasa' },
  { label: 'Aninoasa', value: 'Aninoasa' },
  { label: 'Aniñón', value: 'Aniñón' },
  { label: 'Anísio De Abreu', value: 'Anísio De Abreu' },
  { label: 'Añisoc', value: 'Añisoc' },
  { label: 'Anita Garibaldi', value: 'Anita Garibaldi' },
  { label: 'Anitápolis', value: 'Anitápolis' },
  { label: 'Anito', value: 'Anito' },
  { label: 'Anito', value: 'Anito' },
  { label: 'Anitrella-Chiaiamari', value: 'Anitrella-Chiaiamari' },
  { label: 'Aniva', value: 'Aniva' },
  { label: 'Anizy-Le-Château', value: 'Anizy-Le-Château' },
  { label: 'Anjad', value: 'Anjad' },
  { label: 'Anjala', value: 'Anjala' },
  { label: 'Anjangaon', value: 'Anjangaon' },
  { label: 'Anjar', value: 'Anjar' },
  { label: 'Anjarah', value: 'Anjarah' },
  { label: 'Anjarle', value: 'Anjarle' },
  { label: 'Anjaw', value: 'Anjaw' },
  { label: 'Anjiang', value: 'Anjiang' },
  { label: 'Anjir Baghi', value: 'Anjir Baghi' },
  { label: 'Anjos', value: 'Anjos' },
  { label: 'Anjō-Shi', value: 'Anjō-Shi' },
  { label: 'Anju', value: 'Anju' },
  { label: 'Anka', value: 'Anka' },
  { label: 'Ankang', value: 'Ankang' },
  { label: 'Ankara', value: 'Ankara' },
  { label: 'Ankaran', value: 'Ankaran' },
  { label: 'Ankarsrum', value: 'Ankarsrum' },
  { label: 'Ankeny', value: 'Ankeny' },
  { label: 'Ankeveen', value: 'Ankeveen' },
  { label: 'Ankeveense Rade', value: 'Ankeveense Rade' },
  { label: 'Anklaar', value: 'Anklaar' },
  { label: 'Anklam', value: 'Anklam' },
  { label: 'Ankleshwar', value: 'Ankleshwar' },
  { label: 'Ankola', value: 'Ankola' },
  { label: 'Ankpa', value: 'Ankpa' },
  { label: 'Ankum', value: 'Ankum' },
  { label: 'Anloga', value: 'Anloga' },
  { label: 'Anlu', value: 'Anlu' },
  { label: 'Anmore', value: 'Anmore' },
  { label: 'Ann Arbor', value: 'Ann Arbor' },
  { label: 'Anna', value: 'Anna' },
  { label: 'Anna', value: 'Anna' },
  { label: 'Anna', value: 'Anna' },
  { label: 'Anna', value: 'Anna' },
  { label: 'Anna', value: 'Anna' },
  { label: 'Anna Bay', value: 'Anna Bay' },
  { label: 'Anna Maria', value: 'Anna Maria' },
  { label: 'Anna Regina', value: 'Anna Regina' },
  { label: 'Anna Valley', value: 'Anna Valley' },
  { label: 'Annaba', value: 'Annaba' },
  { label: 'Annaberg-Buchholz', value: 'Annaberg-Buchholz' },
  { label: 'Annabichl', value: 'Annabichl' },
  { label: 'Annaburg', value: 'Annaburg' },
  { label: 'Annacotty', value: 'Annacotty' },
  { label: 'Annahilt', value: 'Annahilt' },
  { label: 'Annaka', value: 'Annaka' },
  { label: 'Annaka Shi', value: 'Annaka Shi' },
  { label: 'Annalong', value: 'Annalong' },
  { label: 'Annamalainagar', value: 'Annamalainagar' },
  { label: 'Annan', value: 'Annan' },
  { label: 'Annandale', value: 'Annandale' },
  { label: 'Annandale', value: 'Annandale' },
  { label: 'Annandale', value: 'Annandale' },
  { label: 'Annandale', value: 'Annandale' },
  { label: 'Annandale', value: 'Annandale' },
  { label: 'Annangrove', value: 'Annangrove' },
  { label: 'Annapolis', value: 'Annapolis' },
  { label: 'Annapolis County', value: 'Annapolis County' },
  { label: 'Annau', value: 'Annau' },
  { label: 'Annavasal', value: 'Annavasal' },
  { label: 'Annay', value: 'Annay' },
  { label: 'Anne Arundel County', value: 'Anne Arundel County' },
  { label: 'Anneberg', value: 'Anneberg' },
  { label: 'Annecy', value: 'Annecy' },
  { label: 'Annecy-Le-Vieux', value: 'Annecy-Le-Vieux' },
  { label: 'Annelund', value: 'Annelund' },
  { label: 'Annemasse', value: 'Annemasse' },
  { label: 'Annen', value: 'Annen' },
  { label: 'Annequin', value: 'Annequin' },
  { label: 'Annerley', value: 'Annerley' },
  { label: 'Annesse-Et-Beaulieu', value: 'Annesse-Et-Beaulieu' },
  { label: 'Annet-Sur-Marne', value: 'Annet-Sur-Marne' },
  { label: 'Annetta', value: 'Annetta' },
  { label: 'Anneyron', value: 'Anneyron' },
  { label: 'Annezin', value: 'Annezin' },
  { label: 'Annfield Plain', value: 'Annfield Plain' },
  { label: 'Annicco', value: 'Annicco' },
  { label: 'Annigeri', value: 'Annigeri' },
  { label: 'Annino', value: 'Annino' },
  { label: 'Annino', value: 'Annino' },
  { label: 'Annino', value: 'Annino' },
  { label: 'Anninskiy Rayon', value: 'Anninskiy Rayon' },
  { label: 'Anniston', value: 'Anniston' },
  { label: 'Annoeullin', value: 'Annoeullin' },
  { label: 'Annonay', value: 'Annonay' },
  { label: 'Annone Di Brianza', value: 'Annone Di Brianza' },
  { label: 'Annone Veneto', value: 'Annone Veneto' },
  { label: 'Annopol', value: 'Annopol' },
  { label: 'Annot', value: 'Annot' },
  { label: 'Annotto Bay', value: 'Annotto Bay' },
  { label: 'Annunziata', value: 'Annunziata' },
  { label: 'Annur', value: 'Annur' },
  { label: 'Annus', value: 'Annus' },
  { label: 'Annville', value: 'Annville' },
  { label: 'Annville', value: 'Annville' },
  { label: 'Annweiler Am Trifels', value: 'Annweiler Am Trifels' },
  { label: 'Ano Arhanes', value: 'Ano Arhanes' },
  { label: 'Áno Kalentíni', value: 'Áno Kalentíni' },
  { label: 'Áno Kastrítsi', value: 'Áno Kastrítsi' },
  { label: 'Áno Kómi', value: 'Áno Kómi' },
  { label: 'Áno Lekhónia', value: 'Áno Lekhónia' },
  { label: 'Áno Liósia', value: 'Áno Liósia' },
  { label: 'Áno Merá', value: 'Áno Merá' },
  { label: 'Áno Sýros', value: 'Áno Sýros' },
  { label: 'Anoca', value: 'Anoca' },
  { label: 'Anoeta', value: 'Anoeta' },
  { label: 'Anógeia', value: 'Anógeia' },
  { label: 'Anoia Inferiore', value: 'Anoia Inferiore' },
  { label: 'Anoia Superiore', value: 'Anoia Superiore' },
  { label: 'Anoixi', value: 'Anoixi' },
  { label: 'Anoka', value: 'Anoka' },
  { label: 'Anoka County', value: 'Anoka County' },
  { label: 'Anolaima', value: 'Anolaima' },
  { label: 'Añón De Moncayo', value: 'Añón De Moncayo' },
  { label: 'Anonang', value: 'Anonang' },
  { label: 'Anopino', value: 'Anopino' },
  { label: 'Anopog', value: 'Anopog' },
  { label: 'Anor', value: 'Anor' },
  { label: 'Añora', value: 'Añora' },
  { label: 'Añorbe', value: 'Añorbe' },
  { label: 'Anori', value: 'Anori' },
  { label: 'Anorí', value: 'Anorí' },
  { label: 'Anoring', value: 'Anoring' },
  { label: 'Anould', value: 'Anould' },
  { label: 'Anouvong District', value: 'Anouvong District' },
  { label: 'Añover De Tajo', value: 'Añover De Tajo' },
  { label: 'Añover De Tormes', value: 'Añover De Tormes' },
  { label: 'Anping', value: 'Anping' },
  { label: 'Anqing', value: 'Anqing' },
  { label: 'Anqing Shi', value: 'Anqing Shi' },
  { label: 'Anqiu', value: 'Anqiu' },
  { label: 'Anquela Del Ducado', value: 'Anquela Del Ducado' },
  { label: 'Anquela Del Pedregal', value: 'Anquela Del Pedregal' },
  { label: 'Anras', value: 'Anras' },
  { label: 'Anröchte', value: 'Anröchte' },
  { label: 'Anrode', value: 'Anrode' },
  { label: 'Ans', value: 'Ans' },
  { label: 'Ans', value: 'Ans' },
  { label: 'Ansalta', value: 'Ansalta' },
  { label: 'Ansan-Si', value: 'Ansan-Si' },
  { label: 'Ansar Ol Emam', value: 'Ansar Ol Emam' },
  { label: 'Ansauvillers', value: 'Ansauvillers' },
  { label: 'Ansavo', value: 'Ansavo' },
  { label: 'Ansbach', value: 'Ansbach' },
  { label: 'Anse', value: 'Anse' },
  { label: 'Anse À Galets', value: 'Anse À Galets' },
  { label: 'Anse Boileau', value: 'Anse Boileau' },
  { label: 'Anse Canot', value: 'Anse Canot' },
  { label: 'Anse Chastanet', value: 'Anse Chastanet' },
  { label: 'Anse Cochon', value: 'Anse Cochon' },
  { label: 'Anse Galet', value: 'Anse Galet' },
  { label: 'Anse Ger', value: 'Anse Ger' },
  { label: 'Anse La Raye', value: 'Anse La Raye' },
  { label: 'Anse La Verdue', value: 'Anse La Verdue' },
  { label: 'Anse Rouge', value: 'Anse Rouge' },
  { label: 'Anse Royale', value: 'Anse Royale' },
  { label: 'Anse-À-Pitre', value: 'Anse-À-Pitre' },
  { label: 'Anse-À-Veau', value: 'Anse-À-Veau' },
  { label: 'Anseong', value: 'Anseong' },
  { label: 'Anserma', value: 'Anserma' },
  { label: 'Ansermanuevo', value: 'Ansermanuevo' },
  { label: 'Ansfelden', value: 'Ansfelden' },
  { label: 'Anshan', value: 'Anshan' },
  { label: 'Anshing', value: 'Anshing' },
  { label: 'Anshun', value: 'Anshun' },
  { label: 'Ansião', value: 'Ansião' },
  { label: 'Ansihuacuaro', value: 'Ansihuacuaro' },
  { label: 'Ansó', value: 'Ansó' },
  { label: 'Ansoáin/Antsoain', value: 'Ansoáin/Antsoain' },
  { label: 'Anson', value: 'Anson' },
  { label: 'Anson County', value: 'Anson County' },
  { label: 'Ansongo', value: 'Ansongo' },
  { label: 'Ansonia', value: 'Ansonia' },
  { label: 'Ansonia', value: 'Ansonia' },
  { label: 'Ansouis', value: 'Ansouis' },
  { label: 'Anstaing', value: 'Anstaing' },
  { label: 'Anstead', value: 'Anstead' },
  { label: 'Ansted', value: 'Ansted' },
  { label: 'Anstey', value: 'Anstey' },
  { label: 'Anstruther', value: 'Anstruther' },
  { label: 'Anta', value: 'Anta' },
  { label: 'Anta', value: 'Anta' },
  { label: 'Anta', value: 'Anta' },
  { label: 'Anta Gorda', value: 'Anta Gorda' },
  { label: 'Antagan Segunda', value: 'Antagan Segunda' },
  { label: 'Antagan Segunda', value: 'Antagan Segunda' },
  { label: 'Antagnod', value: 'Antagnod' },
  { label: 'Antakya', value: 'Antakya' },
  { label: 'Antártica', value: 'Antártica' },
  { label: 'Antas', value: 'Antas' },
  { label: 'Antas', value: 'Antas' },
  { label: 'Antas De Ulla', value: 'Antas De Ulla' },
  { label: 'Antau', value: 'Antau' },
  { label: 'Antdorf', value: 'Antdorf' },
  { label: 'Antegnate', value: 'Antegnate' },
  { label: 'Antella', value: 'Antella' },
  { label: 'Antella', value: 'Antella' },
  { label: 'Antelope', value: 'Antelope' },
  { label: 'Antelope County', value: 'Antelope County' },
  { label: 'Antelope Valley-Crestview', value: 'Antelope Valley-Crestview' },
  { label: 'Antequera', value: 'Antequera' },
  { label: 'Antequera', value: 'Antequera' },
  { label: 'Antequera', value: 'Antequera' },
  { label: 'Anterivo', value: 'Anterivo' },
  { label: 'Antessano', value: 'Antessano' },
  { label: 'Antey-Saint-Andrè', value: 'Antey-Saint-Andrè' },
  { label: 'Anthem', value: 'Anthem' },
  { label: 'Anthering', value: 'Anthering' },
  { label: 'Anthíli', value: 'Anthíli' },
  { label: 'Anthiró', value: 'Anthiró' },
  { label: 'Anthisnes', value: 'Anthisnes' },
  { label: 'Anthiyur', value: 'Anthiyur' },
  { label: 'Anthony', value: 'Anthony' },
  { label: 'Anthony', value: 'Anthony' },
  { label: 'Anthony', value: 'Anthony' },
  { label: 'Anthoúsa', value: 'Anthoúsa' },
  { label: 'Anthy-Sur-Léman', value: 'Anthy-Sur-Léman' },
  { label: 'Antibes', value: 'Antibes' },
  { label: 'Anticala', value: 'Anticala' },
  { label: 'Anticala', value: 'Anticala' },
  { label: 'Anticoli Corrado', value: 'Anticoli Corrado' },
  { label: 'Antignano', value: 'Antignano' },
  { label: 'Antigny', value: 'Antigny' },
  { label: 'Antigo', value: 'Antigo' },
  { label: 'Antigonish', value: 'Antigonish' },
  { label: 'Antigua', value: 'Antigua' },
  { label: 'Antigua Guatemala', value: 'Antigua Guatemala' },
  { label: 'Antigua La', value: 'Antigua La' },
  { label: 'Antigua Ocotepeque', value: 'Antigua Ocotepeque' },
  { label: 'Antigüedad', value: 'Antigüedad' },
  { label: 'Antiguo Cuscatlán', value: 'Antiguo Cuscatlán' },
  { label: 'Antiguo Morelos', value: 'Antiguo Morelos' },
  { label: 'Antikyra', value: 'Antikyra' },
  { label: 'Antillo', value: 'Antillo' },
  { label: 'Antillón', value: 'Antillón' },
  { label: 'Antimácheia', value: 'Antimácheia' },
  { label: 'Antioch', value: 'Antioch' },
  { label: 'Antioch', value: 'Antioch' },
  { label: 'Antíparos', value: 'Antíparos' },
  { label: 'Antipino', value: 'Antipino' },
  { label: 'Antipolo', value: 'Antipolo' },
  { label: 'Antipolo', value: 'Antipolo' },
  { label: 'Antipolo', value: 'Antipolo' },
  { label: 'Antipolo', value: 'Antipolo' },
  { label: 'Antipolo', value: 'Antipolo' },
  { label: 'Antipolo', value: 'Antipolo' },
  { label: 'Antipovka', value: 'Antipovka' },
  { label: 'Antirrio', value: 'Antirrio' },
  { label: 'Antlers', value: 'Antlers' },
  { label: 'Antofagasta', value: 'Antofagasta' },
  { label: 'Antofagasta De La Sierra', value: 'Antofagasta De La Sierra' },
  { label: 'Antoing', value: 'Antoing' },
  { label: 'Anton', value: 'Anton' },
  { label: 'Anton', value: 'Anton' },
  { label: 'Antón', value: 'Antón' },
  { label: 'Antón Lizardo', value: 'Antón Lizardo' },
  { label: 'Antón Ruiz', value: 'Antón Ruiz' },
  { label: 'Anton’Yevka', value: 'Anton’Yevka' },
  { label: 'Antonimina', value: 'Antonimina' },
  { label: 'Antonina', value: 'Antonina' },
  { label: 'Antonina Do Norte', value: 'Antonina Do Norte' },
  { label: 'Antoniny', value: 'Antoniny' },
  { label: 'Antônio Almeida', value: 'Antônio Almeida' },
  { label: 'Antonio Amaro', value: 'Antonio Amaro' },
  { label: 'Antônio Cardoso', value: 'Antônio Cardoso' },
  { label: 'Antônio Carlos', value: 'Antônio Carlos' },
  { label: 'Antônio Carlos', value: 'Antônio Carlos' },
  { label: 'Antônio Dias', value: 'Antônio Dias' },
  { label: 'António Enes', value: 'António Enes' },
  { label: 'Antonio Escobedo', value: 'Antonio Escobedo' },
  { label: 'Antônio Gonçalves', value: 'Antônio Gonçalves' },
  { label: 'Antonio J Bermúdez', value: 'Antonio J Bermúdez' },
  { label: 'Antônio João', value: 'Antônio João' },
  { label: 'Antônio Martins', value: 'Antônio Martins' },
  { label: 'Antônio Olinto', value: 'Antônio Olinto' },
  { label: 'Antônio Prado', value: 'Antônio Prado' },
  { label: 'Antônio Prado De Minas', value: 'Antônio Prado De Minas' },
  { label: 'Antonio Rosales', value: 'Antonio Rosales' },
  { label: 'Antonne-Et-Trigonant', value: 'Antonne-Et-Trigonant' },
  { label: 'Antonovo', value: 'Antonovo' },
  { label: 'Antony', value: 'Antony' },
  { label: 'Antopal’', value: 'Antopal’' },
  { label: 'Antrain', value: 'Antrain' },
  { label: 'Antran', value: 'Antran' },
  { label: 'Antratsyt', value: 'Antratsyt' },
  { label: 'Antratsytivs’Kyy Rayon', value: 'Antratsytivs’Kyy Rayon' },
  { label: 'Antri', value: 'Antri' },
  { label: 'Antrim', value: 'Antrim' },
  { label: 'Antrim', value: 'Antrim' },
  { label: 'Antrim And Newtownabbey', value: 'Antrim And Newtownabbey' },
  { label: 'Antrim County', value: 'Antrim County' },
  { label: 'Antrodoco', value: 'Antrodoco' },
  { label: 'Antronapiana', value: 'Antronapiana' },
  { label: 'Antropovo', value: 'Antropovo' },
  { label: 'Antropovskiy Rayon', value: 'Antropovskiy Rayon' },
  { label: 'Antsahé', value: 'Antsahé' },
  { label: 'Antsla', value: 'Antsla' },
  { label: 'Antsla Vald', value: 'Antsla Vald' },
  { label: 'Antu', value: 'Antu' },
  { label: 'Antuco', value: 'Antuco' },
  { label: 'Antúnez', value: 'Antúnez' },
  { label: 'Antúnez (Morelos)', value: 'Antúnez (Morelos)' },
  { label: 'Antunovac', value: 'Antunovac' },
  { label: 'Antwerp', value: 'Antwerp' },
  { label: 'Antwerpen', value: 'Antwerpen' },
  { label: 'Antzuola', value: 'Antzuola' },
  { label: 'Anuch', value: 'Anuch' },
  { label: 'Anuchino', value: 'Anuchino' },
  { label: 'Anuchinskiy Rayon', value: 'Anuchinskiy Rayon' },
  { label: 'Anue', value: 'Anue' },
  { label: 'Anula', value: 'Anula' },
  { label: 'Anulid', value: 'Anulid' },
  { label: 'Anulid', value: 'Anulid' },
  { label: 'Anuling', value: 'Anuling' },
  { label: 'Anuling', value: 'Anuling' },
  { label: 'Anuling', value: 'Anuling' },
  { label: 'Anuling', value: 'Anuling' },
  { label: 'Anupgarh', value: 'Anupgarh' },
  { label: 'Anuppur', value: 'Anuppur' },
  { label: 'Anupshahr', value: 'Anupshahr' },
  { label: 'Anuradhapura', value: 'Anuradhapura' },
  { label: 'Anuradhapura District', value: 'Anuradhapura District' },
  { label: 'Anushavan', value: 'Anushavan' },
  { label: 'Anversa Degli Abruzzi', value: 'Anversa Degli Abruzzi' },
  { label: 'Anxiang', value: 'Anxiang' },
  { label: 'Anyama', value: 'Anyama' },
  { label: 'Anyang', value: 'Anyang' },
  { label: 'Anyang Shi', value: 'Anyang Shi' },
  { label: 'Anyang-Si', value: 'Anyang-Si' },
  { label: 'Anyksciai', value: 'Anyksciai' },
  { label: 'Anza', value: 'Anza' },
  { label: 'Anza', value: 'Anza' },
  { label: 'Anzano Del Parco', value: 'Anzano Del Parco' },
  { label: 'Anzano Di Puglia', value: 'Anzano Di Puglia' },
  { label: 'Anzegem', value: 'Anzegem' },
  { label: 'Anzhero-Sudzhensk', value: 'Anzhero-Sudzhensk' },
  { label: 'Anzi', value: 'Anzi' },
  { label: 'Anzin', value: 'Anzin' },
  { label: 'Anzing', value: 'Anzing' },
  { label: 'Anzin-Saint-Aubin', value: 'Anzin-Saint-Aubin' },
  { label: 'Anzio', value: 'Anzio' },
  { label: 'Anzoátegui', value: 'Anzoátegui' },
  { label: 'Anzola Dellemilia', value: 'Anzola Dellemilia' },
  { label: 'Anzola Dossola', value: 'Anzola Dossola' },
  { label: 'Ao Luek', value: 'Ao Luek' },
  { label: 'Aoiz', value: 'Aoiz' },
  { label: 'Aoiz/Agoitz', value: 'Aoiz/Agoitz' },
  { label: 'Aoji', value: 'Aoji' },
  { label: 'Aoki', value: 'Aoki' },
  { label: 'Aomori', value: 'Aomori' },
  { label: 'Aomori Shi', value: 'Aomori Shi' },
  { label: 'Aonla', value: 'Aonla' },
  { label: 'Aoshi', value: 'Aoshi' },
  { label: 'Aosta', value: 'Aosta' },
  { label: 'Aoste', value: 'Aoste' },
  { label: 'Aoufous', value: 'Aoufous' },
  { label: 'Aoulef', value: 'Aoulef' },
  { label: 'Aoulouz', value: 'Aoulouz' },
  { label: 'Aourir', value: 'Aourir' },
  { label: 'Aousserd', value: 'Aousserd' },
  { label: 'Aouste-Sur-Sye', value: 'Aouste-Sur-Sye' },
  { label: 'Aowin', value: 'Aowin' },
  { label: 'Aozou', value: 'Aozou' },
  { label: 'Ấp Tân Ngãi', value: 'Ấp Tân Ngãi' },
  { label: 'Apa', value: 'Apa' },
  { label: 'Apa Asău', value: 'Apa Asău' },
  { label: 'Apac', value: 'Apac' },
  { label: 'Apače', value: 'Apače' },
  { label: 'Apache', value: 'Apache' },
  { label: 'Apache County', value: 'Apache County' },
  { label: 'Apache Junction', value: 'Apache Junction' },
  { label: 'Apacilagua', value: 'Apacilagua' },
  { label: 'Apad', value: 'Apad' },
  { label: 'Apaga', value: 'Apaga' },
  { label: 'Apagy', value: 'Apagy' },
  { label: 'Apahida', value: 'Apahida' },
  { label: 'Apalachicola', value: 'Apalachicola' },
  { label: 'Apalachin', value: 'Apalachin' },
  { label: 'Apalani', value: 'Apalani' },
  { label: 'Apalina', value: 'Apalina' },
  { label: 'Apalit', value: 'Apalit' },
  { label: 'Apalit', value: 'Apalit' },
  { label: 'Apam', value: 'Apam' },
  { label: 'Apan', value: 'Apan' },
  { label: 'Apango', value: 'Apango' },
  { label: 'Apango De Zaragoza', value: 'Apango De Zaragoza' },
  { label: 'Apantéopan', value: 'Apantéopan' },
  { label: 'Apantla', value: 'Apantla' },
  { label: 'Apapa', value: 'Apapa' },
  { label: 'Apapantilla', value: 'Apapantilla' },
  { label: 'Apapátaro', value: 'Apapátaro' },
  { label: 'Aparan', value: 'Aparan' },
  { label: 'Aparecida', value: 'Aparecida' },
  { label: 'Aparecida', value: 'Aparecida' },
  { label: 'Aparecida De Goiânia', value: 'Aparecida De Goiânia' },
  { label: 'Aparecida Do Rio Doce', value: 'Aparecida Do Rio Doce' },
  { label: 'Aparecida Do Rio Negro', value: 'Aparecida Do Rio Negro' },
  { label: 'Aparecida Do Taboado', value: 'Aparecida Do Taboado' },
  { label: 'Aparecida Doeste', value: 'Aparecida Doeste' },
  { label: 'Aparri', value: 'Aparri' },
  { label: 'Aparri', value: 'Aparri' },
  { label: 'Apartadó', value: 'Apartadó' },
  { label: 'Apas', value: 'Apas' },
  { label: 'Apas', value: 'Apas' },
  { label: 'Apaseo El Alto', value: 'Apaseo El Alto' },
  { label: 'Apaseo El Grande', value: 'Apaseo El Grande' },
  { label: 'Apastepeque', value: 'Apastepeque' },
  { label: 'Apastovo', value: 'Apastovo' },
  { label: 'Apastovskiy Rayon', value: 'Apastovskiy Rayon' },
  { label: 'Apaţa', value: 'Apaţa' },
  { label: 'Apatauyan', value: 'Apatauyan' },
  { label: 'Apateu', value: 'Apateu' },
  { label: 'Apátfalva', value: 'Apátfalva' },
  { label: 'Apatin', value: 'Apatin' },
  { label: 'Apatity', value: 'Apatity' },
  { label: 'Apatzingán', value: 'Apatzingán' },
  { label: 'Apatzingán', value: 'Apatzingán' },
  { label: 'Apaxco De Ocampo', value: 'Apaxco De Ocampo' },
  { label: 'Apaxtla', value: 'Apaxtla' },
  { label: 'Apaxtla De Castrejón', value: 'Apaxtla De Castrejón' },
  { label: 'Apayao', value: 'Apayao' },
  { label: 'Apazapan', value: 'Apazapan' },
  { label: 'Apazulco', value: 'Apazulco' },
  { label: 'Apc', value: 'Apc' },
  { label: 'Ape', value: 'Ape' },
  { label: 'Apecchio', value: 'Apecchio' },
  { label: 'Apelação', value: 'Apelação' },
  { label: 'Apelação', value: 'Apelação' },
  { label: 'Apeldoorn', value: 'Apeldoorn' },
  { label: 'Apele Vii', value: 'Apele Vii' },
  { label: 'Apelern', value: 'Apelern' },
  { label: 'Apen', value: 'Apen' },
  { label: 'Apensen', value: 'Apensen' },
  { label: 'Apeo', value: 'Apeo' },
  { label: 'Aperibé', value: 'Aperibé' },
  { label: 'Apetatitlán Antonio Carbajal', value: 'Apetatitlán Antonio Carbajal' },
  { label: 'Apetlon', value: 'Apetlon' },
  { label: 'Apex', value: 'Apex' },
  { label: 'Apfelberg', value: 'Apfelberg' },
  { label: 'Apfeldorf', value: 'Apfeldorf' },
  { label: 'Apfelstädt', value: 'Apfelstädt' },
  { label: 'Apia', value: 'Apia' },
  { label: 'Apía', value: 'Apía' },
  { label: 'Apiacá', value: 'Apiacá' },
  { label: 'Apiacás', value: 'Apiacás' },
  { label: 'Apiaí', value: 'Apiaí' },
  { label: 'Apice Vecchio', value: 'Apice Vecchio' },
  { label: 'Apicum-Açu', value: 'Apicum-Açu' },
  { label: 'Apipilulco', value: 'Apipilulco' },
  { label: 'Apiro', value: 'Apiro' },
  { label: 'Apison', value: 'Apison' },
  { label: 'Apitong', value: 'Apitong' },
  { label: 'Apiúna', value: 'Apiúna' },
  { label: 'Apizaco', value: 'Apizaco' },
  { label: 'Aplaya', value: 'Aplaya' },
  { label: 'Aplaya', value: 'Aplaya' },
  { label: 'Aplington', value: 'Aplington' },
  { label: 'Apo', value: 'Apo' },
  { label: 'Apoala', value: 'Apoala' },
  { label: 'Apodaca', value: 'Apodaca' },
  { label: 'Apoderado', value: 'Apoderado' },
  { label: 'Apodi', value: 'Apodi' },
  { label: 'Apold', value: 'Apold' },
  { label: 'Apolda', value: 'Apolda' },
  { label: 'Apoldu De Jos', value: 'Apoldu De Jos' },
  { label: 'Apolinario Saravia', value: 'Apolinario Saravia' },
  { label: 'Apollensdorf', value: 'Apollensdorf' },
  { label: 'Apollo', value: 'Apollo' },
  { label: 'Apollo Bay', value: 'Apollo Bay' },
  { label: 'Apollo Beach', value: 'Apollo Beach' },
  { label: 'Apollosa', value: 'Apollosa' },
  { label: 'Apomu', value: 'Apomu' },
  { label: 'Apopa', value: 'Apopa' },
  { label: 'Apopka', value: 'Apopka' },
  { label: 'Aporá', value: 'Aporá' },
  { label: 'Aporé', value: 'Aporé' },
  { label: 'Aporo', value: 'Aporo' },
  { label: 'Apostag', value: 'Apostag' },
  { label: 'Apostolache', value: 'Apostolache' },
  { label: 'Apostolove', value: 'Apostolove' },
  { label: 'Apoya', value: 'Apoya' },
  { label: 'Apozol', value: 'Apozol' },
  { label: 'Apozol De Gutiérrez', value: 'Apozol De Gutiérrez' },
  { label: 'Appalachia', value: 'Appalachia' },
  { label: 'Appanoose County', value: 'Appanoose County' },
  { label: 'Appel', value: 'Appel' },
  { label: 'Appelscha', value: 'Appelscha' },
  { label: 'Appen', value: 'Appen' },
  { label: 'Appenheim', value: 'Appenheim' },
  { label: 'Appenzell', value: 'Appenzell' },
  { label: 'Appiano Gentile', value: 'Appiano Gentile' },
  { label: 'Appietto', value: 'Appietto' },
  { label: 'Appignano', value: 'Appignano' },
  { label: 'Appignano Del Tronto', value: 'Appignano Del Tronto' },
  { label: 'Appin', value: 'Appin' },
  { label: 'Appingedam', value: 'Appingedam' },
  { label: 'Apple Creek', value: 'Apple Creek' },
  { label: 'Apple Mountain Lake', value: 'Apple Mountain Lake' },
  { label: 'Apple Valley', value: 'Apple Valley' },
  { label: 'Apple Valley', value: 'Apple Valley' },
  { label: 'Apple Valley', value: 'Apple Valley' },
  { label: 'Appleby', value: 'Appleby' },
  { label: 'Appleby-In-Westmorland', value: 'Appleby-In-Westmorland' },
  { label: 'Applecross', value: 'Applecross' },
  { label: 'Appledore', value: 'Appledore' },
  { label: 'Apples', value: 'Apples' },
  { label: 'Appleton', value: 'Appleton' },
  { label: 'Appleton', value: 'Appleton' },
  { label: 'Appleton', value: 'Appleton' },
  { label: 'Appleton City', value: 'Appleton City' },
  { label: 'Appleton Thorn', value: 'Appleton Thorn' },
  { label: 'Applewood', value: 'Applewood' },
  { label: 'Appley Bridge', value: 'Appley Bridge' },
  { label: 'Appling County', value: 'Appling County' },
  { label: 'Appoigny', value: 'Appoigny' },
  { label: 'Appomattox', value: 'Appomattox' },
  { label: 'Appomattox County', value: 'Appomattox County' },
  { label: 'Apprieu', value: 'Apprieu' },
  { label: 'Aprelevka', value: 'Aprelevka' },
  { label: 'Apremont', value: 'Apremont' },
  { label: 'Aprica', value: 'Aprica' },
  { label: 'Apricale', value: 'Apricale' },
  { label: 'Apricena', value: 'Apricena' },
  { label: 'Aprigliano', value: 'Aprigliano' },
  { label: 'Aprilia', value: 'Aprilia' },
  { label: 'Apriltsi', value: 'Apriltsi' },
  { label: 'Aprozi', value: 'Aprozi' },
  { label: 'Ápsalos', value: 'Ápsalos' },
  { label: 'Apsheronsk', value: 'Apsheronsk' },
  { label: 'Apt', value: 'Apt' },
  { label: 'Aptos', value: 'Aptos' },
  { label: 'Aptos Hills-Larkin Valley', value: 'Aptos Hills-Larkin Valley' },
  { label: 'Apuarema', value: 'Apuarema' },
  { label: 'Apucarana', value: 'Apucarana' },
  { label: 'Apud', value: 'Apud' },
  { label: 'Apuí', value: 'Apuí' },
  { label: 'Apuiarés', value: 'Apuiarés' },
  { label: 'Apúlia', value: 'Apúlia' },
  { label: 'Apúlia E Fão', value: 'Apúlia E Fão' },
  { label: 'Apulo', value: 'Apulo' },
  { label: 'Apurawan', value: 'Apurawan' },
  { label: 'Aputzio De Juárez', value: 'Aputzio De Juárez' },
  { label: 'Aq Bolagh-E Aqdaq', value: 'Aq Bolagh-E Aqdaq' },
  { label: 'Aq Bolagh-E Latgah', value: 'Aq Bolagh-E Latgah' },
  { label: 'Aq Bolagh-E Morshed', value: 'Aq Bolagh-E Morshed' },
  { label: 'Aq Chay', value: 'Aq Chay' },
  { label: 'Aq Qala', value: 'Aq Qala' },
  { label: 'Aq Qayeh', value: 'Aq Qayeh' },
  { label: 'Aqa Bolaghi', value: 'Aqa Bolaghi' },
  { label: 'Aqa Jan Bolaghi', value: 'Aqa Jan Bolaghi' },
  { label: 'Aqaba', value: 'Aqaba' },
  { label: 'Aqadyr', value: 'Aqadyr' },
  { label: 'Aqaj', value: 'Aqaj' },
  { label: 'Aqbaqay', value: 'Aqbaqay' },
  { label: 'Āqchah', value: 'Āqchah' },
  { label: 'Aqcheh Kharabeh', value: 'Aqcheh Kharabeh' },
  { label: 'Aqchelu', value: 'Aqchelu' },
  { label: 'Aqda', value: 'Aqda' },
  { label: 'Aqkend', value: 'Aqkend' },
  { label: 'Aqrah', value: 'Aqrah' },
  { label: 'Aqsay', value: 'Aqsay' },
  { label: 'Aqshataū', value: 'Aqshataū' },
  { label: 'Aqtoghay', value: 'Aqtoghay' },
  { label: 'Aqtoghay Aūdany', value: 'Aqtoghay Aūdany' },
  { label: 'Aquara', value: 'Aquara' },
  { label: 'Aquebogue', value: 'Aquebogue' },
  { label: 'Aquia Harbour', value: 'Aquia Harbour' },
  { label: 'Aquidabã', value: 'Aquidabã' },
  { label: 'Aquidauana', value: 'Aquidauana' },
  { label: 'Aquila', value: 'Aquila' },
  { label: 'Aquila', value: 'Aquila' },
  { label: 'Aquila Di Arroscia', value: 'Aquila Di Arroscia' },
  { label: 'Aquileia', value: 'Aquileia' },
  { label: 'Aquiles Córdova Morán', value: 'Aquiles Córdova Morán' },
  { label: 'Aquiles Serdán', value: 'Aquiles Serdán' },
  { label: 'Aquiles Serdán', value: 'Aquiles Serdán' },
  { label: 'Aquiles Serdán', value: 'Aquiles Serdán' },
  { label: 'Aquiles Serdán', value: 'Aquiles Serdán' },
  { label: 'Aquiles Serdán', value: 'Aquiles Serdán' },
  { label: 'Aquiles Serdán', value: 'Aquiles Serdán' },
  { label: 'Aquiles Serdán', value: 'Aquiles Serdán' },
  { label: 'Aquiles Serdán', value: 'Aquiles Serdán' },
  { label: 'Aquiles Serdán (San Fernando)', value: 'Aquiles Serdán (San Fernando)' },
  { label: 'Aquilonia', value: 'Aquilonia' },
  { label: 'Aquin', value: 'Aquin' },
  { label: 'Aquino', value: 'Aquino' },
  { label: 'Aquino', value: 'Aquino' },
  { label: 'Aquiraz', value: 'Aquiraz' },
  { label: 'Aquismón', value: 'Aquismón' },
  { label: 'Aquitania', value: 'Aquitania' },
  { label: 'Aquixtla', value: 'Aquixtla' },
  { label: 'Ar Rabbah', value: 'Ar Rabbah' },
  { label: 'Ar Rābiyah', value: 'Ar Rābiyah' },
  { label: 'Ar Rabwah As Sufla', value: 'Ar Rabwah As Sufla' },
  { label: 'Ar Radmah', value: 'Ar Radmah' },
  { label: 'Ar Rafah', value: 'Ar Rafah' },
  { label: 'Ar Rahad', value: 'Ar Rahad' },
  { label: 'Ar Ramthā', value: 'Ar Ramthā' },
  { label: 'Ar Raqqah', value: 'Ar Raqqah' },
  { label: 'Ar Rass', value: 'Ar Rass' },
  { label: 'Ar Rastan', value: 'Ar Rastan' },
  { label: 'Ar Rawdah', value: 'Ar Rawdah' },
  { label: 'Ar Rawdah Ash Shamaliyah', value: 'Ar Rawdah Ash Shamaliyah' },
  { label: 'Ar Raydah Wa Qusayar', value: 'Ar Raydah Wa Qusayar' },
  { label: 'Ar Rayn', value: 'Ar Rayn' },
  { label: 'Ar Rayyān', value: 'Ar Rayyān' },
  { label: 'Ar Rifā‘', value: 'Ar Rifā‘' },
  { label: 'Ar Riqqah', value: 'Ar Riqqah' },
  { label: 'Ar Rudaymah', value: 'Ar Rudaymah' },
  { label: 'Ar Rudayyif', value: 'Ar Rudayyif' },
  { label: 'Ar Rujum', value: 'Ar Rujum' },
  { label: 'Ar Rukubah', value: 'Ar Rukubah' },
  { label: 'Ar Rumaythah', value: 'Ar Rumaythah' },
  { label: 'Ar Rumaythīyah', value: 'Ar Rumaythīyah' },
  { label: 'Ar Ruseris', value: 'Ar Ruseris' },
  { label: 'Ar Ruţbah', value: 'Ar Ruţbah' },
  { label: 'Ar Ruways', value: 'Ar Ruways' },
  { label: 'Ar Ryashyyah', value: 'Ar Ryashyyah' },
  { label: 'Ar’Ya', value: 'Ar’Ya' },
  { label: 'Ara Nova', value: 'Ara Nova' },
  { label: 'Araal', value: 'Araal' },
  { label: 'Arab', value: 'Arab' },
  { label: 'Araban', value: 'Araban' },
  { label: 'Arabayona De Mógica', value: 'Arabayona De Mógica' },
  { label: 'Arabi', value: 'Arabi' },
  { label: 'Arabkir', value: 'Arabkir' },
  { label: 'Arabutã', value: 'Arabutã' },
  { label: 'Araç', value: 'Araç' },
  { label: 'Araçagi', value: 'Araçagi' },
  { label: 'Araçaí', value: 'Araçaí' },
  { label: 'Aracaju', value: 'Aracaju' },
  { label: 'Araçariguama', value: 'Araçariguama' },
  { label: 'Araçás', value: 'Araçás' },
  { label: 'Aracataca', value: 'Aracataca' },
  { label: 'Aracati', value: 'Aracati' },
  { label: 'Aracatu', value: 'Aracatu' },
  { label: 'Araçatuba', value: 'Araçatuba' },
  { label: 'Araceli', value: 'Araceli' },
  { label: 'Aracena', value: 'Aracena' },
  { label: 'Arâches-La-Frasse', value: 'Arâches-La-Frasse' },
  { label: 'Aráchova', value: 'Aráchova' },
  { label: 'Araci', value: 'Araci' },
  { label: 'Araci', value: 'Araci' },
  { label: 'Aracitaba', value: 'Aracitaba' },
  { label: 'Aracoiaba', value: 'Aracoiaba' },
  { label: 'Araçoiaba', value: 'Araçoiaba' },
  { label: 'Araçoiaba Da Serra', value: 'Araçoiaba Da Serra' },
  { label: 'Aracruz', value: 'Aracruz' },
  { label: 'Araçu', value: 'Araçu' },
  { label: 'Araçuaí', value: 'Araçuaí' },
  { label: 'Arad', value: 'Arad' },
  { label: 'Arad', value: 'Arad' },
  { label: 'Arada', value: 'Arada' },
  { label: 'Aradac', value: 'Aradac' },
  { label: 'Aradan', value: 'Aradan' },
  { label: 'Aradas', value: 'Aradas' },
  { label: 'Aradeo', value: 'Aradeo' },
  { label: 'Aradíppou', value: 'Aradíppou' },
  { label: 'Arafat', value: 'Arafat' },
  { label: 'Arafo', value: 'Arafo' },
  { label: 'Arag', value: 'Arag' },
  { label: 'Aragarças', value: 'Aragarças' },
  { label: 'Aragats', value: 'Aragats' },
  { label: 'Aragoiânia', value: 'Aragoiânia' },
  { label: 'Aragominas', value: 'Aragominas' },
  { label: 'Aragon', value: 'Aragon' },
  { label: 'Aragona', value: 'Aragona' },
  { label: 'Aragua De Barcelona', value: 'Aragua De Barcelona' },
  { label: 'Araguacema', value: 'Araguacema' },
  { label: 'Araguaçu', value: 'Araguaçu' },
  { label: 'Araguaiana', value: 'Araguaiana' },
  { label: 'Araguaína', value: 'Araguaína' },
  { label: 'Araguainha', value: 'Araguainha' },
  { label: 'Araguanã', value: 'Araguanã' },
  { label: 'Araguanã', value: 'Araguanã' },
  { label: 'Araguapaz', value: 'Araguapaz' },
  { label: 'Araguari', value: 'Araguari' },
  { label: 'Araguatins', value: 'Araguatins' },
  { label: 'Aragüés Del Puerto', value: 'Aragüés Del Puerto' },
  { label: 'Arahal', value: 'Arahal' },
  { label: 'Arahuetes', value: 'Arahuetes' },
  { label: 'Arai', value: 'Arai' },
  { label: 'Araioses', value: 'Araioses' },
  { label: 'Araitz', value: 'Araitz' },
  { label: 'Arak', value: 'Arak' },
  { label: 'Arak’S', value: 'Arak’S' },
  { label: 'Arakaldo', value: 'Arakaldo' },
  { label: 'Arakawa Ku', value: 'Arakawa Ku' },
  { label: 'Arakil', value: 'Arakil' },
  { label: 'Arakkonam', value: 'Arakkonam' },
  { label: 'Araklı', value: 'Araklı' },
  { label: 'Aral', value: 'Aral' },
  { label: 'Aral', value: 'Aral' },
  { label: 'Aral Moreira', value: 'Aral Moreira' },
  { label: 'Aralez', value: 'Aralez' },
  { label: 'Aralık', value: 'Aralık' },
  { label: 'Araluen', value: 'Araluen' },
  { label: 'Arama', value: 'Arama' },
  { label: 'Aramaio', value: 'Aramaio' },
  { label: 'Aramari', value: 'Aramari' },
  { label: 'Aramawayan', value: 'Aramawayan' },
  { label: 'Aramayuan', value: 'Aramayuan' },
  { label: 'Arambagh Community Development Block', value: 'Arambagh Community Development Block' },
  { label: 'Arambaré', value: 'Arambaré' },
  { label: 'Aramberri', value: 'Aramberri' },
  { label: 'Arambol', value: 'Arambol' },
  { label: 'Arame', value: 'Arame' },
  { label: 'Aramecina', value: 'Aramecina' },
  { label: 'Aramengo', value: 'Aramengo' },
  { label: 'Aramil', value: 'Aramil' },
  { label: 'Aramina', value: 'Aramina' },
  { label: 'Aramoko-Ekiti', value: 'Aramoko-Ekiti' },
  { label: 'Aramon', value: 'Aramon' },
  { label: 'Aramus', value: 'Aramus' },
  { label: 'Aran', value: 'Aran' },
  { label: 'Aran Va Bidgol', value: 'Aran Va Bidgol' },
  { label: 'Arana Hills', value: 'Arana Hills' },
  { label: 'Aranarache', value: 'Aranarache' },
  { label: 'Aranarache/Aranaratxe', value: 'Aranarache/Aranaratxe' },
  { label: 'Aranas Sur', value: 'Aranas Sur' },
  { label: 'Arancón', value: 'Arancón' },
  { label: 'Aranda', value: 'Aranda' },
  { label: 'Aranda', value: 'Aranda' },
  { label: 'Aranda De Duero', value: 'Aranda De Duero' },
  { label: 'Aranda De Moncayo', value: 'Aranda De Moncayo' },
  { label: 'Arandas', value: 'Arandas' },
  { label: 'Arandas', value: 'Arandas' },
  { label: 'Aranđelovac', value: 'Aranđelovac' },
  { label: 'Arándiga', value: 'Arándiga' },
  { label: 'Arandilla', value: 'Arandilla' },
  { label: 'Arandilla Del Arroyo', value: 'Arandilla Del Arroyo' },
  { label: 'Arandis', value: 'Arandis' },
  { label: 'Arandu', value: 'Arandu' },
  { label: 'Arang', value: 'Arang' },
  { label: 'Aranga', value: 'Aranga' },
  { label: 'Arangaon', value: 'Arangaon' },
  { label: 'Aranguren', value: 'Aranguren' },
  { label: 'Aranguren', value: 'Aranguren' },
  { label: 'Arani', value: 'Arani' },
  { label: 'Aranjuez', value: 'Aranjuez' },
  { label: 'Arano', value: 'Arano' },
  { label: 'Aranos', value: 'Aranos' },
  { label: 'Aransas County', value: 'Aransas County' },
  { label: 'Aransas Pass', value: 'Aransas Pass' },
  { label: 'Arantangi', value: 'Arantangi' },
  { label: 'Arantepacua', value: 'Arantepacua' },
  { label: 'Arantina', value: 'Arantina' },
  { label: 'Arantza', value: 'Arantza' },
  { label: 'Arantzazu', value: 'Arantzazu' },
  { label: 'Arañuel', value: 'Arañuel' },
  { label: 'Aranuka', value: 'Aranuka' },
  { label: 'Aranyaprathet', value: 'Aranyaprathet' },
  { label: 'Aranyosapáti', value: 'Aranyosapáti' },
  { label: 'Aranza', value: 'Aranza' },
  { label: 'Aranzazu', value: 'Aranzazu' },
  { label: 'Aranzueque', value: 'Aranzueque' },
  { label: 'Arao Shi', value: 'Arao Shi' },
  { label: 'Araouane', value: 'Araouane' },
  { label: 'Arapaho', value: 'Arapaho' },
  { label: 'Arapahoe', value: 'Arapahoe' },
  { label: 'Arapahoe', value: 'Arapahoe' },
  { label: 'Arapahoe County', value: 'Arapahoe County' },
  { label: 'Arapeí', value: 'Arapeí' },
  { label: 'Arapgir', value: 'Arapgir' },
  { label: 'Arapiles', value: 'Arapiles' },
  { label: 'Arapiraca', value: 'Arapiraca' },
  { label: 'Arapoema', value: 'Arapoema' },
  { label: 'Araponga', value: 'Araponga' },
  { label: 'Arapongas', value: 'Arapongas' },
  { label: 'Araporã', value: 'Araporã' },
  { label: 'Arapoti', value: 'Arapoti' },
  { label: 'Arapuá', value: 'Arapuá' },
  { label: 'Arapuã', value: 'Arapuã' },
  { label: 'Araputanga', value: 'Araputanga' },
  { label: 'Araquari', value: 'Araquari' },
  { label: 'Arar', value: 'Arar' },
  { label: 'Arara', value: 'Arara' },
  { label: 'Araranguá', value: 'Araranguá' },
  { label: 'Araraquara', value: 'Araraquara' },
  { label: 'Araras', value: 'Araras' },
  { label: 'Ararat', value: 'Ararat' },
  { label: 'Ararat', value: 'Ararat' },
  { label: 'Ararendá', value: 'Ararendá' },
  { label: 'Arari', value: 'Arari' },
  { label: 'Araria', value: 'Araria' },
  { label: 'Araricá', value: 'Araricá' },
  { label: 'Araripe', value: 'Araripe' },
  { label: 'Araripina', value: 'Araripina' },
  { label: 'Araró', value: 'Araró' },
  { label: 'Araruama', value: 'Araruama' },
  { label: 'Araruna', value: 'Araruna' },
  { label: 'Araruna', value: 'Araruna' },
  { label: 'Aras', value: 'Aras' },
  { label: 'Aras De Los Olmos', value: 'Aras De Los Olmos' },
  { label: 'Aras-Asan', value: 'Aras-Asan' },
  { label: 'Aras-Asan', value: 'Aras-Asan' },
  { label: 'Arasat Alhindiya', value: 'Arasat Alhindiya' },
  { label: 'Arashiyama', value: 'Arashiyama' },
  { label: 'Arata', value: 'Arata' },
  { label: 'Arataca', value: 'Arataca' },
  { label: 'Arateh', value: 'Arateh' },
  { label: 'Aratiba', value: 'Aratiba' },
  { label: 'Aratichanguío', value: 'Aratichanguío' },
  { label: 'Aratoca', value: 'Aratoca' },
  { label: 'Áratos', value: 'Áratos' },
  { label: 'Aratuba', value: 'Aratuba' },
  { label: 'Aratuípe', value: 'Aratuípe' },
  { label: 'Arauá', value: 'Arauá' },
  { label: 'Arauca', value: 'Arauca' },
  { label: 'Araucária', value: 'Araucária' },
  { label: 'Arauco', value: 'Arauco' },
  { label: 'Arauco', value: 'Arauco' },
  { label: 'Araújos', value: 'Araújos' },
  { label: 'Araul', value: 'Araul' },
  { label: 'Araulí', value: 'Araulí' },
  { label: 'Arauquita', value: 'Arauquita' },
  { label: 'Araure', value: 'Araure' },
  { label: 'Arauzo De Miel', value: 'Arauzo De Miel' },
  { label: 'Arauzo De Salce', value: 'Arauzo De Salce' },
  { label: 'Arauzo De Torre', value: 'Arauzo De Torre' },
  { label: 'Aravissós', value: 'Aravissós' },
  { label: 'Arawa', value: 'Arawa' },
  { label: 'Araxá', value: 'Araxá' },
  { label: 'Arayat', value: 'Arayat' },
  { label: 'Arayat', value: 'Arayat' },
  { label: 'Arazane', value: 'Arazane' },
  { label: 'Arazap’', value: 'Arazap’' },
  { label: 'Arazede', value: 'Arazede' },
  { label: 'Arba', value: 'Arba' },
  { label: 'Arba Minch', value: 'Arba Minch' },
  { label: 'Arbancón', value: 'Arbancón' },
  { label: 'Arbaoua', value: 'Arbaoua' },
  { label: 'Arbat’', value: 'Arbat’' },
  { label: 'Arbatache', value: 'Arbatache' },
  { label: 'Arbatax', value: 'Arbatax' },
  { label: 'Arbazh', value: 'Arbazh' },
  { label: 'Arbeca', value: 'Arbeca' },
  { label: 'Arbeláez', value: 'Arbeláez' },
  { label: 'Arbent', value: 'Arbent' },
  { label: 'Arberg', value: 'Arberg' },
  { label: 'Arbesbach', value: 'Arbesbach' },
  { label: 'Arbeteta', value: 'Arbeteta' },
  { label: 'Arbia', value: 'Arbia' },
  { label: 'Arbil', value: 'Arbil' },
  { label: 'Arbizu', value: 'Arbizu' },
  { label: 'Arbizzano-Santa Maria', value: 'Arbizzano-Santa Maria' },
  { label: 'Arbo', value: 'Arbo' },
  { label: 'Arboç', value: 'Arboç' },
  { label: 'Arboga', value: 'Arboga' },
  { label: 'Arbois', value: 'Arbois' },
  { label: 'Arbolada Los Sauces', value: 'Arbolada Los Sauces' },
  { label: 'Arboleas', value: 'Arboleas' },
  { label: 'Arboleda', value: 'Arboleda' },
  { label: 'Arboledas', value: 'Arboledas' },
  { label: 'Arboledas', value: 'Arboledas' },
  { label: 'Arboledas Paso Blanco [Fraccionamiento]', value: 'Arboledas Paso Blanco [Fraccionamiento]' },
  { label: 'Arboletes', value: 'Arboletes' },
  { label: 'Arbolí', value: 'Arbolí' },
  { label: 'Arbolillo', value: 'Arbolillo' },
  { label: 'Arbon', value: 'Arbon' },
  { label: 'Arbon District', value: 'Arbon District' },
  { label: 'Arbonne', value: 'Arbonne' },
  { label: 'Arbonne-La-Forêt', value: 'Arbonne-La-Forêt' },
  { label: 'Arbore', value: 'Arbore' },
  { label: 'Arborea', value: 'Arborea' },
  { label: 'Arborio', value: 'Arborio' },
  { label: 'Arbouans', value: 'Arbouans' },
  { label: 'Arbrå', value: 'Arbrå' },
  { label: 'Arbroath', value: 'Arbroath' },
  { label: 'Arbúcies', value: 'Arbúcies' },
  { label: 'Arbuckle', value: 'Arbuckle' },
  { label: 'Arbus', value: 'Arbus' },
  { label: 'Arbus', value: 'Arbus' },
  { label: 'Arbutus', value: 'Arbutus' },
  { label: 'Arbutus Ridge', value: 'Arbutus Ridge' },
  { label: 'Arbuzynka', value: 'Arbuzynka' },
  { label: 'Arçabil', value: 'Arçabil' },
  { label: 'Arcabuco', value: 'Arcabuco' },
  { label: 'Arcachon', value: 'Arcachon' },
  { label: 'Arcade', value: 'Arcade' },
  { label: 'Arcade', value: 'Arcade' },
  { label: 'Arcade', value: 'Arcade' },
  { label: 'Arcadia', value: 'Arcadia' },
  { label: 'Arcadia', value: 'Arcadia' },
  { label: 'Arcadia', value: 'Arcadia' },
  { label: 'Arcadia', value: 'Arcadia' },
  { label: 'Arcadia', value: 'Arcadia' },
  { label: 'Arcadia', value: 'Arcadia' },
  { label: 'Arcadia', value: 'Arcadia' },
  { label: 'Arcadia', value: 'Arcadia' },
  { label: 'Arcadia', value: 'Arcadia' },
  { label: 'Arcadia', value: 'Arcadia' },
  { label: 'Arcadia Vale', value: 'Arcadia Vale' },
  { label: 'Arcahaie', value: 'Arcahaie' },
  { label: 'Arcahaie', value: 'Arcahaie' },
  { label: 'Arcangel', value: 'Arcangel' },
  { label: 'Arcangues', value: 'Arcangues' },
  { label: 'Arcani', value: 'Arcani' },
  { label: 'Arcanum', value: 'Arcanum' },
  { label: 'Arcas Del Villar', value: 'Arcas Del Villar' },
  { label: 'Arcata', value: 'Arcata' },
  { label: 'Arcavacata', value: 'Arcavacata' },
  { label: 'Arce', value: 'Arce' },
  { label: 'Arce/Artzi', value: 'Arce/Artzi' },
  { label: 'Arceburgo', value: 'Arceburgo' },
  { label: 'Arcediano', value: 'Arcediano' },
  { label: 'Arcelia', value: 'Arcelia' },
  { label: 'Arcelia De Rodríguez', value: 'Arcelia De Rodríguez' },
  { label: 'Arcen', value: 'Arcen' },
  { label: 'Arcene', value: 'Arcene' },
  { label: 'Arcenillas', value: 'Arcenillas' },
  { label: 'Arceto', value: 'Arceto' },
  { label: 'Arc-Et-Senans', value: 'Arc-Et-Senans' },
  { label: 'Arcevia', value: 'Arcevia' },
  { label: 'Arcey', value: 'Arcey' },
  { label: 'Arch', value: 'Arch' },
  { label: 'Archaía Olympía', value: 'Archaía Olympía' },
  { label: 'Archamps', value: 'Archamps' },
  { label: 'Archángelos', value: 'Archángelos' },
  { label: 'Archbald', value: 'Archbald' },
  { label: 'Archbold', value: 'Archbold' },
  { label: 'Archdale', value: 'Archdale' },
  { label: 'Archedino-Chernushinsky', value: 'Archedino-Chernushinsky' },
  { label: 'Archedinskaya', value: 'Archedinskaya' },
  { label: 'Archena', value: 'Archena' },
  { label: 'Archer', value: 'Archer' },
  { label: 'Archer City', value: 'Archer City' },
  { label: 'Archer County', value: 'Archer County' },
  { label: 'Archer Lodge', value: 'Archer Lodge' },
  { label: 'Arches', value: 'Arches' },
  { label: 'Archettes', value: 'Archettes' },
  { label: 'Árchez', value: 'Árchez' },
  { label: 'Archi', value: 'Archi' },
  { label: 'Archidona', value: 'Archidona' },
  { label: 'Archidona', value: 'Archidona' },
  { label: 'Archie', value: 'Archie' },
  { label: 'Archigny', value: 'Archigny' },
  { label: 'Archis', value: 'Archis' },
  { label: 'Archiş', value: 'Archiş' },
  { label: 'Archita', value: 'Archita' },
  { label: 'Archontochóri', value: 'Archontochóri' },
  { label: 'Archuleta County', value: 'Archuleta County' },
  { label: 'Archway', value: 'Archway' },
  { label: 'Arcicóllar', value: 'Arcicóllar' },
  { label: 'Arcidosso', value: 'Arcidosso' },
  { label: 'Arci-Empolitana', value: 'Arci-Empolitana' },
  { label: 'Arcila', value: 'Arcila' },
  { label: 'Arcinas', value: 'Arcinas' },
  { label: 'Arcinazzo Romano', value: 'Arcinazzo Romano' },
  { label: 'Arcisate', value: 'Arcisate' },
  { label: 'Arcis-Sur-Aube', value: 'Arcis-Sur-Aube' },
  { label: 'Arc-Lès-Gray', value: 'Arc-Lès-Gray' },
  { label: 'Arco', value: 'Arco' },
  { label: 'Arco', value: 'Arco' },
  { label: 'Arco Da Calheta', value: 'Arco Da Calheta' },
  { label: 'Arco El', value: 'Arco El' },
  { label: 'Arco-Íris', value: 'Arco-Íris' },
  { label: 'Arcola', value: 'Arcola' },
  { label: 'Arcola', value: 'Arcola' },
  { label: 'Arcola', value: 'Arcola' },
  { label: 'Arcole', value: 'Arcole' },
  { label: 'Arconada', value: 'Arconada' },
  { label: 'Arconate', value: 'Arconate' },
  { label: 'Arcones', value: 'Arcones' },
  { label: 'Arçonnay', value: 'Arçonnay' },
  { label: 'Arcore', value: 'Arcore' },
  { label: 'Arcos', value: 'Arcos' },
  { label: 'Arcos', value: 'Arcos' },
  { label: 'Arcos', value: 'Arcos' },
  { label: 'Arcos', value: 'Arcos' },
  { label: 'Arcos De Jalón', value: 'Arcos De Jalón' },
  { label: 'Arcos De La Frontera', value: 'Arcos De La Frontera' },
  { label: 'Arcos De La Polvorosa', value: 'Arcos De La Polvorosa' },
  { label: 'Arcos De La Sierra', value: 'Arcos De La Sierra' },
  { label: 'Arcos De Las Salinas', value: 'Arcos De Las Salinas' },
  { label: 'Arcos De Valdevez', value: 'Arcos De Valdevez' },
  { label: 'Arcos Los', value: 'Arcos Los' },
  { label: 'Arcot', value: 'Arcot' },
  { label: 'Arcoverde', value: 'Arcoverde' },
  { label: 'Arcozelo', value: 'Arcozelo' },
  { label: 'Arcozelo', value: 'Arcozelo' },
  { label: 'Arc-Sur-Tille', value: 'Arc-Sur-Tille' },
  { label: 'Arcueil', value: 'Arcueil' },
  { label: 'Arcugnano-Torri', value: 'Arcugnano-Torri' },
  { label: 'Arcuș', value: 'Arcuș' },
  { label: 'Ardabil', value: 'Ardabil' },
  { label: 'Ardagger Stift', value: 'Ardagger Stift' },
  { label: 'Ardagh', value: 'Ardagh' },
  { label: 'Ardakan', value: 'Ardakan' },
  { label: 'Ardal', value: 'Ardal' },
  { label: 'Årdal', value: 'Årdal' },
  { label: 'Ardales', value: 'Ardales' },
  { label: 'Årdalstangen', value: 'Årdalstangen' },
  { label: 'Ardanuç', value: 'Ardanuç' },
  { label: 'Ardara', value: 'Ardara' },
  { label: 'Ardatov', value: 'Ardatov' },
  { label: 'Ardatov', value: 'Ardatov' },
  { label: 'Ardatovskiy Rayon', value: 'Ardatovskiy Rayon' },
  { label: 'Ardatovskiy Rayon', value: 'Ardatovskiy Rayon' },
  { label: 'Ardauli', value: 'Ardauli' },
  { label: 'Ardea', value: 'Ardea' },
  { label: 'Ardee', value: 'Ardee' },
  { label: 'Ardeer', value: 'Ardeer' },
  { label: 'Arden', value: 'Arden' },
  { label: 'Arden Hills', value: 'Arden Hills' },
  { label: 'Arden On The Severn', value: 'Arden On The Severn' },
  { label: 'Arden-Arcade', value: 'Arden-Arcade' },
  { label: 'Ardennes', value: 'Ardennes' },
  { label: 'Ardenno', value: 'Ardenno' },
  { label: 'Ardentes', value: 'Ardentes' },
  { label: 'Ardeoani', value: 'Ardeoani' },
  { label: 'Ardersier', value: 'Ardersier' },
  { label: 'Ardeşen', value: 'Ardeşen' },
  { label: 'Ardesio', value: 'Ardesio' },
  { label: 'Ardestan', value: 'Ardestan' },
  { label: 'Ardglass', value: 'Ardglass' },
  { label: 'Ardhapur', value: 'Ardhapur' },
  { label: 'Ardin', value: 'Ardin' },
  { label: 'Ardingly', value: 'Ardingly' },
  { label: 'Ardino', value: 'Ardino' },
  { label: 'Ardisa', value: 'Ardisa' },
  { label: 'Ardmore', value: 'Ardmore' },
  { label: 'Ardmore', value: 'Ardmore' },
  { label: 'Ardmore', value: 'Ardmore' },
  { label: 'Ardnacrusha', value: 'Ardnacrusha' },
  { label: 'Ardning', value: 'Ardning' },
  { label: 'Ardole San Marino', value: 'Ardole San Marino' },
  { label: 'Ardon', value: 'Ardon' },
  { label: 'Ardon', value: 'Ardon' },
  { label: 'Ardón', value: 'Ardón' },
  { label: 'Ardon’', value: 'Ardon’' },
  { label: 'Ardooie', value: 'Ardooie' },
  { label: 'Ardore', value: 'Ardore' },
  { label: 'Ardore Marina', value: 'Ardore Marina' },
  { label: 'Ardres', value: 'Ardres' },
  { label: 'Ardrishaig', value: 'Ardrishaig' },
  { label: 'Ardross', value: 'Ardross' },
  { label: 'Ardrossan', value: 'Ardrossan' },
  { label: 'Ardrossan', value: 'Ardrossan' },
  { label: 'Ards And North Down', value: 'Ards And North Down' },
  { label: 'Ardsley', value: 'Ardsley' },
  { label: 'Ardud', value: 'Ardud' },
  { label: 'Ardusat', value: 'Ardusat' },
  { label: 'Arduzel', value: 'Arduzel' },
  { label: 'Area Produttiva', value: 'Area Produttiva' },
  { label: 'Areado', value: 'Areado' },
  { label: 'Areal', value: 'Areal' },
  { label: 'Arealva', value: 'Arealva' },
  { label: 'Areatza', value: 'Areatza' },
  { label: 'Arecibo', value: 'Arecibo' },
  { label: 'Arecibo', value: 'Arecibo' },
  { label: 'Aredioú', value: 'Aredioú' },
  { label: 'Arefu', value: 'Arefu' },
  { label: 'Areguá', value: 'Areguá' },
  { label: 'Areia', value: 'Areia' },
  { label: 'Areia Branca', value: 'Areia Branca' },
  { label: 'Areia Branca', value: 'Areia Branca' },
  { label: 'Areia De Baraúnas', value: 'Areia De Baraúnas' },
  { label: 'Areial', value: 'Areial' },
  { label: 'Areias', value: 'Areias' },
  { label: 'Areiópolis', value: 'Areiópolis' },
  { label: 'Āreka', value: 'Āreka' },
  { label: 'Arellano', value: 'Arellano' },
  { label: 'Arellano', value: 'Arellano' },
  { label: 'Aremark', value: 'Aremark' },
  { label: 'Arén', value: 'Arén' },
  { label: 'Arena', value: 'Arena' },
  { label: 'Arena De Hidalgo', value: 'Arena De Hidalgo' },
  { label: 'Arena Po', value: 'Arena Po' },
  { label: 'Arenac County', value: 'Arenac County' },
  { label: 'Arenal', value: 'Arenal' },
  { label: 'Arenal', value: 'Arenal' },
  { label: 'Arenal', value: 'Arenal' },
  { label: 'Arenal De Álvarez (Arenal De Paco)', value: 'Arenal De Álvarez (Arenal De Paco)' },
  { label: 'Arenal El', value: 'Arenal El' },
  { label: 'Arenal Santa Ana', value: 'Arenal Santa Ana' },
  { label: 'Arenales De San Gregorio', value: 'Arenales De San Gregorio' },
  { label: 'Arenápolis', value: 'Arenápolis' },
  { label: 'Arenas', value: 'Arenas' },
  { label: 'Arenas', value: 'Arenas' },
  { label: 'Arenas', value: 'Arenas' },
  { label: 'Arenas', value: 'Arenas' },
  { label: 'Arenas De Iguña', value: 'Arenas De Iguña' },
  { label: 'Arenas De San Juan', value: 'Arenas De San Juan' },
  { label: 'Arenas De San Pedro', value: 'Arenas De San Pedro' },
  { label: 'Arenas Del Rey', value: 'Arenas Del Rey' },
  { label: 'Arenas Valley', value: 'Arenas Valley' },
  { label: 'Arenc', value: 'Arenc' },
  { label: 'Arendonk', value: 'Arendonk' },
  { label: 'Arenella', value: 'Arenella' },
  { label: 'Areni', value: 'Areni' },
  { label: 'Arenillas', value: 'Arenillas' },
  { label: 'Arenillas De Riopisuerga', value: 'Arenillas De Riopisuerga' },
  { label: 'Arenópolis', value: 'Arenópolis' },
  { label: 'Arenosa', value: 'Arenosa' },
  { label: 'Arenoso', value: 'Arenoso' },
  { label: 'Arens De Lledó', value: 'Arens De Lledó' },
  { label: 'Arenshausen', value: 'Arenshausen' },
  { label: 'Arenthon', value: 'Arenthon' },
  { label: 'Arenys De Lledó / Arens De Lledó', value: 'Arenys De Lledó / Arens De Lledó' },
  { label: 'Arenys De Mar', value: 'Arenys De Mar' },
  { label: 'Arenys De Munt', value: 'Arenys De Munt' },
  { label: 'Arenzana De Abajo', value: 'Arenzana De Abajo' },
  { label: 'Arenzana De Arriba', value: 'Arenzana De Arriba' },
  { label: 'Arenzano', value: 'Arenzano' },
  { label: 'Arequipa', value: 'Arequipa' },
  { label: 'Ares', value: 'Ares' },
  { label: 'Arès', value: 'Arès' },
  { label: 'Arês', value: 'Arês' },
  { label: 'Ares Del Maestrat', value: 'Ares Del Maestrat' },
  { label: 'Ares Del Maestre', value: 'Ares Del Maestre' },
  { label: 'Arese', value: 'Arese' },
  { label: 'Aresing', value: 'Aresing' },
  { label: 'Areso', value: 'Areso' },
  { label: 'Arette', value: 'Arette' },
  { label: 'Aretxabaleta', value: 'Aretxabaleta' },
  { label: 'Arevabuyr', value: 'Arevabuyr' },
  { label: 'Arevalillo', value: 'Arevalillo' },
  { label: 'Arevalillo De Cega', value: 'Arevalillo De Cega' },
  { label: 'Arévalo', value: 'Arévalo' },
  { label: 'Arévalo De La Sierra', value: 'Arévalo De La Sierra' },
  { label: 'Arevashat', value: 'Arevashat' },
  { label: 'Arevashogh', value: 'Arevashogh' },
  { label: 'Arevik', value: 'Arevik' },
  { label: 'Arevik', value: 'Arevik' },
  { label: 'Arevshat', value: 'Arevshat' },
  { label: 'Arevshat', value: 'Arevshat' },
  { label: 'Arezzo', value: 'Arezzo' },
  { label: 'Arfará', value: 'Arfará' },
  { label: 'Arfoud', value: 'Arfoud' },
  { label: 'Argáka', value: 'Argáka' },
  { label: 'Argalastí', value: 'Argalastí' },
  { label: 'Argamasilla De Alba', value: 'Argamasilla De Alba' },
  { label: 'Argamasilla De Calatrava', value: 'Argamasilla De Calatrava' },
  { label: 'Argana', value: 'Argana' },
  { label: 'Argancy', value: 'Argancy' },
  { label: 'Arganda', value: 'Arganda' },
  { label: 'Arganda Del Rey', value: 'Arganda Del Rey' },
  { label: 'Arganil', value: 'Arganil' },
  { label: 'Argañín', value: 'Argañín' },
  { label: 'Arganza', value: 'Arganza' },
  { label: 'Arganzuela', value: 'Arganzuela' },
  { label: 'Argao', value: 'Argao' },
  { label: 'Argaon', value: 'Argaon' },
  { label: 'Argavand', value: 'Argavand' },
  { label: 'Argavand', value: 'Argavand' },
  { label: 'Argavieso', value: 'Argavieso' },
  { label: 'Argayash', value: 'Argayash' },
  { label: 'Argecilla', value: 'Argecilla' },
  { label: 'Argegno', value: 'Argegno' },
  { label: 'Argel', value: 'Argel' },
  { label: 'Argel', value: 'Argel' },
  { label: 'Argelaguer', value: 'Argelaguer' },
  { label: 'Argelato', value: 'Argelato' },
  { label: 'Argelers', value: 'Argelers' },
  { label: 'Argelès-Gazost', value: 'Argelès-Gazost' },
  { label: 'Argelia', value: 'Argelia' },
  { label: 'Argelia', value: 'Argelia' },
  { label: 'Argelia', value: 'Argelia' },
  { label: 'Argeliers', value: 'Argeliers' },
  { label: 'Argelita', value: 'Argelita' },
  { label: 'Argences', value: 'Argences' },
  { label: 'Argençola', value: 'Argençola' },
  { label: 'Argenta', value: 'Argenta' },
  { label: 'Argentan', value: 'Argentan' },
  { label: 'Argentat', value: 'Argentat' },
  { label: 'Argente', value: 'Argente' },
  { label: 'Argentera', value: 'Argentera' },
  { label: 'Argenteuil', value: 'Argenteuil' },
  { label: 'Argenthal', value: 'Argenthal' },
  { label: 'Argentine', value: 'Argentine' },
  { label: 'Argenton', value: 'Argenton' },
  { label: 'Argentona', value: 'Argentona' },
  { label: 'Argenton-Les-Vallées', value: 'Argenton-Les-Vallées' },
  { label: 'Argenton-Sur-Creuse', value: 'Argenton-Sur-Creuse' },
  { label: 'Argentré', value: 'Argentré' },
  { label: 'Argentré-Du-Plessis', value: 'Argentré-Du-Plessis' },
  { label: 'Argent-Sur-Sauldre', value: 'Argent-Sur-Sauldre' },
  { label: 'Argés', value: 'Argés' },
  { label: 'Arges-E Sofla', value: 'Arges-E Sofla' },
  { label: 'Argeșelu', value: 'Argeșelu' },
  { label: 'Argetoaia', value: 'Argetoaia' },
  { label: 'Arghakhanchi ', value: 'Arghakhanchi ' },
  { label: 'Arghira', value: 'Arghira' },
  { label: 'Argine', value: 'Argine' },
  { label: 'Argirita', value: 'Argirita' },
  { label: 'Argithéa', value: 'Argithéa' },
  { label: 'Argivai', value: 'Argivai' },
  { label: 'Argo', value: 'Argo' },
  { label: 'Argo', value: 'Argo' },
  { label: 'Argoncilhe', value: 'Argoncilhe' },
  { label: 'Argoños', value: 'Argoños' },
  { label: 'Argos', value: 'Argos' },
  { label: 'Árgos', value: 'Árgos' },
  { label: 'Argos Orestiko', value: 'Argos Orestiko' },
  { label: 'Argostólion', value: 'Argostólion' },
  { label: 'Argudan', value: 'Argudan' },
  { label: 'Arguedas', value: 'Arguedas' },
  { label: 'Arguello', value: 'Arguello' },
  { label: 'Arguis', value: 'Arguis' },
  { label: 'Arguisuelas', value: 'Arguisuelas' },
  { label: 'Argujillo', value: 'Argujillo' },
  { label: 'Argun', value: 'Argun' },
  { label: 'Argungu', value: 'Argungu' },
  { label: 'Argusto', value: 'Argusto' },
  { label: 'Arguvan', value: 'Arguvan' },
  { label: 'Argyle', value: 'Argyle' },
  { label: 'Argyll And Bute', value: 'Argyll And Bute' },
  { label: 'Argyroúpoli', value: 'Argyroúpoli' },
  { label: 'Arhab', value: 'Arhab' },
  { label: 'Arhavi', value: 'Arhavi' },
  { label: 'Arhribs', value: 'Arhribs' },
  { label: 'Ari', value: 'Ari' },
  { label: 'Aria', value: 'Aria' },
  { label: 'Aria', value: 'Aria' },
  { label: 'Arial', value: 'Arial' },
  { label: 'Arian Shahr', value: 'Arian Shahr' },
  { label: 'Ariana', value: 'Ariana' },
  { label: 'Ariano', value: 'Ariano' },
  { label: 'Ariano', value: 'Ariano' },
  { label: 'Ariano Irpino-Martiri', value: 'Ariano Irpino-Martiri' },
  { label: 'Ariany', value: 'Ariany' },
  { label: 'Arias', value: 'Arias' },
  { label: 'Aribe', value: 'Aribe' },
  { label: 'Arica', value: 'Arica' },
  { label: 'Arıcak', value: 'Arıcak' },
  { label: 'Aricanduva', value: 'Aricanduva' },
  { label: 'Ariccia', value: 'Ariccia' },
  { label: 'Ariceștii Zeletin', value: 'Ariceștii Zeletin' },
  { label: 'Ariceştii-Rahtivani', value: 'Ariceştii-Rahtivani' },
  { label: 'Arico', value: 'Arico' },
  { label: 'Arida Shi', value: 'Arida Shi' },
  { label: 'Aridaía', value: 'Aridaía' },
  { label: 'Arielli', value: 'Arielli' },
  { label: 'Arienzo', value: 'Arienzo' },
  { label: 'Arieşeni', value: 'Arieşeni' },
  { label: 'Arieșu De Câmp', value: 'Arieșu De Câmp' },
  { label: 'Arieșu De Pădure', value: 'Arieșu De Pădure' },
  { label: 'Arifiye', value: 'Arifiye' },
  { label: 'Arifwala', value: 'Arifwala' },
  { label: 'Arignano', value: 'Arignano' },
  { label: 'Ariguaní', value: 'Ariguaní' },
  { label: 'Arīḩā', value: 'Arīḩā' },
  { label: 'Arija', value: 'Arija' },
  { label: 'Arijejen', value: 'Arijejen' },
  { label: 'Arikan', value: 'Arikan' },
  { label: 'Arilje', value: 'Arilje' },
  { label: 'Arima', value: 'Arima' },
  { label: 'Arimalam', value: 'Arimalam' },
  { label: 'Arimatea', value: 'Arimatea' },
  { label: 'Arimís', value: 'Arimís' },
  { label: 'Aringay', value: 'Aringay' },
  { label: 'Aringay', value: 'Aringay' },
  { label: 'Arini', value: 'Arini' },
  { label: 'Ariniş', value: 'Ariniş' },
  { label: 'Arino', value: 'Arino' },
  { label: 'Ariño', value: 'Ariño' },
  { label: 'Arinos', value: 'Arinos' },
  { label: 'Arinsal', value: 'Arinsal' },
  { label: 'Arinthod', value: 'Arinthod' },
  { label: 'Ario De Rayón', value: 'Ario De Rayón' },
  { label: 'Ario De Rosales', value: 'Ario De Rosales' },
  { label: 'Ariogala', value: 'Ariogala' },
  { label: 'Ariola', value: 'Ariola' },
  { label: 'Aripuanã', value: 'Aripuanã' },
  { label: 'Arıqdam', value: 'Arıqdam' },
  { label: 'Arıqıran', value: 'Arıqıran' },
  { label: 'Ariquemes', value: 'Ariquemes' },
  { label: 'Ariranha', value: 'Ariranha' },
  { label: 'Ariranha Do Ivaí', value: 'Ariranha Do Ivaí' },
  { label: 'Áris', value: 'Áris' },
  { label: 'Arisdorf', value: 'Arisdorf' },
  { label: 'Arish', value: 'Arish' },
  { label: 'Aristau', value: 'Aristau' },
  { label: 'Aristóbulo Del Valle', value: 'Aristóbulo Del Valle' },
  { label: 'Aristocrat Ranchettes', value: 'Aristocrat Ranchettes' },
  { label: 'Arísvi', value: 'Arísvi' },
  { label: 'Aritao', value: 'Aritao' },
  { label: 'Aritao', value: 'Aritao' },
  { label: 'Aritzo', value: 'Aritzo' },
  { label: 'Arivaca Junction', value: 'Arivaca Junction' },
  { label: 'Arivechi', value: 'Arivechi' },
  { label: 'Ariyalur', value: 'Ariyalur' },
  { label: 'Ariza', value: 'Ariza' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arizona City', value: 'Arizona City' },
  { label: 'Arizpe', value: 'Arizpe' },
  { label: 'Arizzano', value: 'Arizzano' },
  { label: 'Årjäng', value: 'Årjäng' },
  { label: 'Arjawinangun', value: 'Arjawinangun' },
  { label: 'Arjeplog', value: 'Arjeplog' },
  { label: 'Arjmand', value: 'Arjmand' },
  { label: 'Arjona', value: 'Arjona' },
  { label: 'Arjona', value: 'Arjona' },
  { label: 'Arjonilla', value: 'Arjonilla' },
  { label: 'Arkadak', value: 'Arkadak' },
  { label: 'Arkadelphia', value: 'Arkadelphia' },
  { label: 'Arkalgud', value: 'Arkalgud' },
  { label: 'Arkalochóri', value: 'Arkalochóri' },
  { label: 'Arkalyk', value: 'Arkalyk' },
  { label: 'Arkansas City', value: 'Arkansas City' },
  { label: 'Arkansas City', value: 'Arkansas City' },
  { label: 'Arkansas County', value: 'Arkansas County' },
  { label: 'Arkavaz', value: 'Arkavaz' },
  { label: 'Arkel', value: 'Arkel' },
  { label: 'Arkhaía Kórinthos', value: 'Arkhaía Kórinthos' },
  { label: 'Arkhangel’Sk', value: 'Arkhangel’Sk' },
  { label: 'Arkhangel’Skoye', value: 'Arkhangel’Skoye' },
  { label: 'Arkhara', value: 'Arkhara' },
  { label: 'Arkharinskiy Rayon', value: 'Arkharinskiy Rayon' },
  { label: 'Arkhipo-Osipovka', value: 'Arkhipo-Osipovka' },
  { label: 'Arkhipovka', value: 'Arkhipovka' },
  { label: 'Arkhonskaya', value: 'Arkhonskaya' },
  { label: 'Arki', value: 'Arki' },
  { label: 'Arklow', value: 'Arklow' },
  { label: 'Arkoma', value: 'Arkoma' },
  { label: 'Arkoúdi', value: 'Arkoúdi' },
  { label: 'Arkul’', value: 'Arkul’' },
  { label: 'Ärla', value: 'Ärla' },
  { label: 'Arlanc', value: 'Arlanc' },
  { label: 'Arlanzón', value: 'Arlanzón' },
  { label: 'Arlate', value: 'Arlate' },
  { label: 'Arlena Di Castro', value: 'Arlena Di Castro' },
  { label: 'Arlene Gardens', value: 'Arlene Gardens' },
  { label: 'Arles', value: 'Arles' },
  { label: 'Arles', value: 'Arles' },
  { label: 'Arlesega', value: 'Arlesega' },
  { label: 'Arlesey', value: 'Arlesey' },
  { label: 'Arlesheim', value: 'Arlesheim' },
  { label: 'Arleux', value: 'Arleux' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'Arlington County', value: 'Arlington County' },
  { label: 'Arlington Heights', value: 'Arlington Heights' },
  { label: 'Arlington Heights', value: 'Arlington Heights' },
  { label: 'Arlington Heights', value: 'Arlington Heights' },
  { label: 'Arlit', value: 'Arlit' },
  { label: 'Arló', value: 'Arló' },
  { label: 'Arlon', value: 'Arlon' },
  { label: 'Arlöv', value: 'Arlöv' },
  { label: 'Arluno', value: 'Arluno' },
  { label: 'Arma', value: 'Arma' },
  { label: 'Arma', value: 'Arma' },
  { label: 'Árma', value: 'Árma' },
  { label: 'Armação', value: 'Armação' },
  { label: 'Armação De Pêra', value: 'Armação De Pêra' },
  { label: 'Armação Dos Búzios', value: 'Armação Dos Búzios' },
  { label: 'Armada', value: 'Armada' },
  { label: 'Armadale', value: 'Armadale' },
  { label: 'Armadale', value: 'Armadale' },
  { label: 'Armadale', value: 'Armadale' },
  { label: 'Armagh', value: 'Armagh' },
  { label: 'Armagh City Banbridge And Craigavon', value: 'Armagh City Banbridge And Craigavon' },
  { label: 'Armallones', value: 'Armallones' },
  { label: 'Armamar', value: 'Armamar' },
  { label: 'Arman’', value: 'Arman’' },
  { label: 'Armañanzas', value: 'Armañanzas' },
  { label: 'Armanāz', value: 'Armanāz' },
  { label: 'Armando Fernández Garza', value: 'Armando Fernández Garza' },
  { label: 'Armaqankhaneh', value: 'Armaqankhaneh' },
  { label: 'Armardeh', value: 'Armardeh' },
  { label: 'Armăşeşti', value: 'Armăşeşti' },
  { label: 'Armash', value: 'Armash' },
  { label: 'Armavir', value: 'Armavir' },
  { label: 'Armavir', value: 'Armavir' },
  { label: 'Armazém', value: 'Armazém' },
  { label: 'Armbouts-Cappel', value: 'Armbouts-Cappel' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Armeniş', value: 'Armeniş' },
  { label: 'Armeno', value: 'Armeno' },
  { label: 'Armenonville-Les-Gâtineaux', value: 'Armenonville-Les-Gâtineaux' },
  { label: 'Armenta', value: 'Armenta' },
  { label: 'Armentera L', value: 'Armentera L' },
  { label: 'Armenteros', value: 'Armenteros' },
  { label: 'Armentières', value: 'Armentières' },
  { label: 'Armentières-En-Brie', value: 'Armentières-En-Brie' },
  { label: 'Armento', value: 'Armento' },
  { label: 'Armería', value: 'Armería' },
  { label: 'Armero', value: 'Armero' },
  { label: 'Armetta', value: 'Armetta' },
  { label: 'Armidale', value: 'Armidale' },
  { label: 'Armilla', value: 'Armilla' },
  { label: 'Arminia', value: 'Arminia' },
  { label: 'Arminia', value: 'Arminia' },
  { label: 'Armiñón', value: 'Armiñón' },
  { label: 'Armissan', value: 'Armissan' },
  { label: 'Armitage', value: 'Armitage' },
  { label: 'Armizonskiy Rayon', value: 'Armizonskiy Rayon' },
  { label: 'Armizonskoye', value: 'Armizonskoye' },
  { label: 'Armo', value: 'Armo' },
  { label: 'Armona', value: 'Armona' },
  { label: 'Armonk', value: 'Armonk' },
  { label: 'Armour', value: 'Armour' },
  { label: 'Armoy', value: 'Armoy' },
  { label: 'Armsheim', value: 'Armsheim' },
  { label: 'Armstrong', value: 'Armstrong' },
  { label: 'Armstrong', value: 'Armstrong' },
  { label: 'Armstrong County', value: 'Armstrong County' },
  { label: 'Armstrong County', value: 'Armstrong County' },
  { label: 'Armstrong Creek', value: 'Armstrong Creek' },
  { label: 'Armthorpe', value: 'Armthorpe' },
  { label: 'Armuña', value: 'Armuña' },
  { label: 'Armuña De Almanzora', value: 'Armuña De Almanzora' },
  { label: 'Armuña De Tajuña', value: 'Armuña De Tajuña' },
  { label: 'Armungia', value: 'Armungia' },
  { label: 'Armutlu', value: 'Armutlu' },
  { label: 'Armyansk', value: 'Armyansk' },
  { label: 'Arnac-La-Poste', value: 'Arnac-La-Poste' },
  { label: 'Arnac-Pompadour', value: 'Arnac-Pompadour' },
  { label: 'Arnad', value: 'Arnad' },
  { label: 'Arnage', value: 'Arnage' },
  { label: 'Arnaía', value: 'Arnaía' },
  { label: 'Arnara', value: 'Arnara' },
  { label: 'Arnas', value: 'Arnas' },
  { label: 'Arnasco', value: 'Arnasco' },
  { label: 'Arnaudville', value: 'Arnaudville' },
  { label: 'Arnavutköy', value: 'Arnavutköy' },
  { label: 'Arnay-Le-Duc', value: 'Arnay-Le-Duc' },
  { label: 'Arnbruck', value: 'Arnbruck' },
  { label: 'Arncliffe', value: 'Arncliffe' },
  { label: 'Arneburg', value: 'Arneburg' },
  { label: 'Arnedillo', value: 'Arnedillo' },
  { label: 'Arnedo', value: 'Arnedo' },
  { label: 'Arneiroz', value: 'Arneiroz' },
  { label: 'Arnèke', value: 'Arnèke' },
  { label: 'Arnemuiden', value: 'Arnemuiden' },
  { label: 'Arnes', value: 'Arnes' },
  { label: 'Årnes', value: 'Årnes' },
  { label: 'Arnesano', value: 'Arnesano' },
  { label: 'Arnett', value: 'Arnett' },
  { label: 'Arnett Gardens', value: 'Arnett Gardens' },
  { label: 'Arnfels', value: 'Arnfels' },
  { label: 'Arnhem', value: 'Arnhem' },
  { label: 'Arni', value: 'Arni' },
  { label: 'Arnières-Sur-Iton', value: 'Arnières-Sur-Iton' },
  { label: 'Árnissa', value: 'Árnissa' },
  { label: 'Arniston', value: 'Arniston' },
  { label: 'Arnö', value: 'Arnö' },
  { label: 'Arnö', value: 'Arnö' },
  { label: 'Arnoia A', value: 'Arnoia A' },
  { label: 'Arnold', value: 'Arnold' },
  { label: 'Arnold', value: 'Arnold' },
  { label: 'Arnold', value: 'Arnold' },
  { label: 'Arnold', value: 'Arnold' },
  { label: 'Arnold', value: 'Arnold' },
  { label: 'Arnold', value: 'Arnold' },
  { label: 'Arnold Line', value: 'Arnold Line' },
  { label: 'Arnolds Park', value: 'Arnolds Park' },
  { label: 'Arnoldstein', value: 'Arnoldstein' },
  { label: 'Arnót', value: 'Arnót' },
  { label: 'Arnouville', value: 'Arnouville' },
  { label: 'Arnprior', value: 'Arnprior' },
  { label: 'Arnreit', value: 'Arnreit' },
  { label: 'Arnsberg', value: 'Arnsberg' },
  { label: 'Arnschwang', value: 'Arnschwang' },
  { label: 'Arnside', value: 'Arnside' },
  { label: 'Arnstadt', value: 'Arnstadt' },
  { label: 'Arnstein', value: 'Arnstein' },
  { label: 'Arnstorf', value: 'Arnstorf' },
  { label: 'Arnuero', value: 'Arnuero' },
  { label: 'Aroazes', value: 'Aroazes' },
  { label: 'Aroche', value: 'Aroche' },
  { label: 'Arochukwu', value: 'Arochukwu' },
  { label: 'Aroeiras', value: 'Aroeiras' },
  { label: 'Aroeiras Do Itaim', value: 'Aroeiras Do Itaim' },
  { label: 'Arões', value: 'Arões' },
  { label: 'Arola', value: 'Arola' },
  { label: 'Arola-Preazzano', value: 'Arola-Preazzano' },
  { label: 'Aroma', value: 'Aroma' },
  { label: 'Aromas', value: 'Aromas' },
  { label: 'Aromashevo', value: 'Aromashevo' },
  { label: 'Aromatnoye', value: 'Aromatnoye' },
  { label: 'Aron', value: 'Aron' },
  { label: 'Aron', value: 'Aron' },
  { label: 'Arona', value: 'Arona' },
  { label: 'Arona', value: 'Arona' },
  { label: 'Aroneanu', value: 'Aroneanu' },
  { label: 'Aroona', value: 'Aroona' },
  { label: 'Aroor', value: 'Aroor' },
  { label: 'Aroostook County', value: 'Aroostook County' },
  { label: 'Arorae', value: 'Arorae' },
  { label: 'Aroroy', value: 'Aroroy' },
  { label: 'Åros', value: 'Åros' },
  { label: 'Arosa', value: 'Arosa' },
  { label: 'Arosemena', value: 'Arosemena' },
  { label: 'Arosio', value: 'Arosio' },
  { label: 'Arouca', value: 'Arouca' },
  { label: 'Arouca', value: 'Arouca' },
  { label: 'Årøysund', value: 'Årøysund' },
  { label: 'Arpa Darreh', value: 'Arpa Darreh' },
  { label: 'Arpaçay', value: 'Arpaçay' },
  { label: 'Arpaia', value: 'Arpaia' },
  { label: 'Arpaise', value: 'Arpaise' },
  { label: 'Arpajon', value: 'Arpajon' },
  { label: 'Arpajon-Sur-Cère', value: 'Arpajon-Sur-Cère' },
  { label: 'Arpaşu De Jos', value: 'Arpaşu De Jos' },
  { label: 'Arpașu De Sus', value: 'Arpașu De Sus' },
  { label: 'Arpino', value: 'Arpino' },
  { label: 'Arpino', value: 'Arpino' },
  { label: 'Arpke', value: 'Arpke' },
  { label: 'Arquà Petrarca', value: 'Arquà Petrarca' },
  { label: 'Arquà Polesine', value: 'Arquà Polesine' },
  { label: 'Arquata Del Tronto', value: 'Arquata Del Tronto' },
  { label: 'Arquata Scrivia', value: 'Arquata Scrivia' },
  { label: 'Arques', value: 'Arques' },
  { label: 'Arques-La-Bataille', value: 'Arques-La-Bataille' },
  { label: 'Arquillinos', value: 'Arquillinos' },
  { label: 'Arquillos', value: 'Arquillos' },
  { label: 'Arquitecto Tomás Romero Pereira', value: 'Arquitecto Tomás Romero Pereira' },
  { label: 'Arrabalde', value: 'Arrabalde' },
  { label: 'Arrach', value: 'Arrach' },
  { label: 'Arradon', value: 'Arradon' },
  { label: 'Arrah', value: 'Arrah' },
  { label: 'Arrah', value: 'Arrah' },
  { label: 'Arraial', value: 'Arraial' },
  { label: 'Arraial Do Cabo', value: 'Arraial Do Cabo' },
  { label: 'Arraia-Maeztu', value: 'Arraia-Maeztu' },
  { label: 'Arraias', value: 'Arraias' },
  { label: 'Arraiján', value: 'Arraiján' },
  { label: 'Arraiolos', value: 'Arraiolos' },
  { label: 'Ar-Raml', value: 'Ar-Raml' },
  { label: 'Arrancacepas', value: 'Arrancacepas' },
  { label: 'Arranhó', value: 'Arranhó' },
  { label: 'Arranhó', value: 'Arranhó' },
  { label: 'Arrankudiaga', value: 'Arrankudiaga' },
  { label: 'Arrap’I', value: 'Arrap’I' },
  { label: 'Ar-Raqqah District', value: 'Ar-Raqqah District' },
  { label: 'Arras', value: 'Arras' },
  { label: 'Arrasate/Mondragón', value: 'Arrasate/Mondragón' },
  { label: 'Arratzu', value: 'Arratzu' },
  { label: 'Arraya De Oca', value: 'Arraya De Oca' },
  { label: 'Arrazua-Ubarrundia', value: 'Arrazua-Ubarrundia' },
  { label: 'Arre', value: 'Arre' },
  { label: 'Arrecife', value: 'Arrecife' },
  { label: 'Arrecife', value: 'Arrecife' },
  { label: 'Arredondo', value: 'Arredondo' },
  { label: 'Arrentela', value: 'Arrentela' },
  { label: 'Arres', value: 'Arres' },
  { label: 'Arriach', value: 'Arriach' },
  { label: 'Arriaga', value: 'Arriaga' },
  { label: 'Arrianá', value: 'Arrianá' },
  { label: 'Arriate', value: 'Arriate' },
  { label: 'Arrieta', value: 'Arrieta' },
  { label: 'Arrifana', value: 'Arrifana' },
  { label: 'Arrifana', value: 'Arrifana' },
  { label: 'Arrifes', value: 'Arrifes' },
  { label: 'Arrigorriaga', value: 'Arrigorriaga' },
  { label: 'Arris', value: 'Arris' },
  { label: 'Arroba De Los Montes', value: 'Arroba De Los Montes' },
  { label: 'Arrochar', value: 'Arrochar' },
  { label: 'Arroio Do Meio', value: 'Arroio Do Meio' },
  { label: 'Arroio Do Padre', value: 'Arroio Do Padre' },
  { label: 'Arroio Do Sal', value: 'Arroio Do Sal' },
  { label: 'Arroio Do Tigre', value: 'Arroio Do Tigre' },
  { label: 'Arroio Dos Ratos', value: 'Arroio Dos Ratos' },
  { label: 'Arroio Grande', value: 'Arroio Grande' },
  { label: 'Arroio Trinta', value: 'Arroio Trinta' },
  { label: 'Arronches', value: 'Arronches' },
  { label: 'Arrondissement De Bainet', value: 'Arrondissement De Bainet' },
  { label: 'Arrondissement De Cerca La Source', value: 'Arrondissement De Cerca La Source' },
  { label: 'Arrondissement De Croix Des Bouquets', value: 'Arrondissement De Croix Des Bouquets' },
  { label: 'Arrondissement De Fort Liberté', value: 'Arrondissement De Fort Liberté' },
  { label: 'Arrondissement De Jacmel', value: 'Arrondissement De Jacmel' },
  { label: 'Arrondissement De La Grande Rivière Du Nord', value: 'Arrondissement De La Grande Rivière Du Nord' },
  { label: 'Arrondissement De Léogâne', value: 'Arrondissement De Léogâne' },
  { label: 'Arrondissement De Plaisance', value: 'Arrondissement De Plaisance' },
  { label: 'Arrondissement De Port-Au-Prince', value: 'Arrondissement De Port-Au-Prince' },
  { label: 'Arrondissement De Port-De-Paix', value: 'Arrondissement De Port-De-Paix' },
  { label: 'Arrondissement De Port-Salut', value: 'Arrondissement De Port-Salut' },
  { label: 'Arrondissement De Saint-Louis Du Nord', value: 'Arrondissement De Saint-Louis Du Nord' },
  { label: 'Arrondissement De Saint-Marc', value: 'Arrondissement De Saint-Marc' },
  { label: 'Arrondissement Des Cayes', value: 'Arrondissement Des Cayes' },
  { label: 'Arrondissement Du Borgne', value: 'Arrondissement Du Borgne' },
  { label: 'Arrondissement Du Môle Saint-Nicolas', value: 'Arrondissement Du Môle Saint-Nicolas' },
  { label: 'Arrondissement Du Trou Du Nord', value: 'Arrondissement Du Trou Du Nord' },
  { label: 'Arrone', value: 'Arrone' },
  { label: 'Arróniz', value: 'Arróniz' },
  { label: 'Arrou', value: 'Arrou' },
  { label: 'Arrowtown', value: 'Arrowtown' },
  { label: 'Arroyito', value: 'Arroyito' },
  { label: 'Arroyo', value: 'Arroyo' },
  { label: 'Arroyo', value: 'Arroyo' },
  { label: 'Arroyo Cabral', value: 'Arroyo Cabral' },
  { label: 'Arroyo Chical (Nuevo Arroyo Chicali)', value: 'Arroyo Chical (Nuevo Arroyo Chicali)' },
  { label: 'Arroyo Choápam', value: 'Arroyo Choápam' },
  { label: 'Arroyo De Banco', value: 'Arroyo De Banco' },
  { label: 'Arroyo De Enmedio', value: 'Arroyo De Enmedio' },
  { label: 'Arroyo De La Encomienda', value: 'Arroyo De La Encomienda' },
  { label: 'Arroyo De La Luz', value: 'Arroyo De La Luz' },
  { label: 'Arroyo De Las Fraguas', value: 'Arroyo De Las Fraguas' },
  { label: 'Arroyo De San Serván', value: 'Arroyo De San Serván' },
  { label: 'Arroyo Del Maíz Uno', value: 'Arroyo Del Maíz Uno' },
  { label: 'Arroyo Del Medio', value: 'Arroyo Del Medio' },
  { label: 'Arroyo Del Ojanco', value: 'Arroyo Del Ojanco' },
  { label: 'Arroyo Del Potrero', value: 'Arroyo Del Potrero' },
  { label: 'Arroyo Grande', value: 'Arroyo Grande' },
  { label: 'Arroyo Grande', value: 'Arroyo Grande' },
  { label: 'Arroyo Granizo', value: 'Arroyo Granizo' },
  { label: 'Arroyo Hondo', value: 'Arroyo Hondo' },
  { label: 'Arroyo Hondo', value: 'Arroyo Hondo' },
  { label: 'Arroyo Hondo Abejonal', value: 'Arroyo Hondo Abejonal' },
  { label: 'Arroyo Limón', value: 'Arroyo Limón' },
  { label: 'Arroyo Naranjo', value: 'Arroyo Naranjo' },
  { label: 'Arroyo Palenque', value: 'Arroyo Palenque' },
  { label: 'Arroyo Salado', value: 'Arroyo Salado' },
  { label: 'Arroyo San Isidro', value: 'Arroyo San Isidro' },
  { label: 'Arroyo Seco', value: 'Arroyo Seco' },
  { label: 'Arroyo Seco', value: 'Arroyo Seco' },
  { label: 'Arroyo Seco', value: 'Arroyo Seco' },
  { label: 'Arroyo Seco', value: 'Arroyo Seco' },
  { label: 'Arroyo Vista Hermosa', value: 'Arroyo Vista Hermosa' },
  { label: 'Arroyo Zapotillo', value: 'Arroyo Zapotillo' },
  { label: 'Arroyohondo', value: 'Arroyohondo' },
  { label: 'Arroyomolinos', value: 'Arroyomolinos' },
  { label: 'Arroyomolinos De La Vera', value: 'Arroyomolinos De La Vera' },
  { label: 'Arroyomolinos De León', value: 'Arroyomolinos De León' },
  { label: 'Arroyos', value: 'Arroyos' },
  { label: 'Arroyos Y Esteros', value: 'Arroyos Y Esteros' },
  { label: 'Arruazu', value: 'Arruazu' },
  { label: 'Arrúbal', value: 'Arrúbal' },
  { label: 'Arruda Dos Vinhos', value: 'Arruda Dos Vinhos' },
  { label: 'Arruda Dos Vinhos', value: 'Arruda Dos Vinhos' },
  { label: 'Arrufó', value: 'Arrufó' },
  { label: 'Arsac', value: 'Arsac' },
  { label: 'Arsago Seprio', value: 'Arsago Seprio' },
  { label: 'Arsaki', value: 'Arsaki' },
  { label: 'Arsanjan', value: 'Arsanjan' },
  { label: 'Arsèguel', value: 'Arsèguel' },
  { label: 'Arsen’Yev', value: 'Arsen’Yev' },
  { label: 'Arsen’Yevo', value: 'Arsen’Yevo' },
  { label: 'Arsenal', value: 'Arsenal' },
  { label: 'Arsénio', value: 'Arsénio' },
  { label: 'Arsenjan', value: 'Arsenjan' },
  { label: 'Ars-En-Ré', value: 'Ars-En-Ré' },
  { label: 'Arshaluys', value: 'Arshaluys' },
  { label: 'Arshan’', value: 'Arshan’' },
  { label: 'Arsi Zone', value: 'Arsi Zone' },
  { label: 'Arsiè', value: 'Arsiè' },
  { label: 'Arsiero', value: 'Arsiero' },
  { label: 'Arsikere', value: 'Arsikere' },
  { label: 'Arsin', value: 'Arsin' },
  { label: 'Arsita', value: 'Arsita' },
  { label: 'Arșița', value: 'Arșița' },
  { label: 'Arsk', value: 'Arsk' },
  { label: 'Arskiy Rayon', value: 'Arskiy Rayon' },
  { label: 'Årslev', value: 'Årslev' },
  { label: 'Arsoli', value: 'Arsoli' },
  { label: 'Ars-Sur-Formans', value: 'Ars-Sur-Formans' },
  { label: 'Ars-Sur-Moselle', value: 'Ars-Sur-Moselle' },
  { label: 'Årsta', value: 'Årsta' },
  { label: 'Årsunda', value: 'Årsunda' },
  { label: 'Arsura', value: 'Arsura' },
  { label: 'Arsuz', value: 'Arsuz' },
  { label: 'Ārt Khwājah', value: 'Ārt Khwājah' },
  { label: 'Arta', value: 'Arta' },
  { label: 'Artà', value: 'Artà' },
  { label: 'Árta', value: 'Árta' },
  { label: 'Arta Terme', value: 'Arta Terme' },
  { label: 'Artajona', value: 'Artajona' },
  { label: 'Artana', value: 'Artana' },
  { label: 'Artane', value: 'Artane' },
  { label: 'Artannes-Sur-Indre', value: 'Artannes-Sur-Indre' },
  { label: 'Artarmon', value: 'Artarmon' },
  { label: 'Artas', value: 'Artas' },
  { label: 'Artashat', value: 'Artashat' },
  { label: 'Artazu', value: 'Artazu' },
  { label: 'Artea', value: 'Artea' },
  { label: 'Arteaga', value: 'Arteaga' },
  { label: 'Arteaga', value: 'Arteaga' },
  { label: 'Arteche', value: 'Arteche' },
  { label: 'Arteche', value: 'Arteche' },
  { label: 'Artegna', value: 'Artegna' },
  { label: 'Arteh Bolagh', value: 'Arteh Bolagh' },
  { label: 'Arteixo', value: 'Arteixo' },
  { label: 'Artëm', value: 'Artëm' },
  { label: 'Artemare', value: 'Artemare' },
  { label: 'Artémida', value: 'Artémida' },
  { label: 'Artemio Treviño', value: 'Artemio Treviño' },
  { label: 'Artemisa', value: 'Artemisa' },
  { label: 'Artemivs’K', value: 'Artemivs’K' },
  { label: 'Artëmovskiy', value: 'Artëmovskiy' },
  { label: 'Artena', value: 'Artena' },
  { label: 'Artenara', value: 'Artenara' },
  { label: 'Artenay', value: 'Artenay' },
  { label: 'Arteni', value: 'Arteni' },
  { label: 'Artern', value: 'Artern' },
  { label: 'Artés', value: 'Artés' },
  { label: 'Artesa De Lleida', value: 'Artesa De Lleida' },
  { label: 'Artesa De Segre', value: 'Artesa De Segre' },
  { label: 'Artesia', value: 'Artesia' },
  { label: 'Artesia', value: 'Artesia' },
  { label: 'Artesianó', value: 'Artesianó' },
  { label: 'Arth', value: 'Arth' },
  { label: 'Arthaz-Pont-Notre-Dame', value: 'Arthaz-Pont-Notre-Dame' },
  { label: 'Arthès', value: 'Arthès' },
  { label: 'Arthez-De-Béarn', value: 'Arthez-De-Béarn' },
  { label: 'Arthon', value: 'Arthon' },
  { label: 'Arthon-En-Retz', value: 'Arthon-En-Retz' },
  { label: 'Arthur', value: 'Arthur' },
  { label: 'Arthur', value: 'Arthur' },
  { label: 'Arthur County', value: 'Arthur County' },
  { label: 'Arthur’S Town', value: 'Arthur’S Town' },
  { label: 'Arti', value: 'Arti' },
  { label: 'Artículo Primero Constitucional', value: 'Artículo Primero Constitucional' },
  { label: 'Artieda', value: 'Artieda' },
  { label: 'Artigas', value: 'Artigas' },
  { label: 'Artiguelouve', value: 'Artiguelouve' },
  { label: 'Artigues-Près-Bordeaux', value: 'Artigues-Près-Bordeaux' },
  { label: 'Artiman', value: 'Artiman' },
  { label: 'Artimet', value: 'Artimet' },
  { label: 'Artist Village', value: 'Artist Village' },
  { label: 'Artix', value: 'Artix' },
  { label: 'Artjärvi', value: 'Artjärvi' },
  { label: 'Artlenburg', value: 'Artlenburg' },
  { label: 'Artogne', value: 'Artogne' },
  { label: 'Artondale', value: 'Artondale' },
  { label: 'Artova', value: 'Artova' },
  { label: 'Artres', value: 'Artres' },
  { label: 'Art-Sur-Meurthe', value: 'Art-Sur-Meurthe' },
  { label: 'Artsvaberd', value: 'Artsvaberd' },
  { label: 'Artsyz', value: 'Artsyz' },
  { label: 'Artuklu', value: 'Artuklu' },
  { label: 'Artur Nogueira', value: 'Artur Nogueira' },
  { label: 'Arturo Martínez Adame', value: 'Arturo Martínez Adame' },
  { label: 'Artybash', value: 'Artybash' },
  { label: 'Artyshta', value: 'Artyshta' },
  { label: 'Artzentales', value: 'Artzentales' },
  { label: 'Artziniega', value: 'Artziniega' },
  { label: 'Arua', value: 'Arua' },
  { label: 'Aruanã', value: 'Aruanã' },
  { label: 'Arucas', value: 'Arucas' },
  { label: 'Arudy', value: 'Arudy' },
  { label: 'Arujá', value: 'Arujá' },
  { label: 'Aruküla', value: 'Aruküla' },
  { label: 'Arukutti', value: 'Arukutti' },
  { label: 'Arumbavur', value: 'Arumbavur' },
  { label: 'Arumuganeri', value: 'Arumuganeri' },
  { label: 'Arundel', value: 'Arundel' },
  { label: 'Arundel', value: 'Arundel' },
  { label: 'Arundel', value: 'Arundel' },
  { label: 'Årup', value: 'Årup' },
  { label: 'Aruppukkottai', value: 'Aruppukkottai' },
  { label: 'Arusha', value: 'Arusha' },
  { label: 'Aruvankad', value: 'Aruvankad' },
  { label: 'Arvada', value: 'Arvada' },
  { label: 'Arvand Kenar', value: 'Arvand Kenar' },
  { label: 'Arvayheer', value: 'Arvayheer' },
  { label: 'Arvenza Uno', value: 'Arvenza Uno' },
  { label: 'Arverne', value: 'Arverne' },
  { label: 'Arvert', value: 'Arvert' },
  { label: 'Arveyres', value: 'Arveyres' },
  { label: 'Arvi', value: 'Arvi' },
  { label: 'Arvidsjaur', value: 'Arvidsjaur' },
  { label: 'Arvier', value: 'Arvier' },
  { label: 'Arvigo', value: 'Arvigo' },
  { label: 'Arvika', value: 'Arvika' },
  { label: 'Arvin', value: 'Arvin' },
  { label: 'Árvore', value: 'Árvore' },
  { label: 'Arvoredo', value: 'Arvoredo' },
  { label: 'Arvorezinha', value: 'Arvorezinha' },
  { label: 'Arwal', value: 'Arwal' },
  { label: 'Arya`', value: 'Arya`' },
  { label: 'Arys', value: 'Arys' },
  { label: 'Arzachena', value: 'Arzachena' },
  { label: 'Arzago Dadda', value: 'Arzago Dadda' },
  { label: 'Arzakan', value: 'Arzakan' },
  { label: 'Arzamas', value: 'Arzamas' },
  { label: 'Arzamasskiy Rayon', value: 'Arzamasskiy Rayon' },
  { label: 'Arzan Fud', value: 'Arzan Fud' },
  { label: 'Arzana', value: 'Arzana' },
  { label: 'Arzano', value: 'Arzano' },
  { label: 'Arzano', value: 'Arzano' },
  { label: 'Arzbach', value: 'Arzbach' },
  { label: 'Arzberg', value: 'Arzberg' },
  { label: 'Arzberg', value: 'Arzberg' },
  { label: 'Arzberg', value: 'Arzberg' },
  { label: 'Arzene', value: 'Arzene' },
  { label: 'Arzens', value: 'Arzens' },
  { label: 'Arzergrande', value: 'Arzergrande' },
  { label: 'Arzfeld', value: 'Arzfeld' },
  { label: 'Arzgir', value: 'Arzgir' },
  { label: 'Arzier', value: 'Arzier' },
  { label: 'Arzignano', value: 'Arzignano' },
  { label: 'Arzl', value: 'Arzl' },
  { label: 'Arzl Im Pitztal', value: 'Arzl Im Pitztal' },
  { label: 'Arzni', value: 'Arzni' },
  { label: 'Arzo', value: 'Arzo' },
  { label: 'Arzon', value: 'Arzon' },
  { label: 'Arzúa', value: 'Arzúa' },
  { label: 'Arzuvaj', value: 'Arzuvaj' },
  { label: 'As', value: 'As' },
  { label: 'Aš', value: 'Aš' },
  { label: 'Ås', value: 'Ås' },
  { label: 'Ås', value: 'Ås' },
  { label: 'As Pontes De García Rodríguez', value: 'As Pontes De García Rodríguez' },
  { label: 'As Sabrah', value: 'As Sabrah' },
  { label: 'As Sadayir', value: 'As Sadayir' },
  { label: 'As Saddah', value: 'As Saddah' },
  { label: 'As Sadr', value: 'As Sadr' },
  { label: 'Aş Şaff', value: 'Aş Şaff' },
  { label: 'As Saffānīyah', value: 'As Saffānīyah' },
  { label: 'As Safīrah', value: 'As Safīrah' },
  { label: 'As Safra', value: 'As Safra' },
  { label: 'As Safra', value: 'As Safra' },
  { label: 'As Said', value: 'As Said' },
  { label: 'As Salafiyah', value: 'As Salafiyah' },
  { label: 'As Salamiyah', value: 'As Salamiyah' },
  { label: 'As Salamīyah', value: 'As Salamīyah' },
  { label: 'As Salif', value: 'As Salif' },
  { label: 'As Sālimīyah', value: 'As Sālimīyah' },
  { label: 'As Salţ', value: 'As Salţ' },
  { label: 'As Samawah', value: 'As Samawah' },
  { label: 'As Samd Ash Shamali', value: 'As Samd Ash Shamali' },
  { label: 'As Sanad', value: 'As Sanad' },
  { label: 'Aş Şanamayn', value: 'Aş Şanamayn' },
  { label: 'As Sars', value: 'As Sars' },
  { label: 'As Sawadiyah', value: 'As Sawadiyah' },
  { label: 'As Sawd', value: 'As Sawd' },
  { label: 'As Sawm', value: 'As Sawm' },
  { label: 'As Sawmaah', value: 'As Sawmaah' },
  { label: 'As Sayl Al Kabir', value: 'As Sayl Al Kabir' },
  { label: 'As Sayl As Saghir', value: 'As Sayl As Saghir' },
  { label: 'As Sayyani', value: 'As Sayyani' },
  { label: 'As Sifyani', value: 'As Sifyani' },
  { label: 'As Silw', value: 'As Silw' },
  { label: 'As Sudah', value: 'As Sudah' },
  { label: 'As Sudayrah Makkah', value: 'As Sudayrah Makkah' },
  { label: 'As Sukhnah', value: 'As Sukhnah' },
  { label: 'As Sūkī', value: 'As Sūkī' },
  { label: 'As Sulaymānīyah', value: 'As Sulaymānīyah' },
  { label: 'As Sulayyil', value: 'As Sulayyil' },
  { label: 'As Sumariyah', value: 'As Sumariyah' },
  { label: 'As Suqaylibīyah', value: 'As Suqaylibīyah' },
  { label: 'As Suwadah', value: 'As Suwadah' },
  { label: 'As Suwayq', value: 'As Suwayq' },
  { label: 'Aş Şuwayrah', value: 'Aş Şuwayrah' },
  { label: 'As Suwayriqiyah', value: 'As Suwayriqiyah' },
  { label: 'Åsa', value: 'Åsa' },
  { label: 'Asaba', value: 'Asaba' },
  { label: 'Asadabad', value: 'Asadabad' },
  { label: 'Asadabad', value: 'Asadabad' },
  { label: 'Asadieh', value: 'Asadieh' },
  { label: 'Asago-Shi', value: 'Asago-Shi' },
  { label: 'Asahi', value: 'Asahi' },
  { label: 'Asahi', value: 'Asahi' },
  { label: 'Asahikawa', value: 'Asahikawa' },
  { label: 'Asahi-Shi', value: 'Asahi-Shi' },
  { label: 'Ásahreppur', value: 'Ásahreppur' },
  { label: 'Asaita', value: 'Asaita' },
  { label: 'Asaka', value: 'Asaka' },
  { label: 'Asaka', value: 'Asaka' },
  { label: 'Asaka Tumani', value: 'Asaka Tumani' },
  { label: 'Asaka-Shi', value: 'Asaka-Shi' },
  { label: 'Asakuchi Shi', value: 'Asakuchi Shi' },
  { label: 'Asakura Shi', value: 'Asakura Shi' },
  { label: 'Asalatganj', value: 'Asalatganj' },
  { label: 'Asalem', value: 'Asalem' },
  { label: 'Asaluyeh', value: 'Asaluyeh' },
  { label: 'Asamankese', value: 'Asamankese' },
  { label: 'Asan', value: 'Asan' },
  { label: 'Asandh', value: 'Asandh' },
  { label: 'Asanovo', value: 'Asanovo' },
  { label: 'Asan-Si', value: 'Asan-Si' },
  { label: 'Asansol', value: 'Asansol' },
  { label: 'Asara', value: 'Asara' },
  { label: 'Asarcık', value: 'Asarcık' },
  { label: 'Asarganj', value: 'Asarganj' },
  { label: 'Āsasa', value: 'Āsasa' },
  { label: 'Asau', value: 'Asau' },
  { label: 'Asău', value: 'Asău' },
  { label: 'Asau Village', value: 'Asau Village' },
  { label: 'Asbach', value: 'Asbach' },
  { label: 'Asbach-Bäumenheim', value: 'Asbach-Bäumenheim' },
  { label: 'Āsbe Teferī', value: 'Āsbe Teferī' },
  { label: 'Asbest', value: 'Asbest' },
  { label: 'Asbestos', value: 'Asbestos' },
  { label: 'Asbestovskiy', value: 'Asbestovskiy' },
  { label: 'Åsbro', value: 'Åsbro' },
  { label: 'Asbury', value: 'Asbury' },
  { label: 'Asbury Lake', value: 'Asbury Lake' },
  { label: 'Asbury Park', value: 'Asbury Park' },
  { label: 'Ascain', value: 'Ascain' },
  { label: 'Ascea', value: 'Ascea' },
  { label: 'Ascención De Guarayos', value: 'Ascención De Guarayos' },
  { label: 'Ascensión', value: 'Ascensión' },
  { label: 'Ascensión', value: 'Ascensión' },
  { label: 'Ascension Parish', value: 'Ascension Parish' },
  { label: 'Ascha', value: 'Ascha' },
  { label: 'Aschach An Der Donau', value: 'Aschach An Der Donau' },
  { label: 'Aschach An Der Steyr', value: 'Aschach An Der Steyr' },
  { label: 'Aschaffenburg', value: 'Aschaffenburg' },
  { label: 'Aschau', value: 'Aschau' },
  { label: 'Aschau Am Inn', value: 'Aschau Am Inn' },
  { label: 'Aschau Im Chiemgau', value: 'Aschau Im Chiemgau' },
  { label: 'Aschau Im Zillertal', value: 'Aschau Im Zillertal' },
  { label: 'Aschbach Markt', value: 'Aschbach Markt' },
  { label: 'Ascheberg', value: 'Ascheberg' },
  { label: 'Ascheberg', value: 'Ascheberg' },
  { label: 'Aschères-Le-Marché', value: 'Aschères-Le-Marché' },
  { label: 'Aschersleben', value: 'Aschersleben' },
  { label: 'Aschheim', value: 'Aschheim' },
  { label: 'Aşchileu Dorna', value: 'Aşchileu Dorna' },
  { label: 'Asciano', value: 'Asciano' },
  { label: 'Ascó', value: 'Ascó' },
  { label: 'Ascoli Piceno', value: 'Ascoli Piceno' },
  { label: 'Ascoli Satriano', value: 'Ascoli Satriano' },
  { label: 'Ascona', value: 'Ascona' },
  { label: 'Ascope', value: 'Ascope' },
  { label: 'Ascot', value: 'Ascot' },
  { label: 'Ascot', value: 'Ascot' },
  { label: 'Ascot', value: 'Ascot' },
  { label: 'Ascot', value: 'Ascot' },
  { label: 'Ascot Park', value: 'Ascot Park' },
  { label: 'Ascot Vale', value: 'Ascot Vale' },
  { label: 'Ascrea', value: 'Ascrea' },
  { label: 'Ascurra', value: 'Ascurra' },
  { label: 'Åseda', value: 'Åseda' },
  { label: 'Asekeyevo', value: 'Asekeyevo' },
  { label: 'Åsele', value: 'Åsele' },
  { label: 'Aseman Abad', value: 'Aseman Abad' },
  { label: 'Asen', value: 'Asen' },
  { label: 'Asendorf', value: 'Asendorf' },
  { label: 'Asenovgrad', value: 'Asenovgrad' },
  { label: 'Asentamiento Humano Nicolas De Pierola', value: 'Asentamiento Humano Nicolas De Pierola' },
  { label: 'Åseral', value: 'Åseral' },
  { label: 'Aseri', value: 'Aseri' },
  { label: 'Aserrí', value: 'Aserrí' },
  { label: 'Aserrío De Gariché', value: 'Aserrío De Gariché' },
  { label: 'Asfordby', value: 'Asfordby' },
  { label: 'Åsgårdstrand', value: 'Åsgårdstrand' },
  { label: 'Asghar Abad', value: 'Asghar Abad' },
  { label: 'Ash', value: 'Ash' },
  { label: 'Ash Flat', value: 'Ash Flat' },
  { label: 'Ash Grove', value: 'Ash Grove' },
  { label: 'Ash Shafā', value: 'Ash Shafā' },
  { label: 'Ash Shaghadirah', value: 'Ash Shaghadirah' },
  { label: 'Ash Shahil', value: 'Ash Shahil' },
  { label: 'Ash Shaikh Outhman', value: 'Ash Shaikh Outhman' },
  { label: 'Ash Shair', value: 'Ash Shair' },
  { label: 'Ash Shajarah', value: 'Ash Shajarah' },
  { label: 'Ash Shajwah', value: 'Ash Shajwah' },
  { label: 'Ash Shamayatayn', value: 'Ash Shamayatayn' },
  { label: 'Ash Shamiyah', value: 'Ash Shamiyah' },
  { label: 'Ash Shāmīyah', value: 'Ash Shāmīyah' },
  { label: 'Ash Shāmīyah', value: 'Ash Shāmīyah' },
  { label: 'Ash Sharafayn', value: 'Ash Sharafayn' },
  { label: 'Ash Sharai`', value: 'Ash Sharai`' },
  { label: 'Ash Shaţrah', value: 'Ash Shaţrah' },
  { label: 'Ash Shawātī', value: 'Ash Shawātī' },
  { label: 'Ash Shawbak', value: 'Ash Shawbak' },
  { label: 'Ash Shaybi', value: 'Ash Shaybi' },
  { label: 'Ash Shaykh Miskīn', value: 'Ash Shaykh Miskīn' },
  { label: 'Ash Shaykhān', value: 'Ash Shaykhān' },
  { label: 'Ash Shi`B', value: 'Ash Shi`B' },
  { label: 'Ash Shīḩānīyah', value: 'Ash Shīḩānīyah' },
  { label: 'Ash Shihr', value: 'Ash Shihr' },
  { label: 'Ash Shishah', value: 'Ash Shishah' },
  { label: 'Ash Shufayyah', value: 'Ash Shufayyah' },
  { label: 'Ash Shuhadā’', value: 'Ash Shuhadā’' },
  { label: 'Ash Shumaysi', value: 'Ash Shumaysi' },
  { label: 'Ash Shuqayq', value: 'Ash Shuqayq' },
  { label: 'Ash Shuwaybit', value: 'Ash Shuwaybit' },
  { label: 'Asha', value: 'Asha' },
  { label: 'Ashanagro Koto', value: 'Ashanagro Koto' },
  { label: 'Ashaway', value: 'Ashaway' },
  { label: 'Ashbourne', value: 'Ashbourne' },
  { label: 'Ashbourne', value: 'Ashbourne' },
  { label: 'Ashburn', value: 'Ashburn' },
  { label: 'Ashburn', value: 'Ashburn' },
  { label: 'Ashburn', value: 'Ashburn' },
  { label: 'Ashburnham', value: 'Ashburnham' },
  { label: 'Ashburton', value: 'Ashburton' },
  { label: 'Ashburton', value: 'Ashburton' },
  { label: 'Ashburton', value: 'Ashburton' },
  { label: 'Ashburton', value: 'Ashburton' },
  { label: 'Ashburton District', value: 'Ashburton District' },
  { label: 'Ashbury', value: 'Ashbury' },
  { label: 'Ashby', value: 'Ashby' },
  { label: 'Ashby', value: 'Ashby' },
  { label: 'Ashby De La Zouch', value: 'Ashby De La Zouch' },
  { label: 'Ashchysay', value: 'Ashchysay' },
  { label: 'Ashcroft', value: 'Ashcroft' },
  { label: 'Ashcroft', value: 'Ashcroft' },
  { label: 'Ashdod', value: 'Ashdod' },
  { label: 'Ashdown', value: 'Ashdown' },
  { label: 'Ashe County', value: 'Ashe County' },
  { label: 'Asheboro', value: 'Asheboro' },
  { label: 'Asherton', value: 'Asherton' },
  { label: 'Asheville', value: 'Asheville' },
  { label: 'Ashfield', value: 'Ashfield' },
  { label: 'Ashfield', value: 'Ashfield' },
  { label: 'Ashfield', value: 'Ashfield' },
  { label: 'Ashford', value: 'Ashford' },
  { label: 'Ashford', value: 'Ashford' },
  { label: 'Ashford', value: 'Ashford' },
  { label: 'Ashford', value: 'Ashford' },
  { label: 'Ashford', value: 'Ashford' },
  { label: 'Ashgabat', value: 'Ashgabat' },
  { label: 'Ashgill', value: 'Ashgill' },
  { label: 'Ashgrove', value: 'Ashgrove' },
  { label: 'Ashibetsu', value: 'Ashibetsu' },
  { label: 'Ashibetsu-Shi', value: 'Ashibetsu-Shi' },
  { label: 'Ashikaga', value: 'Ashikaga' },
  { label: 'Ashil’Ta', value: 'Ashil’Ta' },
  { label: 'Ashill', value: 'Ashill' },
  { label: 'Ashington', value: 'Ashington' },
  { label: 'Ashinskiy Rayon', value: 'Ashinskiy Rayon' },
  { label: 'Ashitkovo', value: 'Ashitkovo' },
  { label: 'Ashiya', value: 'Ashiya' },
  { label: 'Ashiya Shi', value: 'Ashiya Shi' },
  { label: 'Ashkanan', value: 'Ashkanan' },
  { label: 'Ashkāsham', value: 'Ashkāsham' },
  { label: 'Ashkelon', value: 'Ashkelon' },
  { label: 'Ashkezar', value: 'Ashkezar' },
  { label: 'Ashkhaneh', value: 'Ashkhaneh' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland', value: 'Ashland' },
  { label: 'Ashland City', value: 'Ashland City' },
  { label: 'Ashland County', value: 'Ashland County' },
  { label: 'Ashland County', value: 'Ashland County' },
  { label: 'Ashley', value: 'Ashley' },
  { label: 'Ashley', value: 'Ashley' },
  { label: 'Ashley', value: 'Ashley' },
  { label: 'Ashley', value: 'Ashley' },
  { label: 'Ashley County', value: 'Ashley County' },
  { label: 'Ashmizan', value: 'Ashmizan' },
  { label: 'Ashmont', value: 'Ashmont' },
  { label: 'Ashmore', value: 'Ashmore' },
  { label: 'Ashmūn', value: 'Ashmūn' },
  { label: 'Ashmyanski Rayon', value: 'Ashmyanski Rayon' },
  { label: 'Ashmyany', value: 'Ashmyany' },
  { label: 'Ashnak', value: 'Ashnak' },
  { label: 'Ashoknagar', value: 'Ashoknagar' },
  { label: 'Ashoknagar Kalyangarh', value: 'Ashoknagar Kalyangarh' },
  { label: 'Ashta', value: 'Ashta' },
  { label: 'Ashta', value: 'Ashta' },
  { label: 'Ashtabula', value: 'Ashtabula' },
  { label: 'Ashtabula County', value: 'Ashtabula County' },
  { label: 'Ashtarak', value: 'Ashtarak' },
  { label: 'Ashtead', value: 'Ashtead' },
  { label: 'Ashti', value: 'Ashti' },
  { label: 'Ashtian', value: 'Ashtian' },
  { label: 'Ashton', value: 'Ashton' },
  { label: 'Ashton', value: 'Ashton' },
  { label: 'Ashton In Makerfield', value: 'Ashton In Makerfield' },
  { label: 'Ashton Keynes', value: 'Ashton Keynes' },
  { label: 'Ashtonfield', value: 'Ashtonfield' },
  { label: 'Ashton-Sandy Spring', value: 'Ashton-Sandy Spring' },
  { label: 'Ashton-Under-Lyne', value: 'Ashton-Under-Lyne' },
  { label: 'Ashukino', value: 'Ashukino' },
  { label: 'Ashurst', value: 'Ashurst' },
  { label: 'Ashville', value: 'Ashville' },
  { label: 'Ashville', value: 'Ashville' },
  { label: 'Ashwaubenon', value: 'Ashwaubenon' },
  { label: 'Ashwell', value: 'Ashwell' },
  { label: 'Ashwood', value: 'Ashwood' },
  { label: 'Asia', value: 'Asia' },
  { label: 'Asia/Pratville', value: 'Asia/Pratville' },
  { label: 'Asiago', value: 'Asiago' },
  { label: 'Asientos', value: 'Asientos' },
  { label: 'Asifabad', value: 'Asifabad' },
  { label: 'Asigliano Veneto', value: 'Asigliano Veneto' },
  { label: 'Asigliano Vercellese', value: 'Asigliano Vercellese' },
  { label: 'Asika', value: 'Asika' },
  { label: 'Asikkala', value: 'Asikkala' },
  { label: 'Asilah', value: 'Asilah' },
  { label: 'Asímion', value: 'Asímion' },
  { label: 'Asín', value: 'Asín' },
  { label: 'Asind', value: 'Asind' },
  { label: 'Asingan', value: 'Asingan' },
  { label: 'Asingan', value: 'Asingan' },
  { label: 'Asíni', value: 'Asíni' },
  { label: 'Asino', value: 'Asino' },
  { label: 'Asipovichy', value: 'Asipovichy' },
  { label: 'Asipovitski Rayon', value: 'Asipovitski Rayon' },
  { label: 'Asir', value: 'Asir' },
  { label: 'Asira', value: 'Asira' },
  { label: 'Ask', value: 'Ask' },
  { label: 'Askainen', value: 'Askainen' },
  { label: 'Aşkale', value: 'Aşkale' },
  { label: 'Askam In Furness', value: 'Askam In Furness' },
  { label: 'Askaniya-Nova', value: 'Askaniya-Nova' },
  { label: 'Askaran', value: 'Askaran' },
  { label: 'Askeaton', value: 'Askeaton' },
  { label: 'Askenish', value: 'Askenish' },
  { label: 'Asker', value: 'Asker' },
  { label: 'Askern', value: 'Askern' },
  { label: 'Askersund', value: 'Askersund' },
  { label: 'Askim', value: 'Askim' },
  { label: 'Askino', value: 'Askino' },
  { label: 'Askinskiy Rayon', value: 'Askinskiy Rayon' },
  { label: 'Askiz', value: 'Askiz' },
  { label: 'Askola', value: 'Askola' },
  { label: 'Askós', value: 'Askós' },
  { label: 'Askøy', value: 'Askøy' },
  { label: 'Askvoll', value: 'Askvoll' },
  { label: 'Askyaran', value: 'Askyaran' },
  { label: 'Aslan Duz', value: 'Aslan Duz' },
  { label: 'Aslanapa', value: 'Aslanapa' },
  { label: 'Aslem', value: 'Aslem' },
  { label: 'Aslockton', value: 'Aslockton' },
  { label: 'Āsmār', value: 'Āsmār' },
  { label: 'Asmara', value: 'Asmara' },
  { label: 'Asmundtorp', value: 'Asmundtorp' },
  { label: 'Asnæs', value: 'Asnæs' },
  { label: 'Åsnes', value: 'Åsnes' },
  { label: 'Asnières-Sur-Nouère', value: 'Asnières-Sur-Nouère' },
  { label: 'Asnières-Sur-Oise', value: 'Asnières-Sur-Oise' },
  { label: 'Asnières-Sur-Seine', value: 'Asnières-Sur-Seine' },
  { label: 'Asnyezhytsy', value: 'Asnyezhytsy' },
  { label: 'Asoda', value: 'Asoda' },
  { label: 'Asokoro', value: 'Asokoro' },
  { label: 'Asola', value: 'Asola' },
  { label: 'Asolo', value: 'Asolo' },
  { label: 'Asopía', value: 'Asopía' },
  { label: 'Asopós', value: 'Asopós' },
  { label: 'Asosa', value: 'Asosa' },
  { label: 'Aso-Shi', value: 'Aso-Shi' },
  { label: 'Asotin', value: 'Asotin' },
  { label: 'Asotin County', value: 'Asotin County' },
  { label: 'Ásotthalom', value: 'Ásotthalom' },
  { label: 'Asp’Indzis Munitsip’Alit’Et’I', value: 'Asp’Indzis Munitsip’Alit’Et’I' },
  { label: 'Aspa', value: 'Aspa' },
  { label: 'Aspach', value: 'Aspach' },
  { label: 'Aspach', value: 'Aspach' },
  { label: 'Aspach-Le-Bas', value: 'Aspach-Le-Bas' },
  { label: 'Aspach-Le-Haut', value: 'Aspach-Le-Haut' },
  { label: 'Aspang Markt', value: 'Aspang Markt' },
  { label: 'Asparetto', value: 'Asparetto' },
  { label: 'Aspariegos', value: 'Aspariegos' },
  { label: 'Asparn An Der Zaya', value: 'Asparn An Der Zaya' },
  { label: 'Asparrena', value: 'Asparrena' },
  { label: 'Asparuhovo', value: 'Asparuhovo' },
  { label: 'Aspásia', value: 'Aspásia' },
  { label: 'Aspatria', value: 'Aspatria' },
  { label: 'Aspe', value: 'Aspe' },
  { label: 'Aspelt', value: 'Aspelt' },
  { label: 'Aspen', value: 'Aspen' },
  { label: 'Aspen Hill', value: 'Aspen Hill' },
  { label: 'Aspendale', value: 'Aspendale' },
  { label: 'Aspendale Gardens', value: 'Aspendale Gardens' },
  { label: 'Asperg', value: 'Asperg' },
  { label: 'Asperhofen', value: 'Asperhofen' },
  { label: 'Aspermont', value: 'Aspermont' },
  { label: 'Aspet', value: 'Aspet' },
  { label: 'Aspindza', value: 'Aspindza' },
  { label: 'Aspinwall', value: 'Aspinwall' },
  { label: 'Aspiran', value: 'Aspiran' },
  { label: 'Aspley', value: 'Aspley' },
  { label: 'Aspra', value: 'Aspra' },
  { label: 'Asprángeloi', value: 'Asprángeloi' },
  { label: 'Aspremont', value: 'Aspremont' },
  { label: 'Asprópyrgos', value: 'Asprópyrgos' },
  { label: 'Asproválta', value: 'Asproválta' },
  { label: 'Asquith', value: 'Asquith' },
  { label: 'Assab', value: 'Assab' },
  { label: 'Assafarge', value: 'Assafarge' },
  { label: 'Assago', value: 'Assago' },
  { label: 'Assaí', value: 'Assaí' },
  { label: 'Assamstadt', value: 'Assamstadt' },
  { label: 'Assaré', value: 'Assaré' },
  { label: 'Assas', value: 'Assas' },
  { label: 'Assat', value: 'Assat' },
  { label: 'Assaye', value: 'Assaye' },
  { label: 'Assa-Zag', value: 'Assa-Zag' },
  { label: 'Asse', value: 'Asse' },
  { label: 'Asselfingen', value: 'Asselfingen' },
  { label: 'Assemini', value: 'Assemini' },
  { label: 'Assen', value: 'Assen' },
  { label: 'Assendelft', value: 'Assendelft' },
  { label: 'Assenede', value: 'Assenede' },
  { label: 'Assens', value: 'Assens' },
  { label: 'Assens Kommune', value: 'Assens Kommune' },
  { label: 'Assentoft', value: 'Assentoft' },
  { label: 'Assérac', value: 'Assérac' },
  { label: 'Assesse', value: 'Assesse' },
  { label: 'Assimpao', value: 'Assimpao' },
  { label: 'Assiniboia', value: 'Assiniboia' },
  { label: 'Assinovskaya', value: 'Assinovskaya' },
  { label: 'Ássiros', value: 'Ássiros' },
  { label: 'Assis', value: 'Assis' },
  { label: 'Assis Brasil', value: 'Assis Brasil' },
  { label: 'Assis Chateaubriand', value: 'Assis Chateaubriand' },
  { label: 'Assisi', value: 'Assisi' },
  { label: 'Aßlar', value: 'Aßlar' },
  { label: 'Aßling', value: 'Aßling' },
  { label: 'Asso', value: 'Asso' },
  { label: 'Assolo', value: 'Assolo' },
  { label: 'Assomada', value: 'Assomada' },
  { label: 'Asson', value: 'Asson' },
  { label: 'Assonet', value: 'Assonet' },
  { label: 'Assoro', value: 'Assoro' },
  { label: 'Ássos', value: 'Ássos' },
  { label: 'Assumption', value: 'Assumption' },
  { label: 'Assumption Parish', value: 'Assumption Parish' },
  { label: 'Assunção', value: 'Assunção' },
  { label: 'Assunção Do Piauí', value: 'Assunção Do Piauí' },
  { label: 'As-Suwayda', value: 'As-Suwayda' },
  { label: 'As-Suwayda District', value: 'As-Suwayda District' },
  { label: 'Astacinga', value: 'Astacinga' },
  { label: 'Astaffort', value: 'Astaffort' },
  { label: 'Astagaon', value: 'Astagaon' },
  { label: 'Astakós', value: 'Astakós' },
  { label: 'Astanajapura', value: 'Astanajapura' },
  { label: 'Astaneh', value: 'Astaneh' },
  { label: 'Astaneh-Ye Ashrafiyeh', value: 'Astaneh-Ye Ashrafiyeh' },
  { label: 'Astapa', value: 'Astapa' },
  { label: 'Astara', value: 'Astara' },
  { label: 'Astara', value: 'Astara' },
  { label: 'Astatula', value: 'Astatula' },
  { label: 'Asteasu', value: 'Asteasu' },
  { label: 'Asten', value: 'Asten' },
  { label: 'Asten', value: 'Asten' },
  { label: 'Astghadzor', value: 'Astghadzor' },
  { label: 'Asti', value: 'Asti' },
  { label: 'Astigarraga', value: 'Astigarraga' },
  { label: 'Aştileu', value: 'Aştileu' },
  { label: 'Astillero El', value: 'Astillero El' },
  { label: 'Astolfo Dutra', value: 'Astolfo Dutra' },
  { label: 'Aston', value: 'Aston' },
  { label: 'Aston Clinton', value: 'Aston Clinton' },
  { label: 'Aston-On-Trent', value: 'Aston-On-Trent' },
  { label: 'Astor', value: 'Astor' },
  { label: 'Astorga', value: 'Astorga' },
  { label: 'Astorga', value: 'Astorga' },
  { label: 'Astorga', value: 'Astorga' },
  { label: 'Astorga', value: 'Astorga' },
  { label: 'Astorga', value: 'Astorga' },
  { label: 'Astoria', value: 'Astoria' },
  { label: 'Astoria', value: 'Astoria' },
  { label: 'Astoria', value: 'Astoria' },
  { label: 'Åstorp', value: 'Åstorp' },
  { label: 'Astrakhan', value: 'Astrakhan' },
  { label: 'Astrakhan', value: 'Astrakhan' },
  { label: 'Astrashytski Haradok', value: 'Astrashytski Haradok' },
  { label: 'Astravyets', value: 'Astravyets' },
  { label: 'Astravyetski Rayon', value: 'Astravyetski Rayon' },
  { label: 'Astrea', value: 'Astrea' },
  { label: 'Astromerítis', value: 'Astromerítis' },
  { label: 'Ástros', value: 'Ástros' },
  { label: 'Astudillo', value: 'Astudillo' },
  { label: 'Asturga', value: 'Asturga' },
  { label: 'Asturianos', value: 'Asturianos' },
  { label: 'Asturias', value: 'Asturias' },
  { label: 'Astwood Bank', value: 'Astwood Bank' },
  { label: 'Astypálaia', value: 'Astypálaia' },
  { label: 'Asuaju De Jos', value: 'Asuaju De Jos' },
  { label: 'Asuaju De Sus', value: 'Asuaju De Sus' },
  { label: 'Asūbulaq', value: 'Asūbulaq' },
  { label: 'Asunafo North', value: 'Asunafo North' },
  { label: 'Asunafo South', value: 'Asunafo South' },
  { label: 'Asuncion', value: 'Asuncion' },
  { label: 'Asuncion', value: 'Asuncion' },
  { label: 'Asuncion', value: 'Asuncion' },
  { label: 'Asunción Cacalotepec', value: 'Asunción Cacalotepec' },
  { label: 'Asunción Ixtaltepec', value: 'Asunción Ixtaltepec' },
  { label: 'Asunción Mita', value: 'Asunción Mita' },
  { label: 'Asunción Nochixtlán', value: 'Asunción Nochixtlán' },
  { label: 'Asunción Ocotlán', value: 'Asunción Ocotlán' },
  { label: 'Asunción Tlacolulita', value: 'Asunción Tlacolulita' },
  { label: 'Asuni', value: 'Asuni' },
  { label: 'Asutifi North', value: 'Asutifi North' },
  { label: 'Asutifi South', value: 'Asutifi South' },
  { label: 'Ásványráró', value: 'Ásványráró' },
  { label: 'Asvestochóri', value: 'Asvestochóri' },
  { label: 'Aswan', value: 'Aswan' },
  { label: 'Asyqata', value: 'Asyqata' },
  { label: 'Asyūţ', value: 'Asyūţ' },
  { label: 'Aszaló', value: 'Aszaló' },
  { label: 'Ászár', value: 'Ászár' },
  { label: 'Aszód', value: 'Aszód' },
  { label: 'Aszódi Járás', value: 'Aszódi Járás' },
  { label: 'At Ta‘Izzīyah', value: 'At Ta‘Izzīyah' },
  { label: 'At Taffah', value: 'At Taffah' },
  { label: 'Aţ Ţafīlah', value: 'Aţ Ţafīlah' },
  { label: 'At Taḩāluf', value: 'At Taḩāluf' },
  { label: 'At Taifiya', value: 'At Taifiya' },
  { label: 'At Tāj', value: 'At Tāj' },
  { label: 'At Tall', value: 'At Tall' },
  { label: 'At Tan`Im', value: 'At Tan`Im' },
  { label: 'Aţ Ţaraf', value: 'Aţ Ţaraf' },
  { label: 'At Tarfa', value: 'At Tarfa' },
  { label: 'Aţ Ţawīlah', value: 'Aţ Ţawīlah' },
  { label: 'Aţ Ţayyibah', value: 'Aţ Ţayyibah' },
  { label: 'Aţ Ţayyibah', value: 'Aţ Ţayyibah' },
  { label: 'At Tūbī', value: 'At Tūbī' },
  { label: 'At Tuhayat', value: 'At Tuhayat' },
  { label: 'At Turqi', value: 'At Turqi' },
  { label: 'Aţ Ţurrah', value: 'Aţ Ţurrah' },
  { label: 'Atabayan', value: 'Atabayan' },
  { label: 'Atabey', value: 'Atabey' },
  { label: 'Atacco', value: 'Atacco' },
  { label: 'Atacheo De Regalado', value: 'Atacheo De Regalado' },
  { label: 'Ataco', value: 'Ataco' },
  { label: 'Atagay', value: 'Atagay' },
  { label: 'Atahualpa', value: 'Atahualpa' },
  { label: 'Atajate', value: 'Atajate' },
  { label: 'Atakent', value: 'Atakent' },
  { label: 'Atakpamé', value: 'Atakpamé' },
  { label: 'Atakum', value: 'Atakum' },
  { label: 'Atalaia', value: 'Atalaia' },
  { label: 'Atalaia', value: 'Atalaia' },
  { label: 'Atalaia', value: 'Atalaia' },
  { label: 'Atalaia', value: 'Atalaia' },
  { label: 'Atalaia Do Norte', value: 'Atalaia Do Norte' },
  { label: 'Atalanta', value: 'Atalanta' },
  { label: 'Atalánti', value: 'Atalánti' },
  { label: 'Atalaya', value: 'Atalaya' },
  { label: 'Atalaya', value: 'Atalaya' },
  { label: 'Atalaya', value: 'Atalaya' },
  { label: 'Atalaya Del Cañavate', value: 'Atalaya Del Cañavate' },
  { label: 'Atalaya La', value: 'Atalaya La' },
  { label: 'Ataléia', value: 'Ataléia' },
  { label: 'Atalpan', value: 'Atalpan' },
  { label: 'Atamanovka', value: 'Atamanovka' },
  { label: 'Atamanskaya', value: 'Atamanskaya' },
  { label: 'Atamaría', value: 'Atamaría' },
  { label: 'Atambua', value: 'Atambua' },
  { label: 'Atami', value: 'Atami' },
  { label: 'Atami-Shi', value: 'Atami-Shi' },
  { label: 'Atamyrat', value: 'Atamyrat' },
  { label: 'Atani', value: 'Atani' },
  { label: 'Atanzón', value: 'Atanzón' },
  { label: 'Atapán', value: 'Atapán' },
  { label: 'Atapaneo', value: 'Atapaneo' },
  { label: 'Atapuerca', value: 'Atapuerca' },
  { label: 'Ataq', value: 'Ataq' },
  { label: 'Ataquines', value: 'Ataquines' },
  { label: 'Atar', value: 'Atar' },
  { label: 'Atarfe', value: 'Atarfe' },
  { label: 'Atarjea', value: 'Atarjea' },
  { label: 'Atarjeas De Covarrubias', value: 'Atarjeas De Covarrubias' },
  { label: 'Atarra', value: 'Atarra' },
  { label: 'Atarrabia', value: 'Atarrabia' },
  { label: 'Atascadero', value: 'Atascadero' },
  { label: 'Atascocita', value: 'Atascocita' },
  { label: 'Atascosa County', value: 'Atascosa County' },
  { label: 'Ataşehir', value: 'Ataşehir' },
  { label: 'Atasta', value: 'Atasta' },
  { label: 'Atasū', value: 'Atasū' },
  { label: 'Ataun', value: 'Ataun' },
  { label: 'Atauro Island', value: 'Atauro Island' },
  { label: 'Atazar El', value: 'Atazar El' },
  { label: 'Atbara', value: 'Atbara' },
  { label: 'Atbasar', value: 'Atbasar' },
  { label: 'At-Bashi', value: 'At-Bashi' },
  { label: 'Atchison', value: 'Atchison' },
  { label: 'Atchison County', value: 'Atchison County' },
  { label: 'Atchison County', value: 'Atchison County' },
  { label: 'Atco', value: 'Atco' },
  { label: 'Atea', value: 'Atea' },
  { label: 'Atebubu-Amantin', value: 'Atebubu-Amantin' },
  { label: 'Ateca', value: 'Ateca' },
  { label: 'Atecax', value: 'Atecax' },
  { label: 'Atecucario De La Constitución (Atecuario)', value: 'Atecucario De La Constitución (Atecuario)' },
  { label: 'Aţel', value: 'Aţel' },
  { label: 'Ateleta', value: 'Ateleta' },
  { label: 'Ateli Mandi', value: 'Ateli Mandi' },
  { label: 'Atella', value: 'Atella' },
  { label: 'Atemanica', value: 'Atemanica' },
  { label: 'Atemar', value: 'Atemar' },
  { label: 'Atempan', value: 'Atempan' },
  { label: 'Atena Lucana', value: 'Atena Lucana' },
  { label: 'Atenango Del Río', value: 'Atenango Del Río' },
  { label: 'Atenas', value: 'Atenas' },
  { label: 'Atenas De San Cristóbal', value: 'Atenas De San Cristóbal' },
  { label: 'Atencingo', value: 'Atencingo' },
  { label: 'Atengo', value: 'Atengo' },
  { label: 'Atengo', value: 'Atengo' },
  { label: 'Atenguillo', value: 'Atenguillo' },
  { label: 'Atenguillo', value: 'Atenguillo' },
  { label: 'Atenxoxola', value: 'Atenxoxola' },
  { label: 'Ateptsevo', value: 'Ateptsevo' },
  { label: 'Atequiza', value: 'Atequiza' },
  { label: 'Atescatempa', value: 'Atescatempa' },
  { label: 'Atessa', value: 'Atessa' },
  { label: 'Atexcal', value: 'Atexcal' },
  { label: 'Atexcatzingo', value: 'Atexcatzingo' },
  { label: 'Atez', value: 'Atez' },
  { label: 'Atglen', value: 'Atglen' },
  { label: 'Ath', value: 'Ath' },
  { label: 'Ath Thawrah', value: 'Ath Thawrah' },
  { label: 'Athabasca', value: 'Athabasca' },
  { label: 'Athagarh', value: 'Athagarh' },
  { label: 'Athboy', value: 'Athboy' },
  { label: 'Athée-Sur-Cher', value: 'Athée-Sur-Cher' },
  { label: 'Athelstone', value: 'Athelstone' },
  { label: 'Athena', value: 'Athena' },
  { label: 'Athenry', value: 'Athenry' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens', value: 'Athens' },
  { label: 'Athens County', value: 'Athens County' },
  { label: 'Atherstone', value: 'Atherstone' },
  { label: 'Atherton', value: 'Atherton' },
  { label: 'Atherton', value: 'Atherton' },
  { label: 'Atherton', value: 'Atherton' },
  { label: 'Athgarvan', value: 'Athgarvan' },
  { label: 'Athi River', value: 'Athi River' },
  { label: 'Athíenou', value: 'Athíenou' },
  { label: 'Athies-Sous-Laon', value: 'Athies-Sous-Laon' },
  { label: 'Athíkia', value: 'Athíkia' },
  { label: 'Athis-De-Lorne', value: 'Athis-De-Lorne' },
  { label: 'Athis-Mons', value: 'Athis-Mons' },
  { label: 'Athlone', value: 'Athlone' },
  { label: 'Athni', value: 'Athni' },
  { label: 'Athol', value: 'Athol' },
  { label: 'Athol Park', value: 'Athol Park' },
  { label: 'Athy', value: 'Athy' },
  { label: 'Áthyra', value: 'Áthyra' },
  { label: 'Ati', value: 'Ati' },
  { label: 'Atibaia', value: 'Atibaia' },
  { label: 'Aticama', value: 'Aticama' },
  { label: 'Atid', value: 'Atid' },
  { label: 'Atienza', value: 'Atienza' },
  { label: 'Atig', value: 'Atig' },
  { label: 'Atikokan', value: 'Atikokan' },
  { label: 'Atil', value: 'Atil' },
  { label: 'Atílio Vivacqua', value: 'Atílio Vivacqua' },
  { label: 'Atima', value: 'Atima' },
  { label: 'Atimonan', value: 'Atimonan' },
  { label: 'Atina', value: 'Atina' },
  { label: 'Atina Inferiore', value: 'Atina Inferiore' },
  { label: 'Atins', value: 'Atins' },
  { label: 'Ațintiș', value: 'Ațintiș' },
  { label: 'Atipuluhan', value: 'Atipuluhan' },
  { label: 'Atiquizaya', value: 'Atiquizaya' },
  { label: 'Atitalaquia', value: 'Atitalaquia' },
  { label: 'Atizapán', value: 'Atizapán' },
  { label: 'Atkaracalar', value: 'Atkaracalar' },
  { label: 'Atkarsk', value: 'Atkarsk' },
  { label: 'Atkarskiy Rayon', value: 'Atkarskiy Rayon' },
  { label: 'Atkins', value: 'Atkins' },
  { label: 'Atkins', value: 'Atkins' },
  { label: 'Atkins', value: 'Atkins' },
  { label: 'Atkinson', value: 'Atkinson' },
  { label: 'Atkinson', value: 'Atkinson' },
  { label: 'Atkinson County', value: 'Atkinson County' },
  { label: 'Atla', value: 'Atla' },
  { label: 'Atlacahualoya', value: 'Atlacahualoya' },
  { label: 'Atlacholoaya', value: 'Atlacholoaya' },
  { label: 'Atlacomulco De Fabela', value: 'Atlacomulco De Fabela' },
  { label: 'Atlahuilco', value: 'Atlahuilco' },
  { label: 'Atlamajac', value: 'Atlamajac' },
  { label: 'Atlamajalcingo Del Río', value: 'Atlamajalcingo Del Río' },
  { label: 'Atlangatepec', value: 'Atlangatepec' },
  { label: 'Atlanta', value: 'Atlanta' },
  { label: 'Atlanta', value: 'Atlanta' },
  { label: 'Atlanta', value: 'Atlanta' },
  { label: 'Atlanta', value: 'Atlanta' },
  { label: 'Atlantic', value: 'Atlantic' },
  { label: 'Atlantic Beach', value: 'Atlantic Beach' },
  { label: 'Atlantic Beach', value: 'Atlantic Beach' },
  { label: 'Atlantic Beach', value: 'Atlantic Beach' },
  { label: 'Atlantic City', value: 'Atlantic City' },
  { label: 'Atlantic County', value: 'Atlantic County' },
  { label: 'Atlantic Highlands', value: 'Atlantic Highlands' },
  { label: 'Atlántida', value: 'Atlántida' },
  { label: 'Atlantis', value: 'Atlantis' },
  { label: 'Atlantis', value: 'Atlantis' },
  { label: 'Atlapexco', value: 'Atlapexco' },
  { label: 'Atlashevo', value: 'Atlashevo' },
  { label: 'Atlasovo', value: 'Atlasovo' },
  { label: 'Atlatlahucan', value: 'Atlatlahucan' },
  { label: 'Atlatongo', value: 'Atlatongo' },
  { label: 'Atlautla', value: 'Atlautla' },
  { label: 'Atlequizayan', value: 'Atlequizayan' },
  { label: 'Atliaca', value: 'Atliaca' },
  { label: 'Atlit', value: 'Atlit' },
  { label: 'Atlixco', value: 'Atlixco' },
  { label: 'Atlixtac', value: 'Atlixtac' },
  { label: 'Atlzayanca', value: 'Atlzayanca' },
  { label: 'Atmakur', value: 'Atmakur' },
  { label: 'Atmore', value: 'Atmore' },
  { label: 'Atninskiy Rayon', value: 'Atninskiy Rayon' },
  { label: 'Atocha', value: 'Atocha' },
  { label: 'Atok', value: 'Atok' },
  { label: 'Atok', value: 'Atok' },
  { label: 'Atoka', value: 'Atoka' },
  { label: 'Atoka', value: 'Atoka' },
  { label: 'Atoka', value: 'Atoka' },
  { label: 'Atoka County', value: 'Atoka County' },
  { label: 'Atolina', value: 'Atolina' },
  { label: 'Atolinga', value: 'Atolinga' },
  { label: 'Atoluca', value: 'Atoluca' },
  { label: 'Atonalisco', value: 'Atonalisco' },
  { label: 'Atongo', value: 'Atongo' },
  { label: 'Atop-Atop', value: 'Atop-Atop' },
  { label: 'Atotonilco', value: 'Atotonilco' },
  { label: 'Atotonilco', value: 'Atotonilco' },
  { label: 'Atotonilco De Tula', value: 'Atotonilco De Tula' },
  { label: 'Atotonilco El Alto Municipality', value: 'Atotonilco El Alto Municipality' },
  { label: 'Atotonilco El Bajo', value: 'Atotonilco El Bajo' },
  { label: 'Atotonilco El Grande', value: 'Atotonilco El Grande' },
  { label: 'Atotonilquillo', value: 'Atotonilquillo' },
  { label: 'Atouguia Da Baleia', value: 'Atouguia Da Baleia' },
  { label: 'Atoyac', value: 'Atoyac' },
  { label: 'Atoyac', value: 'Atoyac' },
  { label: 'Atoyac De Álvarez', value: 'Atoyac De Álvarez' },
  { label: 'Atoyatempan', value: 'Atoyatempan' },
  { label: 'Atrani', value: 'Atrani' },
  { label: 'Atrato', value: 'Atrato' },
  { label: 'Atrauli', value: 'Atrauli' },
  { label: 'Atraulia', value: 'Atraulia' },
  { label: 'Atri', value: 'Atri' },
  { label: 'Atripalda', value: 'Atripalda' },
  { label: 'Atsiaman', value: 'Atsiaman' },
  { label: 'Atsipópoulo', value: 'Atsipópoulo' },
  { label: 'Atsugi', value: 'Atsugi' },
  { label: 'Atsugi Shi', value: 'Atsugi Shi' },
  { label: 'Attadale', value: 'Attadale' },
  { label: 'Attainville', value: 'Attainville' },
  { label: 'Attala County', value: 'Attala County' },
  { label: 'Attalens', value: 'Attalens' },
  { label: 'Attalla', value: 'Attalla' },
  { label: 'Attapeu', value: 'Attapeu' },
  { label: 'Attard', value: 'Attard' },
  { label: 'Attawahi', value: 'Attawahi' },
  { label: 'Attawapiskat', value: 'Attawapiskat' },
  { label: 'Attayyampatti', value: 'Attayyampatti' },
  { label: 'Attendorf', value: 'Attendorf' },
  { label: 'Attendorn', value: 'Attendorn' },
  { label: 'Attenhofen', value: 'Attenhofen' },
  { label: 'Attenkirchen', value: 'Attenkirchen' },
  { label: 'Attenweiler', value: 'Attenweiler' },
  { label: 'Attersee', value: 'Attersee' },
  { label: 'Attert', value: 'Attert' },
  { label: 'Attica', value: 'Attica' },
  { label: 'Attica', value: 'Attica' },
  { label: 'Attiches', value: 'Attiches' },
  { label: 'Attichy', value: 'Attichy' },
  { label: 'Attigliano', value: 'Attigliano' },
  { label: 'Attignat', value: 'Attignat' },
  { label: 'Attigny', value: 'Attigny' },
  { label: 'Attili', value: 'Attili' },
  { label: 'Attimis', value: 'Attimis' },
  { label: 'Atting', value: 'Atting' },
  { label: 'Attingal', value: 'Attingal' },
  { label: 'Attinghausen', value: 'Attinghausen' },
  { label: 'Attiswil', value: 'Attiswil' },
  { label: 'Attleboro', value: 'Attleboro' },
  { label: 'Attleborough', value: 'Attleborough' },
  { label: 'Attnang', value: 'Attnang' },
  { label: 'Attnang-Puchheim', value: 'Attnang-Puchheim' },
  { label: 'Attock Tehsil', value: 'Attock Tehsil' },
  { label: 'Attur', value: 'Attur' },
  { label: 'Attwood', value: 'Attwood' },
  { label: 'Attyal', value: 'Attyal' },
  { label: 'Atulayan', value: 'Atulayan' },
  { label: 'Atulayan', value: 'Atulayan' },
  { label: 'Atuncolla', value: 'Atuncolla' },
  { label: 'Atuntaqui', value: 'Atuntaqui' },
  { label: 'Atur', value: 'Atur' },
  { label: 'Åtvidaberg', value: 'Åtvidaberg' },
  { label: 'Atwater', value: 'Atwater' },
  { label: 'Atwater', value: 'Atwater' },
  { label: 'Atwell', value: 'Atwell' },
  { label: 'Atwood', value: 'Atwood' },
  { label: 'Atwood', value: 'Atwood' },
  { label: 'Atworth', value: 'Atworth' },
  { label: 'Atxondo', value: 'Atxondo' },
  { label: 'Atyashevo', value: 'Atyashevo' },
  { label: 'Atyashevskiy Rayon', value: 'Atyashevskiy Rayon' },
  { label: 'Atyrá', value: 'Atyrá' },
  { label: 'Atyrau', value: 'Atyrau' },
  { label: 'Atyur’Yevskiy Rayon', value: 'Atyur’Yevskiy Rayon' },
  { label: 'Atzacan', value: 'Atzacan' },
  { label: 'Atzacoaloya', value: 'Atzacoaloya' },
  { label: 'Atzala', value: 'Atzala' },
  { label: 'Atzalan', value: 'Atzalan' },
  { label: 'Atzalán', value: 'Atzalán' },
  { label: 'Atzara', value: 'Atzara' },
  { label: 'Atzbach', value: 'Atzbach' },
  { label: 'Atzcatlán', value: 'Atzcatlán' },
  { label: 'Atzenbrugg', value: 'Atzenbrugg' },
  { label: 'Atzendorf', value: 'Atzendorf' },
  { label: 'Atzeneta Dalbaida', value: 'Atzeneta Dalbaida' },
  { label: 'Atzeneta Dalbaida', value: 'Atzeneta Dalbaida' },
  { label: 'Atzeneta Del Maestrat', value: 'Atzeneta Del Maestrat' },
  { label: 'Atzesberg', value: 'Atzesberg' },
  { label: 'Atzingo (La Cumbre)', value: 'Atzingo (La Cumbre)' },
  { label: 'Atzitzintla', value: 'Atzitzintla' },
  { label: 'Au', value: 'Au' },
  { label: 'Au', value: 'Au' },
  { label: 'Au', value: 'Au' },
  { label: 'Au', value: 'Au' },
  { label: 'Au', value: 'Au' },
  { label: 'Au / Mittel-Dorf', value: 'Au / Mittel-Dorf' },
  { label: 'Au / Unter-Dorf', value: 'Au / Unter-Dorf' },
  { label: 'Au Am Leithaberge', value: 'Au Am Leithaberge' },
  { label: 'Au Am Rhein', value: 'Au Am Rhein' },
  { label: 'Au An Der Traun', value: 'Au An Der Traun' },
  { label: 'Au In Der Hallertau', value: 'Au In Der Hallertau' },
  { label: 'Au Leon', value: 'Au Leon' },
  { label: 'Au Sable', value: 'Au Sable' },
  { label: 'Au Tabor', value: 'Au Tabor' },
  { label: 'Au Tabor Hill', value: 'Au Tabor Hill' },
  { label: 'Auas', value: 'Auas' },
  { label: 'Aub', value: 'Aub' },
  { label: 'Aubagne', value: 'Aubagne' },
  { label: 'Aubais', value: 'Aubais' },
  { label: 'Aubange', value: 'Aubange' },
  { label: 'Aubel', value: 'Aubel' },
  { label: 'Aubenas', value: 'Aubenas' },
  { label: 'Auberchicourt', value: 'Auberchicourt' },
  { label: 'Aubergenville', value: 'Aubergenville' },
  { label: 'Auberives-Sur-Varèze', value: 'Auberives-Sur-Varèze' },
  { label: 'Auberry', value: 'Auberry' },
  { label: 'Aubers', value: 'Aubers' },
  { label: 'Aubervilliers', value: 'Aubervilliers' },
  { label: 'Aube-Sur-Rîle', value: 'Aube-Sur-Rîle' },
  { label: 'Aubevoye', value: 'Aubevoye' },
  { label: 'Aubie-Et-Espessas', value: 'Aubie-Et-Espessas' },
  { label: 'Aubière', value: 'Aubière' },
  { label: 'Aubiet', value: 'Aubiet' },
  { label: 'Aubignan', value: 'Aubignan' },
  { label: 'Aubigné-Racan', value: 'Aubigné-Racan' },
  { label: 'Aubigny', value: 'Aubigny' },
  { label: 'Aubigny-Au-Bac', value: 'Aubigny-Au-Bac' },
  { label: 'Aubigny-En-Artois', value: 'Aubigny-En-Artois' },
  { label: 'Aubigny-Sur-Nère', value: 'Aubigny-Sur-Nère' },
  { label: 'Aubin', value: 'Aubin' },
  { label: 'Aubin Grove', value: 'Aubin Grove' },
  { label: 'Aubonne', value: 'Aubonne' },
  { label: 'Aubord', value: 'Aubord' },
  { label: 'Auboué', value: 'Auboué' },
  { label: 'Aubrey', value: 'Aubrey' },
  { label: 'Aubry-Du-Hainaut', value: 'Aubry-Du-Hainaut' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn', value: 'Auburn' },
  { label: 'Auburn Gresham', value: 'Auburn Gresham' },
  { label: 'Auburn Hills', value: 'Auburn Hills' },
  { label: 'Auburn Lake Trails', value: 'Auburn Lake Trails' },
  { label: 'Auburndale', value: 'Auburndale' },
  { label: 'Aubusson', value: 'Aubusson' },
  { label: 'Auby', value: 'Auby' },
  { label: 'Aucamville', value: 'Aucamville' },
  { label: 'Auce', value: 'Auce' },
  { label: 'Auch', value: 'Auch' },
  { label: 'Auchel', value: 'Auchel' },
  { label: 'Auchenflower', value: 'Auchenflower' },
  { label: 'Auchi', value: 'Auchi' },
  { label: 'Auchinleck', value: 'Auchinleck' },
  { label: 'Auchtembeddie', value: 'Auchtembeddie' },
  { label: 'Auchterarder', value: 'Auchterarder' },
  { label: 'Auchtermuchty', value: 'Auchtermuchty' },
  { label: 'Auchy-Lès-Hesdin', value: 'Auchy-Lès-Hesdin' },
  { label: 'Auchy-Les-Mines', value: 'Auchy-Les-Mines' },
  { label: 'Auckland', value: 'Auckland' },
  { label: 'Auckley', value: 'Auckley' },
  { label: 'Audenge', value: 'Audenge' },
  { label: 'Audierne', value: 'Audierne' },
  { label: 'Audincourt', value: 'Audincourt' },
  { label: 'Auditore', value: 'Auditore' },
  { label: 'Audlem', value: 'Audlem' },
  { label: 'Audley', value: 'Audley' },
  { label: 'Audnedal', value: 'Audnedal' },
  { label: 'Audorf', value: 'Audorf' },
  { label: 'Audrain County', value: 'Audrain County' },
  { label: 'Audru', value: 'Audru' },
  { label: 'Audruicq', value: 'Audruicq' },
  { label: 'Audubon', value: 'Audubon' },
  { label: 'Audubon', value: 'Audubon' },
  { label: 'Audubon', value: 'Audubon' },
  { label: 'Audubon County', value: 'Audubon County' },
  { label: 'Audubon Park', value: 'Audubon Park' },
  { label: 'Audubon Park', value: 'Audubon Park' },
  { label: 'Audun-Le-Roman', value: 'Audun-Le-Roman' },
  { label: 'Audun-Le-Tiche', value: 'Audun-Le-Tiche' },
  { label: 'Aue', value: 'Aue' },
  { label: 'Auen', value: 'Auen' },
  { label: 'Auerbach', value: 'Auerbach' },
  { label: 'Auerbach', value: 'Auerbach' },
  { label: 'Auersbach', value: 'Auersbach' },
  { label: 'Auersthal', value: 'Auersthal' },
  { label: 'Auezov', value: 'Auezov' },
  { label: 'Auf Der Horst', value: 'Auf Der Horst' },
  { label: 'Auffargis', value: 'Auffargis' },
  { label: 'Auffay', value: 'Auffay' },
  { label: 'Aufhausen', value: 'Aufhausen' },
  { label: 'Aufseß', value: 'Aufseß' },
  { label: 'Augan', value: 'Augan' },
  { label: 'Auggen', value: 'Auggen' },
  { label: 'Aughrim', value: 'Aughrim' },
  { label: 'Augier', value: 'Augier' },
  { label: 'Auglaize County', value: 'Auglaize County' },
  { label: 'Augny', value: 'Augny' },
  { label: 'Augsburg', value: 'Augsburg' },
  { label: 'August', value: 'August' },
  { label: 'August Town', value: 'August Town' },
  { label: 'Augusta', value: 'Augusta' },
  { label: 'Augusta', value: 'Augusta' },
  { label: 'Augusta', value: 'Augusta' },
  { label: 'Augusta', value: 'Augusta' },
  { label: 'Augusta', value: 'Augusta' },
  { label: 'Augusta', value: 'Augusta' },
  { label: 'Augusta', value: 'Augusta' },
  { label: 'Augusta', value: 'Augusta' },
  { label: 'Augusta', value: 'Augusta' },
  { label: 'Augusta County', value: 'Augusta County' },
  { label: 'Augusta-Margaret River Shire', value: 'Augusta-Margaret River Shire' },
  { label: 'Augustdorf', value: 'Augustdorf' },
  { label: 'Augustenborg', value: 'Augustenborg' },
  { label: 'Augustin', value: 'Augustin' },
  { label: 'Augustine Heights', value: 'Augustine Heights' },
  { label: 'Augustinópolis', value: 'Augustinópolis' },
  { label: 'Augustinusga', value: 'Augustinusga' },
  { label: 'Augusto Corrêa', value: 'Augusto Corrêa' },
  { label: 'Augusto De Lima', value: 'Augusto De Lima' },
  { label: 'Augusto Gómez Villanueva', value: 'Augusto Gómez Villanueva' },
  { label: 'Augusto Pestana', value: 'Augusto Pestana' },
  { label: 'Augusto Severo', value: 'Augusto Severo' },
  { label: 'Augustów', value: 'Augustów' },
  { label: 'Augustusburg', value: 'Augustusburg' },
  { label: 'Augy', value: 'Augy' },
  { label: 'Auhagen', value: 'Auhagen' },
  { label: 'Auhausen', value: 'Auhausen' },
  { label: 'Auka', value: 'Auka' },
  { label: 'Auki', value: 'Auki' },
  { label: 'Aukra', value: 'Aukra' },
  { label: 'Aukrug', value: 'Aukrug' },
  { label: 'Aukstadvaris', value: 'Aukstadvaris' },
  { label: 'Auleben', value: 'Auleben' },
  { label: 'Aulendorf', value: 'Aulendorf' },
  { label: 'Aulesti', value: 'Aulesti' },
  { label: 'Auletta', value: 'Auletta' },
  { label: 'Auli', value: 'Auli' },
  { label: 'Aulla', value: 'Aulla' },
  { label: 'Aulnat', value: 'Aulnat' },
  { label: 'Aulnay', value: 'Aulnay' },
  { label: 'Aulnay-Sous-Bois', value: 'Aulnay-Sous-Bois' },
  { label: 'Aulnay-Sur-Mauldre', value: 'Aulnay-Sur-Mauldre' },
  { label: 'Aulnois-Sous-Laon', value: 'Aulnois-Sous-Laon' },
  { label: 'Aulnoye-Aymeries', value: 'Aulnoye-Aymeries' },
  { label: 'Aulnoy-Lez-Valenciennes', value: 'Aulnoy-Lez-Valenciennes' },
  { label: 'Ault', value: 'Ault' },
  { label: 'Ault', value: 'Ault' },
  { label: 'Ault Field', value: 'Ault Field' },
  { label: 'Auly', value: 'Auly' },
  { label: 'Auma', value: 'Auma' },
  { label: 'Aumale', value: 'Aumale' },
  { label: 'Aumbay', value: 'Aumbay' },
  { label: 'Aumetz', value: 'Aumetz' },
  { label: 'Aumont-Aubrac', value: 'Aumont-Aubrac' },
  { label: 'Aumsville', value: 'Aumsville' },
  { label: 'Auna', value: 'Auna' },
  { label: 'Aunay-Sous-Auneau', value: 'Aunay-Sous-Auneau' },
  { label: 'Aunay-Sur-Odon', value: 'Aunay-Sur-Odon' },
  { label: 'Aundh Satara', value: 'Aundh Satara' },
  { label: 'Auneau', value: 'Auneau' },
  { label: 'Auneuil', value: 'Auneuil' },
  { label: 'Auning', value: 'Auning' },
  { label: 'Auñón', value: 'Auñón' },
  { label: 'Aups', value: 'Aups' },
  { label: 'Aura', value: 'Aura' },
  { label: 'Aura Im Sinngrund', value: 'Aura Im Sinngrund' },
  { label: 'Aurach', value: 'Aurach' },
  { label: 'Aurach Am Hongar', value: 'Aurach Am Hongar' },
  { label: 'Aurad', value: 'Aurad' },
  { label: 'Auraiya', value: 'Auraiya' },
  { label: 'Aurangabad', value: 'Aurangabad' },
  { label: 'Aurangabad', value: 'Aurangabad' },
  { label: 'Aurano', value: 'Aurano' },
  { label: 'Auras', value: 'Auras' },
  { label: 'Auray', value: 'Auray' },
  { label: 'Aure', value: 'Aure' },
  { label: 'Áurea', value: 'Áurea' },
  { label: 'Aurec-Sur-Loire', value: 'Aurec-Sur-Loire' },
  { label: 'Aureilhan', value: 'Aureilhan' },
  { label: 'Aureille', value: 'Aureille' },
  { label: 'Aurel Vlaicu', value: 'Aurel Vlaicu' },
  { label: 'Aurelia', value: 'Aurelia' },
  { label: 'Aurelino Leal', value: 'Aurelino Leal' },
  { label: 'Aurelio Manrique', value: 'Aurelio Manrique' },
  { label: 'Aurelliana', value: 'Aurelliana' },
  { label: 'Aurendel Hill', value: 'Aurendel Hill' },
  { label: 'Auriac-Sur-Vendinelle', value: 'Auriac-Sur-Vendinelle' },
  { label: 'Auribeau-Sur-Siagne', value: 'Auribeau-Sur-Siagne' },
  { label: 'Aurich', value: 'Aurich' },
  { label: 'Auriflama', value: 'Auriflama' },
  { label: 'Aurignac', value: 'Aurignac' },
  { label: 'Aurigo', value: 'Aurigo' },
  { label: 'Aurilândia', value: 'Aurilândia' },
  { label: 'Aurillac', value: 'Aurillac' },
  { label: 'Auriol', value: 'Auriol' },
  { label: 'Aurisina', value: 'Aurisina' },
  { label: 'Auritz/Burguete', value: 'Auritz/Burguete' },
  { label: 'Aurland', value: 'Aurland' },
  { label: 'Aurogna', value: 'Aurogna' },
  { label: 'Aurolzmünster', value: 'Aurolzmünster' },
  { label: 'Auronzo', value: 'Auronzo' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora', value: 'Aurora' },
  { label: 'Aurora County', value: 'Aurora County' },
  { label: 'Aurora Do Pará', value: 'Aurora Do Pará' },
  { label: 'Aurora Do Tocantins', value: 'Aurora Do Tocantins' },
  { label: 'Aurora Ermita', value: 'Aurora Ermita' },
  { label: 'Aurora Esquipulas', value: 'Aurora Esquipulas' },
  { label: 'Auroville', value: 'Auroville' },
  { label: 'Aurskog-Høland', value: 'Aurskog-Høland' },
  { label: 'Aursmoen', value: 'Aursmoen' },
  { label: 'Aurukun', value: 'Aurukun' },
  { label: 'Ausa', value: 'Ausa' },
  { label: 'Ausejo', value: 'Ausejo' },
  { label: 'Ausejo De La Sierra', value: 'Ausejo De La Sierra' },
  { label: 'Auşeu', value: 'Auşeu' },
  { label: 'Aushiger', value: 'Aushiger' },
  { label: 'Ausines Los', value: 'Ausines Los' },
  { label: 'Ausleben', value: 'Ausleben' },
  { label: 'Ausonia', value: 'Ausonia' },
  { label: 'Ausserbraz', value: 'Ausserbraz' },
  { label: 'Außernzell', value: 'Außernzell' },
  { label: 'Außervillgraten', value: 'Außervillgraten' },
  { label: 'Aussillon', value: 'Aussillon' },
  { label: 'Aussonne', value: 'Aussonne' },
  { label: 'Austell', value: 'Austell' },
  { label: 'Austerlitz', value: 'Austerlitz' },
  { label: 'Austevoll', value: 'Austevoll' },
  { label: 'Austin', value: 'Austin' },
  { label: 'Austin', value: 'Austin' },
  { label: 'Austin', value: 'Austin' },
  { label: 'Austin', value: 'Austin' },
  { label: 'Austin County', value: 'Austin County' },
  { label: 'Austinmer', value: 'Austinmer' },
  { label: 'Austins Ferry', value: 'Austins Ferry' },
  { label: 'Austintown', value: 'Austintown' },
  { label: 'Austis', value: 'Austis' },
  { label: 'Austral', value: 'Austral' },
  { label: 'Australind', value: 'Australind' },
  { label: 'Austrheim', value: 'Austrheim' },
  { label: 'Aután', value: 'Aután' },
  { label: 'Autauga County', value: 'Autauga County' },
  { label: 'Autazes', value: 'Autazes' },
  { label: 'Auterive', value: 'Auterive' },
  { label: 'Authie', value: 'Authie' },
  { label: 'Authon-Du-Perche', value: 'Authon-Du-Perche' },
  { label: 'Autilla Del Pino', value: 'Autilla Del Pino' },
  { label: 'Autillo De Campos', value: 'Autillo De Campos' },
  { label: 'Autlán De Navarro', value: 'Autlán De Navarro' },
  { label: 'Autol', value: 'Autol' },
  { label: 'Autozavodskoy Raion', value: 'Autozavodskoy Raion' },
  { label: 'Autrans', value: 'Autrans' },
  { label: 'Autry-Le-Châtel', value: 'Autry-Le-Châtel' },
  { label: 'Auttoinen', value: 'Auttoinen' },
  { label: 'Autun', value: 'Autun' },
  { label: 'Auvernier', value: 'Auvernier' },
  { label: 'Auvers-Le-Hamon', value: 'Auvers-Le-Hamon' },
  { label: 'Auvers-Saint-Georges', value: 'Auvers-Saint-Georges' },
  { label: 'Auvers-Sur-Oise', value: 'Auvers-Sur-Oise' },
  { label: 'Auvillar', value: 'Auvillar' },
  { label: 'Auw', value: 'Auw' },
  { label: 'Auxerre', value: 'Auxerre' },
  { label: 'Auxi-Le-Château', value: 'Auxi-Le-Château' },
  { label: 'Auxon-Dessous', value: 'Auxon-Dessous' },
  { label: 'Auxonne', value: 'Auxonne' },
  { label: 'Auxy', value: 'Auxy' },
  { label: 'Auzances', value: 'Auzances' },
  { label: 'Auzat-La-Combelle', value: 'Auzat-La-Combelle' },
  { label: 'Auzebosc', value: 'Auzebosc' },
  { label: 'Auzeville-Tolosane', value: 'Auzeville-Tolosane' },
  { label: 'Auzielle', value: 'Auzielle' },
  { label: 'Auzouer-En-Touraine', value: 'Auzouer-En-Touraine' },
  { label: 'Ava', value: 'Ava' },
  { label: 'Ava', value: 'Ava' },
  { label: 'Avadi', value: 'Avadi' },
  { label: 'Avaí', value: 'Avaí' },
  { label: 'Availles-Limouzine', value: 'Availles-Limouzine' },
  { label: 'Avaj', value: 'Avaj' },
  { label: 'Avajiq', value: 'Avajiq' },
  { label: 'Avallon', value: 'Avallon' },
  { label: 'Avalon', value: 'Avalon' },
  { label: 'Avalon', value: 'Avalon' },
  { label: 'Avalon', value: 'Avalon' },
  { label: 'Avanca', value: 'Avanca' },
  { label: 'Avane', value: 'Avane' },
  { label: 'Avanhandava', value: 'Avanhandava' },
  { label: 'Avanigadda', value: 'Avanigadda' },
  { label: 'Avanne-Aveney', value: 'Avanne-Aveney' },
  { label: 'Avanoor', value: 'Avanoor' },
  { label: 'Avanos', value: 'Avanos' },
  { label: 'Avanton', value: 'Avanton' },
  { label: 'Ávanuorri', value: 'Ávanuorri' },
  { label: 'Avaré', value: 'Avaré' },
  { label: 'Avarzaman', value: 'Avarzaman' },
  { label: 'Ávato', value: 'Ávato' },
  { label: 'Avcılar', value: 'Avcılar' },
  { label: 'Avdiyivka', value: 'Avdiyivka' },
  { label: 'Avdiyivka', value: 'Avdiyivka' },
  { label: 'Avdon', value: 'Avdon' },
  { label: 'Ave María', value: 'Ave María' },
  { label: 'Avegno', value: 'Avegno' },
  { label: 'Aveh', value: 'Aveh' },
  { label: 'Aveinte', value: 'Aveinte' },
  { label: 'Aveiras De Baixo', value: 'Aveiras De Baixo' },
  { label: 'Aveiras De Cima', value: 'Aveiras De Cima' },
  { label: 'Aveiras De Cima', value: 'Aveiras De Cima' },
  { label: 'Aveiro', value: 'Aveiro' },
  { label: 'Aveiro', value: 'Aveiro' },
  { label: 'Aveize', value: 'Aveize' },
  { label: 'Aveizieux', value: 'Aveizieux' },
  { label: 'Avelãs Da Ribeira', value: 'Avelãs Da Ribeira' },
  { label: 'Aveleda', value: 'Aveleda' },
  { label: 'Avelengo', value: 'Avelengo' },
  { label: 'Aveley', value: 'Aveley' },
  { label: 'Aveley', value: 'Aveley' },
  { label: 'Avelgem', value: 'Avelgem' },
  { label: 'Avelin', value: 'Avelin' },
  { label: 'Avelino Lopes', value: 'Avelino Lopes' },
  { label: 'Avelinópolis', value: 'Avelinópolis' },
  { label: 'Avella', value: 'Avella' },
  { label: 'Avellaneda', value: 'Avellaneda' },
  { label: 'Avellaneda', value: 'Avellaneda' },
  { label: 'Avellanes I Santa Linya Les', value: 'Avellanes I Santa Linya Les' },
  { label: 'Avellanosa De Muñó', value: 'Avellanosa De Muñó' },
  { label: 'Avellino', value: 'Avellino' },
  { label: 'Aveloso', value: 'Aveloso' },
  { label: 'Avenal', value: 'Avenal' },
  { label: 'Avenches', value: 'Avenches' },
  { label: 'Avenel', value: 'Avenel' },
  { label: 'Avenel', value: 'Avenel' },
  { label: 'Avenell Heights', value: 'Avenell Heights' },
  { label: 'Avenhorn', value: 'Avenhorn' },
  { label: 'Avening', value: 'Avening' },
  { label: 'Avensan', value: 'Avensan' },
  { label: 'Aventura', value: 'Aventura' },
  { label: 'Avenue B And C', value: 'Avenue B And C' },
  { label: 'Averara', value: 'Averara' },
  { label: 'Averill Park', value: 'Averill Park' },
  { label: 'Avermes', value: 'Avermes' },
  { label: 'Aver-O-Mar', value: 'Aver-O-Mar' },
  { label: 'Averøy', value: 'Averøy' },
  { label: 'Aversa', value: 'Aversa' },
  { label: 'Avery County', value: 'Avery County' },
  { label: 'Avery Creek', value: 'Avery Creek' },
  { label: 'Aves', value: 'Aves' },
  { label: 'Avesnelles', value: 'Avesnelles' },
  { label: 'Avesnes-Le-Comte', value: 'Avesnes-Le-Comte' },
  { label: 'Avesnes-Les-Aubert', value: 'Avesnes-Les-Aubert' },
  { label: 'Avesnes-Le-Sec', value: 'Avesnes-Le-Sec' },
  { label: 'Avessac', value: 'Avessac' },
  { label: 'Avesta', value: 'Avesta' },
  { label: 'Avetrana', value: 'Avetrana' },
  { label: 'Avèze', value: 'Avèze' },
  { label: 'Avezzano', value: 'Avezzano' },
  { label: 'Avgórou', value: 'Avgórou' },
  { label: 'Avià', value: 'Avià' },
  { label: 'Aviá Terai', value: 'Aviá Terai' },
  { label: 'Aviano', value: 'Aviano' },
  { label: 'Aviano-Castello', value: 'Aviano-Castello' },
  { label: 'Aviatico', value: 'Aviatico' },
  { label: 'Aviatorske', value: 'Aviatorske' },
  { label: 'Aviemore', value: 'Aviemore' },
  { label: 'Avigliana', value: 'Avigliana' },
  { label: 'Avigliano', value: 'Avigliano' },
  { label: 'Avigliano Umbro', value: 'Avigliano Umbro' },
  { label: 'Avignon', value: 'Avignon' },
  { label: 'Avignonet-Lauragais', value: 'Avignonet-Lauragais' },
  { label: 'Avila', value: 'Avila' },
  { label: 'Ávila', value: 'Ávila' },
  { label: 'Avila Beach', value: 'Avila Beach' },
  { label: 'Avilés', value: 'Avilés' },
  { label: 'Avilla', value: 'Avilla' },
  { label: 'Avilly-Saint-Léonard', value: 'Avilly-Saint-Léonard' },
  { label: 'Avinashi', value: 'Avinashi' },
  { label: 'Avintes', value: 'Avintes' },
  { label: 'Avinyó', value: 'Avinyó' },
  { label: 'Avinyonet De Puigventós', value: 'Avinyonet De Puigventós' },
  { label: 'Avinyonet Del Penedès', value: 'Avinyonet Del Penedès' },
  { label: 'Avio', value: 'Avio' },
  { label: 'Avion', value: 'Avion' },
  { label: 'Avión', value: 'Avión' },
  { label: 'Aviron', value: 'Aviron' },
  { label: 'Avis', value: 'Avis' },
  { label: 'Avis', value: 'Avis' },
  { label: 'Avise', value: 'Avise' },
  { label: 'Aviston', value: 'Aviston' },
  { label: 'Avize', value: 'Avize' },
  { label: 'Avlónas', value: 'Avlónas' },
  { label: 'Avlum', value: 'Avlum' },
  { label: 'Avoca', value: 'Avoca' },
  { label: 'Avoca', value: 'Avoca' },
  { label: 'Avoca', value: 'Avoca' },
  { label: 'Avoca', value: 'Avoca' },
  { label: 'Avoca Beach', value: 'Avoca Beach' },
  { label: 'Avocado Heights', value: 'Avocado Heights' },
  { label: 'Avoine', value: 'Avoine' },
  { label: 'Avola', value: 'Avola' },
  { label: 'Avolasca', value: 'Avolasca' },
  { label: 'Avon', value: 'Avon' },
  { label: 'Avon', value: 'Avon' },
  { label: 'Avon', value: 'Avon' },
  { label: 'Avon', value: 'Avon' },
  { label: 'Avon', value: 'Avon' },
  { label: 'Avon', value: 'Avon' },
  { label: 'Avon', value: 'Avon' },
  { label: 'Avon', value: 'Avon' },
  { label: 'Avon Center', value: 'Avon Center' },
  { label: 'Avon Lake', value: 'Avon Lake' },
  { label: 'Avon Park', value: 'Avon Park' },
  { label: 'Avon-By-The-Sea', value: 'Avon-By-The-Sea' },
  { label: 'Avondale', value: 'Avondale' },
  { label: 'Avondale', value: 'Avondale' },
  { label: 'Avondale', value: 'Avondale' },
  { label: 'Avondale', value: 'Avondale' },
  { label: 'Avondale', value: 'Avondale' },
  { label: 'Avondale Estates', value: 'Avondale Estates' },
  { label: 'Avondale Heights', value: 'Avondale Heights' },
  { label: 'Avonia', value: 'Avonia' },
  { label: 'Avord', value: 'Avord' },
  { label: 'Avoyelles Parish', value: 'Avoyelles Parish' },
  { label: 'Avra Valley', value: 'Avra Valley' },
  { label: 'Avram Iancu', value: 'Avram Iancu' },
  { label: 'Avram Iancu', value: 'Avram Iancu' },
  { label: 'Avrămeni', value: 'Avrămeni' },
  { label: 'Avrămeşti', value: 'Avrămeşti' },
  { label: 'Avrămești', value: 'Avrămești' },
  { label: 'Avranches', value: 'Avranches' },
  { label: 'Avratin', value: 'Avratin' },
  { label: 'Avrechy', value: 'Avrechy' },
  { label: 'Avrig', value: 'Avrig' },
  { label: 'Avrillé', value: 'Avrillé' },
  { label: 'Avry-Sur-Matran', value: 'Avry-Sur-Matran' },
  { label: 'Avşar', value: 'Avşar' },
  { label: 'Avshar', value: 'Avshar' },
  { label: 'Avsyunino', value: 'Avsyunino' },
  { label: 'Avtopoligon', value: 'Avtopoligon' },
  { label: 'Avtovo', value: 'Avtovo' },
  { label: 'Avtury', value: 'Avtury' },
  { label: 'Avvocata', value: 'Avvocata' },
  { label: 'Awaji Shi', value: 'Awaji Shi' },
  { label: 'Awallan', value: 'Awallan' },
  { label: 'Awallan', value: 'Awallan' },
  { label: 'Awang', value: 'Awang' },
  { label: 'Awang', value: 'Awang' },
  { label: 'Awans', value: 'Awans' },
  { label: 'Awantipur', value: 'Awantipur' },
  { label: 'Awārān District', value: 'Awārān District' },
  { label: 'Awara-Shi', value: 'Awara-Shi' },
  { label: 'Āwash', value: 'Āwash' },
  { label: 'Awa-Shi', value: 'Awa-Shi' },
  { label: 'Awendaw', value: 'Awendaw' },
  { label: 'Awgu', value: 'Awgu' },
  { label: 'Awjilah', value: 'Awjilah' },
  { label: 'Awka', value: 'Awka' },
  { label: 'Awlad Saqr', value: 'Awlad Saqr' },
  { label: 'Awsīm', value: 'Awsīm' },
  { label: 'Awsworth', value: 'Awsworth' },
  { label: 'Axams', value: 'Axams' },
  { label: 'Axapusco', value: 'Axapusco' },
  { label: 'Axaxacualco', value: 'Axaxacualco' },
  { label: 'Axbridge', value: 'Axbridge' },
  { label: 'Axel', value: 'Axel' },
  { label: 'Axente Sever', value: 'Axente Sever' },
  { label: 'Axim', value: 'Axim' },
  { label: 'Axintele', value: 'Axintele' },
  { label: 'Axioúpoli', value: 'Axioúpoli' },
  { label: 'Axixá', value: 'Axixá' },
  { label: 'Axixá Do Tocantins', value: 'Axixá Do Tocantins' },
  { label: 'Axixintla', value: 'Axixintla' },
  { label: 'Ax-Les-Thermes', value: 'Ax-Les-Thermes' },
  { label: 'Axminster', value: 'Axminster' },
  { label: 'Axochiapan', value: 'Axochiapan' },
  { label: 'Axochío', value: 'Axochío' },
  { label: 'Axós', value: 'Axós' },
  { label: 'Axotlán', value: 'Axotlán' },
  { label: 'Axstedt', value: 'Axstedt' },
  { label: 'Axtla De Terrazas', value: 'Axtla De Terrazas' },
  { label: 'Axum', value: 'Axum' },
  { label: 'Axutla', value: 'Axutla' },
  { label: 'Axuxco', value: 'Axuxco' },
  { label: 'Axvall', value: 'Axvall' },
  { label: 'Ay', value: 'Ay' },
  { label: 'Aya', value: 'Aya' },
  { label: 'Aya', value: 'Aya' },
  { label: 'Ayabaca', value: 'Ayabaca' },
  { label: 'Ayabe', value: 'Ayabe' },
  { label: 'Ayabe-Shi', value: 'Ayabe-Shi' },
  { label: 'Ayacucho', value: 'Ayacucho' },
  { label: 'Ayagoz', value: 'Ayagoz' },
  { label: 'Ayahualulco', value: 'Ayahualulco' },
  { label: 'Ayahualulco', value: 'Ayahualulco' },
  { label: 'Ayakudi', value: 'Ayakudi' },
  { label: 'Ayala', value: 'Ayala' },
  { label: 'Ayala/Aiara', value: 'Ayala/Aiara' },
  { label: 'Ayamé', value: 'Ayamé' },
  { label: 'Ayamonte', value: 'Ayamonte' },
  { label: 'Ayan', value: 'Ayan' },
  { label: 'Ayancık', value: 'Ayancık' },
  { label: 'Ayang-Ni', value: 'Ayang-Ni' },
  { label: 'Ayapa', value: 'Ayapa' },
  { label: 'Ayapa', value: 'Ayapa' },
  { label: 'Ayapango', value: 'Ayapango' },
  { label: 'Ayapel', value: 'Ayapel' },
  { label: 'Ayas', value: 'Ayas' },
  { label: 'Ayaş', value: 'Ayaş' },
  { label: 'Ayase Shi', value: 'Ayase Shi' },
  { label: 'Ayask', value: 'Ayask' },
  { label: 'Ayat', value: 'Ayat' },
  { label: 'Ayaviri', value: 'Ayaviri' },
  { label: 'Aybastı', value: 'Aybastı' },
  { label: 'Aydarken', value: 'Aydarken' },
  { label: 'Aydat', value: 'Aydat' },
  { label: 'Ayden', value: 'Ayden' },
  { label: 'Aydıncık', value: 'Aydıncık' },
  { label: 'Aydıncık', value: 'Aydıncık' },
  { label: 'Aydıntepe', value: 'Aydıntepe' },
  { label: 'Aydoilles', value: 'Aydoilles' },
  { label: 'Aydūn', value: 'Aydūn' },
  { label: 'Ayegui', value: 'Ayegui' },
  { label: 'Ayegui/Aiegi', value: 'Ayegui/Aiegi' },
  { label: 'Ayene', value: 'Ayene' },
  { label: 'Ayent', value: 'Ayent' },
  { label: 'Ayer', value: 'Ayer' },
  { label: 'Ayer Hangat', value: 'Ayer Hangat' },
  { label: 'Ayerbe', value: 'Ayerbe' },
  { label: 'Aygavan', value: 'Aygavan' },
  { label: 'Aygehovit', value: 'Aygehovit' },
  { label: 'Aygek', value: 'Aygek' },
  { label: 'Aygepat', value: 'Aygepat' },
  { label: 'Aygeshat', value: 'Aygeshat' },
  { label: 'Aygestan', value: 'Aygestan' },
  { label: 'Aygezard', value: 'Aygezard' },
  { label: 'Ayguemorte-Les-Graves', value: 'Ayguemorte-Les-Graves' },
  { label: 'Ayguesvives', value: 'Ayguesvives' },
  { label: 'Ayia Napa', value: 'Ayia Napa' },
  { label: 'Ayía Triás', value: 'Ayía Triás' },
  { label: 'Aying', value: 'Aying' },
  { label: 'Áyioi Apóstoloi', value: 'Áyioi Apóstoloi' },
  { label: 'Áyios Adhrianós', value: 'Áyios Adhrianós' },
  { label: 'Áyios Konstandínos', value: 'Áyios Konstandínos' },
  { label: 'Áyios Konstandínos', value: 'Áyios Konstandínos' },
  { label: 'Áyios Nikólaos', value: 'Áyios Nikólaos' },
  { label: 'Áyios Thomás', value: 'Áyios Thomás' },
  { label: 'Áyios Vasílios', value: 'Áyios Vasílios' },
  { label: 'Aykhal', value: 'Aykhal' },
  { label: 'Aykino', value: 'Aykino' },
  { label: 'Aykol', value: 'Aykol' },
  { label: 'Ayl', value: 'Ayl' },
  { label: 'Aylesbury', value: 'Aylesbury' },
  { label: 'Aylesford', value: 'Aylesford' },
  { label: 'Aylesham', value: 'Aylesham' },
  { label: 'Ayllón', value: 'Ayllón' },
  { label: 'Aylmer', value: 'Aylmer' },
  { label: 'Aylsham', value: 'Aylsham' },
  { label: 'Aymavilles', value: 'Aymavilles' },
  { label: 'Ayn Al Arab', value: 'Ayn Al Arab' },
  { label: 'Ayn Jannah', value: 'Ayn Jannah' },
  { label: 'Ayna', value: 'Ayna' },
  { label: 'Ayna', value: 'Ayna' },
  { label: 'Ayní', value: 'Ayní' },
  { label: 'Ayódar', value: 'Ayódar' },
  { label: 'Ayodhya', value: 'Ayodhya' },
  { label: 'Ayometitla', value: 'Ayometitla' },
  { label: 'Ayoó De Vidriales', value: 'Ayoó De Vidriales' },
  { label: 'Ayoquezco De Aldama', value: 'Ayoquezco De Aldama' },
  { label: 'Ayora', value: 'Ayora' },
  { label: 'Ayorou', value: 'Ayorou' },
  { label: 'Ayotitlán', value: 'Ayotitlán' },
  { label: 'Ayotla', value: 'Ayotla' },
  { label: 'Ayotlán Municipality', value: 'Ayotlán Municipality' },
  { label: 'Ayotoxco De Guerrero', value: 'Ayotoxco De Guerrero' },
  { label: 'Ayotuxtla', value: 'Ayotuxtla' },
  { label: 'Ayotzinapa', value: 'Ayotzinapa' },
  { label: 'Ayotzintepec', value: 'Ayotzintepec' },
  { label: 'Ayoun El Atrous', value: 'Ayoun El Atrous' },
  { label: 'Ayr', value: 'Ayr' },
  { label: 'Ayr', value: 'Ayr' },
  { label: 'Ayrancı', value: 'Ayrancı' },
  { label: 'Ayron', value: 'Ayron' },
  { label: 'Ayse', value: 'Ayse' },
  { label: 'Aysén', value: 'Aysén' },
  { label: 'Aysha', value: 'Aysha' },
  { label: 'Aystetten', value: 'Aystetten' },
  { label: 'Ay-Sur-Moselle', value: 'Ay-Sur-Moselle' },
  { label: 'Ayteke Bi', value: 'Ayteke Bi' },
  { label: 'Aytos', value: 'Aytos' },
  { label: 'Aytré', value: 'Aytré' },
  { label: 'Ayuela', value: 'Ayuela' },
  { label: 'Ayugan', value: 'Ayugan' },
  { label: 'Ayungon', value: 'Ayungon' },
  { label: 'Ayuquila', value: 'Ayuquila' },
  { label: 'Ayusan Uno', value: 'Ayusan Uno' },
  { label: 'Ayutinskiy', value: 'Ayutinskiy' },
  { label: 'Ayutla', value: 'Ayutla' },
  { label: 'Ayutla', value: 'Ayutla' },
  { label: 'Ayutla De Los Libres', value: 'Ayutla De Los Libres' },
  { label: 'Ayutla Jalisco', value: 'Ayutla Jalisco' },
  { label: 'Ayutuxtepeque', value: 'Ayutuxtepeque' },
  { label: 'Ayvacık', value: 'Ayvacık' },
  { label: 'Ayvacık', value: 'Ayvacık' },
  { label: 'Ayvalık', value: 'Ayvalık' },
  { label: 'Aywaille', value: 'Aywaille' },
  { label: 'Ayy', value: 'Ayy' },
  { label: 'Ayyampettai', value: 'Ayyampettai' },
  { label: 'Az Zabadānī', value: 'Az Zabadānī' },
  { label: 'Az Zahir', value: 'Az Zahir' },
  { label: 'Az Zahir', value: 'Az Zahir' },
  { label: 'Az Zarqā', value: 'Az Zarqā' },
  { label: 'Az Zāwīyah', value: 'Az Zāwīyah' },
  { label: 'Az Zawr', value: 'Az Zawr' },
  { label: 'Az Zaydīyah', value: 'Az Zaydīyah' },
  { label: 'Az Zaymah', value: 'Az Zaymah' },
  { label: 'Az Zilal', value: 'Az Zilal' },
  { label: 'Az Zubayr', value: 'Az Zubayr' },
  { label: 'Az Zughbah', value: 'Az Zughbah' },
  { label: 'Az Zuhrah', value: 'Az Zuhrah' },
  { label: 'Az Zulfī', value: 'Az Zulfī' },
  { label: 'Az Zurra`', value: 'Az Zurra`' },
  { label: 'Az Zuwayb', value: 'Az Zuwayb' },
  { label: 'Az Zuwaytīnah', value: 'Az Zuwaytīnah' },
  { label: 'Azacualpa', value: 'Azacualpa' },
  { label: 'Azadi', value: 'Azadi' },
  { label: 'Azadshahr', value: 'Azadshahr' },
  { label: 'Azagra', value: 'Azagra' },
  { label: 'Azagra', value: 'Azagra' },
  { label: 'Azaila', value: 'Azaila' },
  { label: 'Azalea Park', value: 'Azalea Park' },
  { label: 'Azambuja', value: 'Azambuja' },
  { label: 'Azambuja', value: 'Azambuja' },
  { label: 'Azambuja (Town)', value: 'Azambuja (Town)' },
  { label: 'Azamgarh', value: 'Azamgarh' },
  { label: 'Azandarian', value: 'Azandarian' },
  { label: 'Azángaro', value: 'Azángaro' },
  { label: 'Azanka', value: 'Azanka' },
  { label: 'Azanuy-Alins', value: 'Azanuy-Alins' },
  { label: 'Azara', value: 'Azara' },
  { label: 'Azara', value: 'Azara' },
  { label: 'Azare', value: 'Azare' },
  { label: 'Azarshahr', value: 'Azarshahr' },
  { label: 'Azatamut', value: 'Azatamut' },
  { label: 'Azatan', value: 'Azatan' },
  { label: 'Azay-Le-Brûlé', value: 'Azay-Le-Brûlé' },
  { label: 'Azay-Le-Ferron', value: 'Azay-Le-Ferron' },
  { label: 'Azay-Le-Rideau', value: 'Azay-Le-Rideau' },
  { label: 'Azay-Sur-Cher', value: 'Azay-Sur-Cher' },
  { label: 'Azaz District', value: 'Azaz District' },
  { label: 'Azazga', value: 'Azazga' },
  { label: 'Azcapotzalco', value: 'Azcapotzalco' },
  { label: 'Azdavay', value: 'Azdavay' },
  { label: 'Azé', value: 'Azé' },
  { label: 'Azé', value: 'Azé' },
  { label: 'Azeglio', value: 'Azeglio' },
  { label: 'Azeh Abad', value: 'Azeh Abad' },
  { label: 'Azeitão', value: 'Azeitão' },
  { label: 'Azemmour', value: 'Azemmour' },
  { label: 'Azenha', value: 'Azenha' },
  { label: 'Azenhas Do Mar', value: 'Azenhas Do Mar' },
  { label: 'Azevo', value: 'Azevo' },
  { label: 'Azhikkal', value: 'Azhikkal' },
  { label: 'Azilal', value: 'Azilal' },
  { label: 'Azilal Province', value: 'Azilal Province' },
  { label: 'Azilda', value: 'Azilda' },
  { label: 'Azille', value: 'Azille' },
  { label: 'Azinhaga', value: 'Azinhaga' },
  { label: 'Aziziye', value: 'Aziziye' },
  { label: 'Azizpur', value: 'Azizpur' },
  { label: 'Azkoitia', value: 'Azkoitia' },
  { label: 'Azle', value: 'Azle' },
  { label: 'Azlor', value: 'Azlor' },
  { label: 'Azmour', value: 'Azmour' },
  { label: 'Azna', value: 'Azna' },
  { label: 'Aznahri', value: 'Aznahri' },
  { label: 'Aznakayevskiy Rayon', value: 'Aznakayevskiy Rayon' },
  { label: 'Aznalcázar', value: 'Aznalcázar' },
  { label: 'Aznalcóllar', value: 'Aznalcóllar' },
  { label: 'Azofra', value: 'Azofra' },
  { label: 'Azogues', value: 'Azogues' },
  { label: 'Azor', value: 'Azor' },
  { label: 'Azougui', value: 'Azougui' },
  { label: 'Azov', value: 'Azov' },
  { label: 'Azovo', value: 'Azovo' },
  { label: 'Azovskaya', value: 'Azovskaya' },
  { label: 'Azovskoye', value: 'Azovskoye' },
  { label: 'Azoyú', value: 'Azoyú' },
  { label: 'Azpeitia', value: 'Azpeitia' },
  { label: 'Azrou', value: 'Azrou' },
  { label: 'Aztalan', value: 'Aztalan' },
  { label: 'Aztec', value: 'Aztec' },
  { label: 'Azteca', value: 'Azteca' },
  { label: 'Aztlán', value: 'Aztlán' },
  { label: 'Azua', value: 'Azua' },
  { label: 'Azuaga', value: 'Azuaga' },
  { label: 'Azuara', value: 'Azuara' },
  { label: 'Azuébar', value: 'Azuébar' },
  { label: 'Azueira', value: 'Azueira' },
  { label: 'Azuelo', value: 'Azuelo' },
  { label: 'Azuga', value: 'Azuga' },
  { label: 'Azumbilla', value: 'Azumbilla' },
  { label: 'Azumino', value: 'Azumino' },
  { label: 'Azumino-Shi', value: 'Azumino-Shi' },
  { label: 'Azuqueca De Henares', value: 'Azuqueca De Henares' },
  { label: 'Azusa', value: 'Azusa' },
  { label: 'Azután', value: 'Azután' },
  { label: 'Azyartso', value: 'Azyartso' },
  { label: 'Azzaba', value: 'Azzaba' },
  { label: 'Azzanello', value: 'Azzanello' },
  { label: 'Azzano', value: 'Azzano' },
  { label: 'Azzano Dasti', value: 'Azzano Dasti' },
  { label: 'Azzano Decimo', value: 'Azzano Decimo' },
  { label: 'Azzano Mella', value: 'Azzano Mella' },
  { label: 'Azzano San Paolo', value: 'Azzano San Paolo' },
  { label: 'Azzate', value: 'Azzate' },
  { label: 'Azzio', value: 'Azzio' },
  { label: 'Azzone', value: 'Azzone' },
  { label: 'Ba', value: 'Ba' },
  { label: 'Ba Province', value: 'Ba Province' },
  { label: 'Baabda', value: 'Baabda' },
  { label: 'Baalbek', value: 'Baalbek' },
  { label: 'Baalberge', value: 'Baalberge' },
  { label: 'Baalder', value: 'Baalder' },
  { label: 'Baambrugge', value: 'Baambrugge' },
  { label: 'Baao', value: 'Baao' },
  { label: 'Baar', value: 'Baar' },
  { label: 'Baardheere', value: 'Baardheere' },
  { label: 'Baardwijk', value: 'Baardwijk' },
  { label: 'Baar-Ebenhausen', value: 'Baar-Ebenhausen' },
  { label: 'Baarle-Hertog', value: 'Baarle-Hertog' },
  { label: 'Baarle-Nassau', value: 'Baarle-Nassau' },
  { label: 'Baarlo', value: 'Baarlo' },
  { label: 'Baarn', value: 'Baarn' },
  { label: 'Baay', value: 'Baay' },
  { label: 'Baay', value: 'Baay' },
  { label: 'Bab Al Moatham', value: 'Bab Al Moatham' },
  { label: 'Bab Al Sharqi', value: 'Bab Al Sharqi' },
  { label: 'Bab Ezzouar', value: 'Bab Ezzouar' },
  { label: 'Baba', value: 'Baba' },
  { label: 'Baba Ana', value: 'Baba Ana' },
  { label: 'Baba Khanjar', value: 'Baba Khanjar' },
  { label: 'Baba Monir', value: 'Baba Monir' },
  { label: 'Baba Pir', value: 'Baba Pir' },
  { label: 'Baba Qasem', value: 'Baba Qasem' },
  { label: 'Babaçulândia', value: 'Babaçulândia' },
  { label: 'Babadag', value: 'Babadag' },
  { label: 'Babadağ', value: 'Babadağ' },
  { label: 'Babaeski', value: 'Babaeski' },
  { label: 'Babag', value: 'Babag' },
  { label: 'Babag', value: 'Babag' },
  { label: 'Babag', value: 'Babag' },
  { label: 'Babaheydar', value: 'Babaheydar' },
  { label: 'Babahoyo', value: 'Babahoyo' },
  { label: 'Babai', value: 'Babai' },
  { label: 'Băbăiţa', value: 'Băbăiţa' },
  { label: 'Babana', value: 'Babana' },
  { label: 'Băbana', value: 'Băbana' },
  { label: 'Babanar', value: 'Babanar' },
  { label: 'Babanka', value: 'Babanka' },
  { label: 'Babanki', value: 'Babanki' },
  { label: 'Babarashani', value: 'Babarashani' },
  { label: 'Babat', value: 'Babat' },
  { label: 'Babati', value: 'Babati' },
  { label: 'Babatngon', value: 'Babatngon' },
  { label: 'Babatngon', value: 'Babatngon' },
  { label: 'Babayevo', value: 'Babayevo' },
  { label: 'Babayevskiy Rayon', value: 'Babayevskiy Rayon' },
  { label: 'Babayurt', value: 'Babayurt' },
  { label: 'Babayurtovskiy Rayon', value: 'Babayurtovskiy Rayon' },
  { label: 'Babberich', value: 'Babberich' },
  { label: 'Babbitt', value: 'Babbitt' },
  { label: 'Babenhausen', value: 'Babenhausen' },
  { label: 'Babenhausen', value: 'Babenhausen' },
  { label: 'Băbeni', value: 'Băbeni' },
  { label: 'Băbeni', value: 'Băbeni' },
  { label: 'Băbeni', value: 'Băbeni' },
  { label: 'Băbeni-Oltețu', value: 'Băbeni-Oltețu' },
  { label: 'Babensham', value: 'Babensham' },
  { label: 'Baberu', value: 'Baberu' },
  { label: 'Babeţi', value: 'Babeţi' },
  { label: 'Babiak', value: 'Babiak' },
  { label: 'Babica', value: 'Babica' },
  { label: 'Babice', value: 'Babice' },
  { label: 'Babice', value: 'Babice' },
  { label: 'Băbiciu', value: 'Băbiciu' },
  { label: 'Babilafuente', value: 'Babilafuente' },
  { label: 'Babimost', value: 'Babimost' },
  { label: 'Babina', value: 'Babina' },
  { label: 'Babina Greda', value: 'Babina Greda' },
  { label: 'Babinda', value: 'Babinda' },
  { label: 'Babino', value: 'Babino' },
  { label: 'Babócsa', value: 'Babócsa' },
  { label: 'Babol', value: 'Babol' },
  { label: 'Bábolna', value: 'Bábolna' },
  { label: 'Babolqani', value: 'Babolqani' },
  { label: 'Babolsar', value: 'Babolsar' },
  { label: 'Babonneau Proper', value: 'Babonneau Proper' },
  { label: 'Babo-Pangulo', value: 'Babo-Pangulo' },
  { label: 'Babor - Ville', value: 'Babor - Ville' },
  { label: 'Baborigame', value: 'Baborigame' },
  { label: 'Baborów', value: 'Baborów' },
  { label: 'Baboszewo', value: 'Baboszewo' },
  { label: 'Babra', value: 'Babra' },
  { label: 'Babrala', value: 'Babrala' },
  { label: 'Babruysk', value: 'Babruysk' },
  { label: 'Babruyski Rayon', value: 'Babruyski Rayon' },
  { label: 'Babson Park', value: 'Babson Park' },
  { label: 'Babstovo', value: 'Babstovo' },
  { label: 'Babța', value: 'Babța' },
  { label: 'Babu', value: 'Babu' },
  { label: 'Babug', value: 'Babug' },
  { label: 'Babugarh', value: 'Babugarh' },
  { label: 'Babugent', value: 'Babugent' },
  { label: 'Babura', value: 'Babura' },
  { label: 'Baburia', value: 'Baburia' },
  { label: 'Babushkin', value: 'Babushkin' },
  { label: 'Babushkin', value: 'Babushkin' },
  { label: 'Babworth', value: 'Babworth' },
  { label: 'Babylon', value: 'Babylon' },
  { label: 'Babynino', value: 'Babynino' },
  { label: 'Babyninskiy Rayon', value: 'Babyninskiy Rayon' },
  { label: 'Bắc Giang', value: 'Bắc Giang' },
  { label: 'Bắc Kạn', value: 'Bắc Kạn' },
  { label: 'Bạc Liêu', value: 'Bạc Liêu' },
  { label: 'Bắc Ninh', value: 'Bắc Ninh' },
  { label: 'Baca', value: 'Baca' },
  { label: 'Baca County', value: 'Baca County' },
  { label: 'Bacabac', value: 'Bacabac' },
  { label: 'Bacabac', value: 'Bacabac' },
  { label: 'Bacabachi', value: 'Bacabachi' },
  { label: 'Bacabal', value: 'Bacabal' },
  { label: 'Bacabchén', value: 'Bacabchén' },
  { label: 'Bacabeira', value: 'Bacabeira' },
  { label: 'Bacacay', value: 'Bacacay' },
  { label: 'Bacadéhuachi', value: 'Bacadéhuachi' },
  { label: 'Bacag', value: 'Bacag' },
  { label: 'Bacag', value: 'Bacag' },
  { label: 'Bacalan', value: 'Bacalan' },
  { label: 'Bacalar', value: 'Bacalar' },
  { label: 'Băcălești', value: 'Băcălești' },
  { label: 'Bacame Nuevo', value: 'Bacame Nuevo' },
  { label: 'Băcani', value: 'Băcani' },
  { label: 'Bacanora', value: 'Bacanora' },
  { label: 'Bacares', value: 'Bacares' },
  { label: 'Bacarra', value: 'Bacarra' },
  { label: 'Bacarra', value: 'Bacarra' },
  { label: 'Bacău', value: 'Bacău' },
  { label: 'Bacayawan', value: 'Bacayawan' },
  { label: 'Bacayawan', value: 'Bacayawan' },
  { label: 'Baccarat', value: 'Baccarat' },
  { label: 'Bacchus Marsh', value: 'Bacchus Marsh' },
  { label: 'Bacea', value: 'Bacea' },
  { label: 'Baceno', value: 'Baceno' },
  { label: 'Bacerac', value: 'Bacerac' },
  { label: 'Băceşti', value: 'Băceşti' },
  { label: 'Bach', value: 'Bach' },
  { label: 'Bäch', value: 'Bäch' },
  { label: 'Bachajón', value: 'Bachajón' },
  { label: 'Bachant', value: 'Bachant' },
  { label: 'Bacharach', value: 'Bacharach' },
  { label: 'Bachatskiy', value: 'Bachatskiy' },
  { label: 'Bachauan', value: 'Bachauan' },
  { label: 'Bachenbülach', value: 'Bachenbülach' },
  { label: 'Bachhagel', value: 'Bachhagel' },
  { label: 'Bachhraon', value: 'Bachhraon' },
  { label: 'Bachhrawan', value: 'Bachhrawan' },
  { label: 'Bachigualatito', value: 'Bachigualatito' },
  { label: 'Bächingen An Der Brenz', value: 'Bächingen An Der Brenz' },
  { label: 'Bachíniva', value: 'Bachíniva' },
  { label: 'Bachi-Yurt', value: 'Bachi-Yurt' },
  { label: 'Bachmanning', value: 'Bachmanning' },
  { label: 'Bachoco', value: 'Bachoco' },
  { label: 'Bachomobampo Número Dos', value: 'Bachomobampo Número Dos' },
  { label: 'Bachowice', value: 'Bachowice' },
  { label: 'Bachy', value: 'Bachy' },
  { label: 'Băcia', value: 'Băcia' },
  { label: 'Băcioiu', value: 'Băcioiu' },
  { label: 'Baciu', value: 'Baciu' },
  { label: 'Baciu', value: 'Baciu' },
  { label: 'Back Mountain', value: 'Back Mountain' },
  { label: 'Backa', value: 'Backa' },
  { label: 'Bačka Palanka', value: 'Bačka Palanka' },
  { label: 'Bačka Topola', value: 'Bačka Topola' },
  { label: 'Bački Breg', value: 'Bački Breg' },
  { label: 'Bački Petrovac', value: 'Bački Petrovac' },
  { label: 'Backnang', value: 'Backnang' },
  { label: 'Bačko Gradište', value: 'Bačko Gradište' },
  { label: 'Bačko Petrovo Selo', value: 'Bačko Petrovo Selo' },
  { label: 'Baćkowice', value: 'Baćkowice' },
  { label: 'Backworth', value: 'Backworth' },
  { label: 'Baclayon', value: 'Baclayon' },
  { label: 'Bâcleș', value: 'Bâcleș' },
  { label: 'Bacliff', value: 'Bacliff' },
  { label: 'Bacnar', value: 'Bacnar' },
  { label: 'Bacnar', value: 'Bacnar' },
  { label: 'Bacnor East', value: 'Bacnor East' },
  { label: 'Bacnor East', value: 'Bacnor East' },
  { label: 'Bacnotan', value: 'Bacnotan' },
  { label: 'Bacnotan', value: 'Bacnotan' },
  { label: 'Baco', value: 'Baco' },
  { label: 'Bacobampo', value: 'Bacobampo' },
  { label: 'Bacoli', value: 'Bacoli' },
  { label: 'Bacolod', value: 'Bacolod' },
  { label: 'Bacolod', value: 'Bacolod' },
  { label: 'Bacolod', value: 'Bacolod' },
  { label: 'Bacolod', value: 'Bacolod' },
  { label: 'Bacolod City', value: 'Bacolod City' },
  { label: 'Bacolod Grande', value: 'Bacolod Grande' },
  { label: 'Bacolod-Kalawi', value: 'Bacolod-Kalawi' },
  { label: 'Bacolor', value: 'Bacolor' },
  { label: 'Bacolor', value: 'Bacolor' },
  { label: 'Bacon', value: 'Bacon' },
  { label: 'Bacon County', value: 'Bacon County' },
  { label: 'Bacong', value: 'Bacong' },
  { label: 'Bacoor', value: 'Bacoor' },
  { label: 'Bacorehuis', value: 'Bacorehuis' },
  { label: 'Bacova', value: 'Bacova' },
  { label: 'Bacqueville-En-Caux', value: 'Bacqueville-En-Caux' },
  { label: 'Bácsalmás', value: 'Bácsalmás' },
  { label: 'Bácsalmási Járás', value: 'Bácsalmási Járás' },
  { label: 'Bacsay', value: 'Bacsay' },
  { label: 'Bacsay', value: 'Bacsay' },
  { label: 'Bácsbokod', value: 'Bácsbokod' },
  { label: 'Bactad Proper', value: 'Bactad Proper' },
  { label: 'Bactad Proper', value: 'Bactad Proper' },
  { label: 'Bacton', value: 'Bacton' },
  { label: 'Bâcu', value: 'Bâcu' },
  { label: 'Bacu Abis', value: 'Bacu Abis' },
  { label: 'Bacuag', value: 'Bacuag' },
  { label: 'Bacuag', value: 'Bacuag' },
  { label: 'Bacubirito', value: 'Bacubirito' },
  { label: 'Baculin', value: 'Baculin' },
  { label: 'Baculin', value: 'Baculin' },
  { label: 'Baculin', value: 'Baculin' },
  { label: 'Baculongan', value: 'Baculongan' },
  { label: 'Baculongan', value: 'Baculongan' },
  { label: 'Bácum', value: 'Bácum' },
  { label: 'Bacundao Weste', value: 'Bacundao Weste' },
  { label: 'Bacundao Weste', value: 'Bacundao Weste' },
  { label: 'Bacungan', value: 'Bacungan' },
  { label: 'Bacup', value: 'Bacup' },
  { label: 'Bacuri', value: 'Bacuri' },
  { label: 'Bacurituba', value: 'Bacurituba' },
  { label: 'Bacuyangan', value: 'Bacuyangan' },
  { label: 'Bad Abbach', value: 'Bad Abbach' },
  { label: 'Bad Aibling', value: 'Bad Aibling' },
  { label: 'Bad Alexandersbad', value: 'Bad Alexandersbad' },
  { label: 'Bad Arolsen', value: 'Bad Arolsen' },
  { label: 'Bad Aussee', value: 'Bad Aussee' },
  { label: 'Bad Axe', value: 'Bad Axe' },
  { label: 'Bad Bellingen', value: 'Bad Bellingen' },
  { label: 'Bad Belzig', value: 'Bad Belzig' },
  { label: 'Bad Bentheim', value: 'Bad Bentheim' },
  { label: 'Bad Bergzabern', value: 'Bad Bergzabern' },
  { label: 'Bad Berka', value: 'Bad Berka' },
  { label: 'Bad Berleburg', value: 'Bad Berleburg' },
  { label: 'Bad Berneck Im Fichtelgebirge', value: 'Bad Berneck Im Fichtelgebirge' },
  { label: 'Bad Bevensen', value: 'Bad Bevensen' },
  { label: 'Bad Bibra', value: 'Bad Bibra' },
  { label: 'Bad Birnbach', value: 'Bad Birnbach' },
  { label: 'Bad Blankenburg', value: 'Bad Blankenburg' },
  { label: 'Bad Bleiberg', value: 'Bad Bleiberg' },
  { label: 'Bad Blumau', value: 'Bad Blumau' },
  { label: 'Bad Bocklet', value: 'Bad Bocklet' },
  { label: 'Bad Brambach', value: 'Bad Brambach' },
  { label: 'Bad Bramstedt', value: 'Bad Bramstedt' },
  { label: 'Bad Breisig', value: 'Bad Breisig' },
  { label: 'Bad Brückenau', value: 'Bad Brückenau' },
  { label: 'Bad Buchau', value: 'Bad Buchau' },
  { label: 'Bad Camberg', value: 'Bad Camberg' },
  { label: 'Bad Deutsch-Altenburg', value: 'Bad Deutsch-Altenburg' },
  { label: 'Bad Ditzenbach', value: 'Bad Ditzenbach' },
  { label: 'Bad Doberan', value: 'Bad Doberan' },
  { label: 'Bad Driburg', value: 'Bad Driburg' },
  { label: 'Bad Düben', value: 'Bad Düben' },
  { label: 'Bad Dürkheim', value: 'Bad Dürkheim' },
  { label: 'Bad Dürrenberg', value: 'Bad Dürrenberg' },
  { label: 'Bad Dürrheim', value: 'Bad Dürrheim' },
  { label: 'Bad Eilsen', value: 'Bad Eilsen' },
  { label: 'Bad Eisenkappel', value: 'Bad Eisenkappel' },
  { label: 'Bad Elster', value: 'Bad Elster' },
  { label: 'Bad Ems', value: 'Bad Ems' },
  { label: 'Bad Endbach', value: 'Bad Endbach' },
  { label: 'Bad Endorf', value: 'Bad Endorf' },
  { label: 'Bad Erlach', value: 'Bad Erlach' },
  { label: 'Bad Essen', value: 'Bad Essen' },
  { label: 'Bad Fallingbostel', value: 'Bad Fallingbostel' },
  { label: 'Bad Feilnbach', value: 'Bad Feilnbach' },
  { label: 'Bad Fischau', value: 'Bad Fischau' },
  { label: 'Bad Frankenhausen', value: 'Bad Frankenhausen' },
  { label: 'Bad Fredeburg', value: 'Bad Fredeburg' },
  { label: 'Bad Freienwalde', value: 'Bad Freienwalde' },
  { label: 'Bad Füssing', value: 'Bad Füssing' },
  { label: 'Bad Gams', value: 'Bad Gams' },
  { label: 'Bad Gandersheim', value: 'Bad Gandersheim' },
  { label: 'Bad Gastein', value: 'Bad Gastein' },
  { label: 'Bad Gleichenberg', value: 'Bad Gleichenberg' },
  { label: 'Bad Goisern', value: 'Bad Goisern' },
  { label: 'Bad Griesbach', value: 'Bad Griesbach' },
  { label: 'Bad Grund', value: 'Bad Grund' },
  { label: 'Bad Hall', value: 'Bad Hall' },
  { label: 'Bad Häring', value: 'Bad Häring' },
  { label: 'Bad Harzburg', value: 'Bad Harzburg' },
  { label: 'Bad Heilbrunn', value: 'Bad Heilbrunn' },
  { label: 'Bad Herrenalb', value: 'Bad Herrenalb' },
  { label: 'Bad Hersfeld', value: 'Bad Hersfeld' },
  { label: 'Bad Hofgastein', value: 'Bad Hofgastein' },
  { label: 'Bad Holzhausen', value: 'Bad Holzhausen' },
  { label: 'Bad Homburg Vor Der Höhe', value: 'Bad Homburg Vor Der Höhe' },
  { label: 'Bad Honnef', value: 'Bad Honnef' },
  { label: 'Bad Hönningen', value: 'Bad Hönningen' },
  { label: 'Bad Iburg', value: 'Bad Iburg' },
  { label: 'Bad Ischl', value: 'Bad Ischl' },
  { label: 'Bad Karlshafen', value: 'Bad Karlshafen' },
  { label: 'Bad Khvoreh', value: 'Bad Khvoreh' },
  { label: 'Bad Kissingen', value: 'Bad Kissingen' },
  { label: 'Bad Kleinen', value: 'Bad Kleinen' },
  { label: 'Bad Kleinkirchheim', value: 'Bad Kleinkirchheim' },
  { label: 'Bad Klosterlausnitz', value: 'Bad Klosterlausnitz' },
  { label: 'Bad Kohlgrub', value: 'Bad Kohlgrub' },
  { label: 'Bad König', value: 'Bad König' },
  { label: 'Bad Königshofen Im Grabfeld', value: 'Bad Königshofen Im Grabfeld' },
  { label: 'Bad Kösen', value: 'Bad Kösen' },
  { label: 'Bad Köstritz', value: 'Bad Köstritz' },
  { label: 'Bad Kreuzen', value: 'Bad Kreuzen' },
  { label: 'Bad Kreuznach', value: 'Bad Kreuznach' },
  { label: 'Bad Laasphe', value: 'Bad Laasphe' },
  { label: 'Bad Laer', value: 'Bad Laer' },
  { label: 'Bad Langensalza', value: 'Bad Langensalza' },
  { label: 'Bad Lauchstädt', value: 'Bad Lauchstädt' },
  { label: 'Bad Lausick', value: 'Bad Lausick' },
  { label: 'Bad Lauterberg Im Harz', value: 'Bad Lauterberg Im Harz' },
  { label: 'Bad Leonfelden', value: 'Bad Leonfelden' },
  { label: 'Bad Liebenstein', value: 'Bad Liebenstein' },
  { label: 'Bad Liebenwerda', value: 'Bad Liebenwerda' },
  { label: 'Bad Liebenzell', value: 'Bad Liebenzell' },
  { label: 'Bad Lippspringe', value: 'Bad Lippspringe' },
  { label: 'Bad Lobenstein', value: 'Bad Lobenstein' },
  { label: 'Bad Marienberg', value: 'Bad Marienberg' },
  { label: 'Bad Meinberg', value: 'Bad Meinberg' },
  { label: 'Bad Mergentheim', value: 'Bad Mergentheim' },
  { label: 'Bad Münder Am Deister', value: 'Bad Münder Am Deister' },
  { label: 'Bad Münster Am Stein-Ebernburg', value: 'Bad Münster Am Stein-Ebernburg' },
  { label: 'Bad Münstereifel', value: 'Bad Münstereifel' },
  { label: 'Bad Muskau', value: 'Bad Muskau' },
  { label: 'Bad Nauheim', value: 'Bad Nauheim' },
  { label: 'Bad Nenndorf', value: 'Bad Nenndorf' },
  { label: 'Bad Neuenahr-Ahrweiler', value: 'Bad Neuenahr-Ahrweiler' },
  { label: 'Bad Neustadt An Der Saale', value: 'Bad Neustadt An Der Saale' },
  { label: 'Bad Oeynhausen', value: 'Bad Oeynhausen' },
  { label: 'Bad Oldesloe', value: 'Bad Oldesloe' },
  { label: 'Bad Orb', value: 'Bad Orb' },
  { label: 'Bad Peterstal-Griesbach', value: 'Bad Peterstal-Griesbach' },
  { label: 'Bad Pirawarth', value: 'Bad Pirawarth' },
  { label: 'Bad Pyrmont', value: 'Bad Pyrmont' },
  { label: 'Bad Radkersburg', value: 'Bad Radkersburg' },
  { label: 'Bad Ragaz', value: 'Bad Ragaz' },
  { label: 'Bad Rappenau', value: 'Bad Rappenau' },
  { label: 'Bad Reichenhall', value: 'Bad Reichenhall' },
  { label: 'Bad Rippoldsau-Schapbach', value: 'Bad Rippoldsau-Schapbach' },
  { label: 'Bad Rothenfelde', value: 'Bad Rothenfelde' },
  { label: 'Bad Saarow', value: 'Bad Saarow' },
  { label: 'Bad Sachsa', value: 'Bad Sachsa' },
  { label: 'Bad Säckingen', value: 'Bad Säckingen' },
  { label: 'Bad Salzdetfurth', value: 'Bad Salzdetfurth' },
  { label: 'Bad Salzschlirf', value: 'Bad Salzschlirf' },
  { label: 'Bad Salzuflen', value: 'Bad Salzuflen' },
  { label: 'Bad Salzungen', value: 'Bad Salzungen' },
  { label: 'Bad Sankt Leonhard Im Lavanttal', value: 'Bad Sankt Leonhard Im Lavanttal' },
  { label: 'Bad Sassendorf', value: 'Bad Sassendorf' },
  { label: 'Bad Sauerbrunn', value: 'Bad Sauerbrunn' },
  { label: 'Bad Schallerbach', value: 'Bad Schallerbach' },
  { label: 'Bad Schandau', value: 'Bad Schandau' },
  { label: 'Bad Schlema', value: 'Bad Schlema' },
  { label: 'Bad Schmiedeberg', value: 'Bad Schmiedeberg' },
  { label: 'Bad Schönau', value: 'Bad Schönau' },
  { label: 'Bad Schussenried', value: 'Bad Schussenried' },
  { label: 'Bad Schwalbach', value: 'Bad Schwalbach' },
  { label: 'Bad Schwartau', value: 'Bad Schwartau' },
  { label: 'Bad Segeberg', value: 'Bad Segeberg' },
  { label: 'Bad Soden Am Taunus', value: 'Bad Soden Am Taunus' },
  { label: 'Bad Soden-Salmünster', value: 'Bad Soden-Salmünster' },
  { label: 'Bad Sooden-Allendorf', value: 'Bad Sooden-Allendorf' },
  { label: 'Bad Staffelstein', value: 'Bad Staffelstein' },
  { label: 'Bad Steben', value: 'Bad Steben' },
  { label: 'Bad Suderode', value: 'Bad Suderode' },
  { label: 'Bad Sulza', value: 'Bad Sulza' },
  { label: 'Bad Sülze', value: 'Bad Sülze' },
  { label: 'Bad Tatzmannsdorf', value: 'Bad Tatzmannsdorf' },
  { label: 'Bad Teinach-Zavelstein', value: 'Bad Teinach-Zavelstein' },
  { label: 'Bad Tennstedt', value: 'Bad Tennstedt' },
  { label: 'Bad Tölz', value: 'Bad Tölz' },
  { label: 'Bad Traunstein', value: 'Bad Traunstein' },
  { label: 'Bad Überkingen', value: 'Bad Überkingen' },
  { label: 'Bad Urach', value: 'Bad Urach' },
  { label: 'Bad Vilbel', value: 'Bad Vilbel' },
  { label: 'Bad Vöslau', value: 'Bad Vöslau' },
  { label: 'Bad Waldsee', value: 'Bad Waldsee' },
  { label: 'Bad Waltersdorf', value: 'Bad Waltersdorf' },
  { label: 'Bad Wiessee', value: 'Bad Wiessee' },
  { label: 'Bad Wildbad', value: 'Bad Wildbad' },
  { label: 'Bad Wildungen', value: 'Bad Wildungen' },
  { label: 'Bad Wilsnack', value: 'Bad Wilsnack' },
  { label: 'Bad Wimpfen', value: 'Bad Wimpfen' },
  { label: 'Bad Windsheim', value: 'Bad Windsheim' },
  { label: 'Bad Wörishofen', value: 'Bad Wörishofen' },
  { label: 'Bad Wurzach', value: 'Bad Wurzach' },
  { label: 'Bad Zell', value: 'Bad Zell' },
  { label: 'Bad Zurzach', value: 'Bad Zurzach' },
  { label: 'Bad Zwischenahn', value: 'Bad Zwischenahn' },
  { label: 'Bada', value: 'Bada' },
  { label: 'Bada Barabil', value: 'Bada Barabil' },
  { label: 'Badacsonytomaj', value: 'Badacsonytomaj' },
  { label: 'Badagry', value: 'Badagry' },
  { label: 'Badajoz', value: 'Badajoz' },
  { label: 'Badak', value: 'Badak' },
  { label: 'Badak', value: 'Badak' },
  { label: 'Badalasco', value: 'Badalasco' },
  { label: 'Badalona', value: 'Badalona' },
  { label: 'Badalucco', value: 'Badalucco' },
  { label: 'Badamdar', value: 'Badamdar' },
  { label: 'Badami', value: 'Badami' },
  { label: 'Badan', value: 'Badan' },
  { label: 'Badaogu', value: 'Badaogu' },
  { label: 'Badar', value: 'Badar' },
  { label: 'Badarán', value: 'Badarán' },
  { label: 'Badarpur', value: 'Badarpur' },
  { label: 'Badarwas', value: 'Badarwas' },
  { label: 'Badbergen', value: 'Badbergen' },
  { label: 'Baddeckenstedt', value: 'Baddeckenstedt' },
  { label: 'Baddi', value: 'Baddi' },
  { label: 'Baddomalhi', value: 'Baddomalhi' },
  { label: 'Bădeana', value: 'Bădeana' },
  { label: 'Badeggi', value: 'Badeggi' },
  { label: 'Badem', value: 'Badem' },
  { label: 'Baden', value: 'Baden' },
  { label: 'Baden', value: 'Baden' },
  { label: 'Baden', value: 'Baden' },
  { label: 'Baden', value: 'Baden' },
  { label: 'Baden', value: 'Baden' },
  { label: 'Bádenas', value: 'Bádenas' },
  { label: 'Baden-Baden', value: 'Baden-Baden' },
  { label: 'Badenhausen', value: 'Badenhausen' },
  { label: 'Bădeni', value: 'Bădeni' },
  { label: 'Bădeni', value: 'Bădeni' },
  { label: 'Badenweiler', value: 'Badenweiler' },
  { label: 'Badersdorf', value: 'Badersdorf' },
  { label: 'Badesi', value: 'Badesi' },
  { label: 'Bădești', value: 'Bădești' },
  { label: 'Bădeuți', value: 'Bădeuți' },
  { label: 'Badgam', value: 'Badgam' },
  { label: 'Badger', value: 'Badger' },
  { label: 'Badger Creek', value: 'Badger Creek' },
  { label: 'Badgingarra', value: 'Badgingarra' },
  { label: 'Badhni Kalan', value: 'Badhni Kalan' },
  { label: 'Badhoevedorp', value: 'Badhoevedorp' },
  { label: 'Badia', value: 'Badia' },
  { label: 'Badia Al Pino', value: 'Badia Al Pino' },
  { label: 'Badia Calavena', value: 'Badia Calavena' },
  { label: 'Badia Del Vallès', value: 'Badia Del Vallès' },
  { label: 'Badia Pavese', value: 'Badia Pavese' },
  { label: 'Badia Polesine', value: 'Badia Polesine' },
  { label: 'Badia Tedalda', value: 'Badia Tedalda' },
  { label: 'Badian', value: 'Badian' },
  { label: 'Badian', value: 'Badian' },
  { label: 'Badiangan', value: 'Badiangan' },
  { label: 'Badile', value: 'Badile' },
  { label: 'Badin', value: 'Badin' },
  { label: 'Badin', value: 'Badin' },
  { label: 'Badiraguato', value: 'Badiraguato' },
  { label: 'Badkulla', value: 'Badkulla' },
  { label: 'Badlan', value: 'Badlan' },
  { label: 'Badlapur', value: 'Badlapur' },
  { label: 'Badnapur', value: 'Badnapur' },
  { label: 'Badnawar', value: 'Badnawar' },
  { label: 'Badnera', value: 'Badnera' },
  { label: 'Badoc', value: 'Badoc' },
  { label: 'Badoc', value: 'Badoc' },
  { label: 'Badoere', value: 'Badoere' },
  { label: 'Badolato', value: 'Badolato' },
  { label: 'Badolato Marina', value: 'Badolato Marina' },
  { label: 'Badolatosa', value: 'Badolatosa' },
  { label: 'Badonviller', value: 'Badonviller' },
  { label: 'Bădoși', value: 'Bădoși' },
  { label: 'Badou', value: 'Badou' },
  { label: 'Badovinci', value: 'Badovinci' },
  { label: 'Badr', value: 'Badr' },
  { label: 'Badreh', value: 'Badreh' },
  { label: 'Badroud', value: 'Badroud' },
  { label: 'Badsey', value: 'Badsey' },
  { label: 'Badsworth', value: 'Badsworth' },
  { label: 'Badu', value: 'Badu' },
  { label: 'Băduleasa', value: 'Băduleasa' },
  { label: 'Baduleh', value: 'Baduleh' },
  { label: 'Badules', value: 'Badules' },
  { label: 'Badulla', value: 'Badulla' },
  { label: 'Badulla District', value: 'Badulla District' },
  { label: 'Baduria', value: 'Baduria' },
  { label: 'Badvel', value: 'Badvel' },
  { label: 'Bady Bassitt', value: 'Bady Bassitt' },
  { label: 'Baekrajan', value: 'Baekrajan' },
  { label: 'Baelen', value: 'Baelen' },
  { label: 'Baells', value: 'Baells' },
  { label: 'Baena', value: 'Baena' },
  { label: 'Baependi', value: 'Baependi' },
  { label: 'Bærum', value: 'Bærum' },
  { label: 'Baesweiler', value: 'Baesweiler' },
  { label: 'Baeza', value: 'Baeza' },
  { label: 'Bafang', value: 'Bafang' },
  { label: 'Bafatá', value: 'Bafatá' },
  { label: 'Baffa', value: 'Baffa' },
  { label: 'Bafgh', value: 'Bafgh' },
  { label: 'Bafia', value: 'Bafia' },
  { label: 'Bafilo', value: 'Bafilo' },
  { label: 'Bafing', value: 'Bafing' },
  { label: 'Baflo', value: 'Baflo' },
  { label: 'Bafoulabé', value: 'Bafoulabé' },
  { label: 'Bafoussam', value: 'Bafoussam' },
  { label: 'Bafq', value: 'Bafq' },
  { label: 'Bafra', value: 'Bafra' },
  { label: 'Bafrān', value: 'Bafrān' },
  { label: 'Bafruiyeh', value: 'Bafruiyeh' },
  { label: 'Baft', value: 'Baft' },
  { label: 'Bag', value: 'Bag' },
  { label: 'Baga', value: 'Baga' },
  { label: 'Bagà', value: 'Bagà' },
  { label: 'Bagabag', value: 'Bagabag' },
  { label: 'Bagac', value: 'Bagac' },
  { label: 'Bagac', value: 'Bagac' },
  { label: 'Bagacay', value: 'Bagacay' },
  { label: 'Bagacay', value: 'Bagacay' },
  { label: 'Bagaces', value: 'Bagaces' },
  { label: 'Băgaciu', value: 'Băgaciu' },
  { label: 'Bagadó', value: 'Bagadó' },
  { label: 'Bagaha', value: 'Bagaha' },
  { label: 'Bagahanlad', value: 'Bagahanlad' },
  { label: 'Bagakay', value: 'Bagakay' },
  { label: 'Bágala', value: 'Bágala' },
  { label: 'Bagaladi', value: 'Bagaladi' },
  { label: 'Bagalangit', value: 'Bagalangit' },
  { label: 'Bagalkot', value: 'Bagalkot' },
  { label: 'Bagamanoc', value: 'Bagamanoc' },
  { label: 'Bagamér', value: 'Bagamér' },
  { label: 'Bagamoyo', value: 'Bagamoyo' },
  { label: 'Bagan', value: 'Bagan' },
  { label: 'Bagan', value: 'Bagan' },
  { label: 'Bagan', value: 'Bagan' },
  { label: 'Bagan Pulau Ketam', value: 'Bagan Pulau Ketam' },
  { label: 'Bagan Serai', value: 'Bagan Serai' },
  { label: 'Baganga', value: 'Baganga' },
  { label: 'Baganga', value: 'Baganga' },
  { label: 'Baganga', value: 'Baganga' },
  { label: 'Bagani', value: 'Bagani' },
  { label: 'Baganzola', value: 'Baganzola' },
  { label: 'Bagar', value: 'Bagar' },
  { label: 'Bagard', value: 'Bagard' },
  { label: 'Bagarji', value: 'Bagarji' },
  { label: 'Bagasara', value: 'Bagasara' },
  { label: 'Bagasra', value: 'Bagasra' },
  { label: 'Bagatelle', value: 'Bagatelle' },
  { label: 'Bagay', value: 'Bagay' },
  { label: 'Bagayevskaya', value: 'Bagayevskaya' },
  { label: 'Bağcılar', value: 'Bağcılar' },
  { label: 'Bagdad', value: 'Bagdad' },
  { label: 'Bagdad', value: 'Bagdad' },
  { label: 'Bagdad', value: 'Bagdad' },
  { label: 'Bagdarin', value: 'Bagdarin' },
  { label: 'Bagdogra', value: 'Bagdogra' },
  { label: 'Bagé', value: 'Bagé' },
  { label: 'Bâgé-La-Ville', value: 'Bâgé-La-Ville' },
  { label: 'Bagenalstown', value: 'Bagenalstown' },
  { label: 'Bagepalli', value: 'Bagepalli' },
  { label: 'Bagerhat ', value: 'Bagerhat ' },
  { label: 'Bages', value: 'Bages' },
  { label: 'Bageshwar', value: 'Bageshwar' },
  { label: 'Bagewadi', value: 'Bagewadi' },
  { label: 'Baggabag B', value: 'Baggabag B' },
  { label: 'Baggabag B', value: 'Baggabag B' },
  { label: 'Baggao', value: 'Baggao' },
  { label: 'Baggiovara', value: 'Baggiovara' },
  { label: 'Bagh', value: 'Bagh' },
  { label: 'Bagh Malek', value: 'Bagh Malek' },
  { label: 'Bagha Purana', value: 'Bagha Purana' },
  { label: 'Baghbahadoran', value: 'Baghbahadoran' },
  { label: 'Baghdad', value: 'Baghdad' },
  { label: 'Baghdad Al Jadeeda', value: 'Baghdad Al Jadeeda' },
  { label: 'Baghdatis Munitsip’Alit’Et’I', value: 'Baghdatis Munitsip’Alit’Et’I' },
  { label: 'Bagheria', value: 'Bagheria' },
  { label: 'Baghershahr', value: 'Baghershahr' },
  { label: 'Baghestan', value: 'Baghestan' },
  { label: 'Bagheyn', value: 'Bagheyn' },
  { label: 'Baghlān', value: 'Baghlān' },
  { label: 'Baghmalek', value: 'Baghmalek' },
  { label: 'Baghpat', value: 'Baghpat' },
  { label: 'Baghra', value: 'Baghra' },
  { label: 'Baghramyan', value: 'Baghramyan' },
  { label: 'Baghshad', value: 'Baghshad' },
  { label: 'Bagillt', value: 'Bagillt' },
  { label: 'Bağlar', value: 'Bağlar' },
  { label: 'Bagley', value: 'Bagley' },
  { label: 'Bagli', value: 'Bagli' },
  { label: 'Baglung ', value: 'Baglung ' },
  { label: 'Bagn', value: 'Bagn' },
  { label: 'Bagnacavallo', value: 'Bagnacavallo' },
  { label: 'Bagnac-Sur-Célé', value: 'Bagnac-Sur-Célé' },
  { label: 'Bagnan', value: 'Bagnan' },
  { label: 'Bagnara Calabra', value: 'Bagnara Calabra' },
  { label: 'Bagnara Di Romagna', value: 'Bagnara Di Romagna' },
  { label: 'Bagnaria', value: 'Bagnaria' },
  { label: 'Bagnaria Arsa', value: 'Bagnaria Arsa' },
  { label: 'Bagnarola', value: 'Bagnarola' },
  { label: 'Bagnarola', value: 'Bagnarola' },
  { label: 'Bagnasco', value: 'Bagnasco' },
  { label: 'Bagnatica', value: 'Bagnatica' },
  { label: 'Bagneaux-Sur-Loing', value: 'Bagneaux-Sur-Loing' },
  { label: 'Bagnères-De-Bigorre', value: 'Bagnères-De-Bigorre' },
  { label: 'Bagnères-De-Luchon', value: 'Bagnères-De-Luchon' },
  { label: 'Bagnes', value: 'Bagnes' },
  { label: 'Bagneux', value: 'Bagneux' },
  { label: 'Bagni Di Lucca', value: 'Bagni Di Lucca' },
  { label: 'Bagni Di Tivoli', value: 'Bagni Di Tivoli' },
  { label: 'Bagno', value: 'Bagno' },
  { label: 'Bagno A Ripoli', value: 'Bagno A Ripoli' },
  { label: 'Bagno Roselle', value: 'Bagno Roselle' },
  { label: 'Bagnoles-De-Lorne', value: 'Bagnoles-De-Lorne' },
  { label: 'Bagnolet', value: 'Bagnolet' },
  { label: 'Bagnoli', value: 'Bagnoli' },
  { label: 'Bagnoli Del Trigno', value: 'Bagnoli Del Trigno' },
  { label: 'Bagnoli Di Sopra', value: 'Bagnoli Di Sopra' },
  { label: 'Bagnoli Irpino', value: 'Bagnoli Irpino' },
  { label: 'Bagnolo', value: 'Bagnolo' },
  { label: 'Bagnolo Cremasco', value: 'Bagnolo Cremasco' },
  { label: 'Bagnolo Del Salento', value: 'Bagnolo Del Salento' },
  { label: 'Bagnolo Di Po', value: 'Bagnolo Di Po' },
  { label: 'Bagnolo In Piano', value: 'Bagnolo In Piano' },
  { label: 'Bagnolo Mella', value: 'Bagnolo Mella' },
  { label: 'Bagnolo Piemonte', value: 'Bagnolo Piemonte' },
  { label: 'Bagnolo San Vito', value: 'Bagnolo San Vito' },
  { label: 'Bagnols-En-Forêt', value: 'Bagnols-En-Forêt' },
  { label: 'Bagnols-Sur-Cèze', value: 'Bagnols-Sur-Cèze' },
  { label: 'Bagnone', value: 'Bagnone' },
  { label: 'Bagnoregio', value: 'Bagnoregio' },
  { label: 'Bago', value: 'Bago' },
  { label: 'Bago City', value: 'Bago City' },
  { label: 'Bagojo Colectivo', value: 'Bagojo Colectivo' },
  { label: 'Bagolino', value: 'Bagolino' },
  { label: 'Bagombong', value: 'Bagombong' },
  { label: 'Bagong Barrio', value: 'Bagong Barrio' },
  { label: 'Bagong Barrio', value: 'Bagong Barrio' },
  { label: 'Bagong Pagasa', value: 'Bagong Pagasa' },
  { label: 'Bagong Sikat', value: 'Bagong Sikat' },
  { label: 'Bagong Tanza', value: 'Bagong Tanza' },
  { label: 'Bagong Tanza', value: 'Bagong Tanza' },
  { label: 'Bagong-Sikat', value: 'Bagong-Sikat' },
  { label: 'Bagong-Sikat', value: 'Bagong-Sikat' },
  { label: 'Bagontapay', value: 'Bagontapay' },
  { label: 'Bagontapay', value: 'Bagontapay' },
  { label: 'Bagra', value: 'Bagra' },
  { label: 'Bagramovo', value: 'Bagramovo' },
  { label: 'Bagratashen', value: 'Bagratashen' },
  { label: 'Bagrationovsk', value: 'Bagrationovsk' },
  { label: 'Bagre', value: 'Bagre' },
  { label: 'Bagroy', value: 'Bagroy' },
  { label: 'Bagshot', value: 'Bagshot' },
  { label: 'Bagtic', value: 'Bagtic' },
  { label: 'Bagu', value: 'Bagu' },
  { label: 'Bagu', value: 'Bagu' },
  { label: 'Bagua Grande', value: 'Bagua Grande' },
  { label: 'Bagudo', value: 'Bagudo' },
  { label: 'Báguena', value: 'Báguena' },
  { label: 'Baguer', value: 'Baguer' },
  { label: 'Baguer', value: 'Baguer' },
  { label: 'Baguer-Morvan', value: 'Baguer-Morvan' },
  { label: 'Baguer-Pican', value: 'Baguer-Pican' },
  { label: 'Bagüés', value: 'Bagüés' },
  { label: 'Baguia', value: 'Baguia' },
  { label: 'Baguim Do Monte', value: 'Baguim Do Monte' },
  { label: 'Baguinge', value: 'Baguinge' },
  { label: 'Baguinge', value: 'Baguinge' },
  { label: 'Baguio', value: 'Baguio' },
  { label: 'Baguio', value: 'Baguio' },
  { label: 'Baguirmi Department', value: 'Baguirmi Department' },
  { label: 'Bagula', value: 'Bagula' },
  { label: 'Bagulin', value: 'Bagulin' },
  { label: 'Bagulin', value: 'Bagulin' },
  { label: 'Bagumbayan', value: 'Bagumbayan' },
  { label: 'Bagumbayan', value: 'Bagumbayan' },
  { label: 'Bagumbayan', value: 'Bagumbayan' },
  { label: 'Bagumbayan', value: 'Bagumbayan' },
  { label: 'Bagumbayan', value: 'Bagumbayan' },
  { label: 'Bagupaye', value: 'Bagupaye' },
  { label: 'Bagworth', value: 'Bagworth' },
  { label: 'Bah', value: 'Bah' },
  { label: 'Bahabad', value: 'Bahabad' },
  { label: 'Bahabón', value: 'Bahabón' },
  { label: 'Bahabón De Esgueva', value: 'Bahabón De Esgueva' },
  { label: 'Bahadurganj', value: 'Bahadurganj' },
  { label: 'Bahadurgarh', value: 'Bahadurgarh' },
  { label: 'Bahani', value: 'Bahani' },
  { label: 'Bahar', value: 'Bahar' },
  { label: 'Baħar Iċ-Ċagħaq', value: 'Baħar Iċ-Ċagħaq' },
  { label: 'Baharan Shahr', value: 'Baharan Shahr' },
  { label: 'Baharestan', value: 'Baharestan' },
  { label: 'Baharestan', value: 'Baharestan' },
  { label: 'Baharly', value: 'Baharly' },
  { label: 'Bahau', value: 'Bahau' },
  { label: 'Bahawalnagar', value: 'Bahawalnagar' },
  { label: 'Bahawalpur', value: 'Bahawalpur' },
  { label: 'Bahay', value: 'Bahay' },
  { label: 'Bahay Pare', value: 'Bahay Pare' },
  { label: 'Bahay Pare', value: 'Bahay Pare' },
  { label: 'Bah-Bah', value: 'Bah-Bah' },
  { label: 'Bah-Bah', value: 'Bah-Bah' },
  { label: 'Bahçe', value: 'Bahçe' },
  { label: 'Bahçelievler', value: 'Bahçelievler' },
  { label: 'Bahçesaray', value: 'Bahçesaray' },
  { label: 'Baheli', value: 'Baheli' },
  { label: 'Baheri', value: 'Baheri' },
  { label: 'Baherove', value: 'Baherove' },
  { label: 'Bahía Asunción', value: 'Bahía Asunción' },
  { label: 'Bahía Azul', value: 'Bahía Azul' },
  { label: 'Bahía De Caráquez', value: 'Bahía De Caráquez' },
  { label: 'Bahía De Kino', value: 'Bahía De Kino' },
  { label: 'Bahía De Lobos', value: 'Bahía De Lobos' },
  { label: 'Bahía Honda', value: 'Bahía Honda' },
  { label: 'Bahía Honda', value: 'Bahía Honda' },
  { label: 'Bahía Solano', value: 'Bahía Solano' },
  { label: 'Bahía Tortugas', value: 'Bahía Tortugas' },
  { label: 'Bahir Dar', value: 'Bahir Dar' },
  { label: 'Bahjoi', value: 'Bahjoi' },
  { label: 'Bahlā’', value: 'Bahlā’' },
  { label: 'Bahlingen', value: 'Bahlingen' },
  { label: 'Bahmai', value: 'Bahmai' },
  { label: 'Bahman', value: 'Bahman' },
  { label: 'Bahna', value: 'Bahna' },
  { label: 'Bahnamir', value: 'Bahnamir' },
  { label: 'Bahnea', value: 'Bahnea' },
  { label: 'Baho', value: 'Baho' },
  { label: 'Bahon', value: 'Bahon' },
  { label: 'Bahraich', value: 'Bahraich' },
  { label: 'Bahraigh', value: 'Bahraigh' },
  { label: 'Bahrat Al Qadimah', value: 'Bahrat Al Qadimah' },
  { label: 'Bahrdorf', value: 'Bahrdorf' },
  { label: 'Bahreman', value: 'Bahreman' },
  { label: 'Bahrenborstel', value: 'Bahrenborstel' },
  { label: 'Baħrija', value: 'Baħrija' },
  { label: 'Bahrs Scrub', value: 'Bahrs Scrub' },
  { label: 'Bahşılı', value: 'Bahşılı' },
  { label: 'Bahsuma', value: 'Bahsuma' },
  { label: 'Bahua', value: 'Bahua' },
  { label: 'Bahuang', value: 'Bahuang' },
  { label: 'Bahuichivo', value: 'Bahuichivo' },
  { label: 'Bahula', value: 'Bahula' },
  { label: 'Bahwil', value: 'Bahwil' },
  { label: 'Baia', value: 'Baia' },
  { label: 'Baia', value: 'Baia' },
  { label: 'Baia', value: 'Baia' },
  { label: 'Baía Da Traição', value: 'Baía Da Traição' },
  { label: 'Baia De Aramă', value: 'Baia De Aramă' },
  { label: 'Baia De Arieş', value: 'Baia De Arieş' },
  { label: 'Baia De Criş', value: 'Baia De Criş' },
  { label: 'Baia De Fier', value: 'Baia De Fier' },
  { label: 'Baía Farta', value: 'Baía Farta' },
  { label: 'Baía Formosa', value: 'Baía Formosa' },
  { label: 'Baia Mare', value: 'Baia Mare' },
  { label: 'Baia Sprie', value: 'Baia Sprie' },
  { label: 'Baiano', value: 'Baiano' },
  { label: 'Baianópolis', value: 'Baianópolis' },
  { label: 'Baião', value: 'Baião' },
  { label: 'Baião', value: 'Baião' },
  { label: 'Baicheng', value: 'Baicheng' },
  { label: 'Băicoi', value: 'Băicoi' },
  { label: 'Băiculeşti', value: 'Băiculeşti' },
  { label: 'Baides', value: 'Baides' },
  { label: 'Baidland', value: 'Baidland' },
  { label: 'Baidoa', value: 'Baidoa' },
  { label: 'Baidyabati', value: 'Baidyabati' },
  { label: 'Baie Aux Huîtres', value: 'Baie Aux Huîtres' },
  { label: 'Baie De Henne', value: 'Baie De Henne' },
  { label: 'Baie Du Cap', value: 'Baie Du Cap' },
  { label: 'Baie Ste. Anne', value: 'Baie Ste. Anne' },
  { label: 'Baie-Comeau', value: 'Baie-Comeau' },
  { label: 'Baie-Durfé', value: 'Baie-Durfé' },
  { label: 'Baienfurt', value: 'Baienfurt' },
  { label: 'Baierbrunn', value: 'Baierbrunn' },
  { label: 'Baierdorf-Umgebung', value: 'Baierdorf-Umgebung' },
  { label: 'Baiersbronn', value: 'Baiersbronn' },
  { label: 'Baiersdorf', value: 'Baiersdorf' },
  { label: 'Baie-Saint-Paul', value: 'Baie-Saint-Paul' },
  { label: 'Baignes-Sainte-Radegonde', value: 'Baignes-Sainte-Radegonde' },
  { label: 'Baihar', value: 'Baihar' },
  { label: 'Baihe', value: 'Baihe' },
  { label: 'Baiima', value: 'Baiima' },
  { label: 'Baijai', value: 'Baijai' },
  { label: 'Baijian', value: 'Baijian' },
  { label: 'Baijiantan', value: 'Baijiantan' },
  { label: 'Baikingon', value: 'Baikingon' },
  { label: 'Baikonur', value: 'Baikonur' },
  { label: 'Baikunthpur', value: 'Baikunthpur' },
  { label: 'Baikunthpur', value: 'Baikunthpur' },
  { label: 'Bail', value: 'Bail' },
  { label: 'Bail', value: 'Bail' },
  { label: 'Bailan', value: 'Bailan' },
  { label: 'Baildon', value: 'Baildon' },
  { label: 'Băile Borșa', value: 'Băile Borșa' },
  { label: 'Băile Drânceni', value: 'Băile Drânceni' },
  { label: 'Băile Govora', value: 'Băile Govora' },
  { label: 'Băile Herculane', value: 'Băile Herculane' },
  { label: 'Băile Olăneşti', value: 'Băile Olăneşti' },
  { label: 'Băile Tuşnad', value: 'Băile Tuşnad' },
  { label: 'Bailén', value: 'Bailén' },
  { label: 'Băileşti', value: 'Băileşti' },
  { label: 'Bailey County', value: 'Bailey County' },
  { label: 'Baileys Crossroads', value: 'Baileys Crossroads' },
  { label: 'Baileys Vale', value: 'Baileys Vale' },
  { label: 'Bail-Hongal', value: 'Bail-Hongal' },
  { label: 'Bailieborough', value: 'Bailieborough' },
  { label: 'Baillargues', value: 'Baillargues' },
  { label: 'Baille', value: 'Baille' },
  { label: 'Bailleau-L’Évêque', value: 'Bailleau-L’Évêque' },
  { label: 'Bailleau-Le-Pin', value: 'Bailleau-Le-Pin' },
  { label: 'Baillet-En-France', value: 'Baillet-En-France' },
  { label: 'Bailleul', value: 'Bailleul' },
  { label: 'Bailleul-Sir-Berthoult', value: 'Bailleul-Sir-Berthoult' },
  { label: 'Bailleul-Sur-Thérain', value: 'Bailleul-Sur-Thérain' },
  { label: 'Bailleval', value: 'Bailleval' },
  { label: 'Bailly', value: 'Bailly' },
  { label: 'Bailly-Carrois', value: 'Bailly-Carrois' },
  { label: 'Bailly-Romainvilliers', value: 'Bailly-Romainvilliers' },
  { label: 'Bailo', value: 'Bailo' },
  { label: 'Bainbridge', value: 'Bainbridge' },
  { label: 'Bainbridge', value: 'Bainbridge' },
  { label: 'Bainbridge', value: 'Bainbridge' },
  { label: 'Bainbridge', value: 'Bainbridge' },
  { label: 'Bainbridge Island', value: 'Bainbridge Island' },
  { label: 'Baincthun', value: 'Baincthun' },
  { label: 'Bain-De-Bretagne', value: 'Bain-De-Bretagne' },
  { label: 'Baindt', value: 'Baindt' },
  { label: 'Bains', value: 'Bains' },
  { label: 'Bains-Les-Bains', value: 'Bains-Les-Bains' },
  { label: 'Bains-Sur-Oust', value: 'Bains-Sur-Oust' },
  { label: 'Bainville-Sur-Madon', value: 'Bainville-Sur-Madon' },
  { label: 'Baiona', value: 'Baiona' },
  { label: 'Baiqi', value: 'Baiqi' },
  { label: 'Baiquan', value: 'Baiquan' },
  { label: 'Bairagnia', value: 'Bairagnia' },
  { label: 'Bairak', value: 'Bairak' },
  { label: 'Bairan', value: 'Bairan' },
  { label: 'Baird', value: 'Baird' },
  { label: 'Bairiki Village', value: 'Bairiki Village' },
  { label: 'Bairisch Kölldorf', value: 'Bairisch Kölldorf' },
  { label: 'Bairnsdale', value: 'Bairnsdale' },
  { label: 'Bairo', value: 'Bairo' },
  { label: 'Bairoa', value: 'Bairoa' },
  { label: 'Bais', value: 'Bais' },
  { label: 'Bais', value: 'Bais' },
  { label: 'Bais', value: 'Bais' },
  { label: 'Baise City', value: 'Baise City' },
  { label: 'Baise Shi', value: 'Baise Shi' },
  { label: 'Băișești', value: 'Băișești' },
  { label: 'Baisha', value: 'Baisha' },
  { label: 'Baishan', value: 'Baishan' },
  { label: 'Baishi', value: 'Baishi' },
  { label: 'Baishishan', value: 'Baishishan' },
  { label: 'Baisi', value: 'Baisi' },
  { label: 'Baisieux', value: 'Baisieux' },
  { label: 'Baiso', value: 'Baiso' },
  { label: 'Băişoara', value: 'Băişoara' },
  { label: 'Baissa', value: 'Baissa' },
  { label: 'Baisweil', value: 'Baisweil' },
  { label: 'Băiţa', value: 'Băiţa' },
  { label: 'Băița', value: 'Băița' },
  { label: 'Băița', value: 'Băița' },
  { label: 'Băiţa De Sub Codru', value: 'Băiţa De Sub Codru' },
  { label: 'Baitadi ', value: 'Baitadi ' },
  { label: 'Baiti', value: 'Baiti' },
  { label: 'Baiting Hollow', value: 'Baiting Hollow' },
  { label: 'Baitoa', value: 'Baitoa' },
  { label: 'Băiuţ', value: 'Băiuţ' },
  { label: 'Baix Pallars', value: 'Baix Pallars' },
  { label: 'Baixa Grande', value: 'Baixa Grande' },
  { label: 'Baixa Grande Do Ribeiro', value: 'Baixa Grande Do Ribeiro' },
  { label: 'Baixas', value: 'Baixas' },
  { label: 'Baixio', value: 'Baixio' },
  { label: 'Baixo Guandu', value: 'Baixo Guandu' },
  { label: 'Baiyer Mul', value: 'Baiyer Mul' },
  { label: 'Baiyin', value: 'Baiyin' },
  { label: 'Baiyun', value: 'Baiyun' },
  { label: 'Baja', value: 'Baja' },
  { label: 'Baja Mar', value: 'Baja Mar' },
  { label: 'Bajadero', value: 'Bajadero' },
  { label: 'Bajai Járás', value: 'Bajai Járás' },
  { label: 'Bajandas', value: 'Bajandas' },
  { label: 'Bajardo', value: 'Bajardo' },
  { label: 'Bajestan', value: 'Bajestan' },
  { label: 'Băjești', value: 'Băjești' },
  { label: 'Bajgiran', value: 'Bajgiran' },
  { label: 'Bajhang ', value: 'Bajhang ' },
  { label: 'Bājil', value: 'Bājil' },
  { label: 'Bajina Bašta', value: 'Bajina Bašta' },
  { label: 'Bajío De Bonillas', value: 'Bajío De Bonillas' },
  { label: 'Bajío De Ratones', value: 'Bajío De Ratones' },
  { label: 'Bajío De San José', value: 'Bajío De San José' },
  { label: 'Bajío De San Nicolás', value: 'Bajío De San Nicolás' },
  { label: 'Bajío Seco', value: 'Bajío Seco' },
  { label: 'Bajna', value: 'Bajna' },
  { label: 'Bajo Baudó', value: 'Bajo Baudó' },
  { label: 'Bajo Boquete', value: 'Bajo Boquete' },
  { label: 'Bajo Corral', value: 'Bajo Corral' },
  { label: 'Bajos De Chila', value: 'Bajos De Chila' },
  { label: 'Bajos De Haina', value: 'Bajos De Haina' },
  { label: 'Bajos Del Ejido', value: 'Bajos Del Ejido' },
  { label: 'Bajót', value: 'Bajót' },
  { label: 'Bajram Curri', value: 'Bajram Curri' },
  { label: 'Bajucu', value: 'Bajucu' },
  { label: 'Bajura', value: 'Bajura' },
  { label: 'Bajura ', value: 'Bajura ' },
  { label: 'Baka', value: 'Baka' },
  { label: 'Baka', value: 'Baka' },
  { label: 'Bakadagy', value: 'Bakadagy' },
  { label: 'Bakaiku', value: 'Bakaiku' },
  { label: 'Bakal', value: 'Bakal' },
  { label: 'Bakalinskiy Rayon', value: 'Bakalinskiy Rayon' },
  { label: 'Bakaly', value: 'Bakaly' },
  { label: 'Bakan', value: 'Bakan' },
  { label: 'Bakanas', value: 'Bakanas' },
  { label: 'Bakani', value: 'Bakani' },
  { label: 'Bakar', value: 'Bakar' },
  { label: 'Bakau', value: 'Bakau' },
  { label: 'Bakchar', value: 'Bakchar' },
  { label: 'Bakel', value: 'Bakel' },
  { label: 'Bakenberg', value: 'Bakenberg' },
  { label: 'Baker', value: 'Baker' },
  { label: 'Baker', value: 'Baker' },
  { label: 'Baker City', value: 'Baker City' },
  { label: 'Baker County', value: 'Baker County' },
  { label: 'Baker County', value: 'Baker County' },
  { label: 'Baker County', value: 'Baker County' },
  { label: 'Bakers Creek', value: 'Bakers Creek' },
  { label: 'Bakers Hill', value: 'Bakers Hill' },
  { label: 'Bakersfield', value: 'Bakersfield' },
  { label: 'Bakerstown', value: 'Bakerstown' },
  { label: 'Bakersville', value: 'Bakersville' },
  { label: 'Bakewar', value: 'Bakewar' },
  { label: 'Bakewell', value: 'Bakewell' },
  { label: 'Bakewell', value: 'Bakewell' },
  { label: 'Bakharla', value: 'Bakharla' },
  { label: 'Bakharz', value: 'Bakharz' },
  { label: 'Bakhchivandzhi', value: 'Bakhchivandzhi' },
  { label: 'Bakhchysarai', value: 'Bakhchysarai' },
  { label: 'Bakhchysarai Raion', value: 'Bakhchysarai Raion' },
  { label: 'Bakhilovo', value: 'Bakhilovo' },
  { label: 'Bakhmach', value: 'Bakhmach' },
  { label: 'Bakhmut', value: 'Bakhmut' },
  { label: 'Bakhmut Raion', value: 'Bakhmut Raion' },
  { label: 'Bakhri Ahmad Khan', value: 'Bakhri Ahmad Khan' },
  { label: 'Bakhshat Yamani', value: 'Bakhshat Yamani' },
  { label: 'Bakhshāyesh', value: 'Bakhshāyesh' },
  { label: 'Bakhtiyarpur', value: 'Bakhtiyarpur' },
  { label: 'Bakhuizen', value: 'Bakhuizen' },
  { label: 'Baki', value: 'Baki' },
  { label: 'Bakil Al Mir', value: 'Bakil Al Mir' },
  { label: 'Bakio', value: 'Bakio' },
  { label: 'Bakırköy', value: 'Bakırköy' },
  { label: 'Bakıxanov', value: 'Bakıxanov' },
  { label: 'Bakkeveen', value: 'Bakkeveen' },
  { label: 'Bakkum', value: 'Bakkum' },
  { label: 'Baklan', value: 'Baklan' },
  { label: 'Baklashi', value: 'Baklashi' },
  { label: 'Bakloh', value: 'Bakloh' },
  { label: 'Bako', value: 'Bako' },
  { label: 'Bakonybánk', value: 'Bakonybánk' },
  { label: 'Bakonycsernye', value: 'Bakonycsernye' },
  { label: 'Bakonysárkány', value: 'Bakonysárkány' },
  { label: 'Bakonyszentlászló', value: 'Bakonyszentlászló' },
  { label: 'Bakonyszombathely', value: 'Bakonyszombathely' },
  { label: 'Bakov Nad Jizerou', value: 'Bakov Nad Jizerou' },
  { label: 'Bakovci', value: 'Bakovci' },
  { label: 'Bąków', value: 'Bąków' },
  { label: 'Bakreswar', value: 'Bakreswar' },
  { label: 'Bakri', value: 'Bakri' },
  { label: 'Baks', value: 'Baks' },
  { label: 'Baksa', value: 'Baksa' },
  { label: 'Baksan', value: 'Baksan' },
  { label: 'Baksanenok', value: 'Baksanenok' },
  { label: 'Baksanskiy Rayon', value: 'Baksanskiy Rayon' },
  { label: 'Baksar', value: 'Baksar' },
  { label: 'Baksheyevo', value: 'Baksheyevo' },
  { label: 'Bakshwaha', value: 'Bakshwaha' },
  { label: 'Baktalórántháza', value: 'Baktalórántháza' },
  { label: 'Baktalórántházai Járás', value: 'Baktalórántházai Járás' },
  { label: 'Baku', value: 'Baku' },
  { label: 'Bakulong', value: 'Bakulong' },
  { label: 'Bakulong', value: 'Bakulong' },
  { label: 'Bakum', value: 'Bakum' },
  { label: 'Bakun', value: 'Bakun' },
  { label: 'Bakun', value: 'Bakun' },
  { label: 'Bakung', value: 'Bakung' },
  { label: 'Bakung', value: 'Bakung' },
  { label: 'Bakuriani', value: 'Bakuriani' },
  { label: 'Bal Harbour', value: 'Bal Harbour' },
  { label: 'Bal’Shavik', value: 'Bal’Shavik' },
  { label: 'Bala', value: 'Bala' },
  { label: 'Bala', value: 'Bala' },
  { label: 'Bala', value: 'Bala' },
  { label: 'Băla', value: 'Băla' },
  { label: 'Balabac', value: 'Balabac' },
  { label: 'Balabag', value: 'Balabag' },
  { label: 'Balabagan', value: 'Balabagan' },
  { label: 'Balabagan', value: 'Balabagan' },
  { label: 'Bălăbănești', value: 'Bălăbănești' },
  { label: 'Balabanovo', value: 'Balabanovo' },
  { label: 'Bălăceana', value: 'Bălăceana' },
  { label: 'Bălăceanca', value: 'Bălăceanca' },
  { label: 'Bălăceanu', value: 'Bălăceanu' },
  { label: 'Balachor', value: 'Balachor' },
  { label: 'Balaci', value: 'Balaci' },
  { label: 'Bălăciţa', value: 'Bălăciţa' },
  { label: 'Balaciu', value: 'Balaciu' },
  { label: 'Balaclava', value: 'Balaclava' },
  { label: 'Balaclava', value: 'Balaclava' },
  { label: 'Bala-Cynwyd', value: 'Bala-Cynwyd' },
  { label: 'Balad', value: 'Balad' },
  { label: 'Baladeh', value: 'Baladeh' },
  { label: 'Baladeh ', value: 'Baladeh ' },
  { label: 'Balading', value: 'Balading' },
  { label: 'Baladrūz', value: 'Baladrūz' },
  { label: 'Balagam', value: 'Balagam' },
  { label: 'Balagansk', value: 'Balagansk' },
  { label: 'Balaganskiy Rayon', value: 'Balaganskiy Rayon' },
  { label: 'Balaghat', value: 'Balaghat' },
  { label: 'Balagny-Sur-Thérain', value: 'Balagny-Sur-Thérain' },
  { label: 'Balagon', value: 'Balagon' },
  { label: 'Balagtas', value: 'Balagtas' },
  { label: 'Balagtas', value: 'Balagtas' },
  { label: 'Balagtas', value: 'Balagtas' },
  { label: 'Balagtas', value: 'Balagtas' },
  { label: 'Balagtasin', value: 'Balagtasin' },
  { label: 'Balaguer', value: 'Balaguer' },
  { label: 'Balagui', value: 'Balagui' },
  { label: 'Balagui', value: 'Balagui' },
  { label: 'Balagunan', value: 'Balagunan' },
  { label: 'Balagunan', value: 'Balagunan' },
  { label: 'Balagunan', value: 'Balagunan' },
  { label: 'Balahovit', value: 'Balahovit' },
  { label: 'Balaipungut', value: 'Balaipungut' },
  { label: 'Balaka', value: 'Balaka' },
  { label: 'Balaka District', value: 'Balaka District' },
  { label: 'Balakhani', value: 'Balakhani' },
  { label: 'Balakhninskiy', value: 'Balakhninskiy' },
  { label: 'Balakhonovskoye', value: 'Balakhonovskoye' },
  { label: 'Balakhta', value: 'Balakhta' },
  { label: 'Balakhtinskiy Rayon', value: 'Balakhtinskiy Rayon' },
  { label: 'Balakirevo', value: 'Balakirevo' },
  { label: 'Balaklava', value: 'Balaklava' },
  { label: 'Balaklava', value: 'Balaklava' },
  { label: 'Balaklava District', value: 'Balaklava District' },
  { label: 'Balakliya', value: 'Balakliya' },
  { label: 'Balakovo', value: 'Balakovo' },
  { label: 'Balally', value: 'Balally' },
  { label: 'Balamau', value: 'Balamau' },
  { label: 'Balamban', value: 'Balamban' },
  { label: 'Balan', value: 'Balan' },
  { label: 'Balan', value: 'Balan' },
  { label: 'Bălan', value: 'Bălan' },
  { label: 'Bălan', value: 'Bălan' },
  { label: 'Balanacan', value: 'Balanacan' },
  { label: 'Balancán', value: 'Balancán' },
  { label: 'Bălănești', value: 'Bălănești' },
  { label: 'Balanga', value: 'Balanga' },
  { label: 'Balanga', value: 'Balanga' },
  { label: 'Balangbalang', value: 'Balangbalang' },
  { label: 'Balangbalang', value: 'Balangbalang' },
  { label: 'Balangero', value: 'Balangero' },
  { label: 'Balangiga', value: 'Balangiga' },
  { label: 'Balangiga', value: 'Balangiga' },
  { label: 'Balangir', value: 'Balangir' },
  { label: 'Balangkayan', value: 'Balangkayan' },
  { label: 'Balangkayan', value: 'Balangkayan' },
  { label: 'Balangonan', value: 'Balangonan' },
  { label: 'Balangonan', value: 'Balangonan' },
  { label: 'Balangonan', value: 'Balangonan' },
  { label: 'Baláo', value: 'Baláo' },
  { label: 'Balaoan', value: 'Balaoan' },
  { label: 'Balaoan', value: 'Balaoan' },
  { label: 'Balaoang', value: 'Balaoang' },
  { label: 'Balaoang', value: 'Balaoang' },
  { label: 'Balaogan', value: 'Balaogan' },
  { label: 'Balapulang', value: 'Balapulang' },
  { label: 'Balapur', value: 'Balapur' },
  { label: 'Balapur', value: 'Balapur' },
  { label: 'Balapur Akola District', value: 'Balapur Akola District' },
  { label: 'Balarampur', value: 'Balarampur' },
  { label: 'Balaruc-Les-Bains', value: 'Balaruc-Les-Bains' },
  { label: 'Balaruc-Le-Vieux', value: 'Balaruc-Le-Vieux' },
  { label: 'Balas', value: 'Balas' },
  { label: 'Balas', value: 'Balas' },
  { label: 'Balas', value: 'Balas' },
  { label: 'Balas', value: 'Balas' },
  { label: 'Balasan', value: 'Balasan' },
  { label: 'Bălăşeşti', value: 'Bălăşeşti' },
  { label: 'Balasheyka', value: 'Balasheyka' },
  { label: 'Balashikha', value: 'Balashikha' },
  { label: 'Balashikha Urban Okrug', value: 'Balashikha Urban Okrug' },
  { label: 'Balashov', value: 'Balashov' },
  { label: 'Balasing', value: 'Balasing' },
  { label: 'Balasing', value: 'Balasing' },
  { label: 'Balasinor', value: 'Balasinor' },
  { label: 'Balasore', value: 'Balasore' },
  { label: 'Balassagyarmat', value: 'Balassagyarmat' },
  { label: 'Balassagyarmati Járás', value: 'Balassagyarmati Járás' },
  { label: 'Balástya', value: 'Balástya' },
  { label: 'Balata', value: 'Balata' },
  { label: 'Balatan', value: 'Balatan' },
  { label: 'Balatero', value: 'Balatero' },
  { label: 'Balatonalmádi', value: 'Balatonalmádi' },
  { label: 'Balatonalmádi Járás', value: 'Balatonalmádi Járás' },
  { label: 'Balatonberény', value: 'Balatonberény' },
  { label: 'Balatonboglár', value: 'Balatonboglár' },
  { label: 'Balatonfenyves', value: 'Balatonfenyves' },
  { label: 'Balatonföldvár', value: 'Balatonföldvár' },
  { label: 'Balatonfüred', value: 'Balatonfüred' },
  { label: 'Balatonfüredi Járás', value: 'Balatonfüredi Járás' },
  { label: 'Balatonkenese', value: 'Balatonkenese' },
  { label: 'Balatonlelle', value: 'Balatonlelle' },
  { label: 'Balatonszabadi', value: 'Balatonszabadi' },
  { label: 'Balatonszárszó', value: 'Balatonszárszó' },
  { label: 'Balatun', value: 'Balatun' },
  { label: 'Bălăușeri', value: 'Bălăușeri' },
  { label: 'Balavah Tareh', value: 'Balavah Tareh' },
  { label: 'Balayan', value: 'Balayan' },
  { label: 'Balayang', value: 'Balayang' },
  { label: 'Balayang', value: 'Balayang' },
  { label: 'Balayong', value: 'Balayong' },
  { label: 'Balazar', value: 'Balazar' },
  { label: 'Balazé', value: 'Balazé' },
  { label: 'Balazote', value: 'Balazote' },
  { label: 'Balbalan', value: 'Balbalan' },
  { label: 'Balbalan', value: 'Balbalan' },
  { label: 'Balbasava', value: 'Balbasava' },
  { label: 'Balbases Los', value: 'Balbases Los' },
  { label: 'Balbigny', value: 'Balbigny' },
  { label: 'Balbinos', value: 'Balbinos' },
  { label: 'Balboa', value: 'Balboa' },
  { label: 'Balboa', value: 'Balboa' },
  { label: 'Balboa', value: 'Balboa' },
  { label: 'Balbriggan', value: 'Balbriggan' },
  { label: 'Balc', value: 'Balc' },
  { label: 'Balca', value: 'Balca' },
  { label: 'Balca/En Leur Ba', value: 'Balca/En Leur Ba' },
  { label: 'Bălcaciu', value: 'Bălcaciu' },
  { label: 'Balcani', value: 'Balcani' },
  { label: 'Balcarres', value: 'Balcarres' },
  { label: 'Balcatta', value: 'Balcatta' },
  { label: 'Bălcăuţi', value: 'Bălcăuţi' },
  { label: 'Bălceşti', value: 'Bălceşti' },
  { label: 'Bălcești', value: 'Bălcești' },
  { label: 'Balch Springs', value: 'Balch Springs' },
  { label: 'Balchik', value: 'Balchik' },
  { label: 'Balclutha', value: 'Balclutha' },
  { label: 'Balcombe', value: 'Balcombe' },
  { label: 'Balconchán', value: 'Balconchán' },
  { label: 'Balcones De La Calera', value: 'Balcones De La Calera' },
  { label: 'Balcones Heights', value: 'Balcones Heights' },
  { label: 'Balçova', value: 'Balçova' },
  { label: 'Bald Hills', value: 'Bald Hills' },
  { label: 'Bald Knob', value: 'Bald Knob' },
  { label: 'Balda', value: 'Balda' },
  { label: 'Bâldana', value: 'Bâldana' },
  { label: 'Baldellou', value: 'Baldellou' },
  { label: 'Baldeo', value: 'Baldeo' },
  { label: 'Baldeogarh', value: 'Baldeogarh' },
  { label: 'Baldersheim', value: 'Baldersheim' },
  { label: 'Baldev', value: 'Baldev' },
  { label: 'Baldichieri Dasti', value: 'Baldichieri Dasti' },
  { label: 'Baldim', value: 'Baldim' },
  { label: 'Baldissero Canavese', value: 'Baldissero Canavese' },
  { label: 'Baldissero Dalba', value: 'Baldissero Dalba' },
  { label: 'Baldissero Torinese', value: 'Baldissero Torinese' },
  { label: 'Baldivis', value: 'Baldivis' },
  { label: 'Baldock', value: 'Baldock' },
  { label: 'Baldone', value: 'Baldone' },
  { label: 'Baldovineşti', value: 'Baldovineşti' },
  { label: 'Baldovinești', value: 'Baldovinești' },
  { label: 'Baldoyle', value: 'Baldoyle' },
  { label: 'Baldramsdorf', value: 'Baldramsdorf' },
  { label: 'Baldwin', value: 'Baldwin' },
  { label: 'Baldwin', value: 'Baldwin' },
  { label: 'Baldwin', value: 'Baldwin' },
  { label: 'Baldwin', value: 'Baldwin' },
  { label: 'Baldwin', value: 'Baldwin' },
  { label: 'Baldwin', value: 'Baldwin' },
  { label: 'Baldwin', value: 'Baldwin' },
  { label: 'Baldwin City', value: 'Baldwin City' },
  { label: 'Baldwin County', value: 'Baldwin County' },
  { label: 'Baldwin County', value: 'Baldwin County' },
  { label: 'Baldwin Harbor', value: 'Baldwin Harbor' },
  { label: 'Baldwin Park', value: 'Baldwin Park' },
  { label: 'Baldwinsville', value: 'Baldwinsville' },
  { label: 'Baldwinville', value: 'Baldwinville' },
  { label: 'Baldwyn', value: 'Baldwyn' },
  { label: 'Bale', value: 'Bale' },
  { label: 'Baleira', value: 'Baleira' },
  { label: 'Balele', value: 'Balele' },
  { label: 'Balembouche', value: 'Balembouche' },
  { label: 'Balen', value: 'Balen' },
  { label: 'Băleni', value: 'Băleni' },
  { label: 'Băleni Sârbi', value: 'Băleni Sârbi' },
  { label: 'Baleno', value: 'Baleno' },
  { label: 'Balenyà', value: 'Balenyà' },
  { label: 'Baler', value: 'Baler' },
  { label: 'Baler', value: 'Baler' },
  { label: 'Balerna', value: 'Balerna' },
  { label: 'Balerno', value: 'Balerno' },
  { label: 'Baleshwar', value: 'Baleshwar' },
  { label: 'Băleşti', value: 'Băleşti' },
  { label: 'Băleşti', value: 'Băleşti' },
  { label: 'Balestrand', value: 'Balestrand' },
  { label: 'Balestrate', value: 'Balestrate' },
  { label: 'Balestrino', value: 'Balestrino' },
  { label: 'Balete', value: 'Balete' },
  { label: 'Balete', value: 'Balete' },
  { label: 'Balete', value: 'Balete' },
  { label: 'Bale-Valle', value: 'Bale-Valle' },
  { label: 'Baley', value: 'Baley' },
  { label: 'Baleyskiy Rayon', value: 'Baleyskiy Rayon' },
  { label: 'Balezino', value: 'Balezino' },
  { label: 'Balfai', value: 'Balfai' },
  { label: 'Balfate', value: 'Balfate' },
  { label: 'Balfour', value: 'Balfour' },
  { label: 'Balfour', value: 'Balfour' },
  { label: 'Balfron', value: 'Balfron' },
  { label: 'Balga', value: 'Balga' },
  { label: 'Balgach', value: 'Balgach' },
  { label: 'Balgarevo', value: 'Balgarevo' },
  { label: 'Balgazyn', value: 'Balgazyn' },
  { label: 'Balge', value: 'Balge' },
  { label: 'Balgowlah', value: 'Balgowlah' },
  { label: 'Balgowlah Heights', value: 'Balgowlah Heights' },
  { label: 'Balgownie', value: 'Balgownie' },
  { label: 'Balhannah', value: 'Balhannah' },
  { label: 'Bali', value: 'Bali' },
  { label: 'Bali', value: 'Bali' },
  { label: 'Bali Chak', value: 'Bali Chak' },
  { label: 'Baliarrain', value: 'Baliarrain' },
  { label: 'Baliasne', value: 'Baliasne' },
  { label: 'Balibagan Oeste', value: 'Balibagan Oeste' },
  { label: 'Balibago', value: 'Balibago' },
  { label: 'Balibago', value: 'Balibago' },
  { label: 'Balice', value: 'Balice' },
  { label: 'Balidat Ameur', value: 'Balidat Ameur' },
  { label: 'Baligang', value: 'Baligang' },
  { label: 'Baligród', value: 'Baligród' },
  { label: 'Balikpapan', value: 'Balikpapan' },
  { label: 'Balila', value: 'Balila' },
  { label: 'Balīlā', value: 'Balīlā' },
  { label: 'Bălileşti', value: 'Bălileşti' },
  { label: 'Balili', value: 'Balili' },
  { label: 'Balilihan', value: 'Balilihan' },
  { label: 'Balimbing', value: 'Balimbing' },
  { label: 'Balimila', value: 'Balimila' },
  { label: 'Balin', value: 'Balin' },
  { label: 'Balinad', value: 'Balinad' },
  { label: 'Balindong', value: 'Balindong' },
  { label: 'Balindong', value: 'Balindong' },
  { label: 'Balingasag', value: 'Balingasag' },
  { label: 'Balingasay', value: 'Balingasay' },
  { label: 'Balingasay', value: 'Balingasay' },
  { label: 'Balingcanaway', value: 'Balingcanaway' },
  { label: 'Balingcanaway', value: 'Balingcanaway' },
  { label: 'Bälinge', value: 'Bälinge' },
  { label: 'Balingen', value: 'Balingen' },
  { label: 'Balingoan', value: 'Balingoan' },
  { label: 'Balingueo', value: 'Balingueo' },
  { label: 'Balingueo', value: 'Balingueo' },
  { label: 'Balinsacayao', value: 'Balinsacayao' },
  { label: 'Balinsacayao', value: 'Balinsacayao' },
  { label: 'Balinţ', value: 'Balinţ' },
  { label: 'Balintești', value: 'Balintești' },
  { label: 'Balintore', value: 'Balintore' },
  { label: 'Baliqchi Tumani', value: 'Baliqchi Tumani' },
  { label: 'Balisana', value: 'Balisana' },
  { label: 'Balışeyh', value: 'Balışeyh' },
  { label: 'Balite', value: 'Balite' },
  { label: 'Balite', value: 'Balite' },
  { label: 'Balite', value: 'Balite' },
  { label: 'Balite Segundo', value: 'Balite Segundo' },
  { label: 'Balitoc', value: 'Balitoc' },
  { label: 'Baliton', value: 'Baliton' },
  { label: 'Baliton', value: 'Baliton' },
  { label: 'Baliuag', value: 'Baliuag' },
  { label: 'Baliuag', value: 'Baliuag' },
  { label: 'Baliuag Nuevo', value: 'Baliuag Nuevo' },
  { label: 'Baliwagan', value: 'Baliwagan' },
  { label: 'Baliwagan', value: 'Baliwagan' },
  { label: 'Baliza', value: 'Baliza' },
  { label: 'Balje', value: 'Balje' },
  { label: 'Balk', value: 'Balk' },
  { label: 'Balkanabat', value: 'Balkanabat' },
  { label: 'Balkány', value: 'Balkány' },
  { label: 'Balkashino', value: 'Balkashino' },
  { label: 'Balkh', value: 'Balkh' },
  { label: 'Balky', value: 'Balky' },
  { label: 'Ball', value: 'Ball' },
  { label: 'Ball Ground', value: 'Ball Ground' },
  { label: 'Ballabio', value: 'Ballabio' },
  { label: 'Ballaghaderreen', value: 'Ballaghaderreen' },
  { label: 'Ballainvilliers', value: 'Ballainvilliers' },
  { label: 'Ballaison', value: 'Ballaison' },
  { label: 'Ballajura', value: 'Ballajura' },
  { label: 'Ballalpur', value: 'Ballalpur' },
  { label: 'Ballan', value: 'Ballan' },
  { label: 'Ballancourt-Sur-Essonne', value: 'Ballancourt-Sur-Essonne' },
  { label: 'Ballangen', value: 'Ballangen' },
  { label: 'Ballan-Miré', value: 'Ballan-Miré' },
  { label: 'Ballantrae', value: 'Ballantrae' },
  { label: 'Ballao', value: 'Ballao' },
  { label: 'Ballarat', value: 'Ballarat' },
  { label: 'Ballarat Central', value: 'Ballarat Central' },
  { label: 'Ballarat East', value: 'Ballarat East' },
  { label: 'Ballarat North', value: 'Ballarat North' },
  { label: 'Ballard', value: 'Ballard' },
  { label: 'Ballard County', value: 'Ballard County' },
  { label: 'Ballard Estate', value: 'Ballard Estate' },
  { label: 'Ballards  Valley', value: 'Ballards  Valley' },
  { label: 'Ballari', value: 'Ballari' },
  { label: 'Ballarpur', value: 'Ballarpur' },
  { label: 'Ballast', value: 'Ballast' },
  { label: 'Ballater', value: 'Ballater' },
  { label: 'Ballenger Creek', value: 'Ballenger Creek' },
  { label: 'Ballens', value: 'Ballens' },
  { label: 'Ballenstedt', value: 'Ballenstedt' },
  { label: 'Ballerup', value: 'Ballerup' },
  { label: 'Ballerup Kommune', value: 'Ballerup Kommune' },
  { label: 'Ballestero El', value: 'Ballestero El' },
  { label: 'Ballesteros', value: 'Ballesteros' },
  { label: 'Ballesteros', value: 'Ballesteros' },
  { label: 'Ballesteros De Calatrava', value: 'Ballesteros De Calatrava' },
  { label: 'Balleyara', value: 'Balleyara' },
  { label: 'Balleza', value: 'Balleza' },
  { label: 'Ballia', value: 'Ballia' },
  { label: 'Ballina', value: 'Ballina' },
  { label: 'Ballina', value: 'Ballina' },
  { label: 'Ballina', value: 'Ballina' },
  { label: 'Ballinamallard', value: 'Ballinamallard' },
  { label: 'Ballinasloe', value: 'Ballinasloe' },
  { label: 'Ballinger', value: 'Ballinger' },
  { label: 'Ballingry', value: 'Ballingry' },
  { label: 'Ballinroad', value: 'Ballinroad' },
  { label: 'Ballinrobe', value: 'Ballinrobe' },
  { label: 'Ballinteer', value: 'Ballinteer' },
  { label: 'Ballintoy Harbour', value: 'Ballintoy Harbour' },
  { label: 'Ballisodare', value: 'Ballisodare' },
  { label: 'Ballito', value: 'Ballito' },
  { label: 'Ballivor', value: 'Ballivor' },
  { label: 'Ballò', value: 'Ballò' },
  { label: 'Ballobar', value: 'Ballobar' },
  { label: 'Balloch', value: 'Balloch' },
  { label: 'Ballon', value: 'Ballon' },
  { label: 'Ballószög', value: 'Ballószög' },
  { label: 'Ballots', value: 'Ballots' },
  { label: 'Ballplay', value: 'Ballplay' },
  { label: 'Ballsh', value: 'Ballsh' },
  { label: 'Ballston Spa', value: 'Ballston Spa' },
  { label: 'Ballville', value: 'Ballville' },
  { label: 'Ballwil', value: 'Ballwil' },
  { label: 'Ballwin', value: 'Ballwin' },
  { label: 'Bally', value: 'Bally' },
  { label: 'Bally', value: 'Bally' },
  { label: 'Ballyboden', value: 'Ballyboden' },
  { label: 'Ballybofey', value: 'Ballybofey' },
  { label: 'Ballybunnion', value: 'Ballybunnion' },
  { label: 'Ballycastle', value: 'Ballycastle' },
  { label: 'Ballyclare', value: 'Ballyclare' },
  { label: 'Ballyconnell', value: 'Ballyconnell' },
  { label: 'Ballyfermot', value: 'Ballyfermot' },
  { label: 'Ballygerry', value: 'Ballygerry' },
  { label: 'Ballygowan', value: 'Ballygowan' },
  { label: 'Ballyhaunis', value: 'Ballyhaunis' },
  { label: 'Ballyjamesduff', value: 'Ballyjamesduff' },
  { label: 'Ballykelly', value: 'Ballykelly' },
  { label: 'Ballylinan', value: 'Ballylinan' },
  { label: 'Ballymahon', value: 'Ballymahon' },
  { label: 'Ballymena', value: 'Ballymena' },
  { label: 'Ballymoney', value: 'Ballymoney' },
  { label: 'Ballymote', value: 'Ballymote' },
  { label: 'Ballymun', value: 'Ballymun' },
  { label: 'Ballynahinch', value: 'Ballynahinch' },
  { label: 'Ballypatrick', value: 'Ballypatrick' },
  { label: 'Ballyragget', value: 'Ballyragget' },
  { label: 'Ballyshannon', value: 'Ballyshannon' },
  { label: 'Ballywalter', value: 'Ballywalter' },
  { label: 'Balm', value: 'Balm' },
  { label: 'Balma', value: 'Balma' },
  { label: 'Balmain', value: 'Balmain' },
  { label: 'Balmain East', value: 'Balmain East' },
  { label: 'Balmaseda', value: 'Balmaseda' },
  { label: 'Balmazújváros', value: 'Balmazújváros' },
  { label: 'Balmazújvárosi Járás', value: 'Balmazújvárosi Járás' },
  { label: 'Balme', value: 'Balme' },
  { label: 'Balmedie', value: 'Balmedie' },
  { label: 'Balmoral', value: 'Balmoral' },
  { label: 'Balmuccia', value: 'Balmuccia' },
  { label: 'Balmullo', value: 'Balmullo' },
  { label: 'Balmville', value: 'Balmville' },
  { label: 'Bălnaca', value: 'Bălnaca' },
  { label: 'Balnarring', value: 'Balnarring' },
  { label: 'Balnearia', value: 'Balnearia' },
  { label: 'Balneário Arroio Do Silva', value: 'Balneário Arroio Do Silva' },
  { label: 'Balneário Barra Do Sul', value: 'Balneário Barra Do Sul' },
  { label: 'Balneário Camboriú', value: 'Balneário Camboriú' },
  { label: 'Balneário Gaivota', value: 'Balneário Gaivota' },
  { label: 'Balneário Piçarras', value: 'Balneário Piçarras' },
  { label: 'Balneário Pinhal', value: 'Balneário Pinhal' },
  { label: 'Balneário Rincão', value: 'Balneário Rincão' },
  { label: 'Baloc', value: 'Baloc' },
  { label: 'Baloc', value: 'Baloc' },
  { label: 'Balocawehay', value: 'Balocawehay' },
  { label: 'Balocawehay', value: 'Balocawehay' },
  { label: 'Balocco', value: 'Balocco' },
  { label: 'Balod', value: 'Balod' },
  { label: 'Baloda', value: 'Baloda' },
  { label: 'Baloda Bazar', value: 'Baloda Bazar' },
  { label: 'Balogo', value: 'Balogo' },
  { label: 'Balogo', value: 'Balogo' },
  { label: 'Balogo', value: 'Balogo' },
  { label: 'Balogo', value: 'Balogo' },
  { label: 'Balogo', value: 'Balogo' },
  { label: 'Balogo', value: 'Balogo' },
  { label: 'Balogo', value: 'Balogo' },
  { label: 'Balogo', value: 'Balogo' },
  { label: 'Balombo', value: 'Balombo' },
  { label: 'Balones', value: 'Balones' },
  { label: 'Balonne Shire', value: 'Balonne Shire' },
  { label: 'Bal-Os', value: 'Bal-Os' },
  { label: 'Baloteşti', value: 'Baloteşti' },
  { label: 'Balotra', value: 'Balotra' },
  { label: 'Baloy', value: 'Baloy' },
  { label: 'Baloy', value: 'Baloy' },
  { label: 'Baloži', value: 'Baloži' },
  { label: 'Balpyk Bī', value: 'Balpyk Bī' },
  { label: 'Balqash', value: 'Balqash' },
  { label: 'Balrampur', value: 'Balrampur' },
  { label: 'Balranald', value: 'Balranald' },
  { label: 'Balrothery', value: 'Balrothery' },
  { label: 'Balş', value: 'Balş' },
  { label: 'Balș', value: 'Balș' },
  { label: 'Balşa', value: 'Balşa' },
  { label: 'Balsa De Ves', value: 'Balsa De Ves' },
  { label: 'Balsa Nova', value: 'Balsa Nova' },
  { label: 'Balsall Common', value: 'Balsall Common' },
  { label: 'Balsam Lake', value: 'Balsam Lake' },
  { label: 'Bálsamo', value: 'Bálsamo' },
  { label: 'Bálsamo Oriental', value: 'Bálsamo Oriental' },
  { label: 'Balsareny', value: 'Balsareny' },
  { label: 'Balsas', value: 'Balsas' },
  { label: 'Balsfjord', value: 'Balsfjord' },
  { label: 'Balsham', value: 'Balsham' },
  { label: 'Balsic', value: 'Balsic' },
  { label: 'Balsic', value: 'Balsic' },
  { label: 'Balsorano', value: 'Balsorano' },
  { label: 'Balsorano Nuovo', value: 'Balsorano Nuovo' },
  { label: 'Bålsta', value: 'Bålsta' },
  { label: 'Balsthal', value: 'Balsthal' },
  { label: 'Balta', value: 'Balta' },
  { label: 'Balta', value: 'Balta' },
  { label: 'Bâlta', value: 'Bâlta' },
  { label: 'Bâlta', value: 'Bâlta' },
  { label: 'Balta Albă', value: 'Balta Albă' },
  { label: 'Balta Doamnei', value: 'Balta Doamnei' },
  { label: 'Balta Verde', value: 'Balta Verde' },
  { label: 'Baltanás', value: 'Baltanás' },
  { label: 'Baltar', value: 'Baltar' },
  { label: 'Baltar', value: 'Baltar' },
  { label: 'Baltasar Brum', value: 'Baltasar Brum' },
  { label: 'Baltasi', value: 'Baltasi' },
  { label: 'Baltasinskiy Rayon', value: 'Baltasinskiy Rayon' },
  { label: 'Bălţăteşti', value: 'Bălţăteşti' },
  { label: 'Bălţaţi', value: 'Bălţaţi' },
  { label: 'Baltay', value: 'Baltay' },
  { label: 'Baltayskiy Rayon', value: 'Baltayskiy Rayon' },
  { label: 'Bâlteni', value: 'Bâlteni' },
  { label: 'Bălteni', value: 'Bălteni' },
  { label: 'Bălteni', value: 'Bălteni' },
  { label: 'Bălteni', value: 'Bălteni' },
  { label: 'Bălțești', value: 'Bălțești' },
  { label: 'Bălţi', value: 'Bălţi' },
  { label: 'Baltic', value: 'Baltic' },
  { label: 'Baltic', value: 'Baltic' },
  { label: 'Baltimore', value: 'Baltimore' },
  { label: 'Baltimore', value: 'Baltimore' },
  { label: 'Baltimore County', value: 'Baltimore County' },
  { label: 'Baltimore Highlands', value: 'Baltimore Highlands' },
  { label: 'Baltinava', value: 'Baltinava' },
  { label: 'Baltinglass', value: 'Baltinglass' },
  { label: 'Baltiysk', value: 'Baltiysk' },
  { label: 'Baltmannsweiler', value: 'Baltmannsweiler' },
  { label: 'Baltoji Vokė', value: 'Baltoji Vokė' },
  { label: 'Bałtów', value: 'Bałtów' },
  { label: 'Balts’Kyy Rayon', value: 'Balts’Kyy Rayon' },
  { label: 'Baltschieder', value: 'Baltschieder' },
  { label: 'Balucawi', value: 'Balucawi' },
  { label: 'Balucuc', value: 'Balucuc' },
  { label: 'Balucuc', value: 'Balucuc' },
  { label: 'Balud', value: 'Balud' },
  { label: 'Balud', value: 'Balud' },
  { label: 'Balud', value: 'Balud' },
  { label: 'Balud', value: 'Balud' },
  { label: 'Balugaon', value: 'Balugaon' },
  { label: 'Balugo', value: 'Balugo' },
  { label: 'Balung', value: 'Balung' },
  { label: 'Balungao', value: 'Balungao' },
  { label: 'Balungao', value: 'Balungao' },
  { label: 'Baluntaicun', value: 'Baluntaicun' },
  { label: 'Balurghat', value: 'Balurghat' },
  { label: 'Băluşeni', value: 'Băluşeni' },
  { label: 'Bălușești', value: 'Bălușești' },
  { label: 'Balut', value: 'Balut' },
  { label: 'Balut', value: 'Balut' },
  { label: 'Balutakay', value: 'Balutakay' },
  { label: 'Balutakay', value: 'Balutakay' },
  { label: 'Balutakay', value: 'Balutakay' },
  { label: 'Balutu', value: 'Balutu' },
  { label: 'Balutu', value: 'Balutu' },
  { label: 'Baluyot', value: 'Baluyot' },
  { label: 'Baluyot', value: 'Baluyot' },
  { label: 'Balvanera', value: 'Balvanera' },
  { label: 'Bâlvăneşti', value: 'Bâlvăneşti' },
  { label: 'Balvano', value: 'Balvano' },
  { label: 'Balvard', value: 'Balvard' },
  { label: 'Balve', value: 'Balve' },
  { label: 'Balvi', value: 'Balvi' },
  { label: 'Balwyn', value: 'Balwyn' },
  { label: 'Balwyn North', value: 'Balwyn North' },
  { label: 'Balya', value: 'Balya' },
  { label: 'Balyaga', value: 'Balyaga' },
  { label: 'Balykchy', value: 'Balykchy' },
  { label: 'Balykshi', value: 'Balykshi' },
  { label: 'Balzac', value: 'Balzac' },
  { label: 'Balzan', value: 'Balzan' },
  { label: 'Balzar', value: 'Balzar' },
  { label: 'Balzers', value: 'Balzers' },
  { label: 'Balzfeld', value: 'Balzfeld' },
  { label: 'Balzhausen', value: 'Balzhausen' },
  { label: 'Balzo', value: 'Balzo' },
  { label: 'Balzola', value: 'Balzola' },
  { label: 'Bam', value: 'Bam' },
  { label: 'Bama', value: 'Bama' },
  { label: 'Bamaga', value: 'Bamaga' },
  { label: 'Bamako', value: 'Bamako' },
  { label: 'Bamanbore', value: 'Bamanbore' },
  { label: 'Bamangola Community Development Block', value: 'Bamangola Community Development Block' },
  { label: 'Bambadjani', value: 'Bambadjani' },
  { label: 'Bambakashat', value: 'Bambakashat' },
  { label: 'Bambali', value: 'Bambali' },
  { label: 'Bambamarca', value: 'Bambamarca' },
  { label: 'Bamban', value: 'Bamban' },
  { label: 'Bamban', value: 'Bamban' },
  { label: 'Bambang', value: 'Bambang' },
  { label: 'Bambang', value: 'Bambang' },
  { label: 'Bambao', value: 'Bambao' },
  { label: 'Bambari', value: 'Bambari' },
  { label: 'Bamberg', value: 'Bamberg' },
  { label: 'Bamberg', value: 'Bamberg' },
  { label: 'Bamberg County', value: 'Bamberg County' },
  { label: 'Bamble', value: 'Bamble' },
  { label: 'Bambolim', value: 'Bambolim' },
  { label: 'Bamboo', value: 'Bamboo' },
  { label: 'Bamboo Flat', value: 'Bamboo Flat' },
  { label: 'Bambous', value: 'Bambous' },
  { label: 'Bambous Virieux', value: 'Bambous Virieux' },
  { label: 'Bambuí', value: 'Bambuí' },
  { label: 'Bamburgh', value: 'Bamburgh' },
  { label: 'Bamburu', value: 'Bamburu' },
  { label: 'Bamencheng', value: 'Bamencheng' },
  { label: 'Bamenda', value: 'Bamenda' },
  { label: 'Bamendjou', value: 'Bamendjou' },
  { label: 'Bamenohl', value: 'Bamenohl' },
  { label: 'Bamiantong', value: 'Bamiantong' },
  { label: 'Bamingui', value: 'Bamingui' },
  { label: 'Bammatyurt', value: 'Bammatyurt' },
  { label: 'Bammental', value: 'Bammental' },
  { label: 'Bamna', value: 'Bamna' },
  { label: 'Bamnet Narong', value: 'Bamnet Narong' },
  { label: 'Bamoa', value: 'Bamoa' },
  { label: 'Bamor Kalan', value: 'Bamor Kalan' },
  { label: 'Bamora', value: 'Bamora' },
  { label: 'Bampour', value: 'Bampour' },
  { label: 'Bampton', value: 'Bampton' },
  { label: 'Bamusso', value: 'Bamusso' },
  { label: 'Bamut', value: 'Bamut' },
  { label: 'Bāmyān', value: 'Bāmyān' },
  { label: 'Ban Ao Nang', value: 'Ban Ao Nang' },
  { label: 'Ban Bang Kadi Pathum Thani', value: 'Ban Bang Kadi Pathum Thani' },
  { label: 'Ban Bueng', value: 'Ban Bueng' },
  { label: 'Ban Chalong', value: 'Ban Chalong' },
  { label: 'Ban Chang', value: 'Ban Chang' },
  { label: 'Ban Dan Lan Hoi', value: 'Ban Dan Lan Hoi' },
  { label: 'Ban Dung', value: 'Ban Dung' },
  { label: 'Ban Houakhoua', value: 'Ban Houakhoua' },
  { label: 'Ban Houayxay', value: 'Ban Houayxay' },
  { label: 'Ban Huai Thalaeng', value: 'Ban Huai Thalaeng' },
  { label: 'Ban Karon', value: 'Ban Karon' },
  { label: 'Ban Kata', value: 'Ban Kata' },
  { label: 'Ban Khao Lak', value: 'Ban Khao Lak' },
  { label: 'Ban Khlong Bang Sao Thong', value: 'Ban Khlong Bang Sao Thong' },
  { label: 'Ban Ko Kaeo', value: 'Ban Ko Kaeo' },
  { label: 'Ban Ko Lan', value: 'Ban Ko Lan' },
  { label: 'Ban Lam Luk Ka', value: 'Ban Lam Luk Ka' },
  { label: 'Ban Mai', value: 'Ban Mai' },
  { label: 'Ban Mai Khao', value: 'Ban Mai Khao' },
  { label: 'Ban Mo', value: 'Ban Mo' },
  { label: 'Ban Na', value: 'Ban Na' },
  { label: 'Ban Na San', value: 'Ban Na San' },
  { label: 'Ban Nahin', value: 'Ban Nahin' },
  { label: 'Ban Nam Yuen', value: 'Ban Nam Yuen' },
  { label: 'Ban Nong Wua So', value: 'Ban Nong Wua So' },
  { label: 'Ban Phaeo', value: 'Ban Phaeo' },
  { label: 'Ban Phai', value: 'Ban Phai' },
  { label: 'Ban Phan Don', value: 'Ban Phan Don' },
  { label: 'Ban Phe', value: 'Ban Phe' },
  { label: 'Ban Phru Nai', value: 'Ban Phru Nai' },
  { label: 'Ban Pong', value: 'Ban Pong' },
  { label: 'Ban Ratsada', value: 'Ban Ratsada' },
  { label: 'Ban Sam Chuk', value: 'Ban Sam Chuk' },
  { label: 'Ban Selaphum', value: 'Ban Selaphum' },
  { label: 'Ban Su-Ngai Pa Di', value: 'Ban Su-Ngai Pa Di' },
  { label: 'Ban Tak', value: 'Ban Tak' },
  { label: 'Ban Talat Bueng', value: 'Ban Talat Bueng' },
  { label: 'Ban Talat Nua', value: 'Ban Talat Nua' },
  { label: 'Ban Talat Yai', value: 'Ban Talat Yai' },
  { label: 'Ban Thai Tan', value: 'Ban Thai Tan' },
  { label: 'Ban Thatèng', value: 'Ban Thatèng' },
  { label: 'Bana', value: 'Bana' },
  { label: 'Bana', value: 'Bana' },
  { label: 'Baña A', value: 'Baña A' },
  { label: 'Banaba', value: 'Banaba' },
  { label: 'Banaba', value: 'Banaba' },
  { label: 'Banabuiú', value: 'Banabuiú' },
  { label: 'Bañados De Carrasco', value: 'Bañados De Carrasco' },
  { label: 'Banag', value: 'Banag' },
  { label: 'Banagher', value: 'Banagher' },
  { label: 'Banaj', value: 'Banaj' },
  { label: 'Banak', value: 'Banak' },
  { label: 'Banalo', value: 'Banalo' },
  { label: 'Banamba', value: 'Banamba' },
  { label: 'Banana', value: 'Banana' },
  { label: 'Banana Village', value: 'Banana Village' },
  { label: 'Bananal', value: 'Bananal' },
  { label: 'Bananeiras', value: 'Bananeiras' },
  { label: 'Banannes Bay', value: 'Banannes Bay' },
  { label: 'Banapur', value: 'Banapur' },
  { label: 'Bañares', value: 'Bañares' },
  { label: 'Banari', value: 'Banari' },
  { label: 'Banaruyeh', value: 'Banaruyeh' },
  { label: 'Banas Kantha', value: 'Banas Kantha' },
  { label: 'Banastás', value: 'Banastás' },
  { label: 'Banat', value: 'Banat' },
  { label: 'Banatska Topola', value: 'Banatska Topola' },
  { label: 'Banatski Despotovac', value: 'Banatski Despotovac' },
  { label: 'Banatski Dvor', value: 'Banatski Dvor' },
  { label: 'Banatski Karlovac', value: 'Banatski Karlovac' },
  { label: 'Banatsko Karađorđevo', value: 'Banatsko Karađorđevo' },
  { label: 'Banatsko Veliko Selo', value: 'Banatsko Veliko Selo' },
  { label: 'Banaue', value: 'Banaue' },
  { label: 'Banaue', value: 'Banaue' },
  { label: 'Banavar', value: 'Banavar' },
  { label: 'Banawa', value: 'Banawa' },
  { label: 'Banawa', value: 'Banawa' },
  { label: 'Banawang', value: 'Banawang' },
  { label: 'Banawang', value: 'Banawang' },
  { label: 'Banaybanay', value: 'Banaybanay' },
  { label: 'Banaybanay', value: 'Banaybanay' },
  { label: 'Banaybanay', value: 'Banaybanay' },
  { label: 'Banayoyo', value: 'Banayoyo' },
  { label: 'Banayoyo', value: 'Banayoyo' },
  { label: 'Banaz', value: 'Banaz' },
  { label: 'Banbalah', value: 'Banbalah' },
  { label: 'Banbasa', value: 'Banbasa' },
  { label: 'Banbridge', value: 'Banbridge' },
  { label: 'Banbury', value: 'Banbury' },
  { label: 'Banca', value: 'Banca' },
  { label: 'Bancal', value: 'Bancal' },
  { label: 'Bancali', value: 'Bancali' },
  { label: 'Bancasi', value: 'Bancasi' },
  { label: 'Bancasi', value: 'Bancasi' },
  { label: 'Banchette', value: 'Banchette' },
  { label: 'Banchory', value: 'Banchory' },
  { label: 'Banco Nacional', value: 'Banco Nacional' },
  { label: 'Bancroft', value: 'Bancroft' },
  { label: 'Bancu', value: 'Bancu' },
  { label: 'Band', value: 'Band' },
  { label: 'Band Aman', value: 'Band Aman' },
  { label: 'Banda', value: 'Banda' },
  { label: 'Banda', value: 'Banda' },
  { label: 'Banda', value: 'Banda' },
  { label: 'Banda Aceh', value: 'Banda Aceh' },
  { label: 'Banda Maharashtra', value: 'Banda Maharashtra' },
  { label: 'Bandajou', value: 'Bandajou' },
  { label: 'Bandar', value: 'Bandar' },
  { label: 'Bandar Abbas', value: 'Bandar Abbas' },
  { label: 'Bandar Anzali', value: 'Bandar Anzali' },
  { label: 'Bandar Bushehr', value: 'Bandar Bushehr' },
  { label: 'Bandar Charak', value: 'Bandar Charak' },
  { label: 'Bandar Deylam', value: 'Bandar Deylam' },
  { label: 'Bandar Ganaveh', value: 'Bandar Ganaveh' },
  { label: 'Bandar Imam Khomeini', value: 'Bandar Imam Khomeini' },
  { label: 'Bandar Khamir', value: 'Bandar Khamir' },
  { label: 'Bandar Kong', value: 'Bandar Kong' },
  { label: 'Bandar Labuan', value: 'Bandar Labuan' },
  { label: 'Bandar Lampung', value: 'Bandar Lampung' },
  { label: 'Bandar Lengeh', value: 'Bandar Lengeh' },
  { label: 'Bandar Mahshahr', value: 'Bandar Mahshahr' },
  { label: 'Bandar Rig', value: 'Bandar Rig' },
  { label: 'Bandar Seri Begawan', value: 'Bandar Seri Begawan' },
  { label: 'Bandar Torkaman', value: 'Bandar Torkaman' },
  { label: 'Bandarban', value: 'Bandarban' },
  { label: 'Bandarbeyla', value: 'Bandarbeyla' },
  { label: 'Bandar-E Gaz', value: 'Bandar-E Gaz' },
  { label: 'Bandar-E Kangan', value: 'Bandar-E Kangan' },
  { label: 'Bandar-E-Jask', value: 'Bandar-E-Jask' },
  { label: 'Bandarsiraf', value: 'Bandarsiraf' },
  { label: 'Bande', value: 'Bande' },
  { label: 'Bandeira', value: 'Bandeira' },
  { label: 'Bandeira Do Sul', value: 'Bandeira Do Sul' },
  { label: 'Bandeirante', value: 'Bandeirante' },
  { label: 'Bandeirantes', value: 'Bandeirantes' },
  { label: 'Bandeirantes', value: 'Bandeirantes' },
  { label: 'Bandeirantes Do Tocantins', value: 'Bandeirantes Do Tocantins' },
  { label: 'Ban-De-Laveline', value: 'Ban-De-Laveline' },
  { label: 'Bandera', value: 'Bandera' },
  { label: 'Bandera County', value: 'Bandera County' },
  { label: 'Banderas', value: 'Banderas' },
  { label: 'Banderas Del Águila', value: 'Banderas Del Águila' },
  { label: 'Banderilla', value: 'Banderilla' },
  { label: 'Bandhi', value: 'Bandhi' },
  { label: 'Bandia', value: 'Bandia' },
  { label: 'Bandiagara', value: 'Bandiagara' },
  { label: 'Bandikui', value: 'Bandikui' },
  { label: 'Bandipore', value: 'Bandipore' },
  { label: 'Bandırma', value: 'Bandırma' },
  { label: 'Bandito', value: 'Bandito' },
  { label: 'Bandjoun', value: 'Bandjoun' },
  { label: 'Bandō', value: 'Bandō' },
  { label: 'Bandol', value: 'Bandol' },
  { label: 'Bandon', value: 'Bandon' },
  { label: 'Bandon', value: 'Bandon' },
  { label: 'Bandora', value: 'Bandora' },
  { label: 'Bandō-Shi', value: 'Bandō-Shi' },
  { label: 'Bandra', value: 'Bandra' },
  { label: 'Bandundu', value: 'Bandundu' },
  { label: 'Bandung', value: 'Bandung' },
  { label: 'Băneasa', value: 'Băneasa' },
  { label: 'Băneasa', value: 'Băneasa' },
  { label: 'Băneasa', value: 'Băneasa' },
  { label: 'Băneasa', value: 'Băneasa' },
  { label: 'Baneh', value: 'Baneh' },
  { label: 'Baner', value: 'Baner' },
  { label: 'Banes', value: 'Banes' },
  { label: 'Banesareh', value: 'Banesareh' },
  { label: 'Băneşti', value: 'Băneşti' },
  { label: 'Bănești', value: 'Bănești' },
  { label: 'Baneswar', value: 'Baneswar' },
  { label: 'Banevreh', value: 'Banevreh' },
  { label: 'Bañeza La', value: 'Bañeza La' },
  { label: 'Banff', value: 'Banff' },
  { label: 'Banff', value: 'Banff' },
  { label: 'Banfora', value: 'Banfora' },
  { label: 'Bang Ban', value: 'Bang Ban' },
  { label: 'Bang Bo District', value: 'Bang Bo District' },
  { label: 'Bang Bon', value: 'Bang Bon' },
  { label: 'Bang Bua Thong', value: 'Bang Bua Thong' },
  { label: 'Bang Kapi', value: 'Bang Kapi' },
  { label: 'Bang Khae', value: 'Bang Khae' },
  { label: 'Bang Khen', value: 'Bang Khen' },
  { label: 'Bang Khla', value: 'Bang Khla' },
  { label: 'Bang Kho Laen', value: 'Bang Kho Laen' },
  { label: 'Bang Khun Thain', value: 'Bang Khun Thain' },
  { label: 'Bang Krathum', value: 'Bang Krathum' },
  { label: 'Bang Kruai', value: 'Bang Kruai' },
  { label: 'Bang Lamung', value: 'Bang Lamung' },
  { label: 'Bang Lamung District', value: 'Bang Lamung District' },
  { label: 'Bang Len', value: 'Bang Len' },
  { label: 'Bang Mun Nak', value: 'Bang Mun Nak' },
  { label: 'Bang Na', value: 'Bang Na' },
  { label: 'Bang Nam Priao', value: 'Bang Nam Priao' },
  { label: 'Bang Pa-In', value: 'Bang Pa-In' },
  { label: 'Bang Pakong', value: 'Bang Pakong' },
  { label: 'Bang Phae', value: 'Bang Phae' },
  { label: 'Bang Phlat', value: 'Bang Phlat' },
  { label: 'Bang Racham', value: 'Bang Racham' },
  { label: 'Bang Rak', value: 'Bang Rak' },
  { label: 'Bang Rakam', value: 'Bang Rakam' },
  { label: 'Bang Saphan', value: 'Bang Saphan' },
  { label: 'Bang Sue', value: 'Bang Sue' },
  { label: 'Bang Yai', value: 'Bang Yai' },
  { label: 'Banga', value: 'Banga' },
  { label: 'Banga', value: 'Banga' },
  { label: 'Banga', value: 'Banga' },
  { label: 'Bañga', value: 'Bañga' },
  { label: 'Bangad', value: 'Bangad' },
  { label: 'Bangad', value: 'Bangad' },
  { label: 'Bangad', value: 'Bangad' },
  { label: 'Bangad', value: 'Bangad' },
  { label: 'Bangahan', value: 'Bangahan' },
  { label: 'Bangalore Rural', value: 'Bangalore Rural' },
  { label: 'Bangalore Urban', value: 'Bangalore Urban' },
  { label: 'Bangalow', value: 'Bangalow' },
  { label: 'Banganan', value: 'Banganan' },
  { label: 'Banganan', value: 'Banganan' },
  { label: 'Banganapalle', value: 'Banganapalle' },
  { label: 'Bangandhó', value: 'Bangandhó' },
  { label: 'Bangangté', value: 'Bangangté' },
  { label: 'Bangan-Oda', value: 'Bangan-Oda' },
  { label: 'Bangan-Oda', value: 'Bangan-Oda' },
  { label: 'Bangao', value: 'Bangao' },
  { label: 'Bangao', value: 'Bangao' },
  { label: 'Bangaon', value: 'Bangaon' },
  { label: 'Bangaon', value: 'Bangaon' },
  { label: 'Bangar', value: 'Bangar' },
  { label: 'Bangar', value: 'Bangar' },
  { label: 'Bangar', value: 'Bangar' },
  { label: 'Bangarapet', value: 'Bangarapet' },
  { label: 'Bangarmau', value: 'Bangarmau' },
  { label: 'Bangassou', value: 'Bangassou' },
  { label: 'Bangdong', value: 'Bangdong' },
  { label: 'Bangert', value: 'Bangert' },
  { label: 'Bangil', value: 'Bangil' },
  { label: 'Bangjun', value: 'Bangjun' },
  { label: 'Bangkal', value: 'Bangkal' },
  { label: 'Bangkal', value: 'Bangkal' },
  { label: 'Bangkalan', value: 'Bangkalan' },
  { label: 'Bangkirohan', value: 'Bangkirohan' },
  { label: 'Bangkok', value: 'Bangkok' },
  { label: 'Bangkok Noi', value: 'Bangkok Noi' },
  { label: 'Bangkok Yai', value: 'Bangkok Yai' },
  { label: 'Bangolo', value: 'Bangolo' },
  { label: 'Bangonay', value: 'Bangonay' },
  { label: 'Bangonay', value: 'Bangonay' },
  { label: 'Bangor', value: 'Bangor' },
  { label: 'Bangor', value: 'Bangor' },
  { label: 'Bangor', value: 'Bangor' },
  { label: 'Bangor', value: 'Bangor' },
  { label: 'Bangor', value: 'Bangor' },
  { label: 'Bangor', value: 'Bangor' },
  { label: 'Bangor', value: 'Bangor' },
  { label: 'Bangor Ridge', value: 'Bangor Ridge' },
  { label: 'Bangor Trident Base', value: 'Bangor Trident Base' },
  { label: 'Bangs', value: 'Bangs' },
  { label: 'Bangued', value: 'Bangued' },
  { label: 'Bangued', value: 'Bangued' },
  { label: 'Bangui', value: 'Bangui' },
  { label: 'Bangui', value: 'Bangui' },
  { label: 'Bangui', value: 'Bangui' },
  { label: 'Banguingui', value: 'Banguingui' },
  { label: 'Banhā', value: 'Banhā' },
  { label: 'Banham', value: 'Banham' },
  { label: 'Bánhida', value: 'Bánhida' },
  { label: 'Banhigan', value: 'Banhigan' },
  { label: 'Banholt', value: 'Banholt' },
  { label: 'Bani', value: 'Bani' },
  { label: 'Bani', value: 'Bani' },
  { label: 'Bani', value: 'Bani' },
  { label: 'Bani', value: 'Bani' },
  { label: 'Baní', value: 'Baní' },
  { label: 'Banī Al ‘Awwām', value: 'Banī Al ‘Awwām' },
  { label: 'Bani Al Awam', value: 'Bani Al Awam' },
  { label: 'Bani Dhabyan', value: 'Bani Dhabyan' },
  { label: 'Bani Hushaysh', value: 'Bani Hushaysh' },
  { label: 'Bani Matar', value: 'Bani Matar' },
  { label: 'Banī Mazār', value: 'Banī Mazār' },
  { label: 'Bani Qais', value: 'Bani Qais' },
  { label: 'Bani Sad', value: 'Bani Sad' },
  { label: 'Bani Suraim', value: 'Bani Suraim' },
  { label: 'Banī Suwayf', value: 'Banī Suwayf' },
  { label: 'Bani Walid', value: 'Bani Walid' },
  { label: 'Bani Yas City', value: 'Bani Yas City' },
  { label: 'Bănia', value: 'Bănia' },
  { label: 'Banie', value: 'Banie' },
  { label: 'Banie Mazurskie', value: 'Banie Mazurskie' },
  { label: 'Banihal', value: 'Banihal' },
  { label: 'Banikoara', value: 'Banikoara' },
  { label: 'Banilad', value: 'Banilad' },
  { label: 'Banilad', value: 'Banilad' },
  { label: 'Banino', value: 'Banino' },
  { label: 'Baniocha', value: 'Baniocha' },
  { label: 'Banisilan', value: 'Banisilan' },
  { label: 'Banisilan', value: 'Banisilan' },
  { label: 'Bănişor', value: 'Bănişor' },
  { label: 'Băniţa', value: 'Băniţa' },
  { label: 'Banite', value: 'Banite' },
  { label: 'Bāniyās', value: 'Bāniyās' },
  { label: 'Banja Luka', value: 'Banja Luka' },
  { label: 'Banjar', value: 'Banjar' },
  { label: 'Banjar', value: 'Banjar' },
  { label: 'Banjar', value: 'Banjar' },
  { label: 'Banjar Wangsian', value: 'Banjar Wangsian' },
  { label: 'Banjaran', value: 'Banjaran' },
  { label: 'Banjarmasin', value: 'Banjarmasin' },
  { label: 'Banjol', value: 'Banjol' },
  { label: 'Banjul', value: 'Banjul' },
  { label: 'Banjup', value: 'Banjup' },
  { label: 'Banka', value: 'Banka' },
  { label: 'Bankaw', value: 'Bankaw' },
  { label: 'Bankaw', value: 'Bankaw' },
  { label: 'Banke', value: 'Banke' },
  { label: 'Bankeryd', value: 'Bankeryd' },
  { label: 'Banket', value: 'Banket' },
  { label: 'Bankfoot', value: 'Bankfoot' },
  { label: 'Banki', value: 'Banki' },
  { label: 'Bankim', value: 'Bankim' },
  { label: 'Banknock', value: 'Banknock' },
  { label: 'Bankot', value: 'Bankot' },
  { label: 'Bankra', value: 'Bankra' },
  { label: 'Banks', value: 'Banks' },
  { label: 'Banks', value: 'Banks' },
  { label: 'Banks', value: 'Banks' },
  { label: 'Banks', value: 'Banks' },
  { label: 'Banks County', value: 'Banks County' },
  { label: 'Banks Springs', value: 'Banks Springs' },
  { label: 'Banksia', value: 'Banksia' },
  { label: 'Banksia Beach', value: 'Banksia Beach' },
  { label: 'Banksia Grove', value: 'Banksia Grove' },
  { label: 'Banksia Park', value: 'Banksia Park' },
  { label: 'Banksmeadow', value: 'Banksmeadow' },
  { label: 'Bankstown', value: 'Bankstown' },
  { label: 'Bankura', value: 'Bankura' },
  { label: 'Banloc', value: 'Banloc' },
  { label: 'Banlung', value: 'Banlung' },
  { label: 'Banmankhi', value: 'Banmankhi' },
  { label: 'Bann', value: 'Bann' },
  { label: 'Banna', value: 'Banna' },
  { label: 'Bannach', value: 'Bannach' },
  { label: 'Bannalec', value: 'Bannalec' },
  { label: 'Banner County', value: 'Banner County' },
  { label: 'Banner Elk', value: 'Banner Elk' },
  { label: 'Banner Hill', value: 'Banner Hill' },
  { label: 'Bannewitz', value: 'Bannewitz' },
  { label: 'Banning', value: 'Banning' },
  { label: 'Bannio', value: 'Bannio' },
  { label: 'Bannock County', value: 'Bannock County' },
  { label: 'Bannockburn', value: 'Bannockburn' },
  { label: 'Bannockburn', value: 'Bannockburn' },
  { label: 'Bannockburn', value: 'Bannockburn' },
  { label: 'Bannu', value: 'Bannu' },
  { label: 'Bannur', value: 'Bannur' },
  { label: 'Bañobárez', value: 'Bañobárez' },
  { label: 'Banocboc', value: 'Banocboc' },
  { label: 'Banog Sur', value: 'Banog Sur' },
  { label: 'Banog Sur', value: 'Banog Sur' },
  { label: 'Bañón', value: 'Bañón' },
  { label: 'Banora Point', value: 'Banora Point' },
  { label: 'Banos', value: 'Banos' },
  { label: 'Baños', value: 'Baños' },
  { label: 'Baños De Ebro/Mañueta', value: 'Baños De Ebro/Mañueta' },
  { label: 'Baños De La Encina', value: 'Baños De La Encina' },
  { label: 'Baños De Molgas', value: 'Baños De Molgas' },
  { label: 'Baños De Montemayor', value: 'Baños De Montemayor' },
  { label: 'Baños De Río Tobía', value: 'Baños De Río Tobía' },
  { label: 'Baños De Rioja', value: 'Baños De Rioja' },
  { label: 'Baños De Tajo', value: 'Baños De Tajo' },
  { label: 'Baños De Valdearados', value: 'Baños De Valdearados' },
  { label: 'Bánov', value: 'Bánov' },
  { label: 'Bánovce Nad Bebravou', value: 'Bánovce Nad Bebravou' },
  { label: 'Banovići', value: 'Banovići' },
  { label: 'Banovo Polje', value: 'Banovo Polje' },
  { label: 'Banposh', value: 'Banposh' },
  { label: 'Banqiao', value: 'Banqiao' },
  { label: 'Banquero', value: 'Banquero' },
  { label: 'Banquero', value: 'Banquero' },
  { label: 'Banraeaba Village', value: 'Banraeaba Village' },
  { label: 'Bansalan', value: 'Bansalan' },
  { label: 'Bansalan', value: 'Bansalan' },
  { label: 'Bansalan', value: 'Bansalan' },
  { label: 'Bansang', value: 'Bansang' },
  { label: 'Bansberia', value: 'Bansberia' },
  { label: 'Bansdih', value: 'Bansdih' },
  { label: 'Banse', value: 'Banse' },
  { label: 'Banse La Grace', value: 'Banse La Grace' },
  { label: 'Bansgaon', value: 'Bansgaon' },
  { label: 'Bansi', value: 'Bansi' },
  { label: 'Bansihari Community Development Block', value: 'Bansihari Community Development Block' },
  { label: 'Banská Bystrica', value: 'Banská Bystrica' },
  { label: 'Banská Štiavnica', value: 'Banská Štiavnica' },
  { label: 'Bansko', value: 'Bansko' },
  { label: 'Bansoa', value: 'Bansoa' },
  { label: 'Banstead', value: 'Banstead' },
  { label: 'Bansud', value: 'Bansud' },
  { label: 'Banswada', value: 'Banswada' },
  { label: 'Banswara', value: 'Banswara' },
  { label: 'Bantacan', value: 'Bantacan' },
  { label: 'Bantacan', value: 'Bantacan' },
  { label: 'Bantacan', value: 'Bantacan' },
  { label: 'Bantay', value: 'Bantay' },
  { label: 'Bantay', value: 'Bantay' },
  { label: 'Bantay', value: 'Bantay' },
  { label: 'Bantay', value: 'Bantay' },
  { label: 'Bantayan', value: 'Bantayan' },
  { label: 'Bantayan', value: 'Bantayan' },
  { label: 'Bantayan', value: 'Bantayan' },
  { label: 'Banteay Meas', value: 'Banteay Meas' },
  { label: 'Banteln', value: 'Banteln' },
  { label: 'Banthra', value: 'Banthra' },
  { label: 'Bantilan', value: 'Bantilan' },
  { label: 'Banting', value: 'Banting' },
  { label: 'Bantiqui', value: 'Bantiqui' },
  { label: 'Bantiqui', value: 'Bantiqui' },
  { label: 'Bantog', value: 'Bantog' },
  { label: 'Bantog', value: 'Bantog' },
  { label: 'Bantogon', value: 'Bantogon' },
  { label: 'Bantogon', value: 'Bantogon' },
  { label: 'Bantry', value: 'Bantry' },
  { label: 'Bantuanon', value: 'Bantuanon' },
  { label: 'Bantva', value: 'Bantva' },
  { label: 'Bantval', value: 'Bantval' },
  { label: 'Bantzenheim', value: 'Bantzenheim' },
  { label: 'Bañuelos', value: 'Bañuelos' },
  { label: 'Bañuelos De Bureba', value: 'Bañuelos De Bureba' },
  { label: 'Banugao', value: 'Banugao' },
  { label: 'Banur', value: 'Banur' },
  { label: 'Banus Vallarta', value: 'Banus Vallarta' },
  { label: 'Banwell', value: 'Banwell' },
  { label: 'Banyalbufar', value: 'Banyalbufar' },
  { label: 'Banyeres De Mariola', value: 'Banyeres De Mariola' },
  { label: 'Banyeres Del Penedès', value: 'Banyeres Del Penedès' },
  { label: 'Banyliv', value: 'Banyliv' },
  { label: 'Banyo', value: 'Banyo' },
  { label: 'Banyo', value: 'Banyo' },
  { label: 'Banyoles', value: 'Banyoles' },
  { label: 'Banyule', value: 'Banyule' },
  { label: 'Banyuls De La Marenda', value: 'Banyuls De La Marenda' },
  { label: 'Banyuls-Dels-Aspres', value: 'Banyuls-Dels-Aspres' },
  { label: 'Banyumas', value: 'Banyumas' },
  { label: 'Banyuwangi', value: 'Banyuwangi' },
  { label: 'Banzaê', value: 'Banzaê' },
  { label: 'Banzano', value: 'Banzano' },
  { label: 'Banzi', value: 'Banzi' },
  { label: 'Banzkow', value: 'Banzkow' },
  { label: 'Bao', value: 'Bao' },
  { label: 'Bảo Lộc', value: 'Bảo Lộc' },
  { label: 'Baoding', value: 'Baoding' },
  { label: 'Baoji Shi', value: 'Baoji Shi' },
  { label: 'Baoma', value: 'Baoma' },
  { label: 'Baon', value: 'Baon' },
  { label: 'Baon', value: 'Baon' },
  { label: 'Baon', value: 'Baon' },
  { label: 'Baone', value: 'Baone' },
  { label: 'Baoqing', value: 'Baoqing' },
  { label: 'Baoro', value: 'Baoro' },
  { label: 'Baoshan', value: 'Baoshan' },
  { label: 'Baotou', value: 'Baotou' },
  { label: 'Baoying', value: 'Baoying' },
  { label: 'Bapatla', value: 'Bapatla' },
  { label: 'Bapaume', value: 'Bapaume' },
  { label: 'Baptist', value: 'Baptist' },
  { label: 'Baq`A', value: 'Baq`A' },
  { label: 'Baqim', value: 'Baqim' },
  { label: 'Baqubah', value: 'Baqubah' },
  { label: 'Baquerín De Campos', value: 'Baquerín De Campos' },
  { label: 'Baquero Norte', value: 'Baquero Norte' },
  { label: 'Baquero Norte', value: 'Baquero Norte' },
  { label: 'Bar', value: 'Bar' },
  { label: 'Bar', value: 'Bar' },
  { label: 'Bar', value: 'Bar' },
  { label: 'Bar Beach', value: 'Bar Beach' },
  { label: 'Bar Bigha', value: 'Bar Bigha' },
  { label: 'Bar Harbor', value: 'Bar Harbor' },
  { label: 'Bar Hill', value: 'Bar Hill' },
  { label: 'Bar Nunn', value: 'Bar Nunn' },
  { label: 'Bara', value: 'Bara' },
  { label: 'Bara', value: 'Bara' },
  { label: 'Bara', value: 'Bara' },
  { label: 'Bara', value: 'Bara' },
  { label: 'Bâra', value: 'Bâra' },
  { label: 'Bâra', value: 'Bâra' },
  { label: 'Bara ', value: 'Bara ' },
  { label: 'Bara Banki', value: 'Bara Banki' },
  { label: 'Bara Bara', value: 'Bara Bara' },
  { label: 'Bara De Chachalacas', value: 'Bara De Chachalacas' },
  { label: 'Bara Uchana', value: 'Bara Uchana' },
  { label: 'Baraba', value: 'Baraba' },
  { label: 'Barabai', value: 'Barabai' },
  { label: 'Baraband', value: 'Baraband' },
  { label: 'Bărăbanț', value: 'Bărăbanț' },
  { label: 'Barabash', value: 'Barabash' },
  { label: 'Barabazar', value: 'Barabazar' },
  { label: 'Barabinsk', value: 'Barabinsk' },
  { label: 'Baraboo', value: 'Baraboo' },
  { label: 'Baraçal', value: 'Baraçal' },
  { label: 'Baracatan', value: 'Baracatan' },
  { label: 'Baracatan', value: 'Baracatan' },
  { label: 'Baracatan', value: 'Baracatan' },
  { label: 'Baracoa', value: 'Baracoa' },
  { label: 'Baracoa', value: 'Baracoa' },
  { label: 'Baracs', value: 'Baracs' },
  { label: 'Baracska', value: 'Baracska' },
  { label: 'Baradères', value: 'Baradères' },
  { label: 'Baradili', value: 'Baradili' },
  { label: 'Baraga', value: 'Baraga' },
  { label: 'Baraga County', value: 'Baraga County' },
  { label: 'Bărăganu', value: 'Bărăganu' },
  { label: 'Bărăganul', value: 'Bărăganul' },
  { label: 'Baragaon', value: 'Baragaon' },
  { label: 'Baragarh', value: 'Baragarh' },
  { label: 'Baraggia', value: 'Baraggia' },
  { label: 'Baragiano', value: 'Baragiano' },
  { label: 'Baraguá', value: 'Baraguá' },
  { label: 'Bārah', value: 'Bārah' },
  { label: 'Barahan', value: 'Barahan' },
  { label: 'Barahona', value: 'Barahona' },
  { label: 'Barajas De Madrid', value: 'Barajas De Madrid' },
  { label: 'Barajas De Melo', value: 'Barajas De Melo' },
  { label: 'Barakaldo', value: 'Barakaldo' },
  { label: 'Barakani', value: 'Barakani' },
  { label: 'Baraki', value: 'Baraki' },
  { label: 'Baraki Barak', value: 'Baraki Barak' },
  { label: 'Baralla', value: 'Baralla' },
  { label: 'Baramati', value: 'Baramati' },
  { label: 'Baramula', value: 'Baramula' },
  { label: 'Baran', value: 'Baran' },
  { label: 'Baran’', value: 'Baran’' },
  { label: 'Baranagar', value: 'Baranagar' },
  { label: 'Baranah', value: 'Baranah' },
  { label: 'Barañáin', value: 'Barañáin' },
  { label: 'Baranca', value: 'Baranca' },
  { label: 'Baranchinskiy', value: 'Baranchinskiy' },
  { label: 'Báránd', value: 'Báránd' },
  { label: 'Baranda', value: 'Baranda' },
  { label: 'Baranduda', value: 'Baranduda' },
  { label: 'Baranello', value: 'Baranello' },
  { label: 'Barangobong', value: 'Barangobong' },
  { label: 'Barangobong', value: 'Barangobong' },
  { label: 'Barani', value: 'Barani' },
  { label: 'Baranivka', value: 'Baranivka' },
  { label: 'Barano Dischia', value: 'Barano Dischia' },
  { label: 'Baranoa', value: 'Baranoa' },
  { label: 'Baranovichi', value: 'Baranovichi' },
  { label: 'Baranovichskiy Rayon', value: 'Baranovichskiy Rayon' },
  { label: 'Baranów', value: 'Baranów' },
  { label: 'Baranów', value: 'Baranów' },
  { label: 'Baranów Sandomierski', value: 'Baranów Sandomierski' },
  { label: 'Baranowo', value: 'Baranowo' },
  { label: 'Baranowo', value: 'Baranowo' },
  { label: 'Baranzate', value: 'Baranzate' },
  { label: 'Barão', value: 'Barão' },
  { label: 'Barão De Antonina', value: 'Barão De Antonina' },
  { label: 'Barão De Cocais', value: 'Barão De Cocais' },
  { label: 'Barão De Cotegipe', value: 'Barão De Cotegipe' },
  { label: 'Barão De Grajaú', value: 'Barão De Grajaú' },
  { label: 'Barão De Melgaço', value: 'Barão De Melgaço' },
  { label: 'Barão De Monte Alto', value: 'Barão De Monte Alto' },
  { label: 'Barão Do Triunfo', value: 'Barão Do Triunfo' },
  { label: 'Baraolt', value: 'Baraolt' },
  { label: 'Baraona', value: 'Baraona' },
  { label: 'Baraqueville', value: 'Baraqueville' },
  { label: 'Baras', value: 'Baras' },
  { label: 'Baras', value: 'Baras' },
  { label: 'Baras', value: 'Baras' },
  { label: 'Baras', value: 'Baras' },
  { label: 'Barasat', value: 'Barasat' },
  { label: 'Barashevo', value: 'Barashevo' },
  { label: 'Barashi', value: 'Barashi' },
  { label: 'Barásoain', value: 'Barásoain' },
  { label: 'Barasso', value: 'Barasso' },
  { label: 'Bărăști', value: 'Bărăști' },
  { label: 'Bărăștii De Vede', value: 'Bărăștii De Vede' },
  { label: 'Barataria', value: 'Barataria' },
  { label: 'Barați', value: 'Barați' },
  { label: 'Baratili San Pietro', value: 'Baratili San Pietro' },
  { label: 'Baratte', value: 'Baratte' },
  { label: 'Barauli', value: 'Barauli' },
  { label: 'Baraúna', value: 'Baraúna' },
  { label: 'Baraúna', value: 'Baraúna' },
  { label: 'Baraut', value: 'Baraut' },
  { label: 'Baravat', value: 'Baravat' },
  { label: 'Baraya', value: 'Baraya' },
  { label: 'Barayong', value: 'Barayong' },
  { label: 'Barba', value: 'Barba' },
  { label: 'Barbacena', value: 'Barbacena' },
  { label: 'Barbacha', value: 'Barbacha' },
  { label: 'Barbacoas', value: 'Barbacoas' },
  { label: 'Barbadás', value: 'Barbadás' },
  { label: 'Barbadillo', value: 'Barbadillo' },
  { label: 'Barbadillo De Herreros', value: 'Barbadillo De Herreros' },
  { label: 'Barbadillo Del Mercado', value: 'Barbadillo Del Mercado' },
  { label: 'Barbadillo Del Pez', value: 'Barbadillo Del Pez' },
  { label: 'Barbalha', value: 'Barbalha' },
  { label: 'Barbalos', value: 'Barbalos' },
  { label: 'Barbania', value: 'Barbania' },
  { label: 'Barbara', value: 'Barbara' },
  { label: 'Barbarano Romano', value: 'Barbarano Romano' },
  { label: 'Barbarano Vicentino', value: 'Barbarano Vicentino' },
  { label: 'Barbarasco', value: 'Barbarasco' },
  { label: 'Barbaresco', value: 'Barbaresco' },
  { label: 'Barbariga', value: 'Barbariga' },
  { label: 'Barbarin', value: 'Barbarin' },
  { label: 'Barbary Hall', value: 'Barbary Hall' },
  { label: 'Barbaste', value: 'Barbaste' },
  { label: 'Barbastro', value: 'Barbastro' },
  { label: 'Barbata', value: 'Barbata' },
  { label: 'Barbate', value: 'Barbate' },
  { label: 'Bărbăteşti', value: 'Bărbăteşti' },
  { label: 'Bărbăteşti', value: 'Bărbăteşti' },
  { label: 'Barbâtre', value: 'Barbâtre' },
  { label: 'Barbaza', value: 'Barbaza' },
  { label: 'Barbazan-Debat', value: 'Barbazan-Debat' },
  { label: 'Barbechat', value: 'Barbechat' },
  { label: 'Barbens', value: 'Barbens' },
  { label: 'Barbentane', value: 'Barbentane' },
  { label: 'Barber County', value: 'Barber County' },
  { label: 'Barberà De La Conca', value: 'Barberà De La Conca' },
  { label: 'Barberà Del Vallès', value: 'Barberà Del Vallès' },
  { label: 'Barberaz', value: 'Barberaz' },
  { label: 'Barberena', value: 'Barberena' },
  { label: 'Barberi', value: 'Barberi' },
  { label: 'Barberino Di Mugello', value: 'Barberino Di Mugello' },
  { label: 'Barberino Val Delsa', value: 'Barberino Val Delsa' },
  { label: 'Barberton', value: 'Barberton' },
  { label: 'Barberton', value: 'Barberton' },
  { label: 'Barberton', value: 'Barberton' },
  { label: 'Barbezieux-Saint-Hilaire', value: 'Barbezieux-Saint-Hilaire' },
  { label: 'Barbianello', value: 'Barbianello' },
  { label: 'Barbiano', value: 'Barbiano' },
  { label: 'Barbiano - Barbian', value: 'Barbiano - Barbian' },
  { label: 'Barbican', value: 'Barbican' },
  { label: 'Barbican', value: 'Barbican' },
  { label: 'Barbil', value: 'Barbil' },
  { label: 'Barbing', value: 'Barbing' },
  { label: 'Barbisano', value: 'Barbisano' },
  { label: 'Barbizon', value: 'Barbizon' },
  { label: 'Bárboles', value: 'Bárboles' },
  { label: 'Barbolla', value: 'Barbolla' },
  { label: 'Barbona', value: 'Barbona' },
  { label: 'Barbosa', value: 'Barbosa' },
  { label: 'Barbosa', value: 'Barbosa' },
  { label: 'Barbosa', value: 'Barbosa' },
  { label: 'Barbosa Ferraz', value: 'Barbosa Ferraz' },
  { label: 'Bărboși', value: 'Bărboși' },
  { label: 'Barbour County', value: 'Barbour County' },
  { label: 'Barbour County', value: 'Barbour County' },
  { label: 'Barbourmeade', value: 'Barbourmeade' },
  { label: 'Barboursville', value: 'Barboursville' },
  { label: 'Barbourville', value: 'Barbourville' },
  { label: 'Barbués', value: 'Barbués' },
  { label: 'Bărbulești', value: 'Bărbulești' },
  { label: 'Bărbuleţu', value: 'Bărbuleţu' },
  { label: 'Barbuñales', value: 'Barbuñales' },
  { label: 'Barby', value: 'Barby' },
  { label: 'Barby', value: 'Barby' },
  { label: 'Barca', value: 'Barca' },
  { label: 'Bârca', value: 'Bârca' },
  { label: 'Bárcabo', value: 'Bárcabo' },
  { label: 'Barcaldine', value: 'Barcaldine' },
  { label: 'Bărcăneşti', value: 'Bărcăneşti' },
  { label: 'Bărcăneşti', value: 'Bărcăneşti' },
  { label: 'Bărcăneşti', value: 'Bărcăneşti' },
  { label: 'Barcani', value: 'Barcani' },
  { label: 'Barcarena', value: 'Barcarena' },
  { label: 'Barcarena', value: 'Barcarena' },
  { label: 'Barcarena', value: 'Barcarena' },
  { label: 'Barcarrota', value: 'Barcarrota' },
  { label: 'Barcea', value: 'Barcea' },
  { label: 'Barcellona Pozzo Di Gotto', value: 'Barcellona Pozzo Di Gotto' },
  { label: 'Barcelona', value: 'Barcelona' },
  { label: 'Barcelona', value: 'Barcelona' },
  { label: 'Barcelona', value: 'Barcelona' },
  { label: 'Barcelona', value: 'Barcelona' },
  { label: 'Barcelona', value: 'Barcelona' },
  { label: 'Barcelona', value: 'Barcelona' },
  { label: 'Barcelona', value: 'Barcelona' },
  { label: 'Barceloneta', value: 'Barceloneta' },
  { label: 'Barceloneta', value: 'Barceloneta' },
  { label: 'Barcelonne-Du-Gers', value: 'Barcelonne-Du-Gers' },
  { label: 'Barcelonnette', value: 'Barcelonnette' },
  { label: 'Barcelos', value: 'Barcelos' },
  { label: 'Barcelos', value: 'Barcelos' },
  { label: 'Bárcena De Campos', value: 'Bárcena De Campos' },
  { label: 'Bárcena De Cicero', value: 'Bárcena De Cicero' },
  { label: 'Bárcena De Pie De Concha', value: 'Bárcena De Pie De Concha' },
  { label: 'Barceo', value: 'Barceo' },
  { label: 'Barchfeld', value: 'Barchfeld' },
  { label: 'Barchi', value: 'Barchi' },
  { label: 'Barchín Del Hoyo', value: 'Barchín Del Hoyo' },
  { label: 'Barcial De La Loma', value: 'Barcial De La Loma' },
  { label: 'Barcial Del Barco', value: 'Barcial Del Barco' },
  { label: 'Barciany', value: 'Barciany' },
  { label: 'Barcience', value: 'Barcience' },
  { label: 'Barcin', value: 'Barcin' },
  { label: 'Barcis', value: 'Barcis' },
  { label: 'Barclayville', value: 'Barclayville' },
  { label: 'Barco', value: 'Barco' },
  { label: 'Barco De Ávila El', value: 'Barco De Ávila El' },
  { label: 'Barco De Valdeorras O', value: 'Barco De Valdeorras O' },
  { label: 'Barcones', value: 'Barcones' },
  { label: 'Barcoo', value: 'Barcoo' },
  { label: 'Barcs', value: 'Barcs' },
  { label: 'Barcsi Járás', value: 'Barcsi Járás' },
  { label: 'Barczewo', value: 'Barczewo' },
  { label: 'Bard', value: 'Bard' },
  { label: 'Barda', value: 'Barda' },
  { label: 'Barda', value: 'Barda' },
  { label: 'Bardallur', value: 'Bardallur' },
  { label: 'Bardaskan', value: 'Bardaskan' },
  { label: 'Bardeh Rasheh', value: 'Bardeh Rasheh' },
  { label: 'Bardejov', value: 'Bardejov' },
  { label: 'Bardello', value: 'Bardello' },
  { label: 'Barden Ridge', value: 'Barden Ridge' },
  { label: 'Bardestan', value: 'Bardestan' },
  { label: 'Bărdești', value: 'Bărdești' },
  { label: 'Bardhaman', value: 'Bardhaman' },
  { label: 'Bardi', value: 'Bardi' },
  { label: 'Bardia', value: 'Bardia' },
  { label: 'Bardineto', value: 'Bardineto' },
  { label: 'Bardiya ', value: 'Bardiya ' },
  { label: 'Bardney', value: 'Bardney' },
  { label: 'Bardo', value: 'Bardo' },
  { label: 'Bardoli', value: 'Bardoli' },
  { label: 'Bardolino', value: 'Bardolino' },
  { label: 'Bardon', value: 'Bardon' },
  { label: 'Bardonecchia', value: 'Bardonecchia' },
  { label: 'Bardonia', value: 'Bardonia' },
  { label: 'Bardos', value: 'Bardos' },
  { label: 'Bardowick', value: 'Bardowick' },
  { label: 'Bardsey', value: 'Bardsey' },
  { label: 'Bardsir', value: 'Bardsir' },
  { label: 'Bardstown', value: 'Bardstown' },
  { label: 'Bardu', value: 'Bardu' },
  { label: 'Bardwell', value: 'Bardwell' },
  { label: 'Bardwell Park', value: 'Bardwell Park' },
  { label: 'Bardwell Valley', value: 'Bardwell Valley' },
  { label: 'Bardzrashen', value: 'Bardzrashen' },
  { label: 'Bareggio', value: 'Bareggio' },
  { label: 'Barehsar', value: 'Barehsar' },
  { label: 'Bareilly', value: 'Bareilly' },
  { label: 'Barela', value: 'Barela' },
  { label: 'Barellan Point', value: 'Barellan Point' },
  { label: 'Barenburg', value: 'Barenburg' },
  { label: 'Barendorf', value: 'Barendorf' },
  { label: 'Barendrecht', value: 'Barendrecht' },
  { label: 'Barengo', value: 'Barengo' },
  { label: 'Bärenstein', value: 'Bärenstein' },
  { label: 'Barentin', value: 'Barentin' },
  { label: 'Barenton', value: 'Barenton' },
  { label: 'Barentu', value: 'Barentu' },
  { label: 'Baressa', value: 'Baressa' },
  { label: 'Barete', value: 'Barete' },
  { label: 'Bäretswil', value: 'Bäretswil' },
  { label: 'Bareyo', value: 'Bareyo' },
  { label: 'Barf Anbar', value: 'Barf Anbar' },
  { label: 'Barga', value: 'Barga' },
  { label: 'Bargaal', value: 'Bargaal' },
  { label: 'Bargagli', value: 'Bargagli' },
  { label: 'Bargano', value: 'Bargano' },
  { label: 'Bargara', value: 'Bargara' },
  { label: 'Bargarh', value: 'Bargarh' },
  { label: 'Bargas', value: 'Bargas' },
  { label: 'Bârgău', value: 'Bârgău' },
  { label: 'Bârgăuani', value: 'Bârgăuani' },
  { label: 'Barge', value: 'Barge' },
  { label: 'Bargecheh', value: 'Bargecheh' },
  { label: 'Bargeddie', value: 'Bargeddie' },
  { label: 'Bargemon', value: 'Bargemon' },
  { label: 'Bargen', value: 'Bargen' },
  { label: 'Bargeres', value: 'Bargeres' },
  { label: 'Barger-Oosterveld', value: 'Barger-Oosterveld' },
  { label: 'Bargersville', value: 'Bargersville' },
  { label: 'Bargfeld-Stegen', value: 'Bargfeld-Stegen' },
  { label: 'Barghat', value: 'Barghat' },
  { label: 'Barghe', value: 'Barghe' },
  { label: 'Bârghiş', value: 'Bârghiş' },
  { label: 'Bargi', value: 'Bargi' },
  { label: 'Bargo', value: 'Bargo' },
  { label: 'Bargoed', value: 'Bargoed' },
  { label: 'Bargota', value: 'Bargota' },
  { label: 'Bargstedt', value: 'Bargstedt' },
  { label: 'Bargteheide', value: 'Bargteheide' },
  { label: 'Barguna ', value: 'Barguna ' },
  { label: 'Barguzin', value: 'Barguzin' },
  { label: 'Barh', value: 'Barh' },
  { label: 'Barhalganj', value: 'Barhalganj' },
  { label: 'Barham', value: 'Barham' },
  { label: 'Barham', value: 'Barham' },
  { label: 'Barhi', value: 'Barhi' },
  { label: 'Barhiya', value: 'Barhiya' },
  { label: 'Bari', value: 'Bari' },
  { label: 'Bari', value: 'Bari' },
  { label: 'Bari Sadri', value: 'Bari Sadri' },
  { label: 'Bari Sardo', value: 'Bari Sardo' },
  { label: 'Bariadi', value: 'Bariadi' },
  { label: 'Bariano', value: 'Bariano' },
  { label: 'Bariarpur', value: 'Bariarpur' },
  { label: 'Baribour', value: 'Baribour' },
  { label: 'Barič', value: 'Barič' },
  { label: 'Barice', value: 'Barice' },
  { label: 'Barice', value: 'Barice' },
  { label: 'Baricella', value: 'Baricella' },
  { label: 'Barichara', value: 'Barichara' },
  { label: 'Bariga', value: 'Bariga' },
  { label: 'Barika', value: 'Barika' },
  { label: 'Barile', value: 'Barile' },
  { label: 'Barili', value: 'Barili' },
  { label: 'Barillas', value: 'Barillas' },
  { label: 'Barillas', value: 'Barillas' },
  { label: 'Barinas', value: 'Barinas' },
  { label: 'Baringo', value: 'Baringo' },
  { label: 'Barinitas', value: 'Barinitas' },
  { label: 'Bariometo', value: 'Bariometo' },
  { label: 'Barique', value: 'Barique' },
  { label: 'Barira', value: 'Barira' },
  { label: 'Barira', value: 'Barira' },
  { label: 'Bariri', value: 'Bariri' },
  { label: 'Barisal ', value: 'Barisal ' },
  { label: 'Barisciano', value: 'Barisciano' },
  { label: 'Barishal', value: 'Barishal' },
  { label: 'Bäriswil', value: 'Bäriswil' },
  { label: 'Bariw', value: 'Bariw' },
  { label: 'Barjac', value: 'Barjac' },
  { label: 'Barjala', value: 'Barjala' },
  { label: 'Barjas', value: 'Barjas' },
  { label: 'Barjols', value: 'Barjols' },
  { label: 'Barjora', value: 'Barjora' },
  { label: 'Barjouville', value: 'Barjouville' },
  { label: 'Bark', value: 'Bark' },
  { label: 'Barka Kana', value: 'Barka Kana' },
  { label: 'Barkā’', value: 'Barkā’' },
  { label: 'Barkåker', value: 'Barkåker' },
  { label: 'Barkam', value: 'Barkam' },
  { label: 'Barkarö', value: 'Barkarö' },
  { label: 'Barkelsby', value: 'Barkelsby' },
  { label: 'Barker Heights', value: 'Barker Heights' },
  { label: 'Barkéwol', value: 'Barkéwol' },
  { label: 'Barkhan', value: 'Barkhan' },
  { label: 'Bārkhān District', value: 'Bārkhān District' },
  { label: 'Barkhera', value: 'Barkhera' },
  { label: 'Barkhera Kalan', value: 'Barkhera Kalan' },
  { label: 'Barki Saria', value: 'Barki Saria' },
  { label: 'Barking', value: 'Barking' },
  { label: 'Barking Lodge', value: 'Barking Lodge' },
  { label: 'Barkisland', value: 'Barkisland' },
  { label: 'Barkly', value: 'Barkly' },
  { label: 'Barkly West', value: 'Barkly West' },
  { label: 'Barkot', value: 'Barkot' },
  { label: 'Bârla', value: 'Bârla' },
  { label: 'Bârlad', value: 'Bârlad' },
  { label: 'Barlassina', value: 'Barlassina' },
  { label: 'Barlaston', value: 'Barlaston' },
  { label: 'Barlborough', value: 'Barlborough' },
  { label: 'Barlby', value: 'Barlby' },
  { label: 'Barleben', value: 'Barleben' },
  { label: 'Bar-Le-Duc', value: 'Bar-Le-Duc' },
  { label: 'Barlestone', value: 'Barlestone' },
  { label: 'Barletta', value: 'Barletta' },
  { label: 'Bârlibaș', value: 'Bârlibaș' },
  { label: 'Bârlibășoaia', value: 'Bârlibășoaia' },
  { label: 'Barlig', value: 'Barlig' },
  { label: 'Barlig', value: 'Barlig' },
  { label: 'Barlin', value: 'Barlin' },
  { label: 'Barlinek', value: 'Barlinek' },
  { label: 'Barling', value: 'Barling' },
  { label: 'Bârlogu', value: 'Bârlogu' },
  { label: 'Barlovento', value: 'Barlovento' },
  { label: 'Barma', value: 'Barma' },
  { label: 'Barmbek-Nord', value: 'Barmbek-Nord' },
  { label: 'Barmby On The Marsh', value: 'Barmby On The Marsh' },
  { label: 'Barmer', value: 'Barmer' },
  { label: 'Barmera', value: 'Barmera' },
  { label: 'Barmouth', value: 'Barmouth' },
  { label: 'Barmstedt', value: 'Barmstedt' },
  { label: 'Bârna', value: 'Bârna' },
  { label: 'Barnack', value: 'Barnack' },
  { label: 'Barnala', value: 'Barnala' },
  { label: 'Barnard Castle', value: 'Barnard Castle' },
  { label: 'Barnard Hill', value: 'Barnard Hill' },
  { label: 'Bärnau', value: 'Bärnau' },
  { label: 'Barnaul', value: 'Barnaul' },
  { label: 'Barnaul Urban Okrug', value: 'Barnaul Urban Okrug' },
  { label: 'Bärnbach', value: 'Bärnbach' },
  { label: 'Barnburgh', value: 'Barnburgh' },
  { label: 'Barnegat', value: 'Barnegat' },
  { label: 'Barnes County', value: 'Barnes County' },
  { label: 'Barnes Lake-Millers Lake', value: 'Barnes Lake-Millers Lake' },
  { label: 'Barnesboro', value: 'Barnesboro' },
  { label: 'Barnesville', value: 'Barnesville' },
  { label: 'Barnesville', value: 'Barnesville' },
  { label: 'Barnesville', value: 'Barnesville' },
  { label: 'Barnet', value: 'Barnet' },
  { label: 'Barnetby Le Wold', value: 'Barnetby Le Wold' },
  { label: 'Barneveld', value: 'Barneveld' },
  { label: 'Barneveld', value: 'Barneveld' },
  { label: 'Barneville-Carteret', value: 'Barneville-Carteret' },
  { label: 'Barneville-Plage', value: 'Barneville-Plage' },
  { label: 'Barney Point', value: 'Barney Point' },
  { label: 'Barneyside', value: 'Barneyside' },
  { label: 'Barnham', value: 'Barnham' },
  { label: 'Barnhart', value: 'Barnhart' },
  { label: 'Barni', value: 'Barni' },
  { label: 'Barnizal', value: 'Barnizal' },
  { label: 'Bärnkopf', value: 'Bärnkopf' },
  { label: 'Barnoldswick', value: 'Barnoldswick' },
  { label: 'Bârnova', value: 'Bârnova' },
  { label: 'Barnsbury', value: 'Barnsbury' },
  { label: 'Barnsdall', value: 'Barnsdall' },
  { label: 'Barnsley', value: 'Barnsley' },
  { label: 'Barnsley', value: 'Barnsley' },
  { label: 'Barnstable', value: 'Barnstable' },
  { label: 'Barnstable County', value: 'Barnstable County' },
  { label: 'Barnstädt', value: 'Barnstädt' },
  { label: 'Barnstaple', value: 'Barnstaple' },
  { label: 'Barnstead', value: 'Barnstead' },
  { label: 'Barnstorf', value: 'Barnstorf' },
  { label: 'Barnt Green', value: 'Barnt Green' },
  { label: 'Barntrup', value: 'Barntrup' },
  { label: 'Barnum Island', value: 'Barnum Island' },
  { label: 'Barnwell', value: 'Barnwell' },
  { label: 'Barnwell County', value: 'Barnwell County' },
  { label: 'Barnwood', value: 'Barnwood' },
  { label: 'Baro', value: 'Baro' },
  { label: 'Baro', value: 'Baro' },
  { label: 'Baro', value: 'Baro' },
  { label: 'Baro Kunda', value: 'Baro Kunda' },
  { label: 'Barobo', value: 'Barobo' },
  { label: 'Barobo', value: 'Barobo' },
  { label: 'Barokhar', value: 'Barokhar' },
  { label: 'Barolo', value: 'Barolo' },
  { label: 'Barone', value: 'Barone' },
  { label: 'Barone Canavese', value: 'Barone Canavese' },
  { label: 'Barong', value: 'Barong' },
  { label: 'Barong', value: 'Barong' },
  { label: 'Barong Barong', value: 'Barong Barong' },
  { label: 'Barongis', value: 'Barongis' },
  { label: 'Barongis', value: 'Barongis' },
  { label: 'Baronia De Rialb La', value: 'Baronia De Rialb La' },
  { label: 'Baronissi', value: 'Baronissi' },
  { label: 'Barons Drive/Coin De Lanse', value: 'Barons Drive/Coin De Lanse' },
  { label: 'Barooga', value: 'Barooga' },
  { label: 'Barossa', value: 'Barossa' },
  { label: 'Barotac Nuevo', value: 'Barotac Nuevo' },
  { label: 'Barotac Viejo', value: 'Barotac Viejo' },
  { label: 'Baroueli', value: 'Baroueli' },
  { label: 'Baroy', value: 'Baroy' },
  { label: 'Barpali', value: 'Barpali' },
  { label: 'Barpathar', value: 'Barpathar' },
  { label: 'Barpeta', value: 'Barpeta' },
  { label: 'Barpeta Road', value: 'Barpeta Road' },
  { label: 'Barquedo', value: 'Barquedo' },
  { label: 'Barqueiros', value: 'Barqueiros' },
  { label: 'Barquisimeto', value: 'Barquisimeto' },
  { label: 'Barr', value: 'Barr' },
  { label: 'Barra', value: 'Barra' },
  { label: 'Barra', value: 'Barra' },
  { label: 'Barra', value: 'Barra' },
  { label: 'Barra', value: 'Barra' },
  { label: 'Barra', value: 'Barra' },
  { label: 'Barra', value: 'Barra' },
  { label: 'Barra Bonita', value: 'Barra Bonita' },
  { label: 'Barra Bonita', value: 'Barra Bonita' },
  { label: 'Barra Da Estiva', value: 'Barra Da Estiva' },
  { label: 'Barra Dalcântara', value: 'Barra Dalcântara' },
  { label: 'Barra De Carrasco', value: 'Barra De Carrasco' },
  { label: 'Barra De Carrasco', value: 'Barra De Carrasco' },
  { label: 'Barra De Cazones', value: 'Barra De Cazones' },
  { label: 'Barra De Guabiraba', value: 'Barra De Guabiraba' },
  { label: 'Barra De Navidad', value: 'Barra De Navidad' },
  { label: 'Barra De Santa Rosa', value: 'Barra De Santa Rosa' },
  { label: 'Barra De Santana', value: 'Barra De Santana' },
  { label: 'Barra De Santo Antônio', value: 'Barra De Santo Antônio' },
  { label: 'Barra De São Francisco', value: 'Barra De São Francisco' },
  { label: 'Barra De São Miguel', value: 'Barra De São Miguel' },
  { label: 'Barra De São Miguel', value: 'Barra De São Miguel' },
  { label: 'Barra De Tecoanapa', value: 'Barra De Tecoanapa' },
  { label: 'Barra Do Bugres', value: 'Barra Do Bugres' },
  { label: 'Barra Do Chapéu', value: 'Barra Do Chapéu' },
  { label: 'Barra Do Choça', value: 'Barra Do Choça' },
  { label: 'Barra Do Corda', value: 'Barra Do Corda' },
  { label: 'Barra Do Garças', value: 'Barra Do Garças' },
  { label: 'Barra Do Guarita', value: 'Barra Do Guarita' },
  { label: 'Barra Do Jacaré', value: 'Barra Do Jacaré' },
  { label: 'Barra Do Mendes', value: 'Barra Do Mendes' },
  { label: 'Barra Do Ouro', value: 'Barra Do Ouro' },
  { label: 'Barra Do Piraí', value: 'Barra Do Piraí' },
  { label: 'Barra Do Quaraí', value: 'Barra Do Quaraí' },
  { label: 'Barra Do Ribeiro', value: 'Barra Do Ribeiro' },
  { label: 'Barra Do Rio Azul', value: 'Barra Do Rio Azul' },
  { label: 'Barra Do Rocha', value: 'Barra Do Rocha' },
  { label: 'Barra Do Turvo', value: 'Barra Do Turvo' },
  { label: 'Barra Dos Coqueiros', value: 'Barra Dos Coqueiros' },
  { label: 'Barra Funda', value: 'Barra Funda' },
  { label: 'Barra Longa', value: 'Barra Longa' },
  { label: 'Barra Mansa', value: 'Barra Mansa' },
  { label: 'Barra Patuca', value: 'Barra Patuca' },
  { label: 'Barra Velha', value: 'Barra Velha' },
  { label: 'Barraba', value: 'Barraba' },
  { label: 'Barracão', value: 'Barracão' },
  { label: 'Barracão', value: 'Barracão' },
  { label: 'Barracas', value: 'Barracas' },
  { label: 'Barracas', value: 'Barracas' },
  { label: 'Barrachina', value: 'Barrachina' },
  { label: 'Barrack Heights', value: 'Barrack Heights' },
  { label: 'Barrackpore', value: 'Barrackpore' },
  { label: 'Barrackville', value: 'Barrackville' },
  { label: 'Barraco', value: 'Barraco' },
  { label: 'Barraco El', value: 'Barraco El' },
  { label: 'Barrado', value: 'Barrado' },
  { label: 'Barrafranca', value: 'Barrafranca' },
  { label: 'Barrali', value: 'Barrali' },
  { label: 'Barranca', value: 'Barranca' },
  { label: 'Barranca De Los Laureles', value: 'Barranca De Los Laureles' },
  { label: 'Barranca De Otates', value: 'Barranca De Otates' },
  { label: 'Barranca De Santa Clara', value: 'Barranca De Santa Clara' },
  { label: 'Barranca De Upía', value: 'Barranca De Upía' },
  { label: 'Barranca Del Tule', value: 'Barranca Del Tule' },
  { label: 'Barrancabermeja', value: 'Barrancabermeja' },
  { label: 'Barrancas', value: 'Barrancas' },
  { label: 'Barrancas', value: 'Barrancas' },
  { label: 'Barrancas', value: 'Barrancas' },
  { label: 'Barranco', value: 'Barranco' },
  { label: 'Barranco Adentro', value: 'Barranco Adentro' },
  { label: 'Barranco Colorado', value: 'Barranco Colorado' },
  { label: 'Barranco De Loba', value: 'Barranco De Loba' },
  { label: 'Barranco Minas', value: 'Barranco Minas' },
  { label: 'Barrancos', value: 'Barrancos' },
  { label: 'Barranqueras', value: 'Barranqueras' },
  { label: 'Barranquilla', value: 'Barranquilla' },
  { label: 'Barranquitas', value: 'Barranquitas' },
  { label: 'Barranquitas', value: 'Barranquitas' },
  { label: 'Barras', value: 'Barras' },
  { label: 'Barraute', value: 'Barraute' },
  { label: 'Barraux', value: 'Barraux' },
  { label: 'Barrax', value: 'Barrax' },
  { label: 'Barre', value: 'Barre' },
  { label: 'Barre', value: 'Barre' },
  { label: 'Barre Denis', value: 'Barre Denis' },
  { label: 'Barre Duchaussee', value: 'Barre Duchaussee' },
  { label: 'Barre St.Joseph', value: 'Barre St.Joseph' },
  { label: 'Barrea', value: 'Barrea' },
  { label: 'Barreh Farakh', value: 'Barreh Farakh' },
  { label: 'Barreira', value: 'Barreira' },
  { label: 'Barreira', value: 'Barreira' },
  { label: 'Barreiras', value: 'Barreiras' },
  { label: 'Barreiras Do Piauí', value: 'Barreiras Do Piauí' },
  { label: 'Barreirinha', value: 'Barreirinha' },
  { label: 'Barreirinhas', value: 'Barreirinhas' },
  { label: 'Barreiro', value: 'Barreiro' },
  { label: 'Barreiro Do Jaíba', value: 'Barreiro Do Jaíba' },
  { label: 'Barreiros', value: 'Barreiros' },
  { label: 'Barreiros', value: 'Barreiros' },
  { label: 'Barren County', value: 'Barren County' },
  { label: 'Barretal', value: 'Barretal' },
  { label: 'Barretos', value: 'Barretos' },
  { label: 'Barretos', value: 'Barretos' },
  { label: 'Barrett', value: 'Barrett' },
  { label: 'Barrett Town', value: 'Barrett Town' },
  { label: 'Barrhead', value: 'Barrhead' },
  { label: 'Barrhead', value: 'Barrhead' },
  { label: 'Barrie', value: 'Barrie' },
  { label: 'Barrière', value: 'Barrière' },
  { label: 'Barrika', value: 'Barrika' },
  { label: 'Barrington', value: 'Barrington' },
  { label: 'Barrington', value: 'Barrington' },
  { label: 'Barrington', value: 'Barrington' },
  { label: 'Barrington', value: 'Barrington' },
  { label: 'Barrington Hills', value: 'Barrington Hills' },
  { label: 'Barrinha', value: 'Barrinha' },
  { label: 'Barrio Aldamas', value: 'Barrio Aldamas' },
  { label: 'Barrio Aztlán', value: 'Barrio Aztlán' },
  { label: 'Barrio Bordo Nuevo', value: 'Barrio Bordo Nuevo' },
  { label: 'Barrio Chiquichuca', value: 'Barrio Chiquichuca' },
  { label: 'Barrio Cuarto (La Loma)', value: 'Barrio Cuarto (La Loma)' },
  { label: 'Barrio De Arriba De San Juan Xoconusco', value: 'Barrio De Arriba De San Juan Xoconusco' },
  { label: 'Barrio De Boyecha', value: 'Barrio De Boyecha' },
  { label: 'Barrio De Canales', value: 'Barrio De Canales' },
  { label: 'Barrio De Centro Del Cerrillo', value: 'Barrio De Centro Del Cerrillo' },
  { label: 'Barrio De Ensido', value: 'Barrio De Ensido' },
  { label: 'Barrio De España', value: 'Barrio De España' },
  { label: 'Barrio De Guadalupe', value: 'Barrio De Guadalupe' },
  { label: 'Barrio De Guadalupe Del Mezquitillo', value: 'Barrio De Guadalupe Del Mezquitillo' },
  { label: 'Barrio De Jalisco', value: 'Barrio De Jalisco' },
  { label: 'Barrio De Jesús Fracción Primera', value: 'Barrio De Jesús Fracción Primera' },
  { label: 'Barrio De La Barranca', value: 'Barrio De La Barranca' },
  { label: 'Barrio De La Concepción', value: 'Barrio De La Concepción' },
  { label: 'Barrio De México', value: 'Barrio De México' },
  { label: 'Barrio De Muñó', value: 'Barrio De Muñó' },
  { label: 'Barrio De Nuevo León', value: 'Barrio De Nuevo León' },
  { label: 'Barrio De Puentecillas', value: 'Barrio De Puentecillas' },
  { label: 'Barrio De Reyes', value: 'Barrio De Reyes' },
  { label: 'Barrio De San Isidro', value: 'Barrio De San Isidro' },
  { label: 'Barrio De San Juan', value: 'Barrio De San Juan' },
  { label: 'Barrio De San Miguel', value: 'Barrio De San Miguel' },
  { label: 'Barrio De San Miguel', value: 'Barrio De San Miguel' },
  { label: 'Barrio De San Pedro La Cabecera', value: 'Barrio De San Pedro La Cabecera' },
  { label: 'Barrio De San Ramón', value: 'Barrio De San Ramón' },
  { label: 'Barrio De Tapias', value: 'Barrio De Tapias' },
  { label: 'Barrio Del Cajón', value: 'Barrio Del Cajón' },
  { label: 'Barrio El Boncho', value: 'Barrio El Boncho' },
  { label: 'Barrio El Vivero', value: 'Barrio El Vivero' },
  { label: 'Barrio Guadalupe', value: 'Barrio Guadalupe' },
  { label: 'Barrio La Joya', value: 'Barrio La Joya' },
  { label: 'Barrio La Tenería', value: 'Barrio La Tenería' },
  { label: 'Barrio Los Tules', value: 'Barrio Los Tules' },
  { label: 'Barrio Nuevo', value: 'Barrio Nuevo' },
  { label: 'Barrio Nuevo De Los Muertos', value: 'Barrio Nuevo De Los Muertos' },
  { label: 'Barrio San Diego', value: 'Barrio San Diego' },
  { label: 'Barrio San Joaquín El Junco', value: 'Barrio San Joaquín El Junco' },
  { label: 'Barrio San Miguel Dorami', value: 'Barrio San Miguel Dorami' },
  { label: 'Barrio Santa Cruz', value: 'Barrio Santa Cruz' },
  { label: 'Barrio Sur', value: 'Barrio Sur' },
  { label: 'Barrio Tepetitlán Emilio Portes Gil', value: 'Barrio Tepetitlán Emilio Portes Gil' },
  { label: 'Barrio Tlatenco', value: 'Barrio Tlatenco' },
  { label: 'Barriopedro', value: 'Barriopedro' },
  { label: 'Barrios De Bureba Los', value: 'Barrios De Bureba Los' },
  { label: 'Barrios De Colina', value: 'Barrios De Colina' },
  { label: 'Barrios De Luna Los', value: 'Barrios De Luna Los' },
  { label: 'Barrios Los', value: 'Barrios Los' },
  { label: 'Barro', value: 'Barro' },
  { label: 'Barro', value: 'Barro' },
  { label: 'Barro Alto', value: 'Barro Alto' },
  { label: 'Barro Alto', value: 'Barro Alto' },
  { label: 'Barro Duro', value: 'Barro Duro' },
  { label: 'Barro Preto', value: 'Barro Preto' },
  { label: 'Barrocas', value: 'Barrocas' },
  { label: 'Barrolândia', value: 'Barrolândia' },
  { label: 'Barromán', value: 'Barromán' },
  { label: 'Barron', value: 'Barron' },
  { label: 'Barrón', value: 'Barrón' },
  { label: 'Barrón', value: 'Barrón' },
  { label: 'Barron County', value: 'Barron County' },
  { label: 'Barroquinha', value: 'Barroquinha' },
  { label: 'Barros Blancos', value: 'Barros Blancos' },
  { label: 'Barros Cassal', value: 'Barros Cassal' },
  { label: 'Barrosas', value: 'Barrosas' },
  { label: 'Barroso', value: 'Barroso' },
  { label: 'Barrouallie', value: 'Barrouallie' },
  { label: 'Barrow', value: 'Barrow' },
  { label: 'Barrow County', value: 'Barrow County' },
  { label: 'Barrow In Furness', value: 'Barrow In Furness' },
  { label: 'Barrow Upon Humber', value: 'Barrow Upon Humber' },
  { label: 'Barrow Upon Soar', value: 'Barrow Upon Soar' },
  { label: 'Barrowby', value: 'Barrowby' },
  { label: 'Barrowford', value: 'Barrowford' },
  { label: 'Barruecopardo', value: 'Barruecopardo' },
  { label: 'Barruelo De Santullán', value: 'Barruelo De Santullán' },
  { label: 'Barruelo Del Valle', value: 'Barruelo Del Valle' },
  { label: 'Barrundia', value: 'Barrundia' },
  { label: 'Barry', value: 'Barry' },
  { label: 'Barry', value: 'Barry' },
  { label: 'Barry County', value: 'Barry County' },
  { label: 'Barry County', value: 'Barry County' },
  { label: 'Bârsa', value: 'Bârsa' },
  { label: 'Barsac', value: 'Barsac' },
  { label: 'Barsana', value: 'Barsana' },
  { label: 'Bârsana', value: 'Bârsana' },
  { label: 'Bârsăneşti', value: 'Bârsăneşti' },
  { label: 'Bârsău De Sus', value: 'Bârsău De Sus' },
  { label: 'Barsbüttel', value: 'Barsbüttel' },
  { label: 'Bârseşti', value: 'Bârseşti' },
  { label: 'Barsi', value: 'Barsi' },
  { label: 'Barsingerhorn', value: 'Barsingerhorn' },
  { label: 'Barsinghausen', value: 'Barsinghausen' },
  { label: 'Barskiy Rayon', value: 'Barskiy Rayon' },
  { label: 'Bårslöv', value: 'Bårslöv' },
  { label: 'Bársonyos', value: 'Bársonyos' },
  { label: 'Barsovo', value: 'Barsovo' },
  { label: 'Barßel', value: 'Barßel' },
  { label: 'Barstow', value: 'Barstow' },
  { label: 'Barstow Heights', value: 'Barstow Heights' },
  { label: 'Barsuki', value: 'Barsuki' },
  { label: 'Barsukovskaya', value: 'Barsukovskaya' },
  { label: 'Bar-Sur-Aube', value: 'Bar-Sur-Aube' },
  { label: 'Bar-Sur-Seine', value: 'Bar-Sur-Seine' },
  { label: 'Bart', value: 'Bart' },
  { label: 'Barţ Al ‘Anān', value: 'Barţ Al ‘Anān' },
  { label: 'Bartenheim', value: 'Bartenheim' },
  { label: 'Bartenshagen-Parkentin', value: 'Bartenshagen-Parkentin' },
  { label: 'Barth', value: 'Barth' },
  { label: 'Bartholomä', value: 'Bartholomä' },
  { label: 'Bartholomäberg', value: 'Bartholomäberg' },
  { label: 'Bartholomew County', value: 'Bartholomew County' },
  { label: 'Bartica', value: 'Bartica' },
  { label: 'Barticești', value: 'Barticești' },
  { label: 'Bartiyah', value: 'Bartiyah' },
  { label: 'Bartlesville', value: 'Bartlesville' },
  { label: 'Bartlett', value: 'Bartlett' },
  { label: 'Bartlett', value: 'Bartlett' },
  { label: 'Bartlett', value: 'Bartlett' },
  { label: 'Bartlett', value: 'Bartlett' },
  { label: 'Bartley Green', value: 'Bartley Green' },
  { label: 'Bartniczka', value: 'Bartniczka' },
  { label: 'Bartolo', value: 'Bartolo' },
  { label: 'Bartolomé Masó', value: 'Bartolomé Masó' },
  { label: 'Barton', value: 'Barton' },
  { label: 'Barton', value: 'Barton' },
  { label: 'Barton County', value: 'Barton County' },
  { label: 'Barton County', value: 'Barton County' },
  { label: 'Barton Creek', value: 'Barton Creek' },
  { label: 'Barton Under Needwood', value: 'Barton Under Needwood' },
  { label: 'Barton Upon Humber', value: 'Barton Upon Humber' },
  { label: 'Barton-Le-Clay', value: 'Barton-Le-Clay' },
  { label: 'Bartons', value: 'Bartons' },
  { label: 'Bartonsville', value: 'Bartonsville' },
  { label: 'Bartonville', value: 'Bartonville' },
  { label: 'Bartonville', value: 'Bartonville' },
  { label: 'Bartošovice', value: 'Bartošovice' },
  { label: 'Bartoszyce', value: 'Bartoszyce' },
  { label: 'Bartow', value: 'Bartow' },
  { label: 'Bartow County', value: 'Bartow County' },
  { label: 'Baru', value: 'Baru' },
  { label: 'Baru Mic', value: 'Baru Mic' },
  { label: 'Barucboc Norte', value: 'Barucboc Norte' },
  { label: 'Barucboc Norte', value: 'Barucboc Norte' },
  { label: 'Baruchella', value: 'Baruchella' },
  { label: 'Baruchowo', value: 'Baruchowo' },
  { label: 'Barueri', value: 'Barueri' },
  { label: 'Barugo', value: 'Barugo' },
  { label: 'Barugo', value: 'Barugo' },
  { label: 'Baruipur', value: 'Baruipur' },
  { label: 'Barum', value: 'Barum' },
  { label: 'Barumini', value: 'Barumini' },
  { label: 'Barunga West', value: 'Barunga West' },
  { label: 'Baruni', value: 'Baruni' },
  { label: 'Baruny', value: 'Baruny' },
  { label: 'Baruq', value: 'Baruq' },
  { label: 'Barurao', value: 'Barurao' },
  { label: 'Barurao', value: 'Barurao' },
  { label: 'Baruta', value: 'Baruta' },
  { label: 'Baruth', value: 'Baruth' },
  { label: 'Baruun-Urt', value: 'Baruun-Urt' },
  { label: 'Barva', value: 'Barva' },
  { label: 'Barver', value: 'Barver' },
  { label: 'Barview', value: 'Barview' },
  { label: 'Barvikha', value: 'Barvikha' },
  { label: 'Barvinkove', value: 'Barvinkove' },
  { label: 'Barwadih', value: 'Barwadih' },
  { label: 'Barwala', value: 'Barwala' },
  { label: 'Barwałd Górny', value: 'Barwałd Górny' },
  { label: 'Barwałd Średni', value: 'Barwałd Średni' },
  { label: 'Barwani', value: 'Barwani' },
  { label: 'Barwar (Lakhimpur Kheri)', value: 'Barwar (Lakhimpur Kheri)' },
  { label: 'Barwedel', value: 'Barwedel' },
  { label: 'Barwice', value: 'Barwice' },
  { label: 'Barwies', value: 'Barwies' },
  { label: 'Barwon Heads', value: 'Barwon Heads' },
  { label: 'Barx', value: 'Barx' },
  { label: 'Barxeta', value: 'Barxeta' },
  { label: 'Baryatino', value: 'Baryatino' },
  { label: 'Baryatinskiy Rayon', value: 'Baryatinskiy Rayon' },
  { label: 'Barybino', value: 'Barybino' },
  { label: 'Barysaw', value: 'Barysaw' },
  { label: 'Barysawski Rayon', value: 'Barysawski Rayon' },
  { label: 'Barysh', value: 'Barysh' },
  { label: 'Baryshevo', value: 'Baryshevo' },
  { label: 'Baryshivka', value: 'Baryshivka' },
  { label: 'Baryshivs’Kyy Rayon', value: 'Baryshivs’Kyy Rayon' },
  { label: 'Barza', value: 'Barza' },
  { label: 'Barza', value: 'Barza' },
  { label: 'Bârza', value: 'Bârza' },
  { label: 'Barzago', value: 'Barzago' },
  { label: 'Barzah', value: 'Barzah' },
  { label: 'Barzana', value: 'Barzana' },
  { label: 'Barzanò', value: 'Barzanò' },
  { label: 'Barzas', value: 'Barzas' },
  { label: 'Bârzava', value: 'Bârzava' },
  { label: 'Bârzești', value: 'Bârzești' },
  { label: 'Barzio', value: 'Barzio' },
  { label: 'Barzok', value: 'Barzok' },
  { label: 'Barzul', value: 'Barzul' },
  { label: 'Baş Göynük', value: 'Baş Göynük' },
  { label: 'Basa', value: 'Basa' },
  { label: 'Basa', value: 'Basa' },
  { label: 'Basaburua', value: 'Basaburua' },
  { label: 'Basag', value: 'Basag' },
  { label: 'Bašaid', value: 'Bašaid' },
  { label: 'Basail', value: 'Basail' },
  { label: 'Basak', value: 'Basak' },
  { label: 'Basak', value: 'Basak' },
  { label: 'Basak', value: 'Basak' },
  { label: 'Basak', value: 'Basak' },
  { label: 'Başakşehir', value: 'Başakşehir' },
  { label: 'Basaldella', value: 'Basaldella' },
  { label: 'Basalt', value: 'Basalt' },
  { label: 'Basaluzzo', value: 'Basaluzzo' },
  { label: 'Basanti', value: 'Basanti' },
  { label: 'Basar', value: 'Basar' },
  { label: 'Basarabeasca', value: 'Basarabeasca' },
  { label: 'Basarabi', value: 'Basarabi' },
  { label: 'Basarabi', value: 'Basarabi' },
  { label: 'Basardilla', value: 'Basardilla' },
  { label: 'Basaseachic', value: 'Basaseachic' },
  { label: 'Basauri', value: 'Basauri' },
  { label: 'Basavakalyan', value: 'Basavakalyan' },
  { label: 'Basavana Bagevadi', value: 'Basavana Bagevadi' },
  { label: 'Bāsawul', value: 'Bāsawul' },
  { label: 'Basay', value: 'Basay' },
  { label: 'Bâsca Chiojdului', value: 'Bâsca Chiojdului' },
  { label: 'Bâsca Rozilei', value: 'Bâsca Rozilei' },
  { label: 'Bascapè', value: 'Bascapè' },
  { label: 'Bàscara', value: 'Bàscara' },
  { label: 'Bascaron', value: 'Bascaron' },
  { label: 'Bâscenii De Jos', value: 'Bâscenii De Jos' },
  { label: 'Bâscenii De Sus', value: 'Bâscenii De Sus' },
  { label: 'Bascharage', value: 'Bascharage' },
  { label: 'Baschi', value: 'Baschi' },
  { label: 'Baschurch', value: 'Baschurch' },
  { label: 'Basciano', value: 'Basciano' },
  { label: 'Başçiftlik', value: 'Başçiftlik' },
  { label: 'Basco', value: 'Basco' },
  { label: 'Basco', value: 'Basco' },
  { label: 'Basconcillos Del Tozo', value: 'Basconcillos Del Tozo' },
  { label: 'Basconcobe', value: 'Basconcobe' },
  { label: 'Báscones De Ojeda', value: 'Báscones De Ojeda' },
  { label: 'Başcov', value: 'Başcov' },
  { label: 'Bâscoveni', value: 'Bâscoveni' },
  { label: 'Bascuñana', value: 'Bascuñana' },
  { label: 'Bascuñana De San Pedro', value: 'Bascuñana De San Pedro' },
  { label: 'Basdahl', value: 'Basdahl' },
  { label: 'Basdiot', value: 'Basdiot' },
  { label: 'Basehor', value: 'Basehor' },
  { label: 'Basel', value: 'Basel' },
  { label: 'Baselga Di Pinè', value: 'Baselga Di Pinè' },
  { label: 'Baselice', value: 'Baselice' },
  { label: 'Basen', value: 'Basen' },
  { label: 'Bas-En-Basset', value: 'Bas-En-Basset' },
  { label: 'Băseşti', value: 'Băseşti' },
  { label: 'Basey', value: 'Basey' },
  { label: 'Basey', value: 'Basey' },
  { label: 'Basford', value: 'Basford' },
  { label: 'Bashagard', value: 'Bashagard' },
  { label: 'Bashanet', value: 'Bashanet' },
  { label: 'Bashkia Berat', value: 'Bashkia Berat' },
  { label: 'Bashkia Bulqizë', value: 'Bashkia Bulqizë' },
  { label: 'Bashkia Devoll', value: 'Bashkia Devoll' },
  { label: 'Bashkia Divjakë', value: 'Bashkia Divjakë' },
  { label: 'Bashkia Dropull', value: 'Bashkia Dropull' },
  { label: 'Bashkia Durrës', value: 'Bashkia Durrës' },
  { label: 'Bashkia Fier', value: 'Bashkia Fier' },
  { label: 'Bashkia Finiq', value: 'Bashkia Finiq' },
  { label: 'Bashkia Himarë', value: 'Bashkia Himarë' },
  { label: 'Bashkia Kavajë', value: 'Bashkia Kavajë' },
  { label: 'Bashkia Kelcyrë', value: 'Bashkia Kelcyrë' },
  { label: 'Bashkia Klos', value: 'Bashkia Klos' },
  { label: 'Bashkia Kolonjë', value: 'Bashkia Kolonjë' },
  { label: 'Bashkia Konispol', value: 'Bashkia Konispol' },
  { label: 'Bashkia Krujë', value: 'Bashkia Krujë' },
  { label: 'Bashkia Kuçovë', value: 'Bashkia Kuçovë' },
  { label: 'Bashkia Kurbin', value: 'Bashkia Kurbin' },
  { label: 'Bashkia Lezhë', value: 'Bashkia Lezhë' },
  { label: 'Bashkia Libohovë', value: 'Bashkia Libohovë' },
  { label: 'Bashkia Malësi E Madhe', value: 'Bashkia Malësi E Madhe' },
  { label: 'Bashkia Maliq', value: 'Bashkia Maliq' },
  { label: 'Bashkia Mallakastër', value: 'Bashkia Mallakastër' },
  { label: 'Bashkia Mat', value: 'Bashkia Mat' },
  { label: 'Bashkia Memaliaj', value: 'Bashkia Memaliaj' },
  { label: 'Bashkia Mirditë', value: 'Bashkia Mirditë' },
  { label: 'Bashkia Patos', value: 'Bashkia Patos' },
  { label: 'Bashkia Përmet', value: 'Bashkia Përmet' },
  { label: 'Bashkia Poliçan', value: 'Bashkia Poliçan' },
  { label: 'Bashkia Pukë', value: 'Bashkia Pukë' },
  { label: 'Bashkia Pustec', value: 'Bashkia Pustec' },
  { label: 'Bashkia Selenicë', value: 'Bashkia Selenicë' },
  { label: 'Bashkia Shijak', value: 'Bashkia Shijak' },
  { label: 'Bashkia Skrapar', value: 'Bashkia Skrapar' },
  { label: 'Bashkia Tepelenë', value: 'Bashkia Tepelenë' },
  { label: 'Bashkia Vau I Dejës', value: 'Bashkia Vau I Dejës' },
  { label: 'Bashkia Vlorë', value: 'Bashkia Vlorë' },
  { label: 'Bashkia Vorë', value: 'Bashkia Vorë' },
  { label: 'Bashm', value: 'Bashm' },
  { label: 'Bashmakovo', value: 'Bashmakovo' },
  { label: 'Bashqurtaran', value: 'Bashqurtaran' },
  { label: 'Basht', value: 'Basht' },
  { label: 'Bashtanka', value: 'Bashtanka' },
  { label: 'Basi', value: 'Basi' },
  { label: 'Basiad', value: 'Basiad' },
  { label: 'Basiano', value: 'Basiano' },
  { label: 'Basiao', value: 'Basiao' },
  { label: 'Basiawan', value: 'Basiawan' },
  { label: 'Basiawan', value: 'Basiawan' },
  { label: 'Basiawan', value: 'Basiawan' },
  { label: 'Basicao Coastal', value: 'Basicao Coastal' },
  { label: 'Basicò', value: 'Basicò' },
  { label: 'Basiglio', value: 'Basiglio' },
  { label: 'Basildon', value: 'Basildon' },
  { label: 'Basile', value: 'Basile' },
  { label: 'Basiliano', value: 'Basiliano' },
  { label: 'Basiliano-Vissandone', value: 'Basiliano-Vissandone' },
  { label: 'Basilicagoiano', value: 'Basilicagoiano' },
  { label: 'Basilicanova', value: 'Basilicanova' },
  { label: 'Basilisa', value: 'Basilisa' },
  { label: 'Basilisa', value: 'Basilisa' },
  { label: 'Basin', value: 'Basin' },
  { label: 'Basin City', value: 'Basin City' },
  { label: 'Basin View', value: 'Basin View' },
  { label: 'Basing', value: 'Basing' },
  { label: 'Basing', value: 'Basing' },
  { label: 'Basingstoke', value: 'Basingstoke' },
  { label: 'Basirhat', value: 'Basirhat' },
  { label: 'Basirpur', value: 'Basirpur' },
  { label: 'Başiskele', value: 'Başiskele' },
  { label: 'Basista', value: 'Basista' },
  { label: 'Basista', value: 'Basista' },
  { label: 'Baška', value: 'Baška' },
  { label: 'Baška', value: 'Baška' },
  { label: 'Baška Voda', value: 'Baška Voda' },
  { label: 'Başkale', value: 'Başkale' },
  { label: 'Baskil', value: 'Baskil' },
  { label: 'Baslow', value: 'Baslow' },
  { label: 'Başmakçı', value: 'Başmakçı' },
  { label: 'Basmat', value: 'Basmat' },
  { label: 'Basmat Ṭab‘Ūn', value: 'Basmat Ṭab‘Ūn' },
  { label: 'Basmenj', value: 'Basmenj' },
  { label: 'Basna', value: 'Basna' },
  { label: 'Basni', value: 'Basni' },
  { label: 'Basoda', value: 'Basoda' },
  { label: 'Basoko', value: 'Basoko' },
  { label: 'Basotu', value: 'Basotu' },
  { label: 'Basqal', value: 'Basqal' },
  { label: 'Basrah', value: 'Basrah' },
  { label: 'Bas-Rhin', value: 'Bas-Rhin' },
  { label: 'Bass Coast', value: 'Bass Coast' },
  { label: 'Bass Hill', value: 'Bass Hill' },
  { label: 'Bass Lake', value: 'Bass Lake' },
  { label: 'Bas-Saint-Laurent', value: 'Bas-Saint-Laurent' },
  { label: 'Bassan', value: 'Bassan' },
  { label: 'Bassano', value: 'Bassano' },
  { label: 'Bassano Bresciano', value: 'Bassano Bresciano' },
  { label: 'Bassano Del Grappa', value: 'Bassano Del Grappa' },
  { label: 'Bassano In Teverina', value: 'Bassano In Teverina' },
  { label: 'Bassano Romano', value: 'Bassano Romano' },
  { label: 'Bassar', value: 'Bassar' },
  { label: 'Basse Lasne', value: 'Basse Lasne' },
  { label: 'Basse Santa Su', value: 'Basse Santa Su' },
  { label: 'Bassecourt', value: 'Bassecourt' },
  { label: 'Basse-Goulaine', value: 'Basse-Goulaine' },
  { label: 'Basse-Ham', value: 'Basse-Ham' },
  { label: 'Bassella', value: 'Bassella' },
  { label: 'Bassendean', value: 'Bassendean' },
  { label: 'Basse-Nendaz', value: 'Basse-Nendaz' },
  { label: 'Bassenge', value: 'Bassenge' },
  { label: 'Bassenheim', value: 'Bassenheim' },
  { label: 'Bassens', value: 'Bassens' },
  { label: 'Bassens', value: 'Bassens' },
  { label: 'Bassersdorf', value: 'Bassersdorf' },
  { label: 'Bassett', value: 'Bassett' },
  { label: 'Bassett', value: 'Bassett' },
  { label: 'Bassiano', value: 'Bassiano' },
  { label: 'Bassignana', value: 'Bassignana' },
  { label: 'Bassila', value: 'Bassila' },
  { label: 'Bassillac', value: 'Bassillac' },
  { label: 'Bassingbourn', value: 'Bassingbourn' },
  { label: 'Bassingham', value: 'Bassingham' },
  { label: 'Bassone', value: 'Bassone' },
  { label: 'Bassum', value: 'Bassum' },
  { label: 'Bassussarry', value: 'Bassussarry' },
  { label: 'Båstad', value: 'Båstad' },
  { label: 'Bastak', value: 'Bastak' },
  { label: 'Bastam', value: 'Bastam' },
  { label: 'Bastan', value: 'Bastan' },
  { label: 'Bastar', value: 'Bastar' },
  { label: 'Bastardo', value: 'Bastardo' },
  { label: 'Bastheim', value: 'Bastheim' },
  { label: 'Basti', value: 'Basti' },
  { label: 'Basti Dosa', value: 'Basti Dosa' },
  { label: 'Bastia', value: 'Bastia' },
  { label: 'Bastia', value: 'Bastia' },
  { label: 'Bastia Mondovì', value: 'Bastia Mondovì' },
  { label: 'Bastia Umbra', value: 'Bastia Umbra' },
  { label: 'Bastida De Dossi', value: 'Bastida De Dossi' },
  { label: 'Bastida La', value: 'Bastida La' },
  { label: 'Bastida Pancarana', value: 'Bastida Pancarana' },
  { label: 'Bastiglia', value: 'Bastiglia' },
  { label: 'Bastimentos', value: 'Bastimentos' },
  { label: 'Bastogne', value: 'Bastogne' },
  { label: 'Baston', value: 'Baston' },
  { label: 'Bastorf', value: 'Bastorf' },
  { label: 'Bastos', value: 'Bastos' },
  { label: 'Bastrop', value: 'Bastrop' },
  { label: 'Bastrop', value: 'Bastrop' },
  { label: 'Bastrop County', value: 'Bastrop County' },
  { label: 'Basúchil', value: 'Basúchil' },
  { label: 'Basud', value: 'Basud' },
  { label: 'Basud', value: 'Basud' },
  { label: 'Basud', value: 'Basud' },
  { label: 'Basudebpur', value: 'Basudebpur' },
  { label: 'Basugaon', value: 'Basugaon' },
  { label: 'Basuo', value: 'Basuo' },
  { label: 'Bas-Vully', value: 'Bas-Vully' },
  { label: 'Baswa', value: 'Baswa' },
  { label: 'Başyayla', value: 'Başyayla' },
  { label: 'Basye', value: 'Basye' },
  { label: 'Basyūn', value: 'Basyūn' },
  { label: 'Bat Khela', value: 'Bat Khela' },
  { label: 'Bat Yam', value: 'Bat Yam' },
  { label: 'Bata', value: 'Bata' },
  { label: 'Bata', value: 'Bata' },
  { label: 'Bata', value: 'Bata' },
  { label: 'Báta', value: 'Báta' },
  { label: 'Batabanó', value: 'Batabanó' },
  { label: 'Batac', value: 'Batac' },
  { label: 'Batac City', value: 'Batac City' },
  { label: 'Batad', value: 'Batad' },
  { label: 'Batagay', value: 'Batagay' },
  { label: 'Batagay-Alyta', value: 'Batagay-Alyta' },
  { label: 'Bataguassu', value: 'Bataguassu' },
  { label: 'Bataiporã', value: 'Bataiporã' },
  { label: 'Batak', value: 'Batak' },
  { label: 'Batal', value: 'Batal' },
  { label: 'Batal', value: 'Batal' },
  { label: 'Batal’Noye', value: 'Batal’Noye' },
  { label: 'Batala', value: 'Batala' },
  { label: 'Batalha', value: 'Batalha' },
  { label: 'Batalha', value: 'Batalha' },
  { label: 'Batalha', value: 'Batalha' },
  { label: 'Batallas', value: 'Batallas' },
  { label: 'Batam', value: 'Batam' },
  { label: 'Batamshinskiy', value: 'Batamshinskiy' },
  { label: 'Batan', value: 'Batan' },
  { label: 'Batán', value: 'Batán' },
  { label: 'Batana', value: 'Batana' },
  { label: 'Batang', value: 'Batang' },
  { label: 'Batang Berjuntai', value: 'Batang Berjuntai' },
  { label: 'Batangafo', value: 'Batangafo' },
  { label: 'Batangas', value: 'Batangas' },
  { label: 'Băţanii Mari', value: 'Băţanii Mari' },
  { label: 'Batanovtsi', value: 'Batanovtsi' },
  { label: 'Bataquil', value: 'Bataquil' },
  { label: 'Bataquil', value: 'Bataquil' },
  { label: 'Batăr', value: 'Batăr' },
  { label: 'Batarasa', value: 'Batarasa' },
  { label: 'Batârăşti', value: 'Batârăşti' },
  { label: 'Bătarci', value: 'Bătarci' },
  { label: 'Batas', value: 'Batas' },
  { label: 'Batasan', value: 'Batasan' },
  { label: 'Batasan', value: 'Batasan' },
  { label: 'Batasan', value: 'Batasan' },
  { label: 'Batasan Bata', value: 'Batasan Bata' },
  { label: 'Batasan Bata', value: 'Batasan Bata' },
  { label: 'Bátaszék', value: 'Bátaszék' },
  { label: 'Batatais', value: 'Batatais' },
  { label: 'Batavia', value: 'Batavia' },
  { label: 'Batavia', value: 'Batavia' },
  { label: 'Batavia', value: 'Batavia' },
  { label: 'Bataween', value: 'Bataween' },
  { label: 'Batayporã', value: 'Batayporã' },
  { label: 'Bataysk', value: 'Bataysk' },
  { label: 'Batea', value: 'Batea' },
  { label: 'Bateau Bay', value: 'Bateau Bay' },
  { label: 'Batehaven', value: 'Batehaven' },
  { label: 'Batelov', value: 'Batelov' },
  { label: 'Bateman', value: 'Bateman' },
  { label: 'Batemans Bay', value: 'Batemans Bay' },
  { label: 'Bateria', value: 'Bateria' },
  { label: 'Baterno', value: 'Baterno' },
  { label: 'Bates County', value: 'Bates County' },
  { label: 'Batesburg', value: 'Batesburg' },
  { label: 'Batesburg-Leesville', value: 'Batesburg-Leesville' },
  { label: 'Bătești', value: 'Bătești' },
  { label: 'Batesville', value: 'Batesville' },
  { label: 'Batesville', value: 'Batesville' },
  { label: 'Batesville', value: 'Batesville' },
  { label: 'Batesville', value: 'Batesville' },
  { label: 'Batetskiy', value: 'Batetskiy' },
  { label: 'Batetskiy Rayon', value: 'Batetskiy Rayon' },
  { label: 'Bath', value: 'Bath' },
  { label: 'Bath', value: 'Bath' },
  { label: 'Bath', value: 'Bath' },
  { label: 'Bath', value: 'Bath' },
  { label: 'Bath', value: 'Bath' },
  { label: 'Bath', value: 'Bath' },
  { label: 'Bath', value: 'Bath' },
  { label: 'Bath', value: 'Bath' },
  { label: 'Bath And North East Somerset', value: 'Bath And North East Somerset' },
  { label: 'Bath Beach', value: 'Bath Beach' },
  { label: 'Bath County', value: 'Bath County' },
  { label: 'Bath County', value: 'Bath County' },
  { label: 'Bath Mountain', value: 'Bath Mountain' },
  { label: 'Bathgate', value: 'Bathgate' },
  { label: 'Bathsheba', value: 'Bathsheba' },
  { label: 'Bathurst', value: 'Bathurst' },
  { label: 'Bathurst', value: 'Bathurst' },
  { label: 'Bathurst City Centre', value: 'Bathurst City Centre' },
  { label: 'Bathurst Regional', value: 'Bathurst Regional' },
  { label: 'Batī', value: 'Batī' },
  { label: 'Batiano', value: 'Batiano' },
  { label: 'Batiano', value: 'Batiano' },
  { label: 'Batiano', value: 'Batiano' },
  { label: 'Batibo', value: 'Batibo' },
  { label: 'Batié', value: 'Batié' },
  { label: 'Batifa', value: 'Batifa' },
  { label: 'Batilly', value: 'Batilly' },
  { label: 'Batinci', value: 'Batinci' },
  { label: 'Batiovo', value: 'Batiovo' },
  { label: 'Batitang', value: 'Batitang' },
  { label: 'Batitang', value: 'Batitang' },
  { label: 'Batken', value: 'Batken' },
  { label: 'Batley', value: 'Batley' },
  { label: 'Batlow', value: 'Batlow' },
  { label: 'Batna', value: 'Batna' },
  { label: 'Batnfjordsøra', value: 'Batnfjordsøra' },
  { label: 'Bato', value: 'Bato' },
  { label: 'Bato', value: 'Bato' },
  { label: 'Bato', value: 'Bato' },
  { label: 'Bato', value: 'Bato' },
  { label: 'Bato', value: 'Bato' },
  { label: 'Bato', value: 'Bato' },
  { label: 'Bato', value: 'Bato' },
  { label: 'Bato', value: 'Bato' },
  { label: 'Bato', value: 'Bato' },
  { label: 'Bato Bato', value: 'Bato Bato' },
  { label: 'Bato Bato', value: 'Bato Bato' },
  { label: 'Batobalane', value: 'Batobalane' },
  { label: 'Batobato', value: 'Batobato' },
  { label: 'Batobato', value: 'Batobato' },
  { label: 'Batobato', value: 'Batobato' },
  { label: 'Baton Rouge', value: 'Baton Rouge' },
  { label: 'Bátonyterenye', value: 'Bátonyterenye' },
  { label: 'Bátonyterenyei Járás', value: 'Bátonyterenyei Járás' },
  { label: 'Batopilas', value: 'Batopilas' },
  { label: 'Batorz', value: 'Batorz' },
  { label: 'Batoș', value: 'Batoș' },
  { label: 'Batoti', value: 'Batoti' },
  { label: 'Batouri', value: 'Batouri' },
  { label: 'Bătrâna', value: 'Bătrâna' },
  { label: 'Bătrâni', value: 'Bătrâni' },
  { label: 'Batres', value: 'Batres' },
  { label: 'Batrina', value: 'Batrina' },
  { label: 'Batroûn', value: 'Batroûn' },
  { label: 'Batschuns', value: 'Batschuns' },
  { label: 'Båtsfjord', value: 'Båtsfjord' },
  { label: 'Battaglia Terme', value: 'Battaglia Terme' },
  { label: 'Battaglione-Bagnara', value: 'Battaglione-Bagnara' },
  { label: 'Battagram', value: 'Battagram' },
  { label: 'Battagram District', value: 'Battagram District' },
  { label: 'Battalgazi', value: 'Battalgazi' },
  { label: 'Battambang', value: 'Battambang' },
  { label: 'Battaramulla South', value: 'Battaramulla South' },
  { label: 'Battenberg', value: 'Battenberg' },
  { label: 'Battenheim', value: 'Battenheim' },
  { label: 'Bätterkinden', value: 'Bätterkinden' },
  { label: 'Battersea', value: 'Battersea' },
  { label: 'Battery Hill', value: 'Battery Hill' },
  { label: 'Battery Point', value: 'Battery Point' },
  { label: 'Batticaloa', value: 'Batticaloa' },
  { label: 'Batticaloa District', value: 'Batticaloa District' },
  { label: 'Battifolle-Ruscello-Poggiola', value: 'Battifolle-Ruscello-Poggiola' },
  { label: 'Battifollo', value: 'Battifollo' },
  { label: 'Battipaglia', value: 'Battipaglia' },
  { label: 'Battle', value: 'Battle' },
  { label: 'Battle Creek', value: 'Battle Creek' },
  { label: 'Battle Creek', value: 'Battle Creek' },
  { label: 'Battle Ground', value: 'Battle Ground' },
  { label: 'Battle Ground', value: 'Battle Ground' },
  { label: 'Battle Mountain', value: 'Battle Mountain' },
  { label: 'Battlefield', value: 'Battlefield' },
  { label: 'Battlement Mesa', value: 'Battlement Mesa' },
  { label: 'Battonya', value: 'Battonya' },
  { label: 'Battuda', value: 'Battuda' },
  { label: 'Battung', value: 'Battung' },
  { label: 'Battung', value: 'Battung' },
  { label: 'Batu', value: 'Batu' },
  { label: 'Batu', value: 'Batu' },
  { label: 'Batu Arang', value: 'Batu Arang' },
  { label: 'Batu Berendam', value: 'Batu Berendam' },
  { label: 'Batu Feringgi', value: 'Batu Feringgi' },
  { label: 'Batu Gajah', value: 'Batu Gajah' },
  { label: 'Batu Pahat', value: 'Batu Pahat' },
  { label: 'Batuan', value: 'Batuan' },
  { label: 'Batuan', value: 'Batuan' },
  { label: 'Batumi', value: 'Batumi' },
  { label: 'Baturaden', value: 'Baturaden' },
  { label: 'Baturaja', value: 'Baturaja' },
  { label: 'Baturinskaya', value: 'Baturinskaya' },
  { label: 'Baturité', value: 'Baturité' },
  { label: 'Baturyn', value: 'Baturyn' },
  { label: 'Batutitik', value: 'Batutitik' },
  { label: 'Batutitik', value: 'Batutitik' },
  { label: 'Bátya', value: 'Bátya' },
  { label: 'Batyrevo', value: 'Batyrevo' },
  { label: 'Batyrevskiy Rayon', value: 'Batyrevskiy Rayon' },
  { label: 'Batz-Sur-Mer', value: 'Batz-Sur-Mer' },
  { label: 'Bau', value: 'Bau' },
  { label: 'Bau', value: 'Bau' },
  { label: 'Bauan', value: 'Bauan' },
  { label: 'Bauan', value: 'Bauan' },
  { label: 'Bauan', value: 'Bauan' },
  { label: 'Bauang', value: 'Bauang' },
  { label: 'Bauang', value: 'Bauang' },
  { label: 'Baucau', value: 'Baucau' },
  { label: 'Bauchi', value: 'Bauchi' },
  { label: 'Baucina', value: 'Baucina' },
  { label: 'Baud', value: 'Baud' },
  { label: 'Baud', value: 'Baud' },
  { label: 'Baud', value: 'Baud' },
  { label: 'Baudenbach', value: 'Baudenbach' },
  { label: 'Baudette', value: 'Baudette' },
  { label: 'Baudh', value: 'Baudh' },
  { label: 'Bauen', value: 'Bauen' },
  { label: 'Baugé-En-Anjou', value: 'Baugé-En-Anjou' },
  { label: 'Baugo', value: 'Baugo' },
  { label: 'Baugy', value: 'Baugy' },
  { label: 'Baukau', value: 'Baukau' },
  { label: 'Bauko', value: 'Bauko' },
  { label: 'Bauko', value: 'Bauko' },
  { label: 'Bauladu', value: 'Bauladu' },
  { label: 'Baule', value: 'Baule' },
  { label: 'Baulkham Hills', value: 'Baulkham Hills' },
  { label: 'Baulne', value: 'Baulne' },
  { label: 'Baulon', value: 'Baulon' },
  { label: 'Bauma', value: 'Bauma' },
  { label: 'Baume-Les-Dames', value: 'Baume-Les-Dames' },
  { label: 'Baumgarten Bei Gnas', value: 'Baumgarten Bei Gnas' },
  { label: 'Baumholder', value: 'Baumholder' },
  { label: 'Baumkirchen', value: 'Baumkirchen' },
  { label: 'Baumschulenweg', value: 'Baumschulenweg' },
  { label: 'Baunach', value: 'Baunach' },
  { label: 'Baunatal', value: 'Baunatal' },
  { label: 'Bauné', value: 'Bauné' },
  { label: 'Baunei', value: 'Baunei' },
  { label: 'Baunu-Timbangan', value: 'Baunu-Timbangan' },
  { label: 'Baunu-Timbangan', value: 'Baunu-Timbangan' },
  { label: 'Bauru', value: 'Bauru' },
  { label: 'Bausen', value: 'Bausen' },
  { label: 'Bausendorf', value: 'Bausendorf' },
  { label: 'Bauska', value: 'Bauska' },
  { label: 'Bauta', value: 'Bauta' },
  { label: 'Băuţar', value: 'Băuţar' },
  { label: 'Baūtīno', value: 'Baūtīno' },
  { label: 'Bautista', value: 'Bautista' },
  { label: 'Bautista', value: 'Bautista' },
  { label: 'Bautista', value: 'Bautista' },
  { label: 'Bautista Chico', value: 'Bautista Chico' },
  { label: 'Bautzen', value: 'Bautzen' },
  { label: 'Bauvin', value: 'Bauvin' },
  { label: 'Bavanat', value: 'Bavanat' },
  { label: 'Bavans', value: 'Bavans' },
  { label: 'Bavay', value: 'Bavay' },
  { label: 'Bavdhan', value: 'Bavdhan' },
  { label: 'Bavel', value: 'Bavel' },
  { label: 'Baveno', value: 'Baveno' },
  { label: 'Bavent', value: 'Bavent' },
  { label: 'Bavi', value: 'Bavi' },
  { label: 'Bavigne', value: 'Bavigne' },
  { label: 'Bavilliers', value: 'Bavilliers' },
  { label: 'Bavispe', value: 'Bavispe' },
  { label: 'Bavleny', value: 'Bavleny' },
  { label: 'Bavlinskiy Rayon', value: 'Bavlinskiy Rayon' },
  { label: 'Bavly', value: 'Bavly' },
  { label: 'Bavois', value: 'Bavois' },
  { label: 'Bavorov', value: 'Bavorov' },
  { label: 'Bavtugay', value: 'Bavtugay' },
  { label: 'Baw Baw', value: 'Baw Baw' },
  { label: 'Bawal', value: 'Bawal' },
  { label: 'Bawali', value: 'Bawali' },
  { label: 'Bawana', value: 'Bawana' },
  { label: 'Bawanbir', value: 'Bawanbir' },
  { label: 'Bawcomville', value: 'Bawcomville' },
  { label: 'Bawinkel', value: 'Bawinkel' },
  { label: 'Bawison', value: 'Bawison' },
  { label: 'Bawison', value: 'Bawison' },
  { label: 'Bawku', value: 'Bawku' },
  { label: 'Bawshar', value: 'Bawshar' },
  { label: 'Bawtry', value: 'Bawtry' },
  { label: 'Baxley', value: 'Baxley' },
  { label: 'Baxter', value: 'Baxter' },
  { label: 'Baxter', value: 'Baxter' },
  { label: 'Baxter', value: 'Baxter' },
  { label: 'Baxter', value: 'Baxter' },
  { label: 'Baxter County', value: 'Baxter County' },
  { label: 'Baxter Estates', value: 'Baxter Estates' },
  { label: 'Baxter Mountain', value: 'Baxter Mountain' },
  { label: 'Baxter Springs', value: 'Baxter Springs' },
  { label: 'Bay', value: 'Bay' },
  { label: 'Bay', value: 'Bay' },
  { label: 'Bay City', value: 'Bay City' },
  { label: 'Bay City', value: 'Bay City' },
  { label: 'Bay City', value: 'Bay City' },
  { label: 'Bay County', value: 'Bay County' },
  { label: 'Bay County', value: 'Bay County' },
  { label: 'Bay Harbor', value: 'Bay Harbor' },
  { label: 'Bay Harbor Islands', value: 'Bay Harbor Islands' },
  { label: 'Bay Hill', value: 'Bay Hill' },
  { label: 'Bay Horse', value: 'Bay Horse' },
  { label: 'Bay Minette', value: 'Bay Minette' },
  { label: 'Bay Park', value: 'Bay Park' },
  { label: 'Bay Pines', value: 'Bay Pines' },
  { label: 'Bay Point', value: 'Bay Point' },
  { label: 'Bay Roberts', value: 'Bay Roberts' },
  { label: 'Bay Saint Louis', value: 'Bay Saint Louis' },
  { label: 'Bay Shore', value: 'Bay Shore' },
  { label: 'Bay Springs', value: 'Bay Springs' },
  { label: 'Bay St. George South', value: 'Bay St. George South' },
  { label: 'Bay View', value: 'Bay View' },
  { label: 'Bay Village', value: 'Bay Village' },
  { label: 'Bay Wood', value: 'Bay Wood' },
  { label: 'Bayaa', value: 'Bayaa' },
  { label: 'Bayabas', value: 'Bayabas' },
  { label: 'Bayabas', value: 'Bayabas' },
  { label: 'Bayabas', value: 'Bayabas' },
  { label: 'Bayabas', value: 'Bayabas' },
  { label: 'Bayaguana', value: 'Bayaguana' },
  { label: 'Bayambang', value: 'Bayambang' },
  { label: 'Bayambang', value: 'Bayambang' },
  { label: 'Bayamo', value: 'Bayamo' },
  { label: 'Bayamon', value: 'Bayamon' },
  { label: 'Bayamón', value: 'Bayamón' },
  { label: 'Bayamón', value: 'Bayamón' },
  { label: 'Bayan', value: 'Bayan' },
  { label: 'Bayān', value: 'Bayān' },
  { label: 'Bayan Nur', value: 'Bayan Nur' },
  { label: 'Bayana', value: 'Bayana' },
  { label: 'Bayanan', value: 'Bayanan' },
  { label: 'Bayanan', value: 'Bayanan' },
  { label: 'Bayanaul', value: 'Bayanaul' },
  { label: 'Bayanday', value: 'Bayanday' },
  { label: 'Bayandayevskiy Rayon', value: 'Bayandayevskiy Rayon' },
  { label: 'Bayang', value: 'Bayang' },
  { label: 'Bayang', value: 'Bayang' },
  { label: 'Bay-Ang', value: 'Bay-Ang' },
  { label: 'Bayanga', value: 'Bayanga' },
  { label: 'Bayanga', value: 'Bayanga' },
  { label: 'Bayangan', value: 'Bayangan' },
  { label: 'Bayangol', value: 'Bayangol' },
  { label: 'Bayanhongor', value: 'Bayanhongor' },
  { label: 'Bayannur Shi', value: 'Bayannur Shi' },
  { label: 'Bayano', value: 'Bayano' },
  { label: 'Bayaoas', value: 'Bayaoas' },
  { label: 'Bayaoas', value: 'Bayaoas' },
  { label: 'Bayárcal', value: 'Bayárcal' },
  { label: 'Bayard', value: 'Bayard' },
  { label: 'Bayard', value: 'Bayard' },
  { label: 'Bayard-Sur-Marne', value: 'Bayard-Sur-Marne' },
  { label: 'Bayarque', value: 'Bayarque' },
  { label: 'Bayas', value: 'Bayas' },
  { label: 'Bayasong', value: 'Bayasong' },
  { label: 'Bayasong', value: 'Bayasong' },
  { label: 'Bayat', value: 'Bayat' },
  { label: 'Bayat', value: 'Bayat' },
  { label: 'Bayawan', value: 'Bayawan' },
  { label: 'Baybay', value: 'Baybay' },
  { label: 'Baybay', value: 'Baybay' },
  { label: 'Baybay', value: 'Baybay' },
  { label: 'Baybayin', value: 'Baybayin' },
  { label: 'Bayboro', value: 'Bayboro' },
  { label: 'Baychester', value: 'Baychester' },
  { label: 'Bayenthal', value: 'Bayenthal' },
  { label: 'Bayerbach', value: 'Bayerbach' },
  { label: 'Bayerisch Eisenstein', value: 'Bayerisch Eisenstein' },
  { label: 'Bayerisch Gmain', value: 'Bayerisch Gmain' },
  { label: 'Bayeux', value: 'Bayeux' },
  { label: 'Bayeux', value: 'Bayeux' },
  { label: 'Bayevo', value: 'Bayevo' },
  { label: 'Bayfield', value: 'Bayfield' },
  { label: 'Bayfield County', value: 'Bayfield County' },
  { label: 'Bayg', value: 'Bayg' },
  { label: 'Bayganin', value: 'Bayganin' },
  { label: 'Bayhan', value: 'Bayhan' },
  { label: 'Bayındır', value: 'Bayındır' },
  { label: 'Bayingolin Mongol Zizhizhou', value: 'Bayingolin Mongol Zizhizhou' },
  { label: 'Bayjī', value: 'Bayjī' },
  { label: 'Baykal’Sk', value: 'Baykal’Sk' },
  { label: 'Baykalovo', value: 'Baykalovo' },
  { label: 'Baykan', value: 'Baykan' },
  { label: 'Bay-Khaak', value: 'Bay-Khaak' },
  { label: 'Baykit', value: 'Baykit' },
  { label: 'Baylo', value: 'Baylo' },
  { label: 'Baylo', value: 'Baylo' },
  { label: 'Baylo', value: 'Baylo' },
  { label: 'Baylor County', value: 'Baylor County' },
  { label: 'Baymak', value: 'Baymak' },
  { label: 'Baynjiwayn', value: 'Baynjiwayn' },
  { label: 'Baynton', value: 'Baynton' },
  { label: 'Bayog', value: 'Bayog' },
  { label: 'Bayombong', value: 'Bayombong' },
  { label: 'Bayombong', value: 'Bayombong' },
  { label: 'Bayon', value: 'Bayon' },
  { label: 'Bayonet Head', value: 'Bayonet Head' },
  { label: 'Bayonet Point', value: 'Bayonet Point' },
  { label: 'Bayonne', value: 'Bayonne' },
  { label: 'Bayonne', value: 'Bayonne' },
  { label: 'Bayou Cane', value: 'Bayou Cane' },
  { label: 'Bayou Gauche', value: 'Bayou Gauche' },
  { label: 'Bayou La Batre', value: 'Bayou La Batre' },
  { label: 'Bayou Vista', value: 'Bayou Vista' },
  { label: 'Bayou Vista', value: 'Bayou Vista' },
  { label: 'Bayport', value: 'Bayport' },
  { label: 'Bayport', value: 'Bayport' },
  { label: 'Bayrachky', value: 'Bayrachky' },
  { label: 'Bayraklı', value: 'Bayraklı' },
  { label: 'Bayramaly', value: 'Bayramaly' },
  { label: 'Bayramiç', value: 'Bayramiç' },
  { label: 'Bayramören', value: 'Bayramören' },
  { label: 'Bayrampaşa', value: 'Bayrampaşa' },
  { label: 'Bayranshahr', value: 'Bayranshahr' },
  { label: 'Bayreuth', value: 'Bayreuth' },
  { label: 'Bayrischzell', value: 'Bayrischzell' },
  { label: 'Bayshonas', value: 'Bayshonas' },
  { label: 'Bayshore', value: 'Bayshore' },
  { label: 'Bayshore Gardens', value: 'Bayshore Gardens' },
  { label: 'Bayside', value: 'Bayside' },
  { label: 'Bayside', value: 'Bayside' },
  { label: 'Bayside', value: 'Bayside' },
  { label: 'Bayside', value: 'Bayside' },
  { label: 'Bayside', value: 'Bayside' },
  { label: 'Bayston Hill', value: 'Bayston Hill' },
  { label: 'Bayswater', value: 'Bayswater' },
  { label: 'Bayswater', value: 'Bayswater' },
  { label: 'Bayswater', value: 'Bayswater' },
  { label: 'Bayswater North', value: 'Bayswater North' },
  { label: 'Bayt Al ‘Awābī', value: 'Bayt Al ‘Awābī' },
  { label: 'Bayt Al Faqīh', value: 'Bayt Al Faqīh' },
  { label: 'Bayt Īdis', value: 'Bayt Īdis' },
  { label: 'Bayt Yāfā', value: 'Bayt Yāfā' },
  { label: 'Baytown', value: 'Baytown' },
  { label: 'Bayubas De Abajo', value: 'Bayubas De Abajo' },
  { label: 'Bayubas De Arriba', value: 'Bayubas De Arriba' },
  { label: 'Bayugan', value: 'Bayugan' },
  { label: 'Bayugan', value: 'Bayugan' },
  { label: 'Bayuin', value: 'Bayuin' },
  { label: 'Bayunovskiye Klyuchi', value: 'Bayunovskiye Klyuchi' },
  { label: 'Bayview', value: 'Bayview' },
  { label: 'Bayview Heights', value: 'Bayview Heights' },
  { label: 'Bayville', value: 'Bayville' },
  { label: 'Bayville', value: 'Bayville' },
  { label: 'Bayzhansay', value: 'Bayzhansay' },
  { label: 'Baza', value: 'Baza' },
  { label: 'Bazainville', value: 'Bazainville' },
  { label: 'Bazaliya', value: 'Bazaliya' },
  { label: 'Bazancourt', value: 'Bazancourt' },
  { label: 'Bażanowice', value: 'Bażanowice' },
  { label: 'Bazar', value: 'Bazar' },
  { label: 'Bazar Jomeh', value: 'Bazar Jomeh' },
  { label: 'Bāzārak', value: 'Bāzārak' },
  { label: 'Bazargan', value: 'Bazargan' },
  { label: 'Bazar-Korgon', value: 'Bazar-Korgon' },
  { label: 'Bazarno-Karabulakskiy Rayon', value: 'Bazarno-Karabulakskiy Rayon' },
  { label: 'Bazarnyy Karabulak', value: 'Bazarnyy Karabulak' },
  { label: 'Bazarnyy Syzgan', value: 'Bazarnyy Syzgan' },
  { label: 'Bazarnyye Mataki', value: 'Bazarnyye Mataki' },
  { label: 'Bazartete', value: 'Bazartete' },
  { label: 'Bazas', value: 'Bazas' },
  { label: 'Bazega Province', value: 'Bazega Province' },
  { label: 'Bazeilles', value: 'Bazeilles' },
  { label: 'Bazemont', value: 'Bazemont' },
  { label: 'Bazeran', value: 'Bazeran' },
  { label: 'Bazet', value: 'Bazet' },
  { label: 'Bazhong Shi', value: 'Bazhong Shi' },
  { label: 'Bazhovo', value: 'Bazhovo' },
  { label: 'Baziège', value: 'Baziège' },
  { label: 'Bazile', value: 'Bazile' },
  { label: 'Bazmān', value: 'Bazmān' },
  { label: 'Bazna', value: 'Bazna' },
  { label: 'Bazoches-Les-Gallerandes', value: 'Bazoches-Les-Gallerandes' },
  { label: 'Bazoft', value: 'Bazoft' },
  { label: 'Bazoges-En-Pareds', value: 'Bazoges-En-Pareds' },
  { label: 'Bazou', value: 'Bazou' },
  { label: 'Bazouges-La-Pérouse', value: 'Bazouges-La-Pérouse' },
  { label: 'Bazouges-Sur-Le-Loir', value: 'Bazouges-Sur-Le-Loir' },
  { label: 'Bazpur', value: 'Bazpur' },
  { label: 'Baztan', value: 'Baztan' },
  { label: 'Bazum', value: 'Bazum' },
  { label: 'Bazzano', value: 'Bazzano' },
  { label: 'Bcharré', value: 'Bcharré' },
  { label: 'Bea', value: 'Bea' },
  { label: 'Beach', value: 'Beach' },
  { label: 'Beach City', value: 'Beach City' },
  { label: 'Beach City', value: 'Beach City' },
  { label: 'Beach Haven', value: 'Beach Haven' },
  { label: 'Beach Haven West', value: 'Beach Haven West' },
  { label: 'Beach Park', value: 'Beach Park' },
  { label: 'Beachlands', value: 'Beachlands' },
  { label: 'Beachmere', value: 'Beachmere' },
  { label: 'Beachwood', value: 'Beachwood' },
  { label: 'Beachwood', value: 'Beachwood' },
  { label: 'Beacon', value: 'Beacon' },
  { label: 'Beacon Hill', value: 'Beacon Hill' },
  { label: 'Beacon Square', value: 'Beacon Square' },
  { label: 'Beaconsfield', value: 'Beaconsfield' },
  { label: 'Beaconsfield', value: 'Beaconsfield' },
  { label: 'Beaconsfield', value: 'Beaconsfield' },
  { label: 'Beaconsfield', value: 'Beaconsfield' },
  { label: 'Beaconsfield', value: 'Beaconsfield' },
  { label: 'Beaconsfield', value: 'Beaconsfield' },
  { label: 'Beaconsfield Upper', value: 'Beaconsfield Upper' },
  { label: 'Beade', value: 'Beade' },
  { label: 'Beadle County', value: 'Beadle County' },
  { label: 'Beadnell', value: 'Beadnell' },
  { label: 'Beale Air Force Base', value: 'Beale Air Force Base' },
  { label: 'Bealeton', value: 'Bealeton' },
  { label: 'Beaminster', value: 'Beaminster' },
  { label: 'Beamud', value: 'Beamud' },
  { label: 'Bean Station', value: 'Bean Station' },
  { label: 'Beane Field', value: 'Beane Field' },
  { label: 'Bear', value: 'Bear' },
  { label: 'Bear Creek', value: 'Bear Creek' },
  { label: 'Bear Creek', value: 'Bear Creek' },
  { label: 'Bear Lake County', value: 'Bear Lake County' },
  { label: 'Bear Rocks', value: 'Bear Rocks' },
  { label: 'Bear Valley Springs', value: 'Bear Valley Springs' },
  { label: 'Beardstown', value: 'Beardstown' },
  { label: 'Beariz', value: 'Beariz' },
  { label: 'Bearna', value: 'Bearna' },
  { label: 'Bearsden', value: 'Bearsden' },
  { label: 'Beas', value: 'Beas' },
  { label: 'Beas De Granada', value: 'Beas De Granada' },
  { label: 'Beas De Guadix', value: 'Beas De Guadix' },
  { label: 'Beas De Segura', value: 'Beas De Segura' },
  { label: 'Beasain', value: 'Beasain' },
  { label: 'Beasejour/Myers Bridge', value: 'Beasejour/Myers Bridge' },
  { label: 'Beatenberg', value: 'Beatenberg' },
  { label: 'Beato', value: 'Beato' },
  { label: 'Beato António', value: 'Beato António' },
  { label: 'Beatrice', value: 'Beatrice' },
  { label: 'Beatrice', value: 'Beatrice' },
  { label: 'Beatty', value: 'Beatty' },
  { label: 'Beattystown', value: 'Beattystown' },
  { label: 'Beattyville', value: 'Beattyville' },
  { label: 'Beau Bassin', value: 'Beau Bassin' },
  { label: 'Beau Bassin-Rose Hill', value: 'Beau Bassin-Rose Hill' },
  { label: 'Beau Vallon', value: 'Beau Vallon' },
  { label: 'Beau Vallon', value: 'Beau Vallon' },
  { label: 'Beaucaire', value: 'Beaucaire' },
  { label: 'Beaucamps-Le-Vieux', value: 'Beaucamps-Le-Vieux' },
  { label: 'Beaucé', value: 'Beaucé' },
  { label: 'Beauceville', value: 'Beauceville' },
  { label: 'Beauchamp', value: 'Beauchamp' },
  { label: 'Beauchamp', value: 'Beauchamp' },
  { label: 'Beauchamps', value: 'Beauchamps' },
  { label: 'Beauchastel', value: 'Beauchastel' },
  { label: 'Beaucourt', value: 'Beaucourt' },
  { label: 'Beaucouzé', value: 'Beaucouzé' },
  { label: 'Beaucroissant', value: 'Beaucroissant' },
  { label: 'Beaudesert', value: 'Beaudesert' },
  { label: 'Beaufay', value: 'Beaufay' },
  { label: 'Beaufort', value: 'Beaufort' },
  { label: 'Beaufort', value: 'Beaufort' },
  { label: 'Beaufort', value: 'Beaufort' },
  { label: 'Beaufort', value: 'Beaufort' },
  { label: 'Beaufort', value: 'Beaufort' },
  { label: 'Beaufort', value: 'Beaufort' },
  { label: 'Beaufort', value: 'Beaufort' },
  { label: 'Beaufort County', value: 'Beaufort County' },
  { label: 'Beaufort County', value: 'Beaufort County' },
  { label: 'Beaufort West', value: 'Beaufort West' },
  { label: 'Beaufort-En-Vallée', value: 'Beaufort-En-Vallée' },
  { label: 'Beaugency', value: 'Beaugency' },
  { label: 'Beauharnois', value: 'Beauharnois' },
  { label: 'Beaujeu', value: 'Beaujeu' },
  { label: 'Beaulieu', value: 'Beaulieu' },
  { label: 'Beaulieu-Lès-Loches', value: 'Beaulieu-Lès-Loches' },
  { label: 'Beaulieu-Sous-La-Roche', value: 'Beaulieu-Sous-La-Roche' },
  { label: 'Beaulieu-Sur-Dordogne', value: 'Beaulieu-Sur-Dordogne' },
  { label: 'Beaulieu-Sur-Layon', value: 'Beaulieu-Sur-Layon' },
  { label: 'Beaulieu-Sur-Mer', value: 'Beaulieu-Sur-Mer' },
  { label: 'Beaulon', value: 'Beaulon' },
  { label: 'Beauly', value: 'Beauly' },
  { label: 'Beaumaris', value: 'Beaumaris' },
  { label: 'Beaumaris', value: 'Beaumaris' },
  { label: 'Beaumes-De-Venise', value: 'Beaumes-De-Venise' },
  { label: 'Beaumetz-Lès-Loges', value: 'Beaumetz-Lès-Loges' },
  { label: 'Beaumont', value: 'Beaumont' },
  { label: 'Beaumont', value: 'Beaumont' },
  { label: 'Beaumont', value: 'Beaumont' },
  { label: 'Beaumont', value: 'Beaumont' },
  { label: 'Beaumont', value: 'Beaumont' },
  { label: 'Beaumont', value: 'Beaumont' },
  { label: 'Beaumont', value: 'Beaumont' },
  { label: 'Beaumont', value: 'Beaumont' },
  { label: 'Beaumont Hills', value: 'Beaumont Hills' },
  { label: 'Beaumont-De-Lomagne', value: 'Beaumont-De-Lomagne' },
  { label: 'Beaumont-De-Pertuis', value: 'Beaumont-De-Pertuis' },
  { label: 'Beaumont-Du-Gâtinais', value: 'Beaumont-Du-Gâtinais' },
  { label: 'Beaumont-Du-Périgord', value: 'Beaumont-Du-Périgord' },
  { label: 'Beaumont-En-Véron', value: 'Beaumont-En-Véron' },
  { label: 'Beaumont-Hague', value: 'Beaumont-Hague' },
  { label: 'Beaumont-La-Ronce', value: 'Beaumont-La-Ronce' },
  { label: 'Beaumont-Le-Roger', value: 'Beaumont-Le-Roger' },
  { label: 'Beaumont-Lès-Valence', value: 'Beaumont-Lès-Valence' },
  { label: 'Beaumont-Monteux', value: 'Beaumont-Monteux' },
  { label: 'Beaumont-Sur-Lèze', value: 'Beaumont-Sur-Lèze' },
  { label: 'Beaumont-Sur-Oise', value: 'Beaumont-Sur-Oise' },
  { label: 'Beaumont-Sur-Sarthe', value: 'Beaumont-Sur-Sarthe' },
  { label: 'Beaune', value: 'Beaune' },
  { label: 'Beaune-La-Rolande', value: 'Beaune-La-Rolande' },
  { label: 'Beaupré', value: 'Beaupré' },
  { label: 'Beaupréau', value: 'Beaupréau' },
  { label: 'Beaupuy', value: 'Beaupuy' },
  { label: 'Beaupuy', value: 'Beaupuy' },
  { label: 'Beauquesne', value: 'Beauquesne' },
  { label: 'Beauraing', value: 'Beauraing' },
  { label: 'Beaurains', value: 'Beaurains' },
  { label: 'Beaurainville', value: 'Beaurainville' },
  { label: 'Beauregard Parish', value: 'Beauregard Parish' },
  { label: 'Beaurepaire', value: 'Beaurepaire' },
  { label: 'Beaurepaire', value: 'Beaurepaire' },
  { label: 'Beaurevoir', value: 'Beaurevoir' },
  { label: 'Beausejour', value: 'Beausejour' },
  { label: 'Beausejour', value: 'Beausejour' },
  { label: 'Beausejour', value: 'Beausejour' },
  { label: 'Beausejour/Fostins Development', value: 'Beausejour/Fostins Development' },
  { label: 'Beausejour/Ndc', value: 'Beausejour/Ndc' },
  { label: 'Beausemblant', value: 'Beausemblant' },
  { label: 'Beausoleil', value: 'Beausoleil' },
  { label: 'Beautiran', value: 'Beautiran' },
  { label: 'Beautor', value: 'Beautor' },
  { label: 'Beauty Point', value: 'Beauty Point' },
  { label: 'Beauvais', value: 'Beauvais' },
  { label: 'Beauval', value: 'Beauval' },
  { label: 'Beauvallon', value: 'Beauvallon' },
  { label: 'Beauvechain', value: 'Beauvechain' },
  { label: 'Beauvoir-De-Marc', value: 'Beauvoir-De-Marc' },
  { label: 'Beauvoir-Sur-Mer', value: 'Beauvoir-Sur-Mer' },
  { label: 'Beauvoir-Sur-Niort', value: 'Beauvoir-Sur-Niort' },
  { label: 'Beauvois-En-Cambrésis', value: 'Beauvois-En-Cambrésis' },
  { label: 'Beauvoisin', value: 'Beauvoisin' },
  { label: 'Beauzac', value: 'Beauzac' },
  { label: 'Beauzelle', value: 'Beauzelle' },
  { label: 'Beaver', value: 'Beaver' },
  { label: 'Beaver', value: 'Beaver' },
  { label: 'Beaver', value: 'Beaver' },
  { label: 'Beaver', value: 'Beaver' },
  { label: 'Beaver City', value: 'Beaver City' },
  { label: 'Beaver County', value: 'Beaver County' },
  { label: 'Beaver County', value: 'Beaver County' },
  { label: 'Beaver County', value: 'Beaver County' },
  { label: 'Beaver Dam', value: 'Beaver Dam' },
  { label: 'Beaver Dam', value: 'Beaver Dam' },
  { label: 'Beaver Dam', value: 'Beaver Dam' },
  { label: 'Beaver Dam Lake', value: 'Beaver Dam Lake' },
  { label: 'Beaver Falls', value: 'Beaver Falls' },
  { label: 'Beavercreek', value: 'Beavercreek' },
  { label: 'Beavercreek', value: 'Beavercreek' },
  { label: 'Beaverdale', value: 'Beaverdale' },
  { label: 'Beaverdam Lake-Salisbury Mills', value: 'Beaverdam Lake-Salisbury Mills' },
  { label: 'Beaverhead County', value: 'Beaverhead County' },
  { label: 'Beaverlodge', value: 'Beaverlodge' },
  { label: 'Beaverton', value: 'Beaverton' },
  { label: 'Beaverton', value: 'Beaverton' },
  { label: 'Beawar', value: 'Beawar' },
  { label: 'Beba Veche', value: 'Beba Veche' },
  { label: 'Bébédja', value: 'Bébédja' },
  { label: 'Bebedouro', value: 'Bebedouro' },
  { label: 'Beberibe', value: 'Beberibe' },
  { label: 'Beberon', value: 'Beberon' },
  { label: 'Bebington', value: 'Bebington' },
  { label: 'Bębło', value: 'Bębło' },
  { label: 'Béboto', value: 'Béboto' },
  { label: 'Bebra', value: 'Bebra' },
  { label: 'Bécal', value: 'Bécal' },
  { label: 'Becanchén', value: 'Becanchén' },
  { label: 'Bécancour', value: 'Bécancour' },
  { label: 'Beccacivetta-Azzano', value: 'Beccacivetta-Azzano' },
  { label: 'Beccles', value: 'Beccles' },
  { label: 'Becedas', value: 'Becedas' },
  { label: 'Becedillas', value: 'Becedillas' },
  { label: 'Beceite', value: 'Beceite' },
  { label: 'Bečej', value: 'Bečej' },
  { label: 'Beceni', value: 'Beceni' },
  { label: 'Becerreá', value: 'Becerreá' },
  { label: 'Becerril', value: 'Becerril' },
  { label: 'Becerril', value: 'Becerril' },
  { label: 'Becerril De Campos', value: 'Becerril De Campos' },
  { label: 'Becerril De La Sierra', value: 'Becerril De La Sierra' },
  { label: 'Bech', value: 'Bech' },
  { label: 'Béchar', value: 'Béchar' },
  { label: 'Bechet', value: 'Bechet' },
  { label: 'Bechhofen', value: 'Bechhofen' },
  { label: 'Bechlín', value: 'Bechlín' },
  { label: 'Bechtheim', value: 'Bechtheim' },
  { label: 'Bechtolsheim', value: 'Bechtolsheim' },
  { label: 'Bechtsrieth', value: 'Bechtsrieth' },
  { label: 'Bechyně', value: 'Bechyně' },
  { label: 'Becicherecu Mic', value: 'Becicherecu Mic' },
  { label: 'Becilla De Valderaduey', value: 'Becilla De Valderaduey' },
  { label: 'Beciu', value: 'Beciu' },
  { label: 'Beckdorf', value: 'Beckdorf' },
  { label: 'Beckedorf', value: 'Beckedorf' },
  { label: 'Beckemeyer', value: 'Beckemeyer' },
  { label: 'Beckenham', value: 'Beckenham' },
  { label: 'Beckenham', value: 'Beckenham' },
  { label: 'Beckenried', value: 'Beckenried' },
  { label: 'Becker', value: 'Becker' },
  { label: 'Becker County', value: 'Becker County' },
  { label: 'Beckerich', value: 'Beckerich' },
  { label: 'Becket', value: 'Becket' },
  { label: 'Beckett', value: 'Beckett' },
  { label: 'Beckett Ridge', value: 'Beckett Ridge' },
  { label: 'Beckford Kraal', value: 'Beckford Kraal' },
  { label: 'Beckham County', value: 'Beckham County' },
  { label: 'Beckingen', value: 'Beckingen' },
  { label: 'Beckingham', value: 'Beckingham' },
  { label: 'Beckley', value: 'Beckley' },
  { label: 'Beckum', value: 'Beckum' },
  { label: 'Beclean', value: 'Beclean' },
  { label: 'Beclean', value: 'Beclean' },
  { label: 'Bečmen', value: 'Bečmen' },
  { label: 'Bécon-Les-Granits', value: 'Bécon-Les-Granits' },
  { label: 'Becontree', value: 'Becontree' },
  { label: 'Bečov', value: 'Bečov' },
  { label: 'Becsehely', value: 'Becsehely' },
  { label: 'Bęczarka', value: 'Bęczarka' },
  { label: 'Bedale', value: 'Bedale' },
  { label: 'Bédar', value: 'Bédar' },
  { label: 'Bédarieux', value: 'Bédarieux' },
  { label: 'Bédarrides', value: 'Bédarrides' },
  { label: 'Bedburg', value: 'Bedburg' },
  { label: 'Beddau', value: 'Beddau' },
  { label: 'Beddeng', value: 'Beddeng' },
  { label: 'Beddeng', value: 'Beddeng' },
  { label: 'Bédée', value: 'Bédée' },
  { label: 'Bedekovčina', value: 'Bedekovčina' },
  { label: 'Bedelē', value: 'Bedelē' },
  { label: 'Bedeni', value: 'Bedeni' },
  { label: 'Beder', value: 'Beder' },
  { label: 'Bedero', value: 'Bedero' },
  { label: 'Bedero Valcuvia', value: 'Bedero Valcuvia' },
  { label: 'Bedēsa', value: 'Bedēsa' },
  { label: 'Bedeyeva Polyana', value: 'Bedeyeva Polyana' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford', value: 'Bedford' },
  { label: 'Bedford County', value: 'Bedford County' },
  { label: 'Bedford County', value: 'Bedford County' },
  { label: 'Bedford County', value: 'Bedford County' },
  { label: 'Bedford Heights', value: 'Bedford Heights' },
  { label: 'Bedford Hills', value: 'Bedford Hills' },
  { label: 'Bedford Park', value: 'Bedford Park' },
  { label: 'Bedfordale', value: 'Bedfordale' },
  { label: 'Bedi', value: 'Bedi' },
  { label: 'Bedia', value: 'Bedia' },
  { label: 'Bedihošť', value: 'Bedihošť' },
  { label: 'Bedinje', value: 'Bedinje' },
  { label: 'Bedizzole', value: 'Bedizzole' },
  { label: 'Będków', value: 'Będków' },
  { label: 'Bedlington', value: 'Bedlington' },
  { label: 'Bedlinog', value: 'Bedlinog' },
  { label: 'Bedlno', value: 'Bedlno' },
  { label: 'Bedmar Y Garcíez', value: 'Bedmar Y Garcíez' },
  { label: 'Bedminster', value: 'Bedminster' },
  { label: 'Bedminster', value: 'Bedminster' },
  { label: 'Bédoin', value: 'Bédoin' },
  { label: 'Bedok', value: 'Bedok' },
  { label: 'Bedong', value: 'Bedong' },
  { label: 'Bedonia', value: 'Bedonia' },
  { label: 'Bedreh', value: 'Bedreh' },
  { label: 'Bedugul', value: 'Bedugul' },
  { label: 'Beduido', value: 'Beduido' },
  { label: 'Bedulita', value: 'Bedulita' },
  { label: 'Bedum', value: 'Bedum' },
  { label: 'Bedwas', value: 'Bedwas' },
  { label: 'Bedworth', value: 'Bedworth' },
  { label: 'Będzin', value: 'Będzin' },
  { label: 'Bee', value: 'Bee' },
  { label: 'Bee Cave', value: 'Bee Cave' },
  { label: 'Bee County', value: 'Bee County' },
  { label: 'Bee Ridge', value: 'Bee Ridge' },
  { label: 'Beebe', value: 'Beebe' },
  { label: 'Beech Grove', value: 'Beech Grove' },
  { label: 'Beech Mountain Lakes', value: 'Beech Mountain Lakes' },
  { label: 'Beechboro', value: 'Beechboro' },
  { label: 'Beecher', value: 'Beecher' },
  { label: 'Beecher', value: 'Beecher' },
  { label: 'Beecher Town', value: 'Beecher Town' },
  { label: 'Beechwood', value: 'Beechwood' },
  { label: 'Beechwood', value: 'Beechwood' },
  { label: 'Beechwood Trails', value: 'Beechwood Trails' },
  { label: 'Beechwood Village', value: 'Beechwood Village' },
  { label: 'Beechworth', value: 'Beechworth' },
  { label: 'Beecroft', value: 'Beecroft' },
  { label: 'Beed', value: 'Beed' },
  { label: 'Beedenbostel', value: 'Beedenbostel' },
  { label: 'Beeford', value: 'Beeford' },
  { label: 'Beegden', value: 'Beegden' },
  { label: 'Beek', value: 'Beek' },
  { label: 'Beek', value: 'Beek' },
  { label: 'Beek Gem Montferland', value: 'Beek Gem Montferland' },
  { label: 'Beekbergen', value: 'Beekbergen' },
  { label: 'Beelen', value: 'Beelen' },
  { label: 'Beeliar', value: 'Beeliar' },
  { label: 'Beelitz', value: 'Beelitz' },
  { label: 'Beemster', value: 'Beemster' },
  { label: 'Beenleigh', value: 'Beenleigh' },
  { label: 'Beerfelden', value: 'Beerfelden' },
  { label: 'Beernem', value: 'Beernem' },
  { label: 'Beers', value: 'Beers' },
  { label: 'Beersdal', value: 'Beersdal' },
  { label: 'Beerse', value: 'Beerse' },
  { label: 'Beersel', value: 'Beersel' },
  { label: 'Beersheba', value: 'Beersheba' },
  { label: 'Beerwah', value: 'Beerwah' },
  { label: 'Beesd', value: 'Beesd' },
  { label: 'Beesel', value: 'Beesel' },
  { label: 'Beesenlaublingen', value: 'Beesenlaublingen' },
  { label: 'Beesenstedt', value: 'Beesenstedt' },
  { label: 'Beeskow', value: 'Beeskow' },
  { label: 'Beesten', value: 'Beesten' },
  { label: 'Beeston Spring', value: 'Beeston Spring' },
  { label: 'Beets', value: 'Beets' },
  { label: 'Beetsterzwaag', value: 'Beetsterzwaag' },
  { label: 'Beetzendorf', value: 'Beetzendorf' },
  { label: 'Beeville', value: 'Beeville' },
  { label: 'Bega', value: 'Bega' },
  { label: 'Bega Valley', value: 'Bega Valley' },
  { label: 'Bégaar', value: 'Bégaar' },
  { label: 'Begamganj', value: 'Begamganj' },
  { label: 'Begampur', value: 'Begampur' },
  { label: 'Begang', value: 'Begang' },
  { label: 'Begang', value: 'Begang' },
  { label: 'Béganne', value: 'Béganne' },
  { label: 'Bégard', value: 'Bégard' },
  { label: 'Beggs', value: 'Beggs' },
  { label: 'Begichevskiy', value: 'Begichevskiy' },
  { label: 'Begíjar', value: 'Begíjar' },
  { label: 'Begijnendijk', value: 'Begijnendijk' },
  { label: 'Bègles', value: 'Bègles' },
  { label: 'Begliano', value: 'Begliano' },
  { label: 'Begnins', value: 'Begnins' },
  { label: 'Begonte', value: 'Begonte' },
  { label: 'Begoro', value: 'Begoro' },
  { label: 'Begowal', value: 'Begowal' },
  { label: 'Begowala', value: 'Begowala' },
  { label: 'Bégrolles-En-Mauges', value: 'Bégrolles-En-Mauges' },
  { label: 'Begues', value: 'Begues' },
  { label: 'Begun', value: 'Begun' },
  { label: 'Begunitsy', value: 'Begunitsy' },
  { label: 'Begur', value: 'Begur' },
  { label: 'Begusarai', value: 'Begusarai' },
  { label: 'Behabad', value: 'Behabad' },
  { label: 'Behamberg', value: 'Behamberg' },
  { label: 'Behat', value: 'Behat' },
  { label: 'Behbahan', value: 'Behbahan' },
  { label: 'Behchokǫ̀', value: 'Behchokǫ̀' },
  { label: 'Behkandan', value: 'Behkandan' },
  { label: 'Behren-Lès-Forbach', value: 'Behren-Lès-Forbach' },
  { label: 'Behringen', value: 'Behringen' },
  { label: 'Behror', value: 'Behror' },
  { label: 'Behshahr', value: 'Behshahr' },
  { label: 'Bei Der Höhne', value: 'Bei Der Höhne' },
  { label: 'Bei’An', value: 'Bei’An' },
  { label: 'Beiarn', value: 'Beiarn' },
  { label: 'Beibei', value: 'Beibei' },
  { label: 'Beica De Jos', value: 'Beica De Jos' },
  { label: 'Beica De Sus', value: 'Beica De Sus' },
  { label: 'Beicang', value: 'Beicang' },
  { label: 'Beichengqu', value: 'Beichengqu' },
  { label: 'Beidaihehaibin', value: 'Beidaihehaibin' },
  { label: 'Beidao', value: 'Beidao' },
  { label: 'Beidaud', value: 'Beidaud' },
  { label: 'Beierfeld', value: 'Beierfeld' },
  { label: 'Beiersdorf', value: 'Beiersdorf' },
  { label: 'Beighton', value: 'Beighton' },
  { label: 'Beignon', value: 'Beignon' },
  { label: 'Beihai', value: 'Beihai' },
  { label: 'Beihuaidian', value: 'Beihuaidian' },
  { label: 'Beijing', value: 'Beijing' },
  { label: 'Beilen', value: 'Beilen' },
  { label: 'Beilizigu', value: 'Beilizigu' },
  { label: 'Beilrode', value: 'Beilrode' },
  { label: 'Beilstein', value: 'Beilstein' },
  { label: 'Beimerstetten', value: 'Beimerstetten' },
  { label: 'Beïnamar', value: 'Beïnamar' },
  { label: 'Beinasco', value: 'Beinasco' },
  { label: 'Beindersheim', value: 'Beindersheim' },
  { label: 'Beinette', value: 'Beinette' },
  { label: 'Beinsdorp', value: 'Beinsdorp' },
  { label: 'Beintza-Labaien', value: 'Beintza-Labaien' },
  { label: 'Beipiao', value: 'Beipiao' },
  { label: 'Beira', value: 'Beira' },
  { label: 'Beira Rio', value: 'Beira Rio' },
  { label: 'Beire', value: 'Beire' },
  { label: 'Beires', value: 'Beires' },
  { label: 'Beiriz De Baixo', value: 'Beiriz De Baixo' },
  { label: 'Beirut', value: 'Beirut' },
  { label: 'Beishancun', value: 'Beishancun' },
  { label: 'Beit Jann', value: 'Beit Jann' },
  { label: 'Beitbridge', value: 'Beitbridge' },
  { label: 'Beitbridge District', value: 'Beitbridge District' },
  { label: 'Beith', value: 'Beith' },
  { label: 'Beiu', value: 'Beiu' },
  { label: 'Beiuş', value: 'Beiuş' },
  { label: 'Beizama', value: 'Beizama' },
  { label: 'Beizhai', value: 'Beizhai' },
  { label: 'Beja', value: 'Beja' },
  { label: 'Béja', value: 'Béja' },
  { label: 'Bejaïa', value: 'Bejaïa' },
  { label: 'Béjar', value: 'Béjar' },
  { label: 'Bejís', value: 'Bejís' },
  { label: 'Bejsce', value: 'Bejsce' },
  { label: 'Bejubang Dua', value: 'Bejubang Dua' },
  { label: 'Bejucal', value: 'Bejucal' },
  { label: 'Bejucal De Ocampo', value: 'Bejucal De Ocampo' },
  { label: 'Bejuco', value: 'Bejuco' },
  { label: 'Bejuco', value: 'Bejuco' },
  { label: 'Bejucos', value: 'Bejucos' },
  { label: 'Bekalta', value: 'Bekalta' },
  { label: 'Bekasi', value: 'Bekasi' },
  { label: 'Bekecs', value: 'Bekecs' },
  { label: 'Békés', value: 'Békés' },
  { label: 'Békéscsaba', value: 'Békéscsaba' },
  { label: 'Békéscsabai Járás', value: 'Békéscsabai Járás' },
  { label: 'Bekeshevskaya', value: 'Bekeshevskaya' },
  { label: 'Békési Járás', value: 'Békési Járás' },
  { label: 'Békéssámson', value: 'Békéssámson' },
  { label: 'Békésszentandrás', value: 'Békésszentandrás' },
  { label: 'Bekhi', value: 'Bekhi' },
  { label: 'Bekhtery', value: 'Bekhtery' },
  { label: 'Bekhteyevka', value: 'Bekhteyevka' },
  { label: 'Bekilli', value: 'Bekilli' },
  { label: 'Bekkevoort', value: 'Bekkevoort' },
  { label: 'Bekobod', value: 'Bekobod' },
  { label: 'Bekondo', value: 'Bekondo' },
  { label: 'Bekovo', value: 'Bekovo' },
  { label: 'Bektemir', value: 'Bektemir' },
  { label: 'Bekwai', value: 'Bekwai' },
  { label: 'Bel Air', value: 'Bel Air' },
  { label: 'Bel Air', value: 'Bel Air' },
  { label: 'Bel Air North', value: 'Bel Air North' },
  { label: 'Bel Air Rivière Sèche', value: 'Bel Air Rivière Sèche' },
  { label: 'Bel Air South', value: 'Bel Air South' },
  { label: 'Bel Ombre', value: 'Bel Ombre' },
  { label: 'Bel Ombre', value: 'Bel Ombre' },
  { label: 'Bel’Skiy Rayon', value: 'Bel’Skiy Rayon' },
  { label: 'Bela', value: 'Bela' },
  { label: 'Bela', value: 'Bela' },
  { label: 'Bela Crkva', value: 'Bela Crkva' },
  { label: 'Bela Cruz', value: 'Bela Cruz' },
  { label: 'Bělá Nad Radbuzou', value: 'Bělá Nad Radbuzou' },
  { label: 'Bělá Pod Bezdězem', value: 'Bělá Pod Bezdězem' },
  { label: 'Bela Vista', value: 'Bela Vista' },
  { label: 'Bela Vista Da Caroba', value: 'Bela Vista Da Caroba' },
  { label: 'Bela Vista De Goiás', value: 'Bela Vista De Goiás' },
  { label: 'Bela Vista De Minas', value: 'Bela Vista De Minas' },
  { label: 'Bela Vista Do Maranhão', value: 'Bela Vista Do Maranhão' },
  { label: 'Bela Vista Do Paraíso', value: 'Bela Vista Do Paraíso' },
  { label: 'Bela Vista Do Piauí', value: 'Bela Vista Do Piauí' },
  { label: 'Bela Vista Do Toldo', value: 'Bela Vista Do Toldo' },
  { label: 'Bélabo', value: 'Bélabo' },
  { label: 'Bélâbre', value: 'Bélâbre' },
  { label: 'Belagavi', value: 'Belagavi' },
  { label: 'Belágua', value: 'Belágua' },
  { label: 'Belaguntha', value: 'Belaguntha' },
  { label: 'Belair', value: 'Belair' },
  { label: 'Belair', value: 'Belair' },
  { label: 'Belalcázar', value: 'Belalcázar' },
  { label: 'Belalcázar', value: 'Belalcázar' },
  { label: 'Belance', value: 'Belance' },
  { label: 'Belance', value: 'Belance' },
  { label: 'Bélapátfalva', value: 'Bélapátfalva' },
  { label: 'Bélapátfalvai Járás', value: 'Bélapátfalvai Járás' },
  { label: 'Belarbi', value: 'Belarbi' },
  { label: 'Belartu', value: 'Belartu' },
  { label: 'Belas', value: 'Belas' },
  { label: 'Belas', value: 'Belas' },
  { label: 'Belas', value: 'Belas' },
  { label: 'Belascoáin', value: 'Belascoáin' },
  { label: 'Belauntza', value: 'Belauntza' },
  { label: 'Belawan', value: 'Belawan' },
  { label: 'Belaya', value: 'Belaya' },
  { label: 'Belaya', value: 'Belaya' },
  { label: 'Belaya Glina', value: 'Belaya Glina' },
  { label: 'Belaya Gora', value: 'Belaya Gora' },
  { label: 'Belaya Kalitva', value: 'Belaya Kalitva' },
  { label: 'Belaya Kholunitsa', value: 'Belaya Kholunitsa' },
  { label: 'Belaya Rechka', value: 'Belaya Rechka' },
  { label: 'Belaya Rechka', value: 'Belaya Rechka' },
  { label: 'Belberaud', value: 'Belberaud' },
  { label: 'Belbeuf', value: 'Belbeuf' },
  { label: 'Belbimbre', value: 'Belbimbre' },
  { label: 'Belbroughton', value: 'Belbroughton' },
  { label: 'Belcastro', value: 'Belcastro' },
  { label: 'Belceşti', value: 'Belceşti' },
  { label: 'Bełchatów', value: 'Bełchatów' },
  { label: 'Belchertown', value: 'Belchertown' },
  { label: 'Belchite', value: 'Belchite' },
  { label: 'Belčišta', value: 'Belčišta' },
  { label: 'Belciugatele', value: 'Belciugatele' },
  { label: 'Belcodène', value: 'Belcodène' },
  { label: 'Belconnen', value: 'Belconnen' },
  { label: 'Belcourt', value: 'Belcourt' },
  { label: 'Belcrum', value: 'Belcrum' },
  { label: 'Belda', value: 'Belda' },
  { label: 'Beldanga', value: 'Beldanga' },
  { label: 'Belding', value: 'Belding' },
  { label: 'Beldon', value: 'Beldon' },
  { label: 'Belebey', value: 'Belebey' },
  { label: 'Beled', value: 'Beled' },
  { label: 'Beledweyne', value: 'Beledweyne' },
  { label: 'Belegiš', value: 'Belegiš' },
  { label: 'Bélel', value: 'Bélel' },
  { label: 'Belem', value: 'Belem' },
  { label: 'Belém', value: 'Belém' },
  { label: 'Belém', value: 'Belém' },
  { label: 'Belém', value: 'Belém' },
  { label: 'Belém De Maria', value: 'Belém De Maria' },
  { label: 'Belém De São Francisco', value: 'Belém De São Francisco' },
  { label: 'Belem Del Refugio', value: 'Belem Del Refugio' },
  { label: 'Belém Do Brejo Do Cruz', value: 'Belém Do Brejo Do Cruz' },
  { label: 'Belém Do Piauí', value: 'Belém Do Piauí' },
  { label: 'Belém Do São Francisco', value: 'Belém Do São Francisco' },
  { label: 'Belen', value: 'Belen' },
  { label: 'Belen', value: 'Belen' },
  { label: 'Belén', value: 'Belén' },
  { label: 'Belén', value: 'Belén' },
  { label: 'Belén', value: 'Belén' },
  { label: 'Belén', value: 'Belén' },
  { label: 'Belén', value: 'Belén' },
  { label: 'Belén', value: 'Belén' },
  { label: 'Belén', value: 'Belén' },
  { label: 'Belén', value: 'Belén' },
  { label: 'Belén', value: 'Belén' },
  { label: 'Belén Atzitzimititlán', value: 'Belén Atzitzimititlán' },
  { label: 'Belén De Los Andaquies', value: 'Belén De Los Andaquies' },
  { label: 'Belén De Umbría', value: 'Belén De Umbría' },
  { label: 'Belén Gualcho', value: 'Belén Gualcho' },
  { label: 'Beleña', value: 'Beleña' },
  { label: 'Belene', value: 'Belene' },
  { label: 'Bélesta', value: 'Bélesta' },
  { label: 'Beleţi', value: 'Beleţi' },
  { label: 'Belëv', value: 'Belëv' },
  { label: 'Belfair', value: 'Belfair' },
  { label: 'Belfast', value: 'Belfast' },
  { label: 'Belfast', value: 'Belfast' },
  { label: 'Belfast', value: 'Belfast' },
  { label: 'Belfast', value: 'Belfast' },
  { label: 'Belfast', value: 'Belfast' },
  { label: 'Belfaux', value: 'Belfaux' },
  { label: 'Belfield', value: 'Belfield' },
  { label: 'Belfield', value: 'Belfield' },
  { label: 'Belfield', value: 'Belfield' },
  { label: 'Belfiore', value: 'Belfiore' },
  { label: 'Belford', value: 'Belford' },
  { label: 'Belford', value: 'Belford' },
  { label: 'Belford Roxo', value: 'Belford Roxo' },
  { label: 'Belfort', value: 'Belfort' },
  { label: 'Belfort', value: 'Belfort' },
  { label: 'Belforte', value: 'Belforte' },
  { label: 'Belforte', value: 'Belforte' },
  { label: 'Belforte Del Chienti', value: 'Belforte Del Chienti' },
  { label: 'Belforte Monferrato', value: 'Belforte Monferrato' },
  { label: 'Belgatoy', value: 'Belgatoy' },
  { label: 'Belgentier', value: 'Belgentier' },
  { label: 'Belgern', value: 'Belgern' },
  { label: 'Belgershain', value: 'Belgershain' },
  { label: 'Belgian Gardens', value: 'Belgian Gardens' },
  { label: 'Bèlgida', value: 'Bèlgida' },
  { label: 'Belgioioso', value: 'Belgioioso' },
  { label: 'Belgirate', value: 'Belgirate' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'Belgorod', value: 'Belgorod' },
  { label: 'Belgorodskiy Rayon', value: 'Belgorodskiy Rayon' },
  { label: 'Belgrade', value: 'Belgrade' },
  { label: 'Belgrade', value: 'Belgrade' },
  { label: 'Belgrade', value: 'Belgrade' },
  { label: 'Belgrano', value: 'Belgrano' },
  { label: 'Belgrave', value: 'Belgrave' },
  { label: 'Belgrave Heights', value: 'Belgrave Heights' },
  { label: 'Belgrave South', value: 'Belgrave South' },
  { label: 'Belhaven', value: 'Belhaven' },
  { label: 'Beli', value: 'Beli' },
  { label: 'Beli Manastir', value: 'Beli Manastir' },
  { label: 'Belianes', value: 'Belianes' },
  { label: 'Beliatore', value: 'Beliatore' },
  { label: 'Belica', value: 'Belica' },
  { label: 'Belidzhi', value: 'Belidzhi' },
  { label: 'Bélier', value: 'Bélier' },
  { label: 'Béligneux', value: 'Béligneux' },
  { label: 'Belin', value: 'Belin' },
  { label: 'Belin-Béliet', value: 'Belin-Béliet' },
  { label: 'Belinchón', value: 'Belinchón' },
  { label: 'Belington', value: 'Belington' },
  { label: 'Belinskiy', value: 'Belinskiy' },
  { label: 'Belinskoye', value: 'Belinskoye' },
  { label: 'Belinţ', value: 'Belinţ' },
  { label: 'Belin-Vale', value: 'Belin-Vale' },
  { label: 'Beliş', value: 'Beliş' },
  { label: 'Belisario Domínguez', value: 'Belisario Domínguez' },
  { label: 'Belisario Domínguez', value: 'Belisario Domínguez' },
  { label: 'Belišće', value: 'Belišće' },
  { label: 'Belison', value: 'Belison' },
  { label: 'Belitsa', value: 'Belitsa' },
  { label: 'Beliu', value: 'Beliu' },
  { label: 'Belize City', value: 'Belize City' },
  { label: 'Bełk', value: 'Bełk' },
  { label: 'Belknap County', value: 'Belknap County' },
  { label: 'Belköl', value: 'Belköl' },
  { label: 'Bell', value: 'Bell' },
  { label: 'Bell', value: 'Bell' },
  { label: 'Bell Acres', value: 'Bell Acres' },
  { label: 'Bell County', value: 'Bell County' },
  { label: 'Bell County', value: 'Bell County' },
  { label: 'Bell Gardens', value: 'Bell Gardens' },
  { label: 'Bell Park', value: 'Bell Park' },
  { label: 'Bell Post Hill', value: 'Bell Post Hill' },
  { label: 'Bell Village', value: 'Bell Village' },
  { label: 'Bell Ville', value: 'Bell Ville' },
  { label: 'Bella', value: 'Bella' },
  { label: 'Bella Esperanza', value: 'Bella Esperanza' },
  { label: 'Bella Farnia', value: 'Bella Farnia' },
  { label: 'Bella Italia', value: 'Bella Italia' },
  { label: 'Bella Italia', value: 'Bella Italia' },
  { label: 'Bella Rosa', value: 'Bella Rosa' },
  { label: 'Bella Rosa', value: 'Bella Rosa' },
  { label: 'Bella Unión', value: 'Bella Unión' },
  { label: 'Bella Vista', value: 'Bella Vista' },
  { label: 'Bella Vista', value: 'Bella Vista' },
  { label: 'Bella Vista', value: 'Bella Vista' },
  { label: 'Bella Vista', value: 'Bella Vista' },
  { label: 'Bella Vista', value: 'Bella Vista' },
  { label: 'Bella Vista', value: 'Bella Vista' },
  { label: 'Bella Vista', value: 'Bella Vista' },
  { label: 'Bella Vista', value: 'Bella Vista' },
  { label: 'Bella Vista', value: 'Bella Vista' },
  { label: 'Bella Vista', value: 'Bella Vista' },
  { label: 'Bella Vista Del Río', value: 'Bella Vista Del Río' },
  { label: 'Bellac', value: 'Bellac' },
  { label: 'Bellaghy', value: 'Bellaghy' },
  { label: 'Bellagio', value: 'Bellagio' },
  { label: 'Bellaguarda', value: 'Bellaguarda' },
  { label: 'Bellaing', value: 'Bellaing' },
  { label: 'Bellaire', value: 'Bellaire' },
  { label: 'Bellaire', value: 'Bellaire' },
  { label: 'Bellaire', value: 'Bellaire' },
  { label: 'Bellaire', value: 'Bellaire' },
  { label: 'Bellaire', value: 'Bellaire' },
  { label: 'Bellair-Meadowbrook Terrace', value: 'Bellair-Meadowbrook Terrace' },
  { label: 'Bellamack', value: 'Bellamack' },
  { label: 'Bellambi', value: 'Bellambi' },
  { label: 'Bellampalli', value: 'Bellampalli' },
  { label: 'Bellano', value: 'Bellano' },
  { label: 'Bellante', value: 'Bellante' },
  { label: 'Bellante Stazione', value: 'Bellante Stazione' },
  { label: 'Bellara', value: 'Bellara' },
  { label: 'Bellaria-Igea Marina', value: 'Bellaria-Igea Marina' },
  { label: 'Bellas Fuentes', value: 'Bellas Fuentes' },
  { label: 'Bellas Gate', value: 'Bellas Gate' },
  { label: 'Bellavista', value: 'Bellavista' },
  { label: 'Bellavista', value: 'Bellavista' },
  { label: 'Bellavista', value: 'Bellavista' },
  { label: 'Bellavista', value: 'Bellavista' },
  { label: 'Bellavista', value: 'Bellavista' },
  { label: 'Bellavista', value: 'Bellavista' },
  { label: 'Bellavista De Victoria (San José Bellavista)', value: 'Bellavista De Victoria (San José Bellavista)' },
  { label: 'Bellbird', value: 'Bellbird' },
  { label: 'Bellbird Park', value: 'Bellbird Park' },
  { label: 'Bellbowrie', value: 'Bellbowrie' },
  { label: 'Bellbrook', value: 'Bellbrook' },
  { label: 'Bellcaire Dempordà', value: 'Bellcaire Dempordà' },
  { label: 'Bellcaire Durgell', value: 'Bellcaire Durgell' },
  { label: 'Belle', value: 'Belle' },
  { label: 'Belle', value: 'Belle' },
  { label: 'Belle Chasse', value: 'Belle Chasse' },
  { label: 'Belle De Mai', value: 'Belle De Mai' },
  { label: 'Belle Fourche', value: 'Belle Fourche' },
  { label: 'Belle Glade', value: 'Belle Glade' },
  { label: 'Belle Glade Camp', value: 'Belle Glade Camp' },
  { label: 'Belle Harbor', value: 'Belle Harbor' },
  { label: 'Belle Haven', value: 'Belle Haven' },
  { label: 'Belle Isle', value: 'Belle Isle' },
  { label: 'Belle Meade', value: 'Belle Meade' },
  { label: 'Belle Plaine', value: 'Belle Plaine' },
  { label: 'Belle Plaine', value: 'Belle Plaine' },
  { label: 'Belle Plaine', value: 'Belle Plaine' },
  { label: 'Belle Plaine', value: 'Belle Plaine' },
  { label: 'Belle Plaine', value: 'Belle Plaine' },
  { label: 'Belle Rose', value: 'Belle Rose' },
  { label: 'Belle Terre', value: 'Belle Terre' },
  { label: 'Belle Vernon', value: 'Belle Vernon' },
  { label: 'Belle Vue', value: 'Belle Vue' },
  { label: 'Belle Vue', value: 'Belle Vue' },
  { label: 'Belle Vue', value: 'Belle Vue' },
  { label: 'Belle Vue Estate', value: 'Belle Vue Estate' },
  { label: 'Belleair', value: 'Belleair' },
  { label: 'Belleair Beach', value: 'Belleair Beach' },
  { label: 'Belleair Bluffs', value: 'Belleair Bluffs' },
  { label: 'Belle-Anse', value: 'Belle-Anse' },
  { label: 'Belleben', value: 'Belleben' },
  { label: 'Bellefield', value: 'Bellefield' },
  { label: 'Bellefond', value: 'Bellefond' },
  { label: 'Bellefontaine', value: 'Bellefontaine' },
  { label: 'Bellefontaine Neighbors', value: 'Bellefontaine Neighbors' },
  { label: 'Bellefonte', value: 'Bellefonte' },
  { label: 'Bellefonte', value: 'Bellefonte' },
  { label: 'Bellegarde', value: 'Bellegarde' },
  { label: 'Bellegarde', value: 'Bellegarde' },
  { label: 'Bellegarde-En-Forez', value: 'Bellegarde-En-Forez' },
  { label: 'Bellegarde-Sur-Valserine', value: 'Bellegarde-Sur-Valserine' },
  { label: 'Bellegra', value: 'Bellegra' },
  { label: 'Belle-Isle-En-Terre', value: 'Belle-Isle-En-Terre' },
  { label: 'Bellême', value: 'Bellême' },
  { label: 'Bellenaves', value: 'Bellenaves' },
  { label: 'Bellenberg', value: 'Bellenberg' },
  { label: 'Belleneuve', value: 'Belleneuve' },
  { label: 'Bellengreville', value: 'Bellengreville' },
  { label: 'Belle-Plagne', value: 'Belle-Plagne' },
  { label: 'Bellerive', value: 'Bellerive' },
  { label: 'Bellerive-Sur-Allier', value: 'Bellerive-Sur-Allier' },
  { label: 'Bellerose', value: 'Bellerose' },
  { label: 'Bellerose Terrace', value: 'Bellerose Terrace' },
  { label: 'Belleu', value: 'Belleu' },
  { label: 'Bellevaux', value: 'Bellevaux' },
  { label: 'Belleview', value: 'Belleview' },
  { label: 'Belleville', value: 'Belleville' },
  { label: 'Belleville', value: 'Belleville' },
  { label: 'Belleville', value: 'Belleville' },
  { label: 'Belleville', value: 'Belleville' },
  { label: 'Belleville', value: 'Belleville' },
  { label: 'Belleville', value: 'Belleville' },
  { label: 'Belleville', value: 'Belleville' },
  { label: 'Belleville', value: 'Belleville' },
  { label: 'Belleville', value: 'Belleville' },
  { label: 'Belleville-Sur-Loire', value: 'Belleville-Sur-Loire' },
  { label: 'Belleville-Sur-Meuse', value: 'Belleville-Sur-Meuse' },
  { label: 'Belleville-Sur-Vie', value: 'Belleville-Sur-Vie' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue', value: 'Bellevue' },
  { label: 'Bellevue Heights', value: 'Bellevue Heights' },
  { label: 'Bellevue Hill', value: 'Bellevue Hill' },
  { label: 'Belley', value: 'Belley' },
  { label: 'Bellfield', value: 'Bellfield' },
  { label: 'Bellflower', value: 'Bellflower' },
  { label: 'Bellheim', value: 'Bellheim' },
  { label: 'Bellignat', value: 'Bellignat' },
  { label: 'Belligné', value: 'Belligné' },
  { label: 'Bellinge', value: 'Bellinge' },
  { label: 'Bellingen', value: 'Bellingen' },
  { label: 'Bellingham', value: 'Bellingham' },
  { label: 'Bellingham', value: 'Bellingham' },
  { label: 'Bellingham', value: 'Bellingham' },
  { label: 'Bellingwolde', value: 'Bellingwolde' },
  { label: 'Bellinzago Lombardo', value: 'Bellinzago Lombardo' },
  { label: 'Bellinzago Novarese', value: 'Bellinzago Novarese' },
  { label: 'Bellinzona', value: 'Bellinzona' },
  { label: 'Bellinzona District', value: 'Bellinzona District' },
  { label: 'Bellizzi', value: 'Bellizzi' },
  { label: 'Bell-Lloc Durgell', value: 'Bell-Lloc Durgell' },
  { label: 'Bellmawr', value: 'Bellmawr' },
  { label: 'Bellmead', value: 'Bellmead' },
  { label: 'Bellmere', value: 'Bellmere' },
  { label: 'Bellmore', value: 'Bellmore' },
  { label: 'Bellmund', value: 'Bellmund' },
  { label: 'Bellmunt Del Priorat', value: 'Bellmunt Del Priorat' },
  { label: 'Bellmunt Durgell', value: 'Bellmunt Durgell' },
  { label: 'Bello', value: 'Bello' },
  { label: 'Bello', value: 'Bello' },
  { label: 'Bello Amanecer', value: 'Bello Amanecer' },
  { label: 'Bellocchi', value: 'Bellocchi' },
  { label: 'Bellona', value: 'Bellona' },
  { label: 'Bellosguardo', value: 'Bellosguardo' },
  { label: 'Bellows Falls', value: 'Bellows Falls' },
  { label: 'Belloy-En-France', value: 'Belloy-En-France' },
  { label: 'Bellport', value: 'Bellport' },
  { label: 'Bellprat', value: 'Bellprat' },
  { label: 'Bellpuig', value: 'Bellpuig' },
  { label: 'Bellreguard', value: 'Bellreguard' },
  { label: 'Bells', value: 'Bells' },
  { label: 'Bells', value: 'Bells' },
  { label: 'Bells Corners', value: 'Bells Corners' },
  { label: 'Bellsbank', value: 'Bellsbank' },
  { label: 'Bellshill', value: 'Bellshill' },
  { label: 'Belluno', value: 'Belluno' },
  { label: 'Belluru', value: 'Belluru' },
  { label: 'Bellús', value: 'Bellús' },
  { label: 'Bellusco', value: 'Bellusco' },
  { label: 'Bellvei', value: 'Bellvei' },
  { label: 'Bellver De Cerdanya', value: 'Bellver De Cerdanya' },
  { label: 'Bellview', value: 'Bellview' },
  { label: 'Bellville', value: 'Bellville' },
  { label: 'Bellville', value: 'Bellville' },
  { label: 'Bellvís', value: 'Bellvís' },
  { label: 'Bellwood', value: 'Bellwood' },
  { label: 'Bellwood', value: 'Bellwood' },
  { label: 'Bellwood', value: 'Bellwood' },
  { label: 'Belm', value: 'Belm' },
  { label: 'Belmar', value: 'Belmar' },
  { label: 'Bélmez', value: 'Bélmez' },
  { label: 'Bélmez De La Moraleda', value: 'Bélmez De La Moraleda' },
  { label: 'Belmira', value: 'Belmira' },
  { label: 'Belmiro Braga', value: 'Belmiro Braga' },
  { label: 'Belmond', value: 'Belmond' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont', value: 'Belmont' },
  { label: 'Belmont County', value: 'Belmont County' },
  { label: 'Belmont Cragin', value: 'Belmont Cragin' },
  { label: 'Belmont Estates', value: 'Belmont Estates' },
  { label: 'Belmont North', value: 'Belmont North' },
  { label: 'Belmont South', value: 'Belmont South' },
  { label: 'Belmont-De-La-Loire', value: 'Belmont-De-La-Loire' },
  { label: 'Belmonte', value: 'Belmonte' },
  { label: 'Belmonte', value: 'Belmonte' },
  { label: 'Belmonte', value: 'Belmonte' },
  { label: 'Belmonte', value: 'Belmonte' },
  { label: 'Belmonte Calabro', value: 'Belmonte Calabro' },
  { label: 'Belmonte Castello', value: 'Belmonte Castello' },
  { label: 'Belmonte De Campos', value: 'Belmonte De Campos' },
  { label: 'Belmonte De Gracián', value: 'Belmonte De Gracián' },
  { label: 'Belmonte De Miranda', value: 'Belmonte De Miranda' },
  { label: 'Belmonte De San José', value: 'Belmonte De San José' },
  { label: 'Belmonte De Tajo', value: 'Belmonte De Tajo' },
  { label: 'Belmonte Del Sannio', value: 'Belmonte Del Sannio' },
  { label: 'Belmonte In Sabina', value: 'Belmonte In Sabina' },
  { label: 'Belmonte Mezzagno', value: 'Belmonte Mezzagno' },
  { label: 'Belmonte Piceno', value: 'Belmonte Piceno' },
  { label: 'Belmontejo', value: 'Belmontejo' },
  { label: 'Belmont-Sur-Lausanne', value: 'Belmont-Sur-Lausanne' },
  { label: 'Belmont-Sur-Rance', value: 'Belmont-Sur-Rance' },
  { label: 'Belmopan', value: 'Belmopan' },
  { label: 'Belmore', value: 'Belmore' },
  { label: 'Belmullet', value: 'Belmullet' },
  { label: 'Bel-Nor', value: 'Bel-Nor' },
  { label: 'Belo', value: 'Belo' },
  { label: 'Belo Blato', value: 'Belo Blato' },
  { label: 'Belo Campo', value: 'Belo Campo' },
  { label: 'Belo Horizonte', value: 'Belo Horizonte' },
  { label: 'Belo Jardim', value: 'Belo Jardim' },
  { label: 'Belo Monte', value: 'Belo Monte' },
  { label: 'Belo Oriente', value: 'Belo Oriente' },
  { label: 'Belo Vale', value: 'Belo Vale' },
  { label: 'Beloeil', value: 'Beloeil' },
  { label: 'Beloeil', value: 'Beloeil' },
  { label: 'Beloglinka', value: 'Beloglinka' },
  { label: 'Belogor’Ye', value: 'Belogor’Ye' },
  { label: 'Belogor’Ye', value: 'Belogor’Ye' },
  { label: 'Belogorsk', value: 'Belogorsk' },
  { label: 'Belogorsk', value: 'Belogorsk' },
  { label: 'Belogorskīy', value: 'Belogorskīy' },
  { label: 'Belogorskiy Rayon', value: 'Belogorskiy Rayon' },
  { label: 'Belogorskiy Rayon', value: 'Belogorskiy Rayon' },
  { label: 'Belogradchik', value: 'Belogradchik' },
  { label: 'Beloit', value: 'Beloit' },
  { label: 'Beloit', value: 'Beloit' },
  { label: 'Belokany', value: 'Belokany' },
  { label: 'Belokurikha', value: 'Belokurikha' },
  { label: 'Belomorsk', value: 'Belomorsk' },
  { label: 'Belonia', value: 'Belonia' },
  { label: 'Beloomut', value: 'Beloomut' },
  { label: 'Beloostrov', value: 'Beloostrov' },
  { label: 'Beloozërskiy', value: 'Beloozërskiy' },
  { label: 'Belorado', value: 'Belorado' },
  { label: 'Belorechensk', value: 'Belorechensk' },
  { label: 'Belorechensk', value: 'Belorechensk' },
  { label: 'Belorechenskiy Rayon', value: 'Belorechenskiy Rayon' },
  { label: 'Beloretsk', value: 'Beloretsk' },
  { label: 'Beloretskiy Rayon', value: 'Beloretskiy Rayon' },
  { label: 'Beloslav', value: 'Beloslav' },
  { label: 'Belotić', value: 'Belotić' },
  { label: 'Bělotín', value: 'Bělotín' },
  { label: 'Belousovka', value: 'Belousovka' },
  { label: 'Belousovo', value: 'Belousovo' },
  { label: 'Belovo', value: 'Belovo' },
  { label: 'Belovo', value: 'Belovo' },
  { label: 'Belovodskoye', value: 'Belovodskoye' },
  { label: 'Beloyarsk', value: 'Beloyarsk' },
  { label: 'Beloyarskiy', value: 'Beloyarskiy' },
  { label: 'Beloyarskiy', value: 'Beloyarskiy' },
  { label: 'Beloye', value: 'Beloye' },
  { label: 'Belozërnyy', value: 'Belozërnyy' },
  { label: 'Belozërsk', value: 'Belozërsk' },
  { label: 'Belozërskiy Rayon', value: 'Belozërskiy Rayon' },
  { label: 'Belozërskoye', value: 'Belozërskoye' },
  { label: 'Belp', value: 'Belp' },
  { label: 'Belpasso', value: 'Belpasso' },
  { label: 'Belpech', value: 'Belpech' },
  { label: 'Belper', value: 'Belper' },
  { label: 'Belpre', value: 'Belpre' },
  { label: 'Bel-Ridge', value: 'Bel-Ridge' },
  { label: 'Belrose', value: 'Belrose' },
  { label: 'Belsand', value: 'Belsand' },
  { label: 'Belsito', value: 'Belsito' },
  { label: 'Belsize Park', value: 'Belsize Park' },
  { label: 'Belsunce', value: 'Belsunce' },
  { label: 'Bełsznica', value: 'Bełsznica' },
  { label: 'Belt', value: 'Belt' },
  { label: 'Beltangadi', value: 'Beltangadi' },
  { label: 'Belterra', value: 'Belterra' },
  { label: 'Belthara', value: 'Belthara' },
  { label: 'Beltheim', value: 'Beltheim' },
  { label: 'Beltiglio-San Giovanni', value: 'Beltiglio-San Giovanni' },
  { label: 'Beltinci', value: 'Beltinci' },
  { label: 'Beltiug', value: 'Beltiug' },
  { label: 'Belton', value: 'Belton' },
  { label: 'Belton', value: 'Belton' },
  { label: 'Belton', value: 'Belton' },
  { label: 'Belton', value: 'Belton' },
  { label: 'Beltrami County', value: 'Beltrami County' },
  { label: 'Beltrán', value: 'Beltrán' },
  { label: 'Beltrán', value: 'Beltrán' },
  { label: 'Beltrum', value: 'Beltrum' },
  { label: 'Beltsville', value: 'Beltsville' },
  { label: 'Belturbet', value: 'Belturbet' },
  { label: 'Belur', value: 'Belur' },
  { label: 'Belur', value: 'Belur' },
  { label: 'Beluzhino-Koldairov', value: 'Beluzhino-Koldairov' },
  { label: 'Belvaux', value: 'Belvaux' },
  { label: 'Belvedere', value: 'Belvedere' },
  { label: 'Belvedere', value: 'Belvedere' },
  { label: 'Belvedere', value: 'Belvedere' },
  { label: 'Belvedere', value: 'Belvedere' },
  { label: 'Belvedere', value: 'Belvedere' },
  { label: 'Belvedere', value: 'Belvedere' },
  { label: 'Belvedere', value: 'Belvedere' },
  { label: 'Belvedere', value: 'Belvedere' },
  { label: 'Belvedere', value: 'Belvedere' },
  { label: 'Belvedere', value: 'Belvedere' },
  { label: 'Belvedere Langhe', value: 'Belvedere Langhe' },
  { label: 'Belvedere Marittimo', value: 'Belvedere Marittimo' },
  { label: 'Belvedere Ostrense', value: 'Belvedere Ostrense' },
  { label: 'Belvedere Park', value: 'Belvedere Park' },
  { label: 'Belvedere Spinello', value: 'Belvedere Spinello' },
  { label: 'Belvedere-Piano Tavola', value: 'Belvedere-Piano Tavola' },
  { label: 'Belveglio', value: 'Belveglio' },
  { label: 'Belver', value: 'Belver' },
  { label: 'Belver De Cinca', value: 'Belver De Cinca' },
  { label: 'Belver De Los Montes', value: 'Belver De Los Montes' },
  { label: 'Belverde', value: 'Belverde' },
  { label: 'Belvès', value: 'Belvès' },
  { label: 'Belvì', value: 'Belvì' },
  { label: 'Belvidere', value: 'Belvidere' },
  { label: 'Belvidere', value: 'Belvidere' },
  { label: 'Belville', value: 'Belville' },
  { label: 'Belvis De La Jara', value: 'Belvis De La Jara' },
  { label: 'Belvís De Monroy', value: 'Belvís De Monroy' },
  { label: 'Belyashi', value: 'Belyashi' },
  { label: 'Belyayevka', value: 'Belyayevka' },
  { label: 'Belyuen', value: 'Belyuen' },
  { label: 'Belyy', value: 'Belyy' },
  { label: 'Belyy Gorodok', value: 'Belyy Gorodok' },
  { label: 'Belyy Yar', value: 'Belyy Yar' },
  { label: 'Belyy Yar', value: 'Belyy Yar' },
  { label: 'Belyye Berega', value: 'Belyye Berega' },
  { label: 'Belyye Stolby', value: 'Belyye Stolby' },
  { label: 'Belyye Vody', value: 'Belyye Vody' },
  { label: 'Belz', value: 'Belz' },
  { label: 'Belz', value: 'Belz' },
  { label: 'Bełżec', value: 'Bełżec' },
  { label: 'Belzoni', value: 'Belzoni' },
  { label: 'Bełżyce', value: 'Bełżyce' },
  { label: 'Bema', value: 'Bema' },
  { label: 'Bemban', value: 'Bemban' },
  { label: 'Bembèrèkè', value: 'Bembèrèkè' },
  { label: 'Bembibre', value: 'Bembibre' },
  { label: 'Bembridge', value: 'Bembridge' },
  { label: 'Bement', value: 'Bement' },
  { label: 'Bemetara', value: 'Bemetara' },
  { label: 'Bemfica', value: 'Bemfica' },
  { label: 'Bemidji', value: 'Bemidji' },
  { label: 'Bemmel', value: 'Bemmel' },
  { label: 'Bemowo', value: 'Bemowo' },
  { label: 'Bempflingen', value: 'Bempflingen' },
  { label: 'Bempton', value: 'Bempton' },
  { label: 'Ben', value: 'Ben' },
  { label: 'Ben Arous', value: 'Ben Arous' },
  { label: 'Ben Avon', value: 'Ben Avon' },
  { label: 'Ben Badis Sid Bel Abbés', value: 'Ben Badis Sid Bel Abbés' },
  { label: 'Ben Gardane', value: 'Ben Gardane' },
  { label: 'Ben Hill County', value: 'Ben Hill County' },
  { label: 'Ben Lomond', value: 'Ben Lomond' },
  { label: 'Ben Mehidi', value: 'Ben Mehidi' },
  { label: 'Bến Tre', value: 'Bến Tre' },
  { label: 'Bena', value: 'Bena' },
  { label: 'Benab E Marand', value: 'Benab E Marand' },
  { label: 'Benabarre', value: 'Benabarre' },
  { label: 'Benacazón', value: 'Benacazón' },
  { label: 'Benadalid', value: 'Benadalid' },
  { label: 'Benafarces', value: 'Benafarces' },
  { label: 'Benafer', value: 'Benafer' },
  { label: 'Benafigos', value: 'Benafigos' },
  { label: 'Benagéber', value: 'Benagéber' },
  { label: 'Benaguasil', value: 'Benaguasil' },
  { label: 'Benahadux', value: 'Benahadux' },
  { label: 'Benahavís', value: 'Benahavís' },
  { label: 'Benalauría', value: 'Benalauría' },
  { label: 'Benalla', value: 'Benalla' },
  { label: 'Benalmádena', value: 'Benalmádena' },
  { label: 'Benalúa', value: 'Benalúa' },
  { label: 'Benalúa De Las Villas', value: 'Benalúa De Las Villas' },
  { label: 'Benalup-Casas Viejas', value: 'Benalup-Casas Viejas' },
  { label: 'Benamargosa', value: 'Benamargosa' },
  { label: 'Benamaurel', value: 'Benamaurel' },
  { label: 'Benamejí', value: 'Benamejí' },
  { label: 'Benamocarra', value: 'Benamocarra' },
  { label: 'Benaocaz', value: 'Benaocaz' },
  { label: 'Benaoján', value: 'Benaoján' },
  { label: 'Benaraby', value: 'Benaraby' },
  { label: 'Benares', value: 'Benares' },
  { label: 'Benarrabá', value: 'Benarrabá' },
  { label: 'Benasal', value: 'Benasal' },
  { label: 'Benasau', value: 'Benasau' },
  { label: 'Benasque', value: 'Benasque' },
  { label: 'Benassal', value: 'Benassal' },
  { label: 'Benatae', value: 'Benatae' },
  { label: 'Benátky Nad Jizerou', value: 'Benátky Nad Jizerou' },
  { label: 'Benaulim', value: 'Benaulim' },
  { label: 'Benavarri / Benabarre', value: 'Benavarri / Benabarre' },
  { label: 'Benavent De Segrià', value: 'Benavent De Segrià' },
  { label: 'Benavente', value: 'Benavente' },
  { label: 'Benavente', value: 'Benavente' },
  { label: 'Benavides', value: 'Benavides' },
  { label: 'Benavides', value: 'Benavides' },
  { label: 'Benavites', value: 'Benavites' },
  { label: 'Benbecula', value: 'Benbecula' },
  { label: 'Benbrook', value: 'Benbrook' },
  { label: 'Bench Maji Zone', value: 'Bench Maji Zone' },
  { label: 'Benchu', value: 'Benchu' },
  { label: 'Bend', value: 'Bend' },
  { label: 'Bendada', value: 'Bendada' },
  { label: 'Bende', value: 'Bende' },
  { label: 'Bender', value: 'Bender' },
  { label: 'Bendestorf', value: 'Bendestorf' },
  { label: 'Bendigo', value: 'Bendigo' },
  { label: 'Bendigo City Centre', value: 'Bendigo City Centre' },
  { label: 'Bendorf', value: 'Bendorf' },
  { label: 'Bene Lario', value: 'Bene Lario' },
  { label: 'Bene Vagienna', value: 'Bene Vagienna' },
  { label: 'Benecko', value: 'Benecko' },
  { label: 'Benedikt', value: 'Benedikt' },
  { label: 'Benediktbeuern', value: 'Benediktbeuern' },
  { label: 'Benedita', value: 'Benedita' },
  { label: 'Beneditinos', value: 'Beneditinos' },
  { label: 'Benedito Leite', value: 'Benedito Leite' },
  { label: 'Benedito Novo', value: 'Benedito Novo' },
  { label: 'Benegiles', value: 'Benegiles' },
  { label: 'Beneixama', value: 'Beneixama' },
  { label: 'Beneixida', value: 'Beneixida' },
  { label: 'Bénéjacq', value: 'Bénéjacq' },
  { label: 'Benejúzar', value: 'Benejúzar' },
  { label: 'Benemérito De Las Américas', value: 'Benemérito De Las Américas' },
  { label: 'Benemérito Juárez', value: 'Benemérito Juárez' },
  { label: 'Benesat', value: 'Benesat' },
  { label: 'Benešov', value: 'Benešov' },
  { label: 'Benešov Nad Černou', value: 'Benešov Nad Černou' },
  { label: 'Benešov Nad Ploučnicí', value: 'Benešov Nad Ploučnicí' },
  { label: 'Benespera', value: 'Benespera' },
  { label: 'Bénesse-Maremne', value: 'Bénesse-Maremne' },
  { label: 'Benestare', value: 'Benestare' },
  { label: 'Benet', value: 'Benet' },
  { label: 'Benetússer', value: 'Benetússer' },
  { label: 'Benetutti', value: 'Benetutti' },
  { label: 'Benevello', value: 'Benevello' },
  { label: 'Benevento', value: 'Benevento' },
  { label: 'Benevides', value: 'Benevides' },
  { label: 'Benewah County', value: 'Benewah County' },
  { label: 'Benfeld', value: 'Benfeld' },
  { label: 'Benferri', value: 'Benferri' },
  { label: 'Benfica', value: 'Benfica' },
  { label: 'Benfica', value: 'Benfica' },
  { label: 'Bengaluru', value: 'Bengaluru' },
  { label: 'Bengbu', value: 'Bengbu' },
  { label: 'Bengești', value: 'Bengești' },
  { label: 'Benghazi', value: 'Benghazi' },
  { label: 'Bengkayang', value: 'Bengkayang' },
  { label: 'Bengkulu', value: 'Bengkulu' },
  { label: 'Benglen', value: 'Benglen' },
  { label: 'Bengtsfors', value: 'Bengtsfors' },
  { label: 'Benguela', value: 'Benguela' },
  { label: 'Beni', value: 'Beni' },
  { label: 'Beni Amrane', value: 'Beni Amrane' },
  { label: 'Beni Douala', value: 'Beni Douala' },
  { label: 'Beni Hassane', value: 'Beni Hassane' },
  { label: 'Beni Khalled', value: 'Beni Khalled' },
  { label: 'Beni Kheddache', value: 'Beni Kheddache' },
  { label: 'Béni Khiar', value: 'Béni Khiar' },
  { label: 'Beni Mellal', value: 'Beni Mellal' },
  { label: 'Beni Mered', value: 'Beni Mered' },
  { label: 'Beni Mester', value: 'Beni Mester' },
  { label: 'Beni Saf', value: 'Beni Saf' },
  { label: 'Beniarbeig', value: 'Beniarbeig' },
  { label: 'Beniardá', value: 'Beniardá' },
  { label: 'Beniarjó', value: 'Beniarjó' },
  { label: 'Beniarrés', value: 'Beniarrés' },
  { label: 'Beniatjar', value: 'Beniatjar' },
  { label: 'Benicarló', value: 'Benicarló' },
  { label: 'Benicasim/Benicàssim', value: 'Benicasim/Benicàssim' },
  { label: 'Benicàssim', value: 'Benicàssim' },
  { label: 'Benicia', value: 'Benicia' },
  { label: 'Benicolet', value: 'Benicolet' },
  { label: 'Benicull De Xúquer', value: 'Benicull De Xúquer' },
  { label: 'Benidoleig', value: 'Benidoleig' },
  { label: 'Benidorm', value: 'Benidorm' },
  { label: 'Beniel', value: 'Beniel' },
  { label: 'Benifaió', value: 'Benifaió' },
  { label: 'Benifairó De La Valldigna', value: 'Benifairó De La Valldigna' },
  { label: 'Benifairó De Les Valls', value: 'Benifairó De Les Valls' },
  { label: 'Benifallet', value: 'Benifallet' },
  { label: 'Benifallim', value: 'Benifallim' },
  { label: 'Benifato', value: 'Benifato' },
  { label: 'Beniflá', value: 'Beniflá' },
  { label: 'Benigànim', value: 'Benigànim' },
  { label: 'Beniganj', value: 'Beniganj' },
  { label: 'Benigembla', value: 'Benigembla' },
  { label: 'Benijofar', value: 'Benijofar' },
  { label: 'Benilloba', value: 'Benilloba' },
  { label: 'Benillup', value: 'Benillup' },
  { label: 'Benimantell', value: 'Benimantell' },
  { label: 'Benimarfull', value: 'Benimarfull' },
  { label: 'Benimassot', value: 'Benimassot' },
  { label: 'Benimeli', value: 'Benimeli' },
  { label: 'Beni-Mellal', value: 'Beni-Mellal' },
  { label: 'Benimodo', value: 'Benimodo' },
  { label: 'Benimuslem', value: 'Benimuslem' },
  { label: 'Benin City', value: 'Benin City' },
  { label: 'Béning-Lès-Saint-Avold', value: 'Béning-Lès-Saint-Avold' },
  { label: 'Beniparrell', value: 'Beniparrell' },
  { label: 'Benirredrà', value: 'Benirredrà' },
  { label: 'Benisanó', value: 'Benisanó' },
  { label: 'Benisheikh', value: 'Benisheikh' },
  { label: 'Benissa', value: 'Benissa' },
  { label: 'Benissanet', value: 'Benissanet' },
  { label: 'Benissoda', value: 'Benissoda' },
  { label: 'Benisuera', value: 'Benisuera' },
  { label: 'Benitachell', value: 'Benitachell' },
  { label: 'Benitachell/Poble Nou De Benitatxell El', value: 'Benitachell/Poble Nou De Benitatxell El' },
  { label: 'Benitagla', value: 'Benitagla' },
  { label: 'Benitez', value: 'Benitez' },
  { label: 'Benito García (El Zorrillo)', value: 'Benito García (El Zorrillo)' },
  { label: 'Benito González', value: 'Benito González' },
  { label: 'Benito Juarez', value: 'Benito Juarez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez', value: 'Benito Juárez' },
  { label: 'Benito Juárez (La Playita)', value: 'Benito Juárez (La Playita)' },
  { label: 'Benito Juárez (Vinatería)', value: 'Benito Juárez (Vinatería)' },
  { label: 'Benito Juárez Ii (San Martín)', value: 'Benito Juárez Ii (San Martín)' },
  { label: 'Benito Juárez Uno', value: 'Benito Juárez Uno' },
  { label: 'Benito Soliven', value: 'Benito Soliven' },
  { label: 'Benito Soliven', value: 'Benito Soliven' },
  { label: 'Benizalón', value: 'Benizalón' },
  { label: 'Benjamin', value: 'Benjamin' },
  { label: 'Benjamin', value: 'Benjamin' },
  { label: 'Benjamín Aceval', value: 'Benjamín Aceval' },
  { label: 'Benjamin Constant', value: 'Benjamin Constant' },
  { label: 'Benjamin Constant Do Sul', value: 'Benjamin Constant Do Sul' },
  { label: 'Benjamín Hill', value: 'Benjamín Hill' },
  { label: 'Benkelman', value: 'Benkelman' },
  { label: 'Benken', value: 'Benken' },
  { label: 'Benkovac', value: 'Benkovac' },
  { label: 'Benld', value: 'Benld' },
  { label: 'Benllech', value: 'Benllech' },
  { label: 'Benlloch', value: 'Benlloch' },
  { label: 'Benna', value: 'Benna' },
  { label: 'Benndorf', value: 'Benndorf' },
  { label: 'Bennebroek', value: 'Bennebroek' },
  { label: 'Benneckenstein', value: 'Benneckenstein' },
  { label: 'Bennecourt', value: 'Bennecourt' },
  { label: 'Bennekom', value: 'Bennekom' },
  { label: 'Bennett', value: 'Bennett' },
  { label: 'Bennett County', value: 'Bennett County' },
  { label: 'Bennett Springs', value: 'Bennett Springs' },
  { label: 'Bennettsville', value: 'Bennettsville' },
  { label: 'Bennewitz', value: 'Bennewitz' },
  { label: 'Benningbroek', value: 'Benningbroek' },
  { label: 'Benningen', value: 'Benningen' },
  { label: 'Benningen Am Neckar', value: 'Benningen Am Neckar' },
  { label: 'Bennington', value: 'Bennington' },
  { label: 'Bennington', value: 'Bennington' },
  { label: 'Bennington County', value: 'Bennington County' },
  { label: 'Bennstedt', value: 'Bennstedt' },
  { label: 'Bennsville', value: 'Bennsville' },
  { label: 'Bennungen', value: 'Bennungen' },
  { label: 'Bennwihr', value: 'Bennwihr' },
  { label: 'Bénodet', value: 'Bénodet' },
  { label: 'Benoni', value: 'Benoni' },
  { label: 'Bénouville', value: 'Bénouville' },
  { label: 'Benowa', value: 'Benowa' },
  { label: 'Benoy', value: 'Benoy' },
  { label: 'Benoy-Yurt', value: 'Benoy-Yurt' },
  { label: 'Benque Viejo El Carmen', value: 'Benque Viejo El Carmen' },
  { label: 'Benquerencia', value: 'Benquerencia' },
  { label: 'Benquerencia De La Serena', value: 'Benquerencia De La Serena' },
  { label: 'Benquet', value: 'Benquet' },
  { label: 'Bensafrim', value: 'Bensafrim' },
  { label: 'Bensdorf', value: 'Bensdorf' },
  { label: 'Bensekrane', value: 'Bensekrane' },
  { label: 'Bensenville', value: 'Bensenville' },
  { label: 'Benshausen', value: 'Benshausen' },
  { label: 'Bensheim', value: 'Bensheim' },
  { label: 'Bensley', value: 'Bensley' },
  { label: 'Benslimane', value: 'Benslimane' },
  { label: 'Benson', value: 'Benson' },
  { label: 'Benson', value: 'Benson' },
  { label: 'Benson', value: 'Benson' },
  { label: 'Benson', value: 'Benson' },
  { label: 'Benson', value: 'Benson' },
  { label: 'Benson County', value: 'Benson County' },
  { label: 'Bensonhurst', value: 'Bensonhurst' },
  { label: 'Bensonton', value: 'Bensonton' },
  { label: 'Bensonville', value: 'Bensonville' },
  { label: 'Bensville', value: 'Bensville' },
  { label: 'Bent', value: 'Bent' },
  { label: 'Bent County', value: 'Bent County' },
  { label: 'Bent Creek', value: 'Bent Creek' },
  { label: 'Bentarique', value: 'Bentarique' },
  { label: 'Benthuizen', value: 'Benthuizen' },
  { label: 'Bentivoglio', value: 'Bentivoglio' },
  { label: 'Bentleigh', value: 'Bentleigh' },
  { label: 'Bentleigh East', value: 'Bentleigh East' },
  { label: 'Bentley', value: 'Bentley' },
  { label: 'Bentley', value: 'Bentley' },
  { label: 'Bentley Park', value: 'Bentley Park' },
  { label: 'Bentleyville', value: 'Bentleyville' },
  { label: 'Bento De Abreu', value: 'Bento De Abreu' },
  { label: 'Bento Fernandes', value: 'Bento Fernandes' },
  { label: 'Bento Gonçalves', value: 'Bento Gonçalves' },
  { label: 'Benton', value: 'Benton' },
  { label: 'Benton', value: 'Benton' },
  { label: 'Benton', value: 'Benton' },
  { label: 'Benton', value: 'Benton' },
  { label: 'Benton', value: 'Benton' },
  { label: 'Benton', value: 'Benton' },
  { label: 'Benton', value: 'Benton' },
  { label: 'Benton City', value: 'Benton City' },
  { label: 'Benton County', value: 'Benton County' },
  { label: 'Benton County', value: 'Benton County' },
  { label: 'Benton County', value: 'Benton County' },
  { label: 'Benton County', value: 'Benton County' },
  { label: 'Benton County', value: 'Benton County' },
  { label: 'Benton County', value: 'Benton County' },
  { label: 'Benton County', value: 'Benton County' },
  { label: 'Benton County', value: 'Benton County' },
  { label: 'Benton County', value: 'Benton County' },
  { label: 'Benton Harbor', value: 'Benton Harbor' },
  { label: 'Benton Heights', value: 'Benton Heights' },
  { label: 'Bentong Town', value: 'Bentong Town' },
  { label: 'Bentonville', value: 'Bentonville' },
  { label: 'Bentota', value: 'Bentota' },
  { label: 'Bentveld', value: 'Bentveld' },
  { label: 'Bentwisch', value: 'Bentwisch' },
  { label: 'Bentzin', value: 'Bentzin' },
  { label: 'Benuza', value: 'Benuza' },
  { label: 'Benwell', value: 'Benwell' },
  { label: 'Benwood', value: 'Benwood' },
  { label: 'Benxi', value: 'Benxi' },
  { label: 'Benzie County', value: 'Benzie County' },
  { label: 'Beohari', value: 'Beohari' },
  { label: 'Beolgyo', value: 'Beolgyo' },
  { label: 'Béoumi', value: 'Béoumi' },
  { label: 'Beppu', value: 'Beppu' },
  { label: 'Beppu Shi', value: 'Beppu Shi' },
  { label: 'Bequimão', value: 'Bequimão' },
  { label: 'Bera', value: 'Bera' },
  { label: 'Berakas A', value: 'Berakas A' },
  { label: 'Berala', value: 'Berala' },
  { label: 'Beranci', value: 'Beranci' },
  { label: 'Berane', value: 'Berane' },
  { label: 'Berango', value: 'Berango' },
  { label: 'Berani', value: 'Berani' },
  { label: 'Berantevilla', value: 'Berantevilla' },
  { label: 'Beranuy', value: 'Beranuy' },
  { label: 'Berasia', value: 'Berasia' },
  { label: 'Berastagi', value: 'Berastagi' },
  { label: 'Berastegi', value: 'Berastegi' },
  { label: 'Berat', value: 'Berat' },
  { label: 'Bérat', value: 'Bérat' },
  { label: 'Beratón', value: 'Beratón' },
  { label: 'Beratzhausen', value: 'Beratzhausen' },
  { label: 'Berbegal', value: 'Berbegal' },
  { label: 'Berbenno', value: 'Berbenno' },
  { label: 'Berbenno Di Valtellina', value: 'Berbenno Di Valtellina' },
  { label: 'Berbeo', value: 'Berbeo' },
  { label: 'Berber', value: 'Berber' },
  { label: 'Berberana', value: 'Berberana' },
  { label: 'Berberati', value: 'Berberati' },
  { label: 'Berbeşti', value: 'Berbeşti' },
  { label: 'Berbești', value: 'Berbești' },
  { label: 'Berbinzana', value: 'Berbinzana' },
  { label: 'Berca', value: 'Berca' },
  { label: 'Bercel', value: 'Bercel' },
  { label: 'Berceni', value: 'Berceni' },
  { label: 'Berceni', value: 'Berceni' },
  { label: 'Berceo', value: 'Berceo' },
  { label: 'Bercero', value: 'Bercero' },
  { label: 'Berceruelo', value: 'Berceruelo' },
  { label: 'Berceto', value: 'Berceto' },
  { label: 'Berchez', value: 'Berchez' },
  { label: 'Berchidda', value: 'Berchidda' },
  { label: 'Berching', value: 'Berching' },
  { label: 'Berchișești', value: 'Berchișești' },
  { label: 'Berchtesgaden', value: 'Berchtesgaden' },
  { label: 'Bérchules', value: 'Bérchules' },
  { label: 'Bercial', value: 'Bercial' },
  { label: 'Bercial De Zapardiel', value: 'Bercial De Zapardiel' },
  { label: 'Bercianos Del Páramo', value: 'Bercianos Del Páramo' },
  { label: 'Bercianos Del Real Camino', value: 'Bercianos Del Real Camino' },
  { label: 'Bercimuel', value: 'Bercimuel' },
  { label: 'Berck', value: 'Berck' },
  { label: 'Berck-Plage', value: 'Berck-Plage' },
  { label: 'Berd', value: 'Berd' },
  { label: 'Berdavan', value: 'Berdavan' },
  { label: 'Berdejo', value: 'Berdejo' },
  { label: 'Berdiansk Raion', value: 'Berdiansk Raion' },
  { label: 'Berdigestyakh', value: 'Berdigestyakh' },
  { label: 'Berdorf', value: 'Berdorf' },
  { label: 'Berdsk', value: 'Berdsk' },
  { label: 'Berdyans’Ka Mis’Krada', value: 'Berdyans’Ka Mis’Krada' },
  { label: 'Berdyansk', value: 'Berdyansk' },
  { label: 'Berdyaush', value: 'Berdyaush' },
  { label: 'Berdychiv', value: 'Berdychiv' },
  { label: 'Berdychiv', value: 'Berdychiv' },
  { label: 'Berdychivskyy Rayon', value: 'Berdychivskyy Rayon' },
  { label: 'Berdyuzhskiy Rayon', value: 'Berdyuzhskiy Rayon' },
  { label: 'Béré', value: 'Béré' },
  { label: 'Béré', value: 'Béré' },
  { label: 'Bere Alston', value: 'Bere Alston' },
  { label: 'Bere Regis', value: 'Bere Regis' },
  { label: 'Berea', value: 'Berea' },
  { label: 'Berea', value: 'Berea' },
  { label: 'Berea', value: 'Berea' },
  { label: 'Berea', value: 'Berea' },
  { label: 'Bereeda', value: 'Bereeda' },
  { label: 'Beregazzo', value: 'Beregazzo' },
  { label: 'Beregazzo Con Figliaro', value: 'Beregazzo Con Figliaro' },
  { label: 'Beregovoy', value: 'Beregovoy' },
  { label: 'Beregovoye', value: 'Beregovoye' },
  { label: 'Beregsău Mare', value: 'Beregsău Mare' },
  { label: 'Bereguardo', value: 'Bereguardo' },
  { label: 'Berehivs’Ka Mis’Krada', value: 'Berehivs’Ka Mis’Krada' },
  { label: 'Berehomet', value: 'Berehomet' },
  { label: 'Berehove', value: 'Berehove' },
  { label: 'Berehove Raion', value: 'Berehove Raion' },
  { label: 'Bereket', value: 'Bereket' },
  { label: 'Berekua', value: 'Berekua' },
  { label: 'Berekum East', value: 'Berekum East' },
  { label: 'Berekum West', value: 'Berekum West' },
  { label: 'Béreldange', value: 'Béreldange' },
  { label: 'Beremend', value: 'Beremend' },
  { label: 'Berendeyevo', value: 'Berendeyevo' },
  { label: 'Bereni', value: 'Bereni' },
  { label: 'Beresfield', value: 'Beresfield' },
  { label: 'Beresford', value: 'Beresford' },
  { label: 'Beresford', value: 'Beresford' },
  { label: 'Bereslavka', value: 'Bereslavka' },
  { label: 'Berestechko', value: 'Berestechko' },
  { label: 'Berești', value: 'Berești' },
  { label: 'Berești', value: 'Berești' },
  { label: 'Bereşti-Bistriţa', value: 'Bereşti-Bistriţa' },
  { label: 'Bereşti-Sat', value: 'Bereşti-Sat' },
  { label: 'Bereşti-Tazlău', value: 'Bereşti-Tazlău' },
  { label: 'Berestivka', value: 'Berestivka' },
  { label: 'Berestovets', value: 'Berestovets' },
  { label: 'Beretinec', value: 'Beretinec' },
  { label: 'Berettyóújfalu', value: 'Berettyóújfalu' },
  { label: 'Berettyóújfalui Járás', value: 'Berettyóújfalui Járás' },
  { label: 'Berevoeşti', value: 'Berevoeşti' },
  { label: 'Berëza', value: 'Berëza' },
  { label: 'Berezanka', value: 'Berezanka' },
  { label: 'Berezanskaya', value: 'Berezanskaya' },
  { label: 'Berezayka', value: 'Berezayka' },
  { label: 'Berezeni', value: 'Berezeni' },
  { label: 'Berezianka', value: 'Berezianka' },
  { label: 'Berezivka', value: 'Berezivka' },
  { label: 'Berezivka', value: 'Berezivka' },
  { label: 'Berezna', value: 'Berezna' },
  { label: 'Bereznehuvate', value: 'Bereznehuvate' },
  { label: 'Berëznik', value: 'Berëznik' },
  { label: 'Berezniki', value: 'Berezniki' },
  { label: 'Bereznyaki', value: 'Bereznyaki' },
  { label: 'Bereznyky', value: 'Bereznyky' },
  { label: 'Berezovii Grud', value: 'Berezovii Grud' },
  { label: 'Berezovka', value: 'Berezovka' },
  { label: 'Berëzovka', value: 'Berëzovka' },
  { label: 'Berëzovka', value: 'Berëzovka' },
  { label: 'Berezovo', value: 'Berezovo' },
  { label: 'Berëzovo', value: 'Berëzovo' },
  { label: 'Berëzovskiy', value: 'Berëzovskiy' },
  { label: 'Berezovskiy Rayon', value: 'Berezovskiy Rayon' },
  { label: 'Berëzovyy', value: 'Berëzovyy' },
  { label: 'Bereztsi', value: 'Bereztsi' },
  { label: 'Berezyne', value: 'Berezyne' },
  { label: 'Berg', value: 'Berg' },
  { label: 'Berg', value: 'Berg' },
  { label: 'Berg', value: 'Berg' },
  { label: 'Berg', value: 'Berg' },
  { label: 'Berg', value: 'Berg' },
  { label: 'Berg', value: 'Berg' },
  { label: 'Berg', value: 'Berg' },
  { label: 'Berg', value: 'Berg' },
  { label: 'Berg En Bos', value: 'Berg En Bos' },
  { label: 'Berg En Dal', value: 'Berg En Dal' },
  { label: 'Berg Im Drautal', value: 'Berg Im Drautal' },
  { label: 'Berg Im Gau', value: 'Berg Im Gau' },
  { label: 'Berga', value: 'Berga' },
  { label: 'Berga', value: 'Berga' },
  { label: 'Berga', value: 'Berga' },
  { label: 'Bergama', value: 'Bergama' },
  { label: 'Bergamasco', value: 'Bergamasco' },
  { label: 'Bergamo', value: 'Bergamo' },
  { label: 'Bergantino', value: 'Bergantino' },
  { label: 'Bergara', value: 'Bergara' },
  { label: 'Bergasa', value: 'Bergasa' },
  { label: 'Bergasillas Bajera', value: 'Bergasillas Bajera' },
  { label: 'Bergatreute', value: 'Bergatreute' },
  { label: 'Bergby', value: 'Bergby' },
  { label: 'Berge', value: 'Berge' },
  { label: 'Berge', value: 'Berge' },
  { label: 'Bergedorf', value: 'Bergedorf' },
  { label: 'Bergeforsen', value: 'Bergeforsen' },
  { label: 'Bergeggi', value: 'Bergeggi' },
  { label: 'Bergeijk', value: 'Bergeijk' },
  { label: 'Bergem', value: 'Bergem' },
  { label: 'Bergen', value: 'Bergen' },
  { label: 'Bergen', value: 'Bergen' },
  { label: 'Bergen', value: 'Bergen' },
  { label: 'Bergen', value: 'Bergen' },
  { label: 'Bergen', value: 'Bergen' },
  { label: 'Bergen', value: 'Bergen' },
  { label: 'Bergen An Der Dumme', value: 'Bergen An Der Dumme' },
  { label: 'Bergen Auf Rügen', value: 'Bergen Auf Rügen' },
  { label: 'Bergen Beach', value: 'Bergen Beach' },
  { label: 'Bergen County', value: 'Bergen County' },
  { label: 'Bergen Op Zoom', value: 'Bergen Op Zoom' },
  { label: 'Bergenfield', value: 'Bergenfield' },
  { label: 'Berger', value: 'Berger' },
  { label: 'Bergerac', value: 'Bergerac' },
  { label: 'Bergern', value: 'Bergern' },
  { label: 'Bergham', value: 'Bergham' },
  { label: 'Bergharen', value: 'Bergharen' },
  { label: 'Berghaupten', value: 'Berghaupten' },
  { label: 'Bergheim', value: 'Bergheim' },
  { label: 'Bergheim', value: 'Bergheim' },
  { label: 'Bergheim', value: 'Bergheim' },
  { label: 'Berghem', value: 'Berghem' },
  { label: 'Berghia', value: 'Berghia' },
  { label: 'Berghin', value: 'Berghin' },
  { label: 'Bergholtz', value: 'Bergholtz' },
  { label: 'Berghülen', value: 'Berghülen' },
  { label: 'Bergisch Gladbach', value: 'Bergisch Gladbach' },
  { label: 'Bergkamen', value: 'Bergkamen' },
  { label: 'Bergkirchen', value: 'Bergkirchen' },
  { label: 'Bergkvara', value: 'Bergkvara' },
  { label: 'Bergland', value: 'Bergland' },
  { label: 'Berglern', value: 'Berglern' },
  { label: 'Bergnäset', value: 'Bergnäset' },
  { label: 'Bergneustadt', value: 'Bergneustadt' },
  { label: 'Bergolo', value: 'Bergolo' },
  { label: 'Bergondo', value: 'Bergondo' },
  { label: 'Bergrheinfeld', value: 'Bergrheinfeld' },
  { label: 'Bergschenhoek', value: 'Bergschenhoek' },
  { label: 'Bergshamra', value: 'Bergshamra' },
  { label: 'Bergsjö', value: 'Bergsjö' },
  { label: 'Bergstedt', value: 'Bergstedt' },
  { label: 'Bergstoep', value: 'Bergstoep' },
  { label: 'Bergsviken', value: 'Bergsviken' },
  { label: 'Bergtheim', value: 'Bergtheim' },
  { label: 'Bergues', value: 'Bergues' },
  { label: 'Bergvliet', value: 'Bergvliet' },
  { label: 'Bergwitz', value: 'Bergwitz' },
  { label: 'Berhampore', value: 'Berhampore' },
  { label: 'Berhida', value: 'Berhida' },
  { label: 'Beri Khas', value: 'Beri Khas' },
  { label: 'Beriáin', value: 'Beriáin' },
  { label: 'Berikon', value: 'Berikon' },
  { label: 'Berilo', value: 'Berilo' },
  { label: 'Beringe', value: 'Beringe' },
  { label: 'Beringel', value: 'Beringel' },
  { label: 'Beringen', value: 'Beringen' },
  { label: 'Beringen', value: 'Beringen' },
  { label: 'Beringovskiy', value: 'Beringovskiy' },
  { label: 'Berino', value: 'Berino' },
  { label: 'Berința', value: 'Berința' },
  { label: 'Berislăveşti', value: 'Berislăveşti' },
  { label: 'Beristain', value: 'Beristain' },
  { label: 'Beriu', value: 'Beriu' },
  { label: 'Berizal', value: 'Berizal' },
  { label: 'Berja', value: 'Berja' },
  { label: 'Berk’Anush', value: 'Berk’Anush' },
  { label: 'Berkåk', value: 'Berkåk' },
  { label: 'Berkakit', value: 'Berkakit' },
  { label: 'Berkane', value: 'Berkane' },
  { label: 'Berkel En Rodenrijs', value: 'Berkel En Rodenrijs' },
  { label: 'Berkeley', value: 'Berkeley' },
  { label: 'Berkeley', value: 'Berkeley' },
  { label: 'Berkeley', value: 'Berkeley' },
  { label: 'Berkeley', value: 'Berkeley' },
  { label: 'Berkeley', value: 'Berkeley' },
  { label: 'Berkeley County', value: 'Berkeley County' },
  { label: 'Berkeley County', value: 'Berkeley County' },
  { label: 'Berkeley Heights', value: 'Berkeley Heights' },
  { label: 'Berkeley Lake', value: 'Berkeley Lake' },
  { label: 'Berkeley Springs', value: 'Berkeley Springs' },
  { label: 'Berkeley Vale', value: 'Berkeley Vale' },
  { label: 'Berkelland', value: 'Berkelland' },
  { label: 'Berkenbrück', value: 'Berkenbrück' },
  { label: 'Berkenthin', value: 'Berkenthin' },
  { label: 'Berkenwoude', value: 'Berkenwoude' },
  { label: 'Berkhamsted', value: 'Berkhamsted' },
  { label: 'Berkheim', value: 'Berkheim' },
  { label: 'Berkhout', value: 'Berkhout' },
  { label: 'Berkley', value: 'Berkley' },
  { label: 'Berkley', value: 'Berkley' },
  { label: 'Berkley', value: 'Berkley' },
  { label: 'Berkmeer', value: 'Berkmeer' },
  { label: 'Berkovitsa', value: 'Berkovitsa' },
  { label: 'Berks County', value: 'Berks County' },
  { label: 'Berkshire', value: 'Berkshire' },
  { label: 'Berkshire County', value: 'Berkshire County' },
  { label: 'Berkshire Park', value: 'Berkshire Park' },
  { label: 'Berkum', value: 'Berkum' },
  { label: 'Berlaar', value: 'Berlaar' },
  { label: 'Berlaimont', value: 'Berlaimont' },
  { label: 'Berlanas Las', value: 'Berlanas Las' },
  { label: 'Berlanga', value: 'Berlanga' },
  { label: 'Berlanga De Duero', value: 'Berlanga De Duero' },
  { label: 'Berlanga Del Bierzo', value: 'Berlanga Del Bierzo' },
  { label: 'Berlangas De Roa', value: 'Berlangas De Roa' },
  { label: 'Berlare', value: 'Berlare' },
  { label: 'Berleşti', value: 'Berleşti' },
  { label: 'Berlevåg', value: 'Berlevåg' },
  { label: 'Berlicum', value: 'Berlicum' },
  { label: 'Berlin', value: 'Berlin' },
  { label: 'Berlin', value: 'Berlin' },
  { label: 'Berlin', value: 'Berlin' },
  { label: 'Berlin', value: 'Berlin' },
  { label: 'Berlin', value: 'Berlin' },
  { label: 'Berlin', value: 'Berlin' },
  { label: 'Berlin', value: 'Berlin' },
  { label: 'Berlín', value: 'Berlín' },
  { label: 'Berlín', value: 'Berlín' },
  { label: 'Berlin Köpenick', value: 'Berlin Köpenick' },
  { label: 'Berlin Treptow', value: 'Berlin Treptow' },
  { label: 'Berlingerode', value: 'Berlingerode' },
  { label: 'Berlingo', value: 'Berlingo' },
  { label: 'Berlişte', value: 'Berlişte' },
  { label: 'Berloz', value: 'Berloz' },
  { label: 'Berlstedt', value: 'Berlstedt' },
  { label: 'Berltsum', value: 'Berltsum' },
  { label: 'Bermagui', value: 'Bermagui' },
  { label: 'Bermatingen', value: 'Bermatingen' },
  { label: 'Bermejillo', value: 'Bermejillo' },
  { label: 'Bermejo', value: 'Bermejo' },
  { label: 'Bermellar', value: 'Bermellar' },
  { label: 'Bermeo', value: 'Bermeo' },
  { label: 'Bermillo De Sayago', value: 'Bermillo De Sayago' },
  { label: 'Bermuda Dunes', value: 'Bermuda Dunes' },
  { label: 'Bermuda Run', value: 'Bermuda Run' },
  { label: 'Bern', value: 'Bern' },
  { label: 'Bernadea', value: 'Bernadea' },
  { label: 'Bernal', value: 'Bernal' },
  { label: 'Bernal', value: 'Bernal' },
  { label: 'Bernalda', value: 'Bernalda' },
  { label: 'Bernalillo', value: 'Bernalillo' },
  { label: 'Bernalillo County', value: 'Bernalillo County' },
  { label: 'Bernardin', value: 'Bernardin' },
  { label: 'Bernardino Batista', value: 'Bernardino Batista' },
  { label: 'Bernardino De Campos', value: 'Bernardino De Campos' },
  { label: 'Bernardo De Irigoyen', value: 'Bernardo De Irigoyen' },
  { label: 'Bernardo Do Mearim', value: 'Bernardo Do Mearim' },
  { label: 'Bernardo Larroudé', value: 'Bernardo Larroudé' },
  { label: 'Bernardo Sayão', value: 'Bernardo Sayão' },
  { label: 'Bernardos', value: 'Bernardos' },
  { label: 'Bernardston', value: 'Bernardston' },
  { label: 'Bernardsville', value: 'Bernardsville' },
  { label: 'Bernardswiller', value: 'Bernardswiller' },
  { label: 'Bernareggio', value: 'Bernareggio' },
  { label: 'Bernartice', value: 'Bernartice' },
  { label: 'Bernasconi', value: 'Bernasconi' },
  { label: 'Bernate Ticino', value: 'Bernate Ticino' },
  { label: 'Bernau', value: 'Bernau' },
  { label: 'Bernau Am Chiemsee', value: 'Bernau Am Chiemsee' },
  { label: 'Bernau Bei Berlin', value: 'Bernau Bei Berlin' },
  { label: 'Bernaville', value: 'Bernaville' },
  { label: 'Bernay', value: 'Bernay' },
  { label: 'Bernbeuren', value: 'Bernbeuren' },
  { label: 'Bernburg', value: 'Bernburg' },
  { label: 'Berndorf', value: 'Berndorf' },
  { label: 'Berndorf', value: 'Berndorf' },
  { label: 'Berndorf Bei Salzburg', value: 'Berndorf Bei Salzburg' },
  { label: 'Berne', value: 'Berne' },
  { label: 'Berné', value: 'Berné' },
  { label: 'Bernedo', value: 'Bernedo' },
  { label: 'Bernes-Sur-Oise', value: 'Bernes-Sur-Oise' },
  { label: 'Berneval-Le-Grand', value: 'Berneval-Le-Grand' },
  { label: 'Bernex', value: 'Bernex' },
  { label: 'Bernezzo', value: 'Bernezzo' },
  { label: 'Berngau', value: 'Berngau' },
  { label: 'Bernhardsthal', value: 'Bernhardsthal' },
  { label: 'Bernhardswald', value: 'Bernhardswald' },
  { label: 'Bernice', value: 'Bernice' },
  { label: 'Bernie', value: 'Bernie' },
  { label: 'Bernières-Sur-Mer', value: 'Bernières-Sur-Mer' },
  { label: 'Bernin', value: 'Bernin' },
  { label: 'Berninches', value: 'Berninches' },
  { label: 'Bernis', value: 'Bernis' },
  { label: 'Bernissart', value: 'Bernissart' },
  { label: 'Bernitt', value: 'Bernitt' },
  { label: 'Bernkastel-Kues', value: 'Bernkastel-Kues' },
  { label: 'Bern-Mittelland District', value: 'Bern-Mittelland District' },
  { label: 'Bernovo', value: 'Bernovo' },
  { label: 'Bernried', value: 'Bernried' },
  { label: 'Bernsbach', value: 'Bernsbach' },
  { label: 'Bernsdorf', value: 'Bernsdorf' },
  { label: 'Bernstadt', value: 'Bernstadt' },
  { label: 'Bernstadt', value: 'Bernstadt' },
  { label: 'Bernstein', value: 'Bernstein' },
  { label: 'Bernuy De Porreros', value: 'Bernuy De Porreros' },
  { label: 'Bernuy-Zapardiel', value: 'Bernuy-Zapardiel' },
  { label: 'Berolle', value: 'Berolle' },
  { label: 'Beromünster', value: 'Beromünster' },
  { label: 'Berón De Astrada', value: 'Berón De Astrada' },
  { label: 'Beroun', value: 'Beroun' },
  { label: 'Berovo', value: 'Berovo' },
  { label: 'Berowra', value: 'Berowra' },
  { label: 'Berowra Heights', value: 'Berowra Heights' },
  { label: 'Berra', value: 'Berra' },
  { label: 'Berrahal', value: 'Berrahal' },
  { label: 'Berrechid', value: 'Berrechid' },
  { label: 'Berrechid Province', value: 'Berrechid Province' },
  { label: 'Berre-Les-Alpes', value: 'Berre-Les-Alpes' },
  { label: 'Berre-Létang', value: 'Berre-Létang' },
  { label: 'Berri', value: 'Berri' },
  { label: 'Berri And Barmera', value: 'Berri And Barmera' },
  { label: 'Berriane', value: 'Berriane' },
  { label: 'Berriat', value: 'Berriat' },
  { label: 'Berriatua', value: 'Berriatua' },
  { label: 'Berric', value: 'Berric' },
  { label: 'Berridale', value: 'Berridale' },
  { label: 'Berriedale', value: 'Berriedale' },
  { label: 'Berrien', value: 'Berrien' },
  { label: 'Berrien County', value: 'Berrien County' },
  { label: 'Berrien County', value: 'Berrien County' },
  { label: 'Berrien Springs', value: 'Berrien Springs' },
  { label: 'Berrigan', value: 'Berrigan' },
  { label: 'Berrimah', value: 'Berrimah' },
  { label: 'Berrinba', value: 'Berrinba' },
  { label: 'Berriobeiti', value: 'Berriobeiti' },
  { label: 'Berrioplano/Berriobeiti', value: 'Berrioplano/Berriobeiti' },
  { label: 'Berriozábal', value: 'Berriozábal' },
  { label: 'Berriozar', value: 'Berriozar' },
  { label: 'Berriz', value: 'Berriz' },
  { label: 'Berrobi', value: 'Berrobi' },
  { label: 'Berrocal', value: 'Berrocal' },
  { label: 'Berrocal De Huebra', value: 'Berrocal De Huebra' },
  { label: 'Berrocal De Salvatierra', value: 'Berrocal De Salvatierra' },
  { label: 'Berrocalejo', value: 'Berrocalejo' },
  { label: 'Berrocalejo De Aragona', value: 'Berrocalejo De Aragona' },
  { label: 'Berrotarán', value: 'Berrotarán' },
  { label: 'Berrouaghia', value: 'Berrouaghia' },
  { label: 'Berrueces', value: 'Berrueces' },
  { label: 'Berrueco', value: 'Berrueco' },
  { label: 'Berrueco El', value: 'Berrueco El' },
  { label: 'Berrwiller', value: 'Berrwiller' },
  { label: 'Berry', value: 'Berry' },
  { label: 'Berry', value: 'Berry' },
  { label: 'Berry Creek', value: 'Berry Creek' },
  { label: 'Berryville', value: 'Berryville' },
  { label: 'Berryville', value: 'Berryville' },
  { label: 'Berryville', value: 'Berryville' },
  { label: 'Bersée', value: 'Bersée' },
  { label: 'Bersenbrück', value: 'Bersenbrück' },
  { label: 'Berserker', value: 'Berserker' },
  { label: 'Bersezio', value: 'Bersezio' },
  { label: 'Bershad', value: 'Bershad' },
  { label: 'Bershet’', value: 'Bershet’' },
  { label: 'Berson', value: 'Berson' },
  { label: 'Bersone', value: 'Bersone' },
  { label: 'Berstadt', value: 'Berstadt' },
  { label: 'Berstett', value: 'Berstett' },
  { label: 'Bertea', value: 'Bertea' },
  { label: 'Berteaucourt-Les-Dames', value: 'Berteaucourt-Les-Dames' },
  { label: 'Bertem', value: 'Bertem' },
  { label: 'Berteştii De Jos', value: 'Berteştii De Jos' },
  { label: 'Berthecourt', value: 'Berthecourt' },
  { label: 'Berthelsdorf', value: 'Berthelsdorf' },
  { label: 'Berthierville', value: 'Berthierville' },
  { label: 'Berthoud', value: 'Berthoud' },
  { label: 'Bertie County', value: 'Bertie County' },
  { label: 'Bertinoro', value: 'Bertinoro' },
  { label: 'Bertioga', value: 'Bertioga' },
  { label: 'Bertiolo', value: 'Bertiolo' },
  { label: 'Bertipaglia', value: 'Bertipaglia' },
  { label: 'Bertizarana', value: 'Bertizarana' },
  { label: 'Bertogne', value: 'Bertogne' },
  { label: 'Bertolínia', value: 'Bertolínia' },
  { label: 'Bertonico', value: 'Bertonico' },
  { label: 'Bertópolis', value: 'Bertópolis' },
  { label: 'Bertoua', value: 'Bertoua' },
  { label: 'Bertram', value: 'Bertram' },
  { label: 'Bertram', value: 'Bertram' },
  { label: 'Bertrange', value: 'Bertrange' },
  { label: 'Bertrange', value: 'Bertrange' },
  { label: 'Bertrix', value: 'Bertrix' },
  { label: 'Bertry', value: 'Bertry' },
  { label: 'Bertsch-Oceanview', value: 'Bertsch-Oceanview' },
  { label: 'Bertsdorf-Hörnitz', value: 'Bertsdorf-Hörnitz' },
  { label: 'Beru', value: 'Beru' },
  { label: 'Béruges', value: 'Béruges' },
  { label: 'Berumbur', value: 'Berumbur' },
  { label: 'Beruniy', value: 'Beruniy' },
  { label: 'Beruri', value: 'Beruri' },
  { label: 'Beruwala', value: 'Beruwala' },
  { label: 'Berveni', value: 'Berveni' },
  { label: 'Berwang', value: 'Berwang' },
  { label: 'Berwick', value: 'Berwick' },
  { label: 'Berwick', value: 'Berwick' },
  { label: 'Berwick', value: 'Berwick' },
  { label: 'Berwick', value: 'Berwick' },
  { label: 'Berwick', value: 'Berwick' },
  { label: 'Berwick-Upon-Tweed', value: 'Berwick-Upon-Tweed' },
  { label: 'Berwyn', value: 'Berwyn' },
  { label: 'Berwyn', value: 'Berwyn' },
  { label: 'Berwyn Heights', value: 'Berwyn Heights' },
  { label: 'Beryozovsky', value: 'Beryozovsky' },
  { label: 'Beryslav', value: 'Beryslav' },
  { label: 'Berzano Di San Pietro', value: 'Berzano Di San Pietro' },
  { label: 'Berzano Di Tortona', value: 'Berzano Di Tortona' },
  { label: 'Berzantina', value: 'Berzantina' },
  { label: 'Berzasca', value: 'Berzasca' },
  { label: 'Berzence', value: 'Berzence' },
  { label: 'Berzo', value: 'Berzo' },
  { label: 'Berzo Inferiore', value: 'Berzo Inferiore' },
  { label: 'Berzo San Fermo', value: 'Berzo San Fermo' },
  { label: 'Berzocana', value: 'Berzocana' },
  { label: 'Berzosa De Bureba', value: 'Berzosa De Bureba' },
  { label: 'Berzosa Del Lozoya', value: 'Berzosa Del Lozoya' },
  { label: 'Berzosilla', value: 'Berzosilla' },
  { label: 'Berzovia', value: 'Berzovia' },
  { label: 'Berzunţi', value: 'Berzunţi' },
  { label: 'Besalú', value: 'Besalú' },
  { label: 'Besana In Brianza', value: 'Besana In Brianza' },
  { label: 'Besançon', value: 'Besançon' },
  { label: 'Besano', value: 'Besano' },
  { label: 'Besao', value: 'Besao' },
  { label: 'Besao', value: 'Besao' },
  { label: 'Besate', value: 'Besate' },
  { label: 'Besbes', value: 'Besbes' },
  { label: 'Bescanó', value: 'Bescanó' },
  { label: 'Besenbüren', value: 'Besenbüren' },
  { label: 'Besenello', value: 'Besenello' },
  { label: 'Besenyőtelek', value: 'Besenyőtelek' },
  { label: 'Besenyszög', value: 'Besenyszög' },
  { label: 'Besenzone', value: 'Besenzone' },
  { label: 'Beshariq', value: 'Beshariq' },
  { label: 'Beshik Tappeh', value: 'Beshik Tappeh' },
  { label: 'Beshkent', value: 'Beshkent' },
  { label: 'Beshpagir', value: 'Beshpagir' },
  { label: 'Besigheim', value: 'Besigheim' },
  { label: 'Beşikdüzü', value: 'Beşikdüzü' },
  { label: 'Besiko', value: 'Besiko' },
  { label: 'Beşiktaş', value: 'Beşiktaş' },
  { label: 'Beşiri', value: 'Beşiri' },
  { label: 'Beška', value: 'Beška' },
  { label: 'Besko', value: 'Besko' },
  { label: 'Beslan', value: 'Beslan' },
  { label: 'Besleney', value: 'Besleney' },
  { label: 'Besleneyevskaya', value: 'Besleneyevskaya' },
  { label: 'Besnate', value: 'Besnate' },
  { label: 'Besné', value: 'Besné' },
  { label: 'Besni', value: 'Besni' },
  { label: 'Besoijen', value: 'Besoijen' },
  { label: 'Besozzo', value: 'Besozzo' },
  { label: 'Bessan', value: 'Bessan' },
  { label: 'Bessancourt', value: 'Bessancourt' },
  { label: 'Bessay-Sur-Allier', value: 'Bessay-Sur-Allier' },
  { label: 'Besse-Et-Saint-Anastaise', value: 'Besse-Et-Saint-Anastaise' },
  { label: 'Bessèges', value: 'Bessèges' },
  { label: 'Bessemer', value: 'Bessemer' },
  { label: 'Bessemer', value: 'Bessemer' },
  { label: 'Bessemer', value: 'Bessemer' },
  { label: 'Bessemer City', value: 'Bessemer City' },
  { label: 'Bessenay', value: 'Bessenay' },
  { label: 'Bessé-Sur-Braye', value: 'Bessé-Sur-Braye' },
  { label: 'Besse-Sur-Issole', value: 'Besse-Sur-Issole' },
  { label: 'Bessières', value: 'Bessières' },
  { label: 'Bessines', value: 'Bessines' },
  { label: 'Bessines-Sur-Gartempe', value: 'Bessines-Sur-Gartempe' },
  { label: 'Besskorbnaya', value: 'Besskorbnaya' },
  { label: 'Bessonovka', value: 'Bessonovka' },
  { label: 'Bessonovka', value: 'Bessonovka' },
  { label: 'Bessude', value: 'Bessude' },
  { label: 'Best', value: 'Best' },
  { label: 'Bestensee', value: 'Bestensee' },
  { label: 'Beștepe', value: 'Beștepe' },
  { label: 'Besterd', value: 'Besterd' },
  { label: 'Bestobe', value: 'Bestobe' },
  { label: 'Bestovje', value: 'Bestovje' },
  { label: 'Bestwig', value: 'Bestwig' },
  { label: 'Bestwina', value: 'Bestwina' },
  { label: 'Bestwinka', value: 'Bestwinka' },
  { label: 'Besuki', value: 'Besuki' },
  { label: 'Beswan', value: 'Beswan' },
  { label: 'Bet Dagan', value: 'Bet Dagan' },
  { label: 'Bet She’An', value: 'Bet She’An' },
  { label: 'Bet Shemesh', value: 'Bet Shemesh' },
  { label: 'Bet Yiẕẖaq', value: 'Bet Yiẕẖaq' },
  { label: 'Betamcherla', value: 'Betamcherla' },
  { label: 'Betances', value: 'Betances' },
  { label: 'Betancuria', value: 'Betancuria' },
  { label: 'Betania', value: 'Betania' },
  { label: 'Betania', value: 'Betania' },
  { label: 'Betania', value: 'Betania' },
  { label: 'Betania', value: 'Betania' },
  { label: 'Betânia', value: 'Betânia' },
  { label: 'Betânia Do Piauí', value: 'Betânia Do Piauí' },
  { label: 'Betanzos', value: 'Betanzos' },
  { label: 'Betanzos', value: 'Betanzos' },
  { label: 'Bétaré Oya', value: 'Bétaré Oya' },
  { label: 'Betéitiva', value: 'Betéitiva' },
  { label: 'Betelu', value: 'Betelu' },
  { label: 'Beteni', value: 'Beteni' },
  { label: 'Bétera', value: 'Bétera' },
  { label: 'Bétérou', value: 'Bétérou' },
  { label: 'Beteta', value: 'Beteta' },
  { label: 'Bethal', value: 'Bethal' },
  { label: 'Bethalto', value: 'Bethalto' },
  { label: 'Bethania', value: 'Bethania' },
  { label: 'Bethanie', value: 'Bethanie' },
  { label: 'Bethany', value: 'Bethany' },
  { label: 'Bethany', value: 'Bethany' },
  { label: 'Bethany', value: 'Bethany' },
  { label: 'Bethany', value: 'Bethany' },
  { label: 'Bethany', value: 'Bethany' },
  { label: 'Bethany', value: 'Bethany' },
  { label: 'Bethany Beach', value: 'Bethany Beach' },
  { label: 'Bethausen', value: 'Bethausen' },
  { label: 'Bethel', value: 'Bethel' },
  { label: 'Bethel', value: 'Bethel' },
  { label: 'Bethel', value: 'Bethel' },
  { label: 'Bethel', value: 'Bethel' },
  { label: 'Bethel', value: 'Bethel' },
  { label: 'Bethel', value: 'Bethel' },
  { label: 'Bethel', value: 'Bethel' },
  { label: 'Bethel Acres', value: 'Bethel Acres' },
  { label: 'Bethel Census Area', value: 'Bethel Census Area' },
  { label: 'Bethel Heights', value: 'Bethel Heights' },
  { label: 'Bethel Island', value: 'Bethel Island' },
  { label: 'Bethel Park', value: 'Bethel Park' },
  { label: 'Bethel Town', value: 'Bethel Town' },
  { label: 'Betheln', value: 'Betheln' },
  { label: 'Béthencourt-Sur-Mer', value: 'Béthencourt-Sur-Mer' },
  { label: 'Bétheny', value: 'Bétheny' },
  { label: 'Bethesda', value: 'Bethesda' },
  { label: 'Bethesda', value: 'Bethesda' },
  { label: 'Bethesda', value: 'Bethesda' },
  { label: 'Béthisy-Saint-Martin', value: 'Béthisy-Saint-Martin' },
  { label: 'Béthisy-Saint-Pierre', value: 'Béthisy-Saint-Pierre' },
  { label: 'Bethlehem', value: 'Bethlehem' },
  { label: 'Bethlehem', value: 'Bethlehem' },
  { label: 'Bethlehem', value: 'Bethlehem' },
  { label: 'Bethlehem', value: 'Bethlehem' },
  { label: 'Bethlehem Village', value: 'Bethlehem Village' },
  { label: 'Bethnal Green', value: 'Bethnal Green' },
  { label: 'Bethoncourt', value: 'Bethoncourt' },
  { label: 'Bethpage', value: 'Bethpage' },
  { label: 'Béthune', value: 'Béthune' },
  { label: 'Betim', value: 'Betim' },
  { label: 'Betio Village', value: 'Betio Village' },
  { label: 'Betley', value: 'Betley' },
  { label: 'Betlitsa', value: 'Betlitsa' },
  { label: 'Betma', value: 'Betma' },
  { label: 'Betong', value: 'Betong' },
  { label: 'Betschdorf', value: 'Betschdorf' },
  { label: 'Bettancourt-La-Ferrée', value: 'Bettancourt-La-Ferrée' },
  { label: 'Bettborn', value: 'Bettborn' },
  { label: 'Bettembourg', value: 'Bettembourg' },
  { label: 'Bettendorf', value: 'Bettendorf' },
  { label: 'Bettendorf', value: 'Bettendorf' },
  { label: 'Bettiah', value: 'Bettiah' },
  { label: 'Bettingen', value: 'Bettingen' },
  { label: 'Bettingen', value: 'Bettingen' },
  { label: 'Bettlach', value: 'Bettlach' },
  { label: 'Bettola', value: 'Bettola' },
  { label: 'Bettola', value: 'Bettola' },
  { label: 'Bettola-Zeloforomagno', value: 'Bettola-Zeloforomagno' },
  { label: 'Bettolle', value: 'Bettolle' },
  { label: 'Betton', value: 'Betton' },
  { label: 'Bettona', value: 'Bettona' },
  { label: 'Betul', value: 'Betul' },
  { label: 'Betul Bazar', value: 'Betul Bazar' },
  { label: 'Betulia', value: 'Betulia' },
  { label: 'Betulia', value: 'Betulia' },
  { label: 'Betulia', value: 'Betulia' },
  { label: 'Betwagan', value: 'Betwagan' },
  { label: 'Betwagan', value: 'Betwagan' },
  { label: 'Betws', value: 'Betws' },
  { label: 'Betws-Y-Coed', value: 'Betws-Y-Coed' },
  { label: 'Betxí', value: 'Betxí' },
  { label: 'Betz', value: 'Betz' },
  { label: 'Betzdorf', value: 'Betzdorf' },
  { label: 'Betzdorf', value: 'Betzdorf' },
  { label: 'Betzendorf', value: 'Betzendorf' },
  { label: 'Betzigau', value: 'Betzigau' },
  { label: 'Beu', value: 'Beu' },
  { label: 'Beuca', value: 'Beuca' },
  { label: 'Beuda', value: 'Beuda' },
  { label: 'Beuern', value: 'Beuern' },
  { label: 'Beulah', value: 'Beulah' },
  { label: 'Beulah', value: 'Beulah' },
  { label: 'Beulah Park', value: 'Beulah Park' },
  { label: 'Beulaville', value: 'Beulaville' },
  { label: 'Beuna', value: 'Beuna' },
  { label: 'Beuningen', value: 'Beuningen' },
  { label: 'Beura', value: 'Beura' },
  { label: 'Beure', value: 'Beure' },
  { label: 'Beuren', value: 'Beuren' },
  { label: 'Beusichem', value: 'Beusichem' },
  { label: 'Beutelsbach', value: 'Beutelsbach' },
  { label: 'Beuville', value: 'Beuville' },
  { label: 'Beuvillers', value: 'Beuvillers' },
  { label: 'Beuvrages', value: 'Beuvrages' },
  { label: 'Beuvry', value: 'Beuvry' },
  { label: 'Beuzec-Cap-Sizun', value: 'Beuzec-Cap-Sizun' },
  { label: 'Beuzeville', value: 'Beuzeville' },
  { label: 'Beuzeville-La-Grenier', value: 'Beuzeville-La-Grenier' },
  { label: 'Bevagna', value: 'Bevagna' },
  { label: 'Bevaix', value: 'Bevaix' },
  { label: 'Bevenrode', value: 'Bevenrode' },
  { label: 'Bevent', value: 'Bevent' },
  { label: 'Bever', value: 'Bever' },
  { label: 'Beveren', value: 'Beveren' },
  { label: 'Beveridge', value: 'Beveridge' },
  { label: 'Beverino', value: 'Beverino' },
  { label: 'Beverley', value: 'Beverley' },
  { label: 'Beverley', value: 'Beverley' },
  { label: 'Beverley', value: 'Beverley' },
  { label: 'Beverley Hills', value: 'Beverley Hills' },
  { label: 'Beverley Park', value: 'Beverley Park' },
  { label: 'Beverly', value: 'Beverly' },
  { label: 'Beverly', value: 'Beverly' },
  { label: 'Beverly', value: 'Beverly' },
  { label: 'Beverly', value: 'Beverly' },
  { label: 'Beverly Cove', value: 'Beverly Cove' },
  { label: 'Beverly Hills', value: 'Beverly Hills' },
  { label: 'Beverly Hills', value: 'Beverly Hills' },
  { label: 'Beverly Hills', value: 'Beverly Hills' },
  { label: 'Beverly Hills', value: 'Beverly Hills' },
  { label: 'Beverly Hills', value: 'Beverly Hills' },
  { label: 'Bevern', value: 'Bevern' },
  { label: 'Beverstedt', value: 'Beverstedt' },
  { label: 'Beverungen', value: 'Beverungen' },
  { label: 'Beverwijk', value: 'Beverwijk' },
  { label: 'Bevil Oaks', value: 'Bevil Oaks' },
  { label: 'Bevilacqua', value: 'Bevilacqua' },
  { label: 'Bévilard', value: 'Bévilard' },
  { label: 'Béville-Le-Comte', value: 'Béville-Le-Comte' },
  { label: 'Bewar', value: 'Bewar' },
  { label: 'Bewbush', value: 'Bewbush' },
  { label: 'Bewdley', value: 'Bewdley' },
  { label: 'Bex', value: 'Bex' },
  { label: 'Bexar County', value: 'Bexar County' },
  { label: 'Bexbach', value: 'Bexbach' },
  { label: 'Bexhill-On-Sea', value: 'Bexhill-On-Sea' },
  { label: 'Bexley', value: 'Bexley' },
  { label: 'Bexley', value: 'Bexley' },
  { label: 'Bexley', value: 'Bexley' },
  { label: 'Bexley North', value: 'Bexley North' },
  { label: 'Bexon', value: 'Bexon' },
  { label: 'Beya', value: 'Beya' },
  { label: 'Beyağaç', value: 'Beyağaç' },
  { label: 'Beychac-Et-Caillau', value: 'Beychac-Et-Caillau' },
  { label: 'Beydağ', value: 'Beydağ' },
  { label: 'Beydokht', value: 'Beydokht' },
  { label: 'Beykoz', value: 'Beykoz' },
  { label: 'Beyla', value: 'Beyla' },
  { label: 'Beyla Prefecture', value: 'Beyla Prefecture' },
  { label: 'Beylagan', value: 'Beylagan' },
  { label: 'Beylikdüzü', value: 'Beylikdüzü' },
  { label: 'Beylikova', value: 'Beylikova' },
  { label: 'Beynat', value: 'Beynat' },
  { label: 'Beyne-Heusay', value: 'Beyne-Heusay' },
  { label: 'Beynes', value: 'Beynes' },
  { label: 'Beyneu', value: 'Beyneu' },
  { label: 'Beynost', value: 'Beynost' },
  { label: 'Beyoğlu', value: 'Beyoğlu' },
  { label: 'Beypazarı', value: 'Beypazarı' },
  { label: 'Beypore', value: 'Beypore' },
  { label: 'Beyram', value: 'Beyram' },
  { label: 'Beyşehir', value: 'Beyşehir' },
  { label: 'Beytüşşebap', value: 'Beytüşşebap' },
  { label: 'Beyza', value: 'Beyza' },
  { label: 'Bezanjan', value: 'Bezanjan' },
  { label: 'Bezannes', value: 'Bezannes' },
  { label: 'Bezares', value: 'Bezares' },
  { label: 'Bezas', value: 'Bezas' },
  { label: 'Bezau', value: 'Bezau' },
  { label: 'Bezdead', value: 'Bezdead' },
  { label: 'Bezdružice', value: 'Bezdružice' },
  { label: 'Bezenchuk', value: 'Bezenchuk' },
  { label: 'Bezerros', value: 'Bezerros' },
  { label: 'Bezhanitsy', value: 'Bezhanitsy' },
  { label: 'Bezhetsk', value: 'Bezhetsk' },
  { label: 'Bezhetskiy Rayon', value: 'Bezhetskiy Rayon' },
  { label: 'Bezhta', value: 'Bezhta' },
  { label: 'Bezid', value: 'Bezid' },
  { label: 'Béziers', value: 'Béziers' },
  { label: 'Bezimenne', value: 'Bezimenne' },
  { label: 'Bezirk Aarau', value: 'Bezirk Aarau' },
  { label: 'Bezirk Affoltern', value: 'Bezirk Affoltern' },
  { label: 'Bezirk Andelfingen', value: 'Bezirk Andelfingen' },
  { label: 'Bezirk Arlesheim', value: 'Bezirk Arlesheim' },
  { label: 'Bezirk Baden', value: 'Bezirk Baden' },
  { label: 'Bezirk Bremgarten', value: 'Bezirk Bremgarten' },
  { label: 'Bezirk Brugg', value: 'Bezirk Brugg' },
  { label: 'Bezirk Bucheggberg', value: 'Bezirk Bucheggberg' },
  { label: 'Bezirk Bülach', value: 'Bezirk Bülach' },
  { label: 'Bezirk Dielsdorf', value: 'Bezirk Dielsdorf' },
  { label: 'Bezirk Dietikon', value: 'Bezirk Dietikon' },
  { label: 'Bezirk Dorneck', value: 'Bezirk Dorneck' },
  { label: 'Bezirk Einsiedeln', value: 'Bezirk Einsiedeln' },
  { label: 'Bezirk Gäu', value: 'Bezirk Gäu' },
  { label: 'Bezirk Gersau', value: 'Bezirk Gersau' },
  { label: 'Bezirk Gösgen', value: 'Bezirk Gösgen' },
  { label: 'Bezirk Hinterland', value: 'Bezirk Hinterland' },
  { label: 'Bezirk Hinwil', value: 'Bezirk Hinwil' },
  { label: 'Bezirk Höfe', value: 'Bezirk Höfe' },
  { label: 'Bezirk Horgen', value: 'Bezirk Horgen' },
  { label: 'Bezirk Kulm', value: 'Bezirk Kulm' },
  { label: 'Bezirk Küssnacht', value: 'Bezirk Küssnacht' },
  { label: 'Bezirk Laufen', value: 'Bezirk Laufen' },
  { label: 'Bezirk Laufenburg', value: 'Bezirk Laufenburg' },
  { label: 'Bezirk Lebern', value: 'Bezirk Lebern' },
  { label: 'Bezirk Lenzburg', value: 'Bezirk Lenzburg' },
  { label: 'Bezirk Liestal', value: 'Bezirk Liestal' },
  { label: 'Bezirk March', value: 'Bezirk March' },
  { label: 'Bezirk Meilen', value: 'Bezirk Meilen' },
  { label: 'Bezirk Mittelland', value: 'Bezirk Mittelland' },
  { label: 'Bezirk Muri', value: 'Bezirk Muri' },
  { label: 'Bezirk Oberklettgau', value: 'Bezirk Oberklettgau' },
  { label: 'Bezirk Olten', value: 'Bezirk Olten' },
  { label: 'Bezirk Pfäffikon', value: 'Bezirk Pfäffikon' },
  { label: 'Bezirk Reiat', value: 'Bezirk Reiat' },
  { label: 'Bezirk Rheinfelden', value: 'Bezirk Rheinfelden' },
  { label: 'Bezirk Schaffhausen', value: 'Bezirk Schaffhausen' },
  { label: 'Bezirk Schleitheim', value: 'Bezirk Schleitheim' },
  { label: 'Bezirk Schwyz', value: 'Bezirk Schwyz' },
  { label: 'Bezirk Sissach', value: 'Bezirk Sissach' },
  { label: 'Bezirk Solothurn', value: 'Bezirk Solothurn' },
  { label: 'Bezirk Stein', value: 'Bezirk Stein' },
  { label: 'Bezirk Thal', value: 'Bezirk Thal' },
  { label: 'Bezirk Thierstein', value: 'Bezirk Thierstein' },
  { label: 'Bezirk Unterklettgau', value: 'Bezirk Unterklettgau' },
  { label: 'Bezirk Uster', value: 'Bezirk Uster' },
  { label: 'Bezirk Vorderland', value: 'Bezirk Vorderland' },
  { label: 'Bezirk Waldenburg', value: 'Bezirk Waldenburg' },
  { label: 'Bezirk Wasseramt', value: 'Bezirk Wasseramt' },
  { label: 'Bezirk Winterthur', value: 'Bezirk Winterthur' },
  { label: 'Bezirk Zofingen', value: 'Bezirk Zofingen' },
  { label: 'Bezirk Zürich', value: 'Bezirk Zürich' },
  { label: 'Bezirk Zurzach', value: 'Bezirk Zurzach' },
  { label: 'Bezlyudivka', value: 'Bezlyudivka' },
  { label: 'Beznea', value: 'Beznea' },
  { label: 'Bezons', value: 'Bezons' },
  { label: 'Bezopasnoye', value: 'Bezopasnoye' },
  { label: 'Bezouce', value: 'Bezouce' },
  { label: 'Bézu-Saint-Éloi', value: 'Bézu-Saint-Éloi' },
  { label: 'Bhabhra', value: 'Bhabhra' },
  { label: 'Bhabhua', value: 'Bhabhua' },
  { label: 'Bhachau', value: 'Bhachau' },
  { label: 'Bhadarsa', value: 'Bhadarsa' },
  { label: 'Bhadarwah', value: 'Bhadarwah' },
  { label: 'Bhadasar', value: 'Bhadasar' },
  { label: 'Bhadaur', value: 'Bhadaur' },
  { label: 'Bhadgaon Maharashtra', value: 'Bhadgaon Maharashtra' },
  { label: 'Bhadohi', value: 'Bhadohi' },
  { label: 'Bhadra', value: 'Bhadra' },
  { label: 'Bhadrachalam', value: 'Bhadrachalam' },
  { label: 'Bhadradri Kothagudem', value: 'Bhadradri Kothagudem' },
  { label: 'Bhadrak', value: 'Bhadrak' },
  { label: 'Bhadrakh', value: 'Bhadrakh' },
  { label: 'Bhadran', value: 'Bhadran' },
  { label: 'Bhadravati', value: 'Bhadravati' },
  { label: 'Bhadreswar', value: 'Bhadreswar' },
  { label: 'Bhag', value: 'Bhag' },
  { label: 'Bhagalpur', value: 'Bhagalpur' },
  { label: 'Bhagirathpur', value: 'Bhagirathpur' },
  { label: 'Bhagwantnagar', value: 'Bhagwantnagar' },
  { label: 'Bhainsdehi', value: 'Bhainsdehi' },
  { label: 'Bhaisa', value: 'Bhaisa' },
  { label: 'Bhakkar', value: 'Bhakkar' },
  { label: 'Bhaktapur', value: 'Bhaktapur' },
  { label: 'Bhalil', value: 'Bhalil' },
  { label: 'Bhalki', value: 'Bhalki' },
  { label: 'Bhalwal', value: 'Bhalwal' },
  { label: 'Bhamdoun', value: 'Bhamdoun' },
  { label: 'Bhamdoûn El Mhatta', value: 'Bhamdoûn El Mhatta' },
  { label: 'Bhamo', value: 'Bhamo' },
  { label: 'Bhan', value: 'Bhan' },
  { label: 'Bhandara', value: 'Bhandara' },
  { label: 'Bhandardaha', value: 'Bhandardaha' },
  { label: 'Bhandardara', value: 'Bhandardara' },
  { label: 'Bhander', value: 'Bhander' },
  { label: 'Bhandu', value: 'Bhandu' },
  { label: 'Bhandup', value: 'Bhandup' },
  { label: 'Bhanjanagar', value: 'Bhanjanagar' },
  { label: 'Bhanpura', value: 'Bhanpura' },
  { label: 'Bhanpuri', value: 'Bhanpuri' },
  { label: 'Bhanvad', value: 'Bhanvad' },
  { label: 'Bharatpur', value: 'Bharatpur' },
  { label: 'Bharatpura', value: 'Bharatpura' },
  { label: 'Bhargain', value: 'Bhargain' },
  { label: 'Bharthana', value: 'Bharthana' },
  { label: 'Bharuch', value: 'Bharuch' },
  { label: 'Bharwari', value: 'Bharwari' },
  { label: 'Bhasawar', value: 'Bhasawar' },
  { label: 'Bhatapara', value: 'Bhatapara' },
  { label: 'Bhatgaon', value: 'Bhatgaon' },
  { label: 'Bhatha', value: 'Bhatha' },
  { label: 'Bhatinda', value: 'Bhatinda' },
  { label: 'Bhatkal', value: 'Bhatkal' },
  { label: 'Bhatpara', value: 'Bhatpara' },
  { label: 'Bhattiprolu', value: 'Bhattiprolu' },
  { label: 'Bhaupur', value: 'Bhaupur' },
  { label: 'Bhavani', value: 'Bhavani' },
  { label: 'Bhavnagar', value: 'Bhavnagar' },
  { label: 'Bhawana', value: 'Bhawana' },
  { label: 'Bhawaniganj', value: 'Bhawaniganj' },
  { label: 'Bhawanigarh', value: 'Bhawanigarh' },
  { label: 'Bhawanipatna', value: 'Bhawanipatna' },
  { label: 'Bhawanipur', value: 'Bhawanipur' },
  { label: 'Bhayandar', value: 'Bhayandar' },
  { label: 'Bhayavadar', value: 'Bhayavadar' },
  { label: 'Bhera', value: 'Bhera' },
  { label: 'Bhigvan', value: 'Bhigvan' },
  { label: 'Bhikangaon', value: 'Bhikangaon' },
  { label: 'Bhikhi', value: 'Bhikhi' },
  { label: 'Bhilai', value: 'Bhilai' },
  { label: 'Bhildi', value: 'Bhildi' },
  { label: 'Bhilwara', value: 'Bhilwara' },
  { label: 'Bhim Tal', value: 'Bhim Tal' },
  { label: 'Bhimavaram', value: 'Bhimavaram' },
  { label: 'Bhimbar', value: 'Bhimbar' },
  { label: 'Bhimtal', value: 'Bhimtal' },
  { label: 'Bhimunipatnam', value: 'Bhimunipatnam' },
  { label: 'Bhind', value: 'Bhind' },
  { label: 'Bhindar', value: 'Bhindar' },
  { label: 'Bhinga', value: 'Bhinga' },
  { label: 'Bhinmal', value: 'Bhinmal' },
  { label: 'Bhiria', value: 'Bhiria' },
  { label: 'Bhisho', value: 'Bhisho' },
  { label: 'Bhit Shah', value: 'Bhit Shah' },
  { label: 'Bhitarwar', value: 'Bhitarwar' },
  { label: 'Bhiwadi', value: 'Bhiwadi' },
  { label: 'Bhiwandi', value: 'Bhiwandi' },
  { label: 'Bhiwani', value: 'Bhiwani' },
  { label: 'Bhiwapur', value: 'Bhiwapur' },
  { label: 'Bhognipur', value: 'Bhognipur' },
  { label: 'Bhogpur', value: 'Bhogpur' },
  { label: 'Bhojpur', value: 'Bhojpur' },
  { label: 'Bhojpur', value: 'Bhojpur' },
  { label: 'Bhojpur Dharampur', value: 'Bhojpur Dharampur' },
  { label: 'Bhojudih', value: 'Bhojudih' },
  { label: 'Bhokar', value: 'Bhokar' },
  { label: 'Bhokardan', value: 'Bhokardan' },
  { label: 'Bhola ', value: 'Bhola ' },
  { label: 'Bholath', value: 'Bholath' },
  { label: 'Bhongaon', value: 'Bhongaon' },
  { label: 'Bhongir', value: 'Bhongir' },
  { label: 'Bhoom', value: 'Bhoom' },
  { label: 'Bhopal', value: 'Bhopal' },
  { label: 'Bhopalwala', value: 'Bhopalwala' },
  { label: 'Bhor', value: 'Bhor' },
  { label: 'Bhowali', value: 'Bhowali' },
  { label: 'Bhuban', value: 'Bhuban' },
  { label: 'Bhubaneswar', value: 'Bhubaneswar' },
  { label: 'Bhudgaon', value: 'Bhudgaon' },
  { label: 'Bhugaon', value: 'Bhugaon' },
  { label: 'Bhuj', value: 'Bhuj' },
  { label: 'Bhuma', value: 'Bhuma' },
  { label: 'Bhunya', value: 'Bhunya' },
  { label: 'Bhurgaon', value: 'Bhurgaon' },
  { label: 'Bhusaval', value: 'Bhusaval' },
  { label: 'Bia East', value: 'Bia East' },
  { label: 'Bia West', value: 'Bia West' },
  { label: 'Biabas', value: 'Biabas' },
  { label: 'Biabou', value: 'Biabou' },
  { label: 'Biache-Saint-Vaast', value: 'Biache-Saint-Vaast' },
  { label: 'Biaj', value: 'Biaj' },
  { label: 'Biak', value: 'Biak' },
  { label: 'Biakoye', value: 'Biakoye' },
  { label: 'Biała', value: 'Biała' },
  { label: 'Biała', value: 'Biała' },
  { label: 'Biała Piska', value: 'Biała Piska' },
  { label: 'Biała Podlaska', value: 'Biała Podlaska' },
  { label: 'Biała Rawska', value: 'Biała Rawska' },
  { label: 'Białaczów', value: 'Białaczów' },
  { label: 'Białe Błota', value: 'Białe Błota' },
  { label: 'Białka', value: 'Białka' },
  { label: 'Białka Tatrzańska', value: 'Białka Tatrzańska' },
  { label: 'Białobrzegi', value: 'Białobrzegi' },
  { label: 'Białobrzegi', value: 'Białobrzegi' },
  { label: 'Białogard', value: 'Białogard' },
  { label: 'Białołeka', value: 'Białołeka' },
  { label: 'Bialong', value: 'Bialong' },
  { label: 'Bialong', value: 'Bialong' },
  { label: 'Białośliwie', value: 'Białośliwie' },
  { label: 'Białowieża', value: 'Białowieża' },
  { label: 'Biały Bór', value: 'Biały Bór' },
  { label: 'Biały Dunajec', value: 'Biały Dunajec' },
  { label: 'Białystok', value: 'Białystok' },
  { label: 'Biancade', value: 'Biancade' },
  { label: 'Biancavilla', value: 'Biancavilla' },
  { label: 'Bianchi', value: 'Bianchi' },
  { label: 'Bianco', value: 'Bianco' },
  { label: 'Biandrate', value: 'Biandrate' },
  { label: 'Biandronno', value: 'Biandronno' },
  { label: 'Biankouma', value: 'Biankouma' },
  { label: 'Bianzano', value: 'Bianzano' },
  { label: 'Bianzè', value: 'Bianzè' },
  { label: 'Bianzhuang', value: 'Bianzhuang' },
  { label: 'Bianzone', value: 'Bianzone' },
  { label: 'Biao', value: 'Biao' },
  { label: 'Biao', value: 'Biao' },
  { label: 'Biao', value: 'Biao' },
  { label: 'Biao', value: 'Biao' },
  { label: 'Biaokou', value: 'Biaokou' },
  { label: 'Biaora', value: 'Biaora' },
  { label: 'Biar', value: 'Biar' },
  { label: 'Biard', value: 'Biard' },
  { label: 'Biarritz', value: 'Biarritz' },
  { label: 'Biars-Sur-Cère', value: 'Biars-Sur-Cère' },
  { label: 'Bias', value: 'Bias' },
  { label: 'Bias Fortes', value: 'Bias Fortes' },
  { label: 'Biasca', value: 'Biasca' },
  { label: 'Biasong', value: 'Biasong' },
  { label: 'Biassono', value: 'Biassono' },
  { label: 'Biatan-E Sofla', value: 'Biatan-E Sofla' },
  { label: 'Biatorbágy', value: 'Biatorbágy' },
  { label: 'Biay', value: 'Biay' },
  { label: 'Biay', value: 'Biay' },
  { label: 'Bibai', value: 'Bibai' },
  { label: 'Bibano', value: 'Bibano' },
  { label: 'Bibb County', value: 'Bibb County' },
  { label: 'Bibb County', value: 'Bibb County' },
  { label: 'Bibbiano', value: 'Bibbiano' },
  { label: 'Bibbiena', value: 'Bibbiena' },
  { label: 'Bibbona', value: 'Bibbona' },
  { label: 'Biberach', value: 'Biberach' },
  { label: 'Biberach An Der Riß', value: 'Biberach An Der Riß' },
  { label: 'Biberbach', value: 'Biberbach' },
  { label: 'Biberbach', value: 'Biberbach' },
  { label: 'Biberist', value: 'Biberist' },
  { label: 'Biberwier', value: 'Biberwier' },
  { label: 'Bibiana', value: 'Bibiana' },
  { label: 'Bibiani', value: 'Bibiani' },
  { label: 'Bibiani-Anhwiaso-Bekwai', value: 'Bibiani-Anhwiaso-Bekwai' },
  { label: 'Bibiclat', value: 'Bibiclat' },
  { label: 'Bibiclat', value: 'Bibiclat' },
  { label: 'Bibinje', value: 'Bibinje' },
  { label: 'Bibione', value: 'Bibione' },
  { label: 'Bibirevo', value: 'Bibirevo' },
  { label: 'Biblis', value: 'Biblis' },
  { label: 'Bibra Lake', value: 'Bibra Lake' },
  { label: 'Bibrka', value: 'Bibrka' },
  { label: 'Biburg', value: 'Biburg' },
  { label: 'Bical Norte', value: 'Bical Norte' },
  { label: 'Bical Norte', value: 'Bical Norte' },
  { label: 'Bicas', value: 'Bicas' },
  { label: 'Bicașu', value: 'Bicașu' },
  { label: 'Bicaz', value: 'Bicaz' },
  { label: 'Bicaz', value: 'Bicaz' },
  { label: 'Bicaz-Chei', value: 'Bicaz-Chei' },
  { label: 'Bicazu Ardelean', value: 'Bicazu Ardelean' },
  { label: 'Biccari', value: 'Biccari' },
  { label: 'Bicercio', value: 'Bicercio' },
  { label: 'Bicester', value: 'Bicester' },
  { label: 'Bich’Vinta', value: 'Bich’Vinta' },
  { label: 'Bichena', value: 'Bichena' },
  { label: 'Bicheva', value: 'Bicheva' },
  { label: 'Bichigiu', value: 'Bichigiu' },
  { label: 'Bichiș', value: 'Bichiș' },
  { label: 'Bichl', value: 'Bichl' },
  { label: 'Bichlbach', value: 'Bichlbach' },
  { label: 'Bichura', value: 'Bichura' },
  { label: 'Bicinicco', value: 'Bicinicco' },
  { label: 'Bickenbach', value: 'Bickenbach' },
  { label: 'Bickersteth', value: 'Bickersteth' },
  { label: 'Bicknacre', value: 'Bicknacre' },
  { label: 'Bicknell', value: 'Bicknell' },
  { label: 'Bicorp', value: 'Bicorp' },
  { label: 'Bicos', value: 'Bicos' },
  { label: 'Bicos', value: 'Bicos' },
  { label: 'Bicske', value: 'Bicske' },
  { label: 'Bicskei Járás', value: 'Bicskei Járás' },
  { label: 'Bicton', value: 'Bicton' },
  { label: 'Bicton', value: 'Bicton' },
  { label: 'Bicurga', value: 'Bicurga' },
  { label: 'Bid Korpeh-Ye Sofla', value: 'Bid Korpeh-Ye Sofla' },
  { label: 'Bid Korpeh-Ye Vosta', value: 'Bid Korpeh-Ye Vosta' },
  { label: 'Bidache', value: 'Bidache' },
  { label: 'Bidar', value: 'Bidar' },
  { label: 'Bidart', value: 'Bidart' },
  { label: 'Bidaurreta', value: 'Bidaurreta' },
  { label: 'Bidbadah', value: 'Bidbadah' },
  { label: 'Bidbid', value: 'Bidbid' },
  { label: 'Biddeford', value: 'Biddeford' },
  { label: 'Biddenden', value: 'Biddenden' },
  { label: 'Biddestone', value: 'Biddestone' },
  { label: 'Biddinghuizen', value: 'Biddinghuizen' },
  { label: 'Biddulph', value: 'Biddulph' },
  { label: 'Bideford', value: 'Bideford' },
  { label: 'Bidegoian', value: 'Bidegoian' },
  { label: 'Bidestan', value: 'Bidestan' },
  { label: 'Bidford-On-Avon', value: 'Bidford-On-Avon' },
  { label: 'Bidhnu', value: 'Bidhnu' },
  { label: 'Bidhuna', value: 'Bidhuna' },
  { label: 'Bidingen', value: 'Bidingen' },
  { label: 'Bidnija', value: 'Bidnija' },
  { label: 'Bidonì', value: 'Bidonì' },
  { label: 'Bidrubeh-Ye', value: 'Bidrubeh-Ye' },
  { label: 'Bidur', value: 'Bidur' },
  { label: 'Bidwill', value: 'Bidwill' },
  { label: 'Biebelried', value: 'Biebelried' },
  { label: 'Biebesheim', value: 'Biebesheim' },
  { label: 'Biecz', value: 'Biecz' },
  { label: 'Biedenkopf', value: 'Biedenkopf' },
  { label: 'Biederitz', value: 'Biederitz' },
  { label: 'Biedermannsdorf', value: 'Biedermannsdorf' },
  { label: 'Biel', value: 'Biel' },
  { label: 'Biel/Bienne', value: 'Biel/Bienne' },
  { label: 'Biel/Bienne District', value: 'Biel/Bienne District' },
  { label: 'Bielany', value: 'Bielany' },
  { label: 'Bielany Wrocławskie', value: 'Bielany Wrocławskie' },
  { label: 'Bielawa', value: 'Bielawa' },
  { label: 'Bielawy', value: 'Bielawy' },
  { label: 'Bielefeld', value: 'Bielefeld' },
  { label: 'Bielice', value: 'Bielice' },
  { label: 'Bieliny', value: 'Bieliny' },
  { label: 'Bieliny', value: 'Bieliny' },
  { label: 'Biella', value: 'Biella' },
  { label: 'Bielsa', value: 'Bielsa' },
  { label: 'Bielsk', value: 'Bielsk' },
  { label: 'Bielsk Podlaski', value: 'Bielsk Podlaski' },
  { label: 'Bielsko-Biala', value: 'Bielsko-Biala' },
  { label: 'Bielsko-Biała', value: 'Bielsko-Biała' },
  { label: 'Biên Hòa', value: 'Biên Hòa' },
  { label: 'Bien Unido', value: 'Bien Unido' },
  { label: 'Biendorf', value: 'Biendorf' },
  { label: 'Bienenbüttel', value: 'Bienenbüttel' },
  { label: 'Bieniewice', value: 'Bieniewice' },
  { label: 'Bieńkówka', value: 'Bieńkówka' },
  { label: 'Bienno', value: 'Bienno' },
  { label: 'Bieno', value: 'Bieno' },
  { label: 'Bienservida', value: 'Bienservida' },
  { label: 'Bientina', value: 'Bientina' },
  { label: 'Bienvenida', value: 'Bienvenida' },
  { label: 'Bienvenido', value: 'Bienvenido' },
  { label: 'Bienville', value: 'Bienville' },
  { label: 'Bienville Parish', value: 'Bienville Parish' },
  { label: 'Bierawa', value: 'Bierawa' },
  { label: 'Bierbaum Am Auersbach', value: 'Bierbaum Am Auersbach' },
  { label: 'Bierbeek', value: 'Bierbeek' },
  { label: 'Bierdzany', value: 'Bierdzany' },
  { label: 'Biere', value: 'Biere' },
  { label: 'Bière', value: 'Bière' },
  { label: 'Bierge', value: 'Bierge' },
  { label: 'Bierne', value: 'Bierne' },
  { label: 'Biertan', value: 'Biertan' },
  { label: 'Bieruń', value: 'Bieruń' },
  { label: 'Bierutów', value: 'Bierutów' },
  { label: 'Bierzwnik', value: 'Bierzwnik' },
  { label: 'Biescas', value: 'Biescas' },
  { label: 'Biesdonk', value: 'Biesdonk' },
  { label: 'Biesdorf', value: 'Biesdorf' },
  { label: 'Biesenthal', value: 'Biesenthal' },
  { label: 'Biesheim', value: 'Biesheim' },
  { label: 'Biesland', value: 'Biesland' },
  { label: 'Biesles', value: 'Biesles' },
  { label: 'Biessenhofen', value: 'Biessenhofen' },
  { label: 'Bietigheim', value: 'Bietigheim' },
  { label: 'Bietigheim-Bissingen', value: 'Bietigheim-Bissingen' },
  { label: 'Biéville-Beuville', value: 'Biéville-Beuville' },
  { label: 'Bièvre', value: 'Bièvre' },
  { label: 'Bièvres', value: 'Bièvres' },
  { label: 'Bieżuń', value: 'Bieżuń' },
  { label: 'Bifanaj', value: 'Bifanaj' },
  { label: 'Big Bass Lake', value: 'Big Bass Lake' },
  { label: 'Big Bear City', value: 'Big Bear City' },
  { label: 'Big Bear Lake', value: 'Big Bear Lake' },
  { label: 'Big Beaver', value: 'Big Beaver' },
  { label: 'Big Bend', value: 'Big Bend' },
  { label: 'Big Bend', value: 'Big Bend' },
  { label: 'Big Bridge', value: 'Big Bridge' },
  { label: 'Big Coppitt Key', value: 'Big Coppitt Key' },
  { label: 'Big Flats', value: 'Big Flats' },
  { label: 'Big Horn County', value: 'Big Horn County' },
  { label: 'Big Horn County', value: 'Big Horn County' },
  { label: 'Big Lake', value: 'Big Lake' },
  { label: 'Big Lake', value: 'Big Lake' },
  { label: 'Big Lake', value: 'Big Lake' },
  { label: 'Big Lake', value: 'Big Lake' },
  { label: 'Big Park', value: 'Big Park' },
  { label: 'Big Pine', value: 'Big Pine' },
  { label: 'Big Pine Key', value: 'Big Pine Key' },
  { label: 'Big Rapids', value: 'Big Rapids' },
  { label: 'Big River', value: 'Big River' },
  { label: 'Big Rock', value: 'Big Rock' },
  { label: 'Big Sandy', value: 'Big Sandy' },
  { label: 'Big Sky', value: 'Big Sky' },
  { label: 'Big Spring', value: 'Big Spring' },
  { label: 'Big Stone County', value: 'Big Stone County' },
  { label: 'Big Stone Gap', value: 'Big Stone Gap' },
  { label: 'Big Timber', value: 'Big Timber' },
  { label: 'Biga', value: 'Biga' },
  { label: 'Biga', value: 'Biga' },
  { label: 'Biga', value: 'Biga' },
  { label: 'Biga', value: 'Biga' },
  { label: 'Biga', value: 'Biga' },
  { label: 'Bigaa', value: 'Bigaa' },
  { label: 'Bigaan', value: 'Bigaan' },
  { label: 'Bigaan', value: 'Bigaan' },
  { label: 'Bigadiç', value: 'Bigadiç' },
  { label: 'Biganos', value: 'Biganos' },
  { label: 'Bigastro', value: 'Bigastro' },
  { label: 'Bigfork', value: 'Bigfork' },
  { label: 'Biggar', value: 'Biggar' },
  { label: 'Biggar', value: 'Biggar' },
  { label: 'Biggera Waters', value: 'Biggera Waters' },
  { label: 'Biggin Hill', value: 'Biggin Hill' },
  { label: 'Biggleswade', value: 'Biggleswade' },
  { label: 'Biggs', value: 'Biggs' },
  { label: 'Bighapur', value: 'Bighapur' },
  { label: 'Bighapur Khurd', value: 'Bighapur Khurd' },
  { label: 'Bighash', value: 'Bighash' },
  { label: 'Biglen', value: 'Biglen' },
  { label: 'Biglerville', value: 'Biglerville' },
  { label: 'Bignan', value: 'Bignan' },
  { label: 'Bignay Uno', value: 'Bignay Uno' },
  { label: 'Bignona', value: 'Bignona' },
  { label: 'Bignoux', value: 'Bignoux' },
  { label: 'Bigolino', value: 'Bigolino' },
  { label: 'Bigoudine', value: 'Bigoudine' },
  { label: 'Biguaçu', value: 'Biguaçu' },
  { label: 'Bigues I Riells', value: 'Bigues I Riells' },
  { label: 'Biguglia', value: 'Biguglia' },
  { label: 'Bigun', value: 'Bigun' },
  { label: 'Bigwoods', value: 'Bigwoods' },
  { label: 'Bihać', value: 'Bihać' },
  { label: 'Bihar Sharif', value: 'Bihar Sharif' },
  { label: 'Biharamulo', value: 'Biharamulo' },
  { label: 'Biharia', value: 'Biharia' },
  { label: 'Bihariganj', value: 'Bihariganj' },
  { label: 'Biharkeresztes', value: 'Biharkeresztes' },
  { label: 'Biharnagybajom', value: 'Biharnagybajom' },
  { label: 'Bihorel', value: 'Bihorel' },
  { label: 'Bihpuriagaon', value: 'Bihpuriagaon' },
  { label: 'Biia', value: 'Biia' },
  { label: 'Bijagua', value: 'Bijagua' },
  { label: 'Bijagual', value: 'Bijagual' },
  { label: 'Bijapur', value: 'Bijapur' },
  { label: 'Bijar', value: 'Bijar' },
  { label: 'Bijawar', value: 'Bijawar' },
  { label: 'Bijbehara', value: 'Bijbehara' },
  { label: 'Bijela', value: 'Bijela' },
  { label: 'Bijeljina', value: 'Bijeljina' },
  { label: 'Bijelo Brdo', value: 'Bijelo Brdo' },
  { label: 'Bijelo Polje', value: 'Bijelo Polje' },
  { label: 'Bijghir', value: 'Bijghir' },
  { label: 'Bijie', value: 'Bijie' },
  { label: 'Bijlmermeer', value: 'Bijlmermeer' },
  { label: 'Bijni', value: 'Bijni' },
  { label: 'Bijnor', value: 'Bijnor' },
  { label: 'Bijrauni', value: 'Bijrauni' },
  { label: 'Bijuesca', value: 'Bijuesca' },
  { label: 'Bijur', value: 'Bijur' },
  { label: 'Bijvanck', value: 'Bijvanck' },
  { label: 'Bikah', value: 'Bikah' },
  { label: 'Bikaner', value: 'Bikaner' },
  { label: 'Bikapur', value: 'Bikapur' },
  { label: 'Bikenibeu Village', value: 'Bikenibeu Village' },
  { label: 'Bikin', value: 'Bikin' },
  { label: 'Biking', value: 'Biking' },
  { label: 'Bikinskiy Rayon', value: 'Bikinskiy Rayon' },
  { label: 'Bikita District', value: 'Bikita District' },
  { label: 'Bikiv', value: 'Bikiv' },
  { label: 'Bikramganj', value: 'Bikramganj' },
  { label: 'Bila', value: 'Bila' },
  { label: 'Bila Krynytsia', value: 'Bila Krynytsia' },
  { label: 'Bila Krynytsya', value: 'Bila Krynytsya' },
  { label: 'Bílá Lhota', value: 'Bílá Lhota' },
  { label: 'Bílá Třemešná', value: 'Bílá Třemešná' },
  { label: 'Bila Tserkva', value: 'Bila Tserkva' },
  { label: 'Bila Tserkva Raion', value: 'Bila Tserkva Raion' },
  { label: 'Bilac', value: 'Bilac' },
  { label: 'Bilad', value: 'Bilad' },
  { label: 'Bilad', value: 'Bilad' },
  { label: 'Bilad Ar Rus', value: 'Bilad Ar Rus' },
  { label: 'Bilad At Taam', value: 'Bilad At Taam' },
  { label: 'Bilajari', value: 'Bilajari' },
  { label: 'Bilajer', value: 'Bilajer' },
  { label: 'Bilambil Heights', value: 'Bilambil Heights' },
  { label: 'Bilao', value: 'Bilao' },
  { label: 'Bilar', value: 'Bilar' },
  { label: 'Bilara', value: 'Bilara' },
  { label: 'Bilaran', value: 'Bilaran' },
  { label: 'Bilari', value: 'Bilari' },
  { label: 'Bilariaganj', value: 'Bilariaganj' },
  { label: 'Bilashi', value: 'Bilashi' },
  { label: 'Bilasipara', value: 'Bilasipara' },
  { label: 'Bilaspur', value: 'Bilaspur' },
  { label: 'Bilaspur', value: 'Bilaspur' },
  { label: 'Bilaspur', value: 'Bilaspur' },
  { label: 'Bilaspur', value: 'Bilaspur' },
  { label: 'Bilbao', value: 'Bilbao' },
  { label: 'Bilbeis', value: 'Bilbeis' },
  { label: 'Bilbor', value: 'Bilbor' },
  { label: 'Bilca', value: 'Bilca' },
  { label: 'Bilciureşti', value: 'Bilciureşti' },
  { label: 'Bilderstoeckchen', value: 'Bilderstoeckchen' },
  { label: 'Bildeston', value: 'Bildeston' },
  { label: 'Bildstein', value: 'Bildstein' },
  { label: 'Bile', value: 'Bile' },
  { label: 'Bileća', value: 'Bileća' },
  { label: 'Biled', value: 'Biled' },
  { label: 'Bileh Savar', value: 'Bileh Savar' },
  { label: 'Bilgaard', value: 'Bilgaard' },
  { label: 'Bilgi', value: 'Bilgi' },
  { label: 'Bilgola Plateau', value: 'Bilgola Plateau' },
  { label: 'Biłgoraj', value: 'Biłgoraj' },
  { label: 'Bilgram', value: 'Bilgram' },
  { label: 'Bilhaur', value: 'Bilhaur' },
  { label: 'Bilhorod-Dnistrovskyi', value: 'Bilhorod-Dnistrovskyi' },
  { label: 'Bili Oslavy', value: 'Bili Oslavy' },
  { label: 'Bilibino', value: 'Bilibino' },
  { label: 'Bilicenii Vechi', value: 'Bilicenii Vechi' },
  { label: 'Biliești', value: 'Biliești' },
  { label: 'Bilieu', value: 'Bilieu' },
  { label: 'Bilii Bereg', value: 'Bilii Bereg' },
  { label: 'Bilimbay', value: 'Bilimbay' },
  { label: 'Bilimora', value: 'Bilimora' },
  { label: 'Bílina', value: 'Bílina' },
  { label: 'Bílina Kyselka', value: 'Bílina Kyselka' },
  { label: 'Bilinga', value: 'Bilinga' },
  { label: 'Bilingurr', value: 'Bilingurr' },
  { label: 'Biliran', value: 'Biliran' },
  { label: 'Bilisht', value: 'Bilisht' },
  { label: 'Biljača', value: 'Biljača' },
  { label: 'Bilje', value: 'Bilje' },
  { label: 'Bilje', value: 'Bilje' },
  { label: 'Bilka', value: 'Bilka' },
  { label: 'Bilkha', value: 'Bilkha' },
  { label: 'Bilky', value: 'Bilky' },
  { label: 'Billdal', value: 'Billdal' },
  { label: 'Billeberga', value: 'Billeberga' },
  { label: 'Billerbeck', value: 'Billerbeck' },
  { label: 'Billère', value: 'Billère' },
  { label: 'Billerica', value: 'Billerica' },
  { label: 'Billericay', value: 'Billericay' },
  { label: 'Billesholm', value: 'Billesholm' },
  { label: 'Billigheim', value: 'Billigheim' },
  { label: 'Billigheim-Ingenheim', value: 'Billigheim-Ingenheim' },
  { label: 'Billingborough', value: 'Billingborough' },
  { label: 'Billinge', value: 'Billinge' },
  { label: 'Billingham', value: 'Billingham' },
  { label: 'Billinghay', value: 'Billinghay' },
  { label: 'Billings', value: 'Billings' },
  { label: 'Billings', value: 'Billings' },
  { label: 'Billings County', value: 'Billings County' },
  { label: 'Billingsfors', value: 'Billingsfors' },
  { label: 'Billingshurst', value: 'Billingshurst' },
  { label: 'Billingstad', value: 'Billingstad' },
  { label: 'Billington', value: 'Billington' },
  { label: 'Billington Heights', value: 'Billington Heights' },
  { label: 'Billiri', value: 'Billiri' },
  { label: 'Bil-Loca', value: 'Bil-Loca' },
  { label: 'Bil-Loca', value: 'Bil-Loca' },
  { label: 'Billom', value: 'Billom' },
  { label: 'Billund', value: 'Billund' },
  { label: 'Billund Kommune', value: 'Billund Kommune' },
  { label: 'Billy-Berclau', value: 'Billy-Berclau' },
  { label: 'Billy-Montigny', value: 'Billy-Montigny' },
  { label: 'Billy-Sur-Aisne', value: 'Billy-Sur-Aisne' },
  { label: 'Bilma', value: 'Bilma' },
  { label: 'Biloela', value: 'Biloela' },
  { label: 'Bilog-Bilog', value: 'Bilog-Bilog' },
  { label: 'Bilohirsk', value: 'Bilohirsk' },
  { label: 'Bilohorivka', value: 'Bilohorivka' },
  { label: 'Bilokurakyne', value: 'Bilokurakyne' },
  { label: 'Biloli', value: 'Biloli' },
  { label: 'Bilopillya', value: 'Bilopillya' },
  { label: 'Bílovec', value: 'Bílovec' },
  { label: 'Bílovice', value: 'Bílovice' },
  { label: 'Bílovice Nad Svitavou', value: 'Bílovice Nad Svitavou' },
  { label: 'Bilovodsk', value: 'Bilovodsk' },
  { label: 'Biloxi', value: 'Biloxi' },
  { label: 'Bilozerka', value: 'Bilozerka' },
  { label: 'Bilqās', value: 'Bilqās' },
  { label: 'Bilsanda', value: 'Bilsanda' },
  { label: 'Bilsdale', value: 'Bilsdale' },
  { label: 'Bilshausen', value: 'Bilshausen' },
  { label: 'Bilshivtsi', value: 'Bilshivtsi' },
  { label: 'Bilsi', value: 'Bilsi' },
  { label: 'Bilsthorpe', value: 'Bilsthorpe' },
  { label: 'Bilston', value: 'Bilston' },
  { label: 'Bilston', value: 'Bilston' },
  { label: 'Bilten', value: 'Bilten' },
  { label: 'Bilthra', value: 'Bilthra' },
  { label: 'Biltine', value: 'Biltine' },
  { label: 'Biltmore Forest', value: 'Biltmore Forest' },
  { label: 'Bilwang', value: 'Bilwang' },
  { label: 'Bilwang', value: 'Bilwang' },
  { label: 'Bilyarsk', value: 'Bilyarsk' },
  { label: 'Bilyayivka', value: 'Bilyayivka' },
  { label: 'Bilylivka', value: 'Bilylivka' },
  { label: 'Bilyts’Ke', value: 'Bilyts’Ke' },
  { label: 'Bilzen', value: 'Bilzen' },
  { label: 'Bỉm Sơn', value: 'Bỉm Sơn' },
  { label: 'Bima', value: 'Bima' },
  { label: 'Bimbaletes Aguascalientes (El Álamo)', value: 'Bimbaletes Aguascalientes (El Álamo)' },
  { label: 'Bimbini', value: 'Bimbini' },
  { label: 'Bimbo', value: 'Bimbo' },
  { label: 'Bimenes', value: 'Bimenes' },
  { label: 'Bin Yauri', value: 'Bin Yauri' },
  { label: 'Binabaan', value: 'Binabaan' },
  { label: 'Binabalian', value: 'Binabalian' },
  { label: 'Binabalian', value: 'Binabalian' },
  { label: 'Binaced', value: 'Binaced' },
  { label: 'Binagadi', value: 'Binagadi' },
  { label: 'Binago', value: 'Binago' },
  { label: 'Binahaan', value: 'Binahaan' },
  { label: 'Binalan', value: 'Binalan' },
  { label: 'Binalan', value: 'Binalan' },
  { label: 'Binalbagan', value: 'Binalbagan' },
  { label: 'Binalonan', value: 'Binalonan' },
  { label: 'Binalonan', value: 'Binalonan' },
  { label: 'Binalud', value: 'Binalud' },
  { label: 'Biñan', value: 'Biñan' },
  { label: 'Binangonan', value: 'Binangonan' },
  { label: 'Binantocan', value: 'Binantocan' },
  { label: 'Binanwanaan', value: 'Binanwanaan' },
  { label: 'Binarowa', value: 'Binarowa' },
  { label: 'Binasco', value: 'Binasco' },
  { label: 'Binau', value: 'Binau' },
  { label: 'Binauli', value: 'Binauli' },
  { label: 'Binaur', value: 'Binaur' },
  { label: 'Binay', value: 'Binay' },
  { label: 'Binbrook', value: 'Binbrook' },
  { label: 'Binche', value: 'Binche' },
  { label: 'Bincoñgan', value: 'Bincoñgan' },
  { label: 'Bincoñgan', value: 'Bincoñgan' },
  { label: 'Bincoñgan', value: 'Bincoñgan' },
  { label: 'Bindal', value: 'Bindal' },
  { label: 'Binday', value: 'Binday' },
  { label: 'Binday', value: 'Binday' },
  { label: 'Bindi', value: 'Bindi' },
  { label: 'Bindki', value: 'Bindki' },
  { label: 'Bindlach', value: 'Bindlach' },
  { label: 'Bindoon', value: 'Bindoon' },
  { label: 'Bindura', value: 'Bindura' },
  { label: 'Bindura District', value: 'Bindura District' },
  { label: 'Binéfar', value: 'Binéfar' },
  { label: 'Binetto', value: 'Binetto' },
  { label: 'Binga', value: 'Binga' },
  { label: 'Binga District', value: 'Binga District' },
  { label: 'Bingara', value: 'Bingara' },
  { label: 'Bingawan', value: 'Bingawan' },
  { label: 'Binġemma', value: 'Binġemma' },
  { label: 'Bingen', value: 'Bingen' },
  { label: 'Bingen Am Rhein', value: 'Bingen Am Rhein' },
  { label: 'Bingerville', value: 'Bingerville' },
  { label: 'Bingham', value: 'Bingham' },
  { label: 'Bingham County', value: 'Bingham County' },
  { label: 'Bingham Farms', value: 'Bingham Farms' },
  { label: 'Binghamton', value: 'Binghamton' },
  { label: 'Bingley', value: 'Bingley' },
  { label: 'Binguang', value: 'Binguang' },
  { label: 'Binguang', value: 'Binguang' },
  { label: 'Bình Long', value: 'Bình Long' },
  { label: 'Binhai New Area', value: 'Binhai New Area' },
  { label: 'Binhe', value: 'Binhe' },
  { label: 'Binic', value: 'Binic' },
  { label: 'Binidayan', value: 'Binidayan' },
  { label: 'Binidayan', value: 'Binidayan' },
  { label: 'Bining', value: 'Bining' },
  { label: 'Binissalem', value: 'Binissalem' },
  { label: 'Binitayan', value: 'Binitayan' },
  { label: 'Binitinan', value: 'Binitinan' },
  { label: 'Binjai', value: 'Binjai' },
  { label: 'Binji', value: 'Binji' },
  { label: 'Binka', value: 'Binka' },
  { label: 'Binkolo', value: 'Binkolo' },
  { label: 'Binlod', value: 'Binlod' },
  { label: 'Binmaley', value: 'Binmaley' },
  { label: 'Binmaley', value: 'Binmaley' },
  { label: 'Binnen', value: 'Binnen' },
  { label: 'Binnenhof', value: 'Binnenhof' },
  { label: 'Binnenstad', value: 'Binnenstad' },
  { label: 'Binnenstad', value: 'Binnenstad' },
  { label: 'Binningen', value: 'Binningen' },
  { label: 'Binningup', value: 'Binningup' },
  { label: 'Binnish', value: 'Binnish' },
  { label: 'Binodegahan', value: 'Binodegahan' },
  { label: 'Binon-An', value: 'Binon-An' },
  { label: 'Binonga', value: 'Binonga' },
  { label: 'Binouk', value: 'Binouk' },
  { label: 'Binsfeld', value: 'Binsfeld' },
  { label: 'Binswangen', value: 'Binswangen' },
  { label: 'Bintacay', value: 'Bintacay' },
  { label: 'Bintawan', value: 'Bintawan' },
  { label: 'Bintawan', value: 'Bintawan' },
  { label: 'Bintulu', value: 'Bintulu' },
  { label: 'Binuang', value: 'Binuang' },
  { label: 'Binuang', value: 'Binuang' },
  { label: 'Binuangan', value: 'Binuangan' },
  { label: 'Binuatan', value: 'Binuatan' },
  { label: 'Binubusan', value: 'Binubusan' },
  { label: 'Binucayan', value: 'Binucayan' },
  { label: 'Binucayan', value: 'Binucayan' },
  { label: 'Binulasan', value: 'Binulasan' },
  { label: 'Biny Selo', value: 'Biny Selo' },
  { label: 'Binz', value: 'Binz' },
  { label: 'Binzen', value: 'Binzen' },
  { label: 'Binzhou', value: 'Binzhou' },
  { label: 'Binzhou', value: 'Binzhou' },
  { label: 'Binzikon', value: 'Binzikon' },
  { label: 'Bioggio', value: 'Bioggio' },
  { label: 'Bioglio-Portula-Andrè', value: 'Bioglio-Portula-Andrè' },
  { label: 'Biograd Na Moru', value: 'Biograd Na Moru' },
  { label: 'Biol', value: 'Biol' },
  { label: 'Biola', value: 'Biola' },
  { label: 'Bionaz', value: 'Bionaz' },
  { label: 'Bione', value: 'Bione' },
  { label: 'Biorki', value: 'Biorki' },
  { label: 'Biosca', value: 'Biosca' },
  { label: 'Biot', value: 'Biot' },
  { label: 'Biota', value: 'Biota' },
  { label: 'Bippen', value: 'Bippen' },
  { label: 'Biquinhas', value: 'Biquinhas' },
  { label: 'Bir Al Mashi', value: 'Bir Al Mashi' },
  { label: 'Bir Ali Ben Khalifa', value: 'Bir Ali Ben Khalifa' },
  { label: 'Bir El Ater', value: 'Bir El Ater' },
  { label: 'Bir El Djir', value: 'Bir El Djir' },
  { label: 'Bir El Hafey', value: 'Bir El Hafey' },
  { label: 'Bīr El Maksūr', value: 'Bīr El Maksūr' },
  { label: 'Bira', value: 'Bira' },
  { label: 'Birakan', value: 'Birakan' },
  { label: 'Birao', value: 'Birao' },
  { label: 'Birbhaddar', value: 'Birbhaddar' },
  { label: 'Birbhum District', value: 'Birbhum District' },
  { label: 'Birch Bay', value: 'Birch Bay' },
  { label: 'Birch Run', value: 'Birch Run' },
  { label: 'Birchgrove', value: 'Birchgrove' },
  { label: 'Birchington-On-Sea', value: 'Birchington-On-Sea' },
  { label: 'Birchiş', value: 'Birchiş' },
  { label: 'Birchwil', value: 'Birchwil' },
  { label: 'Birchwood', value: 'Birchwood' },
  { label: 'Birchwood Lakes', value: 'Birchwood Lakes' },
  { label: 'Bircii', value: 'Bircii' },
  { label: 'Bircotes', value: 'Bircotes' },
  { label: 'Bircza', value: 'Bircza' },
  { label: 'Birda', value: 'Birda' },
  { label: 'Birdham', value: 'Birdham' },
  { label: 'Birdpur', value: 'Birdpur' },
  { label: 'Birdsboro', value: 'Birdsboro' },
  { label: 'Birdwell', value: 'Birdwell' },
  { label: 'Birdwood', value: 'Birdwood' },
  { label: 'Birecik', value: 'Birecik' },
  { label: 'Birenbach', value: 'Birenbach' },
  { label: 'Bireun', value: 'Bireun' },
  { label: 'Birgitz', value: 'Birgitz' },
  { label: 'Birgte', value: 'Birgte' },
  { label: 'Birgu (Vittoriosa)', value: 'Birgu (Vittoriosa)' },
  { label: 'Biri', value: 'Biri' },
  { label: 'Biri', value: 'Biri' },
  { label: 'Birigui', value: 'Birigui' },
  { label: 'Birilyusskiy Rayon', value: 'Birilyusskiy Rayon' },
  { label: 'Birinci Aşıqlı', value: 'Birinci Aşıqlı' },
  { label: 'Birine', value: 'Birine' },
  { label: 'Biritiba Mirim', value: 'Biritiba Mirim' },
  { label: 'Biritiba-Mirim', value: 'Biritiba-Mirim' },
  { label: 'Biritinga', value: 'Biritinga' },
  { label: 'Birjand', value: 'Birjand' },
  { label: 'Birkah', value: 'Birkah' },
  { label: 'Birkdale', value: 'Birkdale' },
  { label: 'Birkenau', value: 'Birkenau' },
  { label: 'Birkenfeld', value: 'Birkenfeld' },
  { label: 'Birkenfeld', value: 'Birkenfeld' },
  { label: 'Birkenfeld', value: 'Birkenfeld' },
  { label: 'Birkenhead', value: 'Birkenhead' },
  { label: 'Birkenhead', value: 'Birkenhead' },
  { label: 'Birkenheide', value: 'Birkenheide' },
  { label: 'Birken-Honigsessen', value: 'Birken-Honigsessen' },
  { label: 'Birkenwerder', value: 'Birkenwerder' },
  { label: 'Birkerød', value: 'Birkerød' },
  { label: 'Birkfeld', value: 'Birkfeld' },
  { label: 'Birkhadem', value: 'Birkhadem' },
  { label: 'Birkirkara', value: 'Birkirkara' },
  { label: 'Birlenbach', value: 'Birlenbach' },
  { label: 'Birlestik', value: 'Birlestik' },
  { label: 'Birmensdorf', value: 'Birmensdorf' },
  { label: 'Birmenstorf', value: 'Birmenstorf' },
  { label: 'Birmingham', value: 'Birmingham' },
  { label: 'Birmingham', value: 'Birmingham' },
  { label: 'Birmingham', value: 'Birmingham' },
  { label: 'Birmingham Gardens', value: 'Birmingham Gardens' },
  { label: 'Birmitrapur', value: 'Birmitrapur' },
  { label: 'Birni N Konni', value: 'Birni N Konni' },
  { label: 'Birnin Kebbi', value: 'Birnin Kebbi' },
  { label: 'Birnin Kudu', value: 'Birnin Kudu' },
  { label: 'Birniwa', value: 'Birniwa' },
  { label: 'Birobidzhan', value: 'Birobidzhan' },
  { label: 'Birobidzhanskiy Rayon', value: 'Birobidzhanskiy Rayon' },
  { label: 'Birori', value: 'Birori' },
  { label: 'Birpara', value: 'Birpara' },
  { label: 'Birpur', value: 'Birpur' },
  { label: 'Birpur', value: 'Birpur' },
  { label: 'Birr', value: 'Birr' },
  { label: 'Birr', value: 'Birr' },
  { label: 'Birresborn', value: 'Birresborn' },
  { label: 'Birrong', value: 'Birrong' },
  { label: 'Birsfelden', value: 'Birsfelden' },
  { label: 'Birsk', value: 'Birsk' },
  { label: 'Birstein', value: 'Birstein' },
  { label: 'Birštonas', value: 'Birštonas' },
  { label: 'Birtinya', value: 'Birtinya' },
  { label: 'Biruinţa', value: 'Biruinţa' },
  { label: 'Birur', value: 'Birur' },
  { label: 'Biryukove', value: 'Biryukove' },
  { label: 'Biryulëvo', value: 'Biryulëvo' },
  { label: 'Biryulëvo Zapadnoye', value: 'Biryulëvo Zapadnoye' },
  { label: 'Biryusinsk', value: 'Biryusinsk' },
  { label: 'Birzai', value: 'Birzai' },
  { label: 'Birżebbuġa', value: 'Birżebbuġa' },
  { label: 'Bisaccia', value: 'Bisaccia' },
  { label: 'Bisaccia Nuova', value: 'Bisaccia Nuova' },
  { label: 'Bisacquino', value: 'Bisacquino' },
  { label: 'Bisalpur', value: 'Bisalpur' },
  { label: 'Bisamberg', value: 'Bisamberg' },
  { label: 'Bisanda Buzurg', value: 'Bisanda Buzurg' },
  { label: 'Bisauli', value: 'Bisauli' },
  { label: 'Bisaurri', value: 'Bisaurri' },
  { label: 'Bisbal De Falset', value: 'Bisbal De Falset' },
  { label: 'Bisbal Del Penedès', value: 'Bisbal Del Penedès' },
  { label: 'Bisbal Dempordà La', value: 'Bisbal Dempordà La' },
  { label: 'Bisbee', value: 'Bisbee' },
  { label: 'Biscarrosse', value: 'Biscarrosse' },
  { label: 'Biscarrués', value: 'Biscarrués' },
  { label: 'Biscayne Park', value: 'Biscayne Park' },
  { label: 'Bisceglie', value: 'Bisceglie' },
  { label: 'Bischberg', value: 'Bischberg' },
  { label: 'Bischbrunn', value: 'Bischbrunn' },
  { label: 'Bischheim', value: 'Bischheim' },
  { label: 'Bischoffen', value: 'Bischoffen' },
  { label: 'Bischofferode', value: 'Bischofferode' },
  { label: 'Bischoffsheim', value: 'Bischoffsheim' },
  { label: 'Bischofsgrün', value: 'Bischofsgrün' },
  { label: 'Bischofsheim', value: 'Bischofsheim' },
  { label: 'Bischofsheim An Der Rhön', value: 'Bischofsheim An Der Rhön' },
  { label: 'Bischofshofen', value: 'Bischofshofen' },
  { label: 'Bischofsmais', value: 'Bischofsmais' },
  { label: 'Bischofstetten', value: 'Bischofstetten' },
  { label: 'Bischofswerda', value: 'Bischofswerda' },
  { label: 'Bischofswiesen', value: 'Bischofswiesen' },
  { label: 'Bischweier', value: 'Bischweier' },
  { label: 'Bischwiller', value: 'Bischwiller' },
  { label: 'Biscoe', value: 'Biscoe' },
  { label: 'Biscoitos', value: 'Biscoitos' },
  { label: 'Bisee', value: 'Bisee' },
  { label: 'Bisegna', value: 'Bisegna' },
  { label: 'Bisenda Buzurg', value: 'Bisenda Buzurg' },
  { label: 'Bisenti', value: 'Bisenti' },
  { label: 'Bisert’', value: 'Bisert’' },
  { label: 'Bish Aghaj', value: 'Bish Aghaj' },
  { label: 'Bishkek', value: 'Bishkek' },
  { label: 'Bishkul', value: 'Bishkul' },
  { label: 'Bishnah', value: 'Bishnah' },
  { label: 'Bishnupur', value: 'Bishnupur' },
  { label: 'Bishnupur', value: 'Bishnupur' },
  { label: 'Bishoftu', value: 'Bishoftu' },
  { label: 'Bishop', value: 'Bishop' },
  { label: 'Bishop', value: 'Bishop' },
  { label: 'Bishop Auckland', value: 'Bishop Auckland' },
  { label: 'Bishop Middleham', value: 'Bishop Middleham' },
  { label: 'Bishop Sutton', value: 'Bishop Sutton' },
  { label: 'Bishopbriggs', value: 'Bishopbriggs' },
  { label: 'Bishops Castle', value: 'Bishops Castle' },
  { label: 'Bishops Cleeve', value: 'Bishops Cleeve' },
  { label: 'Bishops Gap', value: 'Bishops Gap' },
  { label: 'Bishops Lydeard', value: 'Bishops Lydeard' },
  { label: 'Bishops Stortford', value: 'Bishops Stortford' },
  { label: 'Bishops Waltham', value: 'Bishops Waltham' },
  { label: 'Bishopsteignton', value: 'Bishopsteignton' },
  { label: 'Bishopstoke', value: 'Bishopstoke' },
  { label: 'Bishopston', value: 'Bishopston' },
  { label: 'Bishopstone', value: 'Bishopstone' },
  { label: 'Bishopsworth', value: 'Bishopsworth' },
  { label: 'Bishopthorpe', value: 'Bishopthorpe' },
  { label: 'Bishopton', value: 'Bishopton' },
  { label: 'Bishopton', value: 'Bishopton' },
  { label: 'Bishopville', value: 'Bishopville' },
  { label: 'Bishunpur Urf Maharajganj', value: 'Bishunpur Urf Maharajganj' },
  { label: 'Bisignano', value: 'Bisignano' },
  { label: 'Bisimbre', value: 'Bisimbre' },
  { label: 'Bisingen', value: 'Bisingen' },
  { label: 'Bisira', value: 'Bisira' },
  { label: 'Biskamzha', value: 'Biskamzha' },
  { label: 'Biskra', value: 'Biskra' },
  { label: 'Biskupice Radłowskie', value: 'Biskupice Radłowskie' },
  { label: 'Biskupiec', value: 'Biskupiec' },
  { label: 'Biskupin', value: 'Biskupin' },
  { label: 'Bislig', value: 'Bislig' },
  { label: 'Bislig', value: 'Bislig' },
  { label: 'Bislig', value: 'Bislig' },
  { label: 'Bislig', value: 'Bislig' },
  { label: 'Bismarck', value: 'Bismarck' },
  { label: 'Bismarck', value: 'Bismarck' },
  { label: 'Bismark', value: 'Bismark' },
  { label: 'Bismil', value: 'Bismil' },
  { label: 'Bismula', value: 'Bismula' },
  { label: 'Bisoca', value: 'Bisoca' },
  { label: 'Bison', value: 'Bison' },
  { label: 'Bisonó', value: 'Bisonó' },
  { label: 'Bisotun', value: 'Bisotun' },
  { label: 'Bispingen', value: 'Bispingen' },
  { label: 'Bissau', value: 'Bissau' },
  { label: 'Bissee', value: 'Bissee' },
  { label: 'Bissen', value: 'Bissen' },
  { label: 'Bissendorf', value: 'Bissendorf' },
  { label: 'Bissingen', value: 'Bissingen' },
  { label: 'Bissingen An Der Teck', value: 'Bissingen An Der Teck' },
  { label: 'Bissorã', value: 'Bissorã' },
  { label: 'Bistagno', value: 'Bistagno' },
  { label: 'Bistra', value: 'Bistra' },
  { label: 'Bistra', value: 'Bistra' },
  { label: 'Bistra', value: 'Bistra' },
  { label: 'Bistra', value: 'Bistra' },
  { label: 'Bistra Mureșului', value: 'Bistra Mureșului' },
  { label: 'Bistreț', value: 'Bistreț' },
  { label: 'Bistrica', value: 'Bistrica' },
  { label: 'Bistrica Ob Dravi', value: 'Bistrica Ob Dravi' },
  { label: 'Bistrica Ob Sotli', value: 'Bistrica Ob Sotli' },
  { label: 'Bistrica Pri Tržiču', value: 'Bistrica Pri Tržiču' },
  { label: 'Bistrik', value: 'Bistrik' },
  { label: 'Bistrinci', value: 'Bistrinci' },
  { label: 'Bistriţa', value: 'Bistriţa' },
  { label: 'Bistrița', value: 'Bistrița' },
  { label: 'Bistrița', value: 'Bistrița' },
  { label: 'Bistrița', value: 'Bistrița' },
  { label: 'Bistriţa Bârgăului', value: 'Bistriţa Bârgăului' },
  { label: 'Bistriyivka', value: 'Bistriyivka' },
  { label: 'Bisuschio', value: 'Bisuschio' },
  { label: 'Bisvalles', value: 'Bisvalles' },
  { label: 'Biswan', value: 'Biswan' },
  { label: 'Biszcza', value: 'Biszcza' },
  { label: 'Bisztynek', value: 'Bisztynek' },
  { label: 'Bitadtun', value: 'Bitadtun' },
  { label: 'Bitag Grande', value: 'Bitag Grande' },
  { label: 'Bitag Grande', value: 'Bitag Grande' },
  { label: 'Bitam', value: 'Bitam' },
  { label: 'Bitangan', value: 'Bitangan' },
  { label: 'Bitanjuan', value: 'Bitanjuan' },
  { label: 'Bitanjuan', value: 'Bitanjuan' },
  { label: 'Bitaogan', value: 'Bitaogan' },
  { label: 'Bitaogan', value: 'Bitaogan' },
  { label: 'Bitaogan', value: 'Bitaogan' },
  { label: 'Bitburg', value: 'Bitburg' },
  { label: 'Bitche', value: 'Bitche' },
  { label: 'Bitervan', value: 'Bitervan' },
  { label: 'Bitetto', value: 'Bitetto' },
  { label: 'Bithlo', value: 'Bithlo' },
  { label: 'Bithur', value: 'Bithur' },
  { label: 'Bitica', value: 'Bitica' },
  { label: 'Bitin', value: 'Bitin' },
  { label: 'Bitkine', value: 'Bitkine' },
  { label: 'Bito', value: 'Bito' },
  { label: 'Bitola', value: 'Bitola' },
  { label: 'Biton', value: 'Biton' },
  { label: 'Bitonto', value: 'Bitonto' },
  { label: 'Bitoon', value: 'Bitoon' },
  { label: 'Bitritto', value: 'Bitritto' },
  { label: 'Bitschwiller-Lès-Thann', value: 'Bitschwiller-Lès-Thann' },
  { label: 'Bitswijk', value: 'Bitswijk' },
  { label: 'Bitterfeld-Wolfen', value: 'Bitterfeld-Wolfen' },
  { label: 'Bittern', value: 'Bittern' },
  { label: 'Bitti', value: 'Bitti' },
  { label: 'Bitton', value: 'Bitton' },
  { label: 'Bituima', value: 'Bituima' },
  { label: 'Bituñgol', value: 'Bituñgol' },
  { label: 'Bituruna', value: 'Bituruna' },
  { label: 'Bitz', value: 'Bitz' },
  { label: 'Biu', value: 'Biu' },
  { label: 'Biure', value: 'Biure' },
  { label: 'Biurrun-Olcoz', value: 'Biurrun-Olcoz' },
  { label: 'Biviers', value: 'Biviers' },
  { label: 'Bivio Di Capanelle', value: 'Bivio Di Capanelle' },
  { label: 'Bivio Mortola', value: 'Bivio Mortola' },
  { label: 'Bivio San Polo', value: 'Bivio San Polo' },
  { label: 'Bivio Santa Cecilia', value: 'Bivio Santa Cecilia' },
  { label: 'Bivolari', value: 'Bivolari' },
  { label: 'Bivolărie', value: 'Bivolărie' },
  { label: 'Bivona', value: 'Bivona' },
  { label: 'Bivongi', value: 'Bivongi' },
  { label: 'Biwang', value: 'Biwang' },
  { label: 'Biwang', value: 'Biwang' },
  { label: 'Biwer', value: 'Biwer' },
  { label: 'Bixad', value: 'Bixad' },
  { label: 'Bixad', value: 'Bixad' },
  { label: 'Bixby', value: 'Bixby' },
  { label: 'Biyārjomand', value: 'Biyārjomand' },
  { label: 'Biyong', value: 'Biyong' },
  { label: 'Biysk', value: 'Biysk' },
  { label: 'Bizanet', value: 'Bizanet' },
  { label: 'Bizanos', value: 'Bizanos' },
  { label: 'Bizau', value: 'Bizau' },
  { label: 'Bize-Minervois', value: 'Bize-Minervois' },
  { label: 'Bizen Shi', value: 'Bizen Shi' },
  { label: 'Bizerte', value: 'Bizerte' },
  { label: 'Bizerte Sud', value: 'Bizerte Sud' },
  { label: 'Bizhbulyak', value: 'Bizhbulyak' },
  { label: 'Bizovac', value: 'Bizovac' },
  { label: 'Bizzarone', value: 'Bizzarone' },
  { label: 'Bjæverskov', value: 'Bjæverskov' },
  { label: 'Bjärnum', value: 'Bjärnum' },
  { label: 'Bjärred', value: 'Bjärred' },
  { label: 'Bjästa', value: 'Bjästa' },
  { label: 'Bjelovar', value: 'Bjelovar' },
  { label: 'Bjerkreim', value: 'Bjerkreim' },
  { label: 'Bjerkvik', value: 'Bjerkvik' },
  { label: 'Bjerringbro', value: 'Bjerringbro' },
  { label: 'Bjni', value: 'Bjni' },
  { label: 'Björboholm', value: 'Björboholm' },
  { label: 'Bjørkelangen', value: 'Bjørkelangen' },
  { label: 'Björklinge', value: 'Björklinge' },
  { label: 'Björkö', value: 'Björkö' },
  { label: 'Björkskatan', value: 'Björkskatan' },
  { label: 'Björlanda', value: 'Björlanda' },
  { label: 'Björneborg', value: 'Björneborg' },
  { label: 'Bjørnevatn', value: 'Bjørnevatn' },
  { label: 'Bjugn', value: 'Bjugn' },
  { label: 'Bjurholm', value: 'Bjurholm' },
  { label: 'Bjursås', value: 'Bjursås' },
  { label: 'Bjuv', value: 'Bjuv' },
  { label: 'Blaarthem', value: 'Blaarthem' },
  { label: 'Blaby', value: 'Blaby' },
  { label: 'Blacé', value: 'Blacé' },
  { label: 'Blachownia', value: 'Blachownia' },
  { label: 'Black Bay', value: 'Black Bay' },
  { label: 'Black Canyon City', value: 'Black Canyon City' },
  { label: 'Black Creek', value: 'Black Creek' },
  { label: 'Black Diamond', value: 'Black Diamond' },
  { label: 'Black Diamond', value: 'Black Diamond' },
  { label: 'Black Diamond', value: 'Black Diamond' },
  { label: 'Black Earth', value: 'Black Earth' },
  { label: 'Black Forest', value: 'Black Forest' },
  { label: 'Black Forest', value: 'Black Forest' },
  { label: 'Black Hawk County', value: 'Black Hawk County' },
  { label: 'Black Hill', value: 'Black Hill' },
  { label: 'Black Hill', value: 'Black Hill' },
  { label: 'Black Jack', value: 'Black Jack' },
  { label: 'Black Lick', value: 'Black Lick' },
  { label: 'Black Mallet', value: 'Black Mallet' },
  { label: 'Black Mountain', value: 'Black Mountain' },
  { label: 'Black Mountain', value: 'Black Mountain' },
  { label: 'Black Notley', value: 'Black Notley' },
  { label: 'Black Point-Green Point', value: 'Black Point-Green Point' },
  { label: 'Black River', value: 'Black River' },
  { label: 'Black River', value: 'Black River' },
  { label: 'Black River', value: 'Black River' },
  { label: 'Black River', value: 'Black River' },
  { label: 'Black River Falls', value: 'Black River Falls' },
  { label: 'Black Rock', value: 'Black Rock' },
  { label: 'Black Rock', value: 'Black Rock' },
  { label: 'Blackall', value: 'Blackall' },
  { label: 'Blackall Tambo', value: 'Blackall Tambo' },
  { label: 'Blackalls Park', value: 'Blackalls Park' },
  { label: 'Blackburn', value: 'Blackburn' },
  { label: 'Blackburn', value: 'Blackburn' },
  { label: 'Blackburn', value: 'Blackburn' },
  { label: 'Blackburn North', value: 'Blackburn North' },
  { label: 'Blackburn South', value: 'Blackburn South' },
  { label: 'Blackburn With Darwen', value: 'Blackburn With Darwen' },
  { label: 'Blackbutt', value: 'Blackbutt' },
  { label: 'Blackett', value: 'Blackett' },
  { label: 'Blackfalds', value: 'Blackfalds' },
  { label: 'Blackfoot', value: 'Blackfoot' },
  { label: 'Blackford County', value: 'Blackford County' },
  { label: 'Blackhawk', value: 'Blackhawk' },
  { label: 'Blackhawk', value: 'Blackhawk' },
  { label: 'Blackheath', value: 'Blackheath' },
  { label: 'Blackheath', value: 'Blackheath' },
  { label: 'Blackley', value: 'Blackley' },
  { label: 'Blacklick Estates', value: 'Blacklick Estates' },
  { label: 'Blackmans Bay', value: 'Blackmans Bay' },
  { label: 'Blackmoorfoot', value: 'Blackmoorfoot' },
  { label: 'Blackpool', value: 'Blackpool' },
  { label: 'Blackridge', value: 'Blackridge' },
  { label: 'Blackrock', value: 'Blackrock' },
  { label: 'Blackrod', value: 'Blackrod' },
  { label: 'Blacks Beach', value: 'Blacks Beach' },
  { label: 'Blacksburg', value: 'Blacksburg' },
  { label: 'Blacksburg', value: 'Blacksburg' },
  { label: 'Blackshear', value: 'Blackshear' },
  { label: 'Blacksmiths', value: 'Blacksmiths' },
  { label: 'Blackstone', value: 'Blackstone' },
  { label: 'Blackstone', value: 'Blackstone' },
  { label: 'Blackstone Heights', value: 'Blackstone Heights' },
  { label: 'Blackstonedge', value: 'Blackstonedge' },
  { label: 'Blacktown', value: 'Blacktown' },
  { label: 'Blackville', value: 'Blackville' },
  { label: 'Blackwall', value: 'Blackwall' },
  { label: 'Blackwater', value: 'Blackwater' },
  { label: 'Blackwater', value: 'Blackwater' },
  { label: 'Blackwell', value: 'Blackwell' },
  { label: 'Blackwell', value: 'Blackwell' },
  { label: 'Blackwood', value: 'Blackwood' },
  { label: 'Blackwood', value: 'Blackwood' },
  { label: 'Blackwood', value: 'Blackwood' },
  { label: 'Blackwood', value: 'Blackwood' },
  { label: 'Blacon', value: 'Blacon' },
  { label: 'Blacos', value: 'Blacos' },
  { label: 'Bladel', value: 'Bladel' },
  { label: 'Bladen County', value: 'Bladen County' },
  { label: 'Bladenboro', value: 'Bladenboro' },
  { label: 'Bladensburg', value: 'Bladensburg' },
  { label: 'Blades', value: 'Blades' },
  { label: 'Blaenau Gwent', value: 'Blaenau Gwent' },
  { label: 'Blaenau-Ffestiniog', value: 'Blaenau-Ffestiniog' },
  { label: 'Blaenavon', value: 'Blaenavon' },
  { label: 'Blaengwynfi', value: 'Blaengwynfi' },
  { label: 'Blaesheim', value: 'Blaesheim' },
  { label: 'Blagaj', value: 'Blagaj' },
  { label: 'Blagdon', value: 'Blagdon' },
  { label: 'Blăgeşti', value: 'Blăgeşti' },
  { label: 'Blăgeşti', value: 'Blăgeşti' },
  { label: 'Blagnac', value: 'Blagnac' },
  { label: 'Blagny', value: 'Blagny' },
  { label: 'Blagodarnyy', value: 'Blagodarnyy' },
  { label: 'Blagodatnoye', value: 'Blagodatnoye' },
  { label: 'Blagodatnoye', value: 'Blagodatnoye' },
  { label: 'Blagoevgrad', value: 'Blagoevgrad' },
  { label: 'Blagoveshchenka', value: 'Blagoveshchenka' },
  { label: 'Blagoveshchensk', value: 'Blagoveshchensk' },
  { label: 'Blagoveshchensk', value: 'Blagoveshchensk' },
  { label: 'Blagoveshchenskiy Rayon', value: 'Blagoveshchenskiy Rayon' },
  { label: 'Blagovetschenskaya', value: 'Blagovetschenskaya' },
  { label: 'Blagoyevo', value: 'Blagoyevo' },
  { label: 'Blahodatne', value: 'Blahodatne' },
  { label: 'Blahodatne', value: 'Blahodatne' },
  { label: 'Blahovishchenske Raion', value: 'Blahovishchenske Raion' },
  { label: 'Blaibach', value: 'Blaibach' },
  { label: 'Blaichach', value: 'Blaichach' },
  { label: 'Blain', value: 'Blain' },
  { label: 'Blaincourt-Lès-Précy', value: 'Blaincourt-Lès-Précy' },
  { label: 'Blaindorf', value: 'Blaindorf' },
  { label: 'Blaine', value: 'Blaine' },
  { label: 'Blaine', value: 'Blaine' },
  { label: 'Blaine', value: 'Blaine' },
  { label: 'Blaine County', value: 'Blaine County' },
  { label: 'Blaine County', value: 'Blaine County' },
  { label: 'Blaine County', value: 'Blaine County' },
  { label: 'Blaine County', value: 'Blaine County' },
  { label: 'Blainville', value: 'Blainville' },
  { label: 'Blainville-Crevon', value: 'Blainville-Crevon' },
  { label: 'Blainville-Sur-Mer', value: 'Blainville-Sur-Mer' },
  { label: 'Blainville-Sur-Orne', value: 'Blainville-Sur-Orne' },
  { label: 'Blair', value: 'Blair' },
  { label: 'Blair', value: 'Blair' },
  { label: 'Blair Athol', value: 'Blair Athol' },
  { label: 'Blair Athol', value: 'Blair Athol' },
  { label: 'Blair County', value: 'Blair County' },
  { label: 'Blairgowrie', value: 'Blairgowrie' },
  { label: 'Blairgowrie', value: 'Blairgowrie' },
  { label: 'Blairsville', value: 'Blairsville' },
  { label: 'Blairsville', value: 'Blairsville' },
  { label: 'Blaison-Gohier', value: 'Blaison-Gohier' },
  { label: 'Blaj', value: 'Blaj' },
  { label: 'Blăjani', value: 'Blăjani' },
  { label: 'Blăjel', value: 'Blăjel' },
  { label: 'Blăjeni', value: 'Blăjeni' },
  { label: 'Blakehurst', value: 'Blakehurst' },
  { label: 'Blakely', value: 'Blakely' },
  { label: 'Blakely', value: 'Blakely' },
  { label: 'Blakeview', value: 'Blakeview' },
  { label: 'Blakstad', value: 'Blakstad' },
  { label: 'Blama', value: 'Blama' },
  { label: 'Blamont', value: 'Blamont' },
  { label: 'Blanca', value: 'Blanca' },
  { label: 'Blanca Espuma', value: 'Blanca Espuma' },
  { label: 'Blancafort', value: 'Blancafort' },
  { label: 'Blancafort', value: 'Blancafort' },
  { label: 'Blancas', value: 'Blancas' },
  { label: 'Blanchard', value: 'Blanchard' },
  { label: 'Blanchard', value: 'Blanchard' },
  { label: 'Blanchard', value: 'Blanchard' },
  { label: 'Blanchardstown', value: 'Blanchardstown' },
  { label: 'Blanchester', value: 'Blanchester' },
  { label: 'Blanco', value: 'Blanco' },
  { label: 'Blanco County', value: 'Blanco County' },
  { label: 'Blancos Os', value: 'Blancos Os' },
  { label: 'Bland', value: 'Bland' },
  { label: 'Bland', value: 'Bland' },
  { label: 'Bland County', value: 'Bland County' },
  { label: 'Blândeşti', value: 'Blândeşti' },
  { label: 'Blandford Forum', value: 'Blandford Forum' },
  { label: 'Blândiana', value: 'Blândiana' },
  { label: 'Blanding', value: 'Blanding' },
  { label: 'Blandon', value: 'Blandon' },
  { label: 'Blanes', value: 'Blanes' },
  { label: 'Blangy-Sur-Bresle', value: 'Blangy-Sur-Bresle' },
  { label: 'Blankenbach', value: 'Blankenbach' },
  { label: 'Blankenberge', value: 'Blankenberge' },
  { label: 'Blankenburg', value: 'Blankenburg' },
  { label: 'Blankenburg', value: 'Blankenburg' },
  { label: 'Blankenfelde', value: 'Blankenfelde' },
  { label: 'Blankenhain', value: 'Blankenhain' },
  { label: 'Blankenheim', value: 'Blankenheim' },
  { label: 'Blankenheim', value: 'Blankenheim' },
  { label: 'Blankenrath', value: 'Blankenrath' },
  { label: 'Blankensee', value: 'Blankensee' },
  { label: 'Blanquefort', value: 'Blanquefort' },
  { label: 'Blanquillo', value: 'Blanquillo' },
  { label: 'Blansko', value: 'Blansko' },
  { label: 'Blantyre', value: 'Blantyre' },
  { label: 'Blantyre', value: 'Blantyre' },
  { label: 'Blantyre District', value: 'Blantyre District' },
  { label: 'Blanzat', value: 'Blanzat' },
  { label: 'Blânzi', value: 'Blânzi' },
  { label: 'Blanzy', value: 'Blanzy' },
  { label: 'Blaricum', value: 'Blaricum' },
  { label: 'Blaringhem', value: 'Blaringhem' },
  { label: 'Blarney', value: 'Blarney' },
  { label: 'Blascomillán', value: 'Blascomillán' },
  { label: 'Blasconuño De Matacabras', value: 'Blasconuño De Matacabras' },
  { label: 'Blascosancho', value: 'Blascosancho' },
  { label: 'Blasdell', value: 'Blasdell' },
  { label: 'Bláskógabyggð', value: 'Bláskógabyggð' },
  { label: 'Błaszki', value: 'Błaszki' },
  { label: 'Blata L Bajda', value: 'Blata L Bajda' },
  { label: 'Blatec', value: 'Blatec' },
  { label: 'Blatná', value: 'Blatná' },
  { label: 'Blatnica', value: 'Blatnica' },
  { label: 'Blaubeuren', value: 'Blaubeuren' },
  { label: 'Blaufelden', value: 'Blaufelden' },
  { label: 'Blausasc', value: 'Blausasc' },
  { label: 'Blauvelt', value: 'Blauvelt' },
  { label: 'Blauwe Keet', value: 'Blauwe Keet' },
  { label: 'Blauzac', value: 'Blauzac' },
  { label: 'Blavozy', value: 'Blavozy' },
  { label: 'Blawnox', value: 'Blawnox' },
  { label: 'Blaxland', value: 'Blaxland' },
  { label: 'Blaydon-On-Tyne', value: 'Blaydon-On-Tyne' },
  { label: 'Blaye', value: 'Blaye' },
  { label: 'Blaye-Les-Mines', value: 'Blaye-Les-Mines' },
  { label: 'Blayney', value: 'Blayney' },
  { label: 'Blažovice', value: 'Blažovice' },
  { label: 'Błażowa', value: 'Błażowa' },
  { label: 'Blázquez Los', value: 'Blázquez Los' },
  { label: 'Bleadon', value: 'Bleadon' },
  { label: 'Blean', value: 'Blean' },
  { label: 'Blechepsin', value: 'Blechepsin' },
  { label: 'Blécherette', value: 'Blécherette' },
  { label: 'Bleckede', value: 'Bleckede' },
  { label: 'Bleckley County', value: 'Bleckley County' },
  { label: 'Blecua Y Torres', value: 'Blecua Y Torres' },
  { label: 'Bled', value: 'Bled' },
  { label: 'Bledos', value: 'Bledos' },
  { label: 'Błędów', value: 'Błędów' },
  { label: 'Bledsoe County', value: 'Bledsoe County' },
  { label: 'Bledzew', value: 'Bledzew' },
  { label: 'Bleggio Superiore', value: 'Bleggio Superiore' },
  { label: 'Blégny', value: 'Blégny' },
  { label: 'Bleialf', value: 'Bleialf' },
  { label: 'Bleiburg', value: 'Bleiburg' },
  { label: 'Bleicherode', value: 'Bleicherode' },
  { label: 'Bleijerheide', value: 'Bleijerheide' },
  { label: 'Bleiswijk', value: 'Bleiswijk' },
  { label: 'Blejeşti', value: 'Blejeşti' },
  { label: 'Blejoi', value: 'Blejoi' },
  { label: 'Blekendorf', value: 'Blekendorf' },
  { label: 'Blello', value: 'Blello' },
  { label: 'Blendecques', value: 'Blendecques' },
  { label: 'Blender', value: 'Blender' },
  { label: 'Bléneau', value: 'Bléneau' },
  { label: 'Blenheim', value: 'Blenheim' },
  { label: 'Blenio District', value: 'Blenio District' },
  { label: 'Blennerhassett', value: 'Blennerhassett' },
  { label: 'Blénod-Lès-Pont-À-Mousson', value: 'Blénod-Lès-Pont-À-Mousson' },
  { label: 'Blénod-Lès-Toul', value: 'Blénod-Lès-Toul' },
  { label: 'Blentarp', value: 'Blentarp' },
  { label: 'Blera', value: 'Blera' },
  { label: 'Blérancourt', value: 'Blérancourt' },
  { label: 'Bléré', value: 'Bléré' },
  { label: 'Blesa', value: 'Blesa' },
  { label: 'Bleskensgraaf', value: 'Bleskensgraaf' },
  { label: 'Blessagno', value: 'Blessagno' },
  { label: 'Blessington', value: 'Blessington' },
  { label: 'Bletchingley', value: 'Bletchingley' },
  { label: 'Bletchley', value: 'Bletchley' },
  { label: 'Bletterans', value: 'Bletterans' },
  { label: 'Blevio', value: 'Blevio' },
  { label: 'Blewbury', value: 'Blewbury' },
  { label: 'Bli Bli', value: 'Bli Bli' },
  { label: 'Blida', value: 'Blida' },
  { label: 'Blidari', value: 'Blidari' },
  { label: 'Blideşti', value: 'Blideşti' },
  { label: 'Blidireasa', value: 'Blidireasa' },
  { label: 'Blidworth', value: 'Blidworth' },
  { label: 'Bliecos', value: 'Bliecos' },
  { label: 'Bliedersdorf', value: 'Bliedersdorf' },
  { label: 'Bliesbruck', value: 'Bliesbruck' },
  { label: 'Bliesdorf', value: 'Bliesdorf' },
  { label: 'Blieskastel', value: 'Blieskastel' },
  { label: 'Bligh Park', value: 'Bligh Park' },
  { label: 'Bligny-Lès-Beaune', value: 'Bligny-Lès-Beaune' },
  { label: 'Blijham', value: 'Blijham' },
  { label: 'Blind Bight', value: 'Blind Bight' },
  { label: 'Blindenmarkt', value: 'Blindenmarkt' },
  { label: 'Blindheim', value: 'Blindheim' },
  { label: 'Blindley Heath', value: 'Blindley Heath' },
  { label: 'Blingkong', value: 'Blingkong' },
  { label: 'Blingkong', value: 'Blingkong' },
  { label: 'Blinsung', value: 'Blinsung' },
  { label: 'Blinsung', value: 'Blinsung' },
  { label: 'Bliss Corner', value: 'Bliss Corner' },
  { label: 'Blissfield', value: 'Blissfield' },
  { label: 'Blisworth', value: 'Blisworth' },
  { label: 'Blitar', value: 'Blitar' },
  { label: 'Blitterswijck', value: 'Blitterswijck' },
  { label: 'Blizanów', value: 'Blizanów' },
  { label: 'Blížejov', value: 'Blížejov' },
  { label: 'Blizhne-Pesochnoye', value: 'Blizhne-Pesochnoye' },
  { label: 'Blizhneye', value: 'Blizhneye' },
  { label: 'Blížkovice', value: 'Blížkovice' },
  { label: 'Blizne', value: 'Blizne' },
  { label: 'Bliżyn', value: 'Bliżyn' },
  { label: 'Blockley', value: 'Blockley' },
  { label: 'Blodelsheim', value: 'Blodelsheim' },
  { label: 'Bloemendaal', value: 'Bloemendaal' },
  { label: 'Bloemendaal', value: 'Bloemendaal' },
  { label: 'Bloemfontein', value: 'Bloemfontein' },
  { label: 'Bloemhof', value: 'Bloemhof' },
  { label: 'Bloemhof', value: 'Bloemhof' },
  { label: 'Blois', value: 'Blois' },
  { label: 'Blokker', value: 'Blokker' },
  { label: 'Blokzijl', value: 'Blokzijl' },
  { label: 'Blomberg', value: 'Blomberg' },
  { label: 'Blomberg', value: 'Blomberg' },
  { label: 'Blomstermåla', value: 'Blomstermåla' },
  { label: 'Blon’', value: 'Blon’' },
  { label: 'Blonay', value: 'Blonay' },
  { label: 'Błonie', value: 'Błonie' },
  { label: 'Blons', value: 'Blons' },
  { label: 'Blonville-Sur-Mer', value: 'Blonville-Sur-Mer' },
  { label: 'Bloomer', value: 'Bloomer' },
  { label: 'Bloomfield', value: 'Bloomfield' },
  { label: 'Bloomfield', value: 'Bloomfield' },
  { label: 'Bloomfield', value: 'Bloomfield' },
  { label: 'Bloomfield', value: 'Bloomfield' },
  { label: 'Bloomfield', value: 'Bloomfield' },
  { label: 'Bloomfield', value: 'Bloomfield' },
  { label: 'Bloomfield', value: 'Bloomfield' },
  { label: 'Bloomfield', value: 'Bloomfield' },
  { label: 'Bloomfield Hills', value: 'Bloomfield Hills' },
  { label: 'Blooming Prairie', value: 'Blooming Prairie' },
  { label: 'Bloomingdale', value: 'Bloomingdale' },
  { label: 'Bloomingdale', value: 'Bloomingdale' },
  { label: 'Bloomingdale', value: 'Bloomingdale' },
  { label: 'Bloomingdale', value: 'Bloomingdale' },
  { label: 'Bloomingdale', value: 'Bloomingdale' },
  { label: 'Bloomingdale', value: 'Bloomingdale' },
  { label: 'Bloomington', value: 'Bloomington' },
  { label: 'Bloomington', value: 'Bloomington' },
  { label: 'Bloomington', value: 'Bloomington' },
  { label: 'Bloomington', value: 'Bloomington' },
  { label: 'Bloomington', value: 'Bloomington' },
  { label: 'Bloomsburg', value: 'Bloomsburg' },
  { label: 'Blora', value: 'Blora' },
  { label: 'Blossburg', value: 'Blossburg' },
  { label: 'Blossom', value: 'Blossom' },
  { label: 'Blotzheim', value: 'Blotzheim' },
  { label: 'Blount County', value: 'Blount County' },
  { label: 'Blount County', value: 'Blount County' },
  { label: 'Blountstown', value: 'Blountstown' },
  { label: 'Blountsville', value: 'Blountsville' },
  { label: 'Blountville', value: 'Blountville' },
  { label: 'Blovice', value: 'Blovice' },
  { label: 'Blovstrød', value: 'Blovstrød' },
  { label: 'Blowatz', value: 'Blowatz' },
  { label: 'Blowing Rock', value: 'Blowing Rock' },
  { label: 'Bloxborough', value: 'Bloxborough' },
  { label: 'Bloxham', value: 'Bloxham' },
  { label: 'Bloxwich', value: 'Bloxwich' },
  { label: 'Blučina', value: 'Blučina' },
  { label: 'Bludenz', value: 'Bludenz' },
  { label: 'Bludesch', value: 'Bludesch' },
  { label: 'Bludov', value: 'Bludov' },
  { label: 'Blue Ash', value: 'Blue Ash' },
  { label: 'Blue Ball', value: 'Blue Ball' },
  { label: 'Blue Bay', value: 'Blue Bay' },
  { label: 'Blue Bell', value: 'Blue Bell' },
  { label: 'Blue Earth', value: 'Blue Earth' },
  { label: 'Blue Earth County', value: 'Blue Earth County' },
  { label: 'Blue Grass', value: 'Blue Grass' },
  { label: 'Blue Haven', value: 'Blue Haven' },
  { label: 'Blue Hills', value: 'Blue Hills' },
  { label: 'Blue Island', value: 'Blue Island' },
  { label: 'Blue Lake', value: 'Blue Lake' },
  { label: 'Blue Mound', value: 'Blue Mound' },
  { label: 'Blue Mound', value: 'Blue Mound' },
  { label: 'Blue Mountain', value: 'Blue Mountain' },
  { label: 'Blue Mountains Municipality', value: 'Blue Mountains Municipality' },
  { label: 'Blue Point', value: 'Blue Point' },
  { label: 'Blue Ridge', value: 'Blue Ridge' },
  { label: 'Blue Ridge', value: 'Blue Ridge' },
  { label: 'Blue Ridge', value: 'Blue Ridge' },
  { label: 'Blue Springs', value: 'Blue Springs' },
  { label: 'Bluefield', value: 'Bluefield' },
  { label: 'Bluefield', value: 'Bluefield' },
  { label: 'Bluefields', value: 'Bluefields' },
  { label: 'Bluefields', value: 'Bluefields' },
  { label: 'Bluewater', value: 'Bluewater' },
  { label: 'Bluewater', value: 'Bluewater' },
  { label: 'Bluewell', value: 'Bluewell' },
  { label: 'Bluff', value: 'Bluff' },
  { label: 'Bluff City', value: 'Bluff City' },
  { label: 'Bluff Point', value: 'Bluff Point' },
  { label: 'Bluffdale', value: 'Bluffdale' },
  { label: 'Bluffton', value: 'Bluffton' },
  { label: 'Bluffton', value: 'Bluffton' },
  { label: 'Bluffton', value: 'Bluffton' },
  { label: 'Blufi', value: 'Blufi' },
  { label: 'Blumberg', value: 'Blumberg' },
  { label: 'Blumenau', value: 'Blumenau' },
  { label: 'Blumenstein', value: 'Blumenstein' },
  { label: 'Blunham', value: 'Blunham' },
  { label: 'Bluntisham', value: 'Bluntisham' },
  { label: 'Blyth', value: 'Blyth' },
  { label: 'Blythe', value: 'Blythe' },
  { label: 'Blytheville', value: 'Blytheville' },
  { label: 'Blythewood', value: 'Blythewood' },
  { label: 'Blyton', value: 'Blyton' },
  { label: 'Blyznyuky', value: 'Blyznyuky' },
  { label: 'Bnei Ayish', value: 'Bnei Ayish' },
  { label: 'Bnei Brak', value: 'Bnei Brak' },
  { label: 'Bni Bouayach', value: 'Bni Bouayach' },
  { label: 'Bo', value: 'Bo' },
  { label: 'Bø', value: 'Bø' },
  { label: 'Bø', value: 'Bø' },
  { label: 'Bo District', value: 'Bo District' },
  { label: 'Bo Phloi', value: 'Bo Phloi' },
  { label: 'Bo Rai', value: 'Bo Rai' },
  { label: 'Bo‘Ka', value: 'Bo‘Ka' },
  { label: 'Bo‘Z Tumani', value: 'Bo‘Z Tumani' },
  { label: 'Bo’Ness', value: 'Bo’Ness' },
  { label: 'Boa Esperança', value: 'Boa Esperança' },
  { label: 'Boa Esperança', value: 'Boa Esperança' },
  { label: 'Boa Esperança', value: 'Boa Esperança' },
  { label: 'Boa Esperança Do Iguaçu', value: 'Boa Esperança Do Iguaçu' },
  { label: 'Boa Esperança Do Sul', value: 'Boa Esperança Do Sul' },
  { label: 'Boa Hora', value: 'Boa Hora' },
  { label: 'Boa Nova', value: 'Boa Nova' },
  { label: 'Boa Saúde', value: 'Boa Saúde' },
  { label: 'Boa Ventura', value: 'Boa Ventura' },
  { label: 'Boa Ventura De São Roque', value: 'Boa Ventura De São Roque' },
  { label: 'Boa Viagem', value: 'Boa Viagem' },
  { label: 'Boa Vista', value: 'Boa Vista' },
  { label: 'Boa Vista', value: 'Boa Vista' },
  { label: 'Boa Vista Da Aparecida', value: 'Boa Vista Da Aparecida' },
  { label: 'Boa Vista Das Missões', value: 'Boa Vista Das Missões' },
  { label: 'Boa Vista Do Buricá', value: 'Boa Vista Do Buricá' },
  { label: 'Boa Vista Do Cadeado', value: 'Boa Vista Do Cadeado' },
  { label: 'Boa Vista Do Gurupi', value: 'Boa Vista Do Gurupi' },
  { label: 'Boa Vista Do Incra', value: 'Boa Vista Do Incra' },
  { label: 'Boa Vista Do Ramos', value: 'Boa Vista Do Ramos' },
  { label: 'Boa Vista Do Sul', value: 'Boa Vista Do Sul' },
  { label: 'Boa Vista Do Tupim', value: 'Boa Vista Do Tupim' },
  { label: 'Boac', value: 'Boac' },
  { label: 'Boaco', value: 'Boaco' },
  { label: 'Boada', value: 'Boada' },
  { label: 'Boada De Campos', value: 'Boada De Campos' },
  { label: 'Boadella I Les Escaules', value: 'Boadella I Les Escaules' },
  { label: 'Boadilla De Rioseco', value: 'Boadilla De Rioseco' },
  { label: 'Boadilla Del Camino', value: 'Boadilla Del Camino' },
  { label: 'Boadilla Del Monte', value: 'Boadilla Del Monte' },
  { label: 'Boaitha', value: 'Boaitha' },
  { label: 'Boajibu', value: 'Boajibu' },
  { label: 'Boal', value: 'Boal' },
  { label: 'Boali', value: 'Boali' },
  { label: 'Boalo', value: 'Boalo' },
  { label: 'Boalo El', value: 'Boalo El' },
  { label: 'Boalsburg', value: 'Boalsburg' },
  { label: 'Boambee', value: 'Boambee' },
  { label: 'Boambee East', value: 'Boambee East' },
  { label: 'Boane District', value: 'Boane District' },
  { label: 'Boara Pisani', value: 'Boara Pisani' },
  { label: 'Boardman', value: 'Boardman' },
  { label: 'Boardman', value: 'Boardman' },
  { label: 'Boaventura', value: 'Boaventura' },
  { label: 'Boavista Dos Pinheiros', value: 'Boavista Dos Pinheiros' },
  { label: 'Boavita', value: 'Boavita' },
  { label: 'Boaz', value: 'Boaz' },
  { label: 'Boaz', value: 'Boaz' },
  { label: 'Bobadela', value: 'Bobadela' },
  { label: 'Bobadela', value: 'Bobadela' },
  { label: 'Bobadilla', value: 'Bobadilla' },
  { label: 'Bobadilla Del Campo', value: 'Bobadilla Del Campo' },
  { label: 'Bobâlna', value: 'Bobâlna' },
  { label: 'Bobashi De Guadalupe', value: 'Bobashi De Guadalupe' },
  { label: 'Bobbau', value: 'Bobbau' },
  { label: 'Bobbili', value: 'Bobbili' },
  { label: 'Bobbio', value: 'Bobbio' },
  { label: 'Bobbio Pellice', value: 'Bobbio Pellice' },
  { label: 'Bobeldijk', value: 'Bobeldijk' },
  { label: 'Bobenheim-Roxheim', value: 'Bobenheim-Roxheim' },
  { label: 'Bobiceşti', value: 'Bobiceşti' },
  { label: 'Bobigny', value: 'Bobigny' },
  { label: 'Böbing', value: 'Böbing' },
  { label: 'Bobingen', value: 'Bobingen' },
  { label: 'Böbingen An Der Rems', value: 'Böbingen An Der Rems' },
  { label: 'Bobitz', value: 'Bobitz' },
  { label: 'Böblingen', value: 'Böblingen' },
  { label: 'Boboc', value: 'Boboc' },
  { label: 'Bobo-Dioulasso', value: 'Bobo-Dioulasso' },
  { label: 'Bobohalma', value: 'Bobohalma' },
  { label: 'Boboiești', value: 'Boboiești' },
  { label: 'Bobolia', value: 'Bobolia' },
  { label: 'Bobolice', value: 'Bobolice' },
  { label: 'Bobon', value: 'Bobon' },
  { label: 'Bobon', value: 'Bobon' },
  { label: 'Bobon', value: 'Bobon' },
  { label: 'Bobon', value: 'Bobon' },
  { label: 'Bobon', value: 'Bobon' },
  { label: 'Bobon Second', value: 'Bobon Second' },
  { label: 'Bobon Second', value: 'Bobon Second' },
  { label: 'Bobonan', value: 'Bobonan' },
  { label: 'Bobonan', value: 'Bobonan' },
  { label: 'Boborás', value: 'Boborás' },
  { label: 'Boboshevo', value: 'Boboshevo' },
  { label: 'Bobota', value: 'Bobota' },
  { label: 'Bobota', value: 'Bobota' },
  { label: 'Bobov Dol', value: 'Bobov Dol' },
  { label: 'Bobovo', value: 'Bobovo' },
  { label: 'Bobowa', value: 'Bobowa' },
  { label: 'Bobowo', value: 'Bobowo' },
  { label: 'Boboye Department', value: 'Boboye Department' },
  { label: 'Bobr', value: 'Bobr' },
  { label: 'Böbrach', value: 'Böbrach' },
  { label: 'Bobrov', value: 'Bobrov' },
  { label: 'Bobrovka', value: 'Bobrovka' },
  { label: 'Bobrovka', value: 'Bobrovka' },
  { label: 'Bobrovskiy', value: 'Bobrovskiy' },
  { label: 'Bobrovskiy Rayon', value: 'Bobrovskiy Rayon' },
  { label: 'Bobrovytsya', value: 'Bobrovytsya' },
  { label: 'Bobrowice', value: 'Bobrowice' },
  { label: 'Bobrowniki', value: 'Bobrowniki' },
  { label: 'Bobrowniki', value: 'Bobrowniki' },
  { label: 'Bobrowniki', value: 'Bobrowniki' },
  { label: 'Bobrowo', value: 'Bobrowo' },
  { label: 'Bobrynets', value: 'Bobrynets' },
  { label: 'Bobulești', value: 'Bobulești' },
  { label: 'Boca', value: 'Boca' },
  { label: 'Boca Chica', value: 'Boca Chica' },
  { label: 'Boca Chica', value: 'Boca Chica' },
  { label: 'Boca Da Mata', value: 'Boca Da Mata' },
  { label: 'Boca De Balsa', value: 'Boca De Balsa' },
  { label: 'Boca De Cupé', value: 'Boca De Cupé' },
  { label: 'Boca De Huérgano', value: 'Boca De Huérgano' },
  { label: 'Boca De Parita', value: 'Boca De Parita' },
  { label: 'Boca De Tomatlán', value: 'Boca De Tomatlán' },
  { label: 'Boca De Yuma', value: 'Boca De Yuma' },
  { label: 'Boca Del Mar', value: 'Boca Del Mar' },
  { label: 'Boca Del Monte', value: 'Boca Del Monte' },
  { label: 'Boca Del Monte', value: 'Boca Del Monte' },
  { label: 'Boca Del Rio', value: 'Boca Del Rio' },
  { label: 'Boca Do Acre', value: 'Boca Do Acre' },
  { label: 'Boca Pointe', value: 'Boca Pointe' },
  { label: 'Boca Raton', value: 'Boca Raton' },
  { label: 'Boca Suno', value: 'Boca Suno' },
  { label: 'Bocage', value: 'Bocage' },
  { label: 'Bocaina', value: 'Bocaina' },
  { label: 'Bocaina', value: 'Bocaina' },
  { label: 'Bocaina De Minas', value: 'Bocaina De Minas' },
  { label: 'Bocaina Do Sul', value: 'Bocaina Do Sul' },
  { label: 'Bocairent', value: 'Bocairent' },
  { label: 'Bocaiúva', value: 'Bocaiúva' },
  { label: 'Bocaiúva Do Sul', value: 'Bocaiúva Do Sul' },
  { label: 'Bocana', value: 'Bocana' },
  { label: 'Bocana De Paiwas', value: 'Bocana De Paiwas' },
  { label: 'Bocanda', value: 'Bocanda' },
  { label: 'Bocaneo (San Pedro)', value: 'Bocaneo (San Pedro)' },
  { label: 'Bočar', value: 'Bočar' },
  { label: 'Bocaranga', value: 'Bocaranga' },
  { label: 'Bocas', value: 'Bocas' },
  { label: 'Bocas Del Toro', value: 'Bocas Del Toro' },
  { label: 'Bocaue', value: 'Bocaue' },
  { label: 'Bocaue', value: 'Bocaue' },
  { label: 'Bocchigliero', value: 'Bocchigliero' },
  { label: 'Boccioleto', value: 'Boccioleto' },
  { label: 'Bocco', value: 'Bocco' },
  { label: 'Boceguillas', value: 'Boceguillas' },
  { label: 'Bocenago', value: 'Bocenago' },
  { label: 'Bochalema', value: 'Bochalema' },
  { label: 'Bochil', value: 'Bochil' },
  { label: 'Bochnia', value: 'Bochnia' },
  { label: 'Bochojbo Alto', value: 'Bochojbo Alto' },
  { label: 'Bocholt', value: 'Bocholt' },
  { label: 'Bocholt', value: 'Bocholt' },
  { label: 'Bocholtz', value: 'Bocholtz' },
  { label: 'Bochov', value: 'Bochov' },
  { label: 'Bochum', value: 'Bochum' },
  { label: 'Bochum', value: 'Bochum' },
  { label: 'Bochum-Hordel', value: 'Bochum-Hordel' },
  { label: 'Bocicoel', value: 'Bocicoel' },
  { label: 'Bocicoiu Mare', value: 'Bocicoiu Mare' },
  { label: 'Bocigas', value: 'Bocigas' },
  { label: 'Bockau', value: 'Bockau' },
  { label: 'Bockelwitz', value: 'Bockelwitz' },
  { label: 'Bockenau', value: 'Bockenau' },
  { label: 'Bockenem', value: 'Bockenem' },
  { label: 'Bockenheim', value: 'Bockenheim' },
  { label: 'Bockfließ', value: 'Bockfließ' },
  { label: 'Bockhorn', value: 'Bockhorn' },
  { label: 'Bockhorn', value: 'Bockhorn' },
  { label: 'Bockhorst', value: 'Bockhorst' },
  { label: 'Boćki', value: 'Boćki' },
  { label: 'Bocksdorf', value: 'Bocksdorf' },
  { label: 'Bocoio', value: 'Bocoio' },
  { label: 'Boconó', value: 'Boconó' },
  { label: 'Bocos', value: 'Bocos' },
  { label: 'Bocos', value: 'Bocos' },
  { label: 'Bocos De Duero', value: 'Bocos De Duero' },
  { label: 'Bocoyna', value: 'Bocoyna' },
  { label: 'Bőcs', value: 'Bőcs' },
  { label: 'Bocşa', value: 'Bocşa' },
  { label: 'Bocşa', value: 'Bocşa' },
  { label: 'Bocsig', value: 'Bocsig' },
  { label: 'Bod', value: 'Bod' },
  { label: 'Boda', value: 'Boda' },
  { label: 'Bodafors', value: 'Bodafors' },
  { label: 'Bodajk', value: 'Bodajk' },
  { label: 'Bodaybo', value: 'Bodaybo' },
  { label: 'Boddam', value: 'Boddam' },
  { label: 'Boddington', value: 'Boddington' },
  { label: 'Bode Saadu', value: 'Bode Saadu' },
  { label: 'Bodedern', value: 'Bodedern' },
  { label: 'Bodega', value: 'Bodega' },
  { label: 'Bodega', value: 'Bodega' },
  { label: 'Bodega Bay', value: 'Bodega Bay' },
  { label: 'Bodegraven', value: 'Bodegraven' },
  { label: 'Bodegraven-Reeuwijk', value: 'Bodegraven-Reeuwijk' },
  { label: 'Bodelshausen', value: 'Bodelshausen' },
  { label: 'Bodelwyddan', value: 'Bodelwyddan' },
  { label: 'Boden', value: 'Boden' },
  { label: 'Bodenfelde', value: 'Bodenfelde' },
  { label: 'Bodenheim', value: 'Bodenheim' },
  { label: 'Bodenkirchen', value: 'Bodenkirchen' },
  { label: 'Bodenmais', value: 'Bodenmais' },
  { label: 'Bodensdorf', value: 'Bodensdorf' },
  { label: 'Bodenwerder', value: 'Bodenwerder' },
  { label: 'Bodenwöhr', value: 'Bodenwöhr' },
  { label: 'Bodera La', value: 'Bodera La' },
  { label: 'Bodeşti', value: 'Bodeşti' },
  { label: 'Bodești', value: 'Bodești' },
  { label: 'Bodeștii De Jos', value: 'Bodeștii De Jos' },
  { label: 'Bodfish', value: 'Bodfish' },
  { label: 'Bodhan', value: 'Bodhan' },
  { label: 'Bodi', value: 'Bodi' },
  { label: 'Bodilis', value: 'Bodilis' },
  { label: 'Bodinayakkanur', value: 'Bodinayakkanur' },
  { label: 'Bodio', value: 'Bodio' },
  { label: 'Bodio', value: 'Bodio' },
  { label: 'Bodio Lomnago', value: 'Bodio Lomnago' },
  { label: 'Bodītī', value: 'Bodītī' },
  { label: 'Bodle Street', value: 'Bodle Street' },
  { label: 'Bodman-Ludwigshafen', value: 'Bodman-Ludwigshafen' },
  { label: 'Bodmin', value: 'Bodmin' },
  { label: 'Bodnegg', value: 'Bodnegg' },
  { label: 'Bodó', value: 'Bodó' },
  { label: 'Bodø', value: 'Bodø' },
  { label: 'Bodoc', value: 'Bodoc' },
  { label: 'Bodocó', value: 'Bodocó' },
  { label: 'Bodolz', value: 'Bodolz' },
  { label: 'Bodón El', value: 'Bodón El' },
  { label: 'Bodonal De La Sierra', value: 'Bodonal De La Sierra' },
  { label: 'Bodoquena', value: 'Bodoquena' },
  { label: 'Bodri', value: 'Bodri' },
  { label: 'Bodrum', value: 'Bodrum' },
  { label: 'Bodzanów', value: 'Bodzanów' },
  { label: 'Bodzentyn', value: 'Bodzentyn' },
  { label: 'Boé', value: 'Boé' },
  { label: 'Boechout', value: 'Boechout' },
  { label: 'Boecillo', value: 'Boecillo' },
  { label: 'Boedo', value: 'Boedo' },
  { label: 'Boeen-E-Sofla', value: 'Boeen-E-Sofla' },
  { label: 'Boëge', value: 'Boëge' },
  { label: 'Boeil-Bezing', value: 'Boeil-Bezing' },
  { label: 'Boekel', value: 'Boekel' },
  { label: 'Boekel', value: 'Boekel' },
  { label: 'Boende', value: 'Boende' },
  { label: 'Boën-Sur-Lignon', value: 'Boën-Sur-Lignon' },
  { label: 'Boerne', value: 'Boerne' },
  { label: 'Bœrsch', value: 'Bœrsch' },
  { label: 'Boeschepe', value: 'Boeschepe' },
  { label: 'Boesingheliede', value: 'Boesingheliede' },
  { label: 'Boeun-Gun', value: 'Boeun-Gun' },
  { label: 'Boevange-Sur-Attert', value: 'Boevange-Sur-Attert' },
  { label: 'Bofal', value: 'Bofal' },
  { label: 'Bofete', value: 'Bofete' },
  { label: 'Boffa', value: 'Boffa' },
  { label: 'Boffalora Dadda', value: 'Boffalora Dadda' },
  { label: 'Boffalora Sopra Ticino', value: 'Boffalora Sopra Ticino' },
  { label: 'Boffzen', value: 'Boffzen' },
  { label: 'Bog', value: 'Bog' },
  { label: 'Bog Walk', value: 'Bog Walk' },
  { label: 'Bogács', value: 'Bogács' },
  { label: 'Bogajo', value: 'Bogajo' },
  { label: 'Bogale', value: 'Bogale' },
  { label: 'Bogalusa', value: 'Bogalusa' },
  { label: 'Bogan', value: 'Bogan' },
  { label: 'Boganangone', value: 'Boganangone' },
  { label: 'Bogandé', value: 'Bogandé' },
  { label: 'Bogandinskiy', value: 'Bogandinskiy' },
  { label: 'Bogangar', value: 'Bogangar' },
  { label: 'Bogarra', value: 'Bogarra' },
  { label: 'Bogart', value: 'Bogart' },
  { label: 'Bogashevo', value: 'Bogashevo' },
  { label: 'Bogata', value: 'Bogata' },
  { label: 'Bogata', value: 'Bogata' },
  { label: 'Bogata', value: 'Bogata' },
  { label: 'Bogaţi', value: 'Bogaţi' },
  { label: 'Bogatić', value: 'Bogatić' },
  { label: 'Bogatoye', value: 'Bogatoye' },
  { label: 'Bogatoye', value: 'Bogatoye' },
  { label: 'Bogatynia', value: 'Bogatynia' },
  { label: 'Bogatyr’', value: 'Bogatyr’' },
  { label: 'Bogatyye Saby', value: 'Bogatyye Saby' },
  { label: 'Boğazkale', value: 'Boğazkale' },
  { label: 'Boğazlıyan', value: 'Boğazlıyan' },
  { label: 'Bogda', value: 'Bogda' },
  { label: 'Bogdan Vodă', value: 'Bogdan Vodă' },
  { label: 'Bogdana', value: 'Bogdana' },
  { label: 'Bogdana', value: 'Bogdana' },
  { label: 'Bogdanci', value: 'Bogdanci' },
  { label: 'Bogdand', value: 'Bogdand' },
  { label: 'Bogdăneşti', value: 'Bogdăneşti' },
  { label: 'Bogdăneşti', value: 'Bogdăneşti' },
  { label: 'Bogdăneşti', value: 'Bogdăneşti' },
  { label: 'Bogdănești', value: 'Bogdănești' },
  { label: 'Bogdaniec', value: 'Bogdaniec' },
  { label: 'Bogdăniţa', value: 'Bogdăniţa' },
  { label: 'Bogdanovci', value: 'Bogdanovci' },
  { label: 'Bogdanovich', value: 'Bogdanovich' },
  { label: 'Bogei', value: 'Bogei' },
  { label: 'Bogen', value: 'Bogen' },
  { label: 'Bogen', value: 'Bogen' },
  { label: 'Bogenhausen', value: 'Bogenhausen' },
  { label: 'Bogense', value: 'Bogense' },
  { label: 'Boggabri', value: 'Boggabri' },
  { label: 'Bogheşti', value: 'Bogheşti' },
  { label: 'Boghicea', value: 'Boghicea' },
  { label: 'Boghiș', value: 'Boghiș' },
  { label: 'Boghni', value: 'Boghni' },
  { label: 'Boghot Tumani', value: 'Boghot Tumani' },
  { label: 'Bogia', value: 'Bogia' },
  { label: 'Bogliasco', value: 'Bogliasco' },
  { label: 'Bognor Regis', value: 'Bognor Regis' },
  { label: 'Bogo', value: 'Bogo' },
  { label: 'Bogo', value: 'Bogo' },
  { label: 'Bogogno', value: 'Bogogno' },
  { label: 'Bogojevo', value: 'Bogojevo' },
  { label: 'Bogolyubovo', value: 'Bogolyubovo' },
  { label: 'Bogomila', value: 'Bogomila' },
  { label: 'Bogor', value: 'Bogor' },
  { label: 'Bogoria', value: 'Bogoria' },
  { label: 'Bogoroditsk', value: 'Bogoroditsk' },
  { label: 'Bogorodsk', value: 'Bogorodsk' },
  { label: 'Bogorodskiy Rayon', value: 'Bogorodskiy Rayon' },
  { label: 'Bogorodskoye', value: 'Bogorodskoye' },
  { label: 'Bogorodskoye', value: 'Bogorodskoye' },
  { label: 'Bogorodskoye', value: 'Bogorodskoye' },
  { label: 'Bogorodskoye', value: 'Bogorodskoye' },
  { label: 'Bogorodskoye', value: 'Bogorodskoye' },
  { label: 'Bogosavac', value: 'Bogosavac' },
  { label: 'Bogoslovka', value: 'Bogoslovka' },
  { label: 'Bogota', value: 'Bogota' },
  { label: 'Bogotá D.C.', value: 'Bogotá D.C.' },
  { label: 'Bogotol', value: 'Bogotol' },
  { label: 'Bogotol’Skiy Rayon', value: 'Bogotol’Skiy Rayon' },
  { label: 'Bogovarovo', value: 'Bogovarovo' },
  { label: 'Bogovinje', value: 'Bogovinje' },
  { label: 'Bogra ', value: 'Bogra ' },
  { label: 'Bograd', value: 'Bograd' },
  { label: 'Bogradskiy Rayon', value: 'Bogradskiy Rayon' },
  { label: 'Bogtong', value: 'Bogtong' },
  { label: 'Bogtong', value: 'Bogtong' },
  { label: 'Boguchanskiy Rayon', value: 'Boguchanskiy Rayon' },
  { label: 'Boguchany', value: 'Boguchany' },
  { label: 'Boguchar', value: 'Boguchar' },
  { label: 'Boguchwała', value: 'Boguchwała' },
  { label: 'Bogue', value: 'Bogue' },
  { label: 'Bogue', value: 'Bogue' },
  { label: 'Boguis', value: 'Boguis' },
  { label: 'Boguis/Desa Blond', value: 'Boguis/Desa Blond' },
  { label: 'Boguszów-Gorce', value: 'Boguszów-Gorce' },
  { label: 'Boguty-Pianki', value: 'Boguty-Pianki' },
  { label: 'Bogwalk', value: 'Bogwalk' },
  { label: 'Bogyiszló', value: 'Bogyiszló' },
  { label: 'Bogza', value: 'Bogza' },
  { label: 'Bohain-En-Vermandois', value: 'Bohain-En-Vermandois' },
  { label: 'Bohars', value: 'Bohars' },
  { label: 'Bohdalov', value: 'Bohdalov' },
  { label: 'Bohdan', value: 'Bohdan' },
  { label: 'Bohechío', value: 'Bohechío' },
  { label: 'Böheimkirchen', value: 'Böheimkirchen' },
  { label: 'Bohemia', value: 'Bohemia' },
  { label: 'Bohemia', value: 'Bohemia' },
  { label: 'Bohicon', value: 'Bohicon' },
  { label: 'Bohinjska Bistrica', value: 'Bohinjska Bistrica' },
  { label: 'Bohle Plains', value: 'Bohle Plains' },
  { label: 'Böhlen', value: 'Böhlen' },
  { label: 'Böhl-Iggelheim', value: 'Böhl-Iggelheim' },
  { label: 'Böhmenkirch', value: 'Böhmenkirch' },
  { label: 'Bohmte', value: 'Bohmte' },
  { label: 'Bohners Lake', value: 'Bohners Lake' },
  { label: 'Bohnsdorf', value: 'Bohnsdorf' },
  { label: 'Bohodón El', value: 'Bohodón El' },
  { label: 'Bohodukhiv', value: 'Bohodukhiv' },
  { label: 'Bohol', value: 'Bohol' },
  { label: 'Bohonal De Ibor', value: 'Bohonal De Ibor' },
  { label: 'Böhönye', value: 'Böhönye' },
  { label: 'Bohorodchans’Kyy Rayon', value: 'Bohorodchans’Kyy Rayon' },
  { label: 'Bohorodchany', value: 'Bohorodchany' },
  { label: 'Bohotin', value: 'Bohotin' },
  { label: 'Bohoyo', value: 'Bohoyo' },
  { label: 'Bohumín', value: 'Bohumín' },
  { label: 'Bohuňovice', value: 'Bohuňovice' },
  { label: 'Bohuslav', value: 'Bohuslav' },
  { label: 'Bohuslavice', value: 'Bohuslavice' },
  { label: 'Bohušovice Nad Ohří', value: 'Bohušovice Nad Ohří' },
  { label: 'Bohutín', value: 'Bohutín' },
  { label: 'Boi', value: 'Boi' },
  { label: 'Boian', value: 'Boian' },
  { label: 'Boianu Mare', value: 'Boianu Mare' },
  { label: 'Boiereni', value: 'Boiereni' },
  { label: 'Boigny-Sur-Bionne', value: 'Boigny-Sur-Bionne' },
  { label: 'Boiling Spring Lakes', value: 'Boiling Spring Lakes' },
  { label: 'Boiling Springs', value: 'Boiling Springs' },
  { label: 'Boiling Springs', value: 'Boiling Springs' },
  { label: 'Boiling Springs', value: 'Boiling Springs' },
  { label: 'Boimorto', value: 'Boimorto' },
  { label: 'Boinești', value: 'Boinești' },
  { label: 'Boiro', value: 'Boiro' },
  { label: 'Bois Catchet', value: 'Bois Catchet' },
  { label: 'Bois Cheri', value: 'Bois Cheri' },
  { label: 'Bois Des Amourettes', value: 'Bois Des Amourettes' },
  { label: 'Bois Dinde', value: 'Bois Dinde' },
  { label: 'Bois Dinde', value: 'Bois Dinde' },
  { label: 'Bois Dinde', value: 'Bois Dinde' },
  { label: 'Bois Dorange', value: 'Bois Dorange' },
  { label: 'Bois Dornange/Trouya', value: 'Bois Dornange/Trouya' },
  { label: 'Bois Joli', value: 'Bois Joli' },
  { label: 'Bois Patat', value: 'Bois Patat' },
  { label: 'Boisar', value: 'Boisar' },
  { label: 'Boisbriand', value: 'Boisbriand' },
  { label: 'Bois-Colombes', value: 'Bois-Colombes' },
  { label: 'Boiscommun', value: 'Boiscommun' },
  { label: 'Bois-D’Amont', value: 'Bois-D’Amont' },
  { label: 'Bois-D’Arcy', value: 'Bois-D’Arcy' },
  { label: 'Bois-De-Cené', value: 'Bois-De-Cené' },
  { label: 'Bois-Des-Filion', value: 'Bois-Des-Filion' },
  { label: 'Boise', value: 'Boise' },
  { label: 'Boise City', value: 'Boise City' },
  { label: 'Boise County', value: 'Boise County' },
  { label: 'Boisgervilly', value: 'Boisgervilly' },
  { label: 'Bois-Grenier', value: 'Bois-Grenier' },
  { label: 'Bois-Guillaume', value: 'Bois-Guillaume' },
  { label: 'Bois-Le-Roi', value: 'Bois-Le-Roi' },
  { label: 'Boismé', value: 'Boismé' },
  { label: 'Boişoara', value: 'Boişoara' },
  { label: 'Boissano', value: 'Boissano' },
  { label: 'Boisseron', value: 'Boisseron' },
  { label: 'Boisset-Et-Gaujac', value: 'Boisset-Et-Gaujac' },
  { label: 'Boisseuil', value: 'Boisseuil' },
  { label: 'Boissevain', value: 'Boissevain' },
  { label: 'Boissise-Le-Roi', value: 'Boissise-Le-Roi' },
  { label: 'Boissy-Le-Châtel', value: 'Boissy-Le-Châtel' },
  { label: 'Boissy-Le-Cutté', value: 'Boissy-Le-Cutté' },
  { label: 'Boissy-Saint-Léger', value: 'Boissy-Saint-Léger' },
  { label: 'Boissy-Sous-Saint-Yon', value: 'Boissy-Sous-Saint-Yon' },
  { label: 'Boița', value: 'Boița' },
  { label: 'Boituva', value: 'Boituva' },
  { label: 'Boiu', value: 'Boiu' },
  { label: 'Boiu Mare', value: 'Boiu Mare' },
  { label: 'Boizenburg', value: 'Boizenburg' },
  { label: 'Bojacá', value: 'Bojacá' },
  { label: 'Bojadła', value: 'Bojadła' },
  { label: 'Bojanala Platinum District Municipality', value: 'Bojanala Platinum District Municipality' },
  { label: 'Bojane', value: 'Bojane' },
  { label: 'Bojano', value: 'Bojano' },
  { label: 'Bojano', value: 'Bojano' },
  { label: 'Bojanów', value: 'Bojanów' },
  { label: 'Bojanowo', value: 'Bojanowo' },
  { label: 'Bojanowo Stare', value: 'Bojanowo Stare' },
  { label: 'Bojayá', value: 'Bojayá' },
  { label: 'Bojia', value: 'Bojia' },
  { label: 'Bojkovice', value: 'Bojkovice' },
  { label: 'Bojnice', value: 'Bojnice' },
  { label: 'Bojnurd', value: 'Bojnurd' },
  { label: 'Bojonegoro', value: 'Bojonegoro' },
  { label: 'Bojon-Lova', value: 'Bojon-Lova' },
  { label: 'Bojszowy', value: 'Bojszowy' },
  { label: 'Bojszowy Nowe', value: 'Bojszowy Nowe' },
  { label: 'Boju', value: 'Boju' },
  { label: 'Boka', value: 'Boka' },
  { label: 'Bokaa', value: 'Bokaa' },
  { label: 'Bokajan', value: 'Bokajan' },
  { label: 'Bokakhat', value: 'Bokakhat' },
  { label: 'Bokani', value: 'Bokani' },
  { label: 'Bokarina', value: 'Bokarina' },
  { label: 'Bokaro', value: 'Bokaro' },
  { label: 'Boké', value: 'Boké' },
  { label: 'Boke Prefecture', value: 'Boke Prefecture' },
  { label: 'Bokeelia', value: 'Bokeelia' },
  { label: 'Bokel', value: 'Bokel' },
  { label: 'Bokhan', value: 'Bokhan' },
  { label: 'Bokhanskiy Rayon', value: 'Bokhanskiy Rayon' },
  { label: 'Bokholt-Hanredder', value: 'Bokholt-Hanredder' },
  { label: 'Bokino', value: 'Bokino' },
  { label: 'Bokn', value: 'Bokn' },
  { label: 'Bokoba', value: 'Bokoba' },
  { label: 'Bokod', value: 'Bokod' },
  { label: 'Bokod', value: 'Bokod' },
  { label: 'Bököny', value: 'Bököny' },
  { label: 'Bokoro', value: 'Bokoro' },
  { label: 'Bokovskaya', value: 'Bokovskaya' },
  { label: 'Boksburg', value: 'Boksburg' },
  { label: 'Boksitogorsk', value: 'Boksitogorsk' },
  { label: 'Boksitogorskiy Rayon', value: 'Boksitogorskiy Rayon' },
  { label: 'Bol', value: 'Bol' },
  { label: 'Bol', value: 'Bol' },
  { label: 'Bol’Shaya Izhora', value: 'Bol’Shaya Izhora' },
  { label: 'Bol’Shaya Rechka', value: 'Bol’Shaya Rechka' },
  { label: 'Bol’Shaya Setun’', value: 'Bol’Shaya Setun’' },
  { label: 'Bol’Shaya Tura', value: 'Bol’Shaya Tura' },
  { label: 'Bol’Shebereznikovskiy Rayon', value: 'Bol’Shebereznikovskiy Rayon' },
  { label: 'Bol’Sheboldinskiy Rayon', value: 'Bol’Sheboldinskiy Rayon' },
  { label: 'Bol’Shemurtinskiy Rayon', value: 'Bol’Shemurtinskiy Rayon' },
  { label: 'Bol’Sherech’Ye', value: 'Bol’Sherech’Ye' },
  { label: 'Bol’Shesel’Skiy Rayon', value: 'Bol’Shesel’Skiy Rayon' },
  { label: 'Bol’Sheuluyskiy Rayon', value: 'Bol’Sheuluyskiy Rayon' },
  { label: 'Bol’Shoy Kamen’', value: 'Bol’Shoy Kamen’' },
  { label: 'Bol’Shoy Karay', value: 'Bol’Shoy Karay' },
  { label: 'Bol’Shoy Khomutets', value: 'Bol’Shoy Khomutets' },
  { label: 'Bol’Shoy Kunaley', value: 'Bol’Shoy Kunaley' },
  { label: 'Bol’Shoye Boldino', value: 'Bol’Shoye Boldino' },
  { label: 'Bol’Shoye Gryzlovo', value: 'Bol’Shoye Gryzlovo' },
  { label: 'Bol’Shoye Kozino', value: 'Bol’Shoye Kozino' },
  { label: 'Bol’Shoye Murashkino', value: 'Bol’Shoye Murashkino' },
  { label: 'Bol’Shoye Selo', value: 'Bol’Shoye Selo' },
  { label: 'Bol’Shoye Skuratovo', value: 'Bol’Shoye Skuratovo' },
  { label: 'Bola A', value: 'Bola A' },
  { label: 'Bolama', value: 'Bolama' },
  { label: 'Bolanden', value: 'Bolanden' },
  { label: 'Bolands', value: 'Bolands' },
  { label: 'Bolanikhodan', value: 'Bolanikhodan' },
  { label: 'Bolano', value: 'Bolano' },
  { label: 'Bolanon', value: 'Bolanon' },
  { label: 'Bolaños De Calatrava', value: 'Bolaños De Calatrava' },
  { label: 'Bolaños De Campos', value: 'Bolaños De Campos' },
  { label: 'Bolaños Municipality', value: 'Bolaños Municipality' },
  { label: 'Bolaoit', value: 'Bolaoit' },
  { label: 'Bolaoit', value: 'Bolaoit' },
  { label: 'Bolătău', value: 'Bolătău' },
  { label: 'Bolatice', value: 'Bolatice' },
  { label: 'Bolbaite', value: 'Bolbaite' },
  { label: 'Bolbanabad ', value: 'Bolbanabad ' },
  { label: 'Bolbec', value: 'Bolbec' },
  { label: 'Bolbeno', value: 'Bolbeno' },
  { label: 'Bolboc', value: 'Bolboc' },
  { label: 'Bolboşi', value: 'Bolboşi' },
  { label: 'Bölcske', value: 'Bölcske' },
  { label: 'Boldaji', value: 'Boldaji' },
  { label: 'Bolderaja', value: 'Bolderaja' },
  { label: 'Boldeşti', value: 'Boldeşti' },
  { label: 'Boldeşti-Scăeni', value: 'Boldeşti-Scăeni' },
  { label: 'Boldog', value: 'Boldog' },
  { label: 'Boldu', value: 'Boldu' },
  { label: 'Boldumsaz', value: 'Boldumsaz' },
  { label: 'Boldur', value: 'Boldur' },
  { label: 'Boldva', value: 'Boldva' },
  { label: 'Bole', value: 'Bole' },
  { label: 'Bolechowice', value: 'Bolechowice' },
  { label: 'Bolęcin', value: 'Bolęcin' },
  { label: 'Bolekhiv', value: 'Bolekhiv' },
  { label: 'Bolekhivs’Ka Mis’Krada', value: 'Bolekhivs’Ka Mis’Krada' },
  { label: 'Boles Acres', value: 'Boles Acres' },
  { label: 'Bolesław', value: 'Bolesław' },
  { label: 'Bolesławiec', value: 'Bolesławiec' },
  { label: 'Bolesławiec', value: 'Bolesławiec' },
  { label: 'Boleszkowice', value: 'Boleszkowice' },
  { label: 'Boley', value: 'Boley' },
  { label: 'Bolgar', value: 'Bolgar' },
  { label: 'Bolgare', value: 'Bolgare' },
  { label: 'Bolgatanga', value: 'Bolgatanga' },
  { label: 'Bolheim', value: 'Bolheim' },
  { label: 'Bolhrad', value: 'Bolhrad' },
  { label: 'Boli', value: 'Boli' },
  { label: 'Boliden', value: 'Boliden' },
  { label: 'Bolila', value: 'Bolila' },
  { label: 'Bolila', value: 'Bolila' },
  { label: 'Bolila', value: 'Bolila' },
  { label: 'Bolilao', value: 'Bolilao' },
  { label: 'Bolimów', value: 'Bolimów' },
  { label: 'Bolinao', value: 'Bolinao' },
  { label: 'Bolinas', value: 'Bolinas' },
  { label: 'Bolindale', value: 'Bolindale' },
  { label: 'Boliney', value: 'Boliney' },
  { label: 'Boliney', value: 'Boliney' },
  { label: 'Boling', value: 'Boling' },
  { label: 'Bolingbrook', value: 'Bolingbrook' },
  { label: 'Bolingit', value: 'Bolingit' },
  { label: 'Bolingit', value: 'Bolingit' },
  { label: 'Bolintin Deal', value: 'Bolintin Deal' },
  { label: 'Bolintin Vale', value: 'Bolintin Vale' },
  { label: 'Bolintineni', value: 'Bolintineni' },
  { label: 'Boliqueime', value: 'Boliqueime' },
  { label: 'Bolisong', value: 'Bolisong' },
  { label: 'Bolitoc', value: 'Bolitoc' },
  { label: 'Bolitoc', value: 'Bolitoc' },
  { label: 'Bolivar', value: 'Bolivar' },
  { label: 'Bolivar', value: 'Bolivar' },
  { label: 'Bolivar', value: 'Bolivar' },
  { label: 'Bolivar', value: 'Bolivar' },
  { label: 'Bolivar', value: 'Bolivar' },
  { label: 'Bolívar', value: 'Bolívar' },
  { label: 'Bolívar', value: 'Bolívar' },
  { label: 'Bolívar', value: 'Bolívar' },
  { label: 'Bolivar County', value: 'Bolivar County' },
  { label: 'Bolivar Peninsula', value: 'Bolivar Peninsula' },
  { label: 'Bolivia', value: 'Bolivia' },
  { label: 'Boljevci', value: 'Boljevci' },
  { label: 'Boljoon', value: 'Boljoon' },
  { label: 'Bolkhov', value: 'Bolkhov' },
  { label: 'Bolkhovskiy Rayon', value: 'Bolkhovskiy Rayon' },
  { label: 'Bolków', value: 'Bolków' },
  { label: 'Boll', value: 'Boll' },
  { label: 'Bolladello-Peveranza', value: 'Bolladello-Peveranza' },
  { label: 'Bollate', value: 'Bollate' },
  { label: 'Bollebygd', value: 'Bollebygd' },
  { label: 'Bollendorf', value: 'Bollendorf' },
  { label: 'Bollène', value: 'Bollène' },
  { label: 'Bollengo', value: 'Bollengo' },
  { label: 'Bollezeele', value: 'Bollezeele' },
  { label: 'Bolligen', value: 'Bolligen' },
  { label: 'Bollinger County', value: 'Bollinger County' },
  { label: 'Bollingstedt', value: 'Bollingstedt' },
  { label: 'Bollington', value: 'Bollington' },
  { label: 'Bollmora', value: 'Bollmora' },
  { label: 'Bollnäs', value: 'Bollnäs' },
  { label: 'Bollschweil', value: 'Bollschweil' },
  { label: 'Bollstabruk', value: 'Bollstabruk' },
  { label: 'Bollullos De La Mitación', value: 'Bollullos De La Mitación' },
  { label: 'Bollullos Par Del Condado', value: 'Bollullos Par Del Condado' },
  { label: 'Bollwiller', value: 'Bollwiller' },
  { label: 'Bolnes', value: 'Bolnes' },
  { label: 'Bolnisi', value: 'Bolnisi' },
  { label: 'Bolnisis Munitsip’Alit’Et’I', value: 'Bolnisis Munitsip’Alit’Et’I' },
  { label: 'Bolo', value: 'Bolo' },
  { label: 'Bolo', value: 'Bolo' },
  { label: 'Bolo', value: 'Bolo' },
  { label: 'Bolo', value: 'Bolo' },
  { label: 'Bolo', value: 'Bolo' },
  { label: 'Bolo Bolo', value: 'Bolo Bolo' },
  { label: 'Bolo O', value: 'Bolo O' },
  { label: 'Bolobo', value: 'Bolobo' },
  { label: 'Bologaia', value: 'Bologaia' },
  { label: 'Bologna', value: 'Bologna' },
  { label: 'Bolognano', value: 'Bolognano' },
  { label: 'Bolognano-Vignole', value: 'Bolognano-Vignole' },
  { label: 'Bologne', value: 'Bologne' },
  { label: 'Bolognetta', value: 'Bolognetta' },
  { label: 'Bolognola', value: 'Bolognola' },
  { label: 'Bologovskiy Rayon', value: 'Bologovskiy Rayon' },
  { label: 'Bologoye', value: 'Bologoye' },
  { label: 'Bolokhovo', value: 'Bolokhovo' },
  { label: 'Bolón', value: 'Bolón' },
  { label: 'Bolonchén De Rejón', value: 'Bolonchén De Rejón' },
  { label: 'Bolondrón', value: 'Bolondrón' },
  { label: 'Bolong', value: 'Bolong' },
  { label: 'Bolong', value: 'Bolong' },
  { label: 'Bolotana', value: 'Bolotana' },
  { label: 'Boloteşti', value: 'Boloteşti' },
  { label: 'Bolotnoye', value: 'Bolotnoye' },
  { label: 'Bolovăniș', value: 'Bolovăniș' },
  { label: 'Bolpur', value: 'Bolpur' },
  { label: 'Bolsena', value: 'Bolsena' },
  { label: 'Bolshevo', value: 'Bolshevo' },
  { label: 'Bolshiye Vyazëmy', value: 'Bolshiye Vyazëmy' },
  { label: 'Bolshoe Isakovo', value: 'Bolshoe Isakovo' },
  { label: 'Bolsover', value: 'Bolsover' },
  { label: 'Bolsterlang', value: 'Bolsterlang' },
  { label: 'Bolsward', value: 'Bolsward' },
  { label: 'Bolszewo', value: 'Bolszewo' },
  { label: 'Boltaña', value: 'Boltaña' },
  { label: 'Boltiere', value: 'Boltiere' },
  { label: 'Boltigen', value: 'Boltigen' },
  { label: 'Bolton', value: 'Bolton' },
  { label: 'Bolton', value: 'Bolton' },
  { label: 'Bolton Le Sands', value: 'Bolton Le Sands' },
  { label: 'Bolton Point', value: 'Bolton Point' },
  { label: 'Bolton Upon Dearne', value: 'Bolton Upon Dearne' },
  { label: 'Boluk', value: 'Boluk' },
  { label: 'Bolulla', value: 'Bolulla' },
  { label: 'Bolvadin', value: 'Bolvadin' },
  { label: 'Bolvașnița', value: 'Bolvașnița' },
  { label: 'Bolvir', value: 'Bolvir' },
  { label: 'Bolwarra Heights', value: 'Bolwarra Heights' },
  { label: 'Bóly', value: 'Bóly' },
  { label: 'Bolyarovo', value: 'Bolyarovo' },
  { label: 'Bólyi Járás', value: 'Bólyi Járás' },
  { label: 'Bolzano', value: 'Bolzano' },
  { label: 'Bolzano Novarese', value: 'Bolzano Novarese' },
  { label: 'Bolzano Vicentino', value: 'Bolzano Vicentino' },
  { label: 'Bom Conselho', value: 'Bom Conselho' },
  { label: 'Bom Despacho', value: 'Bom Despacho' },
  { label: 'Bom Jardim', value: 'Bom Jardim' },
  { label: 'Bom Jardim', value: 'Bom Jardim' },
  { label: 'Bom Jardim', value: 'Bom Jardim' },
  { label: 'Bom Jardim Da Serra', value: 'Bom Jardim Da Serra' },
  { label: 'Bom Jardim De Goiás', value: 'Bom Jardim De Goiás' },
  { label: 'Bom Jardim De Minas', value: 'Bom Jardim De Minas' },
  { label: 'Bom Jesus', value: 'Bom Jesus' },
  { label: 'Bom Jesus', value: 'Bom Jesus' },
  { label: 'Bom Jesus', value: 'Bom Jesus' },
  { label: 'Bom Jesus', value: 'Bom Jesus' },
  { label: 'Bom Jesus', value: 'Bom Jesus' },
  { label: 'Bom Jesus Da Lapa', value: 'Bom Jesus Da Lapa' },
  { label: 'Bom Jesus Da Penha', value: 'Bom Jesus Da Penha' },
  { label: 'Bom Jesus Da Serra', value: 'Bom Jesus Da Serra' },
  { label: 'Bom Jesus Das Selvas', value: 'Bom Jesus Das Selvas' },
  { label: 'Bom Jesus De Goiás', value: 'Bom Jesus De Goiás' },
  { label: 'Bom Jesus Do Amparo', value: 'Bom Jesus Do Amparo' },
  { label: 'Bom Jesus Do Araguaia', value: 'Bom Jesus Do Araguaia' },
  { label: 'Bom Jesus Do Galho', value: 'Bom Jesus Do Galho' },
  { label: 'Bom Jesus Do Itabapoana', value: 'Bom Jesus Do Itabapoana' },
  { label: 'Bom Jesus Do Norte', value: 'Bom Jesus Do Norte' },
  { label: 'Bom Jesus Do Oeste', value: 'Bom Jesus Do Oeste' },
  { label: 'Bom Jesus Do Sul', value: 'Bom Jesus Do Sul' },
  { label: 'Bom Jesus Do Tocantins', value: 'Bom Jesus Do Tocantins' },
  { label: 'Bom Jesus Do Tocantins', value: 'Bom Jesus Do Tocantins' },
  { label: 'Bom Jesus Dos Perdões', value: 'Bom Jesus Dos Perdões' },
  { label: 'Bom Lugar', value: 'Bom Lugar' },
  { label: 'Bom Princípio', value: 'Bom Princípio' },
  { label: 'Bom Princípio Do Piauí', value: 'Bom Princípio Do Piauí' },
  { label: 'Bom Progresso', value: 'Bom Progresso' },
  { label: 'Bom Repouso', value: 'Bom Repouso' },
  { label: 'Bom Retiro', value: 'Bom Retiro' },
  { label: 'Bom Retiro Do Sul', value: 'Bom Retiro Do Sul' },
  { label: 'Bom Sucesso', value: 'Bom Sucesso' },
  { label: 'Bom Sucesso', value: 'Bom Sucesso' },
  { label: 'Bom Sucesso', value: 'Bom Sucesso' },
  { label: 'Bom Sucesso De Itararé', value: 'Bom Sucesso De Itararé' },
  { label: 'Bom Sucesso Do Sul', value: 'Bom Sucesso Do Sul' },
  { label: 'Boma', value: 'Boma' },
  { label: 'Bomaderry', value: 'Bomaderry' },
  { label: 'Bomadi', value: 'Bomadi' },
  { label: 'Bomanxotha', value: 'Bomanxotha' },
  { label: 'Bomarzo', value: 'Bomarzo' },
  { label: 'Bomba', value: 'Bomba' },
  { label: 'Bombala', value: 'Bombala' },
  { label: 'Bombali District', value: 'Bombali District' },
  { label: 'Bombardopolis', value: 'Bombardopolis' },
  { label: 'Bombarral', value: 'Bombarral' },
  { label: 'Bombay', value: 'Bombay' },
  { label: 'Bombinhas', value: 'Bombinhas' },
  { label: 'Bombon', value: 'Bombon' },
  { label: 'Bomdila', value: 'Bomdila' },
  { label: 'Bomi', value: 'Bomi' },
  { label: 'Bomintzha', value: 'Bomintzha' },
  { label: 'Bomlitz', value: 'Bomlitz' },
  { label: 'Bømlo', value: 'Bømlo' },
  { label: 'Bompas', value: 'Bompas' },
  { label: 'Bompensiere', value: 'Bompensiere' },
  { label: 'Bompietro', value: 'Bompietro' },
  { label: 'Bomporto', value: 'Bomporto' },
  { label: 'Bon Accord', value: 'Bon Accord' },
  { label: 'Bon Accueil', value: 'Bon Accueil' },
  { label: 'Bon Air', value: 'Bon Air' },
  { label: 'Bon Aqua Junction', value: 'Bon Aqua Junction' },
  { label: 'Bon Homme County', value: 'Bon Homme County' },
  { label: 'Bonab', value: 'Bonab' },
  { label: 'Bonabéri', value: 'Bonabéri' },
  { label: 'Bonadelle Ranchos-Madera Ranchos', value: 'Bonadelle Ranchos-Madera Ranchos' },
  { label: 'Bonaduz', value: 'Bonaduz' },
  { label: 'Bonansa', value: 'Bonansa' },
  { label: 'Bonanza', value: 'Bonanza' },
  { label: 'Bonanza', value: 'Bonanza' },
  { label: 'Bonao', value: 'Bonao' },
  { label: 'Boñar', value: 'Boñar' },
  { label: 'Bonarcado', value: 'Bonarcado' },
  { label: 'Bonares', value: 'Bonares' },
  { label: 'Bonassola', value: 'Bonassola' },
  { label: 'Bonastre', value: 'Bonastre' },
  { label: 'Bonate Sopra', value: 'Bonate Sopra' },
  { label: 'Bonate Sotto', value: 'Bonate Sotto' },
  { label: 'Bonaventure', value: 'Bonaventure' },
  { label: 'Bonavicina', value: 'Bonavicina' },
  { label: 'Bonavigo', value: 'Bonavigo' },
  { label: 'Bonavista', value: 'Bonavista' },
  { label: 'Bonawon', value: 'Bonawon' },
  { label: 'Bonbeach', value: 'Bonbeach' },
  { label: 'Bonbon', value: 'Bonbon' },
  { label: 'Bonbon', value: 'Bonbon' },
  { label: 'Bonchamp-Lès-Laval', value: 'Bonchamp-Lès-Laval' },
  { label: 'Boncourt', value: 'Boncourt' },
  { label: 'Bond County', value: 'Bond County' },
  { label: 'Bondarskiy Rayon', value: 'Bondarskiy Rayon' },
  { label: 'Bondeno', value: 'Bondeno' },
  { label: 'Bondeno', value: 'Bondeno' },
  { label: 'Bondi', value: 'Bondi' },
  { label: 'Bondi Beach', value: 'Bondi Beach' },
  { label: 'Bondi Junction', value: 'Bondi Junction' },
  { label: 'Bondione', value: 'Bondione' },
  { label: 'Bondo', value: 'Bondo' },
  { label: 'Bondo', value: 'Bondo' },
  { label: 'Bondo', value: 'Bondo' },
  { label: 'Bondojito', value: 'Bondojito' },
  { label: 'Bondone', value: 'Bondone' },
  { label: 'Bondorf', value: 'Bondorf' },
  { label: 'Bondoufle', value: 'Bondoufle' },
  { label: 'Bondoukou', value: 'Bondoukou' },
  { label: 'Bondowoso', value: 'Bondowoso' },
  { label: 'Bondsville', value: 'Bondsville' },
  { label: 'Bonduel', value: 'Bonduel' },
  { label: 'Bondues', value: 'Bondues' },
  { label: 'Bondurant', value: 'Bondurant' },
  { label: 'Bondy', value: 'Bondy' },
  { label: 'Bone South', value: 'Bone South' },
  { label: 'Bone South', value: 'Bone South' },
  { label: 'Bonea', value: 'Bonea' },
  { label: 'Bönebüttel', value: 'Bönebüttel' },
  { label: 'Bonefeld', value: 'Bonefeld' },
  { label: 'Bonefro', value: 'Bonefro' },
  { label: 'Bonemerse', value: 'Bonemerse' },
  { label: 'Bönen', value: 'Bönen' },
  { label: 'Bon-Encontre', value: 'Bon-Encontre' },
  { label: 'Bonete', value: 'Bonete' },
  { label: 'Bonferraro', value: 'Bonferraro' },
  { label: 'Bonfim', value: 'Bonfim' },
  { label: 'Bonfim', value: 'Bonfim' },
  { label: 'Bonfim Do Piauí', value: 'Bonfim Do Piauí' },
  { label: 'Bonfinópolis', value: 'Bonfinópolis' },
  { label: 'Bonfinópolis De Minas', value: 'Bonfinópolis De Minas' },
  { label: 'Bonga', value: 'Bonga' },
  { label: 'Bonga', value: 'Bonga' },
  { label: 'Bongabon', value: 'Bongabon' },
  { label: 'Bongabon', value: 'Bongabon' },
  { label: 'Bongaigaon', value: 'Bongaigaon' },
  { label: 'Bongalo', value: 'Bongalo' },
  { label: 'Boñgalon', value: 'Boñgalon' },
  { label: 'Boñgalon', value: 'Boñgalon' },
  { label: 'Bongandanga', value: 'Bongandanga' },
  { label: 'Bongao', value: 'Bongao' },
  { label: 'Bongao', value: 'Bongao' },
  { label: 'Bongaree', value: 'Bongaree' },
  { label: 'Bonghwa-Gun', value: 'Bonghwa-Gun' },
  { label: 'Bongor', value: 'Bongor' },
  { label: 'Bongouanou', value: 'Bongouanou' },
  { label: 'Bongued', value: 'Bongued' },
  { label: 'Bongued', value: 'Bongued' },
  { label: 'Bonham', value: 'Bonham' },
  { label: 'Bonheiden', value: 'Bonheiden' },
  { label: 'Bonhill', value: 'Bonhill' },
  { label: 'Boniches', value: 'Boniches' },
  { label: 'Boniewo', value: 'Boniewo' },
  { label: 'Bonifacio', value: 'Bonifacio' },
  { label: 'Bonifacio', value: 'Bonifacio' },
  { label: 'Bonifacio García', value: 'Bonifacio García' },
  { label: 'Bonifacio Moreno (El Aguaje)', value: 'Bonifacio Moreno (El Aguaje)' },
  { label: 'Bonifati', value: 'Bonifati' },
  { label: 'Bonifay', value: 'Bonifay' },
  { label: 'Bönigen', value: 'Bönigen' },
  { label: 'Bonilla De La Sierra', value: 'Bonilla De La Sierra' },
  { label: 'Bonillo El', value: 'Bonillo El' },
  { label: 'Boninal', value: 'Boninal' },
  { label: 'Boniswil', value: 'Boniswil' },
  { label: 'Bonita', value: 'Bonita' },
  { label: 'Bonita Springs', value: 'Bonita Springs' },
  { label: 'Bonito', value: 'Bonito' },
  { label: 'Bonito', value: 'Bonito' },
  { label: 'Bonito', value: 'Bonito' },
  { label: 'Bonito', value: 'Bonito' },
  { label: 'Bonito', value: 'Bonito' },
  { label: 'Bonito De Minas', value: 'Bonito De Minas' },
  { label: 'Bonito De Santa Fé', value: 'Bonito De Santa Fé' },
  { label: 'Bonito Oriental', value: 'Bonito Oriental' },
  { label: 'Bonjār', value: 'Bonjār' },
  { label: 'Bonn', value: 'Bonn' },
  { label: 'Bonnac-La-Côte', value: 'Bonnac-La-Côte' },
  { label: 'Bonnanaro', value: 'Bonnanaro' },
  { label: 'Bonnat', value: 'Bonnat' },
  { label: 'Bonndorf', value: 'Bonndorf' },
  { label: 'Bonne', value: 'Bonne' },
  { label: 'Bonne Terre', value: 'Bonne Terre' },
  { label: 'Bonneau Beach', value: 'Bonneau Beach' },
  { label: 'Bonneauville', value: 'Bonneauville' },
  { label: 'Bonnefamille', value: 'Bonnefamille' },
  { label: 'Bonnelles', value: 'Bonnelles' },
  { label: 'Bonnells Bay', value: 'Bonnells Bay' },
  { label: 'Bonnemain', value: 'Bonnemain' },
  { label: 'Bonner', value: 'Bonner' },
  { label: 'Bonner County', value: 'Bonner County' },
  { label: 'Bonner Springs', value: 'Bonner Springs' },
  { label: 'Bonners Ferry', value: 'Bonners Ferry' },
  { label: 'Bonner-West Riverside', value: 'Bonner-West Riverside' },
  { label: 'Bonnes', value: 'Bonnes' },
  { label: 'Bonnet Bay', value: 'Bonnet Bay' },
  { label: 'Bonnétable', value: 'Bonnétable' },
  { label: 'Bonneterre', value: 'Bonneterre' },
  { label: 'Bonneterre Gardens', value: 'Bonneterre Gardens' },
  { label: 'Bonneuil-Matours', value: 'Bonneuil-Matours' },
  { label: 'Bonneuil-Sur-Marne', value: 'Bonneuil-Sur-Marne' },
  { label: 'Bonneval', value: 'Bonneval' },
  { label: 'Bonneveine', value: 'Bonneveine' },
  { label: 'Bonneville', value: 'Bonneville' },
  { label: 'Bonneville County', value: 'Bonneville County' },
  { label: 'Bonney Lake', value: 'Bonney Lake' },
  { label: 'Bonnières-Sur-Seine', value: 'Bonnières-Sur-Seine' },
  { label: 'Bonnieux', value: 'Bonnieux' },
  { label: 'Bönnigheim', value: 'Bönnigheim' },
  { label: 'Bönningstedt', value: 'Bönningstedt' },
  { label: 'Bonny Doon', value: 'Bonny Doon' },
  { label: 'Bonny Hills', value: 'Bonny Hills' },
  { label: 'Bonnybridge', value: 'Bonnybridge' },
  { label: 'Bonnybrook', value: 'Bonnybrook' },
  { label: 'Bonnygate', value: 'Bonnygate' },
  { label: 'Bonnyrigg', value: 'Bonnyrigg' },
  { label: 'Bonnyrigg', value: 'Bonnyrigg' },
  { label: 'Bonnyrigg Heights', value: 'Bonnyrigg Heights' },
  { label: 'Bonny-Sur-Loire', value: 'Bonny-Sur-Loire' },
  { label: 'Bonnyville', value: 'Bonnyville' },
  { label: 'Bono', value: 'Bono' },
  { label: 'Bono', value: 'Bono' },
  { label: 'Bonogin', value: 'Bonogin' },
  { label: 'Bonópolis', value: 'Bonópolis' },
  { label: 'Bonorva', value: 'Bonorva' },
  { label: 'Bonoua', value: 'Bonoua' },
  { label: 'Bonpland', value: 'Bonpland' },
  { label: 'Bonpland', value: 'Bonpland' },
  { label: 'Bonrepòs I Mirambell', value: 'Bonrepòs I Mirambell' },
  { label: 'Bonriki Village', value: 'Bonriki Village' },
  { label: 'Bonsall', value: 'Bonsall' },
  { label: 'Bon-Secours', value: 'Bon-Secours' },
  { label: 'Bons-En-Chablais', value: 'Bons-En-Chablais' },
  { label: 'Bonson', value: 'Bonson' },
  { label: 'Bonstetten', value: 'Bonstetten' },
  { label: 'Bonstetten', value: 'Bonstetten' },
  { label: 'Bontăieni', value: 'Bontăieni' },
  { label: 'Bontang', value: 'Bontang' },
  { label: 'Bonthe', value: 'Bonthe' },
  { label: 'Bonthe District', value: 'Bonthe District' },
  { label: 'Bonțida', value: 'Bonțida' },
  { label: 'Bontoc', value: 'Bontoc' },
  { label: 'Bontoc', value: 'Bontoc' },
  { label: 'Bontoc', value: 'Bontoc' },
  { label: 'Bontoc', value: 'Bontoc' },
  { label: 'Bonvicino', value: 'Bonvicino' },
  { label: 'Bonville', value: 'Bonville' },
  { label: 'Bőny', value: 'Bőny' },
  { label: 'Bonyhád', value: 'Bonyhád' },
  { label: 'Bonyhádi Járás', value: 'Bonyhádi Járás' },
  { label: 'Bonython', value: 'Bonython' },
  { label: 'Boo', value: 'Boo' },
  { label: 'Bood', value: 'Bood' },
  { label: 'Boofzheim', value: 'Boofzheim' },
  { label: 'Booie', value: 'Booie' },
  { label: 'Booker', value: 'Booker' },
  { label: 'Booker Bay', value: 'Booker Bay' },
  { label: 'Boom', value: 'Boom' },
  { label: 'Boonah', value: 'Boonah' },
  { label: 'Boondall', value: 'Boondall' },
  { label: 'Boone', value: 'Boone' },
  { label: 'Boone', value: 'Boone' },
  { label: 'Boone County', value: 'Boone County' },
  { label: 'Boone County', value: 'Boone County' },
  { label: 'Boone County', value: 'Boone County' },
  { label: 'Boone County', value: 'Boone County' },
  { label: 'Boone County', value: 'Boone County' },
  { label: 'Boone County', value: 'Boone County' },
  { label: 'Boone County', value: 'Boone County' },
  { label: 'Boone County', value: 'Boone County' },
  { label: 'Booneville', value: 'Booneville' },
  { label: 'Booneville', value: 'Booneville' },
  { label: 'Booneville', value: 'Booneville' },
  { label: 'Boonsboro', value: 'Boonsboro' },
  { label: 'Boonton', value: 'Boonton' },
  { label: 'Boonville', value: 'Boonville' },
  { label: 'Boonville', value: 'Boonville' },
  { label: 'Boonville', value: 'Boonville' },
  { label: 'Boonville', value: 'Boonville' },
  { label: 'Boonville', value: 'Boonville' },
  { label: 'Booragoon', value: 'Booragoon' },
  { label: 'Booragul', value: 'Booragul' },
  { label: 'Booral', value: 'Booral' },
  { label: 'Boornbergum', value: 'Boornbergum' },
  { label: 'Boorowa', value: 'Boorowa' },
  { label: 'Boortmeerbeek', value: 'Boortmeerbeek' },
  { label: 'Boos', value: 'Boos' },
  { label: 'Boos', value: 'Boos' },
  { label: 'Boosbeck', value: 'Boosbeck' },
  { label: 'Boostedt', value: 'Boostedt' },
  { label: 'Boot', value: 'Boot' },
  { label: 'Booterstown', value: 'Booterstown' },
  { label: 'Boothbay', value: 'Boothbay' },
  { label: 'Boothbay Harbor', value: 'Boothbay Harbor' },
  { label: 'Boothwyn', value: 'Boothwyn' },
  { label: 'Bootle', value: 'Bootle' },
  { label: 'Booué', value: 'Booué' },
  { label: 'Booval', value: 'Booval' },
  { label: 'Bopfingen', value: 'Bopfingen' },
  { label: 'Bopolu', value: 'Bopolu' },
  { label: 'Boppard', value: 'Boppard' },
  { label: 'Boqueirão', value: 'Boqueirão' },
  { label: 'Boqueirão Do Leão', value: 'Boqueirão Do Leão' },
  { label: 'Boqueirão Do Piauí', value: 'Boqueirão Do Piauí' },
  { label: 'Boqueixón', value: 'Boqueixón' },
  { label: 'Boqueron', value: 'Boqueron' },
  { label: 'Boquerón', value: 'Boquerón' },
  { label: 'Boquerón', value: 'Boquerón' },
  { label: 'Boquerón Cabo Rojo', value: 'Boquerón Cabo Rojo' },
  { label: 'Boquete', value: 'Boquete' },
  { label: 'Boquiapa', value: 'Boquiapa' },
  { label: 'Boquilla De Babisas (La Boquilla De Conchos)', value: 'Boquilla De Babisas (La Boquilla De Conchos)' },
  { label: 'Boquilla De Las Perlas', value: 'Boquilla De Las Perlas' },
  { label: 'Boquillas', value: 'Boquillas' },
  { label: 'Boquim', value: 'Boquim' },
  { label: 'Boquiñeni', value: 'Boquiñeni' },
  { label: 'Boquira', value: 'Boquira' },
  { label: 'Bor', value: 'Bor' },
  { label: 'Bor', value: 'Bor' },
  { label: 'Bor', value: 'Bor' },
  { label: 'Bor', value: 'Bor' },
  { label: 'Bor', value: 'Bor' },
  { label: 'Bor', value: 'Bor' },
  { label: 'Bor Urban Okrug', value: 'Bor Urban Okrug' },
  { label: 'Borá', value: 'Borá' },
  { label: 'Bora Bassa', value: 'Bora Bassa' },
  { label: 'Boracéia', value: 'Boracéia' },
  { label: 'Boradigah', value: 'Boradigah' },
  { label: 'Borănești', value: 'Borănești' },
  { label: 'Boran-Sur-Oise', value: 'Boran-Sur-Oise' },
  { label: 'Borås', value: 'Borås' },
  { label: 'Borăscu', value: 'Borăscu' },
  { label: 'Borau', value: 'Borau' },
  { label: 'Borazjan', value: 'Borazjan' },
  { label: 'Borba', value: 'Borba' },
  { label: 'Borba', value: 'Borba' },
  { label: 'Borbon', value: 'Borbon' },
  { label: 'Borbon', value: 'Borbon' },
  { label: 'Borbon', value: 'Borbon' },
  { label: 'Borbona', value: 'Borbona' },
  { label: 'Borborema', value: 'Borborema' },
  { label: 'Borborema', value: 'Borborema' },
  { label: 'Borca', value: 'Borca' },
  { label: 'Borca', value: 'Borca' },
  { label: 'Borcea', value: 'Borcea' },
  { label: 'Borçka', value: 'Borçka' },
  { label: 'Borculo', value: 'Borculo' },
  { label: 'Borcut', value: 'Borcut' },
  { label: 'Bord', value: 'Bord' },
  { label: 'Bord Khun', value: 'Bord Khun' },
  { label: 'Borda Da Mata', value: 'Borda Da Mata' },
  { label: 'Bordalba', value: 'Bordalba' },
  { label: 'Bordano', value: 'Bordano' },
  { label: 'Bordány', value: 'Bordány' },
  { label: 'Bordeasca Veche', value: 'Bordeasca Veche' },
  { label: 'Bordeaux', value: 'Bordeaux' },
  { label: 'Bordei Verde', value: 'Bordei Verde' },
  { label: 'Bordelais', value: 'Bordelais' },
  { label: 'Bordelum', value: 'Bordelum' },
  { label: 'Borden County', value: 'Borden County' },
  { label: 'Bordenii Mari', value: 'Bordenii Mari' },
  { label: 'Bordentown', value: 'Bordentown' },
  { label: 'Bordertown', value: 'Bordertown' },
  { label: 'Bordes', value: 'Bordes' },
  { label: 'Bòrdes Es', value: 'Bòrdes Es' },
  { label: 'Bordesholm', value: 'Bordesholm' },
  { label: 'Bordeşti', value: 'Bordeşti' },
  { label: 'Bordighera', value: 'Bordighera' },
  { label: 'Bordils', value: 'Bordils' },
  { label: 'Bording Kirkeby', value: 'Bording Kirkeby' },
  { label: 'Bordj Bou Arreridj', value: 'Bordj Bou Arreridj' },
  { label: 'Bordj El Kiffan', value: 'Bordj El Kiffan' },
  { label: 'Bordj Ghdir', value: 'Bordj Ghdir' },
  { label: 'Bordj Zemoura', value: 'Bordj Zemoura' },
  { label: 'Bordo Blanco', value: 'Bordo Blanco' },
  { label: 'Bordolano', value: 'Bordolano' },
  { label: 'Bordon', value: 'Bordon' },
  { label: 'Bordón', value: 'Bordón' },
  { label: 'Bordoșiu', value: 'Bordoșiu' },
  { label: 'Bords', value: 'Bords' },
  { label: 'Borduşani', value: 'Borduşani' },
  { label: 'Bore', value: 'Bore' },
  { label: 'Borebi', value: 'Borebi' },
  { label: 'Boreham', value: 'Boreham' },
  { label: 'Borehamwood', value: 'Borehamwood' },
  { label: 'Borek', value: 'Borek' },
  { label: 'Borek', value: 'Borek' },
  { label: 'Borek Wielkopolski', value: 'Borek Wielkopolski' },
  { label: 'Borel', value: 'Borel' },
  { label: 'Borello', value: 'Borello' },
  { label: 'Borensberg', value: 'Borensberg' },
  { label: 'Bořetice', value: 'Bořetice' },
  { label: 'Boretto', value: 'Boretto' },
  { label: 'Borg El Arab', value: 'Borg El Arab' },
  { label: 'Borgagne', value: 'Borgagne' },
  { label: 'Borgaon Manju', value: 'Borgaon Manju' },
  { label: 'Borgarbyggð', value: 'Borgarbyggð' },
  { label: 'Borgarello', value: 'Borgarello' },
  { label: 'Borgarfjarðarhreppur', value: 'Borgarfjarðarhreppur' },
  { label: 'Borgarnes', value: 'Borgarnes' },
  { label: 'Borgaro Torinese', value: 'Borgaro Torinese' },
  { label: 'Borgata Marina', value: 'Borgata Marina' },
  { label: 'Borge El', value: 'Borge El' },
  { label: 'Borgele', value: 'Borgele' },
  { label: 'Borgentreich', value: 'Borgentreich' },
  { label: 'Borger', value: 'Borger' },
  { label: 'Borger', value: 'Borger' },
  { label: 'Börger', value: 'Börger' },
  { label: 'Börgerende-Rethwisch', value: 'Börgerende-Rethwisch' },
  { label: 'Borges Blanques Les', value: 'Borges Blanques Les' },
  { label: 'Borges Del Camp', value: 'Borges Del Camp' },
  { label: 'Borgetto', value: 'Borgetto' },
  { label: 'Borgfelde', value: 'Borgfelde' },
  { label: 'Borgharen', value: 'Borgharen' },
  { label: 'Borghetto', value: 'Borghetto' },
  { label: 'Borghetto Darroscia', value: 'Borghetto Darroscia' },
  { label: 'Borghetto Di Borbera', value: 'Borghetto Di Borbera' },
  { label: 'Borghetto Di Vara', value: 'Borghetto Di Vara' },
  { label: 'Borghetto Lodigiano', value: 'Borghetto Lodigiano' },
  { label: 'Borghetto Santo Spirito', value: 'Borghetto Santo Spirito' },
  { label: 'Borghetto-Melara', value: 'Borghetto-Melara' },
  { label: 'Borghi', value: 'Borghi' },
  { label: 'Borgholm', value: 'Borgholm' },
  { label: 'Borgholzhausen', value: 'Borgholzhausen' },
  { label: 'Borgia', value: 'Borgia' },
  { label: 'Borgiallo', value: 'Borgiallo' },
  { label: 'Borgio', value: 'Borgio' },
  { label: 'Borgloon', value: 'Borgloon' },
  { label: 'Borgne', value: 'Borgne' },
  { label: 'Borgo', value: 'Borgo' },
  { label: 'Borgo', value: 'Borgo' },
  { label: 'Borgo', value: 'Borgo' },
  { label: 'Borgo A Buggiano', value: 'Borgo A Buggiano' },
  { label: 'Borgo A Mozzano', value: 'Borgo A Mozzano' },
  { label: 'Borgo Dale', value: 'Borgo Dale' },
  { label: 'Borgo Di Ranzo', value: 'Borgo Di Ranzo' },
  { label: 'Borgo Di Terzo', value: 'Borgo Di Terzo' },
  { label: 'Borgo Fornari-Pieve', value: 'Borgo Fornari-Pieve' },
  { label: 'Borgo Grappa', value: 'Borgo Grappa' },
  { label: 'Borgo Hermada', value: 'Borgo Hermada' },
  { label: 'Borgo Lotti', value: 'Borgo Lotti' },
  { label: 'Borgo Maggiore', value: 'Borgo Maggiore' },
  { label: 'Borgo Massano', value: 'Borgo Massano' },
  { label: 'Borgo Melano', value: 'Borgo Melano' },
  { label: 'Borgo Pace', value: 'Borgo Pace' },
  { label: 'Borgo Podgora', value: 'Borgo Podgora' },
  { label: 'Borgo Priolo', value: 'Borgo Priolo' },
  { label: 'Borgo Sabotino-Foce Verde', value: 'Borgo Sabotino-Foce Verde' },
  { label: 'Borgo San Dalmazzo', value: 'Borgo San Dalmazzo' },
  { label: 'Borgo San Giacomo', value: 'Borgo San Giacomo' },
  { label: 'Borgo San Giovanni', value: 'Borgo San Giovanni' },
  { label: 'Borgo San Lorenzo', value: 'Borgo San Lorenzo' },
  { label: 'Borgo San Martino', value: 'Borgo San Martino' },
  { label: 'Borgo San Michele', value: 'Borgo San Michele' },
  { label: 'Borgo San Siro', value: 'Borgo San Siro' },
  { label: 'Borgo Santa Maria', value: 'Borgo Santa Maria' },
  { label: 'Borgo Santa Maria Immacolata', value: 'Borgo Santa Maria Immacolata' },
  { label: 'Borgo Stazione', value: 'Borgo Stazione' },
  { label: 'Borgo Ticino', value: 'Borgo Ticino' },
  { label: 'Borgo Tossignano', value: 'Borgo Tossignano' },
  { label: 'Borgo Val Di Taro', value: 'Borgo Val Di Taro' },
  { label: 'Borgo Valsugana', value: 'Borgo Valsugana' },
  { label: 'Borgo Velino', value: 'Borgo Velino' },
  { label: 'Borgo Vercelli', value: 'Borgo Vercelli' },
  { label: 'Borgoforte', value: 'Borgoforte' },
  { label: 'Borgofranco Divrea', value: 'Borgofranco Divrea' },
  { label: 'Borgofranco Sul Po', value: 'Borgofranco Sul Po' },
  { label: 'Borgolavezzaro', value: 'Borgolavezzaro' },
  { label: 'Borgomale', value: 'Borgomale' },
  { label: 'Borgomanero', value: 'Borgomanero' },
  { label: 'Borgomaro', value: 'Borgomaro' },
  { label: 'Borgomasino', value: 'Borgomasino' },
  { label: 'Borgone Susa', value: 'Borgone Susa' },
  { label: 'Borgonovo Valtidone', value: 'Borgonovo Valtidone' },
  { label: 'Borgonuovo', value: 'Borgonuovo' },
  { label: 'Borgoratto Alessandrino', value: 'Borgoratto Alessandrino' },
  { label: 'Borgoratto Mormorolo', value: 'Borgoratto Mormorolo' },
  { label: 'Borgoricco-San Michele Delle Badesse-Santeufemia', value: 'Borgoricco-San Michele Delle Badesse-Santeufemia' },
  { label: 'Borgorose', value: 'Borgorose' },
  { label: 'Borgosatollo', value: 'Borgosatollo' },
  { label: 'Borgosesia', value: 'Borgosesia' },
  { label: 'Borgstedt', value: 'Borgstedt' },
  { label: 'Borgustanskaya', value: 'Borgustanskaya' },
  { label: 'Bori', value: 'Bori' },
  { label: 'Borino', value: 'Borino' },
  { label: 'Borinskoye', value: 'Borinskoye' },
  { label: 'Borisivka', value: 'Borisivka' },
  { label: 'Borisoglebsk', value: 'Borisoglebsk' },
  { label: 'Borisoglebskiy', value: 'Borisoglebskiy' },
  { label: 'Borisoglebskiy Rayon', value: 'Borisoglebskiy Rayon' },
  { label: 'Borisova Griva', value: 'Borisova Griva' },
  { label: 'Borisovka', value: 'Borisovka' },
  { label: 'Borisovskiy Rayon', value: 'Borisovskiy Rayon' },
  { label: 'Borispol’Skiy Rayon', value: 'Borispol’Skiy Rayon' },
  { label: 'Bořitov', value: 'Bořitov' },
  { label: 'Borivali', value: 'Borivali' },
  { label: 'Borja', value: 'Borja' },
  { label: 'Borja', value: 'Borja' },
  { label: 'Borjabad', value: 'Borjabad' },
  { label: 'Borjaki', value: 'Borjaki' },
  { label: 'Borjomi', value: 'Borjomi' },
  { label: 'Borken', value: 'Borken' },
  { label: 'Borken', value: 'Borken' },
  { label: 'Borkenes', value: 'Borkenes' },
  { label: 'Borkheide', value: 'Borkheide' },
  { label: 'Borkhera', value: 'Borkhera' },
  { label: 'Borkhvar', value: 'Borkhvar' },
  { label: 'Borki', value: 'Borki' },
  { label: 'Børkop', value: 'Børkop' },
  { label: 'Borkowice', value: 'Borkowice' },
  { label: 'Borkum', value: 'Borkum' },
  { label: 'Borkwalde', value: 'Borkwalde' },
  { label: 'Borlänge', value: 'Borlänge' },
  { label: 'Borleşti', value: 'Borleşti' },
  { label: 'Borlova', value: 'Borlova' },
  { label: 'Bormes-Les-Mimosas', value: 'Bormes-Les-Mimosas' },
  { label: 'Bormida', value: 'Bormida' },
  { label: 'Bormida-Genepro', value: 'Bormida-Genepro' },
  { label: 'Bormio', value: 'Bormio' },
  { label: 'Bormujos', value: 'Bormujos' },
  { label: 'Born', value: 'Born' },
  { label: 'Born', value: 'Born' },
  { label: 'Borna', value: 'Borna' },
  { label: 'Bornasco', value: 'Bornasco' },
  { label: 'Bornato', value: 'Bornato' },
  { label: 'Borne', value: 'Borne' },
  { label: 'Borne', value: 'Borne' },
  { label: 'Borne Sulinowo', value: 'Borne Sulinowo' },
  { label: 'Bornel', value: 'Bornel' },
  { label: 'Bornem', value: 'Bornem' },
  { label: 'Bornheim', value: 'Bornheim' },
  { label: 'Bornheim', value: 'Bornheim' },
  { label: 'Bornholm Kommune', value: 'Bornholm Kommune' },
  { label: 'Bornhöved', value: 'Bornhöved' },
  { label: 'Bornich', value: 'Bornich' },
  { label: 'Börnichen', value: 'Börnichen' },
  { label: 'Borno', value: 'Borno' },
  { label: 'Bornos', value: 'Bornos' },
  { label: 'Bornova', value: 'Bornova' },
  { label: 'Börnsen', value: 'Börnsen' },
  { label: 'Bornu Yassu', value: 'Bornu Yassu' },
  { label: 'Boró', value: 'Boró' },
  { label: 'Boroaia', value: 'Boroaia' },
  { label: 'Borobia', value: 'Borobia' },
  { label: 'Borobridge', value: 'Borobridge' },
  { label: 'Borod', value: 'Borod' },
  { label: 'Borodianka', value: 'Borodianka' },
  { label: 'Borodino', value: 'Borodino' },
  { label: 'Borodino', value: 'Borodino' },
  { label: 'Borodinskiy', value: 'Borodinskiy' },
  { label: 'Borodulikha', value: 'Borodulikha' },
  { label: 'Borodyans’Kyy Rayon', value: 'Borodyans’Kyy Rayon' },
  { label: 'Borogontsy', value: 'Borogontsy' },
  { label: 'Borohrádek', value: 'Borohrádek' },
  { label: 'Borok', value: 'Borok' },
  { label: 'Boromlya', value: 'Boromlya' },
  { label: 'Boromo', value: 'Boromo' },
  { label: 'Boron', value: 'Boron' },
  { label: 'Boronda', value: 'Boronda' },
  { label: 'Boroneddu', value: 'Boroneddu' },
  { label: 'Borongan', value: 'Borongan' },
  { label: 'Borongan', value: 'Borongan' },
  { label: 'Boronia', value: 'Boronia' },
  { label: 'Boronia Heights', value: 'Boronia Heights' },
  { label: 'Boronów', value: 'Boronów' },
  { label: 'Boroon', value: 'Boroon' },
  { label: 'Boroondara', value: 'Boroondara' },
  { label: 'Borore', value: 'Borore' },
  { label: 'Boroşneu Mare', value: 'Boroşneu Mare' },
  { label: 'Borosoaia', value: 'Borosoaia' },
  { label: 'Borough Green', value: 'Borough Green' },
  { label: 'Borough Of Bolton', value: 'Borough Of Bolton' },
  { label: 'Borough Of Bury', value: 'Borough Of Bury' },
  { label: 'Borough Of Halton', value: 'Borough Of Halton' },
  { label: 'Borough Of North Tyneside', value: 'Borough Of North Tyneside' },
  { label: 'Borough Of Oldham', value: 'Borough Of Oldham' },
  { label: 'Borough Of Rochdale', value: 'Borough Of Rochdale' },
  { label: 'Borough Of Stockport', value: 'Borough Of Stockport' },
  { label: 'Borough Of Swindon', value: 'Borough Of Swindon' },
  { label: 'Borough Of Tameside', value: 'Borough Of Tameside' },
  { label: 'Borough Of Thurrock', value: 'Borough Of Thurrock' },
  { label: 'Borough Of Torbay', value: 'Borough Of Torbay' },
  { label: 'Borough Of Wigan', value: 'Borough Of Wigan' },
  { label: 'Borough Park', value: 'Borough Park' },
  { label: 'Boroughbridge', value: 'Boroughbridge' },
  { label: 'Boroujen', value: 'Boroujen' },
  { label: 'Borova', value: 'Borova' },
  { label: 'Borova', value: 'Borova' },
  { label: 'Borová Lada', value: 'Borová Lada' },
  { label: 'Borovan', value: 'Borovan' },
  { label: 'Borovany', value: 'Borovany' },
  { label: 'Borovichi', value: 'Borovichi' },
  { label: 'Borovichskiy Rayon', value: 'Borovichskiy Rayon' },
  { label: 'Borovikha', value: 'Borovikha' },
  { label: 'Borovlyany', value: 'Borovlyany' },
  { label: 'Borovnica', value: 'Borovnica' },
  { label: 'Borovo', value: 'Borovo' },
  { label: 'Borovo', value: 'Borovo' },
  { label: 'Borovo Selo', value: 'Borovo Selo' },
  { label: 'Borovoy', value: 'Borovoy' },
  { label: 'Borovoy', value: 'Borovoy' },
  { label: 'Borovoy', value: 'Borovoy' },
  { label: 'Borovsk', value: 'Borovsk' },
  { label: 'Borovskiy', value: 'Borovskiy' },
  { label: 'Borovskiy Rayon', value: 'Borovskiy Rayon' },
  { label: 'Borovskoy', value: 'Borovskoy' },
  { label: 'Borowa', value: 'Borowa' },
  { label: 'Borowie', value: 'Borowie' },
  { label: 'Borowno', value: 'Borowno' },
  { label: 'Borox', value: 'Borox' },
  { label: 'Borrassà', value: 'Borrassà' },
  { label: 'Borrazópolis', value: 'Borrazópolis' },
  { label: 'Borredà', value: 'Borredà' },
  { label: 'Borrego Springs', value: 'Borrego Springs' },
  { label: 'Borrello', value: 'Borrello' },
  { label: 'Borrenes', value: 'Borrenes' },
  { label: 'Borrentin', value: 'Borrentin' },
  { label: 'Borriana', value: 'Borriana' },
  { label: 'Borriana/Burriana', value: 'Borriana/Burriana' },
  { label: 'Borriol', value: 'Borriol' },
  { label: 'Borrowash', value: 'Borrowash' },
  { label: 'Borş', value: 'Borş' },
  { label: 'Borşa', value: 'Borşa' },
  { label: 'Borşa', value: 'Borşa' },
  { label: 'Borsad', value: 'Borsad' },
  { label: 'Borsbeek', value: 'Borsbeek' },
  { label: 'Borsdorf', value: 'Borsdorf' },
  { label: 'Borsec', value: 'Borsec' },
  { label: 'Borshchevskiy Rayon', value: 'Borshchevskiy Rayon' },
  { label: 'Borshchiv', value: 'Borshchiv' },
  { label: 'Boršice', value: 'Boršice' },
  { label: 'Borskoye', value: 'Borskoye' },
  { label: 'Borso Del Grappa', value: 'Borso Del Grappa' },
  { label: 'Borsodnádasd', value: 'Borsodnádasd' },
  { label: 'Borssele', value: 'Borssele' },
  { label: 'Börßum', value: 'Börßum' },
  { label: 'Borstel', value: 'Borstel' },
  { label: 'Borstel-Hohenraden', value: 'Borstel-Hohenraden' },
  { label: 'Borstendorf', value: 'Borstendorf' },
  { label: 'Borth', value: 'Borth' },
  { label: 'Bortigali', value: 'Bortigali' },
  { label: 'Bortigiadas', value: 'Bortigiadas' },
  { label: 'Bort-Les-Orgues', value: 'Bort-Les-Orgues' },
  { label: 'Börtlingen', value: 'Börtlingen' },
  { label: 'Bortolot', value: 'Bortolot' },
  { label: 'Borucin', value: 'Borucin' },
  { label: 'Borujen', value: 'Borujen' },
  { label: 'Borujerd', value: 'Borujerd' },
  { label: 'Borup', value: 'Borup' },
  { label: 'Borutta', value: 'Borutta' },
  { label: 'Boryeong', value: 'Boryeong' },
  { label: 'Boryeong-Si', value: 'Boryeong-Si' },
  { label: 'Boryslav', value: 'Boryslav' },
  { label: 'Boryspil’', value: 'Boryspil’' },
  { label: 'Borzano', value: 'Borzano' },
  { label: 'Borzechów', value: 'Borzechów' },
  { label: 'Borzęcin', value: 'Borzęcin' },
  { label: 'Borzęta', value: 'Borzęta' },
  { label: 'Borzia', value: 'Borzia' },
  { label: 'Borzinskiy Rayon', value: 'Borzinskiy Rayon' },
  { label: 'Borzna', value: 'Borzna' },
  { label: 'Borzonasca', value: 'Borzonasca' },
  { label: 'Borzoy', value: 'Borzoy' },
  { label: 'Borzya', value: 'Borzya' },
  { label: 'Borzytuchom', value: 'Borzytuchom' },
  { label: 'Bos En Lommer', value: 'Bos En Lommer' },
  { label: 'Bosa', value: 'Bosa' },
  { label: 'Bosanci', value: 'Bosanci' },
  { label: 'Bosanska Krupa', value: 'Bosanska Krupa' },
  { label: 'Bosanski Petrovac', value: 'Bosanski Petrovac' },
  { label: 'Bosansko Grahovo', value: 'Bosansko Grahovo' },
  { label: 'Bősárkány', value: 'Bősárkány' },
  { label: 'Bosaro', value: 'Bosaro' },
  { label: 'Bosaso', value: 'Bosaso' },
  { label: 'Bosau', value: 'Bosau' },
  { label: 'Boscawen', value: 'Boscawen' },
  { label: 'Bosch En Duin', value: 'Bosch En Duin' },
  { label: 'Boschetto', value: 'Boschetto' },
  { label: 'Boschi Santanna', value: 'Boschi Santanna' },
  { label: 'Bosc-Le-Hard', value: 'Bosc-Le-Hard' },
  { label: 'Bosco', value: 'Bosco' },
  { label: 'Bosco Chiesanuova', value: 'Bosco Chiesanuova' },
  { label: 'Bosco Ex Parmigiano', value: 'Bosco Ex Parmigiano' },
  { label: 'Bosco Marengo', value: 'Bosco Marengo' },
  { label: 'Bosco Mesola', value: 'Bosco Mesola' },
  { label: 'Boscobel', value: 'Boscobel' },
  { label: 'Boscochiaro', value: 'Boscochiaro' },
  { label: 'Bosconero', value: 'Bosconero' },
  { label: 'Bosconia', value: 'Bosconia' },
  { label: 'Boscoreale', value: 'Boscoreale' },
  { label: 'Boscotrecase', value: 'Boscotrecase' },
  { label: 'Bosdak', value: 'Bosdak' },
  { label: 'Bosdarros', value: 'Bosdarros' },
  { label: 'Bösdorf', value: 'Bösdorf' },
  { label: 'Bösel', value: 'Bösel' },
  { label: 'Bösenbrunn', value: 'Bösenbrunn' },
  { label: 'Bosentino-Migazzone', value: 'Bosentino-Migazzone' },
  { label: 'Boseong-Gun', value: 'Boseong-Gun' },
  { label: 'Bosham', value: 'Bosham' },
  { label: 'Boshan', value: 'Boshan' },
  { label: 'Boshchorbogh', value: 'Boshchorbogh' },
  { label: 'Boshindó', value: 'Boshindó' },
  { label: 'Boshkan', value: 'Boshkan' },
  { label: 'Boshnyakovo', value: 'Boshnyakovo' },
  { label: 'Boshrouyeh', value: 'Boshrouyeh' },
  { label: 'Boshruyeh', value: 'Boshruyeh' },
  { label: 'Bosia', value: 'Bosia' },
  { label: 'Bosia', value: 'Bosia' },
  { label: 'Bosilovo', value: 'Bosilovo' },
  { label: 'Bösingen', value: 'Bösingen' },
  { label: 'Bösingen', value: 'Bösingen' },
  { label: 'Bosio', value: 'Bosio' },
  { label: 'Bosisio Parini', value: 'Bosisio Parini' },
  { label: 'Boskamp', value: 'Boskamp' },
  { label: 'Boskoop', value: 'Boskoop' },
  { label: 'Boskovice', value: 'Boskovice' },
  { label: 'Bosmie-Laiguille', value: 'Bosmie-Laiguille' },
  { label: 'Bosnasco', value: 'Bosnasco' },
  { label: 'Bošnjaci', value: 'Bošnjaci' },
  { label: 'Bosobolo', value: 'Bosobolo' },
  { label: 'Boşorod', value: 'Boşorod' },
  { label: 'Bošovice', value: 'Bošovice' },
  { label: 'Bosque County', value: 'Bosque County' },
  { label: 'Bosque De Saloya', value: 'Bosque De Saloya' },
  { label: 'Bosque El', value: 'Bosque El' },
  { label: 'Bosque Farms', value: 'Bosque Farms' },
  { label: 'Bosque Real Fraccionamiento', value: 'Bosque Real Fraccionamiento' },
  { label: 'Bosques De La Magdalena', value: 'Bosques De La Magdalena' },
  { label: 'Bosques De San Pedro', value: 'Bosques De San Pedro' },
  { label: 'Bosquet Dor', value: 'Bosquet Dor' },
  { label: 'Bossangoa', value: 'Bossangoa' },
  { label: 'Bosschenhoofd', value: 'Bosschenhoofd' },
  { label: 'Bossico', value: 'Bossico' },
  { label: 'Bossier City', value: 'Bossier City' },
  { label: 'Bossier Parish', value: 'Bossier Parish' },
  { label: 'Bossley Park', value: 'Bossley Park' },
  { label: 'Bossolasco', value: 'Bossolasco' },
  { label: 'Bossoroca', value: 'Bossoroca' },
  { label: 'Bossòst', value: 'Bossòst' },
  { label: 'Bostan', value: 'Bostan' },
  { label: 'Bostan Abad', value: 'Bostan Abad' },
  { label: 'Bostanabad', value: 'Bostanabad' },
  { label: 'Boston', value: 'Boston' },
  { label: 'Boston', value: 'Boston' },
  { label: 'Boston', value: 'Boston' },
  { label: 'Boston', value: 'Boston' },
  { label: 'Boston', value: 'Boston' },
  { label: 'Boston', value: 'Boston' },
  { label: 'Boston', value: 'Boston' },
  { label: 'Boston Heights', value: 'Boston Heights' },
  { label: 'Boston Spa', value: 'Boston Spa' },
  { label: 'Bostonia', value: 'Bostonia' },
  { label: 'Bosut', value: 'Bosut' },
  { label: 'Boswell', value: 'Boswell' },
  { label: 'Boswells Corner', value: 'Boswells Corner' },
  { label: 'Bot', value: 'Bot' },
  { label: 'Botad', value: 'Botad' },
  { label: 'Botanic Ridge', value: 'Botanic Ridge' },
  { label: 'Botanicheskoye', value: 'Botanicheskoye' },
  { label: 'Botany', value: 'Botany' },
  { label: 'Botany Bay', value: 'Botany Bay' },
  { label: 'Botao', value: 'Botao' },
  { label: 'Botao', value: 'Botao' },
  { label: 'Botarell', value: 'Botarell' },
  { label: 'Botashyurt', value: 'Botashyurt' },
  { label: 'Botayurt', value: 'Botayurt' },
  { label: 'Botei', value: 'Botei' },
  { label: 'Botelhos', value: 'Botelhos' },
  { label: 'Boteni', value: 'Boteni' },
  { label: 'Bötersen', value: 'Bötersen' },
  { label: 'Botesdale', value: 'Botesdale' },
  { label: 'Botești', value: 'Botești' },
  { label: 'Boţeşti', value: 'Boţeşti' },
  { label: 'Boţeşti', value: 'Boţeşti' },
  { label: 'Botetourt County', value: 'Botetourt County' },
  { label: 'Botevgrad', value: 'Botevgrad' },
  { label: 'Botez', value: 'Botez' },
  { label: 'Bothaville', value: 'Bothaville' },
  { label: 'Bothel', value: 'Bothel' },
  { label: 'Bothell', value: 'Bothell' },
  { label: 'Bothell East', value: 'Bothell East' },
  { label: 'Bothell West', value: 'Bothell West' },
  { label: 'Bothwell', value: 'Bothwell' },
  { label: 'Boticas', value: 'Boticas' },
  { label: 'Botigues', value: 'Botigues' },
  { label: 'Botija', value: 'Botija' },
  { label: 'Botiz', value: 'Botiz' },
  { label: 'Botiza', value: 'Botiza' },
  { label: 'Botkins', value: 'Botkins' },
  { label: 'Botley', value: 'Botley' },
  { label: 'Botlhapatlou', value: 'Botlhapatlou' },
  { label: 'Botlikhskiy Rayon', value: 'Botlikhskiy Rayon' },
  { label: 'Botngård', value: 'Botngård' },
  { label: 'Botolan', value: 'Botolan' },
  { label: 'Botolan', value: 'Botolan' },
  { label: 'Boton', value: 'Boton' },
  { label: 'Botorca', value: 'Botorca' },
  { label: 'Botoroaga', value: 'Botoroaga' },
  { label: 'Botorrita', value: 'Botorrita' },
  { label: 'Botoš', value: 'Botoš' },
  { label: 'Botoşana', value: 'Botoşana' },
  { label: 'Botoşani', value: 'Botoşani' },
  { label: 'Botoşeşti-Paia', value: 'Botoşeşti-Paia' },
  { label: 'Botou', value: 'Botou' },
  { label: 'Botricello', value: 'Botricello' },
  { label: 'Botro', value: 'Botro' },
  { label: 'Botrugno', value: 'Botrugno' },
  { label: 'Botshabelo', value: 'Botshabelo' },
  { label: 'Botta', value: 'Botta' },
  { label: 'Bottanuco', value: 'Bottanuco' },
  { label: 'Bottega', value: 'Bottega' },
  { label: 'Bottens', value: 'Bottens' },
  { label: 'Bottesford', value: 'Bottesford' },
  { label: 'Botticino', value: 'Botticino' },
  { label: 'Bottidda', value: 'Bottidda' },
  { label: 'Bottighofen', value: 'Bottighofen' },
  { label: 'Bottineau', value: 'Bottineau' },
  { label: 'Bottineau County', value: 'Bottineau County' },
  { label: 'Böttingen', value: 'Böttingen' },
  { label: 'Bottisham', value: 'Bottisham' },
  { label: 'Bottmingen', value: 'Bottmingen' },
  { label: 'Bottrighe', value: 'Bottrighe' },
  { label: 'Bottrop', value: 'Bottrop' },
  { label: 'Botucatu', value: 'Botucatu' },
  { label: 'Botumirim', value: 'Botumirim' },
  { label: 'Botuporã', value: 'Botuporã' },
  { label: 'Botuverá', value: 'Botuverá' },
  { label: 'Botwood', value: 'Botwood' },
  { label: 'Bötzingen', value: 'Bötzingen' },
  { label: 'Bou Arada', value: 'Bou Arada' },
  { label: 'Boû Arfa', value: 'Boû Arfa' },
  { label: 'Bou Argoub', value: 'Bou Argoub' },
  { label: 'Bou Attouche', value: 'Bou Attouche' },
  { label: 'Bou Hanifia El Hamamat', value: 'Bou Hanifia El Hamamat' },
  { label: 'Bou Ismaïl', value: 'Bou Ismaïl' },
  { label: 'Bou Tlelis', value: 'Bou Tlelis' },
  { label: 'Bouabout', value: 'Bouabout' },
  { label: 'Bouafle', value: 'Bouafle' },
  { label: 'Bouaflé', value: 'Bouaflé' },
  { label: 'Bouaké', value: 'Bouaké' },
  { label: 'Bouar', value: 'Bouar' },
  { label: 'Bouarfa', value: 'Bouarfa' },
  { label: 'Bouarouss', value: 'Bouarouss' },
  { label: 'Bouaye', value: 'Bouaye' },
  { label: 'Bouca', value: 'Bouca' },
  { label: 'Bouça Cova', value: 'Bouça Cova' },
  { label: 'Boucau', value: 'Boucau' },
  { label: 'Bouc-Bel-Air', value: 'Bouc-Bel-Air' },
  { label: 'Bouchain', value: 'Bouchain' },
  { label: 'Bouchemaine', value: 'Bouchemaine' },
  { label: 'Boucher Park', value: 'Boucher Park' },
  { label: 'Boucherville', value: 'Boucherville' },
  { label: 'Bouctouche', value: 'Bouctouche' },
  { label: 'Boudjima', value: 'Boudjima' },
  { label: 'Boudouaou', value: 'Boudouaou' },
  { label: 'Boudry', value: 'Boudry' },
  { label: 'Boudry District', value: 'Boudry District' },
  { label: 'Boué', value: 'Boué' },
  { label: 'Boufarik', value: 'Boufarik' },
  { label: 'Bouffémont', value: 'Bouffémont' },
  { label: 'Boufféré', value: 'Boufféré' },
  { label: 'Bougaa', value: 'Bougaa' },
  { label: 'Bougado', value: 'Bougado' },
  { label: 'Bougara', value: 'Bougara' },
  { label: 'Boughton', value: 'Boughton' },
  { label: 'Bougival', value: 'Bougival' },
  { label: 'Bougouni', value: 'Bougouni' },
  { label: 'Bouguenais', value: 'Bouguenais' },
  { label: 'Bouillargues', value: 'Bouillargues' },
  { label: 'Bouillé-Loretz', value: 'Bouillé-Loretz' },
  { label: 'Bouillon', value: 'Bouillon' },
  { label: 'Bouilly', value: 'Bouilly' },
  { label: 'Bouin', value: 'Bouin' },
  { label: 'Bouinan', value: 'Bouinan' },
  { label: 'Bouïra', value: 'Bouïra' },
  { label: 'Boujan-Sur-Libron', value: 'Boujan-Sur-Libron' },
  { label: 'Boujdour', value: 'Boujdour' },
  { label: 'Boujniba', value: 'Boujniba' },
  { label: 'Boukadir', value: 'Boukadir' },
  { label: 'Boulaide', value: 'Boulaide' },
  { label: 'Boulange', value: 'Boulange' },
  { label: 'Boulaouane', value: 'Boulaouane' },
  { label: 'Boulay-Moselle', value: 'Boulay-Moselle' },
  { label: 'Boulazac', value: 'Boulazac' },
  { label: 'Boulbon', value: 'Boulbon' },
  { label: 'Boulder', value: 'Boulder' },
  { label: 'Boulder', value: 'Boulder' },
  { label: 'Boulder', value: 'Boulder' },
  { label: 'Boulder City', value: 'Boulder City' },
  { label: 'Boulder County', value: 'Boulder County' },
  { label: 'Boulder Creek', value: 'Boulder Creek' },
  { label: 'Boulder Hill', value: 'Boulder Hill' },
  { label: 'Bouldercombe', value: 'Bouldercombe' },
  { label: 'Boulemane', value: 'Boulemane' },
  { label: 'Bouleurs', value: 'Bouleurs' },
  { label: 'Boulevard Gardens', value: 'Boulevard Gardens' },
  { label: 'Boulevard Park', value: 'Boulevard Park' },
  { label: 'Boulia', value: 'Boulia' },
  { label: 'Bouliac', value: 'Bouliac' },
  { label: 'Boulieu-Lès-Annonay', value: 'Boulieu-Lès-Annonay' },
  { label: 'Bouligny', value: 'Bouligny' },
  { label: 'Boulleret', value: 'Boulleret' },
  { label: 'Bouloc', value: 'Bouloc' },
  { label: 'Boulogne-Billancourt', value: 'Boulogne-Billancourt' },
  { label: 'Boulogne-Sur-Gesse', value: 'Boulogne-Sur-Gesse' },
  { label: 'Boulogne-Sur-Mer', value: 'Boulogne-Sur-Mer' },
  { label: 'Bouloire', value: 'Bouloire' },
  { label: 'Boulsa', value: 'Boulsa' },
  { label: 'Boult-Sur-Suippe', value: 'Boult-Sur-Suippe' },
  { label: 'Boumagueur', value: 'Boumagueur' },
  { label: 'Boumahra Ahmed', value: 'Boumahra Ahmed' },
  { label: 'Boumerdas', value: 'Boumerdas' },
  { label: 'Bouna', value: 'Bouna' },
  { label: 'Bound Brook', value: 'Bound Brook' },
  { label: 'Boundary County', value: 'Boundary County' },
  { label: 'Boundbrook', value: 'Boundbrook' },
  { label: 'Boungouéni', value: 'Boungouéni' },
  { label: 'Bouni', value: 'Bouni' },
  { label: 'Bounkani', value: 'Bounkani' },
  { label: 'Bountiful', value: 'Bountiful' },
  { label: 'Bounty Hall', value: 'Bounty Hall' },
  { label: 'Bourail', value: 'Bourail' },
  { label: 'Bouray-Sur-Juine', value: 'Bouray-Sur-Juine' },
  { label: 'Bourbon', value: 'Bourbon' },
  { label: 'Bourbon', value: 'Bourbon' },
  { label: 'Bourbon County', value: 'Bourbon County' },
  { label: 'Bourbon County', value: 'Bourbon County' },
  { label: 'Bourbon-L’Archambault', value: 'Bourbon-L’Archambault' },
  { label: 'Bourbon-Lancy', value: 'Bourbon-Lancy' },
  { label: 'Bourbonnais', value: 'Bourbonnais' },
  { label: 'Bourbonne-Les-Bains', value: 'Bourbonne-Les-Bains' },
  { label: 'Bourbourg', value: 'Bourbourg' },
  { label: 'Bourbriac', value: 'Bourbriac' },
  { label: 'Bourcefranc-Le-Chapus', value: 'Bourcefranc-Le-Chapus' },
  { label: 'Boureni', value: 'Boureni' },
  { label: 'Boureni', value: 'Boureni' },
  { label: 'Bourg', value: 'Bourg' },
  { label: 'Bourg', value: 'Bourg' },
  { label: 'Bourg De Joué-Sur-Erdre', value: 'Bourg De Joué-Sur-Erdre' },
  { label: 'Bourg-Achard', value: 'Bourg-Achard' },
  { label: 'Bourganeuf', value: 'Bourganeuf' },
  { label: 'Bourg-Argental', value: 'Bourg-Argental' },
  { label: 'Bourgbarré', value: 'Bourgbarré' },
  { label: 'Bourg-Blanc', value: 'Bourg-Blanc' },
  { label: 'Bourg-De-Péage', value: 'Bourg-De-Péage' },
  { label: 'Bourg-Des-Comptes', value: 'Bourg-Des-Comptes' },
  { label: 'Bourg-De-Thizy', value: 'Bourg-De-Thizy' },
  { label: 'Bourg-En-Bresse', value: 'Bourg-En-Bresse' },
  { label: 'Bourges', value: 'Bourges' },
  { label: 'Bourget', value: 'Bourget' },
  { label: 'Bourghelles', value: 'Bourghelles' },
  { label: 'Bourg-La-Reine', value: 'Bourg-La-Reine' },
  { label: 'Bourg-Lès-Valence', value: 'Bourg-Lès-Valence' },
  { label: 'Bourgneuf', value: 'Bourgneuf' },
  { label: 'Bourgneuf-En-Retz', value: 'Bourgneuf-En-Retz' },
  { label: 'Bourgogne', value: 'Bourgogne' },
  { label: 'Bourgoin-Jallieu', value: 'Bourgoin-Jallieu' },
  { label: 'Bourg-Saint-Andéol', value: 'Bourg-Saint-Andéol' },
  { label: 'Bourg-Saint-Maurice', value: 'Bourg-Saint-Maurice' },
  { label: 'Bourgtheroulde-Infreville', value: 'Bourgtheroulde-Infreville' },
  { label: 'Bourguébus', value: 'Bourguébus' },
  { label: 'Bourgueil', value: 'Bourgueil' },
  { label: 'Bourke', value: 'Bourke' },
  { label: 'Bourkelands', value: 'Bourkelands' },
  { label: 'Bourlon', value: 'Bourlon' },
  { label: 'Bourne', value: 'Bourne' },
  { label: 'Bourne', value: 'Bourne' },
  { label: 'Bourne End', value: 'Bourne End' },
  { label: 'Bournemouth', value: 'Bournemouth' },
  { label: 'Bournemouth Gardens', value: 'Bournemouth Gardens' },
  { label: 'Bournezeau', value: 'Bournezeau' },
  { label: 'Bournoncle-Saint-Pierre', value: 'Bournoncle-Saint-Pierre' },
  { label: 'Bourogne', value: 'Bourogne' },
  { label: 'Bourron-Marlotte', value: 'Bourron-Marlotte' },
  { label: 'Bourscheid', value: 'Bourscheid' },
  { label: 'Bourth', value: 'Bourth' },
  { label: 'Bourton', value: 'Bourton' },
  { label: 'Bourton On The Water', value: 'Bourton On The Water' },
  { label: 'Bous', value: 'Bous' },
  { label: 'Bous', value: 'Bous' },
  { label: 'Bousbecque', value: 'Bousbecque' },
  { label: 'Bousies', value: 'Bousies' },
  { label: 'Bouskoura', value: 'Bouskoura' },
  { label: 'Boussac', value: 'Boussac' },
  { label: 'Boussay', value: 'Boussay' },
  { label: 'Bousse', value: 'Bousse' },
  { label: 'Boussé', value: 'Boussé' },
  { label: 'Boussières', value: 'Boussières' },
  { label: 'Bousso', value: 'Bousso' },
  { label: 'Boussois', value: 'Boussois' },
  { label: 'Boussu', value: 'Boussu' },
  { label: 'Boussy-Saint-Antoine', value: 'Boussy-Saint-Antoine' },
  { label: 'Bout-Du-Pont-De-Larn', value: 'Bout-Du-Pont-De-Larn' },
  { label: 'Boutersem', value: 'Boutersem' },
  { label: 'Boutiers-Saint-Trojan', value: 'Boutiers-Saint-Trojan' },
  { label: 'Boutigny-Sur-Essonne', value: 'Boutigny-Sur-Essonne' },
  { label: 'Bouton', value: 'Bouton' },
  { label: 'Boutte', value: 'Boutte' },
  { label: 'Bouttencourt', value: 'Bouttencourt' },
  { label: 'Bouvesse-Quirieu', value: 'Bouvesse-Quirieu' },
  { label: 'Bouvignies', value: 'Bouvignies' },
  { label: 'Bouvigny-Boyeffles', value: 'Bouvigny-Boyeffles' },
  { label: 'Bouville', value: 'Bouville' },
  { label: 'Bouvron', value: 'Bouvron' },
  { label: 'Bouxières-Aux-Chênes', value: 'Bouxières-Aux-Chênes' },
  { label: 'Bouxières-Aux-Dames', value: 'Bouxières-Aux-Dames' },
  { label: 'Bouxwiller', value: 'Bouxwiller' },
  { label: 'Bouza', value: 'Bouza' },
  { label: 'Bouza La', value: 'Bouza La' },
  { label: 'Bouzigues', value: 'Bouzigues' },
  { label: 'Bouzillé', value: 'Bouzillé' },
  { label: 'Bouznika', value: 'Bouznika' },
  { label: 'Bouzonville', value: 'Bouzonville' },
  { label: 'Bouzov', value: 'Bouzov' },
  { label: 'Bouzy-La-Forêt', value: 'Bouzy-La-Forêt' },
  { label: 'Bova', value: 'Bova' },
  { label: 'Bova Marina', value: 'Bova Marina' },
  { label: 'Bovalino', value: 'Bovalino' },
  { label: 'Bovalino Superiore', value: 'Bovalino Superiore' },
  { label: 'Bovec', value: 'Bovec' },
  { label: 'Bóveda', value: 'Bóveda' },
  { label: 'Bóveda De Toro La', value: 'Bóveda De Toro La' },
  { label: 'Bóveda Del Río Almar', value: 'Bóveda Del Río Almar' },
  { label: 'Bovegno', value: 'Bovegno' },
  { label: 'Boven Bolivia', value: 'Boven Bolivia' },
  { label: 'Boven Bolivia', value: 'Boven Bolivia' },
  { label: 'Bovenau', value: 'Bovenau' },
  { label: 'Bovenden', value: 'Bovenden' },
  { label: 'Boven-Hardinxveld', value: 'Boven-Hardinxveld' },
  { label: 'Bovenkarspel', value: 'Bovenkarspel' },
  { label: 'Bovera', value: 'Bovera' },
  { label: 'Boves', value: 'Boves' },
  { label: 'Boves', value: 'Boves' },
  { label: 'Bovey Tracey', value: 'Bovey Tracey' },
  { label: 'Bovezzo', value: 'Bovezzo' },
  { label: 'Boville Ernica', value: 'Boville Ernica' },
  { label: 'Bovina', value: 'Bovina' },
  { label: 'Bovingdon', value: 'Bovingdon' },
  { label: 'Bovington Camp', value: 'Bovington Camp' },
  { label: 'Bovino', value: 'Bovino' },
  { label: 'Bovisio-Masciago', value: 'Bovisio-Masciago' },
  { label: 'Bovolenta', value: 'Bovolenta' },
  { label: 'Bovolone', value: 'Bovolone' },
  { label: 'Bovril', value: 'Bovril' },
  { label: 'Bow', value: 'Bow' },
  { label: 'Bow Bog', value: 'Bow Bog' },
  { label: 'Bow Bowing', value: 'Bow Bowing' },
  { label: 'Bow Brickhill', value: 'Bow Brickhill' },
  { label: 'Bow Island', value: 'Bow Island' },
  { label: 'Bow Street', value: 'Bow Street' },
  { label: 'Bowbells', value: 'Bowbells' },
  { label: 'Bowburn', value: 'Bowburn' },
  { label: 'Bowdon', value: 'Bowdon' },
  { label: 'Bowdon', value: 'Bowdon' },
  { label: 'Bowen', value: 'Bowen' },
  { label: 'Bowen Hills', value: 'Bowen Hills' },
  { label: 'Bowen Island', value: 'Bowen Island' },
  { label: 'Bowen Mountain', value: 'Bowen Mountain' },
  { label: 'Bowenfels', value: 'Bowenfels' },
  { label: 'Bowie', value: 'Bowie' },
  { label: 'Bowie', value: 'Bowie' },
  { label: 'Bowie County', value: 'Bowie County' },
  { label: 'Bowil', value: 'Bowil' },
  { label: 'Bowleys Quarters', value: 'Bowleys Quarters' },
  { label: 'Bowling Green', value: 'Bowling Green' },
  { label: 'Bowling Green', value: 'Bowling Green' },
  { label: 'Bowling Green', value: 'Bowling Green' },
  { label: 'Bowling Green', value: 'Bowling Green' },
  { label: 'Bowling Green', value: 'Bowling Green' },
  { label: 'Bowling Green', value: 'Bowling Green' },
  { label: 'Bowman', value: 'Bowman' },
  { label: 'Bowman County', value: 'Bowman County' },
  { label: 'Bowmansville', value: 'Bowmansville' },
  { label: 'Bowral', value: 'Bowral' },
  { label: 'Bowraville', value: 'Bowraville' },
  { label: 'Bowthorpe', value: 'Bowthorpe' },
  { label: 'Box', value: 'Box' },
  { label: 'Box Butte County', value: 'Box Butte County' },
  { label: 'Box Elder', value: 'Box Elder' },
  { label: 'Box Elder County', value: 'Box Elder County' },
  { label: 'Box Hill', value: 'Box Hill' },
  { label: 'Box Hill North', value: 'Box Hill North' },
  { label: 'Box Hill South', value: 'Box Hill South' },
  { label: 'Boxasní', value: 'Boxasní' },
  { label: 'Boxberg', value: 'Boxberg' },
  { label: 'Boxberg', value: 'Boxberg' },
  { label: 'Boxborough', value: 'Boxborough' },
  { label: 'Boxford', value: 'Boxford' },
  { label: 'Boxgrove', value: 'Boxgrove' },
  { label: 'Boxholm', value: 'Boxholm' },
  { label: 'Boxmeer', value: 'Boxmeer' },
  { label: 'Boxted', value: 'Boxted' },
  { label: 'Boxtel', value: 'Boxtel' },
  { label: 'Boyabat', value: 'Boyabat' },
  { label: 'Boyacá', value: 'Boyacá' },
  { label: 'Boyang', value: 'Boyang' },
  { label: 'Boyanup', value: 'Boyanup' },
  { label: 'Boyany', value: 'Boyany' },
  { label: 'Boyard-Ville', value: 'Boyard-Ville' },
  { label: 'Boyarka', value: 'Boyarka' },
  { label: 'Boyceville', value: 'Boyceville' },
  { label: 'Boychinovtsi', value: 'Boychinovtsi' },
  { label: 'Boyd', value: 'Boyd' },
  { label: 'Boyd County', value: 'Boyd County' },
  { label: 'Boyd County', value: 'Boyd County' },
  { label: 'Boydton', value: 'Boydton' },
  { label: 'Boyé', value: 'Boyé' },
  { label: 'Boyeros', value: 'Boyeros' },
  { label: 'Boyertown', value: 'Boyertown' },
  { label: 'Boyes Hot Springs', value: 'Boyes Hot Springs' },
  { label: 'Boyette', value: 'Boyette' },
  { label: 'Boykivske', value: 'Boykivske' },
  { label: 'Boykivske Raion', value: 'Boykivske Raion' },
  { label: 'Boyle', value: 'Boyle' },
  { label: 'Boyle County', value: 'Boyle County' },
  { label: 'Boyle Heights', value: 'Boyle Heights' },
  { label: 'Boylston', value: 'Boylston' },
  { label: 'Boyne City', value: 'Boyne City' },
  { label: 'Boyne Island', value: 'Boyne Island' },
  { label: 'Boynes', value: 'Boynes' },
  { label: 'Boynitsa', value: 'Boynitsa' },
  { label: 'Boynton Beach', value: 'Boynton Beach' },
  { label: 'Boyo', value: 'Boyo' },
  { label: 'Boyolali', value: 'Boyolali' },
  { label: 'Boyolangu', value: 'Boyolangu' },
  { label: 'Boysun', value: 'Boysun' },
  { label: 'Boyuibe', value: 'Boyuibe' },
  { label: 'Böyük Qaramurad', value: 'Böyük Qaramurad' },
  { label: 'Boyukabad', value: 'Boyukabad' },
  { label: 'Boyup Brook', value: 'Boyup Brook' },
  { label: 'Bozano', value: 'Bozano' },
  { label: 'Bozânta Mare', value: 'Bozânta Mare' },
  { label: 'Bozânta Mică', value: 'Bozânta Mică' },
  { label: 'Bozcaada', value: 'Bozcaada' },
  { label: 'Bozchalu', value: 'Bozchalu' },
  { label: 'Bozdar Wada', value: 'Bozdar Wada' },
  { label: 'Bozdoğan', value: 'Bozdoğan' },
  { label: 'Bozeat', value: 'Bozeat' },
  { label: 'Bozed', value: 'Bozed' },
  { label: 'Bozel', value: 'Bozel' },
  { label: 'Bozeman', value: 'Bozeman' },
  { label: 'Bozeni', value: 'Bozeni' },
  { label: 'Bozhou', value: 'Bozhou' },
  { label: 'Bozhou', value: 'Bozhou' },
  { label: 'Bozhurishte', value: 'Bozhurishte' },
  { label: 'Božice', value: 'Božice' },
  { label: 'Bozieni', value: 'Bozieni' },
  { label: 'Bozineh Jerd', value: 'Bozineh Jerd' },
  { label: 'Bozioru', value: 'Bozioru' },
  { label: 'Bozkır', value: 'Bozkır' },
  { label: 'Bozkow', value: 'Bozkow' },
  { label: 'Bozkurt', value: 'Bozkurt' },
  { label: 'Bozkurt', value: 'Bozkurt' },
  { label: 'Bozoó', value: 'Bozoó' },
  { label: 'Bozouls', value: 'Bozouls' },
  { label: 'Bozoum', value: 'Bozoum' },
  { label: 'Bozova', value: 'Bozova' },
  { label: 'Bozovici', value: 'Bozovici' },
  { label: 'Boztepe', value: 'Boztepe' },
  { label: 'Bozüyük', value: 'Bozüyük' },
  { label: 'Bozyazı', value: 'Bozyazı' },
  { label: 'Bozzole', value: 'Bozzole' },
  { label: 'Bozzolo', value: 'Bozzolo' },
  { label: 'Bra', value: 'Bra' },
  { label: 'Braås', value: 'Braås' },
  { label: 'Brabander', value: 'Brabander' },
  { label: 'Brabeți', value: 'Brabeți' },
  { label: 'Brabham', value: 'Brabham' },
  { label: 'Brabos', value: 'Brabos' },
  { label: 'Brabova', value: 'Brabova' },
  { label: 'Bracca', value: 'Bracca' },
  { label: 'Braccagni', value: 'Braccagni' },
  { label: 'Bracciano', value: 'Bracciano' },
  { label: 'Bracebridge', value: 'Bracebridge' },
  { label: 'Bracebridge Heath', value: 'Bracebridge Heath' },
  { label: 'Bracey', value: 'Bracey' },
  { label: 'Brachbach', value: 'Brachbach' },
  { label: 'Brachterbeek', value: 'Brachterbeek' },
  { label: 'Bracieux', value: 'Bracieux' },
  { label: 'Bracigliano', value: 'Bracigliano' },
  { label: 'Brackel', value: 'Brackel' },
  { label: 'Bracken County', value: 'Bracken County' },
  { label: 'Bracken Ridge', value: 'Bracken Ridge' },
  { label: 'Brackenheim', value: 'Brackenheim' },
  { label: 'Brackenridge', value: 'Brackenridge' },
  { label: 'Brackettville', value: 'Brackettville' },
  { label: 'Brackley', value: 'Brackley' },
  { label: 'Bracknell', value: 'Bracknell' },
  { label: 'Bracknell Forest', value: 'Bracknell Forest' },
  { label: 'Braço Do Norte', value: 'Braço Do Norte' },
  { label: 'Braço Do Trombudo', value: 'Braço Do Trombudo' },
  { label: 'Brad', value: 'Brad' },
  { label: 'Bradbury', value: 'Bradbury' },
  { label: 'Bradbury', value: 'Bradbury' },
  { label: 'Braddock', value: 'Braddock' },
  { label: 'Braddock Heights', value: 'Braddock Heights' },
  { label: 'Braddock Hills', value: 'Braddock Hills' },
  { label: 'Braddon', value: 'Braddon' },
  { label: 'Brădeanu', value: 'Brădeanu' },
  { label: 'Brădeni', value: 'Brădeni' },
  { label: 'Bradenton', value: 'Bradenton' },
  { label: 'Bradenton Beach', value: 'Bradenton Beach' },
  { label: 'Brădeşti', value: 'Brădeşti' },
  { label: 'Brădeşti', value: 'Brădeşti' },
  { label: 'Brădețelu', value: 'Brădețelu' },
  { label: 'Bradfield', value: 'Bradfield' },
  { label: 'Bradford', value: 'Bradford' },
  { label: 'Bradford', value: 'Bradford' },
  { label: 'Bradford', value: 'Bradford' },
  { label: 'Bradford', value: 'Bradford' },
  { label: 'Bradford', value: 'Bradford' },
  { label: 'Bradford', value: 'Bradford' },
  { label: 'Bradford County', value: 'Bradford County' },
  { label: 'Bradford County', value: 'Bradford County' },
  { label: 'Bradford Woods', value: 'Bradford Woods' },
  { label: 'Bradford-On-Avon', value: 'Bradford-On-Avon' },
  { label: 'Brădicești', value: 'Brădicești' },
  { label: 'Brading', value: 'Brading' },
  { label: 'Bradley', value: 'Bradley' },
  { label: 'Bradley', value: 'Bradley' },
  { label: 'Bradley', value: 'Bradley' },
  { label: 'Bradley Beach', value: 'Bradley Beach' },
  { label: 'Bradley County', value: 'Bradley County' },
  { label: 'Bradley County', value: 'Bradley County' },
  { label: 'Bradley Cross', value: 'Bradley Cross' },
  { label: 'Bradley Gardens', value: 'Bradley Gardens' },
  { label: 'Bradner', value: 'Bradner' },
  { label: 'Bradninch', value: 'Bradninch' },
  { label: 'Bradu', value: 'Bradu' },
  { label: 'Bradu', value: 'Bradu' },
  { label: 'Brăduleţ', value: 'Brăduleţ' },
  { label: 'Brăduţ', value: 'Brăduţ' },
  { label: 'Bradwell', value: 'Bradwell' },
  { label: 'Brady', value: 'Brady' },
  { label: 'Brædstrup', value: 'Brædstrup' },
  { label: 'Braes River', value: 'Braes River' },
  { label: 'Brăeşti', value: 'Brăeşti' },
  { label: 'Brăeşti', value: 'Brăeşti' },
  { label: 'Brăeşti', value: 'Brăeşti' },
  { label: 'Braeton', value: 'Braeton' },
  { label: 'Bràfim', value: 'Bràfim' },
  { label: 'Braga', value: 'Braga' },
  { label: 'Braga', value: 'Braga' },
  { label: 'Bragadiru', value: 'Bragadiru' },
  { label: 'Bragadiru', value: 'Bragadiru' },
  { label: 'Bragança', value: 'Bragança' },
  { label: 'Bragança Municipality', value: 'Bragança Municipality' },
  { label: 'Bragança Paulista', value: 'Bragança Paulista' },
  { label: 'Braganey', value: 'Braganey' },
  { label: 'Braglia', value: 'Braglia' },
  { label: 'Braham', value: 'Braham' },
  { label: 'Brăhășești', value: 'Brăhășești' },
  { label: 'Brăhăşeştii De Sus', value: 'Brăhăşeştii De Sus' },
  { label: 'Brahin', value: 'Brahin' },
  { label: 'Brahinski Rayon', value: 'Brahinski Rayon' },
  { label: 'Brahma Lodge', value: 'Brahma Lodge' },
  { label: 'Brahmanbaria', value: 'Brahmanbaria' },
  { label: 'Brahmapur', value: 'Brahmapur' },
  { label: 'Brahmapuri', value: 'Brahmapuri' },
  { label: 'Brahmenau', value: 'Brahmenau' },
  { label: 'Brahojos De Medina', value: 'Brahojos De Medina' },
  { label: 'Braidwood', value: 'Braidwood' },
  { label: 'Braidwood', value: 'Braidwood' },
  { label: 'Braies', value: 'Braies' },
  { label: 'Brăiești', value: 'Brăiești' },
  { label: 'Brăila', value: 'Brăila' },
  { label: 'Brailiv', value: 'Brailiv' },
  { label: 'Braine', value: 'Braine' },
  { label: 'Braine-Lalleud', value: 'Braine-Lalleud' },
  { label: 'Braine-Le-Château', value: 'Braine-Le-Château' },
  { label: 'Braine-Le-Comte', value: 'Braine-Le-Comte' },
  { label: 'Brainerd', value: 'Brainerd' },
  { label: 'Brainerd', value: 'Brainerd' },
  { label: 'Brains', value: 'Brains' },
  { label: 'Brain-Sur-Allonnes', value: 'Brain-Sur-Allonnes' },
  { label: 'Braintree', value: 'Braintree' },
  { label: 'Braintree', value: 'Braintree' },
  { label: 'Braitling', value: 'Braitling' },
  { label: 'Braives', value: 'Braives' },
  { label: 'Brajarajnagar', value: 'Brajarajnagar' },
  { label: 'Brak', value: 'Brak' },
  { label: 'Brake (Unterweser)', value: 'Brake (Unterweser)' },
  { label: 'Brakel', value: 'Brakel' },
  { label: 'Brakel', value: 'Brakel' },
  { label: 'Brakkenstein', value: 'Brakkenstein' },
  { label: 'Bräkne-Hoby', value: 'Bräkne-Hoby' },
  { label: 'Brakpan', value: 'Brakpan' },
  { label: 'Brålanda', value: 'Brålanda' },
  { label: 'Bralin', value: 'Bralin' },
  { label: 'Braloştiţa', value: 'Braloştiţa' },
  { label: 'Bram', value: 'Bram' },
  { label: 'Bramberg Am Wildkogel', value: 'Bramberg Am Wildkogel' },
  { label: 'Brambleton', value: 'Brambleton' },
  { label: 'Bramford', value: 'Bramford' },
  { label: 'Bramhall', value: 'Bramhall' },
  { label: 'Bramham', value: 'Bramham' },
  { label: 'Bramhope', value: 'Bramhope' },
  { label: 'Bramley', value: 'Bramley' },
  { label: 'Bramming', value: 'Bramming' },
  { label: 'Brampton', value: 'Brampton' },
  { label: 'Brampton', value: 'Brampton' },
  { label: 'Brampton', value: 'Brampton' },
  { label: 'Bramsche', value: 'Bramsche' },
  { label: 'Bramstedt', value: 'Bramstedt' },
  { label: 'Bramsthan', value: 'Bramsthan' },
  { label: 'Bran', value: 'Bran' },
  { label: 'Braňany', value: 'Braňany' },
  { label: 'Branca', value: 'Branca' },
  { label: 'Brancaleone', value: 'Brancaleone' },
  { label: 'Brancaleone-Marina', value: 'Brancaleone-Marina' },
  { label: 'Brânceni', value: 'Brânceni' },
  { label: 'Branch', value: 'Branch' },
  { label: 'Branch County', value: 'Branch County' },
  { label: 'Brâncoveanca', value: 'Brâncoveanca' },
  { label: 'Brâncovenești', value: 'Brâncovenești' },
  { label: 'Brâncoveni', value: 'Brâncoveni' },
  { label: 'Brand', value: 'Brand' },
  { label: 'Brand', value: 'Brand' },
  { label: 'Brand', value: 'Brand' },
  { label: 'Brande', value: 'Brande' },
  { label: 'Brande-Hörnerkirchen', value: 'Brande-Hörnerkirchen' },
  { label: 'Brandenberg', value: 'Brandenberg' },
  { label: 'Brandenburg', value: 'Brandenburg' },
  { label: 'Brandenburg An Der Havel', value: 'Brandenburg An Der Havel' },
  { label: 'Brand-Erbisdorf', value: 'Brand-Erbisdorf' },
  { label: 'Brandérion', value: 'Brandérion' },
  { label: 'Brandermill', value: 'Brandermill' },
  { label: 'Brandesburton', value: 'Brandesburton' },
  { label: 'Brandfort', value: 'Brandfort' },
  { label: 'Brandico', value: 'Brandico' },
  { label: 'Brandis', value: 'Brandis' },
  { label: 'Brandizzo', value: 'Brandizzo' },
  { label: 'Brandln', value: 'Brandln' },
  { label: 'Brando', value: 'Brando' },
  { label: 'Brändö', value: 'Brändö' },
  { label: 'Brandoa', value: 'Brandoa' },
  { label: 'Brandon', value: 'Brandon' },
  { label: 'Brandon', value: 'Brandon' },
  { label: 'Brandon', value: 'Brandon' },
  { label: 'Brandon', value: 'Brandon' },
  { label: 'Brandon', value: 'Brandon' },
  { label: 'Brandon', value: 'Brandon' },
  { label: 'Brandon', value: 'Brandon' },
  { label: 'Brandon Hill', value: 'Brandon Hill' },
  { label: 'Brandon Hill', value: 'Brandon Hill' },
  { label: 'Brandon Hill', value: 'Brandon Hill' },
  { label: 'Brandshagen', value: 'Brandshagen' },
  { label: 'Brandvlei', value: 'Brandvlei' },
  { label: 'Brandýs Nad Labem-Stará Boleslav', value: 'Brandýs Nad Labem-Stará Boleslav' },
  { label: 'Brandýs Nad Orlicí', value: 'Brandýs Nad Orlicí' },
  { label: 'Brandýsek', value: 'Brandýsek' },
  { label: 'Brandywine', value: 'Brandywine' },
  { label: 'Brăneşti', value: 'Brăneşti' },
  { label: 'Brăneşti', value: 'Brăneşti' },
  { label: 'Brăneşti', value: 'Brăneşti' },
  { label: 'Braneț', value: 'Braneț' },
  { label: 'Branford', value: 'Branford' },
  { label: 'Branford Center', value: 'Branford Center' },
  { label: 'Branges', value: 'Branges' },
  { label: 'Branice', value: 'Branice' },
  { label: 'Braniewo', value: 'Braniewo' },
  { label: 'Braník', value: 'Braník' },
  { label: 'Brănişca', value: 'Brănişca' },
  { label: 'Brăniștari', value: 'Brăniștari' },
  { label: 'Braniştea', value: 'Braniştea' },
  { label: 'Braniştea', value: 'Braniştea' },
  { label: 'Braniştea', value: 'Braniştea' },
  { label: 'Braniștea', value: 'Braniștea' },
  { label: 'Braniștea', value: 'Braniștea' },
  { label: 'Branne', value: 'Branne' },
  { label: 'Brannenburg', value: 'Brannenburg' },
  { label: 'Brañosera', value: 'Brañosera' },
  { label: 'Branoux-Les-Taillades', value: 'Branoux-Les-Taillades' },
  { label: 'Branquinha', value: 'Branquinha' },
  { label: 'Bransgore', value: 'Bransgore' },
  { label: 'Brańsk', value: 'Brańsk' },
  { label: 'Branson', value: 'Branson' },
  { label: 'Branston', value: 'Branston' },
  { label: 'Brańszczyk', value: 'Brańszczyk' },
  { label: 'Brant', value: 'Brant' },
  { label: 'Brantford', value: 'Brantford' },
  { label: 'Brantice', value: 'Brantice' },
  { label: 'Brantley County', value: 'Brantley County' },
  { label: 'Brantôme', value: 'Brantôme' },
  { label: 'Branxton', value: 'Branxton' },
  { label: 'Branyan', value: 'Branyan' },
  { label: 'Branzi', value: 'Branzi' },
  { label: 'Braojos', value: 'Braojos' },
  { label: 'Braone', value: 'Braone' },
  { label: 'Bras', value: 'Bras' },
  { label: 'Brás Pires', value: 'Brás Pires' },
  { label: 'Braschwitz', value: 'Braschwitz' },
  { label: 'Braselton', value: 'Braselton' },
  { label: 'Brasil Novo', value: 'Brasil Novo' },
  { label: 'Brasilândia', value: 'Brasilândia' },
  { label: 'Brasilândia De Minas', value: 'Brasilândia De Minas' },
  { label: 'Brasilândia Do Sul', value: 'Brasilândia Do Sul' },
  { label: 'Brasilândia Do Tocantins', value: 'Brasilândia Do Tocantins' },
  { label: 'Brasiléia', value: 'Brasiléia' },
  { label: 'Brasileira', value: 'Brasileira' },
  { label: 'Brasília', value: 'Brasília' },
  { label: 'Brasília De Minas', value: 'Brasília De Minas' },
  { label: 'Braslaw', value: 'Braslaw' },
  { label: 'Braslawski Rayon', value: 'Braslawski Rayon' },
  { label: 'Brasles', value: 'Brasles' },
  { label: 'Braslovče', value: 'Braslovče' },
  { label: 'Brasnorte', value: 'Brasnorte' },
  { label: 'Braşov', value: 'Braşov' },
  { label: 'Brasovskiy Rayon', value: 'Brasovskiy Rayon' },
  { label: 'Brasparts', value: 'Brasparts' },
  { label: 'Brass Castle', value: 'Brass Castle' },
  { label: 'Brassac', value: 'Brassac' },
  { label: 'Brassac-Les-Mines', value: 'Brassac-Les-Mines' },
  { label: 'Brassall', value: 'Brassall' },
  { label: 'Brasschaat', value: 'Brasschaat' },
  { label: 'Brastad', value: 'Brastad' },
  { label: 'Brastavățu', value: 'Brastavățu' },
  { label: 'Břasy', value: 'Břasy' },
  { label: 'Brąszewice', value: 'Brąszewice' },
  { label: 'Bratca', value: 'Bratca' },
  { label: 'Brateiu', value: 'Brateiu' },
  { label: 'Bratenahl', value: 'Bratenahl' },
  { label: 'Brateş', value: 'Brateş' },
  { label: 'Brătești', value: 'Brătești' },
  { label: 'Brătești', value: 'Brătești' },
  { label: 'Brateyevo', value: 'Brateyevo' },
  { label: 'Brătila', value: 'Brătila' },
  { label: 'Bratislava', value: 'Bratislava' },
  { label: 'Bratislava - Vajnory', value: 'Bratislava - Vajnory' },
  { label: 'Bratovoești', value: 'Bratovoești' },
  { label: 'Bratovshchina', value: 'Bratovshchina' },
  { label: 'Bratsigovo', value: 'Bratsigovo' },
  { label: 'Bratsk', value: 'Bratsk' },
  { label: 'Bratskiy Rayon', value: 'Bratskiy Rayon' },
  { label: 'Bratskoye', value: 'Bratskoye' },
  { label: 'Bratslav', value: 'Bratslav' },
  { label: 'Brattleboro', value: 'Brattleboro' },
  { label: 'Bratton', value: 'Bratton' },
  { label: 'Brattvåg', value: 'Brattvåg' },
  { label: 'Braubach', value: 'Braubach' },
  { label: 'Braud-Et-Saint-Louis', value: 'Braud-Et-Saint-Louis' },
  { label: 'Braúna', value: 'Braúna' },
  { label: 'Braúnas', value: 'Braúnas' },
  { label: 'Braunau Am Inn', value: 'Braunau Am Inn' },
  { label: 'Braunau Neustadt', value: 'Braunau Neustadt' },
  { label: 'Brauneberg', value: 'Brauneberg' },
  { label: 'Braunfels', value: 'Braunfels' },
  { label: 'Braunlage', value: 'Braunlage' },
  { label: 'Bräunlingen', value: 'Bräunlingen' },
  { label: 'Braunsbach', value: 'Braunsbach' },
  { label: 'Braunsbedra', value: 'Braunsbedra' },
  { label: 'Braunschweig', value: 'Braunschweig' },
  { label: 'Braunston', value: 'Braunston' },
  { label: 'Braunton', value: 'Braunton' },
  { label: 'Bravo', value: 'Bravo' },
  { label: 'Brawley', value: 'Brawley' },
  { label: 'Brax', value: 'Brax' },
  { label: 'Brax', value: 'Brax' },
  { label: 'Braxton County', value: 'Braxton County' },
  { label: 'Bray', value: 'Bray' },
  { label: 'Bray', value: 'Bray' },
  { label: 'Bray Park', value: 'Bray Park' },
  { label: 'Braybrook', value: 'Braybrook' },
  { label: 'Bray-Dunes', value: 'Bray-Dunes' },
  { label: 'Bray-En-Val', value: 'Bray-En-Val' },
  { label: 'Bray-Sur-Seine', value: 'Bray-Sur-Seine' },
  { label: 'Bray-Sur-Somme', value: 'Bray-Sur-Somme' },
  { label: 'Brayton', value: 'Brayton' },
  { label: 'Brazabrantes', value: 'Brazabrantes' },
  { label: 'Brazacorta', value: 'Brazacorta' },
  { label: 'Brazatortas', value: 'Brazatortas' },
  { label: 'Brazey-En-Plaine', value: 'Brazey-En-Plaine' },
  { label: 'Brazi', value: 'Brazi' },
  { label: 'Brazii', value: 'Brazii' },
  { label: 'Brazii De Sus', value: 'Brazii De Sus' },
  { label: 'Brazil', value: 'Brazil' },
  { label: 'Brazópolis', value: 'Brazópolis' },
  { label: 'Brazoria', value: 'Brazoria' },
  { label: 'Brazoria County', value: 'Brazoria County' },
  { label: 'Brazos County', value: 'Brazos County' },
  { label: 'Brazuelo', value: 'Brazuelo' },
  { label: 'Brazzaville', value: 'Brazzaville' },
  { label: 'Brčko', value: 'Brčko' },
  { label: 'Brckovljani', value: 'Brckovljani' },
  { label: 'Brdarica', value: 'Brdarica' },
  { label: 'Brdovec', value: 'Brdovec' },
  { label: 'Brdów', value: 'Brdów' },
  { label: 'Brea', value: 'Brea' },
  { label: 'Brea De Aragón', value: 'Brea De Aragón' },
  { label: 'Brea De Tajo', value: 'Brea De Tajo' },
  { label: 'Breach Candy', value: 'Breach Candy' },
  { label: 'Break Oday', value: 'Break Oday' },
  { label: 'Breakeyville', value: 'Breakeyville' },
  { label: 'Breakfast Point', value: 'Breakfast Point' },
  { label: 'Bréal-Sous-Montfort', value: 'Bréal-Sous-Montfort' },
  { label: 'Bream', value: 'Bream' },
  { label: 'Breasta', value: 'Breasta' },
  { label: 'Breastworks', value: 'Breastworks' },
  { label: 'Breathitt County', value: 'Breathitt County' },
  { label: 'Bréauté', value: 'Bréauté' },
  { label: 'Breaux Bridge', value: 'Breaux Bridge' },
  { label: 'Breaza', value: 'Breaza' },
  { label: 'Breaza', value: 'Breaza' },
  { label: 'Breaza', value: 'Breaza' },
  { label: 'Breaza', value: 'Breaza' },
  { label: 'Breaza', value: 'Breaza' },
  { label: 'Breaza De Jos', value: 'Breaza De Jos' },
  { label: 'Breaza De Sus', value: 'Breaza De Sus' },
  { label: 'Breazu', value: 'Breazu' },
  { label: 'Breb', value: 'Breb' },
  { label: 'Brebbia', value: 'Brebbia' },
  { label: 'Brebeni', value: 'Brebeni' },
  { label: 'Brebeni', value: 'Brebeni' },
  { label: 'Brebières', value: 'Brebières' },
  { label: 'Brebu', value: 'Brebu' },
  { label: 'Brebu Mânăstirei', value: 'Brebu Mânăstirei' },
  { label: 'Brebu Megieșesc', value: 'Brebu Megieșesc' },
  { label: 'Brebu Nou', value: 'Brebu Nou' },
  { label: 'Brécé', value: 'Brécé' },
  { label: 'Brécey', value: 'Brécey' },
  { label: 'Brech', value: 'Brech' },
  { label: 'Brechin', value: 'Brechin' },
  { label: 'Brecht', value: 'Brecht' },
  { label: 'Breckenridge', value: 'Breckenridge' },
  { label: 'Breckenridge', value: 'Breckenridge' },
  { label: 'Breckenridge', value: 'Breckenridge' },
  { label: 'Breckenridge', value: 'Breckenridge' },
  { label: 'Breckenridge Hills', value: 'Breckenridge Hills' },
  { label: 'Breckerfeld', value: 'Breckerfeld' },
  { label: 'Breckinridge Center', value: 'Breckinridge Center' },
  { label: 'Breckinridge County', value: 'Breckinridge County' },
  { label: 'Brecksville', value: 'Brecksville' },
  { label: 'Břeclav', value: 'Břeclav' },
  { label: 'Brecon', value: 'Brecon' },
  { label: 'Breda', value: 'Breda' },
  { label: 'Breda', value: 'Breda' },
  { label: 'Breda', value: 'Breda' },
  { label: 'Bredaryd', value: 'Bredaryd' },
  { label: 'Bredasdorp', value: 'Bredasdorp' },
  { label: 'Bredbury', value: 'Bredbury' },
  { label: 'Bredbyn', value: 'Bredbyn' },
  { label: 'Breddin', value: 'Breddin' },
  { label: 'Breddorf', value: 'Breddorf' },
  { label: 'Bredenbek', value: 'Bredenbek' },
  { label: 'Bredene', value: 'Bredene' },
  { label: 'Bredevoort', value: 'Bredevoort' },
  { label: 'Bredeweg', value: 'Bredeweg' },
  { label: 'Bredon', value: 'Bredon' },
  { label: 'Bredstedt', value: 'Bredstedt' },
  { label: 'Bredy', value: 'Bredy' },
  { label: 'Bree', value: 'Bree' },
  { label: 'Breese', value: 'Breese' },
  { label: 'Breese', value: 'Breese' },
  { label: 'Breezand', value: 'Breezand' },
  { label: 'Breezy Point', value: 'Breezy Point' },
  { label: 'Bregana', value: 'Bregana' },
  { label: 'Bregano', value: 'Bregano' },
  { label: 'Breganze', value: 'Breganze' },
  { label: 'Bregenz', value: 'Bregenz' },
  { label: 'Bregnano', value: 'Bregnano' },
  { label: 'Bregovo', value: 'Bregovo' },
  { label: 'Bregtdorp', value: 'Bregtdorp' },
  { label: 'Breguzzo', value: 'Breguzzo' },
  { label: 'Bréhal', value: 'Bréhal' },
  { label: 'Bréhand', value: 'Bréhand' },
  { label: 'Brehme', value: 'Brehme' },
  { label: 'Brehna', value: 'Brehna' },
  { label: 'Brehuiești', value: 'Brehuiești' },
  { label: 'Breia', value: 'Breia' },
  { label: 'Breiðdalshreppur', value: 'Breiðdalshreppur' },
  { label: 'Breidenbach', value: 'Breidenbach' },
  { label: 'Breiholz', value: 'Breiholz' },
  { label: 'Breíl', value: 'Breíl' },
  { label: 'Breil-Sur-Roya', value: 'Breil-Sur-Roya' },
  { label: 'Breinigsville', value: 'Breinigsville' },
  { label: 'Breisach Am Rhein', value: 'Breisach Am Rhein' },
  { label: 'Breitbrunn', value: 'Breitbrunn' },
  { label: 'Breitenau', value: 'Breitenau' },
  { label: 'Breitenbach', value: 'Breitenbach' },
  { label: 'Breitenbach', value: 'Breitenbach' },
  { label: 'Breitenbach', value: 'Breitenbach' },
  { label: 'Breitenbach Am Inn', value: 'Breitenbach Am Inn' },
  { label: 'Breitenberg', value: 'Breitenberg' },
  { label: 'Breitenbrunn', value: 'Breitenbrunn' },
  { label: 'Breitenbrunn', value: 'Breitenbrunn' },
  { label: 'Breitenbrunn', value: 'Breitenbrunn' },
  { label: 'Breitenburg', value: 'Breitenburg' },
  { label: 'Breitenfeld Am Tannenriegel', value: 'Breitenfeld Am Tannenriegel' },
  { label: 'Breitenfeld An Der Rittschein', value: 'Breitenfeld An Der Rittschein' },
  { label: 'Breitenfelde', value: 'Breitenfelde' },
  { label: 'Breitenfurt Bei Wien', value: 'Breitenfurt Bei Wien' },
  { label: 'Breitengüßbach', value: 'Breitengüßbach' },
  { label: 'Breitenthal', value: 'Breitenthal' },
  { label: 'Breitenwang', value: 'Breitenwang' },
  { label: 'Breitenworbis', value: 'Breitenworbis' },
  { label: 'Breitnau', value: 'Breitnau' },
  { label: 'Breitscheid', value: 'Breitscheid' },
  { label: 'Breitscheidt', value: 'Breitscheidt' },
  { label: 'Breitungen', value: 'Breitungen' },
  { label: 'Brejão', value: 'Brejão' },
  { label: 'Brejetuba', value: 'Brejetuba' },
  { label: 'Brejinho', value: 'Brejinho' },
  { label: 'Brejinho', value: 'Brejinho' },
  { label: 'Brejinho De Nazaré', value: 'Brejinho De Nazaré' },
  { label: 'Brejning', value: 'Brejning' },
  { label: 'Brejo', value: 'Brejo' },
  { label: 'Brejo Alegre', value: 'Brejo Alegre' },
  { label: 'Brejo Da Madre De Deus', value: 'Brejo Da Madre De Deus' },
  { label: 'Brejo De Areia', value: 'Brejo De Areia' },
  { label: 'Brejo Do Cruz', value: 'Brejo Do Cruz' },
  { label: 'Brejo Do Piauí', value: 'Brejo Do Piauí' },
  { label: 'Brejo Dos Santos', value: 'Brejo Dos Santos' },
  { label: 'Brejo Grande', value: 'Brejo Grande' },
  { label: 'Brejo Grande Do Araguaia', value: 'Brejo Grande Do Araguaia' },
  { label: 'Brejo Santo', value: 'Brejo Santo' },
  { label: 'Brejões', value: 'Brejões' },
  { label: 'Brejolândia', value: 'Brejolândia' },
  { label: 'Brekendorf', value: 'Brekendorf' },
  { label: 'Breklum', value: 'Breklum' },
  { label: 'Brekstad', value: 'Brekstad' },
  { label: 'Brela', value: 'Brela' },
  { label: 'Bremanger', value: 'Bremanger' },
  { label: 'Brembate', value: 'Brembate' },
  { label: 'Brembate Di Sopra', value: 'Brembate Di Sopra' },
  { label: 'Brembilla', value: 'Brembilla' },
  { label: 'Brembio', value: 'Brembio' },
  { label: 'Breme', value: 'Breme' },
  { label: 'Bremen', value: 'Bremen' },
  { label: 'Bremen', value: 'Bremen' },
  { label: 'Bremen', value: 'Bremen' },
  { label: 'Bremen', value: 'Bremen' },
  { label: 'Bremer County', value: 'Bremer County' },
  { label: 'Bremerhaven', value: 'Bremerhaven' },
  { label: 'Bremerton', value: 'Bremerton' },
  { label: 'Bremervörde', value: 'Bremervörde' },
  { label: 'Brêmes', value: 'Brêmes' },
  { label: 'Bremgarten', value: 'Bremgarten' },
  { label: 'Breña Alta', value: 'Breña Alta' },
  { label: 'Breña Baja', value: 'Breña Baja' },
  { label: 'Brenas', value: 'Brenas' },
  { label: 'Brenchley', value: 'Brenchley' },
  { label: 'Brendale', value: 'Brendale' },
  { label: 'Brenderup', value: 'Brenderup' },
  { label: 'Brendola', value: 'Brendola' },
  { label: 'Brenes', value: 'Brenes' },
  { label: 'Brenham', value: 'Brenham' },
  { label: 'Brenna', value: 'Brenna' },
  { label: 'Brenna', value: 'Brenna' },
  { label: 'Brennberg', value: 'Brennberg' },
  { label: 'Brennero - Brenner', value: 'Brennero - Brenner' },
  { label: 'Brenno', value: 'Brenno' },
  { label: 'Brenno Useria', value: 'Brenno Useria' },
  { label: 'Breno', value: 'Breno' },
  { label: 'Breñón', value: 'Breñón' },
  { label: 'Brenouille', value: 'Brenouille' },
  { label: 'Brens', value: 'Brens' },
  { label: 'Brensbach', value: 'Brensbach' },
  { label: 'Brent', value: 'Brent' },
  { label: 'Brent', value: 'Brent' },
  { label: 'Brent', value: 'Brent' },
  { label: 'Brent Knoll', value: 'Brent Knoll' },
  { label: 'Brenta', value: 'Brenta' },
  { label: 'Brentford', value: 'Brentford' },
  { label: 'Brentino Belluno', value: 'Brentino Belluno' },
  { label: 'Brentonico', value: 'Brentonico' },
  { label: 'Brentwood', value: 'Brentwood' },
  { label: 'Brentwood', value: 'Brentwood' },
  { label: 'Brentwood', value: 'Brentwood' },
  { label: 'Brentwood', value: 'Brentwood' },
  { label: 'Brentwood', value: 'Brentwood' },
  { label: 'Brentwood', value: 'Brentwood' },
  { label: 'Brentwood', value: 'Brentwood' },
  { label: 'Brentwood', value: 'Brentwood' },
  { label: 'Brentwood', value: 'Brentwood' },
  { label: 'Brentwood Estates', value: 'Brentwood Estates' },
  { label: 'Brenzone', value: 'Brenzone' },
  { label: 'Breo', value: 'Breo' },
  { label: 'Brescello', value: 'Brescello' },
  { label: 'Brescia', value: 'Brescia' },
  { label: 'Bresimo', value: 'Bresimo' },
  { label: 'Breskens', value: 'Breskens' },
  { label: 'Bresles', value: 'Bresles' },
  { label: 'Bressa', value: 'Bressa' },
  { label: 'Bressana', value: 'Bressana' },
  { label: 'Bressanone', value: 'Bressanone' },
  { label: 'Bressanvido', value: 'Bressanvido' },
  { label: 'Bressler', value: 'Bressler' },
  { label: 'Bresso', value: 'Bresso' },
  { label: 'Bressols', value: 'Bressols' },
  { label: 'Bressuire', value: 'Bressuire' },
  { label: 'Brest', value: 'Brest' },
  { label: 'Brest', value: 'Brest' },
  { label: 'Bresternica', value: 'Bresternica' },
  { label: 'Brestovac', value: 'Brestovac' },
  { label: 'Brestovăț', value: 'Brestovăț' },
  { label: 'Brestski Rayon', value: 'Brestski Rayon' },
  { label: 'Bret Harte', value: 'Bret Harte' },
  { label: 'Breţcu', value: 'Breţcu' },
  { label: 'Bretea Română', value: 'Bretea Română' },
  { label: 'Breteil', value: 'Breteil' },
  { label: 'Bretenoux', value: 'Bretenoux' },
  { label: 'Breteuil', value: 'Breteuil' },
  { label: 'Breteuil', value: 'Breteuil' },
  { label: 'Bretignolles-Sur-Mer', value: 'Bretignolles-Sur-Mer' },
  { label: 'Brétigny-Sur-Orge', value: 'Brétigny-Sur-Orge' },
  { label: 'Bretó', value: 'Bretó' },
  { label: 'Bretocino', value: 'Bretocino' },
  { label: 'Bretoncelles', value: 'Bretoncelles' },
  { label: 'Bretstein', value: 'Bretstein' },
  { label: 'Brette-Les-Pins', value: 'Brette-Les-Pins' },
  { label: 'Bretten', value: 'Bretten' },
  { label: 'Bretteville', value: 'Bretteville' },
  { label: 'Bretteville-Du-Grand-Caux', value: 'Bretteville-Du-Grand-Caux' },
  { label: 'Bretteville-L’Orgueilleuse', value: 'Bretteville-L’Orgueilleuse' },
  { label: 'Bretteville-Sur-Laize', value: 'Bretteville-Sur-Laize' },
  { label: 'Bretteville-Sur-Odon', value: 'Bretteville-Sur-Odon' },
  { label: 'Bretzenheim', value: 'Bretzenheim' },
  { label: 'Bretzfeld', value: 'Bretzfeld' },
  { label: 'Breu Branco', value: 'Breu Branco' },
  { label: 'Breugel', value: 'Breugel' },
  { label: 'Breuil-Le-Sec', value: 'Breuil-Le-Sec' },
  { label: 'Breuillet', value: 'Breuillet' },
  { label: 'Breuillet', value: 'Breuillet' },
  { label: 'Breuil-Le-Vert', value: 'Breuil-Le-Vert' },
  { label: 'Breuil-Magné', value: 'Breuil-Magné' },
  { label: 'Breuilpont', value: 'Breuilpont' },
  { label: 'Breukelen', value: 'Breukelen' },
  { label: 'Breukeleveen', value: 'Breukeleveen' },
  { label: 'Breuna', value: 'Breuna' },
  { label: 'Breuschwickersheim', value: 'Breuschwickersheim' },
  { label: 'Bréval', value: 'Bréval' },
  { label: 'Brevard', value: 'Brevard' },
  { label: 'Brevard County', value: 'Brevard County' },
  { label: 'Breves', value: 'Breves' },
  { label: 'Bréviandes', value: 'Bréviandes' },
  { label: 'Brevik', value: 'Brevik' },
  { label: 'Brewarrina', value: 'Brewarrina' },
  { label: 'Brewer', value: 'Brewer' },
  { label: 'Brewerton', value: 'Brewerton' },
  { label: 'Brewood', value: 'Brewood' },
  { label: 'Brewster', value: 'Brewster' },
  { label: 'Brewster', value: 'Brewster' },
  { label: 'Brewster', value: 'Brewster' },
  { label: 'Brewster', value: 'Brewster' },
  { label: 'Brewster', value: 'Brewster' },
  { label: 'Brewster County', value: 'Brewster County' },
  { label: 'Brewster Hill', value: 'Brewster Hill' },
  { label: 'Brewton', value: 'Brewton' },
  { label: 'Brey', value: 'Brey' },
  { label: 'Breyten', value: 'Breyten' },
  { label: 'Breytovo', value: 'Breytovo' },
  { label: 'Breytovskiy Rayon', value: 'Breytovskiy Rayon' },
  { label: 'Brez', value: 'Brez' },
  { label: 'Breza', value: 'Breza' },
  { label: 'Brézé', value: 'Brézé' },
  { label: 'Březí', value: 'Březí' },
  { label: 'Brežice', value: 'Brežice' },
  { label: 'Brezina', value: 'Brezina' },
  { label: 'Brézins', value: 'Brézins' },
  { label: 'Breznica', value: 'Breznica' },
  { label: 'Březnice', value: 'Březnice' },
  { label: 'Březnice', value: 'Březnice' },
  { label: 'Breznički Hum', value: 'Breznički Hum' },
  { label: 'Breznik', value: 'Breznik' },
  { label: 'Brezniţa Ocol', value: 'Brezniţa Ocol' },
  { label: 'Brezniţa-Motru', value: 'Brezniţa-Motru' },
  { label: 'Brezno', value: 'Brezno' },
  { label: 'Březno', value: 'Březno' },
  { label: 'Brezoaele', value: 'Brezoaele' },
  { label: 'Brezoaia', value: 'Brezoaia' },
  { label: 'Brezoi', value: 'Brezoi' },
  { label: 'Brezolles', value: 'Brezolles' },
  { label: 'Březolupy', value: 'Březolupy' },
  { label: 'Březová', value: 'Březová' },
  { label: 'Březová', value: 'Březová' },
  { label: 'Březová', value: 'Březová' },
  { label: 'Březová Nad Svitavou', value: 'Březová Nad Svitavou' },
  { label: 'Brezová Pod Bradlom', value: 'Brezová Pod Bradlom' },
  { label: 'Brezovac', value: 'Brezovac' },
  { label: 'Brezovica', value: 'Brezovica' },
  { label: 'Brezovica Pri Ljubljani', value: 'Brezovica Pri Ljubljani' },
  { label: 'Brezovo', value: 'Brezovo' },
  { label: 'Brezza', value: 'Brezza' },
  { label: 'Brezzo Di Bedero', value: 'Brezzo Di Bedero' },
  { label: 'Brgy. Bachaw Norte Kalibo', value: 'Brgy. Bachaw Norte Kalibo' },
  { label: 'Brgy. Bulwang Numancia', value: 'Brgy. Bulwang Numancia' },
  { label: 'Brgy. Mabilo New Washington', value: 'Brgy. Mabilo New Washington' },
  { label: 'Brgy. Nalook Kalibo', value: 'Brgy. Nalook Kalibo' },
  { label: 'Brgy. New Buswang Kalibo', value: 'Brgy. New Buswang Kalibo' },
  { label: 'Brgy. Tinigao Kalibo', value: 'Brgy. Tinigao Kalibo' },
  { label: 'Bria', value: 'Bria' },
  { label: 'Briaglia', value: 'Briaglia' },
  { label: 'Briagolong', value: 'Briagolong' },
  { label: 'Briançon', value: 'Briançon' },
  { label: 'Briar', value: 'Briar' },
  { label: 'Briar Hill', value: 'Briar Hill' },
  { label: 'Briarcliff', value: 'Briarcliff' },
  { label: 'Briarcliff Manor', value: 'Briarcliff Manor' },
  { label: 'Briare', value: 'Briare' },
  { label: 'Briarwood', value: 'Briarwood' },
  { label: 'Briatexte', value: 'Briatexte' },
  { label: 'Briatico', value: 'Briatico' },
  { label: 'Bribir', value: 'Bribir' },
  { label: 'Bricco Di Neive', value: 'Bricco Di Neive' },
  { label: 'Brice Prairie', value: 'Brice Prairie' },
  { label: 'Briceni', value: 'Briceni' },
  { label: 'Briceni', value: 'Briceni' },
  { label: 'Briceño', value: 'Briceño' },
  { label: 'Briceño', value: 'Briceño' },
  { label: 'Brices Creek', value: 'Brices Creek' },
  { label: 'Bricherasio', value: 'Bricherasio' },
  { label: 'Brickerville', value: 'Brickerville' },
  { label: 'Bricquebec', value: 'Bricquebec' },
  { label: 'Bricy', value: 'Bricy' },
  { label: 'Bridel', value: 'Bridel' },
  { label: 'Bridge', value: 'Bridge' },
  { label: 'Bridge City', value: 'Bridge City' },
  { label: 'Bridge City', value: 'Bridge City' },
  { label: 'Bridge Of Allan', value: 'Bridge Of Allan' },
  { label: 'Bridge Of Earn', value: 'Bridge Of Earn' },
  { label: 'Bridge Of Weir', value: 'Bridge Of Weir' },
  { label: 'Bridgehampton', value: 'Bridgehampton' },
  { label: 'Bridgeman Downs', value: 'Bridgeman Downs' },
  { label: 'Bridgend', value: 'Bridgend' },
  { label: 'Bridgend County Borough', value: 'Bridgend County Borough' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeport', value: 'Bridgeport' },
  { label: 'Bridgeton', value: 'Bridgeton' },
  { label: 'Bridgeton', value: 'Bridgeton' },
  { label: 'Bridgetown', value: 'Bridgetown' },
  { label: 'Bridgetown', value: 'Bridgetown' },
  { label: 'Bridgetown', value: 'Bridgetown' },
  { label: 'Bridgetown-Greenbushes', value: 'Bridgetown-Greenbushes' },
  { label: 'Bridgeview', value: 'Bridgeview' },
  { label: 'Bridgeville', value: 'Bridgeville' },
  { label: 'Bridgeville', value: 'Bridgeville' },
  { label: 'Bridgewater', value: 'Bridgewater' },
  { label: 'Bridgewater', value: 'Bridgewater' },
  { label: 'Bridgewater', value: 'Bridgewater' },
  { label: 'Bridgewater', value: 'Bridgewater' },
  { label: 'Bridgewater', value: 'Bridgewater' },
  { label: 'Bridgewater', value: 'Bridgewater' },
  { label: 'Bridgewater', value: 'Bridgewater' },
  { label: 'Bridgman', value: 'Bridgman' },
  { label: 'Bridgnorth', value: 'Bridgnorth' },
  { label: 'Bridgton', value: 'Bridgton' },
  { label: 'Bridgwater', value: 'Bridgwater' },
  { label: 'Břidličná', value: 'Břidličná' },
  { label: 'Bridlington', value: 'Bridlington' },
  { label: 'Bridport', value: 'Bridport' },
  { label: 'Bridport', value: 'Bridport' },
  { label: 'Bridport', value: 'Bridport' },
  { label: 'Brie', value: 'Brie' },
  { label: 'Briec', value: 'Briec' },
  { label: 'Brie-Comte-Robert', value: 'Brie-Comte-Robert' },
  { label: 'Briedel', value: 'Briedel' },
  { label: 'Brié-Et-Angonnes', value: 'Brié-Et-Angonnes' },
  { label: 'Brielle', value: 'Brielle' },
  { label: 'Brielle', value: 'Brielle' },
  { label: 'Brienne-Le-Château', value: 'Brienne-Le-Château' },
  { label: 'Brienno', value: 'Brienno' },
  { label: 'Briennon', value: 'Briennon' },
  { label: 'Brienon-Sur-Armançon', value: 'Brienon-Sur-Armançon' },
  { label: 'Brienz', value: 'Brienz' },
  { label: 'Brienza', value: 'Brienza' },
  { label: 'Brier', value: 'Brier' },
  { label: 'Brierfield', value: 'Brierfield' },
  { label: 'Brierley Hill', value: 'Brierley Hill' },
  { label: 'Brieselang', value: 'Brieselang' },
  { label: 'Briesen', value: 'Briesen' },
  { label: 'Brieskow-Finkenheerd', value: 'Brieskow-Finkenheerd' },
  { label: 'Brietlingen', value: 'Brietlingen' },
  { label: 'Brieva', value: 'Brieva' },
  { label: 'Brieva De Cameros', value: 'Brieva De Cameros' },
  { label: 'Briey', value: 'Briey' },
  { label: 'Brifu', value: 'Brifu' },
  { label: 'Brig', value: 'Brig' },
  { label: 'Brig District', value: 'Brig District' },
  { label: 'Briga Alta', value: 'Briga Alta' },
  { label: 'Briga Novarese', value: 'Briga Novarese' },
  { label: 'Brigantine', value: 'Brigantine' },
  { label: 'Brigg', value: 'Brigg' },
  { label: 'Brigham City', value: 'Brigham City' },
  { label: 'Brighouse', value: 'Brighouse' },
  { label: 'Brighstone', value: 'Brighstone' },
  { label: 'Bright', value: 'Bright' },
  { label: 'Bright', value: 'Bright' },
  { label: 'Brightlingsea', value: 'Brightlingsea' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton', value: 'Brighton' },
  { label: 'Brighton And Hove', value: 'Brighton And Hove' },
  { label: 'Brighton Beach', value: 'Brighton Beach' },
  { label: 'Brighton East', value: 'Brighton East' },
  { label: 'Brighton Park', value: 'Brighton Park' },
  { label: 'Brighton-Le-Sands', value: 'Brighton-Le-Sands' },
  { label: 'Brightons', value: 'Brightons' },
  { label: 'Brightwater', value: 'Brightwater' },
  { label: 'Brightwaters', value: 'Brightwaters' },
  { label: 'Brightwood', value: 'Brightwood' },
  { label: 'Brignais', value: 'Brignais' },
  { label: 'Brignano', value: 'Brignano' },
  { label: 'Brignano Gera Dadda', value: 'Brignano Gera Dadda' },
  { label: 'Brignoles', value: 'Brignoles' },
  { label: 'Brigstock', value: 'Brigstock' },
  { label: 'Brigueuil', value: 'Brigueuil' },
  { label: 'Brihuega', value: 'Brihuega' },
  { label: 'Briis-Sous-Forges', value: 'Briis-Sous-Forges' },
  { label: 'Brijest', value: 'Brijest' },
  { label: 'Brikama', value: 'Brikama' },
  { label: 'Brikama Nding', value: 'Brikama Nding' },
  { label: 'Brikcha', value: 'Brikcha' },
  { label: 'Brill', value: 'Brill' },
  { label: 'Brilliant', value: 'Brilliant' },
  { label: 'Brillion', value: 'Brillion' },
  { label: 'Brilon', value: 'Brilon' },
  { label: 'Brimbank', value: 'Brimbank' },
  { label: 'Brime De Sog', value: 'Brime De Sog' },
  { label: 'Brime De Urz', value: 'Brime De Urz' },
  { label: 'Brimfield', value: 'Brimfield' },
  { label: 'Brimfield', value: 'Brimfield' },
  { label: 'Brimscombe', value: 'Brimscombe' },
  { label: 'Briñas', value: 'Briñas' },
  { label: 'Brinckerhoff', value: 'Brinckerhoff' },
  { label: 'Brincones', value: 'Brincones' },
  { label: 'Brindas', value: 'Brindas' },
  { label: 'Brindisi', value: 'Brindisi' },
  { label: 'Brindisi Montagna', value: 'Brindisi Montagna' },
  { label: 'Bringelly', value: 'Bringelly' },
  { label: 'Brinje', value: 'Brinje' },
  { label: 'Brinkhorst', value: 'Brinkhorst' },
  { label: 'Brinkin', value: 'Brinkin' },
  { label: 'Brinkley', value: 'Brinkley' },
  { label: 'Brinklow', value: 'Brinklow' },
  { label: 'Brinkmann', value: 'Brinkmann' },
  { label: 'Brinon-Sur-Sauldre', value: 'Brinon-Sur-Sauldre' },
  { label: 'Brinscall', value: 'Brinscall' },
  { label: 'Brinsmead', value: 'Brinsmead' },
  { label: 'Brinzio', value: 'Brinzio' },
  { label: 'Briollay', value: 'Briollay' },
  { label: 'Brion', value: 'Brion' },
  { label: 'Brión', value: 'Brión' },
  { label: 'Briona', value: 'Briona' },
  { label: 'Brione', value: 'Brione' },
  { label: 'Brione', value: 'Brione' },
  { label: 'Briones', value: 'Briones' },
  { label: 'Brionne', value: 'Brionne' },
  { label: 'Briosco', value: 'Briosco' },
  { label: 'Brioude', value: 'Brioude' },
  { label: 'Brioux-Sur-Boutonne', value: 'Brioux-Sur-Boutonne' },
  { label: 'Briouze', value: 'Briouze' },
  { label: 'Brisas Barra De Suchiate', value: 'Brisas Barra De Suchiate' },
  { label: 'Brisas De La Navidad', value: 'Brisas De La Navidad' },
  { label: 'Brisas De Zicatela', value: 'Brisas De Zicatela' },
  { label: 'Brisas Del Carrizal', value: 'Brisas Del Carrizal' },
  { label: 'Brisbane', value: 'Brisbane' },
  { label: 'Brisbane', value: 'Brisbane' },
  { label: 'Briscoe County', value: 'Briscoe County' },
  { label: 'Briscous', value: 'Briscous' },
  { label: 'Brisée Verdière', value: 'Brisée Verdière' },
  { label: 'Briseñas', value: 'Briseñas' },
  { label: 'Briseñas De Matamoros', value: 'Briseñas De Matamoros' },
  { label: 'Brisighella', value: 'Brisighella' },
  { label: 'Brislach', value: 'Brislach' },
  { label: 'Brison-Saint-Innocent', value: 'Brison-Saint-Innocent' },
  { label: 'Brissac-Quincé', value: 'Brissac-Quincé' },
  { label: 'Brissago', value: 'Brissago' },
  { label: 'Brissago-Valtravaglia', value: 'Brissago-Valtravaglia' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol', value: 'Bristol' },
  { label: 'Bristol Bay Borough', value: 'Bristol Bay Borough' },
  { label: 'Bristol County', value: 'Bristol County' },
  { label: 'Bristol County', value: 'Bristol County' },
  { label: 'Briston', value: 'Briston' },
  { label: 'Bristow', value: 'Bristow' },
  { label: 'Britânia', value: 'Britânia' },
  { label: 'Britannia', value: 'Britannia' },
  { label: 'Brito', value: 'Brito' },
  { label: 'Britof', value: 'Britof' },
  { label: 'Briton Ferry', value: 'Briton Ferry' },
  { label: 'Brits', value: 'Brits' },
  { label: 'Britt', value: 'Britt' },
  { label: 'Brittany Farms-Highlands', value: 'Brittany Farms-Highlands' },
  { label: 'Britten', value: 'Britten' },
  { label: 'Brittnau', value: 'Brittnau' },
  { label: 'Brittoli', value: 'Brittoli' },
  { label: 'Britton', value: 'Britton' },
  { label: 'Britz', value: 'Britz' },
  { label: 'Britz', value: 'Britz' },
  { label: 'Brive-La-Gaillarde', value: 'Brive-La-Gaillarde' },
  { label: 'Brives-Charensac', value: 'Brives-Charensac' },
  { label: 'Briviesca', value: 'Briviesca' },
  { label: 'Brivio', value: 'Brivio' },
  { label: 'Brix', value: 'Brix' },
  { label: 'Brixen Im Thale', value: 'Brixen Im Thale' },
  { label: 'Brixham', value: 'Brixham' },
  { label: 'Brixlegg', value: 'Brixlegg' },
  { label: 'Brixton', value: 'Brixton' },
  { label: 'Brixton Hill', value: 'Brixton Hill' },
  { label: 'Brixton Hill', value: 'Brixton Hill' },
  { label: 'Brixworth', value: 'Brixworth' },
  { label: 'Brizuela', value: 'Brizuela' },
  { label: 'Brka', value: 'Brka' },
  { label: 'Brloh', value: 'Brloh' },
  { label: 'Brnaze', value: 'Brnaze' },
  { label: 'Brněnec', value: 'Brněnec' },
  { label: 'Brniště', value: 'Brniště' },
  { label: 'Brno', value: 'Brno' },
  { label: 'Bro', value: 'Bro' },
  { label: 'Broad Blunsdon', value: 'Broad Blunsdon' },
  { label: 'Broad Channel', value: 'Broad Channel' },
  { label: 'Broad Creek', value: 'Broad Creek' },
  { label: 'Broad Ripple', value: 'Broad Ripple' },
  { label: 'Broadalbin', value: 'Broadalbin' },
  { label: 'Broadbeach', value: 'Broadbeach' },
  { label: 'Broadbeach Waters', value: 'Broadbeach Waters' },
  { label: 'Broadfield', value: 'Broadfield' },
  { label: 'Broadford', value: 'Broadford' },
  { label: 'Broadgate', value: 'Broadgate' },
  { label: 'Broadlands', value: 'Broadlands' },
  { label: 'Broadmeadow', value: 'Broadmeadow' },
  { label: 'Broadmeadows', value: 'Broadmeadows' },
  { label: 'Broadmoor', value: 'Broadmoor' },
  { label: 'Broadstairs', value: 'Broadstairs' },
  { label: 'Broadstone', value: 'Broadstone' },
  { label: 'Broadus', value: 'Broadus' },
  { label: 'Broadview', value: 'Broadview' },
  { label: 'Broadview', value: 'Broadview' },
  { label: 'Broadview Heights', value: 'Broadview Heights' },
  { label: 'Broadview Park', value: 'Broadview Park' },
  { label: 'Broadwater', value: 'Broadwater' },
  { label: 'Broadwater', value: 'Broadwater' },
  { label: 'Broadwater County', value: 'Broadwater County' },
  { label: 'Broadway', value: 'Broadway' },
  { label: 'Broadway', value: 'Broadway' },
  { label: 'Broadway', value: 'Broadway' },
  { label: 'Broager', value: 'Broager' },
  { label: 'Broby', value: 'Broby' },
  { label: 'Broc', value: 'Broc' },
  { label: 'Broccostella', value: 'Broccostella' },
  { label: 'Brocēni', value: 'Brocēni' },
  { label: 'Brochier', value: 'Brochier' },
  { label: 'Brochów', value: 'Brochów' },
  { label: 'Brock Hall', value: 'Brock Hall' },
  { label: 'Brockel', value: 'Brockel' },
  { label: 'Brockenhurst', value: 'Brockenhurst' },
  { label: 'Brockley', value: 'Brockley' },
  { label: 'Brockman', value: 'Brockman' },
  { label: 'Brockport', value: 'Brockport' },
  { label: 'Brockton', value: 'Brockton' },
  { label: 'Brockum', value: 'Brockum' },
  { label: 'Brockville', value: 'Brockville' },
  { label: 'Brockway', value: 'Brockway' },
  { label: 'Brocton', value: 'Brocton' },
  { label: 'Brod', value: 'Brod' },
  { label: 'Brodarica', value: 'Brodarica' },
  { label: 'Brodek U Přerova', value: 'Brodek U Přerova' },
  { label: 'Broderstorf', value: 'Broderstorf' },
  { label: 'Brodhead', value: 'Brodhead' },
  { label: 'Brodhead', value: 'Brodhead' },
  { label: 'Brodheadsville', value: 'Brodheadsville' },
  { label: 'Brodina', value: 'Brodina' },
  { label: 'Brodła', value: 'Brodła' },
  { label: 'Brodnica', value: 'Brodnica' },
  { label: 'Brodnica', value: 'Brodnica' },
  { label: 'Brodoc', value: 'Brodoc' },
  { label: 'Brodokalmak', value: 'Brodokalmak' },
  { label: 'Brodósqui', value: 'Brodósqui' },
  { label: 'Brodowski', value: 'Brodowski' },
  { label: 'Brodski Varoš', value: 'Brodski Varoš' },
  { label: 'Brody', value: 'Brody' },
  { label: 'Brody', value: 'Brody' },
  { label: 'Brody', value: 'Brody' },
  { label: 'Brody', value: 'Brody' },
  { label: 'Brody-Parcele', value: 'Brody-Parcele' },
  { label: 'Broek In Waterland', value: 'Broek In Waterland' },
  { label: 'Broek Op Langedijk', value: 'Broek Op Langedijk' },
  { label: 'Broekhem', value: 'Broekhem' },
  { label: 'Broekhoven', value: 'Broekhoven' },
  { label: 'Broeksittard', value: 'Broeksittard' },
  { label: 'Broeksterwâld', value: 'Broeksterwâld' },
  { label: 'Brogden', value: 'Brogden' },
  { label: 'Brogliano', value: 'Brogliano' },
  { label: 'Broglie', value: 'Broglie' },
  { label: 'Brognaturo', value: 'Brognaturo' },
  { label: 'Brognoligo-Costalunga', value: 'Brognoligo-Costalunga' },
  { label: 'Brohl-Lützing', value: 'Brohl-Lützing' },
  { label: 'Brojce', value: 'Brojce' },
  { label: 'Brójce', value: 'Brójce' },
  { label: 'Brójce', value: 'Brójce' },
  { label: 'Brok', value: 'Brok' },
  { label: 'Brokdorf', value: 'Brokdorf' },
  { label: 'Broken Arrow', value: 'Broken Arrow' },
  { label: 'Broken Bow', value: 'Broken Bow' },
  { label: 'Broken Bow', value: 'Broken Bow' },
  { label: 'Broken Hill', value: 'Broken Hill' },
  { label: 'Broken Hill Municipality', value: 'Broken Hill Municipality' },
  { label: 'Brokopondo', value: 'Brokopondo' },
  { label: 'Brokstedt', value: 'Brokstedt' },
  { label: 'Brolo', value: 'Brolo' },
  { label: 'Brom Mayfa', value: 'Brom Mayfa' },
  { label: 'Bromberg', value: 'Bromberg' },
  { label: 'Bromborough', value: 'Bromborough' },
  { label: 'Brome', value: 'Brome' },
  { label: 'Bromham', value: 'Bromham' },
  { label: 'Bromma', value: 'Bromma' },
  { label: 'Bromölla', value: 'Bromölla' },
  { label: 'Bromont', value: 'Bromont' },
  { label: 'Brompton', value: 'Brompton' },
  { label: 'Brompton', value: 'Brompton' },
  { label: 'Brompton', value: 'Brompton' },
  { label: 'Bromsgrove', value: 'Bromsgrove' },
  { label: 'Bromskirchen', value: 'Bromskirchen' },
  { label: 'Bromyard', value: 'Bromyard' },
  { label: 'Bron', value: 'Bron' },
  { label: 'Bronchales', value: 'Bronchales' },
  { label: 'Brøndby Kommune', value: 'Brøndby Kommune' },
  { label: 'Brondello', value: 'Brondello' },
  { label: 'Brønderslev', value: 'Brønderslev' },
  { label: 'Brønderslev Kommune', value: 'Brønderslev Kommune' },
  { label: 'Brongio', value: 'Brongio' },
  { label: 'Broni', value: 'Broni' },
  { label: 'Broniki', value: 'Broniki' },
  { label: 'Broniszewice', value: 'Broniszewice' },
  { label: 'Bronitsia', value: 'Bronitsia' },
  { label: 'Bronitska Guta', value: 'Bronitska Guta' },
  { label: 'Bronkhorstspruit', value: 'Bronkhorstspruit' },
  { label: 'Bronnitsy', value: 'Bronnitsy' },
  { label: 'Brønnøy', value: 'Brønnøy' },
  { label: 'Brønnøysund', value: 'Brønnøysund' },
  { label: 'Bronów', value: 'Bronów' },
  { label: 'Bronschhofen', value: 'Bronschhofen' },
  { label: 'Bronson', value: 'Bronson' },
  { label: 'Bronson', value: 'Bronson' },
  { label: 'Bronte', value: 'Bronte' },
  { label: 'Bronte', value: 'Bronte' },
  { label: 'Bronx', value: 'Bronx' },
  { label: 'Bronxville', value: 'Bronxville' },
  { label: 'Bronzani Majdan', value: 'Bronzani Majdan' },
  { label: 'Bronzolo', value: 'Bronzolo' },
  { label: 'Brook Highland', value: 'Brook Highland' },
  { label: 'Brook Park', value: 'Brook Park' },
  { label: 'Brookdale', value: 'Brookdale' },
  { label: 'Brookdale', value: 'Brookdale' },
  { label: 'Brookdale', value: 'Brookdale' },
  { label: 'Brookdale', value: 'Brookdale' },
  { label: 'Brooke', value: 'Brooke' },
  { label: 'Brooke County', value: 'Brooke County' },
  { label: 'Brookfield', value: 'Brookfield' },
  { label: 'Brookfield', value: 'Brookfield' },
  { label: 'Brookfield', value: 'Brookfield' },
  { label: 'Brookfield', value: 'Brookfield' },
  { label: 'Brookfield', value: 'Brookfield' },
  { label: 'Brookfield Center', value: 'Brookfield Center' },
  { label: 'Brookhaven', value: 'Brookhaven' },
  { label: 'Brookhaven', value: 'Brookhaven' },
  { label: 'Brookhaven', value: 'Brookhaven' },
  { label: 'Brookhaven', value: 'Brookhaven' },
  { label: 'Brookhaven', value: 'Brookhaven' },
  { label: 'Brookings', value: 'Brookings' },
  { label: 'Brookings', value: 'Brookings' },
  { label: 'Brookings County', value: 'Brookings County' },
  { label: 'Brookland', value: 'Brookland' },
  { label: 'Brooklawn', value: 'Brooklawn' },
  { label: 'Brooklet', value: 'Brooklet' },
  { label: 'Brookline', value: 'Brookline' },
  { label: 'Brookline', value: 'Brookline' },
  { label: 'Brooklyn', value: 'Brooklyn' },
  { label: 'Brooklyn', value: 'Brooklyn' },
  { label: 'Brooklyn', value: 'Brooklyn' },
  { label: 'Brooklyn', value: 'Brooklyn' },
  { label: 'Brooklyn', value: 'Brooklyn' },
  { label: 'Brooklyn', value: 'Brooklyn' },
  { label: 'Brooklyn', value: 'Brooklyn' },
  { label: 'Brooklyn', value: 'Brooklyn' },
  { label: 'Brooklyn Center', value: 'Brooklyn Center' },
  { label: 'Brooklyn Heights', value: 'Brooklyn Heights' },
  { label: 'Brooklyn Heights', value: 'Brooklyn Heights' },
  { label: 'Brooklyn Park', value: 'Brooklyn Park' },
  { label: 'Brooklyn Park', value: 'Brooklyn Park' },
  { label: 'Brooklyn Park', value: 'Brooklyn Park' },
  { label: 'Brookmont', value: 'Brookmont' },
  { label: 'Brookneal', value: 'Brookneal' },
  { label: 'Brookridge', value: 'Brookridge' },
  { label: 'Brooks', value: 'Brooks' },
  { label: 'Brooks', value: 'Brooks' },
  { label: 'Brooks', value: 'Brooks' },
  { label: 'Brooks County', value: 'Brooks County' },
  { label: 'Brooks County', value: 'Brooks County' },
  { label: 'Brookshire', value: 'Brookshire' },
  { label: 'Brookside', value: 'Brookside' },
  { label: 'Brookside', value: 'Brookside' },
  { label: 'Brookside Village', value: 'Brookside Village' },
  { label: 'Brookston', value: 'Brookston' },
  { label: 'Brooksville', value: 'Brooksville' },
  { label: 'Brooksville', value: 'Brooksville' },
  { label: 'Brooksville', value: 'Brooksville' },
  { label: 'Brookton', value: 'Brookton' },
  { label: 'Brooktrails', value: 'Brooktrails' },
  { label: 'Brookvale', value: 'Brookvale' },
  { label: 'Brookville', value: 'Brookville' },
  { label: 'Brookville', value: 'Brookville' },
  { label: 'Brookville', value: 'Brookville' },
  { label: 'Brookville', value: 'Brookville' },
  { label: 'Brookwater', value: 'Brookwater' },
  { label: 'Brookwood', value: 'Brookwood' },
  { label: 'Broomall', value: 'Broomall' },
  { label: 'Broome', value: 'Broome' },
  { label: 'Broome County', value: 'Broome County' },
  { label: 'Broomehill-Tambellup', value: 'Broomehill-Tambellup' },
  { label: 'Broomfield', value: 'Broomfield' },
  { label: 'Broomfield County', value: 'Broomfield County' },
  { label: 'Broons', value: 'Broons' },
  { label: 'Brora', value: 'Brora' },
  { label: 'Brørup', value: 'Brørup' },
  { label: 'Broscăuţi', value: 'Broscăuţi' },
  { label: 'Broseley', value: 'Broseley' },
  { label: 'Broshniv-Osada', value: 'Broshniv-Osada' },
  { label: 'Broska', value: 'Broska' },
  { label: 'Brossard', value: 'Brossard' },
  { label: 'Brossasco', value: 'Brossasco' },
  { label: 'Brosso', value: 'Brosso' },
  { label: 'Broşteni', value: 'Broşteni' },
  { label: 'Broşteni', value: 'Broşteni' },
  { label: 'Broşteni', value: 'Broşteni' },
  { label: 'Broșteni', value: 'Broșteni' },
  { label: 'Broșteni', value: 'Broșteni' },
  { label: 'Brotas', value: 'Brotas' },
  { label: 'Brotas De Macaúbas', value: 'Brotas De Macaúbas' },
  { label: 'Brothertown', value: 'Brothertown' },
  { label: 'Broto', value: 'Broto' },
  { label: 'Brotterode', value: 'Brotterode' },
  { label: 'Brotton', value: 'Brotton' },
  { label: 'Brou', value: 'Brou' },
  { label: 'Brouckerque', value: 'Brouckerque' },
  { label: 'Brough', value: 'Brough' },
  { label: 'Broughshane', value: 'Broughshane' },
  { label: 'Broughton', value: 'Broughton' },
  { label: 'Broughton', value: 'Broughton' },
  { label: 'Broughton', value: 'Broughton' },
  { label: 'Broughton Astley', value: 'Broughton Astley' },
  { label: 'Broulee', value: 'Broulee' },
  { label: 'Broumov', value: 'Broumov' },
  { label: 'Broussard', value: 'Broussard' },
  { label: 'Brou-Sur-Chantereine', value: 'Brou-Sur-Chantereine' },
  { label: 'Broût-Vernet', value: 'Broût-Vernet' },
  { label: 'Brouwershaven', value: 'Brouwershaven' },
  { label: 'Brovary', value: 'Brovary' },
  { label: 'Brovary Raion', value: 'Brovary Raion' },
  { label: 'Brovello', value: 'Brovello' },
  { label: 'Brovello-Carpugnino', value: 'Brovello-Carpugnino' },
  { label: 'Brovki Pershi', value: 'Brovki Pershi' },
  { label: 'Brovst', value: 'Brovst' },
  { label: 'Broward County', value: 'Broward County' },
  { label: 'Broward Estates', value: 'Broward Estates' },
  { label: 'Brown City', value: 'Brown City' },
  { label: 'Brown County', value: 'Brown County' },
  { label: 'Brown County', value: 'Brown County' },
  { label: 'Brown County', value: 'Brown County' },
  { label: 'Brown County', value: 'Brown County' },
  { label: 'Brown County', value: 'Brown County' },
  { label: 'Brown County', value: 'Brown County' },
  { label: 'Brown County', value: 'Brown County' },
  { label: 'Brown County', value: 'Brown County' },
  { label: 'Brown County', value: 'Brown County' },
  { label: 'Brown Deer', value: 'Brown Deer' },
  { label: 'Brown Hill', value: 'Brown Hill' },
  { label: 'Brownfield', value: 'Brownfield' },
  { label: 'Brownfield', value: 'Brownfield' },
  { label: 'Brownhills', value: 'Brownhills' },
  { label: 'Browning', value: 'Browning' },
  { label: 'Brownlee Park', value: 'Brownlee Park' },
  { label: 'Browns Hill', value: 'Browns Hill' },
  { label: 'Browns Lake', value: 'Browns Lake' },
  { label: 'Browns Mills', value: 'Browns Mills' },
  { label: 'Browns Point', value: 'Browns Point' },
  { label: 'Browns Town', value: 'Browns Town' },
  { label: 'Brownsboro', value: 'Brownsboro' },
  { label: 'Brownsburg', value: 'Brownsburg' },
  { label: 'Brownsburg-Chatham', value: 'Brownsburg-Chatham' },
  { label: 'Brownsfield', value: 'Brownsfield' },
  { label: 'Brownstown', value: 'Brownstown' },
  { label: 'Brownstown', value: 'Brownstown' },
  { label: 'Brownsville', value: 'Brownsville' },
  { label: 'Brownsville', value: 'Brownsville' },
  { label: 'Brownsville', value: 'Brownsville' },
  { label: 'Brownsville', value: 'Brownsville' },
  { label: 'Brownsville', value: 'Brownsville' },
  { label: 'Brownsville', value: 'Brownsville' },
  { label: 'Brownsville', value: 'Brownsville' },
  { label: 'Brownsville', value: 'Brownsville' },
  { label: 'Brownsweg', value: 'Brownsweg' },
  { label: 'Browntown', value: 'Browntown' },
  { label: 'Brownville', value: 'Brownville' },
  { label: 'Brownville', value: 'Brownville' },
  { label: 'Brownville', value: 'Brownville' },
  { label: 'Brownwood', value: 'Brownwood' },
  { label: 'Broxbourne', value: 'Broxbourne' },
  { label: 'Broxburn', value: 'Broxburn' },
  { label: 'Broxton', value: 'Broxton' },
  { label: 'Broye District', value: 'Broye District' },
  { label: 'Broye-Vully District', value: 'Broye-Vully District' },
  { label: 'Brozas', value: 'Brozas' },
  { label: 'Brozolo', value: 'Brozolo' },
  { label: 'Brozzo', value: 'Brozzo' },
  { label: 'Brrnakot’', value: 'Brrnakot’' },
  { label: 'Bršadin', value: 'Bršadin' },
  { label: 'Brtnice', value: 'Brtnice' },
  { label: 'Brtonigla', value: 'Brtonigla' },
  { label: 'Brtonigla-Verteneglio', value: 'Brtonigla-Verteneglio' },
  { label: 'Bruay-La-Buissière', value: 'Bruay-La-Buissière' },
  { label: 'Bruay-Sur-L’Escaut', value: 'Bruay-Sur-L’Escaut' },
  { label: 'Bruc El', value: 'Bruc El' },
  { label: 'Bruce', value: 'Bruce' },
  { label: 'Bruce', value: 'Bruce' },
  { label: 'Bruce Rock', value: 'Bruce Rock' },
  { label: 'Bruceton', value: 'Bruceton' },
  { label: 'Bruceville/Shanty Town', value: 'Bruceville/Shanty Town' },
  { label: 'Bruceville-Eddy', value: 'Bruceville-Eddy' },
  { label: 'Bruchem', value: 'Bruchem' },
  { label: 'Bruchhausen-Vilsen', value: 'Bruchhausen-Vilsen' },
  { label: 'Bruchköbel', value: 'Bruchköbel' },
  { label: 'Bruchmühlbach-Miesau', value: 'Bruchmühlbach-Miesau' },
  { label: 'Bruchsal', value: 'Bruchsal' },
  { label: 'Bruchweiler-Bärenbach', value: 'Bruchweiler-Bärenbach' },
  { label: 'Bruck', value: 'Bruck' },
  { label: 'Brück', value: 'Brück' },
  { label: 'Bruck Am Ziller', value: 'Bruck Am Ziller' },
  { label: 'Bruck An Der Großglocknerstraße', value: 'Bruck An Der Großglocknerstraße' },
  { label: 'Bruck An Der Leitha', value: 'Bruck An Der Leitha' },
  { label: 'Bruck An Der Mur', value: 'Bruck An Der Mur' },
  { label: 'Bruck In Der Oberpfalz', value: 'Bruck In Der Oberpfalz' },
  { label: 'Bruckberg', value: 'Bruckberg' },
  { label: 'Brücken', value: 'Brücken' },
  { label: 'Brückl', value: 'Brückl' },
  { label: 'Bruckmühl', value: 'Bruckmühl' },
  { label: 'Bruckneudorf', value: 'Bruckneudorf' },
  { label: 'Brucoli', value: 'Brucoli' },
  { label: 'Brudzeń Duży', value: 'Brudzeń Duży' },
  { label: 'Brudzew', value: 'Brudzew' },
  { label: 'Brüel', value: 'Brüel' },
  { label: 'Brugelette', value: 'Brugelette' },
  { label: 'Bruges', value: 'Bruges' },
  { label: 'Brugg', value: 'Brugg' },
  { label: 'Brügg', value: 'Brügg' },
  { label: 'Brugge', value: 'Brugge' },
  { label: 'Brügge', value: 'Brügge' },
  { label: 'Brüggen', value: 'Brüggen' },
  { label: 'Brüggen', value: 'Brüggen' },
  { label: 'Brugheas', value: 'Brugheas' },
  { label: 'Brugherio', value: 'Brugherio' },
  { label: 'Brugine', value: 'Brugine' },
  { label: 'Brugnato', value: 'Brugnato' },
  { label: 'Brugnera', value: 'Brugnera' },
  { label: 'Bruguières', value: 'Bruguières' },
  { label: 'Brühl', value: 'Brühl' },
  { label: 'Brühl', value: 'Brühl' },
  { label: 'Bruille-Lez-Marchiennes', value: 'Bruille-Lez-Marchiennes' },
  { label: 'Bruille-Saint-Amand', value: 'Bruille-Saint-Amand' },
  { label: 'Bruinisse', value: 'Bruinisse' },
  { label: 'Bruino', value: 'Bruino' },
  { label: 'Bruiu', value: 'Bruiu' },
  { label: 'Brujas', value: 'Brujas' },
  { label: 'Brukske', value: 'Brukske' },
  { label: 'Brule County', value: 'Brule County' },
  { label: 'Brull El', value: 'Brull El' },
  { label: 'Brûlon', value: 'Brûlon' },
  { label: 'Brumadinho', value: 'Brumadinho' },
  { label: 'Brumado', value: 'Brumado' },
  { label: 'Brumano', value: 'Brumano' },
  { label: 'Brumath', value: 'Brumath' },
  { label: 'Brumby', value: 'Brumby' },
  { label: 'Brummelhof', value: 'Brummelhof' },
  { label: 'Brummen', value: 'Brummen' },
  { label: 'Brumovice', value: 'Brumovice' },
  { label: 'Brumunddal', value: 'Brumunddal' },
  { label: 'Bruna', value: 'Bruna' },
  { label: 'Brunate', value: 'Brunate' },
  { label: 'Brundall', value: 'Brundall' },
  { label: 'Brundidge', value: 'Brundidge' },
  { label: 'Brunehault', value: 'Brunehault' },
  { label: 'Brunello', value: 'Brunello' },
  { label: 'Brunete', value: 'Brunete' },
  { label: 'Brunico', value: 'Brunico' },
  { label: 'Brunn', value: 'Brunn' },
  { label: 'Brunn', value: 'Brunn' },
  { label: 'Brunn', value: 'Brunn' },
  { label: 'Brunn', value: 'Brunn' },
  { label: 'Brunn Am Gebirge', value: 'Brunn Am Gebirge' },
  { label: 'Brunn An Der Schneebergbahn', value: 'Brunn An Der Schneebergbahn' },
  { label: 'Brunn An Der Wild', value: 'Brunn An Der Wild' },
  { label: 'Brunna', value: 'Brunna' },
  { label: 'Brunna', value: 'Brunna' },
  { label: 'Brunnen', value: 'Brunnen' },
  { label: 'Brunnepe', value: 'Brunnepe' },
  { label: 'Brunnthal', value: 'Brunnthal' },
  { label: 'Bruno', value: 'Bruno' },
  { label: 'Brunópolis', value: 'Brunópolis' },
  { label: 'Brunoy', value: 'Brunoy' },
  { label: 'Brunsbüttel', value: 'Brunsbüttel' },
  { label: 'Brunssum', value: 'Brunssum' },
  { label: 'Brunstatt', value: 'Brunstatt' },
  { label: 'Brunswick', value: 'Brunswick' },
  { label: 'Brunswick', value: 'Brunswick' },
  { label: 'Brunswick', value: 'Brunswick' },
  { label: 'Brunswick', value: 'Brunswick' },
  { label: 'Brunswick', value: 'Brunswick' },
  { label: 'Brunswick', value: 'Brunswick' },
  { label: 'Brunswick', value: 'Brunswick' },
  { label: 'Brunswick County', value: 'Brunswick County' },
  { label: 'Brunswick County', value: 'Brunswick County' },
  { label: 'Brunswick East', value: 'Brunswick East' },
  { label: 'Brunswick Heads', value: 'Brunswick Heads' },
  { label: 'Brunswick West', value: 'Brunswick West' },
  { label: 'Bruntál', value: 'Bruntál' },
  { label: 'Brunyola', value: 'Brunyola' },
  { label: 'Brus Laguna', value: 'Brus Laguna' },
  { label: 'Brusaporto', value: 'Brusaporto' },
  { label: 'Brusartsi', value: 'Brusartsi' },
  { label: 'Brusasco', value: 'Brusasco' },
  { label: 'Brusciano', value: 'Brusciano' },
  { label: 'Brüsewitz', value: 'Brüsewitz' },
  { label: 'Brush', value: 'Brush' },
  { label: 'Brush Fork', value: 'Brush Fork' },
  { label: 'Brush Prairie', value: 'Brush Prairie' },
  { label: 'Brushy Creek', value: 'Brushy Creek' },
  { label: 'Brusimpiano', value: 'Brusimpiano' },
  { label: 'Brusio', value: 'Brusio' },
  { label: 'Brusly', value: 'Brusly' },
  { label: 'Brusnengo', value: 'Brusnengo' },
  { label: 'Brušperk', value: 'Brušperk' },
  { label: 'Brusque', value: 'Brusque' },
  { label: 'Brussels', value: 'Brussels' },
  { label: 'Brussels', value: 'Brussels' },
  { label: 'Brussels', value: 'Brussels' },
  { label: 'Brusson', value: 'Brusson' },
  { label: 'Brüssow', value: 'Brüssow' },
  { label: 'Brusturi', value: 'Brusturi' },
  { label: 'Brusturi', value: 'Brusturi' },
  { label: 'Brusturoasa', value: 'Brusturoasa' },
  { label: 'Brusy', value: 'Brusy' },
  { label: 'Brusyanka', value: 'Brusyanka' },
  { label: 'Brusyliv', value: 'Brusyliv' },
  { label: 'Brusyliv', value: 'Brusyliv' },
  { label: 'Brusylivs’Kyy Rayon', value: 'Brusylivs’Kyy Rayon' },
  { label: 'Bruton', value: 'Bruton' },
  { label: 'Brütten', value: 'Brütten' },
  { label: 'Bruttig-Fankel', value: 'Bruttig-Fankel' },
  { label: 'Brüttisellen', value: 'Brüttisellen' },
  { label: 'Bruyères', value: 'Bruyères' },
  { label: 'Bruyères-Et-Montbérault', value: 'Bruyères-Et-Montbérault' },
  { label: 'Bruyères-Le-Châtel', value: 'Bruyères-Le-Châtel' },
  { label: 'Bruyères-Sur-Oise', value: 'Bruyères-Sur-Oise' },
  { label: 'Bruz', value: 'Bruz' },
  { label: 'Bruzolo', value: 'Bruzolo' },
  { label: 'Bruzzano Zeffirio', value: 'Bruzzano Zeffirio' },
  { label: 'Brvenica', value: 'Brvenica' },
  { label: 'Brwinów', value: 'Brwinów' },
  { label: 'Bryan', value: 'Bryan' },
  { label: 'Bryan', value: 'Bryan' },
  { label: 'Bryan County', value: 'Bryan County' },
  { label: 'Bryan County', value: 'Bryan County' },
  { label: 'Bryanka', value: 'Bryanka' },
  { label: 'Bryans Road', value: 'Bryans Road' },
  { label: 'Bryansk', value: 'Bryansk' },
  { label: 'Bryanskiy Rayon', value: 'Bryanskiy Rayon' },
  { label: 'Bryant', value: 'Bryant' },
  { label: 'Bryant', value: 'Bryant' },
  { label: 'Brylivka', value: 'Brylivka' },
  { label: 'Brymbo', value: 'Brymbo' },
  { label: 'Bryn', value: 'Bryn' },
  { label: 'Bryn Athyn', value: 'Bryn Athyn' },
  { label: 'Bryn Mawr', value: 'Bryn Mawr' },
  { label: 'Bryn Mawr-Skyway', value: 'Bryn Mawr-Skyway' },
  { label: 'Brynamman', value: 'Brynamman' },
  { label: 'Bryne', value: 'Bryne' },
  { label: 'Brynmawr', value: 'Brynmawr' },
  { label: 'Brynna', value: 'Brynna' },
  { label: 'Bryson City', value: 'Bryson City' },
  { label: 'Bry-Sur-Marne', value: 'Bry-Sur-Marne' },
  { label: 'Bryukhovetskaya', value: 'Bryukhovetskaya' },
  { label: 'Bryukhovychi', value: 'Bryukhovychi' },
  { label: 'Brzączowice', value: 'Brzączowice' },
  { label: 'Brzeg', value: 'Brzeg' },
  { label: 'Brzeg Dolny', value: 'Brzeg Dolny' },
  { label: 'Brześć Kujawski', value: 'Brześć Kujawski' },
  { label: 'Brzesko', value: 'Brzesko' },
  { label: 'Brzeszcze', value: 'Brzeszcze' },
  { label: 'Brzezinka', value: 'Brzezinka' },
  { label: 'Brzeziny', value: 'Brzeziny' },
  { label: 'Brzeziny', value: 'Brzeziny' },
  { label: 'Brzeziny Śląskie', value: 'Brzeziny Śląskie' },
  { label: 'Brzeźnica', value: 'Brzeźnica' },
  { label: 'Brzeźnica', value: 'Brzeźnica' },
  { label: 'Brzeźnio', value: 'Brzeźnio' },
  { label: 'Brzostek', value: 'Brzostek' },
  { label: 'Brzóza Królewska', value: 'Brzóza Królewska' },
  { label: 'Brzóza Stadnicka', value: 'Brzóza Stadnicka' },
  { label: 'Brzozie', value: 'Brzozie' },
  { label: 'Brzozów', value: 'Brzozów' },
  { label: 'Brzuze', value: 'Brzuze' },
  { label: 'Brzyska', value: 'Brzyska' },
  { label: 'Bû', value: 'Bû' },
  { label: 'Bu‘Eina', value: 'Bu‘Eina' },
  { label: 'Bua', value: 'Bua' },
  { label: 'Bua Province', value: 'Bua Province' },
  { label: 'Bua Yai', value: 'Bua Yai' },
  { label: 'Buadiposo-Buntong', value: 'Buadiposo-Buntong' },
  { label: 'Buadiposo-Buntong', value: 'Buadiposo-Buntong' },
  { label: 'Buadtasan', value: 'Buadtasan' },
  { label: 'Buadtasan', value: 'Buadtasan' },
  { label: 'Buagsong', value: 'Buagsong' },
  { label: 'Bual', value: 'Bual' },
  { label: 'Bual', value: 'Bual' },
  { label: 'Buala', value: 'Buala' },
  { label: 'Bualan', value: 'Bualan' },
  { label: 'Bualan', value: 'Bualan' },
  { label: 'Buan', value: 'Buan' },
  { label: 'Buan', value: 'Buan' },
  { label: 'Buang', value: 'Buang' },
  { label: 'Buan-Gun', value: 'Buan-Gun' },
  { label: 'Buanoy', value: 'Buanoy' },
  { label: 'Buansa', value: 'Buansa' },
  { label: 'Buansa', value: 'Buansa' },
  { label: 'Buaran', value: 'Buaran' },
  { label: 'Buarcos', value: 'Buarcos' },
  { label: 'Buawan', value: 'Buawan' },
  { label: 'Buayan', value: 'Buayan' },
  { label: 'Buayan', value: 'Buayan' },
  { label: 'Buaysiacobe', value: 'Buaysiacobe' },
  { label: 'Buayums', value: 'Buayums' },
  { label: 'Buba', value: 'Buba' },
  { label: 'Bubano', value: 'Bubano' },
  { label: 'Bubanza', value: 'Bubanza' },
  { label: 'Bubaque', value: 'Bubaque' },
  { label: 'Bubbiano', value: 'Bubbiano' },
  { label: 'Bubbio', value: 'Bubbio' },
  { label: 'Bubendorf', value: 'Bubendorf' },
  { label: 'Bubenreuth', value: 'Bubenreuth' },
  { label: 'Buberos', value: 'Buberos' },
  { label: 'Bubesheim', value: 'Bubesheim' },
  { label: 'Bubi District', value: 'Bubi District' },
  { label: 'Bubierca', value: 'Bubierca' },
  { label: 'Bubikon', value: 'Bubikon' },
  { label: 'Bubión', value: 'Bubión' },
  { label: 'Bubong', value: 'Bubong' },
  { label: 'Bubry', value: 'Bubry' },
  { label: 'Bubsheim', value: 'Bubsheim' },
  { label: 'Bubwith', value: 'Bubwith' },
  { label: 'Buc', value: 'Buc' },
  { label: 'Buca', value: 'Buca' },
  { label: 'Bucak', value: 'Bucak' },
  { label: 'Bucaramanga', value: 'Bucaramanga' },
  { label: 'Bucarasica', value: 'Bucarasica' },
  { label: 'Bucasia', value: 'Bucasia' },
  { label: 'Bucay', value: 'Bucay' },
  { label: 'Bucay', value: 'Bucay' },
  { label: 'Bucay Pait', value: 'Bucay Pait' },
  { label: 'Bucca', value: 'Bucca' },
  { label: 'Buccan', value: 'Buccan' },
  { label: 'Buccheri', value: 'Buccheri' },
  { label: 'Bucchianico', value: 'Bucchianico' },
  { label: 'Bucciano', value: 'Bucciano' },
  { label: 'Buccinasco', value: 'Buccinasco' },
  { label: 'Buccino', value: 'Buccino' },
  { label: 'Bucecea', value: 'Bucecea' },
  { label: 'Bucelas', value: 'Bucelas' },
  { label: 'Bucelas', value: 'Bucelas' },
  { label: 'Buceo', value: 'Buceo' },
  { label: 'Bucerdea-Grânoasă', value: 'Bucerdea-Grânoasă' },
  { label: 'Bucerías', value: 'Bucerías' },
  { label: 'Buceş', value: 'Buceş' },
  { label: 'Bucești', value: 'Bucești' },
  { label: 'Buch', value: 'Buch' },
  { label: 'Buch', value: 'Buch' },
  { label: 'Buch Am Ahorn', value: 'Buch Am Ahorn' },
  { label: 'Buch Am Buchrain', value: 'Buch Am Buchrain' },
  { label: 'Buch In Tirol', value: 'Buch In Tirol' },
  { label: 'Bucha', value: 'Bucha' },
  { label: 'Bucha', value: 'Bucha' },
  { label: 'Buchach', value: 'Buchach' },
  { label: 'Buchanan', value: 'Buchanan' },
  { label: 'Buchanan', value: 'Buchanan' },
  { label: 'Buchanan', value: 'Buchanan' },
  { label: 'Buchanan', value: 'Buchanan' },
  { label: 'Buchanan', value: 'Buchanan' },
  { label: 'Buchanan County', value: 'Buchanan County' },
  { label: 'Buchanan County', value: 'Buchanan County' },
  { label: 'Buchanan County', value: 'Buchanan County' },
  { label: 'Buchanan Dam', value: 'Buchanan Dam' },
  { label: 'Buchardo', value: 'Buchardo' },
  { label: 'Bucharest', value: 'Bucharest' },
  { label: 'Buchbach', value: 'Buchbach' },
  { label: 'Buchbach', value: 'Buchbach' },
  { label: 'Buchbrunn', value: 'Buchbrunn' },
  { label: 'Buchdorf', value: 'Buchdorf' },
  { label: 'Büchel', value: 'Büchel' },
  { label: 'Buchelay', value: 'Buchelay' },
  { label: 'Büchen', value: 'Büchen' },
  { label: 'Buchen In Odenwald', value: 'Buchen In Odenwald' },
  { label: 'Buchenbach', value: 'Buchenbach' },
  { label: 'Büchenbach', value: 'Büchenbach' },
  { label: 'Buchenberg', value: 'Buchenberg' },
  { label: 'Büchenbeuren', value: 'Büchenbeuren' },
  { label: 'Bucheon-Si', value: 'Bucheon-Si' },
  { label: 'Buchères', value: 'Buchères' },
  { label: 'Buchforst', value: 'Buchforst' },
  { label: 'Buchheim', value: 'Buchheim' },
  { label: 'Buchholterberg', value: 'Buchholterberg' },
  { label: 'Buchholz', value: 'Buchholz' },
  { label: 'Buchholz', value: 'Buchholz' },
  { label: 'Buchholz In Der Nordheide', value: 'Buchholz In Der Nordheide' },
  { label: 'Buchin', value: 'Buchin' },
  { label: 'Buchkirchen', value: 'Buchkirchen' },
  { label: 'Büchlberg', value: 'Büchlberg' },
  { label: 'Buchloe', value: 'Buchloe' },
  { label: 'Buchlovice', value: 'Buchlovice' },
  { label: 'Buchmany', value: 'Buchmany' },
  { label: 'Buchrain', value: 'Buchrain' },
  { label: 'Buchs', value: 'Buchs' },
  { label: 'Buchs', value: 'Buchs' },
  { label: 'Buchten', value: 'Buchten' },
  { label: 'Buchy', value: 'Buchy' },
  { label: 'Buciegas', value: 'Buciegas' },
  { label: 'Bučin', value: 'Bučin' },
  { label: 'Bucine', value: 'Bucine' },
  { label: 'Bucinişu', value: 'Bucinişu' },
  { label: 'Bucium', value: 'Bucium' },
  { label: 'Buciumeni', value: 'Buciumeni' },
  { label: 'Buciumeni', value: 'Buciumeni' },
  { label: 'Buciumeni', value: 'Buciumeni' },
  { label: 'Buciumi', value: 'Buciumi' },
  { label: 'Buciumi', value: 'Buciumi' },
  { label: 'Buciumi', value: 'Buciumi' },
  { label: 'Bucium-Orlea', value: 'Bucium-Orlea' },
  { label: 'Buckden', value: 'Buckden' },
  { label: 'Bückeburg', value: 'Bückeburg' },
  { label: 'Bücken', value: 'Bücken' },
  { label: 'Buckenhof', value: 'Buckenhof' },
  { label: 'Buckeye', value: 'Buckeye' },
  { label: 'Buckeye Lake', value: 'Buckeye Lake' },
  { label: 'Buckeystown', value: 'Buckeystown' },
  { label: 'Buckfastleigh', value: 'Buckfastleigh' },
  { label: 'Buckfield', value: 'Buckfield' },
  { label: 'Buckhall', value: 'Buckhall' },
  { label: 'Buckhannon', value: 'Buckhannon' },
  { label: 'Buckhaven', value: 'Buckhaven' },
  { label: 'Buckhead Ridge', value: 'Buckhead Ridge' },
  { label: 'Buckhorn', value: 'Buckhorn' },
  { label: 'Buckhurst Hill', value: 'Buckhurst Hill' },
  { label: 'Buckie', value: 'Buckie' },
  { label: 'Buckingham', value: 'Buckingham' },
  { label: 'Buckingham', value: 'Buckingham' },
  { label: 'Buckingham', value: 'Buckingham' },
  { label: 'Buckingham', value: 'Buckingham' },
  { label: 'Buckingham County', value: 'Buckingham County' },
  { label: 'Buckinghamshire', value: 'Buckinghamshire' },
  { label: 'Buckland', value: 'Buckland' },
  { label: 'Buckley', value: 'Buckley' },
  { label: 'Buckley', value: 'Buckley' },
  { label: 'Bucknell', value: 'Bucknell' },
  { label: 'Buckner', value: 'Buckner' },
  { label: 'Buckner', value: 'Buckner' },
  { label: 'Bucknor', value: 'Bucknor' },
  { label: 'Buckow', value: 'Buckow' },
  { label: 'Buckow', value: 'Buckow' },
  { label: 'Bucks Common', value: 'Bucks Common' },
  { label: 'Bucks County', value: 'Bucks County' },
  { label: 'Bucksport', value: 'Bucksport' },
  { label: 'Buclad', value: 'Buclad' },
  { label: 'Buclad', value: 'Buclad' },
  { label: 'Buclad', value: 'Buclad' },
  { label: 'Bucloc', value: 'Bucloc' },
  { label: 'Bucoşniţa', value: 'Bucoşniţa' },
  { label: 'Bucov', value: 'Bucov' },
  { label: 'Bucova', value: 'Bucova' },
  { label: 'Bucovăţ', value: 'Bucovăţ' },
  { label: 'Bucovăţ', value: 'Bucovăţ' },
  { label: 'Bucovăț', value: 'Bucovăț' },
  { label: 'Bučovice', value: 'Bučovice' },
  { label: 'Bucovineni', value: 'Bucovineni' },
  { label: 'Bucquoy', value: 'Bucquoy' },
  { label: 'Bucsa', value: 'Bucsa' },
  { label: 'Bucşani', value: 'Bucşani' },
  { label: 'Bucşani', value: 'Bucşani' },
  { label: 'Bucșenești', value: 'Bucșenești' },
  { label: 'Bucșenești-Lotași', value: 'Bucșenești-Lotași' },
  { label: 'Bucșești', value: 'Bucșești' },
  { label: 'Bucșoaia', value: 'Bucșoaia' },
  { label: 'Buctzotz', value: 'Buctzotz' },
  { label: 'Bucu', value: 'Bucu' },
  { label: 'Bucureşci', value: 'Bucureşci' },
  { label: 'Bucy-Le-Long', value: 'Bucy-Le-Long' },
  { label: 'Bucyrus', value: 'Bucyrus' },
  { label: 'Buczek', value: 'Buczek' },
  { label: 'Buczkowice', value: 'Buczkowice' },
  { label: 'Buda', value: 'Buda' },
  { label: 'Buda', value: 'Buda' },
  { label: 'Buda', value: 'Buda' },
  { label: 'Buda', value: 'Buda' },
  { label: 'Buda', value: 'Buda' },
  { label: 'Buda', value: 'Buda' },
  { label: 'Budacu De Jos', value: 'Budacu De Jos' },
  { label: 'Budacu De Sus', value: 'Budacu De Sus' },
  { label: 'Budagovo', value: 'Budagovo' },
  { label: 'Budăi', value: 'Budăi' },
  { label: 'Budakalász', value: 'Budakalász' },
  { label: 'Buda-Kashalyova', value: 'Buda-Kashalyova' },
  { label: 'Budakeszi', value: 'Budakeszi' },
  { label: 'Budakeszi Járás', value: 'Budakeszi Járás' },
  { label: 'Buđanovci', value: 'Buđanovci' },
  { label: 'Budaörs', value: 'Budaörs' },
  { label: 'Budapest', value: 'Budapest' },
  { label: 'Budapest I. Kerület', value: 'Budapest I. Kerület' },
  { label: 'Budapest Ii. Kerület', value: 'Budapest Ii. Kerület' },
  { label: 'Budapest Iii. Kerület', value: 'Budapest Iii. Kerület' },
  { label: 'Budapest Iv. Kerület', value: 'Budapest Iv. Kerület' },
  { label: 'Budapest Vi. Kerület', value: 'Budapest Vi. Kerület' },
  { label: 'Budapest Viii. Kerület', value: 'Budapest Viii. Kerület' },
  { label: 'Budapest X. Kerület', value: 'Budapest X. Kerület' },
  { label: 'Budapest Xi. Kerület', value: 'Budapest Xi. Kerület' },
  { label: 'Budapest Xii. Kerület', value: 'Budapest Xii. Kerület' },
  { label: 'Budapest Xiii. Kerület', value: 'Budapest Xiii. Kerület' },
  { label: 'Budapest Xv. Kerület', value: 'Budapest Xv. Kerület' },
  { label: 'Budapest Xvi. Kerület', value: 'Budapest Xvi. Kerület' },
  { label: 'Budapest Xvii. Kerület', value: 'Budapest Xvii. Kerület' },
  { label: 'Budapest Xviii. Kerület', value: 'Budapest Xviii. Kerület' },
  { label: 'Budapest Xx. Kerület', value: 'Budapest Xx. Kerület' },
  { label: 'Budapest Xxi. Kerület', value: 'Budapest Xxi. Kerület' },
  { label: 'Budapest Xxii. Kerület', value: 'Budapest Xxii. Kerület' },
  { label: 'Budapest Xxiii. Kerület', value: 'Budapest Xxiii. Kerület' },
  { label: 'Budaševo', value: 'Budaševo' },
  { label: 'Budaun', value: 'Budaun' },
  { label: 'Budd Lake', value: 'Budd Lake' },
  { label: 'Büddenstedt', value: 'Büddenstedt' },
  { label: 'Buddh Gaya', value: 'Buddh Gaya' },
  { label: 'Buddina', value: 'Buddina' },
  { label: 'Buddusò', value: 'Buddusò' },
  { label: 'Bude', value: 'Bude' },
  { label: 'Bude', value: 'Bude' },
  { label: 'Budeasa Mică', value: 'Budeasa Mică' },
  { label: 'Budel', value: 'Budel' },
  { label: 'Budel-Dorplein', value: 'Budel-Dorplein' },
  { label: 'Budel-Schoot', value: 'Budel-Schoot' },
  { label: 'Büdelsdorf', value: 'Büdelsdorf' },
  { label: 'Budenheim', value: 'Budenheim' },
  { label: 'Budeni', value: 'Budeni' },
  { label: 'Budënnovsk', value: 'Budënnovsk' },
  { label: 'Budenovetc', value: 'Budenovetc' },
  { label: 'Buderim', value: 'Buderim' },
  { label: 'Budeşti', value: 'Budeşti' },
  { label: 'Budeşti', value: 'Budeşti' },
  { label: 'Budeşti', value: 'Budeşti' },
  { label: 'Budeşti', value: 'Budeşti' },
  { label: 'Budești', value: 'Budești' },
  { label: 'Budești', value: 'Budești' },
  { label: 'Budge Budge', value: 'Budge Budge' },
  { label: 'Budgewoi', value: 'Budgewoi' },
  { label: 'Budhana', value: 'Budhana' },
  { label: 'Budhlada', value: 'Budhlada' },
  { label: 'Budia', value: 'Budia' },
  { label: 'Budila', value: 'Budila' },
  { label: 'Büdingen', value: 'Büdingen' },
  { label: 'Budinščina', value: 'Budinščina' },
  { label: 'Budišov', value: 'Budišov' },
  { label: 'Budišov Nad Budišovkou', value: 'Budišov Nad Budišovkou' },
  { label: 'Budiu Mic', value: 'Budiu Mic' },
  { label: 'Budleigh Salterton', value: 'Budleigh Salterton' },
  { label: 'Budogoshch’', value: 'Budogoshch’' },
  { label: 'Budoia', value: 'Budoia' },
  { label: 'Budoni', value: 'Budoni' },
  { label: 'Budrio', value: 'Budrio' },
  { label: 'Budrovci', value: 'Budrovci' },
  { label: 'Budschop', value: 'Budschop' },
  { label: 'Budta', value: 'Budta' },
  { label: 'Budta', value: 'Budta' },
  { label: 'Buduran', value: 'Buduran' },
  { label: 'Budureasa', value: 'Budureasa' },
  { label: 'Buduslău', value: 'Buduslău' },
  { label: 'Budva', value: 'Budva' },
  { label: 'Budy', value: 'Budy' },
  { label: 'Budyně Nad Ohří', value: 'Budyně Nad Ohří' },
  { label: 'Budziszewice', value: 'Budziszewice' },
  { label: 'Budzów', value: 'Budzów' },
  { label: 'Budzów', value: 'Budzów' },
  { label: 'Budzyń', value: 'Budzyń' },
  { label: 'Buea', value: 'Buea' },
  { label: 'Buechel', value: 'Buechel' },
  { label: 'Bued', value: 'Bued' },
  { label: 'Bued', value: 'Bued' },
  { label: 'Buedu', value: 'Buedu' },
  { label: 'Bueil', value: 'Bueil' },
  { label: 'Buellas', value: 'Buellas' },
  { label: 'Buellton', value: 'Buellton' },
  { label: 'Buen Retiro (El Retiro)', value: 'Buen Retiro (El Retiro)' },
  { label: 'Buena', value: 'Buena' },
  { label: 'Bueña', value: 'Bueña' },
  { label: 'Buena Esperanza', value: 'Buena Esperanza' },
  { label: 'Buena Park', value: 'Buena Park' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista', value: 'Buena Vista' },
  { label: 'Buena Vista (Apasco)', value: 'Buena Vista (Apasco)' },
  { label: 'Buena Vista (Puxcatán)', value: 'Buena Vista (Puxcatán)' },
  { label: 'Buena Vista County', value: 'Buena Vista County' },
  { label: 'Buena Vista De La Salud', value: 'Buena Vista De La Salud' },
  { label: 'Buenache De Alarcón', value: 'Buenache De Alarcón' },
  { label: 'Buenache De La Sierra', value: 'Buenache De La Sierra' },
  { label: 'Buenamadre', value: 'Buenamadre' },
  { label: 'Buenaventura', value: 'Buenaventura' },
  { label: 'Buenaventura', value: 'Buenaventura' },
  { label: 'Buenaventura', value: 'Buenaventura' },
  { label: 'Buenaventura Lakes', value: 'Buenaventura Lakes' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista', value: 'Buenavista' },
  { label: 'Buenavista (Matasanos)', value: 'Buenavista (Matasanos)' },
  { label: 'Buenavista De Allende', value: 'Buenavista De Allende' },
  { label: 'Buenavista De Benito Juárez', value: 'Buenavista De Benito Juárez' },
  { label: 'Buenavista De Cortés', value: 'Buenavista De Cortés' },
  { label: 'Buenavista De Cuéllar', value: 'Buenavista De Cuéllar' },
  { label: 'Buenavista De Juárez', value: 'Buenavista De Juárez' },
  { label: 'Buenavista De Trujillo', value: 'Buenavista De Trujillo' },
  { label: 'Buenavista De Valdavia', value: 'Buenavista De Valdavia' },
  { label: 'Buenavista Del Norte', value: 'Buenavista Del Norte' },
  { label: 'Buenavista Tetela', value: 'Buenavista Tetela' },
  { label: 'Buenavista Tomatlán', value: 'Buenavista Tomatlán' },
  { label: 'Buendía', value: 'Buendía' },
  { label: 'Bueng Kan', value: 'Bueng Kan' },
  { label: 'Bueng Kum', value: 'Bueng Kum' },
  { label: 'Bueng Na Rang', value: 'Bueng Na Rang' },
  { label: 'Buenlag', value: 'Buenlag' },
  { label: 'Buenlag', value: 'Buenlag' },
  { label: 'Buenlag', value: 'Buenlag' },
  { label: 'Buenlag', value: 'Buenlag' },
  { label: 'Bueno Brandão', value: 'Bueno Brandão' },
  { label: 'Buenópolis', value: 'Buenópolis' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Buenos Aires (San Isidro)', value: 'Buenos Aires (San Isidro)' },
  { label: 'Buensuseso', value: 'Buensuseso' },
  { label: 'Buensuseso', value: 'Buensuseso' },
  { label: 'Buerarema', value: 'Buerarema' },
  { label: 'Buesaco', value: 'Buesaco' },
  { label: 'Buești', value: 'Buești' },
  { label: 'Bueu', value: 'Bueu' },
  { label: 'Bufali', value: 'Bufali' },
  { label: 'Bufalo', value: 'Bufalo' },
  { label: 'Buff Bay', value: 'Buff Bay' },
  { label: 'Buff Point', value: 'Buff Point' },
  { label: 'Buffalo', value: 'Buffalo' },
  { label: 'Buffalo', value: 'Buffalo' },
  { label: 'Buffalo', value: 'Buffalo' },
  { label: 'Buffalo', value: 'Buffalo' },
  { label: 'Buffalo', value: 'Buffalo' },
  { label: 'Buffalo', value: 'Buffalo' },
  { label: 'Buffalo', value: 'Buffalo' },
  { label: 'Buffalo', value: 'Buffalo' },
  { label: 'Buffalo', value: 'Buffalo' },
  { label: 'Buffalo', value: 'Buffalo' },
  { label: 'Buffalo (Historical)', value: 'Buffalo (Historical)' },
  { label: 'Buffalo City Metropolitan Municipality', value: 'Buffalo City Metropolitan Municipality' },
  { label: 'Buffalo County', value: 'Buffalo County' },
  { label: 'Buffalo County', value: 'Buffalo County' },
  { label: 'Buffalo County', value: 'Buffalo County' },
  { label: 'Buffalo Grove', value: 'Buffalo Grove' },
  { label: 'Buffalora-Bettole', value: 'Buffalora-Bettole' },
  { label: 'Bufleben', value: 'Bufleben' },
  { label: 'Buford', value: 'Buford' },
  { label: 'Buftea', value: 'Buftea' },
  { label: 'Buga', value: 'Buga' },
  { label: 'Buga', value: 'Buga' },
  { label: 'Buga', value: 'Buga' },
  { label: 'Bugaan', value: 'Bugaan' },
  { label: 'Bugaba', value: 'Bugaba' },
  { label: 'Bugabita Arriba', value: 'Bugabita Arriba' },
  { label: 'Bugac', value: 'Bugac' },
  { label: 'Būgai', value: 'Būgai' },
  { label: 'Bugalagrande', value: 'Bugalagrande' },
  { label: 'Bugallon', value: 'Bugallon' },
  { label: 'Bugallon', value: 'Bugallon' },
  { label: 'Bugana', value: 'Bugana' },
  { label: 'Bugang', value: 'Bugang' },
  { label: 'Bugarama', value: 'Bugarama' },
  { label: 'Bugarra', value: 'Bugarra' },
  { label: 'Bugas', value: 'Bugas' },
  { label: 'Bugasan', value: 'Bugasan' },
  { label: 'Bugasan', value: 'Bugasan' },
  { label: 'Bugasong', value: 'Bugasong' },
  { label: 'Bugbrooke', value: 'Bugbrooke' },
  { label: 'Bugcaon', value: 'Bugcaon' },
  { label: 'Bugeac', value: 'Bugeac' },
  { label: 'Bugeat', value: 'Bugeat' },
  { label: 'Bugedo', value: 'Bugedo' },
  { label: 'Bugembe', value: 'Bugembe' },
  { label: 'Bugene', value: 'Bugene' },
  { label: 'Búger', value: 'Búger' },
  { label: 'Buggenhout', value: 'Buggenhout' },
  { label: 'Buggerru', value: 'Buggerru' },
  { label: 'Buggingen', value: 'Buggingen' },
  { label: 'Bughea De Jos', value: 'Bughea De Jos' },
  { label: 'Bughea De Jos', value: 'Bughea De Jos' },
  { label: 'Bughea De Sus', value: 'Bughea De Sus' },
  { label: 'Bugho', value: 'Bugho' },
  { label: 'Bugho', value: 'Bugho' },
  { label: 'Buġibba', value: 'Buġibba' },
  { label: 'Bugiri', value: 'Bugiri' },
  { label: 'Bugko', value: 'Bugko' },
  { label: 'Bugko', value: 'Bugko' },
  { label: 'Bugle', value: 'Bugle' },
  { label: 'Buglio In Monte', value: 'Buglio In Monte' },
  { label: 'Bugnara', value: 'Bugnara' },
  { label: 'Bugo', value: 'Bugo' },
  { label: 'Bugojno', value: 'Bugojno' },
  { label: 'Bugre', value: 'Bugre' },
  { label: 'Bugry', value: 'Bugry' },
  { label: 'Bugsoc', value: 'Bugsoc' },
  { label: 'Buguda', value: 'Buguda' },
  { label: 'Buguey', value: 'Buguey' },
  { label: 'Buguey', value: 'Buguey' },
  { label: 'Buguggiate', value: 'Buguggiate' },
  { label: 'Buguias', value: 'Buguias' },
  { label: 'Buguias', value: 'Buguias' },
  { label: 'Bugul’Ma', value: 'Bugul’Ma' },
  { label: 'Bugul’Minskiy Rayon', value: 'Bugul’Minskiy Rayon' },
  { label: 'Buguma', value: 'Buguma' },
  { label: 'Buguruslan', value: 'Buguruslan' },
  { label: 'Bugyi', value: 'Bugyi' },
  { label: 'Buhalnița', value: 'Buhalnița' },
  { label: 'Buhangin', value: 'Buhangin' },
  { label: 'Buhangin', value: 'Buhangin' },
  { label: 'Buhangin', value: 'Buhangin' },
  { label: 'Buharkent', value: 'Buharkent' },
  { label: 'Buhatan', value: 'Buhatan' },
  { label: 'Buhe', value: 'Buhe' },
  { label: 'Buhera District', value: 'Buhera District' },
  { label: 'Buhi', value: 'Buhi' },
  { label: 'Buhl', value: 'Buhl' },
  { label: 'Buhl', value: 'Buhl' },
  { label: 'Bühl', value: 'Bühl' },
  { label: 'Buhler', value: 'Buhler' },
  { label: 'Bühler', value: 'Bühler' },
  { label: 'Bühlertal', value: 'Bühlertal' },
  { label: 'Bühlertann', value: 'Bühlertann' },
  { label: 'Bühlerzell', value: 'Bühlerzell' },
  { label: 'Buhoci', value: 'Buhoci' },
  { label: 'Buhovo', value: 'Buhovo' },
  { label: 'Bühren', value: 'Bühren' },
  { label: 'Buhuşi', value: 'Buhuşi' },
  { label: 'Buia', value: 'Buia' },
  { label: 'Buies Creek', value: 'Buies Creek' },
  { label: 'Buiksloot', value: 'Buiksloot' },
  { label: 'Buikslotermeer', value: 'Buikslotermeer' },
  { label: 'Builth Wells', value: 'Builth Wells' },
  { label: 'Buin', value: 'Buin' },
  { label: 'Buin Va Miandasht', value: 'Buin Va Miandasht' },
  { label: 'Buin Zahra', value: 'Buin Zahra' },
  { label: 'Buinsk', value: 'Buinsk' },
  { label: 'Buinsk', value: 'Buinsk' },
  { label: 'Buinskiy Rayon', value: 'Buinskiy Rayon' },
  { label: 'Buíque', value: 'Buíque' },
  { label: 'Buironfosse', value: 'Buironfosse' },
  { label: 'Buis-Les-Baronnies', value: 'Buis-Les-Baronnies' },
  { label: 'Buitenkaag', value: 'Buitenkaag' },
  { label: 'Buitenpost', value: 'Buitenpost' },
  { label: 'Buitenveldert', value: 'Buitenveldert' },
  { label: 'Buitrago', value: 'Buitrago' },
  { label: 'Buitrago Del Lozoya', value: 'Buitrago Del Lozoya' },
  { label: 'Buj', value: 'Buj' },
  { label: 'Buják', value: 'Buják' },
  { label: 'Bujaków', value: 'Bujaków' },
  { label: 'Bujalance', value: 'Bujalance' },
  { label: 'Bujalaro', value: 'Bujalaro' },
  { label: 'Bujaraloz', value: 'Bujaraloz' },
  { label: 'Bujari', value: 'Bujari' },
  { label: 'Bujaru', value: 'Bujaru' },
  { label: 'Buje', value: 'Buje' },
  { label: 'Buje-Buie', value: 'Buje-Buie' },
  { label: 'Bujin', value: 'Bujin' },
  { label: 'Bujor', value: 'Bujor' },
  { label: 'Bujoreni', value: 'Bujoreni' },
  { label: 'Bujoreni', value: 'Bujoreni' },
  { label: 'Bujor-Hodaie', value: 'Bujor-Hodaie' },
  { label: 'Bujoru', value: 'Bujoru' },
  { label: 'Bujumbura', value: 'Bujumbura' },
  { label: 'Buk', value: 'Buk' },
  { label: 'Bük', value: 'Bük' },
  { label: 'Bukachacha', value: 'Bukachacha' },
  { label: 'Bukal', value: 'Bukal' },
  { label: 'Bukal Sur', value: 'Bukal Sur' },
  { label: 'Bukama', value: 'Bukama' },
  { label: 'Bukan', value: 'Bukan' },
  { label: 'Bukavu', value: 'Bukavu' },
  { label: 'Bukay Pait', value: 'Bukay Pait' },
  { label: 'Bukene', value: 'Bukene' },
  { label: 'Buk-Gu', value: 'Buk-Gu' },
  { label: 'Buk-Gu', value: 'Buk-Gu' },
  { label: 'Bukhara', value: 'Bukhara' },
  { label: 'Bukhar-Zhyrau', value: 'Bukhar-Zhyrau' },
  { label: 'Buki', value: 'Buki' },
  { label: 'Bukid', value: 'Bukid' },
  { label: 'Bukid', value: 'Bukid' },
  { label: 'Bukid', value: 'Bukid' },
  { label: 'Bukit Batok', value: 'Bukit Batok' },
  { label: 'Bukit Mertajam', value: 'Bukit Mertajam' },
  { label: 'Bukit Panjang', value: 'Bukit Panjang' },
  { label: 'Bukit Rambai', value: 'Bukit Rambai' },
  { label: 'Bukit Timah', value: 'Bukit Timah' },
  { label: 'Bukittinggi', value: 'Bukittinggi' },
  { label: 'Bükkösd', value: 'Bükkösd' },
  { label: 'Bukoba', value: 'Bukoba' },
  { label: 'Bukomansimbi District', value: 'Bukomansimbi District' },
  { label: 'Bukonyo', value: 'Bukonyo' },
  { label: 'Bukor', value: 'Bukor' },
  { label: 'Bukovany', value: 'Bukovany' },
  { label: 'Bukovec', value: 'Bukovec' },
  { label: 'Bukovik', value: 'Bukovik' },
  { label: 'Bukovlje', value: 'Bukovlje' },
  { label: 'Bukowiec', value: 'Bukowiec' },
  { label: 'Bukowina Tatrzańska', value: 'Bukowina Tatrzańska' },
  { label: 'Bukowno', value: 'Bukowno' },
  { label: 'Bukowsko', value: 'Bukowsko' },
  { label: 'Bukuru', value: 'Bukuru' },
  { label: 'Bukwa District', value: 'Bukwa District' },
  { label: 'Buky', value: 'Buky' },
  { label: 'Bula', value: 'Bula' },
  { label: 'Bula', value: 'Bula' },
  { label: 'Bula Atumba', value: 'Bula Atumba' },
  { label: 'Bulacan', value: 'Bulacan' },
  { label: 'Bulacan', value: 'Bulacan' },
  { label: 'Bulacan', value: 'Bulacan' },
  { label: 'Bülach', value: 'Bülach' },
  { label: 'Bülach / Gstückt', value: 'Bülach / Gstückt' },
  { label: 'Bülach / Seematt', value: 'Bülach / Seematt' },
  { label: 'Bülach / Soligänter', value: 'Bülach / Soligänter' },
  { label: 'Bulacnin', value: 'Bulacnin' },
  { label: 'Bulad', value: 'Bulad' },
  { label: 'Bulahdelah', value: 'Bulahdelah' },
  { label: 'Bulakamba', value: 'Bulakamba' },
  { label: 'Bulakan', value: 'Bulakan' },
  { label: 'Bulalacao', value: 'Bulalacao' },
  { label: 'Bulalacao', value: 'Bulalacao' },
  { label: 'Bulalacao', value: 'Bulalacao' },
  { label: 'Bulambuli District', value: 'Bulambuli District' },
  { label: 'Bulan', value: 'Bulan' },
  { label: 'Bulanash', value: 'Bulanash' },
  { label: 'Bulancak', value: 'Bulancak' },
  { label: 'Bulandshahr', value: 'Bulandshahr' },
  { label: 'Bulanık', value: 'Bulanık' },
  { label: 'Bulaon', value: 'Bulaon' },
  { label: 'Bulaon', value: 'Bulaon' },
  { label: 'Bulaq', value: 'Bulaq' },
  { label: 'Bularit', value: 'Bularit' },
  { label: 'Bularit', value: 'Bularit' },
  { label: 'Bularros', value: 'Bularros' },
  { label: 'Bulasa', value: 'Bulasa' },
  { label: 'Bulata', value: 'Bulata' },
  { label: 'Bulatukan', value: 'Bulatukan' },
  { label: 'Bulatukan', value: 'Bulatukan' },
  { label: 'Bulawayo', value: 'Bulawayo' },
  { label: 'Bulawin', value: 'Bulawin' },
  { label: 'Bulawin', value: 'Bulawin' },
  { label: 'Bulayevo', value: 'Bulayevo' },
  { label: 'Bulbucata', value: 'Bulbucata' },
  { label: 'Bulbuente', value: 'Bulbuente' },
  { label: 'Bulciago', value: 'Bulciago' },
  { label: 'Buldan', value: 'Buldan' },
  { label: 'Buldana', value: 'Buldana' },
  { label: 'Buldichiv', value: 'Buldichiv' },
  { label: 'Buldon', value: 'Buldon' },
  { label: 'Buldon', value: 'Buldon' },
  { label: 'Bulembu', value: 'Bulembu' },
  { label: 'Bulford', value: 'Bulford' },
  { label: 'Bulgakovo', value: 'Bulgakovo' },
  { label: 'Bulgan', value: 'Bulgan' },
  { label: 'Bulgarograsso', value: 'Bulgarograsso' },
  { label: 'Bulgarra', value: 'Bulgarra' },
  { label: 'Bulgăruș', value: 'Bulgăruș' },
  { label: 'Bulgnéville', value: 'Bulgnéville' },
  { label: 'Bulgorello', value: 'Bulgorello' },
  { label: 'Buliga', value: 'Buliga' },
  { label: 'Bulihan', value: 'Bulihan' },
  { label: 'Bulihan', value: 'Bulihan' },
  { label: 'Bulihan', value: 'Bulihan' },
  { label: 'Bulimba', value: 'Bulimba' },
  { label: 'Buliok', value: 'Buliok' },
  { label: 'Buliok', value: 'Buliok' },
  { label: 'Buliran', value: 'Buliran' },
  { label: 'Buliran', value: 'Buliran' },
  { label: 'Buliran Segundo', value: 'Buliran Segundo' },
  { label: 'Buliran Segundo', value: 'Buliran Segundo' },
  { label: 'Bulit', value: 'Bulit' },
  { label: 'Bulit', value: 'Bulit' },
  { label: 'Buliwao', value: 'Buliwao' },
  { label: 'Buliwao', value: 'Buliwao' },
  { label: 'Bulkington', value: 'Bulkington' },
  { label: 'Bulkowo', value: 'Bulkowo' },
  { label: 'Bull Bay/ Seven Mile', value: 'Bull Bay/ Seven Mile' },
  { label: 'Bull Creek', value: 'Bull Creek' },
  { label: 'Bull Run', value: 'Bull Run' },
  { label: 'Bull Run Mountain Estates', value: 'Bull Run Mountain Estates' },
  { label: 'Bull Savanna', value: 'Bull Savanna' },
  { label: 'Bull Savannah', value: 'Bull Savannah' },
  { label: 'Bull Shoals', value: 'Bull Shoals' },
  { label: 'Bull Valley', value: 'Bull Valley' },
  { label: 'Bullaburra', value: 'Bullaburra' },
  { label: 'Bullange', value: 'Bullange' },
  { label: 'Bullard', value: 'Bullard' },
  { label: 'Bullas', value: 'Bullas' },
  { label: 'Bullay', value: 'Bullay' },
  { label: 'Bulle', value: 'Bulle' },
  { label: 'Bulleen', value: 'Bulleen' },
  { label: 'Bullerup', value: 'Bullerup' },
  { label: 'Bullhead City', value: 'Bullhead City' },
  { label: 'Bulli', value: 'Bulli' },
  { label: 'Bullion', value: 'Bullion' },
  { label: 'Bullitt County', value: 'Bullitt County' },
  { label: 'Bulloch County', value: 'Bulloch County' },
  { label: 'Bullock County', value: 'Bullock County' },
  { label: 'Bulloo', value: 'Bulloo' },
  { label: 'Bulls', value: 'Bulls' },
  { label: 'Bullsbrook', value: 'Bullsbrook' },
  { label: 'Bully', value: 'Bully' },
  { label: 'Bully', value: 'Bully' },
  { label: 'Bully-Les-Mines', value: 'Bully-Les-Mines' },
  { label: 'Bulnes', value: 'Bulnes' },
  { label: 'Bulo', value: 'Bulo' },
  { label: 'Bulod', value: 'Bulod' },
  { label: 'Bulog', value: 'Bulog' },
  { label: 'Bulog', value: 'Bulog' },
  { label: 'Buloh Kasap', value: 'Buloh Kasap' },
  { label: 'Buloke', value: 'Buloke' },
  { label: 'Bulolo', value: 'Bulolo' },
  { label: 'Buloqboshi Tumani', value: 'Buloqboshi Tumani' },
  { label: 'Bulowice', value: 'Bulowice' },
  { label: 'Bulphan', value: 'Bulphan' },
  { label: 'Bulqizë', value: 'Bulqizë' },
  { label: 'Bulri', value: 'Bulri' },
  { label: 'Bultei', value: 'Bultei' },
  { label: 'Bulu', value: 'Bulu' },
  { label: 'Bulu', value: 'Bulu' },
  { label: 'Bulualto', value: 'Bulualto' },
  { label: 'Bulualto', value: 'Bulualto' },
  { label: 'Buluan', value: 'Buluan' },
  { label: 'Buluan', value: 'Buluan' },
  { label: 'Buluang', value: 'Buluang' },
  { label: 'Buluang', value: 'Buluang' },
  { label: 'Buluangan', value: 'Buluangan' },
  { label: 'Bulung’Ur', value: 'Bulung’Ur' },
  { label: 'Bulungu', value: 'Bulungu' },
  { label: 'Bulunskiy Rayon', value: 'Bulunskiy Rayon' },
  { label: 'Bulusan', value: 'Bulusan' },
  { label: 'Bulverde', value: 'Bulverde' },
  { label: 'Bulz', value: 'Bulz' },
  { label: 'Bulzeşti', value: 'Bulzeşti' },
  { label: 'Bulzeștii De Sus', value: 'Bulzeștii De Sus' },
  { label: 'Bulzi', value: 'Bulzi' },
  { label: 'Bumba', value: 'Bumba' },
  { label: 'Bumbaran', value: 'Bumbaran' },
  { label: 'Bumbeşti-Jiu', value: 'Bumbeşti-Jiu' },
  { label: 'Bumbuna', value: 'Bumbuna' },
  { label: 'Bumehen', value: 'Bumehen' },
  { label: 'Bumpe', value: 'Bumpe' },
  { label: 'Buna', value: 'Buna' },
  { label: 'Bunawan', value: 'Bunawan' },
  { label: 'Bunawan', value: 'Bunawan' },
  { label: 'Bunbury', value: 'Bunbury' },
  { label: 'Bunbury', value: 'Bunbury' },
  { label: 'Bunche Park', value: 'Bunche Park' },
  { label: 'Bunclody', value: 'Bunclody' },
  { label: 'Buncombe County', value: 'Buncombe County' },
  { label: 'Buncrana', value: 'Buncrana' },
  { label: 'Bundaberg', value: 'Bundaberg' },
  { label: 'Bundaberg East', value: 'Bundaberg East' },
  { label: 'Bundaberg North', value: 'Bundaberg North' },
  { label: 'Bundaberg South', value: 'Bundaberg South' },
  { label: 'Bundaberg West', value: 'Bundaberg West' },
  { label: 'Bundall', value: 'Bundall' },
  { label: 'Bundamba', value: 'Bundamba' },
  { label: 'Bundanoon', value: 'Bundanoon' },
  { label: 'Bunde', value: 'Bunde' },
  { label: 'Bunde', value: 'Bunde' },
  { label: 'Bünde', value: 'Bünde' },
  { label: 'Bundeena', value: 'Bundeena' },
  { label: 'Bundenbach', value: 'Bundenbach' },
  { label: 'Bundenthal', value: 'Bundenthal' },
  { label: 'Bundi', value: 'Bundi' },
  { label: 'Bundibugyo', value: 'Bundibugyo' },
  { label: 'Bundoc', value: 'Bundoc' },
  { label: 'Bundoc', value: 'Bundoc' },
  { label: 'Bundoora', value: 'Bundoora' },
  { label: 'Bundoran', value: 'Bundoran' },
  { label: 'Bundu', value: 'Bundu' },
  { label: 'Buner District', value: 'Buner District' },
  { label: 'Buneşti', value: 'Buneşti' },
  { label: 'Buneşti', value: 'Buneşti' },
  { label: 'Buneşti', value: 'Buneşti' },
  { label: 'Bunești', value: 'Bunești' },
  { label: 'Bunga', value: 'Bunga' },
  { label: 'Bunga', value: 'Bunga' },
  { label: 'Bungabon', value: 'Bungabon' },
  { label: 'Bungabon', value: 'Bungabon' },
  { label: 'Bungabon', value: 'Bungabon' },
  { label: 'Bungahan', value: 'Bungahan' },
  { label: 'Bungalow', value: 'Bungalow' },
  { label: 'Bungarribee', value: 'Bungarribee' },
  { label: 'Bungay', value: 'Bungay' },
  { label: 'Bungendore', value: 'Bungendore' },
  { label: 'Bungetu', value: 'Bungetu' },
  { label: 'Bungoma', value: 'Bungoma' },
  { label: 'Bungo-Ōno Shi', value: 'Bungo-Ōno Shi' },
  { label: 'Bungo-Takada-Shi', value: 'Bungo-Takada-Shi' },
  { label: 'Bungoy', value: 'Bungoy' },
  { label: 'Bungsuan', value: 'Bungsuan' },
  { label: 'Bungu', value: 'Bungu' },
  { label: 'Bunguiao', value: 'Bunguiao' },
  { label: 'Bunia', value: 'Bunia' },
  { label: 'Buniel', value: 'Buniel' },
  { label: 'Bunila', value: 'Bunila' },
  { label: 'Buninyong', value: 'Buninyong' },
  { label: 'Bunk Foss', value: 'Bunk Foss' },
  { label: 'Bunkeflostrand', value: 'Bunkeflostrand' },
  { label: 'Bunker Hill', value: 'Bunker Hill' },
  { label: 'Bunker Hill', value: 'Bunker Hill' },
  { label: 'Bunker Hill Village', value: 'Bunker Hill Village' },
  { label: 'Bunkers Hill', value: 'Bunkers Hill' },
  { label: 'Bunkerville', value: 'Bunkerville' },
  { label: 'Bunkie', value: 'Bunkie' },
  { label: 'Bunkpurugu-Nyakpanduri', value: 'Bunkpurugu-Nyakpanduri' },
  { label: 'Bunkyō-Ku', value: 'Bunkyō-Ku' },
  { label: 'Bunnell', value: 'Bunnell' },
  { label: 'Bunnik', value: 'Bunnik' },
  { label: 'Bunog', value: 'Bunog' },
  { label: 'Bunol', value: 'Bunol' },
  { label: 'Bunol', value: 'Bunol' },
  { label: 'Buñol', value: 'Buñol' },
  { label: 'Bunschoten', value: 'Bunschoten' },
  { label: 'Bunteşti', value: 'Bunteşti' },
  { label: 'Buntingford', value: 'Buntingford' },
  { label: 'Buñuel', value: 'Buñuel' },
  { label: 'Bunumbu', value: 'Bunumbu' },
  { label: 'Bununu', value: 'Bununu' },
  { label: 'Bunya', value: 'Bunya' },
  { label: 'Bünyan', value: 'Bünyan' },
  { label: 'Bunyip', value: 'Bunyip' },
  { label: 'Bunyola', value: 'Bunyola' },
  { label: 'Buochs', value: 'Buochs' },
  { label: 'Buôn Ma Thuột', value: 'Buôn Ma Thuột' },
  { label: 'Buonabitacolo', value: 'Buonabitacolo' },
  { label: 'Buonalbergo', value: 'Buonalbergo' },
  { label: 'Buonconvento', value: 'Buonconvento' },
  { label: 'Buonvicino', value: 'Buonvicino' },
  { label: 'Buota Village', value: 'Buota Village' },
  { label: 'Bupyeong-Gu', value: 'Bupyeong-Gu' },
  { label: 'Buqei‘A', value: 'Buqei‘A' },
  { label: 'Bura', value: 'Bura' },
  { label: 'Būrabay', value: 'Būrabay' },
  { label: 'Burabod', value: 'Burabod' },
  { label: 'Buraca', value: 'Buraca' },
  { label: 'Buracan', value: 'Buracan' },
  { label: 'Burago Di Molgora', value: 'Burago Di Molgora' },
  { label: 'Buraidah', value: 'Buraidah' },
  { label: 'Buran', value: 'Buran' },
  { label: 'Burang', value: 'Burang' },
  { label: 'Burano', value: 'Burano' },
  { label: 'Burao', value: 'Burao' },
  { label: 'Burastan', value: 'Burastan' },
  { label: 'Burauen', value: 'Burauen' },
  { label: 'Burauen', value: 'Burauen' },
  { label: 'Buray', value: 'Buray' },
  { label: 'Buraydah', value: 'Buraydah' },
  { label: 'Burayevo', value: 'Burayevo' },
  { label: 'Buraykah', value: 'Buraykah' },
  { label: 'Burayman', value: 'Burayman' },
  { label: 'Burbach', value: 'Burbach' },
  { label: 'Burbage', value: 'Burbage' },
  { label: 'Burbáguena', value: 'Burbáguena' },
  { label: 'Burbank', value: 'Burbank' },
  { label: 'Burbank', value: 'Burbank' },
  { label: 'Burbank', value: 'Burbank' },
  { label: 'Burbank', value: 'Burbank' },
  { label: 'Burbure', value: 'Burbure' },
  { label: 'Burca', value: 'Burca' },
  { label: 'Burcei', value: 'Burcei' },
  { label: 'Burdaard', value: 'Burdaard' },
  { label: 'Burdekin', value: 'Burdekin' },
  { label: 'Burdell', value: 'Burdell' },
  { label: 'Burdeos', value: 'Burdeos' },
  { label: 'Burdinne', value: 'Burdinne' },
  { label: 'Burē', value: 'Burē' },
  { label: 'Bureå', value: 'Bureå' },
  { label: 'Bureau County', value: 'Bureau County' },
  { label: 'Burela', value: 'Burela' },
  { label: 'Büren', value: 'Büren' },
  { label: 'Bureng', value: 'Bureng' },
  { label: 'Bures Saint Mary', value: 'Bures Saint Mary' },
  { label: 'Bures-Sur-Yvette', value: 'Bures-Sur-Yvette' },
  { label: 'Bureta', value: 'Bureta' },
  { label: 'Burevestnik', value: 'Burevestnik' },
  { label: 'Burewala', value: 'Burewala' },
  { label: 'Bureya', value: 'Bureya' },
  { label: 'Bureyskiy Rayon', value: 'Bureyskiy Rayon' },
  { label: 'Burford', value: 'Burford' },
  { label: 'Burford', value: 'Burford' },
  { label: 'Burg', value: 'Burg' },
  { label: 'Burg', value: 'Burg' },
  { label: 'Burg Auf Fehmarn', value: 'Burg Auf Fehmarn' },
  { label: 'Burg Bei Magdeburg', value: 'Burg Bei Magdeburg' },
  { label: 'Burg Stargard', value: 'Burg Stargard' },
  { label: 'Burganes De Valverde', value: 'Burganes De Valverde' },
  { label: 'Burgas', value: 'Burgas' },
  { label: 'Burgau', value: 'Burgau' },
  { label: 'Burgau', value: 'Burgau' },
  { label: 'Burgaw', value: 'Burgaw' },
  { label: 'Burgberg', value: 'Burgberg' },
  { label: 'Burgbernheim', value: 'Burgbernheim' },
  { label: 'Burgbrohl', value: 'Burgbrohl' },
  { label: 'Burgdorf', value: 'Burgdorf' },
  { label: 'Burgdorf', value: 'Burgdorf' },
  { label: 'Burgebrach', value: 'Burgebrach' },
  { label: 'Bürgel', value: 'Bürgel' },
  { label: 'Burgemeesterswijk', value: 'Burgemeesterswijk' },
  { label: 'Burgeo', value: 'Burgeo' },
  { label: 'Burgerbrug', value: 'Burgerbrug' },
  { label: 'Burgersdorp', value: 'Burgersdorp' },
  { label: 'Burgerveen', value: 'Burgerveen' },
  { label: 'Burgervlotbrug', value: 'Burgervlotbrug' },
  { label: 'Burgess Hill', value: 'Burgess Hill' },
  { label: 'Burgettstown', value: 'Burgettstown' },
  { label: 'Burgfried', value: 'Burgfried' },
  { label: 'Burgfried', value: 'Burgfried' },
  { label: 'Burggen', value: 'Burggen' },
  { label: 'Burgh Le Marsh', value: 'Burgh Le Marsh' },
  { label: 'Burghaslach', value: 'Burghaslach' },
  { label: 'Burghaun', value: 'Burghaun' },
  { label: 'Burghausen', value: 'Burghausen' },
  { label: 'Burghead', value: 'Burghead' },
  { label: 'Burgheim', value: 'Burgheim' },
  { label: 'Burgio', value: 'Burgio' },
  { label: 'Burgistein', value: 'Burgistein' },
  { label: 'Burgkirchen An Der Alz', value: 'Burgkirchen An Der Alz' },
  { label: 'Burgkunstadt', value: 'Burgkunstadt' },
  { label: 'Burglauer', value: 'Burglauer' },
  { label: 'Bürglen', value: 'Bürglen' },
  { label: 'Bürglen', value: 'Bürglen' },
  { label: 'Burglengenfeld', value: 'Burglengenfeld' },
  { label: 'Burglesum', value: 'Burglesum' },
  { label: 'Burgo De Ebro El', value: 'Burgo De Ebro El' },
  { label: 'Burgo De Osma-Ciudad De Osma', value: 'Burgo De Osma-Ciudad De Osma' },
  { label: 'Burgo El', value: 'Burgo El' },
  { label: 'Burgo Ranero El', value: 'Burgo Ranero El' },
  { label: 'Burgoberbach', value: 'Burgoberbach' },
  { label: 'Burgohondo', value: 'Burgohondo' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Burgos', value: 'Burgos' },
  { label: 'Burgpreppach', value: 'Burgpreppach' },
  { label: 'Burgrieden', value: 'Burgrieden' },
  { label: 'Burgsalach', value: 'Burgsalach' },
  { label: 'Burgschwalbach', value: 'Burgschwalbach' },
  { label: 'Burgsinn', value: 'Burgsinn' },
  { label: 'Burgsolms', value: 'Burgsolms' },
  { label: 'Burgstädt', value: 'Burgstädt' },
  { label: 'Bürgstadt', value: 'Bürgstadt' },
  { label: 'Burgstetten', value: 'Burgstetten' },
  { label: 'Burgthann', value: 'Burgthann' },
  { label: 'Burgui/Burgi', value: 'Burgui/Burgi' },
  { label: 'Burguillos', value: 'Burguillos' },
  { label: 'Burguillos De Toledo', value: 'Burguillos De Toledo' },
  { label: 'Burguillos Del Cerro', value: 'Burguillos Del Cerro' },
  { label: 'Burgum', value: 'Burgum' },
  { label: 'Burgwerben', value: 'Burgwerben' },
  { label: 'Burgwindheim', value: 'Burgwindheim' },
  { label: 'Burham', value: 'Burham' },
  { label: 'Burhaniye', value: 'Burhaniye' },
  { label: 'Burhanpur', value: 'Burhanpur' },
  { label: 'Burhar', value: 'Burhar' },
  { label: 'Buri', value: 'Buri' },
  { label: 'Buri Ram', value: 'Buri Ram' },
  { label: 'Buriaki', value: 'Buriaki' },
  { label: 'Burias', value: 'Burias' },
  { label: 'Burias', value: 'Burias' },
  { label: 'Burias', value: 'Burias' },
  { label: 'Buriaș', value: 'Buriaș' },
  { label: 'Buriasco', value: 'Buriasco' },
  { label: 'Buribay', value: 'Buribay' },
  { label: 'Burie', value: 'Burie' },
  { label: 'Burien', value: 'Burien' },
  { label: 'Burila Mare', value: 'Burila Mare' },
  { label: 'Burirao', value: 'Burirao' },
  { label: 'Buritama', value: 'Buritama' },
  { label: 'Buriti', value: 'Buriti' },
  { label: 'Buriti Alegre', value: 'Buriti Alegre' },
  { label: 'Buriti Bravo', value: 'Buriti Bravo' },
  { label: 'Buriti De Goiás', value: 'Buriti De Goiás' },
  { label: 'Buriti Do Tocantins', value: 'Buriti Do Tocantins' },
  { label: 'Buriti Dos Lopes', value: 'Buriti Dos Lopes' },
  { label: 'Buriti Dos Montes', value: 'Buriti Dos Montes' },
  { label: 'Buriticá', value: 'Buriticá' },
  { label: 'Buriticupu', value: 'Buriticupu' },
  { label: 'Buritinópolis', value: 'Buritinópolis' },
  { label: 'Buritirama', value: 'Buritirama' },
  { label: 'Buritirana', value: 'Buritirana' },
  { label: 'Buritis', value: 'Buritis' },
  { label: 'Buritis', value: 'Buritis' },
  { label: 'Buritizal', value: 'Buritizal' },
  { label: 'Buritizeiro', value: 'Buritizeiro' },
  { label: 'Buriya', value: 'Buriya' },
  { label: 'Burjassot', value: 'Burjassot' },
  { label: 'Burjuc', value: 'Burjuc' },
  { label: 'Burk', value: 'Burk' },
  { label: 'Burkardroth', value: 'Burkardroth' },
  { label: 'Burkau', value: 'Burkau' },
  { label: 'Burkburnett', value: 'Burkburnett' },
  { label: 'Burke', value: 'Burke' },
  { label: 'Burke', value: 'Burke' },
  { label: 'Burke', value: 'Burke' },
  { label: 'Burke County', value: 'Burke County' },
  { label: 'Burke County', value: 'Burke County' },
  { label: 'Burke County', value: 'Burke County' },
  { label: 'Burkesville', value: 'Burkesville' },
  { label: 'Burkhardtsdorf', value: 'Burkhardtsdorf' },
  { label: 'Burkivtsi', value: 'Burkivtsi' },
  { label: 'Burla', value: 'Burla' },
  { label: 'Burla', value: 'Burla' },
  { label: 'Burla', value: 'Burla' },
  { label: 'Burlada/Burlata', value: 'Burlada/Burlata' },
  { label: 'Burladingen', value: 'Burladingen' },
  { label: 'Burlata', value: 'Burlata' },
  { label: 'Burlats', value: 'Burlats' },
  { label: 'Burlatskoye', value: 'Burlatskoye' },
  { label: 'Burleigh County', value: 'Burleigh County' },
  { label: 'Burleigh Heads', value: 'Burleigh Heads' },
  { label: 'Burleigh Waters', value: 'Burleigh Waters' },
  { label: 'Burleson', value: 'Burleson' },
  { label: 'Burleson County', value: 'Burleson County' },
  { label: 'Burley', value: 'Burley' },
  { label: 'Burley', value: 'Burley' },
  { label: 'Burley', value: 'Burley' },
  { label: 'Burley In Wharfedale', value: 'Burley In Wharfedale' },
  { label: 'Burlin', value: 'Burlin' },
  { label: 'Burlingame', value: 'Burlingame' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington', value: 'Burlington' },
  { label: 'Burlington County', value: 'Burlington County' },
  { label: 'Burmā', value: 'Burmā' },
  { label: 'Burmakino', value: 'Burmakino' },
  { label: 'Burmarrad', value: 'Burmarrad' },
  { label: 'Bürmoos', value: 'Bürmoos' },
  { label: 'Burnaby', value: 'Burnaby' },
  { label: 'Burnage', value: 'Burnage' },
  { label: 'Burnatsky', value: 'Burnatsky' },
  { label: 'Burneside', value: 'Burneside' },
  { label: 'Burnet', value: 'Burnet' },
  { label: 'Burnet County', value: 'Burnet County' },
  { label: 'Burnett County', value: 'Burnett County' },
  { label: 'Burnett Heads', value: 'Burnett Heads' },
  { label: 'Burnettown', value: 'Burnettown' },
  { label: 'Burney', value: 'Burney' },
  { label: 'Burngreave', value: 'Burngreave' },
  { label: 'Burnham', value: 'Burnham' },
  { label: 'Burnham', value: 'Burnham' },
  { label: 'Burnham', value: 'Burnham' },
  { label: 'Burnham', value: 'Burnham' },
  { label: 'Burnham-On-Crouch', value: 'Burnham-On-Crouch' },
  { label: 'Burnham-On-Sea', value: 'Burnham-On-Sea' },
  { label: 'Burnhaupt-Le-Bas', value: 'Burnhaupt-Le-Bas' },
  { label: 'Burnhaupt-Le-Haut', value: 'Burnhaupt-Le-Haut' },
  { label: 'Burnie', value: 'Burnie' },
  { label: 'Burniston', value: 'Burniston' },
  { label: 'Burnley', value: 'Burnley' },
  { label: 'Burnopfield', value: 'Burnopfield' },
  { label: 'Burns', value: 'Burns' },
  { label: 'Burns', value: 'Burns' },
  { label: 'Burns Beach', value: 'Burns Beach' },
  { label: 'Burns Flat', value: 'Burns Flat' },
  { label: 'Burns Harbor', value: 'Burns Harbor' },
  { label: 'Burns Lake', value: 'Burns Lake' },
  { label: 'Burnside', value: 'Burnside' },
  { label: 'Burnside', value: 'Burnside' },
  { label: 'Burnside', value: 'Burnside' },
  { label: 'Burnside Heights', value: 'Burnside Heights' },
  { label: 'Burnsville', value: 'Burnsville' },
  { label: 'Burnsville', value: 'Burnsville' },
  { label: 'Burnt Savannah', value: 'Burnt Savannah' },
  { label: 'Burnt Savannah', value: 'Burnt Savannah' },
  { label: 'Burnt Store Marina', value: 'Burnt Store Marina' },
  { label: 'Burntisland', value: 'Burntisland' },
  { label: 'Burntwood', value: 'Burntwood' },
  { label: 'Burolo', value: 'Burolo' },
  { label: 'Burón', value: 'Burón' },
  { label: 'Büron', value: 'Büron' },
  { label: 'Buronga', value: 'Buronga' },
  { label: 'Buronzo', value: 'Buronzo' },
  { label: 'Buros', value: 'Buros' },
  { label: 'Burow', value: 'Burow' },
  { label: 'Burpengary', value: 'Burpengary' },
  { label: 'Burpengary East', value: 'Burpengary East' },
  { label: 'Burr Ridge', value: 'Burr Ridge' },
  { label: 'Burra', value: 'Burra' },
  { label: 'Burradoo', value: 'Burradoo' },
  { label: 'Burraneer', value: 'Burraneer' },
  { label: 'Burrel', value: 'Burrel' },
  { label: 'Burriana', value: 'Burriana' },
  { label: 'Burrill Lake', value: 'Burrill Lake' },
  { label: 'Burringham', value: 'Burringham' },
  { label: 'Burrington', value: 'Burrington' },
  { label: 'Burrum Heads', value: 'Burrum Heads' },
  { label: 'Burruyacú', value: 'Burruyacú' },
  { label: 'Burry Port', value: 'Burry Port' },
  { label: 'Bürs', value: 'Bürs' },
  { label: 'Burscheid', value: 'Burscheid' },
  { label: 'Burscough', value: 'Burscough' },
  { label: 'Bürserberg', value: 'Bürserberg' },
  { label: 'Burshtyn', value: 'Burshtyn' },
  { label: 'Burshtyns’Ka Mis’Krada', value: 'Burshtyns’Ka Mis’Krada' },
  { label: 'Bursledon', value: 'Bursledon' },
  { label: 'Bürstadt', value: 'Bürstadt' },
  { label: 'Burstwick', value: 'Burstwick' },
  { label: 'Bursuceni', value: 'Bursuceni' },
  { label: 'Burswood', value: 'Burswood' },
  { label: 'Burt', value: 'Burt' },
  { label: 'Burt County', value: 'Burt County' },
  { label: 'Burtenbach', value: 'Burtenbach' },
  { label: 'Burton', value: 'Burton' },
  { label: 'Burton', value: 'Burton' },
  { label: 'Burton', value: 'Burton' },
  { label: 'Burton', value: 'Burton' },
  { label: 'Burton', value: 'Burton' },
  { label: 'Burton Joyce', value: 'Burton Joyce' },
  { label: 'Burton Latimer', value: 'Burton Latimer' },
  { label: 'Burton On The Wolds', value: 'Burton On The Wolds' },
  { label: 'Burton Pidsea', value: 'Burton Pidsea' },
  { label: 'Burton Upon Stather', value: 'Burton Upon Stather' },
  { label: 'Burton Upon Trent', value: 'Burton Upon Trent' },
  { label: 'Burtonsville', value: 'Burtonsville' },
  { label: 'Burtonwood', value: 'Burtonwood' },
  { label: 'Burträsk', value: 'Burträsk' },
  { label: 'Burtunay', value: 'Burtunay' },
  { label: 'Buruanga', value: 'Buruanga' },
  { label: 'Burueneşti', value: 'Burueneşti' },
  { label: 'Buruienești', value: 'Buruienești' },
  { label: 'Burujón', value: 'Burujón' },
  { label: 'Burumburum', value: 'Burumburum' },
  { label: 'Burunday', value: 'Burunday' },
  { label: 'Bururi', value: 'Bururi' },
  { label: 'Burutu', value: 'Burutu' },
  { label: 'Burwash', value: 'Burwash' },
  { label: 'Burwell', value: 'Burwell' },
  { label: 'Burwell', value: 'Burwell' },
  { label: 'Burwood', value: 'Burwood' },
  { label: 'Burwood', value: 'Burwood' },
  { label: 'Burwood East', value: 'Burwood East' },
  { label: 'Bury', value: 'Bury' },
  { label: 'Bury', value: 'Bury' },
  { label: 'Bury St Edmunds', value: 'Bury St Edmunds' },
  { label: 'Buryn’', value: 'Buryn’' },
  { label: 'Burzenin', value: 'Burzenin' },
  { label: 'Burzuc', value: 'Burzuc' },
  { label: 'Busachi', value: 'Busachi' },
  { label: 'Bușag', value: 'Bușag' },
  { label: 'Busalla', value: 'Busalla' },
  { label: 'Busan', value: 'Busan' },
  { label: 'Busana', value: 'Busana' },
  { label: 'Busano', value: 'Busano' },
  { label: 'Busay', value: 'Busay' },
  { label: 'Buşayrā', value: 'Buşayrā' },
  { label: 'Busbanzá', value: 'Busbanzá' },
  { label: 'Busby', value: 'Busby' },
  { label: 'Busby', value: 'Busby' },
  { label: 'Busca', value: 'Busca' },
  { label: 'Bușca', value: 'Bușca' },
  { label: 'Buscate', value: 'Buscate' },
  { label: 'Buscemi', value: 'Buscemi' },
  { label: 'Buscoldo', value: 'Buscoldo' },
  { label: 'Busdi', value: 'Busdi' },
  { label: 'Busdorf', value: 'Busdorf' },
  { label: 'Busembatia', value: 'Busembatia' },
  { label: 'Busenberg', value: 'Busenberg' },
  { label: 'Buseno', value: 'Buseno' },
  { label: 'Buseresere', value: 'Buseresere' },
  { label: 'Buseto Palizzolo', value: 'Buseto Palizzolo' },
  { label: 'Būsh', value: 'Būsh' },
  { label: 'Bushehr', value: 'Bushehr' },
  { label: 'Bushey', value: 'Bushey' },
  { label: 'Bushland', value: 'Bushland' },
  { label: 'Bushland Beach', value: 'Bushland Beach' },
  { label: 'Bushmills', value: 'Bushmills' },
  { label: 'Bushnell', value: 'Bushnell' },
  { label: 'Bushnell', value: 'Bushnell' },
  { label: 'Bushtyno', value: 'Bushtyno' },
  { label: 'Bushwick', value: 'Bushwick' },
  { label: 'Bushy Park', value: 'Bushy Park' },
  { label: 'Bushyhead', value: 'Bushyhead' },
  { label: 'Busia', value: 'Busia' },
  { label: 'Busia', value: 'Busia' },
  { label: 'Busigny', value: 'Busigny' },
  { label: 'Busilak', value: 'Busilak' },
  { label: 'Busilak', value: 'Busilak' },
  { label: 'Busing', value: 'Busing' },
  { label: 'Businga', value: 'Businga' },
  { label: 'Büsingen', value: 'Büsingen' },
  { label: 'Busko-Zdrój', value: 'Busko-Zdrój' },
  { label: 'Busnago', value: 'Busnago' },
  { label: 'Busnes', value: 'Busnes' },
  { label: 'Buso', value: 'Buso' },
  { label: 'Busok', value: 'Busok' },
  { label: 'Busok', value: 'Busok' },
  { label: 'Busot', value: 'Busot' },
  { label: 'Busovača', value: 'Busovača' },
  { label: 'Busquístar', value: 'Busquístar' },
  { label: 'Buşrá Ash Shām', value: 'Buşrá Ash Shām' },
  { label: 'Bussac-Sur-Charente', value: 'Bussac-Sur-Charente' },
  { label: 'Bussang', value: 'Bussang' },
  { label: 'Busselton', value: 'Busselton' },
  { label: 'Busselton City Cenre', value: 'Busselton City Cenre' },
  { label: 'Büsserach', value: 'Büsserach' },
  { label: 'Bussero', value: 'Bussero' },
  { label: 'Busseto', value: 'Busseto' },
  { label: 'Bussi Sul Tirino', value: 'Bussi Sul Tirino' },
  { label: 'Bussière-Dunoise', value: 'Bussière-Dunoise' },
  { label: 'Bussière-Galant', value: 'Bussière-Galant' },
  { label: 'Bussière-Poitevine', value: 'Bussière-Poitevine' },
  { label: 'Bussières', value: 'Bussières' },
  { label: 'Bussigny', value: 'Bussigny' },
  { label: 'Busso', value: 'Busso' },
  { label: 'Bussolengo', value: 'Bussolengo' },
  { label: 'Bussoleno', value: 'Bussoleno' },
  { label: 'Bussum', value: 'Bussum' },
  { label: 'Bussy-Saint-Georges', value: 'Bussy-Saint-Georges' },
  { label: 'Bustamante', value: 'Bustamante' },
  { label: 'Bustamante', value: 'Bustamante' },
  { label: 'Bustandar', value: 'Bustandar' },
  { label: 'Bustares', value: 'Bustares' },
  { label: 'Bustarviejo', value: 'Bustarviejo' },
  { label: 'Buste El', value: 'Buste El' },
  { label: 'Buštěhrad', value: 'Buštěhrad' },
  { label: 'Buşteni', value: 'Buşteni' },
  { label: 'Bustillo De Chaves', value: 'Bustillo De Chaves' },
  { label: 'Bustillo De La Vega', value: 'Bustillo De La Vega' },
  { label: 'Bustillo Del Oro', value: 'Bustillo Del Oro' },
  { label: 'Bustillo Del Páramo', value: 'Bustillo Del Páramo' },
  { label: 'Bustillo Del Páramo De Carrión', value: 'Bustillo Del Páramo De Carrión' },
  { label: 'Busto Arsizio', value: 'Busto Arsizio' },
  { label: 'Busto De Bureba', value: 'Busto De Bureba' },
  { label: 'Busto El', value: 'Busto El' },
  { label: 'Busto Garolfo', value: 'Busto Garolfo' },
  { label: 'Bŭston', value: 'Bŭston' },
  { label: 'Bŭstonqal’A', value: 'Bŭstonqal’A' },
  { label: 'Bustos', value: 'Bustos' },
  { label: 'Bustos', value: 'Bustos' },
  { label: 'Bustuchin', value: 'Bustuchin' },
  { label: 'Busturia', value: 'Busturia' },
  { label: 'Büsum', value: 'Büsum' },
  { label: 'Buta', value: 'Buta' },
  { label: 'Buta Ranquil', value: 'Buta Ranquil' },
  { label: 'Butag', value: 'Butag' },
  { label: 'Butajīra', value: 'Butajīra' },
  { label: 'Butare', value: 'Butare' },
  { label: 'Butaritari', value: 'Butaritari' },
  { label: 'Butazon', value: 'Butazon' },
  { label: 'Butazon', value: 'Butazon' },
  { label: 'Butchers Hill', value: 'Butchers Hill' },
  { label: 'Butea', value: 'Butea' },
  { label: 'Buteasa', value: 'Buteasa' },
  { label: 'Butel', value: 'Butel' },
  { label: 'Butembo', value: 'Butembo' },
  { label: 'Buteni', value: 'Buteni' },
  { label: 'Butera', value: 'Butera' },
  { label: 'Butere', value: 'Butere' },
  { label: 'Butgenbach', value: 'Butgenbach' },
  { label: 'Butha-Buthe', value: 'Butha-Buthe' },
  { label: 'Buti', value: 'Buti' },
  { label: 'Butiá', value: 'Butiá' },
  { label: 'Butiama', value: 'Butiama' },
  { label: 'Butig', value: 'Butig' },
  { label: 'Butig', value: 'Butig' },
  { label: 'Butigui', value: 'Butigui' },
  { label: 'Butigui', value: 'Butigui' },
  { label: 'Butimanu', value: 'Butimanu' },
  { label: 'Butjadingen', value: 'Butjadingen' },
  { label: 'Butka', value: 'Butka' },
  { label: 'Butler', value: 'Butler' },
  { label: 'Butler', value: 'Butler' },
  { label: 'Butler', value: 'Butler' },
  { label: 'Butler', value: 'Butler' },
  { label: 'Butler', value: 'Butler' },
  { label: 'Butler', value: 'Butler' },
  { label: 'Butler', value: 'Butler' },
  { label: 'Butler', value: 'Butler' },
  { label: 'Butler Beach', value: 'Butler Beach' },
  { label: 'Butler County', value: 'Butler County' },
  { label: 'Butler County', value: 'Butler County' },
  { label: 'Butler County', value: 'Butler County' },
  { label: 'Butler County', value: 'Butler County' },
  { label: 'Butler County', value: 'Butler County' },
  { label: 'Butler County', value: 'Butler County' },
  { label: 'Butler County', value: 'Butler County' },
  { label: 'Butler County', value: 'Butler County' },
  { label: 'Butner', value: 'Butner' },
  { label: 'Butoieşti', value: 'Butoieşti' },
  { label: 'Butry-Sur-Oise', value: 'Butry-Sur-Oise' },
  { label: 'Bütschwil', value: 'Bütschwil' },
  { label: 'Buttaba', value: 'Buttaba' },
  { label: 'Buttapietra', value: 'Buttapietra' },
  { label: 'Butte', value: 'Butte' },
  { label: 'Butte', value: 'Butte' },
  { label: 'Butte', value: 'Butte' },
  { label: 'Butte County', value: 'Butte County' },
  { label: 'Butte County', value: 'Butte County' },
  { label: 'Butte County', value: 'Butte County' },
  { label: 'Büttelborn', value: 'Büttelborn' },
  { label: 'Buttelstedt', value: 'Buttelstedt' },
  { label: 'Buttenheim', value: 'Buttenheim' },
  { label: 'Buttenwiesen', value: 'Buttenwiesen' },
  { label: 'Butterhuizen', value: 'Butterhuizen' },
  { label: 'Butterwick', value: 'Butterwick' },
  { label: 'Butterworth', value: 'Butterworth' },
  { label: 'Butterworth', value: 'Butterworth' },
  { label: 'Butte-Silver Bow (Balance)', value: 'Butte-Silver Bow (Balance)' },
  { label: 'Bütthard', value: 'Bütthard' },
  { label: 'Buttigliera Alta', value: 'Buttigliera Alta' },
  { label: 'Buttigliera Dasti', value: 'Buttigliera Dasti' },
  { label: 'Buttisholz', value: 'Buttisholz' },
  { label: 'Buttlar', value: 'Buttlar' },
  { label: 'Buttonwillow', value: 'Buttonwillow' },
  { label: 'Buttrio', value: 'Buttrio' },
  { label: 'Butts County', value: 'Butts County' },
  { label: 'Buttstädt', value: 'Buttstädt' },
  { label: 'Büttstedt', value: 'Büttstedt' },
  { label: 'Butt-Up', value: 'Butt-Up' },
  { label: 'Buttwil', value: 'Buttwil' },
  { label: 'Butuan', value: 'Butuan' },
  { label: 'Butuan', value: 'Butuan' },
  { label: 'Butubut Norte', value: 'Butubut Norte' },
  { label: 'Butubut Norte', value: 'Butubut Norte' },
  { label: 'Butulan', value: 'Butulan' },
  { label: 'Butulan', value: 'Butulan' },
  { label: 'Butulan', value: 'Butulan' },
  { label: 'Buturlino', value: 'Buturlino' },
  { label: 'Buturlinovka', value: 'Buturlinovka' },
  { label: 'Buturlinovskiy Rayon', value: 'Buturlinovskiy Rayon' },
  { label: 'Buturugeni', value: 'Buturugeni' },
  { label: 'Butzbach', value: 'Butzbach' },
  { label: 'Bützow', value: 'Bützow' },
  { label: 'Buug', value: 'Buug' },
  { label: 'Buulobarde', value: 'Buulobarde' },
  { label: 'Buur Gaabo', value: 'Buur Gaabo' },
  { label: 'Buurhakaba', value: 'Buurhakaba' },
  { label: 'Buus', value: 'Buus' },
  { label: 'Buvuma District', value: 'Buvuma District' },
  { label: 'Buwenge', value: 'Buwenge' },
  { label: 'Buxar', value: 'Buxar' },
  { label: 'Buxerolles', value: 'Buxerolles' },
  { label: 'Buxheim', value: 'Buxheim' },
  { label: 'Buxières-Les-Mines', value: 'Buxières-Les-Mines' },
  { label: 'Buxted', value: 'Buxted' },
  { label: 'Buxtehude', value: 'Buxtehude' },
  { label: 'Buxton', value: 'Buxton' },
  { label: 'Buxton', value: 'Buxton' },
  { label: 'Buxton', value: 'Buxton' },
  { label: 'Buxton', value: 'Buxton' },
  { label: 'Buxy', value: 'Buxy' },
  { label: 'Buy', value: 'Buy' },
  { label: 'Buyabod', value: 'Buyabod' },
  { label: 'Buyaqchi', value: 'Buyaqchi' },
  { label: 'Buyeo', value: 'Buyeo' },
  { label: 'Buyeo-Gun', value: 'Buyeo-Gun' },
  { label: 'Buyerak-Popovsky', value: 'Buyerak-Popovsky' },
  { label: 'Buynaksk', value: 'Buynaksk' },
  { label: 'Buynakskiy Rayon', value: 'Buynakskiy Rayon' },
  { label: 'Buynichy', value: 'Buynichy' },
  { label: 'Buyo', value: 'Buyo' },
  { label: 'Buyskiy Rayon', value: 'Buyskiy Rayon' },
  { label: 'Buyuan', value: 'Buyuan' },
  { label: 'Büyükçekmece', value: 'Büyükçekmece' },
  { label: 'Büyükorhan', value: 'Büyükorhan' },
  { label: 'Buza', value: 'Buza' },
  { label: 'Buzançais', value: 'Buzançais' },
  { label: 'Buzău', value: 'Buzău' },
  { label: 'Buzdohanj', value: 'Buzdohanj' },
  { label: 'Buzdyak', value: 'Buzdyak' },
  { label: 'Buzdyakskiy Rayon', value: 'Buzdyakskiy Rayon' },
  { label: 'Buzen', value: 'Buzen' },
  { label: 'Buzen-Shi', value: 'Buzen-Shi' },
  { label: 'Buzescu', value: 'Buzescu' },
  { label: 'Buzești', value: 'Buzești' },
  { label: 'Buzet', value: 'Buzet' },
  { label: 'Buzet-Sur-Baïse', value: 'Buzet-Sur-Baïse' },
  { label: 'Buzet-Sur-Tarn', value: 'Buzet-Sur-Tarn' },
  { label: 'Buzhakan', value: 'Buzhakan' },
  { label: 'Buziaş', value: 'Buziaş' },
  { label: 'Bužim', value: 'Bužim' },
  { label: 'Buznea', value: 'Buznea' },
  { label: 'Buzoeşti', value: 'Buzoeşti' },
  { label: 'Buzovna', value: 'Buzovna' },
  { label: 'Buzuluk', value: 'Buzuluk' },
  { label: 'Buzzards Bay', value: 'Buzzards Bay' },
  { label: 'Bweyogerere', value: 'Bweyogerere' },
  { label: 'Bwizibwera', value: 'Bwizibwera' },
  { label: 'Byadgi', value: 'Byadgi' },
  { label: 'Byahoml’', value: 'Byahoml’' },
  { label: 'Byakabanda', value: 'Byakabanda' },
  { label: 'Byala', value: 'Byala' },
  { label: 'Byala Cherkva', value: 'Byala Cherkva' },
  { label: 'Byala Slatina', value: 'Byala Slatina' },
  { label: 'Byalynichy', value: 'Byalynichy' },
  { label: 'Byalynitski Rayon', value: 'Byalynitski Rayon' },
  { label: 'Byarezinski Rayon', value: 'Byarezinski Rayon' },
  { label: 'Byaroza', value: 'Byaroza' },
  { label: 'Byarozawka', value: 'Byarozawka' },
  { label: 'Byarozawski Rayon', value: 'Byarozawski Rayon' },
  { label: 'Bybrook', value: 'Bybrook' },
  { label: 'Bychawa', value: 'Bychawa' },
  { label: 'Byculla', value: 'Byculla' },
  { label: 'Byczyna', value: 'Byczyna' },
  { label: 'Bydgoszcz', value: 'Bydgoszcz' },
  { label: 'Byelaazyorsk', value: 'Byelaazyorsk' },
  { label: 'Byera Village', value: 'Byera Village' },
  { label: 'Byerazino', value: 'Byerazino' },
  { label: 'Byers', value: 'Byers' },
  { label: 'Byeshankovitski Rayon', value: 'Byeshankovitski Rayon' },
  { label: 'Byesville', value: 'Byesville' },
  { label: 'Byfield', value: 'Byfield' },
  { label: 'Byford', value: 'Byford' },
  { label: 'Byhalia', value: 'Byhalia' },
  { label: 'Bykhaw', value: 'Bykhaw' },
  { label: 'Bykivka', value: 'Bykivka' },
  { label: 'Bykivka', value: 'Bykivka' },
  { label: 'Bykov', value: 'Bykov' },
  { label: 'Bykovo', value: 'Bykovo' },
  { label: 'Bykovo Volgograd Oblast', value: 'Bykovo Volgograd Oblast' },
  { label: 'Bylas', value: 'Bylas' },
  { label: 'Bylym', value: 'Bylym' },
  { label: 'Byndoor', value: 'Byndoor' },
  { label: 'Byng', value: 'Byng' },
  { label: 'Bynum', value: 'Bynum' },
  { label: 'Byram', value: 'Byram' },
  { label: 'Byram', value: 'Byram' },
  { label: 'Byram', value: 'Byram' },
  { label: 'Byrdstown', value: 'Byrdstown' },
  { label: 'Byrnes Mill', value: 'Byrnes Mill' },
  { label: 'Byron', value: 'Byron' },
  { label: 'Byron', value: 'Byron' },
  { label: 'Byron', value: 'Byron' },
  { label: 'Byron', value: 'Byron' },
  { label: 'Byron Bay', value: 'Byron Bay' },
  { label: 'Byron Center', value: 'Byron Center' },
  { label: 'Byron Shire', value: 'Byron Shire' },
  { label: 'Byšice', value: 'Byšice' },
  { label: 'Byske', value: 'Byske' },
  { label: 'Býšť', value: 'Býšť' },
  { label: 'Bystra', value: 'Bystra' },
  { label: 'Bystra', value: 'Bystra' },
  { label: 'Bystřany', value: 'Bystřany' },
  { label: 'Bystré', value: 'Bystré' },
  { label: 'Bystřec', value: 'Bystřec' },
  { label: 'Bystřice', value: 'Bystřice' },
  { label: 'Bystřice', value: 'Bystřice' },
  { label: 'Bystřice Nad Pernštejnem', value: 'Bystřice Nad Pernštejnem' },
  { label: 'Bystřice Pod Hostýnem', value: 'Bystřice Pod Hostýnem' },
  { label: 'Bystrinskiy Rayon', value: 'Bystrinskiy Rayon' },
  { label: 'Bystrogorskiy', value: 'Bystrogorskiy' },
  { label: 'Bystrom', value: 'Bystrom' },
  { label: 'Bystryanka', value: 'Bystryanka' },
  { label: 'Bystrytsya', value: 'Bystrytsya' },
  { label: 'Bystryy Istok', value: 'Bystryy Istok' },
  { label: 'Bystrzyca', value: 'Bystrzyca' },
  { label: 'Bystrzyca Kłodzka', value: 'Bystrzyca Kłodzka' },
  { label: 'Bytča', value: 'Bytča' },
  { label: 'Bytkiv', value: 'Bytkiv' },
  { label: 'Bytnica', value: 'Bytnica' },
  { label: 'Bytom', value: 'Bytom' },
  { label: 'Bytom Odrzański', value: 'Bytom Odrzański' },
  { label: 'Bytoń', value: 'Bytoń' },
  { label: 'Bytosh’', value: 'Bytosh’' },
  { label: 'Bytów', value: 'Bytów' },
  { label: 'Byumba', value: 'Byumba' },
  { label: 'Byurakan', value: 'Byurakan' },
  { label: 'Byuravan', value: 'Byuravan' },
  { label: 'Byureghavan', value: 'Byureghavan' },
  { label: 'Bywong', value: 'Bywong' },
  { label: 'Bzenec', value: 'Bzenec' },
  { label: 'Bzou', value: 'Bzou' },
  { label: 'C.A. Rosetti', value: 'C.A. Rosetti' },
  { label: 'C.A. Rosetti', value: 'C.A. Rosetti' },
  { label: 'Ca Dandrea', value: 'Ca Dandrea' },
  { label: 'Cà Dè Fabbri', value: 'Cà Dè Fabbri' },
  { label: 'Ca Degli Oppi', value: 'Ca Degli Oppi' },
  { label: 'Ca Di Sola', value: 'Ca Di Sola' },
  { label: 'Cà Mau', value: 'Cà Mau' },
  { label: 'Ca Rainati', value: 'Ca Rainati' },
  { label: 'Ca Savio', value: 'Ca Savio' },
  { label: 'Ca Tiepolo', value: 'Ca Tiepolo' },
  { label: 'Caabiangan', value: 'Caabiangan' },
  { label: 'Caabiangan', value: 'Caabiangan' },
  { label: 'Caacupé', value: 'Caacupé' },
  { label: 'Caaguazú', value: 'Caaguazú' },
  { label: 'Caála', value: 'Caála' },
  { label: 'Caapiranga', value: 'Caapiranga' },
  { label: 'Caaporã', value: 'Caaporã' },
  { label: 'Caapucú', value: 'Caapucú' },
  { label: 'Caarapó', value: 'Caarapó' },
  { label: 'Caatiba', value: 'Caatiba' },
  { label: 'Caazapá', value: 'Caazapá' },
  { label: 'Caba', value: 'Caba' },
  { label: 'Caba', value: 'Caba' },
  { label: 'Cabacao', value: 'Cabacao' },
  { label: 'Cabaceiras', value: 'Cabaceiras' },
  { label: 'Cabaceiras Do Paraguaçu', value: 'Cabaceiras Do Paraguaçu' },
  { label: 'Cabacés', value: 'Cabacés' },
  { label: 'Cabaco El', value: 'Cabaco El' },
  { label: 'Cabacungan', value: 'Cabacungan' },
  { label: 'Cabacuñgan', value: 'Cabacuñgan' },
  { label: 'Cabacuñgan', value: 'Cabacuñgan' },
  { label: 'Cabadbaran', value: 'Cabadbaran' },
  { label: 'Cabadbaran', value: 'Cabadbaran' },
  { label: 'Cabadiangan', value: 'Cabadiangan' },
  { label: 'Cabagan', value: 'Cabagan' },
  { label: 'Cabagan', value: 'Cabagan' },
  { label: 'Cabaiguán', value: 'Cabaiguán' },
  { label: 'Cabalaoangan', value: 'Cabalaoangan' },
  { label: 'Cabalaoangan', value: 'Cabalaoangan' },
  { label: 'Cabalawan', value: 'Cabalawan' },
  { label: 'Cabalitian', value: 'Cabalitian' },
  { label: 'Cabalitian', value: 'Cabalitian' },
  { label: 'Caballar', value: 'Caballar' },
  { label: 'Caballero', value: 'Caballero' },
  { label: 'Caballococha', value: 'Caballococha' },
  { label: 'Cabaluay', value: 'Cabaluay' },
  { label: 'Caban', value: 'Caban' },
  { label: 'Cabana De Bergantiños', value: 'Cabana De Bergantiños' },
  { label: 'Cabanabona', value: 'Cabanabona' },
  { label: 'Cabanac-Et-Villagrains', value: 'Cabanac-Et-Villagrains' },
  { label: 'Cabanas', value: 'Cabanas' },
  { label: 'Cabañas', value: 'Cabañas' },
  { label: 'Cabañas', value: 'Cabañas' },
  { label: 'Cabañas', value: 'Cabañas' },
  { label: 'Cabañas De Ebro', value: 'Cabañas De Ebro' },
  { label: 'Cabañas De La Sagra', value: 'Cabañas De La Sagra' },
  { label: 'Cabañas De Polendos', value: 'Cabañas De Polendos' },
  { label: 'Cabañas De Sayago', value: 'Cabañas De Sayago' },
  { label: 'Cabanas De Tavira', value: 'Cabanas De Tavira' },
  { label: 'Cabanas De Torres', value: 'Cabanas De Torres' },
  { label: 'Cabanas De Torres', value: 'Cabanas De Torres' },
  { label: 'Cabanas De Viriato', value: 'Cabanas De Viriato' },
  { label: 'Cabañas De Yepes', value: 'Cabañas De Yepes' },
  { label: 'Cabañas Del Castillo', value: 'Cabañas Del Castillo' },
  { label: 'Cabañas Raras', value: 'Cabañas Raras' },
  { label: 'Cabanatuan', value: 'Cabanatuan' },
  { label: 'Cabanatuan City', value: 'Cabanatuan City' },
  { label: 'Cabanbanan', value: 'Cabanbanan' },
  { label: 'Cabanbanan', value: 'Cabanbanan' },
  { label: 'Cabanelles', value: 'Cabanelles' },
  { label: 'Cabanes', value: 'Cabanes' },
  { label: 'Cabañes De Esgueva', value: 'Cabañes De Esgueva' },
  { label: 'Cabangahan', value: 'Cabangahan' },
  { label: 'Cabangahan', value: 'Cabangahan' },
  { label: 'Cabangan', value: 'Cabangan' },
  { label: 'Cabangan', value: 'Cabangan' },
  { label: 'Cabanglasan', value: 'Cabanglasan' },
  { label: 'Cabanillas', value: 'Cabanillas' },
  { label: 'Cabanillas De La Sierra', value: 'Cabanillas De La Sierra' },
  { label: 'Cabanillas Del Campo', value: 'Cabanillas Del Campo' },
  { label: 'Cabannes', value: 'Cabannes' },
  { label: 'Cabannungan Second', value: 'Cabannungan Second' },
  { label: 'Cabannungan Second', value: 'Cabannungan Second' },
  { label: 'Cabano', value: 'Cabano' },
  { label: 'Cabano', value: 'Cabano' },
  { label: 'Cabanyes Les', value: 'Cabanyes Les' },
  { label: 'Cabaret', value: 'Cabaret' },
  { label: 'Cabarete', value: 'Cabarete' },
  { label: 'Cabariot', value: 'Cabariot' },
  { label: 'Cabarita', value: 'Cabarita' },
  { label: 'Cabaritan East', value: 'Cabaritan East' },
  { label: 'Cabaritan East', value: 'Cabaritan East' },
  { label: 'Cabarlah', value: 'Cabarlah' },
  { label: 'Cabarroguis', value: 'Cabarroguis' },
  { label: 'Cabarroguis', value: 'Cabarroguis' },
  { label: 'Cabarrus County', value: 'Cabarrus County' },
  { label: 'Cabasse', value: 'Cabasse' },
  { label: 'Cabatang', value: 'Cabatang' },
  { label: 'Cabatuan', value: 'Cabatuan' },
  { label: 'Cabatuan', value: 'Cabatuan' },
  { label: 'Cabatuan', value: 'Cabatuan' },
  { label: 'Cabatuan', value: 'Cabatuan' },
  { label: 'Cabay', value: 'Cabay' },
  { label: 'Cabay', value: 'Cabay' },
  { label: 'Cabay', value: 'Cabay' },
  { label: 'Cabayangan', value: 'Cabayangan' },
  { label: 'Cabayangan', value: 'Cabayangan' },
  { label: 'Cabayangan', value: 'Cabayangan' },
  { label: 'Cabayaoasan', value: 'Cabayaoasan' },
  { label: 'Cabayaoasan', value: 'Cabayaoasan' },
  { label: 'Cabazon', value: 'Cabazon' },
  { label: 'Cabcab', value: 'Cabcab' },
  { label: 'Cabcaben', value: 'Cabcaben' },
  { label: 'Cabcaben', value: 'Cabcaben' },
  { label: 'Cabeça', value: 'Cabeça' },
  { label: 'Cabeça Gorda', value: 'Cabeça Gorda' },
  { label: 'Cabeceira Grande', value: 'Cabeceira Grande' },
  { label: 'Cabeceiras', value: 'Cabeceiras' },
  { label: 'Cabeceiras De Basto', value: 'Cabeceiras De Basto' },
  { label: 'Cabeceiras Do Piauí', value: 'Cabeceiras Do Piauí' },
  { label: 'Cabecera De Indígenas', value: 'Cabecera De Indígenas' },
  { label: 'Cabedelo', value: 'Cabedelo' },
  { label: 'Cabell County', value: 'Cabell County' },
  { label: 'Cabella Ligure', value: 'Cabella Ligure' },
  { label: 'Cabestany', value: 'Cabestany' },
  { label: 'Căbeşti', value: 'Căbeşti' },
  { label: 'Cabeza De Béjar La', value: 'Cabeza De Béjar La' },
  { label: 'Cabeza De Toro', value: 'Cabeza De Toro' },
  { label: 'Cabeza Del Buey', value: 'Cabeza Del Buey' },
  { label: 'Cabeza Del Caballo', value: 'Cabeza Del Caballo' },
  { label: 'Cabeza La Vaca', value: 'Cabeza La Vaca' },
  { label: 'Cabezabellosa', value: 'Cabezabellosa' },
  { label: 'Cabezabellosa De La Calzada', value: 'Cabezabellosa De La Calzada' },
  { label: 'Cabezamesada', value: 'Cabezamesada' },
  { label: 'Cabezarados', value: 'Cabezarados' },
  { label: 'Cabezarrubias Del Puerto', value: 'Cabezarrubias Del Puerto' },
  { label: 'Cabezas', value: 'Cabezas' },
  { label: 'Cabezas De Alambre', value: 'Cabezas De Alambre' },
  { label: 'Cabezas De San Juan Las', value: 'Cabezas De San Juan Las' },
  { label: 'Cabezas Del Pozo', value: 'Cabezas Del Pozo' },
  { label: 'Cabezas Del Villar', value: 'Cabezas Del Villar' },
  { label: 'Cabezas Rubias', value: 'Cabezas Rubias' },
  { label: 'Cabezón', value: 'Cabezón' },
  { label: 'Cabezón De Cameros', value: 'Cabezón De Cameros' },
  { label: 'Cabezón De La Sal', value: 'Cabezón De La Sal' },
  { label: 'Cabezón De La Sierra', value: 'Cabezón De La Sierra' },
  { label: 'Cabezón De Liébana', value: 'Cabezón De Liébana' },
  { label: 'Cabezón De Pisuerga', value: 'Cabezón De Pisuerga' },
  { label: 'Cabezón De Valderaduey', value: 'Cabezón De Valderaduey' },
  { label: 'Cabezuela', value: 'Cabezuela' },
  { label: 'Cabezuela Del Valle', value: 'Cabezuela Del Valle' },
  { label: 'Cabiao', value: 'Cabiao' },
  { label: 'Cabiao', value: 'Cabiao' },
  { label: 'Cabiate', value: 'Cabiate' },
  { label: 'Cabiche/Babonneau', value: 'Cabiche/Babonneau' },
  { label: 'Cabiguan', value: 'Cabiguan' },
  { label: 'Cabilao', value: 'Cabilao' },
  { label: 'Cabilauan', value: 'Cabilauan' },
  { label: 'Cabildo', value: 'Cabildo' },
  { label: 'Cabimas', value: 'Cabimas' },
  { label: 'Cabin John', value: 'Cabin John' },
  { label: 'Cabinda', value: 'Cabinda' },
  { label: 'Cabinteely', value: 'Cabinteely' },
  { label: 'Cabinuangan', value: 'Cabinuangan' },
  { label: 'Cabinuangan', value: 'Cabinuangan' },
  { label: 'Cabinuangan', value: 'Cabinuangan' },
  { label: 'Cabiraoan', value: 'Cabiraoan' },
  { label: 'Cabiraoan', value: 'Cabiraoan' },
  { label: 'Cabitan', value: 'Cabitan' },
  { label: 'Cabittaogan', value: 'Cabittaogan' },
  { label: 'Cabittaogan', value: 'Cabittaogan' },
  { label: 'Cabixi', value: 'Cabixi' },
  { label: 'Cabizuela', value: 'Cabizuela' },
  { label: 'Cable Beach', value: 'Cable Beach' },
  { label: 'Cabo', value: 'Cabo' },
  { label: 'Cabó', value: 'Cabó' },
  { label: 'Cabo Corrientes', value: 'Cabo Corrientes' },
  { label: 'Cabo De Hornos', value: 'Cabo De Hornos' },
  { label: 'Cabo De Santo Agostinho', value: 'Cabo De Santo Agostinho' },
  { label: 'Cabo Frio', value: 'Cabo Frio' },
  { label: 'Cabo Rojo', value: 'Cabo Rojo' },
  { label: 'Cabo Rojo', value: 'Cabo Rojo' },
  { label: 'Cabo Rojo Municipio', value: 'Cabo Rojo Municipio' },
  { label: 'Cabo San Lucas', value: 'Cabo San Lucas' },
  { label: 'Cabo Verde', value: 'Cabo Verde' },
  { label: 'Cabodiongan', value: 'Cabodiongan' },
  { label: 'Cabodiongan', value: 'Cabodiongan' },
  { label: 'Cabog', value: 'Cabog' },
  { label: 'Cabog', value: 'Cabog' },
  { label: 'Cabognon', value: 'Cabognon' },
  { label: 'Cabolafuente', value: 'Cabolafuente' },
  { label: 'Cabonne', value: 'Cabonne' },
  { label: 'Cabool', value: 'Cabool' },
  { label: 'Caboolture', value: 'Caboolture' },
  { label: 'Caboolture South', value: 'Caboolture South' },
  { label: 'Cabot', value: 'Cabot' },
  { label: 'Cabouco', value: 'Cabouco' },
  { label: 'Cabourg', value: 'Cabourg' },
  { label: 'Cabra', value: 'Cabra' },
  { label: 'Cabra', value: 'Cabra' },
  { label: 'Cabra', value: 'Cabra' },
  { label: 'Cabra De Mora', value: 'Cabra De Mora' },
  { label: 'Cabra Del Camp', value: 'Cabra Del Camp' },
  { label: 'Cabra Del Santo Cristo', value: 'Cabra Del Santo Cristo' },
  { label: 'Cabra Número Uno', value: 'Cabra Número Uno' },
  { label: 'Cabral', value: 'Cabral' },
  { label: 'Cabrales', value: 'Cabrales' },
  { label: 'Cabrália Paulista', value: 'Cabrália Paulista' },
  { label: 'Cabramatta', value: 'Cabramatta' },
  { label: 'Cabramatta West', value: 'Cabramatta West' },
  { label: 'Cabranes', value: 'Cabranes' },
  { label: 'Cabras', value: 'Cabras' },
  { label: 'Cabredo', value: 'Cabredo' },
  { label: 'Cabrejas Del Campo', value: 'Cabrejas Del Campo' },
  { label: 'Cabrejas Del Pinar', value: 'Cabrejas Del Pinar' },
  { label: 'Cabrera', value: 'Cabrera' },
  { label: 'Cabrera', value: 'Cabrera' },
  { label: 'Cabrera', value: 'Cabrera' },
  { label: 'Cabrera Danoia', value: 'Cabrera Danoia' },
  { label: 'Cabrera De Mar', value: 'Cabrera De Mar' },
  { label: 'Cabrera La', value: 'Cabrera La' },
  { label: 'Cabrerizos', value: 'Cabrerizos' },
  { label: 'Cabrero', value: 'Cabrero' },
  { label: 'Cabrero', value: 'Cabrero' },
  { label: 'Cabreros Del Monte', value: 'Cabreros Del Monte' },
  { label: 'Cabreros Del Río', value: 'Cabreros Del Río' },
  { label: 'Cabreúva', value: 'Cabreúva' },
  { label: 'Cabricán', value: 'Cabricán' },
  { label: 'Cabrières', value: 'Cabrières' },
  { label: 'Cabriès', value: 'Cabriès' },
  { label: 'Cabrillanes', value: 'Cabrillanes' },
  { label: 'Cabrillas', value: 'Cabrillas' },
  { label: 'Cabrils', value: 'Cabrils' },
  { label: 'Cabris', value: 'Cabris' },
  { label: 'Cabrobó', value: 'Cabrobó' },
  { label: 'Cabucgayan', value: 'Cabucgayan' },
  { label: 'Cabucgayan', value: 'Cabucgayan' },
  { label: 'Cabudare', value: 'Cabudare' },
  { label: 'Cabuérniga', value: 'Cabuérniga' },
  { label: 'Cabugao', value: 'Cabugao' },
  { label: 'Cabugao', value: 'Cabugao' },
  { label: 'Cabugao', value: 'Cabugao' },
  { label: 'Cabugao', value: 'Cabugao' },
  { label: 'Cabul-An', value: 'Cabul-An' },
  { label: 'Cabulay', value: 'Cabulay' },
  { label: 'Cabungan', value: 'Cabungan' },
  { label: 'Cabungan', value: 'Cabungan' },
  { label: 'Caburan', value: 'Caburan' },
  { label: 'Caburan', value: 'Caburan' },
  { label: 'Caburan', value: 'Caburan' },
  { label: 'Cabusao', value: 'Cabusao' },
  { label: 'Cabuya', value: 'Cabuya' },
  { label: 'Cabuya', value: 'Cabuya' },
  { label: 'Cabuya', value: 'Cabuya' },
  { label: 'Cabuyao', value: 'Cabuyao' },
  { label: 'Cabuyaro', value: 'Cabuyaro' },
  { label: 'Cacabelos', value: 'Cacabelos' },
  { label: 'Caçador', value: 'Caçador' },
  { label: 'Cacadu District Municipality', value: 'Cacadu District Municipality' },
  { label: 'Cacahoatán', value: 'Cacahoatán' },
  { label: 'Cacahual', value: 'Cacahual' },
  { label: 'Cacahuatal', value: 'Cacahuatal' },
  { label: 'Čačak', value: 'Čačak' },
  { label: 'Cacalchen', value: 'Cacalchen' },
  { label: 'Cacalomacan', value: 'Cacalomacan' },
  { label: 'Cacalotán', value: 'Cacalotán' },
  { label: 'Cacalotenango', value: 'Cacalotenango' },
  { label: 'Cacaloxúchitl', value: 'Cacaloxúchitl' },
  { label: 'Cacalutla', value: 'Cacalutla' },
  { label: 'Cacao', value: 'Cacao' },
  { label: 'Cacao', value: 'Cacao' },
  { label: 'Cacaopera', value: 'Cacaopera' },
  { label: 'Caçapava', value: 'Caçapava' },
  { label: 'Caçapava Do Sul', value: 'Caçapava Do Sul' },
  { label: 'Cacaté', value: 'Cacaté' },
  { label: 'Cacaulândia', value: 'Cacaulândia' },
  { label: 'Caccamo', value: 'Caccamo' },
  { label: 'Caccuri', value: 'Caccuri' },
  { label: 'Cacem', value: 'Cacem' },
  { label: 'Cacém', value: 'Cacém' },
  { label: 'Cacequi', value: 'Cacequi' },
  { label: 'Cáceres', value: 'Cáceres' },
  { label: 'Cáceres', value: 'Cáceres' },
  { label: 'Cáceres', value: 'Cáceres' },
  { label: 'Cachan', value: 'Cachan' },
  { label: 'Cachar', value: 'Cachar' },
  { label: 'Cache', value: 'Cache' },
  { label: 'Cache County', value: 'Cache County' },
  { label: 'Cache Creek', value: 'Cache Creek' },
  { label: 'Cacheu', value: 'Cacheu' },
  { label: 'Cachí', value: 'Cachí' },
  { label: 'Cachipay', value: 'Cachipay' },
  { label: 'Cachirá', value: 'Cachirá' },
  { label: 'Cachoeira', value: 'Cachoeira' },
  { label: 'Cachoeira Alta', value: 'Cachoeira Alta' },
  { label: 'Cachoeira Da Prata', value: 'Cachoeira Da Prata' },
  { label: 'Cachoeira De Goiás', value: 'Cachoeira De Goiás' },
  { label: 'Cachoeira De Minas', value: 'Cachoeira De Minas' },
  { label: 'Cachoeira De Pajeú', value: 'Cachoeira De Pajeú' },
  { label: 'Cachoeira Do Arari', value: 'Cachoeira Do Arari' },
  { label: 'Cachoeira Do Piriá', value: 'Cachoeira Do Piriá' },
  { label: 'Cachoeira Do Sul', value: 'Cachoeira Do Sul' },
  { label: 'Cachoeira Dos Índios', value: 'Cachoeira Dos Índios' },
  { label: 'Cachoeira Dourada', value: 'Cachoeira Dourada' },
  { label: 'Cachoeira Dourada', value: 'Cachoeira Dourada' },
  { label: 'Cachoeira Grande', value: 'Cachoeira Grande' },
  { label: 'Cachoeira Paulista', value: 'Cachoeira Paulista' },
  { label: 'Cachoeiras', value: 'Cachoeiras' },
  { label: 'Cachoeiras De Macacu', value: 'Cachoeiras De Macacu' },
  { label: 'Cachoeirinha', value: 'Cachoeirinha' },
  { label: 'Cachoeirinha', value: 'Cachoeirinha' },
  { label: 'Cachoeirinha', value: 'Cachoeirinha' },
  { label: 'Cachoeiro De Itapemirim', value: 'Cachoeiro De Itapemirim' },
  { label: 'Cachón', value: 'Cachón' },
  { label: 'Cachorrilla', value: 'Cachorrilla' },
  { label: 'Čachtice', value: 'Čachtice' },
  { label: 'Cacica', value: 'Cacica' },
  { label: 'Cacilhas', value: 'Cacilhas' },
  { label: 'Cacimba De Areia', value: 'Cacimba De Areia' },
  { label: 'Cacimba De Dentro', value: 'Cacimba De Dentro' },
  { label: 'Cacimbas', value: 'Cacimbas' },
  { label: 'Cacimbinhas', value: 'Cacimbinhas' },
  { label: 'Cacín', value: 'Cacín' },
  { label: 'Čačinci', value: 'Čačinci' },
  { label: 'Cacique Doble', value: 'Cacique Doble' },
  { label: 'Căciulata', value: 'Căciulata' },
  { label: 'Căciulați', value: 'Căciulați' },
  { label: 'Cacoa', value: 'Cacoa' },
  { label: 'Cacoa/Babonneau', value: 'Cacoa/Babonneau' },
  { label: 'Cacoal', value: 'Cacoal' },
  { label: 'Cacocum', value: 'Cacocum' },
  { label: 'Caconda', value: 'Caconda' },
  { label: 'Caconde', value: 'Caconde' },
  { label: 'Cacoon', value: 'Cacoon' },
  { label: 'Cacoon Castle', value: 'Cacoon Castle' },
  { label: 'Cácota', value: 'Cácota' },
  { label: 'Cacouna', value: 'Cacouna' },
  { label: 'Cactus', value: 'Cactus' },
  { label: 'Cactus Flat', value: 'Cactus Flat' },
  { label: 'Caçu', value: 'Caçu' },
  { label: 'Cacuaco', value: 'Cacuaco' },
  { label: 'Căcuciu', value: 'Căcuciu' },
  { label: 'Caculé', value: 'Caculé' },
  { label: 'Cadafais', value: 'Cadafais' },
  { label: 'Cadafais', value: 'Cadafais' },
  { label: 'Cadafaz', value: 'Cadafaz' },
  { label: 'Cadagmayan Norte', value: 'Cadagmayan Norte' },
  { label: 'Cadale', value: 'Cadale' },
  { label: 'Cadalen', value: 'Cadalen' },
  { label: 'Cadalso', value: 'Cadalso' },
  { label: 'Cadalso De Los Vidrios', value: 'Cadalso De Los Vidrios' },
  { label: 'Cadaqués', value: 'Cadaqués' },
  { label: 'Cadaujac', value: 'Cadaujac' },
  { label: 'Cadaval', value: 'Cadaval' },
  { label: 'Cadaval', value: 'Cadaval' },
  { label: 'Čađavica', value: 'Čađavica' },
  { label: 'Čadca', value: 'Čadca' },
  { label: 'Caddens', value: 'Caddens' },
  { label: 'Caddington', value: 'Caddington' },
  { label: 'Caddo', value: 'Caddo' },
  { label: 'Caddo County', value: 'Caddo County' },
  { label: 'Caddo Mills', value: 'Caddo Mills' },
  { label: 'Caddo Parish', value: 'Caddo Parish' },
  { label: 'Cade', value: 'Cade' },
  { label: 'Cadea', value: 'Cadea' },
  { label: 'Cadè-Gaida', value: 'Cadè-Gaida' },
  { label: 'Cadegliano-Viconago', value: 'Cadegliano-Viconago' },
  { label: 'Cadelbosco Di Sopra', value: 'Cadelbosco Di Sopra' },
  { label: 'Cadelbosco Di Sotto', value: 'Cadelbosco Di Sotto' },
  { label: 'Cadempino', value: 'Cadempino' },
  { label: 'Caden', value: 'Caden' },
  { label: 'Cadenabbia', value: 'Cadenabbia' },
  { label: 'Cadenazzo', value: 'Cadenazzo' },
  { label: 'Cadenberge', value: 'Cadenberge' },
  { label: 'Cadenet', value: 'Cadenet' },
  { label: 'Cadereyta', value: 'Cadereyta' },
  { label: 'Cadereyta', value: 'Cadereyta' },
  { label: 'Cadereyta Jiménez', value: 'Cadereyta Jiménez' },
  { label: 'Caderousse', value: 'Caderousse' },
  { label: 'Caderzone Terme', value: 'Caderzone Terme' },
  { label: 'Cádiar', value: 'Cádiar' },
  { label: 'Cadillac', value: 'Cadillac' },
  { label: 'Cadillac', value: 'Cadillac' },
  { label: 'Cadine', value: 'Cadine' },
  { label: 'Cadipietra', value: 'Cadipietra' },
  { label: 'Caditaan', value: 'Caditaan' },
  { label: 'Cadiz', value: 'Cadiz' },
  { label: 'Cadiz', value: 'Cadiz' },
  { label: 'Cadiz', value: 'Cadiz' },
  { label: 'Cadiz', value: 'Cadiz' },
  { label: 'Cadlan', value: 'Cadlan' },
  { label: 'Cadnam', value: 'Cadnam' },
  { label: 'Cadolive', value: 'Cadolive' },
  { label: 'Cadolzburg', value: 'Cadolzburg' },
  { label: 'Cadoneghe', value: 'Cadoneghe' },
  { label: 'Cadorago-Caslino Al Piano', value: 'Cadorago-Caslino Al Piano' },
  { label: 'Cadott', value: 'Cadott' },
  { label: 'Cadreita', value: 'Cadreita' },
  { label: 'Cadrete', value: 'Cadrete' },
  { label: 'Cadrezzate', value: 'Cadrezzate' },
  { label: 'Cadro', value: 'Cadro' },
  { label: 'Caém', value: 'Caém' },
  { label: 'Caen', value: 'Caen' },
  { label: 'Caerano Di San Marco', value: 'Caerano Di San Marco' },
  { label: 'Caergwrle', value: 'Caergwrle' },
  { label: 'Caerleon', value: 'Caerleon' },
  { label: 'Caernarfon', value: 'Caernarfon' },
  { label: 'Caerphilly', value: 'Caerphilly' },
  { label: 'Caerphilly County Borough', value: 'Caerphilly County Borough' },
  { label: 'Caerwent', value: 'Caerwent' },
  { label: 'Caesarea', value: 'Caesarea' },
  { label: 'Caëstre', value: 'Caëstre' },
  { label: 'Caetanópolis', value: 'Caetanópolis' },
  { label: 'Caetanos', value: 'Caetanos' },
  { label: 'Caeté', value: 'Caeté' },
  { label: 'Caetés', value: 'Caetés' },
  { label: 'Caetité', value: 'Caetité' },
  { label: 'Cafarnaum', value: 'Cafarnaum' },
  { label: 'Cafasse', value: 'Cafasse' },
  { label: 'Cafayate', value: 'Cafayate' },
  { label: 'Cafe', value: 'Cafe' },
  { label: 'Cafe', value: 'Cafe' },
  { label: 'Cafeara', value: 'Cafeara' },
  { label: 'Cafelândia', value: 'Cafelândia' },
  { label: 'Cafelândia', value: 'Cafelândia' },
  { label: 'Cafezal Do Sul', value: 'Cafezal Do Sul' },
  { label: 'Caffiere', value: 'Caffiere' },
  { label: 'Cagamotan', value: 'Cagamotan' },
  { label: 'Cagamotan', value: 'Cagamotan' },
  { label: 'Cagayan', value: 'Cagayan' },
  { label: 'Cagayan De Oro', value: 'Cagayan De Oro' },
  { label: 'Cagayan De Tawi-Tawi', value: 'Cagayan De Tawi-Tawi' },
  { label: 'Cagayancillo', value: 'Cagayancillo' },
  { label: 'Cagbang', value: 'Cagbang' },
  { label: 'Cagdianao', value: 'Cagdianao' },
  { label: 'Cagdianao', value: 'Cagdianao' },
  { label: 'Caggiano', value: 'Caggiano' },
  { label: 'Çağlayancerit', value: 'Çağlayancerit' },
  { label: 'Cagli', value: 'Cagli' },
  { label: 'Cagliari', value: 'Cagliari' },
  { label: 'Caglio', value: 'Caglio' },
  { label: 'Cagmanaba', value: 'Cagmanaba' },
  { label: 'Cagnac-Les-Mines', value: 'Cagnac-Les-Mines' },
  { label: 'Cagnano Amiterno', value: 'Cagnano Amiterno' },
  { label: 'Cagnano Varano', value: 'Cagnano Varano' },
  { label: 'Cagnes-Sur-Mer', value: 'Cagnes-Sur-Mer' },
  { label: 'Cagno', value: 'Cagno' },
  { label: 'Cagnò', value: 'Cagnò' },
  { label: 'Cagny', value: 'Cagny' },
  { label: 'Cagny', value: 'Cagny' },
  { label: 'Cagsiay', value: 'Cagsiay' },
  { label: 'Cagua', value: 'Cagua' },
  { label: 'Caguas', value: 'Caguas' },
  { label: 'Caguas', value: 'Caguas' },
  { label: 'Cagwait', value: 'Cagwait' },
  { label: 'Cagwait', value: 'Cagwait' },
  { label: 'Cahaba Heights', value: 'Cahaba Heights' },
  { label: 'Cahabón', value: 'Cahabón' },
  { label: 'Cahagnes', value: 'Cahagnes' },
  { label: 'Caherconlish', value: 'Caherconlish' },
  { label: 'Cahersiveen', value: 'Cahersiveen' },
  { label: 'Cahir', value: 'Cahir' },
  { label: 'Cahokia', value: 'Cahokia' },
  { label: 'Cahors', value: 'Cahors' },
  { label: 'Cahri', value: 'Cahri' },
  { label: 'Cahuanuyo', value: 'Cahuanuyo' },
  { label: 'Cahuatache', value: 'Cahuatache' },
  { label: 'Cahul', value: 'Cahul' },
  { label: 'Cahuzac-Sur-Vère', value: 'Cahuzac-Sur-Vère' },
  { label: 'Caiabu', value: 'Caiabu' },
  { label: 'Caiana', value: 'Caiana' },
  { label: 'Caianello', value: 'Caianello' },
  { label: 'Căianu', value: 'Căianu' },
  { label: 'Căianu Mic', value: 'Căianu Mic' },
  { label: 'Căianu Mic', value: 'Căianu Mic' },
  { label: 'Caiapônia', value: 'Caiapônia' },
  { label: 'Caiazzo', value: 'Caiazzo' },
  { label: 'Caibarién', value: 'Caibarién' },
  { label: 'Caibaté', value: 'Caibaté' },
  { label: 'Caibi', value: 'Caibi' },
  { label: 'Caibiran', value: 'Caibiran' },
  { label: 'Caibiran', value: 'Caibiran' },
  { label: 'Caiçara', value: 'Caiçara' },
  { label: 'Caiçara', value: 'Caiçara' },
  { label: 'Caiçara Do Norte', value: 'Caiçara Do Norte' },
  { label: 'Caiçara Do Rio Do Vento', value: 'Caiçara Do Rio Do Vento' },
  { label: 'Caicedo', value: 'Caicedo' },
  { label: 'Caicedonia', value: 'Caicedonia' },
  { label: 'Caicó', value: 'Caicó' },
  { label: 'Caico/Millet', value: 'Caico/Millet' },
  { label: 'Caidian', value: 'Caidian' },
  { label: 'Caieiras', value: 'Caieiras' },
  { label: 'Caigangan', value: 'Caigangan' },
  { label: 'Caijia', value: 'Caijia' },
  { label: 'Caijiapu', value: 'Caijiapu' },
  { label: 'Cailloux-Sur-Fontaines', value: 'Cailloux-Sur-Fontaines' },
  { label: 'Caimancito', value: 'Caimancito' },
  { label: 'Caimanero', value: 'Caimanero' },
  { label: 'Caimbambo', value: 'Caimbambo' },
  { label: 'Caimitillo', value: 'Caimitillo' },
  { label: 'Caimito', value: 'Caimito' },
  { label: 'Caimito', value: 'Caimito' },
  { label: 'Caimito', value: 'Caimito' },
  { label: 'Caimito', value: 'Caimito' },
  { label: 'Câinenii Mici', value: 'Câinenii Mici' },
  { label: 'Caines', value: 'Caines' },
  { label: 'Caines - Kuens', value: 'Caines - Kuens' },
  { label: 'Caino', value: 'Caino' },
  { label: 'Cainta', value: 'Cainta' },
  { label: 'Caiolo', value: 'Caiolo' },
  { label: 'Čair', value: 'Čair' },
  { label: 'Caira', value: 'Caira' },
  { label: 'Cairano', value: 'Cairano' },
  { label: 'Cairate', value: 'Cairate' },
  { label: 'Cairn Curran', value: 'Cairn Curran' },
  { label: 'Cairneyhill', value: 'Cairneyhill' },
  { label: 'Cairnlea', value: 'Cairnlea' },
  { label: 'Cairnryan', value: 'Cairnryan' },
  { label: 'Cairns', value: 'Cairns' },
  { label: 'Cairns City', value: 'Cairns City' },
  { label: 'Cairns North', value: 'Cairns North' },
  { label: 'Cairo', value: 'Cairo' },
  { label: 'Cairo', value: 'Cairo' },
  { label: 'Cairo', value: 'Cairo' },
  { label: 'Cairo', value: 'Cairo' },
  { label: 'Cairo Montenotte', value: 'Cairo Montenotte' },
  { label: 'Cairon', value: 'Cairon' },
  { label: 'Cairu', value: 'Cairu' },
  { label: 'Cais Do Pico', value: 'Cais Do Pico' },
  { label: 'Caissargues', value: 'Caissargues' },
  { label: 'Caister-On-Sea', value: 'Caister-On-Sea' },
  { label: 'Caistor', value: 'Caistor' },
  { label: 'Caiuá', value: 'Caiuá' },
  { label: 'Căiuți', value: 'Căiuți' },
  { label: 'Căiuţi-Sat', value: 'Căiuţi-Sat' },
  { label: 'Caivano', value: 'Caivano' },
  { label: 'Caja De Agua', value: 'Caja De Agua' },
  { label: 'Caja Pinta', value: 'Caja Pinta' },
  { label: 'Cajabamba', value: 'Cajabamba' },
  { label: 'Cajahs Mountain', value: 'Cajahs Mountain' },
  { label: 'Cajamar', value: 'Cajamar' },
  { label: 'Cajamarca', value: 'Cajamarca' },
  { label: 'Cajamarca', value: 'Cajamarca' },
  { label: 'Cajapió', value: 'Cajapió' },
  { label: 'Cájar', value: 'Cájar' },
  { label: 'Cajarc', value: 'Cajarc' },
  { label: 'Cajari', value: 'Cajari' },
  { label: 'Cajaruro', value: 'Cajaruro' },
  { label: 'Cajati', value: 'Cajati' },
  { label: 'Cajazeiras', value: 'Cajazeiras' },
  { label: 'Cajazeiras Do Piauí', value: 'Cajazeiras Do Piauí' },
  { label: 'Cajazeirinhas', value: 'Cajazeirinhas' },
  { label: 'Cajelitos', value: 'Cajelitos' },
  { label: 'Čajetina', value: 'Čajetina' },
  { label: 'Cajibío', value: 'Cajibío' },
  { label: 'Cajicá', value: 'Cajicá' },
  { label: 'Cajidiocan', value: 'Cajidiocan' },
  { label: 'Cajimos', value: 'Cajimos' },
  { label: 'Cajititlán', value: 'Cajititlán' },
  { label: 'Čajniče', value: 'Čajniče' },
  { label: 'Cajobi', value: 'Cajobi' },
  { label: 'Cajolá', value: 'Cajolá' },
  { label: 'Cajonos', value: 'Cajonos' },
  { label: 'Cajueiro', value: 'Cajueiro' },
  { label: 'Cajueiro Da Praia', value: 'Cajueiro Da Praia' },
  { label: 'Cajuri', value: 'Cajuri' },
  { label: 'Cajuru', value: 'Cajuru' },
  { label: 'Cajvana', value: 'Cajvana' },
  { label: 'Cakaudrove Province', value: 'Cakaudrove Province' },
  { label: 'Čakovec', value: 'Čakovec' },
  { label: 'Çal', value: 'Çal' },
  { label: 'Cala', value: 'Cala' },
  { label: 'Cala Dor', value: 'Cala Dor' },
  { label: 'Cala Gonone', value: 'Cala Gonone' },
  { label: 'Cala Norte', value: 'Cala Norte' },
  { label: 'Cala Rajada', value: 'Cala Rajada' },
  { label: 'Cala Sur', value: 'Cala Sur' },
  { label: 'Calaba', value: 'Calaba' },
  { label: 'Calaba', value: 'Calaba' },
  { label: 'Calaba', value: 'Calaba' },
  { label: 'Calaba', value: 'Calaba' },
  { label: 'Calabaca', value: 'Calabaca' },
  { label: 'Calabacito', value: 'Calabacito' },
  { label: 'Calabanga', value: 'Calabanga' },
  { label: 'Calabar', value: 'Calabar' },
  { label: 'Calabasa', value: 'Calabasa' },
  { label: 'Calabasas', value: 'Calabasas' },
  { label: 'Calabash', value: 'Calabash' },
  { label: 'Calabazar De Sagua', value: 'Calabazar De Sagua' },
  { label: 'Calabazas De Fuentidueña', value: 'Calabazas De Fuentidueña' },
  { label: 'Calabozo', value: 'Calabozo' },
  { label: 'Calabritto', value: 'Calabritto' },
  { label: 'Calabugao', value: 'Calabugao' },
  { label: 'Calaca', value: 'Calaca' },
  { label: 'Călacea', value: 'Călacea' },
  { label: 'Calaceite', value: 'Calaceite' },
  { label: 'Calachuchi', value: 'Calachuchi' },
  { label: 'Calaf', value: 'Calaf' },
  { label: 'Calafat', value: 'Calafat' },
  { label: 'Calafell', value: 'Calafell' },
  { label: 'Calafindeşti', value: 'Calafindeşti' },
  { label: 'Calahorra', value: 'Calahorra' },
  { label: 'Calahorra De Boedo', value: 'Calahorra De Boedo' },
  { label: 'Calahorra La', value: 'Calahorra La' },
  { label: 'Calais', value: 'Calais' },
  { label: 'Calais', value: 'Calais' },
  { label: 'Calakmul', value: 'Calakmul' },
  { label: 'Calala', value: 'Calala' },
  { label: 'Calalzo Di Cadore', value: 'Calalzo Di Cadore' },
  { label: 'Calama', value: 'Calama' },
  { label: 'Calamagui East', value: 'Calamagui East' },
  { label: 'Calamagui East', value: 'Calamagui East' },
  { label: 'Calamanda', value: 'Calamanda' },
  { label: 'Calamandrana', value: 'Calamandrana' },
  { label: 'Calamar', value: 'Calamar' },
  { label: 'Calamar', value: 'Calamar' },
  { label: 'Calamba', value: 'Calamba' },
  { label: 'Calamba', value: 'Calamba' },
  { label: 'Calamba', value: 'Calamba' },
  { label: 'Calamba', value: 'Calamba' },
  { label: 'Calamba', value: 'Calamba' },
  { label: 'Calamocha', value: 'Calamocha' },
  { label: 'Calamonaci', value: 'Calamonaci' },
  { label: 'Calamonte', value: 'Calamonte' },
  { label: 'Calampisauan', value: 'Calampisauan' },
  { label: 'Calamundingan', value: 'Calamundingan' },
  { label: 'Calamvale', value: 'Calamvale' },
  { label: 'Călan', value: 'Călan' },
  { label: 'Calana', value: 'Calana' },
  { label: 'Calañas', value: 'Calañas' },
  { label: 'Calanasan', value: 'Calanasan' },
  { label: 'Calanasan', value: 'Calanasan' },
  { label: 'Calancuasan Norte', value: 'Calancuasan Norte' },
  { label: 'Calancuasan Norte', value: 'Calancuasan Norte' },
  { label: 'Calanda', value: 'Calanda' },
  { label: 'Calangain', value: 'Calangain' },
  { label: 'Calangain', value: 'Calangain' },
  { label: 'Calangianus', value: 'Calangianus' },
  { label: 'Calangute', value: 'Calangute' },
  { label: 'Calanna', value: 'Calanna' },
  { label: 'Calanogas', value: 'Calanogas' },
  { label: 'Calanogas', value: 'Calanogas' },
  { label: 'Calantac', value: 'Calantac' },
  { label: 'Calantac', value: 'Calantac' },
  { label: 'Calantas', value: 'Calantas' },
  { label: 'Calantas', value: 'Calantas' },
  { label: 'Calantas', value: 'Calantas' },
  { label: 'Calaoagan', value: 'Calaoagan' },
  { label: 'Calaoagan', value: 'Calaoagan' },
  { label: 'Calapan', value: 'Calapan' },
  { label: 'Calape', value: 'Calape' },
  { label: 'Calape', value: 'Calape' },
  { label: 'Calape', value: 'Calape' },
  { label: 'Calape', value: 'Calape' },
  { label: 'Călăraşi', value: 'Călăraşi' },
  { label: 'Călăraşi', value: 'Călăraşi' },
  { label: 'Călăraşi', value: 'Călăraşi' },
  { label: 'Călăraşi', value: 'Călăraşi' },
  { label: 'Călăraşi', value: 'Călăraşi' },
  { label: 'Calarca', value: 'Calarca' },
  { label: 'Calasca-Castiglione', value: 'Calasca-Castiglione' },
  { label: 'Calascibetta', value: 'Calascibetta' },
  { label: 'Calascio', value: 'Calascio' },
  { label: 'Calasetta', value: 'Calasetta' },
  { label: 'Calasgasan', value: 'Calasgasan' },
  { label: 'Calasiao', value: 'Calasiao' },
  { label: 'Calasiao', value: 'Calasiao' },
  { label: 'Calasparra', value: 'Calasparra' },
  { label: 'Calatabiano', value: 'Calatabiano' },
  { label: 'Calatafimi', value: 'Calatafimi' },
  { label: 'Calatagan', value: 'Calatagan' },
  { label: 'Calatagan', value: 'Calatagan' },
  { label: 'Calatañazor', value: 'Calatañazor' },
  { label: 'Calatayud', value: 'Calatayud' },
  { label: 'Călăţele', value: 'Călăţele' },
  { label: 'Calatorao', value: 'Calatorao' },
  { label: 'Calatrava', value: 'Calatrava' },
  { label: 'Calatrava', value: 'Calatrava' },
  { label: 'Calatugas', value: 'Calatugas' },
  { label: 'Calau', value: 'Calau' },
  { label: 'Calauag', value: 'Calauag' },
  { label: 'Calauag', value: 'Calauag' },
  { label: 'Calauan', value: 'Calauan' },
  { label: 'Calaveras County', value: 'Calaveras County' },
  { label: 'Calavino', value: 'Calavino' },
  { label: 'Calaya', value: 'Calaya' },
  { label: 'Calayaan', value: 'Calayaan' },
  { label: 'Calayaan', value: 'Calayaan' },
  { label: 'Calayan', value: 'Calayan' },
  { label: 'Calbayog', value: 'Calbayog' },
  { label: 'Calbayog City', value: 'Calbayog City' },
  { label: 'Calbe', value: 'Calbe' },
  { label: 'Calberlah', value: 'Calberlah' },
  { label: 'Calbiga', value: 'Calbiga' },
  { label: 'Calbiga', value: 'Calbiga' },
  { label: 'Calbuco', value: 'Calbuco' },
  { label: 'Calca', value: 'Calca' },
  { label: 'Calçado', value: 'Calçado' },
  { label: 'Calcahualco', value: 'Calcahualco' },
  { label: 'Calcara', value: 'Calcara' },
  { label: 'Calcasieu Parish', value: 'Calcasieu Parish' },
  { label: 'Calcata Nuova', value: 'Calcata Nuova' },
  { label: 'Calcehtoc', value: 'Calcehtoc' },
  { label: 'Calcena', value: 'Calcena' },
  { label: 'Calceranica Al Lago', value: 'Calceranica Al Lago' },
  { label: 'Calceta', value: 'Calceta' },
  { label: 'Calchaquí', value: 'Calchaquí' },
  { label: 'Calchera-Frontale', value: 'Calchera-Frontale' },
  { label: 'Calci', value: 'Calci' },
  { label: 'Calciano', value: 'Calciano' },
  { label: 'Calcinaia', value: 'Calcinaia' },
  { label: 'Calcinate', value: 'Calcinate' },
  { label: 'Calcinato', value: 'Calcinato' },
  { label: 'Calcinelli', value: 'Calcinelli' },
  { label: 'Calcio', value: 'Calcio' },
  { label: 'Calcium', value: 'Calcium' },
  { label: 'Calco', value: 'Calco' },
  { label: 'Calçoene', value: 'Calçoene' },
  { label: 'Calcot', value: 'Calcot' },
  { label: 'Calcutta', value: 'Calcutta' },
  { label: 'Căldăraru', value: 'Căldăraru' },
  { label: 'Căldăraru', value: 'Căldăraru' },
  { label: 'Căldărăști', value: 'Căldărăști' },
  { label: 'Caldaro Sulla Strada Del Vino', value: 'Caldaro Sulla Strada Del Vino' },
  { label: 'Caldarola', value: 'Caldarola' },
  { label: 'Caldas', value: 'Caldas' },
  { label: 'Caldas', value: 'Caldas' },
  { label: 'Caldas', value: 'Caldas' },
  { label: 'Caldas Brandão', value: 'Caldas Brandão' },
  { label: 'Caldas Da Rainha', value: 'Caldas Da Rainha' },
  { label: 'Caldas Das Taipas', value: 'Caldas Das Taipas' },
  { label: 'Caldas De Reis', value: 'Caldas De Reis' },
  { label: 'Caldas De Vizela', value: 'Caldas De Vizela' },
  { label: 'Caldas Novas', value: 'Caldas Novas' },
  { label: 'Caldazinha', value: 'Caldazinha' },
  { label: 'Caldearenas', value: 'Caldearenas' },
  { label: 'Caldeirão Grande', value: 'Caldeirão Grande' },
  { label: 'Caldeirão Grande Do Piauí', value: 'Caldeirão Grande Do Piauí' },
  { label: 'Caldelas', value: 'Caldelas' },
  { label: 'Calden', value: 'Calden' },
  { label: 'Caldera', value: 'Caldera' },
  { label: 'Caldera', value: 'Caldera' },
  { label: 'Calderara Di Reno', value: 'Calderara Di Reno' },
  { label: 'Calderbank', value: 'Calderbank' },
  { label: 'Caldercruix', value: 'Caldercruix' },
  { label: 'Calderdale', value: 'Calderdale' },
  { label: 'Calderino', value: 'Calderino' },
  { label: 'Calderitas', value: 'Calderitas' },
  { label: 'Calders', value: 'Calders' },
  { label: 'Calderwood', value: 'Calderwood' },
  { label: 'Caldes', value: 'Caldes' },
  { label: 'Caldes De Malavella', value: 'Caldes De Malavella' },
  { label: 'Caldes De Montbui', value: 'Caldes De Montbui' },
  { label: 'Caldes Destrac', value: 'Caldes Destrac' },
  { label: 'Caldicot', value: 'Caldicot' },
  { label: 'Caldierino-Rota', value: 'Caldierino-Rota' },
  { label: 'Caldiero', value: 'Caldiero' },
  { label: 'Caldine', value: 'Caldine' },
  { label: 'Çaldıran', value: 'Çaldıran' },
  { label: 'Caldogno-Rettorgole-Cresole', value: 'Caldogno-Rettorgole-Cresole' },
  { label: 'Caldonazzo', value: 'Caldonazzo' },
  { label: 'Caldono', value: 'Caldono' },
  { label: 'Caldwell', value: 'Caldwell' },
  { label: 'Caldwell', value: 'Caldwell' },
  { label: 'Caldwell', value: 'Caldwell' },
  { label: 'Caldwell', value: 'Caldwell' },
  { label: 'Caldwell', value: 'Caldwell' },
  { label: 'Caldwell County', value: 'Caldwell County' },
  { label: 'Caldwell County', value: 'Caldwell County' },
  { label: 'Caldwell County', value: 'Caldwell County' },
  { label: 'Caldwell County', value: 'Caldwell County' },
  { label: 'Caldwell Parish', value: 'Caldwell Parish' },
  { label: 'Calebasses', value: 'Calebasses' },
  { label: 'Caledon', value: 'Caledon' },
  { label: 'Caledonia', value: 'Caledonia' },
  { label: 'Caledonia', value: 'Caledonia' },
  { label: 'Caledonia', value: 'Caledonia' },
  { label: 'Caledonia', value: 'Caledonia' },
  { label: 'Caledonia', value: 'Caledonia' },
  { label: 'Caledonia', value: 'Caledonia' },
  { label: 'Caledonia County', value: 'Caledonia County' },
  { label: 'Calella', value: 'Calella' },
  { label: 'Calendário', value: 'Calendário' },
  { label: 'Calendasco', value: 'Calendasco' },
  { label: 'Calenzana', value: 'Calenzana' },
  { label: 'Calenzano', value: 'Calenzano' },
  { label: 'Calepaan', value: 'Calepaan' },
  { label: 'Calepaan', value: 'Calepaan' },
  { label: 'Caleppio', value: 'Caleppio' },
  { label: 'Calera', value: 'Calera' },
  { label: 'Calera', value: 'Calera' },
  { label: 'Calera Chica', value: 'Calera Chica' },
  { label: 'Calera De León', value: 'Calera De León' },
  { label: 'Calera De Tango', value: 'Calera De Tango' },
  { label: 'Calera Y Chozas', value: 'Calera Y Chozas' },
  { label: 'Caleras', value: 'Caleras' },
  { label: 'Caleras De Ameche', value: 'Caleras De Ameche' },
  { label: 'Calería', value: 'Calería' },
  { label: 'Calerita', value: 'Calerita' },
  { label: 'Calerno', value: 'Calerno' },
  { label: 'Calero', value: 'Calero' },
  { label: 'Caleruega', value: 'Caleruega' },
  { label: 'Caleruela', value: 'Caleruela' },
  { label: 'Calestano', value: 'Calestano' },
  { label: 'Caleta De Carquín', value: 'Caleta De Carquín' },
  { label: 'Caleta Olivia', value: 'Caleta Olivia' },
  { label: 'Caleufú', value: 'Caleufú' },
  { label: 'Calexico', value: 'Calexico' },
  { label: 'Calgary', value: 'Calgary' },
  { label: 'Calhandriz', value: 'Calhandriz' },
  { label: 'Calheta', value: 'Calheta' },
  { label: 'Calheta', value: 'Calheta' },
  { label: 'Calheta', value: 'Calheta' },
  { label: 'Calheta De São Jorge', value: 'Calheta De São Jorge' },
  { label: 'Calhoun', value: 'Calhoun' },
  { label: 'Calhoun', value: 'Calhoun' },
  { label: 'Calhoun City', value: 'Calhoun City' },
  { label: 'Calhoun County', value: 'Calhoun County' },
  { label: 'Calhoun County', value: 'Calhoun County' },
  { label: 'Calhoun County', value: 'Calhoun County' },
  { label: 'Calhoun County', value: 'Calhoun County' },
  { label: 'Calhoun County', value: 'Calhoun County' },
  { label: 'Calhoun County', value: 'Calhoun County' },
  { label: 'Calhoun County', value: 'Calhoun County' },
  { label: 'Calhoun County', value: 'Calhoun County' },
  { label: 'Calhoun County', value: 'Calhoun County' },
  { label: 'Calhoun County', value: 'Calhoun County' },
  { label: 'Calhoun County', value: 'Calhoun County' },
  { label: 'Calhoun Falls', value: 'Calhoun Falls' },
  { label: 'Cali', value: 'Cali' },
  { label: 'Calibishie', value: 'Calibishie' },
  { label: 'Calibungan', value: 'Calibungan' },
  { label: 'Calibungan', value: 'Calibungan' },
  { label: 'Calibutbut', value: 'Calibutbut' },
  { label: 'Calibutbut', value: 'Calibutbut' },
  { label: 'Calicasas', value: 'Calicasas' },
  { label: 'Calice Al Cornoviglio', value: 'Calice Al Cornoviglio' },
  { label: 'Calice Ligure', value: 'Calice Ligure' },
  { label: 'Calichar Palma Sola', value: 'Calichar Palma Sola' },
  { label: 'Calico Rock', value: 'Calico Rock' },
  { label: 'Calidñgan', value: 'Calidñgan' },
  { label: 'Cálido', value: 'Cálido' },
  { label: 'Calidonia', value: 'Calidonia' },
  { label: 'Caliente', value: 'Caliente' },
  { label: 'Califon', value: 'Califon' },
  { label: 'California', value: 'California' },
  { label: 'California', value: 'California' },
  { label: 'California', value: 'California' },
  { label: 'California', value: 'California' },
  { label: 'California', value: 'California' },
  { label: 'Califórnia', value: 'Califórnia' },
  { label: 'California City', value: 'California City' },
  { label: 'California Gully', value: 'California Gully' },
  { label: 'Càlig', value: 'Càlig' },
  { label: 'Calihualá', value: 'Calihualá' },
  { label: 'Calilayan', value: 'Calilayan' },
  { label: 'Calilegua', value: 'Calilegua' },
  { label: 'Calima', value: 'Calima' },
  { label: 'Călimăneşti', value: 'Călimăneşti' },
  { label: 'Călimănești', value: 'Călimănești' },
  { label: 'Calimaya', value: 'Calimaya' },
  { label: 'Calimera', value: 'Calimera' },
  { label: 'Calimesa', value: 'Calimesa' },
  { label: 'Calimete', value: 'Calimete' },
  { label: 'Călina', value: 'Călina' },
  { label: 'Calinaoan Malasin', value: 'Calinaoan Malasin' },
  { label: 'Calinaoan Malasin', value: 'Calinaoan Malasin' },
  { label: 'Călineşti', value: 'Călineşti' },
  { label: 'Călineşti', value: 'Călineşti' },
  { label: 'Călineşti', value: 'Călineşti' },
  { label: 'Călinești', value: 'Călinești' },
  { label: 'Călinești', value: 'Călinești' },
  { label: 'Călineşti-Oaş', value: 'Călineşti-Oaş' },
  { label: 'Calingasta', value: 'Calingasta' },
  { label: 'Calingcuan', value: 'Calingcuan' },
  { label: 'Calingcuan', value: 'Calingcuan' },
  { label: 'Calinog', value: 'Calinog' },
  { label: 'Calintaan', value: 'Calintaan' },
  { label: 'Calipan', value: 'Calipan' },
  { label: 'Calipatria', value: 'Calipatria' },
  { label: 'Calista', value: 'Calista' },
  { label: 'Calistoga', value: 'Calistoga' },
  { label: 'Calitri', value: 'Calitri' },
  { label: 'Calituban', value: 'Calituban' },
  { label: 'Calitzdorp', value: 'Calitzdorp' },
  { label: 'Calixtlahuaca', value: 'Calixtlahuaca' },
  { label: 'Calizo', value: 'Calizo' },
  { label: 'Calizzano', value: 'Calizzano' },
  { label: 'Calkiní', value: 'Calkiní' },
  { label: 'Callabiana - Chiesa', value: 'Callabiana - Chiesa' },
  { label: 'Callac', value: 'Callac' },
  { label: 'Callaghan', value: 'Callaghan' },
  { label: 'Callaguip', value: 'Callaguip' },
  { label: 'Callaguip', value: 'Callaguip' },
  { label: 'Callahan', value: 'Callahan' },
  { label: 'Callahan County', value: 'Callahan County' },
  { label: 'Callala Bay', value: 'Callala Bay' },
  { label: 'Callan', value: 'Callan' },
  { label: 'Callanca', value: 'Callanca' },
  { label: 'Callander', value: 'Callander' },
  { label: 'Callantsoog', value: 'Callantsoog' },
  { label: 'Callao', value: 'Callao' },
  { label: 'Callas', value: 'Callas' },
  { label: 'Callaway', value: 'Callaway' },
  { label: 'Callaway County', value: 'Callaway County' },
  { label: 'Calldetenes', value: 'Calldetenes' },
  { label: 'Calle Blancos', value: 'Calle Blancos' },
  { label: 'Calle Larga', value: 'Calle Larga' },
  { label: 'Calle Real', value: 'Calle Real' },
  { label: 'Callejones', value: 'Callejones' },
  { label: 'Callejones', value: 'Callejones' },
  { label: 'Callenberg', value: 'Callenberg' },
  { label: 'Callender', value: 'Callender' },
  { label: 'Calles', value: 'Calles' },
  { label: 'Callian', value: 'Callian' },
  { label: 'Calliano', value: 'Calliano' },
  { label: 'Calliano', value: 'Calliano' },
  { label: 'Callington', value: 'Callington' },
  { label: 'Calliope', value: 'Calliope' },
  { label: 'Callosa De Segura', value: 'Callosa De Segura' },
  { label: 'Callosa Den Sarrià', value: 'Callosa Den Sarrià' },
  { label: 'Calloway County', value: 'Calloway County' },
  { label: 'Callús', value: 'Callús' },
  { label: 'Calmar', value: 'Calmar' },
  { label: 'Calmarza', value: 'Calmarza' },
  { label: 'Calmasino', value: 'Calmasino' },
  { label: 'Călmăţuiu', value: 'Călmăţuiu' },
  { label: 'Călmățuiu De Sus', value: 'Călmățuiu De Sus' },
  { label: 'Calmeca', value: 'Calmeca' },
  { label: 'Calmon', value: 'Calmon' },
  { label: 'Calmont', value: 'Calmont' },
  { label: 'Caln', value: 'Caln' },
  { label: 'Calnali', value: 'Calnali' },
  { label: 'Calne', value: 'Calne' },
  { label: 'Câlnic', value: 'Câlnic' },
  { label: 'Câlnic', value: 'Câlnic' },
  { label: 'Câlnic', value: 'Câlnic' },
  { label: 'Calò', value: 'Calò' },
  { label: 'Calobre', value: 'Calobre' },
  { label: 'Caloc-An', value: 'Caloc-An' },
  { label: 'Caloc-An', value: 'Caloc-An' },
  { label: 'Calog Norte', value: 'Calog Norte' },
  { label: 'Calog Norte', value: 'Calog Norte' },
  { label: 'Calolbon', value: 'Calolbon' },
  { label: 'Calolziocorte', value: 'Calolziocorte' },
  { label: 'Calomarde', value: 'Calomarde' },
  { label: 'Calomboyan', value: 'Calomboyan' },
  { label: 'Calomboyan', value: 'Calomboyan' },
  { label: 'Calomfirești', value: 'Calomfirești' },
  { label: 'Calongbuyan', value: 'Calongbuyan' },
  { label: 'Calongbuyan', value: 'Calongbuyan' },
  { label: 'Calonge', value: 'Calonge' },
  { label: 'Calonge', value: 'Calonge' },
  { label: 'Calonge De Segarra', value: 'Calonge De Segarra' },
  { label: 'Calonne-Ricouart', value: 'Calonne-Ricouart' },
  { label: 'Calonne-Sur-La-Lys', value: 'Calonne-Sur-La-Lys' },
  { label: 'Caloocan', value: 'Caloocan' },
  { label: 'Calopăr', value: 'Calopăr' },
  { label: 'Caloppezzati', value: 'Caloppezzati' },
  { label: 'Calosso', value: 'Calosso' },
  { label: 'Calotmul', value: 'Calotmul' },
  { label: 'Caloto', value: 'Caloto' },
  { label: 'Caloundra', value: 'Caloundra' },
  { label: 'Caloundra West', value: 'Caloundra West' },
  { label: 'Calovébora', value: 'Calovébora' },
  { label: 'Caloveto', value: 'Caloveto' },
  { label: 'Calozzo', value: 'Calozzo' },
  { label: 'Calp', value: 'Calp' },
  { label: 'Calpanería Atezquilla', value: 'Calpanería Atezquilla' },
  { label: 'Calpulalpan', value: 'Calpulalpan' },
  { label: 'Calsib', value: 'Calsib' },
  { label: 'Calsib', value: 'Calsib' },
  { label: 'Calslagen', value: 'Calslagen' },
  { label: 'Caltabellotta', value: 'Caltabellotta' },
  { label: 'Caltagirone', value: 'Caltagirone' },
  { label: 'Caltana', value: 'Caltana' },
  { label: 'Caltanissetta', value: 'Caltanissetta' },
  { label: 'Caltavuturo', value: 'Caltavuturo' },
  { label: 'Caltignaga', value: 'Caltignaga' },
  { label: 'Caltimacan', value: 'Caltimacan' },
  { label: 'Calto', value: 'Calto' },
  { label: 'Caltojar', value: 'Caltojar' },
  { label: 'Caltrano', value: 'Caltrano' },
  { label: 'Caltzontzín', value: 'Caltzontzín' },
  { label: 'Calubcub Dos', value: 'Calubcub Dos' },
  { label: 'Calubian', value: 'Calubian' },
  { label: 'Calubian', value: 'Calubian' },
  { label: 'Călugăreni', value: 'Călugăreni' },
  { label: 'Călugăreni', value: 'Călugăreni' },
  { label: 'Călugăreni', value: 'Călugăreni' },
  { label: 'Călui', value: 'Călui' },
  { label: 'Caluire-Et-Cuire', value: 'Caluire-Et-Cuire' },
  { label: 'Calumbi', value: 'Calumbi' },
  { label: 'Calumboyan', value: 'Calumboyan' },
  { label: 'Calumet', value: 'Calumet' },
  { label: 'Calumet City', value: 'Calumet City' },
  { label: 'Calumet County', value: 'Calumet County' },
  { label: 'Calumet Park', value: 'Calumet Park' },
  { label: 'Calumpang', value: 'Calumpang' },
  { label: 'Calumpang', value: 'Calumpang' },
  { label: 'Calumpang', value: 'Calumpang' },
  { label: 'Calumpang', value: 'Calumpang' },
  { label: 'Calumpit', value: 'Calumpit' },
  { label: 'Calumpit', value: 'Calumpit' },
  { label: 'Caluquembe', value: 'Caluquembe' },
  { label: 'Calusco Dadda', value: 'Calusco Dadda' },
  { label: 'Călușeri', value: 'Călușeri' },
  { label: 'Caluso', value: 'Caluso' },
  { label: 'Caluula', value: 'Caluula' },
  { label: 'Caluya', value: 'Caluya' },
  { label: 'Calvados', value: 'Calvados' },
  { label: 'Calvagese Della Riviera', value: 'Calvagese Della Riviera' },
  { label: 'Calvanico', value: 'Calvanico' },
  { label: 'Calvario Buenavista', value: 'Calvario Buenavista' },
  { label: 'Calvario Del Carmen', value: 'Calvario Del Carmen' },
  { label: 'Calvarrasa De Abajo', value: 'Calvarrasa De Abajo' },
  { label: 'Calvarrasa De Arriba', value: 'Calvarrasa De Arriba' },
  { label: 'Calvary', value: 'Calvary' },
  { label: 'Calvary/Calvaire', value: 'Calvary/Calvaire' },
  { label: 'Calvatone', value: 'Calvatone' },
  { label: 'Calvello', value: 'Calvello' },
  { label: 'Calvene', value: 'Calvene' },
  { label: 'Calvenzano', value: 'Calvenzano' },
  { label: 'Calvera', value: 'Calvera' },
  { label: 'Calvert', value: 'Calvert' },
  { label: 'Calvert City', value: 'Calvert City' },
  { label: 'Calvert County', value: 'Calvert County' },
  { label: 'Calverton', value: 'Calverton' },
  { label: 'Calverton', value: 'Calverton' },
  { label: 'Calverton', value: 'Calverton' },
  { label: 'Calverton Park', value: 'Calverton Park' },
  { label: 'Calvi', value: 'Calvi' },
  { label: 'Calvi', value: 'Calvi' },
  { label: 'Calvi Dellumbria', value: 'Calvi Dellumbria' },
  { label: 'Calvi Risorta', value: 'Calvi Risorta' },
  { label: 'Calvià', value: 'Calvià' },
  { label: 'Calvignano', value: 'Calvignano' },
  { label: 'Calvignasco', value: 'Calvignasco' },
  { label: 'Calvillo', value: 'Calvillo' },
  { label: 'Calvini', value: 'Calvini' },
  { label: 'Calvinia', value: 'Calvinia' },
  { label: 'Calvisano', value: 'Calvisano' },
  { label: 'Calvisi', value: 'Calvisi' },
  { label: 'Calvisson', value: 'Calvisson' },
  { label: 'Calvizzano', value: 'Calvizzano' },
  { label: 'Calvörde', value: 'Calvörde' },
  { label: 'Calvos De Randín', value: 'Calvos De Randín' },
  { label: 'Calw', value: 'Calw' },
  { label: 'Calwell', value: 'Calwell' },
  { label: 'Çalxanqala', value: 'Çalxanqala' },
  { label: 'Calzada De Béjar La', value: 'Calzada De Béjar La' },
  { label: 'Calzada De Calatrava', value: 'Calzada De Calatrava' },
  { label: 'Calzada De Don Diego', value: 'Calzada De Don Diego' },
  { label: 'Calzada De Los Molinos', value: 'Calzada De Los Molinos' },
  { label: 'Calzada De Oropesa', value: 'Calzada De Oropesa' },
  { label: 'Calzada De Valdunciel', value: 'Calzada De Valdunciel' },
  { label: 'Calzada Del Coto', value: 'Calzada Del Coto' },
  { label: 'Calzada Del Tepozán', value: 'Calzada Del Tepozán' },
  { label: 'Calzada Larga', value: 'Calzada Larga' },
  { label: 'Calzada Larga', value: 'Calzada Larga' },
  { label: 'Calzadas', value: 'Calzadas' },
  { label: 'Calzadilla', value: 'Calzadilla' },
  { label: 'Calzadilla De Los Barros', value: 'Calzadilla De Los Barros' },
  { label: 'Calzadilla De Tera', value: 'Calzadilla De Tera' },
  { label: 'Cẩm Phả', value: 'Cẩm Phả' },
  { label: 'Cẩm Phả Mines', value: 'Cẩm Phả Mines' },
  { label: 'Cam Ranh', value: 'Cam Ranh' },
  { label: 'Cama Juan', value: 'Cama Juan' },
  { label: 'Cama Juan', value: 'Cama Juan' },
  { label: 'Camabatela', value: 'Camabatela' },
  { label: 'Camacan', value: 'Camacan' },
  { label: 'Camaçari', value: 'Camaçari' },
  { label: 'Camacha', value: 'Camacha' },
  { label: 'Camachile', value: 'Camachile' },
  { label: 'Camachile', value: 'Camachile' },
  { label: 'Camacho', value: 'Camacho' },
  { label: 'Camacupa', value: 'Camacupa' },
  { label: 'Camagna Monferrato', value: 'Camagna Monferrato' },
  { label: 'Camagüey', value: 'Camagüey' },
  { label: 'Camaiore', value: 'Camaiore' },
  { label: 'Camairago', value: 'Camairago' },
  { label: 'Camajapita', value: 'Camajapita' },
  { label: 'Camajuaní', value: 'Camajuaní' },
  { label: 'Camalaniugan', value: 'Camalaniugan' },
  { label: 'Camalaniugan', value: 'Camalaniugan' },
  { label: 'Camalaú', value: 'Camalaú' },
  { label: 'Camaleño', value: 'Camaleño' },
  { label: 'Camaley', value: 'Camaley' },
  { label: 'Camaley', value: 'Camaley' },
  { label: 'Camalig', value: 'Camalig' },
  { label: 'Camaligan', value: 'Camaligan' },
  { label: 'Camalò', value: 'Camalò' },
  { label: 'Camalobalo', value: 'Camalobalo' },
  { label: 'Camalote', value: 'Camalote' },
  { label: 'Camalotita', value: 'Camalotita' },
  { label: 'Camalú', value: 'Camalú' },
  { label: 'Camambugan', value: 'Camambugan' },
  { label: 'Camamu', value: 'Camamu' },
  { label: 'Camaná', value: 'Camaná' },
  { label: 'Camañas', value: 'Camañas' },
  { label: 'Camanche', value: 'Camanche' },
  { label: 'Camandag', value: 'Camandag' },
  { label: 'Camandona', value: 'Camandona' },
  { label: 'Camanducaia', value: 'Camanducaia' },
  { label: 'Camangcamang', value: 'Camangcamang' },
  { label: 'Camano', value: 'Camano' },
  { label: 'Camapuã', value: 'Camapuã' },
  { label: 'Camaquã', value: 'Camaquã' },
  { label: 'Camăr', value: 'Camăr' },
  { label: 'Câmara De Lobos', value: 'Câmara De Lobos' },
  { label: 'Camaragibe', value: 'Camaragibe' },
  { label: 'Camarasa', value: 'Camarasa' },
  { label: 'Cămăraşu', value: 'Cămăraşu' },
  { label: 'Camarate', value: 'Camarate' },
  { label: 'Camarate', value: 'Camarate' },
  { label: 'Çamardı', value: 'Çamardı' },
  { label: 'Camarena', value: 'Camarena' },
  { label: 'Camarena De La Sierra', value: 'Camarena De La Sierra' },
  { label: 'Camarenilla', value: 'Camarenilla' },
  { label: 'Camarès', value: 'Camarès' },
  { label: 'Camaret-Sur-Aigues', value: 'Camaret-Sur-Aigues' },
  { label: 'Camaret-Sur-Mer', value: 'Camaret-Sur-Mer' },
  { label: 'Camargo', value: 'Camargo' },
  { label: 'Camargo', value: 'Camargo' },
  { label: 'Camargo', value: 'Camargo' },
  { label: 'Camargo', value: 'Camargo' },
  { label: 'Camargo', value: 'Camargo' },
  { label: 'Camargo', value: 'Camargo' },
  { label: 'Camarillas', value: 'Camarillas' },
  { label: 'Camarillo', value: 'Camarillo' },
  { label: 'Camariñas', value: 'Camariñas' },
  { label: 'Camarles', value: 'Camarles' },
  { label: 'Camarma De Esteruelas', value: 'Camarma De Esteruelas' },
  { label: 'Camarón Arriba', value: 'Camarón Arriba' },
  { label: 'Camarón De Tejeda', value: 'Camarón De Tejeda' },
  { label: 'Camarones', value: 'Camarones' },
  { label: 'Camarones', value: 'Camarones' },
  { label: 'Cămărzana', value: 'Cămărzana' },
  { label: 'Camarzana De Tera', value: 'Camarzana De Tera' },
  { label: 'Camas', value: 'Camas' },
  { label: 'Camas', value: 'Camas' },
  { label: 'Çamaş', value: 'Çamaş' },
  { label: 'Camas County', value: 'Camas County' },
  { label: 'Camasca', value: 'Camasca' },
  { label: 'Camastra', value: 'Camastra' },
  { label: 'Camayenne', value: 'Camayenne' },
  { label: 'Cambados', value: 'Cambados' },
  { label: 'Cambanay', value: 'Cambanay' },
  { label: 'Cambanugoy', value: 'Cambanugoy' },
  { label: 'Cambanugoy', value: 'Cambanugoy' },
  { label: 'Cambanugoy', value: 'Cambanugoy' },
  { label: 'Cambará', value: 'Cambará' },
  { label: 'Cambará Do Sul', value: 'Cambará Do Sul' },
  { label: 'Cambé', value: 'Cambé' },
  { label: 'Camber', value: 'Camber' },
  { label: 'Camberley', value: 'Camberley' },
  { label: 'Camberwell', value: 'Camberwell' },
  { label: 'Camberwell', value: 'Camberwell' },
  { label: 'Cambes', value: 'Cambes' },
  { label: 'Cambes-En-Plaine', value: 'Cambes-En-Plaine' },
  { label: 'Cambewarra Village', value: 'Cambewarra Village' },
  { label: 'Cambiago', value: 'Cambiago' },
  { label: 'Cambiano', value: 'Cambiano' },
  { label: 'Cambiasca', value: 'Cambiasca' },
  { label: 'Cambil', value: 'Cambil' },
  { label: 'Cambira', value: 'Cambira' },
  { label: 'Cambita Garabitos', value: 'Cambita Garabitos' },
  { label: 'Camblain-Châtelain', value: 'Camblain-Châtelain' },
  { label: 'Camblanes-Et-Meynac', value: 'Camblanes-Et-Meynac' },
  { label: 'Camblesforth', value: 'Camblesforth' },
  { label: 'Cambo-Les-Bains', value: 'Cambo-Les-Bains' },
  { label: 'Cambooya', value: 'Cambooya' },
  { label: 'Camboriú', value: 'Camboriú' },
  { label: 'Camborne', value: 'Camborne' },
  { label: 'Cambourne', value: 'Cambourne' },
  { label: 'Cambrai', value: 'Cambrai' },
  { label: 'Cambre', value: 'Cambre' },
  { label: 'Cambremer', value: 'Cambremer' },
  { label: 'Cambria', value: 'Cambria' },
  { label: 'Cambria', value: 'Cambria' },
  { label: 'Cambria County', value: 'Cambria County' },
  { label: 'Cambria Heights', value: 'Cambria Heights' },
  { label: 'Cambrian Park', value: 'Cambrian Park' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge', value: 'Cambridge' },
  { label: 'Cambridge City', value: 'Cambridge City' },
  { label: 'Cambridge Gardens', value: 'Cambridge Gardens' },
  { label: 'Cambridge Park', value: 'Cambridge Park' },
  { label: 'Cambridge Springs', value: 'Cambridge Springs' },
  { label: 'Cambridgeshire', value: 'Cambridgeshire' },
  { label: 'Cambrils', value: 'Cambrils' },
  { label: 'Cambronne-Lès-Clermont', value: 'Cambronne-Lès-Clermont' },
  { label: 'Cambronne-Lès-Ribécourt', value: 'Cambronne-Lès-Ribécourt' },
  { label: 'Cambuci', value: 'Cambuci' },
  { label: 'Cambuga', value: 'Cambuga' },
  { label: 'Cambuí', value: 'Cambuí' },
  { label: 'Cambuquira', value: 'Cambuquira' },
  { label: 'Camburg', value: 'Camburg' },
  { label: 'Camburzano', value: 'Camburzano' },
  { label: 'Cambuslang', value: 'Cambuslang' },
  { label: 'Cambutal', value: 'Cambutal' },
  { label: 'Camden', value: 'Camden' },
  { label: 'Camden', value: 'Camden' },
  { label: 'Camden', value: 'Camden' },
  { label: 'Camden', value: 'Camden' },
  { label: 'Camden', value: 'Camden' },
  { label: 'Camden', value: 'Camden' },
  { label: 'Camden', value: 'Camden' },
  { label: 'Camden', value: 'Camden' },
  { label: 'Camden', value: 'Camden' },
  { label: 'Camden', value: 'Camden' },
  { label: 'Camden', value: 'Camden' },
  { label: 'Camden County', value: 'Camden County' },
  { label: 'Camden County', value: 'Camden County' },
  { label: 'Camden County', value: 'Camden County' },
  { label: 'Camden County', value: 'Camden County' },
  { label: 'Camden Haven', value: 'Camden Haven' },
  { label: 'Camden Park', value: 'Camden Park' },
  { label: 'Camden South', value: 'Camden South' },
  { label: 'Camden Town', value: 'Camden Town' },
  { label: 'Camdenton', value: 'Camdenton' },
  { label: 'Cameley', value: 'Cameley' },
  { label: 'Camelford', value: 'Camelford' },
  { label: 'Çameli', value: 'Çameli' },
  { label: 'Camelia (Barrio La Camelia)', value: 'Camelia (Barrio La Camelia)' },
  { label: 'Camelia Roja', value: 'Camelia Roja' },
  { label: 'Camémbaro', value: 'Camémbaro' },
  { label: 'Camenca', value: 'Camenca' },
  { label: 'Camerana', value: 'Camerana' },
  { label: 'Camerano', value: 'Camerano' },
  { label: 'Camerano', value: 'Camerano' },
  { label: 'Camerano Casasco', value: 'Camerano Casasco' },
  { label: 'Camerata Cornello', value: 'Camerata Cornello' },
  { label: 'Camerata Nuova', value: 'Camerata Nuova' },
  { label: 'Camerata Picena', value: 'Camerata Picena' },
  { label: 'Cameri', value: 'Cameri' },
  { label: 'Camerino', value: 'Camerino' },
  { label: 'Camerino Z. Mendoza', value: 'Camerino Z. Mendoza' },
  { label: 'Cameron', value: 'Cameron' },
  { label: 'Cameron', value: 'Cameron' },
  { label: 'Cameron', value: 'Cameron' },
  { label: 'Cameron', value: 'Cameron' },
  { label: 'Cameron County', value: 'Cameron County' },
  { label: 'Cameron County', value: 'Cameron County' },
  { label: 'Cameron Parish', value: 'Cameron Parish' },
  { label: 'Cameron Park', value: 'Cameron Park' },
  { label: 'Cameron Park', value: 'Cameron Park' },
  { label: 'Cameron Park', value: 'Cameron Park' },
  { label: 'Cameron Park Colonia', value: 'Cameron Park Colonia' },
  { label: 'Camerota', value: 'Camerota' },
  { label: 'Cametá', value: 'Cametá' },
  { label: 'Camias', value: 'Camias' },
  { label: 'Camias', value: 'Camias' },
  { label: 'Camichín De Jauja', value: 'Camichín De Jauja' },
  { label: 'Camiers', value: 'Camiers' },
  { label: 'Camigliano', value: 'Camigliano' },
  { label: 'Camiling', value: 'Camiling' },
  { label: 'Camiling', value: 'Camiling' },
  { label: 'Camilla', value: 'Camilla' },
  { label: 'Camilleri-Vallelata', value: 'Camilleri-Vallelata' },
  { label: 'Camillo', value: 'Camillo' },
  { label: 'Camillus', value: 'Camillus' },
  { label: 'Camilo Aldao', value: 'Camilo Aldao' },
  { label: 'Cămin', value: 'Cămin' },
  { label: 'Camiña', value: 'Camiña' },
  { label: 'Caminata', value: 'Caminata' },
  { label: 'Caminauit', value: 'Caminauit' },
  { label: 'Camindangan', value: 'Camindangan' },
  { label: 'Camingawan', value: 'Camingawan' },
  { label: 'Caminha', value: 'Caminha' },
  { label: 'Camini', value: 'Camini' },
  { label: 'Camino', value: 'Camino' },
  { label: 'Camino', value: 'Camino' },
  { label: 'Camino Al Tagliamento', value: 'Camino Al Tagliamento' },
  { label: 'Caminomorisco', value: 'Caminomorisco' },
  { label: 'Caminreal', value: 'Caminreal' },
  { label: 'Camira', value: 'Camira' },
  { label: 'Camiri', value: 'Camiri' },
  { label: 'Camisano', value: 'Camisano' },
  { label: 'Camisano Vicentino', value: 'Camisano Vicentino' },
  { label: 'Camlachie', value: 'Camlachie' },
  { label: 'Çamlıdere', value: 'Çamlıdere' },
  { label: 'Çamlıhemşin', value: 'Çamlıhemşin' },
  { label: 'Çamlıyayla', value: 'Çamlıyayla' },
  { label: 'Cammarata', value: 'Cammarata' },
  { label: 'Cammeray', value: 'Cammeray' },
  { label: 'Camminghaburen', value: 'Camminghaburen' },
  { label: 'Camnago-Boscone', value: 'Camnago-Boscone' },
  { label: 'Camo', value: 'Camo' },
  { label: 'Camoapa', value: 'Camoapa' },
  { label: 'Camocim', value: 'Camocim' },
  { label: 'Camocim De São Félix', value: 'Camocim De São Félix' },
  { label: 'Camocuautla', value: 'Camocuautla' },
  { label: 'Camogantí', value: 'Camogantí' },
  { label: 'Camogli', value: 'Camogli' },
  { label: 'Camohaguin', value: 'Camohaguin' },
  { label: 'Çamoluk', value: 'Çamoluk' },
  { label: 'Camon', value: 'Camon' },
  { label: 'Camoneone', value: 'Camoneone' },
  { label: 'Camorino', value: 'Camorino' },
  { label: 'Camors', value: 'Camors' },
  { label: 'Camós', value: 'Camós' },
  { label: 'Camotán', value: 'Camotán' },
  { label: 'Camotepec', value: 'Camotepec' },
  { label: 'Camotinchan', value: 'Camotinchan' },
  { label: 'Camotlán De Miraflores', value: 'Camotlán De Miraflores' },
  { label: 'Camp Carol', value: 'Camp Carol' },
  { label: 'Camp County', value: 'Camp County' },
  { label: 'Camp De Mar', value: 'Camp De Mar' },
  { label: 'Camp De Masque', value: 'Camp De Masque' },
  { label: 'Camp De Masque Pave', value: 'Camp De Masque Pave' },
  { label: 'Camp Diable', value: 'Camp Diable' },
  { label: 'Camp Flora', value: 'Camp Flora' },
  { label: 'Camp Fouqueraux', value: 'Camp Fouqueraux' },
  { label: 'Camp Hill', value: 'Camp Hill' },
  { label: 'Camp Hill', value: 'Camp Hill' },
  { label: 'Camp Ithier', value: 'Camp Ithier' },
  { label: 'Camp Lake', value: 'Camp Lake' },
  { label: 'Camp Meeker', value: 'Camp Meeker' },
  { label: 'Camp Mountain', value: 'Camp Mountain' },
  { label: 'Camp Pendleton North', value: 'Camp Pendleton North' },
  { label: 'Camp Pendleton South', value: 'Camp Pendleton South' },
  { label: 'Camp Point', value: 'Camp Point' },
  { label: 'Camp Sarah', value: 'Camp Sarah' },
  { label: 'Camp Springs', value: 'Camp Springs' },
  { label: 'Camp Swift', value: 'Camp Swift' },
  { label: 'Camp Thorel', value: 'Camp Thorel' },
  { label: 'Camp Verde', value: 'Camp Verde' },
  { label: 'Campagna', value: 'Campagna' },
  { label: 'Campagna Lupia', value: 'Campagna Lupia' },
  { label: 'Campagnano Di Roma', value: 'Campagnano Di Roma' },
  { label: 'Campagnatico', value: 'Campagnatico' },
  { label: 'Campagne-Lès-Hesdin', value: 'Campagne-Lès-Hesdin' },
  { label: 'Campagnola', value: 'Campagnola' },
  { label: 'Campagnola Cremasca', value: 'Campagnola Cremasca' },
  { label: 'Campagnola Emilia', value: 'Campagnola Emilia' },
  { label: 'Campalto', value: 'Campalto' },
  { label: 'Campamento', value: 'Campamento' },
  { label: 'Campamento', value: 'Campamento' },
  { label: 'Campan', value: 'Campan' },
  { label: 'Campana', value: 'Campana' },
  { label: 'Campana La', value: 'Campana La' },
  { label: 'Campanarello', value: 'Campanarello' },
  { label: 'Campanario', value: 'Campanario' },
  { label: 'Campanario', value: 'Campanario' },
  { label: 'Campanário', value: 'Campanário' },
  { label: 'Campanário', value: 'Campanário' },
  { label: 'Campanella-Gianforma', value: 'Campanella-Gianforma' },
  { label: 'Campanet', value: 'Campanet' },
  { label: 'Campanha', value: 'Campanha' },
  { label: 'Câmpani', value: 'Câmpani' },
  { label: 'Câmpani De Pomezeu', value: 'Câmpani De Pomezeu' },
  { label: 'Campanilla', value: 'Campanilla' },
  { label: 'Camparada', value: 'Camparada' },
  { label: 'Campaspe', value: 'Campaspe' },
  { label: 'Campaspero', value: 'Campaspero' },
  { label: 'Campazas', value: 'Campazas' },
  { label: 'Campbell', value: 'Campbell' },
  { label: 'Campbell', value: 'Campbell' },
  { label: 'Campbell', value: 'Campbell' },
  { label: 'Campbell', value: 'Campbell' },
  { label: 'Campbell', value: 'Campbell' },
  { label: 'Campbell County', value: 'Campbell County' },
  { label: 'Campbell County', value: 'Campbell County' },
  { label: 'Campbell County', value: 'Campbell County' },
  { label: 'Campbell County', value: 'Campbell County' },
  { label: 'Campbell County', value: 'Campbell County' },
  { label: 'Campbell River', value: 'Campbell River' },
  { label: 'Campbell Town', value: 'Campbell Town' },
  { label: 'Campbellfield', value: 'Campbellfield' },
  { label: 'Campbells Creek', value: 'Campbells Creek' },
  { label: 'Campbellsport', value: 'Campbellsport' },
  { label: 'Campbellsville', value: 'Campbellsville' },
  { label: 'Campbellton', value: 'Campbellton' },
  { label: 'Campbelltown', value: 'Campbelltown' },
  { label: 'Campbelltown', value: 'Campbelltown' },
  { label: 'Campbelltown', value: 'Campbelltown' },
  { label: 'Campbelltown Municipality', value: 'Campbelltown Municipality' },
  { label: 'Campbeltown', value: 'Campbeltown' },
  { label: 'Campbon', value: 'Campbon' },
  { label: 'Campdevànol', value: 'Campdevànol' },
  { label: 'Campeche', value: 'Campeche' },
  { label: 'Campechuela', value: 'Campechuela' },
  { label: 'Campegine', value: 'Campegine' },
  { label: 'Campelles', value: 'Campelles' },
  { label: 'Campello El', value: 'Campello El' },
  { label: 'Campello Sul Clitunno', value: 'Campello Sul Clitunno' },
  { label: 'Campelos', value: 'Campelos' },
  { label: 'Campelos', value: 'Campelos' },
  { label: 'Campénéac', value: 'Campénéac' },
  { label: 'Câmpeni', value: 'Câmpeni' },
  { label: 'Câmpenița', value: 'Câmpenița' },
  { label: 'Camperdown', value: 'Camperdown' },
  { label: 'Camperdown', value: 'Camperdown' },
  { label: 'Camperduin', value: 'Camperduin' },
  { label: 'Campertogno', value: 'Campertogno' },
  { label: 'Campese', value: 'Campese' },
  { label: 'Campestre', value: 'Campestre' },
  { label: 'Campestre', value: 'Campestre' },
  { label: 'Campestre Da Serra', value: 'Campestre Da Serra' },
  { label: 'Campestre De Goiás', value: 'Campestre De Goiás' },
  { label: 'Campestre Do Maranhão', value: 'Campestre Do Maranhão' },
  { label: 'Campestre El Montesito', value: 'Campestre El Montesito' },
  { label: 'Campestre Flamboyanes', value: 'Campestre Flamboyanes' },
  { label: 'Campestre Las Palomas Fraccionamiento', value: 'Campestre Las Palomas Fraccionamiento' },
  { label: 'Campestre Tarímbaro', value: 'Campestre Tarímbaro' },
  { label: 'Campestre Villas Del Álamo', value: 'Campestre Villas Del Álamo' },
  { label: 'Campezo/Kanpezu', value: 'Campezo/Kanpezu' },
  { label: 'Camphin-En-Carembault', value: 'Camphin-En-Carembault' },
  { label: 'Camphin-En-Pévèle', value: 'Camphin-En-Pévèle' },
  { label: 'Campi Bisenzio', value: 'Campi Bisenzio' },
  { label: 'Campi Salentina', value: 'Campi Salentina' },
  { label: 'Câmpia Turzii', value: 'Câmpia Turzii' },
  { label: 'Campiglia Cervo', value: 'Campiglia Cervo' },
  { label: 'Campiglia Dei Berici', value: 'Campiglia Dei Berici' },
  { label: 'Campiglia Marittima', value: 'Campiglia Marittima' },
  { label: 'Campiglione', value: 'Campiglione' },
  { label: 'Campiglione', value: 'Campiglione' },
  { label: 'Campillo De Altobuey', value: 'Campillo De Altobuey' },
  { label: 'Campillo De Aragón', value: 'Campillo De Aragón' },
  { label: 'Campillo De Aranda', value: 'Campillo De Aranda' },
  { label: 'Campillo De Arenas', value: 'Campillo De Arenas' },
  { label: 'Campillo De Azaba', value: 'Campillo De Azaba' },
  { label: 'Campillo De Deleitosa', value: 'Campillo De Deleitosa' },
  { label: 'Campillo De Dueñas', value: 'Campillo De Dueñas' },
  { label: 'Campillo De La Jara El', value: 'Campillo De La Jara El' },
  { label: 'Campillo De Llerena', value: 'Campillo De Llerena' },
  { label: 'Campillo De Ranas', value: 'Campillo De Ranas' },
  { label: 'Campillo El', value: 'Campillo El' },
  { label: 'Campillo El', value: 'Campillo El' },
  { label: 'Campillos', value: 'Campillos' },
  { label: 'Campillos-Paravientos', value: 'Campillos-Paravientos' },
  { label: 'Campillos-Sierra', value: 'Campillos-Sierra' },
  { label: 'Câmpina', value: 'Câmpina' },
  { label: 'Campina Da Lagoa', value: 'Campina Da Lagoa' },
  { label: 'Campina Das Missões', value: 'Campina Das Missões' },
  { label: 'Campina Do Monte Alegre', value: 'Campina Do Monte Alegre' },
  { label: 'Campina Do Simão', value: 'Campina Do Simão' },
  { label: 'Campina Grande', value: 'Campina Grande' },
  { label: 'Campina Grande Do Sul', value: 'Campina Grande Do Sul' },
  { label: 'Campina Verde', value: 'Campina Verde' },
  { label: 'Campinaçu', value: 'Campinaçu' },
  { label: 'Campinápolis', value: 'Campinápolis' },
  { label: 'Campinas', value: 'Campinas' },
  { label: 'Campinas Do Piauí', value: 'Campinas Do Piauí' },
  { label: 'Campinas Do Sul', value: 'Campinas Do Sul' },
  { label: 'Câmpineanca', value: 'Câmpineanca' },
  { label: 'Campinorte', value: 'Campinorte' },
  { label: 'Campins', value: 'Campins' },
  { label: 'Campion', value: 'Campion' },
  { label: 'Campione', value: 'Campione' },
  { label: 'Campisábalos', value: 'Campisábalos' },
  { label: 'Campitello', value: 'Campitello' },
  { label: 'Campitello Di Fassa', value: 'Campitello Di Fassa' },
  { label: 'Campli', value: 'Campli' },
  { label: 'Campllong', value: 'Campllong' },
  { label: 'Campo', value: 'Campo' },
  { label: 'Campo', value: 'Campo' },
  { label: 'Campo', value: 'Campo' },
  { label: 'Campo', value: 'Campo' },
  { label: 'Campo', value: 'Campo' },
  { label: 'Campo Acosta', value: 'Campo Acosta' },
  { label: 'Campo Alegre', value: 'Campo Alegre' },
  { label: 'Campo Alegre', value: 'Campo Alegre' },
  { label: 'Campo Alegre De Goiás', value: 'Campo Alegre De Goiás' },
  { label: 'Campo Alegre De Lourdes', value: 'Campo Alegre De Lourdes' },
  { label: 'Campo Alegre Do Fidalgo', value: 'Campo Alegre Do Fidalgo' },
  { label: 'Campo Azul', value: 'Campo Azul' },
  { label: 'Campo Balbuena', value: 'Campo Balbuena' },
  { label: 'Campo Belo', value: 'Campo Belo' },
  { label: 'Campo Belo Do Sul', value: 'Campo Belo Do Sul' },
  { label: 'Campo Bom', value: 'Campo Bom' },
  { label: 'Campo Bonito', value: 'Campo Bonito' },
  { label: 'Campo Calabro', value: 'Campo Calabro' },
  { label: 'Campo Carretero', value: 'Campo Carretero' },
  { label: 'Campo Chico', value: 'Campo Chico' },
  { label: 'Campo De Águila', value: 'Campo De Águila' },
  { label: 'Campo De Besteiros', value: 'Campo De Besteiros' },
  { label: 'Campo De Criptana', value: 'Campo De Criptana' },
  { label: 'Campo De La Cruz', value: 'Campo De La Cruz' },
  { label: 'Campo De Mirra/Camp De Mirra El', value: 'Campo De Mirra/Camp De Mirra El' },
  { label: 'Campo De Peñaranda El', value: 'Campo De Peñaranda El' },
  { label: 'Campo De San Pedro', value: 'Campo De San Pedro' },
  { label: 'Campo De Villavidel', value: 'Campo De Villavidel' },
  { label: 'Campo Di Carne', value: 'Campo Di Carne' },
  { label: 'Campo Di Giove', value: 'Campo Di Giove' },
  { label: 'Campo Di Trens', value: 'Campo Di Trens' },
  { label: 'Campo Do Brito', value: 'Campo Do Brito' },
  { label: 'Campo Do Meio', value: 'Campo Do Meio' },
  { label: 'Campo Do Tenente', value: 'Campo Do Tenente' },
  { label: 'Campo Erê', value: 'Campo Erê' },
  { label: 'Campo Florido', value: 'Campo Florido' },
  { label: 'Campo Formoso', value: 'Campo Formoso' },
  { label: 'Campo Gallo', value: 'Campo Gallo' },
  { label: 'Campo Grande', value: 'Campo Grande' },
  { label: 'Campo Grande', value: 'Campo Grande' },
  { label: 'Campo Grande', value: 'Campo Grande' },
  { label: 'Campo Grande', value: 'Campo Grande' },
  { label: 'Campo Grande', value: 'Campo Grande' },
  { label: 'Campo Grande Do Piauí', value: 'Campo Grande Do Piauí' },
  { label: 'Campo Jemini', value: 'Campo Jemini' },
  { label: 'Campo La Arrocera', value: 'Campo La Arrocera' },
  { label: 'Campo Lameiro', value: 'Campo Lameiro' },
  { label: 'Campo Largo', value: 'Campo Largo' },
  { label: 'Campo Largo', value: 'Campo Largo' },
  { label: 'Campo Largo Do Piauí', value: 'Campo Largo Do Piauí' },
  { label: 'Campo Ligure', value: 'Campo Ligure' },
  { label: 'Campo Limpido-Favale', value: 'Campo Limpido-Favale' },
  { label: 'Campo Limpo De Goiás', value: 'Campo Limpo De Goiás' },
  { label: 'Campo Limpo Paulista', value: 'Campo Limpo Paulista' },
  { label: 'Campo Lugar', value: 'Campo Lugar' },
  { label: 'Campo Magro', value: 'Campo Magro' },
  { label: 'Campo Maior', value: 'Campo Maior' },
  { label: 'Campo Maior', value: 'Campo Maior' },
  { label: 'Campo Mourão', value: 'Campo Mourão' },
  { label: 'Campo Nellelba', value: 'Campo Nellelba' },
  { label: 'Campo Novo', value: 'Campo Novo' },
  { label: 'Campo Novo De Rondônia', value: 'Campo Novo De Rondônia' },
  { label: 'Campo Novo Do Parecis', value: 'Campo Novo Do Parecis' },
  { label: 'Campo Pesquero El Colorado', value: 'Campo Pesquero El Colorado' },
  { label: 'Campo Quijano', value: 'Campo Quijano' },
  { label: 'Campo Ramón', value: 'Campo Ramón' },
  { label: 'Campo Real', value: 'Campo Real' },
  { label: 'Campo Real', value: 'Campo Real' },
  { label: 'Campo Redondo', value: 'Campo Redondo' },
  { label: 'Campo Rico', value: 'Campo Rico' },
  { label: 'Campo San Martino', value: 'Campo San Martino' },
  { label: 'Campo Sesenta', value: 'Campo Sesenta' },
  { label: 'Campo Tizzoro', value: 'Campo Tizzoro' },
  { label: 'Campo Tures', value: 'Campo Tures' },
  { label: 'Campo Verde', value: 'Campo Verde' },
  { label: 'Campo Verde', value: 'Campo Verde' },
  { label: 'Campo Viera', value: 'Campo Viera' },
  { label: 'Campoalegre', value: 'Campoalegre' },
  { label: 'Campobasso', value: 'Campobasso' },
  { label: 'Campobello Di Licata', value: 'Campobello Di Licata' },
  { label: 'Campobello Di Mazara', value: 'Campobello Di Mazara' },
  { label: 'Campocavallo', value: 'Campocavallo' },
  { label: 'Campochiaro', value: 'Campochiaro' },
  { label: 'Campochiesa', value: 'Campochiesa' },
  { label: 'Campodarsego', value: 'Campodarsego' },
  { label: 'Campodenno', value: 'Campodenno' },
  { label: 'Campodimele', value: 'Campodimele' },
  { label: 'Campodipietra', value: 'Campodipietra' },
  { label: 'Campodolcino', value: 'Campodolcino' },
  { label: 'Campodoro', value: 'Campodoro' },
  { label: 'Campofelice Di Fitalia', value: 'Campofelice Di Fitalia' },
  { label: 'Campofelice Di Roccella', value: 'Campofelice Di Roccella' },
  { label: 'Campofilone', value: 'Campofilone' },
  { label: 'Campofiorenzo-California', value: 'Campofiorenzo-California' },
  { label: 'Campofiorito', value: 'Campofiorito' },
  { label: 'Campoformido', value: 'Campoformido' },
  { label: 'Campofranco', value: 'Campofranco' },
  { label: 'Campofrío', value: 'Campofrío' },
  { label: 'Campogalliano', value: 'Campogalliano' },
  { label: 'Campohermoso', value: 'Campohermoso' },
  { label: 'Campolara', value: 'Campolara' },
  { label: 'Campolattaro', value: 'Campolattaro' },
  { label: 'Campoleone', value: 'Campoleone' },
  { label: 'Campoli Appennino', value: 'Campoli Appennino' },
  { label: 'Campoli Del Monte Taburno', value: 'Campoli Del Monte Taburno' },
  { label: 'Campolide', value: 'Campolide' },
  { label: 'Campolieto', value: 'Campolieto' },
  { label: 'Campolongo Al Torre', value: 'Campolongo Al Torre' },
  { label: 'Campolongo Al Torre-Cavenzano', value: 'Campolongo Al Torre-Cavenzano' },
  { label: 'Campolongo Maggiore Liettoli', value: 'Campolongo Maggiore Liettoli' },
  { label: 'Campolongo Sul Brenta', value: 'Campolongo Sul Brenta' },
  { label: 'Campomaggiore', value: 'Campomaggiore' },
  { label: 'Campomarino', value: 'Campomarino' },
  { label: 'Campomolino', value: 'Campomolino' },
  { label: 'Campomorone', value: 'Campomorone' },
  { label: 'Camponaraya', value: 'Camponaraya' },
  { label: 'Camponogara', value: 'Camponogara' },
  { label: 'Campoo De Enmedio', value: 'Campoo De Enmedio' },
  { label: 'Campoo De Yuso', value: 'Campoo De Yuso' },
  { label: 'Campora', value: 'Campora' },
  { label: 'Campora San Giovanni', value: 'Campora San Giovanni' },
  { label: 'Camporeale', value: 'Camporeale' },
  { label: 'Camporgiano', value: 'Camporgiano' },
  { label: 'Camporosso', value: 'Camporosso' },
  { label: 'Camporotondo Di Fiastrone', value: 'Camporotondo Di Fiastrone' },
  { label: 'Camporotondo Etneo', value: 'Camporotondo Etneo' },
  { label: 'Camporredondo', value: 'Camporredondo' },
  { label: 'Camporrells', value: 'Camporrells' },
  { label: 'Camporrobles', value: 'Camporrobles' },
  { label: 'Campos', value: 'Campos' },
  { label: 'Campos Altos', value: 'Campos Altos' },
  { label: 'Campos Belos', value: 'Campos Belos' },
  { label: 'Campos Borges', value: 'Campos Borges' },
  { label: 'Campos De Júlio', value: 'Campos De Júlio' },
  { label: 'Campos Del Paraíso', value: 'Campos Del Paraíso' },
  { label: 'Campos Del Río', value: 'Campos Del Río' },
  { label: 'Campos Do Jordão', value: 'Campos Do Jordão' },
  { label: 'Campos Dos Goytacazes', value: 'Campos Dos Goytacazes' },
  { label: 'Campos Gerais', value: 'Campos Gerais' },
  { label: 'Campos Lindos', value: 'Campos Lindos' },
  { label: 'Campos Novos', value: 'Campos Novos' },
  { label: 'Campos Novos Paulista', value: 'Campos Novos Paulista' },
  { label: 'Campos Sales', value: 'Campos Sales' },
  { label: 'Campos Verdes', value: 'Campos Verdes' },
  { label: 'Camposampiero', value: 'Camposampiero' },
  { label: 'Camposano', value: 'Camposano' },
  { label: 'Camposanto', value: 'Camposanto' },
  { label: 'Campospinoso', value: 'Campospinoso' },
  { label: 'Campotéjar', value: 'Campotéjar' },
  { label: 'Campotosto', value: 'Campotosto' },
  { label: 'Campoverde', value: 'Campoverde' },
  { label: 'Campoyo', value: 'Campoyo' },
  { label: 'Camprodon', value: 'Camprodon' },
  { label: 'Camprovín', value: 'Camprovín' },
  { label: 'Campsall', value: 'Campsall' },
  { label: 'Campsas', value: 'Campsas' },
  { label: 'Campsie', value: 'Campsie' },
  { label: 'Camps-La-Source', value: 'Camps-La-Source' },
  { label: 'Campti', value: 'Campti' },
  { label: 'Campton', value: 'Campton' },
  { label: 'Câmpu Cetății', value: 'Câmpu Cetății' },
  { label: 'Câmpulung', value: 'Câmpulung' },
  { label: 'Câmpulung La Tisa', value: 'Câmpulung La Tisa' },
  { label: 'Câmpulung Moldovenesc', value: 'Câmpulung Moldovenesc' },
  { label: 'Câmpurelu', value: 'Câmpurelu' },
  { label: 'Câmpuri', value: 'Câmpuri' },
  { label: 'Câmpurile De Jos', value: 'Câmpurile De Jos' },
  { label: 'Campusong', value: 'Campusong' },
  { label: 'Camrose', value: 'Camrose' },
  { label: 'Camucia-Monsigliolo', value: 'Camucia-Monsigliolo' },
  { label: 'Camudmud', value: 'Camudmud' },
  { label: 'Camudmud', value: 'Camudmud' },
  { label: 'Camudmud', value: 'Camudmud' },
  { label: 'Camugnano', value: 'Camugnano' },
  { label: 'Camuñas', value: 'Camuñas' },
  { label: 'Camutanga', value: 'Camutanga' },
  { label: 'Camuy', value: 'Camuy' },
  { label: 'Camuy', value: 'Camuy' },
  { label: 'Çan', value: 'Çan' },
  { label: 'Cần Giờ', value: 'Cần Giờ' },
  { label: 'Cần Giuộc', value: 'Cần Giuộc' },
  { label: 'Can Pastilla', value: 'Can Pastilla' },
  { label: 'Can Picafort', value: 'Can Picafort' },
  { label: 'Cần Thơ', value: 'Cần Thơ' },
  { label: 'Cana', value: 'Cana' },
  { label: 'Cana Chapetón', value: 'Cana Chapetón' },
  { label: 'Cana Verde', value: 'Cana Verde' },
  { label: 'Canaã', value: 'Canaã' },
  { label: 'Canaã Dos Carajás', value: 'Canaã Dos Carajás' },
  { label: 'Canaan', value: 'Canaan' },
  { label: 'Canaan', value: 'Canaan' },
  { label: 'Canabrava Do Norte', value: 'Canabrava Do Norte' },
  { label: 'Canacona', value: 'Canacona' },
  { label: 'Cañada', value: 'Cañada' },
  { label: 'Cañada', value: 'Cañada' },
  { label: 'Canada Bay', value: 'Canada Bay' },
  { label: 'Cañada De Benatanduz', value: 'Cañada De Benatanduz' },
  { label: 'Cañada De Bustos', value: 'Cañada De Bustos' },
  { label: 'Cañada De Calatrava', value: 'Cañada De Calatrava' },
  { label: 'Cañada De Caracheo', value: 'Cañada De Caracheo' },
  { label: 'Cañada De Cisneros', value: 'Cañada De Cisneros' },
  { label: 'Cañada De Gómez', value: 'Cañada De Gómez' },
  { label: 'Cañada De Guadarrama', value: 'Cañada De Guadarrama' },
  { label: 'Cañada De Islas', value: 'Cañada De Islas' },
  { label: 'Cañada De Luque', value: 'Cañada De Luque' },
  { label: 'Cañada De Madero', value: 'Cañada De Madero' },
  { label: 'Cañada De Negros', value: 'Cañada De Negros' },
  { label: 'Cañada De Ramírez', value: 'Cañada De Ramírez' },
  { label: 'Cañada De Ricos', value: 'Cañada De Ricos' },
  { label: 'Cañada De San Ignacio', value: 'Cañada De San Ignacio' },
  { label: 'Cañada De Verich La', value: 'Cañada De Verich La' },
  { label: 'Cañada Del Hoyo', value: 'Cañada Del Hoyo' },
  { label: 'Cañada Del Tabaco', value: 'Cañada Del Tabaco' },
  { label: 'Cañada Grande', value: 'Cañada Grande' },
  { label: 'Cañada Juncosa', value: 'Cañada Juncosa' },
  { label: 'Cañada Rosal', value: 'Cañada Rosal' },
  { label: 'Cañada Vellida', value: 'Cañada Vellida' },
  { label: 'Cañadas De Obregón', value: 'Cañadas De Obregón' },
  { label: 'Canadian', value: 'Canadian' },
  { label: 'Canadian', value: 'Canadian' },
  { label: 'Canadian County', value: 'Canadian County' },
  { label: 'Canadian Lakes', value: 'Canadian Lakes' },
  { label: 'Canajoharie', value: 'Canajoharie' },
  { label: 'Çanakçı', value: 'Çanakçı' },
  { label: 'Canal De Berdún', value: 'Canal De Berdún' },
  { label: 'Canal Fulton', value: 'Canal Fulton' },
  { label: 'Canal San Bovo', value: 'Canal San Bovo' },
  { label: 'Canal Winchester', value: 'Canal Winchester' },
  { label: 'Canale', value: 'Canale' },
  { label: 'Canale Dagordo', value: 'Canale Dagordo' },
  { label: 'Canale Monterano', value: 'Canale Monterano' },
  { label: 'Canalejas', value: 'Canalejas' },
  { label: 'Canalejas De Peñafiel', value: 'Canalejas De Peñafiel' },
  { label: 'Canalejas Del Arroyo', value: 'Canalejas Del Arroyo' },
  { label: 'Canales', value: 'Canales' },
  { label: 'Canales De La Sierra', value: 'Canales De La Sierra' },
  { label: 'Canalete', value: 'Canalete' },
  { label: 'Canalicchio', value: 'Canalicchio' },
  { label: 'Canals', value: 'Canals' },
  { label: 'Canals', value: 'Canals' },
  { label: 'Canaman', value: 'Canaman' },
  { label: 'Cañamaque', value: 'Cañamaque' },
  { label: 'Cañamares', value: 'Cañamares' },
  { label: 'Cañamero', value: 'Cañamero' },
  { label: 'Canan Norte', value: 'Canan Norte' },
  { label: 'Canan Norte', value: 'Canan Norte' },
  { label: 'Canandaigua', value: 'Canandaigua' },
  { label: 'Cananea', value: 'Cananea' },
  { label: 'Cananéia', value: 'Cananéia' },
  { label: 'Canaoalan', value: 'Canaoalan' },
  { label: 'Canaoalan', value: 'Canaoalan' },
  { label: 'Canapi', value: 'Canapi' },
  { label: 'Canápolis', value: 'Canápolis' },
  { label: 'Canápolis', value: 'Canápolis' },
  { label: 'Cañar', value: 'Cañar' },
  { label: 'Cáñar', value: 'Cáñar' },
  { label: 'Canarana', value: 'Canarana' },
  { label: 'Canarana', value: 'Canarana' },
  { label: 'Canaries', value: 'Canaries' },
  { label: 'Canaro', value: 'Canaro' },
  { label: 'Canarsie', value: 'Canarsie' },
  { label: 'Canary Wharf', value: 'Canary Wharf' },
  { label: 'Canas', value: 'Canas' },
  { label: 'Cañas', value: 'Cañas' },
  { label: 'Cañas', value: 'Cañas' },
  { label: 'Cañas', value: 'Cañas' },
  { label: 'Cañas', value: 'Cañas' },
  { label: 'Canas De Senhorim', value: 'Canas De Senhorim' },
  { label: 'Cañas Gordas', value: 'Cañas Gordas' },
  { label: 'Cañasgordas', value: 'Cañasgordas' },
  { label: 'Canastota', value: 'Canastota' },
  { label: 'Can-Asujan', value: 'Can-Asujan' },
  { label: 'Canatlán', value: 'Canatlán' },
  { label: 'Canauay', value: 'Canauay' },
  { label: 'Canavaccio', value: 'Canavaccio' },
  { label: 'Cañavate El', value: 'Cañavate El' },
  { label: 'Cañaveral', value: 'Cañaveral' },
  { label: 'Cañaveral', value: 'Cañaveral' },
  { label: 'Cañaveral', value: 'Cañaveral' },
  { label: 'Cañaveral De León', value: 'Cañaveral De León' },
  { label: 'Cañaveras', value: 'Cañaveras' },
  { label: 'Cañaveruelas', value: 'Cañaveruelas' },
  { label: 'Can-Avid', value: 'Can-Avid' },
  { label: 'Can-Avid', value: 'Can-Avid' },
  { label: 'Canavieira', value: 'Canavieira' },
  { label: 'Canavieiras', value: 'Canavieiras' },
  { label: 'Canayan', value: 'Canayan' },
  { label: 'Cañazas', value: 'Cañazas' },
  { label: 'Canazei', value: 'Canazei' },
  { label: 'Canberra', value: 'Canberra' },
  { label: 'Canby', value: 'Canby' },
  { label: 'Canby', value: 'Canby' },
  { label: 'Cancale', value: 'Cancale' },
  { label: 'Cancellara', value: 'Cancellara' },
  { label: 'Cancello-Arnone', value: 'Cancello-Arnone' },
  { label: 'Canchol', value: 'Canchol' },
  { label: 'Canchungo', value: 'Canchungo' },
  { label: 'Cancon', value: 'Cancon' },
  { label: 'Cancuc', value: 'Cancuc' },
  { label: 'Cancún', value: 'Cancún' },
  { label: 'Canda', value: 'Canda' },
  { label: 'Candaba', value: 'Candaba' },
  { label: 'Candaba', value: 'Candaba' },
  { label: 'Candabong', value: 'Candabong' },
  { label: 'Candamo', value: 'Candamo' },
  { label: 'Candarave', value: 'Candarave' },
  { label: 'Candasnos', value: 'Candasnos' },
  { label: 'Candating', value: 'Candating' },
  { label: 'Candating', value: 'Candating' },
  { label: 'Candé', value: 'Candé' },
  { label: 'Candeal', value: 'Candeal' },
  { label: 'Candeias', value: 'Candeias' },
  { label: 'Candeias', value: 'Candeias' },
  { label: 'Candeias Do Jamari', value: 'Candeias Do Jamari' },
  { label: 'Candela', value: 'Candela' },
  { label: 'Candela', value: 'Candela' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelaria', value: 'Candelaria' },
  { label: 'Candelária', value: 'Candelária' },
  { label: 'Candelaria Arenas', value: 'Candelaria Arenas' },
  { label: 'Candelaria De La Frontera', value: 'Candelaria De La Frontera' },
  { label: 'Candelaria Loxicha', value: 'Candelaria Loxicha' },
  { label: 'Candelaria Portezuelo', value: 'Candelaria Portezuelo' },
  { label: 'Candelario', value: 'Candelario' },
  { label: 'Candeleda', value: 'Candeleda' },
  { label: 'Candelero Arriba', value: 'Candelero Arriba' },
  { label: 'Candelo', value: 'Candelo' },
  { label: 'Candelù', value: 'Candelù' },
  { label: 'Cândeşti', value: 'Cândeşti' },
  { label: 'Cândeşti', value: 'Cândeşti' },
  { label: 'Cândeşti Vale', value: 'Cândeşti Vale' },
  { label: 'Candé-Sur-Beuvron', value: 'Candé-Sur-Beuvron' },
  { label: 'Candi Prambanan', value: 'Candi Prambanan' },
  { label: 'Candia', value: 'Candia' },
  { label: 'Candia Canavese', value: 'Candia Canavese' },
  { label: 'Candia Lomellina', value: 'Candia Lomellina' },
  { label: 'Candiac', value: 'Candiac' },
  { label: 'Candiana', value: 'Candiana' },
  { label: 'Candiba', value: 'Candiba' },
  { label: 'Candida', value: 'Candida' },
  { label: 'Candide', value: 'Candide' },
  { label: 'Cândido De Abreu', value: 'Cândido De Abreu' },
  { label: 'Cândido Godói', value: 'Cândido Godói' },
  { label: 'Cândido Mendes', value: 'Cândido Mendes' },
  { label: 'Cândido Mota', value: 'Cândido Mota' },
  { label: 'Cândido Rodrigues', value: 'Cândido Rodrigues' },
  { label: 'Cândido Sales', value: 'Cândido Sales' },
  { label: 'Candidoni', value: 'Candidoni' },
  { label: 'Candiis', value: 'Candiis' },
  { label: 'Candijay', value: 'Candijay' },
  { label: 'Candilichera', value: 'Candilichera' },
  { label: 'Candillargues', value: 'Candillargues' },
  { label: 'Candín', value: 'Candín' },
  { label: 'Candiolo', value: 'Candiolo' },
  { label: 'Candiota', value: 'Candiota' },
  { label: 'Çandır', value: 'Çandır' },
  { label: 'Candler County', value: 'Candler County' },
  { label: 'Candler-Mcafee', value: 'Candler-Mcafee' },
  { label: 'Cando', value: 'Cando' },
  { label: 'Candói', value: 'Candói' },
  { label: 'Candolim', value: 'Candolim' },
  { label: 'Candon', value: 'Candon' },
  { label: 'Candon', value: 'Candon' },
  { label: 'Candoni', value: 'Candoni' },
  { label: 'Candoso', value: 'Candoso' },
  { label: 'Cându', value: 'Cându' },
  { label: 'Cane', value: 'Cane' },
  { label: 'Cane River', value: 'Cane River' },
  { label: 'Cane Savannah', value: 'Cane Savannah' },
  { label: 'Caneças', value: 'Caneças' },
  { label: 'Caneças', value: 'Caneças' },
  { label: 'Canegrate', value: 'Canegrate' },
  { label: 'Canejan', value: 'Canejan' },
  { label: 'Canéjan', value: 'Canéjan' },
  { label: 'Canela', value: 'Canela' },
  { label: 'Canela', value: 'Canela' },
  { label: 'Canelas', value: 'Canelas' },
  { label: 'Canelinha', value: 'Canelinha' },
  { label: 'Canelles', value: 'Canelles' },
  { label: 'Canelli', value: 'Canelli' },
  { label: 'Canelones', value: 'Canelones' },
  { label: 'Canena', value: 'Canena' },
  { label: 'Canencia', value: 'Canencia' },
  { label: 'Canepina', value: 'Canepina' },
  { label: 'Căneşti', value: 'Căneşti' },
  { label: 'Canet', value: 'Canet' },
  { label: 'Canet Dadri', value: 'Canet Dadri' },
  { label: 'Canet De Mar', value: 'Canet De Mar' },
  { label: 'Canet Den Berenguer', value: 'Canet Den Berenguer' },
  { label: 'Canet Den Berenguer', value: 'Canet Den Berenguer' },
  { label: 'Canet Lo Roig', value: 'Canet Lo Roig' },
  { label: 'Cañete', value: 'Cañete' },
  { label: 'Cañete', value: 'Cañete' },
  { label: 'Cañete De Las Torres', value: 'Cañete De Las Torres' },
  { label: 'Cañete La Real', value: 'Cañete La Real' },
  { label: 'Canet-En-Roussillon', value: 'Canet-En-Roussillon' },
  { label: 'Caneva', value: 'Caneva' },
  { label: 'Canewdon', value: 'Canewdon' },
  { label: 'Caney', value: 'Caney' },
  { label: 'Canfield', value: 'Canfield' },
  { label: 'Canford Heath', value: 'Canford Heath' },
  { label: 'Canfranc', value: 'Canfranc' },
  { label: 'Cangas', value: 'Cangas' },
  { label: 'Cangas De Onís', value: 'Cangas De Onís' },
  { label: 'Cangas Del Narcea', value: 'Cangas Del Narcea' },
  { label: 'Cangey', value: 'Cangey' },
  { label: 'Canguaretama', value: 'Canguaretama' },
  { label: 'Canguçu', value: 'Canguçu' },
  { label: 'Cangzhou', value: 'Cangzhou' },
  { label: 'Cangzhou Shi', value: 'Cangzhou Shi' },
  { label: 'Canhandugan', value: 'Canhandugan' },
  { label: 'Canhandugan', value: 'Canhandugan' },
  { label: 'Canhas', value: 'Canhas' },
  { label: 'Canhaway', value: 'Canhaway' },
  { label: 'Canhoba', value: 'Canhoba' },
  { label: 'Canhotinho', value: 'Canhotinho' },
  { label: 'Caniçal', value: 'Caniçal' },
  { label: 'Canicattì', value: 'Canicattì' },
  { label: 'Canicattini Bagni', value: 'Canicattini Bagni' },
  { label: 'Caniço', value: 'Caniço' },
  { label: 'Canicosa De La Sierra', value: 'Canicosa De La Sierra' },
  { label: 'Canidelo', value: 'Canidelo' },
  { label: 'Caniezza', value: 'Caniezza' },
  { label: 'Canik', value: 'Canik' },
  { label: 'Caniles', value: 'Caniles' },
  { label: 'Canillá', value: 'Canillá' },
  { label: 'Canillas De Abajo', value: 'Canillas De Abajo' },
  { label: 'Canillas De Aceituno', value: 'Canillas De Aceituno' },
  { label: 'Canillas De Albaida', value: 'Canillas De Albaida' },
  { label: 'Canillas De Esgueva', value: 'Canillas De Esgueva' },
  { label: 'Canillas De Río Tuerto', value: 'Canillas De Río Tuerto' },
  { label: 'Canillo', value: 'Canillo' },
  { label: 'Canindé', value: 'Canindé' },
  { label: 'Canindé De São Francisco', value: 'Canindé De São Francisco' },
  { label: 'Caningay', value: 'Caningay' },
  { label: 'Canino', value: 'Canino' },
  { label: 'Canischio', value: 'Canischio' },
  { label: 'Canisteo', value: 'Canisteo' },
  { label: 'Canistro Inferiore', value: 'Canistro Inferiore' },
  { label: 'Cañita', value: 'Cañita' },
  { label: 'Canitar', value: 'Canitar' },
  { label: 'Cañitas De Felipe Pescador', value: 'Cañitas De Felipe Pescador' },
  { label: 'Cañiza A', value: 'Cañiza A' },
  { label: 'Cañizal', value: 'Cañizal' },
  { label: 'Cañizar', value: 'Cañizar' },
  { label: 'Cañizar Del Olivar', value: 'Cañizar Del Olivar' },
  { label: 'Cañizares', value: 'Cañizares' },
  { label: 'Cañizo', value: 'Cañizo' },
  { label: 'Canjáyar', value: 'Canjáyar' },
  { label: 'Canjulao', value: 'Canjulao' },
  { label: 'Çankaya', value: 'Çankaya' },
  { label: 'Cankova', value: 'Cankova' },
  { label: 'Cankuzo', value: 'Cankuzo' },
  { label: 'Canlaon', value: 'Canlaon' },
  { label: 'Canley Heights', value: 'Canley Heights' },
  { label: 'Canley Vale', value: 'Canley Vale' },
  { label: 'Canmaya Diot', value: 'Canmaya Diot' },
  { label: 'Canmore', value: 'Canmore' },
  { label: 'Canna', value: 'Canna' },
  { label: 'Cannalonga', value: 'Cannalonga' },
  { label: 'Cannara', value: 'Cannara' },
  { label: 'Cannelton', value: 'Cannelton' },
  { label: 'Cannero Riviera', value: 'Cannero Riviera' },
  { label: 'Cannes', value: 'Cannes' },
  { label: 'Cannes-Écluse', value: 'Cannes-Écluse' },
  { label: 'Canneto', value: 'Canneto' },
  { label: 'Canneto', value: 'Canneto' },
  { label: 'Canneto Pavese', value: 'Canneto Pavese' },
  { label: 'Canneto Sulloglio', value: 'Canneto Sulloglio' },
  { label: 'Canning', value: 'Canning' },
  { label: 'Canning', value: 'Canning' },
  { label: 'Canning Vale', value: 'Canning Vale' },
  { label: 'Cannington', value: 'Cannington' },
  { label: 'Cannizzaro-Favara', value: 'Cannizzaro-Favara' },
  { label: 'Cannobio', value: 'Cannobio' },
  { label: 'Cannock', value: 'Cannock' },
  { label: 'Cannole', value: 'Cannole' },
  { label: 'Cannon Air Force Base', value: 'Cannon Air Force Base' },
  { label: 'Cannon Beach', value: 'Cannon Beach' },
  { label: 'Cannon County', value: 'Cannon County' },
  { label: 'Cannon Falls', value: 'Cannon Falls' },
  { label: 'Cannon Hill', value: 'Cannon Hill' },
  { label: 'Cannonvale', value: 'Cannonvale' },
  { label: 'Canoa', value: 'Canoa' },
  { label: 'Canoas', value: 'Canoas' },
  { label: 'Canoas', value: 'Canoas' },
  { label: 'Canoas', value: 'Canoas' },
  { label: 'Canoas De Arriba', value: 'Canoas De Arriba' },
  { label: 'Canobbio', value: 'Canobbio' },
  { label: 'Canoga Park', value: 'Canoga Park' },
  { label: 'Canohès', value: 'Canohès' },
  { label: 'Canoinhas', value: 'Canoinhas' },
  { label: 'Canolo', value: 'Canolo' },
  { label: 'Canomoy', value: 'Canomoy' },
  { label: 'Canon', value: 'Canon' },
  { label: 'Cañon City', value: 'Cañon City' },
  { label: 'Cañón De Vacas', value: 'Cañón De Vacas' },
  { label: 'Canonica', value: 'Canonica' },
  { label: 'Canonica Dadda', value: 'Canonica Dadda' },
  { label: 'Canonja La', value: 'Canonja La' },
  { label: 'Canonsburg', value: 'Canonsburg' },
  { label: 'Canora', value: 'Canora' },
  { label: 'Canosa Di Puglia', value: 'Canosa Di Puglia' },
  { label: 'Canosa Sannita', value: 'Canosa Sannita' },
  { label: 'Canosio', value: 'Canosio' },
  { label: 'Canóvanas', value: 'Canóvanas' },
  { label: 'Canóvanas', value: 'Canóvanas' },
  { label: 'Canova-San Zeno', value: 'Canova-San Zeno' },
  { label: 'Canove Di Roana', value: 'Canove Di Roana' },
  { label: 'Canovelles', value: 'Canovelles' },
  { label: 'Cànoves I Samalús', value: 'Cànoves I Samalús' },
  { label: 'Canowindra', value: 'Canowindra' },
  { label: 'Canredondo', value: 'Canredondo' },
  { label: 'Canroma', value: 'Canroma' },
  { label: 'Cansado', value: 'Cansado' },
  { label: 'Cansahcab', value: 'Cansahcab' },
  { label: 'Cansanção', value: 'Cansanção' },
  { label: 'Cansano', value: 'Cansano' },
  { label: 'Cansilayan', value: 'Cansilayan' },
  { label: 'Cansolungon', value: 'Cansolungon' },
  { label: 'Cansuje', value: 'Cansuje' },
  { label: 'Cantá', value: 'Cantá' },
  { label: 'Cantabrana', value: 'Cantabrana' },
  { label: 'Cantabria', value: 'Cantabria' },
  { label: 'Cantagallo', value: 'Cantagallo' },
  { label: 'Cantagallo', value: 'Cantagallo' },
  { label: 'Cantagallo', value: 'Cantagallo' },
  { label: 'Cantagalo', value: 'Cantagalo' },
  { label: 'Cantagalo', value: 'Cantagalo' },
  { label: 'Cantagalo', value: 'Cantagalo' },
  { label: 'Cantagalo District', value: 'Cantagalo District' },
  { label: 'Cantagrillo-Casalguidi', value: 'Cantagrillo-Casalguidi' },
  { label: 'Cantal', value: 'Cantal' },
  { label: 'Cantalapiedra', value: 'Cantalapiedra' },
  { label: 'Cantalejo', value: 'Cantalejo' },
  { label: 'Cantalice', value: 'Cantalice' },
  { label: 'Cantallops', value: 'Cantallops' },
  { label: 'Cantalojas', value: 'Cantalojas' },
  { label: 'Cantalpino', value: 'Cantalpino' },
  { label: 'Cantalupa', value: 'Cantalupa' },
  { label: 'Cantalupo', value: 'Cantalupo' },
  { label: 'Cantalupo In Sabina', value: 'Cantalupo In Sabina' },
  { label: 'Cantalupo Ligure', value: 'Cantalupo Ligure' },
  { label: 'Cantalupo Nel Sannio', value: 'Cantalupo Nel Sannio' },
  { label: 'Cantamayec', value: 'Cantamayec' },
  { label: 'Cantanhede', value: 'Cantanhede' },
  { label: 'Cantanhede', value: 'Cantanhede' },
  { label: 'Cantao-An', value: 'Cantao-An' },
  { label: 'Cantapoy', value: 'Cantapoy' },
  { label: 'Cantapoy', value: 'Cantapoy' },
  { label: 'Cantaracillo', value: 'Cantaracillo' },
  { label: 'Cantarana', value: 'Cantarana' },
  { label: 'Cantaron', value: 'Cantaron' },
  { label: 'Cantaura', value: 'Cantaura' },
  { label: 'Cantavieja', value: 'Cantavieja' },
  { label: 'Cantel', value: 'Cantel' },
  { label: 'Cantel', value: 'Cantel' },
  { label: 'Canteleu', value: 'Canteleu' },
  { label: 'Cantello', value: 'Cantello' },
  { label: 'Cantemir', value: 'Cantemir' },
  { label: 'Cantenac', value: 'Cantenac' },
  { label: 'Cantenay-Épinard', value: 'Cantenay-Épinard' },
  { label: 'Cantera De Villagrán', value: 'Cantera De Villagrán' },
  { label: 'Canterano', value: 'Canterano' },
  { label: 'Canterbury', value: 'Canterbury' },
  { label: 'Canterbury', value: 'Canterbury' },
  { label: 'Canterbury', value: 'Canterbury' },
  { label: 'Canterbury', value: 'Canterbury' },
  { label: 'Canterbury', value: 'Canterbury' },
  { label: 'Canterwood', value: 'Canterwood' },
  { label: 'Cantiano', value: 'Cantiano' },
  { label: 'Cantilan', value: 'Cantilan' },
  { label: 'Cantilan', value: 'Cantilan' },
  { label: 'Cantillana', value: 'Cantillana' },
  { label: 'Cantimpalos', value: 'Cantimpalos' },
  { label: 'Cantin', value: 'Cantin' },
  { label: 'Cantinela', value: 'Cantinela' },
  { label: 'Cantinella', value: 'Cantinella' },
  { label: 'Cantioc', value: 'Cantioc' },
  { label: 'Cantiveros', value: 'Cantiveros' },
  { label: 'Cantley', value: 'Cantley' },
  { label: 'Canto Del Llano', value: 'Canto Del Llano' },
  { label: 'Canto Do Buriti', value: 'Canto Do Buriti' },
  { label: 'Cantoira', value: 'Cantoira' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton', value: 'Canton' },
  { label: 'Canton Beach', value: 'Canton Beach' },
  { label: 'Cantón Las Delicias', value: 'Cantón Las Delicias' },
  { label: 'Cantón Portoviejo', value: 'Cantón Portoviejo' },
  { label: 'Cantón Rancho Nuevo', value: 'Cantón Rancho Nuevo' },
  { label: 'Cantón San Fernando', value: 'Cantón San Fernando' },
  { label: 'Canton Valley', value: 'Canton Valley' },
  { label: 'Cantón Villaflor', value: 'Cantón Villaflor' },
  { label: 'Cantonement', value: 'Cantonement' },
  { label: 'Cantonment', value: 'Cantonment' },
  { label: 'Cantoria', value: 'Cantoria' },
  { label: 'Cantoria', value: 'Cantoria' },
  { label: 'Cantoria', value: 'Cantoria' },
  { label: 'Cantú', value: 'Cantú' },
  { label: 'Cantù', value: 'Cantù' },
  { label: 'Cantuna', value: 'Cantuna' },
  { label: 'Canturay', value: 'Canturay' },
  { label: 'Canudos', value: 'Canudos' },
  { label: 'Canudos Do Vale', value: 'Canudos Do Vale' },
  { label: 'Canungra', value: 'Canungra' },
  { label: 'Canutama', value: 'Canutama' },
  { label: 'Canutillo', value: 'Canutillo' },
  { label: 'Canvey Island', value: 'Canvey Island' },
  { label: 'Cany-Barville', value: 'Cany-Barville' },
  { label: 'Canyelles', value: 'Canyelles' },
  { label: 'Canyon', value: 'Canyon' },
  { label: 'Canyon City', value: 'Canyon City' },
  { label: 'Canyon County', value: 'Canyon County' },
  { label: 'Canyon Day', value: 'Canyon Day' },
  { label: 'Canyon Lake', value: 'Canyon Lake' },
  { label: 'Canyon Lake', value: 'Canyon Lake' },
  { label: 'Canyon Rim', value: 'Canyon Rim' },
  { label: 'Canyonville', value: 'Canyonville' },
  { label: 'Canzano', value: 'Canzano' },
  { label: 'Canzo', value: 'Canzo' },
  { label: 'Cao Bằng', value: 'Cao Bằng' },
  { label: 'Cao Lãnh', value: 'Cao Lãnh' },
  { label: 'Caoayan', value: 'Caoayan' },
  { label: 'Caoayan', value: 'Caoayan' },
  { label: 'Caobas', value: 'Caobas' },
  { label: 'Caodian', value: 'Caodian' },
  { label: 'Caohe', value: 'Caohe' },
  { label: 'Caol', value: 'Caol' },
  { label: 'Caorle', value: 'Caorle' },
  { label: 'Caorso', value: 'Caorso' },
  { label: 'Cap Estate', value: 'Cap Estate' },
  { label: 'Cap Estate/Becune Point', value: 'Cap Estate/Becune Point' },
  { label: 'Cap Estate/Caribbean Park', value: 'Cap Estate/Caribbean Park' },
  { label: 'Cap Estate/Golf Park', value: 'Cap Estate/Golf Park' },
  { label: 'Cap Estate/Lower Saline Point', value: 'Cap Estate/Lower Saline Point' },
  { label: 'Cap Estate/Mon Du Cap', value: 'Cap Estate/Mon Du Cap' },
  { label: 'Cap Estate/Ranch Site', value: 'Cap Estate/Ranch Site' },
  { label: 'Cap Estate/Saddlec Back', value: 'Cap Estate/Saddlec Back' },
  { label: 'Cap Estate/Upper Saline Point', value: 'Cap Estate/Upper Saline Point' },
  { label: 'Cap Malheureux', value: 'Cap Malheureux' },
  { label: 'Cap Negro Ii', value: 'Cap Negro Ii' },
  { label: 'Capac', value: 'Capac' },
  { label: 'Capaccio', value: 'Capaccio' },
  { label: 'Capaccio Scalo', value: 'Capaccio Scalo' },
  { label: 'Capacho', value: 'Capacho' },
  { label: 'Capaci', value: 'Capaci' },
  { label: 'Capácuaro', value: 'Capácuaro' },
  { label: 'Capafonts', value: 'Capafonts' },
  { label: 'Capaga', value: 'Capaga' },
  { label: 'Capalaba', value: 'Capalaba' },
  { label: 'Capalayan', value: 'Capalayan' },
  { label: 'Capalayan', value: 'Capalayan' },
  { label: 'Capalbio', value: 'Capalbio' },
  { label: 'Căpâlna', value: 'Căpâlna' },
  { label: 'Căpâlna De Sus', value: 'Căpâlna De Sus' },
  { label: 'Căpâlniţa', value: 'Căpâlniţa' },
  { label: 'Capalonga', value: 'Capalonga' },
  { label: 'Capandanan', value: 'Capandanan' },
  { label: 'Capandanan', value: 'Capandanan' },
  { label: 'Capanema', value: 'Capanema' },
  { label: 'Capanema', value: 'Capanema' },
  { label: 'Capanne-Prato-Cinquale', value: 'Capanne-Prato-Cinquale' },
  { label: 'Capannoli', value: 'Capannoli' },
  { label: 'Capannori', value: 'Capannori' },
  { label: 'Capão Alto', value: 'Capão Alto' },
  { label: 'Capão Bonito', value: 'Capão Bonito' },
  { label: 'Capão Bonito Do Sul', value: 'Capão Bonito Do Sul' },
  { label: 'Capão Da Canoa', value: 'Capão Da Canoa' },
  { label: 'Capão Do Cipó', value: 'Capão Do Cipó' },
  { label: 'Capão Do Leão', value: 'Capão Do Leão' },
  { label: 'Caparaó', value: 'Caparaó' },
  { label: 'Capari', value: 'Capari' },
  { label: 'Caparica', value: 'Caparica' },
  { label: 'Caparrapí', value: 'Caparrapí' },
  { label: 'Caparroso', value: 'Caparroso' },
  { label: 'Caparroso', value: 'Caparroso' },
  { label: 'Capas', value: 'Capas' },
  { label: 'Capas', value: 'Capas' },
  { label: 'Căpățânenii Pământeni', value: 'Căpățânenii Pământeni' },
  { label: 'Căpățânești', value: 'Căpățânești' },
  { label: 'Capayán', value: 'Capayán' },
  { label: 'Capbreton', value: 'Capbreton' },
  { label: 'Capçanes', value: 'Capçanes' },
  { label: 'Cap-Chat', value: 'Cap-Chat' },
  { label: 'Cap-D’Ail', value: 'Cap-D’Ail' },
  { label: 'Capdenac-Gare', value: 'Capdenac-Gare' },
  { label: 'Capdepera', value: 'Capdepera' },
  { label: 'Capdesaso', value: 'Capdesaso' },
  { label: 'Cape Breton County', value: 'Cape Breton County' },
  { label: 'Cape Canaveral', value: 'Cape Canaveral' },
  { label: 'Cape Carteret', value: 'Cape Carteret' },
  { label: 'Cape Charles', value: 'Cape Charles' },
  { label: 'Cape Coast', value: 'Cape Coast' },
  { label: 'Cape Coral', value: 'Cape Coral' },
  { label: 'Cape Girardeau', value: 'Cape Girardeau' },
  { label: 'Cape Girardeau County', value: 'Cape Girardeau County' },
  { label: 'Cape May', value: 'Cape May' },
  { label: 'Cape May County', value: 'Cape May County' },
  { label: 'Cape May Court House', value: 'Cape May Court House' },
  { label: 'Cape Neddick', value: 'Cape Neddick' },
  { label: 'Cape Pantoja', value: 'Cape Pantoja' },
  { label: 'Cape Saint Claire', value: 'Cape Saint Claire' },
  { label: 'Cape Town', value: 'Cape Town' },
  { label: 'Cape Winelands District Municipality', value: 'Cape Winelands District Municipality' },
  { label: 'Cape Woolamai', value: 'Cape Woolamai' },
  { label: 'Capel', value: 'Capel' },
  { label: 'Capel', value: 'Capel' },
  { label: 'Capel Le Ferne', value: 'Capel Le Ferne' },
  { label: 'Capel Saint Mary', value: 'Capel Saint Mary' },
  { label: 'Capela', value: 'Capela' },
  { label: 'Capela', value: 'Capela' },
  { label: 'Capela A', value: 'Capela A' },
  { label: 'Capela De Santana', value: 'Capela De Santana' },
  { label: 'Capela Do Alto', value: 'Capela Do Alto' },
  { label: 'Capela Do Alto Alegre', value: 'Capela Do Alto Alegre' },
  { label: 'Capela Nova', value: 'Capela Nova' },
  { label: 'Capelinha', value: 'Capelinha' },
  { label: 'Capella', value: 'Capella' },
  { label: 'Capella', value: 'Capella' },
  { label: 'Capellades', value: 'Capellades' },
  { label: 'Capellanía', value: 'Capellanía' },
  { label: 'Capelle Aan Den Ijssel', value: 'Capelle Aan Den Ijssel' },
  { label: 'Capellen', value: 'Capellen' },
  { label: 'Capelle-West', value: 'Capelle-West' },
  { label: 'Capena', value: 'Capena' },
  { label: 'Capendu', value: 'Capendu' },
  { label: 'Căpeni', value: 'Căpeni' },
  { label: 'Capergnanica', value: 'Capergnanica' },
  { label: 'Capestang', value: 'Capestang' },
  { label: 'Capestrano', value: 'Capestrano' },
  { label: 'Căpeți', value: 'Căpeți' },
  { label: 'Capetinga', value: 'Capetinga' },
  { label: 'Capezzano Inferiore', value: 'Capezzano Inferiore' },
  { label: 'Capezzano-Cologna', value: 'Capezzano-Cologna' },
  { label: 'Capiago-Intimiano-Olmeda', value: 'Capiago-Intimiano-Olmeda' },
  { label: 'Capiatá', value: 'Capiatá' },
  { label: 'Capiíbary', value: 'Capiíbary' },
  { label: 'Capileira', value: 'Capileira' },
  { label: 'Capilla', value: 'Capilla' },
  { label: 'Capilla De Guadalupe', value: 'Capilla De Guadalupe' },
  { label: 'Capilla De Milpillas', value: 'Capilla De Milpillas' },
  { label: 'Capilla Del Monte', value: 'Capilla Del Monte' },
  { label: 'Capillas', value: 'Capillas' },
  { label: 'Capim', value: 'Capim' },
  { label: 'Capim Branco', value: 'Capim Branco' },
  { label: 'Capim Grosso', value: 'Capim Grosso' },
  { label: 'Capinghem', value: 'Capinghem' },
  { label: 'Capinópolis', value: 'Capinópolis' },
  { label: 'Capinota', value: 'Capinota' },
  { label: 'Capinzal', value: 'Capinzal' },
  { label: 'Capinzal Do Norte', value: 'Capinzal Do Norte' },
  { label: 'Capioví', value: 'Capioví' },
  { label: 'Capira', value: 'Capira' },
  { label: 'Capirro', value: 'Capirro' },
  { label: 'Capissayan Sur', value: 'Capissayan Sur' },
  { label: 'Capissayan Sur', value: 'Capissayan Sur' },
  { label: 'Capistrano', value: 'Capistrano' },
  { label: 'Capistrano', value: 'Capistrano' },
  { label: 'Capistrello', value: 'Capistrello' },
  { label: 'Capital Hill', value: 'Capital Hill' },
  { label: 'Capitale-Nationale', value: 'Capitale-Nationale' },
  { label: 'Capitan', value: 'Capitan' },
  { label: 'Capitán Bado', value: 'Capitán Bado' },
  { label: 'Capitán Bermúdez', value: 'Capitán Bermúdez' },
  { label: 'Capitán Felipe Castellanos Díaz (San Pedro)', value: 'Capitán Felipe Castellanos Díaz (San Pedro)' },
  { label: 'Capitan Loreto', value: 'Capitan Loreto' },
  { label: 'Capitán Miranda', value: 'Capitán Miranda' },
  { label: 'Capitán Pablo Lagerenza', value: 'Capitán Pablo Lagerenza' },
  { label: 'Capitan Ramon', value: 'Capitan Ramon' },
  { label: 'Capitán Solari', value: 'Capitán Solari' },
  { label: 'Capitanejo', value: 'Capitanejo' },
  { label: 'Capitanejo', value: 'Capitanejo' },
  { label: 'Capitão', value: 'Capitão' },
  { label: 'Capitão Andrade', value: 'Capitão Andrade' },
  { label: 'Capitão De Campos', value: 'Capitão De Campos' },
  { label: 'Capitão Enéas', value: 'Capitão Enéas' },
  { label: 'Capitão Gervásio Oliveira', value: 'Capitão Gervásio Oliveira' },
  { label: 'Capitão Leônidas Marques', value: 'Capitão Leônidas Marques' },
  { label: 'Capitão Poço', value: 'Capitão Poço' },
  { label: 'Capitello', value: 'Capitello' },
  { label: 'Capitignano', value: 'Capitignano' },
  { label: 'Capitignano', value: 'Capitignano' },
  { label: 'Capitol Heights', value: 'Capitol Heights' },
  { label: 'Capitola', value: 'Capitola' },
  { label: 'Capitólio', value: 'Capitólio' },
  { label: 'Capivari', value: 'Capivari' },
  { label: 'Capivari De Baixo', value: 'Capivari De Baixo' },
  { label: 'Capivari Do Sul', value: 'Capivari Do Sul' },
  { label: 'Capixaba', value: 'Capixaba' },
  { label: 'Capizzi', value: 'Capizzi' },
  { label: 'Căpleni', value: 'Căpleni' },
  { label: 'Čapljina', value: 'Čapljina' },
  { label: 'Capmany', value: 'Capmany' },
  { label: 'Capo Di Ponte', value: 'Capo Di Ponte' },
  { label: 'Capo Dorlando', value: 'Capo Dorlando' },
  { label: 'Capo Rizzuto', value: 'Capo Rizzuto' },
  { label: 'Capodarco', value: 'Capodarco' },
  { label: 'Capodimonte', value: 'Capodimonte' },
  { label: 'Capodrise', value: 'Capodrise' },
  { label: 'Capoeiras', value: 'Capoeiras' },
  { label: 'Capolat', value: 'Capolat' },
  { label: 'Capoliveri', value: 'Capoliveri' },
  { label: 'Capolona', value: 'Capolona' },
  { label: 'Capoluca', value: 'Capoluca' },
  { label: 'Caponago', value: 'Caponago' },
  { label: 'Capoocan', value: 'Capoocan' },
  { label: 'Capoocan', value: 'Capoocan' },
  { label: 'Caporal Alexa', value: 'Caporal Alexa' },
  { label: 'Caporciano', value: 'Caporciano' },
  { label: 'Caposele', value: 'Caposele' },
  { label: 'Capoterra', value: 'Capoterra' },
  { label: 'Capovalle', value: 'Capovalle' },
  { label: 'Cappadocia', value: 'Cappadocia' },
  { label: 'Cappella Cantone', value: 'Cappella Cantone' },
  { label: 'Cappella De Picenardi', value: 'Cappella De Picenardi' },
  { label: 'Cappella Maggiore', value: 'Cappella Maggiore' },
  { label: 'Cappelle Sul Tavo', value: 'Cappelle Sul Tavo' },
  { label: 'Cappelle-En-Pévèle', value: 'Cappelle-En-Pévèle' },
  { label: 'Cappelle-La-Grande', value: 'Cappelle-La-Grande' },
  { label: 'Cappelletta', value: 'Cappelletta' },
  { label: 'Cappeln', value: 'Cappeln' },
  { label: 'Cappone', value: 'Cappone' },
  { label: 'Capracotta', value: 'Capracotta' },
  { label: 'Capraia E Limite', value: 'Capraia E Limite' },
  { label: 'Capraia Isola', value: 'Capraia Isola' },
  { label: 'Capralba', value: 'Capralba' },
  { label: 'Capranica', value: 'Capranica' },
  { label: 'Capranica Prenestina', value: 'Capranica Prenestina' },
  { label: 'Caprara', value: 'Caprara' },
  { label: 'Caprarica Di Lecce', value: 'Caprarica Di Lecce' },
  { label: 'Caprarola', value: 'Caprarola' },
  { label: 'Caprauna', value: 'Caprauna' },
  { label: 'Căpreni', value: 'Căpreni' },
  { label: 'Capreol', value: 'Capreol' },
  { label: 'Caprese Michelangelo', value: 'Caprese Michelangelo' },
  { label: 'Caprezzo', value: 'Caprezzo' },
  { label: 'Capri', value: 'Capri' },
  { label: 'Capri Leone', value: 'Capri Leone' },
  { label: 'Capriana', value: 'Capriana' },
  { label: 'Capriano', value: 'Capriano' },
  { label: 'Capriano Del Colle', value: 'Capriano Del Colle' },
  { label: 'Capriata Dorba', value: 'Capriata Dorba' },
  { label: 'Capriate San Gervasio', value: 'Capriate San Gervasio' },
  { label: 'Capriati A Volturno', value: 'Capriati A Volturno' },
  { label: 'Capricorn District Municipality', value: 'Capricorn District Municipality' },
  { label: 'Caprie', value: 'Caprie' },
  { label: 'Capriglia', value: 'Capriglia' },
  { label: 'Capriglia Irpina', value: 'Capriglia Irpina' },
  { label: 'Capriglio', value: 'Capriglio' },
  { label: 'Caprino', value: 'Caprino' },
  { label: 'Caprino Veronese', value: 'Caprino Veronese' },
  { label: 'Capriolo', value: 'Capriolo' },
  { label: 'Căprioru', value: 'Căprioru' },
  { label: 'Capriva Del Friuli', value: 'Capriva Del Friuli' },
  { label: 'Capron', value: 'Capron' },
  { label: 'Cap-Santé', value: 'Cap-Santé' },
  { label: 'Captain Cook', value: 'Captain Cook' },
  { label: 'Captainganj', value: 'Captainganj' },
  { label: 'Captains Cove', value: 'Captains Cove' },
  { label: 'Captieux', value: 'Captieux' },
  { label: 'Capu Câmpului', value: 'Capu Câmpului' },
  { label: 'Capu Codrului', value: 'Capu Codrului' },
  { label: 'Capu Piscului', value: 'Capu Piscului' },
  { label: 'Capua', value: 'Capua' },
  { label: 'Capucnasan', value: 'Capucnasan' },
  { label: 'Capul', value: 'Capul' },
  { label: 'Capul', value: 'Capul' },
  { label: 'Capula', value: 'Capula' },
  { label: 'Capula', value: 'Capula' },
  { label: 'Capula', value: 'Capula' },
  { label: 'Capulaan', value: 'Capulaan' },
  { label: 'Capulaan', value: 'Capulaan' },
  { label: 'Capulálpam De Méndez', value: 'Capulálpam De Méndez' },
  { label: 'Capulhuac De Mirafuentes', value: 'Capulhuac De Mirafuentes' },
  { label: 'Capulín', value: 'Capulín' },
  { label: 'Capulín De Bustos', value: 'Capulín De Bustos' },
  { label: 'Capuluan', value: 'Capuluan' },
  { label: 'Capurro', value: 'Capurro' },
  { label: 'Capurso', value: 'Capurso' },
  { label: 'Căpușu De Câmpie', value: 'Căpușu De Câmpie' },
  { label: 'Căpuşu Mare', value: 'Căpuşu Mare' },
  { label: 'Caputira', value: 'Caputira' },
  { label: 'Capuy', value: 'Capuy' },
  { label: 'Capvern', value: 'Capvern' },
  { label: 'Caqueza', value: 'Caqueza' },
  { label: 'Caquixtle De Arriba', value: 'Caquixtle De Arriba' },
  { label: 'Caraá', value: 'Caraá' },
  { label: 'Carabalan', value: 'Carabalan' },
  { label: 'Caraballeda', value: 'Caraballeda' },
  { label: 'Carabaña', value: 'Carabaña' },
  { label: 'Carabanchel', value: 'Carabanchel' },
  { label: 'Carabantes', value: 'Carabantes' },
  { label: 'Caracal', value: 'Caracal' },
  { label: 'Caracal', value: 'Caracal' },
  { label: 'Caracaraí', value: 'Caracaraí' },
  { label: 'Caracas', value: 'Caracas' },
  { label: 'Caracena', value: 'Caracena' },
  { label: 'Caraclău', value: 'Caraclău' },
  { label: 'Caracol', value: 'Caracol' },
  { label: 'Caracol', value: 'Caracol' },
  { label: 'Caracol', value: 'Caracol' },
  { label: 'Caracolí', value: 'Caracolí' },
  { label: 'Carácuaro', value: 'Carácuaro' },
  { label: 'Caracuel De Calatrava', value: 'Caracuel De Calatrava' },
  { label: 'Caraffa Del Bianco', value: 'Caraffa Del Bianco' },
  { label: 'Caraffa Di Catanzaro', value: 'Caraffa Di Catanzaro' },
  { label: 'Caraga', value: 'Caraga' },
  { label: 'Caraga', value: 'Caraga' },
  { label: 'Caraga', value: 'Caraga' },
  { label: 'Caraglio', value: 'Caraglio' },
  { label: 'Caraguatatuba', value: 'Caraguatatuba' },
  { label: 'Caraguatay', value: 'Caraguatay' },
  { label: 'Caraguatay', value: 'Caraguatay' },
  { label: 'Carahue', value: 'Carahue' },
  { label: 'Caraí', value: 'Caraí' },
  { label: 'Caraíbas', value: 'Caraíbas' },
  { label: 'Caramagna Piemonte', value: 'Caramagna Piemonte' },
  { label: 'Caraman', value: 'Caraman' },
  { label: 'Caramanico Terme', value: 'Caramanico Terme' },
  { label: 'Caramanta', value: 'Caramanta' },
  { label: 'Caramay', value: 'Caramay' },
  { label: 'Carambeí', value: 'Carambeí' },
  { label: 'Caramoan', value: 'Caramoan' },
  { label: 'Caramoran', value: 'Caramoran' },
  { label: 'Caramutan', value: 'Caramutan' },
  { label: 'Caramutan', value: 'Caramutan' },
  { label: 'Caranaíba', value: 'Caranaíba' },
  { label: 'Caranan', value: 'Caranan' },
  { label: 'Caranavi', value: 'Caranavi' },
  { label: 'Cărand', value: 'Cărand' },
  { label: 'Carandaí', value: 'Carandaí' },
  { label: 'Carangola', value: 'Carangola' },
  { label: 'Caranguejeira', value: 'Caranguejeira' },
  { label: 'Carani', value: 'Carani' },
  { label: 'Carano', value: 'Carano' },
  { label: 'Carano', value: 'Carano' },
  { label: 'Caransebeş', value: 'Caransebeş' },
  { label: 'Carantec', value: 'Carantec' },
  { label: 'Carapán', value: 'Carapán' },
  { label: 'Carapebus', value: 'Carapebus' },
  { label: 'Carapeguá', value: 'Carapeguá' },
  { label: 'Carapelle', value: 'Carapelle' },
  { label: 'Carapelle Calvisio', value: 'Carapelle Calvisio' },
  { label: 'Carapicuíba', value: 'Carapicuíba' },
  { label: 'Carapinheira', value: 'Carapinheira' },
  { label: 'Carapito', value: 'Carapito' },
  { label: 'Carapur', value: 'Carapur' },
  { label: 'Carás', value: 'Carás' },
  { label: 'Carasco', value: 'Carasco' },
  { label: 'Cărășeu', value: 'Cărășeu' },
  { label: 'Carasi', value: 'Carasi' },
  { label: 'Carasi', value: 'Carasi' },
  { label: 'Caraşova', value: 'Caraşova' },
  { label: 'Carassai', value: 'Carassai' },
  { label: 'Carastelec', value: 'Carastelec' },
  { label: 'Carataunas', value: 'Carataunas' },
  { label: 'Carate Brianza', value: 'Carate Brianza' },
  { label: 'Carate Urio', value: 'Carate Urio' },
  { label: 'Caratinga', value: 'Caratinga' },
  { label: 'Carauari', value: 'Carauari' },
  { label: 'Caraúbas', value: 'Caraúbas' },
  { label: 'Caraúbas', value: 'Caraúbas' },
  { label: 'Caraúbas Do Piauí', value: 'Caraúbas Do Piauí' },
  { label: 'Caraula', value: 'Caraula' },
  { label: 'Caravaca', value: 'Caravaca' },
  { label: 'Caravaca De La Cruz', value: 'Caravaca De La Cruz' },
  { label: 'Caravaggio', value: 'Caravaggio' },
  { label: 'Caravate', value: 'Caravate' },
  { label: 'Caravelas', value: 'Caravelas' },
  { label: 'Caravia', value: 'Caravia' },
  { label: 'Caravino', value: 'Caravino' },
  { label: 'Caravonica', value: 'Caravonica' },
  { label: 'Caravonica', value: 'Caravonica' },
  { label: 'Caraway', value: 'Caraway' },
  { label: 'Carayaó', value: 'Carayaó' },
  { label: 'Caraycaray', value: 'Caraycaray' },
  { label: 'Caraycaray', value: 'Caraycaray' },
  { label: 'Caraycayon', value: 'Caraycayon' },
  { label: 'Carazinho', value: 'Carazinho' },
  { label: 'Carazo', value: 'Carazo' },
  { label: 'Carbajales', value: 'Carbajales' },
  { label: 'Carbajales De Alba', value: 'Carbajales De Alba' },
  { label: 'Carbajo', value: 'Carbajo' },
  { label: 'Carbajosa De La Sagrada', value: 'Carbajosa De La Sagrada' },
  { label: 'Carballeda De Avia', value: 'Carballeda De Avia' },
  { label: 'Carballeda De Valdeorras', value: 'Carballeda De Valdeorras' },
  { label: 'Carballedo', value: 'Carballedo' },
  { label: 'Carballiño O', value: 'Carballiño O' },
  { label: 'Carballo', value: 'Carballo' },
  { label: 'Carbellino', value: 'Carbellino' },
  { label: 'Carberry', value: 'Carberry' },
  { label: 'Carbo', value: 'Carbo' },
  { label: 'Carbognano', value: 'Carbognano' },
  { label: 'Carbon Cliff', value: 'Carbon Cliff' },
  { label: 'Carbon County', value: 'Carbon County' },
  { label: 'Carbon County', value: 'Carbon County' },
  { label: 'Carbon County', value: 'Carbon County' },
  { label: 'Carbon County', value: 'Carbon County' },
  { label: 'Carbon Hill', value: 'Carbon Hill' },
  { label: 'Carbonara Al Ticino', value: 'Carbonara Al Ticino' },
  { label: 'Carbonara Di Nola', value: 'Carbonara Di Nola' },
  { label: 'Carbonara Di Po', value: 'Carbonara Di Po' },
  { label: 'Carbonara Scrivia', value: 'Carbonara Scrivia' },
  { label: 'Carbonate', value: 'Carbonate' },
  { label: 'Carbon-Blanc', value: 'Carbon-Blanc' },
  { label: 'Carbondale', value: 'Carbondale' },
  { label: 'Carbondale', value: 'Carbondale' },
  { label: 'Carbondale', value: 'Carbondale' },
  { label: 'Carbondale', value: 'Carbondale' },
  { label: 'Carbone', value: 'Carbone' },
  { label: 'Carbonear', value: 'Carbonear' },
  { label: 'Carbonera', value: 'Carbonera' },
  { label: 'Carbonera', value: 'Carbonera' },
  { label: 'Carboneras', value: 'Carboneras' },
  { label: 'Carboneras', value: 'Carboneras' },
  { label: 'Carboneras', value: 'Carboneras' },
  { label: 'Carboneras', value: 'Carboneras' },
  { label: 'Carboneras', value: 'Carboneras' },
  { label: 'Carboneras De Guadazaón', value: 'Carboneras De Guadazaón' },
  { label: 'Carbonero El Mayor', value: 'Carbonero El Mayor' },
  { label: 'Carboneros', value: 'Carboneros' },
  { label: 'Carbonia', value: 'Carbonia' },
  { label: 'Carbonita', value: 'Carbonita' },
  { label: 'Carbonne', value: 'Carbonne' },
  { label: 'Carbonville', value: 'Carbonville' },
  { label: 'Carbrook', value: 'Carbrook' },
  { label: 'Cărbunari', value: 'Cărbunari' },
  { label: 'Cărbunari', value: 'Cărbunari' },
  { label: 'Cărbuneşti', value: 'Cărbuneşti' },
  { label: 'Carcaboso', value: 'Carcaboso' },
  { label: 'Carcabuey', value: 'Carcabuey' },
  { label: 'Carcaixent', value: 'Carcaixent' },
  { label: 'Carcaliu', value: 'Carcaliu' },
  { label: 'Carcans', value: 'Carcans' },
  { label: 'Carcar', value: 'Carcar' },
  { label: 'Cárcar', value: 'Cárcar' },
  { label: 'Carcarañá', value: 'Carcarañá' },
  { label: 'Carcare', value: 'Carcare' },
  { label: 'Carcasí', value: 'Carcasí' },
  { label: 'Carcassonne', value: 'Carcassonne' },
  { label: 'Carcastillo', value: 'Carcastillo' },
  { label: 'Carcavelos', value: 'Carcavelos' },
  { label: 'Carcavelos', value: 'Carcavelos' },
  { label: 'Cârcea', value: 'Cârcea' },
  { label: 'Carcedo De Bureba', value: 'Carcedo De Bureba' },
  { label: 'Carcedo De Burgos', value: 'Carcedo De Burgos' },
  { label: 'Carcelén', value: 'Carcelén' },
  { label: 'Càrcer', value: 'Càrcer' },
  { label: 'Carceri', value: 'Carceri' },
  { label: 'Carcès', value: 'Carcès' },
  { label: 'Cárcheles', value: 'Cárcheles' },
  { label: 'Carchitti', value: 'Carchitti' },
  { label: 'Carcoforo', value: 'Carcoforo' },
  { label: 'Carcroft', value: 'Carcroft' },
  { label: 'Çardak', value: 'Çardak' },
  { label: 'Cardal', value: 'Cardal' },
  { label: 'Cardano', value: 'Cardano' },
  { label: 'Cardano Al Campo', value: 'Cardano Al Campo' },
  { label: 'Cardè', value: 'Cardè' },
  { label: 'Cardeal Da Silva', value: 'Cardeal Da Silva' },
  { label: 'Cardedeu', value: 'Cardedeu' },
  { label: 'Cardedu', value: 'Cardedu' },
  { label: 'Cardeña', value: 'Cardeña' },
  { label: 'Cardeñadijo', value: 'Cardeñadijo' },
  { label: 'Cardeñajimeno', value: 'Cardeñajimeno' },
  { label: 'Cárdenas', value: 'Cárdenas' },
  { label: 'Cárdenas', value: 'Cárdenas' },
  { label: 'Cárdenas', value: 'Cárdenas' },
  { label: 'Cárdenas', value: 'Cárdenas' },
  { label: 'Cárdenas', value: 'Cárdenas' },
  { label: 'Cárdenas', value: 'Cárdenas' },
  { label: 'Cardenden', value: 'Cardenden' },
  { label: 'Cardenete', value: 'Cardenete' },
  { label: 'Cardeñosa', value: 'Cardeñosa' },
  { label: 'Cardeñosa De Volpejera', value: 'Cardeñosa De Volpejera' },
  { label: 'Cardeñuela Riopico', value: 'Cardeñuela Riopico' },
  { label: 'Cardeto', value: 'Cardeto' },
  { label: 'Cardiel De Los Montes', value: 'Cardiel De Los Montes' },
  { label: 'Cardiff', value: 'Cardiff' },
  { label: 'Cardiff', value: 'Cardiff' },
  { label: 'Cardiff Heights', value: 'Cardiff Heights' },
  { label: 'Cardiff South', value: 'Cardiff South' },
  { label: 'Cardigan', value: 'Cardigan' },
  { label: 'Cardinale', value: 'Cardinale' },
  { label: 'Cardington', value: 'Cardington' },
  { label: 'Cardington', value: 'Cardington' },
  { label: 'Cardinia', value: 'Cardinia' },
  { label: 'Cardito', value: 'Cardito' },
  { label: 'Cardona', value: 'Cardona' },
  { label: 'Cardona', value: 'Cardona' },
  { label: 'Cardona', value: 'Cardona' },
  { label: 'Cardona', value: 'Cardona' },
  { label: 'Cardona', value: 'Cardona' },
  { label: 'Cardonal', value: 'Cardonal' },
  { label: 'Cardosas', value: 'Cardosas' },
  { label: 'Cardoso', value: 'Cardoso' },
  { label: 'Cardoso De La Sierra El', value: 'Cardoso De La Sierra El' },
  { label: 'Cardoso Moreira', value: 'Cardoso Moreira' },
  { label: 'Cardross', value: 'Cardross' },
  { label: 'Cardston', value: 'Cardston' },
  { label: 'Cardup', value: 'Cardup' },
  { label: 'Cardwell', value: 'Cardwell' },
  { label: 'Careaçu', value: 'Careaçu' },
  { label: 'Carefree', value: 'Carefree' },
  { label: 'Careggine', value: 'Careggine' },
  { label: 'Carei', value: 'Carei' },
  { label: 'Careiro', value: 'Careiro' },
  { label: 'Careiro Da Várzea', value: 'Careiro Da Várzea' },
  { label: 'Carellie', value: 'Carellie' },
  { label: 'Carema', value: 'Carema' },
  { label: 'Carenas', value: 'Carenas' },
  { label: 'Carencro', value: 'Carencro' },
  { label: 'Carenno', value: 'Carenno' },
  { label: 'Carentan', value: 'Carentan' },
  { label: 'Carentino', value: 'Carentino' },
  { label: 'Carentoir', value: 'Carentoir' },
  { label: 'Carepa', value: 'Carepa' },
  { label: 'Careri', value: 'Careri' },
  { label: 'Caresana', value: 'Caresana' },
  { label: 'Caresanablot', value: 'Caresanablot' },
  { label: 'Carey', value: 'Carey' },
  { label: 'Carey Park', value: 'Carey Park' },
  { label: 'Carezzano Maggiore', value: 'Carezzano Maggiore' },
  { label: 'Carfin', value: 'Carfin' },
  { label: 'Carfizzi', value: 'Carfizzi' },
  { label: 'Cargados Carajos', value: 'Cargados Carajos' },
  { label: 'Cargeghe', value: 'Cargeghe' },
  { label: 'Cargèse', value: 'Cargèse' },
  { label: 'Carhaix-Plouguer', value: 'Carhaix-Plouguer' },
  { label: 'Carhuamayo', value: 'Carhuamayo' },
  { label: 'Carhuaz', value: 'Carhuaz' },
  { label: 'Cariacica', value: 'Cariacica' },
  { label: 'Carianos', value: 'Carianos' },
  { label: 'Cariati', value: 'Cariati' },
  { label: 'Caribou', value: 'Caribou' },
  { label: 'Caribou County', value: 'Caribou County' },
  { label: 'Carice', value: 'Carice' },
  { label: 'Carichí', value: 'Carichí' },
  { label: 'Caridad', value: 'Caridad' },
  { label: 'Caridad', value: 'Caridad' },
  { label: 'Caridad', value: 'Caridad' },
  { label: 'Caridad', value: 'Caridad' },
  { label: 'Caridade', value: 'Caridade' },
  { label: 'Caridade Do Piauí', value: 'Caridade Do Piauí' },
  { label: 'Carierre', value: 'Carierre' },
  { label: 'Carife', value: 'Carife' },
  { label: 'Carifi-Torello-Priscoli', value: 'Carifi-Torello-Priscoli' },
  { label: 'Carig', value: 'Carig' },
  { label: 'Carig', value: 'Carig' },
  { label: 'Carigara', value: 'Carigara' },
  { label: 'Carigara', value: 'Carigara' },
  { label: 'Carignan', value: 'Carignan' },
  { label: 'Carignan', value: 'Carignan' },
  { label: 'Carignan-De-Bordeaux', value: 'Carignan-De-Bordeaux' },
  { label: 'Carignano', value: 'Carignano' },
  { label: 'Carimate', value: 'Carimate' },
  { label: 'Carina Heights', value: 'Carina Heights' },
  { label: 'Carinaro', value: 'Carinaro' },
  { label: 'Carindale', value: 'Carindale' },
  { label: 'Carine', value: 'Carine' },
  { label: 'Cariñena', value: 'Cariñena' },
  { label: 'Caringbah', value: 'Caringbah' },
  { label: 'Caringbah South', value: 'Caringbah South' },
  { label: 'Caringin', value: 'Caringin' },
  { label: 'Carinhanha', value: 'Carinhanha' },
  { label: 'Carini', value: 'Carini' },
  { label: 'Cariño', value: 'Cariño' },
  { label: 'Carinola', value: 'Carinola' },
  { label: 'Caripito', value: 'Caripito' },
  { label: 'Carira', value: 'Carira' },
  { label: 'Cariré', value: 'Cariré' },
  { label: 'Cariri Do Tocantins', value: 'Cariri Do Tocantins' },
  { label: 'Caririaçu', value: 'Caririaçu' },
  { label: 'Carisbrook', value: 'Carisbrook' },
  { label: 'Carisbrook', value: 'Carisbrook' },
  { label: 'Carisio', value: 'Carisio' },
  { label: 'Carisolo', value: 'Carisolo' },
  { label: 'Caristay', value: 'Caristay' },
  { label: 'Cariús', value: 'Cariús' },
  { label: 'Cârja', value: 'Cârja' },
  { label: 'Cârjiţi', value: 'Cârjiţi' },
  { label: 'Cârjoaia', value: 'Cârjoaia' },
  { label: 'Carl Junction', value: 'Carl Junction' },
  { label: 'Carlantino', value: 'Carlantino' },
  { label: 'Carlazzo', value: 'Carlazzo' },
  { label: 'Carle Place', value: 'Carle Place' },
  { label: 'Carlentini', value: 'Carlentini' },
  { label: 'Carlepont', value: 'Carlepont' },
  { label: 'Carles', value: 'Carles' },
  { label: 'Carlet', value: 'Carlet' },
  { label: 'Carleton', value: 'Carleton' },
  { label: 'Carleton', value: 'Carleton' },
  { label: 'Carleton Place', value: 'Carleton Place' },
  { label: 'Carleton-Sur-Mer', value: 'Carleton-Sur-Mer' },
  { label: 'Carletonville', value: 'Carletonville' },
  { label: 'Cârlibaba', value: 'Cârlibaba' },
  { label: 'Cârligele', value: 'Cârligele' },
  { label: 'Cârligi', value: 'Cârligi' },
  { label: 'Carlin', value: 'Carlin' },
  { label: 'Carlinda', value: 'Carlinda' },
  { label: 'Carling', value: 'Carling' },
  { label: 'Carlingford', value: 'Carlingford' },
  { label: 'Carlingford', value: 'Carlingford' },
  { label: 'Carlino', value: 'Carlino' },
  { label: 'Carlinville', value: 'Carlinville' },
  { label: 'Carlisle', value: 'Carlisle' },
  { label: 'Carlisle', value: 'Carlisle' },
  { label: 'Carlisle', value: 'Carlisle' },
  { label: 'Carlisle', value: 'Carlisle' },
  { label: 'Carlisle', value: 'Carlisle' },
  { label: 'Carlisle', value: 'Carlisle' },
  { label: 'Carlisle', value: 'Carlisle' },
  { label: 'Carlisle', value: 'Carlisle' },
  { label: 'Carlisle County', value: 'Carlisle County' },
  { label: 'Carlisle-Rockledge', value: 'Carlisle-Rockledge' },
  { label: 'Carloforte', value: 'Carloforte' },
  { label: 'Cârlogani', value: 'Cârlogani' },
  { label: 'Carlopoli', value: 'Carlopoli' },
  { label: 'Carlópolis', value: 'Carlópolis' },
  { label: 'Carlos A. Carrillo', value: 'Carlos A. Carrillo' },
  { label: 'Carlos A. Madrazo', value: 'Carlos A. Madrazo' },
  { label: 'Carlos Barbosa', value: 'Carlos Barbosa' },
  { label: 'Carlos Chagas', value: 'Carlos Chagas' },
  { label: 'Carlos Fermin Fitzcarrald', value: 'Carlos Fermin Fitzcarrald' },
  { label: 'Carlos Gomes', value: 'Carlos Gomes' },
  { label: 'Carlos Reyles', value: 'Carlos Reyles' },
  { label: 'Carlota La', value: 'Carlota La' },
  { label: 'Carlow', value: 'Carlow' },
  { label: 'Carlow', value: 'Carlow' },
  { label: 'Carlsbad', value: 'Carlsbad' },
  { label: 'Carlsbad', value: 'Carlsbad' },
  { label: 'Carlsberg', value: 'Carlsberg' },
  { label: 'Carlstadt', value: 'Carlstadt' },
  { label: 'Carlton', value: 'Carlton' },
  { label: 'Carlton', value: 'Carlton' },
  { label: 'Carlton', value: 'Carlton' },
  { label: 'Carlton', value: 'Carlton' },
  { label: 'Carlton', value: 'Carlton' },
  { label: 'Carlton County', value: 'Carlton County' },
  { label: 'Carlton North', value: 'Carlton North' },
  { label: 'Carluke', value: 'Carluke' },
  { label: 'Carlyle', value: 'Carlyle' },
  { label: 'Carlyle', value: 'Carlyle' },
  { label: 'Carlyss', value: 'Carlyss' },
  { label: 'Carmagnola', value: 'Carmagnola' },
  { label: 'Carman', value: 'Carman' },
  { label: 'Carmarthen', value: 'Carmarthen' },
  { label: 'Carmarthenshire', value: 'Carmarthenshire' },
  { label: 'Carmaux', value: 'Carmaux' },
  { label: 'Carme', value: 'Carme' },
  { label: 'Carmel', value: 'Carmel' },
  { label: 'Carmel', value: 'Carmel' },
  { label: 'Carmel', value: 'Carmel' },
  { label: 'Carmel', value: 'Carmel' },
  { label: 'Carmel Hamlet', value: 'Carmel Hamlet' },
  { label: 'Carmel Valley Village', value: 'Carmel Valley Village' },
  { label: 'Carmel-By-The-Sea', value: 'Carmel-By-The-Sea' },
  { label: 'Carmelo', value: 'Carmelo' },
  { label: 'Carmelo', value: 'Carmelo' },
  { label: 'Carmelo', value: 'Carmelo' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen', value: 'Carmen' },
  { label: 'Carmen De Apicalá', value: 'Carmen De Apicalá' },
  { label: 'Carmen De Carupa', value: 'Carmen De Carupa' },
  { label: 'Carmen Del Darien', value: 'Carmen Del Darien' },
  { label: 'Carmen Del Paraná', value: 'Carmen Del Paraná' },
  { label: 'Carmen Grande', value: 'Carmen Grande' },
  { label: 'Carmen Serdán', value: 'Carmen Serdán' },
  { label: 'Carmen Yalchuch', value: 'Carmen Yalchuch' },
  { label: 'Carmen Zacatal', value: 'Carmen Zacatal' },
  { label: 'Carmena', value: 'Carmena' },
  { label: 'Cármenes', value: 'Cármenes' },
  { label: 'Carmésia', value: 'Carmésia' },
  { label: 'Carmi', value: 'Carmi' },
  { label: 'Carmiano', value: 'Carmiano' },
  { label: 'Carmichael', value: 'Carmichael' },
  { label: 'Carmignano', value: 'Carmignano' },
  { label: 'Carmignano Di Brenta', value: 'Carmignano Di Brenta' },
  { label: 'Carmo', value: 'Carmo' },
  { label: 'Carmo Da Cachoeira', value: 'Carmo Da Cachoeira' },
  { label: 'Carmo Da Mata', value: 'Carmo Da Mata' },
  { label: 'Carmo De Minas', value: 'Carmo De Minas' },
  { label: 'Carmo Do Cajuru', value: 'Carmo Do Cajuru' },
  { label: 'Carmo Do Paranaíba', value: 'Carmo Do Paranaíba' },
  { label: 'Carmo Do Rio Claro', value: 'Carmo Do Rio Claro' },
  { label: 'Carmo Do Rio Verde', value: 'Carmo Do Rio Verde' },
  { label: 'Carmões', value: 'Carmões' },
  { label: 'Carmolândia', value: 'Carmolândia' },
  { label: 'Carmona', value: 'Carmona' },
  { label: 'Carmona', value: 'Carmona' },
  { label: 'Carmonita', value: 'Carmonita' },
  { label: 'Carmópolis', value: 'Carmópolis' },
  { label: 'Carmópolis De Minas', value: 'Carmópolis De Minas' },
  { label: 'Carmunnock', value: 'Carmunnock' },
  { label: 'Cârna', value: 'Cârna' },
  { label: 'Carnac', value: 'Carnac' },
  { label: 'Carnago', value: 'Carnago' },
  { label: 'Carnaíba', value: 'Carnaíba' },
  { label: 'Carnamah', value: 'Carnamah' },
  { label: 'Carnarvon', value: 'Carnarvon' },
  { label: 'Carnarvon', value: 'Carnarvon' },
  { label: 'Carnate', value: 'Carnate' },
  { label: 'Carnation', value: 'Carnation' },
  { label: 'Carnaúba Dos Dantas', value: 'Carnaúba Dos Dantas' },
  { label: 'Carnaubais', value: 'Carnaubais' },
  { label: 'Carnaubal', value: 'Carnaubal' },
  { label: 'Carnaubeira Da Penha', value: 'Carnaubeira Da Penha' },
  { label: 'Carnaxide', value: 'Carnaxide' },
  { label: 'Carnaxide', value: 'Carnaxide' },
  { label: 'Carndonagh', value: 'Carndonagh' },
  { label: 'Carnegie', value: 'Carnegie' },
  { label: 'Carnegie', value: 'Carnegie' },
  { label: 'Carnegie', value: 'Carnegie' },
  { label: 'Carneirinho', value: 'Carneirinho' },
  { label: 'Carneiros', value: 'Carneiros' },
  { label: 'Carnerillo', value: 'Carnerillo' },
  { label: 'Carnes Hill', value: 'Carnes Hill' },
  { label: 'Carnesville', value: 'Carnesville' },
  { label: 'Carnew', value: 'Carnew' },
  { label: 'Carney', value: 'Carney' },
  { label: 'Carneys Point', value: 'Carneys Point' },
  { label: 'Carnforth', value: 'Carnforth' },
  { label: 'Carnicães', value: 'Carnicães' },
  { label: 'Cârniceni', value: 'Cârniceni' },
  { label: 'Carnide', value: 'Carnide' },
  { label: 'Carnikava', value: 'Carnikava' },
  { label: 'Carnisse', value: 'Carnisse' },
  { label: 'Carnlough', value: 'Carnlough' },
  { label: 'Carnmoney', value: 'Carnmoney' },
  { label: 'Carnot', value: 'Carnot' },
  { label: 'Carnota', value: 'Carnota' },
  { label: 'Carnota', value: 'Carnota' },
  { label: 'Carnot-Moon', value: 'Carnot-Moon' },
  { label: 'Carnoules', value: 'Carnoules' },
  { label: 'Carnoustie', value: 'Carnoustie' },
  { label: 'Carnoux-En-Provence', value: 'Carnoux-En-Provence' },
  { label: 'Carnuel', value: 'Carnuel' },
  { label: 'Carnwath', value: 'Carnwath' },
  { label: 'Caro', value: 'Caro' },
  { label: 'Caro', value: 'Caro' },
  { label: 'Carobbio', value: 'Carobbio' },
  { label: 'Carobbio Degli Angeli', value: 'Carobbio Degli Angeli' },
  { label: 'Caroebe', value: 'Caroebe' },
  { label: 'Carol City', value: 'Carol City' },
  { label: 'Carol Stream', value: 'Carol Stream' },
  { label: 'Carolei', value: 'Carolei' },
  { label: 'Carolina', value: 'Carolina' },
  { label: 'Carolina', value: 'Carolina' },
  { label: 'Carolina', value: 'Carolina' },
  { label: 'Carolina', value: 'Carolina' },
  { label: 'Carolina', value: 'Carolina' },
  { label: 'Carolina Beach', value: 'Carolina Beach' },
  { label: 'Carolina La', value: 'Carolina La' },
  { label: 'Carolina Shores', value: 'Carolina Shores' },
  { label: 'Caroline County', value: 'Caroline County' },
  { label: 'Caroline County', value: 'Caroline County' },
  { label: 'Caroline Springs', value: 'Caroline Springs' },
  { label: 'Caromatan', value: 'Caromatan' },
  { label: 'Caromb', value: 'Caromb' },
  { label: 'Carona', value: 'Carona' },
  { label: 'Carona', value: 'Carona' },
  { label: 'Caronia', value: 'Caronia' },
  { label: 'Caronno Pertusella', value: 'Caronno Pertusella' },
  { label: 'Caronno Varesino', value: 'Caronno Varesino' },
  { label: 'Caronoan West', value: 'Caronoan West' },
  { label: 'Caronoan West', value: 'Caronoan West' },
  { label: 'Carora', value: 'Carora' },
  { label: 'Carosino', value: 'Carosino' },
  { label: 'Carot', value: 'Carot' },
  { label: 'Carot', value: 'Carot' },
  { label: 'Carouge', value: 'Carouge' },
  { label: 'Carovigno', value: 'Carovigno' },
  { label: 'Carovilli', value: 'Carovilli' },
  { label: 'Carpaneto Piacentino', value: 'Carpaneto Piacentino' },
  { label: 'Carpanzano', value: 'Carpanzano' },
  { label: 'Carpasio', value: 'Carpasio' },
  { label: 'Carpegna', value: 'Carpegna' },
  { label: 'Carpen', value: 'Carpen' },
  { label: 'Carpenedolo', value: 'Carpenedolo' },
  { label: 'Carpeneto', value: 'Carpeneto' },
  { label: 'Carpentaria', value: 'Carpentaria' },
  { label: 'Carpenter', value: 'Carpenter' },
  { label: 'Carpenter Hill', value: 'Carpenter Hill' },
  { label: 'Carpentersville', value: 'Carpentersville' },
  { label: 'Carpentras', value: 'Carpentras' },
  { label: 'Carpesica', value: 'Carpesica' },
  { label: 'Carpi Centro', value: 'Carpi Centro' },
  { label: 'Carpiano', value: 'Carpiano' },
  { label: 'Carpignano Salentino', value: 'Carpignano Salentino' },
  { label: 'Carpignano Sesia', value: 'Carpignano Sesia' },
  { label: 'Carpin', value: 'Carpin' },
  { label: 'Carpina', value: 'Carpina' },
  { label: 'Cărpinet', value: 'Cărpinet' },
  { label: 'Carpineti', value: 'Carpineti' },
  { label: 'Carpineto Della Nora', value: 'Carpineto Della Nora' },
  { label: 'Carpineto Romano', value: 'Carpineto Romano' },
  { label: 'Carpineto Sinello', value: 'Carpineto Sinello' },
  { label: 'Cărpiniş', value: 'Cărpiniş' },
  { label: 'Cărpiniș', value: 'Cărpiniș' },
  { label: 'Cărpiniș', value: 'Cărpiniș' },
  { label: 'Carpino', value: 'Carpino' },
  { label: 'Carpinone', value: 'Carpinone' },
  { label: 'Carpinteria', value: 'Carpinteria' },
  { label: 'Carpinteros', value: 'Carpinteros' },
  { label: 'Carpio', value: 'Carpio' },
  { label: 'Carpio De Azaba', value: 'Carpio De Azaba' },
  { label: 'Carpio De Tajo El', value: 'Carpio De Tajo El' },
  { label: 'Carpio El', value: 'Carpio El' },
  { label: 'Carpiquet', value: 'Carpiquet' },
  { label: 'Carquefou', value: 'Carquefou' },
  { label: 'Carqueiranne', value: 'Carqueiranne' },
  { label: 'Carrabelle', value: 'Carrabelle' },
  { label: 'Carracedelo', value: 'Carracedelo' },
  { label: 'Carragozela', value: 'Carragozela' },
  { label: 'Carraia', value: 'Carraia' },
  { label: 'Carral', value: 'Carral' },
  { label: 'Carramar', value: 'Carramar' },
  { label: 'Carramar', value: 'Carramar' },
  { label: 'Carrancas', value: 'Carrancas' },
  { label: 'Carranco', value: 'Carranco' },
  { label: 'Carranglan', value: 'Carranglan' },
  { label: 'Carranglan', value: 'Carranglan' },
  { label: 'Carranque', value: 'Carranque' },
  { label: 'Carrapateira', value: 'Carrapateira' },
  { label: 'Carrapichana', value: 'Carrapichana' },
  { label: 'Carrara', value: 'Carrara' },
  { label: 'Carrara', value: 'Carrara' },
  { label: 'Carrara San Giorgio', value: 'Carrara San Giorgio' },
  { label: 'Carrara-Pontenuovo', value: 'Carrara-Pontenuovo' },
  { label: 'Carrascal', value: 'Carrascal' },
  { label: 'Carrascal', value: 'Carrascal' },
  { label: 'Carrascal De Barregas', value: 'Carrascal De Barregas' },
  { label: 'Carrascal Del Obispo', value: 'Carrascal Del Obispo' },
  { label: 'Carrascal Del Río', value: 'Carrascal Del Río' },
  { label: 'Carrascalejo', value: 'Carrascalejo' },
  { label: 'Carrascalejo El', value: 'Carrascalejo El' },
  { label: 'Carrasco', value: 'Carrasco' },
  { label: 'Carrasco Bonito', value: 'Carrasco Bonito' },
  { label: 'Carrasco Norte', value: 'Carrasco Norte' },
  { label: 'Carrascosa', value: 'Carrascosa' },
  { label: 'Carrascosa De Abajo', value: 'Carrascosa De Abajo' },
  { label: 'Carrascosa De Haro', value: 'Carrascosa De Haro' },
  { label: 'Carrascosa De La Sierra', value: 'Carrascosa De La Sierra' },
  { label: 'Carrasqueño', value: 'Carrasqueño' },
  { label: 'Carrathool', value: 'Carrathool' },
  { label: 'Carratraca', value: 'Carratraca' },
  { label: 'Carrazeda De Anciães', value: 'Carrazeda De Anciães' },
  { label: 'Carrazeda De Ansiães', value: 'Carrazeda De Ansiães' },
  { label: 'Carrboro', value: 'Carrboro' },
  { label: 'Carrè', value: 'Carrè' },
  { label: 'Carrefour', value: 'Carrefour' },
  { label: 'Carrega Ligure', value: 'Carrega Ligure' },
  { label: 'Carregado', value: 'Carregado' },
  { label: 'Carregado', value: 'Carregado' },
  { label: 'Carregal Do Sal', value: 'Carregal Do Sal' },
  { label: 'Carreño', value: 'Carreño' },
  { label: 'Carrera La', value: 'Carrera La' },
  { label: 'Carretas', value: 'Carretas' },
  { label: 'Carretas', value: 'Carretas' },
  { label: 'Carriage Club', value: 'Carriage Club' },
  { label: 'Carrias', value: 'Carrias' },
  { label: 'Carriches', value: 'Carriches' },
  { label: 'Carricitos', value: 'Carricitos' },
  { label: 'Carrickfergus', value: 'Carrickfergus' },
  { label: 'Carrickmacross', value: 'Carrickmacross' },
  { label: 'Carrick-On-Shannon', value: 'Carrick-On-Shannon' },
  { label: 'Carrick-On-Suir', value: 'Carrick-On-Suir' },
  { label: 'Carrícola', value: 'Carrícola' },
  { label: 'Carriedo', value: 'Carriedo' },
  { label: 'Carriedo', value: 'Carriedo' },
  { label: 'Carriedo', value: 'Carriedo' },
  { label: 'Carrier Mills', value: 'Carrier Mills' },
  { label: 'Carriere', value: 'Carriere' },
  { label: 'Carrières-Sous-Poissy', value: 'Carrières-Sous-Poissy' },
  { label: 'Carrières-Sur-Seine', value: 'Carrières-Sur-Seine' },
  { label: 'Carrigaline', value: 'Carrigaline' },
  { label: 'Carrigtwohill', value: 'Carrigtwohill' },
  { label: 'Carrillo', value: 'Carrillo' },
  { label: 'Carrillo', value: 'Carrillo' },
  { label: 'Carrillo Puerto', value: 'Carrillo Puerto' },
  { label: 'Carrillo Puerto', value: 'Carrillo Puerto' },
  { label: 'Carrillo Puerto', value: 'Carrillo Puerto' },
  { label: 'Carrillo Puerto', value: 'Carrillo Puerto' },
  { label: 'Carrillos', value: 'Carrillos' },
  { label: 'Carrilobo', value: 'Carrilobo' },
  { label: 'Carrington', value: 'Carrington' },
  { label: 'Carrington', value: 'Carrington' },
  { label: 'Carrión De Calatrava', value: 'Carrión De Calatrava' },
  { label: 'Carrión De Los Céspedes', value: 'Carrión De Los Céspedes' },
  { label: 'Carrión De Los Condes', value: 'Carrión De Los Condes' },
  { label: 'Carrizal', value: 'Carrizal' },
  { label: 'Carrizal', value: 'Carrizal' },
  { label: 'Carrizal', value: 'Carrizal' },
  { label: 'Carrizal', value: 'Carrizal' },
  { label: 'Carrizal Grande', value: 'Carrizal Grande' },
  { label: 'Carrizales', value: 'Carrizales' },
  { label: 'Carrizalillo', value: 'Carrizalillo' },
  { label: 'Carrizo', value: 'Carrizo' },
  { label: 'Carrizo De La Ribera', value: 'Carrizo De La Ribera' },
  { label: 'Carrizo Springs', value: 'Carrizo Springs' },
  { label: 'Carrizosa', value: 'Carrizosa' },
  { label: 'Carrizozo', value: 'Carrizozo' },
  { label: 'Carro', value: 'Carro' },
  { label: 'Carrocera', value: 'Carrocera' },
  { label: 'Carrodano', value: 'Carrodano' },
  { label: 'Carroll', value: 'Carroll' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll County', value: 'Carroll County' },
  { label: 'Carroll Valley', value: 'Carroll Valley' },
  { label: 'Carrollton', value: 'Carrollton' },
  { label: 'Carrollton', value: 'Carrollton' },
  { label: 'Carrollton', value: 'Carrollton' },
  { label: 'Carrollton', value: 'Carrollton' },
  { label: 'Carrollton', value: 'Carrollton' },
  { label: 'Carrollton', value: 'Carrollton' },
  { label: 'Carrollton', value: 'Carrollton' },
  { label: 'Carrollton', value: 'Carrollton' },
  { label: 'Carrollton', value: 'Carrollton' },
  { label: 'Carrollton', value: 'Carrollton' },
  { label: 'Carrollwood', value: 'Carrollwood' },
  { label: 'Carrollwood Village', value: 'Carrollwood Village' },
  { label: 'Carron', value: 'Carron' },
  { label: 'Carron Hall', value: 'Carron Hall' },
  { label: 'Carronshore', value: 'Carronshore' },
  { label: 'Carros', value: 'Carros' },
  { label: 'Carrosio', value: 'Carrosio' },
  { label: 'Carrozziere', value: 'Carrozziere' },
  { label: 'Carrù', value: 'Carrù' },
  { label: 'Carruba', value: 'Carruba' },
  { label: 'Carrubazza-Motta', value: 'Carrubazza-Motta' },
  { label: 'Carrum', value: 'Carrum' },
  { label: 'Carrum Downs', value: 'Carrum Downs' },
  { label: 'Carryduff', value: 'Carryduff' },
  { label: 'Carry-Le-Rouet', value: 'Carry-Le-Rouet' },
  { label: 'Cars', value: 'Cars' },
  { label: 'Carsac-Aillac', value: 'Carsac-Aillac' },
  { label: 'Çarşamba', value: 'Çarşamba' },
  { label: 'Carseldine', value: 'Carseldine' },
  { label: 'Carshalton', value: 'Carshalton' },
  { label: 'Çarşıbaşı', value: 'Çarşıbaşı' },
  { label: 'Carsoli', value: 'Carsoli' },
  { label: 'Carson', value: 'Carson' },
  { label: 'Carson', value: 'Carson' },
  { label: 'Carson', value: 'Carson' },
  { label: 'Carson City', value: 'Carson City' },
  { label: 'Carson City', value: 'Carson City' },
  { label: 'Carson County', value: 'Carson County' },
  { label: 'Carspach', value: 'Carspach' },
  { label: 'Carss Park', value: 'Carss Park' },
  { label: 'Carstairs', value: 'Carstairs' },
  { label: 'Cârţa', value: 'Cârţa' },
  { label: 'Cârţa', value: 'Cârţa' },
  { label: 'Cartagena', value: 'Cartagena' },
  { label: 'Cartagena', value: 'Cartagena' },
  { label: 'Cartagena', value: 'Cartagena' },
  { label: 'Cartagena [Fraccionamiento]', value: 'Cartagena [Fraccionamiento]' },
  { label: 'Cartagena De Indias', value: 'Cartagena De Indias' },
  { label: 'Cartagena Del Chairá', value: 'Cartagena Del Chairá' },
  { label: 'Cartago', value: 'Cartago' },
  { label: 'Cartago', value: 'Cartago' },
  { label: 'Cartajima', value: 'Cartajima' },
  { label: 'Cártama', value: 'Cártama' },
  { label: 'Cartaxo', value: 'Cartaxo' },
  { label: 'Cartaya', value: 'Cartaya' },
  { label: 'Cartelle', value: 'Cartelle' },
  { label: 'Carter County', value: 'Carter County' },
  { label: 'Carter County', value: 'Carter County' },
  { label: 'Carter County', value: 'Carter County' },
  { label: 'Carter County', value: 'Carter County' },
  { label: 'Carter County', value: 'Carter County' },
  { label: 'Carter Lake', value: 'Carter Lake' },
  { label: 'Carteret', value: 'Carteret' },
  { label: 'Carteret County', value: 'Carteret County' },
  { label: 'Cartersville', value: 'Cartersville' },
  { label: 'Carterton', value: 'Carterton' },
  { label: 'Carterville', value: 'Carterville' },
  { label: 'Carterville', value: 'Carterville' },
  { label: 'Cartes', value: 'Cartes' },
  { label: 'Carthage', value: 'Carthage' },
  { label: 'Carthage', value: 'Carthage' },
  { label: 'Carthage', value: 'Carthage' },
  { label: 'Carthage', value: 'Carthage' },
  { label: 'Carthage', value: 'Carthage' },
  { label: 'Carthage', value: 'Carthage' },
  { label: 'Carthage', value: 'Carthage' },
  { label: 'Carthage', value: 'Carthage' },
  { label: 'Cartí Sugdup', value: 'Cartí Sugdup' },
  { label: 'Cartiera-Stazione', value: 'Cartiera-Stazione' },
  { label: 'Cartigliano', value: 'Cartigliano' },
  { label: 'Cartignano', value: 'Cartignano' },
  { label: 'Cartignies', value: 'Cartignies' },
  { label: 'Cârţişoara', value: 'Cârţişoara' },
  { label: 'Cartoceto', value: 'Cartoceto' },
  { label: 'Cartojani', value: 'Cartojani' },
  { label: 'Cartosio', value: 'Cartosio' },
  { label: 'Cartura', value: 'Cartura' },
  { label: 'Cartwright', value: 'Cartwright' },
  { label: 'Caruaru', value: 'Caruaru' },
  { label: 'Carucedo', value: 'Carucedo' },
  { label: 'Carugate', value: 'Carugate' },
  { label: 'Carugo', value: 'Carugo' },
  { label: 'Carunchio', value: 'Carunchio' },
  { label: 'Carúpano', value: 'Carúpano' },
  { label: 'Caruray', value: 'Caruray' },
  { label: 'Caruru', value: 'Caruru' },
  { label: 'Carusucan', value: 'Carusucan' },
  { label: 'Carusucan', value: 'Carusucan' },
  { label: 'Carutapera', value: 'Carutapera' },
  { label: 'Caruthers', value: 'Caruthers' },
  { label: 'Caruthersville', value: 'Caruthersville' },
  { label: 'Carvalhal', value: 'Carvalhal' },
  { label: 'Carvalhópolis', value: 'Carvalhópolis' },
  { label: 'Carvalhos', value: 'Carvalhos' },
  { label: 'Carvalhosa', value: 'Carvalhosa' },
  { label: 'Carver', value: 'Carver' },
  { label: 'Carver', value: 'Carver' },
  { label: 'Carver County', value: 'Carver County' },
  { label: 'Carver Ranches', value: 'Carver Ranches' },
  { label: 'Carvico', value: 'Carvico' },
  { label: 'Carville', value: 'Carville' },
  { label: 'Carvin', value: 'Carvin' },
  { label: 'Carvoeira', value: 'Carvoeira' },
  { label: 'Carvoeira', value: 'Carvoeira' },
  { label: 'Carvoeiro', value: 'Carvoeiro' },
  { label: 'Carwoola', value: 'Carwoola' },
  { label: 'Cary', value: 'Cary' },
  { label: 'Cary', value: 'Cary' },
  { label: 'Caryville', value: 'Caryville' },
  { label: 'Carzago Riviera', value: 'Carzago Riviera' },
  { label: 'Carzano', value: 'Carzano' },
  { label: 'Cas En Bas', value: 'Cas En Bas' },
  { label: 'Casa Blanca', value: 'Casa Blanca' },
  { label: 'Casa Blanca', value: 'Casa Blanca' },
  { label: 'Casa Blanca', value: 'Casa Blanca' },
  { label: 'Casa Blanca', value: 'Casa Blanca' },
  { label: 'Casa Branca', value: 'Casa Branca' },
  { label: 'Casa Conejo', value: 'Casa Conejo' },
  { label: 'Casa De Cerros', value: 'Casa De Cerros' },
  { label: 'Casa De Oro-Mount Helix', value: 'Casa De Oro-Mount Helix' },
  { label: 'Casa De Teja', value: 'Casa De Teja' },
  { label: 'Casa De Uceda', value: 'Casa De Uceda' },
  { label: 'Casa Del Diavolo', value: 'Casa Del Diavolo' },
  { label: 'Casa Grande', value: 'Casa Grande' },
  { label: 'Casa Grande', value: 'Casa Grande' },
  { label: 'Casa Nova', value: 'Casa Nova' },
  { label: 'Casa Nueva', value: 'Casa Nueva' },
  { label: 'Casa Ponte', value: 'Casa Ponte' },
  { label: 'Casa Quemada', value: 'Casa Quemada' },
  { label: 'Casa Quemada', value: 'Casa Quemada' },
  { label: 'Casa Rosa', value: 'Casa Rosa' },
  { label: 'Casa Santa', value: 'Casa Santa' },
  { label: 'Casabermeja', value: 'Casabermeja' },
  { label: 'Casabianca', value: 'Casabianca' },
  { label: 'Casablanca', value: 'Casablanca' },
  { label: 'Casablanca', value: 'Casablanca' },
  { label: 'Casabó', value: 'Casabó' },
  { label: 'Casabona', value: 'Casabona' },
  { label: 'Casacalenda', value: 'Casacalenda' },
  { label: 'Casacanditella', value: 'Casacanditella' },
  { label: 'Casacorba', value: 'Casacorba' },
  { label: 'Casafranca', value: 'Casafranca' },
  { label: 'Casagiove', value: 'Casagiove' },
  { label: 'Casahuatlán', value: 'Casahuatlán' },
  { label: 'Casal Cermelli', value: 'Casal Cermelli' },
  { label: 'Casal De Cambra', value: 'Casal De Cambra' },
  { label: 'Casal De Cinza', value: 'Casal De Cinza' },
  { label: 'Casal Di Principe', value: 'Casal Di Principe' },
  { label: 'Casal Palocco', value: 'Casal Palocco' },
  { label: 'Casal Vasco', value: 'Casal Vasco' },
  { label: 'Casal Velino', value: 'Casal Velino' },
  { label: 'Casala-An', value: 'Casala-An' },
  { label: 'Casalanguida', value: 'Casalanguida' },
  { label: 'Casalarreina', value: 'Casalarreina' },
  { label: 'Casalattico', value: 'Casalattico' },
  { label: 'Casalazzara', value: 'Casalazzara' },
  { label: 'Casalbeltrame', value: 'Casalbeltrame' },
  { label: 'Casalbordino-Miracoli', value: 'Casalbordino-Miracoli' },
  { label: 'Casalbore', value: 'Casalbore' },
  { label: 'Casalborgone', value: 'Casalborgone' },
  { label: 'Casalbuono', value: 'Casalbuono' },
  { label: 'Casalbuttano', value: 'Casalbuttano' },
  { label: 'Casalciprano', value: 'Casalciprano' },
  { label: 'Casalduni', value: 'Casalduni' },
  { label: 'Casale', value: 'Casale' },
  { label: 'Casale', value: 'Casale' },
  { label: 'Casale Corte Cerro', value: 'Casale Corte Cerro' },
  { label: 'Casale Cremasco', value: 'Casale Cremasco' },
  { label: 'Casale Di Scodosia', value: 'Casale Di Scodosia' },
  { label: 'Casale Litta', value: 'Casale Litta' },
  { label: 'Casale Marittimo', value: 'Casale Marittimo' },
  { label: 'Casale Monferrato', value: 'Casale Monferrato' },
  { label: 'Casale Sul Sile', value: 'Casale Sul Sile' },
  { label: 'Casalecchio Di Reno', value: 'Casalecchio Di Reno' },
  { label: 'Casaleggio Boiro', value: 'Casaleggio Boiro' },
  { label: 'Casaleggio Novara', value: 'Casaleggio Novara' },
  { label: 'Casaleone', value: 'Casaleone' },
  { label: 'Casaletto Ceredano', value: 'Casaletto Ceredano' },
  { label: 'Casaletto Di Sopra', value: 'Casaletto Di Sopra' },
  { label: 'Casaletto Lodigiano', value: 'Casaletto Lodigiano' },
  { label: 'Casaletto Spartano', value: 'Casaletto Spartano' },
  { label: 'Casaletto Vaprio', value: 'Casaletto Vaprio' },
  { label: 'Casalfiumanese', value: 'Casalfiumanese' },
  { label: 'Casalgrande', value: 'Casalgrande' },
  { label: 'Casalgrasso', value: 'Casalgrasso' },
  { label: 'Casali', value: 'Casali' },
  { label: 'Casalincontrada', value: 'Casalincontrada' },
  { label: 'Casalini', value: 'Casalini' },
  { label: 'Casalino', value: 'Casalino' },
  { label: 'Casali-San Potito', value: 'Casali-San Potito' },
  { label: 'Casalmaggiore', value: 'Casalmaggiore' },
  { label: 'Casalmaiocco', value: 'Casalmaiocco' },
  { label: 'Casalmorano', value: 'Casalmorano' },
  { label: 'Casalmoro', value: 'Casalmoro' },
  { label: 'Casalnoceto', value: 'Casalnoceto' },
  { label: 'Casalnuovo Di Napoli', value: 'Casalnuovo Di Napoli' },
  { label: 'Casalnuovo Monterotaro', value: 'Casalnuovo Monterotaro' },
  { label: 'Casaloldo', value: 'Casaloldo' },
  { label: 'Casalpusterlengo', value: 'Casalpusterlengo' },
  { label: 'Casalromano', value: 'Casalromano' },
  { label: 'Casalserugo', value: 'Casalserugo' },
  { label: 'Casaluce', value: 'Casaluce' },
  { label: 'Casalvecchio Di Puglia', value: 'Casalvecchio Di Puglia' },
  { label: 'Casalvecchio Siculo', value: 'Casalvecchio Siculo' },
  { label: 'Casalvieri', value: 'Casalvieri' },
  { label: 'Casalvolone', value: 'Casalvolone' },
  { label: 'Casalzuigno', value: 'Casalzuigno' },
  { label: 'Casamarciano', value: 'Casamarciano' },
  { label: 'Casamassella', value: 'Casamassella' },
  { label: 'Casamassima', value: 'Casamassima' },
  { label: 'Casambalangan', value: 'Casambalangan' },
  { label: 'Casambalangan', value: 'Casambalangan' },
  { label: 'Casamicciola Terme', value: 'Casamicciola Terme' },
  { label: 'Casandrino', value: 'Casandrino' },
  { label: 'Casano-Dogana-Isola', value: 'Casano-Dogana-Isola' },
  { label: 'Casanova', value: 'Casanova' },
  { label: 'Casanova Elvo', value: 'Casanova Elvo' },
  { label: 'Casanova Lerrone', value: 'Casanova Lerrone' },
  { label: 'Casanova Lonati', value: 'Casanova Lonati' },
  { label: 'Casape', value: 'Casape' },
  { label: 'Casapesenna', value: 'Casapesenna' },
  { label: 'Casapinta', value: 'Casapinta' },
  { label: 'Casaprota', value: 'Casaprota' },
  { label: 'Casapulla', value: 'Casapulla' },
  { label: 'Casar De Cáceres', value: 'Casar De Cáceres' },
  { label: 'Casar De Escalona El', value: 'Casar De Escalona El' },
  { label: 'Casar De Palomero', value: 'Casar De Palomero' },
  { label: 'Casar El', value: 'Casar El' },
  { label: 'Casarabonela', value: 'Casarabonela' },
  { label: 'Casarano', value: 'Casarano' },
  { label: 'Casarea', value: 'Casarea' },
  { label: 'Casarejos', value: 'Casarejos' },
  { label: 'Casares', value: 'Casares' },
  { label: 'Casares De Las Hurdes', value: 'Casares De Las Hurdes' },
  { label: 'Casargo', value: 'Casargo' },
  { label: 'Casariche', value: 'Casariche' },
  { label: 'Casarile', value: 'Casarile' },
  { label: 'Casarrubios Del Monte', value: 'Casarrubios Del Monte' },
  { label: 'Casarrubuelos', value: 'Casarrubuelos' },
  { label: 'Casarsa Della Delizia', value: 'Casarsa Della Delizia' },
  { label: 'Casarza Ligure', value: 'Casarza Ligure' },
  { label: 'Casas', value: 'Casas' },
  { label: 'Casas Adobes', value: 'Casas Adobes' },
  { label: 'Casas Altas', value: 'Casas Altas' },
  { label: 'Casas Bajas', value: 'Casas Bajas' },
  { label: 'Casas De Benítez', value: 'Casas De Benítez' },
  { label: 'Casas De Don Antonio', value: 'Casas De Don Antonio' },
  { label: 'Casas De Don Gómez', value: 'Casas De Don Gómez' },
  { label: 'Casas De Don Pedro', value: 'Casas De Don Pedro' },
  { label: 'Casas De Fernando Alonso', value: 'Casas De Fernando Alonso' },
  { label: 'Casas De Garcimolina', value: 'Casas De Garcimolina' },
  { label: 'Casas De Guijarro', value: 'Casas De Guijarro' },
  { label: 'Casas De Haro', value: 'Casas De Haro' },
  { label: 'Casas De Juan Núñez', value: 'Casas De Juan Núñez' },
  { label: 'Casas De Lázaro', value: 'Casas De Lázaro' },
  { label: 'Casas De Los Pinos', value: 'Casas De Los Pinos' },
  { label: 'Casas De Millán', value: 'Casas De Millán' },
  { label: 'Casas De Miravete', value: 'Casas De Miravete' },
  { label: 'Casas De Reina', value: 'Casas De Reina' },
  { label: 'Casas De San Galindo', value: 'Casas De San Galindo' },
  { label: 'Casas De Ves', value: 'Casas De Ves' },
  { label: 'Casas Del Castañar', value: 'Casas Del Castañar' },
  { label: 'Casas Del Conde Las', value: 'Casas Del Conde Las' },
  { label: 'Casas Del Monte', value: 'Casas Del Monte' },
  { label: 'Casas Del Puerto', value: 'Casas Del Puerto' },
  { label: 'Casas Do Soeiro', value: 'Casas Do Soeiro' },
  { label: 'Casas Grandes', value: 'Casas Grandes' },
  { label: 'Casas Viejas', value: 'Casas Viejas' },
  { label: 'Casas Viejas', value: 'Casas Viejas' },
  { label: 'Casasbuenas', value: 'Casasbuenas' },
  { label: 'Casasco', value: 'Casasco' },
  { label: 'Casasco Intelvi', value: 'Casasco Intelvi' },
  { label: 'Casaseca De Campeán', value: 'Casaseca De Campeán' },
  { label: 'Casaseca De Las Chanas', value: 'Casaseca De Las Chanas' },
  { label: 'Casas-Ibáñez', value: 'Casas-Ibáñez' },
  { label: 'Casasimarro', value: 'Casasimarro' },
  { label: 'Casasola', value: 'Casasola' },
  { label: 'Casasola De Arión', value: 'Casasola De Arión' },
  { label: 'Casate', value: 'Casate' },
  { label: 'Casatejada', value: 'Casatejada' },
  { label: 'Casatenovo', value: 'Casatenovo' },
  { label: 'Casatisma', value: 'Casatisma' },
  { label: 'Casavalle', value: 'Casavalle' },
  { label: 'Casavatore', value: 'Casavatore' },
  { label: 'Casavecchia', value: 'Casavecchia' },
  { label: 'Casavieja', value: 'Casavieja' },
  { label: 'Casay', value: 'Casay' },
  { label: 'Casay', value: 'Casay' },
  { label: 'Casazza', value: 'Casazza' },
  { label: 'Casbas De Huesca', value: 'Casbas De Huesca' },
  { label: 'Casca', value: 'Casca' },
  { label: 'Cascade', value: 'Cascade' },
  { label: 'Cascade', value: 'Cascade' },
  { label: 'Cascade', value: 'Cascade' },
  { label: 'Cascade', value: 'Cascade' },
  { label: 'Cascade', value: 'Cascade' },
  { label: 'Cascade', value: 'Cascade' },
  { label: 'Cascade County', value: 'Cascade County' },
  { label: 'Cascade Locks', value: 'Cascade Locks' },
  { label: 'Cascade Valley', value: 'Cascade Valley' },
  { label: 'Cascade-Chipita Park', value: 'Cascade-Chipita Park' },
  { label: 'Cascais', value: 'Cascais' },
  { label: 'Cascais', value: 'Cascais' },
  { label: 'Cascajares De Bureba', value: 'Cascajares De Bureba' },
  { label: 'Cascajares De La Sierra', value: 'Cascajares De La Sierra' },
  { label: 'Cascalho Rico', value: 'Cascalho Rico' },
  { label: 'Cascano', value: 'Cascano' },
  { label: 'Cascante', value: 'Cascante' },
  { label: 'Cascante Del Río', value: 'Cascante Del Río' },
  { label: 'Cascas', value: 'Cascas' },
  { label: 'Cascavel', value: 'Cascavel' },
  { label: 'Cascavel', value: 'Cascavel' },
  { label: 'Cascavelle', value: 'Cascavelle' },
  { label: 'Cascia', value: 'Cascia' },
  { label: 'Casciago', value: 'Casciago' },
  { label: 'Casciana Terme', value: 'Casciana Terme' },
  { label: 'Cascina', value: 'Cascina' },
  { label: 'Cascina Elisa', value: 'Cascina Elisa' },
  { label: 'Cascinare', value: 'Cascinare' },
  { label: 'Cascine-La Croce', value: 'Cascine-La Croce' },
  { label: 'Cascinette Divrea', value: 'Cascinette Divrea' },
  { label: 'Căscioarele', value: 'Căscioarele' },
  { label: 'Căscioarele', value: 'Căscioarele' },
  { label: 'Casco', value: 'Casco' },
  { label: 'Case Campoli-Panetta', value: 'Case Campoli-Panetta' },
  { label: 'Case Noyale', value: 'Case Noyale' },
  { label: 'Case Nuove', value: 'Case Nuove' },
  { label: 'Caseara', value: 'Caseara' },
  { label: 'Cáseda', value: 'Cáseda' },
  { label: 'Casei', value: 'Casei' },
  { label: 'Caseiros', value: 'Caseiros' },
  { label: 'Căşeiu', value: 'Căşeiu' },
  { label: 'Casekow', value: 'Casekow' },
  { label: 'Caselette', value: 'Caselette' },
  { label: 'Casella', value: 'Casella' },
  { label: 'Caselle', value: 'Caselle' },
  { label: 'Caselle In Pittari', value: 'Caselle In Pittari' },
  { label: 'Caselle Landi', value: 'Caselle Landi' },
  { label: 'Caselle Lurani', value: 'Caselle Lurani' },
  { label: 'Caselle Torinese', value: 'Caselle Torinese' },
  { label: 'Caseo', value: 'Caseo' },
  { label: 'Caseres', value: 'Caseres' },
  { label: 'Caserío De Cortés', value: 'Caserío De Cortés' },
  { label: 'Caseros', value: 'Caseros' },
  { label: 'Caserta', value: 'Caserta' },
  { label: 'Casette Dete', value: 'Casette Dete' },
  { label: 'Casette Verdini', value: 'Casette Verdini' },
  { label: 'Casey', value: 'Casey' },
  { label: 'Casey', value: 'Casey' },
  { label: 'Casey', value: 'Casey' },
  { label: 'Casey County', value: 'Casey County' },
  { label: 'Caseyville', value: 'Caseyville' },
  { label: 'Cash', value: 'Cash' },
  { label: 'Cash Hill', value: 'Cash Hill' },
  { label: 'Cashel', value: 'Cashel' },
  { label: 'Cashmere', value: 'Cashmere' },
  { label: 'Cashmere', value: 'Cashmere' },
  { label: 'Cashton', value: 'Cashton' },
  { label: 'Casian', value: 'Casian' },
  { label: 'Casier', value: 'Casier' },
  { label: 'Casignana', value: 'Casignana' },
  { label: 'Casiguran', value: 'Casiguran' },
  { label: 'Casiguran', value: 'Casiguran' },
  { label: 'Casilda', value: 'Casilda' },
  { label: 'Casillas', value: 'Casillas' },
  { label: 'Casillas', value: 'Casillas' },
  { label: 'Casillas De Coria', value: 'Casillas De Coria' },
  { label: 'Casillas De Flores', value: 'Casillas De Flores' },
  { label: 'Casimcea', value: 'Casimcea' },
  { label: 'Casimiro Castillo', value: 'Casimiro Castillo' },
  { label: 'Casimiro De Abreu', value: 'Casimiro De Abreu' },
  { label: 'Caşin', value: 'Caşin' },
  { label: 'Casina', value: 'Casina' },
  { label: 'Casine', value: 'Casine' },
  { label: 'Casinhas', value: 'Casinhas' },
  { label: 'Casinina', value: 'Casinina' },
  { label: 'Casino', value: 'Casino' },
  { label: 'Casinos', value: 'Casinos' },
  { label: 'Casirate Dadda', value: 'Casirate Dadda' },
  { label: 'Casisang', value: 'Casisang' },
  { label: 'Casitas', value: 'Casitas' },
  { label: 'Čaška', value: 'Čaška' },
  { label: 'Casla', value: 'Casla' },
  { label: 'Caslano', value: 'Caslano' },
  { label: 'Čáslav', value: 'Čáslav' },
  { label: 'Caslino Derba', value: 'Caslino Derba' },
  { label: 'Casnate Con Bernate', value: 'Casnate Con Bernate' },
  { label: 'Casnigo', value: 'Casnigo' },
  { label: 'Caso', value: 'Caso' },
  { label: 'Cașoca', value: 'Cașoca' },
  { label: 'Casola', value: 'Casola' },
  { label: 'Casola Di Napoli', value: 'Casola Di Napoli' },
  { label: 'Casola In Lunigiana', value: 'Casola In Lunigiana' },
  { label: 'Casola Valsenio', value: 'Casola Valsenio' },
  { label: 'Casole Bruzio', value: 'Casole Bruzio' },
  { label: 'Casole Delsa', value: 'Casole Delsa' },
  { label: 'Casoli', value: 'Casoli' },
  { label: 'Casone', value: 'Casone' },
  { label: 'Casoni', value: 'Casoni' },
  { label: 'Casorate Primo', value: 'Casorate Primo' },
  { label: 'Casorate Sempione', value: 'Casorate Sempione' },
  { label: 'Casorezzo', value: 'Casorezzo' },
  { label: 'Casoria', value: 'Casoria' },
  { label: 'Casorzo', value: 'Casorzo' },
  { label: 'Caspe', value: 'Caspe' },
  { label: 'Casper', value: 'Casper' },
  { label: 'Casperia', value: 'Casperia' },
  { label: 'Caspoggio', value: 'Caspoggio' },
  { label: 'Caspueñas', value: 'Caspueñas' },
  { label: 'Cass City', value: 'Cass City' },
  { label: 'Cass County', value: 'Cass County' },
  { label: 'Cass County', value: 'Cass County' },
  { label: 'Cass County', value: 'Cass County' },
  { label: 'Cass County', value: 'Cass County' },
  { label: 'Cass County', value: 'Cass County' },
  { label: 'Cass County', value: 'Cass County' },
  { label: 'Cass County', value: 'Cass County' },
  { label: 'Cass County', value: 'Cass County' },
  { label: 'Cass County', value: 'Cass County' },
  { label: 'Cassà De La Selva', value: 'Cassà De La Selva' },
  { label: 'Cassacco', value: 'Cassacco' },
  { label: 'Cassagnes-Bégonhès', value: 'Cassagnes-Bégonhès' },
  { label: 'Cassago Brianza', value: 'Cassago Brianza' },
  { label: 'Cassanayan', value: 'Cassanayan' },
  { label: 'Cassano Allo Ionio', value: 'Cassano Allo Ionio' },
  { label: 'Cassano Dadda', value: 'Cassano Dadda' },
  { label: 'Cassano Delle Murge', value: 'Cassano Delle Murge' },
  { label: 'Cassano Irpino', value: 'Cassano Irpino' },
  { label: 'Cassano Magnago', value: 'Cassano Magnago' },
  { label: 'Cassano Spinola', value: 'Cassano Spinola' },
  { label: 'Cassano Valcuvia', value: 'Cassano Valcuvia' },
  { label: 'Cassaro', value: 'Cassaro' },
  { label: 'Cassel', value: 'Cassel' },
  { label: 'Casselberry', value: 'Casselberry' },
  { label: 'Casselman', value: 'Casselman' },
  { label: 'Casselton', value: 'Casselton' },
  { label: 'Casseneuil', value: 'Casseneuil' },
  { label: 'Casserengue', value: 'Casserengue' },
  { label: 'Casserres', value: 'Casserres' },
  { label: 'Cássia', value: 'Cássia' },
  { label: 'Cassia County', value: 'Cassia County' },
  { label: 'Cássia Dos Coqueiros', value: 'Cássia Dos Coqueiros' },
  { label: 'Cassia Park', value: 'Cassia Park' },
  { label: 'Cassibile', value: 'Cassibile' },
  { label: 'Cassiglio', value: 'Cassiglio' },
  { label: 'Cassilândia', value: 'Cassilândia' },
  { label: 'Cassina De Pecchi', value: 'Cassina De Pecchi' },
  { label: 'Cassina Rizzardi', value: 'Cassina Rizzardi' },
  { label: 'Cassina Valsassina', value: 'Cassina Valsassina' },
  { label: 'Cassinasco', value: 'Cassinasco' },
  { label: 'Cassine', value: 'Cassine' },
  { label: 'Cassinelle-Concentrico', value: 'Cassinelle-Concentrico' },
  { label: 'Cassinetta Di Lugagnano', value: 'Cassinetta Di Lugagnano' },
  { label: 'Cassino', value: 'Cassino' },
  { label: 'Cassino Dalberi', value: 'Cassino Dalberi' },
  { label: 'Cassis', value: 'Cassis' },
  { label: 'Cassola', value: 'Cassola' },
  { label: 'Cassolnovo', value: 'Cassolnovo' },
  { label: 'Casson', value: 'Casson' },
  { label: 'Cassopolis', value: 'Cassopolis' },
  { label: 'Cassowary Coast', value: 'Cassowary Coast' },
  { label: 'Cassville', value: 'Cassville' },
  { label: 'Cast', value: 'Cast' },
  { label: 'Castagnaro', value: 'Castagnaro' },
  { label: 'Castagneto Carducci', value: 'Castagneto Carducci' },
  { label: 'Castagneto Po', value: 'Castagneto Po' },
  { label: 'Castagniers', value: 'Castagniers' },
  { label: 'Castagnito', value: 'Castagnito' },
  { label: 'Castagnole', value: 'Castagnole' },
  { label: 'Castagnole Delle Lanze', value: 'Castagnole Delle Lanze' },
  { label: 'Castagnole Monferrato', value: 'Castagnole Monferrato' },
  { label: 'Castagnole Piemonte', value: 'Castagnole Piemonte' },
  { label: 'Castaic', value: 'Castaic' },
  { label: 'Castalla', value: 'Castalla' },
  { label: 'Castamay', value: 'Castamay' },
  { label: 'Castana', value: 'Castana' },
  { label: 'Castañar De Ibor', value: 'Castañar De Ibor' },
  { label: 'Castañares De Rioja', value: 'Castañares De Rioja' },
  { label: 'Castañas', value: 'Castañas' },
  { label: 'Castanea', value: 'Castanea' },
  { label: 'Castanea Delle Furie', value: 'Castanea Delle Furie' },
  { label: 'Castañeda', value: 'Castañeda' },
  { label: 'Castanet-Tolosan', value: 'Castanet-Tolosan' },
  { label: 'Castanhal', value: 'Castanhal' },
  { label: 'Castanheira', value: 'Castanheira' },
  { label: 'Castanheira', value: 'Castanheira' },
  { label: 'Castanheira De Pêra', value: 'Castanheira De Pêra' },
  { label: 'Castanheira Do Ribatejo', value: 'Castanheira Do Ribatejo' },
  { label: 'Castanheira Do Ribatejo', value: 'Castanheira Do Ribatejo' },
  { label: 'Castanheiras', value: 'Castanheiras' },
  { label: 'Castaño Del Robledo', value: 'Castaño Del Robledo' },
  { label: 'Castano Primo', value: 'Castano Primo' },
  { label: 'Castaños', value: 'Castaños' },
  { label: 'Castañuelas', value: 'Castañuelas' },
  { label: 'Cástaras', value: 'Cástaras' },
  { label: 'Căstău', value: 'Căstău' },
  { label: 'Casteggio', value: 'Casteggio' },
  { label: 'Castegnato', value: 'Castegnato' },
  { label: 'Casteição', value: 'Casteição' },
  { label: 'Castejón', value: 'Castejón' },
  { label: 'Castejón De Alarba', value: 'Castejón De Alarba' },
  { label: 'Castejón De Henares', value: 'Castejón De Henares' },
  { label: 'Castejón De Las Armas', value: 'Castejón De Las Armas' },
  { label: 'Castejón De Monegros', value: 'Castejón De Monegros' },
  { label: 'Castejón De Sos', value: 'Castejón De Sos' },
  { label: 'Castejón De Tornos', value: 'Castejón De Tornos' },
  { label: 'Castejón De Valdejasa', value: 'Castejón De Valdejasa' },
  { label: 'Castejón Del Puente', value: 'Castejón Del Puente' },
  { label: 'Castel Baronia', value: 'Castel Baronia' },
  { label: 'Castel Boglione', value: 'Castel Boglione' },
  { label: 'Castel Bolognese', value: 'Castel Bolognese' },
  { label: 'Castel Campagnano', value: 'Castel Campagnano' },
  { label: 'Castel Castagna', value: 'Castel Castagna' },
  { label: 'Castel Chiodato', value: 'Castel Chiodato' },
  { label: 'Castel Condino', value: 'Castel Condino' },
  { label: 'Castel Daiano', value: 'Castel Daiano' },
  { label: 'Castel Dario', value: 'Castel Dario' },
  { label: 'Castel De Cabra', value: 'Castel De Cabra' },
  { label: 'Castel Del Giudice', value: 'Castel Del Giudice' },
  { label: 'Castel Del Monte', value: 'Castel Del Monte' },
  { label: 'Castel Del Piano', value: 'Castel Del Piano' },
  { label: 'Castel Del Rio', value: 'Castel Del Rio' },
  { label: 'Castel Di Casio', value: 'Castel Di Casio' },
  { label: 'Castel Di Ieri', value: 'Castel Di Ieri' },
  { label: 'Castel Di Judica', value: 'Castel Di Judica' },
  { label: 'Castel Di Lama', value: 'Castel Di Lama' },
  { label: 'Castel Di Lucio', value: 'Castel Di Lucio' },
  { label: 'Castel Di Sangro', value: 'Castel Di Sangro' },
  { label: 'Castel Di Sasso', value: 'Castel Di Sasso' },
  { label: 'Castel Di Tora', value: 'Castel Di Tora' },
  { label: 'Castel Frentano', value: 'Castel Frentano' },
  { label: 'Castel Fusano', value: 'Castel Fusano' },
  { label: 'Castel Gabbiano', value: 'Castel Gabbiano' },
  { label: 'Castel Gandolfo', value: 'Castel Gandolfo' },
  { label: 'Castel Giorgio', value: 'Castel Giorgio' },
  { label: 'Castel Goffredo', value: 'Castel Goffredo' },
  { label: 'Castel Guelfo Di Bologna', value: 'Castel Guelfo Di Bologna' },
  { label: 'Castel Madama', value: 'Castel Madama' },
  { label: 'Castel Maggiore', value: 'Castel Maggiore' },
  { label: 'Castel Mella', value: 'Castel Mella' },
  { label: 'Castel Morrone', value: 'Castel Morrone' },
  { label: 'Castel Ritaldi', value: 'Castel Ritaldi' },
  { label: 'Castel Rocchero', value: 'Castel Rocchero' },
  { label: 'Castel Rozzone', value: 'Castel Rozzone' },
  { label: 'Castel San Giorgio', value: 'Castel San Giorgio' },
  { label: 'Castel San Giovanni', value: 'Castel San Giovanni' },
  { label: 'Castel San Lorenzo', value: 'Castel San Lorenzo' },
  { label: 'Castel San Niccolò', value: 'Castel San Niccolò' },
  { label: 'Castel San Pietro', value: 'Castel San Pietro' },
  { label: 'Castel San Pietro Romano', value: 'Castel San Pietro Romano' },
  { label: 'Castel San Pietro Terme', value: 'Castel San Pietro Terme' },
  { label: 'Castel San Vincenzo', value: 'Castel San Vincenzo' },
  { label: 'Castel Santangelo', value: 'Castel Santangelo' },
  { label: 'Castel Santelia', value: 'Castel Santelia' },
  { label: 'Castel Viscardo', value: 'Castel Viscardo' },
  { label: 'Castel Vittorio', value: 'Castel Vittorio' },
  { label: 'Castel Volturno', value: 'Castel Volturno' },
  { label: 'Castelândia', value: 'Castelândia' },
  { label: 'Castelbaldo', value: 'Castelbaldo' },
  { label: 'Castelbelforte', value: 'Castelbelforte' },
  { label: 'Castelbellino', value: 'Castelbellino' },
  { label: 'Castelbello', value: 'Castelbello' },
  { label: 'Castelbello-Ciardes - Kastelbell-Tschars', value: 'Castelbello-Ciardes - Kastelbell-Tschars' },
  { label: 'Castelbianco', value: 'Castelbianco' },
  { label: 'Castelbottaccio', value: 'Castelbottaccio' },
  { label: 'Castelbuono', value: 'Castelbuono' },
  { label: 'Castelceriolo', value: 'Castelceriolo' },
  { label: 'Castelcivita', value: 'Castelcivita' },
  { label: 'Castelcovati', value: 'Castelcovati' },
  { label: 'Castelcucco', value: 'Castelcucco' },
  { label: 'Castelculier', value: 'Castelculier' },
  { label: 'Casteldaccia', value: 'Casteldaccia' },
  { label: 'Casteldelci', value: 'Casteldelci' },
  { label: 'Casteldelfino', value: 'Casteldelfino' },
  { label: 'Casteldidone', value: 'Casteldidone' },
  { label: 'Casteleiro', value: 'Casteleiro' },
  { label: 'Castelfidardo', value: 'Castelfidardo' },
  { label: 'Castelfiorentino', value: 'Castelfiorentino' },
  { label: 'Castelflorite', value: 'Castelflorite' },
  { label: 'Castelfondo', value: 'Castelfondo' },
  { label: 'Castelforte', value: 'Castelforte' },
  { label: 'Castelfranci', value: 'Castelfranci' },
  { label: 'Castelfranco Di Sopra', value: 'Castelfranco Di Sopra' },
  { label: 'Castelfranco Di Sotto', value: 'Castelfranco Di Sotto' },
  { label: 'Castelfranco Emilia', value: 'Castelfranco Emilia' },
  { label: 'Castelfranco In Miscano', value: 'Castelfranco In Miscano' },
  { label: 'Castelfranco Veneto', value: 'Castelfranco Veneto' },
  { label: 'Castelginest', value: 'Castelginest' },
  { label: 'Castelgomberto', value: 'Castelgomberto' },
  { label: 'Castelgrande', value: 'Castelgrande' },
  { label: 'Castelguglielmo', value: 'Castelguglielmo' },
  { label: 'Castelguidone', value: 'Castelguidone' },
  { label: 'Casteljaloux', value: 'Casteljaloux' },
  { label: 'Castell De Cabres', value: 'Castell De Cabres' },
  { label: 'Castell De Castells', value: 'Castell De Castells' },
  { label: 'Castell De Guadalest El', value: 'Castell De Guadalest El' },
  { label: 'Castell De Lareny', value: 'Castell De Lareny' },
  { label: 'Castell De Mur', value: 'Castell De Mur' },
  { label: 'Castell Es', value: 'Castell Es' },
  { label: 'Castellafiume', value: 'Castellafiume' },
  { label: 'Castellalfero', value: 'Castellalfero' },
  { label: 'Castellalto', value: 'Castellalto' },
  { label: 'Castellammare Del Golfo', value: 'Castellammare Del Golfo' },
  { label: 'Castellammare Di Stabia', value: 'Castellammare Di Stabia' },
  { label: 'Castellamonte', value: 'Castellamonte' },
  { label: 'Castellana', value: 'Castellana' },
  { label: 'Castellana Sicula', value: 'Castellana Sicula' },
  { label: 'Castellane', value: 'Castellane' },
  { label: 'Castellaneta', value: 'Castellaneta' },
  { label: 'Castellania', value: 'Castellania' },
  { label: 'Castellanos De Castro', value: 'Castellanos De Castro' },
  { label: 'Castellanos De Moriscos', value: 'Castellanos De Moriscos' },
  { label: 'Castellanos De Villiquera', value: 'Castellanos De Villiquera' },
  { label: 'Castellanos De Zapardiel', value: 'Castellanos De Zapardiel' },
  { label: 'Castellanza', value: 'Castellanza' },
  { label: 'Castellar', value: 'Castellar' },
  { label: 'Castellar', value: 'Castellar' },
  { label: 'Castellar De La Frontera', value: 'Castellar De La Frontera' },
  { label: 'Castellar De La Muela', value: 'Castellar De La Muela' },
  { label: 'Castellar De La Ribera', value: 'Castellar De La Ribera' },
  { label: 'Castellar De Nhug', value: 'Castellar De Nhug' },
  { label: 'Castellar De Santiago', value: 'Castellar De Santiago' },
  { label: 'Castellar Del Riu', value: 'Castellar Del Riu' },
  { label: 'Castellar Del Vallès', value: 'Castellar Del Vallès' },
  { label: 'Castellar El', value: 'Castellar El' },
  { label: 'Castellar Guidobono', value: 'Castellar Guidobono' },
  { label: 'Castellarano', value: 'Castellarano' },
  { label: 'Castellaro', value: 'Castellaro' },
  { label: 'Castellarquato', value: 'Castellarquato' },
  { label: 'Castellazzara', value: 'Castellazzara' },
  { label: 'Castellazzo Bormida', value: 'Castellazzo Bormida' },
  { label: 'Castellazzo Novarese', value: 'Castellazzo Novarese' },
  { label: 'Castellbell I El Vilar', value: 'Castellbell I El Vilar' },
  { label: 'Castellbisbal', value: 'Castellbisbal' },
  { label: 'Castellcir', value: 'Castellcir' },
  { label: 'Castelldans', value: 'Castelldans' },
  { label: 'Castelldefels', value: 'Castelldefels' },
  { label: 'Castelleone', value: 'Castelleone' },
  { label: 'Castelleone Di Suasa', value: 'Castelleone Di Suasa' },
  { label: 'Castellero', value: 'Castellero' },
  { label: 'Castellet I La Gornal', value: 'Castellet I La Gornal' },
  { label: 'Castelletto', value: 'Castelletto' },
  { label: 'Castelletto', value: 'Castelletto' },
  { label: 'Castelletto Cervo', value: 'Castelletto Cervo' },
  { label: 'Castelletto Derro', value: 'Castelletto Derro' },
  { label: 'Castelletto Di Branduzzo', value: 'Castelletto Di Branduzzo' },
  { label: 'Castelletto Dorba', value: 'Castelletto Dorba' },
  { label: 'Castelletto Merli', value: 'Castelletto Merli' },
  { label: 'Castelletto Molina', value: 'Castelletto Molina' },
  { label: 'Castelletto Monferrato', value: 'Castelletto Monferrato' },
  { label: 'Castelletto Po', value: 'Castelletto Po' },
  { label: 'Castelletto Sopra Ticino', value: 'Castelletto Sopra Ticino' },
  { label: 'Castelletto Stura', value: 'Castelletto Stura' },
  { label: 'Castelletto Uzzone', value: 'Castelletto Uzzone' },
  { label: 'Castellfollit De La Roca', value: 'Castellfollit De La Roca' },
  { label: 'Castellfollit De Riubregós', value: 'Castellfollit De Riubregós' },
  { label: 'Castellfollit Del Boix', value: 'Castellfollit Del Boix' },
  { label: 'Castellfort', value: 'Castellfort' },
  { label: 'Castellgalí', value: 'Castellgalí' },
  { label: 'Castelli', value: 'Castelli' },
  { label: 'Castelli', value: 'Castelli' },
  { label: 'Castelli Calepio', value: 'Castelli Calepio' },
  { label: 'Castellina In Chianti', value: 'Castellina In Chianti' },
  { label: 'Castellina Marittima', value: 'Castellina Marittima' },
  { label: 'Castellina Scalo', value: 'Castellina Scalo' },
  { label: 'Castellinaldo', value: 'Castellinaldo' },
  { label: 'Castellino Del Biferno', value: 'Castellino Del Biferno' },
  { label: 'Castellino Tanaro', value: 'Castellino Tanaro' },
  { label: 'Castelliri', value: 'Castelliri' },
  { label: 'Castellnou De Bages', value: 'Castellnou De Bages' },
  { label: 'Castellnou De Seana', value: 'Castellnou De Seana' },
  { label: 'Castellnovo', value: 'Castellnovo' },
  { label: 'Castello', value: 'Castello' },
  { label: 'Castello Cabiaglio', value: 'Castello Cabiaglio' },
  { label: 'Castello Dagogna', value: 'Castello Dagogna' },
  { label: 'Castello Dargile', value: 'Castello Dargile' },
  { label: 'Castelló De Farfanya', value: 'Castelló De Farfanya' },
  { label: 'Castelló De La Plana', value: 'Castelló De La Plana' },
  { label: 'Castelló De Rugat', value: 'Castelló De Rugat' },
  { label: 'Castello Del Matese', value: 'Castello Del Matese' },
  { label: 'Castello Dellacqua', value: 'Castello Dellacqua' },
  { label: 'Castelló Dempúries', value: 'Castelló Dempúries' },
  { label: 'Castello Di Annone', value: 'Castello Di Annone' },
  { label: 'Castello Di Brianza', value: 'Castello Di Brianza' },
  { label: 'Castello Di Cisterna', value: 'Castello Di Cisterna' },
  { label: 'Castello Di Godego', value: 'Castello Di Godego' },
  { label: 'Castello Molina Di Fiemme', value: 'Castello Molina Di Fiemme' },
  { label: 'Castello Tesino', value: 'Castello Tesino' },
  { label: 'Castellolí', value: 'Castellolí' },
  { label: 'Castellón De La Plana/Castelló De La Plana', value: 'Castellón De La Plana/Castelló De La Plana' },
  { label: 'Castellonet De La Conquesta', value: 'Castellonet De La Conquesta' },
  { label: 'Castellote', value: 'Castellote' },
  { label: 'Castell-Platja Daro', value: 'Castell-Platja Daro' },
  { label: 'Castellserà', value: 'Castellserà' },
  { label: 'Castellterçol', value: 'Castellterçol' },
  { label: 'Castellucchio', value: 'Castellucchio' },
  { label: 'Castelluccio Dei Sauri', value: 'Castelluccio Dei Sauri' },
  { label: 'Castelluccio Inferiore', value: 'Castelluccio Inferiore' },
  { label: 'Castelluccio Superiore', value: 'Castelluccio Superiore' },
  { label: 'Castelluccio Valmaggiore', value: 'Castelluccio Valmaggiore' },
  { label: 'Castellumberto', value: 'Castellumberto' },
  { label: 'Castelluzzo', value: 'Castelluzzo' },
  { label: 'Castellvell Del Camp', value: 'Castellvell Del Camp' },
  { label: 'Castellví De La Marca', value: 'Castellví De La Marca' },
  { label: 'Castellví De Rosanes', value: 'Castellví De Rosanes' },
  { label: 'Castelmarte', value: 'Castelmarte' },
  { label: 'Castelmassa', value: 'Castelmassa' },
  { label: 'Castelmassimo', value: 'Castelmassimo' },
  { label: 'Castelmauro', value: 'Castelmauro' },
  { label: 'Castelmaurou', value: 'Castelmaurou' },
  { label: 'Castelmezzano', value: 'Castelmezzano' },
  { label: 'Castelminio', value: 'Castelminio' },
  { label: 'Castelmola', value: 'Castelmola' },
  { label: 'Castelmoron-Sur-Lot', value: 'Castelmoron-Sur-Lot' },
  { label: 'Castelnaudary', value: 'Castelnaudary' },
  { label: 'Castelnau-De-Guers', value: 'Castelnau-De-Guers' },
  { label: 'Castelnau-De-Lévis', value: 'Castelnau-De-Lévis' },
  { label: 'Castelnau-De-Médoc', value: 'Castelnau-De-Médoc' },
  { label: 'Castelnau-Destrétefonds', value: 'Castelnau-Destrétefonds' },
  { label: 'Castelnau-Le-Lez', value: 'Castelnau-Le-Lez' },
  { label: 'Castelnau-Montratier', value: 'Castelnau-Montratier' },
  { label: 'Castelnou', value: 'Castelnou' },
  { label: 'Castelnovetto', value: 'Castelnovetto' },
  { label: 'Castelnovo', value: 'Castelnovo' },
  { label: 'Castelnovo Bariano', value: 'Castelnovo Bariano' },
  { label: 'Castelnovo Di Sotto', value: 'Castelnovo Di Sotto' },
  { label: 'Castelnovo Nemonti', value: 'Castelnovo Nemonti' },
  { label: 'Castelnuovo', value: 'Castelnuovo' },
  { label: 'Castelnuovo Belbo', value: 'Castelnuovo Belbo' },
  { label: 'Castelnuovo Berardenga', value: 'Castelnuovo Berardenga' },
  { label: 'Castelnuovo Bocca Dadda', value: 'Castelnuovo Bocca Dadda' },
  { label: 'Castelnuovo Bormida', value: 'Castelnuovo Bormida' },
  { label: 'Castelnuovo Bozzente', value: 'Castelnuovo Bozzente' },
  { label: 'Castelnuovo Calcea', value: 'Castelnuovo Calcea' },
  { label: 'Castelnuovo Cilento', value: 'Castelnuovo Cilento' },
  { label: 'Castelnuovo Dei Sabbioni', value: 'Castelnuovo Dei Sabbioni' },
  { label: 'Castelnuovo Del Garda', value: 'Castelnuovo Del Garda' },
  { label: 'Castelnuovo Della Daunia', value: 'Castelnuovo Della Daunia' },
  { label: 'Castelnuovo Di Ceva', value: 'Castelnuovo Di Ceva' },
  { label: 'Castelnuovo Di Conza', value: 'Castelnuovo Di Conza' },
  { label: 'Castelnuovo Di Farfa', value: 'Castelnuovo Di Farfa' },
  { label: 'Castelnuovo Di Garfagnana', value: 'Castelnuovo Di Garfagnana' },
  { label: 'Castelnuovo Di Porto', value: 'Castelnuovo Di Porto' },
  { label: 'Castelnuovo Di Val Di Cecina', value: 'Castelnuovo Di Val Di Cecina' },
  { label: 'Castelnuovo Don Bosco', value: 'Castelnuovo Don Bosco' },
  { label: 'Castelnuovo Magra', value: 'Castelnuovo Magra' },
  { label: 'Castelnuovo Nigra', value: 'Castelnuovo Nigra' },
  { label: 'Castelnuovo Parano', value: 'Castelnuovo Parano' },
  { label: 'Castelnuovo Rangone', value: 'Castelnuovo Rangone' },
  { label: 'Castelnuovo Scrivia', value: 'Castelnuovo Scrivia' },
  { label: 'Castelnuovo Vomano', value: 'Castelnuovo Vomano' },
  { label: 'Castelo', value: 'Castelo' },
  { label: 'Castelo (Lisbon)', value: 'Castelo (Lisbon)' },
  { label: 'Castelo Bom', value: 'Castelo Bom' },
  { label: 'Castelo Branco', value: 'Castelo Branco' },
  { label: 'Castelo Branco', value: 'Castelo Branco' },
  { label: 'Castelo De Paiva', value: 'Castelo De Paiva' },
  { label: 'Castelo De Vide', value: 'Castelo De Vide' },
  { label: 'Castelo Do Piauí', value: 'Castelo Do Piauí' },
  { label: 'Castelo Melhor', value: 'Castelo Melhor' },
  { label: 'Castelo Rodrigo', value: 'Castelo Rodrigo' },
  { label: 'Castelões De Cepeda', value: 'Castelões De Cepeda' },
  { label: 'Castelpagano', value: 'Castelpagano' },
  { label: 'Castelpetroso', value: 'Castelpetroso' },
  { label: 'Castelpizzuto', value: 'Castelpizzuto' },
  { label: 'Castelplanio', value: 'Castelplanio' },
  { label: 'Castelpoto', value: 'Castelpoto' },
  { label: 'Castelraimondo', value: 'Castelraimondo' },
  { label: 'Castelrotto', value: 'Castelrotto' },
  { label: 'Castelsantangelo Sul Nera', value: 'Castelsantangelo Sul Nera' },
  { label: 'Castelsaraceno', value: 'Castelsaraceno' },
  { label: 'Castelsardo', value: 'Castelsardo' },
  { label: 'Castelsarrasin', value: 'Castelsarrasin' },
  { label: 'Castelseprio', value: 'Castelseprio' },
  { label: 'Castelserás', value: 'Castelserás' },
  { label: 'Castelsilano', value: 'Castelsilano' },
  { label: 'Castelspina', value: 'Castelspina' },
  { label: 'Casteltermini', value: 'Casteltermini' },
  { label: 'Casteltodino', value: 'Casteltodino' },
  { label: 'Castelu', value: 'Castelu' },
  { label: 'Castelveccana', value: 'Castelveccana' },
  { label: 'Castelvecchio Calvisio', value: 'Castelvecchio Calvisio' },
  { label: 'Castelvecchio Di Rocca Barbena', value: 'Castelvecchio Di Rocca Barbena' },
  { label: 'Castelvecchio Subequo', value: 'Castelvecchio Subequo' },
  { label: 'Castelvenere', value: 'Castelvenere' },
  { label: 'Castelverde', value: 'Castelverde' },
  { label: 'Castelverrino', value: 'Castelverrino' },
  { label: 'Castelvetere In Val Fortore', value: 'Castelvetere In Val Fortore' },
  { label: 'Castelvetere Sul Calore', value: 'Castelvetere Sul Calore' },
  { label: 'Castelvetrano', value: 'Castelvetrano' },
  { label: 'Castelvetro Di Modena', value: 'Castelvetro Di Modena' },
  { label: 'Castelvetro Piacentino', value: 'Castelvetro Piacentino' },
  { label: 'Castelvisconti', value: 'Castelvisconti' },
  { label: 'Castenaso', value: 'Castenaso' },
  { label: 'Castenedolo', value: 'Castenedolo' },
  { label: 'Casterton', value: 'Casterton' },
  { label: 'Castets', value: 'Castets' },
  { label: 'Castets-En-Dorthe', value: 'Castets-En-Dorthe' },
  { label: 'Castiadas', value: 'Castiadas' },
  { label: 'Castielfabib', value: 'Castielfabib' },
  { label: 'Castiello De Jaca', value: 'Castiello De Jaca' },
  { label: 'Castigaleu', value: 'Castigaleu' },
  { label: 'Castiglion Fibocchi', value: 'Castiglion Fibocchi' },
  { label: 'Castiglion Fiorentino', value: 'Castiglion Fiorentino' },
  { label: 'Castiglione', value: 'Castiglione' },
  { label: 'Castiglione', value: 'Castiglione' },
  { label: 'Castiglione A Casauria', value: 'Castiglione A Casauria' },
  { label: 'Castiglione Cosentino', value: 'Castiglione Cosentino' },
  { label: 'Castiglione Dadda', value: 'Castiglione Dadda' },
  { label: 'Castiglione Dei Pepoli', value: 'Castiglione Dei Pepoli' },
  { label: 'Castiglione Del Genovesi', value: 'Castiglione Del Genovesi' },
  { label: 'Castiglione Del Lago', value: 'Castiglione Del Lago' },
  { label: 'Castiglione Della Pescaia', value: 'Castiglione Della Pescaia' },
  { label: 'Castiglione Delle Stiviere', value: 'Castiglione Delle Stiviere' },
  { label: 'Castiglione Di Garfagnana', value: 'Castiglione Di Garfagnana' },
  { label: 'Castiglione Di Sicilia', value: 'Castiglione Di Sicilia' },
  { label: 'Castiglione Dintelvi', value: 'Castiglione Dintelvi' },
  { label: 'Castiglione Dorcia', value: 'Castiglione Dorcia' },
  { label: 'Castiglione Falletto', value: 'Castiglione Falletto' },
  { label: 'Castiglione In Teverina', value: 'Castiglione In Teverina' },
  { label: 'Castiglione Messer Marino', value: 'Castiglione Messer Marino' },
  { label: 'Castiglione Messer Raimondo', value: 'Castiglione Messer Raimondo' },
  { label: 'Castiglione Olona', value: 'Castiglione Olona' },
  { label: 'Castiglione Tinella', value: 'Castiglione Tinella' },
  { label: 'Castiglione Torinese', value: 'Castiglione Torinese' },
  { label: 'Castignano', value: 'Castignano' },
  { label: 'Castil De Peones', value: 'Castil De Peones' },
  { label: 'Castil De Vela', value: 'Castil De Vela' },
  { label: 'Castilblanco', value: 'Castilblanco' },
  { label: 'Castilblanco De Los Arroyos', value: 'Castilblanco De Los Arroyos' },
  { label: 'Castildelgado', value: 'Castildelgado' },
  { label: 'Castilenti', value: 'Castilenti' },
  { label: 'Castilfalé', value: 'Castilfalé' },
  { label: 'Castilforte', value: 'Castilforte' },
  { label: 'Castilfrío De La Sierra', value: 'Castilfrío De La Sierra' },
  { label: 'Castilho', value: 'Castilho' },
  { label: 'Castiliscar', value: 'Castiliscar' },
  { label: 'Castilla', value: 'Castilla' },
  { label: 'Castilla La Nueva', value: 'Castilla La Nueva' },
  { label: 'Castillazuelo', value: 'Castillazuelo' },
  { label: 'Castilleja De Guzmán', value: 'Castilleja De Guzmán' },
  { label: 'Castilleja De La Cuesta', value: 'Castilleja De La Cuesta' },
  { label: 'Castilleja Del Campo', value: 'Castilleja Del Campo' },
  { label: 'Castilléjar', value: 'Castilléjar' },
  { label: 'Castillejo De Iniesta', value: 'Castillejo De Iniesta' },
  { label: 'Castillejo De Martín Viejo', value: 'Castillejo De Martín Viejo' },
  { label: 'Castillejo De Mesleón', value: 'Castillejo De Mesleón' },
  { label: 'Castillejo De Robledo', value: 'Castillejo De Robledo' },
  { label: 'Castillejos', value: 'Castillejos' },
  { label: 'Castillejos', value: 'Castillejos' },
  { label: 'Castillejo-Sierra', value: 'Castillejo-Sierra' },
  { label: 'Castillo', value: 'Castillo' },
  { label: 'Castillo', value: 'Castillo' },
  { label: 'Castillo De Bayuela', value: 'Castillo De Bayuela' },
  { label: 'Castillo De Garcimuñoz', value: 'Castillo De Garcimuñoz' },
  { label: 'Castillo De Las Guardas El', value: 'Castillo De Las Guardas El' },
  { label: 'Castillo De Locubín', value: 'Castillo De Locubín' },
  { label: 'Castillo De Teayo', value: 'Castillo De Teayo' },
  { label: 'Castillo De Villamalefa', value: 'Castillo De Villamalefa' },
  { label: 'Castillo-Albaráñez', value: 'Castillo-Albaráñez' },
  { label: 'Castillon-Du-Gard', value: 'Castillon-Du-Gard' },
  { label: 'Castillon-La-Bataille', value: 'Castillon-La-Bataille' },
  { label: 'Castillonnès', value: 'Castillonnès' },
  { label: 'Castillonroy', value: 'Castillonroy' },
  { label: 'Castillonuevo', value: 'Castillonuevo' },
  { label: 'Castillos', value: 'Castillos' },
  { label: 'Castilnuevo', value: 'Castilnuevo' },
  { label: 'Castilruiz', value: 'Castilruiz' },
  { label: 'Castine', value: 'Castine' },
  { label: 'Castino', value: 'Castino' },
  { label: 'Castion', value: 'Castion' },
  { label: 'Castione', value: 'Castione' },
  { label: 'Castione Andevenno', value: 'Castione Andevenno' },
  { label: 'Castione Della Presolana', value: 'Castione Della Presolana' },
  { label: 'Castions', value: 'Castions' },
  { label: 'Castions Di Strada', value: 'Castions Di Strada' },
  { label: 'Castiraga Vidardo', value: 'Castiraga Vidardo' },
  { label: 'Castle Bruce', value: 'Castle Bruce' },
  { label: 'Castle Cary', value: 'Castle Cary' },
  { label: 'Castle Comfort', value: 'Castle Comfort' },
  { label: 'Castle Cove', value: 'Castle Cove' },
  { label: 'Castle Dale', value: 'Castle Dale' },
  { label: 'Castle Donington', value: 'Castle Donington' },
  { label: 'Castle Douglas', value: 'Castle Douglas' },
  { label: 'Castle Hayne', value: 'Castle Hayne' },
  { label: 'Castle Hedingham', value: 'Castle Hedingham' },
  { label: 'Castle Hill', value: 'Castle Hill' },
  { label: 'Castle Hills', value: 'Castle Hills' },
  { label: 'Castle Pines', value: 'Castle Pines' },
  { label: 'Castle Pines North', value: 'Castle Pines North' },
  { label: 'Castle Point', value: 'Castle Point' },
  { label: 'Castle Rock', value: 'Castle Rock' },
  { label: 'Castle Rock', value: 'Castle Rock' },
  { label: 'Castle Shannon', value: 'Castle Shannon' },
  { label: 'Castle Vale', value: 'Castle Vale' },
  { label: 'Castlebar', value: 'Castlebar' },
  { label: 'Castlebellingham', value: 'Castlebellingham' },
  { label: 'Castleblayney', value: 'Castleblayney' },
  { label: 'Castlebridge', value: 'Castlebridge' },
  { label: 'Castlecomer', value: 'Castlecomer' },
  { label: 'Castleconnell', value: 'Castleconnell' },
  { label: 'Castlecrag', value: 'Castlecrag' },
  { label: 'Castledawson', value: 'Castledawson' },
  { label: 'Castlederg', value: 'Castlederg' },
  { label: 'Castledermot', value: 'Castledermot' },
  { label: 'Castleford', value: 'Castleford' },
  { label: 'Castlegar', value: 'Castlegar' },
  { label: 'Castleisland', value: 'Castleisland' },
  { label: 'Castleknock', value: 'Castleknock' },
  { label: 'Castlemaine', value: 'Castlemaine' },
  { label: 'Castlemartyr', value: 'Castlemartyr' },
  { label: 'Castlepoint', value: 'Castlepoint' },
  { label: 'Castlepollard', value: 'Castlepollard' },
  { label: 'Castlerea', value: 'Castlerea' },
  { label: 'Castlereagh', value: 'Castlereagh' },
  { label: 'Castlereagh', value: 'Castlereagh' },
  { label: 'Castlerock', value: 'Castlerock' },
  { label: 'Castleside', value: 'Castleside' },
  { label: 'Castleton', value: 'Castleton' },
  { label: 'Castleton', value: 'Castleton' },
  { label: 'Castleton-On-Hudson', value: 'Castleton-On-Hudson' },
  { label: 'Castletown', value: 'Castletown' },
  { label: 'Castletown', value: 'Castletown' },
  { label: 'Castlewellan', value: 'Castlewellan' },
  { label: 'Castlewood', value: 'Castlewood' },
  { label: 'Castlewood', value: 'Castlewood' },
  { label: 'Casto', value: 'Casto' },
  { label: 'Častolovice', value: 'Častolovice' },
  { label: 'Castor', value: 'Castor' },
  { label: 'Castorano', value: 'Castorano' },
  { label: 'Castranova', value: 'Castranova' },
  { label: 'Castraz', value: 'Castraz' },
  { label: 'Castrejón De La Peña', value: 'Castrejón De La Peña' },
  { label: 'Castrejón De Trabancos', value: 'Castrejón De Trabancos' },
  { label: 'Castrele Traiane', value: 'Castrele Traiane' },
  { label: 'Castrelo De Miño', value: 'Castrelo De Miño' },
  { label: 'Castrelo Do Val', value: 'Castrelo Do Val' },
  { label: 'Castres', value: 'Castres' },
  { label: 'Castres-Gironde', value: 'Castres-Gironde' },
  { label: 'Castrezzato', value: 'Castrezzato' },
  { label: 'Castri Di Lecce', value: 'Castri Di Lecce' },
  { label: 'Castricum', value: 'Castricum' },
  { label: 'Castries', value: 'Castries' },
  { label: 'Castries', value: 'Castries' },
  { label: 'Castrignano De Greci', value: 'Castrignano De Greci' },
  { label: 'Castrignano Del Capo', value: 'Castrignano Del Capo' },
  { label: 'Castril', value: 'Castril' },
  { label: 'Castrillo De Cabrera', value: 'Castrillo De Cabrera' },
  { label: 'Castrillo De Don Juan', value: 'Castrillo De Don Juan' },
  { label: 'Castrillo De Duero', value: 'Castrillo De Duero' },
  { label: 'Castrillo De La Guareña', value: 'Castrillo De La Guareña' },
  { label: 'Castrillo De La Reina', value: 'Castrillo De La Reina' },
  { label: 'Castrillo De La Valduerna', value: 'Castrillo De La Valduerna' },
  { label: 'Castrillo De La Vega', value: 'Castrillo De La Vega' },
  { label: 'Castrillo De Onielo', value: 'Castrillo De Onielo' },
  { label: 'Castrillo De Riopisuerga', value: 'Castrillo De Riopisuerga' },
  { label: 'Castrillo De Villavega', value: 'Castrillo De Villavega' },
  { label: 'Castrillo Del Val', value: 'Castrillo Del Val' },
  { label: 'Castrillo Matajudíos', value: 'Castrillo Matajudíos' },
  { label: 'Castrillón', value: 'Castrillón' },
  { label: 'Castrillo-Tejeriego', value: 'Castrillo-Tejeriego' },
  { label: 'Castro', value: 'Castro' },
  { label: 'Castro', value: 'Castro' },
  { label: 'Castro', value: 'Castro' },
  { label: 'Castro', value: 'Castro' },
  { label: 'Castro Alves', value: 'Castro Alves' },
  { label: 'Castro Barros', value: 'Castro Barros' },
  { label: 'Castro Caldelas', value: 'Castro Caldelas' },
  { label: 'Castro County', value: 'Castro County' },
  { label: 'Castro Daire', value: 'Castro Daire' },
  { label: 'Castro De Filabres', value: 'Castro De Filabres' },
  { label: 'Castro De Fuentidueña', value: 'Castro De Fuentidueña' },
  { label: 'Castro De Rei', value: 'Castro De Rei' },
  { label: 'Castro Dei Volsci', value: 'Castro Dei Volsci' },
  { label: 'Castro Del Río', value: 'Castro Del Río' },
  { label: 'Castro Marim', value: 'Castro Marim' },
  { label: 'Castro Urdiales', value: 'Castro Urdiales' },
  { label: 'Castro Valley', value: 'Castro Valley' },
  { label: 'Castro Verde', value: 'Castro Verde' },
  { label: 'Castrobol', value: 'Castrobol' },
  { label: 'Castrocalbón', value: 'Castrocalbón' },
  { label: 'Castrocaro Terme E Terra Del Sole', value: 'Castrocaro Terme E Terra Del Sole' },
  { label: 'Castrocielo', value: 'Castrocielo' },
  { label: 'Castrocontrigo', value: 'Castrocontrigo' },
  { label: 'Castrodeza', value: 'Castrodeza' },
  { label: 'Castrofilippo', value: 'Castrofilippo' },
  { label: 'Castrogonzalo', value: 'Castrogonzalo' },
  { label: 'Castrojeriz', value: 'Castrojeriz' },
  { label: 'Castrojimeno', value: 'Castrojimeno' },
  { label: 'Castrolibero', value: 'Castrolibero' },
  { label: 'Castromediano', value: 'Castromediano' },
  { label: 'Castromembibre', value: 'Castromembibre' },
  { label: 'Castromocho', value: 'Castromocho' },
  { label: 'Castromonte', value: 'Castromonte' },
  { label: 'Castronno', value: 'Castronno' },
  { label: 'Castronuevo', value: 'Castronuevo' },
  { label: 'Castronuevo De Esgueva', value: 'Castronuevo De Esgueva' },
  { label: 'Castronuño', value: 'Castronuño' },
  { label: 'Castronuovo Di Santandrea', value: 'Castronuovo Di Santandrea' },
  { label: 'Castronuovo Di Sicilia', value: 'Castronuovo Di Sicilia' },
  { label: 'Castropignano', value: 'Castropignano' },
  { label: 'Castropodame', value: 'Castropodame' },
  { label: 'Castropol', value: 'Castropol' },
  { label: 'Castroponce', value: 'Castroponce' },
  { label: 'Castrop-Rauxel', value: 'Castrop-Rauxel' },
  { label: 'Castroreale', value: 'Castroreale' },
  { label: 'Castroregio', value: 'Castroregio' },
  { label: 'Castroserna De Abajo', value: 'Castroserna De Abajo' },
  { label: 'Castroserracín', value: 'Castroserracín' },
  { label: 'Castrotierra De Valmadrigal', value: 'Castrotierra De Valmadrigal' },
  { label: 'Castro-Urdiales', value: 'Castro-Urdiales' },
  { label: 'Castroverde', value: 'Castroverde' },
  { label: 'Castroverde De Campos', value: 'Castroverde De Campos' },
  { label: 'Castroverde De Cerrato', value: 'Castroverde De Cerrato' },
  { label: 'Castroviejo', value: 'Castroviejo' },
  { label: 'Castrovillari', value: 'Castrovillari' },
  { label: 'Castroville', value: 'Castroville' },
  { label: 'Castroville', value: 'Castroville' },
  { label: 'Castuera', value: 'Castuera' },
  { label: 'Casuarina', value: 'Casuarina' },
  { label: 'Casuarina', value: 'Casuarina' },
  { label: 'Casuguran', value: 'Casuguran' },
  { label: 'Casula', value: 'Casula' },
  { label: 'Casupá', value: 'Casupá' },
  { label: 'Cașva', value: 'Cașva' },
  { label: 'Caswell County', value: 'Caswell County' },
  { label: 'Çat', value: 'Çat' },
  { label: 'Cát Bà', value: 'Cát Bà' },
  { label: 'Caţa', value: 'Caţa' },
  { label: 'Catabangan', value: 'Catabangan' },
  { label: 'Catablan', value: 'Catablan' },
  { label: 'Catabola', value: 'Catabola' },
  { label: 'Cataby', value: 'Cataby' },
  { label: 'Catacamas', value: 'Catacamas' },
  { label: 'Catacaos', value: 'Catacaos' },
  { label: 'Catadau', value: 'Catadau' },
  { label: 'Catadupa', value: 'Catadupa' },
  { label: 'Cataeggio', value: 'Cataeggio' },
  { label: 'Cataguases', value: 'Cataguases' },
  { label: 'Catahoula', value: 'Catahoula' },
  { label: 'Catahoula Parish', value: 'Catahoula Parish' },
  { label: 'Cataingan', value: 'Cataingan' },
  { label: 'Çatak', value: 'Çatak' },
  { label: 'Catalão', value: 'Catalão' },
  { label: 'Çatalca', value: 'Çatalca' },
  { label: 'Catalina', value: 'Catalina' },
  { label: 'Catalina', value: 'Catalina' },
  { label: 'Catalina', value: 'Catalina' },
  { label: 'Catalina', value: 'Catalina' },
  { label: 'Cătălina', value: 'Cătălina' },
  { label: 'Catalina Foothills', value: 'Catalina Foothills' },
  { label: 'Cataloi', value: 'Cataloi' },
  { label: 'Çatalpınar', value: 'Çatalpınar' },
  { label: 'Çatalzeytin', value: 'Çatalzeytin' },
  { label: 'Cătămărești-Deal', value: 'Cătămărești-Deal' },
  { label: 'Catanauan', value: 'Catanauan' },
  { label: 'Catanduva', value: 'Catanduva' },
  { label: 'Catanduvas', value: 'Catanduvas' },
  { label: 'Catanduvas', value: 'Catanduvas' },
  { label: 'Catane', value: 'Catane' },
  { label: 'Catanele', value: 'Catanele' },
  { label: 'Catania', value: 'Catania' },
  { label: 'Cataño', value: 'Cataño' },
  { label: 'Cataño', value: 'Cataño' },
  { label: 'Catanzaro', value: 'Catanzaro' },
  { label: 'Catarama', value: 'Catarama' },
  { label: 'Catarina', value: 'Catarina' },
  { label: 'Catarina', value: 'Catarina' },
  { label: 'Catarina', value: 'Catarina' },
  { label: 'Catarino Rodríguez', value: 'Catarino Rodríguez' },
  { label: 'Catarman', value: 'Catarman' },
  { label: 'Catarman', value: 'Catarman' },
  { label: 'Catarman', value: 'Catarman' },
  { label: 'Catarman', value: 'Catarman' },
  { label: 'Catarroja', value: 'Catarroja' },
  { label: 'Catas Altas', value: 'Catas Altas' },
  { label: 'Catas Altas Da Noruega', value: 'Catas Altas Da Noruega' },
  { label: 'Catasauqua', value: 'Catasauqua' },
  { label: 'Catawba', value: 'Catawba' },
  { label: 'Catawba County', value: 'Catawba County' },
  { label: 'Catawissa', value: 'Catawissa' },
  { label: 'Çatax', value: 'Çatax' },
  { label: 'Catayauan', value: 'Catayauan' },
  { label: 'Catayauan', value: 'Catayauan' },
  { label: 'Catazajá', value: 'Catazajá' },
  { label: 'Catbalogan', value: 'Catbalogan' },
  { label: 'Catbalogan', value: 'Catbalogan' },
  { label: 'Câţcău', value: 'Câţcău' },
  { label: 'Catcliffe', value: 'Catcliffe' },
  { label: 'Căteasca', value: 'Căteasca' },
  { label: 'Cateel', value: 'Cateel' },
  { label: 'Cateel', value: 'Cateel' },
  { label: 'Cateel', value: 'Cateel' },
  { label: 'Cățelu', value: 'Cățelu' },
  { label: 'Catemaco', value: 'Catemaco' },
  { label: 'Catemu', value: 'Catemu' },
  { label: 'Catena', value: 'Catena' },
  { label: 'Catenanuova', value: 'Catenanuova' },
  { label: 'Catende', value: 'Catende' },
  { label: 'Catenoy', value: 'Catenoy' },
  { label: 'Caterham', value: 'Caterham' },
  { label: 'Caterman', value: 'Caterman' },
  { label: 'Caterman', value: 'Caterman' },
  { label: 'Cathcart', value: 'Cathcart' },
  { label: 'Cathedral City', value: 'Cathedral City' },
  { label: 'Catherine Field', value: 'Catherine Field' },
  { label: 'Catherine Hall', value: 'Catherine Hall' },
  { label: 'Catherine Mount', value: 'Catherine Mount' },
  { label: 'Cathlamet', value: 'Cathlamet' },
  { label: 'Catí', value: 'Catí' },
  { label: 'Catia La Mar', value: 'Catia La Mar' },
  { label: 'Caticlan', value: 'Caticlan' },
  { label: 'Caticugan', value: 'Caticugan' },
  { label: 'Catigbian', value: 'Catigbian' },
  { label: 'Catignano', value: 'Catignano' },
  { label: 'Catiguá', value: 'Catiguá' },
  { label: 'Catin', value: 'Catin' },
  { label: 'Cătina', value: 'Cătina' },
  { label: 'Cătina', value: 'Cătina' },
  { label: 'Cătina', value: 'Cătina' },
  { label: 'Catingueira', value: 'Catingueira' },
  { label: 'Catió', value: 'Catió' },
  { label: 'Catishtic', value: 'Catishtic' },
  { label: 'Cativá', value: 'Cativá' },
  { label: 'Cativelos', value: 'Cativelos' },
  { label: 'Catlettsburg', value: 'Catlettsburg' },
  { label: 'Catlin', value: 'Catlin' },
  { label: 'Catllar', value: 'Catllar' },
  { label: 'Catmon', value: 'Catmon' },
  { label: 'Catmon', value: 'Catmon' },
  { label: 'Catmon', value: 'Catmon' },
  { label: 'Catmondaan', value: 'Catmondaan' },
  { label: 'Cato', value: 'Cato' },
  { label: 'Cato', value: 'Cato' },
  { label: 'Cato', value: 'Cato' },
  { label: 'Catoira', value: 'Catoira' },
  { label: 'Catolândia', value: 'Catolândia' },
  { label: 'Catolé Do Rocha', value: 'Catolé Do Rocha' },
  { label: 'Caton', value: 'Caton' },
  { label: 'Catonsville', value: 'Catonsville' },
  { label: 'Catoosa', value: 'Catoosa' },
  { label: 'Catoosa County', value: 'Catoosa County' },
  { label: 'Catral', value: 'Catral' },
  { label: 'Catriel', value: 'Catriel' },
  { label: 'Catrijp', value: 'Catrijp' },
  { label: 'Catriló', value: 'Catriló' },
  { label: 'Catrine', value: 'Catrine' },
  { label: 'Catron County', value: 'Catron County' },
  { label: 'Catskill', value: 'Catskill' },
  { label: 'Cattaraugus County', value: 'Cattaraugus County' },
  { label: 'Cattenom', value: 'Cattenom' },
  { label: 'Catterall', value: 'Catterall' },
  { label: 'Catterick', value: 'Catterick' },
  { label: 'Catterick Garrison', value: 'Catterick Garrison' },
  { label: 'Cattolica', value: 'Cattolica' },
  { label: 'Cattolica Eraclea', value: 'Cattolica Eraclea' },
  { label: 'Catu', value: 'Catu' },
  { label: 'Catubig', value: 'Catubig' },
  { label: 'Catubig', value: 'Catubig' },
  { label: 'Catuday', value: 'Catuday' },
  { label: 'Catuday', value: 'Catuday' },
  { label: 'Catuípe', value: 'Catuípe' },
  { label: 'Catuji', value: 'Catuji' },
  { label: 'Catumbela', value: 'Catumbela' },
  { label: 'Catunda', value: 'Catunda' },
  { label: 'Cătunele', value: 'Cătunele' },
  { label: 'Catungan', value: 'Catungan' },
  { label: 'Catungawan Sur', value: 'Catungawan Sur' },
  { label: 'Caturaí', value: 'Caturaí' },
  { label: 'Caturama', value: 'Caturama' },
  { label: 'Caturité', value: 'Caturité' },
  { label: 'Catuti', value: 'Catuti' },
  { label: 'Căuaş', value: 'Căuaş' },
  { label: 'Cauayan', value: 'Cauayan' },
  { label: 'Cauayan', value: 'Cauayan' },
  { label: 'Cauayan', value: 'Cauayan' },
  { label: 'Caucaguita', value: 'Caucaguita' },
  { label: 'Caucagüito', value: 'Caucagüito' },
  { label: 'Caucaia', value: 'Caucaia' },
  { label: 'Caucasia', value: 'Caucasia' },
  { label: 'Caucel', value: 'Caucel' },
  { label: 'Caucete', value: 'Caucete' },
  { label: 'Cauchero', value: 'Cauchero' },
  { label: 'Cauchy-À-La-Tour', value: 'Cauchy-À-La-Tour' },
  { label: 'Cauco', value: 'Cauco' },
  { label: 'Caudan', value: 'Caudan' },
  { label: 'Caudan Waterfront', value: 'Caudan Waterfront' },
  { label: 'Caudebec-En-Caux', value: 'Caudebec-En-Caux' },
  { label: 'Caudebec-Lès-Elbeuf', value: 'Caudebec-Lès-Elbeuf' },
  { label: 'Caudete', value: 'Caudete' },
  { label: 'Caudete De Las Fuentes', value: 'Caudete De Las Fuentes' },
  { label: 'Caudiel', value: 'Caudiel' },
  { label: 'Caudry', value: 'Caudry' },
  { label: 'Caué District', value: 'Caué District' },
  { label: 'Cauffry', value: 'Cauffry' },
  { label: 'Cauldwell', value: 'Cauldwell' },
  { label: 'Caulfield', value: 'Caulfield' },
  { label: 'Caulfield East', value: 'Caulfield East' },
  { label: 'Caulfield North', value: 'Caulfield North' },
  { label: 'Caulfield South', value: 'Caulfield South' },
  { label: 'Caulnes', value: 'Caulnes' },
  { label: 'Caulonia', value: 'Caulonia' },
  { label: 'Caulonia Marina', value: 'Caulonia Marina' },
  { label: 'Caumont', value: 'Caumont' },
  { label: 'Caumont-Léventé', value: 'Caumont-Léventé' },
  { label: 'Caumont-Sur-Durance', value: 'Caumont-Sur-Durance' },
  { label: 'Caunes-Minervois', value: 'Caunes-Minervois' },
  { label: 'Cauquenes', value: 'Cauquenes' },
  { label: 'Caurio De Guadalupe', value: 'Caurio De Guadalupe' },
  { label: 'Cauro', value: 'Cauro' },
  { label: 'Căuşeni', value: 'Căuşeni' },
  { label: 'Causeway Bay', value: 'Causeway Bay' },
  { label: 'Causeway Coast And Glens', value: 'Causeway Coast And Glens' },
  { label: 'Causip', value: 'Causip' },
  { label: 'Caussade', value: 'Caussade' },
  { label: 'Causwagan', value: 'Causwagan' },
  { label: 'Causwagan', value: 'Causwagan' },
  { label: 'Cautano', value: 'Cautano' },
  { label: 'Cauterets', value: 'Cauterets' },
  { label: 'Cauto Cristo', value: 'Cauto Cristo' },
  { label: 'Cauvigny', value: 'Cauvigny' },
  { label: 'Cauville-Sur-Mer', value: 'Cauville-Sur-Mer' },
  { label: 'Caux', value: 'Caux' },
  { label: 'Caux', value: 'Caux' },
  { label: 'Cava', value: 'Cava' },
  { label: 'Cava Daliga', value: 'Cava Daliga' },
  { label: 'Cava Dè Tirreni', value: 'Cava Dè Tirreni' },
  { label: 'Cava Manara', value: 'Cava Manara' },
  { label: 'Cava-Cuculera Nobile', value: 'Cava-Cuculera Nobile' },
  { label: 'Cavacurta', value: 'Cavacurta' },
  { label: 'Cavadineşti', value: 'Cavadineşti' },
  { label: 'Cavadoude', value: 'Cavadoude' },
  { label: 'Cavaglià', value: 'Cavaglià' },
  { label: 'Cavaglietto', value: 'Cavaglietto' },
  { label: 'Cavaglio Dagogna', value: 'Cavaglio Dagogna' },
  { label: 'Cavaglio-Spoccia', value: 'Cavaglio-Spoccia' },
  { label: 'Cavagnolo', value: 'Cavagnolo' },
  { label: 'Cavaillon', value: 'Cavaillon' },
  { label: 'Cavaillon', value: 'Cavaillon' },
  { label: 'Cavajon Veronese', value: 'Cavajon Veronese' },
  { label: 'Cavalaire-Sur-Mer', value: 'Cavalaire-Sur-Mer' },
  { label: 'Cavalcante', value: 'Cavalcante' },
  { label: 'Cavalcaselle', value: 'Cavalcaselle' },
  { label: 'Cavalese', value: 'Cavalese' },
  { label: 'Cavalier', value: 'Cavalier' },
  { label: 'Cavalier County', value: 'Cavalier County' },
  { label: 'Cavaliers', value: 'Cavaliers' },
  { label: 'Cavallasca', value: 'Cavallasca' },
  { label: 'Cavallerleone', value: 'Cavallerleone' },
  { label: 'Cavallermaggiore', value: 'Cavallermaggiore' },
  { label: 'Cavallina', value: 'Cavallina' },
  { label: 'Cavallino', value: 'Cavallino' },
  { label: 'Cavallino', value: 'Cavallino' },
  { label: 'Cavallirio', value: 'Cavallirio' },
  { label: 'Cavally', value: 'Cavally' },
  { label: 'Cavan', value: 'Cavan' },
  { label: 'Cavan', value: 'Cavan' },
  { label: 'Cavanagh', value: 'Cavanagh' },
  { label: 'Cavareno', value: 'Cavareno' },
  { label: 'Cavargna', value: 'Cavargna' },
  { label: 'Cavaria Con Premezzo', value: 'Cavaria Con Premezzo' },
  { label: 'Cavarzere', value: 'Cavarzere' },
  { label: 'Cavaso Del Tomba', value: 'Cavaso Del Tomba' },
  { label: 'Cavasso Nuovo', value: 'Cavasso Nuovo' },
  { label: 'Cavatore', value: 'Cavatore' },
  { label: 'Cavazzo Carnico', value: 'Cavazzo Carnico' },
  { label: 'Cavazzona', value: 'Cavazzona' },
  { label: 'Çavdarhisar', value: 'Çavdarhisar' },
  { label: 'Çavdır', value: 'Çavdır' },
  { label: 'Cave', value: 'Cave' },
  { label: 'Cave', value: 'Cave' },
  { label: 'Cave City', value: 'Cave City' },
  { label: 'Cave City', value: 'Cave City' },
  { label: 'Cave Creek', value: 'Cave Creek' },
  { label: 'Cave Junction', value: 'Cave Junction' },
  { label: 'Cave Spring', value: 'Cave Spring' },
  { label: 'Cave Spring', value: 'Cave Spring' },
  { label: 'Cave Springs', value: 'Cave Springs' },
  { label: 'Cave Valley', value: 'Cave Valley' },
  { label: 'Cavedago', value: 'Cavedago' },
  { label: 'Cavedine', value: 'Cavedine' },
  { label: 'Caveirac', value: 'Caveirac' },
  { label: 'Cavelossim', value: 'Cavelossim' },
  { label: 'Cavenago Dadda', value: 'Cavenago Dadda' },
  { label: 'Cavenago Di Brianza', value: 'Cavenago Di Brianza' },
  { label: 'Cavernago', value: 'Cavernago' },
  { label: 'Caversham', value: 'Caversham' },
  { label: 'Cavertitz', value: 'Cavertitz' },
  { label: 'Caves Beach', value: 'Caves Beach' },
  { label: 'Cavetown', value: 'Cavetown' },
  { label: 'Cavezzo', value: 'Cavezzo' },
  { label: 'Cavia', value: 'Cavia' },
  { label: 'Cavignac', value: 'Cavignac' },
  { label: 'Cavino', value: 'Cavino' },
  { label: 'Cavinti', value: 'Cavinti' },
  { label: 'Cavite', value: 'Cavite' },
  { label: 'Cavite', value: 'Cavite' },
  { label: 'Cavite', value: 'Cavite' },
  { label: 'Cavite City', value: 'Cavite City' },
  { label: 'Cavizzana', value: 'Cavizzana' },
  { label: 'Čavle', value: 'Čavle' },
  { label: 'Cavnic', value: 'Cavnic' },
  { label: 'Cavolano-Schiavoi', value: 'Cavolano-Schiavoi' },
  { label: 'Cavoni-Ginestreto', value: 'Cavoni-Ginestreto' },
  { label: 'Cavour', value: 'Cavour' },
  { label: 'Cavriago', value: 'Cavriago' },
  { label: 'Cavriana', value: 'Cavriana' },
  { label: 'Cavriglia-Monastero', value: 'Cavriglia-Monastero' },
  { label: 'Cavtat', value: 'Cavtat' },
  { label: 'Cawayan', value: 'Cawayan' },
  { label: 'Cawayan', value: 'Cawayan' },
  { label: 'Cawayan', value: 'Cawayan' },
  { label: 'Cawayan', value: 'Cawayan' },
  { label: 'Cawayan Bugtong', value: 'Cawayan Bugtong' },
  { label: 'Cawayan Bugtong', value: 'Cawayan Bugtong' },
  { label: 'Cawood', value: 'Cawood' },
  { label: 'Cawston', value: 'Cawston' },
  { label: 'Cawthorne', value: 'Cawthorne' },
  { label: 'Caxambu', value: 'Caxambu' },
  { label: 'Caxambu Do Sul', value: 'Caxambu Do Sul' },
  { label: 'Caxapa', value: 'Caxapa' },
  { label: 'Caxhuacán', value: 'Caxhuacán' },
  { label: 'Caxias', value: 'Caxias' },
  { label: 'Caxias', value: 'Caxias' },
  { label: 'Caxias', value: 'Caxias' },
  { label: 'Caxias Do Sul', value: 'Caxias Do Sul' },
  { label: 'Caxingó', value: 'Caxingó' },
  { label: 'Caxitepec', value: 'Caxitepec' },
  { label: 'Caxito', value: 'Caxito' },
  { label: 'Caxuxi', value: 'Caxuxi' },
  { label: 'Çay', value: 'Çay' },
  { label: 'Cayaco', value: 'Cayaco' },
  { label: 'Cayambe', value: 'Cayambe' },
  { label: 'Cayang', value: 'Cayang' },
  { label: 'Cayanga', value: 'Cayanga' },
  { label: 'Cayanga', value: 'Cayanga' },
  { label: 'Cayanguan', value: 'Cayanguan' },
  { label: 'Çaybaşı', value: 'Çaybaşı' },
  { label: 'Cayce', value: 'Cayce' },
  { label: 'Çaycuma', value: 'Çaycuma' },
  { label: 'Caye Manje', value: 'Caye Manje' },
  { label: 'Çayeli', value: 'Çayeli' },
  { label: 'Cayes-Jacmel', value: 'Cayes-Jacmel' },
  { label: 'Cayetano Germosén', value: 'Cayetano Germosén' },
  { label: 'Cayeux-Sur-Mer', value: 'Cayeux-Sur-Mer' },
  { label: 'Cayey', value: 'Cayey' },
  { label: 'Cayey', value: 'Cayey' },
  { label: 'Cayhagan', value: 'Cayhagan' },
  { label: 'Çayıralan', value: 'Çayıralan' },
  { label: 'Çayırlı', value: 'Çayırlı' },
  { label: 'Çayırova', value: 'Çayırova' },
  { label: 'Çaykara', value: 'Çaykara' },
  { label: 'Caylus', value: 'Caylus' },
  { label: 'Caymanas', value: 'Caymanas' },
  { label: 'Cayon', value: 'Cayon' },
  { label: 'Caythorpe', value: 'Caythorpe' },
  { label: 'Cayuco', value: 'Cayuco' },
  { label: 'Cayucos', value: 'Cayucos' },
  { label: 'Cayuela', value: 'Cayuela' },
  { label: 'Cayuga', value: 'Cayuga' },
  { label: 'Cayuga County', value: 'Cayuga County' },
  { label: 'Cayuga Heights', value: 'Cayuga Heights' },
  { label: 'Cayungnan', value: 'Cayungnan' },
  { label: 'Cayungnan', value: 'Cayungnan' },
  { label: 'Caza De Aakkar', value: 'Caza De Aakkar' },
  { label: 'Caza De Baabda', value: 'Caza De Baabda' },
  { label: 'Caza De Baalbek', value: 'Caza De Baalbek' },
  { label: 'Caza De Bent Jbaïl', value: 'Caza De Bent Jbaïl' },
  { label: 'Caza De Nabatîyé', value: 'Caza De Nabatîyé' },
  { label: 'Cazaci', value: 'Cazaci' },
  { label: 'Cazaji', value: 'Cazaji' },
  { label: 'Cazalegas', value: 'Cazalegas' },
  { label: 'Cazalilla', value: 'Cazalilla' },
  { label: 'Cazalla De La Sierra', value: 'Cazalla De La Sierra' },
  { label: 'Căzăneşti', value: 'Căzăneşti' },
  { label: 'Căzăneşti', value: 'Căzăneşti' },
  { label: 'Cazasu', value: 'Cazasu' },
  { label: 'Cazaubon', value: 'Cazaubon' },
  { label: 'Cazenga', value: 'Cazenga' },
  { label: 'Cazenovia', value: 'Cazenovia' },
  { label: 'Cazères', value: 'Cazères' },
  { label: 'Cazères-Sur-L’Adour', value: 'Cazères-Sur-L’Adour' },
  { label: 'Cazes-Mondenard', value: 'Cazes-Mondenard' },
  { label: 'Cazilhac', value: 'Cazilhac' },
  { label: 'Cazin', value: 'Cazin' },
  { label: 'Cazis', value: 'Cazis' },
  { label: 'Čazma', value: 'Čazma' },
  { label: 'Cazones De Herrera', value: 'Cazones De Herrera' },
  { label: 'Cazorla', value: 'Cazorla' },
  { label: 'Cazouls-Lès-Béziers', value: 'Cazouls-Lès-Béziers' },
  { label: 'Cazuca', value: 'Cazuca' },
  { label: 'Cazurra', value: 'Cazurra' },
  { label: 'Cazzago Brabbia', value: 'Cazzago Brabbia' },
  { label: 'Cazzago San Martino-Calino', value: 'Cazzago San Martino-Calino' },
  { label: 'Cazzago-Ex Polo', value: 'Cazzago-Ex Polo' },
  { label: 'Cazzano Di Tramigna', value: 'Cazzano Di Tramigna' },
  { label: 'Cazzano Santandrea', value: 'Cazzano Santandrea' },
  { label: 'Ccaquiracunca', value: 'Ccaquiracunca' },
  { label: 'Ccolo', value: 'Ccolo' },
  { label: 'Ccuntuma', value: 'Ccuntuma' },
  { label: 'Cea', value: 'Cea' },
  { label: 'Ceacu', value: 'Ceacu' },
  { label: 'Ceadîr-Lunga', value: 'Ceadîr-Lunga' },
  { label: 'Ceahlău', value: 'Ceahlău' },
  { label: 'Ceamurlia De Jos', value: 'Ceamurlia De Jos' },
  { label: 'Ceamurlia De Sus', value: 'Ceamurlia De Sus' },
  { label: 'Ceanu Mare', value: 'Ceanu Mare' },
  { label: 'Ceará Mirim', value: 'Ceará Mirim' },
  { label: 'Ceará-Mirim', value: 'Ceará-Mirim' },
  { label: 'Ceardac', value: 'Ceardac' },
  { label: 'Ceatalchioi', value: 'Ceatalchioi' },
  { label: 'Ceaucé', value: 'Ceaucé' },
  { label: 'Ceauru', value: 'Ceauru' },
  { label: 'Ceballos', value: 'Ceballos' },
  { label: 'Cebanico', value: 'Cebanico' },
  { label: 'Cébazat', value: 'Cébazat' },
  { label: 'Čebín', value: 'Čebín' },
  { label: 'Cebolla', value: 'Cebolla' },
  { label: 'Cebollatí', value: 'Cebollatí' },
  { label: 'Cebrecos', value: 'Cebrecos' },
  { label: 'Cebreros', value: 'Cebreros' },
  { label: 'Cebrones Del Río', value: 'Cebrones Del Río' },
  { label: 'Cebu City', value: 'Cebu City' },
  { label: 'Cebuano', value: 'Cebuano' },
  { label: 'Cebuano', value: 'Cebuano' },
  { label: 'Cecălaca', value: 'Cecălaca' },
  { label: 'Čečava', value: 'Čečava' },
  { label: 'Ceccano', value: 'Ceccano' },
  { label: 'Cecchina', value: 'Cecchina' },
  { label: 'Cecchini', value: 'Cecchini' },
  { label: 'Cece', value: 'Cece' },
  { label: 'Čechtice', value: 'Čechtice' },
  { label: 'Cecil County', value: 'Cecil County' },
  { label: 'Cecil Hills', value: 'Cecil Hills' },
  { label: 'Cecil-Bishop', value: 'Cecil-Bishop' },
  { label: 'Cecilia', value: 'Cecilia' },
  { label: 'Cecilio Baez', value: 'Cecilio Baez' },
  { label: 'Cecilio Terán', value: 'Cecilio Terán' },
  { label: 'Cecima', value: 'Cecima' },
  { label: 'Cecina', value: 'Cecina' },
  { label: 'Ceclavín', value: 'Ceclavín' },
  { label: 'Cedar', value: 'Cedar' },
  { label: 'Cedar Bluff', value: 'Cedar Bluff' },
  { label: 'Cedar Bluff', value: 'Cedar Bluff' },
  { label: 'Cedar City', value: 'Cedar City' },
  { label: 'Cedar County', value: 'Cedar County' },
  { label: 'Cedar County', value: 'Cedar County' },
  { label: 'Cedar County', value: 'Cedar County' },
  { label: 'Cedar Falls', value: 'Cedar Falls' },
  { label: 'Cedar Glen Lakes', value: 'Cedar Glen Lakes' },
  { label: 'Cedar Glen West', value: 'Cedar Glen West' },
  { label: 'Cedar Grove', value: 'Cedar Grove' },
  { label: 'Cedar Grove', value: 'Cedar Grove' },
  { label: 'Cedar Grove', value: 'Cedar Grove' },
  { label: 'Cedar Grove', value: 'Cedar Grove' },
  { label: 'Cedar Grove', value: 'Cedar Grove' },
  { label: 'Cedar Hill', value: 'Cedar Hill' },
  { label: 'Cedar Hill', value: 'Cedar Hill' },
  { label: 'Cedar Hills', value: 'Cedar Hills' },
  { label: 'Cedar Hills', value: 'Cedar Hills' },
  { label: 'Cedar Lake', value: 'Cedar Lake' },
  { label: 'Cedar Mill', value: 'Cedar Mill' },
  { label: 'Cedar Park', value: 'Cedar Park' },
  { label: 'Cedar Point', value: 'Cedar Point' },
  { label: 'Cedar Rapids', value: 'Cedar Rapids' },
  { label: 'Cedar Ridge', value: 'Cedar Ridge' },
  { label: 'Cedar Springs', value: 'Cedar Springs' },
  { label: 'Cedar Vale', value: 'Cedar Vale' },
  { label: 'Cedar Valley', value: 'Cedar Valley' },
  { label: 'Cedarburg', value: 'Cedarburg' },
  { label: 'Cedaredge', value: 'Cedaredge' },
  { label: 'Cedarhurst', value: 'Cedarhurst' },
  { label: 'Cedars', value: 'Cedars' },
  { label: 'Cedars/Chu Tigre', value: 'Cedars/Chu Tigre' },
  { label: 'Cedartown', value: 'Cedartown' },
  { label: 'Cedarville', value: 'Cedarville' },
  { label: 'Cedarville', value: 'Cedarville' },
  { label: 'Cedegolo', value: 'Cedegolo' },
  { label: 'Cedeira', value: 'Cedeira' },
  { label: 'Cedessano', value: 'Cedessano' },
  { label: 'Cedillo', value: 'Cedillo' },
  { label: 'Cedillo De La Torre', value: 'Cedillo De La Torre' },
  { label: 'Cedillo Del Condado', value: 'Cedillo Del Condado' },
  { label: 'Cedovim', value: 'Cedovim' },
  { label: 'Cedral', value: 'Cedral' },
  { label: 'Cedral', value: 'Cedral' },
  { label: 'Cedrales', value: 'Cedrales' },
  { label: 'Cedrasco', value: 'Cedrasco' },
  { label: 'Cedrillas', value: 'Cedrillas' },
  { label: 'Cedro', value: 'Cedro' },
  { label: 'Cedro', value: 'Cedro' },
  { label: 'Cedro Arriba', value: 'Cedro Arriba' },
  { label: 'Cedro De La Manzana', value: 'Cedro De La Manzana' },
  { label: 'Cedro De São João', value: 'Cedro De São João' },
  { label: 'Cedro Do Abaeté', value: 'Cedro Do Abaeté' },
  { label: 'Cedros', value: 'Cedros' },
  { label: 'Cedros', value: 'Cedros' },
  { label: 'Cedry Wielkie', value: 'Cedry Wielkie' },
  { label: 'Ceduna', value: 'Ceduna' },
  { label: 'Cedynia', value: 'Cedynia' },
  { label: 'Cee', value: 'Cee' },
  { label: 'Ceek', value: 'Ceek' },
  { label: 'Ceelbuur', value: 'Ceelbuur' },
  { label: 'Ceeldheer', value: 'Ceeldheer' },
  { label: 'Ceerigaabo', value: 'Ceerigaabo' },
  { label: 'Cefa', value: 'Cefa' },
  { label: 'Cefalà Diana', value: 'Cefalà Diana' },
  { label: 'Cefalù', value: 'Cefalù' },
  { label: 'Cefn Cribwr', value: 'Cefn Cribwr' },
  { label: 'Cegani', value: 'Cegani' },
  { label: 'Ceggia', value: 'Ceggia' },
  { label: 'Cegléd', value: 'Cegléd' },
  { label: 'Ceglédbercel', value: 'Ceglédbercel' },
  { label: 'Ceglédi Járás', value: 'Ceglédi Járás' },
  { label: 'Ceglie Messapica', value: 'Ceglie Messapica' },
  { label: 'Cegłów', value: 'Cegłów' },
  { label: 'Cegrane', value: 'Cegrane' },
  { label: 'Ceguaca', value: 'Ceguaca' },
  { label: 'Cehal', value: 'Cehal' },
  { label: 'Cehegín', value: 'Cehegín' },
  { label: 'Cehei', value: 'Cehei' },
  { label: 'Cehu Silvaniei', value: 'Cehu Silvaniei' },
  { label: 'Ceiba', value: 'Ceiba' },
  { label: 'Ceiba', value: 'Ceiba' },
  { label: 'Ceiba Municipio', value: 'Ceiba Municipio' },
  { label: 'Ceibas', value: 'Ceibas' },
  { label: 'Ceica', value: 'Ceica' },
  { label: 'Ceie', value: 'Ceie' },
  { label: 'Ceinos De Campos', value: 'Ceinos De Campos' },
  { label: 'Ceja De Bolitas', value: 'Ceja De Bolitas' },
  { label: 'Ceja De Bravo', value: 'Ceja De Bravo' },
  { label: 'Čejč', value: 'Čejč' },
  { label: 'Čejkovice', value: 'Čejkovice' },
  { label: 'Cekcyn', value: 'Cekcyn' },
  { label: 'Çekerek', value: 'Çekerek' },
  { label: 'Çekmeköy', value: 'Çekmeköy' },
  { label: 'Cela', value: 'Cela' },
  { label: 'Celada', value: 'Celada' },
  { label: 'Celada Del Camino', value: 'Celada Del Camino' },
  { label: 'Celadas', value: 'Celadas' },
  { label: 'Čeladná', value: 'Čeladná' },
  { label: 'Čelákovice', value: 'Čelákovice' },
  { label: 'Celano', value: 'Celano' },
  { label: 'Celanova', value: 'Celanova' },
  { label: 'Čelarevo', value: 'Čelarevo' },
  { label: 'Celaru', value: 'Celaru' },
  { label: 'Celat-San Tomaso Agordino', value: 'Celat-San Tomaso Agordino' },
  { label: 'Celaya', value: 'Celaya' },
  { label: 'Celayita', value: 'Celayita' },
  { label: 'Celbridge', value: 'Celbridge' },
  { label: 'Çelebi', value: 'Çelebi' },
  { label: 'Celebration', value: 'Celebration' },
  { label: 'Čelechovice Na Hané', value: 'Čelechovice Na Hané' },
  { label: 'Celendín', value: 'Celendín' },
  { label: 'Celenza Sul Trigno', value: 'Celenza Sul Trigno' },
  { label: 'Celenza Valfortore', value: 'Celenza Valfortore' },
  { label: 'Celerina', value: 'Celerina' },
  { label: 'Celestún', value: 'Celestún' },
  { label: 'Celestynów', value: 'Celestynów' },
  { label: 'Čelić', value: 'Čelić' },
  { label: 'Celico', value: 'Celico' },
  { label: 'Çelikhan', value: 'Çelikhan' },
  { label: 'Celina', value: 'Celina' },
  { label: 'Celina', value: 'Celina' },
  { label: 'Celina', value: 'Celina' },
  { label: 'Čelinac', value: 'Čelinac' },
  { label: 'Celje', value: 'Celje' },
  { label: 'Cella', value: 'Cella' },
  { label: 'Cella Dati', value: 'Cella Dati' },
  { label: 'Cella Monte', value: 'Cella Monte' },
  { label: 'Cellamare', value: 'Cellamare' },
  { label: 'Cellara', value: 'Cellara' },
  { label: 'Cellarengo', value: 'Cellarengo' },
  { label: 'Cellatica', value: 'Cellatica' },
  { label: 'Celldömölk', value: 'Celldömölk' },
  { label: 'Celldömölki Járás', value: 'Celldömölki Járás' },
  { label: 'Celle', value: 'Celle' },
  { label: 'Celle Di Bulgheria', value: 'Celle Di Bulgheria' },
  { label: 'Celle Di San Vito', value: 'Celle Di San Vito' },
  { label: 'Celle Enomondo', value: 'Celle Enomondo' },
  { label: 'Celle Ligure', value: 'Celle Ligure' },
  { label: 'Celle-Lévescault', value: 'Celle-Lévescault' },
  { label: 'Celleno', value: 'Celleno' },
  { label: 'Cellera De Ter La', value: 'Cellera De Ter La' },
  { label: 'Cellere', value: 'Cellere' },
  { label: 'Celles', value: 'Celles' },
  { label: 'Celles-Sur-Belle', value: 'Celles-Sur-Belle' },
  { label: 'Celles-Sur-Durolle', value: 'Celles-Sur-Durolle' },
  { label: 'Cellettes', value: 'Cellettes' },
  { label: 'Cellieu', value: 'Cellieu' },
  { label: 'Cellino Attanasio', value: 'Cellino Attanasio' },
  { label: 'Cellino San Marco', value: 'Cellino San Marco' },
  { label: 'Cellio', value: 'Cellio' },
  { label: 'Cellole', value: 'Cellole' },
  { label: 'Cellore', value: 'Cellore' },
  { label: 'Cellorigo', value: 'Cellorigo' },
  { label: 'Celmira', value: 'Celmira' },
  { label: 'Čelopek', value: 'Čelopek' },
  { label: 'Celorico Da Beira', value: 'Celorico Da Beira' },
  { label: 'Celorico De Basto', value: 'Celorico De Basto' },
  { label: 'Celoron', value: 'Celoron' },
  { label: 'Celrà', value: 'Celrà' },
  { label: 'Celso Ramos', value: 'Celso Ramos' },
  { label: 'Çeltik', value: 'Çeltik' },
  { label: 'Çeltikçi', value: 'Çeltikçi' },
  { label: 'Cély', value: 'Cély' },
  { label: 'Cémaco', value: 'Cémaco' },
  { label: 'Cemaes Bay', value: 'Cemaes Bay' },
  { label: 'Cembra', value: 'Cembra' },
  { label: 'Cementeras Del Pital', value: 'Cementeras Del Pital' },
  { label: 'Cementon', value: 'Cementon' },
  { label: 'Čeminac', value: 'Čeminac' },
  { label: 'Çemişgezek', value: 'Çemişgezek' },
  { label: 'Cenac', value: 'Cenac' },
  { label: 'Cénac', value: 'Cénac' },
  { label: 'Cénac-Et-Saint-Julien', value: 'Cénac-Et-Saint-Julien' },
  { label: 'Cenad', value: 'Cenad' },
  { label: 'Cenade', value: 'Cenade' },
  { label: 'Cenadi', value: 'Cenadi' },
  { label: 'Cenaia', value: 'Cenaia' },
  { label: 'Cenate Di Sotto', value: 'Cenate Di Sotto' },
  { label: 'Cenate Sopra', value: 'Cenate Sopra' },
  { label: 'Cencenighe Agordino', value: 'Cencenighe Agordino' },
  { label: 'Cendea De Olza/Oltza Zendea', value: 'Cendea De Olza/Oltza Zendea' },
  { label: 'Cendejas De Enmedio', value: 'Cendejas De Enmedio' },
  { label: 'Cendejas De La Torre', value: 'Cendejas De La Torre' },
  { label: 'Cendon', value: 'Cendon' },
  { label: 'Cendras', value: 'Cendras' },
  { label: 'Cene', value: 'Cene' },
  { label: 'Cenei', value: 'Cenei' },
  { label: 'Cenes De La Vega', value: 'Cenes De La Vega' },
  { label: 'Ceneselli', value: 'Ceneselli' },
  { label: 'Cengio Alto', value: 'Cengio Alto' },
  { label: 'Cenicero', value: 'Cenicero' },
  { label: 'Cenicientos', value: 'Cenicientos' },
  { label: 'Cenizate', value: 'Cenizate' },
  { label: 'Cenlle', value: 'Cenlle' },
  { label: 'Cenobio Aguilar (La Trinidad)', value: 'Cenobio Aguilar (La Trinidad)' },
  { label: 'Cenobio Moreno', value: 'Cenobio Moreno' },
  { label: 'Cenon', value: 'Cenon' },
  { label: 'Cenon-Sur-Vienne', value: 'Cenon-Sur-Vienne' },
  { label: 'Cenotillo', value: 'Cenotillo' },
  { label: 'Čenta', value: 'Čenta' },
  { label: 'Centa San Nicolò', value: 'Centa San Nicolò' },
  { label: 'Centallo', value: 'Centallo' },
  { label: 'Centar', value: 'Centar' },
  { label: 'Centar Župa', value: 'Centar Župa' },
  { label: 'Centelles', value: 'Centelles' },
  { label: 'Centenario', value: 'Centenario' },
  { label: 'Centenário', value: 'Centenário' },
  { label: 'Centenário', value: 'Centenário' },
  { label: 'Centenário Do Sul', value: 'Centenário Do Sul' },
  { label: 'Centenary', value: 'Centenary' },
  { label: 'Centenary District', value: 'Centenary District' },
  { label: 'Centenary Heights', value: 'Centenary Heights' },
  { label: 'Centenera', value: 'Centenera' },
  { label: 'Centenera De Andaluz', value: 'Centenera De Andaluz' },
  { label: 'Centennial', value: 'Centennial' },
  { label: 'Centennial Park', value: 'Centennial Park' },
  { label: 'Centennial Park', value: 'Centennial Park' },
  { label: 'Center', value: 'Center' },
  { label: 'Center', value: 'Center' },
  { label: 'Center', value: 'Center' },
  { label: 'Center', value: 'Center' },
  { label: 'Center City', value: 'Center City' },
  { label: 'Center City', value: 'Center City' },
  { label: 'Center Harbor', value: 'Center Harbor' },
  { label: 'Center Hill', value: 'Center Hill' },
  { label: 'Center Line', value: 'Center Line' },
  { label: 'Center Moriches', value: 'Center Moriches' },
  { label: 'Center Point', value: 'Center Point' },
  { label: 'Center Point', value: 'Center Point' },
  { label: 'Centerburg', value: 'Centerburg' },
  { label: 'Centereach', value: 'Centereach' },
  { label: 'Centerfield', value: 'Centerfield' },
  { label: 'Centerport', value: 'Centerport' },
  { label: 'Centerton', value: 'Centerton' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centerville', value: 'Centerville' },
  { label: 'Centinarola', value: 'Centinarola' },
  { label: 'Centipede', value: 'Centipede' },
  { label: 'Cento', value: 'Cento' },
  { label: 'Centobuchi', value: 'Centobuchi' },
  { label: 'Centola', value: 'Centola' },
  { label: 'Centrache', value: 'Centrache' },
  { label: 'Central', value: 'Central' },
  { label: 'Central', value: 'Central' },
  { label: 'Central', value: 'Central' },
  { label: 'Central', value: 'Central' },
  { label: 'Central', value: 'Central' },
  { label: 'Central', value: 'Central' },
  { label: 'Central Aguirre', value: 'Central Aguirre' },
  { label: 'Central Baddibu', value: 'Central Baddibu' },
  { label: 'Central Bedfordshire', value: 'Central Bedfordshire' },
  { label: 'Central Bougainville', value: 'Central Bougainville' },
  { label: 'Central City', value: 'Central City' },
  { label: 'Central City', value: 'Central City' },
  { label: 'Central City', value: 'Central City' },
  { label: 'Central City', value: 'Central City' },
  { label: 'Central City', value: 'Central City' },
  { label: 'Central City', value: 'Central City' },
  { label: 'Central City', value: 'Central City' },
  { label: 'Central Coast', value: 'Central Coast' },
  { label: 'Central Coast Regional District', value: 'Central Coast Regional District' },
  { label: 'Central Darling', value: 'Central Darling' },
  { label: 'Central De Minas', value: 'Central De Minas' },
  { label: 'Central Delhi', value: 'Central Delhi' },
  { label: 'Central Desert', value: 'Central Desert' },
  { label: 'Central Do Maranhão', value: 'Central Do Maranhão' },
  { label: 'Central Down Town', value: 'Central Down Town' },
  { label: 'Central Falls', value: 'Central Falls' },
  { label: 'Central Garage', value: 'Central Garage' },
  { label: 'Central Gardens', value: 'Central Gardens' },
  { label: 'Central Goldfields', value: 'Central Goldfields' },
  { label: 'Central Gonja', value: 'Central Gonja' },
  { label: 'Central Heights-Midland City', value: 'Central Heights-Midland City' },
  { label: 'Central High', value: 'Central High' },
  { label: 'Central Highlands', value: 'Central Highlands' },
  { label: 'Central Highlands', value: 'Central Highlands' },
  { label: 'Central Islip', value: 'Central Islip' },
  { label: 'Central Karoo District Municipality', value: 'Central Karoo District Municipality' },
  { label: 'Central Park', value: 'Central Park' },
  { label: 'Central Point', value: 'Central Point' },
  { label: 'Central Port Antonio', value: 'Central Port Antonio' },
  { label: 'Central Square', value: 'Central Square' },
  { label: 'Central Valley', value: 'Central Valley' },
  { label: 'Central Valley (Historical)', value: 'Central Valley (Historical)' },
  { label: 'Central Village', value: 'Central Village' },
  { label: 'Central Waterford', value: 'Central Waterford' },
  { label: 'Centrale', value: 'Centrale' },
  { label: 'Centrale', value: 'Centrale' },
  { label: 'Centralia', value: 'Centralia' },
  { label: 'Centralia', value: 'Centralia' },
  { label: 'Centralia', value: 'Centralia' },
  { label: 'Centralina', value: 'Centralina' },
  { label: 'Centralniy', value: 'Centralniy' },
  { label: 'Centre', value: 'Centre' },
  { label: 'Centre County', value: 'Centre County' },
  { label: 'Centre De Flacq', value: 'Centre De Flacq' },
  { label: 'Centre Hall', value: 'Centre Hall' },
  { label: 'Centre-Du-Québec', value: 'Centre-Du-Québec' },
  { label: 'Centreville', value: 'Centreville' },
  { label: 'Centreville', value: 'Centreville' },
  { label: 'Centreville', value: 'Centreville' },
  { label: 'Centreville', value: 'Centreville' },
  { label: 'Centreville', value: 'Centreville' },
  { label: 'Centreville', value: 'Centreville' },
  { label: 'Centro', value: 'Centro' },
  { label: 'Centro', value: 'Centro' },
  { label: 'Centro De Arriba', value: 'Centro De Arriba' },
  { label: 'Centro De Readaptación Social', value: 'Centro De Readaptación Social' },
  { label: 'Centro De Readaptación Social', value: 'Centro De Readaptación Social' },
  { label: 'Centro De Readaptación Social Nuevo', value: 'Centro De Readaptación Social Nuevo' },
  { label: 'Centro Do Guilherme', value: 'Centro Do Guilherme' },
  { label: 'Centro Familiar La Soledad', value: 'Centro Familiar La Soledad' },
  { label: 'Centro Habana', value: 'Centro Habana' },
  { label: 'Centro Habitacional Burócratas De Guadalupe', value: 'Centro Habitacional Burócratas De Guadalupe' },
  { label: 'Centro Novo Do Maranhão', value: 'Centro Novo Do Maranhão' },
  { label: 'Centro Urbano', value: 'Centro Urbano' },
  { label: 'Centurion', value: 'Centurion' },
  { label: 'Centuripe', value: 'Centuripe' },
  { label: 'Century', value: 'Century' },
  { label: 'Century City', value: 'Century City' },
  { label: 'Cepagatti', value: 'Cepagatti' },
  { label: 'Ceparana-Carpena', value: 'Ceparana-Carpena' },
  { label: 'Cepari', value: 'Cepari' },
  { label: 'Ceparii Pământeni', value: 'Ceparii Pământeni' },
  { label: 'Cepeda', value: 'Cepeda' },
  { label: 'Cepeda', value: 'Cepeda' },
  { label: 'Cepeda La Mora', value: 'Cepeda La Mora' },
  { label: 'Ceper', value: 'Ceper' },
  { label: 'Cépet', value: 'Cépet' },
  { label: 'Čepin', value: 'Čepin' },
  { label: 'Cepina', value: 'Cepina' },
  { label: 'Cepitá', value: 'Cepitá' },
  { label: 'Cepleniţa', value: 'Cepleniţa' },
  { label: 'Cepoy', value: 'Cepoy' },
  { label: 'Ceppaloni', value: 'Ceppaloni' },
  { label: 'Ceppo Morelli', value: 'Ceppo Morelli' },
  { label: 'Ceprano', value: 'Ceprano' },
  { label: 'Ceptura De Jos', value: 'Ceptura De Jos' },
  { label: 'Ceptura De Sus', value: 'Ceptura De Sus' },
  { label: 'Cepu', value: 'Cepu' },
  { label: 'Cerami', value: 'Cerami' },
  { label: 'Ceramida-Pellegrina', value: 'Ceramida-Pellegrina' },
  { label: 'Ceranesi', value: 'Ceranesi' },
  { label: 'Cerano', value: 'Cerano' },
  { label: 'Cerano', value: 'Cerano' },
  { label: 'Cerano Dintelvi', value: 'Cerano Dintelvi' },
  { label: 'Ceranova', value: 'Ceranova' },
  { label: 'Ceranów', value: 'Ceranów' },
  { label: 'Cérans-Foulletourte', value: 'Cérans-Foulletourte' },
  { label: 'Ceraso', value: 'Ceraso' },
  { label: 'Cerasolo', value: 'Cerasolo' },
  { label: 'Ceraşu', value: 'Ceraşu' },
  { label: 'Cerăt', value: 'Cerăt' },
  { label: 'Cerbaia', value: 'Cerbaia' },
  { label: 'Cerbăl', value: 'Cerbăl' },
  { label: 'Cerbara', value: 'Cerbara' },
  { label: 'Cerbón', value: 'Cerbón' },
  { label: 'Cerca La Source', value: 'Cerca La Source' },
  { label: 'Cercado Abajo', value: 'Cercado Abajo' },
  { label: 'Cercal', value: 'Cercal' },
  { label: 'Cercal', value: 'Cercal' },
  { label: 'Čerčany', value: 'Čerčany' },
  { label: 'Cerceda', value: 'Cerceda' },
  { label: 'Cercedilla', value: 'Cercedilla' },
  { label: 'Cercemaggiore', value: 'Cercemaggiore' },
  { label: 'Cercenasco', value: 'Cercenasco' },
  { label: 'Cercepiccola', value: 'Cercepiccola' },
  { label: 'Cerchezu', value: 'Cerchezu' },
  { label: 'Cerchiara Di Calabria', value: 'Cerchiara Di Calabria' },
  { label: 'Cerchio', value: 'Cerchio' },
  { label: 'Cercino', value: 'Cercino' },
  { label: 'Cercivento', value: 'Cercivento' },
  { label: 'Cercle D’Abeïbara', value: 'Cercle D’Abeïbara' },
  { label: 'Cercle De Bourem', value: 'Cercle De Bourem' },
  { label: 'Cercle De Goundam', value: 'Cercle De Goundam' },
  { label: 'Cercle De San', value: 'Cercle De San' },
  { label: 'Cercola', value: 'Cercola' },
  { label: 'Cercoux', value: 'Cercoux' },
  { label: 'Cercs', value: 'Cercs' },
  { label: 'Cercy-La-Tour', value: 'Cercy-La-Tour' },
  { label: 'Cerda', value: 'Cerda' },
  { label: 'Cerdà', value: 'Cerdà' },
  { label: 'Cerdac', value: 'Cerdac' },
  { label: 'Cerdanyola Del Vallès', value: 'Cerdanyola Del Vallès' },
  { label: 'Cerdedo', value: 'Cerdedo' },
  { label: 'Cerdeira', value: 'Cerdeira' },
  { label: 'Cerdido', value: 'Cerdido' },
  { label: 'Cerdon', value: 'Cerdon' },
  { label: 'Cerea', value: 'Cerea' },
  { label: 'Cereceda De La Sierra', value: 'Cereceda De La Sierra' },
  { label: 'Cerecinos De Campos', value: 'Cerecinos De Campos' },
  { label: 'Cerecinos Del Carrizal', value: 'Cerecinos Del Carrizal' },
  { label: 'Cereda-Cozza Cornedo', value: 'Cereda-Cozza Cornedo' },
  { label: 'Ceredo', value: 'Ceredo' },
  { label: 'Ceregnano', value: 'Ceregnano' },
  { label: 'Cerejeiras', value: 'Cerejeiras' },
  { label: 'Cerejo', value: 'Cerejo' },
  { label: 'Cerelles', value: 'Cerelles' },
  { label: 'Cerello-Battuello', value: 'Cerello-Battuello' },
  { label: 'Cérences', value: 'Cérences' },
  { label: 'Cerenzia', value: 'Cerenzia' },
  { label: 'Ceres', value: 'Ceres' },
  { label: 'Ceres', value: 'Ceres' },
  { label: 'Ceres', value: 'Ceres' },
  { label: 'Ceres', value: 'Ceres' },
  { label: 'Ceres', value: 'Ceres' },
  { label: 'Ceresane-Curanuova', value: 'Ceresane-Curanuova' },
  { label: 'Ceresara', value: 'Ceresara' },
  { label: 'Cerese', value: 'Cerese' },
  { label: 'Cereseto', value: 'Cereseto' },
  { label: 'Cereso Del Hongo', value: 'Cereso Del Hongo' },
  { label: 'Cereso Nuevo', value: 'Cereso Nuevo' },
  { label: 'Ceresole Alba', value: 'Ceresole Alba' },
  { label: 'Ceresole Reale', value: 'Ceresole Reale' },
  { label: 'Céreste', value: 'Céreste' },
  { label: 'Ceret', value: 'Ceret' },
  { label: 'Cereté', value: 'Cereté' },
  { label: 'Cerete Alto', value: 'Cerete Alto' },
  { label: 'Ceretto Lomellina', value: 'Ceretto Lomellina' },
  { label: 'Cerezal De Peñahorcada', value: 'Cerezal De Peñahorcada' },
  { label: 'Cerezo', value: 'Cerezo' },
  { label: 'Cerezo De Abajo', value: 'Cerezo De Abajo' },
  { label: 'Cerezo De Arriba', value: 'Cerezo De Arriba' },
  { label: 'Cerezo De Río Tirón', value: 'Cerezo De Río Tirón' },
  { label: 'Cerfignano', value: 'Cerfignano' },
  { label: 'Cerfontaine', value: 'Cerfontaine' },
  { label: 'Cergău Mare', value: 'Cergău Mare' },
  { label: 'Cergău Mic', value: 'Cergău Mic' },
  { label: 'Cerghid', value: 'Cerghid' },
  { label: 'Cerghizel', value: 'Cerghizel' },
  { label: 'Cergnago', value: 'Cergnago' },
  { label: 'Cergy', value: 'Cergy' },
  { label: 'Cergy-Pontoise', value: 'Cergy-Pontoise' },
  { label: 'Cerhenice', value: 'Cerhenice' },
  { label: 'Ceriale', value: 'Ceriale' },
  { label: 'Ceriana', value: 'Ceriana' },
  { label: 'Ceriano Laghetto', value: 'Ceriano Laghetto' },
  { label: 'Cerić', value: 'Cerić' },
  { label: 'Cerignale', value: 'Cerignale' },
  { label: 'Cerignola', value: 'Cerignola' },
  { label: 'Cérilly', value: 'Cérilly' },
  { label: 'Cerinza', value: 'Cerinza' },
  { label: 'Cerisano', value: 'Cerisano' },
  { label: 'Cerisy-La-Salle', value: 'Cerisy-La-Salle' },
  { label: 'Cerizay', value: 'Cerizay' },
  { label: 'Çerkeş', value: 'Çerkeş' },
  { label: 'Çerkezköy', value: 'Çerkezköy' },
  { label: 'Cerklje Na Gorenjskem', value: 'Cerklje Na Gorenjskem' },
  { label: 'Cerknica', value: 'Cerknica' },
  { label: 'Cerkno', value: 'Cerkno' },
  { label: 'Cerkvenjak', value: 'Cerkvenjak' },
  { label: 'Cerkwica', value: 'Cerkwica' },
  { label: 'Cerlongo', value: 'Cerlongo' },
  { label: 'Cermei', value: 'Cermei' },
  { label: 'Cermenate', value: 'Cermenate' },
  { label: 'Cermeño', value: 'Cermeño' },
  { label: 'Cermes', value: 'Cermes' },
  { label: 'Cermignano', value: 'Cermignano' },
  { label: 'Çermik', value: 'Çermik' },
  { label: 'Cerna', value: 'Cerna' },
  { label: 'Cerna', value: 'Cerna' },
  { label: 'Černá Hora', value: 'Černá Hora' },
  { label: 'Cernadilla', value: 'Cernadilla' },
  { label: 'Cernat', value: 'Cernat' },
  { label: 'Cernăteşti', value: 'Cernăteşti' },
  { label: 'Cernăteşti', value: 'Cernăteşti' },
  { label: 'Cernavodă', value: 'Cernavodă' },
  { label: 'Cernay', value: 'Cernay' },
  { label: 'Cernay-La-Ville', value: 'Cernay-La-Ville' },
  { label: 'Cernay-Lès-Reims', value: 'Cernay-Lès-Reims' },
  { label: 'Černčice', value: 'Černčice' },
  { label: 'Černelavci', value: 'Černelavci' },
  { label: 'Cernele', value: 'Cernele' },
  { label: 'Cerneşti', value: 'Cerneşti' },
  { label: 'Cerneți', value: 'Cerneți' },
  { label: 'Cernetu', value: 'Cernetu' },
  { label: 'Cernica', value: 'Cernica' },
  { label: 'Cernier', value: 'Cernier' },
  { label: 'Cernik', value: 'Cernik' },
  { label: 'Cernik', value: 'Cernik' },
  { label: 'Černilov', value: 'Černilov' },
  { label: 'Cernişoara', value: 'Cernişoara' },
  { label: 'Cernobbio', value: 'Cernobbio' },
  { label: 'Černošice', value: 'Černošice' },
  { label: 'Černošín', value: 'Černošín' },
  { label: 'Černovice', value: 'Černovice' },
  { label: 'Černožice', value: 'Černožice' },
  { label: 'Cernu', value: 'Cernu' },
  { label: 'Cernusco Lombardone', value: 'Cernusco Lombardone' },
  { label: 'Cernusco Sul Naviglio', value: 'Cernusco Sul Naviglio' },
  { label: 'Cerny', value: 'Cerny' },
  { label: 'Černý Most', value: 'Černý Most' },
  { label: 'Cerocahui', value: 'Cerocahui' },
  { label: 'Cerollera La', value: 'Cerollera La' },
  { label: 'Cérons', value: 'Cérons' },
  { label: 'Cerqueira César', value: 'Cerqueira César' },
  { label: 'Cerquilho', value: 'Cerquilho' },
  { label: 'Cerquotti-Madonna Del Piano', value: 'Cerquotti-Madonna Del Piano' },
  { label: 'Cerralbo', value: 'Cerralbo' },
  { label: 'Cerralbos Los', value: 'Cerralbos Los' },
  { label: 'Cerralvo', value: 'Cerralvo' },
  { label: 'Cerratina', value: 'Cerratina' },
  { label: 'Cerratón De Juarros', value: 'Cerratón De Juarros' },
  { label: 'Cerreto Castello', value: 'Cerreto Castello' },
  { label: 'Cerreto Dasti', value: 'Cerreto Dasti' },
  { label: 'Cerreto Desi', value: 'Cerreto Desi' },
  { label: 'Cerreto Di Spoleto', value: 'Cerreto Di Spoleto' },
  { label: 'Cerreto Grue', value: 'Cerreto Grue' },
  { label: 'Cerreto Guidi', value: 'Cerreto Guidi' },
  { label: 'Cerreto Langhe', value: 'Cerreto Langhe' },
  { label: 'Cerreto Laziale', value: 'Cerreto Laziale' },
  { label: 'Cerreto Sannita', value: 'Cerreto Sannita' },
  { label: 'Cerrillos', value: 'Cerrillos' },
  { label: 'Cerrina', value: 'Cerrina' },
  { label: 'Cerrione', value: 'Cerrione' },
  { label: 'Cerrito', value: 'Cerrito' },
  { label: 'Cerrito', value: 'Cerrito' },
  { label: 'Cerrito', value: 'Cerrito' },
  { label: 'Cerrito', value: 'Cerrito' },
  { label: 'Cerrito De Buenos Aires', value: 'Cerrito De Buenos Aires' },
  { label: 'Cerrito De Gasca', value: 'Cerrito De Gasca' },
  { label: 'Cerrito De Jaral', value: 'Cerrito De Jaral' },
  { label: 'Cerrito De La Cruz', value: 'Cerrito De La Cruz' },
  { label: 'Cerrito De Tierra', value: 'Cerrito De Tierra' },
  { label: 'Cerrito De Zavala', value: 'Cerrito De Zavala' },
  { label: 'Cerritos', value: 'Cerritos' },
  { label: 'Cerritos', value: 'Cerritos' },
  { label: 'Cerritos', value: 'Cerritos' },
  { label: 'Cerritos', value: 'Cerritos' },
  { label: 'Cerritos', value: 'Cerritos' },
  { label: 'Cerritos De Cárdenas', value: 'Cerritos De Cárdenas' },
  { label: 'Cerritos Del Pilar', value: 'Cerritos Del Pilar' },
  { label: 'Cerro', value: 'Cerro' },
  { label: 'Cerro', value: 'Cerro' },
  { label: 'Cerro Agudo', value: 'Cerro Agudo' },
  { label: 'Cerro Al Lambro', value: 'Cerro Al Lambro' },
  { label: 'Cerro Al Volturno', value: 'Cerro Al Volturno' },
  { label: 'Cerro Armadillo Grande', value: 'Cerro Armadillo Grande' },
  { label: 'Cerro Azul', value: 'Cerro Azul' },
  { label: 'Cerro Azul', value: 'Cerro Azul' },
  { label: 'Cerro Azul', value: 'Cerro Azul' },
  { label: 'Cerro Azul', value: 'Cerro Azul' },
  { label: 'Cerro Banco', value: 'Cerro Banco' },
  { label: 'Cerro Blanco', value: 'Cerro Blanco' },
  { label: 'Cerro Branco', value: 'Cerro Branco' },
  { label: 'Cerro Cama', value: 'Cerro Cama' },
  { label: 'Cerro Caña', value: 'Cerro Caña' },
  { label: 'Cerro Colorado', value: 'Cerro Colorado' },
  { label: 'Cerro Colorado De Ocampo', value: 'Cerro Colorado De Ocampo' },
  { label: 'Cerro Corá', value: 'Cerro Corá' },
  { label: 'Cerro Corá', value: 'Cerro Corá' },
  { label: 'Cerro De Andévalo El', value: 'Cerro De Andévalo El' },
  { label: 'Cerro De La Cruz', value: 'Cerro De La Cruz' },
  { label: 'Cerro De Ortega', value: 'Cerro De Ortega' },
  { label: 'Cerro De Pasco', value: 'Cerro De Pasco' },
  { label: 'Cerro De Piedra', value: 'Cerro De Piedra' },
  { label: 'Cerro De San Antonio', value: 'Cerro De San Antonio' },
  { label: 'Cerro De San Francisco', value: 'Cerro De San Francisco' },
  { label: 'Cerro De San Pedro', value: 'Cerro De San Pedro' },
  { label: 'Cerro Del Aire', value: 'Cerro Del Aire' },
  { label: 'Cerro Del Murciélago', value: 'Cerro Del Murciélago' },
  { label: 'Cerro El', value: 'Cerro El' },
  { label: 'Cerro Gordo', value: 'Cerro Gordo' },
  { label: 'Cerro Gordo', value: 'Cerro Gordo' },
  { label: 'Cerro Gordo', value: 'Cerro Gordo' },
  { label: 'Cerro Gordo', value: 'Cerro Gordo' },
  { label: 'Cerro Gordo', value: 'Cerro Gordo' },
  { label: 'Cerro Gordo County', value: 'Cerro Gordo County' },
  { label: 'Cerro Grande', value: 'Cerro Grande' },
  { label: 'Cerro Grande', value: 'Cerro Grande' },
  { label: 'Cerro Grande', value: 'Cerro Grande' },
  { label: 'Cerro Grande Do Sul', value: 'Cerro Grande Do Sul' },
  { label: 'Cerro Guzmán', value: 'Cerro Guzmán' },
  { label: 'Cerro La Calera', value: 'Cerro La Calera' },
  { label: 'Cerro Largo', value: 'Cerro Largo' },
  { label: 'Cerro Largo', value: 'Cerro Largo' },
  { label: 'Cerro Las Iguanas', value: 'Cerro Las Iguanas' },
  { label: 'Cerro Maggiore', value: 'Cerro Maggiore' },
  { label: 'Cerro Navia', value: 'Cerro Navia' },
  { label: 'Cerro Negro', value: 'Cerro Negro' },
  { label: 'Cerro Plata', value: 'Cerro Plata' },
  { label: 'Cerro Punta', value: 'Cerro Punta' },
  { label: 'Cerro Quemado', value: 'Cerro Quemado' },
  { label: 'Cerro Santa Fe', value: 'Cerro Santa Fe' },
  { label: 'Cerro Tanaro', value: 'Cerro Tanaro' },
  { label: 'Cerro Veronese', value: 'Cerro Veronese' },
  { label: 'Cerro Viejo', value: 'Cerro Viejo' },
  { label: 'Cers', value: 'Cers' },
  { label: 'Cersosimo', value: 'Cersosimo' },
  { label: 'Certaldo', value: 'Certaldo' },
  { label: 'Cértegui', value: 'Cértegui' },
  { label: 'Certeju De Sus', value: 'Certeju De Sus' },
  { label: 'Cerţeşti', value: 'Cerţeşti' },
  { label: 'Certeze', value: 'Certeze' },
  { label: 'Certines', value: 'Certines' },
  { label: 'Certosa', value: 'Certosa' },
  { label: 'Certosa Di Pavia', value: 'Certosa Di Pavia' },
  { label: 'Ceru-Băcăinţi', value: 'Ceru-Băcăinţi' },
  { label: 'Cerva', value: 'Cerva' },
  { label: 'Cervantes', value: 'Cervantes' },
  { label: 'Cervantes', value: 'Cervantes' },
  { label: 'Cervantes', value: 'Cervantes' },
  { label: 'Cervantes', value: 'Cervantes' },
  { label: 'Cervantes', value: 'Cervantes' },
  { label: 'Cervantes', value: 'Cervantes' },
  { label: 'Cervantes', value: 'Cervantes' },
  { label: 'Cervantes', value: 'Cervantes' },
  { label: 'Cervara Di Roma', value: 'Cervara Di Roma' },
  { label: 'Cervarese Santa Croce', value: 'Cervarese Santa Croce' },
  { label: 'Cervaro', value: 'Cervaro' },
  { label: 'Cervasca', value: 'Cervasca' },
  { label: 'Cervatos De La Cueza', value: 'Cervatos De La Cueza' },
  { label: 'Cervatto', value: 'Cervatto' },
  { label: 'Cervelló', value: 'Cervelló' },
  { label: 'Červená Voda', value: 'Červená Voda' },
  { label: 'Červené Pečky', value: 'Červené Pečky' },
  { label: 'Cervenia', value: 'Cervenia' },
  { label: 'Červenka', value: 'Červenka' },
  { label: 'Cerveno', value: 'Cerveno' },
  { label: 'Červený Kostelec', value: 'Červený Kostelec' },
  { label: 'Cervera', value: 'Cervera' },
  { label: 'Cervera De Buitrago', value: 'Cervera De Buitrago' },
  { label: 'Cervera De La Cañada', value: 'Cervera De La Cañada' },
  { label: 'Cervera De La Marenda', value: 'Cervera De La Marenda' },
  { label: 'Cervera De Los Montes', value: 'Cervera De Los Montes' },
  { label: 'Cervera De Pisuerga', value: 'Cervera De Pisuerga' },
  { label: 'Cervera Del Llano', value: 'Cervera Del Llano' },
  { label: 'Cervera Del Maestre', value: 'Cervera Del Maestre' },
  { label: 'Cervera Del Río Alhama', value: 'Cervera Del Río Alhama' },
  { label: 'Cervere', value: 'Cervere' },
  { label: 'Cerveruela', value: 'Cerveruela' },
  { label: 'Cervesina', value: 'Cervesina' },
  { label: 'Cerveteri', value: 'Cerveteri' },
  { label: 'Cervia', value: 'Cervia' },
  { label: 'Cervià De Les Garrigues', value: 'Cervià De Les Garrigues' },
  { label: 'Cervià De Ter', value: 'Cervià De Ter' },
  { label: 'Cervicati', value: 'Cervicati' },
  { label: 'Cervignano Dadda', value: 'Cervignano Dadda' },
  { label: 'Cervignano Del Friuli', value: 'Cervignano Del Friuli' },
  { label: 'Cervillego De La Cruz', value: 'Cervillego De La Cruz' },
  { label: 'Cervinara', value: 'Cervinara' },
  { label: 'Cervino', value: 'Cervino' },
  { label: 'Cervione', value: 'Cervione' },
  { label: 'Cervo', value: 'Cervo' },
  { label: 'Cervo', value: 'Cervo' },
  { label: 'Cerza', value: 'Cerza' },
  { label: 'Cerzeto', value: 'Cerzeto' },
  { label: 'Cesa', value: 'Cesa' },
  { label: 'Cesa', value: 'Cesa' },
  { label: 'Cesana Brianza', value: 'Cesana Brianza' },
  { label: 'Cesana Torinese', value: 'Cesana Torinese' },
  { label: 'Cesano', value: 'Cesano' },
  { label: 'Cesano Boscone', value: 'Cesano Boscone' },
  { label: 'Cesano Maderno', value: 'Cesano Maderno' },
  { label: 'César Chávez', value: 'César Chávez' },
  { label: 'Cesara', value: 'Cesara' },
  { label: 'Cesário Lange', value: 'Cesário Lange' },
  { label: 'Cesarò', value: 'Cesarò' },
  { label: 'Cesarolo', value: 'Cesarolo' },
  { label: 'Cesate', value: 'Cesate' },
  { label: 'Cesena', value: 'Cesena' },
  { label: 'Cesenatico', value: 'Cesenatico' },
  { label: 'Cesinali', value: 'Cesinali' },
  { label: 'Češinovo', value: 'Češinovo' },
  { label: 'Cesio', value: 'Cesio' },
  { label: 'Cesiomaggiore', value: 'Cesiomaggiore' },
  { label: 'Cēsis', value: 'Cēsis' },
  { label: 'Česká Kamenice', value: 'Česká Kamenice' },
  { label: 'Česká Lípa', value: 'Česká Lípa' },
  { label: 'Česká Skalice', value: 'Česká Skalice' },
  { label: 'Česká Třebová', value: 'Česká Třebová' },
  { label: 'Česká Ves', value: 'Česká Ves' },
  { label: 'České Budějovice', value: 'České Budějovice' },
  { label: 'České Meziříčí', value: 'České Meziříčí' },
  { label: 'České Velenice', value: 'České Velenice' },
  { label: 'Český Brod', value: 'Český Brod' },
  { label: 'Český Dub', value: 'Český Dub' },
  { label: 'Český Krumlov', value: 'Český Krumlov' },
  { label: 'Český Rudolec', value: 'Český Rudolec' },
  { label: 'Český Těšín', value: 'Český Těšín' },
  { label: 'Çeşme', value: 'Çeşme' },
  { label: 'Cespedosa De Tormes', value: 'Cespedosa De Tormes' },
  { label: 'Cessalto', value: 'Cessalto' },
  { label: 'Cessaniti', value: 'Cessaniti' },
  { label: 'Cessapalombo', value: 'Cessapalombo' },
  { label: 'Cessenon-Sur-Orb', value: 'Cessenon-Sur-Orb' },
  { label: 'Cessieu', value: 'Cessieu' },
  { label: 'Cessnock', value: 'Cessnock' },
  { label: 'Cessole', value: 'Cessole' },
  { label: 'Cesson', value: 'Cesson' },
  { label: 'Cesson-Sévigné', value: 'Cesson-Sévigné' },
  { label: 'Cessy', value: 'Cessy' },
  { label: 'Cestas', value: 'Cestas' },
  { label: 'Čestereg', value: 'Čestereg' },
  { label: 'Cestica', value: 'Cestica' },
  { label: 'Cestos City', value: 'Cestos City' },
  { label: 'Cesuras', value: 'Cesuras' },
  { label: 'Cesvaine', value: 'Cesvaine' },
  { label: 'Cetara', value: 'Cetara' },
  { label: 'Cetariu', value: 'Cetariu' },
  { label: 'Cetate', value: 'Cetate' },
  { label: 'Cetatea', value: 'Cetatea' },
  { label: 'Cetatea De Baltă', value: 'Cetatea De Baltă' },
  { label: 'Cetățele', value: 'Cetățele' },
  { label: 'Cetăţeni', value: 'Cetăţeni' },
  { label: 'Cetina', value: 'Cetina' },
  { label: 'Cetinje', value: 'Cetinje' },
  { label: 'Ceto', value: 'Ceto' },
  { label: 'Ceton', value: 'Ceton' },
  { label: 'Cetona', value: 'Cetona' },
  { label: 'Cetraro', value: 'Cetraro' },
  { label: 'Cetraro Marina', value: 'Cetraro Marina' },
  { label: 'Cetronia', value: 'Cetronia' },
  { label: 'Céu Azul', value: 'Céu Azul' },
  { label: 'Ceuaș', value: 'Ceuaș' },
  { label: 'Ceuașu De Câmpie', value: 'Ceuașu De Câmpie' },
  { label: 'Ceuta', value: 'Ceuta' },
  { label: 'Ceuti', value: 'Ceuti' },
  { label: 'Ceva', value: 'Ceva' },
  { label: 'Cevico De La Torre', value: 'Cevico De La Torre' },
  { label: 'Cevico Navero', value: 'Cevico Navero' },
  { label: 'Cevicos', value: 'Cevicos' },
  { label: 'Cevio', value: 'Cevio' },
  { label: 'Cevo', value: 'Cevo' },
  { label: 'Cewice', value: 'Cewice' },
  { label: 'Ceyhan', value: 'Ceyhan' },
  { label: 'Ceylanpınar', value: 'Ceylanpınar' },
  { label: 'Ceyranbatan', value: 'Ceyranbatan' },
  { label: 'Ceyrat', value: 'Ceyrat' },
  { label: 'Ceyreste', value: 'Ceyreste' },
  { label: 'Ceyzériat', value: 'Ceyzériat' },
  { label: 'Cézac', value: 'Cézac' },
  { label: 'Cezarina', value: 'Cezarina' },
  { label: 'Cezieni', value: 'Cezieni' },
  { label: 'Cézy', value: 'Cézy' },
  { label: 'Chã De Alegria', value: 'Chã De Alegria' },
  { label: 'Chã Grande', value: 'Chã Grande' },
  { label: 'Chã Preta', value: 'Chã Preta' },
  { label: 'Chaadayevka', value: 'Chaadayevka' },
  { label: 'Chaah', value: 'Chaah' },
  { label: 'Chaam', value: 'Chaam' },
  { label: 'Cha-Am', value: 'Cha-Am' },
  { label: 'Chabahar', value: 'Chabahar' },
  { label: 'Chabaihu', value: 'Chabaihu' },
  { label: 'Chabanais', value: 'Chabanais' },
  { label: 'Chabany', value: 'Chabany' },
  { label: 'Chabařovice', value: 'Chabařovice' },
  { label: 'Chabet El Ameur', value: 'Chabet El Ameur' },
  { label: 'Chabeuil', value: 'Chabeuil' },
  { label: 'Chablé', value: 'Chablé' },
  { label: 'Chablekal', value: 'Chablekal' },
  { label: 'Chablis', value: 'Chablis' },
  { label: 'Chaboksar', value: 'Chaboksar' },
  { label: 'Châbons', value: 'Châbons' },
  { label: 'Chabreloche', value: 'Chabreloche' },
  { label: 'Chabris', value: 'Chabris' },
  { label: 'Chabua', value: 'Chabua' },
  { label: 'Chacala', value: 'Chacala' },
  { label: 'Chacalapa', value: 'Chacalapa' },
  { label: 'Chacalapa', value: 'Chacalapa' },
  { label: 'Chacaljocóm', value: 'Chacaljocóm' },
  { label: 'Chacaltianguis', value: 'Chacaltianguis' },
  { label: 'Chacao', value: 'Chacao' },
  { label: 'Chácara', value: 'Chácara' },
  { label: 'Chacarita', value: 'Chacarita' },
  { label: 'Chacé', value: 'Chacé' },
  { label: 'Chacewater', value: 'Chacewater' },
  { label: 'Chachagüí', value: 'Chachagüí' },
  { label: 'Chachahuantla', value: 'Chachahuantla' },
  { label: 'Chachahuatlán', value: 'Chachahuatlán' },
  { label: 'Chachapoyas', value: 'Chachapoyas' },
  { label: 'Chachersk', value: 'Chachersk' },
  { label: 'Chacherski Rayon', value: 'Chacherski Rayon' },
  { label: 'Chachoengsao', value: 'Chachoengsao' },
  { label: 'Chackbay', value: 'Chackbay' },
  { label: 'Chacoma', value: 'Chacoma' },
  { label: 'Chacsinkin', value: 'Chacsinkin' },
  { label: 'Chadan', value: 'Chadan' },
  { label: 'Chadbourn', value: 'Chadbourn' },
  { label: 'Chadegan', value: 'Chadegan' },
  { label: 'Chadian', value: 'Chadian' },
  { label: 'Chadiza', value: 'Chadiza' },
  { label: 'Chadrac', value: 'Chadrac' },
  { label: 'Chadron', value: 'Chadron' },
  { label: 'Chadstone', value: 'Chadstone' },
  { label: 'Chadwell Heath', value: 'Chadwell Heath' },
  { label: 'Chadwell St Mary', value: 'Chadwell St Mary' },
  { label: 'Chadwicks', value: 'Chadwicks' },
  { label: 'Chaeryŏng-Ŭp', value: 'Chaeryŏng-Ŭp' },
  { label: 'Chaf And Chamkhaleh', value: 'Chaf And Chamkhaleh' },
  { label: 'Chaffee', value: 'Chaffee' },
  { label: 'Chaffee County', value: 'Chaffee County' },
  { label: 'Chafford Hundred', value: 'Chafford Hundred' },
  { label: 'Chāgai District', value: 'Chāgai District' },
  { label: 'Chagarcía Medianero', value: 'Chagarcía Medianero' },
  { label: 'Chagford', value: 'Chagford' },
  { label: 'Chagny', value: 'Chagny' },
  { label: 'Chagoda', value: 'Chagoda' },
  { label: 'Chagodoshchenskiy Rayon', value: 'Chagodoshchenskiy Rayon' },
  { label: 'Chagrin Falls', value: 'Chagrin Falls' },
  { label: 'Chaguanas', value: 'Chaguanas' },
  { label: 'Chaguaní', value: 'Chaguaní' },
  { label: 'Chahal Guatemala', value: 'Chahal Guatemala' },
  { label: 'Chahar Bolagh', value: 'Chahar Bolagh' },
  { label: 'Chahār Burj', value: 'Chahār Burj' },
  { label: 'Chahar Dangeh', value: 'Chahar Dangeh' },
  { label: 'Chahuite', value: 'Chahuite' },
  { label: 'Chahuites', value: 'Chahuites' },
  { label: 'Chai Badan', value: 'Chai Badan' },
  { label: 'Chai Buri', value: 'Chai Buri' },
  { label: 'Chai Nat', value: 'Chai Nat' },
  { label: 'Chai Prakan', value: 'Chai Prakan' },
  { label: 'Chaibasa', value: 'Chaibasa' },
  { label: 'Chaïdári', value: 'Chaïdári' },
  { label: 'Chaihe', value: 'Chaihe' },
  { label: 'Chail', value: 'Chail' },
  { label: 'Chaillac', value: 'Chaillac' },
  { label: 'Chailland', value: 'Chailland' },
  { label: 'Chaillé-Les-Marais', value: 'Chaillé-Les-Marais' },
  { label: 'Chailles', value: 'Chailles' },
  { label: 'Chaillevette', value: 'Chaillevette' },
  { label: 'Chailly-En-Bière', value: 'Chailly-En-Bière' },
  { label: 'Chailly-En-Brie', value: 'Chailly-En-Brie' },
  { label: 'Chain Valley Bay', value: 'Chain Valley Bay' },
  { label: 'Chaingy', value: 'Chaingy' },
  { label: 'Chairóneia', value: 'Chairóneia' },
  { label: 'Chaitén', value: 'Chaitén' },
  { label: 'Chaiya', value: 'Chaiya' },
  { label: 'Chaiyaphum', value: 'Chaiyaphum' },
  { label: 'Chaiyo', value: 'Chaiyo' },
  { label: 'Chajarí', value: 'Chajarí' },
  { label: 'Chajul', value: 'Chajul' },
  { label: 'Chak', value: 'Chak' },
  { label: 'Chak Azam Saffo', value: 'Chak Azam Saffo' },
  { label: 'Chak Jhumra', value: 'Chak Jhumra' },
  { label: 'Chak One Hundred Twenty Nine Left', value: 'Chak One Hundred Twenty Nine Left' },
  { label: 'Chak Thirty-One -Eleven Left', value: 'Chak Thirty-One -Eleven Left' },
  { label: 'Chak Two Hundred Forty-Nine Tda', value: 'Chak Two Hundred Forty-Nine Tda' },
  { label: 'Chakan', value: 'Chakan' },
  { label: 'Chakaneh', value: 'Chakaneh' },
  { label: 'Chakapara', value: 'Chakapara' },
  { label: 'Chakari', value: 'Chakari' },
  { label: 'Chakdaha', value: 'Chakdaha' },
  { label: 'Chake Chake', value: 'Chake Chake' },
  { label: 'Chakia', value: 'Chakia' },
  { label: 'Chakia', value: 'Chakia' },
  { label: 'Chaklasi', value: 'Chaklasi' },
  { label: 'Chakradharpur', value: 'Chakradharpur' },
  { label: 'Chakrata', value: 'Chakrata' },
  { label: 'Chaksu', value: 'Chaksu' },
  { label: 'Chakulia', value: 'Chakulia' },
  { label: 'Chakur', value: 'Chakur' },
  { label: 'Chakvi', value: 'Chakvi' },
  { label: 'Chakwal', value: 'Chakwal' },
  { label: 'Chal Boqa', value: 'Chal Boqa' },
  { label: 'Chala', value: 'Chala' },
  { label: 'Chalabre', value: 'Chalabre' },
  { label: 'Chalahuiyapa', value: 'Chalahuiyapa' },
  { label: 'Chalais', value: 'Chalais' },
  { label: 'Chalais', value: 'Chalais' },
  { label: 'Chalala', value: 'Chalala' },
  { label: 'Chalam', value: 'Chalam' },
  { label: 'Chalamera', value: 'Chalamera' },
  { label: 'Chalamont', value: 'Chalamont' },
  { label: 'Chalampé', value: 'Chalampé' },
  { label: 'Chalán', value: 'Chalán' },
  { label: 'Chalan Chulan', value: 'Chalan Chulan' },
  { label: 'Chalandrítsa', value: 'Chalandrítsa' },
  { label: 'Chalástra', value: 'Chalástra' },
  { label: 'Chalatenango', value: 'Chalatenango' },
  { label: 'Chalcatzingo', value: 'Chalcatzingo' },
  { label: 'Chalchihuapan', value: 'Chalchihuapan' },
  { label: 'Chalchihuapan', value: 'Chalchihuapan' },
  { label: 'Chalchihuitán', value: 'Chalchihuitán' },
  { label: 'Chalchihuites', value: 'Chalchihuites' },
  { label: 'Chalchocoyo', value: 'Chalchocoyo' },
  { label: 'Chalchuapa', value: 'Chalchuapa' },
  { label: 'Chalco', value: 'Chalco' },
  { label: 'Chalco', value: 'Chalco' },
  { label: 'Chaldoran', value: 'Chaldoran' },
  { label: 'Chalé', value: 'Chalé' },
  { label: 'Chaleh Kand', value: 'Chaleh Kand' },
  { label: 'Chaleins', value: 'Chaleins' },
  { label: 'Châlette-Sur-Loing', value: 'Châlette-Sur-Loing' },
  { label: 'Chalfont', value: 'Chalfont' },
  { label: 'Chalfont Saint Peter', value: 'Chalfont Saint Peter' },
  { label: 'Chalfont St Giles', value: 'Chalfont St Giles' },
  { label: 'Chalford', value: 'Chalford' },
  { label: 'Chalgrove', value: 'Chalgrove' },
  { label: 'Chalifert', value: 'Chalifert' },
  { label: 'Chaligny', value: 'Chaligny' },
  { label: 'Chalindrey', value: 'Chalindrey' },
  { label: 'Chalinze', value: 'Chalinze' },
  { label: 'Chalisgaon', value: 'Chalisgaon' },
  { label: 'Chálki', value: 'Chálki' },
  { label: 'Chalkiádes', value: 'Chalkiádes' },
  { label: 'Chalkída', value: 'Chalkída' },
  { label: 'Chalkidóna', value: 'Chalkidóna' },
  { label: 'Chalkville', value: 'Chalkville' },
  { label: 'Chalky Hill', value: 'Chalky Hill' },
  { label: 'Challakere', value: 'Challakere' },
  { label: 'Challand-Saint-Anselme', value: 'Challand-Saint-Anselme' },
  { label: 'Challand-Saint-Victor', value: 'Challand-Saint-Victor' },
  { label: 'Challans', value: 'Challans' },
  { label: 'Challapalle', value: 'Challapalle' },
  { label: 'Challapata', value: 'Challapata' },
  { label: 'Challenge-Brownsville', value: 'Challenge-Brownsville' },
  { label: 'Challes', value: 'Challes' },
  { label: 'Challes-Les-Eaux', value: 'Challes-Les-Eaux' },
  { label: 'Challex', value: 'Challex' },
  { label: 'Challis', value: 'Challis' },
  { label: 'Challuy', value: 'Challuy' },
  { label: 'Chalma', value: 'Chalma' },
  { label: 'Chalma', value: 'Chalma' },
  { label: 'Chalmeca', value: 'Chalmeca' },
  { label: 'Chalmette', value: 'Chalmette' },
  { label: 'Chalmita', value: 'Chalmita' },
  { label: 'Chaloda', value: 'Chaloda' },
  { label: 'Chalon', value: 'Chalon' },
  { label: 'Chalonnes-Sur-Loire', value: 'Chalonnes-Sur-Loire' },
  { label: 'Châlons-En-Champagne', value: 'Châlons-En-Champagne' },
  { label: 'Chalon-Sur-Saône', value: 'Chalon-Sur-Saône' },
  { label: 'Châlonvillars', value: 'Châlonvillars' },
  { label: 'Chalo-Saint-Mars', value: 'Chalo-Saint-Mars' },
  { label: 'Chalpicote', value: 'Chalpicote' },
  { label: 'Chalton', value: 'Chalton' },
  { label: 'Chaltyr', value: 'Chaltyr' },
  { label: 'Chałupki', value: 'Chałupki' },
  { label: 'Chalus', value: 'Chalus' },
  { label: 'Châlus', value: 'Châlus' },
  { label: 'Cham', value: 'Cham' },
  { label: 'Cham', value: 'Cham' },
  { label: 'Chama', value: 'Chama' },
  { label: 'Chama', value: 'Chama' },
  { label: 'Chamácuaro', value: 'Chamácuaro' },
  { label: 'Chamacuero', value: 'Chamacuero' },
  { label: 'Chamagnieu', value: 'Chamagnieu' },
  { label: 'Chamalières', value: 'Chamalières' },
  { label: 'Chaman', value: 'Chaman' },
  { label: 'Chamant', value: 'Chamant' },
  { label: 'Chamarande', value: 'Chamarande' },
  { label: 'Chamarandes-Choignes', value: 'Chamarandes-Choignes' },
  { label: 'Chamarel', value: 'Chamarel' },
  { label: 'Chamartín', value: 'Chamartín' },
  { label: 'Chamba', value: 'Chamba' },
  { label: 'Chambarak', value: 'Chambarak' },
  { label: 'Chambas', value: 'Chambas' },
  { label: 'Chambave', value: 'Chambave' },
  { label: 'Chambellan', value: 'Chambellan' },
  { label: 'Chamber', value: 'Chamber' },
  { label: 'Chamberet', value: 'Chamberet' },
  { label: 'Chamberí', value: 'Chamberí' },
  { label: 'Chamberlain', value: 'Chamberlain' },
  { label: 'Chamberlayne', value: 'Chamberlayne' },
  { label: 'Chambers County', value: 'Chambers County' },
  { label: 'Chambers County', value: 'Chambers County' },
  { label: 'Chambers Flat', value: 'Chambers Flat' },
  { label: 'Chambers Pen', value: 'Chambers Pen' },
  { label: 'Chambersburg', value: 'Chambersburg' },
  { label: 'Chambéry', value: 'Chambéry' },
  { label: 'Chambishi', value: 'Chambishi' },
  { label: 'Chamblee', value: 'Chamblee' },
  { label: 'Chambly', value: 'Chambly' },
  { label: 'Chambly', value: 'Chambly' },
  { label: 'Chambœuf', value: 'Chambœuf' },
  { label: 'Chambon-Sur-Voueize', value: 'Chambon-Sur-Voueize' },
  { label: 'Chambord', value: 'Chambord' },
  { label: 'Chamboulive', value: 'Chamboulive' },
  { label: 'Chambourcy', value: 'Chambourcy' },
  { label: 'Chambourg-Sur-Indre', value: 'Chambourg-Sur-Indre' },
  { label: 'Chambray-Lès-Tours', value: 'Chambray-Lès-Tours' },
  { label: 'Chambretaud', value: 'Chambretaud' },
  { label: 'Chambrey', value: 'Chambrey' },
  { label: 'Chame', value: 'Chame' },
  { label: 'Chamela Jalisco', value: 'Chamela Jalisco' },
  { label: 'Chamerau', value: 'Chamerau' },
  { label: 'Chamestan', value: 'Chamestan' },
  { label: 'Chametla', value: 'Chametla' },
  { label: 'Chametla', value: 'Chametla' },
  { label: 'Chameyrat', value: 'Chameyrat' },
  { label: 'Chameza', value: 'Chameza' },
  { label: 'Chamgolak', value: 'Chamgolak' },
  { label: 'Chamgordan', value: 'Chamgordan' },
  { label: 'Chamical', value: 'Chamical' },
  { label: 'Chamigny', value: 'Chamigny' },
  { label: 'Chamlykskaya', value: 'Chamlykskaya' },
  { label: 'Chamois', value: 'Chamois' },
  { label: 'Chamoli', value: 'Chamoli' },
  { label: 'Chamonix-Mont-Blanc', value: 'Chamonix-Mont-Blanc' },
  { label: 'Chamoson', value: 'Chamoson' },
  { label: 'Chamouny', value: 'Chamouny' },
  { label: 'Champa', value: 'Champa' },
  { label: 'Champadanga', value: 'Champadanga' },
  { label: 'Champagnac', value: 'Champagnac' },
  { label: 'Champagné', value: 'Champagné' },
  { label: 'Champagne-Au-Mont-D’Or', value: 'Champagne-Au-Mont-D’Or' },
  { label: 'Champagné-Les-Marais', value: 'Champagné-Les-Marais' },
  { label: 'Champagne-Mouton', value: 'Champagne-Mouton' },
  { label: 'Champagne-Sur-Oise', value: 'Champagne-Sur-Oise' },
  { label: 'Champagne-Sur-Seine', value: 'Champagne-Sur-Seine' },
  { label: 'Champagney', value: 'Champagney' },
  { label: 'Champagnier', value: 'Champagnier' },
  { label: 'Champagnole', value: 'Champagnole' },
  { label: 'Champahati', value: 'Champahati' },
  { label: 'Champaign', value: 'Champaign' },
  { label: 'Champaign County', value: 'Champaign County' },
  { label: 'Champaign County', value: 'Champaign County' },
  { label: 'Champaner', value: 'Champaner' },
  { label: 'Champasak', value: 'Champasak' },
  { label: 'Champawat', value: 'Champawat' },
  { label: 'Champcevinel', value: 'Champcevinel' },
  { label: 'Champcueil', value: 'Champcueil' },
  { label: 'Champdani', value: 'Champdani' },
  { label: 'Champdeniers-Saint-Denis', value: 'Champdeniers-Saint-Denis' },
  { label: 'Champdepraz', value: 'Champdepraz' },
  { label: 'Champdieu', value: 'Champdieu' },
  { label: 'Champeix', value: 'Champeix' },
  { label: 'Champen Estate', value: 'Champen Estate' },
  { label: 'Champenoux', value: 'Champenoux' },
  { label: 'Champerico', value: 'Champerico' },
  { label: 'Champéry', value: 'Champéry' },
  { label: 'Champfleur', value: 'Champfleur' },
  { label: 'Champforgeuil', value: 'Champforgeuil' },
  { label: 'Champhai', value: 'Champhai' },
  { label: 'Champhol', value: 'Champhol' },
  { label: 'Champier', value: 'Champier' },
  { label: 'Champigné', value: 'Champigné' },
  { label: 'Champignelles', value: 'Champignelles' },
  { label: 'Champigneulles', value: 'Champigneulles' },
  { label: 'Champigny', value: 'Champigny' },
  { label: 'Champigny-Sur-Marne', value: 'Champigny-Sur-Marne' },
  { label: 'Champion Heights', value: 'Champion Heights' },
  { label: 'Champion Lakes', value: 'Champion Lakes' },
  { label: 'Champlain', value: 'Champlain' },
  { label: 'Champlan', value: 'Champlan' },
  { label: 'Champlin', value: 'Champlin' },
  { label: 'Champlitte', value: 'Champlitte' },
  { label: 'Champlitte-La-Ville', value: 'Champlitte-La-Ville' },
  { label: 'Champniers', value: 'Champniers' },
  { label: 'Champorcher', value: 'Champorcher' },
  { label: 'Champotón', value: 'Champotón' },
  { label: 'Champsecret', value: 'Champsecret' },
  { label: 'Champs-Sur-Marne', value: 'Champs-Sur-Marne' },
  { label: 'Champs-Sur-Tarentaine-Marchal', value: 'Champs-Sur-Tarentaine-Marchal' },
  { label: 'Champs-Sur-Yonne', value: 'Champs-Sur-Yonne' },
  { label: 'Champ-Sur-Drac', value: 'Champ-Sur-Drac' },
  { label: 'Champtoceaux', value: 'Champtoceaux' },
  { label: 'Champtocé-Sur-Loire', value: 'Champtocé-Sur-Loire' },
  { label: 'Champua', value: 'Champua' },
  { label: 'Champvans', value: 'Champvans' },
  { label: 'Chamrajnagar', value: 'Chamrajnagar' },
  { label: 'Chamran Town', value: 'Chamran Town' },
  { label: 'Chamula', value: 'Chamula' },
  { label: 'Chamulapita', value: 'Chamulapita' },
  { label: 'Chamusca', value: 'Chamusca' },
  { label: 'Chamzinka', value: 'Chamzinka' },
  { label: 'Chamzinskiy Rayon', value: 'Chamzinskiy Rayon' },
  { label: 'Chan Cenote', value: 'Chan Cenote' },
  { label: 'Chanac', value: 'Chanac' },
  { label: 'Chanal', value: 'Chanal' },
  { label: 'Chañar Ladeado', value: 'Chañar Ladeado' },
  { label: 'Chañaral', value: 'Chañaral' },
  { label: 'Chanas', value: 'Chanas' },
  { label: 'Chanasma', value: 'Chanasma' },
  { label: 'Chancay', value: 'Chancay' },
  { label: 'Chanceaux-Sur-Choisille', value: 'Chanceaux-Sur-Choisille' },
  { label: 'Chancelade', value: 'Chancelade' },
  { label: 'Chancenay', value: 'Chancenay' },
  { label: 'Chanchamayo', value: 'Chanchamayo' },
  { label: 'Chanco', value: 'Chanco' },
  { label: 'Chancol', value: 'Chancol' },
  { label: 'Chancuellar', value: 'Chancuellar' },
  { label: 'Chancy', value: 'Chancy' },
  { label: 'Chanda', value: 'Chanda' },
  { label: 'Chandannagar', value: 'Chandannagar' },
  { label: 'Chandauli', value: 'Chandauli' },
  { label: 'Chandauli District', value: 'Chandauli District' },
  { label: 'Chandausi', value: 'Chandausi' },
  { label: 'Chandbali', value: 'Chandbali' },
  { label: 'Chandel', value: 'Chandel' },
  { label: 'Chanderi', value: 'Chanderi' },
  { label: 'Chandgad', value: 'Chandgad' },
  { label: 'Chandia', value: 'Chandia' },
  { label: 'Chandigarh', value: 'Chandigarh' },
  { label: 'Chandil', value: 'Chandil' },
  { label: 'Chandla', value: 'Chandla' },
  { label: 'Chandler', value: 'Chandler' },
  { label: 'Chandler', value: 'Chandler' },
  { label: 'Chandler', value: 'Chandler' },
  { label: 'Chandler', value: 'Chandler' },
  { label: 'Chandler', value: 'Chandler' },
  { label: 'Chandler', value: 'Chandler' },
  { label: 'Chandon', value: 'Chandon' },
  { label: 'Chandor', value: 'Chandor' },
  { label: 'Chandpur', value: 'Chandpur' },
  { label: 'Chandpur', value: 'Chandpur' },
  { label: 'Chandra', value: 'Chandra' },
  { label: 'Chandrakona', value: 'Chandrakona' },
  { label: 'Chandrapur', value: 'Chandrapur' },
  { label: 'Chandrexa De Queixa', value: 'Chandrexa De Queixa' },
  { label: 'Chanduasi', value: 'Chanduasi' },
  { label: 'Chandur', value: 'Chandur' },
  { label: 'Chandur', value: 'Chandur' },
  { label: 'Chandur Bazar', value: 'Chandur Bazar' },
  { label: 'Chañe', value: 'Chañe' },
  { label: 'Changanacheri', value: 'Changanacheri' },
  { label: 'Changata', value: 'Changata' },
  { label: 'Changchi', value: 'Changchi' },
  { label: 'Changchun', value: 'Changchun' },
  { label: 'Changde', value: 'Changde' },
  { label: 'Changé', value: 'Changé' },
  { label: 'Changgang-Gun', value: 'Changgang-Gun' },
  { label: 'Changhua', value: 'Changhua' },
  { label: 'Changis-Sur-Marne', value: 'Changis-Sur-Marne' },
  { label: 'Changji', value: 'Changji' },
  { label: 'Changji Huizu Zizhizhou', value: 'Changji Huizu Zizhizhou' },
  { label: 'Changlang', value: 'Changlang' },
  { label: 'Changleng', value: 'Changleng' },
  { label: 'Changli', value: 'Changli' },
  { label: 'Changling', value: 'Changling' },
  { label: 'Changnyeong', value: 'Changnyeong' },
  { label: 'Changnyeong-Gun', value: 'Changnyeong-Gun' },
  { label: 'Changping', value: 'Changping' },
  { label: 'Changqiao', value: 'Changqiao' },
  { label: 'Changqing', value: 'Changqing' },
  { label: 'Changsha', value: 'Changsha' },
  { label: 'Changsha', value: 'Changsha' },
  { label: 'Changsha Shi', value: 'Changsha Shi' },
  { label: 'Changshu City', value: 'Changshu City' },
  { label: 'Changsu', value: 'Changsu' },
  { label: 'Changtu', value: 'Changtu' },
  { label: 'Changtun', value: 'Changtun' },
  { label: 'Changuinola', value: 'Changuinola' },
  { label: 'Changwon', value: 'Changwon' },
  { label: 'Changwon-Si', value: 'Changwon-Si' },
  { label: 'Changy-Les-Bois', value: 'Changy-Les-Bois' },
  { label: 'Changyŏn', value: 'Changyŏn' },
  { label: 'Changzhi', value: 'Changzhi' },
  { label: 'Changzhou', value: 'Changzhou' },
  { label: 'Changzhou', value: 'Changzhou' },
  { label: 'Chanhassen', value: 'Chanhassen' },
  { label: 'Chaniá', value: 'Chaniá' },
  { label: 'Chaniers', value: 'Chaniers' },
  { label: 'Chanika', value: 'Chanika' },
  { label: 'Chankom', value: 'Chankom' },
  { label: 'Channagiri', value: 'Channagiri' },
  { label: 'Channahon', value: 'Channahon' },
  { label: 'Channapatna', value: 'Channapatna' },
  { label: 'Channarayapatna', value: 'Channarayapatna' },
  { label: 'Channel Islands Beach', value: 'Channel Islands Beach' },
  { label: 'Channel Lake', value: 'Channel Lake' },
  { label: 'Channel-Port Aux Basques', value: 'Channel-Port Aux Basques' },
  { label: 'Channelview', value: 'Channelview' },
  { label: 'Channing', value: 'Channing' },
  { label: 'Chanonat', value: 'Chanonat' },
  { label: 'Chanos-Curson', value: 'Chanos-Curson' },
  { label: 'Chantada', value: 'Chantada' },
  { label: 'Chantal', value: 'Chantal' },
  { label: 'Chanteau', value: 'Chanteau' },
  { label: 'Chanteheux', value: 'Chanteheux' },
  { label: 'Chantelle', value: 'Chantelle' },
  { label: 'Chanteloup', value: 'Chanteloup' },
  { label: 'Chanteloup-En-Brie', value: 'Chanteloup-En-Brie' },
  { label: 'Chanteloup-Les-Vignes', value: 'Chanteloup-Les-Vignes' },
  { label: 'Chantenay-Saint-Imbert', value: 'Chantenay-Saint-Imbert' },
  { label: 'Chantepie', value: 'Chantepie' },
  { label: 'Chanthaburi', value: 'Chanthaburi' },
  { label: 'Chantilly', value: 'Chantilly' },
  { label: 'Chantilly', value: 'Chantilly' },
  { label: 'Chantilly', value: 'Chantilly' },
  { label: 'Chantilly', value: 'Chantilly' },
  { label: 'Chantôme', value: 'Chantôme' },
  { label: 'Chantonnay', value: 'Chantonnay' },
  { label: 'Chantraine', value: 'Chantraine' },
  { label: 'Chanu', value: 'Chanu' },
  { label: 'Chanute', value: 'Chanute' },
  { label: 'Chany', value: 'Chany' },
  { label: 'Chaohu', value: 'Chaohu' },
  { label: 'Chaource', value: 'Chaource' },
  { label: 'Chaoyang', value: 'Chaoyang' },
  { label: 'Chaoyang', value: 'Chaoyang' },
  { label: 'Chaozhou', value: 'Chaozhou' },
  { label: 'Chapa', value: 'Chapa' },
  { label: 'Chapa De Mota', value: 'Chapa De Mota' },
  { label: 'Chapab', value: 'Chapab' },
  { label: 'Chapada', value: 'Chapada' },
  { label: 'Chapada Da Natividade', value: 'Chapada Da Natividade' },
  { label: 'Chapada De Areia', value: 'Chapada De Areia' },
  { label: 'Chapada Do Norte', value: 'Chapada Do Norte' },
  { label: 'Chapada Dos Guimarães', value: 'Chapada Dos Guimarães' },
  { label: 'Chapada Gaúcha', value: 'Chapada Gaúcha' },
  { label: 'Chapadão Do Céu', value: 'Chapadão Do Céu' },
  { label: 'Chapadão Do Lageado', value: 'Chapadão Do Lageado' },
  { label: 'Chapadão Do Sul', value: 'Chapadão Do Sul' },
  { label: 'Chapadinha', value: 'Chapadinha' },
  { label: 'Chapaev', value: 'Chapaev' },
  { label: 'Chapai Nawabganj', value: 'Chapai Nawabganj' },
  { label: 'Chapais', value: 'Chapais' },
  { label: 'Chapala', value: 'Chapala' },
  { label: 'Chapalilla', value: 'Chapalilla' },
  { label: 'Chapallal Grande', value: 'Chapallal Grande' },
  { label: 'Chapantongo', value: 'Chapantongo' },
  { label: 'Chapar', value: 'Chapar' },
  { label: 'Chaparaco', value: 'Chaparaco' },
  { label: 'Chapareillan', value: 'Chapareillan' },
  { label: 'Chaparral', value: 'Chaparral' },
  { label: 'Chaparral', value: 'Chaparral' },
  { label: 'Chaparrosa', value: 'Chaparrosa' },
  { label: 'Chapayeve', value: 'Chapayeve' },
  { label: 'Chapayevka', value: 'Chapayevka' },
  { label: 'Chapayevka', value: 'Chapayevka' },
  { label: 'Chapayevsk', value: 'Chapayevsk' },
  { label: 'Chapchha', value: 'Chapchha' },
  { label: 'Chapdes-Beaufort', value: 'Chapdes-Beaufort' },
  { label: 'Chapecó', value: 'Chapecó' },
  { label: 'Chapel Allerton', value: 'Chapel Allerton' },
  { label: 'Chapel En Le Frith', value: 'Chapel En Le Frith' },
  { label: 'Chapel Hill', value: 'Chapel Hill' },
  { label: 'Chapel Hill', value: 'Chapel Hill' },
  { label: 'Chapel Hill', value: 'Chapel Hill' },
  { label: 'Chapel Saint Leonards', value: 'Chapel Saint Leonards' },
  { label: 'Chapelhall', value: 'Chapelhall' },
  { label: 'Chapelizod', value: 'Chapelizod' },
  { label: 'Chapelle-Lez-Herlaimont', value: 'Chapelle-Lez-Herlaimont' },
  { label: 'Chapeltique', value: 'Chapeltique' },
  { label: 'Chapelton', value: 'Chapelton' },
  { label: 'Chapeltown', value: 'Chapeltown' },
  { label: 'Chapeshlu', value: 'Chapeshlu' },
  { label: 'Chapet', value: 'Chapet' },
  { label: 'Chapin', value: 'Chapin' },
  { label: 'Chapinería', value: 'Chapinería' },
  { label: 'Chaplygin', value: 'Chaplygin' },
  { label: 'Chaplyginskiy Rayon', value: 'Chaplyginskiy Rayon' },
  { label: 'Chaplynka', value: 'Chaplynka' },
  { label: 'Chapman', value: 'Chapman' },
  { label: 'Chapman', value: 'Chapman' },
  { label: 'Chapman Valley', value: 'Chapman Valley' },
  { label: 'Chapmanslade', value: 'Chapmanslade' },
  { label: 'Chapmanville', value: 'Chapmanville' },
  { label: 'Chaponnay', value: 'Chaponnay' },
  { label: 'Chaponost', value: 'Chaponost' },
  { label: 'Chapopote Chico', value: 'Chapopote Chico' },
  { label: 'Chapopote Núñez', value: 'Chapopote Núñez' },
  { label: 'Chappaqua', value: 'Chappaqua' },
  { label: 'Chappell', value: 'Chappell' },
  { label: 'Chappes', value: 'Chappes' },
  { label: 'Chapra', value: 'Chapra' },
  { label: 'Chaptelat', value: 'Chaptelat' },
  { label: 'Chapulco', value: 'Chapulco' },
  { label: 'Chapulhuacán', value: 'Chapulhuacán' },
  { label: 'Chapulhuacanito', value: 'Chapulhuacanito' },
  { label: 'Chapultenango', value: 'Chapultenango' },
  { label: 'Chapultepec', value: 'Chapultepec' },
  { label: 'Chaqabol', value: 'Chaqabol' },
  { label: 'Chara', value: 'Chara' },
  { label: 'Charadai', value: 'Charadai' },
  { label: 'Charagua', value: 'Charagua' },
  { label: 'Charalá', value: 'Charalá' },
  { label: 'Charallave', value: 'Charallave' },
  { label: 'Charam', value: 'Charam' },
  { label: 'Charantonnay', value: 'Charantonnay' },
  { label: 'Charapán', value: 'Charapán' },
  { label: 'Charapendo', value: 'Charapendo' },
  { label: 'Charata', value: 'Charata' },
  { label: 'Charavimaq', value: 'Charavimaq' },
  { label: 'Charavines', value: 'Charavines' },
  { label: 'Charay', value: 'Charay' },
  { label: 'Charbagh', value: 'Charbagh' },
  { label: 'Charbonnières-Les-Bains', value: 'Charbonnières-Les-Bains' },
  { label: 'Charbonnières-Les-Varennes', value: 'Charbonnières-Les-Varennes' },
  { label: 'Charburj', value: 'Charburj' },
  { label: 'Charbuy', value: 'Charbuy' },
  { label: 'Charcas', value: 'Charcas' },
  { label: 'Charco Blanco', value: 'Charco Blanco' },
  { label: 'Charco Blanco', value: 'Charco Blanco' },
  { label: 'Charco De Pantoja', value: 'Charco De Pantoja' },
  { label: 'Charco Redondo', value: 'Charco Redondo' },
  { label: 'Chard', value: 'Chard' },
  { label: 'Chardara', value: 'Chardara' },
  { label: 'Chardavol County', value: 'Chardavol County' },
  { label: 'Chardon', value: 'Chardon' },
  { label: 'Chardonne', value: 'Chardonne' },
  { label: 'Chardonnière', value: 'Chardonnière' },
  { label: 'Charef', value: 'Charef' },
  { label: 'Charentay', value: 'Charentay' },
  { label: 'Charente', value: 'Charente' },
  { label: 'Charente-Maritime', value: 'Charente-Maritime' },
  { label: 'Charentilly', value: 'Charentilly' },
  { label: 'Charenton', value: 'Charenton' },
  { label: 'Charenton-Du-Cher', value: 'Charenton-Du-Cher' },
  { label: 'Charenton-Le-Pont', value: 'Charenton-Le-Pont' },
  { label: 'Charfield', value: 'Charfield' },
  { label: 'Chargé', value: 'Chargé' },
  { label: 'Chari Department', value: 'Chari Department' },
  { label: 'Charikar', value: 'Charikar' },
  { label: 'Charing', value: 'Charing' },
  { label: 'Chariton', value: 'Chariton' },
  { label: 'Chariton County', value: 'Chariton County' },
  { label: 'Charkhari', value: 'Charkhari' },
  { label: 'Charkhi Dadri', value: 'Charkhi Dadri' },
  { label: 'Charlack', value: 'Charlack' },
  { label: 'Charlbury', value: 'Charlbury' },
  { label: 'Charlemagne', value: 'Charlemagne' },
  { label: 'Charlemont', value: 'Charlemont' },
  { label: 'Charleroi', value: 'Charleroi' },
  { label: 'Charleroi', value: 'Charleroi' },
  { label: 'Charles City', value: 'Charles City' },
  { label: 'Charles City', value: 'Charles City' },
  { label: 'Charles City County', value: 'Charles City County' },
  { label: 'Charles County', value: 'Charles County' },
  { label: 'Charles Mix County', value: 'Charles Mix County' },
  { label: 'Charles Sturt', value: 'Charles Sturt' },
  { label: 'Charles Town', value: 'Charles Town' },
  { label: 'Charles Town', value: 'Charles Town' },
  { label: 'Charles Town', value: 'Charles Town' },
  { label: 'Charles Village', value: 'Charles Village' },
  { label: 'Charlesland', value: 'Charlesland' },
  { label: 'Charleston', value: 'Charleston' },
  { label: 'Charleston', value: 'Charleston' },
  { label: 'Charleston', value: 'Charleston' },
  { label: 'Charleston', value: 'Charleston' },
  { label: 'Charleston', value: 'Charleston' },
  { label: 'Charleston', value: 'Charleston' },
  { label: 'Charleston', value: 'Charleston' },
  { label: 'Charleston', value: 'Charleston' },
  { label: 'Charleston County', value: 'Charleston County' },
  { label: 'Charlestown', value: 'Charlestown' },
  { label: 'Charlestown', value: 'Charlestown' },
  { label: 'Charlestown', value: 'Charlestown' },
  { label: 'Charlestown', value: 'Charlestown' },
  { label: 'Charlestown', value: 'Charlestown' },
  { label: 'Charlestown', value: 'Charlestown' },
  { label: 'Charlestown Of Aberlour', value: 'Charlestown Of Aberlour' },
  { label: 'Charleval', value: 'Charleval' },
  { label: 'Charleval', value: 'Charleval' },
  { label: 'Charleville', value: 'Charleville' },
  { label: 'Charleville-Mézières', value: 'Charleville-Mézières' },
  { label: 'Charlevoix', value: 'Charlevoix' },
  { label: 'Charlevoix County', value: 'Charlevoix County' },
  { label: 'Charlieu', value: 'Charlieu' },
  { label: 'Charlotte', value: 'Charlotte' },
  { label: 'Charlotte', value: 'Charlotte' },
  { label: 'Charlotte', value: 'Charlotte' },
  { label: 'Charlotte', value: 'Charlotte' },
  { label: 'Charlotte', value: 'Charlotte' },
  { label: 'Charlotte Amalie', value: 'Charlotte Amalie' },
  { label: 'Charlotte County', value: 'Charlotte County' },
  { label: 'Charlotte County', value: 'Charlotte County' },
  { label: 'Charlotte Court House', value: 'Charlotte Court House' },
  { label: 'Charlotte Hall', value: 'Charlotte Hall' },
  { label: 'Charlotte Harbor', value: 'Charlotte Harbor' },
  { label: 'Charlotte Park', value: 'Charlotte Park' },
  { label: 'Charlottenberg', value: 'Charlottenberg' },
  { label: 'Charlottenburg', value: 'Charlottenburg' },
  { label: 'Charlottenburg-Nord', value: 'Charlottenburg-Nord' },
  { label: 'Charlottenlund', value: 'Charlottenlund' },
  { label: 'Charlottesville', value: 'Charlottesville' },
  { label: 'Charlottetown', value: 'Charlottetown' },
  { label: 'Charlton', value: 'Charlton' },
  { label: 'Charlton', value: 'Charlton' },
  { label: 'Charlton County', value: 'Charlton County' },
  { label: 'Charlton Kings', value: 'Charlton Kings' },
  { label: 'Charlton Marshall', value: 'Charlton Marshall' },
  { label: 'Charly', value: 'Charly' },
  { label: 'Charly-Sur-Marne', value: 'Charly-Sur-Marne' },
  { label: 'Charmes', value: 'Charmes' },
  { label: 'Charmes', value: 'Charmes' },
  { label: 'Charmes-Sur-Rhône', value: 'Charmes-Sur-Rhône' },
  { label: 'Charmey', value: 'Charmey' },
  { label: 'Charmhaven', value: 'Charmhaven' },
  { label: 'Charminster', value: 'Charminster' },
  { label: 'Charmouth', value: 'Charmouth' },
  { label: 'Charnawchytsy', value: 'Charnawchytsy' },
  { label: 'Charnay', value: 'Charnay' },
  { label: 'Charnay-Lès-Mâcon', value: 'Charnay-Lès-Mâcon' },
  { label: 'Charneca', value: 'Charneca' },
  { label: 'Charneca', value: 'Charneca' },
  { label: 'Charneca De Caparica', value: 'Charneca De Caparica' },
  { label: 'Charnècles', value: 'Charnècles' },
  { label: 'Charnwood', value: 'Charnwood' },
  { label: 'Charny', value: 'Charny' },
  { label: 'Charny', value: 'Charny' },
  { label: 'Charo', value: 'Charo' },
  { label: 'Charodinskiy Rayon', value: 'Charodinskiy Rayon' },
  { label: 'Charolles', value: 'Charolles' },
  { label: 'Charopó', value: 'Charopó' },
  { label: 'Charoq', value: 'Charoq' },
  { label: 'Chârost', value: 'Chârost' },
  { label: 'Charqueada', value: 'Charqueada' },
  { label: 'Charqueadas', value: 'Charqueadas' },
  { label: 'Charquemont', value: 'Charquemont' },
  { label: 'Charras', value: 'Charras' },
  { label: 'Charrat', value: 'Charrat' },
  { label: 'Charron', value: 'Charron' },
  { label: 'Charroux', value: 'Charroux' },
  { label: 'Charrua', value: 'Charrua' },
  { label: 'Chars', value: 'Chars' },
  { label: 'Charsadda', value: 'Charsadda' },
  { label: 'Charta', value: 'Charta' },
  { label: 'Charter Oak', value: 'Charter Oak' },
  { label: 'Charters Towers', value: 'Charters Towers' },
  { label: 'Charters Towers City', value: 'Charters Towers City' },
  { label: 'Chartham', value: 'Chartham' },
  { label: 'Charthawal', value: 'Charthawal' },
  { label: 'Chartres', value: 'Chartres' },
  { label: 'Chartres-De-Bretagne', value: 'Chartres-De-Bretagne' },
  { label: 'Chartrettes', value: 'Chartrettes' },
  { label: 'Charvensod', value: 'Charvensod' },
  { label: 'Charvieu-Chavagneux', value: 'Charvieu-Chavagneux' },
  { label: 'Charvil', value: 'Charvil' },
  { label: 'Charxin', value: 'Charxin' },
  { label: 'Charyshskoye', value: 'Charyshskoye' },
  { label: 'Chas', value: 'Chas' },
  { label: 'Chãs', value: 'Chãs' },
  { label: 'Chase', value: 'Chase' },
  { label: 'Chase City', value: 'Chase City' },
  { label: 'Chase County', value: 'Chase County' },
  { label: 'Chase County', value: 'Chase County' },
  { label: 'Chase Gardens', value: 'Chase Gardens' },
  { label: 'Chasetown', value: 'Chasetown' },
  { label: 'Chashniki', value: 'Chashniki' },
  { label: 'Chashnikovo', value: 'Chashnikovo' },
  { label: 'Chashnitski Rayon', value: 'Chashnitski Rayon' },
  { label: 'Chasht Khvoreh', value: 'Chasht Khvoreh' },
  { label: 'Chasiv Yar', value: 'Chasiv Yar' },
  { label: 'Chaska', value: 'Chaska' },
  { label: 'Chasné-Sur-Illet', value: 'Chasné-Sur-Illet' },
  { label: 'Chąśno', value: 'Chąśno' },
  { label: 'Chasŏng', value: 'Chasŏng' },
  { label: 'Chassagny', value: 'Chassagny' },
  { label: 'Chasselay', value: 'Chasselay' },
  { label: 'Chasseneuil-Du-Poitou', value: 'Chasseneuil-Du-Poitou' },
  { label: 'Chasseneuil-Sur-Bonnieure', value: 'Chasseneuil-Sur-Bonnieure' },
  { label: 'Chasse-Sur-Rhône', value: 'Chasse-Sur-Rhône' },
  { label: 'Chassieu', value: 'Chassieu' },
  { label: 'Chassin/Babonneau', value: 'Chassin/Babonneau' },
  { label: 'Chassors', value: 'Chassors' },
  { label: 'Chastre-Villeroux-Blanmont', value: 'Chastre-Villeroux-Blanmont' },
  { label: 'Chastyye', value: 'Chastyye' },
  { label: 'Chat Trakan', value: 'Chat Trakan' },
  { label: 'Chatakonda', value: 'Chatakonda' },
  { label: 'Chatan', value: 'Chatan' },
  { label: 'Chatburn', value: 'Chatburn' },
  { label: 'Chateau', value: 'Chateau' },
  { label: 'Chateau Belair', value: 'Chateau Belair' },
  { label: 'Chateaubelair', value: 'Chateaubelair' },
  { label: 'Châteaubernard', value: 'Châteaubernard' },
  { label: 'Châteaubourg', value: 'Châteaubourg' },
  { label: 'Châteaubriant', value: 'Châteaubriant' },
  { label: 'Château-Chinon(Ville)', value: 'Château-Chinon(Ville)' },
  { label: 'Château-D’Olonne', value: 'Château-D’Olonne' },
  { label: 'Château-Doex', value: 'Château-Doex' },
  { label: 'Château-Du-Loir', value: 'Château-Du-Loir' },
  { label: 'Châteaudun', value: 'Châteaudun' },
  { label: 'Châteaufort', value: 'Châteaufort' },
  { label: 'Château-Gaillard', value: 'Château-Gaillard' },
  { label: 'Châteaugay', value: 'Châteaugay' },
  { label: 'Châteaugiron', value: 'Châteaugiron' },
  { label: 'Château-Gontier', value: 'Château-Gontier' },
  { label: 'Châteauguay', value: 'Châteauguay' },
  { label: 'Château-Guibert', value: 'Château-Guibert' },
  { label: 'Château-Landon', value: 'Château-Landon' },
  { label: 'Château-La-Vallière', value: 'Château-La-Vallière' },
  { label: 'Châteaulin', value: 'Châteaulin' },
  { label: 'Châteaumeillant', value: 'Châteaumeillant' },
  { label: 'Châteauneuf', value: 'Châteauneuf' },
  { label: 'Châteauneuf-D’Ille-Et-Vilaine', value: 'Châteauneuf-D’Ille-Et-Vilaine' },
  { label: 'Châteauneuf-De-Gadagne', value: 'Châteauneuf-De-Gadagne' },
  { label: 'Châteauneuf-De-Galaure', value: 'Châteauneuf-De-Galaure' },
  { label: 'Châteauneuf-Du-Faou', value: 'Châteauneuf-Du-Faou' },
  { label: 'Châteauneuf-Du-Pape', value: 'Châteauneuf-Du-Pape' },
  { label: 'Châteauneuf-Du-Rhône', value: 'Châteauneuf-Du-Rhône' },
  { label: 'Châteauneuf-En-Thymerais', value: 'Châteauneuf-En-Thymerais' },
  { label: 'Châteauneuf-Grasse', value: 'Châteauneuf-Grasse' },
  { label: 'Châteauneuf-La-Forêt', value: 'Châteauneuf-La-Forêt' },
  { label: 'Châteauneuf-Le-Rouge', value: 'Châteauneuf-Le-Rouge' },
  { label: 'Châteauneuf-Les-Martigues', value: 'Châteauneuf-Les-Martigues' },
  { label: 'Châteauneuf-Sur-Charente', value: 'Châteauneuf-Sur-Charente' },
  { label: 'Châteauneuf-Sur-Cher', value: 'Châteauneuf-Sur-Cher' },
  { label: 'Châteauneuf-Sur-Isère', value: 'Châteauneuf-Sur-Isère' },
  { label: 'Châteauneuf-Sur-Loire', value: 'Châteauneuf-Sur-Loire' },
  { label: 'Châteauneuf-Sur-Sarthe', value: 'Châteauneuf-Sur-Sarthe' },
  { label: 'Châteauponsac', value: 'Châteauponsac' },
  { label: 'Château-Porcien', value: 'Château-Porcien' },
  { label: 'Châteaurenard', value: 'Châteaurenard' },
  { label: 'Château-Renard', value: 'Château-Renard' },
  { label: 'Château-Renault', value: 'Château-Renault' },
  { label: 'Château-Richer', value: 'Château-Richer' },
  { label: 'Châteauroux', value: 'Châteauroux' },
  { label: 'Château-Salins', value: 'Château-Salins' },
  { label: 'Château-Thierry', value: 'Château-Thierry' },
  { label: 'Châteauvillain', value: 'Châteauvillain' },
  { label: 'Châtel', value: 'Châtel' },
  { label: 'Châtelaillon-Plage', value: 'Châtelaillon-Plage' },
  { label: 'Châtelet', value: 'Châtelet' },
  { label: 'Châtel-Guyon', value: 'Châtel-Guyon' },
  { label: 'Châtellerault', value: 'Châtellerault' },
  { label: 'Châtel-Saint-Denis', value: 'Châtel-Saint-Denis' },
  { label: 'Châtel-Saint-Germain', value: 'Châtel-Saint-Germain' },
  { label: 'Châtel-Sur-Moselle', value: 'Châtel-Sur-Moselle' },
  { label: 'Châtenay-Malabry', value: 'Châtenay-Malabry' },
  { label: 'Châtenay-Sur-Seine', value: 'Châtenay-Sur-Seine' },
  { label: 'Châtenois', value: 'Châtenois' },
  { label: 'Châtenois-Les-Forges', value: 'Châtenois-Les-Forges' },
  { label: 'Châtenoy-Le-Royal', value: 'Châtenoy-Le-Royal' },
  { label: 'Chatfield', value: 'Chatfield' },
  { label: 'Chatham', value: 'Chatham' },
  { label: 'Chatham', value: 'Chatham' },
  { label: 'Chatham', value: 'Chatham' },
  { label: 'Chatham', value: 'Chatham' },
  { label: 'Chatham', value: 'Chatham' },
  { label: 'Chatham', value: 'Chatham' },
  { label: 'Chatham', value: 'Chatham' },
  { label: 'Chatham County', value: 'Chatham County' },
  { label: 'Chatham County', value: 'Chatham County' },
  { label: 'Chatham-Kent', value: 'Chatham-Kent' },
  { label: 'Chatian', value: 'Chatian' },
  { label: 'Chatillon', value: 'Chatillon' },
  { label: 'Châtillon', value: 'Châtillon' },
  { label: 'Châtillon', value: 'Châtillon' },
  { label: 'Châtillon-Coligny', value: 'Châtillon-Coligny' },
  { label: 'Châtillon-En-Bazois', value: 'Châtillon-En-Bazois' },
  { label: 'Châtillon-En-Michaille', value: 'Châtillon-En-Michaille' },
  { label: 'Châtillon-En-Vendelais', value: 'Châtillon-En-Vendelais' },
  { label: 'Châtillon-La-Palud', value: 'Châtillon-La-Palud' },
  { label: 'Châtillon-Le-Duc', value: 'Châtillon-Le-Duc' },
  { label: 'Châtillon-Sur-Chalaronne', value: 'Châtillon-Sur-Chalaronne' },
  { label: 'Châtillon-Sur-Cher', value: 'Châtillon-Sur-Cher' },
  { label: 'Châtillon-Sur-Cluses', value: 'Châtillon-Sur-Cluses' },
  { label: 'Châtillon-Sur-Indre', value: 'Châtillon-Sur-Indre' },
  { label: 'Châtillon-Sur-Marne', value: 'Châtillon-Sur-Marne' },
  { label: 'Châtillon-Sur-Seiche', value: 'Châtillon-Sur-Seiche' },
  { label: 'Châtillon-Sur-Seine', value: 'Châtillon-Sur-Seine' },
  { label: 'Châtillon-Sur-Thouet', value: 'Châtillon-Sur-Thouet' },
  { label: 'Chatipán', value: 'Chatipán' },
  { label: 'Chatmoss', value: 'Chatmoss' },
  { label: 'Chato', value: 'Chato' },
  { label: 'Chatom', value: 'Chatom' },
  { label: 'Châtonnay', value: 'Châtonnay' },
  { label: 'Chatou', value: 'Chatou' },
  { label: 'Chatra', value: 'Chatra' },
  { label: 'Chatrapur', value: 'Chatrapur' },
  { label: 'Châtres-Sur-Cher', value: 'Châtres-Sur-Cher' },
  { label: 'Chatroud', value: 'Chatroud' },
  { label: 'Chatswood', value: 'Chatswood' },
  { label: 'Chatswood West', value: 'Chatswood West' },
  { label: 'Chatsworth', value: 'Chatsworth' },
  { label: 'Chatsworth', value: 'Chatsworth' },
  { label: 'Chatsworth', value: 'Chatsworth' },
  { label: 'Chatsworth', value: 'Chatsworth' },
  { label: 'Chattahoochee', value: 'Chattahoochee' },
  { label: 'Chattahoochee County', value: 'Chattahoochee County' },
  { label: 'Chattahoochee Hills', value: 'Chattahoochee Hills' },
  { label: 'Chattanooga', value: 'Chattanooga' },
  { label: 'Chattanooga Valley', value: 'Chattanooga Valley' },
  { label: 'Chatte', value: 'Chatte' },
  { label: 'Chatteris', value: 'Chatteris' },
  { label: 'Chattooga County', value: 'Chattooga County' },
  { label: 'Chatu Chak', value: 'Chatu Chak' },
  { label: 'Chatuzange-Le-Goubet', value: 'Chatuzange-Le-Goubet' },
  { label: 'Chauché', value: 'Chauché' },
  { label: 'Chauchina', value: 'Chauchina' },
  { label: 'Chaucingo', value: 'Chaucingo' },
  { label: 'Chauconin-Neufmontiers', value: 'Chauconin-Neufmontiers' },
  { label: 'Chaudes-Aigues', value: 'Chaudes-Aigues' },
  { label: 'Chaudfontaine', value: 'Chaudfontaine' },
  { label: 'Chaudière-Appalaches', value: 'Chaudière-Appalaches' },
  { label: 'Chaudon', value: 'Chaudon' },
  { label: 'Chaudron-En-Mauges', value: 'Chaudron-En-Mauges' },
  { label: 'Chauffailles', value: 'Chauffailles' },
  { label: 'Chauk', value: 'Chauk' },
  { label: 'Chaulgnes', value: 'Chaulgnes' },
  { label: 'Chaulnes', value: 'Chaulnes' },
  { label: 'Chaumes-En-Brie', value: 'Chaumes-En-Brie' },
  { label: 'Chaumont', value: 'Chaumont' },
  { label: 'Chaumontel', value: 'Chaumontel' },
  { label: 'Chaumont-En-Vexin', value: 'Chaumont-En-Vexin' },
  { label: 'Chaumont-Gistoux', value: 'Chaumont-Gistoux' },
  { label: 'Chaumont-Sur-Loire', value: 'Chaumont-Sur-Loire' },
  { label: 'Chaumont-Sur-Tharonne', value: 'Chaumont-Sur-Tharonne' },
  { label: 'Chaunay', value: 'Chaunay' },
  { label: 'Chauncey', value: 'Chauncey' },
  { label: 'Chauny', value: 'Chauny' },
  { label: 'Chaupal', value: 'Chaupal' },
  { label: 'Chaupimarca', value: 'Chaupimarca' },
  { label: 'Chauray', value: 'Chauray' },
  { label: 'Chauriat', value: 'Chauriat' },
  { label: 'Chausala', value: 'Chausala' },
  { label: 'Chaussin', value: 'Chaussin' },
  { label: 'Chautauqua County', value: 'Chautauqua County' },
  { label: 'Chautauqua County', value: 'Chautauqua County' },
  { label: 'Chauvé', value: 'Chauvé' },
  { label: 'Chauvigny', value: 'Chauvigny' },
  { label: 'Chauvin', value: 'Chauvin' },
  { label: 'Chavagne', value: 'Chavagne' },
  { label: 'Chavagnes-En-Paillers', value: 'Chavagnes-En-Paillers' },
  { label: 'Chaval', value: 'Chaval' },
  { label: 'Chavanay', value: 'Chavanay' },
  { label: 'Chavannes', value: 'Chavannes' },
  { label: 'Chavannes-Le-Veyron', value: 'Chavannes-Le-Veyron' },
  { label: 'Chavanod', value: 'Chavanod' },
  { label: 'Chavanoz', value: 'Chavanoz' },
  { label: 'Chavantes', value: 'Chavantes' },
  { label: 'Chavão', value: 'Chavão' },
  { label: 'Chávari', value: 'Chávari' },
  { label: 'Chavarría', value: 'Chavarría' },
  { label: 'Chavarría', value: 'Chavarría' },
  { label: 'Chavarrillo', value: 'Chavarrillo' },
  { label: 'Chavarzagh', value: 'Chavarzagh' },
  { label: 'Chavaxtla', value: 'Chavaxtla' },
  { label: 'Chavdar', value: 'Chavdar' },
  { label: 'Chavelot', value: 'Chavelot' },
  { label: 'Chavenay', value: 'Chavenay' },
  { label: 'Chaves', value: 'Chaves' },
  { label: 'Chaves', value: 'Chaves' },
  { label: 'Chaves County', value: 'Chaves County' },
  { label: 'Chavibdeh', value: 'Chavibdeh' },
  { label: 'Chavigny', value: 'Chavigny' },
  { label: 'Chaville', value: 'Chaville' },
  { label: 'Chavinda', value: 'Chavinda' },
  { label: 'Chavornay', value: 'Chavornay' },
  { label: 'Chavuski Rayon', value: 'Chavuski Rayon' },
  { label: 'Chavusy', value: 'Chavusy' },
  { label: 'Chawang', value: 'Chawang' },
  { label: 'Chawinda', value: 'Chawinda' },
  { label: 'Chayan', value: 'Chayan' },
  { label: 'Chaykino', value: 'Chaykino' },
  { label: 'Chaykovskaya', value: 'Chaykovskaya' },
  { label: 'Chaykovskiy', value: 'Chaykovskiy' },
  { label: 'Chayotillo', value: 'Chayotillo' },
  { label: 'Chaypareh', value: 'Chaypareh' },
  { label: 'Chazelles', value: 'Chazelles' },
  { label: 'Chazelles-Sur-Lyon', value: 'Chazelles-Sur-Lyon' },
  { label: 'Chazey-Sur-Ain', value: 'Chazey-Sur-Ain' },
  { label: 'Chazón', value: 'Chazón' },
  { label: 'Chazuta', value: 'Chazuta' },
  { label: 'Cheadle', value: 'Cheadle' },
  { label: 'Cheadle Heath', value: 'Cheadle Heath' },
  { label: 'Cheadle Hulme', value: 'Cheadle Hulme' },
  { label: 'Cheam', value: 'Cheam' },
  { label: 'Cheat Lake', value: 'Cheat Lake' },
  { label: 'Cheatham County', value: 'Cheatham County' },
  { label: 'Cheb', value: 'Cheb' },
  { label: 'Chebanse', value: 'Chebanse' },
  { label: 'Chebarkul’', value: 'Chebarkul’' },
  { label: 'Chebarkul’Skiy Rayon', value: 'Chebarkul’Skiy Rayon' },
  { label: 'Chebba', value: 'Chebba' },
  { label: 'Chebel', value: 'Chebel' },
  { label: 'Chebika', value: 'Chebika' },
  { label: 'Chebli', value: 'Chebli' },
  { label: 'Cheboksarskiy Rayon', value: 'Cheboksarskiy Rayon' },
  { label: 'Cheboksary', value: 'Cheboksary' },
  { label: 'Cheboygan', value: 'Cheboygan' },
  { label: 'Cheboygan County', value: 'Cheboygan County' },
  { label: 'Chebsara', value: 'Chebsara' },
  { label: 'Checa', value: 'Checa' },
  { label: 'Checacupe', value: 'Checacupe' },
  { label: 'Checca', value: 'Checca' },
  { label: 'Checea', value: 'Checea' },
  { label: 'Chechat', value: 'Chechat' },
  { label: 'Chechelnyk', value: 'Chechelnyk' },
  { label: 'Chechen-Aul', value: 'Chechen-Aul' },
  { label: 'Chechiș', value: 'Chechiș' },
  { label: 'Chęciny', value: 'Chęciny' },
  { label: 'Checotah', value: 'Checotah' },
  { label: 'Chécy', value: 'Chécy' },
  { label: 'Cheddar', value: 'Cheddar' },
  { label: 'Cheddington', value: 'Cheddington' },
  { label: 'Cheddleton', value: 'Cheddleton' },
  { label: 'Cheektowaga', value: 'Cheektowaga' },
  { label: 'Cheetham Hill', value: 'Cheetham Hill' },
  { label: 'Chef-Boutonne', value: 'Chef-Boutonne' },
  { label: 'Chefchaouen Province', value: 'Chefchaouen Province' },
  { label: 'Chefchaouene', value: 'Chefchaouene' },
  { label: 'Chef-Lieu', value: 'Chef-Lieu' },
  { label: 'Chegdomyn', value: 'Chegdomyn' },
  { label: 'Chegem', value: 'Chegem' },
  { label: 'Chegem Vtoroy', value: 'Chegem Vtoroy' },
  { label: 'Chegga', value: 'Chegga' },
  { label: 'Chegutu', value: 'Chegutu' },
  { label: 'Chegutu District', value: 'Chegutu District' },
  { label: 'Chehalis', value: 'Chehalis' },
  { label: 'Cheia', value: 'Cheia' },
  { label: 'Cheillé', value: 'Cheillé' },
  { label: 'Chekalin', value: 'Chekalin' },
  { label: 'Chekhov', value: 'Chekhov' },
  { label: 'Chekhov', value: 'Chekhov' },
  { label: 'Chekmagush', value: 'Chekmagush' },
  { label: 'Chela', value: 'Chela' },
  { label: 'Chelak', value: 'Chelak' },
  { label: 'Chelakara', value: 'Chelakara' },
  { label: 'Chelan', value: 'Chelan' },
  { label: 'Chelan County', value: 'Chelan County' },
  { label: 'Chelbasskaya', value: 'Chelbasskaya' },
  { label: 'Cheleiros', value: 'Cheleiros' },
  { label: 'Chelem', value: 'Chelem' },
  { label: 'Cheles', value: 'Cheles' },
  { label: 'Chelford', value: 'Chelford' },
  { label: 'Chelgard', value: 'Chelgard' },
  { label: 'Chelgerd', value: 'Chelgerd' },
  { label: 'Chelghoum El Aïd', value: 'Chelghoum El Aïd' },
  { label: 'Chelința', value: 'Chelința' },
  { label: 'Chella', value: 'Chella' },
  { label: 'Chelles', value: 'Chelles' },
  { label: 'Chełm', value: 'Chełm' },
  { label: 'Chełm Śląski', value: 'Chełm Śląski' },
  { label: 'Chełmek', value: 'Chełmek' },
  { label: 'Chelmer', value: 'Chelmer' },
  { label: 'Chełmiec', value: 'Chełmiec' },
  { label: 'Chełmno', value: 'Chełmno' },
  { label: 'Chelmsford', value: 'Chelmsford' },
  { label: 'Chelmsford', value: 'Chelmsford' },
  { label: 'Chelmsley Wood', value: 'Chelmsley Wood' },
  { label: 'Chełmża', value: 'Chełmża' },
  { label: 'Chelno-Vershiny', value: 'Chelno-Vershiny' },
  { label: 'Chelopech', value: 'Chelopech' },
  { label: 'Chelsea', value: 'Chelsea' },
  { label: 'Chelsea', value: 'Chelsea' },
  { label: 'Chelsea', value: 'Chelsea' },
  { label: 'Chelsea', value: 'Chelsea' },
  { label: 'Chelsea', value: 'Chelsea' },
  { label: 'Chelsea', value: 'Chelsea' },
  { label: 'Chelsea', value: 'Chelsea' },
  { label: 'Chelsea', value: 'Chelsea' },
  { label: 'Chelsea', value: 'Chelsea' },
  { label: 'Chelsea Heights', value: 'Chelsea Heights' },
  { label: 'Cheltenham', value: 'Cheltenham' },
  { label: 'Cheltenham', value: 'Cheltenham' },
  { label: 'Cheltenham', value: 'Cheltenham' },
  { label: 'Chelva', value: 'Chelva' },
  { label: 'Chelyabinsk', value: 'Chelyabinsk' },
  { label: 'Chelyadinovo', value: 'Chelyadinovo' },
  { label: 'Chemainus', value: 'Chemainus' },
  { label: 'Chemal', value: 'Chemal' },
  { label: 'Chemaudin', value: 'Chemaudin' },
  { label: 'Chemax', value: 'Chemax' },
  { label: 'Chemazé', value: 'Chemazé' },
  { label: 'Chembur', value: 'Chembur' },
  { label: 'Chéméré', value: 'Chéméré' },
  { label: 'Chemillé-Melay', value: 'Chemillé-Melay' },
  { label: 'Chemin Grenier', value: 'Chemin Grenier' },
  { label: 'Chemini', value: 'Chemini' },
  { label: 'Chemmumiahpet', value: 'Chemmumiahpet' },
  { label: 'Chemnitz', value: 'Chemnitz' },
  { label: 'Chemodanovka', value: 'Chemodanovka' },
  { label: 'Chemolgan', value: 'Chemolgan' },
  { label: 'Chemung County', value: 'Chemung County' },
  { label: 'Chenango Bridge', value: 'Chenango Bridge' },
  { label: 'Chenango County', value: 'Chenango County' },
  { label: 'Chenaran', value: 'Chenaran' },
  { label: 'Chenaran Shahr', value: 'Chenaran Shahr' },
  { label: 'Chenari', value: 'Chenari' },
  { label: 'Chendrea', value: 'Chendrea' },
  { label: 'Chendu', value: 'Chendu' },
  { label: 'Chêne-Bougeries', value: 'Chêne-Bougeries' },
  { label: 'Chêne-Bourg', value: 'Chêne-Bourg' },
  { label: 'Chênehutte-Trèves-Cunault', value: 'Chênehutte-Trèves-Cunault' },
  { label: 'Cheney', value: 'Cheney' },
  { label: 'Cheney', value: 'Cheney' },
  { label: 'Chengam', value: 'Chengam' },
  { label: 'Chengannur', value: 'Chengannur' },
  { label: 'Chengde', value: 'Chengde' },
  { label: 'Chengde Prefecture', value: 'Chengde Prefecture' },
  { label: 'Chengdu', value: 'Chengdu' },
  { label: 'Chengguan', value: 'Chengguan' },
  { label: 'Chenghua', value: 'Chenghua' },
  { label: 'Chengjiao', value: 'Chengjiao' },
  { label: 'Chengmen', value: 'Chengmen' },
  { label: 'Chengqu', value: 'Chengqu' },
  { label: 'Chengtangcun', value: 'Chengtangcun' },
  { label: 'Chengtougu', value: 'Chengtougu' },
  { label: 'Chengxiang', value: 'Chengxiang' },
  { label: 'Chengyang', value: 'Chengyang' },
  { label: 'Chengzhong', value: 'Chengzhong' },
  { label: 'Chengzihe', value: 'Chengzihe' },
  { label: 'Cheniménil', value: 'Cheniménil' },
  { label: 'Chennai', value: 'Chennai' },
  { label: 'Chennevières-Sur-Marne', value: 'Chennevières-Sur-Marne' },
  { label: 'Chennimalai', value: 'Chennimalai' },
  { label: 'Chenoa', value: 'Chenoa' },
  { label: 'Chenoise', value: 'Chenoise' },
  { label: 'Chenôve', value: 'Chenôve' },
  { label: 'Chenoweth', value: 'Chenoweth' },
  { label: 'Chens-Sur-Léman', value: 'Chens-Sur-Léman' },
  { label: 'Cheny', value: 'Cheny' },
  { label: 'Chenzhou', value: 'Chenzhou' },
  { label: 'Cheonan', value: 'Cheonan' },
  { label: 'Cheonan-Si', value: 'Cheonan-Si' },
  { label: 'Cheongdo-Gun', value: 'Cheongdo-Gun' },
  { label: 'Cheongju-Si', value: 'Cheongju-Si' },
  { label: 'Cheongsong Gun', value: 'Cheongsong Gun' },
  { label: 'Cheongsong-Gun', value: 'Cheongsong-Gun' },
  { label: 'Cheongyang-Gun', value: 'Cheongyang-Gun' },
  { label: 'Cheorwon-Gun', value: 'Cheorwon-Gun' },
  { label: 'Chepachet', value: 'Chepachet' },
  { label: 'Chepareria', value: 'Chepareria' },
  { label: 'Chepelare', value: 'Chepelare' },
  { label: 'Chepen', value: 'Chepen' },
  { label: 'Chépica', value: 'Chépica' },
  { label: 'Chepillo', value: 'Chepillo' },
  { label: 'Chepo', value: 'Chepo' },
  { label: 'Chepstow', value: 'Chepstow' },
  { label: 'Cheptainville', value: 'Cheptainville' },
  { label: 'Chépy', value: 'Chépy' },
  { label: 'Chequilla', value: 'Chequilla' },
  { label: 'Cher', value: 'Cher' },
  { label: 'Chera', value: 'Chera' },
  { label: 'Chérac', value: 'Chérac' },
  { label: 'Cheraga', value: 'Cheraga' },
  { label: 'Cherán', value: 'Cherán' },
  { label: 'Cherán Atzicuirín (Cheranástico)', value: 'Cherán Atzicuirín (Cheranástico)' },
  { label: 'Cherasco', value: 'Cherasco' },
  { label: 'Cherat Cantonement', value: 'Cherat Cantonement' },
  { label: 'Chéraute', value: 'Chéraute' },
  { label: 'Cheraw', value: 'Cheraw' },
  { label: 'Cherbourg', value: 'Cherbourg' },
  { label: 'Cherbourg-Octeville', value: 'Cherbourg-Octeville' },
  { label: 'Chercos', value: 'Chercos' },
  { label: 'Cherdaklinskiy Rayon', value: 'Cherdaklinskiy Rayon' },
  { label: 'Cherdakly', value: 'Cherdakly' },
  { label: 'Cherdyn’', value: 'Cherdyn’' },
  { label: 'Cherechiu', value: 'Cherechiu' },
  { label: 'Cherekskiy Rayon', value: 'Cherekskiy Rayon' },
  { label: 'Chereluș', value: 'Chereluș' },
  { label: 'Cheremisinovo', value: 'Cheremisinovo' },
  { label: 'Cheremisinovskiy Rayon', value: 'Cheremisinovskiy Rayon' },
  { label: 'Cheremkhovo', value: 'Cheremkhovo' },
  { label: 'Cheremkhovskiy Rayon', value: 'Cheremkhovskiy Rayon' },
  { label: 'Cheremnoye', value: 'Cheremnoye' },
  { label: 'Cheremshan', value: 'Cheremshan' },
  { label: 'Cheremshanka', value: 'Cheremshanka' },
  { label: 'Cheremshanskiy Rayon', value: 'Cheremshanskiy Rayon' },
  { label: 'Cherëmukhovo', value: 'Cherëmukhovo' },
  { label: 'Cheremule', value: 'Cheremule' },
  { label: 'Cherëmushki', value: 'Cherëmushki' },
  { label: 'Cherëmushki', value: 'Cherëmushki' },
  { label: 'Cheremushskiy', value: 'Cheremushskiy' },
  { label: 'Chéreng', value: 'Chéreng' },
  { label: 'Cherepanovo', value: 'Cherepanovo' },
  { label: 'Cherepet’', value: 'Cherepet’' },
  { label: 'Chereponi', value: 'Chereponi' },
  { label: 'Cherepovets', value: 'Cherepovets' },
  { label: 'Cherepovetskiy Rayon', value: 'Cherepovetskiy Rayon' },
  { label: 'Cherga', value: 'Cherga' },
  { label: 'Cheria', value: 'Cheria' },
  { label: 'Cherisy', value: 'Cherisy' },
  { label: 'Cherkasskoye', value: 'Cherkasskoye' },
  { label: 'Cherkasy', value: 'Cherkasy' },
  { label: 'Cherkessk', value: 'Cherkessk' },
  { label: 'Cherkizovo', value: 'Cherkizovo' },
  { label: 'Cherlak', value: 'Cherlak' },
  { label: 'Chermahin', value: 'Chermahin' },
  { label: 'Chermen', value: 'Chermen' },
  { label: 'Chermignac', value: 'Chermignac' },
  { label: 'Chermignon-D’En Haut', value: 'Chermignon-D’En Haut' },
  { label: 'Chermoz', value: 'Chermoz' },
  { label: 'Chermside', value: 'Chermside' },
  { label: 'Chermside West', value: 'Chermside West' },
  { label: 'Chern’', value: 'Chern’' },
  { label: 'Chërnaya Kholunitsa', value: 'Chërnaya Kholunitsa' },
  { label: 'Chernaya Rechka', value: 'Chernaya Rechka' },
  { label: 'Chernechii Iar', value: 'Chernechii Iar' },
  { label: 'Chernelytsya', value: 'Chernelytsya' },
  { label: 'Cherniakhiv', value: 'Cherniakhiv' },
  { label: 'Cherniakhiv', value: 'Cherniakhiv' },
  { label: 'Chernigovka', value: 'Chernigovka' },
  { label: 'Chernigovskiy Rayon', value: 'Chernigovskiy Rayon' },
  { label: 'Chernihiv', value: 'Chernihiv' },
  { label: 'Chernihiv Raion', value: 'Chernihiv Raion' },
  { label: 'Chernihivka Raion', value: 'Chernihivka Raion' },
  { label: 'Chernihivs’Ka Mis’Krada', value: 'Chernihivs’Ka Mis’Krada' },
  { label: 'Chernitsyno', value: 'Chernitsyno' },
  { label: 'Chernivets’Kyy Rayon', value: 'Chernivets’Kyy Rayon' },
  { label: 'Chernivtsi', value: 'Chernivtsi' },
  { label: 'Chernivtsi', value: 'Chernivtsi' },
  { label: 'Chernivtsi Municipality', value: 'Chernivtsi Municipality' },
  { label: 'Cherniyiv', value: 'Cherniyiv' },
  { label: 'Chernogolovka', value: 'Chernogolovka' },
  { label: 'Chernogorsk', value: 'Chernogorsk' },
  { label: 'Chernoistochinsk', value: 'Chernoistochinsk' },
  { label: 'Chernolesskoye', value: 'Chernolesskoye' },
  { label: 'Chernomorets', value: 'Chernomorets' },
  { label: 'Chernomorskiy', value: 'Chernomorskiy' },
  { label: 'Chernomorskiy Rayon', value: 'Chernomorskiy Rayon' },
  { label: 'Chernomorskoye', value: 'Chernomorskoye' },
  { label: 'Chernopolye', value: 'Chernopolye' },
  { label: 'Chernovo', value: 'Chernovo' },
  { label: 'Chernoyerkovskaya', value: 'Chernoyerkovskaya' },
  { label: 'Chernozemnoye', value: 'Chernozemnoye' },
  { label: 'Chernukha', value: 'Chernukha' },
  { label: 'Chernushka', value: 'Chernushka' },
  { label: 'Chernyakhivka', value: 'Chernyakhivka' },
  { label: 'Chernyakhivskyy Rayon', value: 'Chernyakhivskyy Rayon' },
  { label: 'Chernyakhovsk', value: 'Chernyakhovsk' },
  { label: 'Chernyanka', value: 'Chernyanka' },
  { label: 'Chernyanskiy Rayon', value: 'Chernyanskiy Rayon' },
  { label: 'Chernyatyn', value: 'Chernyatyn' },
  { label: 'Chernyshëvka', value: 'Chernyshëvka' },
  { label: 'Chernyshevo', value: 'Chernyshevo' },
  { label: 'Chernyshevsk', value: 'Chernyshevsk' },
  { label: 'Chernyshevskiy', value: 'Chernyshevskiy' },
  { label: 'Chernyshkovsky', value: 'Chernyshkovsky' },
  { label: 'Cherokee', value: 'Cherokee' },
  { label: 'Cherokee', value: 'Cherokee' },
  { label: 'Cherokee', value: 'Cherokee' },
  { label: 'Cherokee', value: 'Cherokee' },
  { label: 'Cherokee County', value: 'Cherokee County' },
  { label: 'Cherokee County', value: 'Cherokee County' },
  { label: 'Cherokee County', value: 'Cherokee County' },
  { label: 'Cherokee County', value: 'Cherokee County' },
  { label: 'Cherokee County', value: 'Cherokee County' },
  { label: 'Cherokee County', value: 'Cherokee County' },
  { label: 'Cherokee County', value: 'Cherokee County' },
  { label: 'Cherokee County', value: 'Cherokee County' },
  { label: 'Cherokee Village', value: 'Cherokee Village' },
  { label: 'Chéroy', value: 'Chéroy' },
  { label: 'Cherpulassery', value: 'Cherpulassery' },
  { label: 'Cherrapunji', value: 'Cherrapunji' },
  { label: 'Cherré', value: 'Cherré' },
  { label: 'Cherrueix', value: 'Cherrueix' },
  { label: 'Cherry Burton', value: 'Cherry Burton' },
  { label: 'Cherry County', value: 'Cherry County' },
  { label: 'Cherry Creek', value: 'Cherry Creek' },
  { label: 'Cherry Gardens', value: 'Cherry Gardens' },
  { label: 'Cherry Grove', value: 'Cherry Grove' },
  { label: 'Cherry Hill', value: 'Cherry Hill' },
  { label: 'Cherry Hill', value: 'Cherry Hill' },
  { label: 'Cherry Hill Mall', value: 'Cherry Hill Mall' },
  { label: 'Cherry Hills Village', value: 'Cherry Hills Village' },
  { label: 'Cherry Orchard', value: 'Cherry Orchard' },
  { label: 'Cherry Valley', value: 'Cherry Valley' },
  { label: 'Cherry Valley', value: 'Cherry Valley' },
  { label: 'Cherrybrook', value: 'Cherrybrook' },
  { label: 'Cherryfield', value: 'Cherryfield' },
  { label: 'Cherryland', value: 'Cherryland' },
  { label: 'Cherryvale', value: 'Cherryvale' },
  { label: 'Cherryvale', value: 'Cherryvale' },
  { label: 'Cherryville', value: 'Cherryville' },
  { label: 'Cherryville', value: 'Cherryville' },
  { label: 'Cherryville', value: 'Cherryville' },
  { label: 'Cherskiy', value: 'Cherskiy' },
  { label: 'Chert/Xert', value: 'Chert/Xert' },
  { label: 'Chertanovo Yuzhnoye', value: 'Chertanovo Yuzhnoye' },
  { label: 'Cherthala', value: 'Cherthala' },
  { label: 'Chertkovo', value: 'Chertkovo' },
  { label: 'Chertsey', value: 'Chertsey' },
  { label: 'Chertsey', value: 'Chertsey' },
  { label: 'Cherusti', value: 'Cherusti' },
  { label: 'Cherven Bryag', value: 'Cherven Bryag' },
  { label: 'Cherves-Richemont', value: 'Cherves-Richemont' },
  { label: 'Cherveux', value: 'Cherveux' },
  { label: 'Chervishevo', value: 'Chervishevo' },
  { label: 'Chervona Sloboda', value: 'Chervona Sloboda' },
  { label: 'Chervone', value: 'Chervone' },
  { label: 'Chervone', value: 'Chervone' },
  { label: 'Chervonohrad', value: 'Chervonohrad' },
  { label: 'Chervonohryhorivka', value: 'Chervonohryhorivka' },
  { label: 'Chervonopartyzans’K', value: 'Chervonopartyzans’K' },
  { label: 'Chervonoye', value: 'Chervonoye' },
  { label: 'Chervonyy Oskil', value: 'Chervonyy Oskil' },
  { label: 'Chervyen’', value: 'Chervyen’' },
  { label: 'Chervyen’Ski Rayon', value: 'Chervyen’Ski Rayon' },
  { label: 'Cherykaw', value: 'Cherykaw' },
  { label: 'Cherykawski Rayon', value: 'Cherykawski Rayon' },
  { label: 'Chesaning', value: 'Chesaning' },
  { label: 'Chesapeake', value: 'Chesapeake' },
  { label: 'Chesapeake', value: 'Chesapeake' },
  { label: 'Chesapeake Beach', value: 'Chesapeake Beach' },
  { label: 'Chesapeake Ranch Estates', value: 'Chesapeake Ranch Estates' },
  { label: 'Cheseaux', value: 'Cheseaux' },
  { label: 'Cheșereu', value: 'Cheșereu' },
  { label: 'Chesham', value: 'Chesham' },
  { label: 'Cheshire', value: 'Cheshire' },
  { label: 'Cheshire County', value: 'Cheshire County' },
  { label: 'Cheshire East', value: 'Cheshire East' },
  { label: 'Cheshire Village', value: 'Cheshire Village' },
  { label: 'Cheshire West And Chester', value: 'Cheshire West And Chester' },
  { label: 'Cheshmeh Malek', value: 'Cheshmeh Malek' },
  { label: 'Cheshmeh Pahn-E Nanaj', value: 'Cheshmeh Pahn-E Nanaj' },
  { label: 'Cheshmeh Qandab', value: 'Cheshmeh Qandab' },
  { label: 'Cheshmeh Qareh', value: 'Cheshmeh Qareh' },
  { label: 'Cheshmeh Qassaban', value: 'Cheshmeh Qassaban' },
  { label: 'Cheshmeh Valad', value: 'Cheshmeh Valad' },
  { label: 'Cheshmeh Zowraq', value: 'Cheshmeh Zowraq' },
  { label: 'Cheshunt', value: 'Cheshunt' },
  { label: 'Chesilhurst', value: 'Chesilhurst' },
  { label: 'Chesinț', value: 'Chesinț' },
  { label: 'Chesma', value: 'Chesma' },
  { label: 'Chesnokovka', value: 'Chesnokovka' },
  { label: 'Chessington', value: 'Chessington' },
  { label: 'Chessy', value: 'Chessy' },
  { label: 'Chessy', value: 'Chessy' },
  { label: 'Cheste', value: 'Cheste' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chester Castle', value: 'Chester Castle' },
  { label: 'Chester Center', value: 'Chester Center' },
  { label: 'Chester County', value: 'Chester County' },
  { label: 'Chester County', value: 'Chester County' },
  { label: 'Chester County', value: 'Chester County' },
  { label: 'Chester Heights', value: 'Chester Heights' },
  { label: 'Chester Hill', value: 'Chester Hill' },
  { label: 'Chester Springs', value: 'Chester Springs' },
  { label: 'Chesterbrook', value: 'Chesterbrook' },
  { label: 'Chesterfield', value: 'Chesterfield' },
  { label: 'Chesterfield', value: 'Chesterfield' },
  { label: 'Chesterfield', value: 'Chesterfield' },
  { label: 'Chesterfield', value: 'Chesterfield' },
  { label: 'Chesterfield', value: 'Chesterfield' },
  { label: 'Chesterfield', value: 'Chesterfield' },
  { label: 'Chesterfield County', value: 'Chesterfield County' },
  { label: 'Chesterfield County', value: 'Chesterfield County' },
  { label: 'Chesterfield Court House', value: 'Chesterfield Court House' },
  { label: 'Chesterland', value: 'Chesterland' },
  { label: 'Chester-Le-Street', value: 'Chester-Le-Street' },
  { label: 'Chestermere', value: 'Chestermere' },
  { label: 'Chesterton', value: 'Chesterton' },
  { label: 'Chestertown', value: 'Chestertown' },
  { label: 'Chesterville', value: 'Chesterville' },
  { label: 'Chestnut Ridge', value: 'Chestnut Ridge' },
  { label: 'Cheswick', value: 'Cheswick' },
  { label: 'Cheswold', value: 'Cheswold' },
  { label: 'Cheț', value: 'Cheț' },
  { label: 'Chețani', value: 'Chețani' },
  { label: 'Chetek', value: 'Chetek' },
  { label: 'Chetopa', value: 'Chetopa' },
  { label: 'Chetouane', value: 'Chetouane' },
  { label: 'Chetput', value: 'Chetput' },
  { label: 'Chettipalaiyam', value: 'Chettipalaiyam' },
  { label: 'Chetumal', value: 'Chetumal' },
  { label: 'Chetwayi', value: 'Chetwayi' },
  { label: 'Chetwynd', value: 'Chetwynd' },
  { label: 'Cheung Prey', value: 'Cheung Prey' },
  { label: 'Cheung Sha Wan', value: 'Cheung Sha Wan' },
  { label: 'Cheux', value: 'Cheux' },
  { label: 'Chevaigné', value: 'Chevaigné' },
  { label: 'Chevak', value: 'Chevak' },
  { label: 'Cheval', value: 'Cheval' },
  { label: 'Cheval-Blanc', value: 'Cheval-Blanc' },
  { label: 'Chevanceaux', value: 'Chevanceaux' },
  { label: 'Chevannes', value: 'Chevannes' },
  { label: 'Chevereşu Mare', value: 'Chevereşu Mare' },
  { label: 'Cheverly', value: 'Cheverly' },
  { label: 'Cheverny', value: 'Cheverny' },
  { label: 'Chevigny-Saint-Sauveur', value: 'Chevigny-Saint-Sauveur' },
  { label: 'Chevillon', value: 'Chevillon' },
  { label: 'Chevillon-Sur-Huillard', value: 'Chevillon-Sur-Huillard' },
  { label: 'Chevilly', value: 'Chevilly' },
  { label: 'Chevilly', value: 'Chevilly' },
  { label: 'Chevilly-Larue', value: 'Chevilly-Larue' },
  { label: 'Cheviot', value: 'Cheviot' },
  { label: 'Chevremont', value: 'Chevremont' },
  { label: 'Chèvremont', value: 'Chèvremont' },
  { label: 'Chevreuse', value: 'Chevreuse' },
  { label: 'Chevrières', value: 'Chevrières' },
  { label: 'Chevry', value: 'Chevry' },
  { label: 'Chevry-Cossigny', value: 'Chevry-Cossigny' },
  { label: 'Chevy Chase', value: 'Chevy Chase' },
  { label: 'Chevy Chase', value: 'Chevy Chase' },
  { label: 'Chevy Chase Heights', value: 'Chevy Chase Heights' },
  { label: 'Chevy Chase Village', value: 'Chevy Chase Village' },
  { label: 'Chew Magna', value: 'Chew Magna' },
  { label: 'Chewelah', value: 'Chewelah' },
  { label: 'Chewton', value: 'Chewton' },
  { label: 'Chexbres', value: 'Chexbres' },
  { label: 'Cheyenne', value: 'Cheyenne' },
  { label: 'Cheyenne', value: 'Cheyenne' },
  { label: 'Cheyenne County', value: 'Cheyenne County' },
  { label: 'Cheyenne County', value: 'Cheyenne County' },
  { label: 'Cheyenne County', value: 'Cheyenne County' },
  { label: 'Cheyenne Wells', value: 'Cheyenne Wells' },
  { label: 'Cheyyar', value: 'Cheyyar' },
  { label: 'Cheyyur', value: 'Cheyyur' },
  { label: 'Chezani', value: 'Chezani' },
  { label: 'Chézard-Saint-Martin', value: 'Chézard-Saint-Martin' },
  { label: 'Chézy-Sur-Marne', value: 'Chézy-Sur-Marne' },
  { label: 'Chhabra', value: 'Chhabra' },
  { label: 'Chhachhrauli', value: 'Chhachhrauli' },
  { label: 'Chhala', value: 'Chhala' },
  { label: 'Chhapar', value: 'Chhapar' },
  { label: 'Chhaprauli', value: 'Chhaprauli' },
  { label: 'Chharra', value: 'Chharra' },
  { label: 'Chhata', value: 'Chhata' },
  { label: 'Chhatapur', value: 'Chhatapur' },
  { label: 'Chhatarpur', value: 'Chhatarpur' },
  { label: 'Chhibramau', value: 'Chhibramau' },
  { label: 'Chhindwara', value: 'Chhindwara' },
  { label: 'Chhitauni', value: 'Chhitauni' },
  { label: 'Chhor', value: 'Chhor' },
  { label: 'Chhota Udepur', value: 'Chhota Udepur' },
  { label: 'Chhoti Sadri', value: 'Chhoti Sadri' },
  { label: 'Chhouk District', value: 'Chhouk District' },
  { label: 'Chhuikhadan', value: 'Chhuikhadan' },
  { label: 'Chhutmalpur', value: 'Chhutmalpur' },
  { label: 'Chía', value: 'Chía' },
  { label: 'Chía', value: 'Chía' },
  { label: 'Chiador', value: 'Chiador' },
  { label: 'Chiaia', value: 'Chiaia' },
  { label: 'Chiaiano', value: 'Chiaiano' },
  { label: 'Chiajna', value: 'Chiajna' },
  { label: 'Chialamberto', value: 'Chialamberto' },
  { label: 'Chiampo', value: 'Chiampo' },
  { label: 'Chianche', value: 'Chianche' },
  { label: 'Chianchitta-Pallio', value: 'Chianchitta-Pallio' },
  { label: 'Chianchitta-Trappitello', value: 'Chianchitta-Trappitello' },
  { label: 'Chianciano Terme', value: 'Chianciano Terme' },
  { label: 'Chiang Kham', value: 'Chiang Kham' },
  { label: 'Chiang Khong', value: 'Chiang Khong' },
  { label: 'Chiang Klang', value: 'Chiang Klang' },
  { label: 'Chiang Mai', value: 'Chiang Mai' },
  { label: 'Chiang Rai', value: 'Chiang Rai' },
  { label: 'Chiang Saen', value: 'Chiang Saen' },
  { label: 'Chianni', value: 'Chianni' },
  { label: 'Chianocco', value: 'Chianocco' },
  { label: 'Chiantla', value: 'Chiantla' },
  { label: 'Chiapa De Corzo', value: 'Chiapa De Corzo' },
  { label: 'Chiapetta', value: 'Chiapetta' },
  { label: 'Chiapilla', value: 'Chiapilla' },
  { label: 'Chiaramonte Gulfi', value: 'Chiaramonte Gulfi' },
  { label: 'Chiaramonti', value: 'Chiaramonti' },
  { label: 'Chiarano', value: 'Chiarano' },
  { label: 'Chiaravalle', value: 'Chiaravalle' },
  { label: 'Chiaravalle', value: 'Chiaravalle' },
  { label: 'Chiaravalle Centrale', value: 'Chiaravalle Centrale' },
  { label: 'Chiari', value: 'Chiari' },
  { label: 'Chiaromonte', value: 'Chiaromonte' },
  { label: 'Chiassa-Tregozzano', value: 'Chiassa-Tregozzano' },
  { label: 'Chiasso', value: 'Chiasso' },
  { label: 'Chiat’Ura', value: 'Chiat’Ura' },
  { label: 'Chiauci', value: 'Chiauci' },
  { label: 'Chiaucingo', value: 'Chiaucingo' },
  { label: 'Chiaulis', value: 'Chiaulis' },
  { label: 'Chiautla', value: 'Chiautla' },
  { label: 'Chiautla De Tapia', value: 'Chiautla De Tapia' },
  { label: 'Chiavari', value: 'Chiavari' },
  { label: 'Chiavenna', value: 'Chiavenna' },
  { label: 'Chiaverano', value: 'Chiaverano' },
  { label: 'Chiayi', value: 'Chiayi' },
  { label: 'Chiayi County', value: 'Chiayi County' },
  { label: 'Chiba', value: 'Chiba' },
  { label: 'Chibed', value: 'Chibed' },
  { label: 'Chibia', value: 'Chibia' },
  { label: 'Chibombo', value: 'Chibombo' },
  { label: 'Chibombo District', value: 'Chibombo District' },
  { label: 'Chibougamau', value: 'Chibougamau' },
  { label: 'Chibuto', value: 'Chibuto' },
  { label: 'Chicacao', value: 'Chicacao' },
  { label: 'Chicago', value: 'Chicago' },
  { label: 'Chicago Heights', value: 'Chicago Heights' },
  { label: 'Chicago Lawn', value: 'Chicago Lawn' },
  { label: 'Chicago Loop', value: 'Chicago Loop' },
  { label: 'Chicago Ridge', value: 'Chicago Ridge' },
  { label: 'Chicahua', value: 'Chicahua' },
  { label: 'Chicahuaxtla', value: 'Chicahuaxtla' },
  { label: 'Chicalim', value: 'Chicalim' },
  { label: 'Chicama', value: 'Chicama' },
  { label: 'Chicamán', value: 'Chicamán' },
  { label: 'Chicapa De Castro', value: 'Chicapa De Castro' },
  { label: 'Chicavasco', value: 'Chicavasco' },
  { label: 'Chicbul', value: 'Chicbul' },
  { label: 'Chichaoua', value: 'Chichaoua' },
  { label: 'Chichatla', value: 'Chichatla' },
  { label: 'Chichawatni', value: 'Chichawatni' },
  { label: 'Chiché', value: 'Chiché' },
  { label: 'Chiché', value: 'Chiché' },
  { label: 'Chichén-Itzá', value: 'Chichén-Itzá' },
  { label: 'Chichester', value: 'Chichester' },
  { label: 'Chichester', value: 'Chichester' },
  { label: 'Chichibu', value: 'Chichibu' },
  { label: 'Chichibu-Shi', value: 'Chichibu-Shi' },
  { label: 'Chichica', value: 'Chichica' },
  { label: 'Chichicapa', value: 'Chichicapa' },
  { label: 'Chichicapa', value: 'Chichicapa' },
  { label: 'Chichicastenango', value: 'Chichicastenango' },
  { label: 'Chichicaxtle', value: 'Chichicaxtle' },
  { label: 'Chichicuautla', value: 'Chichicuautla' },
  { label: 'Chichigalpa', value: 'Chichigalpa' },
  { label: 'Chichihualco', value: 'Chichihualco' },
  { label: 'Chichimequillas', value: 'Chichimequillas' },
  { label: 'Chichimequillas', value: 'Chichimequillas' },
  { label: 'Chichimequillas', value: 'Chichimequillas' },
  { label: 'Chichimila', value: 'Chichimila' },
  { label: 'Chichinales', value: 'Chichinales' },
  { label: 'Chichiquila', value: 'Chichiquila' },
  { label: 'Chichiriviche', value: 'Chichiriviche' },
  { label: 'Chichiş', value: 'Chichiş' },
  { label: 'Chichli', value: 'Chichli' },
  { label: 'Chicholi', value: 'Chicholi' },
  { label: 'Chichondi Patil', value: 'Chichondi Patil' },
  { label: 'Chickamauga', value: 'Chickamauga' },
  { label: 'Chickasaw', value: 'Chickasaw' },
  { label: 'Chickasaw County', value: 'Chickasaw County' },
  { label: 'Chickasaw County', value: 'Chickasaw County' },
  { label: 'Chickasha', value: 'Chickasha' },
  { label: 'Chickerell', value: 'Chickerell' },
  { label: 'Chicksands', value: 'Chicksands' },
  { label: 'Chiclana De La Frontera', value: 'Chiclana De La Frontera' },
  { label: 'Chiclana De Segura', value: 'Chiclana De Segura' },
  { label: 'Chiclayo', value: 'Chiclayo' },
  { label: 'Chico', value: 'Chico' },
  { label: 'Chico', value: 'Chico' },
  { label: 'Chico', value: 'Chico' },
  { label: 'Chicoana', value: 'Chicoana' },
  { label: 'Chicoasén', value: 'Chicoasén' },
  { label: 'Chicola', value: 'Chicola' },
  { label: 'Chicoloapan', value: 'Chicoloapan' },
  { label: 'Chicomba', value: 'Chicomba' },
  { label: 'Chicomuselo', value: 'Chicomuselo' },
  { label: 'Chiconamel', value: 'Chiconamel' },
  { label: 'Chiconamel', value: 'Chiconamel' },
  { label: 'Chiconcuac', value: 'Chiconcuac' },
  { label: 'Chiconcuac', value: 'Chiconcuac' },
  { label: 'Chiconcuautla', value: 'Chiconcuautla' },
  { label: 'Chiconquiaco', value: 'Chiconquiaco' },
  { label: 'Chicontepec', value: 'Chicontepec' },
  { label: 'Chicontla', value: 'Chicontla' },
  { label: 'Chicopee', value: 'Chicopee' },
  { label: 'Chicora', value: 'Chicora' },
  { label: 'Chicot County', value: 'Chicot County' },
  { label: 'Chicualoque', value: 'Chicualoque' },
  { label: 'Chicuasen', value: 'Chicuasen' },
  { label: 'Chicumtantic', value: 'Chicumtantic' },
  { label: 'Chicxulub Pueblo', value: 'Chicxulub Pueblo' },
  { label: 'Chicxulub Puerto', value: 'Chicxulub Puerto' },
  { label: 'Chidambaram', value: 'Chidambaram' },
  { label: 'Chidawa', value: 'Chidawa' },
  { label: 'Chiddingfold', value: 'Chiddingfold' },
  { label: 'Chidlow', value: 'Chidlow' },
  { label: 'Chiefland', value: 'Chiefland' },
  { label: 'Chieming', value: 'Chieming' },
  { label: 'Chienes', value: 'Chienes' },
  { label: 'Chiepetepec', value: 'Chiepetepec' },
  { label: 'Chier', value: 'Chier' },
  { label: 'Chieri', value: 'Chieri' },
  { label: 'Chierry', value: 'Chierry' },
  { label: 'Chiesa', value: 'Chiesa' },
  { label: 'Chiesa', value: 'Chiesa' },
  { label: 'Chiesa Di Macra', value: 'Chiesa Di Macra' },
  { label: 'Chiesa In Valmalenco', value: 'Chiesa In Valmalenco' },
  { label: 'Chiesa Nuova', value: 'Chiesa Nuova' },
  { label: 'Chiesanuova', value: 'Chiesanuova' },
  { label: 'Chieşd', value: 'Chieşd' },
  { label: 'Chiesina Uzzanese', value: 'Chiesina Uzzanese' },
  { label: 'Chiesino-Collodi', value: 'Chiesino-Collodi' },
  { label: 'Chieti', value: 'Chieti' },
  { label: 'Chietla', value: 'Chietla' },
  { label: 'Chieuti', value: 'Chieuti' },
  { label: 'Chieve', value: 'Chieve' },
  { label: 'Chièvres', value: 'Chièvres' },
  { label: 'Chifeng', value: 'Chifeng' },
  { label: 'Chiffa', value: 'Chiffa' },
  { label: 'Chifley', value: 'Chifley' },
  { label: 'Chifley', value: 'Chifley' },
  { label: 'Chigasaki', value: 'Chigasaki' },
  { label: 'Chigasaki Shi', value: 'Chigasaki Shi' },
  { label: 'Chigmecatitlán', value: 'Chigmecatitlán' },
  { label: 'Chignahuapan', value: 'Chignahuapan' },
  { label: 'Chignayhua', value: 'Chignayhua' },
  { label: 'Chignolo Disola', value: 'Chignolo Disola' },
  { label: 'Chignolo Po', value: 'Chignolo Po' },
  { label: 'Chigoré', value: 'Chigoré' },
  { label: 'Chigorodó', value: 'Chigorodó' },
  { label: 'Chiguayante', value: 'Chiguayante' },
  { label: 'Chiguirí Arriba', value: 'Chiguirí Arriba' },
  { label: 'Chigwell', value: 'Chigwell' },
  { label: 'Chigwell', value: 'Chigwell' },
  { label: 'Chiheru De Jos', value: 'Chiheru De Jos' },
  { label: 'Chiheru De Sus', value: 'Chiheru De Sus' },
  { label: 'Chihuahua', value: 'Chihuahua' },
  { label: 'Chihuahua', value: 'Chihuahua' },
  { label: 'Chihuahuita', value: 'Chihuahuita' },
  { label: 'Chik Ballapur', value: 'Chik Ballapur' },
  { label: 'Chikhli', value: 'Chikhli' },
  { label: 'Chikhli (Buldhana)', value: 'Chikhli (Buldhana)' },
  { label: 'Chikhli (Jalna)', value: 'Chikhli (Jalna)' },
  { label: 'Chikindzonot', value: 'Chikindzonot' },
  { label: 'Chikitigarh', value: 'Chikitigarh' },
  { label: 'Chikkaballapur', value: 'Chikkaballapur' },
  { label: 'Chikkamagaluru', value: 'Chikkamagaluru' },
  { label: 'Chiknayakanhalli', value: 'Chiknayakanhalli' },
  { label: 'Chikodi', value: 'Chikodi' },
  { label: 'Chikola', value: 'Chikola' },
  { label: 'Chikugo Shi', value: 'Chikugo Shi' },
  { label: 'Chikuhoku', value: 'Chikuhoku' },
  { label: 'Chikuma', value: 'Chikuma' },
  { label: 'Chikuma Shi', value: 'Chikuma Shi' },
  { label: 'Chikusei', value: 'Chikusei' },
  { label: 'Chikusei-Shi', value: 'Chikusei-Shi' },
  { label: 'Chikushino-Shi', value: 'Chikushino-Shi' },
  { label: 'Chikwawa', value: 'Chikwawa' },
  { label: 'Chikwawa District', value: 'Chikwawa District' },
  { label: 'Chila', value: 'Chila' },
  { label: 'Chila De Juárez', value: 'Chila De Juárez' },
  { label: 'Chila De La Sal', value: 'Chila De La Sal' },
  { label: 'Chilacachapa', value: 'Chilacachapa' },
  { label: 'Chilacayote', value: 'Chilacayote' },
  { label: 'Chilakalurupet', value: 'Chilakalurupet' },
  { label: 'Chilapa', value: 'Chilapa' },
  { label: 'Chilapa', value: 'Chilapa' },
  { label: 'Chilapa De Álvarez', value: 'Chilapa De Álvarez' },
  { label: 'Chilaw', value: 'Chilaw' },
  { label: 'Chilca', value: 'Chilca' },
  { label: 'Chilches', value: 'Chilches' },
  { label: 'Chilches/Xilxes', value: 'Chilches/Xilxes' },
  { label: 'Chilchota', value: 'Chilchota' },
  { label: 'Chilchota', value: 'Chilchota' },
  { label: 'Chilchotla', value: 'Chilchotla' },
  { label: 'Chilcompton', value: 'Chilcompton' },
  { label: 'Chilcuautla', value: 'Chilcuautla' },
  { label: 'Child Okeford', value: 'Child Okeford' },
  { label: 'Childers', value: 'Childers' },
  { label: 'Childersburg', value: 'Childersburg' },
  { label: 'Childress', value: 'Childress' },
  { label: 'Childress County', value: 'Childress County' },
  { label: 'Chile Chico', value: 'Chile Chico' },
  { label: 'Chilecito', value: 'Chilecito' },
  { label: 'Chilgok-Gun', value: 'Chilgok-Gun' },
  { label: 'Chilhowie', value: 'Chilhowie' },
  { label: 'Chilia Veche', value: 'Chilia Veche' },
  { label: 'Chilibre', value: 'Chilibre' },
  { label: 'Chilii', value: 'Chilii' },
  { label: 'Chiliile', value: 'Chiliile' },
  { label: 'Chililabombwe', value: 'Chililabombwe' },
  { label: 'Chililico', value: 'Chililico' },
  { label: 'Chilla', value: 'Chilla' },
  { label: 'Chillán', value: 'Chillán' },
  { label: 'Chillán Viejo', value: 'Chillán Viejo' },
  { label: 'Chillarón De Cuenca', value: 'Chillarón De Cuenca' },
  { label: 'Chillarón Del Rey', value: 'Chillarón Del Rey' },
  { label: 'Chilleurs-Aux-Bois', value: 'Chilleurs-Aux-Bois' },
  { label: 'Chillicothe', value: 'Chillicothe' },
  { label: 'Chillicothe', value: 'Chillicothe' },
  { label: 'Chillicothe', value: 'Chillicothe' },
  { label: 'Chilliwack', value: 'Chilliwack' },
  { label: 'Chillón', value: 'Chillón' },
  { label: 'Chilluévar', value: 'Chilluévar' },
  { label: 'Chillum', value: 'Chillum' },
  { label: 'Chillupar', value: 'Chillupar' },
  { label: 'Chilly', value: 'Chilly' },
  { label: 'Chilly-Mazarin', value: 'Chilly-Mazarin' },
  { label: 'Chiloeches', value: 'Chiloeches' },
  { label: 'Chiloljá', value: 'Chiloljá' },
  { label: 'Chilón', value: 'Chilón' },
  { label: 'Chilpancingo', value: 'Chilpancingo' },
  { label: 'Chiltepec De Hidalgo', value: 'Chiltepec De Hidalgo' },
  { label: 'Chiltern', value: 'Chiltern' },
  { label: 'Chilton', value: 'Chilton' },
  { label: 'Chilton County', value: 'Chilton County' },
  { label: 'Chilton Foliat', value: 'Chilton Foliat' },
  { label: 'Chiltoyac', value: 'Chiltoyac' },
  { label: 'Chilworth', value: 'Chilworth' },
  { label: 'Chima', value: 'Chima' },
  { label: 'Chima', value: 'Chima' },
  { label: 'Chimá', value: 'Chimá' },
  { label: 'Chimala', value: 'Chimala' },
  { label: 'Chimalapa', value: 'Chimalapa' },
  { label: 'Chimalpa Viejo', value: 'Chimalpa Viejo' },
  { label: 'Chimalpa Y Tlalayote', value: 'Chimalpa Y Tlalayote' },
  { label: 'Chimaltenango', value: 'Chimaltenango' },
  { label: 'Chimaltitán', value: 'Chimaltitán' },
  { label: 'Chimán', value: 'Chimán' },
  { label: 'Chimanimani', value: 'Chimanimani' },
  { label: 'Chimanimani District', value: 'Chimanimani District' },
  { label: 'Chimay', value: 'Chimay' },
  { label: 'Chimayo', value: 'Chimayo' },
  { label: 'Chimbarongo', value: 'Chimbarongo' },
  { label: 'Chimbas', value: 'Chimbas' },
  { label: 'Chimbote', value: 'Chimbote' },
  { label: 'Chimeneas', value: 'Chimeneas' },
  { label: 'Chimhucum', value: 'Chimhucum' },
  { label: 'Chimichagua', value: 'Chimichagua' },
  { label: 'Chimilin', value: 'Chimilin' },
  { label: 'Chimillas', value: 'Chimillas' },
  { label: 'Chimoio', value: 'Chimoio' },
  { label: 'Chimoré', value: 'Chimoré' },
  { label: 'Chimpay', value: 'Chimpay' },
  { label: 'Chimur', value: 'Chimur' },
  { label: 'China', value: 'China' },
  { label: 'China', value: 'China' },
  { label: 'China', value: 'China' },
  { label: 'Chiná', value: 'Chiná' },
  { label: 'China Grove', value: 'China Grove' },
  { label: 'China Grove', value: 'China Grove' },
  { label: 'China Lake Acres', value: 'China Lake Acres' },
  { label: 'China Town', value: 'China Town' },
  { label: 'Chinacla', value: 'Chinacla' },
  { label: 'Chinácota', value: 'Chinácota' },
  { label: 'Chinameca', value: 'Chinameca' },
  { label: 'Chinameca', value: 'Chinameca' },
  { label: 'Chinameca', value: 'Chinameca' },
  { label: 'Chinampa De Gorostiza', value: 'Chinampa De Gorostiza' },
  { label: 'Chinampas', value: 'Chinampas' },
  { label: 'Chinandega', value: 'Chinandega' },
  { label: 'Chinantla', value: 'Chinantla' },
  { label: 'Chinar', value: 'Chinar' },
  { label: 'Chinari', value: 'Chinari' },
  { label: 'Chinatown', value: 'Chinatown' },
  { label: 'Chinautla', value: 'Chinautla' },
  { label: 'Chinautla', value: 'Chinautla' },
  { label: 'Chinavita', value: 'Chinavita' },
  { label: 'Chincha Alta', value: 'Chincha Alta' },
  { label: 'Chinchani', value: 'Chinchani' },
  { label: 'Chinchero', value: 'Chinchero' },
  { label: 'Chinchilla', value: 'Chinchilla' },
  { label: 'Chinchilla', value: 'Chinchilla' },
  { label: 'Chinchilla De Monte-Aragón', value: 'Chinchilla De Monte-Aragón' },
  { label: 'Chinchiná', value: 'Chinchiná' },
  { label: 'Chinchinim', value: 'Chinchinim' },
  { label: 'Chincholi', value: 'Chincholi' },
  { label: 'Chinchón', value: 'Chinchón' },
  { label: 'Chinchŏn', value: 'Chinchŏn' },
  { label: 'Chinchpokli', value: 'Chinchpokli' },
  { label: 'Chinciuș', value: 'Chinciuș' },
  { label: 'Chincoteague', value: 'Chincoteague' },
  { label: 'Chinda', value: 'Chinda' },
  { label: 'Chinde', value: 'Chinde' },
  { label: 'Chinderah', value: 'Chinderah' },
  { label: 'Chindini', value: 'Chindini' },
  { label: 'Chindrieux', value: 'Chindrieux' },
  { label: 'Chingirlau', value: 'Chingirlau' },
  { label: 'Chingleput', value: 'Chingleput' },
  { label: 'Chingola', value: 'Chingola' },
  { label: 'Chingola District', value: 'Chingola District' },
  { label: 'Chingueṭṭi', value: 'Chingueṭṭi' },
  { label: 'Chinhoyi', value: 'Chinhoyi' },
  { label: 'Chiniot', value: 'Chiniot' },
  { label: 'Chínipas', value: 'Chínipas' },
  { label: 'Chinique', value: 'Chinique' },
  { label: 'Chinju', value: 'Chinju' },
  { label: 'Chinle', value: 'Chinle' },
  { label: 'Chinley', value: 'Chinley' },
  { label: 'Chinna Salem', value: 'Chinna Salem' },
  { label: 'Chinnachowk', value: 'Chinnachowk' },
  { label: 'Chinnamanur', value: 'Chinnamanur' },
  { label: 'Chinnasekkadu', value: 'Chinnasekkadu' },
  { label: 'Chinnor', value: 'Chinnor' },
  { label: 'Chino', value: 'Chino' },
  { label: 'Chino', value: 'Chino' },
  { label: 'Chino', value: 'Chino' },
  { label: 'Chino Hills', value: 'Chino Hills' },
  { label: 'Chino Shi', value: 'Chino Shi' },
  { label: 'Chino Valley', value: 'Chino Valley' },
  { label: 'Chinobampo', value: 'Chinobampo' },
  { label: 'Chinon', value: 'Chinon' },
  { label: 'Chinook', value: 'Chinook' },
  { label: 'Chinoz', value: 'Chinoz' },
  { label: 'Chinsali', value: 'Chinsali' },
  { label: 'Chintamani', value: 'Chintamani' },
  { label: 'Chinteni', value: 'Chinteni' },
  { label: 'Chintinici', value: 'Chintinici' },
  { label: 'Chinú', value: 'Chinú' },
  { label: 'Chiny', value: 'Chiny' },
  { label: 'Chiochiş', value: 'Chiochiş' },
  { label: 'Chioggia', value: 'Chioggia' },
  { label: 'Chiojdeanca', value: 'Chiojdeanca' },
  { label: 'Chiojdeni', value: 'Chiojdeni' },
  { label: 'Chiojdu', value: 'Chiojdu' },
  { label: 'Chiomonte', value: 'Chiomonte' },
  { label: 'Chions', value: 'Chions' },
  { label: 'Chiopris', value: 'Chiopris' },
  { label: 'Chipahuatlán', value: 'Chipahuatlán' },
  { label: 'Chipaque', value: 'Chipaque' },
  { label: 'Chipata', value: 'Chipata' },
  { label: 'Chipatá', value: 'Chipatá' },
  { label: 'Chipilo De Francisco Javier Mina', value: 'Chipilo De Francisco Javier Mina' },
  { label: 'Chipindo', value: 'Chipindo' },
  { label: 'Chipinge', value: 'Chipinge' },
  { label: 'Chipinge District', value: 'Chipinge District' },
  { label: 'Chipinque De Arriba', value: 'Chipinque De Arriba' },
  { label: 'Chipiona', value: 'Chipiona' },
  { label: 'Chipispaya', value: 'Chipispaya' },
  { label: 'Chipley', value: 'Chipley' },
  { label: 'Chiplun', value: 'Chiplun' },
  { label: 'Chipoka', value: 'Chipoka' },
  { label: 'Chippendale', value: 'Chippendale' },
  { label: 'Chippenham', value: 'Chippenham' },
  { label: 'Chippewa County', value: 'Chippewa County' },
  { label: 'Chippewa County', value: 'Chippewa County' },
  { label: 'Chippewa County', value: 'Chippewa County' },
  { label: 'Chippewa Falls', value: 'Chippewa Falls' },
  { label: 'Chipping Campden', value: 'Chipping Campden' },
  { label: 'Chipping Norton', value: 'Chipping Norton' },
  { label: 'Chipping Norton', value: 'Chipping Norton' },
  { label: 'Chipping Ongar', value: 'Chipping Ongar' },
  { label: 'Chipping Sodbury', value: 'Chipping Sodbury' },
  { label: 'Chippis', value: 'Chippis' },
  { label: 'Chiprana', value: 'Chiprana' },
  { label: 'Chiprovtsi', value: 'Chiprovtsi' },
  { label: 'Chipurupalle', value: 'Chipurupalle' },
  { label: 'Chique/Blanchard', value: 'Chique/Blanchard' },
  { label: 'Chiquihuitillo', value: 'Chiquihuitillo' },
  { label: 'Chiquilá', value: 'Chiquilá' },
  { label: 'Chiquilistlán', value: 'Chiquilistlán' },
  { label: 'Chiquilistlán Jalisco', value: 'Chiquilistlán Jalisco' },
  { label: 'Chiquimitío', value: 'Chiquimitío' },
  { label: 'Chiquimula', value: 'Chiquimula' },
  { label: 'Chiquimulilla', value: 'Chiquimulilla' },
  { label: 'Chiquinival', value: 'Chiquinival' },
  { label: 'Chiquinquirá', value: 'Chiquinquirá' },
  { label: 'Chiquinshulum', value: 'Chiquinshulum' },
  { label: 'Chíquiza', value: 'Chíquiza' },
  { label: 'Chirac', value: 'Chirac' },
  { label: 'Chiradzulu', value: 'Chiradzulu' },
  { label: 'Chiradzulu District', value: 'Chiradzulu District' },
  { label: 'Chiraftei', value: 'Chiraftei' },
  { label: 'Chirala', value: 'Chirala' },
  { label: 'Chirang', value: 'Chirang' },
  { label: 'Chīras', value: 'Chīras' },
  { label: 'Chircești', value: 'Chircești' },
  { label: 'Chirchiq', value: 'Chirchiq' },
  { label: 'Chiredzi', value: 'Chiredzi' },
  { label: 'Chiredzi District', value: 'Chiredzi District' },
  { label: 'Chirens', value: 'Chirens' },
  { label: 'Chirgaon', value: 'Chirgaon' },
  { label: 'Chiria', value: 'Chiria' },
  { label: 'Chiriacu', value: 'Chiriacu' },
  { label: 'Chiriguaná', value: 'Chiriguaná' },
  { label: 'Chirilagua', value: 'Chirilagua' },
  { label: 'Chirileu', value: 'Chirileu' },
  { label: 'Chirimoya (Estación Chirimoya)', value: 'Chirimoya (Estación Chirimoya)' },
  { label: 'Chiriquí', value: 'Chiriquí' },
  { label: 'Chiriquí Grande', value: 'Chiriquí Grande' },
  { label: 'Chirivel', value: 'Chirivel' },
  { label: 'Chiri-Yurt', value: 'Chiri-Yurt' },
  { label: 'Chirk', value: 'Chirk' },
  { label: 'Chirkey', value: 'Chirkey' },
  { label: 'Chirnogeni', value: 'Chirnogeni' },
  { label: 'Chirnogi', value: 'Chirnogi' },
  { label: 'Chirnside', value: 'Chirnside' },
  { label: 'Chirnside Park', value: 'Chirnside Park' },
  { label: 'Chironkamba', value: 'Chironkamba' },
  { label: 'Chiroqchi', value: 'Chiroqchi' },
  { label: 'Chirpan', value: 'Chirpan' },
  { label: 'Chirpăr', value: 'Chirpăr' },
  { label: 'Chirundu', value: 'Chirundu' },
  { label: 'Chiry-Ourscamp', value: 'Chiry-Ourscamp' },
  { label: 'Chiryū', value: 'Chiryū' },
  { label: 'Chiryū-Shi', value: 'Chiryū-Shi' },
  { label: 'Chisago City', value: 'Chisago City' },
  { label: 'Chisago County', value: 'Chisago County' },
  { label: 'Chisălița', value: 'Chisălița' },
  { label: 'Chiscani', value: 'Chiscani' },
  { label: 'Chișcăreni', value: 'Chișcăreni' },
  { label: 'Chiscas', value: 'Chiscas' },
  { label: 'Chisec', value: 'Chisec' },
  { label: 'Chiseldon', value: 'Chiseldon' },
  { label: 'Chiselet', value: 'Chiselet' },
  { label: 'Chishmy', value: 'Chishmy' },
  { label: 'Chisholm', value: 'Chisholm' },
  { label: 'Chisholm', value: 'Chisholm' },
  { label: 'Chisholm', value: 'Chisholm' },
  { label: 'Chishtian', value: 'Chishtian' },
  { label: 'Chisinau', value: 'Chisinau' },
  { label: 'Chisindia', value: 'Chisindia' },
  { label: 'Chişineu-Criş', value: 'Chişineu-Criş' },
  { label: 'Chişlaz', value: 'Chişlaz' },
  { label: 'Chislehurst', value: 'Chislehurst' },
  { label: 'Chișoda', value: 'Chișoda' },
  { label: 'Chissamba', value: 'Chissamba' },
  { label: 'Chissay-En-Touraine', value: 'Chissay-En-Touraine' },
  { label: 'Chisten’Koye', value: 'Chisten’Koye' },
  { label: 'Chistogorskiy', value: 'Chistogorskiy' },
  { label: 'Chistopol’', value: 'Chistopol’' },
  { label: 'Chistopol’Skiy Rayon', value: 'Chistopol’Skiy Rayon' },
  { label: 'Chistopolye', value: 'Chistopolye' },
  { label: 'Chistoye', value: 'Chistoye' },
  { label: 'Chistyye Bory', value: 'Chistyye Bory' },
  { label: 'Chiswick', value: 'Chiswick' },
  { label: 'Chita', value: 'Chita' },
  { label: 'Chita', value: 'Chita' },
  { label: 'Chita', value: 'Chita' },
  { label: 'Chitab', value: 'Chitab' },
  { label: 'Chitagá', value: 'Chitagá' },
  { label: 'Chitapur', value: 'Chitapur' },
  { label: 'Chitaraque', value: 'Chitaraque' },
  { label: 'Chita-Shi', value: 'Chita-Shi' },
  { label: 'Chiţcani', value: 'Chiţcani' },
  { label: 'Chitejé De Garabato', value: 'Chitejé De Garabato' },
  { label: 'Chitenay', value: 'Chitenay' },
  { label: 'Chitignano', value: 'Chitignano' },
  { label: 'Chitila', value: 'Chitila' },
  { label: 'Chitinskiy Rayon', value: 'Chitinskiy Rayon' },
  { label: 'Chitipa', value: 'Chitipa' },
  { label: 'Chitipa District', value: 'Chitipa District' },
  { label: 'Chițoc', value: 'Chițoc' },
  { label: 'Chițorani', value: 'Chițorani' },
  { label: 'Chitose', value: 'Chitose' },
  { label: 'Chitose Shi', value: 'Chitose Shi' },
  { label: 'Chitradurga', value: 'Chitradurga' },
  { label: 'Chitrakoot', value: 'Chitrakoot' },
  { label: 'Chitrakoot Dham', value: 'Chitrakoot Dham' },
  { label: 'Chitral', value: 'Chitral' },
  { label: 'Chitré', value: 'Chitré' },
  { label: 'Chitrouni', value: 'Chitrouni' },
  { label: 'Chittagong', value: 'Chittagong' },
  { label: 'Chittaranjan', value: 'Chittaranjan' },
  { label: 'Chittarkonda', value: 'Chittarkonda' },
  { label: 'Chittaurgarh', value: 'Chittaurgarh' },
  { label: 'Chittaway Bay', value: 'Chittaway Bay' },
  { label: 'Chittenango', value: 'Chittenango' },
  { label: 'Chittenden', value: 'Chittenden' },
  { label: 'Chittenden County', value: 'Chittenden County' },
  { label: 'Chittering', value: 'Chittering' },
  { label: 'Chittoor', value: 'Chittoor' },
  { label: 'Chittur', value: 'Chittur' },
  { label: 'Chitu', value: 'Chitu' },
  { label: 'Chitungwiza', value: 'Chitungwiza' },
  { label: 'Chitwan', value: 'Chitwan' },
  { label: 'Chiuduno', value: 'Chiuduno' },
  { label: 'Chiugiana-La Commenda', value: 'Chiugiana-La Commenda' },
  { label: 'Chiuiești', value: 'Chiuiești' },
  { label: 'Chiungutwa', value: 'Chiungutwa' },
  { label: 'Chiuppano', value: 'Chiuppano' },
  { label: 'Chiure', value: 'Chiure' },
  { label: 'Chiuro', value: 'Chiuro' },
  { label: 'Chiusa', value: 'Chiusa' },
  { label: 'Chiusa Di Pesio', value: 'Chiusa Di Pesio' },
  { label: 'Chiusa Di San Michele', value: 'Chiusa Di San Michele' },
  { label: 'Chiusa Sclafani', value: 'Chiusa Sclafani' },
  { label: 'Chiusaforte', value: 'Chiusaforte' },
  { label: 'Chiusanico', value: 'Chiusanico' },
  { label: 'Chiusano Dasti', value: 'Chiusano Dasti' },
  { label: 'Chiusano Di San Domenico', value: 'Chiusano Di San Domenico' },
  { label: 'Chiusavecchia', value: 'Chiusavecchia' },
  { label: 'Chiusdino', value: 'Chiusdino' },
  { label: 'Chiusi', value: 'Chiusi' },
  { label: 'Chiusi Della Verna', value: 'Chiusi Della Verna' },
  { label: 'Chiusi Scalo', value: 'Chiusi Scalo' },
  { label: 'Chiuza', value: 'Chiuza' },
  { label: 'Chiuzbaia', value: 'Chiuzbaia' },
  { label: 'Chiva', value: 'Chiva' },
  { label: 'Chivacoa', value: 'Chivacoa' },
  { label: 'Chivana', value: 'Chivana' },
  { label: 'Chivasso', value: 'Chivasso' },
  { label: 'Chivatá', value: 'Chivatá' },
  { label: 'Chivay', value: 'Chivay' },
  { label: 'Chivhu', value: 'Chivhu' },
  { label: 'Chivi District', value: 'Chivi District' },
  { label: 'Chivolo', value: 'Chivolo' },
  { label: 'Chivor', value: 'Chivor' },
  { label: 'Chixi', value: 'Chixi' },
  { label: 'Chixtontic', value: 'Chixtontic' },
  { label: 'Chiyoda-Ku', value: 'Chiyoda-Ku' },
  { label: 'Chizhou', value: 'Chizhou' },
  { label: 'Chizhou Shi', value: 'Chizhou Shi' },
  { label: 'Chkalov', value: 'Chkalov' },
  { label: 'Chkalovo', value: 'Chkalovo' },
  { label: 'Chkalovsk', value: 'Chkalovsk' },
  { label: 'Chkalovskiy', value: 'Chkalovskiy' },
  { label: 'Chkalovskoye', value: 'Chkalovskoye' },
  { label: 'Chłapowo', value: 'Chłapowo' },
  { label: 'Chlebičov', value: 'Chlebičov' },
  { label: 'Chlef', value: 'Chlef' },
  { label: 'Chlewiska', value: 'Chlewiska' },
  { label: 'Chlmec', value: 'Chlmec' },
  { label: 'Chlói', value: 'Chlói' },
  { label: 'Chlórakas', value: 'Chlórakas' },
  { label: 'Chlum U Třeboně', value: 'Chlum U Třeboně' },
  { label: 'Chlumčany', value: 'Chlumčany' },
  { label: 'Chlumec', value: 'Chlumec' },
  { label: 'Chlumec Nad Cidlinou', value: 'Chlumec Nad Cidlinou' },
  { label: 'Chmielnik', value: 'Chmielnik' },
  { label: 'Chmielnik', value: 'Chmielnik' },
  { label: 'Chmielno', value: 'Chmielno' },
  { label: 'Chmielowice', value: 'Chmielowice' },
  { label: 'Chnare', value: 'Chnare' },
  { label: 'Cho Dok', value: 'Cho Dok' },
  { label: 'Choa Saidanshah', value: 'Choa Saidanshah' },
  { label: 'Choachí', value: 'Choachí' },
  { label: 'Chobham', value: 'Chobham' },
  { label: 'Chocamán', value: 'Chocamán' },
  { label: 'Choccolocco', value: 'Choccolocco' },
  { label: 'Choceń', value: 'Choceń' },
  { label: 'Choceň', value: 'Choceň' },
  { label: 'Chochkan', value: 'Chochkan' },
  { label: 'Chochola', value: 'Chochola' },
  { label: 'Chocianów', value: 'Chocianów' },
  { label: 'Chociwel', value: 'Chociwel' },
  { label: 'Chocontá', value: 'Chocontá' },
  { label: 'Chocope', value: 'Chocope' },
  { label: 'Chocques', value: 'Chocques' },
  { label: 'Choctaw', value: 'Choctaw' },
  { label: 'Choctaw County', value: 'Choctaw County' },
  { label: 'Choctaw County', value: 'Choctaw County' },
  { label: 'Choctaw County', value: 'Choctaw County' },
  { label: 'Choctaw Lake', value: 'Choctaw Lake' },
  { label: 'Chocz', value: 'Chocz' },
  { label: 'Choczewo', value: 'Choczewo' },
  { label: 'Chocznia', value: 'Chocznia' },
  { label: 'Chodavaram', value: 'Chodavaram' },
  { label: 'Chodecz', value: 'Chodecz' },
  { label: 'Chodel', value: 'Chodel' },
  { label: 'Chodes', value: 'Chodes' },
  { label: 'Chodos/Xodos', value: 'Chodos/Xodos' },
  { label: 'Chodov', value: 'Chodov' },
  { label: 'Chodová Planá', value: 'Chodová Planá' },
  { label: 'Chodów', value: 'Chodów' },
  { label: 'Chodzież', value: 'Chodzież' },
  { label: 'Choele Choel', value: 'Choele Choel' },
  { label: 'Chōfu', value: 'Chōfu' },
  { label: 'Chōfu-Shi', value: 'Chōfu-Shi' },
  { label: 'Choghadak', value: 'Choghadak' },
  { label: 'Choghamish', value: 'Choghamish' },
  { label: 'Choibalsan', value: 'Choibalsan' },
  { label: 'Choiseul', value: 'Choiseul' },
  { label: 'Choisey', value: 'Choisey' },
  { label: 'Choisy', value: 'Choisy' },
  { label: 'Choisy-Au-Bac', value: 'Choisy-Au-Bac' },
  { label: 'Choisy-En-Brie', value: 'Choisy-En-Brie' },
  { label: 'Choisy-Le-Roi', value: 'Choisy-Le-Roi' },
  { label: 'Choix', value: 'Choix' },
  { label: 'Chojna', value: 'Chojna' },
  { label: 'Chojnice', value: 'Chojnice' },
  { label: 'Chojnów', value: 'Chojnów' },
  { label: 'Chok Chai', value: 'Chok Chai' },
  { label: 'Chokocho', value: 'Chokocho' },
  { label: 'Chokurdakh', value: 'Chokurdakh' },
  { label: 'Chokwé', value: 'Chokwé' },
  { label: 'Cholapuram', value: 'Cholapuram' },
  { label: 'Cholargós', value: 'Cholargós' },
  { label: 'Cholchol', value: 'Cholchol' },
  { label: 'Cholet', value: 'Cholet' },
  { label: 'Choliche', value: 'Choliche' },
  { label: 'Choloma', value: 'Choloma' },
  { label: 'Cholpon-Ata', value: 'Cholpon-Ata' },
  { label: 'Cholsey', value: 'Cholsey' },
  { label: 'Cholul', value: 'Cholul' },
  { label: 'Cholul Cantón', value: 'Cholul Cantón' },
  { label: 'Cholula', value: 'Cholula' },
  { label: 'Choluteca', value: 'Choluteca' },
  { label: 'Chom Bueng', value: 'Chom Bueng' },
  { label: 'Chom Thong', value: 'Chom Thong' },
  { label: 'Chom Thong', value: 'Chom Thong' },
  { label: 'Choma', value: 'Choma' },
  { label: 'Chome', value: 'Chome' },
  { label: 'Chomérac', value: 'Chomérac' },
  { label: 'Chomutov', value: 'Chomutov' },
  { label: 'Chon Buri', value: 'Chon Buri' },
  { label: 'Chon Daen', value: 'Chon Daen' },
  { label: 'Chonchi', value: 'Chonchi' },
  { label: 'Chone', value: 'Chone' },
  { label: 'Chonegal', value: 'Chonegal' },
  { label: 'Chong-Alay District', value: 'Chong-Alay District' },
  { label: 'Chongjin', value: 'Chongjin' },
  { label: 'Chŏngju', value: 'Chŏngju' },
  { label: 'Chŏngju-Gun', value: 'Chŏngju-Gun' },
  { label: 'Chonglong', value: 'Chonglong' },
  { label: 'Chongoroi', value: 'Chongoroi' },
  { label: 'Chongoyape', value: 'Chongoyape' },
  { label: 'Chongqing', value: 'Chongqing' },
  { label: 'Chongru', value: 'Chongru' },
  { label: 'Chongshan', value: 'Chongshan' },
  { label: 'Chongwe', value: 'Chongwe' },
  { label: 'Chongzuo Shi', value: 'Chongzuo Shi' },
  { label: 'Chontalcoatlán', value: 'Chontalcoatlán' },
  { label: 'Chontalpa', value: 'Chontalpa' },
  { label: 'Chontaul', value: 'Chontaul' },
  { label: 'Chontla', value: 'Chontla' },
  { label: 'Choobar', value: 'Choobar' },
  { label: 'Chop', value: 'Chop' },
  { label: 'Chopan', value: 'Chopan' },
  { label: 'Chopda', value: 'Chopda' },
  { label: 'Chopinzinho', value: 'Chopinzinho' },
  { label: 'Chopovychi', value: 'Chopovychi' },
  { label: 'Chopovychi', value: 'Chopovychi' },
  { label: 'Choppin Ridge/Sarot', value: 'Choppin Ridge/Sarot' },
  { label: 'Choppington', value: 'Choppington' },
  { label: 'Chopwell', value: 'Chopwell' },
  { label: 'Choqa Sarahi', value: 'Choqa Sarahi' },
  { label: 'Chóra', value: 'Chóra' },
  { label: 'Chóra Sfakíon', value: 'Chóra Sfakíon' },
  { label: 'Choram', value: 'Choram' },
  { label: 'Chorbane', value: 'Chorbane' },
  { label: 'Chorfa', value: 'Chorfa' },
  { label: 'Chorges', value: 'Chorges' },
  { label: 'Chorhat', value: 'Chorhat' },
  { label: 'Chorin', value: 'Chorin' },
  { label: 'Choristí', value: 'Choristí' },
  { label: 'Chorkówka', value: 'Chorkówka' },
  { label: 'Chorley', value: 'Chorley' },
  { label: 'Chorleywood', value: 'Chorleywood' },
  { label: 'Chorlton', value: 'Chorlton' },
  { label: 'Chorlton Cum Hardy', value: 'Chorlton Cum Hardy' },
  { label: 'Chornobay', value: 'Chornobay' },
  { label: 'Chornobayivka', value: 'Chornobayivka' },
  { label: 'Chornoliztsi', value: 'Chornoliztsi' },
  { label: 'Chornomors’K', value: 'Chornomors’K' },
  { label: 'Chornorudka', value: 'Chornorudka' },
  { label: 'Chornoye', value: 'Chornoye' },
  { label: 'Chornukhy', value: 'Chornukhy' },
  { label: 'Chornukhyne', value: 'Chornukhyne' },
  { label: 'Chornyanka', value: 'Chornyanka' },
  { label: 'Choró', value: 'Choró' },
  { label: 'Choroń', value: 'Choroń' },
  { label: 'Choroszcz', value: 'Choroszcz' },
  { label: 'Chorotis', value: 'Chorotis' },
  { label: 'Chorozinho', value: 'Chorozinho' },
  { label: 'Chorrochó', value: 'Chorrochó' },
  { label: 'Chortiátis', value: 'Chortiátis' },
  { label: 'Chortkiv', value: 'Chortkiv' },
  { label: 'Chortomlyk', value: 'Chortomlyk' },
  { label: 'Chortoq', value: 'Chortoq' },
  { label: 'Chorzele', value: 'Chorzele' },
  { label: 'Chorzów', value: 'Chorzów' },
  { label: 'Chos Malal', value: 'Chos Malal' },
  { label: 'Chōshi-Shi', value: 'Chōshi-Shi' },
  { label: 'Chosica', value: 'Chosica' },
  { label: 'Chostlarn', value: 'Chostlarn' },
  { label: 'Chosto De Los Jarros', value: 'Chosto De Los Jarros' },
  { label: 'Choszczno', value: 'Choszczno' },
  { label: 'Chota', value: 'Chota' },
  { label: 'Chotcza', value: 'Chotcza' },
  { label: 'Choteau', value: 'Choteau' },
  { label: 'Chotěboř', value: 'Chotěboř' },
  { label: 'Chotěbuz', value: 'Chotěbuz' },
  { label: 'Chotepe', value: 'Chotepe' },
  { label: 'Chotěšov', value: 'Chotěšov' },
  { label: 'Chotila', value: 'Chotila' },
  { label: 'Chotoviny', value: 'Chotoviny' },
  { label: 'Chouani', value: 'Chouani' },
  { label: 'Chouteau', value: 'Chouteau' },
  { label: 'Chouteau County', value: 'Chouteau County' },
  { label: 'Chouzé-Sur-Loire', value: 'Chouzé-Sur-Loire' },
  { label: 'Chouzy-Sur-Cisse', value: 'Chouzy-Sur-Cisse' },
  { label: 'Chovar', value: 'Chovar' },
  { label: 'Chóvar', value: 'Chóvar' },
  { label: 'Chowan County', value: 'Chowan County' },
  { label: 'Chowari', value: 'Chowari' },
  { label: 'Chowchilla', value: 'Chowchilla' },
  { label: 'Chowki Jamali', value: 'Chowki Jamali' },
  { label: 'Chowtash', value: 'Chowtash' },
  { label: 'Choya', value: 'Choya' },
  { label: 'Choyr', value: 'Choyr' },
  { label: 'Chozas De Abajo', value: 'Chozas De Abajo' },
  { label: 'Chozas De Canales', value: 'Chozas De Canales' },
  { label: 'Chrast', value: 'Chrast' },
  { label: 'Chrást', value: 'Chrást' },
  { label: 'Chrastava', value: 'Chrastava' },
  { label: 'Chřibská', value: 'Chřibská' },
  { label: 'Chris Hani District Municipality', value: 'Chris Hani District Municipality' },
  { label: 'Chrisman', value: 'Chrisman' },
  { label: 'Christchurch', value: 'Christchurch' },
  { label: 'Christchurch', value: 'Christchurch' },
  { label: 'Christchurch City', value: 'Christchurch City' },
  { label: 'Christian County', value: 'Christian County' },
  { label: 'Christian County', value: 'Christian County' },
  { label: 'Christian County', value: 'Christian County' },
  { label: 'Christian Hill', value: 'Christian Hill' },
  { label: 'Christiana', value: 'Christiana' },
  { label: 'Christiana', value: 'Christiana' },
  { label: 'Christiana', value: 'Christiana' },
  { label: 'Christiana', value: 'Christiana' },
  { label: 'Christiansburg', value: 'Christiansburg' },
  { label: 'Christiansfeld', value: 'Christiansfeld' },
  { label: 'Christianshavn', value: 'Christianshavn' },
  { label: 'Christiansø', value: 'Christiansø' },
  { label: 'Christiansted', value: 'Christiansted' },
  { label: 'Christie Downs', value: 'Christie Downs' },
  { label: 'Christies Beach', value: 'Christies Beach' },
  { label: 'Christmas', value: 'Christmas' },
  { label: 'Christopher', value: 'Christopher' },
  { label: 'Chropyně', value: 'Chropyně' },
  { label: 'Chróścice', value: 'Chróścice' },
  { label: 'Chrościna', value: 'Chrościna' },
  { label: 'Chrostkowo', value: 'Chrostkowo' },
  { label: 'Chroustovice', value: 'Chroustovice' },
  { label: 'Chrudim', value: 'Chrudim' },
  { label: 'Chruszczobród', value: 'Chruszczobród' },
  { label: 'Chrysó', value: 'Chrysó' },
  { label: 'Chrysochórafa', value: 'Chrysochórafa' },
  { label: 'Chrysochóri', value: 'Chrysochóri' },
  { label: 'Chrysoúpolis', value: 'Chrysoúpolis' },
  { label: 'Chryston', value: 'Chryston' },
  { label: 'Chrzanów', value: 'Chrzanów' },
  { label: 'Chrzanów', value: 'Chrzanów' },
  { label: 'Chrząstowice', value: 'Chrząstowice' },
  { label: 'Chrzypsko Wielkie', value: 'Chrzypsko Wielkie' },
  { label: 'Chtouka-Ait-Baha', value: 'Chtouka-Ait-Baha' },
  { label: 'Chu', value: 'Chu' },
  { label: 'Chuadanga ', value: 'Chuadanga ' },
  { label: 'Chualar', value: 'Chualar' },
  { label: 'Chuari Khas', value: 'Chuari Khas' },
  { label: 'Chuarrancho', value: 'Chuarrancho' },
  { label: 'Chuave', value: 'Chuave' },
  { label: 'Chubbuck', value: 'Chubbuck' },
  { label: 'Chubek', value: 'Chubek' },
  { label: 'Chuburná', value: 'Chuburná' },
  { label: 'Chucándiro', value: 'Chucándiro' },
  { label: 'Chucarit', value: 'Chucarit' },
  { label: 'Chucena', value: 'Chucena' },
  { label: 'Chuchelná', value: 'Chuchelná' },
  { label: 'Chuchkovo', value: 'Chuchkovo' },
  { label: 'Chuda', value: 'Chuda' },
  { label: 'Chudey', value: 'Chudey' },
  { label: 'Chudleigh', value: 'Chudleigh' },
  { label: 'Chudleigh', value: 'Chudleigh' },
  { label: 'Chudleigh Knighton', value: 'Chudleigh Knighton' },
  { label: 'Chudniv', value: 'Chudniv' },
  { label: 'Chudniv', value: 'Chudniv' },
  { label: 'Chudniv Raion', value: 'Chudniv Raion' },
  { label: 'Chudovo', value: 'Chudovo' },
  { label: 'Chudovskiy Rayon', value: 'Chudovskiy Rayon' },
  { label: 'Chueca', value: 'Chueca' },
  { label: 'Chufarovo', value: 'Chufarovo' },
  { label: 'Chuguyevka', value: 'Chuguyevka' },
  { label: 'Chuhar Jamali', value: 'Chuhar Jamali' },
  { label: 'Chuhar Kana', value: 'Chuhar Kana' },
  { label: 'Chuhuyiv', value: 'Chuhuyiv' },
  { label: 'Chui', value: 'Chui' },
  { label: 'Chuí', value: 'Chuí' },
  { label: 'Chuka', value: 'Chuka' },
  { label: 'Chukhloma', value: 'Chukhloma' },
  { label: 'Chul’Man', value: 'Chul’Man' },
  { label: 'Chula Vista', value: 'Chula Vista' },
  { label: 'Chula Vista', value: 'Chula Vista' },
  { label: 'Chulak Qapanuri', value: 'Chulak Qapanuri' },
  { label: 'Chulakivka', value: 'Chulakivka' },
  { label: 'Chulakkurgan', value: 'Chulakkurgan' },
  { label: 'Chulilla', value: 'Chulilla' },
  { label: 'Chulmleigh', value: 'Chulmleigh' },
  { label: 'Chulucanas', value: 'Chulucanas' },
  { label: 'Chulum Cárdenas', value: 'Chulum Cárdenas' },
  { label: 'Chulum Juárez', value: 'Chulum Juárez' },
  { label: 'Chulumani', value: 'Chulumani' },
  { label: 'Chuluota', value: 'Chuluota' },
  { label: 'Chulym', value: 'Chulym' },
  { label: 'Chum Phae', value: 'Chum Phae' },
  { label: 'Chumatlán', value: 'Chumatlán' },
  { label: 'Chumayel', value: 'Chumayel' },
  { label: 'Chumi', value: 'Chumi' },
  { label: 'Chumical', value: 'Chumical' },
  { label: 'Chumikan', value: 'Chumikan' },
  { label: 'Chumillas', value: 'Chumillas' },
  { label: 'Chumphon', value: 'Chumphon' },
  { label: 'Chunar', value: 'Chunar' },
  { label: 'Chuncheon', value: 'Chuncheon' },
  { label: 'Chuncheon-Si', value: 'Chuncheon-Si' },
  { label: 'Chunchintok', value: 'Chunchintok' },
  { label: 'Chunchucmil', value: 'Chunchucmil' },
  { label: 'Chunghwa', value: 'Chunghwa' },
  { label: 'Chungju-Si', value: 'Chungju-Si' },
  { label: 'Chunhuhub', value: 'Chunhuhub' },
  { label: 'Chunian', value: 'Chunian' },
  { label: 'Chuniapan De Arriba', value: 'Chuniapan De Arriba' },
  { label: 'Chunoyar', value: 'Chunoyar' },
  { label: 'Chunskiy', value: 'Chunskiy' },
  { label: 'Chūō Ku', value: 'Chūō Ku' },
  { label: 'Chūō-Shi', value: 'Chūō-Shi' },
  { label: 'Chupa', value: 'Chupa' },
  { label: 'Chupaca', value: 'Chupaca' },
  { label: 'Chupaderos', value: 'Chupaderos' },
  { label: 'Chupampa', value: 'Chupampa' },
  { label: 'Chupinguaia', value: 'Chupinguaia' },
  { label: 'Chupio', value: 'Chupio' },
  { label: 'Chuprene', value: 'Chuprene' },
  { label: 'Chupryakovo', value: 'Chupryakovo' },
  { label: 'Chuquitira', value: 'Chuquitira' },
  { label: 'Chur', value: 'Chur' },
  { label: 'Churachandpur', value: 'Churachandpur' },
  { label: 'Churapcha', value: 'Churapcha' },
  { label: 'Church', value: 'Church' },
  { label: 'Church Corner', value: 'Church Corner' },
  { label: 'Church Fenton', value: 'Church Fenton' },
  { label: 'Church Hill', value: 'Church Hill' },
  { label: 'Church Hill', value: 'Church Hill' },
  { label: 'Church Point', value: 'Church Point' },
  { label: 'Church Rock', value: 'Church Rock' },
  { label: 'Church Stretton', value: 'Church Stretton' },
  { label: 'Churchdown', value: 'Churchdown' },
  { label: 'Churchill', value: 'Churchill' },
  { label: 'Churchill', value: 'Churchill' },
  { label: 'Churchill', value: 'Churchill' },
  { label: 'Churchill', value: 'Churchill' },
  { label: 'Churchill', value: 'Churchill' },
  { label: 'Churchill County', value: 'Churchill County' },
  { label: 'Churchlands', value: 'Churchlands' },
  { label: 'Churchville', value: 'Churchville' },
  { label: 'Churchville', value: 'Churchville' },
  { label: 'Churintzio', value: 'Churintzio' },
  { label: 'Churintzio', value: 'Churintzio' },
  { label: 'Churipitzeo', value: 'Churipitzeo' },
  { label: 'Churk', value: 'Churk' },
  { label: 'Churovichi', value: 'Churovichi' },
  { label: 'Churriana De La Vega', value: 'Churriana De La Vega' },
  { label: 'Churt', value: 'Churt' },
  { label: 'Churu', value: 'Churu' },
  { label: 'Churubusco', value: 'Churubusco' },
  { label: 'Churulia', value: 'Churulia' },
  { label: 'Churumuco De Morelos', value: 'Churumuco De Morelos' },
  { label: 'Churuquita Chiquita', value: 'Churuquita Chiquita' },
  { label: 'Churuquita Grande', value: 'Churuquita Grande' },
  { label: 'Churwalden', value: 'Churwalden' },
  { label: 'Chusovoy', value: 'Chusovoy' },
  { label: 'Chust', value: 'Chust' },
  { label: 'Chute-Aux-Outardes', value: 'Chute-Aux-Outardes' },
  { label: 'Chutes-Lavie', value: 'Chutes-Lavie' },
  { label: 'Chutove', value: 'Chutove' },
  { label: 'Chuvisca', value: 'Chuvisca' },
  { label: 'Chuwar', value: 'Chuwar' },
  { label: 'Chuxiong Yizu Zizhizhou', value: 'Chuxiong Yizu Zizhizhou' },
  { label: 'Chuyskiy Rayon', value: 'Chuyskiy Rayon' },
  { label: 'Chuzelles', value: 'Chuzelles' },
  { label: 'Chuzhou', value: 'Chuzhou' },
  { label: 'Chuzhou Shi', value: 'Chuzhou Shi' },
  { label: 'Chvalčov', value: 'Chvalčov' },
  { label: 'Chvaletice', value: 'Chvaletice' },
  { label: 'Chvalšiny', value: 'Chvalšiny' },
  { label: 'Chwałowice', value: 'Chwałowice' },
  { label: 'Chwaszczyno', value: 'Chwaszczyno' },
  { label: 'Chybie', value: 'Chybie' },
  { label: 'Chyhyryn', value: 'Chyhyryn' },
  { label: 'Chynadiyovo', value: 'Chynadiyovo' },
  { label: 'Chyňava', value: 'Chyňava' },
  { label: 'Chýnov', value: 'Chýnov' },
  { label: 'Chynów', value: 'Chynów' },
  { label: 'Chyorny Yar', value: 'Chyorny Yar' },
  { label: 'Chyrvonaya Slabada', value: 'Chyrvonaya Slabada' },
  { label: 'Chyšky', value: 'Chyšky' },
  { label: 'Chyst’', value: 'Chyst’' },
  { label: 'Chystyakove', value: 'Chystyakove' },
  { label: 'Chyżne', value: 'Chyżne' },
  { label: 'Ciacova', value: 'Ciacova' },
  { label: 'Ciaculli', value: 'Ciaculli' },
  { label: 'Ciadoncha', value: 'Ciadoncha' },
  { label: 'Ciales', value: 'Ciales' },
  { label: 'Ciales', value: 'Ciales' },
  { label: 'Ciales Municipio', value: 'Ciales Municipio' },
  { label: 'Ciamis', value: 'Ciamis' },
  { label: 'Ciampea', value: 'Ciampea' },
  { label: 'Ciampino', value: 'Ciampino' },
  { label: 'Cianciana', value: 'Cianciana' },
  { label: 'Ciano', value: 'Ciano' },
  { label: 'Ciano Denza', value: 'Ciano Denza' },
  { label: 'Cianorte', value: 'Cianorte' },
  { label: 'Ciarraí', value: 'Ciarraí' },
  { label: 'Ciasna', value: 'Ciasna' },
  { label: 'Ciavolo', value: 'Ciavolo' },
  { label: 'Ciba', value: 'Ciba' },
  { label: 'Čibača', value: 'Čibača' },
  { label: 'Cibakháza', value: 'Cibakháza' },
  { label: 'Cibecue', value: 'Cibecue' },
  { label: 'Cibiana', value: 'Cibiana' },
  { label: 'Cibinong', value: 'Cibinong' },
  { label: 'Cibitoke', value: 'Cibitoke' },
  { label: 'Cibla', value: 'Cibla' },
  { label: 'Cibola County', value: 'Cibola County' },
  { label: 'Cibolo', value: 'Cibolo' },
  { label: 'Ciboure', value: 'Ciboure' },
  { label: 'Cibu', value: 'Cibu' },
  { label: 'Cicacalco', value: 'Cicacalco' },
  { label: 'Cicagna', value: 'Cicagna' },
  { label: 'Cicala', value: 'Cicala' },
  { label: 'Cicănești', value: 'Cicănești' },
  { label: 'Cicârlău', value: 'Cicârlău' },
  { label: 'Cicciano', value: 'Cicciano' },
  { label: 'Çiçekdağı', value: 'Çiçekdağı' },
  { label: 'Cicerale', value: 'Cicerale' },
  { label: 'Cicero', value: 'Cicero' },
  { label: 'Cicero', value: 'Cicero' },
  { label: 'Cícero Dantas', value: 'Cícero Dantas' },
  { label: 'Ciceron', value: 'Ciceron' },
  { label: 'Ciceu', value: 'Ciceu' },
  { label: 'Ciceu-Giurgeşti', value: 'Ciceu-Giurgeşti' },
  { label: 'Ciceu-Mihăiești', value: 'Ciceu-Mihăiești' },
  { label: 'Ćićevac', value: 'Ćićevac' },
  { label: 'Ciciliano', value: 'Ciciliano' },
  { label: 'Ciclova-Română', value: 'Ciclova-Română' },
  { label: 'Cicognolo', value: 'Cicognolo' },
  { label: 'Ciconia', value: 'Ciconia' },
  { label: 'Ciconio', value: 'Ciconio' },
  { label: 'Cicuco', value: 'Cicuco' },
  { label: 'Cicurug', value: 'Cicurug' },
  { label: 'Cidade Gaúcha', value: 'Cidade Gaúcha' },
  { label: 'Cidade Ocidental', value: 'Cidade Ocidental' },
  { label: 'Cidade Velha', value: 'Cidade Velha' },
  { label: 'Cidamón', value: 'Cidamón' },
  { label: 'Cide', value: 'Cide' },
  { label: 'Cidelândia', value: 'Cidelândia' },
  { label: 'Cidones', value: 'Cidones' },
  { label: 'Cidra', value: 'Cidra' },
  { label: 'Cidra', value: 'Cidra' },
  { label: 'Cidreag', value: 'Cidreag' },
  { label: 'Cidreira', value: 'Cidreira' },
  { label: 'Ciechanów', value: 'Ciechanów' },
  { label: 'Ciechanowiec', value: 'Ciechanowiec' },
  { label: 'Ciechocin', value: 'Ciechocin' },
  { label: 'Ciechocinek', value: 'Ciechocinek' },
  { label: 'Ciechów', value: 'Ciechów' },
  { label: 'Cięcina', value: 'Cięcina' },
  { label: 'Ciego De Ávila', value: 'Ciego De Ávila' },
  { label: 'Cielądz', value: 'Cielądz' },
  { label: 'Cielmice', value: 'Cielmice' },
  { label: 'Ciempozuelos', value: 'Ciempozuelos' },
  { label: 'Ciénaga', value: 'Ciénaga' },
  { label: 'Ciénaga De Oro', value: 'Ciénaga De Oro' },
  { label: 'Ciénega', value: 'Ciénega' },
  { label: 'Ciénega De Flores', value: 'Ciénega De Flores' },
  { label: 'Ciénega De Galvanes', value: 'Ciénega De Galvanes' },
  { label: 'Ciénega De Nuestra Señora De Guadalupe', value: 'Ciénega De Nuestra Señora De Guadalupe' },
  { label: 'Ciénega De Zimatlán', value: 'Ciénega De Zimatlán' },
  { label: 'Ciénega Del Pastor', value: 'Ciénega Del Pastor' },
  { label: 'Ciénega Grande', value: 'Ciénega Grande' },
  { label: 'Cienega Springs', value: 'Cienega Springs' },
  { label: 'Cienegas Terrace', value: 'Cienegas Terrace' },
  { label: 'Cieneguilla', value: 'Cieneguilla' },
  { label: 'Cieneguilla', value: 'Cieneguilla' },
  { label: 'Cieneguillas', value: 'Cieneguillas' },
  { label: 'Cieneguillas', value: 'Cieneguillas' },
  { label: 'Cieneguitas', value: 'Cieneguitas' },
  { label: 'Cienfuegos', value: 'Cienfuegos' },
  { label: 'Ciepielów', value: 'Ciepielów' },
  { label: 'Cieplice Śląskie Zdrój', value: 'Cieplice Śląskie Zdrój' },
  { label: 'Ciepłowody', value: 'Ciepłowody' },
  { label: 'Čierna Nad Tisou', value: 'Čierna Nad Tisou' },
  { label: 'Čierny Balog', value: 'Čierny Balog' },
  { label: 'Cierva La', value: 'Cierva La' },
  { label: 'Ciervo Petiso', value: 'Ciervo Petiso' },
  { label: 'Cieszanów', value: 'Cieszanów' },
  { label: 'Cieszków', value: 'Cieszków' },
  { label: 'Cieszyn', value: 'Cieszyn' },
  { label: 'Cieza', value: 'Cieza' },
  { label: 'Ciężkowice', value: 'Ciężkowice' },
  { label: 'Çifteler', value: 'Çifteler' },
  { label: 'Çiftlik', value: 'Çiftlik' },
  { label: 'Çiftlikköy', value: 'Çiftlikköy' },
  { label: 'Cifuentes', value: 'Cifuentes' },
  { label: 'Cifuentes', value: 'Cifuentes' },
  { label: 'Cigales', value: 'Cigales' },
  { label: 'Cigánd', value: 'Cigánd' },
  { label: 'Cigándi Járás', value: 'Cigándi Járás' },
  { label: 'Cigaras', value: 'Cigaras' },
  { label: 'Çiğli', value: 'Çiğli' },
  { label: 'Cigliano', value: 'Cigliano' },
  { label: 'Cigliè', value: 'Cigliè' },
  { label: 'Cigognola', value: 'Cigognola' },
  { label: 'Cigole', value: 'Cigole' },
  { label: 'Cigudosa', value: 'Cigudosa' },
  { label: 'Ciguñuela', value: 'Ciguñuela' },
  { label: 'Cihanbeyli', value: 'Cihanbeyli' },
  { label: 'Cihuatlán Jalisco', value: 'Cihuatlán Jalisco' },
  { label: 'Cihuela', value: 'Cihuela' },
  { label: 'Cihuri', value: 'Cihuri' },
  { label: 'Cijuela', value: 'Cijuela' },
  { label: 'Cikampek', value: 'Cikampek' },
  { label: 'Cikarang', value: 'Cikarang' },
  { label: 'Cikupa', value: 'Cikupa' },
  { label: 'Cilavegna', value: 'Cilavegna' },
  { label: 'Çıldır', value: 'Çıldır' },
  { label: 'Cileungsir', value: 'Cileungsir' },
  { label: 'Cileunyi', value: 'Cileunyi' },
  { label: 'Cilibia', value: 'Cilibia' },
  { label: 'Cilieni', value: 'Cilieni' },
  { label: 'Çilimli', value: 'Çilimli' },
  { label: 'Cill Airne', value: 'Cill Airne' },
  { label: 'Cillán', value: 'Cillán' },
  { label: 'Cillaperlata', value: 'Cillaperlata' },
  { label: 'Cilleros', value: 'Cilleros' },
  { label: 'Cilleros De La Bastida', value: 'Cilleros De La Bastida' },
  { label: 'Cilleruelo De Abajo', value: 'Cilleruelo De Abajo' },
  { label: 'Cilleruelo De Arriba', value: 'Cilleruelo De Arriba' },
  { label: 'Cilleruelo De San Mamés', value: 'Cilleruelo De San Mamés' },
  { label: 'Cillorigo De Liébana', value: 'Cillorigo De Liébana' },
  { label: 'Cim', value: 'Cim' },
  { label: 'Cima Del Sol', value: 'Cima Del Sol' },
  { label: 'Cimadolmo', value: 'Cimadolmo' },
  { label: 'Cimahi', value: 'Cimahi' },
  { label: 'Cimanes De La Vega', value: 'Cimanes De La Vega' },
  { label: 'Cimanes Del Tejar', value: 'Cimanes Del Tejar' },
  { label: 'Cimarron', value: 'Cimarron' },
  { label: 'Cimarron County', value: 'Cimarron County' },
  { label: 'Cimarron Hills', value: 'Cimarron Hills' },
  { label: 'Cimballa', value: 'Cimballa' },
  { label: 'Cimbergo', value: 'Cimbergo' },
  { label: 'Cimbro', value: 'Cimbro' },
  { label: 'Cimego', value: 'Cimego' },
  { label: 'Čimelice', value: 'Čimelice' },
  { label: 'Ciminà', value: 'Ciminà' },
  { label: 'Ciminna', value: 'Ciminna' },
  { label: 'Cimişlia', value: 'Cimişlia' },
  { label: 'Cimitarra', value: 'Cimitarra' },
  { label: 'Cimitile', value: 'Cimitile' },
  { label: 'Cimolais', value: 'Cimolais' },
  { label: 'Cimoneri', value: 'Cimoneri' },
  { label: 'Cinaglio', value: 'Cinaglio' },
  { label: 'Çınar', value: 'Çınar' },
  { label: 'Çınarcık', value: 'Çınarcık' },
  { label: 'Çinarlı', value: 'Çinarlı' },
  { label: 'Cincinnati', value: 'Cincinnati' },
  { label: 'Cinco De Febrero', value: 'Cinco De Febrero' },
  { label: 'Cinco De Mayo', value: 'Cinco De Mayo' },
  { label: 'Cinco De Mayo', value: 'Cinco De Mayo' },
  { label: 'Cinco Olivas', value: 'Cinco Olivas' },
  { label: 'Cinco Pinos', value: 'Cinco Pinos' },
  { label: 'Cinco Ranch', value: 'Cinco Ranch' },
  { label: 'Cinco Saltos', value: 'Cinco Saltos' },
  { label: 'Cinco Señores', value: 'Cinco Señores' },
  { label: 'Cincovillas', value: 'Cincovillas' },
  { label: 'Cinctorres', value: 'Cinctorres' },
  { label: 'Cincu', value: 'Cincu' },
  { label: 'Cinderford', value: 'Cinderford' },
  { label: 'Çine', value: 'Çine' },
  { label: 'Cineto Romano', value: 'Cineto Romano' },
  { label: 'Ciney', value: 'Ciney' },
  { label: 'Cinfães', value: 'Cinfães' },
  { label: 'Cingia De Botti', value: 'Cingia De Botti' },
  { label: 'Cingoli', value: 'Cingoli' },
  { label: 'Cinigiano', value: 'Cinigiano' },
  { label: 'Cinisello Balsamo', value: 'Cinisello Balsamo' },
  { label: 'Cinisi', value: 'Cinisi' },
  { label: 'Cinnaminson', value: 'Cinnaminson' },
  { label: 'Cino', value: 'Cino' },
  { label: 'Cinq Avenues', value: 'Cinq Avenues' },
  { label: 'Cinq-Mars-La-Pile', value: 'Cinq-Mars-La-Pile' },
  { label: 'Cinque Frondi', value: 'Cinque Frondi' },
  { label: 'Cinqueux', value: 'Cinqueux' },
  { label: 'Cinta', value: 'Cinta' },
  { label: 'Cintalapa', value: 'Cintalapa' },
  { label: 'Cintalapa De Figueroa', value: 'Cintalapa De Figueroa' },
  { label: 'Cintano', value: 'Cintano' },
  { label: 'Cinte Tesino', value: 'Cinte Tesino' },
  { label: 'Cintegabelle', value: 'Cintegabelle' },
  { label: 'Cintei', value: 'Cintei' },
  { label: 'Cinto Caomaggiore', value: 'Cinto Caomaggiore' },
  { label: 'Cintolese', value: 'Cintolese' },
  { label: 'Cintra', value: 'Cintra' },
  { label: 'Cintré', value: 'Cintré' },
  { label: 'Cintruénigo', value: 'Cintruénigo' },
  { label: 'Cinzano', value: 'Cinzano' },
  { label: 'Cioarga', value: 'Cioarga' },
  { label: 'Ciobanu', value: 'Ciobanu' },
  { label: 'Ciobotani', value: 'Ciobotani' },
  { label: 'Ciocănari', value: 'Ciocănari' },
  { label: 'Ciocăneşti', value: 'Ciocăneşti' },
  { label: 'Ciocăneşti', value: 'Ciocăneşti' },
  { label: 'Ciocănești', value: 'Ciocănești' },
  { label: 'Ciocani', value: 'Ciocani' },
  { label: 'Ciocârlia', value: 'Ciocârlia' },
  { label: 'Ciocârlia', value: 'Ciocârlia' },
  { label: 'Ciocârlia De Sus', value: 'Ciocârlia De Sus' },
  { label: 'Ciochina', value: 'Ciochina' },
  { label: 'Ciochiuța', value: 'Ciochiuța' },
  { label: 'Ciocile', value: 'Ciocile' },
  { label: 'Ciocotiș', value: 'Ciocotiș' },
  { label: 'Ciofliceni', value: 'Ciofliceni' },
  { label: 'Ciofrângeni', value: 'Ciofrângeni' },
  { label: 'Ciohorăni', value: 'Ciohorăni' },
  { label: 'Ciolpani', value: 'Ciolpani' },
  { label: 'Ciolt', value: 'Ciolt' },
  { label: 'Ciomăgeşti', value: 'Ciomăgeşti' },
  { label: 'Ciorani', value: 'Ciorani' },
  { label: 'Cioranii De Jos', value: 'Cioranii De Jos' },
  { label: 'Cioranii De Sus', value: 'Cioranii De Sus' },
  { label: 'Ciorăşti', value: 'Ciorăşti' },
  { label: 'Ciorăști', value: 'Ciorăști' },
  { label: 'Ciorescu', value: 'Ciorescu' },
  { label: 'Ciorlano', value: 'Ciorlano' },
  { label: 'Cioroboreni', value: 'Cioroboreni' },
  { label: 'Ciorogârla', value: 'Ciorogârla' },
  { label: 'Cioroiași', value: 'Cioroiași' },
  { label: 'Ciorteşti', value: 'Ciorteşti' },
  { label: 'Cipaieni', value: 'Cipaieni' },
  { label: 'Cipău', value: 'Cipău' },
  { label: 'Cipérez', value: 'Cipérez' },
  { label: 'Cipó', value: 'Cipó' },
  { label: 'Cipolleto', value: 'Cipolleto' },
  { label: 'Cipolletti', value: 'Cipolletti' },
  { label: 'Cipotânea', value: 'Cipotânea' },
  { label: 'Cipreses', value: 'Cipreses' },
  { label: 'Cipressa', value: 'Cipressa' },
  { label: 'Ciprian Porumbescu', value: 'Ciprian Porumbescu' },
  { label: 'Ciputat', value: 'Ciputat' },
  { label: 'Ciranjang-Hilir', value: 'Ciranjang-Hilir' },
  { label: 'Cirat', value: 'Cirat' },
  { label: 'Cirauqui', value: 'Cirauqui' },
  { label: 'Cirauqui/Zirauki', value: 'Cirauqui/Zirauki' },
  { label: 'Circasia', value: 'Circasia' },
  { label: 'Circello', value: 'Circello' },
  { label: 'Circle', value: 'Circle' },
  { label: 'Circle D-Kc Estates', value: 'Circle D-Kc Estates' },
  { label: 'Circle Pines', value: 'Circle Pines' },
  { label: 'Circleville', value: 'Circleville' },
  { label: 'Circular Head', value: 'Circular Head' },
  { label: 'Cirebon', value: 'Cirebon' },
  { label: 'Ciré-D’Aunis', value: 'Ciré-D’Aunis' },
  { label: 'Cirencester', value: 'Cirencester' },
  { label: 'Cires-Lès-Mello', value: 'Cires-Lès-Mello' },
  { label: 'Cireșoaia', value: 'Cireșoaia' },
  { label: 'Cireșoaia', value: 'Cireșoaia' },
  { label: 'Cireşu', value: 'Cireşu' },
  { label: 'Cireşu', value: 'Cireşu' },
  { label: 'Ciretea', value: 'Ciretea' },
  { label: 'Cirey-Sur-Vezouze', value: 'Cirey-Sur-Vezouze' },
  { label: 'Cirhagău', value: 'Cirhagău' },
  { label: 'Cirí De Los Sotos', value: 'Cirí De Los Sotos' },
  { label: 'Ciria', value: 'Ciria' },
  { label: 'Ciríaco', value: 'Ciríaco' },
  { label: 'Ciriè', value: 'Ciriè' },
  { label: 'Cirigliano', value: 'Cirigliano' },
  { label: 'Cirimido', value: 'Cirimido' },
  { label: 'Ciriza', value: 'Ciriza' },
  { label: 'Ciriza/Ziritza', value: 'Ciriza/Ziritza' },
  { label: 'Cirkulane', value: 'Cirkulane' },
  { label: 'Církvice', value: 'Církvice' },
  { label: 'Cirò', value: 'Cirò' },
  { label: 'Cirò Marina', value: 'Cirò Marina' },
  { label: 'Ciro Redondo', value: 'Ciro Redondo' },
  { label: 'Ciruelas', value: 'Ciruelas' },
  { label: 'Ciruelos', value: 'Ciruelos' },
  { label: 'Ciruelos De Cervera', value: 'Ciruelos De Cervera' },
  { label: 'Ciruelos Del Pinar', value: 'Ciruelos Del Pinar' },
  { label: 'Cirueña', value: 'Cirueña' },
  { label: 'Cirujales Del Río', value: 'Cirujales Del Río' },
  { label: 'Ciry-Le-Noble', value: 'Ciry-Le-Noble' },
  { label: 'Cis', value: 'Cis' },
  { label: 'Cisano', value: 'Cisano' },
  { label: 'Cisano Bergamasco', value: 'Cisano Bergamasco' },
  { label: 'Cisco', value: 'Cisco' },
  { label: 'Cisek', value: 'Cisek' },
  { label: 'Ciserano', value: 'Ciserano' },
  { label: 'Cisiec', value: 'Cisiec' },
  { label: 'Cisla', value: 'Cisla' },
  { label: 'Cislago', value: 'Cislago' },
  { label: 'Cislău', value: 'Cislău' },
  { label: 'Cisliano', value: 'Cisliano' },
  { label: 'Cișmele', value: 'Cișmele' },
  { label: 'Cismon Del Grappa', value: 'Cismon Del Grappa' },
  { label: 'Cisna', value: 'Cisna' },
  { label: 'Cisnădie', value: 'Cisnădie' },
  { label: 'Cisneros', value: 'Cisneros' },
  { label: 'Cisneros', value: 'Cisneros' },
  { label: 'Cisnes', value: 'Cisnes' },
  { label: 'Cison Di Valmarino', value: 'Cison Di Valmarino' },
  { label: 'Cisownica', value: 'Cisownica' },
  { label: 'Cissé', value: 'Cissé' },
  { label: 'Cissone', value: 'Cissone' },
  { label: 'Cistella', value: 'Cistella' },
  { label: 'Cisterna Dasti', value: 'Cisterna Dasti' },
  { label: 'Cisterna Di Latina', value: 'Cisterna Di Latina' },
  { label: 'Cistérniga', value: 'Cistérniga' },
  { label: 'Cisternino', value: 'Cisternino' },
  { label: 'Cistierna', value: 'Cistierna' },
  { label: 'Citala', value: 'Citala' },
  { label: 'Citerna', value: 'Citerna' },
  { label: 'Citeureup', value: 'Citeureup' },
  { label: 'Citilcum', value: 'Citilcum' },
  { label: 'Citlaltépec', value: 'Citlaltépec' },
  { label: 'Citlaltépetl', value: 'Citlaltépetl' },
  { label: 'Čitluk', value: 'Čitluk' },
  { label: 'Cítov', value: 'Cítov' },
  { label: 'Citra', value: 'Citra' },
  { label: 'Citronelle', value: 'Citronelle' },
  { label: 'Citrus', value: 'Citrus' },
  { label: 'Citrus City', value: 'Citrus City' },
  { label: 'Citrus County', value: 'Citrus County' },
  { label: 'Citrus Heights', value: 'Citrus Heights' },
  { label: 'Citrus Hills', value: 'Citrus Hills' },
  { label: 'Citrus Park', value: 'Citrus Park' },
  { label: 'Citrus Park', value: 'Citrus Park' },
  { label: 'Citrus Ridge', value: 'Citrus Ridge' },
  { label: 'Citrus Springs', value: 'Citrus Springs' },
  { label: 'Città Della Pieve', value: 'Città Della Pieve' },
  { label: 'Città Di Castello', value: 'Città Di Castello' },
  { label: 'Città Giardino', value: 'Città Giardino' },
  { label: 'Città Metropolitana Di Milano', value: 'Città Metropolitana Di Milano' },
  { label: 'Città Metropolitana Di Roma Capitale', value: 'Città Metropolitana Di Roma Capitale' },
  { label: 'Città Santangelo', value: 'Città Santangelo' },
  { label: 'Cittadella', value: 'Cittadella' },
  { label: 'Cittadella Del Capo', value: 'Cittadella Del Capo' },
  { label: 'Cittaducale', value: 'Cittaducale' },
  { label: 'Cittanova', value: 'Cittanova' },
  { label: 'Cittareale', value: 'Cittareale' },
  { label: 'Cittiglio', value: 'Cittiglio' },
  { label: 'City', value: 'City' },
  { label: 'City', value: 'City' },
  { label: 'City And Borough Of Birmingham', value: 'City And Borough Of Birmingham' },
  { label: 'City And Borough Of Leeds', value: 'City And Borough Of Leeds' },
  { label: 'City And Borough Of Salford', value: 'City And Borough Of Salford' },
  { label: 'City And Borough Of Wakefield', value: 'City And Borough Of Wakefield' },
  { label: 'City And Borough Of Wrangell', value: 'City And Borough Of Wrangell' },
  { label: 'City And County Of San Francisco', value: 'City And County Of San Francisco' },
  { label: 'City And County Of Swansea', value: 'City And County Of Swansea' },
  { label: 'City Beach', value: 'City Beach' },
  { label: 'City Center', value: 'City Center' },
  { label: 'City Gate/La Clery', value: 'City Gate/La Clery' },
  { label: 'City Ghran', value: 'City Ghran' },
  { label: 'City Island', value: 'City Island' },
  { label: 'City Kazeroon', value: 'City Kazeroon' },
  { label: 'City Of Alexandria', value: 'City Of Alexandria' },
  { label: 'City Of Balanga', value: 'City Of Balanga' },
  { label: 'City Of Balikpapan', value: 'City Of Balikpapan' },
  { label: 'City Of Baltimore', value: 'City Of Baltimore' },
  { label: 'City Of Batac', value: 'City Of Batac' },
  { label: 'City Of Bedford', value: 'City Of Bedford' },
  { label: 'City Of Belfast', value: 'City Of Belfast' },
  { label: 'City Of Bristol', value: 'City Of Bristol' },
  { label: 'City Of Bristol', value: 'City Of Bristol' },
  { label: 'City Of Buena Vista', value: 'City Of Buena Vista' },
  { label: 'City Of Cabadbaran', value: 'City Of Cabadbaran' },
  { label: 'City Of Candon', value: 'City Of Candon' },
  { label: 'City Of Cape Town', value: 'City Of Cape Town' },
  { label: 'City Of Charlottesville', value: 'City Of Charlottesville' },
  { label: 'City Of Chesapeake', value: 'City Of Chesapeake' },
  { label: 'City Of Cockburn', value: 'City Of Cockburn' },
  { label: 'City Of Colonial Heights', value: 'City Of Colonial Heights' },
  { label: 'City Of Covington', value: 'City Of Covington' },
  { label: 'City Of Danville', value: 'City Of Danville' },
  { label: 'City Of Edinburgh', value: 'City Of Edinburgh' },
  { label: 'City Of Emporia', value: 'City Of Emporia' },
  { label: 'City Of Fairfax', value: 'City Of Fairfax' },
  { label: 'City Of Falls Church', value: 'City Of Falls Church' },
  { label: 'City Of Franklin', value: 'City Of Franklin' },
  { label: 'City Of Fredericksburg', value: 'City Of Fredericksburg' },
  { label: 'City Of Galax', value: 'City Of Galax' },
  { label: 'City Of Gapan', value: 'City Of Gapan' },
  { label: 'City Of Hampton', value: 'City Of Hampton' },
  { label: 'City Of Harrisonburg', value: 'City Of Harrisonburg' },
  { label: 'City Of Hopewell', value: 'City Of Hopewell' },
  { label: 'City Of Industry', value: 'City Of Industry' },
  { label: 'City Of Isabela', value: 'City Of Isabela' },
  { label: 'City Of Johannesburg Metropolitan Municipality', value: 'City Of Johannesburg Metropolitan Municipality' },
  { label: 'City Of Kidapawan', value: 'City Of Kidapawan' },
  { label: 'City Of Kingston Upon Hull', value: 'City Of Kingston Upon Hull' },
  { label: 'City Of Koronadal', value: 'City Of Koronadal' },
  { label: 'City Of Leicester', value: 'City Of Leicester' },
  { label: 'City Of Lexington', value: 'City Of Lexington' },
  { label: 'City Of London', value: 'City Of London' },
  { label: 'City Of Lynchburg', value: 'City Of Lynchburg' },
  { label: 'City Of Malolos', value: 'City Of Malolos' },
  { label: 'City Of Manassas', value: 'City Of Manassas' },
  { label: 'City Of Manassas Park', value: 'City Of Manassas Park' },
  { label: 'City Of Martinsville', value: 'City Of Martinsville' },
  { label: 'City Of Mati', value: 'City Of Mati' },
  { label: 'City Of Mati', value: 'City Of Mati' },
  { label: 'City Of Meycauayan', value: 'City Of Meycauayan' },
  { label: 'City Of Milford (Balance)', value: 'City Of Milford (Balance)' },
  { label: 'City Of Newport News', value: 'City Of Newport News' },
  { label: 'City Of Norfolk', value: 'City Of Norfolk' },
  { label: 'City Of Norton', value: 'City Of Norton' },
  { label: 'City Of Panabo', value: 'City Of Panabo' },
  { label: 'City Of Panabo', value: 'City Of Panabo' },
  { label: 'City Of Perth', value: 'City Of Perth' },
  { label: 'City Of Petersburg', value: 'City Of Petersburg' },
  { label: 'City Of Poquoson', value: 'City Of Poquoson' },
  { label: 'City Of Portsmouth', value: 'City Of Portsmouth' },
  { label: 'City Of Radford', value: 'City Of Radford' },
  { label: 'City Of Richmond', value: 'City Of Richmond' },
  { label: 'City Of Roanoke', value: 'City Of Roanoke' },
  { label: 'City Of Saint Louis', value: 'City Of Saint Louis' },
  { label: 'City Of Salem', value: 'City Of Salem' },
  { label: 'City Of Sammamish', value: 'City Of Sammamish' },
  { label: 'City Of San Fernando', value: 'City Of San Fernando' },
  { label: 'City Of San Jose Del Monte', value: 'City Of San Jose Del Monte' },
  { label: 'City Of Staunton', value: 'City Of Staunton' },
  { label: 'City Of Suffolk', value: 'City Of Suffolk' },
  { label: 'City Of Sydney', value: 'City Of Sydney' },
  { label: 'City Of Tacurong', value: 'City Of Tacurong' },
  { label: 'City Of Tagum', value: 'City Of Tagum' },
  { label: 'City Of Tagum', value: 'City Of Tagum' },
  { label: 'City Of Tshwane Metropolitan Municipality', value: 'City Of Tshwane Metropolitan Municipality' },
  { label: 'City Of Urdaneta', value: 'City Of Urdaneta' },
  { label: 'City Of Vigan', value: 'City Of Vigan' },
  { label: 'City Of Virginia Beach', value: 'City Of Virginia Beach' },
  { label: 'City Of Waynesboro', value: 'City Of Waynesboro' },
  { label: 'City Of West Torrens', value: 'City Of West Torrens' },
  { label: 'City Of Westminster', value: 'City Of Westminster' },
  { label: 'City Of Williamsburg', value: 'City Of Williamsburg' },
  { label: 'City Of Winchester', value: 'City Of Winchester' },
  { label: 'City Of York', value: 'City Of York' },
  { label: 'City View', value: 'City View' },
  { label: 'Ciuani', value: 'Ciuani' },
  { label: 'Ciucani', value: 'Ciucani' },
  { label: 'Ciucea', value: 'Ciucea' },
  { label: 'Ciuchici', value: 'Ciuchici' },
  { label: 'Ciucsângeorgiu', value: 'Ciucsângeorgiu' },
  { label: 'Ciucurova', value: 'Ciucurova' },
  { label: 'Ciudad Acuña', value: 'Ciudad Acuña' },
  { label: 'Ciudad Altamirano', value: 'Ciudad Altamirano' },
  { label: 'Ciudad Anáhuac', value: 'Ciudad Anáhuac' },
  { label: 'Ciudad Antigua', value: 'Ciudad Antigua' },
  { label: 'Ciudad Apodaca', value: 'Ciudad Apodaca' },
  { label: 'Ciudad Arce', value: 'Ciudad Arce' },
  { label: 'Ciudad Ayala', value: 'Ciudad Ayala' },
  { label: 'Ciudad Barrios', value: 'Ciudad Barrios' },
  { label: 'Ciudad Benito Juárez', value: 'Ciudad Benito Juárez' },
  { label: 'Ciudad Bolívar', value: 'Ciudad Bolívar' },
  { label: 'Ciudad Bolívar', value: 'Ciudad Bolívar' },
  { label: 'Ciudad Camargo', value: 'Ciudad Camargo' },
  { label: 'Ciudad Cerralvo', value: 'Ciudad Cerralvo' },
  { label: 'Ciudad Chemuyil', value: 'Ciudad Chemuyil' },
  { label: 'Ciudad Choluteca', value: 'Ciudad Choluteca' },
  { label: 'Ciudad Coahuila (Kilómetro Cincuenta Y Siete)', value: 'Ciudad Coahuila (Kilómetro Cincuenta Y Siete)' },
  { label: 'Ciudad Constitución', value: 'Ciudad Constitución' },
  { label: 'Ciudad Cortés', value: 'Ciudad Cortés' },
  { label: 'Ciudad Cuauhtémoc', value: 'Ciudad Cuauhtémoc' },
  { label: 'Ciudad Cuauhtémoc', value: 'Ciudad Cuauhtémoc' },
  { label: 'Ciudad Darío', value: 'Ciudad Darío' },
  { label: 'Ciudad De Allende', value: 'Ciudad De Allende' },
  { label: 'Ciudad De Armería', value: 'Ciudad De Armería' },
  { label: 'Ciudad De Chiautla De Tapia', value: 'Ciudad De Chiautla De Tapia' },
  { label: 'Ciudad De Cuetzalan', value: 'Ciudad De Cuetzalan' },
  { label: 'Ciudad De Huajuapan De León', value: 'Ciudad De Huajuapan De León' },
  { label: 'Ciudad De Huitzuco', value: 'Ciudad De Huitzuco' },
  { label: 'Ciudad De México', value: 'Ciudad De México' },
  { label: 'Ciudad De Nanacamilpa', value: 'Ciudad De Nanacamilpa' },
  { label: 'Ciudad De Tlatlauquitepec', value: 'Ciudad De Tlatlauquitepec' },
  { label: 'Ciudad De Villa De Álvarez', value: 'Ciudad De Villa De Álvarez' },
  { label: 'Ciudad De Villaldama', value: 'Ciudad De Villaldama' },
  { label: 'Ciudad Del Carmen', value: 'Ciudad Del Carmen' },
  { label: 'Ciudad Del Este', value: 'Ciudad Del Este' },
  { label: 'Ciudad Del Maíz', value: 'Ciudad Del Maíz' },
  { label: 'Ciudad Delicias', value: 'Ciudad Delicias' },
  { label: 'Ciudad Fernández', value: 'Ciudad Fernández' },
  { label: 'Ciudad General Escobedo', value: 'Ciudad General Escobedo' },
  { label: 'Ciudad General Terán', value: 'Ciudad General Terán' },
  { label: 'Ciudad Guadalupe Victoria', value: 'Ciudad Guadalupe Victoria' },
  { label: 'Ciudad Guayana', value: 'Ciudad Guayana' },
  { label: 'Ciudad Gustavo Díaz Ordaz', value: 'Ciudad Gustavo Díaz Ordaz' },
  { label: 'Ciudad Guzmán', value: 'Ciudad Guzmán' },
  { label: 'Ciudad Guzmán Cereso', value: 'Ciudad Guzmán Cereso' },
  { label: 'Ciudad Hidalgo', value: 'Ciudad Hidalgo' },
  { label: 'Ciudad Hidalgo', value: 'Ciudad Hidalgo' },
  { label: 'Ciudad Insurgentes', value: 'Ciudad Insurgentes' },
  { label: 'Ciudad Lázaro Cárdenas', value: 'Ciudad Lázaro Cárdenas' },
  { label: 'Ciudad Lerdo', value: 'Ciudad Lerdo' },
  { label: 'Ciudad Lineal', value: 'Ciudad Lineal' },
  { label: 'Ciudad López Mateos', value: 'Ciudad López Mateos' },
  { label: 'Ciudad Madero', value: 'Ciudad Madero' },
  { label: 'Ciudad Mante', value: 'Ciudad Mante' },
  { label: 'Ciudad Manuel Doblado', value: 'Ciudad Manuel Doblado' },
  { label: 'Ciudad Melchor Múzquiz', value: 'Ciudad Melchor Múzquiz' },
  { label: 'Ciudad Mendoza', value: 'Ciudad Mendoza' },
  { label: 'Ciudad Miguel Alemán', value: 'Ciudad Miguel Alemán' },
  { label: 'Ciudad Morelos', value: 'Ciudad Morelos' },
  { label: 'Ciudad Nezahualcoyotl', value: 'Ciudad Nezahualcoyotl' },
  { label: 'Ciudad Nueva', value: 'Ciudad Nueva' },
  { label: 'Ciudad Obregón', value: 'Ciudad Obregón' },
  { label: 'Ciudad Ojeda', value: 'Ciudad Ojeda' },
  { label: 'Ciudad Pemex', value: 'Ciudad Pemex' },
  { label: 'Ciudad Real', value: 'Ciudad Real' },
  { label: 'Ciudad Río Bravo', value: 'Ciudad Río Bravo' },
  { label: 'Ciudad Rodrigo', value: 'Ciudad Rodrigo' },
  { label: 'Ciudad Sabinas Hidalgo', value: 'Ciudad Sabinas Hidalgo' },
  { label: 'Ciudad Sahagun', value: 'Ciudad Sahagun' },
  { label: 'Ciudad Sandino', value: 'Ciudad Sandino' },
  { label: 'Ciudad Satélite', value: 'Ciudad Satélite' },
  { label: 'Ciudad Satélite Del Norte', value: 'Ciudad Satélite Del Norte' },
  { label: 'Ciudad Serdán', value: 'Ciudad Serdán' },
  { label: 'Ciudad Tecún Umán', value: 'Ciudad Tecún Umán' },
  { label: 'Ciudad Tula', value: 'Ciudad Tula' },
  { label: 'Ciudad Valles', value: 'Ciudad Valles' },
  { label: 'Ciudad Victoria', value: 'Ciudad Victoria' },
  { label: 'Ciudad Vieja', value: 'Ciudad Vieja' },
  { label: 'Ciudad Vieja', value: 'Ciudad Vieja' },
  { label: 'Ciudanoviţa', value: 'Ciudanoviţa' },
  { label: 'Ciugheș', value: 'Ciugheș' },
  { label: 'Ciugud', value: 'Ciugud' },
  { label: 'Ciulea', value: 'Ciulea' },
  { label: 'Ciulniţa', value: 'Ciulniţa' },
  { label: 'Ciulnița', value: 'Ciulnița' },
  { label: 'Ciumani', value: 'Ciumani' },
  { label: 'Ciumbrud', value: 'Ciumbrud' },
  { label: 'Ciumeghiu', value: 'Ciumeghiu' },
  { label: 'Ciumești', value: 'Ciumești' },
  { label: 'Ciuperceni', value: 'Ciuperceni' },
  { label: 'Ciuperceni', value: 'Ciuperceni' },
  { label: 'Ciupercenii Noi', value: 'Ciupercenii Noi' },
  { label: 'Ciupercenii Vechi', value: 'Ciupercenii Vechi' },
  { label: 'Ciurea', value: 'Ciurea' },
  { label: 'Ciurgău', value: 'Ciurgău' },
  { label: 'Ciurila', value: 'Ciurila' },
  { label: 'Ciuruleasa', value: 'Ciuruleasa' },
  { label: 'Ciușlea', value: 'Ciușlea' },
  { label: 'Ciuta', value: 'Ciuta' },
  { label: 'Ciutadella', value: 'Ciutadella' },
  { label: 'Ciutadella De Menorca', value: 'Ciutadella De Menorca' },
  { label: 'Ciutadilla', value: 'Ciutadilla' },
  { label: 'Ciutelec', value: 'Ciutelec' },
  { label: 'Civate', value: 'Civate' },
  { label: 'Civens', value: 'Civens' },
  { label: 'Civesio', value: 'Civesio' },
  { label: 'Civezza', value: 'Civezza' },
  { label: 'Civezzano', value: 'Civezzano' },
  { label: 'Civiasco', value: 'Civiasco' },
  { label: 'Cividale Del Friuli', value: 'Cividale Del Friuli' },
  { label: 'Cividate Al Piano', value: 'Cividate Al Piano' },
  { label: 'Cividate Camuno', value: 'Cividate Camuno' },
  { label: 'Civita', value: 'Civita' },
  { label: 'Civita Castellana', value: 'Civita Castellana' },
  { label: 'Civita Dantino', value: 'Civita Dantino' },
  { label: 'Civitacampomarano', value: 'Civitacampomarano' },
  { label: 'Civitaluparella', value: 'Civitaluparella' },
  { label: 'Civitanova Alta', value: 'Civitanova Alta' },
  { label: 'Civitanova Del Sannio', value: 'Civitanova Del Sannio' },
  { label: 'Civitanova Marche', value: 'Civitanova Marche' },
  { label: 'Civitaquana', value: 'Civitaquana' },
  { label: 'Civitavecchia', value: 'Civitavecchia' },
  { label: 'Civitella Alfedena', value: 'Civitella Alfedena' },
  { label: 'Civitella Casanova', value: 'Civitella Casanova' },
  { label: 'Civitella Dagliano', value: 'Civitella Dagliano' },
  { label: 'Civitella Del Tronto', value: 'Civitella Del Tronto' },
  { label: 'Civitella Di Romagna', value: 'Civitella Di Romagna' },
  { label: 'Civitella In Val Di Chiana', value: 'Civitella In Val Di Chiana' },
  { label: 'Civitella Marittima', value: 'Civitella Marittima' },
  { label: 'Civitella Messer Raimondo', value: 'Civitella Messer Raimondo' },
  { label: 'Civitella Roveto', value: 'Civitella Roveto' },
  { label: 'Civitella San Paolo', value: 'Civitella San Paolo' },
  { label: 'Civray', value: 'Civray' },
  { label: 'Civray-De-Touraine', value: 'Civray-De-Touraine' },
  { label: 'Civrieux', value: 'Civrieux' },
  { label: 'Çivril', value: 'Çivril' },
  { label: 'Cizer', value: 'Cizer' },
  { label: 'Čížkovice', value: 'Čížkovice' },
  { label: 'Cizre', value: 'Cizre' },
  { label: 'Cizur', value: 'Cizur' },
  { label: 'Cizur Mayor', value: 'Cizur Mayor' },
  { label: 'Cizzago-Comezzano', value: 'Cizzago-Comezzano' },
  { label: 'Čkyně', value: 'Čkyně' },
  { label: 'Clackamas', value: 'Clackamas' },
  { label: 'Clackamas County', value: 'Clackamas County' },
  { label: 'Clackmannan', value: 'Clackmannan' },
  { label: 'Clackmannanshire', value: 'Clackmannanshire' },
  { label: 'Clacton-On-Sea', value: 'Clacton-On-Sea' },
  { label: 'Claiborne', value: 'Claiborne' },
  { label: 'Claiborne County', value: 'Claiborne County' },
  { label: 'Claiborne County', value: 'Claiborne County' },
  { label: 'Claiborne Parish', value: 'Claiborne Parish' },
  { label: 'Claira', value: 'Claira' },
  { label: 'Clairac', value: 'Clairac' },
  { label: 'Clairoix', value: 'Clairoix' },
  { label: 'Clairton', value: 'Clairton' },
  { label: 'Clairvaux-Les-Lacs', value: 'Clairvaux-Les-Lacs' },
  { label: 'Claix', value: 'Claix' },
  { label: 'Clallam County', value: 'Clallam County' },
  { label: 'Clamart', value: 'Clamart' },
  { label: 'Clamecy', value: 'Clamecy' },
  { label: 'Clancy', value: 'Clancy' },
  { label: 'Clane', value: 'Clane' },
  { label: 'Clanton', value: 'Clanton' },
  { label: 'Clanwilliam', value: 'Clanwilliam' },
  { label: 'Clapham', value: 'Clapham' },
  { label: 'Clapham', value: 'Clapham' },
  { label: 'Clapiers', value: 'Clapiers' },
  { label: 'Clara', value: 'Clara' },
  { label: 'Clara City', value: 'Clara City' },
  { label: 'Claraval', value: 'Claraval' },
  { label: 'Clarborough', value: 'Clarborough' },
  { label: 'Clarcona', value: 'Clarcona' },
  { label: 'Clare', value: 'Clare' },
  { label: 'Clare', value: 'Clare' },
  { label: 'Clare', value: 'Clare' },
  { label: 'Clare And Gilbert Valleys', value: 'Clare And Gilbert Valleys' },
  { label: 'Clare County', value: 'Clare County' },
  { label: 'Claregalway', value: 'Claregalway' },
  { label: 'Claremont', value: 'Claremont' },
  { label: 'Claremont', value: 'Claremont' },
  { label: 'Claremont', value: 'Claremont' },
  { label: 'Claremont', value: 'Claremont' },
  { label: 'Claremont', value: 'Claremont' },
  { label: 'Claremont', value: 'Claremont' },
  { label: 'Claremont', value: 'Claremont' },
  { label: 'Claremont Meadows', value: 'Claremont Meadows' },
  { label: 'Claremore', value: 'Claremore' },
  { label: 'Claremorris', value: 'Claremorris' },
  { label: 'Clarence', value: 'Clarence' },
  { label: 'Clarence', value: 'Clarence' },
  { label: 'Clarence Center', value: 'Clarence Center' },
  { label: 'Clarence Gardens', value: 'Clarence Gardens' },
  { label: 'Clarence Park', value: 'Clarence Park' },
  { label: 'Clarence Town', value: 'Clarence Town' },
  { label: 'Clarence Town', value: 'Clarence Town' },
  { label: 'Clarence Valley', value: 'Clarence Valley' },
  { label: 'Clarence-Rockland', value: 'Clarence-Rockland' },
  { label: 'Clarendon', value: 'Clarendon' },
  { label: 'Clarendon', value: 'Clarendon' },
  { label: 'Clarendon', value: 'Clarendon' },
  { label: 'Clarendon County', value: 'Clarendon County' },
  { label: 'Clarendon Hills', value: 'Clarendon Hills' },
  { label: 'Clarendon Vale', value: 'Clarendon Vale' },
  { label: 'Clarensac', value: 'Clarensac' },
  { label: 'Clarenville-Shoal Harbour', value: 'Clarenville-Shoal Harbour' },
  { label: 'Clarés De Ribota', value: 'Clarés De Ribota' },
  { label: 'Claresholm', value: 'Claresholm' },
  { label: 'Claret', value: 'Claret' },
  { label: 'Clariana De Cardener', value: 'Clariana De Cardener' },
  { label: 'Clarin', value: 'Clarin' },
  { label: 'Clarin', value: 'Clarin' },
  { label: 'Clarinda', value: 'Clarinda' },
  { label: 'Clarinda', value: 'Clarinda' },
  { label: 'Clarion', value: 'Clarion' },
  { label: 'Clarion', value: 'Clarion' },
  { label: 'Clarion County', value: 'Clarion County' },
  { label: 'Clark', value: 'Clark' },
  { label: 'Clark', value: 'Clark' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark County', value: 'Clark County' },
  { label: 'Clark Mills', value: 'Clark Mills' },
  { label: 'Clarkdale', value: 'Clarkdale' },
  { label: 'Clarke County', value: 'Clarke County' },
  { label: 'Clarke County', value: 'Clarke County' },
  { label: 'Clarke County', value: 'Clarke County' },
  { label: 'Clarke County', value: 'Clarke County' },
  { label: 'Clarke County', value: 'Clarke County' },
  { label: 'Clarkesville', value: 'Clarkesville' },
  { label: 'Clark-Fulton', value: 'Clark-Fulton' },
  { label: 'Clarks', value: 'Clarks' },
  { label: 'Clarks Green', value: 'Clarks Green' },
  { label: 'Clarks Summit', value: 'Clarks Summit' },
  { label: 'Clarks Town', value: 'Clarks Town' },
  { label: 'Clarksburg', value: 'Clarksburg' },
  { label: 'Clarksburg', value: 'Clarksburg' },
  { label: 'Clarksdale', value: 'Clarksdale' },
  { label: 'Clarkson', value: 'Clarkson' },
  { label: 'Clarkson', value: 'Clarkson' },
  { label: 'Clarkson Valley', value: 'Clarkson Valley' },
  { label: 'Clarkston', value: 'Clarkston' },
  { label: 'Clarkston', value: 'Clarkston' },
  { label: 'Clarkston', value: 'Clarkston' },
  { label: 'Clarkston', value: 'Clarkston' },
  { label: 'Clarkston Heights-Vineland', value: 'Clarkston Heights-Vineland' },
  { label: 'Clarksville', value: 'Clarksville' },
  { label: 'Clarksville', value: 'Clarksville' },
  { label: 'Clarksville', value: 'Clarksville' },
  { label: 'Clarksville', value: 'Clarksville' },
  { label: 'Clarksville', value: 'Clarksville' },
  { label: 'Clarksville', value: 'Clarksville' },
  { label: 'Clarkton', value: 'Clarkton' },
  { label: 'Claro', value: 'Claro' },
  { label: 'Claro Dos Poções', value: 'Claro Dos Poções' },
  { label: 'Clary', value: 'Clary' },
  { label: 'Claryville', value: 'Claryville' },
  { label: 'Classe', value: 'Classe' },
  { label: 'Clatskanie', value: 'Clatskanie' },
  { label: 'Clatsop County', value: 'Clatsop County' },
  { label: 'Claude', value: 'Claude' },
  { label: 'Cláudia', value: 'Cláudia' },
  { label: 'Cláudio', value: 'Cláudio' },
  { label: 'Clausen', value: 'Clausen' },
  { label: 'Claußnitz', value: 'Claußnitz' },
  { label: 'Clausthal-Zellerfeld', value: 'Clausthal-Zellerfeld' },
  { label: 'Claut', value: 'Claut' },
  { label: 'Clauzetto', value: 'Clauzetto' },
  { label: 'Claver', value: 'Claver' },
  { label: 'Claver', value: 'Claver' },
  { label: 'Claveria', value: 'Claveria' },
  { label: 'Claveria', value: 'Claveria' },
  { label: 'Claveria', value: 'Claveria' },
  { label: 'Claveria', value: 'Claveria' },
  { label: 'Claverty Cottage', value: 'Claverty Cottage' },
  { label: 'Clavesana', value: 'Clavesana' },
  { label: 'Clavier', value: 'Clavier' },
  { label: 'Claviere', value: 'Claviere' },
  { label: 'Clavijo', value: 'Clavijo' },
  { label: 'Claville', value: 'Claville' },
  { label: 'Clawson', value: 'Clawson' },
  { label: 'Claxton', value: 'Claxton' },
  { label: 'Clay', value: 'Clay' },
  { label: 'Clay', value: 'Clay' },
  { label: 'Clay', value: 'Clay' },
  { label: 'Clay', value: 'Clay' },
  { label: 'Clay', value: 'Clay' },
  { label: 'Clay Center', value: 'Clay Center' },
  { label: 'Clay Center', value: 'Clay Center' },
  { label: 'Clay City', value: 'Clay City' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Clay County', value: 'Clay County' },
  { label: 'Claycomo', value: 'Claycomo' },
  { label: 'Claydon', value: 'Claydon' },
  { label: 'Claye-Souilly', value: 'Claye-Souilly' },
  { label: 'Clayfield', value: 'Clayfield' },
  { label: 'Claymont', value: 'Claymont' },
  { label: 'Claymore', value: 'Claymore' },
  { label: 'Claypole', value: 'Claypole' },
  { label: 'Claypool', value: 'Claypool' },
  { label: 'Claypool Hill', value: 'Claypool Hill' },
  { label: 'Claysburg', value: 'Claysburg' },
  { label: 'Clayton', value: 'Clayton' },
  { label: 'Clayton', value: 'Clayton' },
  { label: 'Clayton', value: 'Clayton' },
  { label: 'Clayton', value: 'Clayton' },
  { label: 'Clayton', value: 'Clayton' },
  { label: 'Clayton', value: 'Clayton' },
  { label: 'Clayton', value: 'Clayton' },
  { label: 'Clayton', value: 'Clayton' },
  { label: 'Clayton', value: 'Clayton' },
  { label: 'Clayton', value: 'Clayton' },
  { label: 'Clayton', value: 'Clayton' },
  { label: 'Clayton County', value: 'Clayton County' },
  { label: 'Clayton County', value: 'Clayton County' },
  { label: 'Clayton Le Moors', value: 'Clayton Le Moors' },
  { label: 'Clayton South', value: 'Clayton South' },
  { label: 'Clayton West', value: 'Clayton West' },
  { label: 'Clayton-Le-Woods', value: 'Clayton-Le-Woods' },
  { label: 'Cle Elum', value: 'Cle Elum' },
  { label: 'Cleanov', value: 'Cleanov' },
  { label: 'Clear Creek County', value: 'Clear Creek County' },
  { label: 'Clear Island Waters', value: 'Clear Island Waters' },
  { label: 'Clear Lake', value: 'Clear Lake' },
  { label: 'Clear Lake', value: 'Clear Lake' },
  { label: 'Clear Lake', value: 'Clear Lake' },
  { label: 'Clear Lake', value: 'Clear Lake' },
  { label: 'Clear Lake Riviera', value: 'Clear Lake Riviera' },
  { label: 'Clear Lake Shores', value: 'Clear Lake Shores' },
  { label: 'Clearbrook Park', value: 'Clearbrook Park' },
  { label: 'Clearfield', value: 'Clearfield' },
  { label: 'Clearfield', value: 'Clearfield' },
  { label: 'Clearfield County', value: 'Clearfield County' },
  { label: 'Clearlake', value: 'Clearlake' },
  { label: 'Clearlake Oaks', value: 'Clearlake Oaks' },
  { label: 'Clearview', value: 'Clearview' },
  { label: 'Clearview', value: 'Clearview' },
  { label: 'Clearwater', value: 'Clearwater' },
  { label: 'Clearwater', value: 'Clearwater' },
  { label: 'Clearwater', value: 'Clearwater' },
  { label: 'Clearwater', value: 'Clearwater' },
  { label: 'Clearwater County', value: 'Clearwater County' },
  { label: 'Clearwater County', value: 'Clearwater County' },
  { label: 'Cleary', value: 'Cleary' },
  { label: 'Cleator Moor', value: 'Cleator Moor' },
  { label: 'Cleburne', value: 'Cleburne' },
  { label: 'Cleburne County', value: 'Cleburne County' },
  { label: 'Cleburne County', value: 'Cleburne County' },
  { label: 'Cleckheaton', value: 'Cleckheaton' },
  { label: 'Clécy', value: 'Clécy' },
  { label: 'Cléden-Poher', value: 'Cléden-Poher' },
  { label: 'Cléder', value: 'Cléder' },
  { label: 'Cleebronn', value: 'Cleebronn' },
  { label: 'Cleethorpes', value: 'Cleethorpes' },
  { label: 'Cléguer', value: 'Cléguer' },
  { label: 'Cléguérec', value: 'Cléguérec' },
  { label: 'Cleja', value: 'Cleja' },
  { label: 'Clejani', value: 'Clejani' },
  { label: 'Cleland', value: 'Cleland' },
  { label: 'Clemencia', value: 'Clemencia' },
  { label: 'Clémencia', value: 'Clémencia' },
  { label: 'Clemency', value: 'Clemency' },
  { label: 'Clement Town', value: 'Clement Town' },
  { label: 'Clementina', value: 'Clementina' },
  { label: 'Clementon', value: 'Clementon' },
  { label: 'Clemmons', value: 'Clemmons' },
  { label: 'Clemson', value: 'Clemson' },
  { label: 'Clemton Park', value: 'Clemton Park' },
  { label: 'Clenchwarton', value: 'Clenchwarton' },
  { label: 'Clendenin', value: 'Clendenin' },
  { label: 'Clenze', value: 'Clenze' },
  { label: 'Cleobury Mortimer', value: 'Cleobury Mortimer' },
  { label: 'Cléon', value: 'Cléon' },
  { label: 'Cleona', value: 'Cleona' },
  { label: 'Cleora', value: 'Cleora' },
  { label: 'Clérac', value: 'Clérac' },
  { label: 'Cléré-Les-Pins', value: 'Cléré-Les-Pins' },
  { label: 'Clères', value: 'Clères' },
  { label: 'Clérieux', value: 'Clérieux' },
  { label: 'Clerkenwell', value: 'Clerkenwell' },
  { label: 'Clermont', value: 'Clermont' },
  { label: 'Clermont', value: 'Clermont' },
  { label: 'Clermont', value: 'Clermont' },
  { label: 'Clermont', value: 'Clermont' },
  { label: 'Clermont County', value: 'Clermont County' },
  { label: 'Clermont-Créans', value: 'Clermont-Créans' },
  { label: 'Clermont-En-Argonne', value: 'Clermont-En-Argonne' },
  { label: 'Clermont-Ferrand', value: 'Clermont-Ferrand' },
  { label: 'Clerval', value: 'Clerval' },
  { label: 'Clervaux', value: 'Clervaux' },
  { label: 'Cléry-Saint-André', value: 'Cléry-Saint-André' },
  { label: 'Cles', value: 'Cles' },
  { label: 'Cleto', value: 'Cleto' },
  { label: 'Cleve', value: 'Cleve' },
  { label: 'Clevedon', value: 'Clevedon' },
  { label: 'Cleveland', value: 'Cleveland' },
  { label: 'Cleveland', value: 'Cleveland' },
  { label: 'Cleveland', value: 'Cleveland' },
  { label: 'Cleveland', value: 'Cleveland' },
  { label: 'Cleveland', value: 'Cleveland' },
  { label: 'Cleveland', value: 'Cleveland' },
  { label: 'Cleveland', value: 'Cleveland' },
  { label: 'Cleveland', value: 'Cleveland' },
  { label: 'Cleveland', value: 'Cleveland' },
  { label: 'Cleveland', value: 'Cleveland' },
  { label: 'Cleveland County', value: 'Cleveland County' },
  { label: 'Cleveland County', value: 'Cleveland County' },
  { label: 'Cleveland County', value: 'Cleveland County' },
  { label: 'Cleveland Heights', value: 'Cleveland Heights' },
  { label: 'Clevelândia', value: 'Clevelândia' },
  { label: 'Cleveleys', value: 'Cleveleys' },
  { label: 'Clever', value: 'Clever' },
  { label: 'Cleves', value: 'Cleves' },
  { label: 'Clewiston', value: 'Clewiston' },
  { label: 'Clichy', value: 'Clichy' },
  { label: 'Clichy-Sous-Bois', value: 'Clichy-Sous-Bois' },
  { label: 'Clifden', value: 'Clifden' },
  { label: 'Cliffe', value: 'Cliffe' },
  { label: 'Cliffside Park', value: 'Cliffside Park' },
  { label: 'Cliffwood Beach', value: 'Cliffwood Beach' },
  { label: 'Clifton', value: 'Clifton' },
  { label: 'Clifton', value: 'Clifton' },
  { label: 'Clifton', value: 'Clifton' },
  { label: 'Clifton', value: 'Clifton' },
  { label: 'Clifton', value: 'Clifton' },
  { label: 'Clifton', value: 'Clifton' },
  { label: 'Clifton', value: 'Clifton' },
  { label: 'Clifton', value: 'Clifton' },
  { label: 'Clifton', value: 'Clifton' },
  { label: 'Clifton Beach', value: 'Clifton Beach' },
  { label: 'Clifton Forge', value: 'Clifton Forge' },
  { label: 'Clifton Heights', value: 'Clifton Heights' },
  { label: 'Clifton Hill', value: 'Clifton Hill' },
  { label: 'Clifton Springs', value: 'Clifton Springs' },
  { label: 'Clifton Springs', value: 'Clifton Springs' },
  { label: 'Clinceni', value: 'Clinceni' },
  { label: 'Clinch County', value: 'Clinch County' },
  { label: 'Clinchamps-Sur-Orne', value: 'Clinchamps-Sur-Orne' },
  { label: 'Clingen', value: 'Clingen' },
  { label: 'Clint', value: 'Clint' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton', value: 'Clinton' },
  { label: 'Clinton County', value: 'Clinton County' },
  { label: 'Clinton County', value: 'Clinton County' },
  { label: 'Clinton County', value: 'Clinton County' },
  { label: 'Clinton County', value: 'Clinton County' },
  { label: 'Clinton County', value: 'Clinton County' },
  { label: 'Clinton County', value: 'Clinton County' },
  { label: 'Clinton County', value: 'Clinton County' },
  { label: 'Clinton County', value: 'Clinton County' },
  { label: 'Clinton County', value: 'Clinton County' },
  { label: 'Clinton Township', value: 'Clinton Township' },
  { label: 'Clintondale', value: 'Clintondale' },
  { label: 'Clintonville', value: 'Clintonville' },
  { label: 'Clintwood', value: 'Clintwood' },
  { label: 'Clio', value: 'Clio' },
  { label: 'Clio', value: 'Clio' },
  { label: 'Clion', value: 'Clion' },
  { label: 'Cliserio Alanís (San Gaspar)', value: 'Cliserio Alanís (San Gaspar)' },
  { label: 'Clisson', value: 'Clisson' },
  { label: 'Clit', value: 'Clit' },
  { label: 'Clitheroe', value: 'Clitheroe' },
  { label: 'Clive', value: 'Clive' },
  { label: 'Clive', value: 'Clive' },
  { label: 'Clivio', value: 'Clivio' },
  { label: 'Cliza', value: 'Cliza' },
  { label: 'Cloașterf', value: 'Cloașterf' },
  { label: 'Clocolan', value: 'Clocolan' },
  { label: 'Clocotici', value: 'Clocotici' },
  { label: 'Clodig', value: 'Clodig' },
  { label: 'Clodomira', value: 'Clodomira' },
  { label: 'Cloete', value: 'Cloete' },
  { label: 'Clogherhead', value: 'Clogherhead' },
  { label: 'Clohars-Carnoët', value: 'Clohars-Carnoët' },
  { label: 'Clohars-Fouesnant', value: 'Clohars-Fouesnant' },
  { label: 'Clonakilty', value: 'Clonakilty' },
  { label: 'Clonas-Sur-Varèze', value: 'Clonas-Sur-Varèze' },
  { label: 'Cloncurry', value: 'Cloncurry' },
  { label: 'Clondalkin', value: 'Clondalkin' },
  { label: 'Clones', value: 'Clones' },
  { label: 'Clonmel', value: 'Clonmel' },
  { label: 'Clonskeagh', value: 'Clonskeagh' },
  { label: 'Clontarf', value: 'Clontarf' },
  { label: 'Clontarf', value: 'Clontarf' },
  { label: 'Clophill', value: 'Clophill' },
  { label: 'Cloppenburg', value: 'Cloppenburg' },
  { label: 'Cloquet', value: 'Cloquet' },
  { label: 'Clorinda', value: 'Clorinda' },
  { label: 'Cloșani', value: 'Cloșani' },
  { label: 'Closepet', value: 'Closepet' },
  { label: 'Closter', value: 'Closter' },
  { label: 'Clouange', value: 'Clouange' },
  { label: 'Cloud County', value: 'Cloud County' },
  { label: 'Clovelly', value: 'Clovelly' },
  { label: 'Clovelly Park', value: 'Clovelly Park' },
  { label: 'Clover', value: 'Clover' },
  { label: 'Clover Hill', value: 'Clover Hill' },
  { label: 'Cloverdale', value: 'Cloverdale' },
  { label: 'Cloverdale', value: 'Cloverdale' },
  { label: 'Cloverdale', value: 'Cloverdale' },
  { label: 'Cloverdale', value: 'Cloverdale' },
  { label: 'Cloverleaf', value: 'Cloverleaf' },
  { label: 'Cloverly', value: 'Cloverly' },
  { label: 'Cloverport', value: 'Cloverport' },
  { label: 'Clovis', value: 'Clovis' },
  { label: 'Clovis', value: 'Clovis' },
  { label: 'Clowne', value: 'Clowne' },
  { label: 'Cloyes-Sur-Le-Loir', value: 'Cloyes-Sur-Le-Loir' },
  { label: 'Cloyne', value: 'Cloyne' },
  { label: 'Cloz', value: 'Cloz' },
  { label: 'Cluain Meala', value: 'Cluain Meala' },
  { label: 'Cluis', value: 'Cluis' },
  { label: 'Cluj-Napoca', value: 'Cluj-Napoca' },
  { label: 'Clunes', value: 'Clunes' },
  { label: 'Cluny', value: 'Cluny' },
  { label: 'Cluny', value: 'Cluny' },
  { label: 'Clusane', value: 'Clusane' },
  { label: 'Cluses', value: 'Cluses' },
  { label: 'Clusone', value: 'Clusone' },
  { label: 'Clute', value: 'Clute' },
  { label: 'Clutha District', value: 'Clutha District' },
  { label: 'Clutton', value: 'Clutton' },
  { label: 'Clydach', value: 'Clydach' },
  { label: 'Clyde', value: 'Clyde' },
  { label: 'Clyde', value: 'Clyde' },
  { label: 'Clyde', value: 'Clyde' },
  { label: 'Clyde', value: 'Clyde' },
  { label: 'Clyde', value: 'Clyde' },
  { label: 'Clyde Hill', value: 'Clyde Hill' },
  { label: 'Clyde North', value: 'Clyde North' },
  { label: 'Clyde River', value: 'Clyde River' },
  { label: 'Clydebank', value: 'Clydebank' },
  { label: 'Clymer', value: 'Clymer' },
  { label: 'Clymer', value: 'Clymer' },
  { label: 'Ćmielów', value: 'Ćmielów' },
  { label: 'Cmolas', value: 'Cmolas' },
  { label: 'Cờ Đỏ', value: 'Cờ Đỏ' },
  { label: 'Coacalco', value: 'Coacalco' },
  { label: 'Coachella', value: 'Coachella' },
  { label: 'Coachimalco', value: 'Coachimalco' },
  { label: 'Coachiti', value: 'Coachiti' },
  { label: 'Coacoatzintla', value: 'Coacoatzintla' },
  { label: 'Coacotla', value: 'Coacotla' },
  { label: 'Coacoyula De Álvarez', value: 'Coacoyula De Álvarez' },
  { label: 'Coacoyulichán', value: 'Coacoyulichán' },
  { label: 'Coacoyulillo', value: 'Coacoyulillo' },
  { label: 'Coacuilco', value: 'Coacuilco' },
  { label: 'Coada Izvorului', value: 'Coada Izvorului' },
  { label: 'Coahoma County', value: 'Coahoma County' },
  { label: 'Coahuayana De Hidalgo', value: 'Coahuayana De Hidalgo' },
  { label: 'Coahuayana Viejo', value: 'Coahuayana Viejo' },
  { label: 'Coahuayutla De Guerrero', value: 'Coahuayutla De Guerrero' },
  { label: 'Coahuayutla De José María Izazaga', value: 'Coahuayutla De José María Izazaga' },
  { label: 'Coahuitlán', value: 'Coahuitlán' },
  { label: 'Coahuixco', value: 'Coahuixco' },
  { label: 'Coahuixtla', value: 'Coahuixtla' },
  { label: 'Coajomulco', value: 'Coajomulco' },
  { label: 'Coal City', value: 'Coal City' },
  { label: 'Coal City', value: 'Coal City' },
  { label: 'Coal County', value: 'Coal County' },
  { label: 'Coal Creek', value: 'Coal Creek' },
  { label: 'Coal Fork', value: 'Coal Fork' },
  { label: 'Coal Grove', value: 'Coal Grove' },
  { label: 'Coal Hill', value: 'Coal Hill' },
  { label: 'Coal Point', value: 'Coal Point' },
  { label: 'Coal Run Village', value: 'Coal Run Village' },
  { label: 'Coal Valley', value: 'Coal Valley' },
  { label: 'Coalburn', value: 'Coalburn' },
  { label: 'Coalcomán De Vázquez Pallares', value: 'Coalcomán De Vázquez Pallares' },
  { label: 'Coaldale', value: 'Coaldale' },
  { label: 'Coaldale', value: 'Coaldale' },
  { label: 'Coalfield', value: 'Coalfield' },
  { label: 'Coalgate', value: 'Coalgate' },
  { label: 'Coalhurst', value: 'Coalhurst' },
  { label: 'Coaling', value: 'Coaling' },
  { label: 'Coalinga', value: 'Coalinga' },
  { label: 'Coalisland', value: 'Coalisland' },
  { label: 'Coaltown Of Balgonie', value: 'Coaltown Of Balgonie' },
  { label: 'Coalville', value: 'Coalville' },
  { label: 'Coalville', value: 'Coalville' },
  { label: 'Coamilpa De Juárez', value: 'Coamilpa De Juárez' },
  { label: 'Coamo', value: 'Coamo' },
  { label: 'Coamo', value: 'Coamo' },
  { label: 'Coamostita', value: 'Coamostita' },
  { label: 'Coaña', value: 'Coaña' },
  { label: 'Coapilla', value: 'Coapilla' },
  { label: 'Coaraci', value: 'Coaraci' },
  { label: 'Coari', value: 'Coari' },
  { label: 'Coarnele Caprei', value: 'Coarnele Caprei' },
  { label: 'Coarraze', value: 'Coarraze' },
  { label: 'Coarsegold', value: 'Coarsegold' },
  { label: 'Coaș', value: 'Coaș' },
  { label: 'Coassolo', value: 'Coassolo' },
  { label: 'Coassolo Torinese', value: 'Coassolo Torinese' },
  { label: 'Coasta Grindului', value: 'Coasta Grindului' },
  { label: 'Coasta Mare', value: 'Coasta Mare' },
  { label: 'Coatbridge', value: 'Coatbridge' },
  { label: 'Coatecas Altas', value: 'Coatecas Altas' },
  { label: 'Coatepec', value: 'Coatepec' },
  { label: 'Coatepec', value: 'Coatepec' },
  { label: 'Coatepec', value: 'Coatepec' },
  { label: 'Coatepec Costales', value: 'Coatepec Costales' },
  { label: 'Coatepec Harinas', value: 'Coatepec Harinas' },
  { label: 'Coatepeque', value: 'Coatepeque' },
  { label: 'Coatepeque', value: 'Coatepeque' },
  { label: 'Coates', value: 'Coates' },
  { label: 'Coatesville', value: 'Coatesville' },
  { label: 'Coatetelco', value: 'Coatetelco' },
  { label: 'Coaticook', value: 'Coaticook' },
  { label: 'Coatlán Del Río', value: 'Coatlán Del Río' },
  { label: 'Coatlancillo', value: 'Coatlancillo' },
  { label: 'Coats', value: 'Coats' },
  { label: 'Coatzacoalcos', value: 'Coatzacoalcos' },
  { label: 'Coatzingo', value: 'Coatzingo' },
  { label: 'Coatzintla', value: 'Coatzintla' },
  { label: 'Coazze', value: 'Coazze' },
  { label: 'Coazzolo', value: 'Coazzolo' },
  { label: 'Coba', value: 'Coba' },
  { label: 'Cobadin', value: 'Cobadin' },
  { label: 'Cobán', value: 'Cobán' },
  { label: 'Çobanlar', value: 'Çobanlar' },
  { label: 'Çobansığnaq', value: 'Çobansığnaq' },
  { label: 'Cobar', value: 'Cobar' },
  { label: 'Cobatillas', value: 'Cobatillas' },
  { label: 'Cobb', value: 'Cobb' },
  { label: 'Cobb County', value: 'Cobb County' },
  { label: 'Cobb Island', value: 'Cobb Island' },
  { label: 'Cobbitty', value: 'Cobbitty' },
  { label: 'Cobbla', value: 'Cobbla' },
  { label: 'Cóbdar', value: 'Cóbdar' },
  { label: 'Cobden', value: 'Cobden' },
  { label: 'Cobden', value: 'Cobden' },
  { label: 'Cobeja', value: 'Cobeja' },
  { label: 'Cobeña', value: 'Cobeña' },
  { label: 'Cobeta', value: 'Cobeta' },
  { label: 'Cobh', value: 'Cobh' },
  { label: 'Cobham', value: 'Cobham' },
  { label: 'Cobija', value: 'Cobija' },
  { label: 'Cobisa', value: 'Cobisa' },
  { label: 'Cobleskill', value: 'Cobleskill' },
  { label: 'Cobos De Cerrato', value: 'Cobos De Cerrato' },
  { label: 'Cobos De Fuentidueña', value: 'Cobos De Fuentidueña' },
  { label: 'Cobourg', value: 'Cobourg' },
  { label: 'Cobquecura', value: 'Cobquecura' },
  { label: 'Cobram', value: 'Cobram' },
  { label: 'Cobreros', value: 'Cobreros' },
  { label: 'Coburg', value: 'Coburg' },
  { label: 'Coburg', value: 'Coburg' },
  { label: 'Coburg', value: 'Coburg' },
  { label: 'Coburg North', value: 'Coburg North' },
  { label: 'Coca', value: 'Coca' },
  { label: 'Coca De Alba', value: 'Coca De Alba' },
  { label: 'Cocachacra', value: 'Cocachacra' },
  { label: 'Cocal', value: 'Cocal' },
  { label: 'Cocal', value: 'Cocal' },
  { label: 'Cocal De Telha', value: 'Cocal De Telha' },
  { label: 'Cocal Do Sul', value: 'Cocal Do Sul' },
  { label: 'Cocal Dos Alves', value: 'Cocal Dos Alves' },
  { label: 'Cocalinho', value: 'Cocalinho' },
  { label: 'Cocalzinho De Goiás', value: 'Cocalzinho De Goiás' },
  { label: 'Coccaglio', value: 'Coccaglio' },
  { label: 'Coccanile-Cesta', value: 'Coccanile-Cesta' },
  { label: 'Cocconato', value: 'Cocconato' },
  { label: 'Cocentaina', value: 'Cocentaina' },
  { label: 'Cochabamba', value: 'Cochabamba' },
  { label: 'Cochamó', value: 'Cochamó' },
  { label: 'Cochea Abajo', value: 'Cochea Abajo' },
  { label: 'Cochem', value: 'Cochem' },
  { label: 'Cocheren', value: 'Cocheren' },
  { label: 'Cochin', value: 'Cochin' },
  { label: 'Cochirleanca', value: 'Cochirleanca' },
  { label: 'Cochise County', value: 'Cochise County' },
  { label: 'Cochituate', value: 'Cochituate' },
  { label: 'Cochoapa', value: 'Cochoapa' },
  { label: 'Cochoapa El Grande', value: 'Cochoapa El Grande' },
  { label: 'Cochran', value: 'Cochran' },
  { label: 'Cochran County', value: 'Cochran County' },
  { label: 'Cochrane', value: 'Cochrane' },
  { label: 'Cochrane', value: 'Cochrane' },
  { label: 'Cochrane District', value: 'Cochrane District' },
  { label: 'Cochranton', value: 'Cochranton' },
  { label: 'Cochstedt', value: 'Cochstedt' },
  { label: 'Cocieri', value: 'Cocieri' },
  { label: 'Cociu', value: 'Cociu' },
  { label: 'Cociuba Mare', value: 'Cociuba Mare' },
  { label: 'Cockatoo', value: 'Cockatoo' },
  { label: 'Cockburn Central', value: 'Cockburn Central' },
  { label: 'Cockburn Gardens', value: 'Cockburn Gardens' },
  { label: 'Cockburn Town', value: 'Cockburn Town' },
  { label: 'Cocke County', value: 'Cocke County' },
  { label: 'Cockenzie', value: 'Cockenzie' },
  { label: 'Cockermouth', value: 'Cockermouth' },
  { label: 'Cockeysville', value: 'Cockeysville' },
  { label: 'Cockfield', value: 'Cockfield' },
  { label: 'Cockington', value: 'Cockington' },
  { label: 'Cockpit', value: 'Cockpit' },
  { label: 'Cockrell Hill', value: 'Cockrell Hill' },
  { label: 'Coclé', value: 'Coclé' },
  { label: 'Coclé Del Norte', value: 'Coclé Del Norte' },
  { label: 'Coco', value: 'Coco' },
  { label: 'Cocoa', value: 'Cocoa' },
  { label: 'Cocoa Beach', value: 'Cocoa Beach' },
  { label: 'Cocoa Dan', value: 'Cocoa Dan' },
  { label: 'Cocoa West', value: 'Cocoa West' },
  { label: 'Cocobeach', value: 'Cocobeach' },
  { label: 'Cocohital', value: 'Cocohital' },
  { label: 'Coconi', value: 'Coconi' },
  { label: 'Coconino County', value: 'Coconino County' },
  { label: 'Coconut Creek', value: 'Coconut Creek' },
  { label: 'Coconut Grove', value: 'Coconut Grove' },
  { label: 'Coconut Grove', value: 'Coconut Grove' },
  { label: 'Cocora', value: 'Cocora' },
  { label: 'Cocorăștii Colț', value: 'Cocorăștii Colț' },
  { label: 'Cocorăștii Mislii', value: 'Cocorăștii Mislii' },
  { label: 'Cocoreni', value: 'Cocoreni' },
  { label: 'Cocorit', value: 'Cocorit' },
  { label: 'Cocorná', value: 'Cocorná' },
  { label: 'Cocos', value: 'Cocos' },
  { label: 'Cocotitlán', value: 'Cocotitlán' },
  { label: 'Cocoyoc', value: 'Cocoyoc' },
  { label: 'Cocoyol', value: 'Cocoyol' },
  { label: 'Cocoyotla', value: 'Cocoyotla' },
  { label: 'Cocquio', value: 'Cocquio' },
  { label: 'Cocu', value: 'Cocu' },
  { label: 'Cocuasco', value: 'Cocuasco' },
  { label: 'Cocucho', value: 'Cocucho' },
  { label: 'Cocula', value: 'Cocula' },
  { label: 'Cocula Jalisco', value: 'Cocula Jalisco' },
  { label: 'Cocullo', value: 'Cocullo' },
  { label: 'Codăeşti', value: 'Codăeşti' },
  { label: 'Codajás', value: 'Codajás' },
  { label: 'Codaruina', value: 'Codaruina' },
  { label: 'Codcod', value: 'Codcod' },
  { label: 'Codegua', value: 'Codegua' },
  { label: 'Codemondo-Quaresimo', value: 'Codemondo-Quaresimo' },
  { label: 'Codesseiro', value: 'Codesseiro' },
  { label: 'Codevigo', value: 'Codevigo' },
  { label: 'Codevilla', value: 'Codevilla' },
  { label: 'Codicote', value: 'Codicote' },
  { label: 'Codigoro', value: 'Codigoro' },
  { label: 'Codington County', value: 'Codington County' },
  { label: 'Codisotto', value: 'Codisotto' },
  { label: 'Codiverno', value: 'Codiverno' },
  { label: 'Codlea', value: 'Codlea' },
  { label: 'Codo', value: 'Codo' },
  { label: 'Codó', value: 'Codó' },
  { label: 'Codogna-Cardano', value: 'Codogna-Cardano' },
  { label: 'Codognan', value: 'Codognan' },
  { label: 'Codognè', value: 'Codognè' },
  { label: 'Codogno', value: 'Codogno' },
  { label: 'Codoñera La', value: 'Codoñera La' },
  { label: 'Codorniz', value: 'Codorniz' },
  { label: 'Codorniz', value: 'Codorniz' },
  { label: 'Codos', value: 'Codos' },
  { label: 'Codosera La', value: 'Codosera La' },
  { label: 'Codrington', value: 'Codrington' },
  { label: 'Codroipo', value: 'Codroipo' },
  { label: 'Codrongianos', value: 'Codrongianos' },
  { label: 'Codru Butesii', value: 'Codru Butesii' },
  { label: 'Codsall', value: 'Codsall' },
  { label: 'Cody', value: 'Cody' },
  { label: 'Coeburn', value: 'Coeburn' },
  { label: 'Coedpoeth', value: 'Coedpoeth' },
  { label: 'Coelemu', value: 'Coelemu' },
  { label: 'Coelho Neto', value: 'Coelho Neto' },
  { label: 'Coello', value: 'Coello' },
  { label: 'Coendersborg', value: 'Coendersborg' },
  { label: 'Coeneo De La Libertad', value: 'Coeneo De La Libertad' },
  { label: 'Coes Creek', value: 'Coes Creek' },
  { label: 'Coesfeld', value: 'Coesfeld' },
  { label: 'Coësmes', value: 'Coësmes' },
  { label: 'Coëtmieux', value: 'Coëtmieux' },
  { label: 'Coetzala', value: 'Coetzala' },
  { label: 'Coeur Dalene', value: 'Coeur Dalene' },
  { label: 'Coevering', value: 'Coevering' },
  { label: 'Coevorden', value: 'Coevorden' },
  { label: 'Coëx', value: 'Coëx' },
  { label: 'Coffee County', value: 'Coffee County' },
  { label: 'Coffee County', value: 'Coffee County' },
  { label: 'Coffee County', value: 'Coffee County' },
  { label: 'Coffee Piece', value: 'Coffee Piece' },
  { label: 'Coffey County', value: 'Coffey County' },
  { label: 'Coffeyville', value: 'Coffeyville' },
  { label: 'Coffs Harbour', value: 'Coffs Harbour' },
  { label: 'Cofradía', value: 'Cofradía' },
  { label: 'Cofradía', value: 'Cofradía' },
  { label: 'Cofradía', value: 'Cofradía' },
  { label: 'Cofradía', value: 'Cofradía' },
  { label: 'Cofradía De Juárez', value: 'Cofradía De Juárez' },
  { label: 'Cofradía De Morelos', value: 'Cofradía De Morelos' },
  { label: 'Cofradía De Navolato (Cofradía De Los Rocha)', value: 'Cofradía De Navolato (Cofradía De Los Rocha)' },
  { label: 'Cofradía De Suchitlán', value: 'Cofradía De Suchitlán' },
  { label: 'Cofradía Santa Cruz De Camotlán', value: 'Cofradía Santa Cruz De Camotlán' },
  { label: 'Cofrentes', value: 'Cofrentes' },
  { label: 'Cogan', value: 'Cogan' },
  { label: 'Cogealac', value: 'Cogealac' },
  { label: 'Cogeasca', value: 'Cogeasca' },
  { label: 'Cogeces De Íscar', value: 'Cogeces De Íscar' },
  { label: 'Cogeces Del Monte', value: 'Cogeces Del Monte' },
  { label: 'Cogenhoe', value: 'Cogenhoe' },
  { label: 'Coggeshall', value: 'Coggeshall' },
  { label: 'Coggiola', value: 'Coggiola' },
  { label: 'Cogliate', value: 'Cogliate' },
  { label: 'Cognac', value: 'Cognac' },
  { label: 'Cognac-La-Forêt', value: 'Cognac-La-Forêt' },
  { label: 'Cogne', value: 'Cogne' },
  { label: 'Cognin', value: 'Cognin' },
  { label: 'Cogny', value: 'Cogny' },
  { label: 'Cogo', value: 'Cogo' },
  { label: 'Cogoleto', value: 'Cogoleto' },
  { label: 'Cogolin', value: 'Cogolin' },
  { label: 'Cogollo Del Cengio', value: 'Cogollo Del Cengio' },
  { label: 'Cogollor', value: 'Cogollor' },
  { label: 'Cogollos', value: 'Cogollos' },
  { label: 'Cogollos De Guadix', value: 'Cogollos De Guadix' },
  { label: 'Cogollos De La Vega', value: 'Cogollos De La Vega' },
  { label: 'Cogolludo', value: 'Cogolludo' },
  { label: 'Cogolo', value: 'Cogolo' },
  { label: 'Cogon', value: 'Cogon' },
  { label: 'Cogon', value: 'Cogon' },
  { label: 'Cogon', value: 'Cogon' },
  { label: 'Cogon', value: 'Cogon' },
  { label: 'Cogon', value: 'Cogon' },
  { label: 'Cogon Cruz', value: 'Cogon Cruz' },
  { label: 'Cogorno', value: 'Cogorno' },
  { label: 'Cogtong', value: 'Cogtong' },
  { label: 'Cogua', value: 'Cogua' },
  { label: 'Cogul El', value: 'Cogul El' },
  { label: 'Cogula', value: 'Cogula' },
  { label: 'Cohasset', value: 'Cohasset' },
  { label: 'Cohasset', value: 'Cohasset' },
  { label: 'Cohoe', value: 'Cohoe' },
  { label: 'Cohoes', value: 'Cohoes' },
  { label: 'Cohuecán', value: 'Cohuecán' },
  { label: 'Cohuna', value: 'Cohuna' },
  { label: 'Coignières', value: 'Coignières' },
  { label: 'Coihueco', value: 'Coihueco' },
  { label: 'Coimbatore', value: 'Coimbatore' },
  { label: 'Coimbra', value: 'Coimbra' },
  { label: 'Coimbra', value: 'Coimbra' },
  { label: 'Coín', value: 'Coín' },
  { label: 'Coínco', value: 'Coínco' },
  { label: 'Coincy', value: 'Coincy' },
  { label: 'Coirós', value: 'Coirós' },
  { label: 'Coise', value: 'Coise' },
  { label: 'Coishco', value: 'Coishco' },
  { label: 'Coité Do Nóia', value: 'Coité Do Nóia' },
  { label: 'Coity', value: 'Coity' },
  { label: 'Coivaras', value: 'Coivaras' },
  { label: 'Coixtlahuaca', value: 'Coixtlahuaca' },
  { label: 'Cojasca', value: 'Cojasca' },
  { label: 'Cojocna', value: 'Cojocna' },
  { label: 'Cojumatlán De Régules', value: 'Cojumatlán De Régules' },
  { label: 'Cojutepeque', value: 'Cojutepeque' },
  { label: 'Čoka', value: 'Čoka' },
  { label: 'Cokato', value: 'Cokato' },
  { label: 'Coke County', value: 'Coke County' },
  { label: 'Čokešina', value: 'Čokešina' },
  { label: 'Col San Martino', value: 'Col San Martino' },
  { label: 'Col. Bosques De Las Lomas', value: 'Col. Bosques De Las Lomas' },
  { label: 'Colà', value: 'Colà' },
  { label: 'Colaba', value: 'Colaba' },
  { label: 'Colac', value: 'Colac' },
  { label: 'Colachel', value: 'Colachel' },
  { label: 'Colac-Otway', value: 'Colac-Otway' },
  { label: 'Colacu', value: 'Colacu' },
  { label: 'Colacu', value: 'Colacu' },
  { label: 'Colares', value: 'Colares' },
  { label: 'Colares', value: 'Colares' },
  { label: 'Colares', value: 'Colares' },
  { label: 'Colatina', value: 'Colatina' },
  { label: 'Colatlán', value: 'Colatlán' },
  { label: 'Colayrac-Saint-Cirq', value: 'Colayrac-Saint-Cirq' },
  { label: 'Colazza', value: 'Colazza' },
  { label: 'Cölbe', value: 'Cölbe' },
  { label: 'Colbert', value: 'Colbert' },
  { label: 'Colbert County', value: 'Colbert County' },
  { label: 'Colbitz', value: 'Colbitz' },
  { label: 'Colbordolo', value: 'Colbordolo' },
  { label: 'Colbún', value: 'Colbún' },
  { label: 'Colby', value: 'Colby' },
  { label: 'Colby', value: 'Colby' },
  { label: 'Colceag', value: 'Colceag' },
  { label: 'Colchane', value: 'Colchane' },
  { label: 'Colchani', value: 'Colchani' },
  { label: 'Colchani', value: 'Colchani' },
  { label: 'Colchester', value: 'Colchester' },
  { label: 'Colchester', value: 'Colchester' },
  { label: 'Colchester', value: 'Colchester' },
  { label: 'Colchester', value: 'Colchester' },
  { label: 'Colchester', value: 'Colchester' },
  { label: 'Cold Ash', value: 'Cold Ash' },
  { label: 'Cold Lake', value: 'Cold Lake' },
  { label: 'Cold Norton', value: 'Cold Norton' },
  { label: 'Cold Spring', value: 'Cold Spring' },
  { label: 'Cold Spring', value: 'Cold Spring' },
  { label: 'Cold Spring', value: 'Cold Spring' },
  { label: 'Cold Spring Harbor', value: 'Cold Spring Harbor' },
  { label: 'Cold Springs', value: 'Cold Springs' },
  { label: 'Colden Common', value: 'Colden Common' },
  { label: 'Colditz', value: 'Colditz' },
  { label: 'Coldragone', value: 'Coldragone' },
  { label: 'Coldspring', value: 'Coldspring' },
  { label: 'Coldstream', value: 'Coldstream' },
  { label: 'Coldstream', value: 'Coldstream' },
  { label: 'Coldstream', value: 'Coldstream' },
  { label: 'Coldwater', value: 'Coldwater' },
  { label: 'Coldwater', value: 'Coldwater' },
  { label: 'Coldwater', value: 'Coldwater' },
  { label: 'Coldwater', value: 'Coldwater' },
  { label: 'Cole Camp', value: 'Cole Camp' },
  { label: 'Cole County', value: 'Cole County' },
  { label: 'Cole Gate', value: 'Cole Gate' },
  { label: 'Cole Harbour', value: 'Cole Harbour' },
  { label: 'Coleambally', value: 'Coleambally' },
  { label: 'Colebee', value: 'Colebee' },
  { label: 'Colebrook', value: 'Colebrook' },
  { label: 'Coledale', value: 'Coledale' },
  { label: 'Coleford', value: 'Coleford' },
  { label: 'Colegiales', value: 'Colegiales' },
  { label: 'Colelia', value: 'Colelia' },
  { label: 'Coleman', value: 'Coleman' },
  { label: 'Coleman', value: 'Coleman' },
  { label: 'Coleman County', value: 'Coleman County' },
  { label: 'Colera', value: 'Colera' },
  { label: 'Coleraine', value: 'Coleraine' },
  { label: 'Coleraine', value: 'Coleraine' },
  { label: 'Colere', value: 'Colere' },
  { label: 'Colerne', value: 'Colerne' },
  { label: 'Coles', value: 'Coles' },
  { label: 'Coles County', value: 'Coles County' },
  { label: 'Colesberg', value: 'Colesberg' },
  { label: 'Colesville', value: 'Colesville' },
  { label: 'Coleyville', value: 'Coleyville' },
  { label: 'Colfax', value: 'Colfax' },
  { label: 'Colfax', value: 'Colfax' },
  { label: 'Colfax', value: 'Colfax' },
  { label: 'Colfax', value: 'Colfax' },
  { label: 'Colfax', value: 'Colfax' },
  { label: 'Colfax', value: 'Colfax' },
  { label: 'Colfax County', value: 'Colfax County' },
  { label: 'Colfax County', value: 'Colfax County' },
  { label: 'Colfontaine', value: 'Colfontaine' },
  { label: 'Colgong', value: 'Colgong' },
  { label: 'Coli', value: 'Coli' },
  { label: 'Colibaşi', value: 'Colibaşi' },
  { label: 'Colibași', value: 'Colibași' },
  { label: 'Colico Piano', value: 'Colico Piano' },
  { label: 'Colíder', value: 'Colíder' },
  { label: 'Coligny', value: 'Coligny' },
  { label: 'Colihaut', value: 'Colihaut' },
  { label: 'Colijnsplaat', value: 'Colijnsplaat' },
  { label: 'Colilla La', value: 'Colilla La' },
  { label: 'Colima', value: 'Colima' },
  { label: 'Colimes', value: 'Colimes' },
  { label: 'Colina', value: 'Colina' },
  { label: 'Colina', value: 'Colina' },
  { label: 'Colinas', value: 'Colinas' },
  { label: 'Colinas', value: 'Colinas' },
  { label: 'Colinas De Plata', value: 'Colinas De Plata' },
  { label: 'Colinas De Santa Cruz Segunda Sección', value: 'Colinas De Santa Cruz Segunda Sección' },
  { label: 'Colinas Del Aeropuerto', value: 'Colinas Del Aeropuerto' },
  { label: 'Colinas Del Río Juárez', value: 'Colinas Del Río Juárez' },
  { label: 'Colinas Del Roble', value: 'Colinas Del Roble' },
  { label: 'Colinas Del Sol', value: 'Colinas Del Sol' },
  { label: 'Colinas Del Valle', value: 'Colinas Del Valle' },
  { label: 'Colinas Do Sul', value: 'Colinas Do Sul' },
  { label: 'Colinas Do Tocantins', value: 'Colinas Do Tocantins' },
  { label: 'Colindres', value: 'Colindres' },
  { label: 'Colinton', value: 'Colinton' },
  { label: 'Colipa', value: 'Colipa' },
  { label: 'Colipapa', value: 'Colipapa' },
  { label: 'Coll De Nargó', value: 'Coll De Nargó' },
  { label: 'Coll Den Rabassa', value: 'Coll Den Rabassa' },
  { label: 'Collado', value: 'Collado' },
  { label: 'Collado De Contreras', value: 'Collado De Contreras' },
  { label: 'Collado Del Mirón', value: 'Collado Del Mirón' },
  { label: 'Collado Hermoso', value: 'Collado Hermoso' },
  { label: 'Collado Mediano', value: 'Collado Mediano' },
  { label: 'Collado Villalba', value: 'Collado Villalba' },
  { label: 'Collado-Villalba', value: 'Collado-Villalba' },
  { label: 'Collagna', value: 'Collagna' },
  { label: 'Collalbo', value: 'Collalbo' },
  { label: 'Collalto Sabino', value: 'Collalto Sabino' },
  { label: 'Colla-Muggiasca', value: 'Colla-Muggiasca' },
  { label: 'Collantes', value: 'Collantes' },
  { label: 'Collarmele', value: 'Collarmele' },
  { label: 'Collaroy', value: 'Collaroy' },
  { label: 'Collaroy Plateau', value: 'Collaroy Plateau' },
  { label: 'Collazos De Boedo', value: 'Collazos De Boedo' },
  { label: 'Collazzone', value: 'Collazzone' },
  { label: 'Collbató', value: 'Collbató' },
  { label: 'Colldejou', value: 'Colldejou' },
  { label: 'Colle Brianza', value: 'Colle Brianza' },
  { label: 'Colle Campano-Scrima', value: 'Colle Campano-Scrima' },
  { label: 'Colle Danchise', value: 'Colle Danchise' },
  { label: 'Colle Del Pino', value: 'Colle Del Pino' },
  { label: 'Colle Di Fuori', value: 'Colle Di Fuori' },
  { label: 'Colle Di Tora', value: 'Colle Di Tora' },
  { label: 'Colle Di Val Delsa', value: 'Colle Di Val Delsa' },
  { label: 'Colle Isarco', value: 'Colle Isarco' },
  { label: 'Colle Mainello', value: 'Colle Mainello' },
  { label: 'Colle San Magno', value: 'Colle San Magno' },
  { label: 'Colle Sannita', value: 'Colle Sannita' },
  { label: 'Colle Santa Lucia', value: 'Colle Santa Lucia' },
  { label: 'Colle Spina', value: 'Colle Spina' },
  { label: 'Colle Umberto', value: 'Colle Umberto' },
  { label: 'Colle Verde', value: 'Colle Verde' },
  { label: 'Collebeato', value: 'Collebeato' },
  { label: 'Collecchio', value: 'Collecchio' },
  { label: 'Collecorvino', value: 'Collecorvino' },
  { label: 'Colledara', value: 'Colledara' },
  { label: 'Colledimacine', value: 'Colledimacine' },
  { label: 'Colledimezzo', value: 'Colledimezzo' },
  { label: 'Colleferro', value: 'Colleferro' },
  { label: 'Collefontana-Fontana Liri Inferiore', value: 'Collefontana-Fontana Liri Inferiore' },
  { label: 'College', value: 'College' },
  { label: 'College Grove', value: 'College Grove' },
  { label: 'College Park', value: 'College Park' },
  { label: 'College Park', value: 'College Park' },
  { label: 'College Place', value: 'College Place' },
  { label: 'College Point', value: 'College Point' },
  { label: 'College Station', value: 'College Station' },
  { label: 'Collegedale', value: 'Collegedale' },
  { label: 'Collegeville', value: 'Collegeville' },
  { label: 'Collegeville', value: 'Collegeville' },
  { label: 'Collégien', value: 'Collégien' },
  { label: 'Collegiove', value: 'Collegiove' },
  { label: 'Collegno', value: 'Collegno' },
  { label: 'Collelongo', value: 'Collelongo' },
  { label: 'Collemeto', value: 'Collemeto' },
  { label: 'Collepardo', value: 'Collepardo' },
  { label: 'Collepasso', value: 'Collepasso' },
  { label: 'Collepepe', value: 'Collepepe' },
  { label: 'Collepietro', value: 'Collepietro' },
  { label: 'Colleranesco', value: 'Colleranesco' },
  { label: 'Colleret', value: 'Colleret' },
  { label: 'Colleretto Castelnuovo', value: 'Colleretto Castelnuovo' },
  { label: 'Colleretto Giacosa', value: 'Colleretto Giacosa' },
  { label: 'Collesalvetti', value: 'Collesalvetti' },
  { label: 'Collesano', value: 'Collesano' },
  { label: 'Colleton County', value: 'Colleton County' },
  { label: 'Colletorto', value: 'Colletorto' },
  { label: 'Collevecchio', value: 'Collevecchio' },
  { label: 'Colleverde Ii', value: 'Colleverde Ii' },
  { label: 'Colleville-Montgomery', value: 'Colleville-Montgomery' },
  { label: 'Colleyville', value: 'Colleyville' },
  { label: 'Colli A Volturno', value: 'Colli A Volturno' },
  { label: 'Colli Del Tronto', value: 'Colli Del Tronto' },
  { label: 'Colli Di Enea', value: 'Colli Di Enea' },
  { label: 'Colli Sul Velino', value: 'Colli Sul Velino' },
  { label: 'Colliano', value: 'Colliano' },
  { label: 'Collie', value: 'Collie' },
  { label: 'Collier County', value: 'Collier County' },
  { label: 'Collier Row', value: 'Collier Row' },
  { label: 'Collierville', value: 'Collierville' },
  { label: 'Collierville', value: 'Collierville' },
  { label: 'Collin County', value: 'Collin County' },
  { label: 'Collinas', value: 'Collinas' },
  { label: 'Collingbourne Kingston', value: 'Collingbourne Kingston' },
  { label: 'Collingdale', value: 'Collingdale' },
  { label: 'Collingham', value: 'Collingham' },
  { label: 'Collings Lakes', value: 'Collings Lakes' },
  { label: 'Collingswood', value: 'Collingswood' },
  { label: 'Collingsworth County', value: 'Collingsworth County' },
  { label: 'Collingwood', value: 'Collingwood' },
  { label: 'Collingwood', value: 'Collingwood' },
  { label: 'Collingwood Park', value: 'Collingwood Park' },
  { label: 'Collins', value: 'Collins' },
  { label: 'Collinsburg', value: 'Collinsburg' },
  { label: 'Collinsville', value: 'Collinsville' },
  { label: 'Collinsville', value: 'Collinsville' },
  { label: 'Collinsville', value: 'Collinsville' },
  { label: 'Collinsville', value: 'Collinsville' },
  { label: 'Collinsville', value: 'Collinsville' },
  { label: 'Collinsville', value: 'Collinsville' },
  { label: 'Collinsville', value: 'Collinsville' },
  { label: 'Collinsville', value: 'Collinsville' },
  { label: 'Collinswood', value: 'Collinswood' },
  { label: 'Collinwood', value: 'Collinwood' },
  { label: 'Collio', value: 'Collio' },
  { label: 'Collioure', value: 'Collioure' },
  { label: 'Collipulli', value: 'Collipulli' },
  { label: 'Collobiano', value: 'Collobiano' },
  { label: 'Collobrières', value: 'Collobrières' },
  { label: 'Collombey', value: 'Collombey' },
  { label: 'Collonges', value: 'Collonges' },
  { label: 'Collonges-Sous-Salève', value: 'Collonges-Sous-Salève' },
  { label: 'Collooney', value: 'Collooney' },
  { label: 'Colloredo Di Monte Albano', value: 'Colloredo Di Monte Albano' },
  { label: 'Colloredo Di Monte Albano-Lauzzana', value: 'Colloredo Di Monte Albano-Lauzzana' },
  { label: 'Colloredo Di Prato', value: 'Colloredo Di Prato' },
  { label: 'Collsuspina', value: 'Collsuspina' },
  { label: 'Colma', value: 'Colma' },
  { label: 'Colmar', value: 'Colmar' },
  { label: 'Colmar', value: 'Colmar' },
  { label: 'Colmar Manor', value: 'Colmar Manor' },
  { label: 'Colmberg', value: 'Colmberg' },
  { label: 'Colméia', value: 'Colméia' },
  { label: 'Colmenar', value: 'Colmenar' },
  { label: 'Colmenar De Montemayor', value: 'Colmenar De Montemayor' },
  { label: 'Colmenar De Oreja', value: 'Colmenar De Oreja' },
  { label: 'Colmenar Del Arroyo', value: 'Colmenar Del Arroyo' },
  { label: 'Colmenar Viejo', value: 'Colmenar Viejo' },
  { label: 'Colmenarejo', value: 'Colmenarejo' },
  { label: 'Colmurano', value: 'Colmurano' },
  { label: 'Colnbrook', value: 'Colnbrook' },
  { label: 'Colne', value: 'Colne' },
  { label: 'Colniza', value: 'Colniza' },
  { label: 'Colo Vale', value: 'Colo Vale' },
  { label: 'Colobraro', value: 'Colobraro' },
  { label: 'Cologna Spiaggia', value: 'Cologna Spiaggia' },
  { label: 'Cologna Veneta', value: 'Cologna Veneta' },
  { label: 'Cologna-Caraverio', value: 'Cologna-Caraverio' },
  { label: 'Cologne', value: 'Cologne' },
  { label: 'Cologne', value: 'Cologne' },
  { label: 'Cologno Al Serio', value: 'Cologno Al Serio' },
  { label: 'Cologno Monzese', value: 'Cologno Monzese' },
  { label: 'Colognola Ai Colli', value: 'Colognola Ai Colli' },
  { label: 'Cololaca', value: 'Cololaca' },
  { label: 'Coloma', value: 'Coloma' },
  { label: 'Colomadu', value: 'Colomadu' },
  { label: 'Colomars', value: 'Colomars' },
  { label: 'Colomba', value: 'Colomba' },
  { label: 'Colombaro', value: 'Colombaro' },
  { label: 'Colombaro-Timoline', value: 'Colombaro-Timoline' },
  { label: 'Colombe', value: 'Colombe' },
  { label: 'Colombella', value: 'Colombella' },
  { label: 'Colombelles', value: 'Colombelles' },
  { label: 'Colombes', value: 'Colombes' },
  { label: 'Colombette', value: 'Colombette' },
  { label: 'Colombey-Les-Belles', value: 'Colombey-Les-Belles' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Colômbia', value: 'Colômbia' },
  { label: 'Colombia De Guadalupe', value: 'Colombia De Guadalupe' },
  { label: 'Colombier', value: 'Colombier' },
  { label: 'Colombiera-Molicciara', value: 'Colombiera-Molicciara' },
  { label: 'Colombier-Fontaine', value: 'Colombier-Fontaine' },
  { label: 'Colombiers', value: 'Colombiers' },
  { label: 'Colombiers', value: 'Colombiers' },
  { label: 'Colombiès', value: 'Colombiès' },
  { label: 'Colombo', value: 'Colombo' },
  { label: 'Colombo', value: 'Colombo' },
  { label: 'Colombo District', value: 'Colombo District' },
  { label: 'Colomera', value: 'Colomera' },
  { label: 'Colomers', value: 'Colomers' },
  { label: 'Colomi', value: 'Colomi' },
  { label: 'Colomiers', value: 'Colomiers' },
  { label: 'Colomoncagua', value: 'Colomoncagua' },
  { label: 'Colon', value: 'Colon' },
  { label: 'Colón', value: 'Colón' },
  { label: 'Colón', value: 'Colón' },
  { label: 'Colón', value: 'Colón' },
  { label: 'Colón', value: 'Colón' },
  { label: 'Colón', value: 'Colón' },
  { label: 'Colón', value: 'Colón' },
  { label: 'Colón', value: 'Colón' },
  { label: 'Colón', value: 'Colón' },
  { label: 'Colón', value: 'Colón' },
  { label: 'Colona', value: 'Colona' },
  { label: 'Colonel Hill', value: 'Colonel Hill' },
  { label: 'Colonel Light Gardens', value: 'Colonel Light Gardens' },
  { label: 'Colonelganj', value: 'Colonelganj' },
  { label: 'Colonels Ridge', value: 'Colonels Ridge' },
  { label: 'Coloneşti', value: 'Coloneşti' },
  { label: 'Coloneşti', value: 'Coloneşti' },
  { label: 'Colongolo', value: 'Colongolo' },
  { label: 'Colongulo', value: 'Colongulo' },
  { label: 'Colonia', value: 'Colonia' },
  { label: 'Colonia', value: 'Colonia' },
  { label: 'Colonia', value: 'Colonia' },
  { label: 'Colonia', value: 'Colonia' },
  { label: 'Colonia', value: 'Colonia' },
  { label: 'Colonia Abraham González (La Quemada)', value: 'Colonia Abraham González (La Quemada)' },
  { label: 'Colonia Adolfo López Mateos', value: 'Colonia Adolfo López Mateos' },
  { label: 'Colonia Adolfo Ruiz Cortines (Colonia Obrera)', value: 'Colonia Adolfo Ruiz Cortines (Colonia Obrera)' },
  { label: 'Colonia Aeropuerto', value: 'Colonia Aeropuerto' },
  { label: 'Colonia Agrícola Álvaro Obregón', value: 'Colonia Agrícola Álvaro Obregón' },
  { label: 'Colonia Agrícola Analco', value: 'Colonia Agrícola Analco' },
  { label: 'Colonia Agrícola De Ocotepec (Colonia San José)', value: 'Colonia Agrícola De Ocotepec (Colonia San José)' },
  { label: 'Colonia Agrícola México (Palmitas)', value: 'Colonia Agrícola México (Palmitas)' },
  { label: 'Colonia Agua Caliente', value: 'Colonia Agua Caliente' },
  { label: 'Colonia Alborada', value: 'Colonia Alborada' },
  { label: 'Colonia Álvaro Obregón', value: 'Colonia Álvaro Obregón' },
  { label: 'Colonia Álvaro Obregón', value: 'Colonia Álvaro Obregón' },
  { label: 'Colonia Ángel Bocanegra (Adolfo López Mateos)', value: 'Colonia Ángel Bocanegra (Adolfo López Mateos)' },
  { label: 'Colonia Antorcha Campesina (Santa Rosa)', value: 'Colonia Antorcha Campesina (Santa Rosa)' },
  { label: 'Colonia Aquiles Córdoba Morán', value: 'Colonia Aquiles Córdoba Morán' },
  { label: 'Colonia Arboledas (San Andrés)', value: 'Colonia Arboledas (San Andrés)' },
  { label: 'Colonia Aurora', value: 'Colonia Aurora' },
  { label: 'Colonia Aviación Autopan', value: 'Colonia Aviación Autopan' },
  { label: 'Colonia Azteca', value: 'Colonia Azteca' },
  { label: 'Colonia Barón', value: 'Colonia Barón' },
  { label: 'Colonia Bellavista', value: 'Colonia Bellavista' },
  { label: 'Colonia Benítez', value: 'Colonia Benítez' },
  { label: 'Colonia Benito Juárez', value: 'Colonia Benito Juárez' },
  { label: 'Colonia Bod', value: 'Colonia Bod' },
  { label: 'Colonia Bosques Del Sol', value: 'Colonia Bosques Del Sol' },
  { label: 'Colonia Campesina', value: 'Colonia Campesina' },
  { label: 'Colonia Capilla De Rayo', value: 'Colonia Capilla De Rayo' },
  { label: 'Colonia Carlos Antonio López', value: 'Colonia Carlos Antonio López' },
  { label: 'Colonia Catuete', value: 'Colonia Catuete' },
  { label: 'Colonia Chalchihuecan', value: 'Colonia Chalchihuecan' },
  { label: 'Colonia Constitución', value: 'Colonia Constitución' },
  { label: 'Colonia Cuauhtémoc', value: 'Colonia Cuauhtémoc' },
  { label: 'Colonia Cuauhtémoc', value: 'Colonia Cuauhtémoc' },
  { label: 'Colonia Cuisillos', value: 'Colonia Cuisillos' },
  { label: 'Colonia De Guadalupe', value: 'Colonia De Guadalupe' },
  { label: 'Colònia De Sant Jordi', value: 'Colònia De Sant Jordi' },
  { label: 'Colonia Del Sacramento', value: 'Colonia Del Sacramento' },
  { label: 'Colonia Del Sol', value: 'Colonia Del Sol' },
  { label: 'Colonia Del Valle', value: 'Colonia Del Valle' },
  { label: 'Colonia Diana Laura Riojas De Colosio', value: 'Colonia Diana Laura Riojas De Colosio' },
  { label: 'Colônia Do Gurguéia', value: 'Colônia Do Gurguéia' },
  { label: 'Colônia Do Piauí', value: 'Colônia Do Piauí' },
  { label: 'Colonia Doctor Gustavo Baz', value: 'Colonia Doctor Gustavo Baz' },
  { label: 'Colonia Dora', value: 'Colonia Dora' },
  { label: 'Colonia Ecológica Asociación De Lucha Social (Lucha Social)', value: 'Colonia Ecológica Asociación De Lucha Social (Lucha Social)' },
  { label: 'Colonia El Florido', value: 'Colonia El Florido' },
  { label: 'Colonia El Mirador', value: 'Colonia El Mirador' },
  { label: 'Colonia El Mirador (Llano Del Ejido)', value: 'Colonia El Mirador (Llano Del Ejido)' },
  { label: 'Colonia El Pedregal', value: 'Colonia El Pedregal' },
  { label: 'Colonia El Pirame', value: 'Colonia El Pirame' },
  { label: 'Colonia El Refugio', value: 'Colonia El Refugio' },
  { label: 'Colonia El Renacimiento', value: 'Colonia El Renacimiento' },
  { label: 'Colonia El Salado', value: 'Colonia El Salado' },
  { label: 'Colonia Elía', value: 'Colonia Elía' },
  { label: 'Colonia Elisa', value: 'Colonia Elisa' },
  { label: 'Colonia Emiliano Zapata', value: 'Colonia Emiliano Zapata' },
  { label: 'Colonia Emiliano Zapata', value: 'Colonia Emiliano Zapata' },
  { label: 'Colonia Emiliano Zapata', value: 'Colonia Emiliano Zapata' },
  { label: 'Colonia Emiliano Zapata (San Juan Zitácuaro)', value: 'Colonia Emiliano Zapata (San Juan Zitácuaro)' },
  { label: 'Colonia Emiliano Zapata Ejido De Tenancingo', value: 'Colonia Emiliano Zapata Ejido De Tenancingo' },
  { label: 'Colonia Fabricii', value: 'Colonia Fabricii' },
  { label: 'Colonia Felipe Ángeles', value: 'Colonia Felipe Ángeles' },
  { label: 'Colonia Felipe Ángeles', value: 'Colonia Felipe Ángeles' },
  { label: 'Colonia Felipe Ángeles', value: 'Colonia Felipe Ángeles' },
  { label: 'Colonia Fraccionamiento El Puente', value: 'Colonia Fraccionamiento El Puente' },
  { label: 'Colonia Francisco García Salinas', value: 'Colonia Francisco García Salinas' },
  { label: 'Colonia Francisco Javier Mina', value: 'Colonia Francisco Javier Mina' },
  { label: 'Colonia Ganadera', value: 'Colonia Ganadera' },
  { label: 'Colonia General Alfredo Stroessner', value: 'Colonia General Alfredo Stroessner' },
  { label: 'Colonia Gómez Morín', value: 'Colonia Gómez Morín' },
  { label: 'Colonia Guadalupana', value: 'Colonia Guadalupana' },
  { label: 'Colonia Guadalupe', value: 'Colonia Guadalupe' },
  { label: 'Colonia Guadalupe', value: 'Colonia Guadalupe' },
  { label: 'Colonia Guadalupe Victoria', value: 'Colonia Guadalupe Victoria' },
  { label: 'Colonia Guadalupe Victoria (La Capilla)', value: 'Colonia Guadalupe Victoria (La Capilla)' },
  { label: 'Colonia Gustavo Baz Prada', value: 'Colonia Gustavo Baz Prada' },
  { label: 'Colonia Hatmasie', value: 'Colonia Hatmasie' },
  { label: 'Colonia Hidalgo (El Tecolote)', value: 'Colonia Hidalgo (El Tecolote)' },
  { label: 'Colonia Ignacio Allende', value: 'Colonia Ignacio Allende' },
  { label: 'Colonia Independencia', value: 'Colonia Independencia' },
  { label: 'Colonia Independencia (Monterrey)', value: 'Colonia Independencia (Monterrey)' },
  { label: 'Colonia Isidro Fabela', value: 'Colonia Isidro Fabela' },
  { label: 'Colonia Jordán', value: 'Colonia Jordán' },
  { label: 'Colonia José María Morelos', value: 'Colonia José María Morelos' },
  { label: 'Colonia Juárez', value: 'Colonia Juárez' },
  { label: 'Colonia Juárez', value: 'Colonia Juárez' },
  { label: 'Colonia La Asunción', value: 'Colonia La Asunción' },
  { label: 'Colonia La Calzada (La Olla)', value: 'Colonia La Calzada (La Olla)' },
  { label: 'Colonia La Central', value: 'Colonia La Central' },
  { label: 'Colonia La Granjena', value: 'Colonia La Granjena' },
  { label: 'Colonia La Libertad', value: 'Colonia La Libertad' },
  { label: 'Colonia La Mesa', value: 'Colonia La Mesa' },
  { label: 'Colonia La Tordilla', value: 'Colonia La Tordilla' },
  { label: 'Colonia Las Arboledas', value: 'Colonia Las Arboledas' },
  { label: 'Colonia Las Flores', value: 'Colonia Las Flores' },
  { label: 'Colonia Las Granjas', value: 'Colonia Las Granjas' },
  { label: 'Colonia Las Malvinas (Colonia Antorcha)', value: 'Colonia Las Malvinas (Colonia Antorcha)' },
  { label: 'Colonia Las Palmas Campamento Sagar', value: 'Colonia Las Palmas Campamento Sagar' },
  { label: 'Colonia Latinoamericana', value: 'Colonia Latinoamericana' },
  { label: 'Colonia Lázaro Cárdenas', value: 'Colonia Lázaro Cárdenas' },
  { label: 'Colonia Lázaro Cárdenas', value: 'Colonia Lázaro Cárdenas' },
  { label: 'Colonia Lázaro Cárdenas (Los Hornos)', value: 'Colonia Lázaro Cárdenas (Los Hornos)' },
  { label: 'Colonia Lázaro Cárdenas Del Río', value: 'Colonia Lázaro Cárdenas Del Río' },
  { label: 'Colonia Lealtad', value: 'Colonia Lealtad' },
  { label: 'Colônia Leopoldina', value: 'Colônia Leopoldina' },
  { label: 'Colônia Leopoldina', value: 'Colônia Leopoldina' },
  { label: 'Colonia Libertad', value: 'Colonia Libertad' },
  { label: 'Colonia Lindavista', value: 'Colonia Lindavista' },
  { label: 'Colonia Loma Alta', value: 'Colonia Loma Alta' },
  { label: 'Colonia Lomas De San Ramón (Triquis)', value: 'Colonia Lomas De San Ramón (Triquis)' },
  { label: 'Colonia Los Aguiluchos', value: 'Colonia Los Aguiluchos' },
  { label: 'Colonia Los Ángeles', value: 'Colonia Los Ángeles' },
  { label: 'Colonia Los Cedros', value: 'Colonia Los Cedros' },
  { label: 'Colonia Los Cerritos', value: 'Colonia Los Cerritos' },
  { label: 'Colonia Los Remedios', value: 'Colonia Los Remedios' },
  { label: 'Colonia Los Robles', value: 'Colonia Los Robles' },
  { label: 'Colonia Los Sauces', value: 'Colonia Los Sauces' },
  { label: 'Colonia Luces En El Mar', value: 'Colonia Luces En El Mar' },
  { label: 'Colonia Madero', value: 'Colonia Madero' },
  { label: 'Colonia Manuel González', value: 'Colonia Manuel González' },
  { label: 'Colonia Mauricio José Troche', value: 'Colonia Mauricio José Troche' },
  { label: 'Colonia Menno', value: 'Colonia Menno' },
  { label: 'Colonia Michoacana', value: 'Colonia Michoacana' },
  { label: 'Colonia Miguel Hidalgo', value: 'Colonia Miguel Hidalgo' },
  { label: 'Colonia Militar', value: 'Colonia Militar' },
  { label: 'Colonia Minga Porá', value: 'Colonia Minga Porá' },
  { label: 'Colonia Montemariana (Colonia Mariana)', value: 'Colonia Montemariana (Colonia Mariana)' },
  { label: 'Colonia Morelos', value: 'Colonia Morelos' },
  { label: 'Colonia Morelos', value: 'Colonia Morelos' },
  { label: 'Colonia Morelos', value: 'Colonia Morelos' },
  { label: 'Colonia Morelos (El Nueve)', value: 'Colonia Morelos (El Nueve)' },
  { label: 'Colonia Morelos De Guadalupe De Rivera', value: 'Colonia Morelos De Guadalupe De Rivera' },
  { label: 'Colonia Nativitas', value: 'Colonia Nativitas' },
  { label: 'Colonia Neuland', value: 'Colonia Neuland' },
  { label: 'Colonia Nicolás Bravo (Kilómetro Noventa Y Dos)', value: 'Colonia Nicolás Bravo (Kilómetro Noventa Y Dos)' },
  { label: 'Colonia Nicolich', value: 'Colonia Nicolich' },
  { label: 'Colonia Niños Héroes', value: 'Colonia Niños Héroes' },
  { label: 'Colonia Nueva Era', value: 'Colonia Nueva Era' },
  { label: 'Colonia Nueva Germania', value: 'Colonia Nueva Germania' },
  { label: 'Colonia Nueva Revolución', value: 'Colonia Nueva Revolución' },
  { label: 'Colonia Nuevo México', value: 'Colonia Nuevo México' },
  { label: 'Colonia Obrera', value: 'Colonia Obrera' },
  { label: 'Colonia Obrera', value: 'Colonia Obrera' },
  { label: 'Colonia Obrera', value: 'Colonia Obrera' },
  { label: 'Colonia Padre Hidalgo', value: 'Colonia Padre Hidalgo' },
  { label: 'Colonia Palma', value: 'Colonia Palma' },
  { label: 'Colonia Palo Prieto (Chipitongo)', value: 'Colonia Palo Prieto (Chipitongo)' },
  { label: 'Colonia Paraíso Escondido', value: 'Colonia Paraíso Escondido' },
  { label: 'Colonia Paso Del Molino', value: 'Colonia Paso Del Molino' },
  { label: 'Colonia Patria Nueva', value: 'Colonia Patria Nueva' },
  { label: 'Colonia Pedro María Anaya', value: 'Colonia Pedro María Anaya' },
  { label: 'Colonia Pinal Villa', value: 'Colonia Pinal Villa' },
  { label: 'Colonia Plenitud', value: 'Colonia Plenitud' },
  { label: 'Colonia Progreso', value: 'Colonia Progreso' },
  { label: 'Colonia Rafael Corrales Ayala', value: 'Colonia Rafael Corrales Ayala' },
  { label: 'Colonia Reforma Tlalmimilolpan', value: 'Colonia Reforma Tlalmimilolpan' },
  { label: 'Colonia Renacimiento', value: 'Colonia Renacimiento' },
  { label: 'Colonia Revolución', value: 'Colonia Revolución' },
  { label: 'Colonia Ricardo Flores Magón', value: 'Colonia Ricardo Flores Magón' },
  { label: 'Colonia Rincón Viejo', value: 'Colonia Rincón Viejo' },
  { label: 'Colonia Rincón Villa Del Valle', value: 'Colonia Rincón Villa Del Valle' },
  { label: 'Colonia Río Lerma (Tic Ti)', value: 'Colonia Río Lerma (Tic Ti)' },
  { label: 'Colonia San Bartolomé', value: 'Colonia San Bartolomé' },
  { label: 'Colonia San Francisco', value: 'Colonia San Francisco' },
  { label: 'Colonia San Francisco (San Francisco)', value: 'Colonia San Francisco (San Francisco)' },
  { label: 'Colonia San Francisco De Asís', value: 'Colonia San Francisco De Asís' },
  { label: 'Colonia San Isidro', value: 'Colonia San Isidro' },
  { label: 'Colonia San Juan', value: 'Colonia San Juan' },
  { label: 'Colonia San Lorenzo', value: 'Colonia San Lorenzo' },
  { label: 'Colonia San Luis', value: 'Colonia San Luis' },
  { label: 'Colonia San Luis Rey', value: 'Colonia San Luis Rey' },
  { label: 'Colonia San Ramón', value: 'Colonia San Ramón' },
  { label: 'Colonia Santa Bárbara', value: 'Colonia Santa Bárbara' },
  { label: 'Colonia Santa Cecilia', value: 'Colonia Santa Cecilia' },
  { label: 'Colonia Sesenta Y Dos', value: 'Colonia Sesenta Y Dos' },
  { label: 'Colonia Teñhe', value: 'Colonia Teñhe' },
  { label: 'Colonia Terrazas', value: 'Colonia Terrazas' },
  { label: 'Colonia Trigomil', value: 'Colonia Trigomil' },
  { label: 'Colonia Tulteca Teopan', value: 'Colonia Tulteca Teopan' },
  { label: 'Colonia Úrsulo Galván', value: 'Colonia Úrsulo Galván' },
  { label: 'Colonia Veinte De Noviembre', value: 'Colonia Veinte De Noviembre' },
  { label: 'Colonia Veintitrés De Mayo', value: 'Colonia Veintitrés De Mayo' },
  { label: 'Colonia Veintiuno De Marzo', value: 'Colonia Veintiuno De Marzo' },
  { label: 'Colonia Venustiano Carranza', value: 'Colonia Venustiano Carranza' },
  { label: 'Colonia Venustiano Carranza', value: 'Colonia Venustiano Carranza' },
  { label: 'Colonia Venustiano Carranza', value: 'Colonia Venustiano Carranza' },
  { label: 'Colonia Vista Bella (Lomas Del Peaje)', value: 'Colonia Vista Bella (Lomas Del Peaje)' },
  { label: 'Colonia Wenceslao Labra', value: 'Colonia Wenceslao Labra' },
  { label: 'Colonia Yucatán', value: 'Colonia Yucatán' },
  { label: 'Colonial Beach', value: 'Colonial Beach' },
  { label: 'Colonial Heights', value: 'Colonial Heights' },
  { label: 'Colonial Heights', value: 'Colonial Heights' },
  { label: 'Colonial Park', value: 'Colonial Park' },
  { label: 'Colonial Pine Hills', value: 'Colonial Pine Hills' },
  { label: 'Colonias Pedernales', value: 'Colonias Pedernales' },
  { label: 'Colonias Unidas', value: 'Colonias Unidas' },
  { label: 'Colonie', value: 'Colonie' },
  { label: 'Colonna', value: 'Colonna' },
  { label: 'Colonnella', value: 'Colonnella' },
  { label: 'Colonno', value: 'Colonno' },
  { label: 'Colony Park', value: 'Colony Park' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Colorado City', value: 'Colorado City' },
  { label: 'Colorado City', value: 'Colorado City' },
  { label: 'Colorado City', value: 'Colorado City' },
  { label: 'Colorado County', value: 'Colorado County' },
  { label: 'Colorado Do Oeste', value: 'Colorado Do Oeste' },
  { label: 'Colorado Springs', value: 'Colorado Springs' },
  { label: 'Colorina', value: 'Colorina' },
  { label: 'Colorines', value: 'Colorines' },
  { label: 'Colorines', value: 'Colorines' },
  { label: 'Colorno', value: 'Colorno' },
  { label: 'Colosimi', value: 'Colosimi' },
  { label: 'Coloso', value: 'Coloso' },
  { label: 'Colotenango', value: 'Colotenango' },
  { label: 'Colotepec', value: 'Colotepec' },
  { label: 'Colotlán', value: 'Colotlán' },
  { label: 'Colotlán Municipality', value: 'Colotlán Municipality' },
  { label: 'Colotlipa', value: 'Colotlipa' },
  { label: 'Colovale', value: 'Colovale' },
  { label: 'Colpo', value: 'Colpo' },
  { label: 'Colquechaca', value: 'Colquechaca' },
  { label: 'Colquiri', value: 'Colquiri' },
  { label: 'Colquitt', value: 'Colquitt' },
  { label: 'Colquitt County', value: 'Colquitt County' },
  { label: 'Colrain', value: 'Colrain' },
  { label: 'Colsterworth', value: 'Colsterworth' },
  { label: 'Colstrip', value: 'Colstrip' },
  { label: 'Coltău', value: 'Coltău' },
  { label: 'Coltauco', value: 'Coltauco' },
  { label: 'Colţi', value: 'Colţi' },
  { label: 'Colțirea', value: 'Colțirea' },
  { label: 'Coltishall', value: 'Coltishall' },
  { label: 'Colton', value: 'Colton' },
  { label: 'Colturano', value: 'Colturano' },
  { label: 'Colubro', value: 'Colubro' },
  { label: 'Colucan', value: 'Colucan' },
  { label: 'Colugna', value: 'Colugna' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Columbia City', value: 'Columbia City' },
  { label: 'Columbia City', value: 'Columbia City' },
  { label: 'Columbia County', value: 'Columbia County' },
  { label: 'Columbia County', value: 'Columbia County' },
  { label: 'Columbia County', value: 'Columbia County' },
  { label: 'Columbia County', value: 'Columbia County' },
  { label: 'Columbia County', value: 'Columbia County' },
  { label: 'Columbia County', value: 'Columbia County' },
  { label: 'Columbia County', value: 'Columbia County' },
  { label: 'Columbia County', value: 'Columbia County' },
  { label: 'Columbia Falls', value: 'Columbia Falls' },
  { label: 'Columbia Heights', value: 'Columbia Heights' },
  { label: 'Columbiana', value: 'Columbiana' },
  { label: 'Columbiana', value: 'Columbiana' },
  { label: 'Columbiana County', value: 'Columbiana County' },
  { label: 'Columbine', value: 'Columbine' },
  { label: 'Columbine Valley', value: 'Columbine Valley' },
  { label: 'Columbio', value: 'Columbio' },
  { label: 'Columbio', value: 'Columbio' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Columbus Air Force Base', value: 'Columbus Air Force Base' },
  { label: 'Columbus County', value: 'Columbus County' },
  { label: 'Columbus Grove', value: 'Columbus Grove' },
  { label: 'Columbus Junction', value: 'Columbus Junction' },
  { label: 'Coluna', value: 'Coluna' },
  { label: 'Colunga', value: 'Colunga' },
  { label: 'Colungo', value: 'Colungo' },
  { label: 'Colusa', value: 'Colusa' },
  { label: 'Colusa County', value: 'Colusa County' },
  { label: 'Colva', value: 'Colva' },
  { label: 'Colville', value: 'Colville' },
  { label: 'Colwich', value: 'Colwich' },
  { label: 'Colwich', value: 'Colwich' },
  { label: 'Colwood', value: 'Colwood' },
  { label: 'Colwyn', value: 'Colwyn' },
  { label: 'Colwyn Bay', value: 'Colwyn Bay' },
  { label: 'Colyton', value: 'Colyton' },
  { label: 'Colyton', value: 'Colyton' },
  { label: 'Colzate', value: 'Colzate' },
  { label: 'Coma I La Pedra La', value: 'Coma I La Pedra La' },
  { label: 'Comabbio', value: 'Comabbio' },
  { label: 'Comacchio', value: 'Comacchio' },
  { label: 'Comachuén', value: 'Comachuén' },
  { label: 'Comagascas', value: 'Comagascas' },
  { label: 'Comagascas', value: 'Comagascas' },
  { label: 'Comal', value: 'Comal' },
  { label: 'Comal County', value: 'Comal County' },
  { label: 'Comala', value: 'Comala' },
  { label: 'Comalapa', value: 'Comalapa' },
  { label: 'Comalapa', value: 'Comalapa' },
  { label: 'Comalapa', value: 'Comalapa' },
  { label: 'Comalcalco', value: 'Comalcalco' },
  { label: 'Comales', value: 'Comales' },
  { label: 'Comallo', value: 'Comallo' },
  { label: 'Comaltepec', value: 'Comaltepec' },
  { label: 'Comana', value: 'Comana' },
  { label: 'Comana', value: 'Comana' },
  { label: 'Comana De Jos', value: 'Comana De Jos' },
  { label: 'Comana De Sus', value: 'Comana De Sus' },
  { label: 'Comanca', value: 'Comanca' },
  { label: 'Comanche', value: 'Comanche' },
  { label: 'Comanche', value: 'Comanche' },
  { label: 'Comanche County', value: 'Comanche County' },
  { label: 'Comanche County', value: 'Comanche County' },
  { label: 'Comanche County', value: 'Comanche County' },
  { label: 'Comanda', value: 'Comanda' },
  { label: 'Comandante Fontana', value: 'Comandante Fontana' },
  { label: 'Comandante Luis Piedra Buena', value: 'Comandante Luis Piedra Buena' },
  { label: 'Comandău', value: 'Comandău' },
  { label: 'Comăneşti', value: 'Comăneşti' },
  { label: 'Comănești', value: 'Comănești' },
  { label: 'Comani', value: 'Comani' },
  { label: 'Comanjá', value: 'Comanjá' },
  { label: 'Comanja De Corona', value: 'Comanja De Corona' },
  { label: 'Comanjilla', value: 'Comanjilla' },
  { label: 'Comano', value: 'Comano' },
  { label: 'Comano', value: 'Comano' },
  { label: 'Comapa', value: 'Comapa' },
  { label: 'Comapa', value: 'Comapa' },
  { label: 'Comarapa', value: 'Comarapa' },
  { label: 'Comares', value: 'Comares' },
  { label: 'Comarna', value: 'Comarna' },
  { label: 'Comarnic', value: 'Comarnic' },
  { label: 'Comayagua', value: 'Comayagua' },
  { label: 'Comazzo', value: 'Comazzo' },
  { label: 'Combaillaux', value: 'Combaillaux' },
  { label: 'Combapata', value: 'Combapata' },
  { label: 'Combarbalá', value: 'Combarbalá' },
  { label: 'Combe Martin', value: 'Combe Martin' },
  { label: 'Combee Settlement', value: 'Combee Settlement' },
  { label: 'Comber', value: 'Comber' },
  { label: 'Comberton', value: 'Comberton' },
  { label: 'Combes', value: 'Combes' },
  { label: 'Combinado', value: 'Combinado' },
  { label: 'Combine', value: 'Combine' },
  { label: 'Combined Locks', value: 'Combined Locks' },
  { label: 'Combita', value: 'Combita' },
  { label: 'Comblain-Au-Pont', value: 'Comblain-Au-Pont' },
  { label: 'Combloux', value: 'Combloux' },
  { label: 'Combourg', value: 'Combourg' },
  { label: 'Combrand', value: 'Combrand' },
  { label: 'Combrée', value: 'Combrée' },
  { label: 'Combrit', value: 'Combrit' },
  { label: 'Combronde', value: 'Combronde' },
  { label: 'Combs-La-Ville', value: 'Combs-La-Ville' },
  { label: 'Comé', value: 'Comé' },
  { label: 'Comeana', value: 'Comeana' },
  { label: 'Comeglians', value: 'Comeglians' },
  { label: 'Comején', value: 'Comején' },
  { label: 'Comendador Gomes', value: 'Comendador Gomes' },
  { label: 'Comendador Levy Gasparian', value: 'Comendador Levy Gasparian' },
  { label: 'Comer', value: 'Comer' },
  { label: 'Comercinho', value: 'Comercinho' },
  { label: 'Comerio', value: 'Comerio' },
  { label: 'Comerío', value: 'Comerío' },
  { label: 'Comerío', value: 'Comerío' },
  { label: 'Comerío Municipio', value: 'Comerío Municipio' },
  { label: 'Comfort', value: 'Comfort' },
  { label: 'Comfort', value: 'Comfort' },
  { label: 'Comfort Castle', value: 'Comfort Castle' },
  { label: 'Comfort Hall', value: 'Comfort Hall' },
  { label: 'Comfort Hall', value: 'Comfort Hall' },
  { label: 'Comignago', value: 'Comignago' },
  { label: 'Comilla', value: 'Comilla' },
  { label: 'Comillas', value: 'Comillas' },
  { label: 'Comillas', value: 'Comillas' },
  { label: 'Comillas', value: 'Comillas' },
  { label: 'Comillas Norte', value: 'Comillas Norte' },
  { label: 'Comillas Norte', value: 'Comillas Norte' },
  { label: 'Comines', value: 'Comines' },
  { label: 'Cominio', value: 'Cominio' },
  { label: 'Comişani', value: 'Comişani' },
  { label: 'Comiso', value: 'Comiso' },
  { label: 'Comitán', value: 'Comitán' },
  { label: 'Comitancillo', value: 'Comitancillo' },
  { label: 'Comitini', value: 'Comitini' },
  { label: 'Comiziano', value: 'Comiziano' },
  { label: 'Comloşu Mare', value: 'Comloşu Mare' },
  { label: 'Commack', value: 'Commack' },
  { label: 'Commandeurs', value: 'Commandeurs' },
  { label: 'Commelle', value: 'Commelle' },
  { label: 'Commentry', value: 'Commentry' },
  { label: 'Commequiers', value: 'Commequiers' },
  { label: 'Commer', value: 'Commer' },
  { label: 'Commerce', value: 'Commerce' },
  { label: 'Commerce', value: 'Commerce' },
  { label: 'Commerce', value: 'Commerce' },
  { label: 'Commerce', value: 'Commerce' },
  { label: 'Commerce City', value: 'Commerce City' },
  { label: 'Commercial Point', value: 'Commercial Point' },
  { label: 'Commercy', value: 'Commercy' },
  { label: 'Commessaggio', value: 'Commessaggio' },
  { label: 'Commezzadura', value: 'Commezzadura' },
  { label: 'Communal', value: 'Communal' },
  { label: 'Communal', value: 'Communal' },
  { label: 'Communay', value: 'Communay' },
  { label: 'Commune De La Vallée De L’Ernz', value: 'Commune De La Vallée De L’Ernz' },
  { label: 'Commune De Préizerdaul', value: 'Commune De Préizerdaul' },
  { label: 'Commune Of Agbangnizoun', value: 'Commune Of Agbangnizoun' },
  { label: 'Commune Of Athieme', value: 'Commune Of Athieme' },
  { label: 'Commune Of Djougou', value: 'Commune Of Djougou' },
  { label: 'Como', value: 'Como' },
  { label: 'Como', value: 'Como' },
  { label: 'Como', value: 'Como' },
  { label: 'Como', value: 'Como' },
  { label: 'Como', value: 'Como' },
  { label: 'Comoapan', value: 'Comoapan' },
  { label: 'Comodoro', value: 'Comodoro' },
  { label: 'Comodoro Rivadavia', value: 'Comodoro Rivadavia' },
  { label: 'Comondú', value: 'Comondú' },
  { label: 'Comonfort', value: 'Comonfort' },
  { label: 'Comori', value: 'Comori' },
  { label: 'Comoșteni', value: 'Comoșteni' },
  { label: 'Compar', value: 'Compar' },
  { label: 'Compertrix', value: 'Compertrix' },
  { label: 'Cómpeta', value: 'Cómpeta' },
  { label: 'Compiano', value: 'Compiano' },
  { label: 'Compiègne', value: 'Compiègne' },
  { label: 'Compiobbi', value: 'Compiobbi' },
  { label: 'Componistenkwartier', value: 'Componistenkwartier' },
  { label: 'Comporta', value: 'Comporta' },
  { label: 'Compostela', value: 'Compostela' },
  { label: 'Compostela', value: 'Compostela' },
  { label: 'Compostela', value: 'Compostela' },
  { label: 'Compostela Valley', value: 'Compostela Valley' },
  { label: 'Compreignac', value: 'Compreignac' },
  { label: 'Comps', value: 'Comps' },
  { label: 'Compton', value: 'Compton' },
  { label: 'Compton', value: 'Compton' },
  { label: 'Compton Martin', value: 'Compton Martin' },
  { label: 'Compuertas', value: 'Compuertas' },
  { label: 'Comrat', value: 'Comrat' },
  { label: 'Comrie', value: 'Comrie' },
  { label: 'Comstock Northwest', value: 'Comstock Northwest' },
  { label: 'Comstock Park', value: 'Comstock Park' },
  { label: 'Comun Nuovo', value: 'Comun Nuovo' },
  { label: 'Comuna Abram', value: 'Comuna Abram' },
  { label: 'Comuna Abrămuţ', value: 'Comuna Abrămuţ' },
  { label: 'Comuna Acâş', value: 'Comuna Acâş' },
  { label: 'Comuna Adamclisi', value: 'Comuna Adamclisi' },
  { label: 'Comuna Adâncata', value: 'Comuna Adâncata' },
  { label: 'Comuna Adâncata', value: 'Comuna Adâncata' },
  { label: 'Comuna Adăşeni', value: 'Comuna Adăşeni' },
  { label: 'Comuna Adunaţi', value: 'Comuna Adunaţi' },
  { label: 'Comuna Adunaţii-Copăceni', value: 'Comuna Adunaţii-Copăceni' },
  { label: 'Comuna Afumaţi', value: 'Comuna Afumaţi' },
  { label: 'Comuna Afumaţi', value: 'Comuna Afumaţi' },
  { label: 'Comuna Agapia', value: 'Comuna Agapia' },
  { label: 'Comuna Agăş', value: 'Comuna Agăş' },
  { label: 'Comuna Aghireşu', value: 'Comuna Aghireşu' },
  { label: 'Comuna Agigea', value: 'Comuna Agigea' },
  { label: 'Comuna Agrij', value: 'Comuna Agrij' },
  { label: 'Comuna Agriş', value: 'Comuna Agriş' },
  { label: 'Comuna Aita Mare', value: 'Comuna Aita Mare' },
  { label: 'Comuna Aiton', value: 'Comuna Aiton' },
  { label: 'Comuna Albac', value: 'Comuna Albac' },
  { label: 'Comuna Albeni', value: 'Comuna Albeni' },
  { label: 'Comuna Albeşti', value: 'Comuna Albeşti' },
  { label: 'Comuna Albeşti', value: 'Comuna Albeşti' },
  { label: 'Comuna Albeşti', value: 'Comuna Albeşti' },
  { label: 'Comuna Albeşti', value: 'Comuna Albeşti' },
  { label: 'Comuna Albeștii De Argeș', value: 'Comuna Albeștii De Argeș' },
  { label: 'Comuna Albeștii De Muscel', value: 'Comuna Albeștii De Muscel' },
  { label: 'Comuna Albeşti-Paleologu', value: 'Comuna Albeşti-Paleologu' },
  { label: 'Comuna Albota', value: 'Comuna Albota' },
  { label: 'Comuna Alexandru Cel Bun', value: 'Comuna Alexandru Cel Bun' },
  { label: 'Comuna Alexandru I. Cuza', value: 'Comuna Alexandru I. Cuza' },
  { label: 'Comuna Alexandru Odobescu', value: 'Comuna Alexandru Odobescu' },
  { label: 'Comuna Alexandru Vlăhuţă', value: 'Comuna Alexandru Vlăhuţă' },
  { label: 'Comuna Alexeni', value: 'Comuna Alexeni' },
  { label: 'Comuna Aliman', value: 'Comuna Aliman' },
  { label: 'Comuna Alimpeşti', value: 'Comuna Alimpeşti' },
  { label: 'Comuna Alma', value: 'Comuna Alma' },
  { label: 'Comuna Almãj', value: 'Comuna Almãj' },
  { label: 'Comuna Almaş', value: 'Comuna Almaş' },
  { label: 'Comuna Almaşu', value: 'Comuna Almaşu' },
  { label: 'Comuna Almaşu Mare', value: 'Comuna Almaşu Mare' },
  { label: 'Comuna Alțâna', value: 'Comuna Alțâna' },
  { label: 'Comuna Aluniş', value: 'Comuna Aluniş' },
  { label: 'Comuna Aluniş', value: 'Comuna Aluniş' },
  { label: 'Comuna Alunu', value: 'Comuna Alunu' },
  { label: 'Comuna Amărăşti', value: 'Comuna Amărăşti' },
  { label: 'Comuna Amărăştii De Jos', value: 'Comuna Amărăştii De Jos' },
  { label: 'Comuna Amărăştii De Sus', value: 'Comuna Amărăştii De Sus' },
  { label: 'Comuna Amaru', value: 'Comuna Amaru' },
  { label: 'Comuna Amzacea', value: 'Comuna Amzacea' },
  { label: 'Comuna Andrăşeşti', value: 'Comuna Andrăşeşti' },
  { label: 'Comuna Andreiaşu De Jos', value: 'Comuna Andreiaşu De Jos' },
  { label: 'Comuna Andrid', value: 'Comuna Andrid' },
  { label: 'Comuna Andrieşeni', value: 'Comuna Andrieşeni' },
  { label: 'Comuna Aninoasa', value: 'Comuna Aninoasa' },
  { label: 'Comuna Aninoasa', value: 'Comuna Aninoasa' },
  { label: 'Comuna Aninoasa', value: 'Comuna Aninoasa' },
  { label: 'Comuna Apa', value: 'Comuna Apa' },
  { label: 'Comuna Apahida', value: 'Comuna Apahida' },
  { label: 'Comuna Apaţa', value: 'Comuna Apaţa' },
  { label: 'Comuna Apateu', value: 'Comuna Apateu' },
  { label: 'Comuna Apele Vii', value: 'Comuna Apele Vii' },
  { label: 'Comuna Apoldu De Jos', value: 'Comuna Apoldu De Jos' },
  { label: 'Comuna Apostolache', value: 'Comuna Apostolache' },
  { label: 'Comuna Arbore', value: 'Comuna Arbore' },
  { label: 'Comuna Arcani', value: 'Comuna Arcani' },
  { label: 'Comuna Archiş', value: 'Comuna Archiş' },
  { label: 'Comuna Arcuş', value: 'Comuna Arcuş' },
  { label: 'Comuna Ardeoani', value: 'Comuna Ardeoani' },
  { label: 'Comuna Ardusat', value: 'Comuna Ardusat' },
  { label: 'Comuna Arefu', value: 'Comuna Arefu' },
  { label: 'Comuna Argetoaia', value: 'Comuna Argetoaia' },
  { label: 'Comuna Ariceştii Zeletin', value: 'Comuna Ariceştii Zeletin' },
  { label: 'Comuna Ariceştii-Rahtivani', value: 'Comuna Ariceştii-Rahtivani' },
  { label: 'Comuna Arieşeni', value: 'Comuna Arieşeni' },
  { label: 'Comuna Ariniş', value: 'Comuna Ariniş' },
  { label: 'Comuna Armăşeşti', value: 'Comuna Armăşeşti' },
  { label: 'Comuna Armeniş', value: 'Comuna Armeniş' },
  { label: 'Comuna Aroneanu', value: 'Comuna Aroneanu' },
  { label: 'Comuna Arpaşu De Jos', value: 'Comuna Arpaşu De Jos' },
  { label: 'Comuna Arsura', value: 'Comuna Arsura' },
  { label: 'Comuna Asău', value: 'Comuna Asău' },
  { label: 'Comuna Aşchileu', value: 'Comuna Aşchileu' },
  { label: 'Comuna Aştileu', value: 'Comuna Aştileu' },
  { label: 'Comuna Asuaju De Sus', value: 'Comuna Asuaju De Sus' },
  { label: 'Comuna Aţel', value: 'Comuna Aţel' },
  { label: 'Comuna Atid', value: 'Comuna Atid' },
  { label: 'Comuna Augustin', value: 'Comuna Augustin' },
  { label: 'Comuna Auşeu', value: 'Comuna Auşeu' },
  { label: 'Comuna Avram Iancu', value: 'Comuna Avram Iancu' },
  { label: 'Comuna Avram Iancu', value: 'Comuna Avram Iancu' },
  { label: 'Comuna Avrămeni', value: 'Comuna Avrămeni' },
  { label: 'Comuna Avrămeşti', value: 'Comuna Avrămeşti' },
  { label: 'Comuna Axente Sever', value: 'Comuna Axente Sever' },
  { label: 'Comuna Axintele', value: 'Comuna Axintele' },
  { label: 'Comuna Baba Ana', value: 'Comuna Baba Ana' },
  { label: 'Comuna Băbăiţa', value: 'Comuna Băbăiţa' },
  { label: 'Comuna Băbana', value: 'Comuna Băbana' },
  { label: 'Comuna Băbeni', value: 'Comuna Băbeni' },
  { label: 'Comuna Băbiciu', value: 'Comuna Băbiciu' },
  { label: 'Comuna Băcani', value: 'Comuna Băcani' },
  { label: 'Comuna Băceşti', value: 'Comuna Băceşti' },
  { label: 'Comuna Băcia', value: 'Comuna Băcia' },
  { label: 'Comuna Baciu', value: 'Comuna Baciu' },
  { label: 'Comuna Bâcleş', value: 'Comuna Bâcleş' },
  { label: 'Comuna Bahna', value: 'Comuna Bahna' },
  { label: 'Comuna Baia', value: 'Comuna Baia' },
  { label: 'Comuna Baia', value: 'Comuna Baia' },
  { label: 'Comuna Baia De Criş', value: 'Comuna Baia De Criş' },
  { label: 'Comuna Baia De Fier', value: 'Comuna Baia De Fier' },
  { label: 'Comuna Băiculeşti', value: 'Comuna Băiculeşti' },
  { label: 'Comuna Băişoara', value: 'Comuna Băişoara' },
  { label: 'Comuna Băiţa', value: 'Comuna Băiţa' },
  { label: 'Comuna Băiţa De Sub Codru', value: 'Comuna Băiţa De Sub Codru' },
  { label: 'Comuna Băiuţ', value: 'Comuna Băiuţ' },
  { label: 'Comuna Bala', value: 'Comuna Bala' },
  { label: 'Comuna Bălăbăneşti', value: 'Comuna Bălăbăneşti' },
  { label: 'Comuna Bălăceana', value: 'Comuna Bălăceana' },
  { label: 'Comuna Bălăceanu', value: 'Comuna Bălăceanu' },
  { label: 'Comuna Balaci', value: 'Comuna Balaci' },
  { label: 'Comuna Bălăciţa', value: 'Comuna Bălăciţa' },
  { label: 'Comuna Balaciu', value: 'Comuna Balaciu' },
  { label: 'Comuna Bălan', value: 'Comuna Bălan' },
  { label: 'Comuna Bălăneşti', value: 'Comuna Bălăneşti' },
  { label: 'Comuna Bălăşeşti', value: 'Comuna Bălăşeşti' },
  { label: 'Comuna Balc', value: 'Comuna Balc' },
  { label: 'Comuna Balcani', value: 'Comuna Balcani' },
  { label: 'Comuna Bălcăuţi', value: 'Comuna Bălcăuţi' },
  { label: 'Comuna Baldovineşti', value: 'Comuna Baldovineşti' },
  { label: 'Comuna Băleni', value: 'Comuna Băleni' },
  { label: 'Comuna Băleni Sârbi', value: 'Comuna Băleni Sârbi' },
  { label: 'Comuna Băleşti', value: 'Comuna Băleşti' },
  { label: 'Comuna Băleşti', value: 'Comuna Băleşti' },
  { label: 'Comuna Bălileşti', value: 'Comuna Bălileşti' },
  { label: 'Comuna Balinţ', value: 'Comuna Balinţ' },
  { label: 'Comuna Baloteşti', value: 'Comuna Baloteşti' },
  { label: 'Comuna Balş', value: 'Comuna Balş' },
  { label: 'Comuna Balşa', value: 'Comuna Balşa' },
  { label: 'Comuna Balta', value: 'Comuna Balta' },
  { label: 'Comuna Balta Albă', value: 'Comuna Balta Albă' },
  { label: 'Comuna Balta Doamnei', value: 'Comuna Balta Doamnei' },
  { label: 'Comuna Bălţăteşti', value: 'Comuna Bălţăteşti' },
  { label: 'Comuna Bălţaţi', value: 'Comuna Bălţaţi' },
  { label: 'Comuna Bâlteni', value: 'Comuna Bâlteni' },
  { label: 'Comuna Bălteni', value: 'Comuna Bălteni' },
  { label: 'Comuna Bălteni', value: 'Comuna Bălteni' },
  { label: 'Comuna Bălţeşti', value: 'Comuna Bălţeşti' },
  { label: 'Comuna Băluşeni', value: 'Comuna Băluşeni' },
  { label: 'Comuna Bâlvăneşti', value: 'Comuna Bâlvăneşti' },
  { label: 'Comuna Banca', value: 'Comuna Banca' },
  { label: 'Comuna Băneasa', value: 'Comuna Băneasa' },
  { label: 'Comuna Băneasa', value: 'Comuna Băneasa' },
  { label: 'Comuna Băneşti', value: 'Comuna Băneşti' },
  { label: 'Comuna Bănia', value: 'Comuna Bănia' },
  { label: 'Comuna Bănişor', value: 'Comuna Bănişor' },
  { label: 'Comuna Băniţa', value: 'Comuna Băniţa' },
  { label: 'Comuna Banloc', value: 'Comuna Banloc' },
  { label: 'Comuna Bara', value: 'Comuna Bara' },
  { label: 'Comuna Bâra', value: 'Comuna Bâra' },
  { label: 'Comuna Bărăganu', value: 'Comuna Bărăganu' },
  { label: 'Comuna Bărăganul', value: 'Comuna Bărăganul' },
  { label: 'Comuna Bãrãşti', value: 'Comuna Bãrãşti' },
  { label: 'Comuna Bărbăteşti', value: 'Comuna Bărbăteşti' },
  { label: 'Comuna Bărbăteşti', value: 'Comuna Bărbăteşti' },
  { label: 'Comuna Bărbuleşti', value: 'Comuna Bărbuleşti' },
  { label: 'Comuna Bărbuleţu', value: 'Comuna Bărbuleţu' },
  { label: 'Comuna Bârca', value: 'Comuna Bârca' },
  { label: 'Comuna Bărcăneşti', value: 'Comuna Bărcăneşti' },
  { label: 'Comuna Bărcăneşti', value: 'Comuna Bărcăneşti' },
  { label: 'Comuna Barcani', value: 'Comuna Barcani' },
  { label: 'Comuna Barcea', value: 'Comuna Barcea' },
  { label: 'Comuna Bârgãuani', value: 'Comuna Bârgãuani' },
  { label: 'Comuna Bârghiş', value: 'Comuna Bârghiş' },
  { label: 'Comuna Bârla', value: 'Comuna Bârla' },
  { label: 'Comuna Bârna', value: 'Comuna Bârna' },
  { label: 'Comuna Bârnova', value: 'Comuna Bârnova' },
  { label: 'Comuna Bârsa', value: 'Comuna Bârsa' },
  { label: 'Comuna Bârsana', value: 'Comuna Bârsana' },
  { label: 'Comuna Bârsăneşti', value: 'Comuna Bârsăneşti' },
  { label: 'Comuna Bârsău', value: 'Comuna Bârsău' },
  { label: 'Comuna Bârseşti', value: 'Comuna Bârseşti' },
  { label: 'Comuna Baru', value: 'Comuna Baru' },
  { label: 'Comuna Bârza', value: 'Comuna Bârza' },
  { label: 'Comuna Bârzava', value: 'Comuna Bârzava' },
  { label: 'Comuna Başcov', value: 'Comuna Başcov' },
  { label: 'Comuna Băseşti', value: 'Comuna Băseşti' },
  { label: 'Comuna Bata', value: 'Comuna Bata' },
  { label: 'Comuna Băţani', value: 'Comuna Băţani' },
  { label: 'Comuna Batăr', value: 'Comuna Batăr' },
  { label: 'Comuna Bătarci', value: 'Comuna Bătarci' },
  { label: 'Comuna Bătrâna', value: 'Comuna Bătrâna' },
  { label: 'Comuna Bătrâni', value: 'Comuna Bătrâni' },
  { label: 'Comuna Băuţar', value: 'Comuna Băuţar' },
  { label: 'Comuna Bazna', value: 'Comuna Bazna' },
  { label: 'Comuna Beba Veche', value: 'Comuna Beba Veche' },
  { label: 'Comuna Beceni', value: 'Comuna Beceni' },
  { label: 'Comuna Becicherecu Mic', value: 'Comuna Becicherecu Mic' },
  { label: 'Comuna Beciu', value: 'Comuna Beciu' },
  { label: 'Comuna Beclean', value: 'Comuna Beclean' },
  { label: 'Comuna Beidaud', value: 'Comuna Beidaud' },
  { label: 'Comuna Belceşti', value: 'Comuna Belceşti' },
  { label: 'Comuna Belciugatele', value: 'Comuna Belciugatele' },
  { label: 'Comuna Beleţi-Negreşti', value: 'Comuna Beleţi-Negreşti' },
  { label: 'Comuna Belin', value: 'Comuna Belin' },
  { label: 'Comuna Belinţ', value: 'Comuna Belinţ' },
  { label: 'Comuna Beliş', value: 'Comuna Beliş' },
  { label: 'Comuna Beliu', value: 'Comuna Beliu' },
  { label: 'Comuna Beltiug', value: 'Comuna Beltiug' },
  { label: 'Comuna Benesat', value: 'Comuna Benesat' },
  { label: 'Comuna Bengeşti-Ciocadia', value: 'Comuna Bengeşti-Ciocadia' },
  { label: 'Comuna Berca', value: 'Comuna Berca' },
  { label: 'Comuna Berceni', value: 'Comuna Berceni' },
  { label: 'Comuna Berceni', value: 'Comuna Berceni' },
  { label: 'Comuna Berchişeşti', value: 'Comuna Berchişeşti' },
  { label: 'Comuna Bereşti-Bistriţa', value: 'Comuna Bereşti-Bistriţa' },
  { label: 'Comuna Bereşti-Meria', value: 'Comuna Bereşti-Meria' },
  { label: 'Comuna Bereşti-Tazlău', value: 'Comuna Bereşti-Tazlău' },
  { label: 'Comuna Berevoeşti', value: 'Comuna Berevoeşti' },
  { label: 'Comuna Berezeni', value: 'Comuna Berezeni' },
  { label: 'Comuna Berghin', value: 'Comuna Berghin' },
  { label: 'Comuna Berislăveşti', value: 'Comuna Berislăveşti' },
  { label: 'Comuna Beriu', value: 'Comuna Beriu' },
  { label: 'Comuna Berlişte', value: 'Comuna Berlişte' },
  { label: 'Comuna Bertea', value: 'Comuna Bertea' },
  { label: 'Comuna Berteştii De Jos', value: 'Comuna Berteştii De Jos' },
  { label: 'Comuna Berveni', value: 'Comuna Berveni' },
  { label: 'Comuna Berzasca', value: 'Comuna Berzasca' },
  { label: 'Comuna Berzovia', value: 'Comuna Berzovia' },
  { label: 'Comuna Berzunţi', value: 'Comuna Berzunţi' },
  { label: 'Comuna Beştepe', value: 'Comuna Beştepe' },
  { label: 'Comuna Bethausen', value: 'Comuna Bethausen' },
  { label: 'Comuna Beuca', value: 'Comuna Beuca' },
  { label: 'Comuna Bezdead', value: 'Comuna Bezdead' },
  { label: 'Comuna Bicaz', value: 'Comuna Bicaz' },
  { label: 'Comuna Bicaz Chei', value: 'Comuna Bicaz Chei' },
  { label: 'Comuna Bicazu Ardelean', value: 'Comuna Bicazu Ardelean' },
  { label: 'Comuna Biertan', value: 'Comuna Biertan' },
  { label: 'Comuna Biharia', value: 'Comuna Biharia' },
  { label: 'Comuna Bilbor', value: 'Comuna Bilbor' },
  { label: 'Comuna Bilca', value: 'Comuna Bilca' },
  { label: 'Comuna Bilciureşti', value: 'Comuna Bilciureşti' },
  { label: 'Comuna Biled', value: 'Comuna Biled' },
  { label: 'Comuna Bilieşti', value: 'Comuna Bilieşti' },
  { label: 'Comuna Birchiş', value: 'Comuna Birchiş' },
  { label: 'Comuna Birda', value: 'Comuna Birda' },
  { label: 'Comuna Bisoca', value: 'Comuna Bisoca' },
  { label: 'Comuna Bistra', value: 'Comuna Bistra' },
  { label: 'Comuna Bistra', value: 'Comuna Bistra' },
  { label: 'Comuna Bistreţ', value: 'Comuna Bistreţ' },
  { label: 'Comuna Bistriţa Bârgăului', value: 'Comuna Bistriţa Bârgăului' },
  { label: 'Comuna Bivolari', value: 'Comuna Bivolari' },
  { label: 'Comuna Bixad', value: 'Comuna Bixad' },
  { label: 'Comuna Bixad', value: 'Comuna Bixad' },
  { label: 'Comuna Blăgeşti', value: 'Comuna Blăgeşti' },
  { label: 'Comuna Blăgeşti', value: 'Comuna Blăgeşti' },
  { label: 'Comuna Blăjani', value: 'Comuna Blăjani' },
  { label: 'Comuna Blăjel', value: 'Comuna Blăjel' },
  { label: 'Comuna Blăjeni', value: 'Comuna Blăjeni' },
  { label: 'Comuna Blândeşti', value: 'Comuna Blândeşti' },
  { label: 'Comuna Blândiana', value: 'Comuna Blândiana' },
  { label: 'Comuna Blejeşti', value: 'Comuna Blejeşti' },
  { label: 'Comuna Blejoi', value: 'Comuna Blejoi' },
  { label: 'Comuna Bobâlna', value: 'Comuna Bobâlna' },
  { label: 'Comuna Bobiceşti', value: 'Comuna Bobiceşti' },
  { label: 'Comuna Bobota', value: 'Comuna Bobota' },
  { label: 'Comuna Bocicoiu Mare', value: 'Comuna Bocicoiu Mare' },
  { label: 'Comuna Bocşa', value: 'Comuna Bocşa' },
  { label: 'Comuna Bocsig', value: 'Comuna Bocsig' },
  { label: 'Comuna Bod', value: 'Comuna Bod' },
  { label: 'Comuna Bodeşti-Precista', value: 'Comuna Bodeşti-Precista' },
  { label: 'Comuna Bodoc', value: 'Comuna Bodoc' },
  { label: 'Comuna Bogaţi', value: 'Comuna Bogaţi' },
  { label: 'Comuna Bogda', value: 'Comuna Bogda' },
  { label: 'Comuna Bogdan Vodă', value: 'Comuna Bogdan Vodă' },
  { label: 'Comuna Bogdana', value: 'Comuna Bogdana' },
  { label: 'Comuna Bogdana', value: 'Comuna Bogdana' },
  { label: 'Comuna Bogdand', value: 'Comuna Bogdand' },
  { label: 'Comuna Bogdăneşti', value: 'Comuna Bogdăneşti' },
  { label: 'Comuna Bogdăneşti', value: 'Comuna Bogdăneşti' },
  { label: 'Comuna Bogdăneşti', value: 'Comuna Bogdăneşti' },
  { label: 'Comuna Bogdăniţa', value: 'Comuna Bogdăniţa' },
  { label: 'Comuna Bogheşti', value: 'Comuna Bogheşti' },
  { label: 'Comuna Boghicea', value: 'Comuna Boghicea' },
  { label: 'Comuna Boghiş', value: 'Comuna Boghiş' },
  { label: 'Comuna Boianu Mare', value: 'Comuna Boianu Mare' },
  { label: 'Comuna Boişoara', value: 'Comuna Boişoara' },
  { label: 'Comuna Boiţa', value: 'Comuna Boiţa' },
  { label: 'Comuna Boiu Mare', value: 'Comuna Boiu Mare' },
  { label: 'Comuna Bolboşi', value: 'Comuna Bolboşi' },
  { label: 'Comuna Boldeşti-Gradiştea', value: 'Comuna Boldeşti-Gradiştea' },
  { label: 'Comuna Boldu', value: 'Comuna Boldu' },
  { label: 'Comuna Boldur', value: 'Comuna Boldur' },
  { label: 'Comuna Bolintin Deal', value: 'Comuna Bolintin Deal' },
  { label: 'Comuna Boloteşti', value: 'Comuna Boloteşti' },
  { label: 'Comuna Bolvaşniţa', value: 'Comuna Bolvaşniţa' },
  { label: 'Comuna Bonţida', value: 'Comuna Bonţida' },
  { label: 'Comuna Borăneşti', value: 'Comuna Borăneşti' },
  { label: 'Comuna Borăscu', value: 'Comuna Borăscu' },
  { label: 'Comuna Borca', value: 'Comuna Borca' },
  { label: 'Comuna Borcea', value: 'Comuna Borcea' },
  { label: 'Comuna Bordei Verde', value: 'Comuna Bordei Verde' },
  { label: 'Comuna Bordeşti', value: 'Comuna Bordeşti' },
  { label: 'Comuna Borduşani', value: 'Comuna Borduşani' },
  { label: 'Comuna Borleşti', value: 'Comuna Borleşti' },
  { label: 'Comuna Boroaia', value: 'Comuna Boroaia' },
  { label: 'Comuna Borod', value: 'Comuna Borod' },
  { label: 'Comuna Boroşneu Mare', value: 'Comuna Boroşneu Mare' },
  { label: 'Comuna Borş', value: 'Comuna Borş' },
  { label: 'Comuna Borşa', value: 'Comuna Borşa' },
  { label: 'Comuna Bosanci', value: 'Comuna Bosanci' },
  { label: 'Comuna Boşorod', value: 'Comuna Boşorod' },
  { label: 'Comuna Boteni', value: 'Comuna Boteni' },
  { label: 'Comuna Boteşti', value: 'Comuna Boteşti' },
  { label: 'Comuna Boţeşti', value: 'Comuna Boţeşti' },
  { label: 'Comuna Boţeşti', value: 'Comuna Boţeşti' },
  { label: 'Comuna Botiz', value: 'Comuna Botiz' },
  { label: 'Comuna Botiza', value: 'Comuna Botiza' },
  { label: 'Comuna Botoroaga', value: 'Comuna Botoroaga' },
  { label: 'Comuna Botoşana', value: 'Comuna Botoşana' },
  { label: 'Comuna Botoşeşti-Paia', value: 'Comuna Botoşeşti-Paia' },
  { label: 'Comuna Bozieni', value: 'Comuna Bozieni' },
  { label: 'Comuna Bozioru', value: 'Comuna Bozioru' },
  { label: 'Comuna Bozovici', value: 'Comuna Bozovici' },
  { label: 'Comuna Brabova', value: 'Comuna Brabova' },
  { label: 'Comuna Brădeanu', value: 'Comuna Brădeanu' },
  { label: 'Comuna Brădeni', value: 'Comuna Brădeni' },
  { label: 'Comuna Brădeşti', value: 'Comuna Brădeşti' },
  { label: 'Comuna Brădeşti', value: 'Comuna Brădeşti' },
  { label: 'Comuna Bradu', value: 'Comuna Bradu' },
  { label: 'Comuna Brăduleţ', value: 'Comuna Brăduleţ' },
  { label: 'Comuna Brăduţ', value: 'Comuna Brăduţ' },
  { label: 'Comuna Brăeşti', value: 'Comuna Brăeşti' },
  { label: 'Comuna Brăeşti', value: 'Comuna Brăeşti' },
  { label: 'Comuna Brăeşti', value: 'Comuna Brăeşti' },
  { label: 'Comuna Bragadiru', value: 'Comuna Bragadiru' },
  { label: 'Comuna Brăhăşeşti', value: 'Comuna Brăhăşeşti' },
  { label: 'Comuna Braloştiţa', value: 'Comuna Braloştiţa' },
  { label: 'Comuna Bran', value: 'Comuna Bran' },
  { label: 'Comuna Brânceni', value: 'Comuna Brânceni' },
  { label: 'Comuna Brâncoveni', value: 'Comuna Brâncoveni' },
  { label: 'Comuna Brăneşti', value: 'Comuna Brăneşti' },
  { label: 'Comuna Brăneşti', value: 'Comuna Brăneşti' },
  { label: 'Comuna Brăneşti', value: 'Comuna Brăneşti' },
  { label: 'Comuna Brănişca', value: 'Comuna Brănişca' },
  { label: 'Comuna Braniştea', value: 'Comuna Braniştea' },
  { label: 'Comuna Braniştea', value: 'Comuna Braniştea' },
  { label: 'Comuna Braniştea', value: 'Comuna Braniştea' },
  { label: 'Comuna Braniştea', value: 'Comuna Braniştea' },
  { label: 'Comuna Brastavăţu', value: 'Comuna Brastavăţu' },
  { label: 'Comuna Bratca', value: 'Comuna Bratca' },
  { label: 'Comuna Brateiu', value: 'Comuna Brateiu' },
  { label: 'Comuna Brateş', value: 'Comuna Brateş' },
  { label: 'Comuna Bratovoeşti', value: 'Comuna Bratovoeşti' },
  { label: 'Comuna Brazi', value: 'Comuna Brazi' },
  { label: 'Comuna Brazii', value: 'Comuna Brazii' },
  { label: 'Comuna Breasta', value: 'Comuna Breasta' },
  { label: 'Comuna Breaza', value: 'Comuna Breaza' },
  { label: 'Comuna Breaza', value: 'Comuna Breaza' },
  { label: 'Comuna Brebeni', value: 'Comuna Brebeni' },
  { label: 'Comuna Brebu', value: 'Comuna Brebu' },
  { label: 'Comuna Brebu', value: 'Comuna Brebu' },
  { label: 'Comuna Brebu Nou', value: 'Comuna Brebu Nou' },
  { label: 'Comuna Brestovăţ', value: 'Comuna Brestovăţ' },
  { label: 'Comuna Breţcu', value: 'Comuna Breţcu' },
  { label: 'Comuna Bretea Română', value: 'Comuna Bretea Română' },
  { label: 'Comuna Brezniţa Ocol', value: 'Comuna Brezniţa Ocol' },
  { label: 'Comuna Brezniţa-Motru', value: 'Comuna Brezniţa-Motru' },
  { label: 'Comuna Brezoaele', value: 'Comuna Brezoaele' },
  { label: 'Comuna Brodina', value: 'Comuna Brodina' },
  { label: 'Comuna Broscăuţi', value: 'Comuna Broscăuţi' },
  { label: 'Comuna Broşteni', value: 'Comuna Broşteni' },
  { label: 'Comuna Broşteni', value: 'Comuna Broşteni' },
  { label: 'Comuna Bruiu', value: 'Comuna Bruiu' },
  { label: 'Comuna Brusturi', value: 'Comuna Brusturi' },
  { label: 'Comuna Brusturi-Drăgăneşti', value: 'Comuna Brusturi-Drăgăneşti' },
  { label: 'Comuna Brusturoasa', value: 'Comuna Brusturoasa' },
  { label: 'Comuna Bucerdea-Grânoasă', value: 'Comuna Bucerdea-Grânoasă' },
  { label: 'Comuna Buceş', value: 'Comuna Buceş' },
  { label: 'Comuna Buchin', value: 'Comuna Buchin' },
  { label: 'Comuna Bucinişu', value: 'Comuna Bucinişu' },
  { label: 'Comuna Bucium', value: 'Comuna Bucium' },
  { label: 'Comuna Buciumeni', value: 'Comuna Buciumeni' },
  { label: 'Comuna Buciumeni', value: 'Comuna Buciumeni' },
  { label: 'Comuna Buciumi', value: 'Comuna Buciumi' },
  { label: 'Comuna Buciumi', value: 'Comuna Buciumi' },
  { label: 'Comuna Bucoşniţa', value: 'Comuna Bucoşniţa' },
  { label: 'Comuna Bucov', value: 'Comuna Bucov' },
  { label: 'Comuna Bucovăţ', value: 'Comuna Bucovăţ' },
  { label: 'Comuna Bucovăţ', value: 'Comuna Bucovăţ' },
  { label: 'Comuna Bucşani', value: 'Comuna Bucşani' },
  { label: 'Comuna Bucşani', value: 'Comuna Bucşani' },
  { label: 'Comuna Bucu', value: 'Comuna Bucu' },
  { label: 'Comuna Bucureşci', value: 'Comuna Bucureşci' },
  { label: 'Comuna Buda', value: 'Comuna Buda' },
  { label: 'Comuna Budacu De Jos', value: 'Comuna Budacu De Jos' },
  { label: 'Comuna Budeasa', value: 'Comuna Budeasa' },
  { label: 'Comuna Budeşti', value: 'Comuna Budeşti' },
  { label: 'Comuna Budeşti', value: 'Comuna Budeşti' },
  { label: 'Comuna Budeşti', value: 'Comuna Budeşti' },
  { label: 'Comuna Budila', value: 'Comuna Budila' },
  { label: 'Comuna Budureasa', value: 'Comuna Budureasa' },
  { label: 'Comuna Buduslău', value: 'Comuna Buduslău' },
  { label: 'Comuna Bueşti', value: 'Comuna Bueşti' },
  { label: 'Comuna Bughea De Jos', value: 'Comuna Bughea De Jos' },
  { label: 'Comuna Bughea De Sus', value: 'Comuna Bughea De Sus' },
  { label: 'Comuna Buhoci', value: 'Comuna Buhoci' },
  { label: 'Comuna Bujoreni', value: 'Comuna Bujoreni' },
  { label: 'Comuna Bujoreni', value: 'Comuna Bujoreni' },
  { label: 'Comuna Bujoru', value: 'Comuna Bujoru' },
  { label: 'Comuna Bulbucata', value: 'Comuna Bulbucata' },
  { label: 'Comuna Bulz', value: 'Comuna Bulz' },
  { label: 'Comuna Bulzeşti', value: 'Comuna Bulzeşti' },
  { label: 'Comuna Bulzeştii De Sus', value: 'Comuna Bulzeştii De Sus' },
  { label: 'Comuna Bumbeşti-Piţic', value: 'Comuna Bumbeşti-Piţic' },
  { label: 'Comuna Buneşti', value: 'Comuna Buneşti' },
  { label: 'Comuna Buneşti', value: 'Comuna Buneşti' },
  { label: 'Comuna Buneşti', value: 'Comuna Buneşti' },
  { label: 'Comuna Bunești Averești', value: 'Comuna Bunești Averești' },
  { label: 'Comuna Bunila', value: 'Comuna Bunila' },
  { label: 'Comuna Bunteşti', value: 'Comuna Bunteşti' },
  { label: 'Comuna Burila Mare', value: 'Comuna Burila Mare' },
  { label: 'Comuna Burjuc', value: 'Comuna Burjuc' },
  { label: 'Comuna Burla', value: 'Comuna Burla' },
  { label: 'Comuna Bustuchin', value: 'Comuna Bustuchin' },
  { label: 'Comuna Butea', value: 'Comuna Butea' },
  { label: 'Comuna Buteni', value: 'Comuna Buteni' },
  { label: 'Comuna Butimanu', value: 'Comuna Butimanu' },
  { label: 'Comuna Butoieşti', value: 'Comuna Butoieşti' },
  { label: 'Comuna Buturugeni', value: 'Comuna Buturugeni' },
  { label: 'Comuna Buza', value: 'Comuna Buza' },
  { label: 'Comuna Buzescu', value: 'Comuna Buzescu' },
  { label: 'Comuna Buzoeşti', value: 'Comuna Buzoeşti' },
  { label: 'Comuna C.A. Rosetti', value: 'Comuna C.A. Rosetti' },
  { label: 'Comuna C.A. Rosetti', value: 'Comuna C.A. Rosetti' },
  { label: 'Comuna Căbeşti', value: 'Comuna Căbeşti' },
  { label: 'Comuna Cacica', value: 'Comuna Cacica' },
  { label: 'Comuna Căianu', value: 'Comuna Căianu' },
  { label: 'Comuna Căianu', value: 'Comuna Căianu' },
  { label: 'Comuna Câineni', value: 'Comuna Câineni' },
  { label: 'Comuna Căiuţi', value: 'Comuna Căiuţi' },
  { label: 'Comuna Calafindeşti', value: 'Comuna Calafindeşti' },
  { label: 'Comuna Călăraşi', value: 'Comuna Călăraşi' },
  { label: 'Comuna Călăraşi', value: 'Comuna Călăraşi' },
  { label: 'Comuna Călăraşi', value: 'Comuna Călăraşi' },
  { label: 'Comuna Călăţele', value: 'Comuna Călăţele' },
  { label: 'Comuna Căldăraru', value: 'Comuna Căldăraru' },
  { label: 'Comuna Călineşti', value: 'Comuna Călineşti' },
  { label: 'Comuna Călineşti', value: 'Comuna Călineşti' },
  { label: 'Comuna Călineşti', value: 'Comuna Călineşti' },
  { label: 'Comuna Cãlineşti-Oaş', value: 'Comuna Cãlineşti-Oaş' },
  { label: 'Comuna Cãlmãţuiu', value: 'Comuna Cãlmãţuiu' },
  { label: 'Comuna Cãlmãţuiu De Sus', value: 'Comuna Cãlmãţuiu De Sus' },
  { label: 'Comuna Câlnic', value: 'Comuna Câlnic' },
  { label: 'Comuna Câlnic', value: 'Comuna Câlnic' },
  { label: 'Comuna Calopăru', value: 'Comuna Calopăru' },
  { label: 'Comuna Călugăreni', value: 'Comuna Călugăreni' },
  { label: 'Comuna Călugăreni', value: 'Comuna Călugăreni' },
  { label: 'Comuna Cãlui', value: 'Comuna Cãlui' },
  { label: 'Comuna Calvini', value: 'Comuna Calvini' },
  { label: 'Comuna Camăr', value: 'Comuna Camăr' },
  { label: 'Comuna Cămăraşu', value: 'Comuna Cămăraşu' },
  { label: 'Comuna Cămărzana', value: 'Comuna Cămărzana' },
  { label: 'Comuna Cãmin', value: 'Comuna Cãmin' },
  { label: 'Comuna Câmpani', value: 'Comuna Câmpani' },
  { label: 'Comuna Câmpineanca', value: 'Comuna Câmpineanca' },
  { label: 'Comuna Câmpulung La Tisa', value: 'Comuna Câmpulung La Tisa' },
  { label: 'Comuna Câmpuri', value: 'Comuna Câmpuri' },
  { label: 'Comuna Cândeşti', value: 'Comuna Cândeşti' },
  { label: 'Comuna Cândeşti', value: 'Comuna Cândeşti' },
  { label: 'Comuna Cândeşti Vale', value: 'Comuna Cândeşti Vale' },
  { label: 'Comuna Căneşti', value: 'Comuna Căneşti' },
  { label: 'Comuna Cãpâlna', value: 'Comuna Cãpâlna' },
  { label: 'Comuna Căpâlniţa', value: 'Comuna Căpâlniţa' },
  { label: 'Comuna Căpleni', value: 'Comuna Căpleni' },
  { label: 'Comuna Căpreni', value: 'Comuna Căpreni' },
  { label: 'Comuna Capu Câmpului', value: 'Comuna Capu Câmpului' },
  { label: 'Comuna Cãpuşu Mare', value: 'Comuna Cãpuşu Mare' },
  { label: 'Comuna Cărand', value: 'Comuna Cărand' },
  { label: 'Comuna Caraşova', value: 'Comuna Caraşova' },
  { label: 'Comuna Carastelec', value: 'Comuna Carastelec' },
  { label: 'Comuna Caraula', value: 'Comuna Caraula' },
  { label: 'Comuna Cărbunari', value: 'Comuna Cărbunari' },
  { label: 'Comuna Cărbuneşti', value: 'Comuna Cărbuneşti' },
  { label: 'Comuna Carcaliu', value: 'Comuna Carcaliu' },
  { label: 'Comuna Cârcea', value: 'Comuna Cârcea' },
  { label: 'Comuna Cârjiţi', value: 'Comuna Cârjiţi' },
  { label: 'Comuna Cârlibaba', value: 'Comuna Cârlibaba' },
  { label: 'Comuna Cârligele', value: 'Comuna Cârligele' },
  { label: 'Comuna Cârlogani', value: 'Comuna Cârlogani' },
  { label: 'Comuna Cârna', value: 'Comuna Cârna' },
  { label: 'Comuna Carpen', value: 'Comuna Carpen' },
  { label: 'Comuna Cărpinet', value: 'Comuna Cărpinet' },
  { label: 'Comuna Cărpiniş', value: 'Comuna Cărpiniş' },
  { label: 'Comuna Cârţa', value: 'Comuna Cârţa' },
  { label: 'Comuna Cârţa', value: 'Comuna Cârţa' },
  { label: 'Comuna Cârţişoara', value: 'Comuna Cârţişoara' },
  { label: 'Comuna Căscioarele', value: 'Comuna Căscioarele' },
  { label: 'Comuna Căşeiu', value: 'Comuna Căşeiu' },
  { label: 'Comuna Casimcea', value: 'Comuna Casimcea' },
  { label: 'Comuna Castelu', value: 'Comuna Castelu' },
  { label: 'Comuna Castranova', value: 'Comuna Castranova' },
  { label: 'Comuna Caţa', value: 'Comuna Caţa' },
  { label: 'Comuna Catalina', value: 'Comuna Catalina' },
  { label: 'Comuna Catane', value: 'Comuna Catane' },
  { label: 'Comuna Câţcău', value: 'Comuna Câţcău' },
  { label: 'Comuna Căteasca', value: 'Comuna Căteasca' },
  { label: 'Comuna Cătina', value: 'Comuna Cătina' },
  { label: 'Comuna Cătina', value: 'Comuna Cătina' },
  { label: 'Comuna Cătunele', value: 'Comuna Cătunele' },
  { label: 'Comuna Căuaş', value: 'Comuna Căuaş' },
  { label: 'Comuna Cavadineşti', value: 'Comuna Cavadineşti' },
  { label: 'Comuna Căzăneşti', value: 'Comuna Căzăneşti' },
  { label: 'Comuna Cazasu', value: 'Comuna Cazasu' },
  { label: 'Comuna Ceahlău', value: 'Comuna Ceahlău' },
  { label: 'Comuna Ceamurlia De Jos', value: 'Comuna Ceamurlia De Jos' },
  { label: 'Comuna Ceanu Mare', value: 'Comuna Ceanu Mare' },
  { label: 'Comuna Ceatalchioi', value: 'Comuna Ceatalchioi' },
  { label: 'Comuna Cefa', value: 'Comuna Cefa' },
  { label: 'Comuna Cehal', value: 'Comuna Cehal' },
  { label: 'Comuna Cehu Silvaniei', value: 'Comuna Cehu Silvaniei' },
  { label: 'Comuna Ceica', value: 'Comuna Ceica' },
  { label: 'Comuna Celaru', value: 'Comuna Celaru' },
  { label: 'Comuna Cenad', value: 'Comuna Cenad' },
  { label: 'Comuna Cenade', value: 'Comuna Cenade' },
  { label: 'Comuna Cenei', value: 'Comuna Cenei' },
  { label: 'Comuna Cepari', value: 'Comuna Cepari' },
  { label: 'Comuna Cepleniţa', value: 'Comuna Cepleniţa' },
  { label: 'Comuna Ceptura', value: 'Comuna Ceptura' },
  { label: 'Comuna Ceraşu', value: 'Comuna Ceraşu' },
  { label: 'Comuna Cerãt', value: 'Comuna Cerãt' },
  { label: 'Comuna Cerbăl', value: 'Comuna Cerbăl' },
  { label: 'Comuna Cerchezu', value: 'Comuna Cerchezu' },
  { label: 'Comuna Cergău', value: 'Comuna Cergău' },
  { label: 'Comuna Cermei', value: 'Comuna Cermei' },
  { label: 'Comuna Cerna', value: 'Comuna Cerna' },
  { label: 'Comună Cernat', value: 'Comună Cernat' },
  { label: 'Comuna Cernăteşti', value: 'Comuna Cernăteşti' },
  { label: 'Comuna Cernăteşti', value: 'Comuna Cernăteşti' },
  { label: 'Comuna Cerneşti', value: 'Comuna Cerneşti' },
  { label: 'Comuna Cernica', value: 'Comuna Cernica' },
  { label: 'Comuna Cernişoara', value: 'Comuna Cernişoara' },
  { label: 'Comuna Certeju De Sus', value: 'Comuna Certeju De Sus' },
  { label: 'Comuna Cerţeşti', value: 'Comuna Cerţeşti' },
  { label: 'Comuna Certeze', value: 'Comuna Certeze' },
  { label: 'Comuna Ceru-Băcăinţi', value: 'Comuna Ceru-Băcăinţi' },
  { label: 'Comuna Cervenia', value: 'Comuna Cervenia' },
  { label: 'Comuna Cetariu', value: 'Comuna Cetariu' },
  { label: 'Comuna Cetate', value: 'Comuna Cetate' },
  { label: 'Comuna Cetatea De Baltă', value: 'Comuna Cetatea De Baltă' },
  { label: 'Comuna Cetăţeni', value: 'Comuna Cetăţeni' },
  { label: 'Comuna Cezieni', value: 'Comuna Cezieni' },
  { label: 'Comuna Checea', value: 'Comuna Checea' },
  { label: 'Comuna Cherechiu', value: 'Comuna Cherechiu' },
  { label: 'Comuna Chevereşu Mare', value: 'Comuna Chevereşu Mare' },
  { label: 'Comuna Chiajna', value: 'Comuna Chiajna' },
  { label: 'Comuna Chichiş', value: 'Comuna Chichiş' },
  { label: 'Comuna Chieşd', value: 'Comuna Chieşd' },
  { label: 'Comuna Chilia Veche', value: 'Comuna Chilia Veche' },
  { label: 'Comuna Chiliile', value: 'Comuna Chiliile' },
  { label: 'Comuna Chinteni', value: 'Comuna Chinteni' },
  { label: 'Comuna Chiochiş', value: 'Comuna Chiochiş' },
  { label: 'Comuna Chiojdeanca', value: 'Comuna Chiojdeanca' },
  { label: 'Comuna Chiojdeni', value: 'Comuna Chiojdeni' },
  { label: 'Comuna Chiojdu', value: 'Comuna Chiojdu' },
  { label: 'Comuna Chirnogeni', value: 'Comuna Chirnogeni' },
  { label: 'Comuna Chirnogi', value: 'Comuna Chirnogi' },
  { label: 'Comuna Chirpăr', value: 'Comuna Chirpăr' },
  { label: 'Comuna Chiscani', value: 'Comuna Chiscani' },
  { label: 'Comuna Chiselet', value: 'Comuna Chiselet' },
  { label: 'Comuna Chisindia', value: 'Comuna Chisindia' },
  { label: 'Comuna Chişlaz', value: 'Comuna Chişlaz' },
  { label: 'Comuna Chiuieşti', value: 'Comuna Chiuieşti' },
  { label: 'Comuna Chiuza', value: 'Comuna Chiuza' },
  { label: 'Comuna Cicănești', value: 'Comuna Cicănești' },
  { label: 'Comuna Cicârlău', value: 'Comuna Cicârlău' },
  { label: 'Comuna Ciceu', value: 'Comuna Ciceu' },
  { label: 'Comuna Ciceu-Giurgeşti', value: 'Comuna Ciceu-Giurgeşti' },
  { label: 'Comuna Ciceu-Mihăieşti', value: 'Comuna Ciceu-Mihăieşti' },
  { label: 'Comuna Ciclova Română', value: 'Comuna Ciclova Română' },
  { label: 'Comuna Cilibia', value: 'Comuna Cilibia' },
  { label: 'Comuna Cilieni', value: 'Comuna Cilieni' },
  { label: 'Comuna Cincu', value: 'Comuna Cincu' },
  { label: 'Comuna Ciobanu', value: 'Comuna Ciobanu' },
  { label: 'Comuna Ciocăneşti', value: 'Comuna Ciocăneşti' },
  { label: 'Comuna Ciocăneşti', value: 'Comuna Ciocăneşti' },
  { label: 'Comuna Ciocăneşti', value: 'Comuna Ciocăneşti' },
  { label: 'Comuna Ciocani', value: 'Comuna Ciocani' },
  { label: 'Comuna Ciocârlia', value: 'Comuna Ciocârlia' },
  { label: 'Comuna Ciocârlia', value: 'Comuna Ciocârlia' },
  { label: 'Comuna Ciochina', value: 'Comuna Ciochina' },
  { label: 'Comuna Ciocile', value: 'Comuna Ciocile' },
  { label: 'Comuna Ciofrângeni', value: 'Comuna Ciofrângeni' },
  { label: 'Comuna Ciohorãni', value: 'Comuna Ciohorãni' },
  { label: 'Comuna Ciolăneşti', value: 'Comuna Ciolăneşti' },
  { label: 'Comuna Ciolpani', value: 'Comuna Ciolpani' },
  { label: 'Comuna Ciomăgeşti', value: 'Comuna Ciomăgeşti' },
  { label: 'Comuna Ciorani', value: 'Comuna Ciorani' },
  { label: 'Comuna Ciorăşti', value: 'Comuna Ciorăşti' },
  { label: 'Comuna Ciorogârla', value: 'Comuna Ciorogârla' },
  { label: 'Comuna Cioroiaşi', value: 'Comuna Cioroiaşi' },
  { label: 'Comuna Ciorteşti', value: 'Comuna Ciorteşti' },
  { label: 'Comuna Ciprian Porumbescu', value: 'Comuna Ciprian Porumbescu' },
  { label: 'Comuna Cireşu', value: 'Comuna Cireşu' },
  { label: 'Comuna Cireşu', value: 'Comuna Cireşu' },
  { label: 'Comuna Cislău', value: 'Comuna Cislău' },
  { label: 'Comuna Ciucea', value: 'Comuna Ciucea' },
  { label: 'Comuna Ciuchici', value: 'Comuna Ciuchici' },
  { label: 'Comuna Ciucsângeorgiu', value: 'Comuna Ciucsângeorgiu' },
  { label: 'Comuna Ciucurova', value: 'Comuna Ciucurova' },
  { label: 'Comuna Ciudanoviţa', value: 'Comuna Ciudanoviţa' },
  { label: 'Comuna Ciugud', value: 'Comuna Ciugud' },
  { label: 'Comuna Ciulniţa', value: 'Comuna Ciulniţa' },
  { label: 'Comuna Ciumani', value: 'Comuna Ciumani' },
  { label: 'Comuna Ciumeghiu', value: 'Comuna Ciumeghiu' },
  { label: 'Comuna Ciumeşti', value: 'Comuna Ciumeşti' },
  { label: 'Comuna Ciuperceni', value: 'Comuna Ciuperceni' },
  { label: 'Comuna Ciuperceni', value: 'Comuna Ciuperceni' },
  { label: 'Comuna Ciupercenii Noi', value: 'Comuna Ciupercenii Noi' },
  { label: 'Comuna Ciurea', value: 'Comuna Ciurea' },
  { label: 'Comuna Ciurila', value: 'Comuna Ciurila' },
  { label: 'Comuna Ciuruleasa', value: 'Comuna Ciuruleasa' },
  { label: 'Comuna Cizer', value: 'Comuna Cizer' },
  { label: 'Comuna Cleja', value: 'Comuna Cleja' },
  { label: 'Comuna Clejani', value: 'Comuna Clejani' },
  { label: 'Comuna Clinceni', value: 'Comuna Clinceni' },
  { label: 'Comuna Coarnele Caprei', value: 'Comuna Coarnele Caprei' },
  { label: 'Comuna Coaş', value: 'Comuna Coaş' },
  { label: 'Comuna Cobadin', value: 'Comuna Cobadin' },
  { label: 'Comuna Cobia De Sus', value: 'Comuna Cobia De Sus' },
  { label: 'Comuna Cochirleanca', value: 'Comuna Cochirleanca' },
  { label: 'Comuna Cociuba Mare', value: 'Comuna Cociuba Mare' },
  { label: 'Comuna Cocora', value: 'Comuna Cocora' },
  { label: 'Comuna Cocorãştii Mislii', value: 'Comuna Cocorãştii Mislii' },
  { label: 'Comuna Cocorăştii-Colţ', value: 'Comuna Cocorăştii-Colţ' },
  { label: 'Comuna Cocu', value: 'Comuna Cocu' },
  { label: 'Comuna Codăeşti', value: 'Comuna Codăeşti' },
  { label: 'Comuna Cogealac', value: 'Comuna Cogealac' },
  { label: 'Comuna Cojasca', value: 'Comuna Cojasca' },
  { label: 'Comuna Cojocna', value: 'Comuna Cojocna' },
  { label: 'Comuna Colceag', value: 'Comuna Colceag' },
  { label: 'Comuna Colelia', value: 'Comuna Colelia' },
  { label: 'Comuna Colibaşi', value: 'Comuna Colibaşi' },
  { label: 'Comuna Coloneşti', value: 'Comuna Coloneşti' },
  { label: 'Comuna Coloneşti', value: 'Comuna Coloneşti' },
  { label: 'Comuna Coltãu', value: 'Comuna Coltãu' },
  { label: 'Comuna Colţi', value: 'Comuna Colţi' },
  { label: 'Comuna Comana', value: 'Comuna Comana' },
  { label: 'Comuna Comana', value: 'Comuna Comana' },
  { label: 'Comuna Comana', value: 'Comuna Comana' },
  { label: 'Comuna Comandău', value: 'Comuna Comandău' },
  { label: 'Comuna Comăneşti', value: 'Comuna Comăneşti' },
  { label: 'Comuna Comarna', value: 'Comuna Comarna' },
  { label: 'Comuna Comişani', value: 'Comuna Comişani' },
  { label: 'Comuna Comloşu Mare', value: 'Comuna Comloşu Mare' },
  { label: 'Comuna Conceşti', value: 'Comuna Conceşti' },
  { label: 'Comuna Conop', value: 'Comuna Conop' },
  { label: 'Comuna Constantin Daicoviciu', value: 'Comuna Constantin Daicoviciu' },
  { label: 'Comuna Conţeşti', value: 'Comuna Conţeşti' },
  { label: 'Comuna Conţeşti', value: 'Comuna Conţeşti' },
  { label: 'Comuna Copăcel', value: 'Comuna Copăcel' },
  { label: 'Comuna Copăcele', value: 'Comuna Copăcele' },
  { label: 'Comuna Copăceni', value: 'Comuna Copăceni' },
  { label: 'Comuna Copăceni', value: 'Comuna Copăceni' },
  { label: 'Comuna Copălău', value: 'Comuna Copălău' },
  { label: 'Comuna Copalnic Mănăştur', value: 'Comuna Copalnic Mănăştur' },
  { label: 'Comuna Corbasca', value: 'Comuna Corbasca' },
  { label: 'Comuna Corbeanca', value: 'Comuna Corbeanca' },
  { label: 'Comuna Corbeni', value: 'Comuna Corbeni' },
  { label: 'Comuna Corbi', value: 'Comuna Corbi' },
  { label: 'Comuna Corbii Mari', value: 'Comuna Corbii Mari' },
  { label: 'Comuna Corbiţa', value: 'Comuna Corbiţa' },
  { label: 'Comuna Corbu', value: 'Comuna Corbu' },
  { label: 'Comuna Corbu', value: 'Comuna Corbu' },
  { label: 'Comuna Corbu', value: 'Comuna Corbu' },
  { label: 'Comuna Corcova', value: 'Comuna Corcova' },
  { label: 'Comună Cordăreni', value: 'Comună Cordăreni' },
  { label: 'Comuna Cordun', value: 'Comuna Cordun' },
  { label: 'Comuna Corlăţel', value: 'Comuna Corlăţel' },
  { label: 'Comuna Corlăteni', value: 'Comuna Corlăteni' },
  { label: 'Comuna Cornăţelu', value: 'Comuna Cornăţelu' },
  { label: 'Comuna Cornea', value: 'Comuna Cornea' },
  { label: 'Comuna Cornereva', value: 'Comuna Cornereva' },
  { label: 'Comuna Corneşti', value: 'Comuna Corneşti' },
  { label: 'Comuna Corneşti', value: 'Comuna Corneşti' },
  { label: 'Comuna Cornetu', value: 'Comuna Cornetu' },
  { label: 'Comuna Corni', value: 'Comuna Corni' },
  { label: 'Comuna Corni', value: 'Comuna Corni' },
  { label: 'Comuna Cornu', value: 'Comuna Cornu' },
  { label: 'Comuna Cornu Luncii', value: 'Comuna Cornu Luncii' },
  { label: 'Comuna Corod', value: 'Comuna Corod' },
  { label: 'Comuna Coroieni', value: 'Comuna Coroieni' },
  { label: 'Comuna Coroieşti', value: 'Comuna Coroieşti' },
  { label: 'Comuna Coroisânmartin', value: 'Comuna Coroisânmartin' },
  { label: 'Comuna Corund', value: 'Comuna Corund' },
  { label: 'Comuna Cosâmbeşti', value: 'Comuna Cosâmbeşti' },
  { label: 'Comuna Coşbuc', value: 'Comuna Coşbuc' },
  { label: 'Comuna Coşeiu', value: 'Comuna Coşeiu' },
  { label: 'Comuna Coşereni', value: 'Comuna Coşereni' },
  { label: 'Comuna Coşeşti', value: 'Comuna Coşeşti' },
  { label: 'Comuna Cosmeşti', value: 'Comuna Cosmeşti' },
  { label: 'Comuna Cosmeşti', value: 'Comuna Cosmeşti' },
  { label: 'Comuna Cosminele', value: 'Comuna Cosminele' },
  { label: 'Comuna Coşna', value: 'Comuna Coşna' },
  { label: 'Comuna Cosoba', value: 'Comuna Cosoba' },
  { label: 'Comuna Coşoveni', value: 'Comuna Coşoveni' },
  { label: 'Comuna Costache Negri', value: 'Comuna Costache Negri' },
  { label: 'Comuna Coşteiu', value: 'Comuna Coşteiu' },
  { label: 'Comuna Costeşti', value: 'Comuna Costeşti' },
  { label: 'Comuna Costeşti', value: 'Comuna Costeşti' },
  { label: 'Comuna Costeşti', value: 'Comuna Costeşti' },
  { label: 'Comuna Costeşti', value: 'Comuna Costeşti' },
  { label: 'Comuna Costeştii Din Vale', value: 'Comuna Costeştii Din Vale' },
  { label: 'Comuna Costineşti', value: 'Comuna Costineşti' },
  { label: 'Comuna Costişa', value: 'Comuna Costişa' },
  { label: 'Comuna Costuleni', value: 'Comuna Costuleni' },
  { label: 'Comuna Coşula', value: 'Comuna Coşula' },
  { label: 'Comuna Coteana', value: 'Comuna Coteana' },
  { label: 'Comuna Coteşti', value: 'Comuna Coteşti' },
  { label: 'Comuna Cotmeana', value: 'Comuna Cotmeana' },
  { label: 'Comuna Cotnari', value: 'Comuna Cotnari' },
  { label: 'Comuna Coţofăneşti', value: 'Comuna Coţofăneşti' },
  { label: 'Comuna Coţofenii Din Dos', value: 'Comuna Coţofenii Din Dos' },
  { label: 'Comuna Coţofenii Din Faţă', value: 'Comuna Coţofenii Din Faţă' },
  { label: 'Comuna Coţuşca', value: 'Comuna Coţuşca' },
  { label: 'Comuna Covăsinţ', value: 'Comuna Covăsinţ' },
  { label: 'Comuna Cozieni', value: 'Comuna Cozieni' },
  { label: 'Comuna Cozmeni', value: 'Comuna Cozmeni' },
  { label: 'Comuna Cozmeşti', value: 'Comuna Cozmeşti' },
  { label: 'Comuna Cozmeşti', value: 'Comuna Cozmeşti' },
  { label: 'Comuna Crăcăoani', value: 'Comuna Crăcăoani' },
  { label: 'Comuna Crăciunelu De Jos', value: 'Comuna Crăciunelu De Jos' },
  { label: 'Comuna Craidorolţ', value: 'Comuna Craidorolţ' },
  { label: 'Comuna Craiva', value: 'Comuna Craiva' },
  { label: 'Comuna Crâmpoia', value: 'Comuna Crâmpoia' },
  { label: 'Comuna Crângeni', value: 'Comuna Crângeni' },
  { label: 'Comuna Crângu', value: 'Comuna Crângu' },
  { label: 'Comuna Crângurile', value: 'Comuna Crângurile' },
  { label: 'Comuna Crasna', value: 'Comuna Crasna' },
  { label: 'Comuna Crasna', value: 'Comuna Crasna' },
  { label: 'Comuna Creaca', value: 'Comuna Creaca' },
  { label: 'Comuna Creţeni', value: 'Comuna Creţeni' },
  { label: 'Comuna Creţeşti', value: 'Comuna Creţeşti' },
  { label: 'Comuna Crevedia', value: 'Comuna Crevedia' },
  { label: 'Comuna Crevedia Mare', value: 'Comuna Crevedia Mare' },
  { label: 'Comuna Crevenicu', value: 'Comuna Crevenicu' },
  { label: 'Comuna Cricău', value: 'Comuna Cricău' },
  { label: 'Comuna Criciova', value: 'Comuna Criciova' },
  { label: 'Comuna Crişan', value: 'Comuna Crişan' },
  { label: 'Comuna Crişcior', value: 'Comuna Crişcior' },
  { label: 'Comuna Crişeni', value: 'Comuna Crişeni' },
  { label: 'Comuna Cristeşti', value: 'Comuna Cristeşti' },
  { label: 'Comuna Cristeşti', value: 'Comuna Cristeşti' },
  { label: 'Comuna Cristian', value: 'Comuna Cristian' },
  { label: 'Comuna Cristian', value: 'Comuna Cristian' },
  { label: 'Comuna Cristineşti', value: 'Comuna Cristineşti' },
  { label: 'Comuna Criștioru De Jos', value: 'Comuna Criștioru De Jos' },
  { label: 'Comuna Cristolţ', value: 'Comuna Cristolţ' },
  { label: 'Comuna Crivăţ', value: 'Comuna Crivăţ' },
  { label: 'Comuna Crizbav', value: 'Comuna Crizbav' },
  { label: 'Comuna Crucea', value: 'Comuna Crucea' },
  { label: 'Comuna Crucea', value: 'Comuna Crucea' },
  { label: 'Comuna Crucişor', value: 'Comuna Crucişor' },
  { label: 'Comuna Crușeț', value: 'Comuna Crușeț' },
  { label: 'Comuna Cuca', value: 'Comuna Cuca' },
  { label: 'Comuna Cuca', value: 'Comuna Cuca' },
  { label: 'Comuna Cucuteni', value: 'Comuna Cucuteni' },
  { label: 'Comuna Cudalbi', value: 'Comuna Cudalbi' },
  { label: 'Comuna Cujmir', value: 'Comuna Cujmir' },
  { label: 'Comuna Culciu', value: 'Comuna Culciu' },
  { label: 'Comuna Cumpăna', value: 'Comuna Cumpăna' },
  { label: 'Comuna Cungrea', value: 'Comuna Cungrea' },
  { label: 'Comuna Cupşeni', value: 'Comuna Cupşeni' },
  { label: 'Comuna Curăţele', value: 'Comuna Curăţele' },
  { label: 'Comuna Curcani', value: 'Comuna Curcani' },
  { label: 'Comuna Curtea', value: 'Comuna Curtea' },
  { label: 'Comuna Curteşti', value: 'Comuna Curteşti' },
  { label: 'Comuna Curtişoara', value: 'Comuna Curtişoara' },
  { label: 'Comuna Curtuişeni', value: 'Comuna Curtuişeni' },
  { label: 'Comuna Cut', value: 'Comuna Cut' },
  { label: 'Comuna Cuza Voda', value: 'Comuna Cuza Voda' },
  { label: 'Comuna Cuza Vodă', value: 'Comuna Cuza Vodă' },
  { label: 'Comuna Cuza Vodă', value: 'Comuna Cuza Vodă' },
  { label: 'Comuna Cuzăplac', value: 'Comuna Cuzăplac' },
  { label: 'Comuna Cuzdrioara', value: 'Comuna Cuzdrioara' },
  { label: 'Comuna Dăbâca', value: 'Comuna Dăbâca' },
  { label: 'Comuna Dăeni', value: 'Comuna Dăeni' },
  { label: 'Comuna Dăeşti', value: 'Comuna Dăeşti' },
  { label: 'Comuna Dagâţa', value: 'Comuna Dagâţa' },
  { label: 'Comuna Daia', value: 'Comuna Daia' },
  { label: 'Comuna Daia Română', value: 'Comuna Daia Română' },
  { label: 'Comuna Dalboşeţ', value: 'Comuna Dalboşeţ' },
  { label: 'Comuna Dalnic', value: 'Comuna Dalnic' },
  { label: 'Comuna Dâmbovicioara', value: 'Comuna Dâmbovicioara' },
  { label: 'Comuna Dămieneşti', value: 'Comuna Dămieneşti' },
  { label: 'Comuna Dămuc', value: 'Comuna Dămuc' },
  { label: 'Comuna Dănciuleşti', value: 'Comuna Dănciuleşti' },
  { label: 'Comuna Dăneasa', value: 'Comuna Dăneasa' },
  { label: 'Comuna Dăneşti', value: 'Comuna Dăneşti' },
  { label: 'Comuna Dăneşti', value: 'Comuna Dăneşti' },
  { label: 'Comuna Dăneşti', value: 'Comuna Dăneşti' },
  { label: 'Comuna Dăneţi', value: 'Comuna Dăneţi' },
  { label: 'Comuna Dângeni', value: 'Comuna Dângeni' },
  { label: 'Comuna Dănicei', value: 'Comuna Dănicei' },
  { label: 'Comuna Dărăşti-Ilfov', value: 'Comuna Dărăşti-Ilfov' },
  { label: 'Comuna Dârjiu', value: 'Comuna Dârjiu' },
  { label: 'Comuna Dârlos', value: 'Comuna Dârlos' },
  { label: 'Comuna Dărmăneşti', value: 'Comuna Dărmăneşti' },
  { label: 'Comuna Dărmăneşti', value: 'Comuna Dărmăneşti' },
  { label: 'Comuna Dărmăneşti', value: 'Comuna Dărmăneşti' },
  { label: 'Comuna Darova', value: 'Comuna Darova' },
  { label: 'Comuna Dârvari', value: 'Comuna Dârvari' },
  { label: 'Comuna Dascălu Creaţa', value: 'Comuna Dascălu Creaţa' },
  { label: 'Comuna Davideşti', value: 'Comuna Davideşti' },
  { label: 'Comuna Dealu', value: 'Comuna Dealu' },
  { label: 'Comuna Dealu Morii', value: 'Comuna Dealu Morii' },
  { label: 'Comuna Deleni', value: 'Comuna Deleni' },
  { label: 'Comuna Deleni', value: 'Comuna Deleni' },
  { label: 'Comuna Deleni', value: 'Comuna Deleni' },
  { label: 'Comuna Deleşti', value: 'Comuna Deleşti' },
  { label: 'Comuna Densuş', value: 'Comuna Densuş' },
  { label: 'Comuna Denta', value: 'Comuna Denta' },
  { label: 'Comuna Derna', value: 'Comuna Derna' },
  { label: 'Comuna Dersca', value: 'Comuna Dersca' },
  { label: 'Comuna Desa', value: 'Comuna Desa' },
  { label: 'Comuna Deseşti', value: 'Comuna Deseşti' },
  { label: 'Comuna Devesel', value: 'Comuna Devesel' },
  { label: 'Comuna Deveselu', value: 'Comuna Deveselu' },
  { label: 'Comuna Dezna', value: 'Comuna Dezna' },
  { label: 'Comuna Dichiseni', value: 'Comuna Dichiseni' },
  { label: 'Comuna Diculeşti', value: 'Comuna Diculeşti' },
  { label: 'Comuna Dideşti', value: 'Comuna Dideşti' },
  { label: 'Comuna Dieci', value: 'Comuna Dieci' },
  { label: 'Comuna Dimăcheni', value: 'Comuna Dimăcheni' },
  { label: 'Comuna Dimitrie Cantemir', value: 'Comuna Dimitrie Cantemir' },
  { label: 'Comuna Diosig', value: 'Comuna Diosig' },
  { label: 'Comuna Dioşti', value: 'Comuna Dioşti' },
  { label: 'Comuna Ditrău', value: 'Comuna Ditrău' },
  { label: 'Comuna Doba', value: 'Comuna Doba' },
  { label: 'Comuna Dobârceni', value: 'Comuna Dobârceni' },
  { label: 'Comuna Dobârlău', value: 'Comuna Dobârlău' },
  { label: 'Comuna Dobra', value: 'Comuna Dobra' },
  { label: 'Comuna Dobra', value: 'Comuna Dobra' },
  { label: 'Comuna Dobreni', value: 'Comuna Dobreni' },
  { label: 'Comuna Dobreşti', value: 'Comuna Dobreşti' },
  { label: 'Comuna Dobreşti', value: 'Comuna Dobreşti' },
  { label: 'Comuna Dobreşti', value: 'Comuna Dobreşti' },
  { label: 'Comuna Dobreţu', value: 'Comuna Dobreţu' },
  { label: 'Comuna Dobrin', value: 'Comuna Dobrin' },
  { label: 'Comună Dobromir', value: 'Comună Dobromir' },
  { label: 'Comuna Dobrosloveni', value: 'Comuna Dobrosloveni' },
  { label: 'Comuna Dobroteasa', value: 'Comuna Dobroteasa' },
  { label: 'Comuna Dobroteşti', value: 'Comuna Dobroteşti' },
  { label: 'Comuna Dobroteşti', value: 'Comuna Dobroteşti' },
  { label: 'Comuna Dobrovăţ', value: 'Comuna Dobrovăţ' },
  { label: 'Comuna Dobrun', value: 'Comuna Dobrun' },
  { label: 'Comuna Dochia', value: 'Comuna Dochia' },
  { label: 'Comuna Doclin', value: 'Comuna Doclin' },
  { label: 'Comuna Dodeşti', value: 'Comuna Dodeşti' },
  { label: 'Comuna Dofteana', value: 'Comuna Dofteana' },
  { label: 'Comuna Dognecea', value: 'Comuna Dognecea' },
  { label: 'Comuna Doiceşti', value: 'Comuna Doiceşti' },
  { label: 'Comuna Dolheşti', value: 'Comuna Dolheşti' },
  { label: 'Comuna Dolheşti', value: 'Comuna Dolheşti' },
  { label: 'Comuna Doljeşti', value: 'Comuna Doljeşti' },
  { label: 'Comuna Domaşnea', value: 'Comuna Domaşnea' },
  { label: 'Comuna Domneşti', value: 'Comuna Domneşti' },
  { label: 'Comuna Domneşti', value: 'Comuna Domneşti' },
  { label: 'Comuna Dor Mărunt', value: 'Comuna Dor Mărunt' },
  { label: 'Comuna Dorna Cândrenilor', value: 'Comuna Dorna Cândrenilor' },
  { label: 'Comuna Dorna-Arini', value: 'Comuna Dorna-Arini' },
  { label: 'Comuna Dorneşti', value: 'Comuna Dorneşti' },
  { label: 'Comuna Dorobanți', value: 'Comuna Dorobanți' },
  { label: 'Comuna Dorobanţu', value: 'Comuna Dorobanţu' },
  { label: 'Comuna Dorobanţu', value: 'Comuna Dorobanţu' },
  { label: 'Comuna Dorolţ', value: 'Comuna Dorolţ' },
  { label: 'Comuna Doştat', value: 'Comuna Doştat' },
  { label: 'Comuna Dracea', value: 'Comuna Dracea' },
  { label: 'Comuna Drãcşenei', value: 'Comuna Drãcşenei' },
  { label: 'Comuna Dragalina', value: 'Comuna Dragalina' },
  { label: 'Comuna Drăgăneşti', value: 'Comuna Drăgăneşti' },
  { label: 'Comuna Drăgăneşti', value: 'Comuna Drăgăneşti' },
  { label: 'Comuna Drăgăneşti', value: 'Comuna Drăgăneşti' },
  { label: 'Comuna Drăgăneşti', value: 'Comuna Drăgăneşti' },
  { label: 'Comuna Drăgăneşti De Vede', value: 'Comuna Drăgăneşti De Vede' },
  { label: 'Comuna Drăgăneşti-Vlaşca', value: 'Comuna Drăgăneşti-Vlaşca' },
  { label: 'Comuna Drăganu', value: 'Comuna Drăganu' },
  { label: 'Comuna Drăgeşti', value: 'Comuna Drăgeşti' },
  { label: 'Comuna Drăghiceni', value: 'Comuna Drăghiceni' },
  { label: 'Comuna Dragodana', value: 'Comuna Dragodana' },
  { label: 'Comuna Drăgoeşti', value: 'Comuna Drăgoeşti' },
  { label: 'Comuna Drăgoeşti', value: 'Comuna Drăgoeşti' },
  { label: 'Comuna Drãgoieşti', value: 'Comuna Drãgoieşti' },
  { label: 'Comuna Dragomireşti', value: 'Comuna Dragomireşti' },
  { label: 'Comuna Dragomireşti', value: 'Comuna Dragomireşti' },
  { label: 'Comuna Dragomireşti', value: 'Comuna Dragomireşti' },
  { label: 'Comuna Dragomireşti-Vale', value: 'Comuna Dragomireşti-Vale' },
  { label: 'Comuna Dragoş Vodă', value: 'Comuna Dragoş Vodă' },
  { label: 'Comuna Dragoslavele', value: 'Comuna Dragoslavele' },
  { label: 'Comuna Drăgoteşti', value: 'Comuna Drăgoteşti' },
  { label: 'Comuna Drăgoteşti', value: 'Comuna Drăgoteşti' },
  { label: 'Comuna Dragu', value: 'Comuna Dragu' },
  { label: 'Comuna Drăguş', value: 'Comuna Drăguş' },
  { label: 'Comuna Drăguşeni', value: 'Comuna Drăguşeni' },
  { label: 'Comuna Drăguşeni', value: 'Comuna Drăguşeni' },
  { label: 'Comuna Drăguşeni', value: 'Comuna Drăguşeni' },
  { label: 'Comuna Drăguşeni', value: 'Comuna Drăguşeni' },
  { label: 'Comuna Drãguţeşti', value: 'Comuna Drãguţeşti' },
  { label: 'Comuna Drajna De Sus', value: 'Comuna Drajna De Sus' },
  { label: 'Comuna Drânceni', value: 'Comuna Drânceni' },
  { label: 'Comuna Drãnic', value: 'Comuna Drãnic' },
  { label: 'Comuna Dridu', value: 'Comuna Dridu' },
  { label: 'Comuna Dubova', value: 'Comuna Dubova' },
  { label: 'Comuna Duda  Epureni', value: 'Comuna Duda  Epureni' },
  { label: 'Comuna Dudeşti', value: 'Comuna Dudeşti' },
  { label: 'Comuna Dudeştii Noi', value: 'Comuna Dudeştii Noi' },
  { label: 'Comuna Dudeştii Vechi', value: 'Comuna Dudeştii Vechi' },
  { label: 'Comuna Dulceşti', value: 'Comuna Dulceşti' },
  { label: 'Comuna Dumbrava', value: 'Comuna Dumbrava' },
  { label: 'Comuna Dumbrava', value: 'Comuna Dumbrava' },
  { label: 'Comuna Dumbrava', value: 'Comuna Dumbrava' },
  { label: 'Comuna Dumbrava Roşie', value: 'Comuna Dumbrava Roşie' },
  { label: 'Comuna Dumbrăveni', value: 'Comuna Dumbrăveni' },
  { label: 'Comuna Dumbrăveni', value: 'Comuna Dumbrăveni' },
  { label: 'Comună Dumbrăveni', value: 'Comună Dumbrăveni' },
  { label: 'Comuna Dumbrãveşti', value: 'Comuna Dumbrãveşti' },
  { label: 'Comuna Dumbrăviţa', value: 'Comuna Dumbrăviţa' },
  { label: 'Comuna Dumbrăviţa', value: 'Comuna Dumbrăviţa' },
  { label: 'Comuna Dumbrăviţa', value: 'Comuna Dumbrăviţa' },
  { label: 'Comuna Dumeşti', value: 'Comuna Dumeşti' },
  { label: 'Comuna Dumeşti', value: 'Comuna Dumeşti' },
  { label: 'Comuna Dumitra', value: 'Comuna Dumitra' },
  { label: 'Comuna Dumitreşti', value: 'Comuna Dumitreşti' },
  { label: 'Comuna Dumitriţa', value: 'Comuna Dumitriţa' },
  { label: 'Comuna Durneşti', value: 'Comuna Durneşti' },
  { label: 'Comuna Eftimie Murgu', value: 'Comuna Eftimie Murgu' },
  { label: 'Comuna Epureni', value: 'Comuna Epureni' },
  { label: 'Comuna Erbiceni', value: 'Comuna Erbiceni' },
  { label: 'Comuna Eşelniţa', value: 'Comuna Eşelniţa' },
  { label: 'Comuna Estelnic', value: 'Comuna Estelnic' },
  { label: 'Comuna Ezeriş', value: 'Comuna Ezeriş' },
  { label: 'Comuna Făcăeni', value: 'Comuna Făcăeni' },
  { label: 'Comuna Făgeţelu', value: 'Comuna Făgeţelu' },
  { label: 'Comuna Fălciu', value: 'Comuna Fălciu' },
  { label: 'Comuna Fălcoiu', value: 'Comuna Fălcoiu' },
  { label: 'Comuna Fântâna Mare', value: 'Comuna Fântâna Mare' },
  { label: 'Comuna Fântânele', value: 'Comuna Fântânele' },
  { label: 'Comuna Fântânele', value: 'Comuna Fântânele' },
  { label: 'Comuna Fântânele', value: 'Comuna Fântânele' },
  { label: 'Comuna Fântânele', value: 'Comuna Fântânele' },
  { label: 'Comuna Fântânele', value: 'Comuna Fântânele' },
  { label: 'Comuna Fântânele', value: 'Comuna Fântânele' },
  { label: 'Comuna Faraoani', value: 'Comuna Faraoani' },
  { label: 'Comuna Fărău', value: 'Comuna Fărău' },
  { label: 'Comuna Fãrcaş', value: 'Comuna Fãrcaş' },
  { label: 'Comuna Fărcaşa', value: 'Comuna Fărcaşa' },
  { label: 'Comuna Fărcaşa', value: 'Comuna Fărcaşa' },
  { label: 'Comuna Fărcaşele', value: 'Comuna Fărcaşele' },
  { label: 'Comuna Fãrcãşeşti', value: 'Comuna Fãrcãşeşti' },
  { label: 'Comuna Fârdea', value: 'Comuna Fârdea' },
  { label: 'Comuna Fârliug', value: 'Comuna Fârliug' },
  { label: 'Comuna Fârţăneşti', value: 'Comuna Fârţăneşti' },
  { label: 'Comuna Fârtãţeşti', value: 'Comuna Fârtãţeşti' },
  { label: 'Comuna Fãurei', value: 'Comuna Fãurei' },
  { label: 'Comuna Făureşti', value: 'Comuna Făureşti' },
  { label: 'Comuna Feldioara', value: 'Comuna Feldioara' },
  { label: 'Comuna Feldru', value: 'Comuna Feldru' },
  { label: 'Comuna Feleacu', value: 'Comuna Feleacu' },
  { label: 'Comuna Feliceni', value: 'Comuna Feliceni' },
  { label: 'Comuna Felnac', value: 'Comuna Felnac' },
  { label: 'Comuna Fereşti', value: 'Comuna Fereşti' },
  { label: 'Comuna Fibiş', value: 'Comuna Fibiş' },
  { label: 'Comuna Fildu De Jos', value: 'Comuna Fildu De Jos' },
  { label: 'Comuna Filipeni', value: 'Comuna Filipeni' },
  { label: 'Comuna Filipeşti', value: 'Comuna Filipeşti' },
  { label: 'Comuna Filipeştii De Pădure', value: 'Comuna Filipeştii De Pădure' },
  { label: 'Comuna Filipeştii De Târg', value: 'Comuna Filipeştii De Târg' },
  { label: 'Comuna Finiş', value: 'Comuna Finiş' },
  { label: 'Comuna Finta', value: 'Comuna Finta' },
  { label: 'Comuna Fitioneşti', value: 'Comuna Fitioneşti' },
  { label: 'Comuna Fizeşu Gherlii', value: 'Comuna Fizeşu Gherlii' },
  { label: 'Comuna Floreşti', value: 'Comuna Floreşti' },
  { label: 'Comuna Floreşti', value: 'Comuna Floreşti' },
  { label: 'Comuna Floreşti', value: 'Comuna Floreşti' },
  { label: 'Comună Floreşti-Stoeneşti', value: 'Comună Floreşti-Stoeneşti' },
  { label: 'Comuna Florica', value: 'Comuna Florica' },
  { label: 'Comuna Focuri', value: 'Comuna Focuri' },
  { label: 'Comuna Foeni', value: 'Comuna Foeni' },
  { label: 'Comuna Foieni', value: 'Comuna Foieni' },
  { label: 'Comuna Folteşti', value: 'Comuna Folteşti' },
  { label: 'Comuna Forăşti', value: 'Comuna Forăşti' },
  { label: 'Comuna Forotic', value: 'Comuna Forotic' },
  { label: 'Comuna Frânceşti-Mânăstireni', value: 'Comuna Frânceşti-Mânăstireni' },
  { label: 'Comuna Frăsinet', value: 'Comuna Frăsinet' },
  { label: 'Comuna Frăsinet', value: 'Comuna Frăsinet' },
  { label: 'Comuna Frata', value: 'Comuna Frata' },
  { label: 'Comuna Frătăuţii Noi', value: 'Comuna Frătăuţii Noi' },
  { label: 'Comuna Frătăuţii Vechi', value: 'Comuna Frătăuţii Vechi' },
  { label: 'Comuna Frăteşti', value: 'Comuna Frăteşti' },
  { label: 'Comuna Frecăţei', value: 'Comuna Frecăţei' },
  { label: 'Comuna Frecãţei', value: 'Comuna Frecãţei' },
  { label: 'Comuna Frumoasa', value: 'Comuna Frumoasa' },
  { label: 'Comuna Frumoasa', value: 'Comuna Frumoasa' },
  { label: 'Comuna Frumosu', value: 'Comuna Frumosu' },
  { label: 'Comuna Frumuşani', value: 'Comuna Frumuşani' },
  { label: 'Comuna Frumuşeni', value: 'Comuna Frumuşeni' },
  { label: 'Comuna Frumuşica', value: 'Comuna Frumuşica' },
  { label: 'Comuna Frumuşiţa', value: 'Comuna Frumuşiţa' },
  { label: 'Comuna Fruntişeni', value: 'Comuna Fruntişeni' },
  { label: 'Comuna Fulga', value: 'Comuna Fulga' },
  { label: 'Comuna Fundata', value: 'Comuna Fundata' },
  { label: 'Comuna Fundeni', value: 'Comuna Fundeni' },
  { label: 'Comuna Fundeni', value: 'Comuna Fundeni' },
  { label: 'Comuna Fundeni-Dobroeşti', value: 'Comuna Fundeni-Dobroeşti' },
  { label: 'Comuna Fundu Moldovei', value: 'Comuna Fundu Moldovei' },
  { label: 'Comuna Furculeşti', value: 'Comuna Furculeşti' },
  { label: 'Comuna Gâdinţi', value: 'Comuna Gâdinţi' },
  { label: 'Comuna Găgeşti', value: 'Comuna Găgeşti' },
  { label: 'Comuna Găiceana', value: 'Comuna Găiceana' },
  { label: 'Comuna Gãiseni', value: 'Comuna Gãiseni' },
  { label: 'Comuna Gălăneşti', value: 'Comuna Gălăneşti' },
  { label: 'Comuna Gălăţeni', value: 'Comuna Gălăţeni' },
  { label: 'Comuna Galaţii Bistriţei', value: 'Comuna Galaţii Bistriţei' },
  { label: 'Comuna Gălăuţaş', value: 'Comuna Gălăuţaş' },
  { label: 'Comuna Galbenu', value: 'Comuna Galbenu' },
  { label: 'Comuna Gălbinaşi', value: 'Comuna Gălbinaşi' },
  { label: 'Comuna Gălbinaşi', value: 'Comuna Gălbinaşi' },
  { label: 'Comuna Galda De Jos', value: 'Comuna Galda De Jos' },
  { label: 'Comuna Gâlgău', value: 'Comuna Gâlgău' },
  { label: 'Comuna Galicea', value: 'Comuna Galicea' },
  { label: 'Comuna Galicea Mare', value: 'Comuna Galicea Mare' },
  { label: 'Comuna Galiciuica', value: 'Comuna Galiciuica' },
  { label: 'Comuna Găneasa', value: 'Comuna Găneasa' },
  { label: 'Comuna Găneasa', value: 'Comuna Găneasa' },
  { label: 'Comuna Gângiova', value: 'Comuna Gângiova' },
  { label: 'Comuna Gârbãu', value: 'Comuna Gârbãu' },
  { label: 'Comuna Gârbou', value: 'Comuna Gârbou' },
  { label: 'Comuna Gârbova', value: 'Comuna Gârbova' },
  { label: 'Comuna Gârbovi', value: 'Comuna Gârbovi' },
  { label: 'Comuna Gârceni', value: 'Comuna Gârceni' },
  { label: 'Comuna Gârcina', value: 'Comuna Gârcina' },
  { label: 'Comuna Gârcov', value: 'Comuna Gârcov' },
  { label: 'Comuna Gârda De Sus', value: 'Comuna Gârda De Sus' },
  { label: 'Comuna Gârdani', value: 'Comuna Gârdani' },
  { label: 'Comuna Gârla Mare', value: 'Comuna Gârla Mare' },
  { label: 'Comuna Gârleni', value: 'Comuna Gârleni' },
  { label: 'Comuna Gârliciu', value: 'Comuna Gârliciu' },
  { label: 'Comuna Gârnic', value: 'Comuna Gârnic' },
  { label: 'Comuna Garoafa', value: 'Comuna Garoafa' },
  { label: 'Comuna Găujani', value: 'Comuna Găujani' },
  { label: 'Comuna Găvăneşti', value: 'Comuna Găvăneşti' },
  { label: 'Comuna Gavojdia', value: 'Comuna Gavojdia' },
  { label: 'Comuna Geaca', value: 'Comuna Geaca' },
  { label: 'Comuna Gemenele', value: 'Comuna Gemenele' },
  { label: 'Comuna General Berthelot', value: 'Comuna General Berthelot' },
  { label: 'Comuna George Enescu', value: 'Comuna George Enescu' },
  { label: 'Comuna Gepiu', value: 'Comuna Gepiu' },
  { label: 'Comuna Ghelari', value: 'Comuna Ghelari' },
  { label: 'Comuna Ghelinţa', value: 'Comuna Ghelinţa' },
  { label: 'Comuna Gheorghe Doja', value: 'Comuna Gheorghe Doja' },
  { label: 'Comuna Gheorghe Lazăr', value: 'Comuna Gheorghe Lazăr' },
  { label: 'Comuna Gherăeşti', value: 'Comuna Gherăeşti' },
  { label: 'Comuna Gherăseni', value: 'Comuna Gherăseni' },
  { label: 'Comuna Gherceşti', value: 'Comuna Gherceşti' },
  { label: 'Comuna Ghergheasa', value: 'Comuna Ghergheasa' },
  { label: 'Comuna Ghergheşti', value: 'Comuna Ghergheşti' },
  { label: 'Comuna Gherghiţa', value: 'Comuna Gherghiţa' },
  { label: 'Comuna Gherţa Mică', value: 'Comuna Gherţa Mică' },
  { label: 'Comuna Ghidfalău', value: 'Comuna Ghidfalău' },
  { label: 'Comuna Ghidici', value: 'Comuna Ghidici' },
  { label: 'Comuna Ghidigeni', value: 'Comuna Ghidigeni' },
  { label: 'Comuna Ghilad', value: 'Comuna Ghilad' },
  { label: 'Comuna Ghimeş-Făget', value: 'Comuna Ghimeş-Făget' },
  { label: 'Comuna Ghimpaţi', value: 'Comuna Ghimpaţi' },
  { label: 'Comuna Ghimpeţeni', value: 'Comuna Ghimpeţeni' },
  { label: 'Comuna Ghindăoani', value: 'Comuna Ghindăoani' },
  { label: 'Comuna Ghindăreşti', value: 'Comuna Ghindăreşti' },
  { label: 'Comuna Ghindeni', value: 'Comuna Ghindeni' },
  { label: 'Comuna Ghioroc', value: 'Comuna Ghioroc' },
  { label: 'Comuna Ghioroiu', value: 'Comuna Ghioroiu' },
  { label: 'Comuna Ghiroda', value: 'Comuna Ghiroda' },
  { label: 'Comuna Ghizela', value: 'Comuna Ghizela' },
  { label: 'Comuna Giarmata', value: 'Comuna Giarmata' },
  { label: 'Comuna Giera', value: 'Comuna Giera' },
  { label: 'Comuna Gighera', value: 'Comuna Gighera' },
  { label: 'Comuna Gilău', value: 'Comuna Gilău' },
  { label: 'Comuna Gioseni', value: 'Comuna Gioseni' },
  { label: 'Comuna Girişu De Criş', value: 'Comuna Girişu De Criş' },
  { label: 'Comuna Giroc', value: 'Comuna Giroc' },
  { label: 'Comuna Girov', value: 'Comuna Girov' },
  { label: 'Comuna Giubega', value: 'Comuna Giubega' },
  { label: 'Comuna Giuleşti', value: 'Comuna Giuleşti' },
  { label: 'Comuna Giulvăz', value: 'Comuna Giulvăz' },
  { label: 'Comuna Giurgeni', value: 'Comuna Giurgeni' },
  { label: 'Comuna Giurgiţa', value: 'Comuna Giurgiţa' },
  { label: 'Comuna Giuvărăşti', value: 'Comuna Giuvărăşti' },
  { label: 'Comuna Glăvăneşti', value: 'Comuna Glăvăneşti' },
  { label: 'Comuna Glăvile', value: 'Comuna Glăvile' },
  { label: 'Comuna Glimboca', value: 'Comuna Glimboca' },
  { label: 'Comuna Glina', value: 'Comuna Glina' },
  { label: 'Comuna Glodeanu-Sărat', value: 'Comuna Glodeanu-Sărat' },
  { label: 'Comuna Glodeanu-Siliştea', value: 'Comuna Glodeanu-Siliştea' },
  { label: 'Comuna Glodeni', value: 'Comuna Glodeni' },
  { label: 'Comuna Glogova', value: 'Comuna Glogova' },
  { label: 'Comuna Godeanu', value: 'Comuna Godeanu' },
  { label: 'Comuna Godeni', value: 'Comuna Godeni' },
  { label: 'Comuna Godineşti', value: 'Comuna Godineşti' },
  { label: 'Comuna Gogoşari', value: 'Comuna Gogoşari' },
  { label: 'Comuna Gogoşu', value: 'Comuna Gogoşu' },
  { label: 'Comuna Gogoşu', value: 'Comuna Gogoşu' },
  { label: 'Comuna Gohor', value: 'Comuna Gohor' },
  { label: 'Comuna Goicea', value: 'Comuna Goicea' },
  { label: 'Comuna Goieşti', value: 'Comuna Goieşti' },
  { label: 'Comuna Golãieşti', value: 'Comuna Golãieşti' },
  { label: 'Comuna Goleşti', value: 'Comuna Goleşti' },
  { label: 'Comuna Goleşti', value: 'Comuna Goleşti' },
  { label: 'Comuna Gologanu', value: 'Comuna Gologanu' },
  { label: 'Comuna Gorban', value: 'Comuna Gorban' },
  { label: 'Comuna Gorbăneşti', value: 'Comuna Gorbăneşti' },
  { label: 'Comuna Gorgota', value: 'Comuna Gorgota' },
  { label: 'Comuna Gornet', value: 'Comuna Gornet' },
  { label: 'Comuna Gornet-Cricov', value: 'Comuna Gornet-Cricov' },
  { label: 'Comuna Goruia', value: 'Comuna Goruia' },
  { label: 'Comuna Gostavăţu', value: 'Comuna Gostavăţu' },
  { label: 'Comuna Goştinari', value: 'Comuna Goştinari' },
  { label: 'Comuna Gostinu', value: 'Comuna Gostinu' },
  { label: 'Comuna Gottlob', value: 'Comuna Gottlob' },
  { label: 'Comuna Grădina', value: 'Comuna Grădina' },
  { label: 'Comuna Grădinari', value: 'Comuna Grădinari' },
  { label: 'Comuna Grădinari', value: 'Comuna Grădinari' },
  { label: 'Comuna Grãdinari', value: 'Comuna Grãdinari' },
  { label: 'Comuna Grãdinile', value: 'Comuna Grãdinile' },
  { label: 'Comuna Grădiştea', value: 'Comuna Grădiştea' },
  { label: 'Comuna Grădiştea', value: 'Comuna Grădiştea' },
  { label: 'Comuna Grădiştea', value: 'Comuna Grădiştea' },
  { label: 'Comuna Grădiştea', value: 'Comuna Grădiştea' },
  { label: 'Comuna Grajduri', value: 'Comuna Grajduri' },
  { label: 'Comuna Grămeşti', value: 'Comuna Grămeşti' },
  { label: 'Comuna Grăniceri', value: 'Comuna Grăniceri' },
  { label: 'Comuna Grăniceşti', value: 'Comuna Grăniceşti' },
  { label: 'Comuna Gratia', value: 'Comuna Gratia' },
  { label: 'Comuna Greabănu', value: 'Comuna Greabănu' },
  { label: 'Comuna Greaca', value: 'Comuna Greaca' },
  { label: 'Comuna Greceşti', value: 'Comuna Greceşti' },
  { label: 'Comuna Greci', value: 'Comuna Greci' },
  { label: 'Comuna Greci', value: 'Comuna Greci' },
  { label: 'Comuna Grindu', value: 'Comuna Grindu' },
  { label: 'Comuna Grindu', value: 'Comuna Grindu' },
  { label: 'Comuna Grinţieş', value: 'Comuna Grinţieş' },
  { label: 'Comuna Griviţa', value: 'Comuna Griviţa' },
  { label: 'Comuna Griviţa', value: 'Comuna Griviţa' },
  { label: 'Comuna Griviţa', value: 'Comuna Griviţa' },
  { label: 'Comuna Grojdibodu', value: 'Comuna Grojdibodu' },
  { label: 'Comuna Gropeni', value: 'Comuna Gropeni' },
  { label: 'Comuna Gropniţa', value: 'Comuna Gropniţa' },
  { label: 'Comuna Groşi', value: 'Comuna Groşi' },
  { label: 'Comuna Groşii Ţibleşului', value: 'Comuna Groşii Ţibleşului' },
  { label: 'Comuna Grozeşti', value: 'Comuna Grozeşti' },
  { label: 'Comuna Grozeşti', value: 'Comuna Grozeşti' },
  { label: 'Comuna Gruia', value: 'Comuna Gruia' },
  { label: 'Comuna Gruiu', value: 'Comuna Gruiu' },
  { label: 'Comuna Grumăzeşti', value: 'Comuna Grumăzeşti' },
  { label: 'Comuna Gugeşti', value: 'Comuna Gugeşti' },
  { label: 'Comuna Gura Caliţei', value: 'Comuna Gura Caliţei' },
  { label: 'Comuna Gura Foii', value: 'Comuna Gura Foii' },
  { label: 'Comuna Gura Ialomiţei', value: 'Comuna Gura Ialomiţei' },
  { label: 'Comuna Gura Ocniţei', value: 'Comuna Gura Ocniţei' },
  { label: 'Comuna Gura Padinii', value: 'Comuna Gura Padinii' },
  { label: 'Comuna Gura Râului', value: 'Comuna Gura Râului' },
  { label: 'Comuna Gura Şuţii', value: 'Comuna Gura Şuţii' },
  { label: 'Comuna Gura Teghii', value: 'Comuna Gura Teghii' },
  { label: 'Comuna Gura Vadului', value: 'Comuna Gura Vadului' },
  { label: 'Comuna Gura Văii', value: 'Comuna Gura Văii' },
  { label: 'Comuna Gura Vitioarei', value: 'Comuna Gura Vitioarei' },
  { label: 'Comuna Gurahonţ', value: 'Comuna Gurahonţ' },
  { label: 'Comuna Gurasada', value: 'Comuna Gurasada' },
  { label: 'Comuna Gurbăneşti', value: 'Comuna Gurbăneşti' },
  { label: 'Comuna Guşoeni', value: 'Comuna Guşoeni' },
  { label: 'Comuna Hăghig', value: 'Comuna Hăghig' },
  { label: 'Comuna Hălăuceşti', value: 'Comuna Hălăuceşti' },
  { label: 'Comuna Hălchiu', value: 'Comuna Hălchiu' },
  { label: 'Comuna Hălmăgel', value: 'Comuna Hălmăgel' },
  { label: 'Comuna Hălmagiu', value: 'Comuna Hălmagiu' },
  { label: 'Comuna Halmăşd', value: 'Comuna Halmăşd' },
  { label: 'Comuna Halmeu', value: 'Comuna Halmeu' },
  { label: 'Comuna Hamcearca', value: 'Comuna Hamcearca' },
  { label: 'Comuna Hăneşti', value: 'Comuna Hăneşti' },
  { label: 'Comuna Hangu', value: 'Comuna Hangu' },
  { label: 'Comuna Hănţeşti', value: 'Comuna Hănţeşti' },
  { label: 'Comuna Hărău', value: 'Comuna Hărău' },
  { label: 'Comuna Hărman', value: 'Comuna Hărman' },
  { label: 'Comuna Hărmăneşti', value: 'Comuna Hărmăneşti' },
  { label: 'Comuna Hârşeni', value: 'Comuna Hârşeni' },
  { label: 'Comuna Hârseşti', value: 'Comuna Hârseşti' },
  { label: 'Comuna Hârtieşti', value: 'Comuna Hârtieşti' },
  { label: 'Comuna Hârtop', value: 'Comuna Hârtop' },
  { label: 'Comuna Hăşmaş', value: 'Comuna Hăşmaş' },
  { label: 'Comuna Havârna', value: 'Comuna Havârna' },
  { label: 'Comuna Helegiu', value: 'Comuna Helegiu' },
  { label: 'Comuna Heleşteni', value: 'Comuna Heleşteni' },
  { label: 'Comuna Hemeiuşi', value: 'Comuna Hemeiuşi' },
  { label: 'Comuna Herăşti', value: 'Comuna Herăşti' },
  { label: 'Comuna Hereclean', value: 'Comuna Hereclean' },
  { label: 'Comuna Hida', value: 'Comuna Hida' },
  { label: 'Comuna Hidişelu De Sus', value: 'Comuna Hidişelu De Sus' },
  { label: 'Comuna Hilişeu-Horia', value: 'Comuna Hilişeu-Horia' },
  { label: 'Comuna Hinova', value: 'Comuna Hinova' },
  { label: 'Comuna Hlipiceni', value: 'Comuna Hlipiceni' },
  { label: 'Comuna Hoceni', value: 'Comuna Hoceni' },
  { label: 'Comuna Hodod', value: 'Comuna Hodod' },
  { label: 'Comuna Hoghilag', value: 'Comuna Hoghilag' },
  { label: 'Comuna Hoghiz', value: 'Comuna Hoghiz' },
  { label: 'Comuna Holbav', value: 'Comuna Holbav' },
  { label: 'Comuna Holboca', value: 'Comuna Holboca' },
  { label: 'Comuna Holod', value: 'Comuna Holod' },
  { label: 'Comuna Homocea', value: 'Comuna Homocea' },
  { label: 'Comuna Homoroade', value: 'Comuna Homoroade' },
  { label: 'Comuna Homorod', value: 'Comuna Homorod' },
  { label: 'Comuna Hopârta', value: 'Comuna Hopârta' },
  { label: 'Comuna Horea', value: 'Comuna Horea' },
  { label: 'Comuna Horgeşti', value: 'Comuna Horgeşti' },
  { label: 'Comuna Horia', value: 'Comuna Horia' },
  { label: 'Comuna Horia', value: 'Comuna Horia' },
  { label: 'Comuna Horia', value: 'Comuna Horia' },
  { label: 'Comuna Horleşti', value: 'Comuna Horleşti' },
  { label: 'Comuna Horoatu Crasnei', value: 'Comuna Horoatu Crasnei' },
  { label: 'Comuna Horodnic De Jos', value: 'Comuna Horodnic De Jos' },
  { label: 'Comuna Horodnic De Sus', value: 'Comuna Horodnic De Sus' },
  { label: 'Comuna Horodniceni', value: 'Comuna Horodniceni' },
  { label: 'Comuna Hotarele', value: 'Comuna Hotarele' },
  { label: 'Comuna Hudeşti', value: 'Comuna Hudeşti' },
  { label: 'Comuna Hulubeşti', value: 'Comuna Hulubeşti' },
  { label: 'Comuna Hurezani', value: 'Comuna Hurezani' },
  { label: 'Comuna Huruieşti', value: 'Comuna Huruieşti' },
  { label: 'Comuna Husasău De Tinca', value: 'Comuna Husasău De Tinca' },
  { label: 'Comuna Husnicioara', value: 'Comuna Husnicioara' },
  { label: 'Comuna I. L. Caragiale', value: 'Comuna I. L. Caragiale' },
  { label: 'Comuna I.C.Brãtianu', value: 'Comuna I.C.Brãtianu' },
  { label: 'Comuna Iablaniţa', value: 'Comuna Iablaniţa' },
  { label: 'Comuna Iacobeni', value: 'Comuna Iacobeni' },
  { label: 'Comuna Iacobeni', value: 'Comuna Iacobeni' },
  { label: 'Comuna Iana', value: 'Comuna Iana' },
  { label: 'Comuna Ianca', value: 'Comuna Ianca' },
  { label: 'Comuna Iancu Jianu', value: 'Comuna Iancu Jianu' },
  { label: 'Comuna Iara', value: 'Comuna Iara' },
  { label: 'Comuna Iaslovăţ', value: 'Comuna Iaslovăţ' },
  { label: 'Comuna Ibăneşti', value: 'Comuna Ibăneşti' },
  { label: 'Comuna Ibăneşti', value: 'Comuna Ibăneşti' },
  { label: 'Comuna Ibănești Pădure', value: 'Comuna Ibănești Pădure' },
  { label: 'Comuna Iclod', value: 'Comuna Iclod' },
  { label: 'Comuna Icoana', value: 'Comuna Icoana' },
  { label: 'Comuna Icuseşti', value: 'Comuna Icuseşti' },
  { label: 'Comuna Iecea Mare', value: 'Comuna Iecea Mare' },
  { label: 'Comuna Iedera', value: 'Comuna Iedera' },
  { label: 'Comuna Iepureşti', value: 'Comuna Iepureşti' },
  { label: 'Comuna Ieud', value: 'Comuna Ieud' },
  { label: 'Comuna Ighiu', value: 'Comuna Ighiu' },
  { label: 'Comuna Igneşti', value: 'Comuna Igneşti' },
  { label: 'Comuna Ileana', value: 'Comuna Ileana' },
  { label: 'Comuna Ileanda', value: 'Comuna Ileanda' },
  { label: 'Comuna Ilia', value: 'Comuna Ilia' },
  { label: 'Comuna Ilieni', value: 'Comuna Ilieni' },
  { label: 'Comuna Ilişeşti', value: 'Comuna Ilişeşti' },
  { label: 'Comuna Ilovăt', value: 'Comuna Ilovăt' },
  { label: 'Comuna Iloviţa', value: 'Comuna Iloviţa' },
  { label: 'Comuna Ilva Mare', value: 'Comuna Ilva Mare' },
  { label: 'Comuna Ilva Mică', value: 'Comuna Ilva Mică' },
  { label: 'Comuna Independenţa', value: 'Comuna Independenţa' },
  { label: 'Comuna Independenţa', value: 'Comuna Independenţa' },
  { label: 'Comuna Independenţa', value: 'Comuna Independenţa' },
  { label: 'Comuna Ineu', value: 'Comuna Ineu' },
  { label: 'Comuna Întorsura', value: 'Comuna Întorsura' },
  { label: 'Comuna Întregalde', value: 'Comuna Întregalde' },
  { label: 'Comuna Ion Corvin', value: 'Comuna Ion Corvin' },
  { label: 'Comuna Ion Creangă', value: 'Comuna Ion Creangă' },
  { label: 'Comuna Ion Neculce', value: 'Comuna Ion Neculce' },
  { label: 'Comuna Ion Roată', value: 'Comuna Ion Roată' },
  { label: 'Comuna Ioneşti', value: 'Comuna Ioneşti' },
  { label: 'Comuna Ioneşti', value: 'Comuna Ioneşti' },
  { label: 'Comuna Iordãcheanu', value: 'Comuna Iordãcheanu' },
  { label: 'Comuna Ip', value: 'Comuna Ip' },
  { label: 'Comuna Ipatele', value: 'Comuna Ipatele' },
  { label: 'Comuna Ipotesti', value: 'Comuna Ipotesti' },
  { label: 'Comuna Ipoteşti', value: 'Comuna Ipoteşti' },
  { label: 'Comuna Iratoşu', value: 'Comuna Iratoşu' },
  { label: 'Comuna Işalniţa', value: 'Comuna Işalniţa' },
  { label: 'Comuna Islaz', value: 'Comuna Islaz' },
  { label: 'Comuna Istria', value: 'Comuna Istria' },
  { label: 'Comuna Isverna', value: 'Comuna Isverna' },
  { label: 'Comuna Isvoarele', value: 'Comuna Isvoarele' },
  { label: 'Comuna Iteşti', value: 'Comuna Iteşti' },
  { label: 'Comuna Ivăneşti', value: 'Comuna Ivăneşti' },
  { label: 'Comuna Iveşti', value: 'Comuna Iveşti' },
  { label: 'Comuna Iveşti', value: 'Comuna Iveşti' },
  { label: 'Comuna Izbiceni', value: 'Comuna Izbiceni' },
  { label: 'Comuna Izvoare', value: 'Comuna Izvoare' },
  { label: 'Comuna Izvoarele', value: 'Comuna Izvoarele' },
  { label: 'Comuna Izvoarele', value: 'Comuna Izvoarele' },
  { label: 'Comuna Izvoarele', value: 'Comuna Izvoarele' },
  { label: 'Comuna Izvoarele', value: 'Comuna Izvoarele' },
  { label: 'Comuna Izvoarele', value: 'Comuna Izvoarele' },
  { label: 'Comuna Izvoarele Sucevei', value: 'Comuna Izvoarele Sucevei' },
  { label: 'Comuna Izvoru', value: 'Comuna Izvoru' },
  { label: 'Comuna Izvoru Bârzii', value: 'Comuna Izvoru Bârzii' },
  { label: 'Comuna Izvoru Berheciului', value: 'Comuna Izvoru Berheciului' },
  { label: 'Comuna Izvoru Crişului', value: 'Comuna Izvoru Crişului' },
  { label: 'Comuna Jamu Mare', value: 'Comuna Jamu Mare' },
  { label: 'Comuna Jariştea', value: 'Comuna Jariştea' },
  { label: 'Comuna Jebel', value: 'Comuna Jebel' },
  { label: 'Comuna Jegălia', value: 'Comuna Jegălia' },
  { label: 'Comuna Jiana', value: 'Comuna Jiana' },
  { label: 'Comuna Jibert', value: 'Comuna Jibert' },
  { label: 'Comuna Jichişu De Jos', value: 'Comuna Jichişu De Jos' },
  { label: 'Comuna Jidvei', value: 'Comuna Jidvei' },
  { label: 'Comuna Jijila', value: 'Comuna Jijila' },
  { label: 'Comuna Jilava', value: 'Comuna Jilava' },
  { label: 'Comuna Jilavele', value: 'Comuna Jilavele' },
  { label: 'Comuna Jina', value: 'Comuna Jina' },
  { label: 'Comuna Jirlău', value: 'Comuna Jirlău' },
  { label: 'Comuna Jitia', value: 'Comuna Jitia' },
  { label: 'Comuna Joiţa', value: 'Comuna Joiţa' },
  { label: 'Comuna Jorăşti', value: 'Comuna Jorăşti' },
  { label: 'Comuna Joseni', value: 'Comuna Joseni' },
  { label: 'Comuna Josenii Bârgăului', value: 'Comuna Josenii Bârgăului' },
  { label: 'Comuna Jucu', value: 'Comuna Jucu' },
  { label: 'Comuna Jugureni', value: 'Comuna Jugureni' },
  { label: 'Comuna Jupâneşti', value: 'Comuna Jupâneşti' },
  { label: 'Comuna Jurilovca', value: 'Comuna Jurilovca' },
  { label: 'Comuna Lăcusteni', value: 'Comuna Lăcusteni' },
  { label: 'Comuna Lădeşti', value: 'Comuna Lădeşti' },
  { label: 'Comuna Laloşu', value: 'Comuna Laloşu' },
  { label: 'Comuna Lapoş', value: 'Comuna Lapoş' },
  { label: 'Comuna Lãpugiu De Jos', value: 'Comuna Lãpugiu De Jos' },
  { label: 'Comuna Lăpuş', value: 'Comuna Lăpuş' },
  { label: 'Comuna Lăpuşata', value: 'Comuna Lăpuşata' },
  { label: 'Comuna Lăpuşnicel', value: 'Comuna Lăpuşnicel' },
  { label: 'Comuna Lăpuşnicu Mare', value: 'Comuna Lăpuşnicu Mare' },
  { label: 'Comuna Largu', value: 'Comuna Largu' },
  { label: 'Comuna Laslea', value: 'Comuna Laslea' },
  { label: 'Comuna Laza', value: 'Comuna Laza' },
  { label: 'Comuna Lăzarea', value: 'Comuna Lăzarea' },
  { label: 'Comuna Lăzăreni', value: 'Comuna Lăzăreni' },
  { label: 'Comuna Lazuri', value: 'Comuna Lazuri' },
  { label: 'Comuna Lazuri De Beiuş', value: 'Comuna Lazuri De Beiuş' },
  { label: 'Comuna Lechinţa', value: 'Comuna Lechinţa' },
  { label: 'Comuna Lehliu', value: 'Comuna Lehliu' },
  { label: 'Comuna Leleasca', value: 'Comuna Leleasca' },
  { label: 'Comuna Lelese', value: 'Comuna Lelese' },
  { label: 'Comuna Leleşti', value: 'Comuna Leleşti' },
  { label: 'Comuna Leliceni', value: 'Comuna Leliceni' },
  { label: 'Comuna Lemnia', value: 'Comuna Lemnia' },
  { label: 'Comuna Lenauheim', value: 'Comuna Lenauheim' },
  { label: 'Comuna Leorda', value: 'Comuna Leorda' },
  { label: 'Comuna Leordeni', value: 'Comuna Leordeni' },
  { label: 'Comuna Leordina', value: 'Comuna Leordina' },
  { label: 'Comuna Lereşti', value: 'Comuna Lereşti' },
  { label: 'Comuna Lespezi', value: 'Comuna Lespezi' },
  { label: 'Comuna Leşu', value: 'Comuna Leşu' },
  { label: 'Comuna Letca', value: 'Comuna Letca' },
  { label: 'Comuna Letca Nouă', value: 'Comuna Letca Nouă' },
  { label: 'Comuna Leţcani', value: 'Comuna Leţcani' },
  { label: 'Comuna Letea Veche', value: 'Comuna Letea Veche' },
  { label: 'Comuna Leu', value: 'Comuna Leu' },
  { label: 'Comuna Licurici', value: 'Comuna Licurici' },
  { label: 'Comuna Liebling', value: 'Comuna Liebling' },
  { label: 'Comuna Lieşti', value: 'Comuna Lieşti' },
  { label: 'Comuna Limanu', value: 'Comuna Limanu' },
  { label: 'Comuna Lipăneşti', value: 'Comuna Lipăneşti' },
  { label: 'Comuna Lipniţa', value: 'Comuna Lipniţa' },
  { label: 'Comuna Lipova', value: 'Comuna Lipova' },
  { label: 'Comuna Lipovăţ', value: 'Comuna Lipovăţ' },
  { label: 'Comuna Lipovu', value: 'Comuna Lipovu' },
  { label: 'Comuna Lisa', value: 'Comuna Lisa' },
  { label: 'Comuna Lisa', value: 'Comuna Lisa' },
  { label: 'Comuna Liţa', value: 'Comuna Liţa' },
  { label: 'Comuna Livada', value: 'Comuna Livada' },
  { label: 'Comuna Livezi', value: 'Comuna Livezi' },
  { label: 'Comuna Livezi', value: 'Comuna Livezi' },
  { label: 'Comuna Livezile', value: 'Comuna Livezile' },
  { label: 'Comuna Livezile', value: 'Comuna Livezile' },
  { label: 'Comuna Livezile', value: 'Comuna Livezile' },
  { label: 'Comuna Livezile', value: 'Comuna Livezile' },
  { label: 'Comuna Loamneş', value: 'Comuna Loamneş' },
  { label: 'Comuna Logreşti-Moşteni', value: 'Comuna Logreşti-Moşteni' },
  { label: 'Comuna Lopadea Nouă', value: 'Comuna Lopadea Nouă' },
  { label: 'Comuna Lopătari', value: 'Comuna Lopătari' },
  { label: 'Comuna Lovrin', value: 'Comuna Lovrin' },
  { label: 'Comuna Lozna', value: 'Comuna Lozna' },
  { label: 'Comuna Lozna', value: 'Comuna Lozna' },
  { label: 'Comuna Lucieni', value: 'Comuna Lucieni' },
  { label: 'Comuna Luciu', value: 'Comuna Luciu' },
  { label: 'Comuna Ludeşti', value: 'Comuna Ludeşti' },
  { label: 'Comuna Ludoş', value: 'Comuna Ludoş' },
  { label: 'Comuna Lueta', value: 'Comuna Lueta' },
  { label: 'Comuna Lugaşu De Jos', value: 'Comuna Lugaşu De Jos' },
  { label: 'Comuna Luica', value: 'Comuna Luica' },
  { label: 'Comuna Luizi-Cãlugãra', value: 'Comuna Luizi-Cãlugãra' },
  { label: 'Comuna Lumina', value: 'Comuna Lumina' },
  { label: 'Comuna Luna', value: 'Comuna Luna' },
  { label: 'Comuna Lunca', value: 'Comuna Lunca' },
  { label: 'Comuna Lunca', value: 'Comuna Lunca' },
  { label: 'Comuna Lunca', value: 'Comuna Lunca' },
  { label: 'Comuna Lunca Banului', value: 'Comuna Lunca Banului' },
  { label: 'Comuna Lunca Cernii De Jos', value: 'Comuna Lunca Cernii De Jos' },
  { label: 'Comuna Lunca Corbului', value: 'Comuna Lunca Corbului' },
  { label: 'Comuna Lunca De Jos', value: 'Comuna Lunca De Jos' },
  { label: 'Comuna Lunca De Sus', value: 'Comuna Lunca De Sus' },
  { label: 'Comuna Lunca Ilvei', value: 'Comuna Lunca Ilvei' },
  { label: 'Comuna Lunca Mureşului', value: 'Comuna Lunca Mureşului' },
  { label: 'Comuna Luncaviţa', value: 'Comuna Luncaviţa' },
  { label: 'Comuna Luncaviţa', value: 'Comuna Luncaviţa' },
  { label: 'Comuna Luncoiu De Jos', value: 'Comuna Luncoiu De Jos' },
  { label: 'Comuna Lungani', value: 'Comuna Lungani' },
  { label: 'Comuna Lungeşti', value: 'Comuna Lungeşti' },
  { label: 'Comuna Lunguleţu', value: 'Comuna Lunguleţu' },
  { label: 'Comuna Lupac', value: 'Comuna Lupac' },
  { label: 'Comuna Lupeni', value: 'Comuna Lupeni' },
  { label: 'Comuna Lupşa', value: 'Comuna Lupşa' },
  { label: 'Comuna Lupşanu', value: 'Comuna Lupşanu' },
  { label: 'Comuna Macea', value: 'Comuna Macea' },
  { label: 'Comuna Măceşu De Jos', value: 'Comuna Măceşu De Jos' },
  { label: 'Comuna Măceşu De Sus', value: 'Comuna Măceşu De Sus' },
  { label: 'Comuna Măciuca', value: 'Comuna Măciuca' },
  { label: 'Comuna Mădăraş', value: 'Comuna Mădăraş' },
  { label: 'Comuna Mădăraş', value: 'Comuna Mădăraş' },
  { label: 'Comuna Mădârjac', value: 'Comuna Mădârjac' },
  { label: 'Comuna Mădulari-Beica', value: 'Comuna Mădulari-Beica' },
  { label: 'Comuna Măerişte', value: 'Comuna Măerişte' },
  { label: 'Comuna Măgeşti', value: 'Comuna Măgeşti' },
  { label: 'Comuna Măgireşti', value: 'Comuna Măgireşti' },
  { label: 'Comuna Maglavit', value: 'Comuna Maglavit' },
  { label: 'Comuna Măgura', value: 'Comuna Măgura' },
  { label: 'Comuna Măgura', value: 'Comuna Măgura' },
  { label: 'Comuna Măgura', value: 'Comuna Măgura' },
  { label: 'Comuna Măgura Ilvei', value: 'Comuna Măgura Ilvei' },
  { label: 'Comuna Măgurele', value: 'Comuna Măgurele' },
  { label: 'Comuna Măgureni', value: 'Comuna Măgureni' },
  { label: 'Comuna Mãguri-Rãcãtãu', value: 'Comuna Mãguri-Rãcãtãu' },
  { label: 'Comuna Mahmudia', value: 'Comuna Mahmudia' },
  { label: 'Comuna Maia', value: 'Comuna Maia' },
  { label: 'Comuna Măicăneşti', value: 'Comuna Măicăneşti' },
  { label: 'Comuna Măieru', value: 'Comuna Măieru' },
  { label: 'Comuna Mãieruş', value: 'Comuna Mãieruş' },
  { label: 'Comuna Malaia', value: 'Comuna Malaia' },
  { label: 'Comuna Măldăeni', value: 'Comuna Măldăeni' },
  { label: 'Comuna Măldăreşti', value: 'Comuna Măldăreşti' },
  { label: 'Comuna Mălini', value: 'Comuna Mălini' },
  { label: 'Comuna Maliuc', value: 'Comuna Maliuc' },
  { label: 'Comuna Malnaş', value: 'Comuna Malnaş' },
  { label: 'Comuna Malovãţ', value: 'Comuna Malovãţ' },
  { label: 'Comuna Malu', value: 'Comuna Malu' },
  { label: 'Comuna Malu Cu Flori', value: 'Comuna Malu Cu Flori' },
  { label: 'Comuna Malu Mare', value: 'Comuna Malu Mare' },
  { label: 'Comuna Mălureni', value: 'Comuna Mălureni' },
  { label: 'Comuna Măluşteni', value: 'Comuna Măluşteni' },
  { label: 'Comuna Manasia', value: 'Comuna Manasia' },
  { label: 'Comuna Mânăstirea', value: 'Comuna Mânăstirea' },
  { label: 'Comuna Mânăstirea Caşin', value: 'Comuna Mânăstirea Caşin' },
  { label: 'Comuna Mânăstirea Humorului', value: 'Comuna Mânăstirea Humorului' },
  { label: 'Comuna Mânăstireni', value: 'Comuna Mânăstireni' },
  { label: 'Comuna Mănăştiur', value: 'Comuna Mănăştiur' },
  { label: 'Comuna Mândra', value: 'Comuna Mândra' },
  { label: 'Comuna Măneciu', value: 'Comuna Măneciu' },
  { label: 'Comuna Măneşti', value: 'Comuna Măneşti' },
  { label: 'Comuna Măneşti', value: 'Comuna Măneşti' },
  { label: 'Comuna Manoleasa', value: 'Comuna Manoleasa' },
  { label: 'Comuna Mânzăleşti', value: 'Comuna Mânzăleşti' },
  { label: 'Comuna Mărăcineni', value: 'Comuna Mărăcineni' },
  { label: 'Comuna Mărăcineni', value: 'Comuna Mărăcineni' },
  { label: 'Comuna Măraşu', value: 'Comuna Măraşu' },
  { label: 'Comuna Marca', value: 'Comuna Marca' },
  { label: 'Comuna Mărculeşti', value: 'Comuna Mărculeşti' },
  { label: 'Comuna Marga', value: 'Comuna Marga' },
  { label: 'Comuna Mărgăriteşti', value: 'Comuna Mărgăriteşti' },
  { label: 'Comuna Mărgău', value: 'Comuna Mărgău' },
  { label: 'Comuna Margina', value: 'Comuna Margina' },
  { label: 'Comuna Marginea', value: 'Comuna Marginea' },
  { label: 'Comuna Mărgineni', value: 'Comuna Mărgineni' },
  { label: 'Comuna Mărgineni', value: 'Comuna Mărgineni' },
  { label: 'Comuna Mărişel', value: 'Comuna Mărişel' },
  { label: 'Comuna Mărişelu', value: 'Comuna Mărişelu' },
  { label: 'Comuna Marpod', value: 'Comuna Marpod' },
  { label: 'Comuna Mârşa', value: 'Comuna Mârşa' },
  { label: 'Comuna Mârşani', value: 'Comuna Mârşani' },
  { label: 'Comuna Mărtineşti', value: 'Comuna Mărtineşti' },
  { label: 'Comuna Mărtiniş', value: 'Comuna Mărtiniş' },
  { label: 'Comuna Mărunţei', value: 'Comuna Mărunţei' },
  { label: 'Comuna Mârzãneşti', value: 'Comuna Mârzãneşti' },
  { label: 'Comuna Maşloc', value: 'Comuna Maşloc' },
  { label: 'Comuna Măstăcani', value: 'Comuna Măstăcani' },
  { label: 'Comuna Mătăsari', value: 'Comuna Mătăsari' },
  { label: 'Comuna Mătăsaru', value: 'Comuna Mătăsaru' },
  { label: 'Comuna Matca', value: 'Comuna Matca' },
  { label: 'Comuna Mateeşti', value: 'Comuna Mateeşti' },
  { label: 'Comuna Matei', value: 'Comuna Matei' },
  { label: 'Comuna Măureni', value: 'Comuna Măureni' },
  { label: 'Comuna Mavrodin', value: 'Comuna Mavrodin' },
  { label: 'Comuna Măxineni', value: 'Comuna Măxineni' },
  { label: 'Comuna Medieşu Aurit', value: 'Comuna Medieşu Aurit' },
  { label: 'Comuna Mehadia', value: 'Comuna Mehadia' },
  { label: 'Comuna Mehadica', value: 'Comuna Mehadica' },
  { label: 'Comuna Melineşti', value: 'Comuna Melineşti' },
  { label: 'Comuna Mera', value: 'Comuna Mera' },
  { label: 'Comuna Merei', value: 'Comuna Merei' },
  { label: 'Comuna Mereni', value: 'Comuna Mereni' },
  { label: 'Comuna Mereni', value: 'Comuna Mereni' },
  { label: 'Comuna Mereni', value: 'Comuna Mereni' },
  { label: 'Comuna Mereşti', value: 'Comuna Mereşti' },
  { label: 'Comuna Merghindeal', value: 'Comuna Merghindeal' },
  { label: 'Comuna Merișani', value: 'Comuna Merișani' },
  { label: 'Comună Meseşenii De Jos', value: 'Comună Meseşenii De Jos' },
  { label: 'Comuna Meteş', value: 'Comuna Meteş' },
  { label: 'Comuna Mica', value: 'Comuna Mica' },
  { label: 'Comuna Micăsasa', value: 'Comuna Micăsasa' },
  { label: 'Comuna Miceşti', value: 'Comuna Miceşti' },
  { label: 'Comuna Miceştii De Câmpie', value: 'Comuna Miceştii De Câmpie' },
  { label: 'Comuna Micfalău', value: 'Comuna Micfalău' },
  { label: 'Comuna Micleşti', value: 'Comuna Micleşti' },
  { label: 'Comuna Micula', value: 'Comuna Micula' },
  { label: 'Comuna Mihăeşti', value: 'Comuna Mihăeşti' },
  { label: 'Comuna Mihăeşti', value: 'Comuna Mihăeşti' },
  { label: 'Comuna Mihăeşti', value: 'Comuna Mihăeşti' },
  { label: 'Comuna Mihai Bravu', value: 'Comuna Mihai Bravu' },
  { label: 'Comuna Mihai Bravu', value: 'Comuna Mihai Bravu' },
  { label: 'Comuna Mihai Eminescu', value: 'Comuna Mihai Eminescu' },
  { label: 'Comuna Mihai Viteazu', value: 'Comuna Mihai Viteazu' },
  { label: 'Comuna Mihai Viteazu', value: 'Comuna Mihai Viteazu' },
  { label: 'Comuna Mihail Kogălniceanu', value: 'Comuna Mihail Kogălniceanu' },
  { label: 'Comuna Mihail Kogălniceanu', value: 'Comuna Mihail Kogălniceanu' },
  { label: 'Comuna Mihail Kogălniceanu', value: 'Comuna Mihail Kogălniceanu' },
  { label: 'Comuna Mihăileni', value: 'Comuna Mihăileni' },
  { label: 'Comuna Mihăileni', value: 'Comuna Mihăileni' },
  { label: 'Comuna Mihăileni', value: 'Comuna Mihăileni' },
  { label: 'Comuna Mihăileşti', value: 'Comuna Mihăileşti' },
  { label: 'Comuna Mihălăşeni', value: 'Comuna Mihălăşeni' },
  { label: 'Comuna Mihalţ', value: 'Comuna Mihalţ' },
  { label: 'Comuna Milas', value: 'Comuna Milas' },
  { label: 'Comuna Milcoiu', value: 'Comuna Milcoiu' },
  { label: 'Comuna Milcov', value: 'Comuna Milcov' },
  { label: 'Comuna Milcovul', value: 'Comuna Milcovul' },
  { label: 'Comuna Mileanca', value: 'Comuna Mileanca' },
  { label: 'Comuna Miloşeşti', value: 'Comuna Miloşeşti' },
  { label: 'Comuna Mintiu Gherlii', value: 'Comuna Mintiu Gherlii' },
  { label: 'Comuna Mioarele', value: 'Comuna Mioarele' },
  { label: 'Comuna Mirăslău', value: 'Comuna Mirăslău' },
  { label: 'Comuna Mircea Vodă', value: 'Comuna Mircea Vodă' },
  { label: 'Comuna Mircea Vodă', value: 'Comuna Mircea Vodă' },
  { label: 'Comuna Mirceşti', value: 'Comuna Mirceşti' },
  { label: 'Comuna Mireşu Mare', value: 'Comuna Mireşu Mare' },
  { label: 'Comuna Mironeasa', value: 'Comuna Mironeasa' },
  { label: 'Comuna Miroşi', value: 'Comuna Miroşi' },
  { label: 'Comuna Miroslava', value: 'Comuna Miroslava' },
  { label: 'Comuna Mirosloveşti', value: 'Comuna Mirosloveşti' },
  { label: 'Comuna Mirşid', value: 'Comuna Mirşid' },
  { label: 'Comuna Mişca', value: 'Comuna Mişca' },
  { label: 'Comuna Mischii', value: 'Comuna Mischii' },
  { label: 'Comuna Mitoc', value: 'Comuna Mitoc' },
  { label: 'Comuna Mitocu Dragomirnei', value: 'Comuna Mitocu Dragomirnei' },
  { label: 'Comuna Mitreni', value: 'Comuna Mitreni' },
  { label: 'Comuna Mitrofani', value: 'Comuna Mitrofani' },
  { label: 'Comuna Moacşa', value: 'Comuna Moacşa' },
  { label: 'Comuna Moara', value: 'Comuna Moara' },
  { label: 'Comuna Moara Vlãsiei', value: 'Comuna Moara Vlãsiei' },
  { label: 'Comuna Mociu', value: 'Comuna Mociu' },
  { label: 'Comuna Modelu', value: 'Comuna Modelu' },
  { label: 'Comuna Moftinu', value: 'Comuna Moftinu' },
  { label: 'Comuna Mogoş', value: 'Comuna Mogoş' },
  { label: 'Comuna Mogoşani', value: 'Comuna Mogoşani' },
  { label: 'Comuna Mogoşeşti', value: 'Comuna Mogoşeşti' },
  { label: 'Comuna Mogoşeşti-Siret', value: 'Comuna Mogoşeşti-Siret' },
  { label: 'Comuna Mogoşoaia', value: 'Comuna Mogoşoaia' },
  { label: 'Comuna Moieciu', value: 'Comuna Moieciu' },
  { label: 'Comuna Moisei', value: 'Comuna Moisei' },
  { label: 'Comuna Moldova Suliţa', value: 'Comuna Moldova Suliţa' },
  { label: 'Comuna Moldoveneşti', value: 'Comuna Moldoveneşti' },
  { label: 'Comuna Moldoveni', value: 'Comuna Moldoveni' },
  { label: 'Comuna Moldoveni', value: 'Comuna Moldoveni' },
  { label: 'Comuna Moldoviţa', value: 'Comuna Moldoviţa' },
  { label: 'Comuna Moneasa', value: 'Comuna Moneasa' },
  { label: 'Comuna Monor', value: 'Comuna Monor' },
  { label: 'Comuna Morărești', value: 'Comuna Morărești' },
  { label: 'Comuna Moraviţa', value: 'Comuna Moraviţa' },
  { label: 'Comuna Moroeni', value: 'Comuna Moroeni' },
  { label: 'Comuna Morteni', value: 'Comuna Morteni' },
  { label: 'Comuna Morunglav', value: 'Comuna Morunglav' },
  { label: 'Comuna Moşna', value: 'Comuna Moşna' },
  { label: 'Comuna Moşna', value: 'Comuna Moşna' },
  { label: 'Comuna Moşniţa Nouã', value: 'Comuna Moşniţa Nouã' },
  { label: 'Comuna Moşoaia', value: 'Comuna Moşoaia' },
  { label: 'Comuna Moşteni', value: 'Comuna Moşteni' },
  { label: 'Comuna Moţãieni', value: 'Comuna Moţãieni' },
  { label: 'Comuna Moţăţei', value: 'Comuna Moţăţei' },
  { label: 'Comuna Moţca', value: 'Comuna Moţca' },
  { label: 'Comuna Motoşeni', value: 'Comuna Motoşeni' },
  { label: 'Comuna Movila', value: 'Comuna Movila' },
  { label: 'Comuna Movila Banului', value: 'Comuna Movila Banului' },
  { label: 'Comuna Movila Miresii', value: 'Comuna Movila Miresii' },
  { label: 'Comuna Movileni', value: 'Comuna Movileni' },
  { label: 'Comuna Movileni', value: 'Comuna Movileni' },
  { label: 'Comuna Movileni', value: 'Comuna Movileni' },
  { label: 'Comuna Moviliţa', value: 'Comuna Moviliţa' },
  { label: 'Comuna Moviliţa', value: 'Comuna Moviliţa' },
  { label: 'Comuna Mozăceni', value: 'Comuna Mozăceni' },
  { label: 'Comuna Muereasca', value: 'Comuna Muereasca' },
  { label: 'Comuna Mugeni', value: 'Comuna Mugeni' },
  { label: 'Comuna Munteni', value: 'Comuna Munteni' },
  { label: 'Comuna Munteni Buzău', value: 'Comuna Munteni Buzău' },
  { label: 'Comuna Muntenii De Jos', value: 'Comuna Muntenii De Jos' },
  { label: 'Comuna Muntenii De Sus', value: 'Comuna Muntenii De Sus' },
  { label: 'Comuna Murgaşi', value: 'Comuna Murgaşi' },
  { label: 'Comuna Murgeşti', value: 'Comuna Murgeşti' },
  { label: 'Comuna Murighiol', value: 'Comuna Murighiol' },
  { label: 'Comuna Mușătești', value: 'Comuna Mușătești' },
  { label: 'Comuna Muşeniţa', value: 'Comuna Muşeniţa' },
  { label: 'Comuna Muşeteşti', value: 'Comuna Muşeteşti' },
  { label: 'Comuna Nădrag', value: 'Comuna Nădrag' },
  { label: 'Comuna Năeni', value: 'Comuna Năeni' },
  { label: 'Comuna Naidaş', value: 'Comuna Naidaş' },
  { label: 'Comuna Nalbant', value: 'Comuna Nalbant' },
  { label: 'Comuna Nămoloasa', value: 'Comuna Nămoloasa' },
  { label: 'Comuna Nana', value: 'Comuna Nana' },
  { label: 'Comuna Năneşti', value: 'Comuna Năneşti' },
  { label: 'Comuna Nanov', value: 'Comuna Nanov' },
  { label: 'Comuna Năpradea', value: 'Comuna Năpradea' },
  { label: 'Comuna Năruja', value: 'Comuna Năruja' },
  { label: 'Comuna Năsturelu', value: 'Comuna Năsturelu' },
  { label: 'Comuna Necşeşti', value: 'Comuna Necşeşti' },
  { label: 'Comuna Negoi', value: 'Comuna Negoi' },
  { label: 'Comuna Negomir', value: 'Comuna Negomir' },
  { label: 'Comuna Negraşi', value: 'Comuna Negraşi' },
  { label: 'Comuna Negreni', value: 'Comuna Negreni' },
  { label: 'Comuna Negreşti', value: 'Comuna Negreşti' },
  { label: 'Comuna Negri', value: 'Comuna Negri' },
  { label: 'Comuna Negrileşti', value: 'Comuna Negrileşti' },
  { label: 'Comuna Negrileşti', value: 'Comuna Negrileşti' },
  { label: 'Comuna Negrileşti', value: 'Comuna Negrileşti' },
  { label: 'Comuna Nenciuleşti', value: 'Comuna Nenciuleşti' },
  { label: 'Comuna Nereju', value: 'Comuna Nereju' },
  { label: 'Comuna Nicolae Bălcescu', value: 'Comuna Nicolae Bălcescu' },
  { label: 'Comuna Nicolae Bălcescu', value: 'Comuna Nicolae Bălcescu' },
  { label: 'Comuna Nicolae Bălcescu', value: 'Comuna Nicolae Bălcescu' },
  { label: 'Comuna Nicolae Bãlcescu', value: 'Comuna Nicolae Bãlcescu' },
  { label: 'Comuna Nicolae Titulescu', value: 'Comuna Nicolae Titulescu' },
  { label: 'Comuna Nicoreşti', value: 'Comuna Nicoreşti' },
  { label: 'Comuna Nicşeni', value: 'Comuna Nicşeni' },
  { label: 'Comuna Niculeşti', value: 'Comuna Niculeşti' },
  { label: 'Comuna Niculiţel', value: 'Comuna Niculiţel' },
  { label: 'Comuna Nimigea', value: 'Comuna Nimigea' },
  { label: 'Comuna Nistoreşti', value: 'Comuna Nistoreşti' },
  { label: 'Comuna Niţchidorf', value: 'Comuna Niţchidorf' },
  { label: 'Comuna Nocrich', value: 'Comuna Nocrich' },
  { label: 'Comuna Nojorid', value: 'Comuna Nojorid' },
  { label: 'Comuna Noşlac', value: 'Comuna Noşlac' },
  { label: 'Comuna Nucet', value: 'Comuna Nucet' },
  { label: 'Comuna Nuci', value: 'Comuna Nuci' },
  { label: 'Comuna Nucşoara', value: 'Comuna Nucşoara' },
  { label: 'Comuna Nufăru', value: 'Comuna Nufăru' },
  { label: 'Comuna Nuşeni', value: 'Comuna Nuşeni' },
  { label: 'Comuna Nuşfalău', value: 'Comuna Nuşfalău' },
  { label: 'Comuna Oancea', value: 'Comuna Oancea' },
  { label: 'Comuna Oarja', value: 'Comuna Oarja' },
  { label: 'Comuna Oarţa De Jos', value: 'Comuna Oarţa De Jos' },
  { label: 'Comuna Obârşia', value: 'Comuna Obârşia' },
  { label: 'Comuna Obârşia De Câmp', value: 'Comuna Obârşia De Câmp' },
  { label: 'Comuna Obârşia-Cloşani', value: 'Comuna Obârşia-Cloşani' },
  { label: 'Comuna Oboga', value: 'Comuna Oboga' },
  { label: 'Comuna Obreja', value: 'Comuna Obreja' },
  { label: 'Comuna Obrejiţa', value: 'Comuna Obrejiţa' },
  { label: 'Comuna Ocland', value: 'Comuna Ocland' },
  { label: 'Comuna Ocna De Fier', value: 'Comuna Ocna De Fier' },
  { label: 'Comuna Ocna Şugatag', value: 'Comuna Ocna Şugatag' },
  { label: 'Comuna Ocniţa', value: 'Comuna Ocniţa' },
  { label: 'Comuna Ocoliş', value: 'Comuna Ocoliş' },
  { label: 'Comuna Odăile', value: 'Comuna Odăile' },
  { label: 'Comuna Odobeşti', value: 'Comuna Odobeşti' },
  { label: 'Comuna Odobeşti', value: 'Comuna Odobeşti' },
  { label: 'Comuna Odoreu', value: 'Comuna Odoreu' },
  { label: 'Comuna Ograda', value: 'Comuna Ograda' },
  { label: 'Comuna Ogrezeni', value: 'Comuna Ogrezeni' },
  { label: 'Comuna Ohaba', value: 'Comuna Ohaba' },
  { label: 'Comuna Ohaba Lungă', value: 'Comuna Ohaba Lungă' },
  { label: 'Comuna Oinacu', value: 'Comuna Oinacu' },
  { label: 'Comuna Oituz', value: 'Comuna Oituz' },
  { label: 'Comuna Ojdula', value: 'Comuna Ojdula' },
  { label: 'Comuna Olanu', value: 'Comuna Olanu' },
  { label: 'Comuna Olari', value: 'Comuna Olari' },
  { label: 'Comuna Olari', value: 'Comuna Olari' },
  { label: 'Comuna Olcea', value: 'Comuna Olcea' },
  { label: 'Comuna Olteneşti', value: 'Comuna Olteneşti' },
  { label: 'Comuna Olteni', value: 'Comuna Olteni' },
  { label: 'Comuna Oltina', value: 'Comuna Oltina' },
  { label: 'Comuna Onceşti', value: 'Comuna Onceşti' },
  { label: 'Comuna Onceşti', value: 'Comuna Onceşti' },
  { label: 'Comuna Oniceni', value: 'Comuna Oniceni' },
  { label: 'Comuna Oporelu', value: 'Comuna Oporelu' },
  { label: 'Comuna Oprişor', value: 'Comuna Oprişor' },
  { label: 'Comuna Optaşi-Mãgura', value: 'Comuna Optaşi-Mãgura' },
  { label: 'Comuna Orăştioara De Sus', value: 'Comuna Orăştioara De Sus' },
  { label: 'Comuna Oraşu Nou', value: 'Comuna Oraşu Nou' },
  { label: 'Comuna Orbeasca', value: 'Comuna Orbeasca' },
  { label: 'Comuna Orbeni', value: 'Comuna Orbeni' },
  { label: 'Comuna Orlat', value: 'Comuna Orlat' },
  { label: 'Comuna Orlea', value: 'Comuna Orlea' },
  { label: 'Comuna Orleşti', value: 'Comuna Orleşti' },
  { label: 'Comuna Ormeniş', value: 'Comuna Ormeniş' },
  { label: 'Comuna Orodel', value: 'Comuna Orodel' },
  { label: 'Comuna Orţişoara', value: 'Comuna Orţişoara' },
  { label: 'Comuna Oşeşti', value: 'Comuna Oşeşti' },
  { label: 'Comuna Osica De Jos', value: 'Comuna Osica De Jos' },
  { label: 'Comuna Osica De Sus', value: 'Comuna Osica De Sus' },
  { label: 'Comuna Oşorhei', value: 'Comuna Oşorhei' },
  { label: 'Comuna Ostra', value: 'Comuna Ostra' },
  { label: 'Comuna Ostrov', value: 'Comuna Ostrov' },
  { label: 'Comuna Ostrov', value: 'Comuna Ostrov' },
  { label: 'Comuna Ostroveni', value: 'Comuna Ostroveni' },
  { label: 'Comuna Otelec', value: 'Comuna Otelec' },
  { label: 'Comuna Oţeleni', value: 'Comuna Oţeleni' },
  { label: 'Comuna Oteşani', value: 'Comuna Oteşani' },
  { label: 'Comuna Ozun', value: 'Comuna Ozun' },
  { label: 'Comuna Păcureţi', value: 'Comuna Păcureţi' },
  { label: 'Comuna Padeş', value: 'Comuna Padeş' },
  { label: 'Comuna Padina', value: 'Comuna Padina' },
  { label: 'Comuna Padina', value: 'Comuna Padina' },
  { label: 'Comuna Pădureni', value: 'Comuna Pădureni' },
  { label: 'Comuna Pădureni', value: 'Comuna Pădureni' },
  { label: 'Comuna Palanca', value: 'Comuna Palanca' },
  { label: 'Comuna Pãlatca', value: 'Comuna Pãlatca' },
  { label: 'Comuna Paleu', value: 'Comuna Paleu' },
  { label: 'Comuna Paltin', value: 'Comuna Paltin' },
  { label: 'Comuna Păltiniş', value: 'Comuna Păltiniş' },
  { label: 'Comuna Pãltiniş', value: 'Comuna Pãltiniş' },
  { label: 'Comuna Păltinoasa', value: 'Comuna Păltinoasa' },
  { label: 'Comuna Panaci', value: 'Comuna Panaci' },
  { label: 'Comuna Pănătău', value: 'Comuna Pănătău' },
  { label: 'Comuna Pânceşti', value: 'Comuna Pânceşti' },
  { label: 'Comuna Pănceşti', value: 'Comuna Pănceşti' },
  { label: 'Comuna Pângăraţi', value: 'Comuna Pângăraţi' },
  { label: 'Comuna Pantelimon', value: 'Comuna Pantelimon' },
  { label: 'Comuna Panticeu', value: 'Comuna Panticeu' },
  { label: 'Comuna Pârâu', value: 'Comuna Pârâu' },
  { label: 'Comuna Parava', value: 'Comuna Parava' },
  { label: 'Comuna Pardina', value: 'Comuna Pardina' },
  { label: 'Comuna Pardoşi', value: 'Comuna Pardoşi' },
  { label: 'Comuna Pârgăreşti', value: 'Comuna Pârgăreşti' },
  { label: 'Comuna Parincea', value: 'Comuna Parincea' },
  { label: 'Comuna Pârjol', value: 'Comuna Pârjol' },
  { label: 'Comuna Pârscov', value: 'Comuna Pârscov' },
  { label: 'Comuna Pârşcoveni', value: 'Comuna Pârşcoveni' },
  { label: 'Comuna Parţa', value: 'Comuna Parţa' },
  { label: 'Comuna Părteştii De Jos', value: 'Comuna Părteştii De Jos' },
  { label: 'Comuna Parva', value: 'Comuna Parva' },
  { label: 'Comuna Păstrăveni', value: 'Comuna Păstrăveni' },
  { label: 'Comuna Pătrăuţi', value: 'Comuna Pătrăuţi' },
  { label: 'Comuna Pătulele', value: 'Comuna Pătulele' },
  { label: 'Comuna Păuca', value: 'Comuna Păuca' },
  { label: 'Comuna Pãuleni-Ciuc', value: 'Comuna Pãuleni-Ciuc' },
  { label: 'Comuna Păuleşti', value: 'Comuna Păuleşti' },
  { label: 'Comuna Păuleşti', value: 'Comuna Păuleşti' },
  { label: 'Comuna Pãuleşti', value: 'Comuna Pãuleşti' },
  { label: 'Comuna Păuliş', value: 'Comuna Păuliş' },
  { label: 'Comuna Păuneşti', value: 'Comuna Păuneşti' },
  { label: 'Comuna Păuşeşti', value: 'Comuna Păuşeşti' },
  { label: 'Comuna Păuşeşti-Măglaşi', value: 'Comuna Păuşeşti-Măglaşi' },
  { label: 'Comuna Peceneaga', value: 'Comuna Peceneaga' },
  { label: 'Comuna Pechea', value: 'Comuna Pechea' },
  { label: 'Comuna Pecineaga', value: 'Comuna Pecineaga' },
  { label: 'Comuna Peciu Nou', value: 'Comuna Peciu Nou' },
  { label: 'Comuna Peregu Mare', value: 'Comuna Peregu Mare' },
  { label: 'Comuna Peretu', value: 'Comuna Peretu' },
  { label: 'Comuna Periam', value: 'Comuna Periam' },
  { label: 'Comuna Pericei', value: 'Comuna Pericei' },
  { label: 'Comuna Perieni', value: 'Comuna Perieni' },
  { label: 'Comuna Perieţi', value: 'Comuna Perieţi' },
  { label: 'Comuna Perieţi', value: 'Comuna Perieţi' },
  { label: 'Comuna Periş', value: 'Comuna Periş' },
  { label: 'Comuna Perişani', value: 'Comuna Perişani' },
  { label: 'Comuna Perişor', value: 'Comuna Perişor' },
  { label: 'Comuna Perişoru', value: 'Comuna Perişoru' },
  { label: 'Comuna Perşinari', value: 'Comuna Perşinari' },
  { label: 'Comuna Pesac', value: 'Comuna Pesac' },
  { label: 'Comuna Pescari', value: 'Comuna Pescari' },
  { label: 'Comuna Pesceana', value: 'Comuna Pesceana' },
  { label: 'Comuna Peştera', value: 'Comuna Peştera' },
  { label: 'Comuna Peştişani', value: 'Comuna Peştişani' },
  { label: 'Comuna Pestişu Mic', value: 'Comuna Pestişu Mic' },
  { label: 'Comuna Petrăchioaia', value: 'Comuna Petrăchioaia' },
  { label: 'Comuna Petreşti', value: 'Comuna Petreşti' },
  { label: 'Comuna Petreşti', value: 'Comuna Petreşti' },
  { label: 'Comuna Petreştii De Jos', value: 'Comuna Petreştii De Jos' },
  { label: 'Comuna Petricani', value: 'Comuna Petricani' },
  { label: 'Comuna Petriş', value: 'Comuna Petriş' },
  { label: 'Comuna Petrova', value: 'Comuna Petrova' },
  { label: 'Comuna Petru Rareş', value: 'Comuna Petru Rareş' },
  { label: 'Comuna Pianu', value: 'Comuna Pianu' },
  { label: 'Comuna Piatra', value: 'Comuna Piatra' },
  { label: 'Comuna Piatra Şoimului', value: 'Comuna Piatra Şoimului' },
  { label: 'Comuna Pieleşti', value: 'Comuna Pieleşti' },
  { label: 'Comuna Pietrari', value: 'Comuna Pietrari' },
  { label: 'Comuna Pietrari', value: 'Comuna Pietrari' },
  { label: 'Comuna Pietroasa', value: 'Comuna Pietroasa' },
  { label: 'Comuna Pietroasa', value: 'Comuna Pietroasa' },
  { label: 'Comuna Pietroasele', value: 'Comuna Pietroasele' },
  { label: 'Comuna Pietroşani', value: 'Comuna Pietroşani' },
  { label: 'Comuna Pietroşani', value: 'Comuna Pietroşani' },
  { label: 'Comuna Pietroşiţa', value: 'Comuna Pietroşiţa' },
  { label: 'Comuna Pilu', value: 'Comuna Pilu' },
  { label: 'Comuna Pipirig', value: 'Comuna Pipirig' },
  { label: 'Comuna Pir', value: 'Comuna Pir' },
  { label: 'Comuna Pişchia', value: 'Comuna Pişchia' },
  { label: 'Comuna Pişcolt', value: 'Comuna Pişcolt' },
  { label: 'Comuna Piscu', value: 'Comuna Piscu' },
  { label: 'Comuna Piscu Vechi', value: 'Comuna Piscu Vechi' },
  { label: 'Comuna Plãieşii De Jos', value: 'Comuna Plãieşii De Jos' },
  { label: 'Comuna Plătăreşti', value: 'Comuna Plătăreşti' },
  { label: 'Comuna Platoneşti', value: 'Comuna Platoneşti' },
  { label: 'Comuna Pleniţa', value: 'Comuna Pleniţa' },
  { label: 'Comuna Pleşcuţa', value: 'Comuna Pleşcuţa' },
  { label: 'Comuna Pleşoi', value: 'Comuna Pleşoi' },
  { label: 'Comuna Pleşoiu', value: 'Comuna Pleşoiu' },
  { label: 'Comuna Plopana', value: 'Comuna Plopana' },
  { label: 'Comuna Plopii Slăviţeşti', value: 'Comuna Plopii Slăviţeşti' },
  { label: 'Comuna Plopiş', value: 'Comuna Plopiş' },
  { label: 'Comuna Plopşoru', value: 'Comuna Plopşoru' },
  { label: 'Comuna Plopu', value: 'Comuna Plopu' },
  { label: 'Comuna Plosca', value: 'Comuna Plosca' },
  { label: 'Comuna Ploscoş', value: 'Comuna Ploscoş' },
  { label: 'Comuna Ploscuţeni', value: 'Comuna Ploscuţeni' },
  { label: 'Comuna Plugari', value: 'Comuna Plugari' },
  { label: 'Comuna Poarta Albă', value: 'Comuna Poarta Albă' },
  { label: 'Comuna Poboru', value: 'Comuna Poboru' },
  { label: 'Comuna Pochidia', value: 'Comuna Pochidia' },
  { label: 'Comuna Pocola', value: 'Comuna Pocola' },
  { label: 'Comuna Podari', value: 'Comuna Podari' },
  { label: 'Comuna Podeni', value: 'Comuna Podeni' },
  { label: 'Comuna Podenii Noi', value: 'Comuna Podenii Noi' },
  { label: 'Comuna Podgoria', value: 'Comuna Podgoria' },
  { label: 'Comuna Podoleni', value: 'Comuna Podoleni' },
  { label: 'Comuna Podu Turcului', value: 'Comuna Podu Turcului' },
  { label: 'Comuna Poduri', value: 'Comuna Poduri' },
  { label: 'Comuna Poeni', value: 'Comuna Poeni' },
  { label: 'Comuna Pogana', value: 'Comuna Pogana' },
  { label: 'Comuna Pogoneşti', value: 'Comuna Pogoneşti' },
  { label: 'Comuna Poian', value: 'Comuna Poian' },
  { label: 'Comuna Poiana', value: 'Comuna Poiana' },
  { label: 'Comuna Poiana', value: 'Comuna Poiana' },
  { label: 'Comuna Poiana  Ilvei', value: 'Comuna Poiana  Ilvei' },
  { label: 'Comuna Poiana Blenchii', value: 'Comuna Poiana Blenchii' },
  { label: 'Comuna Poiana Câmpina', value: 'Comuna Poiana Câmpina' },
  { label: 'Comuna Poiana Cristei', value: 'Comuna Poiana Cristei' },
  { label: 'Comuna Poiana Lacului', value: 'Comuna Poiana Lacului' },
  { label: 'Comuna Poiana Mare', value: 'Comuna Poiana Mare' },
  { label: 'Comuna Poiana Mărului (Brașov)', value: 'Comuna Poiana Mărului (Brașov)' },
  { label: 'Comuna Poiana Sibiului', value: 'Comuna Poiana Sibiului' },
  { label: 'Comuna Poiana Stampei', value: 'Comuna Poiana Stampei' },
  { label: 'Comuna Poiana Teiului', value: 'Comuna Poiana Teiului' },
  { label: 'Comuna Poiana Vadului', value: 'Comuna Poiana Vadului' },
  { label: 'Comuna Poienari', value: 'Comuna Poienari' },
  { label: 'Comuna Poienarii Burchii', value: 'Comuna Poienarii Burchii' },
  { label: 'Comuna Poienarii De Argeş', value: 'Comuna Poienarii De Argeş' },
  { label: 'Comuna Poienarii De Muscel', value: 'Comuna Poienarii De Muscel' },
  { label: 'Comuna Poieneşti', value: 'Comuna Poieneşti' },
  { label: 'Comuna Poieni', value: 'Comuna Poieni' },
  { label: 'Comuna Poienile De Sub Munte', value: 'Comuna Poienile De Sub Munte' },
  { label: 'Comuna Poienile Izei', value: 'Comuna Poienile Izei' },
  { label: 'Comuna Poieni-Solca', value: 'Comuna Poieni-Solca' },
  { label: 'Comuna Pojejena', value: 'Comuna Pojejena' },
  { label: 'Comuna Pojorâta', value: 'Comuna Pojorâta' },
  { label: 'Comuna Polovragi', value: 'Comuna Polovragi' },
  { label: 'Comuna Pomârla', value: 'Comuna Pomârla' },
  { label: 'Comuna Pomezeu', value: 'Comuna Pomezeu' },
  { label: 'Comuna Pomi', value: 'Comuna Pomi' },
  { label: 'Comuna Ponoarele', value: 'Comuna Ponoarele' },
  { label: 'Comuna Ponor', value: 'Comuna Ponor' },
  { label: 'Comuna Popeşti', value: 'Comuna Popeşti' },
  { label: 'Comuna Popeşti', value: 'Comuna Popeşti' },
  { label: 'Comuna Popeşti', value: 'Comuna Popeşti' },
  { label: 'Comuna Popeşti', value: 'Comuna Popeşti' },
  { label: 'Comuna Popeşti', value: 'Comuna Popeşti' },
  { label: 'Comuna Poplaca', value: 'Comuna Poplaca' },
  { label: 'Comuna Popricani', value: 'Comuna Popricani' },
  { label: 'Comuna Poroina Mare', value: 'Comuna Poroina Mare' },
  { label: 'Comuna Poroschia', value: 'Comuna Poroschia' },
  { label: 'Comuna Porumbacu De Jos', value: 'Comuna Porumbacu De Jos' },
  { label: 'Comuna Porumbeni', value: 'Comuna Porumbeni' },
  { label: 'Comuna Porumbeşti', value: 'Comuna Porumbeşti' },
  { label: 'Comuna Poşaga', value: 'Comuna Poşaga' },
  { label: 'Comuna Poseşti', value: 'Comuna Poseşti' },
  { label: 'Comuna Poşta Câlnãu', value: 'Comuna Poşta Câlnãu' },
  { label: 'Comuna Potlogi', value: 'Comuna Potlogi' },
  { label: 'Comuna Praid', value: 'Comuna Praid' },
  { label: 'Comuna Prăjeni', value: 'Comuna Prăjeni' },
  { label: 'Comuna Prăjeşti', value: 'Comuna Prăjeşti' },
  { label: 'Comuna Predeal-Sărari', value: 'Comuna Predeal-Sărari' },
  { label: 'Comuna Predeşti', value: 'Comuna Predeşti' },
  { label: 'Comuna Prejmer', value: 'Comuna Prejmer' },
  { label: 'Comuna Preuteşti', value: 'Comuna Preuteşti' },
  { label: 'Comuna Priboieni', value: 'Comuna Priboieni' },
  { label: 'Comuna Prigor', value: 'Comuna Prigor' },
  { label: 'Comuna Prigoria', value: 'Comuna Prigoria' },
  { label: 'Comuna Priponeşti', value: 'Comuna Priponeşti' },
  { label: 'Comuna Prisăcani', value: 'Comuna Prisăcani' },
  { label: 'Comuna Priseaca', value: 'Comuna Priseaca' },
  { label: 'Comuna Pristol', value: 'Comuna Pristol' },
  { label: 'Comuna Probota', value: 'Comuna Probota' },
  { label: 'Comuna Produleşti', value: 'Comuna Produleşti' },
  { label: 'Comuna Proviţa De Jos', value: 'Comuna Proviţa De Jos' },
  { label: 'Comuna Proviţa De Sus', value: 'Comuna Proviţa De Sus' },
  { label: 'Comuna Prundeni', value: 'Comuna Prundeni' },
  { label: 'Comuna Prundu', value: 'Comuna Prundu' },
  { label: 'Comuna Prundu Bârgăului', value: 'Comuna Prundu Bârgăului' },
  { label: 'Comuna Prunişor', value: 'Comuna Prunişor' },
  { label: 'Comuna Pucheni', value: 'Comuna Pucheni' },
  { label: 'Comuna Puchenii Mari', value: 'Comuna Puchenii Mari' },
  { label: 'Comuna Pufeşti', value: 'Comuna Pufeşti' },
  { label: 'Comuna Pui', value: 'Comuna Pui' },
  { label: 'Comuna Puieşti', value: 'Comuna Puieşti' },
  { label: 'Comuna Puieşti', value: 'Comuna Puieşti' },
  { label: 'Comuna Pungeşti', value: 'Comuna Pungeşti' },
  { label: 'Comuna Punghina', value: 'Comuna Punghina' },
  { label: 'Comuna Purani', value: 'Comuna Purani' },
  { label: 'Comuna Puşcaşi', value: 'Comuna Puşcaşi' },
  { label: 'Comuna Putineiu', value: 'Comuna Putineiu' },
  { label: 'Comuna Putineiu', value: 'Comuna Putineiu' },
  { label: 'Comuna Putna', value: 'Comuna Putna' },
  { label: 'Comuna Răbăgani', value: 'Comuna Răbăgani' },
  { label: 'Comuna Râca', value: 'Comuna Râca' },
  { label: 'Comuna Răcăciuni', value: 'Comuna Răcăciuni' },
  { label: 'Comuna Răcăşdia', value: 'Comuna Răcăşdia' },
  { label: 'Comuna Răchiţeni', value: 'Comuna Răchiţeni' },
  { label: 'Comuna Răchiţi', value: 'Comuna Răchiţi' },
  { label: 'Comuna Răchitoasa', value: 'Comuna Răchitoasa' },
  { label: 'Comuna Răchitova', value: 'Comuna Răchitova' },
  { label: 'Comuna Raciu', value: 'Comuna Raciu' },
  { label: 'Comuna Răcoasa', value: 'Comuna Răcoasa' },
  { label: 'Comuna Racoş', value: 'Comuna Racoş' },
  { label: 'Comuna Racova', value: 'Comuna Racova' },
  { label: 'Comuna Racoviţa', value: 'Comuna Racoviţa' },
  { label: 'Comuna Racoviţa', value: 'Comuna Racoviţa' },
  { label: 'Comuna Racoviţa', value: 'Comuna Racoviţa' },
  { label: 'Comuna Racoviţa', value: 'Comuna Racoviţa' },
  { label: 'Comuna Racoviţeni', value: 'Comuna Racoviţeni' },
  { label: 'Comuna Racşa', value: 'Comuna Racşa' },
  { label: 'Comuna Racu', value: 'Comuna Racu' },
  { label: 'Comuna Rădăşeni', value: 'Comuna Rădăşeni' },
  { label: 'Comuna Rãdãuţi-Prut', value: 'Comuna Rãdãuţi-Prut' },
  { label: 'Comuna Rădeşti', value: 'Comuna Rădeşti' },
  { label: 'Comuna Rădeşti', value: 'Comuna Rădeşti' },
  { label: 'Comuna Rãdoieşti', value: 'Comuna Rãdoieşti' },
  { label: 'Comuna Radomireşti', value: 'Comuna Radomireşti' },
  { label: 'Comuna Radovan', value: 'Comuna Radovan' },
  { label: 'Comuna Radovanu', value: 'Comuna Radovanu' },
  { label: 'Comuna Răducăneni', value: 'Comuna Răducăneni' },
  { label: 'Comuna Răduleşti', value: 'Comuna Răduleşti' },
  { label: 'Comuna Rafaila', value: 'Comuna Rafaila' },
  { label: 'Comuna Râfov', value: 'Comuna Râfov' },
  { label: 'Comuna Râmeţ', value: 'Comuna Râmeţ' },
  { label: 'Comuna Râmetea', value: 'Comuna Râmetea' },
  { label: 'Comuna Ramna', value: 'Comuna Ramna' },
  { label: 'Comuna Râmnicelu', value: 'Comuna Râmnicelu' },
  { label: 'Comuna Râmnicelu', value: 'Comuna Râmnicelu' },
  { label: 'Comuna Rapoltu Mare', value: 'Comuna Rapoltu Mare' },
  { label: 'Comuna Râşca', value: 'Comuna Râşca' },
  { label: 'Comuna Râșca', value: 'Comuna Râșca' },
  { label: 'Comuna Răscăeţi', value: 'Comuna Răscăeţi' },
  { label: 'Comuna Răşinari', value: 'Comuna Răşinari' },
  { label: 'Comuna Răsmireşti', value: 'Comuna Răsmireşti' },
  { label: 'Comuna Rasova', value: 'Comuna Rasova' },
  { label: 'Comuna Rast', value: 'Comuna Rast' },
  { label: 'Comuna Răstoaca', value: 'Comuna Răstoaca' },
  { label: 'Comuna Răsuceni', value: 'Comuna Răsuceni' },
  { label: 'Comuna Răteşti', value: 'Comuna Răteşti' },
  { label: 'Comuna Râu Alb', value: 'Comuna Râu Alb' },
  { label: 'Comuna Râu De Mori', value: 'Comuna Râu De Mori' },
  { label: 'Comuna Râu Sadului', value: 'Comuna Râu Sadului' },
  { label: 'Comuna Răuceşti', value: 'Comuna Răuceşti' },
  { label: 'Comuna Răuseni', value: 'Comuna Răuseni' },
  { label: 'Comuna Războeni-Cetate', value: 'Comuna Războeni-Cetate' },
  { label: 'Comuna Rãzboieni', value: 'Comuna Rãzboieni' },
  { label: 'Comuna Rãzvad', value: 'Comuna Rãzvad' },
  { label: 'Comuna Rebra', value: 'Comuna Rebra' },
  { label: 'Comuna Rebricea', value: 'Comuna Rebricea' },
  { label: 'Comuna Rebrişoara', value: 'Comuna Rebrişoara' },
  { label: 'Comuna Recea', value: 'Comuna Recea' },
  { label: 'Comuna Recea', value: 'Comuna Recea' },
  { label: 'Comuna Recea', value: 'Comuna Recea' },
  { label: 'Comuna Recea Cristur', value: 'Comuna Recea Cristur' },
  { label: 'Comuna Reci', value: 'Comuna Reci' },
  { label: 'Comuna Redea', value: 'Comuna Redea' },
  { label: 'Comuna Rediu', value: 'Comuna Rediu' },
  { label: 'Comuna Rediu', value: 'Comuna Rediu' },
  { label: 'Comuna Rediu-Tătar', value: 'Comuna Rediu-Tătar' },
  { label: 'Comuna Reghiu', value: 'Comuna Reghiu' },
  { label: 'Comuna Remetea', value: 'Comuna Remetea' },
  { label: 'Comuna Remetea', value: 'Comuna Remetea' },
  { label: 'Comuna Remetea Chioarului', value: 'Comuna Remetea Chioarului' },
  { label: 'Comuna Remetea Mare', value: 'Comuna Remetea Mare' },
  { label: 'Comuna Remeţi', value: 'Comuna Remeţi' },
  { label: 'Comuna Repedea', value: 'Comuna Repedea' },
  { label: 'Comuna Reviga', value: 'Comuna Reviga' },
  { label: 'Comuna Ribiţa', value: 'Comuna Ribiţa' },
  { label: 'Comuna Rieni', value: 'Comuna Rieni' },
  { label: 'Comuna Ripiceni', value: 'Comuna Ripiceni' },
  { label: 'Comuna Roata De Jos', value: 'Comuna Roata De Jos' },
  { label: 'Comuna Robăneşti', value: 'Comuna Robăneşti' },
  { label: 'Comuna Robeasca', value: 'Comuna Robeasca' },
  { label: 'Comuna Rociu', value: 'Comuna Rociu' },
  { label: 'Comuna Rodna', value: 'Comuna Rodna' },
  { label: 'Comuna Roeşti', value: 'Comuna Roeşti' },
  { label: 'Comuna Rogova', value: 'Comuna Rogova' },
  { label: 'Comuna Rojişte', value: 'Comuna Rojişte' },
  { label: 'Comuna Roma', value: 'Comuna Roma' },
  { label: 'Comuna Românaşi', value: 'Comuna Românaşi' },
  { label: 'Comuna Româneşti', value: 'Comuna Româneşti' },
  { label: 'Comuna Româneşti', value: 'Comuna Româneşti' },
  { label: 'Comuna Români', value: 'Comuna Români' },
  { label: 'Comuna Romanu', value: 'Comuna Romanu' },
  { label: 'Comuna Romos', value: 'Comuna Romos' },
  { label: 'Comuna Romuli', value: 'Comuna Romuli' },
  { label: 'Comuna Rona De Jos', value: 'Comuna Rona De Jos' },
  { label: 'Comuna Rona De Sus', value: 'Comuna Rona De Sus' },
  { label: 'Comuna Roşcani', value: 'Comuna Roşcani' },
  { label: 'Comuna Roseţi', value: 'Comuna Roseţi' },
  { label: 'Comuna Roşia', value: 'Comuna Roşia' },
  { label: 'Comuna Roşia', value: 'Comuna Roşia' },
  { label: 'Comuna Roşia De Amaradia', value: 'Comuna Roşia De Amaradia' },
  { label: 'Comuna Roşia De Secaş', value: 'Comuna Roşia De Secaş' },
  { label: 'Comuna Roşia Montană', value: 'Comuna Roşia Montană' },
  { label: 'Comuna Roşieşti', value: 'Comuna Roşieşti' },
  { label: 'Comuna Roşiile', value: 'Comuna Roşiile' },
  { label: 'Comuna Roşiori', value: 'Comuna Roşiori' },
  { label: 'Comuna Roşiori', value: 'Comuna Roşiori' },
  { label: 'Comuna Roşiori', value: 'Comuna Roşiori' },
  { label: 'Comuna Roşiori', value: 'Comuna Roşiori' },
  { label: 'Comuna Rotunda', value: 'Comuna Rotunda' },
  { label: 'Comuna Rozavlea', value: 'Comuna Rozavlea' },
  { label: 'Comuna Rucăr', value: 'Comuna Rucăr' },
  { label: 'Comuna Rugineşti', value: 'Comuna Rugineşti' },
  { label: 'Comuna Ruginoasa', value: 'Comuna Ruginoasa' },
  { label: 'Comuna Ruginoasa', value: 'Comuna Ruginoasa' },
  { label: 'Comuna Runcu', value: 'Comuna Runcu' },
  { label: 'Comuna Runcu', value: 'Comuna Runcu' },
  { label: 'Comuna Runcu', value: 'Comuna Runcu' },
  { label: 'Comuna Runcu Salvei', value: 'Comuna Runcu Salvei' },
  { label: 'Comuna Rus', value: 'Comuna Rus' },
  { label: 'Comuna Rusăneşti', value: 'Comuna Rusăneşti' },
  { label: 'Comuna Rusca Montană', value: 'Comuna Rusca Montană' },
  { label: 'Comuna Ruşcova', value: 'Comuna Ruşcova' },
  { label: 'Comuna Ruşeţu', value: 'Comuna Ruşeţu' },
  { label: 'Comuna Rușii Munți', value: 'Comuna Rușii Munți' },
  { label: 'Comuna Săbăoani', value: 'Comuna Săbăoani' },
  { label: 'Comuna Săbăreni', value: 'Comuna Săbăreni' },
  { label: 'Comuna Săcădat', value: 'Comuna Săcădat' },
  { label: 'Comuna Săcălăşeni', value: 'Comuna Săcălăşeni' },
  { label: 'Comuna Săcălaz', value: 'Comuna Săcălaz' },
  { label: 'Comuna Săcăşeni', value: 'Comuna Săcăşeni' },
  { label: 'Comuna Săcel', value: 'Comuna Săcel' },
  { label: 'Comuna Săcel', value: 'Comuna Săcel' },
  { label: 'Comuna Săcele', value: 'Comuna Săcele' },
  { label: 'Comuna Săcelu', value: 'Comuna Săcelu' },
  { label: 'Comuna Săceni', value: 'Comuna Săceni' },
  { label: 'Comuna Sacoşu Turcesc', value: 'Comuna Sacoşu Turcesc' },
  { label: 'Comuna Sacu', value: 'Comuna Sacu' },
  { label: 'Comuna Sãcuieu', value: 'Comuna Sãcuieu' },
  { label: 'Comuna Sadova', value: 'Comuna Sadova' },
  { label: 'Comuna Sadova', value: 'Comuna Sadova' },
  { label: 'Comuna Sadu', value: 'Comuna Sadu' },
  { label: 'Comuna Saelele', value: 'Comuna Saelele' },
  { label: 'Comuna Sâg', value: 'Comuna Sâg' },
  { label: 'Comuna Şag', value: 'Comuna Şag' },
  { label: 'Comuna Săgeata', value: 'Comuna Săgeata' },
  { label: 'Comuna Sagna', value: 'Comuna Sagna' },
  { label: 'Comuna Şagu', value: 'Comuna Şagu' },
  { label: 'Comuna Săhăteni', value: 'Comuna Săhăteni' },
  { label: 'Comuna Sălacea', value: 'Comuna Sălacea' },
  { label: 'Comuna Sălard', value: 'Comuna Sălard' },
  { label: 'Comuna Sãlaşu De Sus', value: 'Comuna Sãlaşu De Sus' },
  { label: 'Comuna Sălăţig', value: 'Comuna Sălăţig' },
  { label: 'Comuna Sălătrucel', value: 'Comuna Sălătrucel' },
  { label: 'Comuna Sălătrucu', value: 'Comuna Sălătrucu' },
  { label: 'Comuna Salcia', value: 'Comuna Salcia' },
  { label: 'Comuna Salcia', value: 'Comuna Salcia' },
  { label: 'Comuna Salcia', value: 'Comuna Salcia' },
  { label: 'Comuna Salcia Tudor', value: 'Comuna Salcia Tudor' },
  { label: 'Comuna Sălciile', value: 'Comuna Sălciile' },
  { label: 'Comuna Sălcioara', value: 'Comuna Sălcioara' },
  { label: 'Comuna Sălcioara', value: 'Comuna Sălcioara' },
  { label: 'Comuna Sălciua', value: 'Comuna Sălciua' },
  { label: 'Comuna Sălcuţa', value: 'Comuna Sălcuţa' },
  { label: 'Comuna Saligny', value: 'Comuna Saligny' },
  { label: 'Comuna Sãliştea', value: 'Comuna Sãliştea' },
  { label: 'Comuna Sălsig', value: 'Comuna Sălsig' },
  { label: 'Comuna Salva', value: 'Comuna Salva' },
  { label: 'Comuna Samarineşti', value: 'Comuna Samarineşti' },
  { label: 'Comuna Sâmbăta', value: 'Comuna Sâmbăta' },
  { label: 'Comuna Sâmbăta De Sus', value: 'Comuna Sâmbăta De Sus' },
  { label: 'Comuna Sâmbureşti', value: 'Comuna Sâmbureşti' },
  { label: 'Comuna Şamşud', value: 'Comuna Şamşud' },
  { label: 'Comuna Sânandrei', value: 'Comuna Sânandrei' },
  { label: 'Comuna Sâncel', value: 'Comuna Sâncel' },
  { label: 'Comuna Sâncrăieni', value: 'Comuna Sâncrăieni' },
  { label: 'Comuna Sâncraiu', value: 'Comuna Sâncraiu' },
  { label: 'Comuna Sândominic', value: 'Comuna Sândominic' },
  { label: 'Comuna Şandra', value: 'Comuna Şandra' },
  { label: 'Comuna Sănduleni', value: 'Comuna Sănduleni' },
  { label: 'Comuna Sănduleşti', value: 'Comuna Sănduleşti' },
  { label: 'Comuna Sângeru', value: 'Comuna Sângeru' },
  { label: 'Comuna Sâniob', value: 'Comuna Sâniob' },
  { label: 'Comuna Sanislău', value: 'Comuna Sanislău' },
  { label: 'Comuna Sânmartin', value: 'Comuna Sânmartin' },
  { label: 'Comuna Sânmartin', value: 'Comuna Sânmartin' },
  { label: 'Comuna Sânmărtin', value: 'Comuna Sânmărtin' },
  { label: 'Comuna Sânmihaiu Almaşului', value: 'Comuna Sânmihaiu Almaşului' },
  { label: 'Comuna Sânmihaiu De Câmpie', value: 'Comuna Sânmihaiu De Câmpie' },
  { label: 'Comuna Sânmihaiu Român', value: 'Comuna Sânmihaiu Român' },
  { label: 'Comuna Sânnicolau-Român', value: 'Comuna Sânnicolau-Român' },
  { label: 'Comuna Sânpaul', value: 'Comuna Sânpaul' },
  { label: 'Comuna Sânpetru', value: 'Comuna Sânpetru' },
  { label: 'Comuna Sânpetru Mare', value: 'Comuna Sânpetru Mare' },
  { label: 'Comuna Sânsimion', value: 'Comuna Sânsimion' },
  { label: 'Comuna Şanţ', value: 'Comuna Şanţ' },
  { label: 'Comuna Santa Mare', value: 'Comuna Santa Mare' },
  { label: 'Comuna Sântămăria-Orlea', value: 'Comuna Sântămăria-Orlea' },
  { label: 'Comuna Sântandrei', value: 'Comuna Sântandrei' },
  { label: 'Comuna Santău', value: 'Comuna Santău' },
  { label: 'Comuna Sântimbru', value: 'Comuna Sântimbru' },
  { label: 'Comuna Sântimbru', value: 'Comuna Sântimbru' },
  { label: 'Comuna Sânzieni', value: 'Comuna Sânzieni' },
  { label: 'Comuna Sãpânţa', value: 'Comuna Sãpânţa' },
  { label: 'Comuna Săpata', value: 'Comuna Săpata' },
  { label: 'Comuna Săpoca', value: 'Comuna Săpoca' },
  { label: 'Comuna Saraiu', value: 'Comuna Saraiu' },
  { label: 'Comuna Sărăsău', value: 'Comuna Sărăsău' },
  { label: 'Comuna Sărata', value: 'Comuna Sărata' },
  { label: 'Comuna Sărăţeni', value: 'Comuna Sărăţeni' },
  { label: 'Comuna Saravale', value: 'Comuna Saravale' },
  { label: 'Comuna Sârbeni', value: 'Comuna Sârbeni' },
  { label: 'Comuna Sârbi', value: 'Comuna Sârbi' },
  { label: 'Comuna Sârbii-Măgura', value: 'Comuna Sârbii-Măgura' },
  { label: 'Comuna Sarichioi', value: 'Comuna Sarichioi' },
  { label: 'Comuna Sărmaş', value: 'Comuna Sărmaş' },
  { label: 'Comuna Şărmăşag', value: 'Comuna Şărmăşag' },
  { label: 'Comuna Sarmizegetusa', value: 'Comuna Sarmizegetusa' },
  { label: 'Comuna Şaru Dornei', value: 'Comuna Şaru Dornei' },
  { label: 'Comuna Săruleşti', value: 'Comuna Săruleşti' },
  { label: 'Comuna Săruleşti', value: 'Comuna Săruleşti' },
  { label: 'Comuna Sasca Montană', value: 'Comuna Sasca Montană' },
  { label: 'Comuna Săsciori', value: 'Comuna Săsciori' },
  { label: 'Comuna Sascut', value: 'Comuna Sascut' },
  { label: 'Comuna Satchinez', value: 'Comuna Satchinez' },
  { label: 'Comuna Satu Mare', value: 'Comuna Satu Mare' },
  { label: 'Comuna Satu Mare', value: 'Comuna Satu Mare' },
  { label: 'Comuna Satulung', value: 'Comuna Satulung' },
  { label: 'Comuna Săuca', value: 'Comuna Săuca' },
  { label: 'Comuna Săuceşti', value: 'Comuna Săuceşti' },
  { label: 'Comuna Săuleşti', value: 'Comuna Săuleşti' },
  { label: 'Comuna Săvădisla', value: 'Comuna Săvădisla' },
  { label: 'Comuna Săvârşin', value: 'Comuna Săvârşin' },
  { label: 'Comuna Săveni', value: 'Comuna Săveni' },
  { label: 'Comuna Săvineşti', value: 'Comuna Săvineşti' },
  { label: 'Comuna Scăeşti', value: 'Comuna Scăeşti' },
  { label: 'Comuna Scânteia', value: 'Comuna Scânteia' },
  { label: 'Comuna Scânteia', value: 'Comuna Scânteia' },
  { label: 'Comuna Scânteieşti', value: 'Comuna Scânteieşti' },
  { label: 'Comuna Scărişoara', value: 'Comuna Scărişoara' },
  { label: 'Comuna Scărişoara', value: 'Comuna Scărişoara' },
  { label: 'Comuna Scheia', value: 'Comuna Scheia' },
  { label: 'Comuna Scheia', value: 'Comuna Scheia' },
  { label: 'Comuna Schela', value: 'Comuna Schela' },
  { label: 'Comuna Schela', value: 'Comuna Schela' },
  { label: 'Comuna Schitu', value: 'Comuna Schitu' },
  { label: 'Comuna Schitu', value: 'Comuna Schitu' },
  { label: 'Comuna Schitu-Duca', value: 'Comuna Schitu-Duca' },
  { label: 'Comuna Schitu-Goleşti', value: 'Comuna Schitu-Goleşti' },
  { label: 'Comuna Scoarţa', value: 'Comuna Scoarţa' },
  { label: 'Comuna Scobinţi', value: 'Comuna Scobinţi' },
  { label: 'Comuna Scorţaru Nou', value: 'Comuna Scorţaru Nou' },
  { label: 'Comuna Scorţeni', value: 'Comuna Scorţeni' },
  { label: 'Comuna Scorţeni', value: 'Comuna Scorţeni' },
  { label: 'Comuna Scorţoasa', value: 'Comuna Scorţoasa' },
  { label: 'Comuna Scrioaştea', value: 'Comuna Scrioaştea' },
  { label: 'Comuna Scundu', value: 'Comuna Scundu' },
  { label: 'Comuna Scurtu Mare', value: 'Comuna Scurtu Mare' },
  { label: 'Comuna Scutelnici', value: 'Comuna Scutelnici' },
  { label: 'Comuna Seaca', value: 'Comuna Seaca' },
  { label: 'Comuna Seaca', value: 'Comuna Seaca' },
  { label: 'Comuna Seaca De Câmp', value: 'Comuna Seaca De Câmp' },
  { label: 'Comuna Seaca De Pădure', value: 'Comuna Seaca De Pădure' },
  { label: 'Comuna Secăria', value: 'Comuna Secăria' },
  { label: 'Comuna Secaş', value: 'Comuna Secaş' },
  { label: 'Comuna Secu', value: 'Comuna Secu' },
  { label: 'Comuna Secuieni', value: 'Comuna Secuieni' },
  { label: 'Comuna Secuieni', value: 'Comuna Secuieni' },
  { label: 'Comuna Secuieni', value: 'Comuna Secuieni' },
  { label: 'Comuna Secusigiu', value: 'Comuna Secusigiu' },
  { label: 'Comuna Segarcea Vale', value: 'Comuna Segarcea Vale' },
  { label: 'Comuna Şeica Mare', value: 'Comuna Şeica Mare' },
  { label: 'Comuna Şeica Mică', value: 'Comuna Şeica Mică' },
  { label: 'Comuna Seimeni', value: 'Comuna Seimeni' },
  { label: 'Comuna Şeitin', value: 'Comuna Şeitin' },
  { label: 'Comuna Şelaru', value: 'Comuna Şelaru' },
  { label: 'Comuna Seleuş', value: 'Comuna Seleuş' },
  { label: 'Comuna Şelimbăr', value: 'Comuna Şelimbăr' },
  { label: 'Comuna Semlac', value: 'Comuna Semlac' },
  { label: 'Comuna Şendreni', value: 'Comuna Şendreni' },
  { label: 'Comuna Şendriceni', value: 'Comuna Şendriceni' },
  { label: 'Comuna Şepreuş', value: 'Comuna Şepreuş' },
  { label: 'Comuna Şerbăneşti', value: 'Comuna Şerbăneşti' },
  { label: 'Comuna Şerbăuţi', value: 'Comuna Şerbăuţi' },
  { label: 'Comuna Şercaia', value: 'Comuna Şercaia' },
  { label: 'Comuna Sfântu Gheorghe', value: 'Comuna Sfântu Gheorghe' },
  { label: 'Comuna Sfântu Gheorghe', value: 'Comuna Sfântu Gheorghe' },
  { label: 'Comuna Sfinţeşti', value: 'Comuna Sfinţeşti' },
  { label: 'Comuna Şibot', value: 'Comuna Şibot' },
  { label: 'Comuna Sic', value: 'Comuna Sic' },
  { label: 'Comuna Sicheviţa', value: 'Comuna Sicheviţa' },
  { label: 'Comuna Şicula', value: 'Comuna Şicula' },
  { label: 'Comuna Siculeni', value: 'Comuna Siculeni' },
  { label: 'Comuna Şieu', value: 'Comuna Şieu' },
  { label: 'Comuna Şieu', value: 'Comuna Şieu' },
  { label: 'Comuna Şieu-Măgheruş', value: 'Comuna Şieu-Măgheruş' },
  { label: 'Comuna Şieu-Odorhei', value: 'Comuna Şieu-Odorhei' },
  { label: 'Comuna Şieuţ', value: 'Comuna Şieuţ' },
  { label: 'Comuna Sihlea', value: 'Comuna Sihlea' },
  { label: 'Comuna Şilindia', value: 'Comuna Şilindia' },
  { label: 'Comuna Siliştea', value: 'Comuna Siliştea' },
  { label: 'Comuna Siliştea', value: 'Comuna Siliştea' },
  { label: 'Comuna Siliştea', value: 'Comuna Siliştea' },
  { label: 'Comuna Siliştea Crucii', value: 'Comuna Siliştea Crucii' },
  { label: 'Comuna Siliștea Gumești', value: 'Comuna Siliștea Gumești' },
  { label: 'Comuna Silivaşu De Câmpie', value: 'Comuna Silivaşu De Câmpie' },
  { label: 'Comuna Şimand', value: 'Comuna Şimand' },
  { label: 'Comuna Simian', value: 'Comuna Simian' },
  { label: 'Comuna Şimian', value: 'Comuna Şimian' },
  { label: 'Comuna Siminicea', value: 'Comuna Siminicea' },
  { label: 'Comuna Şimişna', value: 'Comuna Şimişna' },
  { label: 'Comuna Şimnicu De Sus', value: 'Comuna Şimnicu De Sus' },
  { label: 'Comuna Simoneşti', value: 'Comuna Simoneşti' },
  { label: 'Comuna Şinca Nouă', value: 'Comuna Şinca Nouă' },
  { label: 'Comuna Şinca Veche', value: 'Comuna Şinca Veche' },
  { label: 'Comuna Sineşti', value: 'Comuna Sineşti' },
  { label: 'Comuna Sineşti', value: 'Comuna Sineşti' },
  { label: 'Comuna Singureni', value: 'Comuna Singureni' },
  { label: 'Comuna Sintea Mare', value: 'Comuna Sintea Mare' },
  { label: 'Comuna Şintereag', value: 'Comuna Şintereag' },
  { label: 'Comuna Sinteşti', value: 'Comuna Sinteşti' },
  { label: 'Comuna Şinteu', value: 'Comuna Şinteu' },
  { label: 'Comuna Şipote', value: 'Comuna Şipote' },
  { label: 'Comuna Sireţel', value: 'Comuna Sireţel' },
  { label: 'Comuna Şiria', value: 'Comuna Şiria' },
  { label: 'Comuna Şirineasa', value: 'Comuna Şirineasa' },
  { label: 'Comuna Siriu', value: 'Comuna Siriu' },
  { label: 'Comuna Şirna', value: 'Comuna Şirna' },
  { label: 'Comuna Siseşti', value: 'Comuna Siseşti' },
  { label: 'Comuna Şişeşti', value: 'Comuna Şişeşti' },
  { label: 'Comuna Şiştarovăţ', value: 'Comuna Şiştarovăţ' },
  { label: 'Comuna Sita Buzăului', value: 'Comuna Sita Buzăului' },
  { label: 'Comuna Slatina', value: 'Comuna Slatina' },
  { label: 'Comuna Slatina-Timiş', value: 'Comuna Slatina-Timiş' },
  { label: 'Comuna Slătioara', value: 'Comuna Slătioara' },
  { label: 'Comuna Slătioara', value: 'Comuna Slătioara' },
  { label: 'Comuna Slava Cercheză', value: 'Comuna Slava Cercheză' },
  { label: 'Comuna Slimnic', value: 'Comuna Slimnic' },
  { label: 'Comuna Slivileşti', value: 'Comuna Slivileşti' },
  { label: 'Comuna Slobozia', value: 'Comuna Slobozia' },
  { label: 'Comuna Slobozia', value: 'Comuna Slobozia' },
  { label: 'Comuna Slobozia', value: 'Comuna Slobozia' },
  { label: 'Comuna Slobozia Bradului', value: 'Comuna Slobozia Bradului' },
  { label: 'Comuna Slobozia Conachi', value: 'Comuna Slobozia Conachi' },
  { label: 'Comuna Slobozia Moara', value: 'Comuna Slobozia Moara' },
  { label: 'Comuna Slobozia-Ciorăşti', value: 'Comuna Slobozia-Ciorăşti' },
  { label: 'Comuna Slobozia-Mândra', value: 'Comuna Slobozia-Mândra' },
  { label: 'Comuna Smârdan', value: 'Comuna Smârdan' },
  { label: 'Comuna Smârdan', value: 'Comuna Smârdan' },
  { label: 'Comuna Smârdioasa', value: 'Comuna Smârdioasa' },
  { label: 'Comuna Smeeni', value: 'Comuna Smeeni' },
  { label: 'Comuna Smulţi', value: 'Comuna Smulţi' },
  { label: 'Comuna Snagov', value: 'Comuna Snagov' },
  { label: 'Comuna Şoarş', value: 'Comuna Şoarş' },
  { label: 'Comuna Socodor', value: 'Comuna Socodor' },
  { label: 'Comuna Socol', value: 'Comuna Socol' },
  { label: 'Comuna Socond', value: 'Comuna Socond' },
  { label: 'Comuna Şofronea', value: 'Comuna Şofronea' },
  { label: 'Comuna Sohatu', value: 'Comuna Sohatu' },
  { label: 'Comuna Sohodol', value: 'Comuna Sohodol' },
  { label: 'Comuna Şoimari', value: 'Comuna Şoimari' },
  { label: 'Comuna Şoimi', value: 'Comuna Şoimi' },
  { label: 'Comuna Şoimuş', value: 'Comuna Şoimuş' },
  { label: 'Comuna Şoldanu', value: 'Comuna Şoldanu' },
  { label: 'Comuna Soleşti', value: 'Comuna Soleşti' },
  { label: 'Comuna Solonţ', value: 'Comuna Solonţ' },
  { label: 'Comuna Someş-Odorhei', value: 'Comuna Someş-Odorhei' },
  { label: 'Comuna Somova', value: 'Comuna Somova' },
  { label: 'Comuna Şona', value: 'Comuna Şona' },
  { label: 'Comuna Şopârliţa', value: 'Comuna Şopârliţa' },
  { label: 'Comuna Sopot', value: 'Comuna Sopot' },
  { label: 'Comuna Şopotu Nou', value: 'Comuna Şopotu Nou' },
  { label: 'Comuna Şotânga', value: 'Comuna Şotânga' },
  { label: 'Comuna Şotrile', value: 'Comuna Şotrile' },
  { label: 'Comuna Sovarna', value: 'Comuna Sovarna' },
  { label: 'Comuna Soveja', value: 'Comuna Soveja' },
  { label: 'Comuna Spanţov', value: 'Comuna Spanţov' },
  { label: 'Comuna Spermezeu', value: 'Comuna Spermezeu' },
  { label: 'Comuna Spineni', value: 'Comuna Spineni' },
  { label: 'Comuna Spinuş', value: 'Comuna Spinuş' },
  { label: 'Comuna Sprâncenata', value: 'Comuna Sprâncenata' },
  { label: 'Comuna Şpring', value: 'Comuna Şpring' },
  { label: 'Comuna Spulber', value: 'Comuna Spulber' },
  { label: 'Comuna Stâlpeni', value: 'Comuna Stâlpeni' },
  { label: 'Comuna Stâlpu', value: 'Comuna Stâlpu' },
  { label: 'Comuna Stăncuţa', value: 'Comuna Stăncuţa' },
  { label: 'Comuna Stăneşti', value: 'Comuna Stăneşti' },
  { label: 'Comuna Stăneşti', value: 'Comuna Stăneşti' },
  { label: 'Comuna Stăneşti', value: 'Comuna Stăneşti' },
  { label: 'Comuna Stângăceaua', value: 'Comuna Stângăceaua' },
  { label: 'Comuna Stănileşti', value: 'Comuna Stănileşti' },
  { label: 'Comuna Stănişeşti', value: 'Comuna Stănişeşti' },
  { label: 'Comuna Stăniţa', value: 'Comuna Stăniţa' },
  { label: 'Comuna Starchiojd', value: 'Comuna Starchiojd' },
  { label: 'Comuna Stăuceni', value: 'Comuna Stăuceni' },
  { label: 'Comuna Ştefan Cel Mare', value: 'Comuna Ştefan Cel Mare' },
  { label: 'Comuna Ştefan Cel Mare', value: 'Comuna Ştefan Cel Mare' },
  { label: 'Comuna Ştefan Cel Mare', value: 'Comuna Ştefan Cel Mare' },
  { label: 'Comuna Ştefan Cel Mare', value: 'Comuna Ştefan Cel Mare' },
  { label: 'Comuna Ştefan Cel Mare', value: 'Comuna Ştefan Cel Mare' },
  { label: 'Comuna Ştefan Cel Mare', value: 'Comuna Ştefan Cel Mare' },
  { label: 'Comuna Ştefan Vodă', value: 'Comuna Ştefan Vodă' },
  { label: 'Comuna Ştefăneşti', value: 'Comuna Ştefăneşti' },
  { label: 'Comuna Ştefãneştii De Jos', value: 'Comuna Ştefãneştii De Jos' },
  { label: 'Comuna Ştefeşti', value: 'Comuna Ştefeşti' },
  { label: 'Comuna Stejari', value: 'Comuna Stejari' },
  { label: 'Comuna Stejaru', value: 'Comuna Stejaru' },
  { label: 'Comuna Stejaru', value: 'Comuna Stejaru' },
  { label: 'Comuna Stelnica', value: 'Comuna Stelnica' },
  { label: 'Comuna Ştiubieni', value: 'Comuna Ştiubieni' },
  { label: 'Comuna Ştiuca', value: 'Comuna Ştiuca' },
  { label: 'Comuna Stoeneşti', value: 'Comuna Stoeneşti' },
  { label: 'Comuna Stoeneşti', value: 'Comuna Stoeneşti' },
  { label: 'Comuna Stoeneşti', value: 'Comuna Stoeneşti' },
  { label: 'Comuna Stoeneşti', value: 'Comuna Stoeneşti' },
  { label: 'Comuna Stoicăneşti', value: 'Comuna Stoicăneşti' },
  { label: 'Comuna Stoileşti', value: 'Comuna Stoileşti' },
  { label: 'Comuna Stoina', value: 'Comuna Stoina' },
  { label: 'Comuna Stolniceni-Prăjescu', value: 'Comuna Stolniceni-Prăjescu' },
  { label: 'Comuna Stolnici', value: 'Comuna Stolnici' },
  { label: 'Comuna Storobăneasa', value: 'Comuna Storobăneasa' },
  { label: 'Comuna Straja', value: 'Comuna Straja' },
  { label: 'Comuna Strâmtura', value: 'Comuna Strâmtura' },
  { label: 'Comuna Străoane', value: 'Comuna Străoane' },
  { label: 'Comuna Strejeşti', value: 'Comuna Strejeşti' },
  { label: 'Comuna Stremţ', value: 'Comuna Stremţ' },
  { label: 'Comuna Stroeşti', value: 'Comuna Stroeşti' },
  { label: 'Comuna Stroieşti', value: 'Comuna Stroieşti' },
  { label: 'Comuna Strugari', value: 'Comuna Strugari' },
  { label: 'Comuna Strunga', value: 'Comuna Strunga' },
  { label: 'Comuna Studina', value: 'Comuna Studina' },
  { label: 'Comuna Stulpicani', value: 'Comuna Stulpicani' },
  { label: 'Comuna Suatu', value: 'Comuna Suatu' },
  { label: 'Comuna Subcetate', value: 'Comuna Subcetate' },
  { label: 'Comuna Suceveni', value: 'Comuna Suceveni' },
  { label: 'Comuna Suceviţa', value: 'Comuna Suceviţa' },
  { label: 'Comuna Suciu De Sus', value: 'Comuna Suciu De Sus' },
  { label: 'Comuna Sudiţi', value: 'Comuna Sudiţi' },
  { label: 'Comuna Şugag', value: 'Comuna Şugag' },
  { label: 'Comuna Suhaia', value: 'Comuna Suhaia' },
  { label: 'Comuna Suharău', value: 'Comuna Suharău' },
  { label: 'Comuna Suhurlui', value: 'Comuna Suhurlui' },
  { label: 'Comuna Şuici', value: 'Comuna Şuici' },
  { label: 'Comuna Şuletea', value: 'Comuna Şuletea' },
  { label: 'Comuna Suliţa', value: 'Comuna Suliţa' },
  { label: 'Comuna Şuncuiuş', value: 'Comuna Şuncuiuş' },
  { label: 'Comuna Suplacu De Barcău', value: 'Comuna Suplacu De Barcău' },
  { label: 'Comuna Supur', value: 'Comuna Supur' },
  { label: 'Comuna Şura Mare', value: 'Comuna Şura Mare' },
  { label: 'Comuna Şura Mică', value: 'Comuna Şura Mică' },
  { label: 'Comuna Suraia', value: 'Comuna Suraia' },
  { label: 'Comuna Surani', value: 'Comuna Surani' },
  { label: 'Comuna Surdila-Găiseanca', value: 'Comuna Surdila-Găiseanca' },
  { label: 'Comuna Surdila-Greci', value: 'Comuna Surdila-Greci' },
  { label: 'Comuna Surduc', value: 'Comuna Surduc' },
  { label: 'Comuna Şuşani', value: 'Comuna Şuşani' },
  { label: 'Comuna Suseni', value: 'Comuna Suseni' },
  { label: 'Comuna Suseni', value: 'Comuna Suseni' },
  { label: 'Comuna Suteşti', value: 'Comuna Suteşti' },
  { label: 'Comuna Şuţeşti', value: 'Comuna Şuţeşti' },
  { label: 'Comuna Sviniţa', value: 'Comuna Sviniţa' },
  { label: 'Comuna Tăcuta', value: 'Comuna Tăcuta' },
  { label: 'Comuna Ţaga', value: 'Comuna Ţaga' },
  { label: 'Comuna Talea', value: 'Comuna Talea' },
  { label: 'Comuna Talpa', value: 'Comuna Talpa' },
  { label: 'Comuna Tãlpaş', value: 'Comuna Tãlpaş' },
  { label: 'Comuna Tãmãdãu Mare', value: 'Comuna Tãmãdãu Mare' },
  { label: 'Comuna Tămăşeni', value: 'Comuna Tămăşeni' },
  { label: 'Comuna Tãmãşeu', value: 'Comuna Tãmãşeu' },
  { label: 'Comuna Tamaşi', value: 'Comuna Tamaşi' },
  { label: 'Comuna Tâmboeşti', value: 'Comuna Tâmboeşti' },
  { label: 'Comuna Tâmna', value: 'Comuna Tâmna' },
  { label: 'Comuna Tanacu', value: 'Comuna Tanacu' },
  { label: 'Comuna Tănăsoaia', value: 'Comuna Tănăsoaia' },
  { label: 'Comuna Tansa', value: 'Comuna Tansa' },
  { label: 'Comuna Ţânţăreni', value: 'Comuna Ţânţăreni' },
  { label: 'Comuna Tărcaia', value: 'Comuna Tărcaia' },
  { label: 'Comuna Tarcău', value: 'Comuna Tarcău' },
  { label: 'Comuna Tarcea', value: 'Comuna Tarcea' },
  { label: 'Comuna Târgşoru Vechi', value: 'Comuna Târgşoru Vechi' },
  { label: 'Comuna Târgu Trotuş', value: 'Comuna Târgu Trotuş' },
  { label: 'Comuna Târguşor', value: 'Comuna Târguşor' },
  { label: 'Comuna Târlişua', value: 'Comuna Târlişua' },
  { label: 'Comuna Tărlungeni', value: 'Comuna Tărlungeni' },
  { label: 'Comuna Tarna Mare', value: 'Comuna Tarna Mare' },
  { label: 'Comuna Târnava', value: 'Comuna Târnava' },
  { label: 'Comuna Târnova', value: 'Comuna Târnova' },
  { label: 'Comuna Târnova', value: 'Comuna Târnova' },
  { label: 'Comuna Târşolţ', value: 'Comuna Târşolţ' },
  { label: 'Comuna Tărtăşeşti', value: 'Comuna Tărtăşeşti' },
  { label: 'Comuna Taşca', value: 'Comuna Taşca' },
  { label: 'Comuna Tătărani', value: 'Comuna Tătărani' },
  { label: 'Comuna Tătărăni', value: 'Comuna Tătărăni' },
  { label: 'Comuna Tãtãranu', value: 'Comuna Tãtãranu' },
  { label: 'Comuna Tătărăşti', value: 'Comuna Tătărăşti' },
  { label: 'Comuna Tătărăştii De Jos', value: 'Comuna Tătărăştii De Jos' },
  { label: 'Comuna Tătărăştii De Sus', value: 'Comuna Tătărăştii De Sus' },
  { label: 'Comuna Tătaru', value: 'Comuna Tătaru' },
  { label: 'Comuna Tătăruşi', value: 'Comuna Tătăruşi' },
  { label: 'Comuna Tătuleşti', value: 'Comuna Tătuleşti' },
  { label: 'Comuna Tauţ', value: 'Comuna Tauţ' },
  { label: 'Comuna Tăuteu', value: 'Comuna Tăuteu' },
  { label: 'Comuna Tazlău', value: 'Comuna Tazlău' },
  { label: 'Comuna Teaca', value: 'Comuna Teaca' },
  { label: 'Comuna Teasc', value: 'Comuna Teasc' },
  { label: 'Comuna Teişani', value: 'Comuna Teişani' },
  { label: 'Comuna Teiu', value: 'Comuna Teiu' },
  { label: 'Comuna Telciu', value: 'Comuna Telciu' },
  { label: 'Comuna Telega', value: 'Comuna Telega' },
  { label: 'Comuna Teleşti', value: 'Comuna Teleşti' },
  { label: 'Comuna Teliu', value: 'Comuna Teliu' },
  { label: 'Comuna Teliucu Inferior', value: 'Comuna Teliucu Inferior' },
  { label: 'Comuna Ţepu', value: 'Comuna Ţepu' },
  { label: 'Comuna Terebeşti', value: 'Comuna Terebeşti' },
  { label: 'Comuna Teregova', value: 'Comuna Teregova' },
  { label: 'Comuna Teremia Mare', value: 'Comuna Teremia Mare' },
  { label: 'Comuna Terpeziţa', value: 'Comuna Terpeziţa' },
  { label: 'Comuna Teslui', value: 'Comuna Teslui' },
  { label: 'Comuna Teslui', value: 'Comuna Teslui' },
  { label: 'Comuna Ţeţchea', value: 'Comuna Ţeţchea' },
  { label: 'Comuna Tetoiu', value: 'Comuna Tetoiu' },
  { label: 'Comuna Tia Mare', value: 'Comuna Tia Mare' },
  { label: 'Comuna Ţibana', value: 'Comuna Ţibana' },
  { label: 'Comuna Ţibăneşti', value: 'Comuna Ţibăneşti' },
  { label: 'Comuna Ţibucani', value: 'Comuna Ţibucani' },
  { label: 'Comuna Tichileşti', value: 'Comuna Tichileşti' },
  { label: 'Comuna Ticuşu Vechi', value: 'Comuna Ticuşu Vechi' },
  { label: 'Comuna Ticvaniu Mare', value: 'Comuna Ticvaniu Mare' },
  { label: 'Comuna Ţifeşti', value: 'Comuna Ţifeşti' },
  { label: 'Comuna Ţigănaşi', value: 'Comuna Ţigănaşi' },
  { label: 'Comuna Ţigăneşti', value: 'Comuna Ţigăneşti' },
  { label: 'Comuna Tigveni', value: 'Comuna Tigveni' },
  { label: 'Comuna Tiha Bârgăului', value: 'Comuna Tiha Bârgăului' },
  { label: 'Comuna Tileagd', value: 'Comuna Tileagd' },
  { label: 'Comuna Tilişca', value: 'Comuna Tilişca' },
  { label: 'Comuna Timişeşti', value: 'Comuna Timişeşti' },
  { label: 'Comuna Tinca', value: 'Comuna Tinca' },
  { label: 'Comuna Tinosu', value: 'Comuna Tinosu' },
  { label: 'Comuna Ţinteşti', value: 'Comuna Ţinteşti' },
  { label: 'Comuna Tiream', value: 'Comuna Tiream' },
  { label: 'Comuna Tisãu', value: 'Comuna Tisãu' },
  { label: 'Comuna Titeşti', value: 'Comuna Titeşti' },
  { label: 'Comuna Țițești', value: 'Comuna Țițești' },
  { label: 'Comuna Toboliu', value: 'Comuna Toboliu' },
  { label: 'Comuna Todireni', value: 'Comuna Todireni' },
  { label: 'Comuna Todireşti', value: 'Comuna Todireşti' },
  { label: 'Comuna Todireşti', value: 'Comuna Todireşti' },
  { label: 'Comuna Todireşti', value: 'Comuna Todireşti' },
  { label: 'Comuna Tomeşti', value: 'Comuna Tomeşti' },
  { label: 'Comuna Tomeşti', value: 'Comuna Tomeşti' },
  { label: 'Comuna Tomeşti', value: 'Comuna Tomeşti' },
  { label: 'Comuna Tomeşti', value: 'Comuna Tomeşti' },
  { label: 'Comuna Tomnatic', value: 'Comuna Tomnatic' },
  { label: 'Comuna Tomşani', value: 'Comuna Tomşani' },
  { label: 'Comuna Tomşani', value: 'Comuna Tomşani' },
  { label: 'Comuna Topalu', value: 'Comuna Topalu' },
  { label: 'Comuna Topana', value: 'Comuna Topana' },
  { label: 'Comuna Topleţ', value: 'Comuna Topleţ' },
  { label: 'Comuna Topliceni', value: 'Comuna Topliceni' },
  { label: 'Comuna Topliţa', value: 'Comuna Topliţa' },
  { label: 'Comuna Topolog', value: 'Comuna Topolog' },
  { label: 'Comuna Topolovãţu Mare', value: 'Comuna Topolovãţu Mare' },
  { label: 'Comuna Toporu', value: 'Comuna Toporu' },
  { label: 'Comuna Topraisar', value: 'Comuna Topraisar' },
  { label: 'Comuna Tormac', value: 'Comuna Tormac' },
  { label: 'Comuna Tortoman', value: 'Comuna Tortoman' },
  { label: 'Comuna Toteşti', value: 'Comuna Toteşti' },
  { label: 'Comuna Traian', value: 'Comuna Traian' },
  { label: 'Comuna Traian', value: 'Comuna Traian' },
  { label: 'Comuna Traian', value: 'Comuna Traian' },
  { label: 'Comuna Traian', value: 'Comuna Traian' },
  { label: 'Comuna Traian', value: 'Comuna Traian' },
  { label: 'Comuna Traian Vuia', value: 'Comuna Traian Vuia' },
  { label: 'Comuna Treznea', value: 'Comuna Treznea' },
  { label: 'Comuna Trifeşti', value: 'Comuna Trifeşti' },
  { label: 'Comuna Trifeşti', value: 'Comuna Trifeşti' },
  { label: 'Comuna Tritenii De Jos', value: 'Comuna Tritenii De Jos' },
  { label: 'Comuna Trivalea-Moşteni', value: 'Comuna Trivalea-Moşteni' },
  { label: 'Comuna Troianul', value: 'Comuna Troianul' },
  { label: 'Comuna Truşeşti', value: 'Comuna Truşeşti' },
  { label: 'Comuna Tudor Vladimirescu', value: 'Comuna Tudor Vladimirescu' },
  { label: 'Comuna Tudor Vladimirescu', value: 'Comuna Tudor Vladimirescu' },
  { label: 'Comuna Tudora', value: 'Comuna Tudora' },
  { label: 'Comuna Tufeni', value: 'Comuna Tufeni' },
  { label: 'Comuna Tufeşti', value: 'Comuna Tufeşti' },
  { label: 'Comuna Ţuglui', value: 'Comuna Ţuglui' },
  { label: 'Comuna Tulca', value: 'Comuna Tulca' },
  { label: 'Comuna Tulgheş', value: 'Comuna Tulgheş' },
  { label: 'Comuna Tulnici', value: 'Comuna Tulnici' },
  { label: 'Comuna Tuluceşti', value: 'Comuna Tuluceşti' },
  { label: 'Comuna Tunari', value: 'Comuna Tunari' },
  { label: 'Comuna Tupilaţi', value: 'Comuna Tupilaţi' },
  { label: 'Comuna Turburea', value: 'Comuna Turburea' },
  { label: 'Comuna Turcineşti', value: 'Comuna Turcineşti' },
  { label: 'Comuna Turcoaia', value: 'Comuna Turcoaia' },
  { label: 'Comuna Turdaş', value: 'Comuna Turdaş' },
  { label: 'Comuna Tureni', value: 'Comuna Tureni' },
  { label: 'Comuna Turia', value: 'Comuna Turia' },
  { label: 'Comuna Turnu Roşu', value: 'Comuna Turnu Roşu' },
  { label: 'Comuna Turnu Rueni', value: 'Comuna Turnu Rueni' },
  { label: 'Comuna Turţ', value: 'Comuna Turţ' },
  { label: 'Comuna Turulung', value: 'Comuna Turulung' },
  { label: 'Comuna Tuşnad', value: 'Comuna Tuşnad' },
  { label: 'Comuna Ţuţora', value: 'Comuna Ţuţora' },
  { label: 'Comuna Tutova', value: 'Comuna Tutova' },
  { label: 'Comuna Tuzla', value: 'Comuna Tuzla' },
  { label: 'Comuna Ucea De Jos', value: 'Comuna Ucea De Jos' },
  { label: 'Comuna Uda', value: 'Comuna Uda' },
  { label: 'Comuna Uda-Clocociov', value: 'Comuna Uda-Clocociov' },
  { label: 'Comuna Udeşti', value: 'Comuna Udeşti' },
  { label: 'Comuna Uileacu De Beiuş', value: 'Comuna Uileacu De Beiuş' },
  { label: 'Comuna Uivar', value: 'Comuna Uivar' },
  { label: 'Comuna Ulieş', value: 'Comuna Ulieş' },
  { label: 'Comuna Ulieşti', value: 'Comuna Ulieşti' },
  { label: 'Comuna Ulma', value: 'Comuna Ulma' },
  { label: 'Comuna Ulmeni', value: 'Comuna Ulmeni' },
  { label: 'Comuna Ulmeni', value: 'Comuna Ulmeni' },
  { label: 'Comuna Ulmi', value: 'Comuna Ulmi' },
  { label: 'Comuna Ulmi', value: 'Comuna Ulmi' },
  { label: 'Comuna Ulmu', value: 'Comuna Ulmu' },
  { label: 'Comuna Ulmu', value: 'Comuna Ulmu' },
  { label: 'Comuna Umbrăreşti', value: 'Comuna Umbrăreşti' },
  { label: 'Comuna Ungheni', value: 'Comuna Ungheni' },
  { label: 'Comuna Ungheni', value: 'Comuna Ungheni' },
  { label: 'Comuna Ungra', value: 'Comuna Ungra' },
  { label: 'Comuna Unguraş', value: 'Comuna Unguraş' },
  { label: 'Comuna Ungureni', value: 'Comuna Ungureni' },
  { label: 'Comuna Ungureni', value: 'Comuna Ungureni' },
  { label: 'Comuna Unguriu', value: 'Comuna Unguriu' },
  { label: 'Comuna Unirea', value: 'Comuna Unirea' },
  { label: 'Comuna Unirea', value: 'Comuna Unirea' },
  { label: 'Comuna Unirea', value: 'Comuna Unirea' },
  { label: 'Comuna Unirea', value: 'Comuna Unirea' },
  { label: 'Comuna Unţeni', value: 'Comuna Unţeni' },
  { label: 'Comuna Urdari', value: 'Comuna Urdari' },
  { label: 'Comuna Urecheni', value: 'Comuna Urecheni' },
  { label: 'Comuna Urecheşti', value: 'Comuna Urecheşti' },
  { label: 'Comuna Urecheşti', value: 'Comuna Urecheşti' },
  { label: 'Comuna Uriu', value: 'Comuna Uriu' },
  { label: 'Comuna Urmeniş', value: 'Comuna Urmeniş' },
  { label: 'Comuna Urzica', value: 'Comuna Urzica' },
  { label: 'Comuna Urziceni', value: 'Comuna Urziceni' },
  { label: 'Comuna Urzicuţa', value: 'Comuna Urzicuţa' },
  { label: 'Comuna Ususău', value: 'Comuna Ususău' },
  { label: 'Comuna Văcăreni', value: 'Comuna Văcăreni' },
  { label: 'Comuna Văcăreşti', value: 'Comuna Văcăreşti' },
  { label: 'Comuna Văculeşti', value: 'Comuna Văculeşti' },
  { label: 'Comuna Vad', value: 'Comuna Vad' },
  { label: 'Comuna Vădastra', value: 'Comuna Vădastra' },
  { label: 'Comuna Vădăstriţa', value: 'Comuna Vădăstriţa' },
  { label: 'Comuna Vădeni', value: 'Comuna Vădeni' },
  { label: 'Comuna Vadu Crişului', value: 'Comuna Vadu Crişului' },
  { label: 'Comuna Vadu Izei', value: 'Comuna Vadu Izei' },
  { label: 'Comuna Vadu Moldovei', value: 'Comuna Vadu Moldovei' },
  { label: 'Comuna Vadu Moţilor', value: 'Comuna Vadu Moţilor' },
  { label: 'Comuna Vadu Paşii', value: 'Comuna Vadu Paşii' },
  { label: 'Comuna Vadu Săpat', value: 'Comuna Vadu Săpat' },
  { label: 'Comuna Văgiuleşti', value: 'Comuna Văgiuleşti' },
  { label: 'Comuna Vaideeni', value: 'Comuna Vaideeni' },
  { label: 'Comuna Vâlcăneşti', value: 'Comuna Vâlcăneşti' },
  { label: 'Comuna Vălcani', value: 'Comuna Vălcani' },
  { label: 'Comuna Valcãu De Jos', value: 'Comuna Valcãu De Jos' },
  { label: 'Comuna Vâlcele', value: 'Comuna Vâlcele' },
  { label: 'Comuna Vâlcele', value: 'Comuna Vâlcele' },
  { label: 'Comuna Vâlcelele', value: 'Comuna Vâlcelele' },
  { label: 'Comuna Vâlcelele', value: 'Comuna Vâlcelele' },
  { label: 'Comuna Valea Argovei', value: 'Comuna Valea Argovei' },
  { label: 'Comuna Valea Călugărească', value: 'Comuna Valea Călugărească' },
  { label: 'Comuna Valea Chioarului', value: 'Comuna Valea Chioarului' },
  { label: 'Comuna Valea Ciorii', value: 'Comuna Valea Ciorii' },
  { label: 'Comuna Valea Crişului', value: 'Comuna Valea Crişului' },
  { label: 'Comuna Valea Danului', value: 'Comuna Valea Danului' },
  { label: 'Comuna Valea Doftanei', value: 'Comuna Valea Doftanei' },
  { label: 'Comuna Valea Dragului', value: 'Comuna Valea Dragului' },
  { label: 'Comuna Valea Iaşului', value: 'Comuna Valea Iaşului' },
  { label: 'Comuna Valea Ierii', value: 'Comuna Valea Ierii' },
  { label: 'Comuna Valea Lungă', value: 'Comuna Valea Lungă' },
  { label: 'Comuna Valea Lungă', value: 'Comuna Valea Lungă' },
  { label: 'Comuna Valea Lupului', value: 'Comuna Valea Lupului' },
  { label: 'Comuna Valea Măcrişului', value: 'Comuna Valea Măcrişului' },
  { label: 'Comuna Valea Mare', value: 'Comuna Valea Mare' },
  { label: 'Comuna Valea Mare', value: 'Comuna Valea Mare' },
  { label: 'Comuna Valea Mare', value: 'Comuna Valea Mare' },
  { label: 'Comuna Valea Mare', value: 'Comuna Valea Mare' },
  { label: 'Comuna Valea Mare-Pravăţ', value: 'Comuna Valea Mare-Pravăţ' },
  { label: 'Comuna Valea Mãrului', value: 'Comuna Valea Mãrului' },
  { label: 'Comuna Valea Moldovei', value: 'Comuna Valea Moldovei' },
  { label: 'Comuna Valea Nucarilor', value: 'Comuna Valea Nucarilor' },
  { label: 'Comuna Valea Râmnicului', value: 'Comuna Valea Râmnicului' },
  { label: 'Comuna Valea Sălciei', value: 'Comuna Valea Sălciei' },
  { label: 'Comuna Valea Sării', value: 'Comuna Valea Sării' },
  { label: 'Comuna Valea Seacă', value: 'Comuna Valea Seacă' },
  { label: 'Comuna Valea Seacă', value: 'Comuna Valea Seacă' },
  { label: 'Comuna Valea Stanciului', value: 'Comuna Valea Stanciului' },
  { label: 'Comuna Valea Teilor', value: 'Comuna Valea Teilor' },
  { label: 'Comuna Valea Ursului', value: 'Comuna Valea Ursului' },
  { label: 'Comuna Valea Viilor', value: 'Comuna Valea Viilor' },
  { label: 'Comuna Valea Vinului', value: 'Comuna Valea Vinului' },
  { label: 'Comuna Văleni', value: 'Comuna Văleni' },
  { label: 'Comuna Văleni', value: 'Comuna Văleni' },
  { label: 'Comuna Vãleni', value: 'Comuna Vãleni' },
  { label: 'Comuna Văleni-Dâmboviţa', value: 'Comuna Văleni-Dâmboviţa' },
  { label: 'Comuna Vălişoara', value: 'Comuna Vălişoara' },
  { label: 'Comuna Văliug', value: 'Comuna Văliug' },
  { label: 'Comuna Valu Lui Traian', value: 'Comuna Valu Lui Traian' },
  { label: 'Comuna Vama', value: 'Comuna Vama' },
  { label: 'Comuna Vama', value: 'Comuna Vama' },
  { label: 'Comuna Vama Buzăului', value: 'Comuna Vama Buzăului' },
  { label: 'Comuna Vânători', value: 'Comuna Vânători' },
  { label: 'Comuna Vânători', value: 'Comuna Vânători' },
  { label: 'Comuna Vânători', value: 'Comuna Vânători' },
  { label: 'Comuna Vânãtori', value: 'Comuna Vânãtori' },
  { label: 'Comuna Vânători Neamţ', value: 'Comuna Vânători Neamţ' },
  { label: 'Comuna Vânătorii Mici', value: 'Comuna Vânătorii Mici' },
  { label: 'Comuna Vânjuleţ', value: 'Comuna Vânjuleţ' },
  { label: 'Comuna Vărădia', value: 'Comuna Vărădia' },
  { label: 'Comuna Vărădia De Mureş', value: 'Comuna Vărădia De Mureş' },
  { label: 'Comuna Vărăşti', value: 'Comuna Vărăşti' },
  { label: 'Comuna Vărbilău', value: 'Comuna Vărbilău' },
  { label: 'Comuna Vârciorog', value: 'Comuna Vârciorog' },
  { label: 'Comuna Vârfu Câmpului', value: 'Comuna Vârfu Câmpului' },
  { label: 'Comuna Vârfuri', value: 'Comuna Vârfuri' },
  { label: 'Comuna Vârfurile', value: 'Comuna Vârfurile' },
  { label: 'Comuna Vârghiş', value: 'Comuna Vârghiş' },
  { label: 'Comuna Variaş', value: 'Comuna Variaş' },
  { label: 'Comuna Vârlezi', value: 'Comuna Vârlezi' },
  { label: 'Comuna Vărşag', value: 'Comuna Vărşag' },
  { label: 'Comuna Vârşolţ', value: 'Comuna Vârşolţ' },
  { label: 'Comuna Vârteşcoiu', value: 'Comuna Vârteşcoiu' },
  { label: 'Comuna Vârtoape', value: 'Comuna Vârtoape' },
  { label: 'Comuna Vârtop', value: 'Comuna Vârtop' },
  { label: 'Comuna Vârvoru De Jos', value: 'Comuna Vârvoru De Jos' },
  { label: 'Comuna Vasilaţi', value: 'Comuna Vasilaţi' },
  { label: 'Comuna Vaţa De Jos', value: 'Comuna Vaţa De Jos' },
  { label: 'Comuna Vatra Moldoviţei', value: 'Comuna Vatra Moldoviţei' },
  { label: 'Comuna Vedea', value: 'Comuna Vedea' },
  { label: 'Comuna Vedea', value: 'Comuna Vedea' },
  { label: 'Comuna Vedea', value: 'Comuna Vedea' },
  { label: 'Comuna Vela', value: 'Comuna Vela' },
  { label: 'Comuna Verbiţa', value: 'Comuna Verbiţa' },
  { label: 'Comuna Vereşti', value: 'Comuna Vereşti' },
  { label: 'Comuna Verguleasa', value: 'Comuna Verguleasa' },
  { label: 'Comuna Vermeş', value: 'Comuna Vermeş' },
  { label: 'Comuna Verneşti', value: 'Comuna Verneşti' },
  { label: 'Comuna Veţel', value: 'Comuna Veţel' },
  { label: 'Comuna Vetiş', value: 'Comuna Vetiş' },
  { label: 'Comuna Vetrişoaia', value: 'Comuna Vetrişoaia' },
  { label: 'Comuna Vicovu De Jos', value: 'Comuna Vicovu De Jos' },
  { label: 'Comuna Victor Vlad Delamarina', value: 'Comuna Victor Vlad Delamarina' },
  { label: 'Comuna Victoria', value: 'Comuna Victoria' },
  { label: 'Comuna Victoria', value: 'Comuna Victoria' },
  { label: 'Comuna Vidra', value: 'Comuna Vidra' },
  { label: 'Comuna Vidra', value: 'Comuna Vidra' },
  { label: 'Comuna Vidra', value: 'Comuna Vidra' },
  { label: 'Comuna Viile Satu Mare', value: 'Comuna Viile Satu Mare' },
  { label: 'Comuna Viişoara', value: 'Comuna Viişoara' },
  { label: 'Comuna Viişoara', value: 'Comuna Viişoara' },
  { label: 'Comuna Viişoara', value: 'Comuna Viişoara' },
  { label: 'Comuna Viişoara', value: 'Comuna Viişoara' },
  { label: 'Comuna Viişoara', value: 'Comuna Viişoara' },
  { label: 'Comuna Vima Micã', value: 'Comuna Vima Micã' },
  { label: 'Comuna Vinderei', value: 'Comuna Vinderei' },
  { label: 'Comuna Vinga', value: 'Comuna Vinga' },
  { label: 'Comuna Vintilă Vodă', value: 'Comuna Vintilă Vodă' },
  { label: 'Comuna Vintileasca', value: 'Comuna Vintileasca' },
  { label: 'Comuna Vinţu De Jos', value: 'Comuna Vinţu De Jos' },
  { label: 'Comuna Vipereşti', value: 'Comuna Vipereşti' },
  { label: 'Comuna Vişani', value: 'Comuna Vişani' },
  { label: 'Comuna Vişeu De Jos', value: 'Comuna Vişeu De Jos' },
  { label: 'Comuna Vişina', value: 'Comuna Vişina' },
  { label: 'Comuna Vişina', value: 'Comuna Vişina' },
  { label: 'Comuna Vişina Nouă', value: 'Comuna Vişina Nouă' },
  { label: 'Comuna Vişineşti', value: 'Comuna Vişineşti' },
  { label: 'Comuna Viştea De Jos', value: 'Comuna Viştea De Jos' },
  { label: 'Comuna Vităneşti', value: 'Comuna Vităneşti' },
  { label: 'Comuna Vitomireşti', value: 'Comuna Vitomireşti' },
  { label: 'Comuna Vizantea-Livezi', value: 'Comuna Vizantea-Livezi' },
  { label: 'Comuna Viziru', value: 'Comuna Viziru' },
  { label: 'Comuna Vlad Ţepeş', value: 'Comuna Vlad Ţepeş' },
  { label: 'Comuna Vlădaia', value: 'Comuna Vlădaia' },
  { label: 'Comuna Vlădeni', value: 'Comuna Vlădeni' },
  { label: 'Comuna Vlădeni', value: 'Comuna Vlădeni' },
  { label: 'Comuna Vlădeni', value: 'Comuna Vlădeni' },
  { label: 'Comuna Vlădeni', value: 'Comuna Vlădeni' },
  { label: 'Comuna Vlădeşti', value: 'Comuna Vlădeşti' },
  { label: 'Comuna Vlădeşti', value: 'Comuna Vlădeşti' },
  { label: 'Comuna Vlădeşti', value: 'Comuna Vlădeşti' },
  { label: 'Comuna Vlădila', value: 'Comuna Vlădila' },
  { label: 'Comuna Vladimir', value: 'Comuna Vladimir' },
  { label: 'Comuna Vladimirescu', value: 'Comuna Vladimirescu' },
  { label: 'Comuna Vlăsineşti', value: 'Comuna Vlăsineşti' },
  { label: 'Comuna Voiceşti', value: 'Comuna Voiceşti' },
  { label: 'Comuna Voila', value: 'Comuna Voila' },
  { label: 'Comuna Voineasa', value: 'Comuna Voineasa' },
  { label: 'Comuna Voineasa', value: 'Comuna Voineasa' },
  { label: 'Comuna Voineşti', value: 'Comuna Voineşti' },
  { label: 'Comuna Voineşti', value: 'Comuna Voineşti' },
  { label: 'Comuna Voineşti', value: 'Comuna Voineşti' },
  { label: 'Comuna Voiteg', value: 'Comuna Voiteg' },
  { label: 'Comuna Voitinel', value: 'Comuna Voitinel' },
  { label: 'Comuna Voloiac', value: 'Comuna Voloiac' },
  { label: 'Comuna Volovăţ', value: 'Comuna Volovăţ' },
  { label: 'Comuna Vorniceni', value: 'Comuna Vorniceni' },
  { label: 'Comuna Vorona', value: 'Comuna Vorona' },
  { label: 'Comuna Vorţa', value: 'Comuna Vorţa' },
  { label: 'Comuna Voşlãbeni', value: 'Comuna Voşlãbeni' },
  { label: 'Comuna Vrâncioaia', value: 'Comuna Vrâncioaia' },
  { label: 'Comuna Vrani', value: 'Comuna Vrani' },
  { label: 'Comuna Vrata', value: 'Comuna Vrata' },
  { label: 'Comuna Vulcan', value: 'Comuna Vulcan' },
  { label: 'Comuna Vulcana Băi', value: 'Comuna Vulcana Băi' },
  { label: 'Comuna Vulcana-Pandele', value: 'Comuna Vulcana-Pandele' },
  { label: 'Comuna Vulpeni', value: 'Comuna Vulpeni' },
  { label: 'Comuna Vultureni', value: 'Comuna Vultureni' },
  { label: 'Comuna Vultureni', value: 'Comuna Vultureni' },
  { label: 'Comuna Vultureşti', value: 'Comuna Vultureşti' },
  { label: 'Comuna Vultureşti', value: 'Comuna Vultureşti' },
  { label: 'Comuna Vultureşti', value: 'Comuna Vultureşti' },
  { label: 'Comuna Vultureşti', value: 'Comuna Vultureşti' },
  { label: 'Comuna Vulturu', value: 'Comuna Vulturu' },
  { label: 'Comuna Vulturu', value: 'Comuna Vulturu' },
  { label: 'Comuna Vurpăr', value: 'Comuna Vurpăr' },
  { label: 'Comuna Vutcani', value: 'Comuna Vutcani' },
  { label: 'Comuna Zăbala', value: 'Comuna Zăbala' },
  { label: 'Comuna Zăbrani', value: 'Comuna Zăbrani' },
  { label: 'Comuna Zădăreni', value: 'Comuna Zădăreni' },
  { label: 'Comuna Zagon', value: 'Comuna Zagon' },
  { label: 'Comuna Zagra', value: 'Comuna Zagra' },
  { label: 'Comuna Zalha', value: 'Comuna Zalha' },
  { label: 'Comuna Zam', value: 'Comuna Zam' },
  { label: 'Comuna Zâmbreasca', value: 'Comuna Zâmbreasca' },
  { label: 'Comuna Zamostea', value: 'Comuna Zamostea' },
  { label: 'Comuna Zăneşti', value: 'Comuna Zăneşti' },
  { label: 'Comuna Zăpodeni', value: 'Comuna Zăpodeni' },
  { label: 'Comuna Zărand', value: 'Comuna Zărand' },
  { label: 'Comuna Zărneşti', value: 'Comuna Zărneşti' },
  { label: 'Comuna Zătreni', value: 'Comuna Zătreni' },
  { label: 'Comuna Zăvoaia', value: 'Comuna Zăvoaia' },
  { label: 'Comuna Zăvoi', value: 'Comuna Zăvoi' },
  { label: 'Comuna Zemeş', value: 'Comuna Zemeş' },
  { label: 'Comuna Zerind', value: 'Comuna Zerind' },
  { label: 'Comuna Zetea', value: 'Comuna Zetea' },
  { label: 'Comuna Ziduri', value: 'Comuna Ziduri' },
  { label: 'Comuna Zimandu Nou', value: 'Comuna Zimandu Nou' },
  { label: 'Comuna Zimbor', value: 'Comuna Zimbor' },
  { label: 'Comuna Zorleni', value: 'Comuna Zorleni' },
  { label: 'Comuna Zorlenţu Mare', value: 'Comuna Zorlenţu Mare' },
  { label: 'Comuna Zvoriştea', value: 'Comuna Zvoriştea' },
  { label: 'Comunanza', value: 'Comunanza' },
  { label: 'Comunas', value: 'Comunas' },
  { label: 'Côn Sơn', value: 'Côn Sơn' },
  { label: 'Cona', value: 'Cona' },
  { label: 'Conakry', value: 'Conakry' },
  { label: 'Conashaugh Lakes', value: 'Conashaugh Lakes' },
  { label: 'Concá', value: 'Concá' },
  { label: 'Conca Casale', value: 'Conca Casale' },
  { label: 'Conca De Dalt', value: 'Conca De Dalt' },
  { label: 'Conca Dei Marini', value: 'Conca Dei Marini' },
  { label: 'Conca Della Campania', value: 'Conca Della Campania' },
  { label: 'Concarán', value: 'Concarán' },
  { label: 'Concarneau', value: 'Concarneau' },
  { label: 'Conceição', value: 'Conceição' },
  { label: 'Conceição', value: 'Conceição' },
  { label: 'Conceição Da Aparecida', value: 'Conceição Da Aparecida' },
  { label: 'Conceição Da Barra', value: 'Conceição Da Barra' },
  { label: 'Conceição Da Barra De Minas', value: 'Conceição Da Barra De Minas' },
  { label: 'Conceição Da Feira', value: 'Conceição Da Feira' },
  { label: 'Conceição Das Alagoas', value: 'Conceição Das Alagoas' },
  { label: 'Conceição Das Pedras', value: 'Conceição Das Pedras' },
  { label: 'Conceição De Ipanema', value: 'Conceição De Ipanema' },
  { label: 'Conceição De Macabu', value: 'Conceição De Macabu' },
  { label: 'Conceição Do Almeida', value: 'Conceição Do Almeida' },
  { label: 'Conceição Do Araguaia', value: 'Conceição Do Araguaia' },
  { label: 'Conceição Do Canindé', value: 'Conceição Do Canindé' },
  { label: 'Conceição Do Castelo', value: 'Conceição Do Castelo' },
  { label: 'Conceição Do Coité', value: 'Conceição Do Coité' },
  { label: 'Conceição Do Jacuípe', value: 'Conceição Do Jacuípe' },
  { label: 'Conceição Do Lago-Açu', value: 'Conceição Do Lago-Açu' },
  { label: 'Conceição Do Mato Dentro', value: 'Conceição Do Mato Dentro' },
  { label: 'Conceição Do Pará', value: 'Conceição Do Pará' },
  { label: 'Conceição Do Rio Verde', value: 'Conceição Do Rio Verde' },
  { label: 'Conceição Do Tocantins', value: 'Conceição Do Tocantins' },
  { label: 'Conceição Dos Ouros', value: 'Conceição Dos Ouros' },
  { label: 'Concelho De Matola', value: 'Concelho De Matola' },
  { label: 'Concepcion', value: 'Concepcion' },
  { label: 'Concepcion', value: 'Concepcion' },
  { label: 'Concepcion', value: 'Concepcion' },
  { label: 'Concepcion', value: 'Concepcion' },
  { label: 'Concepcion', value: 'Concepcion' },
  { label: 'Concepcion', value: 'Concepcion' },
  { label: 'Concepcion', value: 'Concepcion' },
  { label: 'Concepcion', value: 'Concepcion' },
  { label: 'Concepcion', value: 'Concepcion' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción', value: 'Concepción' },
  { label: 'Concepción Batres', value: 'Concepción Batres' },
  { label: 'Concepción Capulac (La Ex-Hacienda)', value: 'Concepción Capulac (La Ex-Hacienda)' },
  { label: 'Concepción Caro', value: 'Concepción Caro' },
  { label: 'Concepción Chimalpa', value: 'Concepción Chimalpa' },
  { label: 'Concepción Chiquirichapa', value: 'Concepción Chiquirichapa' },
  { label: 'Concepción Cuautla', value: 'Concepción Cuautla' },
  { label: 'Concepción De Ataco', value: 'Concepción De Ataco' },
  { label: 'Concepción De Buenos Aires', value: 'Concepción De Buenos Aires' },
  { label: 'Concepción De Guasistagua', value: 'Concepción De Guasistagua' },
  { label: 'Concepción De La Barranca', value: 'Concepción De La Barranca' },
  { label: 'Concepción De La Sierra', value: 'Concepción De La Sierra' },
  { label: 'Concepción De La Vega', value: 'Concepción De La Vega' },
  { label: 'Concepción De María', value: 'Concepción De María' },
  { label: 'Concepción Del Bermejo', value: 'Concepción Del Bermejo' },
  { label: 'Concepción Del Bramador', value: 'Concepción Del Bramador' },
  { label: 'Concepción Del Monte', value: 'Concepción Del Monte' },
  { label: 'Concepción Del Norte', value: 'Concepción Del Norte' },
  { label: 'Concepción Del Oro', value: 'Concepción Del Oro' },
  { label: 'Concepción Del Sur', value: 'Concepción Del Sur' },
  { label: 'Concepción Del Uruguay', value: 'Concepción Del Uruguay' },
  { label: 'Concepción Hidalgo', value: 'Concepción Hidalgo' },
  { label: 'Concepción Huista', value: 'Concepción Huista' },
  { label: 'Concepcion Ibaba', value: 'Concepcion Ibaba' },
  { label: 'Concepción Jolalpan', value: 'Concepción Jolalpan' },
  { label: 'Concepción La Venta', value: 'Concepción La Venta' },
  { label: 'Concepción Las Minas', value: 'Concepción Las Minas' },
  { label: 'Concepción Pápalo', value: 'Concepción Pápalo' },
  { label: 'Concepción Tutuapa', value: 'Concepción Tutuapa' },
  { label: 'Conception Bay South', value: 'Conception Bay South' },
  { label: 'Concerviano', value: 'Concerviano' },
  { label: 'Concesio', value: 'Concesio' },
  { label: 'Concession', value: 'Concession' },
  { label: 'Conceşti', value: 'Conceşti' },
  { label: 'Conchagua', value: 'Conchagua' },
  { label: 'Conchal', value: 'Conchal' },
  { label: 'Conchalí', value: 'Conchalí' },
  { label: 'Conchas', value: 'Conchas' },
  { label: 'Conches-En-Ouche', value: 'Conches-En-Ouche' },
  { label: 'Conches-Sur-Gondoire', value: 'Conches-Sur-Gondoire' },
  { label: 'Concho County', value: 'Concho County' },
  { label: 'Conchopata', value: 'Conchopata' },
  { label: 'Conchos', value: 'Conchos' },
  { label: 'Conciliación', value: 'Conciliación' },
  { label: 'Conco', value: 'Conco' },
  { label: 'Concón', value: 'Concón' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord', value: 'Concord' },
  { label: 'Concord West', value: 'Concord West' },
  { label: 'Concordia', value: 'Concordia' },
  { label: 'Concordia', value: 'Concordia' },
  { label: 'Concordia', value: 'Concordia' },
  { label: 'Concordia', value: 'Concordia' },
  { label: 'Concordia', value: 'Concordia' },
  { label: 'Concordia', value: 'Concordia' },
  { label: 'Concordia', value: 'Concordia' },
  { label: 'Concordia', value: 'Concordia' },
  { label: 'Concordia', value: 'Concordia' },
  { label: 'Concordia', value: 'Concordia' },
  { label: 'Concórdia', value: 'Concórdia' },
  { label: 'Concórdia Do Pará', value: 'Concórdia Do Pará' },
  { label: 'Concordia Parish', value: 'Concordia Parish' },
  { label: 'Concordia Sagittaria', value: 'Concordia Sagittaria' },
  { label: 'Concordia Sulla Secchia', value: 'Concordia Sulla Secchia' },
  { label: 'Concorezzo', value: 'Concorezzo' },
  { label: 'Condado', value: 'Condado' },
  { label: 'Condado', value: 'Condado' },
  { label: 'Condado', value: 'Condado' },
  { label: 'Condado De Castilnovo', value: 'Condado De Castilnovo' },
  { label: 'Condado De Treviño', value: 'Condado De Treviño' },
  { label: 'Condat', value: 'Condat' },
  { label: 'Condat-Sur-Vienne', value: 'Condat-Sur-Vienne' },
  { label: 'Conde', value: 'Conde' },
  { label: 'Conde', value: 'Conde' },
  { label: 'Condeești', value: 'Condeești' },
  { label: 'Condega', value: 'Condega' },
  { label: 'Condeixa-A-Nova', value: 'Condeixa-A-Nova' },
  { label: 'Condell Park', value: 'Condell Park' },
  { label: 'Condémbaro', value: 'Condémbaro' },
  { label: 'Condemios De Abajo', value: 'Condemios De Abajo' },
  { label: 'Condemios De Arriba', value: 'Condemios De Arriba' },
  { label: 'Conder', value: 'Conder' },
  { label: 'Condé-Sainte-Libiaire', value: 'Condé-Sainte-Libiaire' },
  { label: 'Condé-Sur-Huisne', value: 'Condé-Sur-Huisne' },
  { label: 'Condé-Sur-L’Escaut', value: 'Condé-Sur-L’Escaut' },
  { label: 'Condé-Sur-Noireau', value: 'Condé-Sur-Noireau' },
  { label: 'Condé-Sur-Sarthe', value: 'Condé-Sur-Sarthe' },
  { label: 'Condé-Sur-Vesgre', value: 'Condé-Sur-Vesgre' },
  { label: 'Condé-Sur-Vire', value: 'Condé-Sur-Vire' },
  { label: 'Condette', value: 'Condette' },
  { label: 'Condeúba', value: 'Condeúba' },
  { label: 'Condino', value: 'Condino' },
  { label: 'Condobolin', value: 'Condobolin' },
  { label: 'Condofuri', value: 'Condofuri' },
  { label: 'Condom', value: 'Condom' },
  { label: 'Condon', value: 'Condon' },
  { label: 'Condon', value: 'Condon' },
  { label: 'Condon', value: 'Condon' },
  { label: 'Condor', value: 'Condor' },
  { label: 'Condorcanqui', value: 'Condorcanqui' },
  { label: 'Condoto', value: 'Condoto' },
  { label: 'Condove', value: 'Condove' },
  { label: 'Condrieu', value: 'Condrieu' },
  { label: 'Condrò', value: 'Condrò' },
  { label: 'Conduaga', value: 'Conduaga' },
  { label: 'Conduratu', value: 'Conduratu' },
  { label: 'Conecuh County', value: 'Conecuh County' },
  { label: 'Conegliano', value: 'Conegliano' },
  { label: 'Cônego Marinho', value: 'Cônego Marinho' },
  { label: 'Conehatta', value: 'Conehatta' },
  { label: 'Conejeras', value: 'Conejeras' },
  { label: 'Conejos', value: 'Conejos' },
  { label: 'Conejos', value: 'Conejos' },
  { label: 'Conejos County', value: 'Conejos County' },
  { label: 'Conel', value: 'Conel' },
  { label: 'Conemaugh', value: 'Conemaugh' },
  { label: 'Conesa', value: 'Conesa' },
  { label: 'Conestoga', value: 'Conestoga' },
  { label: 'Coney Island', value: 'Coney Island' },
  { label: 'Conferín Arizpe', value: 'Conferín Arizpe' },
  { label: 'Confey', value: 'Confey' },
  { label: 'Confienza', value: 'Confienza' },
  { label: 'Configni', value: 'Configni' },
  { label: 'Confignon', value: 'Confignon' },
  { label: 'Confines', value: 'Confines' },
  { label: 'Confins', value: 'Confins' },
  { label: 'Conflans-En-Jarnisy', value: 'Conflans-En-Jarnisy' },
  { label: 'Conflans-Sainte-Honorine', value: 'Conflans-Sainte-Honorine' },
  { label: 'Conflenti', value: 'Conflenti' },
  { label: 'Confolens', value: 'Confolens' },
  { label: 'Confresa', value: 'Confresa' },
  { label: 'Confrides', value: 'Confrides' },
  { label: 'Congénies', value: 'Congénies' },
  { label: 'Congers', value: 'Congers' },
  { label: 'Congis-Sur-Thérouanne', value: 'Congis-Sur-Thérouanne' },
  { label: 'Congleton', value: 'Congleton' },
  { label: 'Congo', value: 'Congo' },
  { label: 'Congomah', value: 'Congomah' },
  { label: 'Congonhal', value: 'Congonhal' },
  { label: 'Congonhas', value: 'Congonhas' },
  { label: 'Congonhas Do Norte', value: 'Congonhas Do Norte' },
  { label: 'Congonhinhas', value: 'Congonhinhas' },
  { label: 'Congosto', value: 'Congosto' },
  { label: 'Congosto De Valdavia', value: 'Congosto De Valdavia' },
  { label: 'Congostrina', value: 'Congostrina' },
  { label: 'Congregación Calles', value: 'Congregación Calles' },
  { label: 'Congregación El Tajín', value: 'Congregación El Tajín' },
  { label: 'Congregación Hidalgo', value: 'Congregación Hidalgo' },
  { label: 'Congregación Ortíz', value: 'Congregación Ortíz' },
  { label: 'Congregación Reforma', value: 'Congregación Reforma' },
  { label: 'Congresbury', value: 'Congresbury' },
  { label: 'Congress', value: 'Congress' },
  { label: 'Congrier', value: 'Congrier' },
  { label: 'Conguaco', value: 'Conguaco' },
  { label: 'Conil De La Frontera', value: 'Conil De La Frontera' },
  { label: 'Coningsby', value: 'Coningsby' },
  { label: 'Coniolo Bricco', value: 'Coniolo Bricco' },
  { label: 'Conisbrough', value: 'Conisbrough' },
  { label: 'Coniston', value: 'Coniston' },
  { label: 'Conjunto Habitacional Ecológico Suteym', value: 'Conjunto Habitacional Ecológico Suteym' },
  { label: 'Conjunto Habitacional El Trébol', value: 'Conjunto Habitacional El Trébol' },
  { label: 'Conjunto Habitacional Villas Del Pedregal', value: 'Conjunto Habitacional Villas Del Pedregal' },
  { label: 'Conjunto Urbano La Loma I', value: 'Conjunto Urbano La Loma I' },
  { label: 'Conkal', value: 'Conkal' },
  { label: 'Conley', value: 'Conley' },
  { label: 'Conlie', value: 'Conlie' },
  { label: 'Connantre', value: 'Connantre' },
  { label: 'Connaux', value: 'Connaux' },
  { label: 'Conneaut', value: 'Conneaut' },
  { label: 'Conneaut Lakeshore', value: 'Conneaut Lakeshore' },
  { label: 'Connell', value: 'Connell' },
  { label: 'Connells Point', value: 'Connells Point' },
  { label: 'Connellsville', value: 'Connellsville' },
  { label: 'Connelly Springs', value: 'Connelly Springs' },
  { label: 'Conner', value: 'Conner' },
  { label: 'Conner', value: 'Conner' },
  { label: 'Connerré', value: 'Connerré' },
  { label: 'Connersville', value: 'Connersville' },
  { label: 'Connerton', value: 'Connerton' },
  { label: 'Conning Towers-Nautilus Park', value: 'Conning Towers-Nautilus Park' },
  { label: 'Connolly', value: 'Connolly' },
  { label: 'Connor', value: 'Connor' },
  { label: 'Conon Bridge', value: 'Conon Bridge' },
  { label: 'Conop', value: 'Conop' },
  { label: 'Conover', value: 'Conover' },
  { label: 'Conquereuil', value: 'Conquereuil' },
  { label: 'Conques-Sur-Orbiel', value: 'Conques-Sur-Orbiel' },
  { label: 'Conquista', value: 'Conquista' },
  { label: 'Conquista', value: 'Conquista' },
  { label: 'Conquista De La Sierra', value: 'Conquista De La Sierra' },
  { label: 'Conquista Doeste', value: 'Conquista Doeste' },
  { label: 'Conrad', value: 'Conrad' },
  { label: 'Conrad', value: 'Conrad' },
  { label: 'Conroe', value: 'Conroe' },
  { label: 'Consaca', value: 'Consaca' },
  { label: 'Consandolo', value: 'Consandolo' },
  { label: 'Conscenti', value: 'Conscenti' },
  { label: 'Conscio', value: 'Conscio' },
  { label: 'Conscripto Bernardi', value: 'Conscripto Bernardi' },
  { label: 'Consdorf', value: 'Consdorf' },
  { label: 'Conselheiro Lafaiete', value: 'Conselheiro Lafaiete' },
  { label: 'Conselheiro Mairinck', value: 'Conselheiro Mairinck' },
  { label: 'Conselheiro Pena', value: 'Conselheiro Pena' },
  { label: 'Conselice', value: 'Conselice' },
  { label: 'Consell', value: 'Consell' },
  { label: 'Conselve', value: 'Conselve' },
  { label: 'Consett', value: 'Consett' },
  { label: 'Conshohocken', value: 'Conshohocken' },
  { label: 'Consiglio Di Rumo', value: 'Consiglio Di Rumo' },
  { label: 'Consolação', value: 'Consolação' },
  { label: 'Consolacion', value: 'Consolacion' },
  { label: 'Consolacion', value: 'Consolacion' },
  { label: 'Consolación Del Sur', value: 'Consolación Del Sur' },
  { label: 'Constance Bay', value: 'Constance Bay' },
  { label: 'Constancia', value: 'Constancia' },
  { label: 'Constancia', value: 'Constancia' },
  { label: 'Constância', value: 'Constância' },
  { label: 'Constancia Del Rosario', value: 'Constancia Del Rosario' },
  { label: 'Constancio Farfán (La Pascuala)', value: 'Constancio Farfán (La Pascuala)' },
  { label: 'Constant Spring', value: 'Constant Spring' },
  { label: 'Constant Spring Gardens', value: 'Constant Spring Gardens' },
  { label: 'Constanţa', value: 'Constanţa' },
  { label: 'Constantí', value: 'Constantí' },
  { label: 'Constantia', value: 'Constantia' },
  { label: 'Constantia', value: 'Constantia' },
  { label: 'Constantin Brâncoveanu', value: 'Constantin Brâncoveanu' },
  { label: 'Constantin Daicoviciu', value: 'Constantin Daicoviciu' },
  { label: 'Constantina', value: 'Constantina' },
  { label: 'Constantina', value: 'Constantina' },
  { label: 'Constantine', value: 'Constantine' },
  { label: 'Constantine', value: 'Constantine' },
  { label: 'Constanza', value: 'Constanza' },
  { label: 'Constanzana', value: 'Constanzana' },
  { label: 'Constitución', value: 'Constitución' },
  { label: 'Constitución', value: 'Constitución' },
  { label: 'Constitución', value: 'Constitución' },
  { label: 'Constitución', value: 'Constitución' },
  { label: 'Constitución', value: 'Constitución' },
  { label: 'Constitución Mexicana', value: 'Constitución Mexicana' },
  { label: 'Constitution Hill', value: 'Constitution Hill' },
  { label: 'Constitution Hill', value: 'Constitution Hill' },
  { label: 'Consuegra', value: 'Consuegra' },
  { label: 'Consuegra', value: 'Consuegra' },
  { label: 'Consuegra', value: 'Consuegra' },
  { label: 'Consuelo', value: 'Consuelo' },
  { label: 'Consuelo', value: 'Consuelo' },
  { label: 'Consuelo', value: 'Consuelo' },
  { label: 'Contadero', value: 'Contadero' },
  { label: 'Contadora', value: 'Contadora' },
  { label: 'Contagem', value: 'Contagem' },
  { label: 'Contai', value: 'Contai' },
  { label: 'Contamina', value: 'Contamina' },
  { label: 'Contamine-Sur-Arve', value: 'Contamine-Sur-Arve' },
  { label: 'Contenda', value: 'Contenda' },
  { label: 'Contendas Do Sincorá', value: 'Contendas Do Sincorá' },
  { label: 'Content', value: 'Content' },
  { label: 'Content', value: 'Content' },
  { label: 'Content Gap', value: 'Content Gap' },
  { label: 'Content Garden', value: 'Content Garden' },
  { label: 'Contepec', value: 'Contepec' },
  { label: 'Contern', value: 'Contern' },
  { label: 'Contes', value: 'Contes' },
  { label: 'Contessa Entellina', value: 'Contessa Entellina' },
  { label: 'Conţeşti', value: 'Conţeşti' },
  { label: 'Conțești', value: 'Conțești' },
  { label: 'Conțești', value: 'Conțești' },
  { label: 'Conțești', value: 'Conțești' },
  { label: 'Conthey', value: 'Conthey' },
  { label: 'Conthey District', value: 'Conthey District' },
  { label: 'Contigliano', value: 'Contigliano' },
  { label: 'Continental', value: 'Continental' },
  { label: 'Contla', value: 'Contla' },
  { label: 'Contoocook', value: 'Contoocook' },
  { label: 'Contra Costa Centre', value: 'Contra Costa Centre' },
  { label: 'Contra Costa County', value: 'Contra Costa County' },
  { label: 'Contraalmirante Cordero', value: 'Contraalmirante Cordero' },
  { label: 'Contrada', value: 'Contrada' },
  { label: 'Contramaestre', value: 'Contramaestre' },
  { label: 'Contratación', value: 'Contratación' },
  { label: 'Contrecoeur', value: 'Contrecoeur' },
  { label: 'Contreras', value: 'Contreras' },
  { label: 'Contres', value: 'Contres' },
  { label: 'Contrexéville', value: 'Contrexéville' },
  { label: 'Controguerra', value: 'Controguerra' },
  { label: 'Controne', value: 'Controne' },
  { label: 'Contuboel Sector', value: 'Contuboel Sector' },
  { label: 'Contulmo', value: 'Contulmo' },
  { label: 'Contursi Terme', value: 'Contursi Terme' },
  { label: 'Contwig', value: 'Contwig' },
  { label: 'Conty', value: 'Conty' },
  { label: 'Convención', value: 'Convención' },
  { label: 'Convent', value: 'Convent' },
  { label: 'Conversano', value: 'Conversano' },
  { label: 'Converse', value: 'Converse' },
  { label: 'Converse', value: 'Converse' },
  { label: 'Converse County', value: 'Converse County' },
  { label: 'Conversion', value: 'Conversion' },
  { label: 'Conversion', value: 'Conversion' },
  { label: 'Convoy', value: 'Convoy' },
  { label: 'Convoy', value: 'Convoy' },
  { label: 'Conway', value: 'Conway' },
  { label: 'Conway', value: 'Conway' },
  { label: 'Conway', value: 'Conway' },
  { label: 'Conway', value: 'Conway' },
  { label: 'Conway', value: 'Conway' },
  { label: 'Conway', value: 'Conway' },
  { label: 'Conway County', value: 'Conway County' },
  { label: 'Conway Springs', value: 'Conway Springs' },
  { label: 'Conway/Waterfront', value: 'Conway/Waterfront' },
  { label: 'Conwy', value: 'Conwy' },
  { label: 'Conyers', value: 'Conyers' },
  { label: 'Conyngham', value: 'Conyngham' },
  { label: 'Conzano', value: 'Conzano' },
  { label: 'Coober Pedy', value: 'Coober Pedy' },
  { label: 'Cooch Behar', value: 'Cooch Behar' },
  { label: 'Coodanup', value: 'Coodanup' },
  { label: 'Cooee Bay', value: 'Cooee Bay' },
  { label: 'Coogee', value: 'Coogee' },
  { label: 'Coogee', value: 'Coogee' },
  { label: 'Cook', value: 'Cook' },
  { label: 'Cook County', value: 'Cook County' },
  { label: 'Cook County', value: 'Cook County' },
  { label: 'Cook County', value: 'Cook County' },
  { label: 'Cook Shire', value: 'Cook Shire' },
  { label: 'Cooke County', value: 'Cooke County' },
  { label: 'Cookeville', value: 'Cookeville' },
  { label: 'Cookham', value: 'Cookham' },
  { label: 'Cookley', value: 'Cookley' },
  { label: 'Cooks Hill', value: 'Cooks Hill' },
  { label: 'Cookshire', value: 'Cookshire' },
  { label: 'Cookshire-Eaton', value: 'Cookshire-Eaton' },
  { label: 'Cookstown', value: 'Cookstown' },
  { label: 'Cookstown', value: 'Cookstown' },
  { label: 'Cooktown', value: 'Cooktown' },
  { label: 'Cool', value: 'Cool' },
  { label: 'Cool Valley', value: 'Cool Valley' },
  { label: 'Coolah', value: 'Coolah' },
  { label: 'Coolamon', value: 'Coolamon' },
  { label: 'Coolangatta', value: 'Coolangatta' },
  { label: 'Coolaroo', value: 'Coolaroo' },
  { label: 'Coolbellup', value: 'Coolbellup' },
  { label: 'Coolbinia', value: 'Coolbinia' },
  { label: 'Coolgardie', value: 'Coolgardie' },
  { label: 'Coolidge', value: 'Coolidge' },
  { label: 'Coolie Town', value: 'Coolie Town' },
  { label: 'Coolie Town', value: 'Coolie Town' },
  { label: 'Coolie Town', value: 'Coolie Town' },
  { label: 'Coolock', value: 'Coolock' },
  { label: 'Cooloola Cove', value: 'Cooloola Cove' },
  { label: 'Cooloongup', value: 'Cooloongup' },
  { label: 'Coolum Beach', value: 'Coolum Beach' },
  { label: 'Cooma', value: 'Cooma' },
  { label: 'Coomalie', value: 'Coomalie' },
  { label: 'Coombabah', value: 'Coombabah' },
  { label: 'Coombs', value: 'Coombs' },
  { label: 'Coombs', value: 'Coombs' },
  { label: 'Coomera', value: 'Coomera' },
  { label: 'Coominya', value: 'Coominya' },
  { label: 'Coomonte', value: 'Coomonte' },
  { label: 'Coon Rapids', value: 'Coon Rapids' },
  { label: 'Coon Rapids', value: 'Coon Rapids' },
  { label: 'Coonabarabran', value: 'Coonabarabran' },
  { label: 'Coonamble', value: 'Coonamble' },
  { label: 'Coondapoor', value: 'Coondapoor' },
  { label: 'Cooper', value: 'Cooper' },
  { label: 'Cooper City', value: 'Cooper City' },
  { label: 'Cooper County', value: 'Cooper County' },
  { label: 'Cooper’S Town', value: 'Cooper’S Town' },
  { label: 'Coopers Plains', value: 'Coopers Plains' },
  { label: 'Coopersburg', value: 'Coopersburg' },
  { label: 'Cooperstown', value: 'Cooperstown' },
  { label: 'Cooperstown', value: 'Cooperstown' },
  { label: 'Cooperstown', value: 'Cooperstown' },
  { label: 'Coopersville', value: 'Coopersville' },
  { label: 'Coopertown', value: 'Coopertown' },
  { label: 'Cooran', value: 'Cooran' },
  { label: 'Cooranbong', value: 'Cooranbong' },
  { label: 'Cooreville Gardens', value: 'Cooreville Gardens' },
  { label: 'Cooroibah', value: 'Cooroibah' },
  { label: 'Coorow', value: 'Coorow' },
  { label: 'Cooroy', value: 'Cooroy' },
  { label: 'Coorparoo', value: 'Coorparoo' },
  { label: 'Coos Bay', value: 'Coos Bay' },
  { label: 'Coos County', value: 'Coos County' },
  { label: 'Coos County', value: 'Coos County' },
  { label: 'Coosa County', value: 'Coosa County' },
  { label: 'Coosada', value: 'Coosada' },
  { label: 'Cootamundra', value: 'Cootamundra' },
  { label: 'Cootehill', value: 'Cootehill' },
  { label: 'Copacabana', value: 'Copacabana' },
  { label: 'Copacabana', value: 'Copacabana' },
  { label: 'Copăcel', value: 'Copăcel' },
  { label: 'Copăcele', value: 'Copăcele' },
  { label: 'Copăcelu', value: 'Copăcelu' },
  { label: 'Copăceni', value: 'Copăceni' },
  { label: 'Copăceni', value: 'Copăceni' },
  { label: 'Copăceni', value: 'Copăceni' },
  { label: 'Copaciu', value: 'Copaciu' },
  { label: 'Copainalá', value: 'Copainalá' },
  { label: 'Copala', value: 'Copala' },
  { label: 'Cópala', value: 'Cópala' },
  { label: 'Copălău', value: 'Copălău' },
  { label: 'Copales', value: 'Copales' },
  { label: 'Copalillo', value: 'Copalillo' },
  { label: 'Copalnic', value: 'Copalnic' },
  { label: 'Copalnic Mănăştur', value: 'Copalnic Mănăştur' },
  { label: 'Copán', value: 'Copán' },
  { label: 'Copán Ruinas', value: 'Copán Ruinas' },
  { label: 'Copanatoyac', value: 'Copanatoyac' },
  { label: 'Copándaro (Copándaro Del Cuatro)', value: 'Copándaro (Copándaro Del Cuatro)' },
  { label: 'Copándaro De Galeana', value: 'Copándaro De Galeana' },
  { label: 'Copeland', value: 'Copeland' },
  { label: 'Copenhagen', value: 'Copenhagen' },
  { label: 'Coper', value: 'Coper' },
  { label: 'Coperchia', value: 'Coperchia' },
  { label: 'Copernal', value: 'Copernal' },
  { label: 'Copertino', value: 'Copertino' },
  { label: 'Copiague', value: 'Copiague' },
  { label: 'Copiah County', value: 'Copiah County' },
  { label: 'Copiano', value: 'Copiano' },
  { label: 'Copiapó', value: 'Copiapó' },
  { label: 'Copila', value: 'Copila' },
  { label: 'Coplay', value: 'Coplay' },
  { label: 'Cople', value: 'Cople' },
  { label: 'Copley', value: 'Copley' },
  { label: 'Copmanthorpe', value: 'Copmanthorpe' },
  { label: 'Copons', value: 'Copons' },
  { label: 'Copoya', value: 'Copoya' },
  { label: 'Coppabella', value: 'Coppabella' },
  { label: 'Copparo', value: 'Copparo' },
  { label: 'Coppell', value: 'Coppell' },
  { label: 'Coppenbrügge', value: 'Coppenbrügge' },
  { label: 'Copper Canyon', value: 'Copper Canyon' },
  { label: 'Copper Coast', value: 'Copper Coast' },
  { label: 'Copperas Cove', value: 'Copperas Cove' },
  { label: 'Copperopolis', value: 'Copperopolis' },
  { label: 'Coppet', value: 'Coppet' },
  { label: 'Coppito', value: 'Coppito' },
  { label: 'Copplestone', value: 'Copplestone' },
  { label: 'Coppull', value: 'Coppull' },
  { label: 'Copşa Mică', value: 'Copşa Mică' },
  { label: 'Copse', value: 'Copse' },
  { label: 'Copthorne', value: 'Copthorne' },
  { label: 'Coqueiral', value: 'Coqueiral' },
  { label: 'Coqueiro Baixo', value: 'Coqueiro Baixo' },
  { label: 'Coqueiro Seco', value: 'Coqueiro Seco' },
  { label: 'Coqueiros Do Sul', value: 'Coqueiros Do Sul' },
  { label: 'Coquelles', value: 'Coquelles' },
  { label: 'Coquí', value: 'Coquí' },
  { label: 'Coquille', value: 'Coquille' },
  { label: 'Coquimatlán', value: 'Coquimatlán' },
  { label: 'Coquimbo', value: 'Coquimbo' },
  { label: 'Coquitlam', value: 'Coquitlam' },
  { label: 'Corabia', value: 'Corabia' },
  { label: 'Coração De Jesus', value: 'Coração De Jesus' },
  { label: 'Coração De Jesus', value: 'Coração De Jesus' },
  { label: 'Coração De Maria', value: 'Coração De Maria' },
  { label: 'Coracora', value: 'Coracora' },
  { label: 'Corail', value: 'Corail' },
  { label: 'Coraki', value: 'Coraki' },
  { label: 'Coral Bay', value: 'Coral Bay' },
  { label: 'Coral Cove', value: 'Coral Cove' },
  { label: 'Coral Gables', value: 'Coral Gables' },
  { label: 'Coral Gardens', value: 'Coral Gardens' },
  { label: 'Coral Hills', value: 'Coral Hills' },
  { label: 'Coral Springs', value: 'Coral Springs' },
  { label: 'Coral Terrace', value: 'Coral Terrace' },
  { label: 'Ćoralići', value: 'Ćoralići' },
  { label: 'Coralville', value: 'Coralville' },
  { label: 'Coram', value: 'Coram' },
  { label: 'Corana', value: 'Corana' },
  { label: 'Corangamite', value: 'Corangamite' },
  { label: 'Coraopolis', value: 'Coraopolis' },
  { label: 'Corat', value: 'Corat' },
  { label: 'Corato', value: 'Corato' },
  { label: 'Coray', value: 'Coray' },
  { label: 'Corazón', value: 'Corazón' },
  { label: 'Corbalán', value: 'Corbalán' },
  { label: 'Corbanese', value: 'Corbanese' },
  { label: 'Corbara', value: 'Corbara' },
  { label: 'Corbarieu', value: 'Corbarieu' },
  { label: 'Corbas', value: 'Corbas' },
  { label: 'Corbasca', value: 'Corbasca' },
  { label: 'Corbeanca', value: 'Corbeanca' },
  { label: 'Corbehem', value: 'Corbehem' },
  { label: 'Corbeil-Essonnes', value: 'Corbeil-Essonnes' },
  { label: 'Corbeilles', value: 'Corbeilles' },
  { label: 'Corbélia', value: 'Corbélia' },
  { label: 'Corbelin', value: 'Corbelin' },
  { label: 'Corbenay', value: 'Corbenay' },
  { label: 'Corbeni', value: 'Corbeni' },
  { label: 'Corbera', value: 'Corbera' },
  { label: 'Corbera De Llobregat', value: 'Corbera De Llobregat' },
  { label: 'Corbera Debre', value: 'Corbera Debre' },
  { label: 'Corbești', value: 'Corbești' },
  { label: 'Corbetta', value: 'Corbetta' },
  { label: 'Corbi', value: 'Corbi' },
  { label: 'Corbie', value: 'Corbie' },
  { label: 'Corbigny', value: 'Corbigny' },
  { label: 'Corbii Mari', value: 'Corbii Mari' },
  { label: 'Corbillos De Los Oteros', value: 'Corbillos De Los Oteros' },
  { label: 'Corbin', value: 'Corbin' },
  { label: 'Corbins', value: 'Corbins' },
  { label: 'Corbiţa', value: 'Corbiţa' },
  { label: 'Corbola', value: 'Corbola' },
  { label: 'Corbolone', value: 'Corbolone' },
  { label: 'Corbreuse', value: 'Corbreuse' },
  { label: 'Corbridge', value: 'Corbridge' },
  { label: 'Corbu', value: 'Corbu' },
  { label: 'Corbu', value: 'Corbu' },
  { label: 'Corbu', value: 'Corbu' },
  { label: 'Corbu', value: 'Corbu' },
  { label: 'Corbu Nou', value: 'Corbu Nou' },
  { label: 'Corby', value: 'Corby' },
  { label: 'Corby Glen', value: 'Corby Glen' },
  { label: 'Corçà', value: 'Corçà' },
  { label: 'Corcagnano', value: 'Corcagnano' },
  { label: 'Corchiano', value: 'Corchiano' },
  { label: 'Corciano', value: 'Corciano' },
  { label: 'Corcieux', value: 'Corcieux' },
  { label: 'Corcolle', value: 'Corcolle' },
  { label: 'Corcoran', value: 'Corcoran' },
  { label: 'Corcoran', value: 'Corcoran' },
  { label: 'Corcos', value: 'Corcos' },
  { label: 'Corcoué-Sur-Logne', value: 'Corcoué-Sur-Logne' },
  { label: 'Corcova', value: 'Corcova' },
  { label: 'Corcovado', value: 'Corcovado' },
  { label: 'Corcovado', value: 'Corcovado' },
  { label: 'Corcubión', value: 'Corcubión' },
  { label: 'Corcuera', value: 'Corcuera' },
  { label: 'Cordăreni', value: 'Cordăreni' },
  { label: 'Cordău', value: 'Cordău' },
  { label: 'Cordaville', value: 'Cordaville' },
  { label: 'Cordeaux Heights', value: 'Cordeaux Heights' },
  { label: 'Cordeiro', value: 'Cordeiro' },
  { label: 'Cordeirópolis', value: 'Cordeirópolis' },
  { label: 'Cordeiros', value: 'Cordeiros' },
  { label: 'Cordele', value: 'Cordele' },
  { label: 'Cordell', value: 'Cordell' },
  { label: 'Cordemais', value: 'Cordemais' },
  { label: 'Cordenons', value: 'Cordenons' },
  { label: 'Cordes Lakes', value: 'Cordes Lakes' },
  { label: 'Cordes-Sur-Ciel', value: 'Cordes-Sur-Ciel' },
  { label: 'Cordignano', value: 'Cordignano' },
  { label: 'Cordilheira Alta', value: 'Cordilheira Alta' },
  { label: 'Cordillera', value: 'Cordillera' },
  { label: 'Cordisburgo', value: 'Cordisburgo' },
  { label: 'Cordislândia', value: 'Cordislândia' },
  { label: 'Córdoba', value: 'Córdoba' },
  { label: 'Córdoba', value: 'Córdoba' },
  { label: 'Córdoba', value: 'Córdoba' },
  { label: 'Córdoba', value: 'Córdoba' },
  { label: 'Córdoba', value: 'Córdoba' },
  { label: 'Córdoba', value: 'Córdoba' },
  { label: 'Córdoba (Santa Leticia)', value: 'Córdoba (Santa Leticia)' },
  { label: 'Cordobilla De Lácara', value: 'Cordobilla De Lácara' },
  { label: 'Cordon', value: 'Cordon' },
  { label: 'Cordon', value: 'Cordon' },
  { label: 'Cordon', value: 'Cordon' },
  { label: 'Cordón', value: 'Cordón' },
  { label: 'Cordoș', value: 'Cordoș' },
  { label: 'Cordova', value: 'Cordova' },
  { label: 'Cordova', value: 'Cordova' },
  { label: 'Cordova', value: 'Cordova' },
  { label: 'Cordova', value: 'Cordova' },
  { label: 'Cordova', value: 'Cordova' },
  { label: 'Cordovado', value: 'Cordovado' },
  { label: 'Cordovilla', value: 'Cordovilla' },
  { label: 'Cordovilla La Real', value: 'Cordovilla La Real' },
  { label: 'Cordovín', value: 'Cordovín' },
  { label: 'Cordry Sweetwater Lakes', value: 'Cordry Sweetwater Lakes' },
  { label: 'Corduente', value: 'Corduente' },
  { label: 'Cordun', value: 'Cordun' },
  { label: 'Coreaú', value: 'Coreaú' },
  { label: 'Coredo', value: 'Coredo' },
  { label: 'Coreglia Antelminelli', value: 'Coreglia Antelminelli' },
  { label: 'Coreglia Ligure', value: 'Coreglia Ligure' },
  { label: 'Corella', value: 'Corella' },
  { label: 'Corella', value: 'Corella' },
  { label: 'Coremas', value: 'Coremas' },
  { label: 'Corenc', value: 'Corenc' },
  { label: 'Coreno Ausonio', value: 'Coreno Ausonio' },
  { label: 'Corera', value: 'Corera' },
  { label: 'Corerepe', value: 'Corerepe' },
  { label: 'Coreses', value: 'Coreses' },
  { label: 'Corfe Castle', value: 'Corfe Castle' },
  { label: 'Corfinio', value: 'Corfinio' },
  { label: 'Corfu', value: 'Corfu' },
  { label: 'Corgémont', value: 'Corgémont' },
  { label: 'Corgeno', value: 'Corgeno' },
  { label: 'Corgo O', value: 'Corgo O' },
  { label: 'Corguinho', value: 'Corguinho' },
  { label: 'Cori', value: 'Cori' },
  { label: 'Coria', value: 'Coria' },
  { label: 'Coria Del Río', value: 'Coria Del Río' },
  { label: 'Coriano', value: 'Coriano' },
  { label: 'Coribe', value: 'Coribe' },
  { label: 'Corigliano Calabro', value: 'Corigliano Calabro' },
  { label: 'Corigliano Dotranto', value: 'Corigliano Dotranto' },
  { label: 'Corigliano Scalo', value: 'Corigliano Scalo' },
  { label: 'Corinaldo', value: 'Corinaldo' },
  { label: 'Corinda', value: 'Corinda' },
  { label: 'Corindi Beach', value: 'Corindi Beach' },
  { label: 'Corinna', value: 'Corinna' },
  { label: 'Corinth', value: 'Corinth' },
  { label: 'Corinth', value: 'Corinth' },
  { label: 'Corinth', value: 'Corinth' },
  { label: 'Corinth', value: 'Corinth' },
  { label: 'Corinth Estate', value: 'Corinth Estate' },
  { label: 'Corinth/La Bel Lair', value: 'Corinth/La Bel Lair' },
  { label: 'Corinto', value: 'Corinto' },
  { label: 'Corinto', value: 'Corinto' },
  { label: 'Corinto', value: 'Corinto' },
  { label: 'Corinto', value: 'Corinto' },
  { label: 'Corio', value: 'Corio' },
  { label: 'Corio', value: 'Corio' },
  { label: 'Coripata', value: 'Coripata' },
  { label: 'Coripe', value: 'Coripe' },
  { label: 'Coriscada', value: 'Coriscada' },
  { label: 'Coristanco', value: 'Coristanco' },
  { label: 'Cork', value: 'Cork' },
  { label: 'Cork City', value: 'Cork City' },
  { label: 'Corlaer', value: 'Corlaer' },
  { label: 'Corlăţel', value: 'Corlăţel' },
  { label: 'Corlăteni', value: 'Corlăteni' },
  { label: 'Corlătești', value: 'Corlătești' },
  { label: 'Corlay', value: 'Corlay' },
  { label: 'Corleone', value: 'Corleone' },
  { label: 'Corleto Monforte', value: 'Corleto Monforte' },
  { label: 'Corleto Perticara', value: 'Corleto Perticara' },
  { label: 'Corlette', value: 'Corlette' },
  { label: 'Çorlu', value: 'Çorlu' },
  { label: 'Cormaia', value: 'Cormaia' },
  { label: 'Cormano', value: 'Cormano' },
  { label: 'Cormeilles', value: 'Cormeilles' },
  { label: 'Cormeilles-En-Parisis', value: 'Cormeilles-En-Parisis' },
  { label: 'Cormelles-Le-Royal', value: 'Cormelles-Le-Royal' },
  { label: 'Cormeray', value: 'Cormeray' },
  { label: 'Corme-Royal', value: 'Corme-Royal' },
  { label: 'Cormery', value: 'Cormery' },
  { label: 'Cormicy', value: 'Cormicy' },
  { label: 'Corminboeuf', value: 'Corminboeuf' },
  { label: 'Cormons', value: 'Cormons' },
  { label: 'Cormontreuil', value: 'Cormontreuil' },
  { label: 'Corn Island', value: 'Corn Island' },
  { label: 'Corna Imagna', value: 'Corna Imagna' },
  { label: 'Cornago', value: 'Cornago' },
  { label: 'Cornaiano', value: 'Cornaiano' },
  { label: 'Cornalba', value: 'Cornalba' },
  { label: 'Cornale', value: 'Cornale' },
  { label: 'Cornaredo', value: 'Cornaredo' },
  { label: 'Cornas', value: 'Cornas' },
  { label: 'Cornate Dadda', value: 'Cornate Dadda' },
  { label: 'Cornățel', value: 'Cornățel' },
  { label: 'Cornăţelu', value: 'Cornăţelu' },
  { label: 'Cornaux', value: 'Cornaux' },
  { label: 'Cornberg', value: 'Cornberg' },
  { label: 'Corné', value: 'Corné' },
  { label: 'Cornea', value: 'Cornea' },
  { label: 'Cornebarrieu', value: 'Cornebarrieu' },
  { label: 'Cornedo Allisarco', value: 'Cornedo Allisarco' },
  { label: 'Cornedo Vicentino', value: 'Cornedo Vicentino' },
  { label: 'Corneilhan', value: 'Corneilhan' },
  { label: 'Corneilla-Del-Vercol', value: 'Corneilla-Del-Vercol' },
  { label: 'Corneilla-La-Rivière', value: 'Corneilla-La-Rivière' },
  { label: 'Cornelia', value: 'Cornelia' },
  { label: 'Corneliano Dalba', value: 'Corneliano Dalba' },
  { label: 'Cornélio Procópio', value: 'Cornélio Procópio' },
  { label: 'Cornelius', value: 'Cornelius' },
  { label: 'Cornelius', value: 'Cornelius' },
  { label: 'Cornell', value: 'Cornell' },
  { label: 'Cornellà De Llobregat', value: 'Cornellà De Llobregat' },
  { label: 'Cornellà Del Terri', value: 'Cornellà Del Terri' },
  { label: 'Corneno-Galliano-Carella Mariaga', value: 'Corneno-Galliano-Carella Mariaga' },
  { label: 'Corner Brook', value: 'Corner Brook' },
  { label: 'Cornereva', value: 'Cornereva' },
  { label: 'Cornersville', value: 'Cornersville' },
  { label: 'Corneşti', value: 'Corneşti' },
  { label: 'Corneşti', value: 'Corneşti' },
  { label: 'Cornești', value: 'Cornești' },
  { label: 'Cornești', value: 'Cornești' },
  { label: 'Cornetu', value: 'Cornetu' },
  { label: 'Cornetu', value: 'Cornetu' },
  { label: 'Corneville-Sur-Risle', value: 'Corneville-Sur-Risle' },
  { label: 'Cornholme', value: 'Cornholme' },
  { label: 'Corni', value: 'Corni' },
  { label: 'Corni', value: 'Corni' },
  { label: 'Corni', value: 'Corni' },
  { label: 'Corni-Albești', value: 'Corni-Albești' },
  { label: 'Cornier', value: 'Cornier' },
  { label: 'Corniglio', value: 'Corniglio' },
  { label: 'Cornil', value: 'Cornil' },
  { label: 'Cornillon', value: 'Cornillon' },
  { label: 'Cornillon-Confoux', value: 'Cornillon-Confoux' },
  { label: 'Cornimont', value: 'Cornimont' },
  { label: 'Corning', value: 'Corning' },
  { label: 'Corning', value: 'Corning' },
  { label: 'Corning', value: 'Corning' },
  { label: 'Corning', value: 'Corning' },
  { label: 'Cornish', value: 'Cornish' },
  { label: 'Corno Di Rosazzo', value: 'Corno Di Rosazzo' },
  { label: 'Corno Giovine', value: 'Corno Giovine' },
  { label: 'Cornovecchio', value: 'Cornovecchio' },
  { label: 'Cornu De Jos', value: 'Cornu De Jos' },
  { label: 'Cornu De Sus', value: 'Cornu De Sus' },
  { label: 'Cornu Luncii', value: 'Cornu Luncii' },
  { label: 'Cornubia', value: 'Cornubia' },
  { label: 'Cornuda', value: 'Cornuda' },
  { label: 'Cornudella De Montsant', value: 'Cornudella De Montsant' },
  { label: 'Cornuțel', value: 'Cornuțel' },
  { label: 'Cornville', value: 'Cornville' },
  { label: 'Cornville', value: 'Cornville' },
  { label: 'Cornwall', value: 'Cornwall' },
  { label: 'Cornwall', value: 'Cornwall' },
  { label: 'Cornwall', value: 'Cornwall' },
  { label: 'Cornwall', value: 'Cornwall' },
  { label: 'Cornwall', value: 'Cornwall' },
  { label: 'Cornwall', value: 'Cornwall' },
  { label: 'Cornwall Mountian', value: 'Cornwall Mountian' },
  { label: 'Cornwells Heights', value: 'Cornwells Heights' },
  { label: 'Corny-Sur-Moselle', value: 'Corny-Sur-Moselle' },
  { label: 'Coro', value: 'Coro' },
  { label: 'Coroaci', value: 'Coroaci' },
  { label: 'Coroados', value: 'Coroados' },
  { label: 'Coroatá', value: 'Coroatá' },
  { label: 'Corocăiești', value: 'Corocăiești' },
  { label: 'Corocito', value: 'Corocito' },
  { label: 'Corocotan', value: 'Corocotan' },
  { label: 'Corocotan', value: 'Corocotan' },
  { label: 'Corocotan', value: 'Corocotan' },
  { label: 'Corod', value: 'Corod' },
  { label: 'Coroi', value: 'Coroi' },
  { label: 'Coroico', value: 'Coroico' },
  { label: 'Coroieni', value: 'Coroieni' },
  { label: 'Coroieşti', value: 'Coroieşti' },
  { label: 'Coromandel', value: 'Coromandel' },
  { label: 'Coromandel', value: 'Coromandel' },
  { label: 'Coromandel', value: 'Coromandel' },
  { label: 'Coromandel Valley', value: 'Coromandel Valley' },
  { label: 'Coromoro', value: 'Coromoro' },
  { label: 'Coron', value: 'Coron' },
  { label: 'Coron', value: 'Coron' },
  { label: 'Corona', value: 'Corona' },
  { label: 'Corona', value: 'Corona' },
  { label: 'Corona De Tucson', value: 'Corona De Tucson' },
  { label: 'Coronada La', value: 'Coronada La' },
  { label: 'Coronado', value: 'Coronado' },
  { label: 'Coronango', value: 'Coronango' },
  { label: 'Coronda', value: 'Coronda' },
  { label: 'Coronel', value: 'Coronel' },
  { label: 'Coronel Baigorria', value: 'Coronel Baigorria' },
  { label: 'Coronel Barros', value: 'Coronel Barros' },
  { label: 'Coronel Belisle', value: 'Coronel Belisle' },
  { label: 'Coronel Bicaco', value: 'Coronel Bicaco' },
  { label: 'Coronel Domingos Soares', value: 'Coronel Domingos Soares' },
  { label: 'Coronel Du Graty', value: 'Coronel Du Graty' },
  { label: 'Coronel Ezequiel', value: 'Coronel Ezequiel' },
  { label: 'Coronel Fabriciano', value: 'Coronel Fabriciano' },
  { label: 'Coronel Freitas', value: 'Coronel Freitas' },
  { label: 'Coronel João Pessoa', value: 'Coronel João Pessoa' },
  { label: 'Coronel João Sá', value: 'Coronel João Sá' },
  { label: 'Coronel José Dias', value: 'Coronel José Dias' },
  { label: 'Coronel Macedo', value: 'Coronel Macedo' },
  { label: 'Coronel Marcelino Maridueña', value: 'Coronel Marcelino Maridueña' },
  { label: 'Coronel Martínez', value: 'Coronel Martínez' },
  { label: 'Coronel Martins', value: 'Coronel Martins' },
  { label: 'Coronel Moldes', value: 'Coronel Moldes' },
  { label: 'Coronel Murta', value: 'Coronel Murta' },
  { label: 'Coronel Oviedo', value: 'Coronel Oviedo' },
  { label: 'Coronel Pacheco', value: 'Coronel Pacheco' },
  { label: 'Coronel Pilar', value: 'Coronel Pilar' },
  { label: 'Coronel Sapucaia', value: 'Coronel Sapucaia' },
  { label: 'Coronel Tito Hernández', value: 'Coronel Tito Hernández' },
  { label: 'Coronel Vivida', value: 'Coronel Vivida' },
  { label: 'Coronel Xavier Chaves', value: 'Coronel Xavier Chaves' },
  { label: 'Coroneo', value: 'Coroneo' },
  { label: 'Coronil El', value: 'Coronil El' },
  { label: 'Coronini', value: 'Coronini' },
  { label: 'Coronon', value: 'Coronon' },
  { label: 'Coronon', value: 'Coronon' },
  { label: 'Coronon', value: 'Coronon' },
  { label: 'Coropceni', value: 'Coropceni' },
  { label: 'Çorovodë', value: 'Çorovodë' },
  { label: 'Corowa', value: 'Corowa' },
  { label: 'Corozal', value: 'Corozal' },
  { label: 'Corozal', value: 'Corozal' },
  { label: 'Corozal', value: 'Corozal' },
  { label: 'Corozal', value: 'Corozal' },
  { label: 'Corozal', value: 'Corozal' },
  { label: 'Corozal', value: 'Corozal' },
  { label: 'Corozal', value: 'Corozal' },
  { label: 'Corozal', value: 'Corozal' },
  { label: 'Corozal Municipio', value: 'Corozal Municipio' },
  { label: 'Corpa', value: 'Corpa' },
  { label: 'Corpeau', value: 'Corpeau' },
  { label: 'Corpo Reno', value: 'Corpo Reno' },
  { label: 'Corpolò', value: 'Corpolò' },
  { label: 'Corporales', value: 'Corporales' },
  { label: 'Corps-Nuds', value: 'Corps-Nuds' },
  { label: 'Corpus', value: 'Corpus' },
  { label: 'Corpus Christi', value: 'Corpus Christi' },
  { label: 'Corpus Christi', value: 'Corpus Christi' },
  { label: 'Corquilleroy', value: 'Corquilleroy' },
  { label: 'Corquín', value: 'Corquín' },
  { label: 'Corral', value: 'Corral' },
  { label: 'Corral Blanco', value: 'Corral Blanco' },
  { label: 'Corral De Almaguer', value: 'Corral De Almaguer' },
  { label: 'Corral De Ayllón', value: 'Corral De Ayllón' },
  { label: 'Corral De Barrancos', value: 'Corral De Barrancos' },
  { label: 'Corral De Bustos', value: 'Corral De Bustos' },
  { label: 'Corral De Calatrava', value: 'Corral De Calatrava' },
  { label: 'Corral De Piedra', value: 'Corral De Piedra' },
  { label: 'Corral De Piedra', value: 'Corral De Piedra' },
  { label: 'Corral De Piedras De Arriba', value: 'Corral De Piedras De Arriba' },
  { label: 'Corral Del Risco (Punta De Mita)', value: 'Corral Del Risco (Punta De Mita)' },
  { label: 'Corral Falso', value: 'Corral Falso' },
  { label: 'Corral Nuevo', value: 'Corral Nuevo' },
  { label: 'Corralejo', value: 'Corralejo' },
  { label: 'Corralejo De Arriba', value: 'Corralejo De Arriba' },
  { label: 'Corralero', value: 'Corralero' },
  { label: 'Corrales', value: 'Corrales' },
  { label: 'Corrales', value: 'Corrales' },
  { label: 'Corrales', value: 'Corrales' },
  { label: 'Corrales De Buelna Los', value: 'Corrales De Buelna Los' },
  { label: 'Corrales De Duero', value: 'Corrales De Duero' },
  { label: 'Corrales Del Vino', value: 'Corrales Del Vino' },
  { label: 'Corrales Los', value: 'Corrales Los' },
  { label: 'Corralillo', value: 'Corralillo' },
  { label: 'Corralillos', value: 'Corralillos' },
  { label: 'Corralillos', value: 'Corralillos' },
  { label: 'Corralito', value: 'Corralito' },
  { label: 'Corralitos', value: 'Corralitos' },
  { label: 'Corral-Rubio', value: 'Corral-Rubio' },
  { label: 'Correderos', value: 'Correderos' },
  { label: 'Corredor', value: 'Corredor' },
  { label: 'Corredores', value: 'Corredores' },
  { label: 'Correggio', value: 'Correggio' },
  { label: 'Córrego Danta', value: 'Córrego Danta' },
  { label: 'Córrego Do Bom Jesus', value: 'Córrego Do Bom Jesus' },
  { label: 'Córrego Do Ouro', value: 'Córrego Do Ouro' },
  { label: 'Córrego Fundo', value: 'Córrego Fundo' },
  { label: 'Corrego Grande', value: 'Corrego Grande' },
  { label: 'Córrego Novo', value: 'Córrego Novo' },
  { label: 'Correia Pinto', value: 'Correia Pinto' },
  { label: 'Corrente', value: 'Corrente' },
  { label: 'Correntes', value: 'Correntes' },
  { label: 'Correntina', value: 'Correntina' },
  { label: 'Corrèze', value: 'Corrèze' },
  { label: 'Correzzana', value: 'Correzzana' },
  { label: 'Correzzola', value: 'Correzzola' },
  { label: 'Corrido', value: 'Corrido' },
  { label: 'Corridonia', value: 'Corridonia' },
  { label: 'Corrientes', value: 'Corrientes' },
  { label: 'Corrigan', value: 'Corrigan' },
  { label: 'Corrigin', value: 'Corrigin' },
  { label: 'Corrimal', value: 'Corrimal' },
  { label: 'Corroios', value: 'Corroios' },
  { label: 'Corrooy', value: 'Corrooy' },
  { label: 'Corrooy', value: 'Corrooy' },
  { label: 'Corropoli', value: 'Corropoli' },
  { label: 'Corrubbio', value: 'Corrubbio' },
  { label: 'Corry', value: 'Corry' },
  { label: 'Corryong', value: 'Corryong' },
  { label: 'Corsanico-Bargecchia', value: 'Corsanico-Bargecchia' },
  { label: 'Corsano', value: 'Corsano' },
  { label: 'Corse', value: 'Corse' },
  { label: 'Corsept', value: 'Corsept' },
  { label: 'Corseul', value: 'Corseul' },
  { label: 'Corsham', value: 'Corsham' },
  { label: 'Corsicana', value: 'Corsicana' },
  { label: 'Corsico', value: 'Corsico' },
  { label: 'Corsier', value: 'Corsier' },
  { label: 'Corsione', value: 'Corsione' },
  { label: 'Corson County', value: 'Corson County' },
  { label: 'Corston', value: 'Corston' },
  { label: 'Cortaccia Sulla Strada Del Vino', value: 'Cortaccia Sulla Strada Del Vino' },
  { label: 'Cortaillod', value: 'Cortaillod' },
  { label: 'Cortale', value: 'Cortale' },
  { label: 'Cortalim', value: 'Cortalim' },
  { label: 'Cortandone', value: 'Cortandone' },
  { label: 'Čortanovci', value: 'Čortanovci' },
  { label: 'Cortanze', value: 'Cortanze' },
  { label: 'Cortazar', value: 'Cortazar' },
  { label: 'Cortazzone', value: 'Cortazzone' },
  { label: 'Corte', value: 'Corte' },
  { label: 'Corte', value: 'Corte' },
  { label: 'Corte De Cortesi', value: 'Corte De Cortesi' },
  { label: 'Corte De Cortesi Con Cignone', value: 'Corte De Cortesi Con Cignone' },
  { label: 'Corte De Frati', value: 'Corte De Frati' },
  { label: 'Corte De Peleas', value: 'Corte De Peleas' },
  { label: 'Corte Franca', value: 'Corte Franca' },
  { label: 'Corte Madera', value: 'Corte Madera' },
  { label: 'Corteconcepción', value: 'Corteconcepción' },
  { label: 'Cortegaça', value: 'Cortegaça' },
  { label: 'Cortegada', value: 'Cortegada' },
  { label: 'Cortegana', value: 'Cortegana' },
  { label: 'Cortelazor', value: 'Cortelazor' },
  { label: 'Cortemaggiore', value: 'Cortemaggiore' },
  { label: 'Cortemilia', value: 'Cortemilia' },
  { label: 'Corteno Golgi', value: 'Corteno Golgi' },
  { label: 'Cortenova', value: 'Cortenova' },
  { label: 'Cortenuova', value: 'Cortenuova' },
  { label: 'Corteolona', value: 'Corteolona' },
  { label: 'Cortes', value: 'Cortes' },
  { label: 'Cortes', value: 'Cortes' },
  { label: 'Cortes', value: 'Cortes' },
  { label: 'Cortes', value: 'Cortes' },
  { label: 'Cortês', value: 'Cortês' },
  { label: 'Cortes De Aragón', value: 'Cortes De Aragón' },
  { label: 'Cortes De Arenoso', value: 'Cortes De Arenoso' },
  { label: 'Cortes De Baza', value: 'Cortes De Baza' },
  { label: 'Cortes De La Frontera', value: 'Cortes De La Frontera' },
  { label: 'Cortes De Pallás', value: 'Cortes De Pallás' },
  { label: 'Cortes Y Graena', value: 'Cortes Y Graena' },
  { label: 'Cortez', value: 'Cortez' },
  { label: 'Cortez', value: 'Cortez' },
  { label: 'Cortez', value: 'Cortez' },
  { label: 'Cortiçada', value: 'Cortiçada' },
  { label: 'Cortiçô', value: 'Cortiçô' },
  { label: 'Cortiçô Da Serra', value: 'Cortiçô Da Serra' },
  { label: 'Cortiglione', value: 'Cortiglione' },
  { label: 'Cortijos Los', value: 'Cortijos Los' },
  { label: 'Cortina Dampezzo', value: 'Cortina Dampezzo' },
  { label: 'Cortina Sulla Strada Del Vino', value: 'Cortina Sulla Strada Del Vino' },
  { label: 'Cortino', value: 'Cortino' },
  { label: 'Cortland', value: 'Cortland' },
  { label: 'Cortland', value: 'Cortland' },
  { label: 'Cortland', value: 'Cortland' },
  { label: 'Cortland County', value: 'Cortland County' },
  { label: 'Cortland West', value: 'Cortland West' },
  { label: 'Cortoghiana', value: 'Cortoghiana' },
  { label: 'Cortona', value: 'Cortona' },
  { label: 'Coruche', value: 'Coruche' },
  { label: 'Coruche', value: 'Coruche' },
  { label: 'Coruia', value: 'Coruia' },
  { label: 'Corujeira', value: 'Corujeira' },
  { label: 'Corullón', value: 'Corullón' },
  { label: 'Corumbá', value: 'Corumbá' },
  { label: 'Corumbá De Goiás', value: 'Corumbá De Goiás' },
  { label: 'Corumbaíba', value: 'Corumbaíba' },
  { label: 'Corumbataí', value: 'Corumbataí' },
  { label: 'Corumbataí Do Sul', value: 'Corumbataí Do Sul' },
  { label: 'Corumbiara', value: 'Corumbiara' },
  { label: 'Coruña A', value: 'Coruña A' },
  { label: 'Coruña Del Conde', value: 'Coruña Del Conde' },
  { label: 'Corunca', value: 'Corunca' },
  { label: 'Corund', value: 'Corund' },
  { label: 'Corunna', value: 'Corunna' },
  { label: 'Corunna', value: 'Corunna' },
  { label: 'Corupá', value: 'Corupá' },
  { label: 'Corupo', value: 'Corupo' },
  { label: 'Coruripe', value: 'Coruripe' },
  { label: 'Corvallis', value: 'Corvallis' },
  { label: 'Corvara', value: 'Corvara' },
  { label: 'Corvara In Badia', value: 'Corvara In Badia' },
  { label: 'Corvaro', value: 'Corvaro' },
  { label: 'Corvera De Asturias', value: 'Corvera De Asturias' },
  { label: 'Corvera De Toranzo', value: 'Corvera De Toranzo' },
  { label: 'Corvino San Quirico', value: 'Corvino San Quirico' },
  { label: 'Corvo', value: 'Corvo' },
  { label: 'Corydon', value: 'Corydon' },
  { label: 'Corydon', value: 'Corydon' },
  { label: 'Coryell County', value: 'Coryell County' },
  { label: 'Corzano', value: 'Corzano' },
  { label: 'Corzé', value: 'Corzé' },
  { label: 'Corzuela', value: 'Corzuela' },
  { label: 'Cos Cob', value: 'Cos Cob' },
  { label: 'Cosa', value: 'Cosa' },
  { label: 'Cosalá', value: 'Cosalá' },
  { label: 'Cosamaloapan', value: 'Cosamaloapan' },
  { label: 'Cosamaloapan De Carpio', value: 'Cosamaloapan De Carpio' },
  { label: 'Cosâmbeşti', value: 'Cosâmbeşti' },
  { label: 'Coșani', value: 'Coșani' },
  { label: 'Cosautlán', value: 'Cosautlán' },
  { label: 'Cosautlán De Carvajal', value: 'Cosautlán De Carvajal' },
  { label: 'Coşbuc', value: 'Coşbuc' },
  { label: 'Cosby', value: 'Cosby' },
  { label: 'Coscomate Del Progreso', value: 'Coscomate Del Progreso' },
  { label: 'Coscomatepec De Bravo', value: 'Coscomatepec De Bravo' },
  { label: 'Coscurita', value: 'Coscurita' },
  { label: 'Coseano', value: 'Coseano' },
  { label: 'Coşeiu', value: 'Coşeiu' },
  { label: 'Cosenza', value: 'Cosenza' },
  { label: 'Coşereni', value: 'Coşereni' },
  { label: 'Coşeşti', value: 'Coşeşti' },
  { label: 'Cosham', value: 'Cosham' },
  { label: 'Coshocton', value: 'Coshocton' },
  { label: 'Coshocton County', value: 'Coshocton County' },
  { label: 'Cosina', value: 'Cosina' },
  { label: 'Cosío', value: 'Cosío' },
  { label: 'Cosio Di Arroscia', value: 'Cosio Di Arroscia' },
  { label: 'Cosio Valtellino', value: 'Cosio Valtellino' },
  { label: 'Coslada', value: 'Coslada' },
  { label: 'Cosmeşti', value: 'Cosmeşti' },
  { label: 'Cosmeşti', value: 'Cosmeşti' },
  { label: 'Cosmeștii-Vale', value: 'Cosmeștii-Vale' },
  { label: 'Cosmina De Jos', value: 'Cosmina De Jos' },
  { label: 'Cosmopolis', value: 'Cosmopolis' },
  { label: 'Cosmópolis', value: 'Cosmópolis' },
  { label: 'Cosmorama', value: 'Cosmorama' },
  { label: 'Coșna', value: 'Coșna' },
  { label: 'Cosnac', value: 'Cosnac' },
  { label: 'Cosne-Cours-Sur-Loire', value: 'Cosne-Cours-Sur-Loire' },
  { label: 'Cosnes', value: 'Cosnes' },
  { label: 'Cosniga-Zoppè', value: 'Cosniga-Zoppè' },
  { label: 'Cosoba', value: 'Cosoba' },
  { label: 'Cosolapa', value: 'Cosolapa' },
  { label: 'Cosoleacaque', value: 'Cosoleacaque' },
  { label: 'Cosoleto', value: 'Cosoleto' },
  { label: 'Cosoltepec', value: 'Cosoltepec' },
  { label: 'Coşoveni', value: 'Coşoveni' },
  { label: 'Cospeito', value: 'Cospeito' },
  { label: 'Cospicua', value: 'Cospicua' },
  { label: 'Cosquín', value: 'Cosquín' },
  { label: 'Cossack', value: 'Cossack' },
  { label: 'Cossano Belbo', value: 'Cossano Belbo' },
  { label: 'Cossano Canavese', value: 'Cossano Canavese' },
  { label: 'Cossato', value: 'Cossato' },
  { label: 'Cossé-Le-Vivien', value: 'Cossé-Le-Vivien' },
  { label: 'Cosseria', value: 'Cosseria' },
  { label: 'Cossignano', value: 'Cossignano' },
  { label: 'Cossimbazar', value: 'Cossimbazar' },
  { label: 'Cossogno', value: 'Cossogno' },
  { label: 'Cossoine', value: 'Cossoine' },
  { label: 'Cossombrato', value: 'Cossombrato' },
  { label: 'Cossonay', value: 'Cossonay' },
  { label: 'Costa Azul', value: 'Costa Azul' },
  { label: 'Costa Calma', value: 'Costa Calma' },
  { label: 'Costa De Caparica', value: 'Costa De Caparica' },
  { label: 'Costa De Nobili', value: 'Costa De Nobili' },
  { label: 'Costa Di Mezzate', value: 'Costa Di Mezzate' },
  { label: 'Costa Di Rovigo', value: 'Costa Di Rovigo' },
  { label: 'Costa Di Serina', value: 'Costa Di Serina' },
  { label: 'Costa Lambro', value: 'Costa Lambro' },
  { label: 'Costa Marques', value: 'Costa Marques' },
  { label: 'Costa Masnaga', value: 'Costa Masnaga' },
  { label: 'Costa Mesa', value: 'Costa Mesa' },
  { label: 'Costa Rica', value: 'Costa Rica' },
  { label: 'Costa Sacate', value: 'Costa Sacate' },
  { label: 'Costa Santabramo', value: 'Costa Santabramo' },
  { label: 'Costa Teguise', value: 'Costa Teguise' },
  { label: 'Costa Valle Imagna', value: 'Costa Valle Imagna' },
  { label: 'Costa Vescovato', value: 'Costa Vescovato' },
  { label: 'Costa Volpino', value: 'Costa Volpino' },
  { label: 'Costa-Barco', value: 'Costa-Barco' },
  { label: 'Costabissara', value: 'Costabissara' },
  { label: 'Costacciaro', value: 'Costacciaro' },
  { label: 'Costache Negri', value: 'Costache Negri' },
  { label: 'Costâna', value: 'Costâna' },
  { label: 'Costano', value: 'Costano' },
  { label: 'Costanzana', value: 'Costanzana' },
  { label: 'Costarainera', value: 'Costarainera' },
  { label: 'Costaroni', value: 'Costaroni' },
  { label: 'Costeira Do Pirajubae', value: 'Costeira Do Pirajubae' },
  { label: 'Coşteiu', value: 'Coşteiu' },
  { label: 'Costeni', value: 'Costeni' },
  { label: 'Costermano', value: 'Costermano' },
  { label: 'Costessey', value: 'Costessey' },
  { label: 'Costeşti', value: 'Costeşti' },
  { label: 'Costeşti', value: 'Costeşti' },
  { label: 'Costeşti', value: 'Costeşti' },
  { label: 'Costeşti', value: 'Costeşti' },
  { label: 'Costești', value: 'Costești' },
  { label: 'Costești', value: 'Costești' },
  { label: 'Costești', value: 'Costești' },
  { label: 'Costeștii Din Deal', value: 'Costeștii Din Deal' },
  { label: 'Costeştii Din Vale', value: 'Costeştii Din Vale' },
  { label: 'Costigliole Dasti', value: 'Costigliole Dasti' },
  { label: 'Costiglione Saluzzo', value: 'Costiglione Saluzzo' },
  { label: 'Costilla County', value: 'Costilla County' },
  { label: 'Costineşti', value: 'Costineşti' },
  { label: 'Costişa', value: 'Costişa' },
  { label: 'Costitx', value: 'Costitx' },
  { label: 'Coștiui', value: 'Coștiui' },
  { label: 'Costuleni', value: 'Costuleni' },
  { label: 'Costur', value: 'Costur' },
  { label: 'Cosuenda', value: 'Cosuenda' },
  { label: 'Coșula', value: 'Coșula' },
  { label: 'Coswig', value: 'Coswig' },
  { label: 'Coswig', value: 'Coswig' },
  { label: 'Cot', value: 'Cot' },
  { label: 'Cota', value: 'Cota' },
  { label: 'Cotabato', value: 'Cotabato' },
  { label: 'Cotabato City', value: 'Cotabato City' },
  { label: 'Cotabato City', value: 'Cotabato City' },
  { label: 'Cotacachi', value: 'Cotacachi' },
  { label: 'Cotahuasi', value: 'Cotahuasi' },
  { label: 'Cotanes Del Monte', value: 'Cotanes Del Monte' },
  { label: 'Coțatcu', value: 'Coțatcu' },
  { label: 'Cotati', value: 'Cotati' },
  { label: 'Cotaxtla', value: 'Cotaxtla' },
  { label: 'Coteana', value: 'Coteana' },
  { label: 'Coteau-Du-Lac', value: 'Coteau-Du-Lac' },
  { label: 'Cotegipe', value: 'Cotegipe' },
  { label: 'Coté-Lai', value: 'Coté-Lai' },
  { label: 'Cotenești', value: 'Cotenești' },
  { label: 'Côte-Nord', value: 'Côte-Nord' },
  { label: 'Cotes', value: 'Cotes' },
  { label: 'Côte-Saint-Luc', value: 'Côte-Saint-Luc' },
  { label: 'Coteşti', value: 'Coteşti' },
  { label: 'Cotgrave', value: 'Cotgrave' },
  { label: 'Cothen', value: 'Cothen' },
  { label: 'Cotia', value: 'Cotia' },
  { label: 'Cotignac', value: 'Cotignac' },
  { label: 'Cotignola', value: 'Cotignola' },
  { label: 'Cotija De La Paz', value: 'Cotija De La Paz' },
  { label: 'Cotillas', value: 'Cotillas' },
  { label: 'Cótimos', value: 'Cótimos' },
  { label: 'Cotiporã', value: 'Cotiporã' },
  { label: 'Cotmeana', value: 'Cotmeana' },
  { label: 'Cotmon', value: 'Cotmon' },
  { label: 'Cotnari', value: 'Cotnari' },
  { label: 'Coto Brus', value: 'Coto Brus' },
  { label: 'Coto De Caza', value: 'Coto De Caza' },
  { label: 'Coto Laurel', value: 'Coto Laurel' },
  { label: 'Coto Norte', value: 'Coto Norte' },
  { label: 'Cotobade', value: 'Cotobade' },
  { label: 'Cotoca', value: 'Cotoca' },
  { label: 'Coţofăneşti', value: 'Coţofăneşti' },
  { label: 'Coţofenii Din Dos', value: 'Coţofenii Din Dos' },
  { label: 'Coțofenii Din Față', value: 'Coțofenii Din Față' },
  { label: 'Cotonou', value: 'Cotonou' },
  { label: 'Cotorina', value: 'Cotorina' },
  { label: 'Cotorinau', value: 'Cotorinau' },
  { label: 'Cotorra', value: 'Cotorra' },
  { label: 'Cotriguaçu', value: 'Cotriguaçu' },
  { label: 'Cotronei', value: 'Cotronei' },
  { label: 'Cotswold Hills', value: 'Cotswold Hills' },
  { label: 'Cottage', value: 'Cottage' },
  { label: 'Cottage City', value: 'Cottage City' },
  { label: 'Cottage Grove', value: 'Cottage Grove' },
  { label: 'Cottage Grove', value: 'Cottage Grove' },
  { label: 'Cottage Grove', value: 'Cottage Grove' },
  { label: 'Cottage Lake', value: 'Cottage Lake' },
  { label: 'Cottanello', value: 'Cottanello' },
  { label: 'Cottbus', value: 'Cottbus' },
  { label: 'Cottenham', value: 'Cottenham' },
  { label: 'Cotterwood', value: 'Cotterwood' },
  { label: 'Cottesloe', value: 'Cottesloe' },
  { label: 'Cottesmore', value: 'Cottesmore' },
  { label: 'Cottingham', value: 'Cottingham' },
  { label: 'Cottle County', value: 'Cottle County' },
  { label: 'Cottleville', value: 'Cottleville' },
  { label: 'Cotton County', value: 'Cotton County' },
  { label: 'Cotton Ground', value: 'Cotton Ground' },
  { label: 'Cottonport', value: 'Cottonport' },
  { label: 'Cottonwood', value: 'Cottonwood' },
  { label: 'Cottonwood', value: 'Cottonwood' },
  { label: 'Cottonwood', value: 'Cottonwood' },
  { label: 'Cottonwood', value: 'Cottonwood' },
  { label: 'Cottonwood County', value: 'Cottonwood County' },
  { label: 'Cottonwood Falls', value: 'Cottonwood Falls' },
  { label: 'Cottonwood Heights', value: 'Cottonwood Heights' },
  { label: 'Cottonwood Shores', value: 'Cottonwood Shores' },
  { label: 'Cotu Vameș', value: 'Cotu Vameș' },
  { label: 'Cotuí', value: 'Cotuí' },
  { label: 'Cotuit', value: 'Cotuit' },
  { label: 'Cotulla', value: 'Cotulla' },
  { label: 'Cotumba', value: 'Cotumba' },
  { label: 'Cotuș', value: 'Cotuș' },
  { label: 'Coţuşca', value: 'Coţuşca' },
  { label: 'Coubaril', value: 'Coubaril' },
  { label: 'Coubert', value: 'Coubert' },
  { label: 'Coublevie', value: 'Coublevie' },
  { label: 'Coubon', value: 'Coubon' },
  { label: 'Coubron', value: 'Coubron' },
  { label: 'Couches', value: 'Couches' },
  { label: 'Couchey', value: 'Couchey' },
  { label: 'Coucy-Le-Château-Auffrique', value: 'Coucy-Le-Château-Auffrique' },
  { label: 'Coudekerque-Branche', value: 'Coudekerque-Branche' },
  { label: 'Coudekerque-Village', value: 'Coudekerque-Village' },
  { label: 'Coudersport', value: 'Coudersport' },
  { label: 'Coudoux', value: 'Coudoux' },
  { label: 'Coudun', value: 'Coudun' },
  { label: 'Couëron', value: 'Couëron' },
  { label: 'Couffé', value: 'Couffé' },
  { label: 'Coufouleux', value: 'Coufouleux' },
  { label: 'Couhé', value: 'Couhé' },
  { label: 'Couilly-Pont-Aux-Dames', value: 'Couilly-Pont-Aux-Dames' },
  { label: 'Couiza', value: 'Couiza' },
  { label: 'Coulaines', value: 'Coulaines' },
  { label: 'Coulanges-Lès-Nevers', value: 'Coulanges-Lès-Nevers' },
  { label: 'Coulans-Sur-Gée', value: 'Coulans-Sur-Gée' },
  { label: 'Coulee Dam', value: 'Coulee Dam' },
  { label: 'Coullons', value: 'Coullons' },
  { label: 'Coulogne', value: 'Coulogne' },
  { label: 'Coulombiers', value: 'Coulombiers' },
  { label: 'Coulombs', value: 'Coulombs' },
  { label: 'Coulommiers', value: 'Coulommiers' },
  { label: 'Coulon', value: 'Coulon' },
  { label: 'Coulonges-Sur-L’Autize', value: 'Coulonges-Sur-L’Autize' },
  { label: 'Coulounieix-Chamiers', value: 'Coulounieix-Chamiers' },
  { label: 'Coulsdon', value: 'Coulsdon' },
  { label: 'Council', value: 'Council' },
  { label: 'Council Bluffs', value: 'Council Bluffs' },
  { label: 'Council Grove', value: 'Council Grove' },
  { label: 'Coundon', value: 'Coundon' },
  { label: 'Countesthorpe', value: 'Countesthorpe' },
  { label: 'Country Club', value: 'Country Club' },
  { label: 'Country Club', value: 'Country Club' },
  { label: 'Country Club Estates', value: 'Country Club Estates' },
  { label: 'Country Club Hills', value: 'Country Club Hills' },
  { label: 'Country Club Hills', value: 'Country Club Hills' },
  { label: 'Country Club Village', value: 'Country Club Village' },
  { label: 'Country Homes', value: 'Country Homes' },
  { label: 'Country Knolls', value: 'Country Knolls' },
  { label: 'Country Lake Estates', value: 'Country Lake Estates' },
  { label: 'Country Park', value: 'Country Park' },
  { label: 'Country Squire Lakes', value: 'Country Squire Lakes' },
  { label: 'Country Walk', value: 'Country Walk' },
  { label: 'Countryside', value: 'Countryside' },
  { label: 'Countryside', value: 'Countryside' },
  { label: 'County Carlow', value: 'County Carlow' },
  { label: 'County Cork', value: 'County Cork' },
  { label: 'County Donegal', value: 'County Donegal' },
  { label: 'County Durham', value: 'County Durham' },
  { label: 'County Galway', value: 'County Galway' },
  { label: 'County Leitrim', value: 'County Leitrim' },
  { label: 'County Monaghan', value: 'County Monaghan' },
  { label: 'County Of Ceredigion', value: 'County Of Ceredigion' },
  { label: 'County Of Flintshire', value: 'County Of Flintshire' },
  { label: 'County Tipperary', value: 'County Tipperary' },
  { label: 'Coupar Angus', value: 'Coupar Angus' },
  { label: 'Coupeville', value: 'Coupeville' },
  { label: 'Coupvray', value: 'Coupvray' },
  { label: 'Courbevoie', value: 'Courbevoie' },
  { label: 'Courcelles', value: 'Courcelles' },
  { label: 'Courcelles-Chaussy', value: 'Courcelles-Chaussy' },
  { label: 'Courcelles-Lès-Lens', value: 'Courcelles-Lès-Lens' },
  { label: 'Courcelles-Lès-Montbéliard', value: 'Courcelles-Lès-Montbéliard' },
  { label: 'Courcelles-Sur-Seine', value: 'Courcelles-Sur-Seine' },
  { label: 'Courchelettes', value: 'Courchelettes' },
  { label: 'Courchevel', value: 'Courchevel' },
  { label: 'Cour-Cheverny', value: 'Cour-Cheverny' },
  { label: 'Courcité', value: 'Courcité' },
  { label: 'Courçon', value: 'Courçon' },
  { label: 'Courcouronnes', value: 'Courcouronnes' },
  { label: 'Courcy', value: 'Courcy' },
  { label: 'Courdimanche', value: 'Courdimanche' },
  { label: 'Courfaivre', value: 'Courfaivre' },
  { label: 'Courgenay', value: 'Courgenay' },
  { label: 'Courgevaux', value: 'Courgevaux' },
  { label: 'Courlay', value: 'Courlay' },
  { label: 'Courlon-Sur-Yonne', value: 'Courlon-Sur-Yonne' },
  { label: 'Courmayeur', value: 'Courmayeur' },
  { label: 'Courmelles', value: 'Courmelles' },
  { label: 'Cournon-D’Auvergne', value: 'Cournon-D’Auvergne' },
  { label: 'Cournonsec', value: 'Cournonsec' },
  { label: 'Cournonterral', value: 'Cournonterral' },
  { label: 'Courpalay', value: 'Courpalay' },
  { label: 'Courpière', value: 'Courpière' },
  { label: 'Courrendlin', value: 'Courrendlin' },
  { label: 'Courrières', value: 'Courrières' },
  { label: 'Courroux', value: 'Courroux' },
  { label: 'Coursac', value: 'Coursac' },
  { label: 'Coursan', value: 'Coursan' },
  { label: 'Cours-De-Pile', value: 'Cours-De-Pile' },
  { label: 'Courseulles-Sur-Mer', value: 'Courseulles-Sur-Mer' },
  { label: 'Cours-La-Ville', value: 'Cours-La-Ville' },
  { label: 'Cours-Les-Barres', value: 'Cours-Les-Barres' },
  { label: 'Court', value: 'Court' },
  { label: 'Courteilles', value: 'Courteilles' },
  { label: 'Courtelary', value: 'Courtelary' },
  { label: 'Courtenay', value: 'Courtenay' },
  { label: 'Courtenay', value: 'Courtenay' },
  { label: 'Courtételle', value: 'Courtételle' },
  { label: 'Courthézon', value: 'Courthézon' },
  { label: 'Courtisols', value: 'Courtisols' },
  { label: 'Courtland', value: 'Courtland' },
  { label: 'Courtown', value: 'Courtown' },
  { label: 'Courtry', value: 'Courtry' },
  { label: 'Court-Saint-Étienne', value: 'Court-Saint-Étienne' },
  { label: 'Courville-Sur-Eure', value: 'Courville-Sur-Eure' },
  { label: 'Courzieu', value: 'Courzieu' },
  { label: 'Cousance', value: 'Cousance' },
  { label: 'Coushatta', value: 'Coushatta' },
  { label: 'Cousins Cove', value: 'Cousins Cove' },
  { label: 'Cousolre', value: 'Cousolre' },
  { label: 'Coussac-Bonneval', value: 'Coussac-Bonneval' },
  { label: 'Coutances', value: 'Coutances' },
  { label: 'Couterne', value: 'Couterne' },
  { label: 'Couternon', value: 'Couternon' },
  { label: 'Coutiches', value: 'Coutiches' },
  { label: 'Couto De Magalhães De Minas', value: 'Couto De Magalhães De Minas' },
  { label: 'Couto Magalhães', value: 'Couto Magalhães' },
  { label: 'Coutouvre', value: 'Coutouvre' },
  { label: 'Coutras', value: 'Coutras' },
  { label: 'Coutts Crossing', value: 'Coutts Crossing' },
  { label: 'Couva', value: 'Couva' },
  { label: 'Couvet', value: 'Couvet' },
  { label: 'Couvin', value: 'Couvin' },
  { label: 'Couvron-Et-Aumencourt', value: 'Couvron-Et-Aumencourt' },
  { label: 'Coux', value: 'Coux' },
  { label: 'Couzeix', value: 'Couzeix' },
  { label: 'Couzon-Au-Mont-D’Or', value: 'Couzon-Au-Mont-D’Or' },
  { label: 'Cova Figueira', value: 'Cova Figueira' },
  { label: 'Covaleda', value: 'Covaleda' },
  { label: 'Covarachía', value: 'Covarachía' },
  { label: 'Covarrubias', value: 'Covarrubias' },
  { label: 'Covăsinţ', value: 'Covăsinţ' },
  { label: 'Covasna', value: 'Covasna' },
  { label: 'Covasna', value: 'Covasna' },
  { label: 'Cove', value: 'Cove' },
  { label: 'Cové', value: 'Cové' },
  { label: 'Cove Creek', value: 'Cove Creek' },
  { label: 'Covedale', value: 'Covedale' },
  { label: 'Covelo', value: 'Covelo' },
  { label: 'Covelo', value: 'Covelo' },
  { label: 'Covelo', value: 'Covelo' },
  { label: 'Coven', value: 'Coven' },
  { label: 'Coveñas', value: 'Coveñas' },
  { label: 'Coventry', value: 'Coventry' },
  { label: 'Coventry', value: 'Coventry' },
  { label: 'Coventry Lake', value: 'Coventry Lake' },
  { label: 'Coves De Vinromà Les', value: 'Coves De Vinromà Les' },
  { label: 'Covilhã', value: 'Covilhã' },
  { label: 'Covina', value: 'Covina' },
  { label: 'Covington', value: 'Covington' },
  { label: 'Covington', value: 'Covington' },
  { label: 'Covington', value: 'Covington' },
  { label: 'Covington', value: 'Covington' },
  { label: 'Covington', value: 'Covington' },
  { label: 'Covington', value: 'Covington' },
  { label: 'Covington', value: 'Covington' },
  { label: 'Covington', value: 'Covington' },
  { label: 'Covington County', value: 'Covington County' },
  { label: 'Covington County', value: 'Covington County' },
  { label: 'Covo', value: 'Covo' },
  { label: 'Covolo-Levada', value: 'Covolo-Levada' },
  { label: 'Covrigi', value: 'Covrigi' },
  { label: 'Cow Bay', value: 'Cow Bay' },
  { label: 'Cowan', value: 'Cowan' },
  { label: 'Cowandilla', value: 'Cowandilla' },
  { label: 'Cowansville', value: 'Cowansville' },
  { label: 'Cowaramup', value: 'Cowaramup' },
  { label: 'Cowarts', value: 'Cowarts' },
  { label: 'Cowbit', value: 'Cowbit' },
  { label: 'Cowbridge', value: 'Cowbridge' },
  { label: 'Cowdenbeath', value: 'Cowdenbeath' },
  { label: 'Cowell', value: 'Cowell' },
  { label: 'Cowes', value: 'Cowes' },
  { label: 'Cowes', value: 'Cowes' },
  { label: 'Coweta', value: 'Coweta' },
  { label: 'Coweta County', value: 'Coweta County' },
  { label: 'Cowfold', value: 'Cowfold' },
  { label: 'Cowichan Bay', value: 'Cowichan Bay' },
  { label: 'Cowie', value: 'Cowie' },
  { label: 'Cowley', value: 'Cowley' },
  { label: 'Cowley County', value: 'Cowley County' },
  { label: 'Cowlitz County', value: 'Cowlitz County' },
  { label: 'Cowpens', value: 'Cowpens' },
  { label: 'Cowplain', value: 'Cowplain' },
  { label: 'Cowra', value: 'Cowra' },
  { label: 'Cox', value: 'Cox' },
  { label: 'Coxcatlán', value: 'Coxcatlán' },
  { label: 'Coxen Hole', value: 'Coxen Hole' },
  { label: 'Coxhoe', value: 'Coxhoe' },
  { label: 'Coxilha', value: 'Coxilha' },
  { label: 'Coxim', value: 'Coxim' },
  { label: 'Coxixola', value: 'Coxixola' },
  { label: 'Coxolico', value: 'Coxolico' },
  { label: 'Coxquihui', value: 'Coxquihui' },
  { label: 'Coxs Bazar', value: 'Coxs Bazar' },
  { label: 'Coxsackie', value: 'Coxsackie' },
  { label: 'Coxswain', value: 'Coxswain' },
  { label: 'Coyah', value: 'Coyah' },
  { label: 'Coyahualco', value: 'Coyahualco' },
  { label: 'Coyaima', value: 'Coyaima' },
  { label: 'Coyame', value: 'Coyame' },
  { label: 'Coyame Del Sotol', value: 'Coyame Del Sotol' },
  { label: 'Coyamel', value: 'Coyamel' },
  { label: 'Coye-La-Forêt', value: 'Coye-La-Forêt' },
  { label: 'Coyhaique', value: 'Coyhaique' },
  { label: 'Coylton', value: 'Coylton' },
  { label: 'Coyoacán', value: 'Coyoacán' },
  { label: 'Coyoles Central', value: 'Coyoles Central' },
  { label: 'Coyolito', value: 'Coyolito' },
  { label: 'Coyomeapan', value: 'Coyomeapan' },
  { label: 'Coyote', value: 'Coyote' },
  { label: 'Coyotepec', value: 'Coyotepec' },
  { label: 'Coyotepec', value: 'Coyotepec' },
  { label: 'Coyotillos', value: 'Coyotillos' },
  { label: 'Coyotillos', value: 'Coyotillos' },
  { label: 'Coyotitán', value: 'Coyotitán' },
  { label: 'Coyuca De Benítez', value: 'Coyuca De Benítez' },
  { label: 'Coyuca De Catalán', value: 'Coyuca De Catalán' },
  { label: 'Coyula', value: 'Coyula' },
  { label: 'Coyuquilla Norte', value: 'Coyuquilla Norte' },
  { label: 'Coyutla', value: 'Coyutla' },
  { label: 'Cozad', value: 'Cozad' },
  { label: 'Cózar', value: 'Cózar' },
  { label: 'Cozes', value: 'Cozes' },
  { label: 'Cozia', value: 'Cozia' },
  { label: 'Cozieni', value: 'Cozieni' },
  { label: 'Cozma', value: 'Cozma' },
  { label: 'Cozmeni', value: 'Cozmeni' },
  { label: 'Cozmeşti', value: 'Cozmeşti' },
  { label: 'Cozmești', value: 'Cozmești' },
  { label: 'Cozotlán Norte', value: 'Cozotlán Norte' },
  { label: 'Cozuelos De Fuentidueña', value: 'Cozuelos De Fuentidueña' },
  { label: 'Cozumel', value: 'Cozumel' },
  { label: 'Cozzana', value: 'Cozzana' },
  { label: 'Cozzo', value: 'Cozzo' },
  { label: 'Crab Orchard', value: 'Crab Orchard' },
  { label: 'Crabtree', value: 'Crabtree' },
  { label: 'Crăcăoani', value: 'Crăcăoani' },
  { label: 'Cracăul Negru', value: 'Cracăul Negru' },
  { label: 'Crace', value: 'Crace' },
  { label: 'Crach', value: 'Crach' },
  { label: 'Crăciunei', value: 'Crăciunei' },
  { label: 'Crăciunelu De Jos', value: 'Crăciunelu De Jos' },
  { label: 'Crăciunești', value: 'Crăciunești' },
  { label: 'Crăciunești', value: 'Crăciunești' },
  { label: 'Craco-Santangelo', value: 'Craco-Santangelo' },
  { label: 'Cradley Heath', value: 'Cradley Heath' },
  { label: 'Cradock', value: 'Cradock' },
  { label: 'Crafers', value: 'Crafers' },
  { label: 'Crafers West', value: 'Crafers West' },
  { label: 'Crafton', value: 'Crafton' },
  { label: 'Craíbas', value: 'Craíbas' },
  { label: 'Craidorolţ', value: 'Craidorolţ' },
  { label: 'Crăiești', value: 'Crăiești' },
  { label: 'Crăiești', value: 'Crăiești' },
  { label: 'Craig', value: 'Craig' },
  { label: 'Craig', value: 'Craig' },
  { label: 'Craig Beach', value: 'Craig Beach' },
  { label: 'Craig County', value: 'Craig County' },
  { label: 'Craig County', value: 'Craig County' },
  { label: 'Craig Head', value: 'Craig Head' },
  { label: 'Craigavon', value: 'Craigavon' },
  { label: 'Craigburn Farm', value: 'Craigburn Farm' },
  { label: 'Craighead County', value: 'Craighead County' },
  { label: 'Craigie', value: 'Craigie' },
  { label: 'Craigieburn', value: 'Craigieburn' },
  { label: 'Craiglie', value: 'Craiglie' },
  { label: 'Craigmore', value: 'Craigmore' },
  { label: 'Craignish', value: 'Craignish' },
  { label: 'Craigsville', value: 'Craigsville' },
  { label: 'Crail', value: 'Crail' },
  { label: 'Crailsheim', value: 'Crailsheim' },
  { label: 'Crainville', value: 'Crainville' },
  { label: 'Craiova', value: 'Craiova' },
  { label: 'Craiter', value: 'Craiter' },
  { label: 'Craiva', value: 'Craiva' },
  { label: 'Cramerton', value: 'Cramerton' },
  { label: 'Cramlington', value: 'Cramlington' },
  { label: 'Crâmpoia', value: 'Crâmpoia' },
  { label: 'Cranberry Township', value: 'Cranberry Township' },
  { label: 'Cranbourne', value: 'Cranbourne' },
  { label: 'Cranbourne East', value: 'Cranbourne East' },
  { label: 'Cranbourne North', value: 'Cranbourne North' },
  { label: 'Cranbourne South', value: 'Cranbourne South' },
  { label: 'Cranbourne West', value: 'Cranbourne West' },
  { label: 'Cranbrook', value: 'Cranbrook' },
  { label: 'Cranbrook', value: 'Cranbrook' },
  { label: 'Cranbrook', value: 'Cranbrook' },
  { label: 'Cranbrook', value: 'Cranbrook' },
  { label: 'Cranbury', value: 'Cranbury' },
  { label: 'Crandall', value: 'Crandall' },
  { label: 'Crandola Valsassina', value: 'Crandola Valsassina' },
  { label: 'Crandon', value: 'Crandon' },
  { label: 'Crandon Lakes', value: 'Crandon Lakes' },
  { label: 'Crane', value: 'Crane' },
  { label: 'Crane', value: 'Crane' },
  { label: 'Crane', value: 'Crane' },
  { label: 'Crane County', value: 'Crane County' },
  { label: 'Cranebrook', value: 'Cranebrook' },
  { label: 'Cranendonck', value: 'Cranendonck' },
  { label: 'Cranfield', value: 'Cranfield' },
  { label: 'Cranford', value: 'Cranford' },
  { label: 'Crângeni', value: 'Crângeni' },
  { label: 'Cran-Gevrier', value: 'Cran-Gevrier' },
  { label: 'Crângu', value: 'Crângu' },
  { label: 'Crângurile De Sus', value: 'Crângurile De Sus' },
  { label: 'Cranham', value: 'Cranham' },
  { label: 'Cranleigh', value: 'Cranleigh' },
  { label: 'Cranley', value: 'Cranley' },
  { label: 'Cransac', value: 'Cransac' },
  { label: 'Cranston', value: 'Cranston' },
  { label: 'Cranves-Sales', value: 'Cranves-Sales' },
  { label: 'Cranwell', value: 'Cranwell' },
  { label: 'Craon', value: 'Craon' },
  { label: 'Craponne', value: 'Craponne' },
  { label: 'Craponne-Sur-Arzon', value: 'Craponne-Sur-Arzon' },
  { label: 'Cras', value: 'Cras' },
  { label: 'Crasna', value: 'Crasna' },
  { label: 'Crasna', value: 'Crasna' },
  { label: 'Crasna Vișeului', value: 'Crasna Vișeului' },
  { label: 'Crasnoe', value: 'Crasnoe' },
  { label: 'Crateús', value: 'Crateús' },
  { label: 'Crato', value: 'Crato' },
  { label: 'Crato', value: 'Crato' },
  { label: 'Crauthem', value: 'Crauthem' },
  { label: 'Crava', value: 'Crava' },
  { label: 'Cravagliana', value: 'Cravagliana' },
  { label: 'Cravanche', value: 'Cravanche' },
  { label: 'Cravanzana', value: 'Cravanzana' },
  { label: 'Craveggia', value: 'Craveggia' },
  { label: 'Craven Arms', value: 'Craven Arms' },
  { label: 'Craven County', value: 'Craven County' },
  { label: 'Cravinhos', value: 'Cravinhos' },
  { label: 'Cravo Norte', value: 'Cravo Norte' },
  { label: 'Cravolândia', value: 'Cravolândia' },
  { label: 'Crawford', value: 'Crawford' },
  { label: 'Crawford County', value: 'Crawford County' },
  { label: 'Crawford County', value: 'Crawford County' },
  { label: 'Crawford County', value: 'Crawford County' },
  { label: 'Crawford County', value: 'Crawford County' },
  { label: 'Crawford County', value: 'Crawford County' },
  { label: 'Crawford County', value: 'Crawford County' },
  { label: 'Crawford County', value: 'Crawford County' },
  { label: 'Crawford County', value: 'Crawford County' },
  { label: 'Crawford County', value: 'Crawford County' },
  { label: 'Crawford County', value: 'Crawford County' },
  { label: 'Crawford County', value: 'Crawford County' },
  { label: 'Crawfordsville', value: 'Crawfordsville' },
  { label: 'Crawfordville', value: 'Crawfordville' },
  { label: 'Crawfordville', value: 'Crawfordville' },
  { label: 'Crawinkel', value: 'Crawinkel' },
  { label: 'Crawley', value: 'Crawley' },
  { label: 'Crawley', value: 'Crawley' },
  { label: 'Crawley Down', value: 'Crawley Down' },
  { label: 'Creaca', value: 'Creaca' },
  { label: 'Créances', value: 'Créances' },
  { label: 'Creazzo', value: 'Creazzo' },
  { label: 'Crecchio', value: 'Crecchio' },
  { label: 'Crecente', value: 'Crecente' },
  { label: 'Crêches-Sur-Saône', value: 'Crêches-Sur-Saône' },
  { label: 'Crécy-En-Ponthieu', value: 'Crécy-En-Ponthieu' },
  { label: 'Crécy-Sur-Serre', value: 'Crécy-Sur-Serre' },
  { label: 'Credaro', value: 'Credaro' },
  { label: 'Credenhill', value: 'Credenhill' },
  { label: 'Credera', value: 'Credera' },
  { label: 'Crédin', value: 'Crédin' },
  { label: 'Crediton', value: 'Crediton' },
  { label: 'Creech Saint Michael', value: 'Creech Saint Michael' },
  { label: 'Creede', value: 'Creede' },
  { label: 'Creedmoor', value: 'Creedmoor' },
  { label: 'Creek County', value: 'Creek County' },
  { label: 'Creel', value: 'Creel' },
  { label: 'Creglingen', value: 'Creglingen' },
  { label: 'Crégy-Lès-Meaux', value: 'Crégy-Lès-Meaux' },
  { label: 'Créhange', value: 'Créhange' },
  { label: 'Créhen', value: 'Créhen' },
  { label: 'Creighton', value: 'Creighton' },
  { label: 'Creil', value: 'Creil' },
  { label: 'Creissan', value: 'Creissan' },
  { label: 'Creissels', value: 'Creissels' },
  { label: 'Creixell', value: 'Creixell' },
  { label: 'Crema', value: 'Crema' },
  { label: 'Cremella', value: 'Cremella' },
  { label: 'Cremenaga', value: 'Cremenaga' },
  { label: 'Crémenes', value: 'Crémenes' },
  { label: 'Cremeno', value: 'Cremeno' },
  { label: 'Cremia', value: 'Cremia' },
  { label: 'Crémieu', value: 'Crémieu' },
  { label: 'Cremlingen', value: 'Cremlingen' },
  { label: 'Cremolino', value: 'Cremolino' },
  { label: 'Cremona', value: 'Cremona' },
  { label: 'Cremorne', value: 'Cremorne' },
  { label: 'Cremorne', value: 'Cremorne' },
  { label: 'Cremorne Point', value: 'Cremorne Point' },
  { label: 'Cremosano', value: 'Cremosano' },
  { label: 'Creney-Près-Troyes', value: 'Creney-Près-Troyes' },
  { label: 'Crenshaw County', value: 'Crenshaw County' },
  { label: 'Črenšovci', value: 'Črenšovci' },
  { label: 'Creola', value: 'Creola' },
  { label: 'Créon', value: 'Créon' },
  { label: 'Crepaja', value: 'Crepaja' },
  { label: 'Crepaldo', value: 'Crepaldo' },
  { label: 'Crépy', value: 'Crépy' },
  { label: 'Crépy-En-Valois', value: 'Crépy-En-Valois' },
  { label: 'Cres', value: 'Cres' },
  { label: 'Cresaptown', value: 'Cresaptown' },
  { label: 'Crescencio Morales (San Mateo)', value: 'Crescencio Morales (San Mateo)' },
  { label: 'Crescent', value: 'Crescent' },
  { label: 'Crescent', value: 'Crescent' },
  { label: 'Crescent City', value: 'Crescent City' },
  { label: 'Crescent City', value: 'Crescent City' },
  { label: 'Crescent Head', value: 'Crescent Head' },
  { label: 'Crescent Springs', value: 'Crescent Springs' },
  { label: 'Crescentino', value: 'Crescentino' },
  { label: 'Cresco', value: 'Cresco' },
  { label: 'Cresevo', value: 'Cresevo' },
  { label: 'Crespadoro', value: 'Crespadoro' },
  { label: 'Crespano Del Grappa', value: 'Crespano Del Grappa' },
  { label: 'Crespellano', value: 'Crespellano' },
  { label: 'Crespià', value: 'Crespià' },
  { label: 'Crespiatica', value: 'Crespiatica' },
  { label: 'Crespières', value: 'Crespières' },
  { label: 'Crespin', value: 'Crespin' },
  { label: 'Crespina', value: 'Crespina' },
  { label: 'Crespino', value: 'Crespino' },
  { label: 'Crespo', value: 'Crespo' },
  { label: 'Crespos', value: 'Crespos' },
  { label: 'Cressa', value: 'Cressa' },
  { label: 'Cresserons', value: 'Cresserons' },
  { label: 'Cresskill', value: 'Cresskill' },
  { label: 'Cresslands', value: 'Cresslands' },
  { label: 'Cresson', value: 'Cresson' },
  { label: 'Cressona', value: 'Cressona' },
  { label: 'Cressy', value: 'Cressy' },
  { label: 'Crest', value: 'Crest' },
  { label: 'Crest', value: 'Crest' },
  { label: 'Crest Hill', value: 'Crest Hill' },
  { label: 'Crested Butte', value: 'Crested Butte' },
  { label: 'Crestline', value: 'Crestline' },
  { label: 'Crestline', value: 'Crestline' },
  { label: 'Crestmead', value: 'Crestmead' },
  { label: 'Creston', value: 'Creston' },
  { label: 'Creston', value: 'Creston' },
  { label: 'Creston', value: 'Creston' },
  { label: 'Crestview', value: 'Crestview' },
  { label: 'Crestview Hills', value: 'Crestview Hills' },
  { label: 'Crestwood', value: 'Crestwood' },
  { label: 'Crestwood', value: 'Crestwood' },
  { label: 'Crestwood', value: 'Crestwood' },
  { label: 'Crestwood', value: 'Crestwood' },
  { label: 'Crestwood Village', value: 'Crestwood Village' },
  { label: 'Creswell', value: 'Creswell' },
  { label: 'Creswell', value: 'Creswell' },
  { label: 'Creswick', value: 'Creswick' },
  { label: 'Cretas', value: 'Cretas' },
  { label: 'Crete', value: 'Crete' },
  { label: 'Crete', value: 'Crete' },
  { label: 'Créteil', value: 'Créteil' },
  { label: 'Creţeni', value: 'Creţeni' },
  { label: 'Crețești', value: 'Crețești' },
  { label: 'Crețești', value: 'Crețești' },
  { label: 'Creto', value: 'Creto' },
  { label: 'Cretone', value: 'Cretone' },
  { label: 'Creully', value: 'Creully' },
  { label: 'Creuse', value: 'Creuse' },
  { label: 'Creußen', value: 'Creußen' },
  { label: 'Creutzwald', value: 'Creutzwald' },
  { label: 'Creuzburg', value: 'Creuzburg' },
  { label: 'Creuzier-Le-Vieux', value: 'Creuzier-Le-Vieux' },
  { label: 'Crevacuore', value: 'Crevacuore' },
  { label: 'Crevalcore', value: 'Crevalcore' },
  { label: 'Creve Coeur', value: 'Creve Coeur' },
  { label: 'Creve Coeur', value: 'Creve Coeur' },
  { label: 'Crève Coeur', value: 'Crève Coeur' },
  { label: 'Crèvecœur-Le-Grand', value: 'Crèvecœur-Le-Grand' },
  { label: 'Crevedia', value: 'Crevedia' },
  { label: 'Crevedia Mare', value: 'Crevedia Mare' },
  { label: 'Crevenicu', value: 'Crevenicu' },
  { label: 'Crevillent', value: 'Crevillent' },
  { label: 'Crevillente', value: 'Crevillente' },
  { label: 'Crevin', value: 'Crevin' },
  { label: 'Crevoladossola', value: 'Crevoladossola' },
  { label: 'Crewe', value: 'Crewe' },
  { label: 'Crewe', value: 'Crewe' },
  { label: 'Crewkerne', value: 'Crewkerne' },
  { label: 'Creys-Mépieu', value: 'Creys-Mépieu' },
  { label: 'Creysse', value: 'Creysse' },
  { label: 'Crézancy', value: 'Crézancy' },
  { label: 'Crib Point', value: 'Crib Point' },
  { label: 'Cricău', value: 'Cricău' },
  { label: 'Criccieth', value: 'Criccieth' },
  { label: 'Crichi', value: 'Crichi' },
  { label: 'Criciova', value: 'Criciova' },
  { label: 'Criciúma', value: 'Criciúma' },
  { label: 'Crick', value: 'Crick' },
  { label: 'Cricket', value: 'Cricket' },
  { label: 'Crickhowell', value: 'Crickhowell' },
  { label: 'Cricklade', value: 'Cricklade' },
  { label: 'Cricova', value: 'Cricova' },
  { label: 'Cridersville', value: 'Cridersville' },
  { label: 'Crieff', value: 'Crieff' },
  { label: 'Criel-Sur-Mer', value: 'Criel-Sur-Mer' },
  { label: 'Crikvenica', value: 'Crikvenica' },
  { label: 'Crimmitschau', value: 'Crimmitschau' },
  { label: 'Crimora', value: 'Crimora' },
  { label: 'Cringila', value: 'Cringila' },
  { label: 'Cringleford', value: 'Cringleford' },
  { label: 'Crinitz', value: 'Crinitz' },
  { label: 'Cripple Creek', value: 'Cripple Creek' },
  { label: 'Criquebeuf-Sur-Seine', value: 'Criquebeuf-Sur-Seine' },
  { label: 'Criquetot-L’Esneval', value: 'Criquetot-L’Esneval' },
  { label: 'Criș', value: 'Criș' },
  { label: 'Crişan', value: 'Crişan' },
  { label: 'Crişcior', value: 'Crişcior' },
  { label: 'Crişeni', value: 'Crişeni' },
  { label: 'Crisfield', value: 'Crisfield' },
  { label: 'Crisnée', value: 'Crisnée' },
  { label: 'Crisólita', value: 'Crisólita' },
  { label: 'Crisolles', value: 'Crisolles' },
  { label: 'Crisópolis', value: 'Crisópolis' },
  { label: 'Crisóstomos', value: 'Crisóstomos' },
  { label: 'Crisp County', value: 'Crisp County' },
  { label: 'Crispano', value: 'Crispano' },
  { label: 'Crispi Cavour', value: 'Crispi Cavour' },
  { label: 'Crispiano', value: 'Crispiano' },
  { label: 'Crissey', value: 'Crissey' },
  { label: 'Crissier', value: 'Crissier' },
  { label: 'Crissiumal', value: 'Crissiumal' },
  { label: 'Crissolo', value: 'Crissolo' },
  { label: 'Cristais', value: 'Cristais' },
  { label: 'Cristais Paulista', value: 'Cristais Paulista' },
  { label: 'Cristal', value: 'Cristal' },
  { label: 'Cristal Do Sul', value: 'Cristal Do Sul' },
  { label: 'Cristalândia', value: 'Cristalândia' },
  { label: 'Cristalândia Do Piauí', value: 'Cristalândia Do Piauí' },
  { label: 'Cristália', value: 'Cristália' },
  { label: 'Cristalina', value: 'Cristalina' },
  { label: 'Cristelo', value: 'Cristelo' },
  { label: 'Cristeros Fraccionamiento', value: 'Cristeros Fraccionamiento' },
  { label: 'Cristeşti', value: 'Cristeşti' },
  { label: 'Cristeşti', value: 'Cristeşti' },
  { label: 'Cristești', value: 'Cristești' },
  { label: 'Cristeștii Ciceului', value: 'Cristeștii Ciceului' },
  { label: 'Cristian', value: 'Cristian' },
  { label: 'Cristian', value: 'Cristian' },
  { label: 'Cristiano Otoni', value: 'Cristiano Otoni' },
  { label: 'Cristianópolis', value: 'Cristianópolis' },
  { label: 'Cristina', value: 'Cristina' },
  { label: 'Cristina', value: 'Cristina' },
  { label: 'Cristinápolis', value: 'Cristinápolis' },
  { label: 'Cristineşti', value: 'Cristineşti' },
  { label: 'Cristino Castro', value: 'Cristino Castro' },
  { label: 'Criştioru De Jos', value: 'Criştioru De Jos' },
  { label: 'Cristo Rei', value: 'Cristo Rei' },
  { label: 'Cristo Rey', value: 'Cristo Rey' },
  { label: 'Cristo Rey', value: 'Cristo Rey' },
  { label: 'Cristóbal', value: 'Cristóbal' },
  { label: 'Cristóbal', value: 'Cristóbal' },
  { label: 'Cristóbal', value: 'Cristóbal' },
  { label: 'Cristóbal Colón', value: 'Cristóbal Colón' },
  { label: 'Cristóbal Obregón', value: 'Cristóbal Obregón' },
  { label: 'Cristolţ', value: 'Cristolţ' },
  { label: 'Cristópolis', value: 'Cristópolis' },
  { label: 'Cristur', value: 'Cristur' },
  { label: 'Cristuru Secuiesc', value: 'Cristuru Secuiesc' },
  { label: 'Crittenden', value: 'Crittenden' },
  { label: 'Crittenden County', value: 'Crittenden County' },
  { label: 'Crittenden County', value: 'Crittenden County' },
  { label: 'Criuleni', value: 'Criuleni' },
  { label: 'Crivăț', value: 'Crivăț' },
  { label: 'Crivești', value: 'Crivești' },
  { label: 'Crivillén', value: 'Crivillén' },
  { label: 'Crivina', value: 'Crivina' },
  { label: 'Crivina', value: 'Crivina' },
  { label: 'Crivitz', value: 'Crivitz' },
  { label: 'Crixás', value: 'Crixás' },
  { label: 'Crixás Do Tocantins', value: 'Crixás Do Tocantins' },
  { label: 'Crizbav', value: 'Crizbav' },
  { label: 'Crna Bara', value: 'Crna Bara' },
  { label: 'Črna Na Koroškem', value: 'Črna Na Koroškem' },
  { label: 'Crnac', value: 'Crnac' },
  { label: 'Crnići', value: 'Crnići' },
  { label: 'Crnilište', value: 'Crnilište' },
  { label: 'Črnomelj', value: 'Črnomelj' },
  { label: 'Črnuče District', value: 'Črnuče District' },
  { label: 'Croatá', value: 'Croatá' },
  { label: 'Crocefieschi', value: 'Crocefieschi' },
  { label: 'Crocetta Del Montello', value: 'Crocetta Del Montello' },
  { label: 'Crocetta-Nogarè', value: 'Crocetta-Nogarè' },
  { label: 'Crocker', value: 'Crocker' },
  { label: 'Crocker', value: 'Crocker' },
  { label: 'Crockett', value: 'Crockett' },
  { label: 'Crockett', value: 'Crockett' },
  { label: 'Crockett County', value: 'Crockett County' },
  { label: 'Crockett County', value: 'Crockett County' },
  { label: 'Crodo', value: 'Crodo' },
  { label: 'Croeserw', value: 'Croeserw' },
  { label: 'Croft', value: 'Croft' },
  { label: 'Crofton', value: 'Crofton' },
  { label: 'Crofton', value: 'Crofton' },
  { label: 'Crofts Hill', value: 'Crofts Hill' },
  { label: 'Crofty', value: 'Crofty' },
  { label: 'Crognaleto', value: 'Crognaleto' },
  { label: 'Croisilles', value: 'Croisilles' },
  { label: 'Croissy-Beaubourg', value: 'Croissy-Beaubourg' },
  { label: 'Croissy-Sur-Seine', value: 'Croissy-Sur-Seine' },
  { label: 'Croitori', value: 'Croitori' },
  { label: 'Croix', value: 'Croix' },
  { label: 'Croix-Des-Bouquets', value: 'Croix-Des-Bouquets' },
  { label: 'Crolles', value: 'Crolles' },
  { label: 'Cromarty', value: 'Cromarty' },
  { label: 'Cromer', value: 'Cromer' },
  { label: 'Cromer', value: 'Cromer' },
  { label: 'Cromford', value: 'Cromford' },
  { label: 'Cromínia', value: 'Cromínia' },
  { label: 'Crompond', value: 'Crompond' },
  { label: 'Cromwell', value: 'Cromwell' },
  { label: 'Cromwell', value: 'Cromwell' },
  { label: 'Crondall', value: 'Crondall' },
  { label: 'Crone', value: 'Crone' },
  { label: 'Cronulla', value: 'Cronulla' },
  { label: 'Crook', value: 'Crook' },
  { label: 'Crook County', value: 'Crook County' },
  { label: 'Crook County', value: 'Crook County' },
  { label: 'Crooked Lake Park', value: 'Crooked Lake Park' },
  { label: 'Crooked River', value: 'Crooked River' },
  { label: 'Crooks', value: 'Crooks' },
  { label: 'Crookston', value: 'Crookston' },
  { label: 'Crooksville', value: 'Crooksville' },
  { label: 'Crookwell', value: 'Crookwell' },
  { label: 'Croom', value: 'Croom' },
  { label: 'Croom', value: 'Croom' },
  { label: 'Cropalati', value: 'Cropalati' },
  { label: 'Cropani', value: 'Cropani' },
  { label: 'Cropani Marina', value: 'Cropani Marina' },
  { label: 'Cropwell Bishop', value: 'Cropwell Bishop' },
  { label: 'Crosby', value: 'Crosby' },
  { label: 'Crosby', value: 'Crosby' },
  { label: 'Crosby', value: 'Crosby' },
  { label: 'Crosby', value: 'Crosby' },
  { label: 'Crosby County', value: 'Crosby County' },
  { label: 'Crosbyton', value: 'Crosbyton' },
  { label: 'Crosia', value: 'Crosia' },
  { label: 'Crosio Della Valle', value: 'Crosio Della Valle' },
  { label: 'Crosne', value: 'Crosne' },
  { label: 'Cross City', value: 'Cross City' },
  { label: 'Cross County', value: 'Cross County' },
  { label: 'Cross Hands', value: 'Cross Hands' },
  { label: 'Cross Hills', value: 'Cross Hills' },
  { label: 'Cross Keys', value: 'Cross Keys' },
  { label: 'Cross Lake', value: 'Cross Lake' },
  { label: 'Cross Lanes', value: 'Cross Lanes' },
  { label: 'Cross Mountain', value: 'Cross Mountain' },
  { label: 'Cross Plains', value: 'Cross Plains' },
  { label: 'Cross Plains', value: 'Cross Plains' },
  { label: 'Cross Roads', value: 'Cross Roads' },
  { label: 'Crossac', value: 'Crossac' },
  { label: 'Crossett', value: 'Crossett' },
  { label: 'Crossfield', value: 'Crossfield' },
  { label: 'Crossford', value: 'Crossford' },
  { label: 'Crossgar', value: 'Crossgar' },
  { label: 'Crossgates', value: 'Crossgates' },
  { label: 'Crosshaven', value: 'Crosshaven' },
  { label: 'Crosshouse', value: 'Crosshouse' },
  { label: 'Crossmaglen', value: 'Crossmaglen' },
  { label: 'Crossmolina', value: 'Crossmolina' },
  { label: 'Crossville', value: 'Crossville' },
  { label: 'Crossville', value: 'Crossville' },
  { label: 'Crostau', value: 'Crostau' },
  { label: 'Croston', value: 'Croston' },
  { label: 'Crostwitz', value: 'Crostwitz' },
  { label: 'Croswell', value: 'Croswell' },
  { label: 'Croth', value: 'Croth' },
  { label: 'Crothersville', value: 'Crothersville' },
  { label: 'Crotone', value: 'Crotone' },
  { label: 'Croton-On-Hudson', value: 'Croton-On-Hudson' },
  { label: 'Crotta Dadda', value: 'Crotta Dadda' },
  { label: 'Crottendorf', value: 'Crottendorf' },
  { label: 'Crottet', value: 'Crottet' },
  { label: 'Crouch End', value: 'Crouch End' },
  { label: 'Crouy', value: 'Crouy' },
  { label: 'Crouy-En-Thelle', value: 'Crouy-En-Thelle' },
  { label: 'Crouy-Sur-Ourcq', value: 'Crouy-Sur-Ourcq' },
  { label: 'Crova', value: 'Crova' },
  { label: 'Croviana', value: 'Croviana' },
  { label: 'Crovu', value: 'Crovu' },
  { label: 'Crow Agency', value: 'Crow Agency' },
  { label: 'Crow Wing County', value: 'Crow Wing County' },
  { label: 'Crowborough', value: 'Crowborough' },
  { label: 'Crowell', value: 'Crowell' },
  { label: 'Crowland', value: 'Crowland' },
  { label: 'Crowle', value: 'Crowle' },
  { label: 'Crowley', value: 'Crowley' },
  { label: 'Crowley', value: 'Crowley' },
  { label: 'Crowley County', value: 'Crowley County' },
  { label: 'Crown Heights', value: 'Crown Heights' },
  { label: 'Crown Point', value: 'Crown Point' },
  { label: 'Crownlands/Marc', value: 'Crownlands/Marc' },
  { label: 'Crownpoint', value: 'Crownpoint' },
  { label: 'Crownsville', value: 'Crownsville' },
  { label: 'Crows Nest', value: 'Crows Nest' },
  { label: 'Crows Nest', value: 'Crows Nest' },
  { label: 'Crowthorne', value: 'Crowthorne' },
  { label: 'Croy', value: 'Croy' },
  { label: 'Croydon', value: 'Croydon' },
  { label: 'Croydon', value: 'Croydon' },
  { label: 'Croydon', value: 'Croydon' },
  { label: 'Croydon', value: 'Croydon' },
  { label: 'Croydon', value: 'Croydon' },
  { label: 'Croydon Hills', value: 'Croydon Hills' },
  { label: 'Croydon North', value: 'Croydon North' },
  { label: 'Croydon Park', value: 'Croydon Park' },
  { label: 'Croydon South', value: 'Croydon South' },
  { label: 'Crozet', value: 'Crozet' },
  { label: 'Crozon', value: 'Crozon' },
  { label: 'Cruas', value: 'Cruas' },
  { label: 'Cruce De Arinaga', value: 'Cruce De Arinaga' },
  { label: 'Crucea', value: 'Crucea' },
  { label: 'Crucea', value: 'Crucea' },
  { label: 'Crucea', value: 'Crucea' },
  { label: 'Crucecita', value: 'Crucecita' },
  { label: 'Crucero Las Pilas', value: 'Crucero Las Pilas' },
  { label: 'Cruces', value: 'Cruces' },
  { label: 'Crucilândia', value: 'Crucilândia' },
  { label: 'Crucişor', value: 'Crucişor' },
  { label: 'Crucoli', value: 'Crucoli' },
  { label: 'Cruden Bay', value: 'Cruden Bay' },
  { label: 'Cruet', value: 'Cruet' },
  { label: 'Crugers', value: 'Crugers' },
  { label: 'Crugnola', value: 'Crugnola' },
  { label: 'Cruillas', value: 'Cruillas' },
  { label: 'Cruïlles  Monells I Sant Sadurní De Lheura', value: 'Cruïlles  Monells I Sant Sadurní De Lheura' },
  { label: 'Crumlin', value: 'Crumlin' },
  { label: 'Crumlin', value: 'Crumlin' },
  { label: 'Crumlin', value: 'Crumlin' },
  { label: 'Crump', value: 'Crump' },
  { label: 'Crumpsall', value: 'Crumpsall' },
  { label: 'Cruquius', value: 'Cruquius' },
  { label: 'Cruseilles', value: 'Cruseilles' },
  { label: 'Crușeț', value: 'Crușeț' },
  { label: 'Crusnes', value: 'Crusnes' },
  { label: 'Crușovu', value: 'Crușovu' },
  { label: 'Cruz', value: 'Cruz' },
  { label: 'Cruz Alta', value: 'Cruz Alta' },
  { label: 'Cruz Alta', value: 'Cruz Alta' },
  { label: 'Cruz Bay', value: 'Cruz Bay' },
  { label: 'Cruz Blanca', value: 'Cruz Blanca' },
  { label: 'Cruz Blanca', value: 'Cruz Blanca' },
  { label: 'Cruz Das Almas', value: 'Cruz Das Almas' },
  { label: 'Cruz De Huanacaxtle', value: 'Cruz De Huanacaxtle' },
  { label: 'Cruz De Los Milagros', value: 'Cruz De Los Milagros' },
  { label: 'Cruz Del Eje', value: 'Cruz Del Eje' },
  { label: 'Cruz Del Milagro', value: 'Cruz Del Milagro' },
  { label: 'Cruz Do Espírito Santo', value: 'Cruz Do Espírito Santo' },
  { label: 'Cruz Grande', value: 'Cruz Grande' },
  { label: 'Cruz Machado', value: 'Cruz Machado' },
  { label: 'Cruz Quebrada - Dafundo', value: 'Cruz Quebrada - Dafundo' },
  { label: 'Cruz Quemada', value: 'Cruz Quemada' },
  { label: 'Cruz Verde', value: 'Cruz Verde' },
  { label: 'Cruzália', value: 'Cruzália' },
  { label: 'Cruzaltense', value: 'Cruzaltense' },
  { label: 'Cruzeiro', value: 'Cruzeiro' },
  { label: 'Cruzeiro Da Fortaleza', value: 'Cruzeiro Da Fortaleza' },
  { label: 'Cruzeiro Do Iguaçu', value: 'Cruzeiro Do Iguaçu' },
  { label: 'Cruzeiro Do Oeste', value: 'Cruzeiro Do Oeste' },
  { label: 'Cruzeiro Do Sul', value: 'Cruzeiro Do Sul' },
  { label: 'Cruzeiro Do Sul', value: 'Cruzeiro Do Sul' },
  { label: 'Cruzeiro Do Sul', value: 'Cruzeiro Do Sul' },
  { label: 'Cruzeta', value: 'Cruzeta' },
  { label: 'Cruzília', value: 'Cruzília' },
  { label: 'Cruzmaltina', value: 'Cruzmaltina' },
  { label: 'Cruztón', value: 'Cruztón' },
  { label: 'Crymych', value: 'Crymych' },
  { label: 'Crynant', value: 'Crynant' },
  { label: 'Crystal', value: 'Crystal' },
  { label: 'Crystal Brook', value: 'Crystal Brook' },
  { label: 'Crystal City', value: 'Crystal City' },
  { label: 'Crystal City', value: 'Crystal City' },
  { label: 'Crystal Falls', value: 'Crystal Falls' },
  { label: 'Crystal Lake', value: 'Crystal Lake' },
  { label: 'Crystal Lake', value: 'Crystal Lake' },
  { label: 'Crystal Lake', value: 'Crystal Lake' },
  { label: 'Crystal Lakes', value: 'Crystal Lakes' },
  { label: 'Crystal Lawns', value: 'Crystal Lawns' },
  { label: 'Crystal River', value: 'Crystal River' },
  { label: 'Crystal Springs', value: 'Crystal Springs' },
  { label: 'Crystal Springs', value: 'Crystal Springs' },
  { label: 'Csabrendek', value: 'Csabrendek' },
  { label: 'Csákvár', value: 'Csákvár' },
  { label: 'Csanádapáca', value: 'Csanádapáca' },
  { label: 'Csanádpalota', value: 'Csanádpalota' },
  { label: 'Csány', value: 'Csány' },
  { label: 'Csanytelek', value: 'Csanytelek' },
  { label: 'Császár', value: 'Császár' },
  { label: 'Császártöltés', value: 'Császártöltés' },
  { label: 'Csatka', value: 'Csatka' },
  { label: 'Csávoly', value: 'Csávoly' },
  { label: 'Csém', value: 'Csém' },
  { label: 'Csemő', value: 'Csemő' },
  { label: 'Csengele', value: 'Csengele' },
  { label: 'Csenger', value: 'Csenger' },
  { label: 'Csengeri Járás', value: 'Csengeri Járás' },
  { label: 'Csengőd', value: 'Csengőd' },
  { label: 'Csép', value: 'Csép' },
  { label: 'Csepreg', value: 'Csepreg' },
  { label: 'Cserkeszőlő', value: 'Cserkeszőlő' },
  { label: 'Cserszegtomaj', value: 'Cserszegtomaj' },
  { label: 'Csetény', value: 'Csetény' },
  { label: 'Csobánka', value: 'Csobánka' },
  { label: 'Csökmő', value: 'Csökmő' },
  { label: 'Csolnok', value: 'Csolnok' },
  { label: 'Csömör', value: 'Csömör' },
  { label: 'Csongrád', value: 'Csongrád' },
  { label: 'Csongrádi Járás', value: 'Csongrádi Járás' },
  { label: 'Csopak', value: 'Csopak' },
  { label: 'Csorna', value: 'Csorna' },
  { label: 'Csornai Járás', value: 'Csornai Járás' },
  { label: 'Csorvás', value: 'Csorvás' },
  { label: 'Csurgó', value: 'Csurgó' },
  { label: 'Csurgói Járás', value: 'Csurgói Járás' },
  { label: 'Củ Chi', value: 'Củ Chi' },
  { label: 'Cúa', value: 'Cúa' },
  { label: 'Cuacnopalan', value: 'Cuacnopalan' },
  { label: 'Cuacos De Yuste', value: 'Cuacos De Yuste' },
  { label: 'Cuacuila', value: 'Cuacuila' },
  { label: 'Cuadrilla De Dolores', value: 'Cuadrilla De Dolores' },
  { label: 'Cuadrilla Vieja', value: 'Cuadrilla Vieja' },
  { label: 'Cuadros', value: 'Cuadros' },
  { label: 'Cuajinicuilapa', value: 'Cuajinicuilapa' },
  { label: 'Cualác', value: 'Cualác' },
  { label: 'Cualedro', value: 'Cualedro' },
  { label: 'Cuamba', value: 'Cuamba' },
  { label: 'Cuambog', value: 'Cuambog' },
  { label: 'Cuambog', value: 'Cuambog' },
  { label: 'Cuambog', value: 'Cuambog' },
  { label: 'Cuamiles', value: 'Cuamiles' },
  { label: 'Cuamio', value: 'Cuamio' },
  { label: 'Cuanacaxtitlán', value: 'Cuanacaxtitlán' },
  { label: 'Cuanajo', value: 'Cuanajo' },
  { label: 'Cuanalá', value: 'Cuanalá' },
  { label: 'Cuapa', value: 'Cuapa' },
  { label: 'Cuapaxtitla', value: 'Cuapaxtitla' },
  { label: 'Cuapiaxtla', value: 'Cuapiaxtla' },
  { label: 'Cuapiaxtla De Madero', value: 'Cuapiaxtla De Madero' },
  { label: 'Cuapinolito (Azulillo)', value: 'Cuapinolito (Azulillo)' },
  { label: 'Cuaracurío', value: 'Cuaracurío' },
  { label: 'Cuarnens', value: 'Cuarnens' },
  { label: 'Cuarta Brigada', value: 'Cuarta Brigada' },
  { label: 'Cuarte De Huerva', value: 'Cuarte De Huerva' },
  { label: 'Cuartel La Mesa (El Asoleadero)', value: 'Cuartel La Mesa (El Asoleadero)' },
  { label: 'Cuartero', value: 'Cuartero' },
  { label: 'Cuaspud', value: 'Cuaspud' },
  { label: 'Cuasso Al Monte', value: 'Cuasso Al Monte' },
  { label: 'Cuasso Al Piano', value: 'Cuasso Al Piano' },
  { label: 'Cuatolol', value: 'Cuatolol' },
  { label: 'Cuatro Caminos', value: 'Cuatro Caminos' },
  { label: 'Cuatro Caminos', value: 'Cuatro Caminos' },
  { label: 'Cuatro Ciénegas De Carranza', value: 'Cuatro Ciénegas De Carranza' },
  { label: 'Cuatzoquitengo', value: 'Cuatzoquitengo' },
  { label: 'Cuauchichinola', value: 'Cuauchichinola' },
  { label: 'Cuauhtamazaco', value: 'Cuauhtamazaco' },
  { label: 'Cuauhtamingo', value: 'Cuauhtamingo' },
  { label: 'Cuauhtemoc', value: 'Cuauhtemoc' },
  { label: 'Cuauhtémoc', value: 'Cuauhtémoc' },
  { label: 'Cuauhtémoc', value: 'Cuauhtémoc' },
  { label: 'Cuauhtémoc', value: 'Cuauhtémoc' },
  { label: 'Cuauhtémoc', value: 'Cuauhtémoc' },
  { label: 'Cuauhtémoc', value: 'Cuauhtémoc' },
  { label: 'Cuauhtémoc', value: 'Cuauhtémoc' },
  { label: 'Cuauhtémoc', value: 'Cuauhtémoc' },
  { label: 'Cuauhtémoc', value: 'Cuauhtémoc' },
  { label: 'Cuauhtémoc (Campo Cinco)', value: 'Cuauhtémoc (Campo Cinco)' },
  { label: 'Cuauhtempan (San Andrés Cuauhtempan)', value: 'Cuauhtempan (San Andrés Cuauhtempan)' },
  { label: 'Cuauhtenango', value: 'Cuauhtenango' },
  { label: 'Cuautempan', value: 'Cuautempan' },
  { label: 'Cuautepec', value: 'Cuautepec' },
  { label: 'Cuautepec De Hinojosa', value: 'Cuautepec De Hinojosa' },
  { label: 'Cuautinchán', value: 'Cuautinchán' },
  { label: 'Cuautitlán', value: 'Cuautitlán' },
  { label: 'Cuautitlán', value: 'Cuautitlán' },
  { label: 'Cuautitlán De García Barragán', value: 'Cuautitlán De García Barragán' },
  { label: 'Cuautla', value: 'Cuautla' },
  { label: 'Cuautla', value: 'Cuautla' },
  { label: 'Cuautlacingo', value: 'Cuautlacingo' },
  { label: 'Cuautlancingo', value: 'Cuautlancingo' },
  { label: 'Cuautlapán', value: 'Cuautlapán' },
  { label: 'Cuautotola', value: 'Cuautotola' },
  { label: 'Cuautotolapan (San José)', value: 'Cuautotolapan (San José)' },
  { label: 'Cuaxomulco', value: 'Cuaxomulco' },
  { label: 'Cuaxoxpan', value: 'Cuaxoxpan' },
  { label: 'Cuaxuxpa', value: 'Cuaxuxpa' },
  { label: 'Cuayuca De Andrade', value: 'Cuayuca De Andrade' },
  { label: 'Cuazahuatl', value: 'Cuazahuatl' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Cuba City', value: 'Cuba City' },
  { label: 'Cuba La', value: 'Cuba La' },
  { label: 'Cubal', value: 'Cubal' },
  { label: 'Cuballing', value: 'Cuballing' },
  { label: 'Cubará', value: 'Cubará' },
  { label: 'Cubarral', value: 'Cubarral' },
  { label: 'Cubas', value: 'Cubas' },
  { label: 'Cubas De La Sagra', value: 'Cubas De La Sagra' },
  { label: 'Cubatão', value: 'Cubatão' },
  { label: 'Cubati', value: 'Cubati' },
  { label: 'Cubel', value: 'Cubel' },
  { label: 'Cubelles', value: 'Cubelles' },
  { label: 'Cubells', value: 'Cubells' },
  { label: 'Cubilla', value: 'Cubilla' },
  { label: 'Cubillas De Cerrato', value: 'Cubillas De Cerrato' },
  { label: 'Cubillas De Los Oteros', value: 'Cubillas De Los Oteros' },
  { label: 'Cubillas De Rueda', value: 'Cubillas De Rueda' },
  { label: 'Cubillas De Santa Marta', value: 'Cubillas De Santa Marta' },
  { label: 'Cubillo', value: 'Cubillo' },
  { label: 'Cubillo De Uceda El', value: 'Cubillo De Uceda El' },
  { label: 'Cubillo Del Campo', value: 'Cubillo Del Campo' },
  { label: 'Cubillos', value: 'Cubillos' },
  { label: 'Cubillos Del Sil', value: 'Cubillos Del Sil' },
  { label: 'Cubiri De Portelas', value: 'Cubiri De Portelas' },
  { label: 'Cubla', value: 'Cubla' },
  { label: 'Cublac', value: 'Cublac' },
  { label: 'Cublize', value: 'Cublize' },
  { label: 'Cubo De Benavente', value: 'Cubo De Benavente' },
  { label: 'Cubo De Bureba', value: 'Cubo De Bureba' },
  { label: 'Cubo De Don Sancho El', value: 'Cubo De Don Sancho El' },
  { label: 'Cubo De La Solana', value: 'Cubo De La Solana' },
  { label: 'Cubo De Tierra Del Vino El', value: 'Cubo De Tierra Del Vino El' },
  { label: 'Çubuk', value: 'Çubuk' },
  { label: 'Cubulco', value: 'Cubulco' },
  { label: 'Cubulcut', value: 'Cubulcut' },
  { label: 'Cubzac-Les-Ponts', value: 'Cubzac-Les-Ponts' },
  { label: 'Cuca', value: 'Cuca' },
  { label: 'Cuca', value: 'Cuca' },
  { label: 'Cucaita', value: 'Cucaita' },
  { label: 'Cucalón', value: 'Cucalón' },
  { label: 'Cuccaro Monferrato', value: 'Cuccaro Monferrato' },
  { label: 'Cuccaro Vetere', value: 'Cuccaro Vetere' },
  { label: 'Cucciago', value: 'Cucciago' },
  { label: 'Cuccurano', value: 'Cuccurano' },
  { label: 'Cuceglio', value: 'Cuceglio' },
  { label: 'Cucerdea', value: 'Cucerdea' },
  { label: 'Cuch Holoch', value: 'Cuch Holoch' },
  { label: 'Cucharas', value: 'Cucharas' },
  { label: 'Cuchicuato', value: 'Cuchicuato' },
  { label: 'Cuchulumtic', value: 'Cuchulumtic' },
  { label: 'Cuci', value: 'Cuci' },
  { label: 'Cuciulata', value: 'Cuciulata' },
  { label: 'Cuckfield', value: 'Cuckfield' },
  { label: 'Cucq', value: 'Cucq' },
  { label: 'Cucuchucho', value: 'Cucuchucho' },
  { label: 'Cucueți', value: 'Cucueți' },
  { label: 'Cucuieți', value: 'Cucuieți' },
  { label: 'Cucujães', value: 'Cucujães' },
  { label: 'Cucunatí', value: 'Cucunatí' },
  { label: 'Cucunubá', value: 'Cucunubá' },
  { label: 'Cucuron', value: 'Cucuron' },
  { label: 'Cucurpe', value: 'Cucurpe' },
  { label: 'Cucuruzu', value: 'Cucuruzu' },
  { label: 'Cúcuta', value: 'Cúcuta' },
  { label: 'Cucuteni', value: 'Cucuteni' },
  { label: 'Cucutilla', value: 'Cucutilla' },
  { label: 'Cucuyagua', value: 'Cucuyagua' },
  { label: 'Cucuyulapa Primera Sección', value: 'Cucuyulapa Primera Sección' },
  { label: 'Cudahy', value: 'Cudahy' },
  { label: 'Cudahy', value: 'Cudahy' },
  { label: 'Cudalbi', value: 'Cudalbi' },
  { label: 'Cuddalore', value: 'Cuddalore' },
  { label: 'Cuddapah', value: 'Cuddapah' },
  { label: 'Cuddington', value: 'Cuddington' },
  { label: 'Cudillero', value: 'Cudillero' },
  { label: 'Cudjoe Key', value: 'Cudjoe Key' },
  { label: 'Cudworth', value: 'Cudworth' },
  { label: 'Cue', value: 'Cue' },
  { label: 'Cuecuecuatitla', value: 'Cuecuecuatitla' },
  { label: 'Cuejdiu', value: 'Cuejdiu' },
  { label: 'Cuelgamures', value: 'Cuelgamures' },
  { label: 'Cuéllar', value: 'Cuéllar' },
  { label: 'Cuenca', value: 'Cuenca' },
  { label: 'Cuenca', value: 'Cuenca' },
  { label: 'Cuenca', value: 'Cuenca' },
  { label: 'Cuenca De Campos', value: 'Cuenca De Campos' },
  { label: 'Cuencamé', value: 'Cuencamé' },
  { label: 'Cuencamé De Ceniceros', value: 'Cuencamé De Ceniceros' },
  { label: 'Cuendo', value: 'Cuendo' },
  { label: 'Cuentepec', value: 'Cuentepec' },
  { label: 'Cuerámaro', value: 'Cuerámaro' },
  { label: 'Cuerlas Las', value: 'Cuerlas Las' },
  { label: 'Cuernavaca', value: 'Cuernavaca' },
  { label: 'Cuero', value: 'Cuero' },
  { label: 'Cuers', value: 'Cuers' },
  { label: 'Cuerva', value: 'Cuerva' },
  { label: 'Cuervo De Sevilla El', value: 'Cuervo De Sevilla El' },
  { label: 'Cuervo El', value: 'Cuervo El' },
  { label: 'Cuesta Amarilla', value: 'Cuesta Amarilla' },
  { label: 'Cuesta Blanca', value: 'Cuesta Blanca' },
  { label: 'Cuesta Blanca', value: 'Cuesta Blanca' },
  { label: 'Cuesta Chica', value: 'Cuesta Chica' },
  { label: 'Cuesta De Laja', value: 'Cuesta De Laja' },
  { label: 'Cuesta De Ovejas', value: 'Cuesta De Ovejas' },
  { label: 'Cuesta De Peñones', value: 'Cuesta De Peñones' },
  { label: 'Cueto', value: 'Cueto' },
  { label: 'Cuetzala Del Progreso', value: 'Cuetzala Del Progreso' },
  { label: 'Cuetzalan', value: 'Cuetzalan' },
  { label: 'Cuetzalan Del Progreso', value: 'Cuetzalan Del Progreso' },
  { label: 'Cueva De Ágreda', value: 'Cueva De Ágreda' },
  { label: 'Cueva De Roa La', value: 'Cueva De Roa La' },
  { label: 'Cueva Del Hierro', value: 'Cueva Del Hierro' },
  { label: 'Cuevas', value: 'Cuevas' },
  { label: 'Cuevas', value: 'Cuevas' },
  { label: 'Cuevas Bajas', value: 'Cuevas Bajas' },
  { label: 'Cuevas De Almudén', value: 'Cuevas De Almudén' },
  { label: 'Cuevas De Provanco', value: 'Cuevas De Provanco' },
  { label: 'Cuevas De San Clemente', value: 'Cuevas De San Clemente' },
  { label: 'Cuevas De San Marcos', value: 'Cuevas De San Marcos' },
  { label: 'Cuevas De Vinromá', value: 'Cuevas De Vinromá' },
  { label: 'Cuevas Del Almanzora', value: 'Cuevas Del Almanzora' },
  { label: 'Cuevas Del Becerro', value: 'Cuevas Del Becerro' },
  { label: 'Cuevas Del Campo', value: 'Cuevas Del Campo' },
  { label: 'Cuevas Del Valle', value: 'Cuevas Del Valle' },
  { label: 'Cuevas Labradas', value: 'Cuevas Labradas' },
  { label: 'Cuexcomatitlán', value: 'Cuexcomatitlán' },
  { label: 'Cuexpala', value: 'Cuexpala' },
  { label: 'Cuffie Ridge', value: 'Cuffie Ridge' },
  { label: 'Cuffies', value: 'Cuffies' },
  { label: 'Cuffley', value: 'Cuffley' },
  { label: 'Cuffy', value: 'Cuffy' },
  { label: 'Cufoaia', value: 'Cufoaia' },
  { label: 'Cugand', value: 'Cugand' },
  { label: 'Cuges-Les-Pins', value: 'Cuges-Les-Pins' },
  { label: 'Cuggiono', value: 'Cuggiono' },
  { label: 'Cugir', value: 'Cugir' },
  { label: 'Cugliate-Fabiasco', value: 'Cugliate-Fabiasco' },
  { label: 'Cuglieri', value: 'Cuglieri' },
  { label: 'Cugnasco', value: 'Cugnasco' },
  { label: 'Cugnaux', value: 'Cugnaux' },
  { label: 'Cugnoli', value: 'Cugnoli' },
  { label: 'Cugy', value: 'Cugy' },
  { label: 'Cuiabá', value: 'Cuiabá' },
  { label: 'Cuicatlan', value: 'Cuicatlan' },
  { label: 'Cuichapa', value: 'Cuichapa' },
  { label: 'Cuieșd', value: 'Cuieșd' },
  { label: 'Cuijiamatou', value: 'Cuijiamatou' },
  { label: 'Cuijk', value: 'Cuijk' },
  { label: 'Cuilapa', value: 'Cuilapa' },
  { label: 'Cuilapan De Guerrero', value: 'Cuilapan De Guerrero' },
  { label: 'Cuilco', value: 'Cuilco' },
  { label: 'Cuinchy', value: 'Cuinchy' },
  { label: 'Cuincy', value: 'Cuincy' },
  { label: 'Cuiseaux', value: 'Cuiseaux' },
  { label: 'Cuise-La-Motte', value: 'Cuise-La-Motte' },
  { label: 'Cuisery', value: 'Cuisery' },
  { label: 'Cuisiat', value: 'Cuisiat' },
  { label: 'Cuisillos', value: 'Cuisillos' },
  { label: 'Cuité', value: 'Cuité' },
  { label: 'Cuité De Mamanguape', value: 'Cuité De Mamanguape' },
  { label: 'Cuitegi', value: 'Cuitegi' },
  { label: 'Cuítiva', value: 'Cuítiva' },
  { label: 'Cuitláhuac', value: 'Cuitláhuac' },
  { label: 'Cuito', value: 'Cuito' },
  { label: 'Cuitzeo', value: 'Cuitzeo' },
  { label: 'Cuitzeo Del Porvenir', value: 'Cuitzeo Del Porvenir' },
  { label: 'Cuiyachapa', value: 'Cuiyachapa' },
  { label: 'Cujmir', value: 'Cujmir' },
  { label: 'Cujubim', value: 'Cujubim' },
  { label: 'Cukai', value: 'Cukai' },
  { label: 'Çukurca', value: 'Çukurca' },
  { label: 'Çukurova', value: 'Çukurova' },
  { label: 'Cul De Sac', value: 'Cul De Sac' },
  { label: 'Culaba', value: 'Culaba' },
  { label: 'Culaba', value: 'Culaba' },
  { label: 'Culacling', value: 'Culacling' },
  { label: 'Culaman', value: 'Culaman' },
  { label: 'Culaman', value: 'Culaman' },
  { label: 'Culaman', value: 'Culaman' },
  { label: 'Culasi', value: 'Culasi' },
  { label: 'Culasian', value: 'Culasian' },
  { label: 'Culasian', value: 'Culasian' },
  { label: 'Culberson County', value: 'Culberson County' },
  { label: 'Culburra Beach', value: 'Culburra Beach' },
  { label: 'Culcairn', value: 'Culcairn' },
  { label: 'Culcea', value: 'Culcea' },
  { label: 'Culcheth', value: 'Culcheth' },
  { label: 'Culciu Mic', value: 'Culciu Mic' },
  { label: 'Culebra', value: 'Culebra' },
  { label: 'Culebra', value: 'Culebra' },
  { label: 'Culebra Barrio-Pueblo', value: 'Culebra Barrio-Pueblo' },
  { label: 'Culemborg', value: 'Culemborg' },
  { label: 'Culfa', value: 'Culfa' },
  { label: 'Culhat', value: 'Culhat' },
  { label: 'Culiacán', value: 'Culiacán' },
  { label: 'Culiacancito', value: 'Culiacancito' },
  { label: 'Culianan', value: 'Culianan' },
  { label: 'Culianin', value: 'Culianin' },
  { label: 'Culianin', value: 'Culianin' },
  { label: 'Culit', value: 'Culit' },
  { label: 'Culit', value: 'Culit' },
  { label: 'Culla', value: 'Culla' },
  { label: 'Cullalabo Del Sur', value: 'Cullalabo Del Sur' },
  { label: 'Cullalabo Del Sur', value: 'Cullalabo Del Sur' },
  { label: 'Cúllar', value: 'Cúllar' },
  { label: 'Cúllar Vega', value: 'Cúllar Vega' },
  { label: 'Cullcuyre', value: 'Cullcuyre' },
  { label: 'Cullen', value: 'Cullen' },
  { label: 'Cullen', value: 'Cullen' },
  { label: 'Cullera', value: 'Cullera' },
  { label: 'Culleredo', value: 'Culleredo' },
  { label: 'Cullinan', value: 'Cullinan' },
  { label: 'Cullingworth', value: 'Cullingworth' },
  { label: 'Cullman', value: 'Cullman' },
  { label: 'Cullman County', value: 'Cullman County' },
  { label: 'Culloden', value: 'Culloden' },
  { label: 'Culloden', value: 'Culloden' },
  { label: 'Cullompton', value: 'Cullompton' },
  { label: 'Cullowhee', value: 'Cullowhee' },
  { label: 'Cully', value: 'Cully' },
  { label: 'Cullybackey', value: 'Cullybackey' },
  { label: 'Culmea', value: 'Culmea' },
  { label: 'Culmore', value: 'Culmore' },
  { label: 'Culoz', value: 'Culoz' },
  { label: 'Culp Creek', value: 'Culp Creek' },
  { label: 'Culpeper', value: 'Culpeper' },
  { label: 'Culpeper County', value: 'Culpeper County' },
  { label: 'Culpiu', value: 'Culpiu' },
  { label: 'Cults', value: 'Cults' },
  { label: 'Culubasa', value: 'Culubasa' },
  { label: 'Culubasa', value: 'Culubasa' },
  { label: 'Culver', value: 'Culver' },
  { label: 'Culver', value: 'Culver' },
  { label: 'Culver City', value: 'Culver City' },
  { label: 'Culverstone Green', value: 'Culverstone Green' },
  { label: 'Cumadcad', value: 'Cumadcad' },
  { label: 'Cumaná', value: 'Cumaná' },
  { label: 'Cumanayagua', value: 'Cumanayagua' },
  { label: 'Cumaral', value: 'Cumaral' },
  { label: 'Cumari', value: 'Cumari' },
  { label: 'Cumaribo', value: 'Cumaribo' },
  { label: 'Cumaru', value: 'Cumaru' },
  { label: 'Cumaru Do Norte', value: 'Cumaru Do Norte' },
  { label: 'Cumayeri', value: 'Cumayeri' },
  { label: 'Cumbal', value: 'Cumbal' },
  { label: 'Cumbalum', value: 'Cumbalum' },
  { label: 'Cumbe', value: 'Cumbe' },
  { label: 'Cumberland', value: 'Cumberland' },
  { label: 'Cumberland', value: 'Cumberland' },
  { label: 'Cumberland', value: 'Cumberland' },
  { label: 'Cumberland', value: 'Cumberland' },
  { label: 'Cumberland', value: 'Cumberland' },
  { label: 'Cumberland', value: 'Cumberland' },
  { label: 'Cumberland', value: 'Cumberland' },
  { label: 'Cumberland', value: 'Cumberland' },
  { label: 'Cumberland', value: 'Cumberland' },
  { label: 'Cumberland Center', value: 'Cumberland Center' },
  { label: 'Cumberland County', value: 'Cumberland County' },
  { label: 'Cumberland County', value: 'Cumberland County' },
  { label: 'Cumberland County', value: 'Cumberland County' },
  { label: 'Cumberland County', value: 'Cumberland County' },
  { label: 'Cumberland County', value: 'Cumberland County' },
  { label: 'Cumberland County', value: 'Cumberland County' },
  { label: 'Cumberland County', value: 'Cumberland County' },
  { label: 'Cumberland County', value: 'Cumberland County' },
  { label: 'Cumberland Head', value: 'Cumberland Head' },
  { label: 'Cumberland Hill', value: 'Cumberland Hill' },
  { label: 'Cumberland Park', value: 'Cumberland Park' },
  { label: 'Cumbernauld', value: 'Cumbernauld' },
  { label: 'Cumbitara', value: 'Cumbitara' },
  { label: 'Cumbre De Barranca Honda', value: 'Cumbre De Barranca Honda' },
  { label: 'Cumbre La', value: 'Cumbre La' },
  { label: 'Cumbres De Enmedio', value: 'Cumbres De Enmedio' },
  { label: 'Cumbres De San Bartolomé', value: 'Cumbres De San Bartolomé' },
  { label: 'Cumbres Iii', value: 'Cumbres Iii' },
  { label: 'Cumbres Mayores', value: 'Cumbres Mayores' },
  { label: 'Cumbria', value: 'Cumbria' },
  { label: 'Cumbum', value: 'Cumbum' },
  { label: 'Cumbum', value: 'Cumbum' },
  { label: 'Cumiana', value: 'Cumiana' },
  { label: 'Cumignano Sul Naviglio', value: 'Cumignano Sul Naviglio' },
  { label: 'Cuming County', value: 'Cuming County' },
  { label: 'Cumming', value: 'Cumming' },
  { label: 'Cumnock', value: 'Cumnock' },
  { label: 'Cumpăna', value: 'Cumpăna' },
  { label: 'Cumpas', value: 'Cumpas' },
  { label: 'Cumpich', value: 'Cumpich' },
  { label: 'Çumra', value: 'Çumra' },
  { label: 'Cumuato', value: 'Cumuato' },
  { label: 'Cunac', value: 'Cunac' },
  { label: 'Cunardo', value: 'Cunardo' },
  { label: 'Cunco', value: 'Cunco' },
  { label: 'Cuncolim', value: 'Cuncolim' },
  { label: 'Cuncunul', value: 'Cuncunul' },
  { label: 'Cund', value: 'Cund' },
  { label: 'Cunday', value: 'Cunday' },
  { label: 'Cunderdin', value: 'Cunderdin' },
  { label: 'Cundletown', value: 'Cundletown' },
  { label: 'Cunduacán', value: 'Cunduacán' },
  { label: 'Cunén', value: 'Cunén' },
  { label: 'Cuneo', value: 'Cuneo' },
  { label: 'Cunettone-Villa', value: 'Cunettone-Villa' },
  { label: 'Cunevo', value: 'Cunevo' },
  { label: 'Cunewalde', value: 'Cunewalde' },
  { label: 'Cung Kiệm', value: 'Cung Kiệm' },
  { label: 'Cungrea', value: 'Cungrea' },
  { label: 'Çüngüş', value: 'Çüngüş' },
  { label: 'Cunha', value: 'Cunha' },
  { label: 'Cunha Porã', value: 'Cunha Porã' },
  { label: 'Cunhataí', value: 'Cunhataí' },
  { label: 'Cunico', value: 'Cunico' },
  { label: 'Cunit', value: 'Cunit' },
  { label: 'Cunlhat', value: 'Cunlhat' },
  { label: 'Cunnamulla', value: 'Cunnamulla' },
  { label: 'Cuntis', value: 'Cuntis' },
  { label: 'Cuonetzingo', value: 'Cuonetzingo' },
  { label: 'Cuorgnè', value: 'Cuorgnè' },
  { label: 'Cupar', value: 'Cupar' },
  { label: 'Cuparaque', value: 'Cuparaque' },
  { label: 'Cuparátaro', value: 'Cuparátaro' },
  { label: 'Cupareo', value: 'Cupareo' },
  { label: 'Cupello', value: 'Cupello' },
  { label: 'Cupertino', value: 'Cupertino' },
  { label: 'Cupilco', value: 'Cupilco' },
  { label: 'Cupira', value: 'Cupira' },
  { label: 'Cupra Marittima', value: 'Cupra Marittima' },
  { label: 'Cupramontana', value: 'Cupramontana' },
  { label: 'Ćuprija', value: 'Ćuprija' },
  { label: 'Cupşeni', value: 'Cupşeni' },
  { label: 'Cupuán Del Río', value: 'Cupuán Del Río' },
  { label: 'Cuquío', value: 'Cuquío' },
  { label: 'Cura Carpignano', value: 'Cura Carpignano' },
  { label: 'Curaçá', value: 'Curaçá' },
  { label: 'Curacautín', value: 'Curacautín' },
  { label: 'Curacaví', value: 'Curacaví' },
  { label: 'Curaco De Vélez', value: 'Curaco De Vélez' },
  { label: 'Curahuara De Carangas', value: 'Curahuara De Carangas' },
  { label: 'Curanilahue', value: 'Curanilahue' },
  { label: 'Curarén', value: 'Curarén' },
  { label: 'Curarrehue', value: 'Curarrehue' },
  { label: 'Curăţele', value: 'Curăţele' },
  { label: 'Curatoe Hill', value: 'Curatoe Hill' },
  { label: 'Curcani', value: 'Curcani' },
  { label: 'Curchorem', value: 'Curchorem' },
  { label: 'Curcuris', value: 'Curcuris' },
  { label: 'Curdworth', value: 'Curdworth' },
  { label: 'Cureggio', value: 'Cureggio' },
  { label: 'Curepipe', value: 'Curepipe' },
  { label: 'Curepto', value: 'Curepto' },
  { label: 'Curețe', value: 'Curețe' },
  { label: 'Curgies', value: 'Curgies' },
  { label: 'Curgy', value: 'Curgy' },
  { label: 'Curicó', value: 'Curicó' },
  { label: 'Curiel De Duero', value: 'Curiel De Duero' },
  { label: 'Curiglia', value: 'Curiglia' },
  { label: 'Curillo', value: 'Curillo' },
  { label: 'Curimatá', value: 'Curimatá' },
  { label: 'Curimeo', value: 'Curimeo' },
  { label: 'Curinga', value: 'Curinga' },
  { label: 'Curino', value: 'Curino' },
  { label: 'Curionópolis', value: 'Curionópolis' },
  { label: 'Curití', value: 'Curití' },
  { label: 'Curitiba', value: 'Curitiba' },
  { label: 'Curitibanos', value: 'Curitibanos' },
  { label: 'Curiúva', value: 'Curiúva' },
  { label: 'Curl Curl', value: 'Curl Curl' },
  { label: 'Curno', value: 'Curno' },
  { label: 'Curon Venosta', value: 'Curon Venosta' },
  { label: 'Curra', value: 'Curra' },
  { label: 'Currais', value: 'Currais' },
  { label: 'Currais Novos', value: 'Currais Novos' },
  { label: 'Currajong', value: 'Currajong' },
  { label: 'Curral Das Freiras', value: 'Curral Das Freiras' },
  { label: 'Curral De Cima', value: 'Curral De Cima' },
  { label: 'Curral De Dentro', value: 'Curral De Dentro' },
  { label: 'Curral Novo Do Piauí', value: 'Curral Novo Do Piauí' },
  { label: 'Curral Velho', value: 'Curral Velho' },
  { label: 'Curralinho', value: 'Curralinho' },
  { label: 'Curralinhos', value: 'Curralinhos' },
  { label: 'Currambine', value: 'Currambine' },
  { label: 'Currans Hill', value: 'Currans Hill' },
  { label: 'Curridabat', value: 'Curridabat' },
  { label: 'Currie', value: 'Currie' },
  { label: 'Currie', value: 'Currie' },
  { label: 'Currimao', value: 'Currimao' },
  { label: 'Currimao', value: 'Currimao' },
  { label: 'Currimundi', value: 'Currimundi' },
  { label: 'Currituck', value: 'Currituck' },
  { label: 'Currituck County', value: 'Currituck County' },
  { label: 'Currumbin', value: 'Currumbin' },
  { label: 'Currumbin Valley', value: 'Currumbin Valley' },
  { label: 'Currumbin Waters', value: 'Currumbin Waters' },
  { label: 'Curry', value: 'Curry' },
  { label: 'Curry County', value: 'Curry County' },
  { label: 'Curry County', value: 'Curry County' },
  { label: 'Curry Rivel', value: 'Curry Rivel' },
  { label: 'Cursi', value: 'Cursi' },
  { label: 'Cursolo', value: 'Cursolo' },
  { label: 'Curtarolo', value: 'Curtarolo' },
  { label: 'Curtea', value: 'Curtea' },
  { label: 'Curtea De Argeş', value: 'Curtea De Argeş' },
  { label: 'Curteni', value: 'Curteni' },
  { label: 'Curteşti', value: 'Curteşti' },
  { label: 'Curti', value: 'Curti' },
  { label: 'Curti', value: 'Curti' },
  { label: 'Curtice', value: 'Curtice' },
  { label: 'Curtici', value: 'Curtici' },
  { label: 'Curtin', value: 'Curtin' },
  { label: 'Curtin', value: 'Curtin' },
  { label: 'Curtina', value: 'Curtina' },
  { label: 'Curtis', value: 'Curtis' },
  { label: 'Curtişoara', value: 'Curtişoara' },
  { label: 'Curtisville', value: 'Curtisville' },
  { label: 'Curtuișeni', value: 'Curtuișeni' },
  { label: 'Curtuiușu Mare', value: 'Curtuiușu Mare' },
  { label: 'Curtuiușu Mic', value: 'Curtuiușu Mic' },
  { label: 'Curuá', value: 'Curuá' },
  { label: 'Curuan', value: 'Curuan' },
  { label: 'Curuçá', value: 'Curuçá' },
  { label: 'Curug', value: 'Curug' },
  { label: 'Čurug', value: 'Čurug' },
  { label: 'Curumaní', value: 'Curumaní' },
  { label: 'Curundú', value: 'Curundú' },
  { label: 'Curungueo', value: 'Curungueo' },
  { label: 'Curup', value: 'Curup' },
  { label: 'Cururupu', value: 'Cururupu' },
  { label: 'Curuzú Cuatiá', value: 'Curuzú Cuatiá' },
  { label: 'Curvelândia', value: 'Curvelândia' },
  { label: 'Curvelo', value: 'Curvelo' },
  { label: 'Curwensville', value: 'Curwensville' },
  { label: 'Cusago', value: 'Cusago' },
  { label: 'Cusano', value: 'Cusano' },
  { label: 'Cusano Mutri', value: 'Cusano Mutri' },
  { label: 'Cuscatancingo', value: 'Cuscatancingo' },
  { label: 'Cusco', value: 'Cusco' },
  { label: 'Cusercoli', value: 'Cusercoli' },
  { label: 'Cushendall', value: 'Cushendall' },
  { label: 'Cushing', value: 'Cushing' },
  { label: 'Cushing', value: 'Cushing' },
  { label: 'Cusihuiriachi', value: 'Cusihuiriachi' },
  { label: 'Cusino', value: 'Cusino' },
  { label: 'Cusio', value: 'Cusio' },
  { label: 'Cussac', value: 'Cussac' },
  { label: 'Cussac-Fort-Médoc', value: 'Cussac-Fort-Médoc' },
  { label: 'Cussac-Sur-Loire', value: 'Cussac-Sur-Loire' },
  { label: 'Cusset', value: 'Cusset' },
  { label: 'Cusseta', value: 'Cusseta' },
  { label: 'Cuștelnic', value: 'Cuștelnic' },
  { label: 'Custer', value: 'Custer' },
  { label: 'Custer County', value: 'Custer County' },
  { label: 'Custer County', value: 'Custer County' },
  { label: 'Custer County', value: 'Custer County' },
  { label: 'Custer County', value: 'Custer County' },
  { label: 'Custer County', value: 'Custer County' },
  { label: 'Custer County', value: 'Custer County' },
  { label: 'Custines', value: 'Custines' },
  { label: 'Custique', value: 'Custique' },
  { label: 'Custódia', value: 'Custódia' },
  { label: 'Custoias', value: 'Custoias' },
  { label: 'Custonaci', value: 'Custonaci' },
  { label: 'Cusuna', value: 'Cusuna' },
  { label: 'Cusy', value: 'Cusy' },
  { label: 'Cut', value: 'Cut' },
  { label: 'Cut', value: 'Cut' },
  { label: 'Cut And Shoot', value: 'Cut And Shoot' },
  { label: 'Cut Bank', value: 'Cut Bank' },
  { label: 'Cut Off', value: 'Cut Off' },
  { label: 'Cútar', value: 'Cútar' },
  { label: 'Cutchogue', value: 'Cutchogue' },
  { label: 'Cut-Cut Primero', value: 'Cut-Cut Primero' },
  { label: 'Cut-Cut Primero', value: 'Cut-Cut Primero' },
  { label: 'Cuthbert', value: 'Cuthbert' },
  { label: 'Cutias', value: 'Cutias' },
  { label: 'Cutigliano', value: 'Cutigliano' },
  { label: 'Cutler', value: 'Cutler' },
  { label: 'Cutler', value: 'Cutler' },
  { label: 'Cutler Bay', value: 'Cutler Bay' },
  { label: 'Cutler Ridge', value: 'Cutler Ridge' },
  { label: 'Cutlerville', value: 'Cutlerville' },
  { label: 'Cuto De La Esperanza', value: 'Cuto De La Esperanza' },
  { label: 'Cuto Del Porvenir', value: 'Cuto Del Porvenir' },
  { label: 'Cutral-Có', value: 'Cutral-Có' },
  { label: 'Cutro', value: 'Cutro' },
  { label: 'Cutrofiano', value: 'Cutrofiano' },
  { label: 'Cuttack', value: 'Cuttack' },
  { label: 'Cutten', value: 'Cutten' },
  { label: 'Cuttoli-Corticchiato', value: 'Cuttoli-Corticchiato' },
  { label: 'Cutzamala De Pinzón', value: 'Cutzamala De Pinzón' },
  { label: 'Cuvango', value: 'Cuvango' },
  { label: 'Cuveglio', value: 'Cuveglio' },
  { label: 'Cuverville', value: 'Cuverville' },
  { label: 'Cuvin', value: 'Cuvin' },
  { label: 'Cuvio', value: 'Cuvio' },
  { label: 'Cuxac-Cabardès', value: 'Cuxac-Cabardès' },
  { label: 'Cuxhaven', value: 'Cuxhaven' },
  { label: 'Cuxpala', value: 'Cuxpala' },
  { label: 'Cuxton', value: 'Cuxton' },
  { label: 'Cuyahoga County', value: 'Cuyahoga County' },
  { label: 'Cuyahoga Falls', value: 'Cuyahoga Falls' },
  { label: 'Cuyalí', value: 'Cuyalí' },
  { label: 'Cuyamel', value: 'Cuyamel' },
  { label: 'Cuyapo', value: 'Cuyapo' },
  { label: 'Cuyapo', value: 'Cuyapo' },
  { label: 'Cuyo', value: 'Cuyo' },
  { label: 'Cuyoaco', value: 'Cuyoaco' },
  { label: 'Cuyotenango', value: 'Cuyotenango' },
  { label: 'Cuyutlán', value: 'Cuyutlán' },
  { label: 'Cuyuxtlahuaca', value: 'Cuyuxtlahuaca' },
  { label: 'Cuza Vodă', value: 'Cuza Vodă' },
  { label: 'Cuza Vodă', value: 'Cuza Vodă' },
  { label: 'Cuza Vodă', value: 'Cuza Vodă' },
  { label: 'Cuza Vodă', value: 'Cuza Vodă' },
  { label: 'Cuzama', value: 'Cuzama' },
  { label: 'Cuzap', value: 'Cuzap' },
  { label: 'Cuzăplac', value: 'Cuzăplac' },
  { label: 'Cuzcurrita De Río Tirón', value: 'Cuzcurrita De Río Tirón' },
  { label: 'Cuzdrioara', value: 'Cuzdrioara' },
  { label: 'Cuzieu', value: 'Cuzieu' },
  { label: 'Cvikov', value: 'Cvikov' },
  { label: 'Cwm', value: 'Cwm' },
  { label: 'Cwmafan', value: 'Cwmafan' },
  { label: 'Cwmbach', value: 'Cwmbach' },
  { label: 'Cwmbran', value: 'Cwmbran' },
  { label: 'Cyangugu', value: 'Cyangugu' },
  { label: 'Cybinka', value: 'Cybinka' },
  { label: 'Cyców', value: 'Cyców' },
  { label: 'Cygnet', value: 'Cygnet' },
  { label: 'Cynków', value: 'Cynków' },
  { label: 'Cynthiana', value: 'Cynthiana' },
  { label: 'Cypress', value: 'Cypress' },
  { label: 'Cypress', value: 'Cypress' },
  { label: 'Cypress Gardens', value: 'Cypress Gardens' },
  { label: 'Cypress Hills', value: 'Cypress Hills' },
  { label: 'Cypress Lake', value: 'Cypress Lake' },
  { label: 'Cypress Quarters', value: 'Cypress Quarters' },
  { label: 'Cyril', value: 'Cyril' },
  { label: 'Cysoing', value: 'Cysoing' },
  { label: 'Czajków', value: 'Czajków' },
  { label: 'Czaniec', value: 'Czaniec' },
  { label: 'Czaplinek', value: 'Czaplinek' },
  { label: 'Czapury', value: 'Czapury' },
  { label: 'Czarków', value: 'Czarków' },
  { label: 'Czarna', value: 'Czarna' },
  { label: 'Czarna Białostocka', value: 'Czarna Białostocka' },
  { label: 'Czarna Dąbrówka', value: 'Czarna Dąbrówka' },
  { label: 'Czarna Góra', value: 'Czarna Góra' },
  { label: 'Czarna Woda', value: 'Czarna Woda' },
  { label: 'Czarne', value: 'Czarne' },
  { label: 'Czarnia', value: 'Czarnia' },
  { label: 'Czarnków', value: 'Czarnków' },
  { label: 'Czarnochowice', value: 'Czarnochowice' },
  { label: 'Czarnocin', value: 'Czarnocin' },
  { label: 'Czarnocin', value: 'Czarnocin' },
  { label: 'Czarnowąsy', value: 'Czarnowąsy' },
  { label: 'Czarnożyły', value: 'Czarnożyły' },
  { label: 'Czarny Bór', value: 'Czarny Bór' },
  { label: 'Czarny Dunajec', value: 'Czarny Dunajec' },
  { label: 'Czastary', value: 'Czastary' },
  { label: 'Czaszyn', value: 'Czaszyn' },
  { label: 'Czchów', value: 'Czchów' },
  { label: 'Czechowice-Dziedzice', value: 'Czechowice-Dziedzice' },
  { label: 'Czeladź', value: 'Czeladź' },
  { label: 'Czemierniki', value: 'Czemierniki' },
  { label: 'Czempiń', value: 'Czempiń' },
  { label: 'Czeremcha', value: 'Czeremcha' },
  { label: 'Czermin', value: 'Czermin' },
  { label: 'Czermin', value: 'Czermin' },
  { label: 'Czernica', value: 'Czernica' },
  { label: 'Czernica', value: 'Czernica' },
  { label: 'Czernice Borowe', value: 'Czernice Borowe' },
  { label: 'Czernichów', value: 'Czernichów' },
  { label: 'Czernichów', value: 'Czernichów' },
  { label: 'Czerniejewo', value: 'Czerniejewo' },
  { label: 'Czerniewice', value: 'Czerniewice' },
  { label: 'Czerniewice', value: 'Czerniewice' },
  { label: 'Czernikowo', value: 'Czernikowo' },
  { label: 'Czersk', value: 'Czersk' },
  { label: 'Czerwieńsk', value: 'Czerwieńsk' },
  { label: 'Czerwin', value: 'Czerwin' },
  { label: 'Czerwińsk Nad Wisłą', value: 'Czerwińsk Nad Wisłą' },
  { label: 'Czerwionka-Leszczyny', value: 'Czerwionka-Leszczyny' },
  { label: 'Czerwonak', value: 'Czerwonak' },
  { label: 'Czerwonka', value: 'Czerwonka' },
  { label: 'Czerwonka', value: 'Czerwonka' },
  { label: 'Częstochowa', value: 'Częstochowa' },
  { label: 'Człopa', value: 'Człopa' },
  { label: 'Człuchów', value: 'Człuchów' },
  { label: 'Czudec', value: 'Czudec' },
  { label: 'Czułów', value: 'Czułów' },
  { label: 'Czyżew', value: 'Czyżew' },
  { label: 'Czyżowice', value: 'Czyżowice' },
  { label: 'D’Aguilar', value: 'D’Aguilar' },
  { label: 'Ðà Lạt', value: 'Ðà Lạt' },
  { label: 'Da Nang', value: 'Da Nang' },
  { label: 'Daaden', value: 'Daaden' },
  { label: 'Da-An Sur', value: 'Da-An Sur' },
  { label: 'Daanbantayan', value: 'Daanbantayan' },
  { label: 'Daba Kunda', value: 'Daba Kunda' },
  { label: 'Dababa', value: 'Dababa' },
  { label: 'Dăbâca', value: 'Dăbâca' },
  { label: 'Dabachang', value: 'Dabachang' },
  { label: 'Dabai', value: 'Dabai' },
  { label: 'Dabakala', value: 'Dabakala' },
  { label: 'Daban', value: 'Daban' },
  { label: 'Dabaozi', value: 'Dabaozi' },
  { label: 'Dabas', value: 'Dabas' },
  { label: 'Dabasi Járás', value: 'Dabasi Járás' },
  { label: 'Dabat', value: 'Dabat' },
  { label: 'Dabbūrīya', value: 'Dabbūrīya' },
  { label: 'Dabeiba', value: 'Dabeiba' },
  { label: 'Dabel', value: 'Dabel' },
  { label: 'Dabhoda', value: 'Dabhoda' },
  { label: 'Dabhoi', value: 'Dabhoi' },
  { label: 'Dabhol', value: 'Dabhol' },
  { label: 'Dąbie', value: 'Dąbie' },
  { label: 'Dąbie', value: 'Dąbie' },
  { label: 'Dąbie', value: 'Dąbie' },
  { label: 'Dabiran', value: 'Dabiran' },
  { label: 'Dabo', value: 'Dabo' },
  { label: 'Daboh', value: 'Daboh' },
  { label: 'Dabou', value: 'Dabou' },
  { label: 'Dabra', value: 'Dabra' },
  { label: 'Dąbrowa', value: 'Dąbrowa' },
  { label: 'Dąbrowa', value: 'Dąbrowa' },
  { label: 'Dąbrowa Białostocka', value: 'Dąbrowa Białostocka' },
  { label: 'Dąbrowa Chełmińska', value: 'Dąbrowa Chełmińska' },
  { label: 'Dąbrowa Górnicza', value: 'Dąbrowa Górnicza' },
  { label: 'Dąbrowa Tarnowska', value: 'Dąbrowa Tarnowska' },
  { label: 'Dąbrowa Zielona', value: 'Dąbrowa Zielona' },
  { label: 'Dąbrowice', value: 'Dąbrowice' },
  { label: 'Dąbrówka', value: 'Dąbrówka' },
  { label: 'Dąbrówno', value: 'Dąbrówno' },
  { label: 'Dabudasht', value: 'Dabudasht' },
  { label: 'Dăbuleni', value: 'Dăbuleni' },
  { label: 'Dabwali', value: 'Dabwali' },
  { label: 'Dabyah', value: 'Dabyah' },
  { label: 'Daceyville', value: 'Daceyville' },
  { label: 'Dachau', value: 'Dachau' },
  { label: 'Dachnoye', value: 'Dachnoye' },
  { label: 'Dachnoye', value: 'Dachnoye' },
  { label: 'Dachnoye', value: 'Dachnoye' },
  { label: 'Dachsen', value: 'Dachsen' },
  { label: 'Dachsenhausen', value: 'Dachsenhausen' },
  { label: 'Dachstein', value: 'Dachstein' },
  { label: 'Dachwig', value: 'Dachwig' },
  { label: 'Dačice', value: 'Dačice' },
  { label: 'Dacono', value: 'Dacono' },
  { label: 'Dacretin', value: 'Dacretin' },
  { label: 'Dacudao', value: 'Dacudao' },
  { label: 'Dacudao', value: 'Dacudao' },
  { label: 'Dacudao', value: 'Dacudao' },
  { label: 'Dacula', value: 'Dacula' },
  { label: 'Dad', value: 'Dad' },
  { label: 'Daday', value: 'Daday' },
  { label: 'Daddi', value: 'Daddi' },
  { label: 'Dade City', value: 'Dade City' },
  { label: 'Dade City North', value: 'Dade City North' },
  { label: 'Dade County', value: 'Dade County' },
  { label: 'Dade County', value: 'Dade County' },
  { label: 'Dadeldhura ', value: 'Dadeldhura ' },
  { label: 'Dadeng', value: 'Dadeng' },
  { label: 'Dadeville', value: 'Dadeville' },
  { label: 'Dadhar', value: 'Dadhar' },
  { label: 'Dadiya', value: 'Dadiya' },
  { label: 'Dado', value: 'Dado' },
  { label: 'Dado', value: 'Dado' },
  { label: 'Dadonghai', value: 'Dadonghai' },
  { label: 'Dadonville', value: 'Dadonville' },
  { label: 'Dadra', value: 'Dadra' },
  { label: 'Dadra & Nagar Haveli', value: 'Dadra & Nagar Haveli' },
  { label: 'Dadri', value: 'Dadri' },
  { label: 'Dadu', value: 'Dadu' },
  { label: 'Dadukou', value: 'Dadukou' },
  { label: 'Dadunqiu', value: 'Dadunqiu' },
  { label: 'Dadus', value: 'Dadus' },
  { label: 'Dadus', value: 'Dadus' },
  { label: 'Daegu', value: 'Daegu' },
  { label: 'Daejeon', value: 'Daejeon' },
  { label: 'Dăeni', value: 'Dăeni' },
  { label: 'Daerah Batu Pahat', value: 'Daerah Batu Pahat' },
  { label: 'Daerah Johor Baharu', value: 'Daerah Johor Baharu' },
  { label: 'Daerah Keluang', value: 'Daerah Keluang' },
  { label: 'Daerah Kota Tinggi', value: 'Daerah Kota Tinggi' },
  { label: 'Daerah Mersing', value: 'Daerah Mersing' },
  { label: 'Daerah Muar', value: 'Daerah Muar' },
  { label: 'Daerah Pontian', value: 'Daerah Pontian' },
  { label: 'Daerah Segamat', value: 'Daerah Segamat' },
  { label: 'Dăeşti', value: 'Dăeşti' },
  { label: 'Daet', value: 'Daet' },
  { label: 'Dág', value: 'Dág' },
  { label: 'Daga', value: 'Daga' },
  { label: 'Dagami', value: 'Dagami' },
  { label: 'Dagami', value: 'Dagami' },
  { label: 'Daganzo De Arriba', value: 'Daganzo De Arriba' },
  { label: 'Dagapela', value: 'Dagapela' },
  { label: 'Dagâţa', value: 'Dagâţa' },
  { label: 'Dagatan', value: 'Dagatan' },
  { label: 'Dagda', value: 'Dagda' },
  { label: 'Dägeling', value: 'Dägeling' },
  { label: 'Dagenham', value: 'Dagenham' },
  { label: 'Dagestanskiye Ogni', value: 'Dagestanskiye Ogni' },
  { label: 'Daggett County', value: 'Daggett County' },
  { label: 'Daglish', value: 'Daglish' },
  { label: 'Dagmersellen', value: 'Dagmersellen' },
  { label: 'Dagneux', value: 'Dagneux' },
  { label: 'Dagohoy', value: 'Dagohoy' },
  { label: 'Dagomys', value: 'Dagomys' },
  { label: 'Dagotière', value: 'Dagotière' },
  { label: 'Dagshai', value: 'Dagshai' },
  { label: 'Dagua', value: 'Dagua' },
  { label: 'Daguao', value: 'Daguao' },
  { label: 'Daguilar Town/ Rennock Lodge', value: 'Daguilar Town/ Rennock Lodge' },
  { label: 'Daguioman', value: 'Daguioman' },
  { label: 'Daguioman', value: 'Daguioman' },
  { label: 'Daguit', value: 'Daguit' },
  { label: 'Daguma', value: 'Daguma' },
  { label: 'Dagumba-An', value: 'Dagumba-An' },
  { label: 'Dagup', value: 'Dagup' },
  { label: 'Dagup', value: 'Dagup' },
  { label: 'Dagupan', value: 'Dagupan' },
  { label: 'Dagupan', value: 'Dagupan' },
  { label: 'Dagupan', value: 'Dagupan' },
  { label: 'Dagupan', value: 'Dagupan' },
  { label: 'Dahab', value: 'Dahab' },
  { label: 'Dahaban', value: 'Dahaban' },
  { label: 'Dahanu', value: 'Dahanu' },
  { label: 'Dahay', value: 'Dahay' },
  { label: 'Dahay', value: 'Dahay' },
  { label: 'Dahegam', value: 'Dahegam' },
  { label: 'Dahivel', value: 'Dahivel' },
  { label: 'Dahlem', value: 'Dahlem' },
  { label: 'Dahlem', value: 'Dahlem' },
  { label: 'Dahlen', value: 'Dahlen' },
  { label: 'Dahlenburg', value: 'Dahlenburg' },
  { label: 'Dahlenwarsleben', value: 'Dahlenwarsleben' },
  { label: 'Dahlgren', value: 'Dahlgren' },
  { label: 'Dahlonega', value: 'Dahlonega' },
  { label: 'Dahme', value: 'Dahme' },
  { label: 'Dahme', value: 'Dahme' },
  { label: 'Dahn', value: 'Dahn' },
  { label: 'Dahnejerd', value: 'Dahnejerd' },
  { label: 'Dahod', value: 'Dahod' },
  { label: 'Daia', value: 'Daia' },
  { label: 'Daia', value: 'Daia' },
  { label: 'Daia Română', value: 'Daia Română' },
  { label: 'Daiano', value: 'Daiano' },
  { label: 'Daigo', value: 'Daigo' },
  { label: 'Dailekh ', value: 'Dailekh ' },
  { label: 'Daimiel', value: 'Daimiel' },
  { label: 'Daimús', value: 'Daimús' },
  { label: 'Dainava (Kaunas)', value: 'Dainava (Kaunas)' },
  { label: 'Daingean', value: 'Daingean' },
  { label: 'Daingerfield', value: 'Daingerfield' },
  { label: 'Dainville', value: 'Dainville' },
  { label: 'Daira Din Panah', value: 'Daira Din Panah' },
  { label: 'Dairago', value: 'Dairago' },
  { label: 'Daisen', value: 'Daisen' },
  { label: 'Daisendorf', value: 'Daisendorf' },
  { label: 'Daisen-Shi', value: 'Daisen-Shi' },
  { label: 'Daisy Hill', value: 'Daisy Hill' },
  { label: 'Daitari', value: 'Daitari' },
  { label: 'Daitō Shi', value: 'Daitō Shi' },
  { label: 'Daitōchō', value: 'Daitōchō' },
  { label: 'Daix', value: 'Daix' },
  { label: 'Daixi', value: 'Daixi' },
  { label: 'Dajabón', value: 'Dajabón' },
  { label: 'Dajal', value: 'Dajal' },
  { label: 'Daji', value: 'Daji' },
  { label: 'Dajiedi', value: 'Dajiedi' },
  { label: 'Dakabin', value: 'Dakabin' },
  { label: 'Dakar', value: 'Dakar' },
  { label: 'Dakar Department', value: 'Dakar Department' },
  { label: 'Dakbayan Sa Bislig', value: 'Dakbayan Sa Bislig' },
  { label: 'Dakbayan Sa Digos', value: 'Dakbayan Sa Digos' },
  { label: 'Dakbayan Sa Digos', value: 'Dakbayan Sa Digos' },
  { label: 'Dakhadayevskiy Rayon', value: 'Dakhadayevskiy Rayon' },
  { label: 'Dakingari', value: 'Dakingari' },
  { label: 'Dakor', value: 'Dakor' },
  { label: 'Dakoro', value: 'Dakoro' },
  { label: 'Dakota', value: 'Dakota' },
  { label: 'Dakota City', value: 'Dakota City' },
  { label: 'Dakota City', value: 'Dakota City' },
  { label: 'Dakota County', value: 'Dakota County' },
  { label: 'Dakota County', value: 'Dakota County' },
  { label: 'Dakota Dunes', value: 'Dakota Dunes' },
  { label: 'Dakoutun', value: 'Dakoutun' },
  { label: 'Đakovo', value: 'Đakovo' },
  { label: 'Dakshin Dinajpur District', value: 'Dakshin Dinajpur District' },
  { label: 'Dakshina Kannada', value: 'Dakshina Kannada' },
  { label: 'Dal’Nekonstantinovskiy Rayon', value: 'Dal’Nekonstantinovskiy Rayon' },
  { label: 'Dal’Neye Konstantinovo', value: 'Dal’Neye Konstantinovo' },
  { label: 'Dalaas', value: 'Dalaas' },
  { label: 'Dalaba', value: 'Dalaba' },
  { label: 'Dalabyggð', value: 'Dalabyggð' },
  { label: 'Dalachów', value: 'Dalachów' },
  { label: 'Dalaguete', value: 'Dalaguete' },
  { label: 'Dalahu', value: 'Dalahu' },
  { label: 'Dalai', value: 'Dalai' },
  { label: 'Dalaki', value: 'Dalaki' },
  { label: 'Dalakovo', value: 'Dalakovo' },
  { label: 'Dalaman', value: 'Dalaman' },
  { label: 'Daland', value: 'Daland' },
  { label: 'Dalandzadgad', value: 'Dalandzadgad' },
  { label: 'Dalangin', value: 'Dalangin' },
  { label: 'Dalaoig', value: 'Dalaoig' },
  { label: 'Dalaoig', value: 'Dalaoig' },
  { label: 'Dalar', value: 'Dalar' },
  { label: 'Dalarik', value: 'Dalarik' },
  { label: 'Dalarö', value: 'Dalarö' },
  { label: 'Dalbandin', value: 'Dalbandin' },
  { label: 'Dalbeattie', value: 'Dalbeattie' },
  { label: 'Dalboșeț', value: 'Dalboșeț' },
  { label: 'Dalby', value: 'Dalby' },
  { label: 'Dalby', value: 'Dalby' },
  { label: 'Dalcahue', value: 'Dalcahue' },
  { label: 'Dale', value: 'Dale' },
  { label: 'Dale', value: 'Dale' },
  { label: 'Dale City', value: 'Dale City' },
  { label: 'Dale County', value: 'Dale County' },
  { label: 'Dalekoye', value: 'Dalekoye' },
  { label: 'Dalen', value: 'Dalen' },
  { label: 'Dalen', value: 'Dalen' },
  { label: 'Daleszyce', value: 'Daleszyce' },
  { label: 'Daleville', value: 'Daleville' },
  { label: 'Daleville', value: 'Daleville' },
  { label: 'Daleville', value: 'Daleville' },
  { label: 'Dalfsen', value: 'Dalfsen' },
  { label: 'Dâlga-Gară', value: 'Dâlga-Gară' },
  { label: 'Dalgan', value: 'Dalgan' },
  { label: 'Dalgety Bay', value: 'Dalgety Bay' },
  { label: 'Dalgopol', value: 'Dalgopol' },
  { label: 'Dalhart', value: 'Dalhart' },
  { label: 'Dalheim', value: 'Dalheim' },
  { label: 'Dalheim', value: 'Dalheim' },
  { label: 'Dalhem', value: 'Dalhem' },
  { label: 'Dalhousie', value: 'Dalhousie' },
  { label: 'Dali', value: 'Dali' },
  { label: 'Dáli', value: 'Dáli' },
  { label: 'Dali Baizu Zizhizhou', value: 'Dali Baizu Zizhizhou' },
  { label: 'Dali Chu', value: 'Dali Chu' },
  { label: 'Dalian', value: 'Dalian' },
  { label: 'Daliang', value: 'Daliang' },
  { label: 'Dalianwan', value: 'Dalianwan' },
  { label: 'Daliao', value: 'Daliao' },
  { label: 'Daliao', value: 'Daliao' },
  { label: 'Dalías', value: 'Dalías' },
  { label: 'Dalican', value: 'Dalican' },
  { label: 'Dalican', value: 'Dalican' },
  { label: 'Daliciasao', value: 'Daliciasao' },
  { label: 'Dalików', value: 'Dalików' },
  { label: 'Dalipebinaw Municipality', value: 'Dalipebinaw Municipality' },
  { label: 'Dalipey', value: 'Dalipey' },
  { label: 'Dalipey', value: 'Dalipey' },
  { label: 'Dalipuga', value: 'Dalipuga' },
  { label: 'Dalirig', value: 'Dalirig' },
  { label: 'Daliyat Al Karmel', value: 'Daliyat Al Karmel' },
  { label: 'Dalj', value: 'Dalj' },
  { label: 'Dalkeith', value: 'Dalkeith' },
  { label: 'Dalkeith', value: 'Dalkeith' },
  { label: 'Dalkey', value: 'Dalkey' },
  { label: 'Dalkola', value: 'Dalkola' },
  { label: 'Dallam County', value: 'Dallam County' },
  { label: 'Dallas', value: 'Dallas' },
  { label: 'Dallas', value: 'Dallas' },
  { label: 'Dallas', value: 'Dallas' },
  { label: 'Dallas', value: 'Dallas' },
  { label: 'Dallas', value: 'Dallas' },
  { label: 'Dallas', value: 'Dallas' },
  { label: 'Dallas', value: 'Dallas' },
  { label: 'Dallas Center', value: 'Dallas Center' },
  { label: 'Dallas County', value: 'Dallas County' },
  { label: 'Dallas County', value: 'Dallas County' },
  { label: 'Dallas County', value: 'Dallas County' },
  { label: 'Dallas County', value: 'Dallas County' },
  { label: 'Dallas County', value: 'Dallas County' },
  { label: 'Dallastown', value: 'Dallastown' },
  { label: 'Dallenwil', value: 'Dallenwil' },
  { label: 'Dallesport', value: 'Dallesport' },
  { label: 'Dallet', value: 'Dallet' },
  { label: 'Dallgow-Döberitz', value: 'Dallgow-Döberitz' },
  { label: 'Dällikon / Dällikon (Dorf)', value: 'Dällikon / Dällikon (Dorf)' },
  { label: 'Dalmacio Vélez Sársfield', value: 'Dalmacio Vélez Sársfield' },
  { label: 'Dalmatovo', value: 'Dalmatovo' },
  { label: 'Dalmau', value: 'Dalmau' },
  { label: 'Dalmellington', value: 'Dalmellington' },
  { label: 'Dalmeny', value: 'Dalmeny' },
  { label: 'Dalmeny', value: 'Dalmeny' },
  { label: 'Dalmine', value: 'Dalmine' },
  { label: 'Dalnegorsk', value: 'Dalnegorsk' },
  { label: 'Dalnerechensk', value: 'Dalnerechensk' },
  { label: 'Dalnic', value: 'Dalnic' },
  { label: 'Daloa', value: 'Daloa' },
  { label: 'Dalorong', value: 'Dalorong' },
  { label: 'Dalovice', value: 'Dalovice' },
  { label: 'Dalry', value: 'Dalry' },
  { label: 'Dalrymple', value: 'Dalrymple' },
  { label: 'Dals Långed', value: 'Dals Långed' },
  { label: 'Dalseo-Gu', value: 'Dalseo-Gu' },
  { label: 'Dalseong-Gun', value: 'Dalseong-Gun' },
  { label: 'Dalserf', value: 'Dalserf' },
  { label: 'Dalsingh Sarai', value: 'Dalsingh Sarai' },
  { label: 'Dalsjöfors', value: 'Dalsjöfors' },
  { label: 'Dalston', value: 'Dalston' },
  { label: 'Dalton', value: 'Dalton' },
  { label: 'Dalton', value: 'Dalton' },
  { label: 'Dalton', value: 'Dalton' },
  { label: 'Dalton', value: 'Dalton' },
  { label: 'Dalton Gardens', value: 'Dalton Gardens' },
  { label: 'Dalton In Furness', value: 'Dalton In Furness' },
  { label: 'Daltonganj', value: 'Daltonganj' },
  { label: 'Dalu', value: 'Dalu' },
  { label: 'Dalumangcob', value: 'Dalumangcob' },
  { label: 'Dalumangcob', value: 'Dalumangcob' },
  { label: 'Dalupaon', value: 'Dalupaon' },
  { label: 'Dalupirip', value: 'Dalupirip' },
  { label: 'Dalupirip', value: 'Dalupirip' },
  { label: 'Dalvey', value: 'Dalvey' },
  { label: 'Dalvík', value: 'Dalvík' },
  { label: 'Dalvíkurbyggð', value: 'Dalvíkurbyggð' },
  { label: 'Dalwallinu', value: 'Dalwallinu' },
  { label: 'Dalwangan', value: 'Dalwangan' },
  { label: 'Dalworthington Gardens', value: 'Dalworthington Gardens' },
  { label: 'Daly City', value: 'Daly City' },
  { label: 'Dalyellup', value: 'Dalyellup' },
  { label: 'Dalzell', value: 'Dalzell' },
  { label: 'Dam Dam', value: 'Dam Dam' },
  { label: 'Đam Rong', value: 'Đam Rong' },
  { label: 'Damabalas', value: 'Damabalas' },
  { label: 'Damabalas', value: 'Damabalas' },
  { label: 'Damachava', value: 'Damachava' },
  { label: 'Dămăcușeni', value: 'Dămăcușeni' },
  { label: 'Damaia', value: 'Damaia' },
  { label: 'Damal', value: 'Damal' },
  { label: 'Daman', value: 'Daman' },
  { label: 'Damaneh', value: 'Damaneh' },
  { label: 'Damanhūr', value: 'Damanhūr' },
  { label: 'Damaq', value: 'Damaq' },
  { label: 'Damara', value: 'Damara' },
  { label: 'Damariscotta', value: 'Damariscotta' },
  { label: 'Damasak', value: 'Damasak' },
  { label: 'Damasco', value: 'Damasco' },
  { label: 'Damascus', value: 'Damascus' },
  { label: 'Damascus', value: 'Damascus' },
  { label: 'Damascus', value: 'Damascus' },
  { label: 'Damasławek', value: 'Damasławek' },
  { label: 'Damatulan', value: 'Damatulan' },
  { label: 'Damatulan', value: 'Damatulan' },
  { label: 'Damaturu', value: 'Damaturu' },
  { label: 'Damavand', value: 'Damavand' },
  { label: 'Damawato', value: 'Damawato' },
  { label: 'Damawato', value: 'Damawato' },
  { label: 'Damayan', value: 'Damayan' },
  { label: 'Damazan', value: 'Damazan' },
  { label: 'Dambach-La-Ville', value: 'Dambach-La-Ville' },
  { label: 'Dâmbău', value: 'Dâmbău' },
  { label: 'Dambel', value: 'Dambel' },
  { label: 'Damboa', value: 'Damboa' },
  { label: 'Dambořice', value: 'Dambořice' },
  { label: 'Dâmbovicioara', value: 'Dâmbovicioara' },
  { label: 'Dâmbovicioara', value: 'Dâmbovicioara' },
  { label: 'Dâmbroca', value: 'Dâmbroca' },
  { label: 'Dâmbu', value: 'Dâmbu' },
  { label: 'Dâmbu', value: 'Dâmbu' },
  { label: 'Dambulla', value: 'Dambulla' },
  { label: 'Damelevières', value: 'Damelevières' },
  { label: 'Dame-Marie', value: 'Dame-Marie' },
  { label: 'Damery', value: 'Damery' },
  { label: 'Damgan', value: 'Damgan' },
  { label: 'Damghan', value: 'Damghan' },
  { label: 'Damian Carmona', value: 'Damian Carmona' },
  { label: 'Damianópolis', value: 'Damianópolis' },
  { label: 'Damião', value: 'Damião' },
  { label: 'Dămieneşti', value: 'Dămieneşti' },
  { label: 'Dămieni', value: 'Dămieni' },
  { label: 'Damietta', value: 'Damietta' },
  { label: 'Damigny', value: 'Damigny' },
  { label: 'Damilag', value: 'Damilag' },
  { label: 'Dammam', value: 'Dammam' },
  { label: 'Dammarie', value: 'Dammarie' },
  { label: 'Dammarie-Les-Lys', value: 'Dammarie-Les-Lys' },
  { label: 'Dammartin-En-Goële', value: 'Dammartin-En-Goële' },
  { label: 'Damme', value: 'Damme' },
  { label: 'Damme', value: 'Damme' },
  { label: 'Damnagar', value: 'Damnagar' },
  { label: 'Damnica', value: 'Damnica' },
  { label: 'Damnoen Saduak', value: 'Damnoen Saduak' },
  { label: 'Dămoc', value: 'Dămoc' },
  { label: 'Damoh', value: 'Damoh' },
  { label: 'Damolândia', value: 'Damolândia' },
  { label: 'Damolog', value: 'Damolog' },
  { label: 'Damortis', value: 'Damortis' },
  { label: 'Damortis', value: 'Damortis' },
  { label: 'Damp', value: 'Damp' },
  { label: 'Damparis', value: 'Damparis' },
  { label: 'Damphu', value: 'Damphu' },
  { label: 'Dampier', value: 'Dampier' },
  { label: 'Dampier Peninsula', value: 'Dampier Peninsula' },
  { label: 'Dampierre', value: 'Dampierre' },
  { label: 'Dampierre-En-Burly', value: 'Dampierre-En-Burly' },
  { label: 'Dampierre-En-Yvelines', value: 'Dampierre-En-Yvelines' },
  { label: 'Dampierre-Les-Bois', value: 'Dampierre-Les-Bois' },
  { label: 'Dampierre-Sur-Salon', value: 'Dampierre-Sur-Salon' },
  { label: 'Dampit', value: 'Dampit' },
  { label: 'Dampmart', value: 'Dampmart' },
  { label: 'Dampol', value: 'Dampol' },
  { label: 'Dampol', value: 'Dampol' },
  { label: 'Damprichard', value: 'Damprichard' },
  { label: 'Dămuc', value: 'Dămuc' },
  { label: 'Damulog', value: 'Damulog' },
  { label: 'Damüls', value: 'Damüls' },
  { label: 'Damville', value: 'Damville' },
  { label: 'Damwâld', value: 'Damwâld' },
  { label: 'Damyang-Gun', value: 'Damyang-Gun' },
  { label: 'Dan Gora', value: 'Dan Gora' },
  { label: 'Dan Khun Thot', value: 'Dan Khun Thot' },
  { label: 'Dan Sadau', value: 'Dan Sadau' },
  { label: 'Dana', value: 'Dana' },
  { label: 'Dana Point', value: 'Dana Point' },
  { label: 'Danané', value: 'Danané' },
  { label: 'Danao', value: 'Danao' },
  { label: 'Danbury', value: 'Danbury' },
  { label: 'Danbury', value: 'Danbury' },
  { label: 'Danbury', value: 'Danbury' },
  { label: 'Danbury', value: 'Danbury' },
  { label: 'Danbury', value: 'Danbury' },
  { label: 'Danby', value: 'Danby' },
  { label: 'Danby', value: 'Danby' },
  { label: 'Dancagan', value: 'Dancagan' },
  { label: 'Dancalan', value: 'Dancalan' },
  { label: 'Dănceu', value: 'Dănceu' },
  { label: 'Dănciuleşti', value: 'Dănciuleşti' },
  { label: 'Dancu', value: 'Dancu' },
  { label: 'Dancu', value: 'Dancu' },
  { label: 'Dandaragan', value: 'Dandaragan' },
  { label: 'Dande', value: 'Dande' },
  { label: 'Dandeli', value: 'Dandeli' },
  { label: 'Dandenong', value: 'Dandenong' },
  { label: 'Dandenong North', value: 'Dandenong North' },
  { label: 'Danderhall', value: 'Danderhall' },
  { label: 'Dandi', value: 'Dandi' },
  { label: 'Dandi', value: 'Dandi' },
  { label: 'Dandong', value: 'Dandong' },
  { label: 'Dandot Rs', value: 'Dandot Rs' },
  { label: 'Dandridge', value: 'Dandridge' },
  { label: 'Dane', value: 'Dane' },
  { label: 'Dane County', value: 'Dane County' },
  { label: 'Dăneasa', value: 'Dăneasa' },
  { label: 'Daneș', value: 'Daneș' },
  { label: 'Danesfahan', value: 'Danesfahan' },
  { label: 'Dăneşti', value: 'Dăneşti' },
  { label: 'Dăneşti', value: 'Dăneşti' },
  { label: 'Dăneşti', value: 'Dăneşti' },
  { label: 'Dănești', value: 'Dănești' },
  { label: 'Dăneștii Chioarului', value: 'Dăneștii Chioarului' },
  { label: 'Dăneţi', value: 'Dăneţi' },
  { label: 'Dang ', value: 'Dang ' },
  { label: 'Dangcalan', value: 'Dangcalan' },
  { label: 'Dange', value: 'Dange' },
  { label: 'Dângeni', value: 'Dângeni' },
  { label: 'Dangé-Saint-Romain', value: 'Dangé-Saint-Romain' },
  { label: 'Danghara', value: 'Danghara' },
  { label: 'Danglas', value: 'Danglas' },
  { label: 'Danglas', value: 'Danglas' },
  { label: 'Dangriga', value: 'Dangriga' },
  { label: 'Dangs (India)', value: 'Dangs (India)' },
  { label: 'Dania Beach', value: 'Dania Beach' },
  { label: 'Daniel', value: 'Daniel' },
  { label: 'Daniel Flores', value: 'Daniel Flores' },
  { label: 'Daniel Town', value: 'Daniel Town' },
  { label: 'Daniels', value: 'Daniels' },
  { label: 'Daniels County', value: 'Daniels County' },
  { label: 'Daniëlskuil', value: 'Daniëlskuil' },
  { label: 'Danielson', value: 'Danielson' },
  { label: 'Danielsville', value: 'Danielsville' },
  { label: 'Dänikon', value: 'Dänikon' },
  { label: 'Danilov', value: 'Danilov' },
  { label: 'Danilovgrad', value: 'Danilovgrad' },
  { label: 'Danilovka Volgograd Oblast', value: 'Danilovka Volgograd Oblast' },
  { label: 'Danilovskiy Rayon', value: 'Danilovskiy Rayon' },
  { label: 'Dänischenhagen', value: 'Dänischenhagen' },
  { label: 'Danja', value: 'Danja' },
  { label: 'Danjiangkou', value: 'Danjiangkou' },
  { label: 'Danjoutin', value: 'Danjoutin' },
  { label: 'Dankalwa', value: 'Dankalwa' },
  { label: 'Dankama', value: 'Dankama' },
  { label: 'Dankaur', value: 'Dankaur' },
  { label: 'Danki', value: 'Danki' },
  { label: 'Dankmarshausen', value: 'Dankmarshausen' },
  { label: 'Dankov', value: 'Dankov' },
  { label: 'Dankovskiy Rayon', value: 'Dankovskiy Rayon' },
  { label: 'Dankunku', value: 'Dankunku' },
  { label: 'Danlí', value: 'Danlí' },
  { label: 'Danlugan', value: 'Danlugan' },
  { label: 'Danndorf', value: 'Danndorf' },
  { label: 'Dannemarie', value: 'Dannemarie' },
  { label: 'Dannemarie-Sur-Crête', value: 'Dannemarie-Sur-Crête' },
  { label: 'Dannemora', value: 'Dannemora' },
  { label: 'Dannenberg', value: 'Dannenberg' },
  { label: 'Dannes', value: 'Dannes' },
  { label: 'Dannewerk', value: 'Dannewerk' },
  { label: 'Dannstadt-Schauernheim', value: 'Dannstadt-Schauernheim' },
  { label: 'Dano', value: 'Dano' },
  { label: 'Danshui', value: 'Danshui' },
  { label: 'Dansuli', value: 'Dansuli' },
  { label: 'Dansuli', value: 'Dansuli' },
  { label: 'Dansville', value: 'Dansville' },
  { label: 'Dánszentmiklós', value: 'Dánszentmiklós' },
  { label: 'Danta', value: 'Danta' },
  { label: 'Danta', value: 'Danta' },
  { label: 'Dantewada', value: 'Dantewada' },
  { label: 'Dantzibojay', value: 'Dantzibojay' },
  { label: 'Danvers', value: 'Danvers' },
  { label: 'Danvers', value: 'Danvers' },
  { label: 'Danvers Pen', value: 'Danvers Pen' },
  { label: 'Danville', value: 'Danville' },
  { label: 'Danville', value: 'Danville' },
  { label: 'Danville', value: 'Danville' },
  { label: 'Danville', value: 'Danville' },
  { label: 'Danville', value: 'Danville' },
  { label: 'Danville', value: 'Danville' },
  { label: 'Danville', value: 'Danville' },
  { label: 'Danville', value: 'Danville' },
  { label: 'Danville', value: 'Danville' },
  { label: 'Danville', value: 'Danville' },
  { label: 'Danville', value: 'Danville' },
  { label: 'Dány', value: 'Dány' },
  { label: 'Danyang', value: 'Danyang' },
  { label: 'Danyang-Gun', value: 'Danyang-Gun' },
  { label: 'Dao', value: 'Dao' },
  { label: 'Dao', value: 'Dao' },
  { label: 'Dao', value: 'Dao' },
  { label: 'Daokou', value: 'Daokou' },
  { label: 'Daone', value: 'Daone' },
  { label: 'Daoudi', value: 'Daoudi' },
  { label: 'Daoukro', value: 'Daoukro' },
  { label: 'Daoulas', value: 'Daoulas' },
  { label: 'Dapa', value: 'Dapa' },
  { label: 'Dapa', value: 'Dapa' },
  { label: 'Dapaong', value: 'Dapaong' },
  { label: 'Dapawan', value: 'Dapawan' },
  { label: 'Dapchi', value: 'Dapchi' },
  { label: 'Dapdap', value: 'Dapdap' },
  { label: 'Dapdap', value: 'Dapdap' },
  { label: 'Dapdap', value: 'Dapdap' },
  { label: 'Dapdapan', value: 'Dapdapan' },
  { label: 'Daphne', value: 'Daphne' },
  { label: 'Daphu', value: 'Daphu' },
  { label: 'Dapitan', value: 'Dapitan' },
  { label: 'Dapitan', value: 'Dapitan' },
  { label: 'Dapoli', value: 'Dapoli' },
  { label: 'Dapto', value: 'Dapto' },
  { label: 'Daqdaqabad', value: 'Daqdaqabad' },
  { label: 'Daqiao', value: 'Daqiao' },
  { label: 'Daqing', value: 'Daqing' },
  { label: 'Dar Chaabane', value: 'Dar Chaabane' },
  { label: 'Dar Chioukh', value: 'Dar Chioukh' },
  { label: 'Dar El Beïda', value: 'Dar El Beïda' },
  { label: 'Dar Es Salaam', value: 'Dar Es Salaam' },
  { label: 'Dār Kulayb', value: 'Dār Kulayb' },
  { label: 'Dar Ould Zidouh', value: 'Dar Ould Zidouh' },
  { label: 'Dar Qeshlaq', value: 'Dar Qeshlaq' },
  { label: 'Dar Sad', value: 'Dar Sad' },
  { label: 'Dar‘Ā', value: 'Dar‘Ā' },
  { label: 'Dara', value: 'Dara' },
  { label: 'Dara', value: 'Dara' },
  { label: 'Darab', value: 'Darab' },
  { label: 'Darabani', value: 'Darabani' },
  { label: 'Daraga', value: 'Daraga' },
  { label: 'Daraganj', value: 'Daraganj' },
  { label: 'Daragutan', value: 'Daragutan' },
  { label: 'Daragutan', value: 'Daragutan' },
  { label: 'Daraitan', value: 'Daraitan' },
  { label: 'Darakert', value: 'Darakert' },
  { label: 'Daram', value: 'Daram' },
  { label: 'Daram', value: 'Daram' },
  { label: 'Daran', value: 'Daran' },
  { label: 'Daranagar', value: 'Daranagar' },
  { label: 'Darani-Ye Sofla', value: 'Darani-Ye Sofla' },
  { label: 'Darapidap', value: 'Darapidap' },
  { label: 'Darapidap', value: 'Darapidap' },
  { label: 'Daraq', value: 'Daraq' },
  { label: 'Dărăşti-Ilfov', value: 'Dărăşti-Ilfov' },
  { label: 'Dărăști-Vlașca', value: 'Dărăști-Vlașca' },
  { label: 'Darasun', value: 'Darasun' },
  { label: 'Darátsos', value: 'Darátsos' },
  { label: 'Dārayyā', value: 'Dārayyā' },
  { label: 'Darazo', value: 'Darazo' },
  { label: 'Darb Gonbad', value: 'Darb Gonbad' },
  { label: 'Darband', value: 'Darband' },
  { label: 'Darb-E Behesht', value: 'Darb-E Behesht' },
  { label: 'Darbhanga', value: 'Darbhanga' },
  { label: 'Darby', value: 'Darby' },
  { label: 'Darch', value: 'Darch' },
  { label: 'Darcham', value: 'Darcham' },
  { label: 'Darchula ', value: 'Darchula ' },
  { label: 'Darcinópolis', value: 'Darcinópolis' },
  { label: 'Darda', value: 'Darda' },
  { label: 'Dardagny', value: 'Dardagny' },
  { label: 'Dardanelle', value: 'Dardanelle' },
  { label: 'Dardanup', value: 'Dardanup' },
  { label: 'Dardenne Prairie', value: 'Dardenne Prairie' },
  { label: 'Dardilly', value: 'Dardilly' },
  { label: 'Dare County', value: 'Dare County' },
  { label: 'Darebin', value: 'Darebin' },
  { label: 'Dareda', value: 'Dareda' },
  { label: 'Darende', value: 'Darende' },
  { label: 'Darenth', value: 'Darenth' },
  { label: 'Daresbury', value: 'Daresbury' },
  { label: 'Darfield', value: 'Darfield' },
  { label: 'Darfield', value: 'Darfield' },
  { label: 'Darfo Boario Terme', value: 'Darfo Boario Terme' },
  { label: 'Dargahan', value: 'Dargahan' },
  { label: 'Dargaville', value: 'Dargaville' },
  { label: 'Dargaz', value: 'Dargaz' },
  { label: 'Dargeçit', value: 'Dargeçit' },
  { label: 'Dargnies', value: 'Dargnies' },
  { label: 'Dargun', value: 'Dargun' },
  { label: 'Darhan', value: 'Darhan' },
  { label: 'Dariba', value: 'Dariba' },
  { label: 'Darıca', value: 'Darıca' },
  { label: 'Darien', value: 'Darien' },
  { label: 'Darien', value: 'Darien' },
  { label: 'Darien', value: 'Darien' },
  { label: 'Darien', value: 'Darien' },
  { label: 'Dário Meira', value: 'Dário Meira' },
  { label: 'Dariun', value: 'Dariun' },
  { label: 'Darjazin', value: 'Darjazin' },
  { label: 'Darjeeling', value: 'Darjeeling' },
  { label: 'Dârjiu', value: 'Dârjiu' },
  { label: 'Darke County', value: 'Darke County' },
  { label: 'Darkhovin', value: 'Darkhovin' },
  { label: 'Darkovice', value: 'Darkovice' },
  { label: 'Darkūsh', value: 'Darkūsh' },
  { label: 'Darlaston', value: 'Darlaston' },
  { label: 'Darlawn', value: 'Darlawn' },
  { label: 'Darley', value: 'Darley' },
  { label: 'Därligen', value: 'Därligen' },
  { label: 'Darling Downs', value: 'Darling Downs' },
  { label: 'Darling Heights', value: 'Darling Heights' },
  { label: 'Darling Point', value: 'Darling Point' },
  { label: 'Darling Road', value: 'Darling Road' },
  { label: 'Darlingerode', value: 'Darlingerode' },
  { label: 'Darlinghurst', value: 'Darlinghurst' },
  { label: 'Darlington', value: 'Darlington' },
  { label: 'Darlington', value: 'Darlington' },
  { label: 'Darlington', value: 'Darlington' },
  { label: 'Darlington', value: 'Darlington' },
  { label: 'Darlington', value: 'Darlington' },
  { label: 'Darlington', value: 'Darlington' },
  { label: 'Darlington County', value: 'Darlington County' },
  { label: 'Darlington Point', value: 'Darlington Point' },
  { label: 'Darliston', value: 'Darliston' },
  { label: 'Dârlos', value: 'Dârlos' },
  { label: 'Darłowo', value: 'Darłowo' },
  { label: 'Dărmăneşti', value: 'Dărmăneşti' },
  { label: 'Dărmăneşti', value: 'Dărmăneşti' },
  { label: 'Dărmăneşti', value: 'Dărmăneşti' },
  { label: 'Dărmăneşti', value: 'Dărmăneşti' },
  { label: 'Darmian', value: 'Darmian' },
  { label: 'Darmstadt', value: 'Darmstadt' },
  { label: 'Darmstadt', value: 'Darmstadt' },
  { label: 'Darnah', value: 'Darnah' },
  { label: 'Dar-Naim', value: 'Dar-Naim' },
  { label: 'Darndale', value: 'Darndale' },
  { label: 'Darnestown', value: 'Darnestown' },
  { label: 'Darnétal', value: 'Darnétal' },
  { label: 'Darney', value: 'Darney' },
  { label: 'Darnieulles', value: 'Darnieulles' },
  { label: 'Darnius', value: 'Darnius' },
  { label: 'Darnytsia Raion', value: 'Darnytsia Raion' },
  { label: 'Daroca', value: 'Daroca' },
  { label: 'Daroca De Rioja', value: 'Daroca De Rioja' },
  { label: 'Daromehar', value: 'Daromehar' },
  { label: 'Darong', value: 'Darong' },
  { label: 'Daroot-Korgon', value: 'Daroot-Korgon' },
  { label: 'Darova', value: 'Darova' },
  { label: 'Darova Nouă', value: 'Darova Nouă' },
  { label: 'Darovskoy', value: 'Darovskoy' },
  { label: 'Darpas', value: 'Darpas' },
  { label: 'Darra', value: 'Darra' },
  { label: 'Darrang', value: 'Darrang' },
  { label: 'Darras Hall', value: 'Darras Hall' },
  { label: 'Darreh Chenar', value: 'Darreh Chenar' },
  { label: 'Darreh Mianeh-Ye Sofla', value: 'Darreh Mianeh-Ye Sofla' },
  { label: 'Darreh Mirza', value: 'Darreh Mirza' },
  { label: 'Darreh Shahr', value: 'Darreh Shahr' },
  { label: 'Darreh-Ye Ebrahim', value: 'Darreh-Ye Ebrahim' },
  { label: 'Darrington', value: 'Darrington' },
  { label: 'Darrington', value: 'Darrington' },
  { label: 'Darro', value: 'Darro' },
  { label: 'Darrod', value: 'Darrod' },
  { label: 'Darsi', value: 'Darsi' },
  { label: 'Darsibeh', value: 'Darsibeh' },
  { label: 'Dartford', value: 'Dartford' },
  { label: 'Dartmouth', value: 'Dartmouth' },
  { label: 'Dartmouth', value: 'Dartmouth' },
  { label: 'Dartmouth', value: 'Dartmouth' },
  { label: 'Darton', value: 'Darton' },
  { label: 'Daru', value: 'Daru' },
  { label: 'Daru', value: 'Daru' },
  { label: 'Daru Rilwan', value: 'Daru Rilwan' },
  { label: 'Daruvar', value: 'Daruvar' },
  { label: 'Dârvari', value: 'Dârvari' },
  { label: 'Dârvari', value: 'Dârvari' },
  { label: 'Darvel', value: 'Darvel' },
  { label: 'Darvoy', value: 'Darvoy' },
  { label: 'Darwen', value: 'Darwen' },
  { label: 'Darwha', value: 'Darwha' },
  { label: 'Darwin', value: 'Darwin' },
  { label: 'Darwin', value: 'Darwin' },
  { label: 'Darya Khan', value: 'Darya Khan' },
  { label: 'Darya Khan Marri', value: 'Darya Khan Marri' },
  { label: 'Daryapur', value: 'Daryapur' },
  { label: 'Dârza', value: 'Dârza' },
  { label: 'Darzāb', value: 'Darzāb' },
  { label: 'Das', value: 'Das' },
  { label: 'Dasà', value: 'Dasà' },
  { label: 'Dăscălești', value: 'Dăscălești' },
  { label: 'Dascălu', value: 'Dascălu' },
  { label: 'Dasha', value: 'Dasha' },
  { label: 'Dashentang', value: 'Dashentang' },
  { label: 'Dashiqiao', value: 'Dashiqiao' },
  { label: 'Dashitou', value: 'Dashitou' },
  { label: 'Dashiv', value: 'Dashiv' },
  { label: 'Dashkawka', value: 'Dashkawka' },
  { label: 'Dashtak', value: 'Dashtak' },
  { label: 'Dashtavan', value: 'Dashtavan' },
  { label: 'Dasht-E Archī', value: 'Dasht-E Archī' },
  { label: 'Dashtestan', value: 'Dashtestan' },
  { label: 'Dashti', value: 'Dashti' },
  { label: 'Dashti', value: 'Dashti' },
  { label: 'Dashtkar', value: 'Dashtkar' },
  { label: 'Dashtobod', value: 'Dashtobod' },
  { label: 'Dashynka', value: 'Dashynka' },
  { label: 'Dašice', value: 'Dašice' },
  { label: 'Dasing', value: 'Dasing' },
  { label: 'Daska', value: 'Daska' },
  { label: 'Daskara', value: 'Daskara' },
  { label: 'Dasle', value: 'Dasle' },
  { label: 'Dasmariñas', value: 'Dasmariñas' },
  { label: 'Dasmariñas Village', value: 'Dasmariñas Village' },
  { label: 'Dasna', value: 'Dasna' },
  { label: 'Dasnapur', value: 'Dasnapur' },
  { label: 'Daşoguz', value: 'Daşoguz' },
  { label: 'Dasol', value: 'Dasol' },
  { label: 'Dasol', value: 'Dasol' },
  { label: 'Dass', value: 'Dass' },
  { label: 'Dassa-Zoumé', value: 'Dassa-Zoumé' },
  { label: 'Dassel', value: 'Dassel' },
  { label: 'Dassel', value: 'Dassel' },
  { label: 'Dassendorf', value: 'Dassendorf' },
  { label: 'Dassow', value: 'Dassow' },
  { label: 'Dassun', value: 'Dassun' },
  { label: 'Dassun', value: 'Dassun' },
  { label: 'Dastena', value: 'Dastena' },
  { label: 'Dastgerd', value: 'Dastgerd' },
  { label: 'Dastjerd', value: 'Dastjerd' },
  { label: 'Dasuya', value: 'Dasuya' },
  { label: 'Daszewice', value: 'Daszewice' },
  { label: 'Daszyna', value: 'Daszyna' },
  { label: 'Data Kakus', value: 'Data Kakus' },
  { label: 'Dataganj', value: 'Dataganj' },
  { label: 'Datagon', value: 'Datagon' },
  { label: 'Datas', value: 'Datas' },
  { label: 'Dătășeni', value: 'Dătășeni' },
  { label: 'Datça', value: 'Datça' },
  { label: 'Datchet', value: 'Datchet' },
  { label: 'Datchworth', value: 'Datchworth' },
  { label: 'Date', value: 'Date' },
  { label: 'Date-Shi', value: 'Date-Shi' },
  { label: 'Date-Shi', value: 'Date-Shi' },
  { label: 'Datia', value: 'Datia' },
  { label: 'Datong', value: 'Datong' },
  { label: 'Datong', value: 'Datong' },
  { label: 'Datong Shi', value: 'Datong Shi' },
  { label: 'Dattapur', value: 'Dattapur' },
  { label: 'Datteln', value: 'Datteln' },
  { label: 'Dattenberg', value: 'Dattenberg' },
  { label: 'Datu Blah T. Sinsuat', value: 'Datu Blah T. Sinsuat' },
  { label: 'Datu Montawal', value: 'Datu Montawal' },
  { label: 'Datu Odin Sinsuat', value: 'Datu Odin Sinsuat' },
  { label: 'Datu Paglas', value: 'Datu Paglas' },
  { label: 'Datu Paglas', value: 'Datu Paglas' },
  { label: 'Datu Piang', value: 'Datu Piang' },
  { label: 'Datu Piang', value: 'Datu Piang' },
  { label: 'Dauchingen', value: 'Dauchingen' },
  { label: 'Daud Khel', value: 'Daud Khel' },
  { label: 'Daudnagar', value: 'Daudnagar' },
  { label: 'Dauendorf', value: 'Dauendorf' },
  { label: 'Daugai', value: 'Daugai' },
  { label: 'Daugavgrīva', value: 'Daugavgrīva' },
  { label: 'Daugavpils', value: 'Daugavpils' },
  { label: 'Dauin', value: 'Dauin' },
  { label: 'Dauis', value: 'Dauis' },
  { label: 'Daulagala', value: 'Daulagala' },
  { label: 'Daulatabad', value: 'Daulatabad' },
  { label: 'Daulatpur', value: 'Daulatpur' },
  { label: 'Daulatpur', value: 'Daulatpur' },
  { label: 'Daulatpur', value: 'Daulatpur' },
  { label: 'Daulo', value: 'Daulo' },
  { label: 'Daultala', value: 'Daultala' },
  { label: 'Daumeray', value: 'Daumeray' },
  { label: 'Daun', value: 'Daun' },
  { label: 'Daund', value: 'Daund' },
  { label: 'Dauphin', value: 'Dauphin' },
  { label: 'Dauphin', value: 'Dauphin' },
  { label: 'Dauphin County', value: 'Dauphin County' },
  { label: 'Dauphin Island', value: 'Dauphin Island' },
  { label: 'Daur', value: 'Daur' },
  { label: 'Daura', value: 'Daura' },
  { label: 'Daura', value: 'Daura' },
  { label: 'Daurala', value: 'Daurala' },
  { label: 'Dauriya', value: 'Dauriya' },
  { label: 'Dausa', value: 'Dausa' },
  { label: 'Dausenau', value: 'Dausenau' },
  { label: 'Dautillos', value: 'Dautillos' },
  { label: 'Dauwendaele', value: 'Dauwendaele' },
  { label: 'Daux', value: 'Daux' },
  { label: 'Davagna', value: 'Davagna' },
  { label: 'Davan', value: 'Davan' },
  { label: 'Davan', value: 'Davan' },
  { label: 'Davan', value: 'Davan' },
  { label: 'Davanagere', value: 'Davanagere' },
  { label: 'Davao', value: 'Davao' },
  { label: 'Davao City', value: 'Davao City' },
  { label: 'Davao City', value: 'Davao City' },
  { label: 'Davarzan', value: 'Davarzan' },
  { label: 'Daveluyville', value: 'Daveluyville' },
  { label: 'Davenport', value: 'Davenport' },
  { label: 'Davenport', value: 'Davenport' },
  { label: 'Davenport', value: 'Davenport' },
  { label: 'Daventry', value: 'Daventry' },
  { label: 'Daverdisse', value: 'Daverdisse' },
  { label: 'Daverio', value: 'Daverio' },
  { label: 'Davézieux', value: 'Davézieux' },
  { label: 'David', value: 'David' },
  { label: 'David Canabarro', value: 'David Canabarro' },
  { label: 'David City', value: 'David City' },
  { label: 'Davideşti', value: 'Davideşti' },
  { label: 'Davidivka', value: 'Davidivka' },
  { label: 'Davidson', value: 'Davidson' },
  { label: 'Davidson', value: 'Davidson' },
  { label: 'Davidson County', value: 'Davidson County' },
  { label: 'Davidson County', value: 'Davidson County' },
  { label: 'Davidsonville', value: 'Davidsonville' },
  { label: 'Davidsville', value: 'Davidsville' },
  { label: 'Davie', value: 'Davie' },
  { label: 'Davie County', value: 'Davie County' },
  { label: 'Daviess County', value: 'Daviess County' },
  { label: 'Daviess County', value: 'Daviess County' },
  { label: 'Daviess County', value: 'Daviess County' },
  { label: 'Davijan', value: 'Davijan' },
  { label: 'Davila', value: 'Davila' },
  { label: 'Davila', value: 'Davila' },
  { label: 'Davinópolis', value: 'Davinópolis' },
  { label: 'Davinópolis', value: 'Davinópolis' },
  { label: 'Davis', value: 'Davis' },
  { label: 'Davis', value: 'Davis' },
  { label: 'Davis County', value: 'Davis County' },
  { label: 'Davis County', value: 'Davis County' },
  { label: 'Davis Junction', value: 'Davis Junction' },
  { label: 'Davisboro', value: 'Davisboro' },
  { label: 'Davison', value: 'Davison' },
  { label: 'Davison County', value: 'Davison County' },
  { label: 'Davistown', value: 'Davistown' },
  { label: 'Davle', value: 'Davle' },
  { label: 'Davlekanovo', value: 'Davlekanovo' },
  { label: 'Dávod', value: 'Dávod' },
  { label: 'Davoli', value: 'Davoli' },
  { label: 'Davor', value: 'Davor' },
  { label: 'Davoren Park', value: 'Davoren Park' },
  { label: 'Davorlim', value: 'Davorlim' },
  { label: 'Davos', value: 'Davos' },
  { label: 'Davoud Abad', value: 'Davoud Abad' },
  { label: 'Davyd-Haradok', value: 'Davyd-Haradok' },
  { label: 'Davydivka', value: 'Davydivka' },
  { label: 'Davydkovo', value: 'Davydkovo' },
  { label: 'Davydky', value: 'Davydky' },
  { label: 'Davydovka', value: 'Davydovka' },
  { label: 'Davydovo', value: 'Davydovo' },
  { label: 'Daw Park', value: 'Daw Park' },
  { label: 'Dawa-Dawa', value: 'Dawa-Dawa' },
  { label: 'Dawadmi', value: 'Dawadmi' },
  { label: 'Dawan', value: 'Dawan' },
  { label: 'Dawangtai', value: 'Dawangtai' },
  { label: 'Dawei', value: 'Dawei' },
  { label: 'Dawes County', value: 'Dawes County' },
  { label: 'Dawesville', value: 'Dawesville' },
  { label: 'Dawkins', value: 'Dawkins' },
  { label: 'Dawlish', value: 'Dawlish' },
  { label: 'Dawran Aness', value: 'Dawran Aness' },
  { label: 'Dawson', value: 'Dawson' },
  { label: 'Dawson', value: 'Dawson' },
  { label: 'Dawson City', value: 'Dawson City' },
  { label: 'Dawson County', value: 'Dawson County' },
  { label: 'Dawson County', value: 'Dawson County' },
  { label: 'Dawson County', value: 'Dawson County' },
  { label: 'Dawson County', value: 'Dawson County' },
  { label: 'Dawson Creek', value: 'Dawson Creek' },
  { label: 'Dawson Springs', value: 'Dawson Springs' },
  { label: 'Dawsonville', value: 'Dawsonville' },
  { label: 'Dawukou', value: 'Dawukou' },
  { label: 'Dax', value: 'Dax' },
  { label: 'Daxbet', value: 'Daxbet' },
  { label: 'Daxi', value: 'Daxi' },
  { label: 'Daxing', value: 'Daxing' },
  { label: 'Daxinzhuang', value: 'Daxinzhuang' },
  { label: 'Daxtha', value: 'Daxtha' },
  { label: 'Day County', value: 'Day County' },
  { label: 'Day Heights', value: 'Day Heights' },
  { label: 'Day Valley', value: 'Day Valley' },
  { label: 'Daya Nueva', value: 'Daya Nueva' },
  { label: 'Daya Vieja', value: 'Daya Vieja' },
  { label: 'Dayal Bagh', value: 'Dayal Bagh' },
  { label: 'Dayap', value: 'Dayap' },
  { label: 'Dayapan', value: 'Dayapan' },
  { label: 'Dayapar', value: 'Dayapar' },
  { label: 'Dayboro', value: 'Dayboro' },
  { label: 'Daye', value: 'Daye' },
  { label: 'Daykitin', value: 'Daykitin' },
  { label: 'Daylesford', value: 'Daylesford' },
  { label: 'Dayr Al ‘Aşāfīr', value: 'Dayr Al ‘Aşāfīr' },
  { label: 'Dayr Ḩāfir', value: 'Dayr Ḩāfir' },
  { label: 'Dayr Mawās', value: 'Dayr Mawās' },
  { label: 'Dayr Yūsuf', value: 'Dayr Yūsuf' },
  { label: 'Dayrūţ', value: 'Dayrūţ' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Dayton', value: 'Dayton' },
  { label: 'Daytona Beach', value: 'Daytona Beach' },
  { label: 'Daytona Beach Shores', value: 'Daytona Beach Shores' },
  { label: 'Dayyer', value: 'Dayyer' },
  { label: 'Dazaifu-Shi', value: 'Dazaifu-Shi' },
  { label: 'Dazhai', value: 'Dazhai' },
  { label: 'Dazhong', value: 'Dazhong' },
  { label: 'Dazhongzhuang', value: 'Dazhongzhuang' },
  { label: 'Dazhou', value: 'Dazhou' },
  { label: 'Dazio', value: 'Dazio' },
  { label: 'Dazkırı', value: 'Dazkırı' },
  { label: 'Dazuo', value: 'Dazuo' },
  { label: 'Ddmashen', value: 'Ddmashen' },
  { label: 'De Aar', value: 'De Aar' },
  { label: 'De Aker', value: 'De Aker' },
  { label: 'De Baca County', value: 'De Baca County' },
  { label: 'De Bilt', value: 'De Bilt' },
  { label: 'De Blaak', value: 'De Blaak' },
  { label: 'De Bouwhof', value: 'De Bouwhof' },
  { label: 'De Cocksdorp', value: 'De Cocksdorp' },
  { label: 'De Domp', value: 'De Domp' },
  { label: 'De Doornakkers', value: 'De Doornakkers' },
  { label: 'De Drait', value: 'De Drait' },
  { label: 'De Forest', value: 'De Forest' },
  { label: 'De Fryske Marren', value: 'De Fryske Marren' },
  { label: 'De Glip', value: 'De Glip' },
  { label: 'De Goorn', value: 'De Goorn' },
  { label: 'De Graff', value: 'De Graff' },
  { label: 'De Greiden', value: 'De Greiden' },
  { label: 'De Haan', value: 'De Haan' },
  { label: 'De Hagen', value: 'De Hagen' },
  { label: 'De Haukes', value: 'De Haukes' },
  { label: 'De Haven', value: 'De Haven' },
  { label: 'De Heeg', value: 'De Heeg' },
  { label: 'De Heeze', value: 'De Heeze' },
  { label: 'De Hoven', value: 'De Hoven' },
  { label: 'De Hulk', value: 'De Hulk' },
  { label: 'De Kalb', value: 'De Kalb' },
  { label: 'De Kalb', value: 'De Kalb' },
  { label: 'De Kieviet', value: 'De Kieviet' },
  { label: 'De Knipe', value: 'De Knipe' },
  { label: 'De Koog', value: 'De Koog' },
  { label: 'De Kooy', value: 'De Kooy' },
  { label: 'De Kruiskamp', value: 'De Kruiskamp' },
  { label: 'De Kwakel', value: 'De Kwakel' },
  { label: 'De La Paz', value: 'De La Paz' },
  { label: 'De La Paz', value: 'De La Paz' },
  { label: 'De Land Southwest', value: 'De Land Southwest' },
  { label: 'De Leon', value: 'De Leon' },
  { label: 'De Leon Springs', value: 'De Leon Springs' },
  { label: 'De Lier', value: 'De Lier' },
  { label: 'De Lisle', value: 'De Lisle' },
  { label: 'De Loo', value: 'De Loo' },
  { label: 'De Maer', value: 'De Maer' },
  { label: 'De Mailly', value: 'De Mailly' },
  { label: 'De Mheen', value: 'De Mheen' },
  { label: 'De Noord', value: 'De Noord' },
  { label: 'De Panne', value: 'De Panne' },
  { label: 'De Parral', value: 'De Parral' },
  { label: 'De Pere', value: 'De Pere' },
  { label: 'De Peulen', value: 'De Peulen' },
  { label: 'De Pijp', value: 'De Pijp' },
  { label: 'De Pinte', value: 'De Pinte' },
  { label: 'De Queen', value: 'De Queen' },
  { label: 'De Reit', value: 'De Reit' },
  { label: 'De Rijp', value: 'De Rijp' },
  { label: 'De Rompert', value: 'De Rompert' },
  { label: 'De Rust', value: 'De Rust' },
  { label: 'De Salaberry', value: 'De Salaberry' },
  { label: 'De Smet', value: 'De Smet' },
  { label: 'De Soto', value: 'De Soto' },
  { label: 'De Soto', value: 'De Soto' },
  { label: 'De Soto', value: 'De Soto' },
  { label: 'De Soto', value: 'De Soto' },
  { label: 'De Soto County', value: 'De Soto County' },
  { label: 'De Soto Parish', value: 'De Soto Parish' },
  { label: 'De Stolpen', value: 'De Stolpen' },
  { label: 'De Uithof', value: 'De Uithof' },
  { label: 'De Waal', value: 'De Waal' },
  { label: 'De Wallen', value: 'De Wallen' },
  { label: 'De Weere', value: 'De Weere' },
  { label: 'De Westereen', value: 'De Westereen' },
  { label: 'De Wijert', value: 'De Wijert' },
  { label: 'De Wijk', value: 'De Wijk' },
  { label: 'De Witt', value: 'De Witt' },
  { label: 'De Witt', value: 'De Witt' },
  { label: 'De Witt County', value: 'De Witt County' },
  { label: 'De Woude', value: 'De Woude' },
  { label: 'Deadwood', value: 'Deadwood' },
  { label: 'Deaf Smith County', value: 'Deaf Smith County' },
  { label: 'Deag', value: 'Deag' },
  { label: 'Deagon', value: 'Deagon' },
  { label: 'Deaj', value: 'Deaj' },
  { label: 'Deakin', value: 'Deakin' },
  { label: 'Deal', value: 'Deal' },
  { label: 'Deale', value: 'Deale' },
  { label: 'Dealu', value: 'Dealu' },
  { label: 'Dealu', value: 'Dealu' },
  { label: 'Dealu Aluniș', value: 'Dealu Aluniș' },
  { label: 'Dealu Corbului', value: 'Dealu Corbului' },
  { label: 'Dealu Dănicei', value: 'Dealu Dănicei' },
  { label: 'Dealu Frumos', value: 'Dealu Frumos' },
  { label: 'Dealu Mare', value: 'Dealu Mare' },
  { label: 'Dealu Mare', value: 'Dealu Mare' },
  { label: 'Dealu Morii', value: 'Dealu Morii' },
  { label: 'Deán Funes', value: 'Deán Funes' },
  { label: 'Dean Park', value: 'Dean Park' },
  { label: 'Deanshanger', value: 'Deanshanger' },
  { label: 'Dearborn', value: 'Dearborn' },
  { label: 'Dearborn County', value: 'Dearborn County' },
  { label: 'Dearborn Heights', value: 'Dearborn Heights' },
  { label: 'Dearham', value: 'Dearham' },
  { label: 'Deatsville', value: 'Deatsville' },
  { label: 'Deauville', value: 'Deauville' },
  { label: 'Deba', value: 'Deba' },
  { label: 'Deba', value: 'Deba' },
  { label: 'Debagram', value: 'Debagram' },
  { label: 'Debal’Tseve', value: 'Debal’Tseve' },
  { label: 'Debant', value: 'Debant' },
  { label: 'Debark’', value: 'Debark’' },
  { label: 'Debary', value: 'Debary' },
  { label: 'Debdou', value: 'Debdou' },
  { label: 'Debe', value: 'Debe' },
  { label: 'Dębe Wielkie', value: 'Dębe Wielkie' },
  { label: 'Debelets', value: 'Debelets' },
  { label: 'Debeljača', value: 'Debeljača' },
  { label: 'Debenham', value: 'Debenham' },
  { label: 'Debesy', value: 'Debesy' },
  { label: 'Dębica', value: 'Dębica' },
  { label: 'Debila', value: 'Debila' },
  { label: 'Debipur', value: 'Debipur' },
  { label: 'Dęblin', value: 'Dęblin' },
  { label: 'Dębnica Kaszubska', value: 'Dębnica Kaszubska' },
  { label: 'Dębno', value: 'Dębno' },
  { label: 'Dębno', value: 'Dębno' },
  { label: 'Dębno', value: 'Dębno' },
  { label: 'Dębów', value: 'Dębów' },
  { label: 'Dębowa Kłoda', value: 'Dębowa Kłoda' },
  { label: 'Dębowa Łąka', value: 'Dębowa Łąka' },
  { label: 'Dębowiec', value: 'Dębowiec' },
  { label: 'Dębowiec', value: 'Dębowiec' },
  { label: 'Debre Birhan', value: 'Debre Birhan' },
  { label: 'Debre Mark’Os', value: 'Debre Mark’Os' },
  { label: 'Debre Sīna', value: 'Debre Sīna' },
  { label: 'Debre Tabor', value: 'Debre Tabor' },
  { label: 'Debre Werk’', value: 'Debre Werk’' },
  { label: 'Debrecen', value: 'Debrecen' },
  { label: 'Debreceni Járás', value: 'Debreceni Járás' },
  { label: 'Debreuil', value: 'Debreuil' },
  { label: 'Debrzno', value: 'Debrzno' },
  { label: 'Decatur', value: 'Decatur' },
  { label: 'Decatur', value: 'Decatur' },
  { label: 'Decatur', value: 'Decatur' },
  { label: 'Decatur', value: 'Decatur' },
  { label: 'Decatur', value: 'Decatur' },
  { label: 'Decatur', value: 'Decatur' },
  { label: 'Decatur', value: 'Decatur' },
  { label: 'Decatur', value: 'Decatur' },
  { label: 'Decatur', value: 'Decatur' },
  { label: 'Decatur', value: 'Decatur' },
  { label: 'Decatur County', value: 'Decatur County' },
  { label: 'Decatur County', value: 'Decatur County' },
  { label: 'Decatur County', value: 'Decatur County' },
  { label: 'Decatur County', value: 'Decatur County' },
  { label: 'Decatur County', value: 'Decatur County' },
  { label: 'Decaturville', value: 'Decaturville' },
  { label: 'Decazeville', value: 'Decazeville' },
  { label: 'Deccan Gymkhana', value: 'Deccan Gymkhana' },
  { label: 'Decebal', value: 'Decebal' },
  { label: 'Deception Bay', value: 'Deception Bay' },
  { label: 'Dechantskirchen', value: 'Dechantskirchen' },
  { label: 'Decherd', value: 'Decherd' },
  { label: 'Dechy', value: 'Dechy' },
  { label: 'Decima', value: 'Decima' },
  { label: 'Decimomannu', value: 'Decimomannu' },
  { label: 'Decimoputzu', value: 'Decimoputzu' },
  { label: 'Děčín', value: 'Děčín' },
  { label: 'Decindeni', value: 'Decindeni' },
  { label: 'Décines-Charpieu', value: 'Décines-Charpieu' },
  { label: 'Decize', value: 'Decize' },
  { label: 'Deckenpfronn', value: 'Deckenpfronn' },
  { label: 'Decollatura', value: 'Decollatura' },
  { label: 'Decorah', value: 'Decorah' },
  { label: 'Decordova', value: 'Decordova' },
  { label: 'Decs', value: 'Decs' },
  { label: 'Deda', value: 'Deda' },
  { label: 'Deddington', value: 'Deddington' },
  { label: 'Dedelstorf', value: 'Dedelstorf' },
  { label: 'Dedenëvo', value: 'Dedenëvo' },
  { label: 'Deder', value: 'Deder' },
  { label: 'Dedham', value: 'Dedham' },
  { label: 'Dedham', value: 'Dedham' },
  { label: 'Dedham', value: 'Dedham' },
  { label: 'Dédougou', value: 'Dédougou' },
  { label: 'Dedovichi', value: 'Dedovichi' },
  { label: 'Dedovsk', value: 'Dedovsk' },
  { label: 'Dedrad', value: 'Dedrad' },
  { label: 'Dedulești', value: 'Dedulești' },
  { label: 'Dedulești', value: 'Dedulești' },
  { label: 'Dedza', value: 'Dedza' },
  { label: 'Dedza District', value: 'Dedza District' },
  { label: 'Dee Why', value: 'Dee Why' },
  { label: 'Deebing Heights', value: 'Deebing Heights' },
  { label: 'Deensen', value: 'Deensen' },
  { label: 'Deenwood', value: 'Deenwood' },
  { label: 'Deep River', value: 'Deep River' },
  { label: 'Deep River Center', value: 'Deep River Center' },
  { label: 'Deephaven', value: 'Deephaven' },
  { label: 'Deer Isle', value: 'Deer Isle' },
  { label: 'Deer Lake', value: 'Deer Lake' },
  { label: 'Deer Lodge', value: 'Deer Lodge' },
  { label: 'Deer Lodge County', value: 'Deer Lodge County' },
  { label: 'Deer Park', value: 'Deer Park' },
  { label: 'Deer Park', value: 'Deer Park' },
  { label: 'Deer Park', value: 'Deer Park' },
  { label: 'Deer Park', value: 'Deer Park' },
  { label: 'Deer Park', value: 'Deer Park' },
  { label: 'Deer Park', value: 'Deer Park' },
  { label: 'Deer Park', value: 'Deer Park' },
  { label: 'Deer Valley', value: 'Deer Valley' },
  { label: 'Deeragun', value: 'Deeragun' },
  { label: 'Deerfield', value: 'Deerfield' },
  { label: 'Deerfield', value: 'Deerfield' },
  { label: 'Deerfield', value: 'Deerfield' },
  { label: 'Deerfield Beach', value: 'Deerfield Beach' },
  { label: 'Deering', value: 'Deering' },
  { label: 'Deerlijk', value: 'Deerlijk' },
  { label: 'Deeside', value: 'Deeside' },
  { label: 'Deeside', value: 'Deeside' },
  { label: 'Deest', value: 'Deest' },
  { label: 'Defiance', value: 'Defiance' },
  { label: 'Defiance County', value: 'Defiance County' },
  { label: 'Defne', value: 'Defne' },
  { label: 'Defuniak Springs', value: 'Defuniak Springs' },
  { label: 'Dég', value: 'Dég' },
  { label: 'Degache', value: 'Degache' },
  { label: 'Degaña', value: 'Degaña' },
  { label: 'Deganwy', value: 'Deganwy' },
  { label: 'Degeberga', value: 'Degeberga' },
  { label: 'Degehabur Zone', value: 'Degehabur Zone' },
  { label: 'Degerfors', value: 'Degerfors' },
  { label: 'Degersheim', value: 'Degersheim' },
  { label: 'Deggendorf', value: 'Deggendorf' },
  { label: 'Deggingen', value: 'Deggingen' },
  { label: 'Deglos', value: 'Deglos' },
  { label: 'Deglur', value: 'Deglur' },
  { label: 'Dego', value: 'Dego' },
  { label: 'Degollado', value: 'Degollado' },
  { label: 'Degtyarsk', value: 'Degtyarsk' },
  { label: 'Deh Chaneh', value: 'Deh Chaneh' },
  { label: 'Deh Dalian', value: 'Deh Dalian' },
  { label: 'Deh Ful', value: 'Deh Ful' },
  { label: 'Deh Mianeh', value: 'Deh Mianeh' },
  { label: 'Deh Nush', value: 'Deh Nush' },
  { label: 'Deh Piaz', value: 'Deh Piaz' },
  { label: 'Dehagh', value: 'Dehagh' },
  { label: 'Dehaghan', value: 'Dehaghan' },
  { label: 'Dehaj', value: 'Dehaj' },
  { label: 'Dehang', value: 'Dehang' },
  { label: 'Dehaqan', value: 'Dehaqan' },
  { label: 'Dehbārez', value: 'Dehbārez' },
  { label: 'Dehdasht', value: 'Dehdasht' },
  { label: 'Dehdez', value: 'Dehdez' },
  { label: 'Deh-E Shaker', value: 'Deh-E Shaker' },
  { label: 'Dehesa', value: 'Dehesa' },
  { label: 'Dehesa De Montejo', value: 'Dehesa De Montejo' },
  { label: 'Dehesa De Romanos', value: 'Dehesa De Romanos' },
  { label: 'Dehesas De Guadix', value: 'Dehesas De Guadix' },
  { label: 'Dehgolan', value: 'Dehgolan' },
  { label: 'Dehiwala-Mount Lavinia', value: 'Dehiwala-Mount Lavinia' },
  { label: 'Dehloran', value: 'Dehloran' },
  { label: 'Dehnow-E Avarzaman', value: 'Dehnow-E Avarzaman' },
  { label: 'Dehong Daizu Jingpozu Zizhizhou', value: 'Dehong Daizu Jingpozu Zizhizhou' },
  { label: 'Dehradun', value: 'Dehradun' },
  { label: 'Dehram', value: 'Dehram' },
  { label: 'Dehri', value: 'Dehri' },
  { label: 'Dehrn', value: 'Dehrn' },
  { label: 'Dehu', value: 'Dehu' },
  { label: 'Dehui', value: 'Dehui' },
  { label: 'Dei', value: 'Dei' },
  { label: 'Deià', value: 'Deià' },
  { label: 'Deidesheim', value: 'Deidesheim' },
  { label: 'Deifontes', value: 'Deifontes' },
  { label: 'Deil', value: 'Deil' },
  { label: 'Deilingen', value: 'Deilingen' },
  { label: 'Deining', value: 'Deining' },
  { label: 'Deiningen', value: 'Deiningen' },
  { label: 'Deiniolen', value: 'Deiniolen' },
  { label: 'Deinste', value: 'Deinste' },
  { label: 'Deinze', value: 'Deinze' },
  { label: 'Deir Ez-Zor', value: 'Deir Ez-Zor' },
  { label: 'Deir Ḥannā', value: 'Deir Ḥannā' },
  { label: 'Deisenhausen', value: 'Deisenhausen' },
  { label: 'Deißlingen', value: 'Deißlingen' },
  { label: 'Deitingen', value: 'Deitingen' },
  { label: 'Deiva Marina', value: 'Deiva Marina' },
  { label: 'Deizisau', value: 'Deizisau' },
  { label: 'Dej', value: 'Dej' },
  { label: 'Deja', value: 'Deja' },
  { label: 'Deje', value: 'Deje' },
  { label: 'Dejen', value: 'Dejen' },
  { label: 'Dejești', value: 'Dejești' },
  { label: 'Dek’Emhāre', value: 'Dek’Emhāre' },
  { label: 'Dekalb', value: 'Dekalb' },
  { label: 'Dekalb County', value: 'Dekalb County' },
  { label: 'Dekalb County', value: 'Dekalb County' },
  { label: 'Dekalb County', value: 'Dekalb County' },
  { label: 'Dekalb County', value: 'Dekalb County' },
  { label: 'Dekalb County', value: 'Dekalb County' },
  { label: 'Dekalb County', value: 'Dekalb County' },
  { label: 'Dekani', value: 'Dekani' },
  { label: 'Dekanovec', value: 'Dekanovec' },
  { label: 'Dekar', value: 'Dekar' },
  { label: 'De-Kastri', value: 'De-Kastri' },
  { label: 'Dekina', value: 'Dekina' },
  { label: 'Del Aire', value: 'Del Aire' },
  { label: 'Del Campillo', value: 'Del Campillo' },
  { label: 'Del Carmen', value: 'Del Carmen' },
  { label: 'Del Carmen', value: 'Del Carmen' },
  { label: 'Del Carmen', value: 'Del Carmen' },
  { label: 'Del Carmen Surigao Del Norte', value: 'Del Carmen Surigao Del Norte' },
  { label: 'Del City', value: 'Del City' },
  { label: 'Del Gallego', value: 'Del Gallego' },
  { label: 'Del Mar', value: 'Del Mar' },
  { label: 'Del Monte', value: 'Del Monte' },
  { label: 'Del Monte', value: 'Del Monte' },
  { label: 'Del Monte Forest', value: 'Del Monte Forest' },
  { label: 'Del Norte', value: 'Del Norte' },
  { label: 'Del Norte County', value: 'Del Norte County' },
  { label: 'Del Pilar', value: 'Del Pilar' },
  { label: 'Del Pilar', value: 'Del Pilar' },
  { label: 'Del Pilar', value: 'Del Pilar' },
  { label: 'Del Pilar', value: 'Del Pilar' },
  { label: 'Del Pilar', value: 'Del Pilar' },
  { label: 'Del Pilar', value: 'Del Pilar' },
  { label: 'Del Pilar', value: 'Del Pilar' },
  { label: 'Del Rey', value: 'Del Rey' },
  { label: 'Del Rey Oaks', value: 'Del Rey Oaks' },
  { label: 'Del Rio', value: 'Del Rio' },
  { label: 'Del Rio', value: 'Del Rio' },
  { label: 'Del Rosario', value: 'Del Rosario' },
  { label: 'Delabole', value: 'Delabole' },
  { label: 'Delacombe', value: 'Delacombe' },
  { label: 'Delacree Park/ Union Gardens', value: 'Delacree Park/ Union Gardens' },
  { label: 'Delacree Pen', value: 'Delacree Pen' },
  { label: 'Delafield', value: 'Delafield' },
  { label: 'Delahey', value: 'Delahey' },
  { label: 'Delaide', value: 'Delaide' },
  { label: 'Delanco', value: 'Delanco' },
  { label: 'Deland', value: 'Deland' },
  { label: 'Delaneys Creek', value: 'Delaneys Creek' },
  { label: 'Delanggu', value: 'Delanggu' },
  { label: 'Delano', value: 'Delano' },
  { label: 'Delano', value: 'Delano' },
  { label: 'Delavan', value: 'Delavan' },
  { label: 'Delavan', value: 'Delavan' },
  { label: 'Delavan Lake', value: 'Delavan Lake' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Delaware City', value: 'Delaware City' },
  { label: 'Delaware County', value: 'Delaware County' },
  { label: 'Delaware County', value: 'Delaware County' },
  { label: 'Delaware County', value: 'Delaware County' },
  { label: 'Delaware County', value: 'Delaware County' },
  { label: 'Delaware County', value: 'Delaware County' },
  { label: 'Delaware County', value: 'Delaware County' },
  { label: 'Delbaran', value: 'Delbaran' },
  { label: 'Delbrück', value: 'Delbrück' },
  { label: 'Delcambre', value: 'Delcambre' },
  { label: 'Delcer', value: 'Delcer' },
  { label: 'Delcevo', value: 'Delcevo' },
  { label: 'Delden', value: 'Delden' },
  { label: 'Delebio', value: 'Delebio' },
  { label: 'Delegación Cuajimalpa De Morelos', value: 'Delegación Cuajimalpa De Morelos' },
  { label: 'Délegyháza', value: 'Délegyháza' },
  { label: 'Deleitosa', value: 'Deleitosa' },
  { label: 'Đelekovec', value: 'Đelekovec' },
  { label: 'Delémont', value: 'Delémont' },
  { label: 'Delémont District', value: 'Delémont District' },
  { label: 'Deleni', value: 'Deleni' },
  { label: 'Deleni', value: 'Deleni' },
  { label: 'Deleni', value: 'Deleni' },
  { label: 'Deleni', value: 'Deleni' },
  { label: 'Deleni', value: 'Deleni' },
  { label: 'Deleşti', value: 'Deleşti' },
  { label: 'Delevan', value: 'Delevan' },
  { label: 'Delfan', value: 'Delfan' },
  { label: 'Delfim Moreira', value: 'Delfim Moreira' },
  { label: 'Delfin Albano', value: 'Delfin Albano' },
  { label: 'Delfino Victoria (Santa Fe)', value: 'Delfino Victoria (Santa Fe)' },
  { label: 'Delfinópolis', value: 'Delfinópolis' },
  { label: 'Delfshaven', value: 'Delfshaven' },
  { label: 'Delft', value: 'Delft' },
  { label: 'Delfzijl', value: 'Delfzijl' },
  { label: 'Delgado', value: 'Delgado' },
  { label: 'Delgado De Abajo', value: 'Delgado De Abajo' },
  { label: 'Delgado De Arriba', value: 'Delgado De Arriba' },
  { label: 'Delgosha', value: 'Delgosha' },
  { label: 'Delhi', value: 'Delhi' },
  { label: 'Delhi', value: 'Delhi' },
  { label: 'Delhi', value: 'Delhi' },
  { label: 'Delhi', value: 'Delhi' },
  { label: 'Delhi Hills', value: 'Delhi Hills' },
  { label: 'Deli Tua', value: 'Deli Tua' },
  { label: 'Delia', value: 'Delia' },
  { label: 'Delianuova', value: 'Delianuova' },
  { label: 'Delice', value: 'Delice' },
  { label: 'Deliceto', value: 'Deliceto' },
  { label: 'Delicias', value: 'Delicias' },
  { label: 'Delicias', value: 'Delicias' },
  { label: 'Delijan', value: 'Delijan' },
  { label: 'Delingha', value: 'Delingha' },
  { label: 'Delingsdorf', value: 'Delingsdorf' },
  { label: 'Delitzsch', value: 'Delitzsch' },
  { label: 'Dell Rapids', value: 'Dell Rapids' },
  { label: 'Dellach', value: 'Dellach' },
  { label: 'Dellach Im Drautal', value: 'Dellach Im Drautal' },
  { label: 'Delle', value: 'Delle' },
  { label: 'Dellfeld', value: 'Dellfeld' },
  { label: 'Delligsen', value: 'Delligsen' },
  { label: 'Dello', value: 'Dello' },
  { label: 'Dellok', value: 'Dellok' },
  { label: 'Dellwood', value: 'Dellwood' },
  { label: 'Dellwood', value: 'Dellwood' },
  { label: 'Dellys', value: 'Dellys' },
  { label: 'Delmar', value: 'Delmar' },
  { label: 'Delmar', value: 'Delmar' },
  { label: 'Delmas', value: 'Delmas' },
  { label: 'Delmenhorst', value: 'Delmenhorst' },
  { label: 'Delmiro Gouveia', value: 'Delmiro Gouveia' },
  { label: 'Delmont', value: 'Delmont' },
  { label: 'Delnice', value: 'Delnice' },
  { label: 'Delogožda', value: 'Delogožda' },
  { label: 'Delomel', value: 'Delomel' },
  { label: 'Deloraine', value: 'Deloraine' },
  { label: 'Deloraine', value: 'Deloraine' },
  { label: 'Delph', value: 'Delph' },
  { label: 'Delphi', value: 'Delphi' },
  { label: 'Delphi', value: 'Delphi' },
  { label: 'Delphos', value: 'Delphos' },
  { label: 'Delray Beach', value: 'Delray Beach' },
  { label: 'Delsbo', value: 'Delsbo' },
  { label: 'Delson', value: 'Delson' },
  { label: 'Delta', value: 'Delta' },
  { label: 'Delta', value: 'Delta' },
  { label: 'Delta', value: 'Delta' },
  { label: 'Delta', value: 'Delta' },
  { label: 'Delta', value: 'Delta' },
  { label: 'Delta', value: 'Delta' },
  { label: 'Delta County', value: 'Delta County' },
  { label: 'Delta County', value: 'Delta County' },
  { label: 'Delta County', value: 'Delta County' },
  { label: 'Delta Del Tigre', value: 'Delta Del Tigre' },
  { label: 'Deltana', value: 'Deltana' },
  { label: 'Deltaville', value: 'Deltaville' },
  { label: 'Deltebre', value: 'Deltebre' },
  { label: 'Delthota', value: 'Delthota' },
  { label: 'Deltona', value: 'Deltona' },
  { label: 'Delvada', value: 'Delvada' },
  { label: 'Delvar', value: 'Delvar' },
  { label: 'Delveland', value: 'Delveland' },
  { label: 'Delvinë', value: 'Delvinë' },
  { label: 'Delwada', value: 'Delwada' },
  { label: 'Delyatyn', value: 'Delyatyn' },
  { label: 'Dem’Yanovo', value: 'Dem’Yanovo' },
  { label: 'Demacușa', value: 'Demacușa' },
  { label: 'Demak', value: 'Demak' },
  { label: 'Demarest', value: 'Demarest' },
  { label: 'Demba Kunda', value: 'Demba Kunda' },
  { label: 'Dembéni', value: 'Dembéni' },
  { label: 'Dembī Dolo', value: 'Dembī Dolo' },
  { label: 'Demecser', value: 'Demecser' },
  { label: 'Demen', value: 'Demen' },
  { label: 'Demerval Lobão', value: 'Demerval Lobão' },
  { label: 'Demidov', value: 'Demidov' },
  { label: 'Demigny', value: 'Demigny' },
  { label: 'Demikhovo', value: 'Demikhovo' },
  { label: 'Deming', value: 'Deming' },
  { label: 'Demir Hisar', value: 'Demir Hisar' },
  { label: 'Demir Kapija', value: 'Demir Kapija' },
  { label: 'Demirci', value: 'Demirci' },
  { label: 'Demirköy', value: 'Demirköy' },
  { label: 'Demirözü', value: 'Demirözü' },
  { label: 'Demitz-Thumitz', value: 'Demitz-Thumitz' },
  { label: 'Demmin', value: 'Demmin' },
  { label: 'Demnate', value: 'Demnate' },
  { label: 'Demnya', value: 'Demnya' },
  { label: 'Demonte', value: 'Demonte' },
  { label: 'Demopolis', value: 'Demopolis' },
  { label: 'Demorest', value: 'Demorest' },
  { label: 'Demotte', value: 'Demotte' },
  { label: 'Démouville', value: 'Démouville' },
  { label: 'Demre', value: 'Demre' },
  { label: 'Demyansk', value: 'Demyansk' },
  { label: 'Demyanskiy Rayon', value: 'Demyanskiy Rayon' },
  { label: 'Demydivs’Kyy Rayon', value: 'Demydivs’Kyy Rayon' },
  { label: 'Den Burg', value: 'Den Burg' },
  { label: 'Den Chai', value: 'Den Chai' },
  { label: 'Den Dungen', value: 'Den Dungen' },
  { label: 'Den Ham', value: 'Den Ham' },
  { label: 'Den Helder', value: 'Den Helder' },
  { label: 'Den Hoorn', value: 'Den Hoorn' },
  { label: 'Den Ilp', value: 'Den Ilp' },
  { label: 'Den Oever', value: 'Den Oever' },
  { label: 'Denain', value: 'Denain' },
  { label: 'Denair', value: 'Denair' },
  { label: 'Denali Borough', value: 'Denali Borough' },
  { label: 'Denbigh', value: 'Denbigh' },
  { label: 'Denbighshire', value: 'Denbighshire' },
  { label: 'Denby Dale', value: 'Denby Dale' },
  { label: 'Denchukha', value: 'Denchukha' },
  { label: 'Denderleeuw', value: 'Denderleeuw' },
  { label: 'Dendermonde', value: 'Dendermonde' },
  { label: 'Denée', value: 'Denée' },
  { label: 'Deneysville', value: 'Deneysville' },
  { label: 'Dengantzha', value: 'Dengantzha' },
  { label: 'Dengi', value: 'Dengi' },
  { label: 'Dengjiapu', value: 'Dengjiapu' },
  { label: 'Denguin', value: 'Denguin' },
  { label: 'Dengying', value: 'Dengying' },
  { label: 'Dengyuantai', value: 'Dengyuantai' },
  { label: 'Dengzhou', value: 'Dengzhou' },
  { label: 'Denham', value: 'Denham' },
  { label: 'Denham', value: 'Denham' },
  { label: 'Denham Court', value: 'Denham Court' },
  { label: 'Denham Springs', value: 'Denham Springs' },
  { label: 'Denham Town', value: 'Denham Town' },
  { label: 'Denholme', value: 'Denholme' },
  { label: 'Denia', value: 'Denia' },
  { label: 'Denice', value: 'Denice' },
  { label: 'Denicé', value: 'Denicé' },
  { label: 'Deniliquin', value: 'Deniliquin' },
  { label: 'Denise', value: 'Denise' },
  { label: 'Denison', value: 'Denison' },
  { label: 'Denison', value: 'Denison' },
  { label: 'Denistone', value: 'Denistone' },
  { label: 'Denistone East', value: 'Denistone East' },
  { label: 'Denjhi', value: 'Denjhi' },
  { label: 'Denkanikota', value: 'Denkanikota' },
  { label: 'Denkendorf', value: 'Denkendorf' },
  { label: 'Denkingen', value: 'Denkingen' },
  { label: 'Denklingen', value: 'Denklingen' },
  { label: 'Denman', value: 'Denman' },
  { label: 'Denman Island', value: 'Denman Island' },
  { label: 'Denman Island Trust Area', value: 'Denman Island Trust Area' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Denmead', value: 'Denmead' },
  { label: 'Dennery', value: 'Dennery' },
  { label: 'Dennery By Pass', value: 'Dennery By Pass' },
  { label: 'Dennery By Pass/Green Mountain', value: 'Dennery By Pass/Green Mountain' },
  { label: 'Dennery By Pass/Rocky Lane', value: 'Dennery By Pass/Rocky Lane' },
  { label: 'Dennery By Pass/White Rock Gardens', value: 'Dennery By Pass/White Rock Gardens' },
  { label: 'Dennery Village', value: 'Dennery Village' },
  { label: 'Dennheritz', value: 'Dennheritz' },
  { label: 'Dennington', value: 'Dennington' },
  { label: 'Dennis', value: 'Dennis' },
  { label: 'Dennis Port', value: 'Dennis Port' },
  { label: 'Dennison', value: 'Dennison' },
  { label: 'Denno', value: 'Denno' },
  { label: 'Denny', value: 'Denny' },
  { label: 'Dennyloanhead', value: 'Dennyloanhead' },
  { label: 'Denov', value: 'Denov' },
  { label: 'Denpasar', value: 'Denpasar' },
  { label: 'Densbüren', value: 'Densbüren' },
  { label: 'Densuş', value: 'Densuş' },
  { label: 'Dent', value: 'Dent' },
  { label: 'Dent County', value: 'Dent County' },
  { label: 'Denta', value: 'Denta' },
  { label: 'Dentergem', value: 'Dentergem' },
  { label: 'Dentlein Am Forst', value: 'Dentlein Am Forst' },
  { label: 'Denton', value: 'Denton' },
  { label: 'Denton', value: 'Denton' },
  { label: 'Denton', value: 'Denton' },
  { label: 'Denton', value: 'Denton' },
  { label: 'Denton', value: 'Denton' },
  { label: 'Denton County', value: 'Denton County' },
  { label: 'Denton Holme', value: 'Denton Holme' },
  { label: 'Dentsville', value: 'Dentsville' },
  { label: 'Denver', value: 'Denver' },
  { label: 'Denver', value: 'Denver' },
  { label: 'Denver', value: 'Denver' },
  { label: 'Denver', value: 'Denver' },
  { label: 'Denver City', value: 'Denver City' },
  { label: 'Denver County', value: 'Denver County' },
  { label: 'Denyshi', value: 'Denyshi' },
  { label: 'Denyshi', value: 'Denyshi' },
  { label: 'Denzlingen', value: 'Denzlingen' },
  { label: 'Deoband', value: 'Deoband' },
  { label: 'Deodápolis', value: 'Deodápolis' },
  { label: 'Deogarh', value: 'Deogarh' },
  { label: 'Deogarh', value: 'Deogarh' },
  { label: 'Deogarh', value: 'Deogarh' },
  { label: 'Deolali', value: 'Deolali' },
  { label: 'Deolapar', value: 'Deolapar' },
  { label: 'Deoli', value: 'Deoli' },
  { label: 'Deoli', value: 'Deoli' },
  { label: 'Deoli', value: 'Deoli' },
  { label: 'Déols', value: 'Déols' },
  { label: 'Deoni', value: 'Deoni' },
  { label: 'Deoranian', value: 'Deoranian' },
  { label: 'Deori', value: 'Deori' },
  { label: 'Deori Khas', value: 'Deori Khas' },
  { label: 'Deoria', value: 'Deoria' },
  { label: 'Depalpur', value: 'Depalpur' },
  { label: 'Deparați', value: 'Deparați' },
  { label: 'Departamento Capital', value: 'Departamento Capital' },
  { label: 'Departamento De Aguirre', value: 'Departamento De Aguirre' },
  { label: 'Departamento De Albardón', value: 'Departamento De Albardón' },
  { label: 'Departamento De Almirante Brown', value: 'Departamento De Almirante Brown' },
  { label: 'Departamento De Aluminé', value: 'Departamento De Aluminé' },
  { label: 'Departamento De Ambato', value: 'Departamento De Ambato' },
  { label: 'Departamento De Ancasti', value: 'Departamento De Ancasti' },
  { label: 'Departamento De Andalgalá', value: 'Departamento De Andalgalá' },
  { label: 'Departamento De Añelo', value: 'Departamento De Añelo' },
  { label: 'Departamento De Angaco', value: 'Departamento De Angaco' },
  { label: 'Departamento De Anta', value: 'Departamento De Anta' },
  { label: 'Departamento De Antofagasta De La Sierra', value: 'Departamento De Antofagasta De La Sierra' },
  { label: 'Departamento De Apóstoles', value: 'Departamento De Apóstoles' },
  { label: 'Departamento De Arauco', value: 'Departamento De Arauco' },
  { label: 'Departamento De Avellaneda', value: 'Departamento De Avellaneda' },
  { label: 'Departamento De Banda', value: 'Departamento De Banda' },
  { label: 'Departamento De Belgrano', value: 'Departamento De Belgrano' },
  { label: 'Departamento De Bella Vista', value: 'Departamento De Bella Vista' },
  { label: 'Departamento De Bermejo', value: 'Departamento De Bermejo' },
  { label: 'Departamento De Berón De Astrada', value: 'Departamento De Berón De Astrada' },
  { label: 'Departamento De Biedma', value: 'Departamento De Biedma' },
  { label: 'Departamento De Burruyacú', value: 'Departamento De Burruyacú' },
  { label: 'Departamento De Cainguás', value: 'Departamento De Cainguás' },
  { label: 'Departamento De Calamuchita', value: 'Departamento De Calamuchita' },
  { label: 'Departamento De Caleu-Caleu', value: 'Departamento De Caleu-Caleu' },
  { label: 'Departamento De Calingasta', value: 'Departamento De Calingasta' },
  { label: 'Departamento De Candelaria', value: 'Departamento De Candelaria' },
  { label: 'Departamento De Capayán', value: 'Departamento De Capayán' },
  { label: 'Departamento De Capital', value: 'Departamento De Capital' },
  { label: 'Departamento De Capital', value: 'Departamento De Capital' },
  { label: 'Departamento De Capital', value: 'Departamento De Capital' },
  { label: 'Departamento De Capital', value: 'Departamento De Capital' },
  { label: 'Departamento De Capital', value: 'Departamento De Capital' },
  { label: 'Departamento De Capital', value: 'Departamento De Capital' },
  { label: 'Departamento De Caseros', value: 'Departamento De Caseros' },
  { label: 'Departamento De Castellanos', value: 'Departamento De Castellanos' },
  { label: 'Departamento De Catán-Lil', value: 'Departamento De Catán-Lil' },
  { label: 'Departamento De Caucete', value: 'Departamento De Caucete' },
  { label: 'Departamento De Cerrillos', value: 'Departamento De Cerrillos' },
  { label: 'Departamento De Chicoana', value: 'Departamento De Chicoana' },
  { label: 'Departamento De Chimbas', value: 'Departamento De Chimbas' },
  { label: 'Departamento De Chos-Malal', value: 'Departamento De Chos-Malal' },
  { label: 'Departamento De Choya', value: 'Departamento De Choya' },
  { label: 'Departamento De Cochinoca', value: 'Departamento De Cochinoca' },
  { label: 'Departamento De Collón-Curá', value: 'Departamento De Collón-Curá' },
  { label: 'Departamento De Colón', value: 'Departamento De Colón' },
  { label: 'Departamento De Comandante Fernández', value: 'Departamento De Comandante Fernández' },
  { label: 'Departamento De Concepción', value: 'Departamento De Concepción' },
  { label: 'Departamento De Concepción De La Sierra', value: 'Departamento De Concepción De La Sierra' },
  { label: 'Departamento De Confluencia', value: 'Departamento De Confluencia' },
  { label: 'Departamento De Constitución', value: 'Departamento De Constitución' },
  { label: 'Departamento De Cruz Alta', value: 'Departamento De Cruz Alta' },
  { label: 'Departamento De Cruz Del Eje', value: 'Departamento De Cruz Del Eje' },
  { label: 'Departamento De Curuzú Cuatiá', value: 'Departamento De Curuzú Cuatiá' },
  { label: 'Departamento De Cushamen', value: 'Departamento De Cushamen' },
  { label: 'Departamento De Deseado', value: 'Departamento De Deseado' },
  { label: 'Departamento De Doce De Octubre', value: 'Departamento De Doce De Octubre' },
  { label: 'Departamento De Dos De Abril', value: 'Departamento De Dos De Abril' },
  { label: 'Departamento De El Alto', value: 'Departamento De El Alto' },
  { label: 'Departamento De Eldorado', value: 'Departamento De Eldorado' },
  { label: 'Departamento De Empedrado', value: 'Departamento De Empedrado' },
  { label: 'Departamento De Escalante', value: 'Departamento De Escalante' },
  { label: 'Departamento De Esquina', value: 'Departamento De Esquina' },
  { label: 'Departamento De Famaillá', value: 'Departamento De Famaillá' },
  { label: 'Departamento De Florentino Ameghino', value: 'Departamento De Florentino Ameghino' },
  { label: 'Departamento De Fray Mamerto Esquiú', value: 'Departamento De Fray Mamerto Esquiú' },
  { label: 'Departamento De Futaleufú', value: 'Departamento De Futaleufú' },
  { label: 'Departamento De Gaimán', value: 'Departamento De Gaimán' },
  { label: 'Departamento De Gastre', value: 'Departamento De Gastre' },
  { label: 'Departamento De General Alvear', value: 'Departamento De General Alvear' },
  { label: 'Departamento De General Alvear', value: 'Departamento De General Alvear' },
  { label: 'Departamento De General Donovan', value: 'Departamento De General Donovan' },
  { label: 'Departamento De General Güemes', value: 'Departamento De General Güemes' },
  { label: 'Departamento De General Güemes', value: 'Departamento De General Güemes' },
  { label: 'Departamento De General Lamadrid', value: 'Departamento De General Lamadrid' },
  { label: 'Departamento De General Manuel Belgrano', value: 'Departamento De General Manuel Belgrano' },
  { label: 'Departamento De General Paz', value: 'Departamento De General Paz' },
  { label: 'Departamento De General Roca', value: 'Departamento De General Roca' },
  { label: 'Departamento De General San Martín', value: 'Departamento De General San Martín' },
  { label: 'Departamento De Godoy Cruz', value: 'Departamento De Godoy Cruz' },
  { label: 'Departamento De Goya', value: 'Departamento De Goya' },
  { label: 'Departamento De Graneros', value: 'Departamento De Graneros' },
  { label: 'Departamento De Guachipas', value: 'Departamento De Guachipas' },
  { label: 'Departamento De Gualeguaychú', value: 'Departamento De Gualeguaychú' },
  { label: 'Departamento De Guaraní', value: 'Departamento De Guaraní' },
  { label: 'Departamento De Guasayán', value: 'Departamento De Guasayán' },
  { label: 'Departamento De Guaymallén', value: 'Departamento De Guaymallén' },
  { label: 'Departamento De Güer Aike', value: 'Departamento De Güer Aike' },
  { label: 'Departamento De Iglesia', value: 'Departamento De Iglesia' },
  { label: 'Departamento De Iguazú', value: 'Departamento De Iguazú' },
  { label: 'Departamento De Independencia', value: 'Departamento De Independencia' },
  { label: 'Departamento De Independencia', value: 'Departamento De Independencia' },
  { label: 'Departamento De Iruya', value: 'Departamento De Iruya' },
  { label: 'Departamento De Itatí', value: 'Departamento De Itatí' },
  { label: 'Departamento De Ituzaingó', value: 'Departamento De Ituzaingó' },
  { label: 'Departamento De Jáchal', value: 'Departamento De Jáchal' },
  { label: 'Departamento De Juárez Celman', value: 'Departamento De Juárez Celman' },
  { label: 'Departamento De La Capital', value: 'Departamento De La Capital' },
  { label: 'Departamento De La Cocha', value: 'Departamento De La Cocha' },
  { label: 'Departamento De La Paz', value: 'Departamento De La Paz' },
  { label: 'Departamento De La Paz', value: 'Departamento De La Paz' },
  { label: 'Departamento De La Poma', value: 'Departamento De La Poma' },
  { label: 'Departamento De La Viña', value: 'Departamento De La Viña' },
  { label: 'Departamento De Lácar', value: 'Departamento De Lácar' },
  { label: 'Departamento De Lago Argentino', value: 'Departamento De Lago Argentino' },
  { label: 'Departamento De Languiñeo', value: 'Departamento De Languiñeo' },
  { label: 'Departamento De Las Heras', value: 'Departamento De Las Heras' },
  { label: 'Departamento De Lavalle', value: 'Departamento De Lavalle' },
  { label: 'Departamento De Lavalle', value: 'Departamento De Lavalle' },
  { label: 'Departamento De Leandro N. Alem', value: 'Departamento De Leandro N. Alem' },
  { label: 'Departamento De Libertad', value: 'Departamento De Libertad' },
  { label: 'Departamento De Libertador General San Martín', value: 'Departamento De Libertador General San Martín' },
  { label: 'Departamento De Loreto', value: 'Departamento De Loreto' },
  { label: 'Departamento De Los Andes', value: 'Departamento De Los Andes' },
  { label: 'Departamento De Luján', value: 'Departamento De Luján' },
  { label: 'Departamento De Lules', value: 'Departamento De Lules' },
  { label: 'Departamento De Magallanes', value: 'Departamento De Magallanes' },
  { label: 'Departamento De Maipú', value: 'Departamento De Maipú' },
  { label: 'Departamento De Maipú', value: 'Departamento De Maipú' },
  { label: 'Departamento De Malargüe', value: 'Departamento De Malargüe' },
  { label: 'Departamento De Marcos Juárez', value: 'Departamento De Marcos Juárez' },
  { label: 'Departamento De Mártires', value: 'Departamento De Mártires' },
  { label: 'Departamento De Mburucuyá', value: 'Departamento De Mburucuyá' },
  { label: 'Departamento De Mercedes', value: 'Departamento De Mercedes' },
  { label: 'Departamento De Metán', value: 'Departamento De Metán' },
  { label: 'Departamento De Minas', value: 'Departamento De Minas' },
  { label: 'Departamento De Minas', value: 'Departamento De Minas' },
  { label: 'Departamento De Monte Caseros', value: 'Departamento De Monte Caseros' },
  { label: 'Departamento De Montecarlo', value: 'Departamento De Montecarlo' },
  { label: 'Departamento De Monteros', value: 'Departamento De Monteros' },
  { label: 'Departamento De Moreno', value: 'Departamento De Moreno' },
  { label: 'Departamento De Nueve De Julio', value: 'Departamento De Nueve De Julio' },
  { label: 'Departamento De Nueve De Julio', value: 'Departamento De Nueve De Julio' },
  { label: 'Departamento De Nueve De Julio', value: 'Departamento De Nueve De Julio' },
  { label: 'Departamento De O’Higgins', value: 'Departamento De O’Higgins' },
  { label: 'Departamento De Oberá', value: 'Departamento De Oberá' },
  { label: 'Departamento De Paraná', value: 'Departamento De Paraná' },
  { label: 'Departamento De Paso De Indios', value: 'Departamento De Paso De Indios' },
  { label: 'Departamento De Paso De Los Libres', value: 'Departamento De Paso De Los Libres' },
  { label: 'Departamento De Pilcomayo', value: 'Departamento De Pilcomayo' },
  { label: 'Departamento De Pomán', value: 'Departamento De Pomán' },
  { label: 'Departamento De Presidencia De La Plaza', value: 'Departamento De Presidencia De La Plaza' },
  { label: 'Departamento De Presidente Roque Sáenz Peña', value: 'Departamento De Presidente Roque Sáenz Peña' },
  { label: 'Departamento De Quitilipi', value: 'Departamento De Quitilipi' },
  { label: 'Departamento De Rawson', value: 'Departamento De Rawson' },
  { label: 'Departamento De Rawson', value: 'Departamento De Rawson' },
  { label: 'Departamento De Rinconada', value: 'Departamento De Rinconada' },
  { label: 'Departamento De Río Chico', value: 'Departamento De Río Chico' },
  { label: 'Departamento De Río Chico', value: 'Departamento De Río Chico' },
  { label: 'Departamento De Río Cuarto', value: 'Departamento De Río Cuarto' },
  { label: 'Departamento De Río Hondo', value: 'Departamento De Río Hondo' },
  { label: 'Departamento De Río Primero', value: 'Departamento De Río Primero' },
  { label: 'Departamento De Río Seco', value: 'Departamento De Río Seco' },
  { label: 'Departamento De Río Segundo', value: 'Departamento De Río Segundo' },
  { label: 'Departamento De Río Senguerr', value: 'Departamento De Río Senguerr' },
  { label: 'Departamento De Rivadavia', value: 'Departamento De Rivadavia' },
  { label: 'Departamento De Rivadavia', value: 'Departamento De Rivadavia' },
  { label: 'Departamento De Rivadavia', value: 'Departamento De Rivadavia' },
  { label: 'Departamento De Robles', value: 'Departamento De Robles' },
  { label: 'Departamento De Rosario De La Frontera', value: 'Departamento De Rosario De La Frontera' },
  { label: 'Departamento De Rosario De Lerma', value: 'Departamento De Rosario De Lerma' },
  { label: 'Departamento De Saladas', value: 'Departamento De Saladas' },
  { label: 'Departamento De San Alberto', value: 'Departamento De San Alberto' },
  { label: 'Departamento De San Carlos', value: 'Departamento De San Carlos' },
  { label: 'Departamento De San Carlos', value: 'Departamento De San Carlos' },
  { label: 'Departamento De San Cosme', value: 'Departamento De San Cosme' },
  { label: 'Departamento De San Cristóbal', value: 'Departamento De San Cristóbal' },
  { label: 'Departamento De San Fernando', value: 'Departamento De San Fernando' },
  { label: 'Departamento De San Ignacio', value: 'Departamento De San Ignacio' },
  { label: 'Departamento De San Javier', value: 'Departamento De San Javier' },
  { label: 'Departamento De San Javier', value: 'Departamento De San Javier' },
  { label: 'Departamento De San Javier', value: 'Departamento De San Javier' },
  { label: 'Departamento De San Justo', value: 'Departamento De San Justo' },
  { label: 'Departamento De San Justo', value: 'Departamento De San Justo' },
  { label: 'Departamento De San Lorenzo', value: 'Departamento De San Lorenzo' },
  { label: 'Departamento De San Lorenzo', value: 'Departamento De San Lorenzo' },
  { label: 'Departamento De San Luis Del Palmar', value: 'Departamento De San Luis Del Palmar' },
  { label: 'Departamento De San Martín', value: 'Departamento De San Martín' },
  { label: 'Departamento De San Martín', value: 'Departamento De San Martín' },
  { label: 'Departamento De San Martín', value: 'Departamento De San Martín' },
  { label: 'Departamento De San Martín', value: 'Departamento De San Martín' },
  { label: 'Departamento De San Martín', value: 'Departamento De San Martín' },
  { label: 'Departamento De San Miguel', value: 'Departamento De San Miguel' },
  { label: 'Departamento De San Pedro', value: 'Departamento De San Pedro' },
  { label: 'Departamento De San Rafael', value: 'Departamento De San Rafael' },
  { label: 'Departamento De San Roque', value: 'Departamento De San Roque' },
  { label: 'Departamento De Santa Lucía', value: 'Departamento De Santa Lucía' },
  { label: 'Departamento De Santa María', value: 'Departamento De Santa María' },
  { label: 'Departamento De Santa Rosa', value: 'Departamento De Santa Rosa' },
  { label: 'Departamento De Santa Rosa', value: 'Departamento De Santa Rosa' },
  { label: 'Departamento De Santo Tomé', value: 'Departamento De Santo Tomé' },
  { label: 'Departamento De Sargento Cabral', value: 'Departamento De Sargento Cabral' },
  { label: 'Departamento De Sarmiento', value: 'Departamento De Sarmiento' },
  { label: 'Departamento De Sarmiento', value: 'Departamento De Sarmiento' },
  { label: 'Departamento De Sarmiento', value: 'Departamento De Sarmiento' },
  { label: 'Departamento De Sauce', value: 'Departamento De Sauce' },
  { label: 'Departamento De Simoca', value: 'Departamento De Simoca' },
  { label: 'Departamento De Sobremonte', value: 'Departamento De Sobremonte' },
  { label: 'Departamento De Tapenagá', value: 'Departamento De Tapenagá' },
  { label: 'Departamento De Tehuelches', value: 'Departamento De Tehuelches' },
  { label: 'Departamento De Telsen', value: 'Departamento De Telsen' },
  { label: 'Departamento De Tinogasta', value: 'Departamento De Tinogasta' },
  { label: 'Departamento De Toay', value: 'Departamento De Toay' },
  { label: 'Departamento De Totoral', value: 'Departamento De Totoral' },
  { label: 'Departamento De Trancas', value: 'Departamento De Trancas' },
  { label: 'Departamento De Tulumba', value: 'Departamento De Tulumba' },
  { label: 'Departamento De Tumbaya', value: 'Departamento De Tumbaya' },
  { label: 'Departamento De Tunuyán', value: 'Departamento De Tunuyán' },
  { label: 'Departamento De Tupungato', value: 'Departamento De Tupungato' },
  { label: 'Departamento De Ullúm', value: 'Departamento De Ullúm' },
  { label: 'Departamento De Unión', value: 'Departamento De Unión' },
  { label: 'Departamento De Valle Viejo', value: 'Departamento De Valle Viejo' },
  { label: 'Departamento De Veinticinco De Mayo', value: 'Departamento De Veinticinco De Mayo' },
  { label: 'Departamento De Veinticinco De Mayo', value: 'Departamento De Veinticinco De Mayo' },
  { label: 'Departamento De Vera', value: 'Departamento De Vera' },
  { label: 'Departamento De Yerba Buena', value: 'Departamento De Yerba Buena' },
  { label: 'Departamento De Zapala', value: 'Departamento De Zapala' },
  { label: 'Departamento De Zonda', value: 'Departamento De Zonda' },
  { label: 'Département D Illéla', value: 'Département D Illéla' },
  { label: 'Département D’Aguié', value: 'Département D’Aguié' },
  { label: 'Département De Bilma', value: 'Département De Bilma' },
  { label: 'Département De Birni Nkonni', value: 'Département De Birni Nkonni' },
  { label: 'Département De Bouza', value: 'Département De Bouza' },
  { label: 'Département De Dakoro', value: 'Département De Dakoro' },
  { label: 'Département De Diffa', value: 'Département De Diffa' },
  { label: 'Département De Dogondoutchi', value: 'Département De Dogondoutchi' },
  { label: 'Département De Dosso', value: 'Département De Dosso' },
  { label: 'Département De Filingué', value: 'Département De Filingué' },
  { label: 'Département De Gaya', value: 'Département De Gaya' },
  { label: 'Département De Gouré', value: 'Département De Gouré' },
  { label: 'Département De Kantché', value: 'Département De Kantché' },
  { label: 'Département De Keïta', value: 'Département De Keïta' },
  { label: 'Département De La Corrèze', value: 'Département De La Corrèze' },
  { label: 'Département De La Corse-Du-Sud', value: 'Département De La Corse-Du-Sud' },
  { label: 'Département De La Côte-Dor', value: 'Département De La Côte-Dor' },
  { label: 'Département De La Drôme', value: 'Département De La Drôme' },
  { label: 'Département De La Haute-Corse', value: 'Département De La Haute-Corse' },
  { label: 'Département De La Haute-Saône', value: 'Département De La Haute-Saône' },
  { label: 'Département De La Loire', value: 'Département De La Loire' },
  { label: 'Département De La Lozère', value: 'Département De La Lozère' },
  { label: 'Département De La Nièvre', value: 'Département De La Nièvre' },
  { label: 'Département De La Vendée', value: 'Département De La Vendée' },
  { label: 'Département De Lain', value: 'Département De Lain' },
  { label: 'Département De Laisne', value: 'Département De Laisne' },
  { label: 'Département De Lallier', value: 'Département De Lallier' },
  { label: 'Département De Lardèche', value: 'Département De Lardèche' },
  { label: 'Département De Lariège', value: 'Département De Lariège' },
  { label: 'Département De Laube', value: 'Département De Laube' },
  { label: 'Département De Laude', value: 'Département De Laude' },
  { label: 'Département De Laveyron', value: 'Département De Laveyron' },
  { label: 'Département De Lessonne', value: 'Département De Lessonne' },
  { label: 'Département De Leure', value: 'Département De Leure' },
  { label: 'Département De Lhérault', value: 'Département De Lhérault' },
  { label: 'Département De Lindre', value: 'Département De Lindre' },
  { label: 'Département De Lisère', value: 'Département De Lisère' },
  { label: 'Département De Loga', value: 'Département De Loga' },
  { label: 'Département De Loise', value: 'Département De Loise' },
  { label: 'Département De Lorne', value: 'Département De Lorne' },
  { label: 'Département De Lyonne', value: 'Département De Lyonne' },
  { label: 'Département De Madaoua', value: 'Département De Madaoua' },
  { label: 'Département De Maine-Et-Loire', value: 'Département De Maine-Et-Loire' },
  { label: 'Département De Maïné-Soroa', value: 'Département De Maïné-Soroa' },
  { label: 'Département De Mayahi', value: 'Département De Mayahi' },
  { label: 'Département De Nguigmi', value: 'Département De Nguigmi' },
  { label: 'Département De Ouallam', value: 'Département De Ouallam' },
  { label: 'Département De Salémata', value: 'Département De Salémata' },
  { label: 'Département De Saône-Et-Loire', value: 'Département De Saône-Et-Loire' },
  { label: 'Département De Say', value: 'Département De Say' },
  { label: 'Département De Tahoua', value: 'Département De Tahoua' },
  { label: 'Département De Tânout', value: 'Département De Tânout' },
  { label: 'Département De Tchin-Tabaraden', value: 'Département De Tchin-Tabaraden' },
  { label: 'Département De Tchirozérine', value: 'Département De Tchirozérine' },
  { label: 'Département De Tessaoua', value: 'Département De Tessaoua' },
  { label: 'Département Des Bouches-Du-Rhône', value: 'Département Des Bouches-Du-Rhône' },
  { label: 'Département Des Côtes-D’Armor', value: 'Département Des Côtes-D’Armor' },
  { label: 'Département Des Deux-Sèvres', value: 'Département Des Deux-Sèvres' },
  { label: 'Département Des Hautes-Pyrénées', value: 'Département Des Hautes-Pyrénées' },
  { label: 'Département Des Pyrénées-Atlantiques', value: 'Département Des Pyrénées-Atlantiques' },
  { label: 'Département Des Pyrénées-Orientales', value: 'Département Des Pyrénées-Orientales' },
  { label: 'Département Deure-Et-Loir', value: 'Département Deure-Et-Loir' },
  { label: 'Département Dille-Et-Vilaine', value: 'Département Dille-Et-Vilaine' },
  { label: 'Département Dindre-Et-Loire', value: 'Département Dindre-Et-Loire' },
  { label: 'Département Du Finistère', value: 'Département Du Finistère' },
  { label: 'Département Du Loir-Et-Cher', value: 'Département Du Loir-Et-Cher' },
  { label: 'Département Du Lot-Et-Garonne', value: 'Département Du Lot-Et-Garonne' },
  { label: 'Département Du Puy-De-Dôme', value: 'Département Du Puy-De-Dôme' },
  { label: 'Département Du Rhône', value: 'Département Du Rhône' },
  { label: 'Département Du Tarn-Et-Garonne', value: 'Département Du Tarn-Et-Garonne' },
  { label: 'Département Du Val-D’Oise', value: 'Département Du Val-D’Oise' },
  { label: 'Département Du Vaucluse', value: 'Département Du Vaucluse' },
  { label: 'Depew', value: 'Depew' },
  { label: 'Depinay', value: 'Depinay' },
  { label: 'Depoe Bay', value: 'Depoe Bay' },
  { label: 'Depok', value: 'Depok' },
  { label: 'Deposit', value: 'Deposit' },
  { label: 'Depot Hill', value: 'Depot Hill' },
  { label: 'Depressa', value: 'Depressa' },
  { label: 'Depue', value: 'Depue' },
  { label: 'Deputado Irapuan Pinheiro', value: 'Deputado Irapuan Pinheiro' },
  { label: 'Deputatsky', value: 'Deputatsky' },
  { label: 'Dêqên', value: 'Dêqên' },
  { label: 'Dêqên Tibetan Autonomous Prefecture', value: 'Dêqên Tibetan Autonomous Prefecture' },
  { label: 'Deqing', value: 'Deqing' },
  { label: 'Dequincy', value: 'Dequincy' },
  { label: 'Dera Baba Nanak', value: 'Dera Baba Nanak' },
  { label: 'Dera Bassi', value: 'Dera Bassi' },
  { label: 'Dera Bugti', value: 'Dera Bugti' },
  { label: 'Dera Bugti District', value: 'Dera Bugti District' },
  { label: 'Dera Ghazi Khan', value: 'Dera Ghazi Khan' },
  { label: 'Dera Gopipur', value: 'Dera Gopipur' },
  { label: 'Dera Ismail Khan', value: 'Dera Ismail Khan' },
  { label: 'Dera Ismāīl Khān District', value: 'Dera Ismāīl Khān District' },
  { label: 'Dérac', value: 'Dérac' },
  { label: 'Derapur', value: 'Derapur' },
  { label: 'Derazhnya', value: 'Derazhnya' },
  { label: 'Derazhnyans’Kyy Rayon', value: 'Derazhnyans’Kyy Rayon' },
  { label: 'Derbent', value: 'Derbent' },
  { label: 'Derbent', value: 'Derbent' },
  { label: 'Derbentskiy Rayon', value: 'Derbentskiy Rayon' },
  { label: 'Derby', value: 'Derby' },
  { label: 'Derby', value: 'Derby' },
  { label: 'Derby', value: 'Derby' },
  { label: 'Derby', value: 'Derby' },
  { label: 'Derby', value: 'Derby' },
  { label: 'Derbyshire', value: 'Derbyshire' },
  { label: 'Derby-West Kimberley', value: 'Derby-West Kimberley' },
  { label: 'Derdara', value: 'Derdara' },
  { label: 'Derebucak', value: 'Derebucak' },
  { label: 'Derecik', value: 'Derecik' },
  { label: 'Derecske', value: 'Derecske' },
  { label: 'Derecskei Járás', value: 'Derecskei Járás' },
  { label: 'Dereli', value: 'Dereli' },
  { label: 'Derenburg', value: 'Derenburg' },
  { label: 'Derendingen', value: 'Derendingen' },
  { label: 'Derepazarı', value: 'Derepazarı' },
  { label: 'Dergachi', value: 'Dergachi' },
  { label: 'Dergaon', value: 'Dergaon' },
  { label: 'Derhachi', value: 'Derhachi' },
  { label: 'Derhanivka', value: 'Derhanivka' },
  { label: 'Deri', value: 'Deri' },
  { label: 'Deridder', value: 'Deridder' },
  { label: 'Derierre Bois', value: 'Derierre Bois' },
  { label: 'Derierre Fort/Old Victoria Road', value: 'Derierre Fort/Old Victoria Road' },
  { label: 'Derierre Morne', value: 'Derierre Morne' },
  { label: 'Derik', value: 'Derik' },
  { label: 'Derince', value: 'Derince' },
  { label: 'Derinkuyu', value: 'Derinkuyu' },
  { label: 'Derio', value: 'Derio' },
  { label: 'Derma', value: 'Derma' },
  { label: 'Dermbach', value: 'Dermbach' },
  { label: 'Dermott', value: 'Dermott' },
  { label: 'Derna', value: 'Derna' },
  { label: 'Dernancourt', value: 'Dernancourt' },
  { label: 'Dernau', value: 'Dernau' },
  { label: 'Dernbach', value: 'Dernbach' },
  { label: 'Dernekpazarı', value: 'Dernekpazarı' },
  { label: 'Dernice', value: 'Dernice' },
  { label: 'Derniere Riviere', value: 'Derniere Riviere' },
  { label: 'Derniere Riviere/Fond Maricient', value: 'Derniere Riviere/Fond Maricient' },
  { label: 'Derniere Riviere/Mardi Gras/Morne Caca Cochon', value: 'Derniere Riviere/Mardi Gras/Morne Caca Cochon' },
  { label: 'Derniere Riviere/Morne Panache', value: 'Derniere Riviere/Morne Panache' },
  { label: 'Derovere', value: 'Derovere' },
  { label: 'Derramadero Segundo (Infiernillo)', value: 'Derramadero Segundo (Infiernillo)' },
  { label: 'Derramaderos', value: 'Derramaderos' },
  { label: 'Derriere Lagoon', value: 'Derriere Lagoon' },
  { label: 'Derriere Morne', value: 'Derriere Morne' },
  { label: 'Derrimut', value: 'Derrimut' },
  { label: 'Derrinturn', value: 'Derrinturn' },
  { label: 'Derrubadas', value: 'Derrubadas' },
  { label: 'Derry', value: 'Derry' },
  { label: 'Derry', value: 'Derry' },
  { label: 'Derry', value: 'Derry' },
  { label: 'Derry', value: 'Derry' },
  { label: 'Derry City And Strabane', value: 'Derry City And Strabane' },
  { label: 'Derry Village', value: 'Derry Village' },
  { label: 'Derrybeg', value: 'Derrybeg' },
  { label: 'Dersca', value: 'Dersca' },
  { label: 'Derschen', value: 'Derschen' },
  { label: 'Dersekow', value: 'Dersekow' },
  { label: 'Derșida', value: 'Derșida' },
  { label: 'Dersingham', value: 'Dersingham' },
  { label: 'Dersum', value: 'Dersum' },
  { label: 'Deruta', value: 'Deruta' },
  { label: 'Derval', value: 'Derval' },
  { label: 'Derventa', value: 'Derventa' },
  { label: 'Dervio', value: 'Dervio' },
  { label: 'Derwent Valley', value: 'Derwent Valley' },
  { label: 'Derwood', value: 'Derwood' },
  { label: 'Derýneia', value: 'Derýneia' },
  { label: 'Derzhavīnsk', value: 'Derzhavīnsk' },
  { label: 'Des Allemands', value: 'Des Allemands' },
  { label: 'Des Arc', value: 'Des Arc' },
  { label: 'Des Barras', value: 'Des Barras' },
  { label: 'Des Barras/Cacolie', value: 'Des Barras/Cacolie' },
  { label: 'Des Blanchard', value: 'Des Blanchard' },
  { label: 'Des Moines', value: 'Des Moines' },
  { label: 'Des Moines', value: 'Des Moines' },
  { label: 'Des Moines County', value: 'Des Moines County' },
  { label: 'Des Peres', value: 'Des Peres' },
  { label: 'Des Plaines', value: 'Des Plaines' },
  { label: 'Desa', value: 'Desa' },
  { label: 'Desaguadero', value: 'Desaguadero' },
  { label: 'Désaignes', value: 'Désaignes' },
  { label: 'Desamparados', value: 'Desamparados' },
  { label: 'Desamparados', value: 'Desamparados' },
  { label: 'Desana', value: 'Desana' },
  { label: 'Désarmes', value: 'Désarmes' },
  { label: 'Desborough', value: 'Desborough' },
  { label: 'Descalvado', value: 'Descalvado' },
  { label: 'Descanso', value: 'Descanso' },
  { label: 'Descanso', value: 'Descanso' },
  { label: 'Descargamaría', value: 'Descargamaría' },
  { label: 'Descartes', value: 'Descartes' },
  { label: 'Deschutes County', value: 'Deschutes County' },
  { label: 'Deschutes River Woods', value: 'Deschutes River Woods' },
  { label: 'Descoberto', value: 'Descoberto' },
  { label: 'Dese', value: 'Dese' },
  { label: 'Desē', value: 'Desē' },
  { label: 'Desenzano Del Garda', value: 'Desenzano Del Garda' },
  { label: 'Deseronto', value: 'Deseronto' },
  { label: 'Desert Aire', value: 'Desert Aire' },
  { label: 'Desert Edge', value: 'Desert Edge' },
  { label: 'Desert Hills', value: 'Desert Hills' },
  { label: 'Desert Hot Springs', value: 'Desert Hot Springs' },
  { label: 'Desert Shores', value: 'Desert Shores' },
  { label: 'Desert Springs', value: 'Desert Springs' },
  { label: 'Desert View Highlands', value: 'Desert View Highlands' },
  { label: 'Désertines', value: 'Désertines' },
  { label: 'Deseşti', value: 'Deseşti' },
  { label: 'Desford', value: 'Desford' },
  { label: 'Desha County', value: 'Desha County' },
  { label: 'Deshler', value: 'Deshler' },
  { label: 'Deshnoke', value: 'Deshnoke' },
  { label: 'Deshovki', value: 'Deshovki' },
  { label: 'Desio', value: 'Desio' },
  { label: 'Deskáti', value: 'Deskáti' },
  { label: 'Deskle', value: 'Deskle' },
  { label: 'Desloge', value: 'Desloge' },
  { label: 'Desna', value: 'Desna' },
  { label: 'Desná', value: 'Desná' },
  { label: 'Desnogorsk', value: 'Desnogorsk' },
  { label: 'Desnyans’Kyy Rayon', value: 'Desnyans’Kyy Rayon' },
  { label: 'Desojo', value: 'Desojo' },
  { label: 'Desoto', value: 'Desoto' },
  { label: 'Desoto County', value: 'Desoto County' },
  { label: 'Desoto Lakes', value: 'Desoto Lakes' },
  { label: 'Desovo', value: 'Desovo' },
  { label: 'Despard', value: 'Despard' },
  { label: 'Despeñaderos', value: 'Despeñaderos' },
  { label: 'Despinoze', value: 'Despinoze' },
  { label: 'Despotovo', value: 'Despotovo' },
  { label: 'Desrameaux', value: 'Desrameaux' },
  { label: 'Desruisseaux', value: 'Desruisseaux' },
  { label: 'Dessalines', value: 'Dessalines' },
  { label: 'Dessau', value: 'Dessau' },
  { label: 'Dessau-Roßlau', value: 'Dessau-Roßlau' },
  { label: 'Dessel', value: 'Dessel' },
  { label: 'Desselbrunn', value: 'Desselbrunn' },
  { label: 'Dessenheim', value: 'Dessenheim' },
  { label: 'Deste', value: 'Deste' },
  { label: 'Destedt', value: 'Destedt' },
  { label: 'Destelbergen', value: 'Destelbergen' },
  { label: 'Desterro', value: 'Desterro' },
  { label: 'Desterro De Entre Rios', value: 'Desterro De Entre Rios' },
  { label: 'Desterro Do Melo', value: 'Desterro Do Melo' },
  { label: 'Destin', value: 'Destin' },
  { label: 'Destrehan', value: 'Destrehan' },
  { label: 'Destriana', value: 'Destriana' },
  { label: 'Destrnik', value: 'Destrnik' },
  { label: 'Desulo', value: 'Desulo' },
  { label: 'Desur', value: 'Desur' },
  { label: 'Desvres', value: 'Desvres' },
  { label: 'Deszczno', value: 'Deszczno' },
  { label: 'Deszk', value: 'Deszk' },
  { label: 'Det Udom', value: 'Det Udom' },
  { label: 'Deta', value: 'Deta' },
  { label: 'Detchino', value: 'Detchino' },
  { label: 'Dete', value: 'Dete' },
  { label: 'Detern', value: 'Detern' },
  { label: 'Detiña (San Antonio Detiña)', value: 'Detiña (San Antonio Detiña)' },
  { label: 'Dětmarovice', value: 'Dětmarovice' },
  { label: 'Detmerode', value: 'Detmerode' },
  { label: 'Detmold', value: 'Detmold' },
  { label: 'Detroit', value: 'Detroit' },
  { label: 'Detroit Beach', value: 'Detroit Beach' },
  { label: 'Detroit Lakes', value: 'Detroit Lakes' },
  { label: 'Detroit-Shoreway', value: 'Detroit-Shoreway' },
  { label: 'Detroj', value: 'Detroj' },
  { label: 'Dettelbach', value: 'Dettelbach' },
  { label: 'Dettenhausen', value: 'Dettenhausen' },
  { label: 'Dettighofen', value: 'Dettighofen' },
  { label: 'Dettingen', value: 'Dettingen' },
  { label: 'Dettingen An Der Erms', value: 'Dettingen An Der Erms' },
  { label: 'Dettingen Unter Teck', value: 'Dettingen Unter Teck' },
  { label: 'Dettum', value: 'Dettum' },
  { label: 'Dettwiller', value: 'Dettwiller' },
  { label: 'Detva', value: 'Detva' },
  { label: 'Deuben', value: 'Deuben' },
  { label: 'Deuchendorf', value: 'Deuchendorf' },
  { label: 'Deuel County', value: 'Deuel County' },
  { label: 'Deuel County', value: 'Deuel County' },
  { label: 'Deuerling', value: 'Deuerling' },
  { label: 'Deuil-La-Barre', value: 'Deuil-La-Barre' },
  { label: 'Deûlémont', value: 'Deûlémont' },
  { label: 'Deulgaon Raja', value: 'Deulgaon Raja' },
  { label: 'Deuna', value: 'Deuna' },
  { label: 'Deurne', value: 'Deurne' },
  { label: 'Deuteren', value: 'Deuteren' },
  { label: 'Deutsch Evern', value: 'Deutsch Evern' },
  { label: 'Deutsch Goritz', value: 'Deutsch Goritz' },
  { label: 'Deutsch Griffen', value: 'Deutsch Griffen' },
  { label: 'Deutsch Jahrndorf', value: 'Deutsch Jahrndorf' },
  { label: 'Deutsch Kaltenbrunn', value: 'Deutsch Kaltenbrunn' },
  { label: 'Deutschfeistritz', value: 'Deutschfeistritz' },
  { label: 'Deutsch-Griffen', value: 'Deutsch-Griffen' },
  { label: 'Deutschkreutz', value: 'Deutschkreutz' },
  { label: 'Deutschlandsberg', value: 'Deutschlandsberg' },
  { label: 'Deutsch-Wagram', value: 'Deutsch-Wagram' },
  { label: 'Deutz', value: 'Deutz' },
  { label: 'Deutzen', value: 'Deutzen' },
  { label: 'Deux-Montagnes', value: 'Deux-Montagnes' },
  { label: 'Deva', value: 'Deva' },
  { label: 'Devadanappatti', value: 'Devadanappatti' },
  { label: 'Devakottai', value: 'Devakottai' },
  { label: 'Devanhalli', value: 'Devanhalli' },
  { label: 'Dévanos', value: 'Dévanos' },
  { label: 'Devaprayag', value: 'Devaprayag' },
  { label: 'Devarkonda', value: 'Devarkonda' },
  { label: 'Dévaványa', value: 'Dévaványa' },
  { label: 'Devbhumi Dwarka', value: 'Devbhumi Dwarka' },
  { label: 'Devecey', value: 'Devecey' },
  { label: 'Devecser', value: 'Devecser' },
  { label: 'Devecseri Járás', value: 'Devecseri Járás' },
  { label: 'Develi', value: 'Develi' },
  { label: 'Devens', value: 'Devens' },
  { label: 'Deventer', value: 'Deventer' },
  { label: 'Devesel', value: 'Devesel' },
  { label: 'Deveselu', value: 'Deveselu' },
  { label: 'Devgadh Bariya', value: 'Devgadh Bariya' },
  { label: 'Devgarh', value: 'Devgarh' },
  { label: 'Deville', value: 'Deville' },
  { label: 'Deville', value: 'Deville' },
  { label: 'Deville', value: 'Deville' },
  { label: 'Déville-Lès-Rouen', value: 'Déville-Lès-Rouen' },
  { label: 'Devils Lake', value: 'Devils Lake' },
  { label: 'Devin', value: 'Devin' },
  { label: 'Devine', value: 'Devine' },
  { label: 'Devinuwara', value: 'Devinuwara' },
  { label: 'Devitsa', value: 'Devitsa' },
  { label: 'Devizes', value: 'Devizes' },
  { label: 'Devnya', value: 'Devnya' },
  { label: 'Devola', value: 'Devola' },
  { label: 'Devon', value: 'Devon' },
  { label: 'Devon', value: 'Devon' },
  { label: 'Devon', value: 'Devon' },
  { label: 'Devon', value: 'Devon' },
  { label: 'Devon Meadows', value: 'Devon Meadows' },
  { label: 'Devon Pen', value: 'Devon Pen' },
  { label: 'Devonport', value: 'Devonport' },
  { label: 'Devonshire', value: 'Devonshire' },
  { label: 'Devoto', value: 'Devoto' },
  { label: 'Devrek', value: 'Devrek' },
  { label: 'Devrekani', value: 'Devrekani' },
  { label: 'Devrukh', value: 'Devrukh' },
  { label: 'Dewa', value: 'Dewa' },
  { label: 'Dewart', value: 'Dewart' },
  { label: 'Dewas', value: 'Dewas' },
  { label: 'Dewey', value: 'Dewey' },
  { label: 'Dewey County', value: 'Dewey County' },
  { label: 'Dewey County', value: 'Dewey County' },
  { label: 'Dewey-Humboldt', value: 'Dewey-Humboldt' },
  { label: 'Deweyville', value: 'Deweyville' },
  { label: 'Dewitt', value: 'Dewitt' },
  { label: 'Dewitt County', value: 'Dewitt County' },
  { label: 'Dewsbury', value: 'Dewsbury' },
  { label: 'Dexheim', value: 'Dexheim' },
  { label: 'Dexter', value: 'Dexter' },
  { label: 'Dexter', value: 'Dexter' },
  { label: 'Dexter', value: 'Dexter' },
  { label: 'Dexter', value: 'Dexter' },
  { label: 'Dexter', value: 'Dexter' },
  { label: 'Déxtho De Victoria', value: 'Déxtho De Victoria' },
  { label: 'Deyá', value: 'Deyá' },
  { label: 'Deyang', value: 'Deyang' },
  { label: 'Deyhuk', value: 'Deyhuk' },
  { label: 'Deylam', value: 'Deylam' },
  { label: 'Deylaman', value: 'Deylaman' },
  { label: 'Deyskoye', value: 'Deyskoye' },
  { label: 'Deyvillers', value: 'Deyvillers' },
  { label: 'Deza', value: 'Deza' },
  { label: 'Dežanovac', value: 'Dežanovac' },
  { label: 'Dezej', value: 'Dezej' },
  { label: 'Dezesseis De Novembro', value: 'Dezesseis De Novembro' },
  { label: 'Dezful', value: 'Dezful' },
  { label: 'Dezhkord', value: 'Dezhkord' },
  { label: 'Dezhou', value: 'Dezhou' },
  { label: 'Dezmir', value: 'Dezmir' },
  { label: 'Dezna', value: 'Dezna' },
  { label: 'Dhading', value: 'Dhading' },
  { label: 'Dhafní', value: 'Dhafní' },
  { label: 'Dhahran', value: 'Dhahran' },
  { label: 'Dhaid', value: 'Dhaid' },
  { label: 'Dhaka', value: 'Dhaka' },
  { label: 'Dhaka ', value: 'Dhaka ' },
  { label: 'Dhalai', value: 'Dhalai' },
  { label: 'Dhali', value: 'Dhali' },
  { label: 'Dhamār', value: 'Dhamār' },
  { label: 'Dhamnod', value: 'Dhamnod' },
  { label: 'Dhampur', value: 'Dhampur' },
  { label: 'Dhamtari', value: 'Dhamtari' },
  { label: 'Dhana', value: 'Dhana' },
  { label: 'Dhanaula', value: 'Dhanaula' },
  { label: 'Dhanaura', value: 'Dhanaura' },
  { label: 'Dhanbad', value: 'Dhanbad' },
  { label: 'Dhandhuka', value: 'Dhandhuka' },
  { label: 'Dhanera', value: 'Dhanera' },
  { label: 'Dhanghata', value: 'Dhanghata' },
  { label: 'Dhaniakhali Community Development Block', value: 'Dhaniakhali Community Development Block' },
  { label: 'Dhankuta', value: 'Dhankuta' },
  { label: 'Dhanot', value: 'Dhanot' },
  { label: 'Dhansura', value: 'Dhansura' },
  { label: 'Dhanusha ', value: 'Dhanusha ' },
  { label: 'Dhanwar', value: 'Dhanwar' },
  { label: 'Dhar', value: 'Dhar' },
  { label: 'Dhar', value: 'Dhar' },
  { label: 'Dharampur', value: 'Dharampur' },
  { label: 'Dharampuri', value: 'Dharampuri' },
  { label: 'Dharamsala', value: 'Dharamsala' },
  { label: 'Dharangaon', value: 'Dharangaon' },
  { label: 'Dharapuram', value: 'Dharapuram' },
  { label: 'Dharasana', value: 'Dharasana' },
  { label: 'Dharau', value: 'Dharau' },
  { label: 'Dharavi', value: 'Dharavi' },
  { label: 'Dharchula', value: 'Dharchula' },
  { label: 'Dhari', value: 'Dhari' },
  { label: 'Dhariwal', value: 'Dhariwal' },
  { label: 'Dharmabad', value: 'Dharmabad' },
  { label: 'Dharmadom', value: 'Dharmadom' },
  { label: 'Dharmanagar', value: 'Dharmanagar' },
  { label: 'Dharmapuri', value: 'Dharmapuri' },
  { label: 'Dharmavaram', value: 'Dharmavaram' },
  { label: 'Dharruk', value: 'Dharruk' },
  { label: 'Dharuhera', value: 'Dharuhera' },
  { label: 'Dharur', value: 'Dharur' },
  { label: 'Dharwad', value: 'Dharwad' },
  { label: 'Dhasa', value: 'Dhasa' },
  { label: 'Dhaulpur', value: 'Dhaulpur' },
  { label: 'Dhaunkal', value: 'Dhaunkal' },
  { label: 'Dhaurahra', value: 'Dhaurahra' },
  { label: 'Dhawalpuri', value: 'Dhawalpuri' },
  { label: 'Dhekiajuli', value: 'Dhekiajuli' },
  { label: 'Dhemaji', value: 'Dhemaji' },
  { label: 'Dhenkanal', value: 'Dhenkanal' },
  { label: 'Dhī As Sufāl', value: 'Dhī As Sufāl' },
  { label: 'Dhi Bin', value: 'Dhi Bin' },
  { label: 'Dhi Naim', value: 'Dhi Naim' },
  { label: 'Dhidhdhoo', value: 'Dhidhdhoo' },
  { label: 'Dhilwan', value: 'Dhilwan' },
  { label: 'Dhing', value: 'Dhing' },
  { label: 'Dhok Awan', value: 'Dhok Awan' },
  { label: 'Dhokímion', value: 'Dhokímion' },
  { label: 'Dhola', value: 'Dhola' },
  { label: 'Dholera', value: 'Dholera' },
  { label: 'Dholka', value: 'Dholka' },
  { label: 'Dhone', value: 'Dhone' },
  { label: 'Dhoraji', value: 'Dhoraji' },
  { label: 'Dhoro Naro', value: 'Dhoro Naro' },
  { label: 'Dhráfi', value: 'Dhráfi' },
  { label: 'Dhrangadhra', value: 'Dhrangadhra' },
  { label: 'Dhrol', value: 'Dhrol' },
  { label: 'Dhromolaxia', value: 'Dhromolaxia' },
  { label: 'Dhrosiá', value: 'Dhrosiá' },
  { label: 'Dhubab', value: 'Dhubab' },
  { label: 'Dhubri', value: 'Dhubri' },
  { label: 'Dhudi', value: 'Dhudi' },
  { label: 'Dhuizon', value: 'Dhuizon' },
  { label: 'Dhulagari', value: 'Dhulagari' },
  { label: 'Dhule', value: 'Dhule' },
  { label: 'Dhulia', value: 'Dhulia' },
  { label: 'Dhulian', value: 'Dhulian' },
  { label: 'Dhupguri', value: 'Dhupguri' },
  { label: 'Dhuri', value: 'Dhuri' },
  { label: 'Dhuusamarreeb', value: 'Dhuusamarreeb' },
  { label: 'Dhuwaran', value: 'Dhuwaran' },
  { label: 'Dĩ An', value: 'Dĩ An' },
  { label: 'Diablo', value: 'Diablo' },
  { label: 'Diabugu', value: 'Diabugu' },
  { label: 'Diabugu Basilla', value: 'Diabugu Basilla' },
  { label: 'Diade', value: 'Diade' },
  { label: 'Diadema', value: 'Diadema' },
  { label: 'Diadi', value: 'Diadi' },
  { label: 'Diadi', value: 'Diadi' },
  { label: 'Diamante', value: 'Diamante' },
  { label: 'Diamante', value: 'Diamante' },
  { label: 'Diamante', value: 'Diamante' },
  { label: 'Diamante De Echeverría', value: 'Diamante De Echeverría' },
  { label: 'Diamante Do Norte', value: 'Diamante Do Norte' },
  { label: 'Diamante Do Sul', value: 'Diamante Do Sul' },
  { label: 'Diamante Doeste', value: 'Diamante Doeste' },
  { label: 'Diamantina', value: 'Diamantina' },
  { label: 'Diamantina', value: 'Diamantina' },
  { label: 'Diamantina', value: 'Diamantina' },
  { label: 'Diamantina', value: 'Diamantina' },
  { label: 'Diamantino', value: 'Diamantino' },
  { label: 'Diamond', value: 'Diamond' },
  { label: 'Diamond Bar', value: 'Diamond Bar' },
  { label: 'Diamond Creek', value: 'Diamond Creek' },
  { label: 'Diamond Harbour', value: 'Diamond Harbour' },
  { label: 'Diamond Ridge', value: 'Diamond Ridge' },
  { label: 'Diamond Springs', value: 'Diamond Springs' },
  { label: 'Diamond/Diamond Estate', value: 'Diamond/Diamond Estate' },
  { label: 'Diamondhead', value: 'Diamondhead' },
  { label: 'Dianalund', value: 'Dianalund' },
  { label: 'Dian-Ay', value: 'Dian-Ay' },
  { label: 'Dianella', value: 'Dianella' },
  { label: 'Diang', value: 'Diang' },
  { label: 'Diano Arentino', value: 'Diano Arentino' },
  { label: 'Diano Castello', value: 'Diano Castello' },
  { label: 'Diano Dalba', value: 'Diano Dalba' },
  { label: 'Diano Marina', value: 'Diano Marina' },
  { label: 'Diano San Pietro', value: 'Diano San Pietro' },
  { label: 'Dianópolis', value: 'Dianópolis' },
  { label: 'Diapaga', value: 'Diapaga' },
  { label: 'Dias', value: 'Dias' },
  { label: 'Dias Dávila', value: 'Dias Dávila' },
  { label: 'Diavatá', value: 'Diavatá' },
  { label: 'Diavatós', value: 'Diavatós' },
  { label: 'Diawara', value: 'Diawara' },
  { label: 'Diaz', value: 'Diaz' },
  { label: 'Diaz', value: 'Diaz' },
  { label: 'Diaz', value: 'Diaz' },
  { label: 'Dib Dibba', value: 'Dib Dibba' },
  { label: 'Dibai', value: 'Dibai' },
  { label: 'Dibaj', value: 'Dibaj' },
  { label: 'Dibang Valley', value: 'Dibang Valley' },
  { label: 'Dibba Al Fujairah Municipality', value: 'Dibba Al Fujairah Municipality' },
  { label: 'Dibba Al Hesn', value: 'Dibba Al Hesn' },
  { label: 'Dibba Al-Fujairah', value: 'Dibba Al-Fujairah' },
  { label: 'Dibba Al-Hisn', value: 'Dibba Al-Hisn' },
  { label: 'Diberville', value: 'Diberville' },
  { label: 'Diboll', value: 'Diboll' },
  { label: 'Dibombari', value: 'Dibombari' },
  { label: 'Dibrova', value: 'Dibrova' },
  { label: 'Dibrugarh', value: 'Dibrugarh' },
  { label: 'Dibulla', value: 'Dibulla' },
  { label: 'Dibuluan', value: 'Dibuluan' },
  { label: 'Dibuluan', value: 'Dibuluan' },
  { label: 'Dicabisagan', value: 'Dicabisagan' },
  { label: 'Dicabisagan', value: 'Dicabisagan' },
  { label: 'Dicamay', value: 'Dicamay' },
  { label: 'Dicamay', value: 'Dicamay' },
  { label: 'Dicastillo', value: 'Dicastillo' },
  { label: 'Dicayong', value: 'Dicayong' },
  { label: 'Dichiseni', value: 'Dichiseni' },
  { label: 'Dicholi', value: 'Dicholi' },
  { label: 'Dickens', value: 'Dickens' },
  { label: 'Dickens County', value: 'Dickens County' },
  { label: 'Dickens Heath', value: 'Dickens Heath' },
  { label: 'Dickenson County', value: 'Dickenson County' },
  { label: 'Dickerldorf', value: 'Dickerldorf' },
  { label: 'Dickey County', value: 'Dickey County' },
  { label: 'Dickeyville', value: 'Dickeyville' },
  { label: 'Dickinson', value: 'Dickinson' },
  { label: 'Dickinson', value: 'Dickinson' },
  { label: 'Dickinson County', value: 'Dickinson County' },
  { label: 'Dickinson County', value: 'Dickinson County' },
  { label: 'Dickinson County', value: 'Dickinson County' },
  { label: 'Dickleburgh', value: 'Dickleburgh' },
  { label: 'Dickson', value: 'Dickson' },
  { label: 'Dickson', value: 'Dickson' },
  { label: 'Dickson', value: 'Dickson' },
  { label: 'Dickson City', value: 'Dickson City' },
  { label: 'Dickson County', value: 'Dickson County' },
  { label: 'Dicky Beach', value: 'Dicky Beach' },
  { label: 'Dicle', value: 'Dicle' },
  { label: 'Dicmo', value: 'Dicmo' },
  { label: 'Dicomano', value: 'Dicomano' },
  { label: 'Diculești', value: 'Diculești' },
  { label: 'Didcot', value: 'Didcot' },
  { label: 'Didderse', value: 'Didderse' },
  { label: 'Diddillibah', value: 'Diddillibah' },
  { label: 'Didenheim', value: 'Didenheim' },
  { label: 'Didești', value: 'Didești' },
  { label: 'Didi Lilo', value: 'Didi Lilo' },
  { label: 'Didim', value: 'Didim' },
  { label: 'Didkovichi', value: 'Didkovichi' },
  { label: 'Didouche Mourad', value: 'Didouche Mourad' },
  { label: 'Didovichi', value: 'Didovichi' },
  { label: 'Didsbury', value: 'Didsbury' },
  { label: 'Didsbury', value: 'Didsbury' },
  { label: 'Didwana', value: 'Didwana' },
  { label: 'Dídyma', value: 'Dídyma' },
  { label: 'Didymóteicho', value: 'Didymóteicho' },
  { label: 'Die', value: 'Die' },
  { label: 'Diebach', value: 'Diebach' },
  { label: 'Dieblich', value: 'Dieblich' },
  { label: 'Diebling', value: 'Diebling' },
  { label: 'Diébougou', value: 'Diébougou' },
  { label: 'Dieburg', value: 'Dieburg' },
  { label: 'Dieci', value: 'Dieci' },
  { label: 'Dieciocho De Julio', value: 'Dieciocho De Julio' },
  { label: 'Dieciocho De Marzo', value: 'Dieciocho De Marzo' },
  { label: 'Dieciocho De Marzo', value: 'Dieciocho De Marzo' },
  { label: 'Dieciocho De Marzo', value: 'Dieciocho De Marzo' },
  { label: 'Dieciséis De Septiembre', value: 'Dieciséis De Septiembre' },
  { label: 'Dieciséis De Septiembre', value: 'Dieciséis De Septiembre' },
  { label: 'Diedorf', value: 'Diedorf' },
  { label: 'Diegem', value: 'Diegem' },
  { label: 'Diego De Almagro', value: 'Diego De Almagro' },
  { label: 'Diego Del Carpio', value: 'Diego Del Carpio' },
  { label: 'Diegten', value: 'Diegten' },
  { label: 'Diekholzen', value: 'Diekholzen' },
  { label: 'Diekirch', value: 'Diekirch' },
  { label: 'Dielheim', value: 'Dielheim' },
  { label: 'Dielsdorf', value: 'Dielsdorf' },
  { label: 'Diemen', value: 'Diemen' },
  { label: 'Diemeringen', value: 'Diemeringen' },
  { label: 'Diemlach', value: 'Diemlach' },
  { label: 'Diémoz', value: 'Diémoz' },
  { label: 'Diemtigen', value: 'Diemtigen' },
  { label: 'Dien Bien Phu', value: 'Dien Bien Phu' },
  { label: 'Dienersdorf', value: 'Dienersdorf' },
  { label: 'Dienheim', value: 'Dienheim' },
  { label: 'Dienten Am Hochkönig', value: 'Dienten Am Hochkönig' },
  { label: 'Diepenbeek', value: 'Diepenbeek' },
  { label: 'Diepenheim', value: 'Diepenheim' },
  { label: 'Diepholz', value: 'Diepholz' },
  { label: 'Diepoldsau', value: 'Diepoldsau' },
  { label: 'Dieppe', value: 'Dieppe' },
  { label: 'Dieppe', value: 'Dieppe' },
  { label: 'Dieppe Bay Town', value: 'Dieppe Bay Town' },
  { label: 'Diepsloot', value: 'Diepsloot' },
  { label: 'Dierdorf', value: 'Dierdorf' },
  { label: 'Dieren', value: 'Dieren' },
  { label: 'Dierkow-Neu', value: 'Dierkow-Neu' },
  { label: 'Dierkow-West', value: 'Dierkow-West' },
  { label: 'Dierks', value: 'Dierks' },
  { label: 'Diesdorf', value: 'Diesdorf' },
  { label: 'Diesen', value: 'Diesen' },
  { label: 'Dieskau', value: 'Dieskau' },
  { label: 'Diespeck', value: 'Diespeck' },
  { label: 'Diesseits', value: 'Diesseits' },
  { label: 'Diessen', value: 'Diessen' },
  { label: 'Dießen Am Ammersee', value: 'Dießen Am Ammersee' },
  { label: 'Diessenhofen', value: 'Diessenhofen' },
  { label: 'Diest', value: 'Diest' },
  { label: 'Dietach', value: 'Dietach' },
  { label: 'Dietachdorf', value: 'Dietachdorf' },
  { label: 'Dietenheim', value: 'Dietenheim' },
  { label: 'Dietenhofen', value: 'Dietenhofen' },
  { label: 'Dietersburg', value: 'Dietersburg' },
  { label: 'Dietersdorf', value: 'Dietersdorf' },
  { label: 'Dietersdorf Am Gnasbach', value: 'Dietersdorf Am Gnasbach' },
  { label: 'Dietersheim', value: 'Dietersheim' },
  { label: 'Dieterskirchen', value: 'Dieterskirchen' },
  { label: 'Dietfurt', value: 'Dietfurt' },
  { label: 'Dietikon', value: 'Dietikon' },
  { label: 'Dietikon / Almend', value: 'Dietikon / Almend' },
  { label: 'Dietikon / Guggenbühl', value: 'Dietikon / Guggenbühl' },
  { label: 'Dietikon / Hofacker', value: 'Dietikon / Hofacker' },
  { label: 'Dietikon / Kreuzacker', value: 'Dietikon / Kreuzacker' },
  { label: 'Dietikon / Oberdorf', value: 'Dietikon / Oberdorf' },
  { label: 'Dietikon / Schönenwerd', value: 'Dietikon / Schönenwerd' },
  { label: 'Dietikon / Vorstadt', value: 'Dietikon / Vorstadt' },
  { label: 'Dietingen', value: 'Dietingen' },
  { label: 'Dietlikon / Dietlikon (Dorf)', value: 'Dietlikon / Dietlikon (Dorf)' },
  { label: 'Dietlikon / Eichwiesen', value: 'Dietlikon / Eichwiesen' },
  { label: 'Dietmannsried', value: 'Dietmannsried' },
  { label: 'Dietramszell', value: 'Dietramszell' },
  { label: 'Dietwiller', value: 'Dietwiller' },
  { label: 'Dietzenbach', value: 'Dietzenbach' },
  { label: 'Dieue-Sur-Meuse', value: 'Dieue-Sur-Meuse' },
  { label: 'Dieulefit', value: 'Dieulefit' },
  { label: 'Dieulouard', value: 'Dieulouard' },
  { label: 'Dieuze', value: 'Dieuze' },
  { label: 'Diever', value: 'Diever' },
  { label: 'Diex', value: 'Diex' },
  { label: 'Diez', value: 'Diez' },
  { label: 'Diez De Octubre', value: 'Diez De Octubre' },
  { label: 'Diez De Octubre', value: 'Diez De Octubre' },
  { label: 'Diezma', value: 'Diezma' },
  { label: 'Diffa', value: 'Diffa' },
  { label: 'Differdange', value: 'Differdange' },
  { label: 'Diffun', value: 'Diffun' },
  { label: 'Diffun', value: 'Diffun' },
  { label: 'Dig', value: 'Dig' },
  { label: 'Digah', value: 'Digah' },
  { label: 'Digal', value: 'Digal' },
  { label: 'Digal', value: 'Digal' },
  { label: 'Digapahandi', value: 'Digapahandi' },
  { label: 'Digboi', value: 'Digboi' },
  { label: 'Digby', value: 'Digby' },
  { label: 'Digby County', value: 'Digby County' },
  { label: 'Digdig', value: 'Digdig' },
  { label: 'Digdig', value: 'Digdig' },
  { label: 'Diges', value: 'Diges' },
  { label: 'Diggers Rest', value: 'Diggers Rest' },
  { label: 'Diggle', value: 'Diggle' },
  { label: 'Digha', value: 'Digha' },
  { label: 'Dighori', value: 'Dighori' },
  { label: 'Dighton', value: 'Dighton' },
  { label: 'Dighton', value: 'Dighton' },
  { label: 'Dighwara', value: 'Dighwara' },
  { label: 'Digkilaan', value: 'Digkilaan' },
  { label: 'Diglur', value: 'Diglur' },
  { label: 'Dignac', value: 'Dignac' },
  { label: 'Dignano', value: 'Dignano' },
  { label: 'Digne-Les-Bains', value: 'Digne-Les-Bains' },
  { label: 'Digoin', value: 'Digoin' },
  { label: 'Digor', value: 'Digor' },
  { label: 'Digora', value: 'Digora' },
  { label: 'Digorskiy Rayon', value: 'Digorskiy Rayon' },
  { label: 'Digos', value: 'Digos' },
  { label: 'Digos', value: 'Digos' },
  { label: 'Digos', value: 'Digos' },
  { label: 'Digosville', value: 'Digosville' },
  { label: 'Digras', value: 'Digras' },
  { label: 'Digri', value: 'Digri' },
  { label: 'Diguvametta', value: 'Diguvametta' },
  { label: 'Dihok', value: 'Dihok' },
  { label: 'Dihtyari', value: 'Dihtyari' },
  { label: 'Dijkot', value: 'Dijkot' },
  { label: 'Dijon', value: 'Dijon' },
  { label: 'Dike', value: 'Dike' },
  { label: 'Dikhil', value: 'Dikhil' },
  { label: 'Dikili', value: 'Dikili' },
  { label: 'Dikirnis', value: 'Dikirnis' },
  { label: 'Dikmen', value: 'Dikmen' },
  { label: 'Diksmuide', value: 'Diksmuide' },
  { label: 'Dikson', value: 'Dikson' },
  { label: 'Dikwa', value: 'Dikwa' },
  { label: 'Dīla', value: 'Dīla' },
  { label: 'Dilan', value: 'Dilan' },
  { label: 'Dilan', value: 'Dilan' },
  { label: 'Dílar', value: 'Dílar' },
  { label: 'Dilasag', value: 'Dilasag' },
  { label: 'Dilbeek', value: 'Dilbeek' },
  { label: 'Dilermando De Aguiar', value: 'Dilermando De Aguiar' },
  { label: 'Dílesi', value: 'Dílesi' },
  { label: 'Dileu Nou', value: 'Dileu Nou' },
  { label: 'Dileu Vechi', value: 'Dileu Vechi' },
  { label: 'Dili', value: 'Dili' },
  { label: 'Dilijan', value: 'Dilijan' },
  { label: 'Diliman Primero', value: 'Diliman Primero' },
  { label: 'Diliman Primero', value: 'Diliman Primero' },
  { label: 'Dilkon', value: 'Dilkon' },
  { label: 'Dillenburg', value: 'Dillenburg' },
  { label: 'Dilley', value: 'Dilley' },
  { label: 'Dilling', value: 'Dilling' },
  { label: 'Dillingen', value: 'Dillingen' },
  { label: 'Dillingen An Der Donau', value: 'Dillingen An Der Donau' },
  { label: 'Dillingham', value: 'Dillingham' },
  { label: 'Dillingham Census Area', value: 'Dillingham Census Area' },
  { label: 'Dillion Bigwoods', value: 'Dillion Bigwoods' },
  { label: 'Dillon', value: 'Dillon' },
  { label: 'Dillon', value: 'Dillon' },
  { label: 'Dillon County', value: 'Dillon County' },
  { label: 'Dillonvale', value: 'Dillonvale' },
  { label: 'Dillsboro', value: 'Dillsboro' },
  { label: 'Dillsburg', value: 'Dillsburg' },
  { label: 'Dilovası', value: 'Dilovası' },
  { label: 'Dilworth', value: 'Dilworth' },
  { label: 'Dima', value: 'Dima' },
  { label: 'Dima Hasao District', value: 'Dima Hasao District' },
  { label: 'Dimăcheni', value: 'Dimăcheni' },
  { label: 'Dimako', value: 'Dimako' },
  { label: 'Dimaluna', value: 'Dimaluna' },
  { label: 'Dimapur', value: 'Dimapur' },
  { label: 'Dimaro', value: 'Dimaro' },
  { label: 'Dimasalang', value: 'Dimasalang' },
  { label: 'Dimataling', value: 'Dimataling' },
  { label: 'Dimayon', value: 'Dimayon' },
  { label: 'Dimbokro', value: 'Dimbokro' },
  { label: 'Dimboola', value: 'Dimboola' },
  { label: 'Dimbulah', value: 'Dimbulah' },
  { label: 'Dimiao', value: 'Dimiao' },
  { label: 'Dimitrov', value: 'Dimitrov' },
  { label: 'Dimitrovgrad', value: 'Dimitrovgrad' },
  { label: 'Dimitrovgrad', value: 'Dimitrovgrad' },
  { label: 'Dimitsána', value: 'Dimitsána' },
  { label: 'Dimmit County', value: 'Dimmit County' },
  { label: 'Dimmitt', value: 'Dimmitt' },
  { label: 'Dimnat Khadir', value: 'Dimnat Khadir' },
  { label: 'Dimona', value: 'Dimona' },
  { label: 'Dimondale', value: 'Dimondale' },
  { label: 'Dimovo', value: 'Dimovo' },
  { label: 'Din Daeng', value: 'Din Daeng' },
  { label: 'Dina Nagar', value: 'Dina Nagar' },
  { label: 'Dinagat', value: 'Dinagat' },
  { label: 'Dinagat', value: 'Dinagat' },
  { label: 'Dinagat Islands', value: 'Dinagat Islands' },
  { label: 'Dinahican', value: 'Dinahican' },
  { label: 'Dinaig', value: 'Dinaig' },
  { label: 'Dinaig', value: 'Dinaig' },
  { label: 'Dinajpur ', value: 'Dinajpur ' },
  { label: 'Dinalongan', value: 'Dinalongan' },
  { label: 'Dinalupihan', value: 'Dinalupihan' },
  { label: 'Dinalupihan', value: 'Dinalupihan' },
  { label: 'Dinami', value: 'Dinami' },
  { label: 'Dinan', value: 'Dinan' },
  { label: 'Dinan Bashnoian Wala', value: 'Dinan Bashnoian Wala' },
  { label: 'Dinant', value: 'Dinant' },
  { label: 'Dinapigui', value: 'Dinapigui' },
  { label: 'Dinapore', value: 'Dinapore' },
  { label: 'Dinar', value: 'Dinar' },
  { label: 'Dinard', value: 'Dinard' },
  { label: 'Dinas Powys', value: 'Dinas Powys' },
  { label: 'Dindigul', value: 'Dindigul' },
  { label: 'Dindima', value: 'Dindima' },
  { label: 'Dindori', value: 'Dindori' },
  { label: 'Dindori Maharashtra', value: 'Dindori Maharashtra' },
  { label: 'Dinéault', value: 'Dinéault' },
  { label: 'Dinga', value: 'Dinga' },
  { label: 'Dingalan', value: 'Dingalan' },
  { label: 'Dingalan', value: 'Dingalan' },
  { label: 'Dinganen', value: 'Dinganen' },
  { label: 'Dinganen', value: 'Dinganen' },
  { label: 'Dingcheng', value: 'Dingcheng' },
  { label: 'Dingé', value: 'Dingé' },
  { label: 'Dingeleh Kahriz', value: 'Dingeleh Kahriz' },
  { label: 'Dingelstädt', value: 'Dingelstädt' },
  { label: 'Dinghaicun', value: 'Dinghaicun' },
  { label: 'Dingle', value: 'Dingle' },
  { label: 'Dingle', value: 'Dingle' },
  { label: 'Dingley Village', value: 'Dingley Village' },
  { label: 'Dingli', value: 'Dingli' },
  { label: 'Dingolfing', value: 'Dingolfing' },
  { label: 'Dingolshausen', value: 'Dingolshausen' },
  { label: 'Dingras', value: 'Dingras' },
  { label: 'Dingras', value: 'Dingras' },
  { label: 'Dingsheim', value: 'Dingsheim' },
  { label: 'Dingtao', value: 'Dingtao' },
  { label: 'Dingtoucun', value: 'Dingtoucun' },
  { label: 'Dingtuna', value: 'Dingtuna' },
  { label: 'Dingwall', value: 'Dingwall' },
  { label: 'Dingxi Shi', value: 'Dingxi Shi' },
  { label: 'Dingy-Saint-Clair', value: 'Dingy-Saint-Clair' },
  { label: 'Dingzhou', value: 'Dingzhou' },
  { label: 'Đinh Văn', value: 'Đinh Văn' },
  { label: 'Dinhata', value: 'Dinhata' },
  { label: 'Dinkelsbühl', value: 'Dinkelsbühl' },
  { label: 'Dinkelscherben', value: 'Dinkelscherben' },
  { label: 'Dinklage', value: 'Dinklage' },
  { label: 'Dinner Plain', value: 'Dinner Plain' },
  { label: 'Dinnington', value: 'Dinnington' },
  { label: 'Dinsheim-Sur-Bruche', value: 'Dinsheim-Sur-Bruche' },
  { label: 'Dinskaya', value: 'Dinskaya' },
  { label: 'Dinslaken', value: 'Dinslaken' },
  { label: 'Dinteloord', value: 'Dinteloord' },
  { label: 'Dinton', value: 'Dinton' },
  { label: 'Dinuba', value: 'Dinuba' },
  { label: 'Dinwiddie County', value: 'Dinwiddie County' },
  { label: 'Diofior', value: 'Diofior' },
  { label: 'Diogo De Vasconcelos', value: 'Diogo De Vasconcelos' },
  { label: 'Dioknisi', value: 'Dioknisi' },
  { label: 'Díon', value: 'Díon' },
  { label: 'Dióni', value: 'Dióni' },
  { label: 'Dionísio', value: 'Dionísio' },
  { label: 'Dionísio Cerqueira', value: 'Dionísio Cerqueira' },
  { label: 'Diónysos', value: 'Diónysos' },
  { label: 'Diorama', value: 'Diorama' },
  { label: 'Dios Le Guarde', value: 'Dios Le Guarde' },
  { label: 'Dios Padre', value: 'Dios Padre' },
  { label: 'Dios Padre', value: 'Dios Padre' },
  { label: 'Diósd', value: 'Diósd' },
  { label: 'Diosig', value: 'Diosig' },
  { label: 'Diósjenő', value: 'Diósjenő' },
  { label: 'Dioșod', value: 'Dioșod' },
  { label: 'Dioşti', value: 'Dioşti' },
  { label: 'Diou', value: 'Diou' },
  { label: 'Dipaculao', value: 'Dipaculao' },
  { label: 'Dipalpur', value: 'Dipalpur' },
  { label: 'Diphu', value: 'Diphu' },
  { label: 'Dipignano', value: 'Dipignano' },
  { label: 'Dipilto', value: 'Dipilto' },
  { label: 'Diplahan', value: 'Diplahan' },
  { label: 'Diplo', value: 'Diplo' },
  { label: 'Dipolo', value: 'Dipolo' },
  { label: 'Dipolog', value: 'Dipolog' },
  { label: 'Dippach', value: 'Dippach' },
  { label: 'Dippach', value: 'Dippach' },
  { label: 'Dipperz', value: 'Dipperz' },
  { label: 'Dippoldiswalde', value: 'Dippoldiswalde' },
  { label: 'Dirac', value: 'Dirac' },
  { label: 'Dirba', value: 'Dirba' },
  { label: 'Dirce Reis', value: 'Dirce Reis' },
  { label: 'Dirceu Arcoverde', value: 'Dirceu Arcoverde' },
  { label: 'Dire', value: 'Dire' },
  { label: 'Dire Dawa', value: 'Dire Dawa' },
  { label: 'Diriá', value: 'Diriá' },
  { label: 'Diriamba', value: 'Diriamba' },
  { label: 'Dirinon', value: 'Dirinon' },
  { label: 'Diriomo', value: 'Diriomo' },
  { label: 'Diriyah', value: 'Diriyah' },
  { label: 'Dirkshorn', value: 'Dirkshorn' },
  { label: 'Dirksland', value: 'Dirksland' },
  { label: 'Dirlewang', value: 'Dirlewang' },
  { label: 'Dirmstein', value: 'Dirmstein' },
  { label: 'Disa', value: 'Disa' },
  { label: 'Dischingen', value: 'Dischingen' },
  { label: 'Discovery Bay', value: 'Discovery Bay' },
  { label: 'Discovery Bay', value: 'Discovery Bay' },
  { label: 'Disentis', value: 'Disentis' },
  { label: 'Diseröd', value: 'Diseröd' },
  { label: 'Dishman', value: 'Dishman' },
  { label: 'Dishmok', value: 'Dishmok' },
  { label: 'Dishnā', value: 'Dishnā' },
  { label: 'Disina', value: 'Disina' },
  { label: 'Disley', value: 'Disley' },
  { label: 'Diso', value: 'Diso' },
  { label: 'Disod', value: 'Disod' },
  { label: 'Dison', value: 'Dison' },
  { label: 'Dispur', value: 'Dispur' },
  { label: 'Disraeli', value: 'Disraeli' },
  { label: 'Diss', value: 'Diss' },
  { label: 'Dissay', value: 'Dissay' },
  { label: 'Dissen', value: 'Dissen' },
  { label: 'Distington', value: 'Distington' },
  { label: 'Dístomo', value: 'Dístomo' },
  { label: 'Distracción', value: 'Distracción' },
  { label: 'Distré', value: 'Distré' },
  { label: 'District Heights', value: 'District Heights' },
  { label: 'District Of Rutland', value: 'District Of Rutland' },
  { label: 'Distrito Arraiján', value: 'Distrito Arraiján' },
  { label: 'Distrito Central', value: 'Distrito Central' },
  { label: 'Distrito Chiriquí Grande', value: 'Distrito Chiriquí Grande' },
  { label: 'Distrito De Aguadulce', value: 'Distrito De Aguadulce' },
  { label: 'Distrito De Alanje', value: 'Distrito De Alanje' },
  { label: 'Distrito De Antón', value: 'Distrito De Antón' },
  { label: 'Distrito De Atalaya', value: 'Distrito De Atalaya' },
  { label: 'Distrito De Balboa', value: 'Distrito De Balboa' },
  { label: 'Distrito De Barú', value: 'Distrito De Barú' },
  { label: 'Distrito De Bocas Del Toro', value: 'Distrito De Bocas Del Toro' },
  { label: 'Distrito De Boquerón', value: 'Distrito De Boquerón' },
  { label: 'Distrito De Boquete', value: 'Distrito De Boquete' },
  { label: 'Distrito De Bugaba', value: 'Distrito De Bugaba' },
  { label: 'Distrito De Calobre', value: 'Distrito De Calobre' },
  { label: 'Distrito De Cañazas', value: 'Distrito De Cañazas' },
  { label: 'Distrito De Capira', value: 'Distrito De Capira' },
  { label: 'Distrito De Chagres', value: 'Distrito De Chagres' },
  { label: 'Distrito De Chame', value: 'Distrito De Chame' },
  { label: 'Distrito De Changuinola', value: 'Distrito De Changuinola' },
  { label: 'Distrito De Chepigana', value: 'Distrito De Chepigana' },
  { label: 'Distrito De Chepo', value: 'Distrito De Chepo' },
  { label: 'Distrito De Chimán', value: 'Distrito De Chimán' },
  { label: 'Distrito De Chitré', value: 'Distrito De Chitré' },
  { label: 'Distrito De Colón', value: 'Distrito De Colón' },
  { label: 'Distrito De David', value: 'Distrito De David' },
  { label: 'Distrito De Dolega', value: 'Distrito De Dolega' },
  { label: 'Distrito De Donoso', value: 'Distrito De Donoso' },
  { label: 'Distrito De Gualaca', value: 'Distrito De Gualaca' },
  { label: 'Distrito De Guararé', value: 'Distrito De Guararé' },
  { label: 'Distrito De La Chorrera', value: 'Distrito De La Chorrera' },
  { label: 'Distrito De La Mesa', value: 'Distrito De La Mesa' },
  { label: 'Distrito De La Pintada', value: 'Distrito De La Pintada' },
  { label: 'Distrito De Las Minas', value: 'Distrito De Las Minas' },
  { label: 'Distrito De Las Palmas', value: 'Distrito De Las Palmas' },
  { label: 'Distrito De Las Tablas', value: 'Distrito De Las Tablas' },
  { label: 'Distrito De Los Pozos', value: 'Distrito De Los Pozos' },
  { label: 'Distrito De Los Santos', value: 'Distrito De Los Santos' },
  { label: 'Distrito De Macaracas', value: 'Distrito De Macaracas' },
  { label: 'Distrito De Montijo', value: 'Distrito De Montijo' },
  { label: 'Distrito De Natá', value: 'Distrito De Natá' },
  { label: 'Distrito De Ocú', value: 'Distrito De Ocú' },
  { label: 'Distrito De Olá', value: 'Distrito De Olá' },
  { label: 'Distrito De Panamá', value: 'Distrito De Panamá' },
  { label: 'Distrito De Parita', value: 'Distrito De Parita' },
  { label: 'Distrito De Pedasí', value: 'Distrito De Pedasí' },
  { label: 'Distrito De Pesé', value: 'Distrito De Pesé' },
  { label: 'Distrito De Pinogana', value: 'Distrito De Pinogana' },
  { label: 'Distrito De Pocrí', value: 'Distrito De Pocrí' },
  { label: 'Distrito De Portobelo', value: 'Distrito De Portobelo' },
  { label: 'Distrito De Remedios', value: 'Distrito De Remedios' },
  { label: 'Distrito De Renacimiento', value: 'Distrito De Renacimiento' },
  { label: 'Distrito De Río De Jesús', value: 'Distrito De Río De Jesús' },
  { label: 'Distrito De San Carlos', value: 'Distrito De San Carlos' },
  { label: 'Distrito De San Félix', value: 'Distrito De San Félix' },
  { label: 'Distrito De San Francisco', value: 'Distrito De San Francisco' },
  { label: 'Distrito De San Lorenzo', value: 'Distrito De San Lorenzo' },
  { label: 'Distrito De Santa Fé', value: 'Distrito De Santa Fé' },
  { label: 'Distrito De Santa Isabel', value: 'Distrito De Santa Isabel' },
  { label: 'Distrito De Santa María', value: 'Distrito De Santa María' },
  { label: 'Distrito De Santiago', value: 'Distrito De Santiago' },
  { label: 'Distrito De Soná', value: 'Distrito De Soná' },
  { label: 'Distrito De Taboga', value: 'Distrito De Taboga' },
  { label: 'Distrito De Tolé', value: 'Distrito De Tolé' },
  { label: 'Distrito De Tonosí', value: 'Distrito De Tonosí' },
  { label: 'Distrito Penonomé', value: 'Distrito Penonomé' },
  { label: 'Distroff', value: 'Distroff' },
  { label: 'Disūq', value: 'Disūq' },
  { label: 'Ditchingham', value: 'Ditchingham' },
  { label: 'Ditchling', value: 'Ditchling' },
  { label: 'Dițești', value: 'Dițești' },
  { label: 'Ditfurt', value: 'Ditfurt' },
  { label: 'Ditrău', value: 'Ditrău' },
  { label: 'Ditsaan-Ramain', value: 'Ditsaan-Ramain' },
  { label: 'Dittelbrunn', value: 'Dittelbrunn' },
  { label: 'Dittelsheim-Heßloch', value: 'Dittelsheim-Heßloch' },
  { label: 'Dittenheim', value: 'Dittenheim' },
  { label: 'Ditton Hill', value: 'Ditton Hill' },
  { label: 'Ditzingen', value: 'Ditzingen' },
  { label: 'Diu', value: 'Diu' },
  { label: 'Divača', value: 'Divača' },
  { label: 'Divalá', value: 'Divalá' },
  { label: 'Divandarreh', value: 'Divandarreh' },
  { label: 'Diveagar', value: 'Diveagar' },
  { label: 'Divernon', value: 'Divernon' },
  { label: 'Dives-Sur-Mer', value: 'Dives-Sur-Mer' },
  { label: 'Diveyevo', value: 'Diveyevo' },
  { label: 'Divičani', value: 'Divičani' },
  { label: 'Divichibazar', value: 'Divichibazar' },
  { label: 'Divide County', value: 'Divide County' },
  { label: 'Divignano', value: 'Divignano' },
  { label: 'Divilican', value: 'Divilican' },
  { label: 'Divina Pastora', value: 'Divina Pastora' },
  { label: 'Divinésia', value: 'Divinésia' },
  { label: 'Divino', value: 'Divino' },
  { label: 'Divino Das Laranjeiras', value: 'Divino Das Laranjeiras' },
  { label: 'Divino De São Lourenço', value: 'Divino De São Lourenço' },
  { label: 'Divinolândia', value: 'Divinolândia' },
  { label: 'Divinolândia De Minas', value: 'Divinolândia De Minas' },
  { label: 'Divinópolis', value: 'Divinópolis' },
  { label: 'Divinópolis De Goiás', value: 'Divinópolis De Goiás' },
  { label: 'Divinópolis Do Tocantins', value: 'Divinópolis Do Tocantins' },
  { label: 'Divion', value: 'Divion' },
  { label: 'Divisa Alegre', value: 'Divisa Alegre' },
  { label: 'Divisa Nova', value: 'Divisa Nova' },
  { label: 'División Del Norte', value: 'División Del Norte' },
  { label: 'División Del Norte', value: 'División Del Norte' },
  { label: 'Divisópolis', value: 'Divisópolis' },
  { label: 'Divisoria', value: 'Divisoria' },
  { label: 'Divisoria', value: 'Divisoria' },
  { label: 'Divišov', value: 'Divišov' },
  { label: 'Divjakë', value: 'Divjakë' },
  { label: 'Divnogorsk', value: 'Divnogorsk' },
  { label: 'Divnomorskoye', value: 'Divnomorskoye' },
  { label: 'Divnoye', value: 'Divnoye' },
  { label: 'Divo', value: 'Divo' },
  { label: 'Divochki', value: 'Divochki' },
  { label: 'Divonne-Les-Bains', value: 'Divonne-Les-Bains' },
  { label: 'Divrasai', value: 'Divrasai' },
  { label: 'Divriği', value: 'Divriği' },
  { label: 'Diwek', value: 'Diwek' },
  { label: 'Dix Hills', value: 'Dix Hills' },
  { label: 'Dixfield', value: 'Dixfield' },
  { label: 'Dixiana', value: 'Dixiana' },
  { label: 'Dixie County', value: 'Dixie County' },
  { label: 'Dixmont', value: 'Dixmont' },
  { label: 'Dixmoor', value: 'Dixmoor' },
  { label: 'Dixon', value: 'Dixon' },
  { label: 'Dixon', value: 'Dixon' },
  { label: 'Dixon', value: 'Dixon' },
  { label: 'Dixon', value: 'Dixon' },
  { label: 'Dixon County', value: 'Dixon County' },
  { label: 'Dixon Lane-Meadow Creek', value: 'Dixon Lane-Meadow Creek' },
  { label: 'Diyadin', value: 'Diyadin' },
  { label: 'Diyarb Negm', value: 'Diyarb Negm' },
  { label: 'Dizaj Diz', value: 'Dizaj Diz' },
  { label: 'Dizangué', value: 'Dizangué' },
  { label: 'Diziche', value: 'Diziche' },
  { label: 'Dizy', value: 'Dizy' },
  { label: 'Dizzasco-Biazzeno', value: 'Dizzasco-Biazzeno' },
  { label: 'Djakotomey', value: 'Djakotomey' },
  { label: 'Djamaa', value: 'Djamaa' },
  { label: 'Djamaa', value: 'Djamaa' },
  { label: 'Djambala', value: 'Djambala' },
  { label: 'Djebeniana', value: 'Djebeniana' },
  { label: 'Djebilet Rosfa', value: 'Djebilet Rosfa' },
  { label: 'Djelfa', value: 'Djelfa' },
  { label: 'Djemmal', value: 'Djemmal' },
  { label: 'Djénné', value: 'Djénné' },
  { label: 'Djibo', value: 'Djibo' },
  { label: 'Djibouti', value: 'Djibouti' },
  { label: 'Djidiouia', value: 'Djidiouia' },
  { label: 'Djohong', value: 'Djohong' },
  { label: 'Djougou', value: 'Djougou' },
  { label: 'Djoyézi', value: 'Djoyézi' },
  { label: 'Djugun', value: 'Djugun' },
  { label: 'Djurås', value: 'Djurås' },
  { label: 'Djurö', value: 'Djurö' },
  { label: 'Djursholm', value: 'Djursholm' },
  { label: 'Dlouhá Třebová', value: 'Dlouhá Třebová' },
  { label: 'Długie', value: 'Długie' },
  { label: 'Długołęka', value: 'Długołęka' },
  { label: 'Długomiłowice', value: 'Długomiłowice' },
  { label: 'Długosiodło', value: 'Długosiodło' },
  { label: 'Dłutów', value: 'Dłutów' },
  { label: 'Dmanisis Munitsip’Alit’Et’I', value: 'Dmanisis Munitsip’Alit’Et’I' },
  { label: 'Dmitriyevka', value: 'Dmitriyevka' },
  { label: 'Dmitriyevskaya', value: 'Dmitriyevskaya' },
  { label: 'Dmitriyevskoye', value: 'Dmitriyevskoye' },
  { label: 'Dmitrov', value: 'Dmitrov' },
  { label: 'Dmitrovka', value: 'Dmitrovka' },
  { label: 'Dmitrovskiy Pogost', value: 'Dmitrovskiy Pogost' },
  { label: 'Dmitrovskiy Rayon', value: 'Dmitrovskiy Rayon' },
  { label: 'Dmitrovsk-Orlovskiy', value: 'Dmitrovsk-Orlovskiy' },
  { label: 'Dmosin', value: 'Dmosin' },
  { label: 'Dmytrivka', value: 'Dmytrivka' },
  { label: 'Dmytrivka', value: 'Dmytrivka' },
  { label: 'Dmytrivka', value: 'Dmytrivka' },
  { label: 'Dneprovka', value: 'Dneprovka' },
  { label: 'Dneprovskaya', value: 'Dneprovskaya' },
  { label: 'Dnestrovsc', value: 'Dnestrovsc' },
  { label: 'Dnipro', value: 'Dnipro' },
  { label: 'Dnipro Raion', value: 'Dnipro Raion' },
  { label: 'Dnipro Raion', value: 'Dnipro Raion' },
  { label: 'Dniprorudne', value: 'Dniprorudne' },
  { label: 'Dniprovka', value: 'Dniprovka' },
  { label: 'Dniprovs’Ka Mis’Ka Rada', value: 'Dniprovs’Ka Mis’Ka Rada' },
  { label: 'Dnipryany', value: 'Dnipryany' },
  { label: 'Dno', value: 'Dno' },
  { label: 'Do Rudan', value: 'Do Rudan' },
  { label: 'Doaba', value: 'Doaba' },
  { label: 'Doagh', value: 'Doagh' },
  { label: 'Doanca', value: 'Doanca' },
  { label: 'Dob', value: 'Dob' },
  { label: 'Doba', value: 'Doba' },
  { label: 'Doba', value: 'Doba' },
  { label: 'Dobanovci', value: 'Dobanovci' },
  { label: 'Dobarce', value: 'Dobarce' },
  { label: 'Dobârceni', value: 'Dobârceni' },
  { label: 'Dobârlău', value: 'Dobârlău' },
  { label: 'Dobbertin', value: 'Dobbertin' },
  { label: 'Dobbiaco', value: 'Dobbiaco' },
  { label: 'Dobbs Ferry', value: 'Dobbs Ferry' },
  { label: 'Dobczyce', value: 'Dobczyce' },
  { label: 'Dobdoban', value: 'Dobdoban' },
  { label: 'Dobel', value: 'Dobel' },
  { label: 'Dobele', value: 'Dobele' },
  { label: 'Döbeln', value: 'Döbeln' },
  { label: 'Doberdò Del Lago', value: 'Doberdò Del Lago' },
  { label: 'Doberlug-Kirchhain', value: 'Doberlug-Kirchhain' },
  { label: 'Döbernitz', value: 'Döbernitz' },
  { label: 'Dobersberg', value: 'Dobersberg' },
  { label: 'Doberschütz', value: 'Doberschütz' },
  { label: 'Dobersdorf', value: 'Dobersdorf' },
  { label: 'Dobiegniew', value: 'Dobiegniew' },
  { label: 'Dobien', value: 'Dobien' },
  { label: 'Dobieszowice', value: 'Dobieszowice' },
  { label: 'Dobje Pri Planini', value: 'Dobje Pri Planini' },
  { label: 'Doblas', value: 'Doblas' },
  { label: 'Doboj', value: 'Doboj' },
  { label: 'Dobong-Gu', value: 'Dobong-Gu' },
  { label: 'Doborji', value: 'Doborji' },
  { label: 'Doboșeni', value: 'Doboșeni' },
  { label: 'Doboz', value: 'Doboz' },
  { label: 'Dobra', value: 'Dobra' },
  { label: 'Dobra', value: 'Dobra' },
  { label: 'Dobra', value: 'Dobra' },
  { label: 'Dobra', value: 'Dobra' },
  { label: 'Dobra', value: 'Dobra' },
  { label: 'Dobra', value: 'Dobra' },
  { label: 'Dobra', value: 'Dobra' },
  { label: 'Dobra', value: 'Dobra' },
  { label: 'Dobrá', value: 'Dobrá' },
  { label: 'Dobrada', value: 'Dobrada' },
  { label: 'Dobřany', value: 'Dobřany' },
  { label: 'Dobratice', value: 'Dobratice' },
  { label: 'Dobre', value: 'Dobre' },
  { label: 'Dobre', value: 'Dobre' },
  { label: 'Dobre Miasto', value: 'Dobre Miasto' },
  { label: 'Dobreni', value: 'Dobreni' },
  { label: 'Dobreni', value: 'Dobreni' },
  { label: 'Dobreni', value: 'Dobreni' },
  { label: 'Dobreşti', value: 'Dobreşti' },
  { label: 'Dobreşti', value: 'Dobreşti' },
  { label: 'Dobreşti', value: 'Dobreşti' },
  { label: 'Dobreţu', value: 'Dobreţu' },
  { label: 'Dobri Dol', value: 'Dobri Dol' },
  { label: 'Döbriach', value: 'Döbriach' },
  { label: 'Dobric', value: 'Dobric' },
  { label: 'Dobrić', value: 'Dobrić' },
  { label: 'Dobrica', value: 'Dobrica' },
  { label: 'Dobrich', value: 'Dobrich' },
  { label: 'Dobřichovice', value: 'Dobřichovice' },
  { label: 'Dobricu Lăpușului', value: 'Dobricu Lăpușului' },
  { label: 'Dobridor', value: 'Dobridor' },
  { label: 'Dobrin', value: 'Dobrin' },
  { label: 'Dobrinje', value: 'Dobrinje' },
  { label: 'Dobrinka', value: 'Dobrinka' },
  { label: 'Dobrinskiy Rayon', value: 'Dobrinskiy Rayon' },
  { label: 'Dobříš', value: 'Dobříš' },
  { label: 'Dobrița', value: 'Dobrița' },
  { label: 'Dobřív', value: 'Dobřív' },
  { label: 'Dobrljin', value: 'Dobrljin' },
  { label: 'Dobrna', value: 'Dobrna' },
  { label: 'Dobrodzień', value: 'Dobrodzień' },
  { label: 'Dobroeşti', value: 'Dobroeşti' },
  { label: 'Döbrököz', value: 'Döbrököz' },
  { label: 'Dobroměřice', value: 'Dobroměřice' },
  { label: 'Dobromir', value: 'Dobromir' },
  { label: 'Dobromyl’', value: 'Dobromyl’' },
  { label: 'Dobroń', value: 'Dobroń' },
  { label: 'Dobronín', value: 'Dobronín' },
  { label: 'Dobropillia Raion', value: 'Dobropillia Raion' },
  { label: 'Dobropillya', value: 'Dobropillya' },
  { label: 'Dobrosloveni', value: 'Dobrosloveni' },
  { label: 'Dobrošte', value: 'Dobrošte' },
  { label: 'Dobroszyce', value: 'Dobroszyce' },
  { label: 'Dobrota', value: 'Dobrota' },
  { label: 'Dobroteasa', value: 'Dobroteasa' },
  { label: 'Dobroteşti', value: 'Dobroteşti' },
  { label: 'Dobrotești', value: 'Dobrotești' },
  { label: 'Dobrotinet', value: 'Dobrotinet' },
  { label: 'Dobrotu', value: 'Dobrotu' },
  { label: 'Dobrova', value: 'Dobrova' },
  { label: 'Dobrovăţ', value: 'Dobrovăţ' },
  { label: 'Dobrovelychkivka', value: 'Dobrovelychkivka' },
  { label: 'Dobrovelychkivs’Kyy Rayon', value: 'Dobrovelychkivs’Kyy Rayon' },
  { label: 'Dobrovice', value: 'Dobrovice' },
  { label: 'Dobrovnik', value: 'Dobrovnik' },
  { label: 'Dobrovo', value: 'Dobrovo' },
  { label: 'Dobrovskiy Rayon', value: 'Dobrovskiy Rayon' },
  { label: 'Dobroye', value: 'Dobroye' },
  { label: 'Dobroye', value: 'Dobroye' },
  { label: 'Dobrun', value: 'Dobrun' },
  { label: 'Dobrun’', value: 'Dobrun’' },
  { label: 'Dobrușa', value: 'Dobrușa' },
  { label: 'Dobruševo', value: 'Dobruševo' },
  { label: 'Dobrush', value: 'Dobrush' },
  { label: 'Dobrushino', value: 'Dobrushino' },
  { label: 'Dobruška', value: 'Dobruška' },
  { label: 'Dobryanka', value: 'Dobryanka' },
  { label: 'Dobryanka', value: 'Dobryanka' },
  { label: 'Dobryatino', value: 'Dobryatino' },
  { label: 'Dobrzany', value: 'Dobrzany' },
  { label: 'Dobrzeń Wielki', value: 'Dobrzeń Wielki' },
  { label: 'Dobrzyca', value: 'Dobrzyca' },
  { label: 'Dobrzyń Nad Wisłą', value: 'Dobrzyń Nad Wisłą' },
  { label: 'Dobrzyniewo Duże', value: 'Dobrzyniewo Duże' },
  { label: 'Dobšice', value: 'Dobšice' },
  { label: 'Dobšiná', value: 'Dobšiná' },
  { label: 'Dobson', value: 'Dobson' },
  { label: 'Dobwalls', value: 'Dobwalls' },
  { label: 'Docamel/La Resource', value: 'Docamel/La Resource' },
  { label: 'Doce De Octubre', value: 'Doce De Octubre' },
  { label: 'Docelles', value: 'Docelles' },
  { label: 'Dochia', value: 'Dochia' },
  { label: 'Dock Junction', value: 'Dock Junction' },
  { label: 'Docklands', value: 'Docklands' },
  { label: 'Doclin', value: 'Doclin' },
  { label: 'Doctor Alberto Oviedo Mota', value: 'Doctor Alberto Oviedo Mota' },
  { label: 'Doctor Arroyo', value: 'Doctor Arroyo' },
  { label: 'Doctor Belisario Domínguez (La Barra)', value: 'Doctor Belisario Domínguez (La Barra)' },
  { label: 'Doctor Coss', value: 'Doctor Coss' },
  { label: 'Doctor Coss Nuevo León', value: 'Doctor Coss Nuevo León' },
  { label: 'Doctor Domingo Chanona', value: 'Doctor Domingo Chanona' },
  { label: 'Doctor González', value: 'Doctor González' },
  { label: 'Doctor Juan León Mallorquín', value: 'Doctor Juan León Mallorquín' },
  { label: 'Doctor Juan Manuel Frutos', value: 'Doctor Juan Manuel Frutos' },
  { label: 'Doctor Miguel Silva (San Guillermo)', value: 'Doctor Miguel Silva (San Guillermo)' },
  { label: 'Doctor Montes De Oca', value: 'Doctor Montes De Oca' },
  { label: 'Doctor Mora', value: 'Doctor Mora' },
  { label: 'Doctor Phillips', value: 'Doctor Phillips' },
  { label: 'Doctor Rodulfo Figueroa (Tierra Blanca)', value: 'Doctor Rodulfo Figueroa (Tierra Blanca)' },
  { label: 'Doctor Samuel León Brindis', value: 'Doctor Samuel León Brindis' },
  { label: 'Dod Ballapur', value: 'Dod Ballapur' },
  { label: 'Doda', value: 'Doda' },
  { label: 'Dodan', value: 'Dodan' },
  { label: 'Dodan', value: 'Dodan' },
  { label: 'Doddington', value: 'Doddington' },
  { label: 'Doddridge County', value: 'Doddridge County' },
  { label: 'Dodeni', value: 'Dodeni' },
  { label: 'Dodești', value: 'Dodești' },
  { label: 'Dodge Center', value: 'Dodge Center' },
  { label: 'Dodge City', value: 'Dodge City' },
  { label: 'Dodge County', value: 'Dodge County' },
  { label: 'Dodge County', value: 'Dodge County' },
  { label: 'Dodge County', value: 'Dodge County' },
  { label: 'Dodge County', value: 'Dodge County' },
  { label: 'Dodges Ferry', value: 'Dodges Ferry' },
  { label: 'Dodgeville', value: 'Dodgeville' },
  { label: 'Dodici Morelli', value: 'Dodici Morelli' },
  { label: 'Dodola', value: 'Dodola' },
  { label: 'Dodoma', value: 'Dodoma' },
  { label: 'Dodro', value: 'Dodro' },
  { label: 'Dodson Branch', value: 'Dodson Branch' },
  { label: 'Dodurga', value: 'Dodurga' },
  { label: 'Dodworth', value: 'Dodworth' },
  { label: 'Doe Valley', value: 'Doe Valley' },
  { label: 'Doembang Nangbuat', value: 'Doembang Nangbuat' },
  { label: 'Doenrade', value: 'Doenrade' },
  { label: 'Doesburg', value: 'Doesburg' },
  { label: 'Doetinchem', value: 'Doetinchem' },
  { label: 'Doffing', value: 'Doffing' },
  { label: 'Doftana', value: 'Doftana' },
  { label: 'Dofteana', value: 'Dofteana' },
  { label: 'Doğanhisar', value: 'Doğanhisar' },
  { label: 'Doğankent', value: 'Doğankent' },
  { label: 'Doğanşar', value: 'Doğanşar' },
  { label: 'Doğanşehir', value: 'Doğanşehir' },
  { label: 'Doğanyol', value: 'Doğanyol' },
  { label: 'Doğanyurt', value: 'Doğanyurt' },
  { label: 'Dogbo', value: 'Dogbo' },
  { label: 'Döge', value: 'Döge' },
  { label: 'Dogern', value: 'Dogern' },
  { label: 'Doghs', value: 'Doghs' },
  { label: 'Dogliani', value: 'Dogliani' },
  { label: 'Dogliola', value: 'Dogliola' },
  { label: 'Dogna', value: 'Dogna' },
  { label: 'Dognecea', value: 'Dognecea' },
  { label: 'Dogneville', value: 'Dogneville' },
  { label: 'Dogonbadan', value: 'Dogonbadan' },
  { label: 'Dogondoutchi', value: 'Dogondoutchi' },
  { label: 'Dogtown', value: 'Dogtown' },
  { label: 'Doğubayazıt', value: 'Doğubayazıt' },
  { label: 'Doha', value: 'Doha' },
  { label: 'Dohad', value: 'Dohad' },
  { label: 'Döhlau', value: 'Döhlau' },
  { label: 'Dohma', value: 'Dohma' },
  { label: 'Dohna', value: 'Dohna' },
  { label: 'Dohren', value: 'Dohren' },
  { label: 'Dohrighat', value: 'Dohrighat' },
  { label: 'Doiceşti', value: 'Doiceşti' },
  { label: 'Doingt', value: 'Doingt' },
  { label: 'Dois Córregos', value: 'Dois Córregos' },
  { label: 'Dois Irmãos', value: 'Dois Irmãos' },
  { label: 'Dois Irmãos Das Missões', value: 'Dois Irmãos Das Missões' },
  { label: 'Dois Irmãos Do Buriti', value: 'Dois Irmãos Do Buriti' },
  { label: 'Dois Irmãos Do Tocantins', value: 'Dois Irmãos Do Tocantins' },
  { label: 'Dois Lajeados', value: 'Dois Lajeados' },
  { label: 'Dois Portos', value: 'Dois Portos' },
  { label: 'Dois Riachos', value: 'Dois Riachos' },
  { label: 'Dois Vizinhos', value: 'Dois Vizinhos' },
  { label: 'Doische', value: 'Doische' },
  { label: 'Doiwala', value: 'Doiwala' },
  { label: 'Dok Kham Tai', value: 'Dok Kham Tai' },
  { label: 'Doka', value: 'Doka' },
  { label: 'Dokka', value: 'Dokka' },
  { label: 'Dokkum', value: 'Dokkum' },
  { label: 'Dokri', value: 'Dokri' },
  { label: 'Dokshytski Rayon', value: 'Dokshytski Rayon' },
  { label: 'Dokshytsy', value: 'Dokshytsy' },
  { label: 'Doksy', value: 'Doksy' },
  { label: 'Doksy', value: 'Doksy' },
  { label: 'Dokuchayevs’K', value: 'Dokuchayevs’K' },
  { label: 'Dokuzparinskiy Rayon', value: 'Dokuzparinskiy Rayon' },
  { label: 'Dol Pri Hrastniku', value: 'Dol Pri Hrastniku' },
  { label: 'Dol Pri Ljubljani', value: 'Dol Pri Ljubljani' },
  { label: 'Dolakha', value: 'Dolakha' },
  { label: 'Dolan Springs', value: 'Dolan Springs' },
  { label: 'Dolany', value: 'Dolany' },
  { label: 'Dólar', value: 'Dólar' },
  { label: 'Dolatabad', value: 'Dolatabad' },
  { label: 'Dolavón', value: 'Dolavón' },
  { label: 'Dölbau', value: 'Dölbau' },
  { label: 'Dolbeau-Mistassini', value: 'Dolbeau-Mistassini' },
  { label: 'Dolcè', value: 'Dolcè' },
  { label: 'Dolceacqua', value: 'Dolceacqua' },
  { label: 'Dolcedo', value: 'Dolcedo' },
  { label: 'Dolcinópolis', value: 'Dolcinópolis' },
  { label: 'Dol-De-Bretagne', value: 'Dol-De-Bretagne' },
  { label: 'Dole', value: 'Dole' },
  { label: 'Dolega District', value: 'Dolega District' },
  { label: 'Dolegna Del Collio', value: 'Dolegna Del Collio' },
  { label: 'Dolenjske Toplice', value: 'Dolenjske Toplice' },
  { label: 'Dolgellau', value: 'Dolgellau' },
  { label: 'Dolgeville', value: 'Dolgeville' },
  { label: 'Dolgoderevenskoye', value: 'Dolgoderevenskoye' },
  { label: 'Dolgoprudnyy', value: 'Dolgoprudnyy' },
  { label: 'Dolgorukovo', value: 'Dolgorukovo' },
  { label: 'Dolgorukovskiy Rayon', value: 'Dolgorukovskiy Rayon' },
  { label: 'Dolhasca', value: 'Dolhasca' },
  { label: 'Dolheşti', value: 'Dolheşti' },
  { label: 'Dolhești', value: 'Dolhești' },
  { label: 'Dolheștii Mici', value: 'Dolheștii Mici' },
  { label: 'Dolheștii-Mari', value: 'Dolheștii-Mari' },
  { label: 'Dołhobyczów', value: 'Dołhobyczów' },
  { label: 'Dolianova', value: 'Dolianova' },
  { label: 'Dolice', value: 'Dolice' },
  { label: 'Dolina', value: 'Dolina' },
  { label: 'Dolinka', value: 'Dolinka' },
  { label: 'Dolinnoye', value: 'Dolinnoye' },
  { label: 'Dolinsk', value: 'Dolinsk' },
  { label: 'Dolisie', value: 'Dolisie' },
  { label: 'Doljeşti', value: 'Doljeşti' },
  { label: 'Doljo', value: 'Doljo' },
  { label: 'Dollar', value: 'Dollar' },
  { label: 'Dollar Bay', value: 'Dollar Bay' },
  { label: 'Dollar Corner', value: 'Dollar Corner' },
  { label: 'Dollar Point', value: 'Dollar Point' },
  { label: 'Dollard-Des Ormeaux', value: 'Dollard-Des Ormeaux' },
  { label: 'Dollbergen', value: 'Dollbergen' },
  { label: 'Dollern', value: 'Dollern' },
  { label: 'Dollerup', value: 'Dollerup' },
  { label: 'Döllnitz', value: 'Döllnitz' },
  { label: 'Dollon', value: 'Dollon' },
  { label: 'Dolls Point', value: 'Dolls Point' },
  { label: 'Döllstädt', value: 'Döllstädt' },
  { label: 'Dollymount', value: 'Dollymount' },
  { label: 'Dolna Banjica', value: 'Dolna Banjica' },
  { label: 'Dolna Banya', value: 'Dolna Banya' },
  { label: 'Dolna Mitropolia', value: 'Dolna Mitropolia' },
  { label: 'Dolneni', value: 'Dolneni' },
  { label: 'Dolní Bečva', value: 'Dolní Bečva' },
  { label: 'Dolní Benešov', value: 'Dolní Benešov' },
  { label: 'Dolní Beřkovice', value: 'Dolní Beřkovice' },
  { label: 'Dolní Bohdíkov', value: 'Dolní Bohdíkov' },
  { label: 'Dolní Bojanovice', value: 'Dolní Bojanovice' },
  { label: 'Dolní Bousov', value: 'Dolní Bousov' },
  { label: 'Dolní Břežany', value: 'Dolní Břežany' },
  { label: 'Dolní Bukovsko', value: 'Dolní Bukovsko' },
  { label: 'Dolní Cerekev', value: 'Dolní Cerekev' },
  { label: 'Dolní Čermná', value: 'Dolní Čermná' },
  { label: 'Dolní Černilov', value: 'Dolní Černilov' },
  { label: 'Dolni Chiflik', value: 'Dolni Chiflik' },
  { label: 'Dolni Dabnik', value: 'Dolni Dabnik' },
  { label: 'Dolní Dobrouč', value: 'Dolní Dobrouč' },
  { label: 'Dolní Dunajovice', value: 'Dolní Dunajovice' },
  { label: 'Dolní Dvořiště', value: 'Dolní Dvořiště' },
  { label: 'Dolní Kounice', value: 'Dolní Kounice' },
  { label: 'Dolní Lhota', value: 'Dolní Lhota' },
  { label: 'Dolní Loućky', value: 'Dolní Loućky' },
  { label: 'Dolní Lutyně', value: 'Dolní Lutyně' },
  { label: 'Dolní Němčí', value: 'Dolní Němčí' },
  { label: 'Dolní Počernice', value: 'Dolní Počernice' },
  { label: 'Dolní Podluží', value: 'Dolní Podluží' },
  { label: 'Dolní Poustevna', value: 'Dolní Poustevna' },
  { label: 'Dolní Roveň', value: 'Dolní Roveň' },
  { label: 'Dolní Rychnov', value: 'Dolní Rychnov' },
  { label: 'Dolní Sloupnice', value: 'Dolní Sloupnice' },
  { label: 'Dolní Studénky', value: 'Dolní Studénky' },
  { label: 'Dolní Újezd', value: 'Dolní Újezd' },
  { label: 'Dolní Újezd', value: 'Dolní Újezd' },
  { label: 'Dolní Žandov', value: 'Dolní Žandov' },
  { label: 'Dolní Životice', value: 'Dolní Životice' },
  { label: 'Dolno Količani', value: 'Dolno Količani' },
  { label: 'Dolno Orizari', value: 'Dolno Orizari' },
  { label: 'Dolno Palčište', value: 'Dolno Palčište' },
  { label: 'Dolno Srpci', value: 'Dolno Srpci' },
  { label: 'Dolný Kubín', value: 'Dolný Kubín' },
  { label: 'Dolo', value: 'Dolo' },
  { label: 'Dolo', value: 'Dolo' },
  { label: 'Dolo', value: 'Dolo' },
  { label: 'Dolo', value: 'Dolo' },
  { label: 'Dologon', value: 'Dologon' },
  { label: 'Dolomieu', value: 'Dolomieu' },
  { label: 'Doloplazy', value: 'Doloplazy' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores', value: 'Dolores' },
  { label: 'Dolores County', value: 'Dolores County' },
  { label: 'Dolores Cuadrilla De Enmedio', value: 'Dolores Cuadrilla De Enmedio' },
  { label: 'Dolores Hidalgo', value: 'Dolores Hidalgo' },
  { label: 'Dolores Hidalgo', value: 'Dolores Hidalgo' },
  { label: 'Dolores Hidalgo Cuna De La Independencia Nacional', value: 'Dolores Hidalgo Cuna De La Independencia Nacional' },
  { label: 'Dolores Jaltenango', value: 'Dolores Jaltenango' },
  { label: 'Dolores Merendón', value: 'Dolores Merendón' },
  { label: 'Dolpa ', value: 'Dolpa ' },
  { label: 'Dölsach', value: 'Dölsach' },
  { label: 'Dolsk', value: 'Dolsk' },
  { label: 'Dolton', value: 'Dolton' },
  { label: 'Dolus-Doléron', value: 'Dolus-Doléron' },
  { label: 'Doluwa', value: 'Doluwa' },
  { label: 'Dolyar', value: 'Dolyar' },
  { label: 'Dolyna', value: 'Dolyna' },
  { label: 'Dolyns’Kyy Rayon', value: 'Dolyns’Kyy Rayon' },
  { label: 'Dolyns’Kyy Rayon', value: 'Dolyns’Kyy Rayon' },
  { label: 'Dolynska', value: 'Dolynska' },
  { label: 'Dolzago', value: 'Dolzago' },
  { label: 'Dolzhanskaya', value: 'Dolzhanskaya' },
  { label: 'Dom Aquino', value: 'Dom Aquino' },
  { label: 'Dom Basílio', value: 'Dom Basílio' },
  { label: 'Dom Bosco', value: 'Dom Bosco' },
  { label: 'Dom Cavati', value: 'Dom Cavati' },
  { label: 'Dom Eliseu', value: 'Dom Eliseu' },
  { label: 'Dom Expedito Lopes', value: 'Dom Expedito Lopes' },
  { label: 'Dom Feliciano', value: 'Dom Feliciano' },
  { label: 'Dom Inocêncio', value: 'Dom Inocêncio' },
  { label: 'Dom Joaquim', value: 'Dom Joaquim' },
  { label: 'Dom Macedo Costa', value: 'Dom Macedo Costa' },
  { label: 'Dom Pedrito', value: 'Dom Pedrito' },
  { label: 'Dom Pedro', value: 'Dom Pedro' },
  { label: 'Dom Pedro De Alcântara', value: 'Dom Pedro De Alcântara' },
  { label: 'Dom Silvério', value: 'Dom Silvério' },
  { label: 'Dom Viçoso', value: 'Dom Viçoso' },
  { label: 'Doma', value: 'Doma' },
  { label: 'Domagnano', value: 'Domagnano' },
  { label: 'Domagné', value: 'Domagné' },
  { label: 'Domalain', value: 'Domalain' },
  { label: 'Domalanoan', value: 'Domalanoan' },
  { label: 'Domalanoan', value: 'Domalanoan' },
  { label: 'Domaljevac', value: 'Domaljevac' },
  { label: 'Domampot', value: 'Domampot' },
  { label: 'Domampot', value: 'Domampot' },
  { label: 'Domancy', value: 'Domancy' },
  { label: 'Domănești', value: 'Domănești' },
  { label: 'Domaniç', value: 'Domaniç' },
  { label: 'Domanice', value: 'Domanice' },
  { label: 'Domanico', value: 'Domanico' },
  { label: 'Domaniewice', value: 'Domaniewice' },
  { label: 'Domanín', value: 'Domanín' },
  { label: 'Domanivka', value: 'Domanivka' },
  { label: 'Domaradz', value: 'Domaradz' },
  { label: 'Domariaganj', value: 'Domariaganj' },
  { label: 'Domarin', value: 'Domarin' },
  { label: 'Domart-En-Ponthieu', value: 'Domart-En-Ponthieu' },
  { label: 'Domašinec', value: 'Domašinec' },
  { label: 'Domaşnea', value: 'Domaşnea' },
  { label: 'Domaso', value: 'Domaso' },
  { label: 'Domaszék', value: 'Domaszék' },
  { label: 'Domaszowice', value: 'Domaszowice' },
  { label: 'Domaszowice', value: 'Domaszowice' },
  { label: 'Domat', value: 'Domat' },
  { label: 'Domažlice', value: 'Domažlice' },
  { label: 'Dombarovskiy', value: 'Dombarovskiy' },
  { label: 'Dombås', value: 'Dombås' },
  { label: 'Dombasle-Sur-Meurthe', value: 'Dombasle-Sur-Meurthe' },
  { label: 'Dombegyház', value: 'Dombegyház' },
  { label: 'Dombivli', value: 'Dombivli' },
  { label: 'Dombóvár', value: 'Dombóvár' },
  { label: 'Dombóvári Járás', value: 'Dombóvári Járás' },
  { label: 'Dombrád', value: 'Dombrád' },
  { label: 'Dombresson', value: 'Dombresson' },
  { label: 'Dombühl', value: 'Dombühl' },
  { label: 'Domburg', value: 'Domburg' },
  { label: 'Domdidier', value: 'Domdidier' },
  { label: 'Dome', value: 'Dome' },
  { label: 'Domegge Di Cadore', value: 'Domegge Di Cadore' },
  { label: 'Domène', value: 'Domène' },
  { label: 'Domeño', value: 'Domeño' },
  { label: 'Domérat', value: 'Domérat' },
  { label: 'Domersleben', value: 'Domersleben' },
  { label: 'Domessin', value: 'Domessin' },
  { label: 'Domfront', value: 'Domfront' },
  { label: 'Domgermain', value: 'Domgermain' },
  { label: 'Domicella', value: 'Domicella' },
  { label: 'Domingo Arenas', value: 'Domingo Arenas' },
  { label: 'Domingo García', value: 'Domingo García' },
  { label: 'Domingo Pérez', value: 'Domingo Pérez' },
  { label: 'Domingos Martins', value: 'Domingos Martins' },
  { label: 'Domingos Mourão', value: 'Domingos Mourão' },
  { label: 'Domínguez', value: 'Domínguez' },
  { label: 'Dominowo', value: 'Dominowo' },
  { label: 'Domio', value: 'Domio' },
  { label: 'Dömitz', value: 'Dömitz' },
  { label: 'Domjur', value: 'Domjur' },
  { label: 'Dom-Le-Mesnil', value: 'Dom-Le-Mesnil' },
  { label: 'Dommartin-Lès-Remiremont', value: 'Dommartin-Lès-Remiremont' },
  { label: 'Dommartin-Lès-Toul', value: 'Dommartin-Lès-Toul' },
  { label: 'Domme', value: 'Domme' },
  { label: 'Dommelen', value: 'Dommelen' },
  { label: 'Dommershausen', value: 'Dommershausen' },
  { label: 'Dommitzsch', value: 'Dommitzsch' },
  { label: 'Domna', value: 'Domna' },
  { label: 'Domneşti', value: 'Domneşti' },
  { label: 'Domnești', value: 'Domnești' },
  { label: 'Domneşti-Sârbi', value: 'Domneşti-Sârbi' },
  { label: 'Domnești-Târg', value: 'Domnești-Târg' },
  { label: 'Domnița', value: 'Domnița' },
  { label: 'Domodedovo', value: 'Domodedovo' },
  { label: 'Domodedovskiy Rayon', value: 'Domodedovskiy Rayon' },
  { label: 'Domodossola', value: 'Domodossola' },
  { label: 'Domokós', value: 'Domokós' },
  { label: 'Domoni', value: 'Domoni' },
  { label: 'Domont', value: 'Domont' },
  { label: 'Domony', value: 'Domony' },
  { label: 'Dömös', value: 'Dömös' },
  { label: 'Domoszló', value: 'Domoszló' },
  { label: 'Dompierre-Sur-Besbre', value: 'Dompierre-Sur-Besbre' },
  { label: 'Dompierre-Sur-Mer', value: 'Dompierre-Sur-Mer' },
  { label: 'Dompierre-Sur-Veyle', value: 'Dompierre-Sur-Veyle' },
  { label: 'Dompierre-Sur-Yon', value: 'Dompierre-Sur-Yon' },
  { label: 'Dompu', value: 'Dompu' },
  { label: 'Dömsöd', value: 'Dömsöd' },
  { label: 'Domsühl', value: 'Domsühl' },
  { label: 'Domus De Maria', value: 'Domus De Maria' },
  { label: 'Domusnovas', value: 'Domusnovas' },
  { label: 'Domvraína', value: 'Domvraína' },
  { label: 'Domžale', value: 'Domžale' },
  { label: 'Don', value: 'Don' },
  { label: 'Don', value: 'Don' },
  { label: 'Don', value: 'Don' },
  { label: 'Don Álvaro', value: 'Don Álvaro' },
  { label: 'Don Antonio', value: 'Don Antonio' },
  { label: 'Don Benito', value: 'Don Benito' },
  { label: 'Don Carlos', value: 'Don Carlos' },
  { label: 'Don Diego', value: 'Don Diego' },
  { label: 'Don Francisco', value: 'Don Francisco' },
  { label: 'Don Juan', value: 'Don Juan' },
  { label: 'Don Luan', value: 'Don Luan' },
  { label: 'Don Marcelino', value: 'Don Marcelino' },
  { label: 'Don Marcelino', value: 'Don Marcelino' },
  { label: 'Don Mueang', value: 'Don Mueang' },
  { label: 'Don Pedro', value: 'Don Pedro' },
  { label: 'Don Pedro', value: 'Don Pedro' },
  { label: 'Don Sak', value: 'Don Sak' },
  { label: 'Don Samuel', value: 'Don Samuel' },
  { label: 'Doña Ana', value: 'Doña Ana' },
  { label: 'Doña Ana County', value: 'Doña Ana County' },
  { label: 'Dona Emma', value: 'Dona Emma' },
  { label: 'Dona Eusébia', value: 'Dona Eusébia' },
  { label: 'Dona Francisca', value: 'Dona Francisca' },
  { label: 'Dona Inês', value: 'Dona Inês' },
  { label: 'Doña Mencía', value: 'Doña Mencía' },
  { label: 'Doña Remedios Trinidad', value: 'Doña Remedios Trinidad' },
  { label: 'Doña Remedios Trinidad', value: 'Doña Remedios Trinidad' },
  { label: 'Donabate', value: 'Donabate' },
  { label: 'Donaciano Ojeda', value: 'Donaciano Ojeda' },
  { label: 'Donaghadee', value: 'Donaghadee' },
  { label: 'Donaghmede', value: 'Donaghmede' },
  { label: 'Donaji', value: 'Donaji' },
  { label: 'Donald', value: 'Donald' },
  { label: 'Donald', value: 'Donald' },
  { label: 'Donaldsonville', value: 'Donaldsonville' },
  { label: 'Donalsonville', value: 'Donalsonville' },
  { label: 'Donamaria', value: 'Donamaria' },
  { label: 'Donato', value: 'Donato' },
  { label: 'Donaueschingen', value: 'Donaueschingen' },
  { label: 'Donaustadt', value: 'Donaustadt' },
  { label: 'Donaustauf', value: 'Donaustauf' },
  { label: 'Donauwörth', value: 'Donauwörth' },
  { label: 'Donawitz', value: 'Donawitz' },
  { label: 'Doncaster', value: 'Doncaster' },
  { label: 'Doncaster', value: 'Doncaster' },
  { label: 'Doncaster East', value: 'Doncaster East' },
  { label: 'Donceel', value: 'Donceel' },
  { label: 'Donchery', value: 'Donchery' },
  { label: 'Doncourt-Lès-Conflans', value: 'Doncourt-Lès-Conflans' },
  { label: 'Dondaicha', value: 'Dondaicha' },
  { label: 'Dondar Quşçu', value: 'Dondar Quşçu' },
  { label: 'Dondo', value: 'Dondo' },
  { label: 'Dondon', value: 'Dondon' },
  { label: 'Dondukovskaya', value: 'Dondukovskaya' },
  { label: 'Donduşeni', value: 'Donduşeni' },
  { label: 'Donegal', value: 'Donegal' },
  { label: 'Donetsk', value: 'Donetsk' },
  { label: 'Donetsk', value: 'Donetsk' },
  { label: 'Doneztebe', value: 'Doneztebe' },
  { label: 'Doneztebe/Santesteban', value: 'Doneztebe/Santesteban' },
  { label: 'Ðông Hà', value: 'Ðông Hà' },
  { label: 'Dongan Hills', value: 'Dongan Hills' },
  { label: 'Dongara', value: 'Dongara' },
  { label: 'Dongargaon', value: 'Dongargaon' },
  { label: 'Dongargarh', value: 'Dongargarh' },
  { label: 'Dongchongcun', value: 'Dongchongcun' },
  { label: 'Dongcun', value: 'Dongcun' },
  { label: 'Dongdaemun-Gu', value: 'Dongdaemun-Gu' },
  { label: 'Dongdai', value: 'Dongdai' },
  { label: 'Dongditou', value: 'Dongditou' },
  { label: 'Dongdu', value: 'Dongdu' },
  { label: 'Dongducheon', value: 'Dongducheon' },
  { label: 'Dongen', value: 'Dongen' },
  { label: 'Donges', value: 'Donges' },
  { label: 'Dongfeng', value: 'Dongfeng' },
  { label: 'Donggang', value: 'Donggang' },
  { label: 'Donggongon', value: 'Donggongon' },
  { label: 'Dong-Gu', value: 'Dong-Gu' },
  { label: 'Dong-Gu', value: 'Dong-Gu' },
  { label: 'Dongguan', value: 'Dongguan' },
  { label: 'Donghae-Si', value: 'Donghae-Si' },
  { label: 'Donghai', value: 'Donghai' },
  { label: 'Donghu', value: 'Donghu' },
  { label: 'Dongjak-Gu', value: 'Dongjak-Gu' },
  { label: 'Dongkan', value: 'Dongkan' },
  { label: 'Dongling', value: 'Dongling' },
  { label: 'Dongling', value: 'Dongling' },
  { label: 'Dongnae-Gu', value: 'Dongnae-Gu' },
  { label: 'Dongning', value: 'Dongning' },
  { label: 'Dongo', value: 'Dongo' },
  { label: 'Dongobesh', value: 'Dongobesh' },
  { label: 'Dongola', value: 'Dongola' },
  { label: 'Dongri', value: 'Dongri' },
  { label: 'Dongshan Dongzuxiang', value: 'Dongshan Dongzuxiang' },
  { label: 'Dongsheng', value: 'Dongsheng' },
  { label: 'Dongshigu', value: 'Dongshigu' },
  { label: 'Dongta', value: 'Dongta' },
  { label: 'Dongtai', value: 'Dongtai' },
  { label: 'Dongu', value: 'Dongu' },
  { label: 'Dongxi', value: 'Dongxi' },
  { label: 'Dongxi', value: 'Dongxi' },
  { label: 'Dongxing', value: 'Dongxing' },
  { label: 'Dongyang', value: 'Dongyang' },
  { label: 'Dongyuan', value: 'Dongyuan' },
  { label: 'Donhierro', value: 'Donhierro' },
  { label: 'Donigala Fenugheddu', value: 'Donigala Fenugheddu' },
  { label: 'Doñihue', value: 'Doñihue' },
  { label: 'Donington', value: 'Donington' },
  { label: 'Doñinos De Ledesma', value: 'Doñinos De Ledesma' },
  { label: 'Doñinos De Salamanca', value: 'Doñinos De Salamanca' },
  { label: 'Doniphan', value: 'Doniphan' },
  { label: 'Doniphan County', value: 'Doniphan County' },
  { label: 'Donisthorpe', value: 'Donisthorpe' },
  { label: 'Donja Bistra', value: 'Donja Bistra' },
  { label: 'Donja Dubica', value: 'Donja Dubica' },
  { label: 'Donja Lomnica', value: 'Donja Lomnica' },
  { label: 'Donja Mahala', value: 'Donja Mahala' },
  { label: 'Donja Međiđa', value: 'Donja Međiđa' },
  { label: 'Donja Zdenčina', value: 'Donja Zdenčina' },
  { label: 'Donje Ladanje', value: 'Donje Ladanje' },
  { label: 'Donji Andrijevci', value: 'Donji Andrijevci' },
  { label: 'Donji Dobrić', value: 'Donji Dobrić' },
  { label: 'Donji Miholjac', value: 'Donji Miholjac' },
  { label: 'Donji Milanovac', value: 'Donji Milanovac' },
  { label: 'Donji Stupnik', value: 'Donji Stupnik' },
  { label: 'Donji Vakuf', value: 'Donji Vakuf' },
  { label: 'Donji Vinjani', value: 'Donji Vinjani' },
  { label: 'Donjimeno', value: 'Donjimeno' },
  { label: 'Donk', value: 'Donk' },
  { label: 'Donkerbroek', value: 'Donkerbroek' },
  { label: 'Donley County', value: 'Donley County' },
  { label: 'Donmatías', value: 'Donmatías' },
  { label: 'Donna', value: 'Donna' },
  { label: 'Dønna', value: 'Dønna' },
  { label: 'Donnacona', value: 'Donnacona' },
  { label: 'Donnalucata', value: 'Donnalucata' },
  { label: 'Donnas', value: 'Donnas' },
  { label: 'Donnemarie-Dontilly', value: 'Donnemarie-Dontilly' },
  { label: 'Donnersbach', value: 'Donnersbach' },
  { label: 'Donnersdorf', value: 'Donnersdorf' },
  { label: 'Donnerskirchen', value: 'Donnerskirchen' },
  { label: 'Donnery', value: 'Donnery' },
  { label: 'Donnici Inferiore', value: 'Donnici Inferiore' },
  { label: 'Donnington', value: 'Donnington' },
  { label: 'Donnybrook', value: 'Donnybrook' },
  { label: 'Donnybrook', value: 'Donnybrook' },
  { label: 'Donnybrook-Balingup', value: 'Donnybrook-Balingup' },
  { label: 'Donnycarney', value: 'Donnycarney' },
  { label: 'Donora', value: 'Donora' },
  { label: 'Donoratico', value: 'Donoratico' },
  { label: 'Donorì', value: 'Donorì' },
  { label: 'Donostia-San Sebastián', value: 'Donostia-San Sebastián' },
  { label: 'Donovan Estates', value: 'Donovan Estates' },
  { label: 'Donskoy', value: 'Donskoy' },
  { label: 'Donskoy', value: 'Donskoy' },
  { label: 'Donskoye', value: 'Donskoye' },
  { label: 'Donskoye', value: 'Donskoye' },
  { label: 'Donskoye', value: 'Donskoye' },
  { label: 'Donskoye', value: 'Donskoye' },
  { label: 'Donskoye', value: 'Donskoye' },
  { label: 'Donsö', value: 'Donsö' },
  { label: 'Donsol', value: 'Donsol' },
  { label: 'Donvale', value: 'Donvale' },
  { label: 'Donvidas', value: 'Donvidas' },
  { label: 'Donville-Les-Bains', value: 'Donville-Les-Bains' },
  { label: 'Donzdorf', value: 'Donzdorf' },
  { label: 'Donzella', value: 'Donzella' },
  { label: 'Donzenac', value: 'Donzenac' },
  { label: 'Donzère', value: 'Donzère' },
  { label: 'Donzy', value: 'Donzy' },
  { label: 'Doolandella', value: 'Doolandella' },
  { label: 'Doolittle', value: 'Doolittle' },
  { label: 'Dooly County', value: 'Dooly County' },
  { label: 'Doomadgee', value: 'Doomadgee' },
  { label: 'Dooms', value: 'Dooms' },
  { label: 'Doonan', value: 'Doonan' },
  { label: 'Doong', value: 'Doong' },
  { label: 'Doonheide', value: 'Doonheide' },
  { label: 'Doonside', value: 'Doonside' },
  { label: 'Door County', value: 'Door County' },
  { label: 'Doorn', value: 'Doorn' },
  { label: 'Doornenburg', value: 'Doornenburg' },
  { label: 'Doornspijk', value: 'Doornspijk' },
  { label: 'Doornsteeg', value: 'Doornsteeg' },
  { label: 'Doorwerth', value: 'Doorwerth' },
  { label: 'Doos', value: 'Doos' },
  { label: 'Doos', value: 'Doos' },
  { label: 'Dopiewo', value: 'Dopiewo' },
  { label: 'Doppl', value: 'Doppl' },
  { label: 'Dor Mărunt', value: 'Dor Mărunt' },
  { label: 'Dora', value: 'Dora' },
  { label: 'Dora', value: 'Dora' },
  { label: 'Dora Creek', value: 'Dora Creek' },
  { label: 'Dorado', value: 'Dorado' },
  { label: 'Dorado', value: 'Dorado' },
  { label: 'Dorado Municipio', value: 'Dorado Municipio' },
  { label: 'Doraha', value: 'Doraha' },
  { label: 'Dorahak', value: 'Dorahak' },
  { label: 'Doral', value: 'Doral' },
  { label: 'Doraneh', value: 'Doraneh' },
  { label: 'Doraville', value: 'Doraville' },
  { label: 'Dorcheh', value: 'Dorcheh' },
  { label: 'Dorcheh Piaz', value: 'Dorcheh Piaz' },
  { label: 'Dorchester', value: 'Dorchester' },
  { label: 'Dorchester', value: 'Dorchester' },
  { label: 'Dorchester County', value: 'Dorchester County' },
  { label: 'Dorchester County', value: 'Dorchester County' },
  { label: 'Dordives', value: 'Dordives' },
  { label: 'Dordogne', value: 'Dordogne' },
  { label: 'Dordrecht', value: 'Dordrecht' },
  { label: 'Dordrecht', value: 'Dordrecht' },
  { label: 'Doreen', value: 'Doreen' },
  { label: 'Doren', value: 'Doren' },
  { label: 'Dörentrup', value: 'Dörentrup' },
  { label: 'Dores De Campos', value: 'Dores De Campos' },
  { label: 'Dores De Guanhães', value: 'Dores De Guanhães' },
  { label: 'Dores Do Indaiá', value: 'Dores Do Indaiá' },
  { label: 'Dores Do Rio Preto', value: 'Dores Do Rio Preto' },
  { label: 'Dores Do Turvo', value: 'Dores Do Turvo' },
  { label: 'Doresópolis', value: 'Doresópolis' },
  { label: 'Dorf', value: 'Dorf' },
  { label: 'Dorfbeuern', value: 'Dorfbeuern' },
  { label: 'Dorfchemnitz', value: 'Dorfchemnitz' },
  { label: 'Dorfen', value: 'Dorfen' },
  { label: 'Dorfgastein', value: 'Dorfgastein' },
  { label: 'Dorfhain', value: 'Dorfhain' },
  { label: 'Dörfl', value: 'Dörfl' },
  { label: 'Dorfprozelten', value: 'Dorfprozelten' },
  { label: 'Dorfstetten', value: 'Dorfstetten' },
  { label: 'Dorgali', value: 'Dorgali' },
  { label: 'Dorgeli', value: 'Dorgeli' },
  { label: 'Dorgoş', value: 'Dorgoş' },
  { label: 'Dori', value: 'Dori' },
  { label: 'Doria', value: 'Doria' },
  { label: 'Dorio', value: 'Dorio' },
  { label: 'Dorking', value: 'Dorking' },
  { label: 'Dorlisheim', value: 'Dorlisheim' },
  { label: 'Dormaa Central', value: 'Dormaa Central' },
  { label: 'Dormaa East', value: 'Dormaa East' },
  { label: 'Dormaa West', value: 'Dormaa West' },
  { label: 'Dormagen', value: 'Dormagen' },
  { label: 'Dormans', value: 'Dormans' },
  { label: 'Dormansland', value: 'Dormansland' },
  { label: 'Dormelletto', value: 'Dormelletto' },
  { label: 'Dormentes', value: 'Dormentes' },
  { label: 'Dormettingen', value: 'Dormettingen' },
  { label: 'Dormitz', value: 'Dormitz' },
  { label: 'Dormont', value: 'Dormont' },
  { label: 'Dorna Cândrenilor', value: 'Dorna Cândrenilor' },
  { label: 'Dorna-Arini', value: 'Dorna-Arini' },
  { label: 'Dornach', value: 'Dornach' },
  { label: 'Dornakal', value: 'Dornakal' },
  { label: 'Dornava', value: 'Dornava' },
  { label: 'Dornbirn', value: 'Dornbirn' },
  { label: 'Dorndorf', value: 'Dorndorf' },
  { label: 'Dornelas', value: 'Dornelas' },
  { label: 'Dornes', value: 'Dornes' },
  { label: 'Dorneşti', value: 'Dorneşti' },
  { label: 'Dorneyville', value: 'Dorneyville' },
  { label: 'Dornhan', value: 'Dornhan' },
  { label: 'Dorno', value: 'Dorno' },
  { label: 'Dornoch', value: 'Dornoch' },
  { label: 'Dornstadt', value: 'Dornstadt' },
  { label: 'Dornstetten', value: 'Dornstetten' },
  { label: 'Dornum', value: 'Dornum' },
  { label: 'Dorobanți', value: 'Dorobanți' },
  { label: 'Dorobanți', value: 'Dorobanți' },
  { label: 'Dorobanţu', value: 'Dorobanţu' },
  { label: 'Dorobanţu', value: 'Dorobanţu' },
  { label: 'Dorobanțu', value: 'Dorobanțu' },
  { label: 'Dorog', value: 'Dorog' },
  { label: 'Dorogino', value: 'Dorogino' },
  { label: 'Dorogobuzh', value: 'Dorogobuzh' },
  { label: 'Dorogomilovo', value: 'Dorogomilovo' },
  { label: 'Dorohoi', value: 'Dorohoi' },
  { label: 'Dorohusk', value: 'Dorohusk' },
  { label: 'Dorokha', value: 'Dorokha' },
  { label: 'Dorokhovo', value: 'Dorokhovo' },
  { label: 'Dorolţ', value: 'Dorolţ' },
  { label: 'Dorongan Punta', value: 'Dorongan Punta' },
  { label: 'Dorongan Punta', value: 'Dorongan Punta' },
  { label: 'Doroslovo', value: 'Doroslovo' },
  { label: 'Dorotea', value: 'Dorotea' },
  { label: 'Dorowa Mining Lease', value: 'Dorowa Mining Lease' },
  { label: 'Dorp Tera Kora', value: 'Dorp Tera Kora' },
  { label: 'Dorp Tera Kora', value: 'Dorp Tera Kora' },
  { label: 'Dörpen', value: 'Dörpen' },
  { label: 'Dorra', value: 'Dorra' },
  { label: 'Dörrenbach', value: 'Dörrenbach' },
  { label: 'Dorridge', value: 'Dorridge' },
  { label: 'Dorrigo', value: 'Dorrigo' },
  { label: 'Dorsalanam', value: 'Dorsalanam' },
  { label: 'Dorset', value: 'Dorset' },
  { label: 'Dorset', value: 'Dorset' },
  { label: 'Dorsino', value: 'Dorsino' },
  { label: 'Dorst', value: 'Dorst' },
  { label: 'Dorsten', value: 'Dorsten' },
  { label: 'Dorstone', value: 'Dorstone' },
  { label: 'Dortan', value: 'Dortan' },
  { label: 'Dörtdivan', value: 'Dörtdivan' },
  { label: 'Dortmund', value: 'Dortmund' },
  { label: 'Dörtyol', value: 'Dörtyol' },
  { label: 'Doruchów', value: 'Doruchów' },
  { label: 'Dorud', value: 'Dorud' },
  { label: 'Dorudgaran', value: 'Dorudgaran' },
  { label: 'Dorum', value: 'Dorum' },
  { label: 'Dorval', value: 'Dorval' },
  { label: 'Dörverden', value: 'Dörverden' },
  { label: 'Dorzano', value: 'Dorzano' },
  { label: 'Dörzbach', value: 'Dörzbach' },
  { label: 'Dos Aguas', value: 'Dos Aguas' },
  { label: 'Dos Arroyos', value: 'Dos Arroyos' },
  { label: 'Dos Arroyos', value: 'Dos Arroyos' },
  { label: 'Dos Bocas', value: 'Dos Bocas' },
  { label: 'Dos Ceibas', value: 'Dos Ceibas' },
  { label: 'Dos De Abril', value: 'Dos De Abril' },
  { label: 'Dos De Mayo', value: 'Dos De Mayo' },
  { label: 'Dos Hermanas', value: 'Dos Hermanas' },
  { label: 'Dos Hermanas', value: 'Dos Hermanas' },
  { label: 'Dos Montes', value: 'Dos Montes' },
  { label: 'Dos Palos', value: 'Dos Palos' },
  { label: 'Dos Ríos', value: 'Dos Ríos' },
  { label: 'Dos Ríos', value: 'Dos Ríos' },
  { label: 'Dos Torres', value: 'Dos Torres' },
  { label: 'Dosbarrios', value: 'Dosbarrios' },
  { label: 'Doschatoye', value: 'Doschatoye' },
  { label: 'Döşemealtı', value: 'Döşemealtı' },
  { label: 'Dosimo', value: 'Dosimo' },
  { label: 'Dosolo', value: 'Dosolo' },
  { label: 'Dospat', value: 'Dospat' },
  { label: 'Dosquebradas', value: 'Dosquebradas' },
  { label: 'Dosrius', value: 'Dosrius' },
  { label: 'Dossena', value: 'Dossena' },
  { label: 'Dossenheim', value: 'Dossenheim' },
  { label: 'Dossenheim-Sur-Zinsel', value: 'Dossenheim-Sur-Zinsel' },
  { label: 'Dosso', value: 'Dosso' },
  { label: 'Dosso', value: 'Dosso' },
  { label: 'Dosso Del Liro', value: 'Dosso Del Liro' },
  { label: 'Dossobuono', value: 'Dossobuono' },
  { label: 'Dosson', value: 'Dosson' },
  { label: 'Dossor', value: 'Dossor' },
  { label: 'Dosso-Ville', value: 'Dosso-Ville' },
  { label: 'Doştat', value: 'Doştat' },
  { label: 'Dostoyevka', value: 'Dostoyevka' },
  { label: 'Dostpur', value: 'Dostpur' },
  { label: 'Dota', value: 'Dota' },
  { label: 'Dotegiare', value: 'Dotegiare' },
  { label: 'Dothan', value: 'Dothan' },
  { label: 'Doti ', value: 'Doti ' },
  { label: 'Dötlingen', value: 'Dötlingen' },
  { label: 'Dotternhausen', value: 'Dotternhausen' },
  { label: 'Dottikon', value: 'Dottikon' },
  { label: 'Douai', value: 'Douai' },
  { label: 'Douala', value: 'Douala' },
  { label: 'Douar Tindja', value: 'Douar Tindja' },
  { label: 'Douarnenez', value: 'Douarnenez' },
  { label: 'Double Bay', value: 'Double Bay' },
  { label: 'Double Oak', value: 'Double Oak' },
  { label: 'Double Springs', value: 'Double Springs' },
  { label: 'Doubleview', value: 'Doubleview' },
  { label: 'Doubrava', value: 'Doubrava' },
  { label: 'Doubravice Nad Svitavou', value: 'Doubravice Nad Svitavou' },
  { label: 'Doubs', value: 'Doubs' },
  { label: 'Douchy', value: 'Douchy' },
  { label: 'Douchy-Les-Mines', value: 'Douchy-Les-Mines' },
  { label: 'Doudeville', value: 'Doudeville' },
  { label: 'Doudleby Nad Orlicí', value: 'Doudleby Nad Orlicí' },
  { label: 'Doue', value: 'Doue' },
  { label: 'Doué-La-Fontaine', value: 'Doué-La-Fontaine' },
  { label: 'Douentza', value: 'Douentza' },
  { label: 'Douentza Cercle', value: 'Douentza Cercle' },
  { label: 'Douera', value: 'Douera' },
  { label: 'Doues', value: 'Doues' },
  { label: 'Dougherty County', value: 'Dougherty County' },
  { label: 'Douglas', value: 'Douglas' },
  { label: 'Douglas', value: 'Douglas' },
  { label: 'Douglas', value: 'Douglas' },
  { label: 'Douglas', value: 'Douglas' },
  { label: 'Douglas', value: 'Douglas' },
  { label: 'Douglas', value: 'Douglas' },
  { label: 'Douglas', value: 'Douglas' },
  { label: 'Douglas', value: 'Douglas' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas County', value: 'Douglas County' },
  { label: 'Douglas Park', value: 'Douglas Park' },
  { label: 'Douglass', value: 'Douglass' },
  { label: 'Douglass Hills', value: 'Douglass Hills' },
  { label: 'Douglaston', value: 'Douglaston' },
  { label: 'Douglasville', value: 'Douglasville' },
  { label: 'Doulaincourt-Saucourt', value: 'Doulaincourt-Saucourt' },
  { label: 'Douliu', value: 'Douliu' },
  { label: 'Doullens', value: 'Doullens' },
  { label: 'Douma', value: 'Douma' },
  { label: 'Doumé', value: 'Doumé' },
  { label: 'Doune', value: 'Doune' },
  { label: 'Douniani', value: 'Douniani' },
  { label: 'Dour', value: 'Dour' },
  { label: 'Douradina', value: 'Douradina' },
  { label: 'Douradina', value: 'Douradina' },
  { label: 'Dourado', value: 'Dourado' },
  { label: 'Douradoquara', value: 'Douradoquara' },
  { label: 'Dourados', value: 'Dourados' },
  { label: 'Dourdan', value: 'Dourdan' },
  { label: 'Dourges', value: 'Dourges' },
  { label: 'Dourgne', value: 'Dourgne' },
  { label: 'Dousman', value: 'Dousman' },
  { label: 'Doussard', value: 'Doussard' },
  { label: 'Doutor Camargo', value: 'Doutor Camargo' },
  { label: 'Doutor Maurício Cardoso', value: 'Doutor Maurício Cardoso' },
  { label: 'Doutor Pedrinho', value: 'Doutor Pedrinho' },
  { label: 'Doutor Ricardo', value: 'Doutor Ricardo' },
  { label: 'Doutor Severiano', value: 'Doutor Severiano' },
  { label: 'Doutor Ulysses', value: 'Doutor Ulysses' },
  { label: 'Douvaine', value: 'Douvaine' },
  { label: 'Douvres-La-Délivrande', value: 'Douvres-La-Délivrande' },
  { label: 'Douvrin', value: 'Douvrin' },
  { label: 'Douz', value: 'Douz' },
  { label: 'Douzeh', value: 'Douzeh' },
  { label: 'Douzy', value: 'Douzy' },
  { label: 'Dovadola', value: 'Dovadola' },
  { label: 'Dovbysh', value: 'Dovbysh' },
  { label: 'Dovbysh', value: 'Dovbysh' },
  { label: 'Dove Creek', value: 'Dove Creek' },
  { label: 'Dove Holes', value: 'Dove Holes' },
  { label: 'Dove Valley', value: 'Dove Valley' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover', value: 'Dover' },
  { label: 'Dover Base Housing', value: 'Dover Base Housing' },
  { label: 'Dover Beaches North', value: 'Dover Beaches North' },
  { label: 'Dover Beaches South', value: 'Dover Beaches South' },
  { label: 'Dover Gardens', value: 'Dover Gardens' },
  { label: 'Dover Heights', value: 'Dover Heights' },
  { label: 'Dover Plains', value: 'Dover Plains' },
  { label: 'Dovera', value: 'Dovera' },
  { label: 'Dovercourt', value: 'Dovercourt' },
  { label: 'Dover-Foxcroft', value: 'Dover-Foxcroft' },
  { label: 'Doveridge', value: 'Doveridge' },
  { label: 'Doverlândia', value: 'Doverlândia' },
  { label: 'Doveton', value: 'Doveton' },
  { label: 'Dovhe', value: 'Dovhe' },
  { label: 'Dovre', value: 'Dovre' },
  { label: 'Dovzhanskyy Rayon', value: 'Dovzhanskyy Rayon' },
  { label: 'Dow Baraleh', value: 'Dow Baraleh' },
  { label: 'Dow Sari', value: 'Dow Sari' },
  { label: 'Dowa', value: 'Dowa' },
  { label: 'Dowa District', value: 'Dowa District' },
  { label: 'Dowagiac', value: 'Dowagiac' },
  { label: 'Dowerin', value: 'Dowerin' },
  { label: 'Dowlat Abad', value: 'Dowlat Abad' },
  { label: 'Dowlatabad', value: 'Dowlatabad' },
  { label: 'Dowlatābād', value: 'Dowlatābād' },
  { label: 'Dowlatabad-E Hajjilu', value: 'Dowlatabad-E Hajjilu' },
  { label: 'Dowling', value: 'Dowling' },
  { label: 'Dowlujerdin-E Bala', value: 'Dowlujerdin-E Bala' },
  { label: 'Down Town Montego Bay', value: 'Down Town Montego Bay' },
  { label: 'Downer', value: 'Downer' },
  { label: 'Downers Grove', value: 'Downers Grove' },
  { label: 'Downey', value: 'Downey' },
  { label: 'Downham Market', value: 'Downham Market' },
  { label: 'Downieville', value: 'Downieville' },
  { label: 'Downingtown', value: 'Downingtown' },
  { label: 'Downpatrick', value: 'Downpatrick' },
  { label: 'Downs', value: 'Downs' },
  { label: 'Downton', value: 'Downton' },
  { label: 'Downtown Core', value: 'Downtown Core' },
  { label: 'Dowsk', value: 'Dowsk' },
  { label: 'Doxey', value: 'Doxey' },
  { label: 'Doxhicho', value: 'Doxhicho' },
  { label: 'Doxteje Barrio Primero', value: 'Doxteje Barrio Primero' },
  { label: 'Doxteje Centro', value: 'Doxteje Centro' },
  { label: 'Doyet', value: 'Doyet' },
  { label: 'Doylestown', value: 'Doylestown' },
  { label: 'Doylestown', value: 'Doylestown' },
  { label: 'Doyong', value: 'Doyong' },
  { label: 'Doyong', value: 'Doyong' },
  { label: 'Dozón', value: 'Dozón' },
  { label: 'Dozulé', value: 'Dozulé' },
  { label: 'Dozza', value: 'Dozza' },
  { label: 'Dr Kenneth Kaunda District Municipality', value: 'Dr Kenneth Kaunda District Municipality' },
  { label: 'Dr Ruth Segomotsi Mompati District Municipality', value: 'Dr Ruth Segomotsi Mompati District Municipality' },
  { label: 'Dr. Belisario Domínguez', value: 'Dr. Belisario Domínguez' },
  { label: 'Draa Ben Khedda', value: 'Draa Ben Khedda' },
  { label: 'Draa El Mizan', value: 'Draa El Mizan' },
  { label: 'Drabiv', value: 'Drabiv' },
  { label: 'Dracea', value: 'Dracea' },
  { label: 'Dracena', value: 'Dracena' },
  { label: 'Dračevo', value: 'Dračevo' },
  { label: 'Drachselsried', value: 'Drachselsried' },
  { label: 'Drachten', value: 'Drachten' },
  { label: 'Drachtstercompagnie', value: 'Drachtstercompagnie' },
  { label: 'Dracșani', value: 'Dracșani' },
  { label: 'Drăcşani', value: 'Drăcşani' },
  { label: 'Drăcșenei', value: 'Drăcșenei' },
  { label: 'Drăculea Bandului', value: 'Drăculea Bandului' },
  { label: 'Dracut', value: 'Dracut' },
  { label: 'Dracy-Le-Fort', value: 'Dracy-Le-Fort' },
  { label: 'Drăgăești Ungureni', value: 'Drăgăești Ungureni' },
  { label: 'Dragalina', value: 'Dragalina' },
  { label: 'Drăgăneasa', value: 'Drăgăneasa' },
  { label: 'Drăgăneşti', value: 'Drăgăneşti' },
  { label: 'Drăgăneşti', value: 'Drăgăneşti' },
  { label: 'Drăgăneşti', value: 'Drăgăneşti' },
  { label: 'Drăgănești', value: 'Drăgănești' },
  { label: 'Drăgăneşti De Vede', value: 'Drăgăneşti De Vede' },
  { label: 'Drăgăneşti-Olt', value: 'Drăgăneşti-Olt' },
  { label: 'Drăgăneşti-Vlaşca', value: 'Drăgăneşti-Vlaşca' },
  { label: 'Drăganu-Olteni', value: 'Drăganu-Olteni' },
  { label: 'Drăgăşani', value: 'Drăgăşani' },
  { label: 'Drage', value: 'Drage' },
  { label: 'Drăgeşti', value: 'Drăgeşti' },
  { label: 'Drăghia', value: 'Drăghia' },
  { label: 'Drăghiceni', value: 'Drăghiceni' },
  { label: 'Drăghici', value: 'Drăghici' },
  { label: 'Drăghinești', value: 'Drăghinești' },
  { label: 'Draginje', value: 'Draginje' },
  { label: 'Dragodana', value: 'Dragodana' },
  { label: 'Dragodănești', value: 'Dragodănești' },
  { label: 'Drăgoeşti', value: 'Drăgoeşti' },
  { label: 'Drăgoești', value: 'Drăgoești' },
  { label: 'Drăgoeşti-Snagov', value: 'Drăgoeşti-Snagov' },
  { label: 'Drăgoiești', value: 'Drăgoiești' },
  { label: 'Dragoman', value: 'Dragoman' },
  { label: 'Dragomer', value: 'Dragomer' },
  { label: 'Dragomir', value: 'Dragomir' },
  { label: 'Dragomireşti', value: 'Dragomireşti' },
  { label: 'Dragomireşti', value: 'Dragomireşti' },
  { label: 'Dragomireşti', value: 'Dragomireşti' },
  { label: 'Dragomireşti', value: 'Dragomireşti' },
  { label: 'Dragomirești-Deal', value: 'Dragomirești-Deal' },
  { label: 'Dragomireşti-Vale', value: 'Dragomireşti-Vale' },
  { label: 'Dragonea', value: 'Dragonea' },
  { label: 'Dragoni', value: 'Dragoni' },
  { label: 'Dragør', value: 'Dragør' },
  { label: 'Dragør Kommune', value: 'Dragør Kommune' },
  { label: 'Dragoș Vodă', value: 'Dragoș Vodă' },
  { label: 'Dragoslavele', value: 'Dragoslavele' },
  { label: 'Dragosloveni', value: 'Dragosloveni' },
  { label: 'Drăgoteşti', value: 'Drăgoteşti' },
  { label: 'Drăgoteşti', value: 'Drăgoteşti' },
  { label: 'Dragsfjärd', value: 'Dragsfjärd' },
  { label: 'Dragu', value: 'Dragu' },
  { label: 'Drăgugești', value: 'Drăgugești' },
  { label: 'Draguignan', value: 'Draguignan' },
  { label: 'Drăguș', value: 'Drăguș' },
  { label: 'Drăguşeni', value: 'Drăguşeni' },
  { label: 'Drăguşeni', value: 'Drăguşeni' },
  { label: 'Drăguşeni', value: 'Drăguşeni' },
  { label: 'Drăgușeni', value: 'Drăgușeni' },
  { label: 'Drăgușeni', value: 'Drăgușeni' },
  { label: 'Drăguţeşti', value: 'Drăguţeşti' },
  { label: 'Drahanovice', value: 'Drahanovice' },
  { label: 'Drahichyn', value: 'Drahichyn' },
  { label: 'Drahichynski Rayon', value: 'Drahichynski Rayon' },
  { label: 'Drahovo', value: 'Drahovo' },
  { label: 'Drain', value: 'Drain' },
  { label: 'Drain', value: 'Drain' },
  { label: 'Drajna De Jos', value: 'Drajna De Jos' },
  { label: 'Drajna De Sus', value: 'Drajna De Sus' },
  { label: 'Drajna Nouă', value: 'Drajna Nouă' },
  { label: 'Drakenburg', value: 'Drakenburg' },
  { label: 'Drakhtik', value: 'Drakhtik' },
  { label: 'Drakino', value: 'Drakino' },
  { label: 'Dráma', value: 'Dráma' },
  { label: 'Drammen', value: 'Drammen' },
  { label: 'Drânceni', value: 'Drânceni' },
  { label: 'Drancy', value: 'Drancy' },
  { label: 'Dranda', value: 'Dranda' },
  { label: 'Dranesville', value: 'Dranesville' },
  { label: 'Drangedal', value: 'Drangedal' },
  { label: 'Drangstedt', value: 'Drangstedt' },
  { label: 'Drănic', value: 'Drănic' },
  { label: 'Dranovățu', value: 'Dranovățu' },
  { label: 'Dransfeld', value: 'Dransfeld' },
  { label: 'Dranske', value: 'Dranske' },
  { label: 'Drap', value: 'Drap' },
  { label: 'Draper', value: 'Draper' },
  { label: 'Drapers', value: 'Drapers' },
  { label: 'Draperstown', value: 'Draperstown' },
  { label: 'Drapetsóna', value: 'Drapetsóna' },
  { label: 'Drapia', value: 'Drapia' },
  { label: 'Draschen', value: 'Draschen' },
  { label: 'Drasenhofen', value: 'Drasenhofen' },
  { label: 'Drásov', value: 'Drásov' },
  { label: 'Drassburg', value: 'Drassburg' },
  { label: 'Drauț', value: 'Drauț' },
  { label: 'Draveil', value: 'Draveil' },
  { label: 'Dravískos', value: 'Dravískos' },
  { label: 'Dravlje District', value: 'Dravlje District' },
  { label: 'Dravograd', value: 'Dravograd' },
  { label: 'Dravosburg', value: 'Dravosburg' },
  { label: 'Drawno', value: 'Drawno' },
  { label: 'Drawsko', value: 'Drawsko' },
  { label: 'Drawsko Pomorskie', value: 'Drawsko Pomorskie' },
  { label: 'Draxini', value: 'Draxini' },
  { label: 'Draycott', value: 'Draycott' },
  { label: 'Drayton', value: 'Drayton' },
  { label: 'Drayton Valley', value: 'Drayton Valley' },
  { label: 'Draž', value: 'Draž' },
  { label: 'Dražice', value: 'Dražice' },
  { label: 'Drean', value: 'Drean' },
  { label: 'Drebach', value: 'Drebach' },
  { label: 'Drebkau', value: 'Drebkau' },
  { label: 'Drechterland', value: 'Drechterland' },
  { label: 'Dreetz', value: 'Dreetz' },
  { label: 'Drefféac', value: 'Drefféac' },
  { label: 'Dreghorn', value: 'Dreghorn' },
  { label: 'Dreieich', value: 'Dreieich' },
  { label: 'Dreierwalde', value: 'Dreierwalde' },
  { label: 'Dreikirchen', value: 'Dreikirchen' },
  { label: 'Dreis', value: 'Dreis' },
  { label: 'Dreisen', value: 'Dreisen' },
  { label: 'Drelów', value: 'Drelów' },
  { label: 'Drelsdorf', value: 'Drelsdorf' },
  { label: 'Drémil-Lafage', value: 'Drémil-Lafage' },
  { label: 'Drena', value: 'Drena' },
  { label: 'Drenova', value: 'Drenova' },
  { label: 'Drenovac', value: 'Drenovac' },
  { label: 'Drenovci', value: 'Drenovci' },
  { label: 'Drenovets', value: 'Drenovets' },
  { label: 'Drensteinfurt', value: 'Drensteinfurt' },
  { label: 'Drentwede', value: 'Drentwede' },
  { label: 'Drépanon', value: 'Drépanon' },
  { label: 'Dresano', value: 'Dresano' },
  { label: 'Dresden', value: 'Dresden' },
  { label: 'Dresden', value: 'Dresden' },
  { label: 'Dresden', value: 'Dresden' },
  { label: 'Dresher', value: 'Dresher' },
  { label: 'Dreslincourt', value: 'Dreslincourt' },
  { label: 'Dreuil-Lès-Amiens', value: 'Dreuil-Lès-Amiens' },
  { label: 'Dreux', value: 'Dreux' },
  { label: 'Dřevohostice', value: 'Dřevohostice' },
  { label: 'Drew', value: 'Drew' },
  { label: 'Drew County', value: 'Drew County' },
  { label: 'Drewsland', value: 'Drewsland' },
  { label: 'Drewvale', value: 'Drewvale' },
  { label: 'Drexel', value: 'Drexel' },
  { label: 'Drexel', value: 'Drexel' },
  { label: 'Drexel Heights', value: 'Drexel Heights' },
  { label: 'Drexel Hill', value: 'Drexel Hill' },
  { label: 'Drezdenko', value: 'Drezdenko' },
  { label: 'Drezna', value: 'Drezna' },
  { label: 'Drežnica', value: 'Drežnica' },
  { label: 'Dridu', value: 'Dridu' },
  { label: 'Driebergen-Rijsenburg', value: 'Driebergen-Rijsenburg' },
  { label: 'Driebes', value: 'Driebes' },
  { label: 'Driebruggen', value: 'Driebruggen' },
  { label: 'Driedorf', value: 'Driedorf' },
  { label: 'Driefontein', value: 'Driefontein' },
  { label: 'Driehuis', value: 'Driehuis' },
  { label: 'Driehuizen', value: 'Driehuizen' },
  { label: 'Driehuizen', value: 'Driehuizen' },
  { label: 'Driel', value: 'Driel' },
  { label: 'Driemanspolder', value: 'Driemanspolder' },
  { label: 'Driemond', value: 'Driemond' },
  { label: 'Driffield', value: 'Driffield' },
  { label: 'Driggs', value: 'Driggs' },
  { label: 'Drinovci', value: 'Drinovci' },
  { label: 'Driouch Province', value: 'Driouch Province' },
  { label: 'Dripping Springs', value: 'Dripping Springs' },
  { label: 'Dřiteň', value: 'Dřiteň' },
  { label: 'Driver', value: 'Driver' },
  { label: 'Driyorejo', value: 'Driyorejo' },
  { label: 'Drizzona', value: 'Drizzona' },
  { label: 'Drnholec', value: 'Drnholec' },
  { label: 'Drniš', value: 'Drniš' },
  { label: 'Drnje', value: 'Drnje' },
  { label: 'Drnovice', value: 'Drnovice' },
  { label: 'Dro', value: 'Dro' },
  { label: 'Drøbak', value: 'Drøbak' },
  { label: 'Drobeta-Turnu Severin', value: 'Drobeta-Turnu Severin' },
  { label: 'Drobin', value: 'Drobin' },
  { label: 'Drochia', value: 'Drochia' },
  { label: 'Drochtersen', value: 'Drochtersen' },
  { label: 'Drocourt', value: 'Drocourt' },
  { label: 'Drofino', value: 'Drofino' },
  { label: 'Drogeham', value: 'Drogeham' },
  { label: 'Drogenbos', value: 'Drogenbos' },
  { label: 'Drogheda', value: 'Drogheda' },
  { label: 'Drogomyśl', value: 'Drogomyśl' },
  { label: 'Drohiczyn', value: 'Drohiczyn' },
  { label: 'Drohobych', value: 'Drohobych' },
  { label: 'Droichead Nua', value: 'Droichead Nua' },
  { label: 'Droitwich', value: 'Droitwich' },
  { label: 'Drojdii', value: 'Drojdii' },
  { label: 'Drolshagen', value: 'Drolshagen' },
  { label: 'Dromana', value: 'Dromana' },
  { label: 'Dromiskin', value: 'Dromiskin' },
  { label: 'Dromore', value: 'Dromore' },
  { label: 'Dronero', value: 'Dronero' },
  { label: 'Dronfield', value: 'Dronfield' },
  { label: 'Drongan', value: 'Drongan' },
  { label: 'Dronninglund', value: 'Dronninglund' },
  { label: 'Dronryp', value: 'Dronryp' },
  { label: 'Dronten', value: 'Dronten' },
  { label: 'Drosiá', value: 'Drosiá' },
  { label: 'Drösing', value: 'Drösing' },
  { label: 'Droß', value: 'Droß' },
  { label: 'Droué', value: 'Droué' },
  { label: 'Droue-Sur-Drouette', value: 'Droue-Sur-Drouette' },
  { label: 'Drouin', value: 'Drouin' },
  { label: 'Drovyanaya', value: 'Drovyanaya' },
  { label: 'Droylsden', value: 'Droylsden' },
  { label: 'Droyßig', value: 'Droyßig' },
  { label: 'Drozhzhanovskiy Rayon', value: 'Drozhzhanovskiy Rayon' },
  { label: 'Drübeck', value: 'Drübeck' },
  { label: 'Drubiaglio-Grangia', value: 'Drubiaglio-Grangia' },
  { label: 'Druelle', value: 'Druelle' },
  { label: 'Druento', value: 'Druento' },
  { label: 'Drugovo', value: 'Drugovo' },
  { label: 'Druid Hills', value: 'Druid Hills' },
  { label: 'Drulingen', value: 'Drulingen' },
  { label: 'Drum Point', value: 'Drum Point' },
  { label: 'Drumblair', value: 'Drumblair' },
  { label: 'Drumcondra', value: 'Drumcondra' },
  { label: 'Drumettaz', value: 'Drumettaz' },
  { label: 'Drummond Cove', value: 'Drummond Cove' },
  { label: 'Drummondville', value: 'Drummondville' },
  { label: 'Drummoyne', value: 'Drummoyne' },
  { label: 'Drumnadrochit', value: 'Drumnadrochit' },
  { label: 'Drumpt', value: 'Drumpt' },
  { label: 'Drumright', value: 'Drumright' },
  { label: 'Druogno', value: 'Druogno' },
  { label: 'Drusenheim', value: 'Drusenheim' },
  { label: 'Druskininkai', value: 'Druskininkai' },
  { label: 'Druten', value: 'Druten' },
  { label: 'Druya', value: 'Druya' },
  { label: 'Drużbice', value: 'Drużbice' },
  { label: 'Druzhba', value: 'Druzhba' },
  { label: 'Druzhba', value: 'Druzhba' },
  { label: 'Druzhba', value: 'Druzhba' },
  { label: 'Druzhba', value: 'Druzhba' },
  { label: 'Druzhba', value: 'Druzhba' },
  { label: 'Druzhba', value: 'Druzhba' },
  { label: 'Druzhba', value: 'Druzhba' },
  { label: 'Druzhinino', value: 'Druzhinino' },
  { label: 'Druzhkivka', value: 'Druzhkivka' },
  { label: 'Druzhnaya Gorka', value: 'Druzhnaya Gorka' },
  { label: 'Druzhny', value: 'Druzhny' },
  { label: 'Drvar', value: 'Drvar' },
  { label: 'Dry', value: 'Dry' },
  { label: 'Dry Ridge', value: 'Dry Ridge' },
  { label: 'Dry Ridge', value: 'Dry Ridge' },
  { label: 'Dry Run', value: 'Dry Run' },
  { label: 'Dryanovo', value: 'Dryanovo' },
  { label: 'Drybin', value: 'Drybin' },
  { label: 'Drybinski Rayon', value: 'Drybinski Rayon' },
  { label: 'Drybrook', value: 'Drybrook' },
  { label: 'Dryden', value: 'Dryden' },
  { label: 'Dryden', value: 'Dryden' },
  { label: 'Dryden', value: 'Dryden' },
  { label: 'Drymós', value: 'Drymós' },
  { label: 'Drysdale', value: 'Drysdale' },
  { label: 'Drzewica', value: 'Drzewica' },
  { label: 'Drzycim', value: 'Drzycim' },
  { label: 'Dschang', value: 'Dschang' },
  { label: 'Dsegh', value: 'Dsegh' },
  { label: 'Du Quoin', value: 'Du Quoin' },
  { label: 'Dualchi', value: 'Dualchi' },
  { label: 'Dualing', value: 'Dualing' },
  { label: 'Dualing', value: 'Dualing' },
  { label: 'Duanvale', value: 'Duanvale' },
  { label: 'Duarte', value: 'Duarte' },
  { label: 'Duarte', value: 'Duarte' },
  { label: 'Duartina', value: 'Duartina' },
  { label: 'Duas Barras', value: 'Duas Barras' },
  { label: 'Duas Estradas', value: 'Duas Estradas' },
  { label: 'Dub Nad Moravou', value: 'Dub Nad Moravou' },
  { label: 'Duba', value: 'Duba' },
  { label: 'Dubá', value: 'Dubá' },
  { label: 'Dubai', value: 'Dubai' },
  { label: 'Dubăsari', value: 'Dubăsari' },
  { label: 'Duba-Yurt', value: 'Duba-Yurt' },
  { label: 'Dubbo', value: 'Dubbo' },
  { label: 'Dübendorf', value: 'Dübendorf' },
  { label: 'Dübendorf / Kunklerstrasse', value: 'Dübendorf / Kunklerstrasse' },
  { label: 'Dübendorf / Sonnenberg', value: 'Dübendorf / Sonnenberg' },
  { label: 'Dübendorf / Vogelquartier', value: 'Dübendorf / Vogelquartier' },
  { label: 'Dübendorf / Wasserfurren', value: 'Dübendorf / Wasserfurren' },
  { label: 'Dubí', value: 'Dubí' },
  { label: 'Dubicko', value: 'Dubicko' },
  { label: 'Dubiecko', value: 'Dubiecko' },
  { label: 'Dubienka', value: 'Dubienka' },
  { label: 'Dubinino', value: 'Dubinino' },
  { label: 'Dubino', value: 'Dubino' },
  { label: 'Dubiștea De Pădure', value: 'Dubiștea De Pădure' },
  { label: 'Dubivka', value: 'Dubivka' },
  { label: 'Dubki', value: 'Dubki' },
  { label: 'Dubki', value: 'Dubki' },
  { label: 'Dubki', value: 'Dubki' },
  { label: 'Dublin', value: 'Dublin' },
  { label: 'Dublin', value: 'Dublin' },
  { label: 'Dublin', value: 'Dublin' },
  { label: 'Dublin', value: 'Dublin' },
  { label: 'Dublin', value: 'Dublin' },
  { label: 'Dublin', value: 'Dublin' },
  { label: 'Dublin', value: 'Dublin' },
  { label: 'Dublin', value: 'Dublin' },
  { label: 'Dublin City', value: 'Dublin City' },
  { label: 'Dublje', value: 'Dublje' },
  { label: 'Dublyany', value: 'Dublyany' },
  { label: 'Dubna', value: 'Dubna' },
  { label: 'Dubna', value: 'Dubna' },
  { label: 'Dubňany', value: 'Dubňany' },
  { label: 'Dubné', value: 'Dubné' },
  { label: 'Dubnica Nad Váhom', value: 'Dubnica Nad Váhom' },
  { label: 'Dubno', value: 'Dubno' },
  { label: 'Dubnyki', value: 'Dubnyki' },
  { label: 'Dubois', value: 'Dubois' },
  { label: 'Dubois', value: 'Dubois' },
  { label: 'Dubois County', value: 'Dubois County' },
  { label: 'Duboistown', value: 'Duboistown' },
  { label: 'Duboka', value: 'Duboka' },
  { label: 'Dubonnaire', value: 'Dubonnaire' },
  { label: 'Dubova', value: 'Dubova' },
  { label: 'Dubove', value: 'Dubove' },
  { label: 'Dubovka', value: 'Dubovka' },
  { label: 'Dubovka', value: 'Dubovka' },
  { label: 'Dubovskoye', value: 'Dubovskoye' },
  { label: 'Dubovyy Umët', value: 'Dubovyy Umët' },
  { label: 'Dubrajpur', value: 'Dubrajpur' },
  { label: 'Dubrassay', value: 'Dubrassay' },
  { label: 'Dubrava', value: 'Dubrava' },
  { label: 'Dubrave Donje', value: 'Dubrave Donje' },
  { label: 'Dubrave Gornje', value: 'Dubrave Gornje' },
  { label: 'Dubravica', value: 'Dubravica' },
  { label: 'Dubréka', value: 'Dubréka' },
  { label: 'Dubreuil', value: 'Dubreuil' },
  { label: 'Dubrivka', value: 'Dubrivka' },
  { label: 'Dubrovačko Primorje', value: 'Dubrovačko Primorje' },
  { label: 'Dubrovino', value: 'Dubrovino' },
  { label: 'Dubrovitsy', value: 'Dubrovitsy' },
  { label: 'Dubrovka', value: 'Dubrovka' },
  { label: 'Dubrovka', value: 'Dubrovka' },
  { label: 'Dubrovskiy Rayon', value: 'Dubrovskiy Rayon' },
  { label: 'Dubrovytsya', value: 'Dubrovytsya' },
  { label: 'Dubrowna', value: 'Dubrowna' },
  { label: 'Dubti', value: 'Dubti' },
  { label: 'Dubuque', value: 'Dubuque' },
  { label: 'Dubuque County', value: 'Dubuque County' },
  { label: 'Đưc Trọng', value: 'Đưc Trọng' },
  { label: 'Duće', value: 'Duće' },
  { label: 'Ducey', value: 'Ducey' },
  { label: 'Duchcov', value: 'Duchcov' },
  { label: 'Ducheng', value: 'Ducheng' },
  { label: 'Ducherow', value: 'Ducherow' },
  { label: 'Duchesne', value: 'Duchesne' },
  { label: 'Duchesne County', value: 'Duchesne County' },
  { label: 'Duck Hill', value: 'Duck Hill' },
  { label: 'Duck Lake', value: 'Duck Lake' },
  { label: 'Duckenfield', value: 'Duckenfield' },
  { label: 'Ducklington', value: 'Ducklington' },
  { label: 'Duclair', value: 'Duclair' },
  { label: 'Duczki', value: 'Duczki' },
  { label: 'Duda', value: 'Duda' },
  { label: 'Dúdar', value: 'Dúdar' },
  { label: 'Dudașu', value: 'Dudașu' },
  { label: 'Dudchany', value: 'Dudchany' },
  { label: 'Dudelange', value: 'Dudelange' },
  { label: 'Dudeldorf', value: 'Dudeldorf' },
  { label: 'Dudenhofen', value: 'Dudenhofen' },
  { label: 'Duderstadt', value: 'Duderstadt' },
  { label: 'Dudeşti', value: 'Dudeşti' },
  { label: 'Dudeștii Noi', value: 'Dudeștii Noi' },
  { label: 'Dudeştii Vechi', value: 'Dudeştii Vechi' },
  { label: 'Dudhani', value: 'Dudhani' },
  { label: 'Dudhi', value: 'Dudhi' },
  { label: 'Dudince', value: 'Dudince' },
  { label: 'Düdingen', value: 'Düdingen' },
  { label: 'Dudinka', value: 'Dudinka' },
  { label: 'Dudley', value: 'Dudley' },
  { label: 'Dudley', value: 'Dudley' },
  { label: 'Dudley', value: 'Dudley' },
  { label: 'Dudley Park', value: 'Dudley Park' },
  { label: 'Dudu', value: 'Dudu' },
  { label: 'Due Carrare', value: 'Due Carrare' },
  { label: 'Due West', value: 'Due West' },
  { label: 'Duekoué', value: 'Duekoué' },
  { label: 'Dueñas', value: 'Dueñas' },
  { label: 'Dueñas', value: 'Dueñas' },
  { label: 'Duenweg', value: 'Duenweg' },
  { label: 'Dueré', value: 'Dueré' },
  { label: 'Duero', value: 'Duero' },
  { label: 'Duesaigües', value: 'Duesaigües' },
  { label: 'Dueville', value: 'Dueville' },
  { label: 'Duffel', value: 'Duffel' },
  { label: 'Duffield', value: 'Duffield' },
  { label: 'Dufftown', value: 'Dufftown' },
  { label: 'Duffy', value: 'Duffy' },
  { label: 'Dugadda', value: 'Dugadda' },
  { label: 'Dugard', value: 'Dugard' },
  { label: 'Dugard', value: 'Dugard' },
  { label: 'Dugcal', value: 'Dugcal' },
  { label: 'Dugda', value: 'Dugda' },
  { label: 'Dugenta', value: 'Dugenta' },
  { label: 'Duggendorf', value: 'Duggendorf' },
  { label: 'Dughaybjah', value: 'Dughaybjah' },
  { label: 'Dugi Rat Općina', value: 'Dugi Rat Općina' },
  { label: 'Dugny', value: 'Dugny' },
  { label: 'Dugny-Sur-Meuse', value: 'Dugny-Sur-Meuse' },
  { label: 'Dugongan', value: 'Dugongan' },
  { label: 'Dugopolje', value: 'Dugopolje' },
  { label: 'Dugulubgey', value: 'Dugulubgey' },
  { label: 'Duhaney Park', value: 'Duhaney Park' },
  { label: 'Duhaney Pen', value: 'Duhaney Pen' },
  { label: 'Duindorp', value: 'Duindorp' },
  { label: 'Duingen', value: 'Duingen' },
  { label: 'Duino', value: 'Duino' },
  { label: 'Duinzigt', value: 'Duinzigt' },
  { label: 'Duisans', value: 'Duisans' },
  { label: 'Duisburg', value: 'Duisburg' },
  { label: 'Duitama', value: 'Duitama' },
  { label: 'Duiven', value: 'Duiven' },
  { label: 'Duivendrecht', value: 'Duivendrecht' },
  { label: 'Duiwelskloof', value: 'Duiwelskloof' },
  { label: 'Duizel', value: 'Duizel' },
  { label: 'Dujuuma', value: 'Dujuuma' },
  { label: 'Dukat', value: 'Dukat' },
  { label: 'Dukay', value: 'Dukay' },
  { label: 'Dukay', value: 'Dukay' },
  { label: 'Dukes County', value: 'Dukes County' },
  { label: 'Dukhān', value: 'Dukhān' },
  { label: 'Dukhnah', value: 'Dukhnah' },
  { label: 'Dukhovnitskoye', value: 'Dukhovnitskoye' },
  { label: 'Dukhovshchina', value: 'Dukhovshchina' },
  { label: 'Duki', value: 'Duki' },
  { label: 'Dukinfield', value: 'Dukinfield' },
  { label: 'Dukku', value: 'Dukku' },
  { label: 'Dukla', value: 'Dukla' },
  { label: 'Dukora', value: 'Dukora' },
  { label: 'Dūkštas', value: 'Dūkštas' },
  { label: 'Duku', value: 'Duku' },
  { label: 'Dukuhturi', value: 'Dukuhturi' },
  { label: 'Dukwe', value: 'Dukwe' },
  { label: 'Dul’Durga', value: 'Dul’Durga' },
  { label: 'Dulac', value: 'Dulac' },
  { label: 'Dulag', value: 'Dulag' },
  { label: 'Dulag', value: 'Dulag' },
  { label: 'Dulangan', value: 'Dulangan' },
  { label: 'Dulangan', value: 'Dulangan' },
  { label: 'Dulao', value: 'Dulao' },
  { label: 'Dulce', value: 'Dulce' },
  { label: 'Dulce Grande', value: 'Dulce Grande' },
  { label: 'Dulce Nombre', value: 'Dulce Nombre' },
  { label: 'Dulce Nombre De Culmí', value: 'Dulce Nombre De Culmí' },
  { label: 'Dulcea', value: 'Dulcea' },
  { label: 'Dulceşti', value: 'Dulceşti' },
  { label: 'Dulcești', value: 'Dulcești' },
  { label: 'Duleek', value: 'Duleek' },
  { label: 'Duliagaon', value: 'Duliagaon' },
  { label: 'Dulian', value: 'Dulian' },
  { label: 'Duliby', value: 'Duliby' },
  { label: 'Dulig', value: 'Dulig' },
  { label: 'Dulig', value: 'Dulig' },
  { label: 'Dulkadiroğlu', value: 'Dulkadiroğlu' },
  { label: 'Dulles Town Center', value: 'Dulles Town Center' },
  { label: 'Dullewala', value: 'Dullewala' },
  { label: 'Dülmen', value: 'Dülmen' },
  { label: 'Đulovac', value: 'Đulovac' },
  { label: 'Dulovo', value: 'Dulovo' },
  { label: 'Duluth', value: 'Duluth' },
  { label: 'Duluth', value: 'Duluth' },
  { label: 'Dulverton', value: 'Dulverton' },
  { label: 'Dulwich', value: 'Dulwich' },
  { label: 'Dulwich Hill', value: 'Dulwich Hill' },
  { label: 'Dulyapino', value: 'Dulyapino' },
  { label: 'Dum Duma', value: 'Dum Duma' },
  { label: 'Dumabato', value: 'Dumabato' },
  { label: 'Dumabato', value: 'Dumabato' },
  { label: 'Dumaguete', value: 'Dumaguete' },
  { label: 'Dumaguil', value: 'Dumaguil' },
  { label: 'Dumai', value: 'Dumai' },
  { label: 'Dumalag', value: 'Dumalag' },
  { label: 'Dumalaguing', value: 'Dumalaguing' },
  { label: 'Dumalinao', value: 'Dumalinao' },
  { label: 'Dumalneg', value: 'Dumalneg' },
  { label: 'Dumalneg', value: 'Dumalneg' },
  { label: 'Dumangas', value: 'Dumangas' },
  { label: 'Dumanjog', value: 'Dumanjog' },
  { label: 'Dumarais', value: 'Dumarais' },
  { label: 'Dumaran', value: 'Dumaran' },
  { label: 'Dumarao', value: 'Dumarao' },
  { label: 'Dumas', value: 'Dumas' },
  { label: 'Dumas', value: 'Dumas' },
  { label: 'Dumbarton', value: 'Dumbarton' },
  { label: 'Dumbarton', value: 'Dumbarton' },
  { label: 'Dumbéa', value: 'Dumbéa' },
  { label: 'Dumbleyung Shire', value: 'Dumbleyung Shire' },
  { label: 'Dumbrava', value: 'Dumbrava' },
  { label: 'Dumbrava', value: 'Dumbrava' },
  { label: 'Dumbrava', value: 'Dumbrava' },
  { label: 'Dumbrava', value: 'Dumbrava' },
  { label: 'Dumbrava', value: 'Dumbrava' },
  { label: 'Dumbrava', value: 'Dumbrava' },
  { label: 'Dumbrava', value: 'Dumbrava' },
  { label: 'Dumbrava', value: 'Dumbrava' },
  { label: 'Dumbrava De Sus', value: 'Dumbrava De Sus' },
  { label: 'Dumbrava Roşie', value: 'Dumbrava Roşie' },
  { label: 'Dumbrăveni', value: 'Dumbrăveni' },
  { label: 'Dumbrăveni', value: 'Dumbrăveni' },
  { label: 'Dumbrăveni', value: 'Dumbrăveni' },
  { label: 'Dumbrăveni', value: 'Dumbrăveni' },
  { label: 'Dumbrăveşti', value: 'Dumbrăveşti' },
  { label: 'Dumbrăvioara', value: 'Dumbrăvioara' },
  { label: 'Dumbrăviţa', value: 'Dumbrăviţa' },
  { label: 'Dumbrăviţa', value: 'Dumbrăviţa' },
  { label: 'Dumbrăviţa', value: 'Dumbrăviţa' },
  { label: 'Dumbrăvița', value: 'Dumbrăvița' },
  { label: 'Dumbrăvița', value: 'Dumbrăvița' },
  { label: 'Dumbrăvița', value: 'Dumbrăvița' },
  { label: 'Dumbría', value: 'Dumbría' },
  { label: 'Dumeni', value: 'Dumeni' },
  { label: 'Dumenza', value: 'Dumenza' },
  { label: 'Dumeşti', value: 'Dumeşti' },
  { label: 'Dumeşti', value: 'Dumeşti' },
  { label: 'Dumfries', value: 'Dumfries' },
  { label: 'Dumfries', value: 'Dumfries' },
  { label: 'Dumfries', value: 'Dumfries' },
  { label: 'Dumfries And Galloway', value: 'Dumfries And Galloway' },
  { label: 'Dumingag', value: 'Dumingag' },
  { label: 'Duminichi', value: 'Duminichi' },
  { label: 'Duminichskiy Rayon', value: 'Duminichskiy Rayon' },
  { label: 'Dumitra', value: 'Dumitra' },
  { label: 'Dumitreni', value: 'Dumitreni' },
  { label: 'Dumitrești', value: 'Dumitrești' },
  { label: 'Dumitrești', value: 'Dumitrești' },
  { label: 'Dumitreștii', value: 'Dumitreștii' },
  { label: 'Dumitrița', value: 'Dumitrița' },
  { label: 'Dumka', value: 'Dumka' },
  { label: 'Dumkhal', value: 'Dumkhal' },
  { label: 'Dumlan', value: 'Dumlan' },
  { label: 'Dumlan', value: 'Dumlan' },
  { label: 'Dumlan', value: 'Dumlan' },
  { label: 'Dumlupınar', value: 'Dumlupınar' },
  { label: 'Dümmer', value: 'Dümmer' },
  { label: 'Dummerstorf', value: 'Dummerstorf' },
  { label: 'Dumont', value: 'Dumont' },
  { label: 'Dumont', value: 'Dumont' },
  { label: 'Dumpay', value: 'Dumpay' },
  { label: 'Dumpay', value: 'Dumpay' },
  { label: 'Dumra', value: 'Dumra' },
  { label: 'Dumraon', value: 'Dumraon' },
  { label: 'Dún Laoghaire', value: 'Dún Laoghaire' },
  { label: 'Dún Laoghaire-Rathdown', value: 'Dún Laoghaire-Rathdown' },
  { label: 'Dunaalmás', value: 'Dunaalmás' },
  { label: 'Dunabogdány', value: 'Dunabogdány' },
  { label: 'Dunaföldvár', value: 'Dunaföldvár' },
  { label: 'Dunaharaszti', value: 'Dunaharaszti' },
  { label: 'Dunaivtsi', value: 'Dunaivtsi' },
  { label: 'Dunajská Lužná', value: 'Dunajská Lužná' },
  { label: 'Dunajská Streda', value: 'Dunajská Streda' },
  { label: 'Dunakeszi', value: 'Dunakeszi' },
  { label: 'Dunakeszi Járás', value: 'Dunakeszi Járás' },
  { label: 'Dunapataj', value: 'Dunapataj' },
  { label: 'Dunăreni', value: 'Dunăreni' },
  { label: 'Dunaszekcső', value: 'Dunaszekcső' },
  { label: 'Dunaszentgyörgy', value: 'Dunaszentgyörgy' },
  { label: 'Dunaszentmiklós', value: 'Dunaszentmiklós' },
  { label: 'Dunaújváros', value: 'Dunaújváros' },
  { label: 'Dunaújvárosi Járás', value: 'Dunaújvárosi Járás' },
  { label: 'Dunavarsány', value: 'Dunavarsány' },
  { label: 'Dunavecse', value: 'Dunavecse' },
  { label: 'Dunavtsi', value: 'Dunavtsi' },
  { label: 'Dunay', value: 'Dunay' },
  { label: 'Dunbar', value: 'Dunbar' },
  { label: 'Dunbar', value: 'Dunbar' },
  { label: 'Dunbar', value: 'Dunbar' },
  { label: 'Dunblane', value: 'Dunblane' },
  { label: 'Dunboyne', value: 'Dunboyne' },
  { label: 'Duncan', value: 'Duncan' },
  { label: 'Duncan', value: 'Duncan' },
  { label: 'Duncan', value: 'Duncan' },
  { label: 'Duncan Town', value: 'Duncan Town' },
  { label: 'Duncannon', value: 'Duncannon' },
  { label: 'Duncans', value: 'Duncans' },
  { label: 'Duncansville', value: 'Duncansville' },
  { label: 'Duncanville', value: 'Duncanville' },
  { label: 'Dunchurch', value: 'Dunchurch' },
  { label: 'Duncraig', value: 'Duncraig' },
  { label: 'Dundaga', value: 'Dundaga' },
  { label: 'Dundalk', value: 'Dundalk' },
  { label: 'Dundalk', value: 'Dundalk' },
  { label: 'Dundas', value: 'Dundas' },
  { label: 'Dundas', value: 'Dundas' },
  { label: 'Dundas Valley', value: 'Dundas Valley' },
  { label: 'Dundee', value: 'Dundee' },
  { label: 'Dundee', value: 'Dundee' },
  { label: 'Dundee', value: 'Dundee' },
  { label: 'Dundee', value: 'Dundee' },
  { label: 'Dundee', value: 'Dundee' },
  { label: 'Dundee', value: 'Dundee' },
  { label: 'Dundee', value: 'Dundee' },
  { label: 'Dundee City', value: 'Dundee City' },
  { label: 'Dundonald', value: 'Dundonald' },
  { label: 'Dundonald', value: 'Dundonald' },
  { label: 'Dundong', value: 'Dundong' },
  { label: 'Dundowran Beach', value: 'Dundowran Beach' },
  { label: 'Dundrum', value: 'Dundrum' },
  { label: 'Dundrum', value: 'Dundrum' },
  { label: 'Dundry', value: 'Dundry' },
  { label: 'Dundy County', value: 'Dundy County' },
  { label: 'Dunean', value: 'Dunean' },
  { label: 'Dunedin', value: 'Dunedin' },
  { label: 'Dunedin', value: 'Dunedin' },
  { label: 'Dunedoo', value: 'Dunedoo' },
  { label: 'Dunellen', value: 'Dunellen' },
  { label: 'Dunes City', value: 'Dunes City' },
  { label: 'Dunfermline', value: 'Dunfermline' },
  { label: 'Dunga Bunga', value: 'Dunga Bunga' },
  { label: 'Dungannon', value: 'Dungannon' },
  { label: 'Dungarpur', value: 'Dungarpur' },
  { label: 'Dungarpur', value: 'Dungarpur' },
  { label: 'Dungarvan', value: 'Dungarvan' },
  { label: 'Düngenheim', value: 'Düngenheim' },
  { label: 'Dungiven', value: 'Dungiven' },
  { label: 'Dungloe', value: 'Dungloe' },
  { label: 'Dungog', value: 'Dungog' },
  { label: 'Dungon', value: 'Dungon' },
  { label: 'Dunguan', value: 'Dunguan' },
  { label: 'Dunguan', value: 'Dunguan' },
  { label: 'Dunham', value: 'Dunham' },
  { label: 'Dunholme', value: 'Dunholme' },
  { label: 'Dunhua', value: 'Dunhua' },
  { label: 'Dunières', value: 'Dunières' },
  { label: 'Dunipace', value: 'Dunipace' },
  { label: 'Dunkerque', value: 'Dunkerque' },
  { label: 'Dunkeswell', value: 'Dunkeswell' },
  { label: 'Dunkirk', value: 'Dunkirk' },
  { label: 'Dunkirk', value: 'Dunkirk' },
  { label: 'Dunkirk', value: 'Dunkirk' },
  { label: 'Dunkirk Town Center', value: 'Dunkirk Town Center' },
  { label: 'Dunklin County', value: 'Dunklin County' },
  { label: 'Dunkwa', value: 'Dunkwa' },
  { label: 'Dunlap', value: 'Dunlap' },
  { label: 'Dunlap', value: 'Dunlap' },
  { label: 'Dunlap', value: 'Dunlap' },
  { label: 'Dunlap', value: 'Dunlap' },
  { label: 'Dunleer', value: 'Dunleer' },
  { label: 'Dun-Le-Palestel', value: 'Dun-Le-Palestel' },
  { label: 'Dunlewy', value: 'Dunlewy' },
  { label: 'Dunlop', value: 'Dunlop' },
  { label: 'Dunlop', value: 'Dunlop' },
  { label: 'Dunloy', value: 'Dunloy' },
  { label: 'Dunmanway', value: 'Dunmanway' },
  { label: 'Dunmore', value: 'Dunmore' },
  { label: 'Dunmore East', value: 'Dunmore East' },
  { label: 'Dunmore Town', value: 'Dunmore Town' },
  { label: 'Dunn', value: 'Dunn' },
  { label: 'Dunn County', value: 'Dunn County' },
  { label: 'Dunn County', value: 'Dunn County' },
  { label: 'Dunn Loring', value: 'Dunn Loring' },
  { label: 'Dunnellon', value: 'Dunnellon' },
  { label: 'Dunnigan', value: 'Dunnigan' },
  { label: 'Dunningen', value: 'Dunningen' },
  { label: 'Dunnington', value: 'Dunnington' },
  { label: 'Dunnstown', value: 'Dunnstown' },
  { label: 'Duno', value: 'Duno' },
  { label: 'Dunoon', value: 'Dunoon' },
  { label: 'Duns', value: 'Duns' },
  { label: 'Düns', value: 'Düns' },
  { label: 'Dunsborough', value: 'Dunsborough' },
  { label: 'Dünsen', value: 'Dünsen' },
  { label: 'Dünserberg', value: 'Dünserberg' },
  { label: 'Dunshaughlin', value: 'Dunshaughlin' },
  { label: 'Dunsmuir', value: 'Dunsmuir' },
  { label: 'Dunstable', value: 'Dunstable' },
  { label: 'Dunstable', value: 'Dunstable' },
  { label: 'Dun-Sur-Auron', value: 'Dun-Sur-Auron' },
  { label: 'Dunswell', value: 'Dunswell' },
  { label: 'Duntocher', value: 'Duntocher' },
  { label: 'Dunum', value: 'Dunum' },
  { label: 'Dunwoody', value: 'Dunwoody' },
  { label: 'Dünyamalılar', value: 'Dünyamalılar' },
  { label: 'Dunyapur', value: 'Dunyapur' },
  { label: 'Duobao', value: 'Duobao' },
  { label: 'Duomo', value: 'Duomo' },
  { label: 'Dương Đông', value: 'Dương Đông' },
  { label: 'După Deal', value: 'După Deal' },
  { label: 'Dupage County', value: 'Dupage County' },
  { label: 'Dupax Del Norte', value: 'Dupax Del Norte' },
  { label: 'Dupax Del Sur', value: 'Dupax Del Sur' },
  { label: 'Duplin County', value: 'Duplin County' },
  { label: 'Dupnitsa', value: 'Dupnitsa' },
  { label: 'Dupo', value: 'Dupo' },
  { label: 'Dupont', value: 'Dupont' },
  { label: 'Dupont', value: 'Dupont' },
  { label: 'Duppigheim', value: 'Duppigheim' },
  { label: 'Dupre', value: 'Dupre' },
  { label: 'Dupree', value: 'Dupree' },
  { label: 'Duque Bacelar', value: 'Duque Bacelar' },
  { label: 'Duque De Caxias', value: 'Duque De Caxias' },
  { label: 'Duquesne', value: 'Duquesne' },
  { label: 'Duquesne', value: 'Duquesne' },
  { label: 'Durach', value: 'Durach' },
  { label: 'Durack', value: 'Durack' },
  { label: 'Durack', value: 'Durack' },
  { label: 'Durağan', value: 'Durağan' },
  { label: 'Dural', value: 'Dural' },
  { label: 'Duran', value: 'Duran' },
  { label: 'Durand', value: 'Durand' },
  { label: 'Durand', value: 'Durand' },
  { label: 'Durand', value: 'Durand' },
  { label: 'Durandé', value: 'Durandé' },
  { label: 'Durandeau', value: 'Durandeau' },
  { label: 'Durango', value: 'Durango' },
  { label: 'Durango', value: 'Durango' },
  { label: 'Durango', value: 'Durango' },
  { label: 'Durania', value: 'Durania' },
  { label: 'Durant', value: 'Durant' },
  { label: 'Durant', value: 'Durant' },
  { label: 'Durant', value: 'Durant' },
  { label: 'Duras', value: 'Duras' },
  { label: 'Durazno', value: 'Durazno' },
  { label: 'Durazzano', value: 'Durazzano' },
  { label: 'Durbach', value: 'Durbach' },
  { label: 'Durban', value: 'Durban' },
  { label: 'Dürbheim', value: 'Dürbheim' },
  { label: 'Durbuy', value: 'Durbuy' },
  { label: 'Dúrcal', value: 'Dúrcal' },
  { label: 'Đurđenovac', value: 'Đurđenovac' },
  { label: 'Đurđevac', value: 'Đurđevac' },
  { label: 'Ðurđevo', value: 'Ðurđevo' },
  { label: 'Düren', value: 'Düren' },
  { label: 'Durg', value: 'Durg' },
  { label: 'Durgapur', value: 'Durgapur' },
  { label: 'Durgapur', value: 'Durgapur' },
  { label: 'Durgapur Chandrapur', value: 'Durgapur Chandrapur' },
  { label: 'Durgerdam', value: 'Durgerdam' },
  { label: 'Durham', value: 'Durham' },
  { label: 'Durham', value: 'Durham' },
  { label: 'Durham', value: 'Durham' },
  { label: 'Durham', value: 'Durham' },
  { label: 'Durham', value: 'Durham' },
  { label: 'Durham', value: 'Durham' },
  { label: 'Durham', value: 'Durham' },
  { label: 'Durham', value: 'Durham' },
  { label: 'Durham', value: 'Durham' },
  { label: 'Durham County', value: 'Durham County' },
  { label: 'Ðurići', value: 'Ðurići' },
  { label: 'Durijan', value: 'Durijan' },
  { label: 'Durlangen', value: 'Durlangen' },
  { label: 'Đurmanec', value: 'Đurmanec' },
  { label: 'Dürmentingen', value: 'Dürmentingen' },
  { label: 'Durmersheim', value: 'Durmersheim' },
  { label: 'Dürnau', value: 'Dürnau' },
  { label: 'Dürnau', value: 'Dürnau' },
  { label: 'Durneşti', value: 'Durneşti' },
  { label: 'Durnian', value: 'Durnian' },
  { label: 'Dürnkrut', value: 'Dürnkrut' },
  { label: 'Dürnstein', value: 'Dürnstein' },
  { label: 'Dürnstein In Der Steiermark', value: 'Dürnstein In Der Steiermark' },
  { label: 'Dürnten', value: 'Dürnten' },
  { label: 'Durón', value: 'Durón' },
  { label: 'Duronia', value: 'Duronia' },
  { label: 'Dürrenäsch', value: 'Dürrenäsch' },
  { label: 'Durrenbach', value: 'Durrenbach' },
  { label: 'Dürrenroth', value: 'Dürrenroth' },
  { label: 'Durrës', value: 'Durrës' },
  { label: 'Durrës District', value: 'Durrës District' },
  { label: 'Dürrhennersdorf', value: 'Dürrhennersdorf' },
  { label: 'Dürrlauingen', value: 'Dürrlauingen' },
  { label: 'Dürrröhrsdorf', value: 'Dürrröhrsdorf' },
  { label: 'Dürrwangen', value: 'Dürrwangen' },
  { label: 'Dursley', value: 'Dursley' },
  { label: 'Dursunbey', value: 'Dursunbey' },
  { label: 'Durtal', value: 'Durtal' },
  { label: 'Durtol', value: 'Durtol' },
  { label: 'Duruelo', value: 'Duruelo' },
  { label: 'Duruelo De La Sierra', value: 'Duruelo De La Sierra' },
  { label: 'Durușa', value: 'Durușa' },
  { label: 'Dury', value: 'Dury' },
  { label: 'Duryea', value: 'Duryea' },
  { label: 'Dusi', value: 'Dusi' },
  { label: 'Dusino', value: 'Dusino' },
  { label: 'Dusit', value: 'Dusit' },
  { label: 'Dusnok', value: 'Dusnok' },
  { label: 'Duson', value: 'Duson' },
  { label: 'Düsseldorf', value: 'Düsseldorf' },
  { label: 'Düsseldorf District', value: 'Düsseldorf District' },
  { label: 'Düsseldorf-Pempelfort', value: 'Düsseldorf-Pempelfort' },
  { label: 'Dußlingen', value: 'Dußlingen' },
  { label: 'Dust Mohammad', value: 'Dust Mohammad' },
  { label: 'Dŭstí', value: 'Dŭstí' },
  { label: 'Dŭstlik', value: 'Dŭstlik' },
  { label: 'Duszniki', value: 'Duszniki' },
  { label: 'Duszniki-Zdrój', value: 'Duszniki-Zdrój' },
  { label: 'Dutch Harbor', value: 'Dutch Harbor' },
  { label: 'Dutchess County', value: 'Dutchess County' },
  { label: 'Dutlwe', value: 'Dutlwe' },
  { label: 'Dutse', value: 'Dutse' },
  { label: 'Dutsen Wai', value: 'Dutsen Wai' },
  { label: 'Dutsin-Ma', value: 'Dutsin-Ma' },
  { label: 'Duttlenheim', value: 'Duttlenheim' },
  { label: 'Dutton Park', value: 'Dutton Park' },
  { label: 'Duval County', value: 'Duval County' },
  { label: 'Duval County', value: 'Duval County' },
  { label: 'Duvall', value: 'Duvall' },
  { label: 'Duvan', value: 'Duvan' },
  { label: 'Duvenstedt', value: 'Duvenstedt' },
  { label: 'Duvergé', value: 'Duvergé' },
  { label: 'Duxbury', value: 'Duxbury' },
  { label: 'Duxford', value: 'Duxford' },
  { label: 'Duyun', value: 'Duyun' },
  { label: 'Duyure', value: 'Duyure' },
  { label: 'Duzduzan', value: 'Duzduzan' },
  { label: 'Düziçi', value: 'Düziçi' },
  { label: 'Düzköy', value: 'Düzköy' },
  { label: 'Dve Mogili', value: 'Dve Mogili' },
  { label: 'Dvin', value: 'Dvin' },
  { label: 'Dvinskoy', value: 'Dvinskoy' },
  { label: 'Dvokodvweni Inkhundla', value: 'Dvokodvweni Inkhundla' },
  { label: 'Dvor', value: 'Dvor' },
  { label: 'Dvorce', value: 'Dvorce' },
  { label: 'Dvorichna', value: 'Dvorichna' },
  { label: 'Dvorishche', value: 'Dvorishche' },
  { label: 'Dvorovi', value: 'Dvorovi' },
  { label: 'Dvortsy', value: 'Dvortsy' },
  { label: 'Dvubratskiy', value: 'Dvubratskiy' },
  { label: 'Dvůr Králové Nad Labem', value: 'Dvůr Králové Nad Labem' },
  { label: 'Dvurechensk', value: 'Dvurechensk' },
  { label: 'Dwarahat', value: 'Dwarahat' },
  { label: 'Dwarka', value: 'Dwarka' },
  { label: 'Dwight', value: 'Dwight' },
  { label: 'Dwikozy', value: 'Dwikozy' },
  { label: 'Dwingeloo', value: 'Dwingeloo' },
  { label: 'Dyat’Kovo', value: 'Dyat’Kovo' },
  { label: 'Dyat’Kovskiy Rayon', value: 'Dyat’Kovskiy Rayon' },
  { label: 'Dyatlovo', value: 'Dyatlovo' },
  { label: 'Dybbøl', value: 'Dybbøl' },
  { label: 'Dyce', value: 'Dyce' },
  { label: 'Dydnia', value: 'Dydnia' },
  { label: 'Dyer', value: 'Dyer' },
  { label: 'Dyer', value: 'Dyer' },
  { label: 'Dyer County', value: 'Dyer County' },
  { label: 'Dyersburg', value: 'Dyersburg' },
  { label: 'Dyersville', value: 'Dyersville' },
  { label: 'Dyffryn Ardudwy', value: 'Dyffryn Ardudwy' },
  { label: 'Dygowo', value: 'Dygowo' },
  { label: 'Dykanka', value: 'Dykanka' },
  { label: 'Dyker Heights', value: 'Dyker Heights' },
  { label: 'Dylym', value: 'Dylym' },
  { label: 'Dymchurch', value: 'Dymchurch' },
  { label: 'Dymer', value: 'Dymer' },
  { label: 'Dynnyrne', value: 'Dynnyrne' },
  { label: 'Dynów', value: 'Dynów' },
  { label: 'Dyrøy', value: 'Dyrøy' },
  { label: 'Dysart', value: 'Dysart' },
  { label: 'Dysart', value: 'Dysart' },
  { label: 'Dyserth', value: 'Dyserth' },
  { label: 'Dyshne-Vedeno', value: 'Dyshne-Vedeno' },
  { label: 'Dýšina', value: 'Dýšina' },
  { label: 'Dyurtyuli', value: 'Dyurtyuli' },
  { label: 'Dywity', value: 'Dywity' },
  { label: 'Dzagam', value: 'Dzagam' },
  { label: 'Dzahadjou', value: 'Dzahadjou' },
  { label: 'Dzan', value: 'Dzan' },
  { label: 'Dzegvi', value: 'Dzegvi' },
  { label: 'Dzemul', value: 'Dzemul' },
  { label: 'Džepčište', value: 'Džepčište' },
  { label: 'Dzerzhinsk', value: 'Dzerzhinsk' },
  { label: 'Dzerzhinsk', value: 'Dzerzhinsk' },
  { label: 'Dzerzhinskiy', value: 'Dzerzhinskiy' },
  { label: 'Dzerzhinskiy Rayon', value: 'Dzerzhinskiy Rayon' },
  { label: 'Dzerzhinskoye', value: 'Dzerzhinskoye' },
  { label: 'Dzerzhinsky', value: 'Dzerzhinsky' },
  { label: 'Dzhalagash', value: 'Dzhalagash' },
  { label: 'Dzhalil’', value: 'Dzhalil’' },
  { label: 'Dzhalka', value: 'Dzhalka' },
  { label: 'Dzhambeyty', value: 'Dzhambeyty' },
  { label: 'Dzhankoy', value: 'Dzhankoy' },
  { label: 'Dzhankoyskiy Rayon', value: 'Dzhankoyskiy Rayon' },
  { label: 'Dzhayrakh', value: 'Dzhayrakh' },
  { label: 'Dzhayrakhskiy Rayon', value: 'Dzhayrakhskiy Rayon' },
  { label: 'Dzhebariki-Khaya', value: 'Dzhebariki-Khaya' },
  { label: 'Dzhebel', value: 'Dzhebel' },
  { label: 'Dzhetygara', value: 'Dzhetygara' },
  { label: 'Dzhida', value: 'Dzhida' },
  { label: 'Dzhiginka', value: 'Dzhiginka' },
  { label: 'Dzhubga', value: 'Dzhubga' },
  { label: 'Dziadowa Kłoda', value: 'Dziadowa Kłoda' },
  { label: 'Działdowo', value: 'Działdowo' },
  { label: 'Działoszyce', value: 'Działoszyce' },
  { label: 'Działoszyn', value: 'Działoszyn' },
  { label: 'Dziani', value: 'Dziani' },
  { label: 'Dzibalchén', value: 'Dzibalchén' },
  { label: 'Dzibikak', value: 'Dzibikak' },
  { label: 'Dzidzantun', value: 'Dzidzantun' },
  { label: 'Dziećmorowice', value: 'Dziećmorowice' },
  { label: 'Dzięgielów', value: 'Dzięgielów' },
  { label: 'Dziekanów Leśny', value: 'Dziekanów Leśny' },
  { label: 'Dziemiany', value: 'Dziemiany' },
  { label: 'Dziergowice', value: 'Dziergowice' },
  { label: 'Dzierzążnia', value: 'Dzierzążnia' },
  { label: 'Dzierzgoń', value: 'Dzierzgoń' },
  { label: 'Dzierzgowo', value: 'Dzierzgowo' },
  { label: 'Dzierżoniów', value: 'Dzierżoniów' },
  { label: 'Dziewin', value: 'Dziewin' },
  { label: 'Dzików Stary', value: 'Dzików Stary' },
  { label: 'Dzikowiec', value: 'Dzikowiec' },
  { label: 'Dzilam De Bravo', value: 'Dzilam De Bravo' },
  { label: 'Dzilam González', value: 'Dzilam González' },
  { label: 'Dzisna', value: 'Dzisna' },
  { label: 'Dzit’Hank’Ov', value: 'Dzit’Hank’Ov' },
  { label: 'Dzitás', value: 'Dzitás' },
  { label: 'Dzitbalché', value: 'Dzitbalché' },
  { label: 'Dzitnup', value: 'Dzitnup' },
  { label: 'Dzityá', value: 'Dzityá' },
  { label: 'Dziuche', value: 'Dziuche' },
  { label: 'Dziwnów', value: 'Dziwnów' },
  { label: 'Dzoncauich', value: 'Dzoncauich' },
  { label: 'Dzonot Carretero', value: 'Dzonot Carretero' },
  { label: 'Dzoraghbyur', value: 'Dzoraghbyur' },
  { label: 'Dzoragyugh', value: 'Dzoragyugh' },
  { label: 'Dzorastan', value: 'Dzorastan' },
  { label: 'Dzula', value: 'Dzula' },
  { label: 'Dzununcan', value: 'Dzununcan' },
  { label: 'Dzüünharaa', value: 'Dzüünharaa' },
  { label: 'Dzuunmod', value: 'Dzuunmod' },
  { label: 'Dzvinogrud', value: 'Dzvinogrud' },
  { label: 'Dźwierzuty', value: 'Dźwierzuty' },
  { label: 'Dzwola', value: 'Dzwola' },
  { label: 'Dzyarzhynsk', value: 'Dzyarzhynsk' },
  { label: 'E’Erguna', value: 'E’Erguna' },
  { label: 'Ea', value: 'Ea' },
  { label: 'Eadestown', value: 'Eadestown' },
  { label: 'Eads', value: 'Eads' },
  { label: 'Eagan', value: 'Eagan' },
  { label: 'Eagar', value: 'Eagar' },
  { label: 'Eagle', value: 'Eagle' },
  { label: 'Eagle', value: 'Eagle' },
  { label: 'Eagle', value: 'Eagle' },
  { label: 'Eagle', value: 'Eagle' },
  { label: 'Eagle Butte', value: 'Eagle Butte' },
  { label: 'Eagle County', value: 'Eagle County' },
  { label: 'Eagle Farm', value: 'Eagle Farm' },
  { label: 'Eagle Grove', value: 'Eagle Grove' },
  { label: 'Eagle Lake', value: 'Eagle Lake' },
  { label: 'Eagle Lake', value: 'Eagle Lake' },
  { label: 'Eagle Lake', value: 'Eagle Lake' },
  { label: 'Eagle Lake', value: 'Eagle Lake' },
  { label: 'Eagle Mountain', value: 'Eagle Mountain' },
  { label: 'Eagle Mountain', value: 'Eagle Mountain' },
  { label: 'Eagle Pass', value: 'Eagle Pass' },
  { label: 'Eagle Point', value: 'Eagle Point' },
  { label: 'Eagle Point', value: 'Eagle Point' },
  { label: 'Eagle River', value: 'Eagle River' },
  { label: 'Eagle River', value: 'Eagle River' },
  { label: 'Eagle River', value: 'Eagle River' },
  { label: 'Eagle Vale', value: 'Eagle Vale' },
  { label: 'Eagleby', value: 'Eagleby' },
  { label: 'Eaglehawk', value: 'Eaglehawk' },
  { label: 'Eaglemont', value: 'Eaglemont' },
  { label: 'Eaglescliffe', value: 'Eaglescliffe' },
  { label: 'Eaglesham', value: 'Eaglesham' },
  { label: 'Eagleton Village', value: 'Eagleton Village' },
  { label: 'Eagleview', value: 'Eagleview' },
  { label: 'Eagleville', value: 'Eagleville' },
  { label: 'Ear Falls', value: 'Ear Falls' },
  { label: 'Earby', value: 'Earby' },
  { label: 'Earith', value: 'Earith' },
  { label: 'Earl Shilton', value: 'Earl Shilton' },
  { label: 'Earle', value: 'Earle' },
  { label: 'Earlham', value: 'Earlham' },
  { label: 'Earlimart', value: 'Earlimart' },
  { label: 'Earlington', value: 'Earlington' },
  { label: 'Earls Barton', value: 'Earls Barton' },
  { label: 'Earls Colne', value: 'Earls Colne' },
  { label: 'Earlsfield', value: 'Earlsfield' },
  { label: 'Earlston', value: 'Earlston' },
  { label: 'Earlston', value: 'Earlston' },
  { label: 'Earlville', value: 'Earlville' },
  { label: 'Earlville', value: 'Earlville' },
  { label: 'Earlwood', value: 'Earlwood' },
  { label: 'Early', value: 'Early' },
  { label: 'Early County', value: 'Early County' },
  { label: 'Earth', value: 'Earth' },
  { label: 'Easington', value: 'Easington' },
  { label: 'Easington', value: 'Easington' },
  { label: 'Easingwold', value: 'Easingwold' },
  { label: 'Easley', value: 'Easley' },
  { label: 'East Albury', value: 'East Albury' },
  { label: 'East Alton', value: 'East Alton' },
  { label: 'East Angus', value: 'East Angus' },
  { label: 'East Arnhem', value: 'East Arnhem' },
  { label: 'East Atlantic Beach', value: 'East Atlantic Beach' },
  { label: 'East Aurora', value: 'East Aurora' },
  { label: 'East Ayrshire', value: 'East Ayrshire' },
  { label: 'East Ayton', value: 'East Ayton' },
  { label: 'East Bairnsdale', value: 'East Bairnsdale' },
  { label: 'East Ballina', value: 'East Ballina' },
  { label: 'East Bangor', value: 'East Bangor' },
  { label: 'East Baton Rouge Parish', value: 'East Baton Rouge Parish' },
  { label: 'East Bendigo', value: 'East Bendigo' },
  { label: 'East Bergholt', value: 'East Bergholt' },
  { label: 'East Berlin', value: 'East Berlin' },
  { label: 'East Bernard', value: 'East Bernard' },
  { label: 'East Berwick', value: 'East Berwick' },
  { label: 'East Bethel', value: 'East Bethel' },
  { label: 'East Boldon', value: 'East Boldon' },
  { label: 'East Brainerd', value: 'East Brainerd' },
  { label: 'East Branxton', value: 'East Branxton' },
  { label: 'East Brewton', value: 'East Brewton' },
  { label: 'East Bridgewater', value: 'East Bridgewater' },
  { label: 'East Bridgford', value: 'East Bridgford' },
  { label: 'East Brisbane', value: 'East Brisbane' },
  { label: 'East Bronson', value: 'East Bronson' },
  { label: 'East Brookfield', value: 'East Brookfield' },
  { label: 'East Brooklyn', value: 'East Brooklyn' },
  { label: 'East Broughton', value: 'East Broughton' },
  { label: 'East Brunswick', value: 'East Brunswick' },
  { label: 'East Bunbury', value: 'East Bunbury' },
  { label: 'East Calder', value: 'East Calder' },
  { label: 'East Cannington', value: 'East Cannington' },
  { label: 'East Canton', value: 'East Canton' },
  { label: 'East Carbon City', value: 'East Carbon City' },
  { label: 'East Carnarvon', value: 'East Carnarvon' },
  { label: 'East Carroll Parish', value: 'East Carroll Parish' },
  { label: 'East Chattanooga', value: 'East Chattanooga' },
  { label: 'East Chevington', value: 'East Chevington' },
  { label: 'East Chicago', value: 'East Chicago' },
  { label: 'East Cleveland', value: 'East Cleveland' },
  { label: 'East Cleveland', value: 'East Cleveland' },
  { label: 'East Concord', value: 'East Concord' },
  { label: 'East Conemaugh', value: 'East Conemaugh' },
  { label: 'East Corrimal', value: 'East Corrimal' },
  { label: 'East Cowes', value: 'East Cowes' },
  { label: 'East Dean', value: 'East Dean' },
  { label: 'East Delhi', value: 'East Delhi' },
  { label: 'East Dennis', value: 'East Dennis' },
  { label: 'East Dereham', value: 'East Dereham' },
  { label: 'East Devonport', value: 'East Devonport' },
  { label: 'East District', value: 'East District' },
  { label: 'East Douglas', value: 'East Douglas' },
  { label: 'East Down Town', value: 'East Down Town' },
  { label: 'East Dublin', value: 'East Dublin' },
  { label: 'East Dubuque', value: 'East Dubuque' },
  { label: 'East Dunbartonshire', value: 'East Dunbartonshire' },
  { label: 'East Dundee', value: 'East Dundee' },
  { label: 'East Earl', value: 'East Earl' },
  { label: 'East Elmhurst', value: 'East Elmhurst' },
  { label: 'East End', value: 'East End' },
  { label: 'East End', value: 'East End' },
  { label: 'East End', value: 'East End' },
  { label: 'East End', value: 'East End' },
  { label: 'East Falmouth', value: 'East Falmouth' },
  { label: 'East Farmingdale', value: 'East Farmingdale' },
  { label: 'East Feliciana Parish', value: 'East Feliciana Parish' },
  { label: 'East Flat Rock', value: 'East Flat Rock' },
  { label: 'East Flatbush', value: 'East Flatbush' },
  { label: 'East Florence', value: 'East Florence' },
  { label: 'East Foothills', value: 'East Foothills' },
  { label: 'East Franklin', value: 'East Franklin' },
  { label: 'East Freehold', value: 'East Freehold' },
  { label: 'East Fremantle', value: 'East Fremantle' },
  { label: 'East Gaffney', value: 'East Gaffney' },
  { label: 'East Garden City', value: 'East Garden City' },
  { label: 'East Garfield Park', value: 'East Garfield Park' },
  { label: 'East Garo Hills', value: 'East Garo Hills' },
  { label: 'East Geelong', value: 'East Geelong' },
  { label: 'East Gippsland', value: 'East Gippsland' },
  { label: 'East Glenville', value: 'East Glenville' },
  { label: 'East Godavari', value: 'East Godavari' },
  { label: 'East Gonja', value: 'East Gonja' },
  { label: 'East Gosford', value: 'East Gosford' },
  { label: 'East Grand Forks', value: 'East Grand Forks' },
  { label: 'East Grand Rapids', value: 'East Grand Rapids' },
  { label: 'East Greenbush', value: 'East Greenbush' },
  { label: 'East Greenville', value: 'East Greenville' },
  { label: 'East Greenwich', value: 'East Greenwich' },
  { label: 'East Griffin', value: 'East Griffin' },
  { label: 'East Grinstead', value: 'East Grinstead' },
  { label: 'East Gull Lake', value: 'East Gull Lake' },
  { label: 'East Gwillimbury', value: 'East Gwillimbury' },
  { label: 'East Haddam', value: 'East Haddam' },
  { label: 'East Halmahera Regency', value: 'East Halmahera Regency' },
  { label: 'East Hampton', value: 'East Hampton' },
  { label: 'East Hampton', value: 'East Hampton' },
  { label: 'East Hampton', value: 'East Hampton' },
  { label: 'East Hampton North', value: 'East Hampton North' },
  { label: 'East Hanney', value: 'East Hanney' },
  { label: 'East Hanover', value: 'East Hanover' },
  { label: 'East Harerghe Zone', value: 'East Harerghe Zone' },
  { label: 'East Harlem', value: 'East Harlem' },
  { label: 'East Harling', value: 'East Harling' },
  { label: 'East Harptree', value: 'East Harptree' },
  { label: 'East Hartford', value: 'East Hartford' },
  { label: 'East Harwich', value: 'East Harwich' },
  { label: 'East Haven', value: 'East Haven' },
  { label: 'East Hazel Crest', value: 'East Hazel Crest' },
  { label: 'East Helena', value: 'East Helena' },
  { label: 'East Hemet', value: 'East Hemet' },
  { label: 'East Highland Park', value: 'East Highland Park' },
  { label: 'East Hill-Meridian', value: 'East Hill-Meridian' },
  { label: 'East Hills', value: 'East Hills' },
  { label: 'East Hills', value: 'East Hills' },
  { label: 'East Honolulu', value: 'East Honolulu' },
  { label: 'East Horsley', value: 'East Horsley' },
  { label: 'East Independence', value: 'East Independence' },
  { label: 'East Innisfail', value: 'East Innisfail' },
  { label: 'East Ipswich', value: 'East Ipswich' },
  { label: 'East Islip', value: 'East Islip' },
  { label: 'East Ithaca', value: 'East Ithaca' },
  { label: 'East Jaintia Hills', value: 'East Jaintia Hills' },
  { label: 'East Jindabyne', value: 'East Jindabyne' },
  { label: 'East Jordan', value: 'East Jordan' },
  { label: 'East Kameng', value: 'East Kameng' },
  { label: 'East Kempsey', value: 'East Kempsey' },
  { label: 'East Keswick', value: 'East Keswick' },
  { label: 'East Khasi Hills', value: 'East Khasi Hills' },
  { label: 'East Kilbride', value: 'East Kilbride' },
  { label: 'East Killara', value: 'East Killara' },
  { label: 'East Kingston', value: 'East Kingston' },
  { label: 'East Kurrajong', value: 'East Kurrajong' },
  { label: 'East La Mirada', value: 'East La Mirada' },
  { label: 'East Lake', value: 'East Lake' },
  { label: 'East Lake-Orient Park', value: 'East Lake-Orient Park' },
  { label: 'East Lansdowne', value: 'East Lansdowne' },
  { label: 'East Lansing', value: 'East Lansing' },
  { label: 'East Launceston', value: 'East Launceston' },
  { label: 'East Leake', value: 'East Leake' },
  { label: 'East Lexington', value: 'East Lexington' },
  { label: 'East Lindfield', value: 'East Lindfield' },
  { label: 'East Linton', value: 'East Linton' },
  { label: 'East Lismore', value: 'East Lismore' },
  { label: 'East Liverpool', value: 'East Liverpool' },
  { label: 'East London', value: 'East London' },
  { label: 'East Longmeadow', value: 'East Longmeadow' },
  { label: 'East Los Angeles', value: 'East Los Angeles' },
  { label: 'East Lothian', value: 'East Lothian' },
  { label: 'East Machias', value: 'East Machias' },
  { label: 'East Mackay', value: 'East Mackay' },
  { label: 'East Maitland', value: 'East Maitland' },
  { label: 'East Mamprusi', value: 'East Mamprusi' },
  { label: 'East Markham', value: 'East Markham' },
  { label: 'East Massapequa', value: 'East Massapequa' },
  { label: 'East Mckeesport', value: 'East Mckeesport' },
  { label: 'East Meadow', value: 'East Meadow' },
  { label: 'East Melbourne', value: 'East Melbourne' },
  { label: 'East Merrimack', value: 'East Merrimack' },
  { label: 'East Migpulao', value: 'East Migpulao' },
  { label: 'East Millcreek', value: 'East Millcreek' },
  { label: 'East Millinocket', value: 'East Millinocket' },
  { label: 'East Milton', value: 'East Milton' },
  { label: 'East Missoula', value: 'East Missoula' },
  { label: 'East Molesey', value: 'East Molesey' },
  { label: 'East Moline', value: 'East Moline' },
  { label: 'East Moriches', value: 'East Moriches' },
  { label: 'East Naples', value: 'East Naples' },
  { label: 'East New York', value: 'East New York' },
  { label: 'East Newark', value: 'East Newark' },
  { label: 'East Newnan', value: 'East Newnan' },
  { label: 'East Northport', value: 'East Northport' },
  { label: 'East Norwalk', value: 'East Norwalk' },
  { label: 'East Norwich', value: 'East Norwich' },
  { label: 'East Oakdale', value: 'East Oakdale' },
  { label: 'East Orange', value: 'East Orange' },
  { label: 'East Palatka', value: 'East Palatka' },
  { label: 'East Palestine', value: 'East Palestine' },
  { label: 'East Palo Alto', value: 'East Palo Alto' },
  { label: 'East Pasadena', value: 'East Pasadena' },
  { label: 'East Patchogue', value: 'East Patchogue' },
  { label: 'East Peckham', value: 'East Peckham' },
  { label: 'East Pensacola Heights', value: 'East Pensacola Heights' },
  { label: 'East Peoria', value: 'East Peoria' },
  { label: 'East Pepperell', value: 'East Pepperell' },
  { label: 'East Perrine', value: 'East Perrine' },
  { label: 'East Perth', value: 'East Perth' },
  { label: 'East Petersburg', value: 'East Petersburg' },
  { label: 'East Pilbara', value: 'East Pilbara' },
  { label: 'East Pittsburgh', value: 'East Pittsburgh' },
  { label: 'East Point', value: 'East Point' },
  { label: 'East Port Orchard', value: 'East Port Orchard' },
  { label: 'East Porterville', value: 'East Porterville' },
  { label: 'East Prairie', value: 'East Prairie' },
  { label: 'East Providence', value: 'East Providence' },
  { label: 'East Quincy', value: 'East Quincy' },
  { label: 'East Quogue', value: 'East Quogue' },
  { label: 'East Rainton', value: 'East Rainton' },
  { label: 'East Rancho Dominguez', value: 'East Rancho Dominguez' },
  { label: 'East Renfrewshire', value: 'East Renfrewshire' },
  { label: 'East Renton Highlands', value: 'East Renton Highlands' },
  { label: 'East Richmond Heights', value: 'East Richmond Heights' },
  { label: 'East Ridge', value: 'East Ridge' },
  { label: 'East Riding Of Yorkshire', value: 'East Riding Of Yorkshire' },
  { label: 'East Riverdale', value: 'East Riverdale' },
  { label: 'East Rochester', value: 'East Rochester' },
  { label: 'East Rockaway', value: 'East Rockaway' },
  { label: 'East Rockingham', value: 'East Rockingham' },
  { label: 'East Rutherford', value: 'East Rutherford' },
  { label: 'East Ryde', value: 'East Ryde' },
  { label: 'East Sahuarita', value: 'East Sahuarita' },
  { label: 'East Saint Louis', value: 'East Saint Louis' },
  { label: 'East San Gabriel', value: 'East San Gabriel' },
  { label: 'East Sandwich', value: 'East Sandwich' },
  { label: 'East Setauket', value: 'East Setauket' },
  { label: 'East Shewa Zone', value: 'East Shewa Zone' },
  { label: 'East Shoreham', value: 'East Shoreham' },
  { label: 'East Siang', value: 'East Siang' },
  { label: 'East Side', value: 'East Side' },
  { label: 'East Sonora', value: 'East Sonora' },
  { label: 'East Spencer', value: 'East Spencer' },
  { label: 'East Stroudsburg', value: 'East Stroudsburg' },
  { label: 'East Sumter', value: 'East Sumter' },
  { label: 'East Sussex', value: 'East Sussex' },
  { label: 'East Syracuse', value: 'East Syracuse' },
  { label: 'East Tamworth', value: 'East Tamworth' },
  { label: 'East Tawas', value: 'East Tawas' },
  { label: 'East Tilbury', value: 'East Tilbury' },
  { label: 'East Toowoomba', value: 'East Toowoomba' },
  { label: 'East Tremont', value: 'East Tremont' },
  { label: 'East Troy', value: 'East Troy' },
  { label: 'East Uniontown', value: 'East Uniontown' },
  { label: 'East Valencia', value: 'East Valencia' },
  { label: 'East Valley', value: 'East Valley' },
  { label: 'East Victoria Park', value: 'East Victoria Park' },
  { label: 'East Village', value: 'East Village' },
  { label: 'East Washington', value: 'East Washington' },
  { label: 'East Wellega Zone', value: 'East Wellega Zone' },
  { label: 'East Wellington', value: 'East Wellington' },
  { label: 'East Wemyss', value: 'East Wemyss' },
  { label: 'East Wenatchee', value: 'East Wenatchee' },
  { label: 'East Wenatchee Bench', value: 'East Wenatchee Bench' },
  { label: 'East Whitburn', value: 'East Whitburn' },
  { label: 'East Williston', value: 'East Williston' },
  { label: 'East Windsor', value: 'East Windsor' },
  { label: 'East Wittering', value: 'East Wittering' },
  { label: 'East York', value: 'East York' },
  { label: 'East York', value: 'East York' },
  { label: 'Eastbourne', value: 'Eastbourne' },
  { label: 'Eastchester', value: 'Eastchester' },
  { label: 'Eastchurch', value: 'Eastchurch' },
  { label: 'Eastermar', value: 'Eastermar' },
  { label: 'Eastern Heights', value: 'Eastern Heights' },
  { label: 'Eastern Rukum', value: 'Eastern Rukum' },
  { label: 'Eastgate', value: 'Eastgate' },
  { label: 'Eastham', value: 'Eastham' },
  { label: 'Easthampton', value: 'Easthampton' },
  { label: 'Eastington', value: 'Eastington' },
  { label: 'Eastlake', value: 'Eastlake' },
  { label: 'Eastlakes', value: 'Eastlakes' },
  { label: 'Eastland', value: 'Eastland' },
  { label: 'Eastland County', value: 'Eastland County' },
  { label: 'Eastlawn Gardens', value: 'Eastlawn Gardens' },
  { label: 'Eastleigh', value: 'Eastleigh' },
  { label: 'Eastleigh', value: 'Eastleigh' },
  { label: 'Eastman', value: 'Eastman' },
  { label: 'Eastmont', value: 'Eastmont' },
  { label: 'Eastoft', value: 'Eastoft' },
  { label: 'Easton', value: 'Easton' },
  { label: 'Easton', value: 'Easton' },
  { label: 'Easton', value: 'Easton' },
  { label: 'Easton', value: 'Easton' },
  { label: 'Easton', value: 'Easton' },
  { label: 'Easton', value: 'Easton' },
  { label: 'Easton', value: 'Easton' },
  { label: 'Easton', value: 'Easton' },
  { label: 'Easton On The Hill', value: 'Easton On The Hill' },
  { label: 'Easton-In-Gordano', value: 'Easton-In-Gordano' },
  { label: 'Eastover', value: 'Eastover' },
  { label: 'Eastpoint', value: 'Eastpoint' },
  { label: 'Eastpointe', value: 'Eastpointe' },
  { label: 'Eastport', value: 'Eastport' },
  { label: 'Eastport', value: 'Eastport' },
  { label: 'Eastriggs', value: 'Eastriggs' },
  { label: 'Eastrington', value: 'Eastrington' },
  { label: 'Eastry', value: 'Eastry' },
  { label: 'Eastvale', value: 'Eastvale' },
  { label: 'Eastville', value: 'Eastville' },
  { label: 'Eastwood', value: 'Eastwood' },
  { label: 'Eastwood', value: 'Eastwood' },
  { label: 'Eastwood', value: 'Eastwood' },
  { label: 'Eastwood', value: 'Eastwood' },
  { label: 'Eastwood Park Gardens', value: 'Eastwood Park Gardens' },
  { label: 'Eaton', value: 'Eaton' },
  { label: 'Eaton', value: 'Eaton' },
  { label: 'Eaton', value: 'Eaton' },
  { label: 'Eaton', value: 'Eaton' },
  { label: 'Eaton Bray', value: 'Eaton Bray' },
  { label: 'Eaton County', value: 'Eaton County' },
  { label: 'Eaton Estates', value: 'Eaton Estates' },
  { label: 'Eaton Rapids', value: 'Eaton Rapids' },
  { label: 'Eaton Socon', value: 'Eaton Socon' },
  { label: 'Eatons Hill', value: 'Eatons Hill' },
  { label: 'Eatons Neck', value: 'Eatons Neck' },
  { label: 'Eatonton', value: 'Eatonton' },
  { label: 'Eatontown', value: 'Eatontown' },
  { label: 'Eatonville', value: 'Eatonville' },
  { label: 'Eatonville', value: 'Eatonville' },
  { label: 'Eattington', value: 'Eattington' },
  { label: 'Eau Claire', value: 'Eau Claire' },
  { label: 'Eau Claire County', value: 'Eau Claire County' },
  { label: 'Eau Coulee', value: 'Eau Coulee' },
  { label: 'Eau Piquant/St Urbain', value: 'Eau Piquant/St Urbain' },
  { label: 'Eaubonne', value: 'Eaubonne' },
  { label: 'Eaunes', value: 'Eaunes' },
  { label: 'Eauze', value: 'Eauze' },
  { label: 'Ébano', value: 'Ébano' },
  { label: 'Ebbs', value: 'Ebbs' },
  { label: 'Ebbw Vale', value: 'Ebbw Vale' },
  { label: 'Ebcor Town', value: 'Ebcor Town' },
  { label: 'Ebcor Town', value: 'Ebcor Town' },
  { label: 'Ebebiyin', value: 'Ebebiyin' },
  { label: 'Ebéjico', value: 'Ebéjico' },
  { label: 'Ebeleben', value: 'Ebeleben' },
  { label: 'Ebelsbach', value: 'Ebelsbach' },
  { label: 'Ebeltoft', value: 'Ebeltoft' },
  { label: 'Eben Am Achensee', value: 'Eben Am Achensee' },
  { label: 'Eben Im Pongau', value: 'Eben Im Pongau' },
  { label: 'Ebenau', value: 'Ebenau' },
  { label: 'Ebendorf', value: 'Ebendorf' },
  { label: 'Ebene', value: 'Ebene' },
  { label: 'Ebenfurth', value: 'Ebenfurth' },
  { label: 'Ebensburg', value: 'Ebensburg' },
  { label: 'Ebensee', value: 'Ebensee' },
  { label: 'Ebensfeld', value: 'Ebensfeld' },
  { label: 'Ebenthal', value: 'Ebenthal' },
  { label: 'Ebenthal', value: 'Ebenthal' },
  { label: 'Ebenweiler', value: 'Ebenweiler' },
  { label: 'Eberau', value: 'Eberau' },
  { label: 'Eberbach', value: 'Eberbach' },
  { label: 'Eberdingen', value: 'Eberdingen' },
  { label: 'Ebergassing', value: 'Ebergassing' },
  { label: 'Ebergötzen', value: 'Ebergötzen' },
  { label: 'Eberhardzell', value: 'Eberhardzell' },
  { label: 'Ebermannsdorf', value: 'Ebermannsdorf' },
  { label: 'Ebermannstadt', value: 'Ebermannstadt' },
  { label: 'Eberndorf', value: 'Eberndorf' },
  { label: 'Ebernhahn', value: 'Ebernhahn' },
  { label: 'Ebersbach', value: 'Ebersbach' },
  { label: 'Ebersbach An Der Fils', value: 'Ebersbach An Der Fils' },
  { label: 'Ebersbach-Musbach', value: 'Ebersbach-Musbach' },
  { label: 'Ebersberg', value: 'Ebersberg' },
  { label: 'Eberschwang', value: 'Eberschwang' },
  { label: 'Ebersdorf', value: 'Ebersdorf' },
  { label: 'Ebersdorf', value: 'Ebersdorf' },
  { label: 'Ebersdorf', value: 'Ebersdorf' },
  { label: 'Ebersheim', value: 'Ebersheim' },
  { label: 'Eberstadt', value: 'Eberstadt' },
  { label: 'Eberstalzell', value: 'Eberstalzell' },
  { label: 'Eberstein', value: 'Eberstein' },
  { label: 'Eberswalde', value: 'Eberswalde' },
  { label: 'Ebertsheim', value: 'Ebertsheim' },
  { label: 'Ebes', value: 'Ebes' },
  { label: 'Ebetsu', value: 'Ebetsu' },
  { label: 'Ebhausen', value: 'Ebhausen' },
  { label: 'Ebikon', value: 'Ebikon' },
  { label: 'Ebina Shi', value: 'Ebina Shi' },
  { label: 'Ebino-Shi', value: 'Ebino-Shi' },
  { label: 'Ebmatingen', value: 'Ebmatingen' },
  { label: 'Ebnath', value: 'Ebnath' },
  { label: 'Ebnat-Kappel', value: 'Ebnat-Kappel' },
  { label: 'Eboli', value: 'Eboli' },
  { label: 'Ébolowa', value: 'Ébolowa' },
  { label: 'Ebreichsdorf', value: 'Ebreichsdorf' },
  { label: 'Ébreuil', value: 'Ébreuil' },
  { label: 'Ebriach', value: 'Ebriach' },
  { label: 'Ebringen', value: 'Ebringen' },
  { label: 'Ebstorf', value: 'Ebstorf' },
  { label: 'Ebute Ikorodu', value: 'Ebute Ikorodu' },
  { label: 'Ebute Metta', value: 'Ebute Metta' },
  { label: 'Écaillon', value: 'Écaillon' },
  { label: 'Ecatepec De Morelos', value: 'Ecatepec De Morelos' },
  { label: 'Ecatzingo De Hidalgo', value: 'Ecatzingo De Hidalgo' },
  { label: 'Écaussinnes-D’Enghien', value: 'Écaussinnes-D’Enghien' },
  { label: 'Eccles', value: 'Eccles' },
  { label: 'Eccleshall', value: 'Eccleshall' },
  { label: 'Eccleston', value: 'Eccleston' },
  { label: 'Eceabat', value: 'Eceabat' },
  { label: 'Ech Chettia', value: 'Ech Chettia' },
  { label: 'Echague', value: 'Echague' },
  { label: 'Echague (Town)', value: 'Echague (Town)' },
  { label: 'Échalas', value: 'Échalas' },
  { label: 'Echallens', value: 'Echallens' },
  { label: 'Echaporã', value: 'Echaporã' },
  { label: 'Echarri', value: 'Echarri' },
  { label: 'Echarri-Aranaz', value: 'Echarri-Aranaz' },
  { label: 'Echelon', value: 'Echelon' },
  { label: 'Echem', value: 'Echem' },
  { label: 'Échenevex', value: 'Échenevex' },
  { label: 'Échenoz-La-Méline', value: 'Échenoz-La-Méline' },
  { label: 'Échillais', value: 'Échillais' },
  { label: 'Eching', value: 'Eching' },
  { label: 'Eching', value: 'Eching' },
  { label: 'Echínos', value: 'Echínos' },
  { label: 'Échiré', value: 'Échiré' },
  { label: 'Échirolles', value: 'Échirolles' },
  { label: 'Echizen-Shi', value: 'Echizen-Shi' },
  { label: 'Echo', value: 'Echo' },
  { label: 'Echo Park', value: 'Echo Park' },
  { label: 'Echols County', value: 'Echols County' },
  { label: 'Echsenbach', value: 'Echsenbach' },
  { label: 'Echtenerbrug', value: 'Echtenerbrug' },
  { label: 'Echternach', value: 'Echternach' },
  { label: 'Echuca', value: 'Echuca' },
  { label: 'Echunga', value: 'Echunga' },
  { label: 'Echzell', value: 'Echzell' },
  { label: 'Écija', value: 'Écija' },
  { label: 'Ecilda Paullier', value: 'Ecilda Paullier' },
  { label: 'Ečka', value: 'Ečka' },
  { label: 'Eckart', value: 'Eckart' },
  { label: 'Eckartsau', value: 'Eckartsau' },
  { label: 'Eckartsberga', value: 'Eckartsberga' },
  { label: 'Eckbolsheim', value: 'Eckbolsheim' },
  { label: 'Eckernförde', value: 'Eckernförde' },
  { label: 'Eckerö', value: 'Eckerö' },
  { label: 'Eckersdorf', value: 'Eckersdorf' },
  { label: 'Eckington', value: 'Eckington' },
  { label: 'Eckwersheim', value: 'Eckwersheim' },
  { label: 'Eclectic', value: 'Eclectic' },
  { label: 'École-Valentin', value: 'École-Valentin' },
  { label: 'Écommoy', value: 'Écommoy' },
  { label: 'Economy', value: 'Economy' },
  { label: 'Ecoporanga', value: 'Ecoporanga' },
  { label: 'Ecorse', value: 'Ecorse' },
  { label: 'Écouché', value: 'Écouché' },
  { label: 'Écouen', value: 'Écouen' },
  { label: 'Écouflant', value: 'Écouflant' },
  { label: 'Écourt-Saint-Quentin', value: 'Écourt-Saint-Quentin' },
  { label: 'Écoyeux', value: 'Écoyeux' },
  { label: 'Ecques', value: 'Ecques' },
  { label: 'Ecquevilly', value: 'Ecquevilly' },
  { label: 'Écrainville', value: 'Écrainville' },
  { label: 'Ecroignard', value: 'Ecroignard' },
  { label: 'Écrouves', value: 'Écrouves' },
  { label: 'Ecséd', value: 'Ecséd' },
  { label: 'Ecser', value: 'Ecser' },
  { label: 'Ector County', value: 'Ector County' },
  { label: 'Ecuandureo', value: 'Ecuandureo' },
  { label: 'Ecublens', value: 'Ecublens' },
  { label: 'Ecublens', value: 'Ecublens' },
  { label: 'Écueillé', value: 'Écueillé' },
  { label: 'Écuelles', value: 'Écuelles' },
  { label: 'Écuisses', value: 'Écuisses' },
  { label: 'Écully', value: 'Écully' },
  { label: 'Ed', value: 'Ed' },
  { label: 'Ed Damer', value: 'Ed Damer' },
  { label: 'Edakkulam', value: 'Edakkulam' },
  { label: 'Edam', value: 'Edam' },
  { label: 'Edam-Volendam', value: 'Edam-Volendam' },
  { label: 'Edcouch', value: 'Edcouch' },
  { label: 'Edd', value: 'Edd' },
  { label: 'Eddelak', value: 'Eddelak' },
  { label: 'Edderitz', value: 'Edderitz' },
  { label: 'Eddington', value: 'Eddington' },
  { label: 'Eddington', value: 'Eddington' },
  { label: 'Eddy County', value: 'Eddy County' },
  { label: 'Eddy County', value: 'Eddy County' },
  { label: 'Eddystone', value: 'Eddystone' },
  { label: 'Eddyville', value: 'Eddyville' },
  { label: 'Eddyville', value: 'Eddyville' },
  { label: 'Ede', value: 'Ede' },
  { label: 'Edéa', value: 'Edéa' },
  { label: 'Edealina', value: 'Edealina' },
  { label: 'Edegem', value: 'Edegem' },
  { label: 'Edéia', value: 'Edéia' },
  { label: 'Edelény', value: 'Edelény' },
  { label: 'Edelényi Járás', value: 'Edelényi Járás' },
  { label: 'Edelira', value: 'Edelira' },
  { label: 'Edelsbach Bei Feldbach', value: 'Edelsbach Bei Feldbach' },
  { label: 'Edelschrott', value: 'Edelschrott' },
  { label: 'Edelsfeld', value: 'Edelsfeld' },
  { label: 'Edelsgrub', value: 'Edelsgrub' },
  { label: 'Edelstal', value: 'Edelstal' },
  { label: 'Edelstauden', value: 'Edelstauden' },
  { label: 'Edemissen', value: 'Edemissen' },
  { label: 'Eden', value: 'Eden' },
  { label: 'Eden', value: 'Eden' },
  { label: 'Eden', value: 'Eden' },
  { label: 'Eden', value: 'Eden' },
  { label: 'Eden', value: 'Eden' },
  { label: 'Eden', value: 'Eden' },
  { label: 'Eden District Municipality', value: 'Eden District Municipality' },
  { label: 'Eden Glen', value: 'Eden Glen' },
  { label: 'Eden Hill', value: 'Eden Hill' },
  { label: 'Eden Hills', value: 'Eden Hills' },
  { label: 'Eden Isle', value: 'Eden Isle' },
  { label: 'Eden Park', value: 'Eden Park' },
  { label: 'Eden Prairie', value: 'Eden Prairie' },
  { label: 'Eden Valley', value: 'Eden Valley' },
  { label: 'Edenbridge', value: 'Edenbridge' },
  { label: 'Edenderry', value: 'Edenderry' },
  { label: 'Edenfield', value: 'Edenfield' },
  { label: 'Edenkoben', value: 'Edenkoben' },
  { label: 'Edens Landing', value: 'Edens Landing' },
  { label: 'Edensor Park', value: 'Edensor Park' },
  { label: 'Edenton', value: 'Edenton' },
  { label: 'Edenvale', value: 'Edenvale' },
  { label: 'Ederheim', value: 'Ederheim' },
  { label: 'Edern', value: 'Edern' },
  { label: 'Edersleben', value: 'Edersleben' },
  { label: 'Ederveen', value: 'Ederveen' },
  { label: 'Edesheim', value: 'Edesheim' },
  { label: 'Édessa', value: 'Édessa' },
  { label: 'Edewecht', value: 'Edewecht' },
  { label: 'Edgar', value: 'Edgar' },
  { label: 'Edgar County', value: 'Edgar County' },
  { label: 'Edgard', value: 'Edgard' },
  { label: 'Edgartown', value: 'Edgartown' },
  { label: 'Edge Hill', value: 'Edge Hill' },
  { label: 'Edgecliff', value: 'Edgecliff' },
  { label: 'Edgecliff Village', value: 'Edgecliff Village' },
  { label: 'Edgecomb', value: 'Edgecomb' },
  { label: 'Edgecombe County', value: 'Edgecombe County' },
  { label: 'Edgecumbe', value: 'Edgecumbe' },
  { label: 'Edgefield', value: 'Edgefield' },
  { label: 'Edgefield County', value: 'Edgefield County' },
  { label: 'Edgemere', value: 'Edgemere' },
  { label: 'Edgemere', value: 'Edgemere' },
  { label: 'Edgemont Park', value: 'Edgemont Park' },
  { label: 'Edgemoor', value: 'Edgemoor' },
  { label: 'Edgerton', value: 'Edgerton' },
  { label: 'Edgerton', value: 'Edgerton' },
  { label: 'Edgerton', value: 'Edgerton' },
  { label: 'Edgerton', value: 'Edgerton' },
  { label: 'Edgewater', value: 'Edgewater' },
  { label: 'Edgewater', value: 'Edgewater' },
  { label: 'Edgewater', value: 'Edgewater' },
  { label: 'Edgewater', value: 'Edgewater' },
  { label: 'Edgewater', value: 'Edgewater' },
  { label: 'Edgewater', value: 'Edgewater' },
  { label: 'Edgewater', value: 'Edgewater' },
  { label: 'Edgewater Park', value: 'Edgewater Park' },
  { label: 'Edgewood', value: 'Edgewood' },
  { label: 'Edgewood', value: 'Edgewood' },
  { label: 'Edgewood', value: 'Edgewood' },
  { label: 'Edgewood', value: 'Edgewood' },
  { label: 'Edgewood', value: 'Edgewood' },
  { label: 'Edgewood', value: 'Edgewood' },
  { label: 'Edgewood', value: 'Edgewood' },
  { label: 'Edgewood', value: 'Edgewood' },
  { label: 'Edgewood', value: 'Edgewood' },
  { label: 'Edgeworth', value: 'Edgeworth' },
  { label: 'Edgeworth', value: 'Edgeworth' },
  { label: 'Edgeworthstown', value: 'Edgeworthstown' },
  { label: 'Edgmond', value: 'Edgmond' },
  { label: 'Edgware', value: 'Edgware' },
  { label: 'Edgworth', value: 'Edgworth' },
  { label: 'Ediger-Eller', value: 'Ediger-Eller' },
  { label: 'Edina', value: 'Edina' },
  { label: 'Edina', value: 'Edina' },
  { label: 'Edinboro', value: 'Edinboro' },
  { label: 'Edinburg', value: 'Edinburg' },
  { label: 'Edinburg', value: 'Edinburg' },
  { label: 'Edinburg', value: 'Edinburg' },
  { label: 'Edinburg', value: 'Edinburg' },
  { label: 'Edinburgh', value: 'Edinburgh' },
  { label: 'Edinburgh', value: 'Edinburgh' },
  { label: 'Edineţ', value: 'Edineţ' },
  { label: 'Edingen-Neckarhausen', value: 'Edingen-Neckarhausen' },
  { label: 'Edington', value: 'Edington' },
  { label: 'Edison', value: 'Edison' },
  { label: 'Edison', value: 'Edison' },
  { label: 'Edissiya', value: 'Edissiya' },
  { label: 'Edisto', value: 'Edisto' },
  { label: 'Edith Weston', value: 'Edith Weston' },
  { label: 'Edithvale', value: 'Edithvale' },
  { label: 'Edlitz', value: 'Edlitz' },
  { label: 'Edmond', value: 'Edmond' },
  { label: 'Edmonds', value: 'Edmonds' },
  { label: 'Edmondson Park', value: 'Edmondson Park' },
  { label: 'Edmonson County', value: 'Edmonson County' },
  { label: 'Edmonston', value: 'Edmonston' },
  { label: 'Edmonton', value: 'Edmonton' },
  { label: 'Edmonton', value: 'Edmonton' },
  { label: 'Edmonton', value: 'Edmonton' },
  { label: 'Edmore', value: 'Edmore' },
  { label: 'Edmunds County', value: 'Edmunds County' },
  { label: 'Edmundston', value: 'Edmundston' },
  { label: 'Edna', value: 'Edna' },
  { label: 'Edneyville', value: 'Edneyville' },
  { label: 'Edogawa Ku', value: 'Edogawa Ku' },
  { label: 'Edolo', value: 'Edolo' },
  { label: 'Edosaki', value: 'Edosaki' },
  { label: 'Edremit', value: 'Edremit' },
  { label: 'Edremit', value: 'Edremit' },
  { label: 'Edsbyn', value: 'Edsbyn' },
  { label: 'Edson', value: 'Edson' },
  { label: 'Edt Bei Lambach', value: 'Edt Bei Lambach' },
  { label: 'Eduardo Castex', value: 'Eduardo Castex' },
  { label: 'Edwards', value: 'Edwards' },
  { label: 'Edwards', value: 'Edwards' },
  { label: 'Edwards Air Force Base', value: 'Edwards Air Force Base' },
  { label: 'Edwards County', value: 'Edwards County' },
  { label: 'Edwards County', value: 'Edwards County' },
  { label: 'Edwards County', value: 'Edwards County' },
  { label: 'Edwardsburg', value: 'Edwardsburg' },
  { label: 'Edwardstown', value: 'Edwardstown' },
  { label: 'Edwardsville', value: 'Edwardsville' },
  { label: 'Edwardsville', value: 'Edwardsville' },
  { label: 'Edwardsville', value: 'Edwardsville' },
  { label: 'Edwinstowe', value: 'Edwinstowe' },
  { label: 'Eefde', value: 'Eefde' },
  { label: 'Eeklo', value: 'Eeklo' },
  { label: 'Eelde', value: 'Eelde' },
  { label: 'Eenigenburg', value: 'Eenigenburg' },
  { label: 'Eenrum', value: 'Eenrum' },
  { label: 'Eerbeek', value: 'Eerbeek' },
  { label: 'Eerde', value: 'Eerde' },
  { label: 'Eerschot', value: 'Eerschot' },
  { label: 'Eersel', value: 'Eersel' },
  { label: 'Eext', value: 'Eext' },
  { label: 'Eexta', value: 'Eexta' },
  { label: 'Efeler', value: 'Efeler' },
  { label: 'Eferding', value: 'Eferding' },
  { label: 'Effelder', value: 'Effelder' },
  { label: 'Effeltrich', value: 'Effeltrich' },
  { label: 'Effingham', value: 'Effingham' },
  { label: 'Effingham', value: 'Effingham' },
  { label: 'Effingham County', value: 'Effingham County' },
  { label: 'Effingham County', value: 'Effingham County' },
  { label: 'Effium', value: 'Effium' },
  { label: 'Effort', value: 'Effort' },
  { label: 'Effortville', value: 'Effortville' },
  { label: 'Effretikon', value: 'Effretikon' },
  { label: 'Effretikon / Rappenhalde-Bannhalde', value: 'Effretikon / Rappenhalde-Bannhalde' },
  { label: 'Effretikon / Rikon', value: 'Effretikon / Rikon' },
  { label: 'Effretikon / Watt', value: 'Effretikon / Watt' },
  { label: 'Efkarpía', value: 'Efkarpía' },
  { label: 'Eflani', value: 'Eflani' },
  { label: 'Efon-Alaaye', value: 'Efon-Alaaye' },
  { label: 'Eforie Nord', value: 'Eforie Nord' },
  { label: 'Eforie Sud', value: 'Eforie Sud' },
  { label: 'Efraín A. Gutiérrez', value: 'Efraín A. Gutiérrez' },
  { label: 'Efringen-Kirchen', value: 'Efringen-Kirchen' },
  { label: 'Eftimie Murgu', value: 'Eftimie Murgu' },
  { label: 'Efzar', value: 'Efzar' },
  { label: 'Egaña', value: 'Egaña' },
  { label: 'Egbe', value: 'Egbe' },
  { label: 'Egchel', value: 'Egchel' },
  { label: 'Egedal Kommune', value: 'Egedal Kommune' },
  { label: 'Egeln', value: 'Egeln' },
  { label: 'Egelsbach', value: 'Egelsbach' },
  { label: 'Egenhausen', value: 'Egenhausen' },
  { label: 'Egenhofen', value: 'Egenhofen' },
  { label: 'Eger', value: 'Eger' },
  { label: 'Egerkingen', value: 'Egerkingen' },
  { label: 'Egersund', value: 'Egersund' },
  { label: 'Egerszalók', value: 'Egerszalók' },
  { label: 'Egestorf', value: 'Egestorf' },
  { label: 'Egg', value: 'Egg' },
  { label: 'Egg', value: 'Egg' },
  { label: 'Egg An Der Günz', value: 'Egg An Der Günz' },
  { label: 'Egg Harbor City', value: 'Egg Harbor City' },
  { label: 'Eggebek', value: 'Eggebek' },
  { label: 'Eggenberg', value: 'Eggenberg' },
  { label: 'Eggenburg', value: 'Eggenburg' },
  { label: 'Eggendorf', value: 'Eggendorf' },
  { label: 'Eggenfelden', value: 'Eggenfelden' },
  { label: 'Eggenstein-Leopoldshafen', value: 'Eggenstein-Leopoldshafen' },
  { label: 'Eggenthal', value: 'Eggenthal' },
  { label: 'Eggermühlen', value: 'Eggermühlen' },
  { label: 'Eggern', value: 'Eggern' },
  { label: 'Eggersdorf', value: 'Eggersdorf' },
  { label: 'Eggersdorf Bei Graz', value: 'Eggersdorf Bei Graz' },
  { label: 'Eggersriet', value: 'Eggersriet' },
  { label: 'Eggertsville', value: 'Eggertsville' },
  { label: 'Eggesin', value: 'Eggesin' },
  { label: 'Eggingen', value: 'Eggingen' },
  { label: 'Eggiwil', value: 'Eggiwil' },
  { label: 'Egglham', value: 'Egglham' },
  { label: 'Egglkofen', value: 'Egglkofen' },
  { label: 'Eggolsheim', value: 'Eggolsheim' },
  { label: 'Eggstätt', value: 'Eggstätt' },
  { label: 'Egham', value: 'Egham' },
  { label: 'Éghezée', value: 'Éghezée' },
  { label: 'Eğil', value: 'Eğil' },
  { label: 'Egilsstaðir', value: 'Egilsstaðir' },
  { label: 'Egindiköl', value: 'Egindiköl' },
  { label: 'Eging', value: 'Eging' },
  { label: 'Egipto', value: 'Egipto' },
  { label: 'Eğirdir', value: 'Eğirdir' },
  { label: 'Égletons', value: 'Égletons' },
  { label: 'Eglin Air Force Base', value: 'Eglin Air Force Base' },
  { label: 'Eglin Village', value: 'Eglin Village' },
  { label: 'Egling', value: 'Egling' },
  { label: 'Eglinton', value: 'Eglinton' },
  { label: 'Eglinton', value: 'Eglinton' },
  { label: 'Eglinton', value: 'Eglinton' },
  { label: 'Eglise Catholique Centrale Giko', value: 'Eglise Catholique Centrale Giko' },
  { label: 'Egliswil', value: 'Egliswil' },
  { label: 'Egloffstein', value: 'Egloffstein' },
  { label: 'Égly', value: 'Égly' },
  { label: 'Egmating', value: 'Egmating' },
  { label: 'Egmond Aan Den Hoef', value: 'Egmond Aan Den Hoef' },
  { label: 'Egmond Aan Zee', value: 'Egmond Aan Zee' },
  { label: 'Egmond-Binnen', value: 'Egmond-Binnen' },
  { label: 'Egna', value: 'Egna' },
  { label: 'Egnach', value: 'Egnach' },
  { label: 'Egra', value: 'Egra' },
  { label: 'Egremont', value: 'Egremont' },
  { label: 'Égreville', value: 'Égreville' },
  { label: 'Egri Járás', value: 'Egri Járás' },
  { label: 'Égriselles-Le-Bocage', value: 'Égriselles-Le-Bocage' },
  { label: 'Egtved', value: 'Egtved' },
  { label: 'Egüés', value: 'Egüés' },
  { label: 'Eguia', value: 'Eguia' },
  { label: 'Eguia', value: 'Eguia' },
  { label: 'Éguilles', value: 'Éguilles' },
  { label: 'Eguisheim', value: 'Eguisheim' },
  { label: 'Éguzon-Chantôme', value: 'Éguzon-Chantôme' },
  { label: 'Egvekinot', value: 'Egvekinot' },
  { label: 'Egyek', value: 'Egyek' },
  { label: 'Egypt', value: 'Egypt' },
  { label: 'Egypt Lake-Leto', value: 'Egypt Lake-Leto' },
  { label: 'Eha Amufu', value: 'Eha Amufu' },
  { label: 'Ehekirchen', value: 'Ehekirchen' },
  { label: 'Ehenbichl', value: 'Ehenbichl' },
  { label: 'Ehingen', value: 'Ehingen' },
  { label: 'Ehingen', value: 'Ehingen' },
  { label: 'Ehlanzeni District', value: 'Ehlanzeni District' },
  { label: 'Ehlscheid', value: 'Ehlscheid' },
  { label: 'Əhmədbəyli', value: 'Əhmədbəyli' },
  { label: 'Ehningen', value: 'Ehningen' },
  { label: 'Ehrenberg', value: 'Ehrenberg' },
  { label: 'Ehrenburg', value: 'Ehrenburg' },
  { label: 'Ehrendingen', value: 'Ehrendingen' },
  { label: 'Ehrenfriedersdorf', value: 'Ehrenfriedersdorf' },
  { label: 'Ehrenhausen', value: 'Ehrenhausen' },
  { label: 'Ehringshausen', value: 'Ehringshausen' },
  { label: 'Ehrwald', value: 'Ehrwald' },
  { label: 'Eibar', value: 'Eibar' },
  { label: 'Eibau', value: 'Eibau' },
  { label: 'Eibelstadt', value: 'Eibelstadt' },
  { label: 'Eibenstock', value: 'Eibenstock' },
  { label: 'Eibergen', value: 'Eibergen' },
  { label: 'Eibiswald', value: 'Eibiswald' },
  { label: 'Eich', value: 'Eich' },
  { label: 'Eichberg', value: 'Eichberg' },
  { label: 'Eichberg', value: 'Eichberg' },
  { label: 'Eichenau', value: 'Eichenau' },
  { label: 'Eichenbarleben', value: 'Eichenbarleben' },
  { label: 'Eichenberg', value: 'Eichenberg' },
  { label: 'Eichenbühl', value: 'Eichenbühl' },
  { label: 'Eichendorf', value: 'Eichendorf' },
  { label: 'Eichenried', value: 'Eichenried' },
  { label: 'Eichenzell', value: 'Eichenzell' },
  { label: 'Eichfeld', value: 'Eichfeld' },
  { label: 'Eichgraben', value: 'Eichgraben' },
  { label: 'Eichigt', value: 'Eichigt' },
  { label: 'Eichstätt', value: 'Eichstätt' },
  { label: 'Eichstetten', value: 'Eichstetten' },
  { label: 'Eichwalde', value: 'Eichwalde' },
  { label: 'Eickendorf', value: 'Eickendorf' },
  { label: 'Eicklingen', value: 'Eicklingen' },
  { label: 'Eid', value: 'Eid' },
  { label: 'Eide', value: 'Eide' },
  { label: 'Eidelstedt', value: 'Eidelstedt' },
  { label: 'Eidenberg', value: 'Eidenberg' },
  { label: 'Eidfjord', value: 'Eidfjord' },
  { label: 'Eiði', value: 'Eiði' },
  { label: 'Eidsberg', value: 'Eidsberg' },
  { label: 'Eidskog', value: 'Eidskog' },
  { label: 'Eidson Road', value: 'Eidson Road' },
  { label: 'Eidsvåg', value: 'Eidsvåg' },
  { label: 'Eidsvoll', value: 'Eidsvoll' },
  { label: 'Eielson Air Force Base', value: 'Eielson Air Force Base' },
  { label: 'Eigeltingen', value: 'Eigeltingen' },
  { label: 'Eigersund', value: 'Eigersund' },
  { label: 'Eight Ash Green', value: 'Eight Ash Green' },
  { label: 'Eight Mile Plains', value: 'Eight Mile Plains' },
  { label: 'Eiguliai', value: 'Eiguliai' },
  { label: 'Eij', value: 'Eij' },
  { label: 'Eijsden', value: 'Eijsden' },
  { label: 'Eijsden-Margraten', value: 'Eijsden-Margraten' },
  { label: 'Eike', value: 'Eike' },
  { label: 'Eikelandsosen', value: 'Eikelandsosen' },
  { label: 'Eiken', value: 'Eiken' },
  { label: 'Eil', value: 'Eil' },
  { label: 'Eilabun', value: 'Eilabun' },
  { label: 'Eilat', value: 'Eilat' },
  { label: 'Eilean Siar', value: 'Eilean Siar' },
  { label: 'Eilenburg', value: 'Eilenburg' },
  { label: 'Eilsleben', value: 'Eilsleben' },
  { label: 'Eime', value: 'Eime' },
  { label: 'Eimeldingen', value: 'Eimeldingen' },
  { label: 'Eimen', value: 'Eimen' },
  { label: 'Eimeo', value: 'Eimeo' },
  { label: 'Eimke', value: 'Eimke' },
  { label: 'Eimsbüttel', value: 'Eimsbüttel' },
  { label: 'Einbeck', value: 'Einbeck' },
  { label: 'Eindhoven', value: 'Eindhoven' },
  { label: 'Einhausen', value: 'Einhausen' },
  { label: 'Einighausen', value: 'Einighausen' },
  { label: 'Einsiedeln', value: 'Einsiedeln' },
  { label: 'Einville-Au-Jard', value: 'Einville-Au-Jard' },
  { label: 'Eirado', value: 'Eirado' },
  { label: 'Eirinoúpoli', value: 'Eirinoúpoli' },
  { label: 'Eirunepé', value: 'Eirunepé' },
  { label: 'Eisbach', value: 'Eisbach' },
  { label: 'Eischen', value: 'Eischen' },
  { label: 'Eisdorf Am Harz', value: 'Eisdorf Am Harz' },
  { label: 'Eiselfing', value: 'Eiselfing' },
  { label: 'Eisen', value: 'Eisen' },
  { label: 'Eisenach', value: 'Eisenach' },
  { label: 'Eisenbach', value: 'Eisenbach' },
  { label: 'Eisenberg', value: 'Eisenberg' },
  { label: 'Eisenberg', value: 'Eisenberg' },
  { label: 'Eisenburg', value: 'Eisenburg' },
  { label: 'Eisenerz', value: 'Eisenerz' },
  { label: 'Eisenhüttenstadt', value: 'Eisenhüttenstadt' },
  { label: 'Eisenkappel-Vellach', value: 'Eisenkappel-Vellach' },
  { label: 'Eisenstadt', value: 'Eisenstadt' },
  { label: 'Eisenstadt Stadt', value: 'Eisenstadt Stadt' },
  { label: 'Eisenstadt-Umgebung', value: 'Eisenstadt-Umgebung' },
  { label: 'Eisfeld', value: 'Eisfeld' },
  { label: 'Eisgarn', value: 'Eisgarn' },
  { label: 'Eisingen', value: 'Eisingen' },
  { label: 'Eisingen', value: 'Eisingen' },
  { label: 'Eišiškės', value: 'Eišiškės' },
  { label: 'Eisleben Lutherstadt', value: 'Eisleben Lutherstadt' },
  { label: 'Eislingen', value: 'Eislingen' },
  { label: 'Eita Village', value: 'Eita Village' },
  { label: 'Eitelborn', value: 'Eitelborn' },
  { label: 'Eiterfeld', value: 'Eiterfeld' },
  { label: 'Eitorf', value: 'Eitorf' },
  { label: 'Eitting', value: 'Eitting' },
  { label: 'Eivindvik', value: 'Eivindvik' },
  { label: 'Eivissa', value: 'Eivissa' },
  { label: 'Eixo', value: 'Eixo' },
  { label: 'Ejby', value: 'Ejby' },
  { label: 'Ejby', value: 'Ejby' },
  { label: 'Ejea De Los Caballeros', value: 'Ejea De Los Caballeros' },
  { label: 'Ejeme', value: 'Ejeme' },
  { label: 'Ejidal', value: 'Ejidal' },
  { label: 'Ejido', value: 'Ejido' },
  { label: 'Ejido Agua Zarca', value: 'Ejido Agua Zarca' },
  { label: 'Ejido Benito Juárez', value: 'Ejido Benito Juárez' },
  { label: 'Ejido Cajón Ojo De Agua Número Dos', value: 'Ejido Cajón Ojo De Agua Número Dos' },
  { label: 'Ejido Ciénega De Mora', value: 'Ejido Ciénega De Mora' },
  { label: 'Ejido Cinco De Mayo', value: 'Ejido Cinco De Mayo' },
  { label: 'Ejido Cohuibampo', value: 'Ejido Cohuibampo' },
  { label: 'Ejido De Coscomate Del Progreso', value: 'Ejido De Coscomate Del Progreso' },
  { label: 'Ejido De Dolores', value: 'Ejido De Dolores' },
  { label: 'Ejido De Guadalupe', value: 'Ejido De Guadalupe' },
  { label: 'Ejido De La Finca', value: 'Ejido De La Finca' },
  { label: 'Ejido De Mozoquilpan', value: 'Ejido De Mozoquilpan' },
  { label: 'Ejido De San Mateo Coapexco', value: 'Ejido De San Mateo Coapexco' },
  { label: 'Ejido Del Tejocote', value: 'Ejido Del Tejocote' },
  { label: 'Ejido Del Tunal Nenaxi', value: 'Ejido Del Tunal Nenaxi' },
  { label: 'Ejido Doctor Alberto Oviedo Mota (El Indiviso)', value: 'Ejido Doctor Alberto Oviedo Mota (El Indiviso)' },
  { label: 'Ejido El', value: 'Ejido El' },
  { label: 'Ejido El Castillo', value: 'Ejido El Castillo' },
  { label: 'Ejido El Largo', value: 'Ejido El Largo' },
  { label: 'Ejido El Rosario', value: 'Ejido El Rosario' },
  { label: 'Ejido El Saucillo', value: 'Ejido El Saucillo' },
  { label: 'Ejido El Vergel', value: 'Ejido El Vergel' },
  { label: 'Ejido General Leandro Valle', value: 'Ejido General Leandro Valle' },
  { label: 'Ejido Gogorrón (Ex-Hacienda De Gogorrón)', value: 'Ejido Gogorrón (Ex-Hacienda De Gogorrón)' },
  { label: 'Ejido Hidalgo', value: 'Ejido Hidalgo' },
  { label: 'Ejido Javier Rojo Gómez', value: 'Ejido Javier Rojo Gómez' },
  { label: 'Ejido Jesús María', value: 'Ejido Jesús María' },
  { label: 'Ejido Jiquilpan', value: 'Ejido Jiquilpan' },
  { label: 'Ejido La Guayana (Rancho Seco)', value: 'Ejido La Guayana (Rancho Seco)' },
  { label: 'Ejido La Joya', value: 'Ejido La Joya' },
  { label: 'Ejido La Piña (Maromilla)', value: 'Ejido La Piña (Maromilla)' },
  { label: 'Ejido La Pitahaya (Santo Domingo)', value: 'Ejido La Pitahaya (Santo Domingo)' },
  { label: 'Ejido La Quemada', value: 'Ejido La Quemada' },
  { label: 'Ejido La Soledad', value: 'Ejido La Soledad' },
  { label: 'Ejido Lagunitas', value: 'Ejido Lagunitas' },
  { label: 'Ejido Lázaro Cárdenas', value: 'Ejido Lázaro Cárdenas' },
  { label: 'Ejido Loma De Malacota', value: 'Ejido Loma De Malacota' },
  { label: 'Ejido Los Huastecos', value: 'Ejido Los Huastecos' },
  { label: 'Ejido Mayocoba', value: 'Ejido Mayocoba' },
  { label: 'Ejido Michoacán De Ocampo', value: 'Ejido Michoacán De Ocampo' },
  { label: 'Ejido Miraflores', value: 'Ejido Miraflores' },
  { label: 'Ejido Modelo', value: 'Ejido Modelo' },
  { label: 'Ejido Netzahualcóyotl', value: 'Ejido Netzahualcóyotl' },
  { label: 'Ejido Nuevo', value: 'Ejido Nuevo' },
  { label: 'Ejido Nuevo León', value: 'Ejido Nuevo León' },
  { label: 'Ejido Ohuira', value: 'Ejido Ohuira' },
  { label: 'Ejido Ojo De Agua', value: 'Ejido Ojo De Agua' },
  { label: 'Ejido Palma (Ejido San Francisco)', value: 'Ejido Palma (Ejido San Francisco)' },
  { label: 'Ejido Pátzcuaro', value: 'Ejido Pátzcuaro' },
  { label: 'Ejido Plan De Ayala', value: 'Ejido Plan De Ayala' },
  { label: 'Ejido Poblado Atenquique', value: 'Ejido Poblado Atenquique' },
  { label: 'Ejido Quintana Roo', value: 'Ejido Quintana Roo' },
  { label: 'Ejido Saltillo', value: 'Ejido Saltillo' },
  { label: 'Ejido San Cristóbal', value: 'Ejido San Cristóbal' },
  { label: 'Ejido San Diego', value: 'Ejido San Diego' },
  { label: 'Ejido San José Xilatzén', value: 'Ejido San José Xilatzén' },
  { label: 'Ejido San Lorenzo Cuauhtenco', value: 'Ejido San Lorenzo Cuauhtenco' },
  { label: 'Ejido Sinaloa', value: 'Ejido Sinaloa' },
  { label: 'Ejido Sonora', value: 'Ejido Sonora' },
  { label: 'Ejido Tabasco', value: 'Ejido Tabasco' },
  { label: 'Ejido Toluca', value: 'Ejido Toluca' },
  { label: 'Ejido Tosalibampo', value: 'Ejido Tosalibampo' },
  { label: 'Ejido Uno De Febrero', value: 'Ejido Uno De Febrero' },
  { label: 'Ejido Venustiano Carranza', value: 'Ejido Venustiano Carranza' },
  { label: 'Ejido Vicente Guerrero', value: 'Ejido Vicente Guerrero' },
  { label: 'Ejido Viejo', value: 'Ejido Viejo' },
  { label: 'Ejido Yucatán', value: 'Ejido Yucatán' },
  { label: 'Ejido Zaragoza', value: 'Ejido Zaragoza' },
  { label: 'Ejigbo', value: 'Ejigbo' },
  { label: 'Ejirin', value: 'Ejirin' },
  { label: 'Ejulve', value: 'Ejulve' },
  { label: 'Ejura', value: 'Ejura' },
  { label: 'Ejutla', value: 'Ejutla' },
  { label: 'Ejutla De Crespo', value: 'Ejutla De Crespo' },
  { label: 'Ekalaka', value: 'Ekalaka' },
  { label: 'Ekáli', value: 'Ekáli' },
  { label: 'Ekangala', value: 'Ekangala' },
  { label: 'Ekängen', value: 'Ekängen' },
  { label: 'Ekazhevo', value: 'Ekazhevo' },
  { label: 'Ekeby', value: 'Ekeby' },
  { label: 'Ekeby-Almby', value: 'Ekeby-Almby' },
  { label: 'Ekenäs', value: 'Ekenäs' },
  { label: 'Ekenässjön', value: 'Ekenässjön' },
  { label: 'Ekenrooi', value: 'Ekenrooi' },
  { label: 'Ekerö', value: 'Ekerö' },
  { label: 'Eket', value: 'Eket' },
  { label: 'Ekhirit-Bulagatskiy Rayon', value: 'Ekhirit-Bulagatskiy Rayon' },
  { label: 'Ekhteyarabad', value: 'Ekhteyarabad' },
  { label: 'Ekibastuz', value: 'Ekibastuz' },
  { label: 'Ekimchan', value: 'Ekimchan' },
  { label: 'Ekinözü', value: 'Ekinözü' },
  { label: 'Ekmul', value: 'Ekmul' },
  { label: 'Ekpedz', value: 'Ekpedz' },
  { label: 'Ekpoma', value: 'Ekpoma' },
  { label: 'Ekshärad', value: 'Ekshärad' },
  { label: 'Eksjö', value: 'Eksjö' },
  { label: 'Eksochí', value: 'Eksochí' },
  { label: 'Ekukhanyeni', value: 'Ekukhanyeni' },
  { label: 'Ekurhuleni Metropolitan Municipality', value: 'Ekurhuleni Metropolitan Municipality' },
  { label: 'Ekuvukeni', value: 'Ekuvukeni' },
  { label: 'El Abadia', value: 'El Abadia' },
  { label: 'El Abiodh Sidi Cheikh', value: 'El Abiodh Sidi Cheikh' },
  { label: 'El Abra', value: 'El Abra' },
  { label: 'El Acebuche', value: 'El Acebuche' },
  { label: 'El Achiotal', value: 'El Achiotal' },
  { label: 'El Achir', value: 'El Achir' },
  { label: 'El Adelanto', value: 'El Adelanto' },
  { label: 'El Affroun', value: 'El Affroun' },
  { label: 'El Agostadero', value: 'El Agostadero' },
  { label: 'El Agrado', value: 'El Agrado' },
  { label: 'El Agua Dulcita', value: 'El Agua Dulcita' },
  { label: 'El Aguacatal', value: 'El Aguacatal' },
  { label: 'El Aguacate', value: 'El Aguacate' },
  { label: 'El Aguacate', value: 'El Aguacate' },
  { label: 'El Aguajito', value: 'El Aguajito' },
  { label: 'El Águila', value: 'El Águila' },
  { label: 'El Águila', value: 'El Águila' },
  { label: 'El Águila', value: 'El Águila' },
  { label: 'El Águila (La Mesa)', value: 'El Águila (La Mesa)' },
  { label: 'El Aguilar', value: 'El Aguilar' },
  { label: 'El Ahuacate', value: 'El Ahuacate' },
  { label: 'El Aïoun', value: 'El Aïoun' },
  { label: 'El Álamo', value: 'El Álamo' },
  { label: 'El Alcázar', value: 'El Alcázar' },
  { label: 'El Alia', value: 'El Alia' },
  { label: 'El Almendro', value: 'El Almendro' },
  { label: 'El Alto', value: 'El Alto' },
  { label: 'El Alto', value: 'El Alto' },
  { label: 'El Alto', value: 'El Alto' },
  { label: 'El Alvareño', value: 'El Alvareño' },
  { label: 'El Amarillo', value: 'El Amarillo' },
  { label: 'El Amarradero', value: 'El Amarradero' },
  { label: 'El Ámbar', value: 'El Ámbar' },
  { label: 'El Ámbar (El Ámbar De Echeverría)', value: 'El Ámbar (El Ámbar De Echeverría)' },
  { label: 'El Amria', value: 'El Amria' },
  { label: 'El Ángel', value: 'El Ángel' },
  { label: 'El Anono', value: 'El Anono' },
  { label: 'El Aouinet', value: 'El Aouinet' },
  { label: 'El Arador', value: 'El Arador' },
  { label: 'El Arañado', value: 'El Arañado' },
  { label: 'El Arca', value: 'El Arca' },
  { label: 'El Arco', value: 'El Arco' },
  { label: 'El Arco Ameca', value: 'El Arco Ameca' },
  { label: 'El Arenal', value: 'El Arenal' },
  { label: 'El Arenal', value: 'El Arenal' },
  { label: 'El Arenal', value: 'El Arenal' },
  { label: 'El Arenal', value: 'El Arenal' },
  { label: 'El Arenal', value: 'El Arenal' },
  { label: 'El Asintal', value: 'El Asintal' },
  { label: 'El Asoleadero', value: 'El Asoleadero' },
  { label: 'El Astillero', value: 'El Astillero' },
  { label: 'El Attaf', value: 'El Attaf' },
  { label: 'El Ayote', value: 'El Ayote' },
  { label: 'El Bagre', value: 'El Bagre' },
  { label: 'El Bajío', value: 'El Bajío' },
  { label: 'El Bajío', value: 'El Bajío' },
  { label: 'El Bajío', value: 'El Bajío' },
  { label: 'El Bajío', value: 'El Bajío' },
  { label: 'El Bajío', value: 'El Bajío' },
  { label: 'El Bajío De Plascencia', value: 'El Bajío De Plascencia' },
  { label: 'El Bajío Lomas Del Club', value: 'El Bajío Lomas Del Club' },
  { label: 'El Bálsamo', value: 'El Bálsamo' },
  { label: 'El Banco', value: 'El Banco' },
  { label: 'El Banco Del Venado', value: 'El Banco Del Venado' },
  { label: 'El Barco', value: 'El Barco' },
  { label: 'El Barco De Ávila', value: 'El Barco De Ávila' },
  { label: 'El Barrancón Del Tío Blas (El Barrancón)', value: 'El Barrancón Del Tío Blas (El Barrancón)' },
  { label: 'El Barrido', value: 'El Barrido' },
  { label: 'El Barril', value: 'El Barril' },
  { label: 'El Barrio', value: 'El Barrio' },
  { label: 'El Barrio De La Soledad', value: 'El Barrio De La Soledad' },
  { label: 'El Barrito', value: 'El Barrito' },
  { label: 'El Battan', value: 'El Battan' },
  { label: 'El Bauga', value: 'El Bauga' },
  { label: 'El Bayadh', value: 'El Bayadh' },
  { label: 'El Bejuco', value: 'El Bejuco' },
  { label: 'El Bellote (Miguel De La Madrid)', value: 'El Bellote (Miguel De La Madrid)' },
  { label: 'El Benque', value: 'El Benque' },
  { label: 'El Bingú', value: 'El Bingú' },
  { label: 'El Blanco', value: 'El Blanco' },
  { label: 'El Blanco', value: 'El Blanco' },
  { label: 'El Bolsón', value: 'El Bolsón' },
  { label: 'El Bosque', value: 'El Bosque' },
  { label: 'El Bosque', value: 'El Bosque' },
  { label: 'El Bosque', value: 'El Bosque' },
  { label: 'El Botadero', value: 'El Botadero' },
  { label: 'El Bour', value: 'El Bour' },
  { label: 'El Boxtha', value: 'El Boxtha' },
  { label: 'El Brasil', value: 'El Brasil' },
  { label: 'El Brillante Fraccionamiento', value: 'El Brillante Fraccionamiento' },
  { label: 'El Briseño', value: 'El Briseño' },
  { label: 'El Burgo De Ebro', value: 'El Burgo De Ebro' },
  { label: 'El Burgo De Osma', value: 'El Burgo De Osma' },
  { label: 'El Burrión', value: 'El Burrión' },
  { label: 'El Cabezón', value: 'El Cabezón' },
  { label: 'El Cabi', value: 'El Cabi' },
  { label: 'El Cacalote', value: 'El Cacalote' },
  { label: 'El Cacao', value: 'El Cacao' },
  { label: 'El Cacao', value: 'El Cacao' },
  { label: 'El Cacao', value: 'El Cacao' },
  { label: 'El Cafetal', value: 'El Cafetal' },
  { label: 'El Cahulote De Santa Ana', value: 'El Cahulote De Santa Ana' },
  { label: 'El Cairo', value: 'El Cairo' },
  { label: 'El Cajon', value: 'El Cajon' },
  { label: 'El Calabacito', value: 'El Calabacito' },
  { label: 'El Calafate', value: 'El Calafate' },
  { label: 'El Calvario', value: 'El Calvario' },
  { label: 'El Calvario', value: 'El Calvario' },
  { label: 'El Camalote', value: 'El Camalote' },
  { label: 'El Camarón', value: 'El Camarón' },
  { label: 'El Cambio', value: 'El Cambio' },
  { label: 'El Camichín', value: 'El Camichín' },
  { label: 'El Campanario Y Oradel', value: 'El Campanario Y Oradel' },
  { label: 'El Campello', value: 'El Campello' },
  { label: 'El Campo', value: 'El Campo' },
  { label: 'El Cañafístulo', value: 'El Cañafístulo' },
  { label: 'El Canario', value: 'El Canario' },
  { label: 'El Caney', value: 'El Caney' },
  { label: 'El Caño', value: 'El Caño' },
  { label: 'El Cantón', value: 'El Cantón' },
  { label: 'El Cantón De San Pablo', value: 'El Cantón De San Pablo' },
  { label: 'El Capomal', value: 'El Capomal' },
  { label: 'El Capulín', value: 'El Capulín' },
  { label: 'El Capulín', value: 'El Capulín' },
  { label: 'El Capulín', value: 'El Capulín' },
  { label: 'El Capulín', value: 'El Capulín' },
  { label: 'El Capulín (La Nueva Pochota)', value: 'El Capulín (La Nueva Pochota)' },
  { label: 'El Capulín De Chávez', value: 'El Capulín De Chávez' },
  { label: 'El Capulo (La Quebradora)', value: 'El Capulo (La Quebradora)' },
  { label: 'El Capurí', value: 'El Capurí' },
  { label: 'El Caracol', value: 'El Caracol' },
  { label: 'El Carate', value: 'El Carate' },
  { label: 'El Carmen', value: 'El Carmen' },
  { label: 'El Carmen', value: 'El Carmen' },
  { label: 'El Carmen', value: 'El Carmen' },
  { label: 'El Carmen', value: 'El Carmen' },
  { label: 'El Carmen', value: 'El Carmen' },
  { label: 'El Carmen', value: 'El Carmen' },
  { label: 'El Carmen', value: 'El Carmen' },
  { label: 'El Carmen', value: 'El Carmen' },
  { label: 'El Carmen', value: 'El Carmen' },
  { label: 'El Carmen (El Desierto Del Carmen)', value: 'El Carmen (El Desierto Del Carmen)' },
  { label: 'El Carmen (El Limón)', value: 'El Carmen (El Limón)' },
  { label: 'El Carmen Aztama', value: 'El Carmen Aztama' },
  { label: 'El Carmen De Atrato', value: 'El Carmen De Atrato' },
  { label: 'El Carmen De Bolívar', value: 'El Carmen De Bolívar' },
  { label: 'El Carmen De Chucurí', value: 'El Carmen De Chucurí' },
  { label: 'El Carmen De Los Elizondo', value: 'El Carmen De Los Elizondo' },
  { label: 'El Carmen De Viboral', value: 'El Carmen De Viboral' },
  { label: 'El Carmen Nuevo León', value: 'El Carmen Nuevo León' },
  { label: 'El Carmen Ocotepec', value: 'El Carmen Ocotepec' },
  { label: 'El Carmen Xalpatlahuaya', value: 'El Carmen Xalpatlahuaya' },
  { label: 'El Carretón', value: 'El Carretón' },
  { label: 'El Carricillo', value: 'El Carricillo' },
  { label: 'El Carril', value: 'El Carril' },
  { label: 'El Carril', value: 'El Carril' },
  { label: 'El Carrizal', value: 'El Carrizal' },
  { label: 'El Carrizal', value: 'El Carrizal' },
  { label: 'El Carrizal', value: 'El Carrizal' },
  { label: 'El Carrizalito', value: 'El Carrizalito' },
  { label: 'El Carrizo', value: 'El Carrizo' },
  { label: 'El Carrizo', value: 'El Carrizo' },
  { label: 'El Castellar', value: 'El Castellar' },
  { label: 'El Castillo', value: 'El Castillo' },
  { label: 'El Castillo', value: 'El Castillo' },
  { label: 'El Castillo', value: 'El Castillo' },
  { label: 'El Castillo', value: 'El Castillo' },
  { label: 'El Cazadero', value: 'El Cazadero' },
  { label: 'El Cazadero', value: 'El Cazadero' },
  { label: 'El Cedazo', value: 'El Cedazo' },
  { label: 'El Cedral', value: 'El Cedral' },
  { label: 'El Cedro', value: 'El Cedro' },
  { label: 'El Cedro', value: 'El Cedro' },
  { label: 'El Ceñidor', value: 'El Ceñidor' },
  { label: 'El Cenizo', value: 'El Cenizo' },
  { label: 'El Censo', value: 'El Censo' },
  { label: 'El Centenario', value: 'El Centenario' },
  { label: 'El Centro', value: 'El Centro' },
  { label: 'El Cercado', value: 'El Cercado' },
  { label: 'El Cereso', value: 'El Cereso' },
  { label: 'El Cerrillo Vista Hermosa', value: 'El Cerrillo Vista Hermosa' },
  { label: 'El Cerrito', value: 'El Cerrito' },
  { label: 'El Cerrito', value: 'El Cerrito' },
  { label: 'El Cerrito', value: 'El Cerrito' },
  { label: 'El Cerrito', value: 'El Cerrito' },
  { label: 'El Cerrito', value: 'El Cerrito' },
  { label: 'El Cerrito Corona', value: 'El Cerrito Corona' },
  { label: 'El Cerro', value: 'El Cerro' },
  { label: 'El Cerro Jalisco', value: 'El Cerro Jalisco' },
  { label: 'El Cerro Mission', value: 'El Cerro Mission' },
  { label: 'El Césped', value: 'El Césped' },
  { label: 'El Chacal', value: 'El Chacal' },
  { label: 'El Chalate', value: 'El Chalate' },
  { label: 'El Chamizal', value: 'El Chamizal' },
  { label: 'El Chante', value: 'El Chante' },
  { label: 'El Charco', value: 'El Charco' },
  { label: 'El Chauz', value: 'El Chauz' },
  { label: 'El Chaveño', value: 'El Chaveño' },
  { label: 'El Chayote', value: 'El Chayote' },
  { label: 'El Chichicaste', value: 'El Chichicaste' },
  { label: 'El Chico', value: 'El Chico' },
  { label: 'El Chilar', value: 'El Chilar' },
  { label: 'El Chimbo', value: 'El Chimbo' },
  { label: 'El Chinaco (El Pujido)', value: 'El Chinaco (El Pujido)' },
  { label: 'El Chirú', value: 'El Chirú' },
  { label: 'El Chispeadero', value: 'El Chispeadero' },
  { label: 'El Chivatillo', value: 'El Chivatillo' },
  { label: 'El Chocolate', value: 'El Chocolate' },
  { label: 'El Chol', value: 'El Chol' },
  { label: 'El Chorrillo', value: 'El Chorrillo' },
  { label: 'El Chote', value: 'El Chote' },
  { label: 'El Cid', value: 'El Cid' },
  { label: 'El Ciervo', value: 'El Ciervo' },
  { label: 'El Ciruelo', value: 'El Ciruelo' },
  { label: 'El Ciruelo', value: 'El Ciruelo' },
  { label: 'El Coacoyul', value: 'El Coacoyul' },
  { label: 'El Coahuayote', value: 'El Coahuayote' },
  { label: 'El Cobre', value: 'El Cobre' },
  { label: 'El Cocal', value: 'El Cocal' },
  { label: 'El Coclá', value: 'El Coclá' },
  { label: 'El Coco', value: 'El Coco' },
  { label: 'El Coco', value: 'El Coco' },
  { label: 'El Cocuite', value: 'El Cocuite' },
  { label: 'El Cocuy', value: 'El Cocuy' },
  { label: 'El Coecillo', value: 'El Coecillo' },
  { label: 'El Colegio', value: 'El Colegio' },
  { label: 'El Colegio', value: 'El Colegio' },
  { label: 'El Collao', value: 'El Collao' },
  { label: 'El Colomo', value: 'El Colomo' },
  { label: 'El Colomo', value: 'El Colomo' },
  { label: 'El Colorado', value: 'El Colorado' },
  { label: 'El Colorado', value: 'El Colorado' },
  { label: 'El Colorado', value: 'El Colorado' },
  { label: 'El Colorado', value: 'El Colorado' },
  { label: 'El Colorado', value: 'El Colorado' },
  { label: 'El Coloso', value: 'El Coloso' },
  { label: 'El Columpio', value: 'El Columpio' },
  { label: 'El Conchero', value: 'El Conchero' },
  { label: 'El Conde', value: 'El Conde' },
  { label: 'El Conejo', value: 'El Conejo' },
  { label: 'El Conejo', value: 'El Conejo' },
  { label: 'El Congo', value: 'El Congo' },
  { label: 'El Congo', value: 'El Congo' },
  { label: 'El Consuelo', value: 'El Consuelo' },
  { label: 'El Consuelo Ulapa', value: 'El Consuelo Ulapa' },
  { label: 'El Contadero De Matamoros', value: 'El Contadero De Matamoros' },
  { label: 'El Control', value: 'El Control' },
  { label: 'El Copal', value: 'El Copal' },
  { label: 'El Copalar', value: 'El Copalar' },
  { label: 'El Copalillo', value: 'El Copalillo' },
  { label: 'El Copé', value: 'El Copé' },
  { label: 'El Copetillo', value: 'El Copetillo' },
  { label: 'El Copey', value: 'El Copey' },
  { label: 'El Cóporo', value: 'El Cóporo' },
  { label: 'El Corcovado', value: 'El Corcovado' },
  { label: 'El Corozal', value: 'El Corozal' },
  { label: 'El Corpus', value: 'El Corpus' },
  { label: 'El Corpus', value: 'El Corpus' },
  { label: 'El Corte', value: 'El Corte' },
  { label: 'El Corte', value: 'El Corte' },
  { label: 'El Cortés', value: 'El Cortés' },
  { label: 'El Cortezo', value: 'El Cortezo' },
  { label: 'El Cortezo', value: 'El Cortezo' },
  { label: 'El Cortijo', value: 'El Cortijo' },
  { label: 'El Cortijo', value: 'El Cortijo' },
  { label: 'El Cotillo', value: 'El Cotillo' },
  { label: 'El Coto', value: 'El Coto' },
  { label: 'El Coyote', value: 'El Coyote' },
  { label: 'El Coyotillo', value: 'El Coyotillo' },
  { label: 'El Coyul', value: 'El Coyul' },
  { label: 'El Cristo', value: 'El Cristo' },
  { label: 'El Crucero', value: 'El Crucero' },
  { label: 'El Crucero', value: 'El Crucero' },
  { label: 'El Crucero De Arandas', value: 'El Crucero De Arandas' },
  { label: 'El Crucero De Santa María', value: 'El Crucero De Santa María' },
  { label: 'El Cuá', value: 'El Cuá' },
  { label: 'El Cuatro', value: 'El Cuatro' },
  { label: 'El Cubolero', value: 'El Cubolero' },
  { label: 'El Cuenqueño', value: 'El Cuenqueño' },
  { label: 'El Cuervero (Cuerveros)', value: 'El Cuervero (Cuerveros)' },
  { label: 'El Cuije', value: 'El Cuije' },
  { label: 'El Cuitzillo Grande', value: 'El Cuitzillo Grande' },
  { label: 'El Curtidor', value: 'El Curtidor' },
  { label: 'El Cuy', value: 'El Cuy' },
  { label: 'El Cuyo', value: 'El Cuyo' },
  { label: 'El Daein', value: 'El Daein' },
  { label: 'El Derramadero', value: 'El Derramadero' },
  { label: 'El Diamante (La Retranca)', value: 'El Diamante (La Retranca)' },
  { label: 'El Dieciséis', value: 'El Dieciséis' },
  { label: 'El Diez', value: 'El Diez' },
  { label: 'El Diez', value: 'El Diez' },
  { label: 'El Dique', value: 'El Dique' },
  { label: 'El Divisador', value: 'El Divisador' },
  { label: 'El Doncello', value: 'El Doncello' },
  { label: 'El Dorado', value: 'El Dorado' },
  { label: 'El Dorado', value: 'El Dorado' },
  { label: 'El Dorado', value: 'El Dorado' },
  { label: 'El Dorado', value: 'El Dorado' },
  { label: 'El Dorado', value: 'El Dorado' },
  { label: 'El Dorado', value: 'El Dorado' },
  { label: 'El Dorado County', value: 'El Dorado County' },
  { label: 'El Dorado Hills', value: 'El Dorado Hills' },
  { label: 'El Dorado Springs', value: 'El Dorado Springs' },
  { label: 'El Dovio', value: 'El Dovio' },
  { label: 'El Durazno', value: 'El Durazno' },
  { label: 'El Durazno', value: 'El Durazno' },
  { label: 'El Durazno', value: 'El Durazno' },
  { label: 'El Edén', value: 'El Edén' },
  { label: 'El Ejido', value: 'El Ejido' },
  { label: 'El Embarcadero', value: 'El Embarcadero' },
  { label: 'El Empalme', value: 'El Empalme' },
  { label: 'El Encanto', value: 'El Encanto' },
  { label: 'El Encanto', value: 'El Encanto' },
  { label: 'El Encanto Del Cerril', value: 'El Encanto Del Cerril' },
  { label: 'El Escanito', value: 'El Escanito' },
  { label: 'El Escaño De Tepale', value: 'El Escaño De Tepale' },
  { label: 'El Esclavo', value: 'El Esclavo' },
  { label: 'El Escobillo', value: 'El Escobillo' },
  { label: 'El Escondido', value: 'El Escondido' },
  { label: 'El Escoplo', value: 'El Escoplo' },
  { label: 'El Escorial', value: 'El Escorial' },
  { label: 'El Escribano', value: 'El Escribano' },
  { label: 'El Espavé', value: 'El Espavé' },
  { label: 'El Espejo', value: 'El Espejo' },
  { label: 'El Espinal', value: 'El Espinal' },
  { label: 'El Espinal', value: 'El Espinal' },
  { label: 'El Espinal', value: 'El Espinal' },
  { label: 'El Espinal', value: 'El Espinal' },
  { label: 'El Espinalillo', value: 'El Espinalillo' },
  { label: 'El Espino', value: 'El Espino' },
  { label: 'El Espino', value: 'El Espino' },
  { label: 'El Espino De Santa Rosa', value: 'El Espino De Santa Rosa' },
  { label: 'El Estor', value: 'El Estor' },
  { label: 'El Estudiante', value: 'El Estudiante' },
  { label: 'El Eulma', value: 'El Eulma' },
  { label: 'El Factor', value: 'El Factor' },
  { label: 'El Fahs', value: 'El Fahs' },
  { label: 'El Fasher', value: 'El Fasher' },
  { label: 'El Fortín', value: 'El Fortín' },
  { label: 'El Fraile', value: 'El Fraile' },
  { label: 'El Fraile', value: 'El Fraile' },
  { label: 'El Fresno', value: 'El Fresno' },
  { label: 'El Fresno', value: 'El Fresno' },
  { label: 'El Fuerte', value: 'El Fuerte' },
  { label: 'El Fuerte', value: 'El Fuerte' },
  { label: 'El Fuerte', value: 'El Fuerte' },
  { label: 'El Fuerte', value: 'El Fuerte' },
  { label: 'El Fuerte De La Unión', value: 'El Fuerte De La Unión' },
  { label: 'El Fureidīs', value: 'El Fureidīs' },
  { label: 'El Galaneño', value: 'El Galaneño' },
  { label: 'El Gallinero', value: 'El Gallinero' },
  { label: 'El Gallo', value: 'El Gallo' },
  { label: 'El Galpón', value: 'El Galpón' },
  { label: 'El Gato', value: 'El Gato' },
  { label: 'El Giral', value: 'El Giral' },
  { label: 'El Golaa', value: 'El Golaa' },
  { label: 'El Gouna', value: 'El Gouna' },
  { label: 'El Granada', value: 'El Granada' },
  { label: 'El Grao', value: 'El Grao' },
  { label: 'El Grullo Municipality', value: 'El Grullo Municipality' },
  { label: 'El Guabo', value: 'El Guabo' },
  { label: 'El Guacamayo', value: 'El Guacamayo' },
  { label: 'El Guaco', value: 'El Guaco' },
  { label: 'El Guamo', value: 'El Guamo' },
  { label: 'El Guante', value: 'El Guante' },
  { label: 'El Guantillo', value: 'El Guantillo' },
  { label: 'El Guapinol', value: 'El Guapinol' },
  { label: 'El Guarco', value: 'El Guarco' },
  { label: 'El Guásimo', value: 'El Guásimo' },
  { label: 'El Guayabal', value: 'El Guayabal' },
  { label: 'El Guayabito', value: 'El Guayabito' },
  { label: 'El Guayabo', value: 'El Guayabo' },
  { label: 'El Habal', value: 'El Habal' },
  { label: 'El Habillal', value: 'El Habillal' },
  { label: 'El Hadjar', value: 'El Hadjar' },
  { label: 'El Hadjira', value: 'El Hadjira' },
  { label: 'El Hadjira', value: 'El Hadjira' },
  { label: 'El Hajeb', value: 'El Hajeb' },
  { label: 'El Hamma', value: 'El Hamma' },
  { label: 'El Haouaria', value: 'El Haouaria' },
  { label: 'El Hatillo', value: 'El Hatillo' },
  { label: 'El Hatito', value: 'El Hatito' },
  { label: 'El Hato', value: 'El Hato' },
  { label: 'El Hed', value: 'El Hed' },
  { label: 'El Hielo', value: 'El Hielo' },
  { label: 'El Higo', value: 'El Higo' },
  { label: 'El Higo', value: 'El Higo' },
  { label: 'El Higueral', value: 'El Higueral' },
  { label: 'El Hollejo', value: 'El Hollejo' },
  { label: 'El Hoyo', value: 'El Hoyo' },
  { label: 'El Hoyo De Pinares', value: 'El Hoyo De Pinares' },
  { label: 'El Huajote', value: 'El Huajote' },
  { label: 'El Huarache (El Guarache)', value: 'El Huarache (El Guarache)' },
  { label: 'El Huaxtho', value: 'El Huaxtho' },
  { label: 'El Huecú', value: 'El Huecú' },
  { label: 'El Huérfano', value: 'El Huérfano' },
  { label: 'El Huexco', value: 'El Huexco' },
  { label: 'El Huidero', value: 'El Huidero' },
  { label: 'El Huiroche', value: 'El Huiroche' },
  { label: 'El Huitusi', value: 'El Huitusi' },
  { label: 'El Huixmí', value: 'El Huixmí' },
  { label: 'El Huizache', value: 'El Huizache' },
  { label: 'El Humo', value: 'El Humo' },
  { label: 'El Husseiniya', value: 'El Husseiniya' },
  { label: 'El Idrissia', value: 'El Idrissia' },
  { label: 'El Jabalí', value: 'El Jabalí' },
  { label: 'El Jabalí', value: 'El Jabalí' },
  { label: 'El Jacal', value: 'El Jacal' },
  { label: 'El Jadid', value: 'El Jadid' },
  { label: 'El Jaguey', value: 'El Jaguey' },
  { label: 'El Jagüey', value: 'El Jagüey' },
  { label: 'El Jagüey', value: 'El Jagüey' },
  { label: 'El Jaral', value: 'El Jaral' },
  { label: 'El Jaral', value: 'El Jaral' },
  { label: 'El Jaralillo', value: 'El Jaralillo' },
  { label: 'El Jaralito', value: 'El Jaralito' },
  { label: 'El Jaralito', value: 'El Jaralito' },
  { label: 'El Jardín', value: 'El Jardín' },
  { label: 'El Jardín', value: 'El Jardín' },
  { label: 'El Jazmín', value: 'El Jazmín' },
  { label: 'El Jazmín', value: 'El Jazmín' },
  { label: 'El Jebel', value: 'El Jebel' },
  { label: 'El Jem', value: 'El Jem' },
  { label: 'El Jiadi', value: 'El Jiadi' },
  { label: 'El Jicaral', value: 'El Jicaral' },
  { label: 'El Jicaral', value: 'El Jicaral' },
  { label: 'El Jícaro', value: 'El Jícaro' },
  { label: 'El Jícaro', value: 'El Jícaro' },
  { label: 'El Jicote', value: 'El Jicote' },
  { label: 'El Jobo', value: 'El Jobo' },
  { label: 'El Jobo', value: 'El Jobo' },
  { label: 'El Jomate', value: 'El Jomate' },
  { label: 'El Juile', value: 'El Juile' },
  { label: 'El Juncal', value: 'El Juncal' },
  { label: 'El Kala', value: 'El Kala' },
  { label: 'El Kef', value: 'El Kef' },
  { label: 'El Khroub', value: 'El Khroub' },
  { label: 'El Kseur', value: 'El Kseur' },
  { label: 'El Ksiba', value: 'El Ksiba' },
  { label: 'El Ksour', value: 'El Ksour' },
  { label: 'El Lago', value: 'El Lago' },
  { label: 'El Lampotal', value: 'El Lampotal' },
  { label: 'El Laurel', value: 'El Laurel' },
  { label: 'El Laurel', value: 'El Laurel' },
  { label: 'El Lazo', value: 'El Lazo' },
  { label: 'El Leal', value: 'El Leal' },
  { label: 'El Lencero', value: 'El Lencero' },
  { label: 'El Lequeitio', value: 'El Lequeitio' },
  { label: 'El Letrero', value: 'El Letrero' },
  { label: 'El Líbano', value: 'El Líbano' },
  { label: 'El Limar', value: 'El Limar' },
  { label: 'El Limón', value: 'El Limón' },
  { label: 'El Limón', value: 'El Limón' },
  { label: 'El Limón', value: 'El Limón' },
  { label: 'El Limón', value: 'El Limón' },
  { label: 'El Limón De Los Ramos', value: 'El Limón De Los Ramos' },
  { label: 'El Limonar', value: 'El Limonar' },
  { label: 'El Lindero', value: 'El Lindero' },
  { label: 'El Llanito', value: 'El Llanito' },
  { label: 'El Llano', value: 'El Llano' },
  { label: 'El Llano', value: 'El Llano' },
  { label: 'El Llano', value: 'El Llano' },
  { label: 'El Llano', value: 'El Llano' },
  { label: 'El Llano De Barajas', value: 'El Llano De Barajas' },
  { label: 'El Llano De Los Toros', value: 'El Llano De Los Toros' },
  { label: 'El Llano De Plascencia', value: 'El Llano De Plascencia' },
  { label: 'El Llano Del Compromiso', value: 'El Llano Del Compromiso' },
  { label: 'El Llano Santa María', value: 'El Llano Santa María' },
  { label: 'El Lobo', value: 'El Lobo' },
  { label: 'El Lobo', value: 'El Lobo' },
  { label: 'El Lolo', value: 'El Lolo' },
  { label: 'El Lopeño', value: 'El Lopeño' },
  { label: 'El Loreto Occidental', value: 'El Loreto Occidental' },
  { label: 'El Lucero', value: 'El Lucero' },
  { label: 'El Maâmoura', value: 'El Maâmoura' },
  { label: 'El Macano', value: 'El Macano' },
  { label: 'El Macuchi', value: 'El Macuchi' },
  { label: 'El Maestranzo', value: 'El Maestranzo' },
  { label: 'El Maguey', value: 'El Maguey' },
  { label: 'El Maguey', value: 'El Maguey' },
  { label: 'El Maguey', value: 'El Maguey' },
  { label: 'El Magueyal', value: 'El Magueyal' },
  { label: 'El Maitén', value: 'El Maitén' },
  { label: 'El Malah', value: 'El Malah' },
  { label: 'El Maluco', value: 'El Maluco' },
  { label: 'El Manantial', value: 'El Manantial' },
  { label: 'El Manantial', value: 'El Manantial' },
  { label: 'El Mango', value: 'El Mango' },
  { label: 'El Mangó', value: 'El Mangó' },
  { label: 'El Mante', value: 'El Mante' },
  { label: 'El Marañón', value: 'El Marañón' },
  { label: 'El Marañón', value: 'El Marañón' },
  { label: 'El María', value: 'El María' },
  { label: 'El Mastranzo', value: 'El Mastranzo' },
  { label: 'El Matama', value: 'El Matama' },
  { label: 'El Mataria', value: 'El Mataria' },
  { label: 'El Mayoral De Abajo', value: 'El Mayoral De Abajo' },
  { label: 'El Mazra‘A', value: 'El Mazra‘A' },
  { label: 'El Medineño', value: 'El Medineño' },
  { label: 'El Mentidero', value: 'El Mentidero' },
  { label: 'El Mesón', value: 'El Mesón' },
  { label: 'El Mesoncito', value: 'El Mesoncito' },
  { label: 'El Metlapil', value: 'El Metlapil' },
  { label: 'El Mezquite Grande', value: 'El Mezquite Grande' },
  { label: 'El Mezquitillo', value: 'El Mezquitillo' },
  { label: 'El Mida', value: 'El Mida' },
  { label: 'El Milagro', value: 'El Milagro' },
  { label: 'El Milagro', value: 'El Milagro' },
  { label: 'El Mirador', value: 'El Mirador' },
  { label: 'El Mirador', value: 'El Mirador' },
  { label: 'El Mirador', value: 'El Mirador' },
  { label: 'El Mirador', value: 'El Mirador' },
  { label: 'El Mirador', value: 'El Mirador' },
  { label: 'El Mirage', value: 'El Mirage' },
  { label: 'El Mochito', value: 'El Mochito' },
  { label: 'El Mogote', value: 'El Mogote' },
  { label: 'El Molar', value: 'El Molar' },
  { label: 'El Molino', value: 'El Molino' },
  { label: 'El Molino', value: 'El Molino' },
  { label: 'El Molino', value: 'El Molino' },
  { label: 'El Molino', value: 'El Molino' },
  { label: 'El Molino', value: 'El Molino' },
  { label: 'El Molino', value: 'El Molino' },
  { label: 'El Molino', value: 'El Molino' },
  { label: 'El Mollejon', value: 'El Mollejon' },
  { label: 'El Monte', value: 'El Monte' },
  { label: 'El Monte', value: 'El Monte' },
  { label: 'El Moral', value: 'El Moral' },
  { label: 'El Moral', value: 'El Moral' },
  { label: 'El Moreno (San Miguel Moreno)', value: 'El Moreno (San Miguel Moreno)' },
  { label: 'El Morro', value: 'El Morro' },
  { label: 'El Mosquito Linares', value: 'El Mosquito Linares' },
  { label: 'El Mrezga', value: 'El Mrezga' },
  { label: 'El Muey', value: 'El Muey' },
  { label: 'El Muñoz', value: 'El Muñoz' },
  { label: 'El Nabo', value: 'El Nabo' },
  { label: 'El Nacimiento', value: 'El Nacimiento' },
  { label: 'El Nacimiento', value: 'El Nacimiento' },
  { label: 'El Nacimiento De Abajo', value: 'El Nacimiento De Abajo' },
  { label: 'El Nancito', value: 'El Nancito' },
  { label: 'El Naranjillo', value: 'El Naranjillo' },
  { label: 'El Naranjito', value: 'El Naranjito' },
  { label: 'El Naranjito', value: 'El Naranjito' },
  { label: 'El Naranjo', value: 'El Naranjo' },
  { label: 'El Naranjo', value: 'El Naranjo' },
  { label: 'El Naranjo', value: 'El Naranjo' },
  { label: 'El Naranjo', value: 'El Naranjo' },
  { label: 'El Nayar', value: 'El Nayar' },
  { label: 'El Negrito', value: 'El Negrito' },
  { label: 'El Negro', value: 'El Negro' },
  { label: 'El Nido', value: 'El Nido' },
  { label: 'El Nigromante', value: 'El Nigromante' },
  { label: 'El Nigromante', value: 'El Nigromante' },
  { label: 'El Nilo', value: 'El Nilo' },
  { label: 'El Niño', value: 'El Niño' },
  { label: 'El Nio', value: 'El Nio' },
  { label: 'El Níspero', value: 'El Níspero' },
  { label: 'El Nith', value: 'El Nith' },
  { label: 'El Nogalito', value: 'El Nogalito' },
  { label: 'El Nopal', value: 'El Nopal' },
  { label: 'El Nuevo Poblado', value: 'El Nuevo Poblado' },
  { label: 'El Obeid', value: 'El Obeid' },
  { label: 'El Obraje', value: 'El Obraje' },
  { label: 'El Obraje', value: 'El Obraje' },
  { label: 'El Obraje', value: 'El Obraje' },
  { label: 'El Obraje', value: 'El Obraje' },
  { label: 'El Ocotal', value: 'El Ocotal' },
  { label: 'El Ocote', value: 'El Ocote' },
  { label: 'El Ocote', value: 'El Ocote' },
  { label: 'El Ocotillo', value: 'El Ocotillo' },
  { label: 'El Ocotito', value: 'El Ocotito' },
  { label: 'El Ocotón', value: 'El Ocotón' },
  { label: 'El Ojo', value: 'El Ojo' },
  { label: 'El Ojo De Agua', value: 'El Ojo De Agua' },
  { label: 'El Ojuelo', value: 'El Ojuelo' },
  { label: 'El Olivar', value: 'El Olivar' },
  { label: 'El Olvera', value: 'El Olvera' },
  { label: 'El Olvido', value: 'El Olvido' },
  { label: 'El Organal', value: 'El Organal' },
  { label: 'El Oro', value: 'El Oro' },
  { label: 'El Oro De Hidalgo', value: 'El Oro De Hidalgo' },
  { label: 'El Oued', value: 'El Oued' },
  { label: 'El Pacayal', value: 'El Pacayal' },
  { label: 'El Padre Juan', value: 'El Padre Juan' },
  { label: 'El Paisnal', value: 'El Paisnal' },
  { label: 'El Pájaro', value: 'El Pájaro' },
  { label: 'El Palacio', value: 'El Palacio' },
  { label: 'El Palmar', value: 'El Palmar' },
  { label: 'El Palmar', value: 'El Palmar' },
  { label: 'El Palmar', value: 'El Palmar' },
  { label: 'El Palmar', value: 'El Palmar' },
  { label: 'El Palmar', value: 'El Palmar' },
  { label: 'El Palmar', value: 'El Palmar' },
  { label: 'El Palmar (San Gabriel)', value: 'El Palmar (San Gabriel)' },
  { label: 'El Palmar De San Antonio', value: 'El Palmar De San Antonio' },
  { label: 'El Palmar Grande', value: 'El Palmar Grande' },
  { label: 'El Palmarcito', value: 'El Palmarcito' },
  { label: 'El Palmital', value: 'El Palmital' },
  { label: 'El Palmito', value: 'El Palmito' },
  { label: 'El Panorama', value: 'El Panorama' },
  { label: 'El Pantano', value: 'El Pantano' },
  { label: 'El Pantano', value: 'El Pantano' },
  { label: 'El Papayo', value: 'El Papayo' },
  { label: 'El Paracho', value: 'El Paracho' },
  { label: 'El Paraíso', value: 'El Paraíso' },
  { label: 'El Paraíso', value: 'El Paraíso' },
  { label: 'El Paraíso', value: 'El Paraíso' },
  { label: 'El Paraíso', value: 'El Paraíso' },
  { label: 'El Paraíso', value: 'El Paraíso' },
  { label: 'El Paraíso', value: 'El Paraíso' },
  { label: 'El Paraíso (La Charca)', value: 'El Paraíso (La Charca)' },
  { label: 'El Pardo', value: 'El Pardo' },
  { label: 'El Pardo', value: 'El Pardo' },
  { label: 'El Paredón', value: 'El Paredón' },
  { label: 'El Paredoncito', value: 'El Paredoncito' },
  { label: 'El Parral', value: 'El Parral' },
  { label: 'El Paso', value: 'El Paso' },
  { label: 'El Paso', value: 'El Paso' },
  { label: 'El Paso', value: 'El Paso' },
  { label: 'El Paso', value: 'El Paso' },
  { label: 'El Paso County', value: 'El Paso County' },
  { label: 'El Paso County', value: 'El Paso County' },
  { label: 'El Paso Real', value: 'El Paso Real' },
  { label: 'El Pastor', value: 'El Pastor' },
  { label: 'El Paujil', value: 'El Paujil' },
  { label: 'El Pedernal', value: 'El Pedernal' },
  { label: 'El Pedregal', value: 'El Pedregal' },
  { label: 'El Pedregal De Guadalupe Hidalgo', value: 'El Pedregal De Guadalupe Hidalgo' },
  { label: 'El Pedregoso', value: 'El Pedregoso' },
  { label: 'El Pedregoso', value: 'El Pedregoso' },
  { label: 'El Peñasco', value: 'El Peñasco' },
  { label: 'El Peñol', value: 'El Peñol' },
  { label: 'El Peñón', value: 'El Peñón' },
  { label: 'El Peñón', value: 'El Peñón' },
  { label: 'El Peñón', value: 'El Peñón' },
  { label: 'El Peñón', value: 'El Peñón' },
  { label: 'El Peñón', value: 'El Peñón' },
  { label: 'El Perelló', value: 'El Perelló' },
  { label: 'El Perico', value: 'El Perico' },
  { label: 'El Pericón', value: 'El Pericón' },
  { label: 'El Perú', value: 'El Perú' },
  { label: 'El Pescadero', value: 'El Pescadero' },
  { label: 'El Picacho', value: 'El Picacho' },
  { label: 'El Picador', value: 'El Picador' },
  { label: 'El Pilar', value: 'El Pilar' },
  { label: 'El Pino', value: 'El Pino' },
  { label: 'El Pino', value: 'El Pino' },
  { label: 'El Pino', value: 'El Pino' },
  { label: 'El Pino', value: 'El Pino' },
  { label: 'El Piñon', value: 'El Piñon' },
  { label: 'El Pinto', value: 'El Pinto' },
  { label: 'El Pintor', value: 'El Pintor' },
  { label: 'El Pitahayo', value: 'El Pitahayo' },
  { label: 'El Pital', value: 'El Pital' },
  { label: 'El Plan', value: 'El Plan' },
  { label: 'El Plan', value: 'El Plan' },
  { label: 'El Plan', value: 'El Plan' },
  { label: 'El Plan', value: 'El Plan' },
  { label: 'El Platanal', value: 'El Platanal' },
  { label: 'El Platanar', value: 'El Platanar' },
  { label: 'El Plateado De Joaquín Amaro', value: 'El Plateado De Joaquín Amaro' },
  { label: 'El Playón', value: 'El Playón' },
  { label: 'El Pochotal', value: 'El Pochotal' },
  { label: 'El Pochote', value: 'El Pochote' },
  { label: 'El Polvorín', value: 'El Polvorín' },
  { label: 'El Popote', value: 'El Popote' },
  { label: 'El Portal', value: 'El Portal' },
  { label: 'El Portal', value: 'El Portal' },
  { label: 'El Portezuelo', value: 'El Portezuelo' },
  { label: 'El Porvenir', value: 'El Porvenir' },
  { label: 'El Porvenir', value: 'El Porvenir' },
  { label: 'El Porvenir', value: 'El Porvenir' },
  { label: 'El Porvenir', value: 'El Porvenir' },
  { label: 'El Porvenir', value: 'El Porvenir' },
  { label: 'El Porvenir', value: 'El Porvenir' },
  { label: 'El Porvenir', value: 'El Porvenir' },
  { label: 'El Porvenir', value: 'El Porvenir' },
  { label: 'El Porvenir', value: 'El Porvenir' },
  { label: 'El Porvenir', value: 'El Porvenir' },
  { label: 'El Porvenir', value: 'El Porvenir' },
  { label: 'El Porvenir (El Porvenir De Arriba)', value: 'El Porvenir (El Porvenir De Arriba)' },
  { label: 'El Porvenir Agrarista', value: 'El Porvenir Agrarista' },
  { label: 'El Porvenir De Velasco Suárez', value: 'El Porvenir De Velasco Suárez' },
  { label: 'El Porvenir I', value: 'El Porvenir I' },
  { label: 'El Potrerillo (Potrerillo Del Rincón)', value: 'El Potrerillo (Potrerillo Del Rincón)' },
  { label: 'El Potrero', value: 'El Potrero' },
  { label: 'El Potrero', value: 'El Potrero' },
  { label: 'El Potrero', value: 'El Potrero' },
  { label: 'El Potrero', value: 'El Potrero' },
  { label: 'El Potrero De San Diego', value: 'El Potrero De San Diego' },
  { label: 'El Potrero De Sataya', value: 'El Potrero De Sataya' },
  { label: 'El Pozo', value: 'El Pozo' },
  { label: 'El Pozo', value: 'El Pozo' },
  { label: 'El Pozo Santo', value: 'El Pozo Santo' },
  { label: 'El Pozole', value: 'El Pozole' },
  { label: 'El Progreso', value: 'El Progreso' },
  { label: 'El Progreso', value: 'El Progreso' },
  { label: 'El Progreso', value: 'El Progreso' },
  { label: 'El Progreso', value: 'El Progreso' },
  { label: 'El Progreso', value: 'El Progreso' },
  { label: 'El Progreso', value: 'El Progreso' },
  { label: 'El Progreso', value: 'El Progreso' },
  { label: 'El Progreso Hidalgo', value: 'El Progreso Hidalgo' },
  { label: 'El Pueblito', value: 'El Pueblito' },
  { label: 'El Pueblito', value: 'El Pueblito' },
  { label: 'El Pueblito', value: 'El Pueblito' },
  { label: 'El Pueblito (Garbanzal)', value: 'El Pueblito (Garbanzal)' },
  { label: 'El Puente', value: 'El Puente' },
  { label: 'El Puente', value: 'El Puente' },
  { label: 'El Puerto', value: 'El Puerto' },
  { label: 'El Puerto', value: 'El Puerto' },
  { label: 'El Puerto', value: 'El Puerto' },
  { label: 'El Puerto De Amolero', value: 'El Puerto De Amolero' },
  { label: 'El Puerto Magú', value: 'El Puerto Magú' },
  { label: 'El Puesto', value: 'El Puesto' },
  { label: 'El Puesto', value: 'El Puesto' },
  { label: 'El Quebrachal', value: 'El Quebrachal' },
  { label: 'El Quelite', value: 'El Quelite' },
  { label: 'El Quemado', value: 'El Quemado' },
  { label: 'El Quetzal', value: 'El Quetzal' },
  { label: 'El Quince', value: 'El Quince' },
  { label: 'El Quisco', value: 'El Quisco' },
  { label: 'El Rama', value: 'El Rama' },
  { label: 'El Ramal (Porvenir)', value: 'El Ramal (Porvenir)' },
  { label: 'El Ranchito', value: 'El Ranchito' },
  { label: 'El Ranchito Número Uno', value: 'El Ranchito Número Uno' },
  { label: 'El Rancho', value: 'El Rancho' },
  { label: 'El Rancho', value: 'El Rancho' },
  { label: 'El Rancho', value: 'El Rancho' },
  { label: 'El Rayo', value: 'El Rayo' },
  { label: 'El Rayo', value: 'El Rayo' },
  { label: 'El Realejo', value: 'El Realejo' },
  { label: 'El Realito', value: 'El Realito' },
  { label: 'El Realito', value: 'El Realito' },
  { label: 'El Rebalse', value: 'El Rebalse' },
  { label: 'El Rebalsito De Apazulco', value: 'El Rebalsito De Apazulco' },
  { label: 'El Recodo', value: 'El Recodo' },
  { label: 'El Recodo De San José Axalco', value: 'El Recodo De San José Axalco' },
  { label: 'El Recreo', value: 'El Recreo' },
  { label: 'El Recuerdo De Ancón (Xoconoxtle De Arriba)', value: 'El Recuerdo De Ancón (Xoconoxtle De Arriba)' },
  { label: 'El Refugio', value: 'El Refugio' },
  { label: 'El Refugio', value: 'El Refugio' },
  { label: 'El Refugio', value: 'El Refugio' },
  { label: 'El Refugio', value: 'El Refugio' },
  { label: 'El Refugio', value: 'El Refugio' },
  { label: 'El Refugio', value: 'El Refugio' },
  { label: 'El Refugio De Los Altos', value: 'El Refugio De Los Altos' },
  { label: 'El Refugio De Los Sauces', value: 'El Refugio De Los Sauces' },
  { label: 'El Refugio De Peñuelas', value: 'El Refugio De Peñuelas' },
  { label: 'El Refugio De Providencia (Providencia)', value: 'El Refugio De Providencia (Providencia)' },
  { label: 'El Refugio De Vázquez', value: 'El Refugio De Vázquez' },
  { label: 'El Refugio Jalisco', value: 'El Refugio Jalisco' },
  { label: 'El Refugio Suchitlán', value: 'El Refugio Suchitlán' },
  { label: 'El Refugio Y El Reparo', value: 'El Refugio Y El Reparo' },
  { label: 'El Remolino', value: 'El Remolino' },
  { label: 'El Reno', value: 'El Reno' },
  { label: 'El Reparo', value: 'El Reparo' },
  { label: 'El Retén', value: 'El Retén' },
  { label: 'El Retiro', value: 'El Retiro' },
  { label: 'El Retiro', value: 'El Retiro' },
  { label: 'El Retorno', value: 'El Retorno' },
  { label: 'El Rincón', value: 'El Rincón' },
  { label: 'El Rincón', value: 'El Rincón' },
  { label: 'El Rincón', value: 'El Rincón' },
  { label: 'El Rincón', value: 'El Rincón' },
  { label: 'El Rincón', value: 'El Rincón' },
  { label: 'El Rincón', value: 'El Rincón' },
  { label: 'El Rincón (Santa Cruz Del Rincón)', value: 'El Rincón (Santa Cruz Del Rincón)' },
  { label: 'El Rincón Citlaltépetl', value: 'El Rincón Citlaltépetl' },
  { label: 'El Rincón De La Candelaria', value: 'El Rincón De La Candelaria' },
  { label: 'El Rincón De Los Perales', value: 'El Rincón De Los Perales' },
  { label: 'El Rincón De San Felipe (Tercera Manzana San Felipe)', value: 'El Rincón De San Felipe (Tercera Manzana San Felipe)' },
  { label: 'El Rio', value: 'El Rio' },
  { label: 'El Roble', value: 'El Roble' },
  { label: 'El Roble', value: 'El Roble' },
  { label: 'El Roble', value: 'El Roble' },
  { label: 'El Roble', value: 'El Roble' },
  { label: 'El Rodeo', value: 'El Rodeo' },
  { label: 'El Rodeo', value: 'El Rodeo' },
  { label: 'El Rodeo', value: 'El Rodeo' },
  { label: 'El Rodeo', value: 'El Rodeo' },
  { label: 'El Rodeo', value: 'El Rodeo' },
  { label: 'El Rodeo Viejo', value: 'El Rodeo Viejo' },
  { label: 'El Romereño', value: 'El Romereño' },
  { label: 'El Rosal', value: 'El Rosal' },
  { label: 'El Rosal', value: 'El Rosal' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario', value: 'El Rosario' },
  { label: 'El Rosario De Arriba', value: 'El Rosario De Arriba' },
  { label: 'El Rucio', value: 'El Rucio' },
  { label: 'El Rucio', value: 'El Rucio' },
  { label: 'El Rusio', value: 'El Rusio' },
  { label: 'El Sabino', value: 'El Sabino' },
  { label: 'El Sabino', value: 'El Sabino' },
  { label: 'El Sabino', value: 'El Sabino' },
  { label: 'El Sacrificio', value: 'El Sacrificio' },
  { label: 'El Sacrificio', value: 'El Sacrificio' },
  { label: 'El Sahuaral', value: 'El Sahuaral' },
  { label: 'El Saladito', value: 'El Saladito' },
  { label: 'El Saladito', value: 'El Saladito' },
  { label: 'El Salero', value: 'El Salero' },
  { label: 'El Salero', value: 'El Salero' },
  { label: 'El Salitre', value: 'El Salitre' },
  { label: 'El Salitre', value: 'El Salitre' },
  { label: 'El Salitre', value: 'El Salitre' },
  { label: 'El Salitre', value: 'El Salitre' },
  { label: 'El Salitre', value: 'El Salitre' },
  { label: 'El Salto', value: 'El Salto' },
  { label: 'El Salto', value: 'El Salto' },
  { label: 'El Salto', value: 'El Salto' },
  { label: 'El Salto', value: 'El Salto' },
  { label: 'El Salto De Espejo', value: 'El Salto De Espejo' },
  { label: 'El Salto De Eyipantla', value: 'El Salto De Eyipantla' },
  { label: 'El Salto De Las Peñas', value: 'El Salto De Las Peñas' },
  { label: 'El Salto De San Antonio', value: 'El Salto De San Antonio' },
  { label: 'El Salto Municipality', value: 'El Salto Municipality' },
  { label: 'El Salvador', value: 'El Salvador' },
  { label: 'El Salvador', value: 'El Salvador' },
  { label: 'El Salvador', value: 'El Salvador' },
  { label: 'El Salvador (Ranchito San José Del Carmen)', value: 'El Salvador (Ranchito San José Del Carmen)' },
  { label: 'El Salvial', value: 'El Salvial' },
  { label: 'El Saneal', value: 'El Saneal' },
  { label: 'El Santuario', value: 'El Santuario' },
  { label: 'El Sauce', value: 'El Sauce' },
  { label: 'El Sauce', value: 'El Sauce' },
  { label: 'El Saucillo', value: 'El Saucillo' },
  { label: 'El Saucillo', value: 'El Saucillo' },
  { label: 'El Saucillo (Fraccionamiento)', value: 'El Saucillo (Fraccionamiento)' },
  { label: 'El Saucillo De Los Pérez', value: 'El Saucillo De Los Pérez' },
  { label: 'El Saucito', value: 'El Saucito' },
  { label: 'El Saucito (El Horno)', value: 'El Saucito (El Horno)' },
  { label: 'El Sauz', value: 'El Sauz' },
  { label: 'El Saúz', value: 'El Saúz' },
  { label: 'El Sauz (El Sauz De Villaseñor)', value: 'El Sauz (El Sauz De Villaseñor)' },
  { label: 'El Sauz De Abajo', value: 'El Sauz De Abajo' },
  { label: 'El Sauz De Ibarra', value: 'El Sauz De Ibarra' },
  { label: 'El Sauzal De Rodríguez', value: 'El Sauzal De Rodríguez' },
  { label: 'El Segundo', value: 'El Segundo' },
  { label: 'El Seminario', value: 'El Seminario' },
  { label: 'El Sibal', value: 'El Sibal' },
  { label: 'El Silencio', value: 'El Silencio' },
  { label: 'El Silencio', value: 'El Silencio' },
  { label: 'El Sitio', value: 'El Sitio' },
  { label: 'El Sitio', value: 'El Sitio' },
  { label: 'El Siviral (Jigica)', value: 'El Siviral (Jigica)' },
  { label: 'El Soberbio', value: 'El Soberbio' },
  { label: 'El Sobrante', value: 'El Sobrante' },
  { label: 'El Socorro', value: 'El Socorro' },
  { label: 'El Sopial', value: 'El Sopial' },
  { label: 'El Soyate', value: 'El Soyate' },
  { label: 'El Súchil', value: 'El Súchil' },
  { label: 'El Susto', value: 'El Susto' },
  { label: 'El Suyatal', value: 'El Suyatal' },
  { label: 'El Tablero', value: 'El Tablero' },
  { label: 'El Tablón', value: 'El Tablón' },
  { label: 'El Tablón De Gómez', value: 'El Tablón De Gómez' },
  { label: 'El Tabo', value: 'El Tabo' },
  { label: 'El Tamarindo', value: 'El Tamarindo' },
  { label: 'El Tambo', value: 'El Tambo' },
  { label: 'El Tambo', value: 'El Tambo' },
  { label: 'El Taray', value: 'El Taray' },
  { label: 'El Tarengo Nuevo', value: 'El Tarengo Nuevo' },
  { label: 'El Tarf', value: 'El Tarf' },
  { label: 'El Tarra', value: 'El Tarra' },
  { label: 'El Tarter', value: 'El Tarter' },
  { label: 'El Tecolote', value: 'El Tecolote' },
  { label: 'El Tecolote', value: 'El Tecolote' },
  { label: 'El Tecuán', value: 'El Tecuán' },
  { label: 'El Tejar', value: 'El Tejar' },
  { label: 'El Tejar', value: 'El Tejar' },
  { label: 'El Tejar', value: 'El Tejar' },
  { label: 'El Tejocote', value: 'El Tejocote' },
  { label: 'El Tejocote', value: 'El Tejocote' },
  { label: 'El Tejocote (El Domingo)', value: 'El Tejocote (El Domingo)' },
  { label: 'El Tempisque', value: 'El Tempisque' },
  { label: 'El Tepame', value: 'El Tepame' },
  { label: 'El Tepec', value: 'El Tepec' },
  { label: 'El Tepetatal', value: 'El Tepetatal' },
  { label: 'El Tepetate', value: 'El Tepetate' },
  { label: 'El Tepetate', value: 'El Tepetate' },
  { label: 'El Tepeyac', value: 'El Tepeyac' },
  { label: 'El Tephé', value: 'El Tephé' },
  { label: 'El Tepozán', value: 'El Tepozán' },
  { label: 'El Tequesquite', value: 'El Tequesquite' },
  { label: 'El Tequesquite', value: 'El Tequesquite' },
  { label: 'El Terrerito De Sevilla', value: 'El Terrerito De Sevilla' },
  { label: 'El Terrero', value: 'El Terrero' },
  { label: 'El Terrero', value: 'El Terrero' },
  { label: 'El Terrero', value: 'El Terrero' },
  { label: 'El Terrero', value: 'El Terrero' },
  { label: 'El Terrero', value: 'El Terrero' },
  { label: 'El Terrero', value: 'El Terrero' },
  { label: 'El Ticui', value: 'El Ticui' },
  { label: 'El Tiemblo', value: 'El Tiemblo' },
  { label: 'El Tigre', value: 'El Tigre' },
  { label: 'El Tigre', value: 'El Tigre' },
  { label: 'El Tigre', value: 'El Tigre' },
  { label: 'El Tigre (Segunda Manzana De Crescencio Morales)', value: 'El Tigre (Segunda Manzana De Crescencio Morales)' },
  { label: 'El Tintal', value: 'El Tintal' },
  { label: 'El Tío', value: 'El Tío' },
  { label: 'El Tizate', value: 'El Tizate' },
  { label: 'El Tobarito', value: 'El Tobarito' },
  { label: 'El Tocuyo', value: 'El Tocuyo' },
  { label: 'El Toro', value: 'El Toro' },
  { label: 'El Toro', value: 'El Toro' },
  { label: 'El Torque', value: 'El Torque' },
  { label: 'El Torreón', value: 'El Torreón' },
  { label: 'El Torricó / Altorricon', value: 'El Torricó / Altorricon' },
  { label: 'El Tortuguero', value: 'El Tortuguero' },
  { label: 'El Tortuguero', value: 'El Tortuguero' },
  { label: 'El Tránsito', value: 'El Tránsito' },
  { label: 'El Tránsito', value: 'El Tránsito' },
  { label: 'El Trapiche', value: 'El Trapiche' },
  { label: 'El Trébol', value: 'El Trébol' },
  { label: 'El Triunfo', value: 'El Triunfo' },
  { label: 'El Triunfo', value: 'El Triunfo' },
  { label: 'El Triunfo', value: 'El Triunfo' },
  { label: 'El Triunfo', value: 'El Triunfo' },
  { label: 'El Triunfo', value: 'El Triunfo' },
  { label: 'El Triunfo De La Cruz', value: 'El Triunfo De La Cruz' },
  { label: 'El Triunfo De Las Tres Maravillas', value: 'El Triunfo De Las Tres Maravillas' },
  { label: 'El Tuito', value: 'El Tuito' },
  { label: 'El Tular', value: 'El Tular' },
  { label: 'El Tular', value: 'El Tular' },
  { label: 'El Tule', value: 'El Tule' },
  { label: 'El Tule', value: 'El Tule' },
  { label: 'El Tule', value: 'El Tule' },
  { label: 'El Tule', value: 'El Tule' },
  { label: 'El Tulillo', value: 'El Tulillo' },
  { label: 'El Tulín', value: 'El Tulín' },
  { label: 'El Tumbador', value: 'El Tumbador' },
  { label: 'El Tumbo', value: 'El Tumbo' },
  { label: 'El Tunal', value: 'El Tunal' },
  { label: 'El Tzay', value: 'El Tzay' },
  { label: 'El Uvalano', value: 'El Uvalano' },
  { label: 'El Uvito', value: 'El Uvito' },
  { label: 'El Vado De San Pedro', value: 'El Vado De San Pedro' },
  { label: 'El Valle', value: 'El Valle' },
  { label: 'El Valle De Arroyo Seco', value: 'El Valle De Arroyo Seco' },
  { label: 'El Valle De La Unión', value: 'El Valle De La Unión' },
  { label: 'El Varal', value: 'El Varal' },
  { label: 'El Varal (San Sebastián Número Uno)', value: 'El Varal (San Sebastián Número Uno)' },
  { label: 'El Vegil', value: 'El Vegil' },
  { label: 'El Veladero', value: 'El Veladero' },
  { label: 'El Vellón', value: 'El Vellón' },
  { label: 'El Venado', value: 'El Venado' },
  { label: 'El Venado', value: 'El Venado' },
  { label: 'El Verano', value: 'El Verano' },
  { label: 'El Verde', value: 'El Verde' },
  { label: 'El Verde', value: 'El Verde' },
  { label: 'El Vergel', value: 'El Vergel' },
  { label: 'El Vergel', value: 'El Vergel' },
  { label: 'El Vergel', value: 'El Vergel' },
  { label: 'El Vicarlo', value: 'El Vicarlo' },
  { label: 'El Viejo', value: 'El Viejo' },
  { label: 'El Vigía', value: 'El Vigía' },
  { label: 'El Vigía', value: 'El Vigía' },
  { label: 'El Volador', value: 'El Volador' },
  { label: 'El Voló', value: 'El Voló' },
  { label: 'El Walamo', value: 'El Walamo' },
  { label: 'El Zancudo', value: 'El Zancudo' },
  { label: 'El Zapatero', value: 'El Zapatero' },
  { label: 'El Zapotal', value: 'El Zapotal' },
  { label: 'El Zapotal Del Norte', value: 'El Zapotal Del Norte' },
  { label: 'El Zapote', value: 'El Zapote' },
  { label: 'El Zapote', value: 'El Zapote' },
  { label: 'El Zapote Bravo', value: 'El Zapote Bravo' },
  { label: 'El Zapote De Chávez', value: 'El Zapote De Chávez' },
  { label: 'El Zapotillo', value: 'El Zapotillo' },
  { label: 'El Zapotillo', value: 'El Zapotillo' },
  { label: 'El Zulia', value: 'El Zulia' },
  { label: 'El’Brus', value: 'El’Brus' },
  { label: 'El’Brusskiy Rayon', value: 'El’Brusskiy Rayon' },
  { label: 'Elaiochóri', value: 'Elaiochóri' },
  { label: 'Elamanchili', value: 'Elamanchili' },
  { label: 'Élancourt', value: 'Élancourt' },
  { label: 'Elanora', value: 'Elanora' },
  { label: 'Elanora Heights', value: 'Elanora Heights' },
  { label: 'Elantxobe', value: 'Elantxobe' },
  { label: 'Eláteia', value: 'Eláteia' },
  { label: 'Elayirampannai', value: 'Elayirampannai' },
  { label: 'Elb El Jmel', value: 'Elb El Jmel' },
  { label: 'Elba', value: 'Elba' },
  { label: 'Elbe', value: 'Elbe' },
  { label: 'Elbert County', value: 'Elbert County' },
  { label: 'Elbert County', value: 'Elbert County' },
  { label: 'Elberta', value: 'Elberta' },
  { label: 'Elberton', value: 'Elberton' },
  { label: 'Elbeuf', value: 'Elbeuf' },
  { label: 'Elbeyli', value: 'Elbeyli' },
  { label: 'Elbigenalp', value: 'Elbigenalp' },
  { label: 'Elbingerode', value: 'Elbingerode' },
  { label: 'Elbistan', value: 'Elbistan' },
  { label: 'Elbląg', value: 'Elbląg' },
  { label: 'Elbow Lake', value: 'Elbow Lake' },
  { label: 'Elbridge', value: 'Elbridge' },
  { label: 'Elburg', value: 'Elburg' },
  { label: 'Elburgo/Burgelu', value: 'Elburgo/Burgelu' },
  { label: 'Elburn', value: 'Elburn' },
  { label: 'Elche', value: 'Elche' },
  { label: 'Elche De La Sierra', value: 'Elche De La Sierra' },
  { label: 'Elche/Elx', value: 'Elche/Elx' },
  { label: 'Elciego', value: 'Elciego' },
  { label: 'Elda', value: 'Elda' },
  { label: 'Eldama Ravine', value: 'Eldama Ravine' },
  { label: 'Elden', value: 'Elden' },
  { label: 'Eldena', value: 'Eldena' },
  { label: 'Eldersburg', value: 'Eldersburg' },
  { label: 'Elderslie', value: 'Elderslie' },
  { label: 'Elderslie', value: 'Elderslie' },
  { label: 'Elderslie', value: 'Elderslie' },
  { label: 'Eldingen', value: 'Eldingen' },
  { label: 'Eldivan', value: 'Eldivan' },
  { label: 'Eldon', value: 'Eldon' },
  { label: 'Eldora', value: 'Eldora' },
  { label: 'Eldorado', value: 'Eldorado' },
  { label: 'Eldorado', value: 'Eldorado' },
  { label: 'Eldorado', value: 'Eldorado' },
  { label: 'Eldorado', value: 'Eldorado' },
  { label: 'Eldorado', value: 'Eldorado' },
  { label: 'Eldorado', value: 'Eldorado' },
  { label: 'Eldorado At Santa Fe', value: 'Eldorado At Santa Fe' },
  { label: 'Eldorado Do Carajás', value: 'Eldorado Do Carajás' },
  { label: 'Eldorado Do Sul', value: 'Eldorado Do Sul' },
  { label: 'Eldoret', value: 'Eldoret' },
  { label: 'Eldridge', value: 'Eldridge' },
  { label: 'Eldridge', value: 'Eldridge' },
  { label: 'Elduain', value: 'Elduain' },
  { label: 'Eleanor', value: 'Eleanor' },
  { label: 'Electra', value: 'Electra' },
  { label: 'Electric City', value: 'Electric City' },
  { label: 'Eleebana', value: 'Eleebana' },
  { label: 'Eleele', value: 'Eleele' },
  { label: 'Elefsína', value: 'Elefsína' },
  { label: 'Eleftherés', value: 'Eleftherés' },
  { label: 'Eleftheroúpolis', value: 'Eleftheroúpolis' },
  { label: 'Elek', value: 'Elek' },
  { label: 'Elekmonar', value: 'Elekmonar' },
  { label: 'Elektrėnai', value: 'Elektrėnai' },
  { label: 'Elektrogorsk', value: 'Elektrogorsk' },
  { label: 'Elektrostal’', value: 'Elektrostal’' },
  { label: 'Elektrougli', value: 'Elektrougli' },
  { label: 'Elele', value: 'Elele' },
  { label: 'Elemir', value: 'Elemir' },
  { label: 'Elena', value: 'Elena' },
  { label: 'Elena', value: 'Elena' },
  { label: 'Eleoúsa', value: 'Eleoúsa' },
  { label: 'Elephant Butte', value: 'Elephant Butte' },
  { label: 'Elermore Vale', value: 'Elermore Vale' },
  { label: 'Elesbão Veloso', value: 'Elesbão Veloso' },
  { label: 'Eleşkirt', value: 'Eleşkirt' },
  { label: 'Éleu-Dit-Leauwette', value: 'Éleu-Dit-Leauwette' },
  { label: 'Eleven Miles', value: 'Eleven Miles' },
  { label: 'Elfers', value: 'Elfers' },
  { label: 'Elfershausen', value: 'Elfershausen' },
  { label: 'Elgersburg', value: 'Elgersburg' },
  { label: 'Elgeta', value: 'Elgeta' },
  { label: 'Elgg', value: 'Elgg' },
  { label: 'Elgg / Städtchen Und Umgebung', value: 'Elgg / Städtchen Und Umgebung' },
  { label: 'Elgin', value: 'Elgin' },
  { label: 'Elgin', value: 'Elgin' },
  { label: 'Elgin', value: 'Elgin' },
  { label: 'Elgin', value: 'Elgin' },
  { label: 'Elgin', value: 'Elgin' },
  { label: 'Elgin', value: 'Elgin' },
  { label: 'Elgin', value: 'Elgin' },
  { label: 'Elgoibar', value: 'Elgoibar' },
  { label: 'Elgorriaga', value: 'Elgorriaga' },
  { label: 'El-Hajeb', value: 'El-Hajeb' },
  { label: 'Elhayi', value: 'Elhayi' },
  { label: 'Elhovo', value: 'Elhovo' },
  { label: 'Eli Waters', value: 'Eli Waters' },
  { label: 'Eliana L', value: 'Eliana L' },
  { label: 'Elias', value: 'Elias' },
  { label: 'Elias Fausto', value: 'Elias Fausto' },
  { label: 'Elice', value: 'Elice' },
  { label: 'Elida', value: 'Elida' },
  { label: 'Elim', value: 'Elim' },
  { label: 'Elimäki', value: 'Elimäki' },
  { label: 'Elimbah', value: 'Elimbah' },
  { label: 'Elin Pelin', value: 'Elin Pelin' },
  { label: 'Elini', value: 'Elini' },
  { label: 'Elin-Yurt', value: 'Elin-Yurt' },
  { label: 'Eliot', value: 'Eliot' },
  { label: 'Eliseni', value: 'Eliseni' },
  { label: 'Eliseu Martins', value: 'Eliseu Martins' },
  { label: 'Elisiário', value: 'Elisiário' },
  { label: 'Elísio Medrado', value: 'Elísio Medrado' },
  { label: 'Elista', value: 'Elista' },
  { label: 'Elivka', value: 'Elivka' },
  { label: 'Elixhausen', value: 'Elixhausen' },
  { label: 'Elíxir', value: 'Elíxir' },
  { label: 'Elizabeth', value: 'Elizabeth' },
  { label: 'Elizabeth', value: 'Elizabeth' },
  { label: 'Elizabeth', value: 'Elizabeth' },
  { label: 'Elizabeth', value: 'Elizabeth' },
  { label: 'Elizabeth Bay', value: 'Elizabeth Bay' },
  { label: 'Elizabeth City', value: 'Elizabeth City' },
  { label: 'Elizabeth Downs', value: 'Elizabeth Downs' },
  { label: 'Elizabeth East', value: 'Elizabeth East' },
  { label: 'Elizabeth Grove', value: 'Elizabeth Grove' },
  { label: 'Elizabeth Hills', value: 'Elizabeth Hills' },
  { label: 'Elizabeth North', value: 'Elizabeth North' },
  { label: 'Elizabeth Park', value: 'Elizabeth Park' },
  { label: 'Elizabeth South', value: 'Elizabeth South' },
  { label: 'Elizabeth Vale', value: 'Elizabeth Vale' },
  { label: 'Elizabethton', value: 'Elizabethton' },
  { label: 'Elizabethtown', value: 'Elizabethtown' },
  { label: 'Elizabethtown', value: 'Elizabethtown' },
  { label: 'Elizabethtown', value: 'Elizabethtown' },
  { label: 'Elizabethtown', value: 'Elizabethtown' },
  { label: 'Elizabethtown', value: 'Elizabethtown' },
  { label: 'Elizabethville', value: 'Elizabethville' },
  { label: 'El-Jadida', value: 'El-Jadida' },
  { label: 'Eljas', value: 'Eljas' },
  { label: 'Ełk', value: 'Ełk' },
  { label: 'Elk City', value: 'Elk City' },
  { label: 'Elk County', value: 'Elk County' },
  { label: 'Elk County', value: 'Elk County' },
  { label: 'Elk Creek', value: 'Elk Creek' },
  { label: 'Elk Grove', value: 'Elk Grove' },
  { label: 'Elk Grove Village', value: 'Elk Grove Village' },
  { label: 'Elk Plain', value: 'Elk Plain' },
  { label: 'Elk Point', value: 'Elk Point' },
  { label: 'Elk Point', value: 'Elk Point' },
  { label: 'Elk Rapids', value: 'Elk Rapids' },
  { label: 'Elk Ridge', value: 'Elk Ridge' },
  { label: 'Elk River', value: 'Elk River' },
  { label: 'Elk Run Heights', value: 'Elk Run Heights' },
  { label: 'Elkader', value: 'Elkader' },
  { label: 'Elkenroth', value: 'Elkenroth' },
  { label: 'Elkford', value: 'Elkford' },
  { label: 'Elkfork', value: 'Elkfork' },
  { label: 'Elkhart', value: 'Elkhart' },
  { label: 'Elkhart', value: 'Elkhart' },
  { label: 'Elkhart', value: 'Elkhart' },
  { label: 'Elkhart County', value: 'Elkhart County' },
  { label: 'Elkhorn', value: 'Elkhorn' },
  { label: 'Elkhorn', value: 'Elkhorn' },
  { label: 'Elkhorn', value: 'Elkhorn' },
  { label: 'Elkin', value: 'Elkin' },
  { label: 'Elkins', value: 'Elkins' },
  { label: 'Elkins', value: 'Elkins' },
  { label: 'Elkland', value: 'Elkland' },
  { label: 'Elko', value: 'Elko' },
  { label: 'Elko County', value: 'Elko County' },
  { label: 'Elko New Market', value: 'Elko New Market' },
  { label: 'Elkridge', value: 'Elkridge' },
  { label: 'Elkton', value: 'Elkton' },
  { label: 'Elkton', value: 'Elkton' },
  { label: 'Elkton', value: 'Elkton' },
  { label: 'Elkview', value: 'Elkview' },
  { label: 'Ell', value: 'Ell' },
  { label: 'Ella Town', value: 'Ella Town' },
  { label: 'Ellalong', value: 'Ellalong' },
  { label: 'Elland', value: 'Elland' },
  { label: 'Ellaville', value: 'Ellaville' },
  { label: 'Ellbögen', value: 'Ellbögen' },
  { label: 'Ellecom', value: 'Ellecom' },
  { label: 'Ellefeld', value: 'Ellefeld' },
  { label: 'Ellen Grove', value: 'Ellen Grove' },
  { label: 'Ellen Street', value: 'Ellen Street' },
  { label: 'Ellenabad', value: 'Ellenabad' },
  { label: 'Ellenberg', value: 'Ellenberg' },
  { label: 'Ellenbrook', value: 'Ellenbrook' },
  { label: 'Ellendale', value: 'Ellendale' },
  { label: 'Ellensburg', value: 'Ellensburg' },
  { label: 'Ellenton', value: 'Ellenton' },
  { label: 'Ellenville', value: 'Ellenville' },
  { label: 'Ellerau', value: 'Ellerau' },
  { label: 'Ellerbe', value: 'Ellerbe' },
  { label: 'Ellerbek', value: 'Ellerbek' },
  { label: 'Ellerhoop', value: 'Ellerhoop' },
  { label: 'Ellerker', value: 'Ellerker' },
  { label: 'Ellerstadt', value: 'Ellerstadt' },
  { label: 'Ellerton', value: 'Ellerton' },
  { label: 'Ellesmere', value: 'Ellesmere' },
  { label: 'Ellesmere Port', value: 'Ellesmere Port' },
  { label: 'Elleston Flats/ Mona Commons', value: 'Elleston Flats/ Mona Commons' },
  { label: 'Ellettsville', value: 'Ellettsville' },
  { label: 'Ellezelles', value: 'Ellezelles' },
  { label: 'Ellgau', value: 'Ellgau' },
  { label: 'Ellhofen', value: 'Ellhofen' },
  { label: 'Elliant', value: 'Elliant' },
  { label: 'Ellicott', value: 'Ellicott' },
  { label: 'Ellicott City', value: 'Ellicott City' },
  { label: 'Ellijay', value: 'Ellijay' },
  { label: 'Elliminyt', value: 'Elliminyt' },
  { label: 'Ellingen', value: 'Ellingen' },
  { label: 'Ellington', value: 'Ellington' },
  { label: 'Ellinikó', value: 'Ellinikó' },
  { label: 'Ellinwood', value: 'Ellinwood' },
  { label: 'Elliot', value: 'Elliot' },
  { label: 'Elliot Lake', value: 'Elliot Lake' },
  { label: 'Elliott County', value: 'Elliott County' },
  { label: 'Elliott Heads', value: 'Elliott Heads' },
  { label: 'Ellis', value: 'Ellis' },
  { label: 'Ellis County', value: 'Ellis County' },
  { label: 'Ellis County', value: 'Ellis County' },
  { label: 'Ellis County', value: 'Ellis County' },
  { label: 'Ellisburg', value: 'Ellisburg' },
  { label: 'Ellison', value: 'Ellison' },
  { label: 'Elliston', value: 'Elliston' },
  { label: 'Ellisville', value: 'Ellisville' },
  { label: 'Ellisville', value: 'Ellisville' },
  { label: 'Ellmau', value: 'Ellmau' },
  { label: 'Ello', value: 'Ello' },
  { label: 'Ellon', value: 'Ellon' },
  { label: 'Ellore', value: 'Ellore' },
  { label: 'Ellös', value: 'Ellös' },
  { label: 'Ellport', value: 'Ellport' },
  { label: 'Ellrich', value: 'Ellrich' },
  { label: 'Ellsworth', value: 'Ellsworth' },
  { label: 'Ellsworth', value: 'Ellsworth' },
  { label: 'Ellsworth', value: 'Ellsworth' },
  { label: 'Ellsworth County', value: 'Ellsworth County' },
  { label: 'Ellwangen', value: 'Ellwangen' },
  { label: 'Ellwood City', value: 'Ellwood City' },
  { label: 'Ellzee', value: 'Ellzee' },
  { label: 'Elm City', value: 'Elm City' },
  { label: 'Elm Creek', value: 'Elm Creek' },
  { label: 'Elm Grove', value: 'Elm Grove' },
  { label: 'Elm Park', value: 'Elm Park' },
  { label: 'Elm Springs', value: 'Elm Springs' },
  { label: 'Elma', value: 'Elma' },
  { label: 'Elma Center', value: 'Elma Center' },
  { label: 'Elmadağ', value: 'Elmadağ' },
  { label: 'Elmalı', value: 'Elmalı' },
  { label: 'Elmas', value: 'Elmas' },
  { label: 'Elmen', value: 'Elmen' },
  { label: 'Elmendorf', value: 'Elmendorf' },
  { label: 'Elmendorf Air Force Base', value: 'Elmendorf Air Force Base' },
  { label: 'Elmenhorst', value: 'Elmenhorst' },
  { label: 'Elmenhorst', value: 'Elmenhorst' },
  { label: 'Elmer', value: 'Elmer' },
  { label: 'Elmhurst', value: 'Elmhurst' },
  { label: 'Elmhurst', value: 'Elmhurst' },
  { label: 'Elmina', value: 'Elmina' },
  { label: 'Elmira', value: 'Elmira' },
  { label: 'Elmira Heights', value: 'Elmira Heights' },
  { label: 'Elmont', value: 'Elmont' },
  { label: 'Elmore', value: 'Elmore' },
  { label: 'Elmore', value: 'Elmore' },
  { label: 'Elmore County', value: 'Elmore County' },
  { label: 'Elmore County', value: 'Elmore County' },
  { label: 'Elmsford', value: 'Elmsford' },
  { label: 'Elmshorn', value: 'Elmshorn' },
  { label: 'Elmstead Market', value: 'Elmstead Market' },
  { label: 'Elmstein', value: 'Elmstein' },
  { label: 'Elmswell', value: 'Elmswell' },
  { label: 'Elmvale', value: 'Elmvale' },
  { label: 'Elmwood', value: 'Elmwood' },
  { label: 'Elmwood', value: 'Elmwood' },
  { label: 'Elmwood Park', value: 'Elmwood Park' },
  { label: 'Elmwood Park', value: 'Elmwood Park' },
  { label: 'Elmwood Place', value: 'Elmwood Place' },
  { label: 'Elne', value: 'Elne' },
  { label: 'Elnesvågen', value: 'Elnesvågen' },
  { label: 'Elói Mendes', value: 'Elói Mendes' },
  { label: 'Elon', value: 'Elon' },
  { label: 'Elorrio', value: 'Elorrio' },
  { label: 'Elorz', value: 'Elorz' },
  { label: 'Előszállás', value: 'Előszállás' },
  { label: 'Elota', value: 'Elota' },
  { label: 'Elotepec', value: 'Elotepec' },
  { label: 'Eloúnda', value: 'Eloúnda' },
  { label: 'Eloxochitlán', value: 'Eloxochitlán' },
  { label: 'Eloxochitlán', value: 'Eloxochitlán' },
  { label: 'Eloxochitlán De Flores Magón', value: 'Eloxochitlán De Flores Magón' },
  { label: 'Eloy', value: 'Eloy' },
  { label: 'Eloy Alfaro', value: 'Eloy Alfaro' },
  { label: 'Éloyes', value: 'Éloyes' },
  { label: 'Elroy', value: 'Elroy' },
  { label: 'Elroy', value: 'Elroy' },
  { label: 'Els Banys Darles', value: 'Els Banys Darles' },
  { label: 'Els Poblets', value: 'Els Poblets' },
  { label: 'Elsa', value: 'Elsa' },
  { label: 'Elsau-Räterschen', value: 'Elsau-Räterschen' },
  { label: 'Elsau-Räterschen / Räterschen', value: 'Elsau-Räterschen / Räterschen' },
  { label: 'Elsberry', value: 'Elsberry' },
  { label: 'Elsbethen', value: 'Elsbethen' },
  { label: 'Elsdorf', value: 'Elsdorf' },
  { label: 'Elsdorf', value: 'Elsdorf' },
  { label: 'Elsdorf-Westermühlen', value: 'Elsdorf-Westermühlen' },
  { label: 'Elsendorf', value: 'Elsendorf' },
  { label: 'Elsenfeld', value: 'Elsenfeld' },
  { label: 'Elsenham', value: 'Elsenham' },
  { label: 'Elsfleth', value: 'Elsfleth' },
  { label: 'Elshout', value: 'Elshout' },
  { label: 'Elsloo', value: 'Elsloo' },
  { label: 'Elsmere', value: 'Elsmere' },
  { label: 'Elsmere', value: 'Elsmere' },
  { label: 'Elsnig', value: 'Elsnig' },
  { label: 'Elspeet', value: 'Elspeet' },
  { label: 'Elst', value: 'Elst' },
  { label: 'Elstead', value: 'Elstead' },
  { label: 'Elster', value: 'Elster' },
  { label: 'Elsterberg', value: 'Elsterberg' },
  { label: 'Elsternwick', value: 'Elsternwick' },
  { label: 'Elstertrebnitz', value: 'Elstertrebnitz' },
  { label: 'Elsterwerda', value: 'Elsterwerda' },
  { label: 'Elstra', value: 'Elstra' },
  { label: 'Elstree', value: 'Elstree' },
  { label: 'Elswick', value: 'Elswick' },
  { label: 'Eltendorf', value: 'Eltendorf' },
  { label: 'Elterlein', value: 'Elterlein' },
  { label: 'Eltham', value: 'Eltham' },
  { label: 'Eltham', value: 'Eltham' },
  { label: 'Eltham North', value: 'Eltham North' },
  { label: 'Eltingville', value: 'Eltingville' },
  { label: 'Eltmann', value: 'Eltmann' },
  { label: 'Elton', value: 'Elton' },
  { label: 'Elton', value: 'Elton' },
  { label: 'El-Tor', value: 'El-Tor' },
  { label: 'Eltville', value: 'Eltville' },
  { label: 'Elumalai', value: 'Elumalai' },
  { label: 'Elur', value: 'Elur' },
  { label: 'Elva', value: 'Elva' },
  { label: 'Elvas', value: 'Elvas' },
  { label: 'Elven', value: 'Elven' },
  { label: 'Elverson', value: 'Elverson' },
  { label: 'Elverta', value: 'Elverta' },
  { label: 'Elverum', value: 'Elverum' },
  { label: 'Elvillar/Bilar', value: 'Elvillar/Bilar' },
  { label: 'Elvington', value: 'Elvington' },
  { label: 'Elvins', value: 'Elvins' },
  { label: 'Elwick', value: 'Elwick' },
  { label: 'Elwood', value: 'Elwood' },
  { label: 'Elwood', value: 'Elwood' },
  { label: 'Elwood', value: 'Elwood' },
  { label: 'Elwood', value: 'Elwood' },
  { label: 'Elwood', value: 'Elwood' },
  { label: 'Elwood', value: 'Elwood' },
  { label: 'Elwood', value: 'Elwood' },
  { label: 'Elwood', value: 'Elwood' },
  { label: 'Elxleben', value: 'Elxleben' },
  { label: 'Ely', value: 'Ely' },
  { label: 'Ely', value: 'Ely' },
  { label: 'Ely', value: 'Ely' },
  { label: 'Ely', value: 'Ely' },
  { label: 'Elyakhin', value: 'Elyakhin' },
  { label: 'Elyria', value: 'Elyria' },
  { label: 'Elysburg', value: 'Elysburg' },
  { label: 'Elz', value: 'Elz' },
  { label: 'Elzach', value: 'Elzach' },
  { label: 'Elze', value: 'Elze' },
  { label: 'Emad Deh', value: 'Emad Deh' },
  { label: 'Emajagua', value: 'Emajagua' },
  { label: 'Emam Shahr', value: 'Emam Shahr' },
  { label: 'Emamzade Abdollah', value: 'Emamzade Abdollah' },
  { label: 'Emamzadeh Khatun', value: 'Emamzadeh Khatun' },
  { label: 'Emamzadeh Pir Nahan', value: 'Emamzadeh Pir Nahan' },
  { label: 'Emancipación Quetzalapa', value: 'Emancipación Quetzalapa' },
  { label: 'Emanuel County', value: 'Emanuel County' },
  { label: 'Emar', value: 'Emar' },
  { label: 'Emarese', value: 'Emarese' },
  { label: 'Emas', value: 'Emas' },
  { label: 'Emba', value: 'Emba' },
  { label: 'Embajador Martini', value: 'Embajador Martini' },
  { label: 'Embalenhle', value: 'Embalenhle' },
  { label: 'Embalse', value: 'Embalse' },
  { label: 'Embarcación', value: 'Embarcación' },
  { label: 'Embaúba', value: 'Embaúba' },
  { label: 'Emberton', value: 'Emberton' },
  { label: 'Embi', value: 'Embi' },
  { label: 'Embid', value: 'Embid' },
  { label: 'Embid De Ariza', value: 'Embid De Ariza' },
  { label: 'Embleton', value: 'Embleton' },
  { label: 'Embleton', value: 'Embleton' },
  { label: 'Emboscada', value: 'Emboscada' },
  { label: 'Embrach', value: 'Embrach' },
  { label: 'Embrach / Embrach (Dorfkern)', value: 'Embrach / Embrach (Dorfkern)' },
  { label: 'Embrach / Kellersacker', value: 'Embrach / Kellersacker' },
  { label: 'Embrun', value: 'Embrun' },
  { label: 'Embsay', value: 'Embsay' },
  { label: 'Embsen', value: 'Embsen' },
  { label: 'Embu', value: 'Embu' },
  { label: 'Embu', value: 'Embu' },
  { label: 'Embu Das Artes', value: 'Embu Das Artes' },
  { label: 'Embu Guaçu', value: 'Embu Guaçu' },
  { label: 'Embu-Guaçu', value: 'Embu-Guaçu' },
  { label: 'Emden', value: 'Emden' },
  { label: 'Émerainville', value: 'Émerainville' },
  { label: 'Emerald', value: 'Emerald' },
  { label: 'Emerald', value: 'Emerald' },
  { label: 'Emerald Beach', value: 'Emerald Beach' },
  { label: 'Emerald Isle', value: 'Emerald Isle' },
  { label: 'Emerald Lake Hills', value: 'Emerald Lake Hills' },
  { label: 'Emerald Lakes', value: 'Emerald Lakes' },
  { label: 'Emerald Mountain', value: 'Emerald Mountain' },
  { label: 'Émerchicourt', value: 'Émerchicourt' },
  { label: 'Emersacker', value: 'Emersacker' },
  { label: 'Emerson', value: 'Emerson' },
  { label: 'Emerson', value: 'Emerson' },
  { label: 'Emerson Hill', value: 'Emerson Hill' },
  { label: 'Emerton', value: 'Emerton' },
  { label: 'Emery County', value: 'Emery County' },
  { label: 'Emeryville', value: 'Emeryville' },
  { label: 'Emet', value: 'Emet' },
  { label: 'Emigsville', value: 'Emigsville' },
  { label: 'Emil Racoviță', value: 'Emil Racoviță' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata', value: 'Emiliano Zapata' },
  { label: 'Emiliano Zapata (Casahuates)', value: 'Emiliano Zapata (Casahuates)' },
  { label: 'Emiliano Zapata (San José Bata)', value: 'Emiliano Zapata (San José Bata)' },
  { label: 'Emiliano Zapata (Santo Domingo)', value: 'Emiliano Zapata (Santo Domingo)' },
  { label: 'Emiliano Zapata Jalisco', value: 'Emiliano Zapata Jalisco' },
  { label: 'Emilianópolis', value: 'Emilianópolis' },
  { label: 'Emilio Carranza', value: 'Emilio Carranza' },
  { label: 'Emilio Carranza (Santa Cruz)', value: 'Emilio Carranza (Santa Cruz)' },
  { label: 'Emilio Portes Gil', value: 'Emilio Portes Gil' },
  { label: 'Emilio Portes Gil', value: 'Emilio Portes Gil' },
  { label: 'Eminabad', value: 'Eminabad' },
  { label: 'Eminence', value: 'Eminence' },
  { label: 'Eminence', value: 'Eminence' },
  { label: 'Emirdağ', value: 'Emirdağ' },
  { label: 'Emirgazi', value: 'Emirgazi' },
  { label: 'Emkendorf', value: 'Emkendorf' },
  { label: 'Emkhomazi', value: 'Emkhomazi' },
  { label: 'Emley', value: 'Emley' },
  { label: 'Emlichheim', value: 'Emlichheim' },
  { label: 'Emmaboda', value: 'Emmaboda' },
  { label: 'Emmaus', value: 'Emmaus' },
  { label: 'Emmaus', value: 'Emmaus' },
  { label: 'Emmeloord', value: 'Emmeloord' },
  { label: 'Emmelsbüll-Horsbüll', value: 'Emmelsbüll-Horsbüll' },
  { label: 'Emmelshausen', value: 'Emmelshausen' },
  { label: 'Emmen', value: 'Emmen' },
  { label: 'Emmen', value: 'Emmen' },
  { label: 'Emmendingen', value: 'Emmendingen' },
  { label: 'Emmental District', value: 'Emmental District' },
  { label: 'Emmer-Compascuum', value: 'Emmer-Compascuum' },
  { label: 'Emmer-Erfscheidenveen', value: 'Emmer-Erfscheidenveen' },
  { label: 'Emmerhout', value: 'Emmerhout' },
  { label: 'Emmerich', value: 'Emmerich' },
  { label: 'Emmerin', value: 'Emmerin' },
  { label: 'Emmering', value: 'Emmering' },
  { label: 'Emmermeer', value: 'Emmermeer' },
  { label: 'Emmerschans', value: 'Emmerschans' },
  { label: 'Emmersdorf An Der Donau', value: 'Emmersdorf An Der Donau' },
  { label: 'Emmet County', value: 'Emmet County' },
  { label: 'Emmet County', value: 'Emmet County' },
  { label: 'Emmetsburg', value: 'Emmetsburg' },
  { label: 'Emmett', value: 'Emmett' },
  { label: 'Emmetten', value: 'Emmetten' },
  { label: 'Emmiganur', value: 'Emmiganur' },
  { label: 'Emmingen-Liptingen', value: 'Emmingen-Liptingen' },
  { label: 'Emmitsburg', value: 'Emmitsburg' },
  { label: 'Emmons County', value: 'Emmons County' },
  { label: 'Emneth', value: 'Emneth' },
  { label: 'Emőd', value: 'Emőd' },
  { label: 'Emory', value: 'Emory' },
  { label: 'Emory', value: 'Emory' },
  { label: 'Empalme', value: 'Empalme' },
  { label: 'Empalme Escobedo', value: 'Empalme Escobedo' },
  { label: 'Empalme Olmos', value: 'Empalme Olmos' },
  { label: 'Empangeni', value: 'Empangeni' },
  { label: 'Empaque Tarriba', value: 'Empaque Tarriba' },
  { label: 'Empedrado', value: 'Empedrado' },
  { label: 'Empedrado', value: 'Empedrado' },
  { label: 'Emperador', value: 'Emperador' },
  { label: 'Empersdorf', value: 'Empersdorf' },
  { label: 'Empfingen', value: 'Empfingen' },
  { label: 'Empingham', value: 'Empingham' },
  { label: 'Empire', value: 'Empire' },
  { label: 'Empire Bay', value: 'Empire Bay' },
  { label: 'Empoli', value: 'Empoli' },
  { label: 'Émponas', value: 'Émponas' },
  { label: 'Emporeío', value: 'Emporeío' },
  { label: 'Emporia', value: 'Emporia' },
  { label: 'Emporia', value: 'Emporia' },
  { label: 'Empório', value: 'Empório' },
  { label: 'Emporium', value: 'Emporium' },
  { label: 'Emsbüren', value: 'Emsbüren' },
  { label: 'Emsdetten', value: 'Emsdetten' },
  { label: 'Emskirchen', value: 'Emskirchen' },
  { label: 'Emst', value: 'Emst' },
  { label: 'Emstek', value: 'Emstek' },
  { label: 'Emsworth', value: 'Emsworth' },
  { label: 'Emsworth', value: 'Emsworth' },
  { label: 'Emtinghausen', value: 'Emtinghausen' },
  { label: 'Emtmannsberg', value: 'Emtmannsberg' },
  { label: 'Emu Heights', value: 'Emu Heights' },
  { label: 'Emu Park', value: 'Emu Park' },
  { label: 'Emu Plains', value: 'Emu Plains' },
  { label: 'Emure-Ekiti', value: 'Emure-Ekiti' },
  { label: 'En Bamboo', value: 'En Bamboo' },
  { label: 'En Boqeq', value: 'En Boqeq' },
  { label: 'En Nâqoûra', value: 'En Nâqoûra' },
  { label: 'En Pois Doux/Babonneau', value: 'En Pois Doux/Babonneau' },
  { label: 'Ena-Shi', value: 'Ena-Shi' },
  { label: 'Enbacka', value: 'Enbacka' },
  { label: 'Enbar Pwin', value: 'Enbar Pwin' },
  { label: 'Encamp', value: 'Encamp' },
  { label: 'Encantada-Ranchito-El Calaboz', value: 'Encantada-Ranchito-El Calaboz' },
  { label: 'Encantado', value: 'Encantado' },
  { label: 'Encanto', value: 'Encanto' },
  { label: 'Encanto', value: 'Encanto' },
  { label: 'Encarnação', value: 'Encarnação' },
  { label: 'Encarnación', value: 'Encarnación' },
  { label: 'Encarnación De Díaz', value: 'Encarnación De Díaz' },
  { label: 'Enchanted Hills', value: 'Enchanted Hills' },
  { label: 'Enchenberg', value: 'Enchenberg' },
  { label: 'Encheng', value: 'Encheng' },
  { label: 'Encina De San Silvestre', value: 'Encina De San Silvestre' },
  { label: 'Encina La', value: 'Encina La' },
  { label: 'Encinacorba', value: 'Encinacorba' },
  { label: 'Encinal Colorado', value: 'Encinal Colorado' },
  { label: 'Encinas', value: 'Encinas' },
  { label: 'Encinas De Abajo', value: 'Encinas De Abajo' },
  { label: 'Encinas De Arriba', value: 'Encinas De Arriba' },
  { label: 'Encinas De Esgueva', value: 'Encinas De Esgueva' },
  { label: 'Encinas Reales', value: 'Encinas Reales' },
  { label: 'Encinasola', value: 'Encinasola' },
  { label: 'Encinasola De Los Comendadores', value: 'Encinasola De Los Comendadores' },
  { label: 'Encinedo', value: 'Encinedo' },
  { label: 'Encinillas', value: 'Encinillas' },
  { label: 'Encinitas', value: 'Encinitas' },
  { label: 'Encino', value: 'Encino' },
  { label: 'Encino', value: 'Encino' },
  { label: 'Encío', value: 'Encío' },
  { label: 'Enciso', value: 'Enciso' },
  { label: 'Enciso', value: 'Enciso' },
  { label: 'Encounter Bay', value: 'Encounter Bay' },
  { label: 'Encrucijada', value: 'Encrucijada' },
  { label: 'Encruzilhada', value: 'Encruzilhada' },
  { label: 'Encruzilhada Do Sul', value: 'Encruzilhada Do Sul' },
  { label: 'Encs', value: 'Encs' },
  { label: 'Encsencs', value: 'Encsencs' },
  { label: 'Encsi Járás', value: 'Encsi Járás' },
  { label: 'Endach', value: 'Endach' },
  { label: 'Endasak', value: 'Endasak' },
  { label: 'Ende', value: 'Ende' },
  { label: 'Endeavour Hills', value: 'Endeavour Hills' },
  { label: 'Enderby', value: 'Enderby' },
  { label: 'Enderby', value: 'Enderby' },
  { label: 'Endicott', value: 'Endicott' },
  { label: 'Endine', value: 'Endine' },
  { label: 'Endingen', value: 'Endingen' },
  { label: 'Endingen', value: 'Endingen' },
  { label: 'Endirey', value: 'Endirey' },
  { label: 'Endrinal', value: 'Endrinal' },
  { label: 'Endwell', value: 'Endwell' },
  { label: 'Enéas Marques', value: 'Enéas Marques' },
  { label: 'Enebakk', value: 'Enebakk' },
  { label: 'Enebyberg', value: 'Enebyberg' },
  { label: 'Enego', value: 'Enego' },
  { label: 'Enem', value: 'Enem' },
  { label: 'Enemonzo-Quinis', value: 'Enemonzo-Quinis' },
  { label: 'Energetik', value: 'Energetik' },
  { label: 'Energodar', value: 'Energodar' },
  { label: 'Energy', value: 'Energy' },
  { label: 'Enériz', value: 'Enériz' },
  { label: 'Enériz/Eneritz', value: 'Enériz/Eneritz' },
  { label: 'Enetai', value: 'Enetai' },
  { label: 'Enez', value: 'Enez' },
  { label: 'Enfield', value: 'Enfield' },
  { label: 'Enfield', value: 'Enfield' },
  { label: 'Enfield', value: 'Enfield' },
  { label: 'Enfield', value: 'Enfield' },
  { label: 'Enfield', value: 'Enfield' },
  { label: 'Enfield', value: 'Enfield' },
  { label: 'Enfield', value: 'Enfield' },
  { label: 'Enfield', value: 'Enfield' },
  { label: 'Enfield Town', value: 'Enfield Town' },
  { label: 'Engadine', value: 'Engadine' },
  { label: 'Engel’S', value: 'Engel’S' },
  { label: 'Engel’Sskiy Rayon', value: 'Engel’Sskiy Rayon' },
  { label: 'Engel’-Yurt', value: 'Engel’-Yurt' },
  { label: 'Engelberg', value: 'Engelberg' },
  { label: 'Engelhartstetten', value: 'Engelhartstetten' },
  { label: 'Engeln', value: 'Engeln' },
  { label: 'Engelsberg', value: 'Engelsberg' },
  { label: 'Engelsbrand', value: 'Engelsbrand' },
  { label: 'Engelskirchen', value: 'Engelskirchen' },
  { label: 'Engelthal', value: 'Engelthal' },
  { label: 'Engen', value: 'Engen' },
  { label: 'Engenheiro Beltrão', value: 'Engenheiro Beltrão' },
  { label: 'Engenheiro Caldas', value: 'Engenheiro Caldas' },
  { label: 'Engenheiro Coelho', value: 'Engenheiro Coelho' },
  { label: 'Engenheiro Navarro', value: 'Engenheiro Navarro' },
  { label: 'Engenheiro Paulo De Frontin', value: 'Engenheiro Paulo De Frontin' },
  { label: 'Engenho Velho', value: 'Engenho Velho' },
  { label: 'Enger', value: 'Enger' },
  { label: 'Engerdal', value: 'Engerdal' },
  { label: 'Engerwitzdorf', value: 'Engerwitzdorf' },
  { label: 'Enghien', value: 'Enghien' },
  { label: 'Enghien-Les-Bains', value: 'Enghien-Les-Bains' },
  { label: 'Engis', value: 'Engis' },
  { label: 'England', value: 'England' },
  { label: 'Englefontaine', value: 'Englefontaine' },
  { label: 'Englehart', value: 'Englehart' },
  { label: 'Englewood', value: 'Englewood' },
  { label: 'Englewood', value: 'Englewood' },
  { label: 'Englewood', value: 'Englewood' },
  { label: 'Englewood', value: 'Englewood' },
  { label: 'Englewood', value: 'Englewood' },
  { label: 'Englewood', value: 'Englewood' },
  { label: 'Englewood Cliffs', value: 'Englewood Cliffs' },
  { label: 'English', value: 'English' },
  { label: 'English Corner', value: 'English Corner' },
  { label: 'Englishtown', value: 'Englishtown' },
  { label: 'Enguera', value: 'Enguera' },
  { label: 'Enguídanos', value: 'Enguídanos' },
  { label: 'Engure', value: 'Engure' },
  { label: 'Enhagen-Ekbacken', value: 'Enhagen-Ekbacken' },
  { label: 'Enhaut', value: 'Enhaut' },
  { label: 'Enid', value: 'Enid' },
  { label: 'Enigma', value: 'Enigma' },
  { label: 'Eningen Unter Achalm', value: 'Eningen Unter Achalm' },
  { label: 'Enisala', value: 'Enisala' },
  { label: 'Eniwa-Shi', value: 'Eniwa-Shi' },
  { label: 'Enix', value: 'Enix' },
  { label: 'Enkenbach-Alsenborn', value: 'Enkenbach-Alsenborn' },
  { label: 'Enkhuizen', value: 'Enkhuizen' },
  { label: 'Enkirch', value: 'Enkirch' },
  { label: 'Enköping', value: 'Enköping' },
  { label: 'Enlow', value: 'Enlow' },
  { label: 'Enmore', value: 'Enmore' },
  { label: 'Enna', value: 'Enna' },
  { label: 'Ennenda', value: 'Ennenda' },
  { label: 'Ennepetal', value: 'Ennepetal' },
  { label: 'Ennery', value: 'Ennery' },
  { label: 'Ennery', value: 'Ennery' },
  { label: 'Ennery', value: 'Ennery' },
  { label: 'Ennetach', value: 'Ennetach' },
  { label: 'Ennetbürgen', value: 'Ennetbürgen' },
  { label: 'Ennetières-En-Weppes', value: 'Ennetières-En-Weppes' },
  { label: 'Ennevelin', value: 'Ennevelin' },
  { label: 'Ennezat', value: 'Ennezat' },
  { label: 'Ennigerloh', value: 'Ennigerloh' },
  { label: 'Ennis', value: 'Ennis' },
  { label: 'Ennis', value: 'Ennis' },
  { label: 'Enniscorthy', value: 'Enniscorthy' },
  { label: 'Enniskerry', value: 'Enniskerry' },
  { label: 'Enniskillen', value: 'Enniskillen' },
  { label: 'Enns', value: 'Enns' },
  { label: 'Ennsdorf', value: 'Ennsdorf' },
  { label: 'Eno', value: 'Eno' },
  { label: 'Enoch', value: 'Enoch' },
  { label: 'Enochville', value: 'Enochville' },
  { label: 'Enoggera', value: 'Enoggera' },
  { label: 'Enola', value: 'Enola' },
  { label: 'Enon', value: 'Enon' },
  { label: 'Enon', value: 'Enon' },
  { label: 'Enonkoski', value: 'Enonkoski' },
  { label: 'Enontekiö', value: 'Enontekiö' },
  { label: 'Enosburg Falls', value: 'Enosburg Falls' },
  { label: 'Ènova L', value: 'Ènova L' },
  { label: 'Enramadas', value: 'Enramadas' },
  { label: 'Enrile', value: 'Enrile' },
  { label: 'Enrile', value: 'Enrile' },
  { label: 'Enrique Baldivieso', value: 'Enrique Baldivieso' },
  { label: 'Enrique Estrada', value: 'Enrique Estrada' },
  { label: 'Enrique Villanueva', value: 'Enrique Villanueva' },
  { label: 'Enriquillo', value: 'Enriquillo' },
  { label: 'Ens', value: 'Ens' },
  { label: 'Ensanche Luperón', value: 'Ensanche Luperón' },
  { label: 'Enschede', value: 'Enschede' },
  { label: 'Enschot', value: 'Enschot' },
  { label: 'Ensdorf', value: 'Ensdorf' },
  { label: 'Ensdorf', value: 'Ensdorf' },
  { label: 'Ensenada', value: 'Ensenada' },
  { label: 'Enshi', value: 'Enshi' },
  { label: 'Enshi Tujiazu Miaozu Zizhizhou', value: 'Enshi Tujiazu Miaozu Zizhizhou' },
  { label: 'Ensisheim', value: 'Ensisheim' },
  { label: 'Ensley', value: 'Ensley' },
  { label: 'Ensom', value: 'Ensom' },
  { label: 'Ensuès-La-Redonne', value: 'Ensuès-La-Redonne' },
  { label: 'Entabladero', value: 'Entabladero' },
  { label: 'Entablado', value: 'Entablado' },
  { label: 'Entablado', value: 'Entablado' },
  { label: 'Entebbe', value: 'Entebbe' },
  { label: 'Enterprise', value: 'Enterprise' },
  { label: 'Enterprise', value: 'Enterprise' },
  { label: 'Enterprise', value: 'Enterprise' },
  { label: 'Enterprise', value: 'Enterprise' },
  { label: 'Enthavi', value: 'Enthavi' },
  { label: 'Entiat', value: 'Entiat' },
  { label: 'Entlebuch', value: 'Entlebuch' },
  { label: 'Entlebuch District', value: 'Entlebuch District' },
  { label: 'Entracque', value: 'Entracque' },
  { label: 'Entradero', value: 'Entradero' },
  { label: 'Entraigues-Sur-La-Sorgue', value: 'Entraigues-Sur-La-Sorgue' },
  { label: 'Entrala', value: 'Entrala' },
  { label: 'Entrambasaguas', value: 'Entrambasaguas' },
  { label: 'Entrammes', value: 'Entrammes' },
  { label: 'Entrange', value: 'Entrange' },
  { label: 'Entratico', value: 'Entratico' },
  { label: 'Entraygues-Sur-Truyère', value: 'Entraygues-Sur-Truyère' },
  { label: 'Entre Folhas', value: 'Entre Folhas' },
  { label: 'Entre Rios', value: 'Entre Rios' },
  { label: 'Entre Rios', value: 'Entre Rios' },
  { label: 'Entre Ríos', value: 'Entre Ríos' },
  { label: 'Entre Rios De Minas', value: 'Entre Rios De Minas' },
  { label: 'Entre Rios Do Oeste', value: 'Entre Rios Do Oeste' },
  { label: 'Entre Rios Do Sul', value: 'Entre Rios Do Sul' },
  { label: 'Entre-Deux-Guiers', value: 'Entre-Deux-Guiers' },
  { label: 'Entre-Ijuís', value: 'Entre-Ijuís' },
  { label: 'Entremont District', value: 'Entremont District' },
  { label: 'Entrena', value: 'Entrena' },
  { label: 'Entrepot', value: 'Entrepot' },
  { label: 'Entrerrios', value: 'Entrerrios' },
  { label: 'Entrimo', value: 'Entrimo' },
  { label: 'Entrín Bajo', value: 'Entrín Bajo' },
  { label: 'Entroncamento', value: 'Entroncamento' },
  { label: 'Entronque De Matehuala (El Huizache)', value: 'Entronque De Matehuala (El Huizache)' },
  { label: 'Entronque Laredo-Salinas Victoria', value: 'Entronque Laredo-Salinas Victoria' },
  { label: 'Entzheim', value: 'Entzheim' },
  { label: 'Enugu', value: 'Enugu' },
  { label: 'Enugu-Ezike', value: 'Enugu-Ezike' },
  { label: 'Enugu-Ukwu', value: 'Enugu-Ukwu' },
  { label: 'Enumclaw', value: 'Enumclaw' },
  { label: 'Enval', value: 'Enval' },
  { label: 'Envermeu', value: 'Envermeu' },
  { label: 'Envie', value: 'Envie' },
  { label: 'Envigado', value: 'Envigado' },
  { label: 'Envira', value: 'Envira' },
  { label: 'Enxara Do Bispo', value: 'Enxara Do Bispo' },
  { label: 'Enyerhyetykaw', value: 'Enyerhyetykaw' },
  { label: 'Enying', value: 'Enying' },
  { label: 'Enyingi Járás', value: 'Enyingi Járás' },
  { label: 'Enzan', value: 'Enzan' },
  { label: 'Enzenreith', value: 'Enzenreith' },
  { label: 'Enzersdorf An Der Fischa', value: 'Enzersdorf An Der Fischa' },
  { label: 'Enzersfeld', value: 'Enzersfeld' },
  { label: 'Enzklösterle', value: 'Enzklösterle' },
  { label: 'Eot Municipality', value: 'Eot Municipality' },
  { label: 'Éoures', value: 'Éoures' },
  { label: 'Épagny', value: 'Épagny' },
  { label: 'Épaignes', value: 'Épaignes' },
  { label: 'Epalinges', value: 'Epalinges' },
  { label: 'Epanomí', value: 'Epanomí' },
  { label: 'Epazoyucan', value: 'Epazoyucan' },
  { label: 'Epe', value: 'Epe' },
  { label: 'Epe', value: 'Epe' },
  { label: 'Epeche Chico', value: 'Epeche Chico' },
  { label: 'Epeche Grande', value: 'Epeche Grande' },
  { label: 'Épehy', value: 'Épehy' },
  { label: 'Ependes', value: 'Ependes' },
  { label: 'Éperlecques', value: 'Éperlecques' },
  { label: 'Épernay', value: 'Épernay' },
  { label: 'Épernon', value: 'Épernon' },
  { label: 'Épervans', value: 'Épervans' },
  { label: 'Epfenbach', value: 'Epfenbach' },
  { label: 'Epfendorf', value: 'Epfendorf' },
  { label: 'Epfig', value: 'Epfig' },
  { label: 'Ephraim', value: 'Ephraim' },
  { label: 'Ephrata', value: 'Ephrata' },
  { label: 'Ephrata', value: 'Ephrata' },
  { label: 'Épieds-En-Beauce', value: 'Épieds-En-Beauce' },
  { label: 'Epigmenio González', value: 'Epigmenio González' },
  { label: 'Épila', value: 'Épila' },
  { label: 'Épinac', value: 'Épinac' },
  { label: 'Épinal', value: 'Épinal' },
  { label: 'Épinay-Sous-Sénart', value: 'Épinay-Sous-Sénart' },
  { label: 'Épinay-Sur-Orge', value: 'Épinay-Sur-Orge' },
  { label: 'Épinay-Sur-Seine', value: 'Épinay-Sur-Seine' },
  { label: 'Epiniac', value: 'Epiniac' },
  { label: 'Épinouze', value: 'Épinouze' },
  { label: 'Episcopia', value: 'Episcopia' },
  { label: 'Episkopí', value: 'Episkopí' },
  { label: 'Epitacio Huerta', value: 'Epitacio Huerta' },
  { label: 'Epitaciolândia', value: 'Epitaciolândia' },
  { label: 'Epitálio', value: 'Epitálio' },
  { label: 'Epöl', value: 'Epöl' },
  { label: 'Épône', value: 'Épône' },
  { label: 'Épouville', value: 'Épouville' },
  { label: 'Eppelborn', value: 'Eppelborn' },
  { label: 'Eppelheim', value: 'Eppelheim' },
  { label: 'Eppelsheim', value: 'Eppelsheim' },
  { label: 'Eppenbrunn', value: 'Eppenbrunn' },
  { label: 'Eppendorf', value: 'Eppendorf' },
  { label: 'Eppenstein', value: 'Eppenstein' },
  { label: 'Eppertshausen', value: 'Eppertshausen' },
  { label: 'Eppeville', value: 'Eppeville' },
  { label: 'Epping', value: 'Epping' },
  { label: 'Epping', value: 'Epping' },
  { label: 'Epping', value: 'Epping' },
  { label: 'Epping', value: 'Epping' },
  { label: 'Eppingen', value: 'Eppingen' },
  { label: 'Eppishausen', value: 'Eppishausen' },
  { label: 'Eppstein', value: 'Eppstein' },
  { label: 'Épron', value: 'Épron' },
  { label: 'Epse', value: 'Epse' },
  { label: 'Epsom', value: 'Epsom' },
  { label: 'Epsom', value: 'Epsom' },
  { label: 'Epsom', value: 'Epsom' },
  { label: 'Epson', value: 'Epson' },
  { label: 'Epupa Constituency', value: 'Epupa Constituency' },
  { label: 'Epureni', value: 'Epureni' },
  { label: 'Epworth', value: 'Epworth' },
  { label: 'Epworth', value: 'Epworth' },
  { label: 'Epworth', value: 'Epworth' },
  { label: 'Epworth', value: 'Epworth' },
  { label: 'Eqbaliyeh', value: 'Eqbaliyeh' },
  { label: 'Eqbaliyeh', value: 'Eqbaliyeh' },
  { label: 'Eqlid', value: 'Eqlid' },
  { label: 'Equador', value: 'Equador' },
  { label: 'Équemauville', value: 'Équemauville' },
  { label: 'Équeurdreville-Hainneville', value: 'Équeurdreville-Hainneville' },
  { label: 'Équihen-Plage', value: 'Équihen-Plage' },
  { label: 'Er Regueb', value: 'Er Regueb' },
  { label: 'Er Reina', value: 'Er Reina' },
  { label: 'Era', value: 'Era' },
  { label: 'Eraan', value: 'Eraan' },
  { label: 'Eraclea', value: 'Eraclea' },
  { label: 'Éragny', value: 'Éragny' },
  { label: 'Eral', value: 'Eral' },
  { label: 'Erandio', value: 'Erandio' },
  { label: 'Erandique', value: 'Erandique' },
  { label: 'Erandol', value: 'Erandol' },
  { label: 'Eraniel', value: 'Eraniel' },
  { label: 'Erath', value: 'Erath' },
  { label: 'Erath County', value: 'Erath County' },
  { label: 'Eratsun', value: 'Eratsun' },
  { label: 'Erattupetta', value: 'Erattupetta' },
  { label: 'Erátyra', value: 'Erátyra' },
  { label: 'Eravur Town', value: 'Eravur Town' },
  { label: 'Erba', value: 'Erba' },
  { label: 'Erbaa', value: 'Erbaa' },
  { label: 'Erbach', value: 'Erbach' },
  { label: 'Erbach', value: 'Erbach' },
  { label: 'Erbè', value: 'Erbè' },
  { label: 'Erbendorf', value: 'Erbendorf' },
  { label: 'Erbes-Büdesheim', value: 'Erbes-Büdesheim' },
  { label: 'Erbezzo', value: 'Erbezzo' },
  { label: 'Erbiceni', value: 'Erbiceni' },
  { label: 'Erbil', value: 'Erbil' },
  { label: 'Erbray', value: 'Erbray' },
  { label: 'Erbrée', value: 'Erbrée' },
  { label: 'Erbusco', value: 'Erbusco' },
  { label: 'Ercea', value: 'Ercea' },
  { label: 'Ercé-En-Lamée', value: 'Ercé-En-Lamée' },
  { label: 'Ercé-Près-Liffré', value: 'Ercé-Près-Liffré' },
  { label: 'Erchie', value: 'Erchie' },
  { label: 'Ercilla', value: 'Ercilla' },
  { label: 'Ercina La', value: 'Ercina La' },
  { label: 'Erciş', value: 'Erciş' },
  { label: 'Ercolano', value: 'Ercolano' },
  { label: 'Ercsi', value: 'Ercsi' },
  { label: 'Ercuis', value: 'Ercuis' },
  { label: 'Érd', value: 'Érd' },
  { label: 'Erda', value: 'Erda' },
  { label: 'Erdaojiang', value: 'Erdaojiang' },
  { label: 'Erdeborn', value: 'Erdeborn' },
  { label: 'Erdek', value: 'Erdek' },
  { label: 'Erdemli', value: 'Erdemli' },
  { label: 'Erdenet', value: 'Erdenet' },
  { label: 'Erdeven', value: 'Erdeven' },
  { label: 'Érdi Járás', value: 'Érdi Járás' },
  { label: 'Erding', value: 'Erding' },
  { label: 'Erdmannhausen', value: 'Erdmannhausen' },
  { label: 'Erdőkertes', value: 'Erdőkertes' },
  { label: 'Erdőtelek', value: 'Erdőtelek' },
  { label: 'Erdut', value: 'Erdut' },
  { label: 'Erdweg', value: 'Erdweg' },
  { label: 'Erebango', value: 'Erebango' },
  { label: 'Erechim', value: 'Erechim' },
  { label: 'Ereencav', value: 'Ereencav' },
  { label: 'Ereğli', value: 'Ereğli' },
  { label: 'Ereğli', value: 'Ereğli' },
  { label: 'Eremieni', value: 'Eremieni' },
  { label: 'Eremitu', value: 'Eremitu' },
  { label: 'Eremo', value: 'Eremo' },
  { label: 'Erenas', value: 'Erenas' },
  { label: 'Erenas', value: 'Erenas' },
  { label: 'Erenhot', value: 'Erenhot' },
  { label: 'Erenler', value: 'Erenler' },
  { label: 'Ereño', value: 'Ereño' },
  { label: 'Ererê', value: 'Ererê' },
  { label: 'Eresing', value: 'Eresing' },
  { label: 'Eresk', value: 'Eresk' },
  { label: 'Erétria', value: 'Erétria' },
  { label: 'Érezée', value: 'Érezée' },
  { label: 'Erfde', value: 'Erfde' },
  { label: 'Erfelek', value: 'Erfelek' },
  { label: 'Erftstadt', value: 'Erftstadt' },
  { label: 'Erfurt', value: 'Erfurt' },
  { label: 'Erfweiler', value: 'Erfweiler' },
  { label: 'Ergani', value: 'Ergani' },
  { label: 'Ergátes', value: 'Ergátes' },
  { label: 'Ergene', value: 'Ergene' },
  { label: 'Ergersheim', value: 'Ergersheim' },
  { label: 'Ērgļi', value: 'Ērgļi' },
  { label: 'Ergoiena', value: 'Ergoiena' },
  { label: 'Ergolding', value: 'Ergolding' },
  { label: 'Ergoldsbach', value: 'Ergoldsbach' },
  { label: 'Ergué-Gabéric', value: 'Ergué-Gabéric' },
  { label: 'Erice', value: 'Erice' },
  { label: 'Ericeira', value: 'Ericeira' },
  { label: 'Ericeira', value: 'Ericeira' },
  { label: 'Erick', value: 'Erick' },
  { label: 'Érico Cardoso', value: 'Érico Cardoso' },
  { label: 'Erie', value: 'Erie' },
  { label: 'Erie', value: 'Erie' },
  { label: 'Erie', value: 'Erie' },
  { label: 'Erie', value: 'Erie' },
  { label: 'Erie County', value: 'Erie County' },
  { label: 'Erie County', value: 'Erie County' },
  { label: 'Erie County', value: 'Erie County' },
  { label: 'Eriksberg', value: 'Eriksberg' },
  { label: 'Eriksbo', value: 'Eriksbo' },
  { label: 'Erími', value: 'Erími' },
  { label: 'Erin', value: 'Erin' },
  { label: 'Erina', value: 'Erina' },
  { label: 'Erindale', value: 'Erindale' },
  { label: 'Ering', value: 'Ering' },
  { label: 'Eriskirch', value: 'Eriskirch' },
  { label: 'Eriswil', value: 'Eriswil' },
  { label: 'Erith', value: 'Erith' },
  { label: 'Erkelenz', value: 'Erkelenz' },
  { label: 'Erkenbrechtsweiler', value: 'Erkenbrechtsweiler' },
  { label: 'Erkerode', value: 'Erkerode' },
  { label: 'Erkheim', value: 'Erkheim' },
  { label: 'Erkner', value: 'Erkner' },
  { label: 'Erkrath', value: 'Erkrath' },
  { label: 'Erl', value: 'Erl' },
  { label: 'Erla', value: 'Erla' },
  { label: 'Erlabrunn', value: 'Erlabrunn' },
  { label: 'Erlach', value: 'Erlach' },
  { label: 'Erlands Point-Kitsap Lake', value: 'Erlands Point-Kitsap Lake' },
  { label: 'Erlangen', value: 'Erlangen' },
  { label: 'Erlanger', value: 'Erlanger' },
  { label: 'Erlau', value: 'Erlau' },
  { label: 'Erlauf', value: 'Erlauf' },
  { label: 'Erlbach', value: 'Erlbach' },
  { label: 'Erlbach', value: 'Erlbach' },
  { label: 'Erlen', value: 'Erlen' },
  { label: 'Erlenbach', value: 'Erlenbach' },
  { label: 'Erlenbach', value: 'Erlenbach' },
  { label: 'Erlenbach', value: 'Erlenbach' },
  { label: 'Erlenbach / Links Des Dorfbachs Oberhalb Bahnlinie', value: 'Erlenbach / Links Des Dorfbachs Oberhalb Bahnlinie' },
  { label: 'Erlenbach / Rechts Des Dorfbachs Oberhalb Bahnlinie', value: 'Erlenbach / Rechts Des Dorfbachs Oberhalb Bahnlinie' },
  { label: 'Erlenbach Am Main', value: 'Erlenbach Am Main' },
  { label: 'Erlenbach Im Simmental', value: 'Erlenbach Im Simmental' },
  { label: 'Erlenmoos', value: 'Erlenmoos' },
  { label: 'Erlensee', value: 'Erlensee' },
  { label: 'Erli', value: 'Erli' },
  { label: 'Erligheim', value: 'Erligheim' },
  { label: 'Erlinsbach', value: 'Erlinsbach' },
  { label: 'Erma', value: 'Erma' },
  { label: 'Ermatingen', value: 'Ermatingen' },
  { label: 'Ermelo', value: 'Ermelo' },
  { label: 'Ermelo', value: 'Ermelo' },
  { label: 'Ermenek', value: 'Ermenek' },
  { label: 'Ermera Villa', value: 'Ermera Villa' },
  { label: 'Ermesinde', value: 'Ermesinde' },
  { label: 'Ermington', value: 'Ermington' },
  { label: 'Ermióni', value: 'Ermióni' },
  { label: 'Ermita', value: 'Ermita' },
  { label: 'Ermita', value: 'Ermita' },
  { label: 'Ermita De Guadalupe', value: 'Ermita De Guadalupe' },
  { label: 'Ermitagaña', value: 'Ermitagaña' },
  { label: 'Ermlitz', value: 'Ermlitz' },
  { label: 'Ermo', value: 'Ermo' },
  { label: 'Ermont', value: 'Ermont' },
  { label: 'Ermoúpolis', value: 'Ermoúpolis' },
  { label: 'Ermua', value: 'Ermua' },
  { label: 'Ernakulam', value: 'Ernakulam' },
  { label: 'Erndtebrück', value: 'Erndtebrück' },
  { label: 'Ernée', value: 'Ernée' },
  { label: 'Ernei', value: 'Ernei' },
  { label: 'Ernestina', value: 'Ernestina' },
  { label: 'Ernestinovo', value: 'Ernestinovo' },
  { label: 'Ernolsheim-Bruche', value: 'Ernolsheim-Bruche' },
  { label: 'Ernsgaden', value: 'Ernsgaden' },
  { label: 'Ernstbrunn', value: 'Ernstbrunn' },
  { label: 'Ernsthofen', value: 'Ernsthofen' },
  { label: 'Ernstroda', value: 'Ernstroda' },
  { label: 'Erode', value: 'Erode' },
  { label: 'Erolzheim', value: 'Erolzheim' },
  { label: 'Erongarícuaro', value: 'Erongarícuaro' },
  { label: 'Erp', value: 'Erp' },
  { label: 'Erpel', value: 'Erpel' },
  { label: 'Erpeldange', value: 'Erpeldange' },
  { label: 'Erpeli', value: 'Erpeli' },
  { label: 'Erpersdorf', value: 'Erpersdorf' },
  { label: 'Erpfendorf', value: 'Erpfendorf' },
  { label: 'Erpolzheim', value: 'Erpolzheim' },
  { label: 'Erquelinnes', value: 'Erquelinnes' },
  { label: 'Erquinghem-Lys', value: 'Erquinghem-Lys' },
  { label: 'Erquy', value: 'Erquy' },
  { label: 'Errachidia', value: 'Errachidia' },
  { label: 'Erraguntla', value: 'Erraguntla' },
  { label: 'Erre', value: 'Erre' },
  { label: 'Errenteria', value: 'Errenteria' },
  { label: 'Errezil', value: 'Errezil' },
  { label: 'Erriadh', value: 'Erriadh' },
  { label: 'Erriberagoitia/Ribera Alta', value: 'Erriberagoitia/Ribera Alta' },
  { label: 'Errigoiti', value: 'Errigoiti' },
  { label: 'Erro', value: 'Erro' },
  { label: 'Errol', value: 'Errol' },
  { label: 'Érsekcsanád', value: 'Érsekcsanád' },
  { label: 'Ersekë', value: 'Ersekë' },
  { label: 'Érsekvadkert', value: 'Érsekvadkert' },
  { label: 'Ersigen', value: 'Ersigen' },
  { label: 'Ersingen', value: 'Ersingen' },
  { label: 'Erskine', value: 'Erskine' },
  { label: 'Erskine', value: 'Erskine' },
  { label: 'Erskine Park', value: 'Erskine Park' },
  { label: 'Erskineville', value: 'Erskineville' },
  { label: 'Ersmark', value: 'Ersmark' },
  { label: 'Erstein', value: 'Erstein' },
  { label: 'Erstfeld', value: 'Erstfeld' },
  { label: 'Ertil’', value: 'Ertil’' },
  { label: 'Ertil’Skiy Rayon', value: 'Ertil’Skiy Rayon' },
  { label: 'Ertingen', value: 'Ertingen' },
  { label: 'Ertl', value: 'Ertl' },
  { label: 'Erto', value: 'Erto' },
  { label: 'Eruh', value: 'Eruh' },
  { label: 'Erula', value: 'Erula' },
  { label: 'Erumaippatti', value: 'Erumaippatti' },
  { label: 'Erustes', value: 'Erustes' },
  { label: 'Erval Grande', value: 'Erval Grande' },
  { label: 'Erval Seco', value: 'Erval Seco' },
  { label: 'Erval Velho', value: 'Erval Velho' },
  { label: 'Ervália', value: 'Ervália' },
  { label: 'Ervas Tenras', value: 'Ervas Tenras' },
  { label: 'Erve', value: 'Erve' },
  { label: 'Ervedosa', value: 'Ervedosa' },
  { label: 'Ervedosa Do Douro', value: 'Ervedosa Do Douro' },
  { label: 'Erving', value: 'Erving' },
  { label: 'Ervy-Le-Châtel', value: 'Ervy-Le-Châtel' },
  { label: 'Erwangzhuang', value: 'Erwangzhuang' },
  { label: 'Erwin', value: 'Erwin' },
  { label: 'Erwin', value: 'Erwin' },
  { label: 'Erwinville', value: 'Erwinville' },
  { label: 'Erwitte', value: 'Erwitte' },
  { label: 'Erxleben', value: 'Erxleben' },
  { label: 'Erythrés', value: 'Erythrés' },
  { label: 'Erzhausen', value: 'Erzhausen' },
  { label: 'Erzin', value: 'Erzin' },
  { label: 'Erzin', value: 'Erzin' },
  { label: 'Erzsébetváros', value: 'Erzsébetváros' },
  { label: 'Es Castell', value: 'Es Castell' },
  { label: 'Es Molinar', value: 'Es Molinar' },
  { label: 'Es Senia', value: 'Es Senia' },
  { label: 'Esa’Ala', value: 'Esa’Ala' },
  { label: 'Esanatoglia', value: 'Esanatoglia' },
  { label: 'Esbjerg', value: 'Esbjerg' },
  { label: 'Esbjerg Kommune', value: 'Esbjerg Kommune' },
  { label: 'Esbly', value: 'Esbly' },
  { label: 'Escacena Del Campo', value: 'Escacena Del Campo' },
  { label: 'Escada', value: 'Escada' },
  { label: 'Escala L', value: 'Escala L' },
  { label: 'Escalante', value: 'Escalante' },
  { label: 'Escalante', value: 'Escalante' },
  { label: 'Escalaplano', value: 'Escalaplano' },
  { label: 'Escaleras', value: 'Escaleras' },
  { label: 'Escalerilla Lagunas', value: 'Escalerilla Lagunas' },
  { label: 'Escalerillas', value: 'Escalerillas' },
  { label: 'Escalhão', value: 'Escalhão' },
  { label: 'Escalon', value: 'Escalon' },
  { label: 'Escalona', value: 'Escalona' },
  { label: 'Escalona Del Prado', value: 'Escalona Del Prado' },
  { label: 'Escalonilla', value: 'Escalonilla' },
  { label: 'Escalquens', value: 'Escalquens' },
  { label: 'Escambia County', value: 'Escambia County' },
  { label: 'Escambia County', value: 'Escambia County' },
  { label: 'Escamilla', value: 'Escamilla' },
  { label: 'Escamillas', value: 'Escamillas' },
  { label: 'Escanaba', value: 'Escanaba' },
  { label: 'Escañuela', value: 'Escañuela' },
  { label: 'Escap', value: 'Escap' },
  { label: 'Escarabajosa De Cabezas', value: 'Escarabajosa De Cabezas' },
  { label: 'Escárcega', value: 'Escárcega' },
  { label: 'Escariche', value: 'Escariche' },
  { label: 'Escatawpa', value: 'Escatawpa' },
  { label: 'Escatrón', value: 'Escatrón' },
  { label: 'Escaudain', value: 'Escaudain' },
  { label: 'Escaudœuvres', value: 'Escaudœuvres' },
  { label: 'Escautpont', value: 'Escautpont' },
  { label: 'Escazú', value: 'Escazú' },
  { label: 'Esch', value: 'Esch' },
  { label: 'Esch', value: 'Esch' },
  { label: 'Eschach', value: 'Eschach' },
  { label: 'Eschau', value: 'Eschau' },
  { label: 'Eschau', value: 'Eschau' },
  { label: 'Eschbach', value: 'Eschbach' },
  { label: 'Eschborn', value: 'Eschborn' },
  { label: 'Escheburg', value: 'Escheburg' },
  { label: 'Eschede', value: 'Eschede' },
  { label: 'Eschelbronn', value: 'Eschelbronn' },
  { label: 'Eschen', value: 'Eschen' },
  { label: 'Eschenau', value: 'Eschenau' },
  { label: 'Eschenbach', value: 'Eschenbach' },
  { label: 'Eschenbach', value: 'Eschenbach' },
  { label: 'Eschenlohe', value: 'Eschenlohe' },
  { label: 'Eschentzwiller', value: 'Eschentzwiller' },
  { label: 'Eschenz', value: 'Eschenz' },
  { label: 'Eschershausen', value: 'Eschershausen' },
  { label: 'Esches', value: 'Esches' },
  { label: 'Eschlikon', value: 'Eschlikon' },
  { label: 'Eschlkam', value: 'Eschlkam' },
  { label: 'Eschol Park', value: 'Eschol Park' },
  { label: 'Escholzmatt', value: 'Escholzmatt' },
  { label: 'Esch-Sur-Alzette', value: 'Esch-Sur-Alzette' },
  { label: 'Esch-Sur-Sûre', value: 'Esch-Sur-Sûre' },
  { label: 'Eschwege', value: 'Eschwege' },
  { label: 'Eschweiler', value: 'Eschweiler' },
  { label: 'Escobal', value: 'Escobal' },
  { label: 'Escobar De Campos', value: 'Escobar De Campos' },
  { label: 'Escobar De Polendos', value: 'Escobar De Polendos' },
  { label: 'Escobares', value: 'Escobares' },
  { label: 'Escobosa De Almazán', value: 'Escobosa De Almazán' },
  { label: 'Escolasticas', value: 'Escolasticas' },
  { label: 'Escolca', value: 'Escolca' },
  { label: 'Escolín De Olarte', value: 'Escolín De Olarte' },
  { label: 'Escondido', value: 'Escondido' },
  { label: 'Escopete', value: 'Escopete' },
  { label: 'Escorca', value: 'Escorca' },
  { label: 'Escorial El', value: 'Escorial El' },
  { label: 'Escorihuela', value: 'Escorihuela' },
  { label: 'Escoutoux', value: 'Escoutoux' },
  { label: 'Escuadrón Doscientos Uno', value: 'Escuadrón Doscientos Uno' },
  { label: 'Escucha', value: 'Escucha' },
  { label: 'Escuinapa', value: 'Escuinapa' },
  { label: 'Escuintla', value: 'Escuintla' },
  { label: 'Escuintla', value: 'Escuintla' },
  { label: 'Escurial', value: 'Escurial' },
  { label: 'Escurial De La Sierra', value: 'Escurial De La Sierra' },
  { label: 'Escúzar', value: 'Escúzar' },
  { label: 'Eséka', value: 'Eséka' },
  { label: 'Eşelniţa', value: 'Eşelniţa' },
  { label: 'Esenler', value: 'Esenler' },
  { label: 'Esens', value: 'Esens' },
  { label: 'Esenyurt', value: 'Esenyurt' },
  { label: 'Esfahan', value: 'Esfahan' },
  { label: 'Esfarayen', value: 'Esfarayen' },
  { label: 'Esfarvarin', value: 'Esfarvarin' },
  { label: 'Esfedan', value: 'Esfedan' },
  { label: 'Esfuerzos Unidos', value: 'Esfuerzos Unidos' },
  { label: 'Esgos', value: 'Esgos' },
  { label: 'Esgueira', value: 'Esgueira' },
  { label: 'Esguevillas De Esgueva', value: 'Esguevillas De Esgueva' },
  { label: 'Esh Sheikh Dannūn', value: 'Esh Sheikh Dannūn' },
  { label: 'Esher', value: 'Esher' },
  { label: 'Eshowe', value: 'Eshowe' },
  { label: 'Eshqabad', value: 'Eshqabad' },
  { label: 'Eshqabad', value: 'Eshqabad' },
  { label: 'Eshtehard', value: 'Eshtehard' },
  { label: 'Esigodini', value: 'Esigodini' },
  { label: 'Esik', value: 'Esik' },
  { label: 'Esikhaleni', value: 'Esikhaleni' },
  { label: 'Esil', value: 'Esil' },
  { label: 'Esine', value: 'Esine' },
  { label: 'Esino Lario', value: 'Esino Lario' },
  { label: 'Esk', value: 'Esk' },
  { label: 'Eskanan', value: 'Eskanan' },
  { label: 'Eskhar', value: 'Eskhar' },
  { label: 'Eskifjörður', value: 'Eskifjörður' },
  { label: 'Eskil', value: 'Eskil' },
  { label: 'Eskilstuna', value: 'Eskilstuna' },
  { label: 'Eskipazar', value: 'Eskipazar' },
  { label: 'Esko', value: 'Esko' },
  { label: 'Eskoriatza', value: 'Eskoriatza' },
  { label: 'Eslāmābād', value: 'Eslāmābād' },
  { label: 'Eslamabad-E-Gharb', value: 'Eslamabad-E-Gharb' },
  { label: 'Eslamiyeh', value: 'Eslamiyeh' },
  { label: 'Eslamshahr', value: 'Eslamshahr' },
  { label: 'Eslarn', value: 'Eslarn' },
  { label: 'Eslava', value: 'Eslava' },
  { label: 'Eslettes', value: 'Eslettes' },
  { label: 'Eslida', value: 'Eslida' },
  { label: 'Eslohe', value: 'Eslohe' },
  { label: 'Eslöv', value: 'Eslöv' },
  { label: 'Eşme', value: 'Eşme' },
  { label: 'Esmeralda', value: 'Esmeralda' },
  { label: 'Esmeralda', value: 'Esmeralda' },
  { label: 'Esmeralda', value: 'Esmeralda' },
  { label: 'Esmeralda', value: 'Esmeralda' },
  { label: 'Esmeralda', value: 'Esmeralda' },
  { label: 'Esmeralda County', value: 'Esmeralda County' },
  { label: 'Esmeraldas', value: 'Esmeraldas' },
  { label: 'Esmeraldas', value: 'Esmeraldas' },
  { label: 'Esmoriz', value: 'Esmoriz' },
  { label: 'Esnandes', value: 'Esnandes' },
  { label: 'Esneux', value: 'Esneux' },
  { label: 'Espadaña', value: 'Espadaña' },
  { label: 'Espadañedo', value: 'Espadañedo' },
  { label: 'Espadilla', value: 'Espadilla' },
  { label: 'Espakeh', value: 'Espakeh' },
  { label: 'Espalion', value: 'Espalion' },
  { label: 'Espaly-Saint-Marcel', value: 'Espaly-Saint-Marcel' },
  { label: 'España', value: 'España' },
  { label: 'Espanola', value: 'Espanola' },
  { label: 'Española', value: 'Española' },
  { label: 'Espargo', value: 'Espargo' },
  { label: 'Espargos', value: 'Espargos' },
  { label: 'Esparragal', value: 'Esparragal' },
  { label: 'Esparragalejo', value: 'Esparragalejo' },
  { label: 'Esparragosa De La Serena', value: 'Esparragosa De La Serena' },
  { label: 'Esparragosa De Lares', value: 'Esparragosa De Lares' },
  { label: 'Esparreguera', value: 'Esparreguera' },
  { label: 'Esparta', value: 'Esparta' },
  { label: 'Espartinas', value: 'Espartinas' },
  { label: 'Esparto', value: 'Esparto' },
  { label: 'Esparza', value: 'Esparza' },
  { label: 'Esparza De Salazar/Espartza Zaraitzu', value: 'Esparza De Salazar/Espartza Zaraitzu' },
  { label: 'Espeja', value: 'Espeja' },
  { label: 'Espeja De San Marcelino', value: 'Espeja De San Marcelino' },
  { label: 'Espejo', value: 'Espejo' },
  { label: 'Espejón', value: 'Espejón' },
  { label: 'Espeland', value: 'Espeland' },
  { label: 'Espelette', value: 'Espelette' },
  { label: 'Espelkamp', value: 'Espelkamp' },
  { label: 'Espelúy', value: 'Espelúy' },
  { label: 'Espenau', value: 'Espenau' },
  { label: 'Espenhain', value: 'Espenhain' },
  { label: 'Espera', value: 'Espera' },
  { label: 'Espera Feliz', value: 'Espera Feliz' },
  { label: 'Esperalvillo', value: 'Esperalvillo' },
  { label: 'Esperança', value: 'Esperança' },
  { label: 'Esperança Do Sul', value: 'Esperança Do Sul' },
  { label: 'Esperança Nova', value: 'Esperança Nova' },
  { label: 'Esperance', value: 'Esperance' },
  { label: 'Esperance', value: 'Esperance' },
  { label: 'Esperance', value: 'Esperance' },
  { label: 'Esperance', value: 'Esperance' },
  { label: 'Esperance', value: 'Esperance' },
  { label: 'Esperance Shire', value: 'Esperance Shire' },
  { label: 'Espérance Trébuchet', value: 'Espérance Trébuchet' },
  { label: 'Esperantina', value: 'Esperantina' },
  { label: 'Esperantina', value: 'Esperantina' },
  { label: 'Esperantinópolis', value: 'Esperantinópolis' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza', value: 'Esperanza' },
  { label: 'Esperanza East', value: 'Esperanza East' },
  { label: 'Esperanza East', value: 'Esperanza East' },
  { label: 'Espéraza', value: 'Espéraza' },
  { label: 'Espergærde', value: 'Espergærde' },
  { label: 'Esperia', value: 'Esperia' },
  { label: 'Espiel', value: 'Espiel' },
  { label: 'Espigão Alto Do Iguaçu', value: 'Espigão Alto Do Iguaçu' },
  { label: 'Espigão Doeste', value: 'Espigão Doeste' },
  { label: 'Espinal', value: 'Espinal' },
  { label: 'Espinal', value: 'Espinal' },
  { label: 'Espinar El', value: 'Espinar El' },
  { label: 'Espinelves', value: 'Espinelves' },
  { label: 'Espinho', value: 'Espinho' },
  { label: 'Espino', value: 'Espino' },
  { label: 'Espino De La Orbada', value: 'Espino De La Orbada' },
  { label: 'Espinosa', value: 'Espinosa' },
  { label: 'Espinosa De Cerrato', value: 'Espinosa De Cerrato' },
  { label: 'Espinosa De Cervera', value: 'Espinosa De Cervera' },
  { label: 'Espinosa De Henares', value: 'Espinosa De Henares' },
  { label: 'Espinosa De Los Caballeros', value: 'Espinosa De Los Caballeros' },
  { label: 'Espinosa De Los Monteros', value: 'Espinosa De Los Monteros' },
  { label: 'Espinosa De Villagonzalo', value: 'Espinosa De Villagonzalo' },
  { label: 'Espinosa Del Camino', value: 'Espinosa Del Camino' },
  { label: 'Espinoso Del Rey', value: 'Espinoso Del Rey' },
  { label: 'Espirdo', value: 'Espirdo' },
  { label: 'Espírito Santo', value: 'Espírito Santo' },
  { label: 'Espírito Santo Do Dourado', value: 'Espírito Santo Do Dourado' },
  { label: 'Espírito Santo Do Pinhal', value: 'Espírito Santo Do Pinhal' },
  { label: 'Espírito Santo Do Turvo', value: 'Espírito Santo Do Turvo' },
  { label: 'Espiritu', value: 'Espiritu' },
  { label: 'Espita', value: 'Espita' },
  { label: 'Espiye', value: 'Espiye' },
  { label: 'Esplanada', value: 'Esplanada' },
  { label: 'Esplegares', value: 'Esplegares' },
  { label: 'Espluga Calba L', value: 'Espluga Calba L' },
  { label: 'Espluga De Francolí', value: 'Espluga De Francolí' },
  { label: 'Esplugues De Llobregat', value: 'Esplugues De Llobregat' },
  { label: 'Esplús', value: 'Esplús' },
  { label: 'Espolla', value: 'Espolla' },
  { label: 'Esponellà', value: 'Esponellà' },
  { label: 'Espoo', value: 'Espoo' },
  { label: 'Esporlatu', value: 'Esporlatu' },
  { label: 'Esporles', value: 'Esporles' },
  { label: 'Esporões', value: 'Esporões' },
  { label: 'Esposende', value: 'Esposende' },
  { label: 'Espot', value: 'Espot' },
  { label: 'Espronceda', value: 'Espronceda' },
  { label: 'Espumoso', value: 'Espumoso' },
  { label: 'Espunyola L', value: 'Espunyola L' },
  { label: 'Espy', value: 'Espy' },
  { label: 'Esqueda', value: 'Esqueda' },
  { label: 'Esquéhéries', value: 'Esquéhéries' },
  { label: 'Esquel', value: 'Esquel' },
  { label: 'Esquelbecq', value: 'Esquelbecq' },
  { label: 'Esquerdes', value: 'Esquerdes' },
  { label: 'Esquías', value: 'Esquías' },
  { label: 'Esquibien', value: 'Esquibien' },
  { label: 'Esquina', value: 'Esquina' },
  { label: 'Esquipulas', value: 'Esquipulas' },
  { label: 'Esquipulas', value: 'Esquipulas' },
  { label: 'Esquipulas Del Norte', value: 'Esquipulas Del Norte' },
  { label: 'Esquipulas Palo Gordo', value: 'Esquipulas Palo Gordo' },
  { label: 'Esquivias', value: 'Esquivias' },
  { label: 'Essaouira', value: 'Essaouira' },
  { label: 'Essars', value: 'Essars' },
  { label: 'Essau', value: 'Essau' },
  { label: 'Essé', value: 'Essé' },
  { label: 'Essel', value: 'Essel' },
  { label: 'Esselbach', value: 'Esselbach' },
  { label: 'Es-Semara', value: 'Es-Semara' },
  { label: 'Essen', value: 'Essen' },
  { label: 'Essen', value: 'Essen' },
  { label: 'Essen', value: 'Essen' },
  { label: 'Essenbach', value: 'Essenbach' },
  { label: 'Essendine', value: 'Essendine' },
  { label: 'Essendon', value: 'Essendon' },
  { label: 'Essendon North', value: 'Essendon North' },
  { label: 'Essendon West', value: 'Essendon West' },
  { label: 'Essenheim', value: 'Essenheim' },
  { label: 'Essert', value: 'Essert' },
  { label: 'Essesteijn', value: 'Essesteijn' },
  { label: 'Essex', value: 'Essex' },
  { label: 'Essex', value: 'Essex' },
  { label: 'Essex', value: 'Essex' },
  { label: 'Essex', value: 'Essex' },
  { label: 'Essex County', value: 'Essex County' },
  { label: 'Essex County', value: 'Essex County' },
  { label: 'Essex County', value: 'Essex County' },
  { label: 'Essex County', value: 'Essex County' },
  { label: 'Essex County', value: 'Essex County' },
  { label: 'Essex Fells', value: 'Essex Fells' },
  { label: 'Essex Junction', value: 'Essex Junction' },
  { label: 'Essex Village', value: 'Essex Village' },
  { label: 'Essexville', value: 'Essexville' },
  { label: 'Essey-Lès-Nancy', value: 'Essey-Lès-Nancy' },
  { label: 'Essigny-Le-Grand', value: 'Essigny-Le-Grand' },
  { label: 'Essing', value: 'Essing' },
  { label: 'Essingen', value: 'Essingen' },
  { label: 'Essingen', value: 'Essingen' },
  { label: 'Essington', value: 'Essington' },
  { label: 'Esslingen', value: 'Esslingen' },
  { label: 'Esslingen', value: 'Esslingen' },
  { label: 'Esso', value: 'Esso' },
  { label: 'Essômes-Sur-Marne', value: 'Essômes-Sur-Marne' },
  { label: 'Essoyla', value: 'Essoyla' },
  { label: 'Establés', value: 'Establés' },
  { label: 'Estaca', value: 'Estaca' },
  { label: 'Estacada', value: 'Estacada' },
  { label: 'Estação', value: 'Estação' },
  { label: 'Estacion', value: 'Estacion' },
  { label: 'Estacion Bamoa', value: 'Estacion Bamoa' },
  { label: 'Estación Camacho', value: 'Estación Camacho' },
  { label: 'Estación Capomas', value: 'Estación Capomas' },
  { label: 'Estación Central', value: 'Estación Central' },
  { label: 'Estación Chimaneco', value: 'Estación Chimaneco' },
  { label: 'Estación Coahuila', value: 'Estación Coahuila' },
  { label: 'Estación Conchos', value: 'Estación Conchos' },
  { label: 'Estación Consuelo', value: 'Estación Consuelo' },
  { label: 'Estación Corral', value: 'Estación Corral' },
  { label: 'Estación Corralejo', value: 'Estación Corralejo' },
  { label: 'Estación De Apulco', value: 'Estación De Apulco' },
  { label: 'Estación De San Francisco', value: 'Estación De San Francisco' },
  { label: 'Estación Dobladero', value: 'Estación Dobladero' },
  { label: 'Estación Huehuetán', value: 'Estación Huehuetán' },
  { label: 'Estación Joaquín', value: 'Estación Joaquín' },
  { label: 'Estación Juanita', value: 'Estación Juanita' },
  { label: 'Estación La Piedad', value: 'Estación La Piedad' },
  { label: 'Estación Llano', value: 'Estación Llano' },
  { label: 'Estación Mogoñé', value: 'Estación Mogoñé' },
  { label: 'Estación Obispo', value: 'Estación Obispo' },
  { label: 'Estación Pénjamo', value: 'Estación Pénjamo' },
  { label: 'Estación Pescaderos', value: 'Estación Pescaderos' },
  { label: 'Estación Porvenir', value: 'Estación Porvenir' },
  { label: 'Estación Queréndaro', value: 'Estación Queréndaro' },
  { label: 'Estación Rosales', value: 'Estación Rosales' },
  { label: 'Estación San José', value: 'Estación San José' },
  { label: 'Estación San Manuel', value: 'Estación San Manuel' },
  { label: 'Estación Santa Engracia', value: 'Estación Santa Engracia' },
  { label: 'Estación Tamuín', value: 'Estación Tamuín' },
  { label: 'Estación Tuzantán', value: 'Estación Tuzantán' },
  { label: 'Estación Zamora', value: 'Estación Zamora' },
  { label: 'Estada', value: 'Estada' },
  { label: 'Estadilla', value: 'Estadilla' },
  { label: 'Estados Unidos', value: 'Estados Unidos' },
  { label: 'Estagel', value: 'Estagel' },
  { label: 'Estahban', value: 'Estahban' },
  { label: 'Estaimpuis', value: 'Estaimpuis' },
  { label: 'Estaires', value: 'Estaires' },
  { label: 'Estamariu', value: 'Estamariu' },
  { label: 'Estancia', value: 'Estancia' },
  { label: 'Estancia', value: 'Estancia' },
  { label: 'Estancia', value: 'Estancia' },
  { label: 'Estância', value: 'Estância' },
  { label: 'Estancia De Ánimas', value: 'Estancia De Ánimas' },
  { label: 'Estancia De Guadalupe', value: 'Estancia De Guadalupe' },
  { label: 'Estancia De San Nicolás', value: 'Estancia De San Nicolás' },
  { label: 'Estancia Del Llano', value: 'Estancia Del Llano' },
  { label: 'Estância Velha', value: 'Estância Velha' },
  { label: 'Estancia Vieja De Arriba', value: 'Estancia Vieja De Arriba' },
  { label: 'Estancias De Florida', value: 'Estancias De Florida' },
  { label: 'Estanislao Del Campo', value: 'Estanislao Del Campo' },
  { label: 'Estany L', value: 'Estany L' },
  { label: 'Estanzuela', value: 'Estanzuela' },
  { label: 'Estanzuela', value: 'Estanzuela' },
  { label: 'Estanzuela', value: 'Estanzuela' },
  { label: 'Estanzuela De Romero', value: 'Estanzuela De Romero' },
  { label: 'Estapilla', value: 'Estapilla' },
  { label: 'Estaràs', value: 'Estaràs' },
  { label: 'Estarreja', value: 'Estarreja' },
  { label: 'Estavayer-Le-Lac', value: 'Estavayer-Le-Lac' },
  { label: 'Este', value: 'Este' },
  { label: 'Estebanía', value: 'Estebanía' },
  { label: 'Estefania', value: 'Estefania' },
  { label: 'Estefania', value: 'Estefania' },
  { label: 'Esteio', value: 'Esteio' },
  { label: 'Estela', value: 'Estela' },
  { label: 'Estelí', value: 'Estelí' },
  { label: 'Estell Manor', value: 'Estell Manor' },
  { label: 'Estella', value: 'Estella' },
  { label: 'Estella-Lizarra', value: 'Estella-Lizarra' },
  { label: 'Estelle', value: 'Estelle' },
  { label: 'Estellencs', value: 'Estellencs' },
  { label: 'Estelnic', value: 'Estelnic' },
  { label: 'Estenfeld', value: 'Estenfeld' },
  { label: 'Estepa', value: 'Estepa' },
  { label: 'Estepa De San Juan', value: 'Estepa De San Juan' },
  { label: 'Estépar', value: 'Estépar' },
  { label: 'Estepona', value: 'Estepona' },
  { label: 'Ester', value: 'Ester' },
  { label: 'Estercuel', value: 'Estercuel' },
  { label: 'Esterhazy', value: 'Esterhazy' },
  { label: 'Esteribar', value: 'Esteribar' },
  { label: 'Esternay', value: 'Esternay' },
  { label: 'Estero', value: 'Estero' },
  { label: 'Estero De Milpas', value: 'Estero De Milpas' },
  { label: 'Estero Del Ídolo', value: 'Estero Del Ídolo' },
  { label: 'Estero Hondo', value: 'Estero Hondo' },
  { label: 'Esteros', value: 'Esteros' },
  { label: 'Esterri Dàneu', value: 'Esterri Dàneu' },
  { label: 'Esterri De Cardós', value: 'Esterri De Cardós' },
  { label: 'Esterwegen', value: 'Esterwegen' },
  { label: 'Esterzili', value: 'Esterzili' },
  { label: 'Estes Park', value: 'Estes Park' },
  { label: 'Estevan', value: 'Estevan' },
  { label: 'Estevelles', value: 'Estevelles' },
  { label: 'Esthal', value: 'Esthal' },
  { label: 'Esther', value: 'Esther' },
  { label: 'Estherville', value: 'Estherville' },
  { label: 'Estill', value: 'Estill' },
  { label: 'Estill County', value: 'Estill County' },
  { label: 'Estill Springs', value: 'Estill Springs' },
  { label: 'Estillac', value: 'Estillac' },
  { label: 'Estinnes-Au-Val', value: 'Estinnes-Au-Val' },
  { label: 'Estipa', value: 'Estipa' },
  { label: 'Estipona', value: 'Estipona' },
  { label: 'Estipona', value: 'Estipona' },
  { label: 'Estissac', value: 'Estissac' },
  { label: 'Estiva', value: 'Estiva' },
  { label: 'Estiva Gerbi', value: 'Estiva Gerbi' },
  { label: 'Estivareilles', value: 'Estivareilles' },
  { label: 'Estivella', value: 'Estivella' },
  { label: 'Estói', value: 'Estói' },
  { label: 'Estollo', value: 'Estollo' },
  { label: 'Estômbar', value: 'Estômbar' },
  { label: 'Estopiñán Del Castillo', value: 'Estopiñán Del Castillo' },
  { label: 'Estorf', value: 'Estorf' },
  { label: 'Estoril', value: 'Estoril' },
  { label: 'Estoril', value: 'Estoril' },
  { label: 'Estrablin', value: 'Estrablin' },
  { label: 'Estrada', value: 'Estrada' },
  { label: 'Estrada A', value: 'Estrada A' },
  { label: 'Estrées', value: 'Estrées' },
  { label: 'Estrées-Saint-Denis', value: 'Estrées-Saint-Denis' },
  { label: 'Estreito', value: 'Estreito' },
  { label: 'Estreito Da Calheta', value: 'Estreito Da Calheta' },
  { label: 'Estrela', value: 'Estrela' },
  { label: 'Estrela Dalva', value: 'Estrela Dalva' },
  { label: 'Estrela De Alagoas', value: 'Estrela De Alagoas' },
  { label: 'Estrela Do Indaiá', value: 'Estrela Do Indaiá' },
  { label: 'Estrela Do Norte', value: 'Estrela Do Norte' },
  { label: 'Estrela Do Norte', value: 'Estrela Do Norte' },
  { label: 'Estrela Do Sul', value: 'Estrela Do Sul' },
  { label: 'Estrela Doeste', value: 'Estrela Doeste' },
  { label: 'Estrela Velha', value: 'Estrela Velha' },
  { label: 'Estrella', value: 'Estrella' },
  { label: 'Estrella', value: 'Estrella' },
  { label: 'Estrella', value: 'Estrella' },
  { label: 'Estrella De Belén', value: 'Estrella De Belén' },
  { label: 'Estrella La', value: 'Estrella La' },
  { label: 'Estremera', value: 'Estremera' },
  { label: 'Estremoz', value: 'Estremoz' },
  { label: 'Estriégana', value: 'Estriégana' },
  { label: 'Estubeny', value: 'Estubeny' },
  { label: 'Esuk Oron', value: 'Esuk Oron' },
  { label: 'Esvres', value: 'Esvres' },
  { label: 'Esztergom', value: 'Esztergom' },
  { label: 'Eṭ Ṭaiyiba', value: 'Eṭ Ṭaiyiba' },
  { label: 'Étables-Sur-Mer', value: 'Étables-Sur-Mer' },
  { label: 'Étagnac', value: 'Étagnac' },
  { label: 'Etah', value: 'Etah' },
  { label: 'Étain', value: 'Étain' },
  { label: 'Étainhus', value: 'Étainhus' },
  { label: 'Etajima-Shi', value: 'Etajima-Shi' },
  { label: 'Étalans', value: 'Étalans' },
  { label: 'Étalle', value: 'Étalle' },
  { label: 'Étampes', value: 'Étampes' },
  { label: 'Étampes-Sur-Marne', value: 'Étampes-Sur-Marne' },
  { label: 'Etangs', value: 'Etangs' },
  { label: 'Étang-Sur-Arroux', value: 'Étang-Sur-Arroux' },
  { label: 'Étaples', value: 'Étaples' },
  { label: 'Étaules', value: 'Étaules' },
  { label: 'Étauliers', value: 'Étauliers' },
  { label: 'Etaux', value: 'Etaux' },
  { label: 'Etawa', value: 'Etawa' },
  { label: 'Etawah', value: 'Etawah' },
  { label: 'Etayo', value: 'Etayo' },
  { label: 'Etchojoa', value: 'Etchojoa' },
  { label: 'Etchoropo', value: 'Etchoropo' },
  { label: 'Ete', value: 'Ete' },
  { label: 'Ete', value: 'Ete' },
  { label: 'Étel', value: 'Étel' },
  { label: 'Eten', value: 'Eten' },
  { label: 'Etersheim', value: 'Etersheim' },
  { label: 'Éterville', value: 'Éterville' },
  { label: 'Ethekwini Metropolitan Municipality', value: 'Ethekwini Metropolitan Municipality' },
  { label: 'Ethelton', value: 'Ethelton' },
  { label: 'Etheridge', value: 'Etheridge' },
  { label: 'Ethete', value: 'Ethete' },
  { label: 'Etikoppaka', value: 'Etikoppaka' },
  { label: 'Etimesgut', value: 'Etimesgut' },
  { label: 'Étiolles', value: 'Étiolles' },
  { label: 'Eti-Osa', value: 'Eti-Osa' },
  { label: 'Étival-Clairefontaine', value: 'Étival-Clairefontaine' },
  { label: 'Étival-Lès-Le-Mans', value: 'Étival-Lès-Le-Mans' },
  { label: 'Etmadpur', value: 'Etmadpur' },
  { label: 'Etna', value: 'Etna' },
  { label: 'Etna', value: 'Etna' },
  { label: 'Etna', value: 'Etna' },
  { label: 'Etne', value: 'Etne' },
  { label: 'Etnedal', value: 'Etnedal' },
  { label: 'Etobicoke', value: 'Etobicoke' },
  { label: 'Étoile-Sur-Rhône', value: 'Étoile-Sur-Rhône' },
  { label: 'Etoka', value: 'Etoka' },
  { label: 'Eton', value: 'Eton' },
  { label: 'Eton Wick', value: 'Eton Wick' },
  { label: 'Etowah', value: 'Etowah' },
  { label: 'Etowah', value: 'Etowah' },
  { label: 'Etowah County', value: 'Etowah County' },
  { label: 'Étréchy', value: 'Étréchy' },
  { label: 'Étreillers', value: 'Étreillers' },
  { label: 'Étrelles', value: 'Étrelles' },
  { label: 'Étrembières', value: 'Étrembières' },
  { label: 'Étrépagny', value: 'Étrépagny' },
  { label: 'Étretat', value: 'Étretat' },
  { label: 'Étreux', value: 'Étreux' },
  { label: 'Étriché', value: 'Étriché' },
  { label: 'Etropole', value: 'Etropole' },
  { label: 'Etroubles', value: 'Etroubles' },
  { label: 'Etruria', value: 'Etruria' },
  { label: 'Ettadhamen-Mnihla', value: 'Ettadhamen-Mnihla' },
  { label: 'Ettaiyapuram', value: 'Ettaiyapuram' },
  { label: 'Ettal Municipality', value: 'Ettal Municipality' },
  { label: 'Ettalong', value: 'Ettalong' },
  { label: 'Ettalong Beach', value: 'Ettalong Beach' },
  { label: 'Ettelbruck', value: 'Ettelbruck' },
  { label: 'Etten', value: 'Etten' },
  { label: 'Ettenheim', value: 'Ettenheim' },
  { label: 'Ettingen', value: 'Ettingen' },
  { label: 'Ettiswil', value: 'Ettiswil' },
  { label: 'Ettlingen', value: 'Ettlingen' },
  { label: 'Etton', value: 'Etton' },
  { label: 'Ettrick', value: 'Ettrick' },
  { label: 'Ettringen', value: 'Ettringen' },
  { label: 'Ettringen', value: 'Ettringen' },
  { label: 'Etúcuaro', value: 'Etúcuaro' },
  { label: 'Etúcuaro', value: 'Etúcuaro' },
  { label: 'Étupes', value: 'Étupes' },
  { label: 'Etwall', value: 'Etwall' },
  { label: 'Etxalar', value: 'Etxalar' },
  { label: 'Etxarri-Aranatz', value: 'Etxarri-Aranatz' },
  { label: 'Etxauri', value: 'Etxauri' },
  { label: 'Etxebarri', value: 'Etxebarri' },
  { label: 'Etxebarria', value: 'Etxebarria' },
  { label: 'Etyek', value: 'Etyek' },
  { label: 'Etzatlán Municipality', value: 'Etzatlán Municipality' },
  { label: 'Etzbach', value: 'Etzbach' },
  { label: 'Etzelwang', value: 'Etzelwang' },
  { label: 'Etzenricht', value: 'Etzenricht' },
  { label: 'Etzling', value: 'Etzling' },
  { label: 'Eu', value: 'Eu' },
  { label: 'Euan', value: 'Euan' },
  { label: 'Eucaliptus', value: 'Eucaliptus' },
  { label: 'Eucalyptus Hills', value: 'Eucalyptus Hills' },
  { label: 'Euclid', value: 'Euclid' },
  { label: 'Euclides Da Cunha', value: 'Euclides Da Cunha' },
  { label: 'Euclides Da Cunha Paulista', value: 'Euclides Da Cunha Paulista' },
  { label: 'Eudlo', value: 'Eudlo' },
  { label: 'Eudora', value: 'Eudora' },
  { label: 'Eudora', value: 'Eudora' },
  { label: 'Euerbach', value: 'Euerbach' },
  { label: 'Euerdorf', value: 'Euerdorf' },
  { label: 'Eufaula', value: 'Eufaula' },
  { label: 'Eufaula', value: 'Eufaula' },
  { label: 'Eugendorf', value: 'Eugendorf' },
  { label: 'Eugene', value: 'Eugene' },
  { label: 'Eugênio De Castro', value: 'Eugênio De Castro' },
  { label: 'Eugenópolis', value: 'Eugenópolis' },
  { label: 'Euharlee', value: 'Euharlee' },
  { label: 'Eulate', value: 'Eulate' },
  { label: 'Euless', value: 'Euless' },
  { label: 'Eulmont', value: 'Eulmont' },
  { label: 'Eulomogo', value: 'Eulomogo' },
  { label: 'Eumemmerring', value: 'Eumemmerring' },
  { label: 'Eumseong-Gun', value: 'Eumseong-Gun' },
  { label: 'Eumundi', value: 'Eumundi' },
  { label: 'Eunápolis', value: 'Eunápolis' },
  { label: 'Eunice', value: 'Eunice' },
  { label: 'Eunice', value: 'Eunice' },
  { label: 'Eunpyeong-Gu', value: 'Eunpyeong-Gu' },
  { label: 'Eupen', value: 'Eupen' },
  { label: 'Eupora', value: 'Eupora' },
  { label: 'Eura', value: 'Eura' },
  { label: 'Eurajoki', value: 'Eurajoki' },
  { label: 'Eurasburg', value: 'Eurasburg' },
  { label: 'Euratsfeld', value: 'Euratsfeld' },
  { label: 'Eureka', value: 'Eureka' },
  { label: 'Eureka', value: 'Eureka' },
  { label: 'Eureka', value: 'Eureka' },
  { label: 'Eureka', value: 'Eureka' },
  { label: 'Eureka', value: 'Eureka' },
  { label: 'Eureka', value: 'Eureka' },
  { label: 'Eureka County', value: 'Eureka County' },
  { label: 'Eureka De Media Luna (Eureka)', value: 'Eureka De Media Luna (Eureka)' },
  { label: 'Eureka Mill', value: 'Eureka Mill' },
  { label: 'Eureka Springs', value: 'Eureka Springs' },
  { label: 'Eureka Y Belén', value: 'Eureka Y Belén' },
  { label: 'Euroa', value: 'Euroa' },
  { label: 'Eurobodalla', value: 'Eurobodalla' },
  { label: 'Eurre', value: 'Eurre' },
  { label: 'Eurville-Bienville', value: 'Eurville-Bienville' },
  { label: 'Eusébio', value: 'Eusébio' },
  { label: 'Eusebio Ayala', value: 'Eusebio Ayala' },
  { label: 'Euskirchen', value: 'Euskirchen' },
  { label: 'Eußenheim', value: 'Eußenheim' },
  { label: 'Eustaquio Lopez', value: 'Eustaquio Lopez' },
  { label: 'Eustis', value: 'Eustis' },
  { label: 'Eutaw', value: 'Eutaw' },
  { label: 'Eutin', value: 'Eutin' },
  { label: 'Eutingen An Der Enz', value: 'Eutingen An Der Enz' },
  { label: 'Euville', value: 'Euville' },
  { label: 'Euxton', value: 'Euxton' },
  { label: 'Evadale', value: 'Evadale' },
  { label: 'Evandale', value: 'Evandale' },
  { label: 'Evandale', value: 'Evandale' },
  { label: 'Evangeline Parish', value: 'Evangeline Parish' },
  { label: 'Evangelista', value: 'Evangelista' },
  { label: 'Evangelisti-Rubino', value: 'Evangelisti-Rubino' },
  { label: 'Evans', value: 'Evans' },
  { label: 'Evans', value: 'Evans' },
  { label: 'Evans City', value: 'Evans City' },
  { label: 'Evans County', value: 'Evans County' },
  { label: 'Evans Head', value: 'Evans Head' },
  { label: 'Evansburg', value: 'Evansburg' },
  { label: 'Evansdale', value: 'Evansdale' },
  { label: 'Evanston', value: 'Evanston' },
  { label: 'Evanston', value: 'Evanston' },
  { label: 'Evanston', value: 'Evanston' },
  { label: 'Evanston Gardens', value: 'Evanston Gardens' },
  { label: 'Evanston Park', value: 'Evanston Park' },
  { label: 'Evansville', value: 'Evansville' },
  { label: 'Evansville', value: 'Evansville' },
  { label: 'Evansville', value: 'Evansville' },
  { label: 'Evanton', value: 'Evanton' },
  { label: 'Evart', value: 'Evart' },
  { label: 'Evatt', value: 'Evatt' },
  { label: 'Évaux-Les-Bains', value: 'Évaux-Les-Bains' },
  { label: 'Evaz', value: 'Evaz' },
  { label: 'Evciler', value: 'Evciler' },
  { label: 'Eveleth', value: 'Eveleth' },
  { label: 'Even Yehuda', value: 'Even Yehuda' },
  { label: 'Evendale', value: 'Evendale' },
  { label: 'Evenes', value: 'Evenes' },
  { label: 'Evenkiyskiy District', value: 'Evenkiyskiy District' },
  { label: 'Eveno-Bytantaysky National District', value: 'Eveno-Bytantaysky National District' },
  { label: 'Évenos', value: 'Évenos' },
  { label: 'Evensk', value: 'Evensk' },
  { label: 'Evenskjer', value: 'Evenskjer' },
  { label: 'Evenwood', value: 'Evenwood' },
  { label: 'Everard Park', value: 'Everard Park' },
  { label: 'Evercreech', value: 'Evercreech' },
  { label: 'Everett', value: 'Everett' },
  { label: 'Everett', value: 'Everett' },
  { label: 'Everett', value: 'Everett' },
  { label: 'Evergem', value: 'Evergem' },
  { label: 'Evergreen', value: 'Evergreen' },
  { label: 'Evergreen', value: 'Evergreen' },
  { label: 'Evergreen', value: 'Evergreen' },
  { label: 'Evergreen', value: 'Evergreen' },
  { label: 'Evergreen', value: 'Evergreen' },
  { label: 'Evergreen Park', value: 'Evergreen Park' },
  { label: 'Everman', value: 'Everman' },
  { label: 'Eversholt', value: 'Eversholt' },
  { label: 'Eversley', value: 'Eversley' },
  { label: 'Everson', value: 'Everson' },
  { label: 'Everswinkel', value: 'Everswinkel' },
  { label: 'Everton', value: 'Everton' },
  { label: 'Everton Hills', value: 'Everton Hills' },
  { label: 'Everton Park', value: 'Everton Park' },
  { label: 'Evesham', value: 'Evesham' },
  { label: 'Evessen', value: 'Evessen' },
  { label: 'Évian-Les-Bains', value: 'Évian-Les-Bains' },
  { label: 'Evijärvi', value: 'Evijärvi' },
  { label: 'Evilard', value: 'Evilard' },
  { label: 'Evinayong', value: 'Evinayong' },
  { label: 'Évin-Malmaison', value: 'Évin-Malmaison' },
  { label: 'Evionnaz', value: 'Evionnaz' },
  { label: 'Évires', value: 'Évires' },
  { label: 'Evjen', value: 'Evjen' },
  { label: 'Évlalo', value: 'Évlalo' },
  { label: 'Évodoula', value: 'Évodoula' },
  { label: 'Evogli', value: 'Evogli' },
  { label: 'Evolène', value: 'Evolène' },
  { label: 'Évora', value: 'Évora' },
  { label: 'Évosmos', value: 'Évosmos' },
  { label: 'Évran', value: 'Évran' },
  { label: 'Évrecy', value: 'Évrecy' },
  { label: 'Evren', value: 'Evren' },
  { label: 'Évreux', value: 'Évreux' },
  { label: 'Évron', value: 'Évron' },
  { label: 'Evropós', value: 'Evropós' },
  { label: 'Évry', value: 'Évry' },
  { label: 'Evxinoúpolis', value: 'Evxinoúpolis' },
  { label: 'Ewa Beach', value: 'Ewa Beach' },
  { label: 'Ewa Gentry', value: 'Ewa Gentry' },
  { label: 'Ewa Villages', value: 'Ewa Villages' },
  { label: 'Ewarton', value: 'Ewarton' },
  { label: 'Ewbank Da Câmara', value: 'Ewbank Da Câmara' },
  { label: 'Ewell', value: 'Ewell' },
  { label: 'Ewhurst', value: 'Ewhurst' },
  { label: 'Ewing', value: 'Ewing' },
  { label: 'Ewo', value: 'Ewo' },
  { label: 'Ewyas Harold', value: 'Ewyas Harold' },
  { label: 'Examília', value: 'Examília' },
  { label: 'Exaplátanos', value: 'Exaplátanos' },
  { label: 'Excelsior', value: 'Excelsior' },
  { label: 'Excelsior Springs', value: 'Excelsior Springs' },
  { label: 'Exchange', value: 'Exchange' },
  { label: 'Excideuil', value: 'Excideuil' },
  { label: 'Excola', value: 'Excola' },
  { label: 'Exeter', value: 'Exeter' },
  { label: 'Exeter', value: 'Exeter' },
  { label: 'Exeter', value: 'Exeter' },
  { label: 'Exeter', value: 'Exeter' },
  { label: 'Exeter', value: 'Exeter' },
  { label: 'Exeter', value: 'Exeter' },
  { label: 'Exeter', value: 'Exeter' },
  { label: 'Ex-Hacienda De Guadalupe', value: 'Ex-Hacienda De Guadalupe' },
  { label: 'Ex-Hacienda De Guadalupe', value: 'Ex-Hacienda De Guadalupe' },
  { label: 'Ex-Hacienda De Xalpa', value: 'Ex-Hacienda De Xalpa' },
  { label: 'Ex-Hacienda Del Copal', value: 'Ex-Hacienda Del Copal' },
  { label: 'Ex-Hacienda El Hospital', value: 'Ex-Hacienda El Hospital' },
  { label: 'Ex-Hacienda La Concepción', value: 'Ex-Hacienda La Concepción' },
  { label: 'Ex-Hacienda Santa Inés', value: 'Ex-Hacienda Santa Inés' },
  { label: 'Exhall', value: 'Exhall' },
  { label: 'Exideuil', value: 'Exideuil' },
  { label: 'Exilles', value: 'Exilles' },
  { label: 'Exincourt', value: 'Exincourt' },
  { label: 'Exireuil', value: 'Exireuil' },
  { label: 'Exloo', value: 'Exloo' },
  { label: 'Exminster', value: 'Exminster' },
  { label: 'Exmore', value: 'Exmore' },
  { label: 'Exmouth', value: 'Exmouth' },
  { label: 'Exmouth', value: 'Exmouth' },
  { label: 'Exning', value: 'Exning' },
  { label: 'Experiment', value: 'Experiment' },
  { label: 'Ex-Rancho San Dimas', value: 'Ex-Rancho San Dimas' },
  { label: 'Exton', value: 'Exton' },
  { label: 'Exton', value: 'Exton' },
  { label: 'Extrema', value: 'Extrema' },
  { label: 'Extrema', value: 'Extrema' },
  { label: 'Extremoz', value: 'Extremoz' },
  { label: 'Exu', value: 'Exu' },
  { label: 'Ex-Viñedos Guadalupe', value: 'Ex-Viñedos Guadalupe' },
  { label: 'Eybens', value: 'Eybens' },
  { label: 'Eydelstedt', value: 'Eydelstedt' },
  { label: 'Eye', value: 'Eye' },
  { label: 'Eyemouth', value: 'Eyemouth' },
  { label: 'Eyendorf', value: 'Eyendorf' },
  { label: 'Eygalières', value: 'Eygalières' },
  { label: 'Eyguières', value: 'Eyguières' },
  { label: 'Eyja- Og Miklaholtshreppur', value: 'Eyja- Og Miklaholtshreppur' },
  { label: 'Eyjafjarðarsveit', value: 'Eyjafjarðarsveit' },
  { label: 'Eyl', value: 'Eyl' },
  { label: 'Eymet', value: 'Eymet' },
  { label: 'Eymoutiers', value: 'Eymoutiers' },
  { label: 'Eynesbury', value: 'Eynesbury' },
  { label: 'Eynesil', value: 'Eynesil' },
  { label: 'Eynsford', value: 'Eynsford' },
  { label: 'Eynsham', value: 'Eynsham' },
  { label: 'Eyota', value: 'Eyota' },
  { label: 'Eyragues', value: 'Eyragues' },
  { label: 'Eysines', value: 'Eysines' },
  { label: 'Eystrup', value: 'Eystrup' },
  { label: 'Eystur', value: 'Eystur' },
  { label: 'Eythorne', value: 'Eythorne' },
  { label: 'Eyüpsultan', value: 'Eyüpsultan' },
  { label: 'Eyvan', value: 'Eyvan' },
  { label: 'Eyvanekey', value: 'Eyvanekey' },
  { label: 'Eyvigues-Et-Eybènes', value: 'Eyvigues-Et-Eybènes' },
  { label: 'Eyyübiye', value: 'Eyyübiye' },
  { label: 'Eyzin-Pinet', value: 'Eyzin-Pinet' },
  { label: 'Ézanville', value: 'Ézanville' },
  { label: 'Ezcabarte', value: 'Ezcabarte' },
  { label: 'Ezcaray', value: 'Ezcaray' },
  { label: 'Ezcároz/Ezkaroze', value: 'Ezcároz/Ezkaroze' },
  { label: 'Èze', value: 'Èze' },
  { label: 'Ezequiel Montes', value: 'Ezequiel Montes' },
  { label: 'Ežerėlis', value: 'Ežerėlis' },
  { label: 'Ezeriş', value: 'Ezeriş' },
  { label: 'Ezgeleh', value: 'Ezgeleh' },
  { label: 'Ezhieh', value: 'Ezhieh' },
  { label: 'Ezhou', value: 'Ezhou' },
  { label: 'Ezhou Shi', value: 'Ezhou Shi' },
  { label: 'Ezhva', value: 'Ezhva' },
  { label: 'Ezine', value: 'Ezine' },
  { label: 'Ezkio-Itsaso', value: 'Ezkio-Itsaso' },
  { label: 'Ezkurra', value: 'Ezkurra' },
  { label: 'Ezprogui', value: 'Ezprogui' },
  { label: 'Ézy-Sur-Eure', value: 'Ézy-Sur-Eure' },
  { label: 'Ezza-Ohu', value: 'Ezza-Ohu' },
  { label: 'Faaborg', value: 'Faaborg' },
  { label: 'Faaborg-Midtfyn Kommune', value: 'Faaborg-Midtfyn Kommune' },
  { label: 'Faak Am See', value: 'Faak Am See' },
  { label: 'Fabara', value: 'Fabara' },
  { label: 'Fabbriche Di Vallico', value: 'Fabbriche Di Vallico' },
  { label: 'Fabbrico', value: 'Fabbrico' },
  { label: 'Fabens', value: 'Fabens' },
  { label: 'Fabero', value: 'Fabero' },
  { label: 'Fabianki', value: 'Fabianki' },
  { label: 'Fábiánsebestyén', value: 'Fábiánsebestyén' },
  { label: 'Fabijoniškės', value: 'Fabijoniškės' },
  { label: 'Fabrègues', value: 'Fabrègues' },
  { label: 'Fabrezan', value: 'Fabrezan' },
  { label: 'Fabriano', value: 'Fabriano' },
  { label: 'Fabrica', value: 'Fabrica' },
  { label: 'Fábrica Concepción', value: 'Fábrica Concepción' },
  { label: 'Fábrica De Melchor', value: 'Fábrica De Melchor' },
  { label: 'Fabrica Di Roma', value: 'Fabrica Di Roma' },
  { label: 'Fabrizia', value: 'Fabrizia' },
  { label: 'Fabrizio', value: 'Fabrizio' },
  { label: 'Fabro', value: 'Fabro' },
  { label: 'Fabro Scalo', value: 'Fabro Scalo' },
  { label: 'Făcăeni', value: 'Făcăeni' },
  { label: 'Făcăi', value: 'Făcăi' },
  { label: 'Facatativá', value: 'Facatativá' },
  { label: 'Fachbach', value: 'Fachbach' },
  { label: 'Facheca', value: 'Facheca' },
  { label: 'Faches-Thumesnil', value: 'Faches-Thumesnil' },
  { label: 'Făclia', value: 'Făclia' },
  { label: 'Factoryville', value: 'Factoryville' },
  { label: 'Fada', value: 'Fada' },
  { label: 'Fada Ngourma', value: 'Fada Ngourma' },
  { label: 'Fadami ', value: 'Fadami ' },
  { label: 'Fadd', value: 'Fadd' },
  { label: 'Fadden', value: 'Fadden' },
  { label: 'Faedis', value: 'Faedis' },
  { label: 'Faedo', value: 'Faedo' },
  { label: 'Faedo Valtellino', value: 'Faedo Valtellino' },
  { label: 'Faella', value: 'Faella' },
  { label: 'Faenza', value: 'Faenza' },
  { label: 'Færder', value: 'Færder' },
  { label: 'Faetano', value: 'Faetano' },
  { label: 'Faeto', value: 'Faeto' },
  { label: 'Fafe', value: 'Fafe' },
  { label: 'Fagagna', value: 'Fagagna' },
  { label: 'Făgăraș', value: 'Făgăraș' },
  { label: 'Fagernes', value: 'Fagernes' },
  { label: 'Fagersta', value: 'Fagersta' },
  { label: 'Fagerstrand', value: 'Fagerstrand' },
  { label: 'Făget', value: 'Făget' },
  { label: 'Făget', value: 'Făget' },
  { label: 'Făgeţelu', value: 'Făgeţelu' },
  { label: 'Făgetu', value: 'Făgetu' },
  { label: 'Făgetu', value: 'Făgetu' },
  { label: 'Faggeto Lario', value: 'Faggeto Lario' },
  { label: 'Faggiano', value: 'Faggiano' },
  { label: 'Fagnano Castello', value: 'Fagnano Castello' },
  { label: 'Fagnano Olona-Bergoro', value: 'Fagnano Olona-Bergoro' },
  { label: 'Fagnières', value: 'Fagnières' },
  { label: 'Fago', value: 'Fago' },
  { label: 'Fagundes', value: 'Fagundes' },
  { label: 'Fagundes Varela', value: 'Fagundes Varela' },
  { label: 'Fahraj', value: 'Fahraj' },
  { label: 'Fahrdorf', value: 'Fahrdorf' },
  { label: 'Fahrenbach', value: 'Fahrenbach' },
  { label: 'Fahrenkrug', value: 'Fahrenkrug' },
  { label: 'Fahrenzhausen', value: 'Fahrenzhausen' },
  { label: 'Fahrumand', value: 'Fahrumand' },
  { label: 'Fahs-Anjra', value: 'Fahs-Anjra' },
  { label: 'Fai Della Paganella', value: 'Fai Della Paganella' },
  { label: 'Faia', value: 'Faia' },
  { label: 'Faial', value: 'Faial' },
  { label: 'Faiano', value: 'Faiano' },
  { label: 'Faicchio', value: 'Faicchio' },
  { label: 'Faid', value: 'Faid' },
  { label: 'Faido', value: 'Faido' },
  { label: 'Faifley', value: 'Faifley' },
  { label: 'Failsworth', value: 'Failsworth' },
  { label: 'Faimes', value: 'Faimes' },
  { label: 'Faina', value: 'Faina' },
  { label: 'Fains-Véel', value: 'Fains-Véel' },
  { label: 'Fair Grove', value: 'Fair Grove' },
  { label: 'Fair Haven', value: 'Fair Haven' },
  { label: 'Fair Haven', value: 'Fair Haven' },
  { label: 'Fair Lawn', value: 'Fair Lawn' },
  { label: 'Fair Oaks', value: 'Fair Oaks' },
  { label: 'Fair Oaks', value: 'Fair Oaks' },
  { label: 'Fair Oaks Ranch', value: 'Fair Oaks Ranch' },
  { label: 'Fair Plain', value: 'Fair Plain' },
  { label: 'Fairbank', value: 'Fairbank' },
  { label: 'Fairbanks', value: 'Fairbanks' },
  { label: 'Fairbanks North Star Borough', value: 'Fairbanks North Star Borough' },
  { label: 'Fairbanks Ranch', value: 'Fairbanks Ranch' },
  { label: 'Fairborn', value: 'Fairborn' },
  { label: 'Fairburn', value: 'Fairburn' },
  { label: 'Fairbury', value: 'Fairbury' },
  { label: 'Fairbury', value: 'Fairbury' },
  { label: 'Fairchance', value: 'Fairchance' },
  { label: 'Fairchild Air Force Base', value: 'Fairchild Air Force Base' },
  { label: 'Fairchilds', value: 'Fairchilds' },
  { label: 'Fairdale', value: 'Fairdale' },
  { label: 'Fairdale', value: 'Fairdale' },
  { label: 'Fairfax', value: 'Fairfax' },
  { label: 'Fairfax', value: 'Fairfax' },
  { label: 'Fairfax', value: 'Fairfax' },
  { label: 'Fairfax', value: 'Fairfax' },
  { label: 'Fairfax', value: 'Fairfax' },
  { label: 'Fairfax', value: 'Fairfax' },
  { label: 'Fairfax', value: 'Fairfax' },
  { label: 'Fairfax County', value: 'Fairfax County' },
  { label: 'Fairfax Station', value: 'Fairfax Station' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Fairfield Bay', value: 'Fairfield Bay' },
  { label: 'Fairfield Beach', value: 'Fairfield Beach' },
  { label: 'Fairfield County', value: 'Fairfield County' },
  { label: 'Fairfield County', value: 'Fairfield County' },
  { label: 'Fairfield County', value: 'Fairfield County' },
  { label: 'Fairfield East', value: 'Fairfield East' },
  { label: 'Fairfield Glade', value: 'Fairfield Glade' },
  { label: 'Fairfield Harbour', value: 'Fairfield Harbour' },
  { label: 'Fairfield Heights', value: 'Fairfield Heights' },
  { label: 'Fairfield Heights', value: 'Fairfield Heights' },
  { label: 'Fairfield West', value: 'Fairfield West' },
  { label: 'Fairford', value: 'Fairford' },
  { label: 'Fairforest', value: 'Fairforest' },
  { label: 'Fairhaven', value: 'Fairhaven' },
  { label: 'Fairhope', value: 'Fairhope' },
  { label: 'Fairhope', value: 'Fairhope' },
  { label: 'Fairland', value: 'Fairland' },
  { label: 'Fairland', value: 'Fairland' },
  { label: 'Fairlands', value: 'Fairlands' },
  { label: 'Fairlawn', value: 'Fairlawn' },
  { label: 'Fairlawn', value: 'Fairlawn' },
  { label: 'Fairlea', value: 'Fairlea' },
  { label: 'Fairless Hills', value: 'Fairless Hills' },
  { label: 'Fairlie', value: 'Fairlie' },
  { label: 'Fairlight', value: 'Fairlight' },
  { label: 'Fairlight', value: 'Fairlight' },
  { label: 'Fairmead', value: 'Fairmead' },
  { label: 'Fairmont', value: 'Fairmont' },
  { label: 'Fairmont', value: 'Fairmont' },
  { label: 'Fairmont', value: 'Fairmont' },
  { label: 'Fairmont', value: 'Fairmont' },
  { label: 'Fairmont City', value: 'Fairmont City' },
  { label: 'Fairmount', value: 'Fairmount' },
  { label: 'Fairmount', value: 'Fairmount' },
  { label: 'Fairmount', value: 'Fairmount' },
  { label: 'Fairmount Heights', value: 'Fairmount Heights' },
  { label: 'Fairplains', value: 'Fairplains' },
  { label: 'Fairplay', value: 'Fairplay' },
  { label: 'Fairport', value: 'Fairport' },
  { label: 'Fairport Harbor', value: 'Fairport Harbor' },
  { label: 'Fairton', value: 'Fairton' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview', value: 'Fairview' },
  { label: 'Fairview Heights', value: 'Fairview Heights' },
  { label: 'Fairview Park', value: 'Fairview Park' },
  { label: 'Fairview Park', value: 'Fairview Park' },
  { label: 'Fairview Park', value: 'Fairview Park' },
  { label: 'Fairview Shores', value: 'Fairview Shores' },
  { label: 'Fairview-Ferndale', value: 'Fairview-Ferndale' },
  { label: 'Fairway', value: 'Fairway' },
  { label: 'Fairwinds', value: 'Fairwinds' },
  { label: 'Fairwood', value: 'Fairwood' },
  { label: 'Fairwood', value: 'Fairwood' },
  { label: 'Fairy Hill', value: 'Fairy Hill' },
  { label: 'Fairy Meadow', value: 'Fairy Meadow' },
  { label: 'Fais', value: 'Fais' },
  { label: 'Fais Municipality', value: 'Fais Municipality' },
  { label: 'Faisalabad', value: 'Faisalabad' },
  { label: 'Faistenau', value: 'Faistenau' },
  { label: 'Faiths Pen', value: 'Faiths Pen' },
  { label: 'Faizabad', value: 'Faizabad' },
  { label: 'Faizpur', value: 'Faizpur' },
  { label: 'Fajã Da Ovelha', value: 'Fajã Da Ovelha' },
  { label: 'Fajã De Baixo', value: 'Fajã De Baixo' },
  { label: 'Faja De Oro', value: 'Faja De Oro' },
  { label: 'Fajardo', value: 'Fajardo' },
  { label: 'Fajardo', value: 'Fajardo' },
  { label: 'Fajardo Municipio', value: 'Fajardo Municipio' },
  { label: 'Fajsławice', value: 'Fajsławice' },
  { label: 'Fakaifou Village', value: 'Fakaifou Village' },
  { label: 'Fakel', value: 'Fakel' },
  { label: 'Fakenham', value: 'Fakenham' },
  { label: 'Fakhrabad', value: 'Fakhrabad' },
  { label: 'Fako Division', value: 'Fako Division' },
  { label: 'Falagueira', value: 'Falagueira' },
  { label: 'Falaise', value: 'Falaise' },
  { label: 'Falakata', value: 'Falakata' },
  { label: 'Falam', value: 'Falam' },
  { label: 'Falan', value: 'Falan' },
  { label: 'Falaștoaca', value: 'Falaștoaca' },
  { label: 'Falavarjan', value: 'Falavarjan' },
  { label: 'Falcade Alto', value: 'Falcade Alto' },
  { label: 'Falcău', value: 'Falcău' },
  { label: 'Falces', value: 'Falces' },
  { label: 'Falciano Del Massico', value: 'Falciano Del Massico' },
  { label: 'Fălciu', value: 'Fălciu' },
  { label: 'Falck', value: 'Falck' },
  { label: 'Fălcoiu', value: 'Fălcoiu' },
  { label: 'Falcon', value: 'Falcon' },
  { label: 'Falcon Heights', value: 'Falcon Heights' },
  { label: 'Falcon Lake Estates', value: 'Falcon Lake Estates' },
  { label: 'Falconara Albanese', value: 'Falconara Albanese' },
  { label: 'Falconara Marittima', value: 'Falconara Marittima' },
  { label: 'Falcone', value: 'Falcone' },
  { label: 'Falconer', value: 'Falconer' },
  { label: 'Faldarlı', value: 'Faldarlı' },
  { label: 'Falefa', value: 'Falefa' },
  { label: 'Falënki', value: 'Falënki' },
  { label: 'Falënskiy Rayon', value: 'Falënskiy Rayon' },
  { label: 'Faleria', value: 'Faleria' },
  { label: 'Falerna', value: 'Falerna' },
  { label: 'Falerna Scalo', value: 'Falerna Scalo' },
  { label: 'Falerone', value: 'Falerone' },
  { label: 'Fălești', value: 'Fălești' },
  { label: 'Falfurrias', value: 'Falfurrias' },
  { label: 'Falher', value: 'Falher' },
  { label: 'Falicon', value: 'Falicon' },
  { label: 'Faliraki', value: 'Faliraki' },
  { label: 'Falkenau', value: 'Falkenau' },
  { label: 'Falkenberg', value: 'Falkenberg' },
  { label: 'Falkenberg', value: 'Falkenberg' },
  { label: 'Falkenberg', value: 'Falkenberg' },
  { label: 'Falkenberg', value: 'Falkenberg' },
  { label: 'Falkenburg', value: 'Falkenburg' },
  { label: 'Falkenfels', value: 'Falkenfels' },
  { label: 'Falkenhagener Feld', value: 'Falkenhagener Feld' },
  { label: 'Falkenhain', value: 'Falkenhain' },
  { label: 'Falkensee', value: 'Falkensee' },
  { label: 'Falkenstein', value: 'Falkenstein' },
  { label: 'Falkenstein', value: 'Falkenstein' },
  { label: 'Falkenstein', value: 'Falkenstein' },
  { label: 'Falkirk', value: 'Falkirk' },
  { label: 'Falkland', value: 'Falkland' },
  { label: 'Falköping', value: 'Falköping' },
  { label: 'Fałków', value: 'Fałków' },
  { label: 'Falkville', value: 'Falkville' },
  { label: 'Fall Branch', value: 'Fall Branch' },
  { label: 'Fall City', value: 'Fall City' },
  { label: 'Fall Creek', value: 'Fall Creek' },
  { label: 'Fall River', value: 'Fall River' },
  { label: 'Fall River', value: 'Fall River' },
  { label: 'Fall River', value: 'Fall River' },
  { label: 'Fall River County', value: 'Fall River County' },
  { label: 'Fällanden', value: 'Fällanden' },
  { label: 'Fallbach', value: 'Fallbach' },
  { label: 'Fallbrook', value: 'Fallbrook' },
  { label: 'Falleron', value: 'Falleron' },
  { label: 'Fallin', value: 'Fallin' },
  { label: 'Falling Water', value: 'Falling Water' },
  { label: 'Fallingbrook', value: 'Fallingbrook' },
  { label: 'Fallo', value: 'Fallo' },
  { label: 'Fallon', value: 'Fallon' },
  { label: 'Fallon County', value: 'Fallon County' },
  { label: 'Fallowfield', value: 'Fallowfield' },
  { label: 'Falls Church', value: 'Falls Church' },
  { label: 'Falls City', value: 'Falls City' },
  { label: 'Falls County', value: 'Falls County' },
  { label: 'Falls Creek', value: 'Falls Creek' },
  { label: 'Falls Creek', value: 'Falls Creek' },
  { label: 'Fallsburg', value: 'Fallsburg' },
  { label: 'Fallston', value: 'Fallston' },
  { label: 'Falmenta', value: 'Falmenta' },
  { label: 'Falmouth', value: 'Falmouth' },
  { label: 'Falmouth', value: 'Falmouth' },
  { label: 'Falmouth', value: 'Falmouth' },
  { label: 'Falmouth', value: 'Falmouth' },
  { label: 'Falmouth', value: 'Falmouth' },
  { label: 'Falmouth', value: 'Falmouth' },
  { label: 'Falmouth', value: 'Falmouth' },
  { label: 'Falmouth Foreside', value: 'Falmouth Foreside' },
  { label: 'Faloppio', value: 'Faloppio' },
  { label: 'Falset', value: 'Falset' },
  { label: 'Fălticeni', value: 'Fălticeni' },
  { label: 'Falun', value: 'Falun' },
  { label: 'Falvaterra', value: 'Falvaterra' },
  { label: 'Falzè Di Piave', value: 'Falzè Di Piave' },
  { label: 'Falzes', value: 'Falzes' },
  { label: 'Falzè-Signoressa', value: 'Falzè-Signoressa' },
  { label: 'Fama', value: 'Fama' },
  { label: 'Famagusta', value: 'Famagusta' },
  { label: 'Famaillá', value: 'Famaillá' },
  { label: 'Famalicão', value: 'Famalicão' },
  { label: 'Famalicão', value: 'Famalicão' },
  { label: 'Famars', value: 'Famars' },
  { label: 'Famast', value: 'Famast' },
  { label: 'Fambach', value: 'Fambach' },
  { label: 'Fameck', value: 'Fameck' },
  { label: 'Famenin', value: 'Famenin' },
  { label: 'Fámjin', value: 'Fámjin' },
  { label: 'Famões', value: 'Famões' },
  { label: 'Famões', value: 'Famões' },
  { label: 'Famorca', value: 'Famorca' },
  { label: 'Fampoux', value: 'Fampoux' },
  { label: 'Famy', value: 'Famy' },
  { label: 'Fanano', value: 'Fanano' },
  { label: 'Fanano', value: 'Fanano' },
  { label: 'Fananu Municipality', value: 'Fananu Municipality' },
  { label: 'Fanapanges Municipality', value: 'Fanapanges Municipality' },
  { label: 'Fânațe', value: 'Fânațe' },
  { label: 'Fânațe', value: 'Fânațe' },
  { label: 'Fânațele Mădărașului', value: 'Fânațele Mădărașului' },
  { label: 'Fânațele Socolului', value: 'Fânațele Socolului' },
  { label: 'Fane', value: 'Fane' },
  { label: 'Fang', value: 'Fang' },
  { label: 'Fangchenggang Shi', value: 'Fangchenggang Shi' },
  { label: 'Fangshan', value: 'Fangshan' },
  { label: 'Fangting', value: 'Fangting' },
  { label: 'Fanhões', value: 'Fanhões' },
  { label: 'Fanif Municipality', value: 'Fanif Municipality' },
  { label: 'Fanipol', value: 'Fanipol' },
  { label: 'Fanlo', value: 'Fanlo' },
  { label: 'Fanna', value: 'Fanna' },
  { label: 'Fannett', value: 'Fannett' },
  { label: 'Fannie Bay', value: 'Fannie Bay' },
  { label: 'Fannin County', value: 'Fannin County' },
  { label: 'Fannin County', value: 'Fannin County' },
  { label: 'Fano', value: 'Fano' },
  { label: 'Fano Adriano', value: 'Fano Adriano' },
  { label: 'Fanø Kommune', value: 'Fanø Kommune' },
  { label: 'Fanouj', value: 'Fanouj' },
  { label: 'Fantan', value: 'Fantan' },
  { label: 'Fântâna Babii', value: 'Fântâna Babii' },
  { label: 'Fântâna Mare', value: 'Fântâna Mare' },
  { label: 'Fântânele', value: 'Fântânele' },
  { label: 'Fântânele', value: 'Fântânele' },
  { label: 'Fântânele', value: 'Fântânele' },
  { label: 'Fântânele', value: 'Fântânele' },
  { label: 'Fântânele', value: 'Fântânele' },
  { label: 'Fântânele', value: 'Fântânele' },
  { label: 'Fântânele', value: 'Fântânele' },
  { label: 'Fântânele', value: 'Fântânele' },
  { label: 'Fântânele', value: 'Fântânele' },
  { label: 'Fântânele', value: 'Fântânele' },
  { label: 'Fantasina', value: 'Fantasina' },
  { label: 'Fantino', value: 'Fantino' },
  { label: 'Fanuj', value: 'Fanuj' },
  { label: 'Fanwood', value: 'Fanwood' },
  { label: 'Fanzara', value: 'Fanzara' },
  { label: 'Fânzeres', value: 'Fânzeres' },
  { label: 'Fanzhuang', value: 'Fanzhuang' },
  { label: 'Fanzolo', value: 'Fanzolo' },
  { label: 'Faqirwali', value: 'Faqirwali' },
  { label: 'Faqous', value: 'Faqous' },
  { label: 'Far Al Udayn', value: 'Far Al Udayn' },
  { label: 'Far Dempordà El', value: 'Far Dempordà El' },
  { label: 'Far North District', value: 'Far North District' },
  { label: 'Far Rockaway', value: 'Far Rockaway' },
  { label: 'Far`', value: 'Far`' },
  { label: 'Fara Filiorum Petri', value: 'Fara Filiorum Petri' },
  { label: 'Fara Gera Dadda', value: 'Fara Gera Dadda' },
  { label: 'Fara In Sabina', value: 'Fara In Sabina' },
  { label: 'Fara Novarese', value: 'Fara Novarese' },
  { label: 'Fara Olivana', value: 'Fara Olivana' },
  { label: 'Fara San Martino', value: 'Fara San Martino' },
  { label: 'Fara Vicentino', value: 'Fara Vicentino' },
  { label: 'Farád', value: 'Farád' },
  { label: 'Faradonbeh', value: 'Faradonbeh' },
  { label: 'Farafenni', value: 'Farafenni' },
  { label: 'Fărăgău', value: 'Fărăgău' },
  { label: 'Farah', value: 'Farah' },
  { label: 'Farah', value: 'Farah' },
  { label: 'Farahan', value: 'Farahan' },
  { label: 'Faraján', value: 'Faraján' },
  { label: 'Farakka', value: 'Farakka' },
  { label: 'Faraldo-Nocelleto', value: 'Faraldo-Nocelleto' },
  { label: 'Farallón', value: 'Farallón' },
  { label: 'Faramontanos De Tábara', value: 'Faramontanos De Tábara' },
  { label: 'Faraoaní', value: 'Faraoaní' },
  { label: 'Farap', value: 'Farap' },
  { label: 'Faraqi', value: 'Faraqi' },
  { label: 'Farasān', value: 'Farasān' },
  { label: 'Farasfaj', value: 'Farasfaj' },
  { label: 'Farashband', value: 'Farashband' },
  { label: 'Fāraskūr', value: 'Fāraskūr' },
  { label: 'Fărău', value: 'Fărău' },
  { label: 'Faraulep Municipality', value: 'Faraulep Municipality' },
  { label: 'Fărcaș', value: 'Fărcaș' },
  { label: 'Fărcaşa', value: 'Fărcaşa' },
  { label: 'Fărcaşa', value: 'Fărcaşa' },
  { label: 'Fărcaşele', value: 'Fărcaşele' },
  { label: 'Fărcășeni', value: 'Fărcășeni' },
  { label: 'Fărcăşeşti', value: 'Fărcăşeşti' },
  { label: 'Fărcașu De Jos', value: 'Fărcașu De Jos' },
  { label: 'Farchant', value: 'Farchant' },
  { label: 'Farciennes', value: 'Farciennes' },
  { label: 'Fârdea', value: 'Fârdea' },
  { label: 'Fardella', value: 'Fardella' },
  { label: 'Fardis', value: 'Fardis' },
  { label: 'Farébersviller', value: 'Farébersviller' },
  { label: 'Fareghan', value: 'Fareghan' },
  { label: 'Fareham', value: 'Fareham' },
  { label: 'Fareins', value: 'Fareins' },
  { label: 'Faremoutiers', value: 'Faremoutiers' },
  { label: 'Fareydunshahr', value: 'Fareydunshahr' },
  { label: 'Färgelanda', value: 'Färgelanda' },
  { label: 'Fargo', value: 'Fargo' },
  { label: 'Fargues-Saint-Hilaire', value: 'Fargues-Saint-Hilaire' },
  { label: 'Farhadgerd', value: 'Farhadgerd' },
  { label: 'Faria', value: 'Faria' },
  { label: 'Faria Lemos', value: 'Faria Lemos' },
  { label: 'Farias Brito', value: 'Farias Brito' },
  { label: 'Faribault', value: 'Faribault' },
  { label: 'Faribault County', value: 'Faribault County' },
  { label: 'Faridabad', value: 'Faridabad' },
  { label: 'Faridkot', value: 'Faridkot' },
  { label: 'Faridnagar', value: 'Faridnagar' },
  { label: 'Faridpur', value: 'Faridpur' },
  { label: 'Faridpur ', value: 'Faridpur ' },
  { label: 'Farigliano', value: 'Farigliano' },
  { label: 'Färila', value: 'Färila' },
  { label: 'Farim', value: 'Farim' },
  { label: 'Farim', value: 'Farim' },
  { label: 'Fariman', value: 'Fariman' },
  { label: 'Farindola', value: 'Farindola' },
  { label: 'Faringdon', value: 'Faringdon' },
  { label: 'Farini', value: 'Farini' },
  { label: 'Fariza', value: 'Fariza' },
  { label: 'Färjestaden', value: 'Färjestaden' },
  { label: 'Farkadóna', value: 'Farkadóna' },
  { label: 'Farkaševac', value: 'Farkaševac' },
  { label: 'Farkaslyuk', value: 'Farkaslyuk' },
  { label: 'Farkaždin', value: 'Farkaždin' },
  { label: 'Farkhor', value: 'Farkhor' },
  { label: 'Farlete', value: 'Farlete' },
  { label: 'Farley', value: 'Farley' },
  { label: 'Farley', value: 'Farley' },
  { label: 'Fârliug', value: 'Fârliug' },
  { label: 'Färlöv', value: 'Färlöv' },
  { label: 'Farm Heights', value: 'Farm Heights' },
  { label: 'Farm Loop', value: 'Farm Loop' },
  { label: 'Farmahin', value: 'Farmahin' },
  { label: 'Farmborough Heights', value: 'Farmborough Heights' },
  { label: 'Farmer City', value: 'Farmer City' },
  { label: 'Farmers Branch', value: 'Farmers Branch' },
  { label: 'Farmers Loop', value: 'Farmers Loop' },
  { label: 'Farmersburg', value: 'Farmersburg' },
  { label: 'Farmersville', value: 'Farmersville' },
  { label: 'Farmersville', value: 'Farmersville' },
  { label: 'Farmersville', value: 'Farmersville' },
  { label: 'Farmerville', value: 'Farmerville' },
  { label: 'Farmingdale', value: 'Farmingdale' },
  { label: 'Farmingdale', value: 'Farmingdale' },
  { label: 'Farmingdale', value: 'Farmingdale' },
  { label: 'Farmington', value: 'Farmington' },
  { label: 'Farmington', value: 'Farmington' },
  { label: 'Farmington', value: 'Farmington' },
  { label: 'Farmington', value: 'Farmington' },
  { label: 'Farmington', value: 'Farmington' },
  { label: 'Farmington', value: 'Farmington' },
  { label: 'Farmington', value: 'Farmington' },
  { label: 'Farmington', value: 'Farmington' },
  { label: 'Farmington', value: 'Farmington' },
  { label: 'Farmington', value: 'Farmington' },
  { label: 'Farmington', value: 'Farmington' },
  { label: 'Farmington Hills', value: 'Farmington Hills' },
  { label: 'Farmingville', value: 'Farmingville' },
  { label: 'Farmland', value: 'Farmland' },
  { label: 'Farmos', value: 'Farmos' },
  { label: 'Farmsen-Berne', value: 'Farmsen-Berne' },
  { label: 'Farmsum', value: 'Farmsum' },
  { label: 'Farmville', value: 'Farmville' },
  { label: 'Farmville', value: 'Farmville' },
  { label: 'Farnay', value: 'Farnay' },
  { label: 'Farnborough', value: 'Farnborough' },
  { label: 'Farndon', value: 'Farndon' },
  { label: 'Farnes', value: 'Farnes' },
  { label: 'Farnese', value: 'Farnese' },
  { label: 'Farnham', value: 'Farnham' },
  { label: 'Farnham', value: 'Farnham' },
  { label: 'Farnham Royal', value: 'Farnham Royal' },
  { label: 'Farnsfield', value: 'Farnsfield' },
  { label: 'Farnstädt', value: 'Farnstädt' },
  { label: 'Farnworth', value: 'Farnworth' },
  { label: 'Faro', value: 'Faro' },
  { label: 'Faro', value: 'Faro' },
  { label: 'Faro Department', value: 'Faro Department' },
  { label: 'Faro Superiore', value: 'Faro Superiore' },
  { label: 'Farol', value: 'Farol' },
  { label: 'Farooj', value: 'Farooj' },
  { label: 'Fáros', value: 'Fáros' },
  { label: 'Farr West', value: 'Farr West' },
  { label: 'Farra Dalpago', value: 'Farra Dalpago' },
  { label: 'Farra Di Soligo', value: 'Farra Di Soligo' },
  { label: 'Farra Disonzo', value: 'Farra Disonzo' },
  { label: 'Farrach', value: 'Farrach' },
  { label: 'Farragut', value: 'Farragut' },
  { label: 'Farrar', value: 'Farrar' },
  { label: 'Farrell', value: 'Farrell' },
  { label: 'Farrer', value: 'Farrer' },
  { label: 'Farrera', value: 'Farrera' },
  { label: 'Farrokh Shahr', value: 'Farrokh Shahr' },
  { label: 'Farrokhi', value: 'Farrokhi' },
  { label: 'Farrokhshahr', value: 'Farrokhshahr' },
  { label: 'Farroupilha', value: 'Farroupilha' },
  { label: 'Farrukhabad', value: 'Farrukhabad' },
  { label: 'Farrukhnagar', value: 'Farrukhnagar' },
  { label: 'Farrukhnagar', value: 'Farrukhnagar' },
  { label: 'Farsan', value: 'Farsan' },
  { label: 'Farsban', value: 'Farsban' },
  { label: 'Farschviller', value: 'Farschviller' },
  { label: 'Farshūţ', value: 'Farshūţ' },
  { label: 'Farsø', value: 'Farsø' },
  { label: 'Farsund', value: 'Farsund' },
  { label: 'Fârţăneşti', value: 'Fârţăneşti' },
  { label: 'Fărtăţeşti', value: 'Fărtăţeşti' },
  { label: 'Fartura', value: 'Fartura' },
  { label: 'Fartura Do Piauí', value: 'Fartura Do Piauí' },
  { label: 'Faruj', value: 'Faruj' },
  { label: 'Faruka', value: 'Faruka' },
  { label: 'Farum', value: 'Farum' },
  { label: 'Farvaz', value: 'Farvaz' },
  { label: 'Farwell', value: 'Farwell' },
  { label: 'Faryab', value: 'Faryab' },
  { label: 'Faryazan', value: 'Faryazan' },
  { label: 'Fasa', value: 'Fasa' },
  { label: 'Fasano', value: 'Fasano' },
  { label: 'Fascia', value: 'Fascia' },
  { label: 'Fasham', value: 'Fasham' },
  { label: 'Fasijan', value: 'Fasijan' },
  { label: 'Fasito‘Outa', value: 'Fasito‘Outa' },
  { label: 'Fasnia', value: 'Fasnia' },
  { label: 'Faßberg', value: 'Faßberg' },
  { label: 'Fâstâci', value: 'Fâstâci' },
  { label: 'Fastiv', value: 'Fastiv' },
  { label: 'Fastiv Raion', value: 'Fastiv Raion' },
  { label: 'Fastov', value: 'Fastov' },
  { label: 'Fastovetskaya', value: 'Fastovetskaya' },
  { label: 'Fatarella', value: 'Fatarella' },
  { label: 'Fate', value: 'Fate' },
  { label: 'Fatehabad', value: 'Fatehabad' },
  { label: 'Fatehabad', value: 'Fatehabad' },
  { label: 'Fatehganj West', value: 'Fatehganj West' },
  { label: 'Fatehgarh', value: 'Fatehgarh' },
  { label: 'Fatehgarh Churian', value: 'Fatehgarh Churian' },
  { label: 'Fatehgarh Sahib', value: 'Fatehgarh Sahib' },
  { label: 'Fatehpur', value: 'Fatehpur' },
  { label: 'Fatehpur', value: 'Fatehpur' },
  { label: 'Fatehpur (Barabanki)', value: 'Fatehpur (Barabanki)' },
  { label: 'Fatehpur Chaurasi', value: 'Fatehpur Chaurasi' },
  { label: 'Fatehpur Sikri', value: 'Fatehpur Sikri' },
  { label: 'Fatezh', value: 'Fatezh' },
  { label: 'Fatezhskiy Rayon', value: 'Fatezhskiy Rayon' },
  { label: 'Fatholmobin', value: 'Fatholmobin' },
  { label: 'Fatick Department', value: 'Fatick Department' },
  { label: 'Fatih', value: 'Fatih' },
  { label: 'Fátima', value: 'Fátima' },
  { label: 'Fátima', value: 'Fátima' },
  { label: 'Fátima', value: 'Fátima' },
  { label: 'Fátima Do Sul', value: 'Fátima Do Sul' },
  { label: 'Fatsa', value: 'Fatsa' },
  { label: 'Fatuberliu', value: 'Fatuberliu' },
  { label: 'Fatumean', value: 'Fatumean' },
  { label: 'Fatwa', value: 'Fatwa' },
  { label: 'Fauglia', value: 'Fauglia' },
  { label: 'Faulbach', value: 'Faulbach' },
  { label: 'Faulconbridge', value: 'Faulconbridge' },
  { label: 'Fauldhouse', value: 'Fauldhouse' },
  { label: 'Faule', value: 'Faule' },
  { label: 'Faulk County', value: 'Faulk County' },
  { label: 'Faulkner County', value: 'Faulkner County' },
  { label: 'Faulkton', value: 'Faulkton' },
  { label: 'Faulquemont', value: 'Faulquemont' },
  { label: 'Faulx', value: 'Faulx' },
  { label: 'Faumont', value: 'Faumont' },
  { label: 'Fauquier County', value: 'Fauquier County' },
  { label: 'Faura', value: 'Faura' },
  { label: 'Făurei', value: 'Făurei' },
  { label: 'Făurei', value: 'Făurei' },
  { label: 'Făureşti', value: 'Făureşti' },
  { label: 'Făurești', value: 'Făurești' },
  { label: 'Fauske', value: 'Fauske' },
  { label: 'Fauville-En-Caux', value: 'Fauville-En-Caux' },
  { label: 'Fauvillers', value: 'Fauvillers' },
  { label: 'Faux A Chaud', value: 'Faux A Chaud' },
  { label: 'Favaios', value: 'Favaios' },
  { label: 'Favale Di Malvaro', value: 'Favale Di Malvaro' },
  { label: 'Favara', value: 'Favara' },
  { label: 'Favara', value: 'Favara' },
  { label: 'Favari-Avatanei', value: 'Favari-Avatanei' },
  { label: 'Faver', value: 'Faver' },
  { label: 'Faverges', value: 'Faverges' },
  { label: 'Faverges-De-La-Tour', value: 'Faverges-De-La-Tour' },
  { label: 'Faverney', value: 'Faverney' },
  { label: 'Faverolles-Sur-Cher', value: 'Faverolles-Sur-Cher' },
  { label: 'Faversham', value: 'Faversham' },
  { label: 'Favières', value: 'Favières' },
  { label: 'Favignana', value: 'Favignana' },
  { label: 'Favoriten', value: 'Favoriten' },
  { label: 'Favria', value: 'Favria' },
  { label: 'Favrskov Kommune', value: 'Favrskov Kommune' },
  { label: 'Fawkner', value: 'Fawkner' },
  { label: 'Faxe', value: 'Faxe' },
  { label: 'Faxe Kommune', value: 'Faxe Kommune' },
  { label: 'Faxe Ladeplads', value: 'Faxe Ladeplads' },
  { label: 'Faxinal', value: 'Faxinal' },
  { label: 'Faxinal Do Soturno', value: 'Faxinal Do Soturno' },
  { label: 'Faxinal Dos Guedes', value: 'Faxinal Dos Guedes' },
  { label: 'Faxinalzinho', value: 'Faxinalzinho' },
  { label: 'Faxon', value: 'Faxon' },
  { label: 'Faya-Largeau', value: 'Faya-Largeau' },
  { label: 'Fay-Aux-Loges', value: 'Fay-Aux-Loges' },
  { label: 'Fayd', value: 'Fayd' },
  { label: 'Fay-De-Bretagne', value: 'Fay-De-Bretagne' },
  { label: 'Fayence', value: 'Fayence' },
  { label: 'Fayette', value: 'Fayette' },
  { label: 'Fayette', value: 'Fayette' },
  { label: 'Fayette', value: 'Fayette' },
  { label: 'Fayette', value: 'Fayette' },
  { label: 'Fayette', value: 'Fayette' },
  { label: 'Fayette', value: 'Fayette' },
  { label: 'Fayette County', value: 'Fayette County' },
  { label: 'Fayette County', value: 'Fayette County' },
  { label: 'Fayette County', value: 'Fayette County' },
  { label: 'Fayette County', value: 'Fayette County' },
  { label: 'Fayette County', value: 'Fayette County' },
  { label: 'Fayette County', value: 'Fayette County' },
  { label: 'Fayette County', value: 'Fayette County' },
  { label: 'Fayette County', value: 'Fayette County' },
  { label: 'Fayette County', value: 'Fayette County' },
  { label: 'Fayette County', value: 'Fayette County' },
  { label: 'Fayette County', value: 'Fayette County' },
  { label: 'Fayetteville', value: 'Fayetteville' },
  { label: 'Fayetteville', value: 'Fayetteville' },
  { label: 'Fayetteville', value: 'Fayetteville' },
  { label: 'Fayetteville', value: 'Fayetteville' },
  { label: 'Fayetteville', value: 'Fayetteville' },
  { label: 'Fayetteville', value: 'Fayetteville' },
  { label: 'Fayetteville', value: 'Fayetteville' },
  { label: 'Fayetteville', value: 'Fayetteville' },
  { label: 'Fayl-Billot', value: 'Fayl-Billot' },
  { label: 'Fayón', value: 'Fayón' },
  { label: 'Fayos Los', value: 'Fayos Los' },
  { label: 'Fayrōz Kōh', value: 'Fayrōz Kōh' },
  { label: 'Fayzabad', value: 'Fayzabad' },
  { label: 'Fažana', value: 'Fažana' },
  { label: 'Fažana-Fasana', value: 'Fažana-Fasana' },
  { label: 'Fazelabad', value: 'Fazelabad' },
  { label: 'Fazeley', value: 'Fazeley' },
  { label: 'Fazenda Nova', value: 'Fazenda Nova' },
  { label: 'Fazenda Rio Grande', value: 'Fazenda Rio Grande' },
  { label: 'Fazenda Vilanova', value: 'Fazenda Vilanova' },
  { label: 'Fazendas De Almeirim', value: 'Fazendas De Almeirim' },
  { label: 'Fazilka', value: 'Fazilka' },
  { label: 'Fazilpur', value: 'Fazilpur' },
  { label: 'Fderîck', value: 'Fderîck' },
  { label: 'Feanwâlden', value: 'Feanwâlden' },
  { label: 'Fearrington Village', value: 'Fearrington Village' },
  { label: 'Feasterville', value: 'Feasterville' },
  { label: 'Feather Sound', value: 'Feather Sound' },
  { label: 'Featherstone', value: 'Featherstone' },
  { label: 'Febró', value: 'Febró' },
  { label: 'Fécamp', value: 'Fécamp' },
  { label: 'Féchain', value: 'Féchain' },
  { label: 'Fedderwarden', value: 'Fedderwarden' },
  { label: 'Federación', value: 'Federación' },
  { label: 'Federal', value: 'Federal' },
  { label: 'Federal Heights', value: 'Federal Heights' },
  { label: 'Federal Way', value: 'Federal Way' },
  { label: 'Federalsburg', value: 'Federalsburg' },
  { label: 'Fedje', value: 'Fedje' },
  { label: 'Fedorivka', value: 'Fedorivka' },
  { label: 'Fedorovka', value: 'Fedorovka' },
  { label: 'Fedorovskiy', value: 'Fedorovskiy' },
  { label: 'Fëdorovskiy Rayon', value: 'Fëdorovskiy Rayon' },
  { label: 'Fëdorovskoye', value: 'Fëdorovskoye' },
  { label: 'Fedurnovo', value: 'Fedurnovo' },
  { label: 'Fefen Municipality', value: 'Fefen Municipality' },
  { label: 'Feffernitz', value: 'Feffernitz' },
  { label: 'Fegersheim', value: 'Fegersheim' },
  { label: 'Feglino', value: 'Feglino' },
  { label: 'Fégréac', value: 'Fégréac' },
  { label: 'Fegyvernek', value: 'Fegyvernek' },
  { label: 'Fehérgyarmat', value: 'Fehérgyarmat' },
  { label: 'Fehérgyarmati Járás', value: 'Fehérgyarmati Járás' },
  { label: 'Fehérvárcsurgó', value: 'Fehérvárcsurgó' },
  { label: 'Fehmarn', value: 'Fehmarn' },
  { label: 'Fehraltorf', value: 'Fehraltorf' },
  { label: 'Fehrbellin', value: 'Fehrbellin' },
  { label: 'Fehring', value: 'Fehring' },
  { label: 'Feicheng', value: 'Feicheng' },
  { label: 'Feigères', value: 'Feigères' },
  { label: 'Feignies', value: 'Feignies' },
  { label: 'Feijenoord', value: 'Feijenoord' },
  { label: 'Feijó', value: 'Feijó' },
  { label: 'Feilbingert', value: 'Feilbingert' },
  { label: 'Feilitzsch', value: 'Feilitzsch' },
  { label: 'Feillens', value: 'Feillens' },
  { label: 'Feiluan', value: 'Feiluan' },
  { label: 'Feira', value: 'Feira' },
  { label: 'Feira Da Mata', value: 'Feira Da Mata' },
  { label: 'Feira De Santana', value: 'Feira De Santana' },
  { label: 'Feira Grande', value: 'Feira Grande' },
  { label: 'Feira Nova', value: 'Feira Nova' },
  { label: 'Feira Nova', value: 'Feira Nova' },
  { label: 'Feira Nova Do Maranhão', value: 'Feira Nova Do Maranhão' },
  { label: 'Feisa', value: 'Feisa' },
  { label: 'Feisoglio', value: 'Feisoglio' },
  { label: 'Feistritz Am Wechsel', value: 'Feistritz Am Wechsel' },
  { label: 'Feistritz An Der Drau', value: 'Feistritz An Der Drau' },
  { label: 'Feistritz An Der Gail', value: 'Feistritz An Der Gail' },
  { label: 'Feistritz Bei Knittelfeld', value: 'Feistritz Bei Knittelfeld' },
  { label: 'Feistritz Im Rosental', value: 'Feistritz Im Rosental' },
  { label: 'Feistritz Ob Bleiburg', value: 'Feistritz Ob Bleiburg' },
  { label: 'Feke', value: 'Feke' },
  { label: 'Felahiye', value: 'Felahiye' },
  { label: 'Felanitx', value: 'Felanitx' },
  { label: 'Feld Am See', value: 'Feld Am See' },
  { label: 'Feldafing', value: 'Feldafing' },
  { label: 'Feldbach', value: 'Feldbach' },
  { label: 'Feldberg', value: 'Feldberg' },
  { label: 'Felde', value: 'Felde' },
  { label: 'Feldioara', value: 'Feldioara' },
  { label: 'Feldkirch', value: 'Feldkirch' },
  { label: 'Feldkirchen', value: 'Feldkirchen' },
  { label: 'Feldkirchen An Der Donau', value: 'Feldkirchen An Der Donau' },
  { label: 'Feldkirchen Bei Graz', value: 'Feldkirchen Bei Graz' },
  { label: 'Feldkirchen District', value: 'Feldkirchen District' },
  { label: 'Feldkirchen In Kärnten', value: 'Feldkirchen In Kärnten' },
  { label: 'Feldkirchen-Westerham', value: 'Feldkirchen-Westerham' },
  { label: 'Feldmeilen', value: 'Feldmeilen' },
  { label: 'Feldru', value: 'Feldru' },
  { label: 'Feldstadt', value: 'Feldstadt' },
  { label: 'Feleacu', value: 'Feleacu' },
  { label: 'Feleag', value: 'Feleag' },
  { label: 'Felegara', value: 'Felegara' },
  { label: 'Felege Neway', value: 'Felege Neway' },
  { label: 'Feletto', value: 'Feletto' },
  { label: 'Felgueiras', value: 'Felgueiras' },
  { label: 'Feliceni', value: 'Feliceni' },
  { label: 'Feliciano', value: 'Feliciano' },
  { label: 'Felício Dos Santos', value: 'Felício Dos Santos' },
  { label: 'Felida', value: 'Felida' },
  { label: 'Felidhoo', value: 'Felidhoo' },
  { label: 'Felina', value: 'Felina' },
  { label: 'Félines', value: 'Félines' },
  { label: 'Felino', value: 'Felino' },
  { label: 'Felipe Angeles', value: 'Felipe Angeles' },
  { label: 'Felipe Carrillo Puerto', value: 'Felipe Carrillo Puerto' },
  { label: 'Felipe Carrillo Puerto', value: 'Felipe Carrillo Puerto' },
  { label: 'Felipe Carrillo Puerto', value: 'Felipe Carrillo Puerto' },
  { label: 'Felipe Carrillo Puerto', value: 'Felipe Carrillo Puerto' },
  { label: 'Felipe Carrillo Puerto', value: 'Felipe Carrillo Puerto' },
  { label: 'Felipe Carrillo Puerto', value: 'Felipe Carrillo Puerto' },
  { label: 'Felipe Guerra', value: 'Felipe Guerra' },
  { label: 'Felipe Neri (Cuatepec)', value: 'Felipe Neri (Cuatepec)' },
  { label: 'Felipe Yofré', value: 'Felipe Yofré' },
  { label: 'Felisburgo', value: 'Felisburgo' },
  { label: 'Felitto', value: 'Felitto' },
  { label: 'Felix', value: 'Felix' },
  { label: 'Felixdorf', value: 'Felixdorf' },
  { label: 'Felixlândia', value: 'Felixlândia' },
  { label: 'Felixstow', value: 'Felixstow' },
  { label: 'Felixstowe', value: 'Felixstowe' },
  { label: 'Feliz', value: 'Feliz' },
  { label: 'Feliz Deserto', value: 'Feliz Deserto' },
  { label: 'Feliz Natal', value: 'Feliz Natal' },
  { label: 'Felizzano', value: 'Felizzano' },
  { label: 'Fell', value: 'Fell' },
  { label: 'Fellbach', value: 'Fellbach' },
  { label: 'Fellegara', value: 'Fellegara' },
  { label: 'Fellering', value: 'Fellering' },
  { label: 'Felletin', value: 'Felletin' },
  { label: 'Fellheim', value: 'Fellheim' },
  { label: 'Felline', value: 'Felline' },
  { label: 'Felling', value: 'Felling' },
  { label: 'Fellingsbro', value: 'Fellingsbro' },
  { label: 'Fellowship', value: 'Fellowship' },
  { label: 'Fellsburg', value: 'Fellsburg' },
  { label: 'Fellsmere', value: 'Fellsmere' },
  { label: 'Felm', value: 'Felm' },
  { label: 'Felnac', value: 'Felnac' },
  { label: 'Felonica', value: 'Felonica' },
  { label: 'Fels Am Wagram', value: 'Fels Am Wagram' },
  { label: 'Felsberg', value: 'Felsberg' },
  { label: 'Felsberg', value: 'Felsberg' },
  { label: 'Felsőpakony', value: 'Felsőpakony' },
  { label: 'Felsőszentiván', value: 'Felsőszentiván' },
  { label: 'Felsőtárkány', value: 'Felsőtárkány' },
  { label: 'Felsőzsolca', value: 'Felsőzsolca' },
  { label: 'Feltham', value: 'Feltham' },
  { label: 'Felton', value: 'Felton' },
  { label: 'Felton', value: 'Felton' },
  { label: 'Felton', value: 'Felton' },
  { label: 'Feltre', value: 'Feltre' },
  { label: 'Feltwell', value: 'Feltwell' },
  { label: 'Fenain', value: 'Fenain' },
  { label: 'Fenais Da Ajuda', value: 'Fenais Da Ajuda' },
  { label: 'Fenais Da Luz', value: 'Fenais Da Luz' },
  { label: 'Fénay', value: 'Fénay' },
  { label: 'Fendels', value: 'Fendels' },
  { label: 'Fendou', value: 'Fendou' },
  { label: 'Fene', value: 'Fene' },
  { label: 'Fenegrò', value: 'Fenegrò' },
  { label: 'Feneș', value: 'Feneș' },
  { label: 'Fenestrelle', value: 'Fenestrelle' },
  { label: 'Feneu', value: 'Feneu' },
  { label: 'Fengcheng', value: 'Fengcheng' },
  { label: 'Fenghua', value: 'Fenghua' },
  { label: 'Fenghuang', value: 'Fenghuang' },
  { label: 'Fengkou', value: 'Fengkou' },
  { label: 'Fengpu', value: 'Fengpu' },
  { label: 'Fengrun', value: 'Fengrun' },
  { label: 'Fengtai (Ninghe)', value: 'Fengtai (Ninghe)' },
  { label: 'Fengxian', value: 'Fengxian' },
  { label: 'Fengxiang', value: 'Fengxiang' },
  { label: 'Fengzhou', value: 'Fengzhou' },
  { label: 'Feni', value: 'Feni' },
  { label: 'Fenile', value: 'Fenile' },
  { label: 'Fénis', value: 'Fénis' },
  { label: 'Fênix', value: 'Fênix' },
  { label: 'Fennell Bay', value: 'Fennell Bay' },
  { label: 'Fennimore', value: 'Fennimore' },
  { label: 'Fennpfuhl', value: 'Fennpfuhl' },
  { label: 'Fennville', value: 'Fennville' },
  { label: 'Fenouillet', value: 'Fenouillet' },
  { label: 'Fensmark', value: 'Fensmark' },
  { label: 'Fenstanton', value: 'Fenstanton' },
  { label: 'Fentange', value: 'Fentange' },
  { label: 'Fenton', value: 'Fenton' },
  { label: 'Fenton', value: 'Fenton' },
  { label: 'Fentress County', value: 'Fentress County' },
  { label: 'Fenwick', value: 'Fenwick' },
  { label: 'Fényeslitke', value: 'Fényeslitke' },
  { label: 'Fenyi', value: 'Fenyi' },
  { label: 'Feodosiya', value: 'Feodosiya' },
  { label: 'Feraoun', value: 'Feraoun' },
  { label: 'Ferapontovo', value: 'Ferapontovo' },
  { label: 'Ferbane', value: 'Ferbane' },
  { label: 'Ferdinand', value: 'Ferdinand' },
  { label: 'Ferdinandovac', value: 'Ferdinandovac' },
  { label: 'Ferdinandshof', value: 'Ferdinandshof' },
  { label: 'Ferdosiye', value: 'Ferdosiye' },
  { label: 'Ferdows', value: 'Ferdows' },
  { label: 'Fère-Champenoise', value: 'Fère-Champenoise' },
  { label: 'Fère-En-Tardenois', value: 'Fère-En-Tardenois' },
  { label: 'Férel', value: 'Férel' },
  { label: 'Ferenbalm', value: 'Ferenbalm' },
  { label: 'Ferentillo', value: 'Ferentillo' },
  { label: 'Ferentino', value: 'Ferentino' },
  { label: 'Féres', value: 'Féres' },
  { label: 'Ferești', value: 'Ferești' },
  { label: 'Ferești', value: 'Ferești' },
  { label: 'Ferestrău-Oituz', value: 'Ferestrău-Oituz' },
  { label: 'Fereydan', value: 'Fereydan' },
  { label: 'Fereydun Shahr', value: 'Fereydun Shahr' },
  { label: 'Fereydunkenar', value: 'Fereydunkenar' },
  { label: 'Férez', value: 'Férez' },
  { label: 'Fergana', value: 'Fergana' },
  { label: 'Fergus County', value: 'Fergus County' },
  { label: 'Fergus Falls', value: 'Fergus Falls' },
  { label: 'Ferguson', value: 'Ferguson' },
  { label: 'Feria', value: 'Feria' },
  { label: 'Feričanci', value: 'Feričanci' },
  { label: 'Fericea', value: 'Fericea' },
  { label: 'Férin', value: 'Férin' },
  { label: 'Ferizli', value: 'Ferizli' },
  { label: 'Ferla', value: 'Ferla' },
  { label: 'Ferlach', value: 'Ferlach' },
  { label: 'Ferma', value: 'Ferma' },
  { label: 'Fermanagh And Omagh', value: 'Fermanagh And Omagh' },
  { label: 'Fermanville', value: 'Fermanville' },
  { label: 'Ferme-Neuve', value: 'Ferme-Neuve' },
  { label: 'Fermignano', value: 'Fermignano' },
  { label: 'Fermín Rabadán Cervantes', value: 'Fermín Rabadán Cervantes' },
  { label: 'Fermo', value: 'Fermo' },
  { label: 'Fermont', value: 'Fermont' },
  { label: 'Fermoselle', value: 'Fermoselle' },
  { label: 'Fermoy', value: 'Fermoy' },
  { label: 'Fern Acres', value: 'Fern Acres' },
  { label: 'Fern Bay', value: 'Fern Bay' },
  { label: 'Fern Creek', value: 'Fern Creek' },
  { label: 'Fern Hill', value: 'Fern Hill' },
  { label: 'Fern Park', value: 'Fern Park' },
  { label: 'Fern Prairie', value: 'Fern Prairie' },
  { label: 'Fernán Caballero', value: 'Fernán Caballero' },
  { label: 'Fernana', value: 'Fernana' },
  { label: 'Fernandes Pinheiro', value: 'Fernandes Pinheiro' },
  { label: 'Fernandes Tourinho', value: 'Fernandes Tourinho' },
  { label: 'Fernandina Beach', value: 'Fernandina Beach' },
  { label: 'Fernando De La Mora', value: 'Fernando De La Mora' },
  { label: 'Fernando De Noronha', value: 'Fernando De Noronha' },
  { label: 'Fernando De Noronha (Distrito Estadual)', value: 'Fernando De Noronha (Distrito Estadual)' },
  { label: 'Fernando Falcão', value: 'Fernando Falcão' },
  { label: 'Fernando Pedroza', value: 'Fernando Pedroza' },
  { label: 'Fernando Prestes', value: 'Fernando Prestes' },
  { label: 'Fernandópolis', value: 'Fernandópolis' },
  { label: 'Fernán-Núñez', value: 'Fernán-Núñez' },
  { label: 'Fernão', value: 'Fernão' },
  { label: 'Fernão Joanes', value: 'Fernão Joanes' },
  { label: 'Ferndale', value: 'Ferndale' },
  { label: 'Ferndale', value: 'Ferndale' },
  { label: 'Ferndale', value: 'Ferndale' },
  { label: 'Ferndale', value: 'Ferndale' },
  { label: 'Ferndale', value: 'Ferndale' },
  { label: 'Ferndale', value: 'Ferndale' },
  { label: 'Ferndale', value: 'Ferndale' },
  { label: 'Ferndorf', value: 'Ferndorf' },
  { label: 'Ferndown', value: 'Ferndown' },
  { label: 'Ferney-Voltaire', value: 'Ferney-Voltaire' },
  { label: 'Fernhill Heath', value: 'Fernhill Heath' },
  { label: 'Fernhurst', value: 'Fernhurst' },
  { label: 'Fernie', value: 'Fernie' },
  { label: 'Fernitz', value: 'Fernitz' },
  { label: 'Fernley', value: 'Fernley' },
  { label: 'Ferno', value: 'Ferno' },
  { label: 'Ferns', value: 'Ferns' },
  { label: 'Ferntree Gully', value: 'Ferntree Gully' },
  { label: 'Fernvale', value: 'Fernvale' },
  { label: 'Fernway', value: 'Fernway' },
  { label: 'Ferny Creek', value: 'Ferny Creek' },
  { label: 'Ferny Grove', value: 'Ferny Grove' },
  { label: 'Ferny Hills', value: 'Ferny Hills' },
  { label: 'Ferokh', value: 'Ferokh' },
  { label: 'Feroleto Antico', value: 'Feroleto Antico' },
  { label: 'Feroleto Della Chiesa', value: 'Feroleto Della Chiesa' },
  { label: 'Férolles', value: 'Férolles' },
  { label: 'Férolles-Attilly', value: 'Férolles-Attilly' },
  { label: 'Ferozewala', value: 'Ferozewala' },
  { label: 'Ferques', value: 'Ferques' },
  { label: 'Ferrada', value: 'Ferrada' },
  { label: 'Ferragudo', value: 'Ferragudo' },
  { label: 'Ferrals-Les-Corbières', value: 'Ferrals-Les-Corbières' },
  { label: 'Ferrand', value: 'Ferrand' },
  { label: 'Ferrandina', value: 'Ferrandina' },
  { label: 'Ferrara', value: 'Ferrara' },
  { label: 'Ferrara Di Monte Baldo', value: 'Ferrara Di Monte Baldo' },
  { label: 'Ferraz De Vasconcelos', value: 'Ferraz De Vasconcelos' },
  { label: 'Ferrazzano', value: 'Ferrazzano' },
  { label: 'Ferreira', value: 'Ferreira' },
  { label: 'Ferreira', value: 'Ferreira' },
  { label: 'Ferreira Do Alentejo', value: 'Ferreira Do Alentejo' },
  { label: 'Ferreira Do Zêzere', value: 'Ferreira Do Zêzere' },
  { label: 'Ferreira Gomes', value: 'Ferreira Gomes' },
  { label: 'Ferreiras', value: 'Ferreiras' },
  { label: 'Ferreiros', value: 'Ferreiros' },
  { label: 'Ferreiros', value: 'Ferreiros' },
  { label: 'Ferrel', value: 'Ferrel' },
  { label: 'Ferreñafe', value: 'Ferreñafe' },
  { label: 'Ferrera Di Varese', value: 'Ferrera Di Varese' },
  { label: 'Ferrera Erbognone', value: 'Ferrera Erbognone' },
  { label: 'Ferreras De Abajo', value: 'Ferreras De Abajo' },
  { label: 'Ferreras De Arriba', value: 'Ferreras De Arriba' },
  { label: 'Ferrere', value: 'Ferrere' },
  { label: 'Ferrería De Tula', value: 'Ferrería De Tula' },
  { label: 'Ferreries', value: 'Ferreries' },
  { label: 'Ferreruela', value: 'Ferreruela' },
  { label: 'Ferreruela De Huerva', value: 'Ferreruela De Huerva' },
  { label: 'Ferrette', value: 'Ferrette' },
  { label: 'Ferreyres', value: 'Ferreyres' },
  { label: 'Ferriday', value: 'Ferriday' },
  { label: 'Ferrier', value: 'Ferrier' },
  { label: 'Ferriera', value: 'Ferriera' },
  { label: 'Ferriere', value: 'Ferriere' },
  { label: 'Ferriere', value: 'Ferriere' },
  { label: 'Ferrière-La-Grande', value: 'Ferrière-La-Grande' },
  { label: 'Ferrières', value: 'Ferrières' },
  { label: 'Ferrières-En-Brie', value: 'Ferrières-En-Brie' },
  { label: 'Ferris', value: 'Ferris' },
  { label: 'Ferris', value: 'Ferris' },
  { label: 'Ferrisburgh', value: 'Ferrisburgh' },
  { label: 'Ferrol', value: 'Ferrol' },
  { label: 'Ferrol', value: 'Ferrol' },
  { label: 'Ferron', value: 'Ferron' },
  { label: 'Ferros', value: 'Ferros' },
  { label: 'Ferrum', value: 'Ferrum' },
  { label: 'Ferruzzano', value: 'Ferruzzano' },
  { label: 'Ferry', value: 'Ferry' },
  { label: 'Ferry County', value: 'Ferry County' },
  { label: 'Ferry Pass', value: 'Ferry Pass' },
  { label: 'Ferrybridge', value: 'Ferrybridge' },
  { label: 'Ferryden Park', value: 'Ferryden Park' },
  { label: 'Ferryhill', value: 'Ferryhill' },
  { label: 'Ferrysburg', value: 'Ferrysburg' },
  { label: 'Ferschnitz', value: 'Ferschnitz' },
  { label: 'Fershampenuaz', value: 'Fershampenuaz' },
  { label: 'Fersig', value: 'Fersig' },
  { label: 'Fertőd', value: 'Fertőd' },
  { label: 'Fertőrákos', value: 'Fertőrákos' },
  { label: 'Fertőszentmiklós', value: 'Fertőszentmiklós' },
  { label: 'Ferunabad', value: 'Ferunabad' },
  { label: 'Fervedouro', value: 'Fervedouro' },
  { label: 'Ferwert', value: 'Ferwert' },
  { label: 'Ferzikovo', value: 'Ferzikovo' },
  { label: 'Ferzikovskiy Rayon', value: 'Ferzikovskiy Rayon' },
  { label: 'Fes', value: 'Fes' },
  { label: 'Fès Al Bali', value: 'Fès Al Bali' },
  { label: 'Fesches-Le-Châtel', value: 'Fesches-Le-Châtel' },
  { label: 'Fessenden', value: 'Fessenden' },
  { label: 'Fessenheim', value: 'Fessenheim' },
  { label: 'Festac', value: 'Festac' },
  { label: 'Festubert', value: 'Festubert' },
  { label: 'Festus', value: 'Festus' },
  { label: 'Fet', value: 'Fet' },
  { label: 'Feteira', value: 'Feteira' },
  { label: 'Féternes', value: 'Féternes' },
  { label: 'Feteşti', value: 'Feteşti' },
  { label: 'Fetești', value: 'Fetești' },
  { label: 'Fetești-Gară', value: 'Fetești-Gară' },
  { label: 'Fethard', value: 'Fethard' },
  { label: 'Fethiye', value: 'Fethiye' },
  { label: 'Fetsund', value: 'Fetsund' },
  { label: 'Fetters Hot Springs-Agua Caliente', value: 'Fetters Hot Springs-Agua Caliente' },
  { label: 'Feucherolles', value: 'Feucherolles' },
  { label: 'Feucht', value: 'Feucht' },
  { label: 'Feuchtwangen', value: 'Feuchtwangen' },
  { label: 'Feuchy', value: 'Feuchy' },
  { label: 'Feuerthalen', value: 'Feuerthalen' },
  { label: 'Feulen', value: 'Feulen' },
  { label: 'Feuquières', value: 'Feuquières' },
  { label: 'Feuquières-En-Vimeu', value: 'Feuquières-En-Vimeu' },
  { label: 'Feurs', value: 'Feurs' },
  { label: 'Feusisberg', value: 'Feusisberg' },
  { label: 'Fexhe-Le-Haut-Clocher', value: 'Fexhe-Le-Haut-Clocher' },
  { label: 'Feytiat', value: 'Feytiat' },
  { label: 'Feyz Abad', value: 'Feyz Abad' },
  { label: 'Feyzin', value: 'Feyzin' },
  { label: 'Fezile Dabi District Municipality', value: 'Fezile Dabi District Municipality' },
  { label: 'Fgura', value: 'Fgura' },
  { label: 'Fiães', value: 'Fiães' },
  { label: 'Fiães', value: 'Fiães' },
  { label: 'Fiambalá', value: 'Fiambalá' },
  { label: 'Fiamignano', value: 'Fiamignano' },
  { label: 'Fiano', value: 'Fiano' },
  { label: 'Fiano Romano', value: 'Fiano Romano' },
  { label: 'Fiavè', value: 'Fiavè' },
  { label: 'Fiazaman', value: 'Fiazaman' },
  { label: 'Fibiș', value: 'Fibiș' },
  { label: 'Ficarazzi', value: 'Ficarazzi' },
  { label: 'Ficarolo', value: 'Ficarolo' },
  { label: 'Ficarra', value: 'Ficarra' },
  { label: 'Fichē', value: 'Fichē' },
  { label: 'Fichtelberg', value: 'Fichtelberg' },
  { label: 'Fichtenberg', value: 'Fichtenberg' },
  { label: 'Fichtenwalde', value: 'Fichtenwalde' },
  { label: 'Ficulle', value: 'Ficulle' },
  { label: 'Fidenza', value: 'Fidenza' },
  { label: 'Fiditi', value: 'Fiditi' },
  { label: 'Fiè Allo Sciliar', value: 'Fiè Allo Sciliar' },
  { label: 'Fieberbrunn', value: 'Fieberbrunn' },
  { label: 'Fiecht', value: 'Fiecht' },
  { label: 'Fieni', value: 'Fieni' },
  { label: 'Fier', value: 'Fier' },
  { label: 'Fiera Di Primiero', value: 'Fiera Di Primiero' },
  { label: 'Fierbinți', value: 'Fierbinți' },
  { label: 'Fierbinții De Jos', value: 'Fierbinții De Jos' },
  { label: 'Fierbinții De Sus', value: 'Fierbinții De Sus' },
  { label: 'Fierbinţi-Târg', value: 'Fierbinţi-Târg' },
  { label: 'Fier-Çifçi', value: 'Fier-Çifçi' },
  { label: 'Fierozzo', value: 'Fierozzo' },
  { label: 'Fiesch', value: 'Fiesch' },
  { label: 'Fiesco', value: 'Fiesco' },
  { label: 'Fiesole', value: 'Fiesole' },
  { label: 'Fiesse', value: 'Fiesse' },
  { label: 'Fiesso', value: 'Fiesso' },
  { label: 'Fiesso Dartico', value: 'Fiesso Dartico' },
  { label: 'Fiesso Umbertiano', value: 'Fiesso Umbertiano' },
  { label: 'Fife', value: 'Fife' },
  { label: 'Fife', value: 'Fife' },
  { label: 'Fife Heights', value: 'Fife Heights' },
  { label: 'Fifth Street', value: 'Fifth Street' },
  { label: 'Fig Tree', value: 'Fig Tree' },
  { label: 'Fig Tree Pocket', value: 'Fig Tree Pocket' },
  { label: 'Figanières', value: 'Figanières' },
  { label: 'Figari', value: 'Figari' },
  { label: 'Figaró-Montmany', value: 'Figaró-Montmany' },
  { label: 'Figeac', value: 'Figeac' },
  { label: 'Figino', value: 'Figino' },
  { label: 'Figino Serenza', value: 'Figino Serenza' },
  { label: 'Figliaro', value: 'Figliaro' },
  { label: 'Figline Valdarno', value: 'Figline Valdarno' },
  { label: 'Figline Vegliaturo', value: 'Figline Vegliaturo' },
  { label: 'Fígols', value: 'Fígols' },
  { label: 'Fígols I Alinyà', value: 'Fígols I Alinyà' },
  { label: 'Figtree', value: 'Figtree' },
  { label: 'Figueira', value: 'Figueira' },
  { label: 'Figueira Da Foz', value: 'Figueira Da Foz' },
  { label: 'Figueira De Castelo Rodrigo', value: 'Figueira De Castelo Rodrigo' },
  { label: 'Figueira Do Guincho', value: 'Figueira Do Guincho' },
  { label: 'Figueirão', value: 'Figueirão' },
  { label: 'Figueiró', value: 'Figueiró' },
  { label: 'Figueiró Da Granja', value: 'Figueiró Da Granja' },
  { label: 'Figueiró Da Serra', value: 'Figueiró Da Serra' },
  { label: 'Figueiró Dos Vinhos', value: 'Figueiró Dos Vinhos' },
  { label: 'Figueirópolis', value: 'Figueirópolis' },
  { label: 'Figueirópolis Doeste', value: 'Figueirópolis Doeste' },
  { label: 'Figueiros', value: 'Figueiros' },
  { label: 'Figuera', value: 'Figuera' },
  { label: 'Figueres', value: 'Figueres' },
  { label: 'Figuerola Del Camp', value: 'Figuerola Del Camp' },
  { label: 'Figueroles', value: 'Figueroles' },
  { label: 'Figueruela De Arriba', value: 'Figueruela De Arriba' },
  { label: 'Figueruelas', value: 'Figueruelas' },
  { label: 'Figuig', value: 'Figuig' },
  { label: 'Fiji', value: 'Fiji' },
  { label: 'Fijnaart', value: 'Fijnaart' },
  { label: 'Fika', value: 'Fika' },
  { label: 'Fíki', value: 'Fíki' },
  { label: 'Filabusi', value: 'Filabusi' },
  { label: 'Filacciano', value: 'Filacciano' },
  { label: 'Filadelfia', value: 'Filadelfia' },
  { label: 'Filadelfia', value: 'Filadelfia' },
  { label: 'Filadelfia', value: 'Filadelfia' },
  { label: 'Filadélfia', value: 'Filadélfia' },
  { label: 'Filadélfia', value: 'Filadélfia' },
  { label: 'Filago', value: 'Filago' },
  { label: 'Fiľakovo', value: 'Fiľakovo' },
  { label: 'Filandari', value: 'Filandari' },
  { label: 'Filandia', value: 'Filandia' },
  { label: 'Filatovka', value: 'Filatovka' },
  { label: 'Filattiera', value: 'Filattiera' },
  { label: 'Filderstadt', value: 'Filderstadt' },
  { label: 'Fildu De Jos', value: 'Fildu De Jos' },
  { label: 'Filea', value: 'Filea' },
  { label: 'Filenkove', value: 'Filenkove' },
  { label: 'Filer', value: 'Filer' },
  { label: 'Filettino', value: 'Filettino' },
  { label: 'Filetto', value: 'Filetto' },
  { label: 'Filettole', value: 'Filettole' },
  { label: 'Filey', value: 'Filey' },
  { label: 'Fili', value: 'Fili' },
  { label: 'Filia', value: 'Filia' },
  { label: 'Filiano', value: 'Filiano' },
  { label: 'Filiaș', value: 'Filiaș' },
  { label: 'Filiaşi', value: 'Filiaşi' },
  { label: 'Filiátes', value: 'Filiátes' },
  { label: 'Filiatrá', value: 'Filiatrá' },
  { label: 'Filighera', value: 'Filighera' },
  { label: 'Filignano', value: 'Filignano' },
  { label: 'Filimonki', value: 'Filimonki' },
  { label: 'Filimonovo', value: 'Filimonovo' },
  { label: 'Filingué', value: 'Filingué' },
  { label: 'Filioara', value: 'Filioara' },
  { label: 'Filipeni', value: 'Filipeni' },
  { label: 'Filipeşti', value: 'Filipeşti' },
  { label: 'Filipeştii De Pădure', value: 'Filipeştii De Pădure' },
  { label: 'Filipeştii De Târg', value: 'Filipeştii De Târg' },
  { label: 'Filipów', value: 'Filipów' },
  { label: 'Filipowice', value: 'Filipowice' },
  { label: 'Filippiáda', value: 'Filippiáda' },
  { label: 'Filippovka', value: 'Filippovka' },
  { label: 'Filipstad', value: 'Filipstad' },
  { label: 'Fíliro', value: 'Fíliro' },
  { label: 'Filitelnic', value: 'Filitelnic' },
  { label: 'Fílla', value: 'Fílla' },
  { label: 'Fillan', value: 'Fillan' },
  { label: 'Fillé', value: 'Fillé' },
  { label: 'Fillinges', value: 'Fillinges' },
  { label: 'Fillmore', value: 'Fillmore' },
  { label: 'Fillmore', value: 'Fillmore' },
  { label: 'Fillmore County', value: 'Fillmore County' },
  { label: 'Fillmore County', value: 'Fillmore County' },
  { label: 'Fillýra', value: 'Fillýra' },
  { label: 'Filogaso', value: 'Filogaso' },
  { label: 'Filomeno Mata', value: 'Filomeno Mata' },
  { label: 'Filótas', value: 'Filótas' },
  { label: 'Filothéi', value: 'Filothéi' },
  { label: 'Filótion', value: 'Filótion' },
  { label: 'Filottrano', value: 'Filottrano' },
  { label: 'Filpișu Mare', value: 'Filpișu Mare' },
  { label: 'Filpișu Mic', value: 'Filpișu Mic' },
  { label: 'Filsum', value: 'Filsum' },
  { label: 'Filzmoos', value: 'Filzmoos' },
  { label: 'Fin', value: 'Fin' },
  { label: 'Finale', value: 'Finale' },
  { label: 'Finale Emilia', value: 'Finale Emilia' },
  { label: 'Finale Ligure', value: 'Finale Ligure' },
  { label: 'Fiñana', value: 'Fiñana' },
  { label: 'Finca Blanco', value: 'Finca Blanco' },
  { label: 'Fincastle', value: 'Fincastle' },
  { label: 'Fincastle', value: 'Fincastle' },
  { label: 'Finchampstead', value: 'Finchampstead' },
  { label: 'Findern', value: 'Findern' },
  { label: 'Finderne', value: 'Finderne' },
  { label: 'Fındıklı', value: 'Fındıklı' },
  { label: 'Findlay', value: 'Findlay' },
  { label: 'Findochty', value: 'Findochty' },
  { label: 'Findon', value: 'Findon' },
  { label: 'Findon', value: 'Findon' },
  { label: 'Finedon', value: 'Finedon' },
  { label: 'Fines', value: 'Fines' },
  { label: 'Finestrat', value: 'Finestrat' },
  { label: 'Fingal Bay', value: 'Fingal Bay' },
  { label: 'Fingal County', value: 'Fingal County' },
  { label: 'Finglas', value: 'Finglas' },
  { label: 'Finhan', value: 'Finhan' },
  { label: 'Finike', value: 'Finike' },
  { label: 'Finiş', value: 'Finiş' },
  { label: 'Finkenberg', value: 'Finkenberg' },
  { label: 'Finkenstein', value: 'Finkenstein' },
  { label: 'Finkenstein Am Faaker See', value: 'Finkenstein Am Faaker See' },
  { label: 'Finley', value: 'Finley' },
  { label: 'Finley', value: 'Finley' },
  { label: 'Finley', value: 'Finley' },
  { label: 'Finlyandskiy', value: 'Finlyandskiy' },
  { label: 'Finnentrop', value: 'Finnentrop' },
  { label: 'Finney County', value: 'Finney County' },
  { label: 'Finneytown', value: 'Finneytown' },
  { label: 'Finningen', value: 'Finningen' },
  { label: 'Finningley', value: 'Finningley' },
  { label: 'Finnøy', value: 'Finnøy' },
  { label: 'Finnsnes', value: 'Finnsnes' },
  { label: 'Fino Del Monte', value: 'Fino Del Monte' },
  { label: 'Fino Mornasco', value: 'Fino Mornasco' },
  { label: 'Finote Selam', value: 'Finote Selam' },
  { label: 'Finschhafen', value: 'Finschhafen' },
  { label: 'Finsing', value: 'Finsing' },
  { label: 'Finspång', value: 'Finspång' },
  { label: 'Finsterbergen', value: 'Finsterbergen' },
  { label: 'Finsterwalde', value: 'Finsterwalde' },
  { label: 'Finström', value: 'Finström' },
  { label: 'Finta Mare', value: 'Finta Mare' },
  { label: 'Fintel', value: 'Fintel' },
  { label: 'Finteușu Mare', value: 'Finteușu Mare' },
  { label: 'Finteușu Mic', value: 'Finteușu Mic' },
  { label: 'Fintona', value: 'Fintona' },
  { label: 'Fioletovo', value: 'Fioletovo' },
  { label: 'Fiorano', value: 'Fiorano' },
  { label: 'Fiorano Al Serio', value: 'Fiorano Al Serio' },
  { label: 'Fiorano Canavese', value: 'Fiorano Canavese' },
  { label: 'Fiorentino', value: 'Fiorentino' },
  { label: 'Fiorenzuola Darda', value: 'Fiorenzuola Darda' },
  { label: 'Firá', value: 'Firá' },
  { label: 'Firavitoba', value: 'Firavitoba' },
  { label: 'Fircrest', value: 'Fircrest' },
  { label: 'Firebaugh', value: 'Firebaugh' },
  { label: 'Firenzuola', value: 'Firenzuola' },
  { label: 'Firestone', value: 'Firestone' },
  { label: 'Firgas', value: 'Firgas' },
  { label: 'Firhouse', value: 'Firhouse' },
  { label: 'Firing Range', value: 'Firing Range' },
  { label: 'Firiza', value: 'Firiza' },
  { label: 'Firle', value: 'Firle' },
  { label: 'Firlej', value: 'Firlej' },
  { label: 'Firmat', value: 'Firmat' },
  { label: 'Firmi', value: 'Firmi' },
  { label: 'Firmino Alves', value: 'Firmino Alves' },
  { label: 'Firminópolis', value: 'Firminópolis' },
  { label: 'Firminy', value: 'Firminy' },
  { label: 'Firmo', value: 'Firmo' },
  { label: 'Firouz Abad', value: 'Firouz Abad' },
  { label: 'Firouzeh', value: 'Firouzeh' },
  { label: 'Firovo', value: 'Firovo' },
  { label: 'Firovskiy Rayon', value: 'Firovskiy Rayon' },
  { label: 'Firozabad', value: 'Firozabad' },
  { label: 'Firozpur', value: 'Firozpur' },
  { label: 'Firozpur District', value: 'Firozpur District' },
  { label: 'Firozpur Jhirka', value: 'Firozpur Jhirka' },
  { label: 'Firsanovka', value: 'Firsanovka' },
  { label: 'First Mesa', value: 'First Mesa' },
  { label: 'Firthcliffe', value: 'Firthcliffe' },
  { label: 'Firuraq', value: 'Firuraq' },
  { label: 'Firuzabad', value: 'Firuzabad' },
  { label: 'Firuzabad-E Sofla', value: 'Firuzabad-E Sofla' },
  { label: 'Firuzan', value: 'Firuzan' },
  { label: 'Firuzeh', value: 'Firuzeh' },
  { label: 'Firuzkuh', value: 'Firuzkuh' },
  { label: 'Fiscal', value: 'Fiscal' },
  { label: 'Fischach', value: 'Fischach' },
  { label: 'Fischamend-Markt', value: 'Fischamend-Markt' },
  { label: 'Fischbach', value: 'Fischbach' },
  { label: 'Fischbach', value: 'Fischbach' },
  { label: 'Fischbach', value: 'Fischbach' },
  { label: 'Fischbachau', value: 'Fischbachau' },
  { label: 'Fischerbach', value: 'Fischerbach' },
  { label: 'Fisching', value: 'Fisching' },
  { label: 'Fischingen', value: 'Fischingen' },
  { label: 'Fisciano', value: 'Fisciano' },
  { label: 'Fișer', value: 'Fișer' },
  { label: 'Fish Hawk', value: 'Fish Hawk' },
  { label: 'Fish Lake', value: 'Fish Lake' },
  { label: 'Fish Town', value: 'Fish Town' },
  { label: 'Fishburn', value: 'Fishburn' },
  { label: 'Fisher', value: 'Fisher' },
  { label: 'Fisher', value: 'Fisher' },
  { label: 'Fisher County', value: 'Fisher County' },
  { label: 'Fishers', value: 'Fishers' },
  { label: 'Fishersville', value: 'Fishersville' },
  { label: 'Fishguard', value: 'Fishguard' },
  { label: 'Fishhook', value: 'Fishhook' },
  { label: 'Fishkill', value: 'Fishkill' },
  { label: 'Fishtoft', value: 'Fishtoft' },
  { label: 'Fiskdale', value: 'Fiskdale' },
  { label: 'Fisksätra', value: 'Fisksätra' },
  { label: 'Fismes', value: 'Fismes' },
  { label: 'Fiss', value: 'Fiss' },
  { label: 'Fisterra', value: 'Fisterra' },
  { label: 'Fițcău', value: 'Fițcău' },
  { label: 'Fitchburg', value: 'Fitchburg' },
  { label: 'Fitchburg', value: 'Fitchburg' },
  { label: 'Fitero', value: 'Fitero' },
  { label: 'Fitilieu', value: 'Fitilieu' },
  { label: 'Fitioneşti', value: 'Fitioneşti' },
  { label: 'Fitjar', value: 'Fitjar' },
  { label: 'Fittja', value: 'Fittja' },
  { label: 'Fitzgerald', value: 'Fitzgerald' },
  { label: 'Fitzgibbon', value: 'Fitzgibbon' },
  { label: 'Fitz-James', value: 'Fitz-James' },
  { label: 'Fitzroy', value: 'Fitzroy' },
  { label: 'Fitzroy Crossing', value: 'Fitzroy Crossing' },
  { label: 'Fitzroy North', value: 'Fitzroy North' },
  { label: 'Fitzwilliam', value: 'Fitzwilliam' },
  { label: 'Fitzwilliam', value: 'Fitzwilliam' },
  { label: 'Fiuggi', value: 'Fiuggi' },
  { label: 'Fiumalbo-Dogana', value: 'Fiumalbo-Dogana' },
  { label: 'Fiumana', value: 'Fiumana' },
  { label: 'Fiumara', value: 'Fiumara' },
  { label: 'Fiume Veneto', value: 'Fiume Veneto' },
  { label: 'Fiumedinisi', value: 'Fiumedinisi' },
  { label: 'Fiumefreddo Bruzio', value: 'Fiumefreddo Bruzio' },
  { label: 'Fiumefreddo Sicilia', value: 'Fiumefreddo Sicilia' },
  { label: 'Fiumicello', value: 'Fiumicello' },
  { label: 'Fiumicino-Isola Sacra', value: 'Fiumicino-Isola Sacra' },
  { label: 'Five Corners', value: 'Five Corners' },
  { label: 'Five Dock', value: 'Five Dock' },
  { label: 'Five Forks', value: 'Five Forks' },
  { label: 'Five Oak Green', value: 'Five Oak Green' },
  { label: 'Five Points', value: 'Five Points' },
  { label: 'Five Points', value: 'Five Points' },
  { label: 'Fivemiletown', value: 'Fivemiletown' },
  { label: 'Fivepointville', value: 'Fivepointville' },
  { label: 'Fivizzano', value: 'Fivizzano' },
  { label: 'Fizeș', value: 'Fizeș' },
  { label: 'Fizeşu Gherlii', value: 'Fizeşu Gherlii' },
  { label: 'Fizuli', value: 'Fizuli' },
  { label: 'Fizzonasco', value: 'Fizzonasco' },
  { label: 'Fjaler', value: 'Fjaler' },
  { label: 'Fjälkinge', value: 'Fjälkinge' },
  { label: 'Fjallabyggð', value: 'Fjallabyggð' },
  { label: 'Fjärås Kyrkby', value: 'Fjärås Kyrkby' },
  { label: 'Fjarðabyggð', value: 'Fjarðabyggð' },
  { label: 'Fjell', value: 'Fjell' },
  { label: 'Fjellfoten', value: 'Fjellfoten' },
  { label: 'Fjerritslev', value: 'Fjerritslev' },
  { label: 'Fjugesta', value: 'Fjugesta' },
  { label: 'Flå', value: 'Flå' },
  { label: 'Flaçà', value: 'Flaçà' },
  { label: 'Flachau', value: 'Flachau' },
  { label: 'Flachslanden', value: 'Flachslanden' },
  { label: 'Flacht', value: 'Flacht' },
  { label: 'Fladnitz Im Raabtal', value: 'Fladnitz Im Raabtal' },
  { label: 'Fladungen', value: 'Fladungen' },
  { label: 'Flagami', value: 'Flagami' },
  { label: 'Flagler Beach', value: 'Flagler Beach' },
  { label: 'Flagler County', value: 'Flagler County' },
  { label: 'Flagler Estates', value: 'Flagler Estates' },
  { label: 'Flagstaff', value: 'Flagstaff' },
  { label: 'Flagstaff', value: 'Flagstaff' },
  { label: 'Flagstaff Hill', value: 'Flagstaff Hill' },
  { label: 'Flaibano', value: 'Flaibano' },
  { label: 'Flakstad', value: 'Flakstad' },
  { label: 'Flamanville', value: 'Flamanville' },
  { label: 'Flămânzi', value: 'Flămânzi' },
  { label: 'Flamatt', value: 'Flamatt' },
  { label: 'Flamborough', value: 'Flamborough' },
  { label: 'Flammersfeld', value: 'Flammersfeld' },
  { label: 'Flamstead', value: 'Flamstead' },
  { label: 'Flanagan', value: 'Flanagan' },
  { label: 'Flanders', value: 'Flanders' },
  { label: 'Flandes', value: 'Flandes' },
  { label: 'Flandreau', value: 'Flandreau' },
  { label: 'Flankers', value: 'Flankers' },
  { label: 'Flassans-Sur-Issole', value: 'Flassans-Sur-Issole' },
  { label: 'Flat River', value: 'Flat River' },
  { label: 'Flat Rock', value: 'Flat Rock' },
  { label: 'Flat Rock', value: 'Flat Rock' },
  { label: 'Flatanger', value: 'Flatanger' },
  { label: 'Flatbush', value: 'Flatbush' },
  { label: 'Flateby', value: 'Flateby' },
  { label: 'Flathead County', value: 'Flathead County' },
  { label: 'Flatlands', value: 'Flatlands' },
  { label: 'Flatonia', value: 'Flatonia' },
  { label: 'Flatschach', value: 'Flatschach' },
  { label: 'Flattach', value: 'Flattach' },
  { label: 'Flatts', value: 'Flatts' },
  { label: 'Flatwoods', value: 'Flatwoods' },
  { label: 'Flaurling', value: 'Flaurling' },
  { label: 'Flaviac', value: 'Flaviac' },
  { label: 'Flavigny-Sur-Moselle', value: 'Flavigny-Sur-Moselle' },
  { label: 'Flavin', value: 'Flavin' },
  { label: 'Flavon', value: 'Flavon' },
  { label: 'Flavy-Le-Martel', value: 'Flavy-Le-Martel' },
  { label: 'Flawil', value: 'Flawil' },
  { label: 'Flaxlanden', value: 'Flaxlanden' },
  { label: 'Flaxweiler', value: 'Flaxweiler' },
  { label: 'Flayosc', value: 'Flayosc' },
  { label: 'Fléac', value: 'Fléac' },
  { label: 'Fleccia-Chianavasso', value: 'Fleccia-Chianavasso' },
  { label: 'Flechtingen', value: 'Flechtingen' },
  { label: 'Fleckeby', value: 'Fleckeby' },
  { label: 'Fleckney', value: 'Fleckney' },
  { label: 'Fleet', value: 'Fleet' },
  { label: 'Fleetwood', value: 'Fleetwood' },
  { label: 'Fleetwood', value: 'Fleetwood' },
  { label: 'Flein', value: 'Flein' },
  { label: 'Flekkefjord', value: 'Flekkefjord' },
  { label: 'Flémalle-Haute', value: 'Flémalle-Haute' },
  { label: 'Fleming County', value: 'Fleming County' },
  { label: 'Fleming Island', value: 'Fleming Island' },
  { label: 'Flemingsburg', value: 'Flemingsburg' },
  { label: 'Flemington', value: 'Flemington' },
  { label: 'Flemington', value: 'Flemington' },
  { label: 'Flemington', value: 'Flemington' },
  { label: 'Flen', value: 'Flen' },
  { label: 'Flensburg', value: 'Flensburg' },
  { label: 'Flero', value: 'Flero' },
  { label: 'Fléron', value: 'Fléron' },
  { label: 'Flers', value: 'Flers' },
  { label: 'Flers-En-Escrebieux', value: 'Flers-En-Escrebieux' },
  { label: 'Flesberg', value: 'Flesberg' },
  { label: 'Flessau', value: 'Flessau' },
  { label: 'Flesselles', value: 'Flesselles' },
  { label: 'Fletcher', value: 'Fletcher' },
  { label: 'Fletcher', value: 'Fletcher' },
  { label: 'Fletcher', value: 'Fletcher' },
  { label: 'Fletchers Land', value: 'Fletchers Land' },
  { label: 'Fleur De Lys', value: 'Fleur De Lys' },
  { label: 'Fleurance', value: 'Fleurance' },
  { label: 'Fleurbaix', value: 'Fleurbaix' },
  { label: 'Fleurey-Sur-Ouche', value: 'Fleurey-Sur-Ouche' },
  { label: 'Fleurie', value: 'Fleurie' },
  { label: 'Fleurier', value: 'Fleurier' },
  { label: 'Fleurieu-Sur-Saône', value: 'Fleurieu-Sur-Saône' },
  { label: 'Fleurines', value: 'Fleurines' },
  { label: 'Fleurus', value: 'Fleurus' },
  { label: 'Fleury', value: 'Fleury' },
  { label: 'Fleury', value: 'Fleury' },
  { label: 'Fleury-La-Vallée', value: 'Fleury-La-Vallée' },
  { label: 'Fleury-Les-Aubrais', value: 'Fleury-Les-Aubrais' },
  { label: 'Fleury-Mérogis', value: 'Fleury-Mérogis' },
  { label: 'Fleury-Sur-Andelle', value: 'Fleury-Sur-Andelle' },
  { label: 'Fleury-Sur-Orne', value: 'Fleury-Sur-Orne' },
  { label: 'Fléville-Devant-Nancy', value: 'Fléville-Devant-Nancy' },
  { label: 'Flevowijk', value: 'Flevowijk' },
  { label: 'Flexbury', value: 'Flexbury' },
  { label: 'Flexeiras', value: 'Flexeiras' },
  { label: 'Flic En Flac', value: 'Flic En Flac' },
  { label: 'Flieden', value: 'Flieden' },
  { label: 'Fliess', value: 'Fliess' },
  { label: 'Flimby', value: 'Flimby' },
  { label: 'Flims', value: 'Flims' },
  { label: 'Flimwell', value: 'Flimwell' },
  { label: 'Flin Flon', value: 'Flin Flon' },
  { label: 'Flinders', value: 'Flinders' },
  { label: 'Flinders', value: 'Flinders' },
  { label: 'Flinders', value: 'Flinders' },
  { label: 'Flinders Park', value: 'Flinders Park' },
  { label: 'Flinders Ranges', value: 'Flinders Ranges' },
  { label: 'Flinders View', value: 'Flinders View' },
  { label: 'Flines-Lès-Mortagne', value: 'Flines-Lès-Mortagne' },
  { label: 'Flines-Lez-Raches', value: 'Flines-Lez-Raches' },
  { label: 'Flins-Sur-Seine', value: 'Flins-Sur-Seine' },
  { label: 'Flint', value: 'Flint' },
  { label: 'Flint', value: 'Flint' },
  { label: 'Flint City', value: 'Flint City' },
  { label: 'Flint River', value: 'Flint River' },
  { label: 'Flintbek', value: 'Flintbek' },
  { label: 'Flintsbach', value: 'Flintsbach' },
  { label: 'Flippin', value: 'Flippin' },
  { label: 'Flirsch', value: 'Flirsch' },
  { label: 'Flitwick', value: 'Flitwick' },
  { label: 'Flix', value: 'Flix' },
  { label: 'Flixecourt', value: 'Flixecourt' },
  { label: 'Flize', value: 'Flize' },
  { label: 'Fljótsdalshérað', value: 'Fljótsdalshérað' },
  { label: 'Fljótsdalshreppur', value: 'Fljótsdalshreppur' },
  { label: 'Flóahreppur', value: 'Flóahreppur' },
  { label: 'Flobecq', value: 'Flobecq' },
  { label: 'Floby', value: 'Floby' },
  { label: 'Flockton', value: 'Flockton' },
  { label: 'Flogny-La-Chapelle', value: 'Flogny-La-Chapelle' },
  { label: 'Flöha', value: 'Flöha' },
  { label: 'Floing', value: 'Floing' },
  { label: 'Floing', value: 'Floing' },
  { label: 'Floirac', value: 'Floirac' },
  { label: 'Floissac/Marc', value: 'Floissac/Marc' },
  { label: 'Flomaton', value: 'Flomaton' },
  { label: 'Flomborn', value: 'Flomborn' },
  { label: 'Fløng', value: 'Fløng' },
  { label: 'Flonheim', value: 'Flonheim' },
  { label: 'Flookburgh', value: 'Flookburgh' },
  { label: 'Flor Azul', value: 'Flor Azul' },
  { label: 'Flor Batavia', value: 'Flor Batavia' },
  { label: 'Flor Da Serra Do Sul', value: 'Flor Da Serra Do Sul' },
  { label: 'Flor De Cacao', value: 'Flor De Cacao' },
  { label: 'Flor De Maroñas', value: 'Flor De Maroñas' },
  { label: 'Flor Do Sertão', value: 'Flor Do Sertão' },
  { label: 'Flora', value: 'Flora' },
  { label: 'Flora', value: 'Flora' },
  { label: 'Flora', value: 'Flora' },
  { label: 'Flora', value: 'Flora' },
  { label: 'Flora', value: 'Flora' },
  { label: 'Flora', value: 'Flora' },
  { label: 'Flora Hill', value: 'Flora Hill' },
  { label: 'Flora Rica', value: 'Flora Rica' },
  { label: 'Flora Vista', value: 'Flora Vista' },
  { label: 'Florac', value: 'Florac' },
  { label: 'Floraí', value: 'Floraí' },
  { label: 'Floral City', value: 'Floral City' },
  { label: 'Floral Park', value: 'Floral Park' },
  { label: 'Florala', value: 'Florala' },
  { label: 'Florange', value: 'Florange' },
  { label: 'Florânia', value: 'Florânia' },
  { label: 'Floraville', value: 'Floraville' },
  { label: 'Flore', value: 'Flore' },
  { label: 'Floreal', value: 'Floreal' },
  { label: 'Floreal', value: 'Floreal' },
  { label: 'Floreat', value: 'Floreat' },
  { label: 'Floreffe', value: 'Floreffe' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Florence County', value: 'Florence County' },
  { label: 'Florence County', value: 'Florence County' },
  { label: 'Florence-Graham', value: 'Florence-Graham' },
  { label: 'Florenceville-Bristol', value: 'Florenceville-Bristol' },
  { label: 'Florencia', value: 'Florencia' },
  { label: 'Florencia', value: 'Florencia' },
  { label: 'Florencia', value: 'Florencia' },
  { label: 'Florencia', value: 'Florencia' },
  { label: 'Florencio Sánchez', value: 'Florencio Sánchez' },
  { label: 'Florencio Villarreal', value: 'Florencio Villarreal' },
  { label: 'Florennes', value: 'Florennes' },
  { label: 'Florensac', value: 'Florensac' },
  { label: 'Florentino Ameghino', value: 'Florentino Ameghino' },
  { label: 'Florenville', value: 'Florenville' },
  { label: 'Flores', value: 'Flores' },
  { label: 'Flores', value: 'Flores' },
  { label: 'Flores', value: 'Flores' },
  { label: 'Flores', value: 'Flores' },
  { label: 'Flores', value: 'Flores' },
  { label: 'Flores Costa Cuca', value: 'Flores Costa Cuca' },
  { label: 'Flores Da Cunha', value: 'Flores Da Cunha' },
  { label: 'Flores De Ávila', value: 'Flores De Ávila' },
  { label: 'Flores De Goiás', value: 'Flores De Goiás' },
  { label: 'Flores Do Piauí', value: 'Flores Do Piauí' },
  { label: 'Floresta', value: 'Floresta' },
  { label: 'Floresta', value: 'Floresta' },
  { label: 'Floresta', value: 'Floresta' },
  { label: 'Floresta', value: 'Floresta' },
  { label: 'Floresta Azul', value: 'Floresta Azul' },
  { label: 'Floresta Do Araguaia', value: 'Floresta Do Araguaia' },
  { label: 'Floresta Do Piauí', value: 'Floresta Do Piauí' },
  { label: 'Floresta La', value: 'Floresta La' },
  { label: 'Florestal', value: 'Florestal' },
  { label: 'Floreşti', value: 'Floreşti' },
  { label: 'Floreşti', value: 'Floreşti' },
  { label: 'Floreşti', value: 'Floreşti' },
  { label: 'Floreşti', value: 'Floreşti' },
  { label: 'Florești', value: 'Florești' },
  { label: 'Florești', value: 'Florești' },
  { label: 'Florestópolis', value: 'Florestópolis' },
  { label: 'Floresville', value: 'Floresville' },
  { label: 'Florey', value: 'Florey' },
  { label: 'Florham Park', value: 'Florham Park' },
  { label: 'Florián', value: 'Florián' },
  { label: 'Floriana', value: 'Floriana' },
  { label: 'Floriano', value: 'Floriano' },
  { label: 'Floriano Peixoto', value: 'Floriano Peixoto' },
  { label: 'Florianópolis', value: 'Florianópolis' },
  { label: 'Florica', value: 'Florica' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Flórida', value: 'Flórida' },
  { label: 'Florida City', value: 'Florida City' },
  { label: 'Florida De Liébana', value: 'Florida De Liébana' },
  { label: 'Flórida Paulista', value: 'Flórida Paulista' },
  { label: 'Florida Ridge', value: 'Florida Ridge' },
  { label: 'Floridablanca', value: 'Floridablanca' },
  { label: 'Floridablanca', value: 'Floridablanca' },
  { label: 'Floridablanca', value: 'Floridablanca' },
  { label: 'Floridia', value: 'Floridia' },
  { label: 'Floridsdorf', value: 'Floridsdorf' },
  { label: 'Florin', value: 'Florin' },
  { label: 'Flórina', value: 'Flórina' },
  { label: 'Florinas', value: 'Florinas' },
  { label: 'Florínea', value: 'Florínea' },
  { label: 'Floris', value: 'Floris' },
  { label: 'Florissant', value: 'Florissant' },
  { label: 'Florø', value: 'Florø' },
  { label: 'Floroaia', value: 'Floroaia' },
  { label: 'Flörsheim', value: 'Flörsheim' },
  { label: 'Florstadt', value: 'Florstadt' },
  { label: 'Floß', value: 'Floß' },
  { label: 'Flossenbürg', value: 'Flossenbürg' },
  { label: 'Flossmoor', value: 'Flossmoor' },
  { label: 'Flöthe', value: 'Flöthe' },
  { label: 'Flourens', value: 'Flourens' },
  { label: 'Flourtown', value: 'Flourtown' },
  { label: 'Flower Hill', value: 'Flower Hill' },
  { label: 'Flower Hill', value: 'Flower Hill' },
  { label: 'Flower Mound', value: 'Flower Mound' },
  { label: 'Flowery Branch', value: 'Flowery Branch' },
  { label: 'Flowing Wells', value: 'Flowing Wells' },
  { label: 'Flowood', value: 'Flowood' },
  { label: 'Floyd', value: 'Floyd' },
  { label: 'Floyd County', value: 'Floyd County' },
  { label: 'Floyd County', value: 'Floyd County' },
  { label: 'Floyd County', value: 'Floyd County' },
  { label: 'Floyd County', value: 'Floyd County' },
  { label: 'Floyd County', value: 'Floyd County' },
  { label: 'Floyd County', value: 'Floyd County' },
  { label: 'Floydada', value: 'Floydada' },
  { label: 'Flüelen', value: 'Flüelen' },
  { label: 'Flühli', value: 'Flühli' },
  { label: 'Flumeri', value: 'Flumeri' },
  { label: 'Fluminimaggiore', value: 'Fluminimaggiore' },
  { label: 'Flums', value: 'Flums' },
  { label: 'Flurlingen', value: 'Flurlingen' },
  { label: 'Flushing', value: 'Flushing' },
  { label: 'Flussio', value: 'Flussio' },
  { label: 'Fluvanna County', value: 'Fluvanna County' },
  { label: 'Flying Hills', value: 'Flying Hills' },
  { label: 'Flynn', value: 'Flynn' },
  { label: 'Fnidek', value: 'Fnidek' },
  { label: 'Foam Lake', value: 'Foam Lake' },
  { label: 'Foard County', value: 'Foard County' },
  { label: 'Fobello', value: 'Fobello' },
  { label: 'Fobes Hill', value: 'Fobes Hill' },
  { label: 'Foča', value: 'Foča' },
  { label: 'Foça', value: 'Foça' },
  { label: 'Focene', value: 'Focene' },
  { label: 'Fochabers', value: 'Fochabers' },
  { label: 'Fochriw', value: 'Fochriw' },
  { label: 'Fochville', value: 'Fochville' },
  { label: 'Fockbek', value: 'Fockbek' },
  { label: 'Focșani', value: 'Focșani' },
  { label: 'Focuri', value: 'Focuri' },
  { label: 'Föderlach I', value: 'Föderlach I' },
  { label: 'Foëcy', value: 'Foëcy' },
  { label: 'Foeni', value: 'Foeni' },
  { label: 'Fogars De La Selva', value: 'Fogars De La Selva' },
  { label: 'Fogars De Montclús', value: 'Fogars De Montclús' },
  { label: 'Foggia', value: 'Foggia' },
  { label: 'Foglaş', value: 'Foglaş' },
  { label: 'Foglianise', value: 'Foglianise' },
  { label: 'Fogliano', value: 'Fogliano' },
  { label: 'Fogliano', value: 'Fogliano' },
  { label: 'Foglizzo', value: 'Foglizzo' },
  { label: 'Föglö', value: 'Föglö' },
  { label: 'Fognano', value: 'Fognano' },
  { label: 'Fogo Island', value: 'Fogo Island' },
  { label: 'Fohnsdorf', value: 'Fohnsdorf' },
  { label: 'Fohorem', value: 'Fohorem' },
  { label: 'Föhren', value: 'Föhren' },
  { label: 'Foi', value: 'Foi' },
  { label: 'Foiano Della Chiana', value: 'Foiano Della Chiana' },
  { label: 'Foiano Di Val Fortore', value: 'Foiano Di Val Fortore' },
  { label: 'Foieni', value: 'Foieni' },
  { label: 'Foindu', value: 'Foindu' },
  { label: 'Foios', value: 'Foios' },
  { label: 'Fóios', value: 'Fóios' },
  { label: 'Foissiat', value: 'Foissiat' },
  { label: 'Foix', value: 'Foix' },
  { label: 'Foixà', value: 'Foixà' },
  { label: 'Fojnica', value: 'Fojnica' },
  { label: 'Foki', value: 'Foki' },
  { label: 'Fokino', value: 'Fokino' },
  { label: 'Folcroft', value: 'Folcroft' },
  { label: 'Földeák', value: 'Földeák' },
  { label: 'Földes', value: 'Földes' },
  { label: 'Folégandros', value: 'Folégandros' },
  { label: 'Folembray', value: 'Folembray' },
  { label: 'Foley', value: 'Foley' },
  { label: 'Foley', value: 'Foley' },
  { label: 'Folgaria', value: 'Folgaria' },
  { label: 'Folgosinho', value: 'Folgosinho' },
  { label: 'Folgoso De La Ribera', value: 'Folgoso De La Ribera' },
  { label: 'Folgoso Do Courel', value: 'Folgoso Do Courel' },
  { label: 'Folgueroles', value: 'Folgueroles' },
  { label: 'Folhadosa', value: 'Folhadosa' },
  { label: 'Folignano', value: 'Folignano' },
  { label: 'Foligno', value: 'Foligno' },
  { label: 'Folkestone', value: 'Folkestone' },
  { label: 'Folkling', value: 'Folkling' },
  { label: 'Folkston', value: 'Folkston' },
  { label: 'Follainville-Dennemont', value: 'Follainville-Dennemont' },
  { label: 'Follansbee', value: 'Follansbee' },
  { label: 'Folldal', value: 'Folldal' },
  { label: 'Follina', value: 'Follina' },
  { label: 'Follonica', value: 'Follonica' },
  { label: 'Folly Beach', value: 'Folly Beach' },
  { label: 'Folon', value: 'Folon' },
  { label: 'Folschviller', value: 'Folschviller' },
  { label: 'Folsom', value: 'Folsom' },
  { label: 'Folsom', value: 'Folsom' },
  { label: 'Folsom', value: 'Folsom' },
  { label: 'Folteşti', value: 'Folteşti' },
  { label: 'Folzano', value: 'Folzano' },
  { label: 'Fombellida', value: 'Fombellida' },
  { label: 'Fombio', value: 'Fombio' },
  { label: 'Fomboni', value: 'Fomboni' },
  { label: 'Fombuena', value: 'Fombuena' },
  { label: 'Fomento', value: 'Fomento' },
  { label: 'Fomeque', value: 'Fomeque' },
  { label: 'Fompedraza', value: 'Fompedraza' },
  { label: 'Fonadhoo', value: 'Fonadhoo' },
  { label: 'Fonbeauzard', value: 'Fonbeauzard' },
  { label: 'Foncea', value: 'Foncea' },
  { label: 'Fond Assau/Babonneau', value: 'Fond Assau/Babonneau' },
  { label: 'Fond Bassin Bleu', value: 'Fond Bassin Bleu' },
  { label: 'Fond Berange', value: 'Fond Berange' },
  { label: 'Fond Bernier', value: 'Fond Bernier' },
  { label: 'Fond Cacoa', value: 'Fond Cacoa' },
  { label: 'Fond Canie', value: 'Fond Canie' },
  { label: 'Fond Capeche', value: 'Fond Capeche' },
  { label: 'Fond Des Blancs', value: 'Fond Des Blancs' },
  { label: 'Fond Dlor/Dugard', value: 'Fond Dlor/Dugard' },
  { label: 'Fond Doux', value: 'Fond Doux' },
  { label: 'Fond Du Lac', value: 'Fond Du Lac' },
  { label: 'Fond Du Lac County', value: 'Fond Du Lac County' },
  { label: 'Fond Du Sac', value: 'Fond Du Sac' },
  { label: 'Fond Estate', value: 'Fond Estate' },
  { label: 'Fond Gens Libre', value: 'Fond Gens Libre' },
  { label: 'Fond Parisien', value: 'Fond Parisien' },
  { label: 'Fond Sabot', value: 'Fond Sabot' },
  { label: 'Fond/Desruisseaux', value: 'Fond/Desruisseaux' },
  { label: 'Fonda', value: 'Fonda' },
  { label: 'Fondachelli-Fantina', value: 'Fondachelli-Fantina' },
  { label: 'Fondarella', value: 'Fondarella' },
  { label: 'Fondettes', value: 'Fondettes' },
  { label: 'Fondi', value: 'Fondi' },
  { label: 'Fondo', value: 'Fondo' },
  { label: 'Fondó De Les Neus El/Hondón De Las Nieves', value: 'Fondó De Les Neus El/Hondón De Las Nieves' },
  { label: 'Fondón', value: 'Fondón' },
  { label: 'Fonds Verrettes', value: 'Fonds Verrettes' },
  { label: 'Fonelas', value: 'Fonelas' },
  { label: 'Fonfría', value: 'Fonfría' },
  { label: 'Foni Bondali', value: 'Foni Bondali' },
  { label: 'Foni Brefet', value: 'Foni Brefet' },
  { label: 'Foni Jarrol', value: 'Foni Jarrol' },
  { label: 'Foni Kansala', value: 'Foni Kansala' },
  { label: 'Fonni', value: 'Fonni' },
  { label: 'Fonollosa', value: 'Fonollosa' },
  { label: 'Fonoton Municipality', value: 'Fonoton Municipality' },
  { label: 'Fonsagrada A', value: 'Fonsagrada A' },
  { label: 'Fonseca', value: 'Fonseca' },
  { label: 'Fonsorbes', value: 'Fonsorbes' },
  { label: 'Font De La Figuera La', value: 'Font De La Figuera La' },
  { label: 'Font Den Carròs La', value: 'Font Den Carròs La' },
  { label: 'Font Hill', value: 'Font Hill' },
  { label: 'Fontaine', value: 'Fontaine' },
  { label: 'Fontaine-Au-Pire', value: 'Fontaine-Au-Pire' },
  { label: 'Fontainebleau', value: 'Fontainebleau' },
  { label: 'Fontaine-Étoupefour', value: 'Fontaine-Étoupefour' },
  { label: 'Fontaine-La-Guyon', value: 'Fontaine-La-Guyon' },
  { label: 'Fontaine-La-Mallet', value: 'Fontaine-La-Mallet' },
  { label: 'Fontaine-Le-Bourg', value: 'Fontaine-Le-Bourg' },
  { label: 'Fontaine-Le-Comte', value: 'Fontaine-Le-Comte' },
  { label: 'Fontaine-Le-Dun', value: 'Fontaine-Le-Dun' },
  { label: 'Fontaine-Lès-Dijon', value: 'Fontaine-Lès-Dijon' },
  { label: 'Fontaine-Lès-Luxeuil', value: 'Fontaine-Lès-Luxeuil' },
  { label: 'Fontainemelon', value: 'Fontainemelon' },
  { label: 'Fontainemore', value: 'Fontainemore' },
  { label: 'Fontaine-Notre-Dame', value: 'Fontaine-Notre-Dame' },
  { label: 'Fontaines', value: 'Fontaines' },
  { label: 'Fontaines-Saint-Martin', value: 'Fontaines-Saint-Martin' },
  { label: 'Fontaines-Sur-Saône', value: 'Fontaines-Sur-Saône' },
  { label: 'Fontana', value: 'Fontana' },
  { label: 'Fontana', value: 'Fontana' },
  { label: 'Fontana', value: 'Fontana' },
  { label: 'Fontana', value: 'Fontana' },
  { label: 'Fontana Delle Monache', value: 'Fontana Delle Monache' },
  { label: 'Fontana Nuova-Bevia', value: 'Fontana Nuova-Bevia' },
  { label: 'Fontanafredda', value: 'Fontanafredda' },
  { label: 'Fontanals De Cerdanya', value: 'Fontanals De Cerdanya' },
  { label: 'Fontanar', value: 'Fontanar' },
  { label: 'Fontanarejo', value: 'Fontanarejo' },
  { label: 'Fontanarosa', value: 'Fontanarosa' },
  { label: 'Fontanars Dels Alforins', value: 'Fontanars Dels Alforins' },
  { label: 'Fontanelas', value: 'Fontanelas' },
  { label: 'Fontanelas', value: 'Fontanelas' },
  { label: 'Fontanelice', value: 'Fontanelice' },
  { label: 'Fontanella', value: 'Fontanella' },
  { label: 'Fontanella-Ozino', value: 'Fontanella-Ozino' },
  { label: 'Fontanellato', value: 'Fontanellato' },
  { label: 'Fontanelle', value: 'Fontanelle' },
  { label: 'Fontaneto Dagogna', value: 'Fontaneto Dagogna' },
  { label: 'Fontanetto Po', value: 'Fontanetto Po' },
  { label: 'Fontane-Zurane-Gresine', value: 'Fontane-Zurane-Gresine' },
  { label: 'Fontanigorda', value: 'Fontanigorda' },
  { label: 'Fontanil-Cornillon', value: 'Fontanil-Cornillon' },
  { label: 'Fontanile', value: 'Fontanile' },
  { label: 'Fontanilles', value: 'Fontanilles' },
  { label: 'Fontaniva', value: 'Fontaniva' },
  { label: 'Fontanka', value: 'Fontanka' },
  { label: 'Fontannes', value: 'Fontannes' },
  { label: 'Fontany', value: 'Fontany' },
  { label: 'Fontcoberta', value: 'Fontcoberta' },
  { label: 'Fontcouverte', value: 'Fontcouverte' },
  { label: 'Fonte Bastardo', value: 'Fonte Bastardo' },
  { label: 'Fonte Boa', value: 'Fonte Boa' },
  { label: 'Fonte Longa', value: 'Fonte Longa' },
  { label: 'Fonte Umano-San Martino Alta', value: 'Fonte Umano-San Martino Alta' },
  { label: 'Fonteblanda', value: 'Fonteblanda' },
  { label: 'Fontecchio', value: 'Fontecchio' },
  { label: 'Fontechiari', value: 'Fontechiari' },
  { label: 'Fontegreca', value: 'Fontegreca' },
  { label: 'Fontellas', value: 'Fontellas' },
  { label: 'Fontem', value: 'Fontem' },
  { label: 'Fontenais', value: 'Fontenais' },
  { label: 'Fontenay', value: 'Fontenay' },
  { label: 'Fontenay-Aux-Roses', value: 'Fontenay-Aux-Roses' },
  { label: 'Fontenay-En-Parisis', value: 'Fontenay-En-Parisis' },
  { label: 'Fontenay-Le-Comte', value: 'Fontenay-Le-Comte' },
  { label: 'Fontenay-Le-Fleury', value: 'Fontenay-Le-Fleury' },
  { label: 'Fontenay-Le-Marmion', value: 'Fontenay-Le-Marmion' },
  { label: 'Fontenay-Lès-Briis', value: 'Fontenay-Lès-Briis' },
  { label: 'Fontenay-Sous-Bois', value: 'Fontenay-Sous-Bois' },
  { label: 'Fontenay-Sur-Loing', value: 'Fontenay-Sur-Loing' },
  { label: 'Fontenay-Trésigny', value: 'Fontenay-Trésigny' },
  { label: 'Fontenilles', value: 'Fontenilles' },
  { label: 'Fonteno', value: 'Fonteno' },
  { label: 'Fontevivo', value: 'Fontevivo' },
  { label: 'Fontevraud-Labbaye', value: 'Fontevraud-Labbaye' },
  { label: 'Fontezuelas', value: 'Fontezuelas' },
  { label: 'Fontignano', value: 'Fontignano' },
  { label: 'Fontihoyuelo', value: 'Fontihoyuelo' },
  { label: 'Fontioso', value: 'Fontioso' },
  { label: 'Fontiveros', value: 'Fontiveros' },
  { label: 'Fontoura Xavier', value: 'Fontoura Xavier' },
  { label: 'Fontoy', value: 'Fontoy' },
  { label: 'Font-Romeu-Odeillo-Via', value: 'Font-Romeu-Odeillo-Via' },
  { label: 'Font-Rubí', value: 'Font-Rubí' },
  { label: 'Fontvieille', value: 'Fontvieille' },
  { label: 'Fontwell', value: 'Fontwell' },
  { label: 'Fonyód', value: 'Fonyód' },
  { label: 'Fonyódi Járás', value: 'Fonyódi Járás' },
  { label: 'Fonz', value: 'Fonz' },
  { label: 'Fonzaleche', value: 'Fonzaleche' },
  { label: 'Fonzaso', value: 'Fonzaso' },
  { label: 'Fooladshahr', value: 'Fooladshahr' },
  { label: 'Foothill Farms', value: 'Foothill Farms' },
  { label: 'Foothill Ranch', value: 'Foothill Ranch' },
  { label: 'Footscray', value: 'Footscray' },
  { label: 'Foppolo', value: 'Foppolo' },
  { label: 'Foradada', value: 'Foradada' },
  { label: 'Foradada Del Toscar', value: 'Foradada Del Toscar' },
  { label: 'Forallac', value: 'Forallac' },
  { label: 'Forano', value: 'Forano' },
  { label: 'Forăşti', value: 'Forăşti' },
  { label: 'Forău', value: 'Forău' },
  { label: 'Forbach', value: 'Forbach' },
  { label: 'Forbach', value: 'Forbach' },
  { label: 'Forbes', value: 'Forbes' },
  { label: 'Forbesganj', value: 'Forbesganj' },
  { label: 'Forcall', value: 'Forcall' },
  { label: 'Forcalqueiret', value: 'Forcalqueiret' },
  { label: 'Forcalquier', value: 'Forcalquier' },
  { label: 'Forcarei', value: 'Forcarei' },
  { label: 'Force', value: 'Force' },
  { label: 'Forchach', value: 'Forchach' },
  { label: 'Forche', value: 'Forche' },
  { label: 'Forchheim', value: 'Forchheim' },
  { label: 'Forchheim', value: 'Forchheim' },
  { label: 'Forchia', value: 'Forchia' },
  { label: 'Forchtenberg', value: 'Forchtenberg' },
  { label: 'Forchtenstein', value: 'Forchtenstein' },
  { label: 'Forcola', value: 'Forcola' },
  { label: 'Forcoli', value: 'Forcoli' },
  { label: 'Ford', value: 'Ford' },
  { label: 'Ford City', value: 'Ford City' },
  { label: 'Ford City', value: 'Ford City' },
  { label: 'Ford County', value: 'Ford County' },
  { label: 'Ford County', value: 'Ford County' },
  { label: 'Ford Heights', value: 'Ford Heights' },
  { label: 'Forde', value: 'Forde' },
  { label: 'Førde', value: 'Førde' },
  { label: 'Förderstedt', value: 'Förderstedt' },
  { label: 'Fordham', value: 'Fordham' },
  { label: 'Fordham', value: 'Fordham' },
  { label: 'Fordingbridge', value: 'Fordingbridge' },
  { label: 'Fordon', value: 'Fordon' },
  { label: 'Fordongianus', value: 'Fordongianus' },
  { label: 'Fords', value: 'Fords' },
  { label: 'Fords Prairie', value: 'Fords Prairie' },
  { label: 'Fordyce', value: 'Fordyce' },
  { label: 'Forécariah', value: 'Forécariah' },
  { label: 'Forenza', value: 'Forenza' },
  { label: 'Forès', value: 'Forès' },
  { label: 'Forest', value: 'Forest' },
  { label: 'Forest', value: 'Forest' },
  { label: 'Forest', value: 'Forest' },
  { label: 'Forest Acres', value: 'Forest Acres' },
  { label: 'Forest City', value: 'Forest City' },
  { label: 'Forest City', value: 'Forest City' },
  { label: 'Forest City', value: 'Forest City' },
  { label: 'Forest City', value: 'Forest City' },
  { label: 'Forest County', value: 'Forest County' },
  { label: 'Forest County', value: 'Forest County' },
  { label: 'Forest Glen', value: 'Forest Glen' },
  { label: 'Forest Glen', value: 'Forest Glen' },
  { label: 'Forest Grove', value: 'Forest Grove' },
  { label: 'Forest Heights', value: 'Forest Heights' },
  { label: 'Forest Hill', value: 'Forest Hill' },
  { label: 'Forest Hill', value: 'Forest Hill' },
  { label: 'Forest Hill', value: 'Forest Hill' },
  { label: 'Forest Hills', value: 'Forest Hills' },
  { label: 'Forest Hills', value: 'Forest Hills' },
  { label: 'Forest Hills', value: 'Forest Hills' },
  { label: 'Forest Hills', value: 'Forest Hills' },
  { label: 'Forest Hills Gardens', value: 'Forest Hills Gardens' },
  { label: 'Forest Hills/ Plantation Height', value: 'Forest Hills/ Plantation Height' },
  { label: 'Forest Lake', value: 'Forest Lake' },
  { label: 'Forest Lake', value: 'Forest Lake' },
  { label: 'Forest Lake', value: 'Forest Lake' },
  { label: 'Forest Lodge', value: 'Forest Lodge' },
  { label: 'Forest Meadows', value: 'Forest Meadows' },
  { label: 'Forest Oaks', value: 'Forest Oaks' },
  { label: 'Forest Park', value: 'Forest Park' },
  { label: 'Forest Park', value: 'Forest Park' },
  { label: 'Forest Park', value: 'Forest Park' },
  { label: 'Forest Park', value: 'Forest Park' },
  { label: 'Forest Ranch', value: 'Forest Ranch' },
  { label: 'Forest Row', value: 'Forest Row' },
  { label: 'Forest Side', value: 'Forest Side' },
  { label: 'Foresta', value: 'Foresta' },
  { label: 'Forestbrook', value: 'Forestbrook' },
  { label: 'Forestdale', value: 'Forestdale' },
  { label: 'Forestdale', value: 'Forestdale' },
  { label: 'Forestdale', value: 'Forestdale' },
  { label: 'Foresthill', value: 'Foresthill' },
  { label: 'Forestiere', value: 'Forestiere' },
  { label: 'Foresto Sparso', value: 'Foresto Sparso' },
  { label: 'Forest-Sur-Marque', value: 'Forest-Sur-Marque' },
  { label: 'Forestville', value: 'Forestville' },
  { label: 'Forestville', value: 'Forestville' },
  { label: 'Forestville', value: 'Forestville' },
  { label: 'Forestville', value: 'Forestville' },
  { label: 'Forestville', value: 'Forestville' },
  { label: 'Forestville', value: 'Forestville' },
  { label: 'Forette', value: 'Forette' },
  { label: 'Forfar', value: 'Forfar' },
  { label: 'Forfoleda', value: 'Forfoleda' },
  { label: 'Forgaria Nel Friuli', value: 'Forgaria Nel Friuli' },
  { label: 'Forges-Les-Bains', value: 'Forges-Les-Bains' },
  { label: 'Forges-Les-Eaux', value: 'Forges-Les-Eaux' },
  { label: 'Forino', value: 'Forino' },
  { label: 'Forino', value: 'Forino' },
  { label: 'Forio', value: 'Forio' },
  { label: 'Föritz', value: 'Föritz' },
  { label: 'Forjadores De Pachuca', value: 'Forjadores De Pachuca' },
  { label: 'Forked River', value: 'Forked River' },
  { label: 'Forks', value: 'Forks' },
  { label: 'Forlev', value: 'Forlev' },
  { label: 'Forlì', value: 'Forlì' },
  { label: 'Forlì Del Sannio', value: 'Forlì Del Sannio' },
  { label: 'Forlimpopoli', value: 'Forlimpopoli' },
  { label: 'Forman', value: 'Forman' },
  { label: 'Formazza', value: 'Formazza' },
  { label: 'Formby', value: 'Formby' },
  { label: 'Formello', value: 'Formello' },
  { label: 'Formentera', value: 'Formentera' },
  { label: 'Formentera De Segura', value: 'Formentera De Segura' },
  { label: 'Formentera Del Segura', value: 'Formentera Del Segura' },
  { label: 'Formerie', value: 'Formerie' },
  { label: 'Formia', value: 'Formia' },
  { label: 'Formica', value: 'Formica' },
  { label: 'Formiche Alto', value: 'Formiche Alto' },
  { label: 'Formicola', value: 'Formicola' },
  { label: 'Formiga', value: 'Formiga' },
  { label: 'Formigara', value: 'Formigara' },
  { label: 'Formigine', value: 'Formigine' },
  { label: 'Formigliana', value: 'Formigliana' },
  { label: 'Formignana', value: 'Formignana' },
  { label: 'Formigueiro', value: 'Formigueiro' },
  { label: 'Formosa', value: 'Formosa' },
  { label: 'Formosa', value: 'Formosa' },
  { label: 'Formosa Da Serra Negra', value: 'Formosa Da Serra Negra' },
  { label: 'Formosa Do Oeste', value: 'Formosa Do Oeste' },
  { label: 'Formosa Do Rio Preto', value: 'Formosa Do Rio Preto' },
  { label: 'Formosa Do Sul', value: 'Formosa Do Sul' },
  { label: 'Formoso', value: 'Formoso' },
  { label: 'Formoso', value: 'Formoso' },
  { label: 'Formoso Do Araguaia', value: 'Formoso Do Araguaia' },
  { label: 'Fornace', value: 'Fornace' },
  { label: 'Fornace Zarattini', value: 'Fornace Zarattini' },
  { label: 'Fornacelle', value: 'Fornacelle' },
  { label: 'Fornacette', value: 'Fornacette' },
  { label: 'Fornach', value: 'Fornach' },
  { label: 'Fornaci', value: 'Fornaci' },
  { label: 'Fornaci', value: 'Fornaci' },
  { label: 'Fornaci', value: 'Fornaci' },
  { label: 'Fornaci Di Barga', value: 'Fornaci Di Barga' },
  { label: 'Fornalutx', value: 'Fornalutx' },
  { label: 'Fornase', value: 'Fornase' },
  { label: 'Fornelli', value: 'Fornelli' },
  { label: 'Fornells De La Selva', value: 'Fornells De La Selva' },
  { label: 'Fornelos De Montes', value: 'Fornelos De Montes' },
  { label: 'Forney', value: 'Forney' },
  { label: 'Forni Avoltri', value: 'Forni Avoltri' },
  { label: 'Forni Di Sopra', value: 'Forni Di Sopra' },
  { label: 'Forni Di Sotto', value: 'Forni Di Sotto' },
  { label: 'Forninhos', value: 'Forninhos' },
  { label: 'Forno Canavese', value: 'Forno Canavese' },
  { label: 'Forno Di Zoldo', value: 'Forno Di Zoldo' },
  { label: 'Forno Telheiro', value: 'Forno Telheiro' },
  { label: 'Fornole', value: 'Fornole' },
  { label: 'Fórnoles', value: 'Fórnoles' },
  { label: 'Fornos De Algodres', value: 'Fornos De Algodres' },
  { label: 'Fornosovo', value: 'Fornosovo' },
  { label: 'Fornovo Di Taro', value: 'Fornovo Di Taro' },
  { label: 'Fornovo San Giovanni', value: 'Fornovo San Giovanni' },
  { label: 'Foros', value: 'Foros' },
  { label: 'Forotic', value: 'Forotic' },
  { label: 'Forquetinha', value: 'Forquetinha' },
  { label: 'Forquilha', value: 'Forquilha' },
  { label: 'Forquilhinha', value: 'Forquilhinha' },
  { label: 'Forráskút', value: 'Forráskút' },
  { label: 'Forres', value: 'Forres' },
  { label: 'Forrest', value: 'Forrest' },
  { label: 'Forrest', value: 'Forrest' },
  { label: 'Forrest City', value: 'Forrest City' },
  { label: 'Forrest County', value: 'Forrest County' },
  { label: 'Forrestdale', value: 'Forrestdale' },
  { label: 'Forresters Beach', value: 'Forresters Beach' },
  { label: 'Forrestfield', value: 'Forrestfield' },
  { label: 'Forreston', value: 'Forreston' },
  { label: 'Forrópuszta', value: 'Forrópuszta' },
  { label: 'Fors', value: 'Fors' },
  { label: 'Forsand', value: 'Forsand' },
  { label: 'Forsbacka', value: 'Forsbacka' },
  { label: 'Forsbacka', value: 'Forsbacka' },
  { label: 'Forserum', value: 'Forserum' },
  { label: 'Forshaga', value: 'Forshaga' },
  { label: 'Forsheda', value: 'Forsheda' },
  { label: 'Förslöv', value: 'Förslöv' },
  { label: 'Forssa', value: 'Forssa' },
  { label: 'Forst', value: 'Forst' },
  { label: 'Forst', value: 'Forst' },
  { label: 'Forstau', value: 'Forstau' },
  { label: 'Forster', value: 'Forster' },
  { label: 'Forstinning', value: 'Forstinning' },
  { label: 'Forsyth', value: 'Forsyth' },
  { label: 'Forsyth', value: 'Forsyth' },
  { label: 'Forsyth', value: 'Forsyth' },
  { label: 'Forsyth', value: 'Forsyth' },
  { label: 'Forsyth County', value: 'Forsyth County' },
  { label: 'Forsyth County', value: 'Forsyth County' },
  { label: 'Fort', value: 'Fort' },
  { label: 'Fort Abbas', value: 'Fort Abbas' },
  { label: 'Fort Ashby', value: 'Fort Ashby' },
  { label: 'Fort Atkinson', value: 'Fort Atkinson' },
  { label: 'Fort Beaufort', value: 'Fort Beaufort' },
  { label: 'Fort Belknap Agency', value: 'Fort Belknap Agency' },
  { label: 'Fort Belvoir', value: 'Fort Belvoir' },
  { label: 'Fort Bend County', value: 'Fort Bend County' },
  { label: 'Fort Benton', value: 'Fort Benton' },
  { label: 'Fort Bliss', value: 'Fort Bliss' },
  { label: 'Fort Bragg', value: 'Fort Bragg' },
  { label: 'Fort Bragg', value: 'Fort Bragg' },
  { label: 'Fort Branch', value: 'Fort Branch' },
  { label: 'Fort Campbell North', value: 'Fort Campbell North' },
  { label: 'Fort Carson', value: 'Fort Carson' },
  { label: 'Fort Clark Springs', value: 'Fort Clark Springs' },
  { label: 'Fort Collins', value: 'Fort Collins' },
  { label: 'Fort Covington Hamlet', value: 'Fort Covington Hamlet' },
  { label: 'Fort Davis', value: 'Fort Davis' },
  { label: 'Fort Defiance', value: 'Fort Defiance' },
  { label: 'Fort Deposit', value: 'Fort Deposit' },
  { label: 'Fort Dix', value: 'Fort Dix' },
  { label: 'Fort Dodge', value: 'Fort Dodge' },
  { label: 'Fort Drum', value: 'Fort Drum' },
  { label: 'Fort Edward', value: 'Fort Edward' },
  { label: 'Fort Erie', value: 'Fort Erie' },
  { label: 'Fort Fairfield', value: 'Fort Fairfield' },
  { label: 'Fort Frances', value: 'Fort Frances' },
  { label: 'Fort Gaines', value: 'Fort Gaines' },
  { label: 'Fort George G Mead Junction', value: 'Fort George G Mead Junction' },
  { label: 'Fort Gibson', value: 'Fort Gibson' },
  { label: 'Fort Gloster', value: 'Fort Gloster' },
  { label: 'Fort Hall', value: 'Fort Hall' },
  { label: 'Fort Hamilton', value: 'Fort Hamilton' },
  { label: 'Fort Hancock', value: 'Fort Hancock' },
  { label: 'Fort Hood', value: 'Fort Hood' },
  { label: 'Fort Hunt', value: 'Fort Hunt' },
  { label: 'Fort Irwin', value: 'Fort Irwin' },
  { label: 'Fort Kent', value: 'Fort Kent' },
  { label: 'Fort Knox', value: 'Fort Knox' },
  { label: 'Fort Lauderdale', value: 'Fort Lauderdale' },
  { label: 'Fort Lee', value: 'Fort Lee' },
  { label: 'Fort Lee', value: 'Fort Lee' },
  { label: 'Fort Leonard Wood', value: 'Fort Leonard Wood' },
  { label: 'Fort Liberté', value: 'Fort Liberté' },
  { label: 'Fort Loramie', value: 'Fort Loramie' },
  { label: 'Fort Lupton', value: 'Fort Lupton' },
  { label: 'Fort Macleod', value: 'Fort Macleod' },
  { label: 'Fort Madison', value: 'Fort Madison' },
  { label: 'Fort Mckinley', value: 'Fort Mckinley' },
  { label: 'Fort Mcmurray', value: 'Fort Mcmurray' },
  { label: 'Fort Mcpherson', value: 'Fort Mcpherson' },
  { label: 'Fort Meade', value: 'Fort Meade' },
  { label: 'Fort Meade', value: 'Fort Meade' },
  { label: 'Fort Mill', value: 'Fort Mill' },
  { label: 'Fort Mitchell', value: 'Fort Mitchell' },
  { label: 'Fort Montgomery', value: 'Fort Montgomery' },
  { label: 'Fort Morgan', value: 'Fort Morgan' },
  { label: 'Fort Myers', value: 'Fort Myers' },
  { label: 'Fort Myers Beach', value: 'Fort Myers Beach' },
  { label: 'Fort Myers Shores', value: 'Fort Myers Shores' },
  { label: 'Fort Nelson', value: 'Fort Nelson' },
  { label: 'Fort Oglethorpe', value: 'Fort Oglethorpe' },
  { label: 'Fort Payne', value: 'Fort Payne' },
  { label: 'Fort Pierce', value: 'Fort Pierce' },
  { label: 'Fort Pierce North', value: 'Fort Pierce North' },
  { label: 'Fort Pierce South', value: 'Fort Pierce South' },
  { label: 'Fort Pierre', value: 'Fort Pierre' },
  { label: 'Fort Plain', value: 'Fort Plain' },
  { label: 'Fort Polk North', value: 'Fort Polk North' },
  { label: 'Fort Polk South', value: 'Fort Polk South' },
  { label: 'Fort Portal', value: 'Fort Portal' },
  { label: 'Fort Recovery', value: 'Fort Recovery' },
  { label: 'Fort Riley North', value: 'Fort Riley North' },
  { label: 'Fort Rucker', value: 'Fort Rucker' },
  { label: 'Fort Salonga', value: 'Fort Salonga' },
  { label: 'Fort Saskatchewan', value: 'Fort Saskatchewan' },
  { label: 'Fort Scott', value: 'Fort Scott' },
  { label: 'Fort Shawnee', value: 'Fort Shawnee' },
  { label: 'Fort Smith', value: 'Fort Smith' },
  { label: 'Fort Smith', value: 'Fort Smith' },
  { label: 'Fort St. John', value: 'Fort St. John' },
  { label: 'Fort Stewart', value: 'Fort Stewart' },
  { label: 'Fort Stockton', value: 'Fort Stockton' },
  { label: 'Fort Sumner', value: 'Fort Sumner' },
  { label: 'Fort Thomas', value: 'Fort Thomas' },
  { label: 'Fort Thompson', value: 'Fort Thompson' },
  { label: 'Fort Totten', value: 'Fort Totten' },
  { label: 'Fort Valley', value: 'Fort Valley' },
  { label: 'Fort Wadsworth', value: 'Fort Wadsworth' },
  { label: 'Fort Walton Beach', value: 'Fort Walton Beach' },
  { label: 'Fort Washakie', value: 'Fort Washakie' },
  { label: 'Fort Washington', value: 'Fort Washington' },
  { label: 'Fort Washington', value: 'Fort Washington' },
  { label: 'Fort Wayne', value: 'Fort Wayne' },
  { label: 'Fort William', value: 'Fort William' },
  { label: 'Fort Williams', value: 'Fort Williams' },
  { label: 'Fort Worth', value: 'Fort Worth' },
  { label: 'Fort Wright', value: 'Fort Wright' },
  { label: 'Fort Yates', value: 'Fort Yates' },
  { label: 'Fortaleny', value: 'Fortaleny' },
  { label: 'Fortaleza', value: 'Fortaleza' },
  { label: 'Fortaleza De Minas', value: 'Fortaleza De Minas' },
  { label: 'Fortaleza Do Tabocão', value: 'Fortaleza Do Tabocão' },
  { label: 'Fortaleza Dos Nogueiras', value: 'Fortaleza Dos Nogueiras' },
  { label: 'Fortaleza Dos Valos', value: 'Fortaleza Dos Valos' },
  { label: 'Fortanete', value: 'Fortanete' },
  { label: 'Fort-Coulonge', value: 'Fort-Coulonge' },
  { label: 'Forte Da Casa', value: 'Forte Da Casa' },
  { label: 'Forte Dei Marmi', value: 'Forte Dei Marmi' },
  { label: 'Fortezza', value: 'Fortezza' },
  { label: 'Forth', value: 'Forth' },
  { label: 'Fortià', value: 'Fortià' },
  { label: 'Fortim', value: 'Fortim' },
  { label: 'Fortín De Las Flores', value: 'Fortín De Las Flores' },
  { label: 'Fortitude Valley', value: 'Fortitude Valley' },
  { label: 'Fort-Mahon-Plage', value: 'Fort-Mahon-Plage' },
  { label: 'Fort-Mardyck', value: 'Fort-Mardyck' },
  { label: 'Fortrose', value: 'Fortrose' },
  { label: 'Fortschwihr', value: 'Fortschwihr' },
  { label: 'Fort-Shevchenko', value: 'Fort-Shevchenko' },
  { label: 'Fortul', value: 'Fortul' },
  { label: 'Fortuna', value: 'Fortuna' },
  { label: 'Fortuna', value: 'Fortuna' },
  { label: 'Fortuna', value: 'Fortuna' },
  { label: 'Fortuna', value: 'Fortuna' },
  { label: 'Fortuna De Minas', value: 'Fortuna De Minas' },
  { label: 'Fortuna Foothills', value: 'Fortuna Foothills' },
  { label: 'Fortunago', value: 'Fortunago' },
  { label: 'Fortuneswell', value: 'Fortuneswell' },
  { label: 'Fortville', value: 'Fortville' },
  { label: 'Forty Fort', value: 'Forty Fort' },
  { label: 'Forua', value: 'Forua' },
  { label: 'Forville', value: 'Forville' },
  { label: 'Forza Dagrò', value: 'Forza Dagrò' },
  { label: 'Fosca', value: 'Fosca' },
  { label: 'Fosciandora', value: 'Fosciandora' },
  { label: 'Foscoe', value: 'Foscoe' },
  { label: 'Fosdinovo', value: 'Fosdinovo' },
  { label: 'Fosforitnyy', value: 'Fosforitnyy' },
  { label: 'Foshan', value: 'Foshan' },
  { label: 'Foshan Shi', value: 'Foshan Shi' },
  { label: 'Fosnes', value: 'Fosnes' },
  { label: 'Foso', value: 'Foso' },
  { label: 'Fossa', value: 'Fossa' },
  { label: 'Fossa', value: 'Fossa' },
  { label: 'Fossacesia', value: 'Fossacesia' },
  { label: 'Fossalta Di Piave', value: 'Fossalta Di Piave' },
  { label: 'Fossalta Di Portogruaro', value: 'Fossalta Di Portogruaro' },
  { label: 'Fossalto', value: 'Fossalto' },
  { label: 'Fossalunga', value: 'Fossalunga' },
  { label: 'Fossambault-Sur-Le-Lac', value: 'Fossambault-Sur-Le-Lac' },
  { label: 'Fossano', value: 'Fossano' },
  { label: 'Fossato Di Vico', value: 'Fossato Di Vico' },
  { label: 'Fossato Ionico-Fossatello-San Luca Marcelluzzo', value: 'Fossato Ionico-Fossatello-San Luca Marcelluzzo' },
  { label: 'Fossato Serralta', value: 'Fossato Serralta' },
  { label: 'Fossbergom', value: 'Fossbergom' },
  { label: 'Fossby', value: 'Fossby' },
  { label: 'Fosses', value: 'Fosses' },
  { label: 'Fosses-La-Ville', value: 'Fosses-La-Ville' },
  { label: 'Fossignano', value: 'Fossignano' },
  { label: 'Fossil', value: 'Fossil' },
  { label: 'Fossò', value: 'Fossò' },
  { label: 'Fosso Ghiaia', value: 'Fosso Ghiaia' },
  { label: 'Fossoli', value: 'Fossoli' },
  { label: 'Fossombrone', value: 'Fossombrone' },
  { label: 'Fosston', value: 'Fosston' },
  { label: 'Fos-Sur-Mer', value: 'Fos-Sur-Mer' },
  { label: 'Foster', value: 'Foster' },
  { label: 'Foster', value: 'Foster' },
  { label: 'Foster Brook', value: 'Foster Brook' },
  { label: 'Foster City', value: 'Foster City' },
  { label: 'Foster County', value: 'Foster County' },
  { label: 'Fostoria', value: 'Fostoria' },
  { label: 'Fót', value: 'Fót' },
  { label: 'Fotolívos', value: 'Fotolívos' },
  { label: 'Foucarmont', value: 'Foucarmont' },
  { label: 'Foucherans', value: 'Foucherans' },
  { label: 'Fouesnant', value: 'Fouesnant' },
  { label: 'Foug', value: 'Foug' },
  { label: 'Fougamou', value: 'Fougamou' },
  { label: 'Fougères', value: 'Fougères' },
  { label: 'Fougerolles', value: 'Fougerolles' },
  { label: 'Fougerolles-Du-Plessis', value: 'Fougerolles-Du-Plessis' },
  { label: 'Fouillard', value: 'Fouillard' },
  { label: 'Foulayronnes', value: 'Foulayronnes' },
  { label: 'Foulridge', value: 'Foulridge' },
  { label: 'Foulsham', value: 'Foulsham' },
  { label: 'Foumban', value: 'Foumban' },
  { label: 'Foumbot', value: 'Foumbot' },
  { label: 'Foumbouni', value: 'Foumbouni' },
  { label: 'Foundiougne', value: 'Foundiougne' },
  { label: 'Founex', value: 'Founex' },
  { label: 'Fountain', value: 'Fountain' },
  { label: 'Fountain County', value: 'Fountain County' },
  { label: 'Fountain Green', value: 'Fountain Green' },
  { label: 'Fountain Hill', value: 'Fountain Hill' },
  { label: 'Fountain Hills', value: 'Fountain Hills' },
  { label: 'Fountain Inn', value: 'Fountain Inn' },
  { label: 'Fountain Valley', value: 'Fountain Valley' },
  { label: 'Fountainebleau', value: 'Fountainebleau' },
  { label: 'Fountainhead-Orchard Hills', value: 'Fountainhead-Orchard Hills' },
  { label: 'Fouquereuil', value: 'Fouquereuil' },
  { label: 'Fouquières-Lès-Béthune', value: 'Fouquières-Lès-Béthune' },
  { label: 'Fouquières-Lès-Lens', value: 'Fouquières-Lès-Lens' },
  { label: 'Four Corners', value: 'Four Corners' },
  { label: 'Four Corners', value: 'Four Corners' },
  { label: 'Four Corners', value: 'Four Corners' },
  { label: 'Four Corners', value: 'Four Corners' },
  { label: 'Four Corners', value: 'Four Corners' },
  { label: 'Four Crosses', value: 'Four Crosses' },
  { label: 'Four Lanes', value: 'Four Lanes' },
  { label: 'Four Marks', value: 'Four Marks' },
  { label: 'Four Oaks', value: 'Four Oaks' },
  { label: 'Four Paths', value: 'Four Paths' },
  { label: 'Four Seasons', value: 'Four Seasons' },
  { label: 'Fouras', value: 'Fouras' },
  { label: 'Fourchambault', value: 'Fourchambault' },
  { label: 'Fourmies', value: 'Fourmies' },
  { label: 'Fournes-En-Weppes', value: 'Fournes-En-Weppes' },
  { label: 'Fourques', value: 'Fourques' },
  { label: 'Fourques-Sur-Garonne', value: 'Fourques-Sur-Garonne' },
  { label: 'Fourqueux', value: 'Fourqueux' },
  { label: 'Foussais-Payré', value: 'Foussais-Payré' },
  { label: 'Fowey', value: 'Fowey' },
  { label: 'Fowler', value: 'Fowler' },
  { label: 'Fowler', value: 'Fowler' },
  { label: 'Fowler', value: 'Fowler' },
  { label: 'Fowler', value: 'Fowler' },
  { label: 'Fowlerville', value: 'Fowlerville' },
  { label: 'Fowlmere', value: 'Fowlmere' },
  { label: 'Fox Chapel', value: 'Fox Chapel' },
  { label: 'Fox Chase', value: 'Fox Chase' },
  { label: 'Fox Creek', value: 'Fox Creek' },
  { label: 'Fox Farm-College', value: 'Fox Farm-College' },
  { label: 'Fox Island', value: 'Fox Island' },
  { label: 'Fox Lake', value: 'Fox Lake' },
  { label: 'Fox Lake', value: 'Fox Lake' },
  { label: 'Fox Lake Hills', value: 'Fox Lake Hills' },
  { label: 'Fox Point', value: 'Fox Point' },
  { label: 'Fox River Grove', value: 'Fox River Grove' },
  { label: 'Fox Run', value: 'Fox Run' },
  { label: 'Foxborough', value: 'Foxborough' },
  { label: 'Foxford', value: 'Foxford' },
  { label: 'Foxrock', value: 'Foxrock' },
  { label: 'Foxton', value: 'Foxton' },
  { label: 'Foz', value: 'Foz' },
  { label: 'Foz Do Arelho', value: 'Foz Do Arelho' },
  { label: 'Foz Do Douro', value: 'Foz Do Douro' },
  { label: 'Foz Do Iguaçu', value: 'Foz Do Iguaçu' },
  { label: 'Foz Do Jordão', value: 'Foz Do Jordão' },
  { label: 'Foz Do Sousa', value: 'Foz Do Sousa' },
  { label: 'Foza', value: 'Foza' },
  { label: 'Foz-Calanda', value: 'Foz-Calanda' },
  { label: 'Fquih Ben Salah Province', value: 'Fquih Ben Salah Province' },
  { label: 'Frabosa Soprana', value: 'Frabosa Soprana' },
  { label: 'Frabosa Sottana', value: 'Frabosa Sottana' },
  { label: 'Fracción Milpillas', value: 'Fracción Milpillas' },
  { label: 'Fracción San Roque (El Prieto)', value: 'Fracción San Roque (El Prieto)' },
  { label: 'Fraccionamiento Arboledas San Ramón', value: 'Fraccionamiento Arboledas San Ramón' },
  { label: 'Fraccionamiento Carlos Salinas De Gortari', value: 'Fraccionamiento Carlos Salinas De Gortari' },
  { label: 'Fraccionamiento Ciudad Olmeca', value: 'Fraccionamiento Ciudad Olmeca' },
  { label: 'Fraccionamiento Ciudad Yagul', value: 'Fraccionamiento Ciudad Yagul' },
  { label: 'Fraccionamiento Colinas Del Sol', value: 'Fraccionamiento Colinas Del Sol' },
  { label: 'Fraccionamiento Colinas Universidad', value: 'Fraccionamiento Colinas Universidad' },
  { label: 'Fraccionamiento Cosmópolis Octavo Sector', value: 'Fraccionamiento Cosmópolis Octavo Sector' },
  { label: 'Fraccionamiento Costa Dorada', value: 'Fraccionamiento Costa Dorada' },
  { label: 'Fraccionamiento Del Magisterio Tulancinguense', value: 'Fraccionamiento Del Magisterio Tulancinguense' },
  { label: 'Fraccionamiento Del Valle', value: 'Fraccionamiento Del Valle' },
  { label: 'Fraccionamiento Del Valle', value: 'Fraccionamiento Del Valle' },
  { label: 'Fraccionamiento Ex-Hacienda Catano', value: 'Fraccionamiento Ex-Hacienda Catano' },
  { label: 'Fraccionamiento Ex-Hacienda El Refugio', value: 'Fraccionamiento Ex-Hacienda El Refugio' },
  { label: 'Fraccionamiento Galaxia Tarímbaro', value: 'Fraccionamiento Galaxia Tarímbaro' },
  { label: 'Fraccionamiento Geovillas Los Pinos', value: 'Fraccionamiento Geovillas Los Pinos' },
  { label: 'Fraccionamiento Hacienda Del Bosque', value: 'Fraccionamiento Hacienda Del Bosque' },
  { label: 'Fraccionamiento La Florida', value: 'Fraccionamiento La Florida' },
  { label: 'Fraccionamiento La Mezquitera', value: 'Fraccionamiento La Mezquitera' },
  { label: 'Fraccionamiento La Noria', value: 'Fraccionamiento La Noria' },
  { label: 'Fraccionamiento La Trinidad', value: 'Fraccionamiento La Trinidad' },
  { label: 'Fraccionamiento La Virgen', value: 'Fraccionamiento La Virgen' },
  { label: 'Fraccionamiento Las Fuentes', value: 'Fraccionamiento Las Fuentes' },
  { label: 'Fraccionamiento Las Liebres', value: 'Fraccionamiento Las Liebres' },
  { label: 'Fraccionamiento Laureles Eréndira', value: 'Fraccionamiento Laureles Eréndira' },
  { label: 'Fraccionamiento Lomas De Ahuatlán', value: 'Fraccionamiento Lomas De Ahuatlán' },
  { label: 'Fraccionamiento Los Álamos', value: 'Fraccionamiento Los Álamos' },
  { label: 'Fraccionamiento Los Ángeles', value: 'Fraccionamiento Los Ángeles' },
  { label: 'Fraccionamiento Los Prados', value: 'Fraccionamiento Los Prados' },
  { label: 'Fraccionamiento Metrópolis Ii', value: 'Fraccionamiento Metrópolis Ii' },
  { label: 'Fraccionamiento Misión De San Javier', value: 'Fraccionamiento Misión De San Javier' },
  { label: 'Fraccionamiento Misión Del Valle', value: 'Fraccionamiento Misión Del Valle' },
  { label: 'Fraccionamiento Monte Olivo', value: 'Fraccionamiento Monte Olivo' },
  { label: 'Fraccionamiento Ocuiltzapotlán Dos', value: 'Fraccionamiento Ocuiltzapotlán Dos' },
  { label: 'Fraccionamiento Paraíso Real', value: 'Fraccionamiento Paraíso Real' },
  { label: 'Fraccionamiento Paseo De Las Torres', value: 'Fraccionamiento Paseo De Las Torres' },
  { label: 'Fraccionamiento Praderas De La Venta', value: 'Fraccionamiento Praderas De La Venta' },
  { label: 'Fraccionamiento Privadas Del Sol', value: 'Fraccionamiento Privadas Del Sol' },
  { label: 'Fraccionamiento Real De San Pablo', value: 'Fraccionamiento Real De San Pablo' },
  { label: 'Fraccionamiento Real Del Valle', value: 'Fraccionamiento Real Del Valle' },
  { label: 'Fraccionamiento Real Palmas', value: 'Fraccionamiento Real Palmas' },
  { label: 'Fraccionamiento Riberas De San Jerónimo', value: 'Fraccionamiento Riberas De San Jerónimo' },
  { label: 'Fraccionamiento Rinconada Del Valle', value: 'Fraccionamiento Rinconada Del Valle' },
  { label: 'Fraccionamiento San Miguel', value: 'Fraccionamiento San Miguel' },
  { label: 'Fraccionamiento San Miguel', value: 'Fraccionamiento San Miguel' },
  { label: 'Fraccionamiento Santa Cruz', value: 'Fraccionamiento Santa Cruz' },
  { label: 'Fraccionamiento Universo', value: 'Fraccionamiento Universo' },
  { label: 'Fraccionamiento Valle Dorado', value: 'Fraccionamiento Valle Dorado' },
  { label: 'Fraccionamiento Villa Jardín', value: 'Fraccionamiento Villa Jardín' },
  { label: 'Fraccionamiento Villas De Guanajuato', value: 'Fraccionamiento Villas De Guanajuato' },
  { label: 'Fraccionamiento Villas De La Llave', value: 'Fraccionamiento Villas De La Llave' },
  { label: 'Fraccionamiento Y Club De Golf Los Encinos', value: 'Fraccionamiento Y Club De Golf Los Encinos' },
  { label: 'Frackville', value: 'Frackville' },
  { label: 'Fraconalto', value: 'Fraconalto' },
  { label: 'Fradelos', value: 'Fradelos' },
  { label: 'Frades', value: 'Frades' },
  { label: 'Frades De La Sierra', value: 'Frades De La Sierra' },
  { label: 'Fræna', value: 'Fræna' },
  { label: 'Fraga', value: 'Fraga' },
  { label: 'Fragagnano', value: 'Fragagnano' },
  { label: 'Fragneto Labate', value: 'Fragneto Labate' },
  { label: 'Fragneto Monforte', value: 'Fragneto Monforte' },
  { label: 'Frago El', value: 'Frago El' },
  { label: 'Frahier-Et-Chatebier', value: 'Frahier-Et-Chatebier' },
  { label: 'Fraiburgo', value: 'Fraiburgo' },
  { label: 'Fraijanes', value: 'Fraijanes' },
  { label: 'Fraile Pintado', value: 'Fraile Pintado' },
  { label: 'Frailes', value: 'Frailes' },
  { label: 'Fraine', value: 'Fraine' },
  { label: 'Fraisans', value: 'Fraisans' },
  { label: 'Fraisses', value: 'Fraisses' },
  { label: 'Fraize', value: 'Fraize' },
  { label: 'Fram', value: 'Fram' },
  { label: 'Fram', value: 'Fram' },
  { label: 'Frameries', value: 'Frameries' },
  { label: 'Framersheim', value: 'Framersheim' },
  { label: 'Framingham', value: 'Framingham' },
  { label: 'Framingham Center', value: 'Framingham Center' },
  { label: 'Framlev', value: 'Framlev' },
  { label: 'Framlingham', value: 'Framlingham' },
  { label: 'Frammersbach', value: 'Frammersbach' },
  { label: 'Frampol', value: 'Frampol' },
  { label: 'Frampton On Severn', value: 'Frampton On Severn' },
  { label: 'Framura', value: 'Framura' },
  { label: 'Franca', value: 'Franca' },
  { label: 'Francavilla Al Mare', value: 'Francavilla Al Mare' },
  { label: 'Francavilla Angitola', value: 'Francavilla Angitola' },
  { label: 'Francavilla Bisio', value: 'Francavilla Bisio' },
  { label: 'Francavilla Dete', value: 'Francavilla Dete' },
  { label: 'Francavilla Di Sicilia', value: 'Francavilla Di Sicilia' },
  { label: 'Francavilla Fontana', value: 'Francavilla Fontana' },
  { label: 'Francavilla In Sinni', value: 'Francavilla In Sinni' },
  { label: 'Francavilla Marittima', value: 'Francavilla Marittima' },
  { label: 'Francenigo', value: 'Francenigo' },
  { label: 'Frâncenii Boiului', value: 'Frâncenii Boiului' },
  { label: 'Frances Baard District Municipality', value: 'Frances Baard District Municipality' },
  { label: 'Francestown', value: 'Francestown' },
  { label: 'Franceville', value: 'Franceville' },
  { label: 'Franche', value: 'Franche' },
  { label: 'Francheleins', value: 'Francheleins' },
  { label: 'Franches-Montagnes District', value: 'Franches-Montagnes District' },
  { label: 'Francheville', value: 'Francheville' },
  { label: 'Francheville', value: 'Francheville' },
  { label: 'Francia', value: 'Francia' },
  { label: 'Francica', value: 'Francica' },
  { label: 'Francinópolis', value: 'Francinópolis' },
  { label: 'Franciou', value: 'Franciou' },
  { label: 'Francis', value: 'Francis' },
  { label: 'Francisco Alves', value: 'Francisco Alves' },
  { label: 'Francisco Ayres', value: 'Francisco Ayres' },
  { label: 'Francisco Badaró', value: 'Francisco Badaró' },
  { label: 'Francisco Beltrão', value: 'Francisco Beltrão' },
  { label: 'Francisco Dantas', value: 'Francisco Dantas' },
  { label: 'Francisco De Orellana Canton', value: 'Francisco De Orellana Canton' },
  { label: 'Francisco Dumont', value: 'Francisco Dumont' },
  { label: 'Francisco I. Madero', value: 'Francisco I. Madero' },
  { label: 'Francisco I. Madero', value: 'Francisco I. Madero' },
  { label: 'Francisco I. Madero', value: 'Francisco I. Madero' },
  { label: 'Francisco I. Madero', value: 'Francisco I. Madero' },
  { label: 'Francisco I. Madero', value: 'Francisco I. Madero' },
  { label: 'Francisco I. Madero', value: 'Francisco I. Madero' },
  { label: 'Francisco I. Madero', value: 'Francisco I. Madero' },
  { label: 'Francisco I. Madero', value: 'Francisco I. Madero' },
  { label: 'Francisco I. Madero', value: 'Francisco I. Madero' },
  { label: 'Francisco Ibarra Ramos', value: 'Francisco Ibarra Ramos' },
  { label: 'Francisco Ignacio Madero', value: 'Francisco Ignacio Madero' },
  { label: 'Francisco Javier Mina', value: 'Francisco Javier Mina' },
  { label: 'Francisco León', value: 'Francisco León' },
  { label: 'Francisco Macedo', value: 'Francisco Macedo' },
  { label: 'Francisco Morato', value: 'Francisco Morato' },
  { label: 'Francisco Pizarro', value: 'Francisco Pizarro' },
  { label: 'Francisco Primo De Verdad', value: 'Francisco Primo De Verdad' },
  { label: 'Francisco Rueda', value: 'Francisco Rueda' },
  { label: 'Francisco Sá', value: 'Francisco Sá' },
  { label: 'Francisco Santos', value: 'Francisco Santos' },
  { label: 'Francisco Sarabia', value: 'Francisco Sarabia' },
  { label: 'Francisco Sarabia', value: 'Francisco Sarabia' },
  { label: 'Francisco Sarabia (Cerrito Pelón)', value: 'Francisco Sarabia (Cerrito Pelón)' },
  { label: 'Francisco Serrato (San Bartolo)', value: 'Francisco Serrato (San Bartolo)' },
  { label: 'Francisco Villa', value: 'Francisco Villa' },
  { label: 'Francisco Villa', value: 'Francisco Villa' },
  { label: 'Francisco Villa', value: 'Francisco Villa' },
  { label: 'Francisco Villa', value: 'Francisco Villa' },
  { label: 'Francisco Villa', value: 'Francisco Villa' },
  { label: 'Francisco Z. Mena', value: 'Francisco Z. Mena' },
  { label: 'Francisco Zarco', value: 'Francisco Zarco' },
  { label: 'Franciscópolis', value: 'Franciscópolis' },
  { label: 'Francisville', value: 'Francisville' },
  { label: 'Franco', value: 'Franco' },
  { label: 'Franco Da Rocha', value: 'Franco Da Rocha' },
  { label: 'Franco El', value: 'Franco El' },
  { label: 'Franco Tavera', value: 'Franco Tavera' },
  { label: 'Francofonte', value: 'Francofonte' },
  { label: 'Francolino', value: 'Francolino' },
  { label: 'Francolise', value: 'Francolise' },
  { label: 'Franconia', value: 'Franconia' },
  { label: 'Franconville', value: 'Franconville' },
  { label: 'Francova Lhota', value: 'Francova Lhota' },
  { label: 'Frandovínez', value: 'Frandovínez' },
  { label: 'Franeker', value: 'Franeker' },
  { label: 'Frangy', value: 'Frangy' },
  { label: 'Frankenau', value: 'Frankenau' },
  { label: 'Frankenberg', value: 'Frankenberg' },
  { label: 'Frankenberg', value: 'Frankenberg' },
  { label: 'Frankenburg', value: 'Frankenburg' },
  { label: 'Frankenfels', value: 'Frankenfels' },
  { label: 'Frankenheim', value: 'Frankenheim' },
  { label: 'Frankenmarkt', value: 'Frankenmarkt' },
  { label: 'Frankenmuth', value: 'Frankenmuth' },
  { label: 'Frankenstein', value: 'Frankenstein' },
  { label: 'Frankenstein', value: 'Frankenstein' },
  { label: 'Frankenthal', value: 'Frankenthal' },
  { label: 'Frankenthal', value: 'Frankenthal' },
  { label: 'Frankenwinheim', value: 'Frankenwinheim' },
  { label: 'Frankfield', value: 'Frankfield' },
  { label: 'Frankfort', value: 'Frankfort' },
  { label: 'Frankfort', value: 'Frankfort' },
  { label: 'Frankfort', value: 'Frankfort' },
  { label: 'Frankfort', value: 'Frankfort' },
  { label: 'Frankfort', value: 'Frankfort' },
  { label: 'Frankfort', value: 'Frankfort' },
  { label: 'Frankfort', value: 'Frankfort' },
  { label: 'Frankfort', value: 'Frankfort' },
  { label: 'Frankfort Square', value: 'Frankfort Square' },
  { label: 'Frankfurt (Oder)', value: 'Frankfurt (Oder)' },
  { label: 'Frankfurt Am Main', value: 'Frankfurt Am Main' },
  { label: 'Frankhuis', value: 'Frankhuis' },
  { label: 'Fränkisch-Crumbach', value: 'Fränkisch-Crumbach' },
  { label: 'Frankleben', value: 'Frankleben' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin', value: 'Franklin' },
  { label: 'Franklin Center', value: 'Franklin Center' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin County', value: 'Franklin County' },
  { label: 'Franklin Furnace', value: 'Franklin Furnace' },
  { label: 'Franklin Harbour', value: 'Franklin Harbour' },
  { label: 'Franklin Lakes', value: 'Franklin Lakes' },
  { label: 'Franklin Parish', value: 'Franklin Parish' },
  { label: 'Franklin Park', value: 'Franklin Park' },
  { label: 'Franklin Park', value: 'Franklin Park' },
  { label: 'Franklin Park', value: 'Franklin Park' },
  { label: 'Franklin Springs', value: 'Franklin Springs' },
  { label: 'Franklin Square', value: 'Franklin Square' },
  { label: 'Franklinton', value: 'Franklinton' },
  { label: 'Franklinton', value: 'Franklinton' },
  { label: 'Franklinville', value: 'Franklinville' },
  { label: 'Franklinville', value: 'Franklinville' },
  { label: 'Franklyn Town', value: 'Franklyn Town' },
  { label: 'Frankston', value: 'Frankston' },
  { label: 'Frankston', value: 'Frankston' },
  { label: 'Frankston East', value: 'Frankston East' },
  { label: 'Frankston North', value: 'Frankston North' },
  { label: 'Frankston South', value: 'Frankston South' },
  { label: 'Franksville', value: 'Franksville' },
  { label: 'Frankton', value: 'Frankton' },
  { label: 'Frannach', value: 'Frannach' },
  { label: 'Franois', value: 'Franois' },
  { label: 'Franqueses Del Vallès Les', value: 'Franqueses Del Vallès Les' },
  { label: 'Franquez', value: 'Franquez' },
  { label: 'Frans', value: 'Frans' },
  { label: 'Fränsta', value: 'Fränsta' },
  { label: 'Františkovy Lázně', value: 'Františkovy Lázně' },
  { label: 'Frantschach-Sankt Gertraud', value: 'Frantschach-Sankt Gertraud' },
  { label: 'Franzburg', value: 'Franzburg' },
  { label: 'Französisch Buchholz', value: 'Französisch Buchholz' },
  { label: 'Frascaro', value: 'Frascaro' },
  { label: 'Frascarolo', value: 'Frascarolo' },
  { label: 'Frascati', value: 'Frascati' },
  { label: 'Frascineto', value: 'Frascineto' },
  { label: 'Frasdorf', value: 'Frasdorf' },
  { label: 'Fraser', value: 'Fraser' },
  { label: 'Fraser', value: 'Fraser' },
  { label: 'Fraser', value: 'Fraser' },
  { label: 'Fraser Coast', value: 'Fraser Coast' },
  { label: 'Fraser Valley Regional District', value: 'Fraser Valley Regional District' },
  { label: 'Fraserburg', value: 'Fraserburg' },
  { label: 'Fraserburgh', value: 'Fraserburgh' },
  { label: 'Frasin', value: 'Frasin' },
  { label: 'Frăsinet', value: 'Frăsinet' },
  { label: 'Frăsinet', value: 'Frăsinet' },
  { label: 'Frasne', value: 'Frasne' },
  { label: 'Frasnes-Lez-Buissenal', value: 'Frasnes-Lez-Buissenal' },
  { label: 'Frasno El', value: 'Frasno El' },
  { label: 'Frassilongo', value: 'Frassilongo' },
  { label: 'Frassinelle Polesine', value: 'Frassinelle Polesine' },
  { label: 'Frassinello Monferrato', value: 'Frassinello Monferrato' },
  { label: 'Frassineto Po', value: 'Frassineto Po' },
  { label: 'Frassinetto', value: 'Frassinetto' },
  { label: 'Frassino', value: 'Frassino' },
  { label: 'Frassinoro', value: 'Frassinoro' },
  { label: 'Frasso Sabino', value: 'Frasso Sabino' },
  { label: 'Frasso Telesino', value: 'Frasso Telesino' },
  { label: 'Frastanz', value: 'Frastanz' },
  { label: 'Frata', value: 'Frata' },
  { label: 'Frătăuţii Noi', value: 'Frătăuţii Noi' },
  { label: 'Frătăuţii Vechi', value: 'Frătăuţii Vechi' },
  { label: 'Fraternidad', value: 'Fraternidad' },
  { label: 'Frăteşti', value: 'Frăteşti' },
  { label: 'Fratoștița', value: 'Fratoștița' },
  { label: 'Fratta Polesine', value: 'Fratta Polesine' },
  { label: 'Fratta Terme', value: 'Fratta Terme' },
  { label: 'Fratta Todina', value: 'Fratta Todina' },
  { label: 'Frattamaggiore', value: 'Frattamaggiore' },
  { label: 'Frattaminore', value: 'Frattaminore' },
  { label: 'Fratte Rosa', value: 'Fratte Rosa' },
  { label: 'Fraubrunnen', value: 'Fraubrunnen' },
  { label: 'Frauenau', value: 'Frauenau' },
  { label: 'Frauenberg', value: 'Frauenberg' },
  { label: 'Fraueneuharting', value: 'Fraueneuharting' },
  { label: 'Frauenfeld', value: 'Frauenfeld' },
  { label: 'Frauenfeld District', value: 'Frauenfeld District' },
  { label: 'Frauenkappelen', value: 'Frauenkappelen' },
  { label: 'Frauenkirchen', value: 'Frauenkirchen' },
  { label: 'Frauenprießnitz', value: 'Frauenprießnitz' },
  { label: 'Frauenstein', value: 'Frauenstein' },
  { label: 'Frauenstein', value: 'Frauenstein' },
  { label: 'Frauenwald', value: 'Frauenwald' },
  { label: 'Fraunberg', value: 'Fraunberg' },
  { label: 'Fraureuth', value: 'Fraureuth' },
  { label: 'Fraviano', value: 'Fraviano' },
  { label: 'Fraxern', value: 'Fraxern' },
  { label: 'Fray Bentos', value: 'Fray Bentos' },
  { label: 'Fray Luis A. Beltrán', value: 'Fray Luis A. Beltrán' },
  { label: 'Fray Luis Beltrán', value: 'Fray Luis Beltrán' },
  { label: 'Frazão', value: 'Frazão' },
  { label: 'Frazee', value: 'Frazee' },
  { label: 'Frazeysburg', value: 'Frazeysburg' },
  { label: 'Frazier Park', value: 'Frazier Park' },
  { label: 'Frazione Chiesa', value: 'Frazione Chiesa' },
  { label: 'Frazzanò', value: 'Frazzanò' },
  { label: 'Freamunde', value: 'Freamunde' },
  { label: 'Frecăţei', value: 'Frecăţei' },
  { label: 'Frecăţei', value: 'Frecăţei' },
  { label: 'Frecheirinha', value: 'Frecheirinha' },
  { label: 'Frechen', value: 'Frechen' },
  { label: 'Freches', value: 'Freches' },
  { label: 'Frechilla', value: 'Frechilla' },
  { label: 'Frechilla De Almazán', value: 'Frechilla De Almazán' },
  { label: 'Freckenfeld', value: 'Freckenfeld' },
  { label: 'Freckleton', value: 'Freckleton' },
  { label: 'Freden', value: 'Freden' },
  { label: 'Fredensborg', value: 'Fredensborg' },
  { label: 'Fredensborg Kommune', value: 'Fredensborg Kommune' },
  { label: 'Fredepo', value: 'Fredepo' },
  { label: 'Frederic', value: 'Frederic' },
  { label: 'Fredericia', value: 'Fredericia' },
  { label: 'Fredericia Kommune', value: 'Fredericia Kommune' },
  { label: 'Frederick', value: 'Frederick' },
  { label: 'Frederick', value: 'Frederick' },
  { label: 'Frederick', value: 'Frederick' },
  { label: 'Frederick County', value: 'Frederick County' },
  { label: 'Frederick County', value: 'Frederick County' },
  { label: 'Fredericksburg', value: 'Fredericksburg' },
  { label: 'Fredericksburg', value: 'Fredericksburg' },
  { label: 'Fredericksburg', value: 'Fredericksburg' },
  { label: 'Frederickson', value: 'Frederickson' },
  { label: 'Frederickton', value: 'Frederickton' },
  { label: 'Fredericktown', value: 'Fredericktown' },
  { label: 'Fredericktown', value: 'Fredericktown' },
  { label: 'Frederico Westphalen', value: 'Frederico Westphalen' },
  { label: 'Fredericton', value: 'Fredericton' },
  { label: 'Frederiksberg', value: 'Frederiksberg' },
  { label: 'Frederiksberg', value: 'Frederiksberg' },
  { label: 'Frederiksberg Kommune', value: 'Frederiksberg Kommune' },
  { label: 'Frederikshavn', value: 'Frederikshavn' },
  { label: 'Frederikshavn Kommune', value: 'Frederikshavn Kommune' },
  { label: 'Frederikssund', value: 'Frederikssund' },
  { label: 'Frederikssund Kommune', value: 'Frederikssund Kommune' },
  { label: 'Frederiksted', value: 'Frederiksted' },
  { label: 'Frederiksværk', value: 'Frederiksværk' },
  { label: 'Fredonia', value: 'Fredonia' },
  { label: 'Fredonia', value: 'Fredonia' },
  { label: 'Fredonia', value: 'Fredonia' },
  { label: 'Fredonia', value: 'Fredonia' },
  { label: 'Fredonia', value: 'Fredonia' },
  { label: 'Fredrikstad', value: 'Fredrikstad' },
  { label: 'Fredropol', value: 'Fredropol' },
  { label: 'Free Hill', value: 'Free Hill' },
  { label: 'Freeborn County', value: 'Freeborn County' },
  { label: 'Freeburg', value: 'Freeburg' },
  { label: 'Freedom', value: 'Freedom' },
  { label: 'Freedom', value: 'Freedom' },
  { label: 'Freedom', value: 'Freedom' },
  { label: 'Freehold', value: 'Freehold' },
  { label: 'Freeland', value: 'Freeland' },
  { label: 'Freeland', value: 'Freeland' },
  { label: 'Freeland', value: 'Freeland' },
  { label: 'Freeling', value: 'Freeling' },
  { label: 'Freeman', value: 'Freeman' },
  { label: 'Freemans Hall', value: 'Freemans Hall' },
  { label: 'Freemans Reach', value: 'Freemans Reach' },
  { label: 'Freemansburg', value: 'Freemansburg' },
  { label: 'Freeport', value: 'Freeport' },
  { label: 'Freeport', value: 'Freeport' },
  { label: 'Freeport', value: 'Freeport' },
  { label: 'Freeport', value: 'Freeport' },
  { label: 'Freeport', value: 'Freeport' },
  { label: 'Freeport', value: 'Freeport' },
  { label: 'Freeport', value: 'Freeport' },
  { label: 'Freer', value: 'Freer' },
  { label: 'Freestone County', value: 'Freestone County' },
  { label: 'Freetown', value: 'Freetown' },
  { label: 'Freetown', value: 'Freetown' },
  { label: 'Freetown', value: 'Freetown' },
  { label: 'Fregenal De La Sierra', value: 'Fregenal De La Sierra' },
  { label: 'Fregene', value: 'Fregene' },
  { label: 'Fregeneda La', value: 'Fregeneda La' },
  { label: 'Freginals', value: 'Freginals' },
  { label: 'Fregona', value: 'Fregona' },
  { label: 'Freguesia Do Ribeirao Da Ilha', value: 'Freguesia Do Ribeirao Da Ilha' },
  { label: 'Freha', value: 'Freha' },
  { label: 'Fréhel', value: 'Fréhel' },
  { label: 'Frei Gaspar', value: 'Frei Gaspar' },
  { label: 'Frei Inocêncio', value: 'Frei Inocêncio' },
  { label: 'Frei Lagonegro', value: 'Frei Lagonegro' },
  { label: 'Frei Martinho', value: 'Frei Martinho' },
  { label: 'Frei Miguelinho', value: 'Frei Miguelinho' },
  { label: 'Frei Paulo', value: 'Frei Paulo' },
  { label: 'Frei Rogério', value: 'Frei Rogério' },
  { label: 'Freiberg', value: 'Freiberg' },
  { label: 'Freiberg Am Neckar', value: 'Freiberg Am Neckar' },
  { label: 'Freiburg', value: 'Freiburg' },
  { label: 'Freiburg', value: 'Freiburg' },
  { label: 'Freiburg Region', value: 'Freiburg Region' },
  { label: 'Freidorf An Der Laßnitz', value: 'Freidorf An Der Laßnitz' },
  { label: 'Freienbach', value: 'Freienbach' },
  { label: 'Freienbessingen', value: 'Freienbessingen' },
  { label: 'Freienstein', value: 'Freienstein' },
  { label: 'Freiensteinau', value: 'Freiensteinau' },
  { label: 'Freienwill', value: 'Freienwill' },
  { label: 'Freigné', value: 'Freigné' },
  { label: 'Freihung', value: 'Freihung' },
  { label: 'Freila', value: 'Freila' },
  { label: 'Freiland Bei Deutschlandsberg', value: 'Freiland Bei Deutschlandsberg' },
  { label: 'Freilassing', value: 'Freilassing' },
  { label: 'Frei-Laubersheim', value: 'Frei-Laubersheim' },
  { label: 'Freindorf', value: 'Freindorf' },
  { label: 'Freineda', value: 'Freineda' },
  { label: 'Freinsheim', value: 'Freinsheim' },
  { label: 'Freire', value: 'Freire' },
  { label: 'Freiria', value: 'Freiria' },
  { label: 'Freirina', value: 'Freirina' },
  { label: 'Freisbach', value: 'Freisbach' },
  { label: 'Freisen', value: 'Freisen' },
  { label: 'Freising', value: 'Freising' },
  { label: 'Freistadt', value: 'Freistadt' },
  { label: 'Freital', value: 'Freital' },
  { label: 'Freixeda Do Torrão', value: 'Freixeda Do Torrão' },
  { label: 'Freixedas', value: 'Freixedas' },
  { label: 'Freixo', value: 'Freixo' },
  { label: 'Freixo De Espada À Cinta', value: 'Freixo De Espada À Cinta' },
  { label: 'Freixo De Numão', value: 'Freixo De Numão' },
  { label: 'Fréjairolles', value: 'Fréjairolles' },
  { label: 'Frejlev', value: 'Frejlev' },
  { label: 'Fréjus', value: 'Fréjus' },
  { label: 'Frekhaug', value: 'Frekhaug' },
  { label: 'Fréland', value: 'Fréland' },
  { label: 'Frelinghien', value: 'Frelinghien' },
  { label: 'Fremantle', value: 'Fremantle' },
  { label: 'Fremdingen', value: 'Fremdingen' },
  { label: 'Fremington', value: 'Fremington' },
  { label: 'Fremont', value: 'Fremont' },
  { label: 'Fremont', value: 'Fremont' },
  { label: 'Fremont', value: 'Fremont' },
  { label: 'Fremont', value: 'Fremont' },
  { label: 'Fremont', value: 'Fremont' },
  { label: 'Fremont', value: 'Fremont' },
  { label: 'Fremont', value: 'Fremont' },
  { label: 'Fremont County', value: 'Fremont County' },
  { label: 'Fremont County', value: 'Fremont County' },
  { label: 'Fremont County', value: 'Fremont County' },
  { label: 'Fremont County', value: 'Fremont County' },
  { label: 'Frénaros', value: 'Frénaros' },
  { label: 'French Camp', value: 'French Camp' },
  { label: 'French Creek', value: 'French Creek' },
  { label: 'French Harbor', value: 'French Harbor' },
  { label: 'French Island', value: 'French Island' },
  { label: 'French Lick', value: 'French Lick' },
  { label: 'French Park', value: 'French Park' },
  { label: 'French Rocks', value: 'French Rocks' },
  { label: 'French Settlement', value: 'French Settlement' },
  { label: 'Frenchburg', value: 'Frenchburg' },
  { label: 'Frenchs Forest', value: 'Frenchs Forest' },
  { label: 'Frenchtown', value: 'Frenchtown' },
  { label: 'Frenchtown', value: 'Frenchtown' },
  { label: 'Frenchville', value: 'Frenchville' },
  { label: 'Frenchville', value: 'Frenchville' },
  { label: 'Frenda', value: 'Frenda' },
  { label: 'Freneuse', value: 'Freneuse' },
  { label: 'Frenkendorf', value: 'Frenkendorf' },
  { label: 'Frensdorf', value: 'Frensdorf' },
  { label: 'Frenštát Pod Radhoštěm', value: 'Frenštát Pod Radhoštěm' },
  { label: 'Frépillon', value: 'Frépillon' },
  { label: 'Freren', value: 'Freren' },
  { label: 'Fresach', value: 'Fresach' },
  { label: 'Fresagrandinaria', value: 'Fresagrandinaria' },
  { label: 'Frescada', value: 'Frescada' },
  { label: 'Fréscano', value: 'Fréscano' },
  { label: 'Fresen', value: 'Fresen' },
  { label: 'Fresh Meadows', value: 'Fresh Meadows' },
  { label: 'Freshwater', value: 'Freshwater' },
  { label: 'Freshwater', value: 'Freshwater' },
  { label: 'Freshwater', value: 'Freshwater' },
  { label: 'Fresia', value: 'Fresia' },
  { label: 'Fresnay-Sur-Sarthe', value: 'Fresnay-Sur-Sarthe' },
  { label: 'Fresneda De Altarejos', value: 'Fresneda De Altarejos' },
  { label: 'Fresneda De Cuéllar', value: 'Fresneda De Cuéllar' },
  { label: 'Fresneda De La Sierra', value: 'Fresneda De La Sierra' },
  { label: 'Fresneda De La Sierra Tirón', value: 'Fresneda De La Sierra Tirón' },
  { label: 'Fresneda La', value: 'Fresneda La' },
  { label: 'Fresnedilla', value: 'Fresnedilla' },
  { label: 'Fresnedillas', value: 'Fresnedillas' },
  { label: 'Fresnedillas De La Oliva', value: 'Fresnedillas De La Oliva' },
  { label: 'Fresnedoso', value: 'Fresnedoso' },
  { label: 'Fresnedoso De Ibor', value: 'Fresnedoso De Ibor' },
  { label: 'Fresneña', value: 'Fresneña' },
  { label: 'Fresnes', value: 'Fresnes' },
  { label: 'Fresnes-Sur-Escaut', value: 'Fresnes-Sur-Escaut' },
  { label: 'Fresnillo', value: 'Fresnillo' },
  { label: 'Fresnillo De Las Dueñas', value: 'Fresnillo De Las Dueñas' },
  { label: 'Fresno', value: 'Fresno' },
  { label: 'Fresno', value: 'Fresno' },
  { label: 'Fresno', value: 'Fresno' },
  { label: 'Fresno Alhándiga', value: 'Fresno Alhándiga' },
  { label: 'Fresno County', value: 'Fresno County' },
  { label: 'Fresno De Cantespino', value: 'Fresno De Cantespino' },
  { label: 'Fresno De Caracena', value: 'Fresno De Caracena' },
  { label: 'Fresno De La Fuente', value: 'Fresno De La Fuente' },
  { label: 'Fresno De La Polvorosa', value: 'Fresno De La Polvorosa' },
  { label: 'Fresno De La Ribera', value: 'Fresno De La Ribera' },
  { label: 'Fresno De La Vega', value: 'Fresno De La Vega' },
  { label: 'Fresno De Río Tirón', value: 'Fresno De Río Tirón' },
  { label: 'Fresno De Rodilla', value: 'Fresno De Rodilla' },
  { label: 'Fresno De Sayago', value: 'Fresno De Sayago' },
  { label: 'Fresno De Torote', value: 'Fresno De Torote' },
  { label: 'Fresno Del Río', value: 'Fresno Del Río' },
  { label: 'Fresno El', value: 'Fresno El' },
  { label: 'Fresno El Viejo', value: 'Fresno El Viejo' },
  { label: 'Fresno Nichi', value: 'Fresno Nichi' },
  { label: 'Fresnoy-Le-Grand', value: 'Fresnoy-Le-Grand' },
  { label: 'Fresonara', value: 'Fresonara' },
  { label: 'Fressenneville', value: 'Fressenneville' },
  { label: 'Fresse-Sur-Moselle', value: 'Fresse-Sur-Moselle' },
  { label: 'Fréthun', value: 'Fréthun' },
  { label: 'Fretin', value: 'Fretin' },
  { label: 'Freuchie', value: 'Freuchie' },
  { label: 'Freudenberg', value: 'Freudenberg' },
  { label: 'Freudenberg', value: 'Freudenberg' },
  { label: 'Freudenberg', value: 'Freudenberg' },
  { label: 'Freudenburg', value: 'Freudenburg' },
  { label: 'Freudenstadt', value: 'Freudenstadt' },
  { label: 'Freudental', value: 'Freudental' },
  { label: 'Frévent', value: 'Frévent' },
  { label: 'Frewsburg', value: 'Frewsburg' },
  { label: 'Freyburg', value: 'Freyburg' },
  { label: 'Freyming-Merlebach', value: 'Freyming-Merlebach' },
  { label: 'Freystadt', value: 'Freystadt' },
  { label: 'Freyung', value: 'Freyung' },
  { label: 'Fria', value: 'Fria' },
  { label: 'Friars Point', value: 'Friars Point' },
  { label: 'Frías', value: 'Frías' },
  { label: 'Frías', value: 'Frías' },
  { label: 'Frías De Albarracín', value: 'Frías De Albarracín' },
  { label: 'Fribourg', value: 'Fribourg' },
  { label: 'Frick', value: 'Frick' },
  { label: 'Frickenhausen', value: 'Frickenhausen' },
  { label: 'Frickenhausen', value: 'Frickenhausen' },
  { label: 'Frickingen', value: 'Frickingen' },
  { label: 'Friday Harbor', value: 'Friday Harbor' },
  { label: 'Fridingen An Der Donau', value: 'Fridingen An Der Donau' },
  { label: 'Fridley', value: 'Fridley' },
  { label: 'Fridolfing', value: 'Fridolfing' },
  { label: 'Friedberg', value: 'Friedberg' },
  { label: 'Friedberg', value: 'Friedberg' },
  { label: 'Friedberg', value: 'Friedberg' },
  { label: 'Friedeburg', value: 'Friedeburg' },
  { label: 'Friedelsheim', value: 'Friedelsheim' },
  { label: 'Friedenau', value: 'Friedenau' },
  { label: 'Friedenfels', value: 'Friedenfels' },
  { label: 'Friedens', value: 'Friedens' },
  { label: 'Friedensburg', value: 'Friedensburg' },
  { label: 'Friedenweiler', value: 'Friedenweiler' },
  { label: 'Friedersdorf', value: 'Friedersdorf' },
  { label: 'Friedersdorf', value: 'Friedersdorf' },
  { label: 'Friedewald', value: 'Friedewald' },
  { label: 'Friedewald', value: 'Friedewald' },
  { label: 'Friedland', value: 'Friedland' },
  { label: 'Friedland', value: 'Friedland' },
  { label: 'Friedland', value: 'Friedland' },
  { label: 'Friedrichroda', value: 'Friedrichroda' },
  { label: 'Friedrichsbrunn', value: 'Friedrichsbrunn' },
  { label: 'Friedrichsdorf', value: 'Friedrichsdorf' },
  { label: 'Friedrichsfelde', value: 'Friedrichsfelde' },
  { label: 'Friedrichshafen', value: 'Friedrichshafen' },
  { label: 'Friedrichshagen', value: 'Friedrichshagen' },
  { label: 'Friedrichshain', value: 'Friedrichshain' },
  { label: 'Friedrichskoog', value: 'Friedrichskoog' },
  { label: 'Friedrichstadt', value: 'Friedrichstadt' },
  { label: 'Friedrichstadt', value: 'Friedrichstadt' },
  { label: 'Friedrichsthal', value: 'Friedrichsthal' },
  { label: 'Frielas', value: 'Frielas' },
  { label: 'Frielendorf', value: 'Frielendorf' },
  { label: 'Friemar', value: 'Friemar' },
  { label: 'Friend', value: 'Friend' },
  { label: 'Friendly', value: 'Friendly' },
  { label: 'Friendship', value: 'Friendship' },
  { label: 'Friendship', value: 'Friendship' },
  { label: 'Friendship', value: 'Friendship' },
  { label: 'Friendship', value: 'Friendship' },
  { label: 'Friendship Gap', value: 'Friendship Gap' },
  { label: 'Friendship Village', value: 'Friendship Village' },
  { label: 'Friendswood', value: 'Friendswood' },
  { label: 'Friera De Valverde', value: 'Friera De Valverde' },
  { label: 'Friesach', value: 'Friesach' },
  { label: 'Friesack', value: 'Friesack' },
  { label: 'Friese Buurt', value: 'Friese Buurt' },
  { label: 'Friesenhagen', value: 'Friesenhagen' },
  { label: 'Friesenried', value: 'Friesenried' },
  { label: 'Friesoythe', value: 'Friesoythe' },
  { label: 'Frigento', value: 'Frigento' },
  { label: 'Frigiliana', value: 'Frigiliana' },
  { label: 'Frignano', value: 'Frignano' },
  { label: 'Frignicourt', value: 'Frignicourt' },
  { label: 'Frillesås', value: 'Frillesås' },
  { label: 'Frimley', value: 'Frimley' },
  { label: 'Frinco', value: 'Frinco' },
  { label: 'Frinton-On-Sea', value: 'Frinton-On-Sea' },
  { label: 'Frio County', value: 'Frio County' },
  { label: 'Friockheim', value: 'Friockheim' },
  { label: 'Friol', value: 'Friol' },
  { label: 'Friolzheim', value: 'Friolzheim' },
  { label: 'Friona', value: 'Friona' },
  { label: 'Frisa', value: 'Frisa' },
  { label: 'Frisanco', value: 'Frisanco' },
  { label: 'Frisange', value: 'Frisange' },
  { label: 'Frisco', value: 'Frisco' },
  { label: 'Frisco', value: 'Frisco' },
  { label: 'Frisco City', value: 'Frisco City' },
  { label: 'Fristad', value: 'Fristad' },
  { label: 'Friston', value: 'Friston' },
  { label: 'Fritch', value: 'Fritch' },
  { label: 'Fritsla', value: 'Fritsla' },
  { label: 'Frittlingen', value: 'Frittlingen' },
  { label: 'Fritz Creek', value: 'Fritz Creek' },
  { label: 'Fritzens', value: 'Fritzens' },
  { label: 'Fritzlar', value: 'Fritzlar' },
  { label: 'Friville-Escarbotin', value: 'Friville-Escarbotin' },
  { label: 'Frizington', value: 'Frizington' },
  { label: 'Frodsham', value: 'Frodsham' },
  { label: 'Froges', value: 'Froges' },
  { label: 'Frogn', value: 'Frogn' },
  { label: 'Frogner', value: 'Frogner' },
  { label: 'Frohburg', value: 'Frohburg' },
  { label: 'Frohnau', value: 'Frohnau' },
  { label: 'Frohnleiten', value: 'Frohnleiten' },
  { label: 'Frohsdorf', value: 'Frohsdorf' },
  { label: 'Froidchapelle', value: 'Froidchapelle' },
  { label: 'Froideconche', value: 'Froideconche' },
  { label: 'Froideville', value: 'Froideville' },
  { label: 'Froidfond', value: 'Froidfond' },
  { label: 'Frolishchi', value: 'Frolishchi' },
  { label: 'Frolovo', value: 'Frolovo' },
  { label: 'Froly', value: 'Froly' },
  { label: 'Frombork', value: 'Frombork' },
  { label: 'Frome', value: 'Frome' },
  { label: 'Frome', value: 'Frome' },
  { label: 'Fromelennes', value: 'Fromelennes' },
  { label: 'Frómista', value: 'Frómista' },
  { label: 'Froncles', value: 'Froncles' },
  { label: 'Fröndenberg', value: 'Fröndenberg' },
  { label: 'Fronhausen', value: 'Fronhausen' },
  { label: 'Fronreute', value: 'Fronreute' },
  { label: 'Fronsac', value: 'Fronsac' },
  { label: 'Front', value: 'Front' },
  { label: 'Front Royal', value: 'Front Royal' },
  { label: 'Fronteira', value: 'Fronteira' },
  { label: 'Fronteira', value: 'Fronteira' },
  { label: 'Fronteira Dos Vales', value: 'Fronteira Dos Vales' },
  { label: 'Fronteiras', value: 'Fronteiras' },
  { label: 'Frontenac', value: 'Frontenac' },
  { label: 'Frontenac', value: 'Frontenac' },
  { label: 'Frontenay-Rohan-Rohan', value: 'Frontenay-Rohan-Rohan' },
  { label: 'Frontenex', value: 'Frontenex' },
  { label: 'Frontenhausen', value: 'Frontenhausen' },
  { label: 'Frontera', value: 'Frontera' },
  { label: 'Frontera', value: 'Frontera' },
  { label: 'Frontera', value: 'Frontera' },
  { label: 'Frontera Corozal', value: 'Frontera Corozal' },
  { label: 'Frontera Hidalgo', value: 'Frontera Hidalgo' },
  { label: 'Frontera La', value: 'Frontera La' },
  { label: 'Fronteras', value: 'Fronteras' },
  { label: 'Frontier County', value: 'Frontier County' },
  { label: 'Frontignan', value: 'Frontignan' },
  { label: 'Frontino', value: 'Frontino' },
  { label: 'Frontino', value: 'Frontino' },
  { label: 'Fronton', value: 'Fronton' },
  { label: 'Frontonas', value: 'Frontonas' },
  { label: 'Frontone', value: 'Frontone' },
  { label: 'Frontovoye', value: 'Frontovoye' },
  { label: 'Frösakull', value: 'Frösakull' },
  { label: 'Frose', value: 'Frose' },
  { label: 'Frosinone', value: 'Frosinone' },
  { label: 'Frosolone', value: 'Frosolone' },
  { label: 'Frossasco', value: 'Frossasco' },
  { label: 'Frossay', value: 'Frossay' },
  { label: 'Frossos', value: 'Frossos' },
  { label: 'Frosta', value: 'Frosta' },
  { label: 'Frostburg', value: 'Frostburg' },
  { label: 'Frostproof', value: 'Frostproof' },
  { label: 'Frotey-Lès-Vesoul', value: 'Frotey-Lès-Vesoul' },
  { label: 'Frouard', value: 'Frouard' },
  { label: 'Frouzins', value: 'Frouzins' },
  { label: 'Frövi', value: 'Frövi' },
  { label: 'Frøya', value: 'Frøya' },
  { label: 'Frugarolo', value: 'Frugarolo' },
  { label: 'Fruges', value: 'Fruges' },
  { label: 'Fruit Cove', value: 'Fruit Cove' },
  { label: 'Fruit Heights', value: 'Fruit Heights' },
  { label: 'Fruit Hill', value: 'Fruit Hill' },
  { label: 'Fruita', value: 'Fruita' },
  { label: 'Fruitdale', value: 'Fruitdale' },
  { label: 'Fruitfulvale', value: 'Fruitfulvale' },
  { label: 'Fruitland', value: 'Fruitland' },
  { label: 'Fruitland', value: 'Fruitland' },
  { label: 'Fruitland', value: 'Fruitland' },
  { label: 'Fruitland Park', value: 'Fruitland Park' },
  { label: 'Fruitport', value: 'Fruitport' },
  { label: 'Fruitridge Pocket', value: 'Fruitridge Pocket' },
  { label: 'Fruitvale', value: 'Fruitvale' },
  { label: 'Fruitvale', value: 'Fruitvale' },
  { label: 'Fruitville', value: 'Fruitville' },
  { label: 'Fruiz', value: 'Fruiz' },
  { label: 'Frumales', value: 'Frumales' },
  { label: 'Frumoasa', value: 'Frumoasa' },
  { label: 'Frumoasa', value: 'Frumoasa' },
  { label: 'Frumoasa', value: 'Frumoasa' },
  { label: 'Frumosu', value: 'Frumosu' },
  { label: 'Frumuşani', value: 'Frumuşani' },
  { label: 'Frumușelu', value: 'Frumușelu' },
  { label: 'Frumușeni', value: 'Frumușeni' },
  { label: 'Frumuşica', value: 'Frumuşica' },
  { label: 'Frumuşiţa', value: 'Frumuşiţa' },
  { label: 'Fruntișeni', value: 'Fruntișeni' },
  { label: 'Frunze', value: 'Frunze' },
  { label: 'Frunzeni', value: 'Frunzeni' },
  { label: 'Frunzenskiy Rayon', value: 'Frunzenskiy Rayon' },
  { label: 'Frunzi', value: 'Frunzi' },
  { label: 'Frunzyenski Rayon', value: 'Frunzyenski Rayon' },
  { label: 'Fruta De Leite', value: 'Fruta De Leite' },
  { label: 'Frutal', value: 'Frutal' },
  { label: 'Frutigen', value: 'Frutigen' },
  { label: 'Frutigen-Niedersimmental District', value: 'Frutigen-Niedersimmental District' },
  { label: 'Frutillar', value: 'Frutillar' },
  { label: 'Frutuoso Gomes', value: 'Frutuoso Gomes' },
  { label: 'Fry', value: 'Fry' },
  { label: 'Fryanovo', value: 'Fryanovo' },
  { label: 'Fryazevo', value: 'Fryazevo' },
  { label: 'Fryazino', value: 'Fryazino' },
  { label: 'Fryčovice', value: 'Fryčovice' },
  { label: 'Frydek', value: 'Frydek' },
  { label: 'Frýdek-Místek', value: 'Frýdek-Místek' },
  { label: 'Frýdlant', value: 'Frýdlant' },
  { label: 'Frýdlant Nad Ostravicí', value: 'Frýdlant Nad Ostravicí' },
  { label: 'Frydman', value: 'Frydman' },
  { label: 'Frydrychowice', value: 'Frydrychowice' },
  { label: 'Fryeburg', value: 'Fryeburg' },
  { label: 'Frymburk', value: 'Frymburk' },
  { label: 'Fryšták', value: 'Fryšták' },
  { label: 'Frysztak', value: 'Frysztak' },
  { label: 'Fteliá', value: 'Fteliá' },
  { label: 'Fu’An', value: 'Fu’An' },
  { label: 'Fubao', value: 'Fubao' },
  { label: 'Fubine', value: 'Fubine' },
  { label: 'Fublaines', value: 'Fublaines' },
  { label: 'Fucecchio', value: 'Fucecchio' },
  { label: 'Fuchsmühl', value: 'Fuchsmühl' },
  { label: 'Fuchsstadt', value: 'Fuchsstadt' },
  { label: 'Fuchūchō', value: 'Fuchūchō' },
  { label: 'Fuchū-Shi', value: 'Fuchū-Shi' },
  { label: 'Fuchū-Shi', value: 'Fuchū-Shi' },
  { label: 'Fuding', value: 'Fuding' },
  { label: 'Fuefuki-Shi', value: 'Fuefuki-Shi' },
  { label: 'Fuembellida', value: 'Fuembellida' },
  { label: 'Fuencaliente', value: 'Fuencaliente' },
  { label: 'Fuencaliente De La Palma', value: 'Fuencaliente De La Palma' },
  { label: 'Fuencarral-El Pardo', value: 'Fuencarral-El Pardo' },
  { label: 'Fuencemillán', value: 'Fuencemillán' },
  { label: 'Fuendejalón', value: 'Fuendejalón' },
  { label: 'Fuendetodos', value: 'Fuendetodos' },
  { label: 'Fuenferrada', value: 'Fuenferrada' },
  { label: 'Fuengirola', value: 'Fuengirola' },
  { label: 'Fuenlabrada', value: 'Fuenlabrada' },
  { label: 'Fuenlabrada De Los Montes', value: 'Fuenlabrada De Los Montes' },
  { label: 'Fuenllana', value: 'Fuenllana' },
  { label: 'Fuenmayor', value: 'Fuenmayor' },
  { label: 'Fuensaldaña', value: 'Fuensaldaña' },
  { label: 'Fuensalida', value: 'Fuensalida' },
  { label: 'Fuensanta', value: 'Fuensanta' },
  { label: 'Fuensanta De Martos', value: 'Fuensanta De Martos' },
  { label: 'Fuente Álamo De Murcia', value: 'Fuente Álamo De Murcia' },
  { label: 'Fuente De Cantos', value: 'Fuente De Cantos' },
  { label: 'Fuente De Oro', value: 'Fuente De Oro' },
  { label: 'Fuente De Pedro Naharro', value: 'Fuente De Pedro Naharro' },
  { label: 'Fuente De Piedra', value: 'Fuente De Piedra' },
  { label: 'Fuente De San Esteban La', value: 'Fuente De San Esteban La' },
  { label: 'Fuente De Santa Cruz', value: 'Fuente De Santa Cruz' },
  { label: 'Fuente Del Arco', value: 'Fuente Del Arco' },
  { label: 'Fuente Del Maestre', value: 'Fuente Del Maestre' },
  { label: 'Fuente El Fresno', value: 'Fuente El Fresno' },
  { label: 'Fuente El Olmo De Fuentidueña', value: 'Fuente El Olmo De Fuentidueña' },
  { label: 'Fuente El Olmo De Íscar', value: 'Fuente El Olmo De Íscar' },
  { label: 'Fuente El Saúz', value: 'Fuente El Saúz' },
  { label: 'Fuente El Saz', value: 'Fuente El Saz' },
  { label: 'Fuente El Saz De Jarama', value: 'Fuente El Saz De Jarama' },
  { label: 'Fuente El Sol', value: 'Fuente El Sol' },
  { label: 'Fuente Encalada', value: 'Fuente Encalada' },
  { label: 'Fuente La Lancha', value: 'Fuente La Lancha' },
  { label: 'Fuente La Reina', value: 'Fuente La Reina' },
  { label: 'Fuente Obejuna', value: 'Fuente Obejuna' },
  { label: 'Fuente Palmera', value: 'Fuente Palmera' },
  { label: 'Fuente Vaqueros', value: 'Fuente Vaqueros' },
  { label: 'Fuente-Álamo', value: 'Fuente-Álamo' },
  { label: 'Fuente-Álamo De Murcia', value: 'Fuente-Álamo De Murcia' },
  { label: 'Fuentealbilla', value: 'Fuentealbilla' },
  { label: 'Fuentearmegil', value: 'Fuentearmegil' },
  { label: 'Fuentebureba', value: 'Fuentebureba' },
  { label: 'Fuentecambrón', value: 'Fuentecambrón' },
  { label: 'Fuentecantos', value: 'Fuentecantos' },
  { label: 'Fuentecén', value: 'Fuentecén' },
  { label: 'Fuenteguinaldo', value: 'Fuenteguinaldo' },
  { label: 'Fuenteheridos', value: 'Fuenteheridos' },
  { label: 'Fuentelahiguera De Albatages', value: 'Fuentelahiguera De Albatages' },
  { label: 'Fuentelapeña', value: 'Fuentelapeña' },
  { label: 'Fuentelcésped', value: 'Fuentelcésped' },
  { label: 'Fuentelencina', value: 'Fuentelencina' },
  { label: 'Fuentelespino De Haro', value: 'Fuentelespino De Haro' },
  { label: 'Fuentelespino De Moya', value: 'Fuentelespino De Moya' },
  { label: 'Fuenteliante', value: 'Fuenteliante' },
  { label: 'Fuentelisendo', value: 'Fuentelisendo' },
  { label: 'Fuentelmonge', value: 'Fuentelmonge' },
  { label: 'Fuentelsaz', value: 'Fuentelsaz' },
  { label: 'Fuentelsaz De Soria', value: 'Fuentelsaz De Soria' },
  { label: 'Fuentelviejo', value: 'Fuentelviejo' },
  { label: 'Fuentemolinos', value: 'Fuentemolinos' },
  { label: 'Fuentenava De Jábaga', value: 'Fuentenava De Jábaga' },
  { label: 'Fuentenebro', value: 'Fuentenebro' },
  { label: 'Fuentenovilla', value: 'Fuentenovilla' },
  { label: 'Fuente-Olmedo', value: 'Fuente-Olmedo' },
  { label: 'Fuentepelayo', value: 'Fuentepelayo' },
  { label: 'Fuentepiñel', value: 'Fuentepiñel' },
  { label: 'Fuentepinilla', value: 'Fuentepinilla' },
  { label: 'Fuenterrebollo', value: 'Fuenterrebollo' },
  { label: 'Fuenterroble De Salvatierra', value: 'Fuenterroble De Salvatierra' },
  { label: 'Fuenterrobles', value: 'Fuenterrobles' },
  { label: 'Fuentes', value: 'Fuentes' },
  { label: 'Fuentes Calientes', value: 'Fuentes Calientes' },
  { label: 'Fuentes Claras', value: 'Fuentes Claras' },
  { label: 'Fuentes De Andalucía', value: 'Fuentes De Andalucía' },
  { label: 'Fuentes De Año', value: 'Fuentes De Año' },
  { label: 'Fuentes De Ayódar', value: 'Fuentes De Ayódar' },
  { label: 'Fuentes De Béjar', value: 'Fuentes De Béjar' },
  { label: 'Fuentes De Carbajal', value: 'Fuentes De Carbajal' },
  { label: 'Fuentes De Ebro', value: 'Fuentes De Ebro' },
  { label: 'Fuentes De Jiloca', value: 'Fuentes De Jiloca' },
  { label: 'Fuentes De León', value: 'Fuentes De León' },
  { label: 'Fuentes De Magaña', value: 'Fuentes De Magaña' },
  { label: 'Fuentes De Nava', value: 'Fuentes De Nava' },
  { label: 'Fuentes De Oñoro', value: 'Fuentes De Oñoro' },
  { label: 'Fuentes De Ropel', value: 'Fuentes De Ropel' },
  { label: 'Fuentes De Rubielos', value: 'Fuentes De Rubielos' },
  { label: 'Fuentes De Valdepero', value: 'Fuentes De Valdepero' },
  { label: 'Fuentes Del Valle', value: 'Fuentes Del Valle' },
  { label: 'Fuentesaúco', value: 'Fuentesaúco' },
  { label: 'Fuentesaúco De Fuentidueña', value: 'Fuentesaúco De Fuentidueña' },
  { label: 'Fuentesecas', value: 'Fuentesecas' },
  { label: 'Fuentesoto', value: 'Fuentesoto' },
  { label: 'Fuentespalda', value: 'Fuentespalda' },
  { label: 'Fuentespina', value: 'Fuentespina' },
  { label: 'Fuentespreadas', value: 'Fuentespreadas' },
  { label: 'Fuentestrún', value: 'Fuentestrún' },
  { label: 'Fuente-Tójar', value: 'Fuente-Tójar' },
  { label: 'Fuentezuelas', value: 'Fuentezuelas' },
  { label: 'Fuentidueña', value: 'Fuentidueña' },
  { label: 'Fuentidueña De Tajo', value: 'Fuentidueña De Tajo' },
  { label: 'Fuerte', value: 'Fuerte' },
  { label: 'Fuerte', value: 'Fuerte' },
  { label: 'Fuerte Del Rey', value: 'Fuerte Del Rey' },
  { label: 'Fuerte Olimpo', value: 'Fuerte Olimpo' },
  { label: 'Fuertescusa', value: 'Fuertescusa' },
  { label: 'Fueva La', value: 'Fueva La' },
  { label: 'Fügen', value: 'Fügen' },
  { label: 'Fügenberg', value: 'Fügenberg' },
  { label: 'Fuglafjørður', value: 'Fuglafjørður' },
  { label: 'Fuglebjerg', value: 'Fuglebjerg' },
  { label: 'Fugloy', value: 'Fugloy' },
  { label: 'Fuhlsbüttel', value: 'Fuhlsbüttel' },
  { label: 'Fuig', value: 'Fuig' },
  { label: 'Fuipiano Valle Imagna', value: 'Fuipiano Valle Imagna' },
  { label: 'Fuji Shi', value: 'Fuji Shi' },
  { label: 'Fujieda Shi', value: 'Fujieda Shi' },
  { label: 'Fujiidera-Shi', value: 'Fujiidera-Shi' },
  { label: 'Fujikawaguchiko', value: 'Fujikawaguchiko' },
  { label: 'Fujimi', value: 'Fujimi' },
  { label: 'Fujimino Shi', value: 'Fujimino Shi' },
  { label: 'Fujimi-Shi', value: 'Fujimi-Shi' },
  { label: 'Fujin', value: 'Fujin' },
  { label: 'Fujinomiya', value: 'Fujinomiya' },
  { label: 'Fujinomiya Shi', value: 'Fujinomiya Shi' },
  { label: 'Fujioka', value: 'Fujioka' },
  { label: 'Fujioka', value: 'Fujioka' },
  { label: 'Fujioka Shi', value: 'Fujioka Shi' },
  { label: 'Fujisawa', value: 'Fujisawa' },
  { label: 'Fujisawa Shi', value: 'Fujisawa Shi' },
  { label: 'Fujishiro', value: 'Fujishiro' },
  { label: 'Fujiyoshida', value: 'Fujiyoshida' },
  { label: 'Fuji-Yoshida Shi', value: 'Fuji-Yoshida Shi' },
  { label: 'Fukagawa', value: 'Fukagawa' },
  { label: 'Fukagawa-Shi', value: 'Fukagawa-Shi' },
  { label: 'Fukang', value: 'Fukang' },
  { label: 'Fukayachō', value: 'Fukayachō' },
  { label: 'Fukaya-Shi', value: 'Fukaya-Shi' },
  { label: 'Fukiage-Fujimi', value: 'Fukiage-Fujimi' },
  { label: 'Fukuchiyama-Shi', value: 'Fukuchiyama-Shi' },
  { label: 'Fukuechō', value: 'Fukuechō' },
  { label: 'Fukui-Shi', value: 'Fukui-Shi' },
  { label: 'Fukumitsu', value: 'Fukumitsu' },
  { label: 'Fukuoka', value: 'Fukuoka' },
  { label: 'Fukuoka-Shi', value: 'Fukuoka-Shi' },
  { label: 'Fukura', value: 'Fukura' },
  { label: 'Fukuroi', value: 'Fukuroi' },
  { label: 'Fukuroi-Shi', value: 'Fukuroi-Shi' },
  { label: 'Fukushima', value: 'Fukushima' },
  { label: 'Fukushima Shi', value: 'Fukushima Shi' },
  { label: 'Fukutsu Shi', value: 'Fukutsu Shi' },
  { label: 'Fukuyama', value: 'Fukuyama' },
  { label: 'Fukuyama Shi', value: 'Fukuyama Shi' },
  { label: 'Fulbourn', value: 'Fulbourn' },
  { label: 'Fulda', value: 'Fulda' },
  { label: 'Fulda', value: 'Fulda' },
  { label: 'Fuldatal', value: 'Fuldatal' },
  { label: 'Fulenbach', value: 'Fulenbach' },
  { label: 'Fulga De Jos', value: 'Fulga De Jos' },
  { label: 'Fulga De Sus', value: 'Fulga De Sus' },
  { label: 'Fulgatore-Torretta', value: 'Fulgatore-Torretta' },
  { label: 'Fulham', value: 'Fulham' },
  { label: 'Fulham Gardens', value: 'Fulham Gardens' },
  { label: 'Fuli', value: 'Fuli' },
  { label: 'Fuling', value: 'Fuling' },
  { label: 'Fuliola La', value: 'Fuliola La' },
  { label: 'Full Sutton', value: 'Full Sutton' },
  { label: 'Fulladu East', value: 'Fulladu East' },
  { label: 'Fulladu West', value: 'Fulladu West' },
  { label: 'Fullarton', value: 'Fullarton' },
  { label: 'Fulleda', value: 'Fulleda' },
  { label: 'Fuller Heights', value: 'Fuller Heights' },
  { label: 'Fullersfield', value: 'Fullersfield' },
  { label: 'Fullerswood', value: 'Fullerswood' },
  { label: 'Fullerton', value: 'Fullerton' },
  { label: 'Fullerton', value: 'Fullerton' },
  { label: 'Fullerton', value: 'Fullerton' },
  { label: 'Füllinsdorf', value: 'Füllinsdorf' },
  { label: 'Fully', value: 'Fully' },
  { label: 'Fulnek', value: 'Fulnek' },
  { label: 'Fülöpjakab', value: 'Fülöpjakab' },
  { label: 'Fülöpszállás', value: 'Fülöpszállás' },
  { label: 'Fulpmes', value: 'Fulpmes' },
  { label: 'Fulshear', value: 'Fulshear' },
  { label: 'Fulton', value: 'Fulton' },
  { label: 'Fulton', value: 'Fulton' },
  { label: 'Fulton', value: 'Fulton' },
  { label: 'Fulton', value: 'Fulton' },
  { label: 'Fulton', value: 'Fulton' },
  { label: 'Fulton', value: 'Fulton' },
  { label: 'Fulton', value: 'Fulton' },
  { label: 'Fulton County', value: 'Fulton County' },
  { label: 'Fulton County', value: 'Fulton County' },
  { label: 'Fulton County', value: 'Fulton County' },
  { label: 'Fulton County', value: 'Fulton County' },
  { label: 'Fulton County', value: 'Fulton County' },
  { label: 'Fulton County', value: 'Fulton County' },
  { label: 'Fulton County', value: 'Fulton County' },
  { label: 'Fulton County', value: 'Fulton County' },
  { label: 'Fultondale', value: 'Fultondale' },
  { label: 'Fuman', value: 'Fuman' },
  { label: 'Fumane', value: 'Fumane' },
  { label: 'Fumay', value: 'Fumay' },
  { label: 'Fumel', value: 'Fumel' },
  { label: 'Fumone', value: 'Fumone' },
  { label: 'Funabashi-Shi', value: 'Funabashi-Shi' },
  { label: 'Funadhoo', value: 'Funadhoo' },
  { label: 'Funafuti', value: 'Funafuti' },
  { label: 'Funaishikawa', value: 'Funaishikawa' },
  { label: 'Funchal', value: 'Funchal' },
  { label: 'Fundación', value: 'Fundación' },
  { label: 'Fundación', value: 'Fundación' },
  { label: 'Fundão', value: 'Fundão' },
  { label: 'Fundão', value: 'Fundão' },
  { label: 'Fundata', value: 'Fundata' },
  { label: 'Fundătura', value: 'Fundătura' },
  { label: 'Fundeni', value: 'Fundeni' },
  { label: 'Fundeni', value: 'Fundeni' },
  { label: 'Fundeni', value: 'Fundeni' },
  { label: 'Fundeni', value: 'Fundeni' },
  { label: 'Fundoaia', value: 'Fundoaia' },
  { label: 'Fundong', value: 'Fundong' },
  { label: 'Fundu Moldovei', value: 'Fundu Moldovei' },
  { label: 'Fundu Răcăciuni', value: 'Fundu Răcăciuni' },
  { label: 'Fundulea', value: 'Fundulea' },
  { label: 'Fundy Bay', value: 'Fundy Bay' },
  { label: 'Funehikimachi-Funehiki', value: 'Funehikimachi-Funehiki' },
  { label: 'Funes', value: 'Funes' },
  { label: 'Funes', value: 'Funes' },
  { label: 'Funes', value: 'Funes' },
  { label: 'Funes - Villnoess', value: 'Funes - Villnoess' },
  { label: 'Fünfstetten', value: 'Fünfstetten' },
  { label: 'Funilândia', value: 'Funilândia' },
  { label: 'Funtana', value: 'Funtana' },
  { label: 'Funtana-Fontane', value: 'Funtana-Fontane' },
  { label: 'Funtua', value: 'Funtua' },
  { label: 'Funza', value: 'Funza' },
  { label: 'Fuorigrotta', value: 'Fuorigrotta' },
  { label: 'Fuorni', value: 'Fuorni' },
  { label: 'Fuqing', value: 'Fuqing' },
  { label: 'Fuquay-Varina', value: 'Fuquay-Varina' },
  { label: 'Fúquene', value: 'Fúquene' },
  { label: 'Furano-Shi', value: 'Furano-Shi' },
  { label: 'Furao', value: 'Furao' },
  { label: 'Furao', value: 'Furao' },
  { label: 'Furato', value: 'Furato' },
  { label: 'Furcenii Noi', value: 'Furcenii Noi' },
  { label: 'Furci', value: 'Furci' },
  { label: 'Furci Siculo', value: 'Furci Siculo' },
  { label: 'Furculești', value: 'Furculești' },
  { label: 'Furdenheim', value: 'Furdenheim' },
  { label: 'Furesø Kommune', value: 'Furesø Kommune' },
  { label: 'Fürfeld', value: 'Fürfeld' },
  { label: 'Furiani', value: 'Furiani' },
  { label: 'Furmanov', value: 'Furmanov' },
  { label: 'Furnace Green', value: 'Furnace Green' },
  { label: 'Furnari', value: 'Furnari' },
  { label: 'Furnas', value: 'Furnas' },
  { label: 'Furnas County', value: 'Furnas County' },
  { label: 'Fürnitz', value: 'Fürnitz' },
  { label: 'Furore', value: 'Furore' },
  { label: 'Fürstenau', value: 'Fürstenau' },
  { label: 'Fürstenberg', value: 'Fürstenberg' },
  { label: 'Fürstenberg', value: 'Fürstenberg' },
  { label: 'Fürstenberg', value: 'Fürstenberg' },
  { label: 'Fürsteneck', value: 'Fürsteneck' },
  { label: 'Fürstenfeld', value: 'Fürstenfeld' },
  { label: 'Fürstenfeldbruck', value: 'Fürstenfeldbruck' },
  { label: 'Fürstenhausen', value: 'Fürstenhausen' },
  { label: 'Fürstenstein', value: 'Fürstenstein' },
  { label: 'Fürstenwalde', value: 'Fürstenwalde' },
  { label: 'Fürstenzell', value: 'Fürstenzell' },
  { label: 'Furtei', value: 'Furtei' },
  { label: 'Furth', value: 'Furth' },
  { label: 'Fürth', value: 'Fürth' },
  { label: 'Furth An Der Triesting', value: 'Furth An Der Triesting' },
  { label: 'Furth Bei Göttweig', value: 'Furth Bei Göttweig' },
  { label: 'Furth Im Wald', value: 'Furth Im Wald' },
  { label: 'Fürthen', value: 'Fürthen' },
  { label: 'Furtunești', value: 'Furtunești' },
  { label: 'Furtwangen', value: 'Furtwangen' },
  { label: 'Furukawa', value: 'Furukawa' },
  { label: 'Furulund', value: 'Furulund' },
  { label: 'Furulund', value: 'Furulund' },
  { label: 'Fusa', value: 'Fusa' },
  { label: 'Fusagasugá', value: 'Fusagasugá' },
  { label: 'Fuscaldo', value: 'Fuscaldo' },
  { label: 'Fuschl Am See', value: 'Fuschl Am See' },
  { label: 'Fushë-Arrëz', value: 'Fushë-Arrëz' },
  { label: 'Fushë-Krujë', value: 'Fushë-Krujë' },
  { label: 'Fushun', value: 'Fushun' },
  { label: 'Fusignano', value: 'Fusignano' },
  { label: 'Fusine', value: 'Fusine' },
  { label: 'Fusine', value: 'Fusine' },
  { label: 'Fussa', value: 'Fussa' },
  { label: 'Fußach', value: 'Fußach' },
  { label: 'Fussels Corner', value: 'Fussels Corner' },
  { label: 'Füssen', value: 'Füssen' },
  { label: 'Fußgönheim', value: 'Fußgönheim' },
  { label: 'Fussy', value: 'Fussy' },
  { label: 'Fustat', value: 'Fustat' },
  { label: 'Fustiñana', value: 'Fustiñana' },
  { label: 'Futaleufú', value: 'Futaleufú' },
  { label: 'Futani', value: 'Futani' },
  { label: 'Futrono', value: 'Futrono' },
  { label: 'Futtsu', value: 'Futtsu' },
  { label: 'Fuvahmulah', value: 'Fuvahmulah' },
  { label: 'Fuveau', value: 'Fuveau' },
  { label: 'Fuwayriţ', value: 'Fuwayriţ' },
  { label: 'Fuwwah', value: 'Fuwwah' },
  { label: 'Fuxin', value: 'Fuxin' },
  { label: 'Fuyang', value: 'Fuyang' },
  { label: 'Fuyang', value: 'Fuyang' },
  { label: 'Fuyang Shi', value: 'Fuyang Shi' },
  { label: 'Fuyu', value: 'Fuyu' },
  { label: 'Fuyu', value: 'Fuyu' },
  { label: 'Fuyuan', value: 'Fuyuan' },
  { label: 'Füzesabony', value: 'Füzesabony' },
  { label: 'Füzesabonyi Járás', value: 'Füzesabonyi Járás' },
  { label: 'Füzesgyarmat', value: 'Füzesgyarmat' },
  { label: 'Fuzeta', value: 'Fuzeta' },
  { label: 'Fuzhou', value: 'Fuzhou' },
  { label: 'Fuzhuang', value: 'Fuzhuang' },
  { label: 'Fužine', value: 'Fužine' },
  { label: 'Fyffe', value: 'Fyffe' },
  { label: 'Fylde', value: 'Fylde' },
  { label: 'Fylí', value: 'Fylí' },
  { label: 'Fyllinge', value: 'Fyllinge' },
  { label: 'Fyodorovka', value: 'Fyodorovka' },
  { label: 'Fyresdal', value: 'Fyresdal' },
  { label: 'Fyteíes', value: 'Fyteíes' },
  { label: 'Fyzabad', value: 'Fyzabad' },
  { label: 'G. L. Garcia', value: 'G. L. Garcia' },
  { label: 'G‘Azalkent', value: 'G‘Azalkent' },
  { label: 'G‘Uzor', value: 'G‘Uzor' },
  { label: 'Gaaden', value: 'Gaaden' },
  { label: 'Gaal', value: 'Gaal' },
  { label: 'Gaalkacyo', value: 'Gaalkacyo' },
  { label: 'Gabaldon', value: 'Gabaldon' },
  { label: 'Gabaldon', value: 'Gabaldon' },
  { label: 'Gabaldón', value: 'Gabaldón' },
  { label: 'Gabane', value: 'Gabane' },
  { label: 'Gabao', value: 'Gabao' },
  { label: 'Gabarin', value: 'Gabarin' },
  { label: 'Gabarret', value: 'Gabarret' },
  { label: 'Gabas', value: 'Gabas' },
  { label: 'Gabas', value: 'Gabas' },
  { label: 'Gabawan', value: 'Gabawan' },
  { label: 'Gabbio-Cereda-Ramate', value: 'Gabbio-Cereda-Ramate' },
  { label: 'Gabbioneta', value: 'Gabbioneta' },
  { label: 'Gabčíkovo', value: 'Gabčíkovo' },
  { label: 'Gabella', value: 'Gabella' },
  { label: 'Gabersdorf', value: 'Gabersdorf' },
  { label: 'Gabès', value: 'Gabès' },
  { label: 'Gabi', value: 'Gabi' },
  { label: 'Gabi', value: 'Gabi' },
  { label: 'Gabi', value: 'Gabi' },
  { label: 'Gabi', value: 'Gabi' },
  { label: 'Gabi', value: 'Gabi' },
  { label: 'Gabiano', value: 'Gabiano' },
  { label: 'Gabias Las', value: 'Gabias Las' },
  { label: 'Gabicce Mare', value: 'Gabicce Mare' },
  { label: 'Gąbin', value: 'Gąbin' },
  { label: 'Gabino Barreda', value: 'Gabino Barreda' },
  { label: 'Gabiria', value: 'Gabiria' },
  { label: 'Gablenz', value: 'Gablenz' },
  { label: 'Gablingen', value: 'Gablingen' },
  { label: 'Gablitz', value: 'Gablitz' },
  { label: 'Gaborone', value: 'Gaborone' },
  { label: 'Gabriel Esquinca', value: 'Gabriel Esquinca' },
  { label: 'Gabriel Hernández (Mancinas)', value: 'Gabriel Hernández (Mancinas)' },
  { label: 'Gabriel Leyva Solano (Zapotillo Dos)', value: 'Gabriel Leyva Solano (Zapotillo Dos)' },
  { label: 'Gabriel Leyva Velázquez', value: 'Gabriel Leyva Velázquez' },
  { label: 'Gabriel Monteiro', value: 'Gabriel Monteiro' },
  { label: 'Gabrovo', value: 'Gabrovo' },
  { label: 'Gabú', value: 'Gabú' },
  { label: 'Gabuyan', value: 'Gabuyan' },
  { label: 'Gabuyan', value: 'Gabuyan' },
  { label: 'Gabuyan', value: 'Gabuyan' },
  { label: 'Gaby', value: 'Gaby' },
  { label: 'Gać', value: 'Gać' },
  { label: 'Gacé', value: 'Gacé' },
  { label: 'Gachala', value: 'Gachala' },
  { label: 'Gachancipá', value: 'Gachancipá' },
  { label: 'Gachantivá', value: 'Gachantivá' },
  { label: 'Gachenbach', value: 'Gachenbach' },
  { label: 'Gachetá', value: 'Gachetá' },
  { label: 'Gachnang', value: 'Gachnang' },
  { label: 'Gachsaran', value: 'Gachsaran' },
  { label: 'Gachupines', value: 'Gachupines' },
  { label: 'Gacko', value: 'Gacko' },
  { label: 'Gadag', value: 'Gadag' },
  { label: 'Gadag-Betageri', value: 'Gadag-Betageri' },
  { label: 'Gadaley', value: 'Gadaley' },
  { label: 'Gadani', value: 'Gadani' },
  { label: 'Gadarwara', value: 'Gadarwara' },
  { label: 'Gadchiroli', value: 'Gadchiroli' },
  { label: 'Gaddi Annaram', value: 'Gaddi Annaram' },
  { label: 'Gadebusch', value: 'Gadebusch' },
  { label: 'Gadesco-Pieve Delmona', value: 'Gadesco-Pieve Delmona' },
  { label: 'Gadette', value: 'Gadette' },
  { label: 'Gadhada', value: 'Gadhada' },
  { label: 'Gadheim', value: 'Gadheim' },
  { label: 'Gadhinglaj', value: 'Gadhinglaj' },
  { label: 'Gâdinţi', value: 'Gâdinţi' },
  { label: 'Gado Bravo', value: 'Gado Bravo' },
  { label: 'Gadoni', value: 'Gadoni' },
  { label: 'Gádor', value: 'Gádor' },
  { label: 'Gádoros', value: 'Gádoros' },
  { label: 'Gądów Mały', value: 'Gądów Mały' },
  { label: 'Gadsden', value: 'Gadsden' },
  { label: 'Gadsden', value: 'Gadsden' },
  { label: 'Gadsden County', value: 'Gadsden County' },
  { label: 'Gadu', value: 'Gadu' },
  { label: 'Gadu', value: 'Gadu' },
  { label: 'Gadung', value: 'Gadung' },
  { label: 'Gadung', value: 'Gadung' },
  { label: 'Gadwal', value: 'Gadwal' },
  { label: 'Gadzhiyevo', value: 'Gadzhiyevo' },
  { label: 'Gaël', value: 'Gaël' },
  { label: 'Gaerwen', value: 'Gaerwen' },
  { label: 'Găeşti', value: 'Găeşti' },
  { label: 'Gaeta', value: 'Gaeta' },
  { label: 'Gafanha Da Encarnação', value: 'Gafanha Da Encarnação' },
  { label: 'Gafanha Da Nazaré', value: 'Gafanha Da Nazaré' },
  { label: 'Gaffney', value: 'Gaffney' },
  { label: 'Gaflenz', value: 'Gaflenz' },
  { label: 'Gafour', value: 'Gafour' },
  { label: 'Gafsa', value: 'Gafsa' },
  { label: 'Gagarawa', value: 'Gagarawa' },
  { label: 'Gagarin', value: 'Gagarin' },
  { label: 'Gagarin', value: 'Gagarin' },
  { label: 'Gagarin', value: 'Gagarin' },
  { label: 'Gagatli', value: 'Gagatli' },
  { label: 'Gage County', value: 'Gage County' },
  { label: 'Gage Park', value: 'Gage Park' },
  { label: 'Gagebrook', value: 'Gagebrook' },
  { label: 'Gageldonk', value: 'Gageldonk' },
  { label: 'Gägelow', value: 'Gägelow' },
  { label: 'Găgeni', value: 'Găgeni' },
  { label: 'Gages Lake', value: 'Gages Lake' },
  { label: 'Găgeşti', value: 'Găgeşti' },
  { label: 'Găgești', value: 'Găgești' },
  { label: 'Gaggenau', value: 'Gaggenau' },
  { label: 'Gaggi', value: 'Gaggi' },
  { label: 'Gaggiano', value: 'Gaggiano' },
  { label: 'Gaggino', value: 'Gaggino' },
  { label: 'Gaggio', value: 'Gaggio' },
  { label: 'Gaggio Montano', value: 'Gaggio Montano' },
  { label: 'Gagil Municipality', value: 'Gagil Municipality' },
  { label: 'Gagino', value: 'Gagino' },
  { label: 'Gaglianico', value: 'Gaglianico' },
  { label: 'Gagliano Aterno', value: 'Gagliano Aterno' },
  { label: 'Gagliano Castelferrato', value: 'Gagliano Castelferrato' },
  { label: 'Gagliano Del Capo', value: 'Gagliano Del Capo' },
  { label: 'Gagliato', value: 'Gagliato' },
  { label: 'Gagliole', value: 'Gagliole' },
  { label: 'Gagnac-Sur-Garonne', value: 'Gagnac-Sur-Garonne' },
  { label: 'Gagnef', value: 'Gagnef' },
  { label: 'Gagnoa', value: 'Gagnoa' },
  { label: 'Gagny', value: 'Gagny' },
  { label: 'Gagos', value: 'Gagos' },
  { label: 'Gagra', value: 'Gagra' },
  { label: 'Gagret', value: 'Gagret' },
  { label: 'Gahanna', value: 'Gahanna' },
  { label: 'Gahlon', value: 'Gahlon' },
  { label: 'Gahmar', value: 'Gahmar' },
  { label: 'Gahru', value: 'Gahru' },
  { label: 'Gahvareh', value: 'Gahvareh' },
  { label: 'Gaià', value: 'Gaià' },
  { label: 'Gaianes', value: 'Gaianes' },
  { label: 'Gaiano', value: 'Gaiano' },
  { label: 'Gaiarine', value: 'Gaiarine' },
  { label: 'Gaiba', value: 'Gaiba' },
  { label: 'Gaibandha ', value: 'Gaibandha ' },
  { label: 'Gaibanella-Santedigio', value: 'Gaibanella-Santedigio' },
  { label: 'Gaiberg', value: 'Gaiberg' },
  { label: 'Gaibiel', value: 'Gaibiel' },
  { label: 'Găiceana', value: 'Găiceana' },
  { label: 'Gaienhofen', value: 'Gaienhofen' },
  { label: 'Găiești', value: 'Găiești' },
  { label: 'Gaigeturi', value: 'Gaigeturi' },
  { label: 'Gaighata Community Development Block', value: 'Gaighata Community Development Block' },
  { label: 'Gail', value: 'Gail' },
  { label: 'Gaildorf', value: 'Gaildorf' },
  { label: 'Gailes', value: 'Gailes' },
  { label: 'Gailingen', value: 'Gailingen' },
  { label: 'Gailitz', value: 'Gailitz' },
  { label: 'Gaillac', value: 'Gaillac' },
  { label: 'Gaillac-Toulza', value: 'Gaillac-Toulza' },
  { label: 'Gaillan-En-Médoc', value: 'Gaillan-En-Médoc' },
  { label: 'Gaillard', value: 'Gaillard' },
  { label: 'Gaillefontaine', value: 'Gaillefontaine' },
  { label: 'Gaillimh', value: 'Gaillimh' },
  { label: 'Gaillon', value: 'Gaillon' },
  { label: 'Gaimán', value: 'Gaimán' },
  { label: 'Gaimersheim', value: 'Gaimersheim' },
  { label: 'Gaines County', value: 'Gaines County' },
  { label: 'Gainesboro', value: 'Gainesboro' },
  { label: 'Găinești', value: 'Găinești' },
  { label: 'Gainesville', value: 'Gainesville' },
  { label: 'Gainesville', value: 'Gainesville' },
  { label: 'Gainesville', value: 'Gainesville' },
  { label: 'Gainesville', value: 'Gainesville' },
  { label: 'Gainesville', value: 'Gainesville' },
  { label: 'Gainfarn', value: 'Gainfarn' },
  { label: 'Gainford', value: 'Gainford' },
  { label: 'Gaini', value: 'Gaini' },
  { label: 'Gainneville', value: 'Gainneville' },
  { label: 'Gainsborough', value: 'Gainsborough' },
  { label: 'Gaintza', value: 'Gaintza' },
  { label: 'Gainza', value: 'Gainza' },
  { label: 'Gaiola', value: 'Gaiola' },
  { label: 'Gaiole In Chianti', value: 'Gaiole In Chianti' },
  { label: 'Gáïos', value: 'Gáïos' },
  { label: 'Gairkata', value: 'Gairkata' },
  { label: 'Gairo Santelena', value: 'Gairo Santelena' },
  { label: 'Gais', value: 'Gais' },
  { label: 'Gais', value: 'Gais' },
  { label: 'Găiseni', value: 'Găiseni' },
  { label: 'Gaißach', value: 'Gaißach' },
  { label: 'Gaißau', value: 'Gaißau' },
  { label: 'Gaïtánion', value: 'Gaïtánion' },
  { label: 'Gaithersburg', value: 'Gaithersburg' },
  { label: 'Gaizhou', value: 'Gaizhou' },
  { label: 'Gajanejos', value: 'Gajanejos' },
  { label: 'Gajapati', value: 'Gajapati' },
  { label: 'Gajates', value: 'Gajates' },
  { label: 'Gajendragarh', value: 'Gajendragarh' },
  { label: 'Gajraula', value: 'Gajraula' },
  { label: 'Gajuwaka', value: 'Gajuwaka' },
  { label: 'Gakem', value: 'Gakem' },
  { label: 'Ga-Kgapane', value: 'Ga-Kgapane' },
  { label: 'Gakovo', value: 'Gakovo' },
  { label: 'Gal’Bshtadt', value: 'Gal’Bshtadt' },
  { label: 'Gala', value: 'Gala' },
  { label: 'Gâlâfi', value: 'Gâlâfi' },
  { label: 'Galagedara', value: 'Galagedara' },
  { label: 'Galán', value: 'Galán' },
  { label: 'Gălăneşti', value: 'Gălăneşti' },
  { label: 'Galanta', value: 'Galanta' },
  { label: 'Gălăoaia', value: 'Gălăoaia' },
  { label: 'Galaosiyo', value: 'Galaosiyo' },
  { label: 'Galapa', value: 'Galapa' },
  { label: 'Galapagar', value: 'Galapagar' },
  { label: 'Galápagos', value: 'Galápagos' },
  { label: 'Galappo', value: 'Galappo' },
  { label: 'Galar', value: 'Galar' },
  { label: 'Galaroza', value: 'Galaroza' },
  { label: 'Gălășești', value: 'Gălășești' },
  { label: 'Galashiels', value: 'Galashiels' },
  { label: 'Galashki', value: 'Galashki' },
  { label: 'Galatádes', value: 'Galatádes' },
  { label: 'Galatás', value: 'Galatás' },
  { label: 'Galatás', value: 'Galatás' },
  { label: 'Galatás', value: 'Galatás' },
  { label: 'Gălăţeni', value: 'Gălăţeni' },
  { label: 'Gălățeni', value: 'Gălățeni' },
  { label: 'Galateo', value: 'Galateo' },
  { label: 'Galaţi', value: 'Galaţi' },
  { label: 'Galati Mamertino', value: 'Galati Mamertino' },
  { label: 'Galaţii Bistriţei', value: 'Galaţii Bistriţei' },
  { label: 'Galatina', value: 'Galatina' },
  { label: 'Galatiní', value: 'Galatiní' },
  { label: 'Galátista', value: 'Galátista' },
  { label: 'Galatone', value: 'Galatone' },
  { label: 'Galatro', value: 'Galatro' },
  { label: 'Galátsi', value: 'Galátsi' },
  { label: 'Gălăuţaş', value: 'Gălăuţaş' },
  { label: 'Galax', value: 'Galax' },
  { label: 'Galaxia Bonito Jalisco', value: 'Galaxia Bonito Jalisco' },
  { label: 'Galaxia La Calera', value: 'Galaxia La Calera' },
  { label: 'Galaxia La Noria', value: 'Galaxia La Noria' },
  { label: 'Galaxias Toluca', value: 'Galaxias Toluca' },
  { label: 'Galaxídhion', value: 'Galaxídhion' },
  { label: 'Galaz', value: 'Galaz' },
  { label: 'Galbarros', value: 'Galbarros' },
  { label: 'Galbárruli', value: 'Galbárruli' },
  { label: 'Galbeni', value: 'Galbeni' },
  { label: 'Galbenu', value: 'Galbenu' },
  { label: 'Galbiate', value: 'Galbiate' },
  { label: 'Gălbinaşi', value: 'Gălbinaşi' },
  { label: 'Gălbinași', value: 'Gălbinași' },
  { label: 'Galda De Jos', value: 'Galda De Jos' },
  { label: 'Galdakao', value: 'Galdakao' },
  { label: 'Galdames', value: 'Galdames' },
  { label: 'Gáldar', value: 'Gáldar' },
  { label: 'Gâldău', value: 'Gâldău' },
  { label: 'Galeana', value: 'Galeana' },
  { label: 'Galeana', value: 'Galeana' },
  { label: 'Galeana', value: 'Galeana' },
  { label: 'Galeana', value: 'Galeana' },
  { label: 'Galeata', value: 'Galeata' },
  { label: 'Galechugaon', value: 'Galechugaon' },
  { label: 'Galecio Narcia', value: 'Galecio Narcia' },
  { label: 'Galegos', value: 'Galegos' },
  { label: 'Galehdar', value: 'Galehdar' },
  { label: 'Galena', value: 'Galena' },
  { label: 'Galena', value: 'Galena' },
  { label: 'Galena', value: 'Galena' },
  { label: 'Galena', value: 'Galena' },
  { label: 'Galena Park', value: 'Galena Park' },
  { label: 'Galende', value: 'Galende' },
  { label: 'Galera', value: 'Galera' },
  { label: 'Galera', value: 'Galera' },
  { label: 'Galerade Panales', value: 'Galerade Panales' },
  { label: 'Galeras', value: 'Galeras' },
  { label: 'Galeras', value: 'Galeras' },
  { label: 'Gales Ferry', value: 'Gales Ferry' },
  { label: 'Galesburg', value: 'Galesburg' },
  { label: 'Galesburg', value: 'Galesburg' },
  { label: 'Galesong', value: 'Galesong' },
  { label: 'Gălești', value: 'Gălești' },
  { label: 'Galesville', value: 'Galesville' },
  { label: 'Galeton', value: 'Galeton' },
  { label: 'Galeville', value: 'Galeville' },
  { label: 'Galewice', value: 'Galewice' },
  { label: 'Galgagnano', value: 'Galgagnano' },
  { label: 'Galgahévíz', value: 'Galgahévíz' },
  { label: 'Galgamácsa', value: 'Galgamácsa' },
  { label: 'Galgate', value: 'Galgate' },
  { label: 'Gâlgău', value: 'Gâlgău' },
  { label: 'Gâlgău Almaşului', value: 'Gâlgău Almaşului' },
  { label: 'Galgon', value: 'Galgon' },
  { label: 'Galhinna', value: 'Galhinna' },
  { label: 'Gali', value: 'Gali' },
  { label: 'Gália', value: 'Gália' },
  { label: 'Galiakot', value: 'Galiakot' },
  { label: 'Galicea', value: 'Galicea' },
  { label: 'Galicea Mare', value: 'Galicea Mare' },
  { label: 'Galich', value: 'Galich' },
  { label: 'Galichskiy Rayon', value: 'Galichskiy Rayon' },
  { label: 'Galiciuica', value: 'Galiciuica' },
  { label: 'Galikesh', value: 'Galikesh' },
  { label: 'Galilea', value: 'Galilea' },
  { label: 'Galiléia', value: 'Galiléia' },
  { label: 'Galimuyod', value: 'Galimuyod' },
  { label: 'Galimuyod', value: 'Galimuyod' },
  { label: 'Galina', value: 'Galina' },
  { label: 'Galindo', value: 'Galindo' },
  { label: 'Galindo Y Perahuy', value: 'Galindo Y Perahuy' },
  { label: 'Galinduste', value: 'Galinduste' },
  { label: 'Galinhos', value: 'Galinhos' },
  { label: 'Galion', value: 'Galion' },
  { label: 'Galisancho', value: 'Galisancho' },
  { label: 'Galisteo', value: 'Galisteo' },
  { label: 'Galitsy', value: 'Galitsy' },
  { label: 'Galiwinku', value: 'Galiwinku' },
  { label: 'Galižana', value: 'Galižana' },
  { label: 'Gałków Mały', value: 'Gałków Mały' },
  { label: 'Gallarate', value: 'Gallarate' },
  { label: 'Gallardon', value: 'Gallardon' },
  { label: 'Gallardos Los', value: 'Gallardos Los' },
  { label: 'Gallargues-Le-Montueux', value: 'Gallargues-Le-Montueux' },
  { label: 'Gallatin', value: 'Gallatin' },
  { label: 'Gallatin', value: 'Gallatin' },
  { label: 'Gallatin County', value: 'Gallatin County' },
  { label: 'Gallatin County', value: 'Gallatin County' },
  { label: 'Gallatin County', value: 'Gallatin County' },
  { label: 'Galle', value: 'Galle' },
  { label: 'Galle District', value: 'Galle District' },
  { label: 'Gallega La', value: 'Gallega La' },
  { label: 'Gallegos', value: 'Gallegos' },
  { label: 'Gallegos De Altamiros', value: 'Gallegos De Altamiros' },
  { label: 'Gallegos De Argañán', value: 'Gallegos De Argañán' },
  { label: 'Gallegos De Hornija', value: 'Gallegos De Hornija' },
  { label: 'Gallegos De Sobrinos', value: 'Gallegos De Sobrinos' },
  { label: 'Gallegos De Solmirón', value: 'Gallegos De Solmirón' },
  { label: 'Gallegos Del Pan', value: 'Gallegos Del Pan' },
  { label: 'Gallegos Del Río', value: 'Gallegos Del Río' },
  { label: 'Galleh Manda', value: 'Galleh Manda' },
  { label: 'Gallese', value: 'Gallese' },
  { label: 'Gallia County', value: 'Gallia County' },
  { label: 'Galliano', value: 'Galliano' },
  { label: 'Galliano', value: 'Galliano' },
  { label: 'Galliate', value: 'Galliate' },
  { label: 'Galliate Lombardo', value: 'Galliate Lombardo' },
  { label: 'Galliavola', value: 'Galliavola' },
  { label: 'Gallicano', value: 'Gallicano' },
  { label: 'Gallicano Nel Lazio', value: 'Gallicano Nel Lazio' },
  { label: 'Gallicchio', value: 'Gallicchio' },
  { label: 'Galliera Veneta', value: 'Galliera Veneta' },
  { label: 'Gallifa', value: 'Gallifa' },
  { label: 'Gallinaro', value: 'Gallinaro' },
  { label: 'Gallinero De Cameros', value: 'Gallinero De Cameros' },
  { label: 'Gallio', value: 'Gallio' },
  { label: 'Gallipienzo', value: 'Gallipienzo' },
  { label: 'Gallipienzo/Galipentzu', value: 'Gallipienzo/Galipentzu' },
  { label: 'Gallipoli', value: 'Gallipoli' },
  { label: 'Gallipolis', value: 'Gallipolis' },
  { label: 'Gallitzin', value: 'Gallitzin' },
  { label: 'Gällivare', value: 'Gällivare' },
  { label: 'Gallizien', value: 'Gallizien' },
  { label: 'Gallmannsegg', value: 'Gallmannsegg' },
  { label: 'Gallneukirchen', value: 'Gallneukirchen' },
  { label: 'Gallo', value: 'Gallo' },
  { label: 'Gallo', value: 'Gallo' },
  { label: 'Gallo', value: 'Gallo' },
  { label: 'Gallo Matese', value: 'Gallo Matese' },
  { label: 'Gallocanta', value: 'Gallocanta' },
  { label: 'Gallodoro', value: 'Gallodoro' },
  { label: 'Gallo-Tre Re-Mezzana Corti', value: 'Gallo-Tre Re-Mezzana Corti' },
  { label: 'Gallspach', value: 'Gallspach' },
  { label: 'Galluccio', value: 'Galluccio' },
  { label: 'Gallués/Galoze', value: 'Gallués/Galoze' },
  { label: 'Galluis', value: 'Galluis' },
  { label: 'Gallup', value: 'Gallup' },
  { label: 'Gallur', value: 'Gallur' },
  { label: 'Gallus', value: 'Gallus' },
  { label: 'Gallzein', value: 'Gallzein' },
  { label: 'Galmaarden', value: 'Galmaarden' },
  { label: 'Galoșpetreu', value: 'Galoșpetreu' },
  { label: 'Galovac', value: 'Galovac' },
  { label: 'Galșa', value: 'Galșa' },
  { label: 'Galston', value: 'Galston' },
  { label: 'Galston', value: 'Galston' },
  { label: 'Galt', value: 'Galt' },
  { label: 'Galta', value: 'Galta' },
  { label: 'Galtellì', value: 'Galtellì' },
  { label: 'Galten', value: 'Galten' },
  { label: 'Galtür', value: 'Galtür' },
  { label: 'Galugah', value: 'Galugah' },
  { label: 'Galugnano', value: 'Galugnano' },
  { label: 'Galva', value: 'Galva' },
  { label: 'Galván', value: 'Galván' },
  { label: 'Galvão', value: 'Galvão' },
  { label: 'Galvarino', value: 'Galvarino' },
  { label: 'Galve', value: 'Galve' },
  { label: 'Galve De Sorbe', value: 'Galve De Sorbe' },
  { label: 'Galveston', value: 'Galveston' },
  { label: 'Galveston', value: 'Galveston' },
  { label: 'Galveston County', value: 'Galveston County' },
  { label: 'Gálvez', value: 'Gálvez' },
  { label: 'Gálvez', value: 'Gálvez' },
  { label: 'Galway City', value: 'Galway City' },
  { label: 'Galyugayevskaya', value: 'Galyugayevskaya' },
  { label: 'Galzignano', value: 'Galzignano' },
  { label: 'Gama', value: 'Gama' },
  { label: 'Gamaches', value: 'Gamaches' },
  { label: 'Gamagōri', value: 'Gamagōri' },
  { label: 'Gamagōri-Shi', value: 'Gamagōri-Shi' },
  { label: 'Gamalero', value: 'Gamalero' },
  { label: 'Gamarra', value: 'Gamarra' },
  { label: 'Gamasa', value: 'Gamasa' },
  { label: 'Gamasb', value: 'Gamasb' },
  { label: 'Gamay', value: 'Gamay' },
  { label: 'Gamay', value: 'Gamay' },
  { label: 'Gamba', value: 'Gamba' },
  { label: 'Gamba', value: 'Gamba' },
  { label: 'Gambais', value: 'Gambais' },
  { label: 'Gambalidio', value: 'Gambalidio' },
  { label: 'Gambang', value: 'Gambang' },
  { label: 'Gambang', value: 'Gambang' },
  { label: 'Gambara', value: 'Gambara' },
  { label: 'Gambara', value: 'Gambara' },
  { label: 'Gambarana', value: 'Gambarana' },
  { label: 'Gambasca', value: 'Gambasca' },
  { label: 'Gambassi Terme', value: 'Gambassi Terme' },
  { label: 'Gambat', value: 'Gambat' },
  { label: 'Gambatesa', value: 'Gambatesa' },
  { label: 'Gambēla', value: 'Gambēla' },
  { label: 'Gambellara', value: 'Gambellara' },
  { label: 'Gamberale', value: 'Gamberale' },
  { label: 'Gambettola', value: 'Gambettola' },
  { label: 'Gambier', value: 'Gambier' },
  { label: 'Gambino', value: 'Gambino' },
  { label: 'Gambiran Satu', value: 'Gambiran Satu' },
  { label: 'Gambita', value: 'Gambita' },
  { label: 'Gambo', value: 'Gambo' },
  { label: 'Gambo', value: 'Gambo' },
  { label: 'Gambolò', value: 'Gambolò' },
  { label: 'Gamboma', value: 'Gamboma' },
  { label: 'Gamboru', value: 'Gamboru' },
  { label: 'Gambos', value: 'Gambos' },
  { label: 'Gamboula', value: 'Gamboula' },
  { label: 'Gambrills', value: 'Gambrills' },
  { label: 'Gambsheim', value: 'Gambsheim' },
  { label: 'Gambugliano', value: 'Gambugliano' },
  { label: 'Gâmbuț', value: 'Gâmbuț' },
  { label: 'Gameleira', value: 'Gameleira' },
  { label: 'Gameleira De Goiás', value: 'Gameleira De Goiás' },
  { label: 'Gameleiras', value: 'Gameleiras' },
  { label: 'Gameren', value: 'Gameren' },
  { label: 'Gamewell', value: 'Gamewell' },
  { label: 'Gameza', value: 'Gameza' },
  { label: 'Gaming', value: 'Gaming' },
  { label: 'Gamiz-Fika', value: 'Gamiz-Fika' },
  { label: 'Gamla Stan', value: 'Gamla Stan' },
  { label: 'Gamla Uppsala', value: 'Gamla Uppsala' },
  { label: 'Gamleby', value: 'Gamleby' },
  { label: 'Gamlingay', value: 'Gamlingay' },
  { label: 'Gamlitz', value: 'Gamlitz' },
  { label: 'Gammad', value: 'Gammad' },
  { label: 'Gammad', value: 'Gammad' },
  { label: 'Gammelsdorf', value: 'Gammelsdorf' },
  { label: 'Gammelshausen', value: 'Gammelshausen' },
  { label: 'Gammelstad', value: 'Gammelstad' },
  { label: 'Gammertingen', value: 'Gammertingen' },
  { label: 'Gamonal', value: 'Gamonal' },
  { label: 'Gamones', value: 'Gamones' },
  { label: 'Gamovo', value: 'Gamovo' },
  { label: 'Gampaha', value: 'Gampaha' },
  { label: 'Gampaha District', value: 'Gampaha District' },
  { label: 'Gampel', value: 'Gampel' },
  { label: 'Gampengrejo', value: 'Gampengrejo' },
  { label: 'Gampern', value: 'Gampern' },
  { label: 'Gampola', value: 'Gampola' },
  { label: 'Gamprin', value: 'Gamprin' },
  { label: 'Gams', value: 'Gams' },
  { label: 'Gams Bei Hieflau', value: 'Gams Bei Hieflau' },
  { label: 'Gamu', value: 'Gamu' },
  { label: 'Gamu', value: 'Gamu' },
  { label: 'Gamut', value: 'Gamut' },
  { label: 'Gamut', value: 'Gamut' },
  { label: 'Gamvik', value: 'Gamvik' },
  { label: 'Gan', value: 'Gan' },
  { label: 'Gan Yavne', value: 'Gan Yavne' },
  { label: 'Ganado', value: 'Ganado' },
  { label: 'Ganado', value: 'Ganado' },
  { label: 'Gananoque', value: 'Gananoque' },
  { label: 'Ganapi', value: 'Ganapi' },
  { label: 'Ganapi', value: 'Ganapi' },
  { label: 'Ganassi', value: 'Ganassi' },
  { label: 'Ganassi', value: 'Ganassi' },
  { label: 'Gančani', value: 'Gančani' },
  { label: 'Gancedo', value: 'Gancedo' },
  { label: 'Ganda', value: 'Ganda' },
  { label: 'Ganda', value: 'Ganda' },
  { label: 'Gandai', value: 'Gandai' },
  { label: 'Gandajika', value: 'Gandajika' },
  { label: 'Gandara', value: 'Gandara' },
  { label: 'Gandara', value: 'Gandara' },
  { label: 'Gandellino', value: 'Gandellino' },
  { label: 'Ganderbal', value: 'Ganderbal' },
  { label: 'Ganderkesee', value: 'Ganderkesee' },
  { label: 'Gandesa', value: 'Gandesa' },
  { label: 'Gandevi', value: 'Gandevi' },
  { label: 'Gandhi Nagar', value: 'Gandhi Nagar' },
  { label: 'Gandhidham', value: 'Gandhidham' },
  { label: 'Gandhinagar', value: 'Gandhinagar' },
  { label: 'Gandhó', value: 'Gandhó' },
  { label: 'Gandi', value: 'Gandi' },
  { label: 'Gandia', value: 'Gandia' },
  { label: 'Gandiaye', value: 'Gandiaye' },
  { label: 'Gandino', value: 'Gandino' },
  { label: 'Gandoman', value: 'Gandoman' },
  { label: 'Gandorhun', value: 'Gandorhun' },
  { label: 'Gandosso', value: 'Gandosso' },
  { label: 'Gandra', value: 'Gandra' },
  { label: 'Gandra', value: 'Gandra' },
  { label: 'Gandrange', value: 'Gandrange' },
  { label: 'Gandu', value: 'Gandu' },
  { label: 'Gandy', value: 'Gandy' },
  { label: 'Gandzak', value: 'Gandzak' },
  { label: 'Găneasa', value: 'Găneasa' },
  { label: 'Găneasa', value: 'Găneasa' },
  { label: 'Ganei Tikva', value: 'Ganei Tikva' },
  { label: 'Gănești', value: 'Gănești' },
  { label: 'Gănești', value: 'Gănești' },
  { label: 'Gang', value: 'Gang' },
  { label: 'Gang', value: 'Gang' },
  { label: 'Gang Mills', value: 'Gang Mills' },
  { label: 'Ganga Ihala Korale', value: 'Ganga Ihala Korale' },
  { label: 'Gangadharpur', value: 'Gangadharpur' },
  { label: 'Gangaikondan', value: 'Gangaikondan' },
  { label: 'Gangakher', value: 'Gangakher' },
  { label: 'Ganganagar', value: 'Ganganagar' },
  { label: 'Gangapur', value: 'Gangapur' },
  { label: 'Gangapur', value: 'Gangapur' },
  { label: 'Gangarampur', value: 'Gangarampur' },
  { label: 'Gangavalli', value: 'Gangavalli' },
  { label: 'Gangawati', value: 'Gangawati' },
  { label: 'Gangbuk-Gu', value: 'Gangbuk-Gu' },
  { label: 'Gangdong', value: 'Gangdong' },
  { label: 'Gangdong-Gu', value: 'Gangdong-Gu' },
  { label: 'Gangelt', value: 'Gangelt' },
  { label: 'Ganges', value: 'Ganges' },
  { label: 'Gånghester', value: 'Gånghester' },
  { label: 'Gangi', value: 'Gangi' },
  { label: 'Gângiova', value: 'Gângiova' },
  { label: 'Gangjin-Gun', value: 'Gangjin-Gun' },
  { label: 'Gangkofen', value: 'Gangkofen' },
  { label: 'Gangloffsömmern', value: 'Gangloffsömmern' },
  { label: 'Gangnam-Gu', value: 'Gangnam-Gu' },
  { label: 'Gangneung', value: 'Gangneung' },
  { label: 'Gangneung-Si', value: 'Gangneung-Si' },
  { label: 'Gangoh', value: 'Gangoh' },
  { label: 'Gangolli', value: 'Gangolli' },
  { label: 'Gangseo-Gu', value: 'Gangseo-Gu' },
  { label: 'Gangseo-Gu', value: 'Gangseo-Gu' },
  { label: 'Gangtok', value: 'Gangtok' },
  { label: 'Ganguvada', value: 'Ganguvada' },
  { label: 'Ganj Dundawara', value: 'Ganj Dundawara' },
  { label: 'Ganj Dundwara', value: 'Ganj Dundwara' },
  { label: 'Ganj Muradabad', value: 'Ganj Muradabad' },
  { label: 'Ganj Tappeh', value: 'Ganj Tappeh' },
  { label: 'Ganja', value: 'Ganja' },
  { label: 'Ganjab', value: 'Ganjab' },
  { label: 'Ganjam', value: 'Ganjam' },
  { label: 'Ganløse', value: 'Ganløse' },
  { label: 'Ganna', value: 'Ganna' },
  { label: 'Gannan', value: 'Gannan' },
  { label: 'Gannat', value: 'Gannat' },
  { label: 'Gannavaram', value: 'Gannavaram' },
  { label: 'Gannawarra', value: 'Gannawarra' },
  { label: 'Ganpatipule', value: 'Ganpatipule' },
  { label: 'Gänserndorf', value: 'Gänserndorf' },
  { label: 'Ganshui', value: 'Ganshui' },
  { label: 'Gansing', value: 'Gansing' },
  { label: 'Gansing', value: 'Gansing' },
  { label: 'Ganta', value: 'Ganta' },
  { label: 'Gantang', value: 'Gantang' },
  { label: 'Ganterschwil', value: 'Ganterschwil' },
  { label: 'Gantiadi', value: 'Gantiadi' },
  { label: 'Gantofta', value: 'Gantofta' },
  { label: 'Gantt', value: 'Gantt' },
  { label: 'Ganyangan', value: 'Ganyangan' },
  { label: 'Ganye', value: 'Ganye' },
  { label: 'Ganz', value: 'Ganz' },
  { label: 'Ganzda', value: 'Ganzda' },
  { label: 'Ganzhou', value: 'Ganzhou' },
  { label: 'Ganzhou Shi', value: 'Ganzhou Shi' },
  { label: 'Gao', value: 'Gao' },
  { label: 'Gao Cercle', value: 'Gao Cercle' },
  { label: 'Gaogou', value: 'Gaogou' },
  { label: 'Gaojingzhuang', value: 'Gaojingzhuang' },
  { label: 'Gaomi', value: 'Gaomi' },
  { label: 'Gaoniang', value: 'Gaoniang' },
  { label: 'Gaoping', value: 'Gaoping' },
  { label: 'Gaoqiao', value: 'Gaoqiao' },
  { label: 'Gaoual', value: 'Gaoual' },
  { label: 'Gaoual Prefecture', value: 'Gaoual Prefecture' },
  { label: 'Gaoui', value: 'Gaoui' },
  { label: 'Gaowu', value: 'Gaowu' },
  { label: 'Gaoyao', value: 'Gaoyao' },
  { label: 'Gaoyi', value: 'Gaoyi' },
  { label: 'Gaoyou', value: 'Gaoyou' },
  { label: 'Gaozeng', value: 'Gaozeng' },
  { label: 'Gaozhou', value: 'Gaozhou' },
  { label: 'Gap', value: 'Gap' },
  { label: 'Gap', value: 'Gap' },
  { label: 'Gapan', value: 'Gapan' },
  { label: 'Gapan', value: 'Gapan' },
  { label: 'Gaphatshwe', value: 'Gaphatshwe' },
  { label: 'Gappal', value: 'Gappal' },
  { label: 'Gappal', value: 'Gappal' },
  { label: 'Gaptsakh', value: 'Gaptsakh' },
  { label: 'Gapyeong', value: 'Gapyeong' },
  { label: 'Gapyeong-Gun', value: 'Gapyeong-Gun' },
  { label: 'Gara', value: 'Gara' },
  { label: 'Garab', value: 'Garab' },
  { label: 'Garaballa', value: 'Garaballa' },
  { label: 'Garab-E Sofla', value: 'Garab-E Sofla' },
  { label: 'Garabito', value: 'Garabito' },
  { label: 'Garachico', value: 'Garachico' },
  { label: 'Garachiné', value: 'Garachiné' },
  { label: 'Garadassi', value: 'Garadassi' },
  { label: 'Garafía', value: 'Garafía' },
  { label: 'Gărăgău', value: 'Gărăgău' },
  { label: 'Garagoa', value: 'Garagoa' },
  { label: 'Garaguso', value: 'Garaguso' },
  { label: 'Garai', value: 'Garai' },
  { label: 'Garaioa', value: 'Garaioa' },
  { label: 'Garanas', value: 'Garanas' },
  { label: 'Garancières', value: 'Garancières' },
  { label: 'Garango', value: 'Garango' },
  { label: 'Garanhuns', value: 'Garanhuns' },
  { label: 'Ga-Rankuwa', value: 'Ga-Rankuwa' },
  { label: 'Gararu', value: 'Gararu' },
  { label: 'Garat', value: 'Garat' },
  { label: 'Garautha', value: 'Garautha' },
  { label: 'Garbagna', value: 'Garbagna' },
  { label: 'Garbagna Novarese', value: 'Garbagna Novarese' },
  { label: 'Garbagnate', value: 'Garbagnate' },
  { label: 'Garbagnate Milanese', value: 'Garbagnate Milanese' },
  { label: 'Garbagnate Monastero', value: 'Garbagnate Monastero' },
  { label: 'Garbahaarrey', value: 'Garbahaarrey' },
  { label: 'Garbatka-Letnisko', value: 'Garbatka-Letnisko' },
  { label: 'Gârbău', value: 'Gârbău' },
  { label: 'Garbayuela', value: 'Garbayuela' },
  { label: 'Gârbești', value: 'Gârbești' },
  { label: 'Garbolovo', value: 'Garbolovo' },
  { label: 'Gârbou', value: 'Gârbou' },
  { label: 'Gârbova', value: 'Gârbova' },
  { label: 'Gârbovi', value: 'Gârbovi' },
  { label: 'Garbów', value: 'Garbów' },
  { label: 'Garbsen', value: 'Garbsen' },
  { label: 'Garbsen-Mitte', value: 'Garbsen-Mitte' },
  { label: 'Garbutt', value: 'Garbutt' },
  { label: 'Garça', value: 'Garça' },
  { label: 'Gârceni', value: 'Gârceni' },
  { label: 'Garches', value: 'Garches' },
  { label: 'Garching An Der Alz', value: 'Garching An Der Alz' },
  { label: 'Garching Bei München', value: 'Garching Bei München' },
  { label: 'Garchitorena', value: 'Garchitorena' },
  { label: 'Garchizy', value: 'Garchizy' },
  { label: 'Garcia', value: 'Garcia' },
  { label: 'García', value: 'García' },
  { label: 'Garcia Hernandez', value: 'Garcia Hernandez' },
  { label: 'Garciaz', value: 'Garciaz' },
  { label: 'Garcibuey', value: 'Garcibuey' },
  { label: 'Garcihernández', value: 'Garcihernández' },
  { label: 'Garcillán', value: 'Garcillán' },
  { label: 'Garčin', value: 'Garčin' },
  { label: 'Gârcina', value: 'Gârcina' },
  { label: 'Garciotum', value: 'Garciotum' },
  { label: 'Garcirrey', value: 'Garcirrey' },
  { label: 'Gârcov', value: 'Gârcov' },
  { label: 'Garcz', value: 'Garcz' },
  { label: 'Gard', value: 'Gard' },
  { label: 'Garda', value: 'Garda' },
  { label: 'Gârda De Sus', value: 'Gârda De Sus' },
  { label: 'Garðabær', value: 'Garðabær' },
  { label: 'Gardabani', value: 'Gardabani' },
  { label: 'Gardabnis Munitsip’Alit’Et’I', value: 'Gardabnis Munitsip’Alit’Et’I' },
  { label: 'Gârdani', value: 'Gârdani' },
  { label: 'Gardanne', value: 'Gardanne' },
  { label: 'Gardawice', value: 'Gardawice' },
  { label: 'Garde', value: 'Garde' },
  { label: 'Gardeja', value: 'Gardeja' },
  { label: 'Gardelegen', value: 'Gardelegen' },
  { label: 'Garden Acres', value: 'Garden Acres' },
  { label: 'Garden City', value: 'Garden City' },
  { label: 'Garden City', value: 'Garden City' },
  { label: 'Garden City', value: 'Garden City' },
  { label: 'Garden City', value: 'Garden City' },
  { label: 'Garden City', value: 'Garden City' },
  { label: 'Garden City', value: 'Garden City' },
  { label: 'Garden City', value: 'Garden City' },
  { label: 'Garden City', value: 'Garden City' },
  { label: 'Garden City Park', value: 'Garden City Park' },
  { label: 'Garden City South', value: 'Garden City South' },
  { label: 'Garden County', value: 'Garden County' },
  { label: 'Garden Grove', value: 'Garden Grove' },
  { label: 'Garden Home-Whitford', value: 'Garden Home-Whitford' },
  { label: 'Garden Island', value: 'Garden Island' },
  { label: 'Garden Ridge', value: 'Garden Ridge' },
  { label: 'Garden Suburb', value: 'Garden Suburb' },
  { label: 'Garden View', value: 'Garden View' },
  { label: 'Gardena', value: 'Gardena' },
  { label: 'Gardendale', value: 'Gardendale' },
  { label: 'Gardendale', value: 'Gardendale' },
  { label: 'Gardere', value: 'Gardere' },
  { label: 'Garderen', value: 'Garderen' },
  { label: 'Gardez', value: 'Gardez' },
  { label: 'Gardhiwala', value: 'Gardhiwala' },
  { label: 'Gardiner', value: 'Gardiner' },
  { label: 'Garding', value: 'Garding' },
  { label: 'Gardinovci', value: 'Gardinovci' },
  { label: 'Gardner', value: 'Gardner' },
  { label: 'Gardner', value: 'Gardner' },
  { label: 'Gardner', value: 'Gardner' },
  { label: 'Gardnertown', value: 'Gardnertown' },
  { label: 'Gardnerville', value: 'Gardnerville' },
  { label: 'Gardnerville Ranchos', value: 'Gardnerville Ranchos' },
  { label: 'Gardola', value: 'Gardola' },
  { label: 'Gardone Riviera', value: 'Gardone Riviera' },
  { label: 'Gardone Val Trompia', value: 'Gardone Val Trompia' },
  { label: 'Gardonne', value: 'Gardonne' },
  { label: 'Gárdony', value: 'Gárdony' },
  { label: 'Gárdonyi Járás', value: 'Gárdonyi Járás' },
  { label: 'Gardouch', value: 'Gardouch' },
  { label: 'Gårdsten', value: 'Gårdsten' },
  { label: 'Garður', value: 'Garður' },
  { label: 'Garelochhead', value: 'Garelochhead' },
  { label: 'Garennes-Sur-Eure', value: 'Garennes-Sur-Eure' },
  { label: 'Garéoult', value: 'Garéoult' },
  { label: 'Garešnica', value: 'Garešnica' },
  { label: 'Garessio', value: 'Garessio' },
  { label: 'Garfield', value: 'Garfield' },
  { label: 'Garfield', value: 'Garfield' },
  { label: 'Garfield', value: 'Garfield' },
  { label: 'Garfield County', value: 'Garfield County' },
  { label: 'Garfield County', value: 'Garfield County' },
  { label: 'Garfield County', value: 'Garfield County' },
  { label: 'Garfield County', value: 'Garfield County' },
  { label: 'Garfield County', value: 'Garfield County' },
  { label: 'Garfield County', value: 'Garfield County' },
  { label: 'Garfield Heights', value: 'Garfield Heights' },
  { label: 'Garforth', value: 'Garforth' },
  { label: 'Gargaliánoi', value: 'Gargaliánoi' },
  { label: 'Gargallo', value: 'Gargallo' },
  { label: 'Gargallo', value: 'Gargallo' },
  { label: 'Garganta De Los Montes', value: 'Garganta De Los Montes' },
  { label: 'Garganta Del Villar', value: 'Garganta Del Villar' },
  { label: 'Garganta La', value: 'Garganta La' },
  { label: 'Garganta La Olla', value: 'Garganta La Olla' },
  { label: 'Gargantilla', value: 'Gargantilla' },
  { label: 'Gargantilla Del Lozoya Y Pinilla De Buitrago', value: 'Gargantilla Del Lozoya Y Pinilla De Buitrago' },
  { label: 'Gargantillo', value: 'Gargantillo' },
  { label: 'Gargas', value: 'Gargas' },
  { label: 'Gargazzone', value: 'Gargazzone' },
  { label: 'Gargenville', value: 'Gargenville' },
  { label: 'Garges-Lès-Gonesse', value: 'Garges-Lès-Gonesse' },
  { label: 'Gargnano', value: 'Gargnano' },
  { label: 'Gargrave', value: 'Gargrave' },
  { label: 'Gargüera', value: 'Gargüera' },
  { label: 'Gargždai', value: 'Gargždai' },
  { label: 'Garh Maharaja', value: 'Garh Maharaja' },
  { label: 'Garha Brahman', value: 'Garha Brahman' },
  { label: 'Garhakota', value: 'Garhakota' },
  { label: 'Garhi Khairo', value: 'Garhi Khairo' },
  { label: 'Garhi Pukhta', value: 'Garhi Pukhta' },
  { label: 'Garhiyasin', value: 'Garhiyasin' },
  { label: 'Garhmuktesar', value: 'Garhmuktesar' },
  { label: 'Garhshankar', value: 'Garhshankar' },
  { label: 'Garhwa', value: 'Garhwa' },
  { label: 'Garhwa', value: 'Garhwa' },
  { label: 'Garhwal', value: 'Garhwal' },
  { label: 'Gari', value: 'Gari' },
  { label: 'Gariaband', value: 'Gariaband' },
  { label: 'Gariadhar', value: 'Gariadhar' },
  { label: 'Garibaldi', value: 'Garibaldi' },
  { label: 'Garidech', value: 'Garidech' },
  { label: 'Garidells', value: 'Garidells' },
  { label: 'Garino', value: 'Garino' },
  { label: 'Garínoain', value: 'Garínoain' },
  { label: 'Garissa', value: 'Garissa' },
  { label: 'Garkalne', value: 'Garkalne' },
  { label: 'Garki', value: 'Garki' },
  { label: 'Garko', value: 'Garko' },
  { label: 'Gârla-Mare', value: 'Gârla-Mare' },
  { label: 'Garland', value: 'Garland' },
  { label: 'Garland', value: 'Garland' },
  { label: 'Garland', value: 'Garland' },
  { label: 'Garland County', value: 'Garland County' },
  { label: 'Garlands', value: 'Garlands' },
  { label: 'Garlasco', value: 'Garlasco' },
  { label: 'Garlate', value: 'Garlate' },
  { label: 'Garlenda', value: 'Garlenda' },
  { label: 'Gârleni', value: 'Gârleni' },
  { label: 'Gârlenii De Sus', value: 'Gârlenii De Sus' },
  { label: 'Garliava', value: 'Garliava' },
  { label: 'Gârliciu', value: 'Gârliciu' },
  { label: 'Garlin', value: 'Garlin' },
  { label: 'Garlitos', value: 'Garlitos' },
  { label: 'Garlstorf', value: 'Garlstorf' },
  { label: 'Garmaab', value: 'Garmaab' },
  { label: 'Garmdareh', value: 'Garmdareh' },
  { label: 'Garmeh', value: 'Garmeh' },
  { label: 'Garmen', value: 'Garmen' },
  { label: 'Garmisch-Partenkirchen', value: 'Garmisch-Partenkirchen' },
  { label: 'Garmsar', value: 'Garmsar' },
  { label: 'Garnay', value: 'Garnay' },
  { label: 'Garner', value: 'Garner' },
  { label: 'Garner', value: 'Garner' },
  { label: 'Garnet', value: 'Garnet' },
  { label: 'Garnett', value: 'Garnett' },
  { label: 'Gârnic', value: 'Gârnic' },
  { label: 'Garnich', value: 'Garnich' },
  { label: 'Garniga Nuova', value: 'Garniga Nuova' },
  { label: 'Garoafa', value: 'Garoafa' },
  { label: 'Garons', value: 'Garons' },
  { label: 'Garoowe', value: 'Garoowe' },
  { label: 'Garopaba', value: 'Garopaba' },
  { label: 'Garoua', value: 'Garoua' },
  { label: 'Garoua Boulaï', value: 'Garoua Boulaï' },
  { label: 'Garphyttan', value: 'Garphyttan' },
  { label: 'Garrafão Do Norte', value: 'Garrafão Do Norte' },
  { label: 'Garrafe De Torío', value: 'Garrafe De Torío' },
  { label: 'Garralda', value: 'Garralda' },
  { label: 'Garran', value: 'Garran' },
  { label: 'Garrand', value: 'Garrand' },
  { label: 'Garrard County', value: 'Garrard County' },
  { label: 'Garray', value: 'Garray' },
  { label: 'Garrel', value: 'Garrel' },
  { label: 'Garretson', value: 'Garretson' },
  { label: 'Garrett', value: 'Garrett' },
  { label: 'Garrett', value: 'Garrett' },
  { label: 'Garrett County', value: 'Garrett County' },
  { label: 'Garrett Park', value: 'Garrett Park' },
  { label: 'Garrettsville', value: 'Garrettsville' },
  { label: 'Garriga La', value: 'Garriga La' },
  { label: 'Garrigàs', value: 'Garrigàs' },
  { label: 'Garrigoles', value: 'Garrigoles' },
  { label: 'Garriguella', value: 'Garriguella' },
  { label: 'Garrison', value: 'Garrison' },
  { label: 'Garrison', value: 'Garrison' },
  { label: 'Garrni', value: 'Garrni' },
  { label: 'Garrobo El', value: 'Garrobo El' },
  { label: 'Garrochales', value: 'Garrochales' },
  { label: 'Garrovilla La', value: 'Garrovilla La' },
  { label: 'Garrovillas De Alconétar', value: 'Garrovillas De Alconétar' },
  { label: 'Garrucha', value: 'Garrucha' },
  { label: 'Garruchos', value: 'Garruchos' },
  { label: 'Garruchos', value: 'Garruchos' },
  { label: 'Gars', value: 'Gars' },
  { label: 'Gars Am Kamp', value: 'Gars Am Kamp' },
  { label: 'Garsington', value: 'Garsington' },
  { label: 'Gärsnäs', value: 'Gärsnäs' },
  { label: 'Garstang', value: 'Garstang' },
  { label: 'Garstedt', value: 'Garstedt' },
  { label: 'Garsten', value: 'Garsten' },
  { label: 'Gartcosh', value: 'Gartcosh' },
  { label: 'Gartenstadt', value: 'Gartenstadt' },
  { label: 'Gartow', value: 'Gartow' },
  { label: 'Gärtringen', value: 'Gärtringen' },
  { label: 'Gartz', value: 'Gartz' },
  { label: 'Garuhapé', value: 'Garuhapé' },
  { label: 'Garui', value: 'Garui' },
  { label: 'Garulia', value: 'Garulia' },
  { label: 'Garupá', value: 'Garupá' },
  { label: 'Garuva', value: 'Garuva' },
  { label: 'Garvagh', value: 'Garvagh' },
  { label: 'Garvăn', value: 'Garvăn' },
  { label: 'Garvín', value: 'Garvín' },
  { label: 'Garvin County', value: 'Garvin County' },
  { label: 'Garwolin', value: 'Garwolin' },
  { label: 'Garwood', value: 'Garwood' },
  { label: 'Gary', value: 'Gary' },
  { label: 'Garyp', value: 'Garyp' },
  { label: 'Garyville', value: 'Garyville' },
  { label: 'Garz', value: 'Garz' },
  { label: 'Garza County', value: 'Garza County' },
  { label: 'Garza García', value: 'Garza García' },
  { label: 'Garza González', value: 'Garza González' },
  { label: 'Garzê Zangzu Zizhizhou', value: 'Garzê Zangzu Zizhizhou' },
  { label: 'Garzeno', value: 'Garzeno' },
  { label: 'Garzigliana', value: 'Garzigliana' },
  { label: 'Garzón', value: 'Garzón' },
  { label: 'Gas City', value: 'Gas City' },
  { label: 'Gasa', value: 'Gasa' },
  { label: 'Gasan', value: 'Gasan' },
  { label: 'Gąsawa', value: 'Gąsawa' },
  { label: 'Gasca', value: 'Gasca' },
  { label: 'Gaschurn', value: 'Gaschurn' },
  { label: 'Gasconade County', value: 'Gasconade County' },
  { label: 'Gascones', value: 'Gascones' },
  { label: 'Gascueña', value: 'Gascueña' },
  { label: 'Gascueña De Bornova', value: 'Gascueña De Bornova' },
  { label: 'Gasen', value: 'Gasen' },
  { label: 'Gashani', value: 'Gashani' },
  { label: 'Gashua', value: 'Gashua' },
  { label: 'Gasny', value: 'Gasny' },
  { label: 'Gąsocin', value: 'Gąsocin' },
  { label: 'Gaspar', value: 'Gaspar' },
  { label: 'Gaspar Hernández', value: 'Gaspar Hernández' },
  { label: 'Gaspé', value: 'Gaspé' },
  { label: 'Gasperina', value: 'Gasperina' },
  { label: 'Gaspésie-Îles-De-La-Madeleine', value: 'Gaspésie-Îles-De-La-Madeleine' },
  { label: 'Gaspoltshofen', value: 'Gaspoltshofen' },
  { label: 'Gasport', value: 'Gasport' },
  { label: 'Gaspra', value: 'Gaspra' },
  { label: 'Gasselte', value: 'Gasselte' },
  { label: 'Gasselternijveen', value: 'Gasselternijveen' },
  { label: 'Gassin', value: 'Gassin' },
  { label: 'Gassino Torinese', value: 'Gassino Torinese' },
  { label: 'Gassol', value: 'Gassol' },
  { label: 'Gassville', value: 'Gassville' },
  { label: 'Gastão Vidigal', value: 'Gastão Vidigal' },
  { label: 'Gastello', value: 'Gastello' },
  { label: 'Gastern', value: 'Gastern' },
  { label: 'Gaston', value: 'Gaston' },
  { label: 'Gaston', value: 'Gaston' },
  { label: 'Gaston County', value: 'Gaston County' },
  { label: 'Gastonia', value: 'Gastonia' },
  { label: 'Gastonville', value: 'Gastonville' },
  { label: 'Gastor El', value: 'Gastor El' },
  { label: 'Gastoúni', value: 'Gastoúni' },
  { label: 'Gastre', value: 'Gastre' },
  { label: 'Gasville-Oisème', value: 'Gasville-Oisème' },
  { label: 'Gaszowice', value: 'Gaszowice' },
  { label: 'Gata', value: 'Gata' },
  { label: 'Gata De Gorgos', value: 'Gata De Gorgos' },
  { label: 'Gatab', value: 'Gatab' },
  { label: 'Gătaia', value: 'Gătaia' },
  { label: 'Gataivai', value: 'Gataivai' },
  { label: 'Gatak', value: 'Gatak' },
  { label: 'Gatbo', value: 'Gatbo' },
  { label: 'Gatchina', value: 'Gatchina' },
  { label: 'Gate City', value: 'Gate City' },
  { label: 'Gatersleben', value: 'Gatersleben' },
  { label: 'Gates County', value: 'Gates County' },
  { label: 'Gates Mills', value: 'Gates Mills' },
  { label: 'Gateshead', value: 'Gateshead' },
  { label: 'Gateshead', value: 'Gateshead' },
  { label: 'Gates-North Gates', value: 'Gates-North Gates' },
  { label: 'Gatesville', value: 'Gatesville' },
  { label: 'Gatesville', value: 'Gatesville' },
  { label: 'Gateway', value: 'Gateway' },
  { label: 'Gateway', value: 'Gateway' },
  { label: 'Gatika', value: 'Gatika' },
  { label: 'Gatineau', value: 'Gatineau' },
  { label: 'Gatlinburg', value: 'Gatlinburg' },
  { label: 'Gato Colorado', value: 'Gato Colorado' },
  { label: 'Gatón De Campos', value: 'Gatón De Campos' },
  { label: 'Gátova', value: 'Gátova' },
  { label: 'Gatow', value: 'Gatow' },
  { label: 'Gattaran', value: 'Gattaran' },
  { label: 'Gattaran', value: 'Gattaran' },
  { label: 'Gattendorf', value: 'Gattendorf' },
  { label: 'Gattendorf', value: 'Gattendorf' },
  { label: 'Gatteo A Mare', value: 'Gatteo A Mare' },
  { label: 'Gatteo-Santangelo', value: 'Gatteo-Santangelo' },
  { label: 'Gattico', value: 'Gattico' },
  { label: 'Gattières', value: 'Gattières' },
  { label: 'Gattikon', value: 'Gattikon' },
  { label: 'Gattinara', value: 'Gattinara' },
  { label: 'Gatton', value: 'Gatton' },
  { label: 'Gatún', value: 'Gatún' },
  { label: 'Gatuncillo', value: 'Gatuncillo' },
  { label: 'Gau-Algesheim', value: 'Gau-Algesheim' },
  { label: 'Gau-Bickelheim', value: 'Gau-Bickelheim' },
  { label: 'Gau-Bischofsheim', value: 'Gau-Bischofsheim' },
  { label: 'Gaubitsch', value: 'Gaubitsch' },
  { label: 'Gaúcha Do Norte', value: 'Gaúcha Do Norte' },
  { label: 'Gauchy', value: 'Gauchy' },
  { label: 'Gaucín', value: 'Gaucín' },
  { label: 'Găujani', value: 'Găujani' },
  { label: 'Gaukönigshofen', value: 'Gaukönigshofen' },
  { label: 'Gaular', value: 'Gaular' },
  { label: 'Gaumberg', value: 'Gaumberg' },
  { label: 'Gau-Odernheim', value: 'Gau-Odernheim' },
  { label: 'Gaupne', value: 'Gaupne' },
  { label: 'Gaura Sângerului', value: 'Gaura Sângerului' },
  { label: 'Gaurama', value: 'Gaurama' },
  { label: 'Gaurela', value: 'Gaurela' },
  { label: 'Gauriaguet', value: 'Gauriaguet' },
  { label: 'Gauriganj', value: 'Gauriganj' },
  { label: 'Gauripur', value: 'Gauripur' },
  { label: 'Gausdal', value: 'Gausdal' },
  { label: 'Gautam Buddha Nagar', value: 'Gautam Buddha Nagar' },
  { label: 'Gautampura', value: 'Gautampura' },
  { label: 'Gautegiz Arteaga', value: 'Gautegiz Arteaga' },
  { label: 'Gautier', value: 'Gautier' },
  { label: 'Gauting', value: 'Gauting' },
  { label: 'Gav Zaban', value: 'Gav Zaban' },
  { label: 'Gavà', value: 'Gavà' },
  { label: 'Gavaloú', value: 'Gavaloú' },
  { label: 'Găvănești', value: 'Găvănești' },
  { label: 'Găvănești', value: 'Găvănești' },
  { label: 'Gavarda', value: 'Gavarda' },
  { label: 'Gavardo-Sopraponte', value: 'Gavardo-Sopraponte' },
  { label: 'Gavarno Rinnovata', value: 'Gavarno Rinnovata' },
  { label: 'Gavarno-Tribulina', value: 'Gavarno-Tribulina' },
  { label: 'Gavarr', value: 'Gavarr' },
  { label: 'Gavazzana', value: 'Gavazzana' },
  { label: 'Gavello', value: 'Gavello' },
  { label: 'Gaven', value: 'Gaven' },
  { label: 'Gavere', value: 'Gavere' },
  { label: 'Gaverina Terme', value: 'Gaverina Terme' },
  { label: 'Gavet De La Conca', value: 'Gavet De La Conca' },
  { label: 'Gavi', value: 'Gavi' },
  { label: 'Gavia De Rionda', value: 'Gavia De Rionda' },
  { label: 'Gavião', value: 'Gavião' },
  { label: 'Gavião', value: 'Gavião' },
  { label: 'Gavião Peixoto', value: 'Gavião Peixoto' },
  { label: 'Gavignano', value: 'Gavignano' },
  { label: 'Gavilanes', value: 'Gavilanes' },
  { label: 'Gaviotas Sur (El Cedral)', value: 'Gaviotas Sur (El Cedral)' },
  { label: 'Gavirate', value: 'Gavirate' },
  { label: 'Gavkaran', value: 'Gavkaran' },
  { label: 'Gävle', value: 'Gävle' },
  { label: 'Gavoi', value: 'Gavoi' },
  { label: 'Gavojdia', value: 'Gavojdia' },
  { label: 'Gavorrano', value: 'Gavorrano' },
  { label: 'Gavray', value: 'Gavray' },
  { label: 'Gavrilov Posad', value: 'Gavrilov Posad' },
  { label: 'Gavrilovka Vtoraya', value: 'Gavrilovka Vtoraya' },
  { label: 'Gavrilov-Yam', value: 'Gavrilov-Yam' },
  { label: 'Gavrilov-Yamskiy Rayon', value: 'Gavrilov-Yamskiy Rayon' },
  { label: 'Gawan', value: 'Gawan' },
  { label: 'Gaweinstal', value: 'Gaweinstal' },
  { label: 'Gawler', value: 'Gawler' },
  { label: 'Gawler East', value: 'Gawler East' },
  { label: 'Gawler South', value: 'Gawler South' },
  { label: 'Gaworzyce', value: 'Gaworzyce' },
  { label: 'Gay', value: 'Gay' },
  { label: 'Gay', value: 'Gay' },
  { label: 'Gaya', value: 'Gaya' },
  { label: 'Gaya', value: 'Gaya' },
  { label: 'Gaya', value: 'Gaya' },
  { label: 'Gayabois', value: 'Gayabois' },
  { label: 'Gayaman', value: 'Gayaman' },
  { label: 'Gayaman', value: 'Gayaman' },
  { label: 'Gayduk', value: 'Gayduk' },
  { label: 'Gayéri', value: 'Gayéri' },
  { label: 'Gayle', value: 'Gayle' },
  { label: 'Gaylord', value: 'Gaylord' },
  { label: 'Gaylord', value: 'Gaylord' },
  { label: 'Gayndah', value: 'Gayndah' },
  { label: 'Gayny', value: 'Gayny' },
  { label: 'Gaythorne', value: 'Gaythorne' },
  { label: 'Gazanak', value: 'Gazanak' },
  { label: 'Găzărie', value: 'Găzărie' },
  { label: 'Gazborkhar', value: 'Gazborkhar' },
  { label: 'Gazelle', value: 'Gazelle' },
  { label: 'Gazeran', value: 'Gazeran' },
  { label: 'Gazi', value: 'Gazi' },
  { label: 'Gázi', value: 'Gázi' },
  { label: 'Gaziemir', value: 'Gaziemir' },
  { label: 'Gazik', value: 'Gazik' },
  { label: 'Gazimuro-Zavodskiy Rayon', value: 'Gazimuro-Zavodskiy Rayon' },
  { label: 'Gazimurskiy Zavod', value: 'Gazimurskiy Zavod' },
  { label: 'Gaziosmanpaşa', value: 'Gaziosmanpaşa' },
  { label: 'Gazipaşa', value: 'Gazipaşa' },
  { label: 'Gazipur ', value: 'Gazipur ' },
  { label: 'Gazli', value: 'Gazli' },
  { label: 'Gazojak', value: 'Gazojak' },
  { label: 'Gazoldo Degli Ippoliti', value: 'Gazoldo Degli Ippoliti' },
  { label: 'Gázoros', value: 'Gázoros' },
  { label: 'Gaztelu', value: 'Gaztelu' },
  { label: 'Gazzada Schianno', value: 'Gazzada Schianno' },
  { label: 'Gazzaniga', value: 'Gazzaniga' },
  { label: 'Gazzo', value: 'Gazzo' },
  { label: 'Gazzo', value: 'Gazzo' },
  { label: 'Gazzola', value: 'Gazzola' },
  { label: 'Gazzolo-Volpino', value: 'Gazzolo-Volpino' },
  { label: 'Gazzuolo', value: 'Gazzuolo' },
  { label: 'Gbadolite', value: 'Gbadolite' },
  { label: 'Gbarnga', value: 'Gbarnga' },
  { label: 'Gbawe', value: 'Gbawe' },
  { label: 'Gbêkê', value: 'Gbêkê' },
  { label: 'Gbely', value: 'Gbely' },
  { label: 'Gberia Fotombu', value: 'Gberia Fotombu' },
  { label: 'Gbewebu', value: 'Gbewebu' },
  { label: 'Gbôklé', value: 'Gbôklé' },
  { label: 'Gboko', value: 'Gboko' },
  { label: 'Gbongan', value: 'Gbongan' },
  { label: 'Gdańsk', value: 'Gdańsk' },
  { label: 'Gdov', value: 'Gdov' },
  { label: 'Gdovskiy Rayon', value: 'Gdovskiy Rayon' },
  { label: 'Gdów', value: 'Gdów' },
  { label: 'Gdynia', value: 'Gdynia' },
  { label: 'Gea De Albarracín', value: 'Gea De Albarracín' },
  { label: 'Geaca', value: 'Geaca' },
  { label: 'Geamăna', value: 'Geamăna' },
  { label: 'Gearhart', value: 'Gearhart' },
  { label: 'Geary', value: 'Geary' },
  { label: 'Geary County', value: 'Geary County' },
  { label: 'Geauga County', value: 'Geauga County' },
  { label: 'Gebeit', value: 'Gebeit' },
  { label: 'Gebenstorf', value: 'Gebenstorf' },
  { label: 'Gebesee', value: 'Gebesee' },
  { label: 'Gebhardshain', value: 'Gebhardshain' },
  { label: 'Gebog', value: 'Gebog' },
  { label: 'Geboltskirchen', value: 'Geboltskirchen' },
  { label: 'Gebre Guracha', value: 'Gebre Guracha' },
  { label: 'Gebsattel', value: 'Gebsattel' },
  { label: 'Gebze', value: 'Gebze' },
  { label: 'Gechingen', value: 'Gechingen' },
  { label: 'Gedangan', value: 'Gedangan' },
  { label: 'Geddington', value: 'Geddington' },
  { label: 'Gedeo Zone', value: 'Gedeo Zone' },
  { label: 'Gedera', value: 'Gedera' },
  { label: 'Gedern', value: 'Gedern' },
  { label: 'Gedersdorf', value: 'Gedersdorf' },
  { label: 'Gedinne', value: 'Gedinne' },
  { label: 'Gediz', value: 'Gediz' },
  { label: 'Gedney Hill', value: 'Gedney Hill' },
  { label: 'Gēdo', value: 'Gēdo' },
  { label: 'Gedzhukh', value: 'Gedzhukh' },
  { label: 'Geebung', value: 'Geebung' },
  { label: 'Geel', value: 'Geel' },
  { label: 'Geelong', value: 'Geelong' },
  { label: 'Geelong City Centre', value: 'Geelong City Centre' },
  { label: 'Geelong West', value: 'Geelong West' },
  { label: 'Geenhoven', value: 'Geenhoven' },
  { label: 'Geer', value: 'Geer' },
  { label: 'Geertruidenberg', value: 'Geertruidenberg' },
  { label: 'Geeste', value: 'Geeste' },
  { label: 'Geesthacht', value: 'Geesthacht' },
  { label: 'Geetbets', value: 'Geetbets' },
  { label: 'Geeveston', value: 'Geeveston' },
  { label: 'Geffen', value: 'Geffen' },
  { label: 'Gefrees', value: 'Gefrees' },
  { label: 'Géfyra', value: 'Géfyra' },
  { label: 'Géfyra', value: 'Géfyra' },
  { label: 'Gégény', value: 'Gégény' },
  { label: 'Geghakert', value: 'Geghakert' },
  { label: 'Geghamasar', value: 'Geghamasar' },
  { label: 'Geghamavan', value: 'Geghamavan' },
  { label: 'Geghanist', value: 'Geghanist' },
  { label: 'Gehrde', value: 'Gehrde' },
  { label: 'Gehrden', value: 'Gehrden' },
  { label: 'Gehren', value: 'Gehren' },
  { label: 'Geidam', value: 'Geidam' },
  { label: 'Geidorf', value: 'Geidorf' },
  { label: 'Geiersthal', value: 'Geiersthal' },
  { label: 'Geilenkirchen', value: 'Geilenkirchen' },
  { label: 'Geilo', value: 'Geilo' },
  { label: 'Geilston Bay', value: 'Geilston Bay' },
  { label: 'Geiro', value: 'Geiro' },
  { label: 'Geisa', value: 'Geisa' },
  { label: 'Geisei', value: 'Geisei' },
  { label: 'Geiselbach', value: 'Geiselbach' },
  { label: 'Geiselhöring', value: 'Geiselhöring' },
  { label: 'Geiselwind', value: 'Geiselwind' },
  { label: 'Geisenfeld', value: 'Geisenfeld' },
  { label: 'Geisenhausen', value: 'Geisenhausen' },
  { label: 'Geisenheim', value: 'Geisenheim' },
  { label: 'Geising', value: 'Geising' },
  { label: 'Geisingen', value: 'Geisingen' },
  { label: 'Geisleden', value: 'Geisleden' },
  { label: 'Geislingen', value: 'Geislingen' },
  { label: 'Geislingen An Der Steige', value: 'Geislingen An Der Steige' },
  { label: 'Geismar', value: 'Geismar' },
  { label: 'Geispolsheim', value: 'Geispolsheim' },
  { label: 'Geistown', value: 'Geistown' },
  { label: 'Geistthal', value: 'Geistthal' },
  { label: 'Geita', value: 'Geita' },
  { label: 'Geitenkamp', value: 'Geitenkamp' },
  { label: 'Geithain', value: 'Geithain' },
  { label: 'Gejiu', value: 'Gejiu' },
  { label: 'Gejuelo Del Barro', value: 'Gejuelo Del Barro' },
  { label: 'Gekhi', value: 'Gekhi' },
  { label: 'Gela', value: 'Gela' },
  { label: 'Gelbensande', value: 'Gelbensande' },
  { label: 'Geldermalsen', value: 'Geldermalsen' },
  { label: 'Geldermalsen-West', value: 'Geldermalsen-West' },
  { label: 'Geldern', value: 'Geldern' },
  { label: 'Geldersheim', value: 'Geldersheim' },
  { label: 'Geldo', value: 'Geldo' },
  { label: 'Geldrop', value: 'Geldrop' },
  { label: 'Geleen', value: 'Geleen' },
  { label: 'Gelemso', value: 'Gelemso' },
  { label: 'Gelenau', value: 'Gelenau' },
  { label: 'Gelendost', value: 'Gelendost' },
  { label: 'Gelendzhik', value: 'Gelendzhik' },
  { label: 'Gelgaudiškis', value: 'Gelgaudiškis' },
  { label: 'Gelibolu', value: 'Gelibolu' },
  { label: 'Gelida', value: 'Gelida' },
  { label: 'Gelles', value: 'Gelles' },
  { label: 'Gelligaer', value: 'Gelligaer' },
  { label: 'Gello', value: 'Gello' },
  { label: 'Gelnhausen', value: 'Gelnhausen' },
  { label: 'Gelnica', value: 'Gelnica' },
  { label: 'Gelorup', value: 'Gelorup' },
  { label: 'Gelos', value: 'Gelos' },
  { label: 'Gelsa', value: 'Gelsa' },
  { label: 'Gelsenkirchen', value: 'Gelsenkirchen' },
  { label: 'Geltendorf', value: 'Geltendorf' },
  { label: 'Gelterkinden', value: 'Gelterkinden' },
  { label: 'Gelting', value: 'Gelting' },
  { label: 'Gelu', value: 'Gelu' },
  { label: 'Gelves', value: 'Gelves' },
  { label: 'Gem County', value: 'Gem County' },
  { label: 'Gema', value: 'Gema' },
  { label: 'Gembloux', value: 'Gembloux' },
  { label: 'Gembrook', value: 'Gembrook' },
  { label: 'Gembu', value: 'Gembu' },
  { label: 'Gemeente Appingedam', value: 'Gemeente Appingedam' },
  { label: 'Gemeente Delfzijl', value: 'Gemeente Delfzijl' },
  { label: 'Gemeente Groningen', value: 'Gemeente Groningen' },
  { label: 'Gemeente Loppersum', value: 'Gemeente Loppersum' },
  { label: 'Gemeente Oldambt', value: 'Gemeente Oldambt' },
  { label: 'Gemeente Pekela', value: 'Gemeente Pekela' },
  { label: 'Gemeente Stadskanaal', value: 'Gemeente Stadskanaal' },
  { label: 'Gemeente Veendam', value: 'Gemeente Veendam' },
  { label: 'Gemeinde Friedland', value: 'Gemeinde Friedland' },
  { label: 'Gemena', value: 'Gemena' },
  { label: 'Gemenea Brătulești', value: 'Gemenea Brătulești' },
  { label: 'Gemenele', value: 'Gemenele' },
  { label: 'Gemeni', value: 'Gemeni' },
  { label: 'Gémenos', value: 'Gémenos' },
  { label: 'Gemerek', value: 'Gemerek' },
  { label: 'Gemert', value: 'Gemert' },
  { label: 'Gemini', value: 'Gemini' },
  { label: 'Geminiano', value: 'Geminiano' },
  { label: 'Gemla', value: 'Gemla' },
  { label: 'Gemlik', value: 'Gemlik' },
  { label: 'Gemmano', value: 'Gemmano' },
  { label: 'Gemmersdorf', value: 'Gemmersdorf' },
  { label: 'Gemmingen', value: 'Gemmingen' },
  { label: 'Gemmrigheim', value: 'Gemmrigheim' },
  { label: 'Gemona', value: 'Gemona' },
  { label: 'Gemonde', value: 'Gemonde' },
  { label: 'Gemonio', value: 'Gemonio' },
  { label: 'Gémozac', value: 'Gémozac' },
  { label: 'Gemunde', value: 'Gemunde' },
  { label: 'Gemünden', value: 'Gemünden' },
  { label: 'Gemünden Am Main', value: 'Gemünden Am Main' },
  { label: 'Gemünden An Der Wohra', value: 'Gemünden An Der Wohra' },
  { label: 'Gemuño', value: 'Gemuño' },
  { label: 'Genalguacil', value: 'Genalguacil' },
  { label: 'Genappe', value: 'Genappe' },
  { label: 'Genaro Estrada', value: 'Genaro Estrada' },
  { label: 'Genarp', value: 'Genarp' },
  { label: 'Genas', value: 'Genas' },
  { label: 'Génave', value: 'Génave' },
  { label: 'Genaveh', value: 'Genaveh' },
  { label: 'Genay', value: 'Genay' },
  { label: 'Genazzano', value: 'Genazzano' },
  { label: 'Genç', value: 'Genç' },
  { label: 'Gencsapáti', value: 'Gencsapáti' },
  { label: 'Genderen', value: 'Genderen' },
  { label: 'Genderkingen', value: 'Genderkingen' },
  { label: 'Gendringen', value: 'Gendringen' },
  { label: 'Gendt', value: 'Gendt' },
  { label: 'Genech', value: 'Genech' },
  { label: 'Geneina', value: 'Geneina' },
  { label: 'Génelard', value: 'Génelard' },
  { label: 'Générac', value: 'Générac' },
  { label: 'General Acha', value: 'General Acha' },
  { label: 'General Alatriste (San Joaquín)', value: 'General Alatriste (San Joaquín)' },
  { label: 'General Alvear', value: 'General Alvear' },
  { label: 'General Andrés Figueroa', value: 'General Andrés Figueroa' },
  { label: 'General Ángel Flores (La Palma)', value: 'General Ángel Flores (La Palma)' },
  { label: 'General Artigas', value: 'General Artigas' },
  { label: 'General Baldissera', value: 'General Baldissera' },
  { label: 'General Berthelot', value: 'General Berthelot' },
  { label: 'General Bravo', value: 'General Bravo' },
  { label: 'General Cabrera', value: 'General Cabrera' },
  { label: 'General Calixto Contreras', value: 'General Calixto Contreras' },
  { label: 'General Câmara', value: 'General Câmara' },
  { label: 'General Campos', value: 'General Campos' },
  { label: 'General Canuto A. Neri', value: 'General Canuto A. Neri' },
  { label: 'General Carneiro', value: 'General Carneiro' },
  { label: 'General Carneiro', value: 'General Carneiro' },
  { label: 'General Cepeda', value: 'General Cepeda' },
  { label: 'General Conesa', value: 'General Conesa' },
  { label: 'General Delgado', value: 'General Delgado' },
  { label: 'General Elizardo Aquino', value: 'General Elizardo Aquino' },
  { label: 'General Emilio Aguinaldo', value: 'General Emilio Aguinaldo' },
  { label: 'General Enrique Estrada', value: 'General Enrique Estrada' },
  { label: 'General Enrique Godoy', value: 'General Enrique Godoy' },
  { label: 'General Enrique Mosconi', value: 'General Enrique Mosconi' },
  { label: 'General Enrique Mosconi', value: 'General Enrique Mosconi' },
  { label: 'General Escobedo', value: 'General Escobedo' },
  { label: 'General Felipe Ángeles', value: 'General Felipe Ángeles' },
  { label: 'General Felipe Ángeles (Los Ángeles)', value: 'General Felipe Ángeles (Los Ángeles)' },
  { label: 'General Fernández Oro', value: 'General Fernández Oro' },
  { label: 'General Francisco Villa', value: 'General Francisco Villa' },
  { label: 'General Gabriel Leyva', value: 'General Gabriel Leyva' },
  { label: 'General Galarza', value: 'General Galarza' },
  { label: 'General Heliodoro Castillo', value: 'General Heliodoro Castillo' },
  { label: 'General Higinio Morínigo', value: 'General Higinio Morínigo' },
  { label: 'General Ignacio Zaragoza', value: 'General Ignacio Zaragoza' },
  { label: 'General José De San Martín', value: 'General José De San Martín' },
  { label: 'General José Eduvigis Díaz', value: 'General José Eduvigis Díaz' },
  { label: 'General Juan José Baz (San José Del Corral)', value: 'General Juan José Baz (San José Del Corral)' },
  { label: 'General Juan José Ríos', value: 'General Juan José Ríos' },
  { label: 'General Lagos', value: 'General Lagos' },
  { label: 'General Lauro G. Caloca (El Rascón)', value: 'General Lauro G. Caloca (El Rascón)' },
  { label: 'General Lázaro Cárdenas', value: 'General Lázaro Cárdenas' },
  { label: 'General Lázaro Cárdenas (El Colorado)', value: 'General Lázaro Cárdenas (El Colorado)' },
  { label: 'General Levalle', value: 'General Levalle' },
  { label: 'General Luis Felipe Domínguez Suárez', value: 'General Luis Felipe Domínguez Suárez' },
  { label: 'General Luna', value: 'General Luna' },
  { label: 'General Luna', value: 'General Luna' },
  { label: 'General Luna', value: 'General Luna' },
  { label: 'General Luna', value: 'General Luna' },
  { label: 'General Luna', value: 'General Luna' },
  { label: 'General Macarthur', value: 'General Macarthur' },
  { label: 'General Macarthur', value: 'General Macarthur' },
  { label: 'General Mamerto Natividad', value: 'General Mamerto Natividad' },
  { label: 'General Mamerto Natividad', value: 'General Mamerto Natividad' },
  { label: 'General Manuel J. Campos', value: 'General Manuel J. Campos' },
  { label: 'General Maynard', value: 'General Maynard' },
  { label: 'General Miguel Alemán', value: 'General Miguel Alemán' },
  { label: 'General Nakar', value: 'General Nakar' },
  { label: 'General Pánfilo Natera', value: 'General Pánfilo Natera' },
  { label: 'General Pascual Fentes', value: 'General Pascual Fentes' },
  { label: 'General Pedro María Anaya', value: 'General Pedro María Anaya' },
  { label: 'General Pico', value: 'General Pico' },
  { label: 'General Pinedo', value: 'General Pinedo' },
  { label: 'General Ramírez', value: 'General Ramírez' },
  { label: 'General Roca', value: 'General Roca' },
  { label: 'General Roca', value: 'General Roca' },
  { label: 'General Salgado', value: 'General Salgado' },
  { label: 'General Sampaio', value: 'General Sampaio' },
  { label: 'General San Martín', value: 'General San Martín' },
  { label: 'General Santos', value: 'General Santos' },
  { label: 'General Santos', value: 'General Santos' },
  { label: 'General Tapia', value: 'General Tapia' },
  { label: 'General Terán Nuevo León', value: 'General Terán Nuevo León' },
  { label: 'General Tinio', value: 'General Tinio' },
  { label: 'General Tinio', value: 'General Tinio' },
  { label: 'General Toshevo', value: 'General Toshevo' },
  { label: 'General Treviño', value: 'General Treviño' },
  { label: 'General Trias', value: 'General Trias' },
  { label: 'General Vedia', value: 'General Vedia' },
  { label: 'General Zuazua', value: 'General Zuazua' },
  { label: 'Genesee', value: 'Genesee' },
  { label: 'Genesee County', value: 'Genesee County' },
  { label: 'Genesee County', value: 'Genesee County' },
  { label: 'Geneseo', value: 'Geneseo' },
  { label: 'Geneseo', value: 'Geneseo' },
  { label: 'Geneston', value: 'Geneston' },
  { label: 'Genet', value: 'Genet' },
  { label: 'Geneva', value: 'Geneva' },
  { label: 'Geneva', value: 'Geneva' },
  { label: 'Geneva', value: 'Geneva' },
  { label: 'Geneva', value: 'Geneva' },
  { label: 'Geneva', value: 'Geneva' },
  { label: 'Geneva', value: 'Geneva' },
  { label: 'Geneva', value: 'Geneva' },
  { label: 'Geneva', value: 'Geneva' },
  { label: 'Geneva County', value: 'Geneva County' },
  { label: 'Geneva-On-The-Lake', value: 'Geneva-On-The-Lake' },
  { label: 'Genève', value: 'Genève' },
  { label: 'Genevilla', value: 'Genevilla' },
  { label: 'Genga', value: 'Genga' },
  { label: 'Gengenbach', value: 'Gengenbach' },
  { label: 'Genhe', value: 'Genhe' },
  { label: 'Genillé', value: 'Genillé' },
  { label: 'Genio Civile', value: 'Genio Civile' },
  { label: 'Génissac', value: 'Génissac' },
  { label: 'Genisséa', value: 'Genisséa' },
  { label: 'Génissieux', value: 'Génissieux' },
  { label: 'Genivolta', value: 'Genivolta' },
  { label: 'Genk', value: 'Genk' },
  { label: 'Genlis', value: 'Genlis' },
  { label: 'Gennep', value: 'Gennep' },
  { label: 'Gennes', value: 'Gennes' },
  { label: 'Gennevilliers', value: 'Gennevilliers' },
  { label: 'Genoa', value: 'Genoa' },
  { label: 'Genoa', value: 'Genoa' },
  { label: 'Genoa', value: 'Genoa' },
  { label: 'Genoa City', value: 'Genoa City' },
  { label: 'Genoenhuis', value: 'Genoenhuis' },
  { label: 'Genola', value: 'Genola' },
  { label: 'Genola', value: 'Genola' },
  { label: 'Genolier', value: 'Genolier' },
  { label: 'Genoni', value: 'Genoni' },
  { label: 'Génova', value: 'Génova' },
  { label: 'Génova', value: 'Génova' },
  { label: 'Genovés', value: 'Genovés' },
  { label: 'Gensac-La-Pallue', value: 'Gensac-La-Pallue' },
  { label: 'Gensingen', value: 'Gensingen' },
  { label: 'Gent', value: 'Gent' },
  { label: 'Genteng', value: 'Genteng' },
  { label: 'Genthin', value: 'Genthin' },
  { label: 'Gentil', value: 'Gentil' },
  { label: 'Gentil', value: 'Gentil' },
  { label: 'Gentilly', value: 'Gentilly' },
  { label: 'Gentio Do Ouro', value: 'Gentio Do Ouro' },
  { label: 'Gentofte Kommune', value: 'Gentofte Kommune' },
  { label: 'Gentry', value: 'Gentry' },
  { label: 'Gentry County', value: 'Gentry County' },
  { label: 'Genuri', value: 'Genuri' },
  { label: 'Genzano Di Lucania', value: 'Genzano Di Lucania' },
  { label: 'Genzano Di Roma', value: 'Genzano Di Roma' },
  { label: 'Genzone', value: 'Genzone' },
  { label: 'Geoagiu', value: 'Geoagiu' },
  { label: 'Geochang-Gun', value: 'Geochang-Gun' },
  { label: 'Geographe', value: 'Geographe' },
  { label: 'Geoje-Si', value: 'Geoje-Si' },
  { label: 'Geoktschai', value: 'Geoktschai' },
  { label: 'George', value: 'George' },
  { label: 'George', value: 'George' },
  { label: 'George Charles Boulevard', value: 'George Charles Boulevard' },
  { label: 'George County', value: 'George County' },
  { label: 'George Enescu', value: 'George Enescu' },
  { label: 'George Town', value: 'George Town' },
  { label: 'George Town', value: 'George Town' },
  { label: 'George Town', value: 'George Town' },
  { label: 'George West', value: 'George West' },
  { label: 'Georgenberg', value: 'Georgenberg' },
  { label: 'Georgenberg', value: 'Georgenberg' },
  { label: 'Georgensgmünd', value: 'Georgensgmünd' },
  { label: 'Georgenthal', value: 'Georgenthal' },
  { label: 'Georges Hall', value: 'Georges Hall' },
  { label: 'Georges Plain', value: 'Georges Plain' },
  { label: 'Georges Valley', value: 'Georges Valley' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Georgetown County', value: 'Georgetown County' },
  { label: 'Georgiana', value: 'Georgiana' },
  { label: 'Georgīevka', value: 'Georgīevka' },
  { label: 'Georgioupolis', value: 'Georgioupolis' },
  { label: 'Georgiyevka', value: 'Georgiyevka' },
  { label: 'Georgiyevka', value: 'Georgiyevka' },
  { label: 'Georgiyevsk', value: 'Georgiyevsk' },
  { label: 'Georgiyevskaya', value: 'Georgiyevskaya' },
  { label: 'Georgiyevskoye', value: 'Georgiyevskoye' },
  { label: 'Georgsdorf', value: 'Georgsdorf' },
  { label: 'Georgsmarienhütte', value: 'Georgsmarienhütte' },
  { label: 'Geovillas Laureles Del Campanario', value: 'Geovillas Laureles Del Campanario' },
  { label: 'Gepiu', value: 'Gepiu' },
  { label: 'Ger', value: 'Ger' },
  { label: 'Ger', value: 'Ger' },
  { label: 'Gera', value: 'Gera' },
  { label: 'Gera Lario', value: 'Gera Lario' },
  { label: 'Geraardsbergen', value: 'Geraardsbergen' },
  { label: 'Geraberg', value: 'Geraberg' },
  { label: 'Gerabronn', value: 'Gerabronn' },
  { label: 'Gerace', value: 'Gerace' },
  { label: 'Gerach', value: 'Gerach' },
  { label: 'Geraci Siculo', value: 'Geraci Siculo' },
  { label: 'Gerakaroú', value: 'Gerakaroú' },
  { label: 'Gérakas', value: 'Gérakas' },
  { label: 'Geráki', value: 'Geráki' },
  { label: 'Gerald', value: 'Gerald' },
  { label: 'Geraldine', value: 'Geraldine' },
  { label: 'Geraldton', value: 'Geraldton' },
  { label: 'Geraldton City Centre', value: 'Geraldton City Centre' },
  { label: 'Geráni', value: 'Geráni' },
  { label: 'Gerano', value: 'Gerano' },
  { label: 'Gérardmer', value: 'Gérardmer' },
  { label: 'Geras', value: 'Geras' },
  { label: 'Gerasdorf Bei Wien', value: 'Gerasdorf Bei Wien' },
  { label: 'Gerash', value: 'Gerash' },
  { label: 'Gerber', value: 'Gerber' },
  { label: 'Gerbéviller', value: 'Gerbéviller' },
  { label: 'Gerbido', value: 'Gerbido' },
  { label: 'Gerbole', value: 'Gerbole' },
  { label: 'Gerbole-Zucche', value: 'Gerbole-Zucche' },
  { label: 'Gerbrunn', value: 'Gerbrunn' },
  { label: 'Gerbstedt', value: 'Gerbstedt' },
  { label: 'Gercüş', value: 'Gercüş' },
  { label: 'Gerd Kashaneh', value: 'Gerd Kashaneh' },
  { label: 'Gerdau', value: 'Gerdau' },
  { label: 'Gerde', value: 'Gerde' },
  { label: 'Gerede', value: 'Gerede' },
  { label: 'Gereida', value: 'Gereida' },
  { label: 'Gerena', value: 'Gerena' },
  { label: 'Gerenzago', value: 'Gerenzago' },
  { label: 'Gerenzano', value: 'Gerenzano' },
  { label: 'Gerersdorf', value: 'Gerersdorf' },
  { label: 'Gereshk', value: 'Gereshk' },
  { label: 'Geretsried', value: 'Geretsried' },
  { label: 'Gereykhanovskoye', value: 'Gereykhanovskoye' },
  { label: 'Gerga', value: 'Gerga' },
  { label: 'Gérgal', value: 'Gérgal' },
  { label: 'Gergebil’', value: 'Gergebil’' },
  { label: 'Gergebil’Skiy Rayon', value: 'Gergebil’Skiy Rayon' },
  { label: 'Gergei', value: 'Gergei' },
  { label: 'Gerger', value: 'Gerger' },
  { label: 'Gérgeri', value: 'Gérgeri' },
  { label: 'Gergy', value: 'Gergy' },
  { label: 'Gerhardshofen', value: 'Gerhardshofen' },
  { label: 'Géri', value: 'Géri' },
  { label: 'Geria', value: 'Geria' },
  { label: 'Gerindote', value: 'Gerindote' },
  { label: 'Gering', value: 'Gering' },
  { label: 'Geringswalde', value: 'Geringswalde' },
  { label: 'Gerlafingen', value: 'Gerlafingen' },
  { label: 'Gerlamoos', value: 'Gerlamoos' },
  { label: 'Gerlingen', value: 'Gerlingen' },
  { label: 'Gerlos', value: 'Gerlos' },
  { label: 'Gerlosberg', value: 'Gerlosberg' },
  { label: 'Germagnano', value: 'Germagnano' },
  { label: 'Germagno', value: 'Germagno' },
  { label: 'German Busch', value: 'German Busch' },
  { label: 'Germantown', value: 'Germantown' },
  { label: 'Germantown', value: 'Germantown' },
  { label: 'Germantown', value: 'Germantown' },
  { label: 'Germantown', value: 'Germantown' },
  { label: 'Germantown', value: 'Germantown' },
  { label: 'Germantown Hills', value: 'Germantown Hills' },
  { label: 'Germasógeia', value: 'Germasógeia' },
  { label: 'Germenchik', value: 'Germenchik' },
  { label: 'Germenchuk', value: 'Germenchuk' },
  { label: 'Germencik', value: 'Germencik' },
  { label: 'Germering', value: 'Germering' },
  { label: 'Germersheim', value: 'Germersheim' },
  { label: 'Germi', value: 'Germi' },
  { label: 'Germignaga', value: 'Germignaga' },
  { label: 'Germoe', value: 'Germoe' },
  { label: 'Gernika-Lumo', value: 'Gernika-Lumo' },
  { label: 'Gernrode', value: 'Gernrode' },
  { label: 'Gernrode', value: 'Gernrode' },
  { label: 'Gernsbach', value: 'Gernsbach' },
  { label: 'Gernsheim', value: 'Gernsheim' },
  { label: 'Gero', value: 'Gero' },
  { label: 'Gerocarne', value: 'Gerocarne' },
  { label: 'Gerola Alta', value: 'Gerola Alta' },
  { label: 'Gerolding', value: 'Gerolding' },
  { label: 'Geroldsgrün', value: 'Geroldsgrün' },
  { label: 'Geroldshausen', value: 'Geroldshausen' },
  { label: 'Geroldswil', value: 'Geroldswil' },
  { label: 'Gerolfingen', value: 'Gerolfingen' },
  { label: 'Gerolsbach', value: 'Gerolsbach' },
  { label: 'Gerolsheim', value: 'Gerolsheim' },
  { label: 'Gerolstein', value: 'Gerolstein' },
  { label: 'Gerolzhofen', value: 'Gerolzhofen' },
  { label: 'Geromina', value: 'Geromina' },
  { label: 'Gerona', value: 'Gerona' },
  { label: 'Gerona', value: 'Gerona' },
  { label: 'Geronimo', value: 'Geronimo' },
  { label: 'Geronimo', value: 'Geronimo' },
  { label: 'Gerosa', value: 'Gerosa' },
  { label: 'Gero-Shi', value: 'Gero-Shi' },
  { label: 'Geroskipou', value: 'Geroskipou' },
  { label: 'Geroskípou (Quarter)', value: 'Geroskípou (Quarter)' },
  { label: 'Geroskípou Municipality', value: 'Geroskípou Municipality' },
  { label: 'Gerouk', value: 'Gerouk' },
  { label: 'Geroyskoye', value: 'Geroyskoye' },
  { label: 'Gerpinnes', value: 'Gerpinnes' },
  { label: 'Gerrards Cross', value: 'Gerrards Cross' },
  { label: 'Gerre De Caprioli', value: 'Gerre De Caprioli' },
  { label: 'Gerringong', value: 'Gerringong' },
  { label: 'Gers', value: 'Gers' },
  { label: 'Gersau', value: 'Gersau' },
  { label: 'Gersdorf', value: 'Gersdorf' },
  { label: 'Gersdorf An Der Feistritz', value: 'Gersdorf An Der Feistritz' },
  { label: 'Gersfeld', value: 'Gersfeld' },
  { label: 'Gersheim', value: 'Gersheim' },
  { label: 'Gersten', value: 'Gersten' },
  { label: 'Gerstetten', value: 'Gerstetten' },
  { label: 'Gerstheim', value: 'Gerstheim' },
  { label: 'Gersthofen', value: 'Gersthofen' },
  { label: 'Gerstungen', value: 'Gerstungen' },
  { label: 'Gerswalde', value: 'Gerswalde' },
  { label: 'Gert Sibande District Municipality', value: 'Gert Sibande District Municipality' },
  { label: 'Gervais', value: 'Gervais' },
  { label: 'Gerwen', value: 'Gerwen' },
  { label: 'Gerwisch', value: 'Gerwisch' },
  { label: 'Gerzat', value: 'Gerzat' },
  { label: 'Gerze', value: 'Gerze' },
  { label: 'Gerzel’-Aul', value: 'Gerzel’-Aul' },
  { label: 'Gerzen', value: 'Gerzen' },
  { label: 'Gescher', value: 'Gescher' },
  { label: 'Geschwenda', value: 'Geschwenda' },
  { label: 'Gesees', value: 'Gesees' },
  { label: 'Geseke', value: 'Geseke' },
  { label: 'Gesico', value: 'Gesico' },
  { label: 'Geslau', value: 'Geslau' },
  { label: 'Gespunsart', value: 'Gespunsart' },
  { label: 'Gessate', value: 'Gessate' },
  { label: 'Gessertshausen', value: 'Gessertshausen' },
  { label: 'Gessopalena', value: 'Gessopalena' },
  { label: 'Gestalgar', value: 'Gestalgar' },
  { label: 'Gesté', value: 'Gesté' },
  { label: 'Gestel', value: 'Gestel' },
  { label: 'Gestratz', value: 'Gestratz' },
  { label: 'Gesturi', value: 'Gesturi' },
  { label: 'Gesualdo', value: 'Gesualdo' },
  { label: 'Gesundbrunnen', value: 'Gesundbrunnen' },
  { label: 'Gesves', value: 'Gesves' },
  { label: 'Gesztely', value: 'Gesztely' },
  { label: 'Geta', value: 'Geta' },
  { label: 'Getafe', value: 'Getafe' },
  { label: 'Getap’', value: 'Getap’' },
  { label: 'Getaria', value: 'Getaria' },
  { label: 'Getashen', value: 'Getashen' },
  { label: 'Getazat', value: 'Getazat' },
  { label: 'Gétigné', value: 'Gétigné' },
  { label: 'Getinge', value: 'Getinge' },
  { label: 'Getrine', value: 'Getrine' },
  { label: 'Getsemaní', value: 'Getsemaní' },
  { label: 'Gettorf', value: 'Gettorf' },
  { label: 'Gettysburg', value: 'Gettysburg' },
  { label: 'Gettysburg', value: 'Gettysburg' },
  { label: 'Getulina', value: 'Getulina' },
  { label: 'Getulio', value: 'Getulio' },
  { label: 'Getúlio Vargas', value: 'Getúlio Vargas' },
  { label: 'Getxo', value: 'Getxo' },
  { label: 'Geudertheim', value: 'Geudertheim' },
  { label: 'Geuensee', value: 'Geuensee' },
  { label: 'Geumcheon-Gu', value: 'Geumcheon-Gu' },
  { label: 'Geumjeong-Gu', value: 'Geumjeong-Gu' },
  { label: 'Geumsan-Gun', value: 'Geumsan-Gun' },
  { label: 'Geusa', value: 'Geusa' },
  { label: 'Geuzenveld', value: 'Geuzenveld' },
  { label: 'Gevaş', value: 'Gevaş' },
  { label: 'Gevelsberg', value: 'Gevelsberg' },
  { label: 'Gévezé', value: 'Gévezé' },
  { label: 'Gevgelija', value: 'Gevgelija' },
  { label: 'Gevrai', value: 'Gevrai' },
  { label: 'Gevrey-Chambertin', value: 'Gevrey-Chambertin' },
  { label: 'Gewanē', value: 'Gewanē' },
  { label: 'Gex', value: 'Gex' },
  { label: 'Geyer', value: 'Geyer' },
  { label: 'Geylang', value: 'Geylang' },
  { label: 'Geylegphug', value: 'Geylegphug' },
  { label: 'Geyve', value: 'Geyve' },
  { label: 'Gföhl', value: 'Gföhl' },
  { label: 'Ghabāghib', value: 'Ghabāghib' },
  { label: 'Ghadamgah', value: 'Ghadamgah' },
  { label: 'Ghadāmis', value: 'Ghadāmis' },
  { label: 'Ghadeer', value: 'Ghadeer' },
  { label: 'Ghafurov', value: 'Ghafurov' },
  { label: 'Ghahderijan', value: 'Ghahderijan' },
  { label: 'Għajnsielem', value: 'Għajnsielem' },
  { label: 'Ghale Tol', value: 'Ghale Tol' },
  { label: 'Ghalehganj', value: 'Ghalehganj' },
  { label: 'Ghamr', value: 'Ghamr' },
  { label: 'Ghamsar', value: 'Ghamsar' },
  { label: 'Ghanaur', value: 'Ghanaur' },
  { label: 'Ghansor', value: 'Ghansor' },
  { label: 'Ghanzi', value: 'Ghanzi' },
  { label: 'Gharaunda', value: 'Gharaunda' },
  { label: 'Gharavŭtí', value: 'Gharavŭtí' },
  { label: 'Għarb', value: 'Għarb' },
  { label: 'Ghardaïa', value: 'Ghardaïa' },
  { label: 'Ghare Bolagh', value: 'Ghare Bolagh' },
  { label: 'Ghargaon', value: 'Ghargaon' },
  { label: 'Gharghoda', value: 'Gharghoda' },
  { label: 'Gharo', value: 'Gharo' },
  { label: 'Gharqabad', value: 'Gharqabad' },
  { label: 'Gharyan', value: 'Gharyan' },
  { label: 'Ghat', value: 'Ghat' },
  { label: 'Ghatal', value: 'Ghatal' },
  { label: 'Ghatampur', value: 'Ghatampur' },
  { label: 'Ghatanji', value: 'Ghatanji' },
  { label: 'Ghatkesar', value: 'Ghatkesar' },
  { label: 'Ghatkopar', value: 'Ghatkopar' },
  { label: 'Ghatsila', value: 'Ghatsila' },
  { label: 'Ghauspur', value: 'Ghauspur' },
  { label: 'Ghawiyah', value: 'Ghawiyah' },
  { label: 'Ghayen', value: 'Ghayen' },
  { label: 'Ghayl Ba Wazir', value: 'Ghayl Ba Wazir' },
  { label: 'Ghayl Bin Yamin', value: 'Ghayl Bin Yamin' },
  { label: 'Ghazaliya', value: 'Ghazaliya' },
  { label: 'Ghaziabad', value: 'Ghaziabad' },
  { label: 'Ghazieh', value: 'Ghazieh' },
  { label: 'Ghazipur', value: 'Ghazipur' },
  { label: 'Ghazni', value: 'Ghazni' },
  { label: 'Ghazyatan', value: 'Ghazyatan' },
  { label: 'Gheaba', value: 'Gheaba' },
  { label: 'Gheboaia', value: 'Gheboaia' },
  { label: 'Gheboieni', value: 'Gheboieni' },
  { label: 'Ghedi', value: 'Ghedi' },
  { label: 'Gheja', value: 'Gheja' },
  { label: 'Ghelari', value: 'Ghelari' },
  { label: 'Ghelinţa', value: 'Ghelinţa' },
  { label: 'Ghemme', value: 'Ghemme' },
  { label: 'Gheorghe Doja', value: 'Gheorghe Doja' },
  { label: 'Gheorghe Doja', value: 'Gheorghe Doja' },
  { label: 'Gheorghe Doja', value: 'Gheorghe Doja' },
  { label: 'Gheorghe Lazăr', value: 'Gheorghe Lazăr' },
  { label: 'Gheorgheni', value: 'Gheorgheni' },
  { label: 'Gheorghieni', value: 'Gheorghieni' },
  { label: 'Gherăeşti', value: 'Gherăeşti' },
  { label: 'Gheraiat', value: 'Gheraiat' },
  { label: 'Gherăseni', value: 'Gherăseni' },
  { label: 'Ghergheasa', value: 'Ghergheasa' },
  { label: 'Ghergheşti', value: 'Ghergheşti' },
  { label: 'Gherghiţa', value: 'Gherghiţa' },
  { label: 'Gherla', value: 'Gherla' },
  { label: 'Ghermănești', value: 'Ghermănești' },
  { label: 'Ghermănești', value: 'Ghermănești' },
  { label: 'Gherța Mare', value: 'Gherța Mare' },
  { label: 'Gherţa Mică', value: 'Gherţa Mică' },
  { label: 'Gherteniș', value: 'Gherteniș' },
  { label: 'Ghiaie', value: 'Ghiaie' },
  { label: 'Ghiare-Madonna', value: 'Ghiare-Madonna' },
  { label: 'Ghidașteu', value: 'Ghidașteu' },
  { label: 'Ghidfalău', value: 'Ghidfalău' },
  { label: 'Ghidici', value: 'Ghidici' },
  { label: 'Ghidigeni', value: 'Ghidigeni' },
  { label: 'Ghiffa', value: 'Ghiffa' },
  { label: 'Ghighișeni', value: 'Ghighișeni' },
  { label: 'Ghijduwon', value: 'Ghijduwon' },
  { label: 'Ghilad', value: 'Ghilad' },
  { label: 'Ghilarza', value: 'Ghilarza' },
  { label: 'Ghimbav', value: 'Ghimbav' },
  { label: 'Ghimeș', value: 'Ghimeș' },
  { label: 'Ghimeş-Făget', value: 'Ghimeş-Făget' },
  { label: 'Ghimpați', value: 'Ghimpați' },
  { label: 'Ghimpați', value: 'Ghimpați' },
  { label: 'Ghimpețeni', value: 'Ghimpețeni' },
  { label: 'Ghindăoani', value: 'Ghindăoani' },
  { label: 'Ghindăreşti', value: 'Ghindăreşti' },
  { label: 'Ghindari', value: 'Ghindari' },
  { label: 'Ghindeni', value: 'Ghindeni' },
  { label: 'Ghindești', value: 'Ghindești' },
  { label: 'Ghinești', value: 'Ghinești' },
  { label: 'Ghinești', value: 'Ghinești' },
  { label: 'Ghioca', value: 'Ghioca' },
  { label: 'Ghiorac', value: 'Ghiorac' },
  { label: 'Ghioroc', value: 'Ghioroc' },
  { label: 'Ghioroiu', value: 'Ghioroiu' },
  { label: 'Ghioșești', value: 'Ghioșești' },
  { label: 'Ghirdoveni', value: 'Ghirdoveni' },
  { label: 'Ghiroda', value: 'Ghiroda' },
  { label: 'Ghirokarzin', value: 'Ghirokarzin' },
  { label: 'Ghiror', value: 'Ghiror' },
  { label: 'Ghisalba', value: 'Ghisalba' },
  { label: 'Ghislarengo', value: 'Ghislarengo' },
  { label: 'Ghisonaccia', value: 'Ghisonaccia' },
  { label: 'Ghizdăvești', value: 'Ghizdăvești' },
  { label: 'Ghizela', value: 'Ghizela' },
  { label: 'Gho Brahmanan De', value: 'Gho Brahmanan De' },
  { label: 'Ghodasar', value: 'Ghodasar' },
  { label: 'Ghoga', value: 'Ghoga' },
  { label: 'Ghogha', value: 'Ghogha' },
  { label: 'Ghohestan', value: 'Ghohestan' },
  { label: 'Gholam Veys', value: 'Gholam Veys' },
  { label: 'Gholson', value: 'Gholson' },
  { label: 'Ghorawal', value: 'Ghorawal' },
  { label: 'Ghōriyān', value: 'Ghōriyān' },
  { label: 'Ghormach', value: 'Ghormach' },
  { label: 'Ghosi', value: 'Ghosi' },
  { label: 'Ghoti Budrukh', value: 'Ghoti Budrukh' },
  { label: 'Ghotki', value: 'Ghotki' },
  { label: 'Ghouazi', value: 'Ghouazi' },
  { label: 'Ghran', value: 'Ghran' },
  { label: 'Ghugus', value: 'Ghugus' },
  { label: 'Ghukasavan', value: 'Ghukasavan' },
  { label: 'Ghumarwin', value: 'Ghumarwin' },
  { label: 'Ghunkarah', value: 'Ghunkarah' },
  { label: 'Ghurchi Bashi', value: 'Ghurchi Bashi' },
  { label: 'Ghyvelde', value: 'Ghyvelde' },
  { label: 'Giacalone', value: 'Giacalone' },
  { label: 'Giacciano Con Baruchella', value: 'Giacciano Con Baruchella' },
  { label: 'Giado', value: 'Giado' },
  { label: 'Giaginskaya', value: 'Giaginskaya' },
  { label: 'Giaginskiy Rayon', value: 'Giaginskiy Rayon' },
  { label: 'Giaglione', value: 'Giaglione' },
  { label: 'Gialo', value: 'Gialo' },
  { label: 'Giammoro', value: 'Giammoro' },
  { label: 'Gianico', value: 'Gianico' },
  { label: 'Giannitsá', value: 'Giannitsá' },
  { label: 'Giano Dellumbria', value: 'Giano Dellumbria' },
  { label: 'Giano Vetusto', value: 'Giano Vetusto' },
  { label: 'Giardina Gallotti', value: 'Giardina Gallotti' },
  { label: 'Giardinello', value: 'Giardinello' },
  { label: 'Giardini-Naxos', value: 'Giardini-Naxos' },
  { label: 'Giarmata', value: 'Giarmata' },
  { label: 'Giarmata-Vii', value: 'Giarmata-Vii' },
  { label: 'Giarole', value: 'Giarole' },
  { label: 'Giarratana', value: 'Giarratana' },
  { label: 'Giarre', value: 'Giarre' },
  { label: 'Giave', value: 'Giave' },
  { label: 'Giavenale', value: 'Giavenale' },
  { label: 'Giaveno', value: 'Giaveno' },
  { label: 'Giavera Del Montello', value: 'Giavera Del Montello' },
  { label: 'Giawang', value: 'Giawang' },
  { label: 'Giba', value: 'Giba' },
  { label: 'Gibara', value: 'Gibara' },
  { label: 'Gibato', value: 'Gibato' },
  { label: 'Gibbon', value: 'Gibbon' },
  { label: 'Gibbons', value: 'Gibbons' },
  { label: 'Gibbsboro', value: 'Gibbsboro' },
  { label: 'Gibbstown', value: 'Gibbstown' },
  { label: 'Giberville', value: 'Giberville' },
  { label: 'Gibgos', value: 'Gibgos' },
  { label: 'Gibong', value: 'Gibong' },
  { label: 'Gibraleón', value: 'Gibraleón' },
  { label: 'Gibraltar', value: 'Gibraltar' },
  { label: 'Gibraltar', value: 'Gibraltar' },
  { label: 'Gibson', value: 'Gibson' },
  { label: 'Gibson', value: 'Gibson' },
  { label: 'Gibson City', value: 'Gibson City' },
  { label: 'Gibson County', value: 'Gibson County' },
  { label: 'Gibson County', value: 'Gibson County' },
  { label: 'Gibsonburg', value: 'Gibsonburg' },
  { label: 'Gibsonia', value: 'Gibsonia' },
  { label: 'Gibsonia', value: 'Gibsonia' },
  { label: 'Gibsons', value: 'Gibsons' },
  { label: 'Gibsonton', value: 'Gibsonton' },
  { label: 'Gibsonville', value: 'Gibsonville' },
  { label: 'Gidam', value: 'Gidam' },
  { label: 'Giddalur', value: 'Giddalur' },
  { label: 'Giddarbaha', value: 'Giddarbaha' },
  { label: 'Giddings', value: 'Giddings' },
  { label: 'Giddy Hall', value: 'Giddy Hall' },
  { label: 'Gideon', value: 'Gideon' },
  { label: 'Gidgegannup', value: 'Gidgegannup' },
  { label: 'Gidle', value: 'Gidle' },
  { label: 'Gīdolē', value: 'Gīdolē' },
  { label: 'Gidrotorf', value: 'Gidrotorf' },
  { label: 'Gidy', value: 'Gidy' },
  { label: 'Giebelstadt', value: 'Giebelstadt' },
  { label: 'Gieboldehausen', value: 'Gieboldehausen' },
  { label: 'Giebułtów', value: 'Giebułtów' },
  { label: 'Giedlarowa', value: 'Giedlarowa' },
  { label: 'Giehun', value: 'Giehun' },
  { label: 'Giekau', value: 'Giekau' },
  { label: 'Gielniów', value: 'Gielniów' },
  { label: 'Gielow', value: 'Gielow' },
  { label: 'Gien', value: 'Gien' },
  { label: 'Giengen An Der Brenz', value: 'Giengen An Der Brenz' },
  { label: 'Giera', value: 'Giera' },
  { label: 'Gierałtowice', value: 'Gierałtowice' },
  { label: 'Gierałtowice', value: 'Gierałtowice' },
  { label: 'Gières', value: 'Gières' },
  { label: 'Gierłoż', value: 'Gierłoż' },
  { label: 'Giersleben', value: 'Giersleben' },
  { label: 'Gierzwałd', value: 'Gierzwałd' },
  { label: 'Giesbeek', value: 'Giesbeek' },
  { label: 'Giesen', value: 'Giesen' },
  { label: 'Gießen', value: 'Gießen' },
  { label: 'Giessen', value: 'Giessen' },
  { label: 'Giessenburg', value: 'Giessenburg' },
  { label: 'Giessendam', value: 'Giessendam' },
  { label: 'Gießhübl', value: 'Gießhübl' },
  { label: 'Gieten', value: 'Gieten' },
  { label: 'Giethoorn', value: 'Giethoorn' },
  { label: 'Gietrzwałd', value: 'Gietrzwałd' },
  { label: 'Gièvres', value: 'Gièvres' },
  { label: 'Giffers', value: 'Giffers' },
  { label: 'Gifflenga', value: 'Gifflenga' },
  { label: 'Giffnock', value: 'Giffnock' },
  { label: 'Giffone', value: 'Giffone' },
  { label: 'Giffoni Valle Piana', value: 'Giffoni Valle Piana' },
  { label: 'Gifford', value: 'Gifford' },
  { label: 'Gifford', value: 'Gifford' },
  { label: 'Gifhorn', value: 'Gifhorn' },
  { label: 'Gif-Sur-Yvette', value: 'Gif-Sur-Yvette' },
  { label: 'Gifu-Shi', value: 'Gifu-Shi' },
  { label: 'Gig Harbor', value: 'Gig Harbor' },
  { label: 'Gigant', value: 'Gigant' },
  { label: 'Gigante', value: 'Gigante' },
  { label: 'Gigaquit', value: 'Gigaquit' },
  { label: 'Gigaquit', value: 'Gigaquit' },
  { label: 'Gigean', value: 'Gigean' },
  { label: 'Gighera', value: 'Gighera' },
  { label: 'Giglio Castello', value: 'Giglio Castello' },
  { label: 'Gigmoto', value: 'Gigmoto' },
  { label: 'Gignac', value: 'Gignac' },
  { label: 'Gignac-La-Nerthe', value: 'Gignac-La-Nerthe' },
  { label: 'Gignese', value: 'Gignese' },
  { label: 'Gignod', value: 'Gignod' },
  { label: 'Gijang', value: 'Gijang' },
  { label: 'Gijang-Gun', value: 'Gijang-Gun' },
  { label: 'Gijón', value: 'Gijón' },
  { label: 'Gil García', value: 'Gil García' },
  { label: 'Gila Bend', value: 'Gila Bend' },
  { label: 'Gila County', value: 'Gila County' },
  { label: 'Gilan Gharb', value: 'Gilan Gharb' },
  { label: 'Gilău', value: 'Gilău' },
  { label: 'Gilberdyke', value: 'Gilberdyke' },
  { label: 'Gilbert', value: 'Gilbert' },
  { label: 'Gilbert', value: 'Gilbert' },
  { label: 'Gilbert', value: 'Gilbert' },
  { label: 'Gilbert Creek', value: 'Gilbert Creek' },
  { label: 'Gilberto Camacho', value: 'Gilberto Camacho' },
  { label: 'Gilberton', value: 'Gilberton' },
  { label: 'Gilberts', value: 'Gilberts' },
  { label: 'Gilbertsville', value: 'Gilbertsville' },
  { label: 'Gilbuena', value: 'Gilbuena' },
  { label: 'Gilbués', value: 'Gilbués' },
  { label: 'Gilching', value: 'Gilching' },
  { label: 'Gilchrist County', value: 'Gilchrist County' },
  { label: 'Gilcrest', value: 'Gilcrest' },
  { label: 'Gildardo Magaña (Los Ángeles)', value: 'Gildardo Magaña (Los Ángeles)' },
  { label: 'Gildeskål', value: 'Gildeskål' },
  { label: 'Gildone', value: 'Gildone' },
  { label: 'Gilena', value: 'Gilena' },
  { label: 'Giles County', value: 'Giles County' },
  { label: 'Giles County', value: 'Giles County' },
  { label: 'Gilet', value: 'Gilet' },
  { label: 'Gilette', value: 'Gilette' },
  { label: 'Gilfach Goch', value: 'Gilfach Goch' },
  { label: 'Gilford', value: 'Gilford' },
  { label: 'Gilford', value: 'Gilford' },
  { label: 'Gilgandra', value: 'Gilgandra' },
  { label: 'Gilgilçay', value: 'Gilgilçay' },
  { label: 'Gilgit', value: 'Gilgit' },
  { label: 'Gili Air', value: 'Gili Air' },
  { label: 'Gilita', value: 'Gilita' },
  { label: 'Gill', value: 'Gill' },
  { label: 'Gilleleje', value: 'Gilleleje' },
  { label: 'Gillen', value: 'Gillen' },
  { label: 'Gillenfeld', value: 'Gillenfeld' },
  { label: 'Gilles Plains', value: 'Gilles Plains' },
  { label: 'Gillespie', value: 'Gillespie' },
  { label: 'Gillespie County', value: 'Gillespie County' },
  { label: 'Gillett', value: 'Gillett' },
  { label: 'Gillette', value: 'Gillette' },
  { label: 'Gilley', value: 'Gilley' },
  { label: 'Gilliam County', value: 'Gilliam County' },
  { label: 'Gillieston Heights', value: 'Gillieston Heights' },
  { label: 'Gillingham', value: 'Gillingham' },
  { label: 'Gillonnay', value: 'Gillonnay' },
  { label: 'Gilly-Sur-Isère', value: 'Gilly-Sur-Isère' },
  { label: 'Gilman', value: 'Gilman' },
  { label: 'Gilman Municipality', value: 'Gilman Municipality' },
  { label: 'Gilmanton', value: 'Gilmanton' },
  { label: 'Gilmer', value: 'Gilmer' },
  { label: 'Gilmer County', value: 'Gilmer County' },
  { label: 'Gilmer County', value: 'Gilmer County' },
  { label: 'Gilmore', value: 'Gilmore' },
  { label: 'Gilowice', value: 'Gilowice' },
  { label: 'Gilpin County', value: 'Gilpin County' },
  { label: 'Gilroy', value: 'Gilroy' },
  { label: 'Gilserberg', value: 'Gilserberg' },
  { label: 'Gilston', value: 'Gilston' },
  { label: 'Gilten', value: 'Gilten' },
  { label: 'Gilwern', value: 'Gilwern' },
  { label: 'Gilze', value: 'Gilze' },
  { label: 'Gimampang', value: 'Gimampang' },
  { label: 'Gimbi', value: 'Gimbi' },
  { label: 'Gimbsheim', value: 'Gimbsheim' },
  { label: 'Gimcheon', value: 'Gimcheon' },
  { label: 'Gimcheon-Si', value: 'Gimcheon-Si' },
  { label: 'Gimel', value: 'Gimel' },
  { label: 'Gimenells I El Pla De La Font', value: 'Gimenells I El Pla De La Font' },
  { label: 'Gimhae-Si', value: 'Gimhae-Si' },
  { label: 'Gimialcón', value: 'Gimialcón' },
  { label: 'Gimigliano', value: 'Gimigliano' },
  { label: 'Gimileo', value: 'Gimileo' },
  { label: 'Gimje-Si', value: 'Gimje-Si' },
  { label: 'Gimli', value: 'Gimli' },
  { label: 'Gimme-Me-Bit', value: 'Gimme-Me-Bit' },
  { label: 'Gimo', value: 'Gimo' },
  { label: 'Gimont', value: 'Gimont' },
  { label: 'Gimpo-Si', value: 'Gimpo-Si' },
  { label: 'Gimry', value: 'Gimry' },
  { label: 'Gin Gin', value: 'Gin Gin' },
  { label: 'Ginabuyan', value: 'Ginabuyan' },
  { label: 'Ginabuyan', value: 'Ginabuyan' },
  { label: 'Ginasservis', value: 'Ginasservis' },
  { label: 'Ginatilan', value: 'Ginatilan' },
  { label: 'Ginebra', value: 'Ginebra' },
  { label: 'Ginebrosa La', value: 'Ginebrosa La' },
  { label: 'Gines', value: 'Gines' },
  { label: 'Gines-Patay', value: 'Gines-Patay' },
  { label: 'Ginestar', value: 'Ginestar' },
  { label: 'Ginestas', value: 'Ginestas' },
  { label: 'Ginestra', value: 'Ginestra' },
  { label: 'Ginestra Degli Schiavoni', value: 'Ginestra Degli Schiavoni' },
  { label: 'Ginestra Fiorentina', value: 'Ginestra Fiorentina' },
  { label: 'Gineta La', value: 'Gineta La' },
  { label: 'Gingee', value: 'Gingee' },
  { label: 'Gingelom', value: 'Gingelom' },
  { label: 'Gingen An Der Fils', value: 'Gingen An Der Fils' },
  { label: 'Ginger Hill', value: 'Ginger Hill' },
  { label: 'Ginger Ridge', value: 'Ginger Ridge' },
  { label: 'Gingin', value: 'Gingin' },
  { label: 'Gingst', value: 'Gingst' },
  { label: 'Ginir', value: 'Ginir' },
  { label: 'Ginneken', value: 'Ginneken' },
  { label: 'Ginosa', value: 'Ginosa' },
  { label: 'Ginowan Shi', value: 'Ginowan Shi' },
  { label: 'Ginsheim-Gustavsburg', value: 'Ginsheim-Gustavsburg' },
  { label: 'Gioi', value: 'Gioi' },
  { label: 'Gioia Dei Marsi', value: 'Gioia Dei Marsi' },
  { label: 'Gioia Del Colle', value: 'Gioia Del Colle' },
  { label: 'Gioia Sannitica', value: 'Gioia Sannitica' },
  { label: 'Gioia Tauro', value: 'Gioia Tauro' },
  { label: 'Gioiosa Ionica', value: 'Gioiosa Ionica' },
  { label: 'Gioiosa Marea', value: 'Gioiosa Marea' },
  { label: 'Gionghi-Cappella', value: 'Gionghi-Cappella' },
  { label: 'Giorgilorio', value: 'Giorgilorio' },
  { label: 'Gioseni', value: 'Gioseni' },
  { label: 'Giove', value: 'Giove' },
  { label: 'Giovenzano', value: 'Giovenzano' },
  { label: 'Giovinazzo', value: 'Giovinazzo' },
  { label: 'Giovi-Ponte Alla Chiassa', value: 'Giovi-Ponte Alla Chiassa' },
  { label: 'Giovo', value: 'Giovo' },
  { label: 'Gipf-Oberfrick', value: 'Gipf-Oberfrick' },
  { label: 'Giporlos', value: 'Giporlos' },
  { label: 'Giporlos', value: 'Giporlos' },
  { label: 'Gir Somnath', value: 'Gir Somnath' },
  { label: 'Girabolhos', value: 'Girabolhos' },
  { label: 'Giralang', value: 'Giralang' },
  { label: 'Giraldo', value: 'Giraldo' },
  { label: 'Giraltovce', value: 'Giraltovce' },
  { label: 'Girard', value: 'Girard' },
  { label: 'Girard', value: 'Girard' },
  { label: 'Girard', value: 'Girard' },
  { label: 'Girard', value: 'Girard' },
  { label: 'Girard', value: 'Girard' },
  { label: 'Girardi-Bellavista-Terrazze', value: 'Girardi-Bellavista-Terrazze' },
  { label: 'Girardot', value: 'Girardot' },
  { label: 'Girardota', value: 'Girardota' },
  { label: 'Girards Hill', value: 'Girards Hill' },
  { label: 'Girardville', value: 'Girardville' },
  { label: 'Girasole', value: 'Girasole' },
  { label: 'Girau Do Ponciano', value: 'Girau Do Ponciano' },
  { label: 'Giraud', value: 'Giraud' },
  { label: 'Giraumont', value: 'Giraumont' },
  { label: 'Girdwood', value: 'Girdwood' },
  { label: 'Girey', value: 'Girey' },
  { label: 'Girgaon', value: 'Girgaon' },
  { label: 'Giria', value: 'Giria' },
  { label: 'Giridih', value: 'Giridih' },
  { label: 'Girifalco', value: 'Girifalco' },
  { label: 'Girișu De Criș', value: 'Girișu De Criș' },
  { label: 'Giroc', value: 'Giroc' },
  { label: 'Girod', value: 'Girod' },
  { label: 'Giromagny', value: 'Giromagny' },
  { label: 'Girón', value: 'Girón' },
  { label: 'Girona', value: 'Girona' },
  { label: 'Gironde', value: 'Gironde' },
  { label: 'Gironde-Sur-Dropt', value: 'Gironde-Sur-Dropt' },
  { label: 'Gironella', value: 'Gironella' },
  { label: 'Gironico Al Piano', value: 'Gironico Al Piano' },
  { label: 'Giroussens', value: 'Giroussens' },
  { label: 'Girov', value: 'Girov' },
  { label: 'Girraween', value: 'Girraween' },
  { label: 'Girraween', value: 'Girraween' },
  { label: 'Girrawheen', value: 'Girrawheen' },
  { label: 'Girton', value: 'Girton' },
  { label: 'Giruá', value: 'Giruá' },
  { label: 'Girvan', value: 'Girvan' },
  { label: 'Girvas', value: 'Girvas' },
  { label: 'Gisborne', value: 'Gisborne' },
  { label: 'Gisborne', value: 'Gisborne' },
  { label: 'Gisclareny', value: 'Gisclareny' },
  { label: 'Gisenyi', value: 'Gisenyi' },
  { label: 'Giske', value: 'Giske' },
  { label: 'Gislaved', value: 'Gislaved' },
  { label: 'Gisors', value: 'Gisors' },
  { label: 'Gissi', value: 'Gissi' },
  { label: 'Gistaín', value: 'Gistaín' },
  { label: 'Gistel', value: 'Gistel' },
  { label: 'Gistrup', value: 'Gistrup' },
  { label: 'Giswil', value: 'Giswil' },
  { label: 'Gitagum', value: 'Gitagum' },
  { label: 'Gitarama', value: 'Gitarama' },
  { label: 'Gitega', value: 'Gitega' },
  { label: 'Gitschtal', value: 'Gitschtal' },
  { label: 'Gittelde', value: 'Gittelde' },
  { label: 'Giubega', value: 'Giubega' },
  { label: 'Giubiasco', value: 'Giubiasco' },
  { label: 'Giudecca', value: 'Giudecca' },
  { label: 'Giuggianello', value: 'Giuggianello' },
  { label: 'Giugliano In Campania', value: 'Giugliano In Campania' },
  { label: 'Giuleşti', value: 'Giuleşti' },
  { label: 'Giuliana', value: 'Giuliana' },
  { label: 'Giulianello', value: 'Giulianello' },
  { label: 'Giuliano Di Roma', value: 'Giuliano Di Roma' },
  { label: 'Giuliano Teatino', value: 'Giuliano Teatino' },
  { label: 'Giulianova', value: 'Giulianova' },
  { label: 'Giuluș', value: 'Giuluș' },
  { label: 'Giulvăz', value: 'Giulvăz' },
  { label: 'Giuncugnano', value: 'Giuncugnano' },
  { label: 'Giungano', value: 'Giungano' },
  { label: 'Giurdignano', value: 'Giurdignano' },
  { label: 'Giurgeni', value: 'Giurgeni' },
  { label: 'Giurgiș', value: 'Giurgiș' },
  { label: 'Giurgiţa', value: 'Giurgiţa' },
  { label: 'Giurgiu', value: 'Giurgiu' },
  { label: 'Giurgiuleşti', value: 'Giurgiuleşti' },
  { label: 'Giurtelecu Șimleului', value: 'Giurtelecu Șimleului' },
  { label: 'Giussago', value: 'Giussago' },
  { label: 'Giussano', value: 'Giussano' },
  { label: 'Giustenice', value: 'Giustenice' },
  { label: 'Giustino', value: 'Giustino' },
  { label: 'Giusvalla', value: 'Giusvalla' },
  { label: 'Giuvărăşti', value: 'Giuvărăşti' },
  { label: 'Givaki', value: 'Givaki' },
  { label: 'Givat Shmuel', value: 'Givat Shmuel' },
  { label: 'Givatayim', value: 'Givatayim' },
  { label: 'Give', value: 'Give' },
  { label: 'Givenchy-En-Gohelle', value: 'Givenchy-En-Gohelle' },
  { label: 'Givet', value: 'Givet' },
  { label: 'Givisiez', value: 'Givisiez' },
  { label: 'Givoletto', value: 'Givoletto' },
  { label: 'Givors', value: 'Givors' },
  { label: 'Givrand', value: 'Givrand' },
  { label: 'Givry', value: 'Givry' },
  { label: 'Giyan', value: 'Giyan' },
  { label: 'Giyani', value: 'Giyani' },
  { label: 'Giza', value: 'Giza' },
  { label: 'Gizaburuaga', value: 'Gizaburuaga' },
  { label: 'Gizałki', value: 'Gizałki' },
  { label: 'Gizel’', value: 'Gizel’' },
  { label: 'Gizo', value: 'Gizo' },
  { label: 'Giżycko', value: 'Giżycko' },
  { label: 'Gizzeria', value: 'Gizzeria' },
  { label: 'Gjellerup', value: 'Gjellerup' },
  { label: 'Gjemnes', value: 'Gjemnes' },
  { label: 'Gjerdrum', value: 'Gjerdrum' },
  { label: 'Gjesdal', value: 'Gjesdal' },
  { label: 'Gjinkar', value: 'Gjinkar' },
  { label: 'Gjirokastër', value: 'Gjirokastër' },
  { label: 'Gjoa Haven', value: 'Gjoa Haven' },
  { label: 'Gjorče Petro', value: 'Gjorče Petro' },
  { label: 'Gjøvik', value: 'Gjøvik' },
  { label: 'Glabbeek', value: 'Glabbeek' },
  { label: 'Glace Bay', value: 'Glace Bay' },
  { label: 'Glacier County', value: 'Glacier County' },
  { label: 'Glad', value: 'Glad' },
  { label: 'Glad', value: 'Glad' },
  { label: 'Gladbeck', value: 'Gladbeck' },
  { label: 'Glade Spring', value: 'Glade Spring' },
  { label: 'Gladenbach', value: 'Gladenbach' },
  { label: 'Glades County', value: 'Glades County' },
  { label: 'Gladesville', value: 'Gladesville' },
  { label: 'Gladeview', value: 'Gladeview' },
  { label: 'Gladewater', value: 'Gladewater' },
  { label: 'Gladsaxe Municipality', value: 'Gladsaxe Municipality' },
  { label: 'Gladstad', value: 'Gladstad' },
  { label: 'Gladstone', value: 'Gladstone' },
  { label: 'Gladstone', value: 'Gladstone' },
  { label: 'Gladstone', value: 'Gladstone' },
  { label: 'Gladstone', value: 'Gladstone' },
  { label: 'Gladstone', value: 'Gladstone' },
  { label: 'Gladstone Central', value: 'Gladstone Central' },
  { label: 'Gladstone Park', value: 'Gladstone Park' },
  { label: 'Gladwin', value: 'Gladwin' },
  { label: 'Gladwin County', value: 'Gladwin County' },
  { label: 'Gladzor', value: 'Gladzor' },
  { label: 'Glafirovka', value: 'Glafirovka' },
  { label: 'Glăjărie', value: 'Glăjărie' },
  { label: 'Glamang', value: 'Glamang' },
  { label: 'Glamang', value: 'Glamang' },
  { label: 'Glamoč', value: 'Glamoč' },
  { label: 'Glamorgan/Spring Bay', value: 'Glamorgan/Spring Bay' },
  { label: 'Glamsbjerg', value: 'Glamsbjerg' },
  { label: 'Glan', value: 'Glan' },
  { label: 'Glan', value: 'Glan' },
  { label: 'Glan Peidu', value: 'Glan Peidu' },
  { label: 'Glan Peidu', value: 'Glan Peidu' },
  { label: 'Glanamman', value: 'Glanamman' },
  { label: 'Gland', value: 'Gland' },
  { label: 'Glandore', value: 'Glandore' },
  { label: 'Glandorf', value: 'Glandorf' },
  { label: 'Glandorf', value: 'Glandorf' },
  { label: 'Glandwr', value: 'Glandwr' },
  { label: 'Glâne District', value: 'Glâne District' },
  { label: 'Glanegg', value: 'Glanegg' },
  { label: 'Glanegg', value: 'Glanegg' },
  { label: 'Glan-Münchweiler', value: 'Glan-Münchweiler' },
  { label: 'Glapwell', value: 'Glapwell' },
  { label: 'Glarus', value: 'Glarus' },
  { label: 'Glasco', value: 'Glasco' },
  { label: 'Glascock County', value: 'Glascock County' },
  { label: 'Glasenbach', value: 'Glasenbach' },
  { label: 'Glasford', value: 'Glasford' },
  { label: 'Glasgow', value: 'Glasgow' },
  { label: 'Glasgow', value: 'Glasgow' },
  { label: 'Glasgow', value: 'Glasgow' },
  { label: 'Glasgow', value: 'Glasgow' },
  { label: 'Glasgow', value: 'Glasgow' },
  { label: 'Glasgow', value: 'Glasgow' },
  { label: 'Glasgow City', value: 'Glasgow City' },
  { label: 'Glasgow Village', value: 'Glasgow Village' },
  { label: 'Glashütte', value: 'Glashütte' },
  { label: 'Glashütten', value: 'Glashütten' },
  { label: 'Glashütten', value: 'Glashütten' },
  { label: 'Glasnevin', value: 'Glasnevin' },
  { label: 'Glass House Mountains', value: 'Glass House Mountains' },
  { label: 'Glassboro', value: 'Glassboro' },
  { label: 'Glasscock County', value: 'Glasscock County' },
  { label: 'Glassmanor', value: 'Glassmanor' },
  { label: 'Glassport', value: 'Glassport' },
  { label: 'Glastonbury', value: 'Glastonbury' },
  { label: 'Glastonbury', value: 'Glastonbury' },
  { label: 'Glastonbury Center', value: 'Glastonbury Center' },
  { label: 'Glattbach', value: 'Glattbach' },
  { label: 'Glattbrugg', value: 'Glattbrugg' },
  { label: 'Glattbrugg / Rohr/Platten-Balsberg', value: 'Glattbrugg / Rohr/Platten-Balsberg' },
  { label: 'Glattbrugg / Wydacker/Bettacker/Lättenwiesen', value: 'Glattbrugg / Wydacker/Bettacker/Lättenwiesen' },
  { label: 'Glatten', value: 'Glatten' },
  { label: 'Glattfelden', value: 'Glattfelden' },
  { label: 'Glaubitz', value: 'Glaubitz' },
  { label: 'Glauburg', value: 'Glauburg' },
  { label: 'Glauchau', value: 'Glauchau' },
  { label: 'Glaucilândia', value: 'Glaucilândia' },
  { label: 'Glăvăneşti', value: 'Glăvăneşti' },
  { label: 'Glăvile', value: 'Glăvile' },
  { label: 'Glavinitsa', value: 'Glavinitsa' },
  { label: 'Glazebury', value: 'Glazebury' },
  { label: 'Glazov', value: 'Glazov' },
  { label: 'Glazovka', value: 'Glazovka' },
  { label: 'Glazunovka', value: 'Glazunovka' },
  { label: 'Gleason', value: 'Gleason' },
  { label: 'Glebe', value: 'Glebe' },
  { label: 'Glebychevo', value: 'Glebychevo' },
  { label: 'Gleed', value: 'Gleed' },
  { label: 'Gleichamberg', value: 'Gleichamberg' },
  { label: 'Gleinstätten', value: 'Gleinstätten' },
  { label: 'Gleisdorf', value: 'Gleisdorf' },
  { label: 'Gleizé', value: 'Gleizé' },
  { label: 'Glemsford', value: 'Glemsford' },
  { label: 'Glen Allen', value: 'Glen Allen' },
  { label: 'Glen Alpine', value: 'Glen Alpine' },
  { label: 'Glen Alpine', value: 'Glen Alpine' },
  { label: 'Glen Avon', value: 'Glen Avon' },
  { label: 'Glen Burnie', value: 'Glen Burnie' },
  { label: 'Glen Carbon', value: 'Glen Carbon' },
  { label: 'Glen Cove', value: 'Glen Cove' },
  { label: 'Glen Eden', value: 'Glen Eden' },
  { label: 'Glen Eira', value: 'Glen Eira' },
  { label: 'Glen Ellyn', value: 'Glen Ellyn' },
  { label: 'Glen Forrest', value: 'Glen Forrest' },
  { label: 'Glen Gardner', value: 'Glen Gardner' },
  { label: 'Glen Head', value: 'Glen Head' },
  { label: 'Glen Huntly', value: 'Glen Huntly' },
  { label: 'Glen Innes', value: 'Glen Innes' },
  { label: 'Glen Innes Severn', value: 'Glen Innes Severn' },
  { label: 'Glen Iris', value: 'Glen Iris' },
  { label: 'Glen Iris', value: 'Glen Iris' },
  { label: 'Glen Lyon', value: 'Glen Lyon' },
  { label: 'Glen Oaks', value: 'Glen Oaks' },
  { label: 'Glen Osmond', value: 'Glen Osmond' },
  { label: 'Glen Park', value: 'Glen Park' },
  { label: 'Glen Raven', value: 'Glen Raven' },
  { label: 'Glen Ridge', value: 'Glen Ridge' },
  { label: 'Glen Rock', value: 'Glen Rock' },
  { label: 'Glen Rock', value: 'Glen Rock' },
  { label: 'Glen Rose', value: 'Glen Rose' },
  { label: 'Glen Waverley', value: 'Glen Waverley' },
  { label: 'Glenalta', value: 'Glenalta' },
  { label: 'Glenarden', value: 'Glenarden' },
  { label: 'Glenariff', value: 'Glenariff' },
  { label: 'Glenavy', value: 'Glenavy' },
  { label: 'Glenboig', value: 'Glenboig' },
  { label: 'Glenbrook', value: 'Glenbrook' },
  { label: 'Glencoe', value: 'Glencoe' },
  { label: 'Glencoe', value: 'Glencoe' },
  { label: 'Glencoe', value: 'Glencoe' },
  { label: 'Glencoe', value: 'Glencoe' },
  { label: 'Glencoe', value: 'Glencoe' },
  { label: 'Glencoe', value: 'Glencoe' },
  { label: 'Glendale', value: 'Glendale' },
  { label: 'Glendale', value: 'Glendale' },
  { label: 'Glendale', value: 'Glendale' },
  { label: 'Glendale', value: 'Glendale' },
  { label: 'Glendale', value: 'Glendale' },
  { label: 'Glendale', value: 'Glendale' },
  { label: 'Glendale', value: 'Glendale' },
  { label: 'Glendale', value: 'Glendale' },
  { label: 'Glendale', value: 'Glendale' },
  { label: 'Glendale', value: 'Glendale' },
  { label: 'Glendale', value: 'Glendale' },
  { label: 'Glendale Heights', value: 'Glendale Heights' },
  { label: 'Glendalough', value: 'Glendalough' },
  { label: 'Glendenning', value: 'Glendenning' },
  { label: 'Glendevon', value: 'Glendevon' },
  { label: 'Glendive', value: 'Glendive' },
  { label: 'Glendora', value: 'Glendora' },
  { label: 'Glendora', value: 'Glendora' },
  { label: 'Gleneagle', value: 'Gleneagle' },
  { label: 'Gleneagle', value: 'Gleneagle' },
  { label: 'Glenelg', value: 'Glenelg' },
  { label: 'Glenelg', value: 'Glenelg' },
  { label: 'Glenelg East', value: 'Glenelg East' },
  { label: 'Glenelg North', value: 'Glenelg North' },
  { label: 'Glenelg South', value: 'Glenelg South' },
  { label: 'Glenella', value: 'Glenella' },
  { label: 'Glenferrie', value: 'Glenferrie' },
  { label: 'Glenfield', value: 'Glenfield' },
  { label: 'Glenfield', value: 'Glenfield' },
  { label: 'Glenfield Park', value: 'Glenfield Park' },
  { label: 'Glengarry', value: 'Glengarry' },
  { label: 'Glengoffe', value: 'Glengoffe' },
  { label: 'Glengowrie', value: 'Glengowrie' },
  { label: 'Glenhaven', value: 'Glenhaven' },
  { label: 'Glenmavis', value: 'Glenmavis' },
  { label: 'Glenmont', value: 'Glenmont' },
  { label: 'Glenmoor', value: 'Glenmoor' },
  { label: 'Glenmora', value: 'Glenmora' },
  { label: 'Glenmore', value: 'Glenmore' },
  { label: 'Glenmore Park', value: 'Glenmore Park' },
  { label: 'Glenmuir', value: 'Glenmuir' },
  { label: 'Glenn County', value: 'Glenn County' },
  { label: 'Glenn Dale', value: 'Glenn Dale' },
  { label: 'Glenn Heights', value: 'Glenn Heights' },
  { label: 'Glenning Valley', value: 'Glenning Valley' },
  { label: 'Glenns Ferry', value: 'Glenns Ferry' },
  { label: 'Glennville', value: 'Glennville' },
  { label: 'Gleno', value: 'Gleno' },
  { label: 'Glenolden', value: 'Glenolden' },
  { label: 'Glenorchy', value: 'Glenorchy' },
  { label: 'Glenorie', value: 'Glenorie' },
  { label: 'Glenpool', value: 'Glenpool' },
  { label: 'Glenrock', value: 'Glenrock' },
  { label: 'Glenrothes', value: 'Glenrothes' },
  { label: 'Glenroy', value: 'Glenroy' },
  { label: 'Glenroy', value: 'Glenroy' },
  { label: 'Glens Falls', value: 'Glens Falls' },
  { label: 'Glens Falls North', value: 'Glens Falls North' },
  { label: 'Glenshaw', value: 'Glenshaw' },
  { label: 'Glenside', value: 'Glenside' },
  { label: 'Glenside', value: 'Glenside' },
  { label: 'Glenunga', value: 'Glenunga' },
  { label: 'Glenvale', value: 'Glenvale' },
  { label: 'Glenvar Heights', value: 'Glenvar Heights' },
  { label: 'Glenview', value: 'Glenview' },
  { label: 'Glenview', value: 'Glenview' },
  { label: 'Glenville', value: 'Glenville' },
  { label: 'Glenville', value: 'Glenville' },
  { label: 'Glenville', value: 'Glenville' },
  { label: 'Glenwood', value: 'Glenwood' },
  { label: 'Glenwood', value: 'Glenwood' },
  { label: 'Glenwood', value: 'Glenwood' },
  { label: 'Glenwood', value: 'Glenwood' },
  { label: 'Glenwood', value: 'Glenwood' },
  { label: 'Glenwood City', value: 'Glenwood City' },
  { label: 'Glenwood Landing', value: 'Glenwood Landing' },
  { label: 'Glenwood Springs', value: 'Glenwood Springs' },
  { label: 'Gliaca', value: 'Gliaca' },
  { label: 'Glicério', value: 'Glicério' },
  { label: 'Glidden', value: 'Glidden' },
  { label: 'Glide', value: 'Glide' },
  { label: 'Glienicke', value: 'Glienicke' },
  { label: 'Glimåkra', value: 'Glimåkra' },
  { label: 'Glimboca', value: 'Glimboca' },
  { label: 'Glimmen', value: 'Glimmen' },
  { label: 'Glina', value: 'Glina' },
  { label: 'Glina', value: 'Glina' },
  { label: 'Glinde', value: 'Glinde' },
  { label: 'Glindenberg', value: 'Glindenberg' },
  { label: 'Glinishchevo', value: 'Glinishchevo' },
  { label: 'Glinivtsi', value: 'Glinivtsi' },
  { label: 'Glinka', value: 'Glinka' },
  { label: 'Glinojeck', value: 'Glinojeck' },
  { label: 'Glinton', value: 'Glinton' },
  { label: 'Glinzendorf', value: 'Glinzendorf' },
  { label: 'Gliwice', value: 'Gliwice' },
  { label: 'Globasnitz', value: 'Globasnitz' },
  { label: 'Globe', value: 'Globe' },
  { label: 'Glod', value: 'Glod' },
  { label: 'Glod', value: 'Glod' },
  { label: 'Glodeanu-Sărat', value: 'Glodeanu-Sărat' },
  { label: 'Glodeanu-Siliştea', value: 'Glodeanu-Siliştea' },
  { label: 'Glodeni', value: 'Glodeni' },
  { label: 'Glodeni', value: 'Glodeni' },
  { label: 'Glodeni', value: 'Glodeni' },
  { label: 'Glodenii Gândului', value: 'Glodenii Gândului' },
  { label: 'Glödnitz', value: 'Glödnitz' },
  { label: 'Gloggnitz', value: 'Gloggnitz' },
  { label: 'Głogoczów', value: 'Głogoczów' },
  { label: 'Glogova', value: 'Glogova' },
  { label: 'Głogów', value: 'Głogów' },
  { label: 'Głogów Małopolski', value: 'Głogów Małopolski' },
  { label: 'Głogówek', value: 'Głogówek' },
  { label: 'Glojach', value: 'Glojach' },
  { label: 'Glomel', value: 'Glomel' },
  { label: 'Glomfjord', value: 'Glomfjord' },
  { label: 'Glommen', value: 'Glommen' },
  { label: 'Glonn', value: 'Glonn' },
  { label: 'Gloppen', value: 'Gloppen' },
  { label: 'Glorenza', value: 'Glorenza' },
  { label: 'Gloria', value: 'Gloria' },
  { label: 'Glória', value: 'Glória' },
  { label: 'Glória De Dourados', value: 'Glória De Dourados' },
  { label: 'Glória Do Goitá', value: 'Glória Do Goitá' },
  { label: 'Glória Doeste', value: 'Glória Doeste' },
  { label: 'Glorie', value: 'Glorie' },
  { label: 'Glorinha', value: 'Glorinha' },
  { label: 'Glossodia', value: 'Glossodia' },
  { label: 'Glossop', value: 'Glossop' },
  { label: 'Glostrup', value: 'Glostrup' },
  { label: 'Glostrup Kommune', value: 'Glostrup Kommune' },
  { label: 'Glöthe', value: 'Glöthe' },
  { label: 'Glotovka', value: 'Glotovka' },
  { label: 'Glött', value: 'Glött' },
  { label: 'Gloucester', value: 'Gloucester' },
  { label: 'Gloucester', value: 'Gloucester' },
  { label: 'Gloucester', value: 'Gloucester' },
  { label: 'Gloucester City', value: 'Gloucester City' },
  { label: 'Gloucester County', value: 'Gloucester County' },
  { label: 'Gloucester County', value: 'Gloucester County' },
  { label: 'Gloucester Courthouse', value: 'Gloucester Courthouse' },
  { label: 'Gloucester Point', value: 'Gloucester Point' },
  { label: 'Gloucestershire', value: 'Gloucestershire' },
  { label: 'Glouster', value: 'Glouster' },
  { label: 'Glovelier', value: 'Glovelier' },
  { label: 'Gloversville', value: 'Gloversville' },
  { label: 'Gloverville', value: 'Gloverville' },
  { label: 'Główczyce', value: 'Główczyce' },
  { label: 'Glowe', value: 'Glowe' },
  { label: 'Głowno', value: 'Głowno' },
  { label: 'Gložan', value: 'Gložan' },
  { label: 'Głubczyce', value: 'Głubczyce' },
  { label: 'Glubokiy', value: 'Glubokiy' },
  { label: 'Glubokiy', value: 'Glubokiy' },
  { label: 'Glubokovsky', value: 'Glubokovsky' },
  { label: 'Glubokoye', value: 'Glubokoye' },
  { label: 'Głuchołazy', value: 'Głuchołazy' },
  { label: 'Głuchów', value: 'Głuchów' },
  { label: 'Głuchów', value: 'Głuchów' },
  { label: 'Glücksburg', value: 'Glücksburg' },
  { label: 'Glückstadt', value: 'Glückstadt' },
  { label: 'Glumslöv', value: 'Glumslöv' },
  { label: 'Glusburn', value: 'Glusburn' },
  { label: 'Glušci', value: 'Glušci' },
  { label: 'Glushkovo', value: 'Glushkovo' },
  { label: 'Głuszyca', value: 'Głuszyca' },
  { label: 'Głuszyca Górna', value: 'Głuszyca Górna' },
  { label: 'Glyadyanskoye', value: 'Glyadyanskoye' },
  { label: 'Glyfáda', value: 'Glyfáda' },
  { label: 'Glyncorrwg', value: 'Glyncorrwg' },
  { label: 'Glynde', value: 'Glynde' },
  { label: 'Glyndon', value: 'Glyndon' },
  { label: 'Glynn County', value: 'Glynn County' },
  { label: 'Glyn-Neath', value: 'Glyn-Neath' },
  { label: 'Gmbet’', value: 'Gmbet’' },
  { label: 'Gmünd', value: 'Gmünd' },
  { label: 'Gmünd', value: 'Gmünd' },
  { label: 'Gmund Am Tegernsee', value: 'Gmund Am Tegernsee' },
  { label: 'Gmünd In Kärnten', value: 'Gmünd In Kärnten' },
  { label: 'Gmunden', value: 'Gmunden' },
  { label: 'Gnadendorf', value: 'Gnadendorf' },
  { label: 'Gnadenhutten', value: 'Gnadenhutten' },
  { label: 'Gnadenwald', value: 'Gnadenwald' },
  { label: 'Gnagna Province', value: 'Gnagna Province' },
  { label: 'Gnangara', value: 'Gnangara' },
  { label: 'Gnarp', value: 'Gnarp' },
  { label: 'Gnarrenburg', value: 'Gnarrenburg' },
  { label: 'Gnas', value: 'Gnas' },
  { label: 'Gneixendorf', value: 'Gneixendorf' },
  { label: 'Gnesau', value: 'Gnesau' },
  { label: 'Gnesta', value: 'Gnesta' },
  { label: 'Gniazdów', value: 'Gniazdów' },
  { label: 'Gniebing', value: 'Gniebing' },
  { label: 'Gniechowice', value: 'Gniechowice' },
  { label: 'Gniew', value: 'Gniew' },
  { label: 'Gniewino', value: 'Gniewino' },
  { label: 'Gniewkowo', value: 'Gniewkowo' },
  { label: 'Gnieżdżewo', value: 'Gnieżdżewo' },
  { label: 'Gniezno', value: 'Gniezno' },
  { label: 'Gnoien', value: 'Gnoien' },
  { label: 'Gnojnica', value: 'Gnojnica' },
  { label: 'Gnojnik', value: 'Gnojnik' },
  { label: 'Gnojno', value: 'Gnojno' },
  { label: 'Gnosall', value: 'Gnosall' },
  { label: 'Gnosjö', value: 'Gnosjö' },
  { label: 'Gnowangerup', value: 'Gnowangerup' },
  { label: 'Gnutz', value: 'Gnutz' },
  { label: 'Goa', value: 'Goa' },
  { label: 'Goa Velha', value: 'Goa Velha' },
  { label: 'Goalpara', value: 'Goalpara' },
  { label: 'Goascorán', value: 'Goascorán' },
  { label: 'Goba', value: 'Goba' },
  { label: 'Gobabis', value: 'Gobabis' },
  { label: 'Gobardanga', value: 'Gobardanga' },
  { label: 'Gobernador', value: 'Gobernador' },
  { label: 'Gobernador Costa', value: 'Gobernador Costa' },
  { label: 'Gobernador Cruz', value: 'Gobernador Cruz' },
  { label: 'Gobernador Gálvez', value: 'Gobernador Gálvez' },
  { label: 'Gobernador Gregores', value: 'Gobernador Gregores' },
  { label: 'Gobernador Juan E. Martínez', value: 'Gobernador Juan E. Martínez' },
  { label: 'Gobernador Mansilla', value: 'Gobernador Mansilla' },
  { label: 'Gobernador Roca', value: 'Gobernador Roca' },
  { label: 'Gobernador Virasora', value: 'Gobernador Virasora' },
  { label: 'Gobernadores', value: 'Gobernadores' },
  { label: 'Gobichettipalayam', value: 'Gobichettipalayam' },
  { label: 'Gobindapur', value: 'Gobindapur' },
  { label: 'Gobindpur', value: 'Gobindpur' },
  { label: 'Gobō', value: 'Gobō' },
  { label: 'Gobojango', value: 'Gobojango' },
  { label: 'Gobowen', value: 'Gobowen' },
  { label: 'Goch', value: 'Goch' },
  { label: 'Gochang-Gun', value: 'Gochang-Gun' },
  { label: 'Gochsheim', value: 'Gochsheim' },
  { label: 'Gockhausen', value: 'Gockhausen' },
  { label: 'Gocoton', value: 'Gocoton' },
  { label: 'Gocoton', value: 'Gocoton' },
  { label: 'Goczałkowice Zdrój', value: 'Goczałkowice Zdrój' },
  { label: 'Göd', value: 'Göd' },
  { label: 'Göda', value: 'Göda' },
  { label: 'Godall', value: 'Godall' },
  { label: 'Godalming', value: 'Godalming' },
  { label: 'Godda', value: 'Godda' },
  { label: 'Goddard', value: 'Goddard' },
  { label: 'Goddard', value: 'Goddard' },
  { label: 'Gode Zone', value: 'Gode Zone' },
  { label: 'Godeanu', value: 'Godeanu' },
  { label: 'Godech', value: 'Godech' },
  { label: 'Godefroy', value: 'Godefroy' },
  { label: 'Godega', value: 'Godega' },
  { label: 'Godella', value: 'Godella' },
  { label: 'Godelleta', value: 'Godelleta' },
  { label: 'Godeni', value: 'Godeni' },
  { label: 'Goderich', value: 'Goderich' },
  { label: 'Goderville', value: 'Goderville' },
  { label: 'Godewaersvelde', value: 'Godewaersvelde' },
  { label: 'Godfrey', value: 'Godfrey' },
  { label: 'Godhra', value: 'Godhra' },
  { label: 'Godiasco', value: 'Godiasco' },
  { label: 'Godikha', value: 'Godikha' },
  { label: 'Godim', value: 'Godim' },
  { label: 'Godineşti', value: 'Godineşti' },
  { label: 'Godley', value: 'Godley' },
  { label: 'Godmanchester', value: 'Godmanchester' },
  { label: 'Godo', value: 'Godo' },
  { label: 'Gōdo', value: 'Gōdo' },
  { label: 'Godofredo Viana', value: 'Godofredo Viana' },
  { label: 'Godojos', value: 'Godojos' },
  { label: 'Gödöllő', value: 'Gödöllő' },
  { label: 'Gödöllői Járás', value: 'Gödöllői Járás' },
  { label: 'Godów', value: 'Godów' },
  { label: 'Godoy Cruz', value: 'Godoy Cruz' },
  { label: 'Godoy Moreira', value: 'Godoy Moreira' },
  { label: 'Godrano', value: 'Godrano' },
  { label: 'Godshill', value: 'Godshill' },
  { label: 'Godstone', value: 'Godstone' },
  { label: 'Godzianów', value: 'Godzianów' },
  { label: 'Godziszka', value: 'Godziszka' },
  { label: 'Godziszów', value: 'Godziszów' },
  { label: 'Godziszów Pierwszy', value: 'Godziszów Pierwszy' },
  { label: 'Goedereede', value: 'Goedereede' },
  { label: 'Goel', value: 'Goel' },
  { label: 'Goergeville', value: 'Goergeville' },
  { label: 'Gœrsdorf', value: 'Gœrsdorf' },
  { label: 'Goes', value: 'Goes' },
  { label: 'Goes', value: 'Goes' },
  { label: 'Goesan-Gun', value: 'Goesan-Gun' },
  { label: 'Goesdorf', value: 'Goesdorf' },
  { label: 'Goești', value: 'Goești' },
  { label: 'Goetzenbruck', value: 'Goetzenbruck' },
  { label: 'Gœulzin', value: 'Gœulzin' },
  { label: 'Goffstown', value: 'Goffstown' },
  { label: 'Göfis', value: 'Göfis' },
  { label: 'Gofitskoye', value: 'Gofitskoye' },
  { label: 'Gogan', value: 'Gogan' },
  { label: 'Gogan', value: 'Gogan' },
  { label: 'Gogapur', value: 'Gogapur' },
  { label: 'Gogaran', value: 'Gogaran' },
  { label: 'Gogebic County', value: 'Gogebic County' },
  { label: 'Göggingen', value: 'Göggingen' },
  { label: 'Goght’', value: 'Goght’' },
  { label: 'Gogolin', value: 'Gogolin' },
  { label: 'Gogoşari', value: 'Gogoşari' },
  { label: 'Gogoşu', value: 'Gogoşu' },
  { label: 'Gogoşu', value: 'Gogoşu' },
  { label: 'Gôh', value: 'Gôh' },
  { label: 'Gohadi', value: 'Gohadi' },
  { label: 'Gohana', value: 'Gohana' },
  { label: 'Gohand', value: 'Gohand' },
  { label: 'Goheung-Gun', value: 'Goheung-Gun' },
  { label: 'Gohor', value: 'Gohor' },
  { label: 'Gohpur', value: 'Gohpur' },
  { label: 'Goiabeira', value: 'Goiabeira' },
  { label: 'Goiana', value: 'Goiana' },
  { label: 'Goianá', value: 'Goianá' },
  { label: 'Goianápolis', value: 'Goianápolis' },
  { label: 'Goiandira', value: 'Goiandira' },
  { label: 'Goianésia', value: 'Goianésia' },
  { label: 'Goianésia Do Pará', value: 'Goianésia Do Pará' },
  { label: 'Goiânia', value: 'Goiânia' },
  { label: 'Goianinha', value: 'Goianinha' },
  { label: 'Goianira', value: 'Goianira' },
  { label: 'Goianorte', value: 'Goianorte' },
  { label: 'Goiás', value: 'Goiás' },
  { label: 'Goiatins', value: 'Goiatins' },
  { label: 'Goiatuba', value: 'Goiatuba' },
  { label: 'Goicea', value: 'Goicea' },
  { label: 'Goicoechea', value: 'Goicoechea' },
  { label: 'Goiești', value: 'Goiești' },
  { label: 'Goilala', value: 'Goilala' },
  { label: 'Goincourt', value: 'Goincourt' },
  { label: 'Going', value: 'Going' },
  { label: 'Goioerê', value: 'Goioerê' },
  { label: 'Goioxim', value: 'Goioxim' },
  { label: 'Goirle', value: 'Goirle' },
  { label: 'Góis', value: 'Góis' },
  { label: 'Goito', value: 'Goito' },
  { label: 'Goizueta', value: 'Goizueta' },
  { label: 'Gójar', value: 'Gójar' },
  { label: 'Gojō', value: 'Gojō' },
  { label: 'Gojō-Shi', value: 'Gojō-Shi' },
  { label: 'Gojra', value: 'Gojra' },
  { label: 'Gokak', value: 'Gokak' },
  { label: 'Gokarna', value: 'Gokarna' },
  { label: 'Gokavaram', value: 'Gokavaram' },
  { label: 'Gökçeada', value: 'Gökçeada' },
  { label: 'Gökçebey', value: 'Gökçebey' },
  { label: 'Gokseong-Gun', value: 'Gokseong-Gun' },
  { label: 'Göksun', value: 'Göksun' },
  { label: 'Gokul', value: 'Gokul' },
  { label: 'Gokwe', value: 'Gokwe' },
  { label: 'Gol', value: 'Gol' },
  { label: 'Gol Darreh-Ye Anuch', value: 'Gol Darreh-Ye Anuch' },
  { label: 'Gol Gol', value: 'Gol Gol' },
  { label: 'Gol Heydar', value: 'Gol Heydar' },
  { label: 'Gol Tappeh', value: 'Gol Tappeh' },
  { label: 'Gol’Yanovo', value: 'Gol’Yanovo' },
  { label: 'Gola', value: 'Gola' },
  { label: 'Gola Bazar', value: 'Gola Bazar' },
  { label: 'Gola Gokarannath', value: 'Gola Gokarannath' },
  { label: 'Golaghat', value: 'Golaghat' },
  { label: 'Golăiești', value: 'Golăiești' },
  { label: 'Golakganj', value: 'Golakganj' },
  { label: 'Gołańcz', value: 'Gołańcz' },
  { label: 'Golasecca', value: 'Golasecca' },
  { label: 'GolbāF', value: 'GolbāF' },
  { label: 'Golbahar', value: 'Golbahar' },
  { label: 'Gölbaşı', value: 'Gölbaşı' },
  { label: 'Gölbaşı', value: 'Gölbaşı' },
  { label: 'Golbey', value: 'Golbey' },
  { label: 'Golborne', value: 'Golborne' },
  { label: 'Golconda', value: 'Golconda' },
  { label: 'Golcowa', value: 'Golcowa' },
  { label: 'Gölcük', value: 'Gölcük' },
  { label: 'Golčův Jeníkov', value: 'Golčův Jeníkov' },
  { label: 'Golczewo', value: 'Golczewo' },
  { label: 'Gold Bar', value: 'Gold Bar' },
  { label: 'Gold Beach', value: 'Gold Beach' },
  { label: 'Gold Camp', value: 'Gold Camp' },
  { label: 'Gold Canyon', value: 'Gold Canyon' },
  { label: 'Gold Coast', value: 'Gold Coast' },
  { label: 'Gold Hill', value: 'Gold Hill' },
  { label: 'Gold Key Lake', value: 'Gold Key Lake' },
  { label: 'Gold River', value: 'Gold River' },
  { label: 'Goldach', value: 'Goldach' },
  { label: 'Gołdap', value: 'Gołdap' },
  { label: 'Goldasht', value: 'Goldasht' },
  { label: 'Goldau', value: 'Goldau' },
  { label: 'Goldbach', value: 'Goldbach' },
  { label: 'Goldbach', value: 'Goldbach' },
  { label: 'Goldbeck', value: 'Goldbeck' },
  { label: 'Goldberg', value: 'Goldberg' },
  { label: 'Golden', value: 'Golden' },
  { label: 'Golden', value: 'Golden' },
  { label: 'Golden', value: 'Golden' },
  { label: 'Golden Acres', value: 'Golden Acres' },
  { label: 'Golden Bay', value: 'Golden Bay' },
  { label: 'Golden Beach', value: 'Golden Beach' },
  { label: 'Golden Beach', value: 'Golden Beach' },
  { label: 'Golden Gate', value: 'Golden Gate' },
  { label: 'Golden Glades', value: 'Golden Glades' },
  { label: 'Golden Grove', value: 'Golden Grove' },
  { label: 'Golden Grove', value: 'Golden Grove' },
  { label: 'Golden Grove', value: 'Golden Grove' },
  { label: 'Golden Grove', value: 'Golden Grove' },
  { label: 'Golden Hills', value: 'Golden Hills' },
  { label: 'Golden Meadow', value: 'Golden Meadow' },
  { label: 'Golden Plains', value: 'Golden Plains' },
  { label: 'Golden Point', value: 'Golden Point' },
  { label: 'Golden Shores', value: 'Golden Shores' },
  { label: 'Golden Spring', value: 'Golden Spring' },
  { label: 'Golden Square', value: 'Golden Square' },
  { label: 'Golden Triangle', value: 'Golden Triangle' },
  { label: 'Golden Valley', value: 'Golden Valley' },
  { label: 'Golden Valley', value: 'Golden Valley' },
  { label: 'Golden Valley', value: 'Golden Valley' },
  { label: 'Golden Valley County', value: 'Golden Valley County' },
  { label: 'Golden Valley County', value: 'Golden Valley County' },
  { label: 'Goldendale', value: 'Goldendale' },
  { label: 'Goldenrod', value: 'Goldenrod' },
  { label: 'Goldens Bridge', value: 'Goldens Bridge' },
  { label: 'Goldenstedt', value: 'Goldenstedt' },
  { label: 'Goldfield', value: 'Goldfield' },
  { label: 'Goldingen', value: 'Goldingen' },
  { label: 'Goldkronach', value: 'Goldkronach' },
  { label: 'Goldsboro', value: 'Goldsboro' },
  { label: 'Goldsby', value: 'Goldsby' },
  { label: 'Goldthwaite', value: 'Goldthwaite' },
  { label: 'Goldwörth', value: 'Goldwörth' },
  { label: 'Göle', value: 'Göle' },
  { label: 'Golegã', value: 'Golegã' },
  { label: 'Golema Rečica', value: 'Golema Rečica' },
  { label: 'Goleniów', value: 'Goleniów' },
  { label: 'Goléré', value: 'Goléré' },
  { label: 'Golestan', value: 'Golestan' },
  { label: 'Goleşti', value: 'Goleşti' },
  { label: 'Golești', value: 'Golești' },
  { label: 'Goleszów', value: 'Goleszów' },
  { label: 'Goleta', value: 'Goleta' },
  { label: 'Golf Manor', value: 'Golf Manor' },
  { label: 'Golferenzo', value: 'Golferenzo' },
  { label: 'Golfito', value: 'Golfito' },
  { label: 'Golfo Aranci', value: 'Golfo Aranci' },
  { label: 'Golfo De Santa Clara', value: 'Golfo De Santa Clara' },
  { label: 'Golgir', value: 'Golgir' },
  { label: 'Gölhisar', value: 'Gölhisar' },
  { label: 'Goliad', value: 'Goliad' },
  { label: 'Goliad County', value: 'Goliad County' },
  { label: 'Golina', value: 'Golina' },
  { label: 'Golitsyno', value: 'Golitsyno' },
  { label: 'Gołkowice', value: 'Gołkowice' },
  { label: 'Gölköy', value: 'Gölköy' },
  { label: 'Göllersdorf', value: 'Göllersdorf' },
  { label: 'Göllheim', value: 'Göllheim' },
  { label: 'Golling An Der Salzach', value: 'Golling An Der Salzach' },
  { label: 'Golmakan', value: 'Golmakan' },
  { label: 'Gölmarmara', value: 'Gölmarmara' },
  { label: 'Golmayo', value: 'Golmayo' },
  { label: 'Golmbach', value: 'Golmbach' },
  { label: 'Golmés', value: 'Golmés' },
  { label: 'Golmorti', value: 'Golmorti' },
  { label: 'Golmud', value: 'Golmud' },
  { label: 'Golnik', value: 'Golnik' },
  { label: 'Golog Tibetan Autonomous Prefecture', value: 'Golog Tibetan Autonomous Prefecture' },
  { label: 'Gologanu', value: 'Gologanu' },
  { label: 'Golosalvo', value: 'Golosalvo' },
  { label: 'Gölova', value: 'Gölova' },
  { label: 'Golovchino', value: 'Golovchino' },
  { label: 'Golovenka', value: 'Golovenka' },
  { label: 'Golovino', value: 'Golovino' },
  { label: 'Golovinskiy', value: 'Golovinskiy' },
  { label: 'Golovki', value: 'Golovki' },
  { label: 'Golparabad', value: 'Golparabad' },
  { label: 'Golpayegan', value: 'Golpayegan' },
  { label: 'Gölpazarı', value: 'Gölpazarı' },
  { label: 'Golpejas', value: 'Golpejas' },
  { label: 'Gols', value: 'Gols' },
  { label: 'Golsar', value: 'Golsar' },
  { label: 'Golshahr', value: 'Golshahr' },
  { label: 'Golshan', value: 'Golshan' },
  { label: 'Golshir', value: 'Golshir' },
  { label: 'Golspie', value: 'Golspie' },
  { label: 'Golßen', value: 'Golßen' },
  { label: 'Golubac', value: 'Golubac' },
  { label: 'Golub-Dobrzyń', value: 'Golub-Dobrzyń' },
  { label: 'Golubinci', value: 'Golubinci' },
  { label: 'Golubinka', value: 'Golubinka' },
  { label: 'Golubitskaya', value: 'Golubitskaya' },
  { label: 'Golubovci', value: 'Golubovci' },
  { label: 'Golubyatin', value: 'Golubyatin' },
  { label: 'Gołuchów', value: 'Gołuchów' },
  { label: 'Golushjerd', value: 'Golushjerd' },
  { label: 'Gölyaka', value: 'Gölyaka' },
  { label: 'Gołymin-Ośrodek', value: 'Gołymin-Ośrodek' },
  { label: 'Golynki', value: 'Golynki' },
  { label: 'Golyshmanovo', value: 'Golyshmanovo' },
  { label: 'Golyshmanovskiy Rayon', value: 'Golyshmanovskiy Rayon' },
  { label: 'Golzar', value: 'Golzar' },
  { label: 'Golzow', value: 'Golzow' },
  { label: 'Goma', value: 'Goma' },
  { label: 'Goma', value: 'Goma' },
  { label: 'Goma', value: 'Goma' },
  { label: 'Goma', value: 'Goma' },
  { label: 'Gomadingen', value: 'Gomadingen' },
  { label: 'Gómara', value: 'Gómara' },
  { label: 'Gomaringen', value: 'Gomaringen' },
  { label: 'Gomati', value: 'Gomati' },
  { label: 'Gomba', value: 'Gomba' },
  { label: 'Gomba District', value: 'Gomba District' },
  { label: 'Gombe', value: 'Gombe' },
  { label: 'Gombi', value: 'Gombi' },
  { label: 'Gombito', value: 'Gombito' },
  { label: 'Gombong', value: 'Gombong' },
  { label: 'Gombrèn', value: 'Gombrèn' },
  { label: 'Gömeç', value: 'Gömeç' },
  { label: 'Gomecello', value: 'Gomecello' },
  { label: 'Gomesende', value: 'Gomesende' },
  { label: 'Gometz-La-Ville', value: 'Gometz-La-Ville' },
  { label: 'Gometz-Le-Châtel', value: 'Gometz-Le-Châtel' },
  { label: 'Gómez', value: 'Gómez' },
  { label: 'Gómez Farías', value: 'Gómez Farías' },
  { label: 'Gómez Farías Municipality', value: 'Gómez Farías Municipality' },
  { label: 'Gómez Palacio', value: 'Gómez Palacio' },
  { label: 'Gómez Plata', value: 'Gómez Plata' },
  { label: 'Gómez Poniente', value: 'Gómez Poniente' },
  { label: 'Gomezserracín', value: 'Gomezserracín' },
  { label: 'Gómfoi', value: 'Gómfoi' },
  { label: 'Gomier', value: 'Gomier' },
  { label: 'Gomish Tepe', value: 'Gomish Tepe' },
  { label: 'Gomishan', value: 'Gomishan' },
  { label: 'Gommegnies', value: 'Gommegnies' },
  { label: 'Gommern', value: 'Gommern' },
  { label: 'Gommersheim', value: 'Gommersheim' },
  { label: 'Gommiswald', value: 'Gommiswald' },
  { label: 'Gomoh', value: 'Gomoh' },
  { label: 'Goms District', value: 'Goms District' },
  { label: 'Gomunice', value: 'Gomunice' },
  { label: 'Gomush Bolagh', value: 'Gomush Bolagh' },
  { label: 'Gon’Ba', value: 'Gon’Ba' },
  { label: 'Gonabad', value: 'Gonabad' },
  { label: 'Gonaïves', value: 'Gonaïves' },
  { label: 'Gonars', value: 'Gonars' },
  { label: 'Gonbad Chay', value: 'Gonbad Chay' },
  { label: 'Gonbad Kavus', value: 'Gonbad Kavus' },
  { label: 'Gonbad-E Kabud', value: 'Gonbad-E Kabud' },
  { label: 'Gonbaki', value: 'Gonbaki' },
  { label: 'Gönc', value: 'Gönc' },
  { label: 'Gonçalo', value: 'Gonçalo' },
  { label: 'Gonçalo Bocas', value: 'Gonçalo Bocas' },
  { label: 'Gonçalves', value: 'Gonçalves' },
  { label: 'Gonçalves Dias', value: 'Gonçalves Dias' },
  { label: 'Goncelin', value: 'Goncelin' },
  { label: 'Gönci Járás', value: 'Gönci Járás' },
  { label: 'Gonda', value: 'Gonda' },
  { label: 'Gondal', value: 'Gondal' },
  { label: 'Gondar', value: 'Gondar' },
  { label: 'Gondecourt', value: 'Gondecourt' },
  { label: 'Gondelsheim', value: 'Gondelsheim' },
  { label: 'Gonderange', value: 'Gonderange' },
  { label: 'Gondershausen', value: 'Gondershausen' },
  { label: 'Gondia', value: 'Gondia' },
  { label: 'Gondizalves', value: 'Gondizalves' },
  { label: 'Gondomar', value: 'Gondomar' },
  { label: 'Gondomar', value: 'Gondomar' },
  { label: 'Gond-Pontouvre', value: 'Gond-Pontouvre' },
  { label: 'Gondrecourt-Le-Château', value: 'Gondrecourt-Le-Château' },
  { label: 'Gondreville', value: 'Gondreville' },
  { label: 'Gondrin', value: 'Gondrin' },
  { label: 'Gönen', value: 'Gönen' },
  { label: 'Gönen', value: 'Gönen' },
  { label: 'Gonesse', value: 'Gonesse' },
  { label: 'Gonfaron', value: 'Gonfaron' },
  { label: 'Gongchangling', value: 'Gongchangling' },
  { label: 'Gongdanglegi Kulon', value: 'Gongdanglegi Kulon' },
  { label: 'Gongju', value: 'Gongju' },
  { label: 'Gongju-Si', value: 'Gongju-Si' },
  { label: 'Gongogi', value: 'Gongogi' },
  { label: 'Gongzhuling', value: 'Gongzhuling' },
  { label: 'Goni', value: 'Goni' },
  { label: 'Goñi', value: 'Goñi' },
  { label: 'Goniadz', value: 'Goniadz' },
  { label: 'Goniri', value: 'Goniri' },
  { label: 'Gonnehem', value: 'Gonnehem' },
  { label: 'Gonnesa', value: 'Gonnesa' },
  { label: 'Gonneville-La-Mallet', value: 'Gonneville-La-Mallet' },
  { label: 'Gönnheim', value: 'Gönnheim' },
  { label: 'Gonnoscodina', value: 'Gonnoscodina' },
  { label: 'Gonnosfanadiga', value: 'Gonnosfanadiga' },
  { label: 'Gonnosnò', value: 'Gonnosnò' },
  { label: 'Gonnostramatza', value: 'Gonnostramatza' },
  { label: 'Gontaud-De-Nogaret', value: 'Gontaud-De-Nogaret' },
  { label: 'Gonte', value: 'Gonte' },
  { label: 'Gonten', value: 'Gonten' },
  { label: 'Gontenschwil', value: 'Gontenschwil' },
  { label: 'Gontougo', value: 'Gontougo' },
  { label: 'Gonzaga', value: 'Gonzaga' },
  { label: 'Gonzaga', value: 'Gonzaga' },
  { label: 'Gonzaga', value: 'Gonzaga' },
  { label: 'Gonzaga', value: 'Gonzaga' },
  { label: 'Gonzales', value: 'Gonzales' },
  { label: 'Gonzales', value: 'Gonzales' },
  { label: 'Gonzales', value: 'Gonzales' },
  { label: 'Gonzales County', value: 'Gonzales County' },
  { label: 'Gonzales Ortega', value: 'Gonzales Ortega' },
  { label: 'Gonzalez', value: 'Gonzalez' },
  { label: 'González', value: 'González' },
  { label: 'González', value: 'González' },
  { label: 'González Ortega', value: 'González Ortega' },
  { label: 'Gonzalillo', value: 'Gonzalillo' },
  { label: 'Gonzalo', value: 'Gonzalo' },
  { label: 'Gooburrum', value: 'Gooburrum' },
  { label: 'Goochland', value: 'Goochland' },
  { label: 'Goochland County', value: 'Goochland County' },
  { label: 'Good Hope', value: 'Good Hope' },
  { label: 'Good Hope', value: 'Good Hope' },
  { label: 'Good Hope', value: 'Good Hope' },
  { label: 'Good Intent', value: 'Good Intent' },
  { label: 'Goodens River', value: 'Goodens River' },
  { label: 'Goodhue', value: 'Goodhue' },
  { label: 'Goodhue County', value: 'Goodhue County' },
  { label: 'Gooding', value: 'Gooding' },
  { label: 'Gooding County', value: 'Gooding County' },
  { label: 'Goodings Grove', value: 'Goodings Grove' },
  { label: 'Goodland', value: 'Goodland' },
  { label: 'Goodland', value: 'Goodland' },
  { label: 'Goodlands', value: 'Goodlands' },
  { label: 'Goodlands', value: 'Goodlands' },
  { label: 'Goodlettsville', value: 'Goodlettsville' },
  { label: 'Goodman', value: 'Goodman' },
  { label: 'Goodman', value: 'Goodman' },
  { label: 'Goodna', value: 'Goodna' },
  { label: 'Goodrich', value: 'Goodrich' },
  { label: 'Goodview', value: 'Goodview' },
  { label: 'Goodwater', value: 'Goodwater' },
  { label: 'Goodwell', value: 'Goodwell' },
  { label: 'Goodwick', value: 'Goodwick' },
  { label: 'Goodwill', value: 'Goodwill' },
  { label: 'Goodwood', value: 'Goodwood' },
  { label: 'Goodwood', value: 'Goodwood' },
  { label: 'Goodyear', value: 'Goodyear' },
  { label: 'Googad', value: 'Googad' },
  { label: 'Googong', value: 'Googong' },
  { label: 'Gooik', value: 'Gooik' },
  { label: 'Gooise Meren', value: 'Gooise Meren' },
  { label: 'Goole', value: 'Goole' },
  { label: 'Goolwa', value: 'Goolwa' },
  { label: 'Goolwa Beach', value: 'Goolwa Beach' },
  { label: 'Goomalling', value: 'Goomalling' },
  { label: 'Goondiwindi', value: 'Goondiwindi' },
  { label: 'Goonellabah', value: 'Goonellabah' },
  { label: 'Goor', value: 'Goor' },
  { label: 'Goose Creek', value: 'Goose Creek' },
  { label: 'Gooseberry Hill', value: 'Gooseberry Hill' },
  { label: 'Goosnargh', value: 'Goosnargh' },
  { label: 'Goostrey', value: 'Goostrey' },
  { label: 'Gopalganj', value: 'Gopalganj' },
  { label: 'Gopalganj ', value: 'Gopalganj ' },
  { label: 'Gopalpur', value: 'Gopalpur' },
  { label: 'Gopalpur', value: 'Gopalpur' },
  { label: 'Gopalur', value: 'Gopalur' },
  { label: 'Gopamau', value: 'Gopamau' },
  { label: 'Göpfritz An Der Wild', value: 'Göpfritz An Der Wild' },
  { label: 'Gopinathpur', value: 'Gopinathpur' },
  { label: 'Gopinathpur', value: 'Gopinathpur' },
  { label: 'Göppingen', value: 'Göppingen' },
  { label: 'Gor', value: 'Gor' },
  { label: 'Gora', value: 'Gora' },
  { label: 'Góra', value: 'Góra' },
  { label: 'Góra', value: 'Góra' },
  { label: 'Góra Kalwaria', value: 'Góra Kalwaria' },
  { label: 'Góra Świętej Małgorzaty', value: 'Góra Świętej Małgorzaty' },
  { label: 'Gorafe', value: 'Gorafe' },
  { label: 'Goragorskiy', value: 'Goragorskiy' },
  { label: 'Gorahun', value: 'Gorahun' },
  { label: 'Gorai', value: 'Gorai' },
  { label: 'Goraj', value: 'Goraj' },
  { label: 'Gorakhpur', value: 'Gorakhpur' },
  { label: 'Gorakhpur', value: 'Gorakhpur' },
  { label: 'Goranboy', value: 'Goranboy' },
  { label: 'Gorantla', value: 'Gorantla' },
  { label: 'Goranu', value: 'Goranu' },
  { label: 'Goravan', value: 'Goravan' },
  { label: 'Goražde', value: 'Goražde' },
  { label: 'Górażdże', value: 'Górażdże' },
  { label: 'Gorban', value: 'Gorban' },
  { label: 'Gorbăneşti', value: 'Gorbăneşti' },
  { label: 'Gorbatov', value: 'Gorbatov' },
  { label: 'Gorbatovka', value: 'Gorbatovka' },
  { label: 'Gorbatovsky', value: 'Gorbatovsky' },
  { label: 'Gorbea', value: 'Gorbea' },
  { label: 'Görbeháza', value: 'Görbeháza' },
  { label: 'Gorbio', value: 'Gorbio' },
  { label: 'Gorbuliv', value: 'Gorbuliv' },
  { label: 'Gorbunki', value: 'Gorbunki' },
  { label: 'Gorcy', value: 'Gorcy' },
  { label: 'Gordaliza Del Pino', value: 'Gordaliza Del Pino' },
  { label: 'Gordes', value: 'Gordes' },
  { label: 'Gördes', value: 'Gördes' },
  { label: 'Gordexola', value: 'Gordexola' },
  { label: 'Gordeyevka', value: 'Gordeyevka' },
  { label: 'Gordeyevskiy Rayon', value: 'Gordeyevskiy Rayon' },
  { label: 'Gordiyivka', value: 'Gordiyivka' },
  { label: 'Gordo', value: 'Gordo' },
  { label: 'Gordo El', value: 'Gordo El' },
  { label: 'Gordola', value: 'Gordola' },
  { label: 'Gordon', value: 'Gordon' },
  { label: 'Gordon', value: 'Gordon' },
  { label: 'Gordon', value: 'Gordon' },
  { label: 'Gordon', value: 'Gordon' },
  { label: 'Gordon', value: 'Gordon' },
  { label: 'Gordon County', value: 'Gordon County' },
  { label: 'Gordon Heights', value: 'Gordon Heights' },
  { label: 'Gordon Park', value: 'Gordon Park' },
  { label: 'Gordon Town', value: 'Gordon Town' },
  { label: 'Gordona', value: 'Gordona' },
  { label: 'Gordoncillo', value: 'Gordoncillo' },
  { label: 'Gordonsville', value: 'Gordonsville' },
  { label: 'Gordonsville', value: 'Gordonsville' },
  { label: 'Gordonvale', value: 'Gordonvale' },
  { label: 'Gore', value: 'Gore' },
  { label: 'Gorē', value: 'Gorē' },
  { label: 'Gorebridge', value: 'Gorebridge' },
  { label: 'Goregaon', value: 'Goregaon' },
  { label: 'Görele', value: 'Görele' },
  { label: 'Gorelki', value: 'Gorelki' },
  { label: 'Gorelovo', value: 'Gorelovo' },
  { label: 'Goreloye', value: 'Goreloye' },
  { label: 'Goreni', value: 'Goreni' },
  { label: 'Gorenice', value: 'Gorenice' },
  { label: 'Gorenja Vas', value: 'Gorenja Vas' },
  { label: 'Goreville', value: 'Goreville' },
  { label: 'Gorey', value: 'Gorey' },
  { label: 'Gorga', value: 'Gorga' },
  { label: 'Gorga', value: 'Gorga' },
  { label: 'Gorgab', value: 'Gorgab' },
  { label: 'Gorgan', value: 'Gorgan' },
  { label: 'Gorganu', value: 'Gorganu' },
  { label: 'Gorges', value: 'Gorges' },
  { label: 'Gorgier', value: 'Gorgier' },
  { label: 'Gorgo Al Monticano', value: 'Gorgo Al Monticano' },
  { label: 'Gorgo Della Chiesa', value: 'Gorgo Della Chiesa' },
  { label: 'Gorgoglione', value: 'Gorgoglione' },
  { label: 'Gorgonzola', value: 'Gorgonzola' },
  { label: 'Gorgoram', value: 'Gorgoram' },
  { label: 'Gorgota', value: 'Gorgota' },
  { label: 'Gorgota', value: 'Gorgota' },
  { label: 'Gorham', value: 'Gorham' },
  { label: 'Gorham', value: 'Gorham' },
  { label: 'Gori', value: 'Gori' },
  { label: 'Goriano Sicoli', value: 'Goriano Sicoli' },
  { label: 'Gorianshchina', value: 'Gorianshchina' },
  { label: 'Goribidnur', value: 'Goribidnur' },
  { label: 'Gorica', value: 'Gorica' },
  { label: 'Goričan', value: 'Goričan' },
  { label: 'Goričani', value: 'Goričani' },
  { label: 'Gorin', value: 'Gorin' },
  { label: 'Gorinchem', value: 'Gorinchem' },
  { label: 'Goring', value: 'Goring' },
  { label: 'Goring-By-Sea', value: 'Goring-By-Sea' },
  { label: 'Goris', value: 'Goris' },
  { label: 'Goris Munitsip’Alit’Et’I', value: 'Goris Munitsip’Alit’Et’I' },
  { label: 'Gorišnica', value: 'Gorišnica' },
  { label: 'Görisried', value: 'Görisried' },
  { label: 'Goritsy', value: 'Goritsy' },
  { label: 'Gorizia', value: 'Gorizia' },
  { label: 'Gorjani', value: 'Gorjani' },
  { label: 'Gorkha ', value: 'Gorkha ' },
  { label: 'Górki', value: 'Górki' },
  { label: 'Gorki Vtoryye', value: 'Gorki Vtoryye' },
  { label: 'Górki Wielkie', value: 'Górki Wielkie' },
  { label: 'Gorki-Leninskiye', value: 'Gorki-Leninskiye' },
  { label: 'Gorla Maggiore', value: 'Gorla Maggiore' },
  { label: 'Gorla Minore', value: 'Gorla Minore' },
  { label: 'Gorlago', value: 'Gorlago' },
  { label: 'Gorle', value: 'Gorle' },
  { label: 'Gorleston-On-Sea', value: 'Gorleston-On-Sea' },
  { label: 'Gørlev', value: 'Gørlev' },
  { label: 'Gorlice', value: 'Gorlice' },
  { label: 'Gorliczyna', value: 'Gorliczyna' },
  { label: 'Görlitz', value: 'Görlitz' },
  { label: 'Gorliz', value: 'Gorliz' },
  { label: 'Gorman', value: 'Gorman' },
  { label: 'Gorman', value: 'Gorman' },
  { label: 'Gormaz', value: 'Gormaz' },
  { label: 'Görmin', value: 'Görmin' },
  { label: 'Gorna Malina', value: 'Gorna Malina' },
  { label: 'Gorna Oryahovitsa', value: 'Gorna Oryahovitsa' },
  { label: 'Gornate Olona', value: 'Gornate Olona' },
  { label: 'Gornau', value: 'Gornau' },
  { label: 'Gornaya Polyana', value: 'Gornaya Polyana' },
  { label: 'Gornești', value: 'Gornești' },
  { label: 'Gornet', value: 'Gornet' },
  { label: 'Gornet-Cricov', value: 'Gornet-Cricov' },
  { label: 'Gornja Bistra', value: 'Gornja Bistra' },
  { label: 'Gornja Breza', value: 'Gornja Breza' },
  { label: 'Gornja Bukovica', value: 'Gornja Bukovica' },
  { label: 'Gornja Koprivna', value: 'Gornja Koprivna' },
  { label: 'Gornja Radgona', value: 'Gornja Radgona' },
  { label: 'Gornja Rijeka', value: 'Gornja Rijeka' },
  { label: 'Gornja Tuzla', value: 'Gornja Tuzla' },
  { label: 'Gornje Moštre', value: 'Gornje Moštre' },
  { label: 'Gornje Vratno', value: 'Gornje Vratno' },
  { label: 'Gornje Živinice', value: 'Gornje Živinice' },
  { label: 'Gornji Bogićevci', value: 'Gornji Bogićevci' },
  { label: 'Gornji Breg', value: 'Gornji Breg' },
  { label: 'Gornji Grad', value: 'Gornji Grad' },
  { label: 'Gornji Karin', value: 'Gornji Karin' },
  { label: 'Gornji Kneginec', value: 'Gornji Kneginec' },
  { label: 'Gornji Milanovac', value: 'Gornji Milanovac' },
  { label: 'Gornji Petrovci', value: 'Gornji Petrovci' },
  { label: 'Gornji Vakuf', value: 'Gornji Vakuf' },
  { label: 'Gorno', value: 'Gorno' },
  { label: 'Górno', value: 'Górno' },
  { label: 'Górno', value: 'Górno' },
  { label: 'Gorno Orizari', value: 'Gorno Orizari' },
  { label: 'Gorno Orizari', value: 'Gorno Orizari' },
  { label: 'Gorno-Altaysk', value: 'Gorno-Altaysk' },
  { label: 'Gornomariyskiy Rayon', value: 'Gornomariyskiy Rayon' },
  { label: 'Gornopravdinsk', value: 'Gornopravdinsk' },
  { label: 'Gornorechenskiy', value: 'Gornorechenskiy' },
  { label: 'Gornostayevka', value: 'Gornostayevka' },
  { label: 'Gornoye Loo', value: 'Gornoye Loo' },
  { label: 'Gornozavodsk', value: 'Gornozavodsk' },
  { label: 'Gornozavodsk', value: 'Gornozavodsk' },
  { label: 'Gornsdorf', value: 'Gornsdorf' },
  { label: 'Gornyak', value: 'Gornyak' },
  { label: 'Gornyak', value: 'Gornyak' },
  { label: 'Gornyatskiy', value: 'Gornyatskiy' },
  { label: 'Gornyy', value: 'Gornyy' },
  { label: 'Gornyy', value: 'Gornyy' },
  { label: 'Gornyy', value: 'Gornyy' },
  { label: 'Gornyy', value: 'Gornyy' },
  { label: 'Gornyy Rayon', value: 'Gornyy Rayon' },
  { label: 'Gornyy Shchit', value: 'Gornyy Shchit' },
  { label: 'Gornyye Klyuchi', value: 'Gornyye Klyuchi' },
  { label: 'Goro', value: 'Goro' },
  { label: 'Gorobinci', value: 'Gorobinci' },
  { label: 'Gorod Blagoveshchensk', value: 'Gorod Blagoveshchensk' },
  { label: 'Gorod Bryansk', value: 'Gorod Bryansk' },
  { label: 'Gorod Buinsk', value: 'Gorod Buinsk' },
  { label: 'Gorod Chelyabinsk', value: 'Gorod Chelyabinsk' },
  { label: 'Gorod Dyat’Kovo', value: 'Gorod Dyat’Kovo' },
  { label: 'Gorod Kazan’', value: 'Gorod Kazan’' },
  { label: 'Gorod Magadan', value: 'Gorod Magadan' },
  { label: 'Gorod Nizhnekamsk', value: 'Gorod Nizhnekamsk' },
  { label: 'Gorod Raychikhinsk', value: 'Gorod Raychikhinsk' },
  { label: 'Gorod Yelabuga', value: 'Gorod Yelabuga' },
  { label: 'Gorod Zainsk', value: 'Gorod Zainsk' },
  { label: 'Gorod Zelënodol’Sk', value: 'Gorod Zelënodol’Sk' },
  { label: 'Gorodets', value: 'Gorodets' },
  { label: 'Gorodetskiy Rayon', value: 'Gorodetskiy Rayon' },
  { label: 'Gorodishche', value: 'Gorodishche' },
  { label: 'Gorodishche Volgograd Oblast', value: 'Gorodishche Volgograd Oblast' },
  { label: 'Gorodkivka', value: 'Gorodkivka' },
  { label: 'Gorodnya', value: 'Gorodnya' },
  { label: 'Gorodok', value: 'Gorodok' },
  { label: 'Gorodoviki', value: 'Gorodoviki' },
  { label: 'Gorodovikovsk', value: 'Gorodovikovsk' },
  { label: 'Gorodske', value: 'Gorodske' },
  { label: 'Gorodskoy Okrug Alushta', value: 'Gorodskoy Okrug Alushta' },
  { label: 'Gorodskoy Okrug Armyansk', value: 'Gorodskoy Okrug Armyansk' },
  { label: 'Gorodskoy Okrug Dzhankoy', value: 'Gorodskoy Okrug Dzhankoy' },
  { label: 'Gorodskoy Okrug Feodosiya', value: 'Gorodskoy Okrug Feodosiya' },
  { label: 'Gorodskoy Okrug Krasnoperekopsk', value: 'Gorodskoy Okrug Krasnoperekopsk' },
  { label: 'Gorodskoy Okrug Saki', value: 'Gorodskoy Okrug Saki' },
  { label: 'Gorodskoy Okrug Simferopol', value: 'Gorodskoy Okrug Simferopol' },
  { label: 'Gorodskoy Okrug Sudak', value: 'Gorodskoy Okrug Sudak' },
  { label: 'Gorodskoy Okrug Yalta', value: 'Gorodskoy Okrug Yalta' },
  { label: 'Gorodskoy Okrug Yevpatoriya', value: 'Gorodskoy Okrug Yevpatoriya' },
  { label: 'Goroka', value: 'Goroka' },
  { label: 'Gorokan', value: 'Gorokan' },
  { label: 'Gorokhovets', value: 'Gorokhovets' },
  { label: 'Gorom-Gorom', value: 'Gorom-Gorom' },
  { label: 'Goromonzi District', value: 'Goromonzi District' },
  { label: 'Gorontalo', value: 'Gorontalo' },
  { label: 'Goronyo', value: 'Goronyo' },
  { label: 'Goropayi', value: 'Goropayi' },
  { label: 'Goros Número Dos', value: 'Goros Número Dos' },
  { label: 'Górowo Iławeckie', value: 'Górowo Iławeckie' },
  { label: 'Gorredijk', value: 'Gorredijk' },
  { label: 'Gorreto', value: 'Gorreto' },
  { label: 'Gorron', value: 'Gorron' },
  { label: 'Görsbach', value: 'Görsbach' },
  { label: 'Gorseinon', value: 'Gorseinon' },
  { label: 'Gorshechenskiy Rayon', value: 'Gorshechenskiy Rayon' },
  { label: 'Gorshechnoye', value: 'Gorshechnoye' },
  { label: 'Gorshkovo', value: 'Gorshkovo' },
  { label: 'Gorskaya', value: 'Gorskaya' },
  { label: 'Gorssel', value: 'Gorssel' },
  { label: 'Gort', value: 'Gort' },
  { label: 'Görtschach', value: 'Görtschach' },
  { label: 'Gorubathan', value: 'Gorubathan' },
  { label: 'Goruia', value: 'Goruia' },
  { label: 'Goruna', value: 'Goruna' },
  { label: 'Goruni', value: 'Goruni' },
  { label: 'Gorur', value: 'Gorur' },
  { label: 'Gorwa', value: 'Gorwa' },
  { label: 'Görwihl', value: 'Görwihl' },
  { label: 'Goryachevodskiy', value: 'Goryachevodskiy' },
  { label: 'Goryachiy Klyuch', value: 'Goryachiy Klyuch' },
  { label: 'Goryeong-Gun', value: 'Goryeong-Gun' },
  { label: 'Gorzano', value: 'Gorzano' },
  { label: 'Gorze', value: 'Gorze' },
  { label: 'Gorzegno', value: 'Gorzegno' },
  { label: 'Görzig', value: 'Görzig' },
  { label: 'Görzke', value: 'Görzke' },
  { label: 'Gorzków', value: 'Gorzków' },
  { label: 'Gorzkowice', value: 'Gorzkowice' },
  { label: 'Górzno', value: 'Górzno' },
  { label: 'Górzno', value: 'Górzno' },
  { label: 'Gorzów Śląski', value: 'Gorzów Śląski' },
  { label: 'Gorzów Wielkopolski', value: 'Gorzów Wielkopolski' },
  { label: 'Górzyca', value: 'Górzyca' },
  { label: 'Gorzyce', value: 'Gorzyce' },
  { label: 'Gorzyce', value: 'Gorzyce' },
  { label: 'Gorzyce Wielkie', value: 'Gorzyce Wielkie' },
  { label: 'Gorzyczki', value: 'Gorzyczki' },
  { label: 'Gosaba', value: 'Gosaba' },
  { label: 'Gosainganj', value: 'Gosainganj' },
  { label: 'Gosaldo', value: 'Gosaldo' },
  { label: 'Gosanimari', value: 'Gosanimari' },
  { label: 'Gosau', value: 'Gosau' },
  { label: 'Gosberton', value: 'Gosberton' },
  { label: 'Gościcino', value: 'Gościcino' },
  { label: 'Gościeradów', value: 'Gościeradów' },
  { label: 'Gościno', value: 'Gościno' },
  { label: 'Gosdorf', value: 'Gosdorf' },
  { label: 'Gose', value: 'Gose' },
  { label: 'Goseck', value: 'Goseck' },
  { label: 'Gosen', value: 'Gosen' },
  { label: 'Gosen Shi', value: 'Gosen Shi' },
  { label: 'Goseong', value: 'Goseong' },
  { label: 'Goseong-Gun', value: 'Goseong-Gun' },
  { label: 'Goseong-Gun', value: 'Goseong-Gun' },
  { label: 'Gosfield', value: 'Gosfield' },
  { label: 'Gosford', value: 'Gosford' },
  { label: 'Gosforth', value: 'Gosforth' },
  { label: 'Goshainganj', value: 'Goshainganj' },
  { label: 'Goshaingaon', value: 'Goshaingaon' },
  { label: 'Gosheim', value: 'Gosheim' },
  { label: 'Goshen', value: 'Goshen' },
  { label: 'Goshen', value: 'Goshen' },
  { label: 'Goshen', value: 'Goshen' },
  { label: 'Goshen', value: 'Goshen' },
  { label: 'Goshen', value: 'Goshen' },
  { label: 'Goshen', value: 'Goshen' },
  { label: 'Goshen County', value: 'Goshen County' },
  { label: 'Goshiv', value: 'Goshiv' },
  { label: 'Goshogawara', value: 'Goshogawara' },
  { label: 'Goshogawara Shi', value: 'Goshogawara Shi' },
  { label: 'Gosht', value: 'Gosht' },
  { label: 'Goslar', value: 'Goslar' },
  { label: 'Goșmani', value: 'Goșmani' },
  { label: 'Gosnay', value: 'Gosnay' },
  { label: 'Gosné', value: 'Gosné' },
  { label: 'Gosnell', value: 'Gosnell' },
  { label: 'Gosnells', value: 'Gosnells' },
  { label: 'Gósol', value: 'Gósol' },
  { label: 'Gosper County', value: 'Gosper County' },
  { label: 'Gospić', value: 'Gospić' },
  { label: 'Gosport', value: 'Gosport' },
  { label: 'Göss', value: 'Göss' },
  { label: 'Gossau', value: 'Gossau' },
  { label: 'Gossau', value: 'Gossau' },
  { label: 'Gössenberg', value: 'Gössenberg' },
  { label: 'Gossendorf', value: 'Gossendorf' },
  { label: 'Gössenheim', value: 'Gössenheim' },
  { label: 'Gossersweiler-Stein', value: 'Gossersweiler-Stein' },
  { label: 'Gößnitz', value: 'Gößnitz' },
  { label: 'Gossolengo', value: 'Gossolengo' },
  { label: 'Gossops Green', value: 'Gossops Green' },
  { label: 'Gößweinstein', value: 'Gößweinstein' },
  { label: 'Goßwitz', value: 'Goßwitz' },
  { label: 'Gostagayevskaya', value: 'Gostagayevskaya' },
  { label: 'Gostar', value: 'Gostar' },
  { label: 'Gostavăţu', value: 'Gostavăţu' },
  { label: 'Gostilitsy', value: 'Gostilitsy' },
  { label: 'Gostinari', value: 'Gostinari' },
  { label: 'Goştinari-Văcăreşti', value: 'Goştinari-Văcăreşti' },
  { label: 'Gösting', value: 'Gösting' },
  { label: 'Gostinu', value: 'Gostinu' },
  { label: 'Gostivar', value: 'Gostivar' },
  { label: 'Göstling An Der Ybbs', value: 'Göstling An Der Ybbs' },
  { label: 'Gostovići', value: 'Gostovići' },
  { label: 'Gostycyn', value: 'Gostycyn' },
  { label: 'Gostyń', value: 'Gostyń' },
  { label: 'Gostyń', value: 'Gostyń' },
  { label: 'Gostynin', value: 'Gostynin' },
  { label: 'Goszczanów', value: 'Goszczanów' },
  { label: 'Goszczyn', value: 'Goszczyn' },
  { label: 'Göta', value: 'Göta' },
  { label: 'Gotarrendura', value: 'Gotarrendura' },
  { label: 'Göteborg', value: 'Göteborg' },
  { label: 'Göteborgs Stad', value: 'Göteborgs Stad' },
  { label: 'Gotenba Shi', value: 'Gotenba Shi' },
  { label: 'Götene', value: 'Götene' },
  { label: 'Goth Garelo', value: 'Goth Garelo' },
  { label: 'Goth Phulji', value: 'Goth Phulji' },
  { label: 'Goth Radhan', value: 'Goth Radhan' },
  { label: 'Gotha', value: 'Gotha' },
  { label: 'Gotha', value: 'Gotha' },
  { label: 'Gotham', value: 'Gotham' },
  { label: 'Gothenburg', value: 'Gothenburg' },
  { label: 'Gotland', value: 'Gotland' },
  { label: 'Gotō Shi', value: 'Gotō Shi' },
  { label: 'Gotor', value: 'Gotor' },
  { label: 'Gotovlje', value: 'Gotovlje' },
  { label: 'Gotse Delchev', value: 'Gotse Delchev' },
  { label: 'Gōtsu Shi', value: 'Gōtsu Shi' },
  { label: 'Gōtsuchō', value: 'Gōtsuchō' },
  { label: 'Gottasecca', value: 'Gottasecca' },
  { label: 'Göttelsberg', value: 'Göttelsberg' },
  { label: 'Gottenheim', value: 'Gottenheim' },
  { label: 'Gotteszell', value: 'Gotteszell' },
  { label: 'Gottfrieding', value: 'Gottfrieding' },
  { label: 'Göttingen', value: 'Göttingen' },
  { label: 'Gottlieben', value: 'Gottlieben' },
  { label: 'Gottlob', value: 'Gottlob' },
  { label: 'Gottmadingen', value: 'Gottmadingen' },
  { label: 'Gottolengo', value: 'Gottolengo' },
  { label: 'Gotvand', value: 'Gotvand' },
  { label: 'Götzendorf An Der Leitha', value: 'Götzendorf An Der Leitha' },
  { label: 'Götzens', value: 'Götzens' },
  { label: 'Götzis', value: 'Götzis' },
  { label: 'Gouaix', value: 'Gouaix' },
  { label: 'Gouarec', value: 'Gouarec' },
  { label: 'Goubellat', value: 'Goubellat' },
  { label: 'Goubétto', value: 'Goubétto' },
  { label: 'Gouda', value: 'Gouda' },
  { label: 'Goudargues', value: 'Goudargues' },
  { label: 'Goudelin', value: 'Goudelin' },
  { label: 'Gouderak', value: 'Gouderak' },
  { label: 'Goudhurst', value: 'Goudhurst' },
  { label: 'Goudomp Department', value: 'Goudomp Department' },
  { label: 'Goudswaard', value: 'Goudswaard' },
  { label: 'Gouesnach', value: 'Gouesnach' },
  { label: 'Gouesnou', value: 'Gouesnou' },
  { label: 'Gouette', value: 'Gouette' },
  { label: 'Gouézec', value: 'Gouézec' },
  { label: 'Gouharan', value: 'Gouharan' },
  { label: 'Goulburn', value: 'Goulburn' },
  { label: 'Goulburn Mulwaree', value: 'Goulburn Mulwaree' },
  { label: 'Goulding', value: 'Goulding' },
  { label: 'Goulds', value: 'Goulds' },
  { label: 'Goulds', value: 'Goulds' },
  { label: 'Gouldsboro', value: 'Gouldsboro' },
  { label: 'Goulouré', value: 'Goulouré' },
  { label: 'Goult', value: 'Goult' },
  { label: 'Gouménissa', value: 'Gouménissa' },
  { label: 'Goúmero', value: 'Goúmero' },
  { label: 'Goundam', value: 'Goundam' },
  { label: 'Goundi', value: 'Goundi' },
  { label: 'Gounou Gaya', value: 'Gounou Gaya' },
  { label: 'Gourcy', value: 'Gourcy' },
  { label: 'Gourdan-Polignan', value: 'Gourdan-Polignan' },
  { label: 'Gourdon', value: 'Gourdon' },
  { label: 'Gouré', value: 'Gouré' },
  { label: 'Gourin', value: 'Gourin' },
  { label: 'Gourma-Rharous Cercle', value: 'Gourma-Rharous Cercle' },
  { label: 'Gournay-En-Bray', value: 'Gournay-En-Bray' },
  { label: 'Gournay-Sur-Marne', value: 'Gournay-Sur-Marne' },
  { label: 'Goúrnes', value: 'Goúrnes' },
  { label: 'Gourock', value: 'Gourock' },
  { label: 'Goussainville', value: 'Goussainville' },
  { label: 'Goutum', value: 'Goutum' },
  { label: 'Gouveia', value: 'Gouveia' },
  { label: 'Gouveia', value: 'Gouveia' },
  { label: 'Gouvelândia', value: 'Gouvelândia' },
  { label: 'Gouvernes', value: 'Gouvernes' },
  { label: 'Gouverneur', value: 'Gouverneur' },
  { label: 'Gouvieux', value: 'Gouvieux' },
  { label: 'Gouville-Sur-Mer', value: 'Gouville-Sur-Mer' },
  { label: 'Gouvy', value: 'Gouvy' },
  { label: 'Gouwe', value: 'Gouwe' },
  { label: 'Gouwsluis', value: 'Gouwsluis' },
  { label: 'Gouyave', value: 'Gouyave' },
  { label: 'Gouy-Sous-Bellonne', value: 'Gouy-Sous-Bellonne' },
  { label: 'Gouzeaucourt', value: 'Gouzeaucourt' },
  { label: 'Gouzon', value: 'Gouzon' },
  { label: 'Govardhan', value: 'Govardhan' },
  { label: 'Gove', value: 'Gove' },
  { label: 'Gove County', value: 'Gove County' },
  { label: 'Goven', value: 'Goven' },
  { label: 'Governador Archer', value: 'Governador Archer' },
  { label: 'Governador Celso Ramos', value: 'Governador Celso Ramos' },
  { label: 'Governador Dix Sept Rosado', value: 'Governador Dix Sept Rosado' },
  { label: 'Governador Dix-Sept Rosado', value: 'Governador Dix-Sept Rosado' },
  { label: 'Governador Edison Lobão', value: 'Governador Edison Lobão' },
  { label: 'Governador Eugênio Barros', value: 'Governador Eugênio Barros' },
  { label: 'Governador Jorge Teixeira', value: 'Governador Jorge Teixeira' },
  { label: 'Governador Lindenberg', value: 'Governador Lindenberg' },
  { label: 'Governador Luiz Rocha', value: 'Governador Luiz Rocha' },
  { label: 'Governador Mangabeira', value: 'Governador Mangabeira' },
  { label: 'Governador Newton Bello', value: 'Governador Newton Bello' },
  { label: 'Governador Nunes Freire', value: 'Governador Nunes Freire' },
  { label: 'Governador Valadares', value: 'Governador Valadares' },
  { label: 'Governor Generoso', value: 'Governor Generoso' },
  { label: 'Governor Generoso', value: 'Governor Generoso' },
  { label: 'Govilon', value: 'Govilon' },
  { label: 'Govindapuramchilakaluripetguntur', value: 'Govindapuramchilakaluripetguntur' },
  { label: 'Govindgarh', value: 'Govindgarh' },
  { label: 'Govindgarh', value: 'Govindgarh' },
  { label: 'Govone', value: 'Govone' },
  { label: 'Gowanbrae', value: 'Gowanbrae' },
  { label: 'Gowanda', value: 'Gowanda' },
  { label: 'Gowarczów', value: 'Gowarczów' },
  { label: 'Gowdin', value: 'Gowdin' },
  { label: 'Gower', value: 'Gower' },
  { label: 'Goworowo', value: 'Goworowo' },
  { label: 'Gowrie', value: 'Gowrie' },
  { label: 'Gowrie Junction', value: 'Gowrie Junction' },
  { label: 'Gowurdak', value: 'Gowurdak' },
  { label: 'Goxhill', value: 'Goxhill' },
  { label: 'Goya', value: 'Goya' },
  { label: 'Goyang-Si', value: 'Goyang-Si' },
  { label: 'Goyder', value: 'Goyder' },
  { label: 'Göynücek', value: 'Göynücek' },
  { label: 'Göynük', value: 'Göynük' },
  { label: 'Goyty', value: 'Goyty' },
  { label: 'Goz Beïda', value: 'Goz Beïda' },
  { label: 'Goz Béïda', value: 'Goz Béïda' },
  { label: 'Gozal Abdal', value: 'Gozal Abdal' },
  { label: 'Gózd', value: 'Gózd' },
  { label: 'Gozdnica', value: 'Gozdnica' },
  { label: 'Gozdowo', value: 'Gozdowo' },
  { label: 'Gozón', value: 'Gozón' },
  { label: 'Gozzano', value: 'Gozzano' },
  { label: 'Gra Liyiá', value: 'Gra Liyiá' },
  { label: 'Graaff-Reinet', value: 'Graaff-Reinet' },
  { label: 'Grabăț', value: 'Grabăț' },
  { label: 'Grabels', value: 'Grabels' },
  { label: 'Graben', value: 'Graben' },
  { label: 'Graben-Neudorf', value: 'Graben-Neudorf' },
  { label: 'Grabenstätt', value: 'Grabenstätt' },
  { label: 'Grabenstetten', value: 'Grabenstetten' },
  { label: 'Grabersdorf', value: 'Grabersdorf' },
  { label: 'Grabica', value: 'Grabica' },
  { label: 'Grabill', value: 'Grabill' },
  { label: 'Grabiszyn', value: 'Grabiszyn' },
  { label: 'Grabiszynek', value: 'Grabiszynek' },
  { label: 'Gråbo', value: 'Gråbo' },
  { label: 'Grabouw', value: 'Grabouw' },
  { label: 'Grabovac', value: 'Grabovac' },
  { label: 'Grabovci', value: 'Grabovci' },
  { label: 'Grabovci', value: 'Grabovci' },
  { label: 'Grabovo', value: 'Grabovo' },
  { label: 'Grabow', value: 'Grabow' },
  { label: 'Grabów', value: 'Grabów' },
  { label: 'Grabów Nad Prosną', value: 'Grabów Nad Prosną' },
  { label: 'Grabowhöfe', value: 'Grabowhöfe' },
  { label: 'Grabowiec', value: 'Grabowiec' },
  { label: 'Grabownica Starzeńska', value: 'Grabownica Starzeńska' },
  { label: 'Grabowo Kościerskie', value: 'Grabowo Kościerskie' },
  { label: 'Grabs', value: 'Grabs' },
  { label: 'Grabsleben', value: 'Grabsleben' },
  { label: 'Graça', value: 'Graça' },
  { label: 'Graça', value: 'Graça' },
  { label: 'Graça Aranha', value: 'Graça Aranha' },
  { label: 'Gračac', value: 'Gračac' },
  { label: 'Gračanica', value: 'Gračanica' },
  { label: 'Graçay', value: 'Graçay' },
  { label: 'Grace', value: 'Grace' },
  { label: 'Gračec', value: 'Gračec' },
  { label: 'Gracemere', value: 'Gracemere' },
  { label: 'Grâces', value: 'Grâces' },
  { label: 'Graceville', value: 'Graceville' },
  { label: 'Graceville', value: 'Graceville' },
  { label: 'Grächen', value: 'Grächen' },
  { label: 'Gracho Cardoso', value: 'Gracho Cardoso' },
  { label: 'Gracht', value: 'Gracht' },
  { label: 'Graciano Bortoni', value: 'Graciano Bortoni' },
  { label: 'Graciano Sánchez', value: 'Graciano Sánchez' },
  { label: 'Gracias', value: 'Gracias' },
  { label: 'Grad', value: 'Grad' },
  { label: 'Grad Beli Manastir', value: 'Grad Beli Manastir' },
  { label: 'Grad Biograd Na Moru', value: 'Grad Biograd Na Moru' },
  { label: 'Grad Bjelovar', value: 'Grad Bjelovar' },
  { label: 'Grad Buzet', value: 'Grad Buzet' },
  { label: 'Grad Čabar', value: 'Grad Čabar' },
  { label: 'Grad Čakovec', value: 'Grad Čakovec' },
  { label: 'Grad Čazma', value: 'Grad Čazma' },
  { label: 'Grad Crikvenica', value: 'Grad Crikvenica' },
  { label: 'Grad Daruvar', value: 'Grad Daruvar' },
  { label: 'Grad Delnice', value: 'Grad Delnice' },
  { label: 'Grad Donja Stubica', value: 'Grad Donja Stubica' },
  { label: 'Grad Donji Miholjac', value: 'Grad Donji Miholjac' },
  { label: 'Grad Drniš', value: 'Grad Drniš' },
  { label: 'Grad Dubrovnik', value: 'Grad Dubrovnik' },
  { label: 'Grad Dugo Selo', value: 'Grad Dugo Selo' },
  { label: 'Grad Garešnica', value: 'Grad Garešnica' },
  { label: 'Grad Glina', value: 'Grad Glina' },
  { label: 'Grad Grubišno Polje', value: 'Grad Grubišno Polje' },
  { label: 'Grad Hrvatska Kostajnica', value: 'Grad Hrvatska Kostajnica' },
  { label: 'Grad Hvar', value: 'Grad Hvar' },
  { label: 'Grad Imotski', value: 'Grad Imotski' },
  { label: 'Grad Ivanec', value: 'Grad Ivanec' },
  { label: 'Grad Jastrebarsko', value: 'Grad Jastrebarsko' },
  { label: 'Grad Klanjec', value: 'Grad Klanjec' },
  { label: 'Grad Koprivnica', value: 'Grad Koprivnica' },
  { label: 'Grad Korčula', value: 'Grad Korčula' },
  { label: 'Grad Krapina', value: 'Grad Krapina' },
  { label: 'Grad Križevci', value: 'Grad Križevci' },
  { label: 'Grad Krk', value: 'Grad Krk' },
  { label: 'Grad Kutina', value: 'Grad Kutina' },
  { label: 'Grad Labin', value: 'Grad Labin' },
  { label: 'Grad Ludbreg', value: 'Grad Ludbreg' },
  { label: 'Grad Makarska', value: 'Grad Makarska' },
  { label: 'Grad Našice', value: 'Grad Našice' },
  { label: 'Grad Nova Gradiška', value: 'Grad Nova Gradiška' },
  { label: 'Grad Novi Marof', value: 'Grad Novi Marof' },
  { label: 'Grad Novska', value: 'Grad Novska' },
  { label: 'Grad Omiš', value: 'Grad Omiš' },
  { label: 'Grad Opatija', value: 'Grad Opatija' },
  { label: 'Grad Orahovica', value: 'Grad Orahovica' },
  { label: 'Grad Osijek', value: 'Grad Osijek' },
  { label: 'Grad Pakrac', value: 'Grad Pakrac' },
  { label: 'Grad Pazin', value: 'Grad Pazin' },
  { label: 'Grad Petrinja', value: 'Grad Petrinja' },
  { label: 'Grad Ploče', value: 'Grad Ploče' },
  { label: 'Grad Požega', value: 'Grad Požega' },
  { label: 'Grad Rijeka', value: 'Grad Rijeka' },
  { label: 'Grad Samobor', value: 'Grad Samobor' },
  { label: 'Grad Šibenik', value: 'Grad Šibenik' },
  { label: 'Grad Sinj', value: 'Grad Sinj' },
  { label: 'Grad Sisak', value: 'Grad Sisak' },
  { label: 'Grad Slatina', value: 'Grad Slatina' },
  { label: 'Grad Slavonski Brod', value: 'Grad Slavonski Brod' },
  { label: 'Grad Split', value: 'Grad Split' },
  { label: 'Grad Supetar', value: 'Grad Supetar' },
  { label: 'Grad Sveti Ivan Zelina', value: 'Grad Sveti Ivan Zelina' },
  { label: 'Grad Trogir', value: 'Grad Trogir' },
  { label: 'Grad Valpovo', value: 'Grad Valpovo' },
  { label: 'Grad Varaždin', value: 'Grad Varaždin' },
  { label: 'Grad Velika Gorica', value: 'Grad Velika Gorica' },
  { label: 'Grad Vinkovci', value: 'Grad Vinkovci' },
  { label: 'Grad Virovitica', value: 'Grad Virovitica' },
  { label: 'Grad Vis', value: 'Grad Vis' },
  { label: 'Grad Vrbovec', value: 'Grad Vrbovec' },
  { label: 'Grad Vrbovsko', value: 'Grad Vrbovsko' },
  { label: 'Grad Vrgorac', value: 'Grad Vrgorac' },
  { label: 'Grad Vukovar', value: 'Grad Vukovar' },
  { label: 'Grad Zabok', value: 'Grad Zabok' },
  { label: 'Grad Zaprešić', value: 'Grad Zaprešić' },
  { label: 'Grad Zlatar', value: 'Grad Zlatar' },
  { label: 'Grad Županja', value: 'Grad Županja' },
  { label: 'Gradac', value: 'Gradac' },
  { label: 'Gradačac', value: 'Gradačac' },
  { label: 'Gradara', value: 'Gradara' },
  { label: 'Gradec', value: 'Gradec' },
  { label: 'Gradec', value: 'Gradec' },
  { label: 'Gradec', value: 'Gradec' },
  { label: 'Gradefes', value: 'Gradefes' },
  { label: 'Gradići', value: 'Gradići' },
  { label: 'Gradignan', value: 'Gradignan' },
  { label: 'Gradil', value: 'Gradil' },
  { label: 'Gradina', value: 'Gradina' },
  { label: 'Grădina', value: 'Grădina' },
  { label: 'Grădinari', value: 'Grădinari' },
  { label: 'Grădinari', value: 'Grădinari' },
  { label: 'Grădinari', value: 'Grădinari' },
  { label: 'Grădini', value: 'Grădini' },
  { label: 'Gradisca Disonzo', value: 'Gradisca Disonzo' },
  { label: 'Gradiška', value: 'Gradiška' },
  { label: 'Gradište', value: 'Gradište' },
  { label: 'Gradiștea', value: 'Gradiștea' },
  { label: 'Grădiştea', value: 'Grădiştea' },
  { label: 'Grădiştea', value: 'Grădiştea' },
  { label: 'Grădiştea', value: 'Grădiştea' },
  { label: 'Grădiştea', value: 'Grădiştea' },
  { label: 'Grădiștea', value: 'Grădiștea' },
  { label: 'Gradiz', value: 'Gradiz' },
  { label: 'Grado', value: 'Grado' },
  { label: 'Grado', value: 'Grado' },
  { label: 'Grado El', value: 'Grado El' },
  { label: 'Gradoli', value: 'Gradoli' },
  { label: 'Gradska Četvrt Donji Grad', value: 'Gradska Četvrt Donji Grad' },
  { label: 'Gradska Četvrt Gornji Grad - Medvescak', value: 'Gradska Četvrt Gornji Grad - Medvescak' },
  { label: 'Gradska Četvrt Podsljeme', value: 'Gradska Četvrt Podsljeme' },
  { label: 'Gradsko', value: 'Gradsko' },
  { label: 'Grady County', value: 'Grady County' },
  { label: 'Grady County', value: 'Grady County' },
  { label: 'Græsted', value: 'Græsted' },
  { label: 'Gräfelfing', value: 'Gräfelfing' },
  { label: 'Grafenau', value: 'Grafenau' },
  { label: 'Grafenau', value: 'Grafenau' },
  { label: 'Gräfenberg', value: 'Gräfenberg' },
  { label: 'Gräfendorf', value: 'Gräfendorf' },
  { label: 'Grafendorf Bei Hartberg', value: 'Grafendorf Bei Hartberg' },
  { label: 'Grafenegg', value: 'Grafenegg' },
  { label: 'Grafengehaig', value: 'Grafengehaig' },
  { label: 'Gräfenhain', value: 'Gräfenhain' },
  { label: 'Gräfenhainichen', value: 'Gräfenhainichen' },
  { label: 'Grafenhausen', value: 'Grafenhausen' },
  { label: 'Grafenrheinfeld', value: 'Grafenrheinfeld' },
  { label: 'Gräfenroda', value: 'Gräfenroda' },
  { label: 'Grafenschachen', value: 'Grafenschachen' },
  { label: 'Grafenschlag', value: 'Grafenschlag' },
  { label: 'Grafenstein', value: 'Grafenstein' },
  { label: 'Grafenwiesen', value: 'Grafenwiesen' },
  { label: 'Grafenwöhr', value: 'Grafenwöhr' },
  { label: 'Grafenwörth', value: 'Grafenwörth' },
  { label: 'Graffignana', value: 'Graffignana' },
  { label: 'Graffignano', value: 'Graffignano' },
  { label: 'Grafhorst', value: 'Grafhorst' },
  { label: 'Grafhorst', value: 'Grafhorst' },
  { label: 'Grafing Bei München', value: 'Grafing Bei München' },
  { label: 'Grafrath', value: 'Grafrath' },
  { label: 'Graft', value: 'Graft' },
  { label: 'Grafton', value: 'Grafton' },
  { label: 'Grafton', value: 'Grafton' },
  { label: 'Grafton', value: 'Grafton' },
  { label: 'Grafton', value: 'Grafton' },
  { label: 'Grafton', value: 'Grafton' },
  { label: 'Grafton', value: 'Grafton' },
  { label: 'Grafton', value: 'Grafton' },
  { label: 'Grafton County', value: 'Grafton County' },
  { label: 'Graglia', value: 'Graglia' },
  { label: 'Gragnague', value: 'Gragnague' },
  { label: 'Gragnano', value: 'Gragnano' },
  { label: 'Gragnano Trebbiense', value: 'Gragnano Trebbiense' },
  { label: 'Graham', value: 'Graham' },
  { label: 'Graham', value: 'Graham' },
  { label: 'Graham', value: 'Graham' },
  { label: 'Graham County', value: 'Graham County' },
  { label: 'Graham County', value: 'Graham County' },
  { label: 'Graham County', value: 'Graham County' },
  { label: 'Grahamstown', value: 'Grahamstown' },
  { label: 'Graiguenamanagh', value: 'Graiguenamanagh' },
  { label: 'Graikochóri', value: 'Graikochóri' },
  { label: 'Grain', value: 'Grain' },
  { label: 'Grain Valley', value: 'Grain Valley' },
  { label: 'Grainau', value: 'Grainau' },
  { label: 'Grainet', value: 'Grainet' },
  { label: 'Grainger County', value: 'Grainger County' },
  { label: 'Grainville-La-Teinturière', value: 'Grainville-La-Teinturière' },
  { label: 'Grainville-Sur-Odon', value: 'Grainville-Sur-Odon' },
  { label: 'Graja De Campalbo', value: 'Graja De Campalbo' },
  { label: 'Graja De Iniesta', value: 'Graja De Iniesta' },
  { label: 'Grajal De Campos', value: 'Grajal De Campos' },
  { label: 'Grajales', value: 'Grajales' },
  { label: 'Grajaú', value: 'Grajaú' },
  { label: 'Grăjdana', value: 'Grăjdana' },
  { label: 'Grajduri', value: 'Grajduri' },
  { label: 'Grajera', value: 'Grajera' },
  { label: 'Grajewo', value: 'Grajewo' },
  { label: 'Grakhovo', value: 'Grakhovo' },
  { label: 'Gralla', value: 'Gralla' },
  { label: 'Gram', value: 'Gram' },
  { label: 'Gramada', value: 'Gramada' },
  { label: 'Gramado', value: 'Gramado' },
  { label: 'Gramado Dos Loureiros', value: 'Gramado Dos Loureiros' },
  { label: 'Gramado Xavier', value: 'Gramado Xavier' },
  { label: 'Gramais', value: 'Gramais' },
  { label: 'Gramalote', value: 'Gramalote' },
  { label: 'Gramastetten', value: 'Gramastetten' },
  { label: 'Gramat', value: 'Gramat' },
  { label: 'Gramatneusiedl', value: 'Gramatneusiedl' },
  { label: 'Grambach', value: 'Grambach' },
  { label: 'Grambling', value: 'Grambling' },
  { label: 'Grambois', value: 'Grambois' },
  { label: 'Gramercy', value: 'Gramercy' },
  { label: 'Grămeşti', value: 'Grămeşti' },
  { label: 'Gramkow', value: 'Gramkow' },
  { label: 'Grammatikó', value: 'Grammatikó' },
  { label: 'Grammenítsa', value: 'Grammenítsa' },
  { label: 'Grammichele', value: 'Grammichele' },
  { label: 'Gramoteino', value: 'Gramoteino' },
  { label: 'Gramzow', value: 'Gramzow' },
  { label: 'Gran', value: 'Gran' },
  { label: 'Grän', value: 'Grän' },
  { label: 'Gran Chimu', value: 'Gran Chimu' },
  { label: 'Gran Morelos', value: 'Gran Morelos' },
  { label: 'Grana', value: 'Grana' },
  { label: 'Granada', value: 'Granada' },
  { label: 'Granada', value: 'Granada' },
  { label: 'Granada', value: 'Granada' },
  { label: 'Granada', value: 'Granada' },
  { label: 'Granada', value: 'Granada' },
  { label: 'Granada', value: 'Granada' },
  { label: 'Granada', value: 'Granada' },
  { label: 'Granada De Río-Tinto La', value: 'Granada De Río-Tinto La' },
  { label: 'Granada La', value: 'Granada La' },
  { label: 'Granadas', value: 'Granadas' },
  { label: 'Granadella La', value: 'Granadella La' },
  { label: 'Granadero Baigorria', value: 'Granadero Baigorria' },
  { label: 'Granadilla', value: 'Granadilla' },
  { label: 'Granadilla De Abona', value: 'Granadilla De Abona' },
  { label: 'Granadillas', value: 'Granadillas' },
  { label: 'Granado El', value: 'Granado El' },
  { label: 'Granados', value: 'Granados' },
  { label: 'Granados', value: 'Granados' },
  { label: 'Granard', value: 'Granard' },
  { label: 'Granarolo', value: 'Granarolo' },
  { label: 'Granarolo Dellemilia E Viadagola', value: 'Granarolo Dellemilia E Viadagola' },
  { label: 'Granátula De Calatrava', value: 'Granátula De Calatrava' },
  { label: 'Granbury', value: 'Granbury' },
  { label: 'Granby', value: 'Granby' },
  { label: 'Granby', value: 'Granby' },
  { label: 'Granby', value: 'Granby' },
  { label: 'Granby', value: 'Granby' },
  { label: 'Grancona', value: 'Grancona' },
  { label: 'Grancy', value: 'Grancy' },
  { label: 'Grand Baie', value: 'Grand Baie' },
  { label: 'Grand Bank', value: 'Grand Bank' },
  { label: 'Grand Bay', value: 'Grand Bay' },
  { label: 'Grand Bel Air', value: 'Grand Bel Air' },
  { label: 'Grand Blanc', value: 'Grand Blanc' },
  { label: 'Grand Bois', value: 'Grand Bois' },
  { label: 'Grand Boulevard', value: 'Grand Boulevard' },
  { label: 'Grand Brissogne', value: 'Grand Brissogne' },
  { label: 'Grand Canyon', value: 'Grand Canyon' },
  { label: 'Grand Canyon Village', value: 'Grand Canyon Village' },
  { label: 'Grand Centre', value: 'Grand Centre' },
  { label: 'Grand Coulee', value: 'Grand Coulee' },
  { label: 'Grand County', value: 'Grand County' },
  { label: 'Grand County', value: 'Grand County' },
  { label: 'Grand Falls-Windsor', value: 'Grand Falls-Windsor' },
  { label: 'Grand Forks', value: 'Grand Forks' },
  { label: 'Grand Forks', value: 'Grand Forks' },
  { label: 'Grand Forks Air Force Base', value: 'Grand Forks Air Force Base' },
  { label: 'Grand Forks County', value: 'Grand Forks County' },
  { label: 'Grand Gaube', value: 'Grand Gaube' },
  { label: 'Grand Haven', value: 'Grand Haven' },
  { label: 'Grand Island', value: 'Grand Island' },
  { label: 'Grand Island', value: 'Grand Island' },
  { label: 'Grand Isle', value: 'Grand Isle' },
  { label: 'Grand Isle County', value: 'Grand Isle County' },
  { label: 'Grand Junction', value: 'Grand Junction' },
  { label: 'Grand Ledge', value: 'Grand Ledge' },
  { label: 'Grand Marais', value: 'Grand Marais' },
  { label: 'Grand Meadow', value: 'Grand Meadow' },
  { label: 'Grand Mound', value: 'Grand Mound' },
  { label: 'Grand Point', value: 'Grand Point' },
  { label: 'Grand Prairie', value: 'Grand Prairie' },
  { label: 'Grand Rapids', value: 'Grand Rapids' },
  { label: 'Grand Rapids', value: 'Grand Rapids' },
  { label: 'Grand Riviere', value: 'Grand Riviere' },
  { label: 'Grand Ronde', value: 'Grand Ronde' },
  { label: 'Grand Sable', value: 'Grand Sable' },
  { label: 'Grand Saline', value: 'Grand Saline' },
  { label: 'Grand Terrace', value: 'Grand Terrace' },
  { label: 'Grand Traverse County', value: 'Grand Traverse County' },
  { label: 'Grandas De Salime', value: 'Grandas De Salime' },
  { label: 'Grandate', value: 'Grandate' },
  { label: 'Grand-Bassam', value: 'Grand-Bassam' },
  { label: 'Grandcamp-Maisy', value: 'Grandcamp-Maisy' },
  { label: 'Grand-Champ', value: 'Grand-Champ' },
  { label: 'Grand-Charmont', value: 'Grand-Charmont' },
  { label: 'Grand-Couronne', value: 'Grand-Couronne' },
  { label: 'Grande Cache', value: 'Grande Cache' },
  { label: 'Grande Prairie', value: 'Grande Prairie' },
  { label: 'Grande Ravine', value: 'Grande Ravine' },
  { label: 'Grande Retraite', value: 'Grande Retraite' },
  { label: 'Grande Riviere', value: 'Grande Riviere' },
  { label: 'Grande Rivière Du Nord', value: 'Grande Rivière Du Nord' },
  { label: 'Grande Rivière Noire', value: 'Grande Rivière Noire' },
  { label: 'Grande Rivière Sud Est', value: 'Grande Rivière Sud Est' },
  { label: 'Grande Riviere/Assou Canal', value: 'Grande Riviere/Assou Canal' },
  { label: 'Grande Riviere/Des Branch', value: 'Grande Riviere/Des Branch' },
  { label: 'Grande Riviere/En Leur Morne/Discompere', value: 'Grande Riviere/En Leur Morne/Discompere' },
  { label: 'Grande Riviere/Funier', value: 'Grande Riviere/Funier' },
  { label: 'Grande Riviere/Ingle Woods', value: 'Grande Riviere/Ingle Woods' },
  { label: 'Grande Riviere/Morne Caca Cochon', value: 'Grande Riviere/Morne Caca Cochon' },
  { label: 'Grande Riviere/Morne Serpent', value: 'Grande Riviere/Morne Serpent' },
  { label: 'Grande Riviere/Norbert', value: 'Grande Riviere/Norbert' },
  { label: 'Grande Riviere/Piat', value: 'Grande Riviere/Piat' },
  { label: 'Grande Riviere/White Rock', value: 'Grande Riviere/White Rock' },
  { label: 'Grande Saline', value: 'Grande Saline' },
  { label: 'Grande-Digue', value: 'Grande-Digue' },
  { label: 'Grandes Rios', value: 'Grandes Rios' },
  { label: 'Grandes Y San Martín', value: 'Grandes Y San Martín' },
  { label: 'Grande-Synthe', value: 'Grande-Synthe' },
  { label: 'Grandfontaine', value: 'Grandfontaine' },
  { label: 'Grand-Fort-Philippe', value: 'Grand-Fort-Philippe' },
  { label: 'Grand-Fougeray', value: 'Grand-Fougeray' },
  { label: 'Grandfresnoy', value: 'Grandfresnoy' },
  { label: 'Grand-Lahou', value: 'Grand-Lahou' },
  { label: 'Grândola', value: 'Grândola' },
  { label: 'Grandola Ed Uniti', value: 'Grandola Ed Uniti' },
  { label: 'Grandris', value: 'Grandris' },
  { label: 'Grand-Savagnier', value: 'Grand-Savagnier' },
  { label: 'Grandson', value: 'Grandson' },
  { label: 'Grands-Ponts', value: 'Grands-Ponts' },
  { label: 'Grandview', value: 'Grandview' },
  { label: 'Grandview', value: 'Grandview' },
  { label: 'Grandview', value: 'Grandview' },
  { label: 'Grandview', value: 'Grandview' },
  { label: 'Grandview', value: 'Grandview' },
  { label: 'Grandview Heights', value: 'Grandview Heights' },
  { label: 'Grandview Plaza', value: 'Grandview Plaza' },
  { label: 'Grandvillars', value: 'Grandvillars' },
  { label: 'Grandville', value: 'Grandville' },
  { label: 'Grandvilliers', value: 'Grandvilliers' },
  { label: 'Grandwood Park', value: 'Grandwood Park' },
  { label: 'Grandyle Village', value: 'Grandyle Village' },
  { label: 'Grane', value: 'Grane' },
  { label: 'Grane', value: 'Grane' },
  { label: 'Grañén', value: 'Grañén' },
  { label: 'Granera', value: 'Granera' },
  { label: 'Graneros', value: 'Graneros' },
  { label: 'Graneros', value: 'Graneros' },
  { label: 'Grange', value: 'Grange' },
  { label: 'Grange', value: 'Grange' },
  { label: 'Grange', value: 'Grange' },
  { label: 'Grange Hill', value: 'Grange Hill' },
  { label: 'Grange Hill', value: 'Grange Hill' },
  { label: 'Grangemouth', value: 'Grangemouth' },
  { label: 'Grange-Over-Sands', value: 'Grange-Over-Sands' },
  { label: 'Granger', value: 'Granger' },
  { label: 'Granger', value: 'Granger' },
  { label: 'Granger', value: 'Granger' },
  { label: 'Granger', value: 'Granger' },
  { label: 'Grängesberg', value: 'Grängesberg' },
  { label: 'Granges-Sur-Vologne', value: 'Granges-Sur-Vologne' },
  { label: 'Grangeville', value: 'Grangeville' },
  { label: 'Grangwav', value: 'Grangwav' },
  { label: 'Granica', value: 'Granica' },
  { label: 'Grăniceri', value: 'Grăniceri' },
  { label: 'Grăniceşti', value: 'Grăniceşti' },
  { label: 'Gränichen', value: 'Gränichen' },
  { label: 'Granite', value: 'Granite' },
  { label: 'Granite', value: 'Granite' },
  { label: 'Granite Bay', value: 'Granite Bay' },
  { label: 'Granite City', value: 'Granite City' },
  { label: 'Granite County', value: 'Granite County' },
  { label: 'Granite Falls', value: 'Granite Falls' },
  { label: 'Granite Falls', value: 'Granite Falls' },
  { label: 'Granite Falls', value: 'Granite Falls' },
  { label: 'Granite Hills', value: 'Granite Hills' },
  { label: 'Granite Quarry', value: 'Granite Quarry' },
  { label: 'Granite Shoals', value: 'Granite Shoals' },
  { label: 'Graniteville', value: 'Graniteville' },
  { label: 'Graniteville', value: 'Graniteville' },
  { label: 'Graniti', value: 'Graniti' },
  { label: 'Granito', value: 'Granito' },
  { label: 'Granitogorsk', value: 'Granitogorsk' },
  { label: 'Granja', value: 'Granja' },
  { label: 'Granja', value: 'Granja' },
  { label: 'Granja De La Costera La', value: 'Granja De La Costera La' },
  { label: 'Granja De Moreruela', value: 'Granja De Moreruela' },
  { label: 'Granja De Rocamora', value: 'Granja De Rocamora' },
  { label: 'Granja De Torrehermosa', value: 'Granja De Torrehermosa' },
  { label: 'Granja Descarp La', value: 'Granja Descarp La' },
  { label: 'Granja La', value: 'Granja La' },
  { label: 'Granjas Ampliación Santa Rosa', value: 'Granjas Ampliación Santa Rosa' },
  { label: 'Granjeiro', value: 'Granjeiro' },
  { label: 'Granjenal', value: 'Granjenal' },
  { label: 'Granjuela La', value: 'Granjuela La' },
  { label: 'Gränna', value: 'Gränna' },
  { label: 'Granollers', value: 'Granollers' },
  { label: 'Grañón', value: 'Grañón' },
  { label: 'Granowo', value: 'Granowo' },
  { label: 'Granozzo Con Monticello', value: 'Granozzo Con Monticello' },
  { label: 'Grans', value: 'Grans' },
  { label: 'Granschütz', value: 'Granschütz' },
  { label: 'Gransee', value: 'Gransee' },
  { label: 'Grant', value: 'Grant' },
  { label: 'Grant', value: 'Grant' },
  { label: 'Grant', value: 'Grant' },
  { label: 'Grant City', value: 'Grant City' },
  { label: 'Grant City', value: 'Grant City' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant County', value: 'Grant County' },
  { label: 'Grant Parish', value: 'Grant Parish' },
  { label: 'Grant Park', value: 'Grant Park' },
  { label: 'Grantham', value: 'Grantham' },
  { label: 'Grantham', value: 'Grantham' },
  { label: 'Grantham', value: 'Grantham' },
  { label: 'Grantley', value: 'Grantley' },
  { label: 'Grantola', value: 'Grantola' },
  { label: 'Granton', value: 'Granton' },
  { label: 'Grantorto', value: 'Grantorto' },
  { label: 'Grantown On Spey', value: 'Grantown On Spey' },
  { label: 'Grants', value: 'Grants' },
  { label: 'Grants Pass', value: 'Grants Pass' },
  { label: 'Grants Pen', value: 'Grants Pen' },
  { label: 'Grantsburg', value: 'Grantsburg' },
  { label: 'Grantsville', value: 'Grantsville' },
  { label: 'Grantsville', value: 'Grantsville' },
  { label: 'Grant-Valkaria', value: 'Grant-Valkaria' },
  { label: 'Grantville', value: 'Grantville' },
  { label: 'Granucillo', value: 'Granucillo' },
  { label: 'Granvilla', value: 'Granvilla' },
  { label: 'Granville', value: 'Granville' },
  { label: 'Granville', value: 'Granville' },
  { label: 'Granville', value: 'Granville' },
  { label: 'Granville', value: 'Granville' },
  { label: 'Granville', value: 'Granville' },
  { label: 'Granville', value: 'Granville' },
  { label: 'Granville', value: 'Granville' },
  { label: 'Granville', value: 'Granville' },
  { label: 'Granville', value: 'Granville' },
  { label: 'Granville', value: 'Granville' },
  { label: 'Granville County', value: 'Granville County' },
  { label: 'Granville South', value: 'Granville South' },
  { label: 'Granvin', value: 'Granvin' },
  { label: 'Granyanella', value: 'Granyanella' },
  { label: 'Granyena De Les Garrigues', value: 'Granyena De Les Garrigues' },
  { label: 'Granyena De Segarra', value: 'Granyena De Segarra' },
  { label: 'Granze', value: 'Granze' },
  { label: 'Grao De Murviedro', value: 'Grao De Murviedro' },
  { label: 'Grão Mogol', value: 'Grão Mogol' },
  { label: 'Grão Pará', value: 'Grão Pará' },
  { label: 'Grape Creek', value: 'Grape Creek' },
  { label: 'Grapeland', value: 'Grapeland' },
  { label: 'Grapevine', value: 'Grapevine' },
  { label: 'Grappenhall', value: 'Grappenhall' },
  { label: 'Grasberg', value: 'Grasberg' },
  { label: 'Grasbrunn', value: 'Grasbrunn' },
  { label: 'Grashoek', value: 'Grashoek' },
  { label: 'Grasleben', value: 'Grasleben' },
  { label: 'Grasmere', value: 'Grasmere' },
  { label: 'Grasmere', value: 'Grasmere' },
  { label: 'Grasonville', value: 'Grasonville' },
  { label: 'Grasrijk', value: 'Grasrijk' },
  { label: 'Grass Lake', value: 'Grass Lake' },
  { label: 'Grass Street', value: 'Grass Street' },
  { label: 'Grass Valley', value: 'Grass Valley' },
  { label: 'Grassano', value: 'Grassano' },
  { label: 'Grassau', value: 'Grassau' },
  { label: 'Grasse', value: 'Grasse' },
  { label: 'Grassina Ponte A Ema', value: 'Grassina Ponte A Ema' },
  { label: 'Grassington', value: 'Grassington' },
  { label: 'Grassobbio', value: 'Grassobbio' },
  { label: 'Gråsten', value: 'Gråsten' },
  { label: 'Grästorp', value: 'Grästorp' },
  { label: 'Gratacasolo', value: 'Gratacasolo' },
  { label: 'Gratallops', value: 'Gratallops' },
  { label: 'Gratangen', value: 'Gratangen' },
  { label: 'Gratentour', value: 'Gratentour' },
  { label: 'Gratia', value: 'Gratia' },
  { label: 'Gratiot County', value: 'Gratiot County' },
  { label: 'Gratkorn', value: 'Gratkorn' },
  { label: 'Graton', value: 'Graton' },
  { label: 'Gratteri', value: 'Gratteri' },
  { label: 'Grattersdorf', value: 'Grattersdorf' },
  { label: 'Gratwein', value: 'Gratwein' },
  { label: 'Graulhet', value: 'Graulhet' },
  { label: 'Grauno', value: 'Grauno' },
  { label: 'Graus', value: 'Graus' },
  { label: 'Grâușorul', value: 'Grâușorul' },
  { label: 'Grávalos', value: 'Grávalos' },
  { label: 'Gravatá', value: 'Gravatá' },
  { label: 'Gravataí', value: 'Gravataí' },
  { label: 'Gravatal', value: 'Gravatal' },
  { label: 'Gravdal', value: 'Gravdal' },
  { label: 'Grave', value: 'Grave' },
  { label: 'Gravedona-San Gregorio', value: 'Gravedona-San Gregorio' },
  { label: 'Gravel Hill', value: 'Gravel Hill' },
  { label: 'Gravel Ridge', value: 'Gravel Ridge' },
  { label: 'Gravelbourg', value: 'Gravelbourg' },
  { label: 'Gravelines', value: 'Gravelines' },
  { label: 'Gravellona', value: 'Gravellona' },
  { label: 'Gravellona Toce', value: 'Gravellona Toce' },
  { label: 'Gravenhurst', value: 'Gravenhurst' },
  { label: 'Grävenwiesbach', value: 'Grävenwiesbach' },
  { label: 'Gravere', value: 'Gravere' },
  { label: 'Graves County', value: 'Graves County' },
  { label: 'Gravesano', value: 'Gravesano' },
  { label: 'Gravesend', value: 'Gravesend' },
  { label: 'Gravesend', value: 'Gravesend' },
  { label: 'Graveson', value: 'Graveson' },
  { label: 'Gravette', value: 'Gravette' },
  { label: 'Gravigny', value: 'Gravigny' },
  { label: 'Gravina Di Catania', value: 'Gravina Di Catania' },
  { label: 'Gravina In Puglia', value: 'Gravina In Puglia' },
  { label: 'Gray', value: 'Gray' },
  { label: 'Gray', value: 'Gray' },
  { label: 'Gray', value: 'Gray' },
  { label: 'Gray', value: 'Gray' },
  { label: 'Gray', value: 'Gray' },
  { label: 'Gray County', value: 'Gray County' },
  { label: 'Gray County', value: 'Gray County' },
  { label: 'Gray Summit', value: 'Gray Summit' },
  { label: 'Gray-La-Ville', value: 'Gray-La-Ville' },
  { label: 'Grayling', value: 'Grayling' },
  { label: 'Graymoor-Devondale', value: 'Graymoor-Devondale' },
  { label: 'Grays', value: 'Grays' },
  { label: 'Grays Harbor County', value: 'Grays Harbor County' },
  { label: 'Grays Point', value: 'Grays Point' },
  { label: 'Grayslake', value: 'Grayslake' },
  { label: 'Grayson', value: 'Grayson' },
  { label: 'Grayson', value: 'Grayson' },
  { label: 'Grayson County', value: 'Grayson County' },
  { label: 'Grayson County', value: 'Grayson County' },
  { label: 'Grayson County', value: 'Grayson County' },
  { label: 'Grayson Valley', value: 'Grayson Valley' },
  { label: 'Graysville', value: 'Graysville' },
  { label: 'Graysville', value: 'Graysville' },
  { label: 'Grayville', value: 'Grayville' },
  { label: 'Grayvoron', value: 'Grayvoron' },
  { label: 'Grayvoronskiy Rayon', value: 'Grayvoronskiy Rayon' },
  { label: 'Graz', value: 'Graz' },
  { label: 'Graz Stadt', value: 'Graz Stadt' },
  { label: 'Grazalema', value: 'Grazalema' },
  { label: 'Grazhdanka', value: 'Grazhdanka' },
  { label: 'Grazhdanka', value: 'Grazhdanka' },
  { label: 'Grazhdanskoye', value: 'Grazhdanskoye' },
  { label: 'Grazzanise', value: 'Grazzanise' },
  { label: 'Grazzano Badoglio', value: 'Grazzano Badoglio' },
  { label: 'Grčec', value: 'Grčec' },
  { label: 'Greaca', value: 'Greaca' },
  { label: 'Greasby', value: 'Greasby' },
  { label: 'Gréasque', value: 'Gréasque' },
  { label: 'Great Amwell', value: 'Great Amwell' },
  { label: 'Great Ayton', value: 'Great Ayton' },
  { label: 'Great Bardfield', value: 'Great Bardfield' },
  { label: 'Great Barford', value: 'Great Barford' },
  { label: 'Great Barrington', value: 'Great Barrington' },
  { label: 'Great Barton', value: 'Great Barton' },
  { label: 'Great Bedwyn', value: 'Great Bedwyn' },
  { label: 'Great Bend', value: 'Great Bend' },
  { label: 'Great Bentley', value: 'Great Bentley' },
  { label: 'Great Bookham', value: 'Great Bookham' },
  { label: 'Great Chesterford', value: 'Great Chesterford' },
  { label: 'Great Dunmow', value: 'Great Dunmow' },
  { label: 'Great Eccleston', value: 'Great Eccleston' },
  { label: 'Great Falls', value: 'Great Falls' },
  { label: 'Great Falls', value: 'Great Falls' },
  { label: 'Great Falls', value: 'Great Falls' },
  { label: 'Great Glen', value: 'Great Glen' },
  { label: 'Great Gonerby', value: 'Great Gonerby' },
  { label: 'Great Gransden', value: 'Great Gransden' },
  { label: 'Great Hanwood', value: 'Great Hanwood' },
  { label: 'Great Harwood', value: 'Great Harwood' },
  { label: 'Great Haywood', value: 'Great Haywood' },
  { label: 'Great Horkesley', value: 'Great Horkesley' },
  { label: 'Great Horwood', value: 'Great Horwood' },
  { label: 'Great Houghton', value: 'Great Houghton' },
  { label: 'Great Kills', value: 'Great Kills' },
  { label: 'Great Leighs', value: 'Great Leighs' },
  { label: 'Great Malvern', value: 'Great Malvern' },
  { label: 'Great Marton', value: 'Great Marton' },
  { label: 'Great Missenden', value: 'Great Missenden' },
  { label: 'Great Neck', value: 'Great Neck' },
  { label: 'Great Neck Estates', value: 'Great Neck Estates' },
  { label: 'Great Neck Gardens', value: 'Great Neck Gardens' },
  { label: 'Great Neck Plaza', value: 'Great Neck Plaza' },
  { label: 'Great Paxton', value: 'Great Paxton' },
  { label: 'Great Pond', value: 'Great Pond' },
  { label: 'Great River', value: 'Great River' },
  { label: 'Great Sankey', value: 'Great Sankey' },
  { label: 'Great Torrington', value: 'Great Torrington' },
  { label: 'Great Valley', value: 'Great Valley' },
  { label: 'Great Wakering', value: 'Great Wakering' },
  { label: 'Great Waldingfield', value: 'Great Waldingfield' },
  { label: 'Great Wyrley', value: 'Great Wyrley' },
  { label: 'Great Yarmouth', value: 'Great Yarmouth' },
  { label: 'Great Yeldham', value: 'Great Yeldham' },
  { label: 'Greater Bendigo', value: 'Greater Bendigo' },
  { label: 'Greater Dandenong', value: 'Greater Dandenong' },
  { label: 'Greater Geelong', value: 'Greater Geelong' },
  { label: 'Greater Grand Crossing', value: 'Greater Grand Crossing' },
  { label: 'Greater Hume Shire', value: 'Greater Hume Shire' },
  { label: 'Greater Lakeburn', value: 'Greater Lakeburn' },
  { label: 'Greater London', value: 'Greater London' },
  { label: 'Greater Napanee', value: 'Greater Napanee' },
  { label: 'Greater Noida', value: 'Greater Noida' },
  { label: 'Greater Northdale', value: 'Greater Northdale' },
  { label: 'Greater Portmore', value: 'Greater Portmore' },
  { label: 'Greater Shepparton', value: 'Greater Shepparton' },
  { label: 'Greater Sudbury', value: 'Greater Sudbury' },
  { label: 'Greater Upper Marlboro', value: 'Greater Upper Marlboro' },
  { label: 'Greatham', value: 'Greatham' },
  { label: 'Greatwood', value: 'Greatwood' },
  { label: 'Grebănu', value: 'Grebănu' },
  { label: 'Grebbestad', value: 'Grebbestad' },
  { label: 'Grebenau', value: 'Grebenau' },
  { label: 'Grebenhain', value: 'Grebenhain' },
  { label: 'Grebenișu De Câmpie', value: 'Grebenișu De Câmpie' },
  { label: 'Grebenskaya', value: 'Grebenskaya' },
  { label: 'Grebenstein', value: 'Grebenstein' },
  { label: 'Grebin', value: 'Grebin' },
  { label: 'Grębków', value: 'Grębków' },
  { label: 'Greblești', value: 'Greblești' },
  { label: 'Grebnevo', value: 'Grebnevo' },
  { label: 'Grebo', value: 'Grebo' },
  { label: 'Grębocice', value: 'Grębocice' },
  { label: 'Gręboszów', value: 'Gręboszów' },
  { label: 'Grębów', value: 'Grębów' },
  { label: 'Greceanca', value: 'Greceanca' },
  { label: 'Greceşti', value: 'Greceşti' },
  { label: 'Grecheskoye', value: 'Grecheskoye' },
  { label: 'Greci', value: 'Greci' },
  { label: 'Greci', value: 'Greci' },
  { label: 'Greci', value: 'Greci' },
  { label: 'Greci', value: 'Greci' },
  { label: 'Greci', value: 'Greci' },
  { label: 'Grecia', value: 'Grecia' },
  { label: 'Greda', value: 'Greda' },
  { label: 'Greding', value: 'Greding' },
  { label: 'Greece', value: 'Greece' },
  { label: 'Greeley', value: 'Greeley' },
  { label: 'Greeley', value: 'Greeley' },
  { label: 'Greeley County', value: 'Greeley County' },
  { label: 'Greeley County', value: 'Greeley County' },
  { label: 'Green', value: 'Green' },
  { label: 'Green', value: 'Green' },
  { label: 'Green Acres', value: 'Green Acres' },
  { label: 'Green Bay', value: 'Green Bay' },
  { label: 'Green County', value: 'Green County' },
  { label: 'Green County', value: 'Green County' },
  { label: 'Green Cove Springs', value: 'Green Cove Springs' },
  { label: 'Green Forest', value: 'Green Forest' },
  { label: 'Green Gold', value: 'Green Gold' },
  { label: 'Green Harbor-Cedar Crest', value: 'Green Harbor-Cedar Crest' },
  { label: 'Green Haven', value: 'Green Haven' },
  { label: 'Green Head', value: 'Green Head' },
  { label: 'Green Hill', value: 'Green Hill' },
  { label: 'Green Island', value: 'Green Island' },
  { label: 'Green Island', value: 'Green Island' },
  { label: 'Green Knoll', value: 'Green Knoll' },
  { label: 'Green Lake', value: 'Green Lake' },
  { label: 'Green Lake County', value: 'Green Lake County' },
  { label: 'Green Level', value: 'Green Level' },
  { label: 'Green Meadows', value: 'Green Meadows' },
  { label: 'Green Oaks', value: 'Green Oaks' },
  { label: 'Green Park', value: 'Green Park' },
  { label: 'Green Pond', value: 'Green Pond' },
  { label: 'Green River', value: 'Green River' },
  { label: 'Green Rock', value: 'Green Rock' },
  { label: 'Green Springs', value: 'Green Springs' },
  { label: 'Green Tree', value: 'Green Tree' },
  { label: 'Green Valley', value: 'Green Valley' },
  { label: 'Green Valley', value: 'Green Valley' },
  { label: 'Green Valley', value: 'Green Valley' },
  { label: 'Green Valley', value: 'Green Valley' },
  { label: 'Green Valley Farms', value: 'Green Valley Farms' },
  { label: 'Green Zone', value: 'Green Zone' },
  { label: 'Greenacre', value: 'Greenacre' },
  { label: 'Greenacres', value: 'Greenacres' },
  { label: 'Greenacres', value: 'Greenacres' },
  { label: 'Greenacres City', value: 'Greenacres City' },
  { label: 'Greenback', value: 'Greenback' },
  { label: 'Greenbank', value: 'Greenbank' },
  { label: 'Greenbelt', value: 'Greenbelt' },
  { label: 'Greenbriar', value: 'Greenbriar' },
  { label: 'Greenbriar', value: 'Greenbriar' },
  { label: 'Greenbrier', value: 'Greenbrier' },
  { label: 'Greenbrier', value: 'Greenbrier' },
  { label: 'Greenbrier County', value: 'Greenbrier County' },
  { label: 'Greenburgh', value: 'Greenburgh' },
  { label: 'Greenbush', value: 'Greenbush' },
  { label: 'Greencastle', value: 'Greencastle' },
  { label: 'Greencastle', value: 'Greencastle' },
  { label: 'Greendale', value: 'Greendale' },
  { label: 'Greendale', value: 'Greendale' },
  { label: 'Greendale', value: 'Greendale' },
  { label: 'Greene', value: 'Greene' },
  { label: 'Greene', value: 'Greene' },
  { label: 'Greene', value: 'Greene' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greene County', value: 'Greene County' },
  { label: 'Greeneville', value: 'Greeneville' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield', value: 'Greenfield' },
  { label: 'Greenfield Park', value: 'Greenfield Park' },
  { label: 'Greenfields', value: 'Greenfields' },
  { label: 'Greenfields', value: 'Greenfields' },
  { label: 'Greenford', value: 'Greenford' },
  { label: 'Greenhill', value: 'Greenhill' },
  { label: 'Greenhill', value: 'Greenhill' },
  { label: 'Greenhills', value: 'Greenhills' },
  { label: 'Greenhills', value: 'Greenhills' },
  { label: 'Greenhithe', value: 'Greenhithe' },
  { label: 'Greenisland', value: 'Greenisland' },
  { label: 'Greenland', value: 'Greenland' },
  { label: 'Greenland', value: 'Greenland' },
  { label: 'Greenland', value: 'Greenland' },
  { label: 'Greenlawn', value: 'Greenlawn' },
  { label: 'Greenlee County', value: 'Greenlee County' },
  { label: 'Greenmount', value: 'Greenmount' },
  { label: 'Greenock', value: 'Greenock' },
  { label: 'Greenock', value: 'Greenock' },
  { label: 'Greenock', value: 'Greenock' },
  { label: 'Greenpoint', value: 'Greenpoint' },
  { label: 'Greenport', value: 'Greenport' },
  { label: 'Greenport West', value: 'Greenport West' },
  { label: 'Greensboro', value: 'Greensboro' },
  { label: 'Greensboro', value: 'Greensboro' },
  { label: 'Greensboro', value: 'Greensboro' },
  { label: 'Greensboro', value: 'Greensboro' },
  { label: 'Greensborough', value: 'Greensborough' },
  { label: 'Greensburg', value: 'Greensburg' },
  { label: 'Greensburg', value: 'Greensburg' },
  { label: 'Greensburg', value: 'Greensburg' },
  { label: 'Greensburg', value: 'Greensburg' },
  { label: 'Greensburg', value: 'Greensburg' },
  { label: 'Greensburg', value: 'Greensburg' },
  { label: 'Greenslopes', value: 'Greenslopes' },
  { label: 'Greenstone', value: 'Greenstone' },
  { label: 'Greensville County', value: 'Greensville County' },
  { label: 'Greentown', value: 'Greentown' },
  { label: 'Greentown', value: 'Greentown' },
  { label: 'Greentree', value: 'Greentree' },
  { label: 'Greenup', value: 'Greenup' },
  { label: 'Greenup', value: 'Greenup' },
  { label: 'Greenup County', value: 'Greenup County' },
  { label: 'Greenvale', value: 'Greenvale' },
  { label: 'Greenvale', value: 'Greenvale' },
  { label: 'Greenvale', value: 'Greenvale' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenville County', value: 'Greenville County' },
  { label: 'Greenway', value: 'Greenway' },
  { label: 'Greenwell Point', value: 'Greenwell Point' },
  { label: 'Greenwich', value: 'Greenwich' },
  { label: 'Greenwich', value: 'Greenwich' },
  { label: 'Greenwich', value: 'Greenwich' },
  { label: 'Greenwich', value: 'Greenwich' },
  { label: 'Greenwich Town/ Newport West', value: 'Greenwich Town/ Newport West' },
  { label: 'Greenwith', value: 'Greenwith' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Greenwood County', value: 'Greenwood County' },
  { label: 'Greenwood County', value: 'Greenwood County' },
  { label: 'Greenwood Lake', value: 'Greenwood Lake' },
  { label: 'Greenwood Village', value: 'Greenwood Village' },
  { label: 'Greer', value: 'Greer' },
  { label: 'Greer County', value: 'Greer County' },
  { label: 'Greetham', value: 'Greetham' },
  { label: 'Greffern', value: 'Greffern' },
  { label: 'Grefrath', value: 'Grefrath' },
  { label: 'Gregg County', value: 'Gregg County' },
  { label: 'Greggio', value: 'Greggio' },
  { label: 'Gregorio Del Pilar', value: 'Gregorio Del Pilar' },
  { label: 'Gregorio Del Pilar', value: 'Gregorio Del Pilar' },
  { label: 'Gregorio Méndez', value: 'Gregorio Méndez' },
  { label: 'Gregory', value: 'Gregory' },
  { label: 'Gregory', value: 'Gregory' },
  { label: 'Gregory County', value: 'Gregory County' },
  { label: 'Gregory Hills', value: 'Gregory Hills' },
  { label: 'Gregory Park', value: 'Gregory Park' },
  { label: 'Greifenberg', value: 'Greifenberg' },
  { label: 'Greifenburg', value: 'Greifenburg' },
  { label: 'Greifensee', value: 'Greifensee' },
  { label: 'Greifensee / Müllerwis / Seilerwis', value: 'Greifensee / Müllerwis / Seilerwis' },
  { label: 'Greifensee / Pfisterhölzli', value: 'Greifensee / Pfisterhölzli' },
  { label: 'Greifenstein', value: 'Greifenstein' },
  { label: 'Greifswald', value: 'Greifswald' },
  { label: 'Greilickville', value: 'Greilickville' },
  { label: 'Greiling', value: 'Greiling' },
  { label: 'Greimerath', value: 'Greimerath' },
  { label: 'Grein', value: 'Grein' },
  { label: 'Greinsfurth', value: 'Greinsfurth' },
  { label: 'Greisdorf', value: 'Greisdorf' },
  { label: 'Greiz', value: 'Greiz' },
  { label: 'Grellingen', value: 'Grellingen' },
  { label: 'Gremberghoven', value: 'Gremberghoven' },
  { label: 'Gremda', value: 'Gremda' },
  { label: 'Gremersdorf', value: 'Gremersdorf' },
  { label: 'Gremiasco', value: 'Gremiasco' },
  { label: 'Gremsdorf', value: 'Gremsdorf' },
  { label: 'Gremyachevo', value: 'Gremyachevo' },
  { label: 'Gremyachinsk', value: 'Gremyachinsk' },
  { label: 'Grenaa', value: 'Grenaa' },
  { label: 'Grenada', value: 'Grenada' },
  { label: 'Grenada County', value: 'Grenada County' },
  { label: 'Grenade', value: 'Grenade' },
  { label: 'Grenade-Sur-L’Adour', value: 'Grenade-Sur-L’Adour' },
  { label: 'Grenay', value: 'Grenay' },
  { label: 'Grenay', value: 'Grenay' },
  { label: 'Grenchen', value: 'Grenchen' },
  { label: 'Grendelbruch', value: 'Grendelbruch' },
  { label: 'Grenfell', value: 'Grenfell' },
  { label: 'Grenoble', value: 'Grenoble' },
  { label: 'Grenville', value: 'Grenville' },
  { label: 'Grenzach-Wyhlen', value: 'Grenzach-Wyhlen' },
  { label: 'Greoni', value: 'Greoni' },
  { label: 'Gréoux-Les-Bains', value: 'Gréoux-Les-Bains' },
  { label: 'Greppin', value: 'Greppin' },
  { label: 'Gresford', value: 'Gresford' },
  { label: 'Gresham', value: 'Gresham' },
  { label: 'Gresham Park', value: 'Gresham Park' },
  { label: 'Gresik', value: 'Gresik' },
  { label: 'Gresik Regency', value: 'Gresik Regency' },
  { label: 'Gresovskiy', value: 'Gresovskiy' },
  { label: 'Gressan', value: 'Gressan' },
  { label: 'Gressenberg', value: 'Gressenberg' },
  { label: 'Gressier', value: 'Gressier' },
  { label: 'Gressoney-La-Trinitè', value: 'Gressoney-La-Trinitè' },
  { label: 'Gressoney-Saint-Jean', value: 'Gressoney-Saint-Jean' },
  { label: 'Gresswiller', value: 'Gresswiller' },
  { label: 'Gresten', value: 'Gresten' },
  { label: 'Grésy-Sur-Aix', value: 'Grésy-Sur-Aix' },
  { label: 'Grésy-Sur-Isère', value: 'Grésy-Sur-Isère' },
  { label: 'Greta', value: 'Greta' },
  { label: 'Gretna', value: 'Gretna' },
  { label: 'Gretna', value: 'Gretna' },
  { label: 'Gretna', value: 'Gretna' },
  { label: 'Gretna', value: 'Gretna' },
  { label: 'Gretna', value: 'Gretna' },
  { label: 'Grettstadt', value: 'Grettstadt' },
  { label: 'Gretz-Armainvilliers', value: 'Gretz-Armainvilliers' },
  { label: 'Greußen', value: 'Greußen' },
  { label: 'Greußenheim', value: 'Greußenheim' },
  { label: 'Greve', value: 'Greve' },
  { label: 'Greve In Chianti', value: 'Greve In Chianti' },
  { label: 'Greve Kommune', value: 'Greve Kommune' },
  { label: 'Greven', value: 'Greven' },
  { label: 'Grevená', value: 'Grevená' },
  { label: 'Grevenbicht', value: 'Grevenbicht' },
  { label: 'Grevenbroich', value: 'Grevenbroich' },
  { label: 'Grevenmacher', value: 'Grevenmacher' },
  { label: 'Grevesmühlen', value: 'Grevesmühlen' },
  { label: 'Greyabbey', value: 'Greyabbey' },
  { label: 'Greybull', value: 'Greybull' },
  { label: 'Greymouth', value: 'Greymouth' },
  { label: 'Greystanes', value: 'Greystanes' },
  { label: 'Greystones', value: 'Greystones' },
  { label: 'Greytown', value: 'Greytown' },
  { label: 'Greytown', value: 'Greytown' },
  { label: 'Grez-Doiceau', value: 'Grez-Doiceau' },
  { label: 'Grézieu-La-Varenne', value: 'Grézieu-La-Varenne' },
  { label: 'Grez-Neuville', value: 'Grez-Neuville' },
  { label: 'Grez-Sur-Loing', value: 'Grez-Sur-Loing' },
  { label: 'Grezzago', value: 'Grezzago' },
  { label: 'Grezzana', value: 'Grezzana' },
  { label: 'Griante', value: 'Griante' },
  { label: 'Gribanovskiy', value: 'Gribanovskiy' },
  { label: 'Gribanovskiy Rayon', value: 'Gribanovskiy Rayon' },
  { label: 'Griboyedov', value: 'Griboyedov' },
  { label: 'Gribskov Kommune', value: 'Gribskov Kommune' },
  { label: 'Gricignano Di Aversa', value: 'Gricignano Di Aversa' },
  { label: 'Gridley', value: 'Gridley' },
  { label: 'Gridley', value: 'Gridley' },
  { label: 'Grièges', value: 'Grièges' },
  { label: 'Griegos', value: 'Griegos' },
  { label: 'Gries', value: 'Gries' },
  { label: 'Gries', value: 'Gries' },
  { label: 'Gries', value: 'Gries' },
  { label: 'Gries', value: 'Gries' },
  { label: 'Gries Am Brenner', value: 'Gries Am Brenner' },
  { label: 'Gries Im Sellrain', value: 'Gries Im Sellrain' },
  { label: 'Griesheim', value: 'Griesheim' },
  { label: 'Griesheim-Près-Molsheim', value: 'Griesheim-Près-Molsheim' },
  { label: 'Griesheim-Sur-Souffel', value: 'Griesheim-Sur-Souffel' },
  { label: 'Grieskirchen', value: 'Grieskirchen' },
  { label: 'Griesstätt', value: 'Griesstätt' },
  { label: 'Griffen', value: 'Griffen' },
  { label: 'Griffin', value: 'Griffin' },
  { label: 'Griffin', value: 'Griffin' },
  { label: 'Griffioen', value: 'Griffioen' },
  { label: 'Griffith', value: 'Griffith' },
  { label: 'Griffith', value: 'Griffith' },
  { label: 'Griffith', value: 'Griffith' },
  { label: 'Grifton', value: 'Grifton' },
  { label: 'Griggs County', value: 'Griggs County' },
  { label: 'Griggsville', value: 'Griggsville' },
  { label: 'Grigiškės', value: 'Grigiškės' },
  { label: 'Grignan', value: 'Grignan' },
  { label: 'Grignano', value: 'Grignano' },
  { label: 'Grignano Polesine', value: 'Grignano Polesine' },
  { label: 'Grignasco', value: 'Grignasco' },
  { label: 'Grigno', value: 'Grigno' },
  { label: 'Grignols', value: 'Grignols' },
  { label: 'Grignon', value: 'Grignon' },
  { label: 'Grigny', value: 'Grigny' },
  { label: 'Grigny', value: 'Grigny' },
  { label: 'Grigoropolisskaya', value: 'Grigoropolisskaya' },
  { label: 'Grijalba', value: 'Grijalba' },
  { label: 'Grijó', value: 'Grijó' },
  { label: 'Grijota', value: 'Grijota' },
  { label: 'Grijpskerk', value: 'Grijpskerk' },
  { label: 'Grijpskerke', value: 'Grijpskerke' },
  { label: 'Grill', value: 'Grill' },
  { label: 'Grillby', value: 'Grillby' },
  { label: 'Grillon', value: 'Grillon' },
  { label: 'Grimaldi', value: 'Grimaldi' },
  { label: 'Grimari', value: 'Grimari' },
  { label: 'Grimaud', value: 'Grimaud' },
  { label: 'Grimbergen', value: 'Grimbergen' },
  { label: 'Grimes', value: 'Grimes' },
  { label: 'Grimes County', value: 'Grimes County' },
  { label: 'Grimethorpe', value: 'Grimethorpe' },
  { label: 'Grimisuat', value: 'Grimisuat' },
  { label: 'Grimma', value: 'Grimma' },
  { label: 'Grimmen', value: 'Grimmen' },
  { label: 'Grimmenstein', value: 'Grimmenstein' },
  { label: 'Grimsby', value: 'Grimsby' },
  { label: 'Grimshaw', value: 'Grimshaw' },
  { label: 'Grimsley', value: 'Grimsley' },
  { label: 'Grímsnes- Og Grafningshreppur', value: 'Grímsnes- Og Grafningshreppur' },
  { label: 'Grimston', value: 'Grimston' },
  { label: 'Grindavík', value: 'Grindavík' },
  { label: 'Grindelwald', value: 'Grindelwald' },
  { label: 'Grindeni', value: 'Grindeni' },
  { label: 'Grindsted', value: 'Grindsted' },
  { label: 'Grindu', value: 'Grindu' },
  { label: 'Grindu', value: 'Grindu' },
  { label: 'Grinnell', value: 'Grinnell' },
  { label: 'Griñón', value: 'Griñón' },
  { label: 'Grins', value: 'Grins' },
  { label: 'Grinţieş', value: 'Grinţieş' },
  { label: 'Grinzane Cavour', value: 'Grinzane Cavour' },
  { label: 'Grinzens', value: 'Grinzens' },
  { label: 'Grisaleña', value: 'Grisaleña' },
  { label: 'Grisel', value: 'Grisel' },
  { label: 'Grisén', value: 'Grisén' },
  { label: 'Grishino', value: 'Grishino' },
  { label: 'Grishkovka', value: 'Grishkovka' },
  { label: 'Grisignano Di Zocco', value: 'Grisignano Di Zocco' },
  { label: 'Grisolia', value: 'Grisolia' },
  { label: 'Grisolles', value: 'Grisolles' },
  { label: 'Grissom Air Force Base', value: 'Grissom Air Force Base' },
  { label: 'Griston', value: 'Griston' },
  { label: 'Grisy-Suisnes', value: 'Grisy-Suisnes' },
  { label: 'Gritsovskiy', value: 'Gritsovskiy' },
  { label: 'Grivenskaya', value: 'Grivenskaya' },
  { label: 'Griviţa', value: 'Griviţa' },
  { label: 'Griviţa', value: 'Griviţa' },
  { label: 'Griviţa', value: 'Griviţa' },
  { label: 'Grizáno', value: 'Grizáno' },
  { label: 'Grizzana', value: 'Grizzana' },
  { label: 'Grizzana Morandi', value: 'Grizzana Morandi' },
  { label: 'Grnčari', value: 'Grnčari' },
  { label: 'Groaíras', value: 'Groaíras' },
  { label: 'Groapa Rădăii', value: 'Groapa Rădăii' },
  { label: 'Groape', value: 'Groape' },
  { label: 'Grobbendonk', value: 'Grobbendonk' },
  { label: 'Gröbenzell', value: 'Gröbenzell' },
  { label: 'Gröbers', value: 'Gröbers' },
  { label: 'Grobiņa', value: 'Grobiņa' },
  { label: 'Gröbming', value: 'Gröbming' },
  { label: 'Gröbzig', value: 'Gröbzig' },
  { label: 'Gröden', value: 'Gröden' },
  { label: 'Grödig', value: 'Grödig' },
  { label: 'Grodków', value: 'Grodków' },
  { label: 'Grodnenskiy Rayon', value: 'Grodnenskiy Rayon' },
  { label: 'Grodziec', value: 'Grodziec' },
  { label: 'Grodzisk Mazowiecki', value: 'Grodzisk Mazowiecki' },
  { label: 'Grodzisk Wielkopolski', value: 'Grodzisk Wielkopolski' },
  { label: 'Grodzisko Dolne', value: 'Grodzisko Dolne' },
  { label: 'Grodzisko Górne', value: 'Grodzisko Górne' },
  { label: 'Groenekan', value: 'Groenekan' },
  { label: 'Groenewoud', value: 'Groenewoud' },
  { label: 'Groenswaard', value: 'Groenswaard' },
  { label: 'Groesbeck', value: 'Groesbeck' },
  { label: 'Groesbeck', value: 'Groesbeck' },
  { label: 'Groesbeek', value: 'Groesbeek' },
  { label: 'Groessen', value: 'Groessen' },
  { label: 'Groffliers', value: 'Groffliers' },
  { label: 'Grognardo', value: 'Grognardo' },
  { label: 'Grogol', value: 'Grogol' },
  { label: 'Groissiat', value: 'Groissiat' },
  { label: 'Groisy', value: 'Groisy' },
  { label: 'Groitzsch', value: 'Groitzsch' },
  { label: 'Groix', value: 'Groix' },
  { label: 'Grojdibodu', value: 'Grojdibodu' },
  { label: 'Grojec', value: 'Grojec' },
  { label: 'Grójec', value: 'Grójec' },
  { label: 'Grolley', value: 'Grolley' },
  { label: 'Grolsheim', value: 'Grolsheim' },
  { label: 'Gromada', value: 'Gromada' },
  { label: 'Gromadka', value: 'Gromadka' },
  { label: 'Grombalia', value: 'Grombalia' },
  { label: 'Gromiljak', value: 'Gromiljak' },
  { label: 'Grömitz', value: 'Grömitz' },
  { label: 'Gromlongo', value: 'Gromlongo' },
  { label: 'Gromnik', value: 'Gromnik' },
  { label: 'Gromo', value: 'Gromo' },
  { label: 'Gron', value: 'Gron' },
  { label: 'Gronant', value: 'Gronant' },
  { label: 'Gronau', value: 'Gronau' },
  { label: 'Gronau', value: 'Gronau' },
  { label: 'Grondona', value: 'Grondona' },
  { label: 'Grone', value: 'Grone' },
  { label: 'Grône', value: 'Grône' },
  { label: 'Grong', value: 'Grong' },
  { label: 'Groningen', value: 'Groningen' },
  { label: 'Groningen', value: 'Groningen' },
  { label: 'Gröningen', value: 'Gröningen' },
  { label: 'Gronowo Elbląskie', value: 'Gronowo Elbląskie' },
  { label: 'Gronowo Górne', value: 'Gronowo Górne' },
  { label: 'Gronsveld', value: 'Gronsveld' },
  { label: 'Grontardo', value: 'Grontardo' },
  { label: 'Grönwohld', value: 'Grönwohld' },
  { label: 'Groombridge', value: 'Groombridge' },
  { label: 'Groot Ijsselmonde', value: 'Groot Ijsselmonde' },
  { label: 'Groot-Ammers', value: 'Groot-Ammers' },
  { label: 'Grootebroek', value: 'Grootebroek' },
  { label: 'Grootegast', value: 'Grootegast' },
  { label: 'Grootfontein', value: 'Grootfontein' },
  { label: 'Grootschermer', value: 'Grootschermer' },
  { label: 'Gropello Cairoli', value: 'Gropello Cairoli' },
  { label: 'Gropeni', value: 'Gropeni' },
  { label: 'Gropiusstadt', value: 'Gropiusstadt' },
  { label: 'Gropniţa', value: 'Gropniţa' },
  { label: 'Gropparello', value: 'Gropparello' },
  { label: 'Gropșani', value: 'Gropșani' },
  { label: 'Gros Cailloux', value: 'Gros Cailloux' },
  { label: 'Gros Islet', value: 'Gros Islet' },
  { label: 'Gros Islet Town', value: 'Gros Islet Town' },
  { label: 'Gros Islet/Edge Water', value: 'Gros Islet/Edge Water' },
  { label: 'Gros Morne', value: 'Gros Morne' },
  { label: 'Groșani', value: 'Groșani' },
  { label: 'Grosbliederstroff', value: 'Grosbliederstroff' },
  { label: 'Grosbous', value: 'Grosbous' },
  { label: 'Grosbreuil', value: 'Grosbreuil' },
  { label: 'Groscavallo', value: 'Groscavallo' },
  { label: 'Gros-De-Vaud District', value: 'Gros-De-Vaud District' },
  { label: 'Grose Vale', value: 'Grose Vale' },
  { label: 'Groşi', value: 'Groşi' },
  { label: 'Groși', value: 'Groși' },
  { label: 'Groșii Țibleșului', value: 'Groșii Țibleșului' },
  { label: 'Grosio', value: 'Grosio' },
  { label: 'Groslay', value: 'Groslay' },
  { label: 'Grosotto', value: 'Grosotto' },
  { label: 'Gros-Réderching', value: 'Gros-Réderching' },
  { label: 'Groß Ammensleben', value: 'Groß Ammensleben' },
  { label: 'Groß Börnecke', value: 'Groß Börnecke' },
  { label: 'Groß Düben', value: 'Groß Düben' },
  { label: 'Groß Grönau', value: 'Groß Grönau' },
  { label: 'Groß Ippener', value: 'Groß Ippener' },
  { label: 'Groß Kiesow', value: 'Groß Kiesow' },
  { label: 'Groß Köris', value: 'Groß Köris' },
  { label: 'Groß Kreutz', value: 'Groß Kreutz' },
  { label: 'Groß Kummerfeld', value: 'Groß Kummerfeld' },
  { label: 'Groß Laasch', value: 'Groß Laasch' },
  { label: 'Groß Lindow', value: 'Groß Lindow' },
  { label: 'Groß Miltzow', value: 'Groß Miltzow' },
  { label: 'Groß Munzel', value: 'Groß Munzel' },
  { label: 'Groß Nemerow', value: 'Groß Nemerow' },
  { label: 'Groß Oesingen', value: 'Groß Oesingen' },
  { label: 'Groß Pankow', value: 'Groß Pankow' },
  { label: 'Groß Quenstedt', value: 'Groß Quenstedt' },
  { label: 'Groß Rheide', value: 'Groß Rheide' },
  { label: 'Groß Rodensleben', value: 'Groß Rodensleben' },
  { label: 'Groß Rosenburg', value: 'Groß Rosenburg' },
  { label: 'Groß Sankt Florian', value: 'Groß Sankt Florian' },
  { label: 'Groß Santersleben', value: 'Groß Santersleben' },
  { label: 'Groß Twülpstedt', value: 'Groß Twülpstedt' },
  { label: 'Groß Wittensee', value: 'Groß Wittensee' },
  { label: 'Groß Wokern', value: 'Groß Wokern' },
  { label: 'Grossacker/Opfikon', value: 'Grossacker/Opfikon' },
  { label: 'Grossaffoltern', value: 'Grossaffoltern' },
  { label: 'Großaitingen', value: 'Großaitingen' },
  { label: 'Großalmerode', value: 'Großalmerode' },
  { label: 'Grossarl', value: 'Grossarl' },
  { label: 'Großbardau', value: 'Großbardau' },
  { label: 'Großbardorf', value: 'Großbardorf' },
  { label: 'Großbartloff', value: 'Großbartloff' },
  { label: 'Großbeeren', value: 'Großbeeren' },
  { label: 'Großbettlingen', value: 'Großbettlingen' },
  { label: 'Groß-Bieberau', value: 'Groß-Bieberau' },
  { label: 'Großbodungen', value: 'Großbodungen' },
  { label: 'Großbothen', value: 'Großbothen' },
  { label: 'Großbottwar', value: 'Großbottwar' },
  { label: 'Großbreitenbach', value: 'Großbreitenbach' },
  { label: 'Großdorf', value: 'Großdorf' },
  { label: 'Großdubrau', value: 'Großdubrau' },
  { label: 'Grosse Ile', value: 'Grosse Ile' },
  { label: 'Grosse Pointe', value: 'Grosse Pointe' },
  { label: 'Grosse Pointe Farms', value: 'Grosse Pointe Farms' },
  { label: 'Grosse Pointe Park', value: 'Grosse Pointe Park' },
  { label: 'Grosse Pointe Shores', value: 'Grosse Pointe Shores' },
  { label: 'Grosse Pointe Woods', value: 'Grosse Pointe Woods' },
  { label: 'Großebersdorf', value: 'Großebersdorf' },
  { label: 'Großeibstadt', value: 'Großeibstadt' },
  { label: 'Grosselfingen', value: 'Grosselfingen' },
  { label: 'Großenaspe', value: 'Großenaspe' },
  { label: 'Grossenbrode', value: 'Grossenbrode' },
  { label: 'Großenehrich', value: 'Großenehrich' },
  { label: 'Groß-Engersdorf', value: 'Groß-Engersdorf' },
  { label: 'Großengottern', value: 'Großengottern' },
  { label: 'Großenhain', value: 'Großenhain' },
  { label: 'Großenkneten', value: 'Großenkneten' },
  { label: 'Großenlüder', value: 'Großenlüder' },
  { label: 'Großensee', value: 'Großensee' },
  { label: 'Großenseebach', value: 'Großenseebach' },
  { label: 'Großenstein', value: 'Großenstein' },
  { label: 'Großenwiehe', value: 'Großenwiehe' },
  { label: 'Groß-Enzersdorf', value: 'Groß-Enzersdorf' },
  { label: 'Großerlach', value: 'Großerlach' },
  { label: 'Grosseto', value: 'Grosseto' },
  { label: 'Grosseto-Prugna', value: 'Grosseto-Prugna' },
  { label: 'Groß-Gerau', value: 'Groß-Gerau' },
  { label: 'Groß-Gerungs', value: 'Groß-Gerungs' },
  { label: 'Grossgmain', value: 'Grossgmain' },
  { label: 'Großgoltern', value: 'Großgoltern' },
  { label: 'Großhabersdorf', value: 'Großhabersdorf' },
  { label: 'Großhansdorf', value: 'Großhansdorf' },
  { label: 'Großharras', value: 'Großharras' },
  { label: 'Großharthau', value: 'Großharthau' },
  { label: 'Großhartmannsdorf', value: 'Großhartmannsdorf' },
  { label: 'Großheirath', value: 'Großheirath' },
  { label: 'Großhennersdorf', value: 'Großhennersdorf' },
  { label: 'Großheubach', value: 'Großheubach' },
  { label: 'Großhofen', value: 'Großhofen' },
  { label: 'Grosshöflein', value: 'Grosshöflein' },
  { label: 'Großholbach', value: 'Großholbach' },
  { label: 'Großkarlbach', value: 'Großkarlbach' },
  { label: 'Großkarolinenfeld', value: 'Großkarolinenfeld' },
  { label: 'Großkayna', value: 'Großkayna' },
  { label: 'Großkirchheim', value: 'Großkirchheim' },
  { label: 'Großklein', value: 'Großklein' },
  { label: 'Großkmehlen', value: 'Großkmehlen' },
  { label: 'Großkorbetha', value: 'Großkorbetha' },
  { label: 'Großkrotzenburg', value: 'Großkrotzenburg' },
  { label: 'Großkrut', value: 'Großkrut' },
  { label: 'Großkugel', value: 'Großkugel' },
  { label: 'Großlangheim', value: 'Großlangheim' },
  { label: 'Großlehna', value: 'Großlehna' },
  { label: 'Großlittgen', value: 'Großlittgen' },
  { label: 'Großlobming', value: 'Großlobming' },
  { label: 'Großmaischeid', value: 'Großmaischeid' },
  { label: 'Großmonra', value: 'Großmonra' },
  { label: 'Großmugl', value: 'Großmugl' },
  { label: 'Großnaundorf', value: 'Großnaundorf' },
  { label: 'Großniedesheim', value: 'Großniedesheim' },
  { label: 'Grosso', value: 'Grosso' },
  { label: 'Großolbersdorf', value: 'Großolbersdorf' },
  { label: 'Großörner', value: 'Großörner' },
  { label: 'Grossos', value: 'Grossos' },
  { label: 'Großostheim', value: 'Großostheim' },
  { label: 'Grosspetersdorf', value: 'Grosspetersdorf' },
  { label: 'Großpösna', value: 'Großpösna' },
  { label: 'Großpostwitz', value: 'Großpostwitz' },
  { label: 'Grossraming', value: 'Grossraming' },
  { label: 'Großräschen', value: 'Großräschen' },
  { label: 'Großreuth Bei Schweinau', value: 'Großreuth Bei Schweinau' },
  { label: 'Großriedenthal', value: 'Großriedenthal' },
  { label: 'Großrinderfeld', value: 'Großrinderfeld' },
  { label: 'Groß-Rohrheim', value: 'Groß-Rohrheim' },
  { label: 'Großröhrsdorf', value: 'Großröhrsdorf' },
  { label: 'Großrosseln', value: 'Großrosseln' },
  { label: 'Großrückerswalde', value: 'Großrückerswalde' },
  { label: 'Großrudestedt', value: 'Großrudestedt' },
  { label: 'Großrußbach', value: 'Großrußbach' },
  { label: 'Großschirma', value: 'Großschirma' },
  { label: 'Großschönau', value: 'Großschönau' },
  { label: 'Großschweidnitz', value: 'Großschweidnitz' },
  { label: 'Groß-Schweinbarth', value: 'Groß-Schweinbarth' },
  { label: 'Groß-Siegharts', value: 'Groß-Siegharts' },
  { label: 'Großsölk', value: 'Großsölk' },
  { label: 'Großsolt', value: 'Großsolt' },
  { label: 'Grosssulz', value: 'Grosssulz' },
  { label: 'Großthiemig', value: 'Großthiemig' },
  { label: 'Groß-Umstadt', value: 'Groß-Umstadt' },
  { label: 'Großwallstadt', value: 'Großwallstadt' },
  { label: 'Grosswangen', value: 'Grosswangen' },
  { label: 'Großweikersdorf', value: 'Großweikersdorf' },
  { label: 'Großweil', value: 'Großweil' },
  { label: 'Großweitzschen', value: 'Großweitzschen' },
  { label: 'Großwoltersdorf', value: 'Großwoltersdorf' },
  { label: 'Groß-Zimmern', value: 'Groß-Zimmern' },
  { label: 'Grosthuizen', value: 'Grosthuizen' },
  { label: 'Grosuplje', value: 'Grosuplje' },
  { label: 'Groton', value: 'Groton' },
  { label: 'Groton', value: 'Groton' },
  { label: 'Groton', value: 'Groton' },
  { label: 'Groton', value: 'Groton' },
  { label: 'Grottaferrata', value: 'Grottaferrata' },
  { label: 'Grottaglie', value: 'Grottaglie' },
  { label: 'Grottaminarda', value: 'Grottaminarda' },
  { label: 'Grottammare', value: 'Grottammare' },
  { label: 'Grottazzolina', value: 'Grottazzolina' },
  { label: 'Grotte', value: 'Grotte' },
  { label: 'Grotte Di Castro', value: 'Grotte Di Castro' },
  { label: 'Grotte Santo Stefano', value: 'Grotte Santo Stefano' },
  { label: 'Grotteria', value: 'Grotteria' },
  { label: 'Grottoes', value: 'Grottoes' },
  { label: 'Grottola', value: 'Grottola' },
  { label: 'Grottole', value: 'Grottole' },
  { label: 'Grottolella', value: 'Grottolella' },
  { label: 'Grou', value: 'Grou' },
  { label: 'Grove', value: 'Grove' },
  { label: 'Grove', value: 'Grove' },
  { label: 'Grove City', value: 'Grove City' },
  { label: 'Grove City', value: 'Grove City' },
  { label: 'Grove City', value: 'Grove City' },
  { label: 'Grove Hill', value: 'Grove Hill' },
  { label: 'Grove O', value: 'Grove O' },
  { label: 'Grove Place', value: 'Grove Place' },
  { label: 'Grove Town', value: 'Grove Town' },
  { label: 'Grovedale', value: 'Grovedale' },
  { label: 'Groveland', value: 'Groveland' },
  { label: 'Groveland', value: 'Groveland' },
  { label: 'Groveport', value: 'Groveport' },
  { label: 'Grover Beach', value: 'Grover Beach' },
  { label: 'Groves', value: 'Groves' },
  { label: 'Grovesend', value: 'Grovesend' },
  { label: 'Groveton', value: 'Groveton' },
  { label: 'Groveton', value: 'Groveton' },
  { label: 'Groveton', value: 'Groveton' },
  { label: 'Grovetown', value: 'Grovetown' },
  { label: 'Groveville', value: 'Groveville' },
  { label: 'Grozăvești', value: 'Grozăvești' },
  { label: 'Grozeşti', value: 'Grozeşti' },
  { label: 'Grozeşti', value: 'Grozeşti' },
  { label: 'Groznenskiy Rayon', value: 'Groznenskiy Rayon' },
  { label: 'Grožnjan', value: 'Grožnjan' },
  { label: 'Grožnjan-Grisignana', value: 'Grožnjan-Grisignana' },
  { label: 'Groznyy', value: 'Groznyy' },
  { label: 'Grozyne', value: 'Grozyne' },
  { label: 'Grua', value: 'Grua' },
  { label: 'Gruaro', value: 'Gruaro' },
  { label: 'Gruaro-Bagnara', value: 'Gruaro-Bagnara' },
  { label: 'Grub', value: 'Grub' },
  { label: 'Grube', value: 'Grube' },
  { label: 'Grubine', value: 'Grubine' },
  { label: 'Grubišno Polje', value: 'Grubišno Polje' },
  { label: 'Gruchet-Le-Valasse', value: 'Gruchet-Le-Valasse' },
  { label: 'Grude', value: 'Grude' },
  { label: 'Grudki', value: 'Grudki' },
  { label: 'Grudusk', value: 'Grudusk' },
  { label: 'Grudziądz', value: 'Grudziądz' },
  { label: 'Grue', value: 'Grue' },
  { label: 'Gruetli-Laager', value: 'Gruetli-Laager' },
  { label: 'Gruffy', value: 'Gruffy' },
  { label: 'Grugliasco', value: 'Grugliasco' },
  { label: 'Gruia', value: 'Gruia' },
  { label: 'Gruibingen', value: 'Gruibingen' },
  { label: 'Gruișor', value: 'Gruișor' },
  { label: 'Gruissan', value: 'Gruissan' },
  { label: 'Gruiu', value: 'Gruiu' },
  { label: 'Grumăzeşti', value: 'Grumăzeşti' },
  { label: 'Grumello Cremonese', value: 'Grumello Cremonese' },
  { label: 'Grumello Del Monte', value: 'Grumello Del Monte' },
  { label: 'Grumento Nova', value: 'Grumento Nova' },
  { label: 'Grumes', value: 'Grumes' },
  { label: 'Grumo Appula', value: 'Grumo Appula' },
  { label: 'Grumo Nevano', value: 'Grumo Nevano' },
  { label: 'Grumolo Delle Abbadesse', value: 'Grumolo Delle Abbadesse' },
  { label: 'Grumolo Pedemonte', value: 'Grumolo Pedemonte' },
  { label: 'Grums', value: 'Grums' },
  { label: 'Grünau', value: 'Grünau' },
  { label: 'Grünau Im Almtal', value: 'Grünau Im Almtal' },
  { label: 'Grünbach', value: 'Grünbach' },
  { label: 'Grünbach Am Schneeberg', value: 'Grünbach Am Schneeberg' },
  { label: 'Grünberg', value: 'Grünberg' },
  { label: 'Grünburg', value: 'Grünburg' },
  { label: 'Grundisburgh', value: 'Grundisburgh' },
  { label: 'Grundy', value: 'Grundy' },
  { label: 'Grundy Center', value: 'Grundy Center' },
  { label: 'Grundy County', value: 'Grundy County' },
  { label: 'Grundy County', value: 'Grundy County' },
  { label: 'Grundy County', value: 'Grundy County' },
  { label: 'Grundy County', value: 'Grundy County' },
  { label: 'Grünenbach', value: 'Grünenbach' },
  { label: 'Grünendeich', value: 'Grünendeich' },
  { label: 'Grunewald', value: 'Grunewald' },
  { label: 'Grünhain', value: 'Grünhain' },
  { label: 'Grünhainichen', value: 'Grünhainichen' },
  { label: 'Grünheide', value: 'Grünheide' },
  { label: 'Grunji', value: 'Grunji' },
  { label: 'Grünkraut', value: 'Grünkraut' },
  { label: 'Grünsfeld', value: 'Grünsfeld' },
  { label: 'Grünstadt', value: 'Grünstadt' },
  { label: 'Grunthal', value: 'Grunthal' },
  { label: 'Grunuovo-Campomaggiore San Luca', value: 'Grunuovo-Campomaggiore San Luca' },
  { label: 'Grünwald', value: 'Grünwald' },
  { label: 'Grupiara', value: 'Grupiara' },
  { label: 'Grüsch', value: 'Grüsch' },
  { label: 'Grushevka', value: 'Grushevka' },
  { label: 'Grushevskaya', value: 'Grushevskaya' },
  { label: 'Grushki', value: 'Grushki' },
  { label: 'Gruson', value: 'Gruson' },
  { label: 'Gruszów Wielki', value: 'Gruszów Wielki' },
  { label: 'Grüt', value: 'Grüt' },
  { label: 'Gruta', value: 'Gruta' },
  { label: 'Gruver', value: 'Gruver' },
  { label: 'Gruyère District', value: 'Gruyère District' },
  { label: 'Gruyères', value: 'Gruyères' },
  { label: 'Gryazi', value: 'Gryazi' },
  { label: 'Gryazinskiy Rayon', value: 'Gryazinskiy Rayon' },
  { label: 'Gryaznovskoye', value: 'Gryaznovskoye' },
  { label: 'Gryazovets', value: 'Gryazovets' },
  { label: 'Gryazovetskiy Rayon', value: 'Gryazovetskiy Rayon' },
  { label: 'Grybów', value: 'Grybów' },
  { label: 'Grycksbo', value: 'Grycksbo' },
  { label: 'Gryfice', value: 'Gryfice' },
  { label: 'Gryfino', value: 'Gryfino' },
  { label: 'Gryfów Śląski', value: 'Gryfów Śląski' },
  { label: 'Grygov', value: 'Grygov' },
  { label: 'Gryllefjord', value: 'Gryllefjord' },
  { label: 'Grymes Hill', value: 'Grymes Hill' },
  { label: 'Gryon', value: 'Gryon' },
  { label: 'Grýtubakkahreppur', value: 'Grýtubakkahreppur' },
  { label: 'Grzechynia', value: 'Grzechynia' },
  { label: 'Grzegorzew', value: 'Grzegorzew' },
  { label: 'Grzęska', value: 'Grzęska' },
  { label: 'Grzmiąca', value: 'Grzmiąca' },
  { label: 'Grzybowa Góra', value: 'Grzybowa Góra' },
  { label: 'Grzybowo', value: 'Grzybowo' },
  { label: 'Gschnitz', value: 'Gschnitz' },
  { label: 'Gschwandt', value: 'Gschwandt' },
  { label: 'Gschwend', value: 'Gschwend' },
  { label: 'Gstaad', value: 'Gstaad' },
  { label: 'Gstadt Am Chiemsee', value: 'Gstadt Am Chiemsee' },
  { label: 'Gua', value: 'Gua' },
  { label: 'Gua Musang', value: 'Gua Musang' },
  { label: 'Guabal', value: 'Guabal' },
  { label: 'Guabiju', value: 'Guabiju' },
  { label: 'Guabiraba', value: 'Guabiraba' },
  { label: 'Guabiruba', value: 'Guabiruba' },
  { label: 'Guabito', value: 'Guabito' },
  { label: 'Guaca', value: 'Guaca' },
  { label: 'Guaca Arriba', value: 'Guaca Arriba' },
  { label: 'Guacamaya', value: 'Guacamaya' },
  { label: 'Guacamayas', value: 'Guacamayas' },
  { label: 'Guacamayas', value: 'Guacamayas' },
  { label: 'Guacara', value: 'Guacara' },
  { label: 'Guacarí', value: 'Guacarí' },
  { label: 'Guachené', value: 'Guachené' },
  { label: 'Guachetá', value: 'Guachetá' },
  { label: 'Guachinango', value: 'Guachinango' },
  { label: 'Guachochi', value: 'Guachochi' },
  { label: 'Guachucal', value: 'Guachucal' },
  { label: 'Guácimo', value: 'Guácimo' },
  { label: 'Guaçuí', value: 'Guaçuí' },
  { label: 'Guadahortuna', value: 'Guadahortuna' },
  { label: 'Guadalajara', value: 'Guadalajara' },
  { label: 'Guadalajara', value: 'Guadalajara' },
  { label: 'Guadalajara De Buga', value: 'Guadalajara De Buga' },
  { label: 'Guadalaviar', value: 'Guadalaviar' },
  { label: 'Guadalcanal', value: 'Guadalcanal' },
  { label: 'Guadalcázar', value: 'Guadalcázar' },
  { label: 'Guadalcázar', value: 'Guadalcázar' },
  { label: 'Guadalix De La Sierra', value: 'Guadalix De La Sierra' },
  { label: 'Guadalmez', value: 'Guadalmez' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe', value: 'Guadalupe' },
  { label: 'Guadalupe Buenavista', value: 'Guadalupe Buenavista' },
  { label: 'Guadalupe Cachi', value: 'Guadalupe Cachi' },
  { label: 'Guadalupe Calderón', value: 'Guadalupe Calderón' },
  { label: 'Guadalupe Coté', value: 'Guadalupe Coté' },
  { label: 'Guadalupe County', value: 'Guadalupe County' },
  { label: 'Guadalupe County', value: 'Guadalupe County' },
  { label: 'Guadalupe De Atlas', value: 'Guadalupe De Atlas' },
  { label: 'Guadalupe De Lerma', value: 'Guadalupe De Lerma' },
  { label: 'Guadalupe De Paso Blanco', value: 'Guadalupe De Paso Blanco' },
  { label: 'Guadalupe De Rivera', value: 'Guadalupe De Rivera' },
  { label: 'Guadalupe De Tambula', value: 'Guadalupe De Tambula' },
  { label: 'Guadalupe Del Monte', value: 'Guadalupe Del Monte' },
  { label: 'Guadalupe Enríquez', value: 'Guadalupe Enríquez' },
  { label: 'Guadalupe Etla', value: 'Guadalupe Etla' },
  { label: 'Guadalupe Grijalva', value: 'Guadalupe Grijalva' },
  { label: 'Guadalupe Hidalgo', value: 'Guadalupe Hidalgo' },
  { label: 'Guadalupe Hidalgo', value: 'Guadalupe Hidalgo' },
  { label: 'Guadalupe Libertad', value: 'Guadalupe Libertad' },
  { label: 'Guadalupe Minerva', value: 'Guadalupe Minerva' },
  { label: 'Guadalupe Morelos', value: 'Guadalupe Morelos' },
  { label: 'Guadalupe Santa Ana', value: 'Guadalupe Santa Ana' },
  { label: 'Guadalupe Sarabia', value: 'Guadalupe Sarabia' },
  { label: 'Guadalupe Septién', value: 'Guadalupe Septién' },
  { label: 'Guadalupe Texcalac', value: 'Guadalupe Texcalac' },
  { label: 'Guadalupe Tlachco', value: 'Guadalupe Tlachco' },
  { label: 'Guadalupe Totoltepec', value: 'Guadalupe Totoltepec' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Victoria', value: 'Guadalupe Victoria' },
  { label: 'Guadalupe Y Calvo', value: 'Guadalupe Y Calvo' },
  { label: 'Guadalupe Zaragoza', value: 'Guadalupe Zaragoza' },
  { label: 'Guadamur', value: 'Guadamur' },
  { label: 'Guadarrama', value: 'Guadarrama' },
  { label: 'Guadasequies', value: 'Guadasequies' },
  { label: 'Guadasséquies', value: 'Guadasséquies' },
  { label: 'Guadassuar', value: 'Guadassuar' },
  { label: 'Guadix', value: 'Guadix' },
  { label: 'Guadramiro', value: 'Guadramiro' },
  { label: 'Guaduas', value: 'Guaduas' },
  { label: 'Guagnano', value: 'Guagnano' },
  { label: 'Guagua', value: 'Guagua' },
  { label: 'Guagua', value: 'Guagua' },
  { label: 'Guaíba', value: 'Guaíba' },
  { label: 'Guaiçara', value: 'Guaiçara' },
  { label: 'Guaimaca', value: 'Guaimaca' },
  { label: 'Guáimaro', value: 'Guáimaro' },
  { label: 'Guaimbê', value: 'Guaimbê' },
  { label: 'Guaimitas', value: 'Guaimitas' },
  { label: 'Guaíra', value: 'Guaíra' },
  { label: 'Guaíra', value: 'Guaíra' },
  { label: 'Guairaçá', value: 'Guairaçá' },
  { label: 'Guaitarilla', value: 'Guaitarilla' },
  { label: 'Guaitecas', value: 'Guaitecas' },
  { label: 'Guaiúba', value: 'Guaiúba' },
  { label: 'Guajará', value: 'Guajará' },
  { label: 'Guajará Mirim', value: 'Guajará Mirim' },
  { label: 'Guajará-Mirim', value: 'Guajará-Mirim' },
  { label: 'Guajares Los', value: 'Guajares Los' },
  { label: 'Guajeru', value: 'Guajeru' },
  { label: 'Guajiquiro', value: 'Guajiquiro' },
  { label: 'Gualaca', value: 'Gualaca' },
  { label: 'Gualaceo', value: 'Gualaceo' },
  { label: 'Gualaco', value: 'Gualaco' },
  { label: 'Gualala', value: 'Gualala' },
  { label: 'Gualaquiza', value: 'Gualaquiza' },
  { label: 'Gualba', value: 'Gualba' },
  { label: 'Gualchos', value: 'Gualchos' },
  { label: 'Gualcince', value: 'Gualcince' },
  { label: 'Gualdo', value: 'Gualdo' },
  { label: 'Gualdo Cattaneo', value: 'Gualdo Cattaneo' },
  { label: 'Gualdo Tadino', value: 'Gualdo Tadino' },
  { label: 'Gualeguay', value: 'Gualeguay' },
  { label: 'Gualeguaychú', value: 'Gualeguaychú' },
  { label: 'Gualjoco', value: 'Gualjoco' },
  { label: 'Gualmatán', value: 'Gualmatán' },
  { label: 'Gualta', value: 'Gualta' },
  { label: 'Gualtieri', value: 'Gualtieri' },
  { label: 'Gualtieri Sicaminò', value: 'Gualtieri Sicaminò' },
  { label: 'Guamaggiore', value: 'Guamaggiore' },
  { label: 'Guamal', value: 'Guamal' },
  { label: 'Guamal', value: 'Guamal' },
  { label: 'Guamaré', value: 'Guamaré' },
  { label: 'Guamiranga', value: 'Guamiranga' },
  { label: 'Guamo', value: 'Guamo' },
  { label: 'Guamúchil', value: 'Guamúchil' },
  { label: 'Guamúchil', value: 'Guamúchil' },
  { label: 'Guamuchilillo', value: 'Guamuchilillo' },
  { label: 'Guanabacoa', value: 'Guanabacoa' },
  { label: 'Guanaceví', value: 'Guanaceví' },
  { label: 'Guanagazapa', value: 'Guanagazapa' },
  { label: 'Guanaja', value: 'Guanaja' },
  { label: 'Guanajay', value: 'Guanajay' },
  { label: 'Guanajuatillo', value: 'Guanajuatillo' },
  { label: 'Guanajuato', value: 'Guanajuato' },
  { label: 'Guanambi', value: 'Guanambi' },
  { label: 'Guananico', value: 'Guananico' },
  { label: 'Guanare', value: 'Guanare' },
  { label: 'Guanay', value: 'Guanay' },
  { label: 'Guancha La', value: 'Guancha La' },
  { label: 'Guándaro', value: 'Guándaro' },
  { label: 'Guandu', value: 'Guandu' },
  { label: 'Guane', value: 'Guane' },
  { label: 'Guang’An', value: 'Guang’An' },
  { label: 'Guangming', value: 'Guangming' },
  { label: 'Guangshui', value: 'Guangshui' },
  { label: 'Guangyuan', value: 'Guangyuan' },
  { label: 'Guangzhou', value: 'Guangzhou' },
  { label: 'Guangzhou Shi', value: 'Guangzhou Shi' },
  { label: 'Guanhães', value: 'Guanhães' },
  { label: 'Guánica', value: 'Guánica' },
  { label: 'Guánica', value: 'Guánica' },
  { label: 'Guánica Municipio', value: 'Guánica Municipio' },
  { label: 'Guankou', value: 'Guankou' },
  { label: 'Guano', value: 'Guano' },
  { label: 'Guantánamo', value: 'Guantánamo' },
  { label: 'Guantou', value: 'Guantou' },
  { label: 'Guanzate', value: 'Guanzate' },
  { label: 'Guapé', value: 'Guapé' },
  { label: 'Guapi', value: 'Guapi' },
  { label: 'Guapiaçu', value: 'Guapiaçu' },
  { label: 'Guapiara', value: 'Guapiara' },
  { label: 'Guápiles', value: 'Guápiles' },
  { label: 'Guapimirim', value: 'Guapimirim' },
  { label: 'Guapirama', value: 'Guapirama' },
  { label: 'Guapó', value: 'Guapó' },
  { label: 'Guaporé', value: 'Guaporé' },
  { label: 'Guaporema', value: 'Guaporema' },
  { label: 'Guapotá', value: 'Guapotá' },
  { label: 'Guaquitepec', value: 'Guaquitepec' },
  { label: 'Guará', value: 'Guará' },
  { label: 'Guarabira', value: 'Guarabira' },
  { label: 'Guaraçaí', value: 'Guaraçaí' },
  { label: 'Guaraci', value: 'Guaraci' },
  { label: 'Guaraci', value: 'Guaraci' },
  { label: 'Guaraciaba', value: 'Guaraciaba' },
  { label: 'Guaraciaba', value: 'Guaraciaba' },
  { label: 'Guaraciaba Do Norte', value: 'Guaraciaba Do Norte' },
  { label: 'Guaraciama', value: 'Guaraciama' },
  { label: 'Guaraí', value: 'Guaraí' },
  { label: 'Guaraíta', value: 'Guaraíta' },
  { label: 'Guarambaré', value: 'Guarambaré' },
  { label: 'Guaramiranga', value: 'Guaramiranga' },
  { label: 'Guaramirim', value: 'Guaramirim' },
  { label: 'Guaranda', value: 'Guaranda' },
  { label: 'Guaranda', value: 'Guaranda' },
  { label: 'Guaranésia', value: 'Guaranésia' },
  { label: 'Guarani', value: 'Guarani' },
  { label: 'Guaraní', value: 'Guaraní' },
  { label: 'Guarani Das Missões', value: 'Guarani Das Missões' },
  { label: 'Guarani De Goiás', value: 'Guarani De Goiás' },
  { label: 'Guarani Doeste', value: 'Guarani Doeste' },
  { label: 'Guaraniaçu', value: 'Guaraniaçu' },
  { label: 'Guarantã', value: 'Guarantã' },
  { label: 'Guarantã Do Norte', value: 'Guarantã Do Norte' },
  { label: 'Guarapari', value: 'Guarapari' },
  { label: 'Guarapuava', value: 'Guarapuava' },
  { label: 'Guaraqueçaba', value: 'Guaraqueçaba' },
  { label: 'Guarará', value: 'Guarará' },
  { label: 'Guararapes', value: 'Guararapes' },
  { label: 'Guararé', value: 'Guararé' },
  { label: 'Guararé Arriba', value: 'Guararé Arriba' },
  { label: 'Guararema', value: 'Guararema' },
  { label: 'Guaratinga', value: 'Guaratinga' },
  { label: 'Guaratinguetá', value: 'Guaratinguetá' },
  { label: 'Guaratuba', value: 'Guaratuba' },
  { label: 'Guarayos', value: 'Guarayos' },
  { label: 'Guarbecque', value: 'Guarbecque' },
  { label: 'Guarcino', value: 'Guarcino' },
  { label: 'Guarda', value: 'Guarda' },
  { label: 'Guarda A', value: 'Guarda A' },
  { label: 'Guarda Veneta', value: 'Guarda Veneta' },
  { label: 'Guardabosone', value: 'Guardabosone' },
  { label: 'Guardamar De La Safor', value: 'Guardamar De La Safor' },
  { label: 'Guardamar Del Segura', value: 'Guardamar Del Segura' },
  { label: 'Guardamiglio', value: 'Guardamiglio' },
  { label: 'Guarda-Mor', value: 'Guarda-Mor' },
  { label: 'Guardavalle', value: 'Guardavalle' },
  { label: 'Guardavalle Marina', value: 'Guardavalle Marina' },
  { label: 'Guardea', value: 'Guardea' },
  { label: 'Guardia De Jaén La', value: 'Guardia De Jaén La' },
  { label: 'Guardia La', value: 'Guardia La' },
  { label: 'Guardia Lombardi', value: 'Guardia Lombardi' },
  { label: 'Guardia Perticara', value: 'Guardia Perticara' },
  { label: 'Guardia Piemontese', value: 'Guardia Piemontese' },
  { label: 'Guardia Sanframondi', value: 'Guardia Sanframondi' },
  { label: 'Guardiagrele', value: 'Guardiagrele' },
  { label: 'Guardialfiera', value: 'Guardialfiera' },
  { label: 'Guardiaregia', value: 'Guardiaregia' },
  { label: 'Guardiola De Berguedà', value: 'Guardiola De Berguedà' },
  { label: 'Guardistallo', value: 'Guardistallo' },
  { label: 'Guardo', value: 'Guardo' },
  { label: 'Guareí', value: 'Guareí' },
  { label: 'Guareña', value: 'Guareña' },
  { label: 'Guarenas', value: 'Guarenas' },
  { label: 'Guarene', value: 'Guarene' },
  { label: 'Guariba', value: 'Guariba' },
  { label: 'Guaribas', value: 'Guaribas' },
  { label: 'Guarinos', value: 'Guarinos' },
  { label: 'Guarita', value: 'Guarita' },
  { label: 'Guarizama', value: 'Guarizama' },
  { label: 'Guarne', value: 'Guarne' },
  { label: 'Guaro', value: 'Guaro' },
  { label: 'Guarrate', value: 'Guarrate' },
  { label: 'Guarromán', value: 'Guarromán' },
  { label: 'Guarujá', value: 'Guarujá' },
  { label: 'Guarujá Do Sul', value: 'Guarujá Do Sul' },
  { label: 'Guarulhos', value: 'Guarulhos' },
  { label: 'Guarumal', value: 'Guarumal' },
  { label: 'Guarumal', value: 'Guarumal' },
  { label: 'Guasave', value: 'Guasave' },
  { label: 'Guasavito', value: 'Guasavito' },
  { label: 'Guasca', value: 'Guasca' },
  { label: 'Guasila', value: 'Guasila' },
  { label: 'Guásimas (De Belem)', value: 'Guásimas (De Belem)' },
  { label: 'Guastalla', value: 'Guastalla' },
  { label: 'Guastatoya', value: 'Guastatoya' },
  { label: 'Guasticce', value: 'Guasticce' },
  { label: 'Guata', value: 'Guata' },
  { label: 'Guatacalca', value: 'Guatacalca' },
  { label: 'Guatajiagua', value: 'Guatajiagua' },
  { label: 'Guatambú', value: 'Guatambú' },
  { label: 'Guatapanal', value: 'Guatapanal' },
  { label: 'Guatapará', value: 'Guatapará' },
  { label: 'Guatapé', value: 'Guatapé' },
  { label: 'Guataquí', value: 'Guataquí' },
  { label: 'Guatavita', value: 'Guatavita' },
  { label: 'Guatemala City', value: 'Guatemala City' },
  { label: 'Guateque', value: 'Guateque' },
  { label: 'Guática', value: 'Guática' },
  { label: 'Guatimapé', value: 'Guatimapé' },
  { label: 'Guatimozín', value: 'Guatimozín' },
  { label: 'Guatire', value: 'Guatire' },
  { label: 'Guatraché', value: 'Guatraché' },
  { label: 'Guatuso', value: 'Guatuso' },
  { label: 'Guavatá', value: 'Guavatá' },
  { label: 'Guaxupé', value: 'Guaxupé' },
  { label: 'Guayabal', value: 'Guayabal' },
  { label: 'Guayabal', value: 'Guayabal' },
  { label: 'Guayabal', value: 'Guayabal' },
  { label: 'Guayabal De Siquima', value: 'Guayabal De Siquima' },
  { label: 'Guayabetal', value: 'Guayabetal' },
  { label: 'Guayabito', value: 'Guayabito' },
  { label: 'Guayabito', value: 'Guayabito' },
  { label: 'Guayabitos', value: 'Guayabitos' },
  { label: 'Guayabo Dulce', value: 'Guayabo Dulce' },
  { label: 'Guayalejo', value: 'Guayalejo' },
  { label: 'Guayama', value: 'Guayama' },
  { label: 'Guayama', value: 'Guayama' },
  { label: 'Guayama Municipio', value: 'Guayama Municipio' },
  { label: 'Guayameo', value: 'Guayameo' },
  { label: 'Guayanilla', value: 'Guayanilla' },
  { label: 'Guayanilla', value: 'Guayanilla' },
  { label: 'Guayape', value: 'Guayape' },
  { label: 'Guayaquil', value: 'Guayaquil' },
  { label: 'Guayaramerín', value: 'Guayaramerín' },
  { label: 'Guayatá', value: 'Guayatá' },
  { label: 'Guayaybi', value: 'Guayaybi' },
  { label: 'Guaymango', value: 'Guaymango' },
  { label: 'Guaymate', value: 'Guaymate' },
  { label: 'Guaymitas', value: 'Guaymitas' },
  { label: 'Guaynabo', value: 'Guaynabo' },
  { label: 'Guaynabo', value: 'Guaynabo' },
  { label: 'Guayos', value: 'Guayos' },
  { label: 'Guaytalpa', value: 'Guaytalpa' },
  { label: 'Guayubín', value: 'Guayubín' },
  { label: 'Guaza De Campos', value: 'Guaza De Campos' },
  { label: 'Guazacapán', value: 'Guazacapán' },
  { label: 'Guazapa', value: 'Guazapa' },
  { label: 'Guazapares', value: 'Guazapares' },
  { label: 'Guazzino', value: 'Guazzino' },
  { label: 'Guazzora', value: 'Guazzora' },
  { label: 'Guba', value: 'Guba' },
  { label: 'Gubaan', value: 'Gubaan' },
  { label: 'Gubakha', value: 'Gubakha' },
  { label: 'Gubang', value: 'Gubang' },
  { label: 'Gubat', value: 'Gubat' },
  { label: 'Gubbi', value: 'Gubbi' },
  { label: 'Gubbio', value: 'Gubbio' },
  { label: 'Gubden', value: 'Gubden' },
  { label: 'Guben', value: 'Guben' },
  { label: 'Gubin', value: 'Gubin' },
  { label: 'Gubkin', value: 'Gubkin' },
  { label: 'Gubkinskiy', value: 'Gubkinskiy' },
  { label: 'Gubskaya', value: 'Gubskaya' },
  { label: 'Gubske', value: 'Gubske' },
  { label: 'Güce', value: 'Güce' },
  { label: 'Gucheng Chengguanzhen', value: 'Gucheng Chengguanzhen' },
  { label: 'Gückingen', value: 'Gückingen' },
  { label: 'Güçlükonak', value: 'Güçlükonak' },
  { label: 'Gudalur', value: 'Gudalur' },
  { label: 'Gúdar', value: 'Gúdar' },
  { label: 'Gudari', value: 'Gudari' },
  { label: 'Gudauri', value: 'Gudauri' },
  { label: 'Gudauta', value: 'Gudauta' },
  { label: 'Gudensberg', value: 'Gudensberg' },
  { label: 'Guderhandviertel', value: 'Guderhandviertel' },
  { label: 'Gudermes', value: 'Gudermes' },
  { label: 'Gudermesskiy Rayon', value: 'Gudermesskiy Rayon' },
  { label: 'Guderup', value: 'Guderup' },
  { label: 'Gudibanda', value: 'Gudibanda' },
  { label: 'Gudiña A', value: 'Gudiña A' },
  { label: 'Gudivada', value: 'Gudivada' },
  { label: 'Gudiyatham', value: 'Gudiyatham' },
  { label: 'Gudja', value: 'Gudja' },
  { label: 'Gudlavalleru', value: 'Gudlavalleru' },
  { label: 'Gudo Visconti', value: 'Gudo Visconti' },
  { label: 'Gudovac', value: 'Gudovac' },
  { label: 'Gudow', value: 'Gudow' },
  { label: 'Güdül', value: 'Güdül' },
  { label: 'Gudur', value: 'Gudur' },
  { label: 'Gudur', value: 'Gudur' },
  { label: 'Gudurica', value: 'Gudurica' },
  { label: 'Guduvancheri', value: 'Guduvancheri' },
  { label: 'Guebwiller', value: 'Guebwiller' },
  { label: 'Guécélard', value: 'Guécélard' },
  { label: 'Gueckedou', value: 'Gueckedou' },
  { label: 'Guédiawaye Department', value: 'Guédiawaye Department' },
  { label: 'Guégon', value: 'Guégon' },
  { label: 'Güejar Sierra', value: 'Güejar Sierra' },
  { label: 'Guelendeng', value: 'Guelendeng' },
  { label: 'Guelma', value: 'Guelma' },
  { label: 'Guelmim', value: 'Guelmim' },
  { label: 'Guelph', value: 'Guelph' },
  { label: 'Gueltat Zemmour', value: 'Gueltat Zemmour' },
  { label: 'Guémar', value: 'Guémar' },
  { label: 'Guémené-Penfao', value: 'Guémené-Penfao' },
  { label: 'Guémené-Sur-Scorff', value: 'Guémené-Sur-Scorff' },
  { label: 'Guemes', value: 'Guemes' },
  { label: 'Güémez', value: 'Güémez' },
  { label: 'Guémon', value: 'Guémon' },
  { label: 'Guénange', value: 'Guénange' },
  { label: 'Güeñes', value: 'Güeñes' },
  { label: 'Guengat', value: 'Guengat' },
  { label: 'Guénin', value: 'Guénin' },
  { label: 'Guenrouet', value: 'Guenrouet' },
  { label: 'Guéoul', value: 'Guéoul' },
  { label: 'Güepsa', value: 'Güepsa' },
  { label: 'Guer', value: 'Guer' },
  { label: 'Gueral', value: 'Gueral' },
  { label: 'Guérande', value: 'Guérande' },
  { label: 'Guérard', value: 'Guérard' },
  { label: 'Guercif', value: 'Guercif' },
  { label: 'Guercif Province', value: 'Guercif Province' },
  { label: 'Guéreins', value: 'Guéreins' },
  { label: 'Guéret', value: 'Guéret' },
  { label: 'Guérigny', value: 'Guérigny' },
  { label: 'Guerlesquin', value: 'Guerlesquin' },
  { label: 'Guermantes', value: 'Guermantes' },
  { label: 'Guern', value: 'Guern' },
  { label: 'Guerneville', value: 'Guerneville' },
  { label: 'Guernsey', value: 'Guernsey' },
  { label: 'Guernsey County', value: 'Guernsey County' },
  { label: 'Guerrero', value: 'Guerrero' },
  { label: 'Guerrero', value: 'Guerrero' },
  { label: 'Guerrero', value: 'Guerrero' },
  { label: 'Guerrero Negro', value: 'Guerrero Negro' },
  { label: 'Guerville', value: 'Guerville' },
  { label: 'Güesa/Gorza', value: 'Güesa/Gorza' },
  { label: 'Guesálaz/Gesalatz', value: 'Guesálaz/Gesalatz' },
  { label: 'Gueset', value: 'Gueset' },
  { label: 'Gueset', value: 'Gueset' },
  { label: 'Guesnain', value: 'Guesnain' },
  { label: 'Guesneau', value: 'Guesneau' },
  { label: 'Guéthary', value: 'Guéthary' },
  { label: 'Gueugnon', value: 'Gueugnon' },
  { label: 'Gueux', value: 'Gueux' },
  { label: 'Güevéjar', value: 'Güevéjar' },
  { label: 'Guewenheim', value: 'Guewenheim' },
  { label: 'Gueydan', value: 'Gueydan' },
  { label: 'Gufeng', value: 'Gufeng' },
  { label: 'Gug Qash', value: 'Gug Qash' },
  { label: 'Gugark’', value: 'Gugark’' },
  { label: 'Gugeşti', value: 'Gugeşti' },
  { label: 'Gugești', value: 'Gugești' },
  { label: 'Gugging', value: 'Gugging' },
  { label: 'Guggisberg', value: 'Guggisberg' },
  { label: 'Güglingen', value: 'Güglingen' },
  { label: 'Guglionesi', value: 'Guglionesi' },
  { label: 'Guhagar', value: 'Guhagar' },
  { label: 'Guia', value: 'Guia' },
  { label: 'Guia', value: 'Guia' },
  { label: 'Guía De Isora', value: 'Guía De Isora' },
  { label: 'Guia Lopes Da Laguna', value: 'Guia Lopes Da Laguna' },
  { label: 'Guiamets', value: 'Guiamets' },
  { label: 'Guibéroua', value: 'Guibéroua' },
  { label: 'Guibodangan', value: 'Guibodangan' },
  { label: 'Güicán', value: 'Güicán' },
  { label: 'Guichainville', value: 'Guichainville' },
  { label: 'Guichen', value: 'Guichen' },
  { label: 'Guichón', value: 'Guichón' },
  { label: 'Guiclan', value: 'Guiclan' },
  { label: 'Guidan Roumdji Department', value: 'Guidan Roumdji Department' },
  { label: 'Guiddam', value: 'Guiddam' },
  { label: 'Guiddam', value: 'Guiddam' },
  { label: 'Guidel-Plage', value: 'Guidel-Plage' },
  { label: 'Guider', value: 'Guider' },
  { label: 'Guidizzolo', value: 'Guidizzolo' },
  { label: 'Guidomandri Marina', value: 'Guidomandri Marina' },
  { label: 'Guidonia', value: 'Guidonia' },
  { label: 'Guidonia Montecelio', value: 'Guidonia Montecelio' },
  { label: 'Guidoval', value: 'Guidoval' },
  { label: 'Guifões', value: 'Guifões' },
  { label: 'Guigang', value: 'Guigang' },
  { label: 'Guiglia', value: 'Guiglia' },
  { label: 'Guiglo', value: 'Guiglo' },
  { label: 'Guignen', value: 'Guignen' },
  { label: 'Guignes', value: 'Guignes' },
  { label: 'Guignicourt', value: 'Guignicourt' },
  { label: 'Güigüe', value: 'Güigüe' },
  { label: 'Guiguinto', value: 'Guiguinto' },
  { label: 'Guiguinto', value: 'Guiguinto' },
  { label: 'Guihing Proper', value: 'Guihing Proper' },
  { label: 'Guihing Proper', value: 'Guihing Proper' },
  { label: 'Guihing Proper', value: 'Guihing Proper' },
  { label: 'Guihulñgan', value: 'Guihulñgan' },
  { label: 'Guijalo', value: 'Guijalo' },
  { label: 'Guijo De Ávila', value: 'Guijo De Ávila' },
  { label: 'Guijo De Coria', value: 'Guijo De Coria' },
  { label: 'Guijo De Galisteo', value: 'Guijo De Galisteo' },
  { label: 'Guijo De Granadilla', value: 'Guijo De Granadilla' },
  { label: 'Guijo De Santa Bárbara', value: 'Guijo De Santa Bárbara' },
  { label: 'Guijo El', value: 'Guijo El' },
  { label: 'Guijuelo', value: 'Guijuelo' },
  { label: 'Guilden Sutton', value: 'Guilden Sutton' },
  { label: 'Guildford', value: 'Guildford' },
  { label: 'Guildford', value: 'Guildford' },
  { label: 'Guildford West', value: 'Guildford West' },
  { label: 'Guildhall', value: 'Guildhall' },
  { label: 'Guilers', value: 'Guilers' },
  { label: 'Guilford', value: 'Guilford' },
  { label: 'Guilford', value: 'Guilford' },
  { label: 'Guilford Center', value: 'Guilford Center' },
  { label: 'Guilford County', value: 'Guilford County' },
  { label: 'Guilford Siding', value: 'Guilford Siding' },
  { label: 'Guilheiro', value: 'Guilheiro' },
  { label: 'Guilherand-Granges', value: 'Guilherand-Granges' },
  { label: 'Guilin', value: 'Guilin' },
  { label: 'Guilin Shi', value: 'Guilin Shi' },
  { label: 'Guiling', value: 'Guiling' },
  { label: 'Guiling', value: 'Guiling' },
  { label: 'Guiljungan', value: 'Guiljungan' },
  { label: 'Guillac', value: 'Guillac' },
  { label: 'Guillena', value: 'Guillena' },
  { label: 'Guillermo Prieto', value: 'Guillermo Prieto' },
  { label: 'Guillermo Zúñiga', value: 'Guillermo Zúñiga' },
  { label: 'Guillestre', value: 'Guillestre' },
  { label: 'Guilliers', value: 'Guilliers' },
  { label: 'Guilmaro', value: 'Guilmaro' },
  { label: 'Guilmi', value: 'Guilmi' },
  { label: 'Guils De Cerdanya', value: 'Guils De Cerdanya' },
  { label: 'Guilsfield', value: 'Guilsfield' },
  { label: 'Guilvinec', value: 'Guilvinec' },
  { label: 'Güimar', value: 'Güimar' },
  { label: 'Guimarães', value: 'Guimarães' },
  { label: 'Guimarães', value: 'Guimarães' },
  { label: 'Guimarânia', value: 'Guimarânia' },
  { label: 'Guimba', value: 'Guimba' },
  { label: 'Guimba', value: 'Guimba' },
  { label: 'Guimbal', value: 'Guimbal' },
  { label: 'Guimerà', value: 'Guimerà' },
  { label: 'Guin', value: 'Guin' },
  { label: 'Guinabsan', value: 'Guinabsan' },
  { label: 'Guinabsan', value: 'Guinabsan' },
  { label: 'Guinacotan', value: 'Guinacotan' },
  { label: 'Guinayangan Fourth District Of Quezon', value: 'Guinayangan Fourth District Of Quezon' },
  { label: 'Guindapunan', value: 'Guindapunan' },
  { label: 'Guindapunan', value: 'Guindapunan' },
  { label: 'Guindarohan', value: 'Guindarohan' },
  { label: 'Guindulman', value: 'Guindulman' },
  { label: 'Guînes', value: 'Guînes' },
  { label: 'Güines', value: 'Güines' },
  { label: 'Guingamp', value: 'Guingamp' },
  { label: 'Guingueta Dàneu La', value: 'Guingueta Dàneu La' },
  { label: 'Guinguinéo', value: 'Guinguinéo' },
  { label: 'Guiniculalay', value: 'Guiniculalay' },
  { label: 'Guinisiliban', value: 'Guinisiliban' },
  { label: 'Guinlo', value: 'Guinlo' },
  { label: 'Guinoaliuan', value: 'Guinoaliuan' },
  { label: 'Guinobatan', value: 'Guinobatan' },
  { label: 'Güinope', value: 'Güinope' },
  { label: 'Guinsadan', value: 'Guinsadan' },
  { label: 'Guinsadan', value: 'Guinsadan' },
  { label: 'Guinsang-An', value: 'Guinsang-An' },
  { label: 'Guinsang-An', value: 'Guinsang-An' },
  { label: 'Guinticgan', value: 'Guinticgan' },
  { label: 'Guintubhan', value: 'Guintubhan' },
  { label: 'Guinzano', value: 'Guinzano' },
  { label: 'Guiong', value: 'Guiong' },
  { label: 'Guiong', value: 'Guiong' },
  { label: 'Guipavas', value: 'Guipavas' },
  { label: 'Guipel', value: 'Guipel' },
  { label: 'Guiping', value: 'Guiping' },
  { label: 'Guipos', value: 'Guipos' },
  { label: 'Guipry', value: 'Guipry' },
  { label: 'Güira De Melena', value: 'Güira De Melena' },
  { label: 'Guirang', value: 'Guirang' },
  { label: 'Guirang', value: 'Guirang' },
  { label: 'Guiratinga', value: 'Guiratinga' },
  { label: 'Guiren', value: 'Guiren' },
  { label: 'Guirguillano', value: 'Guirguillano' },
  { label: 'Güiria', value: 'Güiria' },
  { label: 'Guiricema', value: 'Guiricema' },
  { label: 'Guirim', value: 'Guirim' },
  { label: 'Guisa', value: 'Guisa' },
  { label: 'Guisande', value: 'Guisande' },
  { label: 'Guisando', value: 'Guisando' },
  { label: 'Guisborough', value: 'Guisborough' },
  { label: 'Guiscard', value: 'Guiscard' },
  { label: 'Guiscriff', value: 'Guiscriff' },
  { label: 'Guise', value: 'Guise' },
  { label: 'Guiseley', value: 'Guiseley' },
  { label: 'Guiset East', value: 'Guiset East' },
  { label: 'Guiset East', value: 'Guiset East' },
  { label: 'Guisguis', value: 'Guisguis' },
  { label: 'Guisguis', value: 'Guisguis' },
  { label: 'Guisguis', value: 'Guisguis' },
  { label: 'Guisijan', value: 'Guisijan' },
  { label: 'Guissény', value: 'Guissény' },
  { label: 'Guissona', value: 'Guissona' },
  { label: 'Guitiriz', value: 'Guitiriz' },
  { label: 'Guîtres', value: 'Guîtres' },
  { label: 'Guiuan', value: 'Guiuan' },
  { label: 'Guiuan', value: 'Guiuan' },
  { label: 'Guiwanon', value: 'Guiwanon' },
  { label: 'Guixé', value: 'Guixé' },
  { label: 'Guixers', value: 'Guixers' },
  { label: 'Guixi', value: 'Guixi' },
  { label: 'Guiyang', value: 'Guiyang' },
  { label: 'Gujak', value: 'Gujak' },
  { label: 'Gujan', value: 'Gujan' },
  { label: 'Gujan-Mestras', value: 'Gujan-Mestras' },
  { label: 'Gujar Khan', value: 'Gujar Khan' },
  { label: 'Gujba', value: 'Gujba' },
  { label: 'Guji Zone', value: 'Guji Zone' },
  { label: 'Gujō', value: 'Gujō' },
  { label: 'Gujō-Shi', value: 'Gujō-Shi' },
  { label: 'Gujranwala', value: 'Gujranwala' },
  { label: 'Gujranwala Division', value: 'Gujranwala Division' },
  { label: 'Gujrat', value: 'Gujrat' },
  { label: 'Gukovo', value: 'Gukovo' },
  { label: 'Gul’Kevichi', value: 'Gul’Kevichi' },
  { label: 'Gulabpura', value: 'Gulabpura' },
  { label: 'Gülağaç', value: 'Gülağaç' },
  { label: 'Gulaothi', value: 'Gulaothi' },
  { label: 'Gulbene', value: 'Gulbene' },
  { label: 'Guldborgsund Kommune', value: 'Guldborgsund Kommune' },
  { label: 'Gulden Bodem', value: 'Gulden Bodem' },
  { label: 'Guledagudda', value: 'Guledagudda' },
  { label: 'Gulen', value: 'Gulen' },
  { label: 'Gulf Breeze', value: 'Gulf Breeze' },
  { label: 'Gulf County', value: 'Gulf County' },
  { label: 'Gulf Gate Estates', value: 'Gulf Gate Estates' },
  { label: 'Gulf Hills', value: 'Gulf Hills' },
  { label: 'Gulf Park Estates', value: 'Gulf Park Estates' },
  { label: 'Gulf Shores', value: 'Gulf Shores' },
  { label: 'Gulfport', value: 'Gulfport' },
  { label: 'Gulfport', value: 'Gulfport' },
  { label: 'Gulfview Heights', value: 'Gulfview Heights' },
  { label: 'Gulgong', value: 'Gulgong' },
  { label: 'Guli', value: 'Guli' },
  { label: 'Gulia', value: 'Gulia' },
  { label: 'Gulia', value: 'Gulia' },
  { label: 'Gulishah Kach', value: 'Gulishah Kach' },
  { label: 'Guliston', value: 'Guliston' },
  { label: 'Gulivoire Park', value: 'Gulivoire Park' },
  { label: 'Gullane', value: 'Gullane' },
  { label: 'Gullbrandstorp', value: 'Gullbrandstorp' },
  { label: 'Gullhaug', value: 'Gullhaug' },
  { label: 'Gulliver', value: 'Gulliver' },
  { label: 'Gullspång', value: 'Gullspång' },
  { label: 'Gulma', value: 'Gulma' },
  { label: 'Gulmarrad', value: 'Gulmarrad' },
  { label: 'Gulmi ', value: 'Gulmi ' },
  { label: 'Gülnar', value: 'Gülnar' },
  { label: 'Gulod', value: 'Gulod' },
  { label: 'Gulpen', value: 'Gulpen' },
  { label: 'Gulpen-Wittem', value: 'Gulpen-Wittem' },
  { label: 'Gulpilhares', value: 'Gulpilhares' },
  { label: 'Gülşehir', value: 'Gülşehir' },
  { label: 'Gulsk', value: 'Gulsk' },
  { label: 'Gulu', value: 'Gulu' },
  { label: 'Gŭlŭbovo', value: 'Gŭlŭbovo' },
  { label: 'Gulvand', value: 'Gulvand' },
  { label: 'Gülyalı', value: 'Gülyalı' },
  { label: 'Gulyantsi', value: 'Gulyantsi' },
  { label: 'Gülzow', value: 'Gülzow' },
  { label: 'Gumaca', value: 'Gumaca' },
  { label: 'Gumalang', value: 'Gumalang' },
  { label: 'Gumalang', value: 'Gumalang' },
  { label: 'Gumalang', value: 'Gumalang' },
  { label: 'Gumaus', value: 'Gumaus' },
  { label: 'Gumbetovskiy Rayon', value: 'Gumbetovskiy Rayon' },
  { label: 'Gumböle', value: 'Gumböle' },
  { label: 'Gumbrechtshoffen', value: 'Gumbrechtshoffen' },
  { label: 'Gumdag', value: 'Gumdag' },
  { label: 'Gumdale', value: 'Gumdale' },
  { label: 'Gumel', value: 'Gumel' },
  { label: 'Gumenniki', value: 'Gumenniki' },
  { label: 'Gumia', value: 'Gumia' },
  { label: 'Gumian', value: 'Gumian' },
  { label: 'Gumiel De Izán', value: 'Gumiel De Izán' },
  { label: 'Gumiel De Mercado', value: 'Gumiel De Mercado' },
  { label: 'Gumine', value: 'Gumine' },
  { label: 'Gumi-Si', value: 'Gumi-Si' },
  { label: 'Gumla', value: 'Gumla' },
  { label: 'Gumlog', value: 'Gumlog' },
  { label: 'Gummersbach', value: 'Gummersbach' },
  { label: 'Gummi', value: 'Gummi' },
  { label: 'Gummidipundi', value: 'Gummidipundi' },
  { label: 'Gumpoldskirchen', value: 'Gumpoldskirchen' },
  { label: 'Gumtow', value: 'Gumtow' },
  { label: 'Gümüşhacıköy', value: 'Gümüşhacıköy' },
  { label: 'Gümüşova', value: 'Gümüşova' },
  { label: 'Gun Barrel City', value: 'Gun Barrel City' },
  { label: 'Guna', value: 'Guna' },
  { label: 'Gunbalanya', value: 'Gunbalanya' },
  { label: 'Gunbarrel', value: 'Gunbarrel' },
  { label: 'Gundagai', value: 'Gundagai' },
  { label: 'Gundaroo', value: 'Gundaroo' },
  { label: 'Gundelen', value: 'Gundelen' },
  { label: 'Gundelfingen', value: 'Gundelfingen' },
  { label: 'Gundelfingen', value: 'Gundelfingen' },
  { label: 'Gundelsheim', value: 'Gundelsheim' },
  { label: 'Gundelsheim', value: 'Gundelsheim' },
  { label: 'Gundersdorf', value: 'Gundersdorf' },
  { label: 'Gundersheim', value: 'Gundersheim' },
  { label: 'Gundershoffen', value: 'Gundershoffen' },
  { label: 'Gundinci', value: 'Gundinci' },
  { label: 'Gundlupet', value: 'Gundlupet' },
  { label: 'Gündoğmuş', value: 'Gündoğmuş' },
  { label: 'Gundorovskiy', value: 'Gundorovskiy' },
  { label: 'Gundremmingen', value: 'Gundremmingen' },
  { label: 'Gundsømagle', value: 'Gundsømagle' },
  { label: 'Gunestan', value: 'Gunestan' },
  { label: 'Güney', value: 'Güney' },
  { label: 'Güneysınır', value: 'Güneysınır' },
  { label: 'Güneysu', value: 'Güneysu' },
  { label: 'Gungahlin', value: 'Gungahlin' },
  { label: 'Güngören', value: 'Güngören' },
  { label: 'Gunib', value: 'Gunib' },
  { label: 'Gunibskiy Rayon', value: 'Gunibskiy Rayon' },
  { label: 'Gunja', value: 'Gunja' },
  { label: 'Gunjur', value: 'Gunjur' },
  { label: 'Gunjur', value: 'Gunjur' },
  { label: 'Gunjur Kuta', value: 'Gunjur Kuta' },
  { label: 'Gunn', value: 'Gunn' },
  { label: 'Gunnaur', value: 'Gunnaur' },
  { label: 'Gunnebo', value: 'Gunnebo' },
  { label: 'Gunnedah', value: 'Gunnedah' },
  { label: 'Gunness', value: 'Gunness' },
  { label: 'Gunnislake', value: 'Gunnislake' },
  { label: 'Gunnison', value: 'Gunnison' },
  { label: 'Gunnison', value: 'Gunnison' },
  { label: 'Gunnison County', value: 'Gunnison County' },
  { label: 'Gunpo-Si', value: 'Gunpo-Si' },
  { label: 'Gunsan', value: 'Gunsan' },
  { label: 'Gunsan-Si', value: 'Gunsan-Si' },
  { label: 'Günselsdorf', value: 'Günselsdorf' },
  { label: 'Gunskirchen', value: 'Gunskirchen' },
  { label: 'Guntakal Junction', value: 'Guntakal Junction' },
  { label: 'Gunter', value: 'Gunter' },
  { label: 'Guntersblum', value: 'Guntersblum' },
  { label: 'Guntersdorf', value: 'Guntersdorf' },
  { label: 'Güntersleben', value: 'Güntersleben' },
  { label: 'Guntersville', value: 'Guntersville' },
  { label: 'Günthersdorf', value: 'Günthersdorf' },
  { label: 'Guntín', value: 'Guntín' },
  { label: 'Guntown', value: 'Guntown' },
  { label: 'Guntramsdorf', value: 'Guntramsdorf' },
  { label: 'Guntur', value: 'Guntur' },
  { label: 'Gunung Kendil', value: 'Gunung Kendil' },
  { label: 'Gunungsitoli', value: 'Gunungsitoli' },
  { label: 'Gunupur', value: 'Gunupur' },
  { label: 'Gunwi-Gun', value: 'Gunwi-Gun' },
  { label: 'Gunyo Poniente (San José Gunyo)', value: 'Gunyo Poniente (San José Gunyo)' },
  { label: 'Günyüzü', value: 'Günyüzü' },
  { label: 'Günzach', value: 'Günzach' },
  { label: 'Günzburg', value: 'Günzburg' },
  { label: 'Gunzenhausen', value: 'Gunzenhausen' },
  { label: 'Gunzgen', value: 'Gunzgen' },
  { label: 'Gunzwil', value: 'Gunzwil' },
  { label: 'Guofuchang', value: 'Guofuchang' },
  { label: 'Guozhen', value: 'Guozhen' },
  { label: 'Gupitan', value: 'Gupitan' },
  { label: 'Gupitan', value: 'Gupitan' },
  { label: 'Gupitan', value: 'Gupitan' },
  { label: 'Gur Gaz', value: 'Gur Gaz' },
  { label: 'Gur’Yevsk', value: 'Gur’Yevsk' },
  { label: 'Gur’Yevsk', value: 'Gur’Yevsk' },
  { label: 'Gura Beliei', value: 'Gura Beliei' },
  { label: 'Gura Caliţei', value: 'Gura Caliţei' },
  { label: 'Gura Căluiu', value: 'Gura Căluiu' },
  { label: 'Gura Foii', value: 'Gura Foii' },
  { label: 'Gura Humorului', value: 'Gura Humorului' },
  { label: 'Gura Ialomiței', value: 'Gura Ialomiței' },
  { label: 'Gura Ocniței', value: 'Gura Ocniței' },
  { label: 'Gura Padinii', value: 'Gura Padinii' },
  { label: 'Gura Putnei', value: 'Gura Putnei' },
  { label: 'Gura Râului', value: 'Gura Râului' },
  { label: 'Gura Suhașului', value: 'Gura Suhașului' },
  { label: 'Gura Şuţii', value: 'Gura Şuţii' },
  { label: 'Gura Teghii', value: 'Gura Teghii' },
  { label: 'Gura Vadului', value: 'Gura Vadului' },
  { label: 'Gura Văii', value: 'Gura Văii' },
  { label: 'Gura Văii', value: 'Gura Văii' },
  { label: 'Gura Viţioarei', value: 'Gura Viţioarei' },
  { label: 'Gura Vulcanei', value: 'Gura Vulcanei' },
  { label: 'Gurab Zarmikh', value: 'Gurab Zarmikh' },
  { label: 'Gurabo', value: 'Gurabo' },
  { label: 'Gurabo', value: 'Gurabo' },
  { label: 'Gurabo Municipio', value: 'Gurabo Municipio' },
  { label: 'Guraghe Zone', value: 'Guraghe Zone' },
  { label: 'Gurahonţ', value: 'Gurahonţ' },
  { label: 'Guraidhoo', value: 'Guraidhoo' },
  { label: 'Guran', value: 'Guran' },
  { label: 'Gurasada', value: 'Gurasada' },
  { label: 'Gurb', value: 'Gurb' },
  { label: 'Gurba', value: 'Gurba' },
  { label: 'Gurbăneşti', value: 'Gurbăneşti' },
  { label: 'Gurbediu', value: 'Gurbediu' },
  { label: 'Gurbuki', value: 'Gurbuki' },
  { label: 'Gurdaha', value: 'Gurdaha' },
  { label: 'Gurdaspur', value: 'Gurdaspur' },
  { label: 'Gurdon', value: 'Gurdon' },
  { label: 'Gurgaon', value: 'Gurgaon' },
  { label: 'Gürgentepe', value: 'Gürgentepe' },
  { label: 'Gurghiu', value: 'Gurghiu' },
  { label: 'Gurgurnica', value: 'Gurgurnica' },
  { label: 'Gurgy', value: 'Gurgy' },
  { label: 'Gurh', value: 'Gurh' },
  { label: 'Guriezo', value: 'Guriezo' },
  { label: 'Gurinhatã', value: 'Gurinhatã' },
  { label: 'Gurinhém', value: 'Gurinhém' },
  { label: 'Guri-Si', value: 'Guri-Si' },
  { label: 'Guriyeh', value: 'Guriyeh' },
  { label: 'Gurjaani', value: 'Gurjaani' },
  { label: 'Gurjão', value: 'Gurjão' },
  { label: 'Gurjiu', value: 'Gurjiu' },
  { label: 'Gurk', value: 'Gurk' },
  { label: 'Gurkovo', value: 'Gurkovo' },
  { label: 'Gurlan', value: 'Gurlan' },
  { label: 'Gurmatkal', value: 'Gurmatkal' },
  { label: 'Gurnee', value: 'Gurnee' },
  { label: 'Guro-Gu', value: 'Guro-Gu' },
  { label: 'Güroymak', value: 'Güroymak' },
  { label: 'Gürpınar', value: 'Gürpınar' },
  { label: 'Gurrea De Gállego', value: 'Gurrea De Gállego' },
  { label: 'Gurro', value: 'Gurro' },
  { label: 'Gursahaiganj', value: 'Gursahaiganj' },
  { label: 'Gursarai', value: 'Gursarai' },
  { label: 'Gürsu', value: 'Gürsu' },
  { label: 'Guru Har Sahai', value: 'Guru Har Sahai' },
  { label: 'Guruieni', value: 'Guruieni' },
  { label: 'Gurun', value: 'Gurun' },
  { label: 'Gürün', value: 'Gürün' },
  { label: 'Gurupá', value: 'Gurupá' },
  { label: 'Gurupi', value: 'Gurupi' },
  { label: 'Guruvayur', value: 'Guruvayur' },
  { label: 'Guruve District', value: 'Guruve District' },
  { label: 'Guruyan', value: 'Guruyan' },
  { label: 'Gurye-Gun', value: 'Gurye-Gun' },
  { label: 'Gurzuf', value: 'Gurzuf' },
  { label: 'Gus’-Khrustal’Nyy', value: 'Gus’-Khrustal’Nyy' },
  { label: 'Gus’-Zheleznyy', value: 'Gus’-Zheleznyy' },
  { label: 'Gusau', value: 'Gusau' },
  { label: 'Gusenburg', value: 'Gusenburg' },
  { label: 'Gusendos De Los Oteros', value: 'Gusendos De Los Oteros' },
  { label: 'Gusev', value: 'Gusev' },
  { label: 'Gusevskiy', value: 'Gusevskiy' },
  { label: 'Gushalan', value: 'Gushalan' },
  { label: 'Gusheh-Ye Kasavand', value: 'Gusheh-Ye Kasavand' },
  { label: 'Gushikawa', value: 'Gushikawa' },
  { label: 'Gushu', value: 'Gushu' },
  { label: 'Gusinje', value: 'Gusinje' },
  { label: 'Gusinoozyorsk', value: 'Gusinoozyorsk' },
  { label: 'Gusinoye Ozero', value: 'Gusinoye Ozero' },
  { label: 'Guskhara', value: 'Guskhara' },
  { label: 'Guşoeni', value: 'Guşoeni' },
  { label: 'Guspini', value: 'Guspini' },
  { label: 'Gussago', value: 'Gussago' },
  { label: 'Güssing', value: 'Güssing' },
  { label: 'Gussola', value: 'Gussola' },
  { label: 'Gustavo A. Madero', value: 'Gustavo A. Madero' },
  { label: 'Gustavo Adolfo Madero', value: 'Gustavo Adolfo Madero' },
  { label: 'Gustavo Díaz Ordaz', value: 'Gustavo Díaz Ordaz' },
  { label: 'Gustavo Díaz Ordaz', value: 'Gustavo Díaz Ordaz' },
  { label: 'Gustavsberg', value: 'Gustavsberg' },
  { label: 'Güsten', value: 'Güsten' },
  { label: 'Güster', value: 'Güster' },
  { label: 'Gusterath', value: 'Gusterath' },
  { label: 'Gustine', value: 'Gustine' },
  { label: 'Güstrow', value: 'Güstrow' },
  { label: 'Gusum', value: 'Gusum' },
  { label: 'Gutach', value: 'Gutach' },
  { label: 'Gutach Im Breisgau', value: 'Gutach Im Breisgau' },
  { label: 'Gutad', value: 'Gutad' },
  { label: 'Gutalac', value: 'Gutalac' },
  { label: 'Gutao', value: 'Gutao' },
  { label: 'Guta-Potiyivka', value: 'Guta-Potiyivka' },
  { label: 'Gutau', value: 'Gutau' },
  { label: 'Gütenbach', value: 'Gütenbach' },
  { label: 'Gutenberg', value: 'Gutenberg' },
  { label: 'Gutenberg', value: 'Gutenberg' },
  { label: 'Gutenbrunn', value: 'Gutenbrunn' },
  { label: 'Gutenstein', value: 'Gutenstein' },
  { label: 'Gutenstetten', value: 'Gutenstetten' },
  { label: 'Gutenswil', value: 'Gutenswil' },
  { label: 'Gutenzell-Hürbel', value: 'Gutenzell-Hürbel' },
  { label: 'Gütersloh', value: 'Gütersloh' },
  { label: 'Guthrie', value: 'Guthrie' },
  { label: 'Guthrie', value: 'Guthrie' },
  { label: 'Guthrie', value: 'Guthrie' },
  { label: 'Guthrie Center', value: 'Guthrie Center' },
  { label: 'Guthrie County', value: 'Guthrie County' },
  { label: 'Gutierre-Muñoz', value: 'Gutierre-Muñoz' },
  { label: 'Gutiérrez', value: 'Gutiérrez' },
  { label: 'Gutiérrez Zamora', value: 'Gutiérrez Zamora' },
  { label: 'Guto-Mariatin', value: 'Guto-Mariatin' },
  { label: 'Guttaring', value: 'Guttaring' },
  { label: 'Guttau', value: 'Guttau' },
  { label: 'Guttecoven', value: 'Guttecoven' },
  { label: 'Güttenbach', value: 'Güttenbach' },
  { label: 'Guttenberg', value: 'Guttenberg' },
  { label: 'Guttenberg', value: 'Guttenberg' },
  { label: 'Güttingen', value: 'Güttingen' },
  { label: 'Gutu District', value: 'Gutu District' },
  { label: 'Gützkow', value: 'Gützkow' },
  { label: 'Guwahati', value: 'Guwahati' },
  { label: 'Guxhagen', value: 'Guxhagen' },
  { label: 'Guyam Malaki', value: 'Guyam Malaki' },
  { label: 'Guyancourt', value: 'Guyancourt' },
  { label: 'Guye', value: 'Guye' },
  { label: 'Guymon', value: 'Guymon' },
  { label: 'Guyong', value: 'Guyong' },
  { label: 'Guyong', value: 'Guyong' },
  { label: 'Guyra', value: 'Guyra' },
  { label: 'Guys Hill', value: 'Guys Hill' },
  { label: 'Guyton', value: 'Guyton' },
  { label: 'Güzelbahçe', value: 'Güzelbahçe' },
  { label: 'Güzelyurt', value: 'Güzelyurt' },
  { label: 'Guzman', value: 'Guzman' },
  { label: 'Guzolândia', value: 'Guzolândia' },
  { label: 'Guzów', value: 'Guzów' },
  { label: 'Gvardeysk', value: 'Gvardeysk' },
  { label: 'Gvardinița', value: 'Gvardinița' },
  { label: 'Gvarv', value: 'Gvarv' },
  { label: 'Gvozd', value: 'Gvozd' },
  { label: 'Gwacheon-Si', value: 'Gwacheon-Si' },
  { label: 'Gwadabawa', value: 'Gwadabawa' },
  { label: 'Gwadar', value: 'Gwadar' },
  { label: 'Gwagwalada', value: 'Gwagwalada' },
  { label: 'Gwalior', value: 'Gwalior' },
  { label: 'Gwanak-Gu', value: 'Gwanak-Gu' },
  { label: 'Gwanda', value: 'Gwanda' },
  { label: 'Gwanda District', value: 'Gwanda District' },
  { label: 'Gwandalan', value: 'Gwandalan' },
  { label: 'Gwandu', value: 'Gwandu' },
  { label: 'Gwangjin-Gu', value: 'Gwangjin-Gu' },
  { label: 'Gwangju', value: 'Gwangju' },
  { label: 'Gwangju', value: 'Gwangju' },
  { label: 'Gwangju-Si', value: 'Gwangju-Si' },
  { label: 'Gwangmyeong-Si', value: 'Gwangmyeong-Si' },
  { label: 'Gwangsan-Gu', value: 'Gwangsan-Gu' },
  { label: 'Gwangyang', value: 'Gwangyang' },
  { label: 'Gwangyang-Si', value: 'Gwangyang-Si' },
  { label: 'Gwaram', value: 'Gwaram' },
  { label: 'Gwaram', value: 'Gwaram' },
  { label: 'Gwarinpa', value: 'Gwarinpa' },
  { label: 'Gwasero', value: 'Gwasero' },
  { label: 'Gweedore', value: 'Gweedore' },
  { label: 'Gwelup', value: 'Gwelup' },
  { label: 'Gwembe', value: 'Gwembe' },
  { label: 'Gweru', value: 'Gweru' },
  { label: 'Gweru District', value: 'Gweru District' },
  { label: 'Gweta', value: 'Gweta' },
  { label: 'Gwinn', value: 'Gwinn' },
  { label: 'Gwinnett County', value: 'Gwinnett County' },
  { label: 'Gwio Kura', value: 'Gwio Kura' },
  { label: 'Gwoza', value: 'Gwoza' },
  { label: 'Gwoźnica Górna', value: 'Gwoźnica Górna' },
  { label: 'Gwydir', value: 'Gwydir' },
  { label: 'Gwynedd', value: 'Gwynedd' },
  { label: 'Gwynneville', value: 'Gwynneville' },
  { label: 'Gy', value: 'Gy' },
  { label: 'Gyál', value: 'Gyál' },
  { label: 'Gyáli Járás', value: 'Gyáli Járás' },
  { label: 'Gyalshing', value: 'Gyalshing' },
  { label: 'Gyanpur', value: 'Gyanpur' },
  { label: 'Gyenesdiás', value: 'Gyenesdiás' },
  { label: 'Gyeongju', value: 'Gyeongju' },
  { label: 'Gyeongsan-Si', value: 'Gyeongsan-Si' },
  { label: 'Gyermely', value: 'Gyermely' },
  { label: 'Gyeryong-Si', value: 'Gyeryong-Si' },
  { label: 'Gyhum', value: 'Gyhum' },
  { label: 'Gymea', value: 'Gymea' },
  { label: 'Gymea Bay', value: 'Gymea Bay' },
  { label: 'Gympie', value: 'Gympie' },
  { label: 'Gympie Regional Council', value: 'Gympie Regional Council' },
  { label: 'Gyōda', value: 'Gyōda' },
  { label: 'Gyomaendrőd', value: 'Gyomaendrőd' },
  { label: 'Gyomaendrődi Járás', value: 'Gyomaendrődi Járás' },
  { label: 'Gyömrő', value: 'Gyömrő' },
  { label: 'Gyöngyös', value: 'Gyöngyös' },
  { label: 'Gyöngyöshalász', value: 'Gyöngyöshalász' },
  { label: 'Gyöngyösi Járás', value: 'Gyöngyösi Járás' },
  { label: 'Gyöngyöspata', value: 'Gyöngyöspata' },
  { label: 'Gyöngyössolymos', value: 'Gyöngyössolymos' },
  { label: 'Gyöngyöstarján', value: 'Gyöngyöstarján' },
  { label: 'Gyönk', value: 'Gyönk' },
  { label: 'Győr', value: 'Győr' },
  { label: 'Győri Járás', value: 'Győri Járás' },
  { label: 'Győrszemere', value: 'Győrszemere' },
  { label: 'Győrújbarát', value: 'Győrújbarát' },
  { label: 'Gyovren', value: 'Gyovren' },
  { label: 'Gypsum', value: 'Gypsum' },
  { label: 'Gýtheio', value: 'Gýtheio' },
  { label: 'Gytsjerk', value: 'Gytsjerk' },
  { label: 'Gyula', value: 'Gyula' },
  { label: 'Gyulagarak', value: 'Gyulagarak' },
  { label: 'Gyulaháza', value: 'Gyulaháza' },
  { label: 'Gyulai Járás', value: 'Gyulai Járás' },
  { label: 'Gyumri', value: 'Gyumri' },
  { label: 'Gyuzdek', value: 'Gyuzdek' },
  { label: 'Gzhel’', value: 'Gzhel’' },
  { label: 'Gżira', value: 'Gżira' },
  { label: 'Gzy', value: 'Gzy' },
  { label: 'H. Rivera Colon', value: 'H. Rivera Colon' },
  { label: 'Ha', value: 'Ha' },
  { label: 'Hå', value: 'Hå' },
  { label: 'Hà Đông', value: 'Hà Đông' },
  { label: 'Hà Giang', value: 'Hà Giang' },
  { label: 'Hạ Long', value: 'Hạ Long' },
  { label: 'Hà Tiên', value: 'Hà Tiên' },
  { label: 'Hà Tĩnh', value: 'Hà Tĩnh' },
  { label: 'Haabneeme', value: 'Haabneeme' },
  { label: 'Haacht', value: 'Haacht' },
  { label: 'Haaften', value: 'Haaften' },
  { label: 'Haag', value: 'Haag' },
  { label: 'Haag', value: 'Haag' },
  { label: 'Haag (Rheintal)', value: 'Haag (Rheintal)' },
  { label: 'Haag Am Hausruck', value: 'Haag Am Hausruck' },
  { label: 'Haag An Der Amper', value: 'Haag An Der Amper' },
  { label: 'Haag In Oberbayern', value: 'Haag In Oberbayern' },
  { label: 'Haakon County', value: 'Haakon County' },
  { label: 'Haaksbergen', value: 'Haaksbergen' },
  { label: 'Haalderen', value: 'Haalderen' },
  { label: 'Haaltert', value: 'Haaltert' },
  { label: 'Haamstede', value: 'Haamstede' },
  { label: 'Haan', value: 'Haan' },
  { label: 'Haanrade', value: 'Haanrade' },
  { label: 'Haapajärvi', value: 'Haapajärvi' },
  { label: 'Haapavesi', value: 'Haapavesi' },
  { label: 'Haapsalu', value: 'Haapsalu' },
  { label: 'Haapsalu Linn', value: 'Haapsalu Linn' },
  { label: 'Haar', value: 'Haar' },
  { label: 'Haarbach', value: 'Haarbach' },
  { label: 'Haaren', value: 'Haaren' },
  { label: 'Haarle', value: 'Haarle' },
  { label: 'Haarlem', value: 'Haarlem' },
  { label: 'Haarlemmerliede', value: 'Haarlemmerliede' },
  { label: 'Haarlemmermeer', value: 'Haarlemmermeer' },
  { label: 'Haarooni', value: 'Haarooni' },
  { label: 'Haarsteeg', value: 'Haarsteeg' },
  { label: 'Haastrecht', value: 'Haastrecht' },
  { label: 'Haba La', value: 'Haba La' },
  { label: 'Habach', value: 'Habach' },
  { label: 'Habach', value: 'Habach' },
  { label: 'Habana Del Este', value: 'Habana Del Este' },
  { label: 'Habartov', value: 'Habartov' },
  { label: 'Habas', value: 'Habas' },
  { label: 'Habay-La-Vieille', value: 'Habay-La-Vieille' },
  { label: 'Habban', value: 'Habban' },
  { label: 'Habboûch', value: 'Habboûch' },
  { label: 'Hăbeni', value: 'Hăbeni' },
  { label: 'Haberfield', value: 'Haberfield' },
  { label: 'Habersham County', value: 'Habersham County' },
  { label: 'Habibabad', value: 'Habibabad' },
  { label: 'Habibiya', value: 'Habibiya' },
  { label: 'Habic', value: 'Habic' },
  { label: 'Habiganj ', value: 'Habiganj ' },
  { label: 'Habikino-Shi', value: 'Habikino-Shi' },
  { label: 'Habil Jabr', value: 'Habil Jabr' },
  { label: 'Habo', value: 'Habo' },
  { label: 'Habra', value: 'Habra' },
  { label: 'Habry', value: 'Habry' },
  { label: 'Habsheim', value: 'Habsheim' },
  { label: 'Habur Zulaymah', value: 'Habur Zulaymah' },
  { label: 'Hacarí', value: 'Hacarí' },
  { label: 'Hachenburg', value: 'Hachenburg' },
  { label: 'Hachimantai', value: 'Hachimantai' },
  { label: 'Hachimantai Shi', value: 'Hachimantai Shi' },
  { label: 'Hachinohe', value: 'Hachinohe' },
  { label: 'Hachinohe Shi', value: 'Hachinohe Shi' },
  { label: 'Hachiōji', value: 'Hachiōji' },
  { label: 'Hachiroud', value: 'Hachiroud' },
  { label: 'Hacı Zeynalabdin', value: 'Hacı Zeynalabdin' },
  { label: 'Hacıbektaş', value: 'Hacıbektaş' },
  { label: 'Hacienda', value: 'Hacienda' },
  { label: 'Hacienda', value: 'Hacienda' },
  { label: 'Hacienda Arriba', value: 'Hacienda Arriba' },
  { label: 'Hacienda Blanca', value: 'Hacienda Blanca' },
  { label: 'Hacienda De Cabañas', value: 'Hacienda De Cabañas' },
  { label: 'Hacienda De Enmedio', value: 'Hacienda De Enmedio' },
  { label: 'Hacienda De La Bolsa', value: 'Hacienda De La Bolsa' },
  { label: 'Hacienda De La Labor', value: 'Hacienda De La Labor' },
  { label: 'Hacienda De Márquez', value: 'Hacienda De Márquez' },
  { label: 'Hacienda Heights', value: 'Hacienda Heights' },
  { label: 'Hacienda Huancane', value: 'Hacienda Huancane' },
  { label: 'Hacienda La Cruz [Fraccionamiento]', value: 'Hacienda La Cruz [Fraccionamiento]' },
  { label: 'Hacienda Los Fresnos', value: 'Hacienda Los Fresnos' },
  { label: 'Hacienda Nueva', value: 'Hacienda Nueva' },
  { label: 'Hacienda Refugio', value: 'Hacienda Refugio' },
  { label: 'Hacienda San Juan', value: 'Hacienda San Juan' },
  { label: 'Hacienda San Pedro', value: 'Hacienda San Pedro' },
  { label: 'Hacienda Santa Fe', value: 'Hacienda Santa Fe' },
  { label: 'Hacienda Santa Rosa', value: 'Hacienda Santa Rosa' },
  { label: 'Hacienda Sotavento', value: 'Hacienda Sotavento' },
  { label: 'Hacienda Tecate', value: 'Hacienda Tecate' },
  { label: 'Hacienda Toribio', value: 'Hacienda Toribio' },
  { label: 'Hacienda Vieja', value: 'Hacienda Vieja' },
  { label: 'Hacienda Vieja Del Castillo', value: 'Hacienda Vieja Del Castillo' },
  { label: 'Haciendas La Herradura', value: 'Haciendas La Herradura' },
  { label: 'Hacıhüseynli', value: 'Hacıhüseynli' },
  { label: 'Hacılar', value: 'Hacılar' },
  { label: 'Hacinas', value: 'Hacinas' },
  { label: 'Hacıqabul', value: 'Hacıqabul' },
  { label: 'Hackberry', value: 'Hackberry' },
  { label: 'Hackberry', value: 'Hackberry' },
  { label: 'Hackenheim', value: 'Hackenheim' },
  { label: 'Hackensack', value: 'Hackensack' },
  { label: 'Hackerberg', value: 'Hackerberg' },
  { label: 'Hackett', value: 'Hackett' },
  { label: 'Hackettstown', value: 'Hackettstown' },
  { label: 'Hackham', value: 'Hackham' },
  { label: 'Hackham West', value: 'Hackham West' },
  { label: 'Hackleburg', value: 'Hackleburg' },
  { label: 'Hackleton', value: 'Hackleton' },
  { label: 'Haczów', value: 'Haczów' },
  { label: 'Had Kourt', value: 'Had Kourt' },
  { label: 'Hadagalli', value: 'Hadagalli' },
  { label: 'Hadali', value: 'Hadali' },
  { label: 'Hadamar', value: 'Hadamar' },
  { label: 'Hadano', value: 'Hadano' },
  { label: 'Hadano-Shi', value: 'Hadano-Shi' },
  { label: 'Hadapsar Pune', value: 'Hadapsar Pune' },
  { label: 'Hădăreni', value: 'Hădăreni' },
  { label: 'Hadayek El Kobba', value: 'Hadayek El Kobba' },
  { label: 'Hadda', value: 'Hadda' },
  { label: 'Haddat Ash Sham', value: 'Haddat Ash Sham' },
  { label: 'Haddenham', value: 'Haddenham' },
  { label: 'Haddington', value: 'Haddington' },
  { label: 'Haddington', value: 'Haddington' },
  { label: 'Haddo', value: 'Haddo' },
  { label: 'Haddon', value: 'Haddon' },
  { label: 'Haddon Heights', value: 'Haddon Heights' },
  { label: 'Haddonfield', value: 'Haddonfield' },
  { label: 'Hadejia', value: 'Hadejia' },
  { label: 'Hadera', value: 'Hadera' },
  { label: 'Hadersdorf Am Kamp', value: 'Hadersdorf Am Kamp' },
  { label: 'Haderslev', value: 'Haderslev' },
  { label: 'Haderslev Kommune', value: 'Haderslev Kommune' },
  { label: 'Hadfield', value: 'Hadfield' },
  { label: 'Hadgaon', value: 'Hadgaon' },
  { label: 'Hadhah', value: 'Hadhah' },
  { label: 'Hadiach', value: 'Hadiach' },
  { label: 'Hadibu', value: 'Hadibu' },
  { label: 'Hadim', value: 'Hadim' },
  { label: 'Hadishahr', value: 'Hadishahr' },
  { label: 'Ḩadīthah', value: 'Ḩadīthah' },
  { label: 'Hadiya Zone', value: 'Hadiya Zone' },
  { label: 'Hadjout', value: 'Hadjout' },
  { label: 'Hadjúszoboszlói Járás', value: 'Hadjúszoboszlói Járás' },
  { label: 'Hadleigh', value: 'Hadleigh' },
  { label: 'Hadley', value: 'Hadley' },
  { label: 'Hadley', value: 'Hadley' },
  { label: 'Hadley', value: 'Hadley' },
  { label: 'Hadley Wood', value: 'Hadley Wood' },
  { label: 'Hadlikon', value: 'Hadlikon' },
  { label: 'Hadlow', value: 'Hadlow' },
  { label: 'Hadmersleben', value: 'Hadmersleben' },
  { label: 'Hadol', value: 'Hadol' },
  { label: 'Hadong-Gun', value: 'Hadong-Gun' },
  { label: 'Hadres', value: 'Hadres' },
  { label: 'Hadrut', value: 'Hadrut' },
  { label: 'Hadsel', value: 'Hadsel' },
  { label: 'Hadspen', value: 'Hadspen' },
  { label: 'Hadsten', value: 'Hadsten' },
  { label: 'Hadston', value: 'Hadston' },
  { label: 'Hadsund', value: 'Hadsund' },
  { label: 'Hadzhidimovo', value: 'Hadzhidimovo' },
  { label: 'Hadžići', value: 'Hadžići' },
  { label: 'Hægebostad', value: 'Hægebostad' },
  { label: 'Haeju', value: 'Haeju' },
  { label: 'Haelen', value: 'Haelen' },
  { label: 'Haenam', value: 'Haenam' },
  { label: 'Haenam-Gun', value: 'Haenam-Gun' },
  { label: 'Haeundae-Gu', value: 'Haeundae-Gu' },
  { label: 'Hafar Al-Batin', value: 'Hafar Al-Batin' },
  { label: 'Hafendorf', value: 'Hafendorf' },
  { label: 'Hafenlohr', value: 'Hafenlohr' },
  { label: 'Haffouz', value: 'Haffouz' },
  { label: 'Hafik', value: 'Hafik' },
  { label: 'Hafizabad', value: 'Hafizabad' },
  { label: 'Haflong', value: 'Haflong' },
  { label: 'Hafnarfjörður', value: 'Hafnarfjörður' },
  { label: 'Hafnerbach', value: 'Hafnerbach' },
  { label: 'Hafshejan', value: 'Hafshejan' },
  { label: 'Haft Cheshmeh', value: 'Haft Cheshmeh' },
  { label: 'Haftkel', value: 'Haftkel' },
  { label: 'Haftoni', value: 'Haftoni' },
  { label: 'Hagaman', value: 'Hagaman' },
  { label: 'Hagău', value: 'Hagău' },
  { label: 'Hagavik', value: 'Hagavik' },
  { label: 'Hagdan', value: 'Hagdan' },
  { label: 'Hage', value: 'Hage' },
  { label: 'Hagelstadt', value: 'Hagelstadt' },
  { label: 'Hagen', value: 'Hagen' },
  { label: 'Hagen', value: 'Hagen' },
  { label: 'Hagen', value: 'Hagen' },
  { label: 'Hagen Im Bremischen', value: 'Hagen Im Bremischen' },
  { label: 'Hagenbach', value: 'Hagenbach' },
  { label: 'Hagenberg Im Mühlkreis', value: 'Hagenberg Im Mühlkreis' },
  { label: 'Hagenbrunn', value: 'Hagenbrunn' },
  { label: 'Hagenbüchach', value: 'Hagenbüchach' },
  { label: 'Hagenburg', value: 'Hagenburg' },
  { label: 'Hägendorf', value: 'Hägendorf' },
  { label: 'Hagenow', value: 'Hagenow' },
  { label: 'Hagenthal-Le-Bas', value: 'Hagenthal-Le-Bas' },
  { label: 'Hāgere Hiywet', value: 'Hāgere Hiywet' },
  { label: 'Hagere Maryam', value: 'Hagere Maryam' },
  { label: 'Hāgere Selam', value: 'Hāgere Selam' },
  { label: 'Hagerman', value: 'Hagerman' },
  { label: 'Hagerstown', value: 'Hagerstown' },
  { label: 'Hagerstown', value: 'Hagerstown' },
  { label: 'Hagetmau', value: 'Hagetmau' },
  { label: 'Hagfors', value: 'Hagfors' },
  { label: 'Häggenschwil', value: 'Häggenschwil' },
  { label: 'Hägglingen', value: 'Hägglingen' },
  { label: 'Haghartsin', value: 'Haghartsin' },
  { label: 'Hăghiac', value: 'Hăghiac' },
  { label: 'Hăghig', value: 'Hăghig' },
  { label: 'Hagi', value: 'Hagi' },
  { label: 'Hagi Shi', value: 'Hagi Shi' },
  { label: 'Hagley', value: 'Hagley' },
  { label: 'Hagley Gap', value: 'Hagley Gap' },
  { label: 'Hagnau', value: 'Hagnau' },
  { label: 'Hagnaya', value: 'Hagnaya' },
  { label: 'Hagondange', value: 'Hagondange' },
  { label: 'Hagonoy', value: 'Hagonoy' },
  { label: 'Hagonoy', value: 'Hagonoy' },
  { label: 'Hagonoy', value: 'Hagonoy' },
  { label: 'Hagonoy', value: 'Hagonoy' },
  { label: 'Hagonoy', value: 'Hagonoy' },
  { label: 'Hagr As Saiar', value: 'Hagr As Saiar' },
  { label: 'Haguenau', value: 'Haguenau' },
  { label: 'Haguimit', value: 'Haguimit' },
  { label: 'Haharinskyi District', value: 'Haharinskyi District' },
  { label: 'Hahira', value: 'Hahira' },
  { label: 'Hahnbach', value: 'Hahnbach' },
  { label: 'Hahndorf', value: 'Hahndorf' },
  { label: 'Hahnheim', value: 'Hahnheim' },
  { label: 'Hähnichen', value: 'Hähnichen' },
  { label: 'Hahnstätten', value: 'Hahnstätten' },
  { label: 'Hahnville', value: 'Hahnville' },
  { label: 'Hải Dương', value: 'Hải Dương' },
  { label: 'Haibach', value: 'Haibach' },
  { label: 'Haibach Im Mühlkreis', value: 'Haibach Im Mühlkreis' },
  { label: 'Haibara-Akanedai', value: 'Haibara-Akanedai' },
  { label: 'Haibei Tibetan Autonomous Prefecture', value: 'Haibei Tibetan Autonomous Prefecture' },
  { label: 'Haicheng', value: 'Haicheng' },
  { label: 'Haid', value: 'Haid' },
  { label: 'Haiden', value: 'Haiden' },
  { label: 'Haidershofen', value: 'Haidershofen' },
  { label: 'Haidl', value: 'Haidl' },
  { label: 'Haidmühle', value: 'Haidmühle' },
  { label: 'Haifa', value: 'Haifa' },
  { label: 'Haiger', value: 'Haiger' },
  { label: 'Haigerloch', value: 'Haigerloch' },
  { label: 'Haihara', value: 'Haihara' },
  { label: 'Haikou', value: 'Haikou' },
  { label: 'Haikou', value: 'Haikou' },
  { label: 'Haikuotiankong', value: 'Haikuotiankong' },
  { label: 'Haiku-Pauwela', value: 'Haiku-Pauwela' },
  { label: 'Hail ', value: 'Hail ' },
  { label: 'Hailakandi', value: 'Hailakandi' },
  { label: 'Hailar', value: 'Hailar' },
  { label: 'Hailey', value: 'Hailey' },
  { label: 'Hailin', value: 'Hailin' },
  { label: 'Haillicourt', value: 'Haillicourt' },
  { label: 'Hailsham', value: 'Hailsham' },
  { label: 'Hailun', value: 'Hailun' },
  { label: 'Hailuoto', value: 'Hailuoto' },
  { label: 'Haimen', value: 'Haimen' },
  { label: 'Haimhausen', value: 'Haimhausen' },
  { label: 'Haiming', value: 'Haiming' },
  { label: 'Haiming', value: 'Haiming' },
  { label: 'Haina', value: 'Haina' },
  { label: 'Haina', value: 'Haina' },
  { label: 'Hainault', value: 'Hainault' },
  { label: 'Hainburg An Der Donau', value: 'Hainburg An Der Donau' },
  { label: 'Hainersdorf', value: 'Hainersdorf' },
  { label: 'Haines', value: 'Haines' },
  { label: 'Haines Borough', value: 'Haines Borough' },
  { label: 'Haines City', value: 'Haines City' },
  { label: 'Haines Junction', value: 'Haines Junction' },
  { label: 'Hainesville', value: 'Hainesville' },
  { label: 'Hainewalde', value: 'Hainewalde' },
  { label: 'Hainfeld', value: 'Hainfeld' },
  { label: 'Hain-Gründau', value: 'Hain-Gründau' },
  { label: 'Hainichen', value: 'Hainichen' },
  { label: 'Haining', value: 'Haining' },
  { label: 'Hainsdorf Im Schwarzautal', value: 'Hainsdorf Im Schwarzautal' },
  { label: 'Hainsfarth', value: 'Hainsfarth' },
  { label: 'Hainzenberg', value: 'Hainzenberg' },
  { label: 'Haiphong', value: 'Haiphong' },
  { label: 'Haisnes', value: 'Haisnes' },
  { label: 'Haisyn', value: 'Haisyn' },
  { label: 'Haiterbach', value: 'Haiterbach' },
  { label: 'Haizhou', value: 'Haizhou' },
  { label: 'Háj Ve Slezsku', value: 'Háj Ve Slezsku' },
  { label: 'Hajan', value: 'Hajan' },
  { label: 'Hajdúbagos', value: 'Hajdúbagos' },
  { label: 'Hajdúböszörmény', value: 'Hajdúböszörmény' },
  { label: 'Hajdúböszörményi Járás', value: 'Hajdúböszörményi Járás' },
  { label: 'Hajdučica', value: 'Hajdučica' },
  { label: 'Hajdúdorog', value: 'Hajdúdorog' },
  { label: 'Hajdúhadház', value: 'Hajdúhadház' },
  { label: 'Hajdúhadházi Járás', value: 'Hajdúhadházi Járás' },
  { label: 'Hajdúnánás', value: 'Hajdúnánás' },
  { label: 'Hajdúnánási Járás', value: 'Hajdúnánási Járás' },
  { label: 'Hajdúsámson', value: 'Hajdúsámson' },
  { label: 'Hajdúszoboszló', value: 'Hajdúszoboszló' },
  { label: 'Hajdúszovát', value: 'Hajdúszovát' },
  { label: 'Haji Abad', value: 'Haji Abad' },
  { label: 'Hajiabad', value: 'Hajiabad' },
  { label: 'Hajiabad', value: 'Hajiabad' },
  { label: 'Hajīn', value: 'Hajīn' },
  { label: 'Hajipur', value: 'Hajipur' },
  { label: 'Hajipur', value: 'Hajipur' },
  { label: 'Hajjah', value: 'Hajjah' },
  { label: 'Hajji Kheder', value: 'Hajji Kheder' },
  { label: 'Hajji Maqsud', value: 'Hajji Maqsud' },
  { label: 'Hajji Tu', value: 'Hajji Tu' },
  { label: 'Hajjiabad', value: 'Hajjiabad' },
  { label: 'Hajmáskér', value: 'Hajmáskér' },
  { label: 'Hajnówka', value: 'Hajnówka' },
  { label: 'Hajo', value: 'Hajo' },
  { label: 'Hajoho', value: 'Hajoho' },
  { label: 'Hajós', value: 'Hajós' },
  { label: 'Hajr', value: 'Hajr' },
  { label: 'Hajrah', value: 'Hajrah' },
  { label: 'Hajur', value: 'Hajur' },
  { label: 'Ḩakamā', value: 'Ḩakamā' },
  { label: 'Hakenfelde', value: 'Hakenfelde' },
  { label: 'Hakha', value: 'Hakha' },
  { label: 'Hakkelaarsbrug', value: 'Hakkelaarsbrug' },
  { label: 'Hakodate', value: 'Hakodate' },
  { label: 'Hakodate Shi', value: 'Hakodate Shi' },
  { label: 'Hakone', value: 'Hakone' },
  { label: 'Hakuba', value: 'Hakuba' },
  { label: 'Hakuba', value: 'Hakuba' },
  { label: 'Hakui', value: 'Hakui' },
  { label: 'Hakui Shi', value: 'Hakui Shi' },
  { label: 'Hakusan Shi', value: 'Hakusan Shi' },
  { label: 'Ħal Far', value: 'Ħal Far' },
  { label: 'Ħal Għargħur', value: 'Ħal Għargħur' },
  { label: 'Ħal Għaxaq', value: 'Ħal Għaxaq' },
  { label: 'Ħal Lija', value: 'Ħal Lija' },
  { label: 'Ħal Luqa', value: 'Ħal Luqa' },
  { label: 'Ħal Safi', value: 'Ħal Safi' },
  { label: 'Ħal Tarxien', value: 'Ħal Tarxien' },
  { label: 'Hal’Shany', value: 'Hal’Shany' },
  { label: 'Hala', value: 'Hala' },
  { label: 'Halab', value: 'Halab' },
  { label: 'Ḩalabjah', value: 'Ḩalabjah' },
  { label: 'Halachó', value: 'Halachó' },
  { label: 'Halamah', value: 'Halamah' },
  { label: 'Halânga', value: 'Halânga' },
  { label: 'Halapitan', value: 'Halapitan' },
  { label: 'Hălărești', value: 'Hălărești' },
  { label: 'Halashi', value: 'Halashi' },
  { label: 'Halászi', value: 'Halászi' },
  { label: 'Halásztelek', value: 'Halásztelek' },
  { label: 'Halat Ammar', value: 'Halat Ammar' },
  { label: 'Hălăuceşti', value: 'Hălăuceşti' },
  { label: 'Hālawa', value: 'Hālawa' },
  { label: 'Hālawa Heights', value: 'Hālawa Heights' },
  { label: 'Ḩalāwah', value: 'Ḩalāwah' },
  { label: 'Halayhay', value: 'Halayhay' },
  { label: 'Halayhayin', value: 'Halayhayin' },
  { label: 'Halbe', value: 'Halbe' },
  { label: 'Halbemond', value: 'Halbemond' },
  { label: 'Halbenrain', value: 'Halbenrain' },
  { label: 'Halberstadt', value: 'Halberstadt' },
  { label: 'Halblech', value: 'Halblech' },
  { label: 'Halbturn', value: 'Halbturn' },
  { label: 'Hălchiu', value: 'Hălchiu' },
  { label: 'Haldaur', value: 'Haldaur' },
  { label: 'Halden', value: 'Halden' },
  { label: 'Haldensleben I', value: 'Haldensleben I' },
  { label: 'Haldenwang', value: 'Haldenwang' },
  { label: 'Haldia', value: 'Haldia' },
  { label: 'Haldibari', value: 'Haldibari' },
  { label: 'Haldimand County', value: 'Haldimand County' },
  { label: 'Haldwani', value: 'Haldwani' },
  { label: 'Hale', value: 'Hale' },
  { label: 'Hale Center', value: 'Hale Center' },
  { label: 'Hale County', value: 'Hale County' },
  { label: 'Hale County', value: 'Hale County' },
  { label: 'Hale‘Iwa', value: 'Hale‘Iwa' },
  { label: 'Haledon', value: 'Haledon' },
  { label: 'Halekulani', value: 'Halekulani' },
  { label: 'Halen', value: 'Halen' },
  { label: 'Halenda', value: 'Halenda' },
  { label: 'Halenkov', value: 'Halenkov' },
  { label: 'Halenkovice', value: 'Halenkovice' },
  { label: 'Halensee', value: 'Halensee' },
  { label: 'Hales Corners', value: 'Hales Corners' },
  { label: 'Halesite', value: 'Halesite' },
  { label: 'Halesowen', value: 'Halesowen' },
  { label: 'Halesworth', value: 'Halesworth' },
  { label: 'Haleyville', value: 'Haleyville' },
  { label: 'Half Moon', value: 'Half Moon' },
  { label: 'Half Moon Bay', value: 'Half Moon Bay' },
  { label: 'Half Way Tree', value: 'Half Way Tree' },
  { label: 'Ḩalfāyā', value: 'Ḩalfāyā' },
  { label: 'Halfeti', value: 'Halfeti' },
  { label: 'Halfing', value: 'Halfing' },
  { label: 'Halfway', value: 'Halfway' },
  { label: 'Halfway House', value: 'Halfway House' },
  { label: 'Half-Way-Tree', value: 'Half-Way-Tree' },
  { label: 'Halfweg', value: 'Halfweg' },
  { label: 'Haliburton Village', value: 'Haliburton Village' },
  { label: 'Halifax', value: 'Halifax' },
  { label: 'Halifax', value: 'Halifax' },
  { label: 'Halifax', value: 'Halifax' },
  { label: 'Halifax', value: 'Halifax' },
  { label: 'Halifax', value: 'Halifax' },
  { label: 'Halifax County', value: 'Halifax County' },
  { label: 'Halifax County', value: 'Halifax County' },
  { label: 'Haligue', value: 'Haligue' },
  { label: 'Halikko', value: 'Halikko' },
  { label: 'Haliliye', value: 'Haliliye' },
  { label: 'Halimayn', value: 'Halimayn' },
  { label: 'Halinów', value: 'Halinów' },
  { label: 'Halisahar', value: 'Halisahar' },
  { label: 'Haliyal', value: 'Haliyal' },
  { label: 'Haljala', value: 'Haljala' },
  { label: 'Haljala Vald', value: 'Haljala Vald' },
  { label: 'Häljarp', value: 'Häljarp' },
  { label: 'Halkapınar', value: 'Halkapınar' },
  { label: 'Halkarni', value: 'Halkarni' },
  { label: 'Halkirk', value: 'Halkirk' },
  { label: 'Hall Bei Admont', value: 'Hall Bei Admont' },
  { label: 'Hall County', value: 'Hall County' },
  { label: 'Hall County', value: 'Hall County' },
  { label: 'Hall County', value: 'Hall County' },
  { label: 'Hall In Tirol', value: 'Hall In Tirol' },
  { label: 'Hall Park', value: 'Hall Park' },
  { label: 'Hällabrottet', value: 'Hällabrottet' },
  { label: 'Hallam', value: 'Hallam' },
  { label: 'Hallam', value: 'Hallam' },
  { label: 'Hallandale Beach', value: 'Hallandale Beach' },
  { label: 'Hallau', value: 'Hallau' },
  { label: 'Hallbergmoos', value: 'Hallbergmoos' },
  { label: 'Hällbybrunn', value: 'Hällbybrunn' },
  { label: 'Halle', value: 'Halle' },
  { label: 'Halle', value: 'Halle' },
  { label: 'Halle', value: 'Halle' },
  { label: 'Halle (Saale)', value: 'Halle (Saale)' },
  { label: 'Halle Neustadt', value: 'Halle Neustadt' },
  { label: 'Hällefors', value: 'Hällefors' },
  { label: 'Hälleforsnäs', value: 'Hälleforsnäs' },
  { label: 'Hallein', value: 'Hallein' },
  { label: 'Hallenberg', value: 'Hallenberg' },
  { label: 'Hallencourt', value: 'Hallencourt' },
  { label: 'Hallennes-Lez-Haubourdin', value: 'Hallennes-Lez-Haubourdin' },
  { label: 'Hallerndorf', value: 'Hallerndorf' },
  { label: 'Hallett Cove', value: 'Hallett Cove' },
  { label: 'Hallettsville', value: 'Hallettsville' },
  { label: 'Hällevik', value: 'Hällevik' },
  { label: 'Hallglen', value: 'Hallglen' },
  { label: 'Hallila', value: 'Hallila' },
  { label: 'Hallines', value: 'Hallines' },
  { label: 'Halling', value: 'Halling' },
  { label: 'Hallock', value: 'Hallock' },
  { label: 'Hallow', value: 'Hallow' },
  { label: 'Hallowell', value: 'Hallowell' },
  { label: 'Halls', value: 'Halls' },
  { label: 'Halls Creek', value: 'Halls Creek' },
  { label: 'Halls Head', value: 'Halls Head' },
  { label: 'Hallsberg', value: 'Hallsberg' },
  { label: 'Hallstadt', value: 'Hallstadt' },
  { label: 'Hallstahammar', value: 'Hallstahammar' },
  { label: 'Hallstavik', value: 'Hallstavik' },
  { label: 'Hallstead', value: 'Hallstead' },
  { label: 'Hallsville', value: 'Hallsville' },
  { label: 'Hallsville', value: 'Hallsville' },
  { label: 'Halluin', value: 'Halluin' },
  { label: 'Hallum', value: 'Hallum' },
  { label: 'Hallwang', value: 'Hallwang' },
  { label: 'Hălmăgel', value: 'Hălmăgel' },
  { label: 'Hălmagiu', value: 'Hălmagiu' },
  { label: 'Halmaj', value: 'Halmaj' },
  { label: 'Halmăşd', value: 'Halmăşd' },
  { label: 'Halmeu', value: 'Halmeu' },
  { label: 'Halmstad', value: 'Halmstad' },
  { label: 'Halog West', value: 'Halog West' },
  { label: 'Halog West', value: 'Halog West' },
  { label: 'Halol', value: 'Halol' },
  { label: 'Hals', value: 'Hals' },
  { label: 'Halsa', value: 'Halsa' },
  { label: 'Halsbrücke', value: 'Halsbrücke' },
  { label: 'Halsenbach', value: 'Halsenbach' },
  { label: 'Halsnæs Kommune', value: 'Halsnæs Kommune' },
  { label: 'Halstead', value: 'Halstead' },
  { label: 'Halstead', value: 'Halstead' },
  { label: 'Halstenbek', value: 'Halstenbek' },
  { label: 'Halsteren', value: 'Halsteren' },
  { label: 'Halsua', value: 'Halsua' },
  { label: 'Haltern Am See', value: 'Haltern Am See' },
  { label: 'Haltom City', value: 'Haltom City' },
  { label: 'Halton', value: 'Halton' },
  { label: 'Halton', value: 'Halton' },
  { label: 'Haltwhistle', value: 'Haltwhistle' },
  { label: 'Halvad', value: 'Halvad' },
  { label: 'Halver', value: 'Halver' },
  { label: 'Halych', value: 'Halych' },
  { label: 'Halyts’Kyy Rayon', value: 'Halyts’Kyy Rayon' },
  { label: 'Ham', value: 'Ham' },
  { label: 'Ham Lake', value: 'Ham Lake' },
  { label: 'Hama District', value: 'Hama District' },
  { label: 'Hamada', value: 'Hamada' },
  { label: 'Hamada Shi', value: 'Hamada Shi' },
  { label: 'Hamadan', value: 'Hamadan' },
  { label: 'Ḩamāh', value: 'Ḩamāh' },
  { label: 'Hamakita', value: 'Hamakita' },
  { label: 'Hamamatsu', value: 'Hamamatsu' },
  { label: 'Hamamatsu-Shi', value: 'Hamamatsu-Shi' },
  { label: 'Hamamözü', value: 'Hamamözü' },
  { label: 'Haman-Gun', value: 'Haman-Gun' },
  { label: 'Hamanoichi', value: 'Hamanoichi' },
  { label: 'Hamar', value: 'Hamar' },
  { label: 'Hamarøy', value: 'Hamarøy' },
  { label: 'Hamashahr', value: 'Hamashahr' },
  { label: 'Hamayyah', value: 'Hamayyah' },
  { label: 'Hambach', value: 'Hambach' },
  { label: 'Hambantota District', value: 'Hambantota District' },
  { label: 'Hamberge', value: 'Hamberge' },
  { label: 'Hambergen', value: 'Hambergen' },
  { label: 'Hamble-Le-Rice', value: 'Hamble-Le-Rice' },
  { label: 'Hamblen County', value: 'Hamblen County' },
  { label: 'Hambleton', value: 'Hambleton' },
  { label: 'Hambol', value: 'Hambol' },
  { label: 'Hambrücken', value: 'Hambrücken' },
  { label: 'Hambühren', value: 'Hambühren' },
  { label: 'Hamburg', value: 'Hamburg' },
  { label: 'Hamburg', value: 'Hamburg' },
  { label: 'Hamburg', value: 'Hamburg' },
  { label: 'Hamburg', value: 'Hamburg' },
  { label: 'Hamburg', value: 'Hamburg' },
  { label: 'Hamburg', value: 'Hamburg' },
  { label: 'Hamburg-Altstadt', value: 'Hamburg-Altstadt' },
  { label: 'Hamburg-Mitte', value: 'Hamburg-Mitte' },
  { label: 'Hamburg-Nord', value: 'Hamburg-Nord' },
  { label: 'Hambye', value: 'Hambye' },
  { label: 'Hamcearca', value: 'Hamcearca' },
  { label: 'Hamdah', value: 'Hamdah' },
  { label: 'Hamdān', value: 'Hamdān' },
  { label: 'Hamden', value: 'Hamden' },
  { label: 'Hamdorf', value: 'Hamdorf' },
  { label: 'Hamedan', value: 'Hamedan' },
  { label: 'Hämeenkoski', value: 'Hämeenkoski' },
  { label: 'Hämeenkyrö', value: 'Hämeenkyrö' },
  { label: 'Hämeenlinna', value: 'Hämeenlinna' },
  { label: 'Hameldon Hill', value: 'Hameldon Hill' },
  { label: 'Hameln', value: 'Hameln' },
  { label: 'Hamersley', value: 'Hamersley' },
  { label: 'Hames-Boucres', value: 'Hames-Boucres' },
  { label: 'Hamhŭng', value: 'Hamhŭng' },
  { label: 'Hami', value: 'Hami' },
  { label: 'Hamidia', value: 'Hamidia' },
  { label: 'Hamidiyeh', value: 'Hamidiyeh' },
  { label: 'Hamil', value: 'Hamil' },
  { label: 'Hamilabad', value: 'Hamilabad' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Hamilton City', value: 'Hamilton City' },
  { label: 'Hamilton County', value: 'Hamilton County' },
  { label: 'Hamilton County', value: 'Hamilton County' },
  { label: 'Hamilton County', value: 'Hamilton County' },
  { label: 'Hamilton County', value: 'Hamilton County' },
  { label: 'Hamilton County', value: 'Hamilton County' },
  { label: 'Hamilton County', value: 'Hamilton County' },
  { label: 'Hamilton County', value: 'Hamilton County' },
  { label: 'Hamilton County', value: 'Hamilton County' },
  { label: 'Hamilton County', value: 'Hamilton County' },
  { label: 'Hamilton County', value: 'Hamilton County' },
  { label: 'Hamilton Hill', value: 'Hamilton Hill' },
  { label: 'Hamilton Square', value: 'Hamilton Square' },
  { label: 'Hamilton Worcester', value: 'Hamilton Worcester' },
  { label: 'Hamina', value: 'Hamina' },
  { label: 'Hamirpur', value: 'Hamirpur' },
  { label: 'Hamirpur', value: 'Hamirpur' },
  { label: 'Hamlet', value: 'Hamlet' },
  { label: 'Hamlin', value: 'Hamlin' },
  { label: 'Hamlin', value: 'Hamlin' },
  { label: 'Hamlin', value: 'Hamlin' },
  { label: 'Hamlin County', value: 'Hamlin County' },
  { label: 'Hamlyn Heights', value: 'Hamlyn Heights' },
  { label: 'Hamlyn Terrace', value: 'Hamlyn Terrace' },
  { label: 'Hamm', value: 'Hamm' },
  { label: 'Hamm', value: 'Hamm' },
  { label: 'Hamma Bouziane', value: 'Hamma Bouziane' },
  { label: 'Hammah', value: 'Hammah' },
  { label: 'Hammam Bou Hadjar', value: 'Hammam Bou Hadjar' },
  { label: 'Hammam Lif', value: 'Hammam Lif' },
  { label: 'Hammam Sousse', value: 'Hammam Sousse' },
  { label: 'Hammamet', value: 'Hammamet' },
  { label: 'Hammamet', value: 'Hammamet' },
  { label: 'Hammar', value: 'Hammar' },
  { label: 'Hammarkullen', value: 'Hammarkullen' },
  { label: 'Hammarland', value: 'Hammarland' },
  { label: 'Hamme', value: 'Hamme' },
  { label: 'Hammel', value: 'Hammel' },
  { label: 'Hammelburg', value: 'Hammelburg' },
  { label: 'Hammerbrook', value: 'Hammerbrook' },
  { label: 'Hammerbrücke', value: 'Hammerbrücke' },
  { label: 'Hammerfest', value: 'Hammerfest' },
  { label: 'Hammersbach', value: 'Hammersbach' },
  { label: 'Hammerum', value: 'Hammerum' },
  { label: 'Hamminkeln', value: 'Hamminkeln' },
  { label: 'Hammond', value: 'Hammond' },
  { label: 'Hammond', value: 'Hammond' },
  { label: 'Hammond', value: 'Hammond' },
  { label: 'Hammond Park', value: 'Hammond Park' },
  { label: 'Hammondville', value: 'Hammondville' },
  { label: 'Hammonton', value: 'Hammonton' },
  { label: 'Hammoor', value: 'Hammoor' },
  { label: 'Hamoir', value: 'Hamoir' },
  { label: 'Hamois', value: 'Hamois' },
  { label: 'Hamoon', value: 'Hamoon' },
  { label: 'Hamoraon', value: 'Hamoraon' },
  { label: 'Hampden', value: 'Hampden' },
  { label: 'Hampden', value: 'Hampden' },
  { label: 'Hampden', value: 'Hampden' },
  { label: 'Hampden County', value: 'Hampden County' },
  { label: 'Hampden Sydney', value: 'Hampden Sydney' },
  { label: 'Hampi', value: 'Hampi' },
  { label: 'Hampolol', value: 'Hampolol' },
  { label: 'Hampshire', value: 'Hampshire' },
  { label: 'Hampshire', value: 'Hampshire' },
  { label: 'Hampshire County', value: 'Hampshire County' },
  { label: 'Hampshire County', value: 'Hampshire County' },
  { label: 'Hampstead', value: 'Hampstead' },
  { label: 'Hampstead', value: 'Hampstead' },
  { label: 'Hampstead', value: 'Hampstead' },
  { label: 'Hampstead', value: 'Hampstead' },
  { label: 'Hampstead', value: 'Hampstead' },
  { label: 'Hampstead Gardens', value: 'Hampstead Gardens' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Hampton Bays', value: 'Hampton Bays' },
  { label: 'Hampton Beach', value: 'Hampton Beach' },
  { label: 'Hampton County', value: 'Hampton County' },
  { label: 'Hampton Court', value: 'Hampton Court' },
  { label: 'Hampton East', value: 'Hampton East' },
  { label: 'Hampton Falls', value: 'Hampton Falls' },
  { label: 'Hampton Green', value: 'Hampton Green' },
  { label: 'Hampton In Arden', value: 'Hampton In Arden' },
  { label: 'Hampton Manor', value: 'Hampton Manor' },
  { label: 'Hampton Park', value: 'Hampton Park' },
  { label: 'Hampyeong-Gun', value: 'Hampyeong-Gun' },
  { label: 'Ħamrun', value: 'Ħamrun' },
  { label: 'Ham-Sous-Varsberg', value: 'Ham-Sous-Varsberg' },
  { label: 'Hamtic', value: 'Hamtic' },
  { label: 'Hamtramck', value: 'Hamtramck' },
  { label: 'Hamur', value: 'Hamur' },
  { label: 'Hamura-Shi', value: 'Hamura-Shi' },
  { label: 'Hamza', value: 'Hamza' },
  { label: 'Hamzehlu-Ye Sofla', value: 'Hamzehlu-Ye Sofla' },
  { label: 'Han', value: 'Han' },
  { label: 'Hana', value: 'Hana' },
  { label: 'Hanahan', value: 'Hanahan' },
  { label: 'Hanak', value: 'Hanak' },
  { label: 'Hanam', value: 'Hanam' },
  { label: 'Hanamā‘Ulu', value: 'Hanamā‘Ulu' },
  { label: 'Hanamaki', value: 'Hanamaki' },
  { label: 'Hanamaki Shi', value: 'Hanamaki Shi' },
  { label: 'Hanam-Si', value: 'Hanam-Si' },
  { label: 'Hanapēpē', value: 'Hanapēpē' },
  { label: 'Hanapēpē Heights', value: 'Hanapēpē Heights' },
  { label: 'Hanaskog', value: 'Hanaskog' },
  { label: 'Hanau Am Main', value: 'Hanau Am Main' },
  { label: 'Hanawa', value: 'Hanawa' },
  { label: 'Hanceville', value: 'Hanceville' },
  { label: 'Hanceville', value: 'Hanceville' },
  { label: 'Hancheng', value: 'Hancheng' },
  { label: 'Hancheng', value: 'Hancheng' },
  { label: 'Hanches', value: 'Hanches' },
  { label: 'Hanchuan', value: 'Hanchuan' },
  { label: 'Hancock', value: 'Hancock' },
  { label: 'Hancock', value: 'Hancock' },
  { label: 'Hancock', value: 'Hancock' },
  { label: 'Hancock County', value: 'Hancock County' },
  { label: 'Hancock County', value: 'Hancock County' },
  { label: 'Hancock County', value: 'Hancock County' },
  { label: 'Hancock County', value: 'Hancock County' },
  { label: 'Hancock County', value: 'Hancock County' },
  { label: 'Hancock County', value: 'Hancock County' },
  { label: 'Hancock County', value: 'Hancock County' },
  { label: 'Hancock County', value: 'Hancock County' },
  { label: 'Hancock County', value: 'Hancock County' },
  { label: 'Hancock County', value: 'Hancock County' },
  { label: 'Hand County', value: 'Hand County' },
  { label: 'Handa', value: 'Handa' },
  { label: 'Handalu Ilbei', value: 'Handalu Ilbei' },
  { label: 'Handan', value: 'Handan' },
  { label: 'Handa-Shi', value: 'Handa-Shi' },
  { label: 'Handcross', value: 'Handcross' },
  { label: 'Handel', value: 'Handel' },
  { label: 'Handeloh', value: 'Handeloh' },
  { label: 'Handen', value: 'Handen' },
  { label: 'Handenberg', value: 'Handenberg' },
  { label: 'Handewitt', value: 'Handewitt' },
  { label: 'Handia', value: 'Handia' },
  { label: 'Handlová', value: 'Handlová' },
  { label: 'Handorf', value: 'Handorf' },
  { label: 'Handzlówka', value: 'Handzlówka' },
  { label: 'Hanerau-Hademarschen', value: 'Hanerau-Hademarschen' },
  { label: 'Hăneşti', value: 'Hăneşti' },
  { label: 'Hanford', value: 'Hanford' },
  { label: 'Hang Dong', value: 'Hang Dong' },
  { label: 'Hangal', value: 'Hangal' },
  { label: 'Hangard', value: 'Hangard' },
  { label: 'Hangenbieten', value: 'Hangenbieten' },
  { label: 'Hangha', value: 'Hangha' },
  { label: 'Hangu', value: 'Hangu' },
  { label: 'Hangu', value: 'Hangu' },
  { label: 'Hanguranketa', value: 'Hanguranketa' },
  { label: 'Hangzhou', value: 'Hangzhou' },
  { label: 'Hanh', value: 'Hanh' },
  { label: 'Hanhofen', value: 'Hanhofen' },
  { label: 'Hanhongor', value: 'Hanhongor' },
  { label: 'Hani', value: 'Hani' },
  { label: 'Hänigsen', value: 'Hänigsen' },
  { label: 'Haninge', value: 'Haninge' },
  { label: 'Hanjiashu', value: 'Hanjiashu' },
  { label: 'Hankasalmi', value: 'Hankasalmi' },
  { label: 'Hankensbüttel', value: 'Hankensbüttel' },
  { label: 'Hanko', value: 'Hanko' },
  { label: 'Hanley Hills', value: 'Hanley Hills' },
  { label: 'Hanna', value: 'Hanna' },
  { label: 'Hanna', value: 'Hanna' },
  { label: 'Hanna City', value: 'Hanna City' },
  { label: 'Hannah Town/ Craig Town', value: 'Hannah Town/ Craig Town' },
  { label: 'Hannahs Mill', value: 'Hannahs Mill' },
  { label: 'Hannan', value: 'Hannan' },
  { label: 'Hannan Shi', value: 'Hannan Shi' },
  { label: 'Hannans', value: 'Hannans' },
  { label: 'Hannawa Falls', value: 'Hannawa Falls' },
  { label: 'Hannersdorf', value: 'Hannersdorf' },
  { label: 'Hannibal', value: 'Hannibal' },
  { label: 'Hannington', value: 'Hannington' },
  { label: 'Hannō', value: 'Hannō' },
  { label: 'Hannō-Shi', value: 'Hannō-Shi' },
  { label: 'Hannover', value: 'Hannover' },
  { label: 'Hannoversch Münden', value: 'Hannoversch Münden' },
  { label: 'Hannut', value: 'Hannut' },
  { label: 'Hanoi', value: 'Hanoi' },
  { label: 'Hanönü', value: 'Hanönü' },
  { label: 'Hanover', value: 'Hanover' },
  { label: 'Hanover', value: 'Hanover' },
  { label: 'Hanover', value: 'Hanover' },
  { label: 'Hanover', value: 'Hanover' },
  { label: 'Hanover', value: 'Hanover' },
  { label: 'Hanover', value: 'Hanover' },
  { label: 'Hanover', value: 'Hanover' },
  { label: 'Hanover', value: 'Hanover' },
  { label: 'Hanover', value: 'Hanover' },
  { label: 'Hanover', value: 'Hanover' },
  { label: 'Hanover County', value: 'Hanover County' },
  { label: 'Hanover Park', value: 'Hanover Park' },
  { label: 'Hansaviertel', value: 'Hansaviertel' },
  { label: 'Hansen', value: 'Hansen' },
  { label: 'Hansford County', value: 'Hansford County' },
  { label: 'Hansi', value: 'Hansi' },
  { label: 'Hańsk', value: 'Hańsk' },
  { label: 'Hanslope', value: 'Hanslope' },
  { label: 'Hansnes', value: 'Hansnes' },
  { label: 'Hanson', value: 'Hanson' },
  { label: 'Hanson County', value: 'Hanson County' },
  { label: 'Hansot', value: 'Hansot' },
  { label: 'Hanstedt', value: 'Hanstedt' },
  { label: 'Hanstedt Eins', value: 'Hanstedt Eins' },
  { label: 'Hanstholm', value: 'Hanstholm' },
  { label: 'Hansville', value: 'Hansville' },
  { label: 'Hănțești', value: 'Hănțești' },
  { label: 'Hanting', value: 'Hanting' },
  { label: 'Hantsavichy', value: 'Hantsavichy' },
  { label: 'Hantsavitski Rayon', value: 'Hantsavitski Rayon' },
  { label: 'Hantsindzi', value: 'Hantsindzi' },
  { label: 'Hantsport', value: 'Hantsport' },
  { label: 'Hanu Conachi', value: 'Hanu Conachi' },
  { label: 'Hanumangarh', value: 'Hanumangarh' },
  { label: 'Hanušovice', value: 'Hanušovice' },
  { label: 'Hanvec', value: 'Hanvec' },
  { label: 'Hanwella Ihala', value: 'Hanwella Ihala' },
  { label: 'Hanwood', value: 'Hanwood' },
  { label: 'Hanyū', value: 'Hanyū' },
  { label: 'Hanyū-Shi', value: 'Hanyū-Shi' },
  { label: 'Hanza', value: 'Hanza' },
  { label: 'Hanzeh', value: 'Hanzeh' },
  { label: 'Hanzhong', value: 'Hanzhong' },
  { label: 'Haoud El Hamra', value: 'Haoud El Hamra' },
  { label: 'Hapao', value: 'Hapao' },
  { label: 'Hapao', value: 'Hapao' },
  { label: 'Haparanda', value: 'Haparanda' },
  { label: 'Hapcheon-Gun', value: 'Hapcheon-Gun' },
  { label: 'Hapert', value: 'Hapert' },
  { label: 'Hapeville', value: 'Hapeville' },
  { label: 'Happurg', value: 'Happurg' },
  { label: 'Happy Camp', value: 'Happy Camp' },
  { label: 'Happy Valley', value: 'Happy Valley' },
  { label: 'Happy Valley', value: 'Happy Valley' },
  { label: 'Happy Valley-Goose Bay', value: 'Happy Valley-Goose Bay' },
  { label: 'Haps', value: 'Haps' },
  { label: 'Hapton', value: 'Hapton' },
  { label: 'Hapugastalawa', value: 'Hapugastalawa' },
  { label: 'Hapur', value: 'Hapur' },
  { label: 'Haputale', value: 'Haputale' },
  { label: 'Haqqulobod', value: 'Haqqulobod' },
  { label: 'Har Adar', value: 'Har Adar' },
  { label: 'Hara', value: 'Hara' },
  { label: 'Harad District', value: 'Harad District' },
  { label: 'Haradh', value: 'Haradh' },
  { label: 'Haradok', value: 'Haradok' },
  { label: 'Haradotski Rayon', value: 'Haradotski Rayon' },
  { label: 'Haradzishcha', value: 'Haradzishcha' },
  { label: 'Haradzyeya', value: 'Haradzyeya' },
  { label: 'Harahan', value: 'Harahan' },
  { label: 'Haraipur', value: 'Haraipur' },
  { label: 'Haraiya', value: 'Haraiya' },
  { label: 'Haralson County', value: 'Haralson County' },
  { label: 'Haram', value: 'Haram' },
  { label: 'Haramabad', value: 'Haramabad' },
  { label: 'Harana/Valle De Arana', value: 'Harana/Valle De Arana' },
  { label: 'Harand', value: 'Harand' },
  { label: 'Hărănglab', value: 'Hărănglab' },
  { label: 'Harangul', value: 'Harangul' },
  { label: 'Harar', value: 'Harar' },
  { label: 'Harare', value: 'Harare' },
  { label: 'Harasiuki', value: 'Harasiuki' },
  { label: 'Ḩarastā', value: 'Ḩarastā' },
  { label: 'Harat', value: 'Harat' },
  { label: 'Hărău', value: 'Hărău' },
  { label: 'Harbatpur', value: 'Harbatpur' },
  { label: 'Harbin', value: 'Harbin' },
  { label: 'Harbison Canyon', value: 'Harbison Canyon' },
  { label: 'Harbke', value: 'Harbke' },
  { label: 'Harbonnières', value: 'Harbonnières' },
  { label: 'Harboøre', value: 'Harboøre' },
  { label: 'Harbor', value: 'Harbor' },
  { label: 'Harbor Beach', value: 'Harbor Beach' },
  { label: 'Harbor Bluffs', value: 'Harbor Bluffs' },
  { label: 'Harbor Hills', value: 'Harbor Hills' },
  { label: 'Harbor Isle', value: 'Harbor Isle' },
  { label: 'Harbor Springs', value: 'Harbor Springs' },
  { label: 'Harbour Breton', value: 'Harbour Breton' },
  { label: 'Harbour Heights', value: 'Harbour Heights' },
  { label: 'Harbour View', value: 'Harbour View' },
  { label: 'Harburg', value: 'Harburg' },
  { label: 'Harburg', value: 'Harburg' },
  { label: 'Harbury', value: 'Harbury' },
  { label: 'Harbutowice', value: 'Harbutowice' },
  { label: 'Hårby', value: 'Hårby' },
  { label: 'Harchandpur', value: 'Harchandpur' },
  { label: 'Hard', value: 'Hard' },
  { label: 'Harda', value: 'Harda' },
  { label: 'Harda Khas', value: 'Harda Khas' },
  { label: 'Hardbakke', value: 'Hardbakke' },
  { label: 'Hardee County', value: 'Hardee County' },
  { label: 'Hardeeville', value: 'Hardeeville' },
  { label: 'Hardegg', value: 'Hardegg' },
  { label: 'Hardegsen', value: 'Hardegsen' },
  { label: 'Hardeman County', value: 'Hardeman County' },
  { label: 'Hardeman County', value: 'Hardeman County' },
  { label: 'Hardenberg', value: 'Hardenberg' },
  { label: 'Harderwijk', value: 'Harderwijk' },
  { label: 'Harderwijk', value: 'Harderwijk' },
  { label: 'Hardheim', value: 'Hardheim' },
  { label: 'Hardin', value: 'Hardin' },
  { label: 'Hardin', value: 'Hardin' },
  { label: 'Hardin County', value: 'Hardin County' },
  { label: 'Hardin County', value: 'Hardin County' },
  { label: 'Hardin County', value: 'Hardin County' },
  { label: 'Hardin County', value: 'Hardin County' },
  { label: 'Hardin County', value: 'Hardin County' },
  { label: 'Hardin County', value: 'Hardin County' },
  { label: 'Harding County', value: 'Harding County' },
  { label: 'Harding County', value: 'Harding County' },
  { label: 'Hardinghen', value: 'Hardinghen' },
  { label: 'Hardingstone', value: 'Hardingstone' },
  { label: 'Hardinsburg', value: 'Hardinsburg' },
  { label: 'Hardoi', value: 'Hardoi' },
  { label: 'Hardricourt', value: 'Hardricourt' },
  { label: 'Hardt', value: 'Hardt' },
  { label: 'Harduaganj', value: 'Harduaganj' },
  { label: 'Hardwick', value: 'Hardwick' },
  { label: 'Hardwick', value: 'Hardwick' },
  { label: 'Hardwick', value: 'Hardwick' },
  { label: 'Hardwick', value: 'Hardwick' },
  { label: 'Hardwick Village', value: 'Hardwick Village' },
  { label: 'Hardy County', value: 'Hardy County' },
  { label: 'Hardys Memories Of Africa', value: 'Hardys Memories Of Africa' },
  { label: 'Harefield', value: 'Harefield' },
  { label: 'Hareid', value: 'Hareid' },
  { label: 'Harelbeke', value: 'Harelbeke' },
  { label: 'Harem District', value: 'Harem District' },
  { label: 'Harembo', value: 'Harembo' },
  { label: 'Haren', value: 'Haren' },
  { label: 'Haren', value: 'Haren' },
  { label: 'Harenkarspel', value: 'Harenkarspel' },
  { label: 'Harewood', value: 'Harewood' },
  { label: 'Harf Sufyan', value: 'Harf Sufyan' },
  { label: 'Harfleur', value: 'Harfleur' },
  { label: 'Harford County', value: 'Harford County' },
  { label: 'Hargarten-Aux-Mines', value: 'Hargarten-Aux-Mines' },
  { label: 'Hargen', value: 'Hargen' },
  { label: 'Hargesheim', value: 'Hargesheim' },
  { label: 'Harhorin', value: 'Harhorin' },
  { label: 'Haría', value: 'Haría' },
  { label: 'Hariana', value: 'Hariana' },
  { label: 'Ḩarīb', value: 'Ḩarīb' },
  { label: 'Harib Al Qaramish', value: 'Harib Al Qaramish' },
  { label: 'Haridwar', value: 'Haridwar' },
  { label: 'Harihar', value: 'Harihar' },
  { label: 'Harij', value: 'Harij' },
  { label: 'Ḩārim', value: 'Ḩārim' },
  { label: 'Harindanga', value: 'Harindanga' },
  { label: 'Haringhata', value: 'Haringhata' },
  { label: 'Haringhuizen', value: 'Haringhuizen' },
  { label: 'Haringsee', value: 'Haringsee' },
  { label: 'Haripur', value: 'Haripur' },
  { label: 'Haripur', value: 'Haripur' },
  { label: 'Harispattuwa', value: 'Harispattuwa' },
  { label: 'Harjavalta', value: 'Harjavalta' },
  { label: 'Harkány', value: 'Harkány' },
  { label: 'Harkema', value: 'Harkema' },
  { label: 'Harker Heights', value: 'Harker Heights' },
  { label: 'Harkers Hall', value: 'Harkers Hall' },
  { label: 'Harkers Island', value: 'Harkers Island' },
  { label: 'Harkstede', value: 'Harkstede' },
  { label: 'Harku', value: 'Harku' },
  { label: 'Harku Vald', value: 'Harku Vald' },
  { label: 'Harlan', value: 'Harlan' },
  { label: 'Harlan', value: 'Harlan' },
  { label: 'Harlan', value: 'Harlan' },
  { label: 'Harlan County', value: 'Harlan County' },
  { label: 'Harlan County', value: 'Harlan County' },
  { label: 'Harland', value: 'Harland' },
  { label: 'Hârlău', value: 'Hârlău' },
  { label: 'Harlaxton', value: 'Harlaxton' },
  { label: 'Harlech', value: 'Harlech' },
  { label: 'Harleigh', value: 'Harleigh' },
  { label: 'Harlem', value: 'Harlem' },
  { label: 'Harlem', value: 'Harlem' },
  { label: 'Harlem', value: 'Harlem' },
  { label: 'Harlem Heights', value: 'Harlem Heights' },
  { label: 'Harleston', value: 'Harleston' },
  { label: 'Hårlev', value: 'Hårlev' },
  { label: 'Harleysville', value: 'Harleysville' },
  { label: 'Harlingen', value: 'Harlingen' },
  { label: 'Harlingen', value: 'Harlingen' },
  { label: 'Harlington', value: 'Harlington' },
  { label: 'Harlow', value: 'Harlow' },
  { label: 'Harlowton', value: 'Harlowton' },
  { label: 'Harly', value: 'Harly' },
  { label: 'Harmah', value: 'Harmah' },
  { label: 'Hărman', value: 'Hărman' },
  { label: 'Harmancık', value: 'Harmancık' },
  { label: 'Hărmăneasa', value: 'Hărmăneasa' },
  { label: 'Hărmăneștii Vechi', value: 'Hărmăneștii Vechi' },
  { label: 'Harmanli', value: 'Harmanli' },
  { label: 'Harmannsdorf', value: 'Harmannsdorf' },
  { label: 'Harmon County', value: 'Harmon County' },
  { label: 'Harmonia', value: 'Harmonia' },
  { label: 'Harmons', value: 'Harmons' },
  { label: 'Harmony Town', value: 'Harmony Town' },
  { label: 'Harnai', value: 'Harnai' },
  { label: 'Harnai', value: 'Harnai' },
  { label: 'Harnes', value: 'Harnes' },
  { label: 'Harnett County', value: 'Harnett County' },
  { label: 'Harney County', value: 'Harney County' },
  { label: 'Hărnicești', value: 'Hărnicești' },
  { label: 'Harnoli', value: 'Harnoli' },
  { label: 'Härnösand', value: 'Härnösand' },
  { label: 'Haro', value: 'Haro' },
  { label: 'Haro', value: 'Haro' },
  { label: 'Harold Wood', value: 'Harold Wood' },
  { label: 'Harpalpur', value: 'Harpalpur' },
  { label: 'Harpanahalli', value: 'Harpanahalli' },
  { label: 'Harpenden', value: 'Harpenden' },
  { label: 'Harper', value: 'Harper' },
  { label: 'Harper', value: 'Harper' },
  { label: 'Harper', value: 'Harper' },
  { label: 'Harper County', value: 'Harper County' },
  { label: 'Harper County', value: 'Harper County' },
  { label: 'Harper Woods', value: 'Harper Woods' },
  { label: 'Harpersville', value: 'Harpersville' },
  { label: 'Harplinge', value: 'Harplinge' },
  { label: 'Harpole', value: 'Harpole' },
  { label: 'Harpstedt', value: 'Harpstedt' },
  { label: 'Harpswell Center', value: 'Harpswell Center' },
  { label: 'Harqalah', value: 'Harqalah' },
  { label: 'Harrachov', value: 'Harrachov' },
  { label: 'Harrah', value: 'Harrah' },
  { label: 'Harrai', value: 'Harrai' },
  { label: 'Harran', value: 'Harran' },
  { label: 'Harrietsham', value: 'Harrietsham' },
  { label: 'Harriman', value: 'Harriman' },
  { label: 'Harriman', value: 'Harriman' },
  { label: 'Harringay', value: 'Harringay' },
  { label: 'Harrington', value: 'Harrington' },
  { label: 'Harrington', value: 'Harrington' },
  { label: 'Harrington Park', value: 'Harrington Park' },
  { label: 'Harrington Park', value: 'Harrington Park' },
  { label: 'Harris', value: 'Harris' },
  { label: 'Harris County', value: 'Harris County' },
  { label: 'Harris County', value: 'Harris County' },
  { label: 'Harris Hill', value: 'Harris Hill' },
  { label: 'Harris Park', value: 'Harris Park' },
  { label: 'Harrisburg', value: 'Harrisburg' },
  { label: 'Harrisburg', value: 'Harrisburg' },
  { label: 'Harrisburg', value: 'Harrisburg' },
  { label: 'Harrisburg', value: 'Harrisburg' },
  { label: 'Harrisburg', value: 'Harrisburg' },
  { label: 'Harrisburg', value: 'Harrisburg' },
  { label: 'Harrisburg', value: 'Harrisburg' },
  { label: 'Harrisdale', value: 'Harrisdale' },
  { label: 'Harrislee', value: 'Harrislee' },
  { label: 'Harrismith', value: 'Harrismith' },
  { label: 'Harrison', value: 'Harrison' },
  { label: 'Harrison', value: 'Harrison' },
  { label: 'Harrison', value: 'Harrison' },
  { label: 'Harrison', value: 'Harrison' },
  { label: 'Harrison', value: 'Harrison' },
  { label: 'Harrison', value: 'Harrison' },
  { label: 'Harrison', value: 'Harrison' },
  { label: 'Harrison', value: 'Harrison' },
  { label: 'Harrison', value: 'Harrison' },
  { label: 'Harrison', value: 'Harrison' },
  { label: 'Harrison', value: 'Harrison' },
  { label: 'Harrison Brook', value: 'Harrison Brook' },
  { label: 'Harrison County', value: 'Harrison County' },
  { label: 'Harrison County', value: 'Harrison County' },
  { label: 'Harrison County', value: 'Harrison County' },
  { label: 'Harrison County', value: 'Harrison County' },
  { label: 'Harrison County', value: 'Harrison County' },
  { label: 'Harrison County', value: 'Harrison County' },
  { label: 'Harrison County', value: 'Harrison County' },
  { label: 'Harrison County', value: 'Harrison County' },
  { label: 'Harrisonburg', value: 'Harrisonburg' },
  { label: 'Harrisonburg', value: 'Harrisonburg' },
  { label: 'Harrisonville', value: 'Harrisonville' },
  { label: 'Harriston', value: 'Harriston' },
  { label: 'Harristown', value: 'Harristown' },
  { label: 'Harristown', value: 'Harristown' },
  { label: 'Harrisville', value: 'Harrisville' },
  { label: 'Harrisville', value: 'Harrisville' },
  { label: 'Harrisville', value: 'Harrisville' },
  { label: 'Harrisville', value: 'Harrisville' },
  { label: 'Harrisville', value: 'Harrisville' },
  { label: 'Harrodsburg', value: 'Harrodsburg' },
  { label: 'Harrogate', value: 'Harrogate' },
  { label: 'Harrogate', value: 'Harrogate' },
  { label: 'Harrold', value: 'Harrold' },
  { label: 'Harrow On The Hill', value: 'Harrow On The Hill' },
  { label: 'Harry Watch', value: 'Harry Watch' },
  { label: 'Harsány', value: 'Harsány' },
  { label: 'Harsdorf', value: 'Harsdorf' },
  { label: 'Harsefeld', value: 'Harsefeld' },
  { label: 'Hârşeni', value: 'Hârşeni' },
  { label: 'Hârseşti', value: 'Hârseşti' },
  { label: 'Harsewinkel', value: 'Harsewinkel' },
  { label: 'Harsin', value: 'Harsin' },
  { label: 'Harskamp', value: 'Harskamp' },
  { label: 'Harsleben', value: 'Harsleben' },
  { label: 'Harsol', value: 'Harsol' },
  { label: 'Hârşova', value: 'Hârşova' },
  { label: 'Harstad', value: 'Harstad' },
  { label: 'Harston', value: 'Harston' },
  { label: 'Harsud', value: 'Harsud' },
  { label: 'Harsum', value: 'Harsum' },
  { label: 'Hart', value: 'Hart' },
  { label: 'Hart', value: 'Hart' },
  { label: 'Hart', value: 'Hart' },
  { label: 'Hart', value: 'Hart' },
  { label: 'Hart Bei Graz', value: 'Hart Bei Graz' },
  { label: 'Hart County', value: 'Hart County' },
  { label: 'Hart County', value: 'Hart County' },
  { label: 'Hart Im Zillertal', value: 'Hart Im Zillertal' },
  { label: 'Harta', value: 'Harta' },
  { label: 'Hărțăgani', value: 'Hărțăgani' },
  { label: 'Hărțău', value: 'Hărțău' },
  { label: 'Hartberg', value: 'Hartberg' },
  { label: 'Hartenholm', value: 'Hartenholm' },
  { label: 'Hartenstein', value: 'Hartenstein' },
  { label: 'Hartenstein', value: 'Hartenstein' },
  { label: 'Hartford', value: 'Hartford' },
  { label: 'Hartford', value: 'Hartford' },
  { label: 'Hartford', value: 'Hartford' },
  { label: 'Hartford', value: 'Hartford' },
  { label: 'Hartford', value: 'Hartford' },
  { label: 'Hartford', value: 'Hartford' },
  { label: 'Hartford', value: 'Hartford' },
  { label: 'Hartford', value: 'Hartford' },
  { label: 'Hartford', value: 'Hartford' },
  { label: 'Hartford', value: 'Hartford' },
  { label: 'Hartford City', value: 'Hartford City' },
  { label: 'Hartford County', value: 'Hartford County' },
  { label: 'Hartha', value: 'Hartha' },
  { label: 'Harthausen', value: 'Harthausen' },
  { label: 'Hartheim', value: 'Hartheim' },
  { label: 'Hartheim', value: 'Hartheim' },
  { label: 'Harthill', value: 'Harthill' },
  { label: 'Harthill', value: 'Harthill' },
  { label: 'Harthiya', value: 'Harthiya' },
  { label: 'Harthiyah', value: 'Harthiyah' },
  { label: 'Hârtieşti', value: 'Hârtieşti' },
  { label: 'Hartington', value: 'Hartington' },
  { label: 'Hartkirchen', value: 'Hartkirchen' },
  { label: 'Hartl', value: 'Hartl' },
  { label: 'Hartland', value: 'Hartland' },
  { label: 'Hartlebury', value: 'Hartlebury' },
  { label: 'Hartlepool', value: 'Hartlepool' },
  { label: 'Hartley', value: 'Hartley' },
  { label: 'Hartley', value: 'Hartley' },
  { label: 'Hartley', value: 'Hartley' },
  { label: 'Hartley County', value: 'Hartley County' },
  { label: 'Hartley Wintney', value: 'Hartley Wintney' },
  { label: 'Hartmanice', value: 'Hartmanice' },
  { label: 'Hartmannsdorf', value: 'Hartmannsdorf' },
  { label: 'Hartmannsdorf', value: 'Hartmannsdorf' },
  { label: 'Hârtoape', value: 'Hârtoape' },
  { label: 'Hartola', value: 'Hartola' },
  { label: 'Hârtop', value: 'Hârtop' },
  { label: 'Hartsdale', value: 'Hartsdale' },
  { label: 'Hartselle', value: 'Hartselle' },
  { label: 'Hartshill', value: 'Hartshill' },
  { label: 'Hartshorne', value: 'Hartshorne' },
  { label: 'Hartstown', value: 'Hartstown' },
  { label: 'Hartsville', value: 'Hartsville' },
  { label: 'Hartsville', value: 'Hartsville' },
  { label: 'Hartville', value: 'Hartville' },
  { label: 'Hartville', value: 'Hartville' },
  { label: 'Hartwell', value: 'Hartwell' },
  { label: 'Hartwell', value: 'Hartwell' },
  { label: 'Harunabad', value: 'Harunabad' },
  { label: 'Harur', value: 'Harur' },
  { label: 'Harvard', value: 'Harvard' },
  { label: 'Harvard', value: 'Harvard' },
  { label: 'Harvest', value: 'Harvest' },
  { label: 'Harvey', value: 'Harvey' },
  { label: 'Harvey', value: 'Harvey' },
  { label: 'Harvey', value: 'Harvey' },
  { label: 'Harvey', value: 'Harvey' },
  { label: 'Harvey', value: 'Harvey' },
  { label: 'Harvey County', value: 'Harvey County' },
  { label: 'Harveys Lake', value: 'Harveys Lake' },
  { label: 'Harvington', value: 'Harvington' },
  { label: 'Harwell', value: 'Harwell' },
  { label: 'Harwich', value: 'Harwich' },
  { label: 'Harwich', value: 'Harwich' },
  { label: 'Harwich Center', value: 'Harwich Center' },
  { label: 'Harwich Port', value: 'Harwich Port' },
  { label: 'Harwood Heights', value: 'Harwood Heights' },
  { label: 'Harxheim', value: 'Harxheim' },
  { label: 'Harzgerode', value: 'Harzgerode' },
  { label: 'Hasaki', value: 'Hasaki' },
  { label: 'Hasan Abdal', value: 'Hasan Abdal' },
  { label: 'Hasan Kusej', value: 'Hasan Kusej' },
  { label: 'Hasan Teymur', value: 'Hasan Teymur' },
  { label: 'Hasanabad', value: 'Hasanabad' },
  { label: 'Hasanabad', value: 'Hasanabad' },
  { label: 'Hasanabad Yasukand', value: 'Hasanabad Yasukand' },
  { label: 'Hasanabad-E Emam', value: 'Hasanabad-E Emam' },
  { label: 'Hasanabad-E Qush Bolagh', value: 'Hasanabad-E Qush Bolagh' },
  { label: 'Hasanabad-E Sheverin', value: 'Hasanabad-E Sheverin' },
  { label: 'Hasanbeyli', value: 'Hasanbeyli' },
  { label: 'Hasankeyf', value: 'Hasankeyf' },
  { label: 'Hasanpur', value: 'Hasanpur' },
  { label: 'Hasanpur', value: 'Hasanpur' },
  { label: 'Hasbergen', value: 'Hasbergen' },
  { label: 'Hasbrouck Heights', value: 'Hasbrouck Heights' },
  { label: 'Hasel', value: 'Hasel' },
  { label: 'Haselau', value: 'Haselau' },
  { label: 'Haselbach', value: 'Haselbach' },
  { label: 'Haselbach', value: 'Haselbach' },
  { label: 'Haselbachtal', value: 'Haselbachtal' },
  { label: 'Haseldorf', value: 'Haseldorf' },
  { label: 'Häselgehr', value: 'Häselgehr' },
  { label: 'Haselhorst', value: 'Haselhorst' },
  { label: 'Haselünne', value: 'Haselünne' },
  { label: 'Hasenkamp', value: 'Hasenkamp' },
  { label: 'Hashima', value: 'Hashima' },
  { label: 'Hashima-Shi', value: 'Hashima-Shi' },
  { label: 'Hashimoto', value: 'Hashimoto' },
  { label: 'Hashimoto Shi', value: 'Hashimoto Shi' },
  { label: 'Hashjin', value: 'Hashjin' },
  { label: 'Hasht Bandi', value: 'Hasht Bandi' },
  { label: 'Hashtgerd', value: 'Hashtgerd' },
  { label: 'Hashtgerd New City', value: 'Hashtgerd New City' },
  { label: 'Hashtpar', value: 'Hashtpar' },
  { label: 'Hashtrood', value: 'Hashtrood' },
  { label: 'Hashtrud', value: 'Hashtrud' },
  { label: 'Hasilpur', value: 'Hasilpur' },
  { label: 'Hasimara', value: 'Hasimara' },
  { label: 'Haskell', value: 'Haskell' },
  { label: 'Haskell', value: 'Haskell' },
  { label: 'Haskell', value: 'Haskell' },
  { label: 'Haskell County', value: 'Haskell County' },
  { label: 'Haskell County', value: 'Haskell County' },
  { label: 'Haskell County', value: 'Haskell County' },
  { label: 'Haskins', value: 'Haskins' },
  { label: 'Haskovo', value: 'Haskovo' },
  { label: 'Hasköy', value: 'Hasköy' },
  { label: 'Haslach', value: 'Haslach' },
  { label: 'Haslach An Der Mühl', value: 'Haslach An Der Mühl' },
  { label: 'Hasle', value: 'Hasle' },
  { label: 'Haslemere', value: 'Haslemere' },
  { label: 'Haslen', value: 'Haslen' },
  { label: 'Haslet', value: 'Haslet' },
  { label: 'Haslett', value: 'Haslett' },
  { label: 'Haslev', value: 'Haslev' },
  { label: 'Haslingden', value: 'Haslingden' },
  { label: 'Haslingfield', value: 'Haslingfield' },
  { label: 'Hasloch', value: 'Hasloch' },
  { label: 'Hasloh', value: 'Hasloh' },
  { label: 'Hășmaș', value: 'Hășmaș' },
  { label: 'Hasnon', value: 'Hasnon' },
  { label: 'Hasparren', value: 'Hasparren' },
  { label: 'Haspres', value: 'Haspres' },
  { label: 'Hassa', value: 'Hassa' },
  { label: 'Hassall Grove', value: 'Hassall Grove' },
  { label: 'Hassan', value: 'Hassan' },
  { label: 'Hassan Abad', value: 'Hassan Abad' },
  { label: 'Haßbergen', value: 'Haßbergen' },
  { label: 'Hassel', value: 'Hassel' },
  { label: 'Hasselfelde', value: 'Hasselfelde' },
  { label: 'Hasselt', value: 'Hasselt' },
  { label: 'Hasselt', value: 'Hasselt' },
  { label: 'Hassendorf', value: 'Hassendorf' },
  { label: 'Haßfurt', value: 'Haßfurt' },
  { label: 'Hassi Messaoud', value: 'Hassi Messaoud' },
  { label: 'Hassi Messaoud', value: 'Hassi Messaoud' },
  { label: 'Haßleben', value: 'Haßleben' },
  { label: 'Hässleholm', value: 'Hässleholm' },
  { label: 'Hasslö', value: 'Hasslö' },
  { label: 'Haßloch', value: 'Haßloch' },
  { label: 'Haßmersheim', value: 'Haßmersheim' },
  { label: 'Hassocks', value: 'Hassocks' },
  { label: 'Hasson Heights', value: 'Hasson Heights' },
  { label: 'Haste', value: 'Haste' },
  { label: 'Hastière-Lavaux', value: 'Hastière-Lavaux' },
  { label: 'Hastinapur', value: 'Hastinapur' },
  { label: 'Hastings', value: 'Hastings' },
  { label: 'Hastings', value: 'Hastings' },
  { label: 'Hastings', value: 'Hastings' },
  { label: 'Hastings', value: 'Hastings' },
  { label: 'Hastings', value: 'Hastings' },
  { label: 'Hastings', value: 'Hastings' },
  { label: 'Hastings', value: 'Hastings' },
  { label: 'Hastings', value: 'Hastings' },
  { label: 'Hastings-On-Hudson', value: 'Hastings-On-Hudson' },
  { label: 'Hästveda', value: 'Hästveda' },
  { label: 'Hasuda', value: 'Hasuda' },
  { label: 'Hasuda-Shi', value: 'Hasuda-Shi' },
  { label: 'Hasvik', value: 'Hasvik' },
  { label: 'Haswell', value: 'Haswell' },
  { label: 'Hat', value: 'Hat' },
  { label: 'Hať', value: 'Hať' },
  { label: 'Hat Yai', value: 'Hat Yai' },
  { label: 'Hata', value: 'Hata' },
  { label: 'Hata (India)', value: 'Hata (India)' },
  { label: 'Hatava', value: 'Hatava' },
  { label: 'Hatboro', value: 'Hatboro' },
  { label: 'Hatch', value: 'Hatch' },
  { label: 'Haţeg', value: 'Haţeg' },
  { label: 'Hatert', value: 'Hatert' },
  { label: 'Hatfield', value: 'Hatfield' },
  { label: 'Hatfield', value: 'Hatfield' },
  { label: 'Hatfield', value: 'Hatfield' },
  { label: 'Hatfield', value: 'Hatfield' },
  { label: 'Hatfield Heath', value: 'Hatfield Heath' },
  { label: 'Hatfield Peverel', value: 'Hatfield Peverel' },
  { label: 'Hatharaliyadda', value: 'Hatharaliyadda' },
  { label: 'Hatherleigh', value: 'Hatherleigh' },
  { label: 'Hathern', value: 'Hathern' },
  { label: 'Hathersage', value: 'Hathersage' },
  { label: 'Hathras', value: 'Hathras' },
  { label: 'Hathuran', value: 'Hathuran' },
  { label: 'Hatib', value: 'Hatib' },
  { label: 'Hatillo', value: 'Hatillo' },
  { label: 'Hatillo', value: 'Hatillo' },
  { label: 'Hatillo De Loba', value: 'Hatillo De Loba' },
  { label: 'Hatillo Municipio', value: 'Hatillo Municipio' },
  { label: 'Hatillo Palma', value: 'Hatillo Palma' },
  { label: 'Ḩātim', value: 'Ḩātim' },
  { label: 'Hato', value: 'Hato' },
  { label: 'Hato Arriba', value: 'Hato Arriba' },
  { label: 'Hato Candal', value: 'Hato Candal' },
  { label: 'Hato Chami', value: 'Hato Chami' },
  { label: 'Hato Corotú', value: 'Hato Corotú' },
  { label: 'Hato Corozal', value: 'Hato Corozal' },
  { label: 'Hato Mayor Del Rey', value: 'Hato Mayor Del Rey' },
  { label: 'Hato Montaña', value: 'Hato Montaña' },
  { label: 'Hatod', value: 'Hatod' },
  { label: 'Hatogaya-Honchō', value: 'Hatogaya-Honchō' },
  { label: 'Hatonuevo', value: 'Hatonuevo' },
  { label: 'Hato-Udo', value: 'Hato-Udo' },
  { label: 'Hats’Avan', value: 'Hats’Avan' },
  { label: 'Hatsukaichi', value: 'Hatsukaichi' },
  { label: 'Hatsukaichi-Shi', value: 'Hatsukaichi-Shi' },
  { label: 'Hatta', value: 'Hatta' },
  { label: 'Hattem', value: 'Hattem' },
  { label: 'Hattemerbroek', value: 'Hattemerbroek' },
  { label: 'Hatten', value: 'Hatten' },
  { label: 'Hatten', value: 'Hatten' },
  { label: 'Hattenhofen', value: 'Hattenhofen' },
  { label: 'Hattenhofen', value: 'Hattenhofen' },
  { label: 'Hattersheim', value: 'Hattersheim' },
  { label: 'Hattert', value: 'Hattert' },
  { label: 'Hattfjelldal', value: 'Hattfjelldal' },
  { label: 'Hattiesburg', value: 'Hattiesburg' },
  { label: 'Hatting', value: 'Hatting' },
  { label: 'Hattingen', value: 'Hattingen' },
  { label: 'Hatton', value: 'Hatton' },
  { label: 'Hatton', value: 'Hatton' },
  { label: 'Hatton Vale', value: 'Hatton Vale' },
  { label: 'Hattorf', value: 'Hattorf' },
  { label: 'Hattstedt', value: 'Hattstedt' },
  { label: 'Hatulia', value: 'Hatulia' },
  { label: 'Hatvan', value: 'Hatvan' },
  { label: 'Hatvani Járás', value: 'Hatvani Járás' },
  { label: 'Hatzenbühl', value: 'Hatzenbühl' },
  { label: 'Hatzendorf', value: 'Hatzendorf' },
  { label: 'Hatzfeld', value: 'Hatzfeld' },
  { label: 'Hau‘Ula', value: 'Hau‘Ula' },
  { label: 'Haubourdin', value: 'Haubourdin' },
  { label: 'Haubstadt', value: 'Haubstadt' },
  { label: 'Haucourt', value: 'Haucourt' },
  { label: 'Hauenstein', value: 'Hauenstein' },
  { label: 'Hauge I Dalane', value: 'Hauge I Dalane' },
  { label: 'Haugesund', value: 'Haugesund' },
  { label: 'Haughley', value: 'Haughley' },
  { label: 'Haughton', value: 'Haughton' },
  { label: 'Haughton Green', value: 'Haughton Green' },
  { label: 'Haugschlag', value: 'Haugschlag' },
  { label: 'Haugsdorf', value: 'Haugsdorf' },
  { label: 'Hauho', value: 'Hauho' },
  { label: 'Haukipudas', value: 'Haukipudas' },
  { label: 'Haukivuori', value: 'Haukivuori' },
  { label: 'Hauknes', value: 'Hauknes' },
  { label: 'Haulchin', value: 'Haulchin' },
  { label: 'Haulerwijk', value: 'Haulerwijk' },
  { label: 'Haundorf', value: 'Haundorf' },
  { label: 'Haunoldstein', value: 'Haunoldstein' },
  { label: 'Haunsheim', value: 'Haunsheim' },
  { label: 'Hauppauge', value: 'Hauppauge' },
  { label: 'Hauptstuhl', value: 'Hauptstuhl' },
  { label: 'Hau-Ri', value: 'Hau-Ri' },
  { label: 'Haus', value: 'Haus' },
  { label: 'Haus Im Ennstal', value: 'Haus Im Ennstal' },
  { label: 'Hausach', value: 'Hausach' },
  { label: 'Hausbrunn', value: 'Hausbrunn' },
  { label: 'Hausen', value: 'Hausen' },
  { label: 'Hausen', value: 'Hausen' },
  { label: 'Hausen', value: 'Hausen' },
  { label: 'Hausen', value: 'Hausen' },
  { label: 'Hausen Am Albis / Hausen (Dorf)', value: 'Hausen Am Albis / Hausen (Dorf)' },
  { label: 'Häusern', value: 'Häusern' },
  { label: 'Hausham', value: 'Hausham' },
  { label: 'Hausjärvi', value: 'Hausjärvi' },
  { label: 'Hauskirchen', value: 'Hauskirchen' },
  { label: 'Hausleiten', value: 'Hausleiten' },
  { label: 'Hausmannstätten', value: 'Hausmannstätten' },
  { label: 'Hausmening', value: 'Hausmening' },
  { label: 'Haussy', value: 'Haussy' },
  { label: 'Hauswalde', value: 'Hauswalde' },
  { label: 'Haut Katanga', value: 'Haut Katanga' },
  { label: 'Hautcharage', value: 'Hautcharage' },
  { label: 'Hautefort', value: 'Hautefort' },
  { label: 'Haute-Garonne', value: 'Haute-Garonne' },
  { label: 'Haute-Goulaine', value: 'Haute-Goulaine' },
  { label: 'Haute-Loire', value: 'Haute-Loire' },
  { label: 'Haute-Marne', value: 'Haute-Marne' },
  { label: 'Hauterive', value: 'Hauterive' },
  { label: 'Hauterive', value: 'Hauterive' },
  { label: 'Hauterives', value: 'Hauterives' },
  { label: 'Haute-Rivoire', value: 'Haute-Rivoire' },
  { label: 'Hautes-Alpes', value: 'Hautes-Alpes' },
  { label: 'Haute-Savoie', value: 'Haute-Savoie' },
  { label: 'Haute-Vienne', value: 'Haute-Vienne' },
  { label: 'Hauteville-Lès-Dijon', value: 'Hauteville-Lès-Dijon' },
  { label: 'Hauteville-Lompnes', value: 'Hauteville-Lompnes' },
  { label: 'Hautmont', value: 'Hautmont' },
  { label: 'Hautot-Sur-Mer', value: 'Hautot-Sur-Mer' },
  { label: 'Haut-Rhin', value: 'Haut-Rhin' },
  { label: 'Haut-Sassandra', value: 'Haut-Sassandra' },
  { label: 'Hauts-De-Seine', value: 'Hauts-De-Seine' },
  { label: 'Hauts-Plateaux', value: 'Hauts-Plateaux' },
  { label: 'Hauville', value: 'Hauville' },
  { label: 'Hauwert', value: 'Hauwert' },
  { label: 'Hauzenberg', value: 'Hauzenberg' },
  { label: 'Havana', value: 'Havana' },
  { label: 'Havana', value: 'Havana' },
  { label: 'Havana', value: 'Havana' },
  { label: 'Havant', value: 'Havant' },
  { label: 'Havârna', value: 'Havârna' },
  { label: 'Havdrup', value: 'Havdrup' },
  { label: 'Havelange', value: 'Havelange' },
  { label: 'Havelberg', value: 'Havelberg' },
  { label: 'Haveli Lakha', value: 'Haveli Lakha' },
  { label: 'Havelian', value: 'Havelian' },
  { label: 'Havelock', value: 'Havelock' },
  { label: 'Havelte', value: 'Havelte' },
  { label: 'Haveluloto', value: 'Haveluloto' },
  { label: 'Haveluy', value: 'Haveluy' },
  { label: 'Haven', value: 'Haven' },
  { label: 'Haven', value: 'Haven' },
  { label: 'Havendale', value: 'Havendale' },
  { label: 'Haverdal', value: 'Haverdal' },
  { label: 'Haverfordwest', value: 'Haverfordwest' },
  { label: 'Haverhill', value: 'Haverhill' },
  { label: 'Haverhill', value: 'Haverhill' },
  { label: 'Haverhill', value: 'Haverhill' },
  { label: 'Haverhill', value: 'Haverhill' },
  { label: 'Haveri', value: 'Haveri' },
  { label: 'Haverigg', value: 'Haverigg' },
  { label: 'Haverlah', value: 'Haverlah' },
  { label: 'Haverskerque', value: 'Haverskerque' },
  { label: 'Haverstraw', value: 'Haverstraw' },
  { label: 'Haviland', value: 'Haviland' },
  { label: 'Haviq', value: 'Haviq' },
  { label: 'Havířov', value: 'Havířov' },
  { label: 'Havixbeck', value: 'Havixbeck' },
  { label: 'Havlíčkův Brod', value: 'Havlíčkův Brod' },
  { label: 'Havran', value: 'Havran' },
  { label: 'Havre', value: 'Havre' },
  { label: 'Havre De Grace', value: 'Havre De Grace' },
  { label: 'Havre-Saint-Pierre', value: 'Havre-Saint-Pierre' },
  { label: 'Havsa', value: 'Havsa' },
  { label: 'Havza', value: 'Havza' },
  { label: 'Haw River', value: 'Haw River' },
  { label: 'Hawaii County', value: 'Hawaii County' },
  { label: 'Hawaiian Acres', value: 'Hawaiian Acres' },
  { label: 'Hawaiian Beaches', value: 'Hawaiian Beaches' },
  { label: 'Hawaiian Gardens', value: 'Hawaiian Gardens' },
  { label: 'Hawaiian Ocean View', value: 'Hawaiian Ocean View' },
  { label: 'Hawaiian Paradise Park', value: 'Hawaiian Paradise Park' },
  { label: 'Ḩawallī', value: 'Ḩawallī' },
  { label: 'Hawangen', value: 'Hawangen' },
  { label: 'Hawarden', value: 'Hawarden' },
  { label: 'Hawarden', value: 'Hawarden' },
  { label: 'Hawassa', value: 'Hawassa' },
  { label: 'Hawera', value: 'Hawera' },
  { label: 'Hawesville', value: 'Hawesville' },
  { label: 'Hawf', value: 'Hawf' },
  { label: 'Hāwī', value: 'Hāwī' },
  { label: 'Hawick', value: 'Hawick' },
  { label: 'Hawker', value: 'Hawker' },
  { label: 'Hawkesbury', value: 'Hawkesbury' },
  { label: 'Hawkesbury', value: 'Hawkesbury' },
  { label: 'Hawkhurst', value: 'Hawkhurst' },
  { label: 'Hawkinge', value: 'Hawkinge' },
  { label: 'Hawkins', value: 'Hawkins' },
  { label: 'Hawkins County', value: 'Hawkins County' },
  { label: 'Hawkinsville', value: 'Hawkinsville' },
  { label: 'Hawks Nest', value: 'Hawks Nest' },
  { label: 'Hawley', value: 'Hawley' },
  { label: 'Hawley', value: 'Hawley' },
  { label: 'Haworth', value: 'Haworth' },
  { label: 'Haworth', value: 'Haworth' },
  { label: 'Ḩawsh ‘Īsá', value: 'Ḩawsh ‘Īsá' },
  { label: 'Hawthorn', value: 'Hawthorn' },
  { label: 'Hawthorn', value: 'Hawthorn' },
  { label: 'Hawthorn', value: 'Hawthorn' },
  { label: 'Hawthorn East', value: 'Hawthorn East' },
  { label: 'Hawthorn South', value: 'Hawthorn South' },
  { label: 'Hawthorn Woods', value: 'Hawthorn Woods' },
  { label: 'Hawthorndene', value: 'Hawthorndene' },
  { label: 'Hawthorne', value: 'Hawthorne' },
  { label: 'Hawthorne', value: 'Hawthorne' },
  { label: 'Hawthorne', value: 'Hawthorne' },
  { label: 'Hawthorne', value: 'Hawthorne' },
  { label: 'Hawthorne', value: 'Hawthorne' },
  { label: 'Hawthorne', value: 'Hawthorne' },
  { label: 'Haxby', value: 'Haxby' },
  { label: 'Haxey', value: 'Haxey' },
  { label: 'Hay', value: 'Hay' },
  { label: 'Hay', value: 'Hay' },
  { label: 'Hay Point', value: 'Hay Point' },
  { label: 'Hay River', value: 'Hay River' },
  { label: 'Hayama', value: 'Hayama' },
  { label: 'Hayang', value: 'Hayang' },
  { label: 'Hayange', value: 'Hayange' },
  { label: 'Hayanist', value: 'Hayanist' },
  { label: 'Haybes', value: 'Haybes' },
  { label: 'Hayborough', value: 'Hayborough' },
  { label: 'Haydan', value: 'Haydan' },
  { label: 'Hayden', value: 'Hayden' },
  { label: 'Hayden', value: 'Hayden' },
  { label: 'Hayden', value: 'Hayden' },
  { label: 'Haydock', value: 'Haydock' },
  { label: 'Haydon Bridge', value: 'Haydon Bridge' },
  { label: 'Hayes', value: 'Hayes' },
  { label: 'Hayes', value: 'Hayes' },
  { label: 'Hayes Center', value: 'Hayes Center' },
  { label: 'Hayes County', value: 'Hayes County' },
  { label: 'Hayes Subdivision', value: 'Hayes Subdivision' },
  { label: 'Hayesville', value: 'Hayesville' },
  { label: 'Hayesville', value: 'Hayesville' },
  { label: 'Hayfan', value: 'Hayfan' },
  { label: 'Hayfield', value: 'Hayfield' },
  { label: 'Hayfield', value: 'Hayfield' },
  { label: 'Hayfield', value: 'Hayfield' },
  { label: 'Hayfield', value: 'Hayfield' },
  { label: 'Hayfork', value: 'Hayfork' },
  { label: 'Hayingen', value: 'Hayingen' },
  { label: 'Haykashen', value: 'Haykashen' },
  { label: 'Haykavan', value: 'Haykavan' },
  { label: 'Haylat Radi Al Baham', value: 'Haylat Radi Al Baham' },
  { label: 'Hayle', value: 'Hayle' },
  { label: 'Hayling Island', value: 'Hayling Island' },
  { label: 'Haymā’', value: 'Haymā’' },
  { label: 'Haymana', value: 'Haymana' },
  { label: 'Haymarket', value: 'Haymarket' },
  { label: 'Haymarket', value: 'Haymarket' },
  { label: 'Haynes', value: 'Haynes' },
  { label: 'Haynesville', value: 'Haynesville' },
  { label: 'Hayneville', value: 'Hayneville' },
  { label: 'Hayrabolu', value: 'Hayrabolu' },
  { label: 'Hayran', value: 'Hayran' },
  { label: 'Hayrat', value: 'Hayrat' },
  { label: 'Hayredin', value: 'Hayredin' },
  { label: 'Hays', value: 'Hays' },
  { label: 'Hays', value: 'Hays' },
  { label: 'Hays', value: 'Hays' },
  { label: 'Hays County', value: 'Hays County' },
  { label: 'Haysville', value: 'Haysville' },
  { label: 'Hayti', value: 'Hayti' },
  { label: 'Hayti', value: 'Hayti' },
  { label: 'Hayton', value: 'Hayton' },
  { label: 'Hayuliang', value: 'Hayuliang' },
  { label: 'Hayvoron', value: 'Hayvoron' },
  { label: 'Hayward', value: 'Hayward' },
  { label: 'Hayward', value: 'Hayward' },
  { label: 'Haywards Heath', value: 'Haywards Heath' },
  { label: 'Haywood County', value: 'Haywood County' },
  { label: 'Haywood County', value: 'Haywood County' },
  { label: 'Ḩayy Al Bunayyāt', value: 'Ḩayy Al Bunayyāt' },
  { label: 'Ḩayy Al Quwaysimah', value: 'Ḩayy Al Quwaysimah' },
  { label: 'Haza', value: 'Haza' },
  { label: 'Hazard', value: 'Hazard' },
  { label: 'Hazard', value: 'Hazard' },
  { label: 'Hazardville', value: 'Hazardville' },
  { label: 'Hazaribag', value: 'Hazaribag' },
  { label: 'Hazaribagh', value: 'Hazaribagh' },
  { label: 'Hazas De Cesto', value: 'Hazas De Cesto' },
  { label: 'Hazebrouck', value: 'Hazebrouck' },
  { label: 'Hazel Crest', value: 'Hazel Crest' },
  { label: 'Hazel Dell', value: 'Hazel Dell' },
  { label: 'Hazel Green', value: 'Hazel Green' },
  { label: 'Hazel Green', value: 'Hazel Green' },
  { label: 'Hazel Grove', value: 'Hazel Grove' },
  { label: 'Hazel Park', value: 'Hazel Park' },
  { label: 'Hazelbrook', value: 'Hazelbrook' },
  { label: 'Hazelwood', value: 'Hazelwood' },
  { label: 'Hazelwood', value: 'Hazelwood' },
  { label: 'Hazelwood North', value: 'Hazelwood North' },
  { label: 'Hazelwood Park', value: 'Hazelwood Park' },
  { label: 'Hazen', value: 'Hazen' },
  { label: 'Hazen', value: 'Hazen' },
  { label: 'Hażlach', value: 'Hażlach' },
  { label: 'Hazlehurst', value: 'Hazlehurst' },
  { label: 'Hazlehurst', value: 'Hazlehurst' },
  { label: 'Hazlerigg', value: 'Hazlerigg' },
  { label: 'Hazlet Township', value: 'Hazlet Township' },
  { label: 'Hazleton', value: 'Hazleton' },
  { label: 'Hazlov', value: 'Hazlov' },
  { label: 'Hazm Al Udayn', value: 'Hazm Al Udayn' },
  { label: 'Hazorasp', value: 'Hazorasp' },
  { label: 'Hazro', value: 'Hazro' },
  { label: 'Hazro', value: 'Hazro' },
  { label: 'Ħaż-Żebbuġ', value: 'Ħaż-Żebbuġ' },
  { label: 'He‘Eia', value: 'He‘Eia' },
  { label: 'Heacham', value: 'Heacham' },
  { label: 'Head Of Muir', value: 'Head Of Muir' },
  { label: 'Head Of The Harbor', value: 'Head Of The Harbor' },
  { label: 'Head Of Westport', value: 'Head Of Westport' },
  { label: 'Headcorn', value: 'Headcorn' },
  { label: 'Headingley', value: 'Headingley' },
  { label: 'Headland', value: 'Headland' },
  { label: 'Headlands', value: 'Headlands' },
  { label: 'Heage', value: 'Heage' },
  { label: 'Healdsburg', value: 'Healdsburg' },
  { label: 'Healdton', value: 'Healdton' },
  { label: 'Healesville', value: 'Healesville' },
  { label: 'Healing', value: 'Healing' },
  { label: 'Healy', value: 'Healy' },
  { label: 'Healy', value: 'Healy' },
  { label: 'Heanor', value: 'Heanor' },
  { label: 'Heard County', value: 'Heard County' },
  { label: 'Hearne', value: 'Hearne' },
  { label: 'Hearst', value: 'Hearst' },
  { label: 'Heartease', value: 'Heartease' },
  { label: 'Heath', value: 'Heath' },
  { label: 'Heath', value: 'Heath' },
  { label: 'Heath And Reach', value: 'Heath And Reach' },
  { label: 'Heathcote', value: 'Heathcote' },
  { label: 'Heathcote', value: 'Heathcote' },
  { label: 'Heathcote', value: 'Heathcote' },
  { label: 'Heatherton', value: 'Heatherton' },
  { label: 'Heathfield', value: 'Heathfield' },
  { label: 'Heathmont', value: 'Heathmont' },
  { label: 'Heathridge', value: 'Heathridge' },
  { label: 'Heathrow', value: 'Heathrow' },
  { label: 'Heathsville', value: 'Heathsville' },
  { label: 'Heathwood', value: 'Heathwood' },
  { label: 'Heatley', value: 'Heatley' },
  { label: 'Heaton Chapel', value: 'Heaton Chapel' },
  { label: 'Heavener', value: 'Heavener' },
  { label: 'Heavitree', value: 'Heavitree' },
  { label: 'Hebbronville', value: 'Hebbronville' },
  { label: 'Hebburn', value: 'Hebburn' },
  { label: 'Hebden Bridge', value: 'Hebden Bridge' },
  { label: 'Hebeitun', value: 'Hebeitun' },
  { label: 'Heber', value: 'Heber' },
  { label: 'Heber City', value: 'Heber City' },
  { label: 'Heber Springs', value: 'Heber Springs' },
  { label: 'Heber-Overgaard', value: 'Heber-Overgaard' },
  { label: 'Hebersham', value: 'Hebersham' },
  { label: 'Hebertsfelden', value: 'Hebertsfelden' },
  { label: 'Hebertshausen', value: 'Hebertshausen' },
  { label: 'Hebi', value: 'Hebi' },
  { label: 'Hebron', value: 'Hebron' },
  { label: 'Hebron', value: 'Hebron' },
  { label: 'Hebron', value: 'Hebron' },
  { label: 'Hebron', value: 'Hebron' },
  { label: 'Hebron', value: 'Hebron' },
  { label: 'Hebron', value: 'Hebron' },
  { label: 'Hebron', value: 'Hebron' },
  { label: 'Hebron', value: 'Hebron' },
  { label: 'Hebron', value: 'Hebron' },
  { label: 'Hebron Estates', value: 'Hebron Estates' },
  { label: 'Heby', value: 'Heby' },
  { label: 'Hecelchakán', value: 'Hecelchakán' },
  { label: 'Hechi Shi', value: 'Hechi Shi' },
  { label: 'Hechingen', value: 'Hechingen' },
  { label: 'Hechthausen', value: 'Hechthausen' },
  { label: 'Hechuan', value: 'Hechuan' },
  { label: 'Heci', value: 'Heci' },
  { label: 'Heckenberg', value: 'Heckenberg' },
  { label: 'Heckington', value: 'Heckington' },
  { label: 'Hecklingen', value: 'Hecklingen' },
  { label: 'Heckmondwike', value: 'Heckmondwike' },
  { label: 'Hector', value: 'Hector' },
  { label: 'Héctor Caballero', value: 'Héctor Caballero' },
  { label: 'Hectors River', value: 'Hectors River' },
  { label: 'Hectorville', value: 'Hectorville' },
  { label: 'Hecun', value: 'Hecun' },
  { label: 'Heda', value: 'Heda' },
  { label: 'Hedaru', value: 'Hedaru' },
  { label: 'Hedaru', value: 'Hedaru' },
  { label: 'Heddesheim', value: 'Heddesheim' },
  { label: 'Heddon Greta', value: 'Heddon Greta' },
  { label: 'Heddon On The Wall', value: 'Heddon On The Wall' },
  { label: 'Hede', value: 'Hede' },
  { label: 'Hédé-Bazouges', value: 'Hédé-Bazouges' },
  { label: 'Hedehusene', value: 'Hedehusene' },
  { label: 'Hedel', value: 'Hedel' },
  { label: 'Hedemora', value: 'Hedemora' },
  { label: 'Hedensted', value: 'Hedensted' },
  { label: 'Hedensted Kommune', value: 'Hedensted Kommune' },
  { label: 'Hedersleben', value: 'Hedersleben' },
  { label: 'Hedesunda', value: 'Hedesunda' },
  { label: 'Hedge End', value: 'Hedge End' },
  { label: 'Hedingen', value: 'Hedingen' },
  { label: 'Hedon', value: 'Hedon' },
  { label: 'Hedwig Village', value: 'Hedwig Village' },
  { label: 'Heechterp', value: 'Heechterp' },
  { label: 'Heede', value: 'Heede' },
  { label: 'Heeg', value: 'Heeg' },
  { label: 'Heek', value: 'Heek' },
  { label: 'Heel', value: 'Heel' },
  { label: 'Heelsum', value: 'Heelsum' },
  { label: 'Heemsen', value: 'Heemsen' },
  { label: 'Heemskerk', value: 'Heemskerk' },
  { label: 'Heemstede', value: 'Heemstede' },
  { label: 'Heer', value: 'Heer' },
  { label: 'Heerde', value: 'Heerde' },
  { label: 'Heere', value: 'Heere' },
  { label: 'Heerenveen', value: 'Heerenveen' },
  { label: 'Heerewaarden', value: 'Heerewaarden' },
  { label: 'Heerhugowaard', value: 'Heerhugowaard' },
  { label: 'Heerjansdam', value: 'Heerjansdam' },
  { label: 'Heerle', value: 'Heerle' },
  { label: 'Heerlen', value: 'Heerlen' },
  { label: 'Heers', value: 'Heers' },
  { label: 'Hees', value: 'Hees' },
  { label: 'Heesch', value: 'Heesch' },
  { label: 'Heeslingen', value: 'Heeslingen' },
  { label: 'Heeßen', value: 'Heeßen' },
  { label: 'Heesterakker', value: 'Heesterakker' },
  { label: 'Heeswijk-Dinther', value: 'Heeswijk-Dinther' },
  { label: 'Heeze', value: 'Heeze' },
  { label: 'Hefei', value: 'Hefei' },
  { label: 'Hefei Shi', value: 'Hefei Shi' },
  { label: 'Heffingen', value: 'Heffingen' },
  { label: 'Heflin', value: 'Heflin' },
  { label: 'Hegang', value: 'Hegang' },
  { label: 'Hegelsom', value: 'Hegelsom' },
  { label: 'Hégenheim', value: 'Hégenheim' },
  { label: 'Heggadadevankote', value: 'Heggadadevankote' },
  { label: 'Hegnau', value: 'Hegnau' },
  { label: 'Hegnau / Dammboden-Grindel', value: 'Hegnau / Dammboden-Grindel' },
  { label: 'Hegnau / Sunnebüel-Eich', value: 'Hegnau / Sunnebüel-Eich' },
  { label: 'Hegyháti Járás', value: 'Hegyháti Járás' },
  { label: 'Héhalom', value: 'Héhalom' },
  { label: 'Hehlen', value: 'Hehlen' },
  { label: 'Heide', value: 'Heide' },
  { label: 'Heide', value: 'Heide' },
  { label: 'Heideck', value: 'Heideck' },
  { label: 'Heidelberg', value: 'Heidelberg' },
  { label: 'Heidelberg', value: 'Heidelberg' },
  { label: 'Heidelberg', value: 'Heidelberg' },
  { label: 'Heidelberg', value: 'Heidelberg' },
  { label: 'Heidelberg', value: 'Heidelberg' },
  { label: 'Heidelberg Heights', value: 'Heidelberg Heights' },
  { label: 'Heidelberg West', value: 'Heidelberg West' },
  { label: 'Heiden', value: 'Heiden' },
  { label: 'Heiden', value: 'Heiden' },
  { label: 'Heidenau', value: 'Heidenau' },
  { label: 'Heidenau', value: 'Heidenau' },
  { label: 'Heidenheim', value: 'Heidenheim' },
  { label: 'Heidenheim An Der Brenz', value: 'Heidenheim An Der Brenz' },
  { label: 'Heidenreichstein', value: 'Heidenreichstein' },
  { label: 'Heidesheim', value: 'Heidesheim' },
  { label: 'Heidgraben', value: 'Heidgraben' },
  { label: 'Heidstock', value: 'Heidstock' },
  { label: 'Heigenbrücken', value: 'Heigenbrücken' },
  { label: 'Heighington', value: 'Heighington' },
  { label: 'Heihe', value: 'Heihe' },
  { label: 'Heijen', value: 'Heijen' },
  { label: 'Heijplaat', value: 'Heijplaat' },
  { label: 'Heikendorf', value: 'Heikendorf' },
  { label: 'Heilbad Heiligenstadt', value: 'Heilbad Heiligenstadt' },
  { label: 'Heilbron', value: 'Heilbron' },
  { label: 'Heilbronn', value: 'Heilbronn' },
  { label: 'Heiligenberg', value: 'Heiligenberg' },
  { label: 'Heiligenblut', value: 'Heiligenblut' },
  { label: 'Heiligenbrunn', value: 'Heiligenbrunn' },
  { label: 'Heiligeneich', value: 'Heiligeneich' },
  { label: 'Heiligengrabe', value: 'Heiligengrabe' },
  { label: 'Heiligenhafen', value: 'Heiligenhafen' },
  { label: 'Heiligenhaus', value: 'Heiligenhaus' },
  { label: 'Heiligenkreuz', value: 'Heiligenkreuz' },
  { label: 'Heiligenkreuz Am Waasen', value: 'Heiligenkreuz Am Waasen' },
  { label: 'Heiligenkreuz Im Lafnitztal', value: 'Heiligenkreuz Im Lafnitztal' },
  { label: 'Heiligenroth', value: 'Heiligenroth' },
  { label: 'Heiligensee', value: 'Heiligensee' },
  { label: 'Heiligenstadt', value: 'Heiligenstadt' },
  { label: 'Heiligenstedten', value: 'Heiligenstedten' },
  { label: 'Heiligerlee', value: 'Heiligerlee' },
  { label: 'Heiligkreuzsteinach', value: 'Heiligkreuzsteinach' },
  { label: 'Heillecourt', value: 'Heillecourt' },
  { label: 'Heiloo', value: 'Heiloo' },
  { label: 'Heilsbronn', value: 'Heilsbronn' },
  { label: 'Heimbach', value: 'Heimbach' },
  { label: 'Heimbach', value: 'Heimbach' },
  { label: 'Heimberg', value: 'Heimberg' },
  { label: 'Heimbuchenthal', value: 'Heimbuchenthal' },
  { label: 'Heimenkirch', value: 'Heimenkirch' },
  { label: 'Heimertingen', value: 'Heimertingen' },
  { label: 'Heimiswil', value: 'Heimiswil' },
  { label: 'Heimsbrunn', value: 'Heimsbrunn' },
  { label: 'Heimschuh', value: 'Heimschuh' },
  { label: 'Heimsheim', value: 'Heimsheim' },
  { label: 'Heinade', value: 'Heinade' },
  { label: 'Heinävesi', value: 'Heinävesi' },
  { label: 'Heinböckel', value: 'Heinböckel' },
  { label: 'Heinersdorf', value: 'Heinersdorf' },
  { label: 'Heinfels', value: 'Heinfels' },
  { label: 'Heiningen', value: 'Heiningen' },
  { label: 'Heino', value: 'Heino' },
  { label: 'Heinola', value: 'Heinola' },
  { label: 'Heinsberg', value: 'Heinsberg' },
  { label: 'Heinsen', value: 'Heinsen' },
  { label: 'Heisdorf', value: 'Heisdorf' },
  { label: 'Heishan', value: 'Heishan' },
  { label: 'Heist', value: 'Heist' },
  { label: 'Heistenbach', value: 'Heistenbach' },
  { label: 'Heist-Op-Den-Berg', value: 'Heist-Op-Den-Berg' },
  { label: 'Heitenried', value: 'Heitenried' },
  { label: 'Heitersheim', value: 'Heitersheim' },
  { label: 'Heiterwang', value: 'Heiterwang' },
  { label: 'Heitoraí', value: 'Heitoraí' },
  { label: 'Hejnice', value: 'Hejnice' },
  { label: 'Hejőbába', value: 'Hejőbába' },
  { label: 'Hekimhan', value: 'Hekimhan' },
  { label: 'Hekinan', value: 'Hekinan' },
  { label: 'Hekinan-Shi', value: 'Hekinan-Shi' },
  { label: 'Heksenberg', value: 'Heksenberg' },
  { label: 'Hel', value: 'Hel' },
  { label: 'Helbra', value: 'Helbra' },
  { label: 'Helchteren', value: 'Helchteren' },
  { label: 'Helden', value: 'Helden' },
  { label: 'Heldrungen', value: 'Heldrungen' },
  { label: 'Helechosa De Los Montes', value: 'Helechosa De Los Montes' },
  { label: 'Helegiu', value: 'Helegiu' },
  { label: 'Helena', value: 'Helena' },
  { label: 'Helena', value: 'Helena' },
  { label: 'Helena', value: 'Helena' },
  { label: 'Helena', value: 'Helena' },
  { label: 'Helena', value: 'Helena' },
  { label: 'Helena', value: 'Helena' },
  { label: 'Helena Valley', value: 'Helena Valley' },
  { label: 'Helena Valley Northeast', value: 'Helena Valley Northeast' },
  { label: 'Helena Valley Northwest', value: 'Helena Valley Northwest' },
  { label: 'Helena Valley Southeast', value: 'Helena Valley Southeast' },
  { label: 'Helena Valley West Central', value: 'Helena Valley West Central' },
  { label: 'Helena West Side', value: 'Helena West Side' },
  { label: 'Helena-West Helena', value: 'Helena-West Helena' },
  { label: 'Helensburgh', value: 'Helensburgh' },
  { label: 'Helensburgh', value: 'Helensburgh' },
  { label: 'Helensvale', value: 'Helensvale' },
  { label: 'Hélesmes', value: 'Hélesmes' },
  { label: 'Heleșteni', value: 'Heleșteni' },
  { label: 'Helfaut', value: 'Helfaut' },
  { label: 'Helferskirchen', value: 'Helferskirchen' },
  { label: 'Helgafellssveit', value: 'Helgafellssveit' },
  { label: 'Helgoland', value: 'Helgoland' },
  { label: 'Heliconia', value: 'Heliconia' },
  { label: 'Helidon', value: 'Helidon' },
  { label: 'Helio García Alfaro', value: 'Helio García Alfaro' },
  { label: 'Heliodora', value: 'Heliodora' },
  { label: 'Heliopolis', value: 'Heliopolis' },
  { label: 'Heliópolis', value: 'Heliópolis' },
  { label: 'Héliopolis', value: 'Héliopolis' },
  { label: 'Hellaby', value: 'Hellaby' },
  { label: 'Hellebæk', value: 'Hellebæk' },
  { label: 'Hellenhahn-Schellenberg', value: 'Hellenhahn-Schellenberg' },
  { label: 'Hellenthal', value: 'Hellenthal' },
  { label: 'Hellersdorf', value: 'Hellersdorf' },
  { label: 'Hellertown', value: 'Hellertown' },
  { label: 'Hellevoetsluis', value: 'Hellevoetsluis' },
  { label: 'Hellifield', value: 'Hellifield' },
  { label: 'Hellín', value: 'Hellín' },
  { label: 'Hellingen', value: 'Hellingen' },
  { label: 'Hellmonsödt', value: 'Hellmonsödt' },
  { label: 'Hellshire', value: 'Hellshire' },
  { label: 'Hellwege', value: 'Hellwege' },
  { label: 'Helmbrechts', value: 'Helmbrechts' },
  { label: 'Helmetta', value: 'Helmetta' },
  { label: 'Helmond', value: 'Helmond' },
  { label: 'Helmsange', value: 'Helmsange' },
  { label: 'Helmsley', value: 'Helmsley' },
  { label: 'Helmstadt', value: 'Helmstadt' },
  { label: 'Helmstadt-Bargen', value: 'Helmstadt-Bargen' },
  { label: 'Helmstedt', value: 'Helmstedt' },
  { label: 'Helong', value: 'Helong' },
  { label: 'Helotes', value: 'Helotes' },
  { label: 'Helper', value: 'Helper' },
  { label: 'Helpman', value: 'Helpman' },
  { label: 'Helpsen', value: 'Helpsen' },
  { label: 'Helpston', value: 'Helpston' },
  { label: 'Helsa', value: 'Helsa' },
  { label: 'Helsby', value: 'Helsby' },
  { label: 'Helsingborg', value: 'Helsingborg' },
  { label: 'Helsinge', value: 'Helsinge' },
  { label: 'Helsingør', value: 'Helsingør' },
  { label: 'Helsingør Kommune', value: 'Helsingør Kommune' },
  { label: 'Helsinki', value: 'Helsinki' },
  { label: 'Helston', value: 'Helston' },
  { label: 'Heltersberg', value: 'Heltersberg' },
  { label: 'Helvecia', value: 'Helvecia' },
  { label: 'Helvécia', value: 'Helvécia' },
  { label: 'Helvoirt', value: 'Helvoirt' },
  { label: 'Helwak', value: 'Helwak' },
  { label: 'Helwan', value: 'Helwan' },
  { label: 'Hem', value: 'Hem' },
  { label: 'Hem', value: 'Hem' },
  { label: 'Hemau', value: 'Hemau' },
  { label: 'Hemby Bridge', value: 'Hemby Bridge' },
  { label: 'Hemdingen', value: 'Hemdingen' },
  { label: 'Hemeiuș', value: 'Hemeiuș' },
  { label: 'Hemel Hempstead', value: 'Hemel Hempstead' },
  { label: 'Hemer', value: 'Hemer' },
  { label: 'Hemet', value: 'Hemet' },
  { label: 'Hemhofen', value: 'Hemhofen' },
  { label: 'Hemiksem', value: 'Hemiksem' },
  { label: 'Hemingbrough', value: 'Hemingbrough' },
  { label: 'Hemingford Grey', value: 'Hemingford Grey' },
  { label: 'Hemlock', value: 'Hemlock' },
  { label: 'Hemlock Farms', value: 'Hemlock Farms' },
  { label: 'Hemmant', value: 'Hemmant' },
  { label: 'Hemmatabad', value: 'Hemmatabad' },
  { label: 'Hemmesta', value: 'Hemmesta' },
  { label: 'Hemmingen', value: 'Hemmingen' },
  { label: 'Hemmingen', value: 'Hemmingen' },
  { label: 'Hemmingstedt', value: 'Hemmingstedt' },
  { label: 'Hemmoor', value: 'Hemmoor' },
  { label: 'Hemne', value: 'Hemne' },
  { label: 'Hemnes', value: 'Hemnes' },
  { label: 'Hemnesberget', value: 'Hemnesberget' },
  { label: 'Hemphill', value: 'Hemphill' },
  { label: 'Hemphill County', value: 'Hemphill County' },
  { label: 'Hempstead', value: 'Hempstead' },
  { label: 'Hempstead', value: 'Hempstead' },
  { label: 'Hempstead County', value: 'Hempstead County' },
  { label: 'Hemsbach', value: 'Hemsbach' },
  { label: 'Hemsbünde', value: 'Hemsbünde' },
  { label: 'Hemsby', value: 'Hemsby' },
  { label: 'Hemse', value: 'Hemse' },
  { label: 'Hemsedal', value: 'Hemsedal' },
  { label: 'Hemşin', value: 'Hemşin' },
  { label: 'Hemslingen', value: 'Hemslingen' },
  { label: 'Hemsworth', value: 'Hemsworth' },
  { label: 'Hemyock', value: 'Hemyock' },
  { label: 'Henagar', value: 'Henagar' },
  { label: 'Henån', value: 'Henån' },
  { label: 'Hénanbihen', value: 'Hénanbihen' },
  { label: 'Henarejos', value: 'Henarejos' },
  { label: 'Henche', value: 'Henche' },
  { label: 'Hendala', value: 'Hendala' },
  { label: 'Hendaye', value: 'Hendaye' },
  { label: 'Hendek', value: 'Hendek' },
  { label: 'Henderson', value: 'Henderson' },
  { label: 'Henderson', value: 'Henderson' },
  { label: 'Henderson', value: 'Henderson' },
  { label: 'Henderson', value: 'Henderson' },
  { label: 'Henderson', value: 'Henderson' },
  { label: 'Henderson', value: 'Henderson' },
  { label: 'Henderson', value: 'Henderson' },
  { label: 'Henderson County', value: 'Henderson County' },
  { label: 'Henderson County', value: 'Henderson County' },
  { label: 'Henderson County', value: 'Henderson County' },
  { label: 'Henderson County', value: 'Henderson County' },
  { label: 'Henderson County', value: 'Henderson County' },
  { label: 'Hendersonville', value: 'Hendersonville' },
  { label: 'Hendersonville', value: 'Hendersonville' },
  { label: 'Hendijan', value: 'Hendijan' },
  { label: 'Hendoudar', value: 'Hendoudar' },
  { label: 'Hendra', value: 'Hendra' },
  { label: 'Hendricks County', value: 'Hendricks County' },
  { label: 'Hendrik-Ido-Ambacht', value: 'Hendrik-Ido-Ambacht' },
  { label: 'Hendrina', value: 'Hendrina' },
  { label: 'Hendron', value: 'Hendron' },
  { label: 'Hendry County', value: 'Hendry County' },
  { label: 'Hendungen', value: 'Hendungen' },
  { label: 'Henfenfeld', value: 'Henfenfeld' },
  { label: 'Henfield', value: 'Henfield' },
  { label: 'Henganofi', value: 'Henganofi' },
  { label: 'Hengbanqiao', value: 'Hengbanqiao' },
  { label: 'Hengchun', value: 'Hengchun' },
  { label: 'Hengelo', value: 'Hengelo' },
  { label: 'Hengelo', value: 'Hengelo' },
  { label: 'Hengersberg', value: 'Hengersberg' },
  { label: 'Hengevelde', value: 'Hengevelde' },
  { label: 'Henggart', value: 'Henggart' },
  { label: 'Hengoed', value: 'Hengoed' },
  { label: 'Hengsberg', value: 'Hengsberg' },
  { label: 'Hengshui', value: 'Hengshui' },
  { label: 'Hengstdal', value: 'Hengstdal' },
  { label: 'Hengyang', value: 'Hengyang' },
  { label: 'Heniches’K', value: 'Heniches’K' },
  { label: 'Heniches’Kyy Rayon', value: 'Heniches’Kyy Rayon' },
  { label: 'Hénin-Beaumont', value: 'Hénin-Beaumont' },
  { label: 'Henley Beach', value: 'Henley Beach' },
  { label: 'Henley Beach South', value: 'Henley Beach South' },
  { label: 'Henley Brook', value: 'Henley Brook' },
  { label: 'Henley In Arden', value: 'Henley In Arden' },
  { label: 'Henley-On-Thames', value: 'Henley-On-Thames' },
  { label: 'Henllan', value: 'Henllan' },
  { label: 'Henlow', value: 'Henlow' },
  { label: 'Hennaya', value: 'Hennaya' },
  { label: 'Henndorf Am Wallersee', value: 'Henndorf Am Wallersee' },
  { label: 'Hennebont', value: 'Hennebont' },
  { label: 'Hennef', value: 'Hennef' },
  { label: 'Hennenman', value: 'Hennenman' },
  { label: 'Hennepin', value: 'Hennepin' },
  { label: 'Hennepin County', value: 'Hennepin County' },
  { label: 'Hennersdorf', value: 'Hennersdorf' },
  { label: 'Hennessey', value: 'Hennessey' },
  { label: 'Hennigsdorf', value: 'Hennigsdorf' },
  { label: 'Henniker', value: 'Henniker' },
  { label: 'Hennstedt', value: 'Hennstedt' },
  { label: 'Hennweiler', value: 'Hennweiler' },
  { label: 'Hénon', value: 'Hénon' },
  { label: 'Hénouville', value: 'Hénouville' },
  { label: 'Henrichemont', value: 'Henrichemont' },
  { label: 'Henrico County', value: 'Henrico County' },
  { label: 'Henrietta', value: 'Henrietta' },
  { label: 'Henrietta', value: 'Henrietta' },
  { label: 'Henry', value: 'Henry' },
  { label: 'Henry County', value: 'Henry County' },
  { label: 'Henry County', value: 'Henry County' },
  { label: 'Henry County', value: 'Henry County' },
  { label: 'Henry County', value: 'Henry County' },
  { label: 'Henry County', value: 'Henry County' },
  { label: 'Henry County', value: 'Henry County' },
  { label: 'Henry County', value: 'Henry County' },
  { label: 'Henry County', value: 'Henry County' },
  { label: 'Henry County', value: 'Henry County' },
  { label: 'Henry County', value: 'Henry County' },
  { label: 'Henry Fork', value: 'Henry Fork' },
  { label: 'Henryetta', value: 'Henryetta' },
  { label: 'Henryków', value: 'Henryków' },
  { label: 'Henryville', value: 'Henryville' },
  { label: 'Hensbroek', value: 'Hensbroek' },
  { label: 'Hensies', value: 'Hensies' },
  { label: 'Henstedt-Ulzburg', value: 'Henstedt-Ulzburg' },
  { label: 'Henstridge', value: 'Henstridge' },
  { label: 'Hentiesbaai', value: 'Hentiesbaai' },
  { label: 'Henty', value: 'Henty' },
  { label: 'Henvic', value: 'Henvic' },
  { label: 'Hepburn', value: 'Hepburn' },
  { label: 'Hephzibah', value: 'Hephzibah' },
  { label: 'Hepingjie', value: 'Hepingjie' },
  { label: 'Hepo', value: 'Hepo' },
  { label: 'Heppenheim An Der Bergstrasse', value: 'Heppenheim An Der Bergstrasse' },
  { label: 'Heppner', value: 'Heppner' },
  { label: 'Hepstedt', value: 'Hepstedt' },
  { label: 'Heraklion', value: 'Heraklion' },
  { label: 'Herald', value: 'Herald' },
  { label: 'Herald Harbor', value: 'Herald Harbor' },
  { label: 'Herálec', value: 'Herálec' },
  { label: 'Heras De Ayuso', value: 'Heras De Ayuso' },
  { label: 'Herăști', value: 'Herăști' },
  { label: 'Herāt', value: 'Herāt' },
  { label: 'Herbault', value: 'Herbault' },
  { label: 'Herbert', value: 'Herbert' },
  { label: 'Herbertingen', value: 'Herbertingen' },
  { label: 'Herbés', value: 'Herbés' },
  { label: 'Herbeumont', value: 'Herbeumont' },
  { label: 'Herbeys', value: 'Herbeys' },
  { label: 'Herbignac', value: 'Herbignac' },
  { label: 'Herbitzheim', value: 'Herbitzheim' },
  { label: 'Herblay', value: 'Herblay' },
  { label: 'Herbolzheim', value: 'Herbolzheim' },
  { label: 'Herborn', value: 'Herborn' },
  { label: 'Herbrechtingen', value: 'Herbrechtingen' },
  { label: 'Herbsleben', value: 'Herbsleben' },
  { label: 'Herbstein', value: 'Herbstein' },
  { label: 'Herby', value: 'Herby' },
  { label: 'Herce', value: 'Herce' },
  { label: 'Hercegovac', value: 'Hercegovac' },
  { label: 'Hercegovačko-Neretvanski Kanton', value: 'Hercegovačko-Neretvanski Kanton' },
  { label: 'Hercegszántó', value: 'Hercegszántó' },
  { label: 'Herculândia', value: 'Herculândia' },
  { label: 'Herculaneum', value: 'Herculaneum' },
  { label: 'Hercules', value: 'Hercules' },
  { label: 'Hércules', value: 'Hércules' },
  { label: 'Herculian', value: 'Herculian' },
  { label: 'Herdecke', value: 'Herdecke' },
  { label: 'Herdorf', value: 'Herdorf' },
  { label: 'Herdsmans Cove', value: 'Herdsmans Cove' },
  { label: 'Herdwangen-Schönach', value: 'Herdwangen-Schönach' },
  { label: 'Hereclean', value: 'Hereclean' },
  { label: 'Heréd', value: 'Heréd' },
  { label: 'Heredia', value: 'Heredia' },
  { label: 'Hereford', value: 'Hereford' },
  { label: 'Hereford', value: 'Hereford' },
  { label: 'Herefordshire', value: 'Herefordshire' },
  { label: 'Héreg', value: 'Héreg' },
  { label: 'Herelle', value: 'Herelle' },
  { label: 'Hérémence', value: 'Hérémence' },
  { label: 'Herencia', value: 'Herencia' },
  { label: 'Herencias Las', value: 'Herencias Las' },
  { label: 'Herend', value: 'Herend' },
  { label: 'Hérens District', value: 'Hérens District' },
  { label: 'Herent', value: 'Herent' },
  { label: 'Herentals', value: 'Herentals' },
  { label: 'Herenthout', value: 'Herenthout' },
  { label: 'Herepea', value: 'Herepea' },
  { label: 'Hérépian', value: 'Hérépian' },
  { label: 'Heretsried', value: 'Heretsried' },
  { label: 'Herford', value: 'Herford' },
  { label: 'Herforst', value: 'Herforst' },
  { label: 'Hergensweiler', value: 'Hergensweiler' },
  { label: 'Herghelia', value: 'Herghelia' },
  { label: 'Hergisdorf', value: 'Hergisdorf' },
  { label: 'Hergiswil', value: 'Hergiswil' },
  { label: 'Hergnies', value: 'Hergnies' },
  { label: 'Herguijuela', value: 'Herguijuela' },
  { label: 'Herguijuela De Ciudad Rodrigo', value: 'Herguijuela De Ciudad Rodrigo' },
  { label: 'Herguijuela De La Sierra', value: 'Herguijuela De La Sierra' },
  { label: 'Herguijuela Del Campo', value: 'Herguijuela Del Campo' },
  { label: 'Heriberto Valdez Romero (El Guayabo)', value: 'Heriberto Valdez Romero (El Guayabo)' },
  { label: 'Héric', value: 'Héric' },
  { label: 'Héricourt', value: 'Héricourt' },
  { label: 'Héricy', value: 'Héricy' },
  { label: 'Hérimoncourt', value: 'Hérimoncourt' },
  { label: 'Hérin', value: 'Hérin' },
  { label: 'Heringen', value: 'Heringen' },
  { label: 'Heringen', value: 'Heringen' },
  { label: 'Heringsdorf', value: 'Heringsdorf' },
  { label: 'Herington', value: 'Herington' },
  { label: 'Heriraz', value: 'Heriraz' },
  { label: 'Heris', value: 'Heris' },
  { label: 'Herisau', value: 'Herisau' },
  { label: 'Heritage Creek', value: 'Heritage Creek' },
  { label: 'Heritage Hills', value: 'Heritage Hills' },
  { label: 'Heritage Lake', value: 'Heritage Lake' },
  { label: 'Heritage Lake', value: 'Heritage Lake' },
  { label: 'Heritage Park', value: 'Heritage Park' },
  { label: 'Heritage Pines', value: 'Heritage Pines' },
  { label: 'Heritage Pointe', value: 'Heritage Pointe' },
  { label: 'Heritage Village', value: 'Heritage Village' },
  { label: 'Herk-De-Stad', value: 'Herk-De-Stad' },
  { label: 'Herkenbosch', value: 'Herkenbosch' },
  { label: 'Herkimer', value: 'Herkimer' },
  { label: 'Herkimer County', value: 'Herkimer County' },
  { label: 'Herla', value: 'Herla' },
  { label: 'Herleshausen', value: 'Herleshausen' },
  { label: 'Herlev Kommune', value: 'Herlev Kommune' },
  { label: 'Herlies', value: 'Herlies' },
  { label: 'Herlitzka', value: 'Herlitzka' },
  { label: 'Hermagor', value: 'Hermagor' },
  { label: 'Hermagor District', value: 'Hermagor District' },
  { label: 'Hermagor-Pressegger See', value: 'Hermagor-Pressegger See' },
  { label: 'Hermandad De Campoo De Suso', value: 'Hermandad De Campoo De Suso' },
  { label: 'Hermann', value: 'Hermann' },
  { label: 'Hermannsburg', value: 'Hermannsburg' },
  { label: 'Hermanos Cedillo', value: 'Hermanos Cedillo' },
  { label: 'Heřmanova Huť', value: 'Heřmanova Huť' },
  { label: 'Hermansverk', value: 'Hermansverk' },
  { label: 'Hermantown', value: 'Hermantown' },
  { label: 'Hermanus', value: 'Hermanus' },
  { label: 'Heřmanův Městec', value: 'Heřmanův Městec' },
  { label: 'Hermanville-Sur-Mer', value: 'Hermanville-Sur-Mer' },
  { label: 'Hermaringen', value: 'Hermaringen' },
  { label: 'Hérmedes De Cerrato', value: 'Hérmedes De Cerrato' },
  { label: 'Hermenegildo Galeana', value: 'Hermenegildo Galeana' },
  { label: 'Hermenegildo Galeana', value: 'Hermenegildo Galeana' },
  { label: 'Hermenegildo J. Aldana', value: 'Hermenegildo J. Aldana' },
  { label: 'Hermersberg', value: 'Hermersberg' },
  { label: 'Hermes', value: 'Hermes' },
  { label: 'Hermeskeil', value: 'Hermeskeil' },
  { label: 'Hermies', value: 'Hermies' },
  { label: 'Hermigua', value: 'Hermigua' },
  { label: 'Hermisende', value: 'Hermisende' },
  { label: 'Hermiston', value: 'Hermiston' },
  { label: 'Hermiswil', value: 'Hermiswil' },
  { label: 'Hermit Park', value: 'Hermit Park' },
  { label: 'Hermitage', value: 'Hermitage' },
  { label: 'Hermitage', value: 'Hermitage' },
  { label: 'Hermitage', value: 'Hermitage' },
  { label: 'Hermitage', value: 'Hermitage' },
  { label: 'Hermitage', value: 'Hermitage' },
  { label: 'Hermon', value: 'Hermon' },
  { label: 'Hermonville', value: 'Hermonville' },
  { label: 'Hermosa', value: 'Hermosa' },
  { label: 'Hermosa', value: 'Hermosa' },
  { label: 'Hermosa Beach', value: 'Hermosa Beach' },
  { label: 'Hermosillo', value: 'Hermosillo' },
  { label: 'Hermosillo', value: 'Hermosillo' },
  { label: 'Hermoso Campo', value: 'Hermoso Campo' },
  { label: 'Hermsdorf', value: 'Hermsdorf' },
  { label: 'Hermsdorf', value: 'Hermsdorf' },
  { label: 'Hermsdorf', value: 'Hermsdorf' },
  { label: 'Hermsdorf', value: 'Hermsdorf' },
  { label: 'Hernád', value: 'Hernád' },
  { label: 'Hernádnémeti', value: 'Hernádnémeti' },
  { label: 'Hernals', value: 'Hernals' },
  { label: 'Hernández', value: 'Hernández' },
  { label: 'Hernández', value: 'Hernández' },
  { label: 'Hernando', value: 'Hernando' },
  { label: 'Hernando', value: 'Hernando' },
  { label: 'Hernando', value: 'Hernando' },
  { label: 'Hernando Beach', value: 'Hernando Beach' },
  { label: 'Hernando County', value: 'Hernando County' },
  { label: 'Hernani', value: 'Hernani' },
  { label: 'Hernani', value: 'Hernani' },
  { label: 'Hernani', value: 'Hernani' },
  { label: 'Hernán-Pérez', value: 'Hernán-Pérez' },
  { label: 'Hernansancho', value: 'Hernansancho' },
  { label: 'Herndon', value: 'Herndon' },
  { label: 'Herne', value: 'Herne' },
  { label: 'Herne', value: 'Herne' },
  { label: 'Herne Bay', value: 'Herne Bay' },
  { label: 'Herne Hill', value: 'Herne Hill' },
  { label: 'Herne Hill', value: 'Herne Hill' },
  { label: 'Hernialde', value: 'Hernialde' },
  { label: 'Herning', value: 'Herning' },
  { label: 'Herning Kommune', value: 'Herning Kommune' },
  { label: 'Hernstein', value: 'Hernstein' },
  { label: 'Héroes De Chapultepec', value: 'Héroes De Chapultepec' },
  { label: 'Héroes De Chapultepec (Rodeo De San Antonio)', value: 'Héroes De Chapultepec (Rodeo De San Antonio)' },
  { label: 'Heroica Caborca', value: 'Heroica Caborca' },
  { label: 'Heroica Ciudad De Ejutla De Crespo', value: 'Heroica Ciudad De Ejutla De Crespo' },
  { label: 'Heroica Ciudad De Juchitán De Zaragoza', value: 'Heroica Ciudad De Juchitán De Zaragoza' },
  { label: 'Heroica Ciudad De Tlaxiaco', value: 'Heroica Ciudad De Tlaxiaco' },
  { label: 'Heroica Guaymas', value: 'Heroica Guaymas' },
  { label: 'Heroica Matamoros', value: 'Heroica Matamoros' },
  { label: 'Heróica Zitácuaro', value: 'Heróica Zitácuaro' },
  { label: 'Heroldsbach', value: 'Heroldsbach' },
  { label: 'Heroldsberg', value: 'Heroldsberg' },
  { label: 'Héron', value: 'Héron' },
  { label: 'Héroumbili', value: 'Héroumbili' },
  { label: 'Hérouville-Saint-Clair', value: 'Hérouville-Saint-Clair' },
  { label: 'Hérouvillette', value: 'Hérouvillette' },
  { label: 'Hérouxville', value: 'Hérouxville' },
  { label: 'Herøy', value: 'Herøy' },
  { label: 'Herøy', value: 'Herøy' },
  { label: 'Herpen', value: 'Herpen' },
  { label: 'Herradón De Pinares', value: 'Herradón De Pinares' },
  { label: 'Herradura', value: 'Herradura' },
  { label: 'Herramélluri', value: 'Herramélluri' },
  { label: 'Herrán', value: 'Herrán' },
  { label: 'Herre', value: 'Herre' },
  { label: 'Herrenberg', value: 'Herrenberg' },
  { label: 'Herrera', value: 'Herrera' },
  { label: 'Herrera', value: 'Herrera' },
  { label: 'Herrera', value: 'Herrera' },
  { label: 'Herrera De Alcántara', value: 'Herrera De Alcántara' },
  { label: 'Herrera De Los Navarros', value: 'Herrera De Los Navarros' },
  { label: 'Herrera De Pisuerga', value: 'Herrera De Pisuerga' },
  { label: 'Herrera De Soria', value: 'Herrera De Soria' },
  { label: 'Herrera De Valdecañas', value: 'Herrera De Valdecañas' },
  { label: 'Herrera Del Duque', value: 'Herrera Del Duque' },
  { label: 'Herrera La', value: 'Herrera La' },
  { label: 'Herrería', value: 'Herrería' },
  { label: 'Herrerías', value: 'Herrerías' },
  { label: 'Herreros De Suso', value: 'Herreros De Suso' },
  { label: 'Herreruela', value: 'Herreruela' },
  { label: 'Herreruela De Oropesa', value: 'Herreruela De Oropesa' },
  { label: 'Herrestad', value: 'Herrestad' },
  { label: 'Herricks', value: 'Herricks' },
  { label: 'Herrieden', value: 'Herrieden' },
  { label: 'Herriman', value: 'Herriman' },
  { label: 'Herrin', value: 'Herrin' },
  { label: 'Herrín De Campos', value: 'Herrín De Campos' },
  { label: 'Herrischried', value: 'Herrischried' },
  { label: 'Herrliberg', value: 'Herrliberg' },
  { label: 'Herrlisheim', value: 'Herrlisheim' },
  { label: 'Herrlisheim-Près-Colmar', value: 'Herrlisheim-Près-Colmar' },
  { label: 'Herrljunga', value: 'Herrljunga' },
  { label: 'Herrnbaumgarten', value: 'Herrnbaumgarten' },
  { label: 'Herrngiersdorf', value: 'Herrngiersdorf' },
  { label: 'Herrnhut', value: 'Herrnhut' },
  { label: 'Herrsching Am Ammersee', value: 'Herrsching Am Ammersee' },
  { label: 'Herrumblar El', value: 'Herrumblar El' },
  { label: 'Herry', value: 'Herry' },
  { label: 'Hersbruck', value: 'Hersbruck' },
  { label: 'Herschdorf', value: 'Herschdorf' },
  { label: 'Herscheid', value: 'Herscheid' },
  { label: 'Herscher', value: 'Herscher' },
  { label: 'Herschweiler-Pettersheim', value: 'Herschweiler-Pettersheim' },
  { label: 'Herselt', value: 'Herselt' },
  { label: 'Herserange', value: 'Herserange' },
  { label: 'Hershey', value: 'Hershey' },
  { label: 'Hersilia', value: 'Hersilia' },
  { label: 'Hersin-Coupigny', value: 'Hersin-Coupigny' },
  { label: 'Herstal', value: 'Herstal' },
  { label: 'Herstmonceux', value: 'Herstmonceux' },
  { label: 'Herston', value: 'Herston' },
  { label: 'Herten', value: 'Herten' },
  { label: 'Herten', value: 'Herten' },
  { label: 'Hertford', value: 'Hertford' },
  { label: 'Hertford', value: 'Hertford' },
  { label: 'Hertford', value: 'Hertford' },
  { label: 'Hertford County', value: 'Hertford County' },
  { label: 'Hertfordshire', value: 'Hertfordshire' },
  { label: 'Herval', value: 'Herval' },
  { label: 'Herval Doeste', value: 'Herval Doeste' },
  { label: 'Hervanta', value: 'Hervanta' },
  { label: 'Hervás', value: 'Hervás' },
  { label: 'Herve', value: 'Herve' },
  { label: 'Herveiras', value: 'Herveiras' },
  { label: 'Herveo', value: 'Herveo' },
  { label: 'Hervey Bay', value: 'Hervey Bay' },
  { label: 'Hervías', value: 'Hervías' },
  { label: 'Herwijnen', value: 'Herwijnen' },
  { label: 'Herxheim Am Berg', value: 'Herxheim Am Berg' },
  { label: 'Héry', value: 'Héry' },
  { label: 'Herzberg', value: 'Herzberg' },
  { label: 'Herzberg Am Harz', value: 'Herzberg Am Harz' },
  { label: 'Herzeele', value: 'Herzeele' },
  { label: 'Herzele', value: 'Herzele' },
  { label: 'Herzhorn', value: 'Herzhorn' },
  { label: 'Herzlake', value: 'Herzlake' },
  { label: 'Herzliya', value: 'Herzliya' },
  { label: 'Herzliya Pituah', value: 'Herzliya Pituah' },
  { label: 'Herzogenaurach', value: 'Herzogenaurach' },
  { label: 'Herzogenbuchsee', value: 'Herzogenbuchsee' },
  { label: 'Herzogenburg', value: 'Herzogenburg' },
  { label: 'Herzogenrath', value: 'Herzogenrath' },
  { label: 'Herzogsdorf', value: 'Herzogsdorf' },
  { label: 'Hesami', value: 'Hesami' },
  { label: 'Hesar-E Garmkhan', value: 'Hesar-E Garmkhan' },
  { label: 'Hesar-E Qarah Baghi', value: 'Hesar-E Qarah Baghi' },
  { label: 'Hesar-E Qujeh Baghi', value: 'Hesar-E Qujeh Baghi' },
  { label: 'Hesdin', value: 'Hesdin' },
  { label: 'Hesel', value: 'Hesel' },
  { label: 'Hésingue', value: 'Hésingue' },
  { label: 'Hesla', value: 'Hesla' },
  { label: 'Hespe', value: 'Hespe' },
  { label: 'Hesperange', value: 'Hesperange' },
  { label: 'Hesperia', value: 'Hesperia' },
  { label: 'Heßdorf', value: 'Heßdorf' },
  { label: 'Heßheim', value: 'Heßheim' },
  { label: 'Hessigheim', value: 'Hessigheim' },
  { label: 'Hessisch Lichtenau', value: 'Hessisch Lichtenau' },
  { label: 'Hessisch Oldendorf', value: 'Hessisch Oldendorf' },
  { label: 'Hesston', value: 'Hesston' },
  { label: 'Hestra', value: 'Hestra' },
  { label: 'Heswall', value: 'Heswall' },
  { label: 'Het Loo', value: 'Het Loo' },
  { label: 'Het Oostrik', value: 'Het Oostrik' },
  { label: 'Hetang', value: 'Hetang' },
  { label: 'Heteren', value: 'Heteren' },
  { label: 'Hethersett', value: 'Hethersett' },
  { label: 'Hetin', value: 'Hetin' },
  { label: 'Hetiur', value: 'Hetiur' },
  { label: 'Hetlingen', value: 'Hetlingen' },
  { label: 'Hettange-Grande', value: 'Hettange-Grande' },
  { label: 'Hettenleidelheim', value: 'Hettenleidelheim' },
  { label: 'Hettenshausen', value: 'Hettenshausen' },
  { label: 'Hettingen', value: 'Hettingen' },
  { label: 'Hettinger', value: 'Hettinger' },
  { label: 'Hettinger County', value: 'Hettinger County' },
  { label: 'Hettlingen', value: 'Hettlingen' },
  { label: 'Hetton-Le-Hole', value: 'Hetton-Le-Hole' },
  { label: 'Hettstadt', value: 'Hettstadt' },
  { label: 'Hettstedt', value: 'Hettstedt' },
  { label: 'Hetzendorf', value: 'Hetzendorf' },
  { label: 'Hetzerath', value: 'Hetzerath' },
  { label: 'Hetzles', value: 'Hetzles' },
  { label: 'Heubach', value: 'Heubach' },
  { label: 'Heuchelheim', value: 'Heuchelheim' },
  { label: 'Heuchelheim Bei Frankenthal', value: 'Heuchelheim Bei Frankenthal' },
  { label: 'Heuchlingen', value: 'Heuchlingen' },
  { label: 'Heudeber', value: 'Heudeber' },
  { label: 'Heudreville-Sur-Eure', value: 'Heudreville-Sur-Eure' },
  { label: 'Heuerßen', value: 'Heuerßen' },
  { label: 'Heugas', value: 'Heugas' },
  { label: 'Heugem', value: 'Heugem' },
  { label: 'Heugraben', value: 'Heugraben' },
  { label: 'Heumen', value: 'Heumen' },
  { label: 'Heunghae', value: 'Heunghae' },
  { label: 'Heuringhem', value: 'Heuringhem' },
  { label: 'Heusden', value: 'Heusden' },
  { label: 'Heusden', value: 'Heusden' },
  { label: 'Heusdenhout', value: 'Heusdenhout' },
  { label: 'Heusenstamm', value: 'Heusenstamm' },
  { label: 'Heustreu', value: 'Heustreu' },
  { label: 'Heusweiler', value: 'Heusweiler' },
  { label: 'Heves', value: 'Heves' },
  { label: 'Hevesi Járás', value: 'Hevesi Járás' },
  { label: 'Hévié', value: 'Hévié' },
  { label: 'Hévíz', value: 'Hévíz' },
  { label: 'Hévízgyörk', value: 'Hévízgyörk' },
  { label: 'Hevlín', value: 'Hevlín' },
  { label: 'Hewanorra Orchard', value: 'Hewanorra Orchard' },
  { label: 'Hewett', value: 'Hewett' },
  { label: 'Hewitt', value: 'Hewitt' },
  { label: 'Hewlett', value: 'Hewlett' },
  { label: 'Hewlett Harbor', value: 'Hewlett Harbor' },
  { label: 'Hexham', value: 'Hexham' },
  { label: 'Hexiangqiao', value: 'Hexiangqiao' },
  { label: 'Hexiwu', value: 'Hexiwu' },
  { label: 'Heyburn', value: 'Heyburn' },
  { label: 'Heydarabad', value: 'Heydarabad' },
  { label: 'Heydareh-Ye Dar-E Emam', value: 'Heydareh-Ye Dar-E Emam' },
  { label: 'Heydareh-Ye Posht-E Shahr', value: 'Heydareh-Ye Posht-E Shahr' },
  { label: 'Heyerode', value: 'Heyerode' },
  { label: 'Heyersum', value: 'Heyersum' },
  { label: 'Heyfield', value: 'Heyfield' },
  { label: 'Heyrieux', value: 'Heyrieux' },
  { label: 'Heysham', value: 'Heysham' },
  { label: 'Heythuysen', value: 'Heythuysen' },
  { label: 'Heyuan', value: 'Heyuan' },
  { label: 'Heywood', value: 'Heywood' },
  { label: 'Heywood', value: 'Heywood' },
  { label: 'Heyworth', value: 'Heyworth' },
  { label: 'Heze', value: 'Heze' },
  { label: 'Hezuo', value: 'Hezuo' },
  { label: 'Hhukwini', value: 'Hhukwini' },
  { label: 'Hialeah', value: 'Hialeah' },
  { label: 'Hialeah Gardens', value: 'Hialeah Gardens' },
  { label: 'Hiawassee', value: 'Hiawassee' },
  { label: 'Hiawatha', value: 'Hiawatha' },
  { label: 'Hiawatha', value: 'Hiawatha' },
  { label: 'Hibaiyo', value: 'Hibaiyo' },
  { label: 'Hibaldstow', value: 'Hibaldstow' },
  { label: 'Hibbing', value: 'Hibbing' },
  { label: 'Hibernia', value: 'Hibernia' },
  { label: 'Hickam Field', value: 'Hickam Field' },
  { label: 'Hickman', value: 'Hickman' },
  { label: 'Hickman', value: 'Hickman' },
  { label: 'Hickman County', value: 'Hickman County' },
  { label: 'Hickman County', value: 'Hickman County' },
  { label: 'Hickory', value: 'Hickory' },
  { label: 'Hickory County', value: 'Hickory County' },
  { label: 'Hickory Creek', value: 'Hickory Creek' },
  { label: 'Hickory Hills', value: 'Hickory Hills' },
  { label: 'Hickory Hills', value: 'Hickory Hills' },
  { label: 'Hickory Withe', value: 'Hickory Withe' },
  { label: 'Hicksville', value: 'Hicksville' },
  { label: 'Hicksville', value: 'Hicksville' },
  { label: 'Hico', value: 'Hico' },
  { label: 'Hida', value: 'Hida' },
  { label: 'Hida', value: 'Hida' },
  { label: 'Hida Shi', value: 'Hida Shi' },
  { label: 'Hidaj', value: 'Hidaj' },
  { label: 'Hidaka', value: 'Hidaka' },
  { label: 'Hidaka-Shi', value: 'Hidaka-Shi' },
  { label: 'Hidalgo', value: 'Hidalgo' },
  { label: 'Hidalgo', value: 'Hidalgo' },
  { label: 'Hidalgo', value: 'Hidalgo' },
  { label: 'Hidalgo', value: 'Hidalgo' },
  { label: 'Hidalgo', value: 'Hidalgo' },
  { label: 'Hidalgo', value: 'Hidalgo' },
  { label: 'Hidalgo', value: 'Hidalgo' },
  { label: 'Hidalgo Amajac', value: 'Hidalgo Amajac' },
  { label: 'Hidalgo County', value: 'Hidalgo County' },
  { label: 'Hidalgo County', value: 'Hidalgo County' },
  { label: 'Hidalgo Del Parral', value: 'Hidalgo Del Parral' },
  { label: 'Hidalgo Joshil', value: 'Hidalgo Joshil' },
  { label: 'Hidalgotitlán', value: 'Hidalgotitlán' },
  { label: 'Hidas', value: 'Hidas' },
  { label: 'Hidaybu', value: 'Hidaybu' },
  { label: 'Hidden Hills', value: 'Hidden Hills' },
  { label: 'Hidden Meadows', value: 'Hidden Meadows' },
  { label: 'Hidden Spring', value: 'Hidden Spring' },
  { label: 'Hidden Valley', value: 'Hidden Valley' },
  { label: 'Hidden Valley Lake', value: 'Hidden Valley Lake' },
  { label: 'Hiddenhausen', value: 'Hiddenhausen' },
  { label: 'Hiddensee', value: 'Hiddensee' },
  { label: 'Hideaga', value: 'Hideaga' },
  { label: 'Hideaway', value: 'Hideaway' },
  { label: 'Hide-A-Way Lake', value: 'Hide-A-Way Lake' },
  { label: 'Hidişelu De Sus', value: 'Hidişelu De Sus' },
  { label: 'Hidouj', value: 'Hidouj' },
  { label: 'Hidrolândia', value: 'Hidrolândia' },
  { label: 'Hidrolândia', value: 'Hidrolândia' },
  { label: 'Hidrolina', value: 'Hidrolina' },
  { label: 'Hieflau', value: 'Hieflau' },
  { label: 'Hiendelaencina', value: 'Hiendelaencina' },
  { label: 'Hierba De Matancillas', value: 'Hierba De Matancillas' },
  { label: 'Hières-Sur-Amby', value: 'Hières-Sur-Amby' },
  { label: 'Hiersac', value: 'Hiersac' },
  { label: 'Hietalahti', value: 'Hietalahti' },
  { label: 'Hietzing', value: 'Hietzing' },
  { label: 'Higashihiroshima Shi', value: 'Higashihiroshima Shi' },
  { label: 'Higashikagawa Shi', value: 'Higashikagawa Shi' },
  { label: 'Higashikurume-Shi', value: 'Higashikurume-Shi' },
  { label: 'Higashimatshushima Shi', value: 'Higashimatshushima Shi' },
  { label: 'Higashimatsushima', value: 'Higashimatsushima' },
  { label: 'Higashimatsuyama Shi', value: 'Higashimatsuyama Shi' },
  { label: 'Higashimurayama', value: 'Higashimurayama' },
  { label: 'Higashi-Murayama-Shi', value: 'Higashi-Murayama-Shi' },
  { label: 'Higashine', value: 'Higashine' },
  { label: 'Higashine Shi', value: 'Higashine Shi' },
  { label: 'Higashiōmi-Shi', value: 'Higashiōmi-Shi' },
  { label: 'Higashiōsaka Shi', value: 'Higashiōsaka Shi' },
  { label: 'Higashiyamato', value: 'Higashiyamato' },
  { label: 'Higashiyamato-Shi', value: 'Higashiyamato-Shi' },
  { label: 'Higganum', value: 'Higganum' },
  { label: 'Higgin Land', value: 'Higgin Land' },
  { label: 'Higgins', value: 'Higgins' },
  { label: 'Higginsville', value: 'Higginsville' },
  { label: 'High Barnet', value: 'High Barnet' },
  { label: 'High Bentham', value: 'High Bentham' },
  { label: 'High Blantyre', value: 'High Blantyre' },
  { label: 'High Bridge', value: 'High Bridge' },
  { label: 'High Etherley', value: 'High Etherley' },
  { label: 'High Halden', value: 'High Halden' },
  { label: 'High Halstow', value: 'High Halstow' },
  { label: 'High Legh', value: 'High Legh' },
  { label: 'High Level', value: 'High Level' },
  { label: 'High Ongar', value: 'High Ongar' },
  { label: 'High Peak', value: 'High Peak' },
  { label: 'High Point', value: 'High Point' },
  { label: 'High Point', value: 'High Point' },
  { label: 'High Prairie', value: 'High Prairie' },
  { label: 'High Ridge', value: 'High Ridge' },
  { label: 'High River', value: 'High River' },
  { label: 'High Rock', value: 'High Rock' },
  { label: 'High Springs', value: 'High Springs' },
  { label: 'High Valleyfield', value: 'High Valleyfield' },
  { label: 'High Wycombe', value: 'High Wycombe' },
  { label: 'High Wycombe', value: 'High Wycombe' },
  { label: 'Higham Ferrers', value: 'Higham Ferrers' },
  { label: 'Highbridge', value: 'Highbridge' },
  { label: 'Highbury', value: 'Highbury' },
  { label: 'Highclere', value: 'Highclere' },
  { label: 'Highett', value: 'Highett' },
  { label: 'Highfield-Cascade', value: 'Highfield-Cascade' },
  { label: 'Highfields', value: 'Highfields' },
  { label: 'Highgate', value: 'Highgate' },
  { label: 'Highgate', value: 'Highgate' },
  { label: 'Highgate', value: 'Highgate' },
  { label: 'Highgate Hill', value: 'Highgate Hill' },
  { label: 'Highgrove', value: 'Highgrove' },
  { label: 'Highland', value: 'Highland' },
  { label: 'Highland', value: 'Highland' },
  { label: 'Highland', value: 'Highland' },
  { label: 'Highland', value: 'Highland' },
  { label: 'Highland', value: 'Highland' },
  { label: 'Highland', value: 'Highland' },
  { label: 'Highland', value: 'Highland' },
  { label: 'Highland', value: 'Highland' },
  { label: 'Highland', value: 'Highland' },
  { label: 'Highland', value: 'Highland' },
  { label: 'Highland Acres', value: 'Highland Acres' },
  { label: 'Highland Beach', value: 'Highland Beach' },
  { label: 'Highland City', value: 'Highland City' },
  { label: 'Highland County', value: 'Highland County' },
  { label: 'Highland County', value: 'Highland County' },
  { label: 'Highland Falls', value: 'Highland Falls' },
  { label: 'Highland Heights', value: 'Highland Heights' },
  { label: 'Highland Heights', value: 'Highland Heights' },
  { label: 'Highland Lake', value: 'Highland Lake' },
  { label: 'Highland Lakes', value: 'Highland Lakes' },
  { label: 'Highland Mills', value: 'Highland Mills' },
  { label: 'Highland Park', value: 'Highland Park' },
  { label: 'Highland Park', value: 'Highland Park' },
  { label: 'Highland Park', value: 'Highland Park' },
  { label: 'Highland Park', value: 'Highland Park' },
  { label: 'Highland Park', value: 'Highland Park' },
  { label: 'Highland Park', value: 'Highland Park' },
  { label: 'Highland Springs', value: 'Highland Springs' },
  { label: 'Highland Village', value: 'Highland Village' },
  { label: 'Highlands', value: 'Highlands' },
  { label: 'Highlands', value: 'Highlands' },
  { label: 'Highlands', value: 'Highlands' },
  { label: 'Highlands County', value: 'Highlands County' },
  { label: 'Highlands Ranch', value: 'Highlands Ranch' },
  { label: 'Highlands-Baywood Park', value: 'Highlands-Baywood Park' },
  { label: 'Highley', value: 'Highley' },
  { label: 'Highmore', value: 'Highmore' },
  { label: 'Highpoint', value: 'Highpoint' },
  { label: 'Highspire', value: 'Highspire' },
  { label: 'Highton', value: 'Highton' },
  { label: 'Hightown', value: 'Hightown' },
  { label: 'Hightstown', value: 'Hightstown' },
  { label: 'Highvale', value: 'Highvale' },
  { label: 'Highview', value: 'Highview' },
  { label: 'Highwood', value: 'Highwood' },
  { label: 'Highworth', value: 'Highworth' },
  { label: 'Higinio Olivo', value: 'Higinio Olivo' },
  { label: 'Higuera', value: 'Higuera' },
  { label: 'Higuera Blanca', value: 'Higuera Blanca' },
  { label: 'Higuera De Calatrava', value: 'Higuera De Calatrava' },
  { label: 'Higuera De La Serena', value: 'Higuera De La Serena' },
  { label: 'Higuera De La Sierra', value: 'Higuera De La Sierra' },
  { label: 'Higuera De Las Dueñas', value: 'Higuera De Las Dueñas' },
  { label: 'Higuera De Llerena', value: 'Higuera De Llerena' },
  { label: 'Higuera De Los Vega', value: 'Higuera De Los Vega' },
  { label: 'Higuera De Vargas', value: 'Higuera De Vargas' },
  { label: 'Higuera De Zaragoza', value: 'Higuera De Zaragoza' },
  { label: 'Higuera La Real', value: 'Higuera La Real' },
  { label: 'Higueras', value: 'Higueras' },
  { label: 'Higueras De Abuya', value: 'Higueras De Abuya' },
  { label: 'Higueras De Los Natoches', value: 'Higueras De Los Natoches' },
  { label: 'Higueras Nuevo León', value: 'Higueras Nuevo León' },
  { label: 'Higueras San Miguel', value: 'Higueras San Miguel' },
  { label: 'Higuerillas', value: 'Higuerillas' },
  { label: 'Higuerillas', value: 'Higuerillas' },
  { label: 'Higuerón', value: 'Higuerón' },
  { label: 'Higueruela', value: 'Higueruela' },
  { label: 'Higueruelas', value: 'Higueruelas' },
  { label: 'Higüey', value: 'Higüey' },
  { label: 'Hihifo', value: 'Hihifo' },
  { label: 'Hihya', value: 'Hihya' },
  { label: 'Hija De Dios La', value: 'Hija De Dios La' },
  { label: 'Híjar', value: 'Híjar' },
  { label: 'Hijes', value: 'Hijes' },
  { label: 'Hiji', value: 'Hiji' },
  { label: 'Hiju Maco', value: 'Hiju Maco' },
  { label: 'Hijuelas', value: 'Hijuelas' },
  { label: 'Hikari', value: 'Hikari' },
  { label: 'Hikari Shi', value: 'Hikari Shi' },
  { label: 'Hiki-Gun', value: 'Hiki-Gun' },
  { label: 'Hikkaduwa', value: 'Hikkaduwa' },
  { label: 'Hikone', value: 'Hikone' },
  { label: 'Hikone-Shi', value: 'Hikone-Shi' },
  { label: 'Hiland Park', value: 'Hiland Park' },
  { label: 'Hilantagaan', value: 'Hilantagaan' },
  { label: 'Hilbersdorf', value: 'Hilbersdorf' },
  { label: 'Hilbert', value: 'Hilbert' },
  { label: 'Hilbert', value: 'Hilbert' },
  { label: 'Hilchenbach', value: 'Hilchenbach' },
  { label: 'Hildale', value: 'Hildale' },
  { label: 'Hildburghausen', value: 'Hildburghausen' },
  { label: 'Hildebran', value: 'Hildebran' },
  { label: 'Hilden', value: 'Hilden' },
  { label: 'Hilders', value: 'Hilders' },
  { label: 'Hildesheim', value: 'Hildesheim' },
  { label: 'Hildisrieden', value: 'Hildisrieden' },
  { label: 'Hildrizhausen', value: 'Hildrizhausen' },
  { label: 'Hilgermissen', value: 'Hilgermissen' },
  { label: 'Hilgert', value: 'Hilgert' },
  { label: 'Hilgertshausen-Tandern', value: 'Hilgertshausen-Tandern' },
  { label: 'Hilişeu-Horia', value: 'Hilişeu-Horia' },
  { label: 'Hill', value: 'Hill' },
  { label: 'Hill Air Force Base', value: 'Hill Air Force Base' },
  { label: 'Hill City', value: 'Hill City' },
  { label: 'Hill Country Village', value: 'Hill Country Village' },
  { label: 'Hill County', value: 'Hill County' },
  { label: 'Hill County', value: 'Hill County' },
  { label: 'Hill N Dale', value: 'Hill N Dale' },
  { label: 'Hill River', value: 'Hill River' },
  { label: 'Hill Top', value: 'Hill Top' },
  { label: 'Hillandale', value: 'Hillandale' },
  { label: 'Hillarys', value: 'Hillarys' },
  { label: 'Hillbank', value: 'Hillbank' },
  { label: 'Hillcrest', value: 'Hillcrest' },
  { label: 'Hillcrest', value: 'Hillcrest' },
  { label: 'Hillcrest', value: 'Hillcrest' },
  { label: 'Hillcrest', value: 'Hillcrest' },
  { label: 'Hillcrest', value: 'Hillcrest' },
  { label: 'Hillcrest Gardens', value: 'Hillcrest Gardens' },
  { label: 'Hillcrest Heights', value: 'Hillcrest Heights' },
  { label: 'Hilldale', value: 'Hilldale' },
  { label: 'Hille', value: 'Hille' },
  { label: 'Hillegom', value: 'Hillegom' },
  { label: 'Hiller', value: 'Hiller' },
  { label: 'Hillerød', value: 'Hillerød' },
  { label: 'Hillerød Kommune', value: 'Hillerød Kommune' },
  { label: 'Hillerse', value: 'Hillerse' },
  { label: 'Hillerstorp', value: 'Hillerstorp' },
  { label: 'Hillesheim', value: 'Hillesheim' },
  { label: 'Hilliard', value: 'Hilliard' },
  { label: 'Hilliard', value: 'Hilliard' },
  { label: 'Hillion', value: 'Hillion' },
  { label: 'Hillman', value: 'Hillman' },
  { label: 'Hillsboro', value: 'Hillsboro' },
  { label: 'Hillsboro', value: 'Hillsboro' },
  { label: 'Hillsboro', value: 'Hillsboro' },
  { label: 'Hillsboro', value: 'Hillsboro' },
  { label: 'Hillsboro', value: 'Hillsboro' },
  { label: 'Hillsboro', value: 'Hillsboro' },
  { label: 'Hillsboro', value: 'Hillsboro' },
  { label: 'Hillsboro', value: 'Hillsboro' },
  { label: 'Hillsboro', value: 'Hillsboro' },
  { label: 'Hillsboro Beach', value: 'Hillsboro Beach' },
  { label: 'Hillsborough', value: 'Hillsborough' },
  { label: 'Hillsborough', value: 'Hillsborough' },
  { label: 'Hillsborough', value: 'Hillsborough' },
  { label: 'Hillsborough', value: 'Hillsborough' },
  { label: 'Hillsborough', value: 'Hillsborough' },
  { label: 'Hillsborough', value: 'Hillsborough' },
  { label: 'Hillsborough County', value: 'Hillsborough County' },
  { label: 'Hillsborough County', value: 'Hillsborough County' },
  { label: 'Hillscheid', value: 'Hillscheid' },
  { label: 'Hillsdale', value: 'Hillsdale' },
  { label: 'Hillsdale', value: 'Hillsdale' },
  { label: 'Hillsdale', value: 'Hillsdale' },
  { label: 'Hillsdale', value: 'Hillsdale' },
  { label: 'Hillsdale County', value: 'Hillsdale County' },
  { label: 'Hillside', value: 'Hillside' },
  { label: 'Hillside', value: 'Hillside' },
  { label: 'Hillside', value: 'Hillside' },
  { label: 'Hillside', value: 'Hillside' },
  { label: 'Hillside', value: 'Hillside' },
  { label: 'Hillside', value: 'Hillside' },
  { label: 'Hillside Lake', value: 'Hillside Lake' },
  { label: 'Hillsmere Shores', value: 'Hillsmere Shores' },
  { label: 'Hillston', value: 'Hillston' },
  { label: 'Hillsville', value: 'Hillsville' },
  { label: 'Hilltop Lakes', value: 'Hilltop Lakes' },
  { label: 'Hillview', value: 'Hillview' },
  { label: 'Hillvue', value: 'Hillvue' },
  { label: 'Hilmar-Irwin', value: 'Hilmar-Irwin' },
  { label: 'Hilo', value: 'Hilo' },
  { label: 'Hilongos', value: 'Hilongos' },
  { label: 'Hilongos', value: 'Hilongos' },
  { label: 'Hilotongan', value: 'Hilotongan' },
  { label: 'Hilpoltstein', value: 'Hilpoltstein' },
  { label: 'Hilsa', value: 'Hilsa' },
  { label: 'Hilsenheim', value: 'Hilsenheim' },
  { label: 'Hiltenfingen', value: 'Hiltenfingen' },
  { label: 'Hilter', value: 'Hilter' },
  { label: 'Hilterfingen', value: 'Hilterfingen' },
  { label: 'Hilton', value: 'Hilton' },
  { label: 'Hilton', value: 'Hilton' },
  { label: 'Hilton', value: 'Hilton' },
  { label: 'Hilton Head', value: 'Hilton Head' },
  { label: 'Hilton Head Island', value: 'Hilton Head Island' },
  { label: 'Hiltpoltstein', value: 'Hiltpoltstein' },
  { label: 'Hilvan', value: 'Hilvan' },
  { label: 'Hilvarenbeek', value: 'Hilvarenbeek' },
  { label: 'Hilversum', value: 'Hilversum' },
  { label: 'Hilversumse Meent', value: 'Hilversumse Meent' },
  { label: 'Hilzingen', value: 'Hilzingen' },
  { label: 'Himaao', value: 'Himaao' },
  { label: 'Himamaylan', value: 'Himamaylan' },
  { label: 'Himanka', value: 'Himanka' },
  { label: 'Himarë', value: 'Himarë' },
  { label: 'Himatnagar', value: 'Himatnagar' },
  { label: 'Himaya', value: 'Himaya' },
  { label: 'Himberg', value: 'Himberg' },
  { label: 'Himbergen', value: 'Himbergen' },
  { label: 'Himeji', value: 'Himeji' },
  { label: 'Himeji Shi', value: 'Himeji Shi' },
  { label: 'Himensulan', value: 'Himensulan' },
  { label: 'Himi Shi', value: 'Himi Shi' },
  { label: 'Himimachi', value: 'Himimachi' },
  { label: 'Himmelberg', value: 'Himmelberg' },
  { label: 'Himmelkron', value: 'Himmelkron' },
  { label: 'Himmelpforten', value: 'Himmelpforten' },
  { label: 'Himmelreich', value: 'Himmelreich' },
  { label: 'Himmelried', value: 'Himmelried' },
  { label: 'Himmelstadt', value: 'Himmelstadt' },
  { label: 'Hinabangan', value: 'Hinabangan' },
  { label: 'Hinapalanan', value: 'Hinapalanan' },
  { label: 'Hinatuan', value: 'Hinatuan' },
  { label: 'Hinatuan', value: 'Hinatuan' },
  { label: 'Hînceşti', value: 'Hînceşti' },
  { label: 'Hinche', value: 'Hinche' },
  { label: 'Hinchinbrook', value: 'Hinchinbrook' },
  { label: 'Hinchinbrook', value: 'Hinchinbrook' },
  { label: 'Hinchley Wood', value: 'Hinchley Wood' },
  { label: 'Hinckley', value: 'Hinckley' },
  { label: 'Hinckley', value: 'Hinckley' },
  { label: 'Hinckley', value: 'Hinckley' },
  { label: 'Hindang', value: 'Hindang' },
  { label: 'Hindang', value: 'Hindang' },
  { label: 'Hindås', value: 'Hindås' },
  { label: 'Hindaun', value: 'Hindaun' },
  { label: 'Hindelbank', value: 'Hindelbank' },
  { label: 'Hinderdam', value: 'Hinderdam' },
  { label: 'Hindhead', value: 'Hindhead' },
  { label: 'Hindisheim', value: 'Hindisheim' },
  { label: 'Hindley', value: 'Hindley' },
  { label: 'Hindman', value: 'Hindman' },
  { label: 'Hindmarsh', value: 'Hindmarsh' },
  { label: 'Hindmarsh Island', value: 'Hindmarsh Island' },
  { label: 'Hindon', value: 'Hindon' },
  { label: 'Hindoria', value: 'Hindoria' },
  { label: 'Hinds County', value: 'Hinds County' },
  { label: 'Hinds Town', value: 'Hinds Town' },
  { label: 'Hindupur', value: 'Hindupur' },
  { label: 'Hindusthan Cables Town', value: 'Hindusthan Cables Town' },
  { label: 'Hines', value: 'Hines' },
  { label: 'Hinesburg', value: 'Hinesburg' },
  { label: 'Hinesville', value: 'Hinesville' },
  { label: 'Hinganghat', value: 'Hinganghat' },
  { label: 'Hingatungan', value: 'Hingatungan' },
  { label: 'Hingatungan', value: 'Hingatungan' },
  { label: 'Hinges', value: 'Hinges' },
  { label: 'Hingham', value: 'Hingham' },
  { label: 'Hingham', value: 'Hingham' },
  { label: 'Hingoli', value: 'Hingoli' },
  { label: 'Hingorja', value: 'Hingorja' },
  { label: 'Hingyon', value: 'Hingyon' },
  { label: 'Hiniesta La', value: 'Hiniesta La' },
  { label: 'Hinigaran', value: 'Hinigaran' },
  { label: 'Hınıs', value: 'Hınıs' },
  { label: 'Hinjilikatu', value: 'Hinjilikatu' },
  { label: 'Hinlayagan Ilaud', value: 'Hinlayagan Ilaud' },
  { label: 'Hinna', value: 'Hinna' },
  { label: 'Hinnerup', value: 'Hinnerup' },
  { label: 'Hino', value: 'Hino' },
  { label: 'Hino', value: 'Hino' },
  { label: 'Hinojal', value: 'Hinojal' },
  { label: 'Hinojales', value: 'Hinojales' },
  { label: 'Hinojares', value: 'Hinojares' },
  { label: 'Hinojos', value: 'Hinojos' },
  { label: 'Hinojosa De Duero', value: 'Hinojosa De Duero' },
  { label: 'Hinojosa De Jarque', value: 'Hinojosa De Jarque' },
  { label: 'Hinojosa De San Vicente', value: 'Hinojosa De San Vicente' },
  { label: 'Hinojosa Del Campo', value: 'Hinojosa Del Campo' },
  { label: 'Hinojosa Del Duque', value: 'Hinojosa Del Duque' },
  { label: 'Hinojosa Del Valle', value: 'Hinojosa Del Valle' },
  { label: 'Hinojosa La', value: 'Hinojosa La' },
  { label: 'Hinojosas De Calatrava', value: 'Hinojosas De Calatrava' },
  { label: 'Hinojosos Los', value: 'Hinojosos Los' },
  { label: 'Hino-Shi', value: 'Hino-Shi' },
  { label: 'Hinova', value: 'Hinova' },
  { label: 'Hinsdale', value: 'Hinsdale' },
  { label: 'Hinsdale', value: 'Hinsdale' },
  { label: 'Hinsdale', value: 'Hinsdale' },
  { label: 'Hinsdale County', value: 'Hinsdale County' },
  { label: 'Hinte', value: 'Hinte' },
  { label: 'Hinterberg', value: 'Hinterberg' },
  { label: 'Hinterbrühl', value: 'Hinterbrühl' },
  { label: 'Hinteregg', value: 'Hinteregg' },
  { label: 'Hinterglemm', value: 'Hinterglemm' },
  { label: 'Hinterleiten', value: 'Hinterleiten' },
  { label: 'Hinterrhein', value: 'Hinterrhein' },
  { label: 'Hinterschmiding', value: 'Hinterschmiding' },
  { label: 'Hintersee', value: 'Hintersee' },
  { label: 'Hinterweidenthal', value: 'Hinterweidenthal' },
  { label: 'Hinterzarten', value: 'Hinterzarten' },
  { label: 'Hinthada', value: 'Hinthada' },
  { label: 'Hinton', value: 'Hinton' },
  { label: 'Hinton', value: 'Hinton' },
  { label: 'Hinton', value: 'Hinton' },
  { label: 'Hinton', value: 'Hinton' },
  { label: 'Hinunangan', value: 'Hinunangan' },
  { label: 'Hinunangan', value: 'Hinunangan' },
  { label: 'Hinundayan', value: 'Hinundayan' },
  { label: 'Hinundayan', value: 'Hinundayan' },
  { label: 'Hinvi', value: 'Hinvi' },
  { label: 'Hinwil', value: 'Hinwil' },
  { label: 'Hinx', value: 'Hinx' },
  { label: 'Hioki', value: 'Hioki' },
  { label: 'Hioki Shi', value: 'Hioki Shi' },
  { label: 'Hipadpad', value: 'Hipadpad' },
  { label: 'Hipadpad', value: 'Hipadpad' },
  { label: 'Hipasngo', value: 'Hipasngo' },
  { label: 'Hipasngo', value: 'Hipasngo' },
  { label: 'Hipona', value: 'Hipona' },
  { label: 'Hippach', value: 'Hippach' },
  { label: 'Hippolytushoef', value: 'Hippolytushoef' },
  { label: 'Hipstedt', value: 'Hipstedt' },
  { label: 'Hir', value: 'Hir' },
  { label: 'Hirado', value: 'Hirado' },
  { label: 'Hirado Shi', value: 'Hirado Shi' },
  { label: 'Hirakata', value: 'Hirakata' },
  { label: 'Hirakata Shi', value: 'Hirakata Shi' },
  { label: 'Hirakawa', value: 'Hirakawa' },
  { label: 'Hirakawa Shi', value: 'Hirakawa Shi' },
  { label: 'Hirakud', value: 'Hirakud' },
  { label: 'Hiram', value: 'Hiram' },
  { label: 'Hiram', value: 'Hiram' },
  { label: 'Hiram', value: 'Hiram' },
  { label: 'Hiranagar', value: 'Hiranagar' },
  { label: 'Hirapur', value: 'Hirapur' },
  { label: 'Hirapur Hamesha', value: 'Hirapur Hamesha' },
  { label: 'Hirara', value: 'Hirara' },
  { label: 'Hiratachō', value: 'Hiratachō' },
  { label: 'Hiratsuka', value: 'Hiratsuka' },
  { label: 'Hiratsuka Shi', value: 'Hiratsuka Shi' },
  { label: 'Hiraya', value: 'Hiraya' },
  { label: 'Hirekerur', value: 'Hirekerur' },
  { label: 'Hirel', value: 'Hirel' },
  { label: 'Hiriberri/Villanueva De Aezkoa', value: 'Hiriberri/Villanueva De Aezkoa' },
  { label: 'Hiriyur', value: 'Hiriyur' },
  { label: 'Hirm', value: 'Hirm' },
  { label: 'Hirmand', value: 'Hirmand' },
  { label: 'Hīrna', value: 'Hīrna' },
  { label: 'Hirnsdorf', value: 'Hirnsdorf' },
  { label: 'Hirosaki', value: 'Hirosaki' },
  { label: 'Hirosaki Shi', value: 'Hirosaki Shi' },
  { label: 'Hiroshima', value: 'Hiroshima' },
  { label: 'Hiroshima-Shi', value: 'Hiroshima-Shi' },
  { label: 'Hirrlingen', value: 'Hirrlingen' },
  { label: 'Hirs’Ke', value: 'Hirs’Ke' },
  { label: 'Hirschaid', value: 'Hirschaid' },
  { label: 'Hirschau', value: 'Hirschau' },
  { label: 'Hirschbach', value: 'Hirschbach' },
  { label: 'Hirschbach', value: 'Hirschbach' },
  { label: 'Hirschberg An Der Bergstraße', value: 'Hirschberg An Der Bergstraße' },
  { label: 'Hirschegg', value: 'Hirschegg' },
  { label: 'Hirschfeld', value: 'Hirschfeld' },
  { label: 'Hirschfeld', value: 'Hirschfeld' },
  { label: 'Hirschfelde', value: 'Hirschfelde' },
  { label: 'Hirschhorn', value: 'Hirschhorn' },
  { label: 'Hirsingue', value: 'Hirsingue' },
  { label: 'Hirson', value: 'Hirson' },
  { label: 'Hirtenberg', value: 'Hirtenberg' },
  { label: 'Hirtshals', value: 'Hirtshals' },
  { label: 'Hirtzbach', value: 'Hirtzbach' },
  { label: 'Hirtzfelden', value: 'Hirtzfelden' },
  { label: 'Hiruela La', value: 'Hiruela La' },
  { label: 'Hirvensalmi', value: 'Hirvensalmi' },
  { label: 'Hirwaun', value: 'Hirwaun' },
  { label: 'Hirzenhain', value: 'Hirzenhain' },
  { label: 'Hisai-Motomachi', value: 'Hisai-Motomachi' },
  { label: 'Hisar', value: 'Hisar' },
  { label: 'Hisarcık', value: 'Hisarcık' },
  { label: 'Hisarya', value: 'Hisarya' },
  { label: 'Hiseti', value: 'Hiseti' },
  { label: 'Hisor', value: 'Hisor' },
  { label: 'Hispania', value: 'Hispania' },
  { label: 'Histon', value: 'Histon' },
  { label: 'Hisua', value: 'Hisua' },
  { label: 'Hisya', value: 'Hisya' },
  { label: 'Hīt', value: 'Hīt' },
  { label: 'Hīt District', value: 'Hīt District' },
  { label: 'Hita', value: 'Hita' },
  { label: 'Hita', value: 'Hita' },
  { label: 'Hita Shi', value: 'Hita Shi' },
  { label: 'Hitachi', value: 'Hitachi' },
  { label: 'Hitachi-Naka', value: 'Hitachi-Naka' },
  { label: 'Hitachinaka-Shi', value: 'Hitachinaka-Shi' },
  { label: 'Hitachiōmiya-Shi', value: 'Hitachiōmiya-Shi' },
  { label: 'Hitachi-Ota', value: 'Hitachi-Ota' },
  { label: 'Hitachiōta-Shi', value: 'Hitachiōta-Shi' },
  { label: 'Hitachi-Shi', value: 'Hitachi-Shi' },
  { label: 'Hitchcock', value: 'Hitchcock' },
  { label: 'Hitchcock County', value: 'Hitchcock County' },
  { label: 'Hitchin', value: 'Hitchin' },
  { label: 'Hithadhoo', value: 'Hithadhoo' },
  { label: 'Hito El', value: 'Hito El' },
  { label: 'Hitoyoshi', value: 'Hitoyoshi' },
  { label: 'Hitoyoshi Shi', value: 'Hitoyoshi Shi' },
  { label: 'Hitra', value: 'Hitra' },
  { label: 'Hittarp', value: 'Hittarp' },
  { label: 'Hittisau', value: 'Hittisau' },
  { label: 'Hittnau / Hittnau (Dorf)', value: 'Hittnau / Hittnau (Dorf)' },
  { label: 'Hitzacker', value: 'Hitzacker' },
  { label: 'Hitzendorf', value: 'Hitzendorf' },
  { label: 'Hitzhusen', value: 'Hitzhusen' },
  { label: 'Hitzkirch', value: 'Hitzkirch' },
  { label: 'Hizaj', value: 'Hizaj' },
  { label: 'Hizan', value: 'Hizan' },
  { label: 'Hjallerup', value: 'Hjallerup' },
  { label: 'Hjärnarp', value: 'Hjärnarp' },
  { label: 'Hjartdal', value: 'Hjartdal' },
  { label: 'Hjärup', value: 'Hjärup' },
  { label: 'Hjelmeland', value: 'Hjelmeland' },
  { label: 'Hjelset', value: 'Hjelset' },
  { label: 'Hjo', value: 'Hjo' },
  { label: 'Hjørring', value: 'Hjørring' },
  { label: 'Hjørring Kommune', value: 'Hjørring Kommune' },
  { label: 'Hjortshøj', value: 'Hjortshøj' },
  { label: 'Hjuvik', value: 'Hjuvik' },
  { label: 'Hlăpești', value: 'Hlăpești' },
  { label: 'Hlatikulu', value: 'Hlatikulu' },
  { label: 'Hlebine', value: 'Hlebine' },
  { label: 'Hlevakha', value: 'Hlevakha' },
  { label: 'Hlinsko', value: 'Hlinsko' },
  { label: 'Hlipiceni', value: 'Hlipiceni' },
  { label: 'Hlobyne', value: 'Hlobyne' },
  { label: 'Hlohovec', value: 'Hlohovec' },
  { label: 'Hlohovec', value: 'Hlohovec' },
  { label: 'Hlubočky', value: 'Hlubočky' },
  { label: 'Hluboká Nad Vltavou', value: 'Hluboká Nad Vltavou' },
  { label: 'Hlučín', value: 'Hlučín' },
  { label: 'Hluhluwe', value: 'Hluhluwe' },
  { label: 'Hluk', value: 'Hluk' },
  { label: 'Hlukhiv', value: 'Hlukhiv' },
  { label: 'Hlusha', value: 'Hlusha' },
  { label: 'Hlusk', value: 'Hlusk' },
  { label: 'Hluti', value: 'Hluti' },
  { label: 'Hlyboka', value: 'Hlyboka' },
  { label: 'Hlybokaye', value: 'Hlybokaye' },
  { label: 'Hlybotski Rayon', value: 'Hlybotski Rayon' },
  { label: 'Hlynsk', value: 'Hlynsk' },
  { label: 'Hmas Cerberus', value: 'Hmas Cerberus' },
  { label: 'Hnaberd', value: 'Hnaberd' },
  { label: 'Hněvošice', value: 'Hněvošice' },
  { label: 'Hněvotín', value: 'Hněvotín' },
  { label: 'Hnidyn', value: 'Hnidyn' },
  { label: 'Hnojník', value: 'Hnojník' },
  { label: 'Ho', value: 'Ho' },
  { label: 'Ho Chi Minh City', value: 'Ho Chi Minh City' },
  { label: 'Hòa Bình', value: 'Hòa Bình' },
  { label: 'Hoachanas', value: 'Hoachanas' },
  { label: 'Hoani', value: 'Hoani' },
  { label: 'Hoback', value: 'Hoback' },
  { label: 'Hobaramachi', value: 'Hobaramachi' },
  { label: 'Hobart', value: 'Hobart' },
  { label: 'Hobart', value: 'Hobart' },
  { label: 'Hobart', value: 'Hobart' },
  { label: 'Hobart', value: 'Hobart' },
  { label: 'Hobart', value: 'Hobart' },
  { label: 'Hobart City Centre', value: 'Hobart City Centre' },
  { label: 'Hobartville', value: 'Hobartville' },
  { label: 'Hobbs', value: 'Hobbs' },
  { label: 'Hobe Sound', value: 'Hobe Sound' },
  { label: 'Hobo', value: 'Hobo' },
  { label: 'Hobo', value: 'Hobo' },
  { label: 'Hoboken', value: 'Hoboken' },
  { label: 'Hoboken', value: 'Hoboken' },
  { label: 'Hobøl', value: 'Hobøl' },
  { label: 'Hobrede', value: 'Hobrede' },
  { label: 'Hobro', value: 'Hobro' },
  { label: 'Hobscheid', value: 'Hobscheid' },
  { label: 'Hobsons Bay', value: 'Hobsons Bay' },
  { label: 'Hobyo', value: 'Hobyo' },
  { label: 'Hocaba', value: 'Hocaba' },
  { label: 'Hocalar', value: 'Hocalar' },
  { label: 'Hoceni', value: 'Hoceni' },
  { label: 'Höchberg', value: 'Höchberg' },
  { label: 'Hochburg-Ach', value: 'Hochburg-Ach' },
  { label: 'Hochdonn', value: 'Hochdonn' },
  { label: 'Hochdorf', value: 'Hochdorf' },
  { label: 'Hochdorf', value: 'Hochdorf' },
  { label: 'Hochdorf District', value: 'Hochdorf District' },
  { label: 'Hochdorf-Assenheim', value: 'Hochdorf-Assenheim' },
  { label: 'Höchenschwand', value: 'Höchenschwand' },
  { label: 'Hochfeld', value: 'Hochfeld' },
  { label: 'Hochfelden', value: 'Hochfelden' },
  { label: 'Hochfelden', value: 'Hochfelden' },
  { label: 'Hochfilzen', value: 'Hochfilzen' },
  { label: 'Höchheim', value: 'Höchheim' },
  { label: 'Hochheim Am Main', value: 'Hochheim Am Main' },
  { label: 'Hochkirch', value: 'Hochkirch' },
  { label: 'Hochleithen', value: 'Hochleithen' },
  { label: 'Hochpoint', value: 'Hochpoint' },
  { label: 'Hochspeyer', value: 'Hochspeyer' },
  { label: 'Höchst', value: 'Höchst' },
  { label: 'Höchst Im Odenwald', value: 'Höchst Im Odenwald' },
  { label: 'Hochstadt', value: 'Hochstadt' },
  { label: 'Hochstadt Am Main', value: 'Hochstadt Am Main' },
  { label: 'Höchstadt An Der Aisch', value: 'Höchstadt An Der Aisch' },
  { label: 'Höchstädt An Der Donau', value: 'Höchstädt An Der Donau' },
  { label: 'Höchstädt Bei Thiersheim', value: 'Höchstädt Bei Thiersheim' },
  { label: 'Hochstatt', value: 'Hochstatt' },
  { label: 'Hochstetten-Dhaun', value: 'Hochstetten-Dhaun' },
  { label: 'Hochtregist', value: 'Hochtregist' },
  { label: 'Hochwald', value: 'Hochwald' },
  { label: 'Hochwolkersdorf', value: 'Hochwolkersdorf' },
  { label: 'Hociungi', value: 'Hociungi' },
  { label: 'Höckendorf', value: 'Höckendorf' },
  { label: 'Hockenheim', value: 'Hockenheim' },
  { label: 'Hockessin', value: 'Hockessin' },
  { label: 'Hocking', value: 'Hocking' },
  { label: 'Hocking County', value: 'Hocking County' },
  { label: 'Hockinson', value: 'Hockinson' },
  { label: 'Hockley', value: 'Hockley' },
  { label: 'Hockley County', value: 'Hockley County' },
  { label: 'Hockley Heath', value: 'Hockley Heath' },
  { label: 'Hockliffe', value: 'Hockliffe' },
  { label: 'Hockwold Cum Wilton', value: 'Hockwold Cum Wilton' },
  { label: 'Hoctun', value: 'Hoctun' },
  { label: 'Hod Hasharon', value: 'Hod Hasharon' },
  { label: 'Hodac', value: 'Hodac' },
  { label: 'Hodaia', value: 'Hodaia' },
  { label: 'Hodal', value: 'Hodal' },
  { label: 'Hodász', value: 'Hodász' },
  { label: 'Hoddesdon', value: 'Hoddesdon' },
  { label: 'Hodenhagen', value: 'Hodenhagen' },
  { label: 'Hodgdon', value: 'Hodgdon' },
  { label: 'Hodgeman County', value: 'Hodgeman County' },
  { label: 'Hodgenville', value: 'Hodgenville' },
  { label: 'Hodgkins', value: 'Hodgkins' },
  { label: 'Hodgson Vale', value: 'Hodgson Vale' },
  { label: 'Hodkovice Nad Mohelkou', value: 'Hodkovice Nad Mohelkou' },
  { label: 'Hodkovičky', value: 'Hodkovičky' },
  { label: 'Hódmezővásárhely', value: 'Hódmezővásárhely' },
  { label: 'Hódmezővásárhelyi Járás', value: 'Hódmezővásárhelyi Járás' },
  { label: 'Hodod', value: 'Hodod' },
  { label: 'Hodoni', value: 'Hodoni' },
  { label: 'Hodonice', value: 'Hodonice' },
  { label: 'Hodonín', value: 'Hodonín' },
  { label: 'Hodora', value: 'Hodora' },
  { label: 'Hodoš', value: 'Hodoš' },
  { label: 'Hodoșa', value: 'Hodoșa' },
  { label: 'Hodoșa', value: 'Hodoșa' },
  { label: 'Hodošan', value: 'Hodošan' },
  { label: 'Hodslavice', value: 'Hodslavice' },
  { label: 'Hoegaarden', value: 'Hoegaarden' },
  { label: 'Hoeilaart', value: 'Hoeilaart' },
  { label: 'Hoek', value: 'Hoek' },
  { label: 'Hoek Van Holland', value: 'Hoek Van Holland' },
  { label: 'Hoemul-Li', value: 'Hoemul-Li' },
  { label: 'Hoengseong-Gun', value: 'Hoengseong-Gun' },
  { label: 'Hœnheim', value: 'Hœnheim' },
  { label: 'Hoensbroek', value: 'Hoensbroek' },
  { label: 'Hœrdt', value: 'Hœrdt' },
  { label: 'Hoeryŏng', value: 'Hoeryŏng' },
  { label: 'Hoeselt', value: 'Hoeselt' },
  { label: 'Hoeven', value: 'Hoeven' },
  { label: 'Hoeyang', value: 'Hoeyang' },
  { label: 'Hof', value: 'Hof' },
  { label: 'Hof', value: 'Hof' },
  { label: 'Hof', value: 'Hof' },
  { label: 'Hof', value: 'Hof' },
  { label: 'Höf', value: 'Höf' },
  { label: 'Hof Am Leithaberge', value: 'Hof Am Leithaberge' },
  { label: 'Hof Bei Salzburg', value: 'Hof Bei Salzburg' },
  { label: 'Hof Bei Straden', value: 'Hof Bei Straden' },
  { label: 'Hofamt Priel', value: 'Hofamt Priel' },
  { label: 'Hofbieber', value: 'Hofbieber' },
  { label: 'Höfen', value: 'Höfen' },
  { label: 'Höfen An Der Enz', value: 'Höfen An Der Enz' },
  { label: 'Hoffman Estates', value: 'Hoffman Estates' },
  { label: 'Hofgeest', value: 'Hofgeest' },
  { label: 'Hofgeismar', value: 'Hofgeismar' },
  { label: 'Hofheim Am Taunus', value: 'Hofheim Am Taunus' },
  { label: 'Hofheim In Unterfranken', value: 'Hofheim In Unterfranken' },
  { label: 'Hofkirchen', value: 'Hofkirchen' },
  { label: 'Hofkirchen Im Traunkreis', value: 'Hofkirchen Im Traunkreis' },
  { label: 'Höflein', value: 'Höflein' },
  { label: 'Höfling', value: 'Höfling' },
  { label: 'Höfn', value: 'Höfn' },
  { label: 'Hofors', value: 'Hofors' },
  { label: 'Hofstätten An Der Raab', value: 'Hofstätten An Der Raab' },
  { label: 'Hofstetten', value: 'Hofstetten' },
  { label: 'Hofstetten', value: 'Hofstetten' },
  { label: 'Hofstetten', value: 'Hofstetten' },
  { label: 'Hofstetten', value: 'Hofstetten' },
  { label: 'Hofterup', value: 'Hofterup' },
  { label: 'Hōfu', value: 'Hōfu' },
  { label: 'Hofu Shi', value: 'Hofu Shi' },
  { label: 'Höganäs', value: 'Höganäs' },
  { label: 'Hogansville', value: 'Hogansville' },
  { label: 'Hoge Mors', value: 'Hoge Mors' },
  { label: 'Hoge Vucht', value: 'Hoge Vucht' },
  { label: 'Hoghilag', value: 'Hoghilag' },
  { label: 'Hoghiz', value: 'Hoghiz' },
  { label: 'Högsby', value: 'Högsby' },
  { label: 'Hőgyész', value: 'Hőgyész' },
  { label: 'Hohberg', value: 'Hohberg' },
  { label: 'Hohburg', value: 'Hohburg' },
  { label: 'Höheinöd', value: 'Höheinöd' },
  { label: 'Höheischweiler', value: 'Höheischweiler' },
  { label: 'Hohen Neuendorf', value: 'Hohen Neuendorf' },
  { label: 'Hohenaspe', value: 'Hohenaspe' },
  { label: 'Hohenau', value: 'Hohenau' },
  { label: 'Hohenau', value: 'Hohenau' },
  { label: 'Hohenau', value: 'Hohenau' },
  { label: 'Hohenau An Der Raab', value: 'Hohenau An Der Raab' },
  { label: 'Hohenberg', value: 'Hohenberg' },
  { label: 'Höhenberg', value: 'Höhenberg' },
  { label: 'Hohenberg An Der Eger', value: 'Hohenberg An Der Eger' },
  { label: 'Hohenbocka', value: 'Hohenbocka' },
  { label: 'Hohenbrunn', value: 'Hohenbrunn' },
  { label: 'Hohenburg', value: 'Hohenburg' },
  { label: 'Hohendodeleben', value: 'Hohendodeleben' },
  { label: 'Hoheneich', value: 'Hoheneich' },
  { label: 'Hohenems', value: 'Hohenems' },
  { label: 'Hohenfelde', value: 'Hohenfelde' },
  { label: 'Hohenfels', value: 'Hohenfels' },
  { label: 'Hohenfurch', value: 'Hohenfurch' },
  { label: 'Hohenhameln', value: 'Hohenhameln' },
  { label: 'Hohenkammer', value: 'Hohenkammer' },
  { label: 'Höhenkirchen-Siegertsbrunn', value: 'Höhenkirchen-Siegertsbrunn' },
  { label: 'Hohenleipisch', value: 'Hohenleipisch' },
  { label: 'Hohenleuben', value: 'Hohenleuben' },
  { label: 'Hohenlinden', value: 'Hohenlinden' },
  { label: 'Hohenlockstedt', value: 'Hohenlockstedt' },
  { label: 'Hohenmölsen', value: 'Hohenmölsen' },
  { label: 'Hohenpeißenberg', value: 'Hohenpeißenberg' },
  { label: 'Hohenpolding', value: 'Hohenpolding' },
  { label: 'Hohenrain', value: 'Hohenrain' },
  { label: 'Hohenruppersdorf', value: 'Hohenruppersdorf' },
  { label: 'Hohenstein-Ernstthal', value: 'Hohenstein-Ernstthal' },
  { label: 'Hohentauern', value: 'Hohentauern' },
  { label: 'Hohentengen', value: 'Hohentengen' },
  { label: 'Hohenthann', value: 'Hohenthann' },
  { label: 'Hohenthurm', value: 'Hohenthurm' },
  { label: 'Hohenthurn', value: 'Hohenthurn' },
  { label: 'Hohenwald', value: 'Hohenwald' },
  { label: 'Hohenwarsleben', value: 'Hohenwarsleben' },
  { label: 'Hohenwarth', value: 'Hohenwarth' },
  { label: 'Hohenwarthe', value: 'Hohenwarthe' },
  { label: 'Hohenweiler', value: 'Hohenweiler' },
  { label: 'Hohenwestedt', value: 'Hohenwestedt' },
  { label: 'Hohhot', value: 'Hohhot' },
  { label: 'Hohn', value: 'Hohn' },
  { label: 'Höhn', value: 'Höhn' },
  { label: 'Hohndorf', value: 'Hohndorf' },
  { label: 'Hohnhorst', value: 'Hohnhorst' },
  { label: 'Höhnstedt', value: 'Höhnstedt' },
  { label: 'Hohnstein', value: 'Hohnstein' },
  { label: 'Hohoe', value: 'Hohoe' },
  { label: 'Ho-Ho-Kus', value: 'Ho-Ho-Kus' },
  { label: 'Höhr-Grenzhausen', value: 'Höhr-Grenzhausen' },
  { label: 'Hội An', value: 'Hội An' },
  { label: 'Hoima', value: 'Hoima' },
  { label: 'Höingen', value: 'Höingen' },
  { label: 'Hoisdorf', value: 'Hoisdorf' },
  { label: 'Hoisington', value: 'Hoisington' },
  { label: 'Hojai', value: 'Hojai' },
  { label: 'Hojancha', value: 'Hojancha' },
  { label: 'Højby', value: 'Højby' },
  { label: 'Hojedk', value: 'Hojedk' },
  { label: 'Høje-Taastrup Kommune', value: 'Høje-Taastrup Kommune' },
  { label: 'Hōjō', value: 'Hōjō' },
  { label: 'Hökåsen', value: 'Hökåsen' },
  { label: 'Hoke County', value: 'Hoke County' },
  { label: 'Hokendauqua', value: 'Hokendauqua' },
  { label: 'Hokes Bluff', value: 'Hokes Bluff' },
  { label: 'Hokitika', value: 'Hokitika' },
  { label: 'Hököpinge', value: 'Hököpinge' },
  { label: 'Hokota-Shi', value: 'Hokota-Shi' },
  { label: 'Hokuto', value: 'Hokuto' },
  { label: 'Hokuto', value: 'Hokuto' },
  { label: 'Hokuto-Shi', value: 'Hokuto-Shi' },
  { label: 'Hokuto-Shi', value: 'Hokuto-Shi' },
  { label: 'Hol', value: 'Hol' },
  { label: 'Hola', value: 'Hola' },
  { label: 'Hola Prystan’', value: 'Hola Prystan’' },
  { label: 'Holalkere', value: 'Holalkere' },
  { label: 'Holambra', value: 'Holambra' },
  { label: 'Holasovice', value: 'Holasovice' },
  { label: 'Holbæk', value: 'Holbæk' },
  { label: 'Holbæk Kommune', value: 'Holbæk Kommune' },
  { label: 'Holbav', value: 'Holbav' },
  { label: 'Holbeach', value: 'Holbeach' },
  { label: 'Holbeck', value: 'Holbeck' },
  { label: 'Holboca', value: 'Holboca' },
  { label: 'Holbrook', value: 'Holbrook' },
  { label: 'Holbrook', value: 'Holbrook' },
  { label: 'Holbrook', value: 'Holbrook' },
  { label: 'Holbrook', value: 'Holbrook' },
  { label: 'Holbrook', value: 'Holbrook' },
  { label: 'Holca', value: 'Holca' },
  { label: 'Holcomb', value: 'Holcomb' },
  { label: 'Holcomb', value: 'Holcomb' },
  { label: 'Holden', value: 'Holden' },
  { label: 'Holden', value: 'Holden' },
  { label: 'Holden', value: 'Holden' },
  { label: 'Holden Heights', value: 'Holden Heights' },
  { label: 'Holden Hill', value: 'Holden Hill' },
  { label: 'Holdenville', value: 'Holdenville' },
  { label: 'Holder', value: 'Holder' },
  { label: 'Holderness', value: 'Holderness' },
  { label: 'Holdfast Bay', value: 'Holdfast Bay' },
  { label: 'Holdorf', value: 'Holdorf' },
  { label: 'Holdrege', value: 'Holdrege' },
  { label: 'Hole', value: 'Hole' },
  { label: 'Hole Narsipur', value: 'Hole Narsipur' },
  { label: 'Holeby', value: 'Holeby' },
  { label: 'Holešov', value: 'Holešov' },
  { label: 'Holetown', value: 'Holetown' },
  { label: 'Holgate', value: 'Holgate' },
  { label: 'Holguera', value: 'Holguera' },
  { label: 'Holguín', value: 'Holguín' },
  { label: 'Holhol', value: 'Holhol' },
  { label: 'Holíč', value: 'Holíč' },
  { label: 'Holice', value: 'Holice' },
  { label: 'Holiday', value: 'Holiday' },
  { label: 'Holiday City South', value: 'Holiday City South' },
  { label: 'Holiday City-Berkeley', value: 'Holiday City-Berkeley' },
  { label: 'Holiday Heights', value: 'Holiday Heights' },
  { label: 'Holiday Island', value: 'Holiday Island' },
  { label: 'Holiday Lakes', value: 'Holiday Lakes' },
  { label: 'Holiday Shores', value: 'Holiday Shores' },
  { label: 'Holiday Valley', value: 'Holiday Valley' },
  { label: 'Hollabrunn', value: 'Hollabrunn' },
  { label: 'Holladay', value: 'Holladay' },
  { label: 'Holland', value: 'Holland' },
  { label: 'Holland', value: 'Holland' },
  { label: 'Holland', value: 'Holland' },
  { label: 'Holland', value: 'Holland' },
  { label: 'Holland', value: 'Holland' },
  { label: 'Holland', value: 'Holland' },
  { label: 'Holland Park', value: 'Holland Park' },
  { label: 'Holland Park West', value: 'Holland Park West' },
  { label: 'Hollandale', value: 'Hollandale' },
  { label: 'Hollands Kroon', value: 'Hollands Kroon' },
  { label: 'Hollandsche Rading', value: 'Hollandsche Rading' },
  { label: 'Holle', value: 'Holle' },
  { label: 'Holleben', value: 'Holleben' },
  { label: 'Hollenbach', value: 'Hollenbach' },
  { label: 'Hollenegg', value: 'Hollenegg' },
  { label: 'Hollenstedt', value: 'Hollenstedt' },
  { label: 'Hollenstein An Der Ybbs', value: 'Hollenstein An Der Ybbs' },
  { label: 'Hollenthon', value: 'Hollenthon' },
  { label: 'Holler', value: 'Holler' },
  { label: 'Hollersbach Im Pinzgau', value: 'Hollersbach Im Pinzgau' },
  { label: 'Holley', value: 'Holley' },
  { label: 'Holley', value: 'Holley' },
  { label: 'Hollfeld', value: 'Hollfeld' },
  { label: 'Holliday', value: 'Holliday' },
  { label: 'Hollidaysburg', value: 'Hollidaysburg' },
  { label: 'Hollingstedt', value: 'Hollingstedt' },
  { label: 'Hollingworth', value: 'Hollingworth' },
  { label: 'Hollins', value: 'Hollins' },
  { label: 'Hollis', value: 'Hollis' },
  { label: 'Hollis', value: 'Hollis' },
  { label: 'Hollis', value: 'Hollis' },
  { label: 'Hollis Center', value: 'Hollis Center' },
  { label: 'Hollister', value: 'Hollister' },
  { label: 'Hollister', value: 'Hollister' },
  { label: 'Holliston', value: 'Holliston' },
  { label: 'Hollola', value: 'Hollola' },
  { label: 'Holloman Air Force Base', value: 'Holloman Air Force Base' },
  { label: 'Holloway', value: 'Holloway' },
  { label: 'Holloways Beach', value: 'Holloways Beach' },
  { label: 'Hollstadt', value: 'Hollstadt' },
  { label: 'Hollum', value: 'Hollum' },
  { label: 'Höllviken', value: 'Höllviken' },
  { label: 'Holly', value: 'Holly' },
  { label: 'Holly Hill', value: 'Holly Hill' },
  { label: 'Holly Hill', value: 'Holly Hill' },
  { label: 'Holly Hills', value: 'Holly Hills' },
  { label: 'Holly Lake Ranch', value: 'Holly Lake Ranch' },
  { label: 'Holly Ridge', value: 'Holly Ridge' },
  { label: 'Holly Springs', value: 'Holly Springs' },
  { label: 'Holly Springs', value: 'Holly Springs' },
  { label: 'Holly Springs', value: 'Holly Springs' },
  { label: 'Hollym', value: 'Hollym' },
  { label: 'Hollymead', value: 'Hollymead' },
  { label: 'Hollywell', value: 'Hollywell' },
  { label: 'Hollywood', value: 'Hollywood' },
  { label: 'Hollywood', value: 'Hollywood' },
  { label: 'Hollywood', value: 'Hollywood' },
  { label: 'Hollywood Park', value: 'Hollywood Park' },
  { label: 'Holm', value: 'Holm' },
  { label: 'Holma', value: 'Holma' },
  { label: 'Holmen', value: 'Holmen' },
  { label: 'Holmes Beach', value: 'Holmes Beach' },
  { label: 'Holmes Chapel', value: 'Holmes Chapel' },
  { label: 'Holmes County', value: 'Holmes County' },
  { label: 'Holmes County', value: 'Holmes County' },
  { label: 'Holmes County', value: 'Holmes County' },
  { label: 'Holmestrand', value: 'Holmestrand' },
  { label: 'Holmesville', value: 'Holmesville' },
  { label: 'Holmfirth', value: 'Holmfirth' },
  { label: 'Holmsund', value: 'Holmsund' },
  { label: 'Holmview', value: 'Holmview' },
  { label: 'Holmwood', value: 'Holmwood' },
  { label: 'Holnon', value: 'Holnon' },
  { label: 'Hölö', value: 'Hölö' },
  { label: 'Hołoby', value: 'Hołoby' },
  { label: 'Holod', value: 'Holod' },
  { label: 'H̱Olon', value: 'H̱Olon' },
  { label: 'Holosiiv Raion', value: 'Holosiiv Raion' },
  { label: 'Holoubkov', value: 'Holoubkov' },
  { label: 'Holovyne', value: 'Holovyne' },
  { label: 'Holovyne', value: 'Holovyne' },
  { label: 'Holroyd', value: 'Holroyd' },
  { label: 'Holsbeek', value: 'Holsbeek' },
  { label: 'Holstebro', value: 'Holstebro' },
  { label: 'Holstebro Kommune', value: 'Holstebro Kommune' },
  { label: 'Holsted', value: 'Holsted' },
  { label: 'Holstein', value: 'Holstein' },
  { label: 'Hölstein', value: 'Hölstein' },
  { label: 'Holsworthy', value: 'Holsworthy' },
  { label: 'Holsworthy', value: 'Holsworthy' },
  { label: 'Holt', value: 'Holt' },
  { label: 'Holt', value: 'Holt' },
  { label: 'Holt', value: 'Holt' },
  { label: 'Holt', value: 'Holt' },
  { label: 'Holt', value: 'Holt' },
  { label: 'Holt County', value: 'Holt County' },
  { label: 'Holt County', value: 'Holt County' },
  { label: 'Holtålen', value: 'Holtålen' },
  { label: 'Holtby', value: 'Holtby' },
  { label: 'Holtland', value: 'Holtland' },
  { label: 'Holton', value: 'Holton' },
  { label: 'Holton Le Clay', value: 'Holton Le Clay' },
  { label: 'Holts Summit', value: 'Holts Summit' },
  { label: 'Holtsee', value: 'Holtsee' },
  { label: 'Holtsville', value: 'Holtsville' },
  { label: 'Holtum', value: 'Holtum' },
  { label: 'Holtville', value: 'Holtville' },
  { label: 'Holtville', value: 'Holtville' },
  { label: 'Holtze', value: 'Holtze' },
  { label: 'Holtzheim', value: 'Holtzheim' },
  { label: 'Holtzwihr', value: 'Holtzwihr' },
  { label: 'Hōlualoa', value: 'Hōlualoa' },
  { label: 'Holubivka', value: 'Holubivka' },
  { label: 'Holvar-E Sofla', value: 'Holvar-E Sofla' },
  { label: 'Holving', value: 'Holving' },
  { label: 'Holwerd', value: 'Holwerd' },
  { label: 'Holyhead', value: 'Holyhead' },
  { label: 'Holyoke', value: 'Holyoke' },
  { label: 'Holyoke', value: 'Holyoke' },
  { label: 'Holysloot', value: 'Holysloot' },
  { label: 'Holýšov', value: 'Holýšov' },
  { label: 'Holytown', value: 'Holytown' },
  { label: 'Holywell', value: 'Holywell' },
  { label: 'Holywell Green', value: 'Holywell Green' },
  { label: 'Holywood', value: 'Holywood' },
  { label: 'Holz', value: 'Holz' },
  { label: 'Holzappel', value: 'Holzappel' },
  { label: 'Holzdorf', value: 'Holzdorf' },
  { label: 'Holzgau', value: 'Holzgau' },
  { label: 'Holzgerlingen', value: 'Holzgerlingen' },
  { label: 'Holzgünz', value: 'Holzgünz' },
  { label: 'Holzhausen', value: 'Holzhausen' },
  { label: 'Holzhausen An Der Haide', value: 'Holzhausen An Der Haide' },
  { label: 'Holzheim', value: 'Holzheim' },
  { label: 'Holzkirchen', value: 'Holzkirchen' },
  { label: 'Holzmaden', value: 'Holzmaden' },
  { label: 'Holzminden', value: 'Holzminden' },
  { label: 'Holzweißig', value: 'Holzweißig' },
  { label: 'Holzwickede', value: 'Holzwickede' },
  { label: 'Homa Bay', value: 'Homa Bay' },
  { label: 'Homagama', value: 'Homagama' },
  { label: 'Homashahr', value: 'Homashahr' },
  { label: 'Homberg', value: 'Homberg' },
  { label: 'Hombleux', value: 'Hombleux' },
  { label: 'Homblières', value: 'Homblières' },
  { label: 'Hombourg-Haut', value: 'Hombourg-Haut' },
  { label: 'Hombrados', value: 'Hombrados' },
  { label: 'Hombrechtikon', value: 'Hombrechtikon' },
  { label: 'Homburg', value: 'Homburg' },
  { label: 'Homburg', value: 'Homburg' },
  { label: 'Home', value: 'Home' },
  { label: 'Home Garden', value: 'Home Garden' },
  { label: 'Home Gardens', value: 'Home Gardens' },
  { label: 'Home Hill', value: 'Home Hill' },
  { label: 'Hom-E Khosrow', value: 'Hom-E Khosrow' },
  { label: 'Homeacre-Lyndora', value: 'Homeacre-Lyndora' },
  { label: 'Homebush', value: 'Homebush' },
  { label: 'Homebush West', value: 'Homebush West' },
  { label: 'Homécourt', value: 'Homécourt' },
  { label: 'Homedale', value: 'Homedale' },
  { label: 'Homeland', value: 'Homeland' },
  { label: 'Homeland Park', value: 'Homeland Park' },
  { label: 'Homer', value: 'Homer' },
  { label: 'Homer', value: 'Homer' },
  { label: 'Homer', value: 'Homer' },
  { label: 'Homer', value: 'Homer' },
  { label: 'Homer', value: 'Homer' },
  { label: 'Homer', value: 'Homer' },
  { label: 'Homer City', value: 'Homer City' },
  { label: 'Homer Glen', value: 'Homer Glen' },
  { label: 'Homerville', value: 'Homerville' },
  { label: 'Homestead', value: 'Homestead' },
  { label: 'Homestead', value: 'Homestead' },
  { label: 'Homestead Meadows North', value: 'Homestead Meadows North' },
  { label: 'Homestead Meadows South', value: 'Homestead Meadows South' },
  { label: 'Hometown', value: 'Hometown' },
  { label: 'Hometown', value: 'Hometown' },
  { label: 'Homewood', value: 'Homewood' },
  { label: 'Homewood', value: 'Homewood' },
  { label: 'Hominy', value: 'Hominy' },
  { label: 'Hommelvik', value: 'Hommelvik' },
  { label: 'Hommersåk', value: 'Hommersåk' },
  { label: 'Homnabad', value: 'Homnabad' },
  { label: 'Homocea', value: 'Homocea' },
  { label: 'Homorâciu', value: 'Homorâciu' },
  { label: 'Homorod', value: 'Homorod' },
  { label: 'Homorodu De Jos', value: 'Homorodu De Jos' },
  { label: 'Homosassa', value: 'Homosassa' },
  { label: 'Homosassa Springs', value: 'Homosassa Springs' },
  { label: 'Homs', value: 'Homs' },
  { label: 'Homun', value: 'Homun' },
  { label: 'Homyel', value: 'Homyel' },
  { label: 'Homyel’Ski Rayon', value: 'Homyel’Ski Rayon' },
  { label: 'Honaker', value: 'Honaker' },
  { label: 'Honalo', value: 'Honalo' },
  { label: 'Honaunau-Napoopoo', value: 'Honaunau-Napoopoo' },
  { label: 'Honavar', value: 'Honavar' },
  { label: 'Honaz', value: 'Honaz' },
  { label: 'Honchō', value: 'Honchō' },
  { label: 'Honchō', value: 'Honchō' },
  { label: 'Honda', value: 'Honda' },
  { label: 'Hondagua', value: 'Hondagua' },
  { label: 'Hondarribia', value: 'Hondarribia' },
  { label: 'Hondo', value: 'Hondo' },
  { label: 'Hondomachi-Hondo', value: 'Hondomachi-Hondo' },
  { label: 'Hondón De Las Nieves', value: 'Hondón De Las Nieves' },
  { label: 'Hondón De Los Frailes', value: 'Hondón De Los Frailes' },
  { label: 'Hondschoote', value: 'Hondschoote' },
  { label: 'Hone', value: 'Hone' },
  { label: 'Honea Path', value: 'Honea Path' },
  { label: 'Hønefoss', value: 'Hønefoss' },
  { label: 'Honeoye Falls', value: 'Honeoye Falls' },
  { label: 'Honesdale', value: 'Honesdale' },
  { label: 'Honey', value: 'Honey' },
  { label: 'Honey Brook', value: 'Honey Brook' },
  { label: 'Honey Grove', value: 'Honey Grove' },
  { label: 'Honeybourne', value: 'Honeybourne' },
  { label: 'Honeymoon Beach', value: 'Honeymoon Beach' },
  { label: 'Honeyville', value: 'Honeyville' },
  { label: 'Honfleur', value: 'Honfleur' },
  { label: 'Høng', value: 'Høng' },
  { label: 'Hongch’Ŏn', value: 'Hongch’Ŏn' },
  { label: 'Hongcheon-Gun', value: 'Hongcheon-Gun' },
  { label: 'Honggang', value: 'Honggang' },
  { label: 'Honghe Hanizu Yizu Zizhizhou', value: 'Honghe Hanizu Yizu Zizhizhou' },
  { label: 'Hongjiang', value: 'Hongjiang' },
  { label: 'Hongqiao', value: 'Hongqiao' },
  { label: 'Hongseong', value: 'Hongseong' },
  { label: 'Hongseong-Gun', value: 'Hongseong-Gun' },
  { label: 'Hongtang', value: 'Hongtang' },
  { label: 'Hongwŏn', value: 'Hongwŏn' },
  { label: 'Hongyang', value: 'Hongyang' },
  { label: 'Honiara', value: 'Honiara' },
  { label: 'Hönigsberg', value: 'Hönigsberg' },
  { label: 'Honiton', value: 'Honiton' },
  { label: 'Honjō', value: 'Honjō' },
  { label: 'Honjō Shi', value: 'Honjō Shi' },
  { label: 'Honmachi', value: 'Honmachi' },
  { label: 'Honnali', value: 'Honnali' },
  { label: 'Honningsvåg', value: 'Honningsvåg' },
  { label: 'Hönö', value: 'Hönö' },
  { label: 'Honoka‘A', value: 'Honoka‘A' },
  { label: 'Honolulu', value: 'Honolulu' },
  { label: 'Honolulu County', value: 'Honolulu County' },
  { label: 'Honório Serpa', value: 'Honório Serpa' },
  { label: 'Honrubia', value: 'Honrubia' },
  { label: 'Honrubia De La Cuesta', value: 'Honrubia De La Cuesta' },
  { label: 'Honselersdijk', value: 'Honselersdijk' },
  { label: 'Hontalbilla', value: 'Hontalbilla' },
  { label: 'Hontanar', value: 'Hontanar' },
  { label: 'Hontanares De Eresma', value: 'Hontanares De Eresma' },
  { label: 'Hontanas', value: 'Hontanas' },
  { label: 'Hontanaya', value: 'Hontanaya' },
  { label: 'Hontangas', value: 'Hontangas' },
  { label: 'Hontecillas', value: 'Hontecillas' },
  { label: 'Hontoba', value: 'Hontoba' },
  { label: 'Hontoria De Cerrato', value: 'Hontoria De Cerrato' },
  { label: 'Hontoria De La Cantera', value: 'Hontoria De La Cantera' },
  { label: 'Hontoria De Valdearados', value: 'Hontoria De Valdearados' },
  { label: 'Hontoria Del Pinar', value: 'Hontoria Del Pinar' },
  { label: 'Hoo', value: 'Hoo' },
  { label: 'Hood County', value: 'Hood County' },
  { label: 'Hood River', value: 'Hood River' },
  { label: 'Hood River County', value: 'Hood River County' },
  { label: 'Hoofddorp', value: 'Hoofddorp' },
  { label: 'Hoogblokland', value: 'Hoogblokland' },
  { label: 'Hooge Mierde', value: 'Hooge Mierde' },
  { label: 'Hooge Zwaluwe', value: 'Hooge Zwaluwe' },
  { label: 'Hoogeloon', value: 'Hoogeloon' },
  { label: 'Hoogerheide', value: 'Hoogerheide' },
  { label: 'Hoogeveen', value: 'Hoogeveen' },
  { label: 'Hoogezand', value: 'Hoogezand' },
  { label: 'Hooghly District', value: 'Hooghly District' },
  { label: 'Hoogkamp', value: 'Hoogkamp' },
  { label: 'Hoogkarspel', value: 'Hoogkarspel' },
  { label: 'Hooglede', value: 'Hooglede' },
  { label: 'Hoogmade', value: 'Hoogmade' },
  { label: 'Hoogstede', value: 'Hoogstede' },
  { label: 'Hoogstraten', value: 'Hoogstraten' },
  { label: 'Hoogwoud', value: 'Hoogwoud' },
  { label: 'Hook', value: 'Hook' },
  { label: 'Hook', value: 'Hook' },
  { label: 'Hook Norton', value: 'Hook Norton' },
  { label: 'Hooker', value: 'Hooker' },
  { label: 'Hooker County', value: 'Hooker County' },
  { label: 'Hooks', value: 'Hooks' },
  { label: 'Hooksett', value: 'Hooksett' },
  { label: 'Hool', value: 'Hool' },
  { label: 'Hoole', value: 'Hoole' },
  { label: 'Hoonah-Angoon Census Area', value: 'Hoonah-Angoon Census Area' },
  { label: 'Hooper', value: 'Hooper' },
  { label: 'Hooper Bay', value: 'Hooper Bay' },
  { label: 'Hoopers Creek', value: 'Hoopers Creek' },
  { label: 'Hoopeston', value: 'Hoopeston' },
  { label: 'Hoopstad', value: 'Hoopstad' },
  { label: 'Höör', value: 'Höör' },
  { label: 'Hoorn', value: 'Hoorn' },
  { label: 'Hoosick Falls', value: 'Hoosick Falls' },
  { label: 'Hooth', value: 'Hooth' },
  { label: 'Hoover', value: 'Hoover' },
  { label: 'Hooverson Heights', value: 'Hooverson Heights' },
  { label: 'Hopa', value: 'Hopa' },
  { label: 'Hopârta', value: 'Hopârta' },
  { label: 'Hopatcong', value: 'Hopatcong' },
  { label: 'Hopatcong Hills', value: 'Hopatcong Hills' },
  { label: 'Hope', value: 'Hope' },
  { label: 'Hope', value: 'Hope' },
  { label: 'Hope', value: 'Hope' },
  { label: 'Hope', value: 'Hope' },
  { label: 'Hope', value: 'Hope' },
  { label: 'Hope Bay', value: 'Hope Bay' },
  { label: 'Hope Estate', value: 'Hope Estate' },
  { label: 'Hope Island', value: 'Hope Island' },
  { label: 'Hope Mills', value: 'Hope Mills' },
  { label: 'Hope Pastures/ Utech', value: 'Hope Pastures/ Utech' },
  { label: 'Hope Tavern', value: 'Hope Tavern' },
  { label: 'Hope Vale', value: 'Hope Vale' },
  { label: 'Hope Valley', value: 'Hope Valley' },
  { label: 'Hope Valley', value: 'Hope Valley' },
  { label: 'Hope Valley', value: 'Hope Valley' },
  { label: 'Hopedale', value: 'Hopedale' },
  { label: 'Hopel', value: 'Hopel' },
  { label: 'Hopelchén', value: 'Hopelchén' },
  { label: 'Hopelchén', value: 'Hopelchén' },
  { label: 'Hopeman', value: 'Hopeman' },
  { label: 'Hopen', value: 'Hopen' },
  { label: 'Hopeton', value: 'Hopeton' },
  { label: 'Hopeton', value: 'Hopeton' },
  { label: 'Hopewell', value: 'Hopewell' },
  { label: 'Hopewell', value: 'Hopewell' },
  { label: 'Hopewell', value: 'Hopewell' },
  { label: 'Hopewell', value: 'Hopewell' },
  { label: 'Hopferau', value: 'Hopferau' },
  { label: 'Hopfgarten Im Brixental', value: 'Hopfgarten Im Brixental' },
  { label: 'Hopfgarten In Defereggen', value: 'Hopfgarten In Defereggen' },
  { label: 'Höpfingen', value: 'Höpfingen' },
  { label: 'Hôpital-Camfrout', value: 'Hôpital-Camfrout' },
  { label: 'Hopkins', value: 'Hopkins' },
  { label: 'Hopkins', value: 'Hopkins' },
  { label: 'Hopkins County', value: 'Hopkins County' },
  { label: 'Hopkins County', value: 'Hopkins County' },
  { label: 'Hopkinsville', value: 'Hopkinsville' },
  { label: 'Hopkinton', value: 'Hopkinton' },
  { label: 'Hopkinton', value: 'Hopkinton' },
  { label: 'Hopkinton', value: 'Hopkinton' },
  { label: 'Hoppers Crossing', value: 'Hoppers Crossing' },
  { label: 'Hoppstädten-Weiersbach', value: 'Hoppstädten-Weiersbach' },
  { label: 'Hopton On Sea', value: 'Hopton On Sea' },
  { label: 'Hopwood', value: 'Hopwood' },
  { label: 'Hoquiam', value: 'Hoquiam' },
  { label: 'Horace', value: 'Horace' },
  { label: 'Horad Baranavichy', value: 'Horad Baranavichy' },
  { label: 'Horad Brest', value: 'Horad Brest' },
  { label: 'Horad Hrodna', value: 'Horad Hrodna' },
  { label: 'Horad Smalyavichy', value: 'Horad Smalyavichy' },
  { label: 'Horad Zhodzina', value: 'Horad Zhodzina' },
  { label: 'Horadiz', value: 'Horadiz' },
  { label: 'Horam', value: 'Horam' },
  { label: 'Horana South', value: 'Horana South' },
  { label: 'Horasan', value: 'Horasan' },
  { label: 'Horatio', value: 'Horatio' },
  { label: 'Horatski Rayon', value: 'Horatski Rayon' },
  { label: 'Horawala Junction', value: 'Horawala Junction' },
  { label: 'Horažďovice', value: 'Horažďovice' },
  { label: 'Horb Am Neckar', value: 'Horb Am Neckar' },
  { label: 'Horben', value: 'Horben' },
  { label: 'Horbourg-Wihr', value: 'Horbourg-Wihr' },
  { label: 'Hörbranz', value: 'Hörbranz' },
  { label: 'Horbury', value: 'Horbury' },
  { label: 'Hörby', value: 'Hörby' },
  { label: 'Horcajada La', value: 'Horcajada La' },
  { label: 'Horcajo De La Sierra', value: 'Horcajo De La Sierra' },
  { label: 'Horcajo De La Sierra-Aoslos', value: 'Horcajo De La Sierra-Aoslos' },
  { label: 'Horcajo De Las Torres', value: 'Horcajo De Las Torres' },
  { label: 'Horcajo De Los Montes', value: 'Horcajo De Los Montes' },
  { label: 'Horcajo De Montemayor', value: 'Horcajo De Montemayor' },
  { label: 'Horcajo De Santiago', value: 'Horcajo De Santiago' },
  { label: 'Horcajo Medianero', value: 'Horcajo Medianero' },
  { label: 'Horcajuelo De La Sierra', value: 'Horcajuelo De La Sierra' },
  { label: 'Horcasitas', value: 'Horcasitas' },
  { label: 'Horche', value: 'Horche' },
  { label: 'Horconcitos', value: 'Horconcitos' },
  { label: 'Hordain', value: 'Hordain' },
  { label: 'Hordel', value: 'Hordel' },
  { label: 'Hörden', value: 'Hörden' },
  { label: 'Hördt', value: 'Hördt' },
  { label: 'Horea', value: 'Horea' },
  { label: 'Horenka', value: 'Horenka' },
  { label: 'Horezu', value: 'Horezu' },
  { label: 'Hörgársveit', value: 'Hörgársveit' },
  { label: 'Horgau', value: 'Horgau' },
  { label: 'Horgen', value: 'Horgen' },
  { label: 'Horgen / Allmend', value: 'Horgen / Allmend' },
  { label: 'Horgen / Horgen (Dorfkern)', value: 'Horgen / Horgen (Dorfkern)' },
  { label: 'Horgen / Oberdorf', value: 'Horgen / Oberdorf' },
  { label: 'Horgen / Scheller-Stockerstrasse', value: 'Horgen / Scheller-Stockerstrasse' },
  { label: 'Horgenzell', value: 'Horgenzell' },
  { label: 'Hörgertshausen', value: 'Hörgertshausen' },
  { label: 'Horgeşti', value: 'Horgeşti' },
  { label: 'Horgues', value: 'Horgues' },
  { label: 'Horhausen', value: 'Horhausen' },
  { label: 'Horhoreh', value: 'Horhoreh' },
  { label: 'Höri', value: 'Höri' },
  { label: 'Horia', value: 'Horia' },
  { label: 'Horia', value: 'Horia' },
  { label: 'Horia', value: 'Horia' },
  { label: 'Horia', value: 'Horia' },
  { label: 'Hořice', value: 'Hořice' },
  { label: 'Horicon', value: 'Horicon' },
  { label: 'Horinchovo', value: 'Horinchovo' },
  { label: 'Horishni Plavni', value: 'Horishni Plavni' },
  { label: 'Horitschon', value: 'Horitschon' },
  { label: 'Horizon City', value: 'Horizon City' },
  { label: 'Horizon West', value: 'Horizon West' },
  { label: 'Horizonte', value: 'Horizonte' },
  { label: 'Horizontina', value: 'Horizontina' },
  { label: 'Horjul', value: 'Horjul' },
  { label: 'Horka', value: 'Horka' },
  { label: 'Horka Nad Moravou', value: 'Horka Nad Moravou' },
  { label: 'Horki', value: 'Horki' },
  { label: 'Horleşti', value: 'Horleşti' },
  { label: 'Horley', value: 'Horley' },
  { label: 'Horlivka', value: 'Horlivka' },
  { label: 'Hormazas Las', value: 'Hormazas Las' },
  { label: 'Hormersdorf', value: 'Hormersdorf' },
  { label: 'Hormigos', value: 'Hormigos' },
  { label: 'Hormiguero', value: 'Hormiguero' },
  { label: 'Hormigueros', value: 'Hormigueros' },
  { label: 'Hormigueros', value: 'Hormigueros' },
  { label: 'Hormigueros Municipio', value: 'Hormigueros Municipio' },
  { label: 'Hormilla', value: 'Hormilla' },
  { label: 'Hormilleja', value: 'Hormilleja' },
  { label: 'Hormuz', value: 'Hormuz' },
  { label: 'Horn', value: 'Horn' },
  { label: 'Horn', value: 'Horn' },
  { label: 'Horn', value: 'Horn' },
  { label: 'Horn Lake', value: 'Horn Lake' },
  { label: 'Hornachos', value: 'Hornachos' },
  { label: 'Hornachuelos', value: 'Hornachuelos' },
  { label: 'Hornaing', value: 'Hornaing' },
  { label: 'Hornbach', value: 'Hornbach' },
  { label: 'Hornbæk', value: 'Hornbæk' },
  { label: 'Hornberg', value: 'Hornberg' },
  { label: 'Hornburg', value: 'Hornburg' },
  { label: 'Hornby Island', value: 'Hornby Island' },
  { label: 'Horncastle', value: 'Horncastle' },
  { label: 'Hornchurch', value: 'Hornchurch' },
  { label: 'Horndal', value: 'Horndal' },
  { label: 'Horndon On The Hill', value: 'Horndon On The Hill' },
  { label: 'Horneburg', value: 'Horneburg' },
  { label: 'Hörnefors', value: 'Hörnefors' },
  { label: 'Hornell', value: 'Hornell' },
  { label: 'Hornepayne', value: 'Hornepayne' },
  { label: 'Hornhausen', value: 'Hornhausen' },
  { label: 'Horní Bečva', value: 'Horní Bečva' },
  { label: 'Horní Benešov', value: 'Horní Benešov' },
  { label: 'Horní Bludovice', value: 'Horní Bludovice' },
  { label: 'Horní Branná', value: 'Horní Branná' },
  { label: 'Horní Bříza', value: 'Horní Bříza' },
  { label: 'Horní Cerekev', value: 'Horní Cerekev' },
  { label: 'Horní Čermná', value: 'Horní Čermná' },
  { label: 'Horní Jelení', value: 'Horní Jelení' },
  { label: 'Horní Jiřetín', value: 'Horní Jiřetín' },
  { label: 'Horní Lideč', value: 'Horní Lideč' },
  { label: 'Horní Město', value: 'Horní Město' },
  { label: 'Horní Moštěnice', value: 'Horní Moštěnice' },
  { label: 'Horní Planá', value: 'Horní Planá' },
  { label: 'Horní Počaply', value: 'Horní Počaply' },
  { label: 'Horní Počernice', value: 'Horní Počernice' },
  { label: 'Horní Slavkov', value: 'Horní Slavkov' },
  { label: 'Horní Sloupnice', value: 'Horní Sloupnice' },
  { label: 'Horní Štěpánov', value: 'Horní Štěpánov' },
  { label: 'Horní Stropnice', value: 'Horní Stropnice' },
  { label: 'Horní Suchá', value: 'Horní Suchá' },
  { label: 'Horní Těrlicko', value: 'Horní Těrlicko' },
  { label: 'Hornillo El', value: 'Hornillo El' },
  { label: 'Hornillos De Cameros', value: 'Hornillos De Cameros' },
  { label: 'Hornillos De Cerrato', value: 'Hornillos De Cerrato' },
  { label: 'Hornillos De Eresma', value: 'Hornillos De Eresma' },
  { label: 'Hornillos Del Camino', value: 'Hornillos Del Camino' },
  { label: 'Hornindal', value: 'Hornindal' },
  { label: 'Horning', value: 'Horning' },
  { label: 'Hørning', value: 'Hørning' },
  { label: 'Horningsea Park', value: 'Horningsea Park' },
  { label: 'Hornitos', value: 'Hornitos' },
  { label: 'Hornos', value: 'Hornos' },
  { label: 'Hornos De Moncalvillo', value: 'Hornos De Moncalvillo' },
  { label: 'Hornostayivka', value: 'Hornostayivka' },
  { label: 'Hornówek', value: 'Hornówek' },
  { label: 'Hornoy-Le-Bourg', value: 'Hornoy-Le-Bourg' },
  { label: 'Hornsby', value: 'Hornsby' },
  { label: 'Hornsby Bend', value: 'Hornsby Bend' },
  { label: 'Hornsby Heights', value: 'Hornsby Heights' },
  { label: 'Hornsby Shire', value: 'Hornsby Shire' },
  { label: 'Hornsea', value: 'Hornsea' },
  { label: 'Hörnsheim', value: 'Hörnsheim' },
  { label: 'Hornslet', value: 'Hornslet' },
  { label: 'Hornstein', value: 'Hornstein' },
  { label: 'Hornstorf', value: 'Hornstorf' },
  { label: 'Hornsyld', value: 'Hornsyld' },
  { label: 'Horoatu Crasnei', value: 'Horoatu Crasnei' },
  { label: 'Horodenka', value: 'Horodenka' },
  { label: 'Horodenkivs’Kyy Rayon', value: 'Horodenkivs’Kyy Rayon' },
  { label: 'Horodets', value: 'Horodets' },
  { label: 'Horodło', value: 'Horodło' },
  { label: 'Horodnic De Jos', value: 'Horodnic De Jos' },
  { label: 'Horodnic De Sus', value: 'Horodnic De Sus' },
  { label: 'Horodniceni', value: 'Horodniceni' },
  { label: 'Horodnya', value: 'Horodnya' },
  { label: 'Horodnye', value: 'Horodnye' },
  { label: 'Horodnytsia', value: 'Horodnytsia' },
  { label: 'Horodnytsya', value: 'Horodnytsya' },
  { label: 'Horodok', value: 'Horodok' },
  { label: 'Horodok', value: 'Horodok' },
  { label: 'Horodok', value: 'Horodok' },
  { label: 'Horodyshche', value: 'Horodyshche' },
  { label: 'Horodyshche', value: 'Horodyshche' },
  { label: 'Horokhiv', value: 'Horokhiv' },
  { label: 'Horoměřice', value: 'Horoměřice' },
  { label: 'Hořovice', value: 'Hořovice' },
  { label: 'Horowhenua District', value: 'Horowhenua District' },
  { label: 'Horqueta', value: 'Horqueta' },
  { label: 'Horr', value: 'Horr' },
  { label: 'Horra La', value: 'Horra La' },
  { label: 'Horrabridge', value: 'Horrabridge' },
  { label: 'Horred', value: 'Horred' },
  { label: 'Horrenberg', value: 'Horrenberg' },
  { label: 'Horrom', value: 'Horrom' },
  { label: 'Horry County', value: 'Horry County' },
  { label: 'Hörsching', value: 'Hörsching' },
  { label: 'Horse Cave', value: 'Horse Cave' },
  { label: 'Horse Pasture', value: 'Horse Pasture' },
  { label: 'Horse Shoe', value: 'Horse Shoe' },
  { label: 'Horseheads', value: 'Horseheads' },
  { label: 'Horseheads North', value: 'Horseheads North' },
  { label: 'Hörselgau', value: 'Hörselgau' },
  { label: 'Horsens', value: 'Horsens' },
  { label: 'Horsens Kommune', value: 'Horsens Kommune' },
  { label: 'Horseshoe Bay', value: 'Horseshoe Bay' },
  { label: 'Horseshoe Bend', value: 'Horseshoe Bend' },
  { label: 'Horsford', value: 'Horsford' },
  { label: 'Horsforth', value: 'Horsforth' },
  { label: 'Horsham', value: 'Horsham' },
  { label: 'Horsham', value: 'Horsham' },
  { label: 'Horsham', value: 'Horsham' },
  { label: 'Hørsholm', value: 'Hørsholm' },
  { label: 'Hørsholm Kommune', value: 'Hørsholm Kommune' },
  { label: 'Horsley', value: 'Horsley' },
  { label: 'Horsley Park', value: 'Horsley Park' },
  { label: 'Horsmonden', value: 'Horsmonden' },
  { label: 'Horšovský Týn', value: 'Horšovský Týn' },
  { label: 'Horssen', value: 'Horssen' },
  { label: 'Horst', value: 'Horst' },
  { label: 'Horsted Keynes', value: 'Horsted Keynes' },
  { label: 'Horstedt', value: 'Horstedt' },
  { label: 'Hörstel', value: 'Hörstel' },
  { label: 'Horstermeer', value: 'Horstermeer' },
  { label: 'Horstmar', value: 'Horstmar' },
  { label: 'Hort', value: 'Hort' },
  { label: 'Horta', value: 'Horta' },
  { label: 'Horta', value: 'Horta' },
  { label: 'Horta De Sant Joan', value: 'Horta De Sant Joan' },
  { label: 'Hortaleza', value: 'Hortaleza' },
  { label: 'Horten', value: 'Horten' },
  { label: 'Hörtendorf', value: 'Hörtendorf' },
  { label: 'Hortezuela De Océn', value: 'Hortezuela De Océn' },
  { label: 'Hortigüela', value: 'Hortigüela' },
  { label: 'Hortlax', value: 'Hortlax' },
  { label: 'Hortobágy', value: 'Hortobágy' },
  { label: 'Hortolândia', value: 'Hortolândia' },
  { label: 'Horton', value: 'Horton' },
  { label: 'Horton', value: 'Horton' },
  { label: 'Horton Kirby', value: 'Horton Kirby' },
  { label: 'Hortonville', value: 'Hortonville' },
  { label: 'Høruphav', value: 'Høruphav' },
  { label: 'Horvati', value: 'Horvati' },
  { label: 'Hørve', value: 'Hørve' },
  { label: 'Horw', value: 'Horw' },
  { label: 'Horwich', value: 'Horwich' },
  { label: 'Horyniec-Zdrój', value: 'Horyniec-Zdrój' },
  { label: 'Hōryūji', value: 'Hōryūji' },
  { label: 'Hosa’Ina', value: 'Hosa’Ina' },
  { label: 'Hosanagara', value: 'Hosanagara' },
  { label: 'Hosangadi', value: 'Hosangadi' },
  { label: 'Hösbach', value: 'Hösbach' },
  { label: 'Hoschton', value: 'Hoschton' },
  { label: 'Hosdurga', value: 'Hosdurga' },
  { label: 'Hosenfeld', value: 'Hosenfeld' },
  { label: 'Hoseynabad-E Bahar', value: 'Hoseynabad-E Bahar' },
  { label: 'Hoseynabad-E Nazem', value: 'Hoseynabad-E Nazem' },
  { label: 'Hoseynabad-E Shamlu', value: 'Hoseynabad-E Shamlu' },
  { label: 'Hoseyniye', value: 'Hoseyniye' },
  { label: 'Hoshangabad', value: 'Hoshangabad' },
  { label: 'Hoshcha', value: 'Hoshcha' },
  { label: 'Hoshiarpur', value: 'Hoshiarpur' },
  { label: 'Hosingen', value: 'Hosingen' },
  { label: 'Hoskote', value: 'Hoskote' },
  { label: 'Höslwang', value: 'Höslwang' },
  { label: 'Hospet', value: 'Hospet' },
  { label: 'Hospital De Órbigo', value: 'Hospital De Órbigo' },
  { label: 'Hospital Road', value: 'Hospital Road' },
  { label: 'Hospitalet De Llobregat L', value: 'Hospitalet De Llobregat L' },
  { label: 'Hosseinabad ', value: 'Hosseinabad ' },
  { label: 'Hosszúhetény', value: 'Hosszúhetény' },
  { label: 'Hosszúpályi', value: 'Hosszúpályi' },
  { label: 'Hostalets De Pierola Els', value: 'Hostalets De Pierola Els' },
  { label: 'Hošťálková', value: 'Hošťálková' },
  { label: 'Hostalric', value: 'Hostalric' },
  { label: 'Hostavice', value: 'Hostavice' },
  { label: 'Hostěradice', value: 'Hostěradice' },
  { label: 'Hostinné', value: 'Hostinné' },
  { label: 'Hostivice', value: 'Hostivice' },
  { label: 'Hošťka', value: 'Hošťka' },
  { label: 'Hostomel', value: 'Hostomel' },
  { label: 'Hostomice', value: 'Hostomice' },
  { label: 'Hostomice', value: 'Hostomice' },
  { label: 'Hostos', value: 'Hostos' },
  { label: 'Hostotipaquillo', value: 'Hostotipaquillo' },
  { label: 'Hostouň', value: 'Hostouň' },
  { label: 'Hosur', value: 'Hosur' },
  { label: 'Hot Spring County', value: 'Hot Spring County' },
  { label: 'Hot Springs', value: 'Hot Springs' },
  { label: 'Hot Springs', value: 'Hot Springs' },
  { label: 'Hot Springs County', value: 'Hot Springs County' },
  { label: 'Hot Springs National Park', value: 'Hot Springs National Park' },
  { label: 'Hot Springs Village', value: 'Hot Springs Village' },
  { label: 'Hot Sulphur Springs', value: 'Hot Sulphur Springs' },
  { label: 'Hotaka', value: 'Hotaka' },
  { label: 'Hotan', value: 'Hotan' },
  { label: 'Hotar', value: 'Hotar' },
  { label: 'Hotarele', value: 'Hotarele' },
  { label: 'Hoteni', value: 'Hoteni' },
  { label: 'Hötensleben', value: 'Hötensleben' },
  { label: 'Hotgi', value: 'Hotgi' },
  { label: 'Hotham', value: 'Hotham' },
  { label: 'Hotham Heights', value: 'Hotham Heights' },
  { label: 'Hotinja Vas', value: 'Hotinja Vas' },
  { label: 'Hotonj', value: 'Hotonj' },
  { label: 'Hotsk', value: 'Hotsk' },
  { label: 'Hötting', value: 'Hötting' },
  { label: 'Höttingen', value: 'Höttingen' },
  { label: 'Hotton', value: 'Hotton' },
  { label: 'Houaïlou', value: 'Houaïlou' },
  { label: 'Houck', value: 'Houck' },
  { label: 'Houdain', value: 'Houdain' },
  { label: 'Houdan', value: 'Houdan' },
  { label: 'Houdemont', value: 'Houdemont' },
  { label: 'Houffalize', value: 'Houffalize' },
  { label: 'Hougang', value: 'Hougang' },
  { label: 'Hough', value: 'Hough' },
  { label: 'Houghton', value: 'Houghton' },
  { label: 'Houghton', value: 'Houghton' },
  { label: 'Houghton', value: 'Houghton' },
  { label: 'Houghton Conquest', value: 'Houghton Conquest' },
  { label: 'Houghton County', value: 'Houghton County' },
  { label: 'Houghton Lake', value: 'Houghton Lake' },
  { label: 'Houghton On The Hill', value: 'Houghton On The Hill' },
  { label: 'Houghton-Le-Spring', value: 'Houghton-Le-Spring' },
  { label: 'Houilles', value: 'Houilles' },
  { label: 'Houk Municipality', value: 'Houk Municipality' },
  { label: 'Houlbec-Cocherel', value: 'Houlbec-Cocherel' },
  { label: 'Houlgate', value: 'Houlgate' },
  { label: 'Houlton', value: 'Houlton' },
  { label: 'Houma', value: 'Houma' },
  { label: 'Houmt El Souk', value: 'Houmt El Souk' },
  { label: 'Houndé', value: 'Houndé' },
  { label: 'Hounslow', value: 'Hounslow' },
  { label: 'Houplin-Ancoisne', value: 'Houplin-Ancoisne' },
  { label: 'Houplines', value: 'Houplines' },
  { label: 'Houppeville', value: 'Houppeville' },
  { label: 'Hourtin', value: 'Hourtin' },
  { label: 'Housatonic', value: 'Housatonic' },
  { label: 'Houserville', value: 'Houserville' },
  { label: 'Houssen', value: 'Houssen' },
  { label: 'Houston', value: 'Houston' },
  { label: 'Houston', value: 'Houston' },
  { label: 'Houston', value: 'Houston' },
  { label: 'Houston', value: 'Houston' },
  { label: 'Houston', value: 'Houston' },
  { label: 'Houston', value: 'Houston' },
  { label: 'Houston', value: 'Houston' },
  { label: 'Houston County', value: 'Houston County' },
  { label: 'Houston County', value: 'Houston County' },
  { label: 'Houston County', value: 'Houston County' },
  { label: 'Houston County', value: 'Houston County' },
  { label: 'Houston County', value: 'Houston County' },
  { label: 'Houten', value: 'Houten' },
  { label: 'Houthalen', value: 'Houthalen' },
  { label: 'Houthem', value: 'Houthem' },
  { label: 'Houthulst', value: 'Houthulst' },
  { label: 'Houtskär', value: 'Houtskär' },
  { label: 'Houyet', value: 'Houyet' },
  { label: 'Houyu', value: 'Houyu' },
  { label: 'Hov', value: 'Hov' },
  { label: 'Hov', value: 'Hov' },
  { label: 'Hova', value: 'Hova' },
  { label: 'Hovd', value: 'Hovd' },
  { label: 'Hove', value: 'Hove' },
  { label: 'Hove', value: 'Hove' },
  { label: 'Hove', value: 'Hove' },
  { label: 'Hövelhof', value: 'Hövelhof' },
  { label: 'Hoveton', value: 'Hoveton' },
  { label: 'Hoveyzeh', value: 'Hoveyzeh' },
  { label: 'Hovězí', value: 'Hovězí' },
  { label: 'Höviksnäs', value: 'Höviksnäs' },
  { label: 'Hovmantorp', value: 'Hovmantorp' },
  { label: 'Hovorany', value: 'Hovorany' },
  { label: 'Hovorčovice', value: 'Hovorčovice' },
  { label: 'Hovrila', value: 'Hovrila' },
  { label: 'Hövsan', value: 'Hövsan' },
  { label: 'Hovsta', value: 'Hovsta' },
  { label: 'Hovtamej', value: 'Hovtamej' },
  { label: 'Hovtashat', value: 'Hovtashat' },
  { label: 'Hovtashen', value: 'Hovtashen' },
  { label: 'Howard', value: 'Howard' },
  { label: 'Howard', value: 'Howard' },
  { label: 'Howard', value: 'Howard' },
  { label: 'Howard', value: 'Howard' },
  { label: 'Howard', value: 'Howard' },
  { label: 'Howard Beach', value: 'Howard Beach' },
  { label: 'Howard City', value: 'Howard City' },
  { label: 'Howard County', value: 'Howard County' },
  { label: 'Howard County', value: 'Howard County' },
  { label: 'Howard County', value: 'Howard County' },
  { label: 'Howard County', value: 'Howard County' },
  { label: 'Howard County', value: 'Howard County' },
  { label: 'Howard County', value: 'Howard County' },
  { label: 'Howard County', value: 'Howard County' },
  { label: 'Howard Lake', value: 'Howard Lake' },
  { label: 'Howard Springs', value: 'Howard Springs' },
  { label: 'Howards Grove', value: 'Howards Grove' },
  { label: 'Howden', value: 'Howden' },
  { label: 'Howe', value: 'Howe' },
  { label: 'Howell', value: 'Howell' },
  { label: 'Howell County', value: 'Howell County' },
  { label: 'Howey-In-The-Hills', value: 'Howey-In-The-Hills' },
  { label: 'Howick', value: 'Howick' },
  { label: 'Howland', value: 'Howland' },
  { label: 'Howland Center', value: 'Howland Center' },
  { label: 'Howli', value: 'Howli' },
  { label: 'Howlong', value: 'Howlong' },
  { label: 'Howrah', value: 'Howrah' },
  { label: 'Howrah', value: 'Howrah' },
  { label: 'Howth', value: 'Howth' },
  { label: 'Howwood', value: 'Howwood' },
  { label: 'Hoxie', value: 'Hoxie' },
  { label: 'Hoxie', value: 'Hoxie' },
  { label: 'Höxter', value: 'Höxter' },
  { label: 'Hoxtolgay', value: 'Hoxtolgay' },
  { label: 'Hoxton Park', value: 'Hoxton Park' },
  { label: 'Hoya', value: 'Hoya' },
  { label: 'Hoya De Cintora (La Hoya De Arriba)', value: 'Hoya De Cintora (La Hoya De Arriba)' },
  { label: 'Hoya La', value: 'Hoya La' },
  { label: 'Hoya-Gonzalo', value: 'Hoya-Gonzalo' },
  { label: 'Hoyales De Roa', value: 'Hoyales De Roa' },
  { label: 'Høyanger', value: 'Høyanger' },
  { label: 'Hoyerswerda', value: 'Hoyerswerda' },
  { label: 'Hoylake', value: 'Hoylake' },
  { label: 'Hoyland Nether', value: 'Hoyland Nether' },
  { label: 'Høylandet', value: 'Høylandet' },
  { label: 'Hoym', value: 'Hoym' },
  { label: 'Hoymille', value: 'Hoymille' },
  { label: 'Hoyo De Epuyén', value: 'Hoyo De Epuyén' },
  { label: 'Hoyo De Manzanares', value: 'Hoyo De Manzanares' },
  { label: 'Hoyo De Pinares El', value: 'Hoyo De Pinares El' },
  { label: 'Hoyocasero', value: 'Hoyocasero' },
  { label: 'Hoyorredondo', value: 'Hoyorredondo' },
  { label: 'Hoyos', value: 'Hoyos' },
  { label: 'Hoyos De Miguel Muñoz', value: 'Hoyos De Miguel Muñoz' },
  { label: 'Hoyos Del Collado', value: 'Hoyos Del Collado' },
  { label: 'Hoyos Del Espino', value: 'Hoyos Del Espino' },
  { label: 'Hoyt Lakes', value: 'Hoyt Lakes' },
  { label: 'Hoz De Jaca', value: 'Hoz De Jaca' },
  { label: 'Hoz De La Vieja La', value: 'Hoz De La Vieja La' },
  { label: 'Hoz Y Costean', value: 'Hoz Y Costean' },
  { label: 'Hozat', value: 'Hozat' },
  { label: 'Hpa-An', value: 'Hpa-An' },
  { label: 'Hrabyně', value: 'Hrabyně' },
  { label: 'Hradec Králové', value: 'Hradec Králové' },
  { label: 'Hradec Nad Moravici', value: 'Hradec Nad Moravici' },
  { label: 'Hradec Nad Svitavou', value: 'Hradec Nad Svitavou' },
  { label: 'Hrádek', value: 'Hrádek' },
  { label: 'Hrádek', value: 'Hrádek' },
  { label: 'Hrádek Nad Nisou', value: 'Hrádek Nad Nisou' },
  { label: 'Hradištko', value: 'Hradištko' },
  { label: 'Hradyzk', value: 'Hradyzk' },
  { label: 'Hranice', value: 'Hranice' },
  { label: 'Hranice', value: 'Hranice' },
  { label: 'Hranitne', value: 'Hranitne' },
  { label: 'Hrašćica', value: 'Hrašćica' },
  { label: 'Hrastje', value: 'Hrastje' },
  { label: 'Hrastnik', value: 'Hrastnik' },
  { label: 'Hrazdan', value: 'Hrazdan' },
  { label: 'Hrdějovice', value: 'Hrdějovice' },
  { label: 'Hřebeč', value: 'Hřebeč' },
  { label: 'Hrebinka', value: 'Hrebinka' },
  { label: 'Hreljin', value: 'Hreljin' },
  { label: 'Hrib-Loški Potok', value: 'Hrib-Loški Potok' },
  { label: 'Hriňová', value: 'Hriňová' },
  { label: 'Hrob', value: 'Hrob' },
  { label: 'Hrochoťslovakia', value: 'Hrochoťslovakia' },
  { label: 'Hrochův Týnec', value: 'Hrochův Týnec' },
  { label: 'Hrodna', value: 'Hrodna' },
  { label: 'Hromnice', value: 'Hromnice' },
  { label: 'Hronov', value: 'Hronov' },
  { label: 'Hrotovice', value: 'Hrotovice' },
  { label: 'Hroznětín', value: 'Hroznětín' },
  { label: 'Hroznová Lhota', value: 'Hroznová Lhota' },
  { label: 'Hrtkovci', value: 'Hrtkovci' },
  { label: 'Hrubieszów', value: 'Hrubieszów' },
  { label: 'Hrunamannahreppur', value: 'Hrunamannahreppur' },
  { label: 'Hrušica', value: 'Hrušica' },
  { label: 'Hrušky', value: 'Hrušky' },
  { label: 'Hrušovany Nad Jevišovkou', value: 'Hrušovany Nad Jevišovkou' },
  { label: 'Hrušovany U Brna', value: 'Hrušovany U Brna' },
  { label: 'Hrvace', value: 'Hrvace' },
  { label: 'Hrvatini', value: 'Hrvatini' },
  { label: 'Hrvatska Kostajnica', value: 'Hrvatska Kostajnica' },
  { label: 'Hryhoriopol', value: 'Hryhoriopol' },
  { label: 'Hrymayliv', value: 'Hrymayliv' },
  { label: 'Hryshkivtsi', value: 'Hryshkivtsi' },
  { label: 'Hryshkivtsi', value: 'Hryshkivtsi' },
  { label: 'Hsinchu', value: 'Hsinchu' },
  { label: 'Hsinchu County', value: 'Hsinchu County' },
  { label: 'Hua Hin', value: 'Hua Hin' },
  { label: 'Huacapa Y Amestoy', value: 'Huacapa Y Amestoy' },
  { label: 'Huacaybamba', value: 'Huacaybamba' },
  { label: 'Huachichil', value: 'Huachichil' },
  { label: 'Huachinantla', value: 'Huachinantla' },
  { label: 'Huachinera', value: 'Huachinera' },
  { label: 'Huacho', value: 'Huacho' },
  { label: 'Huachuca City', value: 'Huachuca City' },
  { label: 'Huadian', value: 'Huadian' },
  { label: 'Huahuaxtla', value: 'Huahuaxtla' },
  { label: 'Huai Khot', value: 'Huai Khot' },
  { label: 'Huai Khwang', value: 'Huai Khwang' },
  { label: 'Huai Mek', value: 'Huai Mek' },
  { label: 'Huai Yot', value: 'Huai Yot' },
  { label: 'Huai’An', value: 'Huai’An' },
  { label: 'Huai’An Shi', value: 'Huai’An Shi' },
  { label: 'Huaian', value: 'Huaian' },
  { label: 'Huaian', value: 'Huaian' },
  { label: 'Huaibei', value: 'Huaibei' },
  { label: 'Huaicheng', value: 'Huaicheng' },
  { label: 'Huaidian', value: 'Huaidian' },
  { label: 'Huaihua', value: 'Huaihua' },
  { label: 'Huainan', value: 'Huainan' },
  { label: 'Huainan Shi', value: 'Huainan Shi' },
  { label: 'Huaiyuan Chengguanzhen', value: 'Huaiyuan Chengguanzhen' },
  { label: 'Huajacatlán', value: 'Huajacatlán' },
  { label: 'Huajicori', value: 'Huajicori' },
  { label: 'Huajimic', value: 'Huajimic' },
  { label: 'Huajintepec', value: 'Huajintepec' },
  { label: 'Huajintlán', value: 'Huajintlán' },
  { label: 'Huajoyuca', value: 'Huajoyuca' },
  { label: 'Huajúmbaro', value: 'Huajúmbaro' },
  { label: 'Hualahuises', value: 'Hualahuises' },
  { label: 'Hualaihué', value: 'Hualaihué' },
  { label: 'Hualañé', value: 'Hualañé' },
  { label: 'Hualcaltzinco', value: 'Hualcaltzinco' },
  { label: 'Hualfín', value: 'Hualfín' },
  { label: 'Hualien', value: 'Hualien' },
  { label: 'Hualien City', value: 'Hualien City' },
  { label: 'Hualmay', value: 'Hualmay' },
  { label: 'Hualpén', value: 'Hualpén' },
  { label: 'Hualqui', value: 'Hualqui' },
  { label: 'Hualula', value: 'Hualula' },
  { label: 'Huamachuco', value: 'Huamachuco' },
  { label: 'Huamantla', value: 'Huamantla' },
  { label: 'Huambo', value: 'Huambo' },
  { label: 'Huamuchapa', value: 'Huamuchapa' },
  { label: 'Huamuxtitlán', value: 'Huamuxtitlán' },
  { label: 'Huanan', value: 'Huanan' },
  { label: 'Huancabamba', value: 'Huancabamba' },
  { label: 'Huancavelica', value: 'Huancavelica' },
  { label: 'Huancayo', value: 'Huancayo' },
  { label: 'Huanchillas', value: 'Huanchillas' },
  { label: 'Huancito', value: 'Huancito' },
  { label: 'Huandacareo', value: 'Huandacareo' },
  { label: 'Huangcaotuo', value: 'Huangcaotuo' },
  { label: 'Huanggang', value: 'Huanggang' },
  { label: 'Huanggang', value: 'Huanggang' },
  { label: 'Huangjinjing', value: 'Huangjinjing' },
  { label: 'Huanglong', value: 'Huanglong' },
  { label: 'Huangmaoyuan', value: 'Huangmaoyuan' },
  { label: 'Huangmei', value: 'Huangmei' },
  { label: 'Huangnan Zangzu Zizhizhou', value: 'Huangnan Zangzu Zizhizhou' },
  { label: 'Huangnihe', value: 'Huangnihe' },
  { label: 'Huangpi', value: 'Huangpi' },
  { label: 'Huangqiao', value: 'Huangqiao' },
  { label: 'Huangshan', value: 'Huangshan' },
  { label: 'Huangshan Shi', value: 'Huangshan Shi' },
  { label: 'Huangshi', value: 'Huangshi' },
  { label: 'Huangtian', value: 'Huangtian' },
  { label: 'Huangtukuang', value: 'Huangtukuang' },
  { label: 'Huanguitío', value: 'Huanguitío' },
  { label: 'Huangxikou', value: 'Huangxikou' },
  { label: 'Huangyan', value: 'Huangyan' },
  { label: 'Huangzhou', value: 'Huangzhou' },
  { label: 'Huanímaro', value: 'Huanímaro' },
  { label: 'Huaniqueo De Morales', value: 'Huaniqueo De Morales' },
  { label: 'Huanren', value: 'Huanren' },
  { label: 'Huanta', value: 'Huanta' },
  { label: 'Huantuo', value: 'Huantuo' },
  { label: 'Huánuco', value: 'Huánuco' },
  { label: 'Huanuni', value: 'Huanuni' },
  { label: 'Huapinol', value: 'Huapinol' },
  { label: 'Huaqiao', value: 'Huaqiao' },
  { label: 'Huaqiu', value: 'Huaqiu' },
  { label: 'Huaquechula', value: 'Huaquechula' },
  { label: 'Huaquillas', value: 'Huaquillas' },
  { label: 'Huara', value: 'Huara' },
  { label: 'Huaral', value: 'Huaral' },
  { label: 'Huarancante', value: 'Huarancante' },
  { label: 'Huaraz', value: 'Huaraz' },
  { label: 'Huarichancara', value: 'Huarichancara' },
  { label: 'Huarina', value: 'Huarina' },
  { label: 'Huarmey', value: 'Huarmey' },
  { label: 'Huarte/Uharte', value: 'Huarte/Uharte' },
  { label: 'Huarte-Uharte', value: 'Huarte-Uharte' },
  { label: 'Huásabas', value: 'Huásabas' },
  { label: 'Huasahuasi', value: 'Huasahuasi' },
  { label: 'Huasca De Ocampo', value: 'Huasca De Ocampo' },
  { label: 'Huascato', value: 'Huascato' },
  { label: 'Huasco', value: 'Huasco' },
  { label: 'Huatabampo', value: 'Huatabampo' },
  { label: 'Huatajata', value: 'Huatajata' },
  { label: 'Huatecalco', value: 'Huatecalco' },
  { label: 'Huatlatlauca', value: 'Huatlatlauca' },
  { label: 'Huatusco', value: 'Huatusco' },
  { label: 'Huauchinango', value: 'Huauchinango' },
  { label: 'Huaura', value: 'Huaura' },
  { label: 'Huautepec', value: 'Huautepec' },
  { label: 'Huautla', value: 'Huautla' },
  { label: 'Huautla De Jiménez', value: 'Huautla De Jiménez' },
  { label: 'Huaxcaleca', value: 'Huaxcaleca' },
  { label: 'Huaxtla', value: 'Huaxtla' },
  { label: 'Huay Max', value: 'Huay Max' },
  { label: 'Huayacanes', value: 'Huayacanes' },
  { label: 'Huayacocotla', value: 'Huayacocotla' },
  { label: 'Huayin', value: 'Huayin' },
  { label: 'Huayna Alcalde', value: 'Huayna Alcalde' },
  { label: 'Huay-Pix', value: 'Huay-Pix' },
  { label: 'Huaytara', value: 'Huaytara' },
  { label: 'Huayuan', value: 'Huayuan' },
  { label: 'Huayucachi', value: 'Huayucachi' },
  { label: 'Huazantlán Del Río', value: 'Huazantlán Del Río' },
  { label: 'Huazhou', value: 'Huazhou' },
  { label: 'Huazhou', value: 'Huazhou' },
  { label: 'Huazulco', value: 'Huazulco' },
  { label: 'Huazuntlán', value: 'Huazuntlán' },
  { label: 'Hubaysh', value: 'Hubaysh' },
  { label: 'Hubballi', value: 'Hubballi' },
  { label: 'Hubbard', value: 'Hubbard' },
  { label: 'Hubbard', value: 'Hubbard' },
  { label: 'Hubbard', value: 'Hubbard' },
  { label: 'Hubbard County', value: 'Hubbard County' },
  { label: 'Hubbard Lake', value: 'Hubbard Lake' },
  { label: 'Hubbardston', value: 'Hubbardston' },
  { label: 'Huber Heights', value: 'Huber Heights' },
  { label: 'Huber Ridge', value: 'Huber Ridge' },
  { label: 'Hubynykha', value: 'Hubynykha' },
  { label: 'Hückelhoven', value: 'Hückelhoven' },
  { label: 'Hückeswagen', value: 'Hückeswagen' },
  { label: 'Hucknall', value: 'Hucknall' },
  { label: 'Hudaraj', value: 'Hudaraj' },
  { label: 'Huddersfield', value: 'Huddersfield' },
  { label: 'Huddinge', value: 'Huddinge' },
  { label: 'Hude', value: 'Hude' },
  { label: 'Hudeşti', value: 'Hudeşti' },
  { label: 'Hudiksvall', value: 'Hudiksvall' },
  { label: 'Hudlice', value: 'Hudlice' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson', value: 'Hudson' },
  { label: 'Hudson Bay', value: 'Hudson Bay' },
  { label: 'Hudson Bend', value: 'Hudson Bend' },
  { label: 'Hudson County', value: 'Hudson County' },
  { label: 'Hudson Falls', value: 'Hudson Falls' },
  { label: 'Hudson Lake', value: 'Hudson Lake' },
  { label: 'Hudson Oaks', value: 'Hudson Oaks' },
  { label: 'Hudsonville', value: 'Hudsonville' },
  { label: 'Hudspeth County', value: 'Hudspeth County' },
  { label: 'Huế', value: 'Huế' },
  { label: 'Huecahuasco', value: 'Huecahuasco' },
  { label: 'Huecas', value: 'Huecas' },
  { label: 'Huechuraba', value: 'Huechuraba' },
  { label: 'Huécija', value: 'Huécija' },
  { label: 'Huede', value: 'Huede' },
  { label: 'Huedin', value: 'Huedin' },
  { label: 'Huehuetán', value: 'Huehuetán' },
  { label: 'Huehuetán', value: 'Huehuetán' },
  { label: 'Huehuetenango', value: 'Huehuetenango' },
  { label: 'Huehuetla', value: 'Huehuetla' },
  { label: 'Huehuetla', value: 'Huehuetla' },
  { label: 'Huehuetlan El Chico', value: 'Huehuetlan El Chico' },
  { label: 'Huehuetlán El Grande', value: 'Huehuetlán El Grande' },
  { label: 'Huehuetoca', value: 'Huehuetoca' },
  { label: 'Huejonapan', value: 'Huejonapan' },
  { label: 'Huejotitán', value: 'Huejotitán' },
  { label: 'Huejotitán', value: 'Huejotitán' },
  { label: 'Huejotitlán', value: 'Huejotitlán' },
  { label: 'Huejotzingo', value: 'Huejotzingo' },
  { label: 'Huejúcar', value: 'Huejúcar' },
  { label: 'Huejuquilla El Alto Municipality', value: 'Huejuquilla El Alto Municipality' },
  { label: 'Huejutla De Reyes', value: 'Huejutla De Reyes' },
  { label: 'Huélaga', value: 'Huélaga' },
  { label: 'Huélago', value: 'Huélago' },
  { label: 'Huélamo', value: 'Huélamo' },
  { label: 'Huelgoat', value: 'Huelgoat' },
  { label: 'Huelma', value: 'Huelma' },
  { label: 'Huelva', value: 'Huelva' },
  { label: 'Huelves', value: 'Huelves' },
  { label: 'Huemetla', value: 'Huemetla' },
  { label: 'Huéneja', value: 'Huéneja' },
  { label: 'Huépac', value: 'Huépac' },
  { label: 'Huepaco', value: 'Huepaco' },
  { label: 'Huepalcalco (San Miguel)', value: 'Huepalcalco (San Miguel)' },
  { label: 'Huércal De Almería', value: 'Huércal De Almería' },
  { label: 'Huércal-Overa', value: 'Huércal-Overa' },
  { label: 'Huércanos', value: 'Huércanos' },
  { label: 'Huerce La', value: 'Huerce La' },
  { label: 'Huerfano County', value: 'Huerfano County' },
  { label: 'Huérguina', value: 'Huérguina' },
  { label: 'Huérmeces', value: 'Huérmeces' },
  { label: 'Huérmeces Del Cerro', value: 'Huérmeces Del Cerro' },
  { label: 'Huerta', value: 'Huerta' },
  { label: 'Huerta De Arriba', value: 'Huerta De Arriba' },
  { label: 'Huerta De La Obispalía', value: 'Huerta De La Obispalía' },
  { label: 'Huerta De Rey', value: 'Huerta De Rey' },
  { label: 'Huerta De Valdecarábanos', value: 'Huerta De Valdecarábanos' },
  { label: 'Huerta Del Marquesado', value: 'Huerta Del Marquesado' },
  { label: 'Huerta Del Rey', value: 'Huerta Del Rey' },
  { label: 'Huerta Grande', value: 'Huerta Grande' },
  { label: 'Huertahernando', value: 'Huertahernando' },
  { label: 'Huertas Estación', value: 'Huertas Estación' },
  { label: 'Huertas Familiares San Pedro', value: 'Huertas Familiares San Pedro' },
  { label: 'Huerto', value: 'Huerto' },
  { label: 'Huertos Los', value: 'Huertos Los' },
  { label: 'Huesa', value: 'Huesa' },
  { label: 'Huesa Del Común', value: 'Huesa Del Común' },
  { label: 'Huesca', value: 'Huesca' },
  { label: 'Huéscar', value: 'Huéscar' },
  { label: 'Huetamo De Núñez', value: 'Huetamo De Núñez' },
  { label: 'Huete', value: 'Huete' },
  { label: 'Huétor De Santillán', value: 'Huétor De Santillán' },
  { label: 'Huétor Tájar', value: 'Huétor Tájar' },
  { label: 'Huétor Vega', value: 'Huétor Vega' },
  { label: 'Hueva', value: 'Hueva' },
  { label: 'Huévar Del Aljarafe', value: 'Huévar Del Aljarafe' },
  { label: 'Huexotitla', value: 'Huexotitla' },
  { label: 'Hueyapa', value: 'Hueyapa' },
  { label: 'Hueyapan', value: 'Hueyapan' },
  { label: 'Hueyapan', value: 'Hueyapan' },
  { label: 'Hueyapan De Ocampo', value: 'Hueyapan De Ocampo' },
  { label: 'Hueycantenango', value: 'Hueycantenango' },
  { label: 'Hueyitlalpan', value: 'Hueyitlalpan' },
  { label: 'Hueyotlipan', value: 'Hueyotlipan' },
  { label: 'Hueyotlipan', value: 'Hueyotlipan' },
  { label: 'Hueypoxtla', value: 'Hueypoxtla' },
  { label: 'Hueytamalco', value: 'Hueytamalco' },
  { label: 'Hueytepec', value: 'Hueytepec' },
  { label: 'Hueytlalpan', value: 'Hueytlalpan' },
  { label: 'Hueytown', value: 'Hueytown' },
  { label: 'Huez', value: 'Huez' },
  { label: 'Hufash', value: 'Hufash' },
  { label: 'Hüffelsheim', value: 'Hüffelsheim' },
  { label: 'Hüffenhardt', value: 'Hüffenhardt' },
  { label: 'Hüfingen', value: 'Hüfingen' },
  { label: 'Hügelsheim', value: 'Hügelsheim' },
  { label: 'Hugh Town', value: 'Hugh Town' },
  { label: 'Hughenden', value: 'Hughenden' },
  { label: 'Hughenden', value: 'Hughenden' },
  { label: 'Hughes', value: 'Hughes' },
  { label: 'Hughes', value: 'Hughes' },
  { label: 'Hughes County', value: 'Hughes County' },
  { label: 'Hughes County', value: 'Hughes County' },
  { label: 'Hughes Springs', value: 'Hughes Springs' },
  { label: 'Hughesdale', value: 'Hughesdale' },
  { label: 'Hughestown', value: 'Hughestown' },
  { label: 'Hughesville', value: 'Hughesville' },
  { label: 'Hughesville', value: 'Hughesville' },
  { label: 'Hughson', value: 'Hughson' },
  { label: 'Huglfing', value: 'Huglfing' },
  { label: 'Hugo', value: 'Hugo' },
  { label: 'Hugo', value: 'Hugo' },
  { label: 'Hugo', value: 'Hugo' },
  { label: 'Hugo Napoleão', value: 'Hugo Napoleão' },
  { label: 'Hugoton', value: 'Hugoton' },
  { label: 'Huguenot', value: 'Huguenot' },
  { label: 'Huguley', value: 'Huguley' },
  { label: 'Huhi', value: 'Huhi' },
  { label: 'Huichang', value: 'Huichang' },
  { label: 'Huichapan', value: 'Huichapan' },
  { label: 'Huicheng', value: 'Huicheng' },
  { label: 'Huichihuayan', value: 'Huichihuayan' },
  { label: 'Huicungo', value: 'Huicungo' },
  { label: 'Huigsloot', value: 'Huigsloot' },
  { label: 'Huijbergen', value: 'Huijbergen' },
  { label: 'Huilacapixtla', value: 'Huilacapixtla' },
  { label: 'Huilango', value: 'Huilango' },
  { label: 'Huillapima', value: 'Huillapima' },
  { label: 'Huiloapan', value: 'Huiloapan' },
  { label: 'Huiloapan', value: 'Huiloapan' },
  { label: 'Huiloapan De Cuauhtémoc', value: 'Huiloapan De Cuauhtémoc' },
  { label: 'Huilong', value: 'Huilong' },
  { label: 'Huimanguillo', value: 'Huimanguillo' },
  { label: 'Huimilpan', value: 'Huimilpan' },
  { label: 'Huinan', value: 'Huinan' },
  { label: 'Huinca Renancó', value: 'Huinca Renancó' },
  { label: 'Huiramba', value: 'Huiramba' },
  { label: 'Huisduinen', value: 'Huisduinen' },
  { label: 'Huisheim', value: 'Huisheim' },
  { label: 'Huisichi', value: 'Huisichi' },
  { label: 'Huismes', value: 'Huismes' },
  { label: 'Huisseau-Sur-Cosson', value: 'Huisseau-Sur-Cosson' },
  { label: 'Huisseau-Sur-Mauves', value: 'Huisseau-Sur-Mauves' },
  { label: 'Huitán', value: 'Huitán' },
  { label: 'Huitchila', value: 'Huitchila' },
  { label: 'Huitel', value: 'Huitel' },
  { label: 'Huitepec', value: 'Huitepec' },
  { label: 'Huitiupán', value: 'Huitiupán' },
  { label: 'Huitrón', value: 'Huitrón' },
  { label: 'Huittinen', value: 'Huittinen' },
  { label: 'Huitzatarito', value: 'Huitzatarito' },
  { label: 'Huitzila', value: 'Huitzila' },
  { label: 'Huitzila', value: 'Huitzila' },
  { label: 'Huitzilac', value: 'Huitzilac' },
  { label: 'Huitzilac', value: 'Huitzilac' },
  { label: 'Huitzilan', value: 'Huitzilan' },
  { label: 'Huitzililla', value: 'Huitzililla' },
  { label: 'Huitziltepec', value: 'Huitziltepec' },
  { label: 'Huitziltepec', value: 'Huitziltepec' },
  { label: 'Huitzitzilingo', value: 'Huitzitzilingo' },
  { label: 'Huitzmaloc', value: 'Huitzmaloc' },
  { label: 'Huitzoltepec', value: 'Huitzoltepec' },
  { label: 'Huitzometl', value: 'Huitzometl' },
  { label: 'Huitzuco De Los Figueroa', value: 'Huitzuco De Los Figueroa' },
  { label: 'Huixcolotla', value: 'Huixcolotla' },
  { label: 'Huixcolotla', value: 'Huixcolotla' },
  { label: 'Huixquilucan', value: 'Huixquilucan' },
  { label: 'Huixquilucan De Degollado', value: 'Huixquilucan De Degollado' },
  { label: 'Huixtepec', value: 'Huixtepec' },
  { label: 'Huixtla', value: 'Huixtla' },
  { label: 'Huizachal', value: 'Huizachal' },
  { label: 'Huizaches', value: 'Huizaches' },
  { label: 'Huizen', value: 'Huizen' },
  { label: 'Huizhou', value: 'Huizhou' },
  { label: 'Huizum', value: 'Huizum' },
  { label: 'Hujra Shah Muqeem', value: 'Hujra Shah Muqeem' },
  { label: 'Hukay', value: 'Hukay' },
  { label: 'Hukeri', value: 'Hukeri' },
  { label: 'Hŭkkyo-Ri', value: 'Hŭkkyo-Ri' },
  { label: 'Ḩukūmatī Azrah', value: 'Ḩukūmatī Azrah' },
  { label: 'Ḩukūmatī Dahanah-Ye Ghōrī', value: 'Ḩukūmatī Dahanah-Ye Ghōrī' },
  { label: 'Hukuntsi', value: 'Hukuntsi' },
  { label: 'Hukvaldy', value: 'Hukvaldy' },
  { label: 'Hulan', value: 'Hulan' },
  { label: 'Hulan Ergi', value: 'Hulan Ergi' },
  { label: 'Hulas', value: 'Hulas' },
  { label: 'Hülben', value: 'Hülben' },
  { label: 'Huldenberg', value: 'Huldenberg' },
  { label: 'Hulha Negra', value: 'Hulha Negra' },
  { label: 'Hulhumale', value: 'Hulhumale' },
  { label: 'Hulín', value: 'Hulín' },
  { label: 'Hull', value: 'Hull' },
  { label: 'Hull', value: 'Hull' },
  { label: 'Hüllhorst', value: 'Hüllhorst' },
  { label: 'Hullo', value: 'Hullo' },
  { label: 'Hulluch', value: 'Hulluch' },
  { label: 'Hulme', value: 'Hulme' },
  { label: 'Hulsberg', value: 'Hulsberg' },
  { label: 'Hülsede', value: 'Hülsede' },
  { label: 'Hulshout', value: 'Hulshout' },
  { label: 'Hulst', value: 'Hulst' },
  { label: 'Hultsfred', value: 'Hultsfred' },
  { label: 'Hulubeşti', value: 'Hulubeşti' },
  { label: 'Hulubești', value: 'Hulubești' },
  { label: 'Huludao', value: 'Huludao' },
  { label: 'Huludao Shi', value: 'Huludao Shi' },
  { label: 'Hulunbuir Region', value: 'Hulunbuir Region' },
  { label: 'Hulyaypole', value: 'Hulyaypole' },
  { label: 'Humacao', value: 'Humacao' },
  { label: 'Humacao', value: 'Humacao' },
  { label: 'Humada', value: 'Humada' },
  { label: 'Humahuaca', value: 'Humahuaca' },
  { label: 'Humaitá', value: 'Humaitá' },
  { label: 'Humaitá', value: 'Humaitá' },
  { label: 'Humanes', value: 'Humanes' },
  { label: 'Humanes De Madrid', value: 'Humanes De Madrid' },
  { label: 'Humansville', value: 'Humansville' },
  { label: 'Humberston', value: 'Humberston' },
  { label: 'Humberto De Campos', value: 'Humberto De Campos' },
  { label: 'Humble', value: 'Humble' },
  { label: 'Humboldt', value: 'Humboldt' },
  { label: 'Humboldt', value: 'Humboldt' },
  { label: 'Humboldt', value: 'Humboldt' },
  { label: 'Humboldt', value: 'Humboldt' },
  { label: 'Humboldt County', value: 'Humboldt County' },
  { label: 'Humboldt County', value: 'Humboldt County' },
  { label: 'Humboldt County', value: 'Humboldt County' },
  { label: 'Humboldt Hill', value: 'Humboldt Hill' },
  { label: 'Humboldtkolonie', value: 'Humboldtkolonie' },
  { label: 'Hume', value: 'Hume' },
  { label: 'Humele', value: 'Humele' },
  { label: 'Humen', value: 'Humen' },
  { label: 'Humenné', value: 'Humenné' },
  { label: 'Humilladero', value: 'Humilladero' },
  { label: 'Humla ', value: 'Humla ' },
  { label: 'Humlebæk', value: 'Humlebæk' },
  { label: 'Hummelo', value: 'Hummelo' },
  { label: 'Hummels Wharf', value: 'Hummels Wharf' },
  { label: 'Hummelsbüttel', value: 'Hummelsbüttel' },
  { label: 'Hummelstown', value: 'Hummelstown' },
  { label: 'Humniska', value: 'Humniska' },
  { label: 'Humoreni', value: 'Humoreni' },
  { label: 'Humpata', value: 'Humpata' },
  { label: 'Humphreys County', value: 'Humphreys County' },
  { label: 'Humphreys County', value: 'Humphreys County' },
  { label: 'Humpolec', value: 'Humpolec' },
  { label: 'Humppila', value: 'Humppila' },
  { label: 'Humpty Doo', value: 'Humpty Doo' },
  { label: 'Humulești', value: 'Humulești' },
  { label: 'Humuya', value: 'Humuya' },
  { label: 'Hūn', value: 'Hūn' },
  { label: 'Húnaþing Vestra', value: 'Húnaþing Vestra' },
  { label: 'Hunchun', value: 'Hunchun' },
  { label: 'Hunderdorf', value: 'Hunderdorf' },
  { label: 'Hundeshagen', value: 'Hundeshagen' },
  { label: 'Hundested', value: 'Hundested' },
  { label: 'Hunding', value: 'Hunding' },
  { label: 'Hundleton', value: 'Hundleton' },
  { label: 'Hundling', value: 'Hundling' },
  { label: 'Hundorp', value: 'Hundorp' },
  { label: 'Hundsangen', value: 'Hundsangen' },
  { label: 'Hundsheim', value: 'Hundsheim' },
  { label: 'Hunedoara', value: 'Hunedoara' },
  { label: 'Hünenberg', value: 'Hünenberg' },
  { label: 'Hünfeld', value: 'Hünfeld' },
  { label: 'Hưng Yên', value: 'Hưng Yên' },
  { label: 'Hungduan', value: 'Hungduan' },
  { label: 'Hungen', value: 'Hungen' },
  { label: 'Hungerford', value: 'Hungerford' },
  { label: 'Hŭngnam', value: 'Hŭngnam' },
  { label: 'Hungund', value: 'Hungund' },
  { label: 'Hunia', value: 'Hunia' },
  { label: 'Huningue', value: 'Huningue' },
  { label: 'Hunkuyi', value: 'Hunkuyi' },
  { label: 'Hunmanby', value: 'Hunmanby' },
  { label: 'Hunnebostrand', value: 'Hunnebostrand' },
  { label: 'Hunnerberg', value: 'Hunnerberg' },
  { label: 'Hunstanton', value: 'Hunstanton' },
  { label: 'Hunsur', value: 'Hunsur' },
  { label: 'Hunt County', value: 'Hunt County' },
  { label: 'Hunt Valley', value: 'Hunt Valley' },
  { label: 'Hunter', value: 'Hunter' },
  { label: 'Hunter', value: 'Hunter' },
  { label: 'Hunterdon County', value: 'Hunterdon County' },
  { label: 'Hunters Creek', value: 'Hunters Creek' },
  { label: 'Hunters Creek Village', value: 'Hunters Creek Village' },
  { label: 'Hunters Hill', value: 'Hunters Hill' },
  { label: 'Huntersville', value: 'Huntersville' },
  { label: 'Huntertown', value: 'Huntertown' },
  { label: 'Hunterview', value: 'Hunterview' },
  { label: 'Huntfield Heights', value: 'Huntfield Heights' },
  { label: 'Huntingburg', value: 'Huntingburg' },
  { label: 'Huntingdale', value: 'Huntingdale' },
  { label: 'Huntingdale', value: 'Huntingdale' },
  { label: 'Huntingdon', value: 'Huntingdon' },
  { label: 'Huntingdon', value: 'Huntingdon' },
  { label: 'Huntingdon', value: 'Huntingdon' },
  { label: 'Huntingdon', value: 'Huntingdon' },
  { label: 'Huntingdon County', value: 'Huntingdon County' },
  { label: 'Huntington', value: 'Huntington' },
  { label: 'Huntington', value: 'Huntington' },
  { label: 'Huntington', value: 'Huntington' },
  { label: 'Huntington', value: 'Huntington' },
  { label: 'Huntington', value: 'Huntington' },
  { label: 'Huntington', value: 'Huntington' },
  { label: 'Huntington Bay', value: 'Huntington Bay' },
  { label: 'Huntington Beach', value: 'Huntington Beach' },
  { label: 'Huntington County', value: 'Huntington County' },
  { label: 'Huntington Park', value: 'Huntington Park' },
  { label: 'Huntington Station', value: 'Huntington Station' },
  { label: 'Huntington Woods', value: 'Huntington Woods' },
  { label: 'Huntingtown', value: 'Huntingtown' },
  { label: 'Huntingtown Town Center', value: 'Huntingtown Town Center' },
  { label: 'Huntingwood', value: 'Huntingwood' },
  { label: 'Huntley', value: 'Huntley' },
  { label: 'Huntley', value: 'Huntley' },
  { label: 'Huntley', value: 'Huntley' },
  { label: 'Huntly', value: 'Huntly' },
  { label: 'Huntly', value: 'Huntly' },
  { label: 'Hunts Point', value: 'Hunts Point' },
  { label: 'Huntspill', value: 'Huntspill' },
  { label: 'Huntsville', value: 'Huntsville' },
  { label: 'Huntsville', value: 'Huntsville' },
  { label: 'Huntsville', value: 'Huntsville' },
  { label: 'Huntsville', value: 'Huntsville' },
  { label: 'Huntsville', value: 'Huntsville' },
  { label: 'Huntsville', value: 'Huntsville' },
  { label: 'Hunucmá', value: 'Hunucmá' },
  { label: 'Hunuku', value: 'Hunuku' },
  { label: 'Hunwick', value: 'Hunwick' },
  { label: 'Hünxe', value: 'Hünxe' },
  { label: 'Huocheng', value: 'Huocheng' },
  { label: 'Huogezhuang', value: 'Huogezhuang' },
  { label: 'Huomachong', value: 'Huomachong' },
  { label: 'Huon Gulf', value: 'Huon Gulf' },
  { label: 'Huon Valley', value: 'Huon Valley' },
  { label: 'Huonville', value: 'Huonville' },
  { label: 'Huoqiu Chengguanzhen', value: 'Huoqiu Chengguanzhen' },
  { label: 'Huotong', value: 'Huotong' },
  { label: 'Hupalivka', value: 'Hupalivka' },
  { label: 'Huraidhah', value: 'Huraidhah' },
  { label: 'Hurand', value: 'Hurand' },
  { label: 'Hurbanovo', value: 'Hurbanovo' },
  { label: 'Hurdal', value: 'Hurdal' },
  { label: 'Hurdegaryp', value: 'Hurdegaryp' },
  { label: 'Hurdugi', value: 'Hurdugi' },
  { label: 'Hurezani', value: 'Hurezani' },
  { label: 'Ḥurfeish', value: 'Ḥurfeish' },
  { label: 'Hurghada', value: 'Hurghada' },
  { label: 'Huriel', value: 'Huriel' },
  { label: 'Hurigny', value: 'Hurigny' },
  { label: 'Hurjuieni', value: 'Hurjuieni' },
  { label: 'Hurlach', value: 'Hurlach' },
  { label: 'Hurley', value: 'Hurley' },
  { label: 'Hurley', value: 'Hurley' },
  { label: 'Hurley', value: 'Hurley' },
  { label: 'Hurley', value: 'Hurley' },
  { label: 'Hurlock', value: 'Hurlock' },
  { label: 'Hurlstone Park', value: 'Hurlstone Park' },
  { label: 'Hürm', value: 'Hürm' },
  { label: 'Huron', value: 'Huron' },
  { label: 'Huron', value: 'Huron' },
  { label: 'Huron', value: 'Huron' },
  { label: 'Huron County', value: 'Huron County' },
  { label: 'Huron County', value: 'Huron County' },
  { label: 'Huron East', value: 'Huron East' },
  { label: 'Hurones', value: 'Hurones' },
  { label: 'Hurricane', value: 'Hurricane' },
  { label: 'Hurricane', value: 'Hurricane' },
  { label: 'Hurriya', value: 'Hurriya' },
  { label: 'Hurst', value: 'Hurst' },
  { label: 'Hurst', value: 'Hurst' },
  { label: 'Hurstbourne', value: 'Hurstbourne' },
  { label: 'Hurstbourne Acres', value: 'Hurstbourne Acres' },
  { label: 'Hurstbridge', value: 'Hurstbridge' },
  { label: 'Hurstpierpoint', value: 'Hurstpierpoint' },
  { label: 'Hurstville', value: 'Hurstville' },
  { label: 'Hurstville Grove', value: 'Hurstville Grove' },
  { label: 'Hurt', value: 'Hurt' },
  { label: 'Hürtgenwald', value: 'Hürtgenwald' },
  { label: 'Hürth', value: 'Hürth' },
  { label: 'Hurtumpascual', value: 'Hurtumpascual' },
  { label: 'Huruiești', value: 'Huruiești' },
  { label: 'Hurum', value: 'Hurum' },
  { label: 'Hurungwe District', value: 'Hurungwe District' },
  { label: 'Hurup', value: 'Hurup' },
  { label: 'Hürup', value: 'Hürup' },
  { label: 'Huruta', value: 'Huruta' },
  { label: 'Husainabad', value: 'Husainabad' },
  { label: 'Husasău De Tinca', value: 'Husasău De Tinca' },
  { label: 'Húsavík', value: 'Húsavík' },
  { label: 'Húsavík', value: 'Húsavík' },
  { label: 'Husayniyah', value: 'Husayniyah' },
  { label: 'Husbands Bosworth', value: 'Husbands Bosworth' },
  { label: 'Husborne Crawley', value: 'Husborne Crawley' },
  { label: 'Hushitai', value: 'Hushitai' },
  { label: 'Huşi', value: 'Huşi' },
  { label: 'Husiatyn', value: 'Husiatyn' },
  { label: 'Husillos', value: 'Husillos' },
  { label: 'Husinec', value: 'Husinec' },
  { label: 'Husken', value: 'Husken' },
  { label: 'Huskvarna', value: 'Huskvarna' },
  { label: 'Husnah', value: 'Husnah' },
  { label: 'Husnicioara', value: 'Husnicioara' },
  { label: 'Husów', value: 'Husów' },
  { label: 'Husseren-Wesserling', value: 'Husseren-Wesserling' },
  { label: 'Hussigny-Godbrange', value: 'Hussigny-Godbrange' },
  { label: 'Hustisford', value: 'Hustisford' },
  { label: 'Hustopeče', value: 'Hustopeče' },
  { label: 'Hustopeče Nad Bečvou', value: 'Hustopeče Nad Bečvou' },
  { label: 'Husum', value: 'Husum' },
  { label: 'Husum', value: 'Husum' },
  { label: 'Husum', value: 'Husum' },
  { label: 'Huswain', value: 'Huswain' },
  { label: 'Huta Certeze', value: 'Huta Certeze' },
  { label: 'Huta Stara B', value: 'Huta Stara B' },
  { label: 'Hutang', value: 'Hutang' },
  { label: 'Hutchins', value: 'Hutchins' },
  { label: 'Hutchinson', value: 'Hutchinson' },
  { label: 'Hutchinson', value: 'Hutchinson' },
  { label: 'Hutchinson County', value: 'Hutchinson County' },
  { label: 'Hutchinson County', value: 'Hutchinson County' },
  { label: 'Hutchinson Island South', value: 'Hutchinson Island South' },
  { label: 'Hütschenhausen', value: 'Hütschenhausen' },
  { label: 'Hüttau', value: 'Hüttau' },
  { label: 'Hutten', value: 'Hutten' },
  { label: 'Hüttenberg', value: 'Hüttenberg' },
  { label: 'Huttenheim', value: 'Huttenheim' },
  { label: 'Hüttenrode', value: 'Hüttenrode' },
  { label: 'Hutthurm', value: 'Hutthurm' },
  { label: 'Hüttisheim', value: 'Hüttisheim' },
  { label: 'Hüttlingen', value: 'Hüttlingen' },
  { label: 'Hutto', value: 'Hutto' },
  { label: 'Hüttschlag', value: 'Hüttschlag' },
  { label: 'Huttwil', value: 'Huttwil' },
  { label: 'Hüttwilen', value: 'Hüttwilen' },
  { label: 'Huty', value: 'Huty' },
  { label: 'Huxley', value: 'Huxley' },
  { label: 'Huy', value: 'Huy' },
  { label: 'Huyện A Lưới', value: 'Huyện A Lưới' },
  { label: 'Huyện An Biên', value: 'Huyện An Biên' },
  { label: 'Huyện An Lão', value: 'Huyện An Lão' },
  { label: 'Huyện An Minh', value: 'Huyện An Minh' },
  { label: 'Huyện An Phú', value: 'Huyện An Phú' },
  { label: 'Huyện Ân Thi', value: 'Huyện Ân Thi' },
  { label: 'Huyện Anh Sơn', value: 'Huyện Anh Sơn' },
  { label: 'Huyện Ba Bể', value: 'Huyện Ba Bể' },
  { label: 'Huyện Ba Chẽ', value: 'Huyện Ba Chẽ' },
  { label: 'Huyện Bá Thước', value: 'Huyện Bá Thước' },
  { label: 'Huyện Ba Tơ', value: 'Huyện Ba Tơ' },
  { label: 'Huyện Ba Tri', value: 'Huyện Ba Tri' },
  { label: 'Huyện Bắc Bình', value: 'Huyện Bắc Bình' },
  { label: 'Huyện Bắc Hà', value: 'Huyện Bắc Hà' },
  { label: 'Huyện Bắc Mê', value: 'Huyện Bắc Mê' },
  { label: 'Huyện Bắc Quang', value: 'Huyện Bắc Quang' },
  { label: 'Huyện Bắc Sơn', value: 'Huyện Bắc Sơn' },
  { label: 'Huyện Bắc Yên', value: 'Huyện Bắc Yên' },
  { label: 'Huyện Bạch Thông', value: 'Huyện Bạch Thông' },
  { label: 'Huyện Bảo Lac', value: 'Huyện Bảo Lac' },
  { label: 'Huyện Bảo Lâm', value: 'Huyện Bảo Lâm' },
  { label: 'Huyện Bảo Lâm', value: 'Huyện Bảo Lâm' },
  { label: 'Huyện Bảo Yên', value: 'Huyện Bảo Yên' },
  { label: 'Huyện Bát Xát', value: 'Huyện Bát Xát' },
  { label: 'Huyện Bến Cầu', value: 'Huyện Bến Cầu' },
  { label: 'Huyện Bến Lức', value: 'Huyện Bến Lức' },
  { label: 'Huyện Bình Đại', value: 'Huyện Bình Đại' },
  { label: 'Huyện Bình Gia', value: 'Huyện Bình Gia' },
  { label: 'Huyện Bình Giang', value: 'Huyện Bình Giang' },
  { label: 'Huyện Bình Liêu', value: 'Huyện Bình Liêu' },
  { label: 'Huyện Bình Lục', value: 'Huyện Bình Lục' },
  { label: 'Huyện Bình Sơn', value: 'Huyện Bình Sơn' },
  { label: 'Huyện Bình Xuyên', value: 'Huyện Bình Xuyên' },
  { label: 'Huyện Bù Đốp', value: 'Huyện Bù Đốp' },
  { label: 'Huyện Buôn Đôn', value: 'Huyện Buôn Đôn' },
  { label: 'Huyện Cái Bè', value: 'Huyện Cái Bè' },
  { label: 'Huyện Cai Lậy', value: 'Huyện Cai Lậy' },
  { label: 'Huyện Cái Nước', value: 'Huyện Cái Nước' },
  { label: 'Huyện Cẩm Giàng', value: 'Huyện Cẩm Giàng' },
  { label: 'Huyện Cẩm Khê', value: 'Huyện Cẩm Khê' },
  { label: 'Huyện Cam Lộ', value: 'Huyện Cam Lộ' },
  { label: 'Huyện Cẩm Thủy', value: 'Huyện Cẩm Thủy' },
  { label: 'Huyện Cẩm Xuyên', value: 'Huyện Cẩm Xuyên' },
  { label: 'Huyện Cần Đước', value: 'Huyện Cần Đước' },
  { label: 'Huyện Cần Giuộc', value: 'Huyện Cần Giuộc' },
  { label: 'Huyện Can Lộc', value: 'Huyện Can Lộc' },
  { label: 'Huyện Càng Long', value: 'Huyện Càng Long' },
  { label: 'Huyện Cao Lộc', value: 'Huyện Cao Lộc' },
  { label: 'Huyện Cao Phong', value: 'Huyện Cao Phong' },
  { label: 'Huyện Cầu Kè', value: 'Huyện Cầu Kè' },
  { label: 'Huyện Cầu Ngang', value: 'Huyện Cầu Ngang' },
  { label: 'Huyện Châu Đức', value: 'Huyện Châu Đức' },
  { label: 'Huyện Châu Phú', value: 'Huyện Châu Phú' },
  { label: 'Huyện Châu Thành', value: 'Huyện Châu Thành' },
  { label: 'Huyện Châu Thành', value: 'Huyện Châu Thành' },
  { label: 'Huyện Châu Thành', value: 'Huyện Châu Thành' },
  { label: 'Huyện Châu Thành', value: 'Huyện Châu Thành' },
  { label: 'Huyện Châu Thành', value: 'Huyện Châu Thành' },
  { label: 'Huyện Châu Thành A', value: 'Huyện Châu Thành A' },
  { label: 'Huyện Chi Lăng', value: 'Huyện Chi Lăng' },
  { label: 'Huyện Chiêm Hóa', value: 'Huyện Chiêm Hóa' },
  { label: 'Huyện Chợ Đồn', value: 'Huyện Chợ Đồn' },
  { label: 'Huyện Chợ Gạo', value: 'Huyện Chợ Gạo' },
  { label: 'Huyện Chợ Lách', value: 'Huyện Chợ Lách' },
  { label: 'Huyện Chợ Mới', value: 'Huyện Chợ Mới' },
  { label: 'Huyện Chơn Thành', value: 'Huyện Chơn Thành' },
  { label: 'Huyện Chư Păh', value: 'Huyện Chư Păh' },
  { label: 'Huyện Chư Prông', value: 'Huyện Chư Prông' },
  { label: 'Huyện Chư Sê', value: 'Huyện Chư Sê' },
  { label: 'Huyện Cô Tô', value: 'Huyện Cô Tô' },
  { label: 'Huyện Con Cuông', value: 'Huyện Con Cuông' },
  { label: 'Huyện Cư Jút', value: 'Huyện Cư Jút' },
  { label: 'Huyện Đạ Huoai', value: 'Huyện Đạ Huoai' },
  { label: 'Huyện Đa Krông', value: 'Huyện Đa Krông' },
  { label: 'Huyện Đạ Tẻh', value: 'Huyện Đạ Tẻh' },
  { label: 'Huyện Đại Lộc', value: 'Huyện Đại Lộc' },
  { label: 'Huyện Đắk Glei', value: 'Huyện Đắk Glei' },
  { label: 'Huyện Đắk Hà', value: 'Huyện Đắk Hà' },
  { label: 'Huyện Đắk R’Lấp', value: 'Huyện Đắk R’Lấp' },
  { label: 'Huyện Đắk Tô', value: 'Huyện Đắk Tô' },
  { label: 'Huyện Đầm Dơi', value: 'Huyện Đầm Dơi' },
  { label: 'Huyện Đầm Hà', value: 'Huyện Đầm Hà' },
  { label: 'Huyện Đất Đỏ', value: 'Huyện Đất Đỏ' },
  { label: 'Huyện Điện Bàn', value: 'Huyện Điện Bàn' },
  { label: 'Huyện Điện Biên Đông', value: 'Huyện Điện Biên Đông' },
  { label: 'Huyện Diễn Châu', value: 'Huyện Diễn Châu' },
  { label: 'Huyện Diên Khánh', value: 'Huyện Diên Khánh' },
  { label: 'Huyện Đình Lập', value: 'Huyện Đình Lập' },
  { label: 'Huyện Định Quán', value: 'Huyện Định Quán' },
  { label: 'Huyện Đô Lương', value: 'Huyện Đô Lương' },
  { label: 'Huyện Đoan Hùng', value: 'Huyện Đoan Hùng' },
  { label: 'Huyện Đơn Dương', value: 'Huyện Đơn Dương' },
  { label: 'Huyện Ðông Hưng', value: 'Huyện Ðông Hưng' },
  { label: 'Huyện Đồng Phú', value: 'Huyện Đồng Phú' },
  { label: 'Huyện Đông Sơn', value: 'Huyện Đông Sơn' },
  { label: 'Huyện Đông Triều', value: 'Huyện Đông Triều' },
  { label: 'Huyện Đồng Văn', value: 'Huyện Đồng Văn' },
  { label: 'Huyện Đức Cơ', value: 'Huyện Đức Cơ' },
  { label: 'Huyện Đức Hòa', value: 'Huyện Đức Hòa' },
  { label: 'Huyện Đức Huệ', value: 'Huyện Đức Huệ' },
  { label: 'Huyện Đức Phổ', value: 'Huyện Đức Phổ' },
  { label: 'Huyện Ðức Thọ', value: 'Huyện Ðức Thọ' },
  { label: 'Huyện Đức Trọng', value: 'Huyện Đức Trọng' },
  { label: 'Huyện Dương Minh Châu', value: 'Huyện Dương Minh Châu' },
  { label: 'Huyện Duy Tiên', value: 'Huyện Duy Tiên' },
  { label: 'Huyện Duy Xuyên', value: 'Huyện Duy Xuyên' },
  { label: 'Huyện Ea Hleo', value: 'Huyện Ea Hleo' },
  { label: 'Huyện Ea Súp', value: 'Huyện Ea Súp' },
  { label: 'Huyện Gia Bình', value: 'Huyện Gia Bình' },
  { label: 'Huyện Gia Lộc', value: 'Huyện Gia Lộc' },
  { label: 'Huyện Giá Rai', value: 'Huyện Giá Rai' },
  { label: 'Huyện Giao Thủy', value: 'Huyện Giao Thủy' },
  { label: 'Huyện Gio Linh', value: 'Huyện Gio Linh' },
  { label: 'Huyện Giồng Riềng', value: 'Huyện Giồng Riềng' },
  { label: 'Huyện Giồng Trôm', value: 'Huyện Giồng Trôm' },
  { label: 'Huyện Gò Công Đông', value: 'Huyện Gò Công Đông' },
  { label: 'Huyện Gò Công Tây', value: 'Huyện Gò Công Tây' },
  { label: 'Huyện Gò Dầu', value: 'Huyện Gò Dầu' },
  { label: 'Huyện Gò Quao', value: 'Huyện Gò Quao' },
  { label: 'Huyện Hạ Hòa', value: 'Huyện Hạ Hòa' },
  { label: 'Huyện Hạ Lang', value: 'Huyện Hạ Lang' },
  { label: 'Huyện Hà Quảng', value: 'Huyện Hà Quảng' },
  { label: 'Huyện Hà Trung', value: 'Huyện Hà Trung' },
  { label: 'Huyện Hải Hà', value: 'Huyện Hải Hà' },
  { label: 'Huyện Hải Hậu', value: 'Huyện Hải Hậu' },
  { label: 'Huyện Hải Lăng', value: 'Huyện Hải Lăng' },
  { label: 'Huyện Hàm Tân', value: 'Huyện Hàm Tân' },
  { label: 'Huyện Hàm Thuận Bắc', value: 'Huyện Hàm Thuận Bắc' },
  { label: 'Huyện Hàm Thuận Nam', value: 'Huyện Hàm Thuận Nam' },
  { label: 'Huyện Hàm Yên', value: 'Huyện Hàm Yên' },
  { label: 'Huyện Hậu Lộc', value: 'Huyện Hậu Lộc' },
  { label: 'Huyện Hiệp Đức', value: 'Huyện Hiệp Đức' },
  { label: 'Huyện Hoa Lư', value: 'Huyện Hoa Lư' },
  { label: 'Huyện Hòa Thành', value: 'Huyện Hòa Thành' },
  { label: 'Huyện Hoài Nhơn', value: 'Huyện Hoài Nhơn' },
  { label: 'Huyện Hoành Bồ', value: 'Huyện Hoành Bồ' },
  { label: 'Huyện Hòn Đất', value: 'Huyện Hòn Đất' },
  { label: 'Huyện Hớn Quản', value: 'Huyện Hớn Quản' },
  { label: 'Huyện Hồng Dân', value: 'Huyện Hồng Dân' },
  { label: 'Huyện Hưng Hà', value: 'Huyện Hưng Hà' },
  { label: 'Huyện Hưng Nguyên', value: 'Huyện Hưng Nguyên' },
  { label: 'Huyện Hướng Hóa', value: 'Huyện Hướng Hóa' },
  { label: 'Huyện Hũu Lũng', value: 'Huyện Hũu Lũng' },
  { label: 'Huyện Ia Grai', value: 'Huyện Ia Grai' },
  { label: 'Huyện Ia Hdrai', value: 'Huyện Ia Hdrai' },
  { label: 'Huyện Kbang', value: 'Huyện Kbang' },
  { label: 'Huyện Kế Sách', value: 'Huyện Kế Sách' },
  { label: 'Huyện Khánh Sơn', value: 'Huyện Khánh Sơn' },
  { label: 'Huyện Khánh Vĩnh', value: 'Huyện Khánh Vĩnh' },
  { label: 'Huyện Khoái Châu', value: 'Huyện Khoái Châu' },
  { label: 'Huyện Kiên Hải', value: 'Huyện Kiên Hải' },
  { label: 'Huyện Kiến Xương', value: 'Huyện Kiến Xương' },
  { label: 'Huyện Kim Bảng', value: 'Huyện Kim Bảng' },
  { label: 'Huyện Kim Động', value: 'Huyện Kim Động' },
  { label: 'Huyện Kim Sơn', value: 'Huyện Kim Sơn' },
  { label: 'Huyện Kinh Môn', value: 'Huyện Kinh Môn' },
  { label: 'Huyện Kon Plông', value: 'Huyện Kon Plông' },
  { label: 'Huyện Kông Chro', value: 'Huyện Kông Chro' },
  { label: 'Huyện Krông Nô', value: 'Huyện Krông Nô' },
  { label: 'Huyện Krông Pa', value: 'Huyện Krông Pa' },
  { label: 'Huyện Kỳ Anh', value: 'Huyện Kỳ Anh' },
  { label: 'Huyện Kỳ Sơn', value: 'Huyện Kỳ Sơn' },
  { label: 'Huyện Kỳ Sơn', value: 'Huyện Kỳ Sơn' },
  { label: 'Huyện Lạc Thủy', value: 'Huyện Lạc Thủy' },
  { label: 'Huyện Lai Vung', value: 'Huyện Lai Vung' },
  { label: 'Huyện Lâm Bình', value: 'Huyện Lâm Bình' },
  { label: 'Huyện Lâm Hà', value: 'Huyện Lâm Hà' },
  { label: 'Huyen Lam Thao', value: 'Huyen Lam Thao' },
  { label: 'Huyện Lang Chánh', value: 'Huyện Lang Chánh' },
  { label: 'Huyện Lấp Vò', value: 'Huyện Lấp Vò' },
  { label: 'Huyện Lệ Thủy', value: 'Huyện Lệ Thủy' },
  { label: 'Huyện Long Hồ', value: 'Huyện Long Hồ' },
  { label: 'Huyện Long Phú', value: 'Huyện Long Phú' },
  { label: 'Huyện Long Thành', value: 'Huyện Long Thành' },
  { label: 'Huyện Lục Nam', value: 'Huyện Lục Nam' },
  { label: 'Huyện Lục Ngạn', value: 'Huyện Lục Ngạn' },
  { label: 'Huyện Lục Yên', value: 'Huyện Lục Yên' },
  { label: 'Huyện Lương Sơn', value: 'Huyện Lương Sơn' },
  { label: 'Huyện Lý Nhân', value: 'Huyện Lý Nhân' },
  { label: 'Huyện Lý Sơn', value: 'Huyện Lý Sơn' },
  { label: 'Huyện Mai Sơn', value: 'Huyện Mai Sơn' },
  { label: 'Huyện Mang Thít', value: 'Huyện Mang Thít' },
  { label: 'Huyện Mang Yang', value: 'Huyện Mang Yang' },
  { label: 'Huyện Minh Long', value: 'Huyện Minh Long' },
  { label: 'Huyện Mộ Đức', value: 'Huyện Mộ Đức' },
  { label: 'Huyện Mộc Châu', value: 'Huyện Mộc Châu' },
  { label: 'Huyện Mộc Hóa', value: 'Huyện Mộc Hóa' },
  { label: 'Huyện Mù Cang Chải', value: 'Huyện Mù Cang Chải' },
  { label: 'Huyện Mường Khương', value: 'Huyện Mường Khương' },
  { label: 'Huyện Mường La', value: 'Huyện Mường La' },
  { label: 'Huyện Mường Lát', value: 'Huyện Mường Lát' },
  { label: 'Huyện Mường Nhé', value: 'Huyện Mường Nhé' },
  { label: 'Huyện Mưòng Tè', value: 'Huyện Mưòng Tè' },
  { label: 'Huyện Mỹ Hào', value: 'Huyện Mỹ Hào' },
  { label: 'Huyện Mỹ Lộc', value: 'Huyện Mỹ Lộc' },
  { label: 'Huyện Mỹ Tú', value: 'Huyện Mỹ Tú' },
  { label: 'Huyện Mỹ Xuyên', value: 'Huyện Mỹ Xuyên' },
  { label: 'Huyện Na Hang', value: 'Huyện Na Hang' },
  { label: 'Huyện Na Rì', value: 'Huyện Na Rì' },
  { label: 'Huyen Nam Can', value: 'Huyen Nam Can' },
  { label: 'Huyện Nam Đàn', value: 'Huyện Nam Đàn' },
  { label: 'Huyện Nam Đông', value: 'Huyện Nam Đông' },
  { label: 'Huyện Nam Giang', value: 'Huyện Nam Giang' },
  { label: 'Huyện Nam Sách', value: 'Huyện Nam Sách' },
  { label: 'Huyện Nam Trực', value: 'Huyện Nam Trực' },
  { label: 'Huyen Nga Nam', value: 'Huyen Nga Nam' },
  { label: 'Huyện Nga Sơn', value: 'Huyện Nga Sơn' },
  { label: 'Huyện Ngân Sơn', value: 'Huyện Ngân Sơn' },
  { label: 'Huyện Nghi Lộc', value: 'Huyện Nghi Lộc' },
  { label: 'Huyện Nghi Xuân', value: 'Huyện Nghi Xuân' },
  { label: 'Huyện Nghĩa Đàn', value: 'Huyện Nghĩa Đàn' },
  { label: 'Huyện Nghĩa Hành', value: 'Huyện Nghĩa Hành' },
  { label: 'Huyện Nghĩa Hưng', value: 'Huyện Nghĩa Hưng' },
  { label: 'Huyện Ngọc Hồi', value: 'Huyện Ngọc Hồi' },
  { label: 'Huyện Ngọc Lặc', value: 'Huyện Ngọc Lặc' },
  { label: 'Huyện Nguyên Bình', value: 'Huyện Nguyên Bình' },
  { label: 'Huyện Nho Quan', value: 'Huyện Nho Quan' },
  { label: 'Huyện Như Thanh', value: 'Huyện Như Thanh' },
  { label: 'Huyện Như Xuân', value: 'Huyện Như Xuân' },
  { label: 'Huyện Nông Cống', value: 'Huyện Nông Cống' },
  { label: 'Huyện Núi Thành', value: 'Huyện Núi Thành' },
  { label: 'Huyện Phong Điền', value: 'Huyện Phong Điền' },
  { label: 'Huyện Phù Cừ', value: 'Huyện Phù Cừ' },
  { label: 'Huyện Phú Lộc', value: 'Huyện Phú Lộc' },
  { label: 'Huyện Phù Mỹ', value: 'Huyện Phù Mỹ' },
  { label: 'Huyện Phú Quốc', value: 'Huyện Phú Quốc' },
  { label: 'Huyện Phú Tân', value: 'Huyện Phú Tân' },
  { label: 'Huyện Phú Vang', value: 'Huyện Phú Vang' },
  { label: 'Huyện Phù Yên', value: 'Huyện Phù Yên' },
  { label: 'Huyen Phuc Hoa', value: 'Huyen Phuc Hoa' },
  { label: 'Huyện Phước Sơn', value: 'Huyện Phước Sơn' },
  { label: 'Huyện Quan Hóa', value: 'Huyện Quan Hóa' },
  { label: 'Huyện Quan Sơn', value: 'Huyện Quan Sơn' },
  { label: 'Huyện Quang Bình', value: 'Huyện Quang Bình' },
  { label: 'Huyện Quảng Ðiền', value: 'Huyện Quảng Ðiền' },
  { label: 'Huyện Quảng Ninh', value: 'Huyện Quảng Ninh' },
  { label: 'Huyện Quảng Trạch', value: 'Huyện Quảng Trạch' },
  { label: 'Huyện Quảng Xương', value: 'Huyện Quảng Xương' },
  { label: 'Huyện Quế Phong', value: 'Huyện Quế Phong' },
  { label: 'Huyện Quốc Oai', value: 'Huyện Quốc Oai' },
  { label: 'Huyện Quỳ Châu', value: 'Huyện Quỳ Châu' },
  { label: 'Huyện Quỳ Hợp', value: 'Huyện Quỳ Hợp' },
  { label: 'Huyện Quỳnh Lưu', value: 'Huyện Quỳnh Lưu' },
  { label: 'Huyện Quỳnh Nhai', value: 'Huyện Quỳnh Nhai' },
  { label: 'Huyện Quỳnh Phụ', value: 'Huyện Quỳnh Phụ' },
  { label: 'Huyện Sa Pa', value: 'Huyện Sa Pa' },
  { label: 'Huyện Sa Thầy', value: 'Huyện Sa Thầy' },
  { label: 'Huyện Si Ma Cai', value: 'Huyện Si Ma Cai' },
  { label: 'Huyện Sơn Dương', value: 'Huyện Sơn Dương' },
  { label: 'Huyện Sơn Hà', value: 'Huyện Sơn Hà' },
  { label: 'Huyện Sơn Hòa', value: 'Huyện Sơn Hòa' },
  { label: 'Huyện Sơn Tây', value: 'Huyện Sơn Tây' },
  { label: 'Huyện Sơn Tịnh', value: 'Huyện Sơn Tịnh' },
  { label: 'Huyện Sông Hinh', value: 'Huyện Sông Hinh' },
  { label: 'Huyện Sông Mã', value: 'Huyện Sông Mã' },
  { label: 'Huyện Tam Bình', value: 'Huyện Tam Bình' },
  { label: 'Huyện Tam Đảo', value: 'Huyện Tam Đảo' },
  { label: 'Huyện Tam Dương', value: 'Huyện Tam Dương' },
  { label: 'Huyện Tam Đường', value: 'Huyện Tam Đường' },
  { label: 'Huyện Tam Nông', value: 'Huyện Tam Nông' },
  { label: 'Huyện Tân Châu', value: 'Huyện Tân Châu' },
  { label: 'Huyện Tân Hiệp', value: 'Huyện Tân Hiệp' },
  { label: 'Huyện Tân Hồng', value: 'Huyện Tân Hồng' },
  { label: 'Huyện Tân Hưng', value: 'Huyện Tân Hưng' },
  { label: 'Huyện Tân Lạc', value: 'Huyện Tân Lạc' },
  { label: 'Huyện Tân Phú', value: 'Huyện Tân Phú' },
  { label: 'Huyện Tân Phước', value: 'Huyện Tân Phước' },
  { label: 'Huyện Tân Thành', value: 'Huyện Tân Thành' },
  { label: 'Huyện Tân Thạnh', value: 'Huyện Tân Thạnh' },
  { label: 'Huyện Tân Trụ', value: 'Huyện Tân Trụ' },
  { label: 'Huyện Tánh Linh', value: 'Huyện Tánh Linh' },
  { label: 'Huyện Tây Sơn', value: 'Huyện Tây Sơn' },
  { label: 'Huyện Thạch An', value: 'Huyện Thạch An' },
  { label: 'Huyện Thạch Hà', value: 'Huyện Thạch Hà' },
  { label: 'Huyện Thái Thụy', value: 'Huyện Thái Thụy' },
  { label: 'Huyện Than Uyên', value: 'Huyện Than Uyên' },
  { label: 'Huyện Thanh Ba', value: 'Huyện Thanh Ba' },
  { label: 'Huyện Thanh Bình', value: 'Huyện Thanh Bình' },
  { label: 'Huyện Thanh Chương', value: 'Huyện Thanh Chương' },
  { label: 'Huyện Thạnh Hóa', value: 'Huyện Thạnh Hóa' },
  { label: 'Huyện Thanh Liêm', value: 'Huyện Thanh Liêm' },
  { label: 'Huyện Thanh Miện', value: 'Huyện Thanh Miện' },
  { label: 'Huyện Thạnh Phú', value: 'Huyện Thạnh Phú' },
  { label: 'Huyện Thạnh Trị', value: 'Huyện Thạnh Trị' },
  { label: 'Huyện Tháp Mười', value: 'Huyện Tháp Mười' },
  { label: 'Huyện Thiệu Hóa', value: 'Huyện Thiệu Hóa' },
  { label: 'Huyện Thọ Xuân', value: 'Huyện Thọ Xuân' },
  { label: 'Huyện Thới Bình', value: 'Huyện Thới Bình' },
  { label: 'Huyện Thống Nhất', value: 'Huyện Thống Nhất' },
  { label: 'Huyện Thông Nông', value: 'Huyện Thông Nông' },
  { label: 'Huyện Thủ Thừa', value: 'Huyện Thủ Thừa' },
  { label: 'Huyện Thuận Châu', value: 'Huyện Thuận Châu' },
  { label: 'Huyện Thuận Thành', value: 'Huyện Thuận Thành' },
  { label: 'Huyện Thường Xuân', value: 'Huyện Thường Xuân' },
  { label: 'Huyện Tiên Du', value: 'Huyện Tiên Du' },
  { label: 'Huyện Tiền Hải', value: 'Huyện Tiền Hải' },
  { label: 'Huyện Tiên Lữ', value: 'Huyện Tiên Lữ' },
  { label: 'Huyện Tiên Phước', value: 'Huyện Tiên Phước' },
  { label: 'Huyện Tiên Yên', value: 'Huyện Tiên Yên' },
  { label: 'Huyện Tiểu Cần', value: 'Huyện Tiểu Cần' },
  { label: 'Huyện Trà Bồng', value: 'Huyện Trà Bồng' },
  { label: 'Huyện Trà Cú', value: 'Huyện Trà Cú' },
  { label: 'Huyện Trà Lĩnh', value: 'Huyện Trà Lĩnh' },
  { label: 'Huyện Trà My', value: 'Huyện Trà My' },
  { label: 'Huyện Trà Ôn', value: 'Huyện Trà Ôn' },
  { label: 'Huyện Trạm Tấu', value: 'Huyện Trạm Tấu' },
  { label: 'Huyện Trấn Yên', value: 'Huyện Trấn Yên' },
  { label: 'Huyện Trảng Bàng', value: 'Huyện Trảng Bàng' },
  { label: 'Huyện Trảng Bom', value: 'Huyện Trảng Bom' },
  { label: 'Huyện Tri Tôn', value: 'Huyện Tri Tôn' },
  { label: 'Huyện Triệu Phong', value: 'Huyện Triệu Phong' },
  { label: 'Huyện Triệu Sơn', value: 'Huyện Triệu Sơn' },
  { label: 'Huyện Trực Ninh', value: 'Huyện Trực Ninh' },
  { label: 'Huyện Trùng Khánh', value: 'Huyện Trùng Khánh' },
  { label: 'Huyện Tư Nghĩa', value: 'Huyện Tư Nghĩa' },
  { label: 'Huyện Tủa Chùa', value: 'Huyện Tủa Chùa' },
  { label: 'Huyện Tuần Giáo', value: 'Huyện Tuần Giáo' },
  { label: 'Huyện Tương Dương', value: 'Huyện Tương Dương' },
  { label: 'Huyện Tuy Phong', value: 'Huyện Tuy Phong' },
  { label: 'Huyện Tuy Phước', value: 'Huyện Tuy Phước' },
  { label: 'Huyện Tuyên Hóa', value: 'Huyện Tuyên Hóa' },
  { label: 'Huyện Văn Bàn', value: 'Huyện Văn Bàn' },
  { label: 'Huyện Văn Chấn', value: 'Huyện Văn Chấn' },
  { label: 'Huyện Vân Đồn', value: 'Huyện Vân Đồn' },
  { label: 'Huyện Vạn Ninh', value: 'Huyện Vạn Ninh' },
  { label: 'Huyện Vặn Quan', value: 'Huyện Vặn Quan' },
  { label: 'Huyện Văn Yên', value: 'Huyện Văn Yên' },
  { label: 'Huyện Vị Xuyên', value: 'Huyện Vị Xuyên' },
  { label: 'Huyện Vĩnh Cửu', value: 'Huyện Vĩnh Cửu' },
  { label: 'Huyện Vĩnh Hưng', value: 'Huyện Vĩnh Hưng' },
  { label: 'Huyện Vĩnh Linh', value: 'Huyện Vĩnh Linh' },
  { label: 'Huyện Vĩnh Lộc', value: 'Huyện Vĩnh Lộc' },
  { label: 'Huyện Vĩnh Thạnh', value: 'Huyện Vĩnh Thạnh' },
  { label: 'Huyện Vĩnh Thuận', value: 'Huyện Vĩnh Thuận' },
  { label: 'Huyện Vụ Bản', value: 'Huyện Vụ Bản' },
  { label: 'Huyện Vũ Thư', value: 'Huyện Vũ Thư' },
  { label: 'Huyện Vũng Liêm', value: 'Huyện Vũng Liêm' },
  { label: 'Huyện Xín Mần', value: 'Huyện Xín Mần' },
  { label: 'Huyện Xuyên Mộc', value: 'Huyện Xuyên Mộc' },
  { label: 'Huyện Ý Yên', value: 'Huyện Ý Yên' },
  { label: 'Huyện Yên Châu', value: 'Huyện Yên Châu' },
  { label: 'Huyện Yên Định', value: 'Huyện Yên Định' },
  { label: 'Huyện Yên Khánh', value: 'Huyện Yên Khánh' },
  { label: 'Huyện Yên Lạc', value: 'Huyện Yên Lạc' },
  { label: 'Huyện Yên Lập', value: 'Huyện Yên Lập' },
  { label: 'Huyện Yên Minh', value: 'Huyện Yên Minh' },
  { label: 'Huyện Yên Mô', value: 'Huyện Yên Mô' },
  { label: 'Huyện Yên Phong', value: 'Huyện Yên Phong' },
  { label: 'Huyện Yên Sơn', value: 'Huyện Yên Sơn' },
  { label: 'Huyện Yên Thành', value: 'Huyện Yên Thành' },
  { label: 'Huyện Yên Thế', value: 'Huyện Yên Thế' },
  { label: 'Huy-Neinstedt', value: 'Huy-Neinstedt' },
  { label: 'Huyton', value: 'Huyton' },
  { label: 'Hüyük', value: 'Hüyük' },
  { label: 'Huzhou', value: 'Huzhou' },
  { label: 'Hvalba', value: 'Hvalba' },
  { label: 'Hvaler', value: 'Hvaler' },
  { label: 'Hvalfjarðarsveit', value: 'Hvalfjarðarsveit' },
  { label: 'Hvannasund', value: 'Hvannasund' },
  { label: 'Hvar', value: 'Hvar' },
  { label: 'Hvardiiske', value: 'Hvardiiske' },
  { label: 'Hveragerði', value: 'Hveragerði' },
  { label: 'Hvide Sande', value: 'Hvide Sande' },
  { label: 'Hvidovre', value: 'Hvidovre' },
  { label: 'Hvidovre Kommune', value: 'Hvidovre Kommune' },
  { label: 'Hvittingfoss', value: 'Hvittingfoss' },
  { label: 'Hvizd', value: 'Hvizd' },
  { label: 'Hvozdná', value: 'Hvozdná' },
  { label: 'Hwacheon', value: 'Hwacheon' },
  { label: 'Hwacheon-Gun', value: 'Hwacheon-Gun' },
  { label: 'Hwado', value: 'Hwado' },
  { label: 'Hwange', value: 'Hwange' },
  { label: 'Hwange District', value: 'Hwange District' },
  { label: 'Hwangju-Ŭp', value: 'Hwangju-Ŭp' },
  { label: 'Hwaseong-Si', value: 'Hwaseong-Si' },
  { label: 'Hwasun', value: 'Hwasun' },
  { label: 'Hwasun-Gun', value: 'Hwasun-Gun' },
  { label: 'Hwawŏn', value: 'Hwawŏn' },
  { label: 'Hyannis', value: 'Hyannis' },
  { label: 'Hyannis', value: 'Hyannis' },
  { label: 'Hyattsville', value: 'Hyattsville' },
  { label: 'Hybe', value: 'Hybe' },
  { label: 'Hybla Valley', value: 'Hybla Valley' },
  { label: 'Hyde', value: 'Hyde' },
  { label: 'Hyde', value: 'Hyde' },
  { label: 'Hyde County', value: 'Hyde County' },
  { label: 'Hyde County', value: 'Hyde County' },
  { label: 'Hyde Heath', value: 'Hyde Heath' },
  { label: 'Hyde Park', value: 'Hyde Park' },
  { label: 'Hyde Park', value: 'Hyde Park' },
  { label: 'Hyde Park', value: 'Hyde Park' },
  { label: 'Hyde Park', value: 'Hyde Park' },
  { label: 'Hyde Park', value: 'Hyde Park' },
  { label: 'Hyde Park', value: 'Hyde Park' },
  { label: 'Hyde Park', value: 'Hyde Park' },
  { label: 'Hyden', value: 'Hyden' },
  { label: 'Hyderabad', value: 'Hyderabad' },
  { label: 'Hyderabad', value: 'Hyderabad' },
  { label: 'Hydesville', value: 'Hydesville' },
  { label: 'Hyeranyony', value: 'Hyeranyony' },
  { label: 'Hyères', value: 'Hyères' },
  { label: 'Hyesan', value: 'Hyesan' },
  { label: 'Hyesan-Dong', value: 'Hyesan-Dong' },
  { label: 'Hylkje', value: 'Hylkje' },
  { label: 'Hyllestad', value: 'Hyllestad' },
  { label: 'Hyllinge', value: 'Hyllinge' },
  { label: 'Hyltebruk', value: 'Hyltebruk' },
  { label: 'Hypoluxo', value: 'Hypoluxo' },
  { label: 'Hyrum', value: 'Hyrum' },
  { label: 'Hyrynsalmi', value: 'Hyrynsalmi' },
  { label: 'Hysham', value: 'Hysham' },
  { label: 'Hýskov', value: 'Hýskov' },
  { label: 'Hythe', value: 'Hythe' },
  { label: 'Hyūga-Shi', value: 'Hyūga-Shi' },
  { label: 'Hyvinge', value: 'Hyvinge' },
  { label: 'Hyżne', value: 'Hyżne' },
  { label: 'I. C. Brătianu', value: 'I. C. Brătianu' },
  { label: 'I. L. Caragiale', value: 'I. L. Caragiale' },
  { label: 'I‘Zāz', value: 'I‘Zāz' },
  { label: 'Iablaniţa', value: 'Iablaniţa' },
  { label: 'Iacanga', value: 'Iacanga' },
  { label: 'Iaciara', value: 'Iaciara' },
  { label: 'Iacobeni', value: 'Iacobeni' },
  { label: 'Iacobeni', value: 'Iacobeni' },
  { label: 'Iacobeni', value: 'Iacobeni' },
  { label: 'Iacri', value: 'Iacri' },
  { label: 'Iaçu', value: 'Iaçu' },
  { label: 'Iadăra', value: 'Iadăra' },
  { label: 'Ialibu', value: 'Ialibu' },
  { label: 'Ialibu Pangia', value: 'Ialibu Pangia' },
  { label: 'Ialmicco', value: 'Ialmicco' },
  { label: 'Ialoveni', value: 'Ialoveni' },
  { label: 'Ialysós', value: 'Ialysós' },
  { label: 'Iana', value: 'Iana' },
  { label: 'Ianca', value: 'Ianca' },
  { label: 'Ianca', value: 'Ianca' },
  { label: 'Iancu Jianu', value: 'Iancu Jianu' },
  { label: 'Ianoșda', value: 'Ianoșda' },
  { label: 'Iapa', value: 'Iapa' },
  { label: 'Iapu', value: 'Iapu' },
  { label: 'Iara', value: 'Iara' },
  { label: 'Iara De Mureș', value: 'Iara De Mureș' },
  { label: 'Iaras', value: 'Iaras' },
  { label: 'Iargara', value: 'Iargara' },
  { label: 'Iaşi', value: 'Iaşi' },
  { label: 'Iaslovăț', value: 'Iaslovăț' },
  { label: 'Íasmos', value: 'Íasmos' },
  { label: 'Iati', value: 'Iati' },
  { label: 'Iawar', value: 'Iawar' },
  { label: 'Iazu', value: 'Iazu' },
  { label: 'Iazu', value: 'Iazu' },
  { label: 'Iazu Nou', value: 'Iazu Nou' },
  { label: 'Iazurile', value: 'Iazurile' },
  { label: 'Iba', value: 'Iba' },
  { label: 'Iba', value: 'Iba' },
  { label: 'Ibaan', value: 'Ibaan' },
  { label: 'Ibaba', value: 'Ibaba' },
  { label: 'Ibabang Tayuman', value: 'Ibabang Tayuman' },
  { label: 'Ibach', value: 'Ibach' },
  { label: 'Ibadan', value: 'Ibadan' },
  { label: 'Ibagué', value: 'Ibagué' },
  { label: 'Ibahernando', value: 'Ibahernando' },
  { label: 'Ibaiti', value: 'Ibaiti' },
  { label: 'Ibajay', value: 'Ibajay' },
  { label: 'Ibanda', value: 'Ibanda' },
  { label: 'Ibanda District', value: 'Ibanda District' },
  { label: 'Ibăneşti', value: 'Ibăneşti' },
  { label: 'Ibănești', value: 'Ibănești' },
  { label: 'Ibănești-Pădure', value: 'Ibănești-Pădure' },
  { label: 'Ibara', value: 'Ibara' },
  { label: 'Ibara Shi', value: 'Ibara Shi' },
  { label: 'Ibaraki', value: 'Ibaraki' },
  { label: 'Ibaraki Shi', value: 'Ibaraki Shi' },
  { label: 'Ibarama', value: 'Ibarama' },
  { label: 'Ibaretama', value: 'Ibaretama' },
  { label: 'Ibargoiti', value: 'Ibargoiti' },
  { label: 'Ibarra', value: 'Ibarra' },
  { label: 'Ibarra', value: 'Ibarra' },
  { label: 'Ibarra', value: 'Ibarra' },
  { label: 'Ibarra', value: 'Ibarra' },
  { label: 'Ibarra', value: 'Ibarra' },
  { label: 'Ibarrangelu', value: 'Ibarrangelu' },
  { label: 'Ibarreta', value: 'Ibarreta' },
  { label: 'Ibarrilla', value: 'Ibarrilla' },
  { label: 'Ibarrilla', value: 'Ibarrilla' },
  { label: 'Ibaté', value: 'Ibaté' },
  { label: 'Ibateguara', value: 'Ibateguara' },
  { label: 'Ibatiba', value: 'Ibatiba' },
  { label: 'Ibb', value: 'Ibb' },
  { label: 'Ibbenbüren', value: 'Ibbenbüren' },
  { label: 'Ibdes', value: 'Ibdes' },
  { label: 'Ibeas De Juarros', value: 'Ibeas De Juarros' },
  { label: 'Ibeju-Lekki', value: 'Ibeju-Lekki' },
  { label: 'Ibema', value: 'Ibema' },
  { label: 'Iberia', value: 'Iberia' },
  { label: 'Iberia Parish', value: 'Iberia Parish' },
  { label: 'Ibertioga', value: 'Ibertioga' },
  { label: 'Iberville Parish', value: 'Iberville Parish' },
  { label: 'Ibestad', value: 'Ibestad' },
  { label: 'Ibeto', value: 'Ibeto' },
  { label: 'Ibi', value: 'Ibi' },
  { label: 'Ibi', value: 'Ibi' },
  { label: 'Ibiá', value: 'Ibiá' },
  { label: 'Ibiaçá', value: 'Ibiaçá' },
  { label: 'Ibiaí', value: 'Ibiaí' },
  { label: 'Ibiam', value: 'Ibiam' },
  { label: 'Ibiapina', value: 'Ibiapina' },
  { label: 'Ibiara', value: 'Ibiara' },
  { label: 'Ibias', value: 'Ibias' },
  { label: 'Ibiassucê', value: 'Ibiassucê' },
  { label: 'Ibicaraí', value: 'Ibicaraí' },
  { label: 'Ibicaré', value: 'Ibicaré' },
  { label: 'Ibicoara', value: 'Ibicoara' },
  { label: 'Ibicuí', value: 'Ibicuí' },
  { label: 'Ibicuitinga', value: 'Ibicuitinga' },
  { label: 'Ibieca', value: 'Ibieca' },
  { label: 'Ibimirim', value: 'Ibimirim' },
  { label: 'Ibipeba', value: 'Ibipeba' },
  { label: 'Ibipitanga', value: 'Ibipitanga' },
  { label: 'Ibiporã', value: 'Ibiporã' },
  { label: 'Ibiquera', value: 'Ibiquera' },
  { label: 'Ibirá', value: 'Ibirá' },
  { label: 'Ibiracatu', value: 'Ibiracatu' },
  { label: 'Ibiraci', value: 'Ibiraci' },
  { label: 'Ibiraçu', value: 'Ibiraçu' },
  { label: 'Ibiraiaras', value: 'Ibiraiaras' },
  { label: 'Ibirajuba', value: 'Ibirajuba' },
  { label: 'Ibirama', value: 'Ibirama' },
  { label: 'Ibirapitanga', value: 'Ibirapitanga' },
  { label: 'Ibirapuã', value: 'Ibirapuã' },
  { label: 'Ibirapuitã', value: 'Ibirapuitã' },
  { label: 'Ibirarema', value: 'Ibirarema' },
  { label: 'Ibirataia', value: 'Ibirataia' },
  { label: 'Ibirité', value: 'Ibirité' },
  { label: 'Ibirubá', value: 'Ibirubá' },
  { label: 'Ibitiara', value: 'Ibitiara' },
  { label: 'Ibitinga', value: 'Ibitinga' },
  { label: 'Ibitirama', value: 'Ibitirama' },
  { label: 'Ibititá', value: 'Ibititá' },
  { label: 'Ibitiúra De Minas', value: 'Ibitiúra De Minas' },
  { label: 'Ibituruna', value: 'Ibituruna' },
  { label: 'Ibiúna', value: 'Ibiúna' },
  { label: 'Ibiza', value: 'Ibiza' },
  { label: 'Ibos', value: 'Ibos' },
  { label: 'Ibotirama', value: 'Ibotirama' },
  { label: 'Ibradı', value: 'Ibradı' },
  { label: 'Ibrahimpur', value: 'Ibrahimpur' },
  { label: 'Ibrány', value: 'Ibrány' },
  { label: 'Ibrányi Járás', value: 'Ibrányi Járás' },
  { label: 'Ibresi', value: 'Ibresi' },
  { label: 'Ibresinskiy Rayon', value: 'Ibresinskiy Rayon' },
  { label: 'Ibrī', value: 'Ibrī' },
  { label: 'Ibrianu', value: 'Ibrianu' },
  { label: 'Ibrillos', value: 'Ibrillos' },
  { label: 'Ibros', value: 'Ibros' },
  { label: 'Ibshawāy', value: 'Ibshawāy' },
  { label: 'Ibstock', value: 'Ibstock' },
  { label: 'Ibṭīn', value: 'Ibṭīn' },
  { label: 'Ibung', value: 'Ibung' },
  { label: 'Ibung', value: 'Ibung' },
  { label: 'Ibusuki', value: 'Ibusuki' },
  { label: 'Ibusuki Shi', value: 'Ibusuki Shi' },
  { label: 'Ica', value: 'Ica' },
  { label: 'Icaño', value: 'Icaño' },
  { label: 'Icapuí', value: 'Icapuí' },
  { label: 'Içara', value: 'Içara' },
  { label: 'Icaraí De Minas', value: 'Icaraí De Minas' },
  { label: 'Icaraíma', value: 'Icaraíma' },
  { label: 'Icard', value: 'Icard' },
  { label: 'Icatu', value: 'Icatu' },
  { label: 'Icém', value: 'Icém' },
  { label: 'Ichalkaranji', value: 'Ichalkaranji' },
  { label: 'Ichán', value: 'Ichán' },
  { label: 'Ichapur', value: 'Ichapur' },
  { label: 'Ichchapuram', value: 'Ichchapuram' },
  { label: 'Ichenhausen', value: 'Ichenhausen' },
  { label: 'Icheon-Si', value: 'Icheon-Si' },
  { label: 'Icheu', value: 'Icheu' },
  { label: 'Ichhawar', value: 'Ichhawar' },
  { label: 'Ichihara', value: 'Ichihara' },
  { label: 'Ichihara Shi', value: 'Ichihara Shi' },
  { label: 'Ichikawa Shi', value: 'Ichikawa Shi' },
  { label: 'Ichikikushikino Shi', value: 'Ichikikushikino Shi' },
  { label: 'Ichinohe', value: 'Ichinohe' },
  { label: 'Ichinomiya', value: 'Ichinomiya' },
  { label: 'Ichinomiya-Shi', value: 'Ichinomiya-Shi' },
  { label: 'Ichinoseki', value: 'Ichinoseki' },
  { label: 'Ichinoseki-Shi', value: 'Ichinoseki-Shi' },
  { label: 'Ichnya', value: 'Ichnya' },
  { label: 'Ichon', value: 'Ichon' },
  { label: 'Ichtegem', value: 'Ichtegem' },
  { label: 'Ichtershausen', value: 'Ichtershausen' },
  { label: 'Ichu', value: 'Ichu' },
  { label: 'Icking', value: 'Icking' },
  { label: 'Icland', value: 'Icland' },
  { label: 'Iclandu Mare', value: 'Iclandu Mare' },
  { label: 'Iclănzel', value: 'Iclănzel' },
  { label: 'Iclod', value: 'Iclod' },
  { label: 'Icó', value: 'Icó' },
  { label: 'Icoana', value: 'Icoana' },
  { label: 'Icoana', value: 'Icoana' },
  { label: 'Icod De Los Vinos', value: 'Icod De Los Vinos' },
  { label: 'Icolo E Bengo', value: 'Icolo E Bengo' },
  { label: 'Iconha', value: 'Iconha' },
  { label: 'Icononzo', value: 'Icononzo' },
  { label: 'Icușeni', value: 'Icușeni' },
  { label: 'Icuseşti', value: 'Icuseşti' },
  { label: 'Icxotitla', value: 'Icxotitla' },
  { label: 'Ida County', value: 'Ida County' },
  { label: 'Ida Grove', value: 'Ida Grove' },
  { label: 'Idabel', value: 'Idabel' },
  { label: 'Idah', value: 'Idah' },
  { label: 'Idaho City', value: 'Idaho City' },
  { label: 'Idaho County', value: 'Idaho County' },
  { label: 'Idaho Falls', value: 'Idaho Falls' },
  { label: 'Idaho Springs', value: 'Idaho Springs' },
  { label: 'Idalia', value: 'Idalia' },
  { label: 'Idalou', value: 'Idalou' },
  { label: 'Idanha-A-Nova', value: 'Idanha-A-Nova' },
  { label: 'Idanre', value: 'Idanre' },
  { label: 'Idappadi', value: 'Idappadi' },
  { label: 'Idar', value: 'Idar' },
  { label: 'Idar-Oberstein', value: 'Idar-Oberstein' },
  { label: 'Ideciu De Jos', value: 'Ideciu De Jos' },
  { label: 'Idfū', value: 'Idfū' },
  { label: 'Idi Iroko', value: 'Idi Iroko' },
  { label: 'Idiazabal', value: 'Idiazabal' },
  { label: 'Idiazábal', value: 'Idiazábal' },
  { label: 'Idicel-Pădure', value: 'Idicel-Pădure' },
  { label: 'Idiciu', value: 'Idiciu' },
  { label: 'Idil', value: 'Idil' },
  { label: 'Idimu', value: 'Idimu' },
  { label: 'Idio', value: 'Idio' },
  { label: 'Idkū', value: 'Idkū' },
  { label: 'Idlib', value: 'Idlib' },
  { label: 'Idrī', value: 'Idrī' },
  { label: 'Idrifaia', value: 'Idrifaia' },
  { label: 'Idrija', value: 'Idrija' },
  { label: 'Idrinskiy Rayon', value: 'Idrinskiy Rayon' },
  { label: 'Idrinskoye', value: 'Idrinskoye' },
  { label: 'Idritsa', value: 'Idritsa' },
  { label: 'Idro', value: 'Idro' },
  { label: 'Idron', value: 'Idron' },
  { label: 'Idstein', value: 'Idstein' },
  { label: 'Idtig', value: 'Idtig' },
  { label: 'Idtig', value: 'Idtig' },
  { label: 'Idukki', value: 'Idukki' },
  { label: 'Idvor', value: 'Idvor' },
  { label: 'Idyllwild', value: 'Idyllwild' },
  { label: 'Idyllwild-Pine Cove', value: 'Idyllwild-Pine Cove' },
  { label: 'Idylwood', value: 'Idylwood' },
  { label: 'Iecava', value: 'Iecava' },
  { label: 'Iecea Mare', value: 'Iecea Mare' },
  { label: 'Iecea Mică', value: 'Iecea Mică' },
  { label: 'Iedera De Jos', value: 'Iedera De Jos' },
  { label: 'Iedera De Sus', value: 'Iedera De Sus' },
  { label: 'Ielmo Marinho', value: 'Ielmo Marinho' },
  { label: 'Iemilivka', value: 'Iemilivka' },
  { label: 'Iepê', value: 'Iepê' },
  { label: 'Ieper', value: 'Ieper' },
  { label: 'Iepureşti', value: 'Iepureşti' },
  { label: 'Ierápetra', value: 'Ierápetra' },
  { label: 'Ierissós', value: 'Ierissós' },
  { label: 'Iernut', value: 'Iernut' },
  { label: 'Iernuțeni', value: 'Iernuțeni' },
  { label: 'Iesolo', value: 'Iesolo' },
  { label: 'Ieud', value: 'Ieud' },
  { label: 'Ievgenivka', value: 'Ievgenivka' },
  { label: 'Iezeru', value: 'Iezeru' },
  { label: 'Ifakara', value: 'Ifakara' },
  { label: 'Ifaki', value: 'Ifaki' },
  { label: 'Ifako-Ijaiye', value: 'Ifako-Ijaiye' },
  { label: 'Iffeldorf', value: 'Iffeldorf' },
  { label: 'Iffendic', value: 'Iffendic' },
  { label: 'Iffezheim', value: 'Iffezheim' },
  { label: 'Iffou', value: 'Iffou' },
  { label: 'Ifo', value: 'Ifo' },
  { label: 'Ifon', value: 'Ifon' },
  { label: 'Ifrane', value: 'Ifrane' },
  { label: 'Ifrane', value: 'Ifrane' },
  { label: 'Ifs', value: 'Ifs' },
  { label: 'Ifta', value: 'Ifta' },
  { label: 'Ig', value: 'Ig' },
  { label: 'Igaci', value: 'Igaci' },
  { label: 'Igando', value: 'Igando' },
  { label: 'Igang', value: 'Igang' },
  { label: 'Iganga', value: 'Iganga' },
  { label: 'Igantzi', value: 'Igantzi' },
  { label: 'Igaporã', value: 'Igaporã' },
  { label: 'Igaraçu Do Tietê', value: 'Igaraçu Do Tietê' },
  { label: 'Igaracy', value: 'Igaracy' },
  { label: 'Igarapava', value: 'Igarapava' },
  { label: 'Igarapé', value: 'Igarapé' },
  { label: 'Igarapé Açu', value: 'Igarapé Açu' },
  { label: 'Igarapé Do Meio', value: 'Igarapé Do Meio' },
  { label: 'Igarapé Grande', value: 'Igarapé Grande' },
  { label: 'Igarapé Miri', value: 'Igarapé Miri' },
  { label: 'Igarapé-Açu', value: 'Igarapé-Açu' },
  { label: 'Igarapé-Miri', value: 'Igarapé-Miri' },
  { label: 'Igarassu', value: 'Igarassu' },
  { label: 'Igaratá', value: 'Igaratá' },
  { label: 'Igaratinga', value: 'Igaratinga' },
  { label: 'Igarka', value: 'Igarka' },
  { label: 'Igarra', value: 'Igarra' },
  { label: 'Iga-Shi', value: 'Iga-Shi' },
  { label: 'Igatpuri', value: 'Igatpuri' },
  { label: 'Igbara-Odo', value: 'Igbara-Odo' },
  { label: 'Igbaras', value: 'Igbaras' },
  { label: 'Igbeti', value: 'Igbeti' },
  { label: 'Igboho', value: 'Igboho' },
  { label: 'Igbon', value: 'Igbon' },
  { label: 'Igbo-Ora', value: 'Igbo-Ora' },
  { label: 'Igbor', value: 'Igbor' },
  { label: 'Igbo-Ukwu', value: 'Igbo-Ukwu' },
  { label: 'Igcocolo', value: 'Igcocolo' },
  { label: 'Igea', value: 'Igea' },
  { label: 'Igede-Ekiti', value: 'Igede-Ekiti' },
  { label: 'Igel', value: 'Igel' },
  { label: 'Igensdorf', value: 'Igensdorf' },
  { label: 'Igersheim', value: 'Igersheim' },
  { label: 'Igești', value: 'Igești' },
  { label: 'Iggensbach', value: 'Iggensbach' },
  { label: 'Iggesund', value: 'Iggesund' },
  { label: 'Iggingen', value: 'Iggingen' },
  { label: 'Ighiel', value: 'Ighiel' },
  { label: 'Ighișu Nou', value: 'Ighișu Nou' },
  { label: 'Ighiu', value: 'Ighiu' },
  { label: 'Ighram', value: 'Ighram' },
  { label: 'Igis', value: 'Igis' },
  { label: 'Iglas', value: 'Iglas' },
  { label: 'Iglesiarrubia', value: 'Iglesiarrubia' },
  { label: 'Iglesias', value: 'Iglesias' },
  { label: 'Iglesias', value: 'Iglesias' },
  { label: 'Iglesuela Del Cid La', value: 'Iglesuela Del Cid La' },
  { label: 'Iglesuela La', value: 'Iglesuela La' },
  { label: 'Igliano', value: 'Igliano' },
  { label: 'Iglino', value: 'Iglino' },
  { label: 'Igls', value: 'Igls' },
  { label: 'Igmaya-An', value: 'Igmaya-An' },
  { label: 'Ignacio Allende', value: 'Ignacio Allende' },
  { label: 'Ignacio Allende', value: 'Ignacio Allende' },
  { label: 'Ignacio Allende', value: 'Ignacio Allende' },
  { label: 'Ignacio Allende', value: 'Ignacio Allende' },
  { label: 'Ignacio Allende (Concepción)', value: 'Ignacio Allende (Concepción)' },
  { label: 'Ignacio Bastida (Santa Catarina Tlayca)', value: 'Ignacio Bastida (Santa Catarina Tlayca)' },
  { label: 'Ignacio De La Llave', value: 'Ignacio De La Llave' },
  { label: 'Ignacio Gutiérrez Gómez', value: 'Ignacio Gutiérrez Gómez' },
  { label: 'Ignacio López Rayón', value: 'Ignacio López Rayón' },
  { label: 'Ignacio Manuel Altamirano', value: 'Ignacio Manuel Altamirano' },
  { label: 'Ignacio Pérez', value: 'Ignacio Pérez' },
  { label: 'Ignacio Ramírez', value: 'Ignacio Ramírez' },
  { label: 'Ignacio Ramírez', value: 'Ignacio Ramírez' },
  { label: 'Ignacio Zaragoza', value: 'Ignacio Zaragoza' },
  { label: 'Ignacio Zaragoza', value: 'Ignacio Zaragoza' },
  { label: 'Ignacio Zaragoza', value: 'Ignacio Zaragoza' },
  { label: 'Ignacio Zaragoza', value: 'Ignacio Zaragoza' },
  { label: 'Ignacio Zaragoza', value: 'Ignacio Zaragoza' },
  { label: 'Ignacio Zaragoza', value: 'Ignacio Zaragoza' },
  { label: 'Ignacio Zaragoza', value: 'Ignacio Zaragoza' },
  { label: 'Ignacio Zaragoza', value: 'Ignacio Zaragoza' },
  { label: 'Ignacio Zaragoza', value: 'Ignacio Zaragoza' },
  { label: 'Ignacio Zaragoza', value: 'Ignacio Zaragoza' },
  { label: 'Ignacio Zaragoza', value: 'Ignacio Zaragoza' },
  { label: 'Ignacio Zaragoza (El Morro)', value: 'Ignacio Zaragoza (El Morro)' },
  { label: 'Ignalina', value: 'Ignalina' },
  { label: 'Ignatovka', value: 'Ignatovka' },
  { label: 'Igneşti', value: 'Igneşti' },
  { label: 'Igney', value: 'Igney' },
  { label: 'Ignit', value: 'Ignit' },
  { label: 'Ignit', value: 'Ignit' },
  { label: 'Ignit', value: 'Ignit' },
  { label: 'Igny', value: 'Igny' },
  { label: 'Igoiu', value: 'Igoiu' },
  { label: 'Igon', value: 'Igon' },
  { label: 'Igorre', value: 'Igorre' },
  { label: 'Igoumenítsa', value: 'Igoumenítsa' },
  { label: 'Igoville', value: 'Igoville' },
  { label: 'Igpit', value: 'Igpit' },
  { label: 'Igra', value: 'Igra' },
  { label: 'Igrapiúna', value: 'Igrapiúna' },
  { label: 'Igreja', value: 'Igreja' },
  { label: 'Igreja Nova', value: 'Igreja Nova' },
  { label: 'Igreja Nova', value: 'Igreja Nova' },
  { label: 'Igrejinha', value: 'Igrejinha' },
  { label: 'Igriés', value: 'Igriés' },
  { label: 'Igrim', value: 'Igrim' },
  { label: 'Igriș', value: 'Igriș' },
  { label: 'Iguaba Grande', value: 'Iguaba Grande' },
  { label: 'Iguaí', value: 'Iguaí' },
  { label: 'Iguala De La Independencia', value: 'Iguala De La Independencia' },
  { label: 'Igualada', value: 'Igualada' },
  { label: 'Igualapa', value: 'Igualapa' },
  { label: 'Igualeja', value: 'Igualeja' },
  { label: 'Iguape', value: 'Iguape' },
  { label: 'Iguaraçu', value: 'Iguaraçu' },
  { label: 'Iguaracy', value: 'Iguaracy' },
  { label: 'Iguatama', value: 'Iguatama' },
  { label: 'Iguatemi', value: 'Iguatemi' },
  { label: 'Iguatu', value: 'Iguatu' },
  { label: 'Iguatu', value: 'Iguatu' },
  { label: 'Igüeña', value: 'Igüeña' },
  { label: 'Igugunu', value: 'Igugunu' },
  { label: 'Iguig', value: 'Iguig' },
  { label: 'Iguig', value: 'Iguig' },
  { label: 'Igurubi', value: 'Igurubi' },
  { label: 'Igúzquiza', value: 'Igúzquiza' },
  { label: 'Ihiala', value: 'Ihiala' },
  { label: 'Ihlienworth', value: 'Ihlienworth' },
  { label: 'Iho', value: 'Iho' },
  { label: 'Ihod', value: 'Ihod' },
  { label: 'Ihringen', value: 'Ihringen' },
  { label: 'Ihrlerstein', value: 'Ihrlerstein' },
  { label: 'Ihsangazi', value: 'Ihsangazi' },
  { label: 'Ihsaniye', value: 'Ihsaniye' },
  { label: 'Ihtiman', value: 'Ihtiman' },
  { label: 'Ihuatzio', value: 'Ihuatzio' },
  { label: 'Ii', value: 'Ii' },
  { label: 'Iida', value: 'Iida' },
  { label: 'Iida', value: 'Iida' },
  { label: 'Iida-Shi', value: 'Iida-Shi' },
  { label: 'Iijima', value: 'Iijima' },
  { label: 'Iisaku', value: 'Iisaku' },
  { label: 'Iitti', value: 'Iitti' },
  { label: 'Iiyama', value: 'Iiyama' },
  { label: 'Iiyama', value: 'Iiyama' },
  { label: 'Iiyama Shi', value: 'Iiyama Shi' },
  { label: 'Iizuka', value: 'Iizuka' },
  { label: 'Iizuka Shi', value: 'Iizuka Shi' },
  { label: 'Iizuna', value: 'Iizuna' },
  { label: 'Ijaci', value: 'Ijaci' },
  { label: 'Ijburg', value: 'Ijburg' },
  { label: 'Ijebu-Ife', value: 'Ijebu-Ife' },
  { label: 'Ijebu-Igbo', value: 'Ijebu-Igbo' },
  { label: 'Ijebu-Jesa', value: 'Ijebu-Jesa' },
  { label: 'Ijebu-Ode', value: 'Ijebu-Ode' },
  { label: 'Ijero-Ekiti', value: 'Ijero-Ekiti' },
  { label: 'Ijevan', value: 'Ijevan' },
  { label: 'Ijivitari', value: 'Ijivitari' },
  { label: 'Ijlst', value: 'Ijlst' },
  { label: 'Ijmuiden', value: 'Ijmuiden' },
  { label: 'Ijora', value: 'Ijora' },
  { label: 'Ijrud', value: 'Ijrud' },
  { label: 'Ijsselstein', value: 'Ijsselstein' },
  { label: 'Ijuí', value: 'Ijuí' },
  { label: 'Ijūin', value: 'Ijūin' },
  { label: 'Ijuw', value: 'Ijuw' },
  { label: 'Ijzendijke', value: 'Ijzendijke' },
  { label: 'Ikaalinen', value: 'Ikaalinen' },
  { label: 'Ikama', value: 'Ikama' },
  { label: 'Ikang', value: 'Ikang' },
  { label: 'Ikast', value: 'Ikast' },
  { label: 'Ikast-Brande Kommune', value: 'Ikast-Brande Kommune' },
  { label: 'Ikauna', value: 'Ikauna' },
  { label: 'Ikaztegieta', value: 'Ikaztegieta' },
  { label: 'Ikeda', value: 'Ikeda' },
  { label: 'Ikeda', value: 'Ikeda' },
  { label: 'Ikeda Shi', value: 'Ikeda Shi' },
  { label: 'Ikedachō', value: 'Ikedachō' },
  { label: 'Ikeja', value: 'Ikeja' },
  { label: 'Ikem', value: 'Ikem' },
  { label: 'Ikere-Ekiti', value: 'Ikere-Ekiti' },
  { label: 'Ikey', value: 'Ikey' },
  { label: 'Iki Shi', value: 'Iki Shi' },
  { label: 'Iki-Burul', value: 'Iki-Burul' },
  { label: 'Iki-Burul’Skiy Rayon', value: 'Iki-Burul’Skiy Rayon' },
  { label: 'Ikinga', value: 'Ikinga' },
  { label: 'Ikire', value: 'Ikire' },
  { label: 'Ikirun', value: 'Ikirun' },
  { label: 'Ikizce', value: 'Ikizce' },
  { label: 'Ikizdere', value: 'Ikizdere' },
  { label: 'Iklad', value: 'Iklad' },
  { label: 'Iklehra', value: 'Iklehra' },
  { label: 'Ikolo', value: 'Ikolo' },
  { label: 'Ikoma', value: 'Ikoma' },
  { label: 'Ikoma-Shi', value: 'Ikoma-Shi' },
  { label: 'Ikon-Khalk', value: 'Ikon-Khalk' },
  { label: 'Ikorodu', value: 'Ikorodu' },
  { label: 'Ikot Ekpene', value: 'Ikot Ekpene' },
  { label: 'Ikovka', value: 'Ikovka' },
  { label: 'Ikryanoye', value: 'Ikryanoye' },
  { label: 'Iksāl', value: 'Iksāl' },
  { label: 'Iksan', value: 'Iksan' },
  { label: 'Iksan-Si', value: 'Iksan-Si' },
  { label: 'Iksha', value: 'Iksha' },
  { label: 'Ikšķile', value: 'Ikšķile' },
  { label: 'Ikungi', value: 'Ikungi' },
  { label: 'Ikusaka', value: 'Ikusaka' },
  { label: 'Ikuti', value: 'Ikuti' },
  { label: 'Ikwiriri', value: 'Ikwiriri' },
  { label: 'Il Romito', value: 'Il Romito' },
  { label: 'Il’Ich', value: 'Il’Ich' },
  { label: 'Il’Ichevo', value: 'Il’Ichevo' },
  { label: 'Il’Ichëvo', value: 'Il’Ichëvo' },
  { label: 'Il’Inka', value: 'Il’Inka' },
  { label: 'Il’Inskiy', value: 'Il’Inskiy' },
  { label: 'Il’Inskiy Pogost', value: 'Il’Inskiy Pogost' },
  { label: 'Il’Insko-Podomskoye', value: 'Il’Insko-Podomskoye' },
  { label: 'Il’Inskoye', value: 'Il’Inskoye' },
  { label: 'Il’Inskoye', value: 'Il’Inskoye' },
  { label: 'Il’Inskoye-Khovanskoye', value: 'Il’Inskoye-Khovanskoye' },
  { label: 'Il’Ka', value: 'Il’Ka' },
  { label: 'Il’Skiy', value: 'Il’Skiy' },
  { label: 'Il’Ya', value: 'Il’Ya' },
  { label: 'Ila Orangun', value: 'Ila Orangun' },
  { label: 'Ilabaya', value: 'Ilabaya' },
  { label: 'Ilagan', value: 'Ilagan' },
  { label: 'Ilagan', value: 'Ilagan' },
  { label: 'Ilala', value: 'Ilala' },
  { label: 'Ilam', value: 'Ilam' },
  { label: 'Ilam', value: 'Ilam' },
  { label: 'Ilama', value: 'Ilama' },
  { label: 'Ilamatlán', value: 'Ilamatlán' },
  { label: 'Ilampillai', value: 'Ilampillai' },
  { label: 'Ilandža', value: 'Ilandža' },
  { label: 'Ilangay', value: 'Ilangay' },
  { label: 'Ilangay', value: 'Ilangay' },
  { label: 'Ilangay', value: 'Ilangay' },
  { label: 'Ilanskiy', value: 'Ilanskiy' },
  { label: 'Ilanskiy Rayon', value: 'Ilanskiy Rayon' },
  { label: 'Ilanz', value: 'Ilanz' },
  { label: 'Ilare', value: 'Ilare' },
  { label: 'Ilaro', value: 'Ilaro' },
  { label: 'Ilaskhan-Yurt', value: 'Ilaskhan-Yurt' },
  { label: 'Ilava', value: 'Ilava' },
  { label: 'Ilave', value: 'Ilave' },
  { label: 'Iława', value: 'Iława' },
  { label: 'Ilaya', value: 'Ilaya' },
  { label: 'Ilaya', value: 'Ilaya' },
  { label: 'Ilaya', value: 'Ilaya' },
  { label: 'Ilba', value: 'Ilba' },
  { label: 'Ilberstedt', value: 'Ilberstedt' },
  { label: 'Ilbesheim', value: 'Ilbesheim' },
  { label: 'Ilbono', value: 'Ilbono' },
  { label: 'Ilche', value: 'Ilche' },
  { label: 'Ilchester', value: 'Ilchester' },
  { label: 'Ilchester', value: 'Ilchester' },
  { label: 'Ile Des Chênes', value: 'Ile Des Chênes' },
  { label: 'Ileana', value: 'Ileana' },
  { label: 'Ileanda', value: 'Ileanda' },
  { label: 'Ilebo', value: 'Ilebo' },
  { label: 'Ile-Ife', value: 'Ile-Ife' },
  { label: 'Ilek', value: 'Ilek' },
  { label: 'Ilembe District Municipality', value: 'Ilembe District Municipality' },
  { label: 'Ilembo', value: 'Ilembo' },
  { label: 'Ilembula', value: 'Ilembula' },
  { label: 'Ilemela District', value: 'Ilemela District' },
  { label: 'Iles', value: 'Iles' },
  { label: 'Ilesa', value: 'Ilesa' },
  { label: 'Ileza', value: 'Ileza' },
  { label: 'Ilfeld', value: 'Ilfeld' },
  { label: 'Ilfracombe', value: 'Ilfracombe' },
  { label: 'Ilgaz', value: 'Ilgaz' },
  { label: 'Ilgın', value: 'Ilgın' },
  { label: 'Ilha Comprida', value: 'Ilha Comprida' },
  { label: 'Ilha Das Flores', value: 'Ilha Das Flores' },
  { label: 'Ilha De Itamaracá', value: 'Ilha De Itamaracá' },
  { label: 'Ilha De Moçambique', value: 'Ilha De Moçambique' },
  { label: 'Ilha Grande', value: 'Ilha Grande' },
  { label: 'Ilha Grande', value: 'Ilha Grande' },
  { label: 'Ilha Solteira', value: 'Ilha Solteira' },
  { label: 'Ilhabela', value: 'Ilhabela' },
  { label: 'Ílhavo', value: 'Ílhavo' },
  { label: 'Ilhéus', value: 'Ilhéus' },
  { label: 'Ilhota', value: 'Ilhota' },
  { label: 'Ili Kazak Zizhizhou', value: 'Ili Kazak Zizhizhou' },
  { label: 'Ilia', value: 'Ilia' },
  { label: 'Iliatenco', value: 'Iliatenco' },
  { label: 'Iliç', value: 'Iliç' },
  { label: 'Ilići', value: 'Ilići' },
  { label: 'Ilicínea', value: 'Ilicínea' },
  { label: 'Ilieni', value: 'Ilieni' },
  { label: 'Ilieni', value: 'Ilieni' },
  { label: 'Ilieși', value: 'Ilieși' },
  { label: 'Iligan City', value: 'Iligan City' },
  { label: 'Ilihan', value: 'Ilihan' },
  { label: 'Ilihan', value: 'Ilihan' },
  { label: 'Ilijaš', value: 'Ilijaš' },
  { label: 'Ilinden', value: 'Ilinden' },
  { label: 'Ilinge', value: 'Ilinge' },
  { label: 'Ilioara', value: 'Ilioara' },
  { label: 'Ilioilio', value: 'Ilioilio' },
  { label: 'Ilioilio', value: 'Ilioilio' },
  { label: 'Iliokentima', value: 'Iliokentima' },
  { label: 'Iliomar', value: 'Iliomar' },
  { label: 'Ilion', value: 'Ilion' },
  { label: 'Ílion', value: 'Ílion' },
  { label: 'Ilioúpoli', value: 'Ilioúpoli' },
  { label: 'Ilir', value: 'Ilir' },
  { label: 'Ilirska Bistrica', value: 'Ilirska Bistrica' },
  { label: 'Ilișești', value: 'Ilișești' },
  { label: 'Ilkadım', value: 'Ilkadım' },
  { label: 'Ilkal', value: 'Ilkal' },
  { label: 'Ilkeston', value: 'Ilkeston' },
  { label: 'Ilkhichi', value: 'Ilkhichi' },
  { label: 'Ilkley', value: 'Ilkley' },
  { label: 'Illa De Arousa A', value: 'Illa De Arousa A' },
  { label: 'Illán De Vacas', value: 'Illán De Vacas' },
  { label: 'Illana', value: 'Illana' },
  { label: 'Illange', value: 'Illange' },
  { label: 'Illano', value: 'Illano' },
  { label: 'Illapel', value: 'Illapel' },
  { label: 'Illar', value: 'Illar' },
  { label: 'Illas', value: 'Illas' },
  { label: 'Illasi', value: 'Illasi' },
  { label: 'Illats', value: 'Illats' },
  { label: 'Illawong', value: 'Illawong' },
  { label: 'Illela', value: 'Illela' },
  { label: 'Illéla', value: 'Illéla' },
  { label: 'Illerrieden', value: 'Illerrieden' },
  { label: 'Illertissen', value: 'Illertissen' },
  { label: 'Illes Balears', value: 'Illes Balears' },
  { label: 'Illescas', value: 'Illescas' },
  { label: 'Illescas', value: 'Illescas' },
  { label: 'Ille-Sur-Têt', value: 'Ille-Sur-Têt' },
  { label: 'Illfurth', value: 'Illfurth' },
  { label: 'Illies', value: 'Illies' },
  { label: 'Illingen', value: 'Illingen' },
  { label: 'Illingen', value: 'Illingen' },
  { label: 'Illintsi', value: 'Illintsi' },
  { label: 'Illizi', value: 'Illizi' },
  { label: 'Illkirch-Graffenstaden', value: 'Illkirch-Graffenstaden' },
  { label: 'Illmensee', value: 'Illmensee' },
  { label: 'Illmitz', value: 'Illmitz' },
  { label: 'Illnau', value: 'Illnau' },
  { label: 'Illnau / Unter-Illnau', value: 'Illnau / Unter-Illnau' },
  { label: 'Illora', value: 'Illora' },
  { label: 'Illorai', value: 'Illorai' },
  { label: 'Illschwang', value: 'Illschwang' },
  { label: 'Illubabor Zone', value: 'Illubabor Zone' },
  { label: 'Illueca', value: 'Illueca' },
  { label: 'Illushi', value: 'Illushi' },
  { label: 'Illzach', value: 'Illzach' },
  { label: 'Ilmajoki', value: 'Ilmajoki' },
  { label: 'Ilmenau', value: 'Ilmenau' },
  { label: 'Ilminster', value: 'Ilminster' },
  { label: 'Ilmmünster', value: 'Ilmmünster' },
  { label: 'Ilo', value: 'Ilo' },
  { label: 'Ilobu', value: 'Ilobu' },
  { label: 'Ilog', value: 'Ilog' },
  { label: 'Iloilo', value: 'Iloilo' },
  { label: 'Ilok', value: 'Ilok' },
  { label: 'Ilomantsi', value: 'Ilomantsi' },
  { label: 'Ilongero', value: 'Ilongero' },
  { label: 'Ilopango', value: 'Ilopango' },
  { label: 'Ilópolis', value: 'Ilópolis' },
  { label: 'Ilorin', value: 'Ilorin' },
  { label: 'Ilovăț', value: 'Ilovăț' },
  { label: 'Ilovays’K', value: 'Ilovays’K' },
  { label: 'Ilovica', value: 'Ilovica' },
  { label: 'Iloviţa', value: 'Iloviţa' },
  { label: 'Ilovka', value: 'Ilovka' },
  { label: 'Ilovlya', value: 'Ilovlya' },
  { label: 'Iłów', value: 'Iłów' },
  { label: 'Iłowa', value: 'Iłowa' },
  { label: 'Iłowo -Osada', value: 'Iłowo -Osada' },
  { label: 'Ilpendam', value: 'Ilpendam' },
  { label: 'Ilsede', value: 'Ilsede' },
  { label: 'Ilsenburg', value: 'Ilsenburg' },
  { label: 'Ilsfeld', value: 'Ilsfeld' },
  { label: 'Ilshofen', value: 'Ilshofen' },
  { label: 'Iluka', value: 'Iluka' },
  { label: 'Iluka', value: 'Iluka' },
  { label: 'Ilūkste', value: 'Ilūkste' },
  { label: 'Ilula', value: 'Ilula' },
  { label: 'Iluppur', value: 'Iluppur' },
  { label: 'Ilva Mare', value: 'Ilva Mare' },
  { label: 'Ilva Mică', value: 'Ilva Mică' },
  { label: 'Ilvesheim', value: 'Ilvesheim' },
  { label: 'Ilychyovo', value: 'Ilychyovo' },
  { label: 'Ilyichevo', value: 'Ilyichevo' },
  { label: 'Ilyinka', value: 'Ilyinka' },
  { label: 'Ilyinogorsk', value: 'Ilyinogorsk' },
  { label: 'Ilz', value: 'Ilz' },
  { label: 'Iłża', value: 'Iłża' },
  { label: 'Imabari-Shi', value: 'Imabari-Shi' },
  { label: 'Imaculada', value: 'Imaculada' },
  { label: 'Imaichi', value: 'Imaichi' },
  { label: 'Imam', value: 'Imam' },
  { label: 'Imam Hassan', value: 'Imam Hassan' },
  { label: 'Imam Qasim', value: 'Imam Qasim' },
  { label: 'Imām Şāḩib', value: 'Imām Şāḩib' },
  { label: 'Imamoğlu', value: 'Imamoğlu' },
  { label: 'Imanshahr', value: 'Imanshahr' },
  { label: 'Imari Shi', value: 'Imari Shi' },
  { label: 'Imarichō-Kō', value: 'Imarichō-Kō' },
  { label: 'Imaruí', value: 'Imaruí' },
  { label: 'Imatra', value: 'Imatra' },
  { label: 'Imbang', value: 'Imbang' },
  { label: 'Imbatug', value: 'Imbatug' },
  { label: 'Imbaú', value: 'Imbaú' },
  { label: 'Imbé', value: 'Imbé' },
  { label: 'Imbé De Minas', value: 'Imbé De Minas' },
  { label: 'Imbersago', value: 'Imbersago' },
  { label: 'Imbert', value: 'Imbert' },
  { label: 'Imbery', value: 'Imbery' },
  { label: 'Imbituba', value: 'Imbituba' },
  { label: 'Imbituva', value: 'Imbituva' },
  { label: 'Imbonggu', value: 'Imbonggu' },
  { label: 'Imbuia', value: 'Imbuia' },
  { label: 'Imdina', value: 'Imdina' },
  { label: 'Imeko', value: 'Imeko' },
  { label: 'Imelda', value: 'Imelda' },
  { label: 'Imelda', value: 'Imelda' },
  { label: 'Imeni Babushkina', value: 'Imeni Babushkina' },
  { label: 'Imeni M. I. Kalinina', value: 'Imeni M. I. Kalinina' },
  { label: 'Imeni Morozova', value: 'Imeni Morozova' },
  { label: 'Imeni Pervogo Maya', value: 'Imeni Pervogo Maya' },
  { label: 'Imeni Poliny Osipenko', value: 'Imeni Poliny Osipenko' },
  { label: 'Imeni Stepana Razina', value: 'Imeni Stepana Razina' },
  { label: 'Imeni Sverdlova', value: 'Imeni Sverdlova' },
  { label: 'Imeni Tsyurupy', value: 'Imeni Tsyurupy' },
  { label: 'Imeni Vladimira Il’Icha Lenina', value: 'Imeni Vladimira Il’Icha Lenina' },
  { label: 'Imeni Vorovskogo', value: 'Imeni Vorovskogo' },
  { label: 'Imeni Vorovskogo', value: 'Imeni Vorovskogo' },
  { label: 'Imeni Zhelyabova', value: 'Imeni Zhelyabova' },
  { label: 'Imeong Hamlet', value: 'Imeong Hamlet' },
  { label: 'Imer', value: 'Imer' },
  { label: 'Imġarr', value: 'Imġarr' },
  { label: 'Imielin', value: 'Imielin' },
  { label: 'Imielno', value: 'Imielno' },
  { label: 'Imigrante', value: 'Imigrante' },
  { label: 'Imilchil', value: 'Imilchil' },
  { label: 'Imishli', value: 'Imishli' },
  { label: 'Imizu Shi', value: 'Imizu Shi' },
  { label: 'Imlay City', value: 'Imlay City' },
  { label: 'Imlili', value: 'Imlili' },
  { label: 'Immelborn', value: 'Immelborn' },
  { label: 'Immendingen', value: 'Immendingen' },
  { label: 'Immenhausen', value: 'Immenhausen' },
  { label: 'Immenreuth', value: 'Immenreuth' },
  { label: 'Immenstaad Am Bodensee', value: 'Immenstaad Am Bodensee' },
  { label: 'Immenstadt Im Allgäu', value: 'Immenstadt Im Allgäu' },
  { label: 'Immingham', value: 'Immingham' },
  { label: 'Immokalee', value: 'Immokalee' },
  { label: 'Imola', value: 'Imola' },
  { label: 'Imotski', value: 'Imotski' },
  { label: 'Imotz', value: 'Imotz' },
  { label: 'Impalutao', value: 'Impalutao' },
  { label: 'Imper', value: 'Imper' },
  { label: 'Imperatriz', value: 'Imperatriz' },
  { label: 'Imperia', value: 'Imperia' },
  { label: 'Imperial', value: 'Imperial' },
  { label: 'Imperial', value: 'Imperial' },
  { label: 'Imperial', value: 'Imperial' },
  { label: 'Imperial', value: 'Imperial' },
  { label: 'Imperial', value: 'Imperial' },
  { label: 'Imperial Beach', value: 'Imperial Beach' },
  { label: 'Imperial County', value: 'Imperial County' },
  { label: 'Impfondo', value: 'Impfondo' },
  { label: 'Imphal East', value: 'Imphal East' },
  { label: 'Imphal West', value: 'Imphal West' },
  { label: 'Imphy', value: 'Imphy' },
  { label: 'Imposte', value: 'Imposte' },
  { label: 'Impruneta', value: 'Impruneta' },
  { label: 'Imranlı', value: 'Imranlı' },
  { label: 'Imsbach', value: 'Imsbach' },
  { label: 'Imsida', value: 'Imsida' },
  { label: 'Imsil', value: 'Imsil' },
  { label: 'Imsil-Gun', value: 'Imsil-Gun' },
  { label: 'Imst', value: 'Imst' },
  { label: 'Imsterberg', value: 'Imsterberg' },
  { label: 'Imtarfa', value: 'Imtarfa' },
  { label: 'Imués', value: 'Imués' },
  { label: 'Imuris', value: 'Imuris' },
  { label: 'Imus', value: 'Imus' },
  { label: 'Imzouren', value: 'Imzouren' },
  { label: 'In Buri', value: 'In Buri' },
  { label: 'Ina', value: 'Ina' },
  { label: 'Ina', value: 'Ina' },
  { label: 'Ina', value: 'Ina' },
  { label: 'Inabaan Sur', value: 'Inabaan Sur' },
  { label: 'Inabaan Sur', value: 'Inabaan Sur' },
  { label: 'Inabanga', value: 'Inabanga' },
  { label: 'Inabe', value: 'Inabe' },
  { label: 'Inabe-Shi', value: 'Inabe-Shi' },
  { label: 'Inácio Martins', value: 'Inácio Martins' },
  { label: 'Inaciolândia', value: 'Inaciolândia' },
  { label: 'Inagi-Shi', value: 'Inagi-Shi' },
  { label: 'Inajá', value: 'Inajá' },
  { label: 'Inajá', value: 'Inajá' },
  { label: 'Inala', value: 'Inala' },
  { label: 'Inangatan', value: 'Inangatan' },
  { label: 'Inangatan', value: 'Inangatan' },
  { label: 'Inapatan', value: 'Inapatan' },
  { label: 'Inárcs', value: 'Inárcs' },
  { label: 'Inari', value: 'Inari' },
  { label: 'Inarzo', value: 'Inarzo' },
  { label: 'Ina-Shi', value: 'Ina-Shi' },
  { label: 'Inashiki', value: 'Inashiki' },
  { label: 'Inashiki-Shi', value: 'Inashiki-Shi' },
  { label: 'Inău', value: 'Inău' },
  { label: 'Inawashiro', value: 'Inawashiro' },
  { label: 'Inawayan', value: 'Inawayan' },
  { label: 'Inawayan', value: 'Inawayan' },
  { label: 'Inawayan', value: 'Inawayan' },
  { label: 'Inayagan', value: 'Inayagan' },
  { label: 'Inayauan', value: 'Inayauan' },
  { label: 'Inazawa', value: 'Inazawa' },
  { label: 'Inazawa-Shi', value: 'Inazawa-Shi' },
  { label: 'Inca', value: 'Inca' },
  { label: 'Ince Blundell', value: 'Ince Blundell' },
  { label: 'Ince-In-Makerfield', value: 'Ince-In-Makerfield' },
  { label: 'Incesu', value: 'Incesu' },
  { label: 'Incheboron', value: 'Incheboron' },
  { label: 'Inchenhofen', value: 'Inchenhofen' },
  { label: 'Incheon', value: 'Incheon' },
  { label: 'Incheville', value: 'Incheville' },
  { label: 'Inchinnan', value: 'Inchinnan' },
  { label: 'Inchture', value: 'Inchture' },
  { label: 'Incio O', value: 'Incio O' },
  { label: 'Incirliova', value: 'Incirliova' },
  { label: 'Incisa In Val Darno', value: 'Incisa In Val Darno' },
  { label: 'Incisa Scapaccino', value: 'Incisa Scapaccino' },
  { label: 'Incline Village', value: 'Incline Village' },
  { label: 'Inconfidentes', value: 'Inconfidentes' },
  { label: 'Incourt', value: 'Incourt' },
  { label: 'Incudine', value: 'Incudine' },
  { label: 'Inčukalns', value: 'Inčukalns' },
  { label: 'Inda Chhoi', value: 'Inda Chhoi' },
  { label: 'Inda Silasē', value: 'Inda Silasē' },
  { label: 'Indaiabira', value: 'Indaiabira' },
  { label: 'Indaial', value: 'Indaial' },
  { label: 'Indaiatuba', value: 'Indaiatuba' },
  { label: 'Indanan', value: 'Indanan' },
  { label: 'Indang', value: 'Indang' },
  { label: 'Indaparapeo', value: 'Indaparapeo' },
  { label: 'Indapur', value: 'Indapur' },
  { label: 'Indé', value: 'Indé' },
  { label: 'Inden', value: 'Inden' },
  { label: 'Indénié-Djuablin', value: 'Indénié-Djuablin' },
  { label: 'Indented Head', value: 'Indented Head' },
  { label: 'Independence', value: 'Independence' },
  { label: 'Independence', value: 'Independence' },
  { label: 'Independence', value: 'Independence' },
  { label: 'Independence', value: 'Independence' },
  { label: 'Independence', value: 'Independence' },
  { label: 'Independence', value: 'Independence' },
  { label: 'Independence', value: 'Independence' },
  { label: 'Independence', value: 'Independence' },
  { label: 'Independence', value: 'Independence' },
  { label: 'Independence', value: 'Independence' },
  { label: 'Independence City', value: 'Independence City' },
  { label: 'Independence City', value: 'Independence City' },
  { label: 'Independence County', value: 'Independence County' },
  { label: 'Independencia', value: 'Independencia' },
  { label: 'Independencia', value: 'Independencia' },
  { label: 'Independencia', value: 'Independencia' },
  { label: 'Independencia', value: 'Independencia' },
  { label: 'Independencia', value: 'Independencia' },
  { label: 'Independencia', value: 'Independencia' },
  { label: 'Independencia', value: 'Independencia' },
  { label: 'Independência', value: 'Independência' },
  { label: 'Independência', value: 'Independência' },
  { label: 'Independent Hill', value: 'Independent Hill' },
  { label: 'Independenţa', value: 'Independenţa' },
  { label: 'Independenţa', value: 'Independenţa' },
  { label: 'Independenţa', value: 'Independenţa' },
  { label: 'Inderbor', value: 'Inderbor' },
  { label: 'Indergarh', value: 'Indergarh' },
  { label: 'Inderka', value: 'Inderka' },
  { label: 'Inderøy', value: 'Inderøy' },
  { label: 'Indi', value: 'Indi' },
  { label: 'India Hook', value: 'India Hook' },
  { label: 'Indialantic', value: 'Indialantic' },
  { label: 'Indian Harbour Beach', value: 'Indian Harbour Beach' },
  { label: 'Indian Head', value: 'Indian Head' },
  { label: 'Indian Head', value: 'Indian Head' },
  { label: 'Indian Head Park', value: 'Indian Head Park' },
  { label: 'Indian Heights', value: 'Indian Heights' },
  { label: 'Indian Hills', value: 'Indian Hills' },
  { label: 'Indian Hills', value: 'Indian Hills' },
  { label: 'Indian Hills', value: 'Indian Hills' },
  { label: 'Indian Hills', value: 'Indian Hills' },
  { label: 'Indian Hills Cherokee Section', value: 'Indian Hills Cherokee Section' },
  { label: 'Indian Mountain Lake', value: 'Indian Mountain Lake' },
  { label: 'Indian River', value: 'Indian River' },
  { label: 'Indian River County', value: 'Indian River County' },
  { label: 'Indian River Estates', value: 'Indian River Estates' },
  { label: 'Indian River Shores', value: 'Indian River Shores' },
  { label: 'Indian Rocks Beach', value: 'Indian Rocks Beach' },
  { label: 'Indian Shores', value: 'Indian Shores' },
  { label: 'Indian Springs', value: 'Indian Springs' },
  { label: 'Indian Springs Village', value: 'Indian Springs Village' },
  { label: 'Indian Trail', value: 'Indian Trail' },
  { label: 'Indian Wells', value: 'Indian Wells' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Indiana County', value: 'Indiana County' },
  { label: 'Indianapolis', value: 'Indianapolis' },
  { label: 'Indianola', value: 'Indianola' },
  { label: 'Indianola', value: 'Indianola' },
  { label: 'Indianola', value: 'Indianola' },
  { label: 'Indianópolis', value: 'Indianópolis' },
  { label: 'Indianópolis', value: 'Indianópolis' },
  { label: 'Indiantown', value: 'Indiantown' },
  { label: 'Indiaporã', value: 'Indiaporã' },
  { label: 'Indiara', value: 'Indiara' },
  { label: 'Indiaroba', value: 'Indiaroba' },
  { label: 'Indiavaí', value: 'Indiavaí' },
  { label: 'Indigo', value: 'Indigo' },
  { label: 'Inđija', value: 'Inđija' },
  { label: 'Indio', value: 'Indio' },
  { label: 'Indios', value: 'Indios' },
  { label: 'Indooroopilly', value: 'Indooroopilly' },
  { label: 'Indore', value: 'Indore' },
  { label: 'Indpur Community Development Block', value: 'Indpur Community Development Block' },
  { label: 'Indragarh', value: 'Indragarh' },
  { label: 'Indramayu', value: 'Indramayu' },
  { label: 'Indre', value: 'Indre' },
  { label: 'Indre Arna', value: 'Indre Arna' },
  { label: 'Indre Fosen', value: 'Indre Fosen' },
  { label: 'Indri', value: 'Indri' },
  { label: 'Indulang', value: 'Indulang' },
  { label: 'Induno Olona', value: 'Induno Olona' },
  { label: 'Indura', value: 'Indura' },
  { label: 'Industrial Estate', value: 'Industrial Estate' },
  { label: 'Industry', value: 'Industry' },
  { label: 'Industry', value: 'Industry' },
  { label: 'Ineangan', value: 'Ineangan' },
  { label: 'Ineangan', value: 'Ineangan' },
  { label: 'Inebolu', value: 'Inebolu' },
  { label: 'Inegöl', value: 'Inegöl' },
  { label: 'Inékar', value: 'Inékar' },
  { label: 'Ineu', value: 'Ineu' },
  { label: 'Ineu', value: 'Ineu' },
  { label: 'Ineu', value: 'Ineu' },
  { label: 'Inez', value: 'Inez' },
  { label: 'Inez', value: 'Inez' },
  { label: 'Inezgane', value: 'Inezgane' },
  { label: 'Inezgane-Ait Melloul', value: 'Inezgane-Ait Melloul' },
  { label: 'Infanta', value: 'Infanta' },
  { label: 'Infanta', value: 'Infanta' },
  { label: 'Infanta', value: 'Infanta' },
  { label: 'Infias', value: 'Infias' },
  { label: 'Infiernillo (Morelos De Infiernillo)', value: 'Infiernillo (Morelos De Infiernillo)' },
  { label: 'Ingá', value: 'Ingá' },
  { label: 'Ingå', value: 'Ingå' },
  { label: 'Ingaí', value: 'Ingaí' },
  { label: 'Ingalls', value: 'Ingalls' },
  { label: 'Ingalls Park', value: 'Ingalls Park' },
  { label: 'Ingared', value: 'Ingared' },
  { label: 'Ingatestone', value: 'Ingatestone' },
  { label: 'Ingazeira', value: 'Ingazeira' },
  { label: 'Ingelfingen', value: 'Ingelfingen' },
  { label: 'Ingelheim Am Rhein', value: 'Ingelheim Am Rhein' },
  { label: 'Ingelmunster', value: 'Ingelmunster' },
  { label: 'Ingelstad', value: 'Ingelstad' },
  { label: 'Ingen', value: 'Ingen' },
  { label: 'Ingenbohl', value: 'Ingenbohl' },
  { label: 'Ingeniero Guillermo N. Juárez', value: 'Ingeniero Guillermo N. Juárez' },
  { label: 'Ingeniero Jacobacci', value: 'Ingeniero Jacobacci' },
  { label: 'Ingeniero Luiggi', value: 'Ingeniero Luiggi' },
  { label: 'Ingeniero Luis A. Huergo', value: 'Ingeniero Luis A. Huergo' },
  { label: 'Ingenio', value: 'Ingenio' },
  { label: 'Ingenio', value: 'Ingenio' },
  { label: 'Ingenio La Esperanza', value: 'Ingenio La Esperanza' },
  { label: 'Ingersheim', value: 'Ingersheim' },
  { label: 'Ingersleben', value: 'Ingersleben' },
  { label: 'Ingersoll', value: 'Ingersoll' },
  { label: 'Ingham', value: 'Ingham' },
  { label: 'Ingham County', value: 'Ingham County' },
  { label: 'Ingle Farm', value: 'Ingle Farm' },
  { label: 'Ingleburn', value: 'Ingleburn' },
  { label: 'Ingleby Greenhow', value: 'Ingleby Greenhow' },
  { label: 'Ingleside', value: 'Ingleside' },
  { label: 'Ingleton', value: 'Ingleton' },
  { label: 'Inglewood', value: 'Inglewood' },
  { label: 'Inglewood', value: 'Inglewood' },
  { label: 'Inglewood-Finn Hill', value: 'Inglewood-Finn Hill' },
  { label: 'Inglis', value: 'Inglis' },
  { label: 'Ingoldingen', value: 'Ingoldingen' },
  { label: 'Ingoldmells', value: 'Ingoldmells' },
  { label: 'Ingolstadt', value: 'Ingolstadt' },
  { label: 'Ingraj Bazar', value: 'Ingraj Bazar' },
  { label: 'Ingram', value: 'Ingram' },
  { label: 'Ingram', value: 'Ingram' },
  { label: 'Ingrandes', value: 'Ingrandes' },
  { label: 'Ingrave', value: 'Ingrave' },
  { label: 'Ingré', value: 'Ingré' },
  { label: 'Ingria', value: 'Ingria' },
  { label: 'Inguiniel', value: 'Inguiniel' },
  { label: 'Ingwiller', value: 'Ingwiller' },
  { label: 'Inhacorá', value: 'Inhacorá' },
  { label: 'Inhambane', value: 'Inhambane' },
  { label: 'Inhambupe', value: 'Inhambupe' },
  { label: 'Inhangapi', value: 'Inhangapi' },
  { label: 'Inhapi', value: 'Inhapi' },
  { label: 'Inhapim', value: 'Inhapim' },
  { label: 'Inhaúma', value: 'Inhaúma' },
  { label: 'Inhisar', value: 'Inhisar' },
  { label: 'Inhuma', value: 'Inhuma' },
  { label: 'Inhumas', value: 'Inhumas' },
  { label: 'Inicbulan', value: 'Inicbulan' },
  { label: 'Iniesta', value: 'Iniesta' },
  { label: 'Iniéstola', value: 'Iniéstola' },
  { label: 'Inimutaba', value: 'Inimutaba' },
  { label: 'Iniö', value: 'Iniö' },
  { label: 'Inírida', value: 'Inírida' },
  { label: 'Inisa', value: 'Inisa' },
  { label: 'Inishcrone', value: 'Inishcrone' },
  { label: 'Initao', value: 'Initao' },
  { label: 'Injambakkam', value: 'Injambakkam' },
  { label: 'Inje-Gun', value: 'Inje-Gun' },
  { label: 'Inkberrow', value: 'Inkberrow' },
  { label: 'Inkerman', value: 'Inkerman' },
  { label: 'Inkerman', value: 'Inkerman' },
  { label: 'Inkhil', value: 'Inkhil' },
  { label: 'Inkster', value: 'Inkster' },
  { label: 'Inman', value: 'Inman' },
  { label: 'Inman', value: 'Inman' },
  { label: 'Inman Mills', value: 'Inman Mills' },
  { label: 'Innaloo', value: 'Innaloo' },
  { label: 'Innbygda', value: 'Innbygda' },
  { label: 'Inndyr', value: 'Inndyr' },
  { label: 'Innenheim', value: 'Innenheim' },
  { label: 'Innerbraz', value: 'Innerbraz' },
  { label: 'Innere Stadt', value: 'Innere Stadt' },
  { label: 'Innere Stadt', value: 'Innere Stadt' },
  { label: 'Innerleithen', value: 'Innerleithen' },
  { label: 'Innernzell', value: 'Innernzell' },
  { label: 'Innerschwand', value: 'Innerschwand' },
  { label: 'Innervillgraten', value: 'Innervillgraten' },
  { label: 'Innes Park', value: 'Innes Park' },
  { label: 'Inning Am Ammersee', value: 'Inning Am Ammersee' },
  { label: 'Inning Am Holz', value: 'Inning Am Holz' },
  { label: 'Innisfail', value: 'Innisfail' },
  { label: 'Innisfail Estate', value: 'Innisfail Estate' },
  { label: 'Innisfil', value: 'Innisfil' },
  { label: 'Inniswold', value: 'Inniswold' },
  { label: 'Innoshima', value: 'Innoshima' },
  { label: 'Innsbruck', value: 'Innsbruck' },
  { label: 'Innsbruck Stadt', value: 'Innsbruck Stadt' },
  { label: 'Ino', value: 'Ino' },
  { label: 'Inobulan', value: 'Inobulan' },
  { label: 'Inocência', value: 'Inocência' },
  { label: 'Inola', value: 'Inola' },
  { label: 'Inongo', value: 'Inongo' },
  { label: 'Inönü', value: 'Inönü' },
  { label: 'Inopacan', value: 'Inopacan' },
  { label: 'Inotești', value: 'Inotești' },
  { label: 'Inowłódz', value: 'Inowłódz' },
  { label: 'Inowrocław', value: 'Inowrocław' },
  { label: 'Inozemtsevo', value: 'Inozemtsevo' },
  { label: 'Inriville', value: 'Inriville' },
  { label: 'Ins', value: 'Ins' },
  { label: 'I-N-Salah', value: 'I-N-Salah' },
  { label: 'Insar', value: 'Insar' },
  { label: 'Insarskiy Rayon', value: 'Insarskiy Rayon' },
  { label: 'Insch', value: 'Insch' },
  { label: 'Insheim', value: 'Insheim' },
  { label: 'Insingen', value: 'Insingen' },
  { label: 'Insiza', value: 'Insiza' },
  { label: 'Insiza District', value: 'Insiza District' },
  { label: 'Insjön', value: 'Insjön' },
  { label: 'Insjön', value: 'Insjön' },
  { label: 'Ińsko', value: 'Ińsko' },
  { label: 'Inskoy', value: 'Inskoy' },
  { label: 'Insrom', value: 'Insrom' },
  { label: 'Instinción', value: 'Instinción' },
  { label: 'Însurăţei', value: 'Însurăţei' },
  { label: 'Inta', value: 'Inta' },
  { label: 'Intampilan', value: 'Intampilan' },
  { label: 'Intendente Alvear', value: 'Intendente Alvear' },
  { label: 'Intercourse', value: 'Intercourse' },
  { label: 'Interlachen', value: 'Interlachen' },
  { label: 'Interlaken', value: 'Interlaken' },
  { label: 'Interlaken', value: 'Interlaken' },
  { label: 'Interlaken-Oberhasli District', value: 'Interlaken-Oberhasli District' },
  { label: 'International Falls', value: 'International Falls' },
  { label: 'Intibucá', value: 'Intibucá' },
  { label: 'Intipucá', value: 'Intipucá' },
  { label: 'Întorsura', value: 'Întorsura' },
  { label: 'Întorsura Buzăului', value: 'Întorsura Buzăului' },
  { label: 'Intragna', value: 'Intragna' },
  { label: 'Intramuros', value: 'Intramuros' },
  { label: 'Intregalde', value: 'Intregalde' },
  { label: 'Întrerâuri', value: 'Întrerâuri' },
  { label: 'Introbio', value: 'Introbio' },
  { label: 'Introd', value: 'Introd' },
  { label: 'Introdacqua', value: 'Introdacqua' },
  { label: 'Introzzo', value: 'Introzzo' },
  { label: 'Inúbia Paulista', value: 'Inúbia Paulista' },
  { label: 'Inuvik', value: 'Inuvik' },
  { label: 'Inuyama', value: 'Inuyama' },
  { label: 'Inuyama-Shi', value: 'Inuyama-Shi' },
  { label: 'Inver Grove Heights', value: 'Inver Grove Heights' },
  { label: 'Inverbervie', value: 'Inverbervie' },
  { label: 'Invercargill', value: 'Invercargill' },
  { label: 'Inverclyde', value: 'Inverclyde' },
  { label: 'Inverell', value: 'Inverell' },
  { label: 'Invergordon', value: 'Invergordon' },
  { label: 'Invergowrie', value: 'Invergowrie' },
  { label: 'Inverigo', value: 'Inverigo' },
  { label: 'Inverkeithing', value: 'Inverkeithing' },
  { label: 'Inverkip', value: 'Inverkip' },
  { label: 'Inverleigh', value: 'Inverleigh' },
  { label: 'Inverloch', value: 'Inverloch' },
  { label: 'Invermay', value: 'Invermay' },
  { label: 'Invermay Park', value: 'Invermay Park' },
  { label: 'Invermere', value: 'Invermere' },
  { label: 'Inverness', value: 'Inverness' },
  { label: 'Inverness', value: 'Inverness' },
  { label: 'Inverness', value: 'Inverness' },
  { label: 'Inverness', value: 'Inverness' },
  { label: 'Inverness', value: 'Inverness' },
  { label: 'Inverness', value: 'Inverness' },
  { label: 'Inverness', value: 'Inverness' },
  { label: 'Inverness', value: 'Inverness' },
  { label: 'Inverness Highlands North', value: 'Inverness Highlands North' },
  { label: 'Inverness Highlands South', value: 'Inverness Highlands South' },
  { label: 'Inverno', value: 'Inverno' },
  { label: 'Inverno E Monteleone', value: 'Inverno E Monteleone' },
  { label: 'Inverso Pinasca', value: 'Inverso Pinasca' },
  { label: 'Inveruno', value: 'Inveruno' },
  { label: 'Inverurie', value: 'Inverurie' },
  { label: 'Inviernas Las', value: 'Inviernas Las' },
  { label: 'Invorio', value: 'Invorio' },
  { label: 'Inwałd', value: 'Inwałd' },
  { label: 'Inwil', value: 'Inwil' },
  { label: 'Inwood', value: 'Inwood' },
  { label: 'Inwood', value: 'Inwood' },
  { label: 'Inwood', value: 'Inwood' },
  { label: 'Inya', value: 'Inya' },
  { label: 'Inya', value: 'Inya' },
  { label: 'Inyati', value: 'Inyati' },
  { label: 'Inyo County', value: 'Inyo County' },
  { label: 'Inyokern', value: 'Inyokern' },
  { label: 'Inyonga', value: 'Inyonga' },
  { label: 'Inza', value: 'Inza' },
  { label: 'Inzá', value: 'Inzá' },
  { label: 'Inzago', value: 'Inzago' },
  { label: 'Inzai-Shi', value: 'Inzai-Shi' },
  { label: 'Inzell', value: 'Inzell' },
  { label: 'Inzenhof', value: 'Inzenhof' },
  { label: 'Inzer', value: 'Inzer' },
  { label: 'Inzersdorf Im Kremstal', value: 'Inzersdorf Im Kremstal' },
  { label: 'Inzhavino', value: 'Inzhavino' },
  { label: 'Inzigkofen', value: 'Inzigkofen' },
  { label: 'Inzing', value: 'Inzing' },
  { label: 'Inzlingen', value: 'Inzlingen' },
  { label: 'Ioánnina', value: 'Ioánnina' },
  { label: 'Iod', value: 'Iod' },
  { label: 'Iogach', value: 'Iogach' },
  { label: 'Iojib', value: 'Iojib' },
  { label: 'Iola', value: 'Iola' },
  { label: 'Iola', value: 'Iola' },
  { label: 'Iomerê', value: 'Iomerê' },
  { label: 'Ion Corvin', value: 'Ion Corvin' },
  { label: 'Ion Creangă', value: 'Ion Creangă' },
  { label: 'Ion Neculce', value: 'Ion Neculce' },
  { label: 'Ion Roată', value: 'Ion Roată' },
  { label: 'Iona', value: 'Iona' },
  { label: 'Iona', value: 'Iona' },
  { label: 'Ionășeni', value: 'Ionășeni' },
  { label: 'Ione', value: 'Ione' },
  { label: 'Ioneşti', value: 'Ioneşti' },
  { label: 'Ioneşti', value: 'Ioneşti' },
  { label: 'Ionești', value: 'Ionești' },
  { label: 'Ionia', value: 'Ionia' },
  { label: 'Ionia County', value: 'Ionia County' },
  { label: 'Iordăcheanu', value: 'Iordăcheanu' },
  { label: 'Íos', value: 'Íos' },
  { label: 'Iosco County', value: 'Iosco County' },
  { label: 'Iota', value: 'Iota' },
  { label: 'Iouik', value: 'Iouik' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Iowa City', value: 'Iowa City' },
  { label: 'Iowa Colony', value: 'Iowa Colony' },
  { label: 'Iowa County', value: 'Iowa County' },
  { label: 'Iowa County', value: 'Iowa County' },
  { label: 'Iowa Falls', value: 'Iowa Falls' },
  { label: 'Iowa Park', value: 'Iowa Park' },
  { label: 'Ip', value: 'Ip' },
  { label: 'Ipaba', value: 'Ipaba' },
  { label: 'Ipaja', value: 'Ipaja' },
  { label: 'Ipala', value: 'Ipala' },
  { label: 'Ipameri', value: 'Ipameri' },
  { label: 'Ipanema', value: 'Ipanema' },
  { label: 'Ipanguaçu', value: 'Ipanguaçu' },
  { label: 'Ipaporanga', value: 'Ipaporanga' },
  { label: 'Ipatele', value: 'Ipatele' },
  { label: 'Ipatinga', value: 'Ipatinga' },
  { label: 'Ipatovo', value: 'Ipatovo' },
  { label: 'Ipauçu', value: 'Ipauçu' },
  { label: 'Ipaumirim', value: 'Ipaumirim' },
  { label: 'Ipaussu', value: 'Ipaussu' },
  { label: 'Ipazoltic', value: 'Ipazoltic' },
  { label: 'Ipê', value: 'Ipê' },
  { label: 'Ipecaetá', value: 'Ipecaetá' },
  { label: 'Ipekyolu', value: 'Ipekyolu' },
  { label: 'Iperó', value: 'Iperó' },
  { label: 'Iperu', value: 'Iperu' },
  { label: 'Ipeúna', value: 'Ipeúna' },
  { label: 'Iphofen', value: 'Iphofen' },
  { label: 'Ipiaçu', value: 'Ipiaçu' },
  { label: 'Ipiales', value: 'Ipiales' },
  { label: 'Ipiaú', value: 'Ipiaú' },
  { label: 'Ipiguá', value: 'Ipiguá' },
  { label: 'Ipil', value: 'Ipil' },
  { label: 'Ipil', value: 'Ipil' },
  { label: 'Ipil', value: 'Ipil' },
  { label: 'Ipil', value: 'Ipil' },
  { label: 'Ipil', value: 'Ipil' },
  { label: 'Ipil', value: 'Ipil' },
  { label: 'Ipilan', value: 'Ipilan' },
  { label: 'Ipinda', value: 'Ipinda' },
  { label: 'Ipira', value: 'Ipira' },
  { label: 'Ipirá', value: 'Ipirá' },
  { label: 'Ipiranga', value: 'Ipiranga' },
  { label: 'Ipiranga De Goiás', value: 'Ipiranga De Goiás' },
  { label: 'Ipiranga Do Norte', value: 'Ipiranga Do Norte' },
  { label: 'Ipiranga Do Piauí', value: 'Ipiranga Do Piauí' },
  { label: 'Ipiranga Do Sul', value: 'Ipiranga Do Sul' },
  { label: 'Ipís', value: 'Ipís' },
  { label: 'Ipixuna', value: 'Ipixuna' },
  { label: 'Ipixuna Do Pará', value: 'Ipixuna Do Pará' },
  { label: 'Ipoh', value: 'Ipoh' },
  { label: 'Ipojuca', value: 'Ipojuca' },
  { label: 'Iporá', value: 'Iporá' },
  { label: 'Iporã', value: 'Iporã' },
  { label: 'Iporã Do Oeste', value: 'Iporã Do Oeste' },
  { label: 'Iporanga', value: 'Iporanga' },
  { label: 'Ipoteşti', value: 'Ipoteşti' },
  { label: 'Ipotești', value: 'Ipotești' },
  { label: 'Ipoti', value: 'Ipoti' },
  { label: 'Ippesheim', value: 'Ippesheim' },
  { label: 'Ipplepen', value: 'Ipplepen' },
  { label: 'Ippy', value: 'Ippy' },
  { label: 'Iprovipe I', value: 'Iprovipe I' },
  { label: 'Ipsala', value: 'Ipsala' },
  { label: 'Ipsheim', value: 'Ipsheim' },
  { label: 'Ipswich', value: 'Ipswich' },
  { label: 'Ipswich', value: 'Ipswich' },
  { label: 'Ipswich', value: 'Ipswich' },
  { label: 'Ipswich', value: 'Ipswich' },
  { label: 'Ipu', value: 'Ipu' },
  { label: 'Ipuã', value: 'Ipuã' },
  { label: 'Ipuaçu', value: 'Ipuaçu' },
  { label: 'Ipubi', value: 'Ipubi' },
  { label: 'Ipueira', value: 'Ipueira' },
  { label: 'Ipueiras', value: 'Ipueiras' },
  { label: 'Ipueiras', value: 'Ipueiras' },
  { label: 'Ipueiras', value: 'Ipueiras' },
  { label: 'Ipuiúna', value: 'Ipuiúna' },
  { label: 'Ipumirim', value: 'Ipumirim' },
  { label: 'Ipupiara', value: 'Ipupiara' },
  { label: 'Iqaluit', value: 'Iqaluit' },
  { label: 'Iquinuapa', value: 'Iquinuapa' },
  { label: 'Iquique', value: 'Iquique' },
  { label: 'Iquira', value: 'Iquira' },
  { label: 'Iquitos', value: 'Iquitos' },
  { label: 'Iraan', value: 'Iraan' },
  { label: 'Iracema', value: 'Iracema' },
  { label: 'Iracema', value: 'Iracema' },
  { label: 'Iracema Do Oeste', value: 'Iracema Do Oeste' },
  { label: 'Iracemápolis', value: 'Iracemápolis' },
  { label: 'Iraceminha', value: 'Iraceminha' },
  { label: 'Iradan', value: 'Iradan' },
  { label: 'Irafskiy Rayon', value: 'Irafskiy Rayon' },
  { label: 'Iraga', value: 'Iraga' },
  { label: 'Irahuan', value: 'Irahuan' },
  { label: 'Iraí', value: 'Iraí' },
  { label: 'Iraí De Minas', value: 'Iraí De Minas' },
  { label: 'Irajuba', value: 'Irajuba' },
  { label: 'Irákleia', value: 'Irákleia' },
  { label: 'Irákleio', value: 'Irákleio' },
  { label: 'Irákleion', value: 'Irákleion' },
  { label: 'Iralaya', value: 'Iralaya' },
  { label: 'Iramaia', value: 'Iramaia' },
  { label: 'Irámuco', value: 'Irámuco' },
  { label: 'Iranduba', value: 'Iranduba' },
  { label: 'Iraneh', value: 'Iraneh' },
  { label: 'Irañeta', value: 'Irañeta' },
  { label: 'Irani', value: 'Irani' },
  { label: 'Iranshahr', value: 'Iranshahr' },
  { label: 'Irapeo', value: 'Irapeo' },
  { label: 'Irapuã', value: 'Irapuã' },
  { label: 'Irapuato', value: 'Irapuato' },
  { label: 'Irapuru', value: 'Irapuru' },
  { label: 'Iraquara', value: 'Iraquara' },
  { label: 'Irará', value: 'Irará' },
  { label: 'Iraray', value: 'Iraray' },
  { label: 'Irasan', value: 'Irasan' },
  { label: 'Irati', value: 'Irati' },
  { label: 'Irati', value: 'Irati' },
  { label: 'Iratoşu', value: 'Iratoşu' },
  { label: 'Iratzio', value: 'Iratzio' },
  { label: 'Irauçuba', value: 'Irauçuba' },
  { label: 'Iraya', value: 'Iraya' },
  { label: 'Irbeyskiy Rayon', value: 'Irbeyskiy Rayon' },
  { label: 'Irbeyskoye', value: 'Irbeyskoye' },
  { label: 'Irbid', value: 'Irbid' },
  { label: 'Irbīn', value: 'Irbīn' },
  { label: 'Irbit', value: 'Irbit' },
  { label: 'Irchenrieth', value: 'Irchenrieth' },
  { label: 'Irchester', value: 'Irchester' },
  { label: 'Irdning', value: 'Irdning' },
  { label: 'Irecê', value: 'Irecê' },
  { label: 'Iredell County', value: 'Iredell County' },
  { label: 'Iregszemcse', value: 'Iregszemcse' },
  { label: 'Irești', value: 'Irești' },
  { label: 'Iretama', value: 'Iretama' },
  { label: 'Irgakly', value: 'Irgakly' },
  { label: 'Irganay', value: 'Irganay' },
  { label: 'Irgoli', value: 'Irgoli' },
  { label: 'Iriba', value: 'Iriba' },
  { label: 'Irig', value: 'Irig' },
  { label: 'Iriga City', value: 'Iriga City' },
  { label: 'Irigny', value: 'Irigny' },
  { label: 'Irimbo', value: 'Irimbo' },
  { label: 'Irineópolis', value: 'Irineópolis' },
  { label: 'Iringa', value: 'Iringa' },
  { label: 'Iringal', value: 'Iringal' },
  { label: 'Irinjalakuda', value: 'Irinjalakuda' },
  { label: 'Irion County', value: 'Irion County' },
  { label: 'Iriona', value: 'Iriona' },
  { label: 'Iriondo Department', value: 'Iriondo Department' },
  { label: 'Irirum', value: 'Irirum' },
  { label: 'Irish Town', value: 'Irish Town' },
  { label: 'Irituia', value: 'Irituia' },
  { label: 'Irixo O', value: 'Irixo O' },
  { label: 'Irixoa', value: 'Irixoa' },
  { label: 'Irkliyevskaya', value: 'Irkliyevskaya' },
  { label: 'Irkutsk', value: 'Irkutsk' },
  { label: 'Irkutskiy Rayon', value: 'Irkutskiy Rayon' },
  { label: 'Irlam', value: 'Irlam' },
  { label: 'Irlbach', value: 'Irlbach' },
  { label: 'Irma', value: 'Irma' },
  { label: 'Irmo', value: 'Irmo' },
  { label: 'Irmstett', value: 'Irmstett' },
  { label: 'Irodouër', value: 'Irodouër' },
  { label: 'Irolo', value: 'Irolo' },
  { label: 'Iron County', value: 'Iron County' },
  { label: 'Iron County', value: 'Iron County' },
  { label: 'Iron County', value: 'Iron County' },
  { label: 'Iron County', value: 'Iron County' },
  { label: 'Iron Mountain', value: 'Iron Mountain' },
  { label: 'Iron River', value: 'Iron River' },
  { label: 'Ironbark', value: 'Ironbark' },
  { label: 'Ironbridge', value: 'Ironbridge' },
  { label: 'Irondale', value: 'Irondale' },
  { label: 'Irondale', value: 'Irondale' },
  { label: 'Irondequoit', value: 'Irondequoit' },
  { label: 'Ironshore', value: 'Ironshore' },
  { label: 'Ironton', value: 'Ironton' },
  { label: 'Ironton', value: 'Ironton' },
  { label: 'Ironville', value: 'Ironville' },
  { label: 'Ironwood', value: 'Ironwood' },
  { label: 'Iroquois County', value: 'Iroquois County' },
  { label: 'Iroquois Falls', value: 'Iroquois Falls' },
  { label: 'Iroquois Point', value: 'Iroquois Point' },
  { label: 'Irosin', value: 'Irosin' },
  { label: 'Irpa Irpa', value: 'Irpa Irpa' },
  { label: 'Irpin', value: 'Irpin' },
  { label: 'Irpins’Ka Mis’Krada', value: 'Irpins’Ka Mis’Krada' },
  { label: 'Irrel', value: 'Irrel' },
  { label: 'Irricana', value: 'Irricana' },
  { label: 'Irrigon', value: 'Irrigon' },
  { label: 'Irrsdorf', value: 'Irrsdorf' },
  { label: 'Irsch', value: 'Irsch' },
  { label: 'Irschen', value: 'Irschen' },
  { label: 'Irschenberg', value: 'Irschenberg' },
  { label: 'Irsee', value: 'Irsee' },
  { label: 'Irsha', value: 'Irsha' },
  { label: 'Irshansk', value: 'Irshansk' },
  { label: 'Irshava', value: 'Irshava' },
  { label: 'Irshava Raion', value: 'Irshava Raion' },
  { label: 'Irsina', value: 'Irsina' },
  { label: 'Irsta', value: 'Irsta' },
  { label: 'Irsta', value: 'Irsta' },
  { label: 'Irthlingborough', value: 'Irthlingborough' },
  { label: 'Irtyshsk', value: 'Irtyshsk' },
  { label: 'Irtyshskiy', value: 'Irtyshskiy' },
  { label: 'Irtyshskiy', value: 'Irtyshskiy' },
  { label: 'Iruela La', value: 'Iruela La' },
  { label: 'Iruelos', value: 'Iruelos' },
  { label: 'Irueste', value: 'Irueste' },
  { label: 'Irugur', value: 'Irugur' },
  { label: 'Iruma-Shi', value: 'Iruma-Shi' },
  { label: 'Irun', value: 'Irun' },
  { label: 'Iruña Oka/Iruña De Oca', value: 'Iruña Oka/Iruña De Oca' },
  { label: 'Irupi', value: 'Irupi' },
  { label: 'Irura', value: 'Irura' },
  { label: 'Iruraiz-Gauna', value: 'Iruraiz-Gauna' },
  { label: 'Irurtzun', value: 'Irurtzun' },
  { label: 'Irvillac', value: 'Irvillac' },
  { label: 'Irvine', value: 'Irvine' },
  { label: 'Irvine', value: 'Irvine' },
  { label: 'Irvine', value: 'Irvine' },
  { label: 'Irvinestown', value: 'Irvinestown' },
  { label: 'Irving', value: 'Irving' },
  { label: 'Irving Park', value: 'Irving Park' },
  { label: 'Irvington', value: 'Irvington' },
  { label: 'Irvington', value: 'Irvington' },
  { label: 'Irvington', value: 'Irvington' },
  { label: 'Irvington', value: 'Irvington' },
  { label: 'Irwin', value: 'Irwin' },
  { label: 'Irwin', value: 'Irwin' },
  { label: 'Irwin', value: 'Irwin' },
  { label: 'Irwin', value: 'Irwin' },
  { label: 'Irwin County', value: 'Irwin County' },
  { label: 'Irwindale', value: 'Irwindale' },
  { label: 'Irwinton', value: 'Irwinton' },
  { label: 'Irxleben', value: 'Irxleben' },
  { label: 'Irymple', value: 'Irymple' },
  { label: 'Is', value: 'Is' },
  { label: 'Isa Shi', value: 'Isa Shi' },
  { label: 'Isaac', value: 'Isaac' },
  { label: 'Isaac Arriaga (Santa Ana Mancera)', value: 'Isaac Arriaga (Santa Ana Mancera)' },
  { label: 'Isaacs', value: 'Isaacs' },
  { label: 'Isaba/Izaba', value: 'Isaba/Izaba' },
  { label: 'Isabang', value: 'Isabang' },
  { label: 'Isabel', value: 'Isabel' },
  { label: 'Isabel', value: 'Isabel' },
  { label: 'Isabel Segunda', value: 'Isabel Segunda' },
  { label: 'Isabela', value: 'Isabela' },
  { label: 'Isabela', value: 'Isabela' },
  { label: 'Isabela', value: 'Isabela' },
  { label: 'Isabela', value: 'Isabela' },
  { label: 'Isabela De Sagua', value: 'Isabela De Sagua' },
  { label: 'Isabella County', value: 'Isabella County' },
  { label: 'Isabella Plains', value: 'Isabella Plains' },
  { label: 'Isábena', value: 'Isábena' },
  { label: 'Isaccea', value: 'Isaccea' },
  { label: 'Ísafjarðarbær', value: 'Ísafjarðarbær' },
  { label: 'Ísafjörður', value: 'Ísafjörður' },
  { label: 'Isagarh', value: 'Isagarh' },
  { label: 'Isahaya', value: 'Isahaya' },
  { label: 'Isahaya-Shi', value: 'Isahaya-Shi' },
  { label: 'Isaías Coelho', value: 'Isaías Coelho' },
  { label: 'Isaka', value: 'Isaka' },
  { label: 'Isakly', value: 'Isakly' },
  { label: 'Isakogorka', value: 'Isakogorka' },
  { label: 'Işalniţa', value: 'Işalniţa' },
  { label: 'Isangel', value: 'Isangel' },
  { label: 'Isanlu-Itedoijowa', value: 'Isanlu-Itedoijowa' },
  { label: 'Isanti', value: 'Isanti' },
  { label: 'Isanti County', value: 'Isanti County' },
  { label: 'Isar', value: 'Isar' },
  { label: 'Isara', value: 'Isara' },
  { label: 'Isasca', value: 'Isasca' },
  { label: 'Isaszeg', value: 'Isaszeg' },
  { label: 'Isawa', value: 'Isawa' },
  { label: 'Isbergues', value: 'Isbergues' },
  { label: 'Isca Marina', value: 'Isca Marina' },
  { label: 'Isca Sullo Ionio', value: 'Isca Sullo Ionio' },
  { label: 'Iscar', value: 'Iscar' },
  { label: 'Iscehisar', value: 'Iscehisar' },
  { label: 'Ischgl', value: 'Ischgl' },
  { label: 'Ischia', value: 'Ischia' },
  { label: 'Ischia Di Castro', value: 'Ischia Di Castro' },
  { label: 'Ischia Porto', value: 'Ischia Porto' },
  { label: 'Ischitella', value: 'Ischitella' },
  { label: 'Iscroni', value: 'Iscroni' },
  { label: 'Ise', value: 'Ise' },
  { label: 'Ise-Ekiti', value: 'Ise-Ekiti' },
  { label: 'Isehara', value: 'Isehara' },
  { label: 'Isehara Shi', value: 'Isehara Shi' },
  { label: 'Iselin', value: 'Iselin' },
  { label: 'Isen', value: 'Isen' },
  { label: 'Isenbüttel', value: 'Isenbüttel' },
  { label: 'Iseo', value: 'Iseo' },
  { label: 'Isera', value: 'Isera' },
  { label: 'Iserlohn', value: 'Iserlohn' },
  { label: 'Isernhagen Farster Bauerschaft', value: 'Isernhagen Farster Bauerschaft' },
  { label: 'Isernia', value: 'Isernia' },
  { label: 'Isesaki', value: 'Isesaki' },
  { label: 'Isesaki Shi', value: 'Isesaki Shi' },
  { label: 'Ise-Shi', value: 'Ise-Shi' },
  { label: 'Iset’', value: 'Iset’' },
  { label: 'Isetskiy Rayon', value: 'Isetskiy Rayon' },
  { label: 'Isetskoye', value: 'Isetskoye' },
  { label: 'Isfahan', value: 'Isfahan' },
  { label: 'Isfana', value: 'Isfana' },
  { label: 'Isfara', value: 'Isfara' },
  { label: 'Ishanpur', value: 'Ishanpur' },
  { label: 'Ishcherskaya', value: 'Ishcherskaya' },
  { label: 'Isheri-Olofin', value: 'Isheri-Olofin' },
  { label: 'Isheyevka', value: 'Isheyevka' },
  { label: 'Ishigaki', value: 'Ishigaki' },
  { label: 'Ishigaki-Shi', value: 'Ishigaki-Shi' },
  { label: 'Ishige', value: 'Ishige' },
  { label: 'Ishii', value: 'Ishii' },
  { label: 'Ishikari', value: 'Ishikari' },
  { label: 'Ishikari-Shi', value: 'Ishikari-Shi' },
  { label: 'Ishikawa', value: 'Ishikawa' },
  { label: 'Ishikawa', value: 'Ishikawa' },
  { label: 'Ishiki', value: 'Ishiki' },
  { label: 'Ishim', value: 'Ishim' },
  { label: 'Ishimbay', value: 'Ishimbay' },
  { label: 'Ishimskiy Rayon', value: 'Ishimskiy Rayon' },
  { label: 'Ishinomaki', value: 'Ishinomaki' },
  { label: 'Ishinomaki Shi', value: 'Ishinomaki Shi' },
  { label: 'Ishioka', value: 'Ishioka' },
  { label: 'Ishioka-Shi', value: 'Ishioka-Shi' },
  { label: 'Ishkhoy-Yurt', value: 'Ishkhoy-Yurt' },
  { label: 'Ishley', value: 'Ishley' },
  { label: 'Ishnya', value: 'Ishnya' },
  { label: 'Ishøj', value: 'Ishøj' },
  { label: 'Ishøj Kommune', value: 'Ishøj Kommune' },
  { label: 'Ishpeming', value: 'Ishpeming' },
  { label: 'Ishqoshim', value: 'Ishqoshim' },
  { label: 'Ishtixon', value: 'Ishtixon' },
  { label: 'Ishun’', value: 'Ishun’' },
  { label: 'Isidoro Noblía', value: 'Isidoro Noblía' },
  { label: 'Isieke', value: 'Isieke' },
  { label: 'Isigny-Le-Buat', value: 'Isigny-Le-Buat' },
  { label: 'Isigny-Sur-Mer', value: 'Isigny-Sur-Mer' },
  { label: 'Isili', value: 'Isili' },
  { label: 'Isiolo', value: 'Isiolo' },
  { label: 'Isiro', value: 'Isiro' },
  { label: 'Iskan', value: 'Iskan' },
  { label: 'Iskandar', value: 'Iskandar' },
  { label: 'Iskar', value: 'Iskar' },
  { label: 'Iskateley', value: 'Iskateley' },
  { label: 'Iskenderun', value: 'Iskenderun' },
  { label: 'Iskilip', value: 'Iskilip' },
  { label: 'Iskitim', value: 'Iskitim' },
  { label: 'Iskitimskiy Rayon', value: 'Iskitimskiy Rayon' },
  { label: 'Iskushuban', value: 'Iskushuban' },
  { label: 'Isla', value: 'Isla' },
  { label: 'Isla', value: 'Isla' },
  { label: 'Isla', value: 'Isla' },
  { label: 'Isla', value: 'Isla' },
  { label: 'Isla Aguada', value: 'Isla Aguada' },
  { label: 'Isla Bastimentos', value: 'Isla Bastimentos' },
  { label: 'Isla Cristina', value: 'Isla Cristina' },
  { label: 'Isla De Maipo', value: 'Isla De Maipo' },
  { label: 'Isla De Pascua', value: 'Isla De Pascua' },
  { label: 'Isla Del Bosque', value: 'Isla Del Bosque' },
  { label: 'Isla Holbox', value: 'Isla Holbox' },
  { label: 'Isla Mayor', value: 'Isla Mayor' },
  { label: 'Isla Mujeres', value: 'Isla Mujeres' },
  { label: 'Isla Pucú', value: 'Isla Pucú' },
  { label: 'Isla Soyaltepec', value: 'Isla Soyaltepec' },
  { label: 'Isla Verde', value: 'Isla Verde' },
  { label: 'Isla Vista', value: 'Isla Vista' },
  { label: 'Islahiye', value: 'Islahiye' },
  { label: 'Islamabad', value: 'Islamabad' },
  { label: 'Islamey', value: 'Islamey' },
  { label: 'Islamkot', value: 'Islamkot' },
  { label: 'Islamnagar', value: 'Islamnagar' },
  { label: 'Islamnagar (Badaun)', value: 'Islamnagar (Badaun)' },
  { label: 'Islamorada', value: 'Islamorada' },
  { label: 'Islampur', value: 'Islampur' },
  { label: 'Islampur', value: 'Islampur' },
  { label: 'Island City', value: 'Island City' },
  { label: 'Island County', value: 'Island County' },
  { label: 'Island Garden City Of Samal', value: 'Island Garden City Of Samal' },
  { label: 'Island Garden City Of Samal', value: 'Island Garden City Of Samal' },
  { label: 'Island Heights', value: 'Island Heights' },
  { label: 'Island Lake', value: 'Island Lake' },
  { label: 'Island Park', value: 'Island Park' },
  { label: 'Island Walk', value: 'Island Walk' },
  { label: 'Islandia', value: 'Islandia' },
  { label: 'Islas Agrarias Grupo A', value: 'Islas Agrarias Grupo A' },
  { label: 'Islas Agrarias Grupo B', value: 'Islas Agrarias Grupo B' },
  { label: 'Islaz', value: 'Islaz' },
  { label: 'Islaz', value: 'Islaz' },
  { label: 'Isle', value: 'Isle' },
  { label: 'Isle Of Arran', value: 'Isle Of Arran' },
  { label: 'Isle Of Bute', value: 'Isle Of Bute' },
  { label: 'Isle Of Cumbrae', value: 'Isle Of Cumbrae' },
  { label: 'Isle Of Hope', value: 'Isle Of Hope' },
  { label: 'Isle Of Islay', value: 'Isle Of Islay' },
  { label: 'Isle Of Lewis', value: 'Isle Of Lewis' },
  { label: 'Isle Of Mull', value: 'Isle Of Mull' },
  { label: 'Isle Of Normandy', value: 'Isle Of Normandy' },
  { label: 'Isle Of North Uist', value: 'Isle Of North Uist' },
  { label: 'Isle Of Palms', value: 'Isle Of Palms' },
  { label: 'Isle Of South Uist', value: 'Isle Of South Uist' },
  { label: 'Isle Of Wight', value: 'Isle Of Wight' },
  { label: 'Isle Of Wight County', value: 'Isle Of Wight County' },
  { label: 'Isleham', value: 'Isleham' },
  { label: 'Isles Of Scilly', value: 'Isles Of Scilly' },
  { label: 'Isleworth', value: 'Isleworth' },
  { label: 'Islington', value: 'Islington' },
  { label: 'Islington', value: 'Islington' },
  { label: 'Islington', value: 'Islington' },
  { label: 'Islip', value: 'Islip' },
  { label: 'Islip Terrace', value: 'Islip Terrace' },
  { label: 'Islita', value: 'Islita' },
  { label: 'Ismael Flores', value: 'Ismael Flores' },
  { label: 'Ismailia', value: 'Ismailia' },
  { label: 'Ismaning', value: 'Ismaning' },
  { label: 'Ismayıllı', value: 'Ismayıllı' },
  { label: 'Isnā', value: 'Isnā' },
  { label: 'Isneauville', value: 'Isneauville' },
  { label: 'Isnello', value: 'Isnello' },
  { label: 'Isnos', value: 'Isnos' },
  { label: 'Isny', value: 'Isny' },
  { label: 'Isojoki', value: 'Isojoki' },
  { label: 'Isoka', value: 'Isoka' },
  { label: 'Isokyrö', value: 'Isokyrö' },
  { label: 'Isola', value: 'Isola' },
  { label: 'Isola Darbia', value: 'Isola Darbia' },
  { label: 'Isola Dasti', value: 'Isola Dasti' },
  { label: 'Isola Del Cantone', value: 'Isola Del Cantone' },
  { label: 'Isola Del Giglio', value: 'Isola Del Giglio' },
  { label: 'Isola Del Gran Sasso Ditalia', value: 'Isola Del Gran Sasso Ditalia' },
  { label: 'Isola Del Liri', value: 'Isola Del Liri' },
  { label: 'Isola Del Piano', value: 'Isola Del Piano' },
  { label: 'Isola Della Scala', value: 'Isola Della Scala' },
  { label: 'Isola Delle Femmine', value: 'Isola Delle Femmine' },
  { label: 'Isola Di Capo Rizzuto', value: 'Isola Di Capo Rizzuto' },
  { label: 'Isola Di Fondra', value: 'Isola Di Fondra' },
  { label: 'Isola Dovarese', value: 'Isola Dovarese' },
  { label: 'Isola Rizza', value: 'Isola Rizza' },
  { label: 'Isola Santantonio', value: 'Isola Santantonio' },
  { label: 'Isola Vicentina', value: 'Isola Vicentina' },
  { label: 'Isolabella', value: 'Isolabella' },
  { label: 'Isolabona', value: 'Isolabona' },
  { label: 'Isolaccia', value: 'Isolaccia' },
  { label: 'Isole Tremiti', value: 'Isole Tremiti' },
  { label: 'Isona I Conca Dellà', value: 'Isona I Conca Dellà' },
  { label: 'Isorella', value: 'Isorella' },
  { label: 'Isoverde', value: 'Isoverde' },
  { label: 'Isòvol', value: 'Isòvol' },
  { label: 'Ispani', value: 'Ispani' },
  { label: 'Ispas', value: 'Ispas' },
  { label: 'Ispaster', value: 'Ispaster' },
  { label: 'Isperih', value: 'Isperih' },
  { label: 'Ispica', value: 'Ispica' },
  { label: 'Ispir', value: 'Ispir' },
  { label: 'Ispra', value: 'Ispra' },
  { label: 'Ispravnaya', value: 'Ispravnaya' },
  { label: 'Ispringen', value: 'Ispringen' },
  { label: 'Isques', value: 'Isques' },
  { label: 'Israelândia', value: 'Israelândia' },
  { label: 'Issa', value: 'Issa' },
  { label: 'Issad', value: 'Issad' },
  { label: 'Issaquah', value: 'Issaquah' },
  { label: 'Issaquena County', value: 'Issaquena County' },
  { label: 'Issé', value: 'Issé' },
  { label: 'Isseksi', value: 'Isseksi' },
  { label: 'Isselburg', value: 'Isselburg' },
  { label: 'Issenheim', value: 'Issenheim' },
  { label: 'Issenye', value: 'Issenye' },
  { label: 'Issia', value: 'Issia' },
  { label: 'Issiglio', value: 'Issiglio' },
  { label: 'Issime', value: 'Issime' },
  { label: 'Issinskiy Rayon', value: 'Issinskiy Rayon' },
  { label: 'Isso', value: 'Isso' },
  { label: 'Issogne', value: 'Issogne' },
  { label: 'Issoire', value: 'Issoire' },
  { label: 'Issou', value: 'Issou' },
  { label: 'Issoudun', value: 'Issoudun' },
  { label: 'Issum', value: 'Issum' },
  { label: 'Is-Sur-Tille', value: 'Is-Sur-Tille' },
  { label: 'Issy-Les-Moulineaux', value: 'Issy-Les-Moulineaux' },
  { label: 'Istán', value: 'Istán' },
  { label: 'Istanbul', value: 'Istanbul' },
  { label: 'Iștan-Tău', value: 'Iștan-Tău' },
  { label: 'Istaravshan', value: 'Istaravshan' },
  { label: 'Istaro', value: 'Istaro' },
  { label: 'Istebna', value: 'Istebna' },
  { label: 'Isthmía', value: 'Isthmía' },
  { label: 'Isti Bolagh', value: 'Isti Bolagh' },
  { label: 'Istia Dombrone-Le Stiacciole', value: 'Istia Dombrone-Le Stiacciole' },
  { label: 'Istiaía', value: 'Istiaía' },
  { label: 'Iștihaza', value: 'Iștihaza' },
  { label: 'Istmina', value: 'Istmina' },
  { label: 'Istočni Mostar', value: 'Istočni Mostar' },
  { label: 'Istok', value: 'Istok' },
  { label: 'Istok', value: 'Istok' },
  { label: 'Istra', value: 'Istra' },
  { label: 'Istrana', value: 'Istrana' },
  { label: 'Istres', value: 'Istres' },
  { label: 'Istria', value: 'Istria' },
  { label: 'Istrinskiy Rayon', value: 'Istrinskiy Rayon' },
  { label: 'Isuerre', value: 'Isuerre' },
  { label: 'Isugod', value: 'Isugod' },
  { label: 'Isulan', value: 'Isulan' },
  { label: 'Isulan', value: 'Isulan' },
  { label: 'Isumi', value: 'Isumi' },
  { label: 'Isumi Shi', value: 'Isumi Shi' },
  { label: 'Isverna', value: 'Isverna' },
  { label: 'Isyangulovo', value: 'Isyangulovo' },
  { label: 'Itá', value: 'Itá' },
  { label: 'Itá', value: 'Itá' },
  { label: 'Itá Ibaté', value: 'Itá Ibaté' },
  { label: 'Itaara', value: 'Itaara' },
  { label: 'Itabaiana', value: 'Itabaiana' },
  { label: 'Itabaiana', value: 'Itabaiana' },
  { label: 'Itabaianinha', value: 'Itabaianinha' },
  { label: 'Itabashi-Ku', value: 'Itabashi-Ku' },
  { label: 'Itabela', value: 'Itabela' },
  { label: 'Itaberá', value: 'Itaberá' },
  { label: 'Itaberaba', value: 'Itaberaba' },
  { label: 'Itaberaí', value: 'Itaberaí' },
  { label: 'Itabi', value: 'Itabi' },
  { label: 'Itabira', value: 'Itabira' },
  { label: 'Itabirinha', value: 'Itabirinha' },
  { label: 'Itabirito', value: 'Itabirito' },
  { label: 'Itaboraí', value: 'Itaboraí' },
  { label: 'Itabuna', value: 'Itabuna' },
  { label: 'Itacajá', value: 'Itacajá' },
  { label: 'Itacambira', value: 'Itacambira' },
  { label: 'Itacarambi', value: 'Itacarambi' },
  { label: 'Itacaré', value: 'Itacaré' },
  { label: 'Itacoatiara', value: 'Itacoatiara' },
  { label: 'Itacorubi', value: 'Itacorubi' },
  { label: 'Itacuruba', value: 'Itacuruba' },
  { label: 'Itacurubi', value: 'Itacurubi' },
  { label: 'Itacurubí De La Cordillera', value: 'Itacurubí De La Cordillera' },
  { label: 'Itacurubí Del Rosario', value: 'Itacurubí Del Rosario' },
  { label: 'Itaeté', value: 'Itaeté' },
  { label: 'Itagi', value: 'Itagi' },
  { label: 'Itagibá', value: 'Itagibá' },
  { label: 'Itagimirim', value: 'Itagimirim' },
  { label: 'Itaguaçu', value: 'Itaguaçu' },
  { label: 'Itaguaçu Da Bahia', value: 'Itaguaçu Da Bahia' },
  { label: 'Itaguaí', value: 'Itaguaí' },
  { label: 'Itaguajé', value: 'Itaguajé' },
  { label: 'Itaguara', value: 'Itaguara' },
  { label: 'Itaguari', value: 'Itaguari' },
  { label: 'Itaguaru', value: 'Itaguaru' },
  { label: 'Itaguatins', value: 'Itaguatins' },
  { label: 'Itagui', value: 'Itagui' },
  { label: 'Itaí', value: 'Itaí' },
  { label: 'Itaíba', value: 'Itaíba' },
  { label: 'Itaiçaba', value: 'Itaiçaba' },
  { label: 'Itainópolis', value: 'Itainópolis' },
  { label: 'Itaiópolis', value: 'Itaiópolis' },
  { label: 'Itaipava Do Grajaú', value: 'Itaipava Do Grajaú' },
  { label: 'Itaipé', value: 'Itaipé' },
  { label: 'Itaipulândia', value: 'Itaipulândia' },
  { label: 'Itaitinga', value: 'Itaitinga' },
  { label: 'Itaituba', value: 'Itaituba' },
  { label: 'Itajá', value: 'Itajá' },
  { label: 'Itajá', value: 'Itajá' },
  { label: 'Itajaí', value: 'Itajaí' },
  { label: 'Itajobi', value: 'Itajobi' },
  { label: 'Itaju', value: 'Itaju' },
  { label: 'Itaju Do Colônia', value: 'Itaju Do Colônia' },
  { label: 'Itajubá', value: 'Itajubá' },
  { label: 'Itajuípe', value: 'Itajuípe' },
  { label: 'Itako', value: 'Itako' },
  { label: 'Itako-Shi', value: 'Itako-Shi' },
  { label: 'Itakyry', value: 'Itakyry' },
  { label: 'Itala', value: 'Itala' },
  { label: 'Italó', value: 'Italó' },
  { label: 'Italva', value: 'Italva' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Itamaracá', value: 'Itamaracá' },
  { label: 'Itamaraju', value: 'Itamaraju' },
  { label: 'Itamarandiba', value: 'Itamarandiba' },
  { label: 'Itamarati', value: 'Itamarati' },
  { label: 'Itamarati De Minas', value: 'Itamarati De Minas' },
  { label: 'Itamari', value: 'Itamari' },
  { label: 'Itambacuri', value: 'Itambacuri' },
  { label: 'Itambaracá', value: 'Itambaracá' },
  { label: 'Itambé', value: 'Itambé' },
  { label: 'Itambé', value: 'Itambé' },
  { label: 'Itambé', value: 'Itambé' },
  { label: 'Itambé Do Mato Dentro', value: 'Itambé Do Mato Dentro' },
  { label: 'Itami', value: 'Itami' },
  { label: 'Itami Shi', value: 'Itami Shi' },
  { label: 'Itamogi', value: 'Itamogi' },
  { label: 'Itamonte', value: 'Itamonte' },
  { label: 'Itanagar', value: 'Itanagar' },
  { label: 'Itanagra', value: 'Itanagra' },
  { label: 'Itancourt', value: 'Itancourt' },
  { label: 'Itanhaém', value: 'Itanhaém' },
  { label: 'Itanhandu', value: 'Itanhandu' },
  { label: 'Itanhangá', value: 'Itanhangá' },
  { label: 'Itanhém', value: 'Itanhém' },
  { label: 'Itanhomi', value: 'Itanhomi' },
  { label: 'Itaobim', value: 'Itaobim' },
  { label: 'Itaoca', value: 'Itaoca' },
  { label: 'Itaocara', value: 'Itaocara' },
  { label: 'Itapaci', value: 'Itapaci' },
  { label: 'Itapagé', value: 'Itapagé' },
  { label: 'Itapagipe', value: 'Itapagipe' },
  { label: 'Itapajé', value: 'Itapajé' },
  { label: 'Itaparica', value: 'Itaparica' },
  { label: 'Itapé', value: 'Itapé' },
  { label: 'Itapé', value: 'Itapé' },
  { label: 'Itapebi', value: 'Itapebi' },
  { label: 'Itapecerica', value: 'Itapecerica' },
  { label: 'Itapecerica Da Serra', value: 'Itapecerica Da Serra' },
  { label: 'Itapecuru Mirim', value: 'Itapecuru Mirim' },
  { label: 'Itapejara Doeste', value: 'Itapejara Doeste' },
  { label: 'Itapema', value: 'Itapema' },
  { label: 'Itapemirim', value: 'Itapemirim' },
  { label: 'Itaperuçu', value: 'Itaperuçu' },
  { label: 'Itaperuna', value: 'Itaperuna' },
  { label: 'Itapetim', value: 'Itapetim' },
  { label: 'Itapetinga', value: 'Itapetinga' },
  { label: 'Itapetininga', value: 'Itapetininga' },
  { label: 'Itapeva', value: 'Itapeva' },
  { label: 'Itapeva', value: 'Itapeva' },
  { label: 'Itapevi', value: 'Itapevi' },
  { label: 'Itapicuru', value: 'Itapicuru' },
  { label: 'Itapipoca', value: 'Itapipoca' },
  { label: 'Itapira', value: 'Itapira' },
  { label: 'Itapiranga', value: 'Itapiranga' },
  { label: 'Itapiranga', value: 'Itapiranga' },
  { label: 'Itapirapuã', value: 'Itapirapuã' },
  { label: 'Itapirapuã Paulista', value: 'Itapirapuã Paulista' },
  { label: 'Itapiratins', value: 'Itapiratins' },
  { label: 'Itapissuma', value: 'Itapissuma' },
  { label: 'Itapitanga', value: 'Itapitanga' },
  { label: 'Itapiúna', value: 'Itapiúna' },
  { label: 'Itapoá', value: 'Itapoá' },
  { label: 'Itápolis', value: 'Itápolis' },
  { label: 'Itaporã', value: 'Itaporã' },
  { label: 'Itaporã Do Tocantins', value: 'Itaporã Do Tocantins' },
  { label: 'Itaporanga', value: 'Itaporanga' },
  { label: 'Itaporanga', value: 'Itaporanga' },
  { label: 'Itaporanga Dajuda', value: 'Itaporanga Dajuda' },
  { label: 'Itapororoca', value: 'Itapororoca' },
  { label: 'Itapuã Do Oeste', value: 'Itapuã Do Oeste' },
  { label: 'Itapuca', value: 'Itapuca' },
  { label: 'Itapuí', value: 'Itapuí' },
  { label: 'Itapura', value: 'Itapura' },
  { label: 'Itapuranga', value: 'Itapuranga' },
  { label: 'Itaquaquecetuba', value: 'Itaquaquecetuba' },
  { label: 'Itaquara', value: 'Itaquara' },
  { label: 'Itaqui', value: 'Itaqui' },
  { label: 'Itaquiraí', value: 'Itaquiraí' },
  { label: 'Itaquitinga', value: 'Itaquitinga' },
  { label: 'Itarana', value: 'Itarana' },
  { label: 'Itarantim', value: 'Itarantim' },
  { label: 'Itararé', value: 'Itararé' },
  { label: 'Itarema', value: 'Itarema' },
  { label: 'Itariri', value: 'Itariri' },
  { label: 'Itarsi', value: 'Itarsi' },
  { label: 'Itarumã', value: 'Itarumã' },
  { label: 'Itasca', value: 'Itasca' },
  { label: 'Itasca', value: 'Itasca' },
  { label: 'Itasca County', value: 'Itasca County' },
  { label: 'Itati', value: 'Itati' },
  { label: 'Itatí', value: 'Itatí' },
  { label: 'Itatiaia', value: 'Itatiaia' },
  { label: 'Itatiaiuçu', value: 'Itatiaiuçu' },
  { label: 'Itatiba', value: 'Itatiba' },
  { label: 'Itatiba Do Sul', value: 'Itatiba Do Sul' },
  { label: 'Itatim', value: 'Itatim' },
  { label: 'Itatinga', value: 'Itatinga' },
  { label: 'Itatira', value: 'Itatira' },
  { label: 'Itatskiy', value: 'Itatskiy' },
  { label: 'Itatuba', value: 'Itatuba' },
  { label: 'Itaú', value: 'Itaú' },
  { label: 'Itaú De Minas', value: 'Itaú De Minas' },
  { label: 'Itaúba', value: 'Itaúba' },
  { label: 'Itaubal', value: 'Itaubal' },
  { label: 'Itauçu', value: 'Itauçu' },
  { label: 'Itaueira', value: 'Itaueira' },
  { label: 'Itauguá', value: 'Itauguá' },
  { label: 'Itaúna', value: 'Itaúna' },
  { label: 'Itaúna Do Sul', value: 'Itaúna Do Sul' },
  { label: 'Itaunja', value: 'Itaunja' },
  { label: 'Itaverava', value: 'Itaverava' },
  { label: 'Itawamba County', value: 'Itawamba County' },
  { label: 'Itbayat', value: 'Itbayat' },
  { label: 'Itbayat', value: 'Itbayat' },
  { label: 'Itéa', value: 'Itéa' },
  { label: 'Itéa', value: 'Itéa' },
  { label: 'Iten', value: 'Iten' },
  { label: 'Itero De La Vega', value: 'Itero De La Vega' },
  { label: 'Itero Del Castillo', value: 'Itero Del Castillo' },
  { label: 'Iteşti', value: 'Iteşti' },
  { label: 'Iteuil', value: 'Iteuil' },
  { label: 'Itezhi-Tezhi District', value: 'Itezhi-Tezhi District' },
  { label: 'Ithaca', value: 'Ithaca' },
  { label: 'Ithaca', value: 'Ithaca' },
  { label: 'Itháki', value: 'Itháki' },
  { label: 'Itigi', value: 'Itigi' },
  { label: 'Itimadpur', value: 'Itimadpur' },
  { label: 'Itinga', value: 'Itinga' },
  { label: 'Itinga Do Maranhão', value: 'Itinga Do Maranhão' },
  { label: 'Itingen', value: 'Itingen' },
  { label: 'Itiquira', value: 'Itiquira' },
  { label: 'Itirapina', value: 'Itirapina' },
  { label: 'Itirapuã', value: 'Itirapuã' },
  { label: 'Itiruçu', value: 'Itiruçu' },
  { label: 'Itiúba', value: 'Itiúba' },
  { label: 'Itō', value: 'Itō' },
  { label: 'Itō Shi', value: 'Itō Shi' },
  { label: 'Itobi', value: 'Itobi' },
  { label: 'Itogon', value: 'Itogon' },
  { label: 'Itogon', value: 'Itogon' },
  { label: 'Itoigawa', value: 'Itoigawa' },
  { label: 'Itoigawa Shi', value: 'Itoigawa Shi' },
  { label: 'Itoman', value: 'Itoman' },
  { label: 'Itoman Shi', value: 'Itoman Shi' },
  { label: 'Itororó', value: 'Itororó' },
  { label: 'Itoshima-Shi', value: 'Itoshima-Shi' },
  { label: 'Ítrabo', value: 'Ítrabo' },
  { label: 'Itri', value: 'Itri' },
  { label: 'Iţsā', value: 'Iţsā' },
  { label: 'Itsandra', value: 'Itsandra' },
  { label: 'Itsandzéni', value: 'Itsandzéni' },
  { label: 'Itsasondo', value: 'Itsasondo' },
  { label: 'Itsukaichi', value: 'Itsukaichi' },
  { label: 'Itta Bena', value: 'Itta Bena' },
  { label: 'Ittenheim', value: 'Ittenheim' },
  { label: 'Itter', value: 'Itter' },
  { label: 'Itterbeck', value: 'Itterbeck' },
  { label: 'Itteville', value: 'Itteville' },
  { label: 'Ittireddu', value: 'Ittireddu' },
  { label: 'Ittiri', value: 'Ittiri' },
  { label: 'Ittlingen', value: 'Ittlingen' },
  { label: 'Ittre', value: 'Ittre' },
  { label: 'Itu', value: 'Itu' },
  { label: 'Itu', value: 'Itu' },
  { label: 'Ituaçu', value: 'Ituaçu' },
  { label: 'Ituango', value: 'Ituango' },
  { label: 'Ituberá', value: 'Ituberá' },
  { label: 'Ituero De Azaba', value: 'Ituero De Azaba' },
  { label: 'Ituero Y Lama', value: 'Ituero Y Lama' },
  { label: 'Itueta', value: 'Itueta' },
  { label: 'Ituiutaba', value: 'Ituiutaba' },
  { label: 'Itumbiara', value: 'Itumbiara' },
  { label: 'Itumirim', value: 'Itumirim' },
  { label: 'Itum-Kali', value: 'Itum-Kali' },
  { label: 'Itum-Kalinskiy Rayon', value: 'Itum-Kalinskiy Rayon' },
  { label: 'Itupeva', value: 'Itupeva' },
  { label: 'Itupiranga', value: 'Itupiranga' },
  { label: 'Ituporanga', value: 'Ituporanga' },
  { label: 'Iturama', value: 'Iturama' },
  { label: 'Iturbe', value: 'Iturbe' },
  { label: 'Iturbide', value: 'Iturbide' },
  { label: 'Iturbide', value: 'Iturbide' },
  { label: 'Iturbide Nuevo León', value: 'Iturbide Nuevo León' },
  { label: 'Ituren', value: 'Ituren' },
  { label: 'Iturmendi', value: 'Iturmendi' },
  { label: 'Iturrama', value: 'Iturrama' },
  { label: 'Itutinga', value: 'Itutinga' },
  { label: 'Ituverava', value: 'Ituverava' },
  { label: 'Ituzaingó', value: 'Ituzaingó' },
  { label: 'Ituzaingó', value: 'Ituzaingó' },
  { label: 'Itxassou', value: 'Itxassou' },
  { label: 'Itzamatitlán', value: 'Itzamatitlán' },
  { label: 'Itzehoe', value: 'Itzehoe' },
  { label: 'Itzer', value: 'Itzer' },
  { label: 'Itzig', value: 'Itzig' },
  { label: 'Itzincab Palomeque', value: 'Itzincab Palomeque' },
  { label: 'Itzoteno', value: 'Itzoteno' },
  { label: 'Itzstedt', value: 'Itzstedt' },
  { label: 'Iugani', value: 'Iugani' },
  { label: 'Iuiu', value: 'Iuiu' },
  { label: 'Iuka', value: 'Iuka' },
  { label: 'Iúna', value: 'Iúna' },
  { label: 'Iurreta', value: 'Iurreta' },
  { label: 'Iva', value: 'Iva' },
  { label: 'Ivaí', value: 'Ivaí' },
  { label: 'Ivaiporã', value: 'Ivaiporã' },
  { label: 'Ivalo', value: 'Ivalo' },
  { label: 'Ivana', value: 'Ivana' },
  { label: 'Ivana', value: 'Ivana' },
  { label: 'Ivanava', value: 'Ivanava' },
  { label: 'Ivanawski Rayon', value: 'Ivanawski Rayon' },
  { label: 'Ivančice', value: 'Ivančice' },
  { label: 'Ivančna Gorica', value: 'Ivančna Gorica' },
  { label: 'Iváncsa', value: 'Iváncsa' },
  { label: 'Ivanec', value: 'Ivanec' },
  { label: 'Ivănești', value: 'Ivănești' },
  { label: 'Ivănești', value: 'Ivănești' },
  { label: 'Ivangorod', value: 'Ivangorod' },
  { label: 'Ivanhoe', value: 'Ivanhoe' },
  { label: 'Ivanhoe', value: 'Ivanhoe' },
  { label: 'Ivanhoe', value: 'Ivanhoe' },
  { label: 'Ivanhoe East', value: 'Ivanhoe East' },
  { label: 'Ivanino', value: 'Ivanino' },
  { label: 'Ivanishchi', value: 'Ivanishchi' },
  { label: 'Ivanivka', value: 'Ivanivka' },
  { label: 'Ivanivka', value: 'Ivanivka' },
  { label: 'Ivanivka', value: 'Ivanivka' },
  { label: 'Ivanka Pri Dunaji', value: 'Ivanka Pri Dunaji' },
  { label: 'Ivankiv', value: 'Ivankiv' },
  { label: 'Ivankiv', value: 'Ivankiv' },
  { label: 'Ivankiv Raion', value: 'Ivankiv Raion' },
  { label: 'Ivankovci', value: 'Ivankovci' },
  { label: 'Ivankovo', value: 'Ivankovo' },
  { label: 'Ivano-Frankivs’Ka Mis’Krada', value: 'Ivano-Frankivs’Ka Mis’Krada' },
  { label: 'Ivano-Frankivsk', value: 'Ivano-Frankivsk' },
  { label: 'Ivano-Frankove', value: 'Ivano-Frankove' },
  { label: 'Ivanopil', value: 'Ivanopil' },
  { label: 'Ivanovec', value: 'Ivanovec' },
  { label: 'Ivanovice Na Hané', value: 'Ivanovice Na Hané' },
  { label: 'Ivanovka', value: 'Ivanovka' },
  { label: 'Ivanovka', value: 'Ivanovka' },
  { label: 'Ivanovka', value: 'Ivanovka' },
  { label: 'Ivanovka', value: 'Ivanovka' },
  { label: 'Ivanovo', value: 'Ivanovo' },
  { label: 'Ivanovo', value: 'Ivanovo' },
  { label: 'Ivanovskiy Rayon', value: 'Ivanovskiy Rayon' },
  { label: 'Ivanovskiy Rayon', value: 'Ivanovskiy Rayon' },
  { label: 'Ivanovskoye', value: 'Ivanovskoye' },
  { label: 'Ivanovskoye', value: 'Ivanovskoye' },
  { label: 'Ivanska', value: 'Ivanska' },
  { label: 'Ivanteyevka', value: 'Ivanteyevka' },
  { label: 'Ivanteyevka', value: 'Ivanteyevka' },
  { label: 'Ivar', value: 'Ivar' },
  { label: 'Ivars De Noguera', value: 'Ivars De Noguera' },
  { label: 'Ivars Durgell', value: 'Ivars Durgell' },
  { label: 'Ivaté', value: 'Ivaté' },
  { label: 'Ivatsevichy', value: 'Ivatsevichy' },
  { label: 'Ivatuba', value: 'Ivatuba' },
  { label: 'Ivaylovgrad', value: 'Ivaylovgrad' },
  { label: 'Ivdel’', value: 'Ivdel’' },
  { label: 'Iver', value: 'Iver' },
  { label: 'Iver Heath', value: 'Iver Heath' },
  { label: 'Ives Estates', value: 'Ives Estates' },
  { label: 'Iveşti', value: 'Iveşti' },
  { label: 'Iveşti', value: 'Iveşti' },
  { label: 'Ivinghoe', value: 'Ivinghoe' },
  { label: 'Ivinhema', value: 'Ivinhema' },
  { label: 'Ivins', value: 'Ivins' },
  { label: 'Ivisan', value: 'Ivisan' },
  { label: 'Ivnya', value: 'Ivnya' },
  { label: 'Ivnyanskiy Rayon', value: 'Ivnyanskiy Rayon' },
  { label: 'Ivolândia', value: 'Ivolândia' },
  { label: 'Ivolginsk', value: 'Ivolginsk' },
  { label: 'Ivolginskiy Rayon', value: 'Ivolginskiy Rayon' },
  { label: 'Ivorá', value: 'Ivorá' },
  { label: 'Ivorra', value: 'Ivorra' },
  { label: 'Ivot', value: 'Ivot' },
  { label: 'Ivoti', value: 'Ivoti' },
  { label: 'Ivouani', value: 'Ivouani' },
  { label: 'Ivrea', value: 'Ivrea' },
  { label: 'Ivrindi', value: 'Ivrindi' },
  { label: 'Ivry-La-Bataille', value: 'Ivry-La-Bataille' },
  { label: 'Ivry-Sur-Seine', value: 'Ivry-Sur-Seine' },
  { label: 'Ivyanyets', value: 'Ivyanyets' },
  { label: 'Ivybridge', value: 'Ivybridge' },
  { label: 'Ivyland', value: 'Ivyland' },
  { label: 'Iwade', value: 'Iwade' },
  { label: 'Iwade', value: 'Iwade' },
  { label: 'Iwade Shi', value: 'Iwade Shi' },
  { label: 'Iwai', value: 'Iwai' },
  { label: 'Iwaki', value: 'Iwaki' },
  { label: 'Iwaki-Shi', value: 'Iwaki-Shi' },
  { label: 'Iwakuni Shi', value: 'Iwakuni Shi' },
  { label: 'Iwakura', value: 'Iwakura' },
  { label: 'Iwakura-Shi', value: 'Iwakura-Shi' },
  { label: 'Iwamizawa', value: 'Iwamizawa' },
  { label: 'Iwamizawa-Shi', value: 'Iwamizawa-Shi' },
  { label: 'Iwanai', value: 'Iwanai' },
  { label: 'Iwaniska', value: 'Iwaniska' },
  { label: 'Iwanuma', value: 'Iwanuma' },
  { label: 'Iwanuma-Shi', value: 'Iwanuma-Shi' },
  { label: 'Iwase', value: 'Iwase' },
  { label: 'Iwata', value: 'Iwata' },
  { label: 'Iwata-Shi', value: 'Iwata-Shi' },
  { label: 'Iwate-Gun', value: 'Iwate-Gun' },
  { label: 'Iwatsuki', value: 'Iwatsuki' },
  { label: 'Iwierzyce', value: 'Iwierzyce' },
  { label: 'Iwkowa', value: 'Iwkowa' },
  { label: 'Iwo', value: 'Iwo' },
  { label: 'Iwonicz-Zdrój', value: 'Iwonicz-Zdrój' },
  { label: 'Iwŏn-Ŭp', value: 'Iwŏn-Ŭp' },
  { label: 'Iwuy', value: 'Iwuy' },
  { label: 'Iwye', value: 'Iwye' },
  { label: 'Ixcamilpa', value: 'Ixcamilpa' },
  { label: 'Ixcamilpa De Guerrero', value: 'Ixcamilpa De Guerrero' },
  { label: 'Ixcapantla', value: 'Ixcapantla' },
  { label: 'Ixcapuzalco', value: 'Ixcapuzalco' },
  { label: 'Ixcaquixtla', value: 'Ixcaquixtla' },
  { label: 'Ixcateopan', value: 'Ixcateopan' },
  { label: 'Ixcateopan De Cuauhtémoc', value: 'Ixcateopan De Cuauhtémoc' },
  { label: 'Ixcatepec', value: 'Ixcatepec' },
  { label: 'Ixcatla', value: 'Ixcatla' },
  { label: 'Ixcatla', value: 'Ixcatla' },
  { label: 'Ixcatlán', value: 'Ixcatlán' },
  { label: 'Ixchiguán', value: 'Ixchiguán' },
  { label: 'Ixcuinquitlapilco', value: 'Ixcuinquitlapilco' },
  { label: 'Ixhuacán De Los Reyes', value: 'Ixhuacán De Los Reyes' },
  { label: 'Ixhuapan', value: 'Ixhuapan' },
  { label: 'Ixhuatán', value: 'Ixhuatán' },
  { label: 'Ixhuatlán De Madero', value: 'Ixhuatlán De Madero' },
  { label: 'Ixhuatlán Del Café', value: 'Ixhuatlán Del Café' },
  { label: 'Ixhuatlán Del Sureste', value: 'Ixhuatlán Del Sureste' },
  { label: 'Ixhuatlancillo', value: 'Ixhuatlancillo' },
  { label: 'Ixil', value: 'Ixil' },
  { label: 'Ixmiquilpan', value: 'Ixmiquilpan' },
  { label: 'Ixonia', value: 'Ixonia' },
  { label: 'Ixpantepec Nieves', value: 'Ixpantepec Nieves' },
  { label: 'Ixpila', value: 'Ixpila' },
  { label: 'Ixtacamaxtitlán', value: 'Ixtacamaxtitlán' },
  { label: 'Ixtacapa El Chico', value: 'Ixtacapa El Chico' },
  { label: 'Ixtacomitán', value: 'Ixtacomitán' },
  { label: 'Ixtaczoquitlán', value: 'Ixtaczoquitlán' },
  { label: 'Ixtahuacán', value: 'Ixtahuacán' },
  { label: 'Ixtahuiata (La Legua)', value: 'Ixtahuiata (La Legua)' },
  { label: 'Ixtapa', value: 'Ixtapa' },
  { label: 'Ixtapa', value: 'Ixtapa' },
  { label: 'Ixtapa', value: 'Ixtapa' },
  { label: 'Ixtapaluca', value: 'Ixtapaluca' },
  { label: 'Ixtapan De La Concepción', value: 'Ixtapan De La Concepción' },
  { label: 'Ixtapan De La Sal', value: 'Ixtapan De La Sal' },
  { label: 'Ixtapan Del Oro', value: 'Ixtapan Del Oro' },
  { label: 'Ixtapangajoya', value: 'Ixtapangajoya' },
  { label: 'Ixtapa-Zihuatanejo', value: 'Ixtapa-Zihuatanejo' },
  { label: 'Ixtepec', value: 'Ixtepec' },
  { label: 'Ixtepec', value: 'Ixtepec' },
  { label: 'Ixticpan', value: 'Ixticpan' },
  { label: 'Ixtlahuaca', value: 'Ixtlahuaca' },
  { label: 'Ixtlahuaca', value: 'Ixtlahuaca' },
  { label: 'Ixtlahuaca Barrio', value: 'Ixtlahuaca Barrio' },
  { label: 'Ixtlahuaca De Cuauhtémoc', value: 'Ixtlahuaca De Cuauhtémoc' },
  { label: 'Ixtlahuaca De Rayón', value: 'Ixtlahuaca De Rayón' },
  { label: 'Ixtlahuacán', value: 'Ixtlahuacán' },
  { label: 'Ixtlahuacán De Los Membrillos', value: 'Ixtlahuacán De Los Membrillos' },
  { label: 'Ixtlahuacán Del Río', value: 'Ixtlahuacán Del Río' },
  { label: 'Ixtlán De Juárez', value: 'Ixtlán De Juárez' },
  { label: 'Ixtlán De Los Hervores', value: 'Ixtlán De Los Hervores' },
  { label: 'Ixtlán Del Río', value: 'Ixtlán Del Río' },
  { label: 'Ixtlilco El Chico', value: 'Ixtlilco El Chico' },
  { label: 'Ixtolco De Morelos', value: 'Ixtolco De Morelos' },
  { label: 'Ixworth', value: 'Ixworth' },
  { label: 'Iyal Surayh', value: 'Iyal Surayh' },
  { label: 'Iyidere', value: 'Iyidere' },
  { label: 'Iyo', value: 'Iyo' },
  { label: 'Iyo-Shi', value: 'Iyo-Shi' },
  { label: 'Iza', value: 'Iza' },
  { label: 'Iza', value: 'Iza' },
  { label: 'Iza/Itza', value: 'Iza/Itza' },
  { label: 'Izabelin', value: 'Izabelin' },
  { label: 'Izačić', value: 'Izačić' },
  { label: 'Izadkhast', value: 'Izadkhast' },
  { label: 'Izadshahr', value: 'Izadshahr' },
  { label: 'Izagaondoa', value: 'Izagaondoa' },
  { label: 'Izagre', value: 'Izagre' },
  { label: 'Izalco', value: 'Izalco' },
  { label: 'Izalzu/Itzaltzu', value: 'Izalzu/Itzaltzu' },
  { label: 'Izamal', value: 'Izamal' },
  { label: 'Izano', value: 'Izano' },
  { label: 'Izard County', value: 'Izard County' },
  { label: 'Izazi', value: 'Izazi' },
  { label: 'Izbat Al Burj', value: 'Izbat Al Burj' },
  { label: 'Izberbash', value: 'Izberbash' },
  { label: 'Izbica', value: 'Izbica' },
  { label: 'Izbica Kujawska', value: 'Izbica Kujawska' },
  { label: 'Izbiceni', value: 'Izbiceni' },
  { label: 'Izbicko', value: 'Izbicko' },
  { label: 'Izbište', value: 'Izbište' },
  { label: 'Izborsk', value: 'Izborsk' },
  { label: 'Izboskan Tumani', value: 'Izboskan Tumani' },
  { label: 'Izdebnik', value: 'Izdebnik' },
  { label: 'Izeaux', value: 'Izeaux' },
  { label: 'Izegem', value: 'Izegem' },
  { label: 'Izeh', value: 'Izeh' },
  { label: 'Izernore', value: 'Izernore' },
  { label: 'Izhevsk', value: 'Izhevsk' },
  { label: 'Izhevskoye', value: 'Izhevskoye' },
  { label: 'Izhma', value: 'Izhma' },
  { label: 'Izhmorskiy', value: 'Izhmorskiy' },
  { label: 'Izimșa', value: 'Izimșa' },
  { label: 'Izkī', value: 'Izkī' },
  { label: 'Izlake', value: 'Izlake' },
  { label: 'Izluchinsk', value: 'Izluchinsk' },
  { label: 'Izmalkovo', value: 'Izmalkovo' },
  { label: 'Izmalkovskiy Rayon', value: 'Izmalkovskiy Rayon' },
  { label: 'Izmayil', value: 'Izmayil' },
  { label: 'Izmaylovo', value: 'Izmaylovo' },
  { label: 'Izmaylovo', value: 'Izmaylovo' },
  { label: 'Izmir', value: 'Izmir' },
  { label: 'Izmit', value: 'Izmit' },
  { label: 'Iznájar', value: 'Iznájar' },
  { label: 'Iznalloz', value: 'Iznalloz' },
  { label: 'Iznate', value: 'Iznate' },
  { label: 'Iznatoraf', value: 'Iznatoraf' },
  { label: 'Iznik', value: 'Iznik' },
  { label: 'Iznoski', value: 'Iznoski' },
  { label: 'Iznoskovskiy Rayon', value: 'Iznoskovskiy Rayon' },
  { label: 'Izobil’Noye', value: 'Izobil’Noye' },
  { label: 'Izobil’Nyy', value: 'Izobil’Nyy' },
  { label: 'Izola', value: 'Izola' },
  { label: 'Izolta', value: 'Izolta' },
  { label: 'Izon', value: 'Izon' },
  { label: 'Izoplit', value: 'Izoplit' },
  { label: 'Izotepec', value: 'Izotepec' },
  { label: 'Izrā', value: 'Izrā' },
  { label: 'Izra District', value: 'Izra District' },
  { label: 'Izra‘', value: 'Izra‘' },
  { label: 'Izsák', value: 'Izsák' },
  { label: 'Izsófalva', value: 'Izsófalva' },
  { label: 'Iztacalco', value: 'Iztacalco' },
  { label: 'Iztacapa', value: 'Iztacapa' },
  { label: 'Iztapa', value: 'Iztapa' },
  { label: 'Iztapalapa', value: 'Iztapalapa' },
  { label: 'Izu', value: 'Izu' },
  { label: 'Izúcar De Matamoros', value: 'Izúcar De Matamoros' },
  { label: 'Izumi', value: 'Izumi' },
  { label: 'Izumi', value: 'Izumi' },
  { label: 'Izumi Shi', value: 'Izumi Shi' },
  { label: 'Izumi Shi', value: 'Izumi Shi' },
  { label: 'Izumiōtsu', value: 'Izumiōtsu' },
  { label: 'Izumiōtsu Shi', value: 'Izumiōtsu Shi' },
  { label: 'Izumisano', value: 'Izumisano' },
  { label: 'Izumisano Shi', value: 'Izumisano Shi' },
  { label: 'Izumo', value: 'Izumo' },
  { label: 'Izumo Shi', value: 'Izumo Shi' },
  { label: 'Izumrud', value: 'Izumrud' },
  { label: 'Izumrudnoye', value: 'Izumrudnoye' },
  { label: 'Izunokuni-Shi', value: 'Izunokuni-Shi' },
  { label: 'Izurtza', value: 'Izurtza' },
  { label: 'Izu-Shi', value: 'Izu-Shi' },
  { label: 'Izvestkovyy', value: 'Izvestkovyy' },
  { label: 'Izvin', value: 'Izvin' },
  { label: 'Izvoare', value: 'Izvoare' },
  { label: 'Izvoare', value: 'Izvoare' },
  { label: 'Izvoarele', value: 'Izvoarele' },
  { label: 'Izvoarele', value: 'Izvoarele' },
  { label: 'Izvoarele', value: 'Izvoarele' },
  { label: 'Izvoarele', value: 'Izvoarele' },
  { label: 'Izvoarele', value: 'Izvoarele' },
  { label: 'Izvoarele', value: 'Izvoarele' },
  { label: 'Izvoarele', value: 'Izvoarele' },
  { label: 'Izvoarele', value: 'Izvoarele' },
  { label: 'Izvoarele', value: 'Izvoarele' },
  { label: 'Izvoarele', value: 'Izvoarele' },
  { label: 'Izvoarele Sucevei', value: 'Izvoarele Sucevei' },
  { label: 'Izvor', value: 'Izvor' },
  { label: 'Izvoru', value: 'Izvoru' },
  { label: 'Izvoru', value: 'Izvoru' },
  { label: 'Izvoru Bârzii', value: 'Izvoru Bârzii' },
  { label: 'Izvoru Berheciului', value: 'Izvoru Berheciului' },
  { label: 'Izvoru Crişului', value: 'Izvoru Crişului' },
  { label: 'Izvoru De Sus', value: 'Izvoru De Sus' },
  { label: 'Izvoru Dulce', value: 'Izvoru Dulce' },
  { label: 'Izyaslav', value: 'Izyaslav' },
  { label: 'Izyum', value: 'Izyum' },
  { label: 'Izyumovka', value: 'Izyumovka' },
  { label: 'J. Isabel Flores', value: 'J. Isabel Flores' },
  { label: 'J. Jesús Díaz Tzirio', value: 'J. Jesús Díaz Tzirio' },
  { label: 'J. Jesús González Ortega (San Mateo)', value: 'J. Jesús González Ortega (San Mateo)' },
  { label: 'Ja Ela', value: 'Ja Ela' },
  { label: 'Jaala', value: 'Jaala' },
  { label: 'Jabal Ash Sharq', value: 'Jabal Ash Sharq' },
  { label: 'Jabal Habashy', value: 'Jabal Habashy' },
  { label: 'Jabal Iyal Yazid', value: 'Jabal Iyal Yazid' },
  { label: 'Jabal Murad', value: 'Jabal Murad' },
  { label: 'Jabal Os Saraj', value: 'Jabal Os Saraj' },
  { label: 'Jabal Ras', value: 'Jabal Ras' },
  { label: 'Jabaloyas', value: 'Jabaloyas' },
  { label: 'Jabalpur', value: 'Jabalpur' },
  { label: 'Jabalquinto', value: 'Jabalquinto' },
  { label: 'Jabbeke', value: 'Jabbeke' },
  { label: 'Jabenița', value: 'Jabenița' },
  { label: 'Jabing', value: 'Jabing' },
  { label: 'Jabiru', value: 'Jabiru' },
  { label: 'Jablah', value: 'Jablah' },
  { label: 'Jablanica', value: 'Jablanica' },
  { label: 'Jablanica', value: 'Jablanica' },
  { label: 'Jablanovec', value: 'Jablanovec' },
  { label: 'Jableh District', value: 'Jableh District' },
  { label: 'Jabłoń', value: 'Jabłoń' },
  { label: 'Jablonec Nad Jizerou', value: 'Jablonec Nad Jizerou' },
  { label: 'Jablonec Nad Nisou', value: 'Jablonec Nad Nisou' },
  { label: 'Jabłonica Polska', value: 'Jabłonica Polska' },
  { label: 'Jabłonka', value: 'Jabłonka' },
  { label: 'Jabłonka', value: 'Jabłonka' },
  { label: 'Jabłonna', value: 'Jabłonna' },
  { label: 'Jabłonna', value: 'Jabłonna' },
  { label: 'Jabłonna', value: 'Jabłonna' },
  { label: 'Jabłonna Lacka', value: 'Jabłonna Lacka' },
  { label: 'Jablonné Nad Orlicí', value: 'Jablonné Nad Orlicí' },
  { label: 'Jablonné V Podještědí', value: 'Jablonné V Podještědí' },
  { label: 'Jabłonowo Pomorskie', value: 'Jabłonowo Pomorskie' },
  { label: 'Jablůnka', value: 'Jablůnka' },
  { label: 'Jablunkov', value: 'Jablunkov' },
  { label: 'Jaboatão', value: 'Jaboatão' },
  { label: 'Jaboatão Dos Guararapes', value: 'Jaboatão Dos Guararapes' },
  { label: 'Jaboncillo', value: 'Jaboncillo' },
  { label: 'Jabonga', value: 'Jabonga' },
  { label: 'Jabonga', value: 'Jabonga' },
  { label: 'Jaborá', value: 'Jaborá' },
  { label: 'Jaborandi', value: 'Jaborandi' },
  { label: 'Jaborandi', value: 'Jaborandi' },
  { label: 'Jaboti', value: 'Jaboti' },
  { label: 'Jaboticaba', value: 'Jaboticaba' },
  { label: 'Jaboticabal', value: 'Jaboticabal' },
  { label: 'Jaboticatubas', value: 'Jaboticatubas' },
  { label: 'Jabugo', value: 'Jabugo' },
  { label: 'Jaca', value: 'Jaca' },
  { label: 'Jacala', value: 'Jacala' },
  { label: 'Jacaleapa', value: 'Jacaleapa' },
  { label: 'Jacaltenango', value: 'Jacaltenango' },
  { label: 'Jacana', value: 'Jacana' },
  { label: 'Jaçanã', value: 'Jaçanã' },
  { label: 'Jacaraci', value: 'Jacaraci' },
  { label: 'Jacarandas', value: 'Jacarandas' },
  { label: 'Jacaraú', value: 'Jacaraú' },
  { label: 'Jacaré Dos Homens', value: 'Jacaré Dos Homens' },
  { label: 'Jacareacanga', value: 'Jacareacanga' },
  { label: 'Jacareí', value: 'Jacareí' },
  { label: 'Jacarezinho', value: 'Jacarezinho' },
  { label: 'Jacarilla', value: 'Jacarilla' },
  { label: 'Jáchymov', value: 'Jáchymov' },
  { label: 'Jaci', value: 'Jaci' },
  { label: 'Jaciara', value: 'Jaciara' },
  { label: 'Jacinto', value: 'Jacinto' },
  { label: 'Jacinto Arauz', value: 'Jacinto Arauz' },
  { label: 'Jacinto City', value: 'Jacinto City' },
  { label: 'Jacinto Machado', value: 'Jacinto Machado' },
  { label: 'Jacinto Vera', value: 'Jacinto Vera' },
  { label: 'Jack County', value: 'Jack County' },
  { label: 'Jackass Flat', value: 'Jackass Flat' },
  { label: 'Jackpot', value: 'Jackpot' },
  { label: 'Jacks Hill', value: 'Jacks Hill' },
  { label: 'Jacks River', value: 'Jacks River' },
  { label: 'Jacksboro', value: 'Jacksboro' },
  { label: 'Jacksboro', value: 'Jacksboro' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson', value: 'Jackson' },
  { label: 'Jackson Center', value: 'Jackson Center' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson County', value: 'Jackson County' },
  { label: 'Jackson Heights', value: 'Jackson Heights' },
  { label: 'Jackson Parish', value: 'Jackson Parish' },
  { label: 'Jackson Town', value: 'Jackson Town' },
  { label: 'Jacksonville', value: 'Jacksonville' },
  { label: 'Jacksonville', value: 'Jacksonville' },
  { label: 'Jacksonville', value: 'Jacksonville' },
  { label: 'Jacksonville', value: 'Jacksonville' },
  { label: 'Jacksonville', value: 'Jacksonville' },
  { label: 'Jacksonville', value: 'Jacksonville' },
  { label: 'Jacksonville', value: 'Jacksonville' },
  { label: 'Jacksonville Beach', value: 'Jacksonville Beach' },
  { label: 'Jacksonwald', value: 'Jacksonwald' },
  { label: 'Jaclupan', value: 'Jaclupan' },
  { label: 'Jacmel', value: 'Jacmel' },
  { label: 'Jacmel', value: 'Jacmel' },
  { label: 'Jacó', value: 'Jacó' },
  { label: 'Jacobabad', value: 'Jacobabad' },
  { label: 'Jacob-Bellecombette', value: 'Jacob-Bellecombette' },
  { label: 'Jacobina', value: 'Jacobina' },
  { label: 'Jacobina Do Piauí', value: 'Jacobina Do Piauí' },
  { label: 'Jacobs Well', value: 'Jacobs Well' },
  { label: 'Jacobsdorf', value: 'Jacobsdorf' },
  { label: 'Jacobus', value: 'Jacobus' },
  { label: 'Jacodu', value: 'Jacodu' },
  { label: 'Jacona', value: 'Jacona' },
  { label: 'Jacona De Plancarte', value: 'Jacona De Plancarte' },
  { label: 'Jacou', value: 'Jacou' },
  { label: 'Jacu', value: 'Jacu' },
  { label: 'Jacuí', value: 'Jacuí' },
  { label: 'Jacuípe', value: 'Jacuípe' },
  { label: 'Jacuizinho', value: 'Jacuizinho' },
  { label: 'Jacundá', value: 'Jacundá' },
  { label: 'Jacupiranga', value: 'Jacupiranga' },
  { label: 'Jacurso', value: 'Jacurso' },
  { label: 'Jacutinga', value: 'Jacutinga' },
  { label: 'Jacutinga', value: 'Jacutinga' },
  { label: 'Jadidah', value: 'Jadidah' },
  { label: 'Jadów', value: 'Jadów' },
  { label: 'Jadowniki', value: 'Jadowniki' },
  { label: 'Jadowniki Mokre', value: 'Jadowniki Mokre' },
  { label: 'Jadranovo', value: 'Jadranovo' },
  { label: 'Jadranska Lešnica', value: 'Jadranska Lešnica' },
  { label: 'Jadraque', value: 'Jadraque' },
  { label: 'Jadriyah', value: 'Jadriyah' },
  { label: 'Jægerspris', value: 'Jægerspris' },
  { label: 'Jaen', value: 'Jaen' },
  { label: 'Jaen', value: 'Jaen' },
  { label: 'Jaén', value: 'Jaén' },
  { label: 'Jaén', value: 'Jaén' },
  { label: 'Jaena', value: 'Jaena' },
  { label: 'Jafarabad', value: 'Jafarabad' },
  { label: 'Jāfarābād District', value: 'Jāfarābād District' },
  { label: 'Jafarie', value: 'Jafarie' },
  { label: 'Jafarpur', value: 'Jafarpur' },
  { label: 'Jaffa', value: 'Jaffa' },
  { label: 'Jaffna', value: 'Jaffna' },
  { label: 'Jaffna District', value: 'Jaffna District' },
  { label: 'Jaffrey', value: 'Jaffrey' },
  { label: 'Jafre', value: 'Jafre' },
  { label: 'Jagadhri', value: 'Jagadhri' },
  { label: 'Jagalur', value: 'Jagalur' },
  { label: 'Jagannathpur', value: 'Jagannathpur' },
  { label: 'Jagatsinghpur', value: 'Jagatsinghpur' },
  { label: 'Jagdalpur', value: 'Jagdalpur' },
  { label: 'Jagdishpur', value: 'Jagdishpur' },
  { label: 'Jagdispur', value: 'Jagdispur' },
  { label: 'Jagerberg', value: 'Jagerberg' },
  { label: 'Jagershoef', value: 'Jagershoef' },
  { label: 'Jaggayyapeta', value: 'Jaggayyapeta' },
  { label: 'Jagiełła', value: 'Jagiełła' },
  { label: 'Jagna', value: 'Jagna' },
  { label: 'Jagnair', value: 'Jagnair' },
  { label: 'Jagodina', value: 'Jagodina' },
  { label: 'Jagodje', value: 'Jagodje' },
  { label: 'Jagodnjak', value: 'Jagodnjak' },
  { label: 'Jagraon', value: 'Jagraon' },
  { label: 'Jagsthausen', value: 'Jagsthausen' },
  { label: 'Jagstzell', value: 'Jagstzell' },
  { label: 'Jagtial', value: 'Jagtial' },
  { label: 'Jagual', value: 'Jagual' },
  { label: 'Jaguapitã', value: 'Jaguapitã' },
  { label: 'Jaguaquara', value: 'Jaguaquara' },
  { label: 'Jaguaraçu', value: 'Jaguaraçu' },
  { label: 'Jaguarão', value: 'Jaguarão' },
  { label: 'Jaguarari', value: 'Jaguarari' },
  { label: 'Jaguaré', value: 'Jaguaré' },
  { label: 'Jaguaretama', value: 'Jaguaretama' },
  { label: 'Jaguari', value: 'Jaguari' },
  { label: 'Jaguariaíva', value: 'Jaguariaíva' },
  { label: 'Jaguaribara', value: 'Jaguaribara' },
  { label: 'Jaguaribe', value: 'Jaguaribe' },
  { label: 'Jaguaripe', value: 'Jaguaripe' },
  { label: 'Jaguariúna', value: 'Jaguariúna' },
  { label: 'Jaguaruana', value: 'Jaguaruana' },
  { label: 'Jaguaruna', value: 'Jaguaruna' },
  { label: 'Jagüey Blanco', value: 'Jagüey Blanco' },
  { label: 'Jagüey De Téllez (Estación Téllez)', value: 'Jagüey De Téllez (Estación Téllez)' },
  { label: 'Jagüey Grande', value: 'Jagüey Grande' },
  { label: 'Jaguimitan', value: 'Jaguimitan' },
  { label: 'Jagupit', value: 'Jagupit' },
  { label: 'Jagupit', value: 'Jagupit' },
  { label: 'Jahanabad', value: 'Jahanabad' },
  { label: 'Jahanabad', value: 'Jahanabad' },
  { label: 'Jahanabad (Pilibhit)', value: 'Jahanabad (Pilibhit)' },
  { label: 'Jahangirabad', value: 'Jahangirabad' },
  { label: 'Jahangirpur', value: 'Jahangirpur' },
  { label: 'Jahanian Shah', value: 'Jahanian Shah' },
  { label: 'Jahazpur', value: 'Jahazpur' },
  { label: 'Jahnsdorf', value: 'Jahnsdorf' },
  { label: 'Jahran', value: 'Jahran' },
  { label: 'Jahrom', value: 'Jahrom' },
  { label: 'Jaíba', value: 'Jaíba' },
  { label: 'Jaibón', value: 'Jaibón' },
  { label: 'Jaicós', value: 'Jaicós' },
  { label: 'Jaidhof', value: 'Jaidhof' },
  { label: 'Jaigaon', value: 'Jaigaon' },
  { label: 'Jainpur', value: 'Jainpur' },
  { label: 'Jaipur', value: 'Jaipur' },
  { label: 'Jais', value: 'Jais' },
  { label: 'Jaisalmer', value: 'Jaisalmer' },
  { label: 'Jaisinghnagar', value: 'Jaisinghnagar' },
  { label: 'Jaisingpur', value: 'Jaisingpur' },
  { label: 'Jaitapur', value: 'Jaitapur' },
  { label: 'Jaitaran', value: 'Jaitaran' },
  { label: 'Jaithari', value: 'Jaithari' },
  { label: 'Jaito', value: 'Jaito' },
  { label: 'Jajarkot ', value: 'Jajarkot ' },
  { label: 'Jajarm', value: 'Jajarm' },
  { label: 'Jajce', value: 'Jajce' },
  { label: 'Jajpur', value: 'Jajpur' },
  { label: 'Ják', value: 'Ják' },
  { label: 'Jakar', value: 'Jakar' },
  { label: 'Jakartovice', value: 'Jakartovice' },
  { label: 'Jakhal', value: 'Jakhal' },
  { label: 'Jakhaly', value: 'Jakhaly' },
  { label: 'Jakhangaon', value: 'Jakhangaon' },
  { label: 'Jakhau', value: 'Jakhau' },
  { label: 'Jakiri', value: 'Jakiri' },
  { label: 'Jakobsberg', value: 'Jakobsberg' },
  { label: 'Jakobstad', value: 'Jakobstad' },
  { label: 'Jakomini', value: 'Jakomini' },
  { label: 'Jakovlje', value: 'Jakovlje' },
  { label: 'Jakšić', value: 'Jakšić' },
  { label: 'Jakubów', value: 'Jakubów' },
  { label: 'Jakubowice Murowane', value: 'Jakubowice Murowane' },
  { label: 'Jal', value: 'Jal' },
  { label: 'Jalacingo', value: 'Jalacingo' },
  { label: 'Jalai Nur', value: 'Jalai Nur' },
  { label: 'Jalajala', value: 'Jalajala' },
  { label: 'Jalajil', value: 'Jalajil' },
  { label: 'Jalakandapuram', value: 'Jalakandapuram' },
  { label: 'Jalalabad', value: 'Jalalabad' },
  { label: 'Jalalabad', value: 'Jalalabad' },
  { label: 'Jalal-Abad', value: 'Jalal-Abad' },
  { label: 'Jalālābād', value: 'Jalālābād' },
  { label: 'Jalalaqsi', value: 'Jalalaqsi' },
  { label: 'Jalali', value: 'Jalali' },
  { label: 'Jalalpore', value: 'Jalalpore' },
  { label: 'Jalalpur', value: 'Jalalpur' },
  { label: 'Jalalpur', value: 'Jalalpur' },
  { label: 'Jalalpur Jattan', value: 'Jalalpur Jattan' },
  { label: 'Jalalpur Pirwala', value: 'Jalalpur Pirwala' },
  { label: 'Jalance', value: 'Jalance' },
  { label: 'Jalandhar', value: 'Jalandhar' },
  { label: 'Jalapa', value: 'Jalapa' },
  { label: 'Jalapa', value: 'Jalapa' },
  { label: 'Jalapa', value: 'Jalapa' },
  { label: 'Jalapa', value: 'Jalapa' },
  { label: 'Jalapa', value: 'Jalapa' },
  { label: 'Jalapilla', value: 'Jalapilla' },
  { label: 'Jalapita', value: 'Jalapita' },
  { label: 'Jalarpet', value: 'Jalarpet' },
  { label: 'Jalasjärvi', value: 'Jalasjärvi' },
  { label: 'Jalaud', value: 'Jalaud' },
  { label: 'Jalaun', value: 'Jalaun' },
  { label: 'Jalcocotán', value: 'Jalcocotán' },
  { label: 'Jalcomulco', value: 'Jalcomulco' },
  { label: 'Jaleaca De Catalán', value: 'Jaleaca De Catalán' },
  { label: 'Jales', value: 'Jales' },
  { label: 'Jalesar', value: 'Jalesar' },
  { label: 'Jaleshwar', value: 'Jaleshwar' },
  { label: 'Jalgaon', value: 'Jalgaon' },
  { label: 'Jalgaon Jamod', value: 'Jalgaon Jamod' },
  { label: 'Jalhay', value: 'Jalhay' },
  { label: 'Jali', value: 'Jali' },
  { label: 'Jalia', value: 'Jalia' },
  { label: 'Jalilabad', value: 'Jalilabad' },
  { label: 'Jalingo', value: 'Jalingo' },
  { label: 'Jalipa', value: 'Jalipa' },
  { label: 'Jalisco', value: 'Jalisco' },
  { label: 'Jaljūlya', value: 'Jaljūlya' },
  { label: 'Jalkot', value: 'Jalkot' },
  { label: 'Jalkovec', value: 'Jalkovec' },
  { label: 'Jallais', value: 'Jallais' },
  { label: 'Jalmolonga (La Hacienda)', value: 'Jalmolonga (La Hacienda)' },
  { label: 'Jalna', value: 'Jalna' },
  { label: 'Jalolkuduk Tumani', value: 'Jalolkuduk Tumani' },
  { label: 'Jalón', value: 'Jalón' },
  { label: 'Jalón De Cameros', value: 'Jalón De Cameros' },
  { label: 'Jalor', value: 'Jalor' },
  { label: 'Jalore', value: 'Jalore' },
  { label: 'Jalostotitlan', value: 'Jalostotitlan' },
  { label: 'Jalousie', value: 'Jalousie' },
  { label: 'Jalpa', value: 'Jalpa' },
  { label: 'Jalpa', value: 'Jalpa' },
  { label: 'Jalpa De Los Baños', value: 'Jalpa De Los Baños' },
  { label: 'Jalpa De Méndez', value: 'Jalpa De Méndez' },
  { label: 'Jalpaiguri', value: 'Jalpaiguri' },
  { label: 'Jalpan', value: 'Jalpan' },
  { label: 'Jalpan', value: 'Jalpan' },
  { label: 'Jalpatagua', value: 'Jalpatagua' },
  { label: 'Jalpilla', value: 'Jalpilla' },
  { label: 'Jalpilla', value: 'Jalpilla' },
  { label: 'Jālq', value: 'Jālq' },
  { label: 'Jalqani', value: 'Jalqani' },
  { label: 'Jaltenango De La Paz', value: 'Jaltenango De La Paz' },
  { label: 'Jaltenco', value: 'Jaltenco' },
  { label: 'Jaltepec', value: 'Jaltepec' },
  { label: 'Jaltepec', value: 'Jaltepec' },
  { label: 'Jaltepec De Candayoc', value: 'Jaltepec De Candayoc' },
  { label: 'Jaltepetongo', value: 'Jaltepetongo' },
  { label: 'Jaltiche De Arriba', value: 'Jaltiche De Arriba' },
  { label: 'Jáltipan De Morelos', value: 'Jáltipan De Morelos' },
  { label: 'Jaltocan', value: 'Jaltocan' },
  { label: 'Jaltocan', value: 'Jaltocan' },
  { label: 'Jaltomate', value: 'Jaltomate' },
  { label: 'Jalubí', value: 'Jalubí' },
  { label: 'Jaluco', value: 'Jaluco' },
  { label: 'Jalupa', value: 'Jalupa' },
  { label: 'Jalžabet', value: 'Jalžabet' },
  { label: 'Jam', value: 'Jam' },
  { label: 'Jām Sāhib', value: 'Jām Sāhib' },
  { label: 'Jamaame', value: 'Jamaame' },
  { label: 'Jamabalod', value: 'Jamabalod' },
  { label: 'Jamadoba', value: 'Jamadoba' },
  { label: 'Jamai', value: 'Jamai' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Jamaica Beach', value: 'Jamaica Beach' },
  { label: 'Jamaica Plain', value: 'Jamaica Plain' },
  { label: 'Jamalpur', value: 'Jamalpur' },
  { label: 'Jamalpur ', value: 'Jamalpur ' },
  { label: 'Jamalteca', value: 'Jamalteca' },
  { label: 'Jaman North', value: 'Jaman North' },
  { label: 'Jaman South', value: 'Jaman South' },
  { label: 'Jamao Al Norte', value: 'Jamao Al Norte' },
  { label: 'Jamapa', value: 'Jamapa' },
  { label: 'Jamay', value: 'Jamay' },
  { label: 'Jamba', value: 'Jamba' },
  { label: 'Jambaló', value: 'Jambaló' },
  { label: 'Jambeiro', value: 'Jambeiro' },
  { label: 'Jamberoo', value: 'Jamberoo' },
  { label: 'Jambi City', value: 'Jambi City' },
  { label: 'Jamboree Heights', value: 'Jamboree Heights' },
  { label: 'Jambrina', value: 'Jambrina' },
  { label: 'Jambuda', value: 'Jambuda' },
  { label: 'Jambusar', value: 'Jambusar' },
  { label: 'Jameln', value: 'Jameln' },
  { label: 'James City', value: 'James City' },
  { label: 'James City County', value: 'James City County' },
  { label: 'James Craik', value: 'James Craik' },
  { label: 'James Hill', value: 'James Hill' },
  { label: 'James Island', value: 'James Island' },
  { label: 'Jamesburg', value: 'Jamesburg' },
  { label: 'Jamesport', value: 'Jamesport' },
  { label: 'Jamestown', value: 'Jamestown' },
  { label: 'Jamestown', value: 'Jamestown' },
  { label: 'Jamestown', value: 'Jamestown' },
  { label: 'Jamestown', value: 'Jamestown' },
  { label: 'Jamestown', value: 'Jamestown' },
  { label: 'Jamestown', value: 'Jamestown' },
  { label: 'Jamestown', value: 'Jamestown' },
  { label: 'Jamestown', value: 'Jamestown' },
  { label: 'Jamestown', value: 'Jamestown' },
  { label: 'Jamestown West', value: 'Jamestown West' },
  { label: 'Jämijärvi', value: 'Jämijärvi' },
  { label: 'Jamila', value: 'Jamila' },
  { label: 'Jamilena', value: 'Jamilena' },
  { label: 'Jamiltepec', value: 'Jamiltepec' },
  { label: 'Jamindan', value: 'Jamindan' },
  { label: 'Jamishlu', value: 'Jamishlu' },
  { label: 'Jamisontown', value: 'Jamisontown' },
  { label: 'Jamjamāl', value: 'Jamjamāl' },
  { label: 'Jämjö', value: 'Jämjö' },
  { label: 'Jamkhandi', value: 'Jamkhandi' },
  { label: 'Jamkhed', value: 'Jamkhed' },
  { label: 'Jammalamadugu', value: 'Jammalamadugu' },
  { label: 'Jammerbugt Kommune', value: 'Jammerbugt Kommune' },
  { label: 'Jammu', value: 'Jammu' },
  { label: 'Jamnagar', value: 'Jamnagar' },
  { label: 'Jamod', value: 'Jamod' },
  { label: 'Jampang', value: 'Jampang' },
  { label: 'Jampruca', value: 'Jampruca' },
  { label: 'Jampur', value: 'Jampur' },
  { label: 'Jämsä', value: 'Jämsä' },
  { label: 'Jämsänkoski', value: 'Jämsänkoski' },
  { label: 'Jamshedpur', value: 'Jamshedpur' },
  { label: 'Jämshög', value: 'Jämshög' },
  { label: 'Jamshoro', value: 'Jamshoro' },
  { label: 'Jamtara', value: 'Jamtara' },
  { label: 'Jamu Mare', value: 'Jamu Mare' },
  { label: 'Jamui', value: 'Jamui' },
  { label: 'Jamul', value: 'Jamul' },
  { label: 'Jamundí', value: 'Jamundí' },
  { label: 'Jamuria', value: 'Jamuria' },
  { label: 'Jan Juc', value: 'Jan Juc' },
  { label: 'Jan Kempdorp', value: 'Jan Kempdorp' },
  { label: 'Jana La', value: 'Jana La' },
  { label: 'Janagdong', value: 'Janagdong' },
  { label: 'Janah', value: 'Janah' },
  { label: 'Janakkala', value: 'Janakkala' },
  { label: 'Janambo', value: 'Janambo' },
  { label: 'Janamuato', value: 'Janamuato' },
  { label: 'Janaúba', value: 'Janaúba' },
  { label: 'Jand', value: 'Jand' },
  { label: 'Jandaia', value: 'Jandaia' },
  { label: 'Jandaia Do Sul', value: 'Jandaia Do Sul' },
  { label: 'Jandaíra', value: 'Jandaíra' },
  { label: 'Jandaíra', value: 'Jandaíra' },
  { label: 'Jandakot', value: 'Jandakot' },
  { label: 'Jandaq', value: 'Jandaq' },
  { label: 'Jandayan Norte', value: 'Jandayan Norte' },
  { label: 'Jandelsbrunn', value: 'Jandelsbrunn' },
  { label: 'Jandiala', value: 'Jandiala' },
  { label: 'Jandiala Guru', value: 'Jandiala Guru' },
  { label: 'Jandiala Sher Khan', value: 'Jandiala Sher Khan' },
  { label: 'Jandira', value: 'Jandira' },
  { label: 'Jandowae', value: 'Jandowae' },
  { label: 'Janduís', value: 'Janduís' },
  { label: 'Jane Brook', value: 'Jane Brook' },
  { label: 'Janeng', value: 'Janeng' },
  { label: 'Janephal', value: 'Janephal' },
  { label: 'Janesville', value: 'Janesville' },
  { label: 'Janesville', value: 'Janesville' },
  { label: 'Janesville', value: 'Janesville' },
  { label: 'Janfida', value: 'Janfida' },
  { label: 'Jangada', value: 'Jangada' },
  { label: 'Jangal', value: 'Jangal' },
  { label: 'Jangaon', value: 'Jangaon' },
  { label: 'Janghai', value: 'Janghai' },
  { label: 'Jangheung-Gun', value: 'Jangheung-Gun' },
  { label: 'Jangipur', value: 'Jangipur' },
  { label: 'Jangseong-Gun', value: 'Jangseong-Gun' },
  { label: 'Jangsu-Gun', value: 'Jangsu-Gun' },
  { label: 'Janikowo', value: 'Janikowo' },
  { label: 'Janiópolis', value: 'Janiópolis' },
  { label: 'Janitzio', value: 'Janitzio' },
  { label: 'Janiuay', value: 'Janiuay' },
  { label: 'Janja', value: 'Janja' },
  { label: 'Janjanbureh', value: 'Janjanbureh' },
  { label: 'Janjgir', value: 'Janjgir' },
  { label: 'Janjgir-Champa', value: 'Janjgir-Champa' },
  { label: 'Jankomir', value: 'Jankomir' },
  { label: 'Jankovec', value: 'Jankovec' },
  { label: 'Janków Przygodzki', value: 'Janków Przygodzki' },
  { label: 'Jankowice', value: 'Jankowice' },
  { label: 'Jankowice Rybnickie', value: 'Jankowice Rybnickie' },
  { label: 'Jannali', value: 'Jannali' },
  { label: 'Jannat Makan', value: 'Jannat Makan' },
  { label: 'Jannatshahr', value: 'Jannatshahr' },
  { label: 'Janneyrias', value: 'Janneyrias' },
  { label: 'Jano', value: 'Jano' },
  { label: 'Janopol', value: 'Janopol' },
  { label: 'Janos', value: 'Janos' },
  { label: 'Jánoshalma', value: 'Jánoshalma' },
  { label: 'Jánoshalmai Járás', value: 'Jánoshalmai Járás' },
  { label: 'Jánosháza', value: 'Jánosháza' },
  { label: 'Jánoshida', value: 'Jánoshida' },
  { label: 'Janošik', value: 'Janošik' },
  { label: 'Jánossomorja', value: 'Jánossomorja' },
  { label: 'Janov Nad Nisou', value: 'Janov Nad Nisou' },
  { label: 'Janovice', value: 'Janovice' },
  { label: 'Janovice Nad Úhlavou', value: 'Janovice Nad Úhlavou' },
  { label: 'Janów Lubelski', value: 'Janów Lubelski' },
  { label: 'Janów Podlaski', value: 'Janów Podlaski' },
  { label: 'Janowice', value: 'Janowice' },
  { label: 'Janowice', value: 'Janowice' },
  { label: 'Janowice Wielkie', value: 'Janowice Wielkie' },
  { label: 'Janowiec', value: 'Janowiec' },
  { label: 'Janowiec Wielkopolski', value: 'Janowiec Wielkopolski' },
  { label: 'Jan-Phyl Village', value: 'Jan-Phyl Village' },
  { label: 'Jans', value: 'Jans' },
  { label: 'Jansath', value: 'Jansath' },
  { label: 'Jänschwalde', value: 'Jänschwalde' },
  { label: 'Jantetelco', value: 'Jantetelco' },
  { label: 'Jantianon', value: 'Jantianon' },
  { label: 'Januária', value: 'Januária' },
  { label: 'Janūb As Surrah', value: 'Janūb As Surrah' },
  { label: 'Januszkowice', value: 'Januszkowice' },
  { label: 'Janville', value: 'Janville' },
  { label: 'Janville-Sur-Juine', value: 'Janville-Sur-Juine' },
  { label: 'Janzé', value: 'Janzé' },
  { label: 'Jaoli', value: 'Jaoli' },
  { label: 'Jaora', value: 'Jaora' },
  { label: 'Japaraíba', value: 'Japaraíba' },
  { label: 'Japaratinga', value: 'Japaratinga' },
  { label: 'Japaratuba', value: 'Japaratuba' },
  { label: 'Japeri', value: 'Japeri' },
  { label: 'Japi', value: 'Japi' },
  { label: 'Japira', value: 'Japira' },
  { label: 'Japitan', value: 'Japitan' },
  { label: 'Japitan', value: 'Japitan' },
  { label: 'Japoatã', value: 'Japoatã' },
  { label: 'Japons', value: 'Japons' },
  { label: 'Japonvar', value: 'Japonvar' },
  { label: 'Japorã', value: 'Japorã' },
  { label: 'Jäppilä', value: 'Jäppilä' },
  { label: 'Japurá', value: 'Japurá' },
  { label: 'Japurá', value: 'Japurá' },
  { label: 'Jaqué', value: 'Jaqué' },
  { label: 'Jaqueira', value: 'Jaqueira' },
  { label: 'Jaquimeyes', value: 'Jaquimeyes' },
  { label: 'Jaquirana', value: 'Jaquirana' },
  { label: 'Jaraba', value: 'Jaraba' },
  { label: 'Jarabacoa', value: 'Jarabacoa' },
  { label: 'Jarābulus', value: 'Jarābulus' },
  { label: 'Jarácuaro', value: 'Jarácuaro' },
  { label: 'Jaraczewo', value: 'Jaraczewo' },
  { label: 'Jarafuel', value: 'Jarafuel' },
  { label: 'Jaraguá', value: 'Jaraguá' },
  { label: 'Jaraguá Do Sul', value: 'Jaraguá Do Sul' },
  { label: 'Jaraguari', value: 'Jaraguari' },
  { label: 'Jaraicejo', value: 'Jaraicejo' },
  { label: 'Jaraíz De La Vera', value: 'Jaraíz De La Vera' },
  { label: 'Jarak', value: 'Jarak' },
  { label: 'Jaral Del Progreso', value: 'Jaral Del Progreso' },
  { label: 'Jarales', value: 'Jarales' },
  { label: 'Jaramānā', value: 'Jaramānā' },
  { label: 'Jaramataia', value: 'Jaramataia' },
  { label: 'Jaramillo De La Fuente', value: 'Jaramillo De La Fuente' },
  { label: 'Jaramillo Quemado', value: 'Jaramillo Quemado' },
  { label: 'Jarandilla De La Vera', value: 'Jarandilla De La Vera' },
  { label: 'Jaranwala', value: 'Jaranwala' },
  { label: 'Jarash', value: 'Jarash' },
  { label: 'Järbo', value: 'Järbo' },
  { label: 'Jarczów', value: 'Jarczów' },
  { label: 'Jardan', value: 'Jardan' },
  { label: 'Járdánháza', value: 'Járdánháza' },
  { label: 'Jardim', value: 'Jardim' },
  { label: 'Jardim', value: 'Jardim' },
  { label: 'Jardim Alegre', value: 'Jardim Alegre' },
  { label: 'Jardim De Angicos', value: 'Jardim De Angicos' },
  { label: 'Jardim De Piranhas', value: 'Jardim De Piranhas' },
  { label: 'Jardim Do Mulato', value: 'Jardim Do Mulato' },
  { label: 'Jardim Do Seridó', value: 'Jardim Do Seridó' },
  { label: 'Jardim Olinda', value: 'Jardim Olinda' },
  { label: 'Jardim Paulista', value: 'Jardim Paulista' },
  { label: 'Jardin', value: 'Jardin' },
  { label: 'Jardín', value: 'Jardín' },
  { label: 'Jardín América', value: 'Jardín América' },
  { label: 'Jardines De La Silla', value: 'Jardines De La Silla' },
  { label: 'Jardines De La Silla (Jardines)', value: 'Jardines De La Silla (Jardines)' },
  { label: 'Jardines De San Sebastián', value: 'Jardines De San Sebastián' },
  { label: 'Jardines Del Edén', value: 'Jardines Del Edén' },
  { label: 'Jardínes Del Grijalva', value: 'Jardínes Del Grijalva' },
  { label: 'Jardines Del Hipódromo', value: 'Jardines Del Hipódromo' },
  { label: 'Jardinópolis', value: 'Jardinópolis' },
  { label: 'Jardinópolis', value: 'Jardinópolis' },
  { label: 'Jard-Sur-Mer', value: 'Jard-Sur-Mer' },
  { label: 'Jarebice', value: 'Jarebice' },
  { label: 'Jargeau', value: 'Jargeau' },
  { label: 'Jari', value: 'Jari' },
  { label: 'Jaria', value: 'Jaria' },
  { label: 'Jarigue', value: 'Jarigue' },
  { label: 'Jarilla', value: 'Jarilla' },
  { label: 'Jarillas', value: 'Jarillas' },
  { label: 'Jarinu', value: 'Jarinu' },
  { label: 'Jaripeo', value: 'Jaripeo' },
  { label: 'Jariştea', value: 'Jariştea' },
  { label: 'Jaritas', value: 'Jaritas' },
  { label: 'Jarkovac', value: 'Jarkovac' },
  { label: 'Jarmelo São Miguel', value: 'Jarmelo São Miguel' },
  { label: 'Jarmelo São Pedro', value: 'Jarmelo São Pedro' },
  { label: 'Jarmen', value: 'Jarmen' },
  { label: 'Jarmina', value: 'Jarmina' },
  { label: 'Järna', value: 'Järna' },
  { label: 'Järna', value: 'Järna' },
  { label: 'Jarnac', value: 'Jarnac' },
  { label: 'Jarnołtów-Jerzmanowo', value: 'Jarnołtów-Jerzmanowo' },
  { label: 'Jarny', value: 'Jarny' },
  { label: 'Jaro', value: 'Jaro' },
  { label: 'Jaro', value: 'Jaro' },
  { label: 'Jarocin', value: 'Jarocin' },
  { label: 'Jarocin', value: 'Jarocin' },
  { label: 'Jarod', value: 'Jarod' },
  { label: 'Jaroměř', value: 'Jaroměř' },
  { label: 'Jaroměřice', value: 'Jaroměřice' },
  { label: 'Jaroměřice Nad Rokytnou', value: 'Jaroměřice Nad Rokytnou' },
  { label: 'Jaroslavice', value: 'Jaroslavice' },
  { label: 'Jarosław', value: 'Jarosław' },
  { label: 'Jarošov Nad Nežárkou', value: 'Jarošov Nad Nežárkou' },
  { label: 'Jaroszowice', value: 'Jaroszowice' },
  { label: 'Jarplund-Weding', value: 'Jarplund-Weding' },
  { label: 'Jarque', value: 'Jarque' },
  { label: 'Jarque De La Val', value: 'Jarque De La Val' },
  { label: 'Jarra Central', value: 'Jarra Central' },
  { label: 'Jarra East', value: 'Jarra East' },
  { label: 'Jarra West', value: 'Jarra West' },
  { label: 'Jarrahdale', value: 'Jarrahdale' },
  { label: 'Jarrell', value: 'Jarrell' },
  { label: 'Jarreng', value: 'Jarreng' },
  { label: 'Jarrettsville', value: 'Jarrettsville' },
  { label: 'Jarrie', value: 'Jarrie' },
  { label: 'Jarrow', value: 'Jarrow' },
  { label: 'Jarše District', value: 'Jarše District' },
  { label: 'Jaru', value: 'Jaru' },
  { label: 'Jaruco', value: 'Jaruco' },
  { label: 'Järva-Jaani', value: 'Järva-Jaani' },
  { label: 'Järvakandi', value: 'Järvakandi' },
  { label: 'Järvenpää', value: 'Järvenpää' },
  { label: 'Jarville-La-Malgrange', value: 'Jarville-La-Malgrange' },
  { label: 'Jarvis', value: 'Jarvis' },
  { label: 'Järvsö', value: 'Järvsö' },
  { label: 'Jarwa', value: 'Jarwa' },
  { label: 'Jarwal', value: 'Jarwal' },
  { label: 'Jarwal', value: 'Jarwal' },
  { label: 'Jarzé', value: 'Jarzé' },
  { label: 'Jasa', value: 'Jasa' },
  { label: 'Jaša Tomić', value: 'Jaša Tomić' },
  { label: 'Jasaan', value: 'Jasaan' },
  { label: 'Jasdan', value: 'Jasdan' },
  { label: 'Jasenice', value: 'Jasenice' },
  { label: 'Jashpur', value: 'Jashpur' },
  { label: 'Jashpurnagar', value: 'Jashpurnagar' },
  { label: 'Jasidih', value: 'Jasidih' },
  { label: 'Jasień', value: 'Jasień' },
  { label: 'Jasień', value: 'Jasień' },
  { label: 'Jasienica', value: 'Jasienica' },
  { label: 'Jasienica', value: 'Jasienica' },
  { label: 'Jasienica', value: 'Jasienica' },
  { label: 'Jasienica Rosielna', value: 'Jasienica Rosielna' },
  { label: 'Jasieniec', value: 'Jasieniec' },
  { label: 'Jasikan', value: 'Jasikan' },
  { label: 'Jāsim', value: 'Jāsim' },
  { label: 'Jasionów', value: 'Jasionów' },
  { label: 'Jask', value: 'Jask' },
  { label: 'Jaśliska', value: 'Jaśliska' },
  { label: 'Jasło', value: 'Jasło' },
  { label: 'Jasmine Estates', value: 'Jasmine Estates' },
  { label: 'Jasonville', value: 'Jasonville' },
  { label: 'Jasper', value: 'Jasper' },
  { label: 'Jasper', value: 'Jasper' },
  { label: 'Jasper', value: 'Jasper' },
  { label: 'Jasper', value: 'Jasper' },
  { label: 'Jasper', value: 'Jasper' },
  { label: 'Jasper', value: 'Jasper' },
  { label: 'Jasper', value: 'Jasper' },
  { label: 'Jasper County', value: 'Jasper County' },
  { label: 'Jasper County', value: 'Jasper County' },
  { label: 'Jasper County', value: 'Jasper County' },
  { label: 'Jasper County', value: 'Jasper County' },
  { label: 'Jasper County', value: 'Jasper County' },
  { label: 'Jasper County', value: 'Jasper County' },
  { label: 'Jasper County', value: 'Jasper County' },
  { label: 'Jasper County', value: 'Jasper County' },
  { label: 'Jasper Park Lodge', value: 'Jasper Park Lodge' },
  { label: 'Jaspur', value: 'Jaspur' },
  { label: 'Jasrana', value: 'Jasrana' },
  { label: 'Jassans-Riottier', value: 'Jassans-Riottier' },
  { label: 'Jasseron', value: 'Jasseron' },
  { label: 'Jastarnia', value: 'Jastarnia' },
  { label: 'Jastków', value: 'Jastków' },
  { label: 'Jastrebarsko', value: 'Jastrebarsko' },
  { label: 'Jastrowie', value: 'Jastrowie' },
  { label: 'Jastrząb', value: 'Jastrząb' },
  { label: 'Jastrząb', value: 'Jastrząb' },
  { label: 'Jastrzębia', value: 'Jastrzębia' },
  { label: 'Jastrzębia', value: 'Jastrzębia' },
  { label: 'Jastrzębia Góra', value: 'Jastrzębia Góra' },
  { label: 'Jastrzębie Zdrój', value: 'Jastrzębie Zdrój' },
  { label: 'Jastrzębie-Zdrój', value: 'Jastrzębie-Zdrój' },
  { label: 'Jaswantnagar', value: 'Jaswantnagar' },
  { label: 'Jászalsószentgyörgy', value: 'Jászalsószentgyörgy' },
  { label: 'Jászapáti', value: 'Jászapáti' },
  { label: 'Jászapáti Járás', value: 'Jászapáti Járás' },
  { label: 'Jászárokszállás', value: 'Jászárokszállás' },
  { label: 'Jászberény', value: 'Jászberény' },
  { label: 'Jászberényi Járás', value: 'Jászberényi Járás' },
  { label: 'Jászdózsa', value: 'Jászdózsa' },
  { label: 'Jászjákóhalma', value: 'Jászjákóhalma' },
  { label: 'Jászkarajenő', value: 'Jászkarajenő' },
  { label: 'Jászkisér', value: 'Jászkisér' },
  { label: 'Jászladány', value: 'Jászladány' },
  { label: 'Jászszentandrás', value: 'Jászszentandrás' },
  { label: 'Jászszentlászló', value: 'Jászszentlászló' },
  { label: 'Jat Sangli', value: 'Jat Sangli' },
  { label: 'Jataí', value: 'Jataí' },
  { label: 'Jataizinho', value: 'Jataizinho' },
  { label: 'Jatani', value: 'Jatani' },
  { label: 'Jatara', value: 'Jatara' },
  { label: 'Jataúba', value: 'Jataúba' },
  { label: 'Jategaon', value: 'Jategaon' },
  { label: 'Jateí', value: 'Jateí' },
  { label: 'Jaten', value: 'Jaten' },
  { label: 'Jati', value: 'Jati' },
  { label: 'Jati', value: 'Jati' },
  { label: 'Jatibarang', value: 'Jatibarang' },
  { label: 'Jatibonico', value: 'Jatibonico' },
  { label: 'Jatiel', value: 'Jatiel' },
  { label: 'Jatiroto', value: 'Jatiroto' },
  { label: 'Jatiwangi', value: 'Jatiwangi' },
  { label: 'Jatobá', value: 'Jatobá' },
  { label: 'Jatobá', value: 'Jatobá' },
  { label: 'Jatobá Do Piauí', value: 'Jatobá Do Piauí' },
  { label: 'Jatoi Shimali', value: 'Jatoi Shimali' },
  { label: 'Jatun Orcochiri', value: 'Jatun Orcochiri' },
  { label: 'Jatznick', value: 'Jatznick' },
  { label: 'Jaú', value: 'Jaú' },
  { label: 'Jaú Do Tocantins', value: 'Jaú Do Tocantins' },
  { label: 'Jauca', value: 'Jauca' },
  { label: 'Jauharabad', value: 'Jauharabad' },
  { label: 'Jauja', value: 'Jauja' },
  { label: 'Jauja', value: 'Jauja' },
  { label: 'Jaujac', value: 'Jaujac' },
  { label: 'Jaula De Abajo', value: 'Jaula De Abajo' },
  { label: 'Jaulín', value: 'Jaulín' },
  { label: 'Jaumave', value: 'Jaumave' },
  { label: 'Jaunaglona', value: 'Jaunaglona' },
  { label: 'Jaunay-Clan', value: 'Jaunay-Clan' },
  { label: 'Jaunciems', value: 'Jaunciems' },
  { label: 'Jaunjelgava', value: 'Jaunjelgava' },
  { label: 'Jaunpils', value: 'Jaunpils' },
  { label: 'Jaunpur', value: 'Jaunpur' },
  { label: 'Jaupaci', value: 'Jaupaci' },
  { label: 'Jauregui', value: 'Jauregui' },
  { label: 'Jáuregui', value: 'Jáuregui' },
  { label: 'Jaurian', value: 'Jaurian' },
  { label: 'Jaurrieta', value: 'Jaurrieta' },
  { label: 'Jauru', value: 'Jauru' },
  { label: 'Jausiers', value: 'Jausiers' },
  { label: 'Jaux', value: 'Jaux' },
  { label: 'Java', value: 'Java' },
  { label: 'Javadabad', value: 'Javadabad' },
  { label: 'Javalera', value: 'Javalera' },
  { label: 'Javan Qala', value: 'Javan Qala' },
  { label: 'Javanrud', value: 'Javanrud' },
  { label: 'Javar Sajin', value: 'Javar Sajin' },
  { label: 'Javarseyan', value: 'Javarseyan' },
  { label: 'Javea', value: 'Javea' },
  { label: 'Jávea/Xàbia', value: 'Jávea/Xàbia' },
  { label: 'Javené', value: 'Javené' },
  { label: 'Jävenitz', value: 'Jävenitz' },
  { label: 'Javier', value: 'Javier' },
  { label: 'Javier', value: 'Javier' },
  { label: 'Javier', value: 'Javier' },
  { label: 'Javier', value: 'Javier' },
  { label: 'Javier Rojo Gómez', value: 'Javier Rojo Gómez' },
  { label: 'Javier Rojo Gómez', value: 'Javier Rojo Gómez' },
  { label: 'Javier Rojo Gómez', value: 'Javier Rojo Gómez' },
  { label: 'Javorník', value: 'Javorník' },
  { label: 'Javron-Les-Chapelles', value: 'Javron-Les-Chapelles' },
  { label: 'Jāwā', value: 'Jāwā' },
  { label: 'Jawad', value: 'Jawad' },
  { label: 'Jawala Mukhi', value: 'Jawala Mukhi' },
  { label: 'Jawf Al Maqbābah', value: 'Jawf Al Maqbābah' },
  { label: 'Jawhar', value: 'Jawhar' },
  { label: 'Jawhar', value: 'Jawhar' },
  { label: 'Jawor', value: 'Jawor' },
  { label: 'Jawornik', value: 'Jawornik' },
  { label: 'Jawornik', value: 'Jawornik' },
  { label: 'Jawornik Polski', value: 'Jawornik Polski' },
  { label: 'Jaworze', value: 'Jaworze' },
  { label: 'Jaworzno', value: 'Jaworzno' },
  { label: 'Jaworzyna Śląska', value: 'Jaworzyna Śląska' },
  { label: 'Jaworzynka', value: 'Jaworzynka' },
  { label: 'Jay', value: 'Jay' },
  { label: 'Jay', value: 'Jay' },
  { label: 'Jay County', value: 'Jay County' },
  { label: 'Jayamkondacholapuram', value: 'Jayamkondacholapuram' },
  { label: 'Jayanca', value: 'Jayanca' },
  { label: 'Jayapura', value: 'Jayapura' },
  { label: 'Jayashankar Bhupalapally', value: 'Jayashankar Bhupalapally' },
  { label: 'Jayena', value: 'Jayena' },
  { label: 'Jayezan', value: 'Jayezan' },
  { label: 'Jaynagar', value: 'Jaynagar' },
  { label: 'Jaynagar Majilpur', value: 'Jaynagar Majilpur' },
  { label: 'Jayobamba', value: 'Jayobamba' },
  { label: 'Jayrūd', value: 'Jayrūd' },
  { label: 'Jayshan', value: 'Jayshan' },
  { label: 'Jaysingpur', value: 'Jaysingpur' },
  { label: 'Jayton', value: 'Jayton' },
  { label: 'Jayubó', value: 'Jayubó' },
  { label: 'Jayune', value: 'Jayune' },
  { label: 'Jayuya', value: 'Jayuya' },
  { label: 'Jayuya', value: 'Jayuya' },
  { label: 'Jazovo', value: 'Jazovo' },
  { label: 'Jbaïl', value: 'Jbaïl' },
  { label: 'Jean Baptiste', value: 'Jean Baptiste' },
  { label: 'Jean Lafitte', value: 'Jean Lafitte' },
  { label: 'Jeanerette', value: 'Jeanerette' },
  { label: 'Jeanménil', value: 'Jeanménil' },
  { label: 'Jeannette', value: 'Jeannette' },
  { label: 'Jean-Rabel', value: 'Jean-Rabel' },
  { label: 'Jebalbarez', value: 'Jebalbarez' },
  { label: 'Jebba', value: 'Jebba' },
  { label: 'Jebel', value: 'Jebel' },
  { label: 'Jebel Tiskaouine', value: 'Jebel Tiskaouine' },
  { label: 'Jeberos', value: 'Jeberos' },
  { label: 'Jebrail', value: 'Jebrail' },
  { label: 'Jebsheim', value: 'Jebsheim' },
  { label: 'Jeceaba', value: 'Jeceaba' },
  { label: 'Jecheon-Si', value: 'Jecheon-Si' },
  { label: 'Jecopaco', value: 'Jecopaco' },
  { label: 'Jedburgh', value: 'Jedburgh' },
  { label: 'Jeddah', value: 'Jeddah' },
  { label: 'Jedenspeigen', value: 'Jedenspeigen' },
  { label: 'Jedlicze', value: 'Jedlicze' },
  { label: 'Jedlina-Zdrój', value: 'Jedlina-Zdrój' },
  { label: 'Jedlińsk', value: 'Jedlińsk' },
  { label: 'Jedlnia-Letnisko', value: 'Jedlnia-Letnisko' },
  { label: 'Jedlová', value: 'Jedlová' },
  { label: 'Jednorożec', value: 'Jednorożec' },
  { label: 'Jedovnice', value: 'Jedovnice' },
  { label: 'Jędrzejów', value: 'Jędrzejów' },
  { label: 'Jedwabne', value: 'Jedwabne' },
  { label: 'Jedwabno', value: 'Jedwabno' },
  { label: 'Jeff Davis County', value: 'Jeff Davis County' },
  { label: 'Jeff Davis County', value: 'Jeff Davis County' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson', value: 'Jefferson' },
  { label: 'Jefferson City', value: 'Jefferson City' },
  { label: 'Jefferson City', value: 'Jefferson City' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson County', value: 'Jefferson County' },
  { label: 'Jefferson Davis County', value: 'Jefferson Davis County' },
  { label: 'Jefferson Davis Parish', value: 'Jefferson Davis Parish' },
  { label: 'Jefferson Heights', value: 'Jefferson Heights' },
  { label: 'Jefferson Hills', value: 'Jefferson Hills' },
  { label: 'Jefferson Parish', value: 'Jefferson Parish' },
  { label: 'Jefferson Valley-Yorktown', value: 'Jefferson Valley-Yorktown' },
  { label: 'Jeffersontown', value: 'Jeffersontown' },
  { label: 'Jeffersonville', value: 'Jeffersonville' },
  { label: 'Jeffersonville', value: 'Jeffersonville' },
  { label: 'Jeffersonville', value: 'Jeffersonville' },
  { label: 'Jeffersonville', value: 'Jeffersonville' },
  { label: 'Jeffery Town', value: 'Jeffery Town' },
  { label: 'Jega', value: 'Jega' },
  { label: 'Jegălia', value: 'Jegălia' },
  { label: 'Jegenstorf', value: 'Jegenstorf' },
  { label: 'Jegun', value: 'Jegun' },
  { label: 'Jegunovce', value: 'Jegunovce' },
  { label: 'Jehanabad', value: 'Jehanabad' },
  { label: 'Jejkowice', value: 'Jejkowice' },
  { label: 'Jeju City', value: 'Jeju City' },
  { label: 'Jejur', value: 'Jejur' },
  { label: 'Jejuri', value: 'Jejuri' },
  { label: 'Jeju-Si', value: 'Jeju-Si' },
  { label: 'Jēkabpils', value: 'Jēkabpils' },
  { label: 'Jekulo', value: 'Jekulo' },
  { label: 'Jelah', value: 'Jelah' },
  { label: 'Jelcz', value: 'Jelcz' },
  { label: 'Jelcz Laskowice', value: 'Jelcz Laskowice' },
  { label: 'Jelenča', value: 'Jelenča' },
  { label: 'Jelenia Góra', value: 'Jelenia Góra' },
  { label: 'Jeleśnia', value: 'Jeleśnia' },
  { label: 'Jelgava', value: 'Jelgava' },
  { label: 'Jelin', value: 'Jelin' },
  { label: 'Jelisavac', value: 'Jelisavac' },
  { label: 'Jellico', value: 'Jellico' },
  { label: 'Jelling', value: 'Jelling' },
  { label: 'Jelsa', value: 'Jelsa' },
  { label: 'Jelsi', value: 'Jelsi' },
  { label: 'Jember', value: 'Jember' },
  { label: 'Jembke', value: 'Jembke' },
  { label: 'Jemez Pueblo', value: 'Jemez Pueblo' },
  { label: 'Jemgum', value: 'Jemgum' },
  { label: 'Jemielnica', value: 'Jemielnica' },
  { label: 'Jemison', value: 'Jemison' },
  { label: 'Jemna', value: 'Jemna' },
  { label: 'Jemnice', value: 'Jemnice' },
  { label: 'Jena', value: 'Jena' },
  { label: 'Jena', value: 'Jena' },
  { label: 'Jenaro Herrera', value: 'Jenaro Herrera' },
  { label: 'Jenaz', value: 'Jenaz' },
  { label: 'Jenbach', value: 'Jenbach' },
  { label: 'Jendouba', value: 'Jendouba' },
  { label: 'Jeneč', value: 'Jeneč' },
  { label: 'Jenesano', value: 'Jenesano' },
  { label: 'Jengen', value: 'Jengen' },
  { label: 'Jenin', value: 'Jenin' },
  { label: 'Jenipapo De Minas', value: 'Jenipapo De Minas' },
  { label: 'Jenipapo Dos Vieiras', value: 'Jenipapo Dos Vieiras' },
  { label: 'Jenison', value: 'Jenison' },
  { label: 'Jenjarum', value: 'Jenjarum' },
  { label: 'Jenkins', value: 'Jenkins' },
  { label: 'Jenkins County', value: 'Jenkins County' },
  { label: 'Jenkintown', value: 'Jenkintown' },
  { label: 'Jenks', value: 'Jenks' },
  { label: 'Jenlain', value: 'Jenlain' },
  { label: 'Jenne', value: 'Jenne' },
  { label: 'Jennersdorf', value: 'Jennersdorf' },
  { label: 'Jennings', value: 'Jennings' },
  { label: 'Jennings', value: 'Jennings' },
  { label: 'Jennings County', value: 'Jennings County' },
  { label: 'Jennings Lodge', value: 'Jennings Lodge' },
  { label: 'Jenoi', value: 'Jenoi' },
  { label: 'Jensen', value: 'Jensen' },
  { label: 'Jensen Beach', value: 'Jensen Beach' },
  { label: 'Jenzan', value: 'Jenzan' },
  { label: 'Jeongeup', value: 'Jeongeup' },
  { label: 'Jeongeup-Si', value: 'Jeongeup-Si' },
  { label: 'Jeongseon-Gun', value: 'Jeongseon-Gun' },
  { label: 'Jeonju', value: 'Jeonju' },
  { label: 'Jeonju-Si', value: 'Jeonju-Si' },
  { label: 'Jepelacio', value: 'Jepelacio' },
  { label: 'Jequeri', value: 'Jequeri' },
  { label: 'Jequiá Da Praia', value: 'Jequiá Da Praia' },
  { label: 'Jequié', value: 'Jequié' },
  { label: 'Jequitaí', value: 'Jequitaí' },
  { label: 'Jequitibá', value: 'Jequitibá' },
  { label: 'Jequitinhonha', value: 'Jequitinhonha' },
  { label: 'Jerada', value: 'Jerada' },
  { label: 'Jerago Con Orago', value: 'Jerago Con Orago' },
  { label: 'Jerantut', value: 'Jerantut' },
  { label: 'Jerauld County', value: 'Jerauld County' },
  { label: 'Jerba Midoun', value: 'Jerba Midoun' },
  { label: 'Jerbanlu', value: 'Jerbanlu' },
  { label: 'Jerécuaro', value: 'Jerécuaro' },
  { label: 'Jeremi', value: 'Jeremi' },
  { label: 'Jérémie', value: 'Jérémie' },
  { label: 'Jeremoabo', value: 'Jeremoabo' },
  { label: 'Jerez', value: 'Jerez' },
  { label: 'Jerez De García Salinas', value: 'Jerez De García Salinas' },
  { label: 'Jerez De La Frontera', value: 'Jerez De La Frontera' },
  { label: 'Jerez De Los Caballeros', value: 'Jerez De Los Caballeros' },
  { label: 'Jérez Del Marquesado', value: 'Jérez Del Marquesado' },
  { label: 'Jérica', value: 'Jérica' },
  { label: 'Jericho', value: 'Jericho' },
  { label: 'Jericho', value: 'Jericho' },
  { label: 'Jericho', value: 'Jericho' },
  { label: 'Jerichow', value: 'Jerichow' },
  { label: 'Jericó', value: 'Jericó' },
  { label: 'Jericó', value: 'Jericó' },
  { label: 'Jericó', value: 'Jericó' },
  { label: 'Jericó', value: 'Jericó' },
  { label: 'Jericó', value: 'Jericó' },
  { label: 'Jerilderie', value: 'Jerilderie' },
  { label: 'Jeriquara', value: 'Jeriquara' },
  { label: 'Jermenovci', value: 'Jermenovci' },
  { label: 'Jermuk', value: 'Jermuk' },
  { label: 'Jermyn', value: 'Jermyn' },
  { label: 'Jerome', value: 'Jerome' },
  { label: 'Jerome', value: 'Jerome' },
  { label: 'Jerome', value: 'Jerome' },
  { label: 'Jerome County', value: 'Jerome County' },
  { label: 'Jerônimo Monteiro', value: 'Jerônimo Monteiro' },
  { label: 'Jerrabomberra', value: 'Jerrabomberra' },
  { label: 'Jerramungup', value: 'Jerramungup' },
  { label: 'Jerrishoe', value: 'Jerrishoe' },
  { label: 'Jersbek', value: 'Jersbek' },
  { label: 'Jersey City', value: 'Jersey City' },
  { label: 'Jersey County', value: 'Jersey County' },
  { label: 'Jersey Shore', value: 'Jersey Shore' },
  { label: 'Jersey Village', value: 'Jersey Village' },
  { label: 'Jerseyville', value: 'Jerseyville' },
  { label: 'Jerte', value: 'Jerte' },
  { label: 'Jertih', value: 'Jertih' },
  { label: 'Jerumenha', value: 'Jerumenha' },
  { label: 'Jerusalem', value: 'Jerusalem' },
  { label: 'Jerusalem', value: 'Jerusalem' },
  { label: 'Jerusalén', value: 'Jerusalén' },
  { label: 'Jerusalén', value: 'Jerusalén' },
  { label: 'Jerxheim', value: 'Jerxheim' },
  { label: 'Jerzens', value: 'Jerzens' },
  { label: 'Jerzmanowice', value: 'Jerzmanowice' },
  { label: 'Jerzu', value: 'Jerzu' },
  { label: 'Jesberg', value: 'Jesberg' },
  { label: 'Jesenice', value: 'Jesenice' },
  { label: 'Jesenice', value: 'Jesenice' },
  { label: 'Jesenice', value: 'Jesenice' },
  { label: 'Jeseník', value: 'Jeseník' },
  { label: 'Jeseník Nad Odrou', value: 'Jeseník Nad Odrou' },
  { label: 'Jesenje', value: 'Jesenje' },
  { label: 'Jesenwang', value: 'Jesenwang' },
  { label: 'Jesewitz', value: 'Jesewitz' },
  { label: 'Jesi', value: 'Jesi' },
  { label: 'Jesmond', value: 'Jesmond' },
  { label: 'Jessamine County', value: 'Jessamine County' },
  { label: 'Jessen', value: 'Jessen' },
  { label: 'Jessheim', value: 'Jessheim' },
  { label: 'Jeßnitz', value: 'Jeßnitz' },
  { label: 'Jessore ', value: 'Jessore ' },
  { label: 'Jessup', value: 'Jessup' },
  { label: 'Jessup', value: 'Jessup' },
  { label: 'Jesteburg', value: 'Jesteburg' },
  { label: 'Jestetten', value: 'Jestetten' },
  { label: 'Jesuânia', value: 'Jesuânia' },
  { label: 'Jesuítas', value: 'Jesuítas' },
  { label: 'Jesup', value: 'Jesup' },
  { label: 'Jesup', value: 'Jesup' },
  { label: 'Jesúpolis', value: 'Jesúpolis' },
  { label: 'Jesús', value: 'Jesús' },
  { label: 'Jesús Carranza', value: 'Jesús Carranza' },
  { label: 'Jesús Carranza (Rancho De Jesús)', value: 'Jesús Carranza (Rancho De Jesús)' },
  { label: 'Jesús De Otoro', value: 'Jesús De Otoro' },
  { label: 'Jesús Del Monte', value: 'Jesús Del Monte' },
  { label: 'Jesús Del Monte', value: 'Jesús Del Monte' },
  { label: 'Jesús Del Monte', value: 'Jesús Del Monte' },
  { label: 'Jesús Gómez Portugal', value: 'Jesús Gómez Portugal' },
  { label: 'Jesús Gómez Portugal (Margaritas)', value: 'Jesús Gómez Portugal (Margaritas)' },
  { label: 'Jesús Huitznahuac', value: 'Jesús Huitznahuac' },
  { label: 'Jesús María', value: 'Jesús María' },
  { label: 'Jesús María', value: 'Jesús María' },
  { label: 'Jesús María', value: 'Jesús María' },
  { label: 'Jesús María', value: 'Jesús María' },
  { label: 'Jesús María', value: 'Jesús María' },
  { label: 'Jesús María', value: 'Jesús María' },
  { label: 'Jesús María', value: 'Jesús María' },
  { label: 'Jesús María', value: 'Jesús María' },
  { label: 'Jesús María', value: 'Jesús María' },
  { label: 'Jesús María Aguirre Galeana', value: 'Jesús María Aguirre Galeana' },
  { label: 'Jesús María De Berrones', value: 'Jesús María De Berrones' },
  { label: 'Jesús María Del Terrero', value: 'Jesús María Del Terrero' },
  { label: 'Jesús María Garza', value: 'Jesús María Garza' },
  { label: 'Jesús María Nayarit', value: 'Jesús María Nayarit' },
  { label: 'Jesús Menéndez', value: 'Jesús Menéndez' },
  { label: 'Jesús Nazareno', value: 'Jesús Nazareno' },
  { label: 'Jesús Tepactepec', value: 'Jesús Tepactepec' },
  { label: 'Jetafe', value: 'Jetafe' },
  { label: 'Jetalpur', value: 'Jetalpur' },
  { label: 'Jetalsar', value: 'Jetalsar' },
  { label: 'Jete', value: 'Jete' },
  { label: 'Jetibá', value: 'Jetibá' },
  { label: 'Jetmore', value: 'Jetmore' },
  { label: 'Jetpur', value: 'Jetpur' },
  { label: 'Jetpur (Navagadh)', value: 'Jetpur (Navagadh)' },
  { label: 'Jettingen-Scheppach', value: 'Jettingen-Scheppach' },
  { label: 'Jetzendorf', value: 'Jetzendorf' },
  { label: 'Jeumont', value: 'Jeumont' },
  { label: 'Jeungpyeong-Gun', value: 'Jeungpyeong-Gun' },
  { label: 'Jevargi', value: 'Jevargi' },
  { label: 'Jevenstedt', value: 'Jevenstedt' },
  { label: 'Jever', value: 'Jever' },
  { label: 'Jevíčko', value: 'Jevíčko' },
  { label: 'Jevišovice', value: 'Jevišovice' },
  { label: 'Jevnaker', value: 'Jevnaker' },
  { label: 'Jevremovac', value: 'Jevremovac' },
  { label: 'Jewar', value: 'Jewar' },
  { label: 'Jewell', value: 'Jewell' },
  { label: 'Jewell County', value: 'Jewell County' },
  { label: 'Jewells', value: 'Jewells' },
  { label: 'Jewett', value: 'Jewett' },
  { label: 'Jewett City', value: 'Jewett City' },
  { label: 'Jeypore', value: 'Jeypore' },
  { label: 'Ježdovec', value: 'Ježdovec' },
  { label: 'Jezerski', value: 'Jezerski' },
  { label: 'Jeżewo', value: 'Jeżewo' },
  { label: 'Jeziora Wielkie', value: 'Jeziora Wielkie' },
  { label: 'Jeziorany', value: 'Jeziorany' },
  { label: 'Jeziorzany', value: 'Jeziorzany' },
  { label: 'Jeżów', value: 'Jeżów' },
  { label: 'Jeżów Sudecki', value: 'Jeżów Sudecki' },
  { label: 'Jeżowe', value: 'Jeżowe' },
  { label: 'Jhabua', value: 'Jhabua' },
  { label: 'Jha-Jha', value: 'Jha-Jha' },
  { label: 'Jhajhar', value: 'Jhajhar' },
  { label: 'Jhajjar', value: 'Jhajjar' },
  { label: 'Jhal Magsi District', value: 'Jhal Magsi District' },
  { label: 'Jhalawar', value: 'Jhalawar' },
  { label: 'Jhalida', value: 'Jhalida' },
  { label: 'Jhalod', value: 'Jhalod' },
  { label: 'Jhalokati ', value: 'Jhalokati ' },
  { label: 'Jhalrapatan', value: 'Jhalrapatan' },
  { label: 'Jhalu', value: 'Jhalu' },
  { label: 'Jhang', value: 'Jhang' },
  { label: 'Jhang Sadar', value: 'Jhang Sadar' },
  { label: 'Jhanjharpur', value: 'Jhanjharpur' },
  { label: 'Jhansi', value: 'Jhansi' },
  { label: 'Jhapa', value: 'Jhapa' },
  { label: 'Jhargram', value: 'Jhargram' },
  { label: 'Jharia', value: 'Jharia' },
  { label: 'Jharsuguda', value: 'Jharsuguda' },
  { label: 'Jharsuguda District', value: 'Jharsuguda District' },
  { label: 'Jhawarian', value: 'Jhawarian' },
  { label: 'Jhelum', value: 'Jhelum' },
  { label: 'Jhenaidah ', value: 'Jhenaidah ' },
  { label: 'Jhilimili', value: 'Jhilimili' },
  { label: 'Jhinjhak', value: 'Jhinjhak' },
  { label: 'Jhinjhana', value: 'Jhinjhana' },
  { label: 'Jhol', value: 'Jhol' },
  { label: 'Jhulasan', value: 'Jhulasan' },
  { label: 'Jhunjhunun', value: 'Jhunjhunun' },
  { label: 'Jhusi', value: 'Jhusi' },
  { label: 'Ji Paraná', value: 'Ji Paraná' },
  { label: 'Ji’An', value: 'Ji’An' },
  { label: 'Ji’An', value: 'Ji’An' },
  { label: 'Jiabong', value: 'Jiabong' },
  { label: 'Jiabong', value: 'Jiabong' },
  { label: 'Jiagedaqi', value: 'Jiagedaqi' },
  { label: 'Jiamaying', value: 'Jiamaying' },
  { label: 'Jiamusi', value: 'Jiamusi' },
  { label: 'Jian’Ou', value: 'Jian’Ou' },
  { label: 'Jiana', value: 'Jiana' },
  { label: 'Jiana Veche', value: 'Jiana Veche' },
  { label: 'Jiancheng', value: 'Jiancheng' },
  { label: 'Jiangfang', value: 'Jiangfang' },
  { label: 'Jiangkou', value: 'Jiangkou' },
  { label: 'Jiangkouxu', value: 'Jiangkouxu' },
  { label: 'Jiangmen', value: 'Jiangmen' },
  { label: 'Jiangshi', value: 'Jiangshi' },
  { label: 'Jianguang', value: 'Jianguang' },
  { label: 'Jiangwakou', value: 'Jiangwakou' },
  { label: 'Jiangyan', value: 'Jiangyan' },
  { label: 'Jiangyin', value: 'Jiangyin' },
  { label: 'Jiangyou', value: 'Jiangyou' },
  { label: 'Jiangzi', value: 'Jiangzi' },
  { label: 'Jianjiang', value: 'Jianjiang' },
  { label: 'Jiannan', value: 'Jiannan' },
  { label: 'Jianshe', value: 'Jianshe' },
  { label: 'Jiaojiang', value: 'Jiaojiang' },
  { label: 'Jiaozhou', value: 'Jiaozhou' },
  { label: 'Jiaozuo', value: 'Jiaozuo' },
  { label: 'Jiashan', value: 'Jiashan' },
  { label: 'Jiaxing', value: 'Jiaxing' },
  { label: 'Jiaxing Shi', value: 'Jiaxing Shi' },
  { label: 'Jiayuguan', value: 'Jiayuguan' },
  { label: 'Jiazi', value: 'Jiazi' },
  { label: 'Jibao-An', value: 'Jibao-An' },
  { label: 'Jibert', value: 'Jibert' },
  { label: 'Jibia', value: 'Jibia' },
  { label: 'Jiblah', value: 'Jiblah' },
  { label: 'Jiblea Veche', value: 'Jiblea Veche' },
  { label: 'Jibou', value: 'Jibou' },
  { label: 'Jicaltepec Autopan', value: 'Jicaltepec Autopan' },
  { label: 'Jicaltepec Cuexcontitlán', value: 'Jicaltepec Cuexcontitlán' },
  { label: 'Jícamas', value: 'Jícamas' },
  { label: 'Jicarero', value: 'Jicarero' },
  { label: 'Jícaro Galán', value: 'Jícaro Galán' },
  { label: 'Jicayán De Tovar', value: 'Jicayán De Tovar' },
  { label: 'Jichişu De Jos', value: 'Jichişu De Jos' },
  { label: 'Jičín', value: 'Jičín' },
  { label: 'Jicolapa', value: 'Jicolapa' },
  { label: 'Jicomé', value: 'Jicomé' },
  { label: 'Jidd Ḩafş', value: 'Jidd Ḩafş' },
  { label: 'Jidong', value: 'Jidong' },
  { label: 'Jidoștița', value: 'Jidoștița' },
  { label: 'Jidvei', value: 'Jidvei' },
  { label: 'Jiehu', value: 'Jiehu' },
  { label: 'Jieni', value: 'Jieni' },
  { label: 'Jieshi', value: 'Jieshi' },
  { label: 'Jieshou', value: 'Jieshou' },
  { label: 'Jiexiu', value: 'Jiexiu' },
  { label: 'Jieyang', value: 'Jieyang' },
  { label: 'Jieznas', value: 'Jieznas' },
  { label: 'Jifarong', value: 'Jifarong' },
  { label: 'Jighi', value: 'Jighi' },
  { label: 'Jiguaní', value: 'Jiguaní' },
  { label: 'Jihad', value: 'Jihad' },
  { label: 'Jihanah', value: 'Jihanah' },
  { label: 'Jihlava', value: 'Jihlava' },
  { label: 'Jijan Kuh', value: 'Jijan Kuh' },
  { label: 'Jijel', value: 'Jijel' },
  { label: 'Jijian Rud', value: 'Jijian Rud' },
  { label: 'Jijiang', value: 'Jijiang' },
  { label: 'Jijiga', value: 'Jijiga' },
  { label: 'Jijila', value: 'Jijila' },
  { label: 'Jijoca De Jericoacoara', value: 'Jijoca De Jericoacoara' },
  { label: 'Jijona', value: 'Jijona' },
  { label: 'Jijona/Xixona', value: 'Jijona/Xixona' },
  { label: 'Jikamshi', value: 'Jikamshi' },
  { label: 'Jikwoyi', value: 'Jikwoyi' },
  { label: 'Jilava', value: 'Jilava' },
  { label: 'Jilavele', value: 'Jilavele' },
  { label: 'Jilemnice', value: 'Jilemnice' },
  { label: 'Jilib', value: 'Jilib' },
  { label: 'Jilikŭl', value: 'Jilikŭl' },
  { label: 'Jilin', value: 'Jilin' },
  { label: 'Jilliby', value: 'Jilliby' },
  { label: 'Jilma', value: 'Jilma' },
  { label: 'Jilotepec', value: 'Jilotepec' },
  { label: 'Jilotepec De Molina Enríquez', value: 'Jilotepec De Molina Enríquez' },
  { label: 'Jilotlán De Los Dolores', value: 'Jilotlán De Los Dolores' },
  { label: 'Jilotzingo', value: 'Jilotzingo' },
  { label: 'Jilotzingo', value: 'Jilotzingo' },
  { label: 'Jílové', value: 'Jílové' },
  { label: 'Jílové U Prahy', value: 'Jílové U Prahy' },
  { label: 'Jim Hogg County', value: 'Jim Hogg County' },
  { label: 'Jim Thorpe', value: 'Jim Thorpe' },
  { label: 'Jim Wells County', value: 'Jim Wells County' },
  { label: 'Jima Abajo', value: 'Jima Abajo' },
  { label: 'Jimaguayú', value: 'Jimaguayú' },
  { label: 'Jimalalud', value: 'Jimalalud' },
  { label: 'Jimaní', value: 'Jimaní' },
  { label: 'Jimbaran', value: 'Jimbaran' },
  { label: 'Jimbolia', value: 'Jimbolia' },
  { label: 'Jimboomba', value: 'Jimboomba' },
  { label: 'Jimena', value: 'Jimena' },
  { label: 'Jimena De La Frontera', value: 'Jimena De La Frontera' },
  { label: 'Jimenez', value: 'Jimenez' },
  { label: 'Jiménez', value: 'Jiménez' },
  { label: 'Jiménez', value: 'Jiménez' },
  { label: 'Jiménez', value: 'Jiménez' },
  { label: 'Jiménez', value: 'Jiménez' },
  { label: 'Jiménez', value: 'Jiménez' },
  { label: 'Jiménez', value: 'Jiménez' },
  { label: 'Jiménez Del Teul', value: 'Jiménez Del Teul' },
  { label: 'Jimera De Líbar', value: 'Jimera De Líbar' },
  { label: 'Jimeta', value: 'Jimeta' },
  { label: 'Jimi', value: 'Jimi' },
  { label: 'Jimma', value: 'Jimma' },
  { label: 'Jimma Zone', value: 'Jimma Zone' },
  { label: 'Jimo', value: 'Jimo' },
  { label: 'Jimramov', value: 'Jimramov' },
  { label: 'Jin Taraqayah', value: 'Jin Taraqayah' },
  { label: 'Jina', value: 'Jina' },
  { label: 'Jinan', value: 'Jinan' },
  { label: 'Jinan-Gun', value: 'Jinan-Gun' },
  { label: 'Jince', value: 'Jince' },
  { label: 'Jinchang', value: 'Jinchang' },
  { label: 'Jincheng', value: 'Jincheng' },
  { label: 'Jincheng', value: 'Jincheng' },
  { label: 'Jincheon-Gun', value: 'Jincheon-Gun' },
  { label: 'Jind', value: 'Jind' },
  { label: 'Jindabyne', value: 'Jindabyne' },
  { label: 'Jindalee', value: 'Jindalee' },
  { label: 'Jindalee', value: 'Jindalee' },
  { label: 'Jindera', value: 'Jindera' },
  { label: 'Jindo-Gun', value: 'Jindo-Gun' },
  { label: 'Jindřichov', value: 'Jindřichov' },
  { label: 'Jindřichův Hradec', value: 'Jindřichův Hradec' },
  { label: 'Jingdezhen', value: 'Jingdezhen' },
  { label: 'Jingdezhen Shi', value: 'Jingdezhen Shi' },
  { label: 'Jingfeng', value: 'Jingfeng' },
  { label: 'Jinghong', value: 'Jinghong' },
  { label: 'Jingili', value: 'Jingili' },
  { label: 'Jingjiang', value: 'Jingjiang' },
  { label: 'Jingling', value: 'Jingling' },
  { label: 'Jingmen', value: 'Jingmen' },
  { label: 'Jingmen Shi', value: 'Jingmen Shi' },
  { label: 'Jingzhou', value: 'Jingzhou' },
  { label: 'Jinhe', value: 'Jinhe' },
  { label: 'Jinhua', value: 'Jinhua' },
  { label: 'Jining', value: 'Jining' },
  { label: 'Jining', value: 'Jining' },
  { label: 'Jinja', value: 'Jinja' },
  { label: 'Jinji', value: 'Jinji' },
  { label: 'Jinjiang', value: 'Jinjiang' },
  { label: 'Jinjiang', value: 'Jinjiang' },
  { label: 'Jinjing', value: 'Jinjing' },
  { label: 'Jinju-Si', value: 'Jinju-Si' },
  { label: 'Jinka', value: 'Jinka' },
  { label: 'Jinotega', value: 'Jinotega' },
  { label: 'Jinotepe', value: 'Jinotepe' },
  { label: 'Jinsha', value: 'Jinsha' },
  { label: 'Jinshi', value: 'Jinshi' },
  { label: 'Jinshiqiao', value: 'Jinshiqiao' },
  { label: 'Jintur', value: 'Jintur' },
  { label: 'Jinxiang', value: 'Jinxiang' },
  { label: 'Jinzhong Shi', value: 'Jinzhong Shi' },
  { label: 'Jinzhou', value: 'Jinzhou' },
  { label: 'Jipapad', value: 'Jipapad' },
  { label: 'Ji-Paraná', value: 'Ji-Paraná' },
  { label: 'Jipijapa', value: 'Jipijapa' },
  { label: 'Jiquilillo', value: 'Jiquilillo' },
  { label: 'Jiquilisco', value: 'Jiquilisco' },
  { label: 'Jiquilpan', value: 'Jiquilpan' },
  { label: 'Jiquilpan (Estación Bonanza)', value: 'Jiquilpan (Estación Bonanza)' },
  { label: 'Jiquílpan De Juárez', value: 'Jiquílpan De Juárez' },
  { label: 'Jiquinlaca', value: 'Jiquinlaca' },
  { label: 'Jiquipilas', value: 'Jiquipilas' },
  { label: 'Jiquipilco', value: 'Jiquipilco' },
  { label: 'Jiquiriçá', value: 'Jiquiriçá' },
  { label: 'Jiran', value: 'Jiran' },
  { label: 'Jiribam', value: 'Jiribam' },
  { label: 'Jiříkov', value: 'Jiříkov' },
  { label: 'Jirindih', value: 'Jirindih' },
  { label: 'Jirjā', value: 'Jirjā' },
  { label: 'Jirkov', value: 'Jirkov' },
  { label: 'Jirlău', value: 'Jirlău' },
  { label: 'Jirnsum', value: 'Jirnsum' },
  { label: 'Jirny', value: 'Jirny' },
  { label: 'Jiroft', value: 'Jiroft' },
  { label: 'Jirov', value: 'Jirov' },
  { label: 'Jirueque', value: 'Jirueque' },
  { label: 'Jīsh', value: 'Jīsh' },
  { label: 'Jishu', value: 'Jishu' },
  { label: 'Jishui', value: 'Jishui' },
  { label: 'Jisp', value: 'Jisp' },
  { label: 'Jisr Al-Shughur District', value: 'Jisr Al-Shughur District' },
  { label: 'Jisr Ash Shughūr', value: 'Jisr Ash Shughūr' },
  { label: 'Jistebnice', value: 'Jistebnice' },
  { label: 'Jistebník', value: 'Jistebník' },
  { label: 'Jitaúna', value: 'Jitaúna' },
  { label: 'Jitia', value: 'Jitia' },
  { label: 'Jitonhueca', value: 'Jitonhueca' },
  { label: 'Jitotol', value: 'Jitotol' },
  { label: 'Jitoucun', value: 'Jitoucun' },
  { label: 'Jitra', value: 'Jitra' },
  { label: 'Jitzamuri', value: 'Jitzamuri' },
  { label: 'Jiufen', value: 'Jiufen' },
  { label: 'Jiujiang', value: 'Jiujiang' },
  { label: 'Jiu-Paroșeni', value: 'Jiu-Paroșeni' },
  { label: 'Jiupu', value: 'Jiupu' },
  { label: 'Jiuquan', value: 'Jiuquan' },
  { label: 'Jiutai', value: 'Jiutai' },
  { label: 'Jiutepec', value: 'Jiutepec' },
  { label: 'Jiwani', value: 'Jiwani' },
  { label: 'Jixi', value: 'Jixi' },
  { label: 'Jiyanpur', value: 'Jiyanpur' },
  { label: 'Jiyuan', value: 'Jiyuan' },
  { label: 'Jizan', value: 'Jizan' },
  { label: 'Jizzax', value: 'Jizzax' },
  { label: 'Jo Daviess County', value: 'Jo Daviess County' },
  { label: 'Joaçaba', value: 'Joaçaba' },
  { label: 'Joachimsthal', value: 'Joachimsthal' },
  { label: 'Joachín', value: 'Joachín' },
  { label: 'Joaíma', value: 'Joaíma' },
  { label: 'Joal-Fadiout', value: 'Joal-Fadiout' },
  { label: 'Joane', value: 'Joane' },
  { label: 'Joanésia', value: 'Joanésia' },
  { label: 'Joanna', value: 'Joanna' },
  { label: 'Joanópolis', value: 'Joanópolis' },
  { label: 'João Alfredo', value: 'João Alfredo' },
  { label: 'João Antão', value: 'João Antão' },
  { label: 'João Câmara', value: 'João Câmara' },
  { label: 'João Costa', value: 'João Costa' },
  { label: 'João Dias', value: 'João Dias' },
  { label: 'João Dourado', value: 'João Dourado' },
  { label: 'João Lisboa', value: 'João Lisboa' },
  { label: 'João Monlevade', value: 'João Monlevade' },
  { label: 'João Neiva', value: 'João Neiva' },
  { label: 'João Pessoa', value: 'João Pessoa' },
  { label: 'João Pinheiro', value: 'João Pinheiro' },
  { label: 'João Ramalho', value: 'João Ramalho' },
  { label: 'João Teves', value: 'João Teves' },
  { label: 'Joaquim Felício', value: 'Joaquim Felício' },
  { label: 'Joaquim Gomes', value: 'Joaquim Gomes' },
  { label: 'Joaquim Nabuco', value: 'Joaquim Nabuco' },
  { label: 'Joaquim Pires', value: 'Joaquim Pires' },
  { label: 'Joaquim Távora', value: 'Joaquim Távora' },
  { label: 'Joaquín Herrera', value: 'Joaquín Herrera' },
  { label: 'Joaquín Miguel Gutiérrez', value: 'Joaquín Miguel Gutiérrez' },
  { label: 'Joaquín Miguel Gutiérrez (Margaritas)', value: 'Joaquín Miguel Gutiérrez (Margaritas)' },
  { label: 'Joaquín Suárez', value: 'Joaquín Suárez' },
  { label: 'Joaquín V. González', value: 'Joaquín V. González' },
  { label: 'Joarilla De Las Matas', value: 'Joarilla De Las Matas' },
  { label: 'Job', value: 'Job' },
  { label: 'Joba Arriba', value: 'Joba Arriba' },
  { label: 'Jobabo', value: 'Jobabo' },
  { label: 'Jobabo', value: 'Jobabo' },
  { label: 'Jobat', value: 'Jobat' },
  { label: 'Jobbágyi', value: 'Jobbágyi' },
  { label: 'Jobner', value: 'Jobner' },
  { label: 'Jobos', value: 'Jobos' },
  { label: 'Jobstown', value: 'Jobstown' },
  { label: 'Joca Claudino', value: 'Joca Claudino' },
  { label: 'Joca Marques', value: 'Joca Marques' },
  { label: 'Jochberg', value: 'Jochberg' },
  { label: 'Jockgrim', value: 'Jockgrim' },
  { label: 'Jocón', value: 'Jocón' },
  { label: 'Joconal', value: 'Joconal' },
  { label: 'Joconoxtle', value: 'Joconoxtle' },
  { label: 'Jocoqui', value: 'Jocoqui' },
  { label: 'Jocoro', value: 'Jocoro' },
  { label: 'Jocotán', value: 'Jocotán' },
  { label: 'Jocotenango', value: 'Jocotenango' },
  { label: 'Jocotepec', value: 'Jocotepec' },
  { label: 'Jocotitlán', value: 'Jocotitlán' },
  { label: 'Jódar', value: 'Jódar' },
  { label: 'Jodhpur', value: 'Jodhpur' },
  { label: 'Jodhpur', value: 'Jodhpur' },
  { label: 'Jodhpur (Ahmedabad)', value: 'Jodhpur (Ahmedabad)' },
  { label: 'Jodia', value: 'Jodia' },
  { label: 'Jodiya Bandar', value: 'Jodiya Bandar' },
  { label: 'Jodłowa', value: 'Jodłowa' },
  { label: 'Jodłówka', value: 'Jodłówka' },
  { label: 'Jodłówka', value: 'Jodłówka' },
  { label: 'Jodłówka-Wałki', value: 'Jodłówka-Wałki' },
  { label: 'Jodoigne', value: 'Jodoigne' },
  { label: 'Joe Gqabi District Municipality', value: 'Joe Gqabi District Municipality' },
  { label: 'Joe Hut', value: 'Joe Hut' },
  { label: 'Jõelähtme Vald', value: 'Jõelähtme Vald' },
  { label: 'Joensuu', value: 'Joensuu' },
  { label: 'Jōetsu', value: 'Jōetsu' },
  { label: 'Jōetsu Shi', value: 'Jōetsu Shi' },
  { label: 'Jœuf', value: 'Jœuf' },
  { label: 'Jofrito', value: 'Jofrito' },
  { label: 'Jogbani', value: 'Jogbani' },
  { label: 'Jogeshwari', value: 'Jogeshwari' },
  { label: 'Jõgeva', value: 'Jõgeva' },
  { label: 'Jõgeva Vald', value: 'Jõgeva Vald' },
  { label: 'Joghatay', value: 'Joghatay' },
  { label: 'Jogighopa', value: 'Jogighopa' },
  { label: 'Jogindarnagar', value: 'Jogindarnagar' },
  { label: 'Jogonalan', value: 'Jogonalan' },
  { label: 'Jogulamba Gadwal', value: 'Jogulamba Gadwal' },
  { label: 'Johannedal', value: 'Johannedal' },
  { label: 'Johannesberg', value: 'Johannesberg' },
  { label: 'Johannesburg', value: 'Johannesburg' },
  { label: 'Johanngeorgenstadt', value: 'Johanngeorgenstadt' },
  { label: 'Johanniskirchen', value: 'Johanniskirchen' },
  { label: 'Johannisthal', value: 'Johannisthal' },
  { label: 'Johi', value: 'Johi' },
  { label: 'Jöhlingen', value: 'Jöhlingen' },
  { label: 'John Compton Highway', value: 'John Compton Highway' },
  { label: 'John Day', value: 'John Day' },
  { label: 'John Taolo Gaetsewe District Municipality', value: 'John Taolo Gaetsewe District Municipality' },
  { label: 'Johns Creek', value: 'Johns Creek' },
  { label: 'Johns Hall', value: 'Johns Hall' },
  { label: 'Johns Hall', value: 'Johns Hall' },
  { label: 'Johns Town', value: 'Johns Town' },
  { label: 'Johnsbach', value: 'Johnsbach' },
  { label: 'Johnsburg', value: 'Johnsburg' },
  { label: 'Johnson', value: 'Johnson' },
  { label: 'Johnson', value: 'Johnson' },
  { label: 'Johnson', value: 'Johnson' },
  { label: 'Johnson City', value: 'Johnson City' },
  { label: 'Johnson City', value: 'Johnson City' },
  { label: 'Johnson City', value: 'Johnson City' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson County', value: 'Johnson County' },
  { label: 'Johnson Creek', value: 'Johnson Creek' },
  { label: 'Johnson Lane', value: 'Johnson Lane' },
  { label: 'Johnson Mountain', value: 'Johnson Mountain' },
  { label: 'Johnson Town', value: 'Johnson Town' },
  { label: 'Johnsonburg', value: 'Johnsonburg' },
  { label: 'Johnsonville', value: 'Johnsonville' },
  { label: 'Johnsonville', value: 'Johnsonville' },
  { label: 'Johnston', value: 'Johnston' },
  { label: 'Johnston', value: 'Johnston' },
  { label: 'Johnston', value: 'Johnston' },
  { label: 'Johnston', value: 'Johnston' },
  { label: 'Johnston', value: 'Johnston' },
  { label: 'Johnston City', value: 'Johnston City' },
  { label: 'Johnston County', value: 'Johnston County' },
  { label: 'Johnston County', value: 'Johnston County' },
  { label: 'Johnstone', value: 'Johnstone' },
  { label: 'Johnstonville', value: 'Johnstonville' },
  { label: 'Johnstown', value: 'Johnstown' },
  { label: 'Johnstown', value: 'Johnstown' },
  { label: 'Johnstown', value: 'Johnstown' },
  { label: 'Johnstown', value: 'Johnstown' },
  { label: 'Johnstown', value: 'Johnstown' },
  { label: 'Johor Bahru', value: 'Johor Bahru' },
  { label: 'Jöhstadt', value: 'Jöhstadt' },
  { label: 'Jõhvi', value: 'Jõhvi' },
  { label: 'Jõhvi Vald', value: 'Jõhvi Vald' },
  { label: 'Jóia', value: 'Jóia' },
  { label: 'Joigny', value: 'Joigny' },
  { label: 'Joint Base Lewis Mcchord', value: 'Joint Base Lewis Mcchord' },
  { label: 'Joinville', value: 'Joinville' },
  { label: 'Joinville', value: 'Joinville' },
  { label: 'Joinville-Le-Pont', value: 'Joinville-Le-Pont' },
  { label: 'Jois', value: 'Jois' },
  { label: 'Joiţa', value: 'Joiţa' },
  { label: 'Jojoima', value: 'Jojoima' },
  { label: 'Jojutla', value: 'Jojutla' },
  { label: 'Jokadu', value: 'Jokadu' },
  { label: 'Jokioinen', value: 'Jokioinen' },
  { label: 'Jokkmokk', value: 'Jokkmokk' },
  { label: 'Jolalpan', value: 'Jolalpan' },
  { label: 'Jolanda Di Savoia', value: 'Jolanda Di Savoia' },
  { label: 'Joldești', value: 'Joldești' },
  { label: 'Jolfa', value: 'Jolfa' },
  { label: 'Joliet', value: 'Joliet' },
  { label: 'Joliette', value: 'Joliette' },
  { label: 'Jolimont', value: 'Jolimont' },
  { label: 'Jolivue', value: 'Jolivue' },
  { label: 'Joljá', value: 'Joljá' },
  { label: 'Jollyville', value: 'Jollyville' },
  { label: 'Jolo', value: 'Jolo' },
  { label: 'Jolo', value: 'Jolo' },
  { label: 'Jolotichán', value: 'Jolotichán' },
  { label: 'Jolsibaquil', value: 'Jolsibaquil' },
  { label: 'Jølster', value: 'Jølster' },
  { label: 'Joltealal', value: 'Joltealal' },
  { label: 'Jomala', value: 'Jomala' },
  { label: 'Jomanichim', value: 'Jomanichim' },
  { label: 'Jombang', value: 'Jombang' },
  { label: 'Jomboy', value: 'Jomboy' },
  { label: 'Jomulco', value: 'Jomulco' },
  { label: 'Jona', value: 'Jona' },
  { label: 'Jonacapa', value: 'Jonacapa' },
  { label: 'Jonacatepec', value: 'Jonacatepec' },
  { label: 'Jonadi', value: 'Jonadi' },
  { label: 'Jonage', value: 'Jonage' },
  { label: 'Jonava', value: 'Jonava' },
  { label: 'Joncherey', value: 'Joncherey' },
  { label: 'Jonchery-Sur-Vesle', value: 'Jonchery-Sur-Vesle' },
  { label: 'Jondal', value: 'Jondal' },
  { label: 'Jonen', value: 'Jonen' },
  { label: 'Jones', value: 'Jones' },
  { label: 'Jones', value: 'Jones' },
  { label: 'Jones', value: 'Jones' },
  { label: 'Jones County', value: 'Jones County' },
  { label: 'Jones County', value: 'Jones County' },
  { label: 'Jones County', value: 'Jones County' },
  { label: 'Jones County', value: 'Jones County' },
  { label: 'Jones County', value: 'Jones County' },
  { label: 'Jones County', value: 'Jones County' },
  { label: 'Jones Creek', value: 'Jones Creek' },
  { label: 'Jones Pen', value: 'Jones Pen' },
  { label: 'Jones Town', value: 'Jones Town' },
  { label: 'Jonesboro', value: 'Jonesboro' },
  { label: 'Jonesboro', value: 'Jonesboro' },
  { label: 'Jonesboro', value: 'Jonesboro' },
  { label: 'Jonesboro', value: 'Jonesboro' },
  { label: 'Jonesboro', value: 'Jonesboro' },
  { label: 'Jonesborough', value: 'Jonesborough' },
  { label: 'Jonesport', value: 'Jonesport' },
  { label: 'Jonestown', value: 'Jonestown' },
  { label: 'Jonestown', value: 'Jonestown' },
  { label: 'Jonestown', value: 'Jonestown' },
  { label: 'Jonesville', value: 'Jonesville' },
  { label: 'Jonesville', value: 'Jonesville' },
  { label: 'Jonesville', value: 'Jonesville' },
  { label: 'Jonesville', value: 'Jonesville' },
  { label: 'Jongiyeh', value: 'Jongiyeh' },
  { label: 'Jongno-Gu', value: 'Jongno-Gu' },
  { label: 'Jongny', value: 'Jongny' },
  { label: 'Joniec', value: 'Joniec' },
  { label: 'Joniškis', value: 'Joniškis' },
  { label: 'Jönköping', value: 'Jönköping' },
  { label: 'Jonkowo', value: 'Jonkowo' },
  { label: 'Jonotla', value: 'Jonotla' },
  { label: 'Jonquera La', value: 'Jonquera La' },
  { label: 'Jonquerettes', value: 'Jonquerettes' },
  { label: 'Jonquière', value: 'Jonquière' },
  { label: 'Jonquières', value: 'Jonquières' },
  { label: 'Jonquières-Saint-Vincent', value: 'Jonquières-Saint-Vincent' },
  { label: 'Jons', value: 'Jons' },
  { label: 'Jonschwil', value: 'Jonschwil' },
  { label: 'Jonstorp', value: 'Jonstorp' },
  { label: 'Jonstorp', value: 'Jonstorp' },
  { label: 'Jonuta', value: 'Jonuta' },
  { label: 'Jonzac', value: 'Jonzac' },
  { label: 'Jonzieux', value: 'Jonzieux' },
  { label: 'Joondalup', value: 'Joondalup' },
  { label: 'Joondanna', value: 'Joondanna' },
  { label: 'Jopala', value: 'Jopala' },
  { label: 'Joplin', value: 'Joplin' },
  { label: 'Joppatowne', value: 'Joppatowne' },
  { label: 'Joppolo', value: 'Joppolo' },
  { label: 'Joppolo Giancaxio', value: 'Joppolo Giancaxio' },
  { label: 'Joquicingo', value: 'Joquicingo' },
  { label: 'Jorăşti', value: 'Jorăşti' },
  { label: 'Jorăști', value: 'Jorăști' },
  { label: 'Jorba', value: 'Jorba' },
  { label: 'Jorcas', value: 'Jorcas' },
  { label: 'Jordaan', value: 'Jordaan' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Jordán', value: 'Jordán' },
  { label: 'Jordan Springs', value: 'Jordan Springs' },
  { label: 'Jordânia', value: 'Jordânia' },
  { label: 'Jordanów', value: 'Jordanów' },
  { label: 'Jordanów Śląski', value: 'Jordanów Śląski' },
  { label: 'Jordanstown', value: 'Jordanstown' },
  { label: 'Jordão', value: 'Jordão' },
  { label: 'Jordbro', value: 'Jordbro' },
  { label: 'Jördenstorf', value: 'Jördenstorf' },
  { label: 'Jorethang', value: 'Jorethang' },
  { label: 'Jorf', value: 'Jorf' },
  { label: 'Jorge Basadre', value: 'Jorge Basadre' },
  { label: 'Jorge Jiménez Cantú', value: 'Jorge Jiménez Cantú' },
  { label: 'Jorhat', value: 'Jorhat' },
  { label: 'Jork', value: 'Jork' },
  { label: 'Jörlanda', value: 'Jörlanda' },
  { label: 'Joroan', value: 'Joroan' },
  { label: 'Jorobas', value: 'Jorobas' },
  { label: 'Jorochito', value: 'Jorochito' },
  { label: 'Joroinen', value: 'Joroinen' },
  { label: 'Jørpeland', value: 'Jørpeland' },
  { label: 'Jorquera', value: 'Jorquera' },
  { label: 'Jos', value: 'Jos' },
  { label: 'Josa', value: 'Josa' },
  { label: 'Josa I Tuixén', value: 'Josa I Tuixén' },
  { label: 'Jose Abad Santos', value: 'Jose Abad Santos' },
  { label: 'Jose Abad Santos', value: 'Jose Abad Santos' },
  { label: 'José Batlle Y Ordóñez', value: 'José Batlle Y Ordóñez' },
  { label: 'José Boiteux', value: 'José Boiteux' },
  { label: 'José Bonifácio', value: 'José Bonifácio' },
  { label: 'José Cardel', value: 'José Cardel' },
  { label: 'José Colomo', value: 'José Colomo' },
  { label: 'José Da Penha', value: 'José Da Penha' },
  { label: 'José De Freitas', value: 'José De Freitas' },
  { label: 'José De Jesús Aguirre Teocaltiche', value: 'José De Jesús Aguirre Teocaltiche' },
  { label: 'José De San Martín', value: 'José De San Martín' },
  { label: 'José Enrique Rodó', value: 'José Enrique Rodó' },
  { label: 'José Esteban Coronado', value: 'José Esteban Coronado' },
  { label: 'José F. Gutiérrez', value: 'José F. Gutiérrez' },
  { label: 'José Gonçalves De Minas', value: 'José Gonçalves De Minas' },
  { label: 'José Guadalupe Aguilera (Santa Lucía)', value: 'José Guadalupe Aguilera (Santa Lucía)' },
  { label: 'José Guadalupe Rodríguez', value: 'José Guadalupe Rodríguez' },
  { label: 'José Manuel Pando', value: 'José Manuel Pando' },
  { label: 'José María Morelos', value: 'José María Morelos' },
  { label: 'José María Morelos', value: 'José María Morelos' },
  { label: 'José María Morelos', value: 'José María Morelos' },
  { label: 'José María Morelos', value: 'José María Morelos' },
  { label: 'José María Morelos', value: 'José María Morelos' },
  { label: 'José María Morelos', value: 'José María Morelos' },
  { label: 'José María Morelos', value: 'José María Morelos' },
  { label: 'José María Morelos (La Yegüería)', value: 'José María Morelos (La Yegüería)' },
  { label: 'José María Morelos (San José)', value: 'José María Morelos (San José)' },
  { label: 'José María Morelos (Santa Mónica)', value: 'José María Morelos (Santa Mónica)' },
  { label: 'José María Morelos Y Pavón', value: 'José María Morelos Y Pavón' },
  { label: 'José María Morelos Y Pavón', value: 'José María Morelos Y Pavón' },
  { label: 'José María Morelos Y Pavón', value: 'José María Morelos Y Pavón' },
  { label: 'José María Pino Suárez', value: 'José María Pino Suárez' },
  { label: 'José María Pino Suárez', value: 'José María Pino Suárez' },
  { label: 'José María Pino Suárez', value: 'José María Pino Suárez' },
  { label: 'José María Pino Suárez', value: 'José María Pino Suárez' },
  { label: 'José Mariano Jiménez', value: 'José Mariano Jiménez' },
  { label: 'Jose Narciso Rovirosa', value: 'Jose Narciso Rovirosa' },
  { label: 'Jose Pañganiban', value: 'Jose Pañganiban' },
  { label: 'José Pedro Varela', value: 'José Pedro Varela' },
  { label: 'José Raydan', value: 'José Raydan' },
  { label: 'José Refugio Salcido', value: 'José Refugio Salcido' },
  { label: 'José Santos Guardiola', value: 'José Santos Guardiola' },
  { label: 'Josefa Ortíz De Domínguez', value: 'Josefa Ortíz De Domínguez' },
  { label: 'Josefina', value: 'Josefina' },
  { label: 'Josefův Důl', value: 'Josefův Důl' },
  { label: 'Joselândia', value: 'Joselândia' },
  { label: 'Joseni', value: 'Joseni' },
  { label: 'Josenii Bârgăului', value: 'Josenii Bârgăului' },
  { label: 'Josenópolis', value: 'Josenópolis' },
  { label: 'Joseph', value: 'Joseph' },
  { label: 'Joseph City', value: 'Joseph City' },
  { label: 'Josephine', value: 'Josephine' },
  { label: 'Josephine County', value: 'Josephine County' },
  { label: 'Joševa', value: 'Joševa' },
  { label: 'Josheqaneqali', value: 'Josheqaneqali' },
  { label: 'Joshil', value: 'Joshil' },
  { label: 'Joshimath', value: 'Joshimath' },
  { label: 'Joshua', value: 'Joshua' },
  { label: 'Joshua Tree', value: 'Joshua Tree' },
  { label: 'Josipovac', value: 'Josipovac' },
  { label: 'Joslin', value: 'Joslin' },
  { label: 'Jōsō-Shi', value: 'Jōsō-Shi' },
  { label: 'Josselin', value: 'Josselin' },
  { label: 'Jouarre', value: 'Jouarre' },
  { label: 'Jouars-Pontchartrain', value: 'Jouars-Pontchartrain' },
  { label: 'Joudreville', value: 'Joudreville' },
  { label: 'Joué-Lès-Tours', value: 'Joué-Lès-Tours' },
  { label: 'Jouet-Sur-Laubois', value: 'Jouet-Sur-Laubois' },
  { label: 'Jougne', value: 'Jougne' },
  { label: 'Jounieh', value: 'Jounieh' },
  { label: 'Joupar', value: 'Joupar' },
  { label: 'Jouques', value: 'Jouques' },
  { label: 'Jourdanton', value: 'Jourdanton' },
  { label: 'Joure', value: 'Joure' },
  { label: 'Joutsa', value: 'Joutsa' },
  { label: 'Joutseno', value: 'Joutseno' },
  { label: 'Joux-La-Ville', value: 'Joux-La-Ville' },
  { label: 'Jouy', value: 'Jouy' },
  { label: 'Jouy-Aux-Arches', value: 'Jouy-Aux-Arches' },
  { label: 'Jouy-En-Josas', value: 'Jouy-En-Josas' },
  { label: 'Jouy-Le-Châtel', value: 'Jouy-Le-Châtel' },
  { label: 'Jouy-Le-Moutier', value: 'Jouy-Le-Moutier' },
  { label: 'Jouy-Le-Potier', value: 'Jouy-Le-Potier' },
  { label: 'Jouy-Sur-Morin', value: 'Jouy-Sur-Morin' },
  { label: 'Jovellanos', value: 'Jovellanos' },
  { label: 'Jovellar', value: 'Jovellar' },
  { label: 'Jovellar', value: 'Jovellar' },
  { label: 'Jovellar', value: 'Jovellar' },
  { label: 'Jovellar', value: 'Jovellar' },
  { label: 'Jovencan', value: 'Jovencan' },
  { label: 'Joveyn', value: 'Joveyn' },
  { label: 'Joviânia', value: 'Joviânia' },
  { label: 'Jovim', value: 'Jovim' },
  { label: 'Jowkar', value: 'Jowkar' },
  { label: 'Jowsheqan Va Kamu', value: 'Jowsheqan Va Kamu' },
  { label: 'Jowzam', value: 'Jowzam' },
  { label: 'Jowzdan', value: 'Jowzdan' },
  { label: 'Joyabaj', value: 'Joyabaj' },
  { label: 'Joyeuse', value: 'Joyeuse' },
  { label: 'Joyeux', value: 'Joyeux' },
  { label: 'Joyner', value: 'Joyner' },
  { label: 'Jōyō Shi', value: 'Jōyō Shi' },
  { label: 'Joyosa La', value: 'Joyosa La' },
  { label: 'Joypurhat ', value: 'Joypurhat ' },
  { label: 'Józefosław', value: 'Józefosław' },
  { label: 'Józefów', value: 'Józefów' },
  { label: 'Józefów', value: 'Józefów' },
  { label: 'Józefów Nad Wisłą', value: 'Józefów Nad Wisłą' },
  { label: 'Józsefváros', value: 'Józsefváros' },
  { label: 'Jrahovit', value: 'Jrahovit' },
  { label: 'Jrashen', value: 'Jrashen' },
  { label: 'Jrashen', value: 'Jrashen' },
  { label: 'Jua', value: 'Jua' },
  { label: 'Juá Dos Vieiras', value: 'Juá Dos Vieiras' },
  { label: 'Juab County', value: 'Juab County' },
  { label: 'Juaboso', value: 'Juaboso' },
  { label: 'Juan Adrián', value: 'Juan Adrián' },
  { label: 'Juan Aldama', value: 'Juan Aldama' },
  { label: 'Juan Aldama', value: 'Juan Aldama' },
  { label: 'Juan Aldama (El Tigre)', value: 'Juan Aldama (El Tigre)' },
  { label: 'Juan C. Bonilla', value: 'Juan C. Bonilla' },
  { label: 'Juan De Acosta', value: 'Juan De Acosta' },
  { label: 'Juan De Ayolas', value: 'Juan De Ayolas' },
  { label: 'Juan De Herrera', value: 'Juan De Herrera' },
  { label: 'Juan De La Granja', value: 'Juan De La Granja' },
  { label: 'Juan Del Grijalva', value: 'Juan Del Grijalva' },
  { label: 'Juan Díaz', value: 'Juan Díaz' },
  { label: 'Juan Díaz', value: 'Juan Díaz' },
  { label: 'Juan Díaz Covarrubias', value: 'Juan Díaz Covarrubias' },
  { label: 'Juan E. García', value: 'Juan E. García' },
  { label: 'Juan Escutia (Borbollón)', value: 'Juan Escutia (Borbollón)' },
  { label: 'Juan Fernández', value: 'Juan Fernández' },
  { label: 'Juan Francisco Bulnes', value: 'Juan Francisco Bulnes' },
  { label: 'Juan Galindo', value: 'Juan Galindo' },
  { label: 'Juan Gil Preciado', value: 'Juan Gil Preciado' },
  { label: 'Juan Griego', value: 'Juan Griego' },
  { label: 'Juan Jacobo Torres [Bodega De Totontepec]', value: 'Juan Jacobo Torres [Bodega De Totontepec]' },
  { label: 'Juan José Ríos', value: 'Juan José Ríos' },
  { label: 'Juan L. Lacaze', value: 'Juan L. Lacaze' },
  { label: 'Juan López Abajo', value: 'Juan López Abajo' },
  { label: 'Juan Marcos (San José Buenavista)', value: 'Juan Marcos (San José Buenavista)' },
  { label: 'Juan Martín', value: 'Juan Martín' },
  { label: 'Juan Martín De Pueyrredón', value: 'Juan Martín De Pueyrredón' },
  { label: 'Juan Morales', value: 'Juan Morales' },
  { label: 'Juan N. Méndez', value: 'Juan N. Méndez' },
  { label: 'Juan Pujol', value: 'Juan Pujol' },
  { label: 'Juan Rodríguez Clara', value: 'Juan Rodríguez Clara' },
  { label: 'Juan Salazar', value: 'Juan Salazar' },
  { label: 'Juan Sarabia', value: 'Juan Sarabia' },
  { label: 'Juana Díaz', value: 'Juana Díaz' },
  { label: 'Juana Díaz', value: 'Juana Díaz' },
  { label: 'Juana Díaz Municipio', value: 'Juana Díaz Municipio' },
  { label: 'Juanacatlán', value: 'Juanacatlán' },
  { label: 'Juancho', value: 'Juancho' },
  { label: 'Juanicó', value: 'Juanicó' },
  { label: 'Juanjuí', value: 'Juanjuí' },
  { label: 'Juara', value: 'Juara' },
  { label: 'Juárez', value: 'Juárez' },
  { label: 'Juárez', value: 'Juárez' },
  { label: 'Juárez Coronaco', value: 'Juárez Coronaco' },
  { label: 'Juárez Nuevo León', value: 'Juárez Nuevo León' },
  { label: 'Juarez Távora', value: 'Juarez Távora' },
  { label: 'Juarina', value: 'Juarina' },
  { label: 'Juarros De Riomoros', value: 'Juarros De Riomoros' },
  { label: 'Juarros De Voltoya', value: 'Juarros De Voltoya' },
  { label: 'Juatuba', value: 'Juatuba' },
  { label: 'Juayúa', value: 'Juayúa' },
  { label: 'Juazeirinho', value: 'Juazeirinho' },
  { label: 'Juazeiro', value: 'Juazeiro' },
  { label: 'Juazeiro Do Norte', value: 'Juazeiro Do Norte' },
  { label: 'Juazeiro Do Piauí', value: 'Juazeiro Do Piauí' },
  { label: 'Juban', value: 'Juban' },
  { label: 'Jubasan', value: 'Jubasan' },
  { label: 'Jubasan', value: 'Jubasan' },
  { label: 'Jubbah', value: 'Jubbah' },
  { label: 'Jubbal', value: 'Jubbal' },
  { label: 'Jubbega', value: 'Jubbega' },
  { label: 'Jübek', value: 'Jübek' },
  { label: 'Jubilee Pocket', value: 'Jubilee Pocket' },
  { label: 'Jubrique', value: 'Jubrique' },
  { label: 'Jucás', value: 'Jucás' },
  { label: 'Jucati', value: 'Jucati' },
  { label: 'Jüchen', value: 'Jüchen' },
  { label: 'Juchipila', value: 'Juchipila' },
  { label: 'Juchique De Ferrer', value: 'Juchique De Ferrer' },
  { label: 'Juchitán De Zaragoza', value: 'Juchitán De Zaragoza' },
  { label: 'Juchitepec', value: 'Juchitepec' },
  { label: 'Jüchsen', value: 'Jüchsen' },
  { label: 'Jucuapa', value: 'Jucuapa' },
  { label: 'Jucuarán', value: 'Jucuarán' },
  { label: 'Jucuruçu', value: 'Jucuruçu' },
  { label: 'Jucurutu', value: 'Jucurutu' },
  { label: 'Jucutacato', value: 'Jucutacato' },
  { label: 'Judaberg', value: 'Judaberg' },
  { label: 'Judeida Makr', value: 'Judeida Makr' },
  { label: 'Judenbach', value: 'Judenbach' },
  { label: 'Judenburg', value: 'Judenburg' },
  { label: 'Judendorf', value: 'Judendorf' },
  { label: 'Judita', value: 'Judita' },
  { label: 'Judith Basin County', value: 'Judith Basin County' },
  { label: 'Judson', value: 'Judson' },
  { label: 'Judsonia', value: 'Judsonia' },
  { label: 'Juegang', value: 'Juegang' },
  { label: 'Juelsminde', value: 'Juelsminde' },
  { label: 'Jugăstreni', value: 'Jugăstreni' },
  { label: 'Jugenheim', value: 'Jugenheim' },
  { label: 'Jugno', value: 'Jugno' },
  { label: 'Jugon-Les-Lacs', value: 'Jugon-Les-Lacs' },
  { label: 'Jugów', value: 'Jugów' },
  { label: 'Jugsalai', value: 'Jugsalai' },
  { label: 'Jugur', value: 'Jugur' },
  { label: 'Jugureanu', value: 'Jugureanu' },
  { label: 'Jugureni', value: 'Jugureni' },
  { label: 'Juha Saudi Arabia', value: 'Juha Saudi Arabia' },
  { label: 'Juhaynah', value: 'Juhaynah' },
  { label: 'Jühnde', value: 'Jühnde' },
  { label: 'Juhu', value: 'Juhu' },
  { label: 'Juià', value: 'Juià' },
  { label: 'Juigalpa', value: 'Juigalpa' },
  { label: 'Juigné', value: 'Juigné' },
  { label: 'Juigné-Sur-Loire', value: 'Juigné-Sur-Loire' },
  { label: 'Juigné-Sur-Sarthe', value: 'Juigné-Sur-Sarthe' },
  { label: 'Juillac', value: 'Juillac' },
  { label: 'Juillan', value: 'Juillan' },
  { label: 'Juilly', value: 'Juilly' },
  { label: 'Juína', value: 'Juína' },
  { label: 'Juist', value: 'Juist' },
  { label: 'Juiz De Fora', value: 'Juiz De Fora' },
  { label: 'Jujurieux', value: 'Jujurieux' },
  { label: 'Jujutla', value: 'Jujutla' },
  { label: 'Julatten', value: 'Julatten' },
  { label: 'Julayjilah', value: 'Julayjilah' },
  { label: 'Julayyil', value: 'Julayyil' },
  { label: 'Julbach', value: 'Julbach' },
  { label: 'Julcan', value: 'Julcan' },
  { label: 'Julesburg', value: 'Julesburg' },
  { label: 'Julfa Rayon', value: 'Julfa Rayon' },
  { label: 'Juli', value: 'Juli' },
  { label: 'Juliaca', value: 'Juliaca' },
  { label: 'Julian', value: 'Julian' },
  { label: 'Julián Blanco (Dos Caminos)', value: 'Julián Blanco (Dos Caminos)' },
  { label: 'Julián Grajales', value: 'Julián Grajales' },
  { label: 'Julián Villagrán', value: 'Julián Villagrán' },
  { label: 'Julianadorp', value: 'Julianadorp' },
  { label: 'Jülich', value: 'Jülich' },
  { label: 'Julimes', value: 'Julimes' },
  { label: 'Júlio Borges', value: 'Júlio Borges' },
  { label: 'Júlio De Castilhos', value: 'Júlio De Castilhos' },
  { label: 'Júlio Mesquita', value: 'Júlio Mesquita' },
  { label: 'Julita', value: 'Julita' },
  { label: 'Julita', value: 'Julita' },
  { label: 'Jullouville', value: 'Jullouville' },
  { label: 'Juma', value: 'Juma' },
  { label: 'Jumelles', value: 'Jumelles' },
  { label: 'Jumgal', value: 'Jumgal' },
  { label: 'Jumièges', value: 'Jumièges' },
  { label: 'Jumilhac-Le-Grand', value: 'Jumilhac-Le-Grand' },
  { label: 'Jumilla', value: 'Jumilla' },
  { label: 'Jumiltepec', value: 'Jumiltepec' },
  { label: 'Jumirim', value: 'Jumirim' },
  { label: 'Jumla', value: 'Jumla' },
  { label: 'Jumprava', value: 'Jumprava' },
  { label: 'Jumri Tilaiya', value: 'Jumri Tilaiya' },
  { label: 'Jun', value: 'Jun' },
  { label: 'Junagadh', value: 'Junagadh' },
  { label: 'Junagarh', value: 'Junagarh' },
  { label: 'Junça', value: 'Junça' },
  { label: 'Juncais', value: 'Juncais' },
  { label: 'Juncal', value: 'Juncal' },
  { label: 'Juncalito Abajo', value: 'Juncalito Abajo' },
  { label: 'Junciana', value: 'Junciana' },
  { label: 'Junco Do Maranhão', value: 'Junco Do Maranhão' },
  { label: 'Junco Do Seridó', value: 'Junco Do Seridó' },
  { label: 'Juncos', value: 'Juncos' },
  { label: 'Juncos', value: 'Juncos' },
  { label: 'Juncosa', value: 'Juncosa' },
  { label: 'Junction', value: 'Junction' },
  { label: 'Junction', value: 'Junction' },
  { label: 'Junction', value: 'Junction' },
  { label: 'Junction City', value: 'Junction City' },
  { label: 'Junction City', value: 'Junction City' },
  { label: 'Junction City', value: 'Junction City' },
  { label: 'Junction Hill', value: 'Junction Hill' },
  { label: 'Junction Village', value: 'Junction Village' },
  { label: 'Jundiá', value: 'Jundiá' },
  { label: 'Jundiá', value: 'Jundiá' },
  { label: 'Jundiaí', value: 'Jundiaí' },
  { label: 'Jundiaí Do Sul', value: 'Jundiaí Do Sul' },
  { label: 'June Park', value: 'June Park' },
  { label: 'Juneau', value: 'Juneau' },
  { label: 'Juneau', value: 'Juneau' },
  { label: 'Juneau County', value: 'Juneau County' },
  { label: 'Juneda', value: 'Juneda' },
  { label: 'Junee', value: 'Junee' },
  { label: 'Jungapeo De Juárez', value: 'Jungapeo De Juárez' },
  { label: 'Jung-Gu', value: 'Jung-Gu' },
  { label: 'Jung-Gu', value: 'Jung-Gu' },
  { label: 'Jung-Gu', value: 'Jung-Gu' },
  { label: 'Jung-Gu', value: 'Jung-Gu' },
  { label: 'Jungholz', value: 'Jungholz' },
  { label: 'Jungingen', value: 'Jungingen' },
  { label: 'Junglinster', value: 'Junglinster' },
  { label: 'Jungnang-Gu', value: 'Jungnang-Gu' },
  { label: 'Juniata County', value: 'Juniata County' },
  { label: 'Junín', value: 'Junín' },
  { label: 'Junín', value: 'Junín' },
  { label: 'Junín', value: 'Junín' },
  { label: 'Junín De Los Andes', value: 'Junín De Los Andes' },
  { label: 'Jünkerath', value: 'Jünkerath' },
  { label: 'Junnar', value: 'Junnar' },
  { label: 'Juno Beach', value: 'Juno Beach' },
  { label: 'Junortoun', value: 'Junortoun' },
  { label: 'Junqan', value: 'Junqan' },
  { label: 'Junqueiro', value: 'Junqueiro' },
  { label: 'Junqueirópolis', value: 'Junqueirópolis' },
  { label: 'Junta De Traslaloma', value: 'Junta De Traslaloma' },
  { label: 'Junta De Villalba De Losa', value: 'Junta De Villalba De Losa' },
  { label: 'Juntas', value: 'Juntas' },
  { label: 'Juodupė', value: 'Juodupė' },
  { label: 'Jupâneşti', value: 'Jupâneşti' },
  { label: 'Jupânești', value: 'Jupânești' },
  { label: 'Jupi', value: 'Jupi' },
  { label: 'Jupiá', value: 'Jupiá' },
  { label: 'Jupiter', value: 'Jupiter' },
  { label: 'Juprelle', value: 'Juprelle' },
  { label: 'Juquiá', value: 'Juquiá' },
  { label: 'Juquitiba', value: 'Juquitiba' },
  { label: 'Jura', value: 'Jura' },
  { label: 'Jura Bernois', value: 'Jura Bernois' },
  { label: 'Juradó', value: 'Juradó' },
  { label: 'Juramento', value: 'Juramento' },
  { label: 'Jurançon', value: 'Jurançon' },
  { label: 'Juranda', value: 'Juranda' },
  { label: 'Jura-Nord Vaudois District', value: 'Jura-Nord Vaudois District' },
  { label: 'Juraqan', value: 'Juraqan' },
  { label: 'Juraré', value: 'Juraré' },
  { label: 'Jurbarkas', value: 'Jurbarkas' },
  { label: 'Jurbise', value: 'Jurbise' },
  { label: 'Jurema', value: 'Jurema' },
  { label: 'Jurema', value: 'Jurema' },
  { label: 'Jürgenshagen', value: 'Jürgenshagen' },
  { label: 'Jüri', value: 'Jüri' },
  { label: 'Jurien Bay', value: 'Jurien Bay' },
  { label: 'Jurilovca', value: 'Jurilovca' },
  { label: 'Juripiranga', value: 'Juripiranga' },
  { label: 'Juriquilla', value: 'Juriquilla' },
  { label: 'Jurisdicción De Lara', value: 'Jurisdicción De Lara' },
  { label: 'Jurisdicción De San Zadornil', value: 'Jurisdicción De San Zadornil' },
  { label: 'Jurm', value: 'Jurm' },
  { label: 'Jūrmala', value: 'Jūrmala' },
  { label: 'Jurong West', value: 'Jurong West' },
  { label: 'Juršinci', value: 'Juršinci' },
  { label: 'Jursla', value: 'Jursla' },
  { label: 'Juru', value: 'Juru' },
  { label: 'Juru', value: 'Juru' },
  { label: 'Juruá', value: 'Juruá' },
  { label: 'Juruaia', value: 'Juruaia' },
  { label: 'Juruena', value: 'Juruena' },
  { label: 'Jurupa Valley', value: 'Jurupa Valley' },
  { label: 'Juruti', value: 'Juruti' },
  { label: 'Jurva', value: 'Jurva' },
  { label: 'Juscimeira', value: 'Juscimeira' },
  { label: 'Juslapeña', value: 'Juslapeña' },
  { label: 'Jussac', value: 'Jussac' },
  { label: 'Jussara', value: 'Jussara' },
  { label: 'Jussara', value: 'Jussara' },
  { label: 'Jussara', value: 'Jussara' },
  { label: 'Jussari', value: 'Jussari' },
  { label: 'Jussey', value: 'Jussey' },
  { label: 'Jussiape', value: 'Jussiape' },
  { label: 'Jussy', value: 'Jussy' },
  { label: 'Jussy', value: 'Jussy' },
  { label: 'Justel', value: 'Justel' },
  { label: 'Justice', value: 'Justice' },
  { label: 'Justice', value: 'Justice' },
  { label: 'Justin', value: 'Justin' },
  { label: 'Justiniano Posse', value: 'Justiniano Posse' },
  { label: 'Justiniškės', value: 'Justiniškės' },
  { label: 'Justo Daract', value: 'Justo Daract' },
  { label: 'Justo Sierra', value: 'Justo Sierra' },
  { label: 'Justvik', value: 'Justvik' },
  { label: 'Juszczyn', value: 'Juszczyn' },
  { label: 'Juszczyna', value: 'Juszczyna' },
  { label: 'Jutaí', value: 'Jutaí' },
  { label: 'Jüterbog', value: 'Jüterbog' },
  { label: 'Juti', value: 'Juti' },
  { label: 'Jutiapa', value: 'Jutiapa' },
  { label: 'Jutiapa', value: 'Jutiapa' },
  { label: 'Jutiapa', value: 'Jutiapa' },
  { label: 'Juticalpa', value: 'Juticalpa' },
  { label: 'Jutiquile', value: 'Jutiquile' },
  { label: 'Jutogh', value: 'Jutogh' },
  { label: 'Jutrosin', value: 'Jutrosin' },
  { label: 'Juuka', value: 'Juuka' },
  { label: 'Juupajoki', value: 'Juupajoki' },
  { label: 'Juva', value: 'Juva' },
  { label: 'Juvenília', value: 'Juvenília' },
  { label: 'Juventino Rosas', value: 'Juventino Rosas' },
  { label: 'Juvignac', value: 'Juvignac' },
  { label: 'Juvigné', value: 'Juvigné' },
  { label: 'Juvigny-Sous-Andaine', value: 'Juvigny-Sous-Andaine' },
  { label: 'Juviles', value: 'Juviles' },
  { label: 'Juvisy-Sur-Orge', value: 'Juvisy-Sur-Orge' },
  { label: 'Juwana', value: 'Juwana' },
  { label: 'Juybar', value: 'Juybar' },
  { label: 'Juye', value: 'Juye' },
  { label: 'Juyom', value: 'Juyom' },
  { label: 'Juzbado', value: 'Juzbado' },
  { label: 'Júzcar', value: 'Júzcar' },
  { label: 'Juziers', value: 'Juziers' },
  { label: 'Jvari', value: 'Jvari' },
  { label: 'Jyderup', value: 'Jyderup' },
  { label: 'Jyllinge', value: 'Jyllinge' },
  { label: 'Jyotiba Phule Nagar', value: 'Jyotiba Phule Nagar' },
  { label: 'Jyväskylä', value: 'Jyväskylä' },
  { label: 'K. I. Sawyer Air Force Base', value: 'K. I. Sawyer Air Force Base' },
  { label: 'K’Alak’I Chiat’Ura', value: 'K’Alak’I Chiat’Ura' },
  { label: 'K’Anak’Erravan', value: 'K’Anak’Erravan' },
  { label: 'K’Olīto', value: 'K’Olīto' },
  { label: 'K’Ulashi', value: 'K’Ulashi' },
  { label: 'Ka`Lul', value: 'Ka`Lul' },
  { label: 'Ka‘A‘Awa', value: 'Ka‘A‘Awa' },
  { label: 'Kā‘Anapali', value: 'Kā‘Anapali' },
  { label: 'Kaa-Khem', value: 'Kaa-Khem' },
  { label: 'Kaalheide', value: 'Kaalheide' },
  { label: 'Kaanapali Landing', value: 'Kaanapali Landing' },
  { label: 'Kaarela', value: 'Kaarela' },
  { label: 'Kaarina', value: 'Kaarina' },
  { label: 'Kaarst', value: 'Kaarst' },
  { label: 'Kaba', value: 'Kaba' },
  { label: 'Kabac', value: 'Kabac' },
  { label: 'Kabacan', value: 'Kabacan' },
  { label: 'Kabacan', value: 'Kabacan' },
  { label: 'Kabadougou', value: 'Kabadougou' },
  { label: 'Kabadüz', value: 'Kabadüz' },
  { label: 'Kabakovo', value: 'Kabakovo' },
  { label: 'Kabala', value: 'Kabala' },
  { label: 'Kabalantian', value: 'Kabalantian' },
  { label: 'Kabale', value: 'Kabale' },
  { label: 'Kabalen', value: 'Kabalen' },
  { label: 'Kabalen', value: 'Kabalen' },
  { label: 'Kabalo', value: 'Kabalo' },
  { label: 'Kabanga', value: 'Kabanga' },
  { label: 'Kabanjahe', value: 'Kabanjahe' },
  { label: 'Kabankalan', value: 'Kabankalan' },
  { label: 'Kabanovo', value: 'Kabanovo' },
  { label: 'Kabansk', value: 'Kabansk' },
  { label: 'Kabardinka', value: 'Kabardinka' },
  { label: 'Kabare', value: 'Kabare' },
  { label: 'Kabarnet', value: 'Kabarnet' },
  { label: 'Kabasalan', value: 'Kabasalan' },
  { label: 'Kabasalan', value: 'Kabasalan' },
  { label: 'Kabasalan', value: 'Kabasalan' },
  { label: 'Kabataş', value: 'Kabataş' },
  { label: 'Kabaty', value: 'Kabaty' },
  { label: 'Kabayan', value: 'Kabayan' },
  { label: 'Kabba', value: 'Kabba' },
  { label: 'Kabeerdham', value: 'Kabeerdham' },
  { label: 'Kabelvåg', value: 'Kabelvåg' },
  { label: 'Kabilauan', value: 'Kabilauan' },
  { label: 'Kabin Buri', value: 'Kabin Buri' },
  { label: 'Kabinda', value: 'Kabinda' },
  { label: 'Kabirwala', value: 'Kabirwala' },
  { label: 'Kablalan', value: 'Kablalan' },
  { label: 'Kablalan', value: 'Kablalan' },
  { label: 'Kabo', value: 'Kabo' },
  { label: 'Kabompo', value: 'Kabompo' },
  { label: 'Kaboodrahang', value: 'Kaboodrahang' },
  { label: 'Kabrai', value: 'Kabrai' },
  { label: 'Kabudrahang', value: 'Kabudrahang' },
  { label: 'Kabugao', value: 'Kabugao' },
  { label: 'Kabugao', value: 'Kabugao' },
  { label: 'Kabul', value: 'Kabul' },
  { label: 'Kābūl', value: 'Kābūl' },
  { label: 'Kabula', value: 'Kabula' },
  { label: 'Kabulohan', value: 'Kabulohan' },
  { label: 'Kabulusan', value: 'Kabulusan' },
  { label: 'Kabungahan', value: 'Kabungahan' },
  { label: 'Kabuntalan', value: 'Kabuntalan' },
  { label: 'Kabupaten Aceh Barat', value: 'Kabupaten Aceh Barat' },
  { label: 'Kabupaten Aceh Barat Daya', value: 'Kabupaten Aceh Barat Daya' },
  { label: 'Kabupaten Aceh Besar', value: 'Kabupaten Aceh Besar' },
  { label: 'Kabupaten Aceh Jaya', value: 'Kabupaten Aceh Jaya' },
  { label: 'Kabupaten Aceh Selatan', value: 'Kabupaten Aceh Selatan' },
  { label: 'Kabupaten Aceh Singkil', value: 'Kabupaten Aceh Singkil' },
  { label: 'Kabupaten Aceh Tamiang', value: 'Kabupaten Aceh Tamiang' },
  { label: 'Kabupaten Aceh Tengah', value: 'Kabupaten Aceh Tengah' },
  { label: 'Kabupaten Aceh Tenggara', value: 'Kabupaten Aceh Tenggara' },
  { label: 'Kabupaten Aceh Timur', value: 'Kabupaten Aceh Timur' },
  { label: 'Kabupaten Aceh Utara', value: 'Kabupaten Aceh Utara' },
  { label: 'Kabupaten Agam', value: 'Kabupaten Agam' },
  { label: 'Kabupaten Alor', value: 'Kabupaten Alor' },
  { label: 'Kabupaten Asahan', value: 'Kabupaten Asahan' },
  { label: 'Kabupaten Asmat', value: 'Kabupaten Asmat' },
  { label: 'Kabupaten Badung', value: 'Kabupaten Badung' },
  { label: 'Kabupaten Balangan', value: 'Kabupaten Balangan' },
  { label: 'Kabupaten Bandung', value: 'Kabupaten Bandung' },
  { label: 'Kabupaten Bandung Barat', value: 'Kabupaten Bandung Barat' },
  { label: 'Kabupaten Banggai', value: 'Kabupaten Banggai' },
  { label: 'Kabupaten Banggai Kepulauan', value: 'Kabupaten Banggai Kepulauan' },
  { label: 'Kabupaten Banggai Laut', value: 'Kabupaten Banggai Laut' },
  { label: 'Kabupaten Bangka', value: 'Kabupaten Bangka' },
  { label: 'Kabupaten Bangka Barat', value: 'Kabupaten Bangka Barat' },
  { label: 'Kabupaten Bangka Selatan', value: 'Kabupaten Bangka Selatan' },
  { label: 'Kabupaten Bangka Tengah', value: 'Kabupaten Bangka Tengah' },
  { label: 'Kabupaten Bangkalan', value: 'Kabupaten Bangkalan' },
  { label: 'Kabupaten Bangli', value: 'Kabupaten Bangli' },
  { label: 'Kabupaten Banjar', value: 'Kabupaten Banjar' },
  { label: 'Kabupaten Banjarnegara', value: 'Kabupaten Banjarnegara' },
  { label: 'Kabupaten Bantaeng', value: 'Kabupaten Bantaeng' },
  { label: 'Kabupaten Bantul', value: 'Kabupaten Bantul' },
  { label: 'Kabupaten Banyumas', value: 'Kabupaten Banyumas' },
  { label: 'Kabupaten Banyuwangi', value: 'Kabupaten Banyuwangi' },
  { label: 'Kabupaten Barito Kuala', value: 'Kabupaten Barito Kuala' },
  { label: 'Kabupaten Barito Selatan', value: 'Kabupaten Barito Selatan' },
  { label: 'Kabupaten Barito Timur', value: 'Kabupaten Barito Timur' },
  { label: 'Kabupaten Barito Utara', value: 'Kabupaten Barito Utara' },
  { label: 'Kabupaten Barru', value: 'Kabupaten Barru' },
  { label: 'Kabupaten Batang', value: 'Kabupaten Batang' },
  { label: 'Kabupaten Batang Hari', value: 'Kabupaten Batang Hari' },
  { label: 'Kabupaten Batu Bara', value: 'Kabupaten Batu Bara' },
  { label: 'Kabupaten Bekasi', value: 'Kabupaten Bekasi' },
  { label: 'Kabupaten Belitung', value: 'Kabupaten Belitung' },
  { label: 'Kabupaten Belitung Timur', value: 'Kabupaten Belitung Timur' },
  { label: 'Kabupaten Belu', value: 'Kabupaten Belu' },
  { label: 'Kabupaten Bener Meriah', value: 'Kabupaten Bener Meriah' },
  { label: 'Kabupaten Bengkalis', value: 'Kabupaten Bengkalis' },
  { label: 'Kabupaten Bengkulu Selatan', value: 'Kabupaten Bengkulu Selatan' },
  { label: 'Kabupaten Bengkulu Tengah', value: 'Kabupaten Bengkulu Tengah' },
  { label: 'Kabupaten Bengkulu Utara', value: 'Kabupaten Bengkulu Utara' },
  { label: 'Kabupaten Berau', value: 'Kabupaten Berau' },
  { label: 'Kabupaten Biak Numfor', value: 'Kabupaten Biak Numfor' },
  { label: 'Kabupaten Bima', value: 'Kabupaten Bima' },
  { label: 'Kabupaten Bintan', value: 'Kabupaten Bintan' },
  { label: 'Kabupaten Bireuen', value: 'Kabupaten Bireuen' },
  { label: 'Kabupaten Blitar', value: 'Kabupaten Blitar' },
  { label: 'Kabupaten Blora', value: 'Kabupaten Blora' },
  { label: 'Kabupaten Boalemo', value: 'Kabupaten Boalemo' },
  { label: 'Kabupaten Bogor', value: 'Kabupaten Bogor' },
  { label: 'Kabupaten Bojonegoro', value: 'Kabupaten Bojonegoro' },
  { label: 'Kabupaten Bolaang Mongondow', value: 'Kabupaten Bolaang Mongondow' },
  { label: 'Kabupaten Bolaang Mongondow Selatan', value: 'Kabupaten Bolaang Mongondow Selatan' },
  { label: 'Kabupaten Bolaang Mongondow Timur', value: 'Kabupaten Bolaang Mongondow Timur' },
  { label: 'Kabupaten Bolaang Mongondow Utara', value: 'Kabupaten Bolaang Mongondow Utara' },
  { label: 'Kabupaten Bombana', value: 'Kabupaten Bombana' },
  { label: 'Kabupaten Bondowoso', value: 'Kabupaten Bondowoso' },
  { label: 'Kabupaten Bone', value: 'Kabupaten Bone' },
  { label: 'Kabupaten Bone Bolango', value: 'Kabupaten Bone Bolango' },
  { label: 'Kabupaten Boven Digoel', value: 'Kabupaten Boven Digoel' },
  { label: 'Kabupaten Boyolali', value: 'Kabupaten Boyolali' },
  { label: 'Kabupaten Brebes', value: 'Kabupaten Brebes' },
  { label: 'Kabupaten Buleleng', value: 'Kabupaten Buleleng' },
  { label: 'Kabupaten Bulukumba', value: 'Kabupaten Bulukumba' },
  { label: 'Kabupaten Bulungan', value: 'Kabupaten Bulungan' },
  { label: 'Kabupaten Bungo', value: 'Kabupaten Bungo' },
  { label: 'Kabupaten Buol', value: 'Kabupaten Buol' },
  { label: 'Kabupaten Buru', value: 'Kabupaten Buru' },
  { label: 'Kabupaten Buru Selatan', value: 'Kabupaten Buru Selatan' },
  { label: 'Kabupaten Buton', value: 'Kabupaten Buton' },
  { label: 'Kabupaten Buton Selatan', value: 'Kabupaten Buton Selatan' },
  { label: 'Kabupaten Buton Tengah', value: 'Kabupaten Buton Tengah' },
  { label: 'Kabupaten Buton Utara', value: 'Kabupaten Buton Utara' },
  { label: 'Kabupaten Ciamis', value: 'Kabupaten Ciamis' },
  { label: 'Kabupaten Cianjur', value: 'Kabupaten Cianjur' },
  { label: 'Kabupaten Cilacap', value: 'Kabupaten Cilacap' },
  { label: 'Kabupaten Cirebon', value: 'Kabupaten Cirebon' },
  { label: 'Kabupaten Dairi', value: 'Kabupaten Dairi' },
  { label: 'Kabupaten Deiyai', value: 'Kabupaten Deiyai' },
  { label: 'Kabupaten Deli Serdang', value: 'Kabupaten Deli Serdang' },
  { label: 'Kabupaten Demak', value: 'Kabupaten Demak' },
  { label: 'Kabupaten Dharmasraya', value: 'Kabupaten Dharmasraya' },
  { label: 'Kabupaten Dogiyai', value: 'Kabupaten Dogiyai' },
  { label: 'Kabupaten Dompu', value: 'Kabupaten Dompu' },
  { label: 'Kabupaten Donggala', value: 'Kabupaten Donggala' },
  { label: 'Kabupaten Empat Lawang', value: 'Kabupaten Empat Lawang' },
  { label: 'Kabupaten Ende', value: 'Kabupaten Ende' },
  { label: 'Kabupaten Enrekang', value: 'Kabupaten Enrekang' },
  { label: 'Kabupaten Fakfak', value: 'Kabupaten Fakfak' },
  { label: 'Kabupaten Flores Timur', value: 'Kabupaten Flores Timur' },
  { label: 'Kabupaten Garut', value: 'Kabupaten Garut' },
  { label: 'Kabupaten Gayo Lues', value: 'Kabupaten Gayo Lues' },
  { label: 'Kabupaten Gianyar', value: 'Kabupaten Gianyar' },
  { label: 'Kabupaten Gorontalo', value: 'Kabupaten Gorontalo' },
  { label: 'Kabupaten Gorontalo Utara', value: 'Kabupaten Gorontalo Utara' },
  { label: 'Kabupaten Gowa', value: 'Kabupaten Gowa' },
  { label: 'Kabupaten Grobogan', value: 'Kabupaten Grobogan' },
  { label: 'Kabupaten Gunung Kidul', value: 'Kabupaten Gunung Kidul' },
  { label: 'Kabupaten Gunung Mas', value: 'Kabupaten Gunung Mas' },
  { label: 'Kabupaten Halmahera Barat', value: 'Kabupaten Halmahera Barat' },
  { label: 'Kabupaten Halmahera Selatan', value: 'Kabupaten Halmahera Selatan' },
  { label: 'Kabupaten Halmahera Tengah', value: 'Kabupaten Halmahera Tengah' },
  { label: 'Kabupaten Halmahera Utara', value: 'Kabupaten Halmahera Utara' },
  { label: 'Kabupaten Hulu Sungai Selatan', value: 'Kabupaten Hulu Sungai Selatan' },
  { label: 'Kabupaten Hulu Sungai Tengah', value: 'Kabupaten Hulu Sungai Tengah' },
  { label: 'Kabupaten Hulu Sungai Utara', value: 'Kabupaten Hulu Sungai Utara' },
  { label: 'Kabupaten Humbang Hasundutan', value: 'Kabupaten Humbang Hasundutan' },
  { label: 'Kabupaten Indragiri Hilir', value: 'Kabupaten Indragiri Hilir' },
  { label: 'Kabupaten Indragiri Hulu', value: 'Kabupaten Indragiri Hulu' },
  { label: 'Kabupaten Indramayu', value: 'Kabupaten Indramayu' },
  { label: 'Kabupaten Intan Jaya', value: 'Kabupaten Intan Jaya' },
  { label: 'Kabupaten Jayapura', value: 'Kabupaten Jayapura' },
  { label: 'Kabupaten Jayawijaya', value: 'Kabupaten Jayawijaya' },
  { label: 'Kabupaten Jember', value: 'Kabupaten Jember' },
  { label: 'Kabupaten Jembrana', value: 'Kabupaten Jembrana' },
  { label: 'Kabupaten Jeneponto', value: 'Kabupaten Jeneponto' },
  { label: 'Kabupaten Jepara', value: 'Kabupaten Jepara' },
  { label: 'Kabupaten Jombang', value: 'Kabupaten Jombang' },
  { label: 'Kabupaten Kaimana', value: 'Kabupaten Kaimana' },
  { label: 'Kabupaten Kampar', value: 'Kabupaten Kampar' },
  { label: 'Kabupaten Kapuas', value: 'Kabupaten Kapuas' },
  { label: 'Kabupaten Karang Asem', value: 'Kabupaten Karang Asem' },
  { label: 'Kabupaten Karanganyar', value: 'Kabupaten Karanganyar' },
  { label: 'Kabupaten Karawang', value: 'Kabupaten Karawang' },
  { label: 'Kabupaten Karimun', value: 'Kabupaten Karimun' },
  { label: 'Kabupaten Karo', value: 'Kabupaten Karo' },
  { label: 'Kabupaten Katingan', value: 'Kabupaten Katingan' },
  { label: 'Kabupaten Kaur', value: 'Kabupaten Kaur' },
  { label: 'Kabupaten Kebumen', value: 'Kabupaten Kebumen' },
  { label: 'Kabupaten Kediri', value: 'Kabupaten Kediri' },
  { label: 'Kabupaten Keerom', value: 'Kabupaten Keerom' },
  { label: 'Kabupaten Kendal', value: 'Kabupaten Kendal' },
  { label: 'Kabupaten Kepahiang', value: 'Kabupaten Kepahiang' },
  { label: 'Kabupaten Kepulauan Anambas', value: 'Kabupaten Kepulauan Anambas' },
  { label: 'Kabupaten Kepulauan Aru', value: 'Kabupaten Kepulauan Aru' },
  { label: 'Kabupaten Kepulauan Mentawai', value: 'Kabupaten Kepulauan Mentawai' },
  { label: 'Kabupaten Kepulauan Meranti', value: 'Kabupaten Kepulauan Meranti' },
  { label: 'Kabupaten Kepulauan Sangihe', value: 'Kabupaten Kepulauan Sangihe' },
  { label: 'Kabupaten Kepulauan Sula', value: 'Kabupaten Kepulauan Sula' },
  { label: 'Kabupaten Kepulauan Talaud', value: 'Kabupaten Kepulauan Talaud' },
  { label: 'Kabupaten Kepulauan Yapen', value: 'Kabupaten Kepulauan Yapen' },
  { label: 'Kabupaten Kerinci', value: 'Kabupaten Kerinci' },
  { label: 'Kabupaten Klaten', value: 'Kabupaten Klaten' },
  { label: 'Kabupaten Klungkung', value: 'Kabupaten Klungkung' },
  { label: 'Kabupaten Kolaka', value: 'Kabupaten Kolaka' },
  { label: 'Kabupaten Kolaka Timur', value: 'Kabupaten Kolaka Timur' },
  { label: 'Kabupaten Kolaka Utara', value: 'Kabupaten Kolaka Utara' },
  { label: 'Kabupaten Konawe', value: 'Kabupaten Konawe' },
  { label: 'Kabupaten Konawe Kepulauan', value: 'Kabupaten Konawe Kepulauan' },
  { label: 'Kabupaten Konawe Selatan', value: 'Kabupaten Konawe Selatan' },
  { label: 'Kabupaten Konawe Utara', value: 'Kabupaten Konawe Utara' },
  { label: 'Kabupaten Kota Baru', value: 'Kabupaten Kota Baru' },
  { label: 'Kabupaten Kotawaringin Barat', value: 'Kabupaten Kotawaringin Barat' },
  { label: 'Kabupaten Kotawaringin Timur', value: 'Kabupaten Kotawaringin Timur' },
  { label: 'Kabupaten Kuantan Singingi', value: 'Kabupaten Kuantan Singingi' },
  { label: 'Kabupaten Kudus', value: 'Kabupaten Kudus' },
  { label: 'Kabupaten Kulon Progo', value: 'Kabupaten Kulon Progo' },
  { label: 'Kabupaten Kuningan', value: 'Kabupaten Kuningan' },
  { label: 'Kabupaten Kupang', value: 'Kabupaten Kupang' },
  { label: 'Kabupaten Kutai Barat', value: 'Kabupaten Kutai Barat' },
  { label: 'Kabupaten Kutai Kartanegara', value: 'Kabupaten Kutai Kartanegara' },
  { label: 'Kabupaten Kutai Timur', value: 'Kabupaten Kutai Timur' },
  { label: 'Kabupaten Labuhan Batu', value: 'Kabupaten Labuhan Batu' },
  { label: 'Kabupaten Labuhan Batu Selatan', value: 'Kabupaten Labuhan Batu Selatan' },
  { label: 'Kabupaten Labuhan Batu Utara', value: 'Kabupaten Labuhan Batu Utara' },
  { label: 'Kabupaten Lamandau', value: 'Kabupaten Lamandau' },
  { label: 'Kabupaten Lamongan', value: 'Kabupaten Lamongan' },
  { label: 'Kabupaten Lampung Barat', value: 'Kabupaten Lampung Barat' },
  { label: 'Kabupaten Lampung Selatan', value: 'Kabupaten Lampung Selatan' },
  { label: 'Kabupaten Lampung Tengah', value: 'Kabupaten Lampung Tengah' },
  { label: 'Kabupaten Lampung Timur', value: 'Kabupaten Lampung Timur' },
  { label: 'Kabupaten Lampung Utara', value: 'Kabupaten Lampung Utara' },
  { label: 'Kabupaten Langkat', value: 'Kabupaten Langkat' },
  { label: 'Kabupaten Lanny Jaya', value: 'Kabupaten Lanny Jaya' },
  { label: 'Kabupaten Lebak', value: 'Kabupaten Lebak' },
  { label: 'Kabupaten Lebong', value: 'Kabupaten Lebong' },
  { label: 'Kabupaten Lembata', value: 'Kabupaten Lembata' },
  { label: 'Kabupaten Lima Puluh Kota', value: 'Kabupaten Lima Puluh Kota' },
  { label: 'Kabupaten Lingga', value: 'Kabupaten Lingga' },
  { label: 'Kabupaten Lombok Barat', value: 'Kabupaten Lombok Barat' },
  { label: 'Kabupaten Lombok Tengah', value: 'Kabupaten Lombok Tengah' },
  { label: 'Kabupaten Lombok Timur', value: 'Kabupaten Lombok Timur' },
  { label: 'Kabupaten Lombok Utara', value: 'Kabupaten Lombok Utara' },
  { label: 'Kabupaten Lumajang', value: 'Kabupaten Lumajang' },
  { label: 'Kabupaten Luwu', value: 'Kabupaten Luwu' },
  { label: 'Kabupaten Luwu Timur', value: 'Kabupaten Luwu Timur' },
  { label: 'Kabupaten Luwu Utara', value: 'Kabupaten Luwu Utara' },
  { label: 'Kabupaten Madiun', value: 'Kabupaten Madiun' },
  { label: 'Kabupaten Magelang', value: 'Kabupaten Magelang' },
  { label: 'Kabupaten Magetan', value: 'Kabupaten Magetan' },
  { label: 'Kabupaten Mahakam Hulu', value: 'Kabupaten Mahakam Hulu' },
  { label: 'Kabupaten Majalengka', value: 'Kabupaten Majalengka' },
  { label: 'Kabupaten Majene', value: 'Kabupaten Majene' },
  { label: 'Kabupaten Malaka', value: 'Kabupaten Malaka' },
  { label: 'Kabupaten Malang', value: 'Kabupaten Malang' },
  { label: 'Kabupaten Malinau', value: 'Kabupaten Malinau' },
  { label: 'Kabupaten Maluku Barat Daya', value: 'Kabupaten Maluku Barat Daya' },
  { label: 'Kabupaten Maluku Tengah', value: 'Kabupaten Maluku Tengah' },
  { label: 'Kabupaten Maluku Tenggara', value: 'Kabupaten Maluku Tenggara' },
  { label: 'Kabupaten Maluku Tenggara Barat', value: 'Kabupaten Maluku Tenggara Barat' },
  { label: 'Kabupaten Mamasa', value: 'Kabupaten Mamasa' },
  { label: 'Kabupaten Mamberamo Raya', value: 'Kabupaten Mamberamo Raya' },
  { label: 'Kabupaten Mamberamo Tengah', value: 'Kabupaten Mamberamo Tengah' },
  { label: 'Kabupaten Mamuju', value: 'Kabupaten Mamuju' },
  { label: 'Kabupaten Mamuju Tengah', value: 'Kabupaten Mamuju Tengah' },
  { label: 'Kabupaten Mamuju Utara', value: 'Kabupaten Mamuju Utara' },
  { label: 'Kabupaten Mandailing Natal', value: 'Kabupaten Mandailing Natal' },
  { label: 'Kabupaten Manggarai', value: 'Kabupaten Manggarai' },
  { label: 'Kabupaten Manggarai Barat', value: 'Kabupaten Manggarai Barat' },
  { label: 'Kabupaten Manggarai Timur', value: 'Kabupaten Manggarai Timur' },
  { label: 'Kabupaten Manokwari', value: 'Kabupaten Manokwari' },
  { label: 'Kabupaten Manokwari Selatan', value: 'Kabupaten Manokwari Selatan' },
  { label: 'Kabupaten Mappi', value: 'Kabupaten Mappi' },
  { label: 'Kabupaten Maros', value: 'Kabupaten Maros' },
  { label: 'Kabupaten Maybrat', value: 'Kabupaten Maybrat' },
  { label: 'Kabupaten Merangin', value: 'Kabupaten Merangin' },
  { label: 'Kabupaten Merauke', value: 'Kabupaten Merauke' },
  { label: 'Kabupaten Mesuji', value: 'Kabupaten Mesuji' },
  { label: 'Kabupaten Mimika', value: 'Kabupaten Mimika' },
  { label: 'Kabupaten Minahasa', value: 'Kabupaten Minahasa' },
  { label: 'Kabupaten Minahasa Selatan', value: 'Kabupaten Minahasa Selatan' },
  { label: 'Kabupaten Minahasa Tenggara', value: 'Kabupaten Minahasa Tenggara' },
  { label: 'Kabupaten Minahasa Utara', value: 'Kabupaten Minahasa Utara' },
  { label: 'Kabupaten Mojokerto', value: 'Kabupaten Mojokerto' },
  { label: 'Kabupaten Morowali Utara', value: 'Kabupaten Morowali Utara' },
  { label: 'Kabupaten Muara Enim', value: 'Kabupaten Muara Enim' },
  { label: 'Kabupaten Muaro Jambi', value: 'Kabupaten Muaro Jambi' },
  { label: 'Kabupaten Mukomuko', value: 'Kabupaten Mukomuko' },
  { label: 'Kabupaten Muna', value: 'Kabupaten Muna' },
  { label: 'Kabupaten Muna Barat', value: 'Kabupaten Muna Barat' },
  { label: 'Kabupaten Murung Raya', value: 'Kabupaten Murung Raya' },
  { label: 'Kabupaten Musi Banyuasin', value: 'Kabupaten Musi Banyuasin' },
  { label: 'Kabupaten Musi Rawas', value: 'Kabupaten Musi Rawas' },
  { label: 'Kabupaten Musi Rawas Utara', value: 'Kabupaten Musi Rawas Utara' },
  { label: 'Kabupaten Nabire', value: 'Kabupaten Nabire' },
  { label: 'Kabupaten Nagan Raya', value: 'Kabupaten Nagan Raya' },
  { label: 'Kabupaten Nagekeo', value: 'Kabupaten Nagekeo' },
  { label: 'Kabupaten Natuna', value: 'Kabupaten Natuna' },
  { label: 'Kabupaten Nduga', value: 'Kabupaten Nduga' },
  { label: 'Kabupaten Ngada', value: 'Kabupaten Ngada' },
  { label: 'Kabupaten Nganjuk', value: 'Kabupaten Nganjuk' },
  { label: 'Kabupaten Ngawi', value: 'Kabupaten Ngawi' },
  { label: 'Kabupaten Nias', value: 'Kabupaten Nias' },
  { label: 'Kabupaten Nias Barat', value: 'Kabupaten Nias Barat' },
  { label: 'Kabupaten Nias Utara', value: 'Kabupaten Nias Utara' },
  { label: 'Kabupaten Nunukan', value: 'Kabupaten Nunukan' },
  { label: 'Kabupaten Ogan Ilir', value: 'Kabupaten Ogan Ilir' },
  { label: 'Kabupaten Ogan Komering Ilir', value: 'Kabupaten Ogan Komering Ilir' },
  { label: 'Kabupaten Ogan Komering Ulu', value: 'Kabupaten Ogan Komering Ulu' },
  { label: 'Kabupaten Ogan Komering Ulu Selatan', value: 'Kabupaten Ogan Komering Ulu Selatan' },
  { label: 'Kabupaten Ogan Komering Ulu Timur', value: 'Kabupaten Ogan Komering Ulu Timur' },
  { label: 'Kabupaten Pacitan', value: 'Kabupaten Pacitan' },
  { label: 'Kabupaten Padang Lawas', value: 'Kabupaten Padang Lawas' },
  { label: 'Kabupaten Padang Lawas Utara', value: 'Kabupaten Padang Lawas Utara' },
  { label: 'Kabupaten Padang Pariaman', value: 'Kabupaten Padang Pariaman' },
  { label: 'Kabupaten Pakpak Bharat', value: 'Kabupaten Pakpak Bharat' },
  { label: 'Kabupaten Pamekasan', value: 'Kabupaten Pamekasan' },
  { label: 'Kabupaten Pandeglang', value: 'Kabupaten Pandeglang' },
  { label: 'Kabupaten Pangandaran', value: 'Kabupaten Pangandaran' },
  { label: 'Kabupaten Pangkajene Dan Kepulauan', value: 'Kabupaten Pangkajene Dan Kepulauan' },
  { label: 'Kabupaten Paniai', value: 'Kabupaten Paniai' },
  { label: 'Kabupaten Parigi Moutong', value: 'Kabupaten Parigi Moutong' },
  { label: 'Kabupaten Pasaman', value: 'Kabupaten Pasaman' },
  { label: 'Kabupaten Pasaman Barat', value: 'Kabupaten Pasaman Barat' },
  { label: 'Kabupaten Paser', value: 'Kabupaten Paser' },
  { label: 'Kabupaten Pasuruan', value: 'Kabupaten Pasuruan' },
  { label: 'Kabupaten Pati', value: 'Kabupaten Pati' },
  { label: 'Kabupaten Pegunungan Bintang', value: 'Kabupaten Pegunungan Bintang' },
  { label: 'Kabupaten Pekalongan', value: 'Kabupaten Pekalongan' },
  { label: 'Kabupaten Pelalawan', value: 'Kabupaten Pelalawan' },
  { label: 'Kabupaten Pemalang', value: 'Kabupaten Pemalang' },
  { label: 'Kabupaten Penajam Paser Utara', value: 'Kabupaten Penajam Paser Utara' },
  { label: 'Kabupaten Penukal Abab Lematang Ilir', value: 'Kabupaten Penukal Abab Lematang Ilir' },
  { label: 'Kabupaten Pesawaran', value: 'Kabupaten Pesawaran' },
  { label: 'Kabupaten Pesisir Barat', value: 'Kabupaten Pesisir Barat' },
  { label: 'Kabupaten Pesisir Selatan', value: 'Kabupaten Pesisir Selatan' },
  { label: 'Kabupaten Pidie', value: 'Kabupaten Pidie' },
  { label: 'Kabupaten Pinrang', value: 'Kabupaten Pinrang' },
  { label: 'Kabupaten Pohuwato', value: 'Kabupaten Pohuwato' },
  { label: 'Kabupaten Polewali Mandar', value: 'Kabupaten Polewali Mandar' },
  { label: 'Kabupaten Ponorogo', value: 'Kabupaten Ponorogo' },
  { label: 'Kabupaten Poso', value: 'Kabupaten Poso' },
  { label: 'Kabupaten Pringsewu', value: 'Kabupaten Pringsewu' },
  { label: 'Kabupaten Probolinggo', value: 'Kabupaten Probolinggo' },
  { label: 'Kabupaten Pulang Pisau', value: 'Kabupaten Pulang Pisau' },
  { label: 'Kabupaten Pulau Morotai', value: 'Kabupaten Pulau Morotai' },
  { label: 'Kabupaten Pulau Taliabu', value: 'Kabupaten Pulau Taliabu' },
  { label: 'Kabupaten Puncak', value: 'Kabupaten Puncak' },
  { label: 'Kabupaten Puncak Jaya', value: 'Kabupaten Puncak Jaya' },
  { label: 'Kabupaten Purbalingga', value: 'Kabupaten Purbalingga' },
  { label: 'Kabupaten Purwakarta', value: 'Kabupaten Purwakarta' },
  { label: 'Kabupaten Purworejo', value: 'Kabupaten Purworejo' },
  { label: 'Kabupaten Raja Ampat', value: 'Kabupaten Raja Ampat' },
  { label: 'Kabupaten Rejang Lebong', value: 'Kabupaten Rejang Lebong' },
  { label: 'Kabupaten Rembang', value: 'Kabupaten Rembang' },
  { label: 'Kabupaten Rokan Hilir', value: 'Kabupaten Rokan Hilir' },
  { label: 'Kabupaten Rokan Hulu', value: 'Kabupaten Rokan Hulu' },
  { label: 'Kabupaten Rote Ndao', value: 'Kabupaten Rote Ndao' },
  { label: 'Kabupaten Sabu Raijua', value: 'Kabupaten Sabu Raijua' },
  { label: 'Kabupaten Samosir', value: 'Kabupaten Samosir' },
  { label: 'Kabupaten Sampang', value: 'Kabupaten Sampang' },
  { label: 'Kabupaten Sarmi', value: 'Kabupaten Sarmi' },
  { label: 'Kabupaten Sarolangun', value: 'Kabupaten Sarolangun' },
  { label: 'Kabupaten Seluma', value: 'Kabupaten Seluma' },
  { label: 'Kabupaten Semarang', value: 'Kabupaten Semarang' },
  { label: 'Kabupaten Seram Bagian Barat', value: 'Kabupaten Seram Bagian Barat' },
  { label: 'Kabupaten Seram Bagian Timur', value: 'Kabupaten Seram Bagian Timur' },
  { label: 'Kabupaten Serang', value: 'Kabupaten Serang' },
  { label: 'Kabupaten Serdang Bedagai', value: 'Kabupaten Serdang Bedagai' },
  { label: 'Kabupaten Seruyan', value: 'Kabupaten Seruyan' },
  { label: 'Kabupaten Siak', value: 'Kabupaten Siak' },
  { label: 'Kabupaten Siau Tagulandang Biaro', value: 'Kabupaten Siau Tagulandang Biaro' },
  { label: 'Kabupaten Sidenreng Rappang', value: 'Kabupaten Sidenreng Rappang' },
  { label: 'Kabupaten Sidoarjo', value: 'Kabupaten Sidoarjo' },
  { label: 'Kabupaten Sigi', value: 'Kabupaten Sigi' },
  { label: 'Kabupaten Sijunjung', value: 'Kabupaten Sijunjung' },
  { label: 'Kabupaten Sikka', value: 'Kabupaten Sikka' },
  { label: 'Kabupaten Simalungun', value: 'Kabupaten Simalungun' },
  { label: 'Kabupaten Simeulue', value: 'Kabupaten Simeulue' },
  { label: 'Kabupaten Sinjai', value: 'Kabupaten Sinjai' },
  { label: 'Kabupaten Situbondo', value: 'Kabupaten Situbondo' },
  { label: 'Kabupaten Sleman', value: 'Kabupaten Sleman' },
  { label: 'Kabupaten Solok', value: 'Kabupaten Solok' },
  { label: 'Kabupaten Solok Selatan', value: 'Kabupaten Solok Selatan' },
  { label: 'Kabupaten Soppeng', value: 'Kabupaten Soppeng' },
  { label: 'Kabupaten Sorong', value: 'Kabupaten Sorong' },
  { label: 'Kabupaten Sorong Selatan', value: 'Kabupaten Sorong Selatan' },
  { label: 'Kabupaten Sragen', value: 'Kabupaten Sragen' },
  { label: 'Kabupaten Subang', value: 'Kabupaten Subang' },
  { label: 'Kabupaten Sukabumi', value: 'Kabupaten Sukabumi' },
  { label: 'Kabupaten Sukamara', value: 'Kabupaten Sukamara' },
  { label: 'Kabupaten Sukoharjo', value: 'Kabupaten Sukoharjo' },
  { label: 'Kabupaten Sumba Barat', value: 'Kabupaten Sumba Barat' },
  { label: 'Kabupaten Sumba Barat Daya', value: 'Kabupaten Sumba Barat Daya' },
  { label: 'Kabupaten Sumba Tengah', value: 'Kabupaten Sumba Tengah' },
  { label: 'Kabupaten Sumba Timur', value: 'Kabupaten Sumba Timur' },
  { label: 'Kabupaten Sumbawa', value: 'Kabupaten Sumbawa' },
  { label: 'Kabupaten Sumbawa Barat', value: 'Kabupaten Sumbawa Barat' },
  { label: 'Kabupaten Sumedang', value: 'Kabupaten Sumedang' },
  { label: 'Kabupaten Sumenep', value: 'Kabupaten Sumenep' },
  { label: 'Kabupaten Supiori', value: 'Kabupaten Supiori' },
  { label: 'Kabupaten Tabalong', value: 'Kabupaten Tabalong' },
  { label: 'Kabupaten Tabanan', value: 'Kabupaten Tabanan' },
  { label: 'Kabupaten Takalar', value: 'Kabupaten Takalar' },
  { label: 'Kabupaten Tambrauw', value: 'Kabupaten Tambrauw' },
  { label: 'Kabupaten Tana Tidung', value: 'Kabupaten Tana Tidung' },
  { label: 'Kabupaten Tana Toraja', value: 'Kabupaten Tana Toraja' },
  { label: 'Kabupaten Tanah Bumbu', value: 'Kabupaten Tanah Bumbu' },
  { label: 'Kabupaten Tanah Datar', value: 'Kabupaten Tanah Datar' },
  { label: 'Kabupaten Tanah Laut', value: 'Kabupaten Tanah Laut' },
  { label: 'Kabupaten Tangerang', value: 'Kabupaten Tangerang' },
  { label: 'Kabupaten Tanggamus', value: 'Kabupaten Tanggamus' },
  { label: 'Kabupaten Tanjung Jabung Barat', value: 'Kabupaten Tanjung Jabung Barat' },
  { label: 'Kabupaten Tanjung Jabung Timur', value: 'Kabupaten Tanjung Jabung Timur' },
  { label: 'Kabupaten Tapanuli Selatan', value: 'Kabupaten Tapanuli Selatan' },
  { label: 'Kabupaten Tapanuli Tengah', value: 'Kabupaten Tapanuli Tengah' },
  { label: 'Kabupaten Tapanuli Utara', value: 'Kabupaten Tapanuli Utara' },
  { label: 'Kabupaten Tapin', value: 'Kabupaten Tapin' },
  { label: 'Kabupaten Tasikmalaya', value: 'Kabupaten Tasikmalaya' },
  { label: 'Kabupaten Tebo', value: 'Kabupaten Tebo' },
  { label: 'Kabupaten Tegal', value: 'Kabupaten Tegal' },
  { label: 'Kabupaten Teluk Bintuni', value: 'Kabupaten Teluk Bintuni' },
  { label: 'Kabupaten Teluk Wondama', value: 'Kabupaten Teluk Wondama' },
  { label: 'Kabupaten Temanggung', value: 'Kabupaten Temanggung' },
  { label: 'Kabupaten Timor Tengah Selatan', value: 'Kabupaten Timor Tengah Selatan' },
  { label: 'Kabupaten Timor Tengah Utara', value: 'Kabupaten Timor Tengah Utara' },
  { label: 'Kabupaten Tolikara', value: 'Kabupaten Tolikara' },
  { label: 'Kabupaten Toli-Toli', value: 'Kabupaten Toli-Toli' },
  { label: 'Kabupaten Toraja Utara', value: 'Kabupaten Toraja Utara' },
  { label: 'Kabupaten Trenggalek', value: 'Kabupaten Trenggalek' },
  { label: 'Kabupaten Tuban', value: 'Kabupaten Tuban' },
  { label: 'Kabupaten Tulangbawang', value: 'Kabupaten Tulangbawang' },
  { label: 'Kabupaten Tulungagung', value: 'Kabupaten Tulungagung' },
  { label: 'Kabupaten Wajo', value: 'Kabupaten Wajo' },
  { label: 'Kabupaten Waropen', value: 'Kabupaten Waropen' },
  { label: 'Kabupaten Way Kanan', value: 'Kabupaten Way Kanan' },
  { label: 'Kabupaten Wonogiri', value: 'Kabupaten Wonogiri' },
  { label: 'Kabupaten Wonosobo', value: 'Kabupaten Wonosobo' },
  { label: 'Kabupaten Yahukimo', value: 'Kabupaten Yahukimo' },
  { label: 'Kabupaten Yalimo', value: 'Kabupaten Yalimo' },
  { label: 'Kabuynan', value: 'Kabuynan' },
  { label: 'Kabuynan', value: 'Kabuynan' },
  { label: 'Kabwe', value: 'Kabwe' },
  { label: 'Kabwum', value: 'Kabwum' },
  { label: 'Kacha', value: 'Kacha' },
  { label: 'Kachchh', value: 'Kachchh' },
  { label: 'Kachholi', value: 'Kachholi' },
  { label: 'Kachhwa', value: 'Kachhwa' },
  { label: 'Kachia', value: 'Kachia' },
  { label: 'Kachina Village', value: 'Kachina Village' },
  { label: 'Kachkanar', value: 'Kachkanar' },
  { label: 'Kachug', value: 'Kachug' },
  { label: 'Kachugskiy Rayon', value: 'Kachugskiy Rayon' },
  { label: 'Kachurwahi', value: 'Kachurwahi' },
  { label: 'Kačice', value: 'Kačice' },
  { label: 'Kačuni', value: 'Kačuni' },
  { label: 'Kaczory', value: 'Kaczory' },
  { label: 'Kaczyce', value: 'Kaczyce' },
  { label: 'Kadakkavoor', value: 'Kadakkavoor' },
  { label: 'Kadambur', value: 'Kadambur' },
  { label: 'Kadaň', value: 'Kadaň' },
  { label: 'Kadarkút', value: 'Kadarkút' },
  { label: 'Kadaura', value: 'Kadaura' },
  { label: 'Kadavu Province', value: 'Kadavu Province' },
  { label: 'Kadaya', value: 'Kadaya' },
  { label: 'Kadayanallur', value: 'Kadayanallur' },
  { label: 'Kadegaon', value: 'Kadegaon' },
  { label: 'Kadenbach', value: 'Kadenbach' },
  { label: 'Kadhan', value: 'Kadhan' },
  { label: 'Kadhimiya', value: 'Kadhimiya' },
  { label: 'Kadi', value: 'Kadi' },
  { label: 'Kadıköy', value: 'Kadıköy' },
  { label: 'Kadina', value: 'Kadina' },
  { label: 'Kadingilan', value: 'Kadingilan' },
  { label: 'Kadınhanı', value: 'Kadınhanı' },
  { label: 'Kadino', value: 'Kadino' },
  { label: 'Kadino', value: 'Kadino' },
  { label: 'Kadiogo Province', value: 'Kadiogo Province' },
  { label: 'Kadipur', value: 'Kadipur' },
  { label: 'Kadiri', value: 'Kadiri' },
  { label: 'Kadirli', value: 'Kadirli' },
  { label: 'Kadışehri', value: 'Kadışehri' },
  { label: 'Kadiyivka', value: 'Kadiyivka' },
  { label: 'Kadjebi', value: 'Kadjebi' },
  { label: 'Kadkan', value: 'Kadkan' },
  { label: 'Kadnikov', value: 'Kadnikov' },
  { label: 'Kadod', value: 'Kadod' },
  { label: 'Kadoelen', value: 'Kadoelen' },
  { label: 'Kadoka', value: 'Kadoka' },
  { label: 'Kadom', value: 'Kadom' },
  { label: 'Kadoma', value: 'Kadoma' },
  { label: 'Kadoma', value: 'Kadoma' },
  { label: 'Kadoma District', value: 'Kadoma District' },
  { label: 'Kadoma Shi', value: 'Kadoma Shi' },
  { label: 'Kadoshkino', value: 'Kadoshkino' },
  { label: 'Kadrina', value: 'Kadrina' },
  { label: 'Kadrina Vald', value: 'Kadrina Vald' },
  { label: 'Kadubivtsi', value: 'Kadubivtsi' },
  { label: 'Kadugannawa', value: 'Kadugannawa' },
  { label: 'Kadugli', value: 'Kadugli' },
  { label: 'Kaduna', value: 'Kaduna' },
  { label: 'Kadur', value: 'Kadur' },
  { label: 'Kadus', value: 'Kadus' },
  { label: 'Kaduwela', value: 'Kaduwela' },
  { label: 'Kaduy', value: 'Kaduy' },
  { label: 'Kaduyskiy Rayon', value: 'Kaduyskiy Rayon' },
  { label: 'Kadyy', value: 'Kadyy' },
  { label: 'Kadyyskiy Rayon', value: 'Kadyyskiy Rayon' },
  { label: 'Kadzhi-Say', value: 'Kadzhi-Say' },
  { label: 'Kadzidło', value: 'Kadzidło' },
  { label: 'Kaédi', value: 'Kaédi' },
  { label: 'Kaélé', value: 'Kaélé' },
  { label: 'Kaeng Khoi', value: 'Kaeng Khoi' },
  { label: 'Kaeng Khro', value: 'Kaeng Khro' },
  { label: 'Käerjeng', value: 'Käerjeng' },
  { label: 'Kaesŏng', value: 'Kaesŏng' },
  { label: 'Kafanchan', value: 'Kafanchan' },
  { label: 'Kafarati', value: 'Kafarati' },
  { label: 'Kaff Al-Jaa', value: 'Kaff Al-Jaa' },
  { label: 'Kaffrine', value: 'Kaffrine' },
  { label: 'Kafin Hausa', value: 'Kafin Hausa' },
  { label: 'Kafir Qala', value: 'Kafir Qala' },
  { label: 'Kåfjord', value: 'Kåfjord' },
  { label: 'Kafr Abīl', value: 'Kafr Abīl' },
  { label: 'Kafr Ad Dawwār', value: 'Kafr Ad Dawwār' },
  { label: 'Kafr Asad', value: 'Kafr Asad' },
  { label: 'Kafr Ash Shaykh', value: 'Kafr Ash Shaykh' },
  { label: 'Kafr Az Zayyāt', value: 'Kafr Az Zayyāt' },
  { label: 'Kafr Kammā', value: 'Kafr Kammā' },
  { label: 'Kafr Kannā', value: 'Kafr Kannā' },
  { label: 'Kafr Lāhā', value: 'Kafr Lāhā' },
  { label: 'Kafr Mandā', value: 'Kafr Mandā' },
  { label: 'Kafr Miṣr', value: 'Kafr Miṣr' },
  { label: 'Kafr Qāsim', value: 'Kafr Qāsim' },
  { label: 'Kafr Şaghīr', value: 'Kafr Şaghīr' },
  { label: 'Kafr Saqr', value: 'Kafr Saqr' },
  { label: 'Kafr Sawm', value: 'Kafr Sawm' },
  { label: 'Kafr Takhārīm', value: 'Kafr Takhārīm' },
  { label: 'Kafr Zaytā', value: 'Kafr Zaytā' },
  { label: 'Kafranbel', value: 'Kafranbel' },
  { label: 'Kafue', value: 'Kafue' },
  { label: 'Kafyr-Kumukh', value: 'Kafyr-Kumukh' },
  { label: 'Kaga Bandoro', value: 'Kaga Bandoro' },
  { label: 'Kaga Shi', value: 'Kaga Shi' },
  { label: 'Kaga-Bandoro', value: 'Kaga-Bandoro' },
  { label: 'Kagadi', value: 'Kagadi' },
  { label: 'Kagal', value: 'Kagal' },
  { label: 'Kagal’Nitskaya', value: 'Kagal’Nitskaya' },
  { label: 'Kagarol', value: 'Kagarol' },
  { label: 'Kagawasan', value: 'Kagawasan' },
  { label: 'Kagay', value: 'Kagay' },
  { label: 'Kagay', value: 'Kagay' },
  { label: 'Kagaznagar', value: 'Kagaznagar' },
  { label: 'Kåge', value: 'Kåge' },
  { label: 'Kågeröd', value: 'Kågeröd' },
  { label: 'Kâğıthane', value: 'Kâğıthane' },
  { label: 'Kağızman', value: 'Kağızman' },
  { label: 'Kagoro', value: 'Kagoro' },
  { label: 'Kagoshima', value: 'Kagoshima' },
  { label: 'Kagoshima Shi', value: 'Kagoshima Shi' },
  { label: 'Kagua Erave', value: 'Kagua Erave' },
  { label: 'Kahak', value: 'Kahak' },
  { label: 'Kahalu‘U', value: 'Kahalu‘U' },
  { label: 'Kahaluu-Keauhou', value: 'Kahaluu-Keauhou' },
  { label: 'Kahama', value: 'Kahama' },
  { label: 'Kahard', value: 'Kahard' },
  { label: 'Kaharlyk', value: 'Kaharlyk' },
  { label: 'Kaharlyk Raion', value: 'Kaharlyk Raion' },
  { label: 'Kahe Tanzania', value: 'Kahe Tanzania' },
  { label: 'Kahibah', value: 'Kahibah' },
  { label: 'Kahkadan', value: 'Kahkadan' },
  { label: 'Kahl Am Main', value: 'Kahl Am Main' },
  { label: 'Kahla', value: 'Kahla' },
  { label: 'Kahna Nau', value: 'Kahna Nau' },
  { label: 'Kahnooj', value: 'Kahnooj' },
  { label: 'Kahnuj', value: 'Kahnuj' },
  { label: 'Kahoka', value: 'Kahoka' },
  { label: 'Kahoku Shi', value: 'Kahoku Shi' },
  { label: 'Kahramankazan', value: 'Kahramankazan' },
  { label: 'Kahriz', value: 'Kahriz' },
  { label: 'Kahrizak', value: 'Kahrizak' },
  { label: 'Kahriz-E Baba Hoseyn', value: 'Kahriz-E Baba Hoseyn' },
  { label: 'Kahriz-E Boqazi', value: 'Kahriz-E Boqazi' },
  { label: 'Kahriz-E Hoseynabad-E Nazem', value: 'Kahriz-E Hoseynabad-E Nazem' },
  { label: 'Kahriz-E Jamal', value: 'Kahriz-E Jamal' },
  { label: 'Kahriz-E Salim', value: 'Kahriz-E Salim' },
  { label: 'Kahrizsang', value: 'Kahrizsang' },
  { label: 'Kâhta', value: 'Kâhta' },
  { label: 'Kahuku', value: 'Kahuku' },
  { label: 'Kahului', value: 'Kahului' },
  { label: 'Kahuta', value: 'Kahuta' },
  { label: 'Kaiaf', value: 'Kaiaf' },
  { label: 'Kaiama', value: 'Kaiama' },
  { label: 'Kaiapoi', value: 'Kaiapoi' },
  { label: 'Kaibing', value: 'Kaibing' },
  { label: 'Kaibito', value: 'Kaibito' },
  { label: 'Kaifeng', value: 'Kaifeng' },
  { label: 'Kaihua', value: 'Kaihua' },
  { label: 'Kaij', value: 'Kaij' },
  { label: 'Kaikalur', value: 'Kaikalur' },
  { label: 'Kailahun', value: 'Kailahun' },
  { label: 'Kailahun District', value: 'Kailahun District' },
  { label: 'Kailali ', value: 'Kailali ' },
  { label: 'Kailaras', value: 'Kailaras' },
  { label: 'Kailashahar', value: 'Kailashahar' },
  { label: 'Kailua', value: 'Kailua' },
  { label: 'Kailua-Kona', value: 'Kailua-Kona' },
  { label: 'Kaimganj', value: 'Kaimganj' },
  { label: 'Kaimori', value: 'Kaimori' },
  { label: 'Kaimur District', value: 'Kaimur District' },
  { label: 'Kainach Bei Voitsberg', value: 'Kainach Bei Voitsberg' },
  { label: 'Kainan', value: 'Kainan' },
  { label: 'Kainan Shi', value: 'Kainan Shi' },
  { label: 'Kainantu', value: 'Kainantu' },
  { label: 'Kainbach', value: 'Kainbach' },
  { label: 'Kaindorf', value: 'Kaindorf' },
  { label: 'Kaindorf An Der Sulm', value: 'Kaindorf An Der Sulm' },
  { label: 'Kaindy', value: 'Kaindy' },
  { label: 'Kainoúryion', value: 'Kainoúryion' },
  { label: 'Kainoúryion', value: 'Kainoúryion' },
  { label: 'Kaintragarh', value: 'Kaintragarh' },
  { label: 'Kaipara District', value: 'Kaipara District' },
  { label: 'Kairana', value: 'Kairana' },
  { label: 'Kairouan', value: 'Kairouan' },
  { label: 'Kairuku-Hiri', value: 'Kairuku-Hiri' },
  { label: 'Kaisarianí', value: 'Kaisarianí' },
  { label: 'Kaiseraugst', value: 'Kaiseraugst' },
  { label: 'Kaisersbach', value: 'Kaisersbach' },
  { label: 'Kaisersdorf', value: 'Kaisersdorf' },
  { label: 'Kaisersesch', value: 'Kaisersesch' },
  { label: 'Kaiserslautern', value: 'Kaiserslautern' },
  { label: 'Kaisheim', value: 'Kaisheim' },
  { label: 'Kai-Shi', value: 'Kai-Shi' },
  { label: 'Kaišiadorys', value: 'Kaišiadorys' },
  { label: 'Kaisten', value: 'Kaisten' },
  { label: 'Kaitaia', value: 'Kaitaia' },
  { label: 'Kaithal', value: 'Kaithal' },
  { label: 'Kaitong', value: 'Kaitong' },
  { label: 'Kaiyuan', value: 'Kaiyuan' },
  { label: 'Kaiyuan', value: 'Kaiyuan' },
  { label: 'Kaiyuan', value: 'Kaiyuan' },
  { label: 'Kaizuka', value: 'Kaizuka' },
  { label: 'Kaizuka Shi', value: 'Kaizuka Shi' },
  { label: 'Kaizu-Shi', value: 'Kaizu-Shi' },
  { label: 'Kaj', value: 'Kaj' },
  { label: 'Kajaani', value: 'Kajaani' },
  { label: 'Kajansi', value: 'Kajansi' },
  { label: 'Kajatian', value: 'Kajatian' },
  { label: 'Kajatian', value: 'Kajatian' },
  { label: 'Kajiado', value: 'Kajiado' },
  { label: 'Kajiki', value: 'Kajiki' },
  { label: 'Kájov', value: 'Kájov' },
  { label: 'Kajuru', value: 'Kajuru' },
  { label: 'Kaka', value: 'Kaka' },
  { label: 'Kakad Wari Dir Upper', value: 'Kakad Wari Dir Upper' },
  { label: 'Kakamega', value: 'Kakamega' },
  { label: 'Kakamigahara-Shi', value: 'Kakamigahara-Shi' },
  { label: 'Kakanj', value: 'Kakanj' },
  { label: 'Kakata', value: 'Kakata' },
  { label: 'Kakching', value: 'Kakching' },
  { label: 'Kakdwip', value: 'Kakdwip' },
  { label: 'Kakegawa', value: 'Kakegawa' },
  { label: 'Kakegawa Shi', value: 'Kakegawa Shi' },
  { label: 'Kakenstorf', value: 'Kakenstorf' },
  { label: 'Kakert', value: 'Kakert' },
  { label: 'Kakhk', value: 'Kakhk' },
  { label: 'Kakhovka', value: 'Kakhovka' },
  { label: 'Kakhun', value: 'Kakhun' },
  { label: 'Kaki', value: 'Kaki' },
  { label: 'Kakinada', value: 'Kakinada' },
  { label: 'Kakogawa Shi', value: 'Kakogawa Shi' },
  { label: 'Kakogawachō-Honmachi', value: 'Kakogawachō-Honmachi' },
  { label: 'Kakonko', value: 'Kakonko' },
  { label: 'Kakopetriá', value: 'Kakopetriá' },
  { label: 'Kakori', value: 'Kakori' },
  { label: 'Kakrala', value: 'Kakrala' },
  { label: 'Kakucs', value: 'Kakucs' },
  { label: 'Kakuda', value: 'Kakuda' },
  { label: 'Kakuda Shi', value: 'Kakuda Shi' },
  { label: 'Kakunodatemachi', value: 'Kakunodatemachi' },
  { label: 'Kál', value: 'Kál' },
  { label: 'Kal Geh-Ye Sardasht', value: 'Kal Geh-Ye Sardasht' },
  { label: 'Kal Kabud', value: 'Kal Kabud' },
  { label: 'Kalá Déndra', value: 'Kalá Déndra' },
  { label: 'Kalaat El-Andalous', value: 'Kalaat El-Andalous' },
  { label: 'Kalabagh', value: 'Kalabagh' },
  { label: 'Kalabo', value: 'Kalabo' },
  { label: 'Kalaburgi', value: 'Kalaburgi' },
  { label: 'Kalach', value: 'Kalach' },
  { label: 'Kalachinsk', value: 'Kalachinsk' },
  { label: 'Kalach-Na-Donu', value: 'Kalach-Na-Donu' },
  { label: 'Kaladhungi', value: 'Kaladhungi' },
  { label: 'Kalagarh Project Colony', value: 'Kalagarh Project Colony' },
  { label: 'Kalahandi', value: 'Kalahandi' },
  { label: 'Kalāheo', value: 'Kalāheo' },
  { label: 'Kalaikunda', value: 'Kalaikunda' },
  { label: 'Kalaisan', value: 'Kalaisan' },
  { label: 'Kalaisan', value: 'Kalaisan' },
  { label: 'Kalajoki', value: 'Kalajoki' },
  { label: 'Kalakkadu', value: 'Kalakkadu' },
  { label: 'Kalaleh', value: 'Kalaleh' },
  { label: 'Kalama', value: 'Kalama' },
  { label: 'Kalamangog', value: 'Kalamangog' },
  { label: 'Kalamangog', value: 'Kalamangog' },
  { label: 'Kalamansig', value: 'Kalamansig' },
  { label: 'Kalamansig', value: 'Kalamansig' },
  { label: 'Kalamare', value: 'Kalamare' },
  { label: 'Kalamariá', value: 'Kalamariá' },
  { label: 'Kalamassery', value: 'Kalamassery' },
  { label: 'Kalamata', value: 'Kalamata' },
  { label: 'Kalamazoo', value: 'Kalamazoo' },
  { label: 'Kalamazoo County', value: 'Kalamazoo County' },
  { label: 'Kalamb', value: 'Kalamb' },
  { label: 'Kalamb Osmanabad', value: 'Kalamb Osmanabad' },
  { label: 'Kalamboli', value: 'Kalamboli' },
  { label: 'Kalameh', value: 'Kalameh' },
  { label: 'Kalamiá', value: 'Kalamiá' },
  { label: 'Kalamnuri', value: 'Kalamnuri' },
  { label: 'Kálamos', value: 'Kálamos' },
  { label: 'Kalampáka', value: 'Kalampáka' },
  { label: 'Kalampáki', value: 'Kalampáki' },
  { label: 'Kalamunda', value: 'Kalamunda' },
  { label: 'Kalanaur', value: 'Kalanaur' },
  { label: 'Kalanaur', value: 'Kalanaur' },
  { label: 'Kalanchak', value: 'Kalanchak' },
  { label: 'Kalandagan', value: 'Kalandagan' },
  { label: 'Kalang', value: 'Kalang' },
  { label: 'Kalang', value: 'Kalang' },
  { label: 'Kalanganan', value: 'Kalanganan' },
  { label: 'Kalanguy', value: 'Kalanguy' },
  { label: 'Kalanwali', value: 'Kalanwali' },
  { label: 'Kalaoa', value: 'Kalaoa' },
  { label: 'Kalar Dasht', value: 'Kalar Dasht' },
  { label: 'Kalas', value: 'Kalas' },
  { label: 'Kalashnikovo', value: 'Kalashnikovo' },
  { label: 'Kalasin', value: 'Kalasin' },
  { label: 'Kalaswala', value: 'Kalaswala' },
  { label: 'Kalat', value: 'Kalat' },
  { label: 'Kalat', value: 'Kalat' },
  { label: 'Kalāt District', value: 'Kalāt District' },
  { label: 'Kalāte Khij', value: 'Kalāte Khij' },
  { label: 'Kalāteh', value: 'Kalāteh' },
  { label: 'Kalavad', value: 'Kalavad' },
  { label: 'Kalavai', value: 'Kalavai' },
  { label: 'Kalavoor', value: 'Kalavoor' },
  { label: 'Kalávryta', value: 'Kalávryta' },
  { label: 'Kalawao County', value: 'Kalawao County' },
  { label: 'Kalawit', value: 'Kalawit' },
  { label: 'Kalayaan', value: 'Kalayaan' },
  { label: 'Kalb Hesari', value: 'Kalb Hesari' },
  { label: 'Kalba', value: 'Kalba' },
  { label: 'Kalbar', value: 'Kalbar' },
  { label: 'Kalbarri', value: 'Kalbarri' },
  { label: 'Kalbay', value: 'Kalbay' },
  { label: 'Kalbay', value: 'Kalbay' },
  { label: 'Kalbay', value: 'Kalbay' },
  { label: 'Kalbe', value: 'Kalbe' },
  { label: 'Kalbugan', value: 'Kalbugan' },
  { label: 'Kalbugan', value: 'Kalbugan' },
  { label: 'Kalcheva', value: 'Kalcheva' },
  { label: 'Kalchreuth', value: 'Kalchreuth' },
  { label: 'Kale', value: 'Kale' },
  { label: 'Kale', value: 'Kale' },
  { label: 'Kalecik', value: 'Kalecik' },
  { label: 'Kaleen', value: 'Kaleen' },
  { label: 'Kalefeld', value: 'Kalefeld' },
  { label: 'Kalej', value: 'Kalej' },
  { label: 'Kaleke Mandi', value: 'Kaleke Mandi' },
  { label: 'Kalemie', value: 'Kalemie' },
  { label: 'Kalenderovci Donji', value: 'Kalenderovci Donji' },
  { label: 'Kalengwa', value: 'Kalengwa' },
  { label: 'Kalety', value: 'Kalety' },
  { label: 'Kalevala', value: 'Kalevala' },
  { label: 'Kaleybar', value: 'Kaleybar' },
  { label: 'Kalga', value: 'Kalga' },
  { label: 'Kalghatgi', value: 'Kalghatgi' },
  { label: 'Kalgoorlie', value: 'Kalgoorlie' },
  { label: 'Kalgoorlie/Boulder', value: 'Kalgoorlie/Boulder' },
  { label: 'Kali', value: 'Kali' },
  { label: 'Kalí', value: 'Kalí' },
  { label: 'Kalí Vrýsi', value: 'Kalí Vrýsi' },
  { label: 'Kali(Dk)', value: 'Kali(Dk)' },
  { label: 'Kaliaganj', value: 'Kaliaganj' },
  { label: 'Kalian', value: 'Kalian' },
  { label: 'Kalian', value: 'Kalian' },
  { label: 'Kalian', value: 'Kalian' },
  { label: 'Kalian', value: 'Kalian' },
  { label: 'Kalianget', value: 'Kalianget' },
  { label: 'Kalibo (Poblacion)', value: 'Kalibo (Poblacion)' },
  { label: 'Kalida', value: 'Kalida' },
  { label: 'Kalifornsky', value: 'Kalifornsky' },
  { label: 'Kaligutan', value: 'Kaligutan' },
  { label: 'Kaligutan', value: 'Kaligutan' },
  { label: 'Kaligutan', value: 'Kaligutan' },
  { label: 'Kalikapur', value: 'Kalikapur' },
  { label: 'Kalikino', value: 'Kalikino' },
  { label: 'Kalikot ', value: 'Kalikot ' },
  { label: 'Kalilabad', value: 'Kalilabad' },
  { label: 'Kalilangan', value: 'Kalilangan' },
  { label: 'Kaliling', value: 'Kaliling' },
  { label: 'Kaliliog', value: 'Kaliliog' },
  { label: 'Kalimna', value: 'Kalimna' },
  { label: 'Kalimpong', value: 'Kalimpong' },
  { label: 'Kalinagar', value: 'Kalinagar' },
  { label: 'Kalinga', value: 'Kalinga' },
  { label: 'Kalinga', value: 'Kalinga' },
  { label: 'Kalingalan Caluang', value: 'Kalingalan Caluang' },
  { label: 'Kalinin', value: 'Kalinin' },
  { label: 'Kalininaul', value: 'Kalininaul' },
  { label: 'Kaliningrad', value: 'Kaliningrad' },
  { label: 'Kalinino', value: 'Kalinino' },
  { label: 'Kalininsk', value: 'Kalininsk' },
  { label: 'Kalininskaya', value: 'Kalininskaya' },
  { label: 'Kalininskiy', value: 'Kalininskiy' },
  { label: 'Kalininskiy Rayon', value: 'Kalininskiy Rayon' },
  { label: 'Kalinkavichy', value: 'Kalinkavichy' },
  { label: 'Kalino', value: 'Kalino' },
  { label: 'Kalinovac', value: 'Kalinovac' },
  { label: 'Kalinovka', value: 'Kalinovka' },
  { label: 'Kalinovo', value: 'Kalinovo' },
  { label: 'Kalinovskaya', value: 'Kalinovskaya' },
  { label: 'Kalinovskoye', value: 'Kalinovskoye' },
  { label: 'Kaliska', value: 'Kaliska' },
  { label: 'Kalispell', value: 'Kalispell' },
  { label: 'Kalisz', value: 'Kalisz' },
  { label: 'Kalisz Pomorski', value: 'Kalisz Pomorski' },
  { label: 'Kaliua', value: 'Kaliua' },
  { label: 'Kalix', value: 'Kalix' },
  { label: 'Kalk', value: 'Kalk' },
  { label: 'Kalka', value: 'Kalka' },
  { label: 'Kalkaman', value: 'Kalkaman' },
  { label: 'Kalkandere', value: 'Kalkandere' },
  { label: 'Kalkar', value: 'Kalkar' },
  { label: 'Kalkara', value: 'Kalkara' },
  { label: 'Kalkaska', value: 'Kalkaska' },
  { label: 'Kalkaska County', value: 'Kalkaska County' },
  { label: 'Kalkhorst', value: 'Kalkhorst' },
  { label: 'Kalkie', value: 'Kalkie' },
  { label: 'Kall', value: 'Kall' },
  { label: 'Kallakkurichchi', value: 'Kallakkurichchi' },
  { label: 'Kallang', value: 'Kallang' },
  { label: 'Kallangur', value: 'Kallangur' },
  { label: 'Kallar Kahar', value: 'Kallar Kahar' },
  { label: 'Kallaroo', value: 'Kallaroo' },
  { label: 'Kallaste', value: 'Kallaste' },
  { label: 'Källby', value: 'Källby' },
  { label: 'Kalleh Bast', value: 'Kalleh Bast' },
  { label: 'Kållered', value: 'Kållered' },
  { label: 'Kallidaikurichi', value: 'Kallidaikurichi' },
  { label: 'Kallifóni', value: 'Kallifóni' },
  { label: 'Kallífytos', value: 'Kallífytos' },
  { label: 'Kallinge', value: 'Kallinge' },
  { label: 'Kallio', value: 'Kallio' },
  { label: 'Kallista', value: 'Kallista' },
  { label: 'Kallithéa', value: 'Kallithéa' },
  { label: 'Kallithéa', value: 'Kallithéa' },
  { label: 'Kallíthiro', value: 'Kallíthiro' },
  { label: 'Kallmünz', value: 'Kallmünz' },
  { label: 'Kallnach', value: 'Kallnach' },
  { label: 'Kállósemjén', value: 'Kállósemjén' },
  { label: 'Kallstadt', value: 'Kallstadt' },
  { label: 'Kallupatti', value: 'Kallupatti' },
  { label: 'Kálmánháza', value: 'Kálmánháza' },
  { label: 'Kalmanka', value: 'Kalmanka' },
  { label: 'Kalmanskiy Rayon', value: 'Kalmanskiy Rayon' },
  { label: 'Kalmar', value: 'Kalmar' },
  { label: 'Kalmeshwar', value: 'Kalmeshwar' },
  { label: 'Kalmthout', value: 'Kalmthout' },
  { label: 'Kalmunai', value: 'Kalmunai' },
  { label: 'Kalna', value: 'Kalna' },
  { label: 'Kalnibolotskaya', value: 'Kalnibolotskaya' },
  { label: 'Kalochóri', value: 'Kalochóri' },
  { label: 'Kalocsa', value: 'Kalocsa' },
  { label: 'Kalocsai Járás', value: 'Kalocsai Járás' },
  { label: 'Kalodzishchy', value: 'Kalodzishchy' },
  { label: 'Kalofer', value: 'Kalofer' },
  { label: 'Kalol', value: 'Kalol' },
  { label: 'Kalona', value: 'Kalona' },
  { label: 'Kalorama', value: 'Kalorama' },
  { label: 'Kaloré', value: 'Kaloré' },
  { label: 'Kalós Agrós', value: 'Kalós Agrós' },
  { label: 'Kaloyanovo', value: 'Kaloyanovo' },
  { label: 'Káloz', value: 'Káloz' },
  { label: 'Kalpáki', value: 'Kalpáki' },
  { label: 'Kalpetta', value: 'Kalpetta' },
  { label: 'Kalpi', value: 'Kalpi' },
  { label: 'Kalsdonk', value: 'Kalsdonk' },
  { label: 'Kalsdorf Bei Graz', value: 'Kalsdorf Bei Graz' },
  { label: 'Kaltan', value: 'Kaltan' },
  { label: 'Kaltasy', value: 'Kaltasy' },
  { label: 'Kaltay', value: 'Kaltay' },
  { label: 'Kaltbrunn', value: 'Kaltbrunn' },
  { label: 'Kaltenbach', value: 'Kaltenbach' },
  { label: 'Kaltenbach', value: 'Kaltenbach' },
  { label: 'Kaltenengers', value: 'Kaltenengers' },
  { label: 'Kaltenhouse', value: 'Kaltenhouse' },
  { label: 'Kaltenkirchen', value: 'Kaltenkirchen' },
  { label: 'Kaltenleutgeben', value: 'Kaltenleutgeben' },
  { label: 'Kaltennordheim', value: 'Kaltennordheim' },
  { label: 'Kaltenwestheim', value: 'Kaltenwestheim' },
  { label: 'Kaltungo', value: 'Kaltungo' },
  { label: 'Kaluga', value: 'Kaluga' },
  { label: 'Kalugmanan', value: 'Kalugmanan' },
  { label: 'Kalugumalai', value: 'Kalugumalai' },
  { label: 'Kalulushi', value: 'Kalulushi' },
  { label: 'Kalundborg', value: 'Kalundborg' },
  { label: 'Kalundborg Kommune', value: 'Kalundborg Kommune' },
  { label: 'Kalundri', value: 'Kalundri' },
  { label: 'Kalur Kot', value: 'Kalur Kot' },
  { label: 'Kalus’Kyy Rayon', value: 'Kalus’Kyy Rayon' },
  { label: 'Kalush', value: 'Kalush' },
  { label: 'Kałuszyn', value: 'Kałuszyn' },
  { label: 'Kalutara', value: 'Kalutara' },
  { label: 'Kalvarija', value: 'Kalvarija' },
  { label: 'Kalvarija Municipality', value: 'Kalvarija Municipality' },
  { label: 'Kalverdijk', value: 'Kalverdijk' },
  { label: 'Kälviä', value: 'Kälviä' },
  { label: 'Kalvola', value: 'Kalvola' },
  { label: 'Kalwang', value: 'Kalwang' },
  { label: 'Kalwaria Zebrzydowska', value: 'Kalwaria Zebrzydowska' },
  { label: 'Kalyan', value: 'Kalyan' },
  { label: 'Kalyandurg', value: 'Kalyandurg' },
  { label: 'Kalyani', value: 'Kalyani' },
  { label: 'Kalyani Nagar', value: 'Kalyani Nagar' },
  { label: 'Kalyanpur', value: 'Kalyanpur' },
  { label: 'Kalyazin', value: 'Kalyazin' },
  { label: 'Kalyazinskiy Rayon', value: 'Kalyazinskiy Rayon' },
  { label: 'Kálymnos', value: 'Kálymnos' },
  { label: 'Kalynivka', value: 'Kalynivka' },
  { label: 'Kalynove', value: 'Kalynove' },
  { label: 'Kalyny', value: 'Kalyny' },
  { label: 'Kalýves', value: 'Kalýves' },
  { label: 'Kalýves Polygýrou', value: 'Kalýves Polygýrou' },
  { label: 'Kalývia', value: 'Kalývia' },
  { label: 'Kalývia Thorikoú', value: 'Kalývia Thorikoú' },
  { label: 'Kama', value: 'Kama' },
  { label: 'Kama Shi', value: 'Kama Shi' },
  { label: 'Kamachumu', value: 'Kamachumu' },
  { label: 'Kamagaya Shi', value: 'Kamagaya Shi' },
  { label: 'Kamaishi', value: 'Kamaishi' },
  { label: 'Kamaishi-Shi', value: 'Kamaishi-Shi' },
  { label: 'Kamak-E Sofla', value: 'Kamak-E Sofla' },
  { label: 'Kamakhyanagar', value: 'Kamakhyanagar' },
  { label: 'Kamakura', value: 'Kamakura' },
  { label: 'Kamakura Shi', value: 'Kamakura Shi' },
  { label: 'Kamakwie', value: 'Kamakwie' },
  { label: 'Kamal', value: 'Kamal' },
  { label: 'Kamalapuram', value: 'Kamalapuram' },
  { label: 'Kamalasai', value: 'Kamalasai' },
  { label: 'Kamalganj', value: 'Kamalganj' },
  { label: 'Kamalia', value: 'Kamalia' },
  { label: 'Kamaliyah', value: 'Kamaliyah' },
  { label: 'Kamalpur', value: 'Kamalpur' },
  { label: 'Kamalshahr', value: 'Kamalshahr' },
  { label: 'Kaman', value: 'Kaman' },
  { label: 'Kaman', value: 'Kaman' },
  { label: 'Kamanga', value: 'Kamanga' },
  { label: 'Kamanga', value: 'Kamanga' },
  { label: 'Kamar Boneh', value: 'Kamar Boneh' },
  { label: 'Kamar Mushani', value: 'Kamar Mushani' },
  { label: 'Kamárai', value: 'Kamárai' },
  { label: 'Kamaran', value: 'Kamaran' },
  { label: 'Kamareddi', value: 'Kamareddi' },
  { label: 'Kamareddy', value: 'Kamareddy' },
  { label: 'Kamargaon', value: 'Kamargaon' },
  { label: 'Kamarhati', value: 'Kamarhati' },
  { label: 'Kamariótissa', value: 'Kamariótissa' },
  { label: 'Kamarpukur', value: 'Kamarpukur' },
  { label: 'Kamas', value: 'Kamas' },
  { label: 'Kamaterón', value: 'Kamaterón' },
  { label: 'Kamatgi', value: 'Kamatgi' },
  { label: 'Kamativi Mine', value: 'Kamativi Mine' },
  { label: 'Kamazan', value: 'Kamazan' },
  { label: 'Kamba', value: 'Kamba' },
  { label: 'Kambah', value: 'Kambah' },
  { label: 'Kambalda East', value: 'Kambalda East' },
  { label: 'Kambalda West', value: 'Kambalda West' },
  { label: 'Kambar', value: 'Kambar' },
  { label: 'Kambarka', value: 'Kambarka' },
  { label: 'Kambia', value: 'Kambia' },
  { label: 'Kambileyevskoye', value: 'Kambileyevskoye' },
  { label: 'Kambing', value: 'Kambing' },
  { label: 'Kambing', value: 'Kambing' },
  { label: 'Kambja Vald', value: 'Kambja Vald' },
  { label: 'Kambove', value: 'Kambove' },
  { label: 'Kameda-Honchō', value: 'Kameda-Honchō' },
  { label: 'Kamen', value: 'Kamen' },
  { label: 'Kamen', value: 'Kamen' },
  { label: 'Kamen’-Na-Obi', value: 'Kamen’-Na-Obi' },
  { label: 'Kamen’-Rybolov', value: 'Kamen’-Rybolov' },
  { label: 'Kaména Voúrla', value: 'Kaména Voúrla' },
  { label: 'Kamenetskiy', value: 'Kamenetskiy' },
  { label: 'Kamenica', value: 'Kamenica' },
  { label: 'Kamenice', value: 'Kamenice' },
  { label: 'Kamenice', value: 'Kamenice' },
  { label: 'Kamenice Nad Lipou', value: 'Kamenice Nad Lipou' },
  { label: 'Kamenický Šenov', value: 'Kamenický Šenov' },
  { label: 'Kamenjane', value: 'Kamenjane' },
  { label: 'Kamenka', value: 'Kamenka' },
  { label: 'Kamenka', value: 'Kamenka' },
  { label: 'Kamenka', value: 'Kamenka' },
  { label: 'Kamenka', value: 'Kamenka' },
  { label: 'Kamenka', value: 'Kamenka' },
  { label: 'Kamenka', value: 'Kamenka' },
  { label: 'Kamenné Žehrovice', value: 'Kamenné Žehrovice' },
  { label: 'Kamennogorsk', value: 'Kamennogorsk' },
  { label: 'Kamennomostskiy', value: 'Kamennomostskiy' },
  { label: 'Kamennomostskoye', value: 'Kamennomostskoye' },
  { label: 'Kamenný Přívoz', value: 'Kamenný Přívoz' },
  { label: 'Kamenný Újezd', value: 'Kamenný Újezd' },
  { label: 'Kameno', value: 'Kameno' },
  { label: 'Kamenolomni', value: 'Kamenolomni' },
  { label: 'Kamenolomnya', value: 'Kamenolomnya' },
  { label: 'Kamenskiy', value: 'Kamenskiy' },
  { label: 'Kamenskiy Rayon', value: 'Kamenskiy Rayon' },
  { label: 'Kamensk-Shakhtinskiy', value: 'Kamensk-Shakhtinskiy' },
  { label: 'Kamensk-Ural’Skiy', value: 'Kamensk-Ural’Skiy' },
  { label: 'Kamenz', value: 'Kamenz' },
  { label: 'Kameoka', value: 'Kameoka' },
  { label: 'Kameoka-Shi', value: 'Kameoka-Shi' },
  { label: 'Kamerunga', value: 'Kamerunga' },
  { label: 'Kameshkovo', value: 'Kameshkovo' },
  { label: 'Kamesznica', value: 'Kamesznica' },
  { label: 'Kameyama', value: 'Kameyama' },
  { label: 'Kameyama Shi', value: 'Kameyama Shi' },
  { label: 'Kamëz', value: 'Kamëz' },
  { label: 'Kamfiruz', value: 'Kamfiruz' },
  { label: 'Kamiah', value: 'Kamiah' },
  { label: 'Kamiamakusa Shi', value: 'Kamiamakusa Shi' },
  { label: 'Kamianets-Podilskyi', value: 'Kamianets-Podilskyi' },
  { label: 'Kamiani Potoky', value: 'Kamiani Potoky' },
  { label: 'Kamianka', value: 'Kamianka' },
  { label: 'Kamianske', value: 'Kamianske' },
  { label: 'Kamianytsia', value: 'Kamianytsia' },
  { label: 'Kamień', value: 'Kamień' },
  { label: 'Kamień', value: 'Kamień' },
  { label: 'Kamień Krajeński', value: 'Kamień Krajeński' },
  { label: 'Kamień Pomorski', value: 'Kamień Pomorski' },
  { label: 'Kamienica', value: 'Kamienica' },
  { label: 'Kamienica', value: 'Kamienica' },
  { label: 'Kamienica Polska', value: 'Kamienica Polska' },
  { label: 'Kamieniec', value: 'Kamieniec' },
  { label: 'Kamieniec Wrocławski', value: 'Kamieniec Wrocławski' },
  { label: 'Kamieniec Ząbkowicki', value: 'Kamieniec Ząbkowicki' },
  { label: 'Kamienna Góra', value: 'Kamienna Góra' },
  { label: 'Kamiennik', value: 'Kamiennik' },
  { label: 'Kamieńsk', value: 'Kamieńsk' },
  { label: 'Kamifukuoka', value: 'Kamifukuoka' },
  { label: 'Kamigyō-Ku', value: 'Kamigyō-Ku' },
  { label: 'Kamiichi', value: 'Kamiichi' },
  { label: 'Kamiiso', value: 'Kamiiso' },
  { label: 'Kamikawa', value: 'Kamikawa' },
  { label: 'Kamimaruko', value: 'Kamimaruko' },
  { label: 'Kamina', value: 'Kamina' },
  { label: 'Kamin-Kashyrskyi', value: 'Kamin-Kashyrskyi' },
  { label: 'Kaminokawa', value: 'Kaminokawa' },
  { label: 'Kaminoyama', value: 'Kaminoyama' },
  { label: 'Kaminoyama-Shi', value: 'Kaminoyama-Shi' },
  { label: 'Kaminskiy', value: 'Kaminskiy' },
  { label: 'Kamionka', value: 'Kamionka' },
  { label: 'Kamionka Wielka', value: 'Kamionka Wielka' },
  { label: 'Kamirenjaku', value: 'Kamirenjaku' },
  { label: 'Kamisu-Shi', value: 'Kamisu-Shi' },
  { label: 'Kamjong', value: 'Kamjong' },
  { label: 'Kamloops', value: 'Kamloops' },
  { label: 'Kamma', value: 'Kamma' },
  { label: 'Kammern Im Liesingtal', value: 'Kammern Im Liesingtal' },
  { label: 'Kammerstein', value: 'Kammerstein' },
  { label: 'Kamnica', value: 'Kamnica' },
  { label: 'Kamnik', value: 'Kamnik' },
  { label: 'Kamo', value: 'Kamo' },
  { label: 'Kamo', value: 'Kamo' },
  { label: 'Kamo Shi', value: 'Kamo Shi' },
  { label: 'Kamogatachō-Kamogata', value: 'Kamogatachō-Kamogata' },
  { label: 'Kamogawa', value: 'Kamogawa' },
  { label: 'Kamogawa-Shi', value: 'Kamogawa-Shi' },
  { label: 'Kamojimachō-Jōgejima', value: 'Kamojimachō-Jōgejima' },
  { label: 'Kamoke', value: 'Kamoke' },
  { label: 'Kampala', value: 'Kampala' },
  { label: 'Kampala District', value: 'Kampala District' },
  { label: 'Kampánis', value: 'Kampánis' },
  { label: 'Kampar', value: 'Kampar' },
  { label: 'Kamp-Bornhofen', value: 'Kamp-Bornhofen' },
  { label: 'Kampen', value: 'Kampen' },
  { label: 'Kampene', value: 'Kampene' },
  { label: 'Kampenhout', value: 'Kampenhout' },
  { label: 'Kamperland', value: 'Kamperland' },
  { label: 'Kamphaeng Phet', value: 'Kamphaeng Phet' },
  { label: 'Kamphaeng Saen District', value: 'Kamphaeng Saen District' },
  { label: 'Kampil', value: 'Kampil' },
  { label: 'Kampinos', value: 'Kampinos' },
  { label: 'Kampli', value: 'Kampli' },
  { label: 'Kamp-Lintfort', value: 'Kamp-Lintfort' },
  { label: 'Kampokpok', value: 'Kampokpok' },
  { label: 'Kampokpok', value: 'Kampokpok' },
  { label: 'Kampong Baharu Balakong', value: 'Kampong Baharu Balakong' },
  { label: 'Kampong Bay', value: 'Kampong Bay' },
  { label: 'Kampong Cham', value: 'Kampong Cham' },
  { label: 'Kampong Chhnang', value: 'Kampong Chhnang' },
  { label: 'Kampong Dungun', value: 'Kampong Dungun' },
  { label: 'Kampong Kadok', value: 'Kampong Kadok' },
  { label: 'Kampong Masjid Tanah', value: 'Kampong Masjid Tanah' },
  { label: 'Kampong Pangkal Kalong', value: 'Kampong Pangkal Kalong' },
  { label: 'Kampong Speu', value: 'Kampong Speu' },
  { label: 'Kampong Tranch', value: 'Kampong Tranch' },
  { label: 'Kampor', value: 'Kampor' },
  { label: 'Kampot', value: 'Kampot' },
  { label: 'Kamptee', value: 'Kamptee' },
  { label: 'Kampung Ayer Keroh', value: 'Kampung Ayer Keroh' },
  { label: 'Kampung Ayer Molek', value: 'Kampung Ayer Molek' },
  { label: 'Kampung Baharu Nilai', value: 'Kampung Baharu Nilai' },
  { label: 'Kampung Baru Subang', value: 'Kampung Baru Subang' },
  { label: 'Kampung Batu Feringgi', value: 'Kampung Batu Feringgi' },
  { label: 'Kampung Bukit Baharu', value: 'Kampung Bukit Baharu' },
  { label: 'Kampung Bukit Tinggi Bentong', value: 'Kampung Bukit Tinggi Bentong' },
  { label: 'Kampung Kilim', value: 'Kampung Kilim' },
  { label: 'Kampung Kok', value: 'Kampung Kok' },
  { label: 'Kampung Kuala Besut', value: 'Kampung Kuala Besut' },
  { label: 'Kampung Pasir Gudang Baru', value: 'Kampung Pasir Gudang Baru' },
  { label: 'Kampung Simpang Renggam', value: 'Kampung Simpang Renggam' },
  { label: 'Kampung Sungai Ara', value: 'Kampung Sungai Ara' },
  { label: 'Kampung Tanjung Karang', value: 'Kampung Tanjung Karang' },
  { label: 'Kampung Tekek', value: 'Kampung Tekek' },
  { label: 'Kamra', value: 'Kamra' },
  { label: 'Kamrup', value: 'Kamrup' },
  { label: 'Kamrup Metropolitan', value: 'Kamrup Metropolitan' },
  { label: 'Kamsack', value: 'Kamsack' },
  { label: 'Kamsdorf', value: 'Kamsdorf' },
  { label: 'Kamsko-Ust’Inskiy Rayon', value: 'Kamsko-Ust’Inskiy Rayon' },
  { label: 'Kamuli', value: 'Kamuli' },
  { label: 'Kamuthi', value: 'Kamuthi' },
  { label: 'Kamwenge', value: 'Kamwenge' },
  { label: 'Kamyanyets', value: 'Kamyanyets' },
  { label: 'Kamyanyetski Rayon', value: 'Kamyanyetski Rayon' },
  { label: 'Kamyanyuki', value: 'Kamyanyuki' },
  { label: 'Kamyaran', value: 'Kamyaran' },
  { label: 'Kamyennyya Lavy', value: 'Kamyennyya Lavy' },
  { label: 'Kamyk', value: 'Kamyk' },
  { label: 'Kamyshevatskaya', value: 'Kamyshevatskaya' },
  { label: 'Kamyshevka Vtoraya', value: 'Kamyshevka Vtoraya' },
  { label: 'Kamyshin', value: 'Kamyshin' },
  { label: 'Kamyshla', value: 'Kamyshla' },
  { label: 'Kamyshlov', value: 'Kamyshlov' },
  { label: 'Kamyzyak', value: 'Kamyzyak' },
  { label: 'Kan’Onji Shi', value: 'Kan’Onji Shi' },
  { label: 'Kan’Onjichō', value: 'Kan’Onjichō' },
  { label: 'Kanab', value: 'Kanab' },
  { label: 'Kanabec County', value: 'Kanabec County' },
  { label: 'Kanadey', value: 'Kanadey' },
  { label: 'Kanadukattan', value: 'Kanadukattan' },
  { label: 'Kanahooka', value: 'Kanahooka' },
  { label: 'Kanal', value: 'Kanal' },
  { label: 'Kanaláki', value: 'Kanaláki' },
  { label: 'Kanáli', value: 'Kanáli' },
  { label: 'Kanália', value: 'Kanália' },
  { label: 'Kananga', value: 'Kananga' },
  { label: 'Kananya', value: 'Kananya' },
  { label: 'Kanash', value: 'Kanash' },
  { label: 'Kanashevo', value: 'Kanashevo' },
  { label: 'Kanashskiy Rayon', value: 'Kanashskiy Rayon' },
  { label: 'Kanasín', value: 'Kanasín' },
  { label: 'Kanata', value: 'Kanata' },
  { label: 'Kanatlarci', value: 'Kanatlarci' },
  { label: 'Kanawha County', value: 'Kanawha County' },
  { label: 'Kanaya', value: 'Kanaya' },
  { label: 'Kanayevka', value: 'Kanayevka' },
  { label: 'Kanazawa', value: 'Kanazawa' },
  { label: 'Kanazawa-Shi', value: 'Kanazawa-Shi' },
  { label: 'Kanbe', value: 'Kanbe' },
  { label: 'Kancab', value: 'Kancab' },
  { label: 'Kancabchén', value: 'Kancabchén' },
  { label: 'Kanchanaburi', value: 'Kanchanaburi' },
  { label: 'Kanchanadit', value: 'Kanchanadit' },
  { label: 'Kanchanpur ', value: 'Kanchanpur ' },
  { label: 'Kancheepuram', value: 'Kancheepuram' },
  { label: 'Kanchipuram', value: 'Kanchipuram' },
  { label: 'Kanchrapara', value: 'Kanchrapara' },
  { label: 'Kańczuga', value: 'Kańczuga' },
  { label: 'Kand Halan', value: 'Kand Halan' },
  { label: 'Kand Tappeh', value: 'Kand Tappeh' },
  { label: 'Kanda', value: 'Kanda' },
  { label: 'Kandabong', value: 'Kandabong' },
  { label: 'Kandahār', value: 'Kandahār' },
  { label: 'Kandalaksha', value: 'Kandalaksha' },
  { label: 'Kandalakshskiy Rayon', value: 'Kandalakshskiy Rayon' },
  { label: 'Kandana', value: 'Kandana' },
  { label: 'Kandava', value: 'Kandava' },
  { label: 'Kandé', value: 'Kandé' },
  { label: 'Kand-E Bolaghi', value: 'Kand-E Bolaghi' },
  { label: 'Kandel', value: 'Kandel' },
  { label: 'Kandep', value: 'Kandep' },
  { label: 'Kandern', value: 'Kandern' },
  { label: 'Kandersteg', value: 'Kandersteg' },
  { label: 'Kandete', value: 'Kandete' },
  { label: 'Kandhamal', value: 'Kandhamal' },
  { label: 'Kandhkot', value: 'Kandhkot' },
  { label: 'Kandhla', value: 'Kandhla' },
  { label: 'Kandi', value: 'Kandi' },
  { label: 'Kandi', value: 'Kandi' },
  { label: 'Kandiari', value: 'Kandiari' },
  { label: 'Kandiaro', value: 'Kandiaro' },
  { label: 'Kandíla', value: 'Kandíla' },
  { label: 'Kandıra', value: 'Kandıra' },
  { label: 'Kandiyohi County', value: 'Kandiyohi County' },
  { label: 'Kandla', value: 'Kandla' },
  { label: 'Kandla Port', value: 'Kandla Port' },
  { label: 'Kandos', value: 'Kandos' },
  { label: 'Kandra', value: 'Kandra' },
  { label: 'Kandri', value: 'Kandri' },
  { label: 'Kandrian', value: 'Kandrian' },
  { label: 'Kandrian Gloucester', value: 'Kandrian Gloucester' },
  { label: 'Kandry', value: 'Kandry' },
  { label: 'Kandukur', value: 'Kandukur' },
  { label: 'Kandy', value: 'Kandy' },
  { label: 'Kandy District', value: 'Kandy District' },
  { label: 'Kandy Four Gravets', value: 'Kandy Four Gravets' },
  { label: 'Kandyagash', value: 'Kandyagash' },
  { label: 'Kane', value: 'Kane' },
  { label: 'Kane County', value: 'Kane County' },
  { label: 'Kane County', value: 'Kane County' },
  { label: 'Kanekomachi', value: 'Kanekomachi' },
  { label: 'Kanel', value: 'Kanel' },
  { label: 'Kanelovskaya', value: 'Kanelovskaya' },
  { label: 'Kaneohe', value: 'Kaneohe' },
  { label: 'Kanepi', value: 'Kanepi' },
  { label: 'Kanepi Vald', value: 'Kanepi Vald' },
  { label: 'Kanevskaya', value: 'Kanevskaya' },
  { label: 'Kanfanar', value: 'Kanfanar' },
  { label: 'Kang', value: 'Kang' },
  { label: 'Kangaba', value: 'Kangaba' },
  { label: 'Kangal', value: 'Kangal' },
  { label: 'Kangalassy', value: 'Kangalassy' },
  { label: 'Kangan', value: 'Kangan' },
  { label: 'Kangani', value: 'Kangani' },
  { label: 'Kanganpur', value: 'Kanganpur' },
  { label: 'Kangar', value: 'Kangar' },
  { label: 'Kangar', value: 'Kangar' },
  { label: 'Kangaroo Flat', value: 'Kangaroo Flat' },
  { label: 'Kangaroo Ground', value: 'Kangaroo Ground' },
  { label: 'Kangaroo Island', value: 'Kangaroo Island' },
  { label: 'Kangaroo Point', value: 'Kangaroo Point' },
  { label: 'Kangasala', value: 'Kangasala' },
  { label: 'Kangasniemi', value: 'Kangasniemi' },
  { label: 'Kangavar', value: 'Kangavar' },
  { label: 'Kangavar-E Kohneh', value: 'Kangavar-E Kohneh' },
  { label: 'Kangayam', value: 'Kangayam' },
  { label: 'Kangding', value: 'Kangding' },
  { label: 'Kangdong-Ŭp', value: 'Kangdong-Ŭp' },
  { label: 'Kangema', value: 'Kangema' },
  { label: 'Kanggye', value: 'Kanggye' },
  { label: 'Kangiwa', value: 'Kangiwa' },
  { label: 'Kangly', value: 'Kangly' },
  { label: 'Kangpokpi', value: 'Kangpokpi' },
  { label: 'Kangra', value: 'Kangra' },
  { label: 'Kangundo', value: 'Kangundo' },
  { label: 'Kanhangad', value: 'Kanhangad' },
  { label: 'Kani Dinar', value: 'Kani Dinar' },
  { label: 'Kani Sur', value: 'Kani Sur' },
  { label: 'Kanie', value: 'Kanie' },
  { label: 'Kanifay Municipality', value: 'Kanifay Municipality' },
  { label: 'Kanigiri', value: 'Kanigiri' },
  { label: 'Kanimbla', value: 'Kanimbla' },
  { label: 'Kanina Khas', value: 'Kanina Khas' },
  { label: 'Kaniów', value: 'Kaniów' },
  { label: 'Kaniów', value: 'Kaniów' },
  { label: 'Kani-Shi', value: 'Kani-Shi' },
  { label: 'Kaniv', value: 'Kaniv' },
  { label: 'Kanjiža', value: 'Kanjiža' },
  { label: 'Kankaanpää', value: 'Kankaanpää' },
  { label: 'Kankakee', value: 'Kankakee' },
  { label: 'Kankakee County', value: 'Kankakee County' },
  { label: 'Kankan', value: 'Kankan' },
  { label: 'Kankan Prefecture', value: 'Kankan Prefecture' },
  { label: 'Kankanhalli', value: 'Kankanhalli' },
  { label: 'Kankara', value: 'Kankara' },
  { label: 'Kankauli', value: 'Kankauli' },
  { label: 'Kankavli', value: 'Kankavli' },
  { label: 'Kanke', value: 'Kanke' },
  { label: 'Kanker', value: 'Kanker' },
  { label: 'Kankintú', value: 'Kankintú' },
  { label: 'Kankipadu', value: 'Kankipadu' },
  { label: 'Kankon', value: 'Kankon' },
  { label: 'Kanlagay', value: 'Kanlagay' },
  { label: 'Kanlagay', value: 'Kanlagay' },
  { label: 'Kanluran', value: 'Kanluran' },
  { label: 'Kannabechō-Yahiro', value: 'Kannabechō-Yahiro' },
  { label: 'Kannad', value: 'Kannad' },
  { label: 'Kannapolis', value: 'Kannapolis' },
  { label: 'Kannauj', value: 'Kannauj' },
  { label: 'Kannavam', value: 'Kannavam' },
  { label: 'Kannawurf', value: 'Kannawurf' },
  { label: 'Kanniyakumari', value: 'Kanniyakumari' },
  { label: 'Kannod', value: 'Kannod' },
  { label: 'Kannonkoski', value: 'Kannonkoski' },
  { label: 'Kannur', value: 'Kannur' },
  { label: 'Kannus', value: 'Kannus' },
  { label: 'Kano', value: 'Kano' },
  { label: 'Kanodar', value: 'Kanodar' },
  { label: 'Kanoni', value: 'Kanoni' },
  { label: 'Kanor', value: 'Kanor' },
  { label: 'Kanoya', value: 'Kanoya' },
  { label: 'Kanoya Shi', value: 'Kanoya Shi' },
  { label: 'Kanpur', value: 'Kanpur' },
  { label: 'Kanpur Dehat', value: 'Kanpur Dehat' },
  { label: 'Kansanshi', value: 'Kansanshi' },
  { label: 'Kansas City', value: 'Kansas City' },
  { label: 'Kansas City', value: 'Kansas City' },
  { label: 'Kansipati', value: 'Kansipati' },
  { label: 'Kansipati', value: 'Kansipati' },
  { label: 'Kansk', value: 'Kansk' },
  { label: 'Kanskiy Rayon', value: 'Kanskiy Rayon' },
  { label: 'Kant', value: 'Kant' },
  { label: 'Kant', value: 'Kant' },
  { label: 'Kantabanji', value: 'Kantabanji' },
  { label: 'Kantagi', value: 'Kantagi' },
  { label: 'Kantang', value: 'Kantang' },
  { label: 'Kantemirovka', value: 'Kantemirovka' },
  { label: 'Kanth', value: 'Kanth' },
  { label: 'Kantharalak', value: 'Kantharalak' },
  { label: 'Kantilo', value: 'Kantilo' },
  { label: 'Kanton', value: 'Kanton' },
  { label: 'Kanton Sarajevo', value: 'Kanton Sarajevo' },
  { label: 'Kantora', value: 'Kantora' },
  { label: 'Kántorjánosi', value: 'Kántorjánosi' },
  { label: 'Kantunil', value: 'Kantunil' },
  { label: 'Kantunilkín', value: 'Kantunilkín' },
  { label: 'Kanturk', value: 'Kanturk' },
  { label: 'Kantyshevo', value: 'Kantyshevo' },
  { label: 'Kanuma', value: 'Kanuma' },
  { label: 'Kanuma-Shi', value: 'Kanuma-Shi' },
  { label: 'Kanungu', value: 'Kanungu' },
  { label: 'Kanuru', value: 'Kanuru' },
  { label: 'Kanwal', value: 'Kanwal' },
  { label: 'Kanxoc', value: 'Kanxoc' },
  { label: 'Kanye', value: 'Kanye' },
  { label: 'Kanzaki Shi', value: 'Kanzaki Shi' },
  { label: 'Kao Liao', value: 'Kao Liao' },
  { label: 'Kaohsiung', value: 'Kaohsiung' },
  { label: 'Kaolack', value: 'Kaolack' },
  { label: 'Kaoma', value: 'Kaoma' },
  { label: 'Kaongkod', value: 'Kaongkod' },
  { label: 'Kapa‘A', value: 'Kapa‘A' },
  { label: 'Kapaau', value: 'Kapaau' },
  { label: 'Kapadvanj', value: 'Kapadvanj' },
  { label: 'Kapai', value: 'Kapai' },
  { label: 'Kapaklı', value: 'Kapaklı' },
  { label: 'Kapalong', value: 'Kapalong' },
  { label: 'Kapalong', value: 'Kapalong' },
  { label: 'Kapalong', value: 'Kapalong' },
  { label: 'Kapan', value: 'Kapan' },
  { label: 'Kapandríti', value: 'Kapandríti' },
  { label: 'Kapangan', value: 'Kapangan' },
  { label: 'Kapangan', value: 'Kapangan' },
  { label: 'Kaparéllion', value: 'Kaparéllion' },
  { label: 'Kapatagan', value: 'Kapatagan' },
  { label: 'Kapatalan', value: 'Kapatalan' },
  { label: 'Kapatan', value: 'Kapatan' },
  { label: 'Kapatan', value: 'Kapatan' },
  { label: 'Kapaya', value: 'Kapaya' },
  { label: 'Kapaya', value: 'Kapaya' },
  { label: 'Kapchorwa', value: 'Kapchorwa' },
  { label: 'Kapel', value: 'Kapel' },
  { label: 'Kapela', value: 'Kapela' },
  { label: 'Kapelle', value: 'Kapelle' },
  { label: 'Kapellen', value: 'Kapellen' },
  { label: 'Kapellen', value: 'Kapellen' },
  { label: 'Kapelle-Op-Den-Bos', value: 'Kapelle-Op-Den-Bos' },
  { label: 'Kapelln', value: 'Kapelln' },
  { label: 'Kapenguria', value: 'Kapenguria' },
  { label: 'Kapfenberg', value: 'Kapfenberg' },
  { label: 'Kapfenstein', value: 'Kapfenstein' },
  { label: 'Kapfing', value: 'Kapfing' },
  { label: 'Kapilvastu ', value: 'Kapilvastu ' },
  { label: 'Kapingkong', value: 'Kapingkong' },
  { label: 'Kapinkong', value: 'Kapinkong' },
  { label: 'Kapiri Mposhi', value: 'Kapiri Mposhi' },
  { label: 'Kapit', value: 'Kapit' },
  { label: 'Kapitanivka', value: 'Kapitanivka' },
  { label: 'Kapiti Coast District', value: 'Kapiti Coast District' },
  { label: 'Kaplan', value: 'Kaplan' },
  { label: 'Kaplice', value: 'Kaplice' },
  { label: 'Kapok', value: 'Kapok' },
  { label: 'Kapolei', value: 'Kapolei' },
  { label: 'Kápolnásnyék', value: 'Kápolnásnyék' },
  { label: 'Kapooka', value: 'Kapooka' },
  { label: 'Kaposmérő', value: 'Kaposmérő' },
  { label: 'Kaposvár', value: 'Kaposvár' },
  { label: 'Kaposvári Járás', value: 'Kaposvári Járás' },
  { label: 'Kapotnya', value: 'Kapotnya' },
  { label: 'Kappel Am Krappfeld', value: 'Kappel Am Krappfeld' },
  { label: 'Kappelen', value: 'Kappelen' },
  { label: 'Kappel-Grafenhausen', value: 'Kappel-Grafenhausen' },
  { label: 'Kappeln', value: 'Kappeln' },
  { label: 'Kappelrodeck', value: 'Kappelrodeck' },
  { label: 'Kappl', value: 'Kappl' },
  { label: 'Kapren', value: 'Kapren' },
  { label: 'Kaprijke', value: 'Kaprijke' },
  { label: 'Kaprun', value: 'Kaprun' },
  { label: 'Kapsabet', value: 'Kapsabet' },
  { label: 'Kapsan-Ŭp', value: 'Kapsan-Ŭp' },
  { label: 'Kapshagay', value: 'Kapshagay' },
  { label: 'Kapsowar', value: 'Kapsowar' },
  { label: 'Kapsweyer', value: 'Kapsweyer' },
  { label: 'Kaptanganj', value: 'Kaptanganj' },
  { label: 'Kaptol', value: 'Kaptol' },
  { label: 'Kapuas Hulu', value: 'Kapuas Hulu' },
  { label: 'Kapunda', value: 'Kapunda' },
  { label: 'Kapurthala', value: 'Kapurthala' },
  { label: 'Kapuskasing', value: 'Kapuskasing' },
  { label: 'Kapustin Yar', value: 'Kapustin Yar' },
  { label: 'Kaputa', value: 'Kaputa' },
  { label: 'Kaputan', value: 'Kaputan' },
  { label: 'Kapuvár', value: 'Kapuvár' },
  { label: 'Kapuvári Járás', value: 'Kapuvári Járás' },
  { label: 'Kapyl’', value: 'Kapyl’' },
  { label: 'Kapyl’Ski Rayon', value: 'Kapyl’Ski Rayon' },
  { label: 'Kara', value: 'Kara' },
  { label: 'Kara', value: 'Kara' },
  { label: 'Kara Kulja', value: 'Kara Kulja' },
  { label: 'Kara Suu', value: 'Kara Suu' },
  { label: 'Karabağlar', value: 'Karabağlar' },
  { label: 'Kara-Balta', value: 'Kara-Balta' },
  { label: 'Karabanovo', value: 'Karabanovo' },
  { label: 'Karabar', value: 'Karabar' },
  { label: 'Karabash', value: 'Karabash' },
  { label: 'Karabash', value: 'Karabash' },
  { label: 'Karabudakhkent', value: 'Karabudakhkent' },
  { label: 'Karabudakhkentskiy Rayon', value: 'Karabudakhkentskiy Rayon' },
  { label: 'Karabulak', value: 'Karabulak' },
  { label: 'Karaburun', value: 'Karaburun' },
  { label: 'Kara-Buurinskiy Rayon', value: 'Kara-Buurinskiy Rayon' },
  { label: 'Karacabey', value: 'Karacabey' },
  { label: 'Karacasu', value: 'Karacasu' },
  { label: 'Karachayevsk', value: 'Karachayevsk' },
  { label: 'Karachayevskiy Rayon', value: 'Karachayevskiy Rayon' },
  { label: 'Karachev', value: 'Karachev' },
  { label: 'Karachi', value: 'Karachi' },
  { label: 'Karaçoban', value: 'Karaçoban' },
  { label: 'Karácsond', value: 'Karácsond' },
  { label: 'Karad', value: 'Karad' },
  { label: 'Karád', value: 'Karád' },
  { label: 'Karada', value: 'Karada' },
  { label: 'Karadaglije', value: 'Karadaglije' },
  { label: 'Karafs', value: 'Karafs' },
  { label: 'Karagach', value: 'Karagach' },
  { label: 'Karagandy', value: 'Karagandy' },
  { label: 'Karagay', value: 'Karagay' },
  { label: 'Karagayla', value: 'Karagayla' },
  { label: 'Karaginsky District', value: 'Karaginsky District' },
  { label: 'Karahallı', value: 'Karahallı' },
  { label: 'Karaidel’', value: 'Karaidel’' },
  { label: 'Karaikal', value: 'Karaikal' },
  { label: 'Karaikkudi', value: 'Karaikkudi' },
  { label: 'Karaisalı', value: 'Karaisalı' },
  { label: 'Karaj', value: 'Karaj' },
  { label: 'Karajagi', value: 'Karajagi' },
  { label: 'Karak', value: 'Karak' },
  { label: 'Karak City', value: 'Karak City' },
  { label: 'Karakeçili', value: 'Karakeçili' },
  { label: 'Karakenja', value: 'Karakenja' },
  { label: 'Karakoçan', value: 'Karakoçan' },
  { label: 'Karakol', value: 'Karakol' },
  { label: 'Karaköprü', value: 'Karaköprü' },
  { label: 'Karakoyunlu', value: 'Karakoyunlu' },
  { label: 'Karakul’', value: 'Karakul’' },
  { label: 'Karakulino', value: 'Karakulino' },
  { label: 'Karakulinskiy Rayon', value: 'Karakulinskiy Rayon' },
  { label: 'Karalat', value: 'Karalat' },
  { label: 'Karalee', value: 'Karalee' },
  { label: 'Karama', value: 'Karama' },
  { label: 'Karamadai', value: 'Karamadai' },
  { label: 'Karamakhi', value: 'Karamakhi' },
  { label: 'Karamanlı', value: 'Karamanlı' },
  { label: 'Karamay', value: 'Karamay' },
  { label: 'Karamay Shi', value: 'Karamay Shi' },
  { label: 'Karambakkudi', value: 'Karambakkudi' },
  { label: 'Karamsad', value: 'Karamsad' },
  { label: 'Karamürsel', value: 'Karamürsel' },
  { label: 'Karana Downs', value: 'Karana Downs' },
  { label: 'Karanac', value: 'Karanac' },
  { label: 'Karancskeszi', value: 'Karancskeszi' },
  { label: 'Karancslapujtő', value: 'Karancslapujtő' },
  { label: 'Karangampel', value: 'Karangampel' },
  { label: 'Karanganom', value: 'Karanganom' },
  { label: 'Karangsembung', value: 'Karangsembung' },
  { label: 'Karanja', value: 'Karanja' },
  { label: 'Karanja Lad', value: 'Karanja Lad' },
  { label: 'Karanlukh', value: 'Karanlukh' },
  { label: 'Karanpur', value: 'Karanpur' },
  { label: 'Karantaba', value: 'Karantaba' },
  { label: 'Karantaba', value: 'Karantaba' },
  { label: 'Karanyowka', value: 'Karanyowka' },
  { label: 'Karapınar', value: 'Karapınar' },
  { label: 'Karapürçek', value: 'Karapürçek' },
  { label: 'Karapyshi', value: 'Karapyshi' },
  { label: 'Karari', value: 'Karari' },
  { label: 'Karasburg', value: 'Karasburg' },
  { label: 'Karasf', value: 'Karasf' },
  { label: 'Kárášjohka', value: 'Kárášjohka' },
  { label: 'Karasjok', value: 'Karasjok' },
  { label: 'Karasu', value: 'Karasu' },
  { label: 'Karasu', value: 'Karasu' },
  { label: 'Karasuk', value: 'Karasuk' },
  { label: 'Karasuyama', value: 'Karasuyama' },
  { label: 'Karata', value: 'Karata' },
  { label: 'Karataş', value: 'Karataş' },
  { label: 'Karatau', value: 'Karatau' },
  { label: 'Karatay', value: 'Karatay' },
  { label: 'Karátoula', value: 'Karátoula' },
  { label: 'Karatsu', value: 'Karatsu' },
  { label: 'Karatsu Shi', value: 'Karatsu Shi' },
  { label: 'Karatuzskoye', value: 'Karatuzskoye' },
  { label: 'Kara-Tyube', value: 'Kara-Tyube' },
  { label: 'Karauli', value: 'Karauli' },
  { label: 'Karaundi', value: 'Karaundi' },
  { label: 'Karavan', value: 'Karavan' },
  { label: 'Karawara', value: 'Karawara' },
  { label: 'Karayazı', value: 'Karayazı' },
  { label: 'Karbach', value: 'Karbach' },
  { label: 'Karbala', value: 'Karbala' },
  { label: 'Karben', value: 'Karben' },
  { label: 'Karbi', value: 'Karbi' },
  { label: 'Karbi Anglong', value: 'Karbi Anglong' },
  { label: 'Karbigwan', value: 'Karbigwan' },
  { label: 'Karbinci', value: 'Karbinci' },
  { label: 'Karcag', value: 'Karcag' },
  { label: 'Karcagi Járás', value: 'Karcagi Járás' },
  { label: 'Karchaghbyur', value: 'Karchaghbyur' },
  { label: 'Karchan', value: 'Karchan' },
  { label: 'Karchana', value: 'Karchana' },
  { label: 'Karcsa', value: 'Karcsa' },
  { label: 'Karczew', value: 'Karczew' },
  { label: 'Karczmiska', value: 'Karczmiska' },
  { label: 'Kardailovo', value: 'Kardailovo' },
  { label: 'Kardámaina', value: 'Kardámaina' },
  { label: 'Kardamás', value: 'Kardamás' },
  { label: 'Kardamítsia', value: 'Kardamítsia' },
  { label: 'Kardamýli', value: 'Kardamýli' },
  { label: 'Kardashivka', value: 'Kardashivka' },
  { label: 'Kardašova Řečice', value: 'Kardašova Řečice' },
  { label: 'Kardiá', value: 'Kardiá' },
  { label: 'Kardinya', value: 'Kardinya' },
  { label: 'Kardítsa', value: 'Kardítsa' },
  { label: 'Karditsomagoúla', value: 'Karditsomagoúla' },
  { label: 'Kärdla', value: 'Kärdla' },
  { label: 'Kardonikskaya', value: 'Kardonikskaya' },
  { label: 'Kardymovo', value: 'Kardymovo' },
  { label: 'Kardzhali', value: 'Kardzhali' },
  { label: 'Kardzhin', value: 'Kardzhin' },
  { label: 'Kareela', value: 'Kareela' },
  { label: 'Kareli', value: 'Kareli' },
  { label: 'Karelichy', value: 'Karelichy' },
  { label: 'Karelitski Rayon', value: 'Karelitski Rayon' },
  { label: 'Karellás', value: 'Karellás' },
  { label: 'Karema', value: 'Karema' },
  { label: 'Karera', value: 'Karera' },
  { label: 'Karesi', value: 'Karesi' },
  { label: 'Kargalinskaya', value: 'Kargalinskaya' },
  { label: 'Kargasok', value: 'Kargasok' },
  { label: 'Kargat', value: 'Kargat' },
  { label: 'Kargı', value: 'Kargı' },
  { label: 'Kargil', value: 'Kargil' },
  { label: 'Kargopol’', value: 'Kargopol’' },
  { label: 'Kargopol’Skiy Rayon', value: 'Kargopol’Skiy Rayon' },
  { label: 'Kargowa', value: 'Kargowa' },
  { label: 'Karhal', value: 'Karhal' },
  { label: 'Karhula', value: 'Karhula' },
  { label: 'Kari', value: 'Kari' },
  { label: 'Kariana', value: 'Kariana' },
  { label: 'Kariapatti', value: 'Kariapatti' },
  { label: 'Kariba', value: 'Kariba' },
  { label: 'Kariba District', value: 'Kariba District' },
  { label: 'Karibib', value: 'Karibib' },
  { label: 'Karijoki', value: 'Karijoki' },
  { label: 'Karimganj', value: 'Karimganj' },
  { label: 'Karimnagar', value: 'Karimnagar' },
  { label: 'Karimpur', value: 'Karimpur' },
  { label: 'Karimui Nomane', value: 'Karimui Nomane' },
  { label: 'Karinainen', value: 'Karinainen' },
  { label: 'Karinskoye', value: 'Karinskoye' },
  { label: 'Kario Ghanwar', value: 'Kario Ghanwar' },
  { label: 'Kariong', value: 'Kariong' },
  { label: 'Karis', value: 'Karis' },
  { label: 'Karítsa', value: 'Karítsa' },
  { label: 'Kariya', value: 'Kariya' },
  { label: 'Kariya', value: 'Kariya' },
  { label: 'Kariya-Shi', value: 'Kariya-Shi' },
  { label: 'Kariz', value: 'Kariz' },
  { label: 'Karjalohja', value: 'Karjalohja' },
  { label: 'Karjan', value: 'Karjan' },
  { label: 'Karjat', value: 'Karjat' },
  { label: 'Karkala', value: 'Karkala' },
  { label: 'Karkamb', value: 'Karkamb' },
  { label: 'Karkamış', value: 'Karkamış' },
  { label: 'Kark-E Sofla', value: 'Kark-E Sofla' },
  { label: 'Karkevand', value: 'Karkevand' },
  { label: 'Karkira', value: 'Karkira' },
  { label: 'Karkkila', value: 'Karkkila' },
  { label: 'Kärkölä', value: 'Kärkölä' },
  { label: 'Karksi-Nuia', value: 'Karksi-Nuia' },
  { label: 'Karlholmsbruk', value: 'Karlholmsbruk' },
  { label: 'Karligan', value: 'Karligan' },
  { label: 'Karlín', value: 'Karlín' },
  { label: 'Karlino', value: 'Karlino' },
  { label: 'Karlıova', value: 'Karlıova' },
  { label: 'Karlivka', value: 'Karlivka' },
  { label: 'Karlobag', value: 'Karlobag' },
  { label: 'Karlovice', value: 'Karlovice' },
  { label: 'Karlovo', value: 'Karlovo' },
  { label: 'Karlovy Vary', value: 'Karlovy Vary' },
  { label: 'Karłowice', value: 'Karłowice' },
  { label: 'Karlsborg', value: 'Karlsborg' },
  { label: 'Karlsdorf-Neuthard', value: 'Karlsdorf-Neuthard' },
  { label: 'Karlsfeld', value: 'Karlsfeld' },
  { label: 'Karlshagen', value: 'Karlshagen' },
  { label: 'Karlshamn', value: 'Karlshamn' },
  { label: 'Karlshorst', value: 'Karlshorst' },
  { label: 'Karlshuld', value: 'Karlshuld' },
  { label: 'Karlshus', value: 'Karlshus' },
  { label: 'Karlskoga', value: 'Karlskoga' },
  { label: 'Karlskron', value: 'Karlskron' },
  { label: 'Karlskrona', value: 'Karlskrona' },
  { label: 'Karlsøy', value: 'Karlsøy' },
  { label: 'Karlsruhe', value: 'Karlsruhe' },
  { label: 'Karlsruhe Region', value: 'Karlsruhe Region' },
  { label: 'Karlstad', value: 'Karlstad' },
  { label: 'Karlstadt', value: 'Karlstadt' },
  { label: 'Karlstetten', value: 'Karlstetten' },
  { label: 'Karma', value: 'Karma' },
  { label: 'Karmah An Nuzul', value: 'Karmah An Nuzul' },
  { label: 'Karmala', value: 'Karmala' },
  { label: 'Karmaskaly', value: 'Karmaskaly' },
  { label: 'Karmėlava', value: 'Karmėlava' },
  { label: 'Karmi’El', value: 'Karmi’El' },
  { label: 'Karmøy', value: 'Karmøy' },
  { label: 'Karnal', value: 'Karnal' },
  { label: 'Karnes City', value: 'Karnes City' },
  { label: 'Karnes County', value: 'Karnes County' },
  { label: 'Karnice', value: 'Karnice' },
  { label: 'Karniewo', value: 'Karniewo' },
  { label: 'Karnobat', value: 'Karnobat' },
  { label: 'Karnup', value: 'Karnup' },
  { label: 'Karoi', value: 'Karoi' },
  { label: 'Karojba', value: 'Karojba' },
  { label: 'Karol Bagh', value: 'Karol Bagh' },
  { label: 'Karolinka', value: 'Karolinka' },
  { label: 'Karolino-Buhaz', value: 'Karolino-Buhaz' },
  { label: 'Karonga', value: 'Karonga' },
  { label: 'Karonga District', value: 'Karonga District' },
  { label: 'Karoonda East Murray', value: 'Karoonda East Murray' },
  { label: 'Karor', value: 'Karor' },
  { label: 'Karosta', value: 'Karosta' },
  { label: 'Karow', value: 'Karow' },
  { label: 'Karpacz', value: 'Karpacz' },
  { label: 'Karpathos', value: 'Karpathos' },
  { label: 'Karpenísi', value: 'Karpenísi' },
  { label: 'Karpinsk', value: 'Karpinsk' },
  { label: 'Karpochóri', value: 'Karpochóri' },
  { label: 'Karpogory', value: 'Karpogory' },
  { label: 'Karpovsky', value: 'Karpovsky' },
  { label: 'Karpushikha', value: 'Karpushikha' },
  { label: 'Karpuzlu', value: 'Karpuzlu' },
  { label: 'Karrantza Harana/Valle De Carranza', value: 'Karrantza Harana/Valle De Carranza' },
  { label: 'Karrapur', value: 'Karrapur' },
  { label: 'Karratha', value: 'Karratha' },
  { label: 'Karres', value: 'Karres' },
  { label: 'Karrinyup', value: 'Karrinyup' },
  { label: 'Karrösten', value: 'Karrösten' },
  { label: 'Kärsämäki', value: 'Kärsämäki' },
  { label: 'Kārsava', value: 'Kārsava' },
  { label: 'Karsbach', value: 'Karsbach' },
  { label: 'Karsdorf', value: 'Karsdorf' },
  { label: 'Karsin', value: 'Karsin' },
  { label: 'Karşıyaka', value: 'Karşıyaka' },
  { label: 'Karstädt', value: 'Karstädt' },
  { label: 'Karstula', value: 'Karstula' },
  { label: 'Karsun', value: 'Karsun' },
  { label: 'Kartal', value: 'Kartal' },
  { label: 'Kartal', value: 'Kartal' },
  { label: 'Kartalinskiy Rayon', value: 'Kartalinskiy Rayon' },
  { label: 'Kartaly', value: 'Kartaly' },
  { label: 'Kartarpur', value: 'Kartarpur' },
  { label: 'Kartasura', value: 'Kartasura' },
  { label: 'Kartepe', value: 'Kartepe' },
  { label: 'Kartilabad', value: 'Kartilabad' },
  { label: 'Kartitsch', value: 'Kartitsch' },
  { label: 'Kartuzy', value: 'Kartuzy' },
  { label: 'Karu', value: 'Karu' },
  { label: 'Karuah', value: 'Karuah' },
  { label: 'Karuizawa', value: 'Karuizawa' },
  { label: 'Karukh', value: 'Karukh' },
  { label: 'Karumba', value: 'Karumba' },
  { label: 'Karumbakkam', value: 'Karumbakkam' },
  { label: 'Karun', value: 'Karun' },
  { label: 'Karungdong', value: 'Karungdong' },
  { label: 'Karungdong', value: 'Karungdong' },
  { label: 'Karup', value: 'Karup' },
  { label: 'Karur', value: 'Karur' },
  { label: 'Karuri', value: 'Karuri' },
  { label: 'Karuzi', value: 'Karuzi' },
  { label: 'Karvia', value: 'Karvia' },
  { label: 'Karviná', value: 'Karviná' },
  { label: 'Karwar', value: 'Karwar' },
  { label: 'Karyés', value: 'Karyés' },
  { label: 'Karymskoye', value: 'Karymskoye' },
  { label: 'Karyótissa', value: 'Karyótissa' },
  { label: 'Kárystos', value: 'Kárystos' },
  { label: 'Karzin', value: 'Karzin' },
  { label: 'Kaş', value: 'Kaş' },
  { label: 'Kås', value: 'Kås' },
  { label: 'Kasai Shi', value: 'Kasai Shi' },
  { label: 'Kasakh', value: 'Kasakh' },
  { label: 'Kasama', value: 'Kasama' },
  { label: 'Kasama', value: 'Kasama' },
  { label: 'Kasama-Shi', value: 'Kasama-Shi' },
  { label: 'Kasamatsuchō', value: 'Kasamatsuchō' },
  { label: 'Kasamwa', value: 'Kasamwa' },
  { label: 'Kasangulu', value: 'Kasangulu' },
  { label: 'Kasaoka', value: 'Kasaoka' },
  { label: 'Kasaoka Shi', value: 'Kasaoka Shi' },
  { label: 'Kasara', value: 'Kasara' },
  { label: 'Kasaragod', value: 'Kasaragod' },
  { label: 'Kasauli', value: 'Kasauli' },
  { label: 'Kasavand', value: 'Kasavand' },
  { label: 'Kasba', value: 'Kasba' },
  { label: 'Kasba Tadla', value: 'Kasba Tadla' },
  { label: 'Kasbach-Ohlenberg', value: 'Kasbach-Ohlenberg' },
  { label: 'Kaseda-Shirakame', value: 'Kaseda-Shirakame' },
  { label: 'Kasejovice', value: 'Kasejovice' },
  { label: 'Kasel', value: 'Kasel' },
  { label: 'Kasempa', value: 'Kasempa' },
  { label: 'Kasendorf', value: 'Kasendorf' },
  { label: 'Kaser', value: 'Kaser' },
  { label: 'Kasese', value: 'Kasese' },
  { label: 'Kaset Sombun', value: 'Kaset Sombun' },
  { label: 'Kaset Wisai', value: 'Kaset Wisai' },
  { label: 'Kasganj', value: 'Kasganj' },
  { label: 'Kashan', value: 'Kashan' },
  { label: 'Kashary', value: 'Kashary' },
  { label: 'Kashgar', value: 'Kashgar' },
  { label: 'Kashiba-Shi', value: 'Kashiba-Shi' },
  { label: 'Kashihara', value: 'Kashihara' },
  { label: 'Kashihara-Shi', value: 'Kashihara-Shi' },
  { label: 'Kashima', value: 'Kashima' },
  { label: 'Kashima Shi', value: 'Kashima Shi' },
  { label: 'Kashima-Shi', value: 'Kashima-Shi' },
  { label: 'Kashin', value: 'Kashin' },
  { label: 'Kashinskiy Rayon', value: 'Kashinskiy Rayon' },
  { label: 'Kashipur', value: 'Kashipur' },
  { label: 'Kashira', value: 'Kashira' },
  { label: 'Kashirskiy Rayon', value: 'Kashirskiy Rayon' },
  { label: 'Kashirskoye', value: 'Kashirskoye' },
  { label: 'Kashiwa', value: 'Kashiwa' },
  { label: 'Kashiwa Shi', value: 'Kashiwa Shi' },
  { label: 'Kashiwara-Shi', value: 'Kashiwara-Shi' },
  { label: 'Kashiwazaki', value: 'Kashiwazaki' },
  { label: 'Kashiwazaki Shi', value: 'Kashiwazaki Shi' },
  { label: 'Kashkhatau', value: 'Kashkhatau' },
  { label: 'Kashmar', value: 'Kashmar' },
  { label: 'Kashmor', value: 'Kashmor' },
  { label: 'Kashtanovoye', value: 'Kashtanovoye' },
  { label: 'Kashtany', value: 'Kashtany' },
  { label: 'Kasibu', value: 'Kasibu' },
  { label: 'Kasibu', value: 'Kasibu' },
  { label: 'Kasimov', value: 'Kasimov' },
  { label: 'Kasimovskiy Rayon', value: 'Kasimovskiy Rayon' },
  { label: 'Kašina', value: 'Kašina' },
  { label: 'Kasina Wielka', value: 'Kasina Wielka' },
  { label: 'Kaski', value: 'Kaski' },
  { label: 'Kaskinen', value: 'Kaskinen' },
  { label: 'Kaslan Qayah', value: 'Kaslan Qayah' },
  { label: 'Kasli', value: 'Kasli' },
  { label: 'Kasoa', value: 'Kasoa' },
  { label: 'Kasoda', value: 'Kasoda' },
  { label: 'Kasongo', value: 'Kasongo' },
  { label: 'Kasongo-Lunda', value: 'Kasongo-Lunda' },
  { label: 'Kašperské Hory', value: 'Kašperské Hory' },
  { label: 'Kaspi', value: 'Kaspi' },
  { label: 'Kaspiysk', value: 'Kaspiysk' },
  { label: 'Kaspiyskiy', value: 'Kaspiyskiy' },
  { label: 'Kasrawad', value: 'Kasrawad' },
  { label: 'Kass Wollof', value: 'Kass Wollof' },
  { label: 'Kassab', value: 'Kassab' },
  { label: 'Kassala', value: 'Kassala' },
  { label: 'Kassándreia', value: 'Kassándreia' },
  { label: 'Kasseedorf', value: 'Kasseedorf' },
  { label: 'Kassel', value: 'Kassel' },
  { label: 'Kasserine', value: 'Kasserine' },
  { label: 'Kassiri', value: 'Kassiri' },
  { label: 'Kasson', value: 'Kasson' },
  { label: 'Kastanayevo', value: 'Kastanayevo' },
  { label: 'Kastaniés', value: 'Kastaniés' },
  { label: 'Kastav', value: 'Kastav' },
  { label: 'Kaštel Gomilica', value: 'Kaštel Gomilica' },
  { label: 'Kaštel Kambelovac', value: 'Kaštel Kambelovac' },
  { label: 'Kaštel Lukšić', value: 'Kaštel Lukšić' },
  { label: 'Kaštel Novi', value: 'Kaštel Novi' },
  { label: 'Kaštel Štafilić', value: 'Kaštel Štafilić' },
  { label: 'Kaštel Stari', value: 'Kaštel Stari' },
  { label: 'Kaštela', value: 'Kaštela' },
  { label: 'Kaštelir-Labinci', value: 'Kaštelir-Labinci' },
  { label: 'Kastélla', value: 'Kastélla' },
  { label: 'Kastellaun', value: 'Kastellaun' },
  { label: 'Kastélli', value: 'Kastélli' },
  { label: 'Kasten Bei Böheimkirchen', value: 'Kasten Bei Böheimkirchen' },
  { label: 'Kasterlee', value: 'Kasterlee' },
  { label: 'Kastl', value: 'Kastl' },
  { label: 'Kastorenskiy Rayon', value: 'Kastorenskiy Rayon' },
  { label: 'Kastorf', value: 'Kastorf' },
  { label: 'Kastoria', value: 'Kastoria' },
  { label: 'Kastornoye', value: 'Kastornoye' },
  { label: 'Kastráki', value: 'Kastráki' },
  { label: 'Kastrí', value: 'Kastrí' },
  { label: 'Kastrychnitski Rayon', value: 'Kastrychnitski Rayon' },
  { label: 'Kastsyukovichy', value: 'Kastsyukovichy' },
  { label: 'Kastsyukowka', value: 'Kastsyukowka' },
  { label: 'Kasuga Shi', value: 'Kasuga Shi' },
  { label: 'Kasugai', value: 'Kasugai' },
  { label: 'Kasugai-Shi', value: 'Kasugai-Shi' },
  { label: 'Kasukabe', value: 'Kasukabe' },
  { label: 'Kasukabe Shi', value: 'Kasukabe Shi' },
  { label: 'Kasulu', value: 'Kasulu' },
  { label: 'Kasumigaura', value: 'Kasumigaura' },
  { label: 'Kasumigaura-Shi', value: 'Kasumigaura-Shi' },
  { label: 'Kasumkent', value: 'Kasumkent' },
  { label: 'Kasungu', value: 'Kasungu' },
  { label: 'Kasungu District', value: 'Kasungu District' },
  { label: 'Kasur', value: 'Kasur' },
  { label: 'Kaszaper', value: 'Kaszaper' },
  { label: 'Kaszów', value: 'Kaszów' },
  { label: 'Kataba', value: 'Kataba' },
  { label: 'Katabu', value: 'Katabu' },
  { label: 'Katagami', value: 'Katagami' },
  { label: 'Katagami-Shi', value: 'Katagami-Shi' },
  { label: 'Katangawan', value: 'Katangawan' },
  { label: 'Katangi', value: 'Katangi' },
  { label: 'Katangskiy Rayon', value: 'Katangskiy Rayon' },
  { label: 'Katanning', value: 'Katanning' },
  { label: 'Katano Shi', value: 'Katano Shi' },
  { label: 'Kataouane', value: 'Kataouane' },
  { label: 'Kataragama', value: 'Kataragama' },
  { label: 'Katastárion', value: 'Katastárion' },
  { label: 'Katav-Ivanovsk', value: 'Katav-Ivanovsk' },
  { label: 'Katav-Ivanovskiy Rayon', value: 'Katav-Ivanovskiy Rayon' },
  { label: 'Kataysk', value: 'Kataysk' },
  { label: 'Katchang', value: 'Katchang' },
  { label: 'Katembe', value: 'Katembe' },
  { label: 'Katendrecht', value: 'Katendrecht' },
  { label: 'Katerero', value: 'Katerero' },
  { label: 'Kateríni', value: 'Kateríni' },
  { label: 'Katesh', value: 'Katesh' },
  { label: 'Katghora', value: 'Katghora' },
  { label: 'Katharinenberg', value: 'Katharinenberg' },
  { label: 'Katherine', value: 'Katherine' },
  { label: 'Katherine East', value: 'Katherine East' },
  { label: 'Katherine South', value: 'Katherine South' },
  { label: 'Kathleen', value: 'Kathleen' },
  { label: 'Kathmandu', value: 'Kathmandu' },
  { label: 'Kathor', value: 'Kathor' },
  { label: 'Kathu', value: 'Kathu' },
  { label: 'Kathu', value: 'Kathu' },
  { label: 'Kathua', value: 'Kathua' },
  { label: 'Kati', value: 'Kati' },
  { label: 'Kati', value: 'Kati' },
  { label: 'Katico', value: 'Katico' },
  { label: 'Katico', value: 'Katico' },
  { label: 'Katidtuan', value: 'Katidtuan' },
  { label: 'Katidtuan', value: 'Katidtuan' },
  { label: 'Katihar', value: 'Katihar' },
  { label: 'Katikati', value: 'Katikati' },
  { label: 'Katima Mulilo', value: 'Katima Mulilo' },
  { label: 'Katiola', value: 'Katiola' },
  { label: 'Katipunan', value: 'Katipunan' },
  { label: 'Katipunan', value: 'Katipunan' },
  { label: 'Katipunan', value: 'Katipunan' },
  { label: 'Katipunan', value: 'Katipunan' },
  { label: 'Katlenburg-Lindau', value: 'Katlenburg-Lindau' },
  { label: 'Katni', value: 'Katni' },
  { label: 'Káto Achaḯa', value: 'Káto Achaḯa' },
  { label: 'Káto Asítai', value: 'Káto Asítai' },
  { label: 'Káto Defterá', value: 'Káto Defterá' },
  { label: 'Káto Dhiminió', value: 'Káto Dhiminió' },
  { label: 'Káto Glykóvrysi', value: 'Káto Glykóvrysi' },
  { label: 'Káto Goúves', value: 'Káto Goúves' },
  { label: 'Káto Kamíla', value: 'Káto Kamíla' },
  { label: 'Káto Lekhónia', value: 'Káto Lekhónia' },
  { label: 'Káto Lipochóri', value: 'Káto Lipochóri' },
  { label: 'Káto Mazaráki', value: 'Káto Mazaráki' },
  { label: 'Káto Miliá', value: 'Káto Miliá' },
  { label: 'Káto Nevrokópi', value: 'Káto Nevrokópi' },
  { label: 'Káto Pýrgos', value: 'Káto Pýrgos' },
  { label: 'Káto Scholári', value: 'Káto Scholári' },
  { label: 'Katō Shi', value: 'Katō Shi' },
  { label: 'Káto Soúlion', value: 'Káto Soúlion' },
  { label: 'Káto Tithoréa', value: 'Káto Tithoréa' },
  { label: 'Katochí', value: 'Katochí' },
  { label: 'Katol', value: 'Katol' },
  { label: 'Katonah', value: 'Katonah' },
  { label: 'Katoomba', value: 'Katoomba' },
  { label: 'Katori-Shi', value: 'Katori-Shi' },
  { label: 'Katoro', value: 'Katoro' },
  { label: 'Katoro', value: 'Katoro' },
  { label: 'Katoúna', value: 'Katoúna' },
  { label: 'Katovice', value: 'Katovice' },
  { label: 'Katowice', value: 'Katowice' },
  { label: 'Katpadi', value: 'Katpadi' },
  { label: 'Katpur', value: 'Katpur' },
  { label: 'Katra', value: 'Katra' },
  { label: 'Katra', value: 'Katra' },
  { label: 'Katral', value: 'Katral' },
  { label: 'Katras', value: 'Katras' },
  { label: 'Katrineholm', value: 'Katrineholm' },
  { label: 'Katsdorf', value: 'Katsdorf' },
  { label: 'Katsikás', value: 'Katsikás' },
  { label: 'Katsina', value: 'Katsina' },
  { label: 'Katsuragi', value: 'Katsuragi' },
  { label: 'Katsuragi Shi', value: 'Katsuragi Shi' },
  { label: 'Katsuren-Haebaru', value: 'Katsuren-Haebaru' },
  { label: 'Katsushika Ku', value: 'Katsushika Ku' },
  { label: 'Katsuta', value: 'Katsuta' },
  { label: 'Katsuura', value: 'Katsuura' },
  { label: 'Katsuura Gun', value: 'Katsuura Gun' },
  { label: 'Katsuura-Shi', value: 'Katsuura-Shi' },
  { label: 'Katsuyama', value: 'Katsuyama' },
  { label: 'Katsuyama Shi', value: 'Katsuyama Shi' },
  { label: 'Kattanam', value: 'Kattanam' },
  { label: 'Kattaqo‘Rg‘On', value: 'Kattaqo‘Rg‘On' },
  { label: 'Kattivakkam', value: 'Kattivakkam' },
  { label: 'Kattupputtur', value: 'Kattupputtur' },
  { label: 'Katubao', value: 'Katubao' },
  { label: 'Katubao', value: 'Katubao' },
  { label: 'Katugastota', value: 'Katugastota' },
  { label: 'Katuli', value: 'Katuli' },
  { label: 'Katuli', value: 'Katuli' },
  { label: 'Katumba', value: 'Katumba' },
  { label: 'Katunayaka', value: 'Katunayaka' },
  { label: 'Katunino', value: 'Katunino' },
  { label: 'Katutura', value: 'Katutura' },
  { label: 'Katwa', value: 'Katwa' },
  { label: 'Katwijk Aan Den Rijn', value: 'Katwijk Aan Den Rijn' },
  { label: 'Katwijk Aan Zee', value: 'Katwijk Aan Zee' },
  { label: 'Katwoude', value: 'Katwoude' },
  { label: 'Katy', value: 'Katy' },
  { label: 'Kąty Wrocławskie', value: 'Kąty Wrocławskie' },
  { label: 'Katymár', value: 'Katymár' },
  { label: 'Katyn', value: 'Katyn' },
  { label: 'Katyr-Yurt', value: 'Katyr-Yurt' },
  { label: 'Katyuzhanka', value: 'Katyuzhanka' },
  { label: 'Katzelsdorf', value: 'Katzelsdorf' },
  { label: 'Katzenelnbogen', value: 'Katzenelnbogen' },
  { label: 'Katzhütte', value: 'Katzhütte' },
  { label: 'Katzweiler', value: 'Katzweiler' },
  { label: 'Katzwinkel', value: 'Katzwinkel' },
  { label: 'Kaua', value: 'Kaua' },
  { label: 'Kauai County', value: 'Kauai County' },
  { label: 'Kaub', value: 'Kaub' },
  { label: 'Kaufbeuren', value: 'Kaufbeuren' },
  { label: 'Kaufering', value: 'Kaufering' },
  { label: 'Kaufman', value: 'Kaufman' },
  { label: 'Kaufman County', value: 'Kaufman County' },
  { label: 'Kaufungen', value: 'Kaufungen' },
  { label: 'Kauhajoki', value: 'Kauhajoki' },
  { label: 'Kauhava', value: 'Kauhava' },
  { label: 'Kauit', value: 'Kauit' },
  { label: 'Kaukab Abū El Hījā', value: 'Kaukab Abū El Hījā' },
  { label: 'Kaukauna', value: 'Kaukauna' },
  { label: 'Kaulsdorf', value: 'Kaulsdorf' },
  { label: 'Kaulsdorf', value: 'Kaulsdorf' },
  { label: 'Kaumberg', value: 'Kaumberg' },
  { label: 'Kaunakakai', value: 'Kaunakakai' },
  { label: 'Kaunas', value: 'Kaunas' },
  { label: 'Kauniainen', value: 'Kauniainen' },
  { label: 'Kauns', value: 'Kauns' },
  { label: 'Kaura Namoda', value: 'Kaura Namoda' },
  { label: 'Kauran', value: 'Kauran' },
  { label: 'Kauran', value: 'Kauran' },
  { label: 'Kausani', value: 'Kausani' },
  { label: 'Kaushambi District', value: 'Kaushambi District' },
  { label: 'Kaustinen', value: 'Kaustinen' },
  { label: 'Kauswagan', value: 'Kauswagan' },
  { label: 'Kauswagan', value: 'Kauswagan' },
  { label: 'Kauswagan', value: 'Kauswagan' },
  { label: 'Kautokeino', value: 'Kautokeino' },
  { label: 'Kautzen', value: 'Kautzen' },
  { label: 'Kavadarci', value: 'Kavadarci' },
  { label: 'Kavajë', value: 'Kavajë' },
  { label: 'Kavak', value: 'Kavak' },
  { label: 'Kavaklıdere', value: 'Kavaklıdere' },
  { label: 'Kavála', value: 'Kavála' },
  { label: 'Kavalerovo', value: 'Kavalerovo' },
  { label: 'Kavali', value: 'Kavali' },
  { label: 'Kavallári', value: 'Kavallári' },
  { label: 'Kavalur', value: 'Kavalur' },
  { label: 'Kavanaj', value: 'Kavanaj' },
  { label: 'Kavani', value: 'Kavani' },
  { label: 'Kavar', value: 'Kavar' },
  { label: 'Kavaratti', value: 'Kavaratti' },
  { label: 'Kavarna', value: 'Kavarna' },
  { label: 'Kavásila', value: 'Kavásila' },
  { label: 'Kavečany', value: 'Kavečany' },
  { label: 'Kavelstorf', value: 'Kavelstorf' },
  { label: 'Kaveripatnam', value: 'Kaveripatnam' },
  { label: 'Kavieng', value: 'Kavieng' },
  { label: 'Kavkazskaya', value: 'Kavkazskaya' },
  { label: 'Kavkazskiy', value: 'Kavkazskiy' },
  { label: 'Kävlinge', value: 'Kävlinge' },
  { label: 'Kavrepalanchok', value: 'Kavrepalanchok' },
  { label: 'Kavýli', value: 'Kavýli' },
  { label: 'Kawachi-Nagano Shi', value: 'Kawachi-Nagano Shi' },
  { label: 'Kawage', value: 'Kawage' },
  { label: 'Kawagoe', value: 'Kawagoe' },
  { label: 'Kawagoe-Shi', value: 'Kawagoe-Shi' },
  { label: 'Kawaguchi', value: 'Kawaguchi' },
  { label: 'Kawaguchi-Shi', value: 'Kawaguchi-Shi' },
  { label: 'Kawakami', value: 'Kawakami' },
  { label: 'Kawakawa', value: 'Kawakawa' },
  { label: 'Kawalu', value: 'Kawalu' },
  { label: 'Kawambwa', value: 'Kawambwa' },
  { label: 'Kawana', value: 'Kawana' },
  { label: 'Kawanishi', value: 'Kawanishi' },
  { label: 'Kawanishi Shi', value: 'Kawanishi Shi' },
  { label: 'Kawanoechō', value: 'Kawanoechō' },
  { label: 'Kawant', value: 'Kawant' },
  { label: 'Kawardha', value: 'Kawardha' },
  { label: 'Kawartha Lakes', value: 'Kawartha Lakes' },
  { label: 'Kawasaki', value: 'Kawasaki' },
  { label: 'Kawasaki', value: 'Kawasaki' },
  { label: 'Kawasaki-Shi', value: 'Kawasaki-Shi' },
  { label: 'Kawayan', value: 'Kawayan' },
  { label: 'Kawayan', value: 'Kawayan' },
  { label: 'Kawęczyn', value: 'Kawęczyn' },
  { label: 'Kawęczyn Nowy', value: 'Kawęczyn Nowy' },
  { label: 'Kawerau', value: 'Kawerau' },
  { label: 'Kawit', value: 'Kawit' },
  { label: 'Kawit', value: 'Kawit' },
  { label: 'Kawm Ḩamādah', value: 'Kawm Ḩamādah' },
  { label: 'Kawm Umbū', value: 'Kawm Umbū' },
  { label: 'Kawthoung', value: 'Kawthoung' },
  { label: 'Kawungan', value: 'Kawungan' },
  { label: 'Kaxgar Diqu', value: 'Kaxgar Diqu' },
  { label: 'Kaxholmen', value: 'Kaxholmen' },
  { label: 'Kay County', value: 'Kay County' },
  { label: 'Kaya', value: 'Kaya' },
  { label: 'Kayakent', value: 'Kayakent' },
  { label: 'Kayakentskiy Rayon', value: 'Kayakentskiy Rayon' },
  { label: 'Kayalpattinam', value: 'Kayalpattinam' },
  { label: 'Kayan', value: 'Kayan' },
  { label: 'Kayangel', value: 'Kayangel' },
  { label: 'Kayankulam', value: 'Kayankulam' },
  { label: 'Kayanza', value: 'Kayanza' },
  { label: 'Kayapa', value: 'Kayapa' },
  { label: 'Kayapınar', value: 'Kayapınar' },
  { label: 'Kayar', value: 'Kayar' },
  { label: 'Kayasula', value: 'Kayasula' },
  { label: 'Kayattar', value: 'Kayattar' },
  { label: 'Kayavarohan', value: 'Kayavarohan' },
  { label: 'Kaybitskiy Rayon', value: 'Kaybitskiy Rayon' },
  { label: 'Kaydaki', value: 'Kaydaki' },
  { label: 'Kayenta', value: 'Kayenta' },
  { label: 'Kayes', value: 'Kayes' },
  { label: 'Kayes', value: 'Kayes' },
  { label: 'Kayhude', value: 'Kayhude' },
  { label: 'Kayima', value: 'Kayima' },
  { label: 'Kayiry', value: 'Kayiry' },
  { label: 'Kayl', value: 'Kayl' },
  { label: 'Kayna', value: 'Kayna' },
  { label: 'Kaynarca', value: 'Kaynarca' },
  { label: 'Kaynardzha', value: 'Kaynardzha' },
  { label: 'Kaynaşlı', value: 'Kaynaşlı' },
  { label: 'Kayong Utara', value: 'Kayong Utara' },
  { label: 'Kayseri', value: 'Kayseri' },
  { label: 'Kaysersberg', value: 'Kaysersberg' },
  { label: 'Kaysone Phomvihane', value: 'Kaysone Phomvihane' },
  { label: 'Kaysville', value: 'Kaysville' },
  { label: 'Kaytagskiy Rayon', value: 'Kaytagskiy Rayon' },
  { label: 'Kaytitinga', value: 'Kaytitinga' },
  { label: 'Kayunga', value: 'Kayunga' },
  { label: 'Kayyerkan', value: 'Kayyerkan' },
  { label: 'Kaz', value: 'Kaz' },
  { label: 'Kazachinskiy Rayon', value: 'Kazachinskiy Rayon' },
  { label: 'Kazachinsko-Lenskiy Rayon', value: 'Kazachinsko-Lenskiy Rayon' },
  { label: 'Kazachinskoye', value: 'Kazachinskoye' },
  { label: 'Kazachinskoye', value: 'Kazachinskoye' },
  { label: 'Kazachka', value: 'Kazachka' },
  { label: 'Kazaki', value: 'Kazaki' },
  { label: 'Kazan', value: 'Kazan' },
  { label: 'Kazanka', value: 'Kazanka' },
  { label: 'Kazanlak', value: 'Kazanlak' },
  { label: 'Kazanów', value: 'Kazanów' },
  { label: 'Kazanskaya', value: 'Kazanskaya' },
  { label: 'Kazanskaya', value: 'Kazanskaya' },
  { label: 'Kazanskiy Rayon', value: 'Kazanskiy Rayon' },
  { label: 'Kazanskoye', value: 'Kazanskoye' },
  { label: 'Kazár', value: 'Kazár' },
  { label: 'Kazarman', value: 'Kazarman' },
  { label: 'Kazaure', value: 'Kazaure' },
  { label: 'Kazbekovskiy Rayon', value: 'Kazbekovskiy Rayon' },
  { label: 'Kazem Abad', value: 'Kazem Abad' },
  { label: 'Kazerun', value: 'Kazerun' },
  { label: 'Kazimierz Biskupi', value: 'Kazimierz Biskupi' },
  { label: 'Kazimierz Dolny', value: 'Kazimierz Dolny' },
  { label: 'Kazimierza Wielka', value: 'Kazimierza Wielka' },
  { label: 'Kazımkarabekir', value: 'Kazımkarabekir' },
  { label: 'Kazincbarcika', value: 'Kazincbarcika' },
  { label: 'Kazincbarcikai Járás', value: 'Kazincbarcikai Járás' },
  { label: 'Kazinka', value: 'Kazinka' },
  { label: 'Kazlų Rūda', value: 'Kazlų Rūda' },
  { label: 'Kazlų Rūda Municipality', value: 'Kazlų Rūda Municipality' },
  { label: 'Kaźmierz', value: 'Kaźmierz' },
  { label: 'Kaznějov', value: 'Kaznějov' },
  { label: 'Kazo', value: 'Kazo' },
  { label: 'Kazo-Shi', value: 'Kazo-Shi' },
  { label: 'Kaztalovka', value: 'Kaztalovka' },
  { label: 'Kazuno Shi', value: 'Kazuno Shi' },
  { label: 'Kbely', value: 'Kbely' },
  { label: 'Kcynia', value: 'Kcynia' },
  { label: 'Kdyně', value: 'Kdyně' },
  { label: 'Kea‘Au', value: 'Kea‘Au' },
  { label: 'Keadby', value: 'Keadby' },
  { label: 'Keady', value: 'Keady' },
  { label: 'Kealakekua', value: 'Kealakekua' },
  { label: 'Kealba', value: 'Kealba' },
  { label: 'Keansburg', value: 'Keansburg' },
  { label: 'Kearney', value: 'Kearney' },
  { label: 'Kearney', value: 'Kearney' },
  { label: 'Kearney County', value: 'Kearney County' },
  { label: 'Kearney Park', value: 'Kearney Park' },
  { label: 'Kearneys Spring', value: 'Kearneys Spring' },
  { label: 'Kearns', value: 'Kearns' },
  { label: 'Kearns', value: 'Kearns' },
  { label: 'Kearny', value: 'Kearny' },
  { label: 'Kearny', value: 'Kearny' },
  { label: 'Kearny County', value: 'Kearny County' },
  { label: 'Kearsley', value: 'Kearsley' },
  { label: 'Keban', value: 'Keban' },
  { label: 'Kebili', value: 'Kebili' },
  { label: 'Kebomas', value: 'Kebomas' },
  { label: 'Kebonarun', value: 'Kebonarun' },
  { label: 'Kecel', value: 'Kecel' },
  { label: 'Kechi', value: 'Kechi' },
  { label: 'Keçiborlu', value: 'Keçiborlu' },
  { label: 'Keçiören', value: 'Keçiören' },
  { label: 'Kecskéd', value: 'Kecskéd' },
  { label: 'Kecskemét', value: 'Kecskemét' },
  { label: 'Kecskeméti Járás', value: 'Kecskeméti Járás' },
  { label: 'Kėdainiai', value: 'Kėdainiai' },
  { label: 'Kédange-Sur-Canner', value: 'Kédange-Sur-Canner' },
  { label: 'Kedichem', value: 'Kedichem' },
  { label: 'Kedington', value: 'Kedington' },
  { label: 'Kediri', value: 'Kediri' },
  { label: 'Kédougou', value: 'Kédougou' },
  { label: 'Kédougou Department', value: 'Kédougou Department' },
  { label: 'Kedron', value: 'Kedron' },
  { label: 'Kedrovka', value: 'Kedrovka' },
  { label: 'Kedrovoye', value: 'Kedrovoye' },
  { label: 'Kedrovyy', value: 'Kedrovyy' },
  { label: 'Kedrovyy', value: 'Kedrovyy' },
  { label: 'Kedungwaru', value: 'Kedungwaru' },
  { label: 'Kedungwuni', value: 'Kedungwuni' },
  { label: 'Kędzierzyn-Koźle', value: 'Kędzierzyn-Koźle' },
  { label: 'Keedysville', value: 'Keedysville' },
  { label: 'Keego Harbor', value: 'Keego Harbor' },
  { label: 'Keelakarai', value: 'Keelakarai' },
  { label: 'Keelby', value: 'Keelby' },
  { label: 'Keele', value: 'Keele' },
  { label: 'Keeler Farm', value: 'Keeler Farm' },
  { label: 'Keene', value: 'Keene' },
  { label: 'Keene', value: 'Keene' },
  { label: 'Keenesburg', value: 'Keenesburg' },
  { label: 'Keent', value: 'Keent' },
  { label: 'Keerbergen', value: 'Keerbergen' },
  { label: 'Keeseville', value: 'Keeseville' },
  { label: 'Keetmanshoop', value: 'Keetmanshoop' },
  { label: 'Keewatin', value: 'Keewatin' },
  { label: 'Kéfalos', value: 'Kéfalos' },
  { label: 'Kefamenanu', value: 'Kefamenanu' },
  { label: 'Kefar H̱Abad', value: 'Kefar H̱Abad' },
  { label: 'Kefar Rosh Haniqra', value: 'Kefar Rosh Haniqra' },
  { label: 'Kefar Shemaryahu', value: 'Kefar Shemaryahu' },
  { label: 'Kefar Tavor', value: 'Kefar Tavor' },
  { label: 'Kefar Weradim', value: 'Kefar Weradim' },
  { label: 'Kefar Yona', value: 'Kefar Yona' },
  { label: 'Kefenrod', value: 'Kefenrod' },
  { label: 'Keffi', value: 'Keffi' },
  { label: 'Keflavík', value: 'Keflavík' },
  { label: 'Kegalle', value: 'Kegalle' },
  { label: 'Kegalle District', value: 'Kegalle District' },
  { label: 'Kegen', value: 'Kegen' },
  { label: 'Kegeyli Shahar', value: 'Kegeyli Shahar' },
  { label: 'Ķegums', value: 'Ķegums' },
  { label: 'Kegworth', value: 'Kegworth' },
  { label: 'Kehl', value: 'Kehl' },
  { label: 'Kehlen', value: 'Kehlen' },
  { label: 'Kehra', value: 'Kehra' },
  { label: 'Kehrig', value: 'Kehrig' },
  { label: 'Kehrsatz', value: 'Kehrsatz' },
  { label: 'Kehtna', value: 'Kehtna' },
  { label: 'Kehtna Vald', value: 'Kehtna Vald' },
  { label: 'Kehychivka', value: 'Kehychivka' },
  { label: 'Keighley', value: 'Keighley' },
  { label: 'Keijenborg', value: 'Keijenborg' },
  { label: 'Keila', value: 'Keila' },
  { label: 'Keilor', value: 'Keilor' },
  { label: 'Keilor Downs', value: 'Keilor Downs' },
  { label: 'Keilor East', value: 'Keilor East' },
  { label: 'Keilor Lodge', value: 'Keilor Lodge' },
  { label: 'Keilor Park', value: 'Keilor Park' },
  { label: 'Keiraville', value: 'Keiraville' },
  { label: 'Keïta', value: 'Keïta' },
  { label: 'Keith', value: 'Keith' },
  { label: 'Keith', value: 'Keith' },
  { label: 'Keith County', value: 'Keith County' },
  { label: 'Keitum', value: 'Keitum' },
  { label: 'Keizer', value: 'Keizer' },
  { label: 'Kék', value: 'Kék' },
  { label: 'Kekaha', value: 'Kekaha' },
  { label: 'Ķekava', value: 'Ķekava' },
  { label: 'Kekri', value: 'Kekri' },
  { label: 'Kelaa-Des-Sraghna', value: 'Kelaa-Des-Sraghna' },
  { label: 'Kelaat Mgouna', value: 'Kelaat Mgouna' },
  { label: 'Kelachay', value: 'Kelachay' },
  { label: 'Kelamangalam', value: 'Kelamangalam' },
  { label: 'Kelaniya', value: 'Kelaniya' },
  { label: 'Kelapa Sawit', value: 'Kelapa Sawit' },
  { label: 'Kelarabad', value: 'Kelarabad' },
  { label: 'Kelardasht', value: 'Kelardasht' },
  { label: 'Kelasuri', value: 'Kelasuri' },
  { label: 'Kelberg', value: 'Kelberg' },
  { label: 'Kelbra', value: 'Kelbra' },
  { label: 'Kelburn', value: 'Kelburn' },
  { label: 'Kelč', value: 'Kelč' },
  { label: 'Këlcyrë', value: 'Këlcyrë' },
  { label: 'Kelebia', value: 'Kelebia' },
  { label: 'Keles', value: 'Keles' },
  { label: 'Kelheim', value: 'Kelheim' },
  { label: 'Kelibia', value: 'Kelibia' },
  { label: 'Kelishad And Soderjaan', value: 'Kelishad And Soderjaan' },
  { label: 'Kelishad Va Sudarjan', value: 'Kelishad Va Sudarjan' },
  { label: 'Kelkheim (Taunus)', value: 'Kelkheim (Taunus)' },
  { label: 'Kelkit', value: 'Kelkit' },
  { label: 'Kell', value: 'Kell' },
  { label: 'Kellenhusen', value: 'Kellenhusen' },
  { label: 'Keller', value: 'Keller' },
  { label: 'Kellerberrin', value: 'Kellerberrin' },
  { label: 'Kellinghusen', value: 'Kellinghusen' },
  { label: 'Kellits', value: 'Kellits' },
  { label: 'Kellmünz', value: 'Kellmünz' },
  { label: 'Kelloe', value: 'Kelloe' },
  { label: 'Kellogg', value: 'Kellogg' },
  { label: 'Kellokoski', value: 'Kellokoski' },
  { label: 'Kells', value: 'Kells' },
  { label: 'Kellyville', value: 'Kellyville' },
  { label: 'Kellyville', value: 'Kellyville' },
  { label: 'Kellyville Ridge', value: 'Kellyville Ridge' },
  { label: 'Kelmė', value: 'Kelmė' },
  { label: 'Kelmscott', value: 'Kelmscott' },
  { label: 'Kelo', value: 'Kelo' },
  { label: 'Kelowna', value: 'Kelowna' },
  { label: 'Kelpen-Oler', value: 'Kelpen-Oler' },
  { label: 'Kelsall', value: 'Kelsall' },
  { label: 'Kelseyville', value: 'Kelseyville' },
  { label: 'Kelso', value: 'Kelso' },
  { label: 'Kelso', value: 'Kelso' },
  { label: 'Kelso', value: 'Kelso' },
  { label: 'Kelso', value: 'Kelso' },
  { label: 'Kelsterbach', value: 'Kelsterbach' },
  { label: 'Kelty', value: 'Kelty' },
  { label: 'Kelvanaq', value: 'Kelvanaq' },
  { label: 'Kelvedon', value: 'Kelvedon' },
  { label: 'Kelvedon Hatch', value: 'Kelvedon Hatch' },
  { label: 'Kelvin Grove', value: 'Kelvin Grove' },
  { label: 'Kem’', value: 'Kem’' },
  { label: 'Ké-Macina', value: 'Ké-Macina' },
  { label: 'Kemah', value: 'Kemah' },
  { label: 'Kemah', value: 'Kemah' },
  { label: 'Kemaliye', value: 'Kemaliye' },
  { label: 'Kemalpaşa', value: 'Kemalpaşa' },
  { label: 'Kemalpaşa', value: 'Kemalpaşa' },
  { label: 'Kematen An Der Krems', value: 'Kematen An Der Krems' },
  { label: 'Kematen An Der Ybbs', value: 'Kematen An Der Ybbs' },
  { label: 'Kematen In Tirol', value: 'Kematen In Tirol' },
  { label: 'Kembata Alaba Tembaro Zone', value: 'Kembata Alaba Tembaro Zone' },
  { label: 'Kembé', value: 'Kembé' },
  { label: 'Kemberg', value: 'Kemberg' },
  { label: 'Kembs', value: 'Kembs' },
  { label: 'Kemecse', value: 'Kemecse' },
  { label: 'Kemecsei Járás', value: 'Kemecsei Járás' },
  { label: 'Kemer', value: 'Kemer' },
  { label: 'Kemer', value: 'Kemer' },
  { label: 'Kemerovo', value: 'Kemerovo' },
  { label: 'Kemeten', value: 'Kemeten' },
  { label: 'Kemi', value: 'Kemi' },
  { label: 'Kemijärvi', value: 'Kemijärvi' },
  { label: 'Kemin', value: 'Kemin' },
  { label: 'Keminmaa', value: 'Keminmaa' },
  { label: 'Kemisē', value: 'Kemisē' },
  { label: 'Kemlya', value: 'Kemlya' },
  { label: 'Kemmerer', value: 'Kemmerer' },
  { label: 'Kemmern', value: 'Kemmern' },
  { label: 'Kemnath', value: 'Kemnath' },
  { label: 'Kemnay', value: 'Kemnay' },
  { label: 'Kemnitz', value: 'Kemnitz' },
  { label: 'Kemp', value: 'Kemp' },
  { label: 'Kemp Mill', value: 'Kemp Mill' },
  { label: 'Kempele', value: 'Kempele' },
  { label: 'Kempen', value: 'Kempen' },
  { label: 'Kempenich', value: 'Kempenich' },
  { label: 'Kemper County', value: 'Kemper County' },
  { label: 'Kempner', value: 'Kempner' },
  { label: 'Kemps Creek', value: 'Kemps Creek' },
  { label: 'Kempsey', value: 'Kempsey' },
  { label: 'Kempsey', value: 'Kempsey' },
  { label: 'Kempston', value: 'Kempston' },
  { label: 'Kempston Hardwick', value: 'Kempston Hardwick' },
  { label: 'Kempten (Allgäu)', value: 'Kempten (Allgäu)' },
  { label: 'Kemri', value: 'Kemri' },
  { label: 'Kemsing', value: 'Kemsing' },
  { label: 'Ken Caryl', value: 'Ken Caryl' },
  { label: 'Kenai', value: 'Kenai' },
  { label: 'Kenai Peninsula Borough', value: 'Kenai Peninsula Borough' },
  { label: 'Kenansville', value: 'Kenansville' },
  { label: 'Kenbridge', value: 'Kenbridge' },
  { label: 'Kencong', value: 'Kencong' },
  { label: 'Kencot', value: 'Kencot' },
  { label: 'Kenda', value: 'Kenda' },
  { label: 'Kendal', value: 'Kendal' },
  { label: 'Kendal', value: 'Kendal' },
  { label: 'Kendal', value: 'Kendal' },
  { label: 'Kendale Lakes', value: 'Kendale Lakes' },
  { label: 'Kendall', value: 'Kendall' },
  { label: 'Kendall', value: 'Kendall' },
  { label: 'Kendall County', value: 'Kendall County' },
  { label: 'Kendall County', value: 'Kendall County' },
  { label: 'Kendall Green', value: 'Kendall Green' },
  { label: 'Kendall Park', value: 'Kendall Park' },
  { label: 'Kendall West', value: 'Kendall West' },
  { label: 'Kendallville', value: 'Kendallville' },
  { label: 'Kendari', value: 'Kendari' },
  { label: 'Kende', value: 'Kende' },
  { label: 'Kenderes', value: 'Kenderes' },
  { label: 'Kendrapara', value: 'Kendrapara' },
  { label: 'Kenduadih', value: 'Kenduadih' },
  { label: 'Kendujhar', value: 'Kendujhar' },
  { label: 'Kenduskeag', value: 'Kenduskeag' },
  { label: 'Keneba', value: 'Keneba' },
  { label: 'Kenedy', value: 'Kenedy' },
  { label: 'Kenedy County', value: 'Kenedy County' },
  { label: 'Kenema', value: 'Kenema' },
  { label: 'Kenema District', value: 'Kenema District' },
  { label: 'Kengkhar', value: 'Kengkhar' },
  { label: 'Kengyel', value: 'Kengyel' },
  { label: 'Kengyuan', value: 'Kengyuan' },
  { label: 'Kenhardt', value: 'Kenhardt' },
  { label: 'Kenhorst', value: 'Kenhorst' },
  { label: 'Kenilworth', value: 'Kenilworth' },
  { label: 'Kenilworth', value: 'Kenilworth' },
  { label: 'Kenilworth', value: 'Kenilworth' },
  { label: 'Kenilworth', value: 'Kenilworth' },
  { label: 'Keningau', value: 'Keningau' },
  { label: 'Kenitra', value: 'Kenitra' },
  { label: 'Kenitra Province', value: 'Kenitra Province' },
  { label: 'Kenly', value: 'Kenly' },
  { label: 'Kenmar', value: 'Kenmar' },
  { label: 'Kenmare', value: 'Kenmare' },
  { label: 'Kenmare', value: 'Kenmare' },
  { label: 'Kenmore', value: 'Kenmore' },
  { label: 'Kenmore', value: 'Kenmore' },
  { label: 'Kenmore', value: 'Kenmore' },
  { label: 'Kenmore Hills', value: 'Kenmore Hills' },
  { label: 'Kenn', value: 'Kenn' },
  { label: 'Kennebec', value: 'Kennebec' },
  { label: 'Kennebec County', value: 'Kennebec County' },
  { label: 'Kennebunk', value: 'Kennebunk' },
  { label: 'Kennebunkport', value: 'Kennebunkport' },
  { label: 'Kennedale', value: 'Kennedale' },
  { label: 'Kennedy', value: 'Kennedy' },
  { label: 'Kennedy Highway/Laborie Bypass', value: 'Kennedy Highway/Laborie Bypass' },
  { label: 'Kennelbach', value: 'Kennelbach' },
  { label: 'Kenner', value: 'Kenner' },
  { label: 'Kennesaw', value: 'Kennesaw' },
  { label: 'Kenneth City', value: 'Kenneth City' },
  { label: 'Kennett', value: 'Kennett' },
  { label: 'Kennett Square', value: 'Kennett Square' },
  { label: 'Kennewick', value: 'Kennewick' },
  { label: 'Kennington', value: 'Kennington' },
  { label: 'Kennington', value: 'Kennington' },
  { label: 'Kennoway', value: 'Kennoway' },
  { label: 'Kenora', value: 'Kenora' },
  { label: 'Kenosha', value: 'Kenosha' },
  { label: 'Kenosha County', value: 'Kenosha County' },
  { label: 'Kenova', value: 'Kenova' },
  { label: 'Kenscoff', value: 'Kenscoff' },
  { label: 'Kensett', value: 'Kensett' },
  { label: 'Kensington', value: 'Kensington' },
  { label: 'Kensington', value: 'Kensington' },
  { label: 'Kensington', value: 'Kensington' },
  { label: 'Kensington', value: 'Kensington' },
  { label: 'Kensington', value: 'Kensington' },
  { label: 'Kensington', value: 'Kensington' },
  { label: 'Kensington', value: 'Kensington' },
  { label: 'Kensington', value: 'Kensington' },
  { label: 'Kensington', value: 'Kensington' },
  { label: 'Kensington Gardens', value: 'Kensington Gardens' },
  { label: 'Kensington Grove', value: 'Kensington Grove' },
  { label: 'Kensington Park', value: 'Kensington Park' },
  { label: 'Kensington Park', value: 'Kensington Park' },
  { label: 'Kensworth', value: 'Kensworth' },
  { label: 'Kent', value: 'Kent' },
  { label: 'Kent', value: 'Kent' },
  { label: 'Kent', value: 'Kent' },
  { label: 'Kent', value: 'Kent' },
  { label: 'Kent', value: 'Kent' },
  { label: 'Kent Acres', value: 'Kent Acres' },
  { label: 'Kent City', value: 'Kent City' },
  { label: 'Kent County', value: 'Kent County' },
  { label: 'Kent County', value: 'Kent County' },
  { label: 'Kent County', value: 'Kent County' },
  { label: 'Kent County', value: 'Kent County' },
  { label: 'Kent County', value: 'Kent County' },
  { label: 'Kent Shire', value: 'Kent Shire' },
  { label: 'Kent Town', value: 'Kent Town' },
  { label: 'Kentau', value: 'Kentau' },
  { label: 'Kentfield', value: 'Kentfield' },
  { label: 'Kenthurst', value: 'Kenthurst' },
  { label: 'Kentish', value: 'Kentish' },
  { label: 'Kentland', value: 'Kentland' },
  { label: 'Kenton', value: 'Kenton' },
  { label: 'Kenton', value: 'Kenton' },
  { label: 'Kenton', value: 'Kenton' },
  { label: 'Kenton', value: 'Kenton' },
  { label: 'Kenton County', value: 'Kenton County' },
  { label: 'Kentrí', value: 'Kentrí' },
  { label: 'Kentstown', value: 'Kentstown' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Kentville', value: 'Kentville' },
  { label: 'Kentwood', value: 'Kentwood' },
  { label: 'Kentwood', value: 'Kentwood' },
  { label: 'Kenvil', value: 'Kenvil' },
  { label: 'Kenwick', value: 'Kenwick' },
  { label: 'Kenwood', value: 'Kenwood' },
  { label: 'Kenwood', value: 'Kenwood' },
  { label: 'Kenwood', value: 'Kenwood' },
  { label: 'Kenwood', value: 'Kenwood' },
  { label: 'Kenyon', value: 'Kenyon' },
  { label: 'Kenzhe', value: 'Kenzhe' },
  { label: 'Kenzingen', value: 'Kenzingen' },
  { label: 'Kēōkea', value: 'Kēōkea' },
  { label: 'Keokuk', value: 'Keokuk' },
  { label: 'Keokuk County', value: 'Keokuk County' },
  { label: 'Keosauqua', value: 'Keosauqua' },
  { label: 'Kepala Batas', value: 'Kepala Batas' },
  { label: 'Kepanjen', value: 'Kepanjen' },
  { label: 'Keperra', value: 'Keperra' },
  { label: 'Kepez', value: 'Kepez' },
  { label: 'Kępice', value: 'Kępice' },
  { label: 'Kępie Żaleszańskie', value: 'Kępie Żaleszańskie' },
  { label: 'Kępno', value: 'Kępno' },
  { label: 'Kepnock', value: 'Kepnock' },
  { label: 'Kepsut', value: 'Kepsut' },
  { label: 'Keramotí', value: 'Keramotí' },
  { label: 'Kerang', value: 'Kerang' },
  { label: 'Kerasochóri', value: 'Kerasochóri' },
  { label: 'Keratéa', value: 'Keratéa' },
  { label: 'Keratsíni', value: 'Keratsíni' },
  { label: 'Kerava', value: 'Kerava' },
  { label: 'Kerbach', value: 'Kerbach' },
  { label: 'Kerbakhiar', value: 'Kerbakhiar' },
  { label: 'Kerben', value: 'Kerben' },
  { label: 'Kerċem', value: 'Kerċem' },
  { label: 'Kerch', value: 'Kerch' },
  { label: 'Kerchenska Miska Rada', value: 'Kerchenska Miska Rada' },
  { label: 'Kerchevskiy', value: 'Kerchevskiy' },
  { label: 'Kerckebosch', value: 'Kerckebosch' },
  { label: 'Kerecsend', value: 'Kerecsend' },
  { label: 'Kerekegyháza', value: 'Kerekegyháza' },
  { label: 'Kerékteleki', value: 'Kerékteleki' },
  { label: 'Kerema', value: 'Kerema' },
  { label: 'Keren', value: 'Keren' },
  { label: 'Kerencun', value: 'Kerencun' },
  { label: 'Kerend-E Gharb', value: 'Kerend-E Gharb' },
  { label: 'Kerens', value: 'Kerens' },
  { label: 'Kerensheide', value: 'Kerensheide' },
  { label: 'Kerepes', value: 'Kerepes' },
  { label: 'Keresley', value: 'Keresley' },
  { label: 'Kerestinec', value: 'Kerestinec' },
  { label: 'Kerewan', value: 'Kerewan' },
  { label: 'Kerhonkson', value: 'Kerhonkson' },
  { label: 'Kericho', value: 'Kericho' },
  { label: 'Kerikeri', value: 'Kerikeri' },
  { label: 'Kerimäki', value: 'Kerimäki' },
  { label: 'Kerkbuurt', value: 'Kerkbuurt' },
  { label: 'Kerkdriel', value: 'Kerkdriel' },
  { label: 'Kerkehout', value: 'Kerkehout' },
  { label: 'Kerkelanden', value: 'Kerkelanden' },
  { label: 'Kerkrade', value: 'Kerkrade' },
  { label: 'Kerlouan', value: 'Kerlouan' },
  { label: 'Kerman', value: 'Kerman' },
  { label: 'Kerman', value: 'Kerman' },
  { label: 'Kermanshah', value: 'Kermanshah' },
  { label: 'Kermen', value: 'Kermen' },
  { label: 'Kermit', value: 'Kermit' },
  { label: 'Kern County', value: 'Kern County' },
  { label: 'Kernersville', value: 'Kernersville' },
  { label: 'Kernilis', value: 'Kernilis' },
  { label: 'Kerns', value: 'Kerns' },
  { label: 'Kernville', value: 'Kernville' },
  { label: 'Keroka', value: 'Keroka' },
  { label: 'Kérouané', value: 'Kérouané' },
  { label: 'Kerouane Prefecture', value: 'Kerouane Prefecture' },
  { label: 'Kerowagi', value: 'Kerowagi' },
  { label: 'Kerpen', value: 'Kerpen' },
  { label: 'Kerr County', value: 'Kerr County' },
  { label: 'Kerrobert', value: 'Kerrobert' },
  { label: 'Kerrouchen', value: 'Kerrouchen' },
  { label: 'Kerrville', value: 'Kerrville' },
  { label: 'Kersaint-Plabennec', value: 'Kersaint-Plabennec' },
  { label: 'Kersbrook', value: 'Kersbrook' },
  { label: 'Kerschoten', value: 'Kerschoten' },
  { label: 'Kersey', value: 'Kersey' },
  { label: 'Kershaw', value: 'Kershaw' },
  { label: 'Kershaw County', value: 'Kershaw County' },
  { label: 'Kerteminde', value: 'Kerteminde' },
  { label: 'Kerteminde Kommune', value: 'Kerteminde Kommune' },
  { label: 'Kertih', value: 'Kertih' },
  { label: 'Kertosono', value: 'Kertosono' },
  { label: 'Kertzfeld', value: 'Kertzfeld' },
  { label: 'Kerugoya', value: 'Kerugoya' },
  { label: 'Kerur', value: 'Kerur' },
  { label: 'Kerva', value: 'Kerva' },
  { label: 'Kervignac', value: 'Kervignac' },
  { label: 'Kerwada', value: 'Kerwada' },
  { label: 'Kerzenheim', value: 'Kerzenheim' },
  { label: 'Kerzers', value: 'Kerzers' },
  { label: 'Kesälahti', value: 'Kesälahti' },
  { label: 'Keşan', value: 'Keşan' },
  { label: 'Keşap', value: 'Keşap' },
  { label: 'Kesb', value: 'Kesb' },
  { label: 'Kesbewa', value: 'Kesbewa' },
  { label: 'Kesennuma', value: 'Kesennuma' },
  { label: 'Kesennuma Shi', value: 'Kesennuma Shi' },
  { label: 'Kesgrave', value: 'Kesgrave' },
  { label: 'Keshabpur', value: 'Keshabpur' },
  { label: 'Keshavarz', value: 'Keshavarz' },
  { label: 'Keshena', value: 'Keshena' },
  { label: 'Keshod', value: 'Keshod' },
  { label: 'Keshorai Patan', value: 'Keshorai Patan' },
  { label: 'Keshupur', value: 'Keshupur' },
  { label: 'Kesinga', value: 'Kesinga' },
  { label: 'Keskastel', value: 'Keskastel' },
  { label: 'Keskin', value: 'Keskin' },
  { label: 'Kesova Gora', value: 'Kesova Gora' },
  { label: 'Kesovogorskiy Rayon', value: 'Kesovogorskiy Rayon' },
  { label: 'Kęsowo', value: 'Kęsowo' },
  { label: 'Kesra', value: 'Kesra' },
  { label: 'Kessel', value: 'Kessel' },
  { label: 'Kessin', value: 'Kessin' },
  { label: 'Kessingland', value: 'Kessingland' },
  { label: 'Kestel', value: 'Kestel' },
  { label: 'Kesteren', value: 'Kesteren' },
  { label: 'Kestilä', value: 'Kestilä' },
  { label: 'Keswick', value: 'Keswick' },
  { label: 'Keswick', value: 'Keswick' },
  { label: 'Keswick Ridge', value: 'Keswick Ridge' },
  { label: 'Keszthely', value: 'Keszthely' },
  { label: 'Keszthelyi Járás', value: 'Keszthelyi Járás' },
  { label: 'Kesztölc', value: 'Kesztölc' },
  { label: 'Keta', value: 'Keta' },
  { label: 'Ketalem And Sadat Shahr', value: 'Ketalem And Sadat Shahr' },
  { label: 'Ketanggungan', value: 'Ketanggungan' },
  { label: 'Ketapang', value: 'Ketapang' },
  { label: 'Ketchikan', value: 'Ketchikan' },
  { label: 'Ketchikan Gateway Borough', value: 'Ketchikan Gateway Borough' },
  { label: 'Ketchum', value: 'Ketchum' },
  { label: 'Kete Krachi', value: 'Kete Krachi' },
  { label: 'Kétegyháza', value: 'Kétegyháza' },
  { label: 'Kéthely', value: 'Kéthely' },
  { label: 'Keti Bandar', value: 'Keti Bandar' },
  { label: 'Kétou', value: 'Kétou' },
  { label: 'Ketovo', value: 'Ketovo' },
  { label: 'Kętrzyn', value: 'Kętrzyn' },
  { label: 'Ketsch', value: 'Ketsch' },
  { label: 'Kettenkamp', value: 'Kettenkamp' },
  { label: 'Kettering', value: 'Kettering' },
  { label: 'Kettering', value: 'Kettering' },
  { label: 'Kettering', value: 'Kettering' },
  { label: 'Kettershausen', value: 'Kettershausen' },
  { label: 'Kettig', value: 'Kettig' },
  { label: 'Kettle Falls', value: 'Kettle Falls' },
  { label: 'Kettleman City', value: 'Kettleman City' },
  { label: 'Ketton', value: 'Ketton' },
  { label: 'Kęty', value: 'Kęty' },
  { label: 'Ketzin', value: 'Ketzin' },
  { label: 'Keuka Park', value: 'Keuka Park' },
  { label: 'Keuruu', value: 'Keuruu' },
  { label: 'Keutschach Am See', value: 'Keutschach Am See' },
  { label: 'Kevelaer', value: 'Kevelaer' },
  { label: 'Kevermes', value: 'Kevermes' },
  { label: 'Kevsala', value: 'Kevsala' },
  { label: 'Kew', value: 'Kew' },
  { label: 'Kew', value: 'Kew' },
  { label: 'Kew East', value: 'Kew East' },
  { label: 'Kew Gardens', value: 'Kew Gardens' },
  { label: 'Kew Gardens Hills', value: 'Kew Gardens Hills' },
  { label: 'Kewanee', value: 'Kewanee' },
  { label: 'Kewarra Beach', value: 'Kewarra Beach' },
  { label: 'Kewaskum', value: 'Kewaskum' },
  { label: 'Kewaunee', value: 'Kewaunee' },
  { label: 'Kewaunee County', value: 'Kewaunee County' },
  { label: 'Kewdale', value: 'Kewdale' },
  { label: 'Keweenaw County', value: 'Keweenaw County' },
  { label: 'Key Biscayne', value: 'Key Biscayne' },
  { label: 'Key Center', value: 'Key Center' },
  { label: 'Key Largo', value: 'Key Largo' },
  { label: 'Key Vista', value: 'Key Vista' },
  { label: 'Key West', value: 'Key West' },
  { label: 'Keya Paha County', value: 'Keya Paha County' },
  { label: 'Keyes', value: 'Keyes' },
  { label: 'Keyingham', value: 'Keyingham' },
  { label: 'Keykaleh', value: 'Keykaleh' },
  { label: 'Keyni Sayeh', value: 'Keyni Sayeh' },
  { label: 'Keynsham', value: 'Keynsham' },
  { label: 'Keyport', value: 'Keyport' },
  { label: 'Keysborough', value: 'Keysborough' },
  { label: 'Keyser', value: 'Keyser' },
  { label: 'Keystone', value: 'Keystone' },
  { label: 'Keystone', value: 'Keystone' },
  { label: 'Keystone', value: 'Keystone' },
  { label: 'Keystone Heights', value: 'Keystone Heights' },
  { label: 'Keytesville', value: 'Keytesville' },
  { label: 'Keyworth', value: 'Keyworth' },
  { label: 'Kez', value: 'Kez' },
  { label: 'Kežmarok', value: 'Kežmarok' },
  { label: 'Kfar Saba', value: 'Kfar Saba' },
  { label: 'Kfar Yasif', value: 'Kfar Yasif' },
  { label: 'Khabar Arkhi', value: 'Khabar Arkhi' },
  { label: 'Khabarovsk', value: 'Khabarovsk' },
  { label: 'Khabarovsk Vtoroy', value: 'Khabarovsk Vtoroy' },
  { label: 'Khabarskiy Rayon', value: 'Khabarskiy Rayon' },
  { label: 'Khabary', value: 'Khabary' },
  { label: 'Khabath Sa`Id', value: 'Khabath Sa`Id' },
  { label: 'Khabb Wa Ash Shaaf', value: 'Khabb Wa Ash Shaaf' },
  { label: 'Khabez', value: 'Khabez' },
  { label: 'Khachrod', value: 'Khachrod' },
  { label: 'Khada', value: 'Khada' },
  { label: 'Khadan Khak', value: 'Khadan Khak' },
  { label: 'Khadki', value: 'Khadki' },
  { label: 'Khadra', value: 'Khadra' },
  { label: 'Khadro', value: 'Khadro' },
  { label: 'Khadyzhensk', value: 'Khadyzhensk' },
  { label: 'Khadzhalmakhi', value: 'Khadzhalmakhi' },
  { label: 'Khaf', value: 'Khaf' },
  { label: 'Khaga', value: 'Khaga' },
  { label: 'Khagaria', value: 'Khagaria' },
  { label: 'Khagaul', value: 'Khagaul' },
  { label: 'Khagrachhari', value: 'Khagrachhari' },
  { label: 'Khailar', value: 'Khailar' },
  { label: 'Khailar', value: 'Khailar' },
  { label: 'Khair', value: 'Khair' },
  { label: 'Khairabad', value: 'Khairabad' },
  { label: 'Khairagarh', value: 'Khairagarh' },
  { label: 'Khairpur', value: 'Khairpur' },
  { label: 'Khairpur Mir’S', value: 'Khairpur Mir’S' },
  { label: 'Khairpur Nathan Shah', value: 'Khairpur Nathan Shah' },
  { label: 'Khairpur Tamiwali', value: 'Khairpur Tamiwali' },
  { label: 'Khaje', value: 'Khaje' },
  { label: 'Khajoo Shahr', value: 'Khajoo Shahr' },
  { label: 'Khajuraho Group Of Monuments', value: 'Khajuraho Group Of Monuments' },
  { label: 'Khakali', value: 'Khakali' },
  { label: 'Khakhea', value: 'Khakhea' },
  { label: 'Khaku', value: 'Khaku' },
  { label: 'Khal’Ch', value: 'Khal’Ch' },
  { label: 'Khalabat', value: 'Khalabat' },
  { label: 'Khalándrion', value: 'Khalándrion' },
  { label: 'Khalapur', value: 'Khalapur' },
  { label: 'Khaled Abad', value: 'Khaled Abad' },
  { label: 'Khaleq Verdi', value: 'Khaleq Verdi' },
  { label: 'Khalfah', value: 'Khalfah' },
  { label: 'Khalifah A City', value: 'Khalifah A City' },
  { label: 'Khalifan', value: 'Khalifan' },
  { label: 'Khalil Abad', value: 'Khalil Abad' },
  { label: 'Khalil Kord', value: 'Khalil Kord' },
  { label: 'Khalil Shahr', value: 'Khalil Shahr' },
  { label: 'Khalilabad', value: 'Khalilabad' },
  { label: 'Khalilabad', value: 'Khalilabad' },
  { label: 'Khāliş', value: 'Khāliş' },
  { label: 'Khalkhal', value: 'Khalkhal' },
  { label: 'Khalkoútsion', value: 'Khalkoútsion' },
  { label: 'Khallar', value: 'Khallar' },
  { label: 'Khallikot', value: 'Khallikot' },
  { label: 'Kham Sakae Saeng', value: 'Kham Sakae Saeng' },
  { label: 'Khamamatyurt', value: 'Khamamatyurt' },
  { label: 'Khamaneh', value: 'Khamaneh' },
  { label: 'Khamanon', value: 'Khamanon' },
  { label: 'Khamaria', value: 'Khamaria' },
  { label: 'Khambhalia', value: 'Khambhalia' },
  { label: 'Khambhat', value: 'Khambhat' },
  { label: 'Khambi-Irze', value: 'Khambi-Irze' },
  { label: 'Khamgaon', value: 'Khamgaon' },
  { label: 'Khamharia', value: 'Khamharia' },
  { label: 'Khamir', value: 'Khamir' },
  { label: 'Khamir', value: 'Khamir' },
  { label: 'Khamis Mushait', value: 'Khamis Mushait' },
  { label: 'Khammam', value: 'Khammam' },
  { label: 'Khan Châmkar Mon', value: 'Khan Châmkar Mon' },
  { label: 'Khan Dângkaô', value: 'Khan Dângkaô' },
  { label: 'Khan Duŏn Pénh', value: 'Khan Duŏn Pénh' },
  { label: 'Khan Méan Chey', value: 'Khan Méan Chey' },
  { label: 'Khan Na Yao', value: 'Khan Na Yao' },
  { label: 'Khan Russey Keo', value: 'Khan Russey Keo' },
  { label: 'Khan Saen Sokh', value: 'Khan Saen Sokh' },
  { label: 'Khan Sala Krau', value: 'Khan Sala Krau' },
  { label: 'Khān Shaykhūn', value: 'Khān Shaykhūn' },
  { label: 'Khanabad', value: 'Khanabad' },
  { label: 'Khanapur', value: 'Khanapur' },
  { label: 'Khanapur', value: 'Khanapur' },
  { label: 'Khanāşir', value: 'Khanāşir' },
  { label: 'Khānbebin', value: 'Khānbebin' },
  { label: 'Khandagayty', value: 'Khandagayty' },
  { label: 'Khandala', value: 'Khandala' },
  { label: 'Khandallah', value: 'Khandallah' },
  { label: 'Khandela', value: 'Khandela' },
  { label: 'Khandowa', value: 'Khandowa' },
  { label: 'Khandūd', value: 'Khandūd' },
  { label: 'Khandwa', value: 'Khandwa' },
  { label: 'Khandyga', value: 'Khandyga' },
  { label: 'Khanewal', value: 'Khanewal' },
  { label: 'Khanezenian', value: 'Khanezenian' },
  { label: 'Khanfir', value: 'Khanfir' },
  { label: 'Khanga Dogran', value: 'Khanga Dogran' },
  { label: 'Khangaon', value: 'Khangaon' },
  { label: 'Khangarh', value: 'Khangarh' },
  { label: 'Khaniman', value: 'Khaniman' },
  { label: 'Khanino', value: 'Khanino' },
  { label: 'Khankala', value: 'Khankala' },
  { label: 'Khanna', value: 'Khanna' },
  { label: 'Khanook', value: 'Khanook' },
  { label: 'Khanpur', value: 'Khanpur' },
  { label: 'Khanpur', value: 'Khanpur' },
  { label: 'Khanpur', value: 'Khanpur' },
  { label: 'Khanpur Mahar', value: 'Khanpur Mahar' },
  { label: 'Khansar', value: 'Khansar' },
  { label: 'Khanskaya', value: 'Khanskaya' },
  { label: 'Khantaū', value: 'Khantaū' },
  { label: 'Khanty-Mansiysk', value: 'Khanty-Mansiysk' },
  { label: 'Khanu Woralaksaburi', value: 'Khanu Woralaksaburi' },
  { label: 'Khao Wong', value: 'Khao Wong' },
  { label: 'Khao Yoi', value: 'Khao Yoi' },
  { label: 'Khapa', value: 'Khapa' },
  { label: 'Kharab Al Marashi', value: 'Kharab Al Marashi' },
  { label: 'Kharabali', value: 'Kharabali' },
  { label: 'Kharagauli', value: 'Kharagauli' },
  { label: 'Kharagpur', value: 'Kharagpur' },
  { label: 'Kharagpur', value: 'Kharagpur' },
  { label: 'Kharajoo', value: 'Kharajoo' },
  { label: 'Kharakvasla', value: 'Kharakvasla' },
  { label: 'Kharameh', value: 'Kharameh' },
  { label: 'Kharan', value: 'Kharan' },
  { label: 'Khārān District', value: 'Khārān District' },
  { label: 'Kharar', value: 'Kharar' },
  { label: 'Kharar', value: 'Kharar' },
  { label: 'Kharba', value: 'Kharba' },
  { label: 'Kharda', value: 'Kharda' },
  { label: 'Khardaha', value: 'Khardaha' },
  { label: 'Kharela', value: 'Kharela' },
  { label: 'Kharg', value: 'Kharg' },
  { label: 'Khargapur', value: 'Khargapur' },
  { label: 'Kharghar', value: 'Kharghar' },
  { label: 'Khargone', value: 'Khargone' },
  { label: 'Khargupur', value: 'Khargupur' },
  { label: 'Kharhial', value: 'Kharhial' },
  { label: 'Kharian', value: 'Kharian' },
  { label: 'Kharif', value: 'Kharif' },
  { label: 'Kharitonovo', value: 'Kharitonovo' },
  { label: 'Kharjā', value: 'Kharjā' },
  { label: 'Khark', value: 'Khark' },
  { label: 'Kharkhauda', value: 'Kharkhauda' },
  { label: 'Kharkhauda', value: 'Kharkhauda' },
  { label: 'Kharkhorin', value: 'Kharkhorin' },
  { label: 'Kharkiv', value: 'Kharkiv' },
  { label: 'Kharlu', value: 'Kharlu' },
  { label: 'Kharod', value: 'Kharod' },
  { label: 'Kharovsk', value: 'Kharovsk' },
  { label: 'Kharovskiy Rayon', value: 'Kharovskiy Rayon' },
  { label: 'Kharp', value: 'Kharp' },
  { label: 'Kharsawan', value: 'Kharsawan' },
  { label: 'Kharsia', value: 'Kharsia' },
  { label: 'Kharsundi', value: 'Kharsundi' },
  { label: 'Khartoum', value: 'Khartoum' },
  { label: 'Khartsyz’K', value: 'Khartsyz’K' },
  { label: 'Kharupatia', value: 'Kharupatia' },
  { label: 'Kharv', value: 'Kharv' },
  { label: 'Kharvana', value: 'Kharvana' },
  { label: 'Khasab', value: 'Khasab' },
  { label: 'Khasanskiy Rayon', value: 'Khasanskiy Rayon' },
  { label: 'Khasavyurt', value: 'Khasavyurt' },
  { label: 'Khasavyurtovskiy Rayon', value: 'Khasavyurtovskiy Rayon' },
  { label: 'Khash', value: 'Khash' },
  { label: 'Khāsh', value: 'Khāsh' },
  { label: 'Khasht’Arrak', value: 'Khasht’Arrak' },
  { label: 'Khashuri', value: 'Khashuri' },
  { label: 'Khatam', value: 'Khatam' },
  { label: 'Khatanga', value: 'Khatanga' },
  { label: 'Khatassy', value: 'Khatassy' },
  { label: 'Khatauli', value: 'Khatauli' },
  { label: 'Khategaon', value: 'Khategaon' },
  { label: 'Khatib Saudi Arabia', value: 'Khatib Saudi Arabia' },
  { label: 'Khatima', value: 'Khatima' },
  { label: 'Khatonabad', value: 'Khatonabad' },
  { label: 'Khatra', value: 'Khatra' },
  { label: 'Khatsyezhyna', value: 'Khatsyezhyna' },
  { label: 'Khatukay', value: 'Khatukay' },
  { label: 'Khaur', value: 'Khaur' },
  { label: 'Khavaran', value: 'Khavaran' },
  { label: 'Khavas Kuh', value: 'Khavas Kuh' },
  { label: 'Khavda', value: 'Khavda' },
  { label: 'Khawhai', value: 'Khawhai' },
  { label: 'Khawr Fakkān', value: 'Khawr Fakkān' },
  { label: 'Khaybullinskiy Rayon', value: 'Khaybullinskiy Rayon' },
  { label: 'Khayf Fadil', value: 'Khayf Fadil' },
  { label: 'Khayran Al Muharraq', value: 'Khayran Al Muharraq' },
  { label: 'Khazar', value: 'Khazar' },
  { label: 'Khebda', value: 'Khebda' },
  { label: 'Khed', value: 'Khed' },
  { label: 'Kheda', value: 'Kheda' },
  { label: 'Khedbrahma', value: 'Khedbrahma' },
  { label: 'Khedoi', value: 'Khedoi' },
  { label: 'Khekra', value: 'Khekra' },
  { label: 'Khelvachauri', value: 'Khelvachauri' },
  { label: 'Khelyulya', value: 'Khelyulya' },
  { label: 'Khemis El Khechna', value: 'Khemis El Khechna' },
  { label: 'Khemis Miliana', value: 'Khemis Miliana' },
  { label: 'Khemisset', value: 'Khemisset' },
  { label: 'Khemkaran', value: 'Khemkaran' },
  { label: 'Khenchela', value: 'Khenchela' },
  { label: 'Khenejin', value: 'Khenejin' },
  { label: 'Khenifra', value: 'Khenifra' },
  { label: 'Kheradmand', value: 'Kheradmand' },
  { label: 'Kherali', value: 'Kherali' },
  { label: 'Kheralu', value: 'Kheralu' },
  { label: 'Kherameh', value: 'Kherameh' },
  { label: 'Kheri', value: 'Kheri' },
  { label: 'Kheri Sampla', value: 'Kheri Sampla' },
  { label: 'Kherson', value: 'Kherson' },
  { label: 'Khesht', value: 'Khesht' },
  { label: 'Khetia', value: 'Khetia' },
  { label: 'Khetri', value: 'Khetri' },
  { label: 'Khewra', value: 'Khewra' },
  { label: 'Kheyr Qoli', value: 'Kheyr Qoli' },
  { label: 'Khezrabad', value: 'Khezrabad' },
  { label: 'Khezri Dashtebayaz', value: 'Khezri Dashtebayaz' },
  { label: 'Khilchipur', value: 'Khilchipur' },
  { label: 'Khiliomódhi', value: 'Khiliomódhi' },
  { label: 'Khilok', value: 'Khilok' },
  { label: 'Khimār', value: 'Khimār' },
  { label: 'Khimki', value: 'Khimki' },
  { label: 'Khimki Urban Okrug', value: 'Khimki Urban Okrug' },
  { label: 'Khingansk', value: 'Khingansk' },
  { label: 'Khirdalan', value: 'Khirdalan' },
  { label: 'Khiri Mat', value: 'Khiri Mat' },
  { label: 'Khirkiyan', value: 'Khirkiyan' },
  { label: 'Khislavichi', value: 'Khislavichi' },
  { label: 'Khitokha', value: 'Khitokha' },
  { label: 'Khiv', value: 'Khiv' },
  { label: 'Khivskiy Rayon', value: 'Khivskiy Rayon' },
  { label: 'Khiwa', value: 'Khiwa' },
  { label: 'Khlevenskiy Rayon', value: 'Khlevenskiy Rayon' },
  { label: 'Khlevnoye', value: 'Khlevnoye' },
  { label: 'Khlong Khuean', value: 'Khlong Khuean' },
  { label: 'Khlong Luang', value: 'Khlong Luang' },
  { label: 'Khlong Sam Wa', value: 'Khlong Sam Wa' },
  { label: 'Khlong San', value: 'Khlong San' },
  { label: 'Khlong Toei', value: 'Khlong Toei' },
  { label: 'Khlong Yai', value: 'Khlong Yai' },
  { label: 'Khlung', value: 'Khlung' },
  { label: 'Khmel’Nyts’Kyy Rayon', value: 'Khmel’Nyts’Kyy Rayon' },
  { label: 'Khmelnytskyi', value: 'Khmelnytskyi' },
  { label: 'Khmilnyk', value: 'Khmilnyk' },
  { label: 'Khndzoresk', value: 'Khndzoresk' },
  { label: 'Khobar', value: 'Khobar' },
  { label: 'Khobi', value: 'Khobi' },
  { label: 'Khoda Afarin', value: 'Khoda Afarin' },
  { label: 'Khodabandeh', value: 'Khodabandeh' },
  { label: 'Khodaky', value: 'Khodaky' },
  { label: 'Khodasy', value: 'Khodasy' },
  { label: 'Khodoriv', value: 'Khodoriv' },
  { label: 'Khodz’', value: 'Khodz’' },
  { label: 'Khodzha-Maston', value: 'Khodzha-Maston' },
  { label: 'Khodzhi-Gasan', value: 'Khodzhi-Gasan' },
  { label: 'Khokhlovo', value: 'Khokhlovo' },
  { label: 'Khokhryaki', value: 'Khokhryaki' },
  { label: 'Kholbon', value: 'Kholbon' },
  { label: 'Kholm', value: 'Kholm' },
  { label: 'Kholmogorskiy Rayon', value: 'Kholmogorskiy Rayon' },
  { label: 'Kholmogory', value: 'Kholmogory' },
  { label: 'Kholmovka', value: 'Kholmovka' },
  { label: 'Kholmsk', value: 'Kholmsk' },
  { label: 'Kholmskiy', value: 'Kholmskiy' },
  { label: 'Kholmskiy Rayon', value: 'Kholmskiy Rayon' },
  { label: 'Kholmy', value: 'Kholmy' },
  { label: 'Kholm-Zhirkovskiy', value: 'Kholm-Zhirkovskiy' },
  { label: 'Kholodnyy', value: 'Kholodnyy' },
  { label: 'Kholopenichi', value: 'Kholopenichi' },
  { label: 'Kholtoson', value: 'Kholtoson' },
  { label: 'Khomam', value: 'Khomam' },
  { label: 'Khomarlu', value: 'Khomarlu' },
  { label: 'Khombole', value: 'Khombole' },
  { label: 'Khomein', value: 'Khomein' },
  { label: 'Khomeygan', value: 'Khomeygan' },
  { label: 'Khomeyn', value: 'Khomeyn' },
  { label: 'Khomeyni Shahr', value: 'Khomeyni Shahr' },
  { label: 'Khomutovka', value: 'Khomutovka' },
  { label: 'Khomutovo', value: 'Khomutovo' },
  { label: 'Khomutovo', value: 'Khomutovo' },
  { label: 'Khon Buri', value: 'Khon Buri' },
  { label: 'Khon Kaen', value: 'Khon Kaen' },
  { label: 'Khondab', value: 'Khondab' },
  { label: 'Khoni', value: 'Khoni' },
  { label: 'Khoni', value: 'Khoni' },
  { label: 'Khonj', value: 'Khonj' },
  { label: 'Khonkholoy', value: 'Khonkholoy' },
  { label: 'Khonsa', value: 'Khonsa' },
  { label: 'Khoome Zar', value: 'Khoome Zar' },
  { label: 'Khoozi', value: 'Khoozi' },
  { label: 'Khopoli', value: 'Khopoli' },
  { label: 'Khor', value: 'Khor' },
  { label: 'Khor Fakkan', value: 'Khor Fakkan' },
  { label: 'Khordha', value: 'Khordha' },
  { label: 'Khorinsk', value: 'Khorinsk' },
  { label: 'Khorixas', value: 'Khorixas' },
  { label: 'Khorixas Constituency', value: 'Khorixas Constituency' },
  { label: 'Khorlovo', value: 'Khorlovo' },
  { label: 'Khormoj', value: 'Khormoj' },
  { label: 'Khorol', value: 'Khorol' },
  { label: 'Khorol’', value: 'Khorol’' },
  { label: 'Khoronk’', value: 'Khoronk’' },
  { label: 'Khorosheve', value: 'Khorosheve' },
  { label: 'Khoroshëvo-Mnevniki', value: 'Khoroshëvo-Mnevniki' },
  { label: 'Khoroshiv', value: 'Khoroshiv' },
  { label: 'Khoroshiv Raion', value: 'Khoroshiv Raion' },
  { label: 'Khorostkiv', value: 'Khorostkiv' },
  { label: 'Khorramabad', value: 'Khorramabad' },
  { label: 'Khorramabad', value: 'Khorramabad' },
  { label: 'Khorrambid', value: 'Khorrambid' },
  { label: 'Khorramdarreh', value: 'Khorramdarreh' },
  { label: 'Khorramdasht', value: 'Khorramdasht' },
  { label: 'Khorramshahr', value: 'Khorramshahr' },
  { label: 'Khorugh', value: 'Khorugh' },
  { label: 'Khorusi-Ye Jonubi', value: 'Khorusi-Ye Jonubi' },
  { label: 'Khorzugh', value: 'Khorzugh' },
  { label: 'Khoshkebijar', value: 'Khoshkebijar' },
  { label: 'Khoshkrud', value: 'Khoshkrud' },
  { label: 'Khoshroud Pey', value: 'Khoshroud Pey' },
  { label: 'Khosrowshah', value: 'Khosrowshah' },
  { label: 'Khōst', value: 'Khōst' },
  { label: 'Khosta', value: 'Khosta' },
  { label: 'Khotang', value: 'Khotang' },
  { label: 'Khotiv', value: 'Khotiv' },
  { label: 'Khotkovo', value: 'Khotkovo' },
  { label: 'Khotsimsk', value: 'Khotsimsk' },
  { label: 'Khotsimski Rayon', value: 'Khotsimski Rayon' },
  { label: 'Khotyn', value: 'Khotyn' },
  { label: 'Khotynets', value: 'Khotynets' },
  { label: 'Khour', value: 'Khour' },
  { label: 'Khouribga', value: 'Khouribga' },
  { label: 'Khouribga Province', value: 'Khouribga Province' },
  { label: 'Khovd', value: 'Khovd' },
  { label: 'Khovu-Aksy', value: 'Khovu-Aksy' },
  { label: 'Khowai', value: 'Khowai' },
  { label: 'Khowshab', value: 'Khowshab' },
  { label: 'Khowy', value: 'Khowy' },
  { label: 'Khoy', value: 'Khoy' },
  { label: 'Khoyniki', value: 'Khoyniki' },
  { label: 'Khrabrovo', value: 'Khrabrovo' },
  { label: 'Khrebtovaya', value: 'Khrebtovaya' },
  { label: 'Khrenovoye', value: 'Khrenovoye' },
  { label: 'Khromtau', value: 'Khromtau' },
  { label: 'Khryashchevka', value: 'Khryashchevka' },
  { label: 'Khrystynivka', value: 'Khrystynivka' },
  { label: 'Khuchni', value: 'Khuchni' },
  { label: 'Khudaganj', value: 'Khudaganj' },
  { label: 'Khudoyelanskoye', value: 'Khudoyelanskoye' },
  { label: 'Khudumelapye', value: 'Khudumelapye' },
  { label: 'Khŭjand', value: 'Khŭjand' },
  { label: 'Khŭjaobod Tumani', value: 'Khŭjaobod Tumani' },
  { label: 'Khŭjayli', value: 'Khŭjayli' },
  { label: 'Khujner', value: 'Khujner' },
  { label: 'Khuldabad', value: 'Khuldabad' },
  { label: 'Khulimsunt', value: 'Khulimsunt' },
  { label: 'Khulm', value: 'Khulm' },
  { label: 'Khulna ', value: 'Khulna ' },
  { label: 'Khulo', value: 'Khulo' },
  { label: 'Khumalag', value: 'Khumalag' },
  { label: 'Khumrah', value: 'Khumrah' },
  { label: 'Khumsiyah', value: 'Khumsiyah' },
  { label: 'Khunti', value: 'Khunti' },
  { label: 'Khunzakh', value: 'Khunzakh' },
  { label: 'Khunzakhskiy Rayon', value: 'Khunzakhskiy Rayon' },
  { label: 'Khur', value: 'Khur' },
  { label: 'Khurai', value: 'Khurai' },
  { label: 'Khurba', value: 'Khurba' },
  { label: 'Khurda', value: 'Khurda' },
  { label: 'Khurja', value: 'Khurja' },
  { label: 'Khurrianwala', value: 'Khurrianwala' },
  { label: 'Khursand', value: 'Khursand' },
  { label: 'Khusf', value: 'Khusf' },
  { label: 'Khushab', value: 'Khushab' },
  { label: 'Khushaym', value: 'Khushaym' },
  { label: 'Khusropur', value: 'Khusropur' },
  { label: 'Khust', value: 'Khust' },
  { label: 'Khust Raion', value: 'Khust Raion' },
  { label: 'Khusts’Ka Mis’Krada', value: 'Khusts’Ka Mis’Krada' },
  { label: 'Khutar', value: 'Khutar' },
  { label: 'Khuzdar', value: 'Khuzdar' },
  { label: 'Khuzdār District', value: 'Khuzdār District' },
  { label: 'Khuzhir', value: 'Khuzhir' },
  { label: 'Khvaf', value: 'Khvaf' },
  { label: 'Khvajeh Hesar', value: 'Khvajeh Hesar' },
  { label: 'Khvajeh Hoseyni', value: 'Khvajeh Hoseyni' },
  { label: 'Khvajeh Kandi', value: 'Khvajeh Kandi' },
  { label: 'Khvalovo', value: 'Khvalovo' },
  { label: 'Khvalynsk', value: 'Khvalynsk' },
  { label: 'Khvalynskiy Rayon', value: 'Khvalynskiy Rayon' },
  { label: 'Khvansar', value: 'Khvansar' },
  { label: 'Khvastovichi', value: 'Khvastovichi' },
  { label: 'Khvastovichskiy Rayon', value: 'Khvastovichskiy Rayon' },
  { label: 'Khvatovka', value: 'Khvatovka' },
  { label: 'Khvorostyanka', value: 'Khvorostyanka' },
  { label: 'Khvoshab-E Sofla', value: 'Khvoshab-E Sofla' },
  { label: 'Khvoynaya', value: 'Khvoynaya' },
  { label: 'Khvoyninskiy Rayon', value: 'Khvoyninskiy Rayon' },
  { label: 'Khwlan', value: 'Khwlan' },
  { label: 'Khymchyn', value: 'Khymchyn' },
  { label: 'Khyriv', value: 'Khyriv' },
  { label: 'Khyzy', value: 'Khyzy' },
  { label: 'Kia Kola', value: 'Kia Kola' },
  { label: 'Kialla', value: 'Kialla' },
  { label: 'Kiama', value: 'Kiama' },
  { label: 'Kiama Downs', value: 'Kiama Downs' },
  { label: 'Kiamba', value: 'Kiamba' },
  { label: 'Kiamba', value: 'Kiamba' },
  { label: 'Kiambu', value: 'Kiambu' },
  { label: 'Kian Shahr', value: 'Kian Shahr' },
  { label: 'Kiang Central', value: 'Kiang Central' },
  { label: 'Kiang East', value: 'Kiang East' },
  { label: 'Kiang West District', value: 'Kiang West District' },
  { label: 'Kiangan', value: 'Kiangan' },
  { label: 'Kiangan', value: 'Kiangan' },
  { label: 'Kiantone', value: 'Kiantone' },
  { label: 'Kiar', value: 'Kiar' },
  { label: 'Kiara', value: 'Kiara' },
  { label: 'Kiasar', value: 'Kiasar' },
  { label: 'Kiashahr', value: 'Kiashahr' },
  { label: 'Kiáto', value: 'Kiáto' },
  { label: 'Kiawah Island', value: 'Kiawah Island' },
  { label: 'Kibæk', value: 'Kibæk' },
  { label: 'Kibaha', value: 'Kibaha' },
  { label: 'Kibakwe', value: 'Kibakwe' },
  { label: 'Kibale', value: 'Kibale' },
  { label: 'Kibangay', value: 'Kibangay' },
  { label: 'Kibara', value: 'Kibara' },
  { label: 'Kibawe', value: 'Kibawe' },
  { label: 'Kibaya', value: 'Kibaya' },
  { label: 'Kibi', value: 'Kibi' },
  { label: 'Kibiti', value: 'Kibiti' },
  { label: 'Kiblawan', value: 'Kiblawan' },
  { label: 'Kiblawan', value: 'Kiblawan' },
  { label: 'Kiblawan', value: 'Kiblawan' },
  { label: 'Kiboga', value: 'Kiboga' },
  { label: 'Kibondo', value: 'Kibondo' },
  { label: 'Kibonsod', value: 'Kibonsod' },
  { label: 'Kibre Mengist', value: 'Kibre Mengist' },
  { label: 'Kıbrıscık', value: 'Kıbrıscık' },
  { label: 'Kibuku District', value: 'Kibuku District' },
  { label: 'Kibungan', value: 'Kibungan' },
  { label: 'Kibungan', value: 'Kibungan' },
  { label: 'Kibungo', value: 'Kibungo' },
  { label: 'Kibureau', value: 'Kibureau' },
  { label: 'Kibuye', value: 'Kibuye' },
  { label: 'Kibworth Harcourt', value: 'Kibworth Harcourt' },
  { label: 'Kičevo', value: 'Kičevo' },
  { label: 'Kichera', value: 'Kichera' },
  { label: 'Kichha', value: 'Kichha' },
  { label: 'Kichmengskiy Gorodok', value: 'Kichmengskiy Gorodok' },
  { label: 'Kichmengsko-Gorodetskiy Rayon', value: 'Kichmengsko-Gorodetskiy Rayon' },
  { label: 'Kidal', value: 'Kidal' },
  { label: 'Kidapawan', value: 'Kidapawan' },
  { label: 'Kidapawan', value: 'Kidapawan' },
  { label: 'Kidatu', value: 'Kidatu' },
  { label: 'Kidder County', value: 'Kidder County' },
  { label: 'Kidderminster', value: 'Kidderminster' },
  { label: 'Kideksha', value: 'Kideksha' },
  { label: 'Kidlington', value: 'Kidlington' },
  { label: 'Kidman Park', value: 'Kidman Park' },
  { label: 'Kidodi', value: 'Kidodi' },
  { label: 'Kidričevo', value: 'Kidričevo' },
  { label: 'Kidsgrove', value: 'Kidsgrove' },
  { label: 'Kidwelly', value: 'Kidwelly' },
  { label: 'Kiebitzreihe', value: 'Kiebitzreihe' },
  { label: 'Kiedrich', value: 'Kiedrich' },
  { label: 'Kiefer', value: 'Kiefer' },
  { label: 'Kiefersfelden', value: 'Kiefersfelden' },
  { label: 'Kiel', value: 'Kiel' },
  { label: 'Kiel', value: 'Kiel' },
  { label: 'Kielanówka', value: 'Kielanówka' },
  { label: 'Kielce', value: 'Kielce' },
  { label: 'Kiełczów', value: 'Kiełczów' },
  { label: 'Kiełpin', value: 'Kiełpin' },
  { label: 'Kien Luong Town', value: 'Kien Luong Town' },
  { label: 'Kienberg', value: 'Kienberg' },
  { label: 'Kierling', value: 'Kierling' },
  { label: 'Kiernozia', value: 'Kiernozia' },
  { label: 'Kierspe', value: 'Kierspe' },
  { label: 'Kieselbronn', value: 'Kieselbronn' },
  { label: 'Kieta', value: 'Kieta' },
  { label: 'Kietrz', value: 'Kietrz' },
  { label: 'Kīevka', value: 'Kīevka' },
  { label: 'Kievskiy', value: 'Kievskiy' },
  { label: 'Kiffa', value: 'Kiffa' },
  { label: 'Kifisiá', value: 'Kifisiá' },
  { label: 'Kifrī', value: 'Kifrī' },
  { label: 'Kigali', value: 'Kigali' },
  { label: 'Kigamboni', value: 'Kigamboni' },
  { label: 'Kiğı', value: 'Kiğı' },
  { label: 'Kigoma', value: 'Kigoma' },
  { label: 'Kigonsera', value: 'Kigonsera' },
  { label: 'Kigorobya', value: 'Kigorobya' },
  { label: 'Kihancha', value: 'Kihancha' },
  { label: 'Kihangara', value: 'Kihangara' },
  { label: 'Kīhei', value: 'Kīhei' },
  { label: 'Kihniö', value: 'Kihniö' },
  { label: 'Kihnu Vald', value: 'Kihnu Vald' },
  { label: 'Kihoku-Chō', value: 'Kihoku-Chō' },
  { label: 'Kihurio', value: 'Kihurio' },
  { label: 'Kiihtelysvaara', value: 'Kiihtelysvaara' },
  { label: 'Kiikala', value: 'Kiikala' },
  { label: 'Kiikka', value: 'Kiikka' },
  { label: 'Kiikoinen', value: 'Kiikoinen' },
  { label: 'Kiili', value: 'Kiili' },
  { label: 'Kiili Vald', value: 'Kiili Vald' },
  { label: 'Kiiminki', value: 'Kiiminki' },
  { label: 'Kiischpelt', value: 'Kiischpelt' },
  { label: 'Kijabe', value: 'Kijabe' },
  { label: 'Kijang', value: 'Kijang' },
  { label: 'Kije', value: 'Kije' },
  { label: 'Kijimadaira', value: 'Kijimadaira' },
  { label: 'Kijini', value: 'Kijini' },
  { label: 'Kijkduin', value: 'Kijkduin' },
  { label: 'Kikerino', value: 'Kikerino' },
  { label: 'Kikinda', value: 'Kikinda' },
  { label: 'Kiknur', value: 'Kiknur' },
  { label: 'Kiknurskiy Rayon', value: 'Kiknurskiy Rayon' },
  { label: 'Kikół', value: 'Kikół' },
  { label: 'Kikori', value: 'Kikori' },
  { label: 'Kikuchi', value: 'Kikuchi' },
  { label: 'Kikuchi Shi', value: 'Kikuchi Shi' },
  { label: 'Kikugawa-Shi', value: 'Kikugawa-Shi' },
  { label: 'Kikuyu', value: 'Kikuyu' },
  { label: 'Kikwit', value: 'Kikwit' },
  { label: 'Kil', value: 'Kil' },
  { label: 'Kil Bhuvanagiri', value: 'Kil Bhuvanagiri' },
  { label: 'Kil’Mez’', value: 'Kil’Mez’' },
  { label: 'Kilaben Bay', value: 'Kilaben Bay' },
  { label: 'Kilafors', value: 'Kilafors' },
  { label: 'Kilan', value: 'Kilan' },
  { label: 'Kīlauea', value: 'Kīlauea' },
  { label: 'Kilb', value: 'Kilb' },
  { label: 'Kilbarchan', value: 'Kilbarchan' },
  { label: 'Kilbeggan', value: 'Kilbeggan' },
  { label: 'Kilbirnie', value: 'Kilbirnie' },
  { label: 'Kilburn', value: 'Kilburn' },
  { label: 'Kilburn', value: 'Kilburn' },
  { label: 'Kilchberg', value: 'Kilchberg' },
  { label: 'Kilchberg / Bächler-Stocken', value: 'Kilchberg / Bächler-Stocken' },
  { label: 'Kilchberg / Kilchberg (Dorfkern)', value: 'Kilchberg / Kilchberg (Dorfkern)' },
  { label: 'Kilcock', value: 'Kilcock' },
  { label: 'Kilcoole', value: 'Kilcoole' },
  { label: 'Kilcoy', value: 'Kilcoy' },
  { label: 'Kilcreggan', value: 'Kilcreggan' },
  { label: 'Kilcullen', value: 'Kilcullen' },
  { label: 'Kildare', value: 'Kildare' },
  { label: 'Kildeer', value: 'Kildeer' },
  { label: 'Kilemarskiy Rayon', value: 'Kilemarskiy Rayon' },
  { label: 'Kilemary', value: 'Kilemary' },
  { label: 'Kilembe', value: 'Kilembe' },
  { label: 'Kilgetty', value: 'Kilgetty' },
  { label: 'Kilgore', value: 'Kilgore' },
  { label: 'Kilham', value: 'Kilham' },
  { label: 'Kilifarevo', value: 'Kilifarevo' },
  { label: 'Kilifi', value: 'Kilifi' },
  { label: 'Kilim', value: 'Kilim' },
  { label: 'Kilim', value: 'Kilim' },
  { label: 'Kilimatinde', value: 'Kilimatinde' },
  { label: 'Kilimli', value: 'Kilimli' },
  { label: 'Kilindoni', value: 'Kilindoni' },
  { label: 'Kilingi-Nõmme', value: 'Kilingi-Nõmme' },
  { label: 'Kilinochchi', value: 'Kilinochchi' },
  { label: 'Kilinochchi District', value: 'Kilinochchi District' },
  { label: 'Kiliya', value: 'Kiliya' },
  { label: 'Kilju', value: 'Kilju' },
  { label: 'Kilkeel', value: 'Kilkeel' },
  { label: 'Kilkenny', value: 'Kilkenny' },
  { label: 'Kilkenny', value: 'Kilkenny' },
  { label: 'Kilkís', value: 'Kilkís' },
  { label: 'Kill', value: 'Kill' },
  { label: 'Kill Devil Hills', value: 'Kill Devil Hills' },
  { label: 'Killaloe', value: 'Killaloe' },
  { label: 'Killam', value: 'Killam' },
  { label: 'Killamarsh', value: 'Killamarsh' },
  { label: 'Killara', value: 'Killara' },
  { label: 'Killarney', value: 'Killarney' },
  { label: 'Killarney Heights', value: 'Killarney Heights' },
  { label: 'Killarney Vale', value: 'Killarney Vale' },
  { label: 'Killdeer', value: 'Killdeer' },
  { label: 'Killearn', value: 'Killearn' },
  { label: 'Killeen', value: 'Killeen' },
  { label: 'Killem', value: 'Killem' },
  { label: 'Killester', value: 'Killester' },
  { label: 'Killian', value: 'Killian' },
  { label: 'Killingly Center', value: 'Killingly Center' },
  { label: 'Killorglin', value: 'Killorglin' },
  { label: 'Killumney', value: 'Killumney' },
  { label: 'Killwangen', value: 'Killwangen' },
  { label: 'Killybegs', value: 'Killybegs' },
  { label: 'Killyleagh', value: 'Killyleagh' },
  { label: 'Kilmacanoge', value: 'Kilmacanoge' },
  { label: 'Kilmacolm', value: 'Kilmacolm' },
  { label: 'Kilmallock', value: 'Kilmallock' },
  { label: 'Kilmanagh', value: 'Kilmanagh' },
  { label: 'Kilmarnoch', value: 'Kilmarnoch' },
  { label: 'Kilmarnock', value: 'Kilmarnock' },
  { label: 'Kilmarnock', value: 'Kilmarnock' },
  { label: 'Kilmaurs', value: 'Kilmaurs' },
  { label: 'Kilmia', value: 'Kilmia' },
  { label: 'Kilmore', value: 'Kilmore' },
  { label: 'Kiln', value: 'Kiln' },
  { label: 'Kilo', value: 'Kilo' },
  { label: 'Kiloloran', value: 'Kiloloran' },
  { label: 'Kilómetro Dieciséis', value: 'Kilómetro Dieciséis' },
  { label: 'Kilómetro Noventa Y Nueve', value: 'Kilómetro Noventa Y Nueve' },
  { label: 'Kilosa', value: 'Kilosa' },
  { label: 'Kilpedder', value: 'Kilpedder' },
  { label: 'Kilquade', value: 'Kilquade' },
  { label: 'Kilrea', value: 'Kilrea' },
  { label: 'Kilrush', value: 'Kilrush' },
  { label: 'Kilsby', value: 'Kilsby' },
  { label: 'Kilstett', value: 'Kilstett' },
  { label: 'Kilsyth', value: 'Kilsyth' },
  { label: 'Kilsyth', value: 'Kilsyth' },
  { label: 'Kilsyth South', value: 'Kilsyth South' },
  { label: 'Kiltamagh', value: 'Kiltamagh' },
  { label: 'Kilvelur', value: 'Kilvelur' },
  { label: 'Kilwinning', value: 'Kilwinning' },
  { label: 'Kilyazi', value: 'Kilyazi' },
  { label: 'Kim', value: 'Kim' },
  { label: 'Kimamba', value: 'Kimamba' },
  { label: 'Kimanuit', value: 'Kimanuit' },
  { label: 'Kimaya', value: 'Kimaya' },
  { label: 'Kimba', value: 'Kimba' },
  { label: 'Kimball', value: 'Kimball' },
  { label: 'Kimball', value: 'Kimball' },
  { label: 'Kimball County', value: 'Kimball County' },
  { label: 'Kimbe', value: 'Kimbe' },
  { label: 'Kimberley', value: 'Kimberley' },
  { label: 'Kimberley', value: 'Kimberley' },
  { label: 'Kimberley', value: 'Kimberley' },
  { label: 'Kimberling City', value: 'Kimberling City' },
  { label: 'Kimberly', value: 'Kimberly' },
  { label: 'Kimberly', value: 'Kimberly' },
  { label: 'Kimberly', value: 'Kimberly' },
  { label: 'Kimbila', value: 'Kimbila' },
  { label: 'Kimble County', value: 'Kimble County' },
  { label: 'Kimbo', value: 'Kimbo' },
  { label: 'Kimbolton', value: 'Kimbolton' },
  { label: 'Kimch’Aek-Si', value: 'Kimch’Aek-Si' },
  { label: 'Kimhae', value: 'Kimhae' },
  { label: 'Kimil’Tey', value: 'Kimil’Tey' },
  { label: 'Kimito', value: 'Kimito' },
  { label: 'Kimitsu', value: 'Kimitsu' },
  { label: 'Kimje', value: 'Kimje' },
  { label: 'Kimle', value: 'Kimle' },
  { label: 'Kimméria', value: 'Kimméria' },
  { label: 'Kímolos', value: 'Kímolos' },
  { label: 'Kimovsk', value: 'Kimovsk' },
  { label: 'Kimpton', value: 'Kimpton' },
  { label: 'Kimrskiy Rayon', value: 'Kimrskiy Rayon' },
  { label: 'Kimry', value: 'Kimry' },
  { label: 'Kimstad', value: 'Kimstad' },
  { label: 'Kin Kora', value: 'Kin Kora' },
  { label: 'Kinabhangan', value: 'Kinabhangan' },
  { label: 'Kinabhangan', value: 'Kinabhangan' },
  { label: 'Kinablangan', value: 'Kinablangan' },
  { label: 'Kinablangan', value: 'Kinablangan' },
  { label: 'Kinablangan', value: 'Kinablangan' },
  { label: 'Kinalaglagan', value: 'Kinalaglagan' },
  { label: 'Kinalansan', value: 'Kinalansan' },
  { label: 'Kinamayan', value: 'Kinamayan' },
  { label: 'Kinamayan', value: 'Kinamayan' },
  { label: 'Kinamayan', value: 'Kinamayan' },
  { label: 'Kināna', value: 'Kināna' },
  { label: 'Kinangan', value: 'Kinangan' },
  { label: 'Kinangan', value: 'Kinangan' },
  { label: 'Kinangan', value: 'Kinangan' },
  { label: 'Kinango', value: 'Kinango' },
  { label: 'Kinarut', value: 'Kinarut' },
  { label: 'Kinatakutan', value: 'Kinatakutan' },
  { label: 'Kincaid', value: 'Kincaid' },
  { label: 'Kincardine', value: 'Kincardine' },
  { label: 'Kincardine', value: 'Kincardine' },
  { label: 'Kinchil', value: 'Kinchil' },
  { label: 'Kincsesbánya', value: 'Kincsesbánya' },
  { label: 'Kindberg', value: 'Kindberg' },
  { label: 'Kindelbrück', value: 'Kindelbrück' },
  { label: 'Kindenheim', value: 'Kindenheim' },
  { label: 'Kinder', value: 'Kinder' },
  { label: 'Kinderbeuern', value: 'Kinderbeuern' },
  { label: 'Kinderhook', value: 'Kinderhook' },
  { label: 'Kindersley', value: 'Kindersley' },
  { label: 'Kindhausen / Kindhausen (Dorf)', value: 'Kindhausen / Kindhausen (Dorf)' },
  { label: 'Kindia', value: 'Kindia' },
  { label: 'Kindsbach', value: 'Kindsbach' },
  { label: 'Kindu', value: 'Kindu' },
  { label: 'Kinel’', value: 'Kinel’' },
  { label: 'Kinel’-Cherkassy', value: 'Kinel’-Cherkassy' },
  { label: 'Kinel’Skiy Rayon', value: 'Kinel’Skiy Rayon' },
  { label: 'Kineshemskiy Rayon', value: 'Kineshemskiy Rayon' },
  { label: 'Kineshma', value: 'Kineshma' },
  { label: 'Kinéta', value: 'Kinéta' },
  { label: 'Kineton', value: 'Kineton' },
  { label: 'King', value: 'King' },
  { label: 'King', value: 'King' },
  { label: 'King', value: 'King' },
  { label: 'King And Queen County', value: 'King And Queen County' },
  { label: 'King And Queen Court House', value: 'King And Queen Court House' },
  { label: 'King City', value: 'King City' },
  { label: 'King City', value: 'King City' },
  { label: 'King City', value: 'King City' },
  { label: 'King County', value: 'King County' },
  { label: 'King County', value: 'King County' },
  { label: 'King Cove', value: 'King Cove' },
  { label: 'King Creek', value: 'King Creek' },
  { label: 'King George', value: 'King George' },
  { label: 'King George County', value: 'King George County' },
  { label: 'King Island', value: 'King Island' },
  { label: 'King Of Prussia', value: 'King Of Prussia' },
  { label: 'King William', value: 'King William' },
  { label: 'King William County', value: 'King William County' },
  { label: 'Kingaroy', value: 'Kingaroy' },
  { label: 'Kingborough', value: 'Kingborough' },
  { label: 'Kingersheim', value: 'Kingersheim' },
  { label: 'Kingfield', value: 'Kingfield' },
  { label: 'Kingfisher', value: 'Kingfisher' },
  { label: 'Kingfisher County', value: 'Kingfisher County' },
  { label: 'Kinghorn', value: 'Kinghorn' },
  { label: 'Kingisepp', value: 'Kingisepp' },
  { label: 'Kingiseppskiy Rayon', value: 'Kingiseppskiy Rayon' },
  { label: 'Kinglake', value: 'Kinglake' },
  { label: 'Kinglake West', value: 'Kinglake West' },
  { label: 'Kinglassie', value: 'Kinglassie' },
  { label: 'Kingman', value: 'Kingman' },
  { label: 'Kingman', value: 'Kingman' },
  { label: 'Kingman County', value: 'Kingman County' },
  { label: 'Kingori', value: 'Kingori' },
  { label: 'Kings Bay Base', value: 'Kings Bay Base' },
  { label: 'Kings Beach', value: 'Kings Beach' },
  { label: 'Kings Beach', value: 'Kings Beach' },
  { label: 'Kings Bridge', value: 'Kings Bridge' },
  { label: 'Kings Clipstone', value: 'Kings Clipstone' },
  { label: 'Kings County', value: 'Kings County' },
  { label: 'Kings County', value: 'Kings County' },
  { label: 'Kings Grant', value: 'Kings Grant' },
  { label: 'Kings Hill', value: 'Kings Hill' },
  { label: 'Kings Langley', value: 'Kings Langley' },
  { label: 'Kings Langley', value: 'Kings Langley' },
  { label: 'Kings Lynn', value: 'Kings Lynn' },
  { label: 'Kings Meadows', value: 'Kings Meadows' },
  { label: 'Kings Mills', value: 'Kings Mills' },
  { label: 'Kings Mountain', value: 'Kings Mountain' },
  { label: 'Kings Park', value: 'Kings Park' },
  { label: 'Kings Park', value: 'Kings Park' },
  { label: 'Kings Park', value: 'Kings Park' },
  { label: 'Kings Park', value: 'Kings Park' },
  { label: 'Kings Park West', value: 'Kings Park West' },
  { label: 'Kings Point', value: 'Kings Point' },
  { label: 'Kings Point', value: 'Kings Point' },
  { label: 'Kings Sutton', value: 'Kings Sutton' },
  { label: 'Kings Worthy', value: 'Kings Worthy' },
  { label: 'Kingsbridge', value: 'Kingsbridge' },
  { label: 'Kingsburg', value: 'Kingsburg' },
  { label: 'Kingsbury', value: 'Kingsbury' },
  { label: 'Kingsbury', value: 'Kingsbury' },
  { label: 'Kingsbury', value: 'Kingsbury' },
  { label: 'Kingsbury County', value: 'Kingsbury County' },
  { label: 'Kingsclere', value: 'Kingsclere' },
  { label: 'Kingscliff', value: 'Kingscliff' },
  { label: 'Kingscote', value: 'Kingscote' },
  { label: 'Kingscourt', value: 'Kingscourt' },
  { label: 'Kingsey Falls', value: 'Kingsey Falls' },
  { label: 'Kingsford', value: 'Kingsford' },
  { label: 'Kingsford', value: 'Kingsford' },
  { label: 'Kingsford Heights', value: 'Kingsford Heights' },
  { label: 'Kingsgate', value: 'Kingsgate' },
  { label: 'Kingsgrove', value: 'Kingsgrove' },
  { label: 'Kingskerswell', value: 'Kingskerswell' },
  { label: 'Kingskettle', value: 'Kingskettle' },
  { label: 'Kingsland', value: 'Kingsland' },
  { label: 'Kingsland', value: 'Kingsland' },
  { label: 'Kingsland', value: 'Kingsland' },
  { label: 'Kingsley', value: 'Kingsley' },
  { label: 'Kingsley', value: 'Kingsley' },
  { label: 'Kingsley', value: 'Kingsley' },
  { label: 'Kingsley', value: 'Kingsley' },
  { label: 'Kingsport', value: 'Kingsport' },
  { label: 'Kingsteignton', value: 'Kingsteignton' },
  { label: 'Kingsthorpe', value: 'Kingsthorpe' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston', value: 'Kingston' },
  { label: 'Kingston Bagpuize', value: 'Kingston Bagpuize' },
  { label: 'Kingston Beach', value: 'Kingston Beach' },
  { label: 'Kingston Estates', value: 'Kingston Estates' },
  { label: 'Kingston Gardens', value: 'Kingston Gardens' },
  { label: 'Kingston Seymour', value: 'Kingston Seymour' },
  { label: 'Kingston South East', value: 'Kingston South East' },
  { label: 'Kingston Springs', value: 'Kingston Springs' },
  { label: 'Kingston Upon Hull', value: 'Kingston Upon Hull' },
  { label: 'Kingston Upon Thames', value: 'Kingston Upon Thames' },
  { label: 'Kingstown', value: 'Kingstown' },
  { label: 'Kingstown', value: 'Kingstown' },
  { label: 'Kingstown Park', value: 'Kingstown Park' },
  { label: 'Kingstree', value: 'Kingstree' },
  { label: 'Kingsvale', value: 'Kingsvale' },
  { label: 'Kingsville', value: 'Kingsville' },
  { label: 'Kingsville', value: 'Kingsville' },
  { label: 'Kingsville', value: 'Kingsville' },
  { label: 'Kingswells', value: 'Kingswells' },
  { label: 'Kingswinford', value: 'Kingswinford' },
  { label: 'Kingswood', value: 'Kingswood' },
  { label: 'Kingswood Park', value: 'Kingswood Park' },
  { label: 'Kington', value: 'Kington' },
  { label: 'Kingussie', value: 'Kingussie' },
  { label: 'Kingwood', value: 'Kingwood' },
  { label: 'Kingwood Area', value: 'Kingwood Area' },
  { label: 'Kini', value: 'Kini' },
  { label: 'Kınık', value: 'Kınık' },
  { label: 'Kinil', value: 'Kinil' },
  { label: 'Kinkala', value: 'Kinkala' },
  { label: 'Kinloss', value: 'Kinloss' },
  { label: 'Kinloss', value: 'Kinloss' },
  { label: 'Kinlough', value: 'Kinlough' },
  { label: 'Kinmen County', value: 'Kinmen County' },
  { label: 'Kinmparana', value: 'Kinmparana' },
  { label: 'Kinna', value: 'Kinna' },
  { label: 'Kinnaur', value: 'Kinnaur' },
  { label: 'Kinnegad', value: 'Kinnegad' },
  { label: 'Kinnelon', value: 'Kinnelon' },
  { label: 'Kinney County', value: 'Kinney County' },
  { label: 'Kinnula', value: 'Kinnula' },
  { label: 'Kinokawa Shi', value: 'Kinokawa Shi' },
  { label: 'Kinondoni', value: 'Kinondoni' },
  { label: 'Kinrooi', value: 'Kinrooi' },
  { label: 'Kinross', value: 'Kinross' },
  { label: 'Kinross', value: 'Kinross' },
  { label: 'Kinsale', value: 'Kinsale' },
  { label: 'Kinsarvik', value: 'Kinsarvik' },
  { label: 'Kinsealy-Drinan', value: 'Kinsealy-Drinan' },
  { label: 'Kinsey', value: 'Kinsey' },
  { label: 'Kinshasa', value: 'Kinshasa' },
  { label: 'Kinsley', value: 'Kinsley' },
  { label: 'Kinston', value: 'Kinston' },
  { label: 'Kintampo North', value: 'Kintampo North' },
  { label: 'Kintampo South', value: 'Kintampo South' },
  { label: 'Kintbury', value: 'Kintbury' },
  { label: 'Kintinku', value: 'Kintinku' },
  { label: 'Kintore', value: 'Kintore' },
  { label: 'Kintyre', value: 'Kintyre' },
  { label: 'Kintzheim', value: 'Kintzheim' },
  { label: 'Kinvere', value: 'Kinvere' },
  { label: 'Kinwat', value: 'Kinwat' },
  { label: 'Kinzan', value: 'Kinzan' },
  { label: 'Kiomboi', value: 'Kiomboi' },
  { label: 'Kiowa', value: 'Kiowa' },
  { label: 'Kiowa', value: 'Kiowa' },
  { label: 'Kiowa County', value: 'Kiowa County' },
  { label: 'Kiowa County', value: 'Kiowa County' },
  { label: 'Kiowa County', value: 'Kiowa County' },
  { label: 'Kipalbig', value: 'Kipalbig' },
  { label: 'Kipalbig', value: 'Kipalbig' },
  { label: 'Kipen’', value: 'Kipen’' },
  { label: 'Kipini', value: 'Kipini' },
  { label: 'Kipit', value: 'Kipit' },
  { label: 'Kipkelion', value: 'Kipkelion' },
  { label: 'Kippa-Ring', value: 'Kippa-Ring' },
  { label: 'Kippax', value: 'Kippax' },
  { label: 'Kippen', value: 'Kippen' },
  { label: 'Kippenheim', value: 'Kippenheim' },
  { label: 'Kipséli', value: 'Kipséli' },
  { label: 'Kipushi', value: 'Kipushi' },
  { label: 'Kirakat', value: 'Kirakat' },
  { label: 'Kirakira', value: 'Kirakira' },
  { label: 'Kirando', value: 'Kirando' },
  { label: 'Kirandul', value: 'Kirandul' },
  { label: 'Kiranur', value: 'Kiranur' },
  { label: 'Kiraoli', value: 'Kiraoli' },
  { label: 'Kiratpur', value: 'Kiratpur' },
  { label: 'Kiratu', value: 'Kiratu' },
  { label: 'Kirawsk', value: 'Kirawsk' },
  { label: 'Kiraz', value: 'Kiraz' },
  { label: 'Kirby', value: 'Kirby' },
  { label: 'Kirby Muxloe', value: 'Kirby Muxloe' },
  { label: 'Kirbyville', value: 'Kirbyville' },
  { label: 'Kirchanschöring', value: 'Kirchanschöring' },
  { label: 'Kirchardt', value: 'Kirchardt' },
  { label: 'Kirchbach', value: 'Kirchbach' },
  { label: 'Kirchbach In Steiermark', value: 'Kirchbach In Steiermark' },
  { label: 'Kirchberg', value: 'Kirchberg' },
  { label: 'Kirchberg', value: 'Kirchberg' },
  { label: 'Kirchberg', value: 'Kirchberg' },
  { label: 'Kirchberg', value: 'Kirchberg' },
  { label: 'Kirchberg', value: 'Kirchberg' },
  { label: 'Kirchberg', value: 'Kirchberg' },
  { label: 'Kirchberg Am Wagram', value: 'Kirchberg Am Wagram' },
  { label: 'Kirchberg Am Walde', value: 'Kirchberg Am Walde' },
  { label: 'Kirchberg Am Wechsel', value: 'Kirchberg Am Wechsel' },
  { label: 'Kirchberg An Der Iller', value: 'Kirchberg An Der Iller' },
  { label: 'Kirchberg An Der Jagst', value: 'Kirchberg An Der Jagst' },
  { label: 'Kirchberg An Der Murr', value: 'Kirchberg An Der Murr' },
  { label: 'Kirchberg An Der Pielach', value: 'Kirchberg An Der Pielach' },
  { label: 'Kirchberg An Der Raab', value: 'Kirchberg An Der Raab' },
  { label: 'Kirchberg In Tirol', value: 'Kirchberg In Tirol' },
  { label: 'Kirchberg Ob Der Donau', value: 'Kirchberg Ob Der Donau' },
  { label: 'Kirchbichl', value: 'Kirchbichl' },
  { label: 'Kirchbrak', value: 'Kirchbrak' },
  { label: 'Kirchdorf', value: 'Kirchdorf' },
  { label: 'Kirchdorf', value: 'Kirchdorf' },
  { label: 'Kirchdorf', value: 'Kirchdorf' },
  { label: 'Kirchdorf Am Inn', value: 'Kirchdorf Am Inn' },
  { label: 'Kirchdorf An Der Krems', value: 'Kirchdorf An Der Krems' },
  { label: 'Kirchdorf Im Wald', value: 'Kirchdorf Im Wald' },
  { label: 'Kirchdorf In Tirol', value: 'Kirchdorf In Tirol' },
  { label: 'Kirchehrenbach', value: 'Kirchehrenbach' },
  { label: 'Kirchen', value: 'Kirchen' },
  { label: 'Kirchenlamitz', value: 'Kirchenlamitz' },
  { label: 'Kirchenpingarten', value: 'Kirchenpingarten' },
  { label: 'Kirchensittenbach', value: 'Kirchensittenbach' },
  { label: 'Kirchentellinsfurt', value: 'Kirchentellinsfurt' },
  { label: 'Kirchenthumbach', value: 'Kirchenthumbach' },
  { label: 'Kirchenviertel', value: 'Kirchenviertel' },
  { label: 'Kirchgellersen', value: 'Kirchgellersen' },
  { label: 'Kirchhain', value: 'Kirchhain' },
  { label: 'Kirchham', value: 'Kirchham' },
  { label: 'Kirchhaslach', value: 'Kirchhaslach' },
  { label: 'Kirchheim', value: 'Kirchheim' },
  { label: 'Kirchheim', value: 'Kirchheim' },
  { label: 'Kirchheim', value: 'Kirchheim' },
  { label: 'Kirchheim Am Neckar', value: 'Kirchheim Am Neckar' },
  { label: 'Kirchheim Am Ries', value: 'Kirchheim Am Ries' },
  { label: 'Kirchheim An Der Weinstraße', value: 'Kirchheim An Der Weinstraße' },
  { label: 'Kirchheim Bei München', value: 'Kirchheim Bei München' },
  { label: 'Kirchheim Unter Teck', value: 'Kirchheim Unter Teck' },
  { label: 'Kirchheimbolanden', value: 'Kirchheimbolanden' },
  { label: 'Kirchhundem', value: 'Kirchhundem' },
  { label: 'Kirchlauter', value: 'Kirchlauter' },
  { label: 'Kirchlengern', value: 'Kirchlengern' },
  { label: 'Kirchlindach', value: 'Kirchlindach' },
  { label: 'Kirchlinteln', value: 'Kirchlinteln' },
  { label: 'Kirchroth', value: 'Kirchroth' },
  { label: 'Kirchschlag', value: 'Kirchschlag' },
  { label: 'Kirchschlag Bei Linz', value: 'Kirchschlag Bei Linz' },
  { label: 'Kirchschlag In Der Buckligen Welt', value: 'Kirchschlag In Der Buckligen Welt' },
  { label: 'Kirchseelte', value: 'Kirchseelte' },
  { label: 'Kirchseeon', value: 'Kirchseeon' },
  { label: 'Kirchstetten', value: 'Kirchstetten' },
  { label: 'Kirchtimke', value: 'Kirchtimke' },
  { label: 'Kirchwald', value: 'Kirchwald' },
  { label: 'Kirchwalsede', value: 'Kirchwalsede' },
  { label: 'Kirchweidach', value: 'Kirchweidach' },
  { label: 'Kirchworbis', value: 'Kirchworbis' },
  { label: 'Kirchzarten', value: 'Kirchzarten' },
  { label: 'Kirchzell', value: 'Kirchzell' },
  { label: 'Kircubbin', value: 'Kircubbin' },
  { label: 'Kireka', value: 'Kireka' },
  { label: 'Kirensk', value: 'Kirensk' },
  { label: 'Kirenskiy Rayon', value: 'Kirenskiy Rayon' },
  { label: 'Kireyevsk', value: 'Kireyevsk' },
  { label: 'Kirgiz-Miyaki', value: 'Kirgiz-Miyaki' },
  { label: 'Kirguli', value: 'Kirguli' },
  { label: 'Kiri Buru', value: 'Kiri Buru' },
  { label: 'Kırıkhan', value: 'Kırıkhan' },
  { label: 'Kirillov', value: 'Kirillov' },
  { label: 'Kirillovskiy Rayon', value: 'Kirillovskiy Rayon' },
  { label: 'Kirishi', value: 'Kirishi' },
  { label: 'Kirishima Shi', value: 'Kirishima Shi' },
  { label: 'Kiritimati', value: 'Kiritimati' },
  { label: 'Kiritsy', value: 'Kiritsy' },
  { label: 'Kiriwina Goodenough', value: 'Kiriwina Goodenough' },
  { label: 'Kirk Sandall', value: 'Kirk Sandall' },
  { label: 'Kırkağaç', value: 'Kırkağaç' },
  { label: 'Kirkburton', value: 'Kirkburton' },
  { label: 'Kirkby', value: 'Kirkby' },
  { label: 'Kirkby In Ashfield', value: 'Kirkby In Ashfield' },
  { label: 'Kirkby Lonsdale', value: 'Kirkby Lonsdale' },
  { label: 'Kirkby Stephen', value: 'Kirkby Stephen' },
  { label: 'Kirkbymoorside', value: 'Kirkbymoorside' },
  { label: 'Kirkcaldy', value: 'Kirkcaldy' },
  { label: 'Kirkconnel', value: 'Kirkconnel' },
  { label: 'Kirkcudbright', value: 'Kirkcudbright' },
  { label: 'Kirke Hvalsø', value: 'Kirke Hvalsø' },
  { label: 'Kirkel', value: 'Kirkel' },
  { label: 'Kirkenær', value: 'Kirkenær' },
  { label: 'Kirkenes', value: 'Kirkenes' },
  { label: 'Kirkham', value: 'Kirkham' },
  { label: 'Kirkintilloch', value: 'Kirkintilloch' },
  { label: 'Kirkkonummi', value: 'Kirkkonummi' },
  { label: 'Kirkland', value: 'Kirkland' },
  { label: 'Kirkland', value: 'Kirkland' },
  { label: 'Kirkland', value: 'Kirkland' },
  { label: 'Kirkland Lake', value: 'Kirkland Lake' },
  { label: 'Kirklees', value: 'Kirklees' },
  { label: 'Kirkliston', value: 'Kirkliston' },
  { label: 'Kirknewton', value: 'Kirknewton' },
  { label: 'Kirkop', value: 'Kirkop' },
  { label: 'Kirkovo', value: 'Kirkovo' },
  { label: 'Kirksville', value: 'Kirksville' },
  { label: 'Kirkuk', value: 'Kirkuk' },
  { label: 'Kirkwall', value: 'Kirkwall' },
  { label: 'Kirkwood', value: 'Kirkwood' },
  { label: 'Kirkwood', value: 'Kirkwood' },
  { label: 'Kirkwood', value: 'Kirkwood' },
  { label: 'Kirn', value: 'Kirn' },
  { label: 'Kirnahar', value: 'Kirnahar' },
  { label: 'Kirnberg An Der Mank', value: 'Kirnberg An Der Mank' },
  { label: 'Kirov', value: 'Kirov' },
  { label: 'Kirov', value: 'Kirov' },
  { label: 'Kirov', value: 'Kirov' },
  { label: 'Kirove', value: 'Kirove' },
  { label: 'Kirove', value: 'Kirove' },
  { label: 'Kirovgrad', value: 'Kirovgrad' },
  { label: 'Kirovo', value: 'Kirovo' },
  { label: 'Kirovo-Chepetsk', value: 'Kirovo-Chepetsk' },
  { label: 'Kirovo-Chepetskiy Rayon', value: 'Kirovo-Chepetskiy Rayon' },
  { label: 'Kirovohrads’Ka Mis’Krada', value: 'Kirovohrads’Ka Mis’Krada' },
  { label: 'Kirovs’K', value: 'Kirovs’K' },
  { label: 'Kirovs’Ka Mis’Krada', value: 'Kirovs’Ka Mis’Krada' },
  { label: 'Kirovsk', value: 'Kirovsk' },
  { label: 'Kirovsk', value: 'Kirovsk' },
  { label: 'Kirovskaya', value: 'Kirovskaya' },
  { label: 'Kirovske Raion', value: 'Kirovske Raion' },
  { label: 'Kirovskiy', value: 'Kirovskiy' },
  { label: 'Kirovskiy', value: 'Kirovskiy' },
  { label: 'Kirovskiy', value: 'Kirovskiy' },
  { label: 'Kirovskiy Rayon', value: 'Kirovskiy Rayon' },
  { label: 'Kirovskiy Rayon', value: 'Kirovskiy Rayon' },
  { label: 'Kirovskiy Rayon', value: 'Kirovskiy Rayon' },
  { label: 'Kirovskoye', value: 'Kirovskoye' },
  { label: 'Kírra', value: 'Kírra' },
  { label: 'Kirrawee', value: 'Kirrawee' },
  { label: 'Kirribilli', value: 'Kirribilli' },
  { label: 'Kirriemuir', value: 'Kirriemuir' },
  { label: 'Kirrweiler', value: 'Kirrweiler' },
  { label: 'Kirs', value: 'Kirs' },
  { label: 'Kirsanov', value: 'Kirsanov' },
  { label: 'Kirsanovskiy Rayon', value: 'Kirsanovskiy Rayon' },
  { label: 'Kirschau', value: 'Kirschau' },
  { label: 'Kirschweiler', value: 'Kirschweiler' },
  { label: 'Kirtland', value: 'Kirtland' },
  { label: 'Kirtland', value: 'Kirtland' },
  { label: 'Kirton', value: 'Kirton' },
  { label: 'Kirton In Lindsey', value: 'Kirton In Lindsey' },
  { label: 'Kirtorf', value: 'Kirtorf' },
  { label: 'Kiruhura', value: 'Kiruhura' },
  { label: 'Kiruna', value: 'Kiruna' },
  { label: 'Kirundo', value: 'Kirundo' },
  { label: 'Kirwan', value: 'Kirwan' },
  { label: 'Kirya', value: 'Kirya' },
  { label: 'Kirya', value: 'Kirya' },
  { label: 'Kiryas Joel', value: 'Kiryas Joel' },
  { label: 'Kiryū', value: 'Kiryū' },
  { label: 'Kiryū Shi', value: 'Kiryū Shi' },
  { label: 'Kirzhach', value: 'Kirzhach' },
  { label: 'Kisa', value: 'Kisa' },
  { label: 'Kisač', value: 'Kisač' },
  { label: 'Kisai', value: 'Kisai' },
  { label: 'Kisanga', value: 'Kisanga' },
  { label: 'Kisangani', value: 'Kisangani' },
  { label: 'Kisante', value: 'Kisante' },
  { label: 'Kisante', value: 'Kisante' },
  { label: 'Kisaran', value: 'Kisaran' },
  { label: 'Kisarawe', value: 'Kisarawe' },
  { label: 'Kisarazu', value: 'Kisarazu' },
  { label: 'Kisarazu Shi', value: 'Kisarazu Shi' },
  { label: 'Kisasa', value: 'Kisasa' },
  { label: 'Kisbér', value: 'Kisbér' },
  { label: 'Kisdorf', value: 'Kisdorf' },
  { label: 'Kisela Voda', value: 'Kisela Voda' },
  { label: 'Kiselëvsk', value: 'Kiselëvsk' },
  { label: 'Kiseljak', value: 'Kiseljak' },
  { label: 'Kisesa', value: 'Kisesa' },
  { label: 'Kish', value: 'Kish' },
  { label: 'Kishanganj', value: 'Kishanganj' },
  { label: 'Kishangarh', value: 'Kishangarh' },
  { label: 'Kishanpur', value: 'Kishanpur' },
  { label: 'Kishanpur Baral', value: 'Kishanpur Baral' },
  { label: 'Kishapu', value: 'Kishapu' },
  { label: 'Kishcha', value: 'Kishcha' },
  { label: 'Kishiwada', value: 'Kishiwada' },
  { label: 'Kishiwada Shi', value: 'Kishiwada Shi' },
  { label: 'Kishni', value: 'Kishni' },
  { label: 'Kishoreganj ', value: 'Kishoreganj ' },
  { label: 'Kishpek', value: 'Kishpek' },
  { label: 'Kishtwar', value: 'Kishtwar' },
  { label: 'Kisi', value: 'Kisi' },
  { label: 'Kisielice', value: 'Kisielice' },
  { label: 'Kisigmánd', value: 'Kisigmánd' },
  { label: 'Kisii', value: 'Kisii' },
  { label: 'Kisiwani', value: 'Kisiwani' },
  { label: 'Kisko', value: 'Kisko' },
  { label: 'Kisköre', value: 'Kisköre' },
  { label: 'Kiskőrös', value: 'Kiskőrös' },
  { label: 'Kiskőrösi Járás', value: 'Kiskőrösi Járás' },
  { label: 'Kiskunfélegyháza', value: 'Kiskunfélegyháza' },
  { label: 'Kiskunfélegyházi Járás', value: 'Kiskunfélegyházi Járás' },
  { label: 'Kiskunhalas', value: 'Kiskunhalas' },
  { label: 'Kiskunhalasi Járás', value: 'Kiskunhalasi Járás' },
  { label: 'Kiskunlacháza', value: 'Kiskunlacháza' },
  { label: 'Kiskunmajsa', value: 'Kiskunmajsa' },
  { label: 'Kiskunmajsai Járás', value: 'Kiskunmajsai Járás' },
  { label: 'Kisláng', value: 'Kisláng' },
  { label: 'Kisléta', value: 'Kisléta' },
  { label: 'Kislingbury', value: 'Kislingbury' },
  { label: 'Kislovodsk', value: 'Kislovodsk' },
  { label: 'Kislyakovskaya', value: 'Kislyakovskaya' },
  { label: 'Kismaros', value: 'Kismaros' },
  { label: 'Kismayo', value: 'Kismayo' },
  { label: 'Kiso', value: 'Kiso' },
  { label: 'Kisolon', value: 'Kisolon' },
  { label: 'Kisoro', value: 'Kisoro' },
  { label: 'Kisovec', value: 'Kisovec' },
  { label: 'Kispest', value: 'Kispest' },
  { label: 'Kíssamos', value: 'Kíssamos' },
  { label: 'Kissee Mills', value: 'Kissee Mills' },
  { label: 'Kissenbrück', value: 'Kissenbrück' },
  { label: 'Kissimmee', value: 'Kissimmee' },
  { label: 'Kissing', value: 'Kissing' },
  { label: 'Kißlegg', value: 'Kißlegg' },
  { label: 'Kissonerga', value: 'Kissonerga' },
  { label: 'Kisszállás', value: 'Kisszállás' },
  { label: 'Kist', value: 'Kist' },
  { label: 'Kista', value: 'Kista' },
  { label: 'Kistanje', value: 'Kistanje' },
  { label: 'Kistarcsa', value: 'Kistarcsa' },
  { label: 'Kistelek', value: 'Kistelek' },
  { label: 'Kisteleki Járás', value: 'Kisteleki Járás' },
  { label: 'Kisújszállás', value: 'Kisújszállás' },
  { label: 'Kisumu', value: 'Kisumu' },
  { label: 'Kisvárda', value: 'Kisvárda' },
  { label: 'Kisvárdai Járás', value: 'Kisvárdai Járás' },
  { label: 'Kiszkowo', value: 'Kiszkowo' },
  { label: 'Kiszombor', value: 'Kiszombor' },
  { label: 'Kit Carson County', value: 'Kit Carson County' },
  { label: 'Kita', value: 'Kita' },
  { label: 'Kitaaiki', value: 'Kitaaiki' },
  { label: 'Kitaakita-Shi', value: 'Kitaakita-Shi' },
  { label: 'Kitaf Wa Al Boqee', value: 'Kitaf Wa Al Boqee' },
  { label: 'Kitagawa', value: 'Kitagawa' },
  { label: 'Kitahama', value: 'Kitahama' },
  { label: 'Kitahiroshima', value: 'Kitahiroshima' },
  { label: 'Kitahiroshima-Shi', value: 'Kitahiroshima-Shi' },
  { label: 'Kitaibaraki', value: 'Kitaibaraki' },
  { label: 'Kitaibaraki-Shi', value: 'Kitaibaraki-Shi' },
  { label: 'Kitakami', value: 'Kitakami' },
  { label: 'Kitakami-Shi', value: 'Kitakami-Shi' },
  { label: 'Kitakata', value: 'Kitakata' },
  { label: 'Kitakata-Shi', value: 'Kitakata-Shi' },
  { label: 'Kita-Ku', value: 'Kita-Ku' },
  { label: 'Kitakyushu', value: 'Kitakyushu' },
  { label: 'Kitale', value: 'Kitale' },
  { label: 'Kitama', value: 'Kitama' },
  { label: 'Kitami', value: 'Kitami' },
  { label: 'Kitamoto Shi', value: 'Kitamoto Shi' },
  { label: 'Kitanagoya', value: 'Kitanagoya' },
  { label: 'Kitanagoya-Shi', value: 'Kitanagoya-Shi' },
  { label: 'Kitangari', value: 'Kitangari' },
  { label: 'Kitango', value: 'Kitango' },
  { label: 'Kitango', value: 'Kitango' },
  { label: 'Kitaotao', value: 'Kitaotao' },
  { label: 'Kitapak', value: 'Kitapak' },
  { label: 'Kitapak', value: 'Kitapak' },
  { label: 'Kitcharao', value: 'Kitcharao' },
  { label: 'Kitcharao', value: 'Kitcharao' },
  { label: 'Kitchener', value: 'Kitchener' },
  { label: 'Kitee', value: 'Kitee' },
  { label: 'Kiten', value: 'Kiten' },
  { label: 'Kiten', value: 'Kiten' },
  { label: 'Kitgum', value: 'Kitgum' },
  { label: 'Kithor', value: 'Kithor' },
  { label: 'Kíti', value: 'Kíti' },
  { label: 'Kitim', value: 'Kitim' },
  { label: 'Kitimat', value: 'Kitimat' },
  { label: 'Kitob', value: 'Kitob' },
  { label: 'Kitobo', value: 'Kitobo' },
  { label: 'Kitovo', value: 'Kitovo' },
  { label: 'Kitoy', value: 'Kitoy' },
  { label: 'Kítros', value: 'Kítros' },
  { label: 'Kitsap County', value: 'Kitsap County' },
  { label: 'Kítsi', value: 'Kítsi' },
  { label: 'Kitson Town', value: 'Kitson Town' },
  { label: 'Kitsuki', value: 'Kitsuki' },
  { label: 'Kitsuki Shi', value: 'Kitsuki Shi' },
  { label: 'Kittanning', value: 'Kittanning' },
  { label: 'Kittery', value: 'Kittery' },
  { label: 'Kittery Point', value: 'Kittery Point' },
  { label: 'Kitti Municipality', value: 'Kitti Municipality' },
  { label: 'Kittilä', value: 'Kittilä' },
  { label: 'Kittitas', value: 'Kittitas' },
  { label: 'Kittitas County', value: 'Kittitas County' },
  { label: 'Kittredge', value: 'Kittredge' },
  { label: 'Kittsee', value: 'Kittsee' },
  { label: 'Kittson County', value: 'Kittson County' },
  { label: 'Kitty Hawk', value: 'Kitty Hawk' },
  { label: 'Kitu', value: 'Kitu' },
  { label: 'Kitui', value: 'Kitui' },
  { label: 'Kitwe', value: 'Kitwe' },
  { label: 'Kitzbühel', value: 'Kitzbühel' },
  { label: 'Kitzeck Im Sausal', value: 'Kitzeck Im Sausal' },
  { label: 'Kitzen', value: 'Kitzen' },
  { label: 'Kitzingen', value: 'Kitzingen' },
  { label: 'Kitzscher', value: 'Kitzscher' },
  { label: 'Kiukainen', value: 'Kiukainen' },
  { label: 'Kiunga', value: 'Kiunga' },
  { label: 'Kiupo', value: 'Kiupo' },
  { label: 'Kiupo', value: 'Kiupo' },
  { label: 'Kivertsi', value: 'Kivertsi' },
  { label: 'Kiveton Park', value: 'Kiveton Park' },
  { label: 'Kivi', value: 'Kivi' },
  { label: 'Kivijärvi', value: 'Kivijärvi' },
  { label: 'Kiviõli', value: 'Kiviõli' },
  { label: 'Kivsharivka', value: 'Kivsharivka' },
  { label: 'Kiwengwa', value: 'Kiwengwa' },
  { label: 'Kiwira', value: 'Kiwira' },
  { label: 'Kiyasovo', value: 'Kiyasovo' },
  { label: 'Kiyasovskiy Rayon', value: 'Kiyasovskiy Rayon' },
  { label: 'Kiyawa', value: 'Kiyawa' },
  { label: 'Kiyevskoye', value: 'Kiyevskoye' },
  { label: 'Kiyose-Shi', value: 'Kiyose-Shi' },
  { label: 'Kiyosu-Shi', value: 'Kiyosu-Shi' },
  { label: 'Kizel', value: 'Kizel' },
  { label: 'Kizema', value: 'Kizema' },
  { label: 'Kizhaba', value: 'Kizhaba' },
  { label: 'Kizhake Chalakudi', value: 'Kizhake Chalakudi' },
  { label: 'Kizhinga', value: 'Kizhinga' },
  { label: 'Kizil’Skiy Rayon', value: 'Kizil’Skiy Rayon' },
  { label: 'Kızılcahamam', value: 'Kızılcahamam' },
  { label: 'Kızılırmak', value: 'Kızılırmak' },
  { label: 'Kızılören', value: 'Kızılören' },
  { label: 'Kızıltepe', value: 'Kızıltepe' },
  { label: 'Kizilyurt', value: 'Kizilyurt' },
  { label: 'Kizilyurtovskiy Rayon', value: 'Kizilyurtovskiy Rayon' },
  { label: 'Kizlyar', value: 'Kizlyar' },
  { label: 'Kizlyar', value: 'Kizlyar' },
  { label: 'Kizlyarskiy Rayon', value: 'Kizlyarskiy Rayon' },
  { label: 'Kizner', value: 'Kizner' },
  { label: 'Kizugawa-Shi', value: 'Kizugawa-Shi' },
  { label: 'Kjellerup', value: 'Kjellerup' },
  { label: 'Kjøllefjord', value: 'Kjøllefjord' },
  { label: 'Kjøpsvik', value: 'Kjøpsvik' },
  { label: 'Kjósarhreppur', value: 'Kjósarhreppur' },
  { label: 'Klaaswaal', value: 'Klaaswaal' },
  { label: 'Kladanj', value: 'Kladanj' },
  { label: 'Kladno', value: 'Kladno' },
  { label: 'Kladow', value: 'Kladow' },
  { label: 'Kladruby', value: 'Kladruby' },
  { label: 'Klæbu', value: 'Klæbu' },
  { label: 'Klaeng', value: 'Klaeng' },
  { label: 'Klaffer Am Hochficht', value: 'Klaffer Am Hochficht' },
  { label: 'Klagenfurt', value: 'Klagenfurt' },
  { label: 'Klagenfurt Am Wörthersee', value: 'Klagenfurt Am Wörthersee' },
  { label: 'Klagenfurt-Land District', value: 'Klagenfurt-Land District' },
  { label: 'Klågerup', value: 'Klågerup' },
  { label: 'Klahanie', value: 'Klahanie' },
  { label: 'Klaipėda', value: 'Klaipėda' },
  { label: 'Kłaj', value: 'Kłaj' },
  { label: 'Klaksvík', value: 'Klaksvík' },
  { label: 'Klamath County', value: 'Klamath County' },
  { label: 'Klamath Falls', value: 'Klamath Falls' },
  { label: 'Klana', value: 'Klana' },
  { label: 'Klang', value: 'Klang' },
  { label: 'Klangenan', value: 'Klangenan' },
  { label: 'Klanjec', value: 'Klanjec' },
  { label: 'Klarenbeek', value: 'Klarenbeek' },
  { label: 'Klarup', value: 'Klarup' },
  { label: 'Klášter', value: 'Klášter' },
  { label: 'Klášterec Nad Ohří', value: 'Klášterec Nad Ohří' },
  { label: 'Klaten', value: 'Klaten' },
  { label: 'Klatovy', value: 'Klatovy' },
  { label: 'Klaus', value: 'Klaus' },
  { label: 'Klausdorf', value: 'Klausdorf' },
  { label: 'Klausen', value: 'Klausen' },
  { label: 'Klausen-Leopoldsdorf', value: 'Klausen-Leopoldsdorf' },
  { label: 'Klavdiyevo-Tarasove', value: 'Klavdiyevo-Tarasove' },
  { label: 'Klazienaveen', value: 'Klazienaveen' },
  { label: 'Klebang Besar', value: 'Klebang Besar' },
  { label: 'Kleberg County', value: 'Kleberg County' },
  { label: 'Kleblach-Lind', value: 'Kleblach-Lind' },
  { label: 'Klecany', value: 'Klecany' },
  { label: 'Kłecko', value: 'Kłecko' },
  { label: 'Klecza Dolna', value: 'Klecza Dolna' },
  { label: 'Kleczew', value: 'Kleczew' },
  { label: 'Kleidí', value: 'Kleidí' },
  { label: 'Klein Berßen', value: 'Klein Berßen' },
  { label: 'Klein Driene', value: 'Klein Driene' },
  { label: 'Klein Gusborn', value: 'Klein Gusborn' },
  { label: 'Klein Nordende', value: 'Klein Nordende' },
  { label: 'Klein Offenseth-Sparrieshoop', value: 'Klein Offenseth-Sparrieshoop' },
  { label: 'Klein Reken', value: 'Klein Reken' },
  { label: 'Klein Rogahn', value: 'Klein Rogahn' },
  { label: 'Klein Rönnau', value: 'Klein Rönnau' },
  { label: 'Klein Sankt Paul', value: 'Klein Sankt Paul' },
  { label: 'Klein Schwülper', value: 'Klein Schwülper' },
  { label: 'Klein Wanzleben', value: 'Klein Wanzleben' },
  { label: 'Kleinaitingen', value: 'Kleinaitingen' },
  { label: 'Kleinandelfingen', value: 'Kleinandelfingen' },
  { label: 'Kleinarl', value: 'Kleinarl' },
  { label: 'Kleinblittersdorf', value: 'Kleinblittersdorf' },
  { label: 'Kleinedling', value: 'Kleinedling' },
  { label: 'Kleiner Grasbrook', value: 'Kleiner Grasbrook' },
  { label: 'Kleinfurra', value: 'Kleinfurra' },
  { label: 'Kleinheubach', value: 'Kleinheubach' },
  { label: 'Kleinhöflein Im Burgenland', value: 'Kleinhöflein Im Burgenland' },
  { label: 'Kleinkahl', value: 'Kleinkahl' },
  { label: 'Kleinlangheim', value: 'Kleinlangheim' },
  { label: 'Kleinlobming', value: 'Kleinlobming' },
  { label: 'Kleinlützel', value: 'Kleinlützel' },
  { label: 'Kleinmachnow', value: 'Kleinmachnow' },
  { label: 'Kleinmaischeid', value: 'Kleinmaischeid' },
  { label: 'Kleinmürbisch', value: 'Kleinmürbisch' },
  { label: 'Klein-Neusiedl', value: 'Klein-Neusiedl' },
  { label: 'Kleinostheim', value: 'Kleinostheim' },
  { label: 'Klein-Pöchlarn', value: 'Klein-Pöchlarn' },
  { label: 'Kleinrinderfeld', value: 'Kleinrinderfeld' },
  { label: 'Kleinsendelbach', value: 'Kleinsendelbach' },
  { label: 'Kleinsöding', value: 'Kleinsöding' },
  { label: 'Kleinsölk', value: 'Kleinsölk' },
  { label: 'Kleinton', value: 'Kleinton' },
  { label: 'Kleinwallstadt', value: 'Kleinwallstadt' },
  { label: 'Kleinwenden', value: 'Kleinwenden' },
  { label: 'Klein-Winternheim', value: 'Klein-Winternheim' },
  { label: 'Kleinzell', value: 'Kleinzell' },
  { label: 'Klein-Zundert', value: 'Klein-Zundert' },
  { label: 'Kleítos', value: 'Kleítos' },
  { label: 'Klek', value: 'Klek' },
  { label: 'Klembivka', value: 'Klembivka' },
  { label: 'Klembów', value: 'Klembów' },
  { label: 'Klemzig', value: 'Klemzig' },
  { label: 'Klenak', value: 'Klenak' },
  { label: 'Klenčí Pod Čerchovem', value: 'Klenčí Pod Čerchovem' },
  { label: 'Klenica', value: 'Klenica' },
  { label: 'Klenje', value: 'Klenje' },
  { label: 'Klenovnik', value: 'Klenovnik' },
  { label: 'Klenovyy', value: 'Klenovyy' },
  { label: 'Klepikovskiy Rayon', value: 'Klepikovskiy Rayon' },
  { label: 'Klepinino', value: 'Klepinino' },
  { label: 'Klepp', value: 'Klepp' },
  { label: 'Klerksdorp', value: 'Klerksdorp' },
  { label: 'Kleszczele', value: 'Kleszczele' },
  { label: 'Kleszczewo', value: 'Kleszczewo' },
  { label: 'Kleszczów', value: 'Kleszczów' },
  { label: 'Kletnya', value: 'Kletnya' },
  { label: 'Kletnyanskiy Rayon', value: 'Kletnyanskiy Rayon' },
  { label: 'Kletskaya', value: 'Kletskaya' },
  { label: 'Klettbach', value: 'Klettbach' },
  { label: 'Klevan', value: 'Klevan' },
  { label: 'Kleve', value: 'Kleve' },
  { label: 'Klichaw', value: 'Klichaw' },
  { label: 'Klichka', value: 'Klichka' },
  { label: 'Klickitat County', value: 'Klickitat County' },
  { label: 'Klieken', value: 'Klieken' },
  { label: 'Klietz', value: 'Klietz' },
  { label: 'Klikuszowa', value: 'Klikuszowa' },
  { label: 'Klimavichy', value: 'Klimavichy' },
  { label: 'Klimkovice', value: 'Klimkovice' },
  { label: 'Klimmen', value: 'Klimmen' },
  { label: 'Klimontów', value: 'Klimontów' },
  { label: 'Klimontów', value: 'Klimontów' },
  { label: 'Klimovo', value: 'Klimovo' },
  { label: 'Klimovsk', value: 'Klimovsk' },
  { label: 'Klimovskiy Rayon', value: 'Klimovskiy Rayon' },
  { label: 'Klin', value: 'Klin' },
  { label: 'Klinan', value: 'Klinan' },
  { label: 'Klinan', value: 'Klinan' },
  { label: 'Kling', value: 'Kling' },
  { label: 'Klingenbach', value: 'Klingenbach' },
  { label: 'Klingenberg Am Main', value: 'Klingenberg Am Main' },
  { label: 'Klingenmünster', value: 'Klingenmünster' },
  { label: 'Klingenstein', value: 'Klingenstein' },
  { label: 'Klingenthal', value: 'Klingenthal' },
  { label: 'Klingnau', value: 'Klingnau' },
  { label: 'Klink', value: 'Klink' },
  { label: 'Klinskiy Rayon', value: 'Klinskiy Rayon' },
  { label: 'Klintehamn', value: 'Klintehamn' },
  { label: 'Klintsovskiy Rayon', value: 'Klintsovskiy Rayon' },
  { label: 'Klintsy', value: 'Klintsy' },
  { label: 'Klippan', value: 'Klippan' },
  { label: 'Klipphausen', value: 'Klipphausen' },
  { label: 'Klírou', value: 'Klírou' },
  { label: 'Klis', value: 'Klis' },
  { label: 'Klishkivtsi', value: 'Klishkivtsi' },
  { label: 'Klisura', value: 'Klisura' },
  { label: 'Klitten', value: 'Klitten' },
  { label: 'Ključ', value: 'Ključ' },
  { label: 'Klobouky', value: 'Klobouky' },
  { label: 'Kłobuck', value: 'Kłobuck' },
  { label: 'Klobuky', value: 'Klobuky' },
  { label: 'Klöch', value: 'Klöch' },
  { label: 'Kłoczew', value: 'Kłoczew' },
  { label: 'Kłodawa', value: 'Kłodawa' },
  { label: 'Kłodawa', value: 'Kłodawa' },
  { label: 'Kłodzko', value: 'Kłodzko' },
  { label: 'Kloetinge', value: 'Kloetinge' },
  { label: 'Kløfta', value: 'Kløfta' },
  { label: 'Kłomnice', value: 'Kłomnice' },
  { label: 'Klonhtoug', value: 'Klonhtoug' },
  { label: 'Klonowa', value: 'Klonowa' },
  { label: 'Klos', value: 'Klos' },
  { label: 'Kloster', value: 'Kloster' },
  { label: 'Kloster Lehnin', value: 'Kloster Lehnin' },
  { label: 'Klösterle', value: 'Klösterle' },
  { label: 'Klosterlechfeld', value: 'Klosterlechfeld' },
  { label: 'Klostermansfeld', value: 'Klostermansfeld' },
  { label: 'Klosterneuburg', value: 'Klosterneuburg' },
  { label: 'Klosters Serneus', value: 'Klosters Serneus' },
  { label: 'Kloten', value: 'Kloten' },
  { label: 'Kloten / Balsberg', value: 'Kloten / Balsberg' },
  { label: 'Kloten / Freienberg (Chanzler-Chlini Chaseren)', value: 'Kloten / Freienberg (Chanzler-Chlini Chaseren)' },
  { label: 'Kloten / Geissberg', value: 'Kloten / Geissberg' },
  { label: 'Kloten / Holberg', value: 'Kloten / Holberg' },
  { label: 'Kloten / Horainli', value: 'Kloten / Horainli' },
  { label: 'Kloten / Hostrass', value: 'Kloten / Hostrass' },
  { label: 'Kloten / Kloten (Zentrum)', value: 'Kloten / Kloten (Zentrum)' },
  { label: 'Kloten / Rütlen', value: 'Kloten / Rütlen' },
  { label: 'Kloten / Spitz', value: 'Kloten / Spitz' },
  { label: 'Klotten', value: 'Klotten' },
  { label: 'Klötze', value: 'Klötze' },
  { label: 'Kloulklubed', value: 'Kloulklubed' },
  { label: 'Kluang', value: 'Kluang' },
  { label: 'Kluczbork', value: 'Kluczbork' },
  { label: 'Klucze', value: 'Klucze' },
  { label: 'Kluki', value: 'Kluki' },
  { label: 'Klundert', value: 'Klundert' },
  { label: 'Klungkung', value: 'Klungkung' },
  { label: 'Kluse', value: 'Kluse' },
  { label: 'Klüsserath', value: 'Klüsserath' },
  { label: 'Kluszkowce', value: 'Kluszkowce' },
  { label: 'Klütz', value: 'Klütz' },
  { label: 'Klwów', value: 'Klwów' },
  { label: 'Klyavlino', value: 'Klyavlino' },
  { label: 'Klyazminskoe Reservoir', value: 'Klyazminskoe Reservoir' },
  { label: 'Klyetsk', value: 'Klyetsk' },
  { label: 'Klyetski Rayon', value: 'Klyetski Rayon' },
  { label: 'Klyuchevsk', value: 'Klyuchevsk' },
  { label: 'Klyuchevskiy', value: 'Klyuchevskiy' },
  { label: 'Klyuchi', value: 'Klyuchi' },
  { label: 'Klyuchi', value: 'Klyuchi' },
  { label: 'Knaphill', value: 'Knaphill' },
  { label: 'Knappskog', value: 'Knappskog' },
  { label: 'Knappstad', value: 'Knappstad' },
  { label: 'Knäred', value: 'Knäred' },
  { label: 'Knaresborough', value: 'Knaresborough' },
  { label: 'Knarvik', value: 'Knarvik' },
  { label: 'Knebworth', value: 'Knebworth' },
  { label: 'Knesselare', value: 'Knesselare' },
  { label: 'Knetzgau', value: 'Knetzgau' },
  { label: 'Knevichi', value: 'Knevichi' },
  { label: 'Kněždub', value: 'Kněždub' },
  { label: 'Kneževi Vinogradi', value: 'Kneževi Vinogradi' },
  { label: 'Kneževo', value: 'Kneževo' },
  { label: 'Knezha', value: 'Knezha' },
  { label: 'Knežica', value: 'Knežica' },
  { label: 'Kněžice', value: 'Kněžice' },
  { label: 'Kněžmost', value: 'Kněžmost' },
  { label: 'Kněžpole', value: 'Kněžpole' },
  { label: 'Knićanin', value: 'Knićanin' },
  { label: 'Knightdale', value: 'Knightdale' },
  { label: 'Knighton', value: 'Knighton' },
  { label: 'Knightsen', value: 'Knightsen' },
  { label: 'Knightstown', value: 'Knightstown' },
  { label: 'Knik-Fairview', value: 'Knik-Fairview' },
  { label: 'Knin', value: 'Knin' },
  { label: 'Knislinge', value: 'Knislinge' },
  { label: 'Knittelfeld', value: 'Knittelfeld' },
  { label: 'Knittelsheim', value: 'Knittelsheim' },
  { label: 'Knittlingen', value: 'Knittlingen' },
  { label: 'Knivsta', value: 'Knivsta' },
  { label: 'Knjazevac', value: 'Knjazevac' },
  { label: 'Knob Noster', value: 'Knob Noster' },
  { label: 'Knocklyon', value: 'Knocklyon' },
  { label: 'Knockpatrick', value: 'Knockpatrick' },
  { label: 'Knokke-Heist', value: 'Knokke-Heist' },
  { label: 'Knollwood', value: 'Knollwood' },
  { label: 'Knonau', value: 'Knonau' },
  { label: 'Knott County', value: 'Knott County' },
  { label: 'Knottingley', value: 'Knottingley' },
  { label: 'Knottsville', value: 'Knottsville' },
  { label: 'Knowle', value: 'Knowle' },
  { label: 'Knowsley', value: 'Knowsley' },
  { label: 'Knox', value: 'Knox' },
  { label: 'Knox', value: 'Knox' },
  { label: 'Knox', value: 'Knox' },
  { label: 'Knox City', value: 'Knox City' },
  { label: 'Knox County', value: 'Knox County' },
  { label: 'Knox County', value: 'Knox County' },
  { label: 'Knox County', value: 'Knox County' },
  { label: 'Knox County', value: 'Knox County' },
  { label: 'Knox County', value: 'Knox County' },
  { label: 'Knox County', value: 'Knox County' },
  { label: 'Knox County', value: 'Knox County' },
  { label: 'Knox County', value: 'Knox County' },
  { label: 'Knox County', value: 'Knox County' },
  { label: 'Knoxfield', value: 'Knoxfield' },
  { label: 'Knoxville', value: 'Knoxville' },
  { label: 'Knoxville', value: 'Knoxville' },
  { label: 'Knoxville', value: 'Knoxville' },
  { label: 'Knoxville', value: 'Knoxville' },
  { label: 'Knurów', value: 'Knurów' },
  { label: 'Knutange', value: 'Knutange' },
  { label: 'Knutsford', value: 'Knutsford' },
  { label: 'Knutwil', value: 'Knutwil' },
  { label: 'Knyaginino', value: 'Knyaginino' },
  { label: 'Knyaze-Volkonskoye', value: 'Knyaze-Volkonskoye' },
  { label: 'Knyazhichi', value: 'Knyazhichi' },
  { label: 'Knyazhpogostskiy Rayon', value: 'Knyazhpogostskiy Rayon' },
  { label: 'Knysna', value: 'Knysna' },
  { label: 'Knyszyn', value: 'Knyszyn' },
  { label: 'Ko Chang Tai', value: 'Ko Chang Tai' },
  { label: 'Ko Lanta', value: 'Ko Lanta' },
  { label: 'Ko Olina', value: 'Ko Olina' },
  { label: 'Ko Pha Ngan', value: 'Ko Pha Ngan' },
  { label: 'Ko Samui', value: 'Ko Samui' },
  { label: 'Ko Si Chang', value: 'Ko Si Chang' },
  { label: 'Koani', value: 'Koani' },
  { label: 'Koani Ndogo', value: 'Koani Ndogo' },
  { label: 'Koath', value: 'Koath' },
  { label: 'Kobarid', value: 'Kobarid' },
  { label: 'Kobayashi', value: 'Kobayashi' },
  { label: 'Kobayashi Shi', value: 'Kobayashi Shi' },
  { label: 'Kobe', value: 'Kobe' },
  { label: 'Kobeliaky', value: 'Kobeliaky' },
  { label: 'København', value: 'København' },
  { label: 'Kobenz', value: 'Kobenz' },
  { label: 'Kobeřice', value: 'Kobeřice' },
  { label: 'Kobern-Gondorf', value: 'Kobern-Gondorf' },
  { label: 'Kobersdorf', value: 'Kobersdorf' },
  { label: 'Kobiernice', value: 'Kobiernice' },
  { label: 'Kobierzyce', value: 'Kobierzyce' },
  { label: 'Kobilja Glava', value: 'Kobilja Glava' },
  { label: 'Kobilje', value: 'Kobilje' },
  { label: 'Kobiór', value: 'Kobiór' },
  { label: 'Koblach', value: 'Koblach' },
  { label: 'Koblenz', value: 'Koblenz' },
  { label: 'Koblenz', value: 'Koblenz' },
  { label: 'Kobleve', value: 'Kobleve' },
  { label: 'Kobrinskoye', value: 'Kobrinskoye' },
  { label: 'Kobryn', value: 'Kobryn' },
  { label: 'Kobuleti', value: 'Kobuleti' },
  { label: 'Kobyayskiy Rayon', value: 'Kobyayskiy Rayon' },
  { label: 'Kobyla Góra', value: 'Kobyla Góra' },
  { label: 'Kobylanka', value: 'Kobylanka' },
  { label: 'Kobylanka', value: 'Kobylanka' },
  { label: 'Kobylí', value: 'Kobylí' },
  { label: 'Kobylin', value: 'Kobylin' },
  { label: 'Kobyłka', value: 'Kobyłka' },
  { label: 'Kobylnica', value: 'Kobylnica' },
  { label: 'Kobylnica', value: 'Kobylnica' },
  { label: 'Kobyzhcha', value: 'Kobyzhcha' },
  { label: 'Kocaali', value: 'Kocaali' },
  { label: 'Kocaköy', value: 'Kocaköy' },
  { label: 'Koçarlı', value: 'Koçarlı' },
  { label: 'Kocasinan', value: 'Kocasinan' },
  { label: 'Kocēni', value: 'Kocēni' },
  { label: 'Kočerin', value: 'Kočerin' },
  { label: 'Kočevje', value: 'Kočevje' },
  { label: 'Kochang', value: 'Kochang' },
  { label: 'Kochani', value: 'Kochani' },
  { label: 'Kochanowice', value: 'Kochanowice' },
  { label: 'Kochel', value: 'Kochel' },
  { label: 'Kochenëvo', value: 'Kochenëvo' },
  { label: 'Kocherinovo', value: 'Kocherinovo' },
  { label: 'Kochetok', value: 'Kochetok' },
  { label: 'Kochevo', value: 'Kochevo' },
  { label: 'Kōchi', value: 'Kōchi' },
  { label: 'Kochki', value: 'Kochki' },
  { label: 'Kochkor-Ata', value: 'Kochkor-Ata' },
  { label: 'Kochkurovo', value: 'Kochkurovo' },
  { label: 'Kochkurovskiy Rayon', value: 'Kochkurovskiy Rayon' },
  { label: 'Kochol', value: 'Kochol' },
  { label: 'Kochubey', value: 'Kochubey' },
  { label: 'Kochubeyevskoye', value: 'Kochubeyevskoye' },
  { label: 'Kocierzew Południowy', value: 'Kocierzew Południowy' },
  { label: 'Kock', value: 'Kock' },
  { label: 'Kocs', value: 'Kocs' },
  { label: 'Kocsér', value: 'Kocsér' },
  { label: 'Kocsord', value: 'Kocsord' },
  { label: 'Koczała', value: 'Koczała' },
  { label: 'Kodagu', value: 'Kodagu' },
  { label: 'Kodaikanal', value: 'Kodaikanal' },
  { label: 'Kodaira-Shi', value: 'Kodaira-Shi' },
  { label: 'Kodala', value: 'Kodala' },
  { label: 'Kodamachō-Kodamaminami', value: 'Kodamachō-Kodamaminami' },
  { label: 'Kodar', value: 'Kodar' },
  { label: 'Kodarma', value: 'Kodarma' },
  { label: 'Kode', value: 'Kode' },
  { label: 'Kodeń', value: 'Kodeń' },
  { label: 'Kodersdorf', value: 'Kodersdorf' },
  { label: 'Kodiak', value: 'Kodiak' },
  { label: 'Kodiak Island Borough', value: 'Kodiak Island Borough' },
  { label: 'Kodiak Station', value: 'Kodiak Station' },
  { label: 'Kodigenahalli', value: 'Kodigenahalli' },
  { label: 'Kodinar', value: 'Kodinar' },
  { label: 'Kodino', value: 'Kodino' },
  { label: 'Kodinsk', value: 'Kodinsk' },
  { label: 'Köditz', value: 'Köditz' },
  { label: 'Kodlipet', value: 'Kodlipet' },
  { label: 'Ködnitz', value: 'Ködnitz' },
  { label: 'Kodoli', value: 'Kodoli' },
  { label: 'Kodra', value: 'Kodra' },
  { label: 'Kodumudi', value: 'Kodumudi' },
  { label: 'Kodungallur', value: 'Kodungallur' },
  { label: 'Kodyma', value: 'Kodyma' },
  { label: 'Kodyms’Kyy Rayon', value: 'Kodyms’Kyy Rayon' },
  { label: 'Koedijk', value: 'Koedijk' },
  { label: 'Koekange', value: 'Koekange' },
  { label: 'Koekelare', value: 'Koekelare' },
  { label: 'Koelwar', value: 'Koelwar' },
  { label: 'Kœnigsmacker', value: 'Kœnigsmacker' },
  { label: 'Koerich', value: 'Koerich' },
  { label: 'Koeru', value: 'Koeru' },
  { label: 'Koesan', value: 'Koesan' },
  { label: 'Koewacht', value: 'Koewacht' },
  { label: 'Kofçaz', value: 'Kofçaz' },
  { label: 'Kofelē', value: 'Kofelē' },
  { label: 'Köfering', value: 'Köfering' },
  { label: 'Kofínou', value: 'Kofínou' },
  { label: 'Köflach', value: 'Köflach' },
  { label: 'Koforidua', value: 'Koforidua' },
  { label: 'Kōfu', value: 'Kōfu' },
  { label: 'Kōfu-Shi', value: 'Kōfu-Shi' },
  { label: 'Koga', value: 'Koga' },
  { label: 'Koga', value: 'Koga' },
  { label: 'Kogalym', value: 'Kogalym' },
  { label: 'Koganei-Shi', value: 'Koganei-Shi' },
  { label: 'Kogarah', value: 'Kogarah' },
  { label: 'Kogarah Bay', value: 'Kogarah Bay' },
  { label: 'Koga-Shi', value: 'Koga-Shi' },
  { label: 'Koga-Shi', value: 'Koga-Shi' },
  { label: 'Køge', value: 'Køge' },
  { label: 'Køge Kommune', value: 'Køge Kommune' },
  { label: 'Koggala', value: 'Koggala' },
  { label: 'Koggenland', value: 'Koggenland' },
  { label: 'Kogon', value: 'Kogon' },
  { label: 'Kogota', value: 'Kogota' },
  { label: 'Koh Kong', value: 'Koh Kong' },
  { label: 'Koh Tao', value: 'Koh Tao' },
  { label: 'Kohanabad', value: 'Kohanabad' },
  { label: 'Kohat', value: 'Kohat' },
  { label: 'Kohfidisch', value: 'Kohfidisch' },
  { label: 'Kohgiluyeh', value: 'Kohgiluyeh' },
  { label: 'Kohila', value: 'Kohila' },
  { label: 'Kohila Vald', value: 'Kohila Vald' },
  { label: 'Kohima', value: 'Kohima' },
  { label: 'Kohlberg', value: 'Kohlberg' },
  { label: 'Kohlberg', value: 'Kohlberg' },
  { label: 'Kohlberg', value: 'Kohlberg' },
  { label: 'Kohler', value: 'Kohler' },
  { label: 'Kohlschwarz', value: 'Kohlschwarz' },
  { label: 'Kohlu', value: 'Kohlu' },
  { label: 'Kohnush', value: 'Kohnush' },
  { label: 'Kohror Pakka', value: 'Kohror Pakka' },
  { label: 'Kohtla-Järve', value: 'Kohtla-Järve' },
  { label: 'Kohtla-Nõmme', value: 'Kohtla-Nõmme' },
  { label: 'Koidu', value: 'Koidu' },
  { label: 'Koíla', value: 'Koíla' },
  { label: 'Koilás', value: 'Koilás' },
  { label: 'Koímisi', value: 'Koímisi' },
  { label: 'Koina', value: 'Koina' },
  { label: 'Koinadugu District', value: 'Koinadugu District' },
  { label: 'Kojetín', value: 'Kojetín' },
  { label: 'Kojonup', value: 'Kojonup' },
  { label: 'Kojur', value: 'Kojur' },
  { label: 'Kóka', value: 'Kóka' },
  { label: 'Kōka-Shi', value: 'Kōka-Shi' },
  { label: 'Kokemäki', value: 'Kokemäki' },
  { label: 'Kokhanava', value: 'Kokhanava' },
  { label: 'Kokhma', value: 'Kokhma' },
  { label: 'Koki', value: 'Koki' },
  { label: 'Kokino', value: 'Kokino' },
  { label: 'Kokkedal', value: 'Kokkedal' },
  { label: 'Kokkíni Cháni', value: 'Kokkíni Cháni' },
  { label: 'Kokkinóchoma', value: 'Kokkinóchoma' },
  { label: 'Kokkinotrimithiá', value: 'Kokkinotrimithiá' },
  { label: 'Kokkola', value: 'Kokkola' },
  { label: 'Kokkónion', value: 'Kokkónion' },
  { label: 'Koknese', value: 'Koknese' },
  { label: 'Kokoda', value: 'Kokoda' },
  { label: 'Kokofata', value: 'Kokofata' },
  { label: 'Kokologo', value: 'Kokologo' },
  { label: 'Kokomo', value: 'Kokomo' },
  { label: 'Kokopo', value: 'Kokopo' },
  { label: 'Kokorevka', value: 'Kokorevka' },
  { label: 'Kokory', value: 'Kokory' },
  { label: 'Kokoshkino', value: 'Kokoshkino' },
  { label: 'Kokotów', value: 'Kokotów' },
  { label: 'Kokrajhar', value: 'Kokrajhar' },
  { label: 'Kokrek', value: 'Kokrek' },
  { label: 'Kokrica', value: 'Kokrica' },
  { label: 'Koksan', value: 'Koksan' },
  { label: 'Kokshetau', value: 'Kokshetau' },
  { label: 'Koksijde', value: 'Koksijde' },
  { label: 'Koksovyy', value: 'Koksovyy' },
  { label: 'Kokstad', value: 'Kokstad' },
  { label: 'Koktal', value: 'Koktal' },
  { label: 'Koktebel', value: 'Koktebel' },
  { label: 'Kokterek', value: 'Kokterek' },
  { label: 'Kokubu-Matsuki', value: 'Kokubu-Matsuki' },
  { label: 'Kokubunji', value: 'Kokubunji' },
  { label: 'Kokubunji-Shi', value: 'Kokubunji-Shi' },
  { label: 'Kokuy', value: 'Kokuy' },
  { label: 'Kol’Chugino', value: 'Kol’Chugino' },
  { label: 'Kol’Chugino', value: 'Kol’Chugino' },
  { label: 'Kol’Chuginskiy Rayon', value: 'Kol’Chuginskiy Rayon' },
  { label: 'Kol’Skiy Rayon', value: 'Kol’Skiy Rayon' },
  { label: 'Kol’Tsovo', value: 'Kol’Tsovo' },
  { label: 'Kol’Tsovo', value: 'Kol’Tsovo' },
  { label: 'Kola', value: 'Kola' },
  { label: 'Kołaczkowo', value: 'Kołaczkowo' },
  { label: 'Kołaczyce', value: 'Kołaczyce' },
  { label: 'Kolambugan', value: 'Kolambugan' },
  { label: 'Kolanjan', value: 'Kolanjan' },
  { label: 'Kolanukonda', value: 'Kolanukonda' },
  { label: 'Kolape', value: 'Kolape' },
  { label: 'Kolape', value: 'Kolape' },
  { label: 'Kolar', value: 'Kolar' },
  { label: 'Kolaras', value: 'Kolaras' },
  { label: 'Kolari', value: 'Kolari' },
  { label: 'Kolarovo', value: 'Kolarovo' },
  { label: 'Kolárovo', value: 'Kolárovo' },
  { label: 'Kolasib', value: 'Kolasib' },
  { label: 'Kolašin', value: 'Kolašin' },
  { label: 'Kolbäck', value: 'Kolbäck' },
  { label: 'Kołbaskowo', value: 'Kołbaskowo' },
  { label: 'Kolbermoor', value: 'Kolbermoor' },
  { label: 'Kołbiel', value: 'Kołbiel' },
  { label: 'Kolbingen', value: 'Kolbingen' },
  { label: 'Kölbingen', value: 'Kölbingen' },
  { label: 'Kolbudy', value: 'Kolbudy' },
  { label: 'Kolbuszowa', value: 'Kolbuszowa' },
  { label: 'Kolchanovo', value: 'Kolchanovo' },
  { label: 'Kolchikón', value: 'Kolchikón' },
  { label: 'Kołczygłowy', value: 'Kołczygłowy' },
  { label: 'Kolda', value: 'Kolda' },
  { label: 'Kolda Department', value: 'Kolda Department' },
  { label: 'Kolding', value: 'Kolding' },
  { label: 'Kolding Kommune', value: 'Kolding Kommune' },
  { label: 'Kolea', value: 'Kolea' },
  { label: 'Koleczkowo', value: 'Koleczkowo' },
  { label: 'Kolhapur', value: 'Kolhapur' },
  { label: 'Kolho', value: 'Kolho' },
  { label: 'Kolhorn', value: 'Kolhorn' },
  { label: 'Kolín', value: 'Kolín' },
  { label: 'Kolindrós', value: 'Kolindrós' },
  { label: 'Kolinec', value: 'Kolinec' },
  { label: 'Kolitzheim', value: 'Kolitzheim' },
  { label: 'Kolkata', value: 'Kolkata' },
  { label: 'Kolkhozobod', value: 'Kolkhozobod' },
  { label: 'Kolkwitz', value: 'Kolkwitz' },
  { label: 'Kollam', value: 'Kollam' },
  { label: 'Kollbrunn', value: 'Kollbrunn' },
  { label: 'Kollbrunn / Kollbrunn (Dorfkern)', value: 'Kollbrunn / Kollbrunn (Dorfkern)' },
  { label: 'Kölleda', value: 'Kölleda' },
  { label: 'Kollegal', value: 'Kollegal' },
  { label: 'Kölliken', value: 'Kölliken' },
  { label: 'Kollmar', value: 'Kollmar' },
  { label: 'Kollnburg', value: 'Kollnburg' },
  { label: 'Kölln-Reisiek', value: 'Kölln-Reisiek' },
  { label: 'Kollo', value: 'Kollo' },
  { label: 'Kollo Department', value: 'Kollo Department' },
  { label: 'Kollum', value: 'Kollum' },
  { label: 'Kollumerzwaag', value: 'Kollumerzwaag' },
  { label: 'Köln', value: 'Köln' },
  { label: 'Kolno', value: 'Kolno' },
  { label: 'Koło', value: 'Koło' },
  { label: 'Koloa', value: 'Koloa' },
  { label: 'Kolobovo', value: 'Kolobovo' },
  { label: 'Kołobrzeg', value: 'Kołobrzeg' },
  { label: 'Kolochava', value: 'Kolochava' },
  { label: 'Kolodenka', value: 'Kolodenka' },
  { label: 'Kolodeznoye', value: 'Kolodeznoye' },
  { label: 'Kolodeznyy', value: 'Kolodeznyy' },
  { label: 'Kologriv', value: 'Kologriv' },
  { label: 'Kolokani', value: 'Kolokani' },
  { label: 'Kolomak', value: 'Kolomak' },
  { label: 'Kolomenskiy Rayon', value: 'Kolomenskiy Rayon' },
  { label: 'Kolomenskoye', value: 'Kolomenskoye' },
  { label: 'Kolomna', value: 'Kolomna' },
  { label: 'Kolomyagi', value: 'Kolomyagi' },
  { label: 'Kolomyia', value: 'Kolomyia' },
  { label: 'Kolomyys’Kyy Rayon', value: 'Kolomyys’Kyy Rayon' },
  { label: 'Kolondiéba', value: 'Kolondiéba' },
  { label: 'Kolonga', value: 'Kolonga' },
  { label: 'Koloni', value: 'Koloni' },
  { label: 'Kolonia', value: 'Kolonia' },
  { label: 'Kolonia Municipality', value: 'Kolonia Municipality' },
  { label: 'Kolonia Town', value: 'Kolonia Town' },
  { label: 'Koloniya Zastav’Ye', value: 'Koloniya Zastav’Ye' },
  { label: 'Kolonnawa', value: 'Kolonnawa' },
  { label: 'Kolonowskie', value: 'Kolonowskie' },
  { label: 'Kolor', value: 'Kolor' },
  { label: 'Koloski', value: 'Koloski' },
  { label: 'Kolosovka', value: 'Kolosovka' },
  { label: 'Kolossi', value: 'Kolossi' },
  { label: 'Kolotsky', value: 'Kolotsky' },
  { label: 'Kolpashevo', value: 'Kolpashevo' },
  { label: 'Kolpino', value: 'Kolpino' },
  { label: 'Kolpny', value: 'Kolpny' },
  { label: 'Kolsass', value: 'Kolsass' },
  { label: 'Kolsassberg', value: 'Kolsassberg' },
  { label: 'Kolsko', value: 'Kolsko' },
  { label: 'Kolsva', value: 'Kolsva' },
  { label: 'Kolt', value: 'Kolt' },
  { label: 'Koltubanovskiy', value: 'Koltubanovskiy' },
  { label: 'Koltushi', value: 'Koltushi' },
  { label: 'Kolubara', value: 'Kolubara' },
  { label: 'Kolumbug', value: 'Kolumbug' },
  { label: 'Koluszki', value: 'Koluszki' },
  { label: 'Kolut', value: 'Kolut' },
  { label: 'Kolvereid', value: 'Kolvereid' },
  { label: 'Kolympári', value: 'Kolympári' },
  { label: 'Kolyshley', value: 'Kolyshley' },
  { label: 'Kolyubakino', value: 'Kolyubakino' },
  { label: 'Kolyvan’', value: 'Kolyvan’' },
  { label: 'Komádi', value: 'Komádi' },
  { label: 'Komae-Shi', value: 'Komae-Shi' },
  { label: 'Komagane', value: 'Komagane' },
  { label: 'Komagane-Shi', value: 'Komagane-Shi' },
  { label: 'Komaki', value: 'Komaki' },
  { label: 'Komaki-Shi', value: 'Komaki-Shi' },
  { label: 'Komańcza', value: 'Komańcza' },
  { label: 'Komarichi', value: 'Komarichi' },
  { label: 'Komarichskiy Rayon', value: 'Komarichskiy Rayon' },
  { label: 'Komarikhinskiy', value: 'Komarikhinskiy' },
  { label: 'Komarno', value: 'Komarno' },
  { label: 'Komárno', value: 'Komárno' },
  { label: 'Komárom', value: 'Komárom' },
  { label: 'Komárov', value: 'Komárov' },
  { label: 'Komarovo', value: 'Komarovo' },
  { label: 'Komarówka Podlaska', value: 'Komarówka Podlaska' },
  { label: 'Komarów-Osada', value: 'Komarów-Osada' },
  { label: 'Komatipoort', value: 'Komatipoort' },
  { label: 'Komatsu', value: 'Komatsu' },
  { label: 'Komatsushima Shi', value: 'Komatsushima Shi' },
  { label: 'Komatsushimachō', value: 'Komatsushimachō' },
  { label: 'Kombai', value: 'Kombai' },
  { label: 'Kombissiri', value: 'Kombissiri' },
  { label: 'Kombo Central District', value: 'Kombo Central District' },
  { label: 'Kombo East District', value: 'Kombo East District' },
  { label: 'Kombo North District', value: 'Kombo North District' },
  { label: 'Kombo Saint Mary District', value: 'Kombo Saint Mary District' },
  { label: 'Kombo South District', value: 'Kombo South District' },
  { label: 'Kombolcha', value: 'Kombolcha' },
  { label: 'Komchén', value: 'Komchén' },
  { label: 'Komeh', value: 'Komeh' },
  { label: 'Komen', value: 'Komen' },
  { label: 'Komenda', value: 'Komenda' },
  { label: 'Komendantsky Aerodrom', value: 'Komendantsky Aerodrom' },
  { label: 'Komijan', value: 'Komijan' },
  { label: 'Komin', value: 'Komin' },
  { label: 'Komiža', value: 'Komiža' },
  { label: 'Komletinci', value: 'Komletinci' },
  { label: 'Komló', value: 'Komló' },
  { label: 'Kömlőd', value: 'Kömlőd' },
  { label: 'Komlói Járás', value: 'Komlói Járás' },
  { label: 'Kommunar', value: 'Kommunar' },
  { label: 'Kommunar', value: 'Kommunar' },
  { label: 'Kommunar', value: 'Kommunar' },
  { label: 'Kommunarka', value: 'Kommunarka' },
  { label: 'Kommunisticheskiy', value: 'Kommunisticheskiy' },
  { label: 'Komniná', value: 'Komniná' },
  { label: 'Komo Margarima', value: 'Komo Margarima' },
  { label: 'Komodo', value: 'Komodo' },
  { label: 'Komono', value: 'Komono' },
  { label: 'Komorní Lhotka', value: 'Komorní Lhotka' },
  { label: 'Komorniki', value: 'Komorniki' },
  { label: 'Komoro', value: 'Komoro' },
  { label: 'Komoro', value: 'Komoro' },
  { label: 'Komoro Shi', value: 'Komoro Shi' },
  { label: 'Komorów', value: 'Komorów' },
  { label: 'Komotiní', value: 'Komotiní' },
  { label: 'Kompaniyivka', value: 'Kompaniyivka' },
  { label: 'Kompiam Ambum', value: 'Kompiam Ambum' },
  { label: 'Kompolt', value: 'Kompolt' },
  { label: 'Kompóti', value: 'Kompóti' },
  { label: 'Komprachcice', value: 'Komprachcice' },
  { label: 'Komshecheh', value: 'Komshecheh' },
  { label: 'Komsomol’Sk', value: 'Komsomol’Sk' },
  { label: 'Komsomol’Skiy', value: 'Komsomol’Skiy' },
  { label: 'Komsomol’Skiy', value: 'Komsomol’Skiy' },
  { label: 'Komsomol’Skiy Rayon', value: 'Komsomol’Skiy Rayon' },
  { label: 'Komsomol’Skiy Rayon', value: 'Komsomol’Skiy Rayon' },
  { label: 'Komsomol’Skoye', value: 'Komsomol’Skoye' },
  { label: 'Komsomolets', value: 'Komsomolets' },
  { label: 'Komsomolets', value: 'Komsomolets' },
  { label: 'Komsomolets', value: 'Komsomolets' },
  { label: 'Komsomolsk-On-Amur', value: 'Komsomolsk-On-Amur' },
  { label: 'Komsomolskoye', value: 'Komsomolskoye' },
  { label: 'Komusan Il-Tong', value: 'Komusan Il-Tong' },
  { label: 'Komyshany', value: 'Komyshany' },
  { label: 'Komyshuvakha', value: 'Komyshuvakha' },
  { label: 'Komysh-Zorya', value: 'Komysh-Zorya' },
  { label: 'Kon Tum', value: 'Kon Tum' },
  { label: 'Konak', value: 'Konak' },
  { label: 'Konak', value: 'Konak' },
  { label: 'Konakovo', value: 'Konakovo' },
  { label: 'Konakovskiy Rayon', value: 'Konakovskiy Rayon' },
  { label: 'Konakridee', value: 'Konakridee' },
  { label: 'Kōnan', value: 'Kōnan' },
  { label: 'Kōnan', value: 'Kōnan' },
  { label: 'Konan-Shi', value: 'Konan-Shi' },
  { label: 'Kōnan-Shi', value: 'Kōnan-Shi' },
  { label: 'Konanur', value: 'Konanur' },
  { label: 'Konar Torshan', value: 'Konar Torshan' },
  { label: 'Konarak', value: 'Konarak' },
  { label: 'Konarka', value: 'Konarka' },
  { label: 'Konartakhteh', value: 'Konartakhteh' },
  { label: 'Konary', value: 'Konary' },
  { label: 'Konavle', value: 'Konavle' },
  { label: 'Konawa', value: 'Konawa' },
  { label: 'Končanica', value: 'Končanica' },
  { label: 'Konče', value: 'Konče' },
  { label: 'Konch', value: 'Konch' },
  { label: 'Kończyce', value: 'Kończyce' },
  { label: 'Kończyce Małe', value: 'Kończyce Małe' },
  { label: 'Kończyce Wielkie', value: 'Kończyce Wielkie' },
  { label: 'Kondagaon', value: 'Kondagaon' },
  { label: 'Kondalwadi', value: 'Kondalwadi' },
  { label: 'Kondapalle', value: 'Kondapalle' },
  { label: 'Konde', value: 'Konde' },
  { label: 'Kondhali', value: 'Kondhali' },
  { label: 'Kondinin', value: 'Kondinin' },
  { label: 'Kondinskoye', value: 'Kondinskoye' },
  { label: 'Kondoa', value: 'Kondoa' },
  { label: 'Kondol’', value: 'Kondol’' },
  { label: 'Kondopoga', value: 'Kondopoga' },
  { label: 'Kondor', value: 'Kondor' },
  { label: 'Kondoros', value: 'Kondoros' },
  { label: 'Kondovo', value: 'Kondovo' },
  { label: 'Kondratovo', value: 'Kondratovo' },
  { label: 'Kondratyevo', value: 'Kondratyevo' },
  { label: 'Kondrovo', value: 'Kondrovo' },
  { label: 'Koné', value: 'Koné' },
  { label: 'Köneürgench', value: 'Köneürgench' },
  { label: 'Konëvo', value: 'Konëvo' },
  { label: 'Konganapuram', value: 'Konganapuram' },
  { label: 'Köngen', value: 'Köngen' },
  { label: 'Kongens Lyngby', value: 'Kongens Lyngby' },
  { label: 'Köngernheim', value: 'Köngernheim' },
  { label: 'Kongolo', value: 'Kongolo' },
  { label: 'Kongoussi', value: 'Kongoussi' },
  { label: 'Kongsberg', value: 'Kongsberg' },
  { label: 'Kongsvinger', value: 'Kongsvinger' },
  { label: 'Kongwa', value: 'Kongwa' },
  { label: 'Konia', value: 'Konia' },
  { label: 'Koniaków', value: 'Koniaków' },
  { label: 'Konibodom', value: 'Konibodom' },
  { label: 'Konice', value: 'Konice' },
  { label: 'Koni-Djodjo', value: 'Koni-Djodjo' },
  { label: 'Koniecpol', value: 'Koniecpol' },
  { label: 'Konieczkowa', value: 'Konieczkowa' },
  { label: 'Königheim', value: 'Königheim' },
  { label: 'Königs Wusterhausen', value: 'Königs Wusterhausen' },
  { label: 'Königsbach-Stein', value: 'Königsbach-Stein' },
  { label: 'Königsbronn', value: 'Königsbronn' },
  { label: 'Königsbrück', value: 'Königsbrück' },
  { label: 'Königsbrunn', value: 'Königsbrunn' },
  { label: 'Königsdorf', value: 'Königsdorf' },
  { label: 'Königsdorf', value: 'Königsdorf' },
  { label: 'Königsee', value: 'Königsee' },
  { label: 'Königsfeld', value: 'Königsfeld' },
  { label: 'Königsfeld', value: 'Königsfeld' },
  { label: 'Königsfeld Im Schwarzwald', value: 'Königsfeld Im Schwarzwald' },
  { label: 'Königshain', value: 'Königshain' },
  { label: 'Königslutter Am Elm', value: 'Königslutter Am Elm' },
  { label: 'Königstein', value: 'Königstein' },
  { label: 'Königstein', value: 'Königstein' },
  { label: 'Königstein Im Taunus', value: 'Königstein Im Taunus' },
  { label: 'Königstetten', value: 'Königstetten' },
  { label: 'Königswalde', value: 'Königswalde' },
  { label: 'Königswartha', value: 'Königswartha' },
  { label: 'Königswiesen', value: 'Königswiesen' },
  { label: 'Königswinter', value: 'Königswinter' },
  { label: 'Konin', value: 'Konin' },
  { label: 'Koni-Ngani', value: 'Koni-Ngani' },
  { label: 'Koningsbosch', value: 'Koningsbosch' },
  { label: 'Koningslust', value: 'Koningslust' },
  { label: 'Konispol', value: 'Konispol' },
  { label: 'Kónitsa', value: 'Kónitsa' },
  { label: 'Könitz', value: 'Könitz' },
  { label: 'Köniz', value: 'Köniz' },
  { label: 'Konjic', value: 'Konjic' },
  { label: 'Konjineh', value: 'Konjineh' },
  { label: 'Konjščina', value: 'Konjščina' },
  { label: 'Konkwesso', value: 'Konkwesso' },
  { label: 'Konnagar', value: 'Konnagar' },
  { label: 'Könnern', value: 'Könnern' },
  { label: 'Konnevesi', value: 'Konnevesi' },
  { label: 'Konnur', value: 'Konnur' },
  { label: 'Kono District', value: 'Kono District' },
  { label: 'Konobeyevo', value: 'Konobeyevo' },
  { label: 'Konokovo', value: 'Konokovo' },
  { label: 'Konolfingen', value: 'Konolfingen' },
  { label: 'Kononenki', value: 'Kononenki' },
  { label: 'Konongo', value: 'Konongo' },
  { label: 'Konopiska', value: 'Konopiska' },
  { label: 'Konosha', value: 'Konosha' },
  { label: 'Kōnosu', value: 'Kōnosu' },
  { label: 'Konosu-Shi', value: 'Konosu-Shi' },
  { label: 'Konotop', value: 'Konotop' },
  { label: 'Konotop', value: 'Konotop' },
  { label: 'Konradshöhe', value: 'Konradshöhe' },
  { label: 'Konradsreuth', value: 'Konradsreuth' },
  { label: 'Końskie', value: 'Końskie' },
  { label: 'Końskowola', value: 'Końskowola' },
  { label: 'Konso', value: 'Konso' },
  { label: 'Konsoy', value: 'Konsoy' },
  { label: 'Konstancin-Jeziorna', value: 'Konstancin-Jeziorna' },
  { label: 'Konstantinovka', value: 'Konstantinovka' },
  { label: 'Konstantinovka', value: 'Konstantinovka' },
  { label: 'Konstantinovo', value: 'Konstantinovo' },
  { label: 'Konstantinovo', value: 'Konstantinovo' },
  { label: 'Konstantinovsk', value: 'Konstantinovsk' },
  { label: 'Konstantinovskaya', value: 'Konstantinovskaya' },
  { label: 'Konstantinovskaya', value: 'Konstantinovskaya' },
  { label: 'Konstantinovskiy', value: 'Konstantinovskiy' },
  { label: 'Konstantinovskiy Rayon', value: 'Konstantinovskiy Rayon' },
  { label: 'Konstantinovskoye', value: 'Konstantinovskoye' },
  { label: 'Konstantynów', value: 'Konstantynów' },
  { label: 'Konstantynów Łódzki', value: 'Konstantynów Łódzki' },
  { label: 'Konstanz', value: 'Konstanz' },
  { label: 'Kontagora', value: 'Kontagora' },
  { label: 'Kontariótissa', value: 'Kontariótissa' },
  { label: 'Kontcha', value: 'Kontcha' },
  { label: 'Kontenko', value: 'Kontenko' },
  { label: 'Kontich', value: 'Kontich' },
  { label: 'Kontiolahti', value: 'Kontiolahti' },
  { label: 'Kontokáli', value: 'Kontokáli' },
  { label: 'Kóny', value: 'Kóny' },
  { label: 'Konyaaltı', value: 'Konyaaltı' },
  { label: 'Konyár', value: 'Konyár' },
  { label: 'Konyshevka', value: 'Konyshevka' },
  { label: 'Konz', value: 'Konz' },
  { label: 'Konza', value: 'Konza' },
  { label: 'Konzell', value: 'Konzell' },
  { label: 'Koochiching County', value: 'Koochiching County' },
  { label: 'Koog Aan De Zaan', value: 'Koog Aan De Zaan' },
  { label: 'Koohsar', value: 'Koohsar' },
  { label: 'Koonawarra', value: 'Koonawarra' },
  { label: 'Koondoola', value: 'Koondoola' },
  { label: 'Koongal', value: 'Koongal' },
  { label: 'Koontz Lake', value: 'Koontz Lake' },
  { label: 'Kooralbyn', value: 'Kooralbyn' },
  { label: 'Koorda', value: 'Koorda' },
  { label: 'Kooringal', value: 'Kooringal' },
  { label: 'Kooshk', value: 'Kooshk' },
  { label: 'Kootenai County', value: 'Kootenai County' },
  { label: 'Koothanallur', value: 'Koothanallur' },
  { label: 'Kootingal', value: 'Kootingal' },
  { label: 'Kootstertille', value: 'Kootstertille' },
  { label: 'Kootwijkerbroek', value: 'Kootwijkerbroek' },
  { label: 'Koo-Wee-Rup', value: 'Koo-Wee-Rup' },
  { label: 'Koozehkonan', value: 'Koozehkonan' },
  { label: 'Kop Van Zuid', value: 'Kop Van Zuid' },
  { label: 'Kopaganj', value: 'Kopaganj' },
  { label: 'Kopanáki', value: 'Kopanáki' },
  { label: 'Kopanós', value: 'Kopanós' },
  { label: 'Kopanskaya', value: 'Kopanskaya' },
  { label: 'Kopar Khairane', value: 'Kopar Khairane' },
  { label: 'Kopargaon', value: 'Kopargaon' },
  { label: 'Kopashnovo', value: 'Kopashnovo' },
  { label: 'Kópavogur', value: 'Kópavogur' },
  { label: 'Kopayhorod', value: 'Kopayhorod' },
  { label: 'Kopela', value: 'Kopela' },
  { label: 'Kopen', value: 'Kopen' },
  { label: 'Köpenick', value: 'Köpenick' },
  { label: 'Koper', value: 'Koper' },
  { label: 'Kopervik', value: 'Kopervik' },
  { label: 'Kopeysk', value: 'Kopeysk' },
  { label: 'Kopfing Im Innkreis', value: 'Kopfing Im Innkreis' },
  { label: 'Kopidlno', value: 'Kopidlno' },
  { label: 'Köping', value: 'Köping' },
  { label: 'Köpingebro', value: 'Köpingebro' },
  { label: 'Koplik', value: 'Koplik' },
  { label: 'Köpmanholmen', value: 'Köpmanholmen' },
  { label: 'Kopoma', value: 'Kopoma' },
  { label: 'Kopong', value: 'Kopong' },
  { label: 'Kopor’Ye', value: 'Kopor’Ye' },
  { label: 'Koppa', value: 'Koppa' },
  { label: 'Koppal', value: 'Koppal' },
  { label: 'Koppang', value: 'Koppang' },
  { label: 'Kopparberg', value: 'Kopparberg' },
  { label: 'Kopparmora', value: 'Kopparmora' },
  { label: 'Koppies', value: 'Koppies' },
  { label: 'Koppigen', value: 'Koppigen' },
  { label: 'Koppl', value: 'Koppl' },
  { label: 'Koprivnica', value: 'Koprivnica' },
  { label: 'Kopřivnice', value: 'Kopřivnice' },
  { label: 'Koprivnički Ivanec', value: 'Koprivnički Ivanec' },
  { label: 'Koprivshtitsa', value: 'Koprivshtitsa' },
  { label: 'Köprübaşı', value: 'Köprübaşı' },
  { label: 'Köprübaşı', value: 'Köprübaşı' },
  { label: 'Köprüköy', value: 'Köprüköy' },
  { label: 'Koprzywnica', value: 'Koprzywnica' },
  { label: 'Kopstal', value: 'Kopstal' },
  { label: 'Koptëvo', value: 'Koptëvo' },
  { label: 'Kopychyntsi', value: 'Kopychyntsi' },
  { label: 'Korablino', value: 'Korablino' },
  { label: 'Koradachcheri', value: 'Koradachcheri' },
  { label: 'Koradi', value: 'Koradi' },
  { label: 'Korampallam', value: 'Korampallam' },
  { label: 'Koran', value: 'Koran' },
  { label: 'Koraput', value: 'Koraput' },
  { label: 'Koratagere', value: 'Koratagere' },
  { label: 'Koratla', value: 'Koratla' },
  { label: 'Korb', value: 'Korb' },
  { label: 'Korba', value: 'Korba' },
  { label: 'Korba', value: 'Korba' },
  { label: 'Korbach', value: 'Korbach' },
  { label: 'Korbielów', value: 'Korbielów' },
  { label: 'Korbous', value: 'Korbous' },
  { label: 'Korçë', value: 'Korçë' },
  { label: 'Korchak', value: 'Korchak' },
  { label: 'Korčula', value: 'Korčula' },
  { label: 'Korczew', value: 'Korczew' },
  { label: 'Korczyna', value: 'Korczyna' },
  { label: 'Kord Bolagh', value: 'Kord Bolagh' },
  { label: 'Kordel', value: 'Kordel' },
  { label: 'Kordkuy', value: 'Kordkuy' },
  { label: 'Koreatown', value: 'Koreatown' },
  { label: 'Koregaon', value: 'Koregaon' },
  { label: 'Korei', value: 'Korei' },
  { label: 'Koreiz', value: 'Koreiz' },
  { label: 'Korem', value: 'Korem' },
  { label: 'Korenëvo', value: 'Korenëvo' },
  { label: 'Korenëvo', value: 'Korenëvo' },
  { label: 'Korenica', value: 'Korenica' },
  { label: 'Kořenov', value: 'Kořenov' },
  { label: 'Korenovsk', value: 'Korenovsk' },
  { label: 'Korets', value: 'Korets' },
  { label: 'Korfantów', value: 'Korfantów' },
  { label: 'Körfez', value: 'Körfez' },
  { label: 'Korfovskiy', value: 'Korfovskiy' },
  { label: 'Korgan', value: 'Korgan' },
  { label: 'Korgen', value: 'Korgen' },
  { label: 'Korgöz', value: 'Korgöz' },
  { label: 'Korgun', value: 'Korgun' },
  { label: 'Koria', value: 'Koria' },
  { label: 'Koribundu', value: 'Koribundu' },
  { label: 'Korinós', value: 'Korinós' },
  { label: 'Kórinthos', value: 'Kórinthos' },
  { label: 'Kōriyama', value: 'Kōriyama' },
  { label: 'Kōriyama Shi', value: 'Kōriyama Shi' },
  { label: 'Korkino', value: 'Korkino' },
  { label: 'Korkmaskala', value: 'Korkmaskala' },
  { label: 'Korkut', value: 'Korkut' },
  { label: 'Korkuteli', value: 'Korkuteli' },
  { label: 'Korla', value: 'Korla' },
  { label: 'Körle', value: 'Körle' },
  { label: 'Körmend', value: 'Körmend' },
  { label: 'Körmendi Járás', value: 'Körmendi Járás' },
  { label: 'Kormëzhka', value: 'Kormëzhka' },
  { label: 'Kormilovka', value: 'Kormilovka' },
  { label: 'Kormovoye', value: 'Kormovoye' },
  { label: 'Körner', value: 'Körner' },
  { label: 'Korneuburg', value: 'Korneuburg' },
  { label: 'Kórnik', value: 'Kórnik' },
  { label: 'Korniyivka', value: 'Korniyivka' },
  { label: 'Kórnos', value: 'Kórnos' },
  { label: 'Kornowac', value: 'Kornowac' },
  { label: 'Korntal', value: 'Korntal' },
  { label: 'Kornwestheim', value: 'Kornwestheim' },
  { label: 'Környe', value: 'Környe' },
  { label: 'Kornyn', value: 'Kornyn' },
  { label: 'Kornyn', value: 'Kornyn' },
  { label: 'Koroba-Lake Kopiago', value: 'Koroba-Lake Kopiago' },
  { label: 'Korobitsyno', value: 'Korobitsyno' },
  { label: 'Korocha', value: 'Korocha' },
  { label: 'Korochanskiy Rayon', value: 'Korochanskiy Rayon' },
  { label: 'Koroit', value: 'Koroit' },
  { label: 'Korolev', value: 'Korolev' },
  { label: 'Korolëv Stan', value: 'Korolëv Stan' },
  { label: 'Korolevo', value: 'Korolevo' },
  { label: 'Korolyov', value: 'Korolyov' },
  { label: 'Koronadal', value: 'Koronadal' },
  { label: 'Koronadal', value: 'Koronadal' },
  { label: 'Koróni', value: 'Koróni' },
  { label: 'Koronowo', value: 'Koronowo' },
  { label: 'Korop', value: 'Korop' },
  { label: 'Koropí', value: 'Koropí' },
  { label: 'Koror', value: 'Koror' },
  { label: 'Koror Town', value: 'Koror Town' },
  { label: 'Korora', value: 'Korora' },
  { label: 'Koroška Bela', value: 'Koroška Bela' },
  { label: 'Körösladány', value: 'Körösladány' },
  { label: 'Köröstarcsa', value: 'Köröstarcsa' },
  { label: 'Korosten', value: 'Korosten' },
  { label: 'Korosten’', value: 'Korosten’' },
  { label: 'Korostyshiv', value: 'Korostyshiv' },
  { label: 'Korostyshiv', value: 'Korostyshiv' },
  { label: 'Korostyshiv Raion', value: 'Korostyshiv Raion' },
  { label: 'Korotchaevo', value: 'Korotchaevo' },
  { label: 'Korotoyak', value: 'Korotoyak' },
  { label: 'Korotych', value: 'Korotych' },
  { label: 'Körperich', value: 'Körperich' },
  { label: 'Korpilahti', value: 'Korpilahti' },
  { label: 'Korrewegwijk', value: 'Korrewegwijk' },
  { label: 'Korsakov', value: 'Korsakov' },
  { label: 'Korsakovo', value: 'Korsakovo' },
  { label: 'Korschenbroich', value: 'Korschenbroich' },
  { label: 'Korsholm', value: 'Korsholm' },
  { label: 'Korsnäs', value: 'Korsnäs' },
  { label: 'Korsør', value: 'Korsør' },
  { label: 'Korsun-Shevchenkivskyi', value: 'Korsun-Shevchenkivskyi' },
  { label: 'Korsze', value: 'Korsze' },
  { label: 'Kortemark', value: 'Kortemark' },
  { label: 'Kortenaken', value: 'Kortenaken' },
  { label: 'Kortenberg', value: 'Kortenberg' },
  { label: 'Kortenhoef', value: 'Kortenhoef' },
  { label: 'Kortesjärvi', value: 'Kortesjärvi' },
  { label: 'Kortessem', value: 'Kortessem' },
  { label: 'Kortezubi', value: 'Kortezubi' },
  { label: 'Kortkeros', value: 'Kortkeros' },
  { label: 'Kortowo', value: 'Kortowo' },
  { label: 'Kortrijk', value: 'Kortrijk' },
  { label: 'Korukollu', value: 'Korukollu' },
  { label: 'Korumburra', value: 'Korumburra' },
  { label: 'Kõrveküla', value: 'Kõrveküla' },
  { label: 'Korvel', value: 'Korvel' },
  { label: 'Korwai', value: 'Korwai' },
  { label: 'Koryazhma', value: 'Koryazhma' },
  { label: 'Koryčany', value: 'Koryčany' },
  { label: 'Korydallós', value: 'Korydallós' },
  { label: 'Koryfí', value: 'Koryfí' },
  { label: 'Korytnica', value: 'Korytnica' },
  { label: 'Koryukivka', value: 'Koryukivka' },
  { label: 'Korzan', value: 'Korzan' },
  { label: 'Korzenna', value: 'Korzenna' },
  { label: 'Korzhevskiy', value: 'Korzhevskiy' },
  { label: 'Korzhovka-Golubovka', value: 'Korzhovka-Golubovka' },
  { label: 'Kos', value: 'Kos' },
  { label: 'Kosa', value: 'Kosa' },
  { label: 'Kosai', value: 'Kosai' },
  { label: 'Kosai-Shi', value: 'Kosai-Shi' },
  { label: 'Kosakowo', value: 'Kosakowo' },
  { label: 'Kosamba', value: 'Kosamba' },
  { label: 'Kosan', value: 'Kosan' },
  { label: 'Kosava', value: 'Kosava' },
  { label: 'Kosaya Gora', value: 'Kosaya Gora' },
  { label: 'Kościan', value: 'Kościan' },
  { label: 'Kościelec', value: 'Kościelec' },
  { label: 'Kościelec', value: 'Kościelec' },
  { label: 'Kościelisko', value: 'Kościelisko' },
  { label: 'Kościerzyna', value: 'Kościerzyna' },
  { label: 'Kosciusko', value: 'Kosciusko' },
  { label: 'Kosciusko County', value: 'Kosciusko County' },
  { label: 'Kosciuszko National Park', value: 'Kosciuszko National Park' },
  { label: 'Kosd', value: 'Kosd' },
  { label: 'Kose', value: 'Kose' },
  { label: 'Köse', value: 'Köse' },
  { label: 'Koseda', value: 'Koseda' },
  { label: 'Kosel', value: 'Kosel' },
  { label: 'Kosel', value: 'Kosel' },
  { label: 'Koserow', value: 'Koserow' },
  { label: 'Kosh', value: 'Kosh' },
  { label: 'Kosh-Agach', value: 'Kosh-Agach' },
  { label: 'Koshekhabl’', value: 'Koshekhabl’' },
  { label: 'Koshekhabl’Skiy Rayon', value: 'Koshekhabl’Skiy Rayon' },
  { label: 'Kōshi Shi', value: 'Kōshi Shi' },
  { label: 'Koshigaya Shi', value: 'Koshigaya Shi' },
  { label: 'Koshima', value: 'Koshima' },
  { label: 'Koshki', value: 'Koshki' },
  { label: 'Koshkonar', value: 'Koshkonar' },
  { label: 'Koshksaray', value: 'Koshksaray' },
  { label: 'Koshkuiyeh', value: 'Koshkuiyeh' },
  { label: 'Koshurnikovo', value: 'Koshurnikovo' },
  { label: 'Kōshū-Shi', value: 'Kōshū-Shi' },
  { label: 'Kosi', value: 'Kosi' },
  { label: 'Košice', value: 'Košice' },
  { label: 'Košice I', value: 'Košice I' },
  { label: 'Košice Ii', value: 'Košice Ii' },
  { label: 'Košice Iii', value: 'Košice Iii' },
  { label: 'Košice Iv', value: 'Košice Iv' },
  { label: 'Kosigi', value: 'Kosigi' },
  { label: 'Kosikha', value: 'Kosikha' },
  { label: 'Kosikhinskiy Rayon', value: 'Kosikhinskiy Rayon' },
  { label: 'Kosina', value: 'Kosina' },
  { label: 'Kosino', value: 'Kosino' },
  { label: 'Kosiv', value: 'Kosiv' },
  { label: 'Kosivs’Kyy Rayon', value: 'Kosivs’Kyy Rayon' },
  { label: 'Köşk', value: 'Köşk' },
  { label: 'Koška', value: 'Koška' },
  { label: 'Koslan', value: 'Koslan' },
  { label: 'Kosmach', value: 'Kosmach' },
  { label: 'Kosmonosy', value: 'Kosmonosy' },
  { label: 'Kosmynino', value: 'Kosmynino' },
  { label: 'Koson', value: 'Koson' },
  { label: 'Koson’', value: 'Koson’' },
  { label: 'Kosong', value: 'Kosong' },
  { label: 'Kosonsoy', value: 'Kosonsoy' },
  { label: 'Kosova Hora', value: 'Kosova Hora' },
  { label: 'Kosów Lacki', value: 'Kosów Lacki' },
  { label: 'Kossa', value: 'Kossa' },
  { label: 'Kössen', value: 'Kössen' },
  { label: 'Kossuth County', value: 'Kossuth County' },
  { label: 'Kostajnica', value: 'Kostajnica' },
  { label: 'Kostakioí', value: 'Kostakioí' },
  { label: 'Košťálov', value: 'Košťálov' },
  { label: 'Kostanay', value: 'Kostanay' },
  { label: 'Kostanjevica Na Krki', value: 'Kostanjevica Na Krki' },
  { label: 'Košťany', value: 'Košťany' },
  { label: 'Kostek', value: 'Kostek' },
  { label: 'Kostel', value: 'Kostel' },
  { label: 'Kostelec Na Hané', value: 'Kostelec Na Hané' },
  { label: 'Kostelec Nad Černými Lesy', value: 'Kostelec Nad Černými Lesy' },
  { label: 'Kostelec Nad Labem', value: 'Kostelec Nad Labem' },
  { label: 'Kostelec Nad Orlicí', value: 'Kostelec Nad Orlicí' },
  { label: 'Köstendorf', value: 'Köstendorf' },
  { label: 'Koster', value: 'Koster' },
  { label: 'Kosterevo', value: 'Kosterevo' },
  { label: 'Kosti', value: 'Kosti' },
  { label: 'Kostiantynivka Raion', value: 'Kostiantynivka Raion' },
  { label: 'Kostice', value: 'Kostice' },
  { label: 'Kostinbrod', value: 'Kostinbrod' },
  { label: 'Kostino', value: 'Kostino' },
  { label: 'Kostino', value: 'Kostino' },
  { label: 'Kostochkovka', value: 'Kostochkovka' },
  { label: 'Kostomłoty Drugie', value: 'Kostomłoty Drugie' },
  { label: 'Kostomłoty Pierwsze', value: 'Kostomłoty Pierwsze' },
  { label: 'Kostomuksha', value: 'Kostomuksha' },
  { label: 'Kostopil’', value: 'Kostopil’' },
  { label: 'Kostroma', value: 'Kostroma' },
  { label: 'Kostromskoy Rayon', value: 'Kostromskoy Rayon' },
  { label: 'Kostrovo', value: 'Kostrovo' },
  { label: 'Kostryzhivka', value: 'Kostryzhivka' },
  { label: 'Kostrzyn', value: 'Kostrzyn' },
  { label: 'Kostrzyn Nad Odrą', value: 'Kostrzyn Nad Odrą' },
  { label: 'Kostyantynivka', value: 'Kostyantynivka' },
  { label: 'Kostyantynivka', value: 'Kostyantynivka' },
  { label: 'Kostyantynivka', value: 'Kostyantynivka' },
  { label: 'Kosum Phisai', value: 'Kosum Phisai' },
  { label: 'Košute', value: 'Košute' },
  { label: 'Koszalin', value: 'Koszalin' },
  { label: 'Koszarawa', value: 'Koszarawa' },
  { label: 'Koszęcin', value: 'Koszęcin' },
  { label: 'Kőszeg', value: 'Kőszeg' },
  { label: 'Kőszegi Járás', value: 'Kőszegi Járás' },
  { label: 'Koszyce', value: 'Koszyce' },
  { label: 'Koszyce Wielkie', value: 'Koszyce Wielkie' },
  { label: 'Kot Addu Tehsil', value: 'Kot Addu Tehsil' },
  { label: 'Kot Diji', value: 'Kot Diji' },
  { label: 'Kot Ghulam Muhammad', value: 'Kot Ghulam Muhammad' },
  { label: 'Kot Isa Khan', value: 'Kot Isa Khan' },
  { label: 'Kot Malik Barkhurdar', value: 'Kot Malik Barkhurdar' },
  { label: 'Kot Mumin', value: 'Kot Mumin' },
  { label: 'Kot Radha Kishan', value: 'Kot Radha Kishan' },
  { label: 'Kot Rajkour', value: 'Kot Rajkour' },
  { label: 'Kot Samaba', value: 'Kot Samaba' },
  { label: 'Kot Sultan', value: 'Kot Sultan' },
  { label: 'Kota', value: 'Kota' },
  { label: 'Kota', value: 'Kota' },
  { label: 'Kota', value: 'Kota' },
  { label: 'Kota Administrasi Jakarta Barat', value: 'Kota Administrasi Jakarta Barat' },
  { label: 'Kota Administrasi Jakarta Pusat', value: 'Kota Administrasi Jakarta Pusat' },
  { label: 'Kota Administrasi Jakarta Selatan', value: 'Kota Administrasi Jakarta Selatan' },
  { label: 'Kota Administrasi Jakarta Timur', value: 'Kota Administrasi Jakarta Timur' },
  { label: 'Kota Administrasi Jakarta Utara', value: 'Kota Administrasi Jakarta Utara' },
  { label: 'Kota Ambon', value: 'Kota Ambon' },
  { label: 'Kota Balikpapan', value: 'Kota Balikpapan' },
  { label: 'Kota Banda Aceh', value: 'Kota Banda Aceh' },
  { label: 'Kota Bandar Lampung', value: 'Kota Bandar Lampung' },
  { label: 'Kota Bandung', value: 'Kota Bandung' },
  { label: 'Kota Banjar', value: 'Kota Banjar' },
  { label: 'Kota Banjar Baru', value: 'Kota Banjar Baru' },
  { label: 'Kota Banjarmasin', value: 'Kota Banjarmasin' },
  { label: 'Kota Batam', value: 'Kota Batam' },
  { label: 'Kota Batu', value: 'Kota Batu' },
  { label: 'Kota Baubau', value: 'Kota Baubau' },
  { label: 'Kota Bekasi', value: 'Kota Bekasi' },
  { label: 'Kota Belud', value: 'Kota Belud' },
  { label: 'Kota Bengkulu', value: 'Kota Bengkulu' },
  { label: 'Kota Bharu', value: 'Kota Bharu' },
  { label: 'Kota Bima', value: 'Kota Bima' },
  { label: 'Kota Binjai', value: 'Kota Binjai' },
  { label: 'Kota Bitung', value: 'Kota Bitung' },
  { label: 'Kota Blitar', value: 'Kota Blitar' },
  { label: 'Kota Bogor', value: 'Kota Bogor' },
  { label: 'Kota Bontang', value: 'Kota Bontang' },
  { label: 'Kota Bukittinggi', value: 'Kota Bukittinggi' },
  { label: 'Kota Cilegon', value: 'Kota Cilegon' },
  { label: 'Kota Cimahi', value: 'Kota Cimahi' },
  { label: 'Kota Cirebon', value: 'Kota Cirebon' },
  { label: 'Kota Denpasar', value: 'Kota Denpasar' },
  { label: 'Kota Depok', value: 'Kota Depok' },
  { label: 'Kota Dumai', value: 'Kota Dumai' },
  { label: 'Kota Gorontalo', value: 'Kota Gorontalo' },
  { label: 'Kota Gunungsitoli', value: 'Kota Gunungsitoli' },
  { label: 'Kota Jambi', value: 'Kota Jambi' },
  { label: 'Kota Jayapura', value: 'Kota Jayapura' },
  { label: 'Kota Kediri', value: 'Kota Kediri' },
  { label: 'Kota Kendari', value: 'Kota Kendari' },
  { label: 'Kota Kinabalu', value: 'Kota Kinabalu' },
  { label: 'Kota Kotamobagu', value: 'Kota Kotamobagu' },
  { label: 'Kota Kupang', value: 'Kota Kupang' },
  { label: 'Kota Langsa', value: 'Kota Langsa' },
  { label: 'Kota Lhokseumawe', value: 'Kota Lhokseumawe' },
  { label: 'Kota Lubuklinggau', value: 'Kota Lubuklinggau' },
  { label: 'Kota Madiun', value: 'Kota Madiun' },
  { label: 'Kota Magelang', value: 'Kota Magelang' },
  { label: 'Kota Makassar', value: 'Kota Makassar' },
  { label: 'Kota Malang', value: 'Kota Malang' },
  { label: 'Kota Manado', value: 'Kota Manado' },
  { label: 'Kota Mataram', value: 'Kota Mataram' },
  { label: 'Kota Medan', value: 'Kota Medan' },
  { label: 'Kota Metro', value: 'Kota Metro' },
  { label: 'Kota Mojokerto', value: 'Kota Mojokerto' },
  { label: 'Kota Padang', value: 'Kota Padang' },
  { label: 'Kota Padang Panjang', value: 'Kota Padang Panjang' },
  { label: 'Kota Padangsidimpuan', value: 'Kota Padangsidimpuan' },
  { label: 'Kota Pagar Alam', value: 'Kota Pagar Alam' },
  { label: 'Kota Palangka Raya', value: 'Kota Palangka Raya' },
  { label: 'Kota Palembang', value: 'Kota Palembang' },
  { label: 'Kota Palopo', value: 'Kota Palopo' },
  { label: 'Kota Palu', value: 'Kota Palu' },
  { label: 'Kota Pangkal Pinang', value: 'Kota Pangkal Pinang' },
  { label: 'Kota Parepare', value: 'Kota Parepare' },
  { label: 'Kota Pariaman', value: 'Kota Pariaman' },
  { label: 'Kota Pasuruan', value: 'Kota Pasuruan' },
  { label: 'Kota Payakumbuh', value: 'Kota Payakumbuh' },
  { label: 'Kota Pekalongan', value: 'Kota Pekalongan' },
  { label: 'Kota Pekanbaru', value: 'Kota Pekanbaru' },
  { label: 'Kota Pematang Siantar', value: 'Kota Pematang Siantar' },
  { label: 'Kota Prabumulih', value: 'Kota Prabumulih' },
  { label: 'Kota Probolinggo', value: 'Kota Probolinggo' },
  { label: 'Kota Sabang', value: 'Kota Sabang' },
  { label: 'Kota Salatiga', value: 'Kota Salatiga' },
  { label: 'Kota Samarinda', value: 'Kota Samarinda' },
  { label: 'Kota Sawah Lunto', value: 'Kota Sawah Lunto' },
  { label: 'Kota Semarang', value: 'Kota Semarang' },
  { label: 'Kota Serang', value: 'Kota Serang' },
  { label: 'Kota Sibolga', value: 'Kota Sibolga' },
  { label: 'Kota Solok', value: 'Kota Solok' },
  { label: 'Kota Sorong', value: 'Kota Sorong' },
  { label: 'Kota Subulussalam', value: 'Kota Subulussalam' },
  { label: 'Kota Sukabumi', value: 'Kota Sukabumi' },
  { label: 'Kota Sungai Penuh', value: 'Kota Sungai Penuh' },
  { label: 'Kota Surabaya', value: 'Kota Surabaya' },
  { label: 'Kota Surakarta', value: 'Kota Surakarta' },
  { label: 'Kota Tangerang', value: 'Kota Tangerang' },
  { label: 'Kota Tangerang Selatan', value: 'Kota Tangerang Selatan' },
  { label: 'Kota Tanjung Balai', value: 'Kota Tanjung Balai' },
  { label: 'Kota Tanjung Pinang', value: 'Kota Tanjung Pinang' },
  { label: 'Kota Tasikmalaya', value: 'Kota Tasikmalaya' },
  { label: 'Kota Tebing Tinggi', value: 'Kota Tebing Tinggi' },
  { label: 'Kota Tegal', value: 'Kota Tegal' },
  { label: 'Kota Ternate', value: 'Kota Ternate' },
  { label: 'Kota Tidore Kepulauan', value: 'Kota Tidore Kepulauan' },
  { label: 'Kota Tinggi', value: 'Kota Tinggi' },
  { label: 'Kota Tomohon', value: 'Kota Tomohon' },
  { label: 'Kota Tual', value: 'Kota Tual' },
  { label: 'Kota Yogyakarta', value: 'Kota Yogyakarta' },
  { label: 'Kotabumi', value: 'Kotabumi' },
  { label: 'Kotagiri', value: 'Kotagiri' },
  { label: 'Kótaj', value: 'Kótaj' },
  { label: 'Kotamangalam', value: 'Kotamangalam' },
  { label: 'Kotaparh', value: 'Kotaparh' },
  { label: 'Kotar', value: 'Kotar' },
  { label: 'Kotara', value: 'Kotara' },
  { label: 'Kotara South', value: 'Kotara South' },
  { label: 'Kotayk’', value: 'Kotayk’' },
  { label: 'Kotdefè', value: 'Kotdefè' },
  { label: 'Kotdwara', value: 'Kotdwara' },
  { label: 'Kotel', value: 'Kotel' },
  { label: 'Kotel’Nich', value: 'Kotel’Nich' },
  { label: 'Kotel’Niki', value: 'Kotel’Niki' },
  { label: 'Kotel’Nikovo', value: 'Kotel’Nikovo' },
  { label: 'Kotelnikovo', value: 'Kotelnikovo' },
  { label: 'Kotelva', value: 'Kotelva' },
  { label: 'Kothapet', value: 'Kothapet' },
  { label: 'Kothara', value: 'Kothara' },
  { label: 'Kotharia', value: 'Kotharia' },
  { label: 'Köthen', value: 'Köthen' },
  { label: 'Kothi', value: 'Kothi' },
  { label: 'Kotido', value: 'Kotido' },
  { label: 'Kotik', value: 'Kotik' },
  { label: 'Kotikawatta', value: 'Kotikawatta' },
  { label: 'Kotka', value: 'Kotka' },
  { label: 'Kotkapura', value: 'Kotkapura' },
  { label: 'Kotkhai', value: 'Kotkhai' },
  { label: 'Kotkot', value: 'Kotkot' },
  { label: 'Kotla', value: 'Kotla' },
  { label: 'Kotla', value: 'Kotla' },
  { label: 'Kotlas', value: 'Kotlas' },
  { label: 'Kotlasskiy Rayon', value: 'Kotlasskiy Rayon' },
  { label: 'Kotli', value: 'Kotli' },
  { label: 'Kotli District', value: 'Kotli District' },
  { label: 'Kotli Loharan', value: 'Kotli Loharan' },
  { label: 'Kotlin', value: 'Kotlin' },
  { label: 'Kotlje', value: 'Kotlje' },
  { label: 'Kotlovka', value: 'Kotlovka' },
  { label: 'Kotlyarevskaya', value: 'Kotlyarevskaya' },
  { label: 'Kotma', value: 'Kotma' },
  { label: 'Koto', value: 'Koto' },
  { label: 'Kōtō-Ku', value: 'Kōtō-Ku' },
  { label: 'Kotolte', value: 'Kotolte' },
  { label: 'Koton-Karfe', value: 'Koton-Karfe' },
  { label: 'Kotor', value: 'Kotor' },
  { label: 'Kotor Varoš', value: 'Kotor Varoš' },
  { label: 'Kotoriba', value: 'Kotoriba' },
  { label: 'Kotovo', value: 'Kotovo' },
  { label: 'Kotovsk', value: 'Kotovsk' },
  { label: 'Kotputli', value: 'Kotputli' },
  { label: 'Kotra', value: 'Kotra' },
  { label: 'Kotri', value: 'Kotri' },
  { label: 'Kötschach', value: 'Kötschach' },
  { label: 'Kötschach-Mauthen', value: 'Kötschach-Mauthen' },
  { label: 'Kotsyubyns’Ke', value: 'Kotsyubyns’Ke' },
  { label: 'Kottagudem', value: 'Kottagudem' },
  { label: 'Kottaiyur', value: 'Kottaiyur' },
  { label: 'Kottapalli', value: 'Kottapalli' },
  { label: 'Kottayam', value: 'Kottayam' },
  { label: 'Kotte', value: 'Kotte' },
  { label: 'Kottenheim', value: 'Kottenheim' },
  { label: 'Kottgeisering', value: 'Kottgeisering' },
  { label: 'Kottingbrunn', value: 'Kottingbrunn' },
  { label: 'Köttmannsdorf', value: 'Köttmannsdorf' },
  { label: 'Kotturu', value: 'Kotturu' },
  { label: 'Kottweiler-Schwanden', value: 'Kottweiler-Schwanden' },
  { label: 'Kotuń', value: 'Kotuń' },
  { label: 'Kotwa', value: 'Kotwa' },
  { label: 'Kotzebue', value: 'Kotzebue' },
  { label: 'Kötzschau', value: 'Kötzschau' },
  { label: 'Kötzting', value: 'Kötzting' },
  { label: 'Koua', value: 'Koua' },
  { label: 'Kouango', value: 'Kouango' },
  { label: 'Koubia', value: 'Koubia' },
  { label: 'Koudekerke', value: 'Koudekerke' },
  { label: 'Koudougou', value: 'Koudougou' },
  { label: 'Koudum', value: 'Koudum' },
  { label: 'Koufália', value: 'Koufália' },
  { label: 'Kouhenjan', value: 'Kouhenjan' },
  { label: 'Kouhestak', value: 'Kouhestak' },
  { label: 'Kouhin', value: 'Kouhin' },
  { label: 'Koukkuniemi', value: 'Koukkuniemi' },
  { label: 'Koulamoutou', value: 'Koulamoutou' },
  { label: 'Koulikoro', value: 'Koulikoro' },
  { label: 'Kouloúra', value: 'Kouloúra' },
  { label: 'Koumra', value: 'Koumra' },
  { label: 'Koundara', value: 'Koundara' },
  { label: 'Koundara Prefecture', value: 'Koundara Prefecture' },
  { label: 'Koungheul', value: 'Koungheul' },
  { label: 'Koung-Khi', value: 'Koung-Khi' },
  { label: 'Kountze', value: 'Kountze' },
  { label: 'Koupéla', value: 'Koupéla' },
  { label: 'Kouřim', value: 'Kouřim' },
  { label: 'Kouritenga Province', value: 'Kouritenga Province' },
  { label: 'Kouroussa', value: 'Kouroussa' },
  { label: 'Kousa', value: 'Kousa' },
  { label: 'Koushkak', value: 'Koushkak' },
  { label: 'Kousséri', value: 'Kousséri' },
  { label: 'Kout Na Šumavě', value: 'Kout Na Šumavě' },
  { label: 'Koutiala', value: 'Koutiala' },
  { label: 'Koutiala Cercle', value: 'Koutiala Cercle' },
  { label: 'Kouts', value: 'Kouts' },
  { label: 'Koutselió', value: 'Koutselió' },
  { label: 'Koutsopódi', value: 'Koutsopódi' },
  { label: 'Kouvarás', value: 'Kouvarás' },
  { label: 'Kouvola', value: 'Kouvola' },
  { label: 'Kovači', value: 'Kovači' },
  { label: 'Kovačica', value: 'Kovačica' },
  { label: 'Kováčová', value: 'Kováčová' },
  { label: 'Kovalam', value: 'Kovalam' },
  { label: 'Kovalevskoye', value: 'Kovalevskoye' },
  { label: 'Kovancılar', value: 'Kovancılar' },
  { label: 'Kovářov', value: 'Kovářov' },
  { label: 'Kovářská', value: 'Kovářská' },
  { label: 'Kovdor', value: 'Kovdor' },
  { label: 'Kovdorskiy Rayon', value: 'Kovdorskiy Rayon' },
  { label: 'Kovel', value: 'Kovel' },
  { label: 'Kovel’S’Ka Mis’Krada', value: 'Kovel’S’Ka Mis’Krada' },
  { label: 'Kovernino', value: 'Kovernino' },
  { label: 'Kovilj', value: 'Kovilj' },
  { label: 'Kovilpatti', value: 'Kovilpatti' },
  { label: 'Kovin', value: 'Kovin' },
  { label: 'Kovrov', value: 'Kovrov' },
  { label: 'Kovvur', value: 'Kovvur' },
  { label: 'Kovyl’Noye', value: 'Kovyl’Noye' },
  { label: 'Kovylkino', value: 'Kovylkino' },
  { label: 'Kowal', value: 'Kowal' },
  { label: 'Kowala', value: 'Kowala' },
  { label: 'Kowale', value: 'Kowale' },
  { label: 'Kowale Oleckie', value: 'Kowale Oleckie' },
  { label: 'Kowale-Popiele', value: 'Kowale-Popiele' },
  { label: 'Kowalewo Pomorskie', value: 'Kowalewo Pomorskie' },
  { label: 'Kowanyama', value: 'Kowanyama' },
  { label: 'Kowary', value: 'Kowary' },
  { label: 'Kowiesy', value: 'Kowiesy' },
  { label: 'Kowloon Tong', value: 'Kowloon Tong' },
  { label: 'Kowŏn-Ŭp', value: 'Kowŏn-Ŭp' },
  { label: 'Kowsar', value: 'Kowsar' },
  { label: 'Kowzareh', value: 'Kowzareh' },
  { label: 'Kōya', value: 'Kōya' },
  { label: 'Köyceğiz', value: 'Köyceğiz' },
  { label: 'Koyelga', value: 'Koyelga' },
  { label: 'Koygorodok', value: 'Koygorodok' },
  { label: 'Koygorodskiy Rayon', value: 'Koygorodskiy Rayon' },
  { label: 'Koyima', value: 'Koyima' },
  { label: 'Köyliö', value: 'Köyliö' },
  { label: 'Koynanagar', value: 'Koynanagar' },
  { label: 'Koynare', value: 'Koynare' },
  { label: 'Koysinceq', value: 'Koysinceq' },
  { label: 'Koyulhisar', value: 'Koyulhisar' },
  { label: 'Koz’Modem’Yansk', value: 'Koz’Modem’Yansk' },
  { label: 'Koza', value: 'Koza' },
  { label: 'Kozachi Laheri', value: 'Kozachi Laheri' },
  { label: 'Kozakai-Chō', value: 'Kozakai-Chō' },
  { label: 'Kozaklı', value: 'Kozaklı' },
  { label: 'Kozan', value: 'Kozan' },
  { label: 'Kozáni', value: 'Kozáni' },
  { label: 'Kozanów', value: 'Kozanów' },
  { label: 'Kozármisleny', value: 'Kozármisleny' },
  { label: 'Kozarska Dubica', value: 'Kozarska Dubica' },
  { label: 'Kozel’Sk', value: 'Kozel’Sk' },
  { label: 'Kozel’Skiy Rayon', value: 'Kozel’Skiy Rayon' },
  { label: 'Kozelets’', value: 'Kozelets’' },
  { label: 'Kozelshchyna', value: 'Kozelshchyna' },
  { label: 'Kozeyevo', value: 'Kozeyevo' },
  { label: 'Kozhanka', value: 'Kozhanka' },
  { label: 'Kozhevnikovo', value: 'Kozhevnikovo' },
  { label: 'Kozhikode', value: 'Kozhikode' },
  { label: 'Kozhukhovo', value: 'Kozhukhovo' },
  { label: 'Kozhva', value: 'Kozhva' },
  { label: 'Koziegłowy', value: 'Koziegłowy' },
  { label: 'Kozienice', value: 'Kozienice' },
  { label: 'Kozina', value: 'Kozina' },
  { label: 'Kozjak', value: 'Kozjak' },
  { label: 'Kozjak', value: 'Kozjak' },
  { label: 'Kozje', value: 'Kozje' },
  { label: 'Kožlany', value: 'Kožlany' },
  { label: 'Koźle', value: 'Koźle' },
  { label: 'Kozloduy', value: 'Kozloduy' },
  { label: 'Kozlovice', value: 'Kozlovice' },
  { label: 'Kozlovka', value: 'Kozlovka' },
  { label: 'Kozlovka', value: 'Kozlovka' },
  { label: 'Kozlovo', value: 'Kozlovo' },
  { label: 'Kozlovskiy Rayon', value: 'Kozlovskiy Rayon' },
  { label: 'Kozłów', value: 'Kozłów' },
  { label: 'Kozłowo', value: 'Kozłowo' },
  { label: 'Kozlu', value: 'Kozlu' },
  { label: 'Kozluk', value: 'Kozluk' },
  { label: 'Kozmice', value: 'Kozmice' },
  { label: 'Koźmin Wielkopolski', value: 'Koźmin Wielkopolski' },
  { label: 'Koźminek', value: 'Koźminek' },
  { label: 'Kozova', value: 'Kozova' },
  { label: 'Kozubszczyzna', value: 'Kozubszczyzna' },
  { label: 'Kożuchów', value: 'Kożuchów' },
  { label: 'Kozy', value: 'Kozy' },
  { label: 'Kozyatyn', value: 'Kozyatyn' },
  { label: 'Kozyrëvsk', value: 'Kozyrëvsk' },
  { label: 'Kpalimé', value: 'Kpalimé' },
  { label: 'Kpandae', value: 'Kpandae' },
  { label: 'Kpandu', value: 'Kpandu' },
  { label: 'Kraaifontein', value: 'Kraaifontein' },
  { label: 'Kraainem', value: 'Kraainem' },
  { label: 'Krabbendam', value: 'Krabbendam' },
  { label: 'Krabi', value: 'Krabi' },
  { label: 'Kracheh', value: 'Kracheh' },
  { label: 'Krachi East', value: 'Krachi East' },
  { label: 'Krachi Nchumuru', value: 'Krachi Nchumuru' },
  { label: 'Krachi West', value: 'Krachi West' },
  { label: 'Kraczkowa', value: 'Kraczkowa' },
  { label: 'Kraftsdorf', value: 'Kraftsdorf' },
  { label: 'Kragerø', value: 'Kragerø' },
  { label: 'Kragujevac', value: 'Kragujevac' },
  { label: 'Kraiburg Am Inn', value: 'Kraiburg Am Inn' },
  { label: 'Kraichtal', value: 'Kraichtal' },
  { label: 'Krailling', value: 'Krailling' },
  { label: 'Krajenka', value: 'Krajenka' },
  { label: 'Krajišnik', value: 'Krajišnik' },
  { label: 'Krakaudorf', value: 'Krakaudorf' },
  { label: 'Krakauhintermühlen', value: 'Krakauhintermühlen' },
  { label: 'Krakauschatten', value: 'Krakauschatten' },
  { label: 'Krakeel', value: 'Krakeel' },
  { label: 'Krakor', value: 'Krakor' },
  { label: 'Kraków', value: 'Kraków' },
  { label: 'Krakow Am See', value: 'Krakow Am See' },
  { label: 'Kraksaan', value: 'Kraksaan' },
  { label: 'Kralice Na Hané', value: 'Kralice Na Hané' },
  { label: 'Králíky', value: 'Králíky' },
  { label: 'Kraljevica', value: 'Kraljevica' },
  { label: 'Kraljevo', value: 'Kraljevo' },
  { label: 'Kralovice', value: 'Kralovice' },
  { label: 'Kralupy Nad Vltavou', value: 'Kralupy Nad Vltavou' },
  { label: 'Králův Dvůr', value: 'Králův Dvůr' },
  { label: 'Kramarzówka', value: 'Kramarzówka' },
  { label: 'Kramators’K', value: 'Kramators’K' },
  { label: 'Kramerhof', value: 'Kramerhof' },
  { label: 'Kramfors', value: 'Kramfors' },
  { label: 'Kramsach', value: 'Kramsach' },
  { label: 'Kramsk', value: 'Kramsk' },
  { label: 'Kranenburg', value: 'Kranenburg' },
  { label: 'Krani', value: 'Krani' },
  { label: 'Kranichfeld', value: 'Kranichfeld' },
  { label: 'Kranídi', value: 'Kranídi' },
  { label: 'Kranj', value: 'Kranj' },
  { label: 'Kranjska Gora', value: 'Kranjska Gora' },
  { label: 'Kranuan', value: 'Kranuan' },
  { label: 'Krapina', value: 'Krapina' },
  { label: 'Krapivinskiy', value: 'Krapivinskiy' },
  { label: 'Krapivna', value: 'Krapivna' },
  { label: 'Krapkowice', value: 'Krapkowice' },
  { label: 'Krasavino', value: 'Krasavino' },
  { label: 'Krasica', value: 'Krasica' },
  { label: 'Krasiczyn', value: 'Krasiczyn' },
  { label: 'Kraskino', value: 'Kraskino' },
  { label: 'Kraskovo', value: 'Kraskovo' },
  { label: 'Krāslava', value: 'Krāslava' },
  { label: 'Kraslice', value: 'Kraslice' },
  { label: 'Krasna', value: 'Krasna' },
  { label: 'Krásná Hora Nad Vltavou', value: 'Krásná Hora Nad Vltavou' },
  { label: 'Krásná Lípa', value: 'Krásná Lípa' },
  { label: 'Krasna Zorka', value: 'Krasna Zorka' },
  { label: 'Krasňany', value: 'Krasňany' },
  { label: 'Krasnapol’Ski Rayon', value: 'Krasnapol’Ski Rayon' },
  { label: 'Krasnapollye', value: 'Krasnapollye' },
  { label: 'Krasnaya Gora', value: 'Krasnaya Gora' },
  { label: 'Krasnaya Gorbatka', value: 'Krasnaya Gorbatka' },
  { label: 'Krasnaya Gorka', value: 'Krasnaya Gorka' },
  { label: 'Krasnaya Gorka', value: 'Krasnaya Gorka' },
  { label: 'Krasnaya Pahra', value: 'Krasnaya Pahra' },
  { label: 'Krasnaya Polyana', value: 'Krasnaya Polyana' },
  { label: 'Krasnaya Polyana', value: 'Krasnaya Polyana' },
  { label: 'Krasnaya Polyana', value: 'Krasnaya Polyana' },
  { label: 'Krasnaya Polyana', value: 'Krasnaya Polyana' },
  { label: 'Krasnaya Poyma', value: 'Krasnaya Poyma' },
  { label: 'Krasnaya Yaruga', value: 'Krasnaya Yaruga' },
  { label: 'Krasnaya Zarya', value: 'Krasnaya Zarya' },
  { label: 'Krasnaye', value: 'Krasnaye' },
  { label: 'Krasne', value: 'Krasne' },
  { label: 'Krasne', value: 'Krasne' },
  { label: 'Krasne', value: 'Krasne' },
  { label: 'Krasne', value: 'Krasne' },
  { label: 'Krasnenskiy Rayon', value: 'Krasnenskiy Rayon' },
  { label: 'Kraśniczyn', value: 'Kraśniczyn' },
  { label: 'Kraśnik', value: 'Kraśnik' },
  { label: 'Krasninskiy Rayon', value: 'Krasninskiy Rayon' },
  { label: 'Krasnoarmeysk', value: 'Krasnoarmeysk' },
  { label: 'Krasnoarmeysk', value: 'Krasnoarmeysk' },
  { label: 'Krasnoarmeyskaya', value: 'Krasnoarmeyskaya' },
  { label: 'Krasnoarmeyskiy', value: 'Krasnoarmeyskiy' },
  { label: 'Krasnoarmeyskiy Rayon', value: 'Krasnoarmeyskiy Rayon' },
  { label: 'Krasnoarmeyskoye', value: 'Krasnoarmeyskoye' },
  { label: 'Krasnoarmeyskoye', value: 'Krasnoarmeyskoye' },
  { label: 'Krasnoarmeyskoye', value: 'Krasnoarmeyskoye' },
  { label: 'Krasnoborsk', value: 'Krasnoborsk' },
  { label: 'Krasnobród', value: 'Krasnobród' },
  { label: 'Krasnobrodskiy', value: 'Krasnobrodskiy' },
  { label: 'Krasnochetayskiy Rayon', value: 'Krasnochetayskiy Rayon' },
  { label: 'Krasnodar', value: 'Krasnodar' },
  { label: 'Krasnofarfornyy', value: 'Krasnofarfornyy' },
  { label: 'Krasnoflotskoye', value: 'Krasnoflotskoye' },
  { label: 'Krasnogorka', value: 'Krasnogorka' },
  { label: 'Krasnogorodsk', value: 'Krasnogorodsk' },
  { label: 'Krasnogorsk', value: 'Krasnogorsk' },
  { label: 'Krasnogorsk', value: 'Krasnogorsk' },
  { label: 'Krasnogorskiy', value: 'Krasnogorskiy' },
  { label: 'Krasnogorskiy', value: 'Krasnogorskiy' },
  { label: 'Krasnogorskiy', value: 'Krasnogorskiy' },
  { label: 'Krasnogorskiy', value: 'Krasnogorskiy' },
  { label: 'Krasnogorskiy Rayon', value: 'Krasnogorskiy Rayon' },
  { label: 'Krasnogorskiy Rayon', value: 'Krasnogorskiy Rayon' },
  { label: 'Krasnogorskoye', value: 'Krasnogorskoye' },
  { label: 'Krasnogorskoye', value: 'Krasnogorskoye' },
  { label: 'Krasnogvardeyets', value: 'Krasnogvardeyets' },
  { label: 'Krasnogvardeyskiy', value: 'Krasnogvardeyskiy' },
  { label: 'Krasnogvardeyskiy Rayon', value: 'Krasnogvardeyskiy Rayon' },
  { label: 'Krasnogvardeyskiy Rayon', value: 'Krasnogvardeyskiy Rayon' },
  { label: 'Krasnogvardeyskoye', value: 'Krasnogvardeyskoye' },
  { label: 'Krasnogvardeyskoye', value: 'Krasnogvardeyskoye' },
  { label: 'Krasnogvardeyskoye', value: 'Krasnogvardeyskoye' },
  { label: 'Krasnogvardeyskoye', value: 'Krasnogvardeyskoye' },
  { label: 'Krasnogvargeisky', value: 'Krasnogvargeisky' },
  { label: 'Krasnohrad', value: 'Krasnohrad' },
  { label: 'Krasnohvardiiske Raion', value: 'Krasnohvardiiske Raion' },
  { label: 'Krasnokamenka', value: 'Krasnokamenka' },
  { label: 'Krasnokamensk', value: 'Krasnokamensk' },
  { label: 'Krasnokamensk', value: 'Krasnokamensk' },
  { label: 'Krasnokamsk', value: 'Krasnokamsk' },
  { label: 'Krasnokholm', value: 'Krasnokholm' },
  { label: 'Krasnokholmskiy', value: 'Krasnokholmskiy' },
  { label: 'Krasnokholmskiy Rayon', value: 'Krasnokholmskiy Rayon' },
  { label: 'Krasnokumskoye', value: 'Krasnokumskoye' },
  { label: 'Krasnokuts’K', value: 'Krasnokuts’K' },
  { label: 'Krasnolesnyy', value: 'Krasnolesnyy' },
  { label: 'Krasnolesye', value: 'Krasnolesye' },
  { label: 'Krasnomayskiy', value: 'Krasnomayskiy' },
  { label: 'Krasnoobsk', value: 'Krasnoobsk' },
  { label: 'Krasnooktyabr’Skiy Rayon', value: 'Krasnooktyabr’Skiy Rayon' },
  { label: 'Krasnopavlivka', value: 'Krasnopavlivka' },
  { label: 'Krasnoperekops’K', value: 'Krasnoperekops’K' },
  { label: 'Krasnoperekopsk Raion', value: 'Krasnoperekopsk Raion' },
  { label: 'Krasnopillya', value: 'Krasnopillya' },
  { label: 'Krasnopol', value: 'Krasnopol' },
  { label: 'Krasnorechenskiy', value: 'Krasnorechenskiy' },
  { label: 'Krasnosel’Skiy', value: 'Krasnosel’Skiy' },
  { label: 'Krasnosel’Skiy Rayon', value: 'Krasnosel’Skiy Rayon' },
  { label: 'Krasnoshchekovo', value: 'Krasnoshchekovo' },
  { label: 'Krasnosielc', value: 'Krasnosielc' },
  { label: 'Krasnosilka', value: 'Krasnosilka' },
  { label: 'Krasnoslobodsk', value: 'Krasnoslobodsk' },
  { label: 'Krasnoslobodsk', value: 'Krasnoslobodsk' },
  { label: 'Krasnoslobodskiy Rayon', value: 'Krasnoslobodskiy Rayon' },
  { label: 'Krasnotorka', value: 'Krasnotorka' },
  { label: 'Krasnotur’Insk', value: 'Krasnotur’Insk' },
  { label: 'Krasnoturansk', value: 'Krasnoturansk' },
  { label: 'Krasnoufimsk', value: 'Krasnoufimsk' },
  { label: 'Krasnoural’Sk', value: 'Krasnoural’Sk' },
  { label: 'Krasnovishersk', value: 'Krasnovishersk' },
  { label: 'Krasnovka', value: 'Krasnovka' },
  { label: 'Krasnoyarka', value: 'Krasnoyarka' },
  { label: 'Krasnoyarsk', value: 'Krasnoyarsk' },
  { label: 'Krasnoyarskaya', value: 'Krasnoyarskaya' },
  { label: 'Krasnoyarskiy', value: 'Krasnoyarskiy' },
  { label: 'Krasnoyarskiy Rayon', value: 'Krasnoyarskiy Rayon' },
  { label: 'Krasnoyarskiy Rayon', value: 'Krasnoyarskiy Rayon' },
  { label: 'Krasnoyarskoye', value: 'Krasnoyarskoye' },
  { label: 'Krasnoyaruzhskiy Rayon', value: 'Krasnoyaruzhskiy Rayon' },
  { label: 'Krasnoye', value: 'Krasnoye' },
  { label: 'Krasnoye', value: 'Krasnoye' },
  { label: 'Krasnoye', value: 'Krasnoye' },
  { label: 'Krasnoye', value: 'Krasnoye' },
  { label: 'Krasnoye', value: 'Krasnoye' },
  { label: 'Krasnoye Selo', value: 'Krasnoye Selo' },
  { label: 'Krasnoye-Na-Volge', value: 'Krasnoye-Na-Volge' },
  { label: 'Krasnozavodsk', value: 'Krasnozavodsk' },
  { label: 'Krasnozërskoye', value: 'Krasnozërskoye' },
  { label: 'Krasnoznamenka', value: 'Krasnoznamenka' },
  { label: 'Krasnoznamensk', value: 'Krasnoznamensk' },
  { label: 'Krasnoznamensk', value: 'Krasnoznamensk' },
  { label: 'Krasny Yar Volgograd Oblast', value: 'Krasny Yar Volgograd Oblast' },
  { label: 'Krasnyi Mak', value: 'Krasnyi Mak' },
  { label: 'Krasnystaw', value: 'Krasnystaw' },
  { label: 'Krasnyy Bereg', value: 'Krasnyy Bereg' },
  { label: 'Krasnyy Bogatyr’', value: 'Krasnyy Bogatyr’' },
  { label: 'Krasnyy Bor', value: 'Krasnyy Bor' },
  { label: 'Krasnyy Chikoy', value: 'Krasnyy Chikoy' },
  { label: 'Krasnyy Gulyay', value: 'Krasnyy Gulyay' },
  { label: 'Krasnyy Kholm', value: 'Krasnyy Kholm' },
  { label: 'Krasnyy Klyuch', value: 'Krasnyy Klyuch' },
  { label: 'Krasnyy Kommunar', value: 'Krasnyy Kommunar' },
  { label: 'Krasnyy Kurgan', value: 'Krasnyy Kurgan' },
  { label: 'Krasnyy Kut', value: 'Krasnyy Kut' },
  { label: 'Krasnyy Kut', value: 'Krasnyy Kut' },
  { label: 'Krasnyy Luch', value: 'Krasnyy Luch' },
  { label: 'Krasnyy Luch', value: 'Krasnyy Luch' },
  { label: 'Krasnyy Oktyabr’', value: 'Krasnyy Oktyabr’' },
  { label: 'Krasnyy Oktyabr’', value: 'Krasnyy Oktyabr’' },
  { label: 'Krasnyy Oktyabr’', value: 'Krasnyy Oktyabr’' },
  { label: 'Krasnyy Profintern', value: 'Krasnyy Profintern' },
  { label: 'Krasnyy Sulin', value: 'Krasnyy Sulin' },
  { label: 'Krasnyy Tkach', value: 'Krasnyy Tkach' },
  { label: 'Krasnyy Yar', value: 'Krasnyy Yar' },
  { label: 'Krasnyy Yar', value: 'Krasnyy Yar' },
  { label: 'Krasnyy Yar', value: 'Krasnyy Yar' },
  { label: 'Krasnyy Yar', value: 'Krasnyy Yar' },
  { label: 'Krasnyy Yar', value: 'Krasnyy Yar' },
  { label: 'Krasnyy Yar', value: 'Krasnyy Yar' },
  { label: 'Krasnyye Baki', value: 'Krasnyye Baki' },
  { label: 'Krasnyye Barrikady', value: 'Krasnyye Barrikady' },
  { label: 'Krasnyye Chetai', value: 'Krasnyye Chetai' },
  { label: 'Krasnyye Tkachi', value: 'Krasnyye Tkachi' },
  { label: 'Krasocin', value: 'Krasocin' },
  { label: 'Krasyatychi', value: 'Krasyatychi' },
  { label: 'Krasyliv', value: 'Krasyliv' },
  { label: 'Krasyukovskaya', value: 'Krasyukovskaya' },
  { label: 'Kraszewice', value: 'Kraszewice' },
  { label: 'Krathum Baen', value: 'Krathum Baen' },
  { label: 'Kratié', value: 'Kratié' },
  { label: 'Kratova Govtva', value: 'Kratova Govtva' },
  { label: 'Kratovo', value: 'Kratovo' },
  { label: 'Kratovo', value: 'Kratovo' },
  { label: 'Kraubath An Der Mur', value: 'Kraubath An Der Mur' },
  { label: 'Krauchenwies', value: 'Krauchenwies' },
  { label: 'Krauchthal', value: 'Krauchthal' },
  { label: 'Krauschwitz', value: 'Krauschwitz' },
  { label: 'Krautergersheim', value: 'Krautergersheim' },
  { label: 'Krauthausen', value: 'Krauthausen' },
  { label: 'Krautheim', value: 'Krautheim' },
  { label: 'Kravaře', value: 'Kravaře' },
  { label: 'Krayneye', value: 'Krayneye' },
  { label: 'Krčedin', value: 'Krčedin' },
  { label: 'Krebeck', value: 'Krebeck' },
  { label: 'Krebs', value: 'Krebs' },
  { label: 'Krechevitsy', value: 'Krechevitsy' },
  { label: 'Krefeld', value: 'Krefeld' },
  { label: 'Kreiensen', value: 'Kreiensen' },
  { label: 'Kreileroord', value: 'Kreileroord' },
  { label: 'Kreischa', value: 'Kreischa' },
  { label: 'Kremastí', value: 'Kremastí' },
  { label: 'Kremenchuk', value: 'Kremenchuk' },
  { label: 'Kremenets', value: 'Kremenets' },
  { label: 'Kremenki', value: 'Kremenki' },
  { label: 'Kremidivka', value: 'Kremidivka' },
  { label: 'Kreminna', value: 'Kreminna' },
  { label: 'Kremmen', value: 'Kremmen' },
  { label: 'Kremmling', value: 'Kremmling' },
  { label: 'Kremnica', value: 'Kremnica' },
  { label: 'Krempe', value: 'Krempe' },
  { label: 'Kremperheide', value: 'Kremperheide' },
  { label: 'Krempna', value: 'Krempna' },
  { label: 'Krems', value: 'Krems' },
  { label: 'Krems An Der Donau', value: 'Krems An Der Donau' },
  { label: 'Krems An Der Donau Stadt', value: 'Krems An Der Donau Stadt' },
  { label: 'Kremsdorf', value: 'Kremsdorf' },
  { label: 'Kremsmünster', value: 'Kremsmünster' },
  { label: 'Křemže', value: 'Křemže' },
  { label: 'Křenovice', value: 'Křenovice' },
  { label: 'Krensdorf', value: 'Krensdorf' },
  { label: 'Křepice', value: 'Křepice' },
  { label: 'Kresek', value: 'Kresek' },
  { label: 'Křešice', value: 'Křešice' },
  { label: 'Kresna', value: 'Kresna' },
  { label: 'Kressbronn Am Bodensee', value: 'Kressbronn Am Bodensee' },
  { label: 'Krest’Yanovka', value: 'Krest’Yanovka' },
  { label: 'Kréstena', value: 'Kréstena' },
  { label: 'Krestetskiy Rayon', value: 'Krestetskiy Rayon' },
  { label: 'Krestovskiy Ostrov', value: 'Krestovskiy Ostrov' },
  { label: 'Kresttsy', value: 'Kresttsy' },
  { label: 'Kretinga', value: 'Kretinga' },
  { label: 'Kretzschau', value: 'Kretzschau' },
  { label: 'Kreut', value: 'Kreut' },
  { label: 'Kreuzau', value: 'Kreuzau' },
  { label: 'Kreuzberg', value: 'Kreuzberg' },
  { label: 'Kreuzlingen', value: 'Kreuzlingen' },
  { label: 'Kreuzlingen District', value: 'Kreuzlingen District' },
  { label: 'Kreuztal', value: 'Kreuztal' },
  { label: 'Kreuzwertheim', value: 'Kreuzwertheim' },
  { label: 'Kreva', value: 'Kreva' },
  { label: 'Krian', value: 'Krian' },
  { label: 'Kribi', value: 'Kribi' },
  { label: 'Krichim', value: 'Krichim' },
  { label: 'Krickenbach', value: 'Krickenbach' },
  { label: 'Kriebitzsch', value: 'Kriebitzsch' },
  { label: 'Kriebstein', value: 'Kriebstein' },
  { label: 'Krieglach', value: 'Krieglach' },
  { label: 'Kriegsfeld', value: 'Kriegsfeld' },
  { label: 'Kriegstetten', value: 'Kriegstetten' },
  { label: 'Kriel', value: 'Kriel' },
  { label: 'Kriens', value: 'Kriens' },
  { label: 'Kriftel', value: 'Kriftel' },
  { label: 'Kríkellos', value: 'Kríkellos' },
  { label: 'Krimml', value: 'Krimml' },
  { label: 'Krimpen Aan Den Ijssel', value: 'Krimpen Aan Den Ijssel' },
  { label: 'Krimpenerwaard', value: 'Krimpenerwaard' },
  { label: 'Křinec', value: 'Křinec' },
  { label: 'Krinichnaya', value: 'Krinichnaya' },
  { label: 'Krinichnoye', value: 'Krinichnoye' },
  { label: 'Krinídes', value: 'Krinídes' },
  { label: 'Kripan', value: 'Kripan' },
  { label: 'Kripens’Kyy', value: 'Kripens’Kyy' },
  { label: 'Krishna', value: 'Krishna' },
  { label: 'Krishnagiri', value: 'Krishnagiri' },
  { label: 'Krishnanagar', value: 'Krishnanagar' },
  { label: 'Krishnapur', value: 'Krishnapur' },
  { label: 'Krishnarajpet', value: 'Krishnarajpet' },
  { label: 'Krispl', value: 'Krispl' },
  { label: 'Kristdala', value: 'Kristdala' },
  { label: 'Kristiansand', value: 'Kristiansand' },
  { label: 'Kristianstad', value: 'Kristianstad' },
  { label: 'Kristiansund', value: 'Kristiansund' },
  { label: 'Kristinehamn', value: 'Kristinehamn' },
  { label: 'Kristinestad', value: 'Kristinestad' },
  { label: 'Krithiá', value: 'Krithiá' },
  { label: 'Kritsá', value: 'Kritsá' },
  { label: 'Kritzendorf', value: 'Kritzendorf' },
  { label: 'Kritzmow', value: 'Kritzmow' },
  { label: 'Kriukivschina', value: 'Kriukivschina' },
  { label: 'Kriva Palanka', value: 'Kriva Palanka' },
  { label: 'Krivaja', value: 'Krivaja' },
  { label: 'Krivodanovka', value: 'Krivodanovka' },
  { label: 'Krivodol', value: 'Krivodol' },
  { label: 'Krivogashtani', value: 'Krivogashtani' },
  { label: 'Krivosheino', value: 'Krivosheino' },
  { label: 'Krivtsovo', value: 'Krivtsovo' },
  { label: 'Krivyanskaya', value: 'Krivyanskaya' },
  { label: 'Križ', value: 'Križ' },
  { label: 'Křižanov', value: 'Křižanov' },
  { label: 'Križevci', value: 'Križevci' },
  { label: 'Križevci Pri Ljutomeru', value: 'Križevci Pri Ljutomeru' },
  { label: 'Křížová', value: 'Křížová' },
  { label: 'Krk', value: 'Krk' },
  { label: 'Krmelín', value: 'Krmelín' },
  { label: 'Krnov', value: 'Krnov' },
  { label: 'Krobia', value: 'Krobia' },
  { label: 'Kroczyce', value: 'Kroczyce' },
  { label: 'Krødsherad', value: 'Krødsherad' },
  { label: 'Krog', value: 'Krog' },
  { label: 'Krokeés', value: 'Krokeés' },
  { label: 'Krokek', value: 'Krokek' },
  { label: 'Krókos', value: 'Krókos' },
  { label: 'Krokowa', value: 'Krokowa' },
  { label: 'Krolevets’', value: 'Krolevets’' },
  { label: 'Krölpa', value: 'Krölpa' },
  { label: 'Krombach', value: 'Krombach' },
  { label: 'Kromberk', value: 'Kromberk' },
  { label: 'Kroměříž', value: 'Kroměříž' },
  { label: 'Krommenie', value: 'Krommenie' },
  { label: 'Krompachy', value: 'Krompachy' },
  { label: 'Kromsdorf', value: 'Kromsdorf' },
  { label: 'Kromy', value: 'Kromy' },
  { label: 'Kronach', value: 'Kronach' },
  { label: 'Kronau', value: 'Kronau' },
  { label: 'Kronberg', value: 'Kronberg' },
  { label: 'Kronberg Tal', value: 'Kronberg Tal' },
  { label: 'Kronburg', value: 'Kronburg' },
  { label: 'Kronenwetter', value: 'Kronenwetter' },
  { label: 'Krŏng Chbar Mon', value: 'Krŏng Chbar Mon' },
  { label: 'Krŏng Doun Kaev', value: 'Krŏng Doun Kaev' },
  { label: 'Krong Kep', value: 'Krong Kep' },
  { label: 'Krŏng Sênmônoŭrôm', value: 'Krŏng Sênmônoŭrôm' },
  { label: 'Krŏng Ta Khmau', value: 'Krŏng Ta Khmau' },
  { label: 'Kronoby', value: 'Kronoby' },
  { label: 'Kronshagen', value: 'Kronshagen' },
  { label: 'Kronshtadt', value: 'Kronshtadt' },
  { label: 'Kronstorf', value: 'Kronstorf' },
  { label: 'Kroonstad', value: 'Kroonstad' },
  { label: 'Krooswijk', value: 'Krooswijk' },
  { label: 'Kropachëvo', value: 'Kropachëvo' },
  { label: 'Kröpelin', value: 'Kröpelin' },
  { label: 'Kropotkin', value: 'Kropotkin' },
  { label: 'Kropotkin', value: 'Kropotkin' },
  { label: 'Kropp', value: 'Kropp' },
  { label: 'Kröppelshagen-Fahrendorf', value: 'Kröppelshagen-Fahrendorf' },
  { label: 'Kroppenstedt', value: 'Kroppenstedt' },
  { label: 'Kropstädt', value: 'Kropstädt' },
  { label: 'Kropyvnytskyi', value: 'Kropyvnytskyi' },
  { label: 'Krościenko Nad Dunajcem', value: 'Krościenko Nad Dunajcem' },
  { label: 'Krościenko Wyżne', value: 'Krościenko Wyżne' },
  { label: 'Kröslin', value: 'Kröslin' },
  { label: 'Krośnica', value: 'Krośnica' },
  { label: 'Krośnice', value: 'Krośnice' },
  { label: 'Krośniewice', value: 'Krośniewice' },
  { label: 'Krosno', value: 'Krosno' },
  { label: 'Krosno', value: 'Krosno' },
  { label: 'Krosno Odrzańskie', value: 'Krosno Odrzańskie' },
  { label: 'Krostitz', value: 'Krostitz' },
  { label: 'Krotoszyn', value: 'Krotoszyn' },
  { label: 'Krotovka', value: 'Krotovka' },
  { label: 'Krottendorf', value: 'Krottendorf' },
  { label: 'Krottendorf Bei Ligist', value: 'Krottendorf Bei Ligist' },
  { label: 'Krotz Springs', value: 'Krotz Springs' },
  { label: 'Krouna', value: 'Krouna' },
  { label: 'Krousón', value: 'Krousón' },
  { label: 'Kröv', value: 'Kröv' },
  { label: 'Kroya', value: 'Kroya' },
  { label: 'Krrabë', value: 'Krrabë' },
  { label: 'Krško', value: 'Krško' },
  { label: 'Kruft', value: 'Kruft' },
  { label: 'Krugersdorp', value: 'Krugersdorp' },
  { label: 'Krugerville', value: 'Krugerville' },
  { label: 'Kruglolesskoye', value: 'Kruglolesskoye' },
  { label: 'Krūgloozernoe', value: 'Krūgloozernoe' },
  { label: 'Kruhlaye', value: 'Kruhlaye' },
  { label: 'Kruibeke', value: 'Kruibeke' },
  { label: 'Kruisberg', value: 'Kruisberg' },
  { label: 'Kruisfontein', value: 'Kruisfontein' },
  { label: 'Kruishoutem', value: 'Kruishoutem' },
  { label: 'Kruisland', value: 'Kruisland' },
  { label: 'Krujë', value: 'Krujë' },
  { label: 'Kruklanki', value: 'Kruklanki' },
  { label: 'Krukovskiy Raion', value: 'Krukovskiy Raion' },
  { label: 'Krum', value: 'Krum' },
  { label: 'Krumau Am Kamp', value: 'Krumau Am Kamp' },
  { label: 'Krumbach', value: 'Krumbach' },
  { label: 'Krumbach', value: 'Krumbach' },
  { label: 'Krumbach Markt', value: 'Krumbach Markt' },
  { label: 'Krumë', value: 'Krumë' },
  { label: 'Krumegg', value: 'Krumegg' },
  { label: 'Krummenau', value: 'Krummenau' },
  { label: 'Krummennaab', value: 'Krummennaab' },
  { label: 'Krummesse', value: 'Krummesse' },
  { label: 'Krumnussbaum', value: 'Krumnussbaum' },
  { label: 'Krumovgrad', value: 'Krumovgrad' },
  { label: 'Krumpa', value: 'Krumpa' },
  { label: 'Krumpendorf', value: 'Krumpendorf' },
  { label: 'Krumvíř', value: 'Krumvíř' },
  { label: 'Krün', value: 'Krün' },
  { label: 'Krupa Na Vrbasu', value: 'Krupa Na Vrbasu' },
  { label: 'Krupanj', value: 'Krupanj' },
  { label: 'Krupina', value: 'Krupina' },
  { label: 'Krupka', value: 'Krupka' },
  { label: 'Krupki', value: 'Krupki' },
  { label: 'Krupski Młyn', value: 'Krupski Młyn' },
  { label: 'Kruså', value: 'Kruså' },
  { label: 'Krusdorf', value: 'Krusdorf' },
  { label: 'Kruševac', value: 'Kruševac' },
  { label: 'Kruševica', value: 'Kruševica' },
  { label: 'Krusevo', value: 'Krusevo' },
  { label: 'Kruševo', value: 'Kruševo' },
  { label: 'Krushari', value: 'Krushari' },
  { label: 'Krušopek', value: 'Krušopek' },
  { label: 'Krustpils', value: 'Krustpils' },
  { label: 'Kruszwica', value: 'Kruszwica' },
  { label: 'Krutaya Gorka', value: 'Krutaya Gorka' },
  { label: 'Kruth', value: 'Kruth' },
  { label: 'Krutikha', value: 'Krutikha' },
  { label: 'Krutinka', value: 'Krutinka' },
  { label: 'Kruty', value: 'Kruty' },
  { label: 'Krýa Vrýsi', value: 'Krýa Vrýsi' },
  { label: 'Krychaw', value: 'Krychaw' },
  { label: 'Krylbo', value: 'Krylbo' },
  { label: 'Krylovskaya', value: 'Krylovskaya' },
  { label: 'Krylovskiy Rayon', value: 'Krylovskiy Rayon' },
  { label: 'Krym', value: 'Krym' },
  { label: 'Krymka', value: 'Krymka' },
  { label: 'Krymsk', value: 'Krymsk' },
  { label: 'Krymskaya Roza', value: 'Krymskaya Roza' },
  { label: 'Krymskiy Rayon', value: 'Krymskiy Rayon' },
  { label: 'Krymskoye', value: 'Krymskoye' },
  { label: 'Krynica Morska', value: 'Krynica Morska' },
  { label: 'Krynica-Zdrój', value: 'Krynica-Zdrój' },
  { label: 'Krynice', value: 'Krynice' },
  { label: 'Krynki', value: 'Krynki' },
  { label: 'Krynychky', value: 'Krynychky' },
  { label: 'Kryonéri', value: 'Kryonéri' },
  { label: 'Kryry', value: 'Kryry' },
  { label: 'Kryry', value: 'Kryry' },
  { label: 'Kryspinów', value: 'Kryspinów' },
  { label: 'Kryva', value: 'Kryva' },
  { label: 'Kryva Hora', value: 'Kryva Hora' },
  { label: 'Kryve Ozero', value: 'Kryve Ozero' },
  { label: 'Kryvichy', value: 'Kryvichy' },
  { label: 'Kryvoriz’Ka Mis’Krada', value: 'Kryvoriz’Ka Mis’Krada' },
  { label: 'Kryvyi Rih', value: 'Kryvyi Rih' },
  { label: 'Kryzhanivka', value: 'Kryzhanivka' },
  { label: 'Kryzhopil’', value: 'Kryzhopil’' },
  { label: 'Krzanowice', value: 'Krzanowice' },
  { label: 'Krzczonów', value: 'Krzczonów' },
  { label: 'Krzczonów', value: 'Krzczonów' },
  { label: 'Krzęcin', value: 'Krzęcin' },
  { label: 'Krzęcin', value: 'Krzęcin' },
  { label: 'Krzeczów', value: 'Krzeczów' },
  { label: 'Krzeczowice', value: 'Krzeczowice' },
  { label: 'Krzemienica', value: 'Krzemienica' },
  { label: 'Krzemieniewo', value: 'Krzemieniewo' },
  { label: 'Krzepice', value: 'Krzepice' },
  { label: 'Krzeszów', value: 'Krzeszów' },
  { label: 'Krzeszów', value: 'Krzeszów' },
  { label: 'Krzeszów', value: 'Krzeszów' },
  { label: 'Krzeszowice', value: 'Krzeszowice' },
  { label: 'Krzeszyce', value: 'Krzeszyce' },
  { label: 'Krzyki', value: 'Krzyki' },
  { label: 'Krzykosy', value: 'Krzykosy' },
  { label: 'Krzynowłoga Mała', value: 'Krzynowłoga Mała' },
  { label: 'Krzyszkowice', value: 'Krzyszkowice' },
  { label: 'Krzywaczka', value: 'Krzywaczka' },
  { label: 'Krzywcza', value: 'Krzywcza' },
  { label: 'Krzywda', value: 'Krzywda' },
  { label: 'Krzywiń', value: 'Krzywiń' },
  { label: 'Krzyż Wielkopolski', value: 'Krzyż Wielkopolski' },
  { label: 'Krzyżanów', value: 'Krzyżanów' },
  { label: 'Krzyżanowice', value: 'Krzyżanowice' },
  { label: 'Krzyżowa', value: 'Krzyżowa' },
  { label: 'Krzyżowice', value: 'Krzyżowice' },
  { label: 'Ksamil', value: 'Ksamil' },
  { label: 'Ksar', value: 'Ksar' },
  { label: 'Ksar Chellala', value: 'Ksar Chellala' },
  { label: 'Ksar El Boukhari', value: 'Ksar El Boukhari' },
  { label: 'Ksar El Kebir', value: 'Ksar El Kebir' },
  { label: 'Ksar El Khali', value: 'Ksar El Khali' },
  { label: 'Ksar Hellal', value: 'Ksar Hellal' },
  { label: 'Ksawerów', value: 'Ksawerów' },
  { label: 'Kshenskiy', value: 'Kshenskiy' },
  { label: 'Kshirpai', value: 'Kshirpai' },
  { label: 'Książ Wielkopolski', value: 'Książ Wielkopolski' },
  { label: 'Książenice', value: 'Książenice' },
  { label: 'Ksibet El Mediouni', value: 'Ksibet El Mediouni' },
  { label: 'Księże Małe-Księże Wielkie', value: 'Księże Małe-Księże Wielkie' },
  { label: 'Księżomierz', value: 'Księżomierz' },
  { label: 'Księżpol', value: 'Księżpol' },
  { label: 'Ksour Essaf', value: 'Ksour Essaf' },
  { label: 'Kstovo', value: 'Kstovo' },
  { label: 'Ktiami Shi', value: 'Ktiami Shi' },
  { label: 'Kuah', value: 'Kuah' },
  { label: 'Kuai’An', value: 'Kuai’An' },
  { label: 'Kuala Belait', value: 'Kuala Belait' },
  { label: 'Kuala Besut', value: 'Kuala Besut' },
  { label: 'Kuala Kangsar', value: 'Kuala Kangsar' },
  { label: 'Kuala Kedah', value: 'Kuala Kedah' },
  { label: 'Kuala Lipis', value: 'Kuala Lipis' },
  { label: 'Kuala Lumpur', value: 'Kuala Lumpur' },
  { label: 'Kuala Perlis', value: 'Kuala Perlis' },
  { label: 'Kuala Pilah', value: 'Kuala Pilah' },
  { label: 'Kuala Selangor', value: 'Kuala Selangor' },
  { label: 'Kuala Sungai Baru', value: 'Kuala Sungai Baru' },
  { label: 'Kuala Terengganu', value: 'Kuala Terengganu' },
  { label: 'Kuala Teriang', value: 'Kuala Teriang' },
  { label: 'Kuala Tungkal', value: 'Kuala Tungkal' },
  { label: 'Kualakapuas', value: 'Kualakapuas' },
  { label: 'Kualapu‘U', value: 'Kualapu‘U' },
  { label: 'Kuanda', value: 'Kuanda' },
  { label: 'Kuandian', value: 'Kuandian' },
  { label: 'Kuang', value: 'Kuang' },
  { label: 'Kuanos', value: 'Kuanos' },
  { label: 'Kuantan', value: 'Kuantan' },
  { label: 'Kuartango', value: 'Kuartango' },
  { label: 'Kuaydinah', value: 'Kuaydinah' },
  { label: 'Kubachi', value: 'Kubachi' },
  { label: 'Kubanskiy', value: 'Kubanskiy' },
  { label: 'Kuba-Taba', value: 'Kuba-Taba' },
  { label: 'Kubinka', value: 'Kubinka' },
  { label: 'Kubrat', value: 'Kubrat' },
  { label: 'Kubschütz', value: 'Kubschütz' },
  { label: 'Kubu Raya', value: 'Kubu Raya' },
  { label: 'Kubuta', value: 'Kubuta' },
  { label: 'Kubwa', value: 'Kubwa' },
  { label: 'Kućan Marof', value: 'Kućan Marof' },
  { label: 'Kuče', value: 'Kuče' },
  { label: 'Kuchaiburi', value: 'Kuchaiburi' },
  { label: 'Kuchaman', value: 'Kuchaman' },
  { label: 'Kuchen', value: 'Kuchen' },
  { label: 'Kuchera', value: 'Kuchera' },
  { label: 'Kucherivka', value: 'Kucherivka' },
  { label: 'Kuchesar', value: 'Kuchesar' },
  { label: 'Kuchesfahan', value: 'Kuchesfahan' },
  { label: 'Kuchinarai', value: 'Kuchinarai' },
  { label: 'Kuchinda', value: 'Kuchinda' },
  { label: 'Kuching', value: 'Kuching' },
  { label: 'Kuchl', value: 'Kuchl' },
  { label: 'Kuchugury', value: 'Kuchugury' },
  { label: 'Kuchurhan', value: 'Kuchurhan' },
  { label: 'Kuçovë', value: 'Kuçovë' },
  { label: 'Küçükçekmece', value: 'Küçükçekmece' },
  { label: 'Kuczbork-Osada', value: 'Kuczbork-Osada' },
  { label: 'Kud', value: 'Kud' },
  { label: 'Kudachi', value: 'Kudachi' },
  { label: 'Kudahuvadhoo', value: 'Kudahuvadhoo' },
  { label: 'Kudal', value: 'Kudal' },
  { label: 'Kudamatsu', value: 'Kudamatsu' },
  { label: 'Kudamatsu Shi', value: 'Kudamatsu Shi' },
  { label: 'Kudanding', value: 'Kudanding' },
  { label: 'Kudanding', value: 'Kudanding' },
  { label: 'Kudara-Somon', value: 'Kudara-Somon' },
  { label: 'Kudarkot', value: 'Kudarkot' },
  { label: 'Kudat', value: 'Kudat' },
  { label: 'Kuddewörde', value: 'Kuddewörde' },
  { label: 'Kudelstaart', value: 'Kudelstaart' },
  { label: 'Kudepsta', value: 'Kudepsta' },
  { label: 'Kudeyevskiy', value: 'Kudeyevskiy' },
  { label: 'Kudinovo', value: 'Kudinovo' },
  { label: 'Kudirkos Naumiestis', value: 'Kudirkos Naumiestis' },
  { label: 'Kudligi', value: 'Kudligi' },
  { label: 'Kudowa-Zdrój', value: 'Kudowa-Zdrój' },
  { label: 'Kudryashovskiy', value: 'Kudryashovskiy' },
  { label: 'Kudus', value: 'Kudus' },
  { label: 'Kudymkar', value: 'Kudymkar' },
  { label: 'Kueps Oberfranken', value: 'Kueps Oberfranken' },
  { label: 'Kufa', value: 'Kufa' },
  { label: 'Kufstein', value: 'Kufstein' },
  { label: 'Kugesi', value: 'Kugesi' },
  { label: 'Kugluktuk', value: 'Kugluktuk' },
  { label: 'Kuh Sefid', value: 'Kuh Sefid' },
  { label: 'Kuhani', value: 'Kuhani' },
  { label: 'Kuhardt', value: 'Kuhardt' },
  { label: 'Kühbach', value: 'Kühbach' },
  { label: 'Kuhbanan', value: 'Kuhbanan' },
  { label: 'Kuhdasht', value: 'Kuhdasht' },
  { label: 'Kuhi', value: 'Kuhi' },
  { label: 'Kuhi Khil', value: 'Kuhi Khil' },
  { label: 'Kuhlan Affar', value: 'Kuhlan Affar' },
  { label: 'Kuhlan Ash Sharaf', value: 'Kuhlan Ash Sharaf' },
  { label: 'Kuhmalahti', value: 'Kuhmalahti' },
  { label: 'Kuhmo', value: 'Kuhmo' },
  { label: 'Kuhmoinen', value: 'Kuhmoinen' },
  { label: 'Kühndorf', value: 'Kühndorf' },
  { label: 'Kühnsdorf', value: 'Kühnsdorf' },
  { label: 'Kuhpayeh', value: 'Kuhpayeh' },
  { label: 'Kuhrang', value: 'Kuhrang' },
  { label: 'Kührstedt', value: 'Kührstedt' },
  { label: 'Kuhsān', value: 'Kuhsān' },
  { label: 'Kui Buri', value: 'Kui Buri' },
  { label: 'Kuibyshevo', value: 'Kuibyshevo' },
  { label: 'Kuiju', value: 'Kuiju' },
  { label: 'Kuivaniemi', value: 'Kuivaniemi' },
  { label: 'Kujama', value: 'Kujama' },
  { label: 'Kujang-Ŭp', value: 'Kujang-Ŭp' },
  { label: 'Kuje', value: 'Kuje' },
  { label: 'Kuji', value: 'Kuji' },
  { label: 'Kuji-Shi', value: 'Kuji-Shi' },
  { label: 'Kuju', value: 'Kuju' },
  { label: 'Kukarmunda', value: 'Kukarmunda' },
  { label: 'Kukatpally', value: 'Kukatpally' },
  { label: 'Kukawa', value: 'Kukawa' },
  { label: 'Kukës', value: 'Kukës' },
  { label: 'Kukichūō', value: 'Kukichūō' },
  { label: 'Kuki-Shi', value: 'Kuki-Shi' },
  { label: 'Kuklis', value: 'Kuklis' },
  { label: 'Kukma', value: 'Kukma' },
  { label: 'Kukmirn', value: 'Kukmirn' },
  { label: 'Kukmor', value: 'Kukmor' },
  { label: 'Kukmorskiy Rayon', value: 'Kukmorskiy Rayon' },
  { label: 'Kukoboy', value: 'Kukoboy' },
  { label: 'Kuków', value: 'Kuków' },
  { label: 'Kukrahill', value: 'Kukrahill' },
  { label: 'Kukshi', value: 'Kukshi' },
  { label: 'Kukuna', value: 'Kukuna' },
  { label: 'Kukurečani', value: 'Kukurečani' },
  { label: 'Kukushkino', value: 'Kukushkino' },
  { label: 'Kukushtan', value: 'Kukushtan' },
  { label: 'Kul’Dur', value: 'Kul’Dur' },
  { label: 'Kula', value: 'Kula' },
  { label: 'Kula', value: 'Kula' },
  { label: 'Kula', value: 'Kula' },
  { label: 'Kulachi', value: 'Kulachi' },
  { label: 'Kulai', value: 'Kulai' },
  { label: 'Kulakh', value: 'Kulakh' },
  { label: 'Kulaman', value: 'Kulaman' },
  { label: 'Kulaman', value: 'Kulaman' },
  { label: 'Kulary', value: 'Kulary' },
  { label: 'Kulase', value: 'Kulase' },
  { label: 'Kulase', value: 'Kulase' },
  { label: 'Kulattur', value: 'Kulattur' },
  { label: 'Kulautuva', value: 'Kulautuva' },
  { label: 'Kulay-Kulay', value: 'Kulay-Kulay' },
  { label: 'Kulay-Kulay', value: 'Kulay-Kulay' },
  { label: 'Kuldīga', value: 'Kuldīga' },
  { label: 'Kulebaki', value: 'Kulebaki' },
  { label: 'Kulempang', value: 'Kulempang' },
  { label: 'Kulempang', value: 'Kulempang' },
  { label: 'Kuleshovka', value: 'Kuleshovka' },
  { label: 'Kulevcha', value: 'Kulevcha' },
  { label: 'Kulgam', value: 'Kulgam' },
  { label: 'Kulhudhuffushi', value: 'Kulhudhuffushi' },
  { label: 'Kuli', value: 'Kuli' },
  { label: 'Kulia Village', value: 'Kulia Village' },
  { label: 'Kulim', value: 'Kulim' },
  { label: 'Kulin', value: 'Kulin' },
  { label: 'Kulinskiy Rayon', value: 'Kulinskiy Rayon' },
  { label: 'Kulittalai', value: 'Kulittalai' },
  { label: 'Kuliyapitiya', value: 'Kuliyapitiya' },
  { label: 'Kullaa', value: 'Kullaa' },
  { label: 'Küllstedt', value: 'Küllstedt' },
  { label: 'Kulm Am Zirbitz', value: 'Kulm Am Zirbitz' },
  { label: 'Kulmain', value: 'Kulmain' },
  { label: 'Kulmbach', value: 'Kulmbach' },
  { label: 'Kŭlob', value: 'Kŭlob' },
  { label: 'Kulotino', value: 'Kulotino' },
  { label: 'Kuloy', value: 'Kuloy' },
  { label: 'Kulp', value: 'Kulp' },
  { label: 'Kulpahar', value: 'Kulpahar' },
  { label: 'Kulpi', value: 'Kulpi' },
  { label: 'Kulpin', value: 'Kulpin' },
  { label: 'Kulpmont', value: 'Kulpmont' },
  { label: 'Kulpsville', value: 'Kulpsville' },
  { label: 'Külsheim', value: 'Külsheim' },
  { label: 'Kultali', value: 'Kultali' },
  { label: 'Kultayevo', value: 'Kultayevo' },
  { label: 'Kulti', value: 'Kulti' },
  { label: 'Kultuk', value: 'Kultuk' },
  { label: 'Kulu', value: 'Kulu' },
  { label: 'Kulu', value: 'Kulu' },
  { label: 'Kuluin', value: 'Kuluin' },
  { label: 'Kuluncak', value: 'Kuluncak' },
  { label: 'Kulunda', value: 'Kulunda' },
  { label: 'Kulundinskiy Rayon', value: 'Kulundinskiy Rayon' },
  { label: 'Kulykiv', value: 'Kulykiv' },
  { label: 'Kulykivka', value: 'Kulykivka' },
  { label: 'Kulynychi', value: 'Kulynychi' },
  { label: 'Kumagaya Shi', value: 'Kumagaya Shi' },
  { label: 'Kumagunnam', value: 'Kumagunnam' },
  { label: 'Kumalarang', value: 'Kumalarang' },
  { label: 'Kumalisquis', value: 'Kumalisquis' },
  { label: 'Kumamoto', value: 'Kumamoto' },
  { label: 'Kumamoto Shi', value: 'Kumamoto Shi' },
  { label: 'Kumane', value: 'Kumane' },
  { label: 'Kumano', value: 'Kumano' },
  { label: 'Kumano-Shi', value: 'Kumano-Shi' },
  { label: 'Kumanovo', value: 'Kumanovo' },
  { label: 'Kumaralingam', value: 'Kumaralingam' },
  { label: 'Kumasi', value: 'Kumasi' },
  { label: 'Kumba', value: 'Kumba' },
  { label: 'Kumbakonam', value: 'Kumbakonam' },
  { label: 'Kumbalam', value: 'Kumbalam' },
  { label: 'Kumberg', value: 'Kumberg' },
  { label: 'Kumbhraj', value: 'Kumbhraj' },
  { label: 'Kumbija', value: 'Kumbija' },
  { label: 'Kumbo', value: 'Kumbo' },
  { label: 'Kumeleh', value: 'Kumeleh' },
  { label: 'Kumëny', value: 'Kumëny' },
  { label: 'Kumertau', value: 'Kumertau' },
  { label: 'Kumhari', value: 'Kumhari' },
  { label: 'Kumhausen', value: 'Kumhausen' },
  { label: 'Kumher', value: 'Kumher' },
  { label: 'Kumi', value: 'Kumi' },
  { label: 'Kuminskiy', value: 'Kuminskiy' },
  { label: 'Kumla', value: 'Kumla' },
  { label: 'Kumlinge', value: 'Kumlinge' },
  { label: 'Kumlu', value: 'Kumlu' },
  { label: 'Kumluca', value: 'Kumluca' },
  { label: 'Kummelnäs', value: 'Kummelnäs' },
  { label: 'Kummerfeld', value: 'Kummerfeld' },
  { label: 'Kümmersbruck', value: 'Kümmersbruck' },
  { label: 'Kumo', value: 'Kumo' },
  { label: 'Kumphawapi', value: 'Kumphawapi' },
  { label: 'Kumrovec', value: 'Kumrovec' },
  { label: 'Kumru', value: 'Kumru' },
  { label: 'Kumsi', value: 'Kumsi' },
  { label: 'Kumta', value: 'Kumta' },
  { label: 'Kumukh', value: 'Kumukh' },
  { label: 'Kumylzhenskaya', value: 'Kumylzhenskaya' },
  { label: 'Kumysh', value: 'Kumysh' },
  { label: 'Kun’Inskiy Rayon', value: 'Kun’Inskiy Rayon' },
  { label: 'Kuna', value: 'Kuna' },
  { label: 'Kunágota', value: 'Kunágota' },
  { label: 'Kunani', value: 'Kunani' },
  { label: 'Kunashak', value: 'Kunashak' },
  { label: 'Kunčice Pod Ondřejníkem', value: 'Kunčice Pod Ondřejníkem' },
  { label: 'Kunčina', value: 'Kunčina' },
  { label: 'Kunda', value: 'Kunda' },
  { label: 'Kunda', value: 'Kunda' },
  { label: 'Kundarkhi', value: 'Kundarkhi' },
  { label: 'Kundarki', value: 'Kundarki' },
  { label: 'Kundasale', value: 'Kundasale' },
  { label: 'Kundgol', value: 'Kundgol' },
  { label: 'Kundian', value: 'Kundian' },
  { label: 'Kundiawa', value: 'Kundiawa' },
  { label: 'Kundl', value: 'Kundl' },
  { label: 'Kundla', value: 'Kundla' },
  { label: 'Kunduz', value: 'Kunduz' },
  { label: 'Kunfehértó', value: 'Kunfehértó' },
  { label: 'Kungälv', value: 'Kungälv' },
  { label: 'Kungsängen', value: 'Kungsängen' },
  { label: 'Kungsbacka', value: 'Kungsbacka' },
  { label: 'Kungshamn', value: 'Kungshamn' },
  { label: 'Kungsholmen', value: 'Kungsholmen' },
  { label: 'Kungsör', value: 'Kungsör' },
  { label: 'Kungtad', value: 'Kungtad' },
  { label: 'Kungtad', value: 'Kungtad' },
  { label: 'Kungur', value: 'Kungur' },
  { label: 'Kungurtug', value: 'Kungurtug' },
  { label: 'Kunhegyes', value: 'Kunhegyes' },
  { label: 'Kunhegyesi Járás', value: 'Kunhegyesi Járás' },
  { label: 'Kunheim', value: 'Kunheim' },
  { label: 'Kunice', value: 'Kunice' },
  { label: 'Kunice Żarskie', value: 'Kunice Żarskie' },
  { label: 'Kunigal', value: 'Kunigal' },
  { label: 'Kunín', value: 'Kunín' },
  { label: 'Kuningan', value: 'Kuningan' },
  { label: 'Kunisaki-Shi', value: 'Kunisaki-Shi' },
  { label: 'Kunitachi-Shi', value: 'Kunitachi-Shi' },
  { label: 'Kunjah', value: 'Kunjah' },
  { label: 'Kunmadaras', value: 'Kunmadaras' },
  { label: 'Kunming', value: 'Kunming' },
  { label: 'Kunnamangalam', value: 'Kunnamangalam' },
  { label: 'Kunnamkulam', value: 'Kunnamkulam' },
  { label: 'Kunnattur', value: 'Kunnattur' },
  { label: 'Kunnumma', value: 'Kunnumma' },
  { label: 'Kunovice', value: 'Kunovice' },
  { label: 'Kunów', value: 'Kunów' },
  { label: 'Kunoy', value: 'Kunoy' },
  { label: 'Kunrade', value: 'Kunrade' },
  { label: 'Kunreuth', value: 'Kunreuth' },
  { label: 'Kunri', value: 'Kunri' },
  { label: 'Kunshan', value: 'Kunshan' },
  { label: 'Kunštát', value: 'Kunštát' },
  { label: 'Kunszentmárton', value: 'Kunszentmárton' },
  { label: 'Kunszentmártoni Járás', value: 'Kunszentmártoni Járás' },
  { label: 'Kunszentmiklós', value: 'Kunszentmiklós' },
  { label: 'Kunszentmiklósi Járás', value: 'Kunszentmiklósi Járás' },
  { label: 'Kuntaur', value: 'Kuntaur' },
  { label: 'Künten', value: 'Künten' },
  { label: 'Kunting', value: 'Kunting' },
  { label: 'Kuntzig', value: 'Kuntzig' },
  { label: 'Kununurra', value: 'Kununurra' },
  { label: 'Kunvald', value: 'Kunvald' },
  { label: 'Kunwi', value: 'Kunwi' },
  { label: 'Kunyang', value: 'Kunyang' },
  { label: 'Kunžak', value: 'Kunžak' },
  { label: 'Künzell', value: 'Künzell' },
  { label: 'Künzelsau', value: 'Künzelsau' },
  { label: 'Künzing', value: 'Künzing' },
  { label: 'Kuortane', value: 'Kuortane' },
  { label: 'Kup', value: 'Kup' },
  { label: 'Kupang', value: 'Kupang' },
  { label: 'Kupavna', value: 'Kupavna' },
  { label: 'Kupchino', value: 'Kupchino' },
  { label: 'Kupech', value: 'Kupech' },
  { label: 'Kupferberg', value: 'Kupferberg' },
  { label: 'Kupferzell', value: 'Kupferzell' },
  { label: 'Kupino', value: 'Kupino' },
  { label: 'Kupinovo', value: 'Kupinovo' },
  { label: 'Kupiskis', value: 'Kupiskis' },
  { label: 'Kupjansk', value: 'Kupjansk' },
  { label: 'Kuppam', value: 'Kuppam' },
  { label: 'Kuppenheim', value: 'Kuppenheim' },
  { label: 'Kupusina', value: 'Kupusina' },
  { label: 'Kupwara', value: 'Kupwara' },
  { label: 'Kuqa', value: 'Kuqa' },
  { label: 'Kur Kahriz', value: 'Kur Kahriz' },
  { label: 'Kur’Inskiy Rayon', value: 'Kur’Inskiy Rayon' },
  { label: 'Kur’Yanovo', value: 'Kur’Yanovo' },
  { label: 'Kuraby', value: 'Kuraby' },
  { label: 'Kuragino', value: 'Kuragino' },
  { label: 'Kuraim', value: 'Kuraim' },
  { label: 'Kurakh', value: 'Kurakh' },
  { label: 'Kurakhovo', value: 'Kurakhovo' },
  { label: 'Kurakhskiy Rayon', value: 'Kurakhskiy Rayon' },
  { label: 'Kuranda', value: 'Kuranda' },
  { label: 'Kurandvad', value: 'Kurandvad' },
  { label: 'Kurankhed', value: 'Kurankhed' },
  { label: 'Kurara', value: 'Kurara' },
  { label: 'Kurashiki', value: 'Kurashiki' },
  { label: 'Kurashiki Shi', value: 'Kurashiki Shi' },
  { label: 'Kuraymah', value: 'Kuraymah' },
  { label: 'Kurayoshi-Shi', value: 'Kurayoshi-Shi' },
  { label: 'Kurayyimah', value: 'Kurayyimah' },
  { label: 'Kurba', value: 'Kurba' },
  { label: 'Kurbnesh', value: 'Kurbnesh' },
  { label: 'Kurchaloy', value: 'Kurchaloy' },
  { label: 'Kurchatov', value: 'Kurchatov' },
  { label: 'Kurchatov', value: 'Kurchatov' },
  { label: 'Kurchum', value: 'Kurchum' },
  { label: 'Kurduvadi', value: 'Kurduvadi' },
  { label: 'Kurdzhinovo', value: 'Kurdzhinovo' },
  { label: 'Kure', value: 'Kure' },
  { label: 'Küre', value: 'Küre' },
  { label: 'Kure Beach', value: 'Kure Beach' },
  { label: 'Kurebharsaidkhanpur', value: 'Kurebharsaidkhanpur' },
  { label: 'Kurepalu', value: 'Kurepalu' },
  { label: 'Kure-Shi', value: 'Kure-Shi' },
  { label: 'Kuressaare', value: 'Kuressaare' },
  { label: 'Kurgan', value: 'Kurgan' },
  { label: 'Kurganinsk', value: 'Kurganinsk' },
  { label: 'Kurgunta', value: 'Kurgunta' },
  { label: 'Kuria', value: 'Kuria' },
  { label: 'Kurigram ', value: 'Kurigram ' },
  { label: 'Kurihara', value: 'Kurihara' },
  { label: 'Kurihara Shi', value: 'Kurihara Shi' },
  { label: 'Kurihashi', value: 'Kurihashi' },
  { label: 'Kurikka', value: 'Kurikka' },
  { label: 'Kuril’Sk', value: 'Kuril’Sk' },
  { label: 'Kurilovo', value: 'Kurilovo' },
  { label: 'Kurilsky District', value: 'Kurilsky District' },
  { label: 'Kuřim', value: 'Kuřim' },
  { label: 'Ku-Ring-Gai', value: 'Ku-Ring-Gai' },
  { label: 'Kurinjippadi', value: 'Kurinjippadi' },
  { label: 'Kurio', value: 'Kurio' },
  { label: 'Kurisove', value: 'Kurisove' },
  { label: 'Kurkent', value: 'Kurkent' },
  { label: 'Kurkino', value: 'Kurkino' },
  { label: 'Kurlovo', value: 'Kurlovo' },
  { label: 'Kurmanayevka', value: 'Kurmanayevka' },
  { label: 'Kurmuk', value: 'Kurmuk' },
  { label: 'Kürnach', value: 'Kürnach' },
  { label: 'Kürnbach', value: 'Kürnbach' },
  { label: 'Kurnell', value: 'Kurnell' },
  { label: 'Kurnool', value: 'Kurnool' },
  { label: 'Kurobe-Shi', value: 'Kurobe-Shi' },
  { label: 'Kuroda', value: 'Kuroda' },
  { label: 'Kuroishi', value: 'Kuroishi' },
  { label: 'Kuroishi Shi', value: 'Kuroishi Shi' },
  { label: 'Kuroiso', value: 'Kuroiso' },
  { label: 'Kurort Gohrisch', value: 'Kurort Gohrisch' },
  { label: 'Kurort Jonsdorf', value: 'Kurort Jonsdorf' },
  { label: 'Kurort Oberwiesenthal', value: 'Kurort Oberwiesenthal' },
  { label: 'Kurort Oybin', value: 'Kurort Oybin' },
  { label: 'Kurort-Darasun', value: 'Kurort-Darasun' },
  { label: 'Kurortnyy', value: 'Kurortnyy' },
  { label: 'Kurortnyy Rayon', value: 'Kurortnyy Rayon' },
  { label: 'Kuroshio', value: 'Kuroshio' },
  { label: 'Kurovskoye', value: 'Kurovskoye' },
  { label: 'Kurovskoye', value: 'Kurovskoye' },
  { label: 'Kurów', value: 'Kurów' },
  { label: 'Kurraba Point', value: 'Kurraba Point' },
  { label: 'Kurrajong Heights', value: 'Kurrajong Heights' },
  { label: 'Kurralta Park', value: 'Kurralta Park' },
  { label: 'Kurri Kurri', value: 'Kurri Kurri' },
  { label: 'Kuršanec', value: 'Kuršanec' },
  { label: 'Kursavka', value: 'Kursavka' },
  { label: 'Kuršėnai', value: 'Kuršėnai' },
  { label: 'Kurseong', value: 'Kurseong' },
  { label: 'Kursk', value: 'Kursk' },
  { label: 'Kurskaya', value: 'Kurskaya' },
  { label: 'Kurskiy Rayon', value: 'Kurskiy Rayon' },
  { label: 'Kurskoye', value: 'Kurskoye' },
  { label: 'Kurşunlu', value: 'Kurşunlu' },
  { label: 'Kurtalan', value: 'Kurtalan' },
  { label: 'Kurtamysh', value: 'Kurtamysh' },
  { label: 'Kürten', value: 'Kürten' },
  { label: 'Kurtistown', value: 'Kurtistown' },
  { label: 'Kürtün', value: 'Kürtün' },
  { label: 'Kuru', value: 'Kuru' },
  { label: 'Kurucaşile', value: 'Kurucaşile' },
  { label: 'Kurud', value: 'Kurud' },
  { label: 'Kurukshetra', value: 'Kurukshetra' },
  { label: 'Kuruman', value: 'Kuruman' },
  { label: 'Kurume', value: 'Kurume' },
  { label: 'Kurume Shi', value: 'Kurume Shi' },
  { label: 'Kurumkan', value: 'Kurumkan' },
  { label: 'Kurumoch', value: 'Kurumoch' },
  { label: 'Kurunduma', value: 'Kurunduma' },
  { label: 'Kurunegala', value: 'Kurunegala' },
  { label: 'Kurunegala District', value: 'Kurunegala District' },
  { label: 'Kurung Kumey', value: 'Kurung Kumey' },
  { label: 'Kurunjang', value: 'Kurunjang' },
  { label: 'Kurush', value: 'Kurush' },
  { label: 'Kurwongbah', value: 'Kurwongbah' },
  { label: 'Kurye', value: 'Kurye' },
  { label: 'Kuryłówka', value: 'Kuryłówka' },
  { label: 'Kurzętnik', value: 'Kurzętnik' },
  { label: 'Kusa', value: 'Kusa' },
  { label: 'Kuşadası', value: 'Kuşadası' },
  { label: 'Kusaj Khalil', value: 'Kusaj Khalil' },
  { label: 'Kusak', value: 'Kusak' },
  { label: 'Kusapín', value: 'Kusapín' },
  { label: 'Kusatsu', value: 'Kusatsu' },
  { label: 'Kusatsu-Shi', value: 'Kusatsu-Shi' },
  { label: 'Kusel', value: 'Kusel' },
  { label: 'Kuševac', value: 'Kuševac' },
  { label: 'Kusey', value: 'Kusey' },
  { label: 'Kushalgarh', value: 'Kushalgarh' },
  { label: 'Kushalnagar', value: 'Kushalnagar' },
  { label: 'Kushar', value: 'Kushar' },
  { label: 'Kushchëvskaya', value: 'Kushchëvskaya' },
  { label: 'Kushelevka', value: 'Kushelevka' },
  { label: 'Kusheriki', value: 'Kusheriki' },
  { label: 'Kushikino', value: 'Kushikino' },
  { label: 'Kushima', value: 'Kushima' },
  { label: 'Kushima Shi', value: 'Kushima Shi' },
  { label: 'Kushinagar', value: 'Kushinagar' },
  { label: 'Kushiro', value: 'Kushiro' },
  { label: 'Kushiro Shi', value: 'Kushiro Shi' },
  { label: 'Kushk', value: 'Kushk' },
  { label: 'Kushnarënkovo', value: 'Kushnarënkovo' },
  { label: 'Kushnytsya', value: 'Kushnytsya' },
  { label: 'Kushoky', value: 'Kushoky' },
  { label: 'Kushtagi', value: 'Kushtagi' },
  { label: 'Kushtia ', value: 'Kushtia ' },
  { label: 'Kushuhum', value: 'Kushuhum' },
  { label: 'Kushva', value: 'Kushva' },
  { label: 'Kuskovo', value: 'Kuskovo' },
  { label: 'Kuślin', value: 'Kuślin' },
  { label: 'Kusmah', value: 'Kusmah' },
  { label: 'Kusmara', value: 'Kusmara' },
  { label: 'Küsnacht', value: 'Küsnacht' },
  { label: 'Küsnacht / Dorf', value: 'Küsnacht / Dorf' },
  { label: 'Küsnacht / Goldbach', value: 'Küsnacht / Goldbach' },
  { label: 'Küsnacht / Heslibach', value: 'Küsnacht / Heslibach' },
  { label: 'Küsnacht / Itschnach', value: 'Küsnacht / Itschnach' },
  { label: 'Küsnacht / Schiedhalden', value: 'Küsnacht / Schiedhalden' },
  { label: 'Kusŏng', value: 'Kusŏng' },
  { label: 'Küssnacht', value: 'Küssnacht' },
  { label: 'Kustavi', value: 'Kustavi' },
  { label: 'Küsten', value: 'Küsten' },
  { label: 'Kusterdingen', value: 'Kusterdingen' },
  { label: 'Kuštilj', value: 'Kuštilj' },
  { label: 'Kusumba', value: 'Kusumba' },
  { label: 'Kut Abdollah', value: 'Kut Abdollah' },
  { label: 'Kut Chap', value: 'Kut Chap' },
  { label: 'Kuta', value: 'Kuta' },
  { label: 'Kuta', value: 'Kuta' },
  { label: 'Kutah Darreh', value: 'Kutah Darreh' },
  { label: 'Kutaisi', value: 'Kutaisi' },
  { label: 'Kutao', value: 'Kutao' },
  { label: 'Kutch District', value: 'Kutch District' },
  { label: 'Kut-E Seyyed Naim', value: 'Kut-E Seyyed Naim' },
  { label: 'Kutenholz', value: 'Kutenholz' },
  { label: 'Kuteynykove', value: 'Kuteynykove' },
  { label: 'Kuthaund', value: 'Kuthaund' },
  { label: 'Kutiatodu', value: 'Kutiatodu' },
  { label: 'Kutina', value: 'Kutina' },
  { label: 'Kutiyana', value: 'Kutiyana' },
  { label: 'Kutjevo', value: 'Kutjevo' },
  { label: 'Kutloanong', value: 'Kutloanong' },
  { label: 'Kutná Hora', value: 'Kutná Hora' },
  { label: 'Kutno', value: 'Kutno' },
  { label: 'Kutoarjo', value: 'Kutoarjo' },
  { label: 'Kuttalam', value: 'Kuttalam' },
  { label: 'Kuttampuzha', value: 'Kuttampuzha' },
  { label: 'Küttigen', value: 'Küttigen' },
  { label: 'Kuttu Municipality', value: 'Kuttu Municipality' },
  { label: 'Kutulik', value: 'Kutulik' },
  { label: 'Kutum', value: 'Kutum' },
  { label: 'Kuty', value: 'Kuty' },
  { label: 'Kutzenhausen', value: 'Kutzenhausen' },
  { label: 'Kutztown', value: 'Kutztown' },
  { label: 'Kuurne', value: 'Kuurne' },
  { label: 'Kuusalu', value: 'Kuusalu' },
  { label: 'Kuusamo', value: 'Kuusamo' },
  { label: 'Kuusjoki', value: 'Kuusjoki' },
  { label: 'Kuvandyk', value: 'Kuvandyk' },
  { label: 'Kuvshinovo', value: 'Kuvshinovo' },
  { label: 'Kuvshinovskiy Rayon', value: 'Kuvshinovskiy Rayon' },
  { label: 'Kuwait City', value: 'Kuwait City' },
  { label: 'Kuwana', value: 'Kuwana' },
  { label: 'Kuwana-Shi', value: 'Kuwana-Shi' },
  { label: 'Kuybyshev', value: 'Kuybyshev' },
  { label: 'Kuybysheve', value: 'Kuybysheve' },
  { label: 'Kuybyshevo', value: 'Kuybyshevo' },
  { label: 'Kuybyshevskiy Rayon', value: 'Kuybyshevskiy Rayon' },
  { label: 'Kuybyshevskiy Zaton', value: 'Kuybyshevskiy Zaton' },
  { label: 'Kuyeda', value: 'Kuyeda' },
  { label: 'Kuytun', value: 'Kuytun' },
  { label: 'Kuytun', value: 'Kuytun' },
  { label: 'Kuytunskiy Rayon', value: 'Kuytunskiy Rayon' },
  { label: 'Kuyucak', value: 'Kuyucak' },
  { label: 'Kuyvozi', value: 'Kuyvozi' },
  { label: 'Kuz’Minki', value: 'Kuz’Minki' },
  { label: 'Kuz’Minskiye Otverzhki', value: 'Kuz’Minskiye Otverzhki' },
  { label: 'Kuzaran', value: 'Kuzaran' },
  { label: 'Kuzedeyevo', value: 'Kuzedeyevo' },
  { label: 'Kuzhener', value: 'Kuzhener' },
  { label: 'Kuzhenerskiy Rayon', value: 'Kuzhenerskiy Rayon' },
  { label: 'Kuzhenkino', value: 'Kuzhenkino' },
  { label: 'Kuzhithurai', value: 'Kuzhithurai' },
  { label: 'Kuzhorskaya', value: 'Kuzhorskaya' },
  { label: 'Kuzino', value: 'Kuzino' },
  { label: 'Kuzino', value: 'Kuzino' },
  { label: 'Kuzma', value: 'Kuzma' },
  { label: 'Kuznechikha', value: 'Kuznechikha' },
  { label: 'Kuznechnoye', value: 'Kuznechnoye' },
  { label: 'Kuznetsk', value: 'Kuznetsk' },
  { label: 'Kuznetskiy Rayon', value: 'Kuznetskiy Rayon' },
  { label: 'Kuźnia Raciborska', value: 'Kuźnia Raciborska' },
  { label: 'Kuźnica', value: 'Kuźnica' },
  { label: 'Kuzovatovo', value: 'Kuzovatovo' },
  { label: 'Kvæfjord', value: 'Kvæfjord' },
  { label: 'Kvænangen', value: 'Kvænangen' },
  { label: 'Kvalsund', value: 'Kvalsund' },
  { label: 'Kvam', value: 'Kvam' },
  { label: 'Kvänum', value: 'Kvänum' },
  { label: 'Kvarkeno', value: 'Kvarkeno' },
  { label: 'Kvarkenskiy Rayon', value: 'Kvarkenskiy Rayon' },
  { label: 'Kvartsitnyy', value: 'Kvartsitnyy' },
  { label: 'Kvasice', value: 'Kvasice' },
  { label: 'Kvasiny', value: 'Kvasiny' },
  { label: 'Kvasy', value: 'Kvasy' },
  { label: 'Kveda Chkhorots’Q’U', value: 'Kveda Chkhorots’Q’U' },
  { label: 'Kvicksund', value: 'Kvicksund' },
  { label: 'Kvidinge', value: 'Kvidinge' },
  { label: 'Kvinesdal', value: 'Kvinesdal' },
  { label: 'Kvinnherad', value: 'Kvinnherad' },
  { label: 'Kvissleby', value: 'Kvissleby' },
  { label: 'Kviteseid', value: 'Kviteseid' },
  { label: 'Kvitneve', value: 'Kvitneve' },
  { label: 'Kvitok', value: 'Kvitok' },
  { label: 'Kvitsøy', value: 'Kvitsøy' },
  { label: 'Kvívík', value: 'Kvívík' },
  { label: 'Kwaczała', value: 'Kwaczała' },
  { label: 'Kwadijk', value: 'Kwadijk' },
  { label: 'Kwadukuza', value: 'Kwadukuza' },
  { label: 'Kwakoa', value: 'Kwakoa' },
  { label: 'Kwaksan', value: 'Kwaksan' },
  { label: 'Kwale', value: 'Kwale' },
  { label: 'Kwale', value: 'Kwale' },
  { label: 'Kwali', value: 'Kwali' },
  { label: 'Kwaluseni', value: 'Kwaluseni' },
  { label: 'Kwang Binh', value: 'Kwang Binh' },
  { label: 'Kwangmyŏng', value: 'Kwangmyŏng' },
  { label: 'Kwangyang', value: 'Kwangyang' },
  { label: 'Kwatarkwashi', value: 'Kwatarkwashi' },
  { label: 'Kwekwe', value: 'Kwekwe' },
  { label: 'Kwekwe District', value: 'Kwekwe District' },
  { label: 'Kwidzyn', value: 'Kwidzyn' },
  { label: 'Kwilcz', value: 'Kwilcz' },
  { label: 'Kwinana', value: 'Kwinana' },
  { label: 'Kwintsheul', value: 'Kwintsheul' },
  { label: 'Kwolla', value: 'Kwolla' },
  { label: 'Kwun Tong', value: 'Kwun Tong' },
  { label: 'Kyabé', value: 'Kyabé' },
  { label: 'Kyabram', value: 'Kyabram' },
  { label: 'Kyadabek', value: 'Kyadabek' },
  { label: 'Kyaikkami', value: 'Kyaikkami' },
  { label: 'Kyaiklat', value: 'Kyaiklat' },
  { label: 'Kyaikto', value: 'Kyaikto' },
  { label: 'Kyain Seikgyi Township', value: 'Kyain Seikgyi Township' },
  { label: 'Kyaka', value: 'Kyaka' },
  { label: 'Kyakhta', value: 'Kyakhta' },
  { label: 'Kyakhulay', value: 'Kyakhulay' },
  { label: 'Kyathampalle', value: 'Kyathampalle' },
  { label: 'Kyaukse', value: 'Kyaukse' },
  { label: 'Kybartai', value: 'Kybartai' },
  { label: 'Kyelang', value: 'Kyelang' },
  { label: 'Kyenjojo', value: 'Kyenjojo' },
  { label: 'Kyiv', value: 'Kyiv' },
  { label: 'Kyjov', value: 'Kyjov' },
  { label: 'Kyle', value: 'Kyle' },
  { label: 'Kyle Bay', value: 'Kyle Bay' },
  { label: 'Kyllburg', value: 'Kyllburg' },
  { label: 'Kyllíni', value: 'Kyllíni' },
  { label: 'Kylmäkoski', value: 'Kylmäkoski' },
  { label: 'Kými', value: 'Kými' },
  { label: 'Kýmina', value: 'Kýmina' },
  { label: 'Kyneton', value: 'Kyneton' },
  { label: 'Kynopiástes', value: 'Kynopiástes' },
  { label: 'Kynšperk Nad Ohří', value: 'Kynšperk Nad Ohří' },
  { label: 'Kyo', value: 'Kyo' },
  { label: 'Kyogle', value: 'Kyogle' },
  { label: 'Kyŏngsŏng', value: 'Kyŏngsŏng' },
  { label: 'Kyŏngwŏn', value: 'Kyŏngwŏn' },
  { label: 'Kyosai', value: 'Kyosai' },
  { label: 'Kyōtanabe Shi', value: 'Kyōtanabe Shi' },
  { label: 'Kyōtango-Shi', value: 'Kyōtango-Shi' },
  { label: 'Kyotera', value: 'Kyotera' },
  { label: 'Kyoto', value: 'Kyoto' },
  { label: 'Kyparissía', value: 'Kyparissía' },
  { label: 'Kyperoúnta', value: 'Kyperoúnta' },
  { label: 'Kyprínos', value: 'Kyprínos' },
  { label: 'Kypséli', value: 'Kypséli' },
  { label: 'Kyra', value: 'Kyra' },
  { label: 'Kyrás Vrýsi', value: 'Kyrás Vrýsi' },
  { label: 'Kyren', value: 'Kyren' },
  { label: 'Kyrenia', value: 'Kyrenia' },
  { label: 'Kyrenia Municipality', value: 'Kyrenia Municipality' },
  { label: 'Kýria', value: 'Kýria' },
  { label: 'Kyriáki', value: 'Kyriáki' },
  { label: 'Kyritz', value: 'Kyritz' },
  { label: 'Kyrksæterøra', value: 'Kyrksæterøra' },
  { label: 'Kyrykivka', value: 'Kyrykivka' },
  { label: 'Kyrylivka', value: 'Kyrylivka' },
  { label: 'Kyshtovka', value: 'Kyshtovka' },
  { label: 'Kyshtym', value: 'Kyshtym' },
  { label: 'Kysucké Nové Mesto', value: 'Kysucké Nové Mesto' },
  { label: 'Kysyl-Syr', value: 'Kysyl-Syr' },
  { label: 'Kýthira', value: 'Kýthira' },
  { label: 'Kýthnos', value: 'Kýthnos' },
  { label: 'Kytmanovo', value: 'Kytmanovo' },
  { label: 'Kyurdarmir', value: 'Kyurdarmir' },
  { label: 'Kyustendil', value: 'Kyustendil' },
  { label: 'Kyyevo-Svyatoshyns’Kyy Rayon', value: 'Kyyevo-Svyatoshyns’Kyy Rayon' },
  { label: 'Kyyjärvi', value: 'Kyyjärvi' },
  { label: 'Kyzburun Pervyy', value: 'Kyzburun Pervyy' },
  { label: 'Kyzyl', value: 'Kyzyl' },
  { label: 'Kyzyl-Burun', value: 'Kyzyl-Burun' },
  { label: 'Kyzyldzhar', value: 'Kyzyldzhar' },
  { label: 'Kyzyl-Eshme', value: 'Kyzyl-Eshme' },
  { label: 'Kyzyl-Khaya', value: 'Kyzyl-Khaya' },
  { label: 'Kyzyl-Kyya', value: 'Kyzyl-Kyya' },
  { label: 'Kyzyl-Mazhalyk', value: 'Kyzyl-Mazhalyk' },
  { label: 'Kyzyl-Oktyabr’Skiy', value: 'Kyzyl-Oktyabr’Skiy' },
  { label: 'Kyzylorda', value: 'Kyzylorda' },
  { label: 'Kyzyl-Suu', value: 'Kyzyl-Suu' },
  { label: 'Kyzylzhar', value: 'Kyzylzhar' },
  { label: 'Kzyltu', value: 'Kzyltu' },
  { label: 'L’Arbaa Naït Irathen', value: 'L’Arbaa Naït Irathen' },
  { label: 'L’Escale', value: 'L’Escale' },
  { label: 'L’Escarène', value: 'L’Escarène' },
  { label: 'L’Étang-La-Ville', value: 'L’Étang-La-Ville' },
  { label: 'L’Étrat', value: 'L’Étrat' },
  { label: 'L’Govskiy', value: 'L’Govskiy' },
  { label: 'L’Govskiy Rayon', value: 'L’Govskiy Rayon' },
  { label: 'L’Govskoye', value: 'L’Govskoye' },
  { label: 'L’Isle-Adam', value: 'L’Isle-Adam' },
  { label: 'L’Isle-D’Abeau', value: 'L’Isle-D’Abeau' },
  { label: 'L’Isle-En-Dodon', value: 'L’Isle-En-Dodon' },
  { label: 'L’Isle-Jourdain', value: 'L’Isle-Jourdain' },
  { label: 'L’Isle-Sur-La-Sorgue', value: 'L’Isle-Sur-La-Sorgue' },
  { label: 'L’Vovskiy', value: 'L’Vovskiy' },
  { label: 'La Adela', value: 'La Adela' },
  { label: 'La Adrada', value: 'La Adrada' },
  { label: 'La Agüita', value: 'La Agüita' },
  { label: 'La Agustina', value: 'La Agustina' },
  { label: 'La Alameda', value: 'La Alameda' },
  { label: 'La Albarrada (San Francisco La Albarrada)', value: 'La Albarrada (San Francisco La Albarrada)' },
  { label: 'La Alberca', value: 'La Alberca' },
  { label: 'La Aldaba', value: 'La Aldaba' },
  { label: 'La Aldea', value: 'La Aldea' },
  { label: 'La Aldea', value: 'La Aldea' },
  { label: 'La Aldea', value: 'La Aldea' },
  { label: 'La Alianza', value: 'La Alianza' },
  { label: 'La Alianza', value: 'La Alianza' },
  { label: 'La Almunia De Doña Godina', value: 'La Almunia De Doña Godina' },
  { label: 'La Amistad', value: 'La Amistad' },
  { label: 'La Angostura', value: 'La Angostura' },
  { label: 'La Angostura', value: 'La Angostura' },
  { label: 'La Angostura De Los Altos', value: 'La Angostura De Los Altos' },
  { label: 'La Antigua', value: 'La Antigua' },
  { label: 'La Apartada', value: 'La Apartada' },
  { label: 'La Arbolada Plus', value: 'La Arbolada Plus' },
  { label: 'La Arena', value: 'La Arena' },
  { label: 'La Arena', value: 'La Arena' },
  { label: 'La Argentina', value: 'La Argentina' },
  { label: 'La Ascensión', value: 'La Ascensión' },
  { label: 'La Asunción', value: 'La Asunción' },
  { label: 'La Asunción', value: 'La Asunción' },
  { label: 'La Aurora', value: 'La Aurora' },
  { label: 'La Aurora', value: 'La Aurora' },
  { label: 'La Azozuca', value: 'La Azozuca' },
  { label: 'La Baconnière', value: 'La Baconnière' },
  { label: 'La Ballena', value: 'La Ballena' },
  { label: 'La Balme-De-Sillingy', value: 'La Balme-De-Sillingy' },
  { label: 'La Banda', value: 'La Banda' },
  { label: 'La Bañeza', value: 'La Bañeza' },
  { label: 'La Barasse', value: 'La Barasse' },
  { label: 'La Barca', value: 'La Barca' },
  { label: 'La Barra De Colotepec', value: 'La Barra De Colotepec' },
  { label: 'La Barranca', value: 'La Barranca' },
  { label: 'La Barre-De-Monts', value: 'La Barre-De-Monts' },
  { label: 'La Barreta', value: 'La Barreta' },
  { label: 'La Barreta', value: 'La Barreta' },
  { label: 'La Barthe-De-Neste', value: 'La Barthe-De-Neste' },
  { label: 'La Bassée', value: 'La Bassée' },
  { label: 'La Bastide-Des-Jourdans', value: 'La Bastide-Des-Jourdans' },
  { label: 'La Bâthie', value: 'La Bâthie' },
  { label: 'La Baticolla', value: 'La Baticolla' },
  { label: 'La Bâtie-Montgascon', value: 'La Bâtie-Montgascon' },
  { label: 'La Bâtie-Neuve', value: 'La Bâtie-Neuve' },
  { label: 'La Baule-Escoublac', value: 'La Baule-Escoublac' },
  { label: 'La Bazoche-Gouet', value: 'La Bazoche-Gouet' },
  { label: 'La Bazoge', value: 'La Bazoge' },
  { label: 'La Bégude-De-Mazenc', value: 'La Bégude-De-Mazenc' },
  { label: 'La Bélgica', value: 'La Bélgica' },
  { label: 'La Belleza', value: 'La Belleza' },
  { label: 'La Bernardière', value: 'La Bernardière' },
  { label: 'La Bernerie-En-Retz', value: 'La Bernerie-En-Retz' },
  { label: 'La Biolle', value: 'La Biolle' },
  { label: 'La Blanca', value: 'La Blanca' },
  { label: 'La Blanca', value: 'La Blanca' },
  { label: 'La Blancarde', value: 'La Blancarde' },
  { label: 'La Blanqueada', value: 'La Blanqueada' },
  { label: 'La Bocana', value: 'La Bocana' },
  { label: 'La Bohalle', value: 'La Bohalle' },
  { label: 'La Boisse', value: 'La Boisse' },
  { label: 'La Boissière-De-Montaigu', value: 'La Boissière-De-Montaigu' },
  { label: 'La Boissière-Des-Landes', value: 'La Boissière-Des-Landes' },
  { label: 'La Bonneville-Sur-Iton', value: 'La Bonneville-Sur-Iton' },
  { label: 'La Boquilla', value: 'La Boquilla' },
  { label: 'La Botte', value: 'La Botte' },
  { label: 'La Bouëxière', value: 'La Bouëxière' },
  { label: 'La Bouilladisse', value: 'La Bouilladisse' },
  { label: 'La Bourboule', value: 'La Bourboule' },
  { label: 'La Boussac', value: 'La Boussac' },
  { label: 'La Bouza', value: 'La Bouza' },
  { label: 'La Boveda', value: 'La Boveda' },
  { label: 'La Brea', value: 'La Brea' },
  { label: 'La Brecha', value: 'La Brecha' },
  { label: 'La Breita', value: 'La Breita' },
  { label: 'La Bresse', value: 'La Bresse' },
  { label: 'La Bridoire', value: 'La Bridoire' },
  { label: 'La Broque', value: 'La Broque' },
  { label: 'La Broquerie', value: 'La Broquerie' },
  { label: 'La Bruffière', value: 'La Bruffière' },
  { label: 'La Bruyère', value: 'La Bruyère' },
  { label: 'La Buisse', value: 'La Buisse' },
  { label: 'La Cabecera', value: 'La Cabecera' },
  { label: 'La Cabecera Concepción', value: 'La Cabecera Concepción' },
  { label: 'La Cabima', value: 'La Cabima' },
  { label: 'La Cabrera', value: 'La Cabrera' },
  { label: 'La Cabucelle', value: 'La Cabucelle' },
  { label: 'La Caja', value: 'La Caja' },
  { label: 'La Calade', value: 'La Calade' },
  { label: 'La Calamine', value: 'La Calamine' },
  { label: 'La Caldera', value: 'La Caldera' },
  { label: 'La Calera', value: 'La Calera' },
  { label: 'La Calera', value: 'La Calera' },
  { label: 'La Calera', value: 'La Calera' },
  { label: 'La Calera', value: 'La Calera' },
  { label: 'La Calera', value: 'La Calera' },
  { label: 'La Calera (Nacimientos)', value: 'La Calera (Nacimientos)' },
  { label: 'La Calera Fraccionamiento', value: 'La Calera Fraccionamiento' },
  { label: 'La Caletta', value: 'La Caletta' },
  { label: 'La California', value: 'La California' },
  { label: 'La Calle', value: 'La Calle' },
  { label: 'La Calmette', value: 'La Calmette' },
  { label: 'La Camelia', value: 'La Camelia' },
  { label: 'La Campa', value: 'La Campa' },
  { label: 'La Campana', value: 'La Campana' },
  { label: 'La Campanilla', value: 'La Campanilla' },
  { label: 'La Cañada', value: 'La Cañada' },
  { label: 'La Cañada', value: 'La Cañada' },
  { label: 'La Cañada', value: 'La Cañada' },
  { label: 'La Cañada', value: 'La Cañada' },
  { label: 'La Cañada', value: 'La Cañada' },
  { label: 'La Cañada Flintridge', value: 'La Cañada Flintridge' },
  { label: 'La Cañada Tezcaltilti', value: 'La Cañada Tezcaltilti' },
  { label: 'La Candelaria', value: 'La Candelaria' },
  { label: 'La Candelaria', value: 'La Candelaria' },
  { label: 'La Candelaria', value: 'La Candelaria' },
  { label: 'La Candelaria Teotlalpan', value: 'La Candelaria Teotlalpan' },
  { label: 'La Candelaria Tlapala', value: 'La Candelaria Tlapala' },
  { label: 'La Canela', value: 'La Canela' },
  { label: 'La Canourgue', value: 'La Canourgue' },
  { label: 'La Cantera', value: 'La Cantera' },
  { label: 'La Cantera', value: 'La Cantera' },
  { label: 'La Cantera', value: 'La Cantera' },
  { label: 'La Capelette', value: 'La Capelette' },
  { label: 'La Capelle', value: 'La Capelle' },
  { label: 'La Capelle-Lès-Boulogne', value: 'La Capelle-Lès-Boulogne' },
  { label: 'La Capilla', value: 'La Capilla' },
  { label: 'La Capilla', value: 'La Capilla' },
  { label: 'La Capilla', value: 'La Capilla' },
  { label: 'La Capilla', value: 'La Capilla' },
  { label: 'La Cardona', value: 'La Cardona' },
  { label: 'La Caridad', value: 'La Caridad' },
  { label: 'La Carierre', value: 'La Carierre' },
  { label: 'La Carlota', value: 'La Carlota' },
  { label: 'La Carlota', value: 'La Carlota' },
  { label: 'La Carrera', value: 'La Carrera' },
  { label: 'La Cassa', value: 'La Cassa' },
  { label: 'La Castellana', value: 'La Castellana' },
  { label: 'La Cavalerie', value: 'La Cavalerie' },
  { label: 'La Caya', value: 'La Caya' },
  { label: 'La Caye', value: 'La Caye' },
  { label: 'La Cebadilla', value: 'La Cebadilla' },
  { label: 'La Ceiba', value: 'La Ceiba' },
  { label: 'La Ceiba', value: 'La Ceiba' },
  { label: 'La Ceiba', value: 'La Ceiba' },
  { label: 'La Ceja', value: 'La Ceja' },
  { label: 'La Ceja', value: 'La Ceja' },
  { label: 'La Ceja De La Capilla', value: 'La Ceja De La Capilla' },
  { label: 'La Celia', value: 'La Celia' },
  { label: 'La Celle', value: 'La Celle' },
  { label: 'La Celle-Saint-Avant', value: 'La Celle-Saint-Avant' },
  { label: 'La Celle-Saint-Cloud', value: 'La Celle-Saint-Cloud' },
  { label: 'La Celle-Sur-Morin', value: 'La Celle-Sur-Morin' },
  { label: 'La Center', value: 'La Center' },
  { label: 'La Center', value: 'La Center' },
  { label: 'La Central', value: 'La Central' },
  { label: 'La Cerca Blanca', value: 'La Cerca Blanca' },
  { label: 'La Cerlangue', value: 'La Cerlangue' },
  { label: 'La Cerquilla', value: 'La Cerquilla' },
  { label: 'La Cesira', value: 'La Cesira' },
  { label: 'La Chaize-Le-Vicomte', value: 'La Chaize-Le-Vicomte' },
  { label: 'La Chambre', value: 'La Chambre' },
  { label: 'La Chapelle-Achard', value: 'La Chapelle-Achard' },
  { label: 'La Chapelle-Basse-Mer', value: 'La Chapelle-Basse-Mer' },
  { label: 'La Chapelle-De-Guinchay', value: 'La Chapelle-De-Guinchay' },
  { label: 'La Chapelle-De-La-Tour', value: 'La Chapelle-De-La-Tour' },
  { label: 'La Chapelle-Des-Fougeretz', value: 'La Chapelle-Des-Fougeretz' },
  { label: 'La Chapelle-Des-Marais', value: 'La Chapelle-Des-Marais' },
  { label: 'La Chapelle-Du-Genêt', value: 'La Chapelle-Du-Genêt' },
  { label: 'La Chapelle-Du-Noyer', value: 'La Chapelle-Du-Noyer' },
  { label: 'La Chapelle-En-Serval', value: 'La Chapelle-En-Serval' },
  { label: 'La Chapelle-Gauthier', value: 'La Chapelle-Gauthier' },
  { label: 'La Chapelle-Heulin', value: 'La Chapelle-Heulin' },
  { label: 'La Chapelle-Janson', value: 'La Chapelle-Janson' },
  { label: 'La Chapelle-La-Reine', value: 'La Chapelle-La-Reine' },
  { label: 'La Chapelle-Launay', value: 'La Chapelle-Launay' },
  { label: 'La Chapelle-Réanville', value: 'La Chapelle-Réanville' },
  { label: 'La Chapelle-Saint-Aubin', value: 'La Chapelle-Saint-Aubin' },
  { label: 'La Chapelle-Saint-Florent', value: 'La Chapelle-Saint-Florent' },
  { label: 'La Chapelle-Saint-Laurent', value: 'La Chapelle-Saint-Laurent' },
  { label: 'La Chapelle-Saint-Luc', value: 'La Chapelle-Saint-Luc' },
  { label: 'La Chapelle-Saint-Mesmin', value: 'La Chapelle-Saint-Mesmin' },
  { label: 'La Chapelle-Saint-Ursin', value: 'La Chapelle-Saint-Ursin' },
  { label: 'La Chapelle-Sur-Crécy', value: 'La Chapelle-Sur-Crécy' },
  { label: 'La Chapelle-Sur-Erdre', value: 'La Chapelle-Sur-Erdre' },
  { label: 'La Chapelle-Sur-Loire', value: 'La Chapelle-Sur-Loire' },
  { label: 'La Chapelle-Thouarault', value: 'La Chapelle-Thouarault' },
  { label: 'La Charité-Sur-Loire', value: 'La Charité-Sur-Loire' },
  { label: 'La Chartre-Sur-Le-Loir', value: 'La Chartre-Sur-Le-Loir' },
  { label: 'La Châtaigneraie', value: 'La Châtaigneraie' },
  { label: 'La Châtre', value: 'La Châtre' },
  { label: 'La Chaussée-Saint-Victor', value: 'La Chaussée-Saint-Victor' },
  { label: 'La Chaux-De-Fonds', value: 'La Chaux-De-Fonds' },
  { label: 'La Chaux-De-Fonds District', value: 'La Chaux-De-Fonds District' },
  { label: 'La Chevrolière', value: 'La Chevrolière' },
  { label: 'La Chiripa', value: 'La Chiripa' },
  { label: 'La Chorrera', value: 'La Chorrera' },
  { label: 'La Chorrera', value: 'La Chorrera' },
  { label: 'La Chueca Cadereyta Jiménez', value: 'La Chueca Cadereyta Jiménez' },
  { label: 'La Ciénaga', value: 'La Ciénaga' },
  { label: 'La Cienega', value: 'La Cienega' },
  { label: 'La Ciénega', value: 'La Ciénega' },
  { label: 'La Ciénega', value: 'La Ciénega' },
  { label: 'La Ciénega', value: 'La Ciénega' },
  { label: 'La Ciénega De Huaistita', value: 'La Ciénega De Huaistita' },
  { label: 'La Cieneguita', value: 'La Cieneguita' },
  { label: 'La Cieneguita', value: 'La Cieneguita' },
  { label: 'La Ciotat', value: 'La Ciotat' },
  { label: 'La Cisterna', value: 'La Cisterna' },
  { label: 'La Ciudad', value: 'La Ciudad' },
  { label: 'La Clayette', value: 'La Clayette' },
  { label: 'La Clery', value: 'La Clery' },
  { label: 'La Clotilde', value: 'La Clotilde' },
  { label: 'La Clusaz', value: 'La Clusaz' },
  { label: 'La Cluse-Et-Mijoux', value: 'La Cluse-Et-Mijoux' },
  { label: 'La Cocha', value: 'La Cocha' },
  { label: 'La Cofradía', value: 'La Cofradía' },
  { label: 'La Cofradía Del Rosario', value: 'La Cofradía Del Rosario' },
  { label: 'La Colle-Sur-Loup', value: 'La Colle-Sur-Loup' },
  { label: 'La Colmena', value: 'La Colmena' },
  { label: 'La Colmena De Arriba', value: 'La Colmena De Arriba' },
  { label: 'La Colonia', value: 'La Colonia' },
  { label: 'La Colonia', value: 'La Colonia' },
  { label: 'La Colonia', value: 'La Colonia' },
  { label: 'La Colonia Guadalupe', value: 'La Colonia Guadalupe' },
  { label: 'La Colonia San Juanito', value: 'La Colonia San Juanito' },
  { label: 'La Colorada', value: 'La Colorada' },
  { label: 'La Colorada', value: 'La Colorada' },
  { label: 'La Colorada', value: 'La Colorada' },
  { label: 'La Colorada', value: 'La Colorada' },
  { label: 'La Comercial', value: 'La Comercial' },
  { label: 'La Compañia', value: 'La Compañia' },
  { label: 'La Compañía', value: 'La Compañía' },
  { label: 'La Compañía', value: 'La Compañía' },
  { label: 'La Competencia', value: 'La Competencia' },
  { label: 'La Comunidad', value: 'La Comunidad' },
  { label: 'La Concepción', value: 'La Concepción' },
  { label: 'La Concepción', value: 'La Concepción' },
  { label: 'La Concepción', value: 'La Concepción' },
  { label: 'La Concepción', value: 'La Concepción' },
  { label: 'La Concepción', value: 'La Concepción' },
  { label: 'La Concepción', value: 'La Concepción' },
  { label: 'La Concepción', value: 'La Concepción' },
  { label: 'La Concepción', value: 'La Concepción' },
  { label: 'La Concepción', value: 'La Concepción' },
  { label: 'La Concepción', value: 'La Concepción' },
  { label: 'La Concepción (La Concha)', value: 'La Concepción (La Concha)' },
  { label: 'La Concepción Coatipac (La Conchita)', value: 'La Concepción Coatipac (La Conchita)' },
  { label: 'La Concepción De Hidalgo', value: 'La Concepción De Hidalgo' },
  { label: 'La Concepción De Los Baños', value: 'La Concepción De Los Baños' },
  { label: 'La Concepción Enyege', value: 'La Concepción Enyege' },
  { label: 'La Concepción Xochicuautla', value: 'La Concepción Xochicuautla' },
  { label: 'La Conception', value: 'La Conception' },
  { label: 'La Conception', value: 'La Conception' },
  { label: 'La Concha', value: 'La Concha' },
  { label: 'La Concha (La Concepción)', value: 'La Concha (La Concepción)' },
  { label: 'La Conchilla', value: 'La Conchilla' },
  { label: 'La Conchita', value: 'La Conchita' },
  { label: 'La Concordia', value: 'La Concordia' },
  { label: 'La Concordia', value: 'La Concordia' },
  { label: 'La Concordia', value: 'La Concordia' },
  { label: 'La Conquista', value: 'La Conquista' },
  { label: 'La Constancia', value: 'La Constancia' },
  { label: 'La Constitución', value: 'La Constitución' },
  { label: 'La Constitución Toltepec', value: 'La Constitución Toltepec' },
  { label: 'La Coquille', value: 'La Coquille' },
  { label: 'La Corregidora', value: 'La Corregidora' },
  { label: 'La Coste', value: 'La Coste' },
  { label: 'La Côte-Saint-André', value: 'La Côte-Saint-André' },
  { label: 'La Couarde-Sur-Mer', value: 'La Couarde-Sur-Mer' },
  { label: 'La Courneuve', value: 'La Courneuve' },
  { label: 'La Couronne', value: 'La Couronne' },
  { label: 'La Courtine', value: 'La Courtine' },
  { label: 'La Courville', value: 'La Courville' },
  { label: 'La Couture', value: 'La Couture' },
  { label: 'La Couture-Boussey', value: 'La Couture-Boussey' },
  { label: 'La Crau', value: 'La Crau' },
  { label: 'La Crèche', value: 'La Crèche' },
  { label: 'La Crescent', value: 'La Crescent' },
  { label: 'La Crescenta-Montrose', value: 'La Crescenta-Montrose' },
  { label: 'La Cretaz-Roisan', value: 'La Cretaz-Roisan' },
  { label: 'La Criba', value: 'La Criba' },
  { label: 'La Criolla', value: 'La Criolla' },
  { label: 'La Croft', value: 'La Croft' },
  { label: 'La Croix Chabourgh', value: 'La Croix Chabourgh' },
  { label: 'La Croix Maingot', value: 'La Croix Maingot' },
  { label: 'La Croix-En-Touraine', value: 'La Croix-En-Touraine' },
  { label: 'La Croix-Rouge', value: 'La Croix-Rouge' },
  { label: 'La Croix-Valmer', value: 'La Croix-Valmer' },
  { label: 'La Crosse', value: 'La Crosse' },
  { label: 'La Crosse', value: 'La Crosse' },
  { label: 'La Crosse County', value: 'La Crosse County' },
  { label: 'La Cruz', value: 'La Cruz' },
  { label: 'La Cruz', value: 'La Cruz' },
  { label: 'La Cruz', value: 'La Cruz' },
  { label: 'La Cruz', value: 'La Cruz' },
  { label: 'La Cruz', value: 'La Cruz' },
  { label: 'La Cruz', value: 'La Cruz' },
  { label: 'La Cruz', value: 'La Cruz' },
  { label: 'La Cruz', value: 'La Cruz' },
  { label: 'La Cruz', value: 'La Cruz' },
  { label: 'La Cruz', value: 'La Cruz' },
  { label: 'La Cruz De Loreto', value: 'La Cruz De Loreto' },
  { label: 'La Cruz De Río Grande', value: 'La Cruz De Río Grande' },
  { label: 'La Cruz Del Palmar', value: 'La Cruz Del Palmar' },
  { label: 'La Cruz Y Carrizal', value: 'La Cruz Y Carrizal' },
  { label: 'La Cuchilla', value: 'La Cuchilla' },
  { label: 'La Cuesta', value: 'La Cuesta' },
  { label: 'La Cuesta', value: 'La Cuesta' },
  { label: 'La Cuesta De Medina', value: 'La Cuesta De Medina' },
  { label: 'La Cueva', value: 'La Cueva' },
  { label: 'La Cuevita', value: 'La Cuevita' },
  { label: 'La Cumbre', value: 'La Cumbre' },
  { label: 'La Cumbre', value: 'La Cumbre' },
  { label: 'La Curva', value: 'La Curva' },
  { label: 'La Curva', value: 'La Curva' },
  { label: 'La Cygne', value: 'La Cygne' },
  { label: 'La D', value: 'La D' },
  { label: 'La Daguenière', value: 'La Daguenière' },
  { label: 'La Defensa', value: 'La Defensa' },
  { label: 'La Defense', value: 'La Defense' },
  { label: 'La Delorme', value: 'La Delorme' },
  { label: 'La Democracia', value: 'La Democracia' },
  { label: 'La Descubierta', value: 'La Descubierta' },
  { label: 'La Despensa', value: 'La Despensa' },
  { label: 'La Destrousse', value: 'La Destrousse' },
  { label: 'La Dicha', value: 'La Dicha' },
  { label: 'La Dicha', value: 'La Dicha' },
  { label: 'La Dolores', value: 'La Dolores' },
  { label: 'La Dolorita', value: 'La Dolorita' },
  { label: 'La Dominelais', value: 'La Dominelais' },
  { label: 'La Dorada', value: 'La Dorada' },
  { label: 'La Eduvigis', value: 'La Eduvigis' },
  { label: 'La Encarnación', value: 'La Encarnación' },
  { label: 'La Encarnación', value: 'La Encarnación' },
  { label: 'La Enea', value: 'La Enea' },
  { label: 'La Ensenada', value: 'La Ensenada' },
  { label: 'La Entrada', value: 'La Entrada' },
  { label: 'La Entrada', value: 'La Entrada' },
  { label: 'La Ermita', value: 'La Ermita' },
  { label: 'La Ermita', value: 'La Ermita' },
  { label: 'La Ermita', value: 'La Ermita' },
  { label: 'La Ermita (Nueva Jerusalén)', value: 'La Ermita (Nueva Jerusalén)' },
  { label: 'La Erradura', value: 'La Erradura' },
  { label: 'La Escalera', value: 'La Escalera' },
  { label: 'La Escondida', value: 'La Escondida' },
  { label: 'La Escondida', value: 'La Escondida' },
  { label: 'La Escondida', value: 'La Escondida' },
  { label: 'La Escuadra', value: 'La Escuadra' },
  { label: 'La Esmeralda', value: 'La Esmeralda' },
  { label: 'La Esmeralda', value: 'La Esmeralda' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza', value: 'La Esperanza' },
  { label: 'La Esperanza (El Zapotal)', value: 'La Esperanza (El Zapotal)' },
  { label: 'La Esperanza [Granjas Familiares]', value: 'La Esperanza [Granjas Familiares]' },
  { label: 'La Espigadilla', value: 'La Espigadilla' },
  { label: 'La Esquina', value: 'La Esquina' },
  { label: 'La Estacada', value: 'La Estacada' },
  { label: 'La Estacada', value: 'La Estacada' },
  { label: 'La Estación', value: 'La Estación' },
  { label: 'La Estancia', value: 'La Estancia' },
  { label: 'La Estancia', value: 'La Estancia' },
  { label: 'La Estancia', value: 'La Estancia' },
  { label: 'La Estancia', value: 'La Estancia' },
  { label: 'La Estancia', value: 'La Estancia' },
  { label: 'La Estancia De Amezcua', value: 'La Estancia De Amezcua' },
  { label: 'La Estancia De Arriba', value: 'La Estancia De Arriba' },
  { label: 'La Estancia De Ayones', value: 'La Estancia De Ayones' },
  { label: 'La Estancia De Navarro', value: 'La Estancia De Navarro' },
  { label: 'La Estancia De San José Del Carmen', value: 'La Estancia De San José Del Carmen' },
  { label: 'La Estancia Sector Uno (La Estancia)', value: 'La Estancia Sector Uno (La Estancia)' },
  { label: 'La Estancita', value: 'La Estancita' },
  { label: 'La Estanzuela', value: 'La Estanzuela' },
  { label: 'La Estanzuela', value: 'La Estanzuela' },
  { label: 'La Estrella', value: 'La Estrella' },
  { label: 'La Estrella', value: 'La Estrella' },
  { label: 'La Estrella', value: 'La Estrella' },
  { label: 'La Estrella', value: 'La Estrella' },
  { label: 'La Estrella', value: 'La Estrella' },
  { label: 'La Estrella', value: 'La Estrella' },
  { label: 'La Falda', value: 'La Falda' },
  { label: 'La Fama', value: 'La Fama' },
  { label: 'La Fare-Les-Oliviers', value: 'La Fare-Les-Oliviers' },
  { label: 'La Fargue', value: 'La Fargue' },
  { label: 'La Farlède', value: 'La Farlède' },
  { label: 'La Fe', value: 'La Fe' },
  { label: 'La Fère', value: 'La Fère' },
  { label: 'La Feria', value: 'La Feria' },
  { label: 'La Fermina', value: 'La Fermina' },
  { label: 'La Ferrière', value: 'La Ferrière' },
  { label: 'La Ferrière-Aux-Étangs', value: 'La Ferrière-Aux-Étangs' },
  { label: 'La Ferté-Alais', value: 'La Ferté-Alais' },
  { label: 'La Ferté-Bernard', value: 'La Ferté-Bernard' },
  { label: 'La Ferté-Gaucher', value: 'La Ferté-Gaucher' },
  { label: 'La Ferté-Imbault', value: 'La Ferté-Imbault' },
  { label: 'La Ferté-Macé', value: 'La Ferté-Macé' },
  { label: 'La Ferté-Milon', value: 'La Ferté-Milon' },
  { label: 'La Ferté-Saint-Aubin', value: 'La Ferté-Saint-Aubin' },
  { label: 'La Ferté-Sous-Jouarre', value: 'La Ferté-Sous-Jouarre' },
  { label: 'La Feuillie', value: 'La Feuillie' },
  { label: 'La Figurita', value: 'La Figurita' },
  { label: 'La Finca', value: 'La Finca' },
  { label: 'La Flamengrie', value: 'La Flamengrie' },
  { label: 'La Flecha', value: 'La Flecha' },
  { label: 'La Flèche', value: 'La Flèche' },
  { label: 'La Flocellière', value: 'La Flocellière' },
  { label: 'La Flor', value: 'La Flor' },
  { label: 'La Flora', value: 'La Flora' },
  { label: 'La Floreña', value: 'La Floreña' },
  { label: 'La Floresta', value: 'La Floresta' },
  { label: 'La Floresta', value: 'La Floresta' },
  { label: 'La Florida', value: 'La Florida' },
  { label: 'La Florida', value: 'La Florida' },
  { label: 'La Florida', value: 'La Florida' },
  { label: 'La Florida', value: 'La Florida' },
  { label: 'La Florida', value: 'La Florida' },
  { label: 'La Flotte', value: 'La Flotte' },
  { label: 'La Font De La Figuera', value: 'La Font De La Figuera' },
  { label: 'La Force', value: 'La Force' },
  { label: 'La Forest-Landerneau', value: 'La Forest-Landerneau' },
  { label: 'La Forêt-Fouesnant', value: 'La Forêt-Fouesnant' },
  { label: 'La Forêt-Sur-Sèvre', value: 'La Forêt-Sur-Sèvre' },
  { label: 'La Forma', value: 'La Forma' },
  { label: 'La Fortuna', value: 'La Fortuna' },
  { label: 'La Fortuna', value: 'La Fortuna' },
  { label: 'La Fortuna', value: 'La Fortuna' },
  { label: 'La Fortuna Fraccionamiento', value: 'La Fortuna Fraccionamiento' },
  { label: 'La Fouillade', value: 'La Fouillade' },
  { label: 'La Fouillouse', value: 'La Fouillouse' },
  { label: 'La Fourragère', value: 'La Fourragère' },
  { label: 'La Francheville', value: 'La Francheville' },
  { label: 'La Francia', value: 'La Francia' },
  { label: 'La Fresnais', value: 'La Fresnais' },
  { label: 'La Frette-Sur-Seine', value: 'La Frette-Sur-Seine' },
  { label: 'La Fría', value: 'La Fría' },
  { label: 'La Frontera', value: 'La Frontera' },
  { label: 'La Fuente', value: 'La Fuente' },
  { label: 'La Fuente De San Esteban', value: 'La Fuente De San Esteban' },
  { label: 'La Fundición (Quinta Manzana)', value: 'La Fundición (Quinta Manzana)' },
  { label: 'La Gacilly', value: 'La Gacilly' },
  { label: 'La Galarza', value: 'La Galarza' },
  { label: 'La Garceana', value: 'La Garceana' },
  { label: 'La Garde', value: 'La Garde' },
  { label: 'La Garde-Adhémar', value: 'La Garde-Adhémar' },
  { label: 'La Garde-Freinet', value: 'La Garde-Freinet' },
  { label: 'La Garenne-Colombes', value: 'La Garenne-Colombes' },
  { label: 'La Garita Jalisco', value: 'La Garita Jalisco' },
  { label: 'La Garnache', value: 'La Garnache' },
  { label: 'La Garruña', value: 'La Garruña' },
  { label: 'La Gaubretière', value: 'La Gaubretière' },
  { label: 'La Gaude', value: 'La Gaude' },
  { label: 'La Gaulette', value: 'La Gaulette' },
  { label: 'La Gavia', value: 'La Gavia' },
  { label: 'La Génétouze', value: 'La Génétouze' },
  { label: 'La Gi', value: 'La Gi' },
  { label: 'La Gila', value: 'La Gila' },
  { label: 'La Ginebrosa', value: 'La Ginebrosa' },
  { label: 'La Glacerie', value: 'La Glacerie' },
  { label: 'La Gloria', value: 'La Gloria' },
  { label: 'La Gloria', value: 'La Gloria' },
  { label: 'La Gloria', value: 'La Gloria' },
  { label: 'La Gloria', value: 'La Gloria' },
  { label: 'La Gloria', value: 'La Gloria' },
  { label: 'La Gloria', value: 'La Gloria' },
  { label: 'La Glorieta', value: 'La Glorieta' },
  { label: 'La Goleta', value: 'La Goleta' },
  { label: 'La Goma', value: 'La Goma' },
  { label: 'La Gomera', value: 'La Gomera' },
  { label: 'La Gorgue', value: 'La Gorgue' },
  { label: 'La Gotera', value: 'La Gotera' },
  { label: 'La Gouesnière', value: 'La Gouesnière' },
  { label: 'La Goulette', value: 'La Goulette' },
  { label: 'La Grand-Croix', value: 'La Grand-Croix' },
  { label: 'La Grande', value: 'La Grande' },
  { label: 'La Grande-Motte', value: 'La Grande-Motte' },
  { label: 'La Grande-Paroisse', value: 'La Grande-Paroisse' },
  { label: 'La Grandeza', value: 'La Grandeza' },
  { label: 'La Grange', value: 'La Grange' },
  { label: 'La Grange', value: 'La Grange' },
  { label: 'La Grange', value: 'La Grange' },
  { label: 'La Grange', value: 'La Grange' },
  { label: 'La Grange Park', value: 'La Grange Park' },
  { label: 'La Granja', value: 'La Granja' },
  { label: 'La Granja', value: 'La Granja' },
  { label: 'La Granja', value: 'La Granja' },
  { label: 'La Granjena', value: 'La Granjena' },
  { label: 'La Griega', value: 'La Griega' },
  { label: 'La Grigonnais', value: 'La Grigonnais' },
  { label: 'La Grita', value: 'La Grita' },
  { label: 'La Grulla', value: 'La Grulla' },
  { label: 'La Guacamaya', value: 'La Guacamaya' },
  { label: 'La Guadalupana', value: 'La Guadalupana' },
  { label: 'La Guadalupe', value: 'La Guadalupe' },
  { label: 'La Guadalupe', value: 'La Guadalupe' },
  { label: 'La Guadalupe', value: 'La Guadalupe' },
  { label: 'La Guadalupe', value: 'La Guadalupe' },
  { label: 'La Guaira', value: 'La Guaira' },
  { label: 'La Guama', value: 'La Guama' },
  { label: 'La Guancha', value: 'La Guancha' },
  { label: 'La Guásima', value: 'La Guásima' },
  { label: 'La Guásima', value: 'La Guásima' },
  { label: 'La Guásima', value: 'La Guásima' },
  { label: 'La Guata', value: 'La Guata' },
  { label: 'La Guerche-De-Bretagne', value: 'La Guerche-De-Bretagne' },
  { label: 'La Guérinière', value: 'La Guérinière' },
  { label: 'La Guerre', value: 'La Guerre' },
  { label: 'La Guerre/Chicken Back Street', value: 'La Guerre/Chicken Back Street' },
  { label: 'La Guinea', value: 'La Guinea' },
  { label: 'La Guingueta Dix', value: 'La Guingueta Dix' },
  { label: 'La Guyonnière', value: 'La Guyonnière' },
  { label: 'La Habana Vieja', value: 'La Habana Vieja' },
  { label: 'La Habra', value: 'La Habra' },
  { label: 'La Habra Heights', value: 'La Habra Heights' },
  { label: 'La Hacienda', value: 'La Hacienda' },
  { label: 'La Haciendita', value: 'La Haciendita' },
  { label: 'La Haie-Fouassière', value: 'La Haie-Fouassière' },
  { label: 'La Harpe', value: 'La Harpe' },
  { label: 'La Haut', value: 'La Haut' },
  { label: 'La Haut', value: 'La Haut' },
  { label: 'La Haute-Saint-Charles', value: 'La Haute-Saint-Charles' },
  { label: 'La Haye-Du-Puits', value: 'La Haye-Du-Puits' },
  { label: 'La Haye-Malherbe', value: 'La Haye-Malherbe' },
  { label: 'La Haye-Pesnel', value: 'La Haye-Pesnel' },
  { label: 'La Hermosura', value: 'La Hermosura' },
  { label: 'La Herradura', value: 'La Herradura' },
  { label: 'La Herradura', value: 'La Herradura' },
  { label: 'La Herradura', value: 'La Herradura' },
  { label: 'La Higuera', value: 'La Higuera' },
  { label: 'La Higuera', value: 'La Higuera' },
  { label: 'La Higuerilla (Los Lirios)', value: 'La Higuerilla (Los Lirios)' },
  { label: 'La Higuerita (Colonia San Rafael)', value: 'La Higuerita (Colonia San Rafael)' },
  { label: 'La Homa', value: 'La Homa' },
  { label: 'La Horqueta (Poblado Doce)', value: 'La Horqueta (Poblado Doce)' },
  { label: 'La Houssaye-En-Brie', value: 'La Houssaye-En-Brie' },
  { label: 'La Huaca', value: 'La Huaca' },
  { label: 'La Huacana', value: 'La Huacana' },
  { label: 'La Huanica', value: 'La Huanica' },
  { label: 'La Huerta', value: 'La Huerta' },
  { label: 'La Huerta', value: 'La Huerta' },
  { label: 'La Huesa', value: 'La Huesa' },
  { label: 'La Huevera', value: 'La Huevera' },
  { label: 'La Hulpe', value: 'La Hulpe' },
  { label: 'La Humedad', value: 'La Humedad' },
  { label: 'La Ibérica (La Gotera)', value: 'La Ibérica (La Gotera)' },
  { label: 'La Iglesuela Del Cid', value: 'La Iglesuela Del Cid' },
  { label: 'La Iguala', value: 'La Iguala' },
  { label: 'La Independencia', value: 'La Independencia' },
  { label: 'La Independencia (Las Pilas)', value: 'La Independencia (Las Pilas)' },
  { label: 'La Isla', value: 'La Isla' },
  { label: 'La Isla', value: 'La Isla' },
  { label: 'La Isla De Chapachapa', value: 'La Isla De Chapachapa' },
  { label: 'La Jagua De Ibirico', value: 'La Jagua De Ibirico' },
  { label: 'La Jagua Del Pilar', value: 'La Jagua Del Pilar' },
  { label: 'La Jarne', value: 'La Jarne' },
  { label: 'La Jarrie', value: 'La Jarrie' },
  { label: 'La Jarrilla', value: 'La Jarrilla' },
  { label: 'La Jigua', value: 'La Jigua' },
  { label: 'La Joliette', value: 'La Joliette' },
  { label: 'La Jolla', value: 'La Jolla' },
  { label: 'La Joya', value: 'La Joya' },
  { label: 'La Joya', value: 'La Joya' },
  { label: 'La Joya', value: 'La Joya' },
  { label: 'La Joya', value: 'La Joya' },
  { label: 'La Joya', value: 'La Joya' },
  { label: 'La Joya', value: 'La Joya' },
  { label: 'La Joya', value: 'La Joya' },
  { label: 'La Joya Chica', value: 'La Joya Chica' },
  { label: 'La Joya De Alardín', value: 'La Joya De Alardín' },
  { label: 'La Joya De Calvillo', value: 'La Joya De Calvillo' },
  { label: 'La Joya Grande', value: 'La Joya Grande' },
  { label: 'La Jubaudière', value: 'La Jubaudière' },
  { label: 'La Julia', value: 'La Julia' },
  { label: 'La Jumellière', value: 'La Jumellière' },
  { label: 'La Junta', value: 'La Junta' },
  { label: 'La Junta', value: 'La Junta' },
  { label: 'La Junta', value: 'La Junta' },
  { label: 'La Junta', value: 'La Junta' },
  { label: 'La Jutosa', value: 'La Jutosa' },
  { label: 'La Labor', value: 'La Labor' },
  { label: 'La Labor', value: 'La Labor' },
  { label: 'La Labor', value: 'La Labor' },
  { label: 'La Labor', value: 'La Labor' },
  { label: 'La Labor De La Concepción', value: 'La Labor De La Concepción' },
  { label: 'La Labor De Los Alba', value: 'La Labor De Los Alba' },
  { label: 'La Laborcita', value: 'La Laborcita' },
  { label: 'La Ladera', value: 'La Ladera' },
  { label: 'La Ladera Grande', value: 'La Ladera Grande' },
  { label: 'La Laguna', value: 'La Laguna' },
  { label: 'La Laguna', value: 'La Laguna' },
  { label: 'La Laguna', value: 'La Laguna' },
  { label: 'La Laguna', value: 'La Laguna' },
  { label: 'La Laguna', value: 'La Laguna' },
  { label: 'La Laguna', value: 'La Laguna' },
  { label: 'La Laguna', value: 'La Laguna' },
  { label: 'La Laguna', value: 'La Laguna' },
  { label: 'La Laguna', value: 'La Laguna' },
  { label: 'La Laguna Grande', value: 'La Laguna Grande' },
  { label: 'La Laguna Y Monte Del Castillo', value: 'La Laguna Y Monte Del Castillo' },
  { label: 'La Lagunilla', value: 'La Lagunilla' },
  { label: 'La Lagunita', value: 'La Lagunita' },
  { label: 'La Laja', value: 'La Laja' },
  { label: 'La Laja', value: 'La Laja' },
  { label: 'La Laja', value: 'La Laja' },
  { label: 'La Laja', value: 'La Laja' },
  { label: 'La Lande-De-Fronsac', value: 'La Lande-De-Fronsac' },
  { label: 'La Lande-Patry', value: 'La Lande-Patry' },
  { label: 'La Lastrilla', value: 'La Lastrilla' },
  { label: 'La Laura-Malenga', value: 'La Laura-Malenga' },
  { label: 'La Leonesa', value: 'La Leonesa' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'La Ligua', value: 'La Ligua' },
  { label: 'La Lima', value: 'La Lima' },
  { label: 'La Lima', value: 'La Lima' },
  { label: 'La Lima', value: 'La Lima' },
  { label: 'La Limouzinière', value: 'La Limouzinière' },
  { label: 'La Línea', value: 'La Línea' },
  { label: 'La Lira', value: 'La Lira' },
  { label: 'La Llanada', value: 'La Llanada' },
  { label: 'La Llave', value: 'La Llave' },
  { label: 'La Lobera', value: 'La Lobera' },
  { label: 'La Lobera', value: 'La Lobera' },
  { label: 'La Lobera De Portillo', value: 'La Lobera De Portillo' },
  { label: 'La Loggia', value: 'La Loggia' },
  { label: 'La Loma', value: 'La Loma' },
  { label: 'La Loma', value: 'La Loma' },
  { label: 'La Loma', value: 'La Loma' },
  { label: 'La Loma', value: 'La Loma' },
  { label: 'La Loma', value: 'La Loma' },
  { label: 'La Loma', value: 'La Loma' },
  { label: 'La Loma', value: 'La Loma' },
  { label: 'La Loma (La Loma De Quila)', value: 'La Loma (La Loma De Quila)' },
  { label: 'La Loma Cuexcontitlán', value: 'La Loma Cuexcontitlán' },
  { label: 'La Loma De Los Negritos', value: 'La Loma De Los Negritos' },
  { label: 'La Loma De Los Rizo', value: 'La Loma De Los Rizo' },
  { label: 'La Loma Del Nopal', value: 'La Loma Del Nopal' },
  { label: 'La Lomita', value: 'La Lomita' },
  { label: 'La Londe-Les-Maures', value: 'La Londe-Les-Maures' },
  { label: 'La Longueville', value: 'La Longueville' },
  { label: 'La Loubière', value: 'La Loubière' },
  { label: 'La Loupe', value: 'La Loupe' },
  { label: 'La Louvière', value: 'La Louvière' },
  { label: 'La Lucha', value: 'La Lucha' },
  { label: 'La Luisa', value: 'La Luisa' },
  { label: 'La Luz', value: 'La Luz' },
  { label: 'La Luz', value: 'La Luz' },
  { label: 'La Luz', value: 'La Luz' },
  { label: 'La Luz', value: 'La Luz' },
  { label: 'La Luz', value: 'La Luz' },
  { label: 'La Luz', value: 'La Luz' },
  { label: 'La Luz', value: 'La Luz' },
  { label: 'La Luz', value: 'La Luz' },
  { label: 'La Luz', value: 'La Luz' },
  { label: 'La Luz De Juárez', value: 'La Luz De Juárez' },
  { label: 'La Macarena', value: 'La Macarena' },
  { label: 'La Machine', value: 'La Machine' },
  { label: 'La Maddalena', value: 'La Maddalena' },
  { label: 'La Madeleine', value: 'La Madeleine' },
  { label: 'La Madeleine-De-Nonancourt', value: 'La Madeleine-De-Nonancourt' },
  { label: 'La Maestranza', value: 'La Maestranza' },
  { label: 'La Magdalena', value: 'La Magdalena' },
  { label: 'La Magdalena', value: 'La Magdalena' },
  { label: 'La Magdalena Chichicaspa', value: 'La Magdalena Chichicaspa' },
  { label: 'La Magdalena De Los Reyes (La Magdalena)', value: 'La Magdalena De Los Reyes (La Magdalena)' },
  { label: 'La Magdalena Tenexpan', value: 'La Magdalena Tenexpan' },
  { label: 'La Magdalena Tetela Morelos', value: 'La Magdalena Tetela Morelos' },
  { label: 'La Magdalena Tlaltelulco', value: 'La Magdalena Tlaltelulco' },
  { label: 'La Magdalena Yancuitlalpan', value: 'La Magdalena Yancuitlalpan' },
  { label: 'La Magdeleine', value: 'La Magdeleine' },
  { label: 'La Mailleraye-Sur-Seine', value: 'La Mailleraye-Sur-Seine' },
  { label: 'La Malbaie', value: 'La Malbaie' },
  { label: 'La Maná', value: 'La Maná' },
  { label: 'La Manga Del Mar Menor', value: 'La Manga Del Mar Menor' },
  { label: 'La Manzanilla', value: 'La Manzanilla' },
  { label: 'La Manzanilla De La Paz', value: 'La Manzanilla De La Paz' },
  { label: 'La Marque', value: 'La Marque' },
  { label: 'La Martella', value: 'La Martella' },
  { label: 'La Maruja', value: 'La Maruja' },
  { label: 'La Masica', value: 'La Masica' },
  { label: 'La Massana', value: 'La Massana' },
  { label: 'La Massimina-Casal Lumbroso', value: 'La Massimina-Casal Lumbroso' },
  { label: 'La Mata', value: 'La Mata' },
  { label: 'La Matanza De Acentejo', value: 'La Matanza De Acentejo' },
  { label: 'La Meauffe', value: 'La Meauffe' },
  { label: 'La Méaugon', value: 'La Méaugon' },
  { label: 'La Meignanne', value: 'La Meignanne' },
  { label: 'La Meilleraie-Tillay', value: 'La Meilleraie-Tillay' },
  { label: 'La Meilleraye-De-Bretagne', value: 'La Meilleraye-De-Bretagne' },
  { label: 'La Membrolle-Sur-Choisille', value: 'La Membrolle-Sur-Choisille' },
  { label: 'La Membrolle-Sur-Longuenée', value: 'La Membrolle-Sur-Longuenée' },
  { label: 'La Mendieta', value: 'La Mendieta' },
  { label: 'La Ménitré', value: 'La Ménitré' },
  { label: 'La Merced', value: 'La Merced' },
  { label: 'La Merced', value: 'La Merced' },
  { label: 'La Mesa', value: 'La Mesa' },
  { label: 'La Mesa', value: 'La Mesa' },
  { label: 'La Mesa', value: 'La Mesa' },
  { label: 'La Mesa', value: 'La Mesa' },
  { label: 'La Mesa', value: 'La Mesa' },
  { label: 'La Mesa', value: 'La Mesa' },
  { label: 'La Mesa', value: 'La Mesa' },
  { label: 'La Mesa', value: 'La Mesa' },
  { label: 'La Mesa', value: 'La Mesa' },
  { label: 'La Mesa (La Mesa De Cedano)', value: 'La Mesa (La Mesa De Cedano)' },
  { label: 'La Mesa De Berrones', value: 'La Mesa De Berrones' },
  { label: 'La Mesa De Chosto', value: 'La Mesa De Chosto' },
  { label: 'La Mesa De Los Conejos', value: 'La Mesa De Los Conejos' },
  { label: 'La Mesa Del Guitarrero', value: 'La Mesa Del Guitarrero' },
  { label: 'La Mesilla', value: 'La Mesilla' },
  { label: 'La Mesilla', value: 'La Mesilla' },
  { label: 'La Mesita', value: 'La Mesita' },
  { label: 'La Mezcalilla', value: 'La Mezcalilla' },
  { label: 'La Mézière', value: 'La Mézière' },
  { label: 'La Mezquitera', value: 'La Mezquitera' },
  { label: 'La Mial', value: 'La Mial' },
  { label: 'La Miel', value: 'La Miel' },
  { label: 'La Milesse', value: 'La Milesse' },
  { label: 'La Millère', value: 'La Millère' },
  { label: 'La Mina', value: 'La Mina' },
  { label: 'La Mina', value: 'La Mina' },
  { label: 'La Mina', value: 'La Mina' },
  { label: 'La Minerve', value: 'La Minerve' },
  { label: 'La Mintzita (Piedra Dura)', value: 'La Mintzita (Piedra Dura)' },
  { label: 'La Mira', value: 'La Mira' },
  { label: 'La Mira Tumbiscatio', value: 'La Mira Tumbiscatio' },
  { label: 'La Mirada', value: 'La Mirada' },
  { label: 'La Misión', value: 'La Misión' },
  { label: 'La Mitra', value: 'La Mitra' },
  { label: 'La Mohammedia', value: 'La Mohammedia' },
  { label: 'La Mohonera', value: 'La Mohonera' },
  { label: 'La Mojarra', value: 'La Mojarra' },
  { label: 'La Mojonera', value: 'La Mojonera' },
  { label: 'La Moncada', value: 'La Moncada' },
  { label: 'La Monja', value: 'La Monja' },
  { label: 'La Monnerie-Le-Montel', value: 'La Monnerie-Le-Montel' },
  { label: 'La Montagne', value: 'La Montagne' },
  { label: 'La Montañita', value: 'La Montañita' },
  { label: 'La Montañuela', value: 'La Montañuela' },
  { label: 'La Monte', value: 'La Monte' },
  { label: 'La Mora', value: 'La Mora' },
  { label: 'La Morra', value: 'La Morra' },
  { label: 'La Mota', value: 'La Mota' },
  { label: 'La Mothe-Achard', value: 'La Mothe-Achard' },
  { label: 'La Mothe-Saint-Héray', value: 'La Mothe-Saint-Héray' },
  { label: 'La Motte', value: 'La Motte' },
  { label: 'La Motte', value: 'La Motte' },
  { label: 'La Motte-D’Aveillans', value: 'La Motte-D’Aveillans' },
  { label: 'La Motte-Saint-Jean', value: 'La Motte-Saint-Jean' },
  { label: 'La Motte-Saint-Martin', value: 'La Motte-Saint-Martin' },
  { label: 'La Motte-Servolex', value: 'La Motte-Servolex' },
  { label: 'La Mulatière', value: 'La Mulatière' },
  { label: 'La Mure', value: 'La Mure' },
  { label: 'La Murette', value: 'La Murette' },
  { label: 'La Nance', value: 'La Nance' },
  { label: 'La Negreta', value: 'La Negreta' },
  { label: 'La Neuveville', value: 'La Neuveville' },
  { label: 'La Nopalera', value: 'La Nopalera' },
  { label: 'La Noria', value: 'La Noria' },
  { label: 'La Noria', value: 'La Noria' },
  { label: 'La Noria', value: 'La Noria' },
  { label: 'La Noria', value: 'La Noria' },
  { label: 'La Noria De San Antonio', value: 'La Noria De San Antonio' },
  { label: 'La Norita', value: 'La Norita' },
  { label: 'La Norville', value: 'La Norville' },
  { label: 'La Nucia', value: 'La Nucia' },
  { label: 'La Nueva Era', value: 'La Nueva Era' },
  { label: 'La Nueva Victoria', value: 'La Nueva Victoria' },
  { label: 'La Oliva', value: 'La Oliva' },
  { label: 'La Ordeña', value: 'La Ordeña' },
  { label: 'La Orduña', value: 'La Orduña' },
  { label: 'La Orilla', value: 'La Orilla' },
  { label: 'La Orilla De La Cerca', value: 'La Orilla De La Cerca' },
  { label: 'La Orilla Del Agua', value: 'La Orilla Del Agua' },
  { label: 'La Orotava', value: 'La Orotava' },
  { label: 'La Oroya', value: 'La Oroya' },
  { label: 'La Ortiga', value: 'La Ortiga' },
  { label: 'La Pacaudière', value: 'La Pacaudière' },
  { label: 'La Page', value: 'La Page' },
  { label: 'La Pahua', value: 'La Pahua' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma', value: 'La Palma' },
  { label: 'La Palma (Las Palmas)', value: 'La Palma (Las Palmas)' },
  { label: 'La Palme', value: 'La Palme' },
  { label: 'La Palmilla', value: 'La Palmilla' },
  { label: 'La Palmita', value: 'La Palmita' },
  { label: 'La Palmita (La Palmita De San Gabriel)', value: 'La Palmita (La Palmita De San Gabriel)' },
  { label: 'La Palmita Nuevo Leon', value: 'La Palmita Nuevo Leon' },
  { label: 'La Palmita Y Anexos (La Presita)', value: 'La Palmita Y Anexos (La Presita)' },
  { label: 'La Paloma', value: 'La Paloma' },
  { label: 'La Paloma', value: 'La Paloma' },
  { label: 'La Paloma', value: 'La Paloma' },
  { label: 'La Paloma', value: 'La Paloma' },
  { label: 'La Panadera', value: 'La Panadera' },
  { label: 'La Panouse', value: 'La Panouse' },
  { label: 'La Pansee', value: 'La Pansee' },
  { label: 'La Para', value: 'La Para' },
  { label: 'La Parguera', value: 'La Parguera' },
  { label: 'La Parota', value: 'La Parota' },
  { label: 'La Parrita', value: 'La Parrita' },
  { label: 'La Partida', value: 'La Partida' },
  { label: 'La Pasera', value: 'La Pasera' },
  { label: 'La Pastoría', value: 'La Pastoría' },
  { label: 'La Patria', value: 'La Patria' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz', value: 'La Paz' },
  { label: 'La Paz Centro', value: 'La Paz Centro' },
  { label: 'La Paz County', value: 'La Paz County' },
  { label: 'La Paz De Carazo', value: 'La Paz De Carazo' },
  { label: 'La Paz De Ordaz', value: 'La Paz De Ordaz' },
  { label: 'La Pe', value: 'La Pe' },
  { label: 'La Pearle', value: 'La Pearle' },
  { label: 'La Peca', value: 'La Peca' },
  { label: 'La Pedraja De Portillo', value: 'La Pedraja De Portillo' },
  { label: 'La Pedrera', value: 'La Pedrera' },
  { label: 'La Pedrera', value: 'La Pedrera' },
  { label: 'La Pelle', value: 'La Pelle' },
  { label: 'La Peña', value: 'La Peña' },
  { label: 'La Peña', value: 'La Peña' },
  { label: 'La Peña Jalisco', value: 'La Peña Jalisco' },
  { label: 'La Pendencia', value: 'La Pendencia' },
  { label: 'La Península', value: 'La Península' },
  { label: 'La Peñita De Jaltomba', value: 'La Peñita De Jaltomba' },
  { label: 'La Penne-Sur-Huveaune', value: 'La Penne-Sur-Huveaune' },
  { label: 'La Perla', value: 'La Perla' },
  { label: 'La Perla', value: 'La Perla' },
  { label: 'La Perle', value: 'La Perle' },
  { label: 'La Pesa', value: 'La Pesa' },
  { label: 'La Pesca', value: 'La Pesca' },
  { label: 'La Peyratte', value: 'La Peyratte' },
  { label: 'La Piedad', value: 'La Piedad' },
  { label: 'La Piedad', value: 'La Piedad' },
  { label: 'La Pieve-Molino', value: 'La Pieve-Molino' },
  { label: 'La Pila', value: 'La Pila' },
  { label: 'La Pimienta', value: 'La Pimienta' },
  { label: 'La Pine', value: 'La Pine' },
  { label: 'La Pinta', value: 'La Pinta' },
  { label: 'La Pintada', value: 'La Pintada' },
  { label: 'La Pintada', value: 'La Pintada' },
  { label: 'La Pintana', value: 'La Pintana' },
  { label: 'La Place', value: 'La Place' },
  { label: 'La Placita De Morelos', value: 'La Placita De Morelos' },
  { label: 'La Plaine', value: 'La Plaine' },
  { label: 'La Plaine-Sur-Mer', value: 'La Plaine-Sur-Mer' },
  { label: 'La Planada', value: 'La Planada' },
  { label: 'La Planada (El Arenal)', value: 'La Planada (El Arenal)' },
  { label: 'La Planche', value: 'La Planche' },
  { label: 'La Plata', value: 'La Plata' },
  { label: 'La Plata', value: 'La Plata' },
  { label: 'La Plata', value: 'La Plata' },
  { label: 'La Plata County', value: 'La Plata County' },
  { label: 'La Playa', value: 'La Playa' },
  { label: 'La Playa', value: 'La Playa' },
  { label: 'La Playa', value: 'La Playa' },
  { label: 'La Playa', value: 'La Playa' },
  { label: 'La Playona', value: 'La Playona' },
  { label: 'La Playosa', value: 'La Playosa' },
  { label: 'La Plaza Del Limón', value: 'La Plaza Del Limón' },
  { label: 'La Plena', value: 'La Plena' },
  { label: 'La Pobla De Farnals', value: 'La Pobla De Farnals' },
  { label: 'La Pobla De Vallbona', value: 'La Pobla De Vallbona' },
  { label: 'La Pobla Llarga', value: 'La Pobla Llarga' },
  { label: 'La Pocatière', value: 'La Pocatière' },
  { label: 'La Pointe', value: 'La Pointe' },
  { label: 'La Pointe', value: 'La Pointe' },
  { label: 'La Pointe', value: 'La Pointe' },
  { label: 'La Pointe Rouge', value: 'La Pointe Rouge' },
  { label: 'La Pola De Gordón', value: 'La Pola De Gordón' },
  { label: 'La Pomme', value: 'La Pomme' },
  { label: 'La Pommeraie-Sur-Sèvre', value: 'La Pommeraie-Sur-Sèvre' },
  { label: 'La Pommeraye', value: 'La Pommeraye' },
  { label: 'La Popular', value: 'La Popular' },
  { label: 'La Porte', value: 'La Porte' },
  { label: 'La Porte', value: 'La Porte' },
  { label: 'La Porte City', value: 'La Porte City' },
  { label: 'La Possonnière', value: 'La Possonnière' },
  { label: 'La Pouëze', value: 'La Pouëze' },
  { label: 'La Pradera', value: 'La Pradera' },
  { label: 'La Prairie', value: 'La Prairie' },
  { label: 'La Presa', value: 'La Presa' },
  { label: 'La Presa', value: 'La Presa' },
  { label: 'La Presa', value: 'La Presa' },
  { label: 'La Presita', value: 'La Presita' },
  { label: 'La Presita De Berlanga', value: 'La Presita De Berlanga' },
  { label: 'La Presita Segundo Cuartel', value: 'La Presita Segundo Cuartel' },
  { label: 'La Presumida', value: 'La Presumida' },
  { label: 'La Primavera', value: 'La Primavera' },
  { label: 'La Primavera', value: 'La Primavera' },
  { label: 'La Providencia', value: 'La Providencia' },
  { label: 'La Providencia', value: 'La Providencia' },
  { label: 'La Providencia', value: 'La Providencia' },
  { label: 'La Providencia', value: 'La Providencia' },
  { label: 'La Providencia', value: 'La Providencia' },
  { label: 'La Providencia', value: 'La Providencia' },
  { label: 'La Providencia Siglo Xxi', value: 'La Providencia Siglo Xxi' },
  { label: 'La Pryor', value: 'La Pryor' },
  { label: 'La Puebla', value: 'La Puebla' },
  { label: 'La Puente', value: 'La Puente' },
  { label: 'La Puerta De San José', value: 'La Puerta De San José' },
  { label: 'La Puerta De San Pedro', value: 'La Puerta De San Pedro' },
  { label: 'La Puerta Del Coche', value: 'La Puerta Del Coche' },
  { label: 'La Puerta Del Pilar', value: 'La Puerta Del Pilar' },
  { label: 'La Punt Chamues-Ch', value: 'La Punt Chamues-Ch' },
  { label: 'La Punta', value: 'La Punta' },
  { label: 'La Punta', value: 'La Punta' },
  { label: 'La Punta', value: 'La Punta' },
  { label: 'La Purificación', value: 'La Purificación' },
  { label: 'La Purísima', value: 'La Purísima' },
  { label: 'La Purísima', value: 'La Purísima' },
  { label: 'La Purísima', value: 'La Purísima' },
  { label: 'La Purísima', value: 'La Purísima' },
  { label: 'La Quemada', value: 'La Quemada' },
  { label: 'La Queue-En-Brie', value: 'La Queue-En-Brie' },
  { label: 'La Queue-Les-Yvelines', value: 'La Queue-Les-Yvelines' },
  { label: 'La Quiaca', value: 'La Quiaca' },
  { label: 'La Quinta', value: 'La Quinta' },
  { label: 'La Rana', value: 'La Rana' },
  { label: 'La Ravoire', value: 'La Ravoire' },
  { label: 'La Raya', value: 'La Raya' },
  { label: 'La Raya De Calobre', value: 'La Raya De Calobre' },
  { label: 'La Raya De Santa María', value: 'La Raya De Santa María' },
  { label: 'La Red', value: 'La Red' },
  { label: 'La Reforma', value: 'La Reforma' },
  { label: 'La Reforma', value: 'La Reforma' },
  { label: 'La Reforma', value: 'La Reforma' },
  { label: 'La Reforma', value: 'La Reforma' },
  { label: 'La Reforma', value: 'La Reforma' },
  { label: 'La Reforma', value: 'La Reforma' },
  { label: 'La Reforma', value: 'La Reforma' },
  { label: 'La Regrippière', value: 'La Regrippière' },
  { label: 'La Reina', value: 'La Reina' },
  { label: 'La Remaudière', value: 'La Remaudière' },
  { label: 'La Remuée', value: 'La Remuée' },
  { label: 'La Réole', value: 'La Réole' },
  { label: 'La Resolana', value: 'La Resolana' },
  { label: 'La Resource', value: 'La Resource' },
  { label: 'La Ressource', value: 'La Ressource' },
  { label: 'La Resurrección', value: 'La Resurrección' },
  { label: 'La Retraite', value: 'La Retraite' },
  { label: 'La Ribera', value: 'La Ribera' },
  { label: 'La Ricamarie', value: 'La Ricamarie' },
  { label: 'La Richardais', value: 'La Richardais' },
  { label: 'La Riche', value: 'La Riche' },
  { label: 'La Rinconada', value: 'La Rinconada' },
  { label: 'La Rinconada', value: 'La Rinconada' },
  { label: 'La Rinconoda', value: 'La Rinconoda' },
  { label: 'La Rioja', value: 'La Rioja' },
  { label: 'La Rivera', value: 'La Rivera' },
  { label: 'La Riviera', value: 'La Riviera' },
  { label: 'La Rivière-De-Corps', value: 'La Rivière-De-Corps' },
  { label: 'La Rivière-Saint-Sauveur', value: 'La Rivière-Saint-Sauveur' },
  { label: 'La Robla', value: 'La Robla' },
  { label: 'La Roca', value: 'La Roca' },
  { label: 'La Roca Dalbera', value: 'La Roca Dalbera' },
  { label: 'La Roche', value: 'La Roche' },
  { label: 'La Roche-Blanche', value: 'La Roche-Blanche' },
  { label: 'La Roche-Chalais', value: 'La Roche-Chalais' },
  { label: 'La Roche-De-Glun', value: 'La Roche-De-Glun' },
  { label: 'La Roche-Derrien', value: 'La Roche-Derrien' },
  { label: 'La Roche-Des-Arnauds', value: 'La Roche-Des-Arnauds' },
  { label: 'La Roche-En-Ardenne', value: 'La Roche-En-Ardenne' },
  { label: 'La Rochefoucauld', value: 'La Rochefoucauld' },
  { label: 'La Rochelle', value: 'La Rochelle' },
  { label: 'La Roche-Maurice', value: 'La Roche-Maurice' },
  { label: 'La Roche-Posay', value: 'La Roche-Posay' },
  { label: 'La Roche-Sur-Foron', value: 'La Roche-Sur-Foron' },
  { label: 'La Roche-Sur-Yon', value: 'La Roche-Sur-Yon' },
  { label: 'La Rochette', value: 'La Rochette' },
  { label: 'La Rochette', value: 'La Rochette' },
  { label: 'La Roche-Vineuse', value: 'La Roche-Vineuse' },
  { label: 'La Romagne', value: 'La Romagne' },
  { label: 'La Romana', value: 'La Romana' },
  { label: 'La Romana', value: 'La Romana' },
  { label: 'La Ronge', value: 'La Ronge' },
  { label: 'La Roquebrussanne', value: 'La Roquebrussanne' },
  { label: 'La Roque-D’Anthéron', value: 'La Roque-D’Anthéron' },
  { label: 'La Roquette-Sur-Siagne', value: 'La Roquette-Sur-Siagne' },
  { label: 'La Rosa', value: 'La Rosa' },
  { label: 'La Rosa', value: 'La Rosa' },
  { label: 'La Rosa', value: 'La Rosa' },
  { label: 'La Rosa', value: 'La Rosa' },
  { label: 'La Rosa Amarilla', value: 'La Rosa Amarilla' },
  { label: 'La Rosa De Castilla', value: 'La Rosa De Castilla' },
  { label: 'La Rose', value: 'La Rose' },
  { label: 'La Rotta', value: 'La Rotta' },
  { label: 'La Roxas', value: 'La Roxas' },
  { label: 'La Rueda', value: 'La Rueda' },
  { label: 'La Sabana', value: 'La Sabana' },
  { label: 'La Sabana', value: 'La Sabana' },
  { label: 'La Sábana', value: 'La Sábana' },
  { label: 'La Sabinita', value: 'La Sabinita' },
  { label: 'La Sala (La Caliente)', value: 'La Sala (La Caliente)' },
  { label: 'La Saletta-Tamara', value: 'La Saletta-Tamara' },
  { label: 'La Salina', value: 'La Salina' },
  { label: 'La Salle', value: 'La Salle' },
  { label: 'La Salle', value: 'La Salle' },
  { label: 'La Salle', value: 'La Salle' },
  { label: 'La Salle County', value: 'La Salle County' },
  { label: 'La Salle Parish', value: 'La Salle Parish' },
  { label: 'La Salle-Et-Chapelle-Aubry', value: 'La Salle-Et-Chapelle-Aubry' },
  { label: 'La Salud', value: 'La Salud' },
  { label: 'La Salute Di Livenza', value: 'La Salute Di Livenza' },
  { label: 'La Salvetat-Peyralès', value: 'La Salvetat-Peyralès' },
  { label: 'La Salvetat-Saint-Gilles', value: 'La Salvetat-Saint-Gilles' },
  { label: 'La Salvetat-Sur-Agout', value: 'La Salvetat-Sur-Agout' },
  { label: 'La Sanguijuela', value: 'La Sanguijuela' },
  { label: 'La Sardina', value: 'La Sardina' },
  { label: 'La Sarraz', value: 'La Sarraz' },
  { label: 'La Sarre', value: 'La Sarre' },
  { label: 'La Sarrosa', value: 'La Sarrosa' },
  { label: 'La Sauceda', value: 'La Sauceda' },
  { label: 'La Sauceda', value: 'La Sauceda' },
  { label: 'La Sauceda', value: 'La Sauceda' },
  { label: 'La Saussaye', value: 'La Saussaye' },
  { label: 'La Sauve', value: 'La Sauve' },
  { label: 'La Séauve-Sur-Semène', value: 'La Séauve-Sur-Semène' },
  { label: 'La Seca', value: 'La Seca' },
  { label: 'La Séguinière', value: 'La Séguinière' },
  { label: 'La Selle-La-Forge', value: 'La Selle-La-Forge' },
  { label: 'La Selva', value: 'La Selva' },
  { label: 'La Selva Beach', value: 'La Selva Beach' },
  { label: 'La Sentinelle', value: 'La Sentinelle' },
  { label: 'La Serena', value: 'La Serena' },
  { label: 'La Seyne-Sur-Mer', value: 'La Seyne-Sur-Mer' },
  { label: 'La Sidra', value: 'La Sidra' },
  { label: 'La Sierpe', value: 'La Sierpe' },
  { label: 'La Sierra', value: 'La Sierra' },
  { label: 'La Solana', value: 'La Solana' },
  { label: 'La Soledad', value: 'La Soledad' },
  { label: 'La Soledad', value: 'La Soledad' },
  { label: 'La Soledad', value: 'La Soledad' },
  { label: 'La Soledad', value: 'La Soledad' },
  { label: 'La Soledad', value: 'La Soledad' },
  { label: 'La Soledad', value: 'La Soledad' },
  { label: 'La Soledad', value: 'La Soledad' },
  { label: 'La Soledad', value: 'La Soledad' },
  { label: 'La Soledad Barrio', value: 'La Soledad Barrio' },
  { label: 'La Sombra', value: 'La Sombra' },
  { label: 'La Soukra', value: 'La Soukra' },
  { label: 'La Souterraine', value: 'La Souterraine' },
  { label: 'La Spezia', value: 'La Spezia' },
  { label: 'La Subida', value: 'La Subida' },
  { label: 'La Suiza', value: 'La Suiza' },
  { label: 'La Suze-Sur-Sarthe', value: 'La Suze-Sur-Sarthe' },
  { label: 'La Tabaquera', value: 'La Tabaquera' },
  { label: 'La Talaudière', value: 'La Talaudière' },
  { label: 'La Tardière', value: 'La Tardière' },
  { label: 'La Tebaida', value: 'La Tebaida' },
  { label: 'La Teja', value: 'La Teja' },
  { label: 'La Teja', value: 'La Teja' },
  { label: 'La Terrasse', value: 'La Terrasse' },
  { label: 'La Tesorera', value: 'La Tesorera' },
  { label: 'La Tessoualle', value: 'La Tessoualle' },
  { label: 'La Teste-De-Buch', value: 'La Teste-De-Buch' },
  { label: 'La Thuile', value: 'La Thuile' },
  { label: 'La Tigra', value: 'La Tigra' },
  { label: 'La Tigrilla', value: 'La Tigrilla' },
  { label: 'La Tijera', value: 'La Tijera' },
  { label: 'La Tille', value: 'La Tille' },
  { label: 'La Timone', value: 'La Timone' },
  { label: 'La Tinaja', value: 'La Tinaja' },
  { label: 'La Tinaja', value: 'La Tinaja' },
  { label: 'La Tinaja', value: 'La Tinaja' },
  { label: 'La Tinajera', value: 'La Tinajera' },
  { label: 'La Tinta', value: 'La Tinta' },
  { label: 'La Tiricia', value: 'La Tiricia' },
  { label: 'La Tiza', value: 'La Tiza' },
  { label: 'La Toc', value: 'La Toc' },
  { label: 'La Tola', value: 'La Tola' },
  { label: 'La Toma', value: 'La Toma' },
  { label: 'La Toma', value: 'La Toma' },
  { label: 'La Torrecilla', value: 'La Torrecilla' },
  { label: 'La Tortuga', value: 'La Tortuga' },
  { label: 'La Tortuga', value: 'La Tortuga' },
  { label: 'La Tour', value: 'La Tour' },
  { label: 'La Tour-De-Peilz', value: 'La Tour-De-Peilz' },
  { label: 'La Tour-De-Salvagny', value: 'La Tour-De-Salvagny' },
  { label: 'La Tour-De-Trême', value: 'La Tour-De-Trême' },
  { label: 'La Tour-Du-Crieu', value: 'La Tour-Du-Crieu' },
  { label: 'La Tour-Du-Pin', value: 'La Tour-Du-Pin' },
  { label: 'La Tour-En-Jarez', value: 'La Tour-En-Jarez' },
  { label: 'La Tourlandry', value: 'La Tourlandry' },
  { label: 'La Tourney/Cedar Heights', value: 'La Tourney/Cedar Heights' },
  { label: 'La Tour-Sur-Orb', value: 'La Tour-Sur-Orb' },
  { label: 'La Tranche-Sur-Mer', value: 'La Tranche-Sur-Mer' },
  { label: 'La Tremblade', value: 'La Tremblade' },
  { label: 'La Trielle', value: 'La Trielle' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad', value: 'La Trinidad' },
  { label: 'La Trinidad Chautenco', value: 'La Trinidad Chautenco' },
  { label: 'La Trinidad Chimalpa', value: 'La Trinidad Chimalpa' },
  { label: 'La Trinidad Tenexyecac', value: 'La Trinidad Tenexyecac' },
  { label: 'La Trinidad Tianguismanalco', value: 'La Trinidad Tianguismanalco' },
  { label: 'La Trinitaria', value: 'La Trinitaria' },
  { label: 'La Trinité', value: 'La Trinité' },
  { label: 'La Trinité-Sur-Mer', value: 'La Trinité-Sur-Mer' },
  { label: 'La Troncal', value: 'La Troncal' },
  { label: 'La Tronche', value: 'La Tronche' },
  { label: 'La Tronosa', value: 'La Tronosa' },
  { label: 'La Tuque', value: 'La Tuque' },
  { label: 'La Turballe', value: 'La Turballe' },
  { label: 'La Turbie', value: 'La Turbie' },
  { label: 'La Unidad Huitzizilapan', value: 'La Unidad Huitzizilapan' },
  { label: 'La Union', value: 'La Union' },
  { label: 'La Union', value: 'La Union' },
  { label: 'La Union', value: 'La Union' },
  { label: 'La Union', value: 'La Union' },
  { label: 'La Union', value: 'La Union' },
  { label: 'La Union', value: 'La Union' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión', value: 'La Unión' },
  { label: 'La Unión Del Cuatro', value: 'La Unión Del Cuatro' },
  { label: 'La Unión Ejido Mexcaltepec', value: 'La Unión Ejido Mexcaltepec' },
  { label: 'La Unión Paso Largo', value: 'La Unión Paso Largo' },
  { label: 'La Unión Y El Cardonal', value: 'La Unión Y El Cardonal' },
  { label: 'La Uvilla', value: 'La Uvilla' },
  { label: 'La Uvita', value: 'La Uvita' },
  { label: 'La Valbarelle', value: 'La Valbarelle' },
  { label: 'La Vale', value: 'La Vale' },
  { label: 'La Valentine', value: 'La Valentine' },
  { label: 'La Valette-Du-Var', value: 'La Valette-Du-Var' },
  { label: 'La Valla', value: 'La Valla' },
  { label: 'La Valle - Wengen', value: 'La Valle - Wengen' },
  { label: 'La Valle Agordina', value: 'La Valle Agordina' },
  { label: 'La Vaquera', value: 'La Vaquera' },
  { label: 'La Varenne', value: 'La Varenne' },
  { label: 'La Vaupalière', value: 'La Vaupalière' },
  { label: 'La Vega', value: 'La Vega' },
  { label: 'La Vega', value: 'La Vega' },
  { label: 'La Vega', value: 'La Vega' },
  { label: 'La Venta', value: 'La Venta' },
  { label: 'La Venta', value: 'La Venta' },
  { label: 'La Venta Del Astillero', value: 'La Venta Del Astillero' },
  { label: 'La Ventana', value: 'La Ventana' },
  { label: 'La Ventilla', value: 'La Ventilla' },
  { label: 'La Ventosa', value: 'La Ventosa' },
  { label: 'La Verde', value: 'La Verde' },
  { label: 'La Verdolaga', value: 'La Verdolaga' },
  { label: 'La Vergne', value: 'La Vergne' },
  { label: 'La Verne', value: 'La Verne' },
  { label: 'La Vernia', value: 'La Vernia' },
  { label: 'La Verpillière', value: 'La Verpillière' },
  { label: 'La Verrie', value: 'La Verrie' },
  { label: 'La Verrière', value: 'La Verrière' },
  { label: 'La Versolilla', value: 'La Versolilla' },
  { label: 'La Vespière', value: 'La Vespière' },
  { label: 'La Victoria', value: 'La Victoria' },
  { label: 'La Victoria', value: 'La Victoria' },
  { label: 'La Victoria', value: 'La Victoria' },
  { label: 'La Victoria', value: 'La Victoria' },
  { label: 'La Victoria', value: 'La Victoria' },
  { label: 'La Victoria', value: 'La Victoria' },
  { label: 'La Victoria', value: 'La Victoria' },
  { label: 'La Victoria', value: 'La Victoria' },
  { label: 'La Victoria (La Peñita)', value: 'La Victoria (La Peñita)' },
  { label: 'La Victoria De Acentejo', value: 'La Victoria De Acentejo' },
  { label: 'La Villa', value: 'La Villa' },
  { label: 'La Villa', value: 'La Villa' },
  { label: 'La Villa', value: 'La Villa' },
  { label: 'La Villa Del Rosario', value: 'La Villa Del Rosario' },
  { label: 'La Ville-Aux-Clercs', value: 'La Ville-Aux-Clercs' },
  { label: 'La Ville-Aux-Dames', value: 'La Ville-Aux-Dames' },
  { label: 'La Villedieu-Du-Clain', value: 'La Villedieu-Du-Clain' },
  { label: 'La Ville-Du-Bois', value: 'La Ville-Du-Bois' },
  { label: 'La Villette', value: 'La Villette' },
  { label: 'La Virgencita', value: 'La Virgencita' },
  { label: 'La Virginia', value: 'La Virginia' },
  { label: 'La Virtud', value: 'La Virtud' },
  { label: 'La Vista', value: 'La Vista' },
  { label: 'La Viste', value: 'La Viste' },
  { label: 'La Voulte-Sur-Rhône', value: 'La Voulte-Sur-Rhône' },
  { label: 'La Vraie-Croix', value: 'La Vraie-Croix' },
  { label: 'La Wantzenau', value: 'La Wantzenau' },
  { label: 'La Y', value: 'La Y' },
  { label: 'La Yeguada', value: 'La Yeguada' },
  { label: 'La Yerbabuena', value: 'La Yerbabuena' },
  { label: 'La Yesa', value: 'La Yesa' },
  { label: 'La Yesca', value: 'La Yesca' },
  { label: 'La Zacatecana', value: 'La Zacatecana' },
  { label: 'La Zapotera', value: 'La Zapotera' },
  { label: 'La Zumbadora', value: 'La Zumbadora' },
  { label: 'Lā‘Ie', value: 'Lā‘Ie' },
  { label: 'Laa An Der Thaya', value: 'Laa An Der Thaya' },
  { label: 'Laab', value: 'Laab' },
  { label: 'Laab Im Walde', value: 'Laab Im Walde' },
  { label: 'Laage', value: 'Laage' },
  { label: 'Laagri', value: 'Laagri' },
  { label: 'Laahen', value: 'Laahen' },
  { label: 'Laakirchen', value: 'Laakirchen' },
  { label: 'Lääne-Nigula Vald', value: 'Lääne-Nigula Vald' },
  { label: 'Laar', value: 'Laar' },
  { label: 'Laarne', value: 'Laarne' },
  { label: 'Laatzen', value: 'Laatzen' },
  { label: 'Laax', value: 'Laax' },
  { label: 'Laayoune', value: 'Laayoune' },
  { label: 'Labadieville', value: 'Labadieville' },
  { label: 'Labajos', value: 'Labajos' },
  { label: 'Labangan', value: 'Labangan' },
  { label: 'Labaroche', value: 'Labaroche' },
  { label: 'Labarque Creek', value: 'Labarque Creek' },
  { label: 'Labarthe-Rivière', value: 'Labarthe-Rivière' },
  { label: 'Labarthe-Sur-Lèze', value: 'Labarthe-Sur-Lèze' },
  { label: 'Labasa', value: 'Labasa' },
  { label: 'Labasan', value: 'Labasan' },
  { label: 'Labason', value: 'Labason' },
  { label: 'Labastida/Bastida', value: 'Labastida/Bastida' },
  { label: 'Labastide-Beauvoir', value: 'Labastide-Beauvoir' },
  { label: 'Labastide-Rouairoux', value: 'Labastide-Rouairoux' },
  { label: 'Labastide-Saint-Georges', value: 'Labastide-Saint-Georges' },
  { label: 'Labastide-Saint-Pierre', value: 'Labastide-Saint-Pierre' },
  { label: 'Labastide-Saint-Sernin', value: 'Labastide-Saint-Sernin' },
  { label: 'Labastidette', value: 'Labastidette' },
  { label: 'Labateca', value: 'Labateca' },
  { label: 'Lábatlan', value: 'Lábatlan' },
  { label: 'Labatut', value: 'Labatut' },
  { label: 'Labayee', value: 'Labayee' },
  { label: 'Labayug', value: 'Labayug' },
  { label: 'Labayug', value: 'Labayug' },
  { label: 'Labé', value: 'Labé' },
  { label: 'Labe Prefecture', value: 'Labe Prefecture' },
  { label: 'Labège', value: 'Labège' },
  { label: 'Labégude', value: 'Labégude' },
  { label: 'Labelle', value: 'Labelle' },
  { label: 'Labelle', value: 'Labelle' },
  { label: 'Labenne', value: 'Labenne' },
  { label: 'Laberweinting', value: 'Laberweinting' },
  { label: 'Labette County', value: 'Labette County' },
  { label: 'Labeuvrière', value: 'Labeuvrière' },
  { label: 'Labico', value: 'Labico' },
  { label: 'Labin', value: 'Labin' },
  { label: 'Labinsk', value: 'Labinsk' },
  { label: 'Labis', value: 'Labis' },
  { label: 'Łabiszyn', value: 'Łabiszyn' },
  { label: 'Lablachère', value: 'Lablachère' },
  { label: 'Labney', value: 'Labney' },
  { label: 'Labney', value: 'Labney' },
  { label: 'Labnig', value: 'Labnig' },
  { label: 'Labo', value: 'Labo' },
  { label: 'Lábod', value: 'Lábod' },
  { label: 'Laboe', value: 'Laboe' },
  { label: 'Labog', value: 'Labog' },
  { label: 'Laboissière-En-Thelle', value: 'Laboissière-En-Thelle' },
  { label: 'Labor De Medina', value: 'Labor De Medina' },
  { label: 'Labor De Montoya', value: 'Labor De Montoya' },
  { label: 'Labor De Peralta', value: 'Labor De Peralta' },
  { label: 'Laborde', value: 'Laborde' },
  { label: 'Labores Las', value: 'Labores Las' },
  { label: 'Laborie', value: 'Laborie' },
  { label: 'Labouheyre', value: 'Labouheyre' },
  { label: 'Laboulaye', value: 'Laboulaye' },
  { label: 'Labourse', value: 'Labourse' },
  { label: 'Łabowa', value: 'Łabowa' },
  { label: 'Labrador', value: 'Labrador' },
  { label: 'Labrador', value: 'Labrador' },
  { label: 'Labrador', value: 'Labrador' },
  { label: 'Labrador City', value: 'Labrador City' },
  { label: 'Labranzagrande', value: 'Labranzagrande' },
  { label: 'Lábrea', value: 'Lábrea' },
  { label: 'Labro', value: 'Labro' },
  { label: 'Labruguière', value: 'Labruguière' },
  { label: 'Labry', value: 'Labry' },
  { label: 'Lab-Sar', value: 'Lab-Sar' },
  { label: 'Labuan', value: 'Labuan' },
  { label: 'Labuan', value: 'Labuan' },
  { label: 'Labuan', value: 'Labuan' },
  { label: 'Labuan Bajo', value: 'Labuan Bajo' },
  { label: 'Labuan Lombok', value: 'Labuan Lombok' },
  { label: 'Labuch', value: 'Labuch' },
  { label: 'Labuerda', value: 'Labuerda' },
  { label: 'Labuhan Deli', value: 'Labuhan Deli' },
  { label: 'Labuñgan', value: 'Labuñgan' },
  { label: 'Labuñgan', value: 'Labuñgan' },
  { label: 'Łabunie', value: 'Łabunie' },
  { label: 'Labunista', value: 'Labunista' },
  { label: 'Labu-O', value: 'Labu-O' },
  { label: 'Labu-O', value: 'Labu-O' },
  { label: 'Labyrinth', value: 'Labyrinth' },
  { label: 'Labytnangi', value: 'Labytnangi' },
  { label: 'Laç', value: 'Laç' },
  { label: 'Lac De La Haute-Sûre', value: 'Lac De La Haute-Sûre' },
  { label: 'Lac Du Bonnet', value: 'Lac Du Bonnet' },
  { label: 'Lac Du Flambeau', value: 'Lac Du Flambeau' },
  { label: 'Lac La Biche', value: 'Lac La Biche' },
  { label: 'Lac Qui Parle County', value: 'Lac Qui Parle County' },
  { label: 'Lac Wey', value: 'Lac Wey' },
  { label: 'Lacag', value: 'Lacag' },
  { label: 'Lac-Alouette', value: 'Lac-Alouette' },
  { label: 'Lacanau', value: 'Lacanau' },
  { label: 'Lacandón', value: 'Lacandón' },
  { label: 'Lacapan Camallagne', value: 'Lacapan Camallagne' },
  { label: 'Lacapelle-Marival', value: 'Lacapelle-Marival' },
  { label: 'Lacaron', value: 'Lacaron' },
  { label: 'Lacaron', value: 'Lacaron' },
  { label: 'Lacaron', value: 'Lacaron' },
  { label: 'Lacaron', value: 'Lacaron' },
  { label: 'Lacasse', value: 'Lacasse' },
  { label: 'Lacaune', value: 'Lacaune' },
  { label: 'Lac-Brome', value: 'Lac-Brome' },
  { label: 'Lacchiarella', value: 'Lacchiarella' },
  { label: 'Lacco Ameno', value: 'Lacco Ameno' },
  { label: 'Lac-Connelly', value: 'Lac-Connelly' },
  { label: 'Lacdayan', value: 'Lacdayan' },
  { label: 'Laceby', value: 'Laceby' },
  { label: 'Lacedonia', value: 'Lacedonia' },
  { label: 'Lăceni', value: 'Lăceni' },
  { label: 'Lacerdópolis', value: 'Lacerdópolis' },
  { label: 'Laces', value: 'Laces' },
  { label: 'Lacey', value: 'Lacey' },
  { label: 'Lachapelle-Aux-Pots', value: 'Lachapelle-Aux-Pots' },
  { label: 'Lachapelle-Sous-Aubenas', value: 'Lachapelle-Sous-Aubenas' },
  { label: 'Láchar', value: 'Láchar' },
  { label: 'Lachen', value: 'Lachen' },
  { label: 'Lachen', value: 'Lachen' },
  { label: 'Lachendorf', value: 'Lachendorf' },
  { label: 'Lachen-Speyerdorf', value: 'Lachen-Speyerdorf' },
  { label: 'Lachhmangarh Sikar', value: 'Lachhmangarh Sikar' },
  { label: 'Lachi', value: 'Lachi' },
  { label: 'Lachlan', value: 'Lachlan' },
  { label: 'Lachowice', value: 'Lachowice' },
  { label: 'Lachute', value: 'Lachute' },
  { label: 'Laçin', value: 'Laçin' },
  { label: 'Laçın', value: 'Laçın' },
  { label: 'Łąck', value: 'Łąck' },
  { label: 'Lackawanna', value: 'Lackawanna' },
  { label: 'Lackawanna County', value: 'Lackawanna County' },
  { label: 'Lackenbach', value: 'Lackenbach' },
  { label: 'Lackendorf', value: 'Lackendorf' },
  { label: 'Lackland Air Force Base', value: 'Lackland Air Force Base' },
  { label: 'Łącko', value: 'Łącko' },
  { label: 'Lac-Lapierre', value: 'Lac-Lapierre' },
  { label: 'Laclede County', value: 'Laclede County' },
  { label: 'Laclo', value: 'Laclo' },
  { label: 'Laclubar', value: 'Laclubar' },
  { label: 'Lacluta', value: 'Lacluta' },
  { label: 'Lac-Mégantic', value: 'Lac-Mégantic' },
  { label: 'Lacock', value: 'Lacock' },
  { label: 'Lacolle', value: 'Lacolle' },
  { label: 'Lacombe', value: 'Lacombe' },
  { label: 'Lacombe', value: 'Lacombe' },
  { label: 'Lacon', value: 'Lacon' },
  { label: 'Laconi', value: 'Laconi' },
  { label: 'Laconia', value: 'Laconia' },
  { label: 'Lacoochee', value: 'Lacoochee' },
  { label: 'Lacovia', value: 'Lacovia' },
  { label: 'Lacroix-Falgarde', value: 'Lacroix-Falgarde' },
  { label: 'Lacroix-Saint-Ouen', value: 'Lacroix-Saint-Ouen' },
  { label: 'Lacrouzette', value: 'Lacrouzette' },
  { label: 'Lac-Simon', value: 'Lac-Simon' },
  { label: 'Lacson', value: 'Lacson' },
  { label: 'Lacson', value: 'Lacson' },
  { label: 'Lacson', value: 'Lacson' },
  { label: 'Lacu Sărat', value: 'Lacu Sărat' },
  { label: 'Lacub', value: 'Lacub' },
  { label: 'Lacugnano', value: 'Lacugnano' },
  { label: 'Lăculețe', value: 'Lăculețe' },
  { label: 'Lăcusteni', value: 'Lăcusteni' },
  { label: 'Lăcusteni', value: 'Lăcusteni' },
  { label: 'Lacy-Lakeview', value: 'Lacy-Lakeview' },
  { label: 'Łączany', value: 'Łączany' },
  { label: 'Łączna', value: 'Łączna' },
  { label: 'Ladainha', value: 'Ladainha' },
  { label: 'Ladakh', value: 'Ladakh' },
  { label: 'Ladan', value: 'Ladan' },
  { label: 'Ladang Seri Kundang', value: 'Ladang Seri Kundang' },
  { label: 'Ladário', value: 'Ladário' },
  { label: 'Ladbergen', value: 'Ladbergen' },
  { label: 'Ladd', value: 'Ladd' },
  { label: 'Lądek', value: 'Lądek' },
  { label: 'Lądek-Zdrój', value: 'Lądek-Zdrój' },
  { label: 'Ladelund', value: 'Ladelund' },
  { label: 'Ladenburg', value: 'Ladenburg' },
  { label: 'Ladendorf', value: 'Ladendorf' },
  { label: 'Ladera', value: 'Ladera' },
  { label: 'Ladera Chica', value: 'Ladera Chica' },
  { label: 'Ladera Heights', value: 'Ladera Heights' },
  { label: 'Ladera Ranch', value: 'Ladera Ranch' },
  { label: 'Lădeşti', value: 'Lădeşti' },
  { label: 'Ladhewala Waraich', value: 'Ladhewala Waraich' },
  { label: 'Ladignac-Le-Long', value: 'Ladignac-Le-Long' },
  { label: 'Ladik', value: 'Ladik' },
  { label: 'Ladimirevci', value: 'Ladimirevci' },
  { label: 'Ladis', value: 'Ladis' },
  { label: 'Ladismith', value: 'Ladismith' },
  { label: 'Ladispoli', value: 'Ladispoli' },
  { label: 'Ladner', value: 'Ladner' },
  { label: 'Ladnun', value: 'Ladnun' },
  { label: 'Ladoix-Serrigny', value: 'Ladoix-Serrigny' },
  { label: 'Ladol', value: 'Ladol' },
  { label: 'Ladon', value: 'Ladon' },
  { label: 'Ladonia', value: 'Ladonia' },
  { label: 'Ladovskaya Balka', value: 'Ladovskaya Balka' },
  { label: 'Ladozhskaya', value: 'Ladozhskaya' },
  { label: 'Ladrillar', value: 'Ladrillar' },
  { label: 'Ladrillera', value: 'Ladrillera' },
  { label: 'Ladrillera (Entronque Pesquería)', value: 'Ladrillera (Entronque Pesquería)' },
  { label: 'Ladrilleras De Ocoro', value: 'Ladrilleras De Ocoro' },
  { label: 'Ladrilleras Del Refugio', value: 'Ladrilleras Del Refugio' },
  { label: 'Ladson', value: 'Ladson' },
  { label: 'Ladue', value: 'Ladue' },
  { label: 'Ladushkin', value: 'Ladushkin' },
  { label: 'Ladwa', value: 'Ladwa' },
  { label: 'Lady Frere', value: 'Lady Frere' },
  { label: 'Lady Lake', value: 'Lady Lake' },
  { label: 'Ladybank', value: 'Ladybank' },
  { label: 'Ladybrand', value: 'Ladybrand' },
  { label: 'Ladysmith', value: 'Ladysmith' },
  { label: 'Ladysmith', value: 'Ladysmith' },
  { label: 'Ladyzhyn', value: 'Ladyzhyn' },
  { label: 'Lae', value: 'Lae' },
  { label: 'Laela', value: 'Laela' },
  { label: 'Laem Sing', value: 'Laem Sing' },
  { label: 'Laer', value: 'Laer' },
  { label: 'Lærdal', value: 'Lærdal' },
  { label: 'Lærdalsøyri', value: 'Lærdalsøyri' },
  { label: 'Laerru', value: 'Laerru' },
  { label: 'Læso Kommune', value: 'Læso Kommune' },
  { label: 'Lafaiete Coutinho', value: 'Lafaiete Coutinho' },
  { label: 'Lafayette', value: 'Lafayette' },
  { label: 'Lafayette', value: 'Lafayette' },
  { label: 'Lafayette', value: 'Lafayette' },
  { label: 'Lafayette', value: 'Lafayette' },
  { label: 'Lafayette', value: 'Lafayette' },
  { label: 'Lafayette', value: 'Lafayette' },
  { label: 'Lafayette', value: 'Lafayette' },
  { label: 'Lafayette', value: 'Lafayette' },
  { label: 'Lafayette County', value: 'Lafayette County' },
  { label: 'Lafayette County', value: 'Lafayette County' },
  { label: 'Lafayette County', value: 'Lafayette County' },
  { label: 'Lafayette County', value: 'Lafayette County' },
  { label: 'Lafayette County', value: 'Lafayette County' },
  { label: 'Lafayette Hill', value: 'Lafayette Hill' },
  { label: 'Lafayette Parish', value: 'Lafayette Parish' },
  { label: 'Lafia', value: 'Lafia' },
  { label: 'Lafiagi', value: 'Lafiagi' },
  { label: 'Laflin', value: 'Laflin' },
  { label: 'Lafnitz', value: 'Lafnitz' },
  { label: 'Lafollette', value: 'Lafollette' },
  { label: 'Lafourche Parish', value: 'Lafourche Parish' },
  { label: 'Lafragua', value: 'Lafragua' },
  { label: 'Lafrançaise', value: 'Lafrançaise' },
  { label: 'Laga', value: 'Laga' },
  { label: 'Lagaip Porgera', value: 'Lagaip Porgera' },
  { label: 'Lagamar', value: 'Lagamar' },
  { label: 'Lagan', value: 'Lagan' },
  { label: 'Laganadi', value: 'Laganadi' },
  { label: 'Lagangilang', value: 'Lagangilang' },
  { label: 'Lagangilang', value: 'Lagangilang' },
  { label: 'Lagardelle-Sur-Lèze', value: 'Lagardelle-Sur-Lèze' },
  { label: 'Lagarinhos', value: 'Lagarinhos' },
  { label: 'Lagarrigue', value: 'Lagarrigue' },
  { label: 'Lagartera', value: 'Lagartera' },
  { label: 'Lagarto', value: 'Lagarto' },
  { label: 'Lagartos', value: 'Lagartos' },
  { label: 'Lagasit', value: 'Lagasit' },
  { label: 'Lagasit', value: 'Lagasit' },
  { label: 'Lagata', value: 'Lagata' },
  { label: 'Lagawe', value: 'Lagawe' },
  { label: 'Lagawe', value: 'Lagawe' },
  { label: 'Lagayan', value: 'Lagayan' },
  { label: 'Lagayan', value: 'Lagayan' },
  { label: 'Lagdo', value: 'Lagdo' },
  { label: 'Lage', value: 'Lage' },
  { label: 'Lage Mierde', value: 'Lage Mierde' },
  { label: 'Lägerdorf', value: 'Lägerdorf' },
  { label: 'Lages', value: 'Lages' },
  { label: 'Laghetti', value: 'Laghetti' },
  { label: 'Laghetto', value: 'Laghetto' },
  { label: 'Laghi', value: 'Laghi' },
  { label: 'Laghouat', value: 'Laghouat' },
  { label: 'Łagiewniki', value: 'Łagiewniki' },
  { label: 'Lagindingan', value: 'Lagindingan' },
  { label: 'Lagja E Poshtme', value: 'Lagja E Poshtme' },
  { label: 'Lagkadás', value: 'Lagkadás' },
  { label: 'Laglio', value: 'Laglio' },
  { label: 'Lagnasco', value: 'Lagnasco' },
  { label: 'Lagnes', value: 'Lagnes' },
  { label: 'Lagnieu', value: 'Lagnieu' },
  { label: 'Lagny-Le-Sec', value: 'Lagny-Le-Sec' },
  { label: 'Lagny-Sur-Marne', value: 'Lagny-Sur-Marne' },
  { label: 'Lago', value: 'Lago' },
  { label: 'Lago', value: 'Lago' },
  { label: 'Lago Da Pedra', value: 'Lago Da Pedra' },
  { label: 'Lago Do Junco', value: 'Lago Do Junco' },
  { label: 'Lago Dos Rodrigues', value: 'Lago Dos Rodrigues' },
  { label: 'Lago Puelo', value: 'Lago Puelo' },
  { label: 'Lago Ranco', value: 'Lago Ranco' },
  { label: 'Lago Verde', value: 'Lago Verde' },
  { label: 'Lago Verde', value: 'Lago Verde' },
  { label: 'Lago Vista', value: 'Lago Vista' },
  { label: 'Lagoa', value: 'Lagoa' },
  { label: 'Lagoa', value: 'Lagoa' },
  { label: 'Lagoa', value: 'Lagoa' },
  { label: 'Lagoa', value: 'Lagoa' },
  { label: 'Lagoa Alegre', value: 'Lagoa Alegre' },
  { label: 'Lagoa Bonita Do Sul', value: 'Lagoa Bonita Do Sul' },
  { label: 'Lagoa Da Canoa', value: 'Lagoa Da Canoa' },
  { label: 'Lagoa Da Confusão', value: 'Lagoa Da Confusão' },
  { label: 'Lagoa Da Prata', value: 'Lagoa Da Prata' },
  { label: 'Lagoa Danta', value: 'Lagoa Danta' },
  { label: 'Lagoa De Dentro', value: 'Lagoa De Dentro' },
  { label: 'Lagoa De Itaenga', value: 'Lagoa De Itaenga' },
  { label: 'Lagoa De Pedras', value: 'Lagoa De Pedras' },
  { label: 'Lagoa De São Francisco', value: 'Lagoa De São Francisco' },
  { label: 'Lagoa De Velhos', value: 'Lagoa De Velhos' },
  { label: 'Lagoa Do Barro Do Piauí', value: 'Lagoa Do Barro Do Piauí' },
  { label: 'Lagoa Do Carro', value: 'Lagoa Do Carro' },
  { label: 'Lagoa Do Itaenga', value: 'Lagoa Do Itaenga' },
  { label: 'Lagoa Do Mato', value: 'Lagoa Do Mato' },
  { label: 'Lagoa Do Ouro', value: 'Lagoa Do Ouro' },
  { label: 'Lagoa Do Piauí', value: 'Lagoa Do Piauí' },
  { label: 'Lagoa Do Sítio', value: 'Lagoa Do Sítio' },
  { label: 'Lagoa Do Tocantins', value: 'Lagoa Do Tocantins' },
  { label: 'Lagoa Dos Gatos', value: 'Lagoa Dos Gatos' },
  { label: 'Lagoa Dos Patos', value: 'Lagoa Dos Patos' },
  { label: 'Lagoa Dos Três Cantos', value: 'Lagoa Dos Três Cantos' },
  { label: 'Lagoa Dourada', value: 'Lagoa Dourada' },
  { label: 'Lagoa Formosa', value: 'Lagoa Formosa' },
  { label: 'Lagoa Grande', value: 'Lagoa Grande' },
  { label: 'Lagoa Grande', value: 'Lagoa Grande' },
  { label: 'Lagoa Grande Do Maranhão', value: 'Lagoa Grande Do Maranhão' },
  { label: 'Lagoa Nova', value: 'Lagoa Nova' },
  { label: 'Lagoa Real', value: 'Lagoa Real' },
  { label: 'Lagoa Salgada', value: 'Lagoa Salgada' },
  { label: 'Lagoa Santa', value: 'Lagoa Santa' },
  { label: 'Lagoa Santa', value: 'Lagoa Santa' },
  { label: 'Lagoa Seca', value: 'Lagoa Seca' },
  { label: 'Lagoa Vermelha', value: 'Lagoa Vermelha' },
  { label: 'Lagoão', value: 'Lagoão' },
  { label: 'Lagodekhi', value: 'Lagodekhi' },
  { label: 'Lagoinha', value: 'Lagoinha' },
  { label: 'Lagoinha Do Piauí', value: 'Lagoinha Do Piauí' },
  { label: 'Lagonav', value: 'Lagonav' },
  { label: 'Lagonegro', value: 'Lagonegro' },
  { label: 'Lagonglong', value: 'Lagonglong' },
  { label: 'Lagonoy', value: 'Lagonoy' },
  { label: 'Lagor', value: 'Lagor' },
  { label: 'Lagorce', value: 'Lagorce' },
  { label: 'Lagord', value: 'Lagord' },
  { label: 'Lagos', value: 'Lagos' },
  { label: 'Lagos', value: 'Lagos' },
  { label: 'Lagós', value: 'Lagós' },
  { label: 'Lagos De Moreno', value: 'Lagos De Moreno' },
  { label: 'Lagos Island', value: 'Lagos Island' },
  { label: 'Lagosanto', value: 'Lagosanto' },
  { label: 'Łagów', value: 'Łagów' },
  { label: 'Łagów', value: 'Łagów' },
  { label: 'Łagów', value: 'Łagów' },
  { label: 'Lagrán', value: 'Lagrán' },
  { label: 'Lagrange', value: 'Lagrange' },
  { label: 'Lagrange', value: 'Lagrange' },
  { label: 'Lagrange', value: 'Lagrange' },
  { label: 'Lagrange County', value: 'Lagrange County' },
  { label: 'Lagrave', value: 'Lagrave' },
  { label: 'Laguardia', value: 'Laguardia' },
  { label: 'Laguenne', value: 'Laguenne' },
  { label: 'Lagueruela', value: 'Lagueruela' },
  { label: 'Laguilayan', value: 'Laguilayan' },
  { label: 'Laguilayan', value: 'Laguilayan' },
  { label: 'Laguiole', value: 'Laguiole' },
  { label: 'Laguit Centro', value: 'Laguit Centro' },
  { label: 'Laguit Centro', value: 'Laguit Centro' },
  { label: 'Laguitas', value: 'Laguitas' },
  { label: 'Laguna', value: 'Laguna' },
  { label: 'Laguna', value: 'Laguna' },
  { label: 'Laguna', value: 'Laguna' },
  { label: 'Laguna Beach', value: 'Laguna Beach' },
  { label: 'Laguna Beach', value: 'Laguna Beach' },
  { label: 'Laguna Blanca', value: 'Laguna Blanca' },
  { label: 'Laguna Carapã', value: 'Laguna Carapã' },
  { label: 'Laguna Chica (Pueblo Nuevo)', value: 'Laguna Chica (Pueblo Nuevo)' },
  { label: 'Laguna Colorada', value: 'Laguna Colorada' },
  { label: 'Laguna Dalga', value: 'Laguna Dalga' },
  { label: 'Laguna De Cameros', value: 'Laguna De Cameros' },
  { label: 'Laguna De Contreras', value: 'Laguna De Contreras' },
  { label: 'Laguna De Duero', value: 'Laguna De Duero' },
  { label: 'Laguna De Farfán', value: 'Laguna De Farfán' },
  { label: 'Laguna De Guadalupe', value: 'Laguna De Guadalupe' },
  { label: 'Laguna De Negrillos', value: 'Laguna De Negrillos' },
  { label: 'Laguna De Perlas', value: 'Laguna De Perlas' },
  { label: 'Laguna De Piedra', value: 'Laguna De Piedra' },
  { label: 'Laguna De San Vicente', value: 'Laguna De San Vicente' },
  { label: 'Laguna De Santa Rita', value: 'Laguna De Santa Rita' },
  { label: 'Laguna De Tabernillas (El Resbaloso)', value: 'Laguna De Tabernillas (El Resbaloso)' },
  { label: 'Laguna De Vaquerías', value: 'Laguna De Vaquerías' },
  { label: 'Laguna Del Cofre', value: 'Laguna Del Cofre' },
  { label: 'Laguna Del Mante', value: 'Laguna Del Mante' },
  { label: 'Laguna Del Marquesado', value: 'Laguna Del Marquesado' },
  { label: 'Laguna Del Rey (Químicas Del Rey)', value: 'Laguna Del Rey (Químicas Del Rey)' },
  { label: 'Laguna Heights', value: 'Laguna Heights' },
  { label: 'Laguna Hills', value: 'Laguna Hills' },
  { label: 'Laguna Larga', value: 'Laguna Larga' },
  { label: 'Laguna Larga', value: 'Laguna Larga' },
  { label: 'Laguna Larga De Cortés', value: 'Laguna Larga De Cortés' },
  { label: 'Laguna Limpia', value: 'Laguna Limpia' },
  { label: 'Laguna Naick-Neck', value: 'Laguna Naick-Neck' },
  { label: 'Laguna Niguel', value: 'Laguna Niguel' },
  { label: 'Laguna Paiva', value: 'Laguna Paiva' },
  { label: 'Laguna Park', value: 'Laguna Park' },
  { label: 'Laguna Salada', value: 'Laguna Salada' },
  { label: 'Laguna Seca', value: 'Laguna Seca' },
  { label: 'Laguna Seca', value: 'Laguna Seca' },
  { label: 'Laguna Verde', value: 'Laguna Verde' },
  { label: 'Laguna Vista', value: 'Laguna Vista' },
  { label: 'Laguna Woods', value: 'Laguna Woods' },
  { label: 'Laguna Yema', value: 'Laguna Yema' },
  { label: 'Lagunas', value: 'Lagunas' },
  { label: 'Lagunaseca', value: 'Lagunaseca' },
  { label: 'Lagundo', value: 'Lagundo' },
  { label: 'Laguneta', value: 'Laguneta' },
  { label: 'Lagunilla', value: 'Lagunilla' },
  { label: 'Lagunilla', value: 'Lagunilla' },
  { label: 'Lagunilla', value: 'Lagunilla' },
  { label: 'Lagunilla Del Jubera', value: 'Lagunilla Del Jubera' },
  { label: 'Lagunillas', value: 'Lagunillas' },
  { label: 'Lagunillas', value: 'Lagunillas' },
  { label: 'Lagunillas', value: 'Lagunillas' },
  { label: 'Lagunillas', value: 'Lagunillas' },
  { label: 'Lagunillas Jalisco', value: 'Lagunillas Jalisco' },
  { label: 'Lagunitas-Forest Knolls', value: 'Lagunitas-Forest Knolls' },
  { label: 'Lagyná', value: 'Lagyná' },
  { label: 'Lahad Datu', value: 'Lahad Datu' },
  { label: 'Lahaina', value: 'Lahaina' },
  { label: 'Lahar', value: 'Lahar' },
  { label: 'Laharpur', value: 'Laharpur' },
  { label: 'Lahat', value: 'Lahat' },
  { label: 'Lahat Regency', value: 'Lahat Regency' },
  { label: 'Lähden', value: 'Lähden' },
  { label: 'Lahiguera', value: 'Lahiguera' },
  { label: 'Laḩij', value: 'Laḩij' },
  { label: 'Lahijan', value: 'Lahijan' },
  { label: 'Lahishyn', value: 'Lahishyn' },
  { label: 'Lahnstein', value: 'Lahnstein' },
  { label: 'Laholm', value: 'Laholm' },
  { label: 'Lahonce', value: 'Lahonce' },
  { label: 'Lahore', value: 'Lahore' },
  { label: 'Lahoysk', value: 'Lahoysk' },
  { label: 'Lahr', value: 'Lahr' },
  { label: 'Lahroud', value: 'Lahroud' },
  { label: 'Lahti', value: 'Lahti' },
  { label: 'Lahuachaca', value: 'Lahuachaca' },
  { label: 'Lahul And Spiti', value: 'Lahul And Spiti' },
  { label: 'Laï', value: 'Laï' },
  { label: 'Lai Chi Kok', value: 'Lai Chi Kok' },
  { label: 'Laibin', value: 'Laibin' },
  { label: 'Laichingen', value: 'Laichingen' },
  { label: 'Laidley', value: 'Laidley' },
  { label: 'Laigné-En-Belin', value: 'Laigné-En-Belin' },
  { label: 'Laigneville', value: 'Laigneville' },
  { label: 'Laigueglia', value: 'Laigueglia' },
  { label: 'Laihia', value: 'Laihia' },
  { label: 'Laikit Laikit Ii (Dimembe)', value: 'Laikit Laikit Ii (Dimembe)' },
  { label: 'Laillé', value: 'Laillé' },
  { label: 'Lailly-En-Val', value: 'Lailly-En-Val' },
  { label: 'Laimós', value: 'Laimós' },
  { label: 'Lainate', value: 'Lainate' },
  { label: 'Laingsburg', value: 'Laingsburg' },
  { label: 'Laino', value: 'Laino' },
  { label: 'Laino Borgo', value: 'Laino Borgo' },
  { label: 'Laino Castello-Nuovo Centro', value: 'Laino Castello-Nuovo Centro' },
  { label: 'Laion', value: 'Laion' },
  { label: 'Lais', value: 'Lais' },
  { label: 'Lais', value: 'Lais' },
  { label: 'Lais', value: 'Lais' },
  { label: 'Laishevo', value: 'Laishevo' },
  { label: 'Laishevskiy Rayon', value: 'Laishevskiy Rayon' },
  { label: 'Laissac', value: 'Laissac' },
  { label: 'Laitila', value: 'Laitila' },
  { label: 'Laives', value: 'Laives' },
  { label: 'Laiwu', value: 'Laiwu' },
  { label: 'Laixi', value: 'Laixi' },
  { label: 'Laiya', value: 'Laiya' },
  { label: 'Laiyang', value: 'Laiyang' },
  { label: 'Laiz', value: 'Laiz' },
  { label: 'Laizhou', value: 'Laizhou' },
  { label: 'Laja', value: 'Laja' },
  { label: 'Lajamina', value: 'Lajamina' },
  { label: 'Lajas', value: 'Lajas' },
  { label: 'Lajas', value: 'Lajas' },
  { label: 'Lajas', value: 'Lajas' },
  { label: 'Lajas Adentro', value: 'Lajas Adentro' },
  { label: 'Lajas De Tolé', value: 'Lajas De Tolé' },
  { label: 'Lajas Municipio', value: 'Lajas Municipio' },
  { label: 'Lajatico', value: 'Lajatico' },
  { label: 'Laje', value: 'Laje' },
  { label: 'Laje Do Muriaé', value: 'Laje Do Muriaé' },
  { label: 'Lajeado', value: 'Lajeado' },
  { label: 'Lajeado', value: 'Lajeado' },
  { label: 'Lajeado Do Bugre', value: 'Lajeado Do Bugre' },
  { label: 'Lajeado Grande', value: 'Lajeado Grande' },
  { label: 'Lajeado Novo', value: 'Lajeado Novo' },
  { label: 'Lajedão', value: 'Lajedão' },
  { label: 'Lajedinho', value: 'Lajedinho' },
  { label: 'Lajedo', value: 'Lajedo' },
  { label: 'Lajedo Do Tabocal', value: 'Lajedo Do Tabocal' },
  { label: 'Lajeosa', value: 'Lajeosa' },
  { label: 'Lajeosa Do Mondego', value: 'Lajeosa Do Mondego' },
  { label: 'Lajere', value: 'Lajere' },
  { label: 'Lajero Arriba', value: 'Lajero Arriba' },
  { label: 'Lajes', value: 'Lajes' },
  { label: 'Lajes', value: 'Lajes' },
  { label: 'Lajes', value: 'Lajes' },
  { label: 'Lajes Das Flores', value: 'Lajes Das Flores' },
  { label: 'Lajes Do Pico', value: 'Lajes Do Pico' },
  { label: 'Lajes Pintadas', value: 'Lajes Pintadas' },
  { label: 'Lajinha', value: 'Lajinha' },
  { label: 'Lajong', value: 'Lajong' },
  { label: 'Lajoskomárom', value: 'Lajoskomárom' },
  { label: 'Lajosmizse', value: 'Lajosmizse' },
  { label: 'Łajski', value: 'Łajski' },
  { label: 'Lak Si', value: 'Lak Si' },
  { label: 'Łąka Prudnicka', value: 'Łąka Prudnicka' },
  { label: 'Lakatnik', value: 'Lakatnik' },
  { label: 'Lakatoro', value: 'Lakatoro' },
  { label: 'Lake Albert', value: 'Lake Albert' },
  { label: 'Lake Alfred', value: 'Lake Alfred' },
  { label: 'Lake And Peninsula Borough', value: 'Lake And Peninsula Borough' },
  { label: 'Lake Andes', value: 'Lake Andes' },
  { label: 'Lake Arbor', value: 'Lake Arbor' },
  { label: 'Lake Arrowhead', value: 'Lake Arrowhead' },
  { label: 'Lake Arrowhead', value: 'Lake Arrowhead' },
  { label: 'Lake Arthur', value: 'Lake Arthur' },
  { label: 'Lake Barcroft', value: 'Lake Barcroft' },
  { label: 'Lake Barrington', value: 'Lake Barrington' },
  { label: 'Lake Belvedere Estates', value: 'Lake Belvedere Estates' },
  { label: 'Lake Bluff', value: 'Lake Bluff' },
  { label: 'Lake Brownwood', value: 'Lake Brownwood' },
  { label: 'Lake Butler', value: 'Lake Butler' },
  { label: 'Lake Camelot', value: 'Lake Camelot' },
  { label: 'Lake Cargelligo', value: 'Lake Cargelligo' },
  { label: 'Lake Carmel', value: 'Lake Carmel' },
  { label: 'Lake Catherine', value: 'Lake Catherine' },
  { label: 'Lake Cathie', value: 'Lake Cathie' },
  { label: 'Lake Charles', value: 'Lake Charles' },
  { label: 'Lake City', value: 'Lake City' },
  { label: 'Lake City', value: 'Lake City' },
  { label: 'Lake City', value: 'Lake City' },
  { label: 'Lake City', value: 'Lake City' },
  { label: 'Lake City', value: 'Lake City' },
  { label: 'Lake City', value: 'Lake City' },
  { label: 'Lake City', value: 'Lake City' },
  { label: 'Lake City', value: 'Lake City' },
  { label: 'Lake City', value: 'Lake City' },
  { label: 'Lake Clarke Shores', value: 'Lake Clarke Shores' },
  { label: 'Lake Como', value: 'Lake Como' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake County', value: 'Lake County' },
  { label: 'Lake Cowichan', value: 'Lake Cowichan' },
  { label: 'Lake Crystal', value: 'Lake Crystal' },
  { label: 'Lake Dalecarlia', value: 'Lake Dalecarlia' },
  { label: 'Lake Dallas', value: 'Lake Dallas' },
  { label: 'Lake Darby', value: 'Lake Darby' },
  { label: 'Lake Delton', value: 'Lake Delton' },
  { label: 'Lake District', value: 'Lake District' },
  { label: 'Lake Dunlap', value: 'Lake Dunlap' },
  { label: 'Lake Echo', value: 'Lake Echo' },
  { label: 'Lake Elmo', value: 'Lake Elmo' },
  { label: 'Lake Elsinore', value: 'Lake Elsinore' },
  { label: 'Lake Erie Beach', value: 'Lake Erie Beach' },
  { label: 'Lake Fenton', value: 'Lake Fenton' },
  { label: 'Lake Forest', value: 'Lake Forest' },
  { label: 'Lake Forest', value: 'Lake Forest' },
  { label: 'Lake Forest', value: 'Lake Forest' },
  { label: 'Lake Forest Park', value: 'Lake Forest Park' },
  { label: 'Lake Gardens', value: 'Lake Gardens' },
  { label: 'Lake Geneva', value: 'Lake Geneva' },
  { label: 'Lake Grace', value: 'Lake Grace' },
  { label: 'Lake Grove', value: 'Lake Grove' },
  { label: 'Lake Hallie', value: 'Lake Hallie' },
  { label: 'Lake Hamilton', value: 'Lake Hamilton' },
  { label: 'Lake Hamilton', value: 'Lake Hamilton' },
  { label: 'Lake Havasu City', value: 'Lake Havasu City' },
  { label: 'Lake Haven', value: 'Lake Haven' },
  { label: 'Lake Heights', value: 'Lake Heights' },
  { label: 'Lake Helen', value: 'Lake Helen' },
  { label: 'Lake Heritage', value: 'Lake Heritage' },
  { label: 'Lake Holiday', value: 'Lake Holiday' },
  { label: 'Lake Illawarra', value: 'Lake Illawarra' },
  { label: 'Lake In The Hills', value: 'Lake In The Hills' },
  { label: 'Lake Isabella', value: 'Lake Isabella' },
  { label: 'Lake Isabella', value: 'Lake Isabella' },
  { label: 'Lake Jackson', value: 'Lake Jackson' },
  { label: 'Lake Junaluska', value: 'Lake Junaluska' },
  { label: 'Lake Katrine', value: 'Lake Katrine' },
  { label: 'Lake Kiowa', value: 'Lake Kiowa' },
  { label: 'Lake Koshkonong', value: 'Lake Koshkonong' },
  { label: 'Lake Lakengren', value: 'Lake Lakengren' },
  { label: 'Lake Latonka', value: 'Lake Latonka' },
  { label: 'Lake Lorraine', value: 'Lake Lorraine' },
  { label: 'Lake Los Angeles', value: 'Lake Los Angeles' },
  { label: 'Lake Lotawana', value: 'Lake Lotawana' },
  { label: 'Lake Lucerne', value: 'Lake Lucerne' },
  { label: 'Lake Lure', value: 'Lake Lure' },
  { label: 'Lake Luzerne', value: 'Lake Luzerne' },
  { label: 'Lake Macdonald', value: 'Lake Macdonald' },
  { label: 'Lake Mack-Forest Hills', value: 'Lake Mack-Forest Hills' },
  { label: 'Lake Macquarie Shire', value: 'Lake Macquarie Shire' },
  { label: 'Lake Magdalene', value: 'Lake Magdalene' },
  { label: 'Lake Marcel-Stillwater', value: 'Lake Marcel-Stillwater' },
  { label: 'Lake Mary', value: 'Lake Mary' },
  { label: 'Lake Meade', value: 'Lake Meade' },
  { label: 'Lake Michigan Beach', value: 'Lake Michigan Beach' },
  { label: 'Lake Mills', value: 'Lake Mills' },
  { label: 'Lake Mills', value: 'Lake Mills' },
  { label: 'Lake Mohawk', value: 'Lake Mohawk' },
  { label: 'Lake Mohawk', value: 'Lake Mohawk' },
  { label: 'Lake Mohegan', value: 'Lake Mohegan' },
  { label: 'Lake Montezuma', value: 'Lake Montezuma' },
  { label: 'Lake Monticello', value: 'Lake Monticello' },
  { label: 'Lake Morton-Berrydale', value: 'Lake Morton-Berrydale' },
  { label: 'Lake Munmorah', value: 'Lake Munmorah' },
  { label: 'Lake Murray Of Richland', value: 'Lake Murray Of Richland' },
  { label: 'Lake Nacimiento', value: 'Lake Nacimiento' },
  { label: 'Lake Nebagamon', value: 'Lake Nebagamon' },
  { label: 'Lake Norman Of Catawba', value: 'Lake Norman Of Catawba' },
  { label: 'Lake Odessa', value: 'Lake Odessa' },
  { label: 'Lake Of The Pines', value: 'Lake Of The Pines' },
  { label: 'Lake Of The Woods', value: 'Lake Of The Woods' },
  { label: 'Lake Of The Woods', value: 'Lake Of The Woods' },
  { label: 'Lake Of The Woods County', value: 'Lake Of The Woods County' },
  { label: 'Lake Orion', value: 'Lake Orion' },
  { label: 'Lake Oswego', value: 'Lake Oswego' },
  { label: 'Lake Ozark', value: 'Lake Ozark' },
  { label: 'Lake Panasoffkee', value: 'Lake Panasoffkee' },
  { label: 'Lake Panorama', value: 'Lake Panorama' },
  { label: 'Lake Park', value: 'Lake Park' },
  { label: 'Lake Park', value: 'Lake Park' },
  { label: 'Lake Park', value: 'Lake Park' },
  { label: 'Lake Placid', value: 'Lake Placid' },
  { label: 'Lake Placid', value: 'Lake Placid' },
  { label: 'Lake Pleasant', value: 'Lake Pleasant' },
  { label: 'Lake Pocotopaug', value: 'Lake Pocotopaug' },
  { label: 'Lake Providence', value: 'Lake Providence' },
  { label: 'Lake Purdy', value: 'Lake Purdy' },
  { label: 'Lake Ridge', value: 'Lake Ridge' },
  { label: 'Lake Ripley', value: 'Lake Ripley' },
  { label: 'Lake Ronkonkoma', value: 'Lake Ronkonkoma' },
  { label: 'Lake Saint Croix Beach', value: 'Lake Saint Croix Beach' },
  { label: 'Lake Saint Louis', value: 'Lake Saint Louis' },
  { label: 'Lake San Marcos', value: 'Lake San Marcos' },
  { label: 'Lake Sarasota', value: 'Lake Sarasota' },
  { label: 'Lake Sebu', value: 'Lake Sebu' },
  { label: 'Lake Secession', value: 'Lake Secession' },
  { label: 'Lake Shore', value: 'Lake Shore' },
  { label: 'Lake Shore', value: 'Lake Shore' },
  { label: 'Lake Shore', value: 'Lake Shore' },
  { label: 'Lake Station', value: 'Lake Station' },
  { label: 'Lake Stevens', value: 'Lake Stevens' },
  { label: 'Lake Stickney', value: 'Lake Stickney' },
  { label: 'Lake Success', value: 'Lake Success' },
  { label: 'Lake Summerset', value: 'Lake Summerset' },
  { label: 'Lake Tansi', value: 'Lake Tansi' },
  { label: 'Lake Telemark', value: 'Lake Telemark' },
  { label: 'Lake View', value: 'Lake View' },
  { label: 'Lake View', value: 'Lake View' },
  { label: 'Lake Villa', value: 'Lake Villa' },
  { label: 'Lake Village', value: 'Lake Village' },
  { label: 'Lake Waccamaw', value: 'Lake Waccamaw' },
  { label: 'Lake Wales', value: 'Lake Wales' },
  { label: 'Lake Wazeecha', value: 'Lake Wazeecha' },
  { label: 'Lake Wendouree', value: 'Lake Wendouree' },
  { label: 'Lake Wildwood', value: 'Lake Wildwood' },
  { label: 'Lake Winnebago', value: 'Lake Winnebago' },
  { label: 'Lake Wisconsin', value: 'Lake Wisconsin' },
  { label: 'Lake Wissota', value: 'Lake Wissota' },
  { label: 'Lake Worth', value: 'Lake Worth' },
  { label: 'Lake Worth', value: 'Lake Worth' },
  { label: 'Lake Worth Corridor', value: 'Lake Worth Corridor' },
  { label: 'Lake Wylie', value: 'Lake Wylie' },
  { label: 'Lake Wynonah', value: 'Lake Wynonah' },
  { label: 'Lake Zurich', value: 'Lake Zurich' },
  { label: 'Lakefield', value: 'Lakefield' },
  { label: 'Lakefield', value: 'Lakefield' },
  { label: 'Lakehills', value: 'Lakehills' },
  { label: 'Lakehurst', value: 'Lakehurst' },
  { label: 'Lakeland', value: 'Lakeland' },
  { label: 'Lakeland', value: 'Lakeland' },
  { label: 'Lakeland', value: 'Lakeland' },
  { label: 'Lakeland', value: 'Lakeland' },
  { label: 'Lakeland', value: 'Lakeland' },
  { label: 'Lakeland Highlands', value: 'Lakeland Highlands' },
  { label: 'Lakeland North', value: 'Lakeland North' },
  { label: 'Lakeland South', value: 'Lakeland South' },
  { label: 'Lakeland Village', value: 'Lakeland Village' },
  { label: 'Lakelands', value: 'Lakelands' },
  { label: 'Lakelands', value: 'Lakelands' },
  { label: 'Lakemba', value: 'Lakemba' },
  { label: 'Lakemont', value: 'Lakemont' },
  { label: 'Lakemoor', value: 'Lakemoor' },
  { label: 'Lakemore', value: 'Lakemore' },
  { label: 'Lakenheath', value: 'Lakenheath' },
  { label: 'Lakeport', value: 'Lakeport' },
  { label: 'Lakerlopen', value: 'Lakerlopen' },
  { label: 'Lakes', value: 'Lakes' },
  { label: 'Lakes By The Bay', value: 'Lakes By The Bay' },
  { label: 'Lakes Entrance', value: 'Lakes Entrance' },
  { label: 'Lakes Of The Four Seasons', value: 'Lakes Of The Four Seasons' },
  { label: 'Lakeshire', value: 'Lakeshire' },
  { label: 'Lakeshore', value: 'Lakeshore' },
  { label: 'Lakeside', value: 'Lakeside' },
  { label: 'Lakeside', value: 'Lakeside' },
  { label: 'Lakeside', value: 'Lakeside' },
  { label: 'Lakeside', value: 'Lakeside' },
  { label: 'Lakeside', value: 'Lakeside' },
  { label: 'Lakeside', value: 'Lakeside' },
  { label: 'Lakeside Park', value: 'Lakeside Park' },
  { label: 'Lakesite', value: 'Lakesite' },
  { label: 'Lakeview', value: 'Lakeview' },
  { label: 'Lakeview', value: 'Lakeview' },
  { label: 'Lakeview', value: 'Lakeview' },
  { label: 'Lakeview', value: 'Lakeview' },
  { label: 'Lakeview', value: 'Lakeview' },
  { label: 'Lakeview', value: 'Lakeview' },
  { label: 'Lakeview Estates', value: 'Lakeview Estates' },
  { label: 'Lakeville', value: 'Lakeville' },
  { label: 'Lakeway', value: 'Lakeway' },
  { label: 'Lakewood', value: 'Lakewood' },
  { label: 'Lakewood', value: 'Lakewood' },
  { label: 'Lakewood', value: 'Lakewood' },
  { label: 'Lakewood', value: 'Lakewood' },
  { label: 'Lakewood', value: 'Lakewood' },
  { label: 'Lakewood', value: 'Lakewood' },
  { label: 'Lakewood', value: 'Lakewood' },
  { label: 'Lakewood', value: 'Lakewood' },
  { label: 'Lakewood', value: 'Lakewood' },
  { label: 'Lakewood', value: 'Lakewood' },
  { label: 'Lakewood Club', value: 'Lakewood Club' },
  { label: 'Lakewood Park', value: 'Lakewood Park' },
  { label: 'Lakewood Shores', value: 'Lakewood Shores' },
  { label: 'Lakha Nëvre', value: 'Lakha Nëvre' },
  { label: 'Lakhandur', value: 'Lakhandur' },
  { label: 'Lakhdaria', value: 'Lakhdaria' },
  { label: 'Lakhdenpokh’Ya', value: 'Lakhdenpokh’Ya' },
  { label: 'Lakheri', value: 'Lakheri' },
  { label: 'Lakhi', value: 'Lakhi' },
  { label: 'Lakhimpur', value: 'Lakhimpur' },
  { label: 'Lakhimpur', value: 'Lakhimpur' },
  { label: 'Lakhipur', value: 'Lakhipur' },
  { label: 'Lakhisarai', value: 'Lakhisarai' },
  { label: 'Lakhna', value: 'Lakhna' },
  { label: 'Lakhnadon', value: 'Lakhnadon' },
  { label: 'Lakhpat', value: 'Lakhpat' },
  { label: 'Lakhtar', value: 'Lakhtar' },
  { label: 'Lakhtinskiy', value: 'Lakhtinskiy' },
  { label: 'Laki', value: 'Laki' },
  { label: 'Lakin', value: 'Lakin' },
  { label: 'Lakinsk', value: 'Lakinsk' },
  { label: 'Lakitelek', value: 'Lakitelek' },
  { label: 'Lakkha Nëvre', value: 'Lakkha Nëvre' },
  { label: 'Lakki', value: 'Lakki' },
  { label: 'Lakkí', value: 'Lakkí' },
  { label: 'Lákkoma', value: 'Lákkoma' },
  { label: 'Lakota', value: 'Lakota' },
  { label: 'Lakota', value: 'Lakota' },
  { label: 'Laksar', value: 'Laksar' },
  { label: 'Lakselv', value: 'Lakselv' },
  { label: 'Lakshadweep', value: 'Lakshadweep' },
  { label: 'Lakshettipet', value: 'Lakshettipet' },
  { label: 'Lakshmeshwar', value: 'Lakshmeshwar' },
  { label: 'Lakshmipur', value: 'Lakshmipur' },
  { label: 'Lakskiy Rayon', value: 'Lakskiy Rayon' },
  { label: 'Laktaši', value: 'Laktaši' },
  { label: 'Lakuntza', value: 'Lakuntza' },
  { label: 'Lal Bahadur Nagar', value: 'Lal Bahadur Nagar' },
  { label: 'Lal’Sk', value: 'Lal’Sk' },
  { label: 'Lala', value: 'Lala' },
  { label: 'Lala', value: 'Lala' },
  { label: 'Lala Musa', value: 'Lala Musa' },
  { label: 'Lalab', value: 'Lalab' },
  { label: 'Lalago', value: 'Lalago' },
  { label: 'Lalagsan', value: 'Lalagsan' },
  { label: 'Lalam', value: 'Lalam' },
  { label: 'Lalapanzi', value: 'Lalapanzi' },
  { label: 'Lâlapaşa', value: 'Lâlapaşa' },
  { label: 'Lálas', value: 'Lálas' },
  { label: 'Lalauigan', value: 'Lalauigan' },
  { label: 'Lalauigan', value: 'Lalauigan' },
  { label: 'Lalbenque', value: 'Lalbenque' },
  { label: 'Lalcora', value: 'Lalcora' },
  { label: 'Lalcúdia', value: 'Lalcúdia' },
  { label: 'Lalcúdia De Crespìns', value: 'Lalcúdia De Crespìns' },
  { label: 'Laleh Dan', value: 'Laleh Dan' },
  { label: 'Laleh Zaar', value: 'Laleh Zaar' },
  { label: 'Lalejin', value: 'Lalejin' },
  { label: 'Lalendorf', value: 'Lalendorf' },
  { label: 'Lalevade-D’Ardèche', value: 'Lalevade-D’Ardèche' },
  { label: 'Lalfàs Del Pi', value: 'Lalfàs Del Pi' },
  { label: 'Lalganj', value: 'Lalganj' },
  { label: 'Lalganj', value: 'Lalganj' },
  { label: 'Lalgarh', value: 'Lalgarh' },
  { label: 'Lalgola', value: 'Lalgola' },
  { label: 'Lalgudi', value: 'Lalgudi' },
  { label: 'Lali', value: 'Lali' },
  { label: 'Lalian', value: 'Lalian' },
  { label: 'Lalībela', value: 'Lalībela' },
  { label: 'Lalig', value: 'Lalig' },
  { label: 'Laliki', value: 'Laliki' },
  { label: 'Lalín', value: 'Lalín' },
  { label: 'Lalinde', value: 'Lalinde' },
  { label: 'Lalitpur', value: 'Lalitpur' },
  { label: 'Lalitpur', value: 'Lalitpur' },
  { label: 'Lallaing', value: 'Lallaing' },
  { label: 'Lallayug', value: 'Lallayug' },
  { label: 'Lallayug', value: 'Lallayug' },
  { label: 'Lalling', value: 'Lalling' },
  { label: 'Lallio', value: 'Lallio' },
  { label: 'Lal-Lo', value: 'Lal-Lo' },
  { label: 'Lalmatie', value: 'Lalmatie' },
  { label: 'Lalmonirhat ', value: 'Lalmonirhat ' },
  { label: 'Lalor', value: 'Lalor' },
  { label: 'Lalor Park', value: 'Lalor Park' },
  { label: 'Laloşu', value: 'Laloşu' },
  { label: 'Laloubère', value: 'Laloubère' },
  { label: 'Lalpur', value: 'Lalpur' },
  { label: 'Lalqueria De La Comtessa', value: 'Lalqueria De La Comtessa' },
  { label: 'Lalsot', value: 'Lalsot' },
  { label: 'Laluenga', value: 'Laluenga' },
  { label: 'Lalueza', value: 'Lalueza' },
  { label: 'Lalupon', value: 'Lalupon' },
  { label: 'Lam', value: 'Lam' },
  { label: 'Lam Tin', value: 'Lam Tin' },
  { label: 'Lama', value: 'Lama' },
  { label: 'Lama A', value: 'Lama A' },
  { label: 'Lama Dei Peligni', value: 'Lama Dei Peligni' },
  { label: 'Lama Pezzoli', value: 'Lama Pezzoli' },
  { label: 'Lamadelaine', value: 'Lamadelaine' },
  { label: 'Lamadrid', value: 'Lamadrid' },
  { label: 'Lamagistère', value: 'Lamagistère' },
  { label: 'Lamak', value: 'Lamak' },
  { label: 'Lamak', value: 'Lamak' },
  { label: 'Lamalou-Les-Bains', value: 'Lamalou-Les-Bains' },
  { label: 'Lamam', value: 'Lamam' },
  { label: 'Lamaní', value: 'Lamaní' },
  { label: 'Lamanon', value: 'Lamanon' },
  { label: 'Lamar', value: 'Lamar' },
  { label: 'Lamar', value: 'Lamar' },
  { label: 'Lamar', value: 'Lamar' },
  { label: 'Lamar County', value: 'Lamar County' },
  { label: 'Lamar County', value: 'Lamar County' },
  { label: 'Lamar County', value: 'Lamar County' },
  { label: 'Lamar County', value: 'Lamar County' },
  { label: 'Lamarão', value: 'Lamarão' },
  { label: 'Lamarche', value: 'Lamarche' },
  { label: 'Lamarche-Sur-Saône', value: 'Lamarche-Sur-Saône' },
  { label: 'Lamarque', value: 'Lamarque' },
  { label: 'Lamarque', value: 'Lamarque' },
  { label: 'Lamartine', value: 'Lamartine' },
  { label: 'Lamas', value: 'Lamas' },
  { label: 'Lamas', value: 'Lamas' },
  { label: 'Lamas', value: 'Lamas' },
  { label: 'Lămășeni', value: 'Lămășeni' },
  { label: 'Lamasón', value: 'Lamasón' },
  { label: 'Lamastre', value: 'Lamastre' },
  { label: 'Lamaze', value: 'Lamaze' },
  { label: 'Lamb County', value: 'Lamb County' },
  { label: 'Lamba', value: 'Lamba' },
  { label: 'Lambach', value: 'Lambach' },
  { label: 'Lambakin', value: 'Lambakin' },
  { label: 'Lambakin', value: 'Lambakin' },
  { label: 'Lamballe', value: 'Lamballe' },
  { label: 'Lambaré', value: 'Lambaré' },
  { label: 'Lambaréné', value: 'Lambaréné' },
  { label: 'Lambari', value: 'Lambari' },
  { label: 'Lambari Doeste', value: 'Lambari Doeste' },
  { label: 'Lambayeque', value: 'Lambayeque' },
  { label: 'Lambayong', value: 'Lambayong' },
  { label: 'Lambayong', value: 'Lambayong' },
  { label: 'Lamberhurst', value: 'Lamberhurst' },
  { label: 'Lambersart', value: 'Lambersart' },
  { label: 'Lambert', value: 'Lambert' },
  { label: 'Lambertschaag', value: 'Lambertschaag' },
  { label: 'Lambertville', value: 'Lambertville' },
  { label: 'Lambertville', value: 'Lambertville' },
  { label: 'Lambesc', value: 'Lambesc' },
  { label: 'Lambeth', value: 'Lambeth' },
  { label: 'Lambhua', value: 'Lambhua' },
  { label: 'Łambinowice', value: 'Łambinowice' },
  { label: 'Lambo', value: 'Lambo' },
  { label: 'Lamboglia', value: 'Lamboglia' },
  { label: 'Lambontong', value: 'Lambontong' },
  { label: 'Lambontong', value: 'Lambontong' },
  { label: 'Lambourn', value: 'Lambourn' },
  { label: 'Lambrecht', value: 'Lambrecht' },
  { label: 'Lambrechtshagen', value: 'Lambrechtshagen' },
  { label: 'Lambres-Lez-Douai', value: 'Lambres-Lez-Douai' },
  { label: 'Lambrinia', value: 'Lambrinia' },
  { label: 'Lambrugo', value: 'Lambrugo' },
  { label: 'Lambs River', value: 'Lambs River' },
  { label: 'Lambsheim', value: 'Lambsheim' },
  { label: 'Lambton', value: 'Lambton' },
  { label: 'Lambton Shores', value: 'Lambton Shores' },
  { label: 'Lambunao', value: 'Lambunao' },
  { label: 'Lame', value: 'Lame' },
  { label: 'Lame Deer', value: 'Lame Deer' },
  { label: 'Lamegal', value: 'Lamegal' },
  { label: 'Lamego', value: 'Lamego' },
  { label: 'Lameiras', value: 'Lameiras' },
  { label: 'Lamerd', value: 'Lamerd' },
  { label: 'Lamerdingen', value: 'Lamerdingen' },
  { label: 'Lamesa', value: 'Lamesa' },
  { label: 'Lamesley', value: 'Lamesley' },
  { label: 'Lamezia Terme', value: 'Lamezia Terme' },
  { label: 'Lamía', value: 'Lamía' },
  { label: 'Lamian', value: 'Lamian' },
  { label: 'Lamian', value: 'Lamian' },
  { label: 'Lamie', value: 'Lamie' },
  { label: 'Lamie Di Olimpie-Selva', value: 'Lamie Di Olimpie-Selva' },
  { label: 'Lamim', value: 'Lamim' },
  { label: 'Lamin', value: 'Lamin' },
  { label: 'Lamington', value: 'Lamington' },
  { label: 'Laminusa', value: 'Laminusa' },
  { label: 'Laminusa', value: 'Laminusa' },
  { label: 'Lamisahan', value: 'Lamisahan' },
  { label: 'Lamitan', value: 'Lamitan' },
  { label: 'Lamitan', value: 'Lamitan' },
  { label: 'Lamitan', value: 'Lamitan' },
  { label: 'Lamitan', value: 'Lamitan' },
  { label: 'Lamitan City', value: 'Lamitan City' },
  { label: 'Lamitan City', value: 'Lamitan City' },
  { label: 'Lamiyan', value: 'Lamiyan' },
  { label: 'Lamjung ', value: 'Lamjung ' },
  { label: 'Lamlash', value: 'Lamlash' },
  { label: 'Lammerenburg', value: 'Lammerenburg' },
  { label: 'Lammermoor', value: 'Lammermoor' },
  { label: 'Lammhult', value: 'Lammhult' },
  { label: 'Lammi', value: 'Lammi' },
  { label: 'Lamnitz', value: 'Lamnitz' },
  { label: 'Lamoille County', value: 'Lamoille County' },
  { label: 'Lamon', value: 'Lamon' },
  { label: 'Lamongan', value: 'Lamongan' },
  { label: 'Lamoni', value: 'Lamoni' },
  { label: 'Lamont', value: 'Lamont' },
  { label: 'Lamont', value: 'Lamont' },
  { label: 'Lamonzie-Saint-Martin', value: 'Lamonzie-Saint-Martin' },
  { label: 'Lamorlaye', value: 'Lamorlaye' },
  { label: 'Lamosano', value: 'Lamosano' },
  { label: 'Lamothe-Montravel', value: 'Lamothe-Montravel' },
  { label: 'Lamotrek Municipality', value: 'Lamotrek Municipality' },
  { label: 'Lamotte-Beuvron', value: 'Lamotte-Beuvron' },
  { label: 'Lamoure County', value: 'Lamoure County' },
  { label: 'Lamovita', value: 'Lamovita' },
  { label: 'Lampa', value: 'Lampa' },
  { label: 'Lampa', value: 'Lampa' },
  { label: 'Lampang', value: 'Lampang' },
  { label: 'Lampari', value: 'Lampari' },
  { label: 'Lampari', value: 'Lampari' },
  { label: 'Lampasas', value: 'Lampasas' },
  { label: 'Lampasas County', value: 'Lampasas County' },
  { label: 'Lampaul-Guimiliau', value: 'Lampaul-Guimiliau' },
  { label: 'Lampaul-Plouarzel', value: 'Lampaul-Plouarzel' },
  { label: 'Lampazos De Naranjo', value: 'Lampazos De Naranjo' },
  { label: 'Lampedusa', value: 'Lampedusa' },
  { label: 'Lampertheim', value: 'Lampertheim' },
  { label: 'Lampertheim', value: 'Lampertheim' },
  { label: 'Lampertswalde', value: 'Lampertswalde' },
  { label: 'Lampeter', value: 'Lampeter' },
  { label: 'Lampeter', value: 'Lampeter' },
  { label: 'Lamphun', value: 'Lamphun' },
  { label: 'Lampitak', value: 'Lampitak' },
  { label: 'Lampitak', value: 'Lampitak' },
  { label: 'Lamporecchio', value: 'Lamporecchio' },
  { label: 'Lamporo', value: 'Lamporo' },
  { label: 'Lamprechtshausen', value: 'Lamprechtshausen' },
  { label: 'Lamspringe', value: 'Lamspringe' },
  { label: 'Lamstedt', value: 'Lamstedt' },
  { label: 'Lamtar', value: 'Lamtar' },
  { label: 'Lamu', value: 'Lamu' },
  { label: 'Lamure-Sur-Azergues', value: 'Lamure-Sur-Azergues' },
  { label: 'Lamut', value: 'Lamut' },
  { label: 'Lamut', value: 'Lamut' },
  { label: 'Lan Krabue', value: 'Lan Krabue' },
  { label: 'Lan Sak', value: 'Lan Sak' },
  { label: 'Lana', value: 'Lana' },
  { label: 'Lana', value: 'Lana' },
  { label: 'Lanai City', value: 'Lanai City' },
  { label: 'Lanaja', value: 'Lanaja' },
  { label: 'Lanaken', value: 'Lanaken' },
  { label: 'Lanao', value: 'Lanao' },
  { label: 'Lanark', value: 'Lanark' },
  { label: 'Lanark', value: 'Lanark' },
  { label: 'Lanas', value: 'Lanas' },
  { label: 'Lanas', value: 'Lanas' },
  { label: 'Lanat', value: 'Lanat' },
  { label: 'Lanat', value: 'Lanat' },
  { label: 'Láncara', value: 'Láncara' },
  { label: 'Lancashire', value: 'Lancashire' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Lancaster County', value: 'Lancaster County' },
  { label: 'Lancaster County', value: 'Lancaster County' },
  { label: 'Lancaster County', value: 'Lancaster County' },
  { label: 'Lancaster County', value: 'Lancaster County' },
  { label: 'Lancaster Mill', value: 'Lancaster Mill' },
  { label: 'Lancefield', value: 'Lancefield' },
  { label: 'Lancelin', value: 'Lancelin' },
  { label: 'Lancenigo-Villorba', value: 'Lancenigo-Villorba' },
  { label: 'Lances Bay', value: 'Lances Bay' },
  { label: 'Lanchester', value: 'Lanchester' },
  { label: 'Lanchkhuti', value: 'Lanchkhuti' },
  { label: 'Lanchyn', value: 'Lanchyn' },
  { label: 'Lanciano', value: 'Lanciano' },
  { label: 'Lanciego/Lantziego', value: 'Lanciego/Lantziego' },
  { label: 'Lancienne-Lorette', value: 'Lancienne-Lorette' },
  { label: 'Lancieux', value: 'Lancieux' },
  { label: 'Lancing', value: 'Lancing' },
  { label: 'Lanckorona', value: 'Lanckorona' },
  { label: 'Lanco', value: 'Lanco' },
  { label: 'Lançon-Provence', value: 'Lançon-Provence' },
  { label: 'Lancrăm', value: 'Lancrăm' },
  { label: 'Lancrans', value: 'Lancrans' },
  { label: 'Lancusi-Penta-Bolano', value: 'Lancusi-Penta-Bolano' },
  { label: 'Łańcut', value: 'Łańcut' },
  { label: 'Lancy', value: 'Lancy' },
  { label: 'Land O Lakes', value: 'Land O Lakes' },
  { label: 'Landa De Matamoros', value: 'Landa De Matamoros' },
  { label: 'Landak', value: 'Landak' },
  { label: 'Landang Laum', value: 'Landang Laum' },
  { label: 'Landari', value: 'Landari' },
  { label: 'Landas', value: 'Landas' },
  { label: 'Landau An Der Isar', value: 'Landau An Der Isar' },
  { label: 'Landau In Der Pfalz', value: 'Landau In Der Pfalz' },
  { label: 'Landaul', value: 'Landaul' },
  { label: 'Landázuri', value: 'Landázuri' },
  { label: 'Landéan', value: 'Landéan' },
  { label: 'Landeck', value: 'Landeck' },
  { label: 'Landéda', value: 'Landéda' },
  { label: 'Landegg', value: 'Landegg' },
  { label: 'Landeh', value: 'Landeh' },
  { label: 'Landéhen', value: 'Landéhen' },
  { label: 'Landeleau', value: 'Landeleau' },
  { label: 'Landemont', value: 'Landemont' },
  { label: 'Landen', value: 'Landen' },
  { label: 'Landen', value: 'Landen' },
  { label: 'Lander', value: 'Lander' },
  { label: 'Lander County', value: 'Lander County' },
  { label: 'Landerneau', value: 'Landerneau' },
  { label: 'Landero Y Coss', value: 'Landero Y Coss' },
  { label: 'Landeronde', value: 'Landeronde' },
  { label: 'Landes', value: 'Landes' },
  { label: 'Landesbergen', value: 'Landesbergen' },
  { label: 'Landete', value: 'Landete' },
  { label: 'Landévant', value: 'Landévant' },
  { label: 'Landgraaf', value: 'Landgraaf' },
  { label: 'Landi Kotal', value: 'Landi Kotal' },
  { label: 'Landín', value: 'Landín' },
  { label: 'Landing', value: 'Landing' },
  { label: 'Landiona', value: 'Landiona' },
  { label: 'Landiras', value: 'Landiras' },
  { label: 'Landis', value: 'Landis' },
  { label: 'Landisville', value: 'Landisville' },
  { label: 'Landivisiau', value: 'Landivisiau' },
  { label: 'Landivy', value: 'Landivy' },
  { label: 'Landl', value: 'Landl' },
  { label: 'Landmark', value: 'Landmark' },
  { label: 'Landmark', value: 'Landmark' },
  { label: 'Landolfshausen', value: 'Landolfshausen' },
  { label: 'Landover', value: 'Landover' },
  { label: 'Landover Hills', value: 'Landover Hills' },
  { label: 'Landquart', value: 'Landquart' },
  { label: 'Landrake', value: 'Landrake' },
  { label: 'Landrecies', value: 'Landrecies' },
  { label: 'Landres', value: 'Landres' },
  { label: 'Landrévarzec', value: 'Landrévarzec' },
  { label: 'Landri Sales', value: 'Landri Sales' },
  { label: 'Landriano', value: 'Landriano' },
  { label: 'Landrum', value: 'Landrum' },
  { label: 'Landsberg', value: 'Landsberg' },
  { label: 'Landsberg Am Lech', value: 'Landsberg Am Lech' },
  { label: 'Landsberied', value: 'Landsberied' },
  { label: 'Landsborough', value: 'Landsborough' },
  { label: 'Landsbro', value: 'Landsbro' },
  { label: 'Landscheid', value: 'Landscheid' },
  { label: 'Landsdale', value: 'Landsdale' },
  { label: 'Landser', value: 'Landser' },
  { label: 'Landshut', value: 'Landshut' },
  { label: 'Landskron', value: 'Landskron' },
  { label: 'Landskrona', value: 'Landskrona' },
  { label: 'Landsmeer', value: 'Landsmeer' },
  { label: 'Landstuhl', value: 'Landstuhl' },
  { label: 'Landudec', value: 'Landudec' },
  { label: 'Landunvez', value: 'Landunvez' },
  { label: 'Landvetter', value: 'Landvetter' },
  { label: 'Lane County', value: 'Lane County' },
  { label: 'Lane County', value: 'Lane County' },
  { label: 'Lane Cove', value: 'Lane Cove' },
  { label: 'Lane Cove North', value: 'Lane Cove North' },
  { label: 'Lane Cove West', value: 'Lane Cove West' },
  { label: 'Lanesborough', value: 'Lanesborough' },
  { label: 'Lanesborough', value: 'Lanesborough' },
  { label: 'Lanester', value: 'Lanester' },
  { label: 'Lanestosa', value: 'Lanestosa' },
  { label: 'Lanett', value: 'Lanett' },
  { label: 'Laneuveville-Devant-Nancy', value: 'Laneuveville-Devant-Nancy' },
  { label: 'Lang', value: 'Lang' },
  { label: 'Lang Lang', value: 'Lang Lang' },
  { label: 'Lạng Sơn', value: 'Lạng Sơn' },
  { label: 'Lang Suan', value: 'Lang Suan' },
  { label: 'Langa', value: 'Langa' },
  { label: 'Langå', value: 'Langå' },
  { label: 'Langa De Duero', value: 'Langa De Duero' },
  { label: 'Langa Del Castillo', value: 'Langa Del Castillo' },
  { label: 'Langádhia', value: 'Langádhia' },
  { label: 'Langanesbyggð', value: 'Langanesbyggð' },
  { label: 'Langarud', value: 'Langarud' },
  { label: 'Langatian', value: 'Langatian' },
  { label: 'Langau', value: 'Langau' },
  { label: 'Langayo', value: 'Langayo' },
  { label: 'Langballig', value: 'Langballig' },
  { label: 'Langcangan', value: 'Langcangan' },
  { label: 'Langdon', value: 'Langdon' },
  { label: 'Langeac', value: 'Langeac' },
  { label: 'Langeais', value: 'Langeais' },
  { label: 'Langedijk', value: 'Langedijk' },
  { label: 'Lange-Gardien', value: 'Lange-Gardien' },
  { label: 'Langegg Bei Graz', value: 'Langegg Bei Graz' },
  { label: 'Langeheit', value: 'Langeheit' },
  { label: 'Langeland Kommune', value: 'Langeland Kommune' },
  { label: 'Längelmäki', value: 'Längelmäki' },
  { label: 'Längelmäki', value: 'Längelmäki' },
  { label: 'Langeln', value: 'Langeln' },
  { label: 'Langelsheim', value: 'Langelsheim' },
  { label: 'Langen', value: 'Langen' },
  { label: 'Langen', value: 'Langen' },
  { label: 'Langen', value: 'Langen' },
  { label: 'Langenaltheim', value: 'Langenaltheim' },
  { label: 'Langenargen', value: 'Langenargen' },
  { label: 'Langenau', value: 'Langenau' },
  { label: 'Langenbach', value: 'Langenbach' },
  { label: 'Langenbach Bei Marienberg', value: 'Langenbach Bei Marienberg' },
  { label: 'Langenberg', value: 'Langenberg' },
  { label: 'Langenbernsdorf', value: 'Langenbernsdorf' },
  { label: 'Langenbogen', value: 'Langenbogen' },
  { label: 'Langenboom', value: 'Langenboom' },
  { label: 'Langenbrettach', value: 'Langenbrettach' },
  { label: 'Langenbruck', value: 'Langenbruck' },
  { label: 'Langenburg', value: 'Langenburg' },
  { label: 'Langenburg', value: 'Langenburg' },
  { label: 'Langendorf', value: 'Langendorf' },
  { label: 'Langendorf', value: 'Langendorf' },
  { label: 'Langenegg', value: 'Langenegg' },
  { label: 'Langeneichstädt', value: 'Langeneichstädt' },
  { label: 'Langenenslingen', value: 'Langenenslingen' },
  { label: 'Langenfeld', value: 'Langenfeld' },
  { label: 'Langenfeld', value: 'Langenfeld' },
  { label: 'Längenfeld', value: 'Längenfeld' },
  { label: 'Langenhagen', value: 'Langenhagen' },
  { label: 'Langenhahn', value: 'Langenhahn' },
  { label: 'Langenhorn', value: 'Langenhorn' },
  { label: 'Langenhorn', value: 'Langenhorn' },
  { label: 'Langenlebarn-Oberaigen', value: 'Langenlebarn-Oberaigen' },
  { label: 'Langenlois', value: 'Langenlois' },
  { label: 'Langenlonsheim', value: 'Langenlonsheim' },
  { label: 'Langenmosen', value: 'Langenmosen' },
  { label: 'Langenneufnach', value: 'Langenneufnach' },
  { label: 'Langenoord', value: 'Langenoord' },
  { label: 'Langenorla', value: 'Langenorla' },
  { label: 'Langenpreising', value: 'Langenpreising' },
  { label: 'Langenrohr', value: 'Langenrohr' },
  { label: 'Langenselbold', value: 'Langenselbold' },
  { label: 'Langensendelbach', value: 'Langensendelbach' },
  { label: 'Langenstein', value: 'Langenstein' },
  { label: 'Langenstein', value: 'Langenstein' },
  { label: 'Langenthal', value: 'Langenthal' },
  { label: 'Langenwang', value: 'Langenwang' },
  { label: 'Langenwetzendorf', value: 'Langenwetzendorf' },
  { label: 'Langenzenn', value: 'Langenzenn' },
  { label: 'Langenzersdorf', value: 'Langenzersdorf' },
  { label: 'Langeoog', value: 'Langeoog' },
  { label: 'Langepas', value: 'Langepas' },
  { label: 'Langereis', value: 'Langereis' },
  { label: 'Langerringen', value: 'Langerringen' },
  { label: 'Langerwehe', value: 'Langerwehe' },
  { label: 'Langeskov', value: 'Langeskov' },
  { label: 'Langewiesen', value: 'Langewiesen' },
  { label: 'Langfang', value: 'Langfang' },
  { label: 'Langfang Shi', value: 'Langfang Shi' },
  { label: 'Langford', value: 'Langford' },
  { label: 'Langford', value: 'Langford' },
  { label: 'Langford', value: 'Langford' },
  { label: 'Langfurth', value: 'Langfurth' },
  { label: 'Langgöns', value: 'Langgöns' },
  { label: 'Langham', value: 'Langham' },
  { label: 'Langham', value: 'Langham' },
  { label: 'Länghem', value: 'Länghem' },
  { label: 'Langhirano', value: 'Langhirano' },
  { label: 'Langhnaj', value: 'Langhnaj' },
  { label: 'Langho', value: 'Langho' },
  { label: 'Langholm', value: 'Langholm' },
  { label: 'Langholzfeld', value: 'Langholzfeld' },
  { label: 'Langhorne', value: 'Langhorne' },
  { label: 'Langhorne Manor', value: 'Langhorne Manor' },
  { label: 'Langiden', value: 'Langiden' },
  { label: 'Langkawi', value: 'Langkawi' },
  { label: 'Langlade', value: 'Langlade' },
  { label: 'Langlade County', value: 'Langlade County' },
  { label: 'Langley', value: 'Langley' },
  { label: 'Langley', value: 'Langley' },
  { label: 'Langley', value: 'Langley' },
  { label: 'Langley Green', value: 'Langley Green' },
  { label: 'Langley Park', value: 'Langley Park' },
  { label: 'Langley Park', value: 'Langley Park' },
  { label: 'Langlingen', value: 'Langlingen' },
  { label: 'Langnau', value: 'Langnau' },
  { label: 'Langnau / Langnau (Dorf)', value: 'Langnau / Langnau (Dorf)' },
  { label: 'Langnau / Vitaquartier', value: 'Langnau / Vitaquartier' },
  { label: 'Langnau Am Albis', value: 'Langnau Am Albis' },
  { label: 'Langoat', value: 'Langoat' },
  { label: 'Langob', value: 'Langob' },
  { label: 'Langogne', value: 'Langogne' },
  { label: 'Langoiran', value: 'Langoiran' },
  { label: 'Langon', value: 'Langon' },
  { label: 'Langon', value: 'Langon' },
  { label: 'Langonnet', value: 'Langonnet' },
  { label: 'Langosco', value: 'Langosco' },
  { label: 'Langpas', value: 'Langpas' },
  { label: 'Langpas', value: 'Langpas' },
  { label: 'Langport', value: 'Langport' },
  { label: 'Langquaid', value: 'Langquaid' },
  { label: 'Langreo', value: 'Langreo' },
  { label: 'Langres', value: 'Langres' },
  { label: 'Langrickenbach', value: 'Langrickenbach' },
  { label: 'Langrune-Sur-Mer', value: 'Langrune-Sur-Mer' },
  { label: 'Langsa', value: 'Langsa' },
  { label: 'Langschlag', value: 'Langschlag' },
  { label: 'Långsele', value: 'Långsele' },
  { label: 'Långsele', value: 'Långsele' },
  { label: 'Långshyttan', value: 'Långshyttan' },
  { label: 'Langstedt', value: 'Langstedt' },
  { label: 'Langston', value: 'Langston' },
  { label: 'Langsur', value: 'Langsur' },
  { label: 'Langtad', value: 'Langtad' },
  { label: 'Langtang', value: 'Langtang' },
  { label: 'Langtoft', value: 'Langtoft' },
  { label: 'Langtoucun', value: 'Langtoucun' },
  { label: 'Lañgub', value: 'Lañgub' },
  { label: 'Langue', value: 'Langue' },
  { label: 'Langueux', value: 'Langueux' },
  { label: 'Langui', value: 'Langui' },
  { label: 'Languidic', value: 'Languidic' },
  { label: 'Languilla', value: 'Languilla' },
  { label: 'Langula', value: 'Langula' },
  { label: 'Languyan', value: 'Languyan' },
  { label: 'Languyan', value: 'Languyan' },
  { label: 'Långvik', value: 'Långvik' },
  { label: 'Langwarrin', value: 'Langwarrin' },
  { label: 'Langwarrin South', value: 'Langwarrin South' },
  { label: 'Langwedel', value: 'Langwedel' },
  { label: 'Langwedel', value: 'Langwedel' },
  { label: 'Langweer', value: 'Langweer' },
  { label: 'Langweid', value: 'Langweid' },
  { label: 'Langxiang', value: 'Langxiang' },
  { label: 'Langzhong', value: 'Langzhong' },
  { label: 'Lanham', value: 'Lanham' },
  { label: 'Lanham-Seabrook', value: 'Lanham-Seabrook' },
  { label: 'Lani', value: 'Lani' },
  { label: 'Lanier County', value: 'Lanier County' },
  { label: 'Łanięta', value: 'Łanięta' },
  { label: 'Lanigan', value: 'Lanigan' },
  { label: 'Lanigay', value: 'Lanigay' },
  { label: 'Lanipao', value: 'Lanipao' },
  { label: 'Lanivtsi', value: 'Lanivtsi' },
  { label: 'Lanja', value: 'Lanja' },
  { label: 'Lanjaghbyur', value: 'Lanjaghbyur' },
  { label: 'Lanjarón', value: 'Lanjarón' },
  { label: 'Lankaran', value: 'Lankaran' },
  { label: 'Lankow', value: 'Lankow' },
  { label: 'Lankwitz', value: 'Lankwitz' },
  { label: 'Lanli', value: 'Lanli' },
  { label: 'Lanmeur', value: 'Lanmeur' },
  { label: 'Lanna', value: 'Lanna' },
  { label: 'Lanna', value: 'Lanna' },
  { label: 'Lannach', value: 'Lannach' },
  { label: 'Lannemezan', value: 'Lannemezan' },
  { label: 'Lannilis', value: 'Lannilis' },
  { label: 'Lannion', value: 'Lannion' },
  { label: 'Lannon', value: 'Lannon' },
  { label: 'Lannoy', value: 'Lannoy' },
  { label: 'Lanobre', value: 'Lanobre' },
  { label: 'Lanoraie', value: 'Lanoraie' },
  { label: 'Lanot', value: 'Lanot' },
  { label: 'Lanouaille', value: 'Lanouaille' },
  { label: 'Lanouée', value: 'Lanouée' },
  { label: 'Lanquetot', value: 'Lanquetot' },
  { label: 'Lanquín', value: 'Lanquín' },
  { label: 'Lanrivoaré', value: 'Lanrivoaré' },
  { label: 'Lanrodec', value: 'Lanrodec' },
  { label: 'Lans', value: 'Lans' },
  { label: 'Lansargues', value: 'Lansargues' },
  { label: 'Lansdale', value: 'Lansdale' },
  { label: 'Lansdowne', value: 'Lansdowne' },
  { label: 'Lansdowne', value: 'Lansdowne' },
  { label: 'Lansdowne', value: 'Lansdowne' },
  { label: 'Lansdowne', value: 'Lansdowne' },
  { label: 'Lanse', value: 'Lanse' },
  { label: 'Lanse Road', value: 'Lanse Road' },
  { label: 'Lans-En-Vercors', value: 'Lans-En-Vercors' },
  { label: 'Lansford', value: 'Lansford' },
  { label: 'Lansing', value: 'Lansing' },
  { label: 'Lansing', value: 'Lansing' },
  { label: 'Lansing', value: 'Lansing' },
  { label: 'Lansing', value: 'Lansing' },
  { label: 'Lanškroun', value: 'Lanškroun' },
  { label: 'Lanstrop', value: 'Lanstrop' },
  { label: 'Lansvale', value: 'Lansvale' },
  { label: 'Lanta', value: 'Lanta' },
  { label: 'Lantadilla', value: 'Lantadilla' },
  { label: 'Lantana', value: 'Lantana' },
  { label: 'Lantana', value: 'Lantana' },
  { label: 'Lantangan', value: 'Lantangan' },
  { label: 'Lantangan', value: 'Lantangan' },
  { label: 'Lantapan', value: 'Lantapan' },
  { label: 'Lantarón', value: 'Lantarón' },
  { label: 'Lanteira', value: 'Lanteira' },
  { label: 'Lantejuela La', value: 'Lantejuela La' },
  { label: 'Lantian', value: 'Lantian' },
  { label: 'Lantic', value: 'Lantic' },
  { label: 'Lanton', value: 'Lanton' },
  { label: 'Lantosque', value: 'Lantosque' },
  { label: 'Lantriac', value: 'Lantriac' },
  { label: 'Lantz', value: 'Lantz' },
  { label: 'Lantz', value: 'Lantz' },
  { label: 'Lanurile', value: 'Lanurile' },
  { label: 'Lanusei', value: 'Lanusei' },
  { label: 'Lanuvio', value: 'Lanuvio' },
  { label: 'Lanuza', value: 'Lanuza' },
  { label: 'Lanuza', value: 'Lanuza' },
  { label: 'Lanvallay', value: 'Lanvallay' },
  { label: 'Lanvario', value: 'Lanvario' },
  { label: 'Lanvénégen', value: 'Lanvénégen' },
  { label: 'Lanvéoc', value: 'Lanvéoc' },
  { label: 'Lanvollon', value: 'Lanvollon' },
  { label: 'Lanxi', value: 'Lanxi' },
  { label: 'Lanxi', value: 'Lanxi' },
  { label: 'Lány', value: 'Lány' },
  { label: 'Lánycsók', value: 'Lánycsók' },
  { label: 'Lanzada', value: 'Lanzada' },
  { label: 'Lanzahíta', value: 'Lanzahíta' },
  { label: 'Lanzara', value: 'Lanzara' },
  { label: 'Lanzendorf', value: 'Lanzendorf' },
  { label: 'Lanzenkirchen', value: 'Lanzenkirchen' },
  { label: 'Lanžhot', value: 'Lanžhot' },
  { label: 'Lanzhou', value: 'Lanzhou' },
  { label: 'Lanzo Dintelvi', value: 'Lanzo Dintelvi' },
  { label: 'Lanzo Torinese', value: 'Lanzo Torinese' },
  { label: 'Lanzuela', value: 'Lanzuela' },
  { label: 'Lao', value: 'Lao' },
  { label: 'Lao', value: 'Lao' },
  { label: 'Lào Cai', value: 'Lào Cai' },
  { label: 'Lao Chải', value: 'Lao Chải' },
  { label: 'Laoac', value: 'Laoac' },
  { label: 'Laoag', value: 'Laoag' },
  { label: 'Laoag', value: 'Laoag' },
  { label: 'Laoang', value: 'Laoang' },
  { label: 'Laoang', value: 'Laoang' },
  { label: 'Laocheng', value: 'Laocheng' },
  { label: 'Laohekou', value: 'Laohekou' },
  { label: 'Laois', value: 'Laois' },
  { label: 'Laojunmiao', value: 'Laojunmiao' },
  { label: 'Laon', value: 'Laon' },
  { label: 'Lapa', value: 'Lapa' },
  { label: 'Lapa', value: 'Lapa' },
  { label: 'Lapa Dos Dinheiros', value: 'Lapa Dos Dinheiros' },
  { label: 'Lapa La', value: 'Lapa La' },
  { label: 'Lapachito', value: 'Lapachito' },
  { label: 'Lapai', value: 'Lapai' },
  { label: 'Lapalisse', value: 'Lapalisse' },
  { label: 'Lapalud', value: 'Lapalud' },
  { label: 'Łapanów', value: 'Łapanów' },
  { label: 'Lapão', value: 'Lapão' },
  { label: 'Laparay', value: 'Laparay' },
  { label: 'Lápas', value: 'Lápas' },
  { label: 'Lapase', value: 'Lapase' },
  { label: 'Lapaz', value: 'Lapaz' },
  { label: 'Łapczyca', value: 'Łapczyca' },
  { label: 'Lapedona', value: 'Lapedona' },
  { label: 'Lapeer', value: 'Lapeer' },
  { label: 'Lapeer County', value: 'Lapeer County' },
  { label: 'Lapel', value: 'Lapel' },
  { label: 'Laperdiguera', value: 'Laperdiguera' },
  { label: 'Lapeyrouse-Fossat', value: 'Lapeyrouse-Fossat' },
  { label: 'Lapi', value: 'Lapi' },
  { label: 'Lapi', value: 'Lapi' },
  { label: 'Lapid', value: 'Lapid' },
  { label: 'Lapinig', value: 'Lapinig' },
  { label: 'Lapinig', value: 'Lapinig' },
  { label: 'Lapinigan', value: 'Lapinigan' },
  { label: 'Lapinigan', value: 'Lapinigan' },
  { label: 'Lapining', value: 'Lapining' },
  { label: 'Lapinjärvi', value: 'Lapinjärvi' },
  { label: 'Lapio', value: 'Lapio' },
  { label: 'Lápithos', value: 'Lápithos' },
  { label: 'Laplace', value: 'Laplace' },
  { label: 'Laplume', value: 'Laplume' },
  { label: 'Lapoblación', value: 'Lapoblación' },
  { label: 'Lapolapo', value: 'Lapolapo' },
  { label: 'Laporte', value: 'Laporte' },
  { label: 'Laporte', value: 'Laporte' },
  { label: 'Laporte', value: 'Laporte' },
  { label: 'Laporte County', value: 'Laporte County' },
  { label: 'Lapoș', value: 'Lapoș' },
  { label: 'Lăpoș', value: 'Lăpoș' },
  { label: 'Lapoutroie', value: 'Lapoutroie' },
  { label: 'Lapouyee', value: 'Lapouyee' },
  { label: 'Lapovo', value: 'Lapovo' },
  { label: 'Lappajärvi', value: 'Lappajärvi' },
  { label: 'Lappano', value: 'Lappano' },
  { label: 'Lappe', value: 'Lappe' },
  { label: 'Lappeenranta', value: 'Lappeenranta' },
  { label: 'Lappersdorf', value: 'Lappersdorf' },
  { label: 'Lappi', value: 'Lappi' },
  { label: 'Lapseki', value: 'Lapseki' },
  { label: 'Łapsze Niżne', value: 'Łapsze Niżne' },
  { label: 'Lapte', value: 'Lapte' },
  { label: 'Lapua', value: 'Lapua' },
  { label: 'Lapuan', value: 'Lapuan' },
  { label: 'Lapuan', value: 'Lapuan' },
  { label: 'Lapuan', value: 'Lapuan' },
  { label: 'Lapuebla De Labarca', value: 'Lapuebla De Labarca' },
  { label: 'Lăpugiu De Jos', value: 'Lăpugiu De Jos' },
  { label: 'Lapugnoy', value: 'Lapugnoy' },
  { label: 'Lapu-Lapu City', value: 'Lapu-Lapu City' },
  { label: 'Lăpuş', value: 'Lăpuş' },
  { label: 'Lăpușel', value: 'Lăpușel' },
  { label: 'Lăpușna', value: 'Lăpușna' },
  { label: 'Lăpuşnicel', value: 'Lăpuşnicel' },
  { label: 'Lăpuşnicu Mare', value: 'Lăpuşnicu Mare' },
  { label: 'Lapuyan', value: 'Lapuyan' },
  { label: 'Lapuz', value: 'Lapuz' },
  { label: 'Lapuz', value: 'Lapuz' },
  { label: 'Lapwai', value: 'Lapwai' },
  { label: 'Łapy', value: 'Łapy' },
  { label: 'Laquila', value: 'Laquila' },
  { label: 'Lar', value: 'Lar' },
  { label: 'Lar', value: 'Lar' },
  { label: 'Lara', value: 'Lara' },
  { label: 'Laracha A', value: 'Laracha A' },
  { label: 'Larache', value: 'Larache' },
  { label: 'Laragne-Montéglin', value: 'Laragne-Montéglin' },
  { label: 'Larajasse', value: 'Larajasse' },
  { label: 'Laramie', value: 'Laramie' },
  { label: 'Laramie County', value: 'Laramie County' },
  { label: 'Laranja Da Terra', value: 'Laranja Da Terra' },
  { label: 'Laranjal', value: 'Laranjal' },
  { label: 'Laranjal', value: 'Laranjal' },
  { label: 'Laranjal Do Jari', value: 'Laranjal Do Jari' },
  { label: 'Laranjal Paulista', value: 'Laranjal Paulista' },
  { label: 'Laranjeira', value: 'Laranjeira' },
  { label: 'Laranjeiras', value: 'Laranjeiras' },
  { label: 'Laranjeiras Do Sul', value: 'Laranjeiras Do Sul' },
  { label: 'Laranjeiro', value: 'Laranjeiro' },
  { label: 'Larap', value: 'Larap' },
  { label: 'Larap', value: 'Larap' },
  { label: 'Larap', value: 'Larap' },
  { label: 'Larapinta', value: 'Larapinta' },
  { label: 'Larbaâ', value: 'Larbaâ' },
  { label: 'Larbert', value: 'Larbert' },
  { label: 'Larçay', value: 'Larçay' },
  { label: 'Larce', value: 'Larce' },
  { label: 'Larch Way', value: 'Larch Way' },
  { label: 'Larchamp', value: 'Larchamp' },
  { label: 'Larche', value: 'Larche' },
  { label: 'Larchmont', value: 'Larchmont' },
  { label: 'Larciano', value: 'Larciano' },
  { label: 'Lardaro', value: 'Lardaro' },
  { label: 'Larderia', value: 'Larderia' },
  { label: 'Lardero', value: 'Lardero' },
  { label: 'Lardirago', value: 'Lardirago' },
  { label: 'Lardjem', value: 'Lardjem' },
  { label: 'Lárdos', value: 'Lárdos' },
  { label: 'Lardy', value: 'Lardy' },
  { label: 'Laredo', value: 'Laredo' },
  { label: 'Laredo', value: 'Laredo' },
  { label: 'Laredo', value: 'Laredo' },
  { label: 'Laren', value: 'Laren' },
  { label: 'Laren', value: 'Laren' },
  { label: 'Larequille', value: 'Larequille' },
  { label: 'Lares', value: 'Lares' },
  { label: 'Lares', value: 'Lares' },
  { label: 'Larestan', value: 'Larestan' },
  { label: 'Larga', value: 'Larga' },
  { label: 'Larga', value: 'Larga' },
  { label: 'Larga', value: 'Larga' },
  { label: 'Largentière', value: 'Largentière' },
  { label: 'Largo', value: 'Largo' },
  { label: 'Largo', value: 'Largo' },
  { label: 'Largo', value: 'Largo' },
  { label: 'Largs', value: 'Largs' },
  { label: 'Largs', value: 'Largs' },
  { label: 'Largs Bay', value: 'Largs Bay' },
  { label: 'Largs North', value: 'Largs North' },
  { label: 'Largu', value: 'Largu' },
  { label: 'Lari', value: 'Lari' },
  { label: 'Lariano', value: 'Lariano' },
  { label: 'Larimer County', value: 'Larimer County' },
  { label: 'Larimore', value: 'Larimore' },
  { label: 'Larino', value: 'Larino' },
  { label: 'Larion Alto', value: 'Larion Alto' },
  { label: 'Larion Alto', value: 'Larion Alto' },
  { label: 'Lark Hill', value: 'Lark Hill' },
  { label: 'Larkana', value: 'Larkana' },
  { label: 'Larkfield', value: 'Larkfield' },
  { label: 'Larkfield-Wikiup', value: 'Larkfield-Wikiup' },
  { label: 'Larkhall', value: 'Larkhall' },
  { label: 'Larkird', value: 'Larkird' },
  { label: 'Larkollen', value: 'Larkollen' },
  { label: 'Larkspur', value: 'Larkspur' },
  { label: 'Larkspur', value: 'Larkspur' },
  { label: 'Larksville', value: 'Larksville' },
  { label: 'Larmor-Baden', value: 'Larmor-Baden' },
  { label: 'Larmor-Plage', value: 'Larmor-Plage' },
  { label: 'Larnaca', value: 'Larnaca' },
  { label: 'Larne', value: 'Larne' },
  { label: 'Larned', value: 'Larned' },
  { label: 'Laroche-Saint-Cydroine', value: 'Laroche-Saint-Cydroine' },
  { label: 'Larochette', value: 'Larochette' },
  { label: 'Laroque', value: 'Laroque' },
  { label: 'Laroquebrou', value: 'Laroquebrou' },
  { label: 'Laroque-D’Olmes', value: 'Laroque-D’Olmes' },
  { label: 'Laroque-Timbaut', value: 'Laroque-Timbaut' },
  { label: 'Larose', value: 'Larose' },
  { label: 'Larouco', value: 'Larouco' },
  { label: 'Laroya', value: 'Laroya' },
  { label: 'Larrabetzu', value: 'Larrabetzu' },
  { label: 'Larraga', value: 'Larraga' },
  { label: 'Larráinzar', value: 'Larráinzar' },
  { label: 'Larrakeyah', value: 'Larrakeyah' },
  { label: 'Larrañaga', value: 'Larrañaga' },
  { label: 'Larraona', value: 'Larraona' },
  { label: 'Larraul', value: 'Larraul' },
  { label: 'Larraun', value: 'Larraun' },
  { label: 'Larressore', value: 'Larressore' },
  { label: 'Larreynaga', value: 'Larreynaga' },
  { label: 'Larringes', value: 'Larringes' },
  { label: 'Larrodrigo', value: 'Larrodrigo' },
  { label: 'Larroque', value: 'Larroque' },
  { label: 'Larsmo', value: 'Larsmo' },
  { label: 'Larsnes', value: 'Larsnes' },
  { label: 'Larue County', value: 'Larue County' },
  { label: 'Laruns', value: 'Laruns' },
  { label: 'Laruscade', value: 'Laruscade' },
  { label: 'Larva', value: 'Larva' },
  { label: 'Larvik', value: 'Larvik' },
  { label: 'Las Acacias', value: 'Las Acacias' },
  { label: 'Las Acequias', value: 'Las Acequias' },
  { label: 'Las Aguamitas', value: 'Las Aguamitas' },
  { label: 'Las Alfalfas', value: 'Las Alfalfas' },
  { label: 'Las Amapolas', value: 'Las Amapolas' },
  { label: 'Las Amarillas', value: 'Las Amarillas' },
  { label: 'Las Amilpas', value: 'Las Amilpas' },
  { label: 'Las Animas', value: 'Las Animas' },
  { label: 'Las Ánimas', value: 'Las Ánimas' },
  { label: 'Las Ánimas', value: 'Las Ánimas' },
  { label: 'Las Ánimas', value: 'Las Ánimas' },
  { label: 'Las Ánimas', value: 'Las Ánimas' },
  { label: 'Las Animas County', value: 'Las Animas County' },
  { label: 'Las Arenitas', value: 'Las Arenitas' },
  { label: 'Las Armas', value: 'Las Armas' },
  { label: 'Las Asturias Fraccionamiento', value: 'Las Asturias Fraccionamiento' },
  { label: 'Las Azules', value: 'Las Azules' },
  { label: 'Las Barretas', value: 'Las Barretas' },
  { label: 'Las Barrillas', value: 'Las Barrillas' },
  { label: 'Las Bocas', value: 'Las Bocas' },
  { label: 'Las Breñas', value: 'Las Breñas' },
  { label: 'Las Brisas', value: 'Las Brisas' },
  { label: 'Las Brisas', value: 'Las Brisas' },
  { label: 'Las Brisas', value: 'Las Brisas' },
  { label: 'Las Brisas', value: 'Las Brisas' },
  { label: 'Las Cabras', value: 'Las Cabras' },
  { label: 'Las Calaveras', value: 'Las Calaveras' },
  { label: 'Las Cañadas', value: 'Las Cañadas' },
  { label: 'Las Cañadas', value: 'Las Cañadas' },
  { label: 'Las Cañas', value: 'Las Cañas' },
  { label: 'Las Cañas', value: 'Las Cañas' },
  { label: 'Las Canoas', value: 'Las Canoas' },
  { label: 'Las Carretas', value: 'Las Carretas' },
  { label: 'Las Catarinas', value: 'Las Catarinas' },
  { label: 'Las Charcas', value: 'Las Charcas' },
  { label: 'Las Chicharras', value: 'Las Chicharras' },
  { label: 'Las Choapas', value: 'Las Choapas' },
  { label: 'Las Cieneguitas', value: 'Las Cieneguitas' },
  { label: 'Las Colinas', value: 'Las Colinas' },
  { label: 'Las Colonias De Hidalgo', value: 'Las Colonias De Hidalgo' },
  { label: 'Las Coloradas', value: 'Las Coloradas' },
  { label: 'Las Coloradas', value: 'Las Coloradas' },
  { label: 'Las Compuertas', value: 'Las Compuertas' },
  { label: 'Las Compuertas', value: 'Las Compuertas' },
  { label: 'Las Condes', value: 'Las Condes' },
  { label: 'Las Cruces', value: 'Las Cruces' },
  { label: 'Las Cruces', value: 'Las Cruces' },
  { label: 'Las Cruces', value: 'Las Cruces' },
  { label: 'Las Cruces', value: 'Las Cruces' },
  { label: 'Las Cuevas', value: 'Las Cuevas' },
  { label: 'Las Cumbres', value: 'Las Cumbres' },
  { label: 'Las Delicias', value: 'Las Delicias' },
  { label: 'Las Delicias', value: 'Las Delicias' },
  { label: 'Las Esperanzas (El Ranchito)', value: 'Las Esperanzas (El Ranchito)' },
  { label: 'Las Flechas', value: 'Las Flechas' },
  { label: 'Las Flores', value: 'Las Flores' },
  { label: 'Las Flores', value: 'Las Flores' },
  { label: 'Las Garcitas', value: 'Las Garcitas' },
  { label: 'Las Golondrinas [Fraccionamiento]', value: 'Las Golondrinas [Fraccionamiento]' },
  { label: 'Las Grullas Margen Derecha', value: 'Las Grullas Margen Derecha' },
  { label: 'Las Grullas Margen Izquierda', value: 'Las Grullas Margen Izquierda' },
  { label: 'Las Guabas', value: 'Las Guabas' },
  { label: 'Las Guabas', value: 'Las Guabas' },
  { label: 'Las Guáranas', value: 'Las Guáranas' },
  { label: 'Las Guías Abajo', value: 'Las Guías Abajo' },
  { label: 'Las Heras', value: 'Las Heras' },
  { label: 'Las Heras', value: 'Las Heras' },
  { label: 'Las Higueras', value: 'Las Higueras' },
  { label: 'Las Higueras', value: 'Las Higueras' },
  { label: 'Las Higuerillas', value: 'Las Higuerillas' },
  { label: 'Las Huacas', value: 'Las Huacas' },
  { label: 'Las Huacas Del Quije', value: 'Las Huacas Del Quije' },
  { label: 'Las Huertas', value: 'Las Huertas' },
  { label: 'Las Huertas Tercera Sección', value: 'Las Huertas Tercera Sección' },
  { label: 'Las Jarretaderas', value: 'Las Jarretaderas' },
  { label: 'Las Juntas', value: 'Las Juntas' },
  { label: 'Las Junturas', value: 'Las Junturas' },
  { label: 'Las Khorey', value: 'Las Khorey' },
  { label: 'Las Lajas', value: 'Las Lajas' },
  { label: 'Las Lajas', value: 'Las Lajas' },
  { label: 'Las Lajas', value: 'Las Lajas' },
  { label: 'Las Lajas', value: 'Las Lajas' },
  { label: 'Las Lajas', value: 'Las Lajas' },
  { label: 'Las Lajitas', value: 'Las Lajitas' },
  { label: 'Las Lajitas', value: 'Las Lajitas' },
  { label: 'Las Letras', value: 'Las Letras' },
  { label: 'Las Liebres', value: 'Las Liebres' },
  { label: 'Las Lomas', value: 'Las Lomas' },
  { label: 'Las Lomas', value: 'Las Lomas' },
  { label: 'Las Lomas', value: 'Las Lomas' },
  { label: 'Las Lomas', value: 'Las Lomas' },
  { label: 'Las Lomas', value: 'Las Lomas' },
  { label: 'Las Lomas', value: 'Las Lomas' },
  { label: 'Las Lomas', value: 'Las Lomas' },
  { label: 'Las Lomas De Tacamichapan', value: 'Las Lomas De Tacamichapan' },
  { label: 'Las Lomitas', value: 'Las Lomitas' },
  { label: 'Las Manzanas', value: 'Las Manzanas' },
  { label: 'Las Maravillas', value: 'Las Maravillas' },
  { label: 'Las Maravillas', value: 'Las Maravillas' },
  { label: 'Las Margaritas', value: 'Las Margaritas' },
  { label: 'Las Margaritas', value: 'Las Margaritas' },
  { label: 'Las Margaritas', value: 'Las Margaritas' },
  { label: 'Las Margaritas', value: 'Las Margaritas' },
  { label: 'Las Margaritas', value: 'Las Margaritas' },
  { label: 'Las Marias', value: 'Las Marias' },
  { label: 'Las Marias', value: 'Las Marias' },
  { label: 'Las Marías', value: 'Las Marías' },
  { label: 'Las Marías Municipio', value: 'Las Marías Municipio' },
  { label: 'Las Masas (La Luz Masas)', value: 'Las Masas (La Luz Masas)' },
  { label: 'Las Matas', value: 'Las Matas' },
  { label: 'Las Matas De Farfán', value: 'Las Matas De Farfán' },
  { label: 'Las Matas De Santa Cruz', value: 'Las Matas De Santa Cruz' },
  { label: 'Las Mercedes [Agropecuaria]', value: 'Las Mercedes [Agropecuaria]' },
  { label: 'Las Mesas', value: 'Las Mesas' },
  { label: 'Las Mesas De San Juan', value: 'Las Mesas De San Juan' },
  { label: 'Las Mieleras', value: 'Las Mieleras' },
  { label: 'Las Minas', value: 'Las Minas' },
  { label: 'Las Minas', value: 'Las Minas' },
  { label: 'Las Minas', value: 'Las Minas' },
  { label: 'Las Moras', value: 'Las Moras' },
  { label: 'Las Navas', value: 'Las Navas' },
  { label: 'Las Navas', value: 'Las Navas' },
  { label: 'Las Navas Del Marqués', value: 'Las Navas Del Marqués' },
  { label: 'Las Nieves', value: 'Las Nieves' },
  { label: 'Las Nieves', value: 'Las Nieves' },
  { label: 'Las Norias De Ojocaliente', value: 'Las Norias De Ojocaliente' },
  { label: 'Las Ochenta', value: 'Las Ochenta' },
  { label: 'Las Ollas', value: 'Las Ollas' },
  { label: 'Las Ollas', value: 'Las Ollas' },
  { label: 'Las Ovejas', value: 'Las Ovejas' },
  { label: 'Las Palmas', value: 'Las Palmas' },
  { label: 'Las Palmas', value: 'Las Palmas' },
  { label: 'Las Palmas', value: 'Las Palmas' },
  { label: 'Las Palmas', value: 'Las Palmas' },
  { label: 'Las Palmas De Gran Canaria', value: 'Las Palmas De Gran Canaria' },
  { label: 'Las Palmas De Gran Canaria', value: 'Las Palmas De Gran Canaria' },
  { label: 'Las Palmas Ii', value: 'Las Palmas Ii' },
  { label: 'Las Palmitas', value: 'Las Palmitas' },
  { label: 'Las Palomas', value: 'Las Palomas' },
  { label: 'Las Parejas', value: 'Las Parejas' },
  { label: 'Las Peñas', value: 'Las Peñas' },
  { label: 'Las Perdices', value: 'Las Perdices' },
  { label: 'Las Piedras', value: 'Las Piedras' },
  { label: 'Las Piedras', value: 'Las Piedras' },
  { label: 'Las Piedras', value: 'Las Piedras' },
  { label: 'Las Piedras', value: 'Las Piedras' },
  { label: 'Las Piedras Municipio', value: 'Las Piedras Municipio' },
  { label: 'Las Pilas', value: 'Las Pilas' },
  { label: 'Las Piñas', value: 'Las Piñas' },
  { label: 'Las Pintas', value: 'Las Pintas' },
  { label: 'Las Pintas De Arriba', value: 'Las Pintas De Arriba' },
  { label: 'Las Pintitas', value: 'Las Pintitas' },
  { label: 'Las Plassas', value: 'Las Plassas' },
  { label: 'Las Plazuelas', value: 'Las Plazuelas' },
  { label: 'Las Plumas', value: 'Las Plumas' },
  { label: 'Las Praderas', value: 'Las Praderas' },
  { label: 'Las Primaveras [Invernadero]', value: 'Las Primaveras [Invernadero]' },
  { label: 'Las Quemazones', value: 'Las Quemazones' },
  { label: 'Las Quintas Fronterizas', value: 'Las Quintas Fronterizas' },
  { label: 'Las Quintas Fronterizas Colonia', value: 'Las Quintas Fronterizas Colonia' },
  { label: 'Las Ranas', value: 'Las Ranas' },
  { label: 'Las Rosas', value: 'Las Rosas' },
  { label: 'Las Rosas', value: 'Las Rosas' },
  { label: 'Las Rosas', value: 'Las Rosas' },
  { label: 'Las Rosas', value: 'Las Rosas' },
  { label: 'Las Rozas De Madrid', value: 'Las Rozas De Madrid' },
  { label: 'Las Sabanas', value: 'Las Sabanas' },
  { label: 'Las Sabanas', value: 'Las Sabanas' },
  { label: 'Las Sabanetas', value: 'Las Sabanetas' },
  { label: 'Las Sabinas', value: 'Las Sabinas' },
  { label: 'Las Salinas', value: 'Las Salinas' },
  { label: 'Las Tablas', value: 'Las Tablas' },
  { label: 'Las Tablas', value: 'Las Tablas' },
  { label: 'Las Taponas', value: 'Las Taponas' },
  { label: 'Las Tazas', value: 'Las Tazas' },
  { label: 'Las Tejeras', value: 'Las Tejeras' },
  { label: 'Las Tejerías', value: 'Las Tejerías' },
  { label: 'Las Terrenas', value: 'Las Terrenas' },
  { label: 'Las Torres', value: 'Las Torres' },
  { label: 'Las Torres De Cotillas', value: 'Las Torres De Cotillas' },
  { label: 'Las Torres De Guadalupe', value: 'Las Torres De Guadalupe' },
  { label: 'Las Toscas', value: 'Las Toscas' },
  { label: 'Las Toscas', value: 'Las Toscas' },
  { label: 'Las Trancas', value: 'Las Trancas' },
  { label: 'Las Trancas', value: 'Las Trancas' },
  { label: 'Las Trojas', value: 'Las Trojas' },
  { label: 'Las Trojes', value: 'Las Trojes' },
  { label: 'Las Tunas', value: 'Las Tunas' },
  { label: 'Las Tunas', value: 'Las Tunas' },
  { label: 'Las Uvas', value: 'Las Uvas' },
  { label: 'Las Varas', value: 'Las Varas' },
  { label: 'Las Varas', value: 'Las Varas' },
  { label: 'Las Varas', value: 'Las Varas' },
  { label: 'Las Varas (Estación Babícora)', value: 'Las Varas (Estación Babícora)' },
  { label: 'Las Varillas', value: 'Las Varillas' },
  { label: 'Las Vegas', value: 'Las Vegas' },
  { label: 'Las Vegas', value: 'Las Vegas' },
  { label: 'Las Vegas', value: 'Las Vegas' },
  { label: 'Las Vegas', value: 'Las Vegas' },
  { label: 'Las Vegas Santa Barbara', value: 'Las Vegas Santa Barbara' },
  { label: 'Las Veredas', value: 'Las Veredas' },
  { label: 'Las Vigas', value: 'Las Vigas' },
  { label: 'Las Vigas De Ramírez', value: 'Las Vigas De Ramírez' },
  { label: 'Las Yerbitas [Aserradero]', value: 'Las Yerbitas [Aserradero]' },
  { label: 'Lasa', value: 'Lasa' },
  { label: 'Lasalgaon', value: 'Lasalgaon' },
  { label: 'Lasalle', value: 'Lasalle' },
  { label: 'Lasalle County', value: 'Lasalle County' },
  { label: 'Lasam', value: 'Lasam' },
  { label: 'Lasam', value: 'Lasam' },
  { label: 'Lasang', value: 'Lasang' },
  { label: 'Lasang', value: 'Lasang' },
  { label: 'Lasang', value: 'Lasang' },
  { label: 'Lasara', value: 'Lasara' },
  { label: 'Lasarte-Oria', value: 'Lasarte-Oria' },
  { label: 'Lasbek', value: 'Lasbek' },
  { label: 'Lasbela District', value: 'Lasbela District' },
  { label: 'Lascahobas', value: 'Lascahobas' },
  { label: 'Lascano', value: 'Lascano' },
  { label: 'Lascari', value: 'Lascari' },
  { label: 'Lascellas-Ponzano', value: 'Lascellas-Ponzano' },
  { label: 'Lascension-De-Notre-Seigneur', value: 'Lascension-De-Notre-Seigneur' },
  { label: 'Lăschia', value: 'Lăschia' },
  { label: 'Lascuarre', value: 'Lascuarre' },
  { label: 'Lăscud', value: 'Lăscud' },
  { label: 'Lasek', value: 'Lasek' },
  { label: 'Lasem', value: 'Lasem' },
  { label: 'Lases', value: 'Lases' },
  { label: 'Lashio', value: 'Lashio' },
  { label: 'Lashkar Gāh', value: 'Lashkar Gāh' },
  { label: 'Lashma', value: 'Lashma' },
  { label: 'Lasht-E Nesha', value: 'Lasht-E Nesha' },
  { label: 'Łasin', value: 'Łasin' },
  { label: 'Lasino', value: 'Lasino' },
  { label: 'Łask', value: 'Łask' },
  { label: 'Łaskarzew', value: 'Łaskarzew' },
  { label: 'Laško', value: 'Laško' },
  { label: 'Laskowa', value: 'Laskowa' },
  { label: 'Lasky', value: 'Lasky' },
  { label: 'Laslău Mare', value: 'Laslău Mare' },
  { label: 'Laslău Mic', value: 'Laslău Mic' },
  { label: 'Laslea', value: 'Laslea' },
  { label: 'Laslovo', value: 'Laslovo' },
  { label: 'Lasnigo', value: 'Lasnigo' },
  { label: 'Laspaúles', value: 'Laspaúles' },
  { label: 'Laspuña', value: 'Laspuña' },
  { label: 'Lassan', value: 'Lassan' },
  { label: 'Lassance', value: 'Lassance' },
  { label: 'Lassay-Les-Châteaux', value: 'Lassay-Les-Châteaux' },
  { label: 'Lassee', value: 'Lassee' },
  { label: 'Lassen County', value: 'Lassen County' },
  { label: 'Lasseube', value: 'Lasseube' },
  { label: 'Lassigny', value: 'Lassigny' },
  { label: 'Lassnitzhöhe', value: 'Lassnitzhöhe' },
  { label: 'Lassolaz', value: 'Lassolaz' },
  { label: 'Lassomption', value: 'Lassomption' },
  { label: 'Lassy', value: 'Lassy' },
  { label: 'Lastebasse', value: 'Lastebasse' },
  { label: 'Lastic Hill', value: 'Lastic Hill' },
  { label: 'Lastoursville', value: 'Lastoursville' },
  { label: 'Lastovo', value: 'Lastovo' },
  { label: 'Lastra A Signa', value: 'Lastra A Signa' },
  { label: 'Lastras De Cuéllar', value: 'Lastras De Cuéllar' },
  { label: 'Lastras Del Pozo', value: 'Lastras Del Pozo' },
  { label: 'Lastrilla La', value: 'Lastrilla La' },
  { label: 'Lastro', value: 'Lastro' },
  { label: 'Lastrup', value: 'Lastrup' },
  { label: 'Łaszczów', value: 'Łaszczów' },
  { label: 'Laszki', value: 'Laszki' },
  { label: 'Lat Krabang', value: 'Lat Krabang' },
  { label: 'Lat Phrao', value: 'Lat Phrao' },
  { label: 'Lat Yao', value: 'Lat Yao' },
  { label: 'Lata', value: 'Lata' },
  { label: 'Latacunga', value: 'Latacunga' },
  { label: 'Latah County', value: 'Latah County' },
  { label: 'Latakia', value: 'Latakia' },
  { label: 'Latakia District', value: 'Latakia District' },
  { label: 'Latchingdon And Snoreham', value: 'Latchingdon And Snoreham' },
  { label: 'Latehar', value: 'Latehar' },
  { label: 'Latera', value: 'Latera' },
  { label: 'Laterina', value: 'Laterina' },
  { label: 'Laterza', value: 'Laterza' },
  { label: 'Latgah', value: 'Latgah' },
  { label: 'Latham', value: 'Latham' },
  { label: 'Latham', value: 'Latham' },
  { label: 'Lathen', value: 'Lathen' },
  { label: 'Lathi', value: 'Lathi' },
  { label: 'Lathlain', value: 'Lathlain' },
  { label: 'Lathrop', value: 'Lathrop' },
  { label: 'Lathrop', value: 'Lathrop' },
  { label: 'Lathrup Village', value: 'Lathrup Village' },
  { label: 'Lathus-Saint-Rémy', value: 'Lathus-Saint-Rémy' },
  { label: 'Latiano', value: 'Latiano' },
  { label: 'Latifi', value: 'Latifi' },
  { label: 'Latillé', value: 'Latillé' },
  { label: 'Latimer', value: 'Latimer' },
  { label: 'Latimer County', value: 'Latimer County' },
  { label: 'Latina', value: 'Latina' },
  { label: 'Latina', value: 'Latina' },
  { label: 'Latina Scalo', value: 'Latina Scalo' },
  { label: 'Latisana', value: 'Latisana' },
  { label: 'Latnaya', value: 'Latnaya' },
  { label: 'Laton', value: 'Laton' },
  { label: 'Latour-Bas-Elne', value: 'Latour-Bas-Elne' },
  { label: 'Latowicz', value: 'Latowicz' },
  { label: 'Latresne', value: 'Latresne' },
  { label: 'Latrobe', value: 'Latrobe' },
  { label: 'Latrobe', value: 'Latrobe' },
  { label: 'Latrobe', value: 'Latrobe' },
  { label: 'Latronico', value: 'Latronico' },
  { label: 'Latta', value: 'Latta' },
  { label: 'Lattarico', value: 'Lattarico' },
  { label: 'Lattes', value: 'Lattes' },
  { label: 'Lattingtown', value: 'Lattingtown' },
  { label: 'Latung', value: 'Latung' },
  { label: 'Latung', value: 'Latung' },
  { label: 'Latur', value: 'Latur' },
  { label: 'Lau', value: 'Lau' },
  { label: 'Lau Province', value: 'Lau Province' },
  { label: 'Laubach', value: 'Laubach' },
  { label: 'Laubach', value: 'Laubach' },
  { label: 'Lauben', value: 'Lauben' },
  { label: 'Laucha', value: 'Laucha' },
  { label: 'Lauchhammer', value: 'Lauchhammer' },
  { label: 'Lauchheim', value: 'Lauchheim' },
  { label: 'Lauchringen', value: 'Lauchringen' },
  { label: 'Lauchröden', value: 'Lauchröden' },
  { label: 'Lauco', value: 'Lauco' },
  { label: 'Lauda-Königshofen', value: 'Lauda-Königshofen' },
  { label: 'Laudenbach', value: 'Laudenbach' },
  { label: 'Laudenbach', value: 'Laudenbach' },
  { label: 'Lauder', value: 'Lauder' },
  { label: 'Lauderdale', value: 'Lauderdale' },
  { label: 'Lauderdale', value: 'Lauderdale' },
  { label: 'Lauderdale County', value: 'Lauderdale County' },
  { label: 'Lauderdale County', value: 'Lauderdale County' },
  { label: 'Lauderdale County', value: 'Lauderdale County' },
  { label: 'Lauderdale Lakes', value: 'Lauderdale Lakes' },
  { label: 'Lauderdale-By-The-Sea', value: 'Lauderdale-By-The-Sea' },
  { label: 'Lauderhill', value: 'Lauderhill' },
  { label: 'Laudio/Llodio', value: 'Laudio/Llodio' },
  { label: 'Laudun-Lardoise', value: 'Laudun-Lardoise' },
  { label: 'Lauenau', value: 'Lauenau' },
  { label: 'Lauenbrück', value: 'Lauenbrück' },
  { label: 'Lauenburg', value: 'Lauenburg' },
  { label: 'Lauenhagen', value: 'Lauenhagen' },
  { label: 'Lauf', value: 'Lauf' },
  { label: 'Lauf An Der Pegnitz', value: 'Lauf An Der Pegnitz' },
  { label: 'Laufach', value: 'Laufach' },
  { label: 'Laufdorf', value: 'Laufdorf' },
  { label: 'Läufelfingen', value: 'Läufelfingen' },
  { label: 'Laufen', value: 'Laufen' },
  { label: 'Laufen', value: 'Laufen' },
  { label: 'Laufenburg', value: 'Laufenburg' },
  { label: 'Laufenburg', value: 'Laufenburg' },
  { label: 'Lauffen Am Neckar', value: 'Lauffen Am Neckar' },
  { label: 'Lauffohr (Brugg)', value: 'Lauffohr (Brugg)' },
  { label: 'Laug', value: 'Laug' },
  { label: 'Laug', value: 'Laug' },
  { label: 'Laugar', value: 'Laugar' },
  { label: 'Laughlin', value: 'Laughlin' },
  { label: 'Laughlin Air Force Base', value: 'Laughlin Air Force Base' },
  { label: 'Laugna', value: 'Laugna' },
  { label: 'Lauingen', value: 'Lauingen' },
  { label: 'Laujar De Andarax', value: 'Laujar De Andarax' },
  { label: 'Laukaa', value: 'Laukaa' },
  { label: 'Laukiz', value: 'Laukiz' },
  { label: 'Launac', value: 'Launac' },
  { label: 'Launaguet', value: 'Launaguet' },
  { label: 'Launceston', value: 'Launceston' },
  { label: 'Launceston', value: 'Launceston' },
  { label: 'Launceston City Centre', value: 'Launceston City Centre' },
  { label: 'Launching Place', value: 'Launching Place' },
  { label: 'Laungowal', value: 'Laungowal' },
  { label: 'Launsdorf', value: 'Launsdorf' },
  { label: 'Laupen', value: 'Laupen' },
  { label: 'Laupersdorf', value: 'Laupersdorf' },
  { label: 'Lauperswil', value: 'Lauperswil' },
  { label: 'Laupheim', value: 'Laupheim' },
  { label: 'Laur', value: 'Laur' },
  { label: 'Laur', value: 'Laur' },
  { label: 'Laura', value: 'Laura' },
  { label: 'Lauradorp', value: 'Lauradorp' },
  { label: 'Laureana Cilento', value: 'Laureana Cilento' },
  { label: 'Laureana Di Borrello', value: 'Laureana Di Borrello' },
  { label: 'Lauregno', value: 'Lauregno' },
  { label: 'Laurel', value: 'Laurel' },
  { label: 'Laurel', value: 'Laurel' },
  { label: 'Laurel', value: 'Laurel' },
  { label: 'Laurel', value: 'Laurel' },
  { label: 'Laurel', value: 'Laurel' },
  { label: 'Laurel', value: 'Laurel' },
  { label: 'Laurel', value: 'Laurel' },
  { label: 'Laurel', value: 'Laurel' },
  { label: 'Laurel', value: 'Laurel' },
  { label: 'Laurel Bay', value: 'Laurel Bay' },
  { label: 'Laurel County', value: 'Laurel County' },
  { label: 'Laurel Hill', value: 'Laurel Hill' },
  { label: 'Laurel Hill', value: 'Laurel Hill' },
  { label: 'Laurel Hollow', value: 'Laurel Hollow' },
  { label: 'Laurel Lake', value: 'Laurel Lake' },
  { label: 'Laurel Park', value: 'Laurel Park' },
  { label: 'Laurel Springs', value: 'Laurel Springs' },
  { label: 'Laureldale', value: 'Laureldale' },
  { label: 'Laureles', value: 'Laureles' },
  { label: 'Laurelton', value: 'Laurelton' },
  { label: 'Laure-Minervois', value: 'Laure-Minervois' },
  { label: 'Laurence Harbor', value: 'Laurence Harbor' },
  { label: 'Laurencekirk', value: 'Laurencekirk' },
  { label: 'Lăureni', value: 'Lăureni' },
  { label: 'Laurens', value: 'Laurens' },
  { label: 'Laurens', value: 'Laurens' },
  { label: 'Laurens', value: 'Laurens' },
  { label: 'Laurens County', value: 'Laurens County' },
  { label: 'Laurens County', value: 'Laurens County' },
  { label: 'Laurentino', value: 'Laurentino' },
  { label: 'Laurenzana', value: 'Laurenzana' },
  { label: 'Lauria', value: 'Lauria' },
  { label: 'Lauriano', value: 'Lauriano' },
  { label: 'Lauricocha', value: 'Lauricocha' },
  { label: 'Laurieston', value: 'Laurieston' },
  { label: 'Laurignano', value: 'Laurignano' },
  { label: 'Laurinburg', value: 'Laurinburg' },
  { label: 'Laurino', value: 'Laurino' },
  { label: 'Lauris', value: 'Lauris' },
  { label: 'Laurito', value: 'Laurito' },
  { label: 'Laurium', value: 'Laurium' },
  { label: 'Lauro', value: 'Lauro' },
  { label: 'Lauro De Freitas', value: 'Lauro De Freitas' },
  { label: 'Lauro Muller', value: 'Lauro Muller' },
  { label: 'Laurys Station', value: 'Laurys Station' },
  { label: 'Lausanne', value: 'Lausanne' },
  { label: 'Lausanne District', value: 'Lausanne District' },
  { label: 'Lauscha', value: 'Lauscha' },
  { label: 'Lausen', value: 'Lausen' },
  { label: 'Laussa', value: 'Laussa' },
  { label: 'Laußig', value: 'Laußig' },
  { label: 'Laußnitz', value: 'Laußnitz' },
  { label: 'Laussonne', value: 'Laussonne' },
  { label: 'Lautaro', value: 'Lautaro' },
  { label: 'Lautem', value: 'Lautem' },
  { label: 'Lautenbach', value: 'Lautenbach' },
  { label: 'Lautenbach', value: 'Lautenbach' },
  { label: 'Lautenbachzell', value: 'Lautenbachzell' },
  { label: 'Lauter', value: 'Lauter' },
  { label: 'Lauter', value: 'Lauter' },
  { label: 'Lauterach', value: 'Lauterach' },
  { label: 'Lauterbach', value: 'Lauterbach' },
  { label: 'Lauterbach/Schwarzwald', value: 'Lauterbach/Schwarzwald' },
  { label: 'Lauterbourg', value: 'Lauterbourg' },
  { label: 'Lauterbrunnen', value: 'Lauterbrunnen' },
  { label: 'Lauterecken', value: 'Lauterecken' },
  { label: 'Lauterhofen', value: 'Lauterhofen' },
  { label: 'Lauterique', value: 'Lauterique' },
  { label: 'Lautertal', value: 'Lautertal' },
  { label: 'Lautertal', value: 'Lautertal' },
  { label: 'Lautoka', value: 'Lautoka' },
  { label: 'Lautrach', value: 'Lautrach' },
  { label: 'Lautrec', value: 'Lautrec' },
  { label: 'Lauttasaari', value: 'Lauttasaari' },
  { label: 'Lauvsnes', value: 'Lauvsnes' },
  { label: 'Lauwin-Planque', value: 'Lauwin-Planque' },
  { label: 'Lauzacco', value: 'Lauzacco' },
  { label: 'Lauzerte', value: 'Lauzerte' },
  { label: 'Lavaca', value: 'Lavaca' },
  { label: 'Lavaca County', value: 'Lavaca County' },
  { label: 'Lavagna', value: 'Lavagna' },
  { label: 'Lavagno', value: 'Lavagno' },
  { label: 'Laval', value: 'Laval' },
  { label: 'Laval', value: 'Laval' },
  { label: 'Lavalette', value: 'Lavalette' },
  { label: 'Lavalette', value: 'Lavalette' },
  { label: 'Lavallette', value: 'Lavallette' },
  { label: 'Laval-Pradel', value: 'Laval-Pradel' },
  { label: 'Lavaltrie', value: 'Lavaltrie' },
  { label: 'Lavamünd', value: 'Lavamünd' },
  { label: 'Lavandeira', value: 'Lavandeira' },
  { label: 'Lavandvil', value: 'Lavandvil' },
  { label: 'Lavangen', value: 'Lavangen' },
  { label: 'Lavans-Lès-Saint-Claude', value: 'Lavans-Lès-Saint-Claude' },
  { label: 'Lavant', value: 'Lavant' },
  { label: 'Lávara', value: 'Lávara' },
  { label: 'Lavardac', value: 'Lavardac' },
  { label: 'Lavasa', value: 'Lavasa' },
  { label: 'Lavasan', value: 'Lavasan' },
  { label: 'Lavashan', value: 'Lavashan' },
  { label: 'Lavault-Sainte-Anne', value: 'Lavault-Sainte-Anne' },
  { label: 'Lavaur', value: 'Lavaur' },
  { label: 'Lavaux-Oron District', value: 'Lavaux-Oron District' },
  { label: 'Laveen', value: 'Laveen' },
  { label: 'Lavelanet', value: 'Lavelanet' },
  { label: 'Lavello', value: 'Lavello' },
  { label: 'Lavena Ponte Tresa', value: 'Lavena Ponte Tresa' },
  { label: 'Lavendon', value: 'Lavendon' },
  { label: 'Lavenham', value: 'Lavenham' },
  { label: 'Lavenir', value: 'Lavenir' },
  { label: 'Laveno', value: 'Laveno' },
  { label: 'Lavenone', value: 'Lavenone' },
  { label: 'Laventie', value: 'Laventie' },
  { label: 'Laventille', value: 'Laventille' },
  { label: 'Laventure', value: 'Laventure' },
  { label: 'Laverkin', value: 'Laverkin' },
  { label: 'Laverne', value: 'Laverne' },
  { label: 'Lavernose-Lacasse', value: 'Lavernose-Lacasse' },
  { label: 'Lavertezzo', value: 'Lavertezzo' },
  { label: 'Laverton', value: 'Laverton' },
  { label: 'Laverton', value: 'Laverton' },
  { label: 'Lavérune', value: 'Lavérune' },
  { label: 'Lavezares', value: 'Lavezares' },
  { label: 'Lavezares', value: 'Lavezares' },
  { label: 'Lavezzola', value: 'Lavezzola' },
  { label: 'Lavia', value: 'Lavia' },
  { label: 'Laviana', value: 'Laviana' },
  { label: 'Laviano', value: 'Laviano' },
  { label: 'Lavilledieu', value: 'Lavilledieu' },
  { label: 'Lavinaio-Monterosso', value: 'Lavinaio-Monterosso' },
  { label: 'Lavington', value: 'Lavington' },
  { label: 'Lavínia', value: 'Lavínia' },
  { label: 'Lavinio', value: 'Lavinio' },
  { label: 'Lavis', value: 'Lavis' },
  { label: 'Lavit', value: 'Lavit' },
  { label: 'Lavon', value: 'Lavon' },
  { label: 'Lavonia', value: 'Lavonia' },
  { label: 'Lavos', value: 'Lavos' },
  { label: 'Lavoux', value: 'Lavoux' },
  { label: 'Lavra', value: 'Lavra' },
  { label: 'Lavradio', value: 'Lavradio' },
  { label: 'Lavras', value: 'Lavras' },
  { label: 'Lavras Da Mangabeira', value: 'Lavras Da Mangabeira' },
  { label: 'Lavras Do Sul', value: 'Lavras Do Sul' },
  { label: 'Lavrentiya', value: 'Lavrentiya' },
  { label: 'Lavrica', value: 'Lavrica' },
  { label: 'Lavrinhas', value: 'Lavrinhas' },
  { label: 'Lávrio', value: 'Lávrio' },
  { label: 'Lavumisa', value: 'Lavumisa' },
  { label: 'Law', value: 'Law' },
  { label: 'Lawai', value: 'Lawai' },
  { label: 'Lawalde', value: 'Lawalde' },
  { label: 'Lawang', value: 'Lawang' },
  { label: 'Lawang Kupang', value: 'Lawang Kupang' },
  { label: 'Lawang Kupang', value: 'Lawang Kupang' },
  { label: 'Lawar', value: 'Lawar' },
  { label: 'Lawar Khas', value: 'Lawar Khas' },
  { label: 'Lawas', value: 'Lawas' },
  { label: 'Lawdar', value: 'Lawdar' },
  { label: 'Lawigan', value: 'Lawigan' },
  { label: 'Lawndale', value: 'Lawndale' },
  { label: 'Lawngtlai', value: 'Lawngtlai' },
  { label: 'Lawnside', value: 'Lawnside' },
  { label: 'Lawnton', value: 'Lawnton' },
  { label: 'Lawnton', value: 'Lawnton' },
  { label: 'Lawrence', value: 'Lawrence' },
  { label: 'Lawrence', value: 'Lawrence' },
  { label: 'Lawrence', value: 'Lawrence' },
  { label: 'Lawrence', value: 'Lawrence' },
  { label: 'Lawrence', value: 'Lawrence' },
  { label: 'Lawrence County', value: 'Lawrence County' },
  { label: 'Lawrence County', value: 'Lawrence County' },
  { label: 'Lawrence County', value: 'Lawrence County' },
  { label: 'Lawrence County', value: 'Lawrence County' },
  { label: 'Lawrence County', value: 'Lawrence County' },
  { label: 'Lawrence County', value: 'Lawrence County' },
  { label: 'Lawrence County', value: 'Lawrence County' },
  { label: 'Lawrence County', value: 'Lawrence County' },
  { label: 'Lawrence County', value: 'Lawrence County' },
  { label: 'Lawrence County', value: 'Lawrence County' },
  { label: 'Lawrence County', value: 'Lawrence County' },
  { label: 'Lawrence Park', value: 'Lawrence Park' },
  { label: 'Lawrence Tavern', value: 'Lawrence Tavern' },
  { label: 'Lawrenceburg', value: 'Lawrenceburg' },
  { label: 'Lawrenceburg', value: 'Lawrenceburg' },
  { label: 'Lawrenceburg', value: 'Lawrenceburg' },
  { label: 'Lawrenceville', value: 'Lawrenceville' },
  { label: 'Lawrenceville', value: 'Lawrenceville' },
  { label: 'Lawrenceville', value: 'Lawrenceville' },
  { label: 'Lawrenceville', value: 'Lawrenceville' },
  { label: 'Lawson', value: 'Lawson' },
  { label: 'Lawson', value: 'Lawson' },
  { label: 'Lawson Heights', value: 'Lawson Heights' },
  { label: 'Lawtell', value: 'Lawtell' },
  { label: 'Lawton', value: 'Lawton' },
  { label: 'Lawton', value: 'Lawton' },
  { label: 'Laxå', value: 'Laxå' },
  { label: 'Laxe', value: 'Laxe' },
  { label: 'Laxenburg', value: 'Laxenburg' },
  { label: 'Laxolo', value: 'Laxolo' },
  { label: 'Laxou', value: 'Laxou' },
  { label: 'Laya', value: 'Laya' },
  { label: 'Laya', value: 'Laya' },
  { label: 'Layana', value: 'Layana' },
  { label: 'Laybid', value: 'Laybid' },
  { label: 'Layer De La Haye', value: 'Layer De La Haye' },
  { label: 'Layhill', value: 'Layhill' },
  { label: 'Layla', value: 'Layla' },
  { label: 'Laylay', value: 'Laylay' },
  { label: 'Laymantown', value: 'Laymantown' },
  { label: 'Layo', value: 'Layo' },
  { label: 'Layog', value: 'Layog' },
  { label: 'Layog', value: 'Layog' },
  { label: 'Layos', value: 'Layos' },
  { label: 'Layou', value: 'Layou' },
  { label: 'Layrac', value: 'Layrac' },
  { label: 'Lay-Saint-Christophe', value: 'Lay-Saint-Christophe' },
  { label: 'Layton', value: 'Layton' },
  { label: 'Laytonville', value: 'Laytonville' },
  { label: 'Laytown', value: 'Laytown' },
  { label: 'Layyah', value: 'Layyah' },
  { label: 'Layyah District', value: 'Layyah District' },
  { label: 'Laza', value: 'Laza' },
  { label: 'Laza', value: 'Laza' },
  { label: 'Lazagurría', value: 'Lazagurría' },
  { label: 'Lažani', value: 'Lažani' },
  { label: 'Lazarat', value: 'Lazarat' },
  { label: 'Lăzarea', value: 'Lăzarea' },
  { label: 'Lăzăreni', value: 'Lăzăreni' },
  { label: 'Lăzărești', value: 'Lăzărești' },
  { label: 'Lazarev', value: 'Lazarev' },
  { label: 'Lazarevac', value: 'Lazarevac' },
  { label: 'Lazarevo', value: 'Lazarevo' },
  { label: 'Lazarevskoye', value: 'Lazarevskoye' },
  { label: 'Lazarivka', value: 'Lazarivka' },
  { label: 'Lázaro Cárdenas', value: 'Lázaro Cárdenas' },
  { label: 'Lázaro Cárdenas', value: 'Lázaro Cárdenas' },
  { label: 'Lázaro Cárdenas', value: 'Lázaro Cárdenas' },
  { label: 'Lázaro Cárdenas', value: 'Lázaro Cárdenas' },
  { label: 'Lázaro Cárdenas', value: 'Lázaro Cárdenas' },
  { label: 'Lázaro Cárdenas', value: 'Lázaro Cárdenas' },
  { label: 'Lázaro Cárdenas', value: 'Lázaro Cárdenas' },
  { label: 'Lázaro Cárdenas', value: 'Lázaro Cárdenas' },
  { label: 'Lázaro Cárdenas', value: 'Lázaro Cárdenas' },
  { label: 'Lázaro Cárdenas', value: 'Lázaro Cárdenas' },
  { label: 'Lázaro Cárdenas', value: 'Lázaro Cárdenas' },
  { label: 'Lázaro Cárdenas (Chilil)', value: 'Lázaro Cárdenas (Chilil)' },
  { label: 'Lázaro Cárdenas (El Empalme)', value: 'Lázaro Cárdenas (El Empalme)' },
  { label: 'Lázaro Cárdenas (La Purísima)', value: 'Lázaro Cárdenas (La Purísima)' },
  { label: 'Lázaro Cárdenas (Rancho Grande)', value: 'Lázaro Cárdenas (Rancho Grande)' },
  { label: 'Lázaro Cárdenas (Santana)', value: 'Lázaro Cárdenas (Santana)' },
  { label: 'Lázaro Cárdenas Jalisco', value: 'Lázaro Cárdenas Jalisco' },
  { label: 'Lazdijai', value: 'Lazdijai' },
  { label: 'Lazdynai', value: 'Lazdynai' },
  { label: 'Lazeshchyna', value: 'Lazeshchyna' },
  { label: 'Lazi', value: 'Lazi' },
  { label: 'Lazi', value: 'Lazi' },
  { label: 'Lazise', value: 'Lazise' },
  { label: 'Łaziska', value: 'Łaziska' },
  { label: 'Łaziska', value: 'Łaziska' },
  { label: 'Łaziska Górne', value: 'Łaziska Górne' },
  { label: 'Lazkao', value: 'Lazkao' },
  { label: 'Lázně Bělohrad', value: 'Lázně Bělohrad' },
  { label: 'Lázně Bohdaneč', value: 'Lázně Bohdaneč' },
  { label: 'Lázně Kynžvart', value: 'Lázně Kynžvart' },
  { label: 'Lazo', value: 'Lazo' },
  { label: 'Lazovskiy Rayon', value: 'Lazovskiy Rayon' },
  { label: 'Lazu Baciului', value: 'Lazu Baciului' },
  { label: 'Lazuri', value: 'Lazuri' },
  { label: 'Lazuri', value: 'Lazuri' },
  { label: 'Lazuri De Beiuş', value: 'Lazuri De Beiuş' },
  { label: 'Lazurne', value: 'Lazurne' },
  { label: 'Łazy', value: 'Łazy' },
  { label: 'Lazy Mountain', value: 'Lazy Mountain' },
  { label: 'Lazzaro', value: 'Lazzaro' },
  { label: 'Lazzate', value: 'Lazzate' },
  { label: 'Lazzeretto', value: 'Lazzeretto' },
  { label: 'Lchap’', value: 'Lchap’' },
  { label: 'Lchashen', value: 'Lchashen' },
  { label: 'Le Ban Saint-Martin', value: 'Le Ban Saint-Martin' },
  { label: 'Le Barcarès', value: 'Le Barcarès' },
  { label: 'Le Barp', value: 'Le Barp' },
  { label: 'Le Bar-Sur-Loup', value: 'Le Bar-Sur-Loup' },
  { label: 'Le Beausset', value: 'Le Beausset' },
  { label: 'Le Bic', value: 'Le Bic' },
  { label: 'Le Bignon', value: 'Le Bignon' },
  { label: 'Le Blanc', value: 'Le Blanc' },
  { label: 'Le Blanc-Mesnil', value: 'Le Blanc-Mesnil' },
  { label: 'Le Bois-Doingt', value: 'Le Bois-Doingt' },
  { label: 'Le Bois-Plage-En-Ré', value: 'Le Bois-Plage-En-Ré' },
  { label: 'Le Bono', value: 'Le Bono' },
  { label: 'Le Bosc-Roger-En-Roumois', value: 'Le Bosc-Roger-En-Roumois' },
  { label: 'Le Boupère', value: 'Le Boupère' },
  { label: 'Le Bourg-D’Oisans', value: 'Le Bourg-D’Oisans' },
  { label: 'Le Bourget', value: 'Le Bourget' },
  { label: 'Le Bourget-Du-Lac', value: 'Le Bourget-Du-Lac' },
  { label: 'Le Bourgneuf-La-Forêt', value: 'Le Bourgneuf-La-Forêt' },
  { label: 'Le Bouscat', value: 'Le Bouscat' },
  { label: 'Le Bousquet-D’Orb', value: 'Le Bousquet-D’Orb' },
  { label: 'Le Breil-Sur-Mérize', value: 'Le Breil-Sur-Mérize' },
  { label: 'Le Breuil', value: 'Le Breuil' },
  { label: 'Le Breuil-Sur-Couze', value: 'Le Breuil-Sur-Couze' },
  { label: 'Le Broc', value: 'Le Broc' },
  { label: 'Le Brusquet', value: 'Le Brusquet' },
  { label: 'Le Bugue', value: 'Le Bugue' },
  { label: 'Le Buisson-De-Cadouin', value: 'Le Buisson-De-Cadouin' },
  { label: 'Le Cabot', value: 'Le Cabot' },
  { label: 'Le Cailar', value: 'Le Cailar' },
  { label: 'Le Camas', value: 'Le Camas' },
  { label: 'Le Canet', value: 'Le Canet' },
  { label: 'Le Cannet', value: 'Le Cannet' },
  { label: 'Le Cannet-Des-Maures', value: 'Le Cannet-Des-Maures' },
  { label: 'Le Cap Dagde', value: 'Le Cap Dagde' },
  { label: 'Le Casine-Perignano-Spinelli', value: 'Le Casine-Perignano-Spinelli' },
  { label: 'Le Castella', value: 'Le Castella' },
  { label: 'Le Castellet', value: 'Le Castellet' },
  { label: 'Le Cellier', value: 'Le Cellier' },
  { label: 'Le Cendre', value: 'Le Cendre' },
  { label: 'Le Center', value: 'Le Center' },
  { label: 'Le Chambon-Feugerolles', value: 'Le Chambon-Feugerolles' },
  { label: 'Le Chambon-Sur-Lignon', value: 'Le Chambon-Sur-Lignon' },
  { label: 'Le Champ-Près-Froges', value: 'Le Champ-Près-Froges' },
  { label: 'Le Champ-Saint-Père', value: 'Le Champ-Saint-Père' },
  { label: 'Le Chapitre', value: 'Le Chapitre' },
  { label: 'Le Châtelet', value: 'Le Châtelet' },
  { label: 'Le Châtelet-En-Brie', value: 'Le Châtelet-En-Brie' },
  { label: 'Le Chenit', value: 'Le Chenit' },
  { label: 'Le Chesnay', value: 'Le Chesnay' },
  { label: 'Le Cheylard', value: 'Le Cheylard' },
  { label: 'Le Cheylas', value: 'Le Cheylas' },
  { label: 'Le Claire', value: 'Le Claire' },
  { label: 'Le Conquet', value: 'Le Conquet' },
  { label: 'Le Coteau', value: 'Le Coteau' },
  { label: 'Le Coudray', value: 'Le Coudray' },
  { label: 'Le Coudray-Montceaux', value: 'Le Coudray-Montceaux' },
  { label: 'Le Crès', value: 'Le Crès' },
  { label: 'Le Crest', value: 'Le Crest' },
  { label: 'Le Creusot', value: 'Le Creusot' },
  { label: 'Le Croisic', value: 'Le Croisic' },
  { label: 'Le Crotoy', value: 'Le Crotoy' },
  { label: 'Le Donjon', value: 'Le Donjon' },
  { label: 'Le Dorat', value: 'Le Dorat' },
  { label: 'Le Doulieu', value: 'Le Doulieu' },
  { label: 'Le Drennec', value: 'Le Drennec' },
  { label: 'Le Faou', value: 'Le Faou' },
  { label: 'Le Faouët', value: 'Le Faouët' },
  { label: 'Le Fauga', value: 'Le Fauga' },
  { label: 'Le Fenouiller', value: 'Le Fenouiller' },
  { label: 'Le Fief-Sauvin', value: 'Le Fief-Sauvin' },
  { label: 'Le Fleix', value: 'Le Fleix' },
  { label: 'Le Flore County', value: 'Le Flore County' },
  { label: 'Le Fœil', value: 'Le Fœil' },
  { label: 'Le Folgoët', value: 'Le Folgoët' },
  { label: 'Le Forna', value: 'Le Forna' },
  { label: 'Le Fousseret', value: 'Le Fousseret' },
  { label: 'Le Fuilet', value: 'Le Fuilet' },
  { label: 'Le Garric', value: 'Le Garric' },
  { label: 'Le Gâvre', value: 'Le Gâvre' },
  { label: 'Le Grand', value: 'Le Grand' },
  { label: 'Le Grand-Bornand', value: 'Le Grand-Bornand' },
  { label: 'Le Grand-Bourg', value: 'Le Grand-Bourg' },
  { label: 'Le Grand-Lemps', value: 'Le Grand-Lemps' },
  { label: 'Le Grand-Lucé', value: 'Le Grand-Lucé' },
  { label: 'Le Grand-Pressigny', value: 'Le Grand-Pressigny' },
  { label: 'Le Grand-Quevilly', value: 'Le Grand-Quevilly' },
  { label: 'Le Grand-Saconnex', value: 'Le Grand-Saconnex' },
  { label: 'Le Grau-Du-Roi', value: 'Le Grau-Du-Roi' },
  { label: 'Le Grazie', value: 'Le Grazie' },
  { label: 'Le Grazie Di Ancona', value: 'Le Grazie Di Ancona' },
  { label: 'Le Gua', value: 'Le Gua' },
  { label: 'Le Gua', value: 'Le Gua' },
  { label: 'Le Haillan', value: 'Le Haillan' },
  { label: 'Le Havre', value: 'Le Havre' },
  { label: 'Le Hochet', value: 'Le Hochet' },
  { label: 'Le Houga', value: 'Le Houga' },
  { label: 'Le Houlme', value: 'Le Houlme' },
  { label: 'Le Kremlin-Bicêtre', value: 'Le Kremlin-Bicêtre' },
  { label: 'Le Krib', value: 'Le Krib' },
  { label: 'Le Landeron', value: 'Le Landeron' },
  { label: 'Le Landreau', value: 'Le Landreau' },
  { label: 'Le Langon', value: 'Le Langon' },
  { label: 'Le Lardin-Saint-Lazare', value: 'Le Lardin-Saint-Lazare' },
  { label: 'Le Lavandou', value: 'Le Lavandou' },
  { label: 'Le Locle', value: 'Le Locle' },
  { label: 'Le Locle District', value: 'Le Locle District' },
  { label: 'Le Longeron', value: 'Le Longeron' },
  { label: 'Le Loroux-Bottereau', value: 'Le Loroux-Bottereau' },
  { label: 'Le Louroux-Béconnais', value: 'Le Louroux-Béconnais' },
  { label: 'Le Luart', value: 'Le Luart' },
  { label: 'Le Luc', value: 'Le Luc' },
  { label: 'Le Lude', value: 'Le Lude' },
  { label: 'Le Malzieu-Ville', value: 'Le Malzieu-Ville' },
  { label: 'Le Manoir', value: 'Le Manoir' },
  { label: 'Le Mans', value: 'Le Mans' },
  { label: 'Le Mars', value: 'Le Mars' },
  { label: 'Le Mas-D’Agenais', value: 'Le Mas-D’Agenais' },
  { label: 'Le Mayet-De-Montagne', value: 'Le Mayet-De-Montagne' },
  { label: 'Le May-Sur-Èvre', value: 'Le May-Sur-Èvre' },
  { label: 'Le Mée-Sur-Seine', value: 'Le Mée-Sur-Seine' },
  { label: 'Le Ménil', value: 'Le Ménil' },
  { label: 'Le Merlan', value: 'Le Merlan' },
  { label: 'Le Mesnil-En-Thelle', value: 'Le Mesnil-En-Thelle' },
  { label: 'Le Mesnil-En-Vallée', value: 'Le Mesnil-En-Vallée' },
  { label: 'Le Mesnil-Esnard', value: 'Le Mesnil-Esnard' },
  { label: 'Le Mesnil-Le-Roi', value: 'Le Mesnil-Le-Roi' },
  { label: 'Le Mesnil-Saint-Denis', value: 'Le Mesnil-Saint-Denis' },
  { label: 'Le Mesnil-Sur-Oger', value: 'Le Mesnil-Sur-Oger' },
  { label: 'Le Meux', value: 'Le Meux' },
  { label: 'Le Minihic-Sur-Rance', value: 'Le Minihic-Sur-Rance' },
  { label: 'Le Molay-Littry', value: 'Le Molay-Littry' },
  { label: 'Le Monastère', value: 'Le Monastère' },
  { label: 'Le Monastier-Sur-Gazeille', value: 'Le Monastier-Sur-Gazeille' },
  { label: 'Le Monêtier-Les-Bains', value: 'Le Monêtier-Les-Bains' },
  { label: 'Le Mont-Dore', value: 'Le Mont-Dore' },
  { label: 'Le Mont-Sur-Lausanne', value: 'Le Mont-Sur-Lausanne' },
  { label: 'Le Morne', value: 'Le Morne' },
  { label: 'Le Muy', value: 'Le Muy' },
  { label: 'Le Neubourg', value: 'Le Neubourg' },
  { label: 'Le Noirmont', value: 'Le Noirmont' },
  { label: 'Le Nouvion-En-Thiérache', value: 'Le Nouvion-En-Thiérache' },
  { label: 'Le Palais', value: 'Le Palais' },
  { label: 'Le Palais-Sur-Vienne', value: 'Le Palais-Sur-Vienne' },
  { label: 'Le Pallet', value: 'Le Pallet' },
  { label: 'Le Passage', value: 'Le Passage' },
  { label: 'Le Péage-De-Roussillon', value: 'Le Péage-De-Roussillon' },
  { label: 'Le Pêchereau', value: 'Le Pêchereau' },
  { label: 'Le Pecq', value: 'Le Pecq' },
  { label: 'Le Pellerin', value: 'Le Pellerin' },
  { label: 'Le Perray-En-Yvelines', value: 'Le Perray-En-Yvelines' },
  { label: 'Le Perréon', value: 'Le Perréon' },
  { label: 'Le Perreux-Sur-Marne', value: 'Le Perreux-Sur-Marne' },
  { label: 'Le Perrier', value: 'Le Perrier' },
  { label: 'Le Pertre', value: 'Le Pertre' },
  { label: 'Le Petit-Quevilly', value: 'Le Petit-Quevilly' },
  { label: 'Le Pharo', value: 'Le Pharo' },
  { label: 'Le Pian-Médoc', value: 'Le Pian-Médoc' },
  { label: 'Le Pin', value: 'Le Pin' },
  { label: 'Le Pin', value: 'Le Pin' },
  { label: 'Le Pin', value: 'Le Pin' },
  { label: 'Le Pin-En-Mauges', value: 'Le Pin-En-Mauges' },
  { label: 'Le Pizou', value: 'Le Pizou' },
  { label: 'Le Plan-De-La-Tour', value: 'Le Plan-De-La-Tour' },
  { label: 'Le Plateau', value: 'Le Plateau' },
  { label: 'Le Plessis-Belleville', value: 'Le Plessis-Belleville' },
  { label: 'Le Plessis-Bouchard', value: 'Le Plessis-Bouchard' },
  { label: 'Le Plessis-Brion', value: 'Le Plessis-Brion' },
  { label: 'Le Plessis-Grammoire', value: 'Le Plessis-Grammoire' },
  { label: 'Le Plessis-Macé', value: 'Le Plessis-Macé' },
  { label: 'Le Plessis-Pâté', value: 'Le Plessis-Pâté' },
  { label: 'Le Plessis-Robinson', value: 'Le Plessis-Robinson' },
  { label: 'Le Plessis-Trévise', value: 'Le Plessis-Trévise' },
  { label: 'Le Poinçonnet', value: 'Le Poinçonnet' },
  { label: 'Le Poiré-Sur-Vie', value: 'Le Poiré-Sur-Vie' },
  { label: 'Le Pont-De-Beauvoisin', value: 'Le Pont-De-Beauvoisin' },
  { label: 'Le Pont-De-Claix', value: 'Le Pont-De-Claix' },
  { label: 'Le Pontet', value: 'Le Pontet' },
  { label: 'Le Porge', value: 'Le Porge' },
  { label: 'Le Portel', value: 'Le Portel' },
  { label: 'Le Port-Marly', value: 'Le Port-Marly' },
  { label: 'Le Pouget', value: 'Le Pouget' },
  { label: 'Le Poujol-Sur-Orb', value: 'Le Poujol-Sur-Orb' },
  { label: 'Le Pouliguen', value: 'Le Pouliguen' },
  { label: 'Le Pouzin', value: 'Le Pouzin' },
  { label: 'Le Pradet', value: 'Le Pradet' },
  { label: 'Le Pré-Saint-Gervais', value: 'Le Pré-Saint-Gervais' },
  { label: 'Le Puy-En-Velay', value: 'Le Puy-En-Velay' },
  { label: 'Le Puy-Notre-Dame', value: 'Le Puy-Notre-Dame' },
  { label: 'Le Puy-Sainte-Réparade', value: 'Le Puy-Sainte-Réparade' },
  { label: 'Le Quesnoy', value: 'Le Quesnoy' },
  { label: 'Le Raincy', value: 'Le Raincy' },
  { label: 'Le Redon', value: 'Le Redon' },
  { label: 'Le Relecq-Kerhuon', value: 'Le Relecq-Kerhuon' },
  { label: 'Le Revest-Les-Eaux', value: 'Le Revest-Les-Eaux' },
  { label: 'Le Rheu', value: 'Le Rheu' },
  { label: 'Le Riche', value: 'Le Riche' },
  { label: 'Le Rouret', value: 'Le Rouret' },
  { label: 'Le Rove', value: 'Le Rove' },
  { label: 'Le Roy', value: 'Le Roy' },
  { label: 'Le Roy', value: 'Le Roy' },
  { label: 'Le Rughe', value: 'Le Rughe' },
  { label: 'Le Russey', value: 'Le Russey' },
  { label: 'Le Sappey-En-Chartreuse', value: 'Le Sappey-En-Chartreuse' },
  { label: 'Le Sequestre', value: 'Le Sequestre' },
  { label: 'Le Soler', value: 'Le Soler' },
  { label: 'Le Sourn', value: 'Le Sourn' },
  { label: 'Le Sueur', value: 'Le Sueur' },
  { label: 'Le Sueur County', value: 'Le Sueur County' },
  { label: 'Le Syndicat', value: 'Le Syndicat' },
  { label: 'Le Taillan-Médoc', value: 'Le Taillan-Médoc' },
  { label: 'Le Tallud', value: 'Le Tallud' },
  { label: 'Le Teich', value: 'Le Teich' },
  { label: 'Le Teil', value: 'Le Teil' },
  { label: 'Le Teilleul', value: 'Le Teilleul' },
  { label: 'Le Temple-De-Bretagne', value: 'Le Temple-De-Bretagne' },
  { label: 'Le Theil-Bocage', value: 'Le Theil-Bocage' },
  { label: 'Le Theil-De-Bretagne', value: 'Le Theil-De-Bretagne' },
  { label: 'Le Thillay', value: 'Le Thillay' },
  { label: 'Le Thillot', value: 'Le Thillot' },
  { label: 'Le Tholonet', value: 'Le Tholonet' },
  { label: 'Le Tholy', value: 'Le Tholy' },
  { label: 'Le Thor', value: 'Le Thor' },
  { label: 'Le Thoronet', value: 'Le Thoronet' },
  { label: 'Le Thou', value: 'Le Thou' },
  { label: 'Le Thuit-Signol', value: 'Le Thuit-Signol' },
  { label: 'Le Tignet', value: 'Le Tignet' },
  { label: 'Le Touquet-Paris-Plage', value: 'Le Touquet-Paris-Plage' },
  { label: 'Le Touvet', value: 'Le Touvet' },
  { label: 'Le Trait', value: 'Le Trait' },
  { label: 'Le Tréport', value: 'Le Tréport' },
  { label: 'Le Trévoux', value: 'Le Trévoux' },
  { label: 'Le Val', value: 'Le Val' },
  { label: 'Le Val-Saint-Germain', value: 'Le Val-Saint-Germain' },
  { label: 'Le Val-Saint-Père', value: 'Le Val-Saint-Père' },
  { label: 'Le Vaud', value: 'Le Vaud' },
  { label: 'Le Vaudreuil', value: 'Le Vaudreuil' },
  { label: 'Le Verdon-Sur-Mer', value: 'Le Verdon-Sur-Mer' },
  { label: 'Le Versoud', value: 'Le Versoud' },
  { label: 'Le Vésinet', value: 'Le Vésinet' },
  { label: 'Le Vieil-Baugé', value: 'Le Vieil-Baugé' },
  { label: 'Le Vieux-Marché', value: 'Le Vieux-Marché' },
  { label: 'Le Vigan', value: 'Le Vigan' },
  { label: 'Le Vigen', value: 'Le Vigen' },
  { label: 'Le Vivier-Sur-Mer', value: 'Le Vivier-Sur-Mer' },
  { label: 'Lea County', value: 'Lea County' },
  { label: 'Lea Hill', value: 'Lea Hill' },
  { label: 'Leabrook', value: 'Leabrook' },
  { label: 'Leaburu', value: 'Leaburu' },
  { label: 'Leacaman', value: 'Leacaman' },
  { label: 'Leache', value: 'Leache' },
  { label: 'Leachville', value: 'Leachville' },
  { label: 'Lead', value: 'Lead' },
  { label: 'Leadville', value: 'Leadville' },
  { label: 'Leadville North', value: 'Leadville North' },
  { label: 'Leadwood', value: 'Leadwood' },
  { label: 'League City', value: 'League City' },
  { label: 'Leake County', value: 'Leake County' },
  { label: 'Leakesville', value: 'Leakesville' },
  { label: 'Leakey', value: 'Leakey' },
  { label: 'Lealman', value: 'Lealman' },
  { label: 'Leamington', value: 'Leamington' },
  { label: 'Leamington', value: 'Leamington' },
  { label: 'Leander', value: 'Leander' },
  { label: 'Leandro Ferreira', value: 'Leandro Ferreira' },
  { label: 'Leanyer', value: 'Leanyer' },
  { label: 'Leányfalu', value: 'Leányfalu' },
  { label: 'Leányvár', value: 'Leányvár' },
  { label: 'Leasingham', value: 'Leasingham' },
  { label: 'Leatherhead', value: 'Leatherhead' },
  { label: 'Leau Mineau', value: 'Leau Mineau' },
  { label: 'Leavenworth', value: 'Leavenworth' },
  { label: 'Leavenworth', value: 'Leavenworth' },
  { label: 'Leavenworth County', value: 'Leavenworth County' },
  { label: 'Leavittsburg', value: 'Leavittsburg' },
  { label: 'Leawood', value: 'Leawood' },
  { label: 'Łeba', value: 'Łeba' },
  { label: 'Lebach', value: 'Lebach' },
  { label: 'Lebak', value: 'Lebak' },
  { label: 'Lebaksiu', value: 'Lebaksiu' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lebanon County', value: 'Lebanon County' },
  { label: 'Lebanon Junction', value: 'Lebanon Junction' },
  { label: 'Lebanon South', value: 'Lebanon South' },
  { label: 'Lebbeke', value: 'Lebbeke' },
  { label: 'Lebec', value: 'Lebec' },
  { label: 'Lebedinovka', value: 'Lebedinovka' },
  { label: 'Lebedinyy', value: 'Lebedinyy' },
  { label: 'Lebedyan’', value: 'Lebedyan’' },
  { label: 'Lebedyanskiy Rayon', value: 'Lebedyanskiy Rayon' },
  { label: 'Lebedyn', value: 'Lebedyn' },
  { label: 'Lebedyn', value: 'Lebedyn' },
  { label: 'Lebel-Sur-Quévillon', value: 'Lebel-Sur-Quévillon' },
  { label: 'Lébény', value: 'Lébény' },
  { label: 'Lebesby', value: 'Lebesby' },
  { label: 'Lebialem', value: 'Lebialem' },
  { label: 'Leblanc', value: 'Leblanc' },
  { label: 'Lebon Régis', value: 'Lebon Régis' },
  { label: 'Lębork', value: 'Lębork' },
  { label: 'Lebowakgomo', value: 'Lebowakgomo' },
  { label: 'Lebrija', value: 'Lebrija' },
  { label: 'Lebrija', value: 'Lebrija' },
  { label: 'Lebu', value: 'Lebu' },
  { label: 'Lebus', value: 'Lebus' },
  { label: 'Lebyazh’Ye', value: 'Lebyazh’Ye' },
  { label: 'Lebyazh’Ye', value: 'Lebyazh’Ye' },
  { label: 'Lebyazh’Ye', value: 'Lebyazh’Ye' },
  { label: 'Lebyazh’Yevskiy Rayon', value: 'Lebyazh’Yevskiy Rayon' },
  { label: 'Leça Da Palmeira', value: 'Leça Da Palmeira' },
  { label: 'Leça Do Bailio', value: 'Leça Do Bailio' },
  { label: 'Lecanto', value: 'Lecanto' },
  { label: 'Lecce', value: 'Lecce' },
  { label: 'Lecce Nei Marsi', value: 'Lecce Nei Marsi' },
  { label: 'Lecco', value: 'Lecco' },
  { label: 'Lecelles', value: 'Lecelles' },
  { label: 'Lécera', value: 'Lécera' },
  { label: 'Lech', value: 'Lech' },
  { label: 'Lechainá', value: 'Lechainá' },
  { label: 'Lechaschau', value: 'Lechaschau' },
  { label: 'Lechbruck', value: 'Lechbruck' },
  { label: 'Lechee', value: 'Lechee' },
  { label: 'Lecheng', value: 'Lecheng' },
  { label: 'Lechincioara', value: 'Lechincioara' },
  { label: 'Lechinkay', value: 'Lechinkay' },
  { label: 'Lechinţa', value: 'Lechinţa' },
  { label: 'Lechința', value: 'Lechința' },
  { label: 'Lechlade', value: 'Lechlade' },
  { label: 'Lechón', value: 'Lechón' },
  { label: 'Léchovo', value: 'Léchovo' },
  { label: 'Leciñena', value: 'Leciñena' },
  { label: 'Leck', value: 'Leck' },
  { label: 'Lecka', value: 'Lecka' },
  { label: 'Lécluse', value: 'Lécluse' },
  { label: 'Lecompte', value: 'Lecompte' },
  { label: 'Leconfield', value: 'Leconfield' },
  { label: 'Lécousse', value: 'Lécousse' },
  { label: 'Lecrín', value: 'Lecrín' },
  { label: 'Lectoure', value: 'Lectoure' },
  { label: 'Łęczna', value: 'Łęczna' },
  { label: 'Łęczyca', value: 'Łęczyca' },
  { label: 'Łęczyce', value: 'Łęczyce' },
  { label: 'Leda', value: 'Leda' },
  { label: 'Ledaña', value: 'Ledaña' },
  { label: 'Ledanca', value: 'Ledanca' },
  { label: 'Ledbetter', value: 'Ledbetter' },
  { label: 'Ledbury', value: 'Ledbury' },
  { label: 'Lede', value: 'Lede' },
  { label: 'Ledeberg', value: 'Ledeberg' },
  { label: 'Ledeč Nad Sázavou', value: 'Ledeč Nad Sázavou' },
  { label: 'Ledegem', value: 'Ledegem' },
  { label: 'Ledenice', value: 'Ledenice' },
  { label: 'Ledenitzen', value: 'Ledenitzen' },
  { label: 'Lédenon', value: 'Lédenon' },
  { label: 'Ledesma', value: 'Ledesma' },
  { label: 'Ledesma De La Cogolla', value: 'Ledesma De La Cogolla' },
  { label: 'Ledeuix', value: 'Ledeuix' },
  { label: 'Lédignan', value: 'Lédignan' },
  { label: 'Ledigos', value: 'Ledigos' },
  { label: 'Ledmozero', value: 'Ledmozero' },
  { label: 'Lednice', value: 'Lednice' },
  { label: 'Ledrada', value: 'Ledrada' },
  { label: 'Ledsham', value: 'Ledsham' },
  { label: 'Leduc', value: 'Leduc' },
  { label: 'Ledyard', value: 'Ledyard' },
  { label: 'Lędziny', value: 'Lędziny' },
  { label: 'Lee', value: 'Lee' },
  { label: 'Lee', value: 'Lee' },
  { label: 'Lee Acres', value: 'Lee Acres' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Lee County', value: 'Lee County' },
  { label: 'Leechburg', value: 'Leechburg' },
  { label: 'Leederville', value: 'Leederville' },
  { label: 'Leeds', value: 'Leeds' },
  { label: 'Leeds', value: 'Leeds' },
  { label: 'Leeds', value: 'Leeds' },
  { label: 'Leeds', value: 'Leeds' },
  { label: 'Leegebruch', value: 'Leegebruch' },
  { label: 'Leek', value: 'Leek' },
  { label: 'Leek', value: 'Leek' },
  { label: 'Leek Wootton', value: 'Leek Wootton' },
  { label: 'Leelanau County', value: 'Leelanau County' },
  { label: 'Leeman', value: 'Leeman' },
  { label: 'Leeming', value: 'Leeming' },
  { label: 'Leende', value: 'Leende' },
  { label: 'Leens', value: 'Leens' },
  { label: 'Lee-On-The-Solent', value: 'Lee-On-The-Solent' },
  { label: 'Leer', value: 'Leer' },
  { label: 'Leerdam', value: 'Leerdam' },
  { label: 'Leers', value: 'Leers' },
  { label: 'Leersum', value: 'Leersum' },
  { label: 'Lees Summit', value: 'Lees Summit' },
  { label: 'Leesburg', value: 'Leesburg' },
  { label: 'Leesburg', value: 'Leesburg' },
  { label: 'Leesburg', value: 'Leesburg' },
  { label: 'Leesburg', value: 'Leesburg' },
  { label: 'Leesburg', value: 'Leesburg' },
  { label: 'Leese', value: 'Leese' },
  { label: 'Leesport', value: 'Leesport' },
  { label: 'Leest', value: 'Leest' },
  { label: 'Leesten', value: 'Leesten' },
  { label: 'Leeston', value: 'Leeston' },
  { label: 'Leesville', value: 'Leesville' },
  { label: 'Leesville', value: 'Leesville' },
  { label: 'Leeswood', value: 'Leeswood' },
  { label: 'Leeton', value: 'Leeton' },
  { label: 'Leetonia', value: 'Leetonia' },
  { label: 'Leetsdale', value: 'Leetsdale' },
  { label: 'Leeuwarden', value: 'Leeuwarden' },
  { label: 'Leeuwen', value: 'Leeuwen' },
  { label: 'Leezdorf', value: 'Leezdorf' },
  { label: 'Leezen', value: 'Leezen' },
  { label: 'Leezen', value: 'Leezen' },
  { label: 'Lefaia', value: 'Lefaia' },
  { label: 'Leffe', value: 'Leffe' },
  { label: 'Leffrinckoucke', value: 'Leffrinckoucke' },
  { label: 'Léfka', value: 'Léfka' },
  { label: 'Lefkada', value: 'Lefkada' },
  { label: 'Lefkímmi', value: 'Lefkímmi' },
  { label: 'Lefkónas', value: 'Lefkónas' },
  { label: 'Lefkónoiko', value: 'Lefkónoiko' },
  { label: 'Leflore County', value: 'Leflore County' },
  { label: 'Leforest', value: 'Leforest' },
  { label: 'Lefortovo', value: 'Lefortovo' },
  { label: 'Legana', value: 'Legana' },
  { label: 'Leganes', value: 'Leganes' },
  { label: 'Leganés', value: 'Leganés' },
  { label: 'Leganiel', value: 'Leganiel' },
  { label: 'Legarda', value: 'Legarda' },
  { label: 'Legaria', value: 'Legaria' },
  { label: 'Legaspi', value: 'Legaspi' },
  { label: 'Legat', value: 'Legat' },
  { label: 'Legau', value: 'Legau' },
  { label: 'Legazpi', value: 'Legazpi' },
  { label: 'Legden', value: 'Legden' },
  { label: 'Legé', value: 'Legé' },
  { label: 'Legen', value: 'Legen' },
  { label: 'Legend Lake', value: 'Legend Lake' },
  { label: 'Leggiuno', value: 'Leggiuno' },
  { label: 'Legian', value: 'Legian' },
  { label: 'Legionowo', value: 'Legionowo' },
  { label: 'Léglise', value: 'Léglise' },
  { label: 'Legnago', value: 'Legnago' },
  { label: 'Legnano', value: 'Legnano' },
  { label: 'Legnaro', value: 'Legnaro' },
  { label: 'Legnica', value: 'Legnica' },
  { label: 'Legnickie Pole', value: 'Legnickie Pole' },
  { label: 'Legorreta', value: 'Legorreta' },
  { label: 'Łęgowo', value: 'Łęgowo' },
  { label: 'Legrad', value: 'Legrad' },
  { label: 'Legrada', value: 'Legrada' },
  { label: 'Léguevin', value: 'Léguevin' },
  { label: 'Legutio', value: 'Legutio' },
  { label: 'Leh', value: 'Leh' },
  { label: 'Lehavim', value: 'Lehavim' },
  { label: 'Lehe', value: 'Lehe' },
  { label: 'Lehen', value: 'Lehen' },
  { label: 'Lehesten', value: 'Lehesten' },
  { label: 'Lehi', value: 'Lehi' },
  { label: 'Lehigh Acres', value: 'Lehigh Acres' },
  { label: 'Lehigh County', value: 'Lehigh County' },
  { label: 'Lehighton', value: 'Lehighton' },
  { label: 'Lehliu', value: 'Lehliu' },
  { label: 'Lehliu-Gară', value: 'Lehliu-Gară' },
  { label: 'Lehmen', value: 'Lehmen' },
  { label: 'Lehmkuhlen', value: 'Lehmkuhlen' },
  { label: 'Léhon', value: 'Léhon' },
  { label: 'Lehota Pod Vtáčnikom', value: 'Lehota Pod Vtáčnikom' },
  { label: 'Lehrberg', value: 'Lehrberg' },
  { label: 'Lehre', value: 'Lehre' },
  { label: 'Lehrensteinsfeld', value: 'Lehrensteinsfeld' },
  { label: 'Lehrte', value: 'Lehrte' },
  { label: 'Lehtimäki', value: 'Lehtimäki' },
  { label: 'Lehututu', value: 'Lehututu' },
  { label: 'Lei', value: 'Lei' },
  { label: 'Leiben', value: 'Leiben' },
  { label: 'Leibertingen', value: 'Leibertingen' },
  { label: 'Leiblfing', value: 'Leiblfing' },
  { label: 'Leibnitz', value: 'Leibnitz' },
  { label: 'Leibstadt', value: 'Leibstadt' },
  { label: 'Leicester', value: 'Leicester' },
  { label: 'Leicester', value: 'Leicester' },
  { label: 'Leicester', value: 'Leicester' },
  { label: 'Leicestershire', value: 'Leicestershire' },
  { label: 'Leicești', value: 'Leicești' },
  { label: 'Leichhardt', value: 'Leichhardt' },
  { label: 'Leichhardt', value: 'Leichhardt' },
  { label: 'Leichlingen', value: 'Leichlingen' },
  { label: 'Leiden', value: 'Leiden' },
  { label: 'Leiderdorp', value: 'Leiderdorp' },
  { label: 'Leidersbach', value: 'Leidersbach' },
  { label: 'Leifear', value: 'Leifear' },
  { label: 'Leiferde', value: 'Leiferde' },
  { label: 'Leigh', value: 'Leigh' },
  { label: 'Leighton Buzzard', value: 'Leighton Buzzard' },
  { label: 'Leikanger', value: 'Leikanger' },
  { label: 'Leilan', value: 'Leilan' },
  { label: 'Leilani Estates', value: 'Leilani Estates' },
  { label: 'Leimbach', value: 'Leimbach' },
  { label: 'Leimen', value: 'Leimen' },
  { label: 'Leimersheim', value: 'Leimersheim' },
  { label: 'Leimuiden', value: 'Leimuiden' },
  { label: 'Leimuiderbrug', value: 'Leimuiderbrug' },
  { label: 'Leinburg', value: 'Leinburg' },
  { label: 'Leinefelde-Worbis', value: 'Leinefelde-Worbis' },
  { label: 'Leinfelden-Echterdingen', value: 'Leinfelden-Echterdingen' },
  { label: 'Leingarten', value: 'Leingarten' },
  { label: 'Leini', value: 'Leini' },
  { label: 'Leinster', value: 'Leinster' },
  { label: 'Leintz-Gatzaga', value: 'Leintz-Gatzaga' },
  { label: 'Leinzell', value: 'Leinzell' },
  { label: 'Leioa', value: 'Leioa' },
  { label: 'Leipheim', value: 'Leipheim' },
  { label: 'Leipsic', value: 'Leipsic' },
  { label: 'Leipzig', value: 'Leipzig' },
  { label: 'Leirfjord', value: 'Leirfjord' },
  { label: 'Leiria', value: 'Leiria' },
  { label: 'Leiro', value: 'Leiro' },
  { label: 'Leirsund', value: 'Leirsund' },
  { label: 'Leirvik', value: 'Leirvik' },
  { label: 'Leisach', value: 'Leisach' },
  { label: 'Leisnig', value: 'Leisnig' },
  { label: 'Leißling', value: 'Leißling' },
  { label: 'Leiston', value: 'Leiston' },
  { label: 'Leisure City', value: 'Leisure City' },
  { label: 'Leisure Knoll', value: 'Leisure Knoll' },
  { label: 'Leisure Village', value: 'Leisure Village' },
  { label: 'Leisure Village East', value: 'Leisure Village East' },
  { label: 'Leisure Village West-Pine Lake Park', value: 'Leisure Village West-Pine Lake Park' },
  { label: 'Leisure World', value: 'Leisure World' },
  { label: 'Leisuretowne', value: 'Leisuretowne' },
  { label: 'Leitchfield', value: 'Leitchfield' },
  { label: 'Leitendorf', value: 'Leitendorf' },
  { label: 'Leitersdorf Im Raabtal', value: 'Leitersdorf Im Raabtal' },
  { label: 'Leith Hall', value: 'Leith Hall' },
  { label: 'Leithaprodersdorf', value: 'Leithaprodersdorf' },
  { label: 'Leith-Hatfield', value: 'Leith-Hatfield' },
  { label: 'Leitring', value: 'Leitring' },
  { label: 'Leitza', value: 'Leitza' },
  { label: 'Leitzersdorf', value: 'Leitzersdorf' },
  { label: 'Leitzkau', value: 'Leitzkau' },
  { label: 'Leiva', value: 'Leiva' },
  { label: 'Leiva', value: 'Leiva' },
  { label: 'Leivi', value: 'Leivi' },
  { label: 'Leivonmäki', value: 'Leivonmäki' },
  { label: 'Leiwen', value: 'Leiwen' },
  { label: 'Leixlip', value: 'Leixlip' },
  { label: 'Leiyang', value: 'Leiyang' },
  { label: 'Lejamaní', value: 'Lejamaní' },
  { label: 'Lejanías', value: 'Lejanías' },
  { label: 'Lejre', value: 'Lejre' },
  { label: 'Lejre Kommune', value: 'Lejre Kommune' },
  { label: 'Lejweleputswa District Municipality', value: 'Lejweleputswa District Municipality' },
  { label: 'Leka', value: 'Leka' },
  { label: 'Łęka Opatowska', value: 'Łęka Opatowska' },
  { label: 'Lekarstvennoye', value: 'Lekarstvennoye' },
  { label: 'Łękawica', value: 'Łękawica' },
  { label: 'Lekeitio', value: 'Lekeitio' },
  { label: 'Lekenik', value: 'Lekenik' },
  { label: 'Lékhaio', value: 'Lékhaio' },
  { label: 'Łęki', value: 'Łęki' },
  { label: 'Łęki Dolne', value: 'Łęki Dolne' },
  { label: 'Łęki Górne', value: 'Łęki Górne' },
  { label: 'Łęki Szlacheckie', value: 'Łęki Szlacheckie' },
  { label: 'Lekinioch Municipality', value: 'Lekinioch Municipality' },
  { label: 'Lekki', value: 'Lekki' },
  { label: 'Leknes', value: 'Leknes' },
  { label: 'Łęknica', value: 'Łęknica' },
  { label: 'Lékoni', value: 'Lékoni' },
  { label: 'Leksand', value: 'Leksand' },
  { label: 'Leku', value: 'Leku' },
  { label: 'Lekunberri', value: 'Lekunberri' },
  { label: 'Leland', value: 'Leland' },
  { label: 'Leland', value: 'Leland' },
  { label: 'Leland', value: 'Leland' },
  { label: 'Leland', value: 'Leland' },
  { label: 'Leland Grove', value: 'Leland Grove' },
  { label: 'Leleasca', value: 'Leleasca' },
  { label: 'Lelekovice', value: 'Lelekovice' },
  { label: 'Lelese', value: 'Lelese' },
  { label: 'Leleşti', value: 'Leleşti' },
  { label: 'Leliana', value: 'Leliana' },
  { label: 'Leliceni', value: 'Leliceni' },
  { label: 'Lelkowo', value: 'Lelkowo' },
  { label: 'Lélouma', value: 'Lélouma' },
  { label: 'Lelouma Prefecture', value: 'Lelouma Prefecture' },
  { label: 'Lelów', value: 'Lelów' },
  { label: 'Lelu Municipality', value: 'Lelu Municipality' },
  { label: 'Lely', value: 'Lely' },
  { label: 'Lely Resort', value: 'Lely Resort' },
  { label: 'Lelydorp', value: 'Lelydorp' },
  { label: 'Lelystad', value: 'Lelystad' },
  { label: 'Lemay', value: 'Lemay' },
  { label: 'Lemazan', value: 'Lemazan' },
  { label: 'Lembach', value: 'Lembach' },
  { label: 'Lembach Im Mühlkreis', value: 'Lembach Im Mühlkreis' },
  { label: 'Lembang', value: 'Lembang' },
  { label: 'Lembar', value: 'Lembar' },
  { label: 'Lembeni', value: 'Lembeni' },
  { label: 'Lemberg', value: 'Lemberg' },
  { label: 'Lemberg', value: 'Lemberg' },
  { label: 'Lembras', value: 'Lembras' },
  { label: 'Leme', value: 'Leme' },
  { label: 'Leme Do Prado', value: 'Leme Do Prado' },
  { label: 'Lemery', value: 'Lemery' },
  { label: 'Lemery', value: 'Lemery' },
  { label: 'Lemesós', value: 'Lemesós' },
  { label: 'Lemförde', value: 'Lemförde' },
  { label: 'Lemgo', value: 'Lemgo' },
  { label: 'Lemhi County', value: 'Lemhi County' },
  { label: 'Lemi', value: 'Lemi' },
  { label: 'Lemie', value: 'Lemie' },
  { label: 'Lemland', value: 'Lemland' },
  { label: 'Lemmer', value: 'Lemmer' },
  { label: 'Lemmon', value: 'Lemmon' },
  { label: 'Lemmon Valley', value: 'Lemmon Valley' },
  { label: 'Lemna', value: 'Lemna' },
  { label: 'Lemnia', value: 'Lemnia' },
  { label: 'Lemoa', value: 'Lemoa' },
  { label: 'Lemoiz', value: 'Lemoiz' },
  { label: 'Lemon Grove', value: 'Lemon Grove' },
  { label: 'Lemon Tree Passage', value: 'Lemon Tree Passage' },
  { label: 'Lemont', value: 'Lemont' },
  { label: 'Lemont', value: 'Lemont' },
  { label: 'Lemoore', value: 'Lemoore' },
  { label: 'Lemoore Station', value: 'Lemoore Station' },
  { label: 'Lemoyne', value: 'Lemoyne' },
  { label: 'Lempäälä', value: 'Lempäälä' },
  { label: 'Lempdes-Sur-Allagnon', value: 'Lempdes-Sur-Allagnon' },
  { label: 'Lempster', value: 'Lempster' },
  { label: 'Lemsahl-Mellingstedt', value: 'Lemsahl-Mellingstedt' },
  { label: 'Lemta', value: 'Lemta' },
  { label: 'Lemu', value: 'Lemu' },
  { label: 'Lemvig', value: 'Lemvig' },
  { label: 'Lemvig Kommune', value: 'Lemvig Kommune' },
  { label: 'Lemwerder', value: 'Lemwerder' },
  { label: 'Lena', value: 'Lena' },
  { label: 'Lena', value: 'Lena' },
  { label: 'Lena', value: 'Lena' },
  { label: 'Lenah Valley', value: 'Lenah Valley' },
  { label: 'Lenape Heights', value: 'Lenape Heights' },
  { label: 'Lenart V Slov. Goricah', value: 'Lenart V Slov. Goricah' },
  { label: 'Lenauheim', value: 'Lenauheim' },
  { label: 'Lenawee County', value: 'Lenawee County' },
  { label: 'Lenbe', value: 'Lenbe' },
  { label: 'Lenchwe Le Tau', value: 'Lenchwe Le Tau' },
  { label: 'Lencloître', value: 'Lencloître' },
  { label: 'Lençóis', value: 'Lençóis' },
  { label: 'Lençóis Paulista', value: 'Lençóis Paulista' },
  { label: 'Leńcze', value: 'Leńcze' },
  { label: 'Lend', value: 'Lend' },
  { label: 'Lend', value: 'Lend' },
  { label: 'Lendava', value: 'Lendava' },
  { label: 'Lendeh', value: 'Lendeh' },
  { label: 'Lendelede', value: 'Lendelede' },
  { label: 'Lendinara', value: 'Lendinara' },
  { label: 'Lendorf', value: 'Lendorf' },
  { label: 'Lenešice', value: 'Lenešice' },
  { label: 'Lenexa', value: 'Lenexa' },
  { label: 'Lengdorf', value: 'Lengdorf' },
  { label: 'Lengede', value: 'Lengede' },
  { label: 'Lengefeld', value: 'Lengefeld' },
  { label: 'Lengenfeld', value: 'Lengenfeld' },
  { label: 'Lengenfeld', value: 'Lengenfeld' },
  { label: 'Lengenwang', value: 'Lengenwang' },
  { label: 'Lenger', value: 'Lenger' },
  { label: 'Lengerich', value: 'Lengerich' },
  { label: 'Lengerich', value: 'Lengerich' },
  { label: 'Lengfelden', value: 'Lengfelden' },
  { label: 'Lenggries', value: 'Lenggries' },
  { label: 'Lengnau', value: 'Lengnau' },
  { label: 'Lengshuijiang', value: 'Lengshuijiang' },
  { label: 'Lengshuitan', value: 'Lengshuitan' },
  { label: 'Lenguazaque', value: 'Lenguazaque' },
  { label: 'Lengyeltóti', value: 'Lengyeltóti' },
  { label: 'Lenham', value: 'Lenham' },
  { label: 'Lenhovda', value: 'Lenhovda' },
  { label: 'Leni', value: 'Leni' },
  { label: 'Lenin District', value: 'Lenin District' },
  { label: 'Lenina', value: 'Lenina' },
  { label: 'Lenina', value: 'Lenina' },
  { label: 'Leninaul', value: 'Leninaul' },
  { label: 'Lenine Raion', value: 'Lenine Raion' },
  { label: 'Leningradskaya', value: 'Leningradskaya' },
  { label: 'Leninkent', value: 'Leninkent' },
  { label: 'Lenino', value: 'Lenino' },
  { label: 'Leninogorsk', value: 'Leninogorsk' },
  { label: 'Leninogorskiy Rayon', value: 'Leninogorskiy Rayon' },
  { label: 'Leninsk', value: 'Leninsk' },
  { label: 'Leninsk', value: 'Leninsk' },
  { label: 'Leninskiy', value: 'Leninskiy' },
  { label: 'Leninskiy', value: 'Leninskiy' },
  { label: 'Leninskiy', value: 'Leninskiy' },
  { label: 'Leninskiy Rayon', value: 'Leninskiy Rayon' },
  { label: 'Leninskiy Rayon', value: 'Leninskiy Rayon' },
  { label: 'Leninskiye Gory', value: 'Leninskiye Gory' },
  { label: 'Leninsk-Kuznetskiy Rayon', value: 'Leninsk-Kuznetskiy Rayon' },
  { label: 'Leninsk-Kuznetsky', value: 'Leninsk-Kuznetsky' },
  { label: 'Leninskoye', value: 'Leninskoye' },
  { label: 'Leninskoye', value: 'Leninskoye' },
  { label: 'Leninskoye', value: 'Leninskoye' },
  { label: 'Leninskoye', value: 'Leninskoye' },
  { label: 'Leninskoye', value: 'Leninskoye' },
  { label: 'Leniș', value: 'Leniș' },
  { label: 'Lenjan', value: 'Lenjan' },
  { label: 'Lenk', value: 'Lenk' },
  { label: 'Lenna', value: 'Lenna' },
  { label: 'Lennec', value: 'Lennec' },
  { label: 'Lennec', value: 'Lennec' },
  { label: 'Lennestadt', value: 'Lennestadt' },
  { label: 'Lenningen', value: 'Lenningen' },
  { label: 'Lenningen', value: 'Lenningen' },
  { label: 'Lenno', value: 'Lenno' },
  { label: 'Lennox', value: 'Lennox' },
  { label: 'Lennox', value: 'Lennox' },
  { label: 'Lennox Head', value: 'Lennox Head' },
  { label: 'Lennoxtown', value: 'Lennoxtown' },
  { label: 'Lenny Hill', value: 'Lenny Hill' },
  { label: 'Leno', value: 'Leno' },
  { label: 'Lenoir', value: 'Lenoir' },
  { label: 'Lenoir City', value: 'Lenoir City' },
  { label: 'Lenoir County', value: 'Lenoir County' },
  { label: 'Lenola', value: 'Lenola' },
  { label: 'Lenox', value: 'Lenox' },
  { label: 'Lenox', value: 'Lenox' },
  { label: 'Lenox Bigwoods', value: 'Lenox Bigwoods' },
  { label: 'Lens', value: 'Lens' },
  { label: 'Lens', value: 'Lens' },
  { label: 'Lens', value: 'Lens' },
  { label: 'Lensahn', value: 'Lensahn' },
  { label: 'Lensk', value: 'Lensk' },
  { label: 'Lenskiy Rayon', value: 'Lenskiy Rayon' },
  { label: 'Lent', value: 'Lent' },
  { label: 'Lent', value: 'Lent' },
  { label: 'Lent’Ekhi', value: 'Lent’Ekhi' },
  { label: 'Lenta', value: 'Lenta' },
  { label: 'Lentate Sul Seveso', value: 'Lentate Sul Seveso' },
  { label: 'Lentävänniemi', value: 'Lentävänniemi' },
  { label: 'Lentegí', value: 'Lentegí' },
  { label: 'Lentella', value: 'Lentella' },
  { label: 'Lentföhrden', value: 'Lentföhrden' },
  { label: 'Lenti', value: 'Lenti' },
  { label: 'Lenti Járás', value: 'Lenti Járás' },
  { label: 'Lentiai', value: 'Lentiai' },
  { label: 'Lentigione-Sorbolo A Mane', value: 'Lentigione-Sorbolo A Mane' },
  { label: 'Lentigny', value: 'Lentigny' },
  { label: 'Lentilly', value: 'Lentilly' },
  { label: 'Lentini', value: 'Lentini' },
  { label: 'Lents', value: 'Lents' },
  { label: 'Lentvaris', value: 'Lentvaris' },
  { label: 'Lenughi', value: 'Lenughi' },
  { label: 'Lenvik', value: 'Lenvik' },
  { label: 'Lenwood', value: 'Lenwood' },
  { label: 'Lenzburg', value: 'Lenzburg' },
  { label: 'Lenzen', value: 'Lenzen' },
  { label: 'Lenzerheide', value: 'Lenzerheide' },
  { label: 'Lenzie', value: 'Lenzie' },
  { label: 'Lenzing', value: 'Lenzing' },
  { label: 'Lenzkirch', value: 'Lenzkirch' },
  { label: 'Léo', value: 'Léo' },
  { label: 'Leoben', value: 'Leoben' },
  { label: 'Leobendorf', value: 'Leobendorf' },
  { label: 'Leobersdorf', value: 'Leobersdorf' },
  { label: 'Leoberto Leal', value: 'Leoberto Leal' },
  { label: 'Leo-Cedarville', value: 'Leo-Cedarville' },
  { label: 'Léogâne', value: 'Léogâne' },
  { label: 'Leogang', value: 'Leogang' },
  { label: 'Léognan', value: 'Léognan' },
  { label: 'Leola', value: 'Leola' },
  { label: 'Leola', value: 'Leola' },
  { label: 'Leomil', value: 'Leomil' },
  { label: 'Leominster', value: 'Leominster' },
  { label: 'Leominster', value: 'Leominster' },
  { label: 'Leon', value: 'Leon' },
  { label: 'Leon', value: 'Leon' },
  { label: 'León', value: 'León' },
  { label: 'León', value: 'León' },
  { label: 'León', value: 'León' },
  { label: 'Léon', value: 'Léon' },
  { label: 'León Cortés', value: 'León Cortés' },
  { label: 'Leon County', value: 'Leon County' },
  { label: 'Leon County', value: 'Leon County' },
  { label: 'León De Los Aldama', value: 'León De Los Aldama' },
  { label: 'León Guzmán', value: 'León Guzmán' },
  { label: 'Leon Postigo', value: 'Leon Postigo' },
  { label: 'Leon Valley', value: 'Leon Valley' },
  { label: 'Leona Valley', value: 'Leona Valley' },
  { label: 'Leona Vicario', value: 'Leona Vicario' },
  { label: 'Leona Vicario', value: 'Leona Vicario' },
  { label: 'Leonard', value: 'Leonard' },
  { label: 'Leonardo', value: 'Leonardo' },
  { label: 'Leonardtown', value: 'Leonardtown' },
  { label: 'Leonárisso', value: 'Leonárisso' },
  { label: 'Leonay', value: 'Leonay' },
  { label: 'Leonberg', value: 'Leonberg' },
  { label: 'Leonberg', value: 'Leonberg' },
  { label: 'Leondárion', value: 'Leondárion' },
  { label: 'Leonding', value: 'Leonding' },
  { label: 'Leones', value: 'Leones' },
  { label: 'Leones Arriba', value: 'Leones Arriba' },
  { label: 'Leones Arriba', value: 'Leones Arriba' },
  { label: 'Leones East', value: 'Leones East' },
  { label: 'Leones East', value: 'Leones East' },
  { label: 'Leone-Santa Elisabetta', value: 'Leone-Santa Elisabetta' },
  { label: 'Leonessa', value: 'Leonessa' },
  { label: 'Leonforte', value: 'Leonforte' },
  { label: 'Leongatha', value: 'Leongatha' },
  { label: 'Leonia', value: 'Leonia' },
  { label: 'Leonídio', value: 'Leonídio' },
  { label: 'Leonidovo', value: 'Leonidovo' },
  { label: 'Leonora', value: 'Leonora' },
  { label: 'Leonovo', value: 'Leonovo' },
  { label: 'Leonstein', value: 'Leonstein' },
  { label: 'Leonville', value: 'Leonville' },
  { label: 'Leopold', value: 'Leopold' },
  { label: 'Leopoldina', value: 'Leopoldina' },
  { label: 'Leopoldo De Bulhões', value: 'Leopoldo De Bulhões' },
  { label: 'Leopoldov', value: 'Leopoldov' },
  { label: 'Leopoldsburg', value: 'Leopoldsburg' },
  { label: 'Leopoldsdorf', value: 'Leopoldsdorf' },
  { label: 'Leopoldsdorf Im Marchfelde', value: 'Leopoldsdorf Im Marchfelde' },
  { label: 'Leopoldshöhe', value: 'Leopoldshöhe' },
  { label: 'Leópolis', value: 'Leópolis' },
  { label: 'Leorda', value: 'Leorda' },
  { label: 'Leordeni', value: 'Leordeni' },
  { label: 'Leordeni', value: 'Leordeni' },
  { label: 'Leordina', value: 'Leordina' },
  { label: 'Leorința', value: 'Leorința' },
  { label: 'Leorința-Șăulia', value: 'Leorința-Șăulia' },
  { label: 'Leoti', value: 'Leoti' },
  { label: 'Leoz/Leotz', value: 'Leoz/Leotz' },
  { label: 'Lepa', value: 'Lepa' },
  { label: 'Lepa', value: 'Lepa' },
  { label: 'Lepaera', value: 'Lepaera' },
  { label: 'Lepan', value: 'Lepan' },
  { label: 'Lepanto', value: 'Lepanto' },
  { label: 'Lepanto', value: 'Lepanto' },
  { label: 'Lepaterique', value: 'Lepaterique' },
  { label: 'Lepe', value: 'Lepe' },
  { label: 'Lepelstraat', value: 'Lepelstraat' },
  { label: 'Lepenoú', value: 'Lepenoú' },
  { label: 'Lepindea', value: 'Lepindea' },
  { label: 'Lépiphanie', value: 'Lépiphanie' },
  { label: 'Lepley', value: 'Lepley' },
  { label: 'Lepoglava', value: 'Lepoglava' },
  { label: 'Leporano', value: 'Leporano' },
  { label: 'Leporano Marina', value: 'Leporano Marina' },
  { label: 'Leppen', value: 'Leppen' },
  { label: 'Leppington', value: 'Leppington' },
  { label: 'Lepsény', value: 'Lepsény' },
  { label: 'Lepsy', value: 'Lepsy' },
  { label: 'Leptokaryá', value: 'Leptokaryá' },
  { label: 'Lepuix', value: 'Lepuix' },
  { label: 'Lequidoe', value: 'Lequidoe' },
  { label: 'Lequile', value: 'Lequile' },
  { label: 'Lequio Berria', value: 'Lequio Berria' },
  { label: 'Lequio Tanaro', value: 'Lequio Tanaro' },
  { label: 'Lercara Friddi', value: 'Lercara Friddi' },
  { label: 'Lerchenfeld', value: 'Lerchenfeld' },
  { label: 'Lerdo De Tejada', value: 'Lerdo De Tejada' },
  { label: 'Lere', value: 'Lere' },
  { label: 'Lere', value: 'Lere' },
  { label: 'Léré', value: 'Léré' },
  { label: 'Lereşti', value: 'Lereşti' },
  { label: 'Lerga', value: 'Lerga' },
  { label: 'Leribe', value: 'Leribe' },
  { label: 'Lerici', value: 'Lerici' },
  { label: 'Lérida', value: 'Lérida' },
  { label: 'Lerik', value: 'Lerik' },
  { label: 'Lerín', value: 'Lerín' },
  { label: 'Lerma', value: 'Lerma' },
  { label: 'Lerma', value: 'Lerma' },
  { label: 'Lerma', value: 'Lerma' },
  { label: 'Lerma De Villada', value: 'Lerma De Villada' },
  { label: 'Lermontov', value: 'Lermontov' },
  { label: 'Lermontovka', value: 'Lermontovka' },
  { label: 'Lermontovo', value: 'Lermontovo' },
  { label: 'Lermontovo', value: 'Lermontovo' },
  { label: 'Lermoos', value: 'Lermoos' },
  { label: 'Lérouville', value: 'Lérouville' },
  { label: 'Lerrnakert', value: 'Lerrnakert' },
  { label: 'Lerrnanist', value: 'Lerrnanist' },
  { label: 'Lerrnants’K’', value: 'Lerrnants’K’' },
  { label: 'Lerrnapat', value: 'Lerrnapat' },
  { label: 'Lerrnavan', value: 'Lerrnavan' },
  { label: 'Lerum', value: 'Lerum' },
  { label: 'Lervik', value: 'Lervik' },
  { label: 'Lerwick', value: 'Lerwick' },
  { label: 'Léry', value: 'Léry' },
  { label: 'Les', value: 'Les' },
  { label: 'Les Abrets', value: 'Les Abrets' },
  { label: 'Les Abricots', value: 'Les Abricots' },
  { label: 'Les Accates', value: 'Les Accates' },
  { label: 'Les Ageux', value: 'Les Ageux' },
  { label: 'Les Aix-D’Angillon', value: 'Les Aix-D’Angillon' },
  { label: 'Les Allues', value: 'Les Allues' },
  { label: 'Les Alluets-Le-Roi', value: 'Les Alluets-Le-Roi' },
  { label: 'Les Ancizes', value: 'Les Ancizes' },
  { label: 'Les Andelys', value: 'Les Andelys' },
  { label: 'Les Anglais', value: 'Les Anglais' },
  { label: 'Les Angles', value: 'Les Angles' },
  { label: 'Les Arcs', value: 'Les Arcs' },
  { label: 'Les Arnavaux', value: 'Les Arnavaux' },
  { label: 'Les Artigues-De-Lussac', value: 'Les Artigues-De-Lussac' },
  { label: 'Les Attaques', value: 'Les Attaques' },
  { label: 'Les Authieux-Sur-Le-Port-Saint-Ouen', value: 'Les Authieux-Sur-Le-Port-Saint-Ouen' },
  { label: 'Les Avanchets', value: 'Les Avanchets' },
  { label: 'Les Avenières', value: 'Les Avenières' },
  { label: 'Les Aygalades', value: 'Les Aygalades' },
  { label: 'Les Baumettes', value: 'Les Baumettes' },
  { label: 'Les Bois', value: 'Les Bois' },
  { label: 'Les Brenets', value: 'Les Brenets' },
  { label: 'Les Breuleux', value: 'Les Breuleux' },
  { label: 'Les Brouzils', value: 'Les Brouzils' },
  { label: 'Les Caillols', value: 'Les Caillols' },
  { label: 'Les Camoins', value: 'Les Camoins' },
  { label: 'Les Cayes', value: 'Les Cayes' },
  { label: 'Les Cèdres', value: 'Les Cèdres' },
  { label: 'Les Champs-Géraux', value: 'Les Champs-Géraux' },
  { label: 'Les Chartreux', value: 'Les Chartreux' },
  { label: 'Les Chères', value: 'Les Chères' },
  { label: 'Les Clayes-Sous-Bois', value: 'Les Clayes-Sous-Bois' },
  { label: 'Les Clouzeaux', value: 'Les Clouzeaux' },
  { label: 'Les Contamines-Montjoie', value: 'Les Contamines-Montjoie' },
  { label: 'Les Coteaux', value: 'Les Coteaux' },
  { label: 'Les Côtes-Darey', value: 'Les Côtes-Darey' },
  { label: 'Les Crottes', value: 'Les Crottes' },
  { label: 'Les Deux Alpes', value: 'Les Deux Alpes' },
  { label: 'Les Échelles', value: 'Les Échelles' },
  { label: 'Les Églisottes-Et-Chalaures', value: 'Les Églisottes-Et-Chalaures' },
  { label: 'Les Epesses', value: 'Les Epesses' },
  { label: 'Les Escaldes', value: 'Les Escaldes' },
  { label: 'Les Escoumins', value: 'Les Escoumins' },
  { label: 'Les Essarts', value: 'Les Essarts' },
  { label: 'Les Essarts-Le-Roi', value: 'Les Essarts-Le-Roi' },
  { label: 'Les Fins', value: 'Les Fins' },
  { label: 'Les Forges', value: 'Les Forges' },
  { label: 'Les Fourgs', value: 'Les Fourgs' },
  { label: 'Les Geneveys-Sur-Coffrane', value: 'Les Geneveys-Sur-Coffrane' },
  { label: 'Les Gets', value: 'Les Gets' },
  { label: 'Les Gonds', value: 'Les Gonds' },
  { label: 'Les Grandes-Ventes', value: 'Les Grandes-Ventes' },
  { label: 'Les Grands Carmes', value: 'Les Grands Carmes' },
  { label: 'Les Hautes-Rivières', value: 'Les Hautes-Rivières' },
  { label: 'Les Herbiers', value: 'Les Herbiers' },
  { label: 'Les Houches', value: 'Les Houches' },
  { label: 'Les Irois', value: 'Les Irois' },
  { label: 'Les Landes-Genusson', value: 'Les Landes-Genusson' },
  { label: 'Les Lilas', value: 'Les Lilas' },
  { label: 'Les Loges', value: 'Les Loges' },
  { label: 'Les Loges-En-Josas', value: 'Les Loges-En-Josas' },
  { label: 'Les Loges-Marchis', value: 'Les Loges-Marchis' },
  { label: 'Les Lucs-Sur-Boulogne', value: 'Les Lucs-Sur-Boulogne' },
  { label: 'Les Mages', value: 'Les Mages' },
  { label: 'Les Magnils-Reigniers', value: 'Les Magnils-Reigniers' },
  { label: 'Les Marches', value: 'Les Marches' },
  { label: 'Les Martres-Dartière', value: 'Les Martres-Dartière' },
  { label: 'Les Matelles', value: 'Les Matelles' },
  { label: 'Les Mathes', value: 'Les Mathes' },
  { label: 'Les Médecins', value: 'Les Médecins' },
  { label: 'Les Mées', value: 'Les Mées' },
  { label: 'Les Molières', value: 'Les Molières' },
  { label: 'Les Montils', value: 'Les Montils' },
  { label: 'Les Moutiers-En-Retz', value: 'Les Moutiers-En-Retz' },
  { label: 'Les Mureaux', value: 'Les Mureaux' },
  { label: 'Les Noës-Près-Troyes', value: 'Les Noës-Près-Troyes' },
  { label: 'Les Olives', value: 'Les Olives' },
  { label: 'Les Ormes', value: 'Les Ormes' },
  { label: 'Les Pailles', value: 'Les Pailles' },
  { label: 'Les Pavillons-Sous-Bois', value: 'Les Pavillons-Sous-Bois' },
  { label: 'Les Peintures', value: 'Les Peintures' },
  { label: 'Les Pennes-Mirabeau', value: 'Les Pennes-Mirabeau' },
  { label: 'Les Pieux', value: 'Les Pieux' },
  { label: 'Les Ponts-De-Cé', value: 'Les Ponts-De-Cé' },
  { label: 'Les Ponts-De-Martel', value: 'Les Ponts-De-Martel' },
  { label: 'Les Riceys', value: 'Les Riceys' },
  { label: 'Les Roches-De-Condrieu', value: 'Les Roches-De-Condrieu' },
  { label: 'Les Rosiers-Sur-Loire', value: 'Les Rosiers-Sur-Loire' },
  { label: 'Les Rousses', value: 'Les Rousses' },
  { label: 'Les Sables-D’Olonne', value: 'Les Sables-D’Olonne' },
  { label: 'Les Salines', value: 'Les Salines' },
  { label: 'Les Salles-Du-Gardon', value: 'Les Salles-Du-Gardon' },
  { label: 'Les Sorinières', value: 'Les Sorinières' },
  { label: 'Les Touches', value: 'Les Touches' },
  { label: 'Les Trois-Lucs', value: 'Les Trois-Lucs' },
  { label: 'Les Ulis', value: 'Les Ulis' },
  { label: 'Les Vans', value: 'Les Vans' },
  { label: 'Lesa', value: 'Lesa' },
  { label: 'Lesachtal', value: 'Lesachtal' },
  { label: 'Lesage', value: 'Lesage' },
  { label: 'Lesaka', value: 'Lesaka' },
  { label: 'Lesbury', value: 'Lesbury' },
  { label: 'Lescalier', value: 'Lescalier' },
  { label: 'Lescar', value: 'Lescar' },
  { label: 'Lesce', value: 'Lesce' },
  { label: 'Leschenault', value: 'Leschenault' },
  { label: 'Lesegno', value: 'Lesegno' },
  { label: 'Leshan', value: 'Leshan' },
  { label: 'Leshukonskiy Rayon', value: 'Leshukonskiy Rayon' },
  { label: 'Leshukonskoye', value: 'Leshukonskoye' },
  { label: 'Lesichovo', value: 'Lesichovo' },
  { label: 'Lesignano Debagni', value: 'Lesignano Debagni' },
  { label: 'Lésigny', value: 'Lésigny' },
  { label: 'Lesina', value: 'Lesina' },
  { label: 'Lesja', value: 'Lesja' },
  { label: 'Leskenskiy Rayon', value: 'Leskenskiy Rayon' },
  { label: 'Lesko', value: 'Lesko' },
  { label: 'Leskolovo', value: 'Leskolovo' },
  { label: 'Leskova Dolina', value: 'Leskova Dolina' },
  { label: 'Leskovac', value: 'Leskovac' },
  { label: 'Leskovec Pri Krškem', value: 'Leskovec Pri Krškem' },
  { label: 'Leskovik', value: 'Leskovik' },
  { label: 'Leslie', value: 'Leslie' },
  { label: 'Leslie', value: 'Leslie' },
  { label: 'Leslie County', value: 'Leslie County' },
  { label: 'Leslie Land', value: 'Leslie Land' },
  { label: 'Lesmahagow', value: 'Lesmahagow' },
  { label: 'Lesmo', value: 'Lesmo' },
  { label: 'Lesmurdie', value: 'Lesmurdie' },
  { label: 'Lesna', value: 'Lesna' },
  { label: 'Leśna', value: 'Leśna' },
  { label: 'Leśna', value: 'Leśna' },
  { label: 'Lešná', value: 'Lešná' },
  { label: 'Leśna Podlaska', value: 'Leśna Podlaska' },
  { label: 'Lesneven', value: 'Lesneven' },
  { label: 'Leśnica', value: 'Leśnica' },
  { label: 'Leśnica', value: 'Leśnica' },
  { label: 'Lešnica', value: 'Lešnica' },
  { label: 'Leśnica-Ratyń-Pustki', value: 'Leśnica-Ratyń-Pustki' },
  { label: 'Lesnikovo', value: 'Lesnikovo' },
  { label: 'Lesnovka', value: 'Lesnovka' },
  { label: 'Lesnoy', value: 'Lesnoy' },
  { label: 'Lesnoy', value: 'Lesnoy' },
  { label: 'Lesnoy', value: 'Lesnoy' },
  { label: 'Lesnoy', value: 'Lesnoy' },
  { label: 'Lesnoy Gorodok', value: 'Lesnoy Gorodok' },
  { label: 'Lesnoy Rayon', value: 'Lesnoy Rayon' },
  { label: 'Lesnoye', value: 'Lesnoye' },
  { label: 'Lesnoye', value: 'Lesnoye' },
  { label: 'Lesnyye Polyany', value: 'Lesnyye Polyany' },
  { label: 'Lesnyye Polyany', value: 'Lesnyye Polyany' },
  { label: 'Lesogorsk', value: 'Lesogorsk' },
  { label: 'Lesogorskiy', value: 'Lesogorskiy' },
  { label: 'Lesosibirsk', value: 'Lesosibirsk' },
  { label: 'Lesozavodsk', value: 'Lesozavodsk' },
  { label: 'Lesparre-Médoc', value: 'Lesparre-Médoc' },
  { label: 'Lesperance', value: 'Lesperance' },
  { label: 'Lespezi', value: 'Lespezi' },
  { label: 'Lespezi', value: 'Lespezi' },
  { label: 'Lespezi', value: 'Lespezi' },
  { label: 'Lespignan', value: 'Lespignan' },
  { label: 'Lespinasse', value: 'Lespinasse' },
  { label: 'Lesquin', value: 'Lesquin' },
  { label: 'Lessay', value: 'Lessay' },
  { label: 'Lessebo', value: 'Lessebo' },
  { label: 'Lessines', value: 'Lessines' },
  { label: 'Lesslie', value: 'Lesslie' },
  { label: 'Lessolo', value: 'Lessolo' },
  { label: 'Lessona', value: 'Lessona' },
  { label: 'Lestans', value: 'Lestans' },
  { label: 'Lestaque', value: 'Lestaque' },
  { label: 'Lestelle-Bétharram', value: 'Lestelle-Bétharram' },
  { label: 'Lester Prairie', value: 'Lester Prairie' },
  { label: 'Lestijärvi', value: 'Lestijärvi' },
  { label: 'Leština', value: 'Leština' },
  { label: 'Lestizza', value: 'Lestizza' },
  { label: 'Lestrem', value: 'Lestrem' },
  { label: 'Leşu', value: 'Leşu' },
  { label: 'Leszno', value: 'Leszno' },
  { label: 'Leszno', value: 'Leszno' },
  { label: 'Lesznowola', value: 'Lesznowola' },
  { label: 'Létavértes', value: 'Létavértes' },
  { label: 'Letca', value: 'Letca' },
  { label: 'Letca Nouă', value: 'Letca Nouă' },
  { label: 'Letca Veche', value: 'Letca Veche' },
  { label: 'Leţcani', value: 'Leţcani' },
  { label: 'Letcher County', value: 'Letcher County' },
  { label: 'Letchworth', value: 'Letchworth' },
  { label: 'Letchworth Garden City', value: 'Letchworth Garden City' },
  { label: 'Letea Veche', value: 'Letea Veche' },
  { label: 'Letefoho', value: 'Letefoho' },
  { label: 'Letenye', value: 'Letenye' },
  { label: 'Letenyei Járás', value: 'Letenyei Járás' },
  { label: 'Leteri', value: 'Leteri' },
  { label: 'Letham', value: 'Letham' },
  { label: 'Lethbridge', value: 'Lethbridge' },
  { label: 'Lethbridge Park', value: 'Lethbridge Park' },
  { label: 'Lethem', value: 'Lethem' },
  { label: 'Leticia', value: 'Leticia' },
  { label: 'Letino', value: 'Letino' },
  { label: 'Letka', value: 'Letka' },
  { label: 'Letlhakane', value: 'Letlhakane' },
  { label: 'Letlhakeng', value: 'Letlhakeng' },
  { label: 'Letňany', value: 'Letňany' },
  { label: 'Letnik', value: 'Letnik' },
  { label: 'Letnitsa', value: 'Letnitsa' },
  { label: 'Letnyaya Stavka', value: 'Letnyaya Stavka' },
  { label: 'Letohrad', value: 'Letohrad' },
  { label: 'Letojanni', value: 'Letojanni' },
  { label: 'Letonice', value: 'Letonice' },
  { label: 'Letovice', value: 'Letovice' },
  { label: 'Łętownia', value: 'Łętownia' },
  { label: 'Łętownia', value: 'Łętownia' },
  { label: 'Letpandan', value: 'Letpandan' },
  { label: 'Letschin', value: 'Letschin' },
  { label: 'Letsheng', value: 'Letsheng' },
  { label: 'Lettere', value: 'Lettere' },
  { label: 'Letterkenny', value: 'Letterkenny' },
  { label: 'Letterston', value: 'Letterston' },
  { label: 'Lettomanoppello', value: 'Lettomanoppello' },
  { label: 'Lettopalena', value: 'Lettopalena' },
  { label: 'Letur', value: 'Letur' },
  { label: 'Letux', value: 'Letux' },
  { label: 'Letychiv', value: 'Letychiv' },
  { label: 'Letzlingen', value: 'Letzlingen' },
  { label: 'Leu', value: 'Leu' },
  { label: 'Léua', value: 'Léua' },
  { label: 'Leubnitz', value: 'Leubnitz' },
  { label: 'Leubsdorf', value: 'Leubsdorf' },
  { label: 'Leubsdorf', value: 'Leubsdorf' },
  { label: 'Leuca', value: 'Leuca' },
  { label: 'Leucate', value: 'Leucate' },
  { label: 'Leuchars', value: 'Leuchars' },
  { label: 'Leuchtenberg', value: 'Leuchtenberg' },
  { label: 'Leudelange', value: 'Leudelange' },
  { label: 'Leudeville', value: 'Leudeville' },
  { label: 'Leuk', value: 'Leuk' },
  { label: 'Leuk District', value: 'Leuk District' },
  { label: 'Leuken', value: 'Leuken' },
  { label: 'Leukerbad', value: 'Leukerbad' },
  { label: 'Leulumoega', value: 'Leulumoega' },
  { label: 'Leumeah', value: 'Leumeah' },
  { label: 'Leun', value: 'Leun' },
  { label: 'Leuna', value: 'Leuna' },
  { label: 'Leunen', value: 'Leunen' },
  { label: 'Leupoldsgrün', value: 'Leupoldsgrün' },
  { label: 'Leura', value: 'Leura' },
  { label: 'Leusden', value: 'Leusden' },
  { label: 'Leutasch', value: 'Leutasch' },
  { label: 'Leuteboro', value: 'Leuteboro' },
  { label: 'Leutenbach', value: 'Leutenbach' },
  { label: 'Leutenbach', value: 'Leutenbach' },
  { label: 'Leutenberg', value: 'Leutenberg' },
  { label: 'Leutersdorf', value: 'Leutersdorf' },
  { label: 'Leutershausen', value: 'Leutershausen' },
  { label: 'Leutesdorf', value: 'Leutesdorf' },
  { label: 'Leuth', value: 'Leuth' },
  { label: 'Leutkirch', value: 'Leutkirch' },
  { label: 'Leutschach', value: 'Leutschach' },
  { label: 'Leuven', value: 'Leuven' },
  { label: 'Leuville-Sur-Orge', value: 'Leuville-Sur-Orge' },
  { label: 'Leuzigen', value: 'Leuzigen' },
  { label: 'Lev Tolstoy', value: 'Lev Tolstoy' },
  { label: 'Levada', value: 'Levada' },
  { label: 'Levadki', value: 'Levadki' },
  { label: 'Leval', value: 'Leval' },
  { label: 'Levallois-Perret', value: 'Levallois-Perret' },
  { label: 'Levanger', value: 'Levanger' },
  { label: 'Levant', value: 'Levant' },
  { label: 'Levanto', value: 'Levanto' },
  { label: 'Levashevo', value: 'Levashevo' },
  { label: 'Levashi', value: 'Levashi' },
  { label: 'Levashinskiy Rayon', value: 'Levashinskiy Rayon' },
  { label: 'Levashovo', value: 'Levashovo' },
  { label: 'Levata', value: 'Levata' },
  { label: 'Levate', value: 'Levate' },
  { label: 'Level Green', value: 'Level Green' },
  { label: 'Level Park-Oak Park', value: 'Level Park-Oak Park' },
  { label: 'Level Plains', value: 'Level Plains' },
  { label: 'Levelek', value: 'Levelek' },
  { label: 'Levelland', value: 'Levelland' },
  { label: 'Leven', value: 'Leven' },
  { label: 'Leven', value: 'Leven' },
  { label: 'Levens', value: 'Levens' },
  { label: 'Leventina District', value: 'Leventina District' },
  { label: 'Leverano', value: 'Leverano' },
  { label: 'Leverett', value: 'Leverett' },
  { label: 'Leverkusen', value: 'Leverkusen' },
  { label: 'Lèves', value: 'Lèves' },
  { label: 'Levet', value: 'Levet' },
  { label: 'Levice', value: 'Levice' },
  { label: 'Levice', value: 'Levice' },
  { label: 'Levico Terme', value: 'Levico Terme' },
  { label: 'Levídion', value: 'Levídion' },
  { label: 'Levier', value: 'Levier' },
  { label: 'Lévignac', value: 'Lévignac' },
  { label: 'Levikha', value: 'Levikha' },
  { label: 'Levin', value: 'Levin' },
  { label: 'Lévis', value: 'Lévis' },
  { label: 'Lévis-Saint-Nom', value: 'Lévis-Saint-Nom' },
  { label: 'Levittown', value: 'Levittown' },
  { label: 'Levittown', value: 'Levittown' },
  { label: 'Levittown', value: 'Levittown' },
  { label: 'Levoberezhnyy', value: 'Levoberezhnyy' },
  { label: 'Levoča', value: 'Levoča' },
  { label: 'Levokumka', value: 'Levokumka' },
  { label: 'Levokumskoye', value: 'Levokumskoye' },
  { label: 'Levone', value: 'Levone' },
  { label: 'Levroux', value: 'Levroux' },
  { label: 'Levski', value: 'Levski' },
  { label: 'Lev-Tolstovskiy Rayon', value: 'Lev-Tolstovskiy Rayon' },
  { label: 'Levuka', value: 'Levuka' },
  { label: 'Levy County', value: 'Levy County' },
  { label: 'Lewarde', value: 'Lewarde' },
  { label: 'Lewenberg', value: 'Lewenberg' },
  { label: 'Lewes', value: 'Lewes' },
  { label: 'Lewes', value: 'Lewes' },
  { label: 'Lewin Brzeski', value: 'Lewin Brzeski' },
  { label: 'Lewin Kłodzki', value: 'Lewin Kłodzki' },
  { label: 'Lewis And Clark County', value: 'Lewis And Clark County' },
  { label: 'Lewis Center', value: 'Lewis Center' },
  { label: 'Lewis County', value: 'Lewis County' },
  { label: 'Lewis County', value: 'Lewis County' },
  { label: 'Lewis County', value: 'Lewis County' },
  { label: 'Lewis County', value: 'Lewis County' },
  { label: 'Lewis County', value: 'Lewis County' },
  { label: 'Lewis County', value: 'Lewis County' },
  { label: 'Lewis County', value: 'Lewis County' },
  { label: 'Lewisburg', value: 'Lewisburg' },
  { label: 'Lewisburg', value: 'Lewisburg' },
  { label: 'Lewisburg', value: 'Lewisburg' },
  { label: 'Lewisburg', value: 'Lewisburg' },
  { label: 'Lewisham', value: 'Lewisham' },
  { label: 'Lewisport', value: 'Lewisport' },
  { label: 'Lewisporte', value: 'Lewisporte' },
  { label: 'Lewiston', value: 'Lewiston' },
  { label: 'Lewiston', value: 'Lewiston' },
  { label: 'Lewiston', value: 'Lewiston' },
  { label: 'Lewiston', value: 'Lewiston' },
  { label: 'Lewiston', value: 'Lewiston' },
  { label: 'Lewiston', value: 'Lewiston' },
  { label: 'Lewiston', value: 'Lewiston' },
  { label: 'Lewiston', value: 'Lewiston' },
  { label: 'Lewiston Orchards', value: 'Lewiston Orchards' },
  { label: 'Lewistown', value: 'Lewistown' },
  { label: 'Lewistown', value: 'Lewistown' },
  { label: 'Lewistown', value: 'Lewistown' },
  { label: 'Lewisville', value: 'Lewisville' },
  { label: 'Lewisville', value: 'Lewisville' },
  { label: 'Lewisville', value: 'Lewisville' },
  { label: 'Lewisville', value: 'Lewisville' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Lexington County', value: 'Lexington County' },
  { label: 'Lexington Hills', value: 'Lexington Hills' },
  { label: 'Lexington Park', value: 'Lexington Park' },
  { label: 'Lexington-Fayette', value: 'Lexington-Fayette' },
  { label: 'Lexy', value: 'Lexy' },
  { label: 'Leyburn', value: 'Leyburn' },
  { label: 'Leyland', value: 'Leyland' },
  { label: 'Leyli Yadegar', value: 'Leyli Yadegar' },
  { label: 'Leyme', value: 'Leyme' },
  { label: 'Leymen', value: 'Leymen' },
  { label: 'Leyment', value: 'Leyment' },
  { label: 'Leysdown-On-Sea', value: 'Leysdown-On-Sea' },
  { label: 'Leysin', value: 'Leysin' },
  { label: 'Leyte', value: 'Leyte' },
  { label: 'Leyte', value: 'Leyte' },
  { label: 'Leytron', value: 'Leytron' },
  { label: 'Leyva Solano', value: 'Leyva Solano' },
  { label: 'Leza', value: 'Leza' },
  { label: 'Leza De Río Leza', value: 'Leza De Río Leza' },
  { label: 'Leżajsk', value: 'Leżajsk' },
  { label: 'Lezama', value: 'Lezama' },
  { label: 'Lézan', value: 'Lézan' },
  { label: 'Lézardrieux', value: 'Lézardrieux' },
  { label: 'Lézat-Sur-Lèze', value: 'Lézat-Sur-Lèze' },
  { label: 'Lezáun', value: 'Lezáun' },
  { label: 'Lezay', value: 'Lezay' },
  { label: 'Lezennes', value: 'Lezennes' },
  { label: 'Lezhë', value: 'Lezhë' },
  { label: 'Lezhnevo', value: 'Lezhnevo' },
  { label: 'Lezhnevskiy Rayon', value: 'Lezhnevskiy Rayon' },
  { label: 'Lezica', value: 'Lezica' },
  { label: 'Lézignan-Corbières', value: 'Lézignan-Corbières' },
  { label: 'Lézignan-La-Cèbe', value: 'Lézignan-La-Cèbe' },
  { label: 'Lézigneux', value: 'Lézigneux' },
  { label: 'Lezo', value: 'Lezo' },
  { label: 'Lezoux', value: 'Lezoux' },
  { label: 'Lezuza', value: 'Lezuza' },
  { label: 'Lezy', value: 'Lezy' },
  { label: 'Lezzeno', value: 'Lezzeno' },
  { label: 'Lgota Wielka', value: 'Lgota Wielka' },
  { label: 'Lhanbryde', value: 'Lhanbryde' },
  { label: 'Lhasa', value: 'Lhasa' },
  { label: 'Lhaÿ-Les-Roses', value: 'Lhaÿ-Les-Roses' },
  { label: 'Lhenice', value: 'Lhenice' },
  { label: 'Lherm', value: 'Lherm' },
  { label: 'Lhermitage', value: 'Lhermitage' },
  { label: 'Lhokseumawe', value: 'Lhokseumawe' },
  { label: 'Lhuentse', value: 'Lhuentse' },
  { label: 'Li Punti-San Giovanni', value: 'Li Punti-San Giovanni' },
  { label: 'Lian', value: 'Lian' },
  { label: 'Liancourt', value: 'Liancourt' },
  { label: 'Lianga', value: 'Lianga' },
  { label: 'Lianga', value: 'Lianga' },
  { label: 'Liangcunchang', value: 'Liangcunchang' },
  { label: 'Lianghu', value: 'Lianghu' },
  { label: 'Liangping District', value: 'Liangping District' },
  { label: 'Liangshan Yizu Zizhizhou', value: 'Liangshan Yizu Zizhizhou' },
  { label: 'Liangxiang', value: 'Liangxiang' },
  { label: 'Liangyaping', value: 'Liangyaping' },
  { label: 'Lianhe', value: 'Lianhe' },
  { label: 'Lianhecun', value: 'Lianhecun' },
  { label: 'Lianjiang', value: 'Lianjiang' },
  { label: 'Lianokládhion', value: 'Lianokládhion' },
  { label: 'Lianovérgi', value: 'Lianovérgi' },
  { label: 'Lianozovo', value: 'Lianozovo' },
  { label: 'Lianran', value: 'Lianran' },
  { label: 'Lianshan', value: 'Lianshan' },
  { label: 'Lianyuan', value: 'Lianyuan' },
  { label: 'Lianyungang', value: 'Lianyungang' },
  { label: 'Lianyungang Shi', value: 'Lianyungang Shi' },
  { label: 'Lianzhou', value: 'Lianzhou' },
  { label: 'Lianzhou', value: 'Lianzhou' },
  { label: 'Lianzhuang', value: 'Lianzhuang' },
  { label: 'Liaocheng', value: 'Liaocheng' },
  { label: 'Liaoyang', value: 'Liaoyang' },
  { label: 'Liaoyuan', value: 'Liaoyuan' },
  { label: 'Liaozhong', value: 'Liaozhong' },
  { label: 'Libacao', value: 'Libacao' },
  { label: 'Libagon', value: 'Libagon' },
  { label: 'Libagon', value: 'Libagon' },
  { label: 'Libáň', value: 'Libáň' },
  { label: 'Líbano', value: 'Líbano' },
  { label: 'Libas', value: 'Libas' },
  { label: 'Libas', value: 'Libas' },
  { label: 'Libas', value: 'Libas' },
  { label: 'Libas', value: 'Libas' },
  { label: 'Libas', value: 'Libas' },
  { label: 'Libas', value: 'Libas' },
  { label: 'Libas', value: 'Libas' },
  { label: 'Libas', value: 'Libas' },
  { label: 'Libas', value: 'Libas' },
  { label: 'Libato', value: 'Libato' },
  { label: 'Libby', value: 'Libby' },
  { label: 'Libčice Nad Vltavou', value: 'Libčice Nad Vltavou' },
  { label: 'Libeň', value: 'Libeň' },
  { label: 'Liben Zone', value: 'Liben Zone' },
  { label: 'Libenge', value: 'Libenge' },
  { label: 'Liberal', value: 'Liberal' },
  { label: 'Liberato Salzano', value: 'Liberato Salzano' },
  { label: 'Libercourt', value: 'Libercourt' },
  { label: 'Liberdade', value: 'Liberdade' },
  { label: 'Liberec', value: 'Liberec' },
  { label: 'Liberi', value: 'Liberi' },
  { label: 'Liberia', value: 'Liberia' },
  { label: 'Liberta', value: 'Liberta' },
  { label: 'Libertad', value: 'Libertad' },
  { label: 'Libertad', value: 'Libertad' },
  { label: 'Libertad', value: 'Libertad' },
  { label: 'Libertad', value: 'Libertad' },
  { label: 'Libertad', value: 'Libertad' },
  { label: 'Libertad', value: 'Libertad' },
  { label: 'Libertad', value: 'Libertad' },
  { label: 'Libertad', value: 'Libertad' },
  { label: 'Libertad', value: 'Libertad' },
  { label: 'Libertad', value: 'Libertad' },
  { label: 'Libertad', value: 'Libertad' },
  { label: 'Libertad Álvaro Obregón', value: 'Libertad Álvaro Obregón' },
  { label: 'Libertad Melchor Ocampo', value: 'Libertad Melchor Ocampo' },
  { label: 'Libertad Ventanas', value: 'Libertad Ventanas' },
  { label: 'Libertador General San Martín', value: 'Libertador General San Martín' },
  { label: 'Libertów', value: 'Libertów' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty', value: 'Liberty' },
  { label: 'Liberty Center', value: 'Liberty Center' },
  { label: 'Liberty City', value: 'Liberty City' },
  { label: 'Liberty County', value: 'Liberty County' },
  { label: 'Liberty County', value: 'Liberty County' },
  { label: 'Liberty County', value: 'Liberty County' },
  { label: 'Liberty County', value: 'Liberty County' },
  { label: 'Liberty Grove', value: 'Liberty Grove' },
  { label: 'Liberty Hill', value: 'Liberty Hill' },
  { label: 'Liberty Lake', value: 'Liberty Lake' },
  { label: 'Libertyville', value: 'Libertyville' },
  { label: 'Liběšice', value: 'Liběšice' },
  { label: 'Líbeznice', value: 'Líbeznice' },
  { label: 'Libiąż', value: 'Libiąż' },
  { label: 'Libice Nad Cidlinou', value: 'Libice Nad Cidlinou' },
  { label: 'Libin', value: 'Libin' },
  { label: 'Libiš', value: 'Libiš' },
  { label: 'Libjo', value: 'Libjo' },
  { label: 'Libjo', value: 'Libjo' },
  { label: 'Libmanan', value: 'Libmanan' },
  { label: 'Libochovice', value: 'Libochovice' },
  { label: 'Libog', value: 'Libog' },
  { label: 'Libohovë', value: 'Libohovë' },
  { label: 'Libon', value: 'Libon' },
  { label: 'Libona', value: 'Libona' },
  { label: 'Libonik', value: 'Libonik' },
  { label: 'Liboran', value: 'Liboran' },
  { label: 'Liborina', value: 'Liborina' },
  { label: 'Liborio Negron Torres', value: 'Liborio Negron Torres' },
  { label: 'Liboro', value: 'Liboro' },
  { label: 'Libotin', value: 'Libotin' },
  { label: 'Libouchec', value: 'Libouchec' },
  { label: 'Libourne', value: 'Libourne' },
  { label: 'Libre Unión', value: 'Libre Unión' },
  { label: 'Libres', value: 'Libres' },
  { label: 'Libreville', value: 'Libreville' },
  { label: 'Librilla', value: 'Librilla' },
  { label: 'Librizzi', value: 'Librizzi' },
  { label: 'Libros', value: 'Libros' },
  { label: 'Libuganon', value: 'Libuganon' },
  { label: 'Libuganon', value: 'Libuganon' },
  { label: 'Libuganon', value: 'Libuganon' },
  { label: 'Libungan', value: 'Libungan' },
  { label: 'Libungan', value: 'Libungan' },
  { label: 'Libušín', value: 'Libušín' },
  { label: 'Licab', value: 'Licab' },
  { label: 'Licab', value: 'Licab' },
  { label: 'Licantén', value: 'Licantén' },
  { label: 'Licata', value: 'Licata' },
  { label: 'Licciana Nardi', value: 'Licciana Nardi' },
  { label: 'Lice', value: 'Lice' },
  { label: 'Licenciado Benito Juárez (Campo Gobierno)', value: 'Licenciado Benito Juárez (Campo Gobierno)' },
  { label: 'Licenciado Gustavo Díaz Ordaz', value: 'Licenciado Gustavo Díaz Ordaz' },
  { label: 'Licenza', value: 'Licenza' },
  { label: 'Liceras', value: 'Liceras' },
  { label: 'Licey Al Medio', value: 'Licey Al Medio' },
  { label: 'Lich', value: 'Lich' },
  { label: 'Licheń Stary', value: 'Licheń Stary' },
  { label: 'Licheng', value: 'Licheng' },
  { label: 'Licheng', value: 'Licheng' },
  { label: 'Lichfield', value: 'Lichfield' },
  { label: 'Lichinga', value: 'Lichinga' },
  { label: 'Lichk’', value: 'Lichk’' },
  { label: 'Lichnov', value: 'Lichnov' },
  { label: 'Lichnov (O. Nový Jičín)', value: 'Lichnov (O. Nový Jičín)' },
  { label: 'Lichte', value: 'Lichte' },
  { label: 'Lichtenau', value: 'Lichtenau' },
  { label: 'Lichtenau', value: 'Lichtenau' },
  { label: 'Lichtenau', value: 'Lichtenau' },
  { label: 'Lichtenberg', value: 'Lichtenberg' },
  { label: 'Lichtenberg', value: 'Lichtenberg' },
  { label: 'Lichtenberg', value: 'Lichtenberg' },
  { label: 'Lichtenberg', value: 'Lichtenberg' },
  { label: 'Lichtenburg', value: 'Lichtenburg' },
  { label: 'Lichtenegg', value: 'Lichtenegg' },
  { label: 'Lichtenegg', value: 'Lichtenegg' },
  { label: 'Lichtenfels', value: 'Lichtenfels' },
  { label: 'Lichtenrade', value: 'Lichtenrade' },
  { label: 'Lichtensteig', value: 'Lichtensteig' },
  { label: 'Lichtenstein', value: 'Lichtenstein' },
  { label: 'Lichtentanne', value: 'Lichtentanne' },
  { label: 'Lichtenvoorde', value: 'Lichtenvoorde' },
  { label: 'Lichtenwörth', value: 'Lichtenwörth' },
  { label: 'Lichterfelde', value: 'Lichterfelde' },
  { label: 'Lichtervelde', value: 'Lichtervelde' },
  { label: 'Lichuan', value: 'Lichuan' },
  { label: 'Liciada', value: 'Liciada' },
  { label: 'Licinella-Torre Di Paestum', value: 'Licinella-Torre Di Paestum' },
  { label: 'Licínio De Almeida', value: 'Licínio De Almeida' },
  { label: 'Lički Osik', value: 'Lički Osik' },
  { label: 'Licking', value: 'Licking' },
  { label: 'Licking County', value: 'Licking County' },
  { label: 'Licodia Eubea', value: 'Licodia Eubea' },
  { label: 'Licques', value: 'Licques' },
  { label: 'Licuan', value: 'Licuan' },
  { label: 'Licuan', value: 'Licuan' },
  { label: 'Licuan-Baay', value: 'Licuan-Baay' },
  { label: 'Licurici', value: 'Licurici' },
  { label: 'Licusati', value: 'Licusati' },
  { label: 'Lida', value: 'Lida' },
  { label: 'Lidcombe', value: 'Lidcombe' },
  { label: 'Lidečko', value: 'Lidečko' },
  { label: 'Lidianópolis', value: 'Lidianópolis' },
  { label: 'Lídice', value: 'Lídice' },
  { label: 'Lidingö', value: 'Lidingö' },
  { label: 'Lidköping', value: 'Lidköping' },
  { label: 'Lidlidda', value: 'Lidlidda' },
  { label: 'Lidlidda', value: 'Lidlidda' },
  { label: 'Lidlington', value: 'Lidlington' },
  { label: 'Lido', value: 'Lido' },
  { label: 'Lido', value: 'Lido' },
  { label: 'Lido Adriano', value: 'Lido Adriano' },
  { label: 'Lido Beach', value: 'Lido Beach' },
  { label: 'Lido Degli Estensi', value: 'Lido Degli Estensi' },
  { label: 'Lido Dei Pini', value: 'Lido Dei Pini' },
  { label: 'Lido Di Fermo', value: 'Lido Di Fermo' },
  { label: 'Lido Di Jesolo', value: 'Lido Di Jesolo' },
  { label: 'Lido Di Ostia', value: 'Lido Di Ostia' },
  { label: 'Lido Di Pomposa-Lido Degli Scacchi', value: 'Lido Di Pomposa-Lido Degli Scacchi' },
  { label: 'Lidón', value: 'Lidón' },
  { label: 'Lidoríki', value: 'Lidoríki' },
  { label: 'Lidski Rayon', value: 'Lidski Rayon' },
  { label: 'Lidung Jelo', value: 'Lidung Jelo' },
  { label: 'Lidzbark', value: 'Lidzbark' },
  { label: 'Lidzbark Warmiński', value: 'Lidzbark Warmiński' },
  { label: 'Liebefeld ', value: 'Liebefeld ' },
  { label: 'Liebenau', value: 'Liebenau' },
  { label: 'Liebenau', value: 'Liebenau' },
  { label: 'Liebenau', value: 'Liebenau' },
  { label: 'Liebenburg', value: 'Liebenburg' },
  { label: 'Liebenfels', value: 'Liebenfels' },
  { label: 'Liebenwalde', value: 'Liebenwalde' },
  { label: 'Lieberose', value: 'Lieberose' },
  { label: 'Liebling', value: 'Liebling' },
  { label: 'Lieboch', value: 'Lieboch' },
  { label: 'Liebstadt', value: 'Liebstadt' },
  { label: 'Liedekerke', value: 'Liedekerke' },
  { label: 'Liédena', value: 'Liédena' },
  { label: 'Liederbach', value: 'Liederbach' },
  { label: 'Liège', value: 'Liège' },
  { label: 'Lieģi', value: 'Lieģi' },
  { label: 'Lieksa', value: 'Lieksa' },
  { label: 'Lielahti', value: 'Lielahti' },
  { label: 'Lielvārde', value: 'Lielvārde' },
  { label: 'Liempde', value: 'Liempde' },
  { label: 'Lienchiang', value: 'Lienchiang' },
  { label: 'Lienden', value: 'Lienden' },
  { label: 'Liendo', value: 'Liendo' },
  { label: 'Lienen', value: 'Lienen' },
  { label: 'Lienz', value: 'Lienz' },
  { label: 'Liepāja', value: 'Liepāja' },
  { label: 'Lièpvre', value: 'Lièpvre' },
  { label: 'Lier', value: 'Lier' },
  { label: 'Lier', value: 'Lier' },
  { label: 'Liérganes', value: 'Liérganes' },
  { label: 'Liergues', value: 'Liergues' },
  { label: 'Lierna', value: 'Lierna' },
  { label: 'Lierne', value: 'Lierne' },
  { label: 'Lierneux', value: 'Lierneux' },
  { label: 'Lierop', value: 'Lierop' },
  { label: 'Liesberg', value: 'Liesberg' },
  { label: 'Lieser', value: 'Lieser' },
  { label: 'Lieshout', value: 'Lieshout' },
  { label: 'Liesingtal', value: 'Liesingtal' },
  { label: 'Lieskau', value: 'Lieskau' },
  { label: 'Liessel', value: 'Liessel' },
  { label: 'Liesse-Notre-Dame', value: 'Liesse-Notre-Dame' },
  { label: 'Liestal', value: 'Liestal' },
  { label: 'Lieşti', value: 'Lieşti' },
  { label: 'Liesveld', value: 'Liesveld' },
  { label: 'Lieto', value: 'Lieto' },
  { label: 'Liétor', value: 'Liétor' },
  { label: 'Lieuran-Lès-Béziers', value: 'Lieuran-Lès-Béziers' },
  { label: 'Lieurey', value: 'Lieurey' },
  { label: 'Lieusaint', value: 'Lieusaint' },
  { label: 'Lieu-Saint-Amand', value: 'Lieu-Saint-Amand' },
  { label: 'Lievendaal', value: 'Lievendaal' },
  { label: 'Liévin', value: 'Liévin' },
  { label: 'Liezen', value: 'Liezen' },
  { label: 'Liffol-Le-Grand', value: 'Liffol-Le-Grand' },
  { label: 'Liffré', value: 'Liffré' },
  { label: 'Lifou', value: 'Lifou' },
  { label: 'Lifton', value: 'Lifton' },
  { label: 'Ligao', value: 'Ligao' },
  { label: 'Līgatne', value: 'Līgatne' },
  { label: 'Ligaya', value: 'Ligaya' },
  { label: 'Ligaya', value: 'Ligaya' },
  { label: 'Ligaya', value: 'Ligaya' },
  { label: 'Ligayan', value: 'Ligayan' },
  { label: 'Ligayan', value: 'Ligayan' },
  { label: 'Light', value: 'Light' },
  { label: 'Light Street', value: 'Light Street' },
  { label: 'Lighthouse Point', value: 'Lighthouse Point' },
  { label: 'Lightning Ridge', value: 'Lightning Ridge' },
  { label: 'Lightwater', value: 'Lightwater' },
  { label: 'Ligist', value: 'Ligist' },
  { label: 'Lignana', value: 'Lignana' },
  { label: 'Lignano Sabbiadoro', value: 'Lignano Sabbiadoro' },
  { label: 'Lignan-Sur-Orb', value: 'Lignan-Sur-Orb' },
  { label: 'Ligné', value: 'Ligné' },
  { label: 'Lignières', value: 'Lignières' },
  { label: 'Ligny-En-Barrois', value: 'Ligny-En-Barrois' },
  { label: 'Ligny-En-Cambrésis', value: 'Ligny-En-Cambrésis' },
  { label: 'Ligny-Le-Châtel', value: 'Ligny-Le-Châtel' },
  { label: 'Ligny-Le-Ribault', value: 'Ligny-Le-Ribault' },
  { label: 'Ligonchio', value: 'Ligonchio' },
  { label: 'Ligonier', value: 'Ligonier' },
  { label: 'Ligonier', value: 'Ligonier' },
  { label: 'Ligornetto', value: 'Ligornetto' },
  { label: 'Ligosullo', value: 'Ligosullo' },
  { label: 'Ligota', value: 'Ligota' },
  { label: 'Ligourión', value: 'Ligourión' },
  { label: 'Ligré', value: 'Ligré' },
  { label: 'Liguanea', value: 'Liguanea' },
  { label: 'Ligueil', value: 'Ligueil' },
  { label: 'Ligugé', value: 'Ligugé' },
  { label: 'Lihue', value: 'Lihue' },
  { label: 'Lihula', value: 'Lihula' },
  { label: 'Liiva', value: 'Liiva' },
  { label: 'Líjar', value: 'Líjar' },
  { label: 'Liješnica', value: 'Liješnica' },
  { label: 'Lijiang', value: 'Lijiang' },
  { label: 'Lijnden', value: 'Lijnden' },
  { label: 'Likak', value: 'Likak' },
  { label: 'Likasi', value: 'Likasi' },
  { label: 'Likhobory', value: 'Likhobory' },
  { label: 'Likhoslavl’', value: 'Likhoslavl’' },
  { label: 'Likhoslavl’Skiy Rayon', value: 'Likhoslavl’Skiy Rayon' },
  { label: 'Likhovskoy', value: 'Likhovskoy' },
  { label: 'Likhoy', value: 'Likhoy' },
  { label: 'Likino-Dulevo', value: 'Likino-Dulevo' },
  { label: 'Likisá', value: 'Likisá' },
  { label: 'L-Iklin', value: 'L-Iklin' },
  { label: 'Liknes', value: 'Liknes' },
  { label: 'Likoma District', value: 'Likoma District' },
  { label: 'Lila', value: 'Lila' },
  { label: 'Lilbourn', value: 'Lilbourn' },
  { label: 'Lilburn', value: 'Lilburn' },
  { label: 'Lîle-Bouchard', value: 'Lîle-Bouchard' },
  { label: 'Lîle-Perrot', value: 'Lîle-Perrot' },
  { label: 'Lîle-Rousse', value: 'Lîle-Rousse' },
  { label: 'Liliani', value: 'Liliani' },
  { label: 'Lilieci', value: 'Lilieci' },
  { label: 'Lilienfeld', value: 'Lilienfeld' },
  { label: 'Lilienthal', value: 'Lilienthal' },
  { label: 'Liliești', value: 'Liliești' },
  { label: 'Lilio', value: 'Lilio' },
  { label: 'Liliongan', value: 'Liliongan' },
  { label: 'Liliongan', value: 'Liliongan' },
  { label: 'Liljendal', value: 'Liljendal' },
  { label: 'Lilla Edet', value: 'Lilla Edet' },
  { label: 'Lille', value: 'Lille' },
  { label: 'Lille', value: 'Lille' },
  { label: 'Lillebonne', value: 'Lillebonne' },
  { label: 'Lillehammer', value: 'Lillehammer' },
  { label: 'Lillerød', value: 'Lillerød' },
  { label: 'Lillers', value: 'Lillers' },
  { label: 'Lillestrøm', value: 'Lillestrøm' },
  { label: 'Lilli Pilli', value: 'Lilli Pilli' },
  { label: 'Lillianes', value: 'Lillianes' },
  { label: 'Lillington', value: 'Lillington' },
  { label: 'Lilliput', value: 'Lilliput' },
  { label: 'Lillo', value: 'Lillo' },
  { label: 'Lillooet', value: 'Lillooet' },
  { label: 'Liloan', value: 'Liloan' },
  { label: 'Liloan', value: 'Liloan' },
  { label: 'Liloan', value: 'Liloan' },
  { label: 'Lilongwe', value: 'Lilongwe' },
  { label: 'Lilongwe District', value: 'Lilongwe District' },
  { label: 'Liloy', value: 'Liloy' },
  { label: 'Lily Lake', value: 'Lily Lake' },
  { label: 'Lilydale', value: 'Lilydale' },
  { label: 'Lilyfield', value: 'Lilyfield' },
  { label: 'Lima', value: 'Lima' },
  { label: 'Lima', value: 'Lima' },
  { label: 'Lima', value: 'Lima' },
  { label: 'Lima', value: 'Lima' },
  { label: 'Lima', value: 'Lima' },
  { label: 'Lima Campos', value: 'Lima Campos' },
  { label: 'Lima Duarte', value: 'Lima Duarte' },
  { label: 'Lima Pampa', value: 'Lima Pampa' },
  { label: 'Limache', value: 'Limache' },
  { label: 'Liman', value: 'Liman' },
  { label: 'Limana', value: 'Limana' },
  { label: 'Limanancong', value: 'Limanancong' },
  { label: 'Limanowa', value: 'Limanowa' },
  { label: 'Limanskiy Rayon', value: 'Limanskiy Rayon' },
  { label: 'Limanu', value: 'Limanu' },
  { label: 'Limao', value: 'Limao' },
  { label: 'Limao', value: 'Limao' },
  { label: 'Limao', value: 'Limao' },
  { label: 'Limaong', value: 'Limaong' },
  { label: 'Limas', value: 'Limas' },
  { label: 'Limasawa', value: 'Limasawa' },
  { label: 'Limassol', value: 'Limassol' },
  { label: 'Limatola', value: 'Limatola' },
  { label: 'Limavady', value: 'Limavady' },
  { label: 'Limay', value: 'Limay' },
  { label: 'Limay', value: 'Limay' },
  { label: 'Limay', value: 'Limay' },
  { label: 'Limbaan', value: 'Limbaan' },
  { label: 'Limbach', value: 'Limbach' },
  { label: 'Limbach-Oberfrohna', value: 'Limbach-Oberfrohna' },
  { label: 'Limbadi-Caroni', value: 'Limbadi-Caroni' },
  { label: 'Limbalod', value: 'Limbalod' },
  { label: 'Limbalod', value: 'Limbalod' },
  { label: 'Limbang', value: 'Limbang' },
  { label: 'Limbaži', value: 'Limbaži' },
  { label: 'Limbdi', value: 'Limbdi' },
  { label: 'Limbe', value: 'Limbe' },
  { label: 'Limbi', value: 'Limbi' },
  { label: 'Limbiate', value: 'Limbiate' },
  { label: 'Limbo', value: 'Limbo' },
  { label: 'Limbo', value: 'Limbo' },
  { label: 'Limbourg', value: 'Limbourg' },
  { label: 'Limbricht', value: 'Limbricht' },
  { label: 'Limbuhan', value: 'Limbuhan' },
  { label: 'Limburg An Der Lahn', value: 'Limburg An Der Lahn' },
  { label: 'Limburgerhof', value: 'Limburgerhof' },
  { label: 'Limbuš', value: 'Limbuš' },
  { label: 'Lime Hall', value: 'Lime Hall' },
  { label: 'Lime Tree Gardens', value: 'Lime Tree Gardens' },
  { label: 'Limeil-Brévannes', value: 'Limeil-Brévannes' },
  { label: 'Limeira', value: 'Limeira' },
  { label: 'Limeira Do Oeste', value: 'Limeira Do Oeste' },
  { label: 'Limekilns', value: 'Limekilns' },
  { label: 'Limena', value: 'Limena' },
  { label: 'Limenária', value: 'Limenária' },
  { label: 'Limerick', value: 'Limerick' },
  { label: 'Limerick', value: 'Limerick' },
  { label: 'Limerzel', value: 'Limerzel' },
  { label: 'Limestone', value: 'Limestone' },
  { label: 'Limestone', value: 'Limestone' },
  { label: 'Limestone County', value: 'Limestone County' },
  { label: 'Limestone County', value: 'Limestone County' },
  { label: 'Limestone Creek', value: 'Limestone Creek' },
  { label: 'Limésy', value: 'Limésy' },
  { label: 'Limetz-Villez', value: 'Limetz-Villez' },
  { label: 'Limidi', value: 'Limidi' },
  { label: 'Limido Comasco', value: 'Limido Comasco' },
  { label: 'Limín Khersonísou', value: 'Limín Khersonísou' },
  { label: 'Limín Mesoyaías', value: 'Limín Mesoyaías' },
  { label: 'Limina', value: 'Limina' },
  { label: 'Limington', value: 'Limington' },
  { label: 'Liminka', value: 'Liminka' },
  { label: 'Limit', value: 'Limit' },
  { label: 'Limite', value: 'Limite' },
  { label: 'Limiti Di Greccio', value: 'Limiti Di Greccio' },
  { label: 'Limkheda', value: 'Limkheda' },
  { label: 'Limmared', value: 'Limmared' },
  { label: 'Limmel', value: 'Limmel' },
  { label: 'Limmen', value: 'Limmen' },
  { label: 'Límni', value: 'Límni' },
  { label: 'Limnokhórion', value: 'Limnokhórion' },
  { label: 'Limoeiro', value: 'Limoeiro' },
  { label: 'Limoeiro De Anadia', value: 'Limoeiro De Anadia' },
  { label: 'Limoeiro Do Ajuru', value: 'Limoeiro Do Ajuru' },
  { label: 'Limoeiro Do Norte', value: 'Limoeiro Do Norte' },
  { label: 'Limoges', value: 'Limoges' },
  { label: 'Limoges', value: 'Limoges' },
  { label: 'Limon', value: 'Limon' },
  { label: 'Limon', value: 'Limon' },
  { label: 'Limon', value: 'Limon' },
  { label: 'Limon', value: 'Limon' },
  { label: 'Limón', value: 'Limón' },
  { label: 'Limón', value: 'Limón' },
  { label: 'Limón De Tijeras', value: 'Limón De Tijeras' },
  { label: 'Limonade', value: 'Limonade' },
  { label: 'Limonar', value: 'Limonar' },
  { label: 'Limoncito', value: 'Limoncito' },
  { label: 'Limone Piemonte', value: 'Limone Piemonte' },
  { label: 'Limone Sul Garda', value: 'Limone Sul Garda' },
  { label: 'Limones', value: 'Limones' },
  { label: 'Limones', value: 'Limones' },
  { label: 'Limonest', value: 'Limonest' },
  { label: 'Lim-Oo', value: 'Lim-Oo' },
  { label: 'Lim-Oo', value: 'Lim-Oo' },
  { label: 'Limosano', value: 'Limosano' },
  { label: 'Limot', value: 'Limot' },
  { label: 'Limot', value: 'Limot' },
  { label: 'Limot', value: 'Limot' },
  { label: 'Limours', value: 'Limours' },
  { label: 'Limoux', value: 'Limoux' },
  { label: 'Limpapa', value: 'Limpapa' },
  { label: 'Limpeziș', value: 'Limpeziș' },
  { label: 'Limpias', value: 'Limpias' },
  { label: 'Limpio', value: 'Limpio' },
  { label: 'Limpley Stoke', value: 'Limpley Stoke' },
  { label: 'Limulan', value: 'Limulan' },
  { label: 'Limulan', value: 'Limulan' },
  { label: 'Limulunga', value: 'Limulunga' },
  { label: 'Limuru', value: 'Limuru' },
  { label: 'Linabo', value: 'Linabo' },
  { label: 'Linabuan', value: 'Linabuan' },
  { label: 'Linabuan Sur', value: 'Linabuan Sur' },
  { label: 'Linaküla', value: 'Linaküla' },
  { label: 'Linamon', value: 'Linamon' },
  { label: 'Linan', value: 'Linan' },
  { label: 'Linan (Town)', value: 'Linan (Town)' },
  { label: 'Linao', value: 'Linao' },
  { label: 'Linao', value: 'Linao' },
  { label: 'Linao', value: 'Linao' },
  { label: 'Linao', value: 'Linao' },
  { label: 'Linao', value: 'Linao' },
  { label: 'Linaon', value: 'Linaon' },
  { label: 'Linards', value: 'Linards' },
  { label: 'Linares', value: 'Linares' },
  { label: 'Linares', value: 'Linares' },
  { label: 'Linares', value: 'Linares' },
  { label: 'Linares', value: 'Linares' },
  { label: 'Linares De La Sierra', value: 'Linares De La Sierra' },
  { label: 'Linares De Mora', value: 'Linares De Mora' },
  { label: 'Linares De Riofrío', value: 'Linares De Riofrío' },
  { label: 'Linares Nuevo León', value: 'Linares Nuevo León' },
  { label: 'Linarolo', value: 'Linarolo' },
  { label: 'Linars', value: 'Linars' },
  { label: 'Linas', value: 'Linas' },
  { label: 'Linate', value: 'Linate' },
  { label: 'Linau', value: 'Linau' },
  { label: 'Linay', value: 'Linay' },
  { label: 'Lincang Shi', value: 'Lincang Shi' },
  { label: 'Lincent', value: 'Lincent' },
  { label: 'Lincheng', value: 'Lincheng' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln', value: 'Lincoln' },
  { label: 'Lincoln Beach', value: 'Lincoln Beach' },
  { label: 'Lincoln City', value: 'Lincoln City' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln County', value: 'Lincoln County' },
  { label: 'Lincoln Heights', value: 'Lincoln Heights' },
  { label: 'Lincoln Parish', value: 'Lincoln Parish' },
  { label: 'Lincoln Park', value: 'Lincoln Park' },
  { label: 'Lincoln Park', value: 'Lincoln Park' },
  { label: 'Lincoln Park', value: 'Lincoln Park' },
  { label: 'Lincoln Park', value: 'Lincoln Park' },
  { label: 'Lincoln Park', value: 'Lincoln Park' },
  { label: 'Lincoln Park', value: 'Lincoln Park' },
  { label: 'Lincoln Square', value: 'Lincoln Square' },
  { label: 'Lincoln Village', value: 'Lincoln Village' },
  { label: 'Lincoln Village', value: 'Lincoln Village' },
  { label: 'Lincolndale', value: 'Lincolndale' },
  { label: 'Lincolnia', value: 'Lincolnia' },
  { label: 'Lincolnshire', value: 'Lincolnshire' },
  { label: 'Lincolnshire', value: 'Lincolnshire' },
  { label: 'Lincolnton', value: 'Lincolnton' },
  { label: 'Lincolnton', value: 'Lincolnton' },
  { label: 'Lincolnville', value: 'Lincolnville' },
  { label: 'Lincolnville', value: 'Lincolnville' },
  { label: 'Lincolnwood', value: 'Lincolnwood' },
  { label: 'Lincroft', value: 'Lincroft' },
  { label: 'Lind', value: 'Lind' },
  { label: 'Lind', value: 'Lind' },
  { label: 'Linda', value: 'Linda' },
  { label: 'Linda', value: 'Linda' },
  { label: 'Linda-A-Velha', value: 'Linda-A-Velha' },
  { label: 'Linda-A-Velha', value: 'Linda-A-Velha' },
  { label: 'Lindale', value: 'Lindale' },
  { label: 'Lindale', value: 'Lindale' },
  { label: 'Lindås', value: 'Lindås' },
  { label: 'Lindau', value: 'Lindau' },
  { label: 'Lindau', value: 'Lindau' },
  { label: 'Lindau', value: 'Lindau' },
  { label: 'Lindavista', value: 'Lindavista' },
  { label: 'Lindavista', value: 'Lindavista' },
  { label: 'Lindberg', value: 'Lindberg' },
  { label: 'Linden', value: 'Linden' },
  { label: 'Linden', value: 'Linden' },
  { label: 'Linden', value: 'Linden' },
  { label: 'Linden', value: 'Linden' },
  { label: 'Linden', value: 'Linden' },
  { label: 'Linden', value: 'Linden' },
  { label: 'Linden', value: 'Linden' },
  { label: 'Linden', value: 'Linden' },
  { label: 'Linden', value: 'Linden' },
  { label: 'Linden', value: 'Linden' },
  { label: 'Linden Park', value: 'Linden Park' },
  { label: 'Lindenberg', value: 'Lindenberg' },
  { label: 'Lindenfels', value: 'Lindenfels' },
  { label: 'Lindenheuvel', value: 'Lindenheuvel' },
  { label: 'Lindenholt', value: 'Lindenholt' },
  { label: 'Lindenhurst', value: 'Lindenhurst' },
  { label: 'Lindenhurst', value: 'Lindenhurst' },
  { label: 'Lindenwold', value: 'Lindenwold' },
  { label: 'Lindesberg', value: 'Lindesberg' },
  { label: 'Lindesnes', value: 'Lindesnes' },
  { label: 'Lindewitt', value: 'Lindewitt' },
  { label: 'Lindfield', value: 'Lindfield' },
  { label: 'Lindholmen', value: 'Lindholmen' },
  { label: 'Lindhorst', value: 'Lindhorst' },
  { label: 'Lindi', value: 'Lindi' },
  { label: 'Lindisfarne', value: 'Lindisfarne' },
  { label: 'Lindlar', value: 'Lindlar' },
  { label: 'Lindley', value: 'Lindley' },
  { label: 'Lindley', value: 'Lindley' },
  { label: 'Lindö', value: 'Lindö' },
  { label: 'Lindoeste', value: 'Lindoeste' },
  { label: 'Lindóia', value: 'Lindóia' },
  { label: 'Lindóia Do Sul', value: 'Lindóia Do Sul' },
  { label: 'Lindolfo Collor', value: 'Lindolfo Collor' },
  { label: 'Lindome', value: 'Lindome' },
  { label: 'Lindon', value: 'Lindon' },
  { label: 'Lindow', value: 'Lindow' },
  { label: 'Lindsay', value: 'Lindsay' },
  { label: 'Lindsay', value: 'Lindsay' },
  { label: 'Lindsay', value: 'Lindsay' },
  { label: 'Lindsay', value: 'Lindsay' },
  { label: 'Lindsborg', value: 'Lindsborg' },
  { label: 'Lindsdal', value: 'Lindsdal' },
  { label: 'Lindstrom', value: 'Lindstrom' },
  { label: 'Lindwedel', value: 'Lindwedel' },
  { label: 'Líně', value: 'Líně' },
  { label: 'Línea De La Concepción La', value: 'Línea De La Concepción La' },
  { label: 'Linera', value: 'Linera' },
  { label: 'Lineville', value: 'Lineville' },
  { label: 'Linëvo', value: 'Linëvo' },
  { label: 'Linfen', value: 'Linfen' },
  { label: 'Linganore', value: 'Linganore' },
  { label: 'Lingasan', value: 'Lingasan' },
  { label: 'Lingating', value: 'Lingating' },
  { label: 'Lingayen', value: 'Lingayen' },
  { label: 'Lingayen', value: 'Lingayen' },
  { label: 'Lingbao Chengguanzhen', value: 'Lingbao Chengguanzhen' },
  { label: 'Lingcheng', value: 'Lingcheng' },
  { label: 'Lingdale', value: 'Lingdale' },
  { label: 'Lingdong', value: 'Lingdong' },
  { label: 'Lingen', value: 'Lingen' },
  { label: 'Lingenau', value: 'Lingenau' },
  { label: 'Lingenfeld', value: 'Lingenfeld' },
  { label: 'Lingfield', value: 'Lingfield' },
  { label: 'Linghai', value: 'Linghai' },
  { label: 'Linghem', value: 'Linghem' },
  { label: 'Lingig', value: 'Lingig' },
  { label: 'Lingig', value: 'Lingig' },
  { label: 'Lingion', value: 'Lingion' },
  { label: 'Linglestown', value: 'Linglestown' },
  { label: 'Lingolsheim', value: 'Lingolsheim' },
  { label: 'Lingoni', value: 'Lingoni' },
  { label: 'Lingsugur', value: 'Lingsugur' },
  { label: 'Linguaglossa', value: 'Linguaglossa' },
  { label: 'Linguere Department', value: 'Linguere Department' },
  { label: 'Linguizzetta', value: 'Linguizzetta' },
  { label: 'Lingwood', value: 'Lingwood' },
  { label: 'Lingyuan', value: 'Lingyuan' },
  { label: 'Linha Nova', value: 'Linha Nova' },
  { label: 'Linhai', value: 'Linhai' },
  { label: 'Linhares', value: 'Linhares' },
  { label: 'Linhares (Celorico Da Beira)', value: 'Linhares (Celorico Da Beira)' },
  { label: 'Linia', value: 'Linia' },
  { label: 'Linia', value: 'Linia' },
  { label: 'Linière', value: 'Linière' },
  { label: 'Liniewo', value: 'Liniewo' },
  { label: 'Linjiang', value: 'Linjiang' },
  { label: 'Linkebeek', value: 'Linkebeek' },
  { label: 'Linkenheim-Hochstetten', value: 'Linkenheim-Hochstetten' },
  { label: 'Linköping', value: 'Linköping' },
  { label: 'Linkou', value: 'Linkou' },
  { label: 'Linkou', value: 'Linkou' },
  { label: 'Linkuva', value: 'Linkuva' },
  { label: 'Linlithgow', value: 'Linlithgow' },
  { label: 'Linmansangan', value: 'Linmansangan' },
  { label: 'Linmansangan', value: 'Linmansangan' },
  { label: 'Linn', value: 'Linn' },
  { label: 'Linn County', value: 'Linn County' },
  { label: 'Linn County', value: 'Linn County' },
  { label: 'Linn County', value: 'Linn County' },
  { label: 'Linn County', value: 'Linn County' },
  { label: 'Linne', value: 'Linne' },
  { label: 'Linneus', value: 'Linneus' },
  { label: 'Linnich', value: 'Linnich' },
  { label: 'Linntown', value: 'Linntown' },
  { label: 'Lino Lakes', value: 'Lino Lakes' },
  { label: 'Linoan', value: 'Linoan' },
  { label: 'Linoan', value: 'Linoan' },
  { label: 'Linoan', value: 'Linoan' },
  { label: 'Linping', value: 'Linping' },
  { label: 'Linqiong', value: 'Linqiong' },
  { label: 'Linqu', value: 'Linqu' },
  { label: 'Lins', value: 'Lins' },
  { label: 'Linschoten', value: 'Linschoten' },
  { label: 'Linselles', value: 'Linselles' },
  { label: 'Linshui', value: 'Linshui' },
  { label: 'Linstead', value: 'Linstead' },
  { label: 'Lint', value: 'Lint' },
  { label: 'Linț', value: 'Linț' },
  { label: 'Lintangan', value: 'Lintangan' },
  { label: 'Lintgen', value: 'Lintgen' },
  { label: 'Linthal', value: 'Linthal' },
  { label: 'Linthicum', value: 'Linthicum' },
  { label: 'Linthwaite', value: 'Linthwaite' },
  { label: 'Lintig', value: 'Lintig' },
  { label: 'Lintingkou', value: 'Lintingkou' },
  { label: 'Linton', value: 'Linton' },
  { label: 'Linton', value: 'Linton' },
  { label: 'Linton', value: 'Linton' },
  { label: 'Linton Hall', value: 'Linton Hall' },
  { label: 'Linton Park', value: 'Linton Park' },
  { label: 'Linton Upon Ouse', value: 'Linton Upon Ouse' },
  { label: 'Lintong', value: 'Lintong' },
  { label: 'Linwood', value: 'Linwood' },
  { label: 'Linwood', value: 'Linwood' },
  { label: 'Linwood', value: 'Linwood' },
  { label: 'Linxe', value: 'Linxe' },
  { label: 'Linxi', value: 'Linxi' },
  { label: 'Linxia Chengguanzhen', value: 'Linxia Chengguanzhen' },
  { label: 'Linxia Huizu Zizhizhou', value: 'Linxia Huizu Zizhizhou' },
  { label: 'Linyi', value: 'Linyi' },
  { label: 'Linyola', value: 'Linyola' },
  { label: 'Linz', value: 'Linz' },
  { label: 'Linz Am Rhein', value: 'Linz Am Rhein' },
  { label: 'Linz Stadt', value: 'Linz Stadt' },
  { label: 'Lionel Town', value: 'Lionel Town' },
  { label: 'Lioni', value: 'Lioni' },
  { label: 'Lions Bay', value: 'Lions Bay' },
  { label: 'Lion-Sur-Mer', value: 'Lion-Sur-Mer' },
  { label: 'Lionville', value: 'Lionville' },
  { label: 'Liopétri', value: 'Liopétri' },
  { label: 'Liozon', value: 'Liozon' },
  { label: 'Liozon', value: 'Liozon' },
  { label: 'Lípa', value: 'Lípa' },
  { label: 'Lipa City', value: 'Lipa City' },
  { label: 'Lipahan', value: 'Lipahan' },
  { label: 'Lipăneşti', value: 'Lipăneşti' },
  { label: 'Lipany', value: 'Lipany' },
  { label: 'Lipari', value: 'Lipari' },
  { label: 'Lipay', value: 'Lipay' },
  { label: 'Lipay', value: 'Lipay' },
  { label: 'Lipayran', value: 'Lipayran' },
  { label: 'Lipce Reymontowskie', value: 'Lipce Reymontowskie' },
  { label: 'Liperi', value: 'Liperi' },
  { label: 'Lipetsk', value: 'Lipetsk' },
  { label: 'Lipetskiy Rayon', value: 'Lipetskiy Rayon' },
  { label: 'Liphook', value: 'Liphook' },
  { label: 'Lipia', value: 'Lipia' },
  { label: 'Lipia', value: 'Lipia' },
  { label: 'Lipiany', value: 'Lipiany' },
  { label: 'Lipie', value: 'Lipie' },
  { label: 'Lipik', value: 'Lipik' },
  { label: 'Lipin Bor', value: 'Lipin Bor' },
  { label: 'Lipinki', value: 'Lipinki' },
  { label: 'Lipinki Łużyckie', value: 'Lipinki Łużyckie' },
  { label: 'Lipka', value: 'Lipka' },
  { label: 'Lipkovo', value: 'Lipkovo' },
  { label: 'Lipnica', value: 'Lipnica' },
  { label: 'Lipnica', value: 'Lipnica' },
  { label: 'Lipnica Mała', value: 'Lipnica Mała' },
  { label: 'Lipnica Wielka', value: 'Lipnica Wielka' },
  { label: 'Lipnički Šor', value: 'Lipnički Šor' },
  { label: 'Lipnik', value: 'Lipnik' },
  { label: 'Lipnik', value: 'Lipnik' },
  { label: 'Lipník Nad Bečvou', value: 'Lipník Nad Bečvou' },
  { label: 'Lipniţa', value: 'Lipniţa' },
  { label: 'Lipno', value: 'Lipno' },
  { label: 'Lipno', value: 'Lipno' },
  { label: 'Lipolist', value: 'Lipolist' },
  { label: 'Lipomo', value: 'Lipomo' },
  { label: 'Lipov', value: 'Lipov' },
  { label: 'Lipova', value: 'Lipova' },
  { label: 'Lipova', value: 'Lipova' },
  { label: 'Lipovăţ', value: 'Lipovăţ' },
  { label: 'Lipovci', value: 'Lipovci' },
  { label: 'Lipovec', value: 'Lipovec' },
  { label: 'Lipovljani', value: 'Lipovljani' },
  { label: 'Lipovtsy', value: 'Lipovtsy' },
  { label: 'Lipovu', value: 'Lipovu' },
  { label: 'Lipowa', value: 'Lipowa' },
  { label: 'Lipowiec Kościelny', value: 'Lipowiec Kościelny' },
  { label: 'Lippstadt', value: 'Lippstadt' },
  { label: 'Lipscomb', value: 'Lipscomb' },
  { label: 'Lipscomb County', value: 'Lipscomb County' },
  { label: 'Lipsheim', value: 'Lipsheim' },
  { label: 'Lipsk', value: 'Lipsk' },
  { label: 'Lipsko', value: 'Lipsko' },
  { label: 'Liptál', value: 'Liptál' },
  { label: 'Liptovský Hrádok', value: 'Liptovský Hrádok' },
  { label: 'Liptovský Mikuláš', value: 'Liptovský Mikuláš' },
  { label: 'Lipuntahuaca', value: 'Lipuntahuaca' },
  { label: 'Lipusz', value: 'Lipusz' },
  { label: 'Lipůvka', value: 'Lipůvka' },
  { label: 'Lira', value: 'Lira' },
  { label: 'Liré', value: 'Liré' },
  { label: 'Lirio', value: 'Lirio' },
  { label: 'Lisa', value: 'Lisa' },
  { label: 'Lisa', value: 'Lisa' },
  { label: 'Lisakovsk', value: 'Lisakovsk' },
  { label: 'Lisala', value: 'Lisala' },
  { label: 'Lisanza', value: 'Lisanza' },
  { label: 'Lisar', value: 'Lisar' },
  { label: 'Lisarow', value: 'Lisarow' },
  { label: 'Lisbon', value: 'Lisbon' },
  { label: 'Lisbon', value: 'Lisbon' },
  { label: 'Lisbon', value: 'Lisbon' },
  { label: 'Lisbon', value: 'Lisbon' },
  { label: 'Lisbon', value: 'Lisbon' },
  { label: 'Lisbon', value: 'Lisbon' },
  { label: 'Lisbon', value: 'Lisbon' },
  { label: 'Lisbon Falls', value: 'Lisbon Falls' },
  { label: 'Lisburn', value: 'Lisburn' },
  { label: 'Lisburn And Castlereagh', value: 'Lisburn And Castlereagh' },
  { label: 'Liscate', value: 'Liscate' },
  { label: 'Liscia', value: 'Liscia' },
  { label: 'Lisciano Niccone', value: 'Lisciano Niccone' },
  { label: 'Liseleje', value: 'Liseleje' },
  { label: 'Lisewo', value: 'Lisewo' },
  { label: 'Lisewo Malborskie', value: 'Lisewo Malborskie' },
  { label: 'Lishu', value: 'Lishu' },
  { label: 'Lishui', value: 'Lishui' },
  { label: 'Lisia Góra', value: 'Lisia Góra' },
  { label: 'Lisičani', value: 'Lisičani' },
  { label: 'Lisiera', value: 'Lisiera' },
  { label: 'Lisieux', value: 'Lisieux' },
  { label: 'Lisignago', value: 'Lisignago' },
  { label: 'Lisio', value: 'Lisio' },
  { label: 'Lisiy Nos', value: 'Lisiy Nos' },
  { label: 'Liskeard', value: 'Liskeard' },
  { label: 'Liski', value: 'Liski' },
  { label: 'Liskinskiy Rayon', value: 'Liskinskiy Rayon' },
  { label: 'Lisków', value: 'Lisków' },
  { label: 'Lisle', value: 'Lisle' },
  { label: 'Lisle-Sur-Tarn', value: 'Lisle-Sur-Tarn' },
  { label: 'Lismore', value: 'Lismore' },
  { label: 'Lismore Heights', value: 'Lismore Heights' },
  { label: 'Lismore Municipality', value: 'Lismore Municipality' },
  { label: 'Lisnaskea', value: 'Lisnaskea' },
  { label: 'Lisogirka', value: 'Lisogirka' },
  { label: 'Lišov', value: 'Lišov' },
  { label: 'Lisovi Sorochyntsi', value: 'Lisovi Sorochyntsi' },
  { label: 'Liss', value: 'Liss' },
  { label: 'Lisse', value: 'Lisse' },
  { label: 'Lissendorf', value: 'Lissendorf' },
  { label: 'Lisserbroek', value: 'Lisserbroek' },
  { label: 'Lisses', value: 'Lisses' },
  { label: 'Lissieu', value: 'Lissieu' },
  { label: 'Lissone', value: 'Lissone' },
  { label: 'List', value: 'List' },
  { label: 'Lișteava', value: 'Lișteava' },
  { label: 'Listowel', value: 'Listowel' },
  { label: 'Listowel', value: 'Listowel' },
  { label: 'Listrac-Médoc', value: 'Listrac-Médoc' },
  { label: 'Listvennoye', value: 'Listvennoye' },
  { label: 'Listvyagi', value: 'Listvyagi' },
  { label: 'Listvyanka', value: 'Listvyanka' },
  { label: 'Listvyanskiy', value: 'Listvyanskiy' },
  { label: 'Liszki', value: 'Liszki' },
  { label: 'Liţa', value: 'Liţa' },
  { label: 'Litago', value: 'Litago' },
  { label: 'Litayan', value: 'Litayan' },
  { label: 'Litayan', value: 'Litayan' },
  { label: 'Litchfield', value: 'Litchfield' },
  { label: 'Litchfield', value: 'Litchfield' },
  { label: 'Litchfield', value: 'Litchfield' },
  { label: 'Litchfield', value: 'Litchfield' },
  { label: 'Litchfield', value: 'Litchfield' },
  { label: 'Litchfield', value: 'Litchfield' },
  { label: 'Litchfield', value: 'Litchfield' },
  { label: 'Litchfield County', value: 'Litchfield County' },
  { label: 'Litchfield Park', value: 'Litchfield Park' },
  { label: 'Litein', value: 'Litein' },
  { label: 'Liteni', value: 'Liteni' },
  { label: 'Liteni', value: 'Liteni' },
  { label: 'Litér', value: 'Litér' },
  { label: 'Lit-Et-Mixe', value: 'Lit-Et-Mixe' },
  { label: 'Lith', value: 'Lith' },
  { label: 'Lithakiá', value: 'Lithakiá' },
  { label: 'Litherland', value: 'Litherland' },
  { label: 'Lithgow', value: 'Lithgow' },
  { label: 'Lithia Springs', value: 'Lithia Springs' },
  { label: 'Lithonia', value: 'Lithonia' },
  { label: 'Lithopolis', value: 'Lithopolis' },
  { label: 'Lití', value: 'Lití' },
  { label: 'Litija', value: 'Litija' },
  { label: 'Lititz', value: 'Lititz' },
  { label: 'Lititz', value: 'Lititz' },
  { label: 'Litóchoro', value: 'Litóchoro' },
  { label: 'Litoměřice', value: 'Litoměřice' },
  { label: 'Litomyšl', value: 'Litomyšl' },
  { label: 'Litoral De Atacama', value: 'Litoral De Atacama' },
  { label: 'Litoral Del San Juan', value: 'Litoral Del San Juan' },
  { label: 'Litovel', value: 'Litovel' },
  { label: 'Litovko', value: 'Litovko' },
  { label: 'Litschau', value: 'Litschau' },
  { label: 'Litta Parodi-Cascinagrossa', value: 'Litta Parodi-Cascinagrossa' },
  { label: 'Littau', value: 'Littau' },
  { label: 'Little Amwell', value: 'Little Amwell' },
  { label: 'Little Baguio', value: 'Little Baguio' },
  { label: 'Little Bay', value: 'Little Bay' },
  { label: 'Little Bray', value: 'Little Bray' },
  { label: 'Little Canada', value: 'Little Canada' },
  { label: 'Little Chalfont', value: 'Little Chalfont' },
  { label: 'Little Chute', value: 'Little Chute' },
  { label: 'Little Clacton', value: 'Little Clacton' },
  { label: 'Little Cottonwood Creek Valley', value: 'Little Cottonwood Creek Valley' },
  { label: 'Little Current', value: 'Little Current' },
  { label: 'Little Dunmow', value: 'Little Dunmow' },
  { label: 'Little Eaton', value: 'Little Eaton' },
  { label: 'Little Elm', value: 'Little Elm' },
  { label: 'Little Falls', value: 'Little Falls' },
  { label: 'Little Falls', value: 'Little Falls' },
  { label: 'Little Falls', value: 'Little Falls' },
  { label: 'Little Ferry', value: 'Little Ferry' },
  { label: 'Little Flock', value: 'Little Flock' },
  { label: 'Little Grove', value: 'Little Grove' },
  { label: 'Little Hallingbury', value: 'Little Hallingbury' },
  { label: 'Little Hampton', value: 'Little Hampton' },
  { label: 'Little Hulton', value: 'Little Hulton' },
  { label: 'Little Lever', value: 'Little Lever' },
  { label: 'Little London', value: 'Little London' },
  { label: 'Little Mountain', value: 'Little Mountain' },
  { label: 'Little Neck', value: 'Little Neck' },
  { label: 'Little Paxton', value: 'Little Paxton' },
  { label: 'Little River', value: 'Little River' },
  { label: 'Little River', value: 'Little River' },
  { label: 'Little River County', value: 'Little River County' },
  { label: 'Little River-Academy', value: 'Little River-Academy' },
  { label: 'Little Rock', value: 'Little Rock' },
  { label: 'Little Rock', value: 'Little Rock' },
  { label: 'Little Rock Air Force Base', value: 'Little Rock Air Force Base' },
  { label: 'Little Round Lake', value: 'Little Round Lake' },
  { label: 'Little Silver', value: 'Little Silver' },
  { label: 'Little Valley', value: 'Little Valley' },
  { label: 'Little Weighton', value: 'Little Weighton' },
  { label: 'Littleborough', value: 'Littleborough' },
  { label: 'Littlebourne', value: 'Littlebourne' },
  { label: 'Littlefield', value: 'Littlefield' },
  { label: 'Littlehampton', value: 'Littlehampton' },
  { label: 'Littleport', value: 'Littleport' },
  { label: 'Littlerock', value: 'Littlerock' },
  { label: 'Littlestown', value: 'Littlestown' },
  { label: 'Littleton', value: 'Littleton' },
  { label: 'Littleton', value: 'Littleton' },
  { label: 'Littleton Common', value: 'Littleton Common' },
  { label: 'Litueche', value: 'Litueche' },
  { label: 'Lituénigo', value: 'Lituénigo' },
  { label: 'Litvinenkovo', value: 'Litvinenkovo' },
  { label: 'Litvínov', value: 'Litvínov' },
  { label: 'Litvínovice', value: 'Litvínovice' },
  { label: 'Lityn', value: 'Lityn' },
  { label: 'Lityns’Kyy Rayon', value: 'Lityns’Kyy Rayon' },
  { label: 'Litzelsdorf', value: 'Litzelsdorf' },
  { label: 'Litzendorf', value: 'Litzendorf' },
  { label: 'Liubar', value: 'Liubar' },
  { label: 'Liubar', value: 'Liubar' },
  { label: 'Liubcova', value: 'Liubcova' },
  { label: 'Liuboml', value: 'Liuboml' },
  { label: 'Liuduzhai', value: 'Liuduzhai' },
  { label: 'Liuhe', value: 'Liuhe' },
  { label: 'Liujiading', value: 'Liujiading' },
  { label: 'Liukuaizhuang', value: 'Liukuaizhuang' },
  { label: 'Liuli', value: 'Liuli' },
  { label: 'Liupanshui', value: 'Liupanshui' },
  { label: 'Liure', value: 'Liure' },
  { label: 'Liutizh', value: 'Liutizh' },
  { label: 'Liuwudiancun', value: 'Liuwudiancun' },
  { label: 'Liuzhou Shi', value: 'Liuzhou Shi' },
  { label: 'Liuzikou', value: 'Liuzikou' },
  { label: 'Livada', value: 'Livada' },
  { label: 'Livada', value: 'Livada' },
  { label: 'Livada De Bihor', value: 'Livada De Bihor' },
  { label: 'Livadea', value: 'Livadea' },
  { label: 'Livadeiá', value: 'Livadeiá' },
  { label: 'Livaderó', value: 'Livaderó' },
  { label: 'Livadia', value: 'Livadia' },
  { label: 'Livadia', value: 'Livadia' },
  { label: 'Livádia', value: 'Livádia' },
  { label: 'Livadiya', value: 'Livadiya' },
  { label: 'Livanátes', value: 'Livanátes' },
  { label: 'Līvāni', value: 'Līvāni' },
  { label: 'Livarot', value: 'Livarot' },
  { label: 'Live Oak', value: 'Live Oak' },
  { label: 'Live Oak', value: 'Live Oak' },
  { label: 'Live Oak', value: 'Live Oak' },
  { label: 'Live Oak County', value: 'Live Oak County' },
  { label: 'Lively', value: 'Lively' },
  { label: 'Livenka', value: 'Livenka' },
  { label: 'Livera', value: 'Livera' },
  { label: 'Liverdun', value: 'Liverdun' },
  { label: 'Liverdy-En-Brie', value: 'Liverdy-En-Brie' },
  { label: 'Liveri', value: 'Liveri' },
  { label: 'Livermore', value: 'Livermore' },
  { label: 'Livermore', value: 'Livermore' },
  { label: 'Livermore', value: 'Livermore' },
  { label: 'Livermore Falls', value: 'Livermore Falls' },
  { label: 'Liverpool', value: 'Liverpool' },
  { label: 'Liverpool', value: 'Liverpool' },
  { label: 'Liverpool', value: 'Liverpool' },
  { label: 'Liverpool Plains', value: 'Liverpool Plains' },
  { label: 'Liversedge', value: 'Liversedge' },
  { label: 'Livet-Et-Gavet', value: 'Livet-Et-Gavet' },
  { label: 'Livezeni', value: 'Livezeni' },
  { label: 'Livezeni', value: 'Livezeni' },
  { label: 'Livezi', value: 'Livezi' },
  { label: 'Livezi', value: 'Livezi' },
  { label: 'Livezile', value: 'Livezile' },
  { label: 'Livezile', value: 'Livezile' },
  { label: 'Livezile', value: 'Livezile' },
  { label: 'Livezile', value: 'Livezile' },
  { label: 'Livezi-Vale', value: 'Livezi-Vale' },
  { label: 'Livigno', value: 'Livigno' },
  { label: 'Livinallongo Del Col Di Lana', value: 'Livinallongo Del Col Di Lana' },
  { label: 'Livingston', value: 'Livingston' },
  { label: 'Livingston', value: 'Livingston' },
  { label: 'Livingston', value: 'Livingston' },
  { label: 'Livingston', value: 'Livingston' },
  { label: 'Livingston', value: 'Livingston' },
  { label: 'Livingston', value: 'Livingston' },
  { label: 'Livingston', value: 'Livingston' },
  { label: 'Livingston', value: 'Livingston' },
  { label: 'Lívingston', value: 'Lívingston' },
  { label: 'Livingston County', value: 'Livingston County' },
  { label: 'Livingston County', value: 'Livingston County' },
  { label: 'Livingston County', value: 'Livingston County' },
  { label: 'Livingston County', value: 'Livingston County' },
  { label: 'Livingston County', value: 'Livingston County' },
  { label: 'Livingston Manor', value: 'Livingston Manor' },
  { label: 'Livingston Parish', value: 'Livingston Parish' },
  { label: 'Livingstone', value: 'Livingstone' },
  { label: 'Livingstonia', value: 'Livingstonia' },
  { label: 'Livinhac-Le-Haut', value: 'Livinhac-Le-Haut' },
  { label: 'Livno', value: 'Livno' },
  { label: 'Livny', value: 'Livny' },
  { label: 'Livo', value: 'Livo' },
  { label: 'Livo', value: 'Livo' },
  { label: 'Livonia', value: 'Livonia' },
  { label: 'Livonia', value: 'Livonia' },
  { label: 'Livonia', value: 'Livonia' },
  { label: 'Livorno', value: 'Livorno' },
  { label: 'Livorno Ferraris', value: 'Livorno Ferraris' },
  { label: 'Livraga', value: 'Livraga' },
  { label: 'Livramento', value: 'Livramento' },
  { label: 'Livramento De Nossa Senhora', value: 'Livramento De Nossa Senhora' },
  { label: 'Livramento Do Brumado', value: 'Livramento Do Brumado' },
  { label: 'Livré-Sur-Changeon', value: 'Livré-Sur-Changeon' },
  { label: 'Livron-Sur-Drôme', value: 'Livron-Sur-Drôme' },
  { label: 'Livry-Gargan', value: 'Livry-Gargan' },
  { label: 'Livry-Sur-Seine', value: 'Livry-Sur-Seine' },
  { label: 'Liwá', value: 'Liwá' },
  { label: 'Liwan', value: 'Liwan' },
  { label: 'Liwan', value: 'Liwan' },
  { label: 'Liwonde', value: 'Liwonde' },
  { label: 'Lixiqiao', value: 'Lixiqiao' },
  { label: 'Lixoúri', value: 'Lixoúri' },
  { label: 'Lizarda', value: 'Lizarda' },
  { label: 'Lizartza', value: 'Lizartza' },
  { label: 'Ližnjan', value: 'Ližnjan' },
  { label: 'Ližnjan-Lisignano', value: 'Ližnjan-Lisignano' },
  { label: 'Lizoáin', value: 'Lizoáin' },
  { label: 'Lizoáin-Arriasgoiti', value: 'Lizoáin-Arriasgoiti' },
  { label: 'Lizy-Sur-Ourcq', value: 'Lizy-Sur-Ourcq' },
  { label: 'Lizzanello', value: 'Lizzanello' },
  { label: 'Lizzano', value: 'Lizzano' },
  { label: 'Lizzano In Belvedere', value: 'Lizzano In Belvedere' },
  { label: 'Ljig', value: 'Ljig' },
  { label: 'Ljubečna', value: 'Ljubečna' },
  { label: 'Ljubešćica', value: 'Ljubešćica' },
  { label: 'Ljubin', value: 'Ljubin' },
  { label: 'Ljubinje', value: 'Ljubinje' },
  { label: 'Ljubljana', value: 'Ljubljana' },
  { label: 'Ljubno Ob Savinji', value: 'Ljubno Ob Savinji' },
  { label: 'Ljubovija', value: 'Ljubovija' },
  { label: 'Ljubuški', value: 'Ljubuški' },
  { label: 'Ljukovo', value: 'Ljukovo' },
  { label: 'Ljungby', value: 'Ljungby' },
  { label: 'Ljungbyhed', value: 'Ljungbyhed' },
  { label: 'Ljungbyholm', value: 'Ljungbyholm' },
  { label: 'Ljunghusen', value: 'Ljunghusen' },
  { label: 'Ljungsbro', value: 'Ljungsbro' },
  { label: 'Ljungskile', value: 'Ljungskile' },
  { label: 'Ljusdal', value: 'Ljusdal' },
  { label: 'Ljusne', value: 'Ljusne' },
  { label: 'Ljutomer', value: 'Ljutomer' },
  { label: 'Llacao', value: 'Llacao' },
  { label: 'Llacuna La', value: 'Llacuna La' },
  { label: 'Lladó', value: 'Lladó' },
  { label: 'Lladorre', value: 'Lladorre' },
  { label: 'Lladurs', value: 'Lladurs' },
  { label: 'Llagosta La', value: 'Llagosta La' },
  { label: 'Llagostera', value: 'Llagostera' },
  { label: 'Llaillay', value: 'Llaillay' },
  { label: 'Llallagua', value: 'Llallagua' },
  { label: 'Llamas De La Ribera', value: 'Llamas De La Ribera' },
  { label: 'Llambilles', value: 'Llambilles' },
  { label: 'Llanars', value: 'Llanars' },
  { label: 'Llanarth', value: 'Llanarth' },
  { label: 'Llanarth', value: 'Llanarth' },
  { label: 'Llanbedr', value: 'Llanbedr' },
  { label: 'Llanberis', value: 'Llanberis' },
  { label: 'Llanbradach', value: 'Llanbradach' },
  { label: 'Llançà', value: 'Llançà' },
  { label: 'Llandeilo', value: 'Llandeilo' },
  { label: 'Llandewey', value: 'Llandewey' },
  { label: 'Llandilo', value: 'Llandilo' },
  { label: 'Llandilo', value: 'Llandilo' },
  { label: 'Llandovery', value: 'Llandovery' },
  { label: 'Llandrindod Wells', value: 'Llandrindod Wells' },
  { label: 'Llandudno', value: 'Llandudno' },
  { label: 'Llandybie', value: 'Llandybie' },
  { label: 'Llandysul', value: 'Llandysul' },
  { label: 'Llanelli', value: 'Llanelli' },
  { label: 'Llanera', value: 'Llanera' },
  { label: 'Llanera', value: 'Llanera' },
  { label: 'Llanera', value: 'Llanera' },
  { label: 'Llanera De Ranes', value: 'Llanera De Ranes' },
  { label: 'Llanerchymedd', value: 'Llanerchymedd' },
  { label: 'Llanes', value: 'Llanes' },
  { label: 'Llanfair Caereinion', value: 'Llanfair Caereinion' },
  { label: 'Llanfairfechan', value: 'Llanfairfechan' },
  { label: 'Llanfairpwllgwyngyll', value: 'Llanfairpwllgwyngyll' },
  { label: 'Llanfyllin', value: 'Llanfyllin' },
  { label: 'Llangefni', value: 'Llangefni' },
  { label: 'Llangoed', value: 'Llangoed' },
  { label: 'Llangollen', value: 'Llangollen' },
  { label: 'Llangwm', value: 'Llangwm' },
  { label: 'Llangybi', value: 'Llangybi' },
  { label: 'Llangynidr', value: 'Llangynidr' },
  { label: 'Llanharan', value: 'Llanharan' },
  { label: 'Llanharry', value: 'Llanharry' },
  { label: 'Llanidloes', value: 'Llanidloes' },
  { label: 'Llanilar', value: 'Llanilar' },
  { label: 'Llano', value: 'Llano' },
  { label: 'Llano Abajo', value: 'Llano Abajo' },
  { label: 'Llano Bonito', value: 'Llano Bonito' },
  { label: 'Llano County', value: 'Llano County' },
  { label: 'Llano De Agua', value: 'Llano De Agua' },
  { label: 'Llano De Bureba', value: 'Llano De Bureba' },
  { label: 'Llano De Enmedio', value: 'Llano De Enmedio' },
  { label: 'Llano De La Cruz', value: 'Llano De La Cruz' },
  { label: 'Llano De La Cruz', value: 'Llano De La Cruz' },
  { label: 'Llano De La Lima', value: 'Llano De La Lima' },
  { label: 'Llano De La Puerta', value: 'Llano De La Puerta' },
  { label: 'Llano De La Y', value: 'Llano De La Y' },
  { label: 'Llano De Las Flores (Barrio Del Hueso)', value: 'Llano De Las Flores (Barrio Del Hueso)' },
  { label: 'Llano De Olmedo', value: 'Llano De Olmedo' },
  { label: 'Llano De Piedra', value: 'Llano De Piedra' },
  { label: 'Llano De Zacapexco', value: 'Llano De Zacapexco' },
  { label: 'Llano Del Tigre', value: 'Llano Del Tigre' },
  { label: 'Llano Grande', value: 'Llano Grande' },
  { label: 'Llano Grande', value: 'Llano Grande' },
  { label: 'Llano Grande', value: 'Llano Grande' },
  { label: 'Llano Grande', value: 'Llano Grande' },
  { label: 'Llano Grande', value: 'Llano Grande' },
  { label: 'Llano Grande', value: 'Llano Grande' },
  { label: 'Llano Grande', value: 'Llano Grande' },
  { label: 'Llano Grande', value: 'Llano Grande' },
  { label: 'Llano Grande', value: 'Llano Grande' },
  { label: 'Llano Largo', value: 'Llano Largo' },
  { label: 'Llano Largo', value: 'Llano Largo' },
  { label: 'Llano Largo', value: 'Llano Largo' },
  { label: 'Llano Marín', value: 'Llano Marín' },
  { label: 'Llano Ñopo', value: 'Llano Ñopo' },
  { label: 'Llano Suchiapa', value: 'Llano Suchiapa' },
  { label: 'Llanos De Aridane Los', value: 'Llanos De Aridane Los' },
  { label: 'Llanos De Tormes Los', value: 'Llanos De Tormes Los' },
  { label: 'Llanos Del Caudillo', value: 'Llanos Del Caudillo' },
  { label: 'Llanquihue', value: 'Llanquihue' },
  { label: 'Llanrhaeadr-Ym-Mochnant', value: 'Llanrhaeadr-Ym-Mochnant' },
  { label: 'Llanrug', value: 'Llanrug' },
  { label: 'Llanrwst', value: 'Llanrwst' },
  { label: 'Llansantffraid Glan Conwy', value: 'Llansantffraid Glan Conwy' },
  { label: 'Llansteffan', value: 'Llansteffan' },
  { label: 'Llantrisant', value: 'Llantrisant' },
  { label: 'Llantwit Fardre', value: 'Llantwit Fardre' },
  { label: 'Llantwit Major', value: 'Llantwit Major' },
  { label: 'Llardecans', value: 'Llardecans' },
  { label: 'Llata', value: 'Llata' },
  { label: 'Llaurí', value: 'Llaurí' },
  { label: 'Llavorsí', value: 'Llavorsí' },
  { label: 'Lledó', value: 'Lledó' },
  { label: 'Lleida', value: 'Lleida' },
  { label: 'Llera', value: 'Llera' },
  { label: 'Llera', value: 'Llera' },
  { label: 'Llera De Canales', value: 'Llera De Canales' },
  { label: 'Llerena', value: 'Llerena' },
  { label: 'Llers', value: 'Llers' },
  { label: 'Lles De Cerdanya', value: 'Lles De Cerdanya' },
  { label: 'Llíber', value: 'Llíber' },
  { label: 'Lliçà Damunt', value: 'Lliçà Damunt' },
  { label: 'Lliçà De Vall', value: 'Lliçà De Vall' },
  { label: 'Llimiana', value: 'Llimiana' },
  { label: 'Llinars Del Vallès', value: 'Llinars Del Vallès' },
  { label: 'Llíria', value: 'Llíria' },
  { label: 'Llívia', value: 'Llívia' },
  { label: 'Lloar', value: 'Lloar' },
  { label: 'Llobera', value: 'Llobera' },
  { label: 'Llocnou De La Corona', value: 'Llocnou De La Corona' },
  { label: 'Llocnou De Sant Jeroni', value: 'Llocnou De Sant Jeroni' },
  { label: 'Llocnou Den Fenollet', value: 'Llocnou Den Fenollet' },
  { label: 'Llombai', value: 'Llombai' },
  { label: 'Llongasora', value: 'Llongasora' },
  { label: 'Llorac', value: 'Llorac' },
  { label: 'Lloren', value: 'Lloren' },
  { label: 'Lloren', value: 'Lloren' },
  { label: 'Llorenç Del Penedès', value: 'Llorenç Del Penedès' },
  { label: 'Llorente', value: 'Llorente' },
  { label: 'Llorente', value: 'Llorente' },
  { label: 'Llorente', value: 'Llorente' },
  { label: 'Lloret De Mar', value: 'Lloret De Mar' },
  { label: 'Lloret De Vistalegre', value: 'Lloret De Vistalegre' },
  { label: 'Lloró', value: 'Lloró' },
  { label: 'Llosa De Ranes', value: 'Llosa De Ranes' },
  { label: 'Llosa De Ranes La', value: 'Llosa De Ranes La' },
  { label: 'Llosa La', value: 'Llosa La' },
  { label: 'Lloseta', value: 'Lloseta' },
  { label: 'Llosses Les', value: 'Llosses Les' },
  { label: 'Lloyd Harbor', value: 'Lloyd Harbor' },
  { label: 'Lloydminster', value: 'Lloydminster' },
  { label: 'Lloyds', value: 'Lloyds' },
  { label: 'Llubí', value: 'Llubí' },
  { label: 'Lluçà', value: 'Lluçà' },
  { label: 'Llucmajor', value: 'Llucmajor' },
  { label: 'Lluidas Vale', value: 'Lluidas Vale' },
  { label: 'Llupia', value: 'Llupia' },
  { label: 'Lluta', value: 'Lluta' },
  { label: 'Llutxent', value: 'Llutxent' },
  { label: 'Lluveras', value: 'Lluveras' },
  { label: 'Llwynypia', value: 'Llwynypia' },
  { label: 'Lms', value: 'Lms' },
  { label: 'Lniano', value: 'Lniano' },
  { label: 'Lo Arado', value: 'Lo Arado' },
  { label: 'Lo Barnechea', value: 'Lo Barnechea' },
  { label: 'Lo De Jesús (Campo Romero)', value: 'Lo De Jesús (Campo Romero)' },
  { label: 'Lo De Juárez', value: 'Lo De Juárez' },
  { label: 'Lo De Lamedo', value: 'Lo De Lamedo' },
  { label: 'Lo De Marcos', value: 'Lo De Marcos' },
  { label: 'Lo Espejo', value: 'Lo Espejo' },
  { label: 'Lo Pagán', value: 'Lo Pagán' },
  { label: 'Lo Prado', value: 'Lo Prado' },
  { label: 'Loa', value: 'Loa' },
  { label: 'Loa Janan', value: 'Loa Janan' },
  { label: 'Loacan', value: 'Loacan' },
  { label: 'Loacan', value: 'Loacan' },
  { label: 'Loamneş', value: 'Loamneş' },
  { label: 'Loanda', value: 'Loanda' },
  { label: 'Loandjili', value: 'Loandjili' },
  { label: 'Loanhead', value: 'Loanhead' },
  { label: 'Loano', value: 'Loano' },
  { label: 'Loarre', value: 'Loarre' },
  { label: 'Loay', value: 'Loay' },
  { label: 'Loazzolo', value: 'Loazzolo' },
  { label: 'Lobamba', value: 'Lobamba' },
  { label: 'Lobanovo', value: 'Lobanovo' },
  { label: 'Lobanovo', value: 'Lobanovo' },
  { label: 'Lobão', value: 'Lobão' },
  { label: 'Lobato', value: 'Lobato' },
  { label: 'Lobatos', value: 'Lobatos' },
  { label: 'Löbau', value: 'Löbau' },
  { label: 'Lobbach', value: 'Lobbach' },
  { label: 'Lobbes', value: 'Lobbes' },
  { label: 'Lobeira', value: 'Lobeira' },
  { label: 'Löbejün', value: 'Löbejün' },
  { label: 'Lobera De Onsella', value: 'Lobera De Onsella' },
  { label: 'Löberitz', value: 'Löberitz' },
  { label: 'Löberöd', value: 'Löberöd' },
  { label: 'Lobersberg', value: 'Lobersberg' },
  { label: 'Lobethal', value: 'Lobethal' },
  { label: 'Łobez', value: 'Łobez' },
  { label: 'Löbichau', value: 'Löbichau' },
  { label: 'Lobios', value: 'Lobios' },
  { label: 'Lobith', value: 'Lobith' },
  { label: 'Lobito', value: 'Lobito' },
  { label: 'Lobnig', value: 'Lobnig' },
  { label: 'Löbnitz', value: 'Löbnitz' },
  { label: 'Lobnya', value: 'Lobnya' },
  { label: 'Lobo', value: 'Lobo' },
  { label: 'Loboc', value: 'Loboc' },
  { label: 'Łobodno', value: 'Łobodno' },
  { label: 'Lobón', value: 'Lobón' },
  { label: 'Lobong', value: 'Lobong' },
  { label: 'Lobong', value: 'Lobong' },
  { label: 'Lobras', value: 'Lobras' },
  { label: 'Lobstädt', value: 'Lobstädt' },
  { label: 'Lobuni', value: 'Lobuni' },
  { label: 'Loburg', value: 'Loburg' },
  { label: 'Lobva', value: 'Lobva' },
  { label: 'Łobżenica', value: 'Łobżenica' },
  { label: 'Locana', value: 'Locana' },
  { label: 'Locara', value: 'Locara' },
  { label: 'Locarno', value: 'Locarno' },
  { label: 'Locarno District', value: 'Locarno District' },
  { label: 'Locate Di Triulzi', value: 'Locate Di Triulzi' },
  { label: 'Locate Varesino', value: 'Locate Varesino' },
  { label: 'Locatello', value: 'Locatello' },
  { label: 'Loceri', value: 'Loceri' },
  { label: 'Loch Garman', value: 'Loch Garman' },
  { label: 'Loch Lomond', value: 'Loch Lomond' },
  { label: 'Locharbriggs', value: 'Locharbriggs' },
  { label: 'Lochau', value: 'Lochau' },
  { label: 'Lochau', value: 'Lochau' },
  { label: 'Lochbuie', value: 'Lochbuie' },
  { label: 'Loché', value: 'Loché' },
  { label: 'Lochearn', value: 'Lochearn' },
  { label: 'Lochem', value: 'Lochem' },
  { label: 'Loches', value: 'Loches' },
  { label: 'Löchgau', value: 'Löchgau' },
  { label: 'Lochgelly', value: 'Lochgelly' },
  { label: 'Lochgilphead', value: 'Lochgilphead' },
  { label: 'Lochmaben', value: 'Lochmaben' },
  { label: 'Lochmoor Waterway Estates', value: 'Lochmoor Waterway Estates' },
  { label: 'Lochovice', value: 'Lochovice' },
  { label: 'Łochów', value: 'Łochów' },
  { label: 'Lochristi', value: 'Lochristi' },
  { label: 'Lochsloy', value: 'Lochsloy' },
  { label: 'Lochwinnoch', value: 'Lochwinnoch' },
  { label: 'Lock Haven', value: 'Lock Haven' },
  { label: 'Lockeford', value: 'Lockeford' },
  { label: 'Lockenhaus', value: 'Lockenhaus' },
  { label: 'Lockerbie', value: 'Lockerbie' },
  { label: 'Lockhart', value: 'Lockhart' },
  { label: 'Lockhart', value: 'Lockhart' },
  { label: 'Lockhart', value: 'Lockhart' },
  { label: 'Lockhart River', value: 'Lockhart River' },
  { label: 'Locking', value: 'Locking' },
  { label: 'Lockington', value: 'Lockington' },
  { label: 'Lockland', value: 'Lockland' },
  { label: 'Lockleys', value: 'Lockleys' },
  { label: 'Lockney', value: 'Lockney' },
  { label: 'Löcknitz', value: 'Löcknitz' },
  { label: 'Lockport', value: 'Lockport' },
  { label: 'Lockport', value: 'Lockport' },
  { label: 'Lockport', value: 'Lockport' },
  { label: 'Lockport Heights', value: 'Lockport Heights' },
  { label: 'Lockridge', value: 'Lockridge' },
  { label: 'Lockwood', value: 'Lockwood' },
  { label: 'Lockyer', value: 'Lockyer' },
  { label: 'Lockyer Valley', value: 'Lockyer Valley' },
  { label: 'Locmariaquer', value: 'Locmariaquer' },
  { label: 'Locmayan', value: 'Locmayan' },
  { label: 'Locminé', value: 'Locminé' },
  { label: 'Locmiquélic', value: 'Locmiquélic' },
  { label: 'Locoal-Mendon', value: 'Locoal-Mendon' },
  { label: 'Locon', value: 'Locon' },
  { label: 'Locorotondo', value: 'Locorotondo' },
  { label: 'Locquémeau', value: 'Locquémeau' },
  { label: 'Locquirec', value: 'Locquirec' },
  { label: 'Locri', value: 'Locri' },
  { label: 'Loctudy', value: 'Loctudy' },
  { label: 'Loculi', value: 'Loculi' },
  { label: 'Locust', value: 'Locust' },
  { label: 'Locust Fork', value: 'Locust Fork' },
  { label: 'Locust Grove', value: 'Locust Grove' },
  { label: 'Locust Grove', value: 'Locust Grove' },
  { label: 'Locust Valley', value: 'Locust Valley' },
  { label: 'Lod', value: 'Lod' },
  { label: 'Löddeköpinge', value: 'Löddeköpinge' },
  { label: 'Loddin', value: 'Loddin' },
  { label: 'Loddon', value: 'Loddon' },
  { label: 'Loddon', value: 'Loddon' },
  { label: 'Lodè', value: 'Lodè' },
  { label: 'Loděnice', value: 'Loděnice' },
  { label: 'Lödersdorf', value: 'Lödersdorf' },
  { label: 'Lodetto', value: 'Lodetto' },
  { label: 'Lodève', value: 'Lodève' },
  { label: 'Lodeynopol’Skiy Rayon', value: 'Lodeynopol’Skiy Rayon' },
  { label: 'Lodeynoye Pole', value: 'Lodeynoye Pole' },
  { label: 'Lodhikheda', value: 'Lodhikheda' },
  { label: 'Lodhran', value: 'Lodhran' },
  { label: 'Lodi', value: 'Lodi' },
  { label: 'Lodi', value: 'Lodi' },
  { label: 'Lodi', value: 'Lodi' },
  { label: 'Lodi', value: 'Lodi' },
  { label: 'Lodi', value: 'Lodi' },
  { label: 'Lodi', value: 'Lodi' },
  { label: 'Lodi Vecchio', value: 'Lodi Vecchio' },
  { label: 'Lodine', value: 'Lodine' },
  { label: 'Løding', value: 'Løding' },
  { label: 'Lødingen', value: 'Lødingen' },
  { label: 'Lodja', value: 'Lodja' },
  { label: 'Lodo Grande', value: 'Lodo Grande' },
  { label: 'Lodosa', value: 'Lodosa' },
  { label: 'Lödöse', value: 'Lödöse' },
  { label: 'Lodrino', value: 'Lodrino' },
  { label: 'Lodrino', value: 'Lodrino' },
  { label: 'Lodrone-Darzo', value: 'Lodrone-Darzo' },
  { label: 'Lodwar', value: 'Lodwar' },
  { label: 'Łodygowice', value: 'Łodygowice' },
  { label: 'Łódź', value: 'Łódź' },
  { label: 'Loeches', value: 'Loeches' },
  { label: 'Loei', value: 'Loei' },
  { label: 'Loenen', value: 'Loenen' },
  { label: 'Löf', value: 'Löf' },
  { label: 'Lofall', value: 'Lofall' },
  { label: 'Lofer', value: 'Lofer' },
  { label: 'Loffenau', value: 'Loffenau' },
  { label: 'Löffingen', value: 'Löffingen' },
  { label: 'Lófos', value: 'Lófos' },
  { label: 'Lofthouse', value: 'Lofthouse' },
  { label: 'Loftus', value: 'Loftus' },
  { label: 'Loftus', value: 'Loftus' },
  { label: 'Log', value: 'Log' },
  { label: 'Log Pri Brezovici', value: 'Log Pri Brezovici' },
  { label: 'Logan', value: 'Logan' },
  { label: 'Logan', value: 'Logan' },
  { label: 'Logan', value: 'Logan' },
  { label: 'Logan', value: 'Logan' },
  { label: 'Logan', value: 'Logan' },
  { label: 'Logan', value: 'Logan' },
  { label: 'Logan Central', value: 'Logan Central' },
  { label: 'Logan City', value: 'Logan City' },
  { label: 'Logan County', value: 'Logan County' },
  { label: 'Logan County', value: 'Logan County' },
  { label: 'Logan County', value: 'Logan County' },
  { label: 'Logan County', value: 'Logan County' },
  { label: 'Logan County', value: 'Logan County' },
  { label: 'Logan County', value: 'Logan County' },
  { label: 'Logan County', value: 'Logan County' },
  { label: 'Logan County', value: 'Logan County' },
  { label: 'Logan County', value: 'Logan County' },
  { label: 'Logan County', value: 'Logan County' },
  { label: 'Logan Elm Village', value: 'Logan Elm Village' },
  { label: 'Logan Lake', value: 'Logan Lake' },
  { label: 'Logan Reserve', value: 'Logan Reserve' },
  { label: 'Logan Square', value: 'Logan Square' },
  { label: 'Logan Village', value: 'Logan Village' },
  { label: 'Loganholme', value: 'Loganholme' },
  { label: 'Loganlea', value: 'Loganlea' },
  { label: 'Logansport', value: 'Logansport' },
  { label: 'Logansport', value: 'Logansport' },
  { label: 'Loganville', value: 'Loganville' },
  { label: 'Loganville', value: 'Loganville' },
  { label: 'Logatec', value: 'Logatec' },
  { label: 'Logig', value: 'Logig' },
  { label: 'Lognes', value: 'Lognes' },
  { label: 'Logon', value: 'Logon' },
  { label: 'Logonna-Daoulas', value: 'Logonna-Daoulas' },
  { label: 'Logovardi', value: 'Logovardi' },
  { label: 'Logovskoye', value: 'Logovskoye' },
  { label: 'Logradouro', value: 'Logradouro' },
  { label: 'Lograto', value: 'Lograto' },
  { label: 'Logreşti', value: 'Logreşti' },
  { label: 'Logrești Moșteni', value: 'Logrești Moșteni' },
  { label: 'Logroño', value: 'Logroño' },
  { label: 'Logrosán', value: 'Logrosán' },
  { label: 'Løgstør', value: 'Løgstør' },
  { label: 'Løgten', value: 'Løgten' },
  { label: 'Løgumkloster', value: 'Løgumkloster' },
  { label: 'Logwood', value: 'Logwood' },
  { label: 'Lohaghat', value: 'Lohaghat' },
  { label: 'Lohardaga', value: 'Lohardaga' },
  { label: 'Loharu', value: 'Loharu' },
  { label: 'Lohberg', value: 'Lohberg' },
  { label: 'Lôh-Djiboua', value: 'Lôh-Djiboua' },
  { label: 'Lohe-Rickelshof', value: 'Lohe-Rickelshof' },
  { label: 'Lohfelden', value: 'Lohfelden' },
  { label: 'Lohit District', value: 'Lohit District' },
  { label: 'Lohja', value: 'Lohja' },
  { label: 'Lohmar', value: 'Lohmar' },
  { label: 'Lohmen', value: 'Lohmen' },
  { label: 'Löhnberg', value: 'Löhnberg' },
  { label: 'Lohne', value: 'Lohne' },
  { label: 'Löhne', value: 'Löhne' },
  { label: 'Löhningen', value: 'Löhningen' },
  { label: 'Lohogaon', value: 'Lohogaon' },
  { label: 'Lohr Am Main', value: 'Lohr Am Main' },
  { label: 'Lohra', value: 'Lohra' },
  { label: 'Lohsa', value: 'Lohsa' },
  { label: 'Lohtaja', value: 'Lohtaja' },
  { label: 'Loiano', value: 'Loiano' },
  { label: 'Loich', value: 'Loich' },
  { label: 'Loiching', value: 'Loiching' },
  { label: 'Loikaw', value: 'Loikaw' },
  { label: 'Loipersbach Im Burgenland', value: 'Loipersbach Im Burgenland' },
  { label: 'Loipersdorf Bei Fürstenfeld', value: 'Loipersdorf Bei Fürstenfeld' },
  { label: 'Loire-Atlantique', value: 'Loire-Atlantique' },
  { label: 'Loire-Sur-Rhône', value: 'Loire-Sur-Rhône' },
  { label: 'Loiret', value: 'Loiret' },
  { label: 'Loiri Porto San Paolo', value: 'Loiri Porto San Paolo' },
  { label: 'Loiron', value: 'Loiron' },
  { label: 'Loisin', value: 'Loisin' },
  { label: 'Loison-Sous-Lens', value: 'Loison-Sous-Lens' },
  { label: 'Loitz', value: 'Loitz' },
  { label: 'Loiu', value: 'Loiu' },
  { label: 'Loivre', value: 'Loivre' },
  { label: 'Loíza', value: 'Loíza' },
  { label: 'Loíza', value: 'Loíza' },
  { label: 'Loja', value: 'Loja' },
  { label: 'Loja', value: 'Loja' },
  { label: 'Lojane', value: 'Lojane' },
  { label: 'Løjt Kirkeby', value: 'Løjt Kirkeby' },
  { label: 'Lok', value: 'Lok' },
  { label: 'Lokachi', value: 'Lokachi' },
  { label: 'Lokavec', value: 'Lokavec' },
  { label: 'Lökbatan', value: 'Lökbatan' },
  { label: 'Lokeren', value: 'Lokeren' },
  { label: 'Loket', value: 'Loket' },
  { label: 'Lokhvytsia', value: 'Lokhvytsia' },
  { label: 'Løkken', value: 'Løkken' },
  { label: 'Loknya', value: 'Loknya' },
  { label: 'Lokoja', value: 'Lokoja' },
  { label: 'Lokomotivnyy', value: 'Lokomotivnyy' },
  { label: 'Lőkösháza', value: 'Lőkösháza' },
  { label: 'Lokosovo', value: 'Lokosovo' },
  { label: 'Lokossa', value: 'Lokossa' },
  { label: 'Lokot’', value: 'Lokot’' },
  { label: 'Loksa', value: 'Loksa' },
  { label: 'Loksa Linn', value: 'Loksa Linn' },
  { label: 'Loktevskiy Rayon', value: 'Loktevskiy Rayon' },
  { label: 'Lokve', value: 'Lokve' },
  { label: 'Lokvičići', value: 'Lokvičići' },
  { label: 'Lokvine', value: 'Lokvine' },
  { label: 'Lola', value: 'Lola' },
  { label: 'Lolá', value: 'Lolá' },
  { label: 'Lolland Kommune', value: 'Lolland Kommune' },
  { label: 'Lollar', value: 'Lollar' },
  { label: 'Lolleria', value: 'Lolleria' },
  { label: 'Lolo', value: 'Lolo' },
  { label: 'Lolodorf', value: 'Lolodorf' },
  { label: 'Loloiasca', value: 'Loloiasca' },
  { label: 'Lolol', value: 'Lolol' },
  { label: 'Lolotique', value: 'Lolotique' },
  { label: 'Lolotla', value: 'Lolotla' },
  { label: 'Lom', value: 'Lom' },
  { label: 'Lom', value: 'Lom' },
  { label: 'Lom Sak', value: 'Lom Sak' },
  { label: 'Lom U Mostu', value: 'Lom U Mostu' },
  { label: 'Loma', value: 'Loma' },
  { label: 'Loma', value: 'Loma' },
  { label: 'Loma Alta', value: 'Loma Alta' },
  { label: 'Loma Alta', value: 'Loma Alta' },
  { label: 'Loma Alta', value: 'Loma Alta' },
  { label: 'Loma Alta Taxhimay', value: 'Loma Alta Taxhimay' },
  { label: 'Loma Angosta', value: 'Loma Angosta' },
  { label: 'Loma Blanca', value: 'Loma Blanca' },
  { label: 'Loma Bonita', value: 'Loma Bonita' },
  { label: 'Loma Bonita', value: 'Loma Bonita' },
  { label: 'Loma Bonita', value: 'Loma Bonita' },
  { label: 'Loma Bonita', value: 'Loma Bonita' },
  { label: 'Loma Bonita', value: 'Loma Bonita' },
  { label: 'Loma Chapultepec', value: 'Loma Chapultepec' },
  { label: 'Loma De Bácum', value: 'Loma De Bácum' },
  { label: 'Loma De Cabrera', value: 'Loma De Cabrera' },
  { label: 'Loma De Gato', value: 'Loma De Gato' },
  { label: 'Loma De Guadalupe (La Biznaga)', value: 'Loma De Guadalupe (La Biznaga)' },
  { label: 'Loma De Guamúchil', value: 'Loma De Guamúchil' },
  { label: 'Loma De Juárez', value: 'Loma De Juárez' },
  { label: 'Loma De La Esperanza', value: 'Loma De La Esperanza' },
  { label: 'Loma De Los Carmona', value: 'Loma De Los Carmona' },
  { label: 'Loma De Los Hoyos', value: 'Loma De Los Hoyos' },
  { label: 'Loma De San Francisco', value: 'Loma De San Francisco' },
  { label: 'Loma De San José', value: 'Loma De San José' },
  { label: 'Loma De San Miguel', value: 'Loma De San Miguel' },
  { label: 'Loma De Sogotegoyo', value: 'Loma De Sogotegoyo' },
  { label: 'Loma De Ucieza', value: 'Loma De Ucieza' },
  { label: 'Loma De Yerbabuena', value: 'Loma De Yerbabuena' },
  { label: 'Loma De Zempoala', value: 'Loma De Zempoala' },
  { label: 'Loma Del Refugio', value: 'Loma Del Refugio' },
  { label: 'Loma Del Río', value: 'Loma Del Río' },
  { label: 'Loma Grande', value: 'Loma Grande' },
  { label: 'Loma La Paz', value: 'Loma La Paz' },
  { label: 'Loma Larga', value: 'Loma Larga' },
  { label: 'Loma Larga (Barrio De Loma Larga)', value: 'Loma Larga (Barrio De Loma Larga)' },
  { label: 'Loma Linda', value: 'Loma Linda' },
  { label: 'Loma Linda', value: 'Loma Linda' },
  { label: 'Loma Pelada', value: 'Loma Pelada' },
  { label: 'Loma Rica', value: 'Loma Rica' },
  { label: 'Loma Tendida', value: 'Loma Tendida' },
  { label: 'Loma Yuca', value: 'Loma Yuca' },
  { label: 'Lomagna', value: 'Lomagna' },
  { label: 'Lomaiviti Province', value: 'Lomaiviti Province' },
  { label: 'Lomantán', value: 'Lomantán' },
  { label: 'Lomas', value: 'Lomas' },
  { label: 'Lomas', value: 'Lomas' },
  { label: 'Lomas De Ahuatepec', value: 'Lomas De Ahuatepec' },
  { label: 'Lomas De Altavista', value: 'Lomas De Altavista' },
  { label: 'Lomas De Barrillas', value: 'Lomas De Barrillas' },
  { label: 'Lomas De Chapultepec', value: 'Lomas De Chapultepec' },
  { label: 'Lomas De La Maestranza', value: 'Lomas De La Maestranza' },
  { label: 'Lomas De Río Medio Cuatro', value: 'Lomas De Río Medio Cuatro' },
  { label: 'Lomas De Romero', value: 'Lomas De Romero' },
  { label: 'Lomas De San Agustín', value: 'Lomas De San Agustín' },
  { label: 'Lomas De San Francisco', value: 'Lomas De San Francisco' },
  { label: 'Lomas De San Juan', value: 'Lomas De San Juan' },
  { label: 'Lomas De San Martín', value: 'Lomas De San Martín' },
  { label: 'Lomas De San Pablo', value: 'Lomas De San Pablo' },
  { label: 'Lomas De San Sebastián', value: 'Lomas De San Sebastián' },
  { label: 'Lomas De Santa Anita', value: 'Lomas De Santa Anita' },
  { label: 'Lomas De Santa Cruz', value: 'Lomas De Santa Cruz' },
  { label: 'Lomas De Sargentillo', value: 'Lomas De Sargentillo' },
  { label: 'Lomas De Tejeda', value: 'Lomas De Tejeda' },
  { label: 'Lomas De Tenopalco', value: 'Lomas De Tenopalco' },
  { label: 'Lomas De Tepemecatl', value: 'Lomas De Tepemecatl' },
  { label: 'Lomas De Tlatelolco', value: 'Lomas De Tlatelolco' },
  { label: 'Lomas De Vallejos', value: 'Lomas De Vallejos' },
  { label: 'Lomas Del Real', value: 'Lomas Del Real' },
  { label: 'Lomas Del Sur', value: 'Lomas Del Sur' },
  { label: 'Lomas Verdes', value: 'Lomas Verdes' },
  { label: 'Lomashasha', value: 'Lomashasha' },
  { label: 'Łomazy', value: 'Łomazy' },
  { label: 'Lomazzo', value: 'Lomazzo' },
  { label: 'Lombard', value: 'Lombard' },
  { label: 'Lombard', value: 'Lombard' },
  { label: 'Lombardía', value: 'Lombardía' },
  { label: 'Lombardijen', value: 'Lombardijen' },
  { label: 'Lombardore', value: 'Lombardore' },
  { label: 'Lombez', value: 'Lombez' },
  { label: 'Lombocan', value: 'Lombocan' },
  { label: 'Lombog', value: 'Lombog' },
  { label: 'Lomboy', value: 'Lomboy' },
  { label: 'Lomboy', value: 'Lomboy' },
  { label: 'Lombriasco', value: 'Lombriasco' },
  { label: 'Lombron', value: 'Lombron' },
  { label: 'Lomé', value: 'Lomé' },
  { label: 'Lomello', value: 'Lomello' },
  { label: 'Łomianki', value: 'Łomianki' },
  { label: 'Lominchar', value: 'Lominchar' },
  { label: 'Lomintsevskiy', value: 'Lomintsevskiy' },
  { label: 'Lomira', value: 'Lomira' },
  { label: 'Lomita', value: 'Lomita' },
  { label: 'Lomitas', value: 'Lomitas' },
  { label: 'Lomitas', value: 'Lomitas' },
  { label: 'Lomma', value: 'Lomma' },
  { label: 'Lommatzsch', value: 'Lommatzsch' },
  { label: 'Lomme', value: 'Lomme' },
  { label: 'Lommel', value: 'Lommel' },
  { label: 'Lomnice', value: 'Lomnice' },
  { label: 'Lomnice', value: 'Lomnice' },
  { label: 'Lomnice Nad Lužnicí', value: 'Lomnice Nad Lužnicí' },
  { label: 'Lomnice Nad Popelkou', value: 'Lomnice Nad Popelkou' },
  { label: 'Lomo De Arico', value: 'Lomo De Arico' },
  { label: 'Lomonosov', value: 'Lomonosov' },
  { label: 'Lomonosovskiy Rayon', value: 'Lomonosovskiy Rayon' },
  { label: 'Lomoviejo', value: 'Lomoviejo' },
  { label: 'Lomovka', value: 'Lomovka' },
  { label: 'Lompico', value: 'Lompico' },
  { label: 'Lompoc', value: 'Lompoc' },
  { label: 'Lompret', value: 'Lompret' },
  { label: 'Łomża', value: 'Łomża' },
  { label: 'Lonaconing', value: 'Lonaconing' },
  { label: 'Lona-Lases', value: 'Lona-Lases' },
  { label: 'Lonar', value: 'Lonar' },
  { label: 'Lonate Ceppino', value: 'Lonate Ceppino' },
  { label: 'Lonate Pozzolo', value: 'Lonate Pozzolo' },
  { label: 'Lonato', value: 'Lonato' },
  { label: 'Lonavla', value: 'Lonavla' },
  { label: 'Loncoche', value: 'Loncoche' },
  { label: 'Loncopué', value: 'Loncopué' },
  { label: 'Londa', value: 'Londa' },
  { label: 'Londa', value: 'Londa' },
  { label: 'Londerzeel', value: 'Londerzeel' },
  { label: 'Londiani', value: 'Londiani' },
  { label: 'Londinières', value: 'Londinières' },
  { label: 'Londoko', value: 'Londoko' },
  { label: 'London', value: 'London' },
  { label: 'London', value: 'London' },
  { label: 'London', value: 'London' },
  { label: 'London', value: 'London' },
  { label: 'London', value: 'London' },
  { label: 'London', value: 'London' },
  { label: 'London Village', value: 'London Village' },
  { label: 'Londonderry', value: 'Londonderry' },
  { label: 'Londonderry', value: 'Londonderry' },
  { label: 'Londonderry', value: 'Londonderry' },
  { label: 'Londonderry', value: 'Londonderry' },
  { label: 'Londonderry County Borough', value: 'Londonderry County Borough' },
  { label: 'Londontowne', value: 'Londontowne' },
  { label: 'Londres', value: 'Londres' },
  { label: 'Londrina', value: 'Londrina' },
  { label: 'Lone Grove', value: 'Lone Grove' },
  { label: 'Lone Jack', value: 'Lone Jack' },
  { label: 'Lone Oak', value: 'Lone Oak' },
  { label: 'Lone Pine', value: 'Lone Pine' },
  { label: 'Lone Star', value: 'Lone Star' },
  { label: 'Lone Tree', value: 'Lone Tree' },
  { label: 'Lone Tree', value: 'Lone Tree' },
  { label: 'Lonevåg', value: 'Lonevåg' },
  { label: 'Long', value: 'Long' },
  { label: 'Long Ampan Aing Or Abanang', value: 'Long Ampan Aing Or Abanang' },
  { label: 'Long Ashton', value: 'Long Ashton' },
  { label: 'Long Bay', value: 'Long Bay' },
  { label: 'Long Beach', value: 'Long Beach' },
  { label: 'Long Beach', value: 'Long Beach' },
  { label: 'Long Beach', value: 'Long Beach' },
  { label: 'Long Beach', value: 'Long Beach' },
  { label: 'Long Beach', value: 'Long Beach' },
  { label: 'Long Beach', value: 'Long Beach' },
  { label: 'Long Beach', value: 'Long Beach' },
  { label: 'Long Beach', value: 'Long Beach' },
  { label: 'Long Bennington', value: 'Long Bennington' },
  { label: 'Long Branch', value: 'Long Branch' },
  { label: 'Long Buckby', value: 'Long Buckby' },
  { label: 'Long Clawson', value: 'Long Clawson' },
  { label: 'Long County', value: 'Long County' },
  { label: 'Long Creek', value: 'Long Creek' },
  { label: 'Long Crendon', value: 'Long Crendon' },
  { label: 'Long Eaton', value: 'Long Eaton' },
  { label: 'Long Grove', value: 'Long Grove' },
  { label: 'Long Gully', value: 'Long Gully' },
  { label: 'Long Hill', value: 'Long Hill' },
  { label: 'Long Island City', value: 'Long Island City' },
  { label: 'Long Itchington', value: 'Long Itchington' },
  { label: 'Long Jetty', value: 'Long Jetty' },
  { label: 'Long Lake', value: 'Long Lake' },
  { label: 'Long Lake', value: 'Long Lake' },
  { label: 'Long Lawford', value: 'Long Lawford' },
  { label: 'Long Melford', value: 'Long Melford' },
  { label: 'Long Mountain', value: 'Long Mountain' },
  { label: 'Long Neck', value: 'Long Neck' },
  { label: 'Long Prairie', value: 'Long Prairie' },
  { label: 'Long Road', value: 'Long Road' },
  { label: 'Long Road', value: 'Long Road' },
  { label: 'Long Stratton', value: 'Long Stratton' },
  { label: 'Long Sutton', value: 'Long Sutton' },
  { label: 'Long Valley', value: 'Long Valley' },
  { label: 'Long Whatton', value: 'Long Whatton' },
  { label: 'Long Xuyên', value: 'Long Xuyên' },
  { label: 'Long Xuyên City', value: 'Long Xuyên City' },
  { label: 'Longages', value: 'Longages' },
  { label: 'Longano', value: 'Longano' },
  { label: 'Longara', value: 'Longara' },
  { label: 'Longare', value: 'Longare' },
  { label: 'Longares', value: 'Longares' },
  { label: 'Longarone', value: 'Longarone' },
  { label: 'Longás', value: 'Longás' },
  { label: 'Longastrino', value: 'Longastrino' },
  { label: 'Longaví', value: 'Longaví' },
  { label: 'Longboat Key', value: 'Longboat Key' },
  { label: 'Longbranch', value: 'Longbranch' },
  { label: 'Longchaeng', value: 'Longchaeng' },
  { label: 'Longchamp', value: 'Longchamp' },
  { label: 'Longchaumois', value: 'Longchaumois' },
  { label: 'Longdendale', value: 'Longdendale' },
  { label: 'Longecourt-En-Plaine', value: 'Longecourt-En-Plaine' },
  { label: 'Longeville-En-Barrois', value: 'Longeville-En-Barrois' },
  { label: 'Longeville-Lès-Metz', value: 'Longeville-Lès-Metz' },
  { label: 'Longeville-Lès-Saint-Avold', value: 'Longeville-Lès-Saint-Avold' },
  { label: 'Longeville-Sur-Mer', value: 'Longeville-Sur-Mer' },
  { label: 'Longfeng', value: 'Longfeng' },
  { label: 'Longfield', value: 'Longfield' },
  { label: 'Longford', value: 'Longford' },
  { label: 'Longford', value: 'Longford' },
  { label: 'Longford', value: 'Longford' },
  { label: 'Longforgan', value: 'Longforgan' },
  { label: 'Longfossé', value: 'Longfossé' },
  { label: 'Longgang', value: 'Longgang' },
  { label: 'Longhena', value: 'Longhena' },
  { label: 'Longhi', value: 'Longhi' },
  { label: 'Longhope', value: 'Longhope' },
  { label: 'Longhorsley', value: 'Longhorsley' },
  { label: 'Longhoughton', value: 'Longhoughton' },
  { label: 'Longi', value: 'Longi' },
  { label: 'Longiano', value: 'Longiano' },
  { label: 'Longido', value: 'Longido' },
  { label: 'Longjiang', value: 'Longjiang' },
  { label: 'Longjing', value: 'Longjing' },
  { label: 'Longjumeau', value: 'Longjumeau' },
  { label: 'Longkamp', value: 'Longkamp' },
  { label: 'Longlaville', value: 'Longlaville' },
  { label: 'Longlisuo', value: 'Longlisuo' },
  { label: 'Longmeadow', value: 'Longmeadow' },
  { label: 'Longmen', value: 'Longmen' },
  { label: 'Longmont', value: 'Longmont' },
  { label: 'Longnan Shi', value: 'Longnan Shi' },
  { label: 'Longnes', value: 'Longnes' },
  { label: 'Longniddry', value: 'Longniddry' },
  { label: 'Longny-Au-Perche', value: 'Longny-Au-Perche' },
  { label: 'Longobardi', value: 'Longobardi' },
  { label: 'Longobucco', value: 'Longobucco' },
  { label: 'Longone Al Segrino', value: 'Longone Al Segrino' },
  { label: 'Longone Sabino', value: 'Longone Sabino' },
  { label: 'Longonjo', value: 'Longonjo' },
  { label: 'Longos', value: 'Longos' },
  { label: 'Longos', value: 'Longos' },
  { label: 'Longperrier', value: 'Longperrier' },
  { label: 'Longpont-Sur-Orge', value: 'Longpont-Sur-Orge' },
  { label: 'Longpré-Les-Corps-Saints', value: 'Longpré-Les-Corps-Saints' },
  { label: 'Longquan', value: 'Longquan' },
  { label: 'Longreach', value: 'Longreach' },
  { label: 'Longridge', value: 'Longridge' },
  { label: 'Longroiva', value: 'Longroiva' },
  { label: 'Longsight', value: 'Longsight' },
  { label: 'Longstanton', value: 'Longstanton' },
  { label: 'Longtan', value: 'Longtan' },
  { label: 'Longton', value: 'Longton' },
  { label: 'Longtou’An', value: 'Longtou’An' },
  { label: 'Longtown', value: 'Longtown' },
  { label: 'Longtown', value: 'Longtown' },
  { label: 'Longueau', value: 'Longueau' },
  { label: 'Longueil-Annel', value: 'Longueil-Annel' },
  { label: 'Longueil-Sainte-Marie', value: 'Longueil-Sainte-Marie' },
  { label: 'Longuenesse', value: 'Longuenesse' },
  { label: 'Longueuil', value: 'Longueuil' },
  { label: 'Longueville', value: 'Longueville' },
  { label: 'Longueville', value: 'Longueville' },
  { label: 'Longuich', value: 'Longuich' },
  { label: 'Lónguida/Longida', value: 'Lónguida/Longida' },
  { label: 'Longuyon', value: 'Longuyon' },
  { label: 'Longvic', value: 'Longvic' },
  { label: 'Longview', value: 'Longview' },
  { label: 'Longview', value: 'Longview' },
  { label: 'Longview', value: 'Longview' },
  { label: 'Longview Heights', value: 'Longview Heights' },
  { label: 'Longville Park', value: 'Longville Park' },
  { label: 'Longwarry', value: 'Longwarry' },
  { label: 'Longwick', value: 'Longwick' },
  { label: 'Longwood', value: 'Longwood' },
  { label: 'Longwood', value: 'Longwood' },
  { label: 'Longwood', value: 'Longwood' },
  { label: 'Longwy', value: 'Longwy' },
  { label: 'Longyan', value: 'Longyan' },
  { label: 'Loni', value: 'Loni' },
  { label: 'Lonigo', value: 'Lonigo' },
  { label: 'Löningen', value: 'Löningen' },
  { label: 'Łoniów', value: 'Łoniów' },
  { label: 'Lonjica', value: 'Lonjica' },
  { label: 'Lonnerstadt', value: 'Lonnerstadt' },
  { label: 'Lonnig', value: 'Lonnig' },
  { label: 'Lono', value: 'Lono' },
  { label: 'Lonoke', value: 'Lonoke' },
  { label: 'Lonoke County', value: 'Lonoke County' },
  { label: 'Lonoy', value: 'Lonoy' },
  { label: 'Lonquimay', value: 'Lonquimay' },
  { label: 'Lonquimay', value: 'Lonquimay' },
  { label: 'Lons', value: 'Lons' },
  { label: 'Lönsboda', value: 'Lönsboda' },
  { label: 'Lonsdale', value: 'Lonsdale' },
  { label: 'Lonsee', value: 'Lonsee' },
  { label: 'Lons-Le-Saunier', value: 'Lons-Le-Saunier' },
  { label: 'Lontra', value: 'Lontra' },
  { label: 'Lontras', value: 'Lontras' },
  { label: 'Lontzen', value: 'Lontzen' },
  { label: 'Loo', value: 'Loo' },
  { label: 'Looberghe', value: 'Looberghe' },
  { label: 'Looc', value: 'Looc' },
  { label: 'Looc', value: 'Looc' },
  { label: 'Looc', value: 'Looc' },
  { label: 'Looc', value: 'Looc' },
  { label: 'Looc', value: 'Looc' },
  { label: 'Looe', value: 'Looe' },
  { label: 'Loogootee', value: 'Loogootee' },
  { label: 'Lookan', value: 'Lookan' },
  { label: 'Lookan', value: 'Lookan' },
  { label: 'Lookout Mountain', value: 'Lookout Mountain' },
  { label: 'Lookout Mountain', value: 'Lookout Mountain' },
  { label: 'Loomis', value: 'Loomis' },
  { label: 'Loon', value: 'Loon' },
  { label: 'Loon Op Zand', value: 'Loon Op Zand' },
  { label: 'Loon-Plage', value: 'Loon-Plage' },
  { label: 'Loos', value: 'Loos' },
  { label: 'Loosbroek', value: 'Loosbroek' },
  { label: 'Loosdorf', value: 'Loosdorf' },
  { label: 'Loosdrecht', value: 'Loosdrecht' },
  { label: 'Loos-En-Gohelle', value: 'Loos-En-Gohelle' },
  { label: 'Lop Buri', value: 'Lop Buri' },
  { label: 'Lopadea Nouă', value: 'Lopadea Nouă' },
  { label: 'Lopandino', value: 'Lopandino' },
  { label: 'Lopar', value: 'Lopar' },
  { label: 'Lopare', value: 'Lopare' },
  { label: 'Lopătari', value: 'Lopătari' },
  { label: 'Lopatinec', value: 'Lopatinec' },
  { label: 'Lopatino', value: 'Lopatino' },
  { label: 'Lopatinskiy', value: 'Lopatinskiy' },
  { label: 'Lopatyn', value: 'Lopatyn' },
  { label: 'Lope De Vega', value: 'Lope De Vega' },
  { label: 'Lope De Vega', value: 'Lope De Vega' },
  { label: 'Lopera', value: 'Lopera' },
  { label: 'Lopérec', value: 'Lopérec' },
  { label: 'Loperhet', value: 'Loperhet' },
  { label: 'Lopez', value: 'Lopez' },
  { label: 'López De Micay', value: 'López De Micay' },
  { label: 'Lopez Jaena', value: 'Lopez Jaena' },
  { label: 'Lopez Jaena', value: 'Lopez Jaena' },
  { label: 'Lopez Jaena', value: 'Lopez Jaena' },
  { label: 'Lopez Jaena', value: 'Lopez Jaena' },
  { label: 'López Rayón', value: 'López Rayón' },
  { label: 'Lopezville', value: 'Lopezville' },
  { label: 'Łopiennik Górny', value: 'Łopiennik Górny' },
  { label: 'Lopik', value: 'Lopik' },
  { label: 'Loporzano', value: 'Loporzano' },
  { label: 'Loppa', value: 'Loppa' },
  { label: 'Loppersum', value: 'Loppersum' },
  { label: 'Loppi', value: 'Loppi' },
  { label: 'Løpsmarka', value: 'Løpsmarka' },
  { label: 'Lopukhiv', value: 'Lopukhiv' },
  { label: 'Łopuszka Wielka', value: 'Łopuszka Wielka' },
  { label: 'Łopuszna', value: 'Łopuszna' },
  { label: 'Łopuszno', value: 'Łopuszno' },
  { label: 'Loqueb Este', value: 'Loqueb Este' },
  { label: 'Loqueb Este', value: 'Loqueb Este' },
  { label: 'Lora De Estepa', value: 'Lora De Estepa' },
  { label: 'Lora Del Río', value: 'Lora Del Río' },
  { label: 'Lorain', value: 'Lorain' },
  { label: 'Lorain County', value: 'Lorain County' },
  { label: 'Loralai', value: 'Loralai' },
  { label: 'Loralai District', value: 'Loralai District' },
  { label: 'Loranca De Tajuña', value: 'Loranca De Tajuña' },
  { label: 'Lorane', value: 'Lorane' },
  { label: 'Loranzè', value: 'Loranzè' },
  { label: 'Lorca', value: 'Lorca' },
  { label: 'Lorch', value: 'Lorch' },
  { label: 'Lorcha/Orxa L', value: 'Lorcha/Orxa L' },
  { label: 'Lordegan', value: 'Lordegan' },
  { label: 'Lordelo', value: 'Lordelo' },
  { label: 'Lordelo', value: 'Lordelo' },
  { label: 'Lordsburg', value: 'Lordsburg' },
  { label: 'Lordstown', value: 'Lordstown' },
  { label: 'Loreggia', value: 'Loreggia' },
  { label: 'Loreggiola', value: 'Loreggiola' },
  { label: 'Loreglia', value: 'Loreglia' },
  { label: 'Lorena', value: 'Lorena' },
  { label: 'Lorena', value: 'Lorena' },
  { label: 'Lorengau', value: 'Lorengau' },
  { label: 'Lørenskog', value: 'Lørenskog' },
  { label: 'Lorentzweiler', value: 'Lorentzweiler' },
  { label: 'Lorenz Park', value: 'Lorenz Park' },
  { label: 'Lorenzago Di Cadore', value: 'Lorenzago Di Cadore' },
  { label: 'Lorenzana', value: 'Lorenzana' },
  { label: 'Lorenzo', value: 'Lorenzo' },
  { label: 'Loreo', value: 'Loreo' },
  { label: 'Loreto', value: 'Loreto' },
  { label: 'Loreto', value: 'Loreto' },
  { label: 'Loreto', value: 'Loreto' },
  { label: 'Loreto', value: 'Loreto' },
  { label: 'Loreto', value: 'Loreto' },
  { label: 'Loreto', value: 'Loreto' },
  { label: 'Loreto', value: 'Loreto' },
  { label: 'Loreto', value: 'Loreto' },
  { label: 'Loreto Aprutino', value: 'Loreto Aprutino' },
  { label: 'Loreto Canton', value: 'Loreto Canton' },
  { label: 'Loreto Stazione', value: 'Loreto Stazione' },
  { label: 'Lorette', value: 'Lorette' },
  { label: 'Lorette', value: 'Lorette' },
  { label: 'Loretto', value: 'Loretto' },
  { label: 'Loretto', value: 'Loretto' },
  { label: 'Loretto', value: 'Loretto' },
  { label: 'Lorgies', value: 'Lorgies' },
  { label: 'Lorgues', value: 'Lorgues' },
  { label: 'Loria Bessica', value: 'Loria Bessica' },
  { label: 'Lorica', value: 'Lorica' },
  { label: 'Lorient', value: 'Lorient' },
  { label: 'Loriga', value: 'Loriga' },
  { label: 'Lorignal', value: 'Lorignal' },
  { label: 'Loriguilla', value: 'Loriguilla' },
  { label: 'Lőrinci', value: 'Lőrinci' },
  { label: 'Lorino', value: 'Lorino' },
  { label: 'Loriol-Du-Comtat', value: 'Loriol-Du-Comtat' },
  { label: 'Loriol-Sur-Drôme', value: 'Loriol-Sur-Drôme' },
  { label: 'Loris', value: 'Loris' },
  { label: 'Lormaison', value: 'Lormaison' },
  { label: 'Lormes', value: 'Lormes' },
  { label: 'Lormi', value: 'Lormi' },
  { label: 'Lormont', value: 'Lormont' },
  { label: 'Lorn', value: 'Lorn' },
  { label: 'Lorne', value: 'Lorne' },
  { label: 'Loro Ciuffenna', value: 'Loro Ciuffenna' },
  { label: 'Loro Piceno', value: 'Loro Piceno' },
  { label: 'Lorquí', value: 'Lorquí' },
  { label: 'Lorquin', value: 'Lorquin' },
  { label: 'Lörrach', value: 'Lörrach' },
  { label: 'Lorraine', value: 'Lorraine' },
  { label: 'Lorrez-Le-Bocage-Préaux', value: 'Lorrez-Le-Bocage-Préaux' },
  { label: 'Lorrimers', value: 'Lorrimers' },
  { label: 'Lorris', value: 'Lorris' },
  { label: 'Lorsch', value: 'Lorsch' },
  { label: 'Lorsica', value: 'Lorsica' },
  { label: 'Lorton', value: 'Lorton' },
  { label: 'Lorüns', value: 'Lorüns' },
  { label: 'Lorup', value: 'Lorup' },
  { label: 'Lorut', value: 'Lorut' },
  { label: 'Lorvão', value: 'Lorvão' },
  { label: 'Lörzweiler', value: 'Lörzweiler' },
  { label: 'Los Achotes', value: 'Los Achotes' },
  { label: 'Los Aguajes', value: 'Los Aguajes' },
  { label: 'Los Alamitos', value: 'Los Alamitos' },
  { label: 'Los Alamos', value: 'Los Alamos' },
  { label: 'Los Alamos', value: 'Los Alamos' },
  { label: 'Los Álamos', value: 'Los Álamos' },
  { label: 'Los Álamos', value: 'Los Álamos' },
  { label: 'Los Álamos', value: 'Los Álamos' },
  { label: 'Los Alamos County', value: 'Los Alamos County' },
  { label: 'Los Alcázares', value: 'Los Alcázares' },
  { label: 'Los Aldamas', value: 'Los Aldamas' },
  { label: 'Los Algarrobos', value: 'Los Algarrobos' },
  { label: 'Los Algarrobos', value: 'Los Algarrobos' },
  { label: 'Los Algodones', value: 'Los Algodones' },
  { label: 'Los Almendros', value: 'Los Almendros' },
  { label: 'Los Altos', value: 'Los Altos' },
  { label: 'Los Altos', value: 'Los Altos' },
  { label: 'Los Altos', value: 'Los Altos' },
  { label: 'Los Altos Hills', value: 'Los Altos Hills' },
  { label: 'Los Amates', value: 'Los Amates' },
  { label: 'Los Anastacios', value: 'Los Anastacios' },
  { label: 'Los Andes', value: 'Los Andes' },
  { label: 'Los Andes', value: 'Los Andes' },
  { label: 'Los Angeles', value: 'Los Angeles' },
  { label: 'Los Angeles', value: 'Los Angeles' },
  { label: 'Los Angeles', value: 'Los Angeles' },
  { label: 'Los Angeles', value: 'Los Angeles' },
  { label: 'Los Angeles', value: 'Los Angeles' },
  { label: 'Los Ángeles', value: 'Los Ángeles' },
  { label: 'Los Ángeles', value: 'Los Ángeles' },
  { label: 'Los Ángeles', value: 'Los Ángeles' },
  { label: 'Los Ángeles', value: 'Los Ángeles' },
  { label: 'Los Ángeles', value: 'Los Ángeles' },
  { label: 'Los Angeles County', value: 'Los Angeles County' },
  { label: 'Los Ángeles Nuevo León', value: 'Los Ángeles Nuevo León' },
  { label: 'Los Ángeles Tetela', value: 'Los Ángeles Tetela' },
  { label: 'Los Antiguos', value: 'Los Antiguos' },
  { label: 'Los Aquijes', value: 'Los Aquijes' },
  { label: 'Los Arabos', value: 'Los Arabos' },
  { label: 'Los Arana', value: 'Los Arana' },
  { label: 'Los Arcos', value: 'Los Arcos' },
  { label: 'Los Arcos', value: 'Los Arcos' },
  { label: 'Los Arcos', value: 'Los Arcos' },
  { label: 'Los Arcos', value: 'Los Arcos' },
  { label: 'Los Arcos', value: 'Los Arcos' },
  { label: 'Los Arquitos', value: 'Los Arquitos' },
  { label: 'Los Asientos', value: 'Los Asientos' },
  { label: 'Los Aztecas', value: 'Los Aztecas' },
  { label: 'Los Azulitos', value: 'Los Azulitos' },
  { label: 'Los Bahuises', value: 'Los Bahuises' },
  { label: 'Los Banos', value: 'Los Banos' },
  { label: 'Los Baños', value: 'Los Baños' },
  { label: 'Los Baños', value: 'Los Baños' },
  { label: 'Los Barriles', value: 'Los Barriles' },
  { label: 'Los Benitos', value: 'Los Benitos' },
  { label: 'Los Berros', value: 'Los Berros' },
  { label: 'Los Botados', value: 'Los Botados' },
  { label: 'Los Cabos', value: 'Los Cabos' },
  { label: 'Los Cafetales', value: 'Los Cafetales' },
  { label: 'Los Cajones', value: 'Los Cajones' },
  { label: 'Los Caminos', value: 'Los Caminos' },
  { label: 'Los Canelos', value: 'Los Canelos' },
  { label: 'Los Caños', value: 'Los Caños' },
  { label: 'Los Castillos', value: 'Los Castillos' },
  { label: 'Los Castillos', value: 'Los Castillos' },
  { label: 'Los Cavazos', value: 'Los Cavazos' },
  { label: 'Los Cedros', value: 'Los Cedros' },
  { label: 'Los Cenotes', value: 'Los Cenotes' },
  { label: 'Los Cerrillos', value: 'Los Cerrillos' },
  { label: 'Los Cerritos', value: 'Los Cerritos' },
  { label: 'Los Cerritos', value: 'Los Cerritos' },
  { label: 'Los Cerritos', value: 'Los Cerritos' },
  { label: 'Los Cerros De Paja', value: 'Los Cerros De Paja' },
  { label: 'Los Charcos', value: 'Los Charcos' },
  { label: 'Los Charrúas', value: 'Los Charrúas' },
  { label: 'Los Chavez', value: 'Los Chavez' },
  { label: 'Los Chiles', value: 'Los Chiles' },
  { label: 'Los Cides', value: 'Los Cides' },
  { label: 'Los Cocos', value: 'Los Cocos' },
  { label: 'Los Condes', value: 'Los Condes' },
  { label: 'Los Cóndores', value: 'Los Cóndores' },
  { label: 'Los Conos', value: 'Los Conos' },
  { label: 'Los Conquistadores', value: 'Los Conquistadores' },
  { label: 'Los Corazones', value: 'Los Corazones' },
  { label: 'Los Córdobas', value: 'Los Córdobas' },
  { label: 'Los Corrales De Buelna', value: 'Los Corrales De Buelna' },
  { label: 'Los Cristales (Talican)', value: 'Los Cristales (Talican)' },
  { label: 'Los Cues', value: 'Los Cues' },
  { label: 'Los Desmontes', value: 'Los Desmontes' },
  { label: 'Los Divorciados', value: 'Los Divorciados' },
  { label: 'Los Dolores (Las Quince Letras)', value: 'Los Dolores (Las Quince Letras)' },
  { label: 'Los Dos Caminos', value: 'Los Dos Caminos' },
  { label: 'Los Dos Ríos', value: 'Los Dos Ríos' },
  { label: 'Los Dulces Nombres', value: 'Los Dulces Nombres' },
  { label: 'Los Encinos', value: 'Los Encinos' },
  { label: 'Los Fierros', value: 'Los Fierros' },
  { label: 'Los Frentones', value: 'Los Frentones' },
  { label: 'Los Fresnos', value: 'Los Fresnos' },
  { label: 'Los Gabatos', value: 'Los Gabatos' },
  { label: 'Los Galvan', value: 'Los Galvan' },
  { label: 'Los Garcías', value: 'Los Garcías' },
  { label: 'Los Gatos', value: 'Los Gatos' },
  { label: 'Los Gavilanes', value: 'Los Gavilanes' },
  { label: 'Los Gigantes', value: 'Los Gigantes' },
  { label: 'Los Guajes', value: 'Los Guajes' },
  { label: 'Los Guayos', value: 'Los Guayos' },
  { label: 'Los Guerra', value: 'Los Guerra' },
  { label: 'Los Guerrero', value: 'Los Guerrero' },
  { label: 'Los Helechos', value: 'Los Helechos' },
  { label: 'Los Herreras', value: 'Los Herreras' },
  { label: 'Los Hidalgos', value: 'Los Hidalgos' },
  { label: 'Los Higos', value: 'Los Higos' },
  { label: 'Los Hoyos', value: 'Los Hoyos' },
  { label: 'Los Hucuares', value: 'Los Hucuares' },
  { label: 'Los Ídolos', value: 'Los Ídolos' },
  { label: 'Los Indios', value: 'Los Indios' },
  { label: 'Los Juríes', value: 'Los Juríes' },
  { label: 'Los Lagos', value: 'Los Lagos' },
  { label: 'Los Laureles', value: 'Los Laureles' },
  { label: 'Los Laureles', value: 'Los Laureles' },
  { label: 'Los Limones', value: 'Los Limones' },
  { label: 'Los Lirios', value: 'Los Lirios' },
  { label: 'Los Liros', value: 'Los Liros' },
  { label: 'Los Llanitos', value: 'Los Llanitos' },
  { label: 'Los Llanitos', value: 'Los Llanitos' },
  { label: 'Los Llanos', value: 'Los Llanos' },
  { label: 'Los Llanos', value: 'Los Llanos' },
  { label: 'Los Llanos De Aridane', value: 'Los Llanos De Aridane' },
  { label: 'Los Lorenzos', value: 'Los Lorenzos' },
  { label: 'Los Lotes', value: 'Los Lotes' },
  { label: 'Los Lunas', value: 'Los Lunas' },
  { label: 'Los Mancera', value: 'Los Mancera' },
  { label: 'Los Mangos', value: 'Los Mangos' },
  { label: 'Los Martínez', value: 'Los Martínez' },
  { label: 'Los Mazos', value: 'Los Mazos' },
  { label: 'Los Medranos', value: 'Los Medranos' },
  { label: 'Los Menucos', value: 'Los Menucos' },
  { label: 'Los Mochis', value: 'Los Mochis' },
  { label: 'Los Mogotes', value: 'Los Mogotes' },
  { label: 'Los Molinos', value: 'Los Molinos' },
  { label: 'Los Molinos', value: 'Los Molinos' },
  { label: 'Los Molinos', value: 'Los Molinos' },
  { label: 'Los Montesinos', value: 'Los Montesinos' },
  { label: 'Los Muermos', value: 'Los Muermos' },
  { label: 'Los Naranjos', value: 'Los Naranjos' },
  { label: 'Los Naranjos', value: 'Los Naranjos' },
  { label: 'Los Naranjos', value: 'Los Naranjos' },
  { label: 'Los Naranjos', value: 'Los Naranjos' },
  { label: 'Los Naranjos Esquipulas', value: 'Los Naranjos Esquipulas' },
  { label: 'Los Negros', value: 'Los Negros' },
  { label: 'Los Nicolases', value: 'Los Nicolases' },
  { label: 'Los Nogales', value: 'Los Nogales' },
  { label: 'Los Ocotes', value: 'Los Ocotes' },
  { label: 'Los Olivos', value: 'Los Olivos' },
  { label: 'Los Olivos', value: 'Los Olivos' },
  { label: 'Los Olivos', value: 'Los Olivos' },
  { label: 'Los Olvera', value: 'Los Olvera' },
  { label: 'Los Órganos De San Agustín', value: 'Los Órganos De San Agustín' },
  { label: 'Los Osos', value: 'Los Osos' },
  { label: 'Los Otates', value: 'Los Otates' },
  { label: 'Los Palacios', value: 'Los Palacios' },
  { label: 'Los Palacios', value: 'Los Palacios' },
  { label: 'Los Palmitos', value: 'Los Palmitos' },
  { label: 'Los Parques', value: 'Los Parques' },
  { label: 'Los Patios', value: 'Los Patios' },
  { label: 'Los Pescados', value: 'Los Pescados' },
  { label: 'Los Pilares', value: 'Los Pilares' },
  { label: 'Los Pilares', value: 'Los Pilares' },
  { label: 'Los Pinos', value: 'Los Pinos' },
  { label: 'Los Pinos', value: 'Los Pinos' },
  { label: 'Los Planes', value: 'Los Planes' },
  { label: 'Los Plátanos', value: 'Los Plátanos' },
  { label: 'Los Pocitos', value: 'Los Pocitos' },
  { label: 'Los Pocitos', value: 'Los Pocitos' },
  { label: 'Los Pocitos', value: 'Los Pocitos' },
  { label: 'Los Pollos', value: 'Los Pollos' },
  { label: 'Los Pozos', value: 'Los Pozos' },
  { label: 'Los Pozos', value: 'Los Pozos' },
  { label: 'Los Pozos', value: 'Los Pozos' },
  { label: 'Los Prietos', value: 'Los Prietos' },
  { label: 'Los Ramírez', value: 'Los Ramírez' },
  { label: 'Los Ramírez', value: 'Los Ramírez' },
  { label: 'Los Ramones', value: 'Los Ramones' },
  { label: 'Los Ranchos De Albuquerque', value: 'Los Ranchos De Albuquerque' },
  { label: 'Los Rastrojos', value: 'Los Rastrojos' },
  { label: 'Los Realejos', value: 'Los Realejos' },
  { label: 'Los Remedios', value: 'Los Remedios' },
  { label: 'Los Remedios', value: 'Los Remedios' },
  { label: 'Los Reyes', value: 'Los Reyes' },
  { label: 'Los Reyes', value: 'Los Reyes' },
  { label: 'Los Reyes', value: 'Los Reyes' },
  { label: 'Los Reyes Acaquilpan', value: 'Los Reyes Acaquilpan' },
  { label: 'Los Reyes Acatlixhuayán', value: 'Los Reyes Acatlixhuayán' },
  { label: 'Los Reyes De Juárez', value: 'Los Reyes De Juárez' },
  { label: 'Los Reyes De Salgado', value: 'Los Reyes De Salgado' },
  { label: 'Los Reyes Quiahuixtlan', value: 'Los Reyes Quiahuixtlan' },
  { label: 'Los Reyes Tlanechicolpan', value: 'Los Reyes Tlanechicolpan' },
  { label: 'Los Riegos', value: 'Los Riegos' },
  { label: 'Los Ríos', value: 'Los Ríos' },
  { label: 'Los Robles', value: 'Los Robles' },
  { label: 'Los Rodríguez', value: 'Los Rodríguez' },
  { label: 'Los Romeros', value: 'Los Romeros' },
  { label: 'Los Ruiseñores', value: 'Los Ruiseñores' },
  { label: 'Los Sabinos', value: 'Los Sabinos' },
  { label: 'Los Santos', value: 'Los Santos' },
  { label: 'Los Santos', value: 'Los Santos' },
  { label: 'Los Santos De La Humosa', value: 'Los Santos De La Humosa' },
  { label: 'Los Sauces', value: 'Los Sauces' },
  { label: 'Los Sauces', value: 'Los Sauces' },
  { label: 'Los Sauces', value: 'Los Sauces' },
  { label: 'Los Sauces', value: 'Los Sauces' },
  { label: 'Los Saucitos', value: 'Los Saucitos' },
  { label: 'Los Saucos', value: 'Los Saucos' },
  { label: 'Los Serranos', value: 'Los Serranos' },
  { label: 'Los Silos', value: 'Los Silos' },
  { label: 'Los Surgentes', value: 'Los Surgentes' },
  { label: 'Los Tangos', value: 'Los Tangos' },
  { label: 'Los Telares', value: 'Los Telares' },
  { label: 'Los Tepames', value: 'Los Tepames' },
  { label: 'Los Tepetates', value: 'Los Tepetates' },
  { label: 'Los Teques', value: 'Los Teques' },
  { label: 'Los Tigres (San Marcos)', value: 'Los Tigres (San Marcos)' },
  { label: 'Los Torrentes', value: 'Los Torrentes' },
  { label: 'Los Tuzos', value: 'Los Tuzos' },
  { label: 'Los Valles', value: 'Los Valles' },
  { label: 'Los Varela', value: 'Los Varela' },
  { label: 'Los Vergara', value: 'Los Vergara' },
  { label: 'Los Vilos', value: 'Los Vilos' },
  { label: 'Los Zacatones', value: 'Los Zacatones' },
  { label: 'Losa Del Obispo', value: 'Losa Del Obispo' },
  { label: 'Losa La', value: 'Losa La' },
  { label: 'Losacino', value: 'Losacino' },
  { label: 'Losacio', value: 'Losacio' },
  { label: 'Losal', value: 'Losal' },
  { label: 'Losap Municipality', value: 'Losap Municipality' },
  { label: 'Losar De La Vera', value: 'Losar De La Vera' },
  { label: 'Losar Del Barco El', value: 'Losar Del Barco El' },
  { label: 'Loscorrales', value: 'Loscorrales' },
  { label: 'Loscos', value: 'Loscos' },
  { label: 'Losenstein', value: 'Losenstein' },
  { label: 'Loseto', value: 'Loseto' },
  { label: 'Losevo', value: 'Losevo' },
  { label: 'Losheim', value: 'Losheim' },
  { label: 'Loshnitsa', value: 'Loshnitsa' },
  { label: 'Łosice', value: 'Łosice' },
  { label: 'Losilla La', value: 'Losilla La' },
  { label: 'Losine', value: 'Losine' },
  { label: 'Losino-Petrovskiy', value: 'Losino-Petrovskiy' },
  { label: 'Losinyy', value: 'Losinyy' },
  { label: 'Łosiów', value: 'Łosiów' },
  { label: 'Losne', value: 'Losne' },
  { label: 'Losone', value: 'Losone' },
  { label: 'Lososina', value: 'Lososina' },
  { label: 'Łososina Dolna', value: 'Łososina Dolna' },
  { label: 'Lospalos', value: 'Lospalos' },
  { label: 'Loßburg', value: 'Loßburg' },
  { label: 'Losser', value: 'Losser' },
  { label: 'Lossiemouth', value: 'Lossiemouth' },
  { label: 'Lößnitz', value: 'Lößnitz' },
  { label: 'Lost Creek', value: 'Lost Creek' },
  { label: 'Lost Hills', value: 'Lost Hills' },
  { label: 'Lostau', value: 'Lostau' },
  { label: 'Loštice', value: 'Loštice' },
  { label: 'Lostorf', value: 'Lostorf' },
  { label: 'Lostwithiel', value: 'Lostwithiel' },
  { label: 'Lot', value: 'Lot' },
  { label: 'Lota', value: 'Lota' },
  { label: 'Lota', value: 'Lota' },
  { label: 'Løten', value: 'Løten' },
  { label: 'Lotfabad', value: 'Lotfabad' },
  { label: 'Lotofagā', value: 'Lotofagā' },
  { label: 'Lotoshino', value: 'Lotoshino' },
  { label: 'Lotoshinskiy Rayon', value: 'Lotoshinskiy Rayon' },
  { label: 'Lotte', value: 'Lotte' },
  { label: 'Lottery', value: 'Lottery' },
  { label: 'Lottstetten', value: 'Lottstetten' },
  { label: 'Lottum', value: 'Lottum' },
  { label: 'Loțu', value: 'Loțu' },
  { label: 'Lotzorai', value: 'Lotzorai' },
  { label: 'Lotzwil', value: 'Lotzwil' },
  { label: 'Louannec', value: 'Louannec' },
  { label: 'Louargat', value: 'Louargat' },
  { label: 'Loubert', value: 'Loubert' },
  { label: 'Loučeň', value: 'Loučeň' },
  { label: 'Loučná Nad Desnou', value: 'Loučná Nad Desnou' },
  { label: 'Loučovice', value: 'Loučovice' },
  { label: 'Loudéac', value: 'Loudéac' },
  { label: 'Loudi', value: 'Loudi' },
  { label: 'Loudon', value: 'Loudon' },
  { label: 'Loudon County', value: 'Loudon County' },
  { label: 'Loudonville', value: 'Loudonville' },
  { label: 'Loudoun County', value: 'Loudoun County' },
  { label: 'Loudoun Valley Estates', value: 'Loudoun Valley Estates' },
  { label: 'Loudun', value: 'Loudun' },
  { label: 'Loué', value: 'Loué' },
  { label: 'Louga', value: 'Louga' },
  { label: 'Loughborough', value: 'Loughborough' },
  { label: 'Loughlinstown', value: 'Loughlinstown' },
  { label: 'Loughman', value: 'Loughman' },
  { label: 'Loughrea', value: 'Loughrea' },
  { label: 'Louhans', value: 'Louhans' },
  { label: 'Louis Trichardt', value: 'Louis Trichardt' },
  { label: 'Louisa', value: 'Louisa' },
  { label: 'Louisa', value: 'Louisa' },
  { label: 'Louisa County', value: 'Louisa County' },
  { label: 'Louisa County', value: 'Louisa County' },
  { label: 'Louisburg', value: 'Louisburg' },
  { label: 'Louisburg', value: 'Louisburg' },
  { label: 'Louiseville', value: 'Louiseville' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Louisville', value: 'Louisville' },
  { label: 'Louisville', value: 'Louisville' },
  { label: 'Louisville', value: 'Louisville' },
  { label: 'Louisville', value: 'Louisville' },
  { label: 'Louisville', value: 'Louisville' },
  { label: 'Louisville', value: 'Louisville' },
  { label: 'Louisville', value: 'Louisville' },
  { label: 'Louisville', value: 'Louisville' },
  { label: 'Louka', value: 'Louka' },
  { label: 'Loukhi', value: 'Loukhi' },
  { label: 'Loukísia', value: 'Loukísia' },
  { label: 'Loulé', value: 'Loulé' },
  { label: 'Louleman', value: 'Louleman' },
  { label: 'Loum', value: 'Loum' },
  { label: 'Loumar', value: 'Loumar' },
  { label: 'Louny', value: 'Louny' },
  { label: 'Loup City', value: 'Loup City' },
  { label: 'Loup County', value: 'Loup County' },
  { label: 'Loupiac', value: 'Loupiac' },
  { label: 'Loupian', value: 'Loupian' },
  { label: 'Louplande', value: 'Louplande' },
  { label: 'Lourches', value: 'Lourches' },
  { label: 'Lourdes', value: 'Lourdes' },
  { label: 'Lourdes', value: 'Lourdes' },
  { label: 'Lourdes', value: 'Lourdes' },
  { label: 'Lourdes', value: 'Lourdes' },
  { label: 'Lourdes', value: 'Lourdes' },
  { label: 'Lourdes', value: 'Lourdes' },
  { label: 'Lourdes', value: 'Lourdes' },
  { label: 'Lourdes', value: 'Lourdes' },
  { label: 'Lourenzá', value: 'Lourenzá' },
  { label: 'Loures', value: 'Loures' },
  { label: 'Loures', value: 'Loures' },
  { label: 'Louriçal', value: 'Louriçal' },
  { label: 'Lourinhã', value: 'Lourinhã' },
  { label: 'Lourinhã', value: 'Lourinhã' },
  { label: 'Lourmarin', value: 'Lourmarin' },
  { label: 'Loúros', value: 'Loúros' },
  { label: 'Lourosa', value: 'Lourosa' },
  { label: 'Loury', value: 'Loury' },
  { label: 'Lousa', value: 'Lousa' },
  { label: 'Lousã', value: 'Lousã' },
  { label: 'Lousada', value: 'Lousada' },
  { label: 'Lousame', value: 'Lousame' },
  { label: 'Loushanguan', value: 'Loushanguan' },
  { label: 'Louth', value: 'Louth' },
  { label: 'Loutrá Aidhipsoú', value: 'Loutrá Aidhipsoú' },
  { label: 'Loutrá Oraías Elénis', value: 'Loutrá Oraías Elénis' },
  { label: 'Loutráki', value: 'Loutráki' },
  { label: 'Loutráki', value: 'Loutráki' },
  { label: 'Loutrós', value: 'Loutrós' },
  { label: 'Louvain-La-Neuve', value: 'Louvain-La-Neuve' },
  { label: 'Louveciennes', value: 'Louveciennes' },
  { label: 'Louveira', value: 'Louveira' },
  { label: 'Louverné', value: 'Louverné' },
  { label: 'Louvie-Juzon', value: 'Louvie-Juzon' },
  { label: 'Louviers', value: 'Louviers' },
  { label: 'Louvigné-De-Bais', value: 'Louvigné-De-Bais' },
  { label: 'Louvigné-Du-Désert', value: 'Louvigné-Du-Désert' },
  { label: 'Louvigny', value: 'Louvigny' },
  { label: 'Louvres', value: 'Louvres' },
  { label: 'Louvroil', value: 'Louvroil' },
  { label: 'Louzy', value: 'Louzy' },
  { label: 'Lovagny', value: 'Lovagny' },
  { label: 'Lovas', value: 'Lovas' },
  { label: 'Lovasberény', value: 'Lovasberény' },
  { label: 'Love County', value: 'Love County' },
  { label: 'Lovech', value: 'Lovech' },
  { label: 'Lovejoy', value: 'Lovejoy' },
  { label: 'Loveland', value: 'Loveland' },
  { label: 'Loveland', value: 'Loveland' },
  { label: 'Loveland Park', value: 'Loveland Park' },
  { label: 'Lovell', value: 'Lovell' },
  { label: 'Lovell', value: 'Lovell' },
  { label: 'Lovelock', value: 'Lovelock' },
  { label: 'Lovely Banks', value: 'Lovely Banks' },
  { label: 'Loven', value: 'Loven' },
  { label: 'Lovendegem', value: 'Lovendegem' },
  { label: 'Lovere', value: 'Lovere' },
  { label: 'Lovero', value: 'Lovero' },
  { label: 'Loves Park', value: 'Loves Park' },
  { label: 'Lovettsville', value: 'Lovettsville' },
  { label: 'Lövgärdet', value: 'Lövgärdet' },
  { label: 'Lovina', value: 'Lovina' },
  { label: 'Loving', value: 'Loving' },
  { label: 'Loving County', value: 'Loving County' },
  { label: 'Lovingston', value: 'Lovingston' },
  { label: 'Lovington', value: 'Lovington' },
  { label: 'Lovington', value: 'Lovington' },
  { label: 'Lovisa', value: 'Lovisa' },
  { label: 'Lovlinskaya', value: 'Lovlinskaya' },
  { label: 'Lovosice', value: 'Lovosice' },
  { label: 'Lovozero', value: 'Lovozero' },
  { label: 'Lovozerskiy Rayon', value: 'Lovozerskiy Rayon' },
  { label: 'Lovran', value: 'Lovran' },
  { label: 'Lovreć', value: 'Lovreć' },
  { label: 'Lovrenc Na Pohorju', value: 'Lovrenc Na Pohorju' },
  { label: 'Lovrin', value: 'Lovrin' },
  { label: 'Lövstalöt', value: 'Lövstalöt' },
  { label: 'Low Ackworth', value: 'Low Ackworth' },
  { label: 'Low Bradley', value: 'Low Bradley' },
  { label: 'Low Etherley', value: 'Low Etherley' },
  { label: 'Lowdham', value: 'Lowdham' },
  { label: 'Lowell', value: 'Lowell' },
  { label: 'Lowell', value: 'Lowell' },
  { label: 'Lowell', value: 'Lowell' },
  { label: 'Lowell', value: 'Lowell' },
  { label: 'Lowell', value: 'Lowell' },
  { label: 'Lowell', value: 'Lowell' },
  { label: 'Lowellville', value: 'Lowellville' },
  { label: 'Löwenstein', value: 'Löwenstein' },
  { label: 'Lower Allen', value: 'Lower Allen' },
  { label: 'Lower Baddibu District', value: 'Lower Baddibu District' },
  { label: 'Lower Bavaria', value: 'Lower Bavaria' },
  { label: 'Lower Beechmont', value: 'Lower Beechmont' },
  { label: 'Lower Brailes', value: 'Lower Brailes' },
  { label: 'Lower Broadheath', value: 'Lower Broadheath' },
  { label: 'Lower Burrell', value: 'Lower Burrell' },
  { label: 'Lower Chittering', value: 'Lower Chittering' },
  { label: 'Lower Dibang Valley', value: 'Lower Dibang Valley' },
  { label: 'Lower Earley', value: 'Lower Earley' },
  { label: 'Lower Eyre Peninsula', value: 'Lower Eyre Peninsula' },
  { label: 'Lower Grand Lagoon', value: 'Lower Grand Lagoon' },
  { label: 'Lower Halstow', value: 'Lower Halstow' },
  { label: 'Lower Hutt', value: 'Lower Hutt' },
  { label: 'Lower King', value: 'Lower King' },
  { label: 'Lower Kingswood', value: 'Lower Kingswood' },
  { label: 'Lower Lake', value: 'Lower Lake' },
  { label: 'Lower Mitcham', value: 'Lower Mitcham' },
  { label: 'Lower Niumi District', value: 'Lower Niumi District' },
  { label: 'Lower Plenty', value: 'Lower Plenty' },
  { label: 'Lower Sackville', value: 'Lower Sackville' },
  { label: 'Lower Saloum', value: 'Lower Saloum' },
  { label: 'Lower Subansiri', value: 'Lower Subansiri' },
  { label: 'Lower West Side', value: 'Lower West Side' },
  { label: 'Lowes Island', value: 'Lowes Island' },
  { label: 'Lowestoft', value: 'Lowestoft' },
  { label: 'Lowesville', value: 'Lowesville' },
  { label: 'Lowick', value: 'Lowick' },
  { label: 'Łowicz', value: 'Łowicz' },
  { label: 'Lowndes County', value: 'Lowndes County' },
  { label: 'Lowndes County', value: 'Lowndes County' },
  { label: 'Lowndes County', value: 'Lowndes County' },
  { label: 'Lowood', value: 'Lowood' },
  { label: 'Lowry Crossing', value: 'Lowry Crossing' },
  { label: 'Lowshan', value: 'Lowshan' },
  { label: 'Lowville', value: 'Lowville' },
  { label: 'Loxahatchee Groves', value: 'Loxahatchee Groves' },
  { label: 'Loxley', value: 'Loxley' },
  { label: 'Loxstedt', value: 'Loxstedt' },
  { label: 'Loxton', value: 'Loxton' },
  { label: 'Loxton Waikerie', value: 'Loxton Waikerie' },
  { label: 'Loyabad', value: 'Loyabad' },
  { label: 'Loyada', value: 'Loyada' },
  { label: 'Loyal', value: 'Loyal' },
  { label: 'Loyalhanna', value: 'Loyalhanna' },
  { label: 'Loyat', value: 'Loyat' },
  { label: 'Loyettes', value: 'Loyettes' },
  { label: 'Loyew', value: 'Loyew' },
  { label: 'Loyga', value: 'Loyga' },
  { label: 'Loyola', value: 'Loyola' },
  { label: 'Loyola', value: 'Loyola' },
  { label: 'Loyola', value: 'Loyola' },
  { label: 'Loza De Barrera', value: 'Loza De Barrera' },
  { label: 'Loza De Los Padres', value: 'Loza De Los Padres' },
  { label: 'Lozanne', value: 'Lozanne' },
  { label: 'Lozhki', value: 'Lozhki' },
  { label: 'Lozinghem', value: 'Lozinghem' },
  { label: 'Lozio', value: 'Lozio' },
  { label: 'Lozna', value: 'Lozna' },
  { label: 'Lozna', value: 'Lozna' },
  { label: 'Loznitsa', value: 'Loznitsa' },
  { label: 'Lozno-Oleksandrivka', value: 'Lozno-Oleksandrivka' },
  { label: 'Lozova', value: 'Lozova' },
  { label: 'Lozovo', value: 'Lozovo' },
  { label: 'Lozovoye', value: 'Lozovoye' },
  { label: 'Lozoya', value: 'Lozoya' },
  { label: 'Lozoyuela-Navas-Sieteiglesias', value: 'Lozoyuela-Navas-Sieteiglesias' },
  { label: 'Lozuvatka', value: 'Lozuvatka' },
  { label: 'Lozza', value: 'Lozza' },
  { label: 'Lozzo Atestino', value: 'Lozzo Atestino' },
  { label: 'Lozzo Di Cadore', value: 'Lozzo Di Cadore' },
  { label: 'Lozzolo', value: 'Lozzolo' },
  { label: 'Lu', value: 'Lu' },
  { label: 'Lú', value: 'Lú' },
  { label: 'Lu Bagnu', value: 'Lu Bagnu' },
  { label: 'Lu’An', value: 'Lu’An' },
  { label: 'Luancheng', value: 'Luancheng' },
  { label: 'Luanda', value: 'Luanda' },
  { label: 'Luanda', value: 'Luanda' },
  { label: 'Luang Namtha', value: 'Luang Namtha' },
  { label: 'Luang Prabang', value: 'Luang Prabang' },
  { label: 'Luangwa', value: 'Luangwa' },
  { label: 'Luanshya', value: 'Luanshya' },
  { label: 'Luant', value: 'Luant' },
  { label: 'Luau', value: 'Luau' },
  { label: 'Luba', value: 'Luba' },
  { label: 'Luba', value: 'Luba' },
  { label: 'Luba', value: 'Luba' },
  { label: 'Lubaczów', value: 'Lubaczów' },
  { label: 'Lubań', value: 'Lubań' },
  { label: 'Lubāna', value: 'Lubāna' },
  { label: 'Lubang', value: 'Lubang' },
  { label: 'Lubango', value: 'Lubango' },
  { label: 'Lubanie', value: 'Lubanie' },
  { label: 'Lubao', value: 'Lubao' },
  { label: 'Lubao', value: 'Lubao' },
  { label: 'Lubao', value: 'Lubao' },
  { label: 'Lübars', value: 'Lübars' },
  { label: 'Lubartów', value: 'Lubartów' },
  { label: 'Lubasz', value: 'Lubasz' },
  { label: 'Lubawa', value: 'Lubawa' },
  { label: 'Lubawka', value: 'Lubawka' },
  { label: 'Lübbecke', value: 'Lübbecke' },
  { label: 'Lubbeek', value: 'Lubbeek' },
  { label: 'Lübben', value: 'Lübben' },
  { label: 'Lübbenau', value: 'Lübbenau' },
  { label: 'Lubbock', value: 'Lubbock' },
  { label: 'Lubbock County', value: 'Lubbock County' },
  { label: 'Lubeck', value: 'Lubeck' },
  { label: 'Lübeck', value: 'Lübeck' },
  { label: 'Lubenec', value: 'Lubenec' },
  { label: 'Lubenia', value: 'Lubenia' },
  { label: 'Lubersac', value: 'Lubersac' },
  { label: 'Lubi', value: 'Lubi' },
  { label: 'Lubián', value: 'Lubián' },
  { label: 'Łubianka', value: 'Łubianka' },
  { label: 'Ľubica', value: 'Ľubica' },
  { label: 'Lubichowo', value: 'Lubichowo' },
  { label: 'Lubicz Dolny', value: 'Lubicz Dolny' },
  { label: 'Lubicz Górny', value: 'Lubicz Górny' },
  { label: 'Lubień', value: 'Lubień' },
  { label: 'Lubień Kujawski', value: 'Lubień Kujawski' },
  { label: 'Lubiewo', value: 'Lubiewo' },
  { label: 'Lubigan', value: 'Lubigan' },
  { label: 'Lubin', value: 'Lubin' },
  { label: 'Lubiszyn', value: 'Lubiszyn' },
  { label: 'Lublewo Gdańskie', value: 'Lublewo Gdańskie' },
  { label: 'Lublin', value: 'Lublin' },
  { label: 'Lubliniec', value: 'Lubliniec' },
  { label: 'Lubmin', value: 'Lubmin' },
  { label: 'Łubniany', value: 'Łubniany' },
  { label: 'Łubnice', value: 'Łubnice' },
  { label: 'Łubnice', value: 'Łubnice' },
  { label: 'Lubniewice', value: 'Lubniewice' },
  { label: 'Lubny', value: 'Lubny' },
  { label: 'Lubochnia', value: 'Lubochnia' },
  { label: 'Lubomia', value: 'Lubomia' },
  { label: 'Lubomierz', value: 'Lubomierz' },
  { label: 'Lubomierz', value: 'Lubomierz' },
  { label: 'Lubomino', value: 'Lubomino' },
  { label: 'Luboń', value: 'Luboń' },
  { label: 'Lübow', value: 'Lübow' },
  { label: 'Lubowidz', value: 'Lubowidz' },
  { label: 'Łubowo', value: 'Łubowo' },
  { label: 'Łubowo', value: 'Łubowo' },
  { label: 'Lubraniec', value: 'Lubraniec' },
  { label: 'Lubriano', value: 'Lubriano' },
  { label: 'Lubrín', value: 'Lubrín' },
  { label: 'Lubrza', value: 'Lubrza' },
  { label: 'Lubrza', value: 'Lubrza' },
  { label: 'Lubsko', value: 'Lubsko' },
  { label: 'Lübstorf', value: 'Lübstorf' },
  { label: 'Lubsza', value: 'Lubsza' },
  { label: 'Lübtheen', value: 'Lübtheen' },
  { label: 'Lubu', value: 'Lubu' },
  { label: 'Lubuagan', value: 'Lubuagan' },
  { label: 'Lubuagan', value: 'Lubuagan' },
  { label: 'Lubuklinggau', value: 'Lubuklinggau' },
  { label: 'Lubumbashi', value: 'Lubumbashi' },
  { label: 'Luby', value: 'Luby' },
  { label: 'Lubyany', value: 'Lubyany' },
  { label: 'Lubycza Królewska', value: 'Lubycza Królewska' },
  { label: 'Lübz', value: 'Lübz' },
  { label: 'Lucăceni', value: 'Lucăceni' },
  { label: 'Lucăcești', value: 'Lucăcești' },
  { label: 'Lucainena De Las Torres', value: 'Lucainena De Las Torres' },
  { label: 'Lucama', value: 'Lucama' },
  { label: 'Lucan', value: 'Lucan' },
  { label: 'Lucan', value: 'Lucan' },
  { label: 'Lučany Nad Nisou', value: 'Lučany Nad Nisou' },
  { label: 'Lucap', value: 'Lucap' },
  { label: 'Lucap', value: 'Lucap' },
  { label: 'Lucapa', value: 'Lucapa' },
  { label: 'Lucapon', value: 'Lucapon' },
  { label: 'Lucapon', value: 'Lucapon' },
  { label: 'Lúcar', value: 'Lúcar' },
  { label: 'Lucas', value: 'Lucas' },
  { label: 'Lucas', value: 'Lucas' },
  { label: 'Lucas County', value: 'Lucas County' },
  { label: 'Lucas County', value: 'Lucas County' },
  { label: 'Lucas Do Rio Verde', value: 'Lucas Do Rio Verde' },
  { label: 'Lucas González', value: 'Lucas González' },
  { label: 'Lucas Valley-Marinwood', value: 'Lucas Valley-Marinwood' },
  { label: 'Lucasville', value: 'Lucasville' },
  { label: 'Lucaya', value: 'Lucaya' },
  { label: 'Luçay-Le-Mâle', value: 'Luçay-Le-Mâle' },
  { label: 'Lucban', value: 'Lucban' },
  { label: 'Lucban', value: 'Lucban' },
  { label: 'Lucban', value: 'Lucban' },
  { label: 'Lucca', value: 'Lucca' },
  { label: 'Lucca Sicula', value: 'Lucca Sicula' },
  { label: 'Lucciana', value: 'Lucciana' },
  { label: 'Lucé', value: 'Lucé' },
  { label: 'Luče', value: 'Luče' },
  { label: 'Luce County', value: 'Luce County' },
  { label: 'Lucea', value: 'Lucea' },
  { label: 'Lucea East', value: 'Lucea East' },
  { label: 'Lucea West', value: 'Lucea West' },
  { label: 'Luceau', value: 'Luceau' },
  { label: 'Lucedale', value: 'Lucedale' },
  { label: 'Lucélia', value: 'Lucélia' },
  { label: 'Lucena', value: 'Lucena' },
  { label: 'Lucena', value: 'Lucena' },
  { label: 'Lucena', value: 'Lucena' },
  { label: 'Lucena', value: 'Lucena' },
  { label: 'Lucena De Jalón', value: 'Lucena De Jalón' },
  { label: 'Lucena Del Cid', value: 'Lucena Del Cid' },
  { label: 'Lucena Del Puerto', value: 'Lucena Del Puerto' },
  { label: 'Lucenay', value: 'Lucenay' },
  { label: 'Lucenay-Lès-Aix', value: 'Lucenay-Lès-Aix' },
  { label: 'Lučenec', value: 'Lučenec' },
  { label: 'Luceni', value: 'Luceni' },
  { label: 'Lucens', value: 'Lucens' },
  { label: 'Lucera', value: 'Lucera' },
  { label: 'Lucéram', value: 'Lucéram' },
  { label: 'Lucerna', value: 'Lucerna' },
  { label: 'Lucerne', value: 'Lucerne' },
  { label: 'Lucerne Valley', value: 'Lucerne Valley' },
  { label: 'Lucerne-Land District', value: 'Lucerne-Land District' },
  { label: 'Lucerne-Stadt District', value: 'Lucerne-Stadt District' },
  { label: 'Lucero', value: 'Lucero' },
  { label: 'Lucero', value: 'Lucero' },
  { label: 'Lucero', value: 'Lucero' },
  { label: 'Luceville', value: 'Luceville' },
  { label: 'Luchanas', value: 'Luchanas' },
  { label: 'Luchegorsk', value: 'Luchegorsk' },
  { label: 'Lucheng', value: 'Lucheng' },
  { label: 'Luchenza', value: 'Luchenza' },
  { label: 'Luché-Pringé', value: 'Luché-Pringé' },
  { label: 'Luchingu', value: 'Luchingu' },
  { label: 'Luchistoye', value: 'Luchistoye' },
  { label: 'Luchki', value: 'Luchki' },
  { label: 'Lüchow', value: 'Lüchow' },
  { label: 'Luchsingen', value: 'Luchsingen' },
  { label: 'Luciana', value: 'Luciana' },
  { label: 'Lucianópolis', value: 'Lucianópolis' },
  { label: 'Luciara', value: 'Luciara' },
  { label: 'Lucieni', value: 'Lucieni' },
  { label: 'Lucignano', value: 'Lucignano' },
  { label: 'Lucija', value: 'Lucija' },
  { label: 'Lucillo', value: 'Lucillo' },
  { label: 'Lucillos', value: 'Lucillos' },
  { label: 'Lučina', value: 'Lučina' },
  { label: 'Lucinasco', value: 'Lucinasco' },
  { label: 'Lucinges', value: 'Lucinges' },
  { label: 'Lucino', value: 'Lucino' },
  { label: 'Lucino-Rodano', value: 'Lucino-Rodano' },
  { label: 'Lucio Blanco (Los Gavilanes)', value: 'Lucio Blanco (Los Gavilanes)' },
  { label: 'Lucito', value: 'Lucito' },
  { label: 'Luciu', value: 'Luciu' },
  { label: 'Luciu', value: 'Luciu' },
  { label: 'Luck', value: 'Luck' },
  { label: 'Lucka', value: 'Lucka' },
  { label: 'Luckau', value: 'Luckau' },
  { label: 'Luckeesarai', value: 'Luckeesarai' },
  { label: 'Luckenwalde', value: 'Luckenwalde' },
  { label: 'Luckey', value: 'Luckey' },
  { label: 'Luckington', value: 'Luckington' },
  { label: 'Lucknow', value: 'Lucknow' },
  { label: 'Lucknow', value: 'Lucknow' },
  { label: 'Lucknow District', value: 'Lucknow District' },
  { label: 'Lučko', value: 'Lučko' },
  { label: 'Lúčky', value: 'Lúčky' },
  { label: 'Lucky Hill', value: 'Lucky Hill' },
  { label: 'Luc-La-Primaube', value: 'Luc-La-Primaube' },
  { label: 'Luco Dei Marsi', value: 'Luco Dei Marsi' },
  { label: 'Luco Mugello', value: 'Luco Mugello' },
  { label: 'Lucoli', value: 'Lucoli' },
  { label: 'Luçon', value: 'Luçon' },
  { label: 'Lucq-De-Béarn', value: 'Lucq-De-Béarn' },
  { label: 'Lucrécia', value: 'Lucrécia' },
  { label: 'Lucrezia', value: 'Lucrezia' },
  { label: 'Lucsuhin', value: 'Lucsuhin' },
  { label: 'Luc-Sur-Mer', value: 'Luc-Sur-Mer' },
  { label: 'Lucugnano', value: 'Lucugnano' },
  { label: 'Ludași', value: 'Ludași' },
  { label: 'Ludbreg', value: 'Ludbreg' },
  { label: 'Luddenden Foot', value: 'Luddenden Foot' },
  { label: 'Luddenham', value: 'Luddenham' },
  { label: 'Lüdenscheid', value: 'Lüdenscheid' },
  { label: 'Lüder', value: 'Lüder' },
  { label: 'Lüderitz', value: 'Lüderitz' },
  { label: 'Lüderitz', value: 'Lüderitz' },
  { label: 'Lüdersdorf', value: 'Lüdersdorf' },
  { label: 'Lüdersfeld', value: 'Lüdersfeld' },
  { label: 'Ludesch', value: 'Ludesch' },
  { label: 'Ludeşti', value: 'Ludeşti' },
  { label: 'Ludgeřovice', value: 'Ludgeřovice' },
  { label: 'Ludgershall', value: 'Ludgershall' },
  { label: 'Ludhiana', value: 'Ludhiana' },
  { label: 'Ludiente', value: 'Ludiente' },
  { label: 'Lüdinghausen', value: 'Lüdinghausen' },
  { label: 'Ludington', value: 'Ludington' },
  { label: 'Ludlow', value: 'Ludlow' },
  { label: 'Ludlow', value: 'Ludlow' },
  { label: 'Ludlow', value: 'Ludlow' },
  { label: 'Ludmannsdorf', value: 'Ludmannsdorf' },
  { label: 'Ludmilla', value: 'Ludmilla' },
  { label: 'Ludon-Médoc', value: 'Ludon-Médoc' },
  { label: 'Ludoş', value: 'Ludoş' },
  { label: 'Ludowici', value: 'Ludowici' },
  { label: 'Ludres', value: 'Ludres' },
  { label: 'Ludriano', value: 'Ludriano' },
  { label: 'Luduș', value: 'Luduș' },
  { label: 'Ludvika', value: 'Ludvika' },
  { label: 'Ludweiler-Warndt', value: 'Ludweiler-Warndt' },
  { label: 'Ludwigsburg', value: 'Ludwigsburg' },
  { label: 'Ludwigsfelde', value: 'Ludwigsfelde' },
  { label: 'Ludwigshafen Am Rhein', value: 'Ludwigshafen Am Rhein' },
  { label: 'Ludwigslust', value: 'Ludwigslust' },
  { label: 'Ludwigsstadt', value: 'Ludwigsstadt' },
  { label: 'Ludwikowice Kłodzkie', value: 'Ludwikowice Kłodzkie' },
  { label: 'Ludwin', value: 'Ludwin' },
  { label: 'Ludza', value: 'Ludza' },
  { label: 'Ludźmierz', value: 'Ludźmierz' },
  { label: 'Luebo', value: 'Luebo' },
  { label: 'Luelmo', value: 'Luelmo' },
  { label: 'Luena', value: 'Luena' },
  { label: 'Luena', value: 'Luena' },
  { label: 'Luesia', value: 'Luesia' },
  { label: 'Luesma', value: 'Luesma' },
  { label: 'Lueta', value: 'Lueta' },
  { label: 'Lufa', value: 'Lufa' },
  { label: 'Lufilufi', value: 'Lufilufi' },
  { label: 'Lufkin', value: 'Lufkin' },
  { label: 'Luftenberg An Der Donau', value: 'Luftenberg An Der Donau' },
  { label: 'Luftkurort Arendsee', value: 'Luftkurort Arendsee' },
  { label: 'Luga', value: 'Luga' },
  { label: 'Lugagnano', value: 'Lugagnano' },
  { label: 'Lugagnano Val Darda', value: 'Lugagnano Val Darda' },
  { label: 'Lugait', value: 'Lugait' },
  { label: 'Lugano', value: 'Lugano' },
  { label: 'Lugano District', value: 'Lugano District' },
  { label: 'Luganskoye', value: 'Luganskoye' },
  { label: 'Lüganuse Vald', value: 'Lüganuse Vald' },
  { label: 'Luganville', value: 'Luganville' },
  { label: 'Lugarno', value: 'Lugarno' },
  { label: 'Lugaşu De Jos', value: 'Lugaşu De Jos' },
  { label: 'Lugau', value: 'Lugau' },
  { label: 'Lugavčina', value: 'Lugavčina' },
  { label: 'Lugazi', value: 'Lugazi' },
  { label: 'Lügde', value: 'Lügde' },
  { label: 'Lughignano', value: 'Lughignano' },
  { label: 'Lugnacco', value: 'Lugnacco' },
  { label: 'Lugnano In Teverina', value: 'Lugnano In Teverina' },
  { label: 'Lugo', value: 'Lugo' },
  { label: 'Lugo', value: 'Lugo' },
  { label: 'Lugo', value: 'Lugo' },
  { label: 'Lugo Di Vicenza', value: 'Lugo Di Vicenza' },
  { label: 'Lugoba', value: 'Lugoba' },
  { label: 'Lugoff', value: 'Lugoff' },
  { label: 'Lugoj', value: 'Lugoj' },
  { label: 'Lugon-Et-Lîle-Du-Carnay', value: 'Lugon-Et-Lîle-Du-Carnay' },
  { label: 'Lugovaya', value: 'Lugovaya' },
  { label: 'Lugovoy', value: 'Lugovoy' },
  { label: 'Lugovoye', value: 'Lugovoye' },
  { label: 'Lugovoye', value: 'Lugovoye' },
  { label: 'Lugovskoy', value: 'Lugovskoy' },
  { label: 'Lugrin', value: 'Lugrin' },
  { label: 'Lugros', value: 'Lugros' },
  { label: 'Lugu', value: 'Lugu' },
  { label: 'Lugugnana', value: 'Lugugnana' },
  { label: 'Lugui', value: 'Lugui' },
  { label: 'Lugulu', value: 'Lugulu' },
  { label: 'Lugus', value: 'Lugus' },
  { label: 'Lugus', value: 'Lugus' },
  { label: 'Lugwardine', value: 'Lugwardine' },
  { label: 'Luhačovice', value: 'Luhačovice' },
  { label: 'Luhanka', value: 'Luhanka' },
  { label: 'Luhans’Ka Mis’Krada', value: 'Luhans’Ka Mis’Krada' },
  { label: 'Luhans’Ke', value: 'Luhans’Ke' },
  { label: 'Luhansk', value: 'Luhansk' },
  { label: 'Luhavaya Slabada', value: 'Luhavaya Slabada' },
  { label: 'Luhden', value: 'Luhden' },
  { label: 'Luhe-Wildenau', value: 'Luhe-Wildenau' },
  { label: 'Luhyny', value: 'Luhyny' },
  { label: 'Luhyny', value: 'Luhyny' },
  { label: 'Luhyny Raion', value: 'Luhyny Raion' },
  { label: 'Luica', value: 'Luica' },
  { label: 'Luieriu', value: 'Luieriu' },
  { label: 'Luimneach', value: 'Luimneach' },
  { label: 'Luino', value: 'Luino' },
  { label: 'Luintra', value: 'Luintra' },
  { label: 'Luís Antônio', value: 'Luís Antônio' },
  { label: 'Luís Correia', value: 'Luís Correia' },
  { label: 'Luís Domingues', value: 'Luís Domingues' },
  { label: 'Luís Eduardo Magalhães', value: 'Luís Eduardo Magalhães' },
  { label: 'Luis Espinoza', value: 'Luis Espinoza' },
  { label: 'Luis Gil Pérez', value: 'Luis Gil Pérez' },
  { label: 'Luís Gomes', value: 'Luís Gomes' },
  { label: 'Luis Lloréns Torres', value: 'Luis Lloréns Torres' },
  { label: 'Luis M. Cintron', value: 'Luis M. Cintron' },
  { label: 'Luis Moya', value: 'Luis Moya' },
  { label: 'Luis Rodríguez (El Vergel)', value: 'Luis Rodríguez (El Vergel)' },
  { label: 'Luisago', value: 'Luisago' },
  { label: 'Luisant', value: 'Luisant' },
  { label: 'Luisburgo', value: 'Luisburgo' },
  { label: 'Luisenthal', value: 'Luisenthal' },
  { label: 'Luisenthal', value: 'Luisenthal' },
  { label: 'Luisiana', value: 'Luisiana' },
  { label: 'Luisiana La', value: 'Luisiana La' },
  { label: 'Luislândia', value: 'Luislândia' },
  { label: 'Luitré', value: 'Luitré' },
  { label: 'Luiz Alves', value: 'Luiz Alves' },
  { label: 'Luiziana', value: 'Luiziana' },
  { label: 'Luiziânia', value: 'Luiziânia' },
  { label: 'Luizi-Călugăra', value: 'Luizi-Călugăra' },
  { label: 'Lujali', value: 'Lujali' },
  { label: 'Luján', value: 'Luján' },
  { label: 'Lújar', value: 'Lújar' },
  { label: 'Luka', value: 'Luka' },
  { label: 'Luka Nad Jihlavou', value: 'Luka Nad Jihlavou' },
  { label: 'Lukachukai', value: 'Lukachukai' },
  { label: 'Lukashin', value: 'Lukashin' },
  { label: 'Lukatan', value: 'Lukatan' },
  { label: 'Lukatan', value: 'Lukatan' },
  { label: 'Lukatan', value: 'Lukatan' },
  { label: 'Lukavac', value: 'Lukavac' },
  { label: 'Lukavec', value: 'Lukavec' },
  { label: 'Lukavec', value: 'Lukavec' },
  { label: 'Lukavica', value: 'Lukavica' },
  { label: 'Lukavice', value: 'Lukavice' },
  { label: 'Lukh', value: 'Lukh' },
  { label: 'Lukhovitsy', value: 'Lukhovitsy' },
  { label: 'Lukhovka', value: 'Lukhovka' },
  { label: 'Lukhskiy Rayon', value: 'Lukhskiy Rayon' },
  { label: 'Lukićevo', value: 'Lukićevo' },
  { label: 'Lukino', value: 'Lukino' },
  { label: 'Lukiv', value: 'Lukiv' },
  { label: 'Luklukan', value: 'Luklukan' },
  { label: 'Luknovo', value: 'Luknovo' },
  { label: 'Lukolela', value: 'Lukolela' },
  { label: 'Lukov', value: 'Lukov' },
  { label: 'Lukovetskiy', value: 'Lukovetskiy' },
  { label: 'Lukovica Pri Domžalah', value: 'Lukovica Pri Domžalah' },
  { label: 'Lukovit', value: 'Lukovit' },
  { label: 'Lukovo', value: 'Lukovo' },
  { label: 'Łuków', value: 'Łuków' },
  { label: 'Łukowa', value: 'Łukowa' },
  { label: 'Łukowa', value: 'Łukowa' },
  { label: 'Łukowica', value: 'Łukowica' },
  { label: 'Lukoyanov', value: 'Lukoyanov' },
  { label: 'Lukoyanovskiy Rayon', value: 'Lukoyanovskiy Rayon' },
  { label: 'Luksuhin', value: 'Luksuhin' },
  { label: 'Lukuledi', value: 'Lukuledi' },
  { label: 'Lukulu', value: 'Lukulu' },
  { label: 'Lula', value: 'Lula' },
  { label: 'Lula', value: 'Lula' },
  { label: 'Luleå', value: 'Luleå' },
  { label: 'Lüleburgaz', value: 'Lüleburgaz' },
  { label: 'Lüliang', value: 'Lüliang' },
  { label: 'Lulindi', value: 'Lulindi' },
  { label: 'Luling', value: 'Luling' },
  { label: 'Luling', value: 'Luling' },
  { label: 'Luluhar', value: 'Luluhar' },
  { label: 'Lumaco', value: 'Lumaco' },
  { label: 'Lumajang', value: 'Lumajang' },
  { label: 'Lumangbayan', value: 'Lumangbayan' },
  { label: 'Lumatil', value: 'Lumatil' },
  { label: 'Lumatil', value: 'Lumatil' },
  { label: 'Lumazal', value: 'Lumazal' },
  { label: 'Lumazal', value: 'Lumazal' },
  { label: 'Lumba-A-Bayabao', value: 'Lumba-A-Bayabao' },
  { label: 'Lumbac', value: 'Lumbac' },
  { label: 'Lumbac', value: 'Lumbac' },
  { label: 'Lumbang', value: 'Lumbang' },
  { label: 'Lumbangan', value: 'Lumbangan' },
  { label: 'Lumbarda', value: 'Lumbarda' },
  { label: 'Lumbatan', value: 'Lumbatan' },
  { label: 'Lumbatan', value: 'Lumbatan' },
  { label: 'Lumbayan', value: 'Lumbayan' },
  { label: 'Lumbayanague', value: 'Lumbayanague' },
  { label: 'Lumbayanague', value: 'Lumbayanague' },
  { label: 'Lumbayao', value: 'Lumbayao' },
  { label: 'Lumber City', value: 'Lumber City' },
  { label: 'Lumberton', value: 'Lumberton' },
  { label: 'Lumberton', value: 'Lumberton' },
  { label: 'Lumberton', value: 'Lumberton' },
  { label: 'Lumbia', value: 'Lumbia' },
  { label: 'Lumbier', value: 'Lumbier' },
  { label: 'Lumbin', value: 'Lumbin' },
  { label: 'Lumbog', value: 'Lumbog' },
  { label: 'Lumbrales', value: 'Lumbrales' },
  { label: 'Lumbreras', value: 'Lumbreras' },
  { label: 'Lumbres', value: 'Lumbres' },
  { label: 'Lumby', value: 'Lumby' },
  { label: 'Lumding Railway Colony', value: 'Lumding Railway Colony' },
  { label: 'Lumeje', value: 'Lumeje' },
  { label: 'Lumellogno', value: 'Lumellogno' },
  { label: 'Lumes', value: 'Lumes' },
  { label: 'Lumezzane', value: 'Lumezzane' },
  { label: 'Lumiar', value: 'Lumiar' },
  { label: 'Lumiere', value: 'Lumiere' },
  { label: 'Lumignano', value: 'Lumignano' },
  { label: 'Lumigny-Nesles-Ormeaux', value: 'Lumigny-Nesles-Ormeaux' },
  { label: 'Lumijoki', value: 'Lumijoki' },
  { label: 'Lumil', value: 'Lumil' },
  { label: 'Lumina', value: 'Lumina' },
  { label: 'Luminárias', value: 'Luminárias' },
  { label: 'Luminiș', value: 'Luminiș' },
  { label: 'Lumino', value: 'Lumino' },
  { label: 'Lumio', value: 'Lumio' },
  { label: 'Lummen', value: 'Lummen' },
  { label: 'Lumphat', value: 'Lumphat' },
  { label: 'Lumpiaque', value: 'Lumpiaque' },
  { label: 'Lumpkin', value: 'Lumpkin' },
  { label: 'Lumpkin County', value: 'Lumpkin County' },
  { label: 'Lumsden', value: 'Lumsden' },
  { label: 'Lumut', value: 'Lumut' },
  { label: 'Lumuyon', value: 'Lumuyon' },
  { label: 'Lumuyon', value: 'Lumuyon' },
  { label: 'Lun Pequeño', value: 'Lun Pequeño' },
  { label: 'Lun Pequeño', value: 'Lun Pequeño' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna', value: 'Luna' },
  { label: 'Luna County', value: 'Luna County' },
  { label: 'Luna De Sus', value: 'Luna De Sus' },
  { label: 'Luna Pier', value: 'Luna Pier' },
  { label: 'Lunamatrona', value: 'Lunamatrona' },
  { label: 'Lunano', value: 'Lunano' },
  { label: 'Lunao', value: 'Lunao' },
  { label: 'Lunardelli', value: 'Lunardelli' },
  { label: 'Lunas', value: 'Lunas' },
  { label: 'Lunavada', value: 'Lunavada' },
  { label: 'Lunay', value: 'Lunay' },
  { label: 'Lunca', value: 'Lunca' },
  { label: 'Lunca', value: 'Lunca' },
  { label: 'Lunca', value: 'Lunca' },
  { label: 'Lunca', value: 'Lunca' },
  { label: 'Lunca', value: 'Lunca' },
  { label: 'Lunca', value: 'Lunca' },
  { label: 'Lunca', value: 'Lunca' },
  { label: 'Lunca', value: 'Lunca' },
  { label: 'Lunca', value: 'Lunca' },
  { label: 'Lunca Banului', value: 'Lunca Banului' },
  { label: 'Lunca Bradului', value: 'Lunca Bradului' },
  { label: 'Lunca Cernii De Jos', value: 'Lunca Cernii De Jos' },
  { label: 'Lunca Cetățuii', value: 'Lunca Cetățuii' },
  { label: 'Lunca Corbului', value: 'Lunca Corbului' },
  { label: 'Lunca De Jos', value: 'Lunca De Jos' },
  { label: 'Lunca De Sus', value: 'Lunca De Sus' },
  { label: 'Lunca Ilvei', value: 'Lunca Ilvei' },
  { label: 'Lunca Jariștei', value: 'Lunca Jariștei' },
  { label: 'Lunca La Tisa', value: 'Lunca La Tisa' },
  { label: 'Lunca Leșului', value: 'Lunca Leșului' },
  { label: 'Lunca Mureşului', value: 'Lunca Mureşului' },
  { label: 'Lunca Mureșului', value: 'Lunca Mureșului' },
  { label: 'Lunca Prahovei', value: 'Lunca Prahovei' },
  { label: 'Lunca Priporului', value: 'Lunca Priporului' },
  { label: 'Luncani', value: 'Luncani' },
  { label: 'Luncarty', value: 'Luncarty' },
  { label: 'Luncaviţa', value: 'Luncaviţa' },
  { label: 'Luncaviţa', value: 'Luncaviţa' },
  { label: 'Luncoiu De Jos', value: 'Luncoiu De Jos' },
  { label: 'Luncșoara', value: 'Luncșoara' },
  { label: 'Lund', value: 'Lund' },
  { label: 'Lund', value: 'Lund' },
  { label: 'Lund', value: 'Lund' },
  { label: 'Lundamo', value: 'Lundamo' },
  { label: 'Lundazi', value: 'Lundazi' },
  { label: 'Lunde', value: 'Lunde' },
  { label: 'Lunden', value: 'Lunden' },
  { label: 'Lunderskov', value: 'Lunderskov' },
  { label: 'Lundin Links', value: 'Lundin Links' },
  { label: 'Lüneburg', value: 'Lüneburg' },
  { label: 'Lunec', value: 'Lunec' },
  { label: 'Lunec', value: 'Lunec' },
  { label: 'Lunel', value: 'Lunel' },
  { label: 'Lunel-Viel', value: 'Lunel-Viel' },
  { label: 'Lunen', value: 'Lunen' },
  { label: 'Lunen', value: 'Lunen' },
  { label: 'Lünen', value: 'Lünen' },
  { label: 'Lunenburg', value: 'Lunenburg' },
  { label: 'Lunenburg', value: 'Lunenburg' },
  { label: 'Lunenburg', value: 'Lunenburg' },
  { label: 'Lunenburg', value: 'Lunenburg' },
  { label: 'Lunenburg County', value: 'Lunenburg County' },
  { label: 'Luneray', value: 'Luneray' },
  { label: 'Lunery', value: 'Lunery' },
  { label: 'Lunestedt', value: 'Lunestedt' },
  { label: 'Lunetta-Frassino', value: 'Lunetta-Frassino' },
  { label: 'Lunetten', value: 'Lunetten' },
  { label: 'Lunéville', value: 'Lunéville' },
  { label: 'Lunga', value: 'Lunga' },
  { label: 'Lungani', value: 'Lungani' },
  { label: 'Lungaog', value: 'Lungaog' },
  { label: 'Lungaog', value: 'Lungaog' },
  { label: 'Lungaog', value: 'Lungaog' },
  { label: 'Lungavilla', value: 'Lungavilla' },
  { label: 'Lungern', value: 'Lungern' },
  { label: 'Lungeşti', value: 'Lungeşti' },
  { label: 'Lunglei', value: 'Lunglei' },
  { label: 'Lungog', value: 'Lungog' },
  { label: 'Lungog', value: 'Lungog' },
  { label: 'Lungro', value: 'Lungro' },
  { label: 'Lunguleţu', value: 'Lunguleţu' },
  { label: 'Lunino', value: 'Lunino' },
  { label: 'Luninyets', value: 'Luninyets' },
  { label: 'Lunion', value: 'Lunion' },
  { label: 'Lünne', value: 'Lünne' },
  { label: 'Lunner', value: 'Lunner' },
  { label: 'Lunsar', value: 'Lunsar' },
  { label: 'Luntal', value: 'Luntal' },
  { label: 'Lunteren', value: 'Lunteren' },
  { label: 'Lunz Am See', value: 'Lunz Am See' },
  { label: 'Lunzenau', value: 'Lunzenau' },
  { label: 'Luocheng', value: 'Luocheng' },
  { label: 'Luocheng', value: 'Luocheng' },
  { label: 'Luogosano', value: 'Luogosano' },
  { label: 'Luogosanto', value: 'Luogosanto' },
  { label: 'Luohe', value: 'Luohe' },
  { label: 'Luohe Shi', value: 'Luohe Shi' },
  { label: 'Luojiu', value: 'Luojiu' },
  { label: 'Luopioinen', value: 'Luopioinen' },
  { label: 'Luoqiao', value: 'Luoqiao' },
  { label: 'Luorong', value: 'Luorong' },
  { label: 'Luotuofangzi', value: 'Luotuofangzi' },
  { label: 'Luoyang', value: 'Luoyang' },
  { label: 'Luoyang', value: 'Luoyang' },
  { label: 'Luoyang', value: 'Luoyang' },
  { label: 'Lupac', value: 'Lupac' },
  { label: 'Lupane', value: 'Lupane' },
  { label: 'Lupane District', value: 'Lupane District' },
  { label: 'Lupao', value: 'Lupao' },
  { label: 'Lupao', value: 'Lupao' },
  { label: 'Lupara', value: 'Lupara' },
  { label: 'Lupata', value: 'Lupata' },
  { label: 'Lupburg', value: 'Lupburg' },
  { label: 'Lupeni', value: 'Lupeni' },
  { label: 'Lupeni', value: 'Lupeni' },
  { label: 'Lupércio', value: 'Lupércio' },
  { label: 'Luperón', value: 'Luperón' },
  { label: 'Lupi', value: 'Lupi' },
  { label: 'Lupi Viejo', value: 'Lupi Viejo' },
  { label: 'Lupiana', value: 'Lupiana' },
  { label: 'Lupiñén-Ortilla', value: 'Lupiñén-Ortilla' },
  { label: 'Lupión', value: 'Lupión' },
  { label: 'Lupionópolis', value: 'Lupionópolis' },
  { label: 'Lupiro', value: 'Lupiro' },
  { label: 'Lupo', value: 'Lupo' },
  { label: 'Lupoglav', value: 'Lupoglav' },
  { label: 'Lupoglav', value: 'Lupoglav' },
  { label: 'Lupon', value: 'Lupon' },
  { label: 'Lupon', value: 'Lupon' },
  { label: 'Lupon', value: 'Lupon' },
  { label: 'Luppolovo', value: 'Luppolovo' },
  { label: 'Lupşa', value: 'Lupşa' },
  { label: 'Lupşanu', value: 'Lupşanu' },
  { label: 'Luqiao', value: 'Luqiao' },
  { label: 'Luque', value: 'Luque' },
  { label: 'Luquillo', value: 'Luquillo' },
  { label: 'Luquillo', value: 'Luquillo' },
  { label: 'Luquillo Municipio', value: 'Luquillo Municipio' },
  { label: 'Luquin', value: 'Luquin' },
  { label: 'Lurago Derba', value: 'Lurago Derba' },
  { label: 'Lurago Marinone', value: 'Lurago Marinone' },
  { label: 'Lurano', value: 'Lurano' },
  { label: 'Luras', value: 'Luras' },
  { label: 'Lurate Caccivio', value: 'Lurate Caccivio' },
  { label: 'Luray', value: 'Luray' },
  { label: 'Luray', value: 'Luray' },
  { label: 'Lurcy-Lévis', value: 'Lurcy-Lévis' },
  { label: 'Lure', value: 'Lure' },
  { label: 'Lurnea', value: 'Lurnea' },
  { label: 'Lurnfeld', value: 'Lurnfeld' },
  { label: 'Luro', value: 'Luro' },
  { label: 'Lurøy', value: 'Lurøy' },
  { label: 'Lürschau', value: 'Lürschau' },
  { label: 'Luruaco', value: 'Luruaco' },
  { label: 'Lurugan', value: 'Lurugan' },
  { label: 'Lurup', value: 'Lurup' },
  { label: 'Lusacan', value: 'Lusacan' },
  { label: 'Lusaka', value: 'Lusaka' },
  { label: 'Lusambo', value: 'Lusambo' },
  { label: 'Lusanger', value: 'Lusanger' },
  { label: 'Lusarrat', value: 'Lusarrat' },
  { label: 'Lusby', value: 'Lusby' },
  { label: 'Lusciano', value: 'Lusciano' },
  { label: 'Luserna', value: 'Luserna' },
  { label: 'Luserna', value: 'Luserna' },
  { label: 'Lusernetta', value: 'Lusernetta' },
  { label: 'Lusevera', value: 'Lusevera' },
  { label: 'Lushnjë', value: 'Lushnjë' },
  { label: 'Lushoto', value: 'Lushoto' },
  { label: 'Lüshun', value: 'Lüshun' },
  { label: 'Lusia', value: 'Lusia' },
  { label: 'Lusiana', value: 'Lusiana' },
  { label: 'Lusigliè', value: 'Lusigliè' },
  { label: 'Lusignan', value: 'Lusignan' },
  { label: 'Lusigny', value: 'Lusigny' },
  { label: 'Lusigny-Sur-Barse', value: 'Lusigny-Sur-Barse' },
  { label: 'Lusk', value: 'Lusk' },
  { label: 'Lusk', value: 'Lusk' },
  { label: 'Luso', value: 'Luso' },
  { label: 'Luson - Luesen', value: 'Luson - Luesen' },
  { label: 'Lusong', value: 'Lusong' },
  { label: 'Lusong', value: 'Lusong' },
  { label: 'Lussac', value: 'Lussac' },
  { label: 'Lussac-Les-Châteaux', value: 'Lussac-Les-Châteaux' },
  { label: 'Lustadt', value: 'Lustadt' },
  { label: 'Lustenau', value: 'Lustenau' },
  { label: 'Luštěnice', value: 'Luštěnice' },
  { label: 'Luster', value: 'Luster' },
  { label: 'Lustosa', value: 'Lustosa' },
  { label: 'Lustra', value: 'Lustra' },
  { label: 'Lusungo', value: 'Lusungo' },
  { label: 'Luszowice', value: 'Luszowice' },
  { label: 'Lutana', value: 'Lutana' },
  { label: 'Lutayan', value: 'Lutayan' },
  { label: 'Lutayan', value: 'Lutayan' },
  { label: 'Lutayan', value: 'Lutayan' },
  { label: 'Lutcher', value: 'Lutcher' },
  { label: 'Lutécia', value: 'Lutécia' },
  { label: 'Luterbach', value: 'Luterbach' },
  { label: 'Lutes Mountain', value: 'Lutes Mountain' },
  { label: 'Luther', value: 'Luther' },
  { label: 'Luthern', value: 'Luthern' },
  { label: 'Lutherville', value: 'Lutherville' },
  { label: 'Lutherville-Timonium', value: 'Lutherville-Timonium' },
  { label: 'Lutín', value: 'Lutín' },
  { label: 'Lütisburg', value: 'Lütisburg' },
  { label: 'Lutjebroek', value: 'Lutjebroek' },
  { label: 'Lütjenburg', value: 'Lütjenburg' },
  { label: 'Lütjensee', value: 'Lütjensee' },
  { label: 'Lutjewinkel', value: 'Lutjewinkel' },
  { label: 'Lutkun', value: 'Lutkun' },
  { label: 'Lutocin', value: 'Lutocin' },
  { label: 'Lut-Od', value: 'Lut-Od' },
  { label: 'Lutomiersk', value: 'Lutomiersk' },
  { label: 'Luton', value: 'Luton' },
  { label: 'Lutoryż', value: 'Lutoryż' },
  { label: 'Lutovynivka', value: 'Lutovynivka' },
  { label: 'Lutowiska', value: 'Lutowiska' },
  { label: 'Lutrano', value: 'Lutrano' },
  { label: 'Lutry', value: 'Lutry' },
  { label: 'Lutsk', value: 'Lutsk' },
  { label: 'Lutter Am Barenberge', value: 'Lutter Am Barenberge' },
  { label: 'Lutterbach', value: 'Lutterbach' },
  { label: 'Lutterworth', value: 'Lutterworth' },
  { label: 'Luttrell', value: 'Luttrell' },
  { label: 'Lutuhyne', value: 'Lutuhyne' },
  { label: 'Lutuhyns’Kyy Rayon', value: 'Lutuhyns’Kyy Rayon' },
  { label: 'Lututów', value: 'Lututów' },
  { label: 'Lutwyche', value: 'Lutwyche' },
  { label: 'Lutz', value: 'Lutz' },
  { label: 'Lützelbach', value: 'Lützelbach' },
  { label: 'Lützelflüh', value: 'Lützelflüh' },
  { label: 'Lutzelhouse', value: 'Lutzelhouse' },
  { label: 'Lützen', value: 'Lützen' },
  { label: 'Lutzerath', value: 'Lutzerath' },
  { label: 'Lutzmannsburg', value: 'Lutzmannsburg' },
  { label: 'Lützow', value: 'Lützow' },
  { label: 'Luuk', value: 'Luuk' },
  { label: 'Lu-Uk', value: 'Lu-Uk' },
  { label: 'Luuk Datan', value: 'Luuk Datan' },
  { label: 'Luuk Datan', value: 'Luuk Datan' },
  { label: 'Luumäki', value: 'Luumäki' },
  { label: 'Luunja', value: 'Luunja' },
  { label: 'Luunja Vald', value: 'Luunja Vald' },
  { label: 'Luuq', value: 'Luuq' },
  { label: 'Luverne', value: 'Luverne' },
  { label: 'Luverne', value: 'Luverne' },
  { label: 'Luvia', value: 'Luvia' },
  { label: 'Luvianos', value: 'Luvianos' },
  { label: 'Luvinate', value: 'Luvinate' },
  { label: 'Luwero', value: 'Luwero' },
  { label: 'Luwingu', value: 'Luwingu' },
  { label: 'Luwuk', value: 'Luwuk' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Luxemburg', value: 'Luxemburg' },
  { label: 'Luxeuil-Les-Bains', value: 'Luxeuil-Les-Bains' },
  { label: 'Luxia', value: 'Luxia' },
  { label: 'Luxor', value: 'Luxor' },
  { label: 'Luxora', value: 'Luxora' },
  { label: 'Luyando', value: 'Luyando' },
  { label: 'Luyang', value: 'Luyang' },
  { label: 'Luyego', value: 'Luyego' },
  { label: 'Luyksgestel', value: 'Luyksgestel' },
  { label: 'Luynes', value: 'Luynes' },
  { label: 'Luz', value: 'Luz' },
  { label: 'Luz', value: 'Luz' },
  { label: 'Luza', value: 'Luza' },
  { label: 'Luzaga', value: 'Luzaga' },
  { label: 'Luzaide/Valcarlos', value: 'Luzaide/Valcarlos' },
  { label: 'Lužani', value: 'Lužani' },
  { label: 'Luzarches', value: 'Luzarches' },
  { label: 'Luže', value: 'Luže' },
  { label: 'Lužec Nad Vltavou', value: 'Lužec Nad Vltavou' },
  { label: 'Luzech', value: 'Luzech' },
  { label: 'Luzein', value: 'Luzein' },
  { label: 'Luzern', value: 'Luzern' },
  { label: 'Luzerna', value: 'Luzerna' },
  { label: 'Luzerne', value: 'Luzerne' },
  { label: 'Luzerne County', value: 'Luzerne County' },
  { label: 'Luzhany', value: 'Luzhany' },
  { label: 'Luzhniki', value: 'Luzhniki' },
  { label: 'Luzhou', value: 'Luzhou' },
  { label: 'Luzhskiy Rayon', value: 'Luzhskiy Rayon' },
  { label: 'Luziânia', value: 'Luziânia' },
  { label: 'Lužice', value: 'Lužice' },
  { label: 'Luzilândia', value: 'Luzilândia' },
  { label: 'Luzinay', value: 'Luzinay' },
  { label: 'Luzino', value: 'Luzino' },
  { label: 'Luzino', value: 'Luzino' },
  { label: 'Luzinópolis', value: 'Luzinópolis' },
  { label: 'Luzmela', value: 'Luzmela' },
  { label: 'Lužná', value: 'Lužná' },
  { label: 'Łużna', value: 'Łużna' },
  { label: 'Luzon', value: 'Luzon' },
  { label: 'Luzon', value: 'Luzon' },
  { label: 'Luzon', value: 'Luzon' },
  { label: 'Luzón', value: 'Luzón' },
  { label: 'Luz-Saint-Sauveur', value: 'Luz-Saint-Sauveur' },
  { label: 'Luzskiy Rayon', value: 'Luzskiy Rayon' },
  { label: 'Luzy', value: 'Luzy' },
  { label: 'Luzzana', value: 'Luzzana' },
  { label: 'Luzzano', value: 'Luzzano' },
  { label: 'Luzzara', value: 'Luzzara' },
  { label: 'Luzzi', value: 'Luzzi' },
  { label: 'Lviv', value: 'Lviv' },
  { label: 'Lwówek', value: 'Lwówek' },
  { label: 'Lwówek Śląski', value: 'Lwówek Śląski' },
  { label: 'Lyakhavichy', value: 'Lyakhavichy' },
  { label: 'Lyalichi', value: 'Lyalichi' },
  { label: 'Lyambir’', value: 'Lyambir’' },
  { label: 'Lyambirskiy Rayon', value: 'Lyambirskiy Rayon' },
  { label: 'Lyamino', value: 'Lyamino' },
  { label: 'Lyangasovo', value: 'Lyangasovo' },
  { label: 'Lyantonde', value: 'Lyantonde' },
  { label: 'Lyantor', value: 'Lyantor' },
  { label: 'Lyaskelya', value: 'Lyaskelya' },
  { label: 'Lyaskovets', value: 'Lyaskovets' },
  { label: 'Lyasny', value: 'Lyasny' },
  { label: 'Lyaud', value: 'Lyaud' },
  { label: 'Lychen', value: 'Lychen' },
  { label: 'Lycksele', value: 'Lycksele' },
  { label: 'Lycoming County', value: 'Lycoming County' },
  { label: 'Lydbrook', value: 'Lydbrook' },
  { label: 'Lydd', value: 'Lydd' },
  { label: 'Lydenburg', value: 'Lydenburg' },
  { label: 'Lydiard Millicent', value: 'Lydiard Millicent' },
  { label: 'Lydney', value: 'Lydney' },
  { label: 'Lyefjell', value: 'Lyefjell' },
  { label: 'Lyel’Chytski Rayon', value: 'Lyel’Chytski Rayon' },
  { label: 'Lyel’Chytsy', value: 'Lyel’Chytsy' },
  { label: 'Lyeninski Rayon', value: 'Lyeninski Rayon' },
  { label: 'Lyepyel’', value: 'Lyepyel’' },
  { label: 'Lyepyel’Ski Rayon', value: 'Lyepyel’Ski Rayon' },
  { label: 'Lyeskawka', value: 'Lyeskawka' },
  { label: 'Lyford', value: 'Lyford' },
  { label: 'Lýkeio', value: 'Lýkeio' },
  { label: 'Lykens', value: 'Lykens' },
  { label: 'Lykhivka', value: 'Lykhivka' },
  { label: 'Lykóvrysi', value: 'Lykóvrysi' },
  { label: 'Lyman', value: 'Lyman' },
  { label: 'Lyman', value: 'Lyman' },
  { label: 'Lyman', value: 'Lyman' },
  { label: 'Lyman', value: 'Lyman' },
  { label: 'Lyman', value: 'Lyman' },
  { label: 'Lyman County', value: 'Lyman County' },
  { label: 'Lyman Raion', value: 'Lyman Raion' },
  { label: 'Lymanske', value: 'Lymanske' },
  { label: 'Lyme', value: 'Lyme' },
  { label: 'Lyme Regis', value: 'Lyme Regis' },
  { label: 'Lyminge', value: 'Lyminge' },
  { label: 'Lymington', value: 'Lymington' },
  { label: 'Lymm', value: 'Lymm' },
  { label: 'Lýmpia', value: 'Lýmpia' },
  { label: 'Lympne', value: 'Lympne' },
  { label: 'Lympstone', value: 'Lympstone' },
  { label: 'Lynbrook', value: 'Lynbrook' },
  { label: 'Lynbrook', value: 'Lynbrook' },
  { label: 'Lynchburg', value: 'Lynchburg' },
  { label: 'Lynchburg', value: 'Lynchburg' },
  { label: 'Lynchburg', value: 'Lynchburg' },
  { label: 'Lynchburg', value: 'Lynchburg' },
  { label: 'Lyncourt', value: 'Lyncourt' },
  { label: 'Lyndeborough', value: 'Lyndeborough' },
  { label: 'Lynden', value: 'Lynden' },
  { label: 'Lyndhurst', value: 'Lyndhurst' },
  { label: 'Lyndhurst', value: 'Lyndhurst' },
  { label: 'Lyndhurst', value: 'Lyndhurst' },
  { label: 'Lyndhurst', value: 'Lyndhurst' },
  { label: 'Lyndoch', value: 'Lyndoch' },
  { label: 'Lyndon', value: 'Lyndon' },
  { label: 'Lyndon', value: 'Lyndon' },
  { label: 'Lyndon', value: 'Lyndon' },
  { label: 'Lyndonville', value: 'Lyndonville' },
  { label: 'Lyneham', value: 'Lyneham' },
  { label: 'Lyneham', value: 'Lyneham' },
  { label: 'Lynemouth', value: 'Lynemouth' },
  { label: 'Lyngby-Tårbæk Kommune', value: 'Lyngby-Tårbæk Kommune' },
  { label: 'Lyngdal', value: 'Lyngdal' },
  { label: 'Lynge', value: 'Lynge' },
  { label: 'Lyngen', value: 'Lyngen' },
  { label: 'Lyngseidet', value: 'Lyngseidet' },
  { label: 'Lynn', value: 'Lynn' },
  { label: 'Lynn', value: 'Lynn' },
  { label: 'Lynn County', value: 'Lynn County' },
  { label: 'Lynn Haven', value: 'Lynn Haven' },
  { label: 'Lynnfield', value: 'Lynnfield' },
  { label: 'Lynnwood', value: 'Lynnwood' },
  { label: 'Lynnwood-Pricedale', value: 'Lynnwood-Pricedale' },
  { label: 'Lynton', value: 'Lynton' },
  { label: 'Lyntupy', value: 'Lyntupy' },
  { label: 'Lynwood', value: 'Lynwood' },
  { label: 'Lynwood', value: 'Lynwood' },
  { label: 'Lynwood', value: 'Lynwood' },
  { label: 'Lyon', value: 'Lyon' },
  { label: 'Lyon County', value: 'Lyon County' },
  { label: 'Lyon County', value: 'Lyon County' },
  { label: 'Lyon County', value: 'Lyon County' },
  { label: 'Lyon County', value: 'Lyon County' },
  { label: 'Lyon County', value: 'Lyon County' },
  { label: 'Lyons', value: 'Lyons' },
  { label: 'Lyons', value: 'Lyons' },
  { label: 'Lyons', value: 'Lyons' },
  { label: 'Lyons', value: 'Lyons' },
  { label: 'Lyons', value: 'Lyons' },
  { label: 'Lyons', value: 'Lyons' },
  { label: 'Lyons', value: 'Lyons' },
  { label: 'Lyons', value: 'Lyons' },
  { label: 'Lyozna', value: 'Lyozna' },
  { label: 'Lyoznyenski Rayon', value: 'Lyoznyenski Rayon' },
  { label: 'Lypcha', value: 'Lypcha' },
  { label: 'Lypnyazhka', value: 'Lypnyazhka' },
  { label: 'Lypova Dolyna', value: 'Lypova Dolyna' },
  { label: 'Lypovets’Kyy Rayon', value: 'Lypovets’Kyy Rayon' },
  { label: 'Lys’Va', value: 'Lys’Va' },
  { label: 'Lysa Hora', value: 'Lysa Hora' },
  { label: 'Lysá Nad Labem', value: 'Lysá Nad Labem' },
  { label: 'Lysaker', value: 'Lysaker' },
  { label: 'Łyse', value: 'Łyse' },
  { label: 'Lysekil', value: 'Lysekil' },
  { label: 'Lysianka', value: 'Lysianka' },
  { label: 'Lysice', value: 'Lysice' },
  { label: 'Lyski', value: 'Lyski' },
  { label: 'Lyskovo', value: 'Lyskovo' },
  { label: 'Lys-Lez-Lannoy', value: 'Lys-Lez-Lannoy' },
  { label: 'Lysogorskaya', value: 'Lysogorskaya' },
  { label: 'Łysomice', value: 'Łysomice' },
  { label: 'Lyss', value: 'Lyss' },
  { label: 'Lyssach', value: 'Lyssach' },
  { label: 'Lyssons', value: 'Lyssons' },
  { label: 'Lysterfield', value: 'Lysterfield' },
  { label: 'Lystrup', value: 'Lystrup' },
  { label: 'Lysychans’K', value: 'Lysychans’K' },
  { label: 'Lysychovo', value: 'Lysychovo' },
  { label: 'Lysyye Gory', value: 'Lysyye Gory' },
  { label: 'Łyszkowice', value: 'Łyszkowice' },
  { label: 'Lytchett Matravers', value: 'Lytchett Matravers' },
  { label: 'Lytham St Annes', value: 'Lytham St Annes' },
  { label: 'Lythrodóntas', value: 'Lythrodóntas' },
  { label: 'Lytkarino', value: 'Lytkarino' },
  { label: 'Lytle', value: 'Lytle' },
  { label: 'Lyuban’', value: 'Lyuban’' },
  { label: 'Lyuban’', value: 'Lyuban’' },
  { label: 'Lyubashivka', value: 'Lyubashivka' },
  { label: 'Lyubcha', value: 'Lyubcha' },
  { label: 'Lyubech', value: 'Lyubech' },
  { label: 'Lyuberetskiy Rayon', value: 'Lyuberetskiy Rayon' },
  { label: 'Lyubertsy', value: 'Lyubertsy' },
  { label: 'Lyubeshivs’Kyy Rayon', value: 'Lyubeshivs’Kyy Rayon' },
  { label: 'Lyubim', value: 'Lyubim' },
  { label: 'Lyubimets', value: 'Lyubimets' },
  { label: 'Lyubimskiy Rayon', value: 'Lyubimskiy Rayon' },
  { label: 'Lyubinskiy', value: 'Lyubinskiy' },
  { label: 'Lyublino', value: 'Lyublino' },
  { label: 'Lyubokhna', value: 'Lyubokhna' },
  { label: 'Lyuboml’S’Kyy Rayon', value: 'Lyuboml’S’Kyy Rayon' },
  { label: 'Lyubotyn', value: 'Lyubotyn' },
  { label: 'Lyubovsho', value: 'Lyubovsho' },
  { label: 'Lyubuchany', value: 'Lyubuchany' },
  { label: 'Lyubymivka', value: 'Lyubymivka' },
  { label: 'Lyubytino', value: 'Lyubytino' },
  { label: 'Lyubytinskiy Rayon', value: 'Lyubytinskiy Rayon' },
  { label: 'Lyudinovo', value: 'Lyudinovo' },
  { label: 'Lyudinovskiy Rayon', value: 'Lyudinovskiy Rayon' },
  { label: 'M’Sila', value: 'M’Sila' },
  { label: 'Ma On Shan', value: 'Ma On Shan' },
  { label: 'Ma`Riyah', value: 'Ma`Riyah' },
  { label: 'Ma‘Arratmişrīn', value: 'Ma‘Arratmişrīn' },
  { label: 'Mā‘Ili', value: 'Mā‘Ili' },
  { label: 'Ma‘Lūlā', value: 'Ma‘Lūlā' },
  { label: 'Ma’An', value: 'Ma’An' },
  { label: 'Maadi', value: 'Maadi' },
  { label: 'Maafushi', value: 'Maafushi' },
  { label: 'Maagnas', value: 'Maagnas' },
  { label: 'Maalot Tarshīhā', value: 'Maalot Tarshīhā' },
  { label: 'Maamba', value: 'Maamba' },
  { label: 'Maan', value: 'Maan' },
  { label: 'Maan', value: 'Maan' },
  { label: 'Maan', value: 'Maan' },
  { label: 'Maanas', value: 'Maanas' },
  { label: 'Maao', value: 'Maao' },
  { label: 'Maap Municipality', value: 'Maap Municipality' },
  { label: 'Maapi', value: 'Maapi' },
  { label: 'Maardu', value: 'Maardu' },
  { label: 'Maardu Linn', value: 'Maardu Linn' },
  { label: 'Maarheeze', value: 'Maarheeze' },
  { label: 'Maarn', value: 'Maarn' },
  { label: 'Maarrat Al-Numan District', value: 'Maarrat Al-Numan District' },
  { label: 'Maarssen', value: 'Maarssen' },
  { label: 'Maartensdijk', value: 'Maartensdijk' },
  { label: 'Maasbracht', value: 'Maasbracht' },
  { label: 'Maasbree', value: 'Maasbree' },
  { label: 'Maasdijk', value: 'Maasdijk' },
  { label: 'Maaseik', value: 'Maaseik' },
  { label: 'Maasim', value: 'Maasim' },
  { label: 'Maasim', value: 'Maasim' },
  { label: 'Maasim', value: 'Maasim' },
  { label: 'Maasim', value: 'Maasim' },
  { label: 'Maasin', value: 'Maasin' },
  { label: 'Maasin', value: 'Maasin' },
  { label: 'Maasin', value: 'Maasin' },
  { label: 'Maaskantje', value: 'Maaskantje' },
  { label: 'Maasland', value: 'Maasland' },
  { label: 'Maasmechelen', value: 'Maasmechelen' },
  { label: 'Maassluis', value: 'Maassluis' },
  { label: 'Maastricht', value: 'Maastricht' },
  { label: 'Maayon', value: 'Maayon' },
  { label: 'Maayong Tubig', value: 'Maayong Tubig' },
  { label: 'Maba', value: 'Maba' },
  { label: 'Mababanaba', value: 'Mababanaba' },
  { label: 'Mabahin', value: 'Mabahin' },
  { label: 'Mabahin', value: 'Mabahin' },
  { label: 'Mabai', value: 'Mabai' },
  { label: 'Mabalacat', value: 'Mabalacat' },
  { label: 'Mabalacat City', value: 'Mabalacat City' },
  { label: 'Mabama', value: 'Mabama' },
  { label: 'Mabamba', value: 'Mabamba' },
  { label: 'Mabank', value: 'Mabank' },
  { label: 'Mabaruma', value: 'Mabaruma' },
  { label: 'Mabasa', value: 'Mabasa' },
  { label: 'Mabasa', value: 'Mabasa' },
  { label: 'Mabay', value: 'Mabay' },
  { label: 'Mabay', value: 'Mabay' },
  { label: 'Mabayo', value: 'Mabayo' },
  { label: 'Mabayo', value: 'Mabayo' },
  { label: 'Mabilang', value: 'Mabilang' },
  { label: 'Mabilang', value: 'Mabilang' },
  { label: 'Mabilao', value: 'Mabilao' },
  { label: 'Mabilao', value: 'Mabilao' },
  { label: 'Mabilbila Sur', value: 'Mabilbila Sur' },
  { label: 'Mabilbila Sur', value: 'Mabilbila Sur' },
  { label: 'Mabilog', value: 'Mabilog' },
  { label: 'Mabilog', value: 'Mabilog' },
  { label: 'Mabinay', value: 'Mabinay' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabini', value: 'Mabini' },
  { label: 'Mabitac', value: 'Mabitac' },
  { label: 'Mabiton', value: 'Mabiton' },
  { label: 'Mablethorpe', value: 'Mablethorpe' },
  { label: 'Mableton', value: 'Mableton' },
  { label: 'Mably', value: 'Mably' },
  { label: 'Mabopane', value: 'Mabopane' },
  { label: 'Mabscott', value: 'Mabscott' },
  { label: 'Mabton', value: 'Mabton' },
  { label: 'Mabua', value: 'Mabua' },
  { label: 'Mabua', value: 'Mabua' },
  { label: 'Mabuhay', value: 'Mabuhay' },
  { label: 'Mabuhay', value: 'Mabuhay' },
  { label: 'Mabuhay', value: 'Mabuhay' },
  { label: 'Mabuhay', value: 'Mabuhay' },
  { label: 'Mabunga', value: 'Mabunga' },
  { label: 'Mabusag', value: 'Mabusag' },
  { label: 'Mabusag', value: 'Mabusag' },
  { label: 'Mabuttal East', value: 'Mabuttal East' },
  { label: 'Mabuttal East', value: 'Mabuttal East' },
  { label: 'Mabyan', value: 'Mabyan' },
  { label: 'Macaas', value: 'Macaas' },
  { label: 'Macabebe', value: 'Macabebe' },
  { label: 'Macabebe', value: 'Macabebe' },
  { label: 'Macabuboni', value: 'Macabuboni' },
  { label: 'Macabuboni', value: 'Macabuboni' },
  { label: 'Macabugos', value: 'Macabugos' },
  { label: 'Macachín', value: 'Macachín' },
  { label: 'Macaé', value: 'Macaé' },
  { label: 'Macael', value: 'Macael' },
  { label: 'Macaíba', value: 'Macaíba' },
  { label: 'Maçainhas', value: 'Maçainhas' },
  { label: 'Macajuba', value: 'Macajuba' },
  { label: 'Maçal Do Chão', value: 'Maçal Do Chão' },
  { label: 'Macalamcam A', value: 'Macalamcam A' },
  { label: 'Macalaya', value: 'Macalaya' },
  { label: 'Macalelon', value: 'Macalelon' },
  { label: 'Macalong', value: 'Macalong' },
  { label: 'Macalong', value: 'Macalong' },
  { label: 'Macalva Norte', value: 'Macalva Norte' },
  { label: 'Macalva Norte', value: 'Macalva Norte' },
  { label: 'Maçambará', value: 'Maçambará' },
  { label: 'Macambira', value: 'Macambira' },
  { label: 'Macamic', value: 'Macamic' },
  { label: 'Macanal', value: 'Macanal' },
  { label: 'Maçanet De Cabrenys', value: 'Maçanet De Cabrenys' },
  { label: 'Maçanet De La Selva', value: 'Maçanet De La Selva' },
  { label: 'Mação', value: 'Mação' },
  { label: 'Macapá', value: 'Macapá' },
  { label: 'Macaparana', value: 'Macaparana' },
  { label: 'Macapsing', value: 'Macapsing' },
  { label: 'Macapsing', value: 'Macapsing' },
  { label: 'Macapule', value: 'Macapule' },
  { label: 'Macaracas', value: 'Macaracas' },
  { label: 'Macarani', value: 'Macarani' },
  { label: 'Macaravita', value: 'Macaravita' },
  { label: 'Macario J. Gómez [Colonia]', value: 'Macario J. Gómez [Colonia]' },
  { label: 'Macarse', value: 'Macarse' },
  { label: 'Macarse', value: 'Macarse' },
  { label: 'Macarthur', value: 'Macarthur' },
  { label: 'Macarthur', value: 'Macarthur' },
  { label: 'Macarthur', value: 'Macarthur' },
  { label: 'Macarthur', value: 'Macarthur' },
  { label: 'Macas', value: 'Macas' },
  { label: 'Macastre', value: 'Macastre' },
  { label: 'Macatbong', value: 'Macatbong' },
  { label: 'Macatbong', value: 'Macatbong' },
  { label: 'Macatuba', value: 'Macatuba' },
  { label: 'Macau', value: 'Macau' },
  { label: 'Macau', value: 'Macau' },
  { label: 'Macaubal', value: 'Macaubal' },
  { label: 'Macaúbas', value: 'Macaúbas' },
  { label: 'Macayug', value: 'Macayug' },
  { label: 'Macayug', value: 'Macayug' },
  { label: 'Maccacari', value: 'Maccacari' },
  { label: 'Maccagno', value: 'Maccagno' },
  { label: 'Maccarese', value: 'Maccarese' },
  { label: 'Maccastorna', value: 'Maccastorna' },
  { label: 'Macchia', value: 'Macchia' },
  { label: 'Macchia Disernia', value: 'Macchia Disernia' },
  { label: 'Macchia Valfortore', value: 'Macchia Valfortore' },
  { label: 'Macchiagodena', value: 'Macchiagodena' },
  { label: 'Macclenny', value: 'Macclenny' },
  { label: 'Macclesfield', value: 'Macclesfield' },
  { label: 'Macclesfield', value: 'Macclesfield' },
  { label: 'Maccoli-Perrillo', value: 'Maccoli-Perrillo' },
  { label: 'Macdomel', value: 'Macdomel' },
  { label: 'Macdonnell', value: 'Macdonnell' },
  { label: 'Macduff', value: 'Macduff' },
  { label: 'Mače', value: 'Mače' },
  { label: 'Macea', value: 'Macea' },
  { label: 'Maceda', value: 'Maceda' },
  { label: 'Macedo De Cavaleiros', value: 'Macedo De Cavaleiros' },
  { label: 'Macedon', value: 'Macedon' },
  { label: 'Macedon', value: 'Macedon' },
  { label: 'Macedon Ranges', value: 'Macedon Ranges' },
  { label: 'Macedonia', value: 'Macedonia' },
  { label: 'Macedônia', value: 'Macedônia' },
  { label: 'Macedonio Alonso', value: 'Macedonio Alonso' },
  { label: 'Maceió', value: 'Maceió' },
  { label: 'Maceira', value: 'Maceira' },
  { label: 'Maceira', value: 'Maceira' },
  { label: 'Macello', value: 'Macello' },
  { label: 'Macenta', value: 'Macenta' },
  { label: 'Maceo', value: 'Maceo' },
  { label: 'Macerata', value: 'Macerata' },
  { label: 'Macerata Campania', value: 'Macerata Campania' },
  { label: 'Macerata Feltria', value: 'Macerata Feltria' },
  { label: 'Macere', value: 'Macere' },
  { label: 'Măceşu De Jos', value: 'Măceşu De Jos' },
  { label: 'Măceşu De Sus', value: 'Măceşu De Sus' },
  { label: 'Macgregor', value: 'Macgregor' },
  { label: 'Macgregor', value: 'Macgregor' },
  { label: 'Mach', value: 'Mach' },
  { label: 'Machacalis', value: 'Machacalis' },
  { label: 'Machacamarca', value: 'Machacamarca' },
  { label: 'Machachi', value: 'Machachi' },
  { label: 'Machacón', value: 'Machacón' },
  { label: 'Machadinho', value: 'Machadinho' },
  { label: 'Machadinho Doeste', value: 'Machadinho Doeste' },
  { label: 'Machado', value: 'Machado' },
  { label: 'Machados', value: 'Machados' },
  { label: 'Machagai', value: 'Machagai' },
  { label: 'Machakos', value: 'Machakos' },
  { label: 'Machala', value: 'Machala' },
  { label: 'Machalí', value: 'Machalí' },
  { label: 'Machalpur', value: 'Machalpur' },
  { label: 'Machame', value: 'Machame' },
  { label: 'Machame', value: 'Machame' },
  { label: 'Machaneng', value: 'Machaneng' },
  { label: 'Machans Beach', value: 'Machans Beach' },
  { label: 'Macharaviaya', value: 'Macharaviaya' },
  { label: 'Maché', value: 'Maché' },
  { label: 'Machecoul', value: 'Machecoul' },
  { label: 'Macheke', value: 'Macheke' },
  { label: 'Machelen', value: 'Machelen' },
  { label: 'Machen', value: 'Machen' },
  { label: 'Macheng', value: 'Macheng' },
  { label: 'Mácher', value: 'Mácher' },
  { label: 'Macheren', value: 'Macheren' },
  { label: 'Macherio', value: 'Macherio' },
  { label: 'Macherla', value: 'Macherla' },
  { label: 'Machern', value: 'Machern' },
  { label: 'Machesney Park', value: 'Machesney Park' },
  { label: 'Macheta', value: 'Macheta' },
  { label: 'Machetla', value: 'Machetla' },
  { label: 'Machhali Shahar', value: 'Machhali Shahar' },
  { label: 'Machhiwara', value: 'Machhiwara' },
  { label: 'Machhlishahr', value: 'Machhlishahr' },
  { label: 'Machias', value: 'Machias' },
  { label: 'Machias', value: 'Machias' },
  { label: 'Machiasport', value: 'Machiasport' },
  { label: 'Machico', value: 'Machico' },
  { label: 'Machida', value: 'Machida' },
  { label: 'Machida-Shi', value: 'Machida-Shi' },
  { label: 'Machilipatnam', value: 'Machilipatnam' },
  { label: 'Machina', value: 'Machina' },
  { label: 'Machinda', value: 'Machinda' },
  { label: 'Machinga', value: 'Machinga' },
  { label: 'Machinga District', value: 'Machinga District' },
  { label: 'Machiques', value: 'Machiques' },
  { label: 'Macho De Agua', value: 'Macho De Agua' },
  { label: 'Machov', value: 'Machov' },
  { label: 'Machulishchy', value: 'Machulishchy' },
  { label: 'Machynlleth', value: 'Machynlleth' },
  { label: 'Macia', value: 'Macia' },
  { label: 'Maciá', value: 'Maciá' },
  { label: 'Măcicășești', value: 'Măcicășești' },
  { label: 'Macieira', value: 'Macieira' },
  { label: 'Macieira De Cambra', value: 'Macieira De Cambra' },
  { label: 'Macieira De Rates', value: 'Macieira De Rates' },
  { label: 'Maciejowice', value: 'Maciejowice' },
  { label: 'Măcin', value: 'Măcin' },
  { label: 'Macín Chico', value: 'Macín Chico' },
  { label: 'Macine-Borgo Loreto', value: 'Macine-Borgo Loreto' },
  { label: 'Mack', value: 'Mack' },
  { label: 'Maçka', value: 'Maçka' },
  { label: 'Mackay', value: 'Mackay' },
  { label: 'Mackay City', value: 'Mackay City' },
  { label: 'Mackenbach', value: 'Mackenbach' },
  { label: 'Mackenzie', value: 'Mackenzie' },
  { label: 'Mackenzie', value: 'Mackenzie' },
  { label: 'Mackenzie District', value: 'Mackenzie District' },
  { label: 'Mackfield', value: 'Mackfield' },
  { label: 'Mackinac County', value: 'Mackinac County' },
  { label: 'Mackinaw', value: 'Mackinaw' },
  { label: 'Macklin', value: 'Macklin' },
  { label: 'Macknie', value: 'Macknie' },
  { label: 'Mačkovec', value: 'Mačkovec' },
  { label: 'Macksville', value: 'Macksville' },
  { label: 'Maclas', value: 'Maclas' },
  { label: 'Maclean', value: 'Maclean' },
  { label: 'Macleay Island', value: 'Macleay Island' },
  { label: 'Macleod', value: 'Macleod' },
  { label: 'Maclodio', value: 'Maclodio' },
  { label: 'Maclovio Herrera', value: 'Maclovio Herrera' },
  { label: 'Maclovio Herrera (Colonia Aviación)', value: 'Maclovio Herrera (Colonia Aviación)' },
  { label: 'Maclovio Rojas', value: 'Maclovio Rojas' },
  { label: 'Macmasters Beach', value: 'Macmasters Beach' },
  { label: 'Macmerry', value: 'Macmerry' },
  { label: 'Maco', value: 'Maco' },
  { label: 'Maco', value: 'Maco' },
  { label: 'Maco', value: 'Maco' },
  { label: 'Macomb', value: 'Macomb' },
  { label: 'Macomb County', value: 'Macomb County' },
  { label: 'Macomer', value: 'Macomer' },
  { label: 'Macon', value: 'Macon' },
  { label: 'Macon', value: 'Macon' },
  { label: 'Macon', value: 'Macon' },
  { label: 'Macon', value: 'Macon' },
  { label: 'Mâcon', value: 'Mâcon' },
  { label: 'Macon County', value: 'Macon County' },
  { label: 'Macon County', value: 'Macon County' },
  { label: 'Macon County', value: 'Macon County' },
  { label: 'Macon County', value: 'Macon County' },
  { label: 'Macon County', value: 'Macon County' },
  { label: 'Macon County', value: 'Macon County' },
  { label: 'Maconacon', value: 'Maconacon' },
  { label: 'Macotera', value: 'Macotera' },
  { label: 'Mâcot-La-Plagne', value: 'Mâcot-La-Plagne' },
  { label: 'Macoupin County', value: 'Macoupin County' },
  { label: 'Macquarie', value: 'Macquarie' },
  { label: 'Macquarie Fields', value: 'Macquarie Fields' },
  { label: 'Macquarie Hills', value: 'Macquarie Hills' },
  { label: 'Macquarie Links', value: 'Macquarie Links' },
  { label: 'Macquarie Park', value: 'Macquarie Park' },
  { label: 'Macra', value: 'Macra' },
  { label: 'Macrohon', value: 'Macrohon' },
  { label: 'Macrohon', value: 'Macrohon' },
  { label: 'Macroom', value: 'Macroom' },
  { label: 'Mactún', value: 'Mactún' },
  { label: 'Macuata Province', value: 'Macuata Province' },
  { label: 'Macuco', value: 'Macuco' },
  { label: 'Macuelizo', value: 'Macuelizo' },
  { label: 'Macuelizo', value: 'Macuelizo' },
  { label: 'Macugnaga', value: 'Macugnaga' },
  { label: 'Macuilxóchitl De Artigas Carranza', value: 'Macuilxóchitl De Artigas Carranza' },
  { label: 'Macul', value: 'Macul' },
  { label: 'Macultepec', value: 'Macultepec' },
  { label: 'Macungie', value: 'Macungie' },
  { label: 'Macururé', value: 'Macururé' },
  { label: 'Macusani', value: 'Macusani' },
  { label: 'Macuspana', value: 'Macuspana' },
  { label: 'Maçussa', value: 'Maçussa' },
  { label: 'Macutzio', value: 'Macutzio' },
  { label: 'Mačva', value: 'Mačva' },
  { label: 'Mačvanska Mitrovica', value: 'Mačvanska Mitrovica' },
  { label: 'Macvilhó', value: 'Macvilhó' },
  { label: 'Macy', value: 'Macy' },
  { label: 'Mád', value: 'Mád' },
  { label: 'Mādabā', value: 'Mādabā' },
  { label: 'Madagali', value: 'Madagali' },
  { label: 'Madagh', value: 'Madagh' },
  { label: 'Madala', value: 'Madala' },
  { label: 'Madalag', value: 'Madalag' },
  { label: 'Madalena', value: 'Madalena' },
  { label: 'Madalena', value: 'Madalena' },
  { label: 'Madalena', value: 'Madalena' },
  { label: 'Madalena', value: 'Madalena' },
  { label: 'Madalena', value: 'Madalena' },
  { label: 'Madalum', value: 'Madalum' },
  { label: 'Madalum', value: 'Madalum' },
  { label: 'Madamba', value: 'Madamba' },
  { label: 'Madamba', value: 'Madamba' },
  { label: 'Madambakkam', value: 'Madambakkam' },
  { label: 'Madan', value: 'Madan' },
  { label: 'Madanapalle', value: 'Madanapalle' },
  { label: 'Madang', value: 'Madang' },
  { label: 'Madanpur', value: 'Madanpur' },
  { label: 'Madaoua', value: 'Madaoua' },
  { label: 'Madara', value: 'Madara' },
  { label: 'Madaras', value: 'Madaras' },
  { label: 'Mădăras', value: 'Mădăras' },
  { label: 'Mădăraş', value: 'Mădăraş' },
  { label: 'Mădăraș', value: 'Mădăraș' },
  { label: 'Mădăraș', value: 'Mădăraș' },
  { label: 'Mădărășeni', value: 'Mădărășeni' },
  { label: 'Madarcos', value: 'Madarcos' },
  { label: 'Madaripur ', value: 'Madaripur ' },
  { label: 'Mădârjac', value: 'Mădârjac' },
  { label: 'Madarounfa', value: 'Madarounfa' },
  { label: 'Madavan', value: 'Madavan' },
  { label: 'Madawala', value: 'Madawala' },
  { label: 'Madawaska', value: 'Madawaska' },
  { label: 'Madbury', value: 'Madbury' },
  { label: 'Maddagiri', value: 'Maddagiri' },
  { label: 'Maddaloni', value: 'Maddaloni' },
  { label: 'Maddarulug Norte', value: 'Maddarulug Norte' },
  { label: 'Maddarulug Norte', value: 'Maddarulug Norte' },
  { label: 'Maddela', value: 'Maddela' },
  { label: 'Maddela', value: 'Maddela' },
  { label: 'Maddingley', value: 'Maddingley' },
  { label: 'Maddington', value: 'Maddington' },
  { label: 'Maddiston', value: 'Maddiston' },
  { label: 'Maddur', value: 'Maddur' },
  { label: 'Made', value: 'Made' },
  { label: 'Madeira', value: 'Madeira' },
  { label: 'Madeira Beach', value: 'Madeira Beach' },
  { label: 'Madeiro', value: 'Madeiro' },
  { label: 'Madeji', value: 'Madeji' },
  { label: 'Madeley', value: 'Madeley' },
  { label: 'Madeley', value: 'Madeley' },
  { label: 'Madelia', value: 'Madelia' },
  { label: 'Maden', value: 'Maden' },
  { label: 'Mäder', value: 'Mäder' },
  { label: 'Madera', value: 'Madera' },
  { label: 'Madera', value: 'Madera' },
  { label: 'Madera Acres', value: 'Madera Acres' },
  { label: 'Madera County', value: 'Madera County' },
  { label: 'Maderal El', value: 'Maderal El' },
  { label: 'Măderat', value: 'Măderat' },
  { label: 'Maderuelo', value: 'Maderuelo' },
  { label: 'Madgaon', value: 'Madgaon' },
  { label: 'Madḩā’ Al Jadīdah', value: 'Madḩā’ Al Jadīdah' },
  { label: 'Madhavpur Ghed', value: 'Madhavpur Ghed' },
  { label: 'Madhepura', value: 'Madhepura' },
  { label: 'Madhi', value: 'Madhi' },
  { label: 'Madhoganj', value: 'Madhoganj' },
  { label: 'Madhogarh', value: 'Madhogarh' },
  { label: 'Madhogarh', value: 'Madhogarh' },
  { label: 'Madhubani', value: 'Madhubani' },
  { label: 'Madhupur', value: 'Madhupur' },
  { label: 'Madhyamgram', value: 'Madhyamgram' },
  { label: 'Madignano', value: 'Madignano' },
  { label: 'Madikeri', value: 'Madikeri' },
  { label: 'Madill', value: 'Madill' },
  { label: 'Madimba', value: 'Madimba' },
  { label: 'Madina', value: 'Madina' },
  { label: 'Madīnat ‘Īsá', value: 'Madīnat ‘Īsá' },
  { label: 'Madīnat Ash Shamāl', value: 'Madīnat Ash Shamāl' },
  { label: 'Madīnat Ḩamad', value: 'Madīnat Ḩamad' },
  { label: 'Madīnat Sittah Uktūbar', value: 'Madīnat Sittah Uktūbar' },
  { label: 'Madingou', value: 'Madingou' },
  { label: 'Madipakkam', value: 'Madipakkam' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison', value: 'Madison' },
  { label: 'Madison Center', value: 'Madison Center' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison County', value: 'Madison County' },
  { label: 'Madison Heights', value: 'Madison Heights' },
  { label: 'Madison Heights', value: 'Madison Heights' },
  { label: 'Madison Lake', value: 'Madison Lake' },
  { label: 'Madison Parish', value: 'Madison Parish' },
  { label: 'Madison Park', value: 'Madison Park' },
  { label: 'Madisonville', value: 'Madisonville' },
  { label: 'Madisonville', value: 'Madisonville' },
  { label: 'Madisonville', value: 'Madisonville' },
  { label: 'Madiswil', value: 'Madiswil' },
  { label: 'Madiun', value: 'Madiun' },
  { label: 'Madjeouéni', value: 'Madjeouéni' },
  { label: 'Madliena', value: 'Madliena' },
  { label: 'Madoc', value: 'Madoc' },
  { label: 'Madocsa', value: 'Madocsa' },
  { label: 'Madolenihm Municipality', value: 'Madolenihm Municipality' },
  { label: 'Madona', value: 'Madona' },
  { label: 'Madone', value: 'Madone' },
  { label: 'Madonna Del Sasso', value: 'Madonna Del Sasso' },
  { label: 'Madonna Dellacqua', value: 'Madonna Dellacqua' },
  { label: 'Madonna Dellalbero', value: 'Madonna Dellalbero' },
  { label: 'Madora Bay', value: 'Madora Bay' },
  { label: 'Madrakah', value: 'Madrakah' },
  { label: 'Madrano-Canzolino', value: 'Madrano-Canzolino' },
  { label: 'Madras', value: 'Madras' },
  { label: 'Madras', value: 'Madras' },
  { label: 'Madre De Deus', value: 'Madre De Deus' },
  { label: 'Madre De Deus De Minas', value: 'Madre De Deus De Minas' },
  { label: 'Madremanya', value: 'Madremanya' },
  { label: 'Madrid', value: 'Madrid' },
  { label: 'Madrid', value: 'Madrid' },
  { label: 'Madrid', value: 'Madrid' },
  { label: 'Madrid', value: 'Madrid' },
  { label: 'Madrid', value: 'Madrid' },
  { label: 'Madrid', value: 'Madrid' },
  { label: 'Madridanos', value: 'Madridanos' },
  { label: 'Madridejos', value: 'Madridejos' },
  { label: 'Madridejos', value: 'Madridejos' },
  { label: 'Madrigal De La Vera', value: 'Madrigal De La Vera' },
  { label: 'Madrigal De Las Altas Torres', value: 'Madrigal De Las Altas Torres' },
  { label: 'Madrigal Del Monte', value: 'Madrigal Del Monte' },
  { label: 'Madrigalejo', value: 'Madrigalejo' },
  { label: 'Madrigalejo Del Monte', value: 'Madrigalejo Del Monte' },
  { label: 'Madrigueras', value: 'Madrigueras' },
  { label: 'Madroñal', value: 'Madroñal' },
  { label: 'Madroñera', value: 'Madroñera' },
  { label: 'Madroño El', value: 'Madroño El' },
  { label: 'Madruga', value: 'Madruga' },
  { label: 'Madsus', value: 'Madsus' },
  { label: 'Maduao', value: 'Maduao' },
  { label: 'Maduao', value: 'Maduao' },
  { label: 'Maduao', value: 'Maduao' },
  { label: 'Madugula', value: 'Madugula' },
  { label: 'Madukkarai', value: 'Madukkarai' },
  { label: 'Madukkur', value: 'Madukkur' },
  { label: 'Madulao', value: 'Madulao' },
  { label: 'Mădulari', value: 'Mădulari' },
  { label: 'Madurai', value: 'Madurai' },
  { label: 'Madurantakam', value: 'Madurantakam' },
  { label: 'Madzhalis', value: 'Madzhalis' },
  { label: 'Madzharovo', value: 'Madzharovo' },
  { label: 'Mae Chai', value: 'Mae Chai' },
  { label: 'Mae Chan', value: 'Mae Chan' },
  { label: 'Mãe Dágua', value: 'Mãe Dágua' },
  { label: 'Mãe Do Rio', value: 'Mãe Do Rio' },
  { label: 'Mae Hong Son', value: 'Mae Hong Son' },
  { label: 'Mae Ramat', value: 'Mae Ramat' },
  { label: 'Mae Sai', value: 'Mae Sai' },
  { label: 'Mae Sot', value: 'Mae Sot' },
  { label: 'Mae Tha', value: 'Mae Tha' },
  { label: 'Maebaru-Chūō', value: 'Maebaru-Chūō' },
  { label: 'Maebashi', value: 'Maebashi' },
  { label: 'Maebashi Shi', value: 'Maebashi Shi' },
  { label: 'Maël-Carhaix', value: 'Maël-Carhaix' },
  { label: 'Maella', value: 'Maella' },
  { label: 'Maello', value: 'Maello' },
  { label: 'Maenza', value: 'Maenza' },
  { label: 'Măerişte', value: 'Măerişte' },
  { label: 'Maerne', value: 'Maerne' },
  { label: 'Maeser', value: 'Maeser' },
  { label: 'Maesteg', value: 'Maesteg' },
  { label: 'Maetinga', value: 'Maetinga' },
  { label: 'Mafafas', value: 'Mafafas' },
  { label: 'Mafalda', value: 'Mafalda' },
  { label: 'Mafeteng', value: 'Mafeteng' },
  { label: 'Maffliers', value: 'Maffliers' },
  { label: 'Maffra', value: 'Maffra' },
  { label: 'Máfil', value: 'Máfil' },
  { label: 'Mafinga', value: 'Mafinga' },
  { label: 'Mafra', value: 'Mafra' },
  { label: 'Mafra', value: 'Mafra' },
  { label: 'Mafra', value: 'Mafra' },
  { label: 'Mafraq', value: 'Mafraq' },
  { label: 'Mafruq', value: 'Mafruq' },
  { label: 'Magacela', value: 'Magacela' },
  { label: 'Magadan', value: 'Magadan' },
  { label: 'Magadenovac', value: 'Magadenovac' },
  { label: 'Magadi', value: 'Magadi' },
  { label: 'Magadi', value: 'Magadi' },
  { label: 'Magadino', value: 'Magadino' },
  { label: 'Magalalag', value: 'Magalalag' },
  { label: 'Magalalag', value: 'Magalalag' },
  { label: 'Magalang', value: 'Magalang' },
  { label: 'Magalang', value: 'Magalang' },
  { label: 'Magalas', value: 'Magalas' },
  { label: 'Magalhães Barata', value: 'Magalhães Barata' },
  { label: 'Magalhães De Almeida', value: 'Magalhães De Almeida' },
  { label: 'Magalia', value: 'Magalia' },
  { label: 'Magallanes', value: 'Magallanes' },
  { label: 'Magallanes', value: 'Magallanes' },
  { label: 'Magallanes', value: 'Magallanes' },
  { label: 'Magallanes', value: 'Magallanes' },
  { label: 'Magallanes', value: 'Magallanes' },
  { label: 'Magallanes', value: 'Magallanes' },
  { label: 'Magallón', value: 'Magallón' },
  { label: 'Magallon Cadre', value: 'Magallon Cadre' },
  { label: 'Magaluf', value: 'Magaluf' },
  { label: 'Magam', value: 'Magam' },
  { label: 'Magan', value: 'Magan' },
  { label: 'Magán', value: 'Magán' },
  { label: 'Magaña', value: 'Magaña' },
  { label: 'Magangué', value: 'Magangué' },
  { label: 'Maganier', value: 'Maganier' },
  { label: 'Maganoy', value: 'Maganoy' },
  { label: 'Magapit', value: 'Magapit' },
  { label: 'Magapit Aguiguican', value: 'Magapit Aguiguican' },
  { label: 'Magaramkent', value: 'Magaramkent' },
  { label: 'Magaramkentskiy Rayon', value: 'Magaramkentskiy Rayon' },
  { label: 'Magarao', value: 'Magarao' },
  { label: 'Magaria', value: 'Magaria' },
  { label: 'Magas', value: 'Magas' },
  { label: 'Magas Arriba', value: 'Magas Arriba' },
  { label: 'Magasa', value: 'Magasa' },
  { label: 'Magatos', value: 'Magatos' },
  { label: 'Magatos', value: 'Magatos' },
  { label: 'Magatos', value: 'Magatos' },
  { label: 'Magay', value: 'Magay' },
  { label: 'Magaz De Cepeda', value: 'Magaz De Cepeda' },
  { label: 'Magaz De Pisuerga', value: 'Magaz De Pisuerga' },
  { label: 'Magazinka', value: 'Magazinka' },
  { label: 'Magbay', value: 'Magbay' },
  { label: 'Magburaka', value: 'Magburaka' },
  { label: 'Magda', value: 'Magda' },
  { label: 'Magdagachi', value: 'Magdagachi' },
  { label: 'Magdagachinskiy Rayon', value: 'Magdagachinskiy Rayon' },
  { label: 'Magdala', value: 'Magdala' },
  { label: 'Magdalena', value: 'Magdalena' },
  { label: 'Magdalena', value: 'Magdalena' },
  { label: 'Magdalena', value: 'Magdalena' },
  { label: 'Magdalena', value: 'Magdalena' },
  { label: 'Magdalena', value: 'Magdalena' },
  { label: 'Magdalena', value: 'Magdalena' },
  { label: 'Magdalena', value: 'Magdalena' },
  { label: 'Magdalena Apasco', value: 'Magdalena Apasco' },
  { label: 'Magdalena Atlicpac', value: 'Magdalena Atlicpac' },
  { label: 'Magdalena Contreras', value: 'Magdalena Contreras' },
  { label: 'Magdalena Cuayucatepec', value: 'Magdalena Cuayucatepec' },
  { label: 'Magdalena De Araceo', value: 'Magdalena De Araceo' },
  { label: 'Magdalena De Kino', value: 'Magdalena De Kino' },
  { label: 'Magdalena Jaltepec', value: 'Magdalena Jaltepec' },
  { label: 'Magdalena Milpas Altas', value: 'Magdalena Milpas Altas' },
  { label: 'Magdalena Mixtepec', value: 'Magdalena Mixtepec' },
  { label: 'Magdalena Ocotlán', value: 'Magdalena Ocotlán' },
  { label: 'Magdalena Teitipac', value: 'Magdalena Teitipac' },
  { label: 'Magdalena Tequisistlán', value: 'Magdalena Tequisistlán' },
  { label: 'Magdalena Tlacotepec', value: 'Magdalena Tlacotepec' },
  { label: 'Magdalena Yodocono De Porfirio Díaz', value: 'Magdalena Yodocono De Porfirio Díaz' },
  { label: 'Magdalena Zahuatlán', value: 'Magdalena Zahuatlán' },
  { label: 'Magdaleno Cedillo', value: 'Magdaleno Cedillo' },
  { label: 'Magdalensberg', value: 'Magdalensberg' },
  { label: 'Magdeburg', value: 'Magdeburg' },
  { label: 'Magden', value: 'Magden' },
  { label: 'Magdiwang', value: 'Magdiwang' },
  { label: 'Magdug', value: 'Magdug' },
  { label: 'Magdug', value: 'Magdug' },
  { label: 'Magdug', value: 'Magdug' },
  { label: 'Magé', value: 'Magé' },
  { label: 'Magee', value: 'Magee' },
  { label: 'Magelang', value: 'Magelang' },
  { label: 'Magele', value: 'Magele' },
  { label: 'Magenta', value: 'Magenta' },
  { label: 'Magenta', value: 'Magenta' },
  { label: 'Magescq', value: 'Magescq' },
  { label: 'Măgeşti', value: 'Măgeşti' },
  { label: 'Maggie Valley', value: 'Maggie Valley' },
  { label: 'Maggiora', value: 'Maggiora' },
  { label: 'Maggotty', value: 'Maggotty' },
  { label: 'Maghar', value: 'Maghar' },
  { label: 'Maghār', value: 'Maghār' },
  { label: 'Maghera', value: 'Maghera' },
  { label: 'Magherafelt', value: 'Magherafelt' },
  { label: 'Magheralin', value: 'Magheralin' },
  { label: 'Măgherani', value: 'Măgherani' },
  { label: 'Magherno', value: 'Magherno' },
  { label: 'Măgheruș', value: 'Măgheruș' },
  { label: 'Maghirib Ans', value: 'Maghirib Ans' },
  { label: 'Magħtab', value: 'Magħtab' },
  { label: 'Maghull', value: 'Maghull' },
  { label: 'Magill', value: 'Magill' },
  { label: 'Maginsk', value: 'Maginsk' },
  { label: 'Magione', value: 'Magione' },
  { label: 'Măgireşti', value: 'Măgireşti' },
  { label: 'Magisano', value: 'Magisano' },
  { label: 'Magisterio Digno', value: 'Magisterio Digno' },
  { label: 'Magistral’Nyy', value: 'Magistral’Nyy' },
  { label: 'Maglaj', value: 'Maglaj' },
  { label: 'Maglajani', value: 'Maglajani' },
  { label: 'Maglamin', value: 'Maglamin' },
  { label: 'Magland', value: 'Magland' },
  { label: 'Maglavit', value: 'Maglavit' },
  { label: 'Magliano', value: 'Magliano' },
  { label: 'Magliano Alfieri', value: 'Magliano Alfieri' },
  { label: 'Magliano Alpi', value: 'Magliano Alpi' },
  { label: 'Magliano Demarsi', value: 'Magliano Demarsi' },
  { label: 'Magliano Di Tenna', value: 'Magliano Di Tenna' },
  { label: 'Magliano In Toscana', value: 'Magliano In Toscana' },
  { label: 'Magliano Romano', value: 'Magliano Romano' },
  { label: 'Magliano Sabina', value: 'Magliano Sabina' },
  { label: 'Magliano Vetere', value: 'Magliano Vetere' },
  { label: 'Maglić', value: 'Maglić' },
  { label: 'Maglie', value: 'Maglie' },
  { label: 'Magliman', value: 'Magliman' },
  { label: 'Magliman', value: 'Magliman' },
  { label: 'Magliolo', value: 'Magliolo' },
  { label: 'Maglione', value: 'Maglione' },
  { label: 'Maglione-Crosa', value: 'Maglione-Crosa' },
  { label: 'Maglizh', value: 'Maglizh' },
  { label: 'Maglód', value: 'Maglód' },
  { label: 'Magna', value: 'Magna' },
  { label: 'Magnacavallo', value: 'Magnacavallo' },
  { label: 'Magnac-Laval', value: 'Magnac-Laval' },
  { label: 'Magnac-Sur-Touvre', value: 'Magnac-Sur-Touvre' },
  { label: 'Magnaga', value: 'Magnaga' },
  { label: 'Magnaga', value: 'Magnaga' },
  { label: 'Magnaga', value: 'Magnaga' },
  { label: 'Magnago', value: 'Magnago' },
  { label: 'Magnano', value: 'Magnano' },
  { label: 'Magnano In Riviera', value: 'Magnano In Riviera' },
  { label: 'Magnanville', value: 'Magnanville' },
  { label: 'Magnassini-Nindri', value: 'Magnassini-Nindri' },
  { label: 'Magné', value: 'Magné' },
  { label: 'Magnetic Island', value: 'Magnetic Island' },
  { label: 'Magnitka', value: 'Magnitka' },
  { label: 'Magnitogorsk', value: 'Magnitogorsk' },
  { label: 'Magno', value: 'Magno' },
  { label: 'Magnolia', value: 'Magnolia' },
  { label: 'Magnolia', value: 'Magnolia' },
  { label: 'Magnolia', value: 'Magnolia' },
  { label: 'Magnolia', value: 'Magnolia' },
  { label: 'Magny-Cours', value: 'Magny-Cours' },
  { label: 'Magny-En-Vexin', value: 'Magny-En-Vexin' },
  { label: 'Magny-Le-Désert', value: 'Magny-Le-Désert' },
  { label: 'Magny-Le-Hongre', value: 'Magny-Le-Hongre' },
  { label: 'Magny-Les-Hameaux', value: 'Magny-Les-Hameaux' },
  { label: 'Magny-Vernois', value: 'Magny-Vernois' },
  { label: 'Mago', value: 'Mago' },
  { label: 'Mágocs', value: 'Mágocs' },
  { label: 'Magoffin County', value: 'Magoffin County' },
  { label: 'Magog', value: 'Magog' },
  { label: 'Magole', value: 'Magole' },
  { label: 'Magomadas', value: 'Magomadas' },
  { label: 'Magomeni', value: 'Magomeni' },
  { label: 'Magomeni', value: 'Magomeni' },
  { label: 'Magong', value: 'Magong' },
  { label: 'Magor', value: 'Magor' },
  { label: 'Magoúla', value: 'Magoúla' },
  { label: 'Magoúla', value: 'Magoúla' },
  { label: 'Magoúla', value: 'Magoúla' },
  { label: 'Magoúla', value: 'Magoúla' },
  { label: 'Magpet', value: 'Magpet' },
  { label: 'Magpet', value: 'Magpet' },
  { label: 'Magrath', value: 'Magrath' },
  { label: 'Magrè Sulla Strada Del Vino', value: 'Magrè Sulla Strada Del Vino' },
  { label: 'Magreglio', value: 'Magreglio' },
  { label: 'Magreta', value: 'Magreta' },
  { label: 'Magsalangi', value: 'Magsalangi' },
  { label: 'Magsaysay', value: 'Magsaysay' },
  { label: 'Magsaysay', value: 'Magsaysay' },
  { label: 'Magsaysay', value: 'Magsaysay' },
  { label: 'Magsaysay', value: 'Magsaysay' },
  { label: 'Magsaysay', value: 'Magsaysay' },
  { label: 'Magsaysay', value: 'Magsaysay' },
  { label: 'Magsaysay', value: 'Magsaysay' },
  { label: 'Magsingal', value: 'Magsingal' },
  { label: 'Magsingal', value: 'Magsingal' },
  { label: 'Magstadt', value: 'Magstadt' },
  { label: 'Magtaking', value: 'Magtaking' },
  { label: 'Magtaking', value: 'Magtaking' },
  { label: 'Magtangol', value: 'Magtangol' },
  { label: 'Magtangol', value: 'Magtangol' },
  { label: 'Magtymguly', value: 'Magtymguly' },
  { label: 'Maguarichi', value: 'Maguarichi' },
  { label: 'Magude District', value: 'Magude District' },
  { label: 'Maguey Blanco', value: 'Maguey Blanco' },
  { label: 'Magugnano', value: 'Magugnano' },
  { label: 'Magugpo Poblacion', value: 'Magugpo Poblacion' },
  { label: 'Magugu', value: 'Magugu' },
  { label: 'Magüí Payán', value: 'Magüí Payán' },
  { label: 'Maguilla', value: 'Maguilla' },
  { label: 'Maguilling', value: 'Maguilling' },
  { label: 'Maguilling', value: 'Maguilling' },
  { label: 'Maguinao', value: 'Maguinao' },
  { label: 'Maguinao', value: 'Maguinao' },
  { label: 'Maguing', value: 'Maguing' },
  { label: 'Magula', value: 'Magula' },
  { label: 'Maguling', value: 'Maguling' },
  { label: 'Maguling', value: 'Maguling' },
  { label: 'Magumeri', value: 'Magumeri' },
  { label: 'Măgura', value: 'Măgura' },
  { label: 'Măgura', value: 'Măgura' },
  { label: 'Măgura', value: 'Măgura' },
  { label: 'Magura ', value: 'Magura ' },
  { label: 'Măgura Ilvei', value: 'Măgura Ilvei' },
  { label: 'Măgurele', value: 'Măgurele' },
  { label: 'Măgurele', value: 'Măgurele' },
  { label: 'Măgureni', value: 'Măgureni' },
  { label: 'Măgureni', value: 'Măgureni' },
  { label: 'Măguri', value: 'Măguri' },
  { label: 'Măguri-Răcătău', value: 'Măguri-Răcătău' },
  { label: 'Maguyam', value: 'Maguyam' },
  { label: 'Magway', value: 'Magway' },
  { label: 'Magyarbánhegyes', value: 'Magyarbánhegyes' },
  { label: 'Mah Velat', value: 'Mah Velat' },
  { label: 'Maha Sarakham', value: 'Maha Sarakham' },
  { label: 'Mahaba', value: 'Mahaba' },
  { label: 'Mahabad', value: 'Mahabad' },
  { label: 'Mahabad', value: 'Mahabad' },
  { label: 'Mahabaleshwar', value: 'Mahabaleshwar' },
  { label: 'Mahaban', value: 'Mahaban' },
  { label: 'Mahabang Parang', value: 'Mahabang Parang' },
  { label: 'Mahabubabad', value: 'Mahabubabad' },
  { label: 'Mahad', value: 'Mahad' },
  { label: 'Mahaddayweyne', value: 'Mahaddayweyne' },
  { label: 'Mahagnao', value: 'Mahagnao' },
  { label: 'Mahaica Village', value: 'Mahaica Village' },
  { label: 'Mahaicony Village', value: 'Mahaicony Village' },
  { label: 'Mahala', value: 'Mahala' },
  { label: 'Mahala', value: 'Mahala' },
  { label: 'Mahala', value: 'Mahala' },
  { label: 'Mahalapye', value: 'Mahalapye' },
  { label: 'Mahalat', value: 'Mahalat' },
  { label: 'Mahalingpur', value: 'Mahalingpur' },
  { label: 'Mahallat', value: 'Mahallat' },
  { label: 'Maham', value: 'Maham' },
  { label: 'Mahamud', value: 'Mahamud' },
  { label: 'Mahan', value: 'Mahan' },
  { label: 'Mahanabad', value: 'Mahanabad' },
  { label: 'Mahanje', value: 'Mahanje' },
  { label: 'Mahanob', value: 'Mahanob' },
  { label: 'Mahanob', value: 'Mahanob' },
  { label: 'Mahanob', value: 'Mahanob' },
  { label: 'Mahanoro', value: 'Mahanoro' },
  { label: 'Mahanoy City', value: 'Mahanoy City' },
  { label: 'Mahape', value: 'Mahape' },
  { label: 'Mahaplag', value: 'Mahaplag' },
  { label: 'Mahaplag', value: 'Mahaplag' },
  { label: 'Maharagama', value: 'Maharagama' },
  { label: 'Maharajgani', value: 'Maharajgani' },
  { label: 'Maharajganj', value: 'Maharajganj' },
  { label: 'Maharishi Vedic City', value: 'Maharishi Vedic City' },
  { label: 'Mahasamund', value: 'Mahasamund' },
  { label: 'Mahaska County', value: 'Mahaska County' },
  { label: 'Mahatah', value: 'Mahatah' },
  { label: 'Mahatao', value: 'Mahatao' },
  { label: 'Mahatao', value: 'Mahatao' },
  { label: 'Mahates', value: 'Mahates' },
  { label: 'Mahattat Al Hafah', value: 'Mahattat Al Hafah' },
  { label: 'Mahaut', value: 'Mahaut' },
  { label: 'Mahaut', value: 'Mahaut' },
  { label: 'Mahavelona', value: 'Mahavelona' },
  { label: 'Mahayag', value: 'Mahayag' },
  { label: 'Mahayag', value: 'Mahayag' },
  { label: 'Mahayag', value: 'Mahayag' },
  { label: 'Mahayag', value: 'Mahayag' },
  { label: 'Mahayag', value: 'Mahayag' },
  { label: 'Mahbar', value: 'Mahbar' },
  { label: 'Mahbubnagar', value: 'Mahbubnagar' },
  { label: 'Mahdalynivka', value: 'Mahdalynivka' },
  { label: 'Mahdalynivs’Kyy Rayon', value: 'Mahdalynivs’Kyy Rayon' },
  { label: 'Mahdasht', value: 'Mahdasht' },
  { label: 'Mahdaviyeh', value: 'Mahdaviyeh' },
  { label: 'Mahdi Shahr', value: 'Mahdi Shahr' },
  { label: 'Mahdia', value: 'Mahdia' },
  { label: 'Mahdia', value: 'Mahdia' },
  { label: 'Mahdishahr', value: 'Mahdishahr' },
  { label: 'Mahe', value: 'Mahe' },
  { label: 'Mahe', value: 'Mahe' },
  { label: 'Mahébourg', value: 'Mahébourg' },
  { label: 'Mahemdavad', value: 'Mahemdavad' },
  { label: 'Mahendragarh', value: 'Mahendragarh' },
  { label: 'Mahenge', value: 'Mahenge' },
  { label: 'Mahesana', value: 'Mahesana' },
  { label: 'Maheshwar', value: 'Maheshwar' },
  { label: 'Mahgawan', value: 'Mahgawan' },
  { label: 'Mahiari', value: 'Mahiari' },
  { label: 'Mahibadhoo', value: 'Mahibadhoo' },
  { label: 'Mahide', value: 'Mahide' },
  { label: 'Mahikeng', value: 'Mahikeng' },
  { label: 'Mahilyow', value: 'Mahilyow' },
  { label: 'Mahilyowski Rayon', value: 'Mahilyowski Rayon' },
  { label: 'Mahim', value: 'Mahim' },
  { label: 'Mahinog', value: 'Mahinog' },
  { label: 'Mahisa', value: 'Mahisa' },
  { label: 'Mahishadal Community Development Block', value: 'Mahishadal Community Development Block' },
  { label: 'Mahlberg', value: 'Mahlberg' },
  { label: 'Mahliyah', value: 'Mahliyah' },
  { label: 'Mahlsdorf', value: 'Mahlsdorf' },
  { label: 'Mahmood Abad Nemooneh', value: 'Mahmood Abad Nemooneh' },
  { label: 'Mahmoodabad', value: 'Mahmoodabad' },
  { label: 'Mahmudabad', value: 'Mahmudabad' },
  { label: 'Mahmudabad', value: 'Mahmudabad' },
  { label: 'Mahmudia', value: 'Mahmudia' },
  { label: 'Mahmudiye', value: 'Mahmudiye' },
  { label: 'Mahneshan', value: 'Mahneshan' },
  { label: 'Mahnian', value: 'Mahnian' },
  { label: 'Mahnomen', value: 'Mahnomen' },
  { label: 'Mahnomen County', value: 'Mahnomen County' },
  { label: 'Mahoba', value: 'Mahoba' },
  { label: 'Maholi', value: 'Maholi' },
  { label: 'Mahomet', value: 'Mahomet' },
  { label: 'Mahonda', value: 'Mahonda' },
  { label: 'Mahoning County', value: 'Mahoning County' },
  { label: 'Mahopac', value: 'Mahopac' },
  { label: 'Mahora', value: 'Mahora' },
  { label: 'Mahottari ', value: 'Mahottari ' },
  { label: 'Mahrajganj', value: 'Mahrajganj' },
  { label: 'Mahrajganj (Raebareli)', value: 'Mahrajganj (Raebareli)' },
  { label: 'Mähring', value: 'Mähring' },
  { label: 'Mahriz', value: 'Mahriz' },
  { label: 'Mahroni', value: 'Mahroni' },
  { label: 'Mahtomedi', value: 'Mahtomedi' },
  { label: 'Mahudha', value: 'Mahudha' },
  { label: 'Mahuixtlan', value: 'Mahuixtlan' },
  { label: 'Mahul', value: 'Mahul' },
  { label: 'Mahur', value: 'Mahur' },
  { label: 'Mahuta', value: 'Mahuta' },
  { label: 'Mahuta', value: 'Mahuta' },
  { label: 'Mahuva', value: 'Mahuva' },
  { label: 'Mahuva (Surat)', value: 'Mahuva (Surat)' },
  { label: 'Mahwah', value: 'Mahwah' },
  { label: 'Mahwah', value: 'Mahwah' },
  { label: 'Maia', value: 'Maia' },
  { label: 'Maia', value: 'Maia' },
  { label: 'Maia', value: 'Maia' },
  { label: 'Maia', value: 'Maia' },
  { label: 'Maià De Montcal', value: 'Maià De Montcal' },
  { label: 'Maiac', value: 'Maiac' },
  { label: 'Maiad', value: 'Maiad' },
  { label: 'Maials', value: 'Maials' },
  { label: 'Maiana', value: 'Maiana' },
  { label: 'Maibara', value: 'Maibara' },
  { label: 'Maibara-Shi', value: 'Maibara-Shi' },
  { label: 'Maibong', value: 'Maibong' },
  { label: 'Maibu', value: 'Maibu' },
  { label: 'Maibu', value: 'Maibu' },
  { label: 'Măicăneşti', value: 'Măicăneşti' },
  { label: 'Maicao', value: 'Maicao' },
  { label: 'Maicas', value: 'Maicas' },
  { label: 'Maîche', value: 'Maîche' },
  { label: 'Maida', value: 'Maida' },
  { label: 'Maida Vale', value: 'Maida Vale' },
  { label: 'Maiden', value: 'Maiden' },
  { label: 'Maiden Gully', value: 'Maiden Gully' },
  { label: 'Maiden Newton', value: 'Maiden Newton' },
  { label: 'Maidenbower', value: 'Maidenbower' },
  { label: 'Maidenhead', value: 'Maidenhead' },
  { label: 'Maidières', value: 'Maidières' },
  { label: 'Maidstone', value: 'Maidstone' },
  { label: 'Maidstone', value: 'Maidstone' },
  { label: 'Maidstone', value: 'Maidstone' },
  { label: 'Maiduguri', value: 'Maiduguri' },
  { label: 'Maienfeld', value: 'Maienfeld' },
  { label: 'Maierà', value: 'Maierà' },
  { label: 'Maierato', value: 'Maierato' },
  { label: 'Maierdorf', value: 'Maierdorf' },
  { label: 'Maierhöfen', value: 'Maierhöfen' },
  { label: 'Măieru', value: 'Măieru' },
  { label: 'Măieruş', value: 'Măieruş' },
  { label: 'Maignelay-Montigny', value: 'Maignelay-Montigny' },
  { label: 'Maigo', value: 'Maigo' },
  { label: 'Maihar', value: 'Maihar' },
  { label: 'Maihingen', value: 'Maihingen' },
  { label: 'Maikammer', value: 'Maikammer' },
  { label: 'Mailag', value: 'Mailag' },
  { label: 'Mailani', value: 'Mailani' },
  { label: 'Mailat', value: 'Mailat' },
  { label: 'Mailberg', value: 'Mailberg' },
  { label: 'Maile', value: 'Maile' },
  { label: 'Maillane', value: 'Maillane' },
  { label: 'Maillezais', value: 'Maillezais' },
  { label: 'Maíllo El', value: 'Maíllo El' },
  { label: 'Maillot', value: 'Maillot' },
  { label: 'Mailly Motete', value: 'Mailly Motete' },
  { label: 'Mailly-Le-Camp', value: 'Mailly-Le-Camp' },
  { label: 'Mailsi', value: 'Mailsi' },
  { label: 'Maimará', value: 'Maimará' },
  { label: 'Maimbung', value: 'Maimbung' },
  { label: 'Maimbung', value: 'Maimbung' },
  { label: 'Maimón', value: 'Maimón' },
  { label: 'Main Beach', value: 'Main Beach' },
  { label: 'Mainaguri', value: 'Mainaguri' },
  { label: 'Mainar', value: 'Mainar' },
  { label: 'Mainaschaff', value: 'Mainaschaff' },
  { label: 'Mainbernheim', value: 'Mainbernheim' },
  { label: 'Mainburg', value: 'Mainburg' },
  { label: 'Maincy', value: 'Maincy' },
  { label: 'Maindang', value: 'Maindang' },
  { label: 'Maindang', value: 'Maindang' },
  { label: 'Maindargi', value: 'Maindargi' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Maïné Soroa', value: 'Maïné Soroa' },
  { label: 'Mainero', value: 'Mainero' },
  { label: 'Maing', value: 'Maing' },
  { label: 'Mainhardt', value: 'Mainhardt' },
  { label: 'Mainit', value: 'Mainit' },
  { label: 'Mainit', value: 'Mainit' },
  { label: 'Mainit', value: 'Mainit' },
  { label: 'Mainit', value: 'Mainit' },
  { label: 'Mainit Norte', value: 'Mainit Norte' },
  { label: 'Mainleus', value: 'Mainleus' },
  { label: 'Mainpuri', value: 'Mainpuri' },
  { label: 'Mainque', value: 'Mainque' },
  { label: 'Mainstockheim', value: 'Mainstockheim' },
  { label: 'Maintal', value: 'Maintal' },
  { label: 'Maintenon', value: 'Maintenon' },
  { label: 'Mainvilliers', value: 'Mainvilliers' },
  { label: 'Mainz', value: 'Mainz' },
  { label: 'Mainzweiler', value: 'Mainzweiler' },
  { label: 'Maiolati Spontini', value: 'Maiolati Spontini' },
  { label: 'Maiorești', value: 'Maiorești' },
  { label: 'Maiori', value: 'Maiori' },
  { label: 'Maipú', value: 'Maipú' },
  { label: 'Maiquetía', value: 'Maiquetía' },
  { label: 'Maiquinique', value: 'Maiquinique' },
  { label: 'Mairago', value: 'Mairago' },
  { label: 'Mairana', value: 'Mairana' },
  { label: 'Mairang', value: 'Mairang' },
  { label: 'Mairano', value: 'Mairano' },
  { label: 'Maire De Castroponce', value: 'Maire De Castroponce' },
  { label: 'Mairena Del Alcor', value: 'Mairena Del Alcor' },
  { label: 'Mairena Del Aljarafe', value: 'Mairena Del Aljarafe' },
  { label: 'Mairi', value: 'Mairi' },
  { label: 'Mairinque', value: 'Mairinque' },
  { label: 'Mairiporã', value: 'Mairiporã' },
  { label: 'Mairipotaba', value: 'Mairipotaba' },
  { label: 'Mairwa', value: 'Mairwa' },
  { label: 'Maisach', value: 'Maisach' },
  { label: 'Maisdon-Sur-Sèvre', value: 'Maisdon-Sur-Sèvre' },
  { label: 'Maishofen', value: 'Maishofen' },
  { label: 'Maisí', value: 'Maisí' },
  { label: 'Maisnil-Lès-Ruitz', value: 'Maisnil-Lès-Ruitz' },
  { label: 'Maisons-Alfort', value: 'Maisons-Alfort' },
  { label: 'Maisons-Laffitte', value: 'Maisons-Laffitte' },
  { label: 'Maissana', value: 'Maissana' },
  { label: 'Maissau', value: 'Maissau' },
  { label: 'Maisse', value: 'Maisse' },
  { label: 'Maitama', value: 'Maitama' },
  { label: 'Maitenbeth', value: 'Maitenbeth' },
  { label: 'Maitinez', value: 'Maitinez' },
  { label: 'Maitland', value: 'Maitland' },
  { label: 'Maitland', value: 'Maitland' },
  { label: 'Maitland', value: 'Maitland' },
  { label: 'Maitland City Centre', value: 'Maitland City Centre' },
  { label: 'Maitland Municipality', value: 'Maitland Municipality' },
  { label: 'Maitum', value: 'Maitum' },
  { label: 'Maitum', value: 'Maitum' },
  { label: 'Maiurno', value: 'Maiurno' },
  { label: 'Maiyama', value: 'Maiyama' },
  { label: 'Maizal', value: 'Maizal' },
  { label: 'Maize', value: 'Maize' },
  { label: 'Maizières-La-Grande-Paroisse', value: 'Maizières-La-Grande-Paroisse' },
  { label: 'Maizières-Lès-Metz', value: 'Maizières-Lès-Metz' },
  { label: 'Maizuru', value: 'Maizuru' },
  { label: 'Maizuru-Shi', value: 'Maizuru-Shi' },
  { label: 'Majadahonda', value: 'Majadahonda' },
  { label: 'Majadas', value: 'Majadas' },
  { label: 'Majadas Las', value: 'Majadas Las' },
  { label: 'Majaelrayo', value: 'Majaelrayo' },
  { label: 'Majagual', value: 'Majagual' },
  { label: 'Majagual', value: 'Majagual' },
  { label: 'Majalengka', value: 'Majalengka' },
  { label: 'Majalgaon', value: 'Majalgaon' },
  { label: 'Maján', value: 'Maján' },
  { label: 'Majano', value: 'Majano' },
  { label: 'Majastic', value: 'Majastic' },
  { label: 'Majayjay', value: 'Majayjay' },
  { label: 'Majdan Królewski', value: 'Majdan Królewski' },
  { label: 'Majdanpek', value: 'Majdanpek' },
  { label: 'Majenang', value: 'Majenang' },
  { label: 'Majene', value: 'Majene' },
  { label: 'Majengo', value: 'Majengo' },
  { label: 'Majestic Gardens', value: 'Majestic Gardens' },
  { label: 'Majetín', value: 'Majetín' },
  { label: 'Majholi', value: 'Majholi' },
  { label: 'Majhupur', value: 'Majhupur' },
  { label: 'Majie', value: 'Majie' },
  { label: 'Majitha', value: 'Majitha' },
  { label: 'Majlesi', value: 'Majlesi' },
  { label: 'Majomut', value: 'Majomut' },
  { label: 'Major County', value: 'Major County' },
  { label: 'Major Gercino', value: 'Major Gercino' },
  { label: 'Major Isidoro', value: 'Major Isidoro' },
  { label: 'Major Sales', value: 'Major Sales' },
  { label: 'Major Vieira', value: 'Major Vieira' },
  { label: 'Majorna', value: 'Majorna' },
  { label: 'Majšperk', value: 'Majšperk' },
  { label: 'Majur', value: 'Majur' },
  { label: 'Majz', value: 'Majz' },
  { label: 'Majzar', value: 'Majzar' },
  { label: 'Makabe', value: 'Makabe' },
  { label: 'Makadi Bay', value: 'Makadi Bay' },
  { label: 'Mākaha', value: 'Mākaha' },
  { label: 'Mākaha Valley', value: 'Mākaha Valley' },
  { label: 'Makakilo City', value: 'Makakilo City' },
  { label: 'Makaleng', value: 'Makaleng' },
  { label: 'Makali', value: 'Makali' },
  { label: 'Makallé', value: 'Makallé' },
  { label: 'Makamba', value: 'Makamba' },
  { label: 'Makanpur', value: 'Makanpur' },
  { label: 'Makanya', value: 'Makanya' },
  { label: 'Makar’Yev', value: 'Makar’Yev' },
  { label: 'Makariv', value: 'Makariv' },
  { label: 'Makariv Raion', value: 'Makariv Raion' },
  { label: 'Makariv Yar', value: 'Makariv Yar' },
  { label: 'Makarov', value: 'Makarov' },
  { label: 'Makarska', value: 'Makarska' },
  { label: 'Makary', value: 'Makary' },
  { label: 'Makassar', value: 'Makassar' },
  { label: 'Makati', value: 'Makati' },
  { label: 'Makato', value: 'Makato' },
  { label: 'Makawao', value: 'Makawao' },
  { label: 'Makedonska Kamenica', value: 'Makedonska Kamenica' },
  { label: 'Makedonski Brod', value: 'Makedonski Brod' },
  { label: 'Makeni', value: 'Makeni' },
  { label: 'Maketu', value: 'Maketu' },
  { label: 'Makhachkala', value: 'Makhachkala' },
  { label: 'Makhalino', value: 'Makhalino' },
  { label: 'Makhambet', value: 'Makhambet' },
  { label: 'Makhinjauri', value: 'Makhinjauri' },
  { label: 'Makhjan', value: 'Makhjan' },
  { label: 'Makhu', value: 'Makhu' },
  { label: 'Maki', value: 'Maki' },
  { label: 'Makilala', value: 'Makilala' },
  { label: 'Makilala', value: 'Makilala' },
  { label: 'Makin', value: 'Makin' },
  { label: 'Makin Village', value: 'Makin Village' },
  { label: 'Makinohara Shi', value: 'Makinohara Shi' },
  { label: 'Makinsk', value: 'Makinsk' },
  { label: 'Makir', value: 'Makir' },
  { label: 'Makir', value: 'Makir' },
  { label: 'Makiv', value: 'Makiv' },
  { label: 'Makiwalo', value: 'Makiwalo' },
  { label: 'Makiwalo', value: 'Makiwalo' },
  { label: 'Makiyivka', value: 'Makiyivka' },
  { label: 'Makiyivs’Ka Mis’Krada', value: 'Makiyivs’Ka Mis’Krada' },
  { label: 'Makkaveyevo', value: 'Makkaveyevo' },
  { label: 'Makkum', value: 'Makkum' },
  { label: 'Maklár', value: 'Maklár' },
  { label: 'Maklavan', value: 'Maklavan' },
  { label: 'Makó', value: 'Makó' },
  { label: 'Makobeng', value: 'Makobeng' },
  { label: 'Makói Járás', value: 'Makói Járás' },
  { label: 'Makoko', value: 'Makoko' },
  { label: 'Makokou', value: 'Makokou' },
  { label: 'Makole', value: 'Makole' },
  { label: 'Makonde District', value: 'Makonde District' },
  { label: 'Makoni District', value: 'Makoni District' },
  { label: 'Makoua', value: 'Makoua' },
  { label: 'Makouda', value: 'Makouda' },
  { label: 'Maków', value: 'Maków' },
  { label: 'Maków Mazowiecki', value: 'Maków Mazowiecki' },
  { label: 'Maków Podhalański', value: 'Maków Podhalański' },
  { label: 'Makrakómi', value: 'Makrakómi' },
  { label: 'Makrana', value: 'Makrana' },
  { label: 'Makresh', value: 'Makresh' },
  { label: 'Makrísia', value: 'Makrísia' },
  { label: 'Makrochóri', value: 'Makrochóri' },
  { label: 'Makrychóri', value: 'Makrychóri' },
  { label: 'Makrýgialos', value: 'Makrýgialos' },
  { label: 'Maksatikha', value: 'Maksatikha' },
  { label: 'Maksatikhinskiy Rayon', value: 'Maksatikhinskiy Rayon' },
  { label: 'Maksi', value: 'Maksi' },
  { label: 'Maktar', value: 'Maktar' },
  { label: 'Maku', value: 'Maku' },
  { label: 'Makubetsu', value: 'Makubetsu' },
  { label: 'Makueni Boma', value: 'Makueni Boma' },
  { label: 'Makum', value: 'Makum' },
  { label: 'Makumbako', value: 'Makumbako' },
  { label: 'Makungu', value: 'Makungu' },
  { label: 'Makur Municipality', value: 'Makur Municipality' },
  { label: 'Makurazaki', value: 'Makurazaki' },
  { label: 'Makurazaki Shi', value: 'Makurazaki Shi' },
  { label: 'Makurdi', value: 'Makurdi' },
  { label: 'Makushino', value: 'Makushino' },
  { label: 'Makuyuni', value: 'Makuyuni' },
  { label: 'Makwanpur', value: 'Makwanpur' },
  { label: 'Makwata', value: 'Makwata' },
  { label: 'Mal', value: 'Mal' },
  { label: 'Mala', value: 'Mala' },
  { label: 'Malå', value: 'Malå' },
  { label: 'Mala Bilozerka', value: 'Mala Bilozerka' },
  { label: 'Mala Danylivka', value: 'Mala Danylivka' },
  { label: 'Mala Kladuša', value: 'Mala Kladuša' },
  { label: 'Mala Moštanica', value: 'Mala Moštanica' },
  { label: 'Mala Rudka', value: 'Mala Rudka' },
  { label: 'Malá Skála', value: 'Malá Skála' },
  { label: 'Malá Strana', value: 'Malá Strana' },
  { label: 'Mala Subotica', value: 'Mala Subotica' },
  { label: 'Mala Tokmachka', value: 'Mala Tokmachka' },
  { label: 'Mala Vyska', value: 'Mala Vyska' },
  { label: 'Mała Wieś', value: 'Mała Wieś' },
  { label: 'Malaba', value: 'Malaba' },
  { label: 'Malabag', value: 'Malabag' },
  { label: 'Malabago', value: 'Malabago' },
  { label: 'Malabago', value: 'Malabago' },
  { label: 'Malabanan', value: 'Malabanan' },
  { label: 'Malabanban Norte', value: 'Malabanban Norte' },
  { label: 'Malabang', value: 'Malabang' },
  { label: 'Malabang', value: 'Malabang' },
  { label: 'Malabar', value: 'Malabar' },
  { label: 'Malabar', value: 'Malabar' },
  { label: 'Malabar Hill', value: 'Malabar Hill' },
  { label: 'Malabo', value: 'Malabo' },
  { label: 'Malabog', value: 'Malabog' },
  { label: 'Malabon', value: 'Malabon' },
  { label: 'Malabon', value: 'Malabon' },
  { label: 'Malabon', value: 'Malabon' },
  { label: 'Malabonot', value: 'Malabonot' },
  { label: 'Malabor', value: 'Malabor' },
  { label: 'Malabrigo', value: 'Malabrigo' },
  { label: 'Malabugas', value: 'Malabugas' },
  { label: 'Malabuyoc', value: 'Malabuyoc' },
  { label: 'Malacacheta', value: 'Malacacheta' },
  { label: 'Malacampa', value: 'Malacampa' },
  { label: 'Malacampa', value: 'Malacampa' },
  { label: 'Malacatán', value: 'Malacatán' },
  { label: 'Malacatancito', value: 'Malacatancito' },
  { label: 'Malacca', value: 'Malacca' },
  { label: 'Malacky', value: 'Malacky' },
  { label: 'Malad', value: 'Malad' },
  { label: 'Malad City', value: 'Malad City' },
  { label: 'Maladzyechna', value: 'Maladzyechna' },
  { label: 'Mălăești', value: 'Mălăești' },
  { label: 'Malaga', value: 'Malaga' },
  { label: 'Malaga', value: 'Malaga' },
  { label: 'Malaga', value: 'Malaga' },
  { label: 'Málaga', value: 'Málaga' },
  { label: 'Málaga', value: 'Málaga' },
  { label: 'Málaga Del Fresno', value: 'Málaga Del Fresno' },
  { label: 'Malagana (San Antonio Del Monte)', value: 'Malagana (San Antonio Del Monte)' },
  { label: 'Malagnino', value: 'Malagnino' },
  { label: 'Malagón', value: 'Malagón' },
  { label: 'Malagos', value: 'Malagos' },
  { label: 'Malagos', value: 'Malagos' },
  { label: 'Malagos', value: 'Malagos' },
  { label: 'Malagueño', value: 'Malagueño' },
  { label: 'Malaguilla', value: 'Malaguilla' },
  { label: 'Malahá La', value: 'Malahá La' },
  { label: 'Malahide', value: 'Malahide' },
  { label: 'Malaia', value: 'Malaia' },
  { label: 'Malaiba', value: 'Malaiba' },
  { label: 'Malainen Luma', value: 'Malainen Luma' },
  { label: 'Malajog', value: 'Malajog' },
  { label: 'Malajog', value: 'Malajog' },
  { label: 'Malak', value: 'Malak' },
  { label: 'Malakan', value: 'Malakan' },
  { label: 'Malakhovka', value: 'Malakhovka' },
  { label: 'Malakoff', value: 'Malakoff' },
  { label: 'Malakoff', value: 'Malakoff' },
  { label: 'Malakónta', value: 'Malakónta' },
  { label: 'Malakwal', value: 'Malakwal' },
  { label: 'Malakwal City', value: 'Malakwal City' },
  { label: 'Malalag', value: 'Malalag' },
  { label: 'Malalag', value: 'Malalag' },
  { label: 'Malalag', value: 'Malalag' },
  { label: 'Malalbergo', value: 'Malalbergo' },
  { label: 'Malambo', value: 'Malambo' },
  { label: 'Malamig', value: 'Malamig' },
  { label: 'Malamote', value: 'Malamote' },
  { label: 'Malamote', value: 'Malamote' },
  { label: 'Malampaka', value: 'Malampaka' },
  { label: 'Malanay', value: 'Malanay' },
  { label: 'Malanay', value: 'Malanay' },
  { label: 'Mălâncrav', value: 'Mălâncrav' },
  { label: 'Malanda', value: 'Malanda' },
  { label: 'Malandag', value: 'Malandag' },
  { label: 'Malandag', value: 'Malandag' },
  { label: 'Malanday', value: 'Malanday' },
  { label: 'Malang', value: 'Malang' },
  { label: 'Malangabang', value: 'Malangabang' },
  { label: 'Malangali', value: 'Malangali' },
  { label: 'Malangas', value: 'Malangas' },
  { label: 'Malanje', value: 'Malanje' },
  { label: 'Malanów', value: 'Malanów' },
  { label: 'Malanquilla', value: 'Malanquilla' },
  { label: 'Malans', value: 'Malans' },
  { label: 'Malansac', value: 'Malansac' },
  { label: 'Malanville', value: 'Malanville' },
  { label: 'Malapag', value: 'Malapag' },
  { label: 'Malapag', value: 'Malapag' },
  { label: 'Malapatan', value: 'Malapatan' },
  { label: 'Malapatan', value: 'Malapatan' },
  { label: 'Malappuram', value: 'Malappuram' },
  { label: 'Malard', value: 'Malard' },
  { label: 'Malartic', value: 'Malartic' },
  { label: 'Malaruhatan', value: 'Malaruhatan' },
  { label: 'Malaryta', value: 'Malaryta' },
  { label: 'Malasa', value: 'Malasa' },
  { label: 'Malasila', value: 'Malasila' },
  { label: 'Malasila', value: 'Malasila' },
  { label: 'Malasin', value: 'Malasin' },
  { label: 'Malasin', value: 'Malasin' },
  { label: 'Malasiqui', value: 'Malasiqui' },
  { label: 'Malasiqui', value: 'Malasiqui' },
  { label: 'Malasugui', value: 'Malasugui' },
  { label: 'Malatap', value: 'Malatap' },
  { label: 'Malataverne', value: 'Malataverne' },
  { label: 'Malate', value: 'Malate' },
  { label: 'Malaucène', value: 'Malaucène' },
  { label: 'Malaunay', value: 'Malaunay' },
  { label: 'Malaut', value: 'Malaut' },
  { label: 'Malavalli', value: 'Malavalli' },
  { label: 'Malavicina', value: 'Malavicina' },
  { label: 'Malawa', value: 'Malawa' },
  { label: 'Malawa', value: 'Malawa' },
  { label: 'Malawag', value: 'Malawag' },
  { label: 'Malax', value: 'Malax' },
  { label: 'Malay', value: 'Malay' },
  { label: 'Malaya', value: 'Malaya' },
  { label: 'Malaya Dubna', value: 'Malaya Dubna' },
  { label: 'Malaya Purga', value: 'Malaya Purga' },
  { label: 'Malaya Serdoba', value: 'Malaya Serdoba' },
  { label: 'Malaya Vishera', value: 'Malaya Vishera' },
  { label: 'Malayal', value: 'Malayal' },
  { label: 'Malaybalay', value: 'Malaybalay' },
  { label: 'Malayer', value: 'Malayer' },
  { label: 'Malay-Le-Grand', value: 'Malay-Le-Grand' },
  { label: 'Malayo-An', value: 'Malayo-An' },
  { label: 'Malazgirt', value: 'Malazgirt' },
  { label: 'Malbang', value: 'Malbang' },
  { label: 'Malbang', value: 'Malbang' },
  { label: 'Malberg', value: 'Malberg' },
  { label: 'Malborghetto', value: 'Malborghetto' },
  { label: 'Malbork', value: 'Malbork' },
  { label: 'Malborn', value: 'Malborn' },
  { label: 'Malbug', value: 'Malbug' },
  { label: 'Malbug', value: 'Malbug' },
  { label: 'Malburgen West', value: 'Malburgen West' },
  { label: 'Malcata', value: 'Malcata' },
  { label: 'Malcesine', value: 'Malcesine' },
  { label: 'Malche-Santa Croce-Serroni', value: 'Malche-Santa Croce-Serroni' },
  { label: 'Malchin', value: 'Malchin' },
  { label: 'Malching', value: 'Malching' },
  { label: 'Malchow', value: 'Malchow' },
  { label: 'Malcoci', value: 'Malcoci' },
  { label: 'Malcocinado', value: 'Malcocinado' },
  { label: 'Malczyce', value: 'Malczyce' },
  { label: 'Maldà', value: 'Maldà' },
  { label: 'Măldăeni', value: 'Măldăeni' },
  { label: 'Maldaoci', value: 'Maldaoci' },
  { label: 'Măldăreşti', value: 'Măldăreşti' },
  { label: 'Maldegem', value: 'Maldegem' },
  { label: 'Malden', value: 'Malden' },
  { label: 'Malden', value: 'Malden' },
  { label: 'Maldon', value: 'Maldon' },
  { label: 'Maldon', value: 'Maldon' },
  { label: 'Maldon', value: 'Maldon' },
  { label: 'Maldonado', value: 'Maldonado' },
  { label: 'Małdyty', value: 'Małdyty' },
  { label: 'Male', value: 'Male' },
  { label: 'Malè', value: 'Malè' },
  { label: 'Mal-E Khalifeh', value: 'Mal-E Khalifeh' },
  { label: 'Malé Svatoňovice', value: 'Malé Svatoňovice' },
  { label: 'Malea', value: 'Malea' },
  { label: 'Malec', value: 'Malec' },
  { label: 'Malegaon', value: 'Malegaon' },
  { label: 'Malegno', value: 'Malegno' },
  { label: 'Maleján', value: 'Maleján' },
  { label: 'Malekan', value: 'Malekan' },
  { label: 'Malek-E Ashtar', value: 'Malek-E Ashtar' },
  { label: 'Malekshahi', value: 'Malekshahi' },
  { label: 'Malem Municipality', value: 'Malem Municipality' },
  { label: 'Malemort-Du-Comtat', value: 'Malemort-Du-Comtat' },
  { label: 'Malemort-Sur-Corrèze', value: 'Malemort-Sur-Corrèze' },
  { label: 'Malen’Koye', value: 'Malen’Koye' },
  { label: 'Malente', value: 'Malente' },
  { label: 'Maleny', value: 'Maleny' },
  { label: 'Maleo', value: 'Maleo' },
  { label: 'Malerkotla', value: 'Malerkotla' },
  { label: 'Malesco', value: 'Malesco' },
  { label: 'Malesherbes', value: 'Malesherbes' },
  { label: 'Malešići', value: 'Malešići' },
  { label: 'Malesína', value: 'Malesína' },
  { label: 'Malestroit', value: 'Malestroit' },
  { label: 'Maletto', value: 'Maletto' },
  { label: 'Maleyevsk', value: 'Maleyevsk' },
  { label: 'Malfa', value: 'Malfa' },
  { label: 'Malgersdorf', value: 'Malgersdorf' },
  { label: 'Malgesso', value: 'Malgesso' },
  { label: 'Malgobek', value: 'Malgobek' },
  { label: 'Malgobekskiy Rayon', value: 'Malgobekskiy Rayon' },
  { label: 'Malgocta', value: 'Malgocta' },
  { label: 'Malgrat De Mar', value: 'Malgrat De Mar' },
  { label: 'Malgrate', value: 'Malgrate' },
  { label: 'Malgretoute', value: 'Malgretoute' },
  { label: 'Malgretoute', value: 'Malgretoute' },
  { label: 'Malguénac', value: 'Malguénac' },
  { label: 'Malhada', value: 'Malhada' },
  { label: 'Malhada De Pedras', value: 'Malhada De Pedras' },
  { label: 'Malhada Dos Bois', value: 'Malhada Dos Bois' },
  { label: 'Malhada Sorda', value: 'Malhada Sorda' },
  { label: 'Malhador', value: 'Malhador' },
  { label: 'Malhargarh', value: 'Malhargarh' },
  { label: 'Malheur County', value: 'Malheur County' },
  { label: 'Malhiao', value: 'Malhiao' },
  { label: 'Mali', value: 'Mali' },
  { label: 'Mali Lošinj', value: 'Mali Lošinj' },
  { label: 'Mali Prefecture', value: 'Mali Prefecture' },
  { label: 'Mali Zvornik', value: 'Mali Zvornik' },
  { label: 'Mália', value: 'Mália' },
  { label: 'Maliana', value: 'Maliana' },
  { label: 'Malibago', value: 'Malibago' },
  { label: 'Malibcong', value: 'Malibcong' },
  { label: 'Malibong East', value: 'Malibong East' },
  { label: 'Malibong East', value: 'Malibong East' },
  { label: 'Malibu', value: 'Malibu' },
  { label: 'Malicboy', value: 'Malicboy' },
  { label: 'Malicorne-Sur-Sarthe', value: 'Malicorne-Sur-Sarthe' },
  { label: 'Malidong', value: 'Malidong' },
  { label: 'Malie', value: 'Malie' },
  { label: 'Maligaya', value: 'Maligaya' },
  { label: 'Maligaya', value: 'Maligaya' },
  { label: 'Malihabad', value: 'Malihabad' },
  { label: 'Maliig', value: 'Maliig' },
  { label: 'Malijai', value: 'Malijai' },
  { label: 'Malikisi', value: 'Malikisi' },
  { label: 'Malilinao', value: 'Malilinao' },
  { label: 'Malilipot', value: 'Malilipot' },
  { label: 'Målilla', value: 'Målilla' },
  { label: 'Malim', value: 'Malim' },
  { label: 'Malimono', value: 'Malimono' },
  { label: 'Malimono', value: 'Malimono' },
  { label: 'Malin', value: 'Malin' },
  { label: 'Malinalco', value: 'Malinalco' },
  { label: 'Malinaltepec', value: 'Malinaltepec' },
  { label: 'Malinao', value: 'Malinao' },
  { label: 'Malinao', value: 'Malinao' },
  { label: 'Malinao', value: 'Malinao' },
  { label: 'Malinao', value: 'Malinao' },
  { label: 'Malinao', value: 'Malinao' },
  { label: 'Malinao', value: 'Malinao' },
  { label: 'Malinao Ilaya', value: 'Malinao Ilaya' },
  { label: 'Malinaw', value: 'Malinaw' },
  { label: 'Malindi', value: 'Malindi' },
  { label: 'Malingao', value: 'Malingao' },
  { label: 'Malingao', value: 'Malingao' },
  { label: 'Malingin', value: 'Malingin' },
  { label: 'Mălini', value: 'Mălini' },
  { label: 'Malino', value: 'Malino' },
  { label: 'Malino', value: 'Malino' },
  { label: 'Malino', value: 'Malino' },
  { label: 'Malinovoye Ozero', value: 'Malinovoye Ozero' },
  { label: 'Malinovskiy', value: 'Malinovskiy' },
  { label: 'Malinska-Dubašnica', value: 'Malinska-Dubašnica' },
  { label: 'Malinta', value: 'Malinta' },
  { label: 'Malintrat', value: 'Malintrat' },
  { label: 'Malinyi', value: 'Malinyi' },
  { label: 'Maliotenam', value: 'Maliotenam' },
  { label: 'Maliq', value: 'Maliq' },
  { label: 'Malir Cantonment', value: 'Malir Cantonment' },
  { label: 'Malisbeng', value: 'Malisbeng' },
  { label: 'Malisbeng', value: 'Malisbeng' },
  { label: 'Malishka', value: 'Malishka' },
  { label: 'Malissard', value: 'Malissard' },
  { label: 'Malita', value: 'Malita' },
  { label: 'Malita', value: 'Malita' },
  { label: 'Malita', value: 'Malita' },
  { label: 'Malitbog', value: 'Malitbog' },
  { label: 'Malitbog', value: 'Malitbog' },
  { label: 'Malitbog', value: 'Malitbog' },
  { label: 'Malito', value: 'Malito' },
  { label: 'Malitubog', value: 'Malitubog' },
  { label: 'Malitubog', value: 'Malitubog' },
  { label: 'Maliuc', value: 'Maliuc' },
  { label: 'Malka', value: 'Malka' },
  { label: 'Malkā', value: 'Malkā' },
  { label: 'Malkajgiri', value: 'Malkajgiri' },
  { label: 'Malkangiri', value: 'Malkangiri' },
  { label: 'Malkapur', value: 'Malkapur' },
  { label: 'Malkara', value: 'Malkara' },
  { label: 'Malkera', value: 'Malkera' },
  { label: 'Malkerns', value: 'Malkerns' },
  { label: 'Małkinia Górna', value: 'Małkinia Górna' },
  { label: 'Malko Tarnovo', value: 'Malko Tarnovo' },
  { label: 'Malla', value: 'Malla' },
  { label: 'Mallabia', value: 'Mallabia' },
  { label: 'Mallacoota', value: 'Mallacoota' },
  { label: 'Mallala', value: 'Mallala' },
  { label: 'Mallama', value: 'Mallama' },
  { label: 'Mallammaduri', value: 'Mallammaduri' },
  { label: 'Mallapuram', value: 'Mallapuram' },
  { label: 'Mallare', value: 'Mallare' },
  { label: 'Mallasamudram', value: 'Mallasamudram' },
  { label: 'Mallawī', value: 'Mallawī' },
  { label: 'Mallemoisson', value: 'Mallemoisson' },
  { label: 'Mallemort', value: 'Mallemort' },
  { label: 'Mallén', value: 'Mallén' },
  { label: 'Malleray', value: 'Malleray' },
  { label: 'Mallersdorf-Pfaffenberg', value: 'Mallersdorf-Pfaffenberg' },
  { label: 'Malles Venosta', value: 'Malles Venosta' },
  { label: 'Mallet', value: 'Mallet' },
  { label: 'Mallig', value: 'Mallig' },
  { label: 'Mallig', value: 'Mallig' },
  { label: 'Malling', value: 'Malling' },
  { label: 'Malliß', value: 'Malliß' },
  { label: 'Mallnitz', value: 'Mallnitz' },
  { label: 'Malloa', value: 'Malloa' },
  { label: 'Mallory', value: 'Mallory' },
  { label: 'Mallow', value: 'Mallow' },
  { label: 'Mallur', value: 'Mallur' },
  { label: 'Malm', value: 'Malm' },
  { label: 'Malmantile', value: 'Malmantile' },
  { label: 'Malmbäck', value: 'Malmbäck' },
  { label: 'Malmberget', value: 'Malmberget' },
  { label: 'Malmédy', value: 'Malmédy' },
  { label: 'Malmesbury', value: 'Malmesbury' },
  { label: 'Malmesbury', value: 'Malmesbury' },
  { label: 'Malmköping', value: 'Malmköping' },
  { label: 'Malmö', value: 'Malmö' },
  { label: 'Malmslätt', value: 'Malmslätt' },
  { label: 'Malmstrom Air Force Base', value: 'Malmstrom Air Force Base' },
  { label: 'Malmyzh', value: 'Malmyzh' },
  { label: 'Malmyzhskiy Rayon', value: 'Malmyzhskiy Rayon' },
  { label: 'Malnaş', value: 'Malnaş' },
  { label: 'Malnate', value: 'Malnate' },
  { label: 'Malo', value: 'Malo' },
  { label: 'Maloarkhangel’Sk', value: 'Maloarkhangel’Sk' },
  { label: 'Malocloc', value: 'Malocloc' },
  { label: 'Maloco', value: 'Maloco' },
  { label: 'Małogoszcz', value: 'Małogoszcz' },
  { label: 'Maloh', value: 'Maloh' },
  { label: 'Malokakhovka', value: 'Malokakhovka' },
  { label: 'Malokarachayevskiy Rayon', value: 'Malokarachayevskiy Rayon' },
  { label: 'Malokaterynivka', value: 'Malokaterynivka' },
  { label: 'Malolos', value: 'Malolos' },
  { label: 'Malolos', value: 'Malolos' },
  { label: 'Maloma', value: 'Maloma' },
  { label: 'Maloma', value: 'Maloma' },
  { label: 'Małomice', value: 'Małomice' },
  { label: 'Malón', value: 'Malón' },
  { label: 'Malone', value: 'Malone' },
  { label: 'Malone', value: 'Malone' },
  { label: 'Malonno', value: 'Malonno' },
  { label: 'Malonty', value: 'Malonty' },
  { label: 'Malopurginskiy Rayon', value: 'Malopurginskiy Rayon' },
  { label: 'Malorechenskoye', value: 'Malorechenskoye' },
  { label: 'Malosco', value: 'Malosco' },
  { label: 'Malougrenevo', value: 'Malougrenevo' },
  { label: 'Måløv', value: 'Måløv' },
  { label: 'Malovăţ', value: 'Malovăţ' },
  { label: 'Malovisherskiy Rayon', value: 'Malovisherskiy Rayon' },
  { label: 'Malovyskivs’Kyy Rayon', value: 'Malovyskivs’Kyy Rayon' },
  { label: 'Måløy', value: 'Måløy' },
  { label: 'Maloyaroslavets', value: 'Maloyaroslavets' },
  { label: 'Maloyaroslavetskiy Rayon', value: 'Maloyaroslavetskiy Rayon' },
  { label: 'Maloye Ganyushkino', value: 'Maloye Ganyushkino' },
  { label: 'Maloye Isakovo', value: 'Maloye Isakovo' },
  { label: 'Maloye Verevo', value: 'Maloye Verevo' },
  { label: 'Malpartida', value: 'Malpartida' },
  { label: 'Malpartida', value: 'Malpartida' },
  { label: 'Malpartida De Cáceres', value: 'Malpartida De Cáceres' },
  { label: 'Malpartida De Corneja', value: 'Malpartida De Corneja' },
  { label: 'Malpartida De La Serena', value: 'Malpartida De La Serena' },
  { label: 'Malpartida De Plasencia', value: 'Malpartida De Plasencia' },
  { label: 'Malpas', value: 'Malpas' },
  { label: 'Malpaso', value: 'Malpaso' },
  { label: 'Malpaso', value: 'Malpaso' },
  { label: 'Malpassé', value: 'Malpassé' },
  { label: 'Malpe', value: 'Malpe' },
  { label: 'Malpica De Bergantiños', value: 'Malpica De Bergantiños' },
  { label: 'Malpica De Tajo', value: 'Malpica De Tajo' },
  { label: 'Mālpils', value: 'Mālpils' },
  { label: 'Malpur', value: 'Malpur' },
  { label: 'Malpura', value: 'Malpura' },
  { label: 'Malsch', value: 'Malsch' },
  { label: 'Malschwitz', value: 'Malschwitz' },
  { label: 'Målselv', value: 'Målselv' },
  { label: 'Malsfeld', value: 'Malsfeld' },
  { label: 'Malšice', value: 'Malšice' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Maltahöhe', value: 'Maltahöhe' },
  { label: 'Maltana', value: 'Maltana' },
  { label: 'Maltana', value: 'Maltana' },
  { label: 'Maltby', value: 'Maltby' },
  { label: 'Maltby', value: 'Maltby' },
  { label: 'Maltepe', value: 'Maltepe' },
  { label: 'Malterdingen', value: 'Malterdingen' },
  { label: 'Malters', value: 'Malters' },
  { label: 'Maltignano', value: 'Maltignano' },
  { label: 'Malton', value: 'Malton' },
  { label: 'Maltrata', value: 'Maltrata' },
  { label: 'Malu', value: 'Malu' },
  { label: 'Malu Cu Flori', value: 'Malu Cu Flori' },
  { label: 'Malu Mare', value: 'Malu Mare' },
  { label: 'Malu Roșu', value: 'Malu Roșu' },
  { label: 'Malu Spart', value: 'Malu Spart' },
  { label: 'Malua Bay', value: 'Malua Bay' },
  { label: 'Maluanluan', value: 'Maluanluan' },
  { label: 'Maluenda', value: 'Maluenda' },
  { label: 'Maluid', value: 'Maluid' },
  { label: 'Maluid', value: 'Maluid' },
  { label: 'Maluko', value: 'Maluko' },
  { label: 'Malung', value: 'Malung' },
  { label: 'Malungon', value: 'Malungon' },
  { label: 'Maluñgun', value: 'Maluñgun' },
  { label: 'Maluno Sur', value: 'Maluno Sur' },
  { label: 'Maluno Sur', value: 'Maluno Sur' },
  { label: 'Malur', value: 'Malur' },
  { label: 'Mălureni', value: 'Mălureni' },
  { label: 'Malusac', value: 'Malusac' },
  { label: 'Malusac', value: 'Malusac' },
  { label: 'Malusan', value: 'Malusan' },
  { label: 'Malusay', value: 'Malusay' },
  { label: 'Maluso', value: 'Maluso' },
  { label: 'Maluso', value: 'Maluso' },
  { label: 'Măluşteni', value: 'Măluşteni' },
  { label: 'Malva', value: 'Malva' },
  { label: 'Malvaglia', value: 'Malvaglia' },
  { label: 'Malvagna', value: 'Malvagna' },
  { label: 'Malvan', value: 'Malvan' },
  { label: 'Malvar', value: 'Malvar' },
  { label: 'Malveira', value: 'Malveira' },
  { label: 'Malveira', value: 'Malveira' },
  { label: 'Malvern', value: 'Malvern' },
  { label: 'Malvern', value: 'Malvern' },
  { label: 'Malvern', value: 'Malvern' },
  { label: 'Malvern', value: 'Malvern' },
  { label: 'Malvern', value: 'Malvern' },
  { label: 'Malvern', value: 'Malvern' },
  { label: 'Malvern', value: 'Malvern' },
  { label: 'Malvern', value: 'Malvern' },
  { label: 'Malvern East', value: 'Malvern East' },
  { label: 'Malverne', value: 'Malverne' },
  { label: 'Malvicino', value: 'Malvicino' },
  { label: 'Malvik', value: 'Malvik' },
  { label: 'Malville', value: 'Malville' },
  { label: 'Malvín', value: 'Malvín' },
  { label: 'Malvín Norte', value: 'Malvín Norte' },
  { label: 'Malvinas Argentinas', value: 'Malvinas Argentinas' },
  { label: 'Malvito', value: 'Malvito' },
  { label: 'Malway', value: 'Malway' },
  { label: 'Maly Mayak', value: 'Maly Mayak' },
  { label: 'Mały Płock', value: 'Mały Płock' },
  { label: 'Malya', value: 'Malya' },
  { label: 'Mályi', value: 'Mályi' },
  { label: 'Malyn', value: 'Malyn' },
  { label: 'Malyn', value: 'Malyn' },
  { label: 'Malyn Raion', value: 'Malyn Raion' },
  { label: 'Malynivka', value: 'Malynivka' },
  { label: 'Malysheva', value: 'Malysheva' },
  { label: 'Malyshevo', value: 'Malyshevo' },
  { label: 'Malyye Derbety', value: 'Malyye Derbety' },
  { label: 'Malzéville', value: 'Malzéville' },
  { label: 'Mama', value: 'Mama' },
  { label: 'Mama', value: 'Mama' },
  { label: 'Mamadysh', value: 'Mamadysh' },
  { label: 'Mamadyshskiy Rayon', value: 'Mamadyshskiy Rayon' },
  { label: 'Mamaia-Sat', value: 'Mamaia-Sat' },
  { label: 'Mamak', value: 'Mamak' },
  { label: 'Mamakan', value: 'Mamakan' },
  { label: 'Mamala', value: 'Mamala' },
  { label: 'Mamali', value: 'Mamali' },
  { label: 'Mamali', value: 'Mamali' },
  { label: 'Mamanguape', value: 'Mamanguape' },
  { label: 'Mamantel', value: 'Mamantel' },
  { label: 'Mamaqan', value: 'Mamaqan' },
  { label: 'Mamaroneck', value: 'Mamaroneck' },
  { label: 'Mamasani', value: 'Mamasani' },
  { label: 'Mamatid', value: 'Mamatid' },
  { label: 'Mambagatan', value: 'Mambagatan' },
  { label: 'Mambago', value: 'Mambago' },
  { label: 'Mambago', value: 'Mambago' },
  { label: 'Mambago', value: 'Mambago' },
  { label: 'Mambaí', value: 'Mambaí' },
  { label: 'Mambajao', value: 'Mambajao' },
  { label: 'Mambatangan', value: 'Mambatangan' },
  { label: 'Mambayaan', value: 'Mambayaan' },
  { label: 'Mamblas', value: 'Mamblas' },
  { label: 'Mambog', value: 'Mambog' },
  { label: 'Mambog', value: 'Mambog' },
  { label: 'Mambolo', value: 'Mambolo' },
  { label: 'Mamboma', value: 'Mamboma' },
  { label: 'Mamborê', value: 'Mamborê' },
  { label: 'Mambrilla De Castrejón', value: 'Mambrilla De Castrejón' },
  { label: 'Mambrillas De Lara', value: 'Mambrillas De Lara' },
  { label: 'Mambulo', value: 'Mambulo' },
  { label: 'Mamburao', value: 'Mamburao' },
  { label: 'Mambusao', value: 'Mambusao' },
  { label: 'Mamedkala', value: 'Mamedkala' },
  { label: 'Mamer', value: 'Mamer' },
  { label: 'Mamers', value: 'Mamers' },
  { label: 'Mametz', value: 'Mametz' },
  { label: 'Mamfe', value: 'Mamfe' },
  { label: 'Mamiku', value: 'Mamiku' },
  { label: 'Mamirolle', value: 'Mamirolle' },
  { label: 'Mamit', value: 'Mamit' },
  { label: 'Mamithi', value: 'Mamithi' },
  { label: 'Mámmari', value: 'Mámmari' },
  { label: 'Mammelzen', value: 'Mammelzen' },
  { label: 'Mammendorf', value: 'Mammendorf' },
  { label: 'Mamming', value: 'Mamming' },
  { label: 'Mammola', value: 'Mammola' },
  { label: 'Mammoth', value: 'Mammoth' },
  { label: 'Mammoth Lakes', value: 'Mammoth Lakes' },
  { label: 'Mamoiada', value: 'Mamoiada' },
  { label: 'Mamolar', value: 'Mamolar' },
  { label: 'Mamonas', value: 'Mamonas' },
  { label: 'Mamonit', value: 'Mamonit' },
  { label: 'Mamonit', value: 'Mamonit' },
  { label: 'Mamonovo', value: 'Mamonovo' },
  { label: 'Mamontovka', value: 'Mamontovka' },
  { label: 'Mamontovo', value: 'Mamontovo' },
  { label: 'Mamontovskiy Rayon', value: 'Mamontovskiy Rayon' },
  { label: 'Mamou', value: 'Mamou' },
  { label: 'Mamou', value: 'Mamou' },
  { label: 'Mamou Prefecture', value: 'Mamou Prefecture' },
  { label: 'Mampituba', value: 'Mampituba' },
  { label: 'Mampong', value: 'Mampong' },
  { label: 'Mamprugu-Moagduri', value: 'Mamprugu-Moagduri' },
  { label: 'Mampurog', value: 'Mampurog' },
  { label: 'Mamrux', value: 'Mamrux' },
  { label: 'Mamsko-Chuyskiy Rayon', value: 'Mamsko-Chuyskiy Rayon' },
  { label: 'Mamu Kanjan', value: 'Mamu Kanjan' },
  { label: 'Mamuju', value: 'Mamuju' },
  { label: 'Mamulan', value: 'Mamulan' },
  { label: 'Mamungan', value: 'Mamungan' },
  { label: 'Mamuniyeh', value: 'Mamuniyeh' },
  { label: 'Mamurras', value: 'Mamurras' },
  { label: 'Man', value: 'Man' },
  { label: 'Manabo', value: 'Manabo' },
  { label: 'Manabo', value: 'Manabo' },
  { label: 'Manaca Civil', value: 'Manaca Civil' },
  { label: 'Manaca Norte', value: 'Manaca Norte' },
  { label: 'Manacapuru', value: 'Manacapuru' },
  { label: 'Manacor', value: 'Manacor' },
  { label: 'Manacsac', value: 'Manacsac' },
  { label: 'Manacsac', value: 'Manacsac' },
  { label: 'Manadhoo', value: 'Manadhoo' },
  { label: 'Manado', value: 'Manado' },
  { label: 'Managa', value: 'Managa' },
  { label: 'Managa', value: 'Managa' },
  { label: 'Managa', value: 'Managa' },
  { label: 'Manage', value: 'Manage' },
  { label: 'Managok', value: 'Managok' },
  { label: 'Managua', value: 'Managua' },
  { label: 'Manahawkin', value: 'Manahawkin' },
  { label: 'Mănăilești', value: 'Mănăilești' },
  { label: 'Manaíra', value: 'Manaíra' },
  { label: 'Manakhah', value: 'Manakhah' },
  { label: 'Manalad', value: 'Manalad' },
  { label: 'Manali', value: 'Manali' },
  { label: 'Manali', value: 'Manali' },
  { label: 'Manalisco', value: 'Manalisco' },
  { label: 'Manaloal', value: 'Manaloal' },
  { label: 'Manaloal', value: 'Manaloal' },
  { label: 'Manaloal', value: 'Manaloal' },
  { label: 'Manalongon', value: 'Manalongon' },
  { label: 'Manalurpettai', value: 'Manalurpettai' },
  { label: 'Manama', value: 'Manama' },
  { label: 'Manama', value: 'Manama' },
  { label: 'Manamadurai', value: 'Manamadurai' },
  { label: 'Manamrag', value: 'Manamrag' },
  { label: 'Manang ', value: 'Manang ' },
  { label: 'Manantiales', value: 'Manantiales' },
  { label: 'Mananum', value: 'Mananum' },
  { label: 'Mananwala', value: 'Mananwala' },
  { label: 'Manaoag', value: 'Manaoag' },
  { label: 'Manaoag', value: 'Manaoag' },
  { label: 'Manapa', value: 'Manapa' },
  { label: 'Manapa', value: 'Manapa' },
  { label: 'Manapla', value: 'Manapla' },
  { label: 'Manappakkam', value: 'Manappakkam' },
  { label: 'Manapparai', value: 'Manapparai' },
  { label: 'Manaquiri', value: 'Manaquiri' },
  { label: 'Manar', value: 'Manar' },
  { label: 'Manari', value: 'Manari' },
  { label: 'Mañaria', value: 'Mañaria' },
  { label: 'Manaring', value: 'Manaring' },
  { label: 'Manaring', value: 'Manaring' },
  { label: 'Manas', value: 'Manas' },
  { label: 'Manasa', value: 'Manasa' },
  { label: 'Manasia', value: 'Manasia' },
  { label: 'Manaskent', value: 'Manaskent' },
  { label: 'Manasota Key', value: 'Manasota Key' },
  { label: 'Manasquan', value: 'Manasquan' },
  { label: 'Manassas', value: 'Manassas' },
  { label: 'Manassas Park', value: 'Manassas Park' },
  { label: 'Manasterz', value: 'Manasterz' },
  { label: 'Mânăstirea', value: 'Mânăstirea' },
  { label: 'Mănăstirea', value: 'Mănăstirea' },
  { label: 'Mănăstirea', value: 'Mănăstirea' },
  { label: 'Mânăstirea Caşin', value: 'Mânăstirea Caşin' },
  { label: 'Mănăstirea Humorului', value: 'Mănăstirea Humorului' },
  { label: 'Mânăstireni', value: 'Mânăstireni' },
  { label: 'Mănăştiur', value: 'Mănăştiur' },
  { label: 'Mănăștur', value: 'Mănăștur' },
  { label: 'Manat', value: 'Manat' },
  { label: 'Manat', value: 'Manat' },
  { label: 'Manat', value: 'Manat' },
  { label: 'Manatal', value: 'Manatal' },
  { label: 'Manatal', value: 'Manatal' },
  { label: 'Manatee County', value: 'Manatee County' },
  { label: 'Manatee Road', value: 'Manatee Road' },
  { label: 'Manatí', value: 'Manatí' },
  { label: 'Manatí', value: 'Manatí' },
  { label: 'Manatí', value: 'Manatí' },
  { label: 'Manatí', value: 'Manatí' },
  { label: 'Manatuto', value: 'Manatuto' },
  { label: 'Manatutu', value: 'Manatutu' },
  { label: 'Mânău', value: 'Mânău' },
  { label: 'Manaul', value: 'Manaul' },
  { label: 'Manaulanan', value: 'Manaulanan' },
  { label: 'Manaulanan', value: 'Manaulanan' },
  { label: 'Manaure', value: 'Manaure' },
  { label: 'Manaure Balcón Del Cesar', value: 'Manaure Balcón Del Cesar' },
  { label: 'Manaus', value: 'Manaus' },
  { label: 'Manavadar', value: 'Manavadar' },
  { label: 'Manavalakurichi', value: 'Manavalakurichi' },
  { label: 'Manavgat', value: 'Manavgat' },
  { label: 'Manawa', value: 'Manawa' },
  { label: 'Manawan', value: 'Manawan' },
  { label: 'Manawar', value: 'Manawar' },
  { label: 'Manawatu District', value: 'Manawatu District' },
  { label: 'Manay', value: 'Manay' },
  { label: 'Manay', value: 'Manay' },
  { label: 'Manay', value: 'Manay' },
  { label: 'Manbij', value: 'Manbij' },
  { label: 'Manbij District', value: 'Manbij District' },
  { label: 'Manby', value: 'Manby' },
  { label: 'Mancelona', value: 'Mancelona' },
  { label: 'Mancera De Abajo', value: 'Mancera De Abajo' },
  { label: 'Mancera De Arriba', value: 'Mancera De Arriba' },
  { label: 'Mancha Real', value: 'Mancha Real' },
  { label: 'Manchaca', value: 'Manchaca' },
  { label: 'Manchar', value: 'Manchar' },
  { label: 'Manche', value: 'Manche' },
  { label: 'Manchenky', value: 'Manchenky' },
  { label: 'Mancheral', value: 'Mancheral' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester', value: 'Manchester' },
  { label: 'Manchester Center', value: 'Manchester Center' },
  { label: 'Manchester-By-The-Sea', value: 'Manchester-By-The-Sea' },
  { label: 'Manching', value: 'Manching' },
  { label: 'Manchioneal', value: 'Manchioneal' },
  { label: 'Manchita', value: 'Manchita' },
  { label: 'Manchones', value: 'Manchones' },
  { label: 'Manciano', value: 'Manciano' },
  { label: 'Mancieulles', value: 'Mancieulles' },
  { label: 'Mancilang', value: 'Mancilang' },
  { label: 'Manciles', value: 'Manciles' },
  { label: 'Mâncio Lima', value: 'Mâncio Lima' },
  { label: 'Mancor De La Vall', value: 'Mancor De La Vall' },
  { label: 'Máncora', value: 'Máncora' },
  { label: 'Mancos', value: 'Mancos' },
  { label: 'Mancuernas', value: 'Mancuernas' },
  { label: 'Manda', value: 'Manda' },
  { label: 'Mandaguaçu', value: 'Mandaguaçu' },
  { label: 'Mandaguari', value: 'Mandaguari' },
  { label: 'Mandal', value: 'Mandal' },
  { label: 'Mandal', value: 'Mandal' },
  { label: 'Mandal', value: 'Mandal' },
  { label: 'Mandalay', value: 'Mandalay' },
  { label: 'Mandalgarh', value: 'Mandalgarh' },
  { label: 'Mandalgovi', value: 'Mandalgovi' },
  { label: 'Mandalī', value: 'Mandalī' },
  { label: 'Mándalo', value: 'Mándalo' },
  { label: 'Mandaluyong', value: 'Mandaluyong' },
  { label: 'Mandamarri', value: 'Mandamarri' },
  { label: 'Mandan', value: 'Mandan' },
  { label: 'Mandangad', value: 'Mandangad' },
  { label: 'Mandangoa', value: 'Mandangoa' },
  { label: 'Mandanici', value: 'Mandanici' },
  { label: 'Mandaon', value: 'Mandaon' },
  { label: 'Mandapam', value: 'Mandapam' },
  { label: 'Mandapeta', value: 'Mandapeta' },
  { label: 'Mandas', value: 'Mandas' },
  { label: 'Mandasa', value: 'Mandasa' },
  { label: 'Mandatoriccio', value: 'Mandatoriccio' },
  { label: 'Mandaue City', value: 'Mandaue City' },
  { label: 'Mandawar', value: 'Mandawar' },
  { label: 'Mandawar', value: 'Mandawar' },
  { label: 'Mandayona', value: 'Mandayona' },
  { label: 'Mandela', value: 'Mandela' },
  { label: 'Mandelieu-La-Napoule', value: 'Mandelieu-La-Napoule' },
  { label: 'Mandello Del Lario', value: 'Mandello Del Lario' },
  { label: 'Mandello Vitta', value: 'Mandello Vitta' },
  { label: 'Mandera', value: 'Mandera' },
  { label: 'Manderscheid', value: 'Manderscheid' },
  { label: 'Mandeure', value: 'Mandeure' },
  { label: 'Mandeville', value: 'Mandeville' },
  { label: 'Mandeville', value: 'Mandeville' },
  { label: 'Mandeville', value: 'Mandeville' },
  { label: 'Mandeville Proper', value: 'Mandeville Proper' },
  { label: 'Mandhal', value: 'Mandhal' },
  { label: 'Mandholi Kalan', value: 'Mandholi Kalan' },
  { label: 'Mandi', value: 'Mandi' },
  { label: 'Mandi Bahauddin', value: 'Mandi Bahauddin' },
  { label: 'Mandi Bahauddin District', value: 'Mandi Bahauddin District' },
  { label: 'Mandiana', value: 'Mandiana' },
  { label: 'Mandiana Prefecture', value: 'Mandiana Prefecture' },
  { label: 'Mandideep', value: 'Mandideep' },
  { label: 'Mandih', value: 'Mandih' },
  { label: 'Mandili', value: 'Mandili' },
  { label: 'Mandili', value: 'Mandili' },
  { label: 'Mandimba', value: 'Mandimba' },
  { label: 'Mandirituba', value: 'Mandirituba' },
  { label: 'Mandjafa', value: 'Mandjafa' },
  { label: 'Mandla', value: 'Mandla' },
  { label: 'Mandleshwar', value: 'Mandleshwar' },
  { label: 'Mando', value: 'Mando' },
  { label: 'Mándok', value: 'Mándok' },
  { label: 'Mándra', value: 'Mándra' },
  { label: 'Mândra', value: 'Mândra' },
  { label: 'Mandráki', value: 'Mandráki' },
  { label: 'Mandres-Les-Roses', value: 'Mandres-Les-Roses' },
  { label: 'Mândrești', value: 'Mândrești' },
  { label: 'Mândrești', value: 'Mândrești' },
  { label: 'Mandriola-Santagostino', value: 'Mandriola-Santagostino' },
  { label: 'Mandrogne', value: 'Mandrogne' },
  { label: 'Mândruloc', value: 'Mândruloc' },
  { label: 'Mandsaur', value: 'Mandsaur' },
  { label: 'Manduel', value: 'Manduel' },
  { label: 'Mandurah', value: 'Mandurah' },
  { label: 'Mandurah City Centre', value: 'Mandurah City Centre' },
  { label: 'Manduri', value: 'Manduri' },
  { label: 'Manduria', value: 'Manduria' },
  { label: 'Mandvi', value: 'Mandvi' },
  { label: 'Mandvi (Surat)', value: 'Mandvi (Surat)' },
  { label: 'Mandwa', value: 'Mandwa' },
  { label: 'Mandya', value: 'Mandya' },
  { label: 'Mandza', value: 'Mandza' },
  { label: 'Mane', value: 'Mane' },
  { label: 'Manea', value: 'Manea' },
  { label: 'Măneciu-Ungureni', value: 'Măneciu-Ungureni' },
  { label: 'Manéglise', value: 'Manéglise' },
  { label: 'Maneh Va Samalqan', value: 'Maneh Va Samalqan' },
  { label: 'Maner', value: 'Maner' },
  { label: 'Manera', value: 'Manera' },
  { label: 'Manerba Del Garda', value: 'Manerba Del Garda' },
  { label: 'Manerbio', value: 'Manerbio' },
  { label: 'Maneromango', value: 'Maneromango' },
  { label: 'Mañeru', value: 'Mañeru' },
  { label: 'Manesseno', value: 'Manesseno' },
  { label: 'Măneşti', value: 'Măneşti' },
  { label: 'Măneşti', value: 'Măneşti' },
  { label: 'Manětín', value: 'Manětín' },
  { label: 'Manevychi', value: 'Manevychi' },
  { label: 'Manevyts’Kyy Rayon', value: 'Manevyts’Kyy Rayon' },
  { label: 'Manfalūţ', value: 'Manfalūţ' },
  { label: 'Manfredonia', value: 'Manfredonia' },
  { label: 'Manfrinópolis', value: 'Manfrinópolis' },
  { label: 'Manfuha', value: 'Manfuha' },
  { label: 'Manga', value: 'Manga' },
  { label: 'Manga', value: 'Manga' },
  { label: 'Manga', value: 'Manga' },
  { label: 'Manga', value: 'Manga' },
  { label: 'Manga', value: 'Manga' },
  { label: 'Manga', value: 'Manga' },
  { label: 'Manga', value: 'Manga' },
  { label: 'Manga', value: 'Manga' },
  { label: 'Mangai', value: 'Mangai' },
  { label: 'Mangalagiri', value: 'Mangalagiri' },
  { label: 'Mangalam', value: 'Mangalam' },
  { label: 'Mangaldai', value: 'Mangaldai' },
  { label: 'Mangaldan', value: 'Mangaldan' },
  { label: 'Mangaldan', value: 'Mangaldan' },
  { label: 'Mangalia', value: 'Mangalia' },
  { label: 'Mangaluru', value: 'Mangaluru' },
  { label: 'Mangan', value: 'Mangan' },
  { label: 'Manganeses De La Lampreana', value: 'Manganeses De La Lampreana' },
  { label: 'Manganeses De La Polvorosa', value: 'Manganeses De La Polvorosa' },
  { label: 'Mangaon', value: 'Mangaon' },
  { label: 'Mangaratiba', value: 'Mangaratiba' },
  { label: 'Mangarine', value: 'Mangarine' },
  { label: 'Mangas', value: 'Mangas' },
  { label: 'Mangas', value: 'Mangas' },
  { label: 'Mangatarem', value: 'Mangatarem' },
  { label: 'Mangatarem', value: 'Mangatarem' },
  { label: 'Mangaung Metropolitan Municipality', value: 'Mangaung Metropolitan Municipality' },
  { label: 'Mangavi', value: 'Mangavi' },
  { label: 'Mangawan', value: 'Mangawan' },
  { label: 'Mange', value: 'Mange' },
  { label: 'Manger', value: 'Manger' },
  { label: 'Mangere', value: 'Mangere' },
  { label: 'Mangero', value: 'Mangero' },
  { label: 'Mangerton', value: 'Mangerton' },
  { label: 'Mangga', value: 'Mangga' },
  { label: 'Mangga', value: 'Mangga' },
  { label: 'Manggahan', value: 'Manggahan' },
  { label: 'Manggar', value: 'Manggar' },
  { label: 'Manghit', value: 'Manghit' },
  { label: 'Mangili', value: 'Mangili' },
  { label: 'Mangili', value: 'Mangili' },
  { label: 'Mangili', value: 'Mangili' },
  { label: 'Mangla', value: 'Mangla' },
  { label: 'Manglaur', value: 'Manglaur' },
  { label: 'Manglisi', value: 'Manglisi' },
  { label: 'Mango', value: 'Mango' },
  { label: 'Mango', value: 'Mango' },
  { label: 'Mango Hill', value: 'Mango Hill' },
  { label: 'Mango Valley', value: 'Mango Valley' },
  { label: 'Mangochi', value: 'Mangochi' },
  { label: 'Mangochi District', value: 'Mangochi District' },
  { label: 'Mangone', value: 'Mangone' },
  { label: 'Mangonia Park', value: 'Mangonia Park' },
  { label: 'Mangoso', value: 'Mangoso' },
  { label: 'Mangotsfield', value: 'Mangotsfield' },
  { label: 'Mangrol', value: 'Mangrol' },
  { label: 'Mangrol', value: 'Mangrol' },
  { label: 'Mangrol (Junagadh)', value: 'Mangrol (Junagadh)' },
  { label: 'Mangrul Pir', value: 'Mangrul Pir' },
  { label: 'Mangualde', value: 'Mangualde' },
  { label: 'Mangualde Da Serra', value: 'Mangualde Da Serra' },
  { label: 'Mangueirinha', value: 'Mangueirinha' },
  { label: 'Manguito', value: 'Manguito' },
  { label: 'Mangulile', value: 'Mangulile' },
  { label: 'Mangum', value: 'Mangum' },
  { label: 'Mangusu', value: 'Mangusu' },
  { label: 'Mangwe District', value: 'Mangwe District' },
  { label: 'Manhasset', value: 'Manhasset' },
  { label: 'Manhasset Hills', value: 'Manhasset Hills' },
  { label: 'Manhattan', value: 'Manhattan' },
  { label: 'Manhattan', value: 'Manhattan' },
  { label: 'Manhattan', value: 'Manhattan' },
  { label: 'Manhattan', value: 'Manhattan' },
  { label: 'Manhattan Beach', value: 'Manhattan Beach' },
  { label: 'Manhay', value: 'Manhay' },
  { label: 'Manheim', value: 'Manheim' },
  { label: 'Manhica', value: 'Manhica' },
  { label: 'Manhuaçu', value: 'Manhuaçu' },
  { label: 'Manhumirim', value: 'Manhumirim' },
  { label: 'Manhush', value: 'Manhush' },
  { label: 'Mani', value: 'Mani' },
  { label: 'Maní', value: 'Maní' },
  { label: 'Maniace', value: 'Maniace' },
  { label: 'Maniago', value: 'Maniago' },
  { label: 'Maniákoi', value: 'Maniákoi' },
  { label: 'Maniar', value: 'Maniar' },
  { label: 'Manibaug Pasig', value: 'Manibaug Pasig' },
  { label: 'Manibaug Pasig', value: 'Manibaug Pasig' },
  { label: 'Manicahan', value: 'Manicahan' },
  { label: 'Manicaragua', value: 'Manicaragua' },
  { label: 'Manicoré', value: 'Manicoré' },
  { label: 'Manifold Heights', value: 'Manifold Heights' },
  { label: 'Manigoto', value: 'Manigoto' },
  { label: 'Manihari', value: 'Manihari' },
  { label: 'Manika', value: 'Manika' },
  { label: 'Manikata', value: 'Manikata' },
  { label: 'Manikganj ', value: 'Manikganj ' },
  { label: 'Manikling', value: 'Manikling' },
  { label: 'Manikling', value: 'Manikling' },
  { label: 'Manikling', value: 'Manikling' },
  { label: 'Manikpara', value: 'Manikpara' },
  { label: 'Manikpur', value: 'Manikpur' },
  { label: 'Manikyangsa', value: 'Manikyangsa' },
  { label: 'Manila', value: 'Manila' },
  { label: 'Manila', value: 'Manila' },
  { label: 'Manila', value: 'Manila' },
  { label: 'Manilla', value: 'Manilla' },
  { label: 'Manilva', value: 'Manilva' },
  { label: 'Maningrida', value: 'Maningrida' },
  { label: 'Maninihon', value: 'Maninihon' },
  { label: 'Maniowy', value: 'Maniowy' },
  { label: 'Manipal', value: 'Manipal' },
  { label: 'Manique Do Intendente', value: 'Manique Do Intendente' },
  { label: 'Manises', value: 'Manises' },
  { label: 'Manismata', value: 'Manismata' },
  { label: 'Manistee', value: 'Manistee' },
  { label: 'Manistee County', value: 'Manistee County' },
  { label: 'Manistique', value: 'Manistique' },
  { label: 'Manito', value: 'Manito' },
  { label: 'Manito', value: 'Manito' },
  { label: 'Manitou Beach-Devils Lake', value: 'Manitou Beach-Devils Lake' },
  { label: 'Manitou Springs', value: 'Manitou Springs' },
  { label: 'Manitoulin District', value: 'Manitoulin District' },
  { label: 'Manitouwadge', value: 'Manitouwadge' },
  { label: 'Manitowoc', value: 'Manitowoc' },
  { label: 'Manitowoc County', value: 'Manitowoc County' },
  { label: 'Maniwaki', value: 'Maniwaki' },
  { label: 'Maniwa-Shi', value: 'Maniwa-Shi' },
  { label: 'Manizales', value: 'Manizales' },
  { label: 'Manjabálago', value: 'Manjabálago' },
  { label: 'Manjarrés', value: 'Manjarrés' },
  { label: 'Manjeri', value: 'Manjeri' },
  { label: 'Manjeshwaram', value: 'Manjeshwaram' },
  { label: 'Manjhanpur', value: 'Manjhanpur' },
  { label: 'Manjil', value: 'Manjil' },
  { label: 'Manjimup', value: 'Manjimup' },
  { label: 'Manjlegaon', value: 'Manjlegaon' },
  { label: 'Manjo', value: 'Manjo' },
  { label: 'Manjoy', value: 'Manjoy' },
  { label: 'Mank', value: 'Mank' },
  { label: 'Mankachar', value: 'Mankachar' },
  { label: 'Mankapur', value: 'Mankapur' },
  { label: 'Mankato', value: 'Mankato' },
  { label: 'Mankato', value: 'Mankato' },
  { label: 'Mankayan', value: 'Mankayan' },
  { label: 'Mankayan', value: 'Mankayan' },
  { label: 'Mankera', value: 'Mankera' },
  { label: 'Mankeshwar', value: 'Mankeshwar' },
  { label: 'Mankhurd', value: 'Mankhurd' },
  { label: 'Mankivka', value: 'Mankivka' },
  { label: 'Mankoeng', value: 'Mankoeng' },
  { label: 'Mankono', value: 'Mankono' },
  { label: 'Manley Meadows', value: 'Manley Meadows' },
  { label: 'Manlius', value: 'Manlius' },
  { label: 'Manlleu', value: 'Manlleu' },
  { label: 'Manlucahoc', value: 'Manlucahoc' },
  { label: 'Manly', value: 'Manly' },
  { label: 'Manly', value: 'Manly' },
  { label: 'Manly Vale', value: 'Manly Vale' },
  { label: 'Manly West', value: 'Manly West' },
  { label: 'Manmad', value: 'Manmad' },
  { label: 'Mannarakkat', value: 'Mannarakkat' },
  { label: 'Mannargudi', value: 'Mannargudi' },
  { label: 'Männedorf', value: 'Männedorf' },
  { label: 'Männedorf / Ausserfeld', value: 'Männedorf / Ausserfeld' },
  { label: 'Männedorf / Dorfkern', value: 'Männedorf / Dorfkern' },
  { label: 'Mannering Park', value: 'Mannering Park' },
  { label: 'Mannersdorf Am Leithagebirge', value: 'Mannersdorf Am Leithagebirge' },
  { label: 'Mannersdorf An Der Rabnitz', value: 'Mannersdorf An Der Rabnitz' },
  { label: 'Manneville-Sur-Risle', value: 'Manneville-Sur-Risle' },
  { label: 'Mannford', value: 'Mannford' },
  { label: 'Mannheim', value: 'Mannheim' },
  { label: 'Manning', value: 'Manning' },
  { label: 'Manning', value: 'Manning' },
  { label: 'Manning', value: 'Manning' },
  { label: 'Manning', value: 'Manning' },
  { label: 'Manning', value: 'Manning' },
  { label: 'Manning', value: 'Manning' },
  { label: 'Manningham', value: 'Manningham' },
  { label: 'Manningham', value: 'Manningham' },
  { label: 'Mannings Hill', value: 'Mannings Hill' },
  { label: 'Mannings Hill (Rural)', value: 'Mannings Hill (Rural)' },
  { label: 'Mannington', value: 'Mannington' },
  { label: 'Manningtree', value: 'Manningtree' },
  { label: 'Mannsdorf An Der Donau', value: 'Mannsdorf An Der Donau' },
  { label: 'Mannswörth', value: 'Mannswörth' },
  { label: 'Mannum', value: 'Mannum' },
  { label: 'Mannville', value: 'Mannville' },
  { label: 'Maño', value: 'Maño' },
  { label: 'Manocalzati', value: 'Manocalzati' },
  { label: 'Manoc-Manoc', value: 'Manoc-Manoc' },
  { label: 'Manoel Emídio', value: 'Manoel Emídio' },
  { label: 'Manoel Ribas', value: 'Manoel Ribas' },
  { label: 'Manoel Urbano', value: 'Manoel Urbano' },
  { label: 'Manoel Viana', value: 'Manoel Viana' },
  { label: 'Manoel Vitorino', value: 'Manoel Vitorino' },
  { label: 'Manogpi', value: 'Manogpi' },
  { label: 'Manogpi', value: 'Manogpi' },
  { label: 'Manohar Thana', value: 'Manohar Thana' },
  { label: 'Manoharpur', value: 'Manoharpur' },
  { label: 'Manoharpur', value: 'Manoharpur' },
  { label: 'Manokwari', value: 'Manokwari' },
  { label: 'Manoláda', value: 'Manoláda' },
  { label: 'Manoleasa', value: 'Manoleasa' },
  { label: 'Manolo Fortich', value: 'Manolo Fortich' },
  { label: 'Manom', value: 'Manom' },
  { label: 'Mañón', value: 'Mañón' },
  { label: 'Manoojan', value: 'Manoojan' },
  { label: 'Manoora', value: 'Manoora' },
  { label: 'Manoppello', value: 'Manoppello' },
  { label: 'Manoppello Scalo', value: 'Manoppello Scalo' },
  { label: 'Manor', value: 'Manor' },
  { label: 'Manor', value: 'Manor' },
  { label: 'Manor', value: 'Manor' },
  { label: 'Manorhamilton', value: 'Manorhamilton' },
  { label: 'Manorhaven', value: 'Manorhaven' },
  { label: 'Manorville', value: 'Manorville' },
  { label: 'Manosque', value: 'Manosque' },
  { label: 'Manouba', value: 'Manouba' },
  { label: 'Manowa', value: 'Manowa' },
  { label: 'Manp’O', value: 'Manp’O' },
  { label: 'Manpur', value: 'Manpur' },
  { label: 'Manquillos', value: 'Manquillos' },
  { label: 'Manquiring', value: 'Manquiring' },
  { label: 'Manresa', value: 'Manresa' },
  { label: 'Mansa', value: 'Mansa' },
  { label: 'Mansa', value: 'Mansa' },
  { label: 'Mansa', value: 'Mansa' },
  { label: 'Mansa Konko', value: 'Mansa Konko' },
  { label: 'Mansac', value: 'Mansac' },
  { label: 'Mansalay', value: 'Mansalay' },
  { label: 'Mansar', value: 'Mansar' },
  { label: 'Manschnow', value: 'Manschnow' },
  { label: 'Mansehra', value: 'Mansehra' },
  { label: 'Mansfeld', value: 'Mansfeld' },
  { label: 'Mansfield', value: 'Mansfield' },
  { label: 'Mansfield', value: 'Mansfield' },
  { label: 'Mansfield', value: 'Mansfield' },
  { label: 'Mansfield', value: 'Mansfield' },
  { label: 'Mansfield', value: 'Mansfield' },
  { label: 'Mansfield', value: 'Mansfield' },
  { label: 'Mansfield', value: 'Mansfield' },
  { label: 'Mansfield', value: 'Mansfield' },
  { label: 'Mansfield', value: 'Mansfield' },
  { label: 'Mansfield', value: 'Mansfield' },
  { label: 'Mansfield', value: 'Mansfield' },
  { label: 'Mansfield Center', value: 'Mansfield Center' },
  { label: 'Mansfield City', value: 'Mansfield City' },
  { label: 'Mansfield Park', value: 'Mansfield Park' },
  { label: 'Mansfield Woodhouse', value: 'Mansfield Woodhouse' },
  { label: 'Mansidão', value: 'Mansidão' },
  { label: 'Mansigné', value: 'Mansigné' },
  { label: 'Mansilingan', value: 'Mansilingan' },
  { label: 'Mansilla De La Sierra', value: 'Mansilla De La Sierra' },
  { label: 'Mansilla De Las Mulas', value: 'Mansilla De Las Mulas' },
  { label: 'Mansilla Mayor', value: 'Mansilla Mayor' },
  { label: 'Manskiy Rayon', value: 'Manskiy Rayon' },
  { label: 'Mansle', value: 'Mansle' },
  { label: 'Mansôa', value: 'Mansôa' },
  { label: 'Manson', value: 'Manson' },
  { label: 'Manson', value: 'Manson' },
  { label: 'Mansour', value: 'Mansour' },
  { label: 'Mansoûra', value: 'Mansoûra' },
  { label: 'Mansourah', value: 'Mansourah' },
  { label: 'Manston', value: 'Manston' },
  { label: 'Mansuè', value: 'Mansuè' },
  { label: 'Mansura', value: 'Mansura' },
  { label: 'Mansur-E Aqai', value: 'Mansur-E Aqai' },
  { label: 'Mansuriyeh', value: 'Mansuriyeh' },
  { label: 'Manta', value: 'Manta' },
  { label: 'Manta', value: 'Manta' },
  { label: 'Manta', value: 'Manta' },
  { label: 'Manta Rota', value: 'Manta Rota' },
  { label: 'Mantachie', value: 'Mantachie' },
  { label: 'Mantalongon', value: 'Mantalongon' },
  { label: 'Mantampay', value: 'Mantampay' },
  { label: 'Mantang', value: 'Mantang' },
  { label: 'Mantang', value: 'Mantang' },
  { label: 'Manteca', value: 'Manteca' },
  { label: 'Mantegazza', value: 'Mantegazza' },
  { label: 'Manteigas', value: 'Manteigas' },
  { label: 'Manteigas (São Pedro)', value: 'Manteigas (São Pedro)' },
  { label: 'Mantel', value: 'Mantel' },
  { label: 'Mantello', value: 'Mantello' },
  { label: 'Mantena', value: 'Mantena' },
  { label: 'Manteno', value: 'Manteno' },
  { label: 'Mantenópolis', value: 'Mantenópolis' },
  { label: 'Manteo', value: 'Manteo' },
  { label: 'Manternach', value: 'Manternach' },
  { label: 'Mantes-La-Jolie', value: 'Mantes-La-Jolie' },
  { label: 'Mantes-La-Ville', value: 'Mantes-La-Ville' },
  { label: 'Mantgum', value: 'Mantgum' },
  { label: 'Manthani', value: 'Manthani' },
  { label: 'Manthelan', value: 'Manthelan' },
  { label: 'Manti', value: 'Manti' },
  { label: 'Manticao', value: 'Manticao' },
  { label: 'Mantiel', value: 'Mantiel' },
  { label: 'Mantiglia Di Ardea', value: 'Mantiglia Di Ardea' },
  { label: 'Mantignana', value: 'Mantignana' },
  { label: 'Mantignano-Ugnano', value: 'Mantignano-Ugnano' },
  { label: 'Mantinos', value: 'Mantinos' },
  { label: 'Mantiquil', value: 'Mantiquil' },
  { label: 'Manto', value: 'Manto' },
  { label: 'Manto Del Río Ejido', value: 'Manto Del Río Ejido' },
  { label: 'Manton', value: 'Manton' },
  { label: 'Manton', value: 'Manton' },
  { label: 'Mantorp', value: 'Mantorp' },
  { label: 'Mantorville', value: 'Mantorville' },
  { label: 'Mantoúdi', value: 'Mantoúdi' },
  { label: 'Mantova', value: 'Mantova' },
  { label: 'Mäntsälä', value: 'Mäntsälä' },
  { label: 'Mänttä', value: 'Mänttä' },
  { label: 'Mantua', value: 'Mantua' },
  { label: 'Mantua', value: 'Mantua' },
  { label: 'Mantua', value: 'Mantua' },
  { label: 'Mantua Township', value: 'Mantua Township' },
  { label: 'Manturovo', value: 'Manturovo' },
  { label: 'Manturovo', value: 'Manturovo' },
  { label: 'Manturovskiy Rayon', value: 'Manturovskiy Rayon' },
  { label: 'Mäntyharju', value: 'Mäntyharju' },
  { label: 'Manuangan', value: 'Manuangan' },
  { label: 'Manuangan', value: 'Manuangan' },
  { label: 'Manubul', value: 'Manubul' },
  { label: 'Manubul', value: 'Manubul' },
  { label: 'Manuel', value: 'Manuel' },
  { label: 'Manuel Ávila Camacho', value: 'Manuel Ávila Camacho' },
  { label: 'Manuel Ávila Camacho', value: 'Manuel Ávila Camacho' },
  { label: 'Manuel Ávila Camacho (Ponte Duro)', value: 'Manuel Ávila Camacho (Ponte Duro)' },
  { label: 'Manuel Benavides', value: 'Manuel Benavides' },
  { label: 'Manuel Doblado', value: 'Manuel Doblado' },
  { label: 'Manuel Edgardo Ávalos (San Isidro)', value: 'Manuel Edgardo Ávalos (San Isidro)' },
  { label: 'Manuel Lazos', value: 'Manuel Lazos' },
  { label: 'Manuel León', value: 'Manuel León' },
  { label: 'Manuel María Contreras', value: 'Manuel María Contreras' },
  { label: 'Manuel Ojinaga', value: 'Manuel Ojinaga' },
  { label: 'Manuel Roxas', value: 'Manuel Roxas' },
  { label: 'Manuel Roxas', value: 'Manuel Roxas' },
  { label: 'Manuel Villalongín', value: 'Manuel Villalongín' },
  { label: 'Manuguru', value: 'Manuguru' },
  { label: 'Manujan', value: 'Manujan' },
  { label: 'Manuk Mangkaw', value: 'Manuk Mangkaw' },
  { label: 'Manuk Mangkaw', value: 'Manuk Mangkaw' },
  { label: 'Manukan', value: 'Manukan' },
  { label: 'Manukau City', value: 'Manukau City' },
  { label: 'Manunda', value: 'Manunda' },
  { label: 'Manup', value: 'Manup' },
  { label: 'Manus', value: 'Manus' },
  { label: 'Manvel', value: 'Manvel' },
  { label: 'Manvi', value: 'Manvi' },
  { label: 'Manville', value: 'Manville' },
  { label: 'Manwat', value: 'Manwat' },
  { label: 'Many', value: 'Many' },
  { label: 'Mány', value: 'Mány' },
  { label: 'Many Farms', value: 'Many Farms' },
  { label: 'Manyana', value: 'Manyana' },
  { label: 'Manyas', value: 'Manyas' },
  { label: 'Manyava', value: 'Manyava' },
  { label: 'Mânzăleşti', value: 'Mânzăleşti' },
  { label: 'Manzana De San Luis', value: 'Manzana De San Luis' },
  { label: 'Manzana La Cofradía', value: 'Manzana La Cofradía' },
  { label: 'Manzana Quinta (La Cañada)', value: 'Manzana Quinta (La Cañada)' },
  { label: 'Manzana Segunda', value: 'Manzana Segunda' },
  { label: 'Manzana Sexta Parte Centro', value: 'Manzana Sexta Parte Centro' },
  { label: 'Manzana Tercera De Santa Cruz Tepexpan', value: 'Manzana Tercera De Santa Cruz Tepexpan' },
  { label: 'Manzanal De Arriba', value: 'Manzanal De Arriba' },
  { label: 'Manzanal De Los Infantes', value: 'Manzanal De Los Infantes' },
  { label: 'Manzanal Del Barco', value: 'Manzanal Del Barco' },
  { label: 'Manzanares', value: 'Manzanares' },
  { label: 'Manzanares', value: 'Manzanares' },
  { label: 'Manzanares De Rioja', value: 'Manzanares De Rioja' },
  { label: 'Manzanares El Real', value: 'Manzanares El Real' },
  { label: 'Manzaneda', value: 'Manzaneda' },
  { label: 'Manzaneque', value: 'Manzaneque' },
  { label: 'Manzanera', value: 'Manzanera' },
  { label: 'Manzanilla', value: 'Manzanilla' },
  { label: 'Manzanillo', value: 'Manzanillo' },
  { label: 'Manzanillo', value: 'Manzanillo' },
  { label: 'Manzanillo', value: 'Manzanillo' },
  { label: 'Manzano', value: 'Manzano' },
  { label: 'Manzano El', value: 'Manzano El' },
  { label: 'Manzariyeh', value: 'Manzariyeh' },
  { label: 'Manzat', value: 'Manzat' },
  { label: 'Manzherok', value: 'Manzherok' },
  { label: 'Manzhouli', value: 'Manzhouli' },
  { label: 'Manziana', value: 'Manziana' },
  { label: 'Manziat', value: 'Manziat' },
  { label: 'Manzini', value: 'Manzini' },
  { label: 'Manzini South', value: 'Manzini South' },
  { label: 'Manzolino', value: 'Manzolino' },
  { label: 'Manzurka', value: 'Manzurka' },
  { label: 'Manzya', value: 'Manzya' },
  { label: 'Mao', value: 'Mao' },
  { label: 'Mao', value: 'Mao' },
  { label: 'Maó', value: 'Maó' },
  { label: 'Maoming', value: 'Maoming' },
  { label: 'Maonon', value: 'Maonon' },
  { label: 'Maoping', value: 'Maoping' },
  { label: 'Maoping', value: 'Maoping' },
  { label: 'Mapachapa', value: 'Mapachapa' },
  { label: 'Mapalacsiao', value: 'Mapalacsiao' },
  { label: 'Mapalacsiao', value: 'Mapalacsiao' },
  { label: 'Mapalad', value: 'Mapalad' },
  { label: 'Mapalad', value: 'Mapalad' },
  { label: 'Mapanas', value: 'Mapanas' },
  { label: 'Mapanas', value: 'Mapanas' },
  { label: 'Mapandan', value: 'Mapandan' },
  { label: 'Mapandan', value: 'Mapandan' },
  { label: 'Mapaniqui', value: 'Mapaniqui' },
  { label: 'Mapaniqui', value: 'Mapaniqui' },
  { label: 'Mapastepec', value: 'Mapastepec' },
  { label: 'Mapello', value: 'Mapello' },
  { label: 'Mapili', value: 'Mapili' },
  { label: 'Mapimí', value: 'Mapimí' },
  { label: 'Maping', value: 'Maping' },
  { label: 'Mapiri', value: 'Mapiri' },
  { label: 'Mapiripán', value: 'Mapiripán' },
  { label: 'Mapiripana', value: 'Mapiripana' },
  { label: 'Maple Bluff', value: 'Maple Bluff' },
  { label: 'Maple Creek', value: 'Maple Creek' },
  { label: 'Maple Cross', value: 'Maple Cross' },
  { label: 'Maple Glen', value: 'Maple Glen' },
  { label: 'Maple Grove', value: 'Maple Grove' },
  { label: 'Maple Heights', value: 'Maple Heights' },
  { label: 'Maple Heights-Lake Desire', value: 'Maple Heights-Lake Desire' },
  { label: 'Maple Lake', value: 'Maple Lake' },
  { label: 'Maple Park', value: 'Maple Park' },
  { label: 'Maple Plain', value: 'Maple Plain' },
  { label: 'Maple Ridge', value: 'Maple Ridge' },
  { label: 'Maple Ridge', value: 'Maple Ridge' },
  { label: 'Maple Shade', value: 'Maple Shade' },
  { label: 'Maple Valley', value: 'Maple Valley' },
  { label: 'Mapleton', value: 'Mapleton' },
  { label: 'Mapleton', value: 'Mapleton' },
  { label: 'Mapleton', value: 'Mapleton' },
  { label: 'Mapleton', value: 'Mapleton' },
  { label: 'Maplewood', value: 'Maplewood' },
  { label: 'Maplewood', value: 'Maplewood' },
  { label: 'Maplewood', value: 'Maplewood' },
  { label: 'Maplewood', value: 'Maplewood' },
  { label: 'Mapo-Gu', value: 'Mapo-Gu' },
  { label: 'Mapolopolo', value: 'Mapolopolo' },
  { label: 'Mapolopolo', value: 'Mapolopolo' },
  { label: 'Mapoon', value: 'Mapoon' },
  { label: 'Maposeni', value: 'Maposeni' },
  { label: 'Mapou', value: 'Mapou' },
  { label: 'Mappano', value: 'Mappano' },
  { label: 'Mapua', value: 'Mapua' },
  { label: 'Mapuca', value: 'Mapuca' },
  { label: 'Mapulaca', value: 'Mapulaca' },
  { label: 'Mapulo', value: 'Mapulo' },
  { label: 'Mapulot', value: 'Mapulot' },
  { label: 'Maputi', value: 'Maputi' },
  { label: 'Maputo', value: 'Maputo' },
  { label: 'Maputsoe', value: 'Maputsoe' },
  { label: 'Maqat', value: 'Maqat' },
  { label: 'Maqbanah', value: 'Maqbanah' },
  { label: 'Maqrah', value: 'Maqrah' },
  { label: 'Maqshush', value: 'Maqshush' },
  { label: 'Maqtaaral Aūdany', value: 'Maqtaaral Aūdany' },
  { label: 'Maqueda', value: 'Maqueda' },
  { label: 'Maquiapo', value: 'Maquiapo' },
  { label: 'Maquiapo', value: 'Maquiapo' },
  { label: 'Maquiling', value: 'Maquiling' },
  { label: 'Maquinchao', value: 'Maquinchao' },
  { label: 'Maquiné', value: 'Maquiné' },
  { label: 'Maquoketa', value: 'Maquoketa' },
  { label: 'Mar De Espanha', value: 'Mar De Espanha' },
  { label: 'Mar Vermelho', value: 'Mar Vermelho' },
  { label: 'Mar’’Ina Horka', value: 'Mar’’Ina Horka' },
  { label: 'Mar’Ino', value: 'Mar’Ino' },
  { label: 'Mar’Yanovka', value: 'Mar’Yanovka' },
  { label: 'Mara', value: 'Mara' },
  { label: 'Mara', value: 'Mara' },
  { label: 'Mara', value: 'Mara' },
  { label: 'Mara Rosa', value: 'Mara Rosa' },
  { label: 'Maraã', value: 'Maraã' },
  { label: 'Marabá', value: 'Marabá' },
  { label: 'Marabá Paulista', value: 'Marabá Paulista' },
  { label: 'Marabba', value: 'Marabba' },
  { label: 'Marabella', value: 'Marabella' },
  { label: 'Marabut', value: 'Marabut' },
  { label: 'Maracaçumé', value: 'Maracaçumé' },
  { label: 'Maracaí', value: 'Maracaí' },
  { label: 'Maracaibo', value: 'Maracaibo' },
  { label: 'Maracajá', value: 'Maracajá' },
  { label: 'Maracaju', value: 'Maracaju' },
  { label: 'Maracalagonis', value: 'Maracalagonis' },
  { label: 'Maracanã', value: 'Maracanã' },
  { label: 'Maracanaú', value: 'Maracanaú' },
  { label: 'Maracás', value: 'Maracás' },
  { label: 'Maracay', value: 'Maracay' },
  { label: 'Maracena', value: 'Maracena' },
  { label: 'Mărăcineni', value: 'Mărăcineni' },
  { label: 'Mărăcineni', value: 'Mărăcineni' },
  { label: 'Marādah', value: 'Marādah' },
  { label: 'Maradi', value: 'Maradi' },
  { label: 'Maragheh', value: 'Maragheh' },
  { label: 'Maragogi', value: 'Maragogi' },
  { label: 'Maragogipe', value: 'Maragogipe' },
  { label: 'Maragondon', value: 'Maragondon' },
  { label: 'Maragua', value: 'Maragua' },
  { label: 'Maraharé', value: 'Maraharé' },
  { label: 'Marahoué', value: 'Marahoué' },
  { label: 'Marahra', value: 'Marahra' },
  { label: 'Maraial', value: 'Maraial' },
  { label: 'Maraita', value: 'Maraita' },
  { label: 'Marajá Do Sena', value: 'Marajá Do Sena' },
  { label: 'Marakei', value: 'Marakei' },
  { label: 'Marakkanam', value: 'Marakkanam' },
  { label: 'Maralal', value: 'Maralal' },
  { label: 'Marale', value: 'Marale' },
  { label: 'Maralik', value: 'Maralik' },
  { label: 'Maralinga Tjarutja', value: 'Maralinga Tjarutja' },
  { label: 'Maramag', value: 'Maramag' },
  { label: 'Maramba', value: 'Maramba' },
  { label: 'Marana', value: 'Marana' },
  { label: 'Maraña', value: 'Maraña' },
  { label: 'Maranchón', value: 'Maranchón' },
  { label: 'Marand', value: 'Marand' },
  { label: 'Marandahalli', value: 'Marandahalli' },
  { label: 'Maranding', value: 'Maranding' },
  { label: 'Maranello', value: 'Maranello' },
  { label: 'Marang', value: 'Marang' },
  { label: 'Maranganí', value: 'Maranganí' },
  { label: 'Marangaroo', value: 'Marangaroo' },
  { label: 'Marange-Silvange', value: 'Marange-Silvange' },
  { label: 'Marangu', value: 'Marangu' },
  { label: 'Marangu', value: 'Marangu' },
  { label: 'Maranguape', value: 'Maranguape' },
  { label: 'Maranhãozinho', value: 'Maranhãozinho' },
  { label: 'Marano', value: 'Marano' },
  { label: 'Marano Di Napoli', value: 'Marano Di Napoli' },
  { label: 'Marano Di Valpolicella', value: 'Marano Di Valpolicella' },
  { label: 'Marano Equo', value: 'Marano Equo' },
  { label: 'Marano Lagunare', value: 'Marano Lagunare' },
  { label: 'Marano Marchesato', value: 'Marano Marchesato' },
  { label: 'Marano Principato', value: 'Marano Principato' },
  { label: 'Marano Sul Panaro', value: 'Marano Sul Panaro' },
  { label: 'Marano Ticino', value: 'Marano Ticino' },
  { label: 'Marano Vicentino', value: 'Marano Vicentino' },
  { label: 'Maranoa', value: 'Maranoa' },
  { label: 'Maranola-Trivio', value: 'Maranola-Trivio' },
  { label: 'Marañón', value: 'Marañón' },
  { label: 'Marans', value: 'Marans' },
  { label: 'Marantao', value: 'Marantao' },
  { label: 'Marantao', value: 'Marantao' },
  { label: 'Maranzana', value: 'Maranzana' },
  { label: 'Marao', value: 'Marao' },
  { label: 'Marapanim', value: 'Marapanim' },
  { label: 'Marapoama', value: 'Marapoama' },
  { label: 'Maras', value: 'Maras' },
  { label: 'Mărășești', value: 'Mărășești' },
  { label: 'Mărășești', value: 'Mărășești' },
  { label: 'Măraşu', value: 'Măraşu' },
  { label: 'Marāt', value: 'Marāt' },
  { label: 'Maratá', value: 'Maratá' },
  { label: 'Marataizes', value: 'Marataizes' },
  { label: 'Maratea', value: 'Maratea' },
  { label: 'Marathon', value: 'Marathon' },
  { label: 'Marathon', value: 'Marathon' },
  { label: 'Marathon', value: 'Marathon' },
  { label: 'Marathon County', value: 'Marathon County' },
  { label: 'Marathónas', value: 'Marathónas' },
  { label: 'Marau', value: 'Marau' },
  { label: 'Maraú', value: 'Maraú' },
  { label: 'Marausa', value: 'Marausa' },
  { label: 'Maraussan', value: 'Maraussan' },
  { label: 'Maravatío De Ocampo', value: 'Maravatío De Ocampo' },
  { label: 'Maravatío Del Encinal', value: 'Maravatío Del Encinal' },
  { label: 'Maraveh', value: 'Maraveh' },
  { label: 'Maraveh Tappeh', value: 'Maraveh Tappeh' },
  { label: 'Maravilha', value: 'Maravilha' },
  { label: 'Maravilha', value: 'Maravilha' },
  { label: 'Maravilhas', value: 'Maravilhas' },
  { label: 'Maravilla', value: 'Maravilla' },
  { label: 'Maravilla Tenejapa', value: 'Maravilla Tenejapa' },
  { label: 'Maravillas', value: 'Maravillas' },
  { label: 'Maravillas', value: 'Maravillas' },
  { label: 'Maravillas', value: 'Maravillas' },
  { label: 'Maravillas', value: 'Maravillas' },
  { label: 'Maravillas', value: 'Maravillas' },
  { label: 'Marawa', value: 'Marawa' },
  { label: 'Marawa', value: 'Marawa' },
  { label: 'Marawi', value: 'Marawi' },
  { label: 'Marawi City', value: 'Marawi City' },
  { label: 'Marawis', value: 'Marawis' },
  { label: 'Maraylya', value: 'Maraylya' },
  { label: 'Marayong', value: 'Marayong' },
  { label: 'Marayur', value: 'Marayur' },
  { label: 'Marazion', value: 'Marazion' },
  { label: 'Marazoleja', value: 'Marazoleja' },
  { label: 'Marazuela', value: 'Marazuela' },
  { label: 'Marbach Am Neckar', value: 'Marbach Am Neckar' },
  { label: 'Marbach An Der Donau', value: 'Marbach An Der Donau' },
  { label: 'Marbache', value: 'Marbache' },
  { label: 'Marbel', value: 'Marbel' },
  { label: 'Marbel', value: 'Marbel' },
  { label: 'Marbella', value: 'Marbella' },
  { label: 'Marble Bar', value: 'Marble Bar' },
  { label: 'Marble Falls', value: 'Marble Falls' },
  { label: 'Marble Hill', value: 'Marble Hill' },
  { label: 'Marblehead', value: 'Marblehead' },
  { label: 'Marbleton', value: 'Marbleton' },
  { label: 'Marbletown', value: 'Marbletown' },
  { label: 'Marboué', value: 'Marboué' },
  { label: 'Marboz', value: 'Marboz' },
  { label: 'Marburg An Der Lahn', value: 'Marburg An Der Lahn' },
  { label: 'Marbury', value: 'Marbury' },
  { label: 'Marc', value: 'Marc' },
  { label: 'Marca', value: 'Marca' },
  { label: 'Marçà', value: 'Marçà' },
  { label: 'Marcação', value: 'Marcação' },
  { label: 'Marcala', value: 'Marcala' },
  { label: 'Marcali', value: 'Marcali' },
  { label: 'Marcali Járás', value: 'Marcali Járás' },
  { label: 'Marcallo', value: 'Marcallo' },
  { label: 'Marcamps', value: 'Marcamps' },
  { label: 'Marčana', value: 'Marčana' },
  { label: 'Marcaria', value: 'Marcaria' },
  { label: 'Marcavelica', value: 'Marcavelica' },
  { label: 'Marcedusa', value: 'Marcedusa' },
  { label: 'Marcelândia', value: 'Marcelândia' },
  { label: 'Marceline', value: 'Marceline' },
  { label: 'Marcelino Ramos', value: 'Marcelino Ramos' },
  { label: 'Marcelino Rodríguez (San Ignacio)', value: 'Marcelino Rodríguez (San Ignacio)' },
  { label: 'Marcelino Vieira', value: 'Marcelino Vieira' },
  { label: 'Marčelji', value: 'Marčelji' },
  { label: 'Marcellaz-Albanais', value: 'Marcellaz-Albanais' },
  { label: 'Marcelli', value: 'Marcelli' },
  { label: 'Marcellina', value: 'Marcellina' },
  { label: 'Marcellina', value: 'Marcellina' },
  { label: 'Marcellinara', value: 'Marcellinara' },
  { label: 'Marcellus', value: 'Marcellus' },
  { label: 'Marcellus', value: 'Marcellus' },
  { label: 'Mărcești', value: 'Mărcești' },
  { label: 'Marcetelli', value: 'Marcetelli' },
  { label: 'Marcey-Les-Grèves', value: 'Marcey-Les-Grèves' },
  { label: 'March', value: 'March' },
  { label: 'March Air Force Base', value: 'March Air Force Base' },
  { label: 'March Town', value: 'March Town' },
  { label: 'Marchagaz', value: 'Marchagaz' },
  { label: 'Marchal', value: 'Marchal' },
  { label: 'Marcham', value: 'Marcham' },
  { label: 'Marchamalo', value: 'Marchamalo' },
  { label: 'Marchand', value: 'Marchand' },
  { label: 'Marche-En-Famenne', value: 'Marche-En-Famenne' },
  { label: 'Marchegg', value: 'Marchegg' },
  { label: 'Marchena', value: 'Marchena' },
  { label: 'Marchenki', value: 'Marchenki' },
  { label: 'Marcheno', value: 'Marcheno' },
  { label: 'Marcheprime', value: 'Marcheprime' },
  { label: 'Marchesino-Bovo', value: 'Marchesino-Bovo' },
  { label: 'Marchiennes', value: 'Marchiennes' },
  { label: 'Marchigüe', value: 'Marchigüe' },
  { label: 'Marchin', value: 'Marchin' },
  { label: 'Marchirolo', value: 'Marchirolo' },
  { label: 'Marchtrenk', value: 'Marchtrenk' },
  { label: 'Marchwiel', value: 'Marchwiel' },
  { label: 'Marchwood', value: 'Marchwood' },
  { label: 'Marciac', value: 'Marciac' },
  { label: 'Marciana', value: 'Marciana' },
  { label: 'Marciana Marina', value: 'Marciana Marina' },
  { label: 'Marcianise', value: 'Marcianise' },
  { label: 'Marciano', value: 'Marciano' },
  { label: 'Marciano Della Chiana', value: 'Marciano Della Chiana' },
  { label: 'Marcignago', value: 'Marcignago' },
  { label: 'Marcignana', value: 'Marcignana' },
  { label: 'Marcigny', value: 'Marcigny' },
  { label: 'Marcilla', value: 'Marcilla' },
  { label: 'Marcilla De Campos', value: 'Marcilla De Campos' },
  { label: 'Marcillac', value: 'Marcillac' },
  { label: 'Marcillac-Vallon', value: 'Marcillac-Vallon' },
  { label: 'Marcilly-En-Villette', value: 'Marcilly-En-Villette' },
  { label: 'Marcilly-Le-Châtel', value: 'Marcilly-Le-Châtel' },
  { label: 'Marcilly-Sur-Eure', value: 'Marcilly-Sur-Eure' },
  { label: 'Marcilly-Sur-Tille', value: 'Marcilly-Sur-Tille' },
  { label: 'Marcionílio Souza', value: 'Marcionílio Souza' },
  { label: 'Marciszów', value: 'Marciszów' },
  { label: 'Marck', value: 'Marck' },
  { label: 'Marckolsheim', value: 'Marckolsheim' },
  { label: 'Marco', value: 'Marco' },
  { label: 'Marco', value: 'Marco' },
  { label: 'Marco', value: 'Marco' },
  { label: 'Marco De Canaveses', value: 'Marco De Canaveses' },
  { label: 'Marco De Canavezes', value: 'Marco De Canavezes' },
  { label: 'Marco Island', value: 'Marco Island' },
  { label: 'Marco Simone', value: 'Marco Simone' },
  { label: 'Marcoing', value: 'Marcoing' },
  { label: 'Marcol', value: 'Marcol' },
  { label: 'Marcolândia', value: 'Marcolândia' },
  { label: 'Marçon', value: 'Marçon' },
  { label: 'Marcon-Gaggio-Colmello', value: 'Marcon-Gaggio-Colmello' },
  { label: 'Marconi', value: 'Marconi' },
  { label: 'Marconia', value: 'Marconia' },
  { label: 'Marconne', value: 'Marconne' },
  { label: 'Marconnelle', value: 'Marconnelle' },
  { label: 'Marcoola', value: 'Marcoola' },
  { label: 'Marcorignan', value: 'Marcorignan' },
  { label: 'Marcos', value: 'Marcos' },
  { label: 'Marcos', value: 'Marcos' },
  { label: 'Marcos Juárez', value: 'Marcos Juárez' },
  { label: 'Marcos Parente', value: 'Marcos Parente' },
  { label: 'Marcoussis', value: 'Marcoussis' },
  { label: 'Marcovia', value: 'Marcovia' },
  { label: 'Marcq-En-Barœul', value: 'Marcq-En-Barœul' },
  { label: 'Mărculeni', value: 'Mărculeni' },
  { label: 'Mărculeşti', value: 'Mărculeşti' },
  { label: 'Mărculești', value: 'Mărculești' },
  { label: 'Marcus', value: 'Marcus' },
  { label: 'Marcus Hook', value: 'Marcus Hook' },
  { label: 'Mardakan', value: 'Mardakan' },
  { label: 'Mardan', value: 'Mardan' },
  { label: 'Mardehak', value: 'Mardehak' },
  { label: 'Marden', value: 'Marden' },
  { label: 'Marden', value: 'Marden' },
  { label: 'Mardeuil', value: 'Mardeuil' },
  { label: 'Mardi', value: 'Mardi' },
  { label: 'Mardié', value: 'Mardié' },
  { label: 'Mardimago', value: 'Mardimago' },
  { label: 'Maré', value: 'Maré' },
  { label: 'Mare Dalbert', value: 'Mare Dalbert' },
  { label: 'Mare La Chaux', value: 'Mare La Chaux' },
  { label: 'Mare Tabac', value: 'Mare Tabac' },
  { label: 'Mareau-Aux-Prés', value: 'Mareau-Aux-Prés' },
  { label: 'Marechal Cândido Rondon', value: 'Marechal Cândido Rondon' },
  { label: 'Marechal Deodoro', value: 'Marechal Deodoro' },
  { label: 'Marechal Floriano', value: 'Marechal Floriano' },
  { label: 'Marechal Thaumaturgo', value: 'Marechal Thaumaturgo' },
  { label: 'Mareeba', value: 'Mareeba' },
  { label: 'Maregaon', value: 'Maregaon' },
  { label: 'Mareil-Marly', value: 'Mareil-Marly' },
  { label: 'Mareil-Sur-Mauldre', value: 'Mareil-Sur-Mauldre' },
  { label: 'Marema', value: 'Marema' },
  { label: 'Marene', value: 'Marene' },
  { label: 'Marengo', value: 'Marengo' },
  { label: 'Marengo', value: 'Marengo' },
  { label: 'Marengo County', value: 'Marengo County' },
  { label: 'Marennes', value: 'Marennes' },
  { label: 'Marennes', value: 'Marennes' },
  { label: 'Mareno Di Piave', value: 'Mareno Di Piave' },
  { label: 'Marentino', value: 'Marentino' },
  { label: 'Mareș', value: 'Mareș' },
  { label: 'Maresso', value: 'Maresso' },
  { label: 'Maretto', value: 'Maretto' },
  { label: 'Maretz', value: 'Maretz' },
  { label: 'Mareuil', value: 'Mareuil' },
  { label: 'Mareuil-Le-Port', value: 'Mareuil-Le-Port' },
  { label: 'Mareuil-Lès-Meaux', value: 'Mareuil-Lès-Meaux' },
  { label: 'Mareuil-Sur-Ay', value: 'Mareuil-Sur-Ay' },
  { label: 'Mareuil-Sur-Cher', value: 'Mareuil-Sur-Cher' },
  { label: 'Mareuil-Sur-Lay-Dissais', value: 'Mareuil-Sur-Lay-Dissais' },
  { label: 'Mareuil-Sur-Ourcq', value: 'Mareuil-Sur-Ourcq' },
  { label: 'Marevo', value: 'Marevo' },
  { label: 'Marevskiy Rayon', value: 'Marevskiy Rayon' },
  { label: 'Marfa', value: 'Marfa' },
  { label: 'Marfil', value: 'Marfil' },
  { label: 'Marfino', value: 'Marfino' },
  { label: 'Marfino', value: 'Marfino' },
  { label: 'Marfovka', value: 'Marfovka' },
  { label: 'Marg‘Ilon', value: 'Marg‘Ilon' },
  { label: 'Marga', value: 'Marga' },
  { label: 'Margahayukencana', value: 'Margahayukencana' },
  { label: 'Margahovit', value: 'Margahovit' },
  { label: 'Margalef', value: 'Margalef' },
  { label: 'Marganell', value: 'Marganell' },
  { label: 'Marganlar', value: 'Marganlar' },
  { label: 'Margara', value: 'Margara' },
  { label: 'Margaret', value: 'Margaret' },
  { label: 'Margaret River', value: 'Margaret River' },
  { label: 'Margarethen Am Moos', value: 'Margarethen Am Moos' },
  { label: 'Margaride', value: 'Margaride' },
  { label: 'Margarita', value: 'Margarita' },
  { label: 'Margarita', value: 'Margarita' },
  { label: 'Margarita Belén', value: 'Margarita Belén' },
  { label: 'Margaritas', value: 'Margaritas' },
  { label: 'Mărgăriteşti', value: 'Mărgăriteşti' },
  { label: 'Margasari', value: 'Margasari' },
  { label: 'Margate', value: 'Margate' },
  { label: 'Margate', value: 'Margate' },
  { label: 'Margate', value: 'Margate' },
  { label: 'Margate', value: 'Margate' },
  { label: 'Margate', value: 'Margate' },
  { label: 'Margate City', value: 'Margate City' },
  { label: 'Mărgău', value: 'Mărgău' },
  { label: 'Margaux', value: 'Margaux' },
  { label: 'Margen', value: 'Margen' },
  { label: 'Margen', value: 'Margen' },
  { label: 'Margencel', value: 'Margencel' },
  { label: 'Margency', value: 'Margency' },
  { label: 'Margetshöchheim', value: 'Margetshöchheim' },
  { label: 'Margherita', value: 'Margherita' },
  { label: 'Margherita', value: 'Margherita' },
  { label: 'Margherita Di Savoia', value: 'Margherita Di Savoia' },
  { label: 'Marghita', value: 'Marghita' },
  { label: 'Margina', value: 'Margina' },
  { label: 'Margine Coperta-Traversagna', value: 'Margine Coperta-Traversagna' },
  { label: 'Marginea', value: 'Marginea' },
  { label: 'Mărgineni', value: 'Mărgineni' },
  { label: 'Mărgineni', value: 'Mărgineni' },
  { label: 'Mărgineni Slobozia', value: 'Mărgineni Slobozia' },
  { label: 'Mărginenii De Jos', value: 'Mărginenii De Jos' },
  { label: 'Mărginenii De Sus', value: 'Mărginenii De Sus' },
  { label: 'Mărgineni-Munteni', value: 'Mărgineni-Munteni' },
  { label: 'Margita', value: 'Margita' },
  { label: 'Margno', value: 'Margno' },
  { label: 'Margny-Lès-Compiègne', value: 'Margny-Lès-Compiègne' },
  { label: 'Margon', value: 'Margon' },
  { label: 'Margonin', value: 'Margonin' },
  { label: 'Margoon', value: 'Margoon' },
  { label: 'Margos', value: 'Margos' },
  { label: 'Margosatubig', value: 'Margosatubig' },
  { label: 'Margraten', value: 'Margraten' },
  { label: 'Marguerittes', value: 'Marguerittes' },
  { label: 'Marhamat', value: 'Marhamat' },
  { label: 'Marhamat Tumani', value: 'Marhamat Tumani' },
  { label: 'Marhanets’', value: 'Marhanets’' },
  { label: 'Marhaura', value: 'Marhaura' },
  { label: 'Marhoum', value: 'Marhoum' },
  { label: 'Mari', value: 'Mari' },
  { label: 'Maria', value: 'Maria' },
  { label: 'María', value: 'María' },
  { label: 'Maria Alm Am Steinernen Meer', value: 'Maria Alm Am Steinernen Meer' },
  { label: 'Maria Antonia', value: 'Maria Antonia' },
  { label: 'Maria Aurora', value: 'Maria Aurora' },
  { label: 'Maria Aurora', value: 'Maria Aurora' },
  { label: 'María Aurora', value: 'María Aurora' },
  { label: 'María Auxiliadora', value: 'María Auxiliadora' },
  { label: 'María Chiquita', value: 'María Chiquita' },
  { label: 'Maria Cristina', value: 'Maria Cristina' },
  { label: 'Maria Da Fé', value: 'Maria Da Fé' },
  { label: 'María De Huerva', value: 'María De Huerva' },
  { label: 'Maria De La Salut', value: 'Maria De La Salut' },
  { label: 'María De La Torre', value: 'María De La Torre' },
  { label: 'María Elena', value: 'María Elena' },
  { label: 'Maria Ellend', value: 'Maria Ellend' },
  { label: 'Maria Enzersdorf', value: 'Maria Enzersdorf' },
  { label: 'Maria Helena', value: 'Maria Helena' },
  { label: 'María La Baja', value: 'María La Baja' },
  { label: 'Maria Laach Am Jauerling', value: 'Maria Laach Am Jauerling' },
  { label: 'Maria Lankowitz', value: 'Maria Lankowitz' },
  { label: 'Maria Lanzendorf', value: 'Maria Lanzendorf' },
  { label: 'María Lombardo De Caso', value: 'María Lombardo De Caso' },
  { label: 'Maria Neustift', value: 'Maria Neustift' },
  { label: 'María Pinto', value: 'María Pinto' },
  { label: 'Maria Rain', value: 'Maria Rain' },
  { label: 'Maria Saal', value: 'Maria Saal' },
  { label: 'Maria Schmolln', value: 'Maria Schmolln' },
  { label: 'Maria Taferl', value: 'Maria Taferl' },
  { label: 'Maria Wörth', value: 'Maria Wörth' },
  { label: 'Maria-Anzbach', value: 'Maria-Anzbach' },
  { label: 'Mariager', value: 'Mariager' },
  { label: 'Mariagerfjord Kommune', value: 'Mariagerfjord Kommune' },
  { label: 'Máriahalom', value: 'Máriahalom' },
  { label: 'Mariahof', value: 'Mariahof' },
  { label: 'Mariahout', value: 'Mariahout' },
  { label: 'Mariahu', value: 'Mariahu' },
  { label: 'Mariakani', value: 'Mariakani' },
  { label: 'Marialva', value: 'Marialva' },
  { label: 'Marialva', value: 'Marialva' },
  { label: 'Marian', value: 'Marian' },
  { label: 'Mariana', value: 'Mariana' },
  { label: 'Mariana', value: 'Mariana' },
  { label: 'Mariana Mantovana', value: 'Mariana Mantovana' },
  { label: 'Mariana Pimentel', value: 'Mariana Pimentel' },
  { label: 'Mariani', value: 'Mariani' },
  { label: 'Marianka', value: 'Marianka' },
  { label: 'Marianna', value: 'Marianna' },
  { label: 'Marianna', value: 'Marianna' },
  { label: 'Marianne', value: 'Marianne' },
  { label: 'Mariannelund', value: 'Mariannelund' },
  { label: 'Mariano', value: 'Mariano' },
  { label: 'Mariano Balleza', value: 'Mariano Balleza' },
  { label: 'Mariano Colón', value: 'Mariano Colón' },
  { label: 'Mariano Comense', value: 'Mariano Comense' },
  { label: 'Mariano Del Friuli', value: 'Mariano Del Friuli' },
  { label: 'Mariano Escobedo', value: 'Mariano Escobedo' },
  { label: 'Mariano Escobedo', value: 'Mariano Escobedo' },
  { label: 'Mariano I. Loza', value: 'Mariano I. Loza' },
  { label: 'Mariano Marcos', value: 'Mariano Marcos' },
  { label: 'Mariano Marcos', value: 'Mariano Marcos' },
  { label: 'Mariano Matamoros', value: 'Mariano Matamoros' },
  { label: 'Mariano Moreno', value: 'Mariano Moreno' },
  { label: 'Mariano Moro', value: 'Mariano Moro' },
  { label: 'Mariano Roque Alonso', value: 'Mariano Roque Alonso' },
  { label: 'Marianopoli', value: 'Marianopoli' },
  { label: 'Marianópolis Do Tocantins', value: 'Marianópolis Do Tocantins' },
  { label: 'Marianowo', value: 'Marianowo' },
  { label: 'Mariánské Lázně', value: 'Mariánské Lázně' },
  { label: 'Mariapfarr', value: 'Mariapfarr' },
  { label: 'Máriapócs', value: 'Máriapócs' },
  { label: 'Mariápolis', value: 'Mariápolis' },
  { label: 'Mariaposching', value: 'Mariaposching' },
  { label: 'Mariara', value: 'Mariara' },
  { label: 'Mariarade', value: 'Mariarade' },
  { label: 'Mariasdorf', value: 'Mariasdorf' },
  { label: 'Mariastein', value: 'Mariastein' },
  { label: 'Mariatal', value: 'Mariatal' },
  { label: 'Mariato District', value: 'Mariato District' },
  { label: 'Mariatrost', value: 'Mariatrost' },
  { label: 'Mariazell', value: 'Mariazell' },
  { label: 'Marib', value: 'Marib' },
  { label: 'Marib', value: 'Marib' },
  { label: 'Marib City', value: 'Marib City' },
  { label: 'Maribo', value: 'Maribo' },
  { label: 'Maribojoc', value: 'Maribojoc' },
  { label: 'Maribondo', value: 'Maribondo' },
  { label: 'Maribong', value: 'Maribong' },
  { label: 'Maribor', value: 'Maribor' },
  { label: 'Maribyrnong', value: 'Maribyrnong' },
  { label: 'Maricá', value: 'Maricá' },
  { label: 'Maricaban', value: 'Maricaban' },
  { label: 'Maricalom', value: 'Maricalom' },
  { label: 'Maricao', value: 'Maricao' },
  { label: 'Maricao', value: 'Maricao' },
  { label: 'Maricao Municipio', value: 'Maricao Municipio' },
  { label: 'Marićka', value: 'Marićka' },
  { label: 'Maricopa', value: 'Maricopa' },
  { label: 'Maricopa', value: 'Maricopa' },
  { label: 'Maricopa County', value: 'Maricopa County' },
  { label: 'Marieberg', value: 'Marieberg' },
  { label: 'Mariefred', value: 'Mariefred' },
  { label: 'Mariehamn', value: 'Mariehamn' },
  { label: 'Marieholm', value: 'Marieholm' },
  { label: 'Marieholm', value: 'Marieholm' },
  { label: 'Mariel', value: 'Mariel' },
  { label: 'Marielund', value: 'Marielund' },
  { label: 'Mariemont', value: 'Mariemont' },
  { label: 'Marienberg', value: 'Marienberg' },
  { label: 'Mariënburg', value: 'Mariënburg' },
  { label: 'Mariendorf', value: 'Mariendorf' },
  { label: 'Marienfelde', value: 'Marienfelde' },
  { label: 'Marienhafe', value: 'Marienhafe' },
  { label: 'Marienheide', value: 'Marienheide' },
  { label: 'Marienrachdorf', value: 'Marienrachdorf' },
  { label: 'Mariental', value: 'Mariental' },
  { label: 'Mariental', value: 'Mariental' },
  { label: 'Marienthal', value: 'Marienthal' },
  { label: 'Marienville', value: 'Marienville' },
  { label: 'Marienwerder', value: 'Marienwerder' },
  { label: 'Maries County', value: 'Maries County' },
  { label: 'Mariestad', value: 'Mariestad' },
  { label: 'Marietta', value: 'Marietta' },
  { label: 'Marietta', value: 'Marietta' },
  { label: 'Marietta', value: 'Marietta' },
  { label: 'Marietta', value: 'Marietta' },
  { label: 'Marietta', value: 'Marietta' },
  { label: 'Marietta-Alderwood', value: 'Marietta-Alderwood' },
  { label: 'Marieville', value: 'Marieville' },
  { label: 'Mariglianella', value: 'Mariglianella' },
  { label: 'Marigliano', value: 'Marigliano' },
  { label: 'Marignane', value: 'Marignane' },
  { label: 'Marigné-Laillé', value: 'Marigné-Laillé' },
  { label: 'Marignier', value: 'Marignier' },
  { label: 'Marigny', value: 'Marigny' },
  { label: 'Marigny-Brizay', value: 'Marigny-Brizay' },
  { label: 'Marigny-Le-Châtel', value: 'Marigny-Le-Châtel' },
  { label: 'Marigny-Les-Usages', value: 'Marigny-Les-Usages' },
  { label: 'Marigot', value: 'Marigot' },
  { label: 'Marigot', value: 'Marigot' },
  { label: 'Marigot', value: 'Marigot' },
  { label: 'Marihatag', value: 'Marihatag' },
  { label: 'Marihatag', value: 'Marihatag' },
  { label: 'Mariinsk', value: 'Mariinsk' },
  { label: 'Mariinskiy Posad', value: 'Mariinskiy Posad' },
  { label: 'Mariinsko-Posadskiy Rayon', value: 'Mariinsko-Posadskiy Rayon' },
  { label: 'Marija Bistrica', value: 'Marija Bistrica' },
  { label: 'Marijampolė', value: 'Marijampolė' },
  { label: 'Marijampolė Municipality', value: 'Marijampolė Municipality' },
  { label: 'Marijanci', value: 'Marijanci' },
  { label: 'Marikina', value: 'Marikina' },
  { label: 'Marilac', value: 'Marilac' },
  { label: 'Marilândia', value: 'Marilândia' },
  { label: 'Marilândia Do Sul', value: 'Marilândia Do Sul' },
  { label: 'Marilao', value: 'Marilao' },
  { label: 'Marilao', value: 'Marilao' },
  { label: 'Marilena', value: 'Marilena' },
  { label: 'Marília', value: 'Marília' },
  { label: 'Mariluz', value: 'Mariluz' },
  { label: 'Marin', value: 'Marin' },
  { label: 'Marin', value: 'Marin' },
  { label: 'Marín', value: 'Marín' },
  { label: 'Marín', value: 'Marín' },
  { label: 'Marin City', value: 'Marin City' },
  { label: 'Marin County', value: 'Marin County' },
  { label: 'Marina', value: 'Marina' },
  { label: 'Marina', value: 'Marina' },
  { label: 'Marína', value: 'Marína' },
  { label: 'Marina De Cudeyo', value: 'Marina De Cudeyo' },
  { label: 'Marina Del Rey', value: 'Marina Del Rey' },
  { label: 'Marina Di Altidona', value: 'Marina Di Altidona' },
  { label: 'Marina Di Andora', value: 'Marina Di Andora' },
  { label: 'Marina Di Ardea-Tor San Lorenzo', value: 'Marina Di Ardea-Tor San Lorenzo' },
  { label: 'Marina Di Camerota', value: 'Marina Di Camerota' },
  { label: 'Marina Di Campo', value: 'Marina Di Campo' },
  { label: 'Marina Di Caronia', value: 'Marina Di Caronia' },
  { label: 'Marina Di Carrara', value: 'Marina Di Carrara' },
  { label: 'Marina Di Casal Velino', value: 'Marina Di Casal Velino' },
  { label: 'Marina Di Cerveteri', value: 'Marina Di Cerveteri' },
  { label: 'Marina Di Davoli', value: 'Marina Di Davoli' },
  { label: 'Marina Di Fuscaldo', value: 'Marina Di Fuscaldo' },
  { label: 'Marina Di Ginosa', value: 'Marina Di Ginosa' },
  { label: 'Marina Di Gioiosa Ionica', value: 'Marina Di Gioiosa Ionica' },
  { label: 'Marina Di Grosseto', value: 'Marina Di Grosseto' },
  { label: 'Marina Di Massa', value: 'Marina Di Massa' },
  { label: 'Marina Di Montemarciano', value: 'Marina Di Montemarciano' },
  { label: 'Marina Di Pisa', value: 'Marina Di Pisa' },
  { label: 'Marina Di Pisa-Tirrenia-Calambrone', value: 'Marina Di Pisa-Tirrenia-Calambrone' },
  { label: 'Marina Di Ragusa', value: 'Marina Di Ragusa' },
  { label: 'Marina Di Ravenna', value: 'Marina Di Ravenna' },
  { label: 'Marina Di San Vito', value: 'Marina Di San Vito' },
  { label: 'Marina Di Schiavonea', value: 'Marina Di Schiavonea' },
  { label: 'Marina Di Vasto', value: 'Marina Di Vasto' },
  { label: 'Marina Romea', value: 'Marina Romea' },
  { label: 'Marina San Nicola', value: 'Marina San Nicola' },
  { label: 'Marinaleda', value: 'Marinaleda' },
  { label: 'Marine City', value: 'Marine City' },
  { label: 'Marine Corps Base Hawaii - Mcbh', value: 'Marine Corps Base Hawaii - Mcbh' },
  { label: 'Marine Parade', value: 'Marine Parade' },
  { label: 'Marinella', value: 'Marinella' },
  { label: 'Marineo', value: 'Marineo' },
  { label: 'Marin-Epagnier', value: 'Marin-Epagnier' },
  { label: 'Mariners Harbor', value: 'Mariners Harbor' },
  { label: 'Marines', value: 'Marines' },
  { label: 'Marines', value: 'Marines' },
  { label: 'Marines Los', value: 'Marines Los' },
  { label: 'Marinette', value: 'Marinette' },
  { label: 'Marinette County', value: 'Marinette County' },
  { label: 'Maringá', value: 'Maringá' },
  { label: 'Maring-Noviand', value: 'Maring-Noviand' },
  { label: 'Maringouin', value: 'Maringouin' },
  { label: 'Maringues', value: 'Maringues' },
  { label: 'Marinha Grande', value: 'Marinha Grande' },
  { label: 'Marinhais', value: 'Marinhais' },
  { label: 'Marinhas', value: 'Marinhas' },
  { label: 'Marinići', value: 'Marinići' },
  { label: 'Marinilla', value: 'Marinilla' },
  { label: 'Marino', value: 'Marino' },
  { label: 'Marino', value: 'Marino' },
  { label: 'Marino', value: 'Marino' },
  { label: 'Marino', value: 'Marino' },
  { label: 'Marinópolis', value: 'Marinópolis' },
  { label: 'Marintoc', value: 'Marintoc' },
  { label: 'Mário Campos', value: 'Mário Campos' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marion Center', value: 'Marion Center' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marion County', value: 'Marion County' },
  { label: 'Marionville', value: 'Marionville' },
  { label: 'Mariópolis', value: 'Mariópolis' },
  { label: 'Mariotto', value: 'Mariotto' },
  { label: 'Maripa', value: 'Maripa' },
  { label: 'Maripá', value: 'Maripá' },
  { label: 'Maripá De Minas', value: 'Maripá De Minas' },
  { label: 'Maripí', value: 'Maripí' },
  { label: 'Maripipi', value: 'Maripipi' },
  { label: 'Maripipi', value: 'Maripipi' },
  { label: 'Mariposa', value: 'Mariposa' },
  { label: 'Mariposa County', value: 'Mariposa County' },
  { label: 'Mariquina', value: 'Mariquina' },
  { label: 'Mariscal Subikuski', value: 'Mariscal Subikuski' },
  { label: 'Mariscala', value: 'Mariscala' },
  { label: 'Mariscala De Juárez', value: 'Mariscala De Juárez' },
  { label: 'Mărişel', value: 'Mărişel' },
  { label: 'Mărişelu', value: 'Mărişelu' },
  { label: 'Marissa', value: 'Marissa' },
  { label: 'Marisule', value: 'Marisule' },
  { label: 'Marisule/Bon Air', value: 'Marisule/Bon Air' },
  { label: 'Marisule/East Winds', value: 'Marisule/East Winds' },
  { label: 'Marisule/La Brellotte', value: 'Marisule/La Brellotte' },
  { label: 'Marisule/Top Of The World', value: 'Marisule/Top Of The World' },
  { label: 'Măriței', value: 'Măriței' },
  { label: 'Marittima', value: 'Marittima' },
  { label: 'Marituba', value: 'Marituba' },
  { label: 'Mari-Turek', value: 'Mari-Turek' },
  { label: 'Mari-Turekskiy Rayon', value: 'Mari-Turekskiy Rayon' },
  { label: 'Mariupol', value: 'Mariupol' },
  { label: 'Marivan', value: 'Marivan' },
  { label: 'Mariveles', value: 'Mariveles' },
  { label: 'Mariveles', value: 'Mariveles' },
  { label: 'Mariyenburg', value: 'Mariyenburg' },
  { label: 'Mariz', value: 'Mariz' },
  { label: 'Marizópolis', value: 'Marizópolis' },
  { label: 'Marjaghal', value: 'Marjaghal' },
  { label: 'Marjaliza', value: 'Marjaliza' },
  { label: 'Märjamaa', value: 'Märjamaa' },
  { label: 'Märjamaa Vald', value: 'Märjamaa Vald' },
  { label: 'Marjanci', value: 'Marjanci' },
  { label: 'Marjayoûn', value: 'Marjayoûn' },
  { label: 'Marka', value: 'Marka' },
  { label: 'Markala', value: 'Markala' },
  { label: 'Markapur', value: 'Markapur' },
  { label: 'Markaryd', value: 'Markaryd' },
  { label: 'Markaz Abū Ḩammād', value: 'Markaz Abū Ḩammād' },
  { label: 'Markaz Al Uqşur', value: 'Markaz Al Uqşur' },
  { label: 'Markaz Disūq', value: 'Markaz Disūq' },
  { label: 'Markaz Jirjā', value: 'Markaz Jirjā' },
  { label: 'Markaz Sūhāj', value: 'Markaz Sūhāj' },
  { label: 'Markaz-E Ḩukūmat-E Darwēshān', value: 'Markaz-E Ḩukūmat-E Darwēshān' },
  { label: 'Markaz-E Woluswalī-Ye Āchīn', value: 'Markaz-E Woluswalī-Ye Āchīn' },
  { label: 'Markdale', value: 'Markdale' },
  { label: 'Markdorf', value: 'Markdorf' },
  { label: 'Marked Tree', value: 'Marked Tree' },
  { label: 'Markelo', value: 'Markelo' },
  { label: 'Marken', value: 'Marken' },
  { label: 'Markenbinnen', value: 'Markenbinnen' },
  { label: 'Marker', value: 'Marker' },
  { label: 'Markersbach', value: 'Markersbach' },
  { label: 'Markersdorf', value: 'Markersdorf' },
  { label: 'Markersdorf An Der Pielach', value: 'Markersdorf An Der Pielach' },
  { label: 'Markesan', value: 'Markesan' },
  { label: 'Market Bosworth', value: 'Market Bosworth' },
  { label: 'Market Deeping', value: 'Market Deeping' },
  { label: 'Market Drayton', value: 'Market Drayton' },
  { label: 'Market Harborough', value: 'Market Harborough' },
  { label: 'Market Lavington', value: 'Market Lavington' },
  { label: 'Market Overton', value: 'Market Overton' },
  { label: 'Market Rasen', value: 'Market Rasen' },
  { label: 'Market Shop', value: 'Market Shop' },
  { label: 'Market Warsop', value: 'Market Warsop' },
  { label: 'Market Weighton', value: 'Market Weighton' },
  { label: 'Markfield', value: 'Markfield' },
  { label: 'Markgrafneusiedl', value: 'Markgrafneusiedl' },
  { label: 'Markgröningen', value: 'Markgröningen' },
  { label: 'Markha', value: 'Markha' },
  { label: 'Markham', value: 'Markham' },
  { label: 'Markham', value: 'Markham' },
  { label: 'Markham', value: 'Markham' },
  { label: 'Markham', value: 'Markham' },
  { label: 'Marki', value: 'Marki' },
  { label: 'Markina-Xemein', value: 'Markina-Xemein' },
  { label: 'Markinch', value: 'Markinch' },
  { label: 'Märkisches Viertel', value: 'Märkisches Viertel' },
  { label: 'Markivka', value: 'Markivka' },
  { label: 'Markivka', value: 'Markivka' },
  { label: 'Markkleeberg', value: 'Markkleeberg' },
  { label: 'Markle', value: 'Markle' },
  { label: 'Marklkofen', value: 'Marklkofen' },
  { label: 'Marklohe', value: 'Marklohe' },
  { label: 'Markłowice', value: 'Markłowice' },
  { label: 'Markneukirchen', value: 'Markneukirchen' },
  { label: 'Markópoulo', value: 'Markópoulo' },
  { label: 'Markópoulo Oropoú', value: 'Markópoulo Oropoú' },
  { label: 'Markov Grad', value: 'Markov Grad' },
  { label: 'Markova', value: 'Markova' },
  { label: 'Markova', value: 'Markova' },
  { label: 'Markovac Našički', value: 'Markovac Našički' },
  { label: 'Markovci', value: 'Markovci' },
  { label: 'Markovo', value: 'Markovo' },
  { label: 'Markovo', value: 'Markovo' },
  { label: 'Markowa', value: 'Markowa' },
  { label: 'Markranstädt', value: 'Markranstädt' },
  { label: 'Marks', value: 'Marks' },
  { label: 'Marks', value: 'Marks' },
  { label: 'Marks Point', value: 'Marks Point' },
  { label: 'Marks Tey', value: 'Marks Tey' },
  { label: 'Marksovskiy Rayon', value: 'Marksovskiy Rayon' },
  { label: 'Marksuhl', value: 'Marksuhl' },
  { label: 'Marksville', value: 'Marksville' },
  { label: 'Markt Allhau', value: 'Markt Allhau' },
  { label: 'Markt Berolzheim', value: 'Markt Berolzheim' },
  { label: 'Markt Bibart', value: 'Markt Bibart' },
  { label: 'Markt Einersheim', value: 'Markt Einersheim' },
  { label: 'Markt Erlbach', value: 'Markt Erlbach' },
  { label: 'Markt Indersdorf', value: 'Markt Indersdorf' },
  { label: 'Markt Neuhodis', value: 'Markt Neuhodis' },
  { label: 'Markt Nordheim', value: 'Markt Nordheim' },
  { label: 'Markt Piesting', value: 'Markt Piesting' },
  { label: 'Markt Rettenbach', value: 'Markt Rettenbach' },
  { label: 'Markt Sankt Martin', value: 'Markt Sankt Martin' },
  { label: 'Markt Schwaben', value: 'Markt Schwaben' },
  { label: 'Markt Taschendorf', value: 'Markt Taschendorf' },
  { label: 'Markt Wald', value: 'Markt Wald' },
  { label: 'Marktbergel', value: 'Marktbergel' },
  { label: 'Marktbreit', value: 'Marktbreit' },
  { label: 'Marktgraitz', value: 'Marktgraitz' },
  { label: 'Marktheidenfeld', value: 'Marktheidenfeld' },
  { label: 'Marktl', value: 'Marktl' },
  { label: 'Marktleugast', value: 'Marktleugast' },
  { label: 'Marktleuthen', value: 'Marktleuthen' },
  { label: 'Marktoberdorf', value: 'Marktoberdorf' },
  { label: 'Marktoffingen', value: 'Marktoffingen' },
  { label: 'Marktredwitz', value: 'Marktredwitz' },
  { label: 'Marktrodach', value: 'Marktrodach' },
  { label: 'Marktschellenberg', value: 'Marktschellenberg' },
  { label: 'Marktschorgast', value: 'Marktschorgast' },
  { label: 'Marktsteft', value: 'Marktsteft' },
  { label: 'Marktzeuln', value: 'Marktzeuln' },
  { label: 'Markušica', value: 'Markušica' },
  { label: 'Markuszów', value: 'Markuszów' },
  { label: 'Markvartovice', value: 'Markvartovice' },
  { label: 'Markyate', value: 'Markyate' },
  { label: 'Marl', value: 'Marl' },
  { label: 'Marlboro', value: 'Marlboro' },
  { label: 'Marlboro', value: 'Marlboro' },
  { label: 'Marlboro County', value: 'Marlboro County' },
  { label: 'Marlboro Meadows', value: 'Marlboro Meadows' },
  { label: 'Marlboro Village', value: 'Marlboro Village' },
  { label: 'Marlborough', value: 'Marlborough' },
  { label: 'Marlborough', value: 'Marlborough' },
  { label: 'Marlborough', value: 'Marlborough' },
  { label: 'Marlborough', value: 'Marlborough' },
  { label: 'Marldon', value: 'Marldon' },
  { label: 'Marle', value: 'Marle' },
  { label: 'Marlengo', value: 'Marlengo' },
  { label: 'Marlenheim', value: 'Marlenheim' },
  { label: 'Marles-En-Brie', value: 'Marles-En-Brie' },
  { label: 'Marles-Les-Mines', value: 'Marles-Les-Mines' },
  { label: 'Marleston', value: 'Marleston' },
  { label: 'Marlette', value: 'Marlette' },
  { label: 'Marlhes', value: 'Marlhes' },
  { label: 'Marliana', value: 'Marliana' },
  { label: 'Marlie Hill', value: 'Marlie Hill' },
  { label: 'Marliéria', value: 'Marliéria' },
  { label: 'Marlin', value: 'Marlin' },
  { label: 'Marlín', value: 'Marlín' },
  { label: 'Marlinton', value: 'Marlinton' },
  { label: 'Marloffstein', value: 'Marloffstein' },
  { label: 'Marlow', value: 'Marlow' },
  { label: 'Marlow', value: 'Marlow' },
  { label: 'Marlow', value: 'Marlow' },
  { label: 'Marlow Heights', value: 'Marlow Heights' },
  { label: 'Marlton', value: 'Marlton' },
  { label: 'Marlton', value: 'Marlton' },
  { label: 'Marly', value: 'Marly' },
  { label: 'Marly', value: 'Marly' },
  { label: 'Marly', value: 'Marly' },
  { label: 'Marly-La-Ville', value: 'Marly-La-Ville' },
  { label: 'Marly-Le-Roi', value: 'Marly-Le-Roi' },
  { label: 'Mar-Mac', value: 'Mar-Mac' },
  { label: 'Marmaduke', value: 'Marmaduke' },
  { label: 'Marmagne', value: 'Marmagne' },
  { label: 'Marmagne', value: 'Marmagne' },
  { label: 'Marmande', value: 'Marmande' },
  { label: 'Marmara', value: 'Marmara' },
  { label: 'Marmaraereğlisi', value: 'Marmaraereğlisi' },
  { label: 'Marmarashen', value: 'Marmarashen' },
  { label: 'Marmárion', value: 'Marmárion' },
  { label: 'Marmaris', value: 'Marmaris' },
  { label: 'Marmashen', value: 'Marmashen' },
  { label: 'Marmato', value: 'Marmato' },
  { label: 'Marmelade', value: 'Marmelade' },
  { label: 'Marmeleiro', value: 'Marmeleiro' },
  { label: 'Marmeleiro', value: 'Marmeleiro' },
  { label: 'Marmelete', value: 'Marmelete' },
  { label: 'Marmelópolis', value: 'Marmelópolis' },
  { label: 'Marmentino', value: 'Marmentino' },
  { label: 'Marmet', value: 'Marmet' },
  { label: 'Marmion', value: 'Marmion' },
  { label: 'Marmirolo', value: 'Marmirolo' },
  { label: 'Marmolejo', value: 'Marmolejo' },
  { label: 'Marmora', value: 'Marmora' },
  { label: 'Marmoutier', value: 'Marmoutier' },
  { label: 'Marnardal', value: 'Marnardal' },
  { label: 'Marnate', value: 'Marnate' },
  { label: 'Marnay', value: 'Marnay' },
  { label: 'Marnaz', value: 'Marnaz' },
  { label: 'Marne', value: 'Marne' },
  { label: 'Marnes-La-Coquette', value: 'Marnes-La-Coquette' },
  { label: 'Marneuli', value: 'Marneuli' },
  { label: 'Marneulis Munitsip’Alit’Et’I', value: 'Marneulis Munitsip’Alit’Et’I' },
  { label: 'Marnheim', value: 'Marnheim' },
  { label: 'Marnhull', value: 'Marnhull' },
  { label: 'Maroa', value: 'Maroa' },
  { label: 'Maroa', value: 'Maroa' },
  { label: 'Marocchi', value: 'Marocchi' },
  { label: 'Marœuil', value: 'Marœuil' },
  { label: 'Marogong', value: 'Marogong' },
  { label: 'Marogong', value: 'Marogong' },
  { label: 'Maroilles', value: 'Maroilles' },
  { label: 'Marola', value: 'Marola' },
  { label: 'Marolambo', value: 'Marolambo' },
  { label: 'Maroldsweisach', value: 'Maroldsweisach' },
  { label: 'Marolles-En-Hurepoix', value: 'Marolles-En-Hurepoix' },
  { label: 'Marolles-Les-Braults', value: 'Marolles-Les-Braults' },
  { label: 'Marolles-Sur-Seine', value: 'Marolles-Sur-Seine' },
  { label: 'Maromitsa', value: 'Maromitsa' },
  { label: 'Maromme', value: 'Maromme' },
  { label: 'Maroñas', value: 'Maroñas' },
  { label: 'Marondera', value: 'Marondera' },
  { label: 'Marondera District', value: 'Marondera District' },
  { label: 'Marone', value: 'Marone' },
  { label: 'Marong', value: 'Marong' },
  { label: 'Maroochy River', value: 'Maroochy River' },
  { label: 'Maroochydore', value: 'Maroochydore' },
  { label: 'Maroon Town', value: 'Maroon Town' },
  { label: 'Maroondah', value: 'Maroondah' },
  { label: 'Maropati', value: 'Maropati' },
  { label: 'Maros', value: 'Maros' },
  { label: 'Maroslele', value: 'Maroslele' },
  { label: 'Marostica', value: 'Marostica' },
  { label: 'Marotta', value: 'Marotta' },
  { label: 'Maroua', value: 'Maroua' },
  { label: 'Maroubra', value: 'Maroubra' },
  { label: 'Maroúsi', value: 'Maroúsi' },
  { label: 'Marpent', value: 'Marpent' },
  { label: 'Marpingen', value: 'Marpingen' },
  { label: 'Marple', value: 'Marple' },
  { label: 'Marpod', value: 'Marpod' },
  { label: 'Marquard', value: 'Marquard' },
  { label: 'Marquartstein', value: 'Marquartstein' },
  { label: 'Marquefave', value: 'Marquefave' },
  { label: 'Marquelia', value: 'Marquelia' },
  { label: 'Marqués De Comillas', value: 'Marqués De Comillas' },
  { label: 'Marques De Souza', value: 'Marques De Souza' },
  { label: 'Marquetalia', value: 'Marquetalia' },
  { label: 'Marquette', value: 'Marquette' },
  { label: 'Marquette County', value: 'Marquette County' },
  { label: 'Marquette County', value: 'Marquette County' },
  { label: 'Marquette Heights', value: 'Marquette Heights' },
  { label: 'Marquette-En-Ostrevant', value: 'Marquette-En-Ostrevant' },
  { label: 'Marquette-Lez-Lille', value: 'Marquette-Lez-Lille' },
  { label: 'Marquillies', value: 'Marquillies' },
  { label: 'Marquinho', value: 'Marquinho' },
  { label: 'Marquion', value: 'Marquion' },
  { label: 'Marquis Estate', value: 'Marquis Estate' },
  { label: 'Marquise', value: 'Marquise' },
  { label: 'Marr', value: 'Marr' },
  { label: 'Marracos', value: 'Marracos' },
  { label: 'Marracuene District', value: 'Marracuene District' },
  { label: 'Marradi', value: 'Marradi' },
  { label: 'Marrakech', value: 'Marrakech' },
  { label: 'Marrakesh', value: 'Marrakesh' },
  { label: 'Marrara', value: 'Marrara' },
  { label: 'Marratxí', value: 'Marratxí' },
  { label: 'Marrero', value: 'Marrero' },
  { label: 'Marrickville', value: 'Marrickville' },
  { label: 'Marriott-Slaterville', value: 'Marriott-Slaterville' },
  { label: 'Marroquín', value: 'Marroquín' },
  { label: 'Marrubiu', value: 'Marrubiu' },
  { label: 'Marrum', value: 'Marrum' },
  { label: 'Marrupe', value: 'Marrupe' },
  { label: 'Mars', value: 'Mars' },
  { label: 'Mars Hill', value: 'Mars Hill' },
  { label: 'Marsa', value: 'Marsa' },
  { label: 'Mârşa', value: 'Mârşa' },
  { label: 'Mârșa', value: 'Mârșa' },
  { label: 'Marsa Alam', value: 'Marsa Alam' },
  { label: 'Marsabit', value: 'Marsabit' },
  { label: 'Marsac-En-Livradois', value: 'Marsac-En-Livradois' },
  { label: 'Marsac-Sur-Don', value: 'Marsac-Sur-Don' },
  { label: 'Marsada', value: 'Marsada' },
  { label: 'Marsada', value: 'Marsada' },
  { label: 'Marsaglia', value: 'Marsaglia' },
  { label: 'Marsaglia', value: 'Marsaglia' },
  { label: 'Marsala', value: 'Marsala' },
  { label: 'Mârşani', value: 'Mârşani' },
  { label: 'Marsannay-La-Côte', value: 'Marsannay-La-Côte' },
  { label: 'Marsanne', value: 'Marsanne' },
  { label: 'Marsaskala', value: 'Marsaskala' },
  { label: 'Marsassoum', value: 'Marsassoum' },
  { label: 'Marsat', value: 'Marsat' },
  { label: 'Marsaxlokk', value: 'Marsaxlokk' },
  { label: 'Marsberg', value: 'Marsberg' },
  { label: 'Marschacht', value: 'Marschacht' },
  { label: 'Marsciano', value: 'Marsciano' },
  { label: 'Marsden', value: 'Marsden' },
  { label: 'Marsden', value: 'Marsden' },
  { label: 'Marsdijk', value: 'Marsdijk' },
  { label: 'Marseillan', value: 'Marseillan' },
  { label: 'Marseille', value: 'Marseille' },
  { label: 'Marseille Bompard', value: 'Marseille Bompard' },
  { label: 'Marseille Endoume', value: 'Marseille Endoume' },
  { label: 'Marseille Prefecture', value: 'Marseille Prefecture' },
  { label: 'Marseille Roucas-Blanc', value: 'Marseille Roucas-Blanc' },
  { label: 'Marseille Saint-Victor', value: 'Marseille Saint-Victor' },
  { label: 'Marseille Vauban', value: 'Marseille Vauban' },
  { label: 'Marseilles', value: 'Marseilles' },
  { label: 'Marsella', value: 'Marsella' },
  { label: 'Marsens', value: 'Marsens' },
  { label: 'Marsfield', value: 'Marsfield' },
  { label: 'Marsh Harbour', value: 'Marsh Harbour' },
  { label: 'Marshall', value: 'Marshall' },
  { label: 'Marshall', value: 'Marshall' },
  { label: 'Marshall', value: 'Marshall' },
  { label: 'Marshall', value: 'Marshall' },
  { label: 'Marshall', value: 'Marshall' },
  { label: 'Marshall', value: 'Marshall' },
  { label: 'Marshall', value: 'Marshall' },
  { label: 'Marshall', value: 'Marshall' },
  { label: 'Marshall', value: 'Marshall' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshall County', value: 'Marshall County' },
  { label: 'Marshallton', value: 'Marshallton' },
  { label: 'Marshalltown', value: 'Marshalltown' },
  { label: 'Marshallville', value: 'Marshallville' },
  { label: 'Marshfield', value: 'Marshfield' },
  { label: 'Marshfield', value: 'Marshfield' },
  { label: 'Marshfield', value: 'Marshfield' },
  { label: 'Marshfield', value: 'Marshfield' },
  { label: 'Marshfield', value: 'Marshfield' },
  { label: 'Marshfield Hills', value: 'Marshfield Hills' },
  { label: 'Marshintsy', value: 'Marshintsy' },
  { label: 'Marshville', value: 'Marshville' },
  { label: 'Marsia', value: 'Marsia' },
  { label: 'Marsico Nuovo', value: 'Marsico Nuovo' },
  { label: 'Marsicovetere', value: 'Marsicovetere' },
  { label: 'Marsillargues', value: 'Marsillargues' },
  { label: 'Marsilly', value: 'Marsilly' },
  { label: 'Marsing', value: 'Marsing' },
  { label: 'Marske-By-The-Sea', value: 'Marske-By-The-Sea' },
  { label: 'Mårslet', value: 'Mårslet' },
  { label: 'Marssac-Sur-Tarn', value: 'Marssac-Sur-Tarn' },
  { label: 'Märsta', value: 'Märsta' },
  { label: 'Marstal', value: 'Marstal' },
  { label: 'Märstetten-Dorf', value: 'Märstetten-Dorf' },
  { label: 'Marston Moretaine', value: 'Marston Moretaine' },
  { label: 'Marstons Mills', value: 'Marstons Mills' },
  { label: 'Marsum', value: 'Marsum' },
  { label: 'Marsure', value: 'Marsure' },
  { label: 'Marszowice', value: 'Marszowice' },
  { label: 'Mart', value: 'Mart' },
  { label: 'Mart’Vili', value: 'Mart’Vili' },
  { label: 'Marta', value: 'Marta' },
  { label: 'Martaban', value: 'Martaban' },
  { label: 'Martakert', value: 'Martakert' },
  { label: 'Martan-Chu', value: 'Martan-Chu' },
  { label: 'Martano', value: 'Martano' },
  { label: 'Martapura', value: 'Martapura' },
  { label: 'Marte', value: 'Marte' },
  { label: 'Marte R. Gómez', value: 'Marte R. Gómez' },
  { label: 'Marte R. Gómez (Tobarito)', value: 'Marte R. Gómez (Tobarito)' },
  { label: 'Martel', value: 'Martel' },
  { label: 'Martelange', value: 'Martelange' },
  { label: 'Marteleira', value: 'Marteleira' },
  { label: 'Martellago', value: 'Martellago' },
  { label: 'Martelli-Laganosa', value: 'Martelli-Laganosa' },
  { label: 'Martensrade', value: 'Martensrade' },
  { label: 'Martensville', value: 'Martensville' },
  { label: 'Mârţeşti', value: 'Mârţeşti' },
  { label: 'Martfeld', value: 'Martfeld' },
  { label: 'Martha Brae', value: 'Martha Brae' },
  { label: 'Martha Lake', value: 'Martha Lake' },
  { label: 'Marthalen', value: 'Marthalen' },
  { label: 'Martham', value: 'Martham' },
  { label: 'Marthasville', value: 'Marthasville' },
  { label: 'Marthod', value: 'Marthod' },
  { label: 'Marti', value: 'Marti' },
  { label: 'Martí', value: 'Martí' },
  { label: 'Martiago', value: 'Martiago' },
  { label: 'Martignacco', value: 'Martignacco' },
  { label: 'Martignana Di Po', value: 'Martignana Di Po' },
  { label: 'Martignano', value: 'Martignano' },
  { label: 'Martignas-Sur-Jalle', value: 'Martignas-Sur-Jalle' },
  { label: 'Martignat', value: 'Martignat' },
  { label: 'Martigné-Briand', value: 'Martigné-Briand' },
  { label: 'Martigné-Ferchaud', value: 'Martigné-Ferchaud' },
  { label: 'Martigné-Sur-Mayenne', value: 'Martigné-Sur-Mayenne' },
  { label: 'Martigny District', value: 'Martigny District' },
  { label: 'Martigny-Combe', value: 'Martigny-Combe' },
  { label: 'Martigny-Ville', value: 'Martigny-Ville' },
  { label: 'Martigues', value: 'Martigues' },
  { label: 'Martiherrero', value: 'Martiherrero' },
  { label: 'Martil', value: 'Martil' },
  { label: 'Martillac', value: 'Martillac' },
  { label: 'Martin', value: 'Martin' },
  { label: 'Martin', value: 'Martin' },
  { label: 'Martin', value: 'Martin' },
  { label: 'Martin', value: 'Martin' },
  { label: 'Martin', value: 'Martin' },
  { label: 'Martin', value: 'Martin' },
  { label: 'Martin', value: 'Martin' },
  { label: 'Martin County', value: 'Martin County' },
  { label: 'Martin County', value: 'Martin County' },
  { label: 'Martin County', value: 'Martin County' },
  { label: 'Martin County', value: 'Martin County' },
  { label: 'Martin County', value: 'Martin County' },
  { label: 'Martin County', value: 'Martin County' },
  { label: 'Martín De La Jara', value: 'Martín De La Jara' },
  { label: 'Martín De Yeltes', value: 'Martín De Yeltes' },
  { label: 'Martín Del Río', value: 'Martín Del Río' },
  { label: 'Martín Miguel', value: 'Martín Miguel' },
  { label: 'Martín Muñoz De La Dehesa', value: 'Martín Muñoz De La Dehesa' },
  { label: 'Martín Muñoz De Las Posadas', value: 'Martín Muñoz De Las Posadas' },
  { label: 'Martina Franca', value: 'Martina Franca' },
  { label: 'Martinamor', value: 'Martinamor' },
  { label: 'Martindale', value: 'Martindale' },
  { label: 'Martin-Église', value: 'Martin-Église' },
  { label: 'Martinengo', value: 'Martinengo' },
  { label: 'Mărtineşti', value: 'Mărtineşti' },
  { label: 'Martinez', value: 'Martinez' },
  { label: 'Martinez', value: 'Martinez' },
  { label: 'Martinez', value: 'Martinez' },
  { label: 'Martínez', value: 'Martínez' },
  { label: 'Martínez De La Torre', value: 'Martínez De La Torre' },
  { label: 'Martínez Domínguez', value: 'Martínez Domínguez' },
  { label: 'Martinhagen', value: 'Martinhagen' },
  { label: 'Martinho Campos', value: 'Martinho Campos' },
  { label: 'Martiniana Po', value: 'Martiniana Po' },
  { label: 'Mărtiniş', value: 'Mărtiniş' },
  { label: 'Martínon', value: 'Martínon' },
  { label: 'Martinópole', value: 'Martinópole' },
  { label: 'Martinópolis', value: 'Martinópolis' },
  { label: 'Martins', value: 'Martins' },
  { label: 'Martins Ferry', value: 'Martins Ferry' },
  { label: 'Martins Soares', value: 'Martins Soares' },
  { label: 'Martinsberg', value: 'Martinsberg' },
  { label: 'Martinsburg', value: 'Martinsburg' },
  { label: 'Martinsburg', value: 'Martinsburg' },
  { label: 'Martinsheim', value: 'Martinsheim' },
  { label: 'Martinshöhe', value: 'Martinshöhe' },
  { label: 'Martinsicuro', value: 'Martinsicuro' },
  { label: 'Martinska Ves', value: 'Martinska Ves' },
  { label: 'Martinsville', value: 'Martinsville' },
  { label: 'Martinsville', value: 'Martinsville' },
  { label: 'Martinsville', value: 'Martinsville' },
  { label: 'Martinsville', value: 'Martinsville' },
  { label: 'Martinvast', value: 'Martinvast' },
  { label: 'Martirano', value: 'Martirano' },
  { label: 'Martirano Lombardo', value: 'Martirano Lombardo' },
  { label: 'Mártires', value: 'Mártires' },
  { label: 'Mártires', value: 'Mártires' },
  { label: 'Martis', value: 'Martis' },
  { label: 'Martizay', value: 'Martizay' },
  { label: 'Martock', value: 'Martock' },
  { label: 'Martone', value: 'Martone' },
  { label: 'Martonvásár', value: 'Martonvásár' },
  { label: 'Martonvásári Járás', value: 'Martonvásári Járás' },
  { label: 'Martorell', value: 'Martorell' },
  { label: 'Martorell', value: 'Martorell' },
  { label: 'Martorelles', value: 'Martorelles' },
  { label: 'Martos', value: 'Martos' },
  { label: 'Martres-Tolosane', value: 'Martres-Tolosane' },
  { label: 'Marttila', value: 'Marttila' },
  { label: 'Martuk', value: 'Martuk' },
  { label: 'Martuni', value: 'Martuni' },
  { label: 'Martynovo', value: 'Martynovo' },
  { label: 'Martynovskiy Rayon', value: 'Martynovskiy Rayon' },
  { label: 'Martyush', value: 'Martyush' },
  { label: 'Maru', value: 'Maru' },
  { label: 'Măru', value: 'Măru' },
  { label: 'Marudi', value: 'Marudi' },
  { label: 'Marudo', value: 'Marudo' },
  { label: 'Marueño', value: 'Marueño' },
  { label: 'Marugame', value: 'Marugame' },
  { label: 'Marugame Shi', value: 'Marugame Shi' },
  { label: 'Marugán', value: 'Marugán' },
  { label: 'Maruggio', value: 'Maruggio' },
  { label: 'Maruim', value: 'Maruim' },
  { label: 'Marulan', value: 'Marulan' },
  { label: 'Marulanda', value: 'Marulanda' },
  { label: 'Marull', value: 'Marull' },
  { label: 'Marum', value: 'Marum' },
  { label: 'Marumbi', value: 'Marumbi' },
  { label: 'Marumori', value: 'Marumori' },
  { label: 'Marunggas', value: 'Marunggas' },
  { label: 'Mărunţei', value: 'Mărunţei' },
  { label: 'Mărunțișu', value: 'Mărunțișu' },
  { label: 'Maruoka', value: 'Maruoka' },
  { label: 'Mārupe', value: 'Mārupe' },
  { label: 'Marupit', value: 'Marupit' },
  { label: 'Maruri-Jatabe', value: 'Maruri-Jatabe' },
  { label: 'Marvão', value: 'Marvão' },
  { label: 'Marvast', value: 'Marvast' },
  { label: 'Marvdasht', value: 'Marvdasht' },
  { label: 'Marvejols', value: 'Marvejols' },
  { label: 'Marvell', value: 'Marvell' },
  { label: 'Marverley', value: 'Marverley' },
  { label: 'Marvil', value: 'Marvil' },
  { label: 'Marvila', value: 'Marvila' },
  { label: 'Marvin', value: 'Marvin' },
  { label: 'Marxen', value: 'Marxen' },
  { label: 'Marxheim', value: 'Marxheim' },
  { label: 'Mary', value: 'Mary' },
  { label: 'Mary Esther', value: 'Mary Esther' },
  { label: 'Maryanaj', value: 'Maryanaj' },
  { label: 'Maryborough', value: 'Maryborough' },
  { label: 'Maryborough', value: 'Maryborough' },
  { label: 'Maryburgh', value: 'Maryburgh' },
  { label: 'Maryevka', value: 'Maryevka' },
  { label: 'Maryinka', value: 'Maryinka' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Maryland City', value: 'Maryland City' },
  { label: 'Maryland Heights', value: 'Maryland Heights' },
  { label: 'Maryport', value: 'Maryport' },
  { label: 'Marystown', value: 'Marystown' },
  { label: 'Mary-Sur-Marne', value: 'Mary-Sur-Marne' },
  { label: 'Marysville', value: 'Marysville' },
  { label: 'Marysville', value: 'Marysville' },
  { label: 'Marysville', value: 'Marysville' },
  { label: 'Marysville', value: 'Marysville' },
  { label: 'Marysville', value: 'Marysville' },
  { label: 'Marysville', value: 'Marysville' },
  { label: 'Maryvale', value: 'Maryvale' },
  { label: 'Maryville', value: 'Maryville' },
  { label: 'Maryville', value: 'Maryville' },
  { label: 'Maryville', value: 'Maryville' },
  { label: 'Maryville', value: 'Maryville' },
  { label: 'Marz', value: 'Marz' },
  { label: 'Marzabotto', value: 'Marzabotto' },
  { label: 'Marzagão', value: 'Marzagão' },
  { label: 'Marzahn', value: 'Marzahn' },
  { label: 'Marzales', value: 'Marzales' },
  { label: 'Marzan', value: 'Marzan' },
  { label: 'Marzanabad', value: 'Marzanabad' },
  { label: 'Marzanello', value: 'Marzanello' },
  { label: 'Mârzăneşti', value: 'Mârzăneşti' },
  { label: 'Marzano', value: 'Marzano' },
  { label: 'Marzano Appio', value: 'Marzano Appio' },
  { label: 'Marzano Di Nola', value: 'Marzano Di Nola' },
  { label: 'Marzi', value: 'Marzi' },
  { label: 'Marzikola', value: 'Marzikola' },
  { label: 'Marzio', value: 'Marzio' },
  { label: 'Marzling', value: 'Marzling' },
  { label: 'Marzy', value: 'Marzy' },
  { label: 'Mas De Barberans', value: 'Mas De Barberans' },
  { label: 'Mas De Las Matas', value: 'Mas De Las Matas' },
  { label: 'Masaba', value: 'Masaba' },
  { label: 'Masachapa', value: 'Masachapa' },
  { label: 'Masagua', value: 'Masagua' },
  { label: 'Masaguara', value: 'Masaguara' },
  { label: 'Masaguisi', value: 'Masaguisi' },
  { label: 'Masahili', value: 'Masahili' },
  { label: 'Masainas', value: 'Masainas' },
  { label: 'Masaka', value: 'Masaka' },
  { label: 'Masaka', value: 'Masaka' },
  { label: 'Masaki-Chō', value: 'Masaki-Chō' },
  { label: 'Masal', value: 'Masal' },
  { label: 'Masalavés', value: 'Masalavés' },
  { label: 'Masaling', value: 'Masaling' },
  { label: 'Masalipit', value: 'Masalipit' },
  { label: 'Masalipit', value: 'Masalipit' },
  { label: 'Masallātah', value: 'Masallātah' },
  { label: 'Masally', value: 'Masally' },
  { label: 'Masalovka', value: 'Masalovka' },
  { label: 'Masalukot Uno', value: 'Masalukot Uno' },
  { label: 'Masan', value: 'Masan' },
  { label: 'Masantol', value: 'Masantol' },
  { label: 'Masantol', value: 'Masantol' },
  { label: 'Masapang', value: 'Masapang' },
  { label: 'Masarac', value: 'Masarac' },
  { label: 'Masaraway', value: 'Masaraway' },
  { label: 'Masarayao', value: 'Masarayao' },
  { label: 'Masarayao', value: 'Masarayao' },
  { label: 'Masaryktown', value: 'Masaryktown' },
  { label: 'Masasi', value: 'Masasi' },
  { label: 'Masate', value: 'Masate' },
  { label: 'Masatepe', value: 'Masatepe' },
  { label: 'Masaurhi Buzurg', value: 'Masaurhi Buzurg' },
  { label: 'Masaya', value: 'Masaya' },
  { label: 'Masaya', value: 'Masaya' },
  { label: 'Masaya Sur', value: 'Masaya Sur' },
  { label: 'Masaya Sur', value: 'Masaya Sur' },
  { label: 'Masbate', value: 'Masbate' },
  { label: 'Masburg', value: 'Masburg' },
  { label: 'Mâsca', value: 'Mâsca' },
  { label: 'Mascali', value: 'Mascali' },
  { label: 'Mascalucia', value: 'Mascalucia' },
  { label: 'Mascara', value: 'Mascara' },
  { label: 'Mascaraque', value: 'Mascaraque' },
  { label: 'Maschito', value: 'Maschito' },
  { label: 'Maschiwka', value: 'Maschiwka' },
  { label: 'Masciago Primo', value: 'Masciago Primo' },
  { label: 'Mascot', value: 'Mascot' },
  { label: 'Mascot', value: 'Mascot' },
  { label: 'Mascota Municipality', value: 'Mascota Municipality' },
  { label: 'Mascote', value: 'Mascote' },
  { label: 'Mascotte', value: 'Mascotte' },
  { label: 'Mascouche', value: 'Mascouche' },
  { label: 'Mascoutah', value: 'Mascoutah' },
  { label: 'Masdenverge', value: 'Masdenverge' },
  { label: 'Masegosa', value: 'Masegosa' },
  { label: 'Masegoso', value: 'Masegoso' },
  { label: 'Masegoso De Tajuña', value: 'Masegoso De Tajuña' },
  { label: 'Maselheim', value: 'Maselheim' },
  { label: 'Maser', value: 'Maser' },
  { label: 'Masera', value: 'Masera' },
  { label: 'Maserà Di Padova', value: 'Maserà Di Padova' },
  { label: 'Maserada Sul Piave', value: 'Maserada Sul Piave' },
  { label: 'Maseru', value: 'Maseru' },
  { label: 'Masevaux', value: 'Masevaux' },
  { label: 'Masfjorden', value: 'Masfjorden' },
  { label: 'Masfout', value: 'Masfout' },
  { label: 'Mashajji', value: 'Mashajji' },
  { label: 'Masham', value: 'Masham' },
  { label: 'Mashava', value: 'Mashava' },
  { label: 'Mashhad', value: 'Mashhad' },
  { label: 'Mashhad Rizeh', value: 'Mashhad Rizeh' },
  { label: 'Mashi', value: 'Mashi' },
  { label: 'Mashiko', value: 'Mashiko' },
  { label: 'Mashkat', value: 'Mashkat' },
  { label: 'Mashpee', value: 'Mashpee' },
  { label: 'Mashraa Wa Hadnan', value: 'Mashraa Wa Hadnan' },
  { label: 'Mashtal', value: 'Mashtal' },
  { label: 'Mashtoul El Souk', value: 'Mashtoul El Souk' },
  { label: 'Masi', value: 'Masi' },
  { label: 'Masiaca', value: 'Masiaca' },
  { label: 'Maside', value: 'Maside' },
  { label: 'Masies De Roda Les', value: 'Masies De Roda Les' },
  { label: 'Masies De Voltregà Les', value: 'Masies De Voltregà Les' },
  { label: 'Masiga', value: 'Masiga' },
  { label: 'Masihat Mahd Al Hayl', value: 'Masihat Mahd Al Hayl' },
  { label: 'Masila', value: 'Masila' },
  { label: 'Masina', value: 'Masina' },
  { label: 'Masindi', value: 'Masindi' },
  { label: 'Masindi Port', value: 'Masindi Port' },
  { label: 'Masingbi', value: 'Masingbi' },
  { label: 'Masinigudi', value: 'Masinigudi' },
  { label: 'Masinloc', value: 'Masinloc' },
  { label: 'Masinloc', value: 'Masinloc' },
  { label: 'Masio', value: 'Masio' },
  { label: 'Masipi West', value: 'Masipi West' },
  { label: 'Masipi West', value: 'Masipi West' },
  { label: 'Masiri', value: 'Masiri' },
  { label: 'Masis', value: 'Masis' },
  { label: 'Masi-Torello', value: 'Masi-Torello' },
  { label: 'Masiu', value: 'Masiu' },
  { label: 'Masiu', value: 'Masiu' },
  { label: 'Masjed Soleyman', value: 'Masjed Soleyman' },
  { label: 'Masjedin', value: 'Masjedin' },
  { label: 'Maskar', value: 'Maskar' },
  { label: 'Maskinongé', value: 'Maskinongé' },
  { label: 'Maskowski Rayon', value: 'Maskowski Rayon' },
  { label: 'Masku', value: 'Masku' },
  { label: 'Maslianico', value: 'Maslianico' },
  { label: 'Maślice Małe', value: 'Maślice Małe' },
  { label: 'Maślice Wielkie', value: 'Maślice Wielkie' },
  { label: 'Maslin Beach', value: 'Maslin Beach' },
  { label: 'Masllorenç', value: 'Masllorenç' },
  { label: 'Maşloc', value: 'Maşloc' },
  { label: 'Maslog', value: 'Maslog' },
  { label: 'Maslog', value: 'Maslog' },
  { label: 'Maslog', value: 'Maslog' },
  { label: 'Maslova Pristan’', value: 'Maslova Pristan’' },
  { label: 'Maslovare', value: 'Maslovare' },
  { label: 'Maslovka', value: 'Maslovka' },
  { label: 'Maslovo', value: 'Maslovo' },
  { label: 'Masłów', value: 'Masłów' },
  { label: 'Maslyanino', value: 'Maslyanino' },
  { label: 'Maslyaninskiy Rayon', value: 'Maslyaninskiy Rayon' },
  { label: 'Masnières', value: 'Masnières' },
  { label: 'Masnou El', value: 'Masnou El' },
  { label: 'Masny', value: 'Masny' },
  { label: 'Masó', value: 'Masó' },
  { label: 'Masoli', value: 'Masoli' },
  { label: 'Mason', value: 'Mason' },
  { label: 'Mason', value: 'Mason' },
  { label: 'Mason', value: 'Mason' },
  { label: 'Mason', value: 'Mason' },
  { label: 'Mason', value: 'Mason' },
  { label: 'Mason City', value: 'Mason City' },
  { label: 'Mason City', value: 'Mason City' },
  { label: 'Mason County', value: 'Mason County' },
  { label: 'Mason County', value: 'Mason County' },
  { label: 'Mason County', value: 'Mason County' },
  { label: 'Mason County', value: 'Mason County' },
  { label: 'Mason County', value: 'Mason County' },
  { label: 'Mason County', value: 'Mason County' },
  { label: 'Mason Hall', value: 'Mason Hall' },
  { label: 'Mason Vicentino', value: 'Mason Vicentino' },
  { label: 'Masonboro', value: 'Masonboro' },
  { label: 'Masone', value: 'Masone' },
  { label: 'Masonogan', value: 'Masonogan' },
  { label: 'Masontown', value: 'Masontown' },
  { label: 'Masonville', value: 'Masonville' },
  { label: 'Måsøy', value: 'Måsøy' },
  { label: 'Masoyila', value: 'Masoyila' },
  { label: 'Maspalomas', value: 'Maspalomas' },
  { label: 'Maspeth', value: 'Maspeth' },
  { label: 'Maspujols', value: 'Maspujols' },
  { label: 'Masquefa', value: 'Masquefa' },
  { label: 'Masroig', value: 'Masroig' },
  { label: 'Massa', value: 'Massa' },
  { label: 'Massa', value: 'Massa' },
  { label: 'Massa Dalbe-Corona', value: 'Massa Dalbe-Corona' },
  { label: 'Massa Di Somma', value: 'Massa Di Somma' },
  { label: 'Massa Fermana', value: 'Massa Fermana' },
  { label: 'Massa Finalese', value: 'Massa Finalese' },
  { label: 'Massa Fiscaglia', value: 'Massa Fiscaglia' },
  { label: 'Massa Lombarda', value: 'Massa Lombarda' },
  { label: 'Massa Lubrense', value: 'Massa Lubrense' },
  { label: 'Massa Marittima', value: 'Massa Marittima' },
  { label: 'Massa Martana', value: 'Massa Martana' },
  { label: 'Massac', value: 'Massac' },
  { label: 'Massac County', value: 'Massac County' },
  { label: 'Massacre', value: 'Massacre' },
  { label: 'Massade', value: 'Massade' },
  { label: 'Massafra', value: 'Massafra' },
  { label: 'Massagno', value: 'Massagno' },
  { label: 'Massaguet', value: 'Massaguet' },
  { label: 'Massakory', value: 'Massakory' },
  { label: 'Massalavés', value: 'Massalavés' },
  { label: 'Massalcoreig', value: 'Massalcoreig' },
  { label: 'Massalengo-Motta Vigana', value: 'Massalengo-Motta Vigana' },
  { label: 'Massalfassar', value: 'Massalfassar' },
  { label: 'Massamá', value: 'Massamá' },
  { label: 'Massamá', value: 'Massamá' },
  { label: 'Massamagrell', value: 'Massamagrell' },
  { label: 'Massanassa', value: 'Massanassa' },
  { label: 'Massandra', value: 'Massandra' },
  { label: 'Massanes', value: 'Massanes' },
  { label: 'Massanetta Springs', value: 'Massanetta Springs' },
  { label: 'Massanutten', value: 'Massanutten' },
  { label: 'Massanzago-Ca Baglioni-San Dono', value: 'Massanzago-Ca Baglioni-San Dono' },
  { label: 'Massapê', value: 'Massapê' },
  { label: 'Massapê Do Piauí', value: 'Massapê Do Piauí' },
  { label: 'Massapequa', value: 'Massapequa' },
  { label: 'Massapequa Park', value: 'Massapequa Park' },
  { label: 'Massaranduba', value: 'Massaranduba' },
  { label: 'Massaranduba', value: 'Massaranduba' },
  { label: 'Massarosa', value: 'Massarosa' },
  { label: 'Massawa', value: 'Massawa' },
  { label: 'Massay', value: 'Massay' },
  { label: 'Massazza', value: 'Massazza' },
  { label: 'Maßbach', value: 'Maßbach' },
  { label: 'Massello', value: 'Massello' },
  { label: 'Massena', value: 'Massena' },
  { label: 'Massenbachhausen', value: 'Massenbachhausen' },
  { label: 'Massenya', value: 'Massenya' },
  { label: 'Massenzatico', value: 'Massenzatico' },
  { label: 'Masserano', value: 'Masserano' },
  { label: 'Masserberg', value: 'Masserberg' },
  { label: 'Masseria Vecchia Ovest', value: 'Masseria Vecchia Ovest' },
  { label: 'Masseube', value: 'Masseube' },
  { label: 'Massiac', value: 'Massiac' },
  { label: 'Massieux', value: 'Massieux' },
  { label: 'Massignano', value: 'Massignano' },
  { label: 'Massillon', value: 'Massillon' },
  { label: 'Massimeno', value: 'Massimeno' },
  { label: 'Massimino', value: 'Massimino' },
  { label: 'Massing', value: 'Massing' },
  { label: 'Massino Visconti', value: 'Massino Visconti' },
  { label: 'Massiola', value: 'Massiola' },
  { label: 'Massongy', value: 'Massongy' },
  { label: 'Massoteres', value: 'Massoteres' },
  { label: 'Maßweiler', value: 'Maßweiler' },
  { label: 'Massy', value: 'Massy' },
  { label: 'Mastacăn', value: 'Mastacăn' },
  { label: 'Măstăcani', value: 'Măstăcani' },
  { label: 'Maştağa', value: 'Maştağa' },
  { label: 'Mastaiciai', value: 'Mastaiciai' },
  { label: 'Mastershausen', value: 'Mastershausen' },
  { label: 'Masterton', value: 'Masterton' },
  { label: 'Mastic', value: 'Mastic' },
  { label: 'Mastic Beach', value: 'Mastic Beach' },
  { label: 'Mastiholi', value: 'Mastiholi' },
  { label: 'Mastung', value: 'Mastung' },
  { label: 'Mastung District', value: 'Mastung District' },
  { label: 'Masuda', value: 'Masuda' },
  { label: 'Masuda Shi', value: 'Masuda Shi' },
  { label: 'Masueco', value: 'Masueco' },
  { label: 'Masuguru', value: 'Masuguru' },
  { label: 'Masukulu', value: 'Masukulu' },
  { label: 'Masuleh', value: 'Masuleh' },
  { label: 'Masullas', value: 'Masullas' },
  { label: 'Masumbwe', value: 'Masumbwe' },
  { label: 'Masunga', value: 'Masunga' },
  { label: 'Masur India', value: 'Masur India' },
  { label: 'Masury', value: 'Masury' },
  { label: 'Masvingo', value: 'Masvingo' },
  { label: 'Masvingo District', value: 'Masvingo District' },
  { label: 'Maswa', value: 'Maswa' },
  { label: 'Maswar', value: 'Maswar' },
  { label: 'Maswarah', value: 'Maswarah' },
  { label: 'Maşyāf', value: 'Maşyāf' },
  { label: 'Masyaf District', value: 'Masyaf District' },
  { label: 'Maszewo', value: 'Maszewo' },
  { label: 'Maszewo', value: 'Maszewo' },
  { label: 'Maszewo Duże', value: 'Maszewo Duże' },
  { label: 'Maszkienice', value: 'Maszkienice' },
  { label: 'Mata', value: 'Mata' },
  { label: 'Mata De Alcántara', value: 'Mata De Alcántara' },
  { label: 'Mata De Cuéllar', value: 'Mata De Cuéllar' },
  { label: 'Mata De Indio', value: 'Mata De Indio' },
  { label: 'Mata De Ledesma La', value: 'Mata De Ledesma La' },
  { label: 'Mata De Lobos', value: 'Mata De Lobos' },
  { label: 'Mata De Los Olmos La', value: 'Mata De Los Olmos La' },
  { label: 'Mata De Morella La', value: 'Mata De Morella La' },
  { label: 'Mata De Plátano', value: 'Mata De Plátano' },
  { label: 'Mata De São João', value: 'Mata De São João' },
  { label: 'Mata Del Nance', value: 'Mata Del Nance' },
  { label: 'Mata Grande', value: 'Mata Grande' },
  { label: 'Mata La', value: 'Mata La' },
  { label: 'Mata Loma', value: 'Mata Loma' },
  { label: 'Mata Naranjo', value: 'Mata Naranjo' },
  { label: 'Mata Obscura', value: 'Mata Obscura' },
  { label: 'Mata Roma', value: 'Mata Roma' },
  { label: 'Mata Tenatito (Casco Hacienda)', value: 'Mata Tenatito (Casco Hacienda)' },
  { label: 'Mata Verde', value: 'Mata Verde' },
  { label: 'Mataas Na Kahoy', value: 'Mataas Na Kahoy' },
  { label: 'Matabao', value: 'Matabao' },
  { label: 'Matabao', value: 'Matabao' },
  { label: 'Matabuena', value: 'Matabuena' },
  { label: 'Matacães', value: 'Matacães' },
  { label: 'Matacapan', value: 'Matacapan' },
  { label: 'Matachí', value: 'Matachí' },
  { label: 'Mătăcina', value: 'Mătăcina' },
  { label: 'Matacon', value: 'Matacon' },
  { label: 'Matadeón De Los Oteros', value: 'Matadeón De Los Oteros' },
  { label: 'Matadepera', value: 'Matadepera' },
  { label: 'Matadi', value: 'Matadi' },
  { label: 'Matador', value: 'Matador' },
  { label: 'Matagalpa', value: 'Matagalpa' },
  { label: 'Matagami', value: 'Matagami' },
  { label: 'Matagbak', value: 'Matagbak' },
  { label: 'Matag-Ob', value: 'Matag-Ob' },
  { label: 'Matagorda County', value: 'Matagorda County' },
  { label: 'Mataguži', value: 'Mataguži' },
  { label: 'Matai', value: 'Matai' },
  { label: 'Matala', value: 'Matala' },
  { label: 'Matala', value: 'Matala' },
  { label: 'Matalam', value: 'Matalam' },
  { label: 'Matalam', value: 'Matalam' },
  { label: 'Matale', value: 'Matale' },
  { label: 'Matale District', value: 'Matale District' },
  { label: 'Matalebreras', value: 'Matalebreras' },
  { label: 'Matallana De Torío', value: 'Matallana De Torío' },
  { label: 'Matalom', value: 'Matalom' },
  { label: 'Matalom', value: 'Matalom' },
  { label: 'Matam', value: 'Matam' },
  { label: 'Matam Department', value: 'Matam Department' },
  { label: 'Matamala De Almazán', value: 'Matamala De Almazán' },
  { label: 'Matamata', value: 'Matamata' },
  { label: 'Matamba', value: 'Matamba' },
  { label: 'Matamey', value: 'Matamey' },
  { label: 'Matamoras', value: 'Matamoras' },
  { label: 'Matamoros', value: 'Matamoros' },
  { label: 'Matamoros', value: 'Matamoros' },
  { label: 'Matamoros', value: 'Matamoros' },
  { label: 'Matamoros', value: 'Matamoros' },
  { label: 'Matanao', value: 'Matanao' },
  { label: 'Matanao', value: 'Matanao' },
  { label: 'Matanao', value: 'Matanao' },
  { label: 'Matança', value: 'Matança' },
  { label: 'Matancillas (San Isidro Matancillas)', value: 'Matancillas (San Isidro Matancillas)' },
  { label: 'Matane', value: 'Matane' },
  { label: 'Matangad', value: 'Matangad' },
  { label: 'Matanog', value: 'Matanog' },
  { label: 'Matanog', value: 'Matanog' },
  { label: 'Matanuska-Susitna Borough', value: 'Matanuska-Susitna Borough' },
  { label: 'Matanza', value: 'Matanza' },
  { label: 'Matanza', value: 'Matanza' },
  { label: 'Matanza De Acentejo La', value: 'Matanza De Acentejo La' },
  { label: 'Matanzas', value: 'Matanzas' },
  { label: 'Matanzas', value: 'Matanzas' },
  { label: 'Matanzas', value: 'Matanzas' },
  { label: 'Matão', value: 'Matão' },
  { label: 'Mataporquera', value: 'Mataporquera' },
  { label: 'Matapozuelos', value: 'Matapozuelos' },
  { label: 'Mataquescuintla', value: 'Mataquescuintla' },
  { label: 'Matara', value: 'Matara' },
  { label: 'Matara District', value: 'Matara District' },
  { label: 'Mataraca', value: 'Mataraca' },
  { label: 'Mataram', value: 'Mataram' },
  { label: 'Mataránga', value: 'Mataránga' },
  { label: 'Mataró', value: 'Mataró' },
  { label: 'Matarrubia', value: 'Matarrubia' },
  { label: 'Mătăsari', value: 'Mătăsari' },
  { label: 'Mătăsaru', value: 'Mătăsaru' },
  { label: 'Matatlán', value: 'Matatlán' },
  { label: 'Mățău', value: 'Mățău' },
  { label: 'Mataundh', value: 'Mataundh' },
  { label: 'Matavai', value: 'Matavai' },
  { label: 'Matawan', value: 'Matawan' },
  { label: 'Matay', value: 'Matay' },
  { label: 'Maţāy', value: 'Maţāy' },
  { label: 'Mataya', value: 'Mataya' },
  { label: 'Mataya', value: 'Mataya' },
  { label: 'Matayaya', value: 'Matayaya' },
  { label: 'Matayumtayum', value: 'Matayumtayum' },
  { label: 'Matayumtayum', value: 'Matayumtayum' },
  { label: 'Mataywanac', value: 'Mataywanac' },
  { label: 'Matca', value: 'Matca' },
  { label: 'Mateeşti', value: 'Mateeşti' },
  { label: 'Matehuala', value: 'Matehuala' },
  { label: 'Matei', value: 'Matei' },
  { label: 'Mateiros', value: 'Mateiros' },
  { label: 'Matejce', value: 'Matejce' },
  { label: 'Matelândia', value: 'Matelândia' },
  { label: 'Matelica', value: 'Matelica' },
  { label: 'Matendonk', value: 'Matendonk' },
  { label: 'Matendreef', value: 'Matendreef' },
  { label: 'Matengaarde', value: 'Matengaarde' },
  { label: 'Matenhoeve', value: 'Matenhoeve' },
  { label: 'Matenhorst', value: 'Matenhorst' },
  { label: 'Matenveld', value: 'Matenveld' },
  { label: 'Mateo', value: 'Mateo' },
  { label: 'Matera', value: 'Matera' },
  { label: 'Materdomini', value: 'Materdomini' },
  { label: 'Materlândia', value: 'Materlândia' },
  { label: 'Mătești', value: 'Mătești' },
  { label: 'Mátészalka', value: 'Mátészalka' },
  { label: 'Mátészalkai Járás', value: 'Mátészalkai Járás' },
  { label: 'Matet', value: 'Matet' },
  { label: 'Mateur', value: 'Mateur' },
  { label: 'Mateus Leme', value: 'Mateus Leme' },
  { label: 'Matfors', value: 'Matfors' },
  { label: 'Matha', value: 'Matha' },
  { label: 'Mathabhanga', value: 'Mathabhanga' },
  { label: 'Mathakola', value: 'Mathakola' },
  { label: 'Mathambgwane', value: 'Mathambgwane' },
  { label: 'Mathathane', value: 'Mathathane' },
  { label: 'Mathay', value: 'Mathay' },
  { label: 'Matheny', value: 'Matheny' },
  { label: 'Matheran', value: 'Matheran' },
  { label: 'Mathews', value: 'Mathews' },
  { label: 'Mathews', value: 'Mathews' },
  { label: 'Mathews County', value: 'Mathews County' },
  { label: 'Mathi', value: 'Mathi' },
  { label: 'Mathias Lobato', value: 'Mathias Lobato' },
  { label: 'Mathieu', value: 'Mathieu' },
  { label: 'Mathis', value: 'Mathis' },
  { label: 'Mathura', value: 'Mathura' },
  { label: 'Mati', value: 'Mati' },
  { label: 'Mati', value: 'Mati' },
  { label: 'Mati', value: 'Mati' },
  { label: 'Mati', value: 'Mati' },
  { label: 'Mati', value: 'Mati' },
  { label: 'Mat-I', value: 'Mat-I' },
  { label: 'Matiali Community Development Block', value: 'Matiali Community Development Block' },
  { label: 'Matiao', value: 'Matiao' },
  { label: 'Matiao', value: 'Matiao' },
  { label: 'Matiao', value: 'Matiao' },
  { label: 'Matiari', value: 'Matiari' },
  { label: 'Matias Barbosa', value: 'Matias Barbosa' },
  { label: 'Matias Cardoso', value: 'Matias Cardoso' },
  { label: 'Matias Olímpio', value: 'Matias Olímpio' },
  { label: 'Matías Romero', value: 'Matías Romero' },
  { label: 'Maticmatic', value: 'Maticmatic' },
  { label: 'Maticmatic', value: 'Maticmatic' },
  { label: 'Matigara Community Development Block', value: 'Matigara Community Development Block' },
  { label: 'Matignon', value: 'Matignon' },
  { label: 'Matiguás', value: 'Matiguás' },
  { label: 'Matilla De Arzón', value: 'Matilla De Arzón' },
  { label: 'Matilla De Los Caños', value: 'Matilla De Los Caños' },
  { label: 'Matilla De Los Caños Del Río', value: 'Matilla De Los Caños Del Río' },
  { label: 'Matilla La', value: 'Matilla La' },
  { label: 'Matilla La Seca', value: 'Matilla La Seca' },
  { label: 'Matillas', value: 'Matillas' },
  { label: 'Matina', value: 'Matina' },
  { label: 'Matina', value: 'Matina' },
  { label: 'Matinao', value: 'Matinao' },
  { label: 'Matinella', value: 'Matinella' },
  { label: 'Matingain', value: 'Matingain' },
  { label: 'Matinha', value: 'Matinha' },
  { label: 'Matinhas', value: 'Matinhas' },
  { label: 'Matinhos', value: 'Matinhos' },
  { label: 'Matino', value: 'Matino' },
  { label: 'Matiompong', value: 'Matiompong' },
  { label: 'Matipó', value: 'Matipó' },
  { label: 'Matiri', value: 'Matiri' },
  { label: 'Matiyah', value: 'Matiyah' },
  { label: 'Matlahuacales Aquiles Serdán', value: 'Matlahuacales Aquiles Serdán' },
  { label: 'Matlang', value: 'Matlang' },
  { label: 'Matlang', value: 'Matlang' },
  { label: 'Matli', value: 'Matli' },
  { label: 'Matlock', value: 'Matlock' },
  { label: 'Matmata', value: 'Matmata' },
  { label: 'Matnog', value: 'Matnog' },
  { label: 'Mato Castelhano', value: 'Mato Castelhano' },
  { label: 'Mato Grosso', value: 'Mato Grosso' },
  { label: 'Mato Leitão', value: 'Mato Leitão' },
  { label: 'Mato Queimado', value: 'Mato Queimado' },
  { label: 'Mato Rico', value: 'Mato Rico' },
  { label: 'Mato Verde', value: 'Mato Verde' },
  { label: 'Matoaca', value: 'Matoaca' },
  { label: 'Matobo', value: 'Matobo' },
  { label: 'Matões', value: 'Matões' },
  { label: 'Matões Do Norte', value: 'Matões Do Norte' },
  { label: 'Matola', value: 'Matola' },
  { label: 'Matonga', value: 'Matonga' },
  { label: 'Matos Costa', value: 'Matos Costa' },
  { label: 'Matosinhos', value: 'Matosinhos' },
  { label: 'Matour', value: 'Matour' },
  { label: 'Matozinhos', value: 'Matozinhos' },
  { label: 'Mátraderecske', value: 'Mátraderecske' },
  { label: 'Mátranovák', value: 'Mátranovák' },
  { label: 'Mátraterenye', value: 'Mátraterenye' },
  { label: 'Mátraverebély', value: 'Mátraverebély' },
  { label: 'Matraville', value: 'Matraville' },
  { label: 'Matrei Am Brenner', value: 'Matrei Am Brenner' },
  { label: 'Matrei In Osttirol', value: 'Matrei In Osttirol' },
  { label: 'Matrice', value: 'Matrice' },
  { label: 'Mătrici', value: 'Mătrici' },
  { label: 'Matrinchã', value: 'Matrinchã' },
  { label: 'Matriz De Camaragibe', value: 'Matriz De Camaragibe' },
  { label: 'Matrosy', value: 'Matrosy' },
  { label: 'Matsanjeni', value: 'Matsanjeni' },
  { label: 'Matsubara', value: 'Matsubara' },
  { label: 'Matsubara-Shi', value: 'Matsubara-Shi' },
  { label: 'Matsubase', value: 'Matsubase' },
  { label: 'Matsudo Shi', value: 'Matsudo Shi' },
  { label: 'Matsue', value: 'Matsue' },
  { label: 'Matsue Shi', value: 'Matsue Shi' },
  { label: 'Matsukawa', value: 'Matsukawa' },
  { label: 'Matsumoto', value: 'Matsumoto' },
  { label: 'Matsumoto', value: 'Matsumoto' },
  { label: 'Matsumoto Shi', value: 'Matsumoto Shi' },
  { label: 'Matsushima', value: 'Matsushima' },
  { label: 'Matsutō', value: 'Matsutō' },
  { label: 'Matsuura', value: 'Matsuura' },
  { label: 'Matsuura Shi', value: 'Matsuura Shi' },
  { label: 'Matsuyama-Shi', value: 'Matsuyama-Shi' },
  { label: 'Matsuzaka-Shi', value: 'Matsuzaka-Shi' },
  { label: 'Mattaldi', value: 'Mattaldi' },
  { label: 'Mattanur', value: 'Mattanur' },
  { label: 'Mattapoisett', value: 'Mattapoisett' },
  { label: 'Mattapoisett Center', value: 'Mattapoisett Center' },
  { label: 'Mattarello', value: 'Mattarello' },
  { label: 'Mattawa', value: 'Mattawa' },
  { label: 'Mattawa', value: 'Mattawa' },
  { label: 'Mattawan', value: 'Mattawan' },
  { label: 'Matten', value: 'Matten' },
  { label: 'Mattersburg', value: 'Mattersburg' },
  { label: 'Matteson', value: 'Matteson' },
  { label: 'Matthew Town', value: 'Matthew Town' },
  { label: 'Matthews', value: 'Matthews' },
  { label: 'Matti', value: 'Matti' },
  { label: 'Matti', value: 'Matti' },
  { label: 'Matti', value: 'Matti' },
  { label: 'Mattie', value: 'Mattie' },
  { label: 'Mattighofen', value: 'Mattighofen' },
  { label: 'Mattinata', value: 'Mattinata' },
  { label: 'Mattishall', value: 'Mattishall' },
  { label: 'Mattituck', value: 'Mattituck' },
  { label: 'Mattoon', value: 'Mattoon' },
  { label: 'Mattsee', value: 'Mattsee' },
  { label: 'Mattur', value: 'Mattur' },
  { label: 'Mattydale', value: 'Mattydale' },
  { label: 'Matucana', value: 'Matucana' },
  { label: 'Matuguinao', value: 'Matuguinao' },
  { label: 'Matui', value: 'Matui' },
  { label: 'Matulji', value: 'Matulji' },
  { label: 'Matunga', value: 'Matunga' },
  { label: 'Matungao', value: 'Matungao' },
  { label: 'Matupá', value: 'Matupá' },
  { label: 'Maturanoc', value: 'Maturanoc' },
  { label: 'Maturanoc', value: 'Maturanoc' },
  { label: 'Maturéia', value: 'Maturéia' },
  { label: 'Maturín', value: 'Maturín' },
  { label: 'Matute', value: 'Matute' },
  { label: 'Matutina', value: 'Matutina' },
  { label: 'Matutiune District', value: 'Matutiune District' },
  { label: 'Matveyev Kurgan', value: 'Matveyev Kurgan' },
  { label: 'Matveyevka', value: 'Matveyevka' },
  { label: 'Matveyevskoye', value: 'Matveyevskoye' },
  { label: 'Matviyivka', value: 'Matviyivka' },
  { label: 'Matzaco', value: 'Matzaco' },
  { label: 'Matzam', value: 'Matzam' },
  { label: 'Matzen', value: 'Matzen' },
  { label: 'Matzendorf', value: 'Matzendorf' },
  { label: 'Matzendorf', value: 'Matzendorf' },
  { label: 'Matzenheim', value: 'Matzenheim' },
  { label: 'Matzingen', value: 'Matzingen' },
  { label: 'Mau', value: 'Mau' },
  { label: 'Mau', value: 'Mau' },
  { label: 'Mau Aima', value: 'Mau Aima' },
  { label: 'Mau Aimma', value: 'Mau Aimma' },
  { label: 'Maua', value: 'Maua' },
  { label: 'Mauá', value: 'Mauá' },
  { label: 'Mauá Da Serra', value: 'Mauá Da Serra' },
  { label: 'Mauban', value: 'Mauban' },
  { label: 'Maubara', value: 'Maubara' },
  { label: 'Maubec', value: 'Maubec' },
  { label: 'Maubec', value: 'Maubec' },
  { label: 'Maubeuge', value: 'Maubeuge' },
  { label: 'Maubin', value: 'Maubin' },
  { label: 'Mauboh', value: 'Mauboh' },
  { label: 'Mauboh', value: 'Mauboh' },
  { label: 'Maubourguet', value: 'Maubourguet' },
  { label: 'Maucatar', value: 'Maucatar' },
  { label: 'Mauchline', value: 'Mauchline' },
  { label: 'Maud', value: 'Maud' },
  { label: 'Maud', value: 'Maud' },
  { label: 'Maudaha', value: 'Maudaha' },
  { label: 'Maudsland', value: 'Maudsland' },
  { label: 'Mauensee', value: 'Mauensee' },
  { label: 'Mauer', value: 'Mauer' },
  { label: 'Mauer Bei Amstetten', value: 'Mauer Bei Amstetten' },
  { label: 'Mauerbach', value: 'Mauerbach' },
  { label: 'Mauerkirchen', value: 'Mauerkirchen' },
  { label: 'Mauern', value: 'Mauern' },
  { label: 'Mauerstetten', value: 'Mauerstetten' },
  { label: 'Maués', value: 'Maués' },
  { label: 'Mauganj', value: 'Mauganj' },
  { label: 'Maugansville', value: 'Maugansville' },
  { label: 'Maugat West', value: 'Maugat West' },
  { label: 'Maugeri', value: 'Maugeri' },
  { label: 'Mauguio', value: 'Mauguio' },
  { label: 'Mauhao', value: 'Mauhao' },
  { label: 'Maui County', value: 'Maui County' },
  { label: 'Maulawin', value: 'Maulawin' },
  { label: 'Maulbronn', value: 'Maulbronn' },
  { label: 'Maulburg', value: 'Maulburg' },
  { label: 'Maulden', value: 'Maulden' },
  { label: 'Mauldin', value: 'Mauldin' },
  { label: 'Maule', value: 'Maule' },
  { label: 'Maule', value: 'Maule' },
  { label: 'Mauléon-Licharre', value: 'Mauléon-Licharre' },
  { label: 'Maulévrier', value: 'Maulévrier' },
  { label: 'Maullín', value: 'Maullín' },
  { label: 'Maumee', value: 'Maumee' },
  { label: 'Maumelle', value: 'Maumelle' },
  { label: 'Maumere', value: 'Maumere' },
  { label: 'Maun', value: 'Maun' },
  { label: 'Maunabo', value: 'Maunabo' },
  { label: 'Maunabo', value: 'Maunabo' },
  { label: 'Maunabo Municipio', value: 'Maunabo Municipio' },
  { label: 'Maunatlala', value: 'Maunatlala' },
  { label: 'Maunawili', value: 'Maunawili' },
  { label: 'Maungatapere', value: 'Maungatapere' },
  { label: 'Maur Mandi', value: 'Maur Mandi' },
  { label: 'Maura', value: 'Maura' },
  { label: 'Maurach', value: 'Maurach' },
  { label: 'Mauraro', value: 'Mauraro' },
  { label: 'Maurawan', value: 'Maurawan' },
  { label: 'Mauraz', value: 'Mauraz' },
  { label: 'Maurecourt', value: 'Maurecourt' },
  { label: 'Maure-De-Bretagne', value: 'Maure-De-Bretagne' },
  { label: 'Maureilhan', value: 'Maureilhan' },
  { label: 'Maureillas-Las-Illas', value: 'Maureillas-Las-Illas' },
  { label: 'Mauren', value: 'Mauren' },
  { label: 'Măureni', value: 'Măureni' },
  { label: 'Maurepas', value: 'Maurepas' },
  { label: 'Mauriac', value: 'Mauriac' },
  { label: 'Maurice', value: 'Maurice' },
  { label: 'Mauriceville', value: 'Mauriceville' },
  { label: 'Mauricie', value: 'Mauricie' },
  { label: 'Maurik', value: 'Maurik' },
  { label: 'Maurilândia', value: 'Maurilândia' },
  { label: 'Maurilândia Do Tocantins', value: 'Maurilândia Do Tocantins' },
  { label: 'Mauriti', value: 'Mauriti' },
  { label: 'Mauron', value: 'Mauron' },
  { label: 'Maurs', value: 'Maurs' },
  { label: 'Maury', value: 'Maury' },
  { label: 'Maury County', value: 'Maury County' },
  { label: 'Maussane-Les-Alpilles', value: 'Maussane-Les-Alpilles' },
  { label: 'Mauston', value: 'Mauston' },
  { label: 'Mautbrücken', value: 'Mautbrücken' },
  { label: 'Mautern', value: 'Mautern' },
  { label: 'Mautern In Steiermark', value: 'Mautern In Steiermark' },
  { label: 'Mauterndorf', value: 'Mauterndorf' },
  { label: 'Mauth', value: 'Mauth' },
  { label: 'Mauves', value: 'Mauves' },
  { label: 'Mauves-Sur-Loire', value: 'Mauves-Sur-Loire' },
  { label: 'Mauvezin', value: 'Mauvezin' },
  { label: 'Mauzé-Sur-Le-Mignon', value: 'Mauzé-Sur-Le-Mignon' },
  { label: 'Mauzé-Thouarsais', value: 'Mauzé-Thouarsais' },
  { label: 'Mavelikara', value: 'Mavelikara' },
  { label: 'Maverick County', value: 'Maverick County' },
  { label: 'Mavingouni', value: 'Mavingouni' },
  { label: 'Mavis Bank', value: 'Mavis Bank' },
  { label: 'Mavoor', value: 'Mavoor' },
  { label: 'Mavoro', value: 'Mavoro' },
  { label: 'Mavrochóri', value: 'Mavrochóri' },
  { label: 'Mavrodin', value: 'Mavrodin' },
  { label: 'Mavrodin', value: 'Mavrodin' },
  { label: 'Mavrommáti', value: 'Mavrommáti' },
  { label: 'Mavrothálassa', value: 'Mavrothálassa' },
  { label: 'Mavrovoúni', value: 'Mavrovoúni' },
  { label: 'Mawab', value: 'Mawab' },
  { label: 'Mawab', value: 'Mawab' },
  { label: 'Mawab', value: 'Mawab' },
  { label: 'Mawana', value: 'Mawana' },
  { label: 'Mawar', value: 'Mawar' },
  { label: 'Mawarah', value: 'Mawarah' },
  { label: 'Māwīyah', value: 'Māwīyah' },
  { label: 'Mawlaik', value: 'Mawlaik' },
  { label: 'Mawlamyine', value: 'Mawlamyine' },
  { label: 'Mawlamyinegyunn', value: 'Mawlamyinegyunn' },
  { label: 'Mawqaq', value: 'Mawqaq' },
  { label: 'Mawson', value: 'Mawson' },
  { label: 'Mawson Lakes', value: 'Mawson Lakes' },
  { label: 'Mawu', value: 'Mawu' },
  { label: 'Mawza', value: 'Mawza' },
  { label: 'Maxaranguape', value: 'Maxaranguape' },
  { label: 'Maxcanú', value: 'Maxcanú' },
  { label: 'Maxdorf', value: 'Maxdorf' },
  { label: 'Maxela', value: 'Maxela' },
  { label: 'Maxent', value: 'Maxent' },
  { label: 'Maxenu', value: 'Maxenu' },
  { label: 'Maxéville', value: 'Maxéville' },
  { label: 'Maxfield Park', value: 'Maxfield Park' },
  { label: 'Maxhütte-Haidhof', value: 'Maxhütte-Haidhof' },
  { label: 'Maxial', value: 'Maxial' },
  { label: 'Maxilly-Sur-Léman', value: 'Maxilly-Sur-Léman' },
  { label: 'Maximiliano De Almeida', value: 'Maximiliano De Almeida' },
  { label: 'Máximo Serdán', value: 'Máximo Serdán' },
  { label: 'Măxineni', value: 'Măxineni' },
  { label: 'Maxingal', value: 'Maxingal' },
  { label: 'Maxingal', value: 'Maxingal' },
  { label: 'Maxixe', value: 'Maxixe' },
  { label: 'Maxsain', value: 'Maxsain' },
  { label: 'Maxtleca De Galeana', value: 'Maxtleca De Galeana' },
  { label: 'Maxton', value: 'Maxton' },
  { label: 'Maxut', value: 'Maxut' },
  { label: 'Maxwell', value: 'Maxwell' },
  { label: 'Maxwellheugh', value: 'Maxwellheugh' },
  { label: 'May Day', value: 'May Day' },
  { label: 'May Pen', value: 'May Pen' },
  { label: 'May Pen Proper', value: 'May Pen Proper' },
  { label: 'Maya', value: 'Maya' },
  { label: 'Maya Balam', value: 'Maya Balam' },
  { label: 'Maya La', value: 'Maya La' },
  { label: 'Maya Tecún I', value: 'Maya Tecún I' },
  { label: 'Mayabon', value: 'Mayabon' },
  { label: 'Mayachnyy', value: 'Mayachnyy' },
  { label: 'Mayagüez', value: 'Mayagüez' },
  { label: 'Mayagüez', value: 'Mayagüez' },
  { label: 'Mayahi', value: 'Mayahi' },
  { label: 'Mayakonda', value: 'Mayakonda' },
  { label: 'Mayakovski', value: 'Mayakovski' },
  { label: 'Mayaky', value: 'Mayaky' },
  { label: 'Mayalde', value: 'Mayalde' },
  { label: 'Mayamey', value: 'Mayamey' },
  { label: 'Mayana', value: 'Mayana' },
  { label: 'Mayanalán', value: 'Mayanalán' },
  { label: 'Mayantoc', value: 'Mayantoc' },
  { label: 'Mayantoc', value: 'Mayantoc' },
  { label: 'Mayapan', value: 'Mayapan' },
  { label: 'Mayapusi', value: 'Mayapusi' },
  { label: 'Maybole', value: 'Maybole' },
  { label: 'Maybrook', value: 'Maybrook' },
  { label: 'Maych’Ew', value: 'Maych’Ew' },
  { label: 'Maydolong', value: 'Maydolong' },
  { label: 'Maydolong', value: 'Maydolong' },
  { label: 'Mayen', value: 'Mayen' },
  { label: 'Mayenne', value: 'Mayenne' },
  { label: 'Mayer', value: 'Mayer' },
  { label: 'Mayer', value: 'Mayer' },
  { label: 'Mayersville', value: 'Mayersville' },
  { label: 'Mayerthorpe', value: 'Mayerthorpe' },
  { label: 'Mayes County', value: 'Mayes County' },
  { label: 'Mayet', value: 'Mayet' },
  { label: 'Mayfaa', value: 'Mayfaa' },
  { label: 'Mayfaat Anss', value: 'Mayfaat Anss' },
  { label: 'Mayfield', value: 'Mayfield' },
  { label: 'Mayfield', value: 'Mayfield' },
  { label: 'Mayfield', value: 'Mayfield' },
  { label: 'Mayfield', value: 'Mayfield' },
  { label: 'Mayfield', value: 'Mayfield' },
  { label: 'Mayfield', value: 'Mayfield' },
  { label: 'Mayfield', value: 'Mayfield' },
  { label: 'Mayfield East', value: 'Mayfield East' },
  { label: 'Mayfield Heights', value: 'Mayfield Heights' },
  { label: 'Mayfield West', value: 'Mayfield West' },
  { label: 'Mayflower', value: 'Mayflower' },
  { label: 'Mayflower Village', value: 'Mayflower Village' },
  { label: 'Maygatasan', value: 'Maygatasan' },
  { label: 'Maygatasan', value: 'Maygatasan' },
  { label: 'Mayiladuthurai', value: 'Mayiladuthurai' },
  { label: 'Mayisad', value: 'Mayisad' },
  { label: 'Mayisyan', value: 'Mayisyan' },
  { label: 'Mayisyan', value: 'Mayisyan' },
  { label: 'Maykop', value: 'Maykop' },
  { label: 'Maykop Republican Urban Okrug', value: 'Maykop Republican Urban Okrug' },
  { label: 'Maykopskiy Rayon', value: 'Maykopskiy Rayon' },
  { label: 'Maykopskoye', value: 'Maykopskoye' },
  { label: 'Maykor', value: 'Maykor' },
  { label: 'Mayland', value: 'Mayland' },
  { label: 'Maylands', value: 'Maylands' },
  { label: 'Maylands', value: 'Maylands' },
  { label: 'Mayma', value: 'Mayma' },
  { label: 'Maymana', value: 'Maymana' },
  { label: 'Maymeh', value: 'Maymeh' },
  { label: 'Mayminskiy Rayon', value: 'Mayminskiy Rayon' },
  { label: 'Mayna', value: 'Mayna' },
  { label: 'Mayna', value: 'Mayna' },
  { label: 'Maynard', value: 'Maynard' },
  { label: 'Maynard Hill', value: 'Maynard Hill' },
  { label: 'Maynardville', value: 'Maynardville' },
  { label: 'Mayngaran', value: 'Mayngaran' },
  { label: 'Maynooth', value: 'Maynooth' },
  { label: 'Maynskiy Rayon', value: 'Maynskiy Rayon' },
  { label: 'Mayo', value: 'Mayo' },
  { label: 'Mayo', value: 'Mayo' },
  { label: 'Mayo', value: 'Mayo' },
  { label: 'Mayo', value: 'Mayo' },
  { label: 'Mayo', value: 'Mayo' },
  { label: 'Mayo', value: 'Mayo' },
  { label: 'Mayo County', value: 'Mayo County' },
  { label: 'Mayo-Banyo', value: 'Mayo-Banyo' },
  { label: 'Mayo-Belwa', value: 'Mayo-Belwa' },
  { label: 'Mayodan', value: 'Mayodan' },
  { label: 'Mayo-Louti', value: 'Mayo-Louti' },
  { label: 'Mayorazgo De León', value: 'Mayorazgo De León' },
  { label: 'Mayo-Rey', value: 'Mayo-Rey' },
  { label: 'Mayorga', value: 'Mayorga' },
  { label: 'Mayorga', value: 'Mayorga' },
  { label: 'Mayorga', value: 'Mayorga' },
  { label: 'Mayo-Sava', value: 'Mayo-Sava' },
  { label: 'Mayo-Tsanaga', value: 'Mayo-Tsanaga' },
  { label: 'Mayoyao', value: 'Mayoyao' },
  { label: 'Maypangdan', value: 'Maypangdan' },
  { label: 'Maypangdan', value: 'Maypangdan' },
  { label: 'Mayqayyng', value: 'Mayqayyng' },
  { label: 'Mayran', value: 'Mayran' },
  { label: 'Mayrhofen', value: 'Mayrhofen' },
  { label: 'Mayrtup', value: 'Mayrtup' },
  { label: 'Mays Chapel', value: 'Mays Chapel' },
  { label: 'Mays Landing', value: 'Mays Landing' },
  { label: 'Mayskiy', value: 'Mayskiy' },
  { label: 'Mayskiy', value: 'Mayskiy' },
  { label: 'Mayskiy', value: 'Mayskiy' },
  { label: 'Mayskiy', value: 'Mayskiy' },
  { label: 'Mayskiy Rayon', value: 'Mayskiy Rayon' },
  { label: 'Mayskoye', value: 'Mayskoye' },
  { label: 'Mayskoye', value: 'Mayskoye' },
  { label: 'May-Sur-Orne', value: 'May-Sur-Orne' },
  { label: 'Maysville', value: 'Maysville' },
  { label: 'Maysville', value: 'Maysville' },
  { label: 'Maysville', value: 'Maysville' },
  { label: 'Maysville', value: 'Maysville' },
  { label: 'Maysville', value: 'Maysville' },
  { label: 'Maytown', value: 'Maytown' },
  { label: 'Mayuge', value: 'Mayuge' },
  { label: 'Mayumba', value: 'Mayumba' },
  { label: 'Mayurbhanj', value: 'Mayurbhanj' },
  { label: 'Mayville', value: 'Mayville' },
  { label: 'Mayville', value: 'Mayville' },
  { label: 'Mayville', value: 'Mayville' },
  { label: 'Maywood', value: 'Maywood' },
  { label: 'Maywood', value: 'Maywood' },
  { label: 'Maywood', value: 'Maywood' },
  { label: 'Mayya', value: 'Mayya' },
  { label: 'Mazabuka', value: 'Mazabuka' },
  { label: 'Mazagão', value: 'Mazagão' },
  { label: 'Mazagaon', value: 'Mazagaon' },
  { label: 'Mazaleón', value: 'Mazaleón' },
  { label: 'Mazamari', value: 'Mazamari' },
  { label: 'Mazamet', value: 'Mazamet' },
  { label: 'Mazamitlongo', value: 'Mazamitlongo' },
  { label: 'Mazan', value: 'Mazan' },
  { label: 'Măzănăești', value: 'Măzănăești' },
  { label: 'Mazańcowice', value: 'Mazańcowice' },
  { label: 'Mazanka', value: 'Mazanka' },
  { label: 'Mazanovskiy Rayon', value: 'Mazanovskiy Rayon' },
  { label: 'Mazapa', value: 'Mazapa' },
  { label: 'Mazapa', value: 'Mazapa' },
  { label: 'Mazapa De Madero', value: 'Mazapa De Madero' },
  { label: 'Mazapil', value: 'Mazapil' },
  { label: 'Mazapiltepec', value: 'Mazapiltepec' },
  { label: 'Mazara Del Vallo', value: 'Mazara Del Vallo' },
  { label: 'Mazara Ii', value: 'Mazara Ii' },
  { label: 'Mazarambroz', value: 'Mazarambroz' },
  { label: 'Mazār-E Sharīf', value: 'Mazār-E Sharīf' },
  { label: 'Mazarete', value: 'Mazarete' },
  { label: 'Mazargues', value: 'Mazargues' },
  { label: 'Mazaricos', value: 'Mazaricos' },
  { label: 'Mazariegos', value: 'Mazariegos' },
  { label: 'Mazarrón', value: 'Mazarrón' },
  { label: 'Mazatán', value: 'Mazatán' },
  { label: 'Mazatán', value: 'Mazatán' },
  { label: 'Mazatecochco', value: 'Mazatecochco' },
  { label: 'Mazatenango', value: 'Mazatenango' },
  { label: 'Mazatepec', value: 'Mazatepec' },
  { label: 'Mazatepec', value: 'Mazatepec' },
  { label: 'Mazateupa', value: 'Mazateupa' },
  { label: 'Mazatlan', value: 'Mazatlan' },
  { label: 'Mazatlán', value: 'Mazatlán' },
  { label: 'Mazatlán', value: 'Mazatlán' },
  { label: 'Mazatlán Villa De Flores', value: 'Mazatlán Villa De Flores' },
  { label: 'Mazayejan', value: 'Mazayejan' },
  { label: 'Mazcuerras', value: 'Mazcuerras' },
  { label: 'Mazdavand', value: 'Mazdavand' },
  { label: 'Mazé', value: 'Mazé' },
  { label: 'Mazeikiai', value: 'Mazeikiai' },
  { label: 'Mazères', value: 'Mazères' },
  { label: 'Mazères-Lezons', value: 'Mazères-Lezons' },
  { label: 'Mazet-Saint-Voy', value: 'Mazet-Saint-Voy' },
  { label: 'Mazgirt', value: 'Mazgirt' },
  { label: 'Mazhar', value: 'Mazhar' },
  { label: 'Mazhin', value: 'Mazhin' },
  { label: 'Mazıdağı', value: 'Mazıdağı' },
  { label: 'Mazières-De-Touraine', value: 'Mazières-De-Touraine' },
  { label: 'Mazières-En-Mauges', value: 'Mazières-En-Mauges' },
  { label: 'Mazín Grande', value: 'Mazín Grande' },
  { label: 'Mazinde', value: 'Mazinde' },
  { label: 'Mazingarbe', value: 'Mazingarbe' },
  { label: 'Mazkeret Batya', value: 'Mazkeret Batya' },
  { label: 'Mazo', value: 'Mazo' },
  { label: 'Mazomanie', value: 'Mazomanie' },
  { label: 'Mazouna', value: 'Mazouna' },
  { label: 'Mazowe', value: 'Mazowe' },
  { label: 'Mazowe District', value: 'Mazowe District' },
  { label: 'Mazraeh', value: 'Mazraeh' },
  { label: 'Mazsalaca', value: 'Mazsalaca' },
  { label: 'Mazuecos', value: 'Mazuecos' },
  { label: 'Mazuecos De Valdeginate', value: 'Mazuecos De Valdeginate' },
  { label: 'Mazuela', value: 'Mazuela' },
  { label: 'Mazumiapam', value: 'Mazumiapam' },
  { label: 'Mazyr', value: 'Mazyr' },
  { label: 'Mazyrski Rayon', value: 'Mazyrski Rayon' },
  { label: 'Mazzano', value: 'Mazzano' },
  { label: 'Mazzano Romano', value: 'Mazzano Romano' },
  { label: 'Mazzarino', value: 'Mazzarino' },
  { label: 'Mazzarrà Santandrea', value: 'Mazzarrà Santandrea' },
  { label: 'Mazzarrone', value: 'Mazzarrone' },
  { label: 'Mazze', value: 'Mazze' },
  { label: 'Mazzin', value: 'Mazzin' },
  { label: 'Mazzo Di Valtellina', value: 'Mazzo Di Valtellina' },
  { label: 'Mbabane', value: 'Mbabane' },
  { label: 'Mbacké', value: 'Mbacké' },
  { label: 'Mbaiki', value: 'Mbaiki' },
  { label: 'Mbaké', value: 'Mbaké' },
  { label: 'Mbala', value: 'Mbala' },
  { label: 'Mbale', value: 'Mbale' },
  { label: 'Mbalmayo', value: 'Mbalmayo' },
  { label: 'Mbamba Bay', value: 'Mbamba Bay' },
  { label: 'Mbam-Et-Inoubou', value: 'Mbam-Et-Inoubou' },
  { label: 'Mbandaka', value: 'Mbandaka' },
  { label: 'Mbandjok', value: 'Mbandjok' },
  { label: 'Mbang', value: 'Mbang' },
  { label: 'Mbanga', value: 'Mbanga' },
  { label: 'Mbankomo', value: 'Mbankomo' },
  { label: 'Mbanza Congo', value: 'Mbanza Congo' },
  { label: 'Mbanza-Ngungu', value: 'Mbanza-Ngungu' },
  { label: 'Mbarara', value: 'Mbarara' },
  { label: 'Mbekenyera', value: 'Mbekenyera' },
  { label: 'Mbengwi', value: 'Mbengwi' },
  { label: 'Mbéni', value: 'Mbéni' },
  { label: 'Mberengwa District', value: 'Mberengwa District' },
  { label: 'Mberubu', value: 'Mberubu' },
  { label: 'Mbeya', value: 'Mbeya' },
  { label: 'Mbigou', value: 'Mbigou' },
  { label: 'Mbinga', value: 'Mbinga' },
  { label: 'Mbini', value: 'Mbini' },
  { label: 'Mbocayaty', value: 'Mbocayaty' },
  { label: 'Mborje', value: 'Mborje' },
  { label: 'Mbouda', value: 'Mbouda' },
  { label: 'Mbour', value: 'Mbour' },
  { label: 'Mboursou Léré', value: 'Mboursou Léré' },
  { label: 'Mbuguni', value: 'Mbuguni' },
  { label: 'Mbuji-Mayi', value: 'Mbuji-Mayi' },
  { label: 'Mbulu', value: 'Mbulu' },
  { label: 'Mbumi', value: 'Mbumi' },
  { label: 'Mburucuyá', value: 'Mburucuyá' },
  { label: 'Mbutuý', value: 'Mbutuý' },
  { label: 'Mbuyapey', value: 'Mbuyapey' },
  { label: 'Mcadoo', value: 'Mcadoo' },
  { label: 'Mcalester', value: 'Mcalester' },
  { label: 'Mcallen', value: 'Mcallen' },
  { label: 'Mcalmont', value: 'Mcalmont' },
  { label: 'Mcarthur', value: 'Mcarthur' },
  { label: 'Mccall', value: 'Mccall' },
  { label: 'Mccamey', value: 'Mccamey' },
  { label: 'Mccaysville', value: 'Mccaysville' },
  { label: 'Mcchord Air Force Base', value: 'Mcchord Air Force Base' },
  { label: 'Mcclain County', value: 'Mcclain County' },
  { label: 'Mccleary', value: 'Mccleary' },
  { label: 'Mccloud', value: 'Mccloud' },
  { label: 'Mcclusky', value: 'Mcclusky' },
  { label: 'Mccoll', value: 'Mccoll' },
  { label: 'Mccomb', value: 'Mccomb' },
  { label: 'Mccomb', value: 'Mccomb' },
  { label: 'Mccone County', value: 'Mccone County' },
  { label: 'Mcconnell Afb', value: 'Mcconnell Afb' },
  { label: 'Mcconnellsburg', value: 'Mcconnellsburg' },
  { label: 'Mcconnellstown', value: 'Mcconnellstown' },
  { label: 'Mcconnelsville', value: 'Mcconnelsville' },
  { label: 'Mccook', value: 'Mccook' },
  { label: 'Mccook County', value: 'Mccook County' },
  { label: 'Mccord', value: 'Mccord' },
  { label: 'Mccordsville', value: 'Mccordsville' },
  { label: 'Mccormick', value: 'Mccormick' },
  { label: 'Mccormick County', value: 'Mccormick County' },
  { label: 'Mccracken', value: 'Mccracken' },
  { label: 'Mccracken County', value: 'Mccracken County' },
  { label: 'Mccrae', value: 'Mccrae' },
  { label: 'Mccreary County', value: 'Mccreary County' },
  { label: 'Mccrory', value: 'Mccrory' },
  { label: 'Mcculloch County', value: 'Mcculloch County' },
  { label: 'Mccullom Lake', value: 'Mccullom Lake' },
  { label: 'Mccurtain County', value: 'Mccurtain County' },
  { label: 'Mcdonald', value: 'Mcdonald' },
  { label: 'Mcdonald', value: 'Mcdonald' },
  { label: 'Mcdonald County', value: 'Mcdonald County' },
  { label: 'Mcdonough', value: 'Mcdonough' },
  { label: 'Mcdonough County', value: 'Mcdonough County' },
  { label: 'Mcdowall', value: 'Mcdowall' },
  { label: 'Mcdowell County', value: 'Mcdowell County' },
  { label: 'Mcdowell County', value: 'Mcdowell County' },
  { label: 'Mcduffie County', value: 'Mcduffie County' },
  { label: 'Mcewen', value: 'Mcewen' },
  { label: 'Mcewen', value: 'Mcewen' },
  { label: 'Mcfarland', value: 'Mcfarland' },
  { label: 'Mcfarland', value: 'Mcfarland' },
  { label: 'Mcgehee', value: 'Mcgehee' },
  { label: 'Mcgill', value: 'Mcgill' },
  { label: 'Mcgovern', value: 'Mcgovern' },
  { label: 'Mcgraths Hill', value: 'Mcgraths Hill' },
  { label: 'Mcgraw', value: 'Mcgraw' },
  { label: 'Mcgregor', value: 'Mcgregor' },
  { label: 'Mcgregor', value: 'Mcgregor' },
  { label: 'Mcguire Afb', value: 'Mcguire Afb' },
  { label: 'Mchenry', value: 'Mchenry' },
  { label: 'Mchenry County', value: 'Mchenry County' },
  { label: 'Mchenry County', value: 'Mchenry County' },
  { label: 'Mchinji', value: 'Mchinji' },
  { label: 'Mchinji District', value: 'Mchinji District' },
  { label: 'Mcintosh', value: 'Mcintosh' },
  { label: 'Mcintosh', value: 'Mcintosh' },
  { label: 'Mcintosh County', value: 'Mcintosh County' },
  { label: 'Mcintosh County', value: 'Mcintosh County' },
  { label: 'Mcintosh County', value: 'Mcintosh County' },
  { label: 'Mckail', value: 'Mckail' },
  { label: 'Mckean County', value: 'Mckean County' },
  { label: 'Mckee', value: 'Mckee' },
  { label: 'Mckees Rocks', value: 'Mckees Rocks' },
  { label: 'Mckeesport', value: 'Mckeesport' },
  { label: 'Mckellar', value: 'Mckellar' },
  { label: 'Mckenzie', value: 'Mckenzie' },
  { label: 'Mckenzie County', value: 'Mckenzie County' },
  { label: 'Mckinlay', value: 'Mckinlay' },
  { label: 'Mckinley', value: 'Mckinley' },
  { label: 'Mckinley County', value: 'Mckinley County' },
  { label: 'Mckinley Heights', value: 'Mckinley Heights' },
  { label: 'Mckinley Park', value: 'Mckinley Park' },
  { label: 'Mckinleyville', value: 'Mckinleyville' },
  { label: 'Mckinney', value: 'Mckinney' },
  { label: 'Mckinnon', value: 'Mckinnon' },
  { label: 'Mckownville', value: 'Mckownville' },
  { label: 'Mclaren Flat', value: 'Mclaren Flat' },
  { label: 'Mclaren Vale', value: 'Mclaren Vale' },
  { label: 'Mclean', value: 'Mclean' },
  { label: 'Mclean County', value: 'Mclean County' },
  { label: 'Mclean County', value: 'Mclean County' },
  { label: 'Mclean County', value: 'Mclean County' },
  { label: 'Mcleansboro', value: 'Mcleansboro' },
  { label: 'Mcleansville', value: 'Mcleansville' },
  { label: 'Mclendon-Chisholm', value: 'Mclendon-Chisholm' },
  { label: 'Mclennan County', value: 'Mclennan County' },
  { label: 'Mcleod County', value: 'Mcleod County' },
  { label: 'Mcloud', value: 'Mcloud' },
  { label: 'Mcmahons Point', value: 'Mcmahons Point' },
  { label: 'Mcmechen', value: 'Mcmechen' },
  { label: 'Mcmillin', value: 'Mcmillin' },
  { label: 'Mcminn County', value: 'Mcminn County' },
  { label: 'Mcminnville', value: 'Mcminnville' },
  { label: 'Mcminnville', value: 'Mcminnville' },
  { label: 'Mcmullen County', value: 'Mcmullen County' },
  { label: 'Mcmurray', value: 'Mcmurray' },
  { label: 'Mcnairy County', value: 'Mcnairy County' },
  { label: 'Mcpherson', value: 'Mcpherson' },
  { label: 'Mcpherson County', value: 'Mcpherson County' },
  { label: 'Mcpherson County', value: 'Mcpherson County' },
  { label: 'Mcpherson County', value: 'Mcpherson County' },
  { label: 'Mcqueeney', value: 'Mcqueeney' },
  { label: 'Mcrae', value: 'Mcrae' },
  { label: 'Mcsherrystown', value: 'Mcsherrystown' },
  { label: 'Mdiq-Fnideq', value: 'Mdiq-Fnideq' },
  { label: 'Mead', value: 'Mead' },
  { label: 'Mead', value: 'Mead' },
  { label: 'Mead Valley', value: 'Mead Valley' },
  { label: 'Meade', value: 'Meade' },
  { label: 'Meade County', value: 'Meade County' },
  { label: 'Meade County', value: 'Meade County' },
  { label: 'Meade County', value: 'Meade County' },
  { label: 'Meadow Glade', value: 'Meadow Glade' },
  { label: 'Meadow Heights', value: 'Meadow Heights' },
  { label: 'Meadow Lake', value: 'Meadow Lake' },
  { label: 'Meadow Lake', value: 'Meadow Lake' },
  { label: 'Meadow Lakes', value: 'Meadow Lakes' },
  { label: 'Meadow Oaks', value: 'Meadow Oaks' },
  { label: 'Meadow Springs', value: 'Meadow Springs' },
  { label: 'Meadow Vista', value: 'Meadow Vista' },
  { label: 'Meadow Woods', value: 'Meadow Woods' },
  { label: 'Meadowbank', value: 'Meadowbank' },
  { label: 'Meadowbrook', value: 'Meadowbrook' },
  { label: 'Meadowbrook', value: 'Meadowbrook' },
  { label: 'Meadowbrook', value: 'Meadowbrook' },
  { label: 'Meadowbrook', value: 'Meadowbrook' },
  { label: 'Meadowbrook', value: 'Meadowbrook' },
  { label: 'Meadowbrook Estate', value: 'Meadowbrook Estate' },
  { label: 'Meadowdale', value: 'Meadowdale' },
  { label: 'Meadowlakes', value: 'Meadowlakes' },
  { label: 'Meadowood', value: 'Meadowood' },
  { label: 'Meadows', value: 'Meadows' },
  { label: 'Meadows Place', value: 'Meadows Place' },
  { label: 'Meads', value: 'Meads' },
  { label: 'Meadview', value: 'Meadview' },
  { label: 'Meadville', value: 'Meadville' },
  { label: 'Meadville', value: 'Meadville' },
  { label: 'Meaford', value: 'Meaford' },
  { label: 'Meagher County', value: 'Meagher County' },
  { label: 'Mealhada', value: 'Mealhada' },
  { label: 'Meámbar', value: 'Meámbar' },
  { label: 'Meana Di Susa', value: 'Meana Di Susa' },
  { label: 'Meana Sardo', value: 'Meana Sardo' },
  { label: 'Meander Valley', value: 'Meander Valley' },
  { label: 'Meano', value: 'Meano' },
  { label: 'Meaño', value: 'Meaño' },
  { label: 'Mearnsville', value: 'Mearnsville' },
  { label: 'Measham', value: 'Measham' },
  { label: 'Méaudre', value: 'Méaudre' },
  { label: 'Méaulte', value: 'Méaulte' },
  { label: 'Meaux', value: 'Meaux' },
  { label: 'Mebane', value: 'Mebane' },
  { label: 'Meca', value: 'Meca' },
  { label: 'Meca', value: 'Meca' },
  { label: 'Mecapalapa', value: 'Mecapalapa' },
  { label: 'Mecatán', value: 'Mecatán' },
  { label: 'Mecatepec', value: 'Mecatepec' },
  { label: 'Mecatlán', value: 'Mecatlán' },
  { label: 'Mecatlán', value: 'Mecatlán' },
  { label: 'Mecayapan', value: 'Mecayapan' },
  { label: 'Mecca', value: 'Mecca' },
  { label: 'Mecca', value: 'Mecca' },
  { label: 'Mecerreyes', value: 'Mecerreyes' },
  { label: 'Mechanic Falls', value: 'Mechanic Falls' },
  { label: 'Mechanicsburg', value: 'Mechanicsburg' },
  { label: 'Mechanicsburg', value: 'Mechanicsburg' },
  { label: 'Mechanicstown', value: 'Mechanicstown' },
  { label: 'Mechanicsville', value: 'Mechanicsville' },
  { label: 'Mechanicsville', value: 'Mechanicsville' },
  { label: 'Mechanicsville', value: 'Mechanicsville' },
  { label: 'Mechanicsville', value: 'Mechanicsville' },
  { label: 'Mechanicville', value: 'Mechanicville' },
  { label: 'Mechelen', value: 'Mechelen' },
  { label: 'Mechelen', value: 'Mechelen' },
  { label: 'Mechernich', value: 'Mechernich' },
  { label: 'Mechetinskaya', value: 'Mechetinskaya' },
  { label: 'Mechetlinskiy Rayon', value: 'Mechetlinskiy Rayon' },
  { label: 'Mechoacanejo', value: 'Mechoacanejo' },
  { label: 'Měcholupy', value: 'Měcholupy' },
  { label: 'Mechraa Bel Ksiri', value: 'Mechraa Bel Ksiri' },
  { label: 'Mechterstädt', value: 'Mechterstädt' },
  { label: 'Měčín', value: 'Měčín' },
  { label: 'Mecitözü', value: 'Mecitözü' },
  { label: 'Meckenbeuren', value: 'Meckenbeuren' },
  { label: 'Meckenheim', value: 'Meckenheim' },
  { label: 'Meckenheim', value: 'Meckenheim' },
  { label: 'Meckesheim', value: 'Meckesheim' },
  { label: 'Mecklenburg', value: 'Mecklenburg' },
  { label: 'Mecklenburg County', value: 'Mecklenburg County' },
  { label: 'Mecklenburg County', value: 'Mecklenburg County' },
  { label: 'Mécleuves', value: 'Mécleuves' },
  { label: 'Meclov', value: 'Meclov' },
  { label: 'Meco', value: 'Meco' },
  { label: 'Mecoacán', value: 'Mecoacán' },
  { label: 'Mecosta County', value: 'Mecosta County' },
  { label: 'Mecseknádasd', value: 'Mecseknádasd' },
  { label: 'Meda', value: 'Meda' },
  { label: 'Međa', value: 'Međa' },
  { label: 'Mêda', value: 'Mêda' },
  { label: 'Medadumbara', value: 'Medadumbara' },
  { label: 'Medak', value: 'Medak' },
  { label: 'Medan', value: 'Medan' },
  { label: 'Médan', value: 'Médan' },
  { label: 'Medaya', value: 'Medaya' },
  { label: 'Medchal', value: 'Medchal' },
  { label: 'Medchal Malkajgiri', value: 'Medchal Malkajgiri' },
  { label: 'Meddersheim', value: 'Meddersheim' },
  { label: 'Mede', value: 'Mede' },
  { label: 'Medea', value: 'Medea' },
  { label: 'Médéa', value: 'Médéa' },
  { label: 'Medebach', value: 'Medebach' },
  { label: 'Medeiros', value: 'Medeiros' },
  { label: 'Medeiros Neto', value: 'Medeiros Neto' },
  { label: 'Medellin', value: 'Medellin' },
  { label: 'Medellín', value: 'Medellín' },
  { label: 'Medellín', value: 'Medellín' },
  { label: 'Medellín', value: 'Medellín' },
  { label: 'Medellín De Bravo', value: 'Medellín De Bravo' },
  { label: 'Medellín Y Madero Segunda Sección', value: 'Medellín Y Madero Segunda Sección' },
  { label: 'Medemblik', value: 'Medemblik' },
  { label: 'Medenine', value: 'Medenine' },
  { label: 'Medenychi', value: 'Medenychi' },
  { label: 'Medernach', value: 'Medernach' },
  { label: 'Medesano', value: 'Medesano' },
  { label: 'Medfield', value: 'Medfield' },
  { label: 'Medford', value: 'Medford' },
  { label: 'Medford', value: 'Medford' },
  { label: 'Medford', value: 'Medford' },
  { label: 'Medford', value: 'Medford' },
  { label: 'Medford', value: 'Medford' },
  { label: 'Medford', value: 'Medford' },
  { label: 'Medford Lakes', value: 'Medford Lakes' },
  { label: 'Medghal', value: 'Medghal' },
  { label: 'Medgidia', value: 'Medgidia' },
  { label: 'Medgyesegyháza', value: 'Medgyesegyháza' },
  { label: 'Media', value: 'Media' },
  { label: 'Media Luna', value: 'Media Luna' },
  { label: 'Mediana De Aragón', value: 'Mediana De Aragón' },
  { label: 'Mediana De Voltoya', value: 'Mediana De Voltoya' },
  { label: 'Medianeira', value: 'Medianeira' },
  { label: 'Mediapolis', value: 'Mediapolis' },
  { label: 'Mediaş', value: 'Mediaş' },
  { label: 'Medias Aguas', value: 'Medias Aguas' },
  { label: 'Medical Lake', value: 'Medical Lake' },
  { label: 'Medicilândia', value: 'Medicilândia' },
  { label: 'Medicina-Buda', value: 'Medicina-Buda' },
  { label: 'Medicine Hat', value: 'Medicine Hat' },
  { label: 'Medicine Lodge', value: 'Medicine Lodge' },
  { label: 'Medieşu Aurit', value: 'Medieşu Aurit' },
  { label: 'Mediglia', value: 'Mediglia' },
  { label: 'Mediis', value: 'Mediis' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina', value: 'Medina' },
  { label: 'Medina County', value: 'Medina County' },
  { label: 'Medina County', value: 'Medina County' },
  { label: 'Medina De Las Torres', value: 'Medina De Las Torres' },
  { label: 'Medina De Pomar', value: 'Medina De Pomar' },
  { label: 'Medina De Ríoseco', value: 'Medina De Ríoseco' },
  { label: 'Medina Del Campo', value: 'Medina Del Campo' },
  { label: 'Medina Estates', value: 'Medina Estates' },
  { label: 'Medinaceli', value: 'Medinaceli' },
  { label: 'Medina-Sidonia', value: 'Medina-Sidonia' },
  { label: 'Medindie', value: 'Medindie' },
  { label: 'Medine Camp De Masque', value: 'Medine Camp De Masque' },
  { label: 'Medinilla', value: 'Medinilla' },
  { label: 'Medinipur', value: 'Medinipur' },
  { label: 'Medio Atrato', value: 'Medio Atrato' },
  { label: 'Medio Baudó', value: 'Medio Baudó' },
  { label: 'Medio Cudeyo', value: 'Medio Cudeyo' },
  { label: 'Medio San Juan', value: 'Medio San Juan' },
  { label: 'Medio Sitio', value: 'Medio Sitio' },
  { label: 'Mediona', value: 'Mediona' },
  { label: 'Mediouna', value: 'Mediouna' },
  { label: 'Médis', value: 'Médis' },
  { label: 'Medjez El Bab', value: 'Medjez El Bab' },
  { label: 'Medkovets', value: 'Medkovets' },
  { label: 'Medlingen', value: 'Medlingen' },
  { label: 'Medlov', value: 'Medlov' },
  { label: 'Mednogorsk', value: 'Mednogorsk' },
  { label: 'Mednogorskiy', value: 'Mednogorskiy' },
  { label: 'Medolago', value: 'Medolago' },
  { label: 'Medole', value: 'Medole' },
  { label: 'Medolla', value: 'Medolla' },
  { label: 'Medora', value: 'Medora' },
  { label: 'Medovene', value: 'Medovene' },
  { label: 'Medowie', value: 'Medowie' },
  { label: 'Medranda', value: 'Medranda' },
  { label: 'Medrano', value: 'Medrano' },
  { label: 'Medraz', value: 'Medraz' },
  { label: 'Médréac', value: 'Médréac' },
  { label: 'Mędrzechów', value: 'Mędrzechów' },
  { label: 'Medulin', value: 'Medulin' },
  { label: 'Medulla', value: 'Medulla' },
  { label: 'Meduna Di Livenza', value: 'Meduna Di Livenza' },
  { label: 'Meduno', value: 'Meduno' },
  { label: 'Medvedevka', value: 'Medvedevka' },
  { label: 'Medvedevo', value: 'Medvedevo' },
  { label: 'Medvedevo', value: 'Medvedevo' },
  { label: 'Medvedevskiy Rayon', value: 'Medvedevskiy Rayon' },
  { label: 'Medveditsky', value: 'Medveditsky' },
  { label: 'Medvedok', value: 'Medvedok' },
  { label: 'Medvedovskaya', value: 'Medvedovskaya' },
  { label: 'Medvenskiy Rayon', value: 'Medvenskiy Rayon' },
  { label: 'Medvezh’I Ozëra', value: 'Medvezh’I Ozëra' },
  { label: 'Medvezh’Yegorsk', value: 'Medvezh’Yegorsk' },
  { label: 'Medvode', value: 'Medvode' },
  { label: 'Medway', value: 'Medway' },
  { label: 'Medway', value: 'Medway' },
  { label: 'Medway', value: 'Medway' },
  { label: 'Medyka', value: 'Medyka' },
  { label: 'Medyn’', value: 'Medyn’' },
  { label: 'Medynskiy Rayon', value: 'Medynskiy Rayon' },
  { label: 'Medzev', value: 'Medzev' },
  { label: 'Medzhybizh', value: 'Medzhybizh' },
  { label: 'Medzilaborce', value: 'Medzilaborce' },
  { label: 'Meeden', value: 'Meeden' },
  { label: 'Meeder', value: 'Meeder' },
  { label: 'Meedhoo', value: 'Meedhoo' },
  { label: 'Meekatharra', value: 'Meekatharra' },
  { label: 'Meeker', value: 'Meeker' },
  { label: 'Meeker', value: 'Meeker' },
  { label: 'Meeker County', value: 'Meeker County' },
  { label: 'Meerane', value: 'Meerane' },
  { label: 'Meerbeck', value: 'Meerbeck' },
  { label: 'Meerbusch', value: 'Meerbusch' },
  { label: 'Meerdorf', value: 'Meerdorf' },
  { label: 'Meerhout', value: 'Meerhout' },
  { label: 'Meerhoven', value: 'Meerhoven' },
  { label: 'Meerlo', value: 'Meerlo' },
  { label: 'Meersburg', value: 'Meersburg' },
  { label: 'Meerssen', value: 'Meerssen' },
  { label: 'Meerut', value: 'Meerut' },
  { label: 'Meerveldhoven', value: 'Meerveldhoven' },
  { label: 'Mées', value: 'Mées' },
  { label: 'Meethari Marwar', value: 'Meethari Marwar' },
  { label: 'Meezenbroek', value: 'Meezenbroek' },
  { label: 'Mefou-Et-Akono', value: 'Mefou-Et-Akono' },
  { label: 'Meftah', value: 'Meftah' },
  { label: 'Mēga', value: 'Mēga' },
  { label: 'Megála Kalývia', value: 'Megála Kalývia' },
  { label: 'Megáli Khóra', value: 'Megáli Khóra' },
  { label: 'Megáli Panagía', value: 'Megáli Panagía' },
  { label: 'Megálo Chorió', value: 'Megálo Chorió' },
  { label: 'Megalochóri', value: 'Megalochóri' },
  { label: 'Megalochóri', value: 'Megalochóri' },
  { label: 'Megalópoli', value: 'Megalópoli' },
  { label: 'Mégara', value: 'Mégara' },
  { label: 'Megarine', value: 'Megarine' },
  { label: 'Megarine', value: 'Megarine' },
  { label: 'Megeces', value: 'Megeces' },
  { label: 'Megen', value: 'Megen' },
  { label: 'Meget', value: 'Meget' },
  { label: 'Megève', value: 'Megève' },
  { label: 'Meggen', value: 'Meggen' },
  { label: 'Meggenhofen', value: 'Meggenhofen' },
  { label: 'Meggett', value: 'Meggett' },
  { label: 'Meggiano', value: 'Meggiano' },
  { label: 'Meghradzor', value: 'Meghradzor' },
  { label: 'Meghraj', value: 'Meghraj' },
  { label: 'Meghrashen', value: 'Meghrashen' },
  { label: 'Meghri', value: 'Meghri' },
  { label: 'Megina', value: 'Megina' },
  { label: 'Megion', value: 'Megion' },
  { label: 'Megísti', value: 'Megísti' },
  { label: 'Megliadino San Fidenzio', value: 'Megliadino San Fidenzio' },
  { label: 'Megliadino San Vitale', value: 'Megliadino San Vitale' },
  { label: 'Meguro-Ku', value: 'Meguro-Ku' },
  { label: 'Megyaszó', value: 'Megyaszó' },
  { label: 'Mehadia', value: 'Mehadia' },
  { label: 'Mehadica', value: 'Mehadica' },
  { label: 'Mehamn', value: 'Mehamn' },
  { label: 'Mehar', value: 'Mehar' },
  { label: 'Mehdawal', value: 'Mehdawal' },
  { label: 'Mehdia Daira De Meghila', value: 'Mehdia Daira De Meghila' },
  { label: 'Mehekar', value: 'Mehekar' },
  { label: 'Mehergaon', value: 'Mehergaon' },
  { label: 'Meherpur ', value: 'Meherpur ' },
  { label: 'Mehkar', value: 'Mehkar' },
  { label: 'Méhkerék', value: 'Méhkerék' },
  { label: 'Mehlbach', value: 'Mehlbach' },
  { label: 'Mehlingen', value: 'Mehlingen' },
  { label: 'Mehlmeisel', value: 'Mehlmeisel' },
  { label: 'Mehltheuer', value: 'Mehltheuer' },
  { label: 'Mehlville', value: 'Mehlville' },
  { label: 'Mehmand Chak', value: 'Mehmand Chak' },
  { label: 'Mehnagar', value: 'Mehnagar' },
  { label: 'Mehndawal', value: 'Mehndawal' },
  { label: 'Mehr', value: 'Mehr' },
  { label: 'Mehraban', value: 'Mehraban' },
  { label: 'Mehrabpur', value: 'Mehrabpur' },
  { label: 'Mehran', value: 'Mehran' },
  { label: 'Mehrdasht', value: 'Mehrdasht' },
  { label: 'Mehren', value: 'Mehren' },
  { label: 'Mehrestan', value: 'Mehrestan' },
  { label: 'Mehrhoog', value: 'Mehrhoog' },
  { label: 'Mehring', value: 'Mehring' },
  { label: 'Mehring', value: 'Mehring' },
  { label: 'Mehringen', value: 'Mehringen' },
  { label: 'Mehriz', value: 'Mehriz' },
  { label: 'Mehrnbach', value: 'Mehrnbach' },
  { label: 'Mehrstetten', value: 'Mehrstetten' },
  { label: 'Mehsana', value: 'Mehsana' },
  { label: 'Mehtar Lām', value: 'Mehtar Lām' },
  { label: 'Mehun-Sur-Yèvre', value: 'Mehun-Sur-Yèvre' },
  { label: 'Meichang', value: 'Meichang' },
  { label: 'Meiderich', value: 'Meiderich' },
  { label: 'Meidling', value: 'Meidling' },
  { label: 'Meierijstad', value: 'Meierijstad' },
  { label: 'Meiern', value: 'Meiern' },
  { label: 'Meierskappel', value: 'Meierskappel' },
  { label: 'Meïganga', value: 'Meïganga' },
  { label: 'Meigs', value: 'Meigs' },
  { label: 'Meigs County', value: 'Meigs County' },
  { label: 'Meigs County', value: 'Meigs County' },
  { label: 'Meihekou', value: 'Meihekou' },
  { label: 'Meijel', value: 'Meijel' },
  { label: 'Meiktila', value: 'Meiktila' },
  { label: 'Meilen', value: 'Meilen' },
  { label: 'Meilhan', value: 'Meilhan' },
  { label: 'Meilhan-Sur-Garonne', value: 'Meilhan-Sur-Garonne' },
  { label: 'Meillac', value: 'Meillac' },
  { label: 'Meillonnas', value: 'Meillonnas' },
  { label: 'Meina', value: 'Meina' },
  { label: 'Meinedo', value: 'Meinedo' },
  { label: 'Meiners Oaks', value: 'Meiners Oaks' },
  { label: 'Meinersen', value: 'Meinersen' },
  { label: 'Meinerzhagen', value: 'Meinerzhagen' },
  { label: 'Meinier', value: 'Meinier' },
  { label: 'Meiningen', value: 'Meiningen' },
  { label: 'Meiningen', value: 'Meiningen' },
  { label: 'Meinisberg', value: 'Meinisberg' },
  { label: 'Meios', value: 'Meios' },
  { label: 'Meipu', value: 'Meipu' },
  { label: 'Meira', value: 'Meira' },
  { label: 'Meiringen', value: 'Meiringen' },
  { label: 'Meis', value: 'Meis' },
  { label: 'Meise', value: 'Meise' },
  { label: 'Meisenheim', value: 'Meisenheim' },
  { label: 'Meishan Shi', value: 'Meishan Shi' },
  { label: 'Meissen', value: 'Meissen' },
  { label: 'Meißenheim', value: 'Meißenheim' },
  { label: 'Meisterschwanden', value: 'Meisterschwanden' },
  { label: 'Meistratzheim', value: 'Meistratzheim' },
  { label: 'Meitingen', value: 'Meitingen' },
  { label: 'Meitzendorf', value: 'Meitzendorf' },
  { label: 'Meix-Devant-Virton', value: 'Meix-Devant-Virton' },
  { label: 'Meizhou', value: 'Meizhou' },
  { label: 'Mejaniga', value: 'Mejaniga' },
  { label: 'Mejdan - Obilićevo', value: 'Mejdan - Obilićevo' },
  { label: 'Mejia Community Development Block', value: 'Mejia Community Development Block' },
  { label: 'Mejicanos', value: 'Mejicanos' },
  { label: 'Mejillones', value: 'Mejillones' },
  { label: 'Mejorada', value: 'Mejorada' },
  { label: 'Mejorada Del Campo', value: 'Mejorada Del Campo' },
  { label: 'Mekele', value: 'Mekele' },
  { label: 'Mékhé', value: 'Mékhé' },
  { label: 'Mekinje', value: 'Mekinje' },
  { label: 'Mekkelholt', value: 'Mekkelholt' },
  { label: 'Mekla', value: 'Mekla' },
  { label: 'Meknes', value: 'Meknes' },
  { label: 'Mel', value: 'Mel' },
  { label: 'Mel’Nichnoye', value: 'Mel’Nichnoye' },
  { label: 'Mel’Nikovo', value: 'Mel’Nikovo' },
  { label: 'Meland', value: 'Meland' },
  { label: 'Melano', value: 'Melano' },
  { label: 'Melara', value: 'Melara' },
  { label: 'Melawi', value: 'Melawi' },
  { label: 'Melay', value: 'Melay' },
  { label: 'Melazzo', value: 'Melazzo' },
  { label: 'Melba', value: 'Melba' },
  { label: 'Melbach', value: 'Melbach' },
  { label: 'Melbeck', value: 'Melbeck' },
  { label: 'Melbourn', value: 'Melbourn' },
  { label: 'Melbourne', value: 'Melbourne' },
  { label: 'Melbourne', value: 'Melbourne' },
  { label: 'Melbourne', value: 'Melbourne' },
  { label: 'Melbourne', value: 'Melbourne' },
  { label: 'Melbourne Beach', value: 'Melbourne Beach' },
  { label: 'Melbourne City Centre', value: 'Melbourne City Centre' },
  { label: 'Melbu', value: 'Melbu' },
  { label: 'Melcher-Dallas', value: 'Melcher-Dallas' },
  { label: 'Melchnau', value: 'Melchnau' },
  { label: 'Melchor De Mencos', value: 'Melchor De Mencos' },
  { label: 'Melchor Ocampo', value: 'Melchor Ocampo' },
  { label: 'Melchor Ocampo', value: 'Melchor Ocampo' },
  { label: 'Melchor Ocampo', value: 'Melchor Ocampo' },
  { label: 'Melchor Ocampo', value: 'Melchor Ocampo' },
  { label: 'Melchor Ocampo', value: 'Melchor Ocampo' },
  { label: 'Melchor Ocampo', value: 'Melchor Ocampo' },
  { label: 'Melchor Ocampo Nuevo León', value: 'Melchor Ocampo Nuevo León' },
  { label: 'Melchow', value: 'Melchow' },
  { label: 'Meldal', value: 'Meldal' },
  { label: 'Melderslo', value: 'Melderslo' },
  { label: 'Meldola', value: 'Meldola' },
  { label: 'Meldorf', value: 'Meldorf' },
  { label: 'Meldreth', value: 'Meldreth' },
  { label: 'Mele', value: 'Mele' },
  { label: 'Meledo', value: 'Meledo' },
  { label: 'Melegnano', value: 'Melegnano' },
  { label: 'Meleiro', value: 'Meleiro' },
  { label: 'Melekeok Village', value: 'Melekeok Village' },
  { label: 'Melekhovo', value: 'Melekhovo' },
  { label: 'Melekyne', value: 'Melekyne' },
  { label: 'Melenci', value: 'Melenci' },
  { label: 'Melendugno', value: 'Melendugno' },
  { label: 'Melenki', value: 'Melenki' },
  { label: 'Melenkovskiy Rayon', value: 'Melenkovskiy Rayon' },
  { label: 'Melesse', value: 'Melesse' },
  { label: 'Meleti', value: 'Meleti' },
  { label: 'Meleuz', value: 'Meleuz' },
  { label: 'Melfi', value: 'Melfi' },
  { label: 'Melfi', value: 'Melfi' },
  { label: 'Melfort', value: 'Melfort' },
  { label: 'Melgaço', value: 'Melgaço' },
  { label: 'Melgaço', value: 'Melgaço' },
  { label: 'Melgar', value: 'Melgar' },
  { label: 'Melgar De Abajo', value: 'Melgar De Abajo' },
  { label: 'Melgar De Arriba', value: 'Melgar De Arriba' },
  { label: 'Melgar De Fernamental', value: 'Melgar De Fernamental' },
  { label: 'Melgar De Tera', value: 'Melgar De Tera' },
  { label: 'Melgar De Yuso', value: 'Melgar De Yuso' },
  { label: 'Mełgiew', value: 'Mełgiew' },
  { label: 'Melgven', value: 'Melgven' },
  { label: 'Melhus', value: 'Melhus' },
  { label: 'Melia', value: 'Melia' },
  { label: 'Meliana', value: 'Meliana' },
  { label: 'Melick', value: 'Melick' },
  { label: 'Melicuccà', value: 'Melicuccà' },
  { label: 'Melicucco', value: 'Melicucco' },
  { label: 'Mélida', value: 'Mélida' },
  { label: 'Melide', value: 'Melide' },
  { label: 'Melide', value: 'Melide' },
  { label: 'Meligalás', value: 'Meligalás' },
  { label: 'Melikgazi', value: 'Melikgazi' },
  { label: 'Melikhovo', value: 'Melikhovo' },
  { label: 'Melikhovskaya', value: 'Melikhovskaya' },
  { label: 'Melíki', value: 'Melíki' },
  { label: 'Melilla', value: 'Melilla' },
  { label: 'Melilli', value: 'Melilli' },
  { label: 'Melincué', value: 'Melincué' },
  { label: 'Melineşti', value: 'Melineşti' },
  { label: 'Melioratorov', value: 'Melioratorov' },
  { label: 'Melioratyvne', value: 'Melioratyvne' },
  { label: 'Melipeuco', value: 'Melipeuco' },
  { label: 'Melipilla', value: 'Melipilla' },
  { label: 'Mélisey', value: 'Mélisey' },
  { label: 'Meliskerke', value: 'Meliskerke' },
  { label: 'Melissa', value: 'Melissa' },
  { label: 'Melissa', value: 'Melissa' },
  { label: 'Melissano', value: 'Melissano' },
  { label: 'Melíssi', value: 'Melíssi' },
  { label: 'Melíssia', value: 'Melíssia' },
  { label: 'Melissochóri', value: 'Melissochóri' },
  { label: 'Melita', value: 'Melita' },
  { label: 'Melíti', value: 'Melíti' },
  { label: 'Melito Di Napoli', value: 'Melito Di Napoli' },
  { label: 'Melito Di Porto Salvo', value: 'Melito Di Porto Salvo' },
  { label: 'Melito Irpino', value: 'Melito Irpino' },
  { label: 'Melitón Albáñez Domínguez', value: 'Melitón Albáñez Domínguez' },
  { label: 'Melitopol', value: 'Melitopol' },
  { label: 'Melitopol’S’Kyy Rayon', value: 'Melitopol’S’Kyy Rayon' },
  { label: 'Melizzano', value: 'Melizzano' },
  { label: 'Melk', value: 'Melk' },
  { label: 'Melksham', value: 'Melksham' },
  { label: 'Mella', value: 'Mella' },
  { label: 'Mellac', value: 'Mellac' },
  { label: 'Mellach', value: 'Mellach' },
  { label: 'Mellau', value: 'Mellau' },
  { label: 'Mellbystrand', value: 'Mellbystrand' },
  { label: 'Melle', value: 'Melle' },
  { label: 'Melle', value: 'Melle' },
  { label: 'Melle', value: 'Melle' },
  { label: 'Melle', value: 'Melle' },
  { label: 'Melle', value: 'Melle' },
  { label: 'Mellecey', value: 'Mellecey' },
  { label: 'Mellenbach-Glasbach', value: 'Mellenbach-Glasbach' },
  { label: 'Mellerud', value: 'Mellerud' },
  { label: 'Mellette County', value: 'Mellette County' },
  { label: 'Mellieħa', value: 'Mellieħa' },
  { label: 'Mellilä', value: 'Mellilä' },
  { label: 'Mellingen', value: 'Mellingen' },
  { label: 'Mellingen', value: 'Mellingen' },
  { label: 'Mellinghausen', value: 'Mellinghausen' },
  { label: 'Mello', value: 'Mello' },
  { label: 'Melloulèche', value: 'Melloulèche' },
  { label: 'Mellrichstadt', value: 'Mellrichstadt' },
  { label: 'Mellunkylä', value: 'Mellunkylä' },
  { label: 'Mellwood', value: 'Mellwood' },
  { label: 'Mělník', value: 'Mělník' },
  { label: 'Melo', value: 'Melo' },
  { label: 'Melo', value: 'Melo' },
  { label: 'Melocheville', value: 'Melocheville' },
  { label: 'Melody Hill', value: 'Melody Hill' },
  { label: 'Melón', value: 'Melón' },
  { label: 'Melong', value: 'Melong' },
  { label: 'Melouza', value: 'Melouza' },
  { label: 'Meløy', value: 'Meløy' },
  { label: 'Melpignano', value: 'Melpignano' },
  { label: 'Melque De Cercos', value: 'Melque De Cercos' },
  { label: 'Melrand', value: 'Melrand' },
  { label: 'Melres', value: 'Melres' },
  { label: 'Melrose', value: 'Melrose' },
  { label: 'Melrose', value: 'Melrose' },
  { label: 'Melrose', value: 'Melrose' },
  { label: 'Melrose', value: 'Melrose' },
  { label: 'Melrose', value: 'Melrose' },
  { label: 'Melrose Park', value: 'Melrose Park' },
  { label: 'Melrose Park', value: 'Melrose Park' },
  { label: 'Melrose Park', value: 'Melrose Park' },
  { label: 'Melrose Park', value: 'Melrose Park' },
  { label: 'Melrose Park', value: 'Melrose Park' },
  { label: 'Melsbach', value: 'Melsbach' },
  { label: 'Melsdorf', value: 'Melsdorf' },
  { label: 'Melsomvik', value: 'Melsomvik' },
  { label: 'Melsungen', value: 'Melsungen' },
  { label: 'Meltham', value: 'Meltham' },
  { label: 'Meltham Mills', value: 'Meltham Mills' },
  { label: 'Meltina', value: 'Meltina' },
  { label: 'Melton', value: 'Melton' },
  { label: 'Melton Mowbray', value: 'Melton Mowbray' },
  { label: 'Melton South', value: 'Melton South' },
  { label: 'Melton West', value: 'Melton West' },
  { label: 'Melukote', value: 'Melukote' },
  { label: 'Melun', value: 'Melun' },
  { label: 'Melur', value: 'Melur' },
  { label: 'Melville', value: 'Melville' },
  { label: 'Melville', value: 'Melville' },
  { label: 'Melville', value: 'Melville' },
  { label: 'Melville', value: 'Melville' },
  { label: 'Melville', value: 'Melville' },
  { label: 'Melvindale', value: 'Melvindale' },
  { label: 'Mélykút', value: 'Mélykút' },
  { label: 'Melzo', value: 'Melzo' },
  { label: 'Mem Martins', value: 'Mem Martins' },
  { label: 'Mem Martins', value: 'Mem Martins' },
  { label: 'Memaliaj', value: 'Memaliaj' },
  { label: 'Memari', value: 'Memari' },
  { label: 'Membibre De La Hoz', value: 'Membibre De La Hoz' },
  { label: 'Membribe De La Sierra', value: 'Membribe De La Sierra' },
  { label: 'Membrilla', value: 'Membrilla' },
  { label: 'Membrillera', value: 'Membrillera' },
  { label: 'Membrío', value: 'Membrío' },
  { label: 'Memmelsdorf', value: 'Memmelsdorf' },
  { label: 'Memmingen', value: 'Memmingen' },
  { label: 'Memmingerberg', value: 'Memmingerberg' },
  { label: 'Mempawah', value: 'Mempawah' },
  { label: 'Memphis', value: 'Memphis' },
  { label: 'Memphis', value: 'Memphis' },
  { label: 'Memphis', value: 'Memphis' },
  { label: 'Memphis', value: 'Memphis' },
  { label: 'Memphis', value: 'Memphis' },
  { label: 'Mena', value: 'Mena' },
  { label: 'Mena', value: 'Mena' },
  { label: 'Menaam', value: 'Menaam' },
  { label: 'Menaggio', value: 'Menaggio' },
  { label: 'Menahga', value: 'Menahga' },
  { label: 'Menai', value: 'Menai' },
  { label: 'Menai Bridge', value: 'Menai Bridge' },
  { label: 'Meñaka', value: 'Meñaka' },
  { label: 'Menands', value: 'Menands' },
  { label: 'Menangle', value: 'Menangle' },
  { label: 'Menard', value: 'Menard' },
  { label: 'Menard County', value: 'Menard County' },
  { label: 'Menard County', value: 'Menard County' },
  { label: 'Menàrguens', value: 'Menàrguens' },
  { label: 'Menarola', value: 'Menarola' },
  { label: 'Menasalbas', value: 'Menasalbas' },
  { label: 'Menasha', value: 'Menasha' },
  { label: 'Menconico', value: 'Menconico' },
  { label: 'Mendaha', value: 'Mendaha' },
  { label: 'Mendarda', value: 'Mendarda' },
  { label: 'Mendaro', value: 'Mendaro' },
  { label: 'Mendata', value: 'Mendata' },
  { label: 'Mendatica', value: 'Mendatica' },
  { label: 'Mendavia', value: 'Mendavia' },
  { label: 'Mendaza', value: 'Mendaza' },
  { label: 'Mende', value: 'Mende' },
  { label: 'Mende', value: 'Mende' },
  { label: 'Mendefera', value: 'Mendefera' },
  { label: 'Mendeleyevo', value: 'Mendeleyevo' },
  { label: 'Mendeleyevo', value: 'Mendeleyevo' },
  { label: 'Mendeleyevo', value: 'Mendeleyevo' },
  { label: 'Mendeleyevsk', value: 'Mendeleyevsk' },
  { label: 'Mendeleyevskiy', value: 'Mendeleyevskiy' },
  { label: 'Mendeleyevskiy Rayon', value: 'Mendeleyevskiy Rayon' },
  { label: 'Menden', value: 'Menden' },
  { label: 'Mendenhall', value: 'Mendenhall' },
  { label: 'Menderes', value: 'Menderes' },
  { label: 'Mendes', value: 'Mendes' },
  { label: 'Mendes Pimentel', value: 'Mendes Pimentel' },
  { label: 'Mendexa', value: 'Mendexa' },
  { label: 'Méndez', value: 'Méndez' },
  { label: 'Mendez-Nuñez', value: 'Mendez-Nuñez' },
  { label: 'Mendham', value: 'Mendham' },
  { label: 'Mendi', value: 'Mendi' },
  { label: 'Mendī', value: 'Mendī' },
  { label: 'Mendicino', value: 'Mendicino' },
  { label: 'Mendig', value: 'Mendig' },
  { label: 'Mendigorría', value: 'Mendigorría' },
  { label: 'Mendiolaza', value: 'Mendiolaza' },
  { label: 'Mendip', value: 'Mendip' },
  { label: 'Mendocino County', value: 'Mendocino County' },
  { label: 'Mendon', value: 'Mendon' },
  { label: 'Mendon', value: 'Mendon' },
  { label: 'Mendon', value: 'Mendon' },
  { label: 'Mendonça', value: 'Mendonça' },
  { label: 'Mendota', value: 'Mendota' },
  { label: 'Mendota', value: 'Mendota' },
  { label: 'Mendota Heights', value: 'Mendota Heights' },
  { label: 'Mendoza', value: 'Mendoza' },
  { label: 'Mendoza', value: 'Mendoza' },
  { label: 'Mendrisio', value: 'Mendrisio' },
  { label: 'Mendrisio District', value: 'Mendrisio District' },
  { label: 'Ménéac', value: 'Ménéac' },
  { label: 'Menemen', value: 'Menemen' },
  { label: 'Meneméni', value: 'Meneméni' },
  { label: 'Menen', value: 'Menen' },
  { label: 'Menen', value: 'Menen' },
  { label: 'Meneou', value: 'Meneou' },
  { label: 'Ménerbes', value: 'Ménerbes' },
  { label: 'Meneses De Campos', value: 'Meneses De Campos' },
  { label: 'Ménesplet', value: 'Ménesplet' },
  { label: 'Ménestreau-En-Villette', value: 'Ménestreau-En-Villette' },
  { label: 'Menetou-Salon', value: 'Menetou-Salon' },
  { label: 'Ménétrol', value: 'Ménétrol' },
  { label: 'Menfi', value: 'Menfi' },
  { label: 'Mengabril', value: 'Mengabril' },
  { label: 'Mengamuñoz', value: 'Mengamuñoz' },
  { label: 'Mengcheng Chengguanzhen', value: 'Mengcheng Chengguanzhen' },
  { label: 'Mengellang', value: 'Mengellang' },
  { label: 'Mengen', value: 'Mengen' },
  { label: 'Mengen', value: 'Mengen' },
  { label: 'Mengenich', value: 'Mengenich' },
  { label: 'Mengersgereuth-Hämmern', value: 'Mengersgereuth-Hämmern' },
  { label: 'Mengerskirchen', value: 'Mengerskirchen' },
  { label: 'Mengeš', value: 'Mengeš' },
  { label: 'Mengibar', value: 'Mengibar' },
  { label: 'Mengkofen', value: 'Mengkofen' },
  { label: 'Mengomeyén', value: 'Mengomeyén' },
  { label: 'Mengquan', value: 'Mengquan' },
  { label: 'Mengyin', value: 'Mengyin' },
  { label: 'Menídi', value: 'Menídi' },
  { label: 'Menifee', value: 'Menifee' },
  { label: 'Menifee County', value: 'Menifee County' },
  { label: 'Menikdiwela', value: 'Menikdiwela' },
  { label: 'Méniko', value: 'Méniko' },
  { label: 'Ménilles', value: 'Ménilles' },
  { label: 'Měnín', value: 'Měnín' },
  { label: 'Meningie', value: 'Meningie' },
  { label: 'Menj', value: 'Menj' },
  { label: 'Menjan', value: 'Menjan' },
  { label: 'Menlo Park', value: 'Menlo Park' },
  { label: 'Mennecy', value: 'Mennecy' },
  { label: 'Menneval', value: 'Menneval' },
  { label: 'Menominee', value: 'Menominee' },
  { label: 'Menominee County', value: 'Menominee County' },
  { label: 'Menominee County', value: 'Menominee County' },
  { label: 'Menomonee Falls', value: 'Menomonee Falls' },
  { label: 'Menomonie', value: 'Menomonie' },
  { label: 'Menongue', value: 'Menongue' },
  { label: 'Menora', value: 'Menora' },
  { label: 'Menores', value: 'Menores' },
  { label: 'Menpenti', value: 'Menpenti' },
  { label: 'Mens', value: 'Mens' },
  { label: 'Mens’Kyy Rayon', value: 'Mens’Kyy Rayon' },
  { label: 'Mensignac', value: 'Mensignac' },
  { label: 'Menslage', value: 'Menslage' },
  { label: 'Menston', value: 'Menston' },
  { label: 'Menstrie', value: 'Menstrie' },
  { label: 'Mentana', value: 'Mentana' },
  { label: 'Mentekab', value: 'Mentekab' },
  { label: 'Menteroda', value: 'Menteroda' },
  { label: 'Menteşe', value: 'Menteşe' },
  { label: 'Menthon-Saint-Bernard', value: 'Menthon-Saint-Bernard' },
  { label: 'Mentiri', value: 'Mentiri' },
  { label: 'Menton', value: 'Menton' },
  { label: 'Mentone', value: 'Mentone' },
  { label: 'Mentone', value: 'Mentone' },
  { label: 'Mentone', value: 'Mentone' },
  { label: 'Mentor', value: 'Mentor' },
  { label: 'Mentor-On-The-Lake', value: 'Mentor-On-The-Lake' },
  { label: 'Mentougou', value: 'Mentougou' },
  { label: 'Méntrida', value: 'Méntrida' },
  { label: 'Menucourt', value: 'Menucourt' },
  { label: 'Menuma', value: 'Menuma' },
  { label: 'Menyamya', value: 'Menyamya' },
  { label: 'Menzel Abderhaman', value: 'Menzel Abderhaman' },
  { label: 'Menzel Bourguiba', value: 'Menzel Bourguiba' },
  { label: 'Menzel Bouzelfa', value: 'Menzel Bouzelfa' },
  { label: 'Menzel Horr', value: 'Menzel Horr' },
  { label: 'Menzel Jemil', value: 'Menzel Jemil' },
  { label: 'Menzel Kamel', value: 'Menzel Kamel' },
  { label: 'Menzel Salem', value: 'Menzel Salem' },
  { label: 'Menzel Temime', value: 'Menzel Temime' },
  { label: 'Menzelinsk', value: 'Menzelinsk' },
  { label: 'Menzelinskiy Rayon', value: 'Menzelinskiy Rayon' },
  { label: 'Menzies', value: 'Menzies' },
  { label: 'Menzies', value: 'Menzies' },
  { label: 'Menziken', value: 'Menziken' },
  { label: 'Menzingen', value: 'Menzingen' },
  { label: 'Menznau', value: 'Menznau' },
  { label: 'Meolo', value: 'Meolo' },
  { label: 'Meopham', value: 'Meopham' },
  { label: 'Meoqui', value: 'Meoqui' },
  { label: 'Méounes-Lès-Montrieux', value: 'Méounes-Lès-Montrieux' },
  { label: 'Meppel', value: 'Meppel' },
  { label: 'Meppen', value: 'Meppen' },
  { label: 'Meppershall', value: 'Meppershall' },
  { label: 'Mequetla', value: 'Mequetla' },
  { label: 'Mequinensa / Mequinenza', value: 'Mequinensa / Mequinenza' },
  { label: 'Mequinenza', value: 'Mequinenza' },
  { label: 'Mequon', value: 'Mequon' },
  { label: 'Mer', value: 'Mer' },
  { label: 'Mera', value: 'Mera' },
  { label: 'Mera', value: 'Mera' },
  { label: 'Meråker', value: 'Meråker' },
  { label: 'Meram', value: 'Meram' },
  { label: 'Merana', value: 'Merana' },
  { label: 'Meranges', value: 'Meranges' },
  { label: 'Merano', value: 'Merano' },
  { label: 'Merate', value: 'Merate' },
  { label: 'Meraux', value: 'Meraux' },
  { label: 'Merbein', value: 'Merbein' },
  { label: 'Merbes-Le-Château', value: 'Merbes-Le-Château' },
  { label: 'Merca A', value: 'Merca A' },
  { label: 'Mercadal', value: 'Mercadal' },
  { label: 'Mercadal Es', value: 'Mercadal Es' },
  { label: 'Mercaderes', value: 'Mercaderes' },
  { label: 'Mercado Modelo', value: 'Mercado Modelo' },
  { label: 'Mercallo', value: 'Mercallo' },
  { label: 'Mercatale', value: 'Mercatale' },
  { label: 'Mercatale', value: 'Mercatale' },
  { label: 'Mercatale-San Quirico', value: 'Mercatale-San Quirico' },
  { label: 'Mercatello Sul Metauro', value: 'Mercatello Sul Metauro' },
  { label: 'Mercatino Conca', value: 'Mercatino Conca' },
  { label: 'Mercato', value: 'Mercato' },
  { label: 'Mercato San Severino', value: 'Mercato San Severino' },
  { label: 'Mercato Saraceno', value: 'Mercato Saraceno' },
  { label: 'Mercato Vecchio', value: 'Mercato Vecchio' },
  { label: 'Merced', value: 'Merced' },
  { label: 'Merced County', value: 'Merced County' },
  { label: 'Merced Del Potrero', value: 'Merced Del Potrero' },
  { label: 'Mercedes', value: 'Mercedes' },
  { label: 'Mercedes', value: 'Mercedes' },
  { label: 'Mercedes', value: 'Mercedes' },
  { label: 'Mercedes', value: 'Mercedes' },
  { label: 'Mercedes', value: 'Mercedes' },
  { label: 'Mercedes', value: 'Mercedes' },
  { label: 'Mercedes', value: 'Mercedes' },
  { label: 'Mercedes', value: 'Mercedes' },
  { label: 'Mercedes', value: 'Mercedes' },
  { label: 'Mercedes', value: 'Mercedes' },
  { label: 'Mercedes', value: 'Mercedes' },
  { label: 'Mercedes De Oriente', value: 'Mercedes De Oriente' },
  { label: 'Mercenasco', value: 'Mercenasco' },
  { label: 'Mercer', value: 'Mercer' },
  { label: 'Mercer County', value: 'Mercer County' },
  { label: 'Mercer County', value: 'Mercer County' },
  { label: 'Mercer County', value: 'Mercer County' },
  { label: 'Mercer County', value: 'Mercer County' },
  { label: 'Mercer County', value: 'Mercer County' },
  { label: 'Mercer County', value: 'Mercer County' },
  { label: 'Mercer County', value: 'Mercer County' },
  { label: 'Mercer County', value: 'Mercer County' },
  { label: 'Mercer Island', value: 'Mercer Island' },
  { label: 'Mercersburg', value: 'Mercersburg' },
  { label: 'Mercerville', value: 'Mercerville' },
  { label: 'Mercerville-Hamilton Square', value: 'Mercerville-Hamilton Square' },
  { label: 'Mercês', value: 'Mercês' },
  { label: 'Mercês', value: 'Mercês' },
  { label: 'Merchantville', value: 'Merchantville' },
  { label: 'Merching', value: 'Merching' },
  { label: 'Merchtem', value: 'Merchtem' },
  { label: 'Merchweiler', value: 'Merchweiler' },
  { label: 'Mercier', value: 'Mercier' },
  { label: 'Mercogliano', value: 'Mercogliano' },
  { label: 'Mercurey', value: 'Mercurey' },
  { label: 'Mercurol-Veaunes', value: 'Mercurol-Veaunes' },
  { label: 'Mercury', value: 'Mercury' },
  { label: 'Mercus-Garrabet', value: 'Mercus-Garrabet' },
  { label: 'Mercy-Le-Bas', value: 'Mercy-Le-Bas' },
  { label: 'Merdingen', value: 'Merdingen' },
  { label: 'Merdrignac', value: 'Merdrignac' },
  { label: 'Merdzavan', value: 'Merdzavan' },
  { label: 'Mere', value: 'Mere' },
  { label: 'Méré', value: 'Méré' },
  { label: 'Méreau', value: 'Méreau' },
  { label: 'Meredith', value: 'Meredith' },
  { label: 'Meredosia', value: 'Meredosia' },
  { label: 'Merefa', value: 'Merefa' },
  { label: 'Merei', value: 'Merei' },
  { label: 'Merelani', value: 'Merelani' },
  { label: 'Merelbeke', value: 'Merelbeke' },
  { label: 'Merelim', value: 'Merelim' },
  { label: 'Merenberg', value: 'Merenberg' },
  { label: 'Mereni', value: 'Mereni' },
  { label: 'Mereni', value: 'Mereni' },
  { label: 'Merenii De Jos', value: 'Merenii De Jos' },
  { label: 'Merenii De Sus', value: 'Merenii De Sus' },
  { label: 'Merenschwand', value: 'Merenschwand' },
  { label: 'Merenwijk', value: 'Merenwijk' },
  { label: 'Mereşti', value: 'Mereşti' },
  { label: 'Mereto Di Tomba', value: 'Mereto Di Tomba' },
  { label: 'Méréville', value: 'Méréville' },
  { label: 'Méréville', value: 'Méréville' },
  { label: 'Merewether', value: 'Merewether' },
  { label: 'Merewether Heights', value: 'Merewether Heights' },
  { label: 'Merghindeal', value: 'Merghindeal' },
  { label: 'Mergo', value: 'Mergo' },
  { label: 'Mergozzo', value: 'Mergozzo' },
  { label: 'Meri', value: 'Meri' },
  { label: 'Merì', value: 'Merì' },
  { label: 'Méribel', value: 'Méribel' },
  { label: 'Meriç', value: 'Meriç' },
  { label: 'Méricourt', value: 'Méricourt' },
  { label: 'Merida', value: 'Merida' },
  { label: 'Merida', value: 'Merida' },
  { label: 'Mérida', value: 'Mérida' },
  { label: 'Mérida', value: 'Mérida' },
  { label: 'Mérida', value: 'Mérida' },
  { label: 'Mérida', value: 'Mérida' },
  { label: 'Meridan Plains', value: 'Meridan Plains' },
  { label: 'Meriden', value: 'Meriden' },
  { label: 'Meriden', value: 'Meriden' },
  { label: 'Meridian', value: 'Meridian' },
  { label: 'Meridian', value: 'Meridian' },
  { label: 'Meridian', value: 'Meridian' },
  { label: 'Meridian', value: 'Meridian' },
  { label: 'Meridian', value: 'Meridian' },
  { label: 'Meridian', value: 'Meridian' },
  { label: 'Meridian Hills', value: 'Meridian Hills' },
  { label: 'Meridian Station', value: 'Meridian Station' },
  { label: 'Meridiano', value: 'Meridiano' },
  { label: 'Meridianville', value: 'Meridianville' },
  { label: 'Mériel', value: 'Mériel' },
  { label: 'Mérignac', value: 'Mérignac' },
  { label: 'Mérignies', value: 'Mérignies' },
  { label: 'Merii Petchii', value: 'Merii Petchii' },
  { label: 'Merijärvi', value: 'Merijärvi' },
  { label: 'Merikarvia', value: 'Merikarvia' },
  { label: 'Merimasku', value: 'Merimasku' },
  { label: 'Merimbula', value: 'Merimbula' },
  { label: 'Měřín', value: 'Měřín' },
  { label: 'Merindad De Cuesta-Urria', value: 'Merindad De Cuesta-Urria' },
  { label: 'Merindad De Montija', value: 'Merindad De Montija' },
  { label: 'Merindad De Río Ubierna', value: 'Merindad De Río Ubierna' },
  { label: 'Merindad De Sotoscueva', value: 'Merindad De Sotoscueva' },
  { label: 'Merindad De Valdeporres', value: 'Merindad De Valdeporres' },
  { label: 'Merindad De Valdivielso', value: 'Merindad De Valdivielso' },
  { label: 'Mérindol', value: 'Mérindol' },
  { label: 'Merine', value: 'Merine' },
  { label: 'Merine', value: 'Merine' },
  { label: 'Mering', value: 'Mering' },
  { label: 'Meringandan West', value: 'Meringandan West' },
  { label: 'Merino', value: 'Merino' },
  { label: 'Merişani', value: 'Merişani' },
  { label: 'Merișani', value: 'Merișani' },
  { label: 'Merișor', value: 'Merișor' },
  { label: 'Merișor', value: 'Merișor' },
  { label: 'Merișoru', value: 'Merișoru' },
  { label: 'Meriwether County', value: 'Meriwether County' },
  { label: 'Mérk', value: 'Mérk' },
  { label: 'Merke', value: 'Merke' },
  { label: 'Merkel', value: 'Merkel' },
  { label: 'Merkelbeek', value: 'Merkelbeek' },
  { label: 'Merkenbach', value: 'Merkenbach' },
  { label: 'Merkendorf', value: 'Merkendorf' },
  { label: 'Merkendorf', value: 'Merkendorf' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkez', value: 'Merkez' },
  { label: 'Merkezefendi', value: 'Merkezefendi' },
  { label: 'Merkhah Al Ulya', value: 'Merkhah Al Ulya' },
  { label: 'Merkhah As Sufla', value: 'Merkhah As Sufla' },
  { label: 'Merklín', value: 'Merklín' },
  { label: 'Merklín', value: 'Merklín' },
  { label: 'Merklingen', value: 'Merklingen' },
  { label: 'Merksplas', value: 'Merksplas' },
  { label: 'Merlara', value: 'Merlara' },
  { label: 'Merlevenez', value: 'Merlevenez' },
  { label: 'Merlimont', value: 'Merlimont' },
  { label: 'Merlin', value: 'Merlin' },
  { label: 'Merlino', value: 'Merlino' },
  { label: 'Merlo', value: 'Merlo' },
  { label: 'Mermaid Beach', value: 'Mermaid Beach' },
  { label: 'Mermaid Waters', value: 'Mermaid Waters' },
  { label: 'Mermoz Boabab', value: 'Mermoz Boabab' },
  { label: 'Mernda', value: 'Mernda' },
  { label: 'Merone', value: 'Merone' },
  { label: 'Merouana', value: 'Merouana' },
  { label: 'Merowe', value: 'Merowe' },
  { label: 'Merredin', value: 'Merredin' },
  { label: 'Merriam', value: 'Merriam' },
  { label: 'Merriam Woods', value: 'Merriam Woods' },
  { label: 'Merrick', value: 'Merrick' },
  { label: 'Merrick County', value: 'Merrick County' },
  { label: 'Merrifield', value: 'Merrifield' },
  { label: 'Merrill', value: 'Merrill' },
  { label: 'Merrillville', value: 'Merrillville' },
  { label: 'Merrimac', value: 'Merrimac' },
  { label: 'Merrimac', value: 'Merrimac' },
  { label: 'Merrimac', value: 'Merrimac' },
  { label: 'Merrimack', value: 'Merrimack' },
  { label: 'Merrimack County', value: 'Merrimack County' },
  { label: 'Merrionette Park', value: 'Merrionette Park' },
  { label: 'Merriott', value: 'Merriott' },
  { label: 'Merritt', value: 'Merritt' },
  { label: 'Merritt Island', value: 'Merritt Island' },
  { label: 'Merritt Park', value: 'Merritt Park' },
  { label: 'Merriwa', value: 'Merriwa' },
  { label: 'Merriwa', value: 'Merriwa' },
  { label: 'Merrydale', value: 'Merrydale' },
  { label: 'Merrylands', value: 'Merrylands' },
  { label: 'Merrylands West', value: 'Merrylands West' },
  { label: 'Merryville', value: 'Merryville' },
  { label: 'Merrywood', value: 'Merrywood' },
  { label: 'Mers El Kebir', value: 'Mers El Kebir' },
  { label: 'Mersa Matruh', value: 'Mersa Matruh' },
  { label: 'Mersch', value: 'Mersch' },
  { label: 'Merseburg', value: 'Merseburg' },
  { label: 'Merselo', value: 'Merselo' },
  { label: 'Mersin', value: 'Mersin' },
  { label: 'Mersing', value: 'Mersing' },
  { label: 'Mers-Les-Bains', value: 'Mers-Les-Bains' },
  { label: 'Merso Di Sopra', value: 'Merso Di Sopra' },
  { label: 'Merta', value: 'Merta' },
  { label: 'Merten', value: 'Merten' },
  { label: 'Mertert', value: 'Mertert' },
  { label: 'Mertesdorf', value: 'Mertesdorf' },
  { label: 'Merthyr Tydfil', value: 'Merthyr Tydfil' },
  { label: 'Merthyr Tydfil County Borough', value: 'Merthyr Tydfil County Borough' },
  { label: 'Mertingen', value: 'Mertingen' },
  { label: 'Mertloch', value: 'Mertloch' },
  { label: 'Mértola', value: 'Mértola' },
  { label: 'Merton', value: 'Merton' },
  { label: 'Mertoyudan', value: 'Mertoyudan' },
  { label: 'Mertzig', value: 'Mertzig' },
  { label: 'Mertzon', value: 'Mertzon' },
  { label: 'Mertzwiller', value: 'Mertzwiller' },
  { label: 'Meru', value: 'Meru' },
  { label: 'Meru', value: 'Meru' },
  { label: 'Méru', value: 'Méru' },
  { label: 'Meruelo', value: 'Meruelo' },
  { label: 'Merum', value: 'Merum' },
  { label: 'Meruoca', value: 'Meruoca' },
  { label: 'Mervans', value: 'Mervans' },
  { label: 'Mervent', value: 'Mervent' },
  { label: 'Merville', value: 'Merville' },
  { label: 'Merville', value: 'Merville' },
  { label: 'Merville-Franceville-Plage', value: 'Merville-Franceville-Plage' },
  { label: 'Merxheim', value: 'Merxheim' },
  { label: 'Merxheim', value: 'Merxheim' },
  { label: 'Méry', value: 'Méry' },
  { label: 'Méry-Sur-Oise', value: 'Méry-Sur-Oise' },
  { label: 'Méry-Sur-Seine', value: 'Méry-Sur-Seine' },
  { label: 'Merzalben', value: 'Merzalben' },
  { label: 'Merzen', value: 'Merzen' },
  { label: 'Merzhausen', value: 'Merzhausen' },
  { label: 'Merzifon', value: 'Merzifon' },
  { label: 'Merzig', value: 'Merzig' },
  { label: 'Mesa', value: 'Mesa' },
  { label: 'Mesa County', value: 'Mesa County' },
  { label: 'Mesa De Guadalupe', value: 'Mesa De Guadalupe' },
  { label: 'Mesa Del Huracán', value: 'Mesa Del Huracán' },
  { label: 'Mesa Del Nayar', value: 'Mesa Del Nayar' },
  { label: 'Mesa Grande', value: 'Mesa Grande' },
  { label: 'Mesa Verde', value: 'Mesa Verde' },
  { label: 'Mesagne', value: 'Mesagne' },
  { label: 'Mésanger', value: 'Mésanger' },
  { label: 'Mesão Frio', value: 'Mesão Frio' },
  { label: 'Mesariá', value: 'Mesariá' },
  { label: 'Mesas De Ibor', value: 'Mesas De Ibor' },
  { label: 'Mesas Las', value: 'Mesas Las' },
  { label: 'Mescal', value: 'Mescal' },
  { label: 'Mescalero', value: 'Mescalero' },
  { label: 'Meschede', value: 'Meschede' },
  { label: 'Meschers-Sur-Gironde', value: 'Meschers-Sur-Gironde' },
  { label: 'Mesdour', value: 'Mesdour' },
  { label: 'Mese', value: 'Mese' },
  { label: 'Mesegar De Corneja', value: 'Mesegar De Corneja' },
  { label: 'Mesegar De Tajo', value: 'Mesegar De Tajo' },
  { label: 'Mešeišta', value: 'Mešeišta' },
  { label: 'Mesekenhagen', value: 'Mesekenhagen' },
  { label: 'Mesenzana', value: 'Mesenzana' },
  { label: 'Mesero', value: 'Mesero' },
  { label: 'Meseşenii De Jos', value: 'Meseşenii De Jos' },
  { label: 'Mesetas', value: 'Mesetas' },
  { label: 'Meshcherino', value: 'Meshcherino' },
  { label: 'Meshchovsk', value: 'Meshchovsk' },
  { label: 'Meshchovskiy Rayon', value: 'Meshchovskiy Rayon' },
  { label: 'Meshgin Shahr', value: 'Meshgin Shahr' },
  { label: 'Meshkan', value: 'Meshkan' },
  { label: 'Mesia', value: 'Mesia' },
  { label: 'Měšice', value: 'Měšice' },
  { label: 'Mesilla', value: 'Mesilla' },
  { label: 'Mesillas', value: 'Mesillas' },
  { label: 'Mesillas', value: 'Mesillas' },
  { label: 'Mesiméri', value: 'Mesiméri' },
  { label: 'Mesinak', value: 'Mesinak' },
  { label: 'Mesker-Yurt', value: 'Mesker-Yurt' },
  { label: 'Meskiana', value: 'Meskiana' },
  { label: 'Meslan', value: 'Meslan' },
  { label: 'Meslay-Du-Maine', value: 'Meslay-Du-Maine' },
  { label: 'Mesnières-En-Bray', value: 'Mesnières-En-Bray' },
  { label: 'Mesocco', value: 'Mesocco' },
  { label: 'Mesógi', value: 'Mesógi' },
  { label: 'Mesola', value: 'Mesola' },
  { label: 'Mesolóngi', value: 'Mesolóngi' },
  { label: 'Mesón Nuevo (Cañada De La Magdalena)', value: 'Mesón Nuevo (Cañada De La Magdalena)' },
  { label: 'Mesones De Isuela', value: 'Mesones De Isuela' },
  { label: 'Mesones Hidalgo', value: 'Mesones Hidalgo' },
  { label: 'Mesópolis', value: 'Mesópolis' },
  { label: 'Mesopotamía', value: 'Mesopotamía' },
  { label: 'Mesoraca', value: 'Mesoraca' },
  { label: 'Mespelbrunn', value: 'Mespelbrunn' },
  { label: 'Mesquer', value: 'Mesquer' },
  { label: 'Mesquita', value: 'Mesquita' },
  { label: 'Mesquita', value: 'Mesquita' },
  { label: 'Mesquite', value: 'Mesquite' },
  { label: 'Mesquite', value: 'Mesquite' },
  { label: 'Mesquite', value: 'Mesquite' },
  { label: 'Mesquitela', value: 'Mesquitela' },
  { label: 'Messaad', value: 'Messaad' },
  { label: 'Messac', value: 'Messac' },
  { label: 'Messancy', value: 'Messancy' },
  { label: 'Messei', value: 'Messei' },
  { label: 'Messein', value: 'Messein' },
  { label: 'Messeix', value: 'Messeix' },
  { label: 'Messel', value: 'Messel' },
  { label: 'Messen', value: 'Messen' },
  { label: 'Messery', value: 'Messery' },
  { label: 'Messias', value: 'Messias' },
  { label: 'Messias Targino', value: 'Messias Targino' },
  { label: 'Messignadi', value: 'Messignadi' },
  { label: 'Messigny-Et-Vantoux', value: 'Messigny-Et-Vantoux' },
  { label: 'Messimy', value: 'Messimy' },
  { label: 'Messina', value: 'Messina' },
  { label: 'Messingen', value: 'Messingen' },
  { label: 'Messingham', value: 'Messingham' },
  { label: 'Messíni', value: 'Messíni' },
  { label: 'Meßkirch', value: 'Meßkirch' },
  { label: 'Meßstetten', value: 'Meßstetten' },
  { label: 'Messukylä', value: 'Messukylä' },
  { label: 'Mest’Ia', value: 'Mest’Ia' },
  { label: 'Mest’Iis Munitsip’Alit’Et’I', value: 'Mest’Iis Munitsip’Alit’Et’I' },
  { label: 'Mestanza', value: 'Mestanza' },
  { label: 'Mesteacăn', value: 'Mesteacăn' },
  { label: 'Městec Králové', value: 'Městec Králové' },
  { label: 'Městečko Trnávka', value: 'Městečko Trnávka' },
  { label: 'Meștera', value: 'Meștera' },
  { label: 'Město', value: 'Město' },
  { label: 'Město Albrechtice', value: 'Město Albrechtice' },
  { label: 'Město Brno', value: 'Město Brno' },
  { label: 'Město Libavá', value: 'Město Libavá' },
  { label: 'Město Touškov', value: 'Město Touškov' },
  { label: 'Mestre', value: 'Mestre' },
  { label: 'Mestrino', value: 'Mestrino' },
  { label: 'Mesudiye', value: 'Mesudiye' },
  { label: 'Mesyagutovo', value: 'Mesyagutovo' },
  { label: 'Meta', value: 'Meta' },
  { label: 'Métabetchouan', value: 'Métabetchouan' },
  { label: 'Metabetchouan-Lac-A-La-Croix', value: 'Metabetchouan-Lac-A-La-Croix' },
  { label: 'Metahāra', value: 'Metahāra' },
  { label: 'Metairie', value: 'Metairie' },
  { label: 'Metairie Terrace', value: 'Metairie Terrace' },
  { label: 'Metallostroy', value: 'Metallostroy' },
  { label: 'Metamora', value: 'Metamora' },
  { label: 'Metamórfosi', value: 'Metamórfosi' },
  { label: 'Metapa', value: 'Metapa' },
  { label: 'Metapán', value: 'Metapán' },
  { label: 'Metato', value: 'Metato' },
  { label: 'Metauten', value: 'Metauten' },
  { label: 'Metcalfe', value: 'Metcalfe' },
  { label: 'Metcalfe', value: 'Metcalfe' },
  { label: 'Metcalfe County', value: 'Metcalfe County' },
  { label: 'Metchosin', value: 'Metchosin' },
  { label: 'Metekel', value: 'Metekel' },
  { label: 'Metelen', value: 'Metelen' },
  { label: 'Metepec', value: 'Metepec' },
  { label: 'Metepec', value: 'Metepec' },
  { label: 'Metepec', value: 'Metepec' },
  { label: 'Metepec Primero', value: 'Metepec Primero' },
  { label: 'Meteren', value: 'Meteren' },
  { label: 'Méteren', value: 'Méteren' },
  { label: 'Meteş', value: 'Meteş' },
  { label: 'Metetí', value: 'Metetí' },
  { label: 'Metford', value: 'Metford' },
  { label: 'Metheringham', value: 'Metheringham' },
  { label: 'Methil', value: 'Methil' },
  { label: 'Methley', value: 'Methley' },
  { label: 'Methóni', value: 'Methóni' },
  { label: 'Methuen', value: 'Methuen' },
  { label: 'Methven', value: 'Methven' },
  { label: 'Methven', value: 'Methven' },
  { label: 'Metinaro', value: 'Metinaro' },
  { label: 'Metković', value: 'Metković' },
  { label: 'Metković', value: 'Metković' },
  { label: 'Mętków', value: 'Mętków' },
  { label: 'Metlac Hernández (Metlac Primero)', value: 'Metlac Hernández (Metlac Primero)' },
  { label: 'Metlakatla', value: 'Metlakatla' },
  { label: 'Metlaltoyuca', value: 'Metlaltoyuca' },
  { label: 'Metlaoui', value: 'Metlaoui' },
  { label: 'Metlapa', value: 'Metlapa' },
  { label: 'Metlatónoc', value: 'Metlatónoc' },
  { label: 'Metlika', value: 'Metlika' },
  { label: 'Metlili Chaamba', value: 'Metlili Chaamba' },
  { label: 'Metnitz', value: 'Metnitz' },
  { label: 'Metro', value: 'Metro' },
  { label: 'Metro Vancouver Regional District', value: 'Metro Vancouver Regional District' },
  { label: 'Metrogorodok', value: 'Metrogorodok' },
  { label: 'Metropolis', value: 'Metropolis' },
  { label: 'Metropolitan Borough Of Wirral', value: 'Metropolitan Borough Of Wirral' },
  { label: 'Mets Mant’Ash', value: 'Mets Mant’Ash' },
  { label: 'Mets Masrik', value: 'Mets Masrik' },
  { label: 'Mets Parni', value: 'Mets Parni' },
  { label: 'Metsamor', value: 'Metsamor' },
  { label: 'Metsavan', value: 'Metsavan' },
  { label: 'Metsemotlhaba', value: 'Metsemotlhaba' },
  { label: 'Metsovo', value: 'Metsovo' },
  { label: 'Metten', value: 'Metten' },
  { label: 'Mettendorf', value: 'Mettendorf' },
  { label: 'Mettenheim', value: 'Mettenheim' },
  { label: 'Mettenheim', value: 'Mettenheim' },
  { label: 'Metter', value: 'Metter' },
  { label: 'Mettersdorf Am Saßbach', value: 'Mettersdorf Am Saßbach' },
  { label: 'Mettet', value: 'Mettet' },
  { label: 'Mettingen', value: 'Mettingen' },
  { label: 'Mettlach', value: 'Mettlach' },
  { label: 'Mettmann', value: 'Mettmann' },
  { label: 'Mettmenstetten', value: 'Mettmenstetten' },
  { label: 'Mettray', value: 'Mettray' },
  { label: 'Mettuppalaiyam', value: 'Mettuppalaiyam' },
  { label: 'Mettur', value: 'Mettur' },
  { label: 'Metu', value: 'Metu' },
  { label: 'Metuchen', value: 'Metuchen' },
  { label: 'Metulla', value: 'Metulla' },
  { label: 'Metung', value: 'Metung' },
  { label: 'Metylovice', value: 'Metylovice' },
  { label: 'Metz', value: 'Metz' },
  { label: 'Metz', value: 'Metz' },
  { label: 'Metzeral', value: 'Metzeral' },
  { label: 'Metzervisse', value: 'Metzervisse' },
  { label: 'Metzger', value: 'Metzger' },
  { label: 'Metzingen', value: 'Metzingen' },
  { label: 'Metztitlán', value: 'Metztitlán' },
  { label: 'Meucon', value: 'Meucon' },
  { label: 'Meudon', value: 'Meudon' },
  { label: 'Meudt', value: 'Meudt' },
  { label: 'Meugliano', value: 'Meugliano' },
  { label: 'Meulaboh', value: 'Meulaboh' },
  { label: 'Meulan-En-Yvelines', value: 'Meulan-En-Yvelines' },
  { label: 'Meulebeke', value: 'Meulebeke' },
  { label: 'Meung-Sur-Loire', value: 'Meung-Sur-Loire' },
  { label: 'Meurchin', value: 'Meurchin' },
  { label: 'Meursac', value: 'Meursac' },
  { label: 'Meursault', value: 'Meursault' },
  { label: 'Meurthe-Et-Moselle', value: 'Meurthe-Et-Moselle' },
  { label: 'Meuse', value: 'Meuse' },
  { label: 'Meuselbach', value: 'Meuselbach' },
  { label: 'Meuselwitz', value: 'Meuselwitz' },
  { label: 'Mevagissey', value: 'Mevagissey' },
  { label: 'Mevasseret Ẕiyyon', value: 'Mevasseret Ẕiyyon' },
  { label: 'Mexborough', value: 'Mexborough' },
  { label: 'Mexcala', value: 'Mexcala' },
  { label: 'Mexcalcuautla', value: 'Mexcalcuautla' },
  { label: 'Mexia', value: 'Mexia' },
  { label: 'Mexicali', value: 'Mexicali' },
  { label: 'Mexicaltzingo', value: 'Mexicaltzingo' },
  { label: 'Mexicanos', value: 'Mexicanos' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Mexico Beach', value: 'Mexico Beach' },
  { label: 'Mexilhoeira Grande', value: 'Mexilhoeira Grande' },
  { label: 'Meximieux', value: 'Meximieux' },
  { label: 'Mexiquito', value: 'Mexiquito' },
  { label: 'Méxpan', value: 'Méxpan' },
  { label: 'Mextepec', value: 'Mextepec' },
  { label: 'Mexticacán', value: 'Mexticacán' },
  { label: 'Mexy', value: 'Mexy' },
  { label: 'Meybod', value: 'Meybod' },
  { label: 'Meycauayan', value: 'Meycauayan' },
  { label: 'Meycauayan', value: 'Meycauayan' },
  { label: 'Meyenburg', value: 'Meyenburg' },
  { label: 'Meyenheim', value: 'Meyenheim' },
  { label: 'Meyersdale', value: 'Meyersdale' },
  { label: 'Meylan', value: 'Meylan' },
  { label: 'Meymac', value: 'Meymac' },
  { label: 'Meymand', value: 'Meymand' },
  { label: 'Meymeh', value: 'Meymeh' },
  { label: 'Meynes', value: 'Meynes' },
  { label: 'Meyrargues', value: 'Meyrargues' },
  { label: 'Meyreuil', value: 'Meyreuil' },
  { label: 'Meyrin', value: 'Meyrin' },
  { label: 'Meyrueis', value: 'Meyrueis' },
  { label: 'Meyssac', value: 'Meyssac' },
  { label: 'Meysse', value: 'Meysse' },
  { label: 'Meythet', value: 'Meythet' },
  { label: 'Meyvaleh', value: 'Meyvaleh' },
  { label: 'Meyzieu', value: 'Meyzieu' },
  { label: 'Mezalocha', value: 'Mezalocha' },
  { label: 'Mezaourou', value: 'Mezaourou' },
  { label: 'Mezapa', value: 'Mezapa' },
  { label: 'Mezapa La Fábrica', value: 'Mezapa La Fábrica' },
  { label: 'Mežaparks', value: 'Mežaparks' },
  { label: 'Mezcala', value: 'Mezcala' },
  { label: 'Mezcala', value: 'Mezcala' },
  { label: 'Mezcales', value: 'Mezcales' },
  { label: 'Mezdra', value: 'Mezdra' },
  { label: 'Mèze', value: 'Mèze' },
  { label: 'Mezel', value: 'Mezel' },
  { label: 'Mezen’', value: 'Mezen’' },
  { label: 'Mezenivka', value: 'Mezenivka' },
  { label: 'Mezenskiy Rayon', value: 'Mezenskiy Rayon' },
  { label: 'Mézeray', value: 'Mézeray' },
  { label: 'Mézériat', value: 'Mézériat' },
  { label: 'Mezgore', value: 'Mezgore' },
  { label: 'Mezhdurechensk', value: 'Mezhdurechensk' },
  { label: 'Mezhdurechensk', value: 'Mezhdurechensk' },
  { label: 'Mezhdurechensk', value: 'Mezhdurechensk' },
  { label: 'Mezhdurechenskiy', value: 'Mezhdurechenskiy' },
  { label: 'Mezhdurechenskiy Rayon', value: 'Mezhdurechenskiy Rayon' },
  { label: 'Mezhevoy', value: 'Mezhevoy' },
  { label: 'Mezhova', value: 'Mezhova' },
  { label: 'Mezhvodnoye', value: 'Mezhvodnoye' },
  { label: 'Meziad', value: 'Meziad' },
  { label: 'Meziboři', value: 'Meziboři' },
  { label: 'Mežica', value: 'Mežica' },
  { label: 'Mézières', value: 'Mézières' },
  { label: 'Mézières-En-Brenne', value: 'Mézières-En-Brenne' },
  { label: 'Mézières-En-Drouais', value: 'Mézières-En-Drouais' },
  { label: 'Mézières-Sur-Seine', value: 'Mézières-Sur-Seine' },
  { label: 'Meziměstí', value: 'Meziměstí' },
  { label: 'Mézin', value: 'Mézin' },
  { label: 'Mezinovskiy', value: 'Mezinovskiy' },
  { label: 'Méziré', value: 'Méziré' },
  { label: 'Mezitli', value: 'Mezitli' },
  { label: 'Mezőberény', value: 'Mezőberény' },
  { label: 'Mezőcsát', value: 'Mezőcsát' },
  { label: 'Mezőcsáti Járás', value: 'Mezőcsáti Járás' },
  { label: 'Mezőfalva', value: 'Mezőfalva' },
  { label: 'Mezőhegyes', value: 'Mezőhegyes' },
  { label: 'Mezőkeresztes', value: 'Mezőkeresztes' },
  { label: 'Mezőkovácsháza', value: 'Mezőkovácsháza' },
  { label: 'Mezőkovácsházai Járás', value: 'Mezőkovácsházai Járás' },
  { label: 'Mezőkövesd', value: 'Mezőkövesd' },
  { label: 'Mezőkövesdi Járás', value: 'Mezőkövesdi Járás' },
  { label: 'Mezőszilas', value: 'Mezőszilas' },
  { label: 'Mezőtúr', value: 'Mezőtúr' },
  { label: 'Mezőtúri Járás', value: 'Mezőtúri Járás' },
  { label: 'Mezőzombor', value: 'Mezőzombor' },
  { label: 'Mezquita A', value: 'Mezquita A' },
  { label: 'Mezquita De Jarque', value: 'Mezquita De Jarque' },
  { label: 'Mezquital', value: 'Mezquital' },
  { label: 'Mezquital Del Oro', value: 'Mezquital Del Oro' },
  { label: 'Mezquite De Luna', value: 'Mezquite De Luna' },
  { label: 'Mezquite De Sotelo', value: 'Mezquite De Sotelo' },
  { label: 'Mezquite Gordo', value: 'Mezquite Gordo' },
  { label: 'Mezquitic De La Magdalena', value: 'Mezquitic De La Magdalena' },
  { label: 'Mezquitic Municipality', value: 'Mezquitic Municipality' },
  { label: 'Mezquititlán', value: 'Mezquititlán' },
  { label: 'Mézy-Sur-Seine', value: 'Mézy-Sur-Seine' },
  { label: 'Mezzago', value: 'Mezzago' },
  { label: 'Mezzana', value: 'Mezzana' },
  { label: 'Mezzana Bigli', value: 'Mezzana Bigli' },
  { label: 'Mezzana Mortigliengo', value: 'Mezzana Mortigliengo' },
  { label: 'Mezzana Rabattone', value: 'Mezzana Rabattone' },
  { label: 'Mezzane Di Sotto', value: 'Mezzane Di Sotto' },
  { label: 'Mezzanego', value: 'Mezzanego' },
  { label: 'Mezzanino', value: 'Mezzanino' },
  { label: 'Mezzano', value: 'Mezzano' },
  { label: 'Mezzano', value: 'Mezzano' },
  { label: 'Mezzano Inferiore', value: 'Mezzano Inferiore' },
  { label: 'Mezzate', value: 'Mezzate' },
  { label: 'Mezzegra', value: 'Mezzegra' },
  { label: 'Mezzenile', value: 'Mezzenile' },
  { label: 'Mezzocorona', value: 'Mezzocorona' },
  { label: 'Mezzogoro', value: 'Mezzogoro' },
  { label: 'Mezzojuso', value: 'Mezzojuso' },
  { label: 'Mezzolara', value: 'Mezzolara' },
  { label: 'Mezzoldo', value: 'Mezzoldo' },
  { label: 'Mezzolombardo', value: 'Mezzolombardo' },
  { label: 'Mezzomerico', value: 'Mezzomerico' },
  { label: 'Mezzouna', value: 'Mezzouna' },
  { label: 'Mfoundi', value: 'Mfoundi' },
  { label: 'Mga', value: 'Mga' },
  { label: 'Mgachi', value: 'Mgachi' },
  { label: 'Mgandu', value: 'Mgandu' },
  { label: 'Mġarr', value: 'Mġarr' },
  { label: 'Mglin', value: 'Mglin' },
  { label: 'Mglinskiy Rayon', value: 'Mglinskiy Rayon' },
  { label: 'Mhamid', value: 'Mhamid' },
  { label: 'Mhango', value: 'Mhango' },
  { label: 'Mhangura', value: 'Mhangura' },
  { label: 'Mhasla', value: 'Mhasla' },
  { label: 'Mhasvad', value: 'Mhasvad' },
  { label: 'Mhlambanyatsi', value: 'Mhlambanyatsi' },
  { label: 'Mhlume', value: 'Mhlume' },
  { label: 'Mi Patria Es Primero', value: 'Mi Patria Es Primero' },
  { label: 'Mi‘Ilyā', value: 'Mi‘Ilyā' },
  { label: 'Miacatlán', value: 'Miacatlán' },
  { label: 'Miączyn', value: 'Miączyn' },
  { label: 'Miaga', value: 'Miaga' },
  { label: 'Miagao', value: 'Miagao' },
  { label: 'Miagliano', value: 'Miagliano' },
  { label: 'Miahuatlán', value: 'Miahuatlán' },
  { label: 'Miahuatlán', value: 'Miahuatlán' },
  { label: 'Miahuatlán De Porfirio Díaz', value: 'Miahuatlán De Porfirio Díaz' },
  { label: 'Miajadas', value: 'Miajadas' },
  { label: 'Miami', value: 'Miami' },
  { label: 'Miami', value: 'Miami' },
  { label: 'Miami', value: 'Miami' },
  { label: 'Miami', value: 'Miami' },
  { label: 'Miami', value: 'Miami' },
  { label: 'Miami Beach', value: 'Miami Beach' },
  { label: 'Miami County', value: 'Miami County' },
  { label: 'Miami County', value: 'Miami County' },
  { label: 'Miami County', value: 'Miami County' },
  { label: 'Miami Gardens', value: 'Miami Gardens' },
  { label: 'Miami Heights', value: 'Miami Heights' },
  { label: 'Miami Lakes', value: 'Miami Lakes' },
  { label: 'Miami Shores', value: 'Miami Shores' },
  { label: 'Miami Springs', value: 'Miami Springs' },
  { label: 'Miami/Bexon', value: 'Miami/Bexon' },
  { label: 'Miami-Dade County', value: 'Miami-Dade County' },
  { label: 'Miamisburg', value: 'Miamisburg' },
  { label: 'Miamitown', value: 'Miamitown' },
  { label: 'Mian Channun', value: 'Mian Channun' },
  { label: 'Mianay', value: 'Mianay' },
  { label: 'Miandetta', value: 'Miandetta' },
  { label: 'Miandoab', value: 'Miandoab' },
  { label: 'Miandorud', value: 'Miandorud' },
  { label: 'Miane', value: 'Miane' },
  { label: 'Mianeh', value: 'Mianeh' },
  { label: 'Mianke Mor', value: 'Mianke Mor' },
  { label: 'Miano', value: 'Miano' },
  { label: 'Mianos', value: 'Mianos' },
  { label: 'Mianrood', value: 'Mianrood' },
  { label: 'Mianwali', value: 'Mianwali' },
  { label: 'Mianyang', value: 'Mianyang' },
  { label: 'Mianzulan', value: 'Mianzulan' },
  { label: 'Miaoli', value: 'Miaoli' },
  { label: 'Miaray', value: 'Miaray' },
  { label: 'Miasino', value: 'Miasino' },
  { label: 'Miass', value: 'Miass' },
  { label: 'Miasskoye', value: 'Miasskoye' },
  { label: 'Miasteczko Krajeńskie', value: 'Miasteczko Krajeńskie' },
  { label: 'Miasteczko Śląskie', value: 'Miasteczko Śląskie' },
  { label: 'Miastko', value: 'Miastko' },
  { label: 'Miastków Kościelny', value: 'Miastków Kościelny' },
  { label: 'Miatli', value: 'Miatli' },
  { label: 'Miazzina', value: 'Miazzina' },
  { label: 'Mibu', value: 'Mibu' },
  { label: 'Mica', value: 'Mica' },
  { label: 'Mica', value: 'Mica' },
  { label: 'Micăsasa', value: 'Micăsasa' },
  { label: 'Micco', value: 'Micco' },
  { label: 'Micereces De Tera', value: 'Micereces De Tera' },
  { label: 'Miceşti', value: 'Miceşti' },
  { label: 'Micești', value: 'Micești' },
  { label: 'Miceştii De Câmpie', value: 'Miceştii De Câmpie' },
  { label: 'Mičevec', value: 'Mičevec' },
  { label: 'Micfalău', value: 'Micfalău' },
  { label: 'Michac', value: 'Michac' },
  { label: 'Michaelerberg', value: 'Michaelerberg' },
  { label: 'Michaelnbach', value: 'Michaelnbach' },
  { label: 'Michalovce', value: 'Michalovce' },
  { label: 'Michałów', value: 'Michałów' },
  { label: 'Michałów', value: 'Michałów' },
  { label: 'Michałowice', value: 'Michałowice' },
  { label: 'Michałowice', value: 'Michałowice' },
  { label: 'Michałowo', value: 'Michałowo' },
  { label: 'Michałów-Reginów', value: 'Michałów-Reginów' },
  { label: 'Michanovichi', value: 'Michanovichi' },
  { label: 'Michapa', value: 'Michapa' },
  { label: 'Michelau', value: 'Michelau' },
  { label: 'Michelbach An Der Bilz', value: 'Michelbach An Der Bilz' },
  { label: 'Micheldorf', value: 'Micheldorf' },
  { label: 'Micheldorf In Oberösterreich', value: 'Micheldorf In Oberösterreich' },
  { label: 'Michelfeld', value: 'Michelfeld' },
  { label: 'Michelhausen', value: 'Michelhausen' },
  { label: 'Michelsneukirchen', value: 'Michelsneukirchen' },
  { label: 'Michelstadt', value: 'Michelstadt' },
  { label: 'Michendorf', value: 'Michendorf' },
  { label: 'Miches', value: 'Miches' },
  { label: 'Micheweni', value: 'Micheweni' },
  { label: 'Michigan Center', value: 'Michigan Center' },
  { label: 'Michigan City', value: 'Michigan City' },
  { label: 'Michimaloya', value: 'Michimaloya' },
  { label: 'Michów', value: 'Michów' },
  { label: 'Michuhol', value: 'Michuhol' },
  { label: 'Michurinsk', value: 'Michurinsk' },
  { label: 'Michurinskoye', value: 'Michurinskoye' },
  { label: 'Michurinskoye', value: 'Michurinskoye' },
  { label: 'Micieces De Ojeda', value: 'Micieces De Ojeda' },
  { label: 'Micigliano', value: 'Micigliano' },
  { label: 'Mickhausen', value: 'Mickhausen' },
  { label: 'Mickle Trafford', value: 'Mickle Trafford' },
  { label: 'Mickleham', value: 'Mickleham' },
  { label: 'Mickleton', value: 'Mickleton' },
  { label: 'Micleşti', value: 'Micleşti' },
  { label: 'Micoud', value: 'Micoud' },
  { label: 'Micula', value: 'Micula' },
  { label: 'Mid And East Antrim', value: 'Mid And East Antrim' },
  { label: 'Mid Calder', value: 'Mid Calder' },
  { label: 'Mid Murray', value: 'Mid Murray' },
  { label: 'Mid Ulster', value: 'Mid Ulster' },
  { label: 'Midar', value: 'Midar' },
  { label: 'Midavod', value: 'Midavod' },
  { label: 'Middelbeers', value: 'Middelbeers' },
  { label: 'Middelburg', value: 'Middelburg' },
  { label: 'Middelburg', value: 'Middelburg' },
  { label: 'Middelburg', value: 'Middelburg' },
  { label: 'Middelfart', value: 'Middelfart' },
  { label: 'Middelfart Kommune', value: 'Middelfart Kommune' },
  { label: 'Middelharnis', value: 'Middelharnis' },
  { label: 'Middelie', value: 'Middelie' },
  { label: 'Middelkerke', value: 'Middelkerke' },
  { label: 'Middelrode', value: 'Middelrode' },
  { label: 'Middelsluis', value: 'Middelsluis' },
  { label: 'Middelstum', value: 'Middelstum' },
  { label: 'Middenbeemster', value: 'Middenbeemster' },
  { label: 'Midden-Groningen', value: 'Midden-Groningen' },
  { label: 'Middenmeer', value: 'Middenmeer' },
  { label: 'Middle Cove', value: 'Middle Cove' },
  { label: 'Middle Fly', value: 'Middle Fly' },
  { label: 'Middle Island', value: 'Middle Island' },
  { label: 'Middle Island', value: 'Middle Island' },
  { label: 'Middle Park', value: 'Middle Park' },
  { label: 'Middle Park', value: 'Middle Park' },
  { label: 'Middle Quarters', value: 'Middle Quarters' },
  { label: 'Middle Ramu', value: 'Middle Ramu' },
  { label: 'Middle Rasen', value: 'Middle Rasen' },
  { label: 'Middle Ridge', value: 'Middle Ridge' },
  { label: 'Middle River', value: 'Middle River' },
  { label: 'Middle Swan', value: 'Middle Swan' },
  { label: 'Middle Valley', value: 'Middle Valley' },
  { label: 'Middle Village', value: 'Middle Village' },
  { label: 'Middle Winterslow', value: 'Middle Winterslow' },
  { label: 'Middleborough', value: 'Middleborough' },
  { label: 'Middleborough Center', value: 'Middleborough Center' },
  { label: 'Middlebourne', value: 'Middlebourne' },
  { label: 'Middleburg', value: 'Middleburg' },
  { label: 'Middleburg', value: 'Middleburg' },
  { label: 'Middleburg Heights', value: 'Middleburg Heights' },
  { label: 'Middleburgh', value: 'Middleburgh' },
  { label: 'Middlebury', value: 'Middlebury' },
  { label: 'Middlebury', value: 'Middlebury' },
  { label: 'Middlebury (Village)', value: 'Middlebury (Village)' },
  { label: 'Middlebush', value: 'Middlebush' },
  { label: 'Middlefield', value: 'Middlefield' },
  { label: 'Middlemount', value: 'Middlemount' },
  { label: 'Middleport', value: 'Middleport' },
  { label: 'Middleport', value: 'Middleport' },
  { label: 'Middlesboro', value: 'Middlesboro' },
  { label: 'Middlesbrough', value: 'Middlesbrough' },
  { label: 'Middlesex', value: 'Middlesex' },
  { label: 'Middlesex County', value: 'Middlesex County' },
  { label: 'Middlesex County', value: 'Middlesex County' },
  { label: 'Middlesex County', value: 'Middlesex County' },
  { label: 'Middlesex County', value: 'Middlesex County' },
  { label: 'Middlestown', value: 'Middlestown' },
  { label: 'Middleton', value: 'Middleton' },
  { label: 'Middleton', value: 'Middleton' },
  { label: 'Middleton', value: 'Middleton' },
  { label: 'Middleton', value: 'Middleton' },
  { label: 'Middleton', value: 'Middleton' },
  { label: 'Middleton', value: 'Middleton' },
  { label: 'Middleton', value: 'Middleton' },
  { label: 'Middleton Grange', value: 'Middleton Grange' },
  { label: 'Middletown', value: 'Middletown' },
  { label: 'Middletown', value: 'Middletown' },
  { label: 'Middletown', value: 'Middletown' },
  { label: 'Middletown', value: 'Middletown' },
  { label: 'Middletown', value: 'Middletown' },
  { label: 'Middletown', value: 'Middletown' },
  { label: 'Middletown', value: 'Middletown' },
  { label: 'Middletown', value: 'Middletown' },
  { label: 'Middletown', value: 'Middletown' },
  { label: 'Middletown', value: 'Middletown' },
  { label: 'Middletown', value: 'Middletown' },
  { label: 'Middleville', value: 'Middleville' },
  { label: 'Middlewich', value: 'Middlewich' },
  { label: 'Midelt', value: 'Midelt' },
  { label: 'Midelt', value: 'Midelt' },
  { label: 'Midfield', value: 'Midfield' },
  { label: 'Midhurst', value: 'Midhurst' },
  { label: 'Midi', value: 'Midi' },
  { label: 'Midland', value: 'Midland' },
  { label: 'Midland', value: 'Midland' },
  { label: 'Midland', value: 'Midland' },
  { label: 'Midland', value: 'Midland' },
  { label: 'Midland', value: 'Midland' },
  { label: 'Midland', value: 'Midland' },
  { label: 'Midland', value: 'Midland' },
  { label: 'Midland Beach', value: 'Midland Beach' },
  { label: 'Midland City', value: 'Midland City' },
  { label: 'Midland County', value: 'Midland County' },
  { label: 'Midland County', value: 'Midland County' },
  { label: 'Midland Park', value: 'Midland Park' },
  { label: 'Midlands', value: 'Midlands' },
  { label: 'Midleton', value: 'Midleton' },
  { label: 'Midlothian', value: 'Midlothian' },
  { label: 'Midlothian', value: 'Midlothian' },
  { label: 'Midlothian', value: 'Midlothian' },
  { label: 'Midlum', value: 'Midlum' },
  { label: 'Midori', value: 'Midori' },
  { label: 'Midori Shi', value: 'Midori Shi' },
  { label: 'Midoun', value: 'Midoun' },
  { label: 'Midpines', value: 'Midpines' },
  { label: 'Midrand', value: 'Midrand' },
  { label: 'Midreshet Ben-Gurion', value: 'Midreshet Ben-Gurion' },
  { label: 'Midsalip', value: 'Midsalip' },
  { label: 'Midsayap', value: 'Midsayap' },
  { label: 'Midsayap', value: 'Midsayap' },
  { label: 'Midsomer Norton', value: 'Midsomer Norton' },
  { label: 'Midstream', value: 'Midstream' },
  { label: 'Midsund', value: 'Midsund' },
  { label: 'Midtown', value: 'Midtown' },
  { label: 'Midtre Gauldal', value: 'Midtre Gauldal' },
  { label: 'Midvale', value: 'Midvale' },
  { label: 'Midvale', value: 'Midvale' },
  { label: 'Midway', value: 'Midway' },
  { label: 'Midway', value: 'Midway' },
  { label: 'Midway', value: 'Midway' },
  { label: 'Midway', value: 'Midway' },
  { label: 'Midway', value: 'Midway' },
  { label: 'Midway', value: 'Midway' },
  { label: 'Midway', value: 'Midway' },
  { label: 'Midway', value: 'Midway' },
  { label: 'Midway', value: 'Midway' },
  { label: 'Midway City', value: 'Midway City' },
  { label: 'Midway North', value: 'Midway North' },
  { label: 'Midway Point', value: 'Midway Point' },
  { label: 'Midway South', value: 'Midway South' },
  { label: 'Midwest City', value: 'Midwest City' },
  { label: 'Mid-Western Regional', value: 'Mid-Western Regional' },
  { label: 'Midwolda', value: 'Midwolda' },
  { label: 'Midwoud', value: 'Midwoud' },
  { label: 'Midyat', value: 'Midyat' },
  { label: 'Miechów', value: 'Miechów' },
  { label: 'Miechów Charsznica', value: 'Miechów Charsznica' },
  { label: 'Mieders', value: 'Mieders' },
  { label: 'Miedes De Aragón', value: 'Miedes De Aragón' },
  { label: 'Miedes De Atienza', value: 'Miedes De Atienza' },
  { label: 'Miedziana Góra', value: 'Miedziana Góra' },
  { label: 'Miedzichowo', value: 'Miedzichowo' },
  { label: 'Miedzna', value: 'Miedzna' },
  { label: 'Miedźna', value: 'Miedźna' },
  { label: 'Miedzno', value: 'Miedzno' },
  { label: 'Międzybórz', value: 'Międzybórz' },
  { label: 'Międzybrodzie Bialskie', value: 'Międzybrodzie Bialskie' },
  { label: 'Międzychód', value: 'Międzychód' },
  { label: 'Międzylesie', value: 'Międzylesie' },
  { label: 'Międzylesie', value: 'Międzylesie' },
  { label: 'Międzyrzec Podlaski', value: 'Międzyrzec Podlaski' },
  { label: 'Międzyrzecz', value: 'Międzyrzecz' },
  { label: 'Międzyzdroje', value: 'Międzyzdroje' },
  { label: 'Miehikkälä', value: 'Miehikkälä' },
  { label: 'Miehlen', value: 'Miehlen' },
  { label: 'Miejsce Piastowe', value: 'Miejsce Piastowe' },
  { label: 'Miejska Górka', value: 'Miejska Górka' },
  { label: 'Miękinia', value: 'Miękinia' },
  { label: 'Miękinia', value: 'Miękinia' },
  { label: 'Miélan', value: 'Miélan' },
  { label: 'Mielec', value: 'Mielec' },
  { label: 'Mieleszyn', value: 'Mieleszyn' },
  { label: 'Mielkendorf', value: 'Mielkendorf' },
  { label: 'Mielno', value: 'Mielno' },
  { label: 'Mieming', value: 'Mieming' },
  { label: 'Miengo', value: 'Miengo' },
  { label: 'Mier', value: 'Mier' },
  { label: 'Mier Y Noriega', value: 'Mier Y Noriega' },
  { label: 'Miera', value: 'Miera' },
  { label: 'Miercurea Nirajului', value: 'Miercurea Nirajului' },
  { label: 'Miercurea Sibiului', value: 'Miercurea Sibiului' },
  { label: 'Miercurea-Ciuc', value: 'Miercurea-Ciuc' },
  { label: 'Mieres', value: 'Mieres' },
  { label: 'Mierla La', value: 'Mierla La' },
  { label: 'Mierlo', value: 'Mierlo' },
  { label: 'Mieroszów', value: 'Mieroszów' },
  { label: 'Mierzęcice', value: 'Mierzęcice' },
  { label: 'Miesbach', value: 'Miesbach' },
  { label: 'Mieścisko', value: 'Mieścisko' },
  { label: 'Miesenbach', value: 'Miesenbach' },
  { label: 'Mieste', value: 'Mieste' },
  { label: 'Mieszkowice', value: 'Mieszkowice' },
  { label: 'Mietingen', value: 'Mietingen' },
  { label: 'Miętne', value: 'Miętne' },
  { label: 'Mietoinen', value: 'Mietoinen' },
  { label: 'Mieussy', value: 'Mieussy' },
  { label: 'Mieza', value: 'Mieza' },
  { label: 'Mifa', value: 'Mifa' },
  { label: 'Mifflin County', value: 'Mifflin County' },
  { label: 'Mifflinburg', value: 'Mifflinburg' },
  { label: 'Mifflintown', value: 'Mifflintown' },
  { label: 'Mifflinville', value: 'Mifflinville' },
  { label: 'Migdal Ha‘Emeq', value: 'Migdal Ha‘Emeq' },
  { label: 'Migennes', value: 'Migennes' },
  { label: 'Miggiano', value: 'Miggiano' },
  { label: 'Migjorn Gran Es', value: 'Migjorn Gran Es' },
  { label: 'Miglianico', value: 'Miglianico' },
  { label: 'Migliarina', value: 'Migliarina' },
  { label: 'Migliarino', value: 'Migliarino' },
  { label: 'Migliarino', value: 'Migliarino' },
  { label: 'Migliaro', value: 'Migliaro' },
  { label: 'Miglierina', value: 'Miglierina' },
  { label: 'Miglionico', value: 'Miglionico' },
  { label: 'Mignagola', value: 'Mignagola' },
  { label: 'Mignaloux-Beauvoir', value: 'Mignaloux-Beauvoir' },
  { label: 'Mignanego', value: 'Mignanego' },
  { label: 'Mignano Monte Lungo', value: 'Mignano Monte Lungo' },
  { label: 'Migné-Auxances', value: 'Migné-Auxances' },
  { label: 'Mignon', value: 'Mignon' },
  { label: 'Migny', value: 'Migny' },
  { label: 'Migori', value: 'Migori' },
  { label: 'Miguel Ahumada', value: 'Miguel Ahumada' },
  { label: 'Miguel Alemán', value: 'Miguel Alemán' },
  { label: 'Miguel Alemán (La Doce)', value: 'Miguel Alemán (La Doce)' },
  { label: 'Miguel Alves', value: 'Miguel Alves' },
  { label: 'Miguel Auza', value: 'Miguel Auza' },
  { label: 'Miguel Bocanegra', value: 'Miguel Bocanegra' },
  { label: 'Miguel Calmon', value: 'Miguel Calmon' },
  { label: 'Miguel Esteban', value: 'Miguel Esteban' },
  { label: 'Miguel Hidalgo', value: 'Miguel Hidalgo' },
  { label: 'Miguel Hidalgo', value: 'Miguel Hidalgo' },
  { label: 'Miguel Hidalgo', value: 'Miguel Hidalgo' },
  { label: 'Miguel Hidalgo', value: 'Miguel Hidalgo' },
  { label: 'Miguel Hidalgo', value: 'Miguel Hidalgo' },
  { label: 'Miguel Hidalgo', value: 'Miguel Hidalgo' },
  { label: 'Miguel Leão', value: 'Miguel Leão' },
  { label: 'Miguel Negrete', value: 'Miguel Negrete' },
  { label: 'Miguel Pereira', value: 'Miguel Pereira' },
  { label: 'Miguel Riglos', value: 'Miguel Riglos' },
  { label: 'Miguel Utrilla (Los Chorros)', value: 'Miguel Utrilla (Los Chorros)' },
  { label: 'Miguel Valdez Quintero (El Corazón)', value: 'Miguel Valdez Quintero (El Corazón)' },
  { label: 'Migueláñez', value: 'Migueláñez' },
  { label: 'Miguelópolis', value: 'Miguelópolis' },
  { label: 'Miguelturra', value: 'Miguelturra' },
  { label: 'Migues', value: 'Migues' },
  { label: 'Mihăeşti', value: 'Mihăeşti' },
  { label: 'Mihăeşti', value: 'Mihăeşti' },
  { label: 'Mihăeşti', value: 'Mihăeşti' },
  { label: 'Mihai Bravu', value: 'Mihai Bravu' },
  { label: 'Mihai Bravu', value: 'Mihai Bravu' },
  { label: 'Mihai Bravu', value: 'Mihai Bravu' },
  { label: 'Mihai Bravu', value: 'Mihai Bravu' },
  { label: 'Mihai Viteazu', value: 'Mihai Viteazu' },
  { label: 'Mihai Viteazu', value: 'Mihai Viteazu' },
  { label: 'Mihai Viteazu', value: 'Mihai Viteazu' },
  { label: 'Mihai Viteazu', value: 'Mihai Viteazu' },
  { label: 'Mihai Vodă', value: 'Mihai Vodă' },
  { label: 'Mihăiești', value: 'Mihăiești' },
  { label: 'Mihail Kogălniceanu', value: 'Mihail Kogălniceanu' },
  { label: 'Mihail Kogălniceanu', value: 'Mihail Kogălniceanu' },
  { label: 'Mihail Kogălniceanu', value: 'Mihail Kogălniceanu' },
  { label: 'Mihăileni', value: 'Mihăileni' },
  { label: 'Mihăileni', value: 'Mihăileni' },
  { label: 'Mihăileni', value: 'Mihăileni' },
  { label: 'Mihăileşti', value: 'Mihăileşti' },
  { label: 'Mihăileşti', value: 'Mihăileşti' },
  { label: 'Mihajlovo', value: 'Mihajlovo' },
  { label: 'Mihălăşeni', value: 'Mihălăşeni' },
  { label: 'Mihălceni', value: 'Mihălceni' },
  { label: 'Mihalgazi', value: 'Mihalgazi' },
  { label: 'Mihalıççık', value: 'Mihalıççık' },
  { label: 'Mihalţ', value: 'Mihalţ' },
  { label: 'Mihályi', value: 'Mihályi' },
  { label: 'Mihamleh-Ye Sofla', value: 'Mihamleh-Ye Sofla' },
  { label: 'Mihara', value: 'Mihara' },
  { label: 'Mihara', value: 'Mihara' },
  { label: 'Mihara Shi', value: 'Mihara Shi' },
  { label: 'Miharu', value: 'Miharu' },
  { label: 'Miheșu De Câmpie', value: 'Miheșu De Câmpie' },
  { label: 'Mihintale', value: 'Mihintale' },
  { label: 'Mihla', value: 'Mihla' },
  { label: 'Mihona', value: 'Mihona' },
  { label: 'Mihotići', value: 'Mihotići' },
  { label: 'Mihoveni', value: 'Mihoveni' },
  { label: 'Mihovljan', value: 'Mihovljan' },
  { label: 'Mihovljan', value: 'Mihovljan' },
  { label: 'Mija', value: 'Mija' },
  { label: 'Mijares', value: 'Mijares' },
  { label: 'Mijas', value: 'Mijas' },
  { label: 'Mijdrecht', value: 'Mijdrecht' },
  { label: 'Mijlocenii Bârgăului', value: 'Mijlocenii Bârgăului' },
  { label: 'Mijnsheerenland', value: 'Mijnsheerenland' },
  { label: 'Mikasa', value: 'Mikasa' },
  { label: 'Mikasa-Shi', value: 'Mikasa-Shi' },
  { label: 'Mikashevichy', value: 'Mikashevichy' },
  { label: 'Mike Town', value: 'Mike Town' },
  { label: 'Mikenaungea', value: 'Mikenaungea' },
  { label: 'Mikepércs', value: 'Mikepércs' },
  { label: 'Mikhalkovo', value: 'Mikhalkovo' },
  { label: 'Mikhaylov', value: 'Mikhaylov' },
  { label: 'Mikhaylovka', value: 'Mikhaylovka' },
  { label: 'Mikhaylovka', value: 'Mikhaylovka' },
  { label: 'Mikhaylovka', value: 'Mikhaylovka' },
  { label: 'Mikhaylovka', value: 'Mikhaylovka' },
  { label: 'Mikhaylovka', value: 'Mikhaylovka' },
  { label: 'Mikhaylovka Urban Okrug', value: 'Mikhaylovka Urban Okrug' },
  { label: 'Mikhaylovsk', value: 'Mikhaylovsk' },
  { label: 'Mikhaylovsk', value: 'Mikhaylovsk' },
  { label: 'Mikhaylovskaya', value: 'Mikhaylovskaya' },
  { label: 'Mikhaylovskiy Rayon', value: 'Mikhaylovskiy Rayon' },
  { label: 'Mikhaylovskiy Rayon', value: 'Mikhaylovskiy Rayon' },
  { label: 'Mikhaylovskiy Rayon', value: 'Mikhaylovskiy Rayon' },
  { label: 'Mikhaylovskoye', value: 'Mikhaylovskoye' },
  { label: 'Mikhaylovskoye', value: 'Mikhaylovskoye' },
  { label: 'Mikhnëvo', value: 'Mikhnëvo' },
  { label: 'Miki', value: 'Miki' },
  { label: 'Miki Shi', value: 'Miki Shi' },
  { label: 'Mikkeli', value: 'Mikkeli' },
  { label: 'Miklavž Na Dravskem Polju', value: 'Miklavž Na Dravskem Polju' },
  { label: 'Mikleuš', value: 'Mikleuš' },
  { label: 'Mikołajki', value: 'Mikołajki' },
  { label: 'Mikołajki Pomorskie', value: 'Mikołajki Pomorskie' },
  { label: 'Mikołów', value: 'Mikołów' },
  { label: 'Mikoma', value: 'Mikoma' },
  { label: 'Mikomeseng', value: 'Mikomeseng' },
  { label: 'Mikró Monastíri', value: 'Mikró Monastíri' },
  { label: 'Mikrópolis', value: 'Mikrópolis' },
  { label: 'Mikstat', value: 'Mikstat' },
  { label: 'Mikulášovice', value: 'Mikulášovice' },
  { label: 'Mikulčice', value: 'Mikulčice' },
  { label: 'Mikulino', value: 'Mikulino' },
  { label: 'Mikulintsy', value: 'Mikulintsy' },
  { label: 'Mikulov', value: 'Mikulov' },
  { label: 'Mikulovice', value: 'Mikulovice' },
  { label: 'Mikumi', value: 'Mikumi' },
  { label: 'Mikun’', value: 'Mikun’' },
  { label: 'Mikuni', value: 'Mikuni' },
  { label: 'Mil’Kovo', value: 'Mil’Kovo' },
  { label: 'Mil’Kovskiy Rayon', value: 'Mil’Kovskiy Rayon' },
  { label: 'Mila', value: 'Mila' },
  { label: 'Milà', value: 'Milà' },
  { label: 'Mila Doce', value: 'Mila Doce' },
  { label: 'Milab', value: 'Milab' },
  { label: 'Milaca', value: 'Milaca' },
  { label: 'Miladinovci', value: 'Miladinovci' },
  { label: 'Milagres', value: 'Milagres' },
  { label: 'Milagres', value: 'Milagres' },
  { label: 'Milagres Do Maranhão', value: 'Milagres Do Maranhão' },
  { label: 'Milagro', value: 'Milagro' },
  { label: 'Milagro', value: 'Milagro' },
  { label: 'Milagros', value: 'Milagros' },
  { label: 'Milagros', value: 'Milagros' },
  { label: 'Milagros', value: 'Milagros' },
  { label: 'Milajerd', value: 'Milajerd' },
  { label: 'Milak', value: 'Milak' },
  { label: 'Miłakowo', value: 'Miłakowo' },
  { label: 'Milam', value: 'Milam' },
  { label: 'Milam County', value: 'Milam County' },
  { label: 'Milan', value: 'Milan' },
  { label: 'Milan', value: 'Milan' },
  { label: 'Milan', value: 'Milan' },
  { label: 'Milan', value: 'Milan' },
  { label: 'Milan', value: 'Milan' },
  { label: 'Milan', value: 'Milan' },
  { label: 'Milan', value: 'Milan' },
  { label: 'Milan', value: 'Milan' },
  { label: 'Milan', value: 'Milan' },
  { label: 'Milán', value: 'Milán' },
  { label: 'Milanere', value: 'Milanere' },
  { label: 'Milano El', value: 'Milano El' },
  { label: 'Milano Marittima', value: 'Milano Marittima' },
  { label: 'Milanów', value: 'Milanów' },
  { label: 'Milanówek', value: 'Milanówek' },
  { label: 'Milaor', value: 'Milaor' },
  { label: 'Milas', value: 'Milas' },
  { label: 'Milaş', value: 'Milaş' },
  { label: 'Milășel', value: 'Milășel' },
  { label: 'Milazzo', value: 'Milazzo' },
  { label: 'Milbank', value: 'Milbank' },
  { label: 'Milborne Port', value: 'Milborne Port' },
  { label: 'Milborne St Andrew', value: 'Milborne St Andrew' },
  { label: 'Milbridge', value: 'Milbridge' },
  { label: 'Milcoiu', value: 'Milcoiu' },
  { label: 'Milcovățu', value: 'Milcovățu' },
  { label: 'Milcovul', value: 'Milcovul' },
  { label: 'Mildenau', value: 'Mildenau' },
  { label: 'Mildenhall', value: 'Mildenhall' },
  { label: 'Mildmay', value: 'Mildmay' },
  { label: 'Mildstedt', value: 'Mildstedt' },
  { label: 'Mildura', value: 'Mildura' },
  { label: 'Mildura Shire', value: 'Mildura Shire' },
  { label: 'Mile End', value: 'Mile End' },
  { label: 'Mile Gully', value: 'Mile Gully' },
  { label: 'Mile Gully', value: 'Mile Gully' },
  { label: 'Mileanca', value: 'Mileanca' },
  { label: 'Mileb', value: 'Mileb' },
  { label: 'Mileb', value: 'Mileb' },
  { label: 'Milehah', value: 'Milehah' },
  { label: 'Milejczyce', value: 'Milejczyce' },
  { label: 'Milejów', value: 'Milejów' },
  { label: 'Milena', value: 'Milena' },
  { label: 'Miles', value: 'Miles' },
  { label: 'Miles City', value: 'Miles City' },
  { label: 'Milesburg', value: 'Milesburg' },
  { label: 'Miletino', value: 'Miletino' },
  { label: 'Mileto', value: 'Mileto' },
  { label: 'Milevsko', value: 'Milevsko' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford', value: 'Milford' },
  { label: 'Milford Haven', value: 'Milford Haven' },
  { label: 'Milford Mill', value: 'Milford Mill' },
  { label: 'Milford On Sea', value: 'Milford On Sea' },
  { label: 'Milhã', value: 'Milhã' },
  { label: 'Milhah', value: 'Milhah' },
  { label: 'Milhan', value: 'Milhan' },
  { label: 'Milharado', value: 'Milharado' },
  { label: 'Milharado', value: 'Milharado' },
  { label: 'Milhaud', value: 'Milhaud' },
  { label: 'Milheeze', value: 'Milheeze' },
  { label: 'Milheirós', value: 'Milheirós' },
  { label: 'Milheirós De Poiares', value: 'Milheirós De Poiares' },
  { label: 'Milići', value: 'Milići' },
  { label: 'Milicz', value: 'Milicz' },
  { label: 'Milíkov', value: 'Milíkov' },
  { label: 'Mililani Town', value: 'Mililani Town' },
  { label: 'Milín', value: 'Milín' },
  { label: 'Milingimbi', value: 'Milingimbi' },
  { label: 'Miliroc', value: 'Miliroc' },
  { label: 'Milis', value: 'Milis' },
  { label: 'Milişăuţi', value: 'Milişăuţi' },
  { label: 'Militello In Val Di Catania', value: 'Militello In Val Di Catania' },
  { label: 'Militello Rosmarino', value: 'Militello Rosmarino' },
  { label: 'Milizac', value: 'Milizac' },
  { label: 'Milk River', value: 'Milk River' },
  { label: 'Milkipur', value: 'Milkipur' },
  { label: 'Miłkowice', value: 'Miłkowice' },
  { label: 'Mill City', value: 'Mill City' },
  { label: 'Mill Creek', value: 'Mill Creek' },
  { label: 'Mill Creek East', value: 'Mill Creek East' },
  { label: 'Mill Hall', value: 'Mill Hall' },
  { label: 'Mill Neck', value: 'Mill Neck' },
  { label: 'Mill Park', value: 'Mill Park' },
  { label: 'Mill Plain', value: 'Mill Plain' },
  { label: 'Mill Valley', value: 'Mill Valley' },
  { label: 'Mill Woods Town Centre', value: 'Mill Woods Town Centre' },
  { label: 'Millana', value: 'Millana' },
  { label: 'Millanes', value: 'Millanes' },
  { label: 'Millard County', value: 'Millard County' },
  { label: 'Millares', value: 'Millares' },
  { label: 'Millars Well', value: 'Millars Well' },
  { label: 'Millas', value: 'Millas' },
  { label: 'Millau', value: 'Millau' },
  { label: 'Millbank', value: 'Millbank' },
  { label: 'Millbourne', value: 'Millbourne' },
  { label: 'Millbrae', value: 'Millbrae' },
  { label: 'Millbridge', value: 'Millbridge' },
  { label: 'Millbrook', value: 'Millbrook' },
  { label: 'Millbrook', value: 'Millbrook' },
  { label: 'Millbrook', value: 'Millbrook' },
  { label: 'Millbrook', value: 'Millbrook' },
  { label: 'Millbury', value: 'Millbury' },
  { label: 'Millbury', value: 'Millbury' },
  { label: 'Millcreek', value: 'Millcreek' },
  { label: 'Mille Lacs County', value: 'Mille Lacs County' },
  { label: 'Milledgeville', value: 'Milledgeville' },
  { label: 'Millen', value: 'Millen' },
  { label: 'Millena', value: 'Millena' },
  { label: 'Millepini', value: 'Millepini' },
  { label: 'Miller', value: 'Miller' },
  { label: 'Miller', value: 'Miller' },
  { label: 'Miller County', value: 'Miller County' },
  { label: 'Miller County', value: 'Miller County' },
  { label: 'Miller County', value: 'Miller County' },
  { label: 'Miller Place', value: 'Miller Place' },
  { label: 'Millerovo', value: 'Millerovo' },
  { label: 'Millerovo', value: 'Millerovo' },
  { label: 'Millers Creek', value: 'Millers Creek' },
  { label: 'Millers Falls', value: 'Millers Falls' },
  { label: 'Millers Point', value: 'Millers Point' },
  { label: 'Millersburg', value: 'Millersburg' },
  { label: 'Millersburg', value: 'Millersburg' },
  { label: 'Millersburg', value: 'Millersburg' },
  { label: 'Millersport', value: 'Millersport' },
  { label: 'Millersville', value: 'Millersville' },
  { label: 'Millersville', value: 'Millersville' },
  { label: 'Millery', value: 'Millery' },
  { label: 'Milles De La Polvorosa', value: 'Milles De La Polvorosa' },
  { label: 'Millesimo', value: 'Millesimo' },
  { label: 'Millet', value: 'Millet' },
  { label: 'Millet', value: 'Millet' },
  { label: 'Millgrove', value: 'Millgrove' },
  { label: 'Millicent', value: 'Millicent' },
  { label: 'Milliken', value: 'Milliken' },
  { label: 'Millington', value: 'Millington' },
  { label: 'Millington', value: 'Millington' },
  { label: 'Millinocket', value: 'Millinocket' },
  { label: 'Millis', value: 'Millis' },
  { label: 'Millis-Clicquot', value: 'Millis-Clicquot' },
  { label: 'Millisle', value: 'Millisle' },
  { label: 'Millmerran', value: 'Millmerran' },
  { label: 'Millner', value: 'Millner' },
  { label: 'Millom', value: 'Millom' },
  { label: 'Millport', value: 'Millport' },
  { label: 'Mills', value: 'Mills' },
  { label: 'Mills County', value: 'Mills County' },
  { label: 'Mills County', value: 'Mills County' },
  { label: 'Mills River', value: 'Mills River' },
  { label: 'Millsboro', value: 'Millsboro' },
  { label: 'Millstadt', value: 'Millstadt' },
  { label: 'Millstatt', value: 'Millstatt' },
  { label: 'Millstream', value: 'Millstream' },
  { label: 'Millstreet', value: 'Millstreet' },
  { label: 'Millswood', value: 'Millswood' },
  { label: 'Millthorpe', value: 'Millthorpe' },
  { label: 'Milltimber', value: 'Milltimber' },
  { label: 'Milltown', value: 'Milltown' },
  { label: 'Milltown', value: 'Milltown' },
  { label: 'Millvale', value: 'Millvale' },
  { label: 'Millville', value: 'Millville' },
  { label: 'Millville', value: 'Millville' },
  { label: 'Millville', value: 'Millville' },
  { label: 'Millwood', value: 'Millwood' },
  { label: 'Milly-La-Forêt', value: 'Milly-La-Forêt' },
  { label: 'Milly-Sur-Thérain', value: 'Milly-Sur-Thérain' },
  { label: 'Milmarcos', value: 'Milmarcos' },
  { label: 'Milmersdorf', value: 'Milmersdorf' },
  { label: 'Milna', value: 'Milna' },
  { label: 'Milnathort', value: 'Milnathort' },
  { label: 'Milngavie', value: 'Milngavie' },
  { label: 'Milnrow', value: 'Milnrow' },
  { label: 'Milnthorpe', value: 'Milnthorpe' },
  { label: 'Milo', value: 'Milo' },
  { label: 'Milo', value: 'Milo' },
  { label: 'Miłomłyn', value: 'Miłomłyn' },
  { label: 'Miłoradz', value: 'Miłoradz' },
  { label: 'Mílos', value: 'Mílos' },
  { label: 'Miloşeşti', value: 'Miloşeşti' },
  { label: 'Miloslavskoye', value: 'Miloslavskoye' },
  { label: 'Miłosław', value: 'Miłosław' },
  { label: 'Milot', value: 'Milot' },
  { label: 'Milot', value: 'Milot' },
  { label: 'Milotice', value: 'Milotice' },
  { label: 'Milove', value: 'Milove' },
  { label: 'Milovice', value: 'Milovice' },
  { label: 'Milówka', value: 'Milówka' },
  { label: 'Milpa Alta', value: 'Milpa Alta' },
  { label: 'Milpas Viejas', value: 'Milpas Viejas' },
  { label: 'Milperra', value: 'Milperra' },
  { label: 'Milpillas', value: 'Milpillas' },
  { label: 'Milpillas', value: 'Milpillas' },
  { label: 'Milpitas', value: 'Milpitas' },
  { label: 'Milroy', value: 'Milroy' },
  { label: 'Mils Bei Imst', value: 'Mils Bei Imst' },
  { label: 'Mils Bei Solbad Hall', value: 'Mils Bei Solbad Hall' },
  { label: 'Milsbeek', value: 'Milsbeek' },
  { label: 'Milsons Point', value: 'Milsons Point' },
  { label: 'Miltach', value: 'Miltach' },
  { label: 'Miltenberg', value: 'Miltenberg' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Milton Brandão', value: 'Milton Brandão' },
  { label: 'Milton Keynes', value: 'Milton Keynes' },
  { label: 'Milton Of Campsie', value: 'Milton Of Campsie' },
  { label: 'Milton Of Leys', value: 'Milton Of Leys' },
  { label: 'Milton-Freewater', value: 'Milton-Freewater' },
  { label: 'Milverton', value: 'Milverton' },
  { label: 'Milwaukee', value: 'Milwaukee' },
  { label: 'Milwaukee County', value: 'Milwaukee County' },
  { label: 'Milwaukie', value: 'Milwaukie' },
  { label: 'Milyutinskaya', value: 'Milyutinskaya' },
  { label: 'Milyutinskiy Rayon', value: 'Milyutinskiy Rayon' },
  { label: 'Milz', value: 'Milz' },
  { label: 'Milzano', value: 'Milzano' },
  { label: 'Mima Shi', value: 'Mima Shi' },
  { label: 'Mimasaka-Shi', value: 'Mimasaka-Shi' },
  { label: 'Mimbaste', value: 'Mimbaste' },
  { label: 'Mimet', value: 'Mimet' },
  { label: 'Mimizan', value: 'Mimizan' },
  { label: 'Mimoň', value: 'Mimoň' },
  { label: 'Mimongo', value: 'Mimongo' },
  { label: 'Mimosa-Poggio Verde-Nuova Comunità', value: 'Mimosa-Poggio Verde-Nuova Comunità' },
  { label: 'Mimoso De Goiás', value: 'Mimoso De Goiás' },
  { label: 'Mimoso Do Sul', value: 'Mimoso Do Sul' },
  { label: 'Mims', value: 'Mims' },
  { label: 'Min Buri', value: 'Min Buri' },
  { label: 'Min’An', value: 'Min’An' },
  { label: 'Mina', value: 'Mina' },
  { label: 'Mina', value: 'Mina' },
  { label: 'Mina', value: 'Mina' },
  { label: 'Mina', value: 'Mina' },
  { label: 'Mina Clavero', value: 'Mina Clavero' },
  { label: 'Mina México', value: 'Mina México' },
  { label: 'Mina Nuevo León', value: 'Mina Nuevo León' },
  { label: 'Mina Vieja', value: 'Mina Vieja' },
  { label: 'Minab', value: 'Minab' },
  { label: 'Minaçu', value: 'Minaçu' },
  { label: 'Minador Do Negrão', value: 'Minador Do Negrão' },
  { label: 'Minakuchichō-Matoba', value: 'Minakuchichō-Matoba' },
  { label: 'Minalabac', value: 'Minalabac' },
  { label: 'Minalin', value: 'Minalin' },
  { label: 'Minalin', value: 'Minalin' },
  { label: 'Minallo', value: 'Minallo' },
  { label: 'Minallo', value: 'Minallo' },
  { label: 'Minamata', value: 'Minamata' },
  { label: 'Minamata Shi', value: 'Minamata Shi' },
  { label: 'Minami Alps-Shi', value: 'Minami Alps-Shi' },
  { label: 'Minamiaiki', value: 'Minamiaiki' },
  { label: 'Minamiashigara', value: 'Minamiashigara' },
  { label: 'Minamiashigara Shi', value: 'Minamiashigara Shi' },
  { label: 'Minamiawaji Shi', value: 'Minamiawaji Shi' },
  { label: 'Minamibōsō Shi', value: 'Minamibōsō Shi' },
  { label: 'Minamikyushu Shi', value: 'Minamikyushu Shi' },
  { label: 'Minamimaki', value: 'Minamimaki' },
  { label: 'Minamiminowa', value: 'Minamiminowa' },
  { label: 'Minamirinkan', value: 'Minamirinkan' },
  { label: 'Minamisatsuma Shi', value: 'Minamisatsuma Shi' },
  { label: 'Minamishimabara-Shi', value: 'Minamishimabara-Shi' },
  { label: 'Minami-Sōma', value: 'Minami-Sōma' },
  { label: 'Minamisōma Shi', value: 'Minamisōma Shi' },
  { label: 'Minamiuonuma Shi', value: 'Minamiuonuma Shi' },
  { label: 'Minanga Norte', value: 'Minanga Norte' },
  { label: 'Minanga Norte', value: 'Minanga Norte' },
  { label: 'Minante Segundo', value: 'Minante Segundo' },
  { label: 'Minante Segundo', value: 'Minante Segundo' },
  { label: 'Minapan', value: 'Minapan' },
  { label: 'Minapan', value: 'Minapan' },
  { label: 'Minapasoc', value: 'Minapasoc' },
  { label: 'Minas', value: 'Minas' },
  { label: 'Minas', value: 'Minas' },
  { label: 'Minas De Barroterán', value: 'Minas De Barroterán' },
  { label: 'Minas De Corrales', value: 'Minas De Corrales' },
  { label: 'Minas De Marcona', value: 'Minas De Marcona' },
  { label: 'Minas De Matahambre', value: 'Minas De Matahambre' },
  { label: 'Minas De Oro', value: 'Minas De Oro' },
  { label: 'Minas De Riotinto', value: 'Minas De Riotinto' },
  { label: 'Minas De São Domingos', value: 'Minas De São Domingos' },
  { label: 'Minas Do Leão', value: 'Minas Do Leão' },
  { label: 'Minas Novas', value: 'Minas Novas' },
  { label: 'Minatitlán', value: 'Minatitlán' },
  { label: 'Minatitlán', value: 'Minatitlán' },
  { label: 'Minato', value: 'Minato' },
  { label: 'Minato-Ku', value: 'Minato-Ku' },
  { label: 'Minaya', value: 'Minaya' },
  { label: 'Minbu', value: 'Minbu' },
  { label: 'Minchinabad', value: 'Minchinabad' },
  { label: 'Minchinbury', value: 'Minchinbury' },
  { label: 'Minchinhampton', value: 'Minchinhampton' },
  { label: 'Mincivan', value: 'Mincivan' },
  { label: 'Minco', value: 'Minco' },
  { label: 'Mindarie', value: 'Mindarie' },
  { label: 'Minde', value: 'Minde' },
  { label: 'Mindelheim', value: 'Mindelheim' },
  { label: 'Mindelo', value: 'Mindelo' },
  { label: 'Mindelo', value: 'Mindelo' },
  { label: 'Minden', value: 'Minden' },
  { label: 'Minden', value: 'Minden' },
  { label: 'Minden', value: 'Minden' },
  { label: 'Minden', value: 'Minden' },
  { label: 'Minden', value: 'Minden' },
  { label: 'Mindif', value: 'Mindif' },
  { label: 'Mindilóglion', value: 'Mindilóglion' },
  { label: 'Mîndreşti', value: 'Mîndreşti' },
  { label: 'Mindszent', value: 'Mindszent' },
  { label: 'Mindupok', value: 'Mindupok' },
  { label: 'Mindupok', value: 'Mindupok' },
  { label: 'Minduri', value: 'Minduri' },
  { label: 'Mindyak', value: 'Mindyak' },
  { label: 'Mine Shi', value: 'Mine Shi' },
  { label: 'Minehead', value: 'Minehead' },
  { label: 'Mineiros', value: 'Mineiros' },
  { label: 'Mineiros Do Tietê', value: 'Mineiros Do Tietê' },
  { label: 'Mineo', value: 'Mineo' },
  { label: 'Mineola', value: 'Mineola' },
  { label: 'Mineola', value: 'Mineola' },
  { label: 'Miner County', value: 'Miner County' },
  { label: 'Mineral County', value: 'Mineral County' },
  { label: 'Mineral County', value: 'Mineral County' },
  { label: 'Mineral County', value: 'Mineral County' },
  { label: 'Mineral County', value: 'Mineral County' },
  { label: 'Mineral De Angangueo', value: 'Mineral De Angangueo' },
  { label: 'Mineral De La Luz', value: 'Mineral De La Luz' },
  { label: 'Mineral Del Monte', value: 'Mineral Del Monte' },
  { label: 'Mineral Heights', value: 'Mineral Heights' },
  { label: 'Mineral Point', value: 'Mineral Point' },
  { label: 'Mineral Ridge', value: 'Mineral Ridge' },
  { label: 'Mineral Springs', value: 'Mineral Springs' },
  { label: 'Mineral Springs', value: 'Mineral Springs' },
  { label: 'Mineral Wells', value: 'Mineral Wells' },
  { label: 'Mineral Wells', value: 'Mineral Wells' },
  { label: 'Mineralni Bani', value: 'Mineralni Bani' },
  { label: 'Mineralnye Vody', value: 'Mineralnye Vody' },
  { label: 'Minerbe', value: 'Minerbe' },
  { label: 'Minerbio', value: 'Minerbio' },
  { label: 'Mineri', value: 'Mineri' },
  { label: 'Mineros', value: 'Mineros' },
  { label: 'Miners Rest', value: 'Miners Rest' },
  { label: 'Minersville', value: 'Minersville' },
  { label: 'Minerva', value: 'Minerva' },
  { label: 'Minerva Park', value: 'Minerva Park' },
  { label: 'Minervino Di Lecce', value: 'Minervino Di Lecce' },
  { label: 'Minervino Murge', value: 'Minervino Murge' },
  { label: 'Minetto', value: 'Minetto' },
  { label: 'Minety', value: 'Minety' },
  { label: 'Mineville', value: 'Mineville' },
  { label: 'Minfeld', value: 'Minfeld' },
  { label: 'Mingelchaur', value: 'Mingelchaur' },
  { label: 'Mingenew', value: 'Mingenew' },
  { label: 'Minggang', value: 'Minggang' },
  { label: 'Mingguang', value: 'Mingguang' },
  { label: 'Minglanilla', value: 'Minglanilla' },
  { label: 'Minglanilla', value: 'Minglanilla' },
  { label: 'Mingo County', value: 'Mingo County' },
  { label: 'Mingo Junction', value: 'Mingo Junction' },
  { label: 'Mingora', value: 'Mingora' },
  { label: 'Mingorría', value: 'Mingorría' },
  { label: 'Mingoyo', value: 'Mingoyo' },
  { label: 'Mingshui', value: 'Mingshui' },
  { label: 'Mingshui', value: 'Mingshui' },
  { label: 'Mingyue', value: 'Mingyue' },
  { label: 'Minhocal', value: 'Minhocal' },
  { label: 'Miniac-Morvan', value: 'Miniac-Morvan' },
  { label: 'Minidoka County', value: 'Minidoka County' },
  { label: 'Minien East', value: 'Minien East' },
  { label: 'Minien East', value: 'Minien East' },
  { label: 'Minier', value: 'Minier' },
  { label: 'Minieri', value: 'Minieri' },
  { label: 'Minihy-Tréguier', value: 'Minihy-Tréguier' },
  { label: 'Minipe', value: 'Minipe' },
  { label: 'Ministro Andreazza', value: 'Ministro Andreazza' },
  { label: 'Minjur', value: 'Minjur' },
  { label: 'Minkler', value: 'Minkler' },
  { label: 'Minlagas', value: 'Minlagas' },
  { label: 'Minlaton', value: 'Minlaton' },
  { label: 'Minna', value: 'Minna' },
  { label: 'Minneapolis', value: 'Minneapolis' },
  { label: 'Minneapolis', value: 'Minneapolis' },
  { label: 'Minnedosa', value: 'Minnedosa' },
  { label: 'Minnehaha', value: 'Minnehaha' },
  { label: 'Minnehaha County', value: 'Minnehaha County' },
  { label: 'Minneola', value: 'Minneola' },
  { label: 'Minneota', value: 'Minneota' },
  { label: 'Minnertsga', value: 'Minnertsga' },
  { label: 'Minnetonka', value: 'Minnetonka' },
  { label: 'Minnetonka Mills', value: 'Minnetonka Mills' },
  { label: 'Minnetrista', value: 'Minnetrista' },
  { label: 'Minnewaukan', value: 'Minnewaukan' },
  { label: 'Mino', value: 'Mino' },
  { label: 'Mino', value: 'Mino' },
  { label: 'Miño', value: 'Miño' },
  { label: 'Miño De Medinaceli', value: 'Miño De Medinaceli' },
  { label: 'Miño De San Esteban', value: 'Miño De San Esteban' },
  { label: 'Minoa', value: 'Minoa' },
  { label: 'Minokamo', value: 'Minokamo' },
  { label: 'Minokamo-Shi', value: 'Minokamo-Shi' },
  { label: 'Minolos', value: 'Minolos' },
  { label: 'Minonk', value: 'Minonk' },
  { label: 'Minoo Shi', value: 'Minoo Shi' },
  { label: 'Minooka', value: 'Minooka' },
  { label: 'Minor', value: 'Minor' },
  { label: 'Minorca', value: 'Minorca' },
  { label: 'Minori', value: 'Minori' },
  { label: 'Miñosa La', value: 'Miñosa La' },
  { label: 'Mino-Shi', value: 'Mino-Shi' },
  { label: 'Minot', value: 'Minot' },
  { label: 'Minot', value: 'Minot' },
  { label: 'Minot Air Force Base', value: 'Minot Air Force Base' },
  { label: 'Minowa', value: 'Minowa' },
  { label: 'Minsk', value: 'Minsk' },
  { label: 'Mińsk Mazowiecki', value: 'Mińsk Mazowiecki' },
  { label: 'Minster', value: 'Minster' },
  { label: 'Minster Lovell', value: 'Minster Lovell' },
  { label: 'Minsterley', value: 'Minsterley' },
  { label: 'Mint Hill', value: 'Mint Hill' },
  { label: 'Minta', value: 'Minta' },
  { label: 'Mintiu Gherlii', value: 'Mintiu Gherlii' },
  { label: 'Mintlaw', value: 'Mintlaw' },
  { label: 'Minto', value: 'Minto' },
  { label: 'Mintraching', value: 'Mintraching' },
  { label: 'Minturn', value: 'Minturn' },
  { label: 'Minturno', value: 'Minturno' },
  { label: 'Minucciano', value: 'Minucciano' },
  { label: 'Minudasht', value: 'Minudasht' },
  { label: 'Minuri', value: 'Minuri' },
  { label: 'Minuri', value: 'Minuri' },
  { label: 'Minushahr', value: 'Minushahr' },
  { label: 'Minusinsk', value: 'Minusinsk' },
  { label: 'Minusinskiy Rayon', value: 'Minusinskiy Rayon' },
  { label: 'Minusio', value: 'Minusio' },
  { label: 'Minuwangoda', value: 'Minuwangoda' },
  { label: 'Minuyan', value: 'Minuyan' },
  { label: 'Minya El Qamh', value: 'Minya El Qamh' },
  { label: 'Minyama', value: 'Minyama' },
  { label: 'Minyat An Naşr', value: 'Minyat An Naşr' },
  { label: 'Minzapan', value: 'Minzapan' },
  { label: 'Minzhu', value: 'Minzhu' },
  { label: 'Mio', value: 'Mio' },
  { label: 'Mioglia', value: 'Mioglia' },
  { label: 'Miola Di Pinè', value: 'Miola Di Pinè' },
  { label: 'Mionica', value: 'Mionica' },
  { label: 'Mionnay', value: 'Mionnay' },
  { label: 'Mions', value: 'Mions' },
  { label: 'Miorcani', value: 'Miorcani' },
  { label: 'Mios', value: 'Mios' },
  { label: 'Mioveni', value: 'Mioveni' },
  { label: 'Miquihuana', value: 'Miquihuana' },
  { label: 'Mir', value: 'Mir' },
  { label: 'Mīr Bachah Kōṯ', value: 'Mīr Bachah Kōṯ' },
  { label: 'Mira', value: 'Mira' },
  { label: 'Mira', value: 'Mira' },
  { label: 'Mira', value: 'Mira' },
  { label: 'Mira Estrela', value: 'Mira Estrela' },
  { label: 'Mira Mar', value: 'Mira Mar' },
  { label: 'Mira Mesa', value: 'Mira Mesa' },
  { label: 'Mira Monte', value: 'Mira Monte' },
  { label: 'Mira Taglio', value: 'Mira Taglio' },
  { label: 'Mirabad', value: 'Mirabad' },
  { label: 'Mīrābād', value: 'Mīrābād' },
  { label: 'Mirabel', value: 'Mirabel' },
  { label: 'Mirabel', value: 'Mirabel' },
  { label: 'Mirabela', value: 'Mirabela' },
  { label: 'Mirabel-Aux-Baronnies', value: 'Mirabel-Aux-Baronnies' },
  { label: 'Mirabella Eclano', value: 'Mirabella Eclano' },
  { label: 'Mirabella Imbaccari', value: 'Mirabella Imbaccari' },
  { label: 'Mirabello', value: 'Mirabello' },
  { label: 'Mirabello Monferrato', value: 'Mirabello Monferrato' },
  { label: 'Mirabello Sannitico', value: 'Mirabello Sannitico' },
  { label: 'Mirabueno', value: 'Mirabueno' },
  { label: 'Miracatu', value: 'Miracatu' },
  { label: 'Miracema', value: 'Miracema' },
  { label: 'Miracema Do Tocantins', value: 'Miracema Do Tocantins' },
  { label: 'Miradolo Terme', value: 'Miradolo Terme' },
  { label: 'Mirador', value: 'Mirador' },
  { label: 'Mirador', value: 'Mirador' },
  { label: 'Miradouro', value: 'Miradouro' },
  { label: 'Miraflores', value: 'Miraflores' },
  { label: 'Miraflores', value: 'Miraflores' },
  { label: 'Miraflores', value: 'Miraflores' },
  { label: 'Miraflores', value: 'Miraflores' },
  { label: 'Miraflores De La Sierra', value: 'Miraflores De La Sierra' },
  { label: 'Mirafuentes', value: 'Mirafuentes' },
  { label: 'Miragaia', value: 'Miragaia' },
  { label: 'Miragoâne', value: 'Miragoâne' },
  { label: 'Miraguaí', value: 'Miraguaí' },
  { label: 'Miraí', value: 'Miraí' },
  { label: 'Miraíma', value: 'Miraíma' },
  { label: 'Miraj', value: 'Miraj' },
  { label: 'Miralcamp', value: 'Miralcamp' },
  { label: 'Miralrío', value: 'Miralrío' },
  { label: 'Miramar', value: 'Miramar' },
  { label: 'Miramar', value: 'Miramar' },
  { label: 'Miramar', value: 'Miramar' },
  { label: 'Miramar', value: 'Miramar' },
  { label: 'Miramar', value: 'Miramar' },
  { label: 'Miramar', value: 'Miramar' },
  { label: 'Miramar', value: 'Miramar' },
  { label: 'Miramar Beach', value: 'Miramar Beach' },
  { label: 'Miramas', value: 'Miramas' },
  { label: 'Mirambeau', value: 'Mirambeau' },
  { label: 'Mirambel', value: 'Mirambel' },
  { label: 'Miramichi', value: 'Miramichi' },
  { label: 'Miramont-De-Guyenne', value: 'Miramont-De-Guyenne' },
  { label: 'Miran Shah', value: 'Miran Shah' },
  { label: 'Miranda', value: 'Miranda' },
  { label: 'Miranda', value: 'Miranda' },
  { label: 'Miranda', value: 'Miranda' },
  { label: 'Miranda', value: 'Miranda' },
  { label: 'Miranda', value: 'Miranda' },
  { label: 'Miranda', value: 'Miranda' },
  { label: 'Miranda De Arga', value: 'Miranda De Arga' },
  { label: 'Miranda De Azán', value: 'Miranda De Azán' },
  { label: 'Miranda De Ebro', value: 'Miranda De Ebro' },
  { label: 'Miranda Del Castañar', value: 'Miranda Del Castañar' },
  { label: 'Miranda Do Corvo', value: 'Miranda Do Corvo' },
  { label: 'Miranda Do Douro', value: 'Miranda Do Douro' },
  { label: 'Miranda Do Norte', value: 'Miranda Do Norte' },
  { label: 'Mirande', value: 'Mirande' },
  { label: 'Mirandela', value: 'Mirandela' },
  { label: 'Mirandiba', value: 'Mirandiba' },
  { label: 'Mirandilla', value: 'Mirandilla' },
  { label: 'Mirandola', value: 'Mirandola' },
  { label: 'Mirandol-Bourgnounac', value: 'Mirandol-Bourgnounac' },
  { label: 'Mirandopólis', value: 'Mirandopólis' },
  { label: 'Mirangaba', value: 'Mirangaba' },
  { label: 'Mirani', value: 'Mirani' },
  { label: 'Mirano', value: 'Mirano' },
  { label: 'Miranorte', value: 'Miranorte' },
  { label: 'Miranpur', value: 'Miranpur' },
  { label: 'Miranpur Katra', value: 'Miranpur Katra' },
  { label: 'Mirante', value: 'Mirante' },
  { label: 'Mirante Da Serra', value: 'Mirante Da Serra' },
  { label: 'Mirante Do Paranapanema', value: 'Mirante Do Paranapanema' },
  { label: 'Miraselva', value: 'Miraselva' },
  { label: 'Mira-Sintra', value: 'Mira-Sintra' },
  { label: 'Mirăslău', value: 'Mirăslău' },
  { label: 'Mirassol', value: 'Mirassol' },
  { label: 'Mirassol Doeste', value: 'Mirassol Doeste' },
  { label: 'Mirassolândia', value: 'Mirassolândia' },
  { label: 'Miratovac', value: 'Miratovac' },
  { label: 'Miravalle', value: 'Miravalle' },
  { label: 'Miravalles', value: 'Miravalles' },
  { label: 'Miravânia', value: 'Miravânia' },
  { label: 'Miravci', value: 'Miravci' },
  { label: 'Miraveche', value: 'Miraveche' },
  { label: 'Miravet', value: 'Miravet' },
  { label: 'Miravete De La Sierra', value: 'Miravete De La Sierra' },
  { label: 'Mirboo North', value: 'Mirboo North' },
  { label: 'Mircea Vodă', value: 'Mircea Vodă' },
  { label: 'Mircea Vodă', value: 'Mircea Vodă' },
  { label: 'Mirceşti', value: 'Mirceşti' },
  { label: 'Mirceștii Noi', value: 'Mirceștii Noi' },
  { label: 'Mircze', value: 'Mircze' },
  { label: 'Miré', value: 'Miré' },
  { label: 'Mirebalais', value: 'Mirebalais' },
  { label: 'Mirebeau', value: 'Mirebeau' },
  { label: 'Mirebeau-Sur-Bèze', value: 'Mirebeau-Sur-Bèze' },
  { label: 'Mirecourt', value: 'Mirecourt' },
  { label: 'Mirefleurs', value: 'Mirefleurs' },
  { label: 'Miremont', value: 'Miremont' },
  { label: 'Miren', value: 'Miren' },
  { label: 'Mirepeix', value: 'Mirepeix' },
  { label: 'Mirepoix', value: 'Mirepoix' },
  { label: 'Mireşu Mare', value: 'Mireşu Mare' },
  { label: 'Mireșu Mare', value: 'Mireșu Mare' },
  { label: 'Miřetice', value: 'Miřetice' },
  { label: 'Mireval', value: 'Mireval' },
  { label: 'Mirfield', value: 'Mirfield' },
  { label: 'Mirganj', value: 'Mirganj' },
  { label: 'Miri', value: 'Miri' },
  { label: 'Mirialguda', value: 'Mirialguda' },
  { label: 'Miribel', value: 'Miribel' },
  { label: 'Miribel-Les-Échelles', value: 'Miribel-Les-Échelles' },
  { label: 'Mirigioaia', value: 'Mirigioaia' },
  { label: 'Mirik', value: 'Mirik' },
  { label: 'Mirim Doce', value: 'Mirim Doce' },
  { label: 'Miringa', value: 'Miringa' },
  { label: 'Mirinzal', value: 'Mirinzal' },
  { label: 'Mirissa City', value: 'Mirissa City' },
  { label: 'Miriti - Paraná', value: 'Miriti - Paraná' },
  { label: 'Mirjaveh', value: 'Mirjaveh' },
  { label: 'Mirkovci', value: 'Mirkovci' },
  { label: 'Mirkovo', value: 'Mirkovo' },
  { label: 'Mirków', value: 'Mirków' },
  { label: 'Mirna', value: 'Mirna' },
  { label: 'Mirna Peč', value: 'Mirna Peč' },
  { label: 'Mirninskiy Rayon', value: 'Mirninskiy Rayon' },
  { label: 'Mirnovka', value: 'Mirnovka' },
  { label: 'Mirnoye', value: 'Mirnoye' },
  { label: 'Mirny', value: 'Mirny' },
  { label: 'Mirny', value: 'Mirny' },
  { label: 'Mirnyy', value: 'Mirnyy' },
  { label: 'Mirnyy', value: 'Mirnyy' },
  { label: 'Mirnyy', value: 'Mirnyy' },
  { label: 'Mirnyy', value: 'Mirnyy' },
  { label: 'Miro Khan', value: 'Miro Khan' },
  { label: 'Mirocin', value: 'Mirocin' },
  { label: 'Miron Costin', value: 'Miron Costin' },
  { label: 'Mirón El', value: 'Mirón El' },
  { label: 'Mironcillo', value: 'Mironcillo' },
  { label: 'Mironeasa', value: 'Mironeasa' },
  { label: 'Mirono', value: 'Mirono' },
  { label: 'Mirontsi', value: 'Mirontsi' },
  { label: 'Mironu', value: 'Mironu' },
  { label: 'Miroşi', value: 'Miroşi' },
  { label: 'Miroslav', value: 'Miroslav' },
  { label: 'Miroslava', value: 'Miroslava' },
  { label: 'Miroslăvești', value: 'Miroslăvești' },
  { label: 'Mirosławiec', value: 'Mirosławiec' },
  { label: 'Miroslovești', value: 'Miroslovești' },
  { label: 'Mirošov', value: 'Mirošov' },
  { label: 'Mirotice', value: 'Mirotice' },
  { label: 'Mirovice', value: 'Mirovice' },
  { label: 'Mirovka', value: 'Mirovka' },
  { label: 'Mirow', value: 'Mirow' },
  { label: 'Mirpur Bhtoro', value: 'Mirpur Bhtoro' },
  { label: 'Mirpur District', value: 'Mirpur District' },
  { label: 'Mirpur Khas', value: 'Mirpur Khas' },
  { label: 'Mirpur Mathelo', value: 'Mirpur Mathelo' },
  { label: 'Mirpur Sakro', value: 'Mirpur Sakro' },
  { label: 'Mirrabooka', value: 'Mirrabooka' },
  { label: 'Mirriah', value: 'Mirriah' },
  { label: 'Mirrormont', value: 'Mirrormont' },
  { label: 'Mirşid', value: 'Mirşid' },
  { label: 'Mirsk', value: 'Mirsk' },
  { label: 'Mirskoy', value: 'Mirskoy' },
  { label: 'Mirto', value: 'Mirto' },
  { label: 'Mirto', value: 'Mirto' },
  { label: 'Mirueña De Los Infanzones', value: 'Mirueña De Los Infanzones' },
  { label: 'Mirwah Gorchani', value: 'Mirwah Gorchani' },
  { label: 'Miryang', value: 'Miryang' },
  { label: 'Miryang-Si', value: 'Miryang-Si' },
  { label: 'Mirza Hesari', value: 'Mirza Hesari' },
  { label: 'Mirzapur', value: 'Mirzapur' },
  { label: 'Mirzec', value: 'Mirzec' },
  { label: 'Misano Adriatico', value: 'Misano Adriatico' },
  { label: 'Misano Di Gera Dadda', value: 'Misano Di Gera Dadda' },
  { label: 'Misantla', value: 'Misantla' },
  { label: 'Misasi', value: 'Misasi' },
  { label: 'Misato Shi', value: 'Misato Shi' },
  { label: 'Misau', value: 'Misau' },
  { label: 'Misawa', value: 'Misawa' },
  { label: 'Misawa Shi', value: 'Misawa Shi' },
  { label: 'Mișca', value: 'Mișca' },
  { label: 'Mișca', value: 'Mișca' },
  { label: 'Mischendorf', value: 'Mischendorf' },
  { label: 'Mischii', value: 'Mischii' },
  { label: 'Misentea', value: 'Misentea' },
  { label: 'Miserey-Salines', value: 'Miserey-Salines' },
  { label: 'Misérieux', value: 'Misérieux' },
  { label: 'Mishan', value: 'Mishan' },
  { label: 'Mishawaka', value: 'Mishawaka' },
  { label: 'Mishelevka', value: 'Mishelevka' },
  { label: 'Mishen', value: 'Mishen' },
  { label: 'Misheronskiy', value: 'Misheronskiy' },
  { label: 'Mishicot', value: 'Mishicot' },
  { label: 'Mishima', value: 'Mishima' },
  { label: 'Mishima Shi', value: 'Mishima Shi' },
  { label: 'Mishima-Gun', value: 'Mishima-Gun' },
  { label: 'Mishkino', value: 'Mishkino' },
  { label: 'Mishkino', value: 'Mishkino' },
  { label: 'Mishkinskiy Rayon', value: 'Mishkinskiy Rayon' },
  { label: 'Misija', value: 'Misija' },
  { label: 'Misilmeri', value: 'Misilmeri' },
  { label: 'Misinto', value: 'Misinto' },
  { label: 'Misión De Chichimecas', value: 'Misión De Chichimecas' },
  { label: 'Misión San Luis Gonzaga Chiriyaqui', value: 'Misión San Luis Gonzaga Chiriyaqui' },
  { label: 'Misión San Pablo', value: 'Misión San Pablo' },
  { label: 'Miskindzha', value: 'Miskindzha' },
  { label: 'Miskolc', value: 'Miskolc' },
  { label: 'Miskolci Járás', value: 'Miskolci Járás' },
  { label: 'Mislata', value: 'Mislata' },
  { label: 'Mislea', value: 'Mislea' },
  { label: 'Mislesevo', value: 'Mislesevo' },
  { label: 'Mislinja', value: 'Mislinja' },
  { label: 'Mislīyah', value: 'Mislīyah' },
  { label: 'Mismaloya', value: 'Mismaloya' },
  { label: 'Mismaloya (Fraccionamiento Pedregal De Santa Martha)', value: 'Mismaloya (Fraccionamiento Pedregal De Santa Martha)' },
  { label: 'Mişrātah', value: 'Mişrātah' },
  { label: 'Misrikh', value: 'Misrikh' },
  { label: 'Missaglia', value: 'Missaglia' },
  { label: 'Missal', value: 'Missal' },
  { label: 'Missanello', value: 'Missanello' },
  { label: 'Missão Velha', value: 'Missão Velha' },
  { label: 'Missaukee County', value: 'Missaukee County' },
  { label: 'Missen-Wilhams', value: 'Missen-Wilhams' },
  { label: 'Missillac', value: 'Missillac' },
  { label: 'Mission', value: 'Mission' },
  { label: 'Mission', value: 'Mission' },
  { label: 'Mission', value: 'Mission' },
  { label: 'Mission', value: 'Mission' },
  { label: 'Mission', value: 'Mission' },
  { label: 'Mission Beach', value: 'Mission Beach' },
  { label: 'Mission Bend', value: 'Mission Bend' },
  { label: 'Mission Canyon', value: 'Mission Canyon' },
  { label: 'Mission District', value: 'Mission District' },
  { label: 'Mission Hills', value: 'Mission Hills' },
  { label: 'Mission Hills', value: 'Mission Hills' },
  { label: 'Mission River', value: 'Mission River' },
  { label: 'Mission Viejo', value: 'Mission Viejo' },
  { label: 'Mississauga', value: 'Mississauga' },
  { label: 'Mississauga Beach', value: 'Mississauga Beach' },
  { label: 'Mississippi County', value: 'Mississippi County' },
  { label: 'Mississippi County', value: 'Mississippi County' },
  { label: 'Missoula', value: 'Missoula' },
  { label: 'Missoula County', value: 'Missoula County' },
  { label: 'Missour', value: 'Missour' },
  { label: 'Missouri City', value: 'Missouri City' },
  { label: 'Missouri Valley', value: 'Missouri Valley' },
  { label: 'Mistelbach', value: 'Mistelbach' },
  { label: 'Mistelgau', value: 'Mistelgau' },
  { label: 'Misterbianco', value: 'Misterbianco' },
  { label: 'Misterton', value: 'Misterton' },
  { label: 'Mistissini', value: 'Mistissini' },
  { label: 'Mistley', value: 'Mistley' },
  { label: 'Mistrató', value: 'Mistrató' },
  { label: 'Mistretta', value: 'Mistretta' },
  { label: 'Mistřice', value: 'Mistřice' },
  { label: 'Misungwi', value: 'Misungwi' },
  { label: 'Mitaka-Shi', value: 'Mitaka-Shi' },
  { label: 'Mitake', value: 'Mitake' },
  { label: 'Mitcham', value: 'Mitcham' },
  { label: 'Mitcham', value: 'Mitcham' },
  { label: 'Mitcham', value: 'Mitcham' },
  { label: 'Mitcheldean', value: 'Mitcheldean' },
  { label: 'Mitchell', value: 'Mitchell' },
  { label: 'Mitchell', value: 'Mitchell' },
  { label: 'Mitchell', value: 'Mitchell' },
  { label: 'Mitchell', value: 'Mitchell' },
  { label: 'Mitchell', value: 'Mitchell' },
  { label: 'Mitchell', value: 'Mitchell' },
  { label: 'Mitchell', value: 'Mitchell' },
  { label: 'Mitchell County', value: 'Mitchell County' },
  { label: 'Mitchell County', value: 'Mitchell County' },
  { label: 'Mitchell County', value: 'Mitchell County' },
  { label: 'Mitchell County', value: 'Mitchell County' },
  { label: 'Mitchell County', value: 'Mitchell County' },
  { label: 'Mitchell Park', value: 'Mitchell Park' },
  { label: 'Mitchell Town', value: 'Mitchell Town' },
  { label: 'Mitchellville', value: 'Mitchellville' },
  { label: 'Mitchellville', value: 'Mitchellville' },
  { label: 'Mitchelstown', value: 'Mitchelstown' },
  { label: 'Mitchelton', value: 'Mitchelton' },
  { label: 'Mitha Tiwana', value: 'Mitha Tiwana' },
  { label: 'Mithapur', value: 'Mithapur' },
  { label: 'Mithi', value: 'Mithi' },
  { label: 'Mitkin', value: 'Mitkin' },
  { label: 'Mito', value: 'Mito' },
  { label: 'Mitoc', value: 'Mitoc' },
  { label: 'Mitocu Dragomirnei', value: 'Mitocu Dragomirnei' },
  { label: 'Mitontic', value: 'Mitontic' },
  { label: 'Mito-Shi', value: 'Mito-Shi' },
  { label: 'Mitoyo Shi', value: 'Mitoyo Shi' },
  { label: 'Mitras Poniente', value: 'Mitras Poniente' },
  { label: 'Mitreni', value: 'Mitreni' },
  { label: 'Mitrești', value: 'Mitrești' },
  { label: 'Mitrofani', value: 'Mitrofani' },
  { label: 'Mitrofanovka', value: 'Mitrofanovka' },
  { label: 'Mitrofanovka', value: 'Mitrofanovka' },
  { label: 'Mitrópoli', value: 'Mitrópoli' },
  { label: 'Mitroúsi', value: 'Mitroúsi' },
  { label: 'Mitry-Mory', value: 'Mitry-Mory' },
  { label: 'Mitsamiouli', value: 'Mitsamiouli' },
  { label: 'Mitsoudjé', value: 'Mitsoudjé' },
  { label: 'Mitsukaidō', value: 'Mitsukaidō' },
  { label: 'Mitsuke', value: 'Mitsuke' },
  { label: 'Mitsuke Shi', value: 'Mitsuke Shi' },
  { label: 'Mitsushima', value: 'Mitsushima' },
  { label: 'Mittagong', value: 'Mittagong' },
  { label: 'Mitte', value: 'Mitte' },
  { label: 'Mittegroßefehn', value: 'Mittegroßefehn' },
  { label: 'Mittelberg', value: 'Mittelberg' },
  { label: 'Mittelbiberach', value: 'Mittelbiberach' },
  { label: 'Mitteleschenbach', value: 'Mitteleschenbach' },
  { label: 'Mittelhausbergen', value: 'Mittelhausbergen' },
  { label: 'Mittelherwigsdorf', value: 'Mittelherwigsdorf' },
  { label: 'Mittelhof', value: 'Mittelhof' },
  { label: 'Mittelneufnach', value: 'Mittelneufnach' },
  { label: 'Mittelschöntal', value: 'Mittelschöntal' },
  { label: 'Mittelstetten', value: 'Mittelstetten' },
  { label: 'Mittenaar', value: 'Mittenaar' },
  { label: 'Mittenwald', value: 'Mittenwald' },
  { label: 'Mittenwalde', value: 'Mittenwalde' },
  { label: 'Mitterbach Am Erlaufsee', value: 'Mitterbach Am Erlaufsee' },
  { label: 'Mitterberghütten', value: 'Mitterberghütten' },
  { label: 'Mitterdorf An Der Raab', value: 'Mitterdorf An Der Raab' },
  { label: 'Mitterdorf Im Mürztal', value: 'Mitterdorf Im Mürztal' },
  { label: 'Mitterfels', value: 'Mitterfels' },
  { label: 'Mitterhofen', value: 'Mitterhofen' },
  { label: 'Mitterkirchen Im Machland', value: 'Mitterkirchen Im Machland' },
  { label: 'Mitterlabill', value: 'Mitterlabill' },
  { label: 'Mitterndorf', value: 'Mitterndorf' },
  { label: 'Mitterndorf An Der Fischa', value: 'Mitterndorf An Der Fischa' },
  { label: 'Mitterndorf Im Steirischen Salzkammergut', value: 'Mitterndorf Im Steirischen Salzkammergut' },
  { label: 'Mitterpullendorf', value: 'Mitterpullendorf' },
  { label: 'Mittersill', value: 'Mittersill' },
  { label: 'Mitterskirchen', value: 'Mitterskirchen' },
  { label: 'Mitterteich', value: 'Mitterteich' },
  { label: 'Mittertreffling', value: 'Mittertreffling' },
  { label: 'Mittweida', value: 'Mittweida' },
  { label: 'Mitú', value: 'Mitú' },
  { label: 'Mitwitz', value: 'Mitwitz' },
  { label: 'Mityana', value: 'Mityana' },
  { label: 'Mityayevo', value: 'Mityayevo' },
  { label: 'Mitzic', value: 'Mitzic' },
  { label: 'Mitzitón', value: 'Mitzitón' },
  { label: 'Mitzpe Ramon', value: 'Mitzpe Ramon' },
  { label: 'Miura', value: 'Miura' },
  { label: 'Miura Shi', value: 'Miura Shi' },
  { label: 'Miusyns’K', value: 'Miusyns’K' },
  { label: 'Miuzela', value: 'Miuzela' },
  { label: 'Mixco', value: 'Mixco' },
  { label: 'Mixcum', value: 'Mixcum' },
  { label: 'Mixdorf', value: 'Mixdorf' },
  { label: 'Mixistlán De La Reforma', value: 'Mixistlán De La Reforma' },
  { label: 'Mixquiahuala De Juarez', value: 'Mixquiahuala De Juarez' },
  { label: 'Mixquiapan', value: 'Mixquiapan' },
  { label: 'Mixtla', value: 'Mixtla' },
  { label: 'Mixtla De Altamirano', value: 'Mixtla De Altamirano' },
  { label: 'Mixtlalcingo', value: 'Mixtlalcingo' },
  { label: 'Mixtlán', value: 'Mixtlán' },
  { label: 'Miyada', value: 'Miyada' },
  { label: 'Miyajima', value: 'Miyajima' },
  { label: 'Miyaki-Gun', value: 'Miyaki-Gun' },
  { label: 'Miyakinskiy Rayon', value: 'Miyakinskiy Rayon' },
  { label: 'Miyako', value: 'Miyako' },
  { label: 'Miyakojima Shi', value: 'Miyakojima Shi' },
  { label: 'Miyakonojō', value: 'Miyakonojō' },
  { label: 'Miyakonojō Shi', value: 'Miyakonojō Shi' },
  { label: 'Miyako-Shi', value: 'Miyako-Shi' },
  { label: 'Miyaly', value: 'Miyaly' },
  { label: 'Miyama Shi', value: 'Miyama Shi' },
  { label: 'Miyan Rahan', value: 'Miyan Rahan' },
  { label: 'Miyang', value: 'Miyang' },
  { label: 'Miyanoura', value: 'Miyanoura' },
  { label: 'Miyanshahr', value: 'Miyanshahr' },
  { label: 'Miyata', value: 'Miyata' },
  { label: 'Miyawaka Shi', value: 'Miyawaka Shi' },
  { label: 'Miyazaki', value: 'Miyazaki' },
  { label: 'Miyazaki-Shi', value: 'Miyazaki-Shi' },
  { label: 'Miyazu', value: 'Miyazu' },
  { label: 'Miyazu-Shi', value: 'Miyazu-Shi' },
  { label: 'Miyoshi', value: 'Miyoshi' },
  { label: 'Miyoshi Shi', value: 'Miyoshi Shi' },
  { label: 'Miyoshi Shi', value: 'Miyoshi Shi' },
  { label: 'Miyoshi-Gun', value: 'Miyoshi-Gun' },
  { label: 'Miyoshi-Shi', value: 'Miyoshi-Shi' },
  { label: 'Miyota', value: 'Miyota' },
  { label: 'Mīzan Teferī', value: 'Mīzan Teferī' },
  { label: 'Mizarela', value: 'Mizarela' },
  { label: 'Mizdah', value: 'Mizdah' },
  { label: 'Mizhhirya', value: 'Mizhhirya' },
  { label: 'Mizhhirya Raion', value: 'Mizhhirya Raion' },
  { label: 'Mizhirah', value: 'Mizhirah' },
  { label: 'Mizhou', value: 'Mizhou' },
  { label: 'Mizia', value: 'Mizia' },
  { label: 'Mizil', value: 'Mizil' },
  { label: 'Mizoch', value: 'Mizoch' },
  { label: 'Mizque', value: 'Mizque' },
  { label: 'Mizuho-Shi', value: 'Mizuho-Shi' },
  { label: 'Mizunami', value: 'Mizunami' },
  { label: 'Mizunami-Shi', value: 'Mizunami-Shi' },
  { label: 'Mizur', value: 'Mizur' },
  { label: 'Mizusawa', value: 'Mizusawa' },
  { label: 'Mjällby', value: 'Mjällby' },
  { label: 'Mjällby', value: 'Mjällby' },
  { label: 'Mjamaoué', value: 'Mjamaoué' },
  { label: 'Mjimandra', value: 'Mjimandra' },
  { label: 'Mjölby', value: 'Mjölby' },
  { label: 'Mkokotoni', value: 'Mkokotoni' },
  { label: 'Mkuranga', value: 'Mkuranga' },
  { label: 'Mkushi', value: 'Mkushi' },
  { label: 'Mladá Boleslav', value: 'Mladá Boleslav' },
  { label: 'Mladá Vožice', value: 'Mladá Vožice' },
  { label: 'Mladé Buky', value: 'Mladé Buky' },
  { label: 'Mlaka Pri Kranju', value: 'Mlaka Pri Kranju' },
  { label: 'Mlalo', value: 'Mlalo' },
  { label: 'Mlandizi', value: 'Mlandizi' },
  { label: 'Mlang', value: 'Mlang' },
  { label: 'Mlangali', value: 'Mlangali' },
  { label: 'Mława', value: 'Mława' },
  { label: 'Mlenăuți', value: 'Mlenăuți' },
  { label: 'Mliiv', value: 'Mliiv' },
  { label: 'Mlimba', value: 'Mlimba' },
  { label: 'Mljet', value: 'Mljet' },
  { label: 'Młodzieszyn', value: 'Młodzieszyn' },
  { label: 'Mlonggo', value: 'Mlonggo' },
  { label: 'Mlowo', value: 'Mlowo' },
  { label: 'Młynary', value: 'Młynary' },
  { label: 'Młynarze', value: 'Młynarze' },
  { label: 'Mlyniv', value: 'Mlyniv' },
  { label: 'Mlynivs’Kyy Rayon', value: 'Mlynivs’Kyy Rayon' },
  { label: 'Mmabatho', value: 'Mmabatho' },
  { label: 'Mmathubudukwane', value: 'Mmathubudukwane' },
  { label: 'Mme-Bafumen', value: 'Mme-Bafumen' },
  { label: 'Mmopone', value: 'Mmopone' },
  { label: 'Mnich', value: 'Mnich' },
  { label: 'Mnichovice', value: 'Mnichovice' },
  { label: 'Mnichovo Hradiště', value: 'Mnichovo Hradiště' },
  { label: 'Mnihla', value: 'Mnihla' },
  { label: 'Mników', value: 'Mników' },
  { label: 'Mniów', value: 'Mniów' },
  { label: 'Mníšek', value: 'Mníšek' },
  { label: 'Mníšek Pod Brdy', value: 'Mníšek Pod Brdy' },
  { label: 'Mniszków', value: 'Mniszków' },
  { label: 'Mnogoudobnoye', value: 'Mnogoudobnoye' },
  { label: 'Mnogovershinnyy', value: 'Mnogovershinnyy' },
  { label: 'Mnoungou', value: 'Mnoungou' },
  { label: 'Mo I Rana', value: 'Mo I Rana' },
  { label: 'Moa', value: 'Moa' },
  { label: 'Moab', value: 'Moab' },
  { label: 'Moabit', value: 'Moabit' },
  { label: 'Moacşa', value: 'Moacşa' },
  { label: 'Moalboal', value: 'Moalboal' },
  { label: 'Moallem Kelayeh', value: 'Moallem Kelayeh' },
  { label: 'Moama', value: 'Moama' },
  { label: 'Moamba District', value: 'Moamba District' },
  { label: 'Moana', value: 'Moana' },
  { label: 'Moaña', value: 'Moaña' },
  { label: 'Moanda', value: 'Moanda' },
  { label: 'Moanda', value: 'Moanda' },
  { label: 'Moapa Town', value: 'Moapa Town' },
  { label: 'Moapa Valley', value: 'Moapa Valley' },
  { label: 'Moara Carp', value: 'Moara Carp' },
  { label: 'Moara De Jos', value: 'Moara De Jos' },
  { label: 'Moara Domnească', value: 'Moara Domnească' },
  { label: 'Moara Grecilor', value: 'Moara Grecilor' },
  { label: 'Moara Nica', value: 'Moara Nica' },
  { label: 'Moara Vlăsiei', value: 'Moara Vlăsiei' },
  { label: 'Moasca', value: 'Moasca' },
  { label: 'Moate', value: 'Moate' },
  { label: 'Mobai', value: 'Mobai' },
  { label: 'Mobara', value: 'Mobara' },
  { label: 'Mobara Shi', value: 'Mobara Shi' },
  { label: 'Mobarak Abad', value: 'Mobarak Abad' },
  { label: 'Mobarakabad', value: 'Mobarakabad' },
  { label: 'Mobarakeh', value: 'Mobarakeh' },
  { label: 'Mobarakin', value: 'Mobarakin' },
  { label: 'Mobaye', value: 'Mobaye' },
  { label: 'Mobberley', value: 'Mobberley' },
  { label: 'Moberly', value: 'Moberly' },
  { label: 'Mobile', value: 'Mobile' },
  { label: 'Mobile County', value: 'Mobile County' },
  { label: 'Mobo', value: 'Mobo' },
  { label: 'Mobridge', value: 'Mobridge' },
  { label: 'Moca', value: 'Moca' },
  { label: 'Moca', value: 'Moca' },
  { label: 'Moca', value: 'Moca' },
  { label: 'Moca Municipio', value: 'Moca Municipio' },
  { label: 'Mocajuba', value: 'Mocajuba' },
  { label: 'Mocejón', value: 'Mocejón' },
  { label: 'Moceşti', value: 'Moceşti' },
  { label: 'Moch Municipality', value: 'Moch Municipality' },
  { label: 'Mocha', value: 'Mocha' },
  { label: 'Mochales', value: 'Mochales' },
  { label: 'Mochalishche', value: 'Mochalishche' },
  { label: 'Mochau', value: 'Mochau' },
  { label: 'Moche', value: 'Moche' },
  { label: 'Mochicahui', value: 'Mochicahui' },
  { label: 'Mochis', value: 'Mochis' },
  { label: 'Mochishche', value: 'Mochishche' },
  { label: 'Mochitlán', value: 'Mochitlán' },
  { label: 'Mocho', value: 'Mocho' },
  { label: 'Mochowo', value: 'Mochowo' },
  { label: 'Mochudi', value: 'Mochudi' },
  { label: 'Mochumí', value: 'Mochumí' },
  { label: 'Mocímboa', value: 'Mocímboa' },
  { label: 'Mocira', value: 'Mocira' },
  { label: 'Mociu', value: 'Mociu' },
  { label: 'Möckern', value: 'Möckern' },
  { label: 'Mockfjärd', value: 'Mockfjärd' },
  { label: 'Möckmühl', value: 'Möckmühl' },
  { label: 'Mockrehna', value: 'Mockrehna' },
  { label: 'Mocksville', value: 'Mocksville' },
  { label: 'Moclín', value: 'Moclín' },
  { label: 'Moclinejo', value: 'Moclinejo' },
  { label: 'Mocoa', value: 'Mocoa' },
  { label: 'Mococa', value: 'Mococa' },
  { label: 'Mococha', value: 'Mococha' },
  { label: 'Moconesi', value: 'Moconesi' },
  { label: 'Mocoretá', value: 'Mocoretá' },
  { label: 'Mocorito', value: 'Mocorito' },
  { label: 'Mocsa', value: 'Mocsa' },
  { label: 'Moctezuma', value: 'Moctezuma' },
  { label: 'Moctezuma', value: 'Moctezuma' },
  { label: 'Modakeke', value: 'Modakeke' },
  { label: 'Modalen', value: 'Modalen' },
  { label: 'Modane', value: 'Modane' },
  { label: 'Modasa', value: 'Modasa' },
  { label: 'Modave', value: 'Modave' },
  { label: 'Modbury', value: 'Modbury' },
  { label: 'Modbury', value: 'Modbury' },
  { label: 'Modbury Heights', value: 'Modbury Heights' },
  { label: 'Modbury North', value: 'Modbury North' },
  { label: 'Modderfontein', value: 'Modderfontein' },
  { label: 'Modelo', value: 'Modelo' },
  { label: 'Modelu', value: 'Modelu' },
  { label: 'Modena', value: 'Modena' },
  { label: 'Modesto', value: 'Modesto' },
  { label: 'Modi‘In Makkabbim Re‘Ut', value: 'Modi‘In Makkabbim Re‘Ut' },
  { label: 'Modica', value: 'Modica' },
  { label: 'Modigliana', value: 'Modigliana' },
  { label: 'Modiin Ilit', value: 'Modiin Ilit' },
  { label: 'Modimolle', value: 'Modimolle' },
  { label: 'Mödingen', value: 'Mödingen' },
  { label: 'Modliborzyce', value: 'Modliborzyce' },
  { label: 'Mödling', value: 'Mödling' },
  { label: 'Modlnica', value: 'Modlnica' },
  { label: 'Modlniczka', value: 'Modlniczka' },
  { label: 'Modoc County', value: 'Modoc County' },
  { label: 'Modolo', value: 'Modolo' },
  { label: 'Modra', value: 'Modra' },
  { label: 'Modřany', value: 'Modřany' },
  { label: 'Modriach', value: 'Modriach' },
  { label: 'Modriča', value: 'Modriča' },
  { label: 'Modřice', value: 'Modřice' },
  { label: 'Modúbar De La Emparedada', value: 'Modúbar De La Emparedada' },
  { label: 'Modugno', value: 'Modugno' },
  { label: 'Modum', value: 'Modum' },
  { label: 'Moe', value: 'Moe' },
  { label: 'Moeche', value: 'Moeche' },
  { label: 'Moeciu De Jos', value: 'Moeciu De Jos' },
  { label: 'Moeda', value: 'Moeda' },
  { label: 'Moeiwadi', value: 'Moeiwadi' },
  { label: 'Moëlan-Sur-Mer', value: 'Moëlan-Sur-Mer' },
  { label: 'Moelfre', value: 'Moelfre' },
  { label: 'Moelv', value: 'Moelv' },
  { label: 'Moema', value: 'Moema' },
  { label: 'Moen', value: 'Moen' },
  { label: 'Moena', value: 'Moena' },
  { label: 'Moengo', value: 'Moengo' },
  { label: 'Moerbeek', value: 'Moerbeek' },
  { label: 'Moerbeke', value: 'Moerbeke' },
  { label: 'Moerdijk', value: 'Moerdijk' },
  { label: 'Moerewa', value: 'Moerewa' },
  { label: 'Moers', value: 'Moers' },
  { label: 'Moesel', value: 'Moesel' },
  { label: 'Moffat', value: 'Moffat' },
  { label: 'Moffat Beach', value: 'Moffat Beach' },
  { label: 'Moffat County', value: 'Moffat County' },
  { label: 'Mofleni', value: 'Mofleni' },
  { label: 'Moftinu Mare', value: 'Moftinu Mare' },
  { label: 'Moftinu Mic', value: 'Moftinu Mic' },
  { label: 'Moga', value: 'Moga' },
  { label: 'Mogadishu', value: 'Mogadishu' },
  { label: 'Mogadore', value: 'Mogadore' },
  { label: 'Mogadouro', value: 'Mogadouro' },
  { label: 'Mogán', value: 'Mogán' },
  { label: 'Mogapi', value: 'Mogapi' },
  { label: 'Mogarraz', value: 'Mogarraz' },
  { label: 'Mogbwemo', value: 'Mogbwemo' },
  { label: 'Mogeiro', value: 'Mogeiro' },
  { label: 'Mogelsberg', value: 'Mogelsberg' },
  { label: 'Mogendorf', value: 'Mogendorf' },
  { label: 'Mogente', value: 'Mogente' },
  { label: 'Mogente/Moixent', value: 'Mogente/Moixent' },
  { label: 'Mogersdorf', value: 'Mogersdorf' },
  { label: 'Mogești', value: 'Mogești' },
  { label: 'Moggar', value: 'Moggar' },
  { label: 'Möggers', value: 'Möggers' },
  { label: 'Moggill', value: 'Moggill' },
  { label: 'Moggio', value: 'Moggio' },
  { label: 'Moggio Di Sotto', value: 'Moggio Di Sotto' },
  { label: 'Moggio Udinese', value: 'Moggio Udinese' },
  { label: 'Mögglingen', value: 'Mögglingen' },
  { label: 'Mogi Das Cruzes', value: 'Mogi Das Cruzes' },
  { label: 'Mogi Guaçu', value: 'Mogi Guaçu' },
  { label: 'Mogi Mirim', value: 'Mogi Mirim' },
  { label: 'Mogielnica', value: 'Mogielnica' },
  { label: 'Mogila', value: 'Mogila' },
  { label: 'Mogilany', value: 'Mogilany' },
  { label: 'Mogilno', value: 'Mogilno' },
  { label: 'Moglia', value: 'Moglia' },
  { label: 'Mogliano', value: 'Mogliano' },
  { label: 'Mogliano Veneto', value: 'Mogliano Veneto' },
  { label: 'Möglingen', value: 'Möglingen' },
  { label: 'Mogneville', value: 'Mogneville' },
  { label: 'Mogoaia', value: 'Mogoaia' },
  { label: 'Mogocha', value: 'Mogocha' },
  { label: 'Mogoditshane', value: 'Mogoditshane' },
  { label: 'Mogok', value: 'Mogok' },
  { label: 'Mogollón', value: 'Mogollón' },
  { label: 'Mogorella', value: 'Mogorella' },
  { label: 'Mogoro', value: 'Mogoro' },
  { label: 'Mogoș', value: 'Mogoș' },
  { label: 'Mogoşani', value: 'Mogoşani' },
  { label: 'Mogoşeşti', value: 'Mogoşeşti' },
  { label: 'Mogoșești', value: 'Mogoșești' },
  { label: 'Mogoşeşti-Siret', value: 'Mogoşeşti-Siret' },
  { label: 'Mogoşoaia', value: 'Mogoşoaia' },
  { label: 'Mogotes', value: 'Mogotes' },
  { label: 'Mogoytuy', value: 'Mogoytuy' },
  { label: 'Mogpog', value: 'Mogpog' },
  { label: 'Moguer', value: 'Moguer' },
  { label: 'Mogul', value: 'Mogul' },
  { label: 'Mogyoród', value: 'Mogyoród' },
  { label: 'Mogyorósbánya', value: 'Mogyorósbánya' },
  { label: 'Mogzon', value: 'Mogzon' },
  { label: 'Mohács', value: 'Mohács' },
  { label: 'Mohácsi Járás', value: 'Mohácsi Járás' },
  { label: 'Mohadi', value: 'Mohadi' },
  { label: 'Mohajeran', value: 'Mohajeran' },
  { label: 'Mohajeran', value: 'Mohajeran' },
  { label: 'Mohale’S Hoek', value: 'Mohale’S Hoek' },
  { label: 'Mohali', value: 'Mohali' },
  { label: 'Mohall', value: 'Mohall' },
  { label: 'Mohamadan', value: 'Mohamadan' },
  { label: 'Mohammad Abad', value: 'Mohammad Abad' },
  { label: 'Mohammad Shahr', value: 'Mohammad Shahr' },
  { label: 'Mohammad Shahr', value: 'Mohammad Shahr' },
  { label: 'Mohammadabad', value: 'Mohammadabad' },
  { label: 'Mohammadābād', value: 'Mohammadābād' },
  { label: 'Mohammadabad-E Chulak', value: 'Mohammadabad-E Chulak' },
  { label: 'Mohammadi', value: 'Mohammadi' },
  { label: 'Mohammadiyeh ', value: 'Mohammadiyeh ' },
  { label: 'Mohammadiyeh-E Sofla', value: 'Mohammadiyeh-E Sofla' },
  { label: 'Mohammadyar', value: 'Mohammadyar' },
  { label: 'Mohammedia', value: 'Mohammedia' },
  { label: 'Mohan', value: 'Mohan' },
  { label: 'Mohanpur', value: 'Mohanpur' },
  { label: 'Mohanur', value: 'Mohanur' },
  { label: 'Mohara', value: 'Mohara' },
  { label: 'Mohave County', value: 'Mohave County' },
  { label: 'Mohave Valley', value: 'Mohave Valley' },
  { label: 'Mohawk', value: 'Mohawk' },
  { label: 'Moheda', value: 'Moheda' },
  { label: 'Mohedas De Granadilla', value: 'Mohedas De Granadilla' },
  { label: 'Mohedas De La Jara', value: 'Mohedas De La Jara' },
  { label: 'Mohelnice', value: 'Mohelnice' },
  { label: 'Mohelno', value: 'Mohelno' },
  { label: 'Mohernando', value: 'Mohernando' },
  { label: 'Mohgaon', value: 'Mohgaon' },
  { label: 'Mohiuddi Nagar', value: 'Mohiuddi Nagar' },
  { label: 'Möhlau', value: 'Möhlau' },
  { label: 'Möhlin', value: 'Möhlin' },
  { label: 'Mohlsdorf', value: 'Mohlsdorf' },
  { label: 'Möhnesee', value: 'Möhnesee' },
  { label: 'Mohnton', value: 'Mohnton' },
  { label: 'Mohol', value: 'Mohol' },
  { label: 'Mohoneras', value: 'Mohoneras' },
  { label: 'Mohoro', value: 'Mohoro' },
  { label: 'Mohpa', value: 'Mohpa' },
  { label: 'Mohr', value: 'Mohr' },
  { label: 'Möhrendorf', value: 'Möhrendorf' },
  { label: 'Mohrkirch', value: 'Mohrkirch' },
  { label: 'Mohyabad', value: 'Mohyabad' },
  { label: 'Mohyliv-Podilskyi', value: 'Mohyliv-Podilskyi' },
  { label: 'Moi', value: 'Moi' },
  { label: 'Moià', value: 'Moià' },
  { label: 'Moiano', value: 'Moiano' },
  { label: 'Moidieu', value: 'Moidieu' },
  { label: 'Moie', value: 'Moie' },
  { label: 'Moieciu De Jos', value: 'Moieciu De Jos' },
  { label: 'Moigny-Sur-École', value: 'Moigny-Sur-École' },
  { label: 'Moijabana', value: 'Moijabana' },
  { label: 'Moil', value: 'Moil' },
  { label: 'Moimacco', value: 'Moimacco' },
  { label: 'Moimenta Da Beira', value: 'Moimenta Da Beira' },
  { label: 'Moimenta Da Serra', value: 'Moimenta Da Serra' },
  { label: 'Moimentinha', value: 'Moimentinha' },
  { label: 'Moineşti', value: 'Moineşti' },
  { label: 'Moio Alcantara', value: 'Moio Alcantara' },
  { label: 'Moio De Calvi', value: 'Moio De Calvi' },
  { label: 'Moio Della Civitella-Pellare', value: 'Moio Della Civitella-Pellare' },
  { label: 'Moiola', value: 'Moiola' },
  { label: 'Moiporá', value: 'Moiporá' },
  { label: 'Moira', value: 'Moira' },
  { label: 'Moira', value: 'Moira' },
  { label: 'Moira', value: 'Moira' },
  { label: 'Moirans', value: 'Moirans' },
  { label: 'Moirans-En-Montagne', value: 'Moirans-En-Montagne' },
  { label: 'Moíres', value: 'Moíres' },
  { label: 'Moișa', value: 'Moișa' },
  { label: 'Mõisaküla', value: 'Mõisaküla' },
  { label: 'Moisburg', value: 'Moisburg' },
  { label: 'Moisdon-La-Rivière', value: 'Moisdon-La-Rivière' },
  { label: 'Moisei', value: 'Moisei' },
  { label: 'Moisenay', value: 'Moisenay' },
  { label: 'Moișeni', value: 'Moișeni' },
  { label: 'Moises Padilla', value: 'Moises Padilla' },
  { label: 'Moislains', value: 'Moislains' },
  { label: 'Moissac', value: 'Moissac' },
  { label: 'Moïssala', value: 'Moïssala' },
  { label: 'Moisselles', value: 'Moisselles' },
  { label: 'Moissy-Cramayel', value: 'Moissy-Cramayel' },
  { label: 'Moita', value: 'Moita' },
  { label: 'Moita', value: 'Moita' },
  { label: 'Moita Bonita', value: 'Moita Bonita' },
  { label: 'Moita Dos Ferreiros', value: 'Moita Dos Ferreiros' },
  { label: 'Moita Dos Ferreiros', value: 'Moita Dos Ferreiros' },
  { label: 'Mojacar', value: 'Mojacar' },
  { label: 'Mojados', value: 'Mojados' },
  { label: 'Mojanovići', value: 'Mojanovići' },
  { label: 'Mojarras', value: 'Mojarras' },
  { label: 'Mojave', value: 'Mojave' },
  { label: 'Mojen', value: 'Mojen' },
  { label: 'Mojimán', value: 'Mojimán' },
  { label: 'Mojkovac', value: 'Mojkovac' },
  { label: 'Mojo', value: 'Mojo' },
  { label: 'Mojoagung', value: 'Mojoagung' },
  { label: 'Mojokerto', value: 'Mojokerto' },
  { label: 'Mojón Grande', value: 'Mojón Grande' },
  { label: 'Mojonera La', value: 'Mojonera La' },
  { label: 'Mojstrana', value: 'Mojstrana' },
  { label: 'Moju', value: 'Moju' },
  { label: 'Mojuí Dos Campos', value: 'Mojuí Dos Campos' },
  { label: 'Moka', value: 'Moka' },
  { label: 'Mokameh', value: 'Mokameh' },
  { label: 'Mokarrabi', value: 'Mokarrabi' },
  { label: 'Mokena', value: 'Mokena' },
  { label: 'Mokhada Taluka', value: 'Mokhada Taluka' },
  { label: 'Mokhós', value: 'Mokhós' },
  { label: 'Mokhotlong', value: 'Mokhotlong' },
  { label: 'Mokhsogollokh', value: 'Mokhsogollokh' },
  { label: 'Mokil Municipality', value: 'Mokil Municipality' },
  { label: 'Mokobody', value: 'Mokobody' },
  { label: 'Mokokchung', value: 'Mokokchung' },
  { label: 'Mokolo', value: 'Mokolo' },
  { label: 'Mokopane', value: 'Mokopane' },
  { label: 'Mokošica', value: 'Mokošica' },
  { label: 'Mokotów', value: 'Mokotów' },
  { label: 'Mokpo', value: 'Mokpo' },
  { label: 'Mokpo-Si', value: 'Mokpo-Si' },
  { label: 'Mokrá Hora', value: 'Mokrá Hora' },
  { label: 'Mokré Lazce', value: 'Mokré Lazce' },
  { label: 'Mokrin', value: 'Mokrin' },
  { label: 'Mokronog', value: 'Mokronog' },
  { label: 'Mokrous', value: 'Mokrous' },
  { label: 'Mokrousovo', value: 'Mokrousovo' },
  { label: 'Mokrousovskiy Rayon', value: 'Mokrousovskiy Rayon' },
  { label: 'Mokrsko', value: 'Mokrsko' },
  { label: 'Mokshan', value: 'Mokshan' },
  { label: 'Mokulēia', value: 'Mokulēia' },
  { label: 'Mol', value: 'Mol' },
  { label: 'Mol', value: 'Mol' },
  { label: 'Mola Di Bari', value: 'Mola Di Bari' },
  { label: 'Molac', value: 'Molac' },
  { label: 'Molacillos', value: 'Molacillos' },
  { label: 'Molagavita', value: 'Molagavita' },
  { label: 'Molalla', value: 'Molalla' },
  { label: 'Molango', value: 'Molango' },
  { label: 'Moláoi', value: 'Moláoi' },
  { label: 'Molar', value: 'Molar' },
  { label: 'Molar El', value: 'Molar El' },
  { label: 'Molare', value: 'Molare' },
  { label: 'Molares Los', value: 'Molares Los' },
  { label: 'Molas', value: 'Molas' },
  { label: 'Molave', value: 'Molave' },
  { label: 'Molazzana', value: 'Molazzana' },
  { label: 'Molbergen', value: 'Molbergen' },
  { label: 'Mölbling', value: 'Mölbling' },
  { label: 'Molchanovo', value: 'Molchanovo' },
  { label: 'Molchanovskiy Rayon', value: 'Molchanovskiy Rayon' },
  { label: 'Mold', value: 'Mold' },
  { label: 'Moldava Nad Bodvou', value: 'Moldava Nad Bodvou' },
  { label: 'Molde', value: 'Molde' },
  { label: 'Moldova Nouă', value: 'Moldova Nouă' },
  { label: 'Moldova Suliţa', value: 'Moldova Suliţa' },
  { label: 'Moldoveneşti', value: 'Moldoveneşti' },
  { label: 'Moldoveni', value: 'Moldoveni' },
  { label: 'Moldoveni', value: 'Moldoveni' },
  { label: 'Moldoveni', value: 'Moldoveni' },
  { label: 'Moldoviţa', value: 'Moldoviţa' },
  { label: 'Môle Saint-Nicolas', value: 'Môle Saint-Nicolas' },
  { label: 'Moledo', value: 'Moledo' },
  { label: 'Molella', value: 'Molella' },
  { label: 'Molendinar', value: 'Molendinar' },
  { label: 'Molenhoek', value: 'Molenhoek' },
  { label: 'Molepolole', value: 'Molepolole' },
  { label: 'Moletai', value: 'Moletai' },
  { label: 'Molezuelas De La Carballeda', value: 'Molezuelas De La Carballeda' },
  { label: 'Molfetta', value: 'Molfetta' },
  { label: 'Molfsee', value: 'Molfsee' },
  { label: 'Molham Darreh', value: 'Molham Darreh' },
  { label: 'Molières', value: 'Molières' },
  { label: 'Molières-Sur-Cèze', value: 'Molières-Sur-Cèze' },
  { label: 'Molina', value: 'Molina' },
  { label: 'Molina Aterno', value: 'Molina Aterno' },
  { label: 'Molina De Aragón', value: 'Molina De Aragón' },
  { label: 'Molina De Segura', value: 'Molina De Segura' },
  { label: 'Molina Di Ledro', value: 'Molina Di Ledro' },
  { label: 'Molinara', value: 'Molinara' },
  { label: 'Molinaseca', value: 'Molinaseca' },
  { label: 'Moline', value: 'Moline' },
  { label: 'Moline Acres', value: 'Moline Acres' },
  { label: 'Molinella', value: 'Molinella' },
  { label: 'Molinet', value: 'Molinet' },
  { label: 'Molinetto', value: 'Molinetto' },
  { label: 'Molini', value: 'Molini' },
  { label: 'Molini Di Triora', value: 'Molini Di Triora' },
  { label: 'Molinicos', value: 'Molinicos' },
  { label: 'Molinillo', value: 'Molinillo' },
  { label: 'Molino', value: 'Molino' },
  { label: 'Molino Abajo', value: 'Molino Abajo' },
  { label: 'Molino De Camou', value: 'Molino De Camou' },
  { label: 'Molino De San José', value: 'Molino De San José' },
  { label: 'Molino De Santa Ana', value: 'Molino De Santa Ana' },
  { label: 'Molino Dei Torti', value: 'Molino Dei Torti' },
  { label: 'Molino Del Pallone', value: 'Molino Del Pallone' },
  { label: 'Molino Del Piano', value: 'Molino Del Piano' },
  { label: 'Molino Vecchio-Scapitola-Baio', value: 'Molino Vecchio-Scapitola-Baio' },
  { label: 'Molino-Mozzi-Bittarelli', value: 'Molino-Mozzi-Bittarelli' },
  { label: 'Molino-Pera', value: 'Molino-Pera' },
  { label: 'Molinos', value: 'Molinos' },
  { label: 'Molinos', value: 'Molinos' },
  { label: 'Molinos De Duero', value: 'Molinos De Duero' },
  { label: 'Molinos Los', value: 'Molinos Los' },
  { label: 'Molins De Rei', value: 'Molins De Rei' },
  { label: 'Molise', value: 'Molise' },
  { label: 'Moliterno', value: 'Moliterno' },
  { label: 'Molkabad', value: 'Molkabad' },
  { label: 'Molkom', value: 'Molkom' },
  { label: 'Molla Bodagh', value: 'Molla Bodagh' },
  { label: 'Mollasani', value: 'Mollasani' },
  { label: 'Möllbrücke', value: 'Möllbrücke' },
  { label: 'Molledo', value: 'Molledo' },
  { label: 'Mollégès', value: 'Mollégès' },
  { label: 'Mollendo', value: 'Mollendo' },
  { label: 'Möllenhagen', value: 'Möllenhagen' },
  { label: 'Mollens', value: 'Mollens' },
  { label: 'Möllersdorf', value: 'Möllersdorf' },
  { label: 'Mollerussa', value: 'Mollerussa' },
  { label: 'Mollet De Peralada', value: 'Mollet De Peralada' },
  { label: 'Mollet Del Vallès', value: 'Mollet Del Vallès' },
  { label: 'Mollia', value: 'Mollia' },
  { label: 'Mollina', value: 'Mollina' },
  { label: 'Mollington', value: 'Mollington' },
  { label: 'Mollis', value: 'Mollis' },
  { label: 'Molln', value: 'Molln' },
  { label: 'Mölln', value: 'Mölln' },
  { label: 'Molló', value: 'Molló' },
  { label: 'Mölltorp', value: 'Mölltorp' },
  { label: 'Mollymook', value: 'Mollymook' },
  { label: 'Mollymook Beach', value: 'Mollymook Beach' },
  { label: 'Mölnbo', value: 'Mölnbo' },
  { label: 'Mölndal', value: 'Mölndal' },
  { label: 'Mölnlycke', value: 'Mölnlycke' },
  { label: 'Molo', value: 'Molo' },
  { label: 'Moloacán', value: 'Moloacán' },
  { label: 'Molochio', value: 'Molochio' },
  { label: 'Molochnoye', value: 'Molochnoye' },
  { label: 'Molochnoye', value: 'Molochnoye' },
  { label: 'Molochnyy', value: 'Molochnyy' },
  { label: 'Molodezhnoye', value: 'Molodezhnoye' },
  { label: 'Molodiya', value: 'Molodiya' },
  { label: 'Molodizhne', value: 'Molodizhne' },
  { label: 'Molodohvardiys’K', value: 'Molodohvardiys’K' },
  { label: 'Molodyozhnoye', value: 'Molodyozhnoye' },
  { label: 'Molokovo', value: 'Molokovo' },
  { label: 'Molokovo', value: 'Molokovo' },
  { label: 'Molong', value: 'Molong' },
  { label: 'Mólos', value: 'Mólos' },
  { label: 'Molschleben', value: 'Molschleben' },
  { label: 'Molsheim', value: 'Molsheim' },
  { label: 'Molsosa La', value: 'Molsosa La' },
  { label: 'Molteno', value: 'Molteno' },
  { label: 'Molteno', value: 'Molteno' },
  { label: 'Moltrasio', value: 'Moltrasio' },
  { label: 'Molugan', value: 'Molugan' },
  { label: 'Molundo', value: 'Molundo' },
  { label: 'Molve', value: 'Molve' },
  { label: 'Molveno', value: 'Molveno' },
  { label: 'Molvízar', value: 'Molvízar' },
  { label: 'Molynes Four Roads', value: 'Molynes Four Roads' },
  { label: 'Molynes Gardens', value: 'Molynes Gardens' },
  { label: 'Momax', value: 'Momax' },
  { label: 'Mombaça', value: 'Mombaça' },
  { label: 'Mombaldone', value: 'Mombaldone' },
  { label: 'Mombarcaro', value: 'Mombarcaro' },
  { label: 'Mombaroccio', value: 'Mombaroccio' },
  { label: 'Mombaruzzo', value: 'Mombaruzzo' },
  { label: 'Mombasa', value: 'Mombasa' },
  { label: 'Mombasiglio', value: 'Mombasiglio' },
  { label: 'Mombelli', value: 'Mombelli' },
  { label: 'Mombello Di Torino', value: 'Mombello Di Torino' },
  { label: 'Mombello Monferrato', value: 'Mombello Monferrato' },
  { label: 'Mombeltrán', value: 'Mombeltrán' },
  { label: 'Mombercelli', value: 'Mombercelli' },
  { label: 'Mombetsu', value: 'Mombetsu' },
  { label: 'Momblona', value: 'Momblona' },
  { label: 'Mombretto', value: 'Mombretto' },
  { label: 'Mömbris', value: 'Mömbris' },
  { label: 'Mombuca', value: 'Mombuca' },
  { label: 'Mombuey', value: 'Mombuey' },
  { label: 'Momen Abad', value: 'Momen Abad' },
  { label: 'Momence', value: 'Momence' },
  { label: 'Momignies', value: 'Momignies' },
  { label: 'Momil', value: 'Momil' },
  { label: 'Mömlingen', value: 'Mömlingen' },
  { label: 'Mommenheim', value: 'Mommenheim' },
  { label: 'Mommenheim', value: 'Mommenheim' },
  { label: 'Momo', value: 'Momo' },
  { label: 'Momostenango', value: 'Momostenango' },
  { label: 'Mompach', value: 'Mompach' },
  { label: 'Mompaní', value: 'Mompaní' },
  { label: 'Mompantero', value: 'Mompantero' },
  { label: 'Mompeo', value: 'Mompeo' },
  { label: 'Momperone', value: 'Momperone' },
  { label: 'Mompós', value: 'Mompós' },
  { label: 'Momsky District', value: 'Momsky District' },
  { label: 'Mon', value: 'Mon' },
  { label: 'Mon Repos', value: 'Mon Repos' },
  { label: 'Mon Repos', value: 'Mon Repos' },
  { label: 'Mona', value: 'Mona' },
  { label: 'Mona Heights', value: 'Mona Heights' },
  { label: 'Mona Vale', value: 'Mona Vale' },
  { label: 'Monabbih', value: 'Monabbih' },
  { label: 'Monaca', value: 'Monaca' },
  { label: 'Monachil', value: 'Monachil' },
  { label: 'Monacilioni', value: 'Monacilioni' },
  { label: 'Monaghan', value: 'Monaghan' },
  { label: 'Monagrillo', value: 'Monagrillo' },
  { label: 'Monahans', value: 'Monahans' },
  { label: 'Monale', value: 'Monale' },
  { label: 'Monamon', value: 'Monamon' },
  { label: 'Monamon', value: 'Monamon' },
  { label: 'Monaragala', value: 'Monaragala' },
  { label: 'Monarch Mill', value: 'Monarch Mill' },
  { label: 'Monash', value: 'Monash' },
  { label: 'Monash', value: 'Monash' },
  { label: 'Monash', value: 'Monash' },
  { label: 'Monasterace', value: 'Monasterace' },
  { label: 'Monasterace Marina', value: 'Monasterace Marina' },
  { label: 'Monasterevin', value: 'Monasterevin' },
  { label: 'Monasterio', value: 'Monasterio' },
  { label: 'Monasterio De La Sierra', value: 'Monasterio De La Sierra' },
  { label: 'Monasterio De Rodilla', value: 'Monasterio De Rodilla' },
  { label: 'Monasterio De Vega', value: 'Monasterio De Vega' },
  { label: 'Monastero Bormida', value: 'Monastero Bormida' },
  { label: 'Monastero Di Lanzo', value: 'Monastero Di Lanzo' },
  { label: 'Monastero Di Vasco', value: 'Monastero Di Vasco' },
  { label: 'Monasterolo', value: 'Monasterolo' },
  { label: 'Monasterolo', value: 'Monasterolo' },
  { label: 'Monasterolo Casotto', value: 'Monasterolo Casotto' },
  { label: 'Monasterolo Del Castello', value: 'Monasterolo Del Castello' },
  { label: 'Monasterolo Di Savigliano', value: 'Monasterolo Di Savigliano' },
  { label: 'Monastier Di Treviso', value: 'Monastier Di Treviso' },
  { label: 'Monastir', value: 'Monastir' },
  { label: 'Monastir', value: 'Monastir' },
  { label: 'Monastiráki', value: 'Monastiráki' },
  { label: 'Monastyrishche', value: 'Monastyrishche' },
  { label: 'Monastyrshchina', value: 'Monastyrshchina' },
  { label: 'Monastyryshche', value: 'Monastyryshche' },
  { label: 'Monavvar Tappeh', value: 'Monavvar Tappeh' },
  { label: 'Monbazillac', value: 'Monbazillac' },
  { label: 'Monbetsu Shi', value: 'Monbetsu Shi' },
  { label: 'Monbon', value: 'Monbon' },
  { label: 'Monbulk', value: 'Monbulk' },
  { label: 'Moncada', value: 'Moncada' },
  { label: 'Moncada', value: 'Moncada' },
  { label: 'Moncada', value: 'Moncada' },
  { label: 'Moncagua', value: 'Moncagua' },
  { label: 'Moncalieri', value: 'Moncalieri' },
  { label: 'Moncalvillo', value: 'Moncalvillo' },
  { label: 'Moncalvo', value: 'Moncalvo' },
  { label: 'Monção', value: 'Monção' },
  { label: 'Monção', value: 'Monção' },
  { label: 'Moncarapacho', value: 'Moncarapacho' },
  { label: 'Moncé-En-Belin', value: 'Moncé-En-Belin' },
  { label: 'Moncenisio', value: 'Moncenisio' },
  { label: 'Moncestino', value: 'Moncestino' },
  { label: 'Mönchaltorf', value: 'Mönchaltorf' },
  { label: 'Mönchaltorf / Dorf', value: 'Mönchaltorf / Dorf' },
  { label: 'Mönchberg', value: 'Mönchberg' },
  { label: 'Monchecourt', value: 'Monchecourt' },
  { label: 'Monchegorsk', value: 'Monchegorsk' },
  { label: 'Mönchengladbach', value: 'Mönchengladbach' },
  { label: 'Mönchenholzhausen', value: 'Mönchenholzhausen' },
  { label: 'Mönchhagen', value: 'Mönchhagen' },
  { label: 'Mönchhof', value: 'Mönchhof' },
  { label: 'Monchiero Borgonuovo', value: 'Monchiero Borgonuovo' },
  { label: 'Monching', value: 'Monching' },
  { label: 'Monchio Delle Corti', value: 'Monchio Delle Corti' },
  { label: 'Monchique', value: 'Monchique' },
  { label: 'Mönchsdeggingen', value: 'Mönchsdeggingen' },
  { label: 'Mönchsroth', value: 'Mönchsroth' },
  { label: 'Mönchweiler', value: 'Mönchweiler' },
  { label: 'Monchy', value: 'Monchy' },
  { label: 'Monchy/Careffe', value: 'Monchy/Careffe' },
  { label: 'Monchy/Cletus Village', value: 'Monchy/Cletus Village' },
  { label: 'Monchy/La Borne', value: 'Monchy/La Borne' },
  { label: 'Monchy/La Borne/Sans Souci', value: 'Monchy/La Borne/Sans Souci' },
  { label: 'Monchy/La Retraite', value: 'Monchy/La Retraite' },
  { label: 'Monchy/Lafeuillee', value: 'Monchy/Lafeuillee' },
  { label: 'Monchy/Lawi Fwen', value: 'Monchy/Lawi Fwen' },
  { label: 'Monchy/Malgretoute', value: 'Monchy/Malgretoute' },
  { label: 'Monchy/Moulin A Vent', value: 'Monchy/Moulin A Vent' },
  { label: 'Monchy/Ravine Macock', value: 'Monchy/Ravine Macock' },
  { label: 'Monchy/Riviere Mitan', value: 'Monchy/Riviere Mitan' },
  { label: 'Monchy/Ti Dauphin', value: 'Monchy/Ti Dauphin' },
  { label: 'Monchy/Vieux Sucreic', value: 'Monchy/Vieux Sucreic' },
  { label: 'Monchy/Vieux Sucreic/Bois Dinde', value: 'Monchy/Vieux Sucreic/Bois Dinde' },
  { label: 'Monchy/Vieux Sucreic/Careffe', value: 'Monchy/Vieux Sucreic/Careffe' },
  { label: 'Monchy-Saint-Éloi', value: 'Monchy-Saint-Éloi' },
  { label: 'Monción', value: 'Monción' },
  { label: 'Moncks Corner', value: 'Moncks Corner' },
  { label: 'Monclar-De-Quercy', value: 'Monclar-De-Quercy' },
  { label: 'Monclassico', value: 'Monclassico' },
  { label: 'Moncloa-Aravaca', value: 'Moncloa-Aravaca' },
  { label: 'Monclova', value: 'Monclova' },
  { label: 'Monclova Primer Sector', value: 'Monclova Primer Sector' },
  { label: 'Monclova Segundo Sector', value: 'Monclova Segundo Sector' },
  { label: 'Monções', value: 'Monções' },
  { label: 'Moncofa', value: 'Moncofa' },
  { label: 'Moncontour', value: 'Moncontour' },
  { label: 'Moncoutant', value: 'Moncoutant' },
  { label: 'Moncrivello', value: 'Moncrivello' },
  { label: 'Moncton', value: 'Moncton' },
  { label: 'Moncucco', value: 'Moncucco' },
  { label: 'Moncucco Torinese', value: 'Moncucco Torinese' },
  { label: 'Monda', value: 'Monda' },
  { label: 'Mondaí', value: 'Mondaí' },
  { label: 'Mondaino', value: 'Mondaino' },
  { label: 'Mondariz', value: 'Mondariz' },
  { label: 'Mondariz-Balneario', value: 'Mondariz-Balneario' },
  { label: 'Mondavio', value: 'Mondavio' },
  { label: 'Mondéjar', value: 'Mondéjar' },
  { label: 'Mondelange', value: 'Mondelange' },
  { label: 'Mondercange', value: 'Mondercange' },
  { label: 'Mondeville', value: 'Mondeville' },
  { label: 'Mondim De Basto', value: 'Mondim De Basto' },
  { label: 'Mondlo', value: 'Mondlo' },
  { label: 'Mondolfo', value: 'Mondolfo' },
  { label: 'Mondoñedo', value: 'Mondoñedo' },
  { label: 'Mondonville', value: 'Mondonville' },
  { label: 'Mondorf-Les-Bains', value: 'Mondorf-Les-Bains' },
  { label: 'Mondoubleau', value: 'Mondoubleau' },
  { label: 'Mondovi', value: 'Mondovi' },
  { label: 'Mondovì', value: 'Mondovì' },
  { label: 'Mondragon', value: 'Mondragon' },
  { label: 'Mondragon', value: 'Mondragon' },
  { label: 'Mondragon', value: 'Mondragon' },
  { label: 'Mondragone', value: 'Mondragone' },
  { label: 'Mondsee', value: 'Mondsee' },
  { label: 'Monduli', value: 'Monduli' },
  { label: 'Moneague', value: 'Moneague' },
  { label: 'Moneasa', value: 'Moneasa' },
  { label: 'Monee', value: 'Monee' },
  { label: 'Moneglia', value: 'Moneglia' },
  { label: 'Monegrillo', value: 'Monegrillo' },
  { label: 'Monein', value: 'Monein' },
  { label: 'Moneragala District', value: 'Moneragala District' },
  { label: 'Monesiglio', value: 'Monesiglio' },
  { label: 'Monesma Y Cajigar', value: 'Monesma Y Cajigar' },
  { label: 'Monessen', value: 'Monessen' },
  { label: 'Monesterio', value: 'Monesterio' },
  { label: 'Monestiés', value: 'Monestiés' },
  { label: 'Monéteau', value: 'Monéteau' },
  { label: 'Monetnyy', value: 'Monetnyy' },
  { label: 'Monett', value: 'Monett' },
  { label: 'Monette', value: 'Monette' },
  { label: 'Moneva', value: 'Moneva' },
  { label: 'Moneymore', value: 'Moneymore' },
  { label: 'Monfalcone', value: 'Monfalcone' },
  { label: 'Monfarracinos', value: 'Monfarracinos' },
  { label: 'Monfero', value: 'Monfero' },
  { label: 'Monflanquin', value: 'Monflanquin' },
  { label: 'Monflorite-Lascasas', value: 'Monflorite-Lascasas' },
  { label: 'Monfort Heights', value: 'Monfort Heights' },
  { label: 'Monforte', value: 'Monforte' },
  { label: 'Monforte Dalba', value: 'Monforte Dalba' },
  { label: 'Monforte De La Sierra', value: 'Monforte De La Sierra' },
  { label: 'Monforte De Lemos', value: 'Monforte De Lemos' },
  { label: 'Monforte De Moyuela', value: 'Monforte De Moyuela' },
  { label: 'Monforte Del Cid', value: 'Monforte Del Cid' },
  { label: 'Monforte San Giorgio', value: 'Monforte San Giorgio' },
  { label: 'Monfumo', value: 'Monfumo' },
  { label: 'Móng Cái', value: 'Móng Cái' },
  { label: 'Mong Kok', value: 'Mong Kok' },
  { label: 'Mongaguá', value: 'Mongaguá' },
  { label: 'Mongar', value: 'Mongar' },
  { label: 'Mongardino', value: 'Mongardino' },
  { label: 'Monghidoro', value: 'Monghidoro' },
  { label: 'Monghyr', value: 'Monghyr' },
  { label: 'Mongiana', value: 'Mongiana' },
  { label: 'Mongiardino Ligure', value: 'Mongiardino Ligure' },
  { label: 'Mongiraud', value: 'Mongiraud' },
  { label: 'Mongiuffi Melia', value: 'Mongiuffi Melia' },
  { label: 'Mongkol Borei', value: 'Mongkol Borei' },
  { label: 'Mongnod', value: 'Mongnod' },
  { label: 'Mongo', value: 'Mongo' },
  { label: 'Mongomo', value: 'Mongomo' },
  { label: 'Möngönmorĭt', value: 'Möngönmorĭt' },
  { label: 'Mongouge', value: 'Mongouge' },
  { label: 'Mongoumba', value: 'Mongoumba' },
  { label: 'Mongrando', value: 'Mongrando' },
  { label: 'Mongrassano', value: 'Mongrassano' },
  { label: 'Mongu', value: 'Mongu' },
  { label: 'Mongua', value: 'Mongua' },
  { label: 'Monguelfo', value: 'Monguelfo' },
  { label: 'Monguí', value: 'Monguí' },
  { label: 'Monguno', value: 'Monguno' },
  { label: 'Monheim', value: 'Monheim' },
  { label: 'Monheim Am Rhein', value: 'Monheim Am Rhein' },
  { label: 'Mönichkirchen', value: 'Mönichkirchen' },
  { label: 'Mönichwald', value: 'Mönichwald' },
  { label: 'Moniego', value: 'Moniego' },
  { label: 'Monier', value: 'Monier' },
  { label: 'Monifieth', value: 'Monifieth' },
  { label: 'Moniga Del Garda', value: 'Moniga Del Garda' },
  { label: 'Monino', value: 'Monino' },
  { label: 'Moniquirá', value: 'Moniquirá' },
  { label: 'Monistrol De Calders', value: 'Monistrol De Calders' },
  { label: 'Monistrol De Montserrat', value: 'Monistrol De Montserrat' },
  { label: 'Monistrol-Sur-Loire', value: 'Monistrol-Sur-Loire' },
  { label: 'Moniteau County', value: 'Moniteau County' },
  { label: 'Moñitos', value: 'Moñitos' },
  { label: 'Monjarás', value: 'Monjarás' },
  { label: 'Monjarás', value: 'Monjarás' },
  { label: 'Monjas', value: 'Monjas' },
  { label: 'Monjolos', value: 'Monjolos' },
  { label: 'Monk Fryston', value: 'Monk Fryston' },
  { label: 'Monkayo', value: 'Monkayo' },
  { label: 'Monkayo', value: 'Monkayo' },
  { label: 'Monkayo', value: 'Monkayo' },
  { label: 'Mönkeberg', value: 'Mönkeberg' },
  { label: 'Monkey Bay', value: 'Monkey Bay' },
  { label: 'Monkey Hill', value: 'Monkey Hill' },
  { label: 'Monkey Town Ciceron', value: 'Monkey Town Ciceron' },
  { label: 'Mońki', value: 'Mońki' },
  { label: 'Monkland', value: 'Monkland' },
  { label: 'Monkstown', value: 'Monkstown' },
  { label: 'Monleale', value: 'Monleale' },
  { label: 'Monleón', value: 'Monleón' },
  { label: 'Monleras', value: 'Monleras' },
  { label: 'Monmouth', value: 'Monmouth' },
  { label: 'Monmouth', value: 'Monmouth' },
  { label: 'Monmouth', value: 'Monmouth' },
  { label: 'Monmouth', value: 'Monmouth' },
  { label: 'Monmouth Beach', value: 'Monmouth Beach' },
  { label: 'Monmouth County', value: 'Monmouth County' },
  { label: 'Monmouth Junction', value: 'Monmouth Junction' },
  { label: 'Monmouthshire', value: 'Monmouthshire' },
  { label: 'Monnaie', value: 'Monnaie' },
  { label: 'Monnetier-Mornex', value: 'Monnetier-Mornex' },
  { label: 'Monnickendam', value: 'Monnickendam' },
  { label: 'Monnières', value: 'Monnières' },
  { label: 'Monno', value: 'Monno' },
  { label: 'Mono County', value: 'Mono County' },
  { label: 'Mono Vista', value: 'Mono Vista' },
  { label: 'Monoharpur', value: 'Monoharpur' },
  { label: 'Monok', value: 'Monok' },
  { label: 'Monon', value: 'Monon' },
  { label: 'Monona', value: 'Monona' },
  { label: 'Monona', value: 'Monona' },
  { label: 'Monona County', value: 'Monona County' },
  { label: 'Monongah', value: 'Monongah' },
  { label: 'Monongahela', value: 'Monongahela' },
  { label: 'Monongalia County', value: 'Monongalia County' },
  { label: 'Monopoli', value: 'Monopoli' },
  { label: 'Monor', value: 'Monor' },
  { label: 'Monor', value: 'Monor' },
  { label: 'Monori Járás', value: 'Monori Járás' },
  { label: 'Monostorpályi', value: 'Monostorpályi' },
  { label: 'Monóvar', value: 'Monóvar' },
  { label: 'Monóvar/Monòver', value: 'Monóvar/Monòver' },
  { label: 'Monpon', value: 'Monpon' },
  { label: 'Monreal', value: 'Monreal' },
  { label: 'Monreal', value: 'Monreal' },
  { label: 'Monreal De Ariza', value: 'Monreal De Ariza' },
  { label: 'Monreal Del Campo', value: 'Monreal Del Campo' },
  { label: 'Monreal Del Llano', value: 'Monreal Del Llano' },
  { label: 'Monreal/Elo', value: 'Monreal/Elo' },
  { label: 'Monreale', value: 'Monreale' },
  { label: 'Monroe', value: 'Monroe' },
  { label: 'Monroe', value: 'Monroe' },
  { label: 'Monroe', value: 'Monroe' },
  { label: 'Monroe', value: 'Monroe' },
  { label: 'Monroe', value: 'Monroe' },
  { label: 'Monroe', value: 'Monroe' },
  { label: 'Monroe', value: 'Monroe' },
  { label: 'Monroe', value: 'Monroe' },
  { label: 'Monroe', value: 'Monroe' },
  { label: 'Monroe', value: 'Monroe' },
  { label: 'Monroe City', value: 'Monroe City' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe County', value: 'Monroe County' },
  { label: 'Monroe North', value: 'Monroe North' },
  { label: 'Monroeville', value: 'Monroeville' },
  { label: 'Monroeville', value: 'Monroeville' },
  { label: 'Monroeville', value: 'Monroeville' },
  { label: 'Monroeville', value: 'Monroeville' },
  { label: 'Monrovia', value: 'Monrovia' },
  { label: 'Monrovia', value: 'Monrovia' },
  { label: 'Monrovia', value: 'Monrovia' },
  { label: 'Monroy', value: 'Monroy' },
  { label: 'Monroyo', value: 'Monroyo' },
  { label: 'Mons', value: 'Mons' },
  { label: 'Mons', value: 'Mons' },
  { label: 'Monsagro', value: 'Monsagro' },
  { label: 'Monsalupe', value: 'Monsalupe' },
  { label: 'Monsampietro Morico', value: 'Monsampietro Morico' },
  { label: 'Monsampolo Del Tronto', value: 'Monsampolo Del Tronto' },
  { label: 'Monsano', value: 'Monsano' },
  { label: 'Monsanto', value: 'Monsanto' },
  { label: 'Monschau', value: 'Monschau' },
  { label: 'Monsefú', value: 'Monsefú' },
  { label: 'Monségur', value: 'Monségur' },
  { label: 'Monselice', value: 'Monselice' },
  { label: 'Monsempron-Libos', value: 'Monsempron-Libos' },
  { label: 'Mons-En-Barœul', value: 'Mons-En-Barœul' },
  { label: 'Monsenhor Gil', value: 'Monsenhor Gil' },
  { label: 'Monsenhor Hipólito', value: 'Monsenhor Hipólito' },
  { label: 'Monsenhor Paulo', value: 'Monsenhor Paulo' },
  { label: 'Monsenhor Tabosa', value: 'Monsenhor Tabosa' },
  { label: 'Mons-En-Pévèle', value: 'Mons-En-Pévèle' },
  { label: 'Monserrat', value: 'Monserrat' },
  { label: 'Monserrate', value: 'Monserrate' },
  { label: 'Monserrato', value: 'Monserrato' },
  { label: 'Monsey', value: 'Monsey' },
  { label: 'Monsheim', value: 'Monsheim' },
  { label: 'Mönsheim', value: 'Mönsheim' },
  { label: 'Mons-Lez-Liège', value: 'Mons-Lez-Liège' },
  { label: 'Monson', value: 'Monson' },
  { label: 'Monson Center', value: 'Monson Center' },
  { label: 'Monster', value: 'Monster' },
  { label: 'Mönsterås', value: 'Mönsterås' },
  { label: 'Monsummano Terme', value: 'Monsummano Terme' },
  { label: 'Monswiller', value: 'Monswiller' },
  { label: 'Mont', value: 'Mont' },
  { label: 'Mont Albert', value: 'Mont Albert' },
  { label: 'Mont Albert North', value: 'Mont Albert North' },
  { label: 'Mont Alto', value: 'Mont Alto' },
  { label: 'Mont Belvieu', value: 'Mont Belvieu' },
  { label: 'Mont Vernon', value: 'Mont Vernon' },
  { label: 'Montà', value: 'Montà' },
  { label: 'Montabaur', value: 'Montabaur' },
  { label: 'Montaberner', value: 'Montaberner' },
  { label: 'Montabone', value: 'Montabone' },
  { label: 'Montacuto', value: 'Montacuto' },
  { label: 'Montadas', value: 'Montadas' },
  { label: 'Montady', value: 'Montady' },
  { label: 'Montafia', value: 'Montafia' },
  { label: 'Montagano', value: 'Montagano' },
  { label: 'Montagna', value: 'Montagna' },
  { label: 'Montagna In Valtellina', value: 'Montagna In Valtellina' },
  { label: 'Montagnac', value: 'Montagnac' },
  { label: 'Montagnana', value: 'Montagnana' },
  { label: 'Montagnareale', value: 'Montagnareale' },
  { label: 'Montagnat', value: 'Montagnat' },
  { label: 'Montagne', value: 'Montagne' },
  { label: 'Montagne', value: 'Montagne' },
  { label: 'Montagne Blanche', value: 'Montagne Blanche' },
  { label: 'Montagnola', value: 'Montagnola' },
  { label: 'Montagny', value: 'Montagny' },
  { label: 'Montagny', value: 'Montagny' },
  { label: 'Montagu', value: 'Montagu' },
  { label: 'Montague', value: 'Montague' },
  { label: 'Montague', value: 'Montague' },
  { label: 'Montague', value: 'Montague' },
  { label: 'Montague', value: 'Montague' },
  { label: 'Montague', value: 'Montague' },
  { label: 'Montague County', value: 'Montague County' },
  { label: 'Montagut I Oix', value: 'Montagut I Oix' },
  { label: 'Montaguto', value: 'Montaguto' },
  { label: 'Montaigu', value: 'Montaigu' },
  { label: 'Montaigu-De-Quercy', value: 'Montaigu-De-Quercy' },
  { label: 'Montaigut', value: 'Montaigut' },
  { label: 'Montaigut-Sur-Save', value: 'Montaigut-Sur-Save' },
  { label: 'Montaione', value: 'Montaione' },
  { label: 'Montalbán', value: 'Montalbán' },
  { label: 'Montalbán De Córdoba', value: 'Montalbán De Córdoba' },
  { label: 'Montalbanejo', value: 'Montalbanejo' },
  { label: 'Montalbano', value: 'Montalbano' },
  { label: 'Montalbano', value: 'Montalbano' },
  { label: 'Montalbano Elicona', value: 'Montalbano Elicona' },
  { label: 'Montalbano Jonico', value: 'Montalbano Jonico' },
  { label: 'Montalbo', value: 'Montalbo' },
  { label: 'Montalcino', value: 'Montalcino' },
  { label: 'Montaldeo', value: 'Montaldeo' },
  { label: 'Montaldo Bormida', value: 'Montaldo Bormida' },
  { label: 'Montaldo Di Mondovì', value: 'Montaldo Di Mondovì' },
  { label: 'Montaldo Roero', value: 'Montaldo Roero' },
  { label: 'Montaldo Scarampi', value: 'Montaldo Scarampi' },
  { label: 'Montaldo Torinese', value: 'Montaldo Torinese' },
  { label: 'Montale', value: 'Montale' },
  { label: 'Montale', value: 'Montale' },
  { label: 'Montalegre', value: 'Montalegre' },
  { label: 'Montalenghe', value: 'Montalenghe' },
  { label: 'Montalieu', value: 'Montalieu' },
  { label: 'Montallegro', value: 'Montallegro' },
  { label: 'Montalto', value: 'Montalto' },
  { label: 'Montalto Delle Marche', value: 'Montalto Delle Marche' },
  { label: 'Montalto Di Castro', value: 'Montalto Di Castro' },
  { label: 'Montalto Dora', value: 'Montalto Dora' },
  { label: 'Montalto Ligure', value: 'Montalto Ligure' },
  { label: 'Montalto Pavese', value: 'Montalto Pavese' },
  { label: 'Montalto Uffugo', value: 'Montalto Uffugo' },
  { label: 'Montalvânia', value: 'Montalvânia' },
  { label: 'Montalvin', value: 'Montalvin' },
  { label: 'Montalvinho', value: 'Montalvinho' },
  { label: 'Montalvo', value: 'Montalvo' },
  { label: 'Montalvo', value: 'Montalvo' },
  { label: 'Montalvos', value: 'Montalvos' },
  { label: 'Montamarta', value: 'Montamarta' },
  { label: 'Montamisé', value: 'Montamisé' },
  { label: 'Montán', value: 'Montán' },
  { label: 'Montana', value: 'Montana' },
  { label: 'Montana', value: 'Montana' },
  { label: 'Montana City', value: 'Montana City' },
  { label: 'Montaña Monarca (Punta Altozano)', value: 'Montaña Monarca (Punta Altozano)' },
  { label: 'Montan-Angelin-Arensod', value: 'Montan-Angelin-Arensod' },
  { label: 'Montanara', value: 'Montanara' },
  { label: 'Montanaro', value: 'Montanaro' },
  { label: 'Montanaso Lombardo', value: 'Montanaso Lombardo' },
  { label: 'Montanay', value: 'Montanay' },
  { label: 'Montánchez', value: 'Montánchez' },
  { label: 'Montanejos', value: 'Montanejos' },
  { label: 'Montanera', value: 'Montanera' },
  { label: 'Montaneza', value: 'Montaneza' },
  { label: 'Montanha', value: 'Montanha' },
  { label: 'Montanhas', value: 'Montanhas' },
  { label: 'Montano', value: 'Montano' },
  { label: 'Montano Antilia', value: 'Montano Antilia' },
  { label: 'Montano Lucino', value: 'Montano Lucino' },
  { label: 'Montans', value: 'Montans' },
  { label: 'Montanuy', value: 'Montanuy' },
  { label: 'Montappone', value: 'Montappone' },
  { label: 'Montaquila', value: 'Montaquila' },
  { label: 'Montara', value: 'Montara' },
  { label: 'Montardon', value: 'Montardon' },
  { label: 'Montaren-Et-Saint-Médiers', value: 'Montaren-Et-Saint-Médiers' },
  { label: 'Montargil', value: 'Montargil' },
  { label: 'Montargis', value: 'Montargis' },
  { label: 'Montariolo', value: 'Montariolo' },
  { label: 'Montarnaud', value: 'Montarnaud' },
  { label: 'Montarrón', value: 'Montarrón' },
  { label: 'Montasola', value: 'Montasola' },
  { label: 'Montastruc-La-Conseillère', value: 'Montastruc-La-Conseillère' },
  { label: 'Montataire', value: 'Montataire' },
  { label: 'Montauban', value: 'Montauban' },
  { label: 'Montauban-De-Bretagne', value: 'Montauban-De-Bretagne' },
  { label: 'Montauk', value: 'Montauk' },
  { label: 'Montaure', value: 'Montaure' },
  { label: 'Montauri', value: 'Montauri' },
  { label: 'Montauro', value: 'Montauro' },
  { label: 'Montauroux', value: 'Montauroux' },
  { label: 'Montaut', value: 'Montaut' },
  { label: 'Montauville', value: 'Montauville' },
  { label: 'Montaverner', value: 'Montaverner' },
  { label: 'Montayral', value: 'Montayral' },
  { label: 'Montaza', value: 'Montaza' },
  { label: 'Montazzoli', value: 'Montazzoli' },
  { label: 'Montbard', value: 'Montbard' },
  { label: 'Montbazens', value: 'Montbazens' },
  { label: 'Montbazin', value: 'Montbazin' },
  { label: 'Montbazon', value: 'Montbazon' },
  { label: 'Montbéliard', value: 'Montbéliard' },
  { label: 'Mont-Bernanchon', value: 'Mont-Bernanchon' },
  { label: 'Montberon', value: 'Montberon' },
  { label: 'Montbert', value: 'Montbert' },
  { label: 'Montbeton', value: 'Montbeton' },
  { label: 'Montbizot', value: 'Montbizot' },
  { label: 'Montblanc', value: 'Montblanc' },
  { label: 'Montblanc', value: 'Montblanc' },
  { label: 'Montbonnot-Saint-Martin', value: 'Montbonnot-Saint-Martin' },
  { label: 'Montboucher-Sur-Jabron', value: 'Montboucher-Sur-Jabron' },
  { label: 'Montbrió Del Camp', value: 'Montbrió Del Camp' },
  { label: 'Montbrison', value: 'Montbrison' },
  { label: 'Montbron', value: 'Montbron' },
  { label: 'Montbronn', value: 'Montbronn' },
  { label: 'Montcada I Reixac', value: 'Montcada I Reixac' },
  { label: 'Montcalm County', value: 'Montcalm County' },
  { label: 'Montcaret', value: 'Montcaret' },
  { label: 'Montceau-Les-Mines', value: 'Montceau-Les-Mines' },
  { label: 'Montcenis', value: 'Montcenis' },
  { label: 'Montchanin', value: 'Montchanin' },
  { label: 'Montclair', value: 'Montclair' },
  { label: 'Montclair', value: 'Montclair' },
  { label: 'Montclair', value: 'Montclair' },
  { label: 'Montclar', value: 'Montclar' },
  { label: 'Montcornet', value: 'Montcornet' },
  { label: 'Montcourt-Fromonville', value: 'Montcourt-Fromonville' },
  { label: 'Montcresson', value: 'Montcresson' },
  { label: 'Montcuq', value: 'Montcuq' },
  { label: 'Montcy-Notre-Dame', value: 'Montcy-Notre-Dame' },
  { label: 'Mont-De-Lans', value: 'Mont-De-Lans' },
  { label: 'Mont-De-Marsan', value: 'Mont-De-Marsan' },
  { label: 'Montdidier', value: 'Montdidier' },
  { label: 'Mont-Dore', value: 'Mont-Dore' },
  { label: 'Monte', value: 'Monte' },
  { label: 'Monte Abraão', value: 'Monte Abraão' },
  { label: 'Monte Alegre', value: 'Monte Alegre' },
  { label: 'Monte Alegre', value: 'Monte Alegre' },
  { label: 'Monte Alegre', value: 'Monte Alegre' },
  { label: 'Monte Alegre De Goiás', value: 'Monte Alegre De Goiás' },
  { label: 'Monte Alegre De Minas', value: 'Monte Alegre De Minas' },
  { label: 'Monte Alegre De Sergipe', value: 'Monte Alegre De Sergipe' },
  { label: 'Monte Alegre Do Piauí', value: 'Monte Alegre Do Piauí' },
  { label: 'Monte Alegre Do Sul', value: 'Monte Alegre Do Sul' },
  { label: 'Monte Alegre Dos Campos', value: 'Monte Alegre Dos Campos' },
  { label: 'Monte Alto', value: 'Monte Alto' },
  { label: 'Monte Alto', value: 'Monte Alto' },
  { label: 'Monte Aprazível', value: 'Monte Aprazível' },
  { label: 'Monte Argentario', value: 'Monte Argentario' },
  { label: 'Monte Azul', value: 'Monte Azul' },
  { label: 'Monte Azul Paulista', value: 'Monte Azul Paulista' },
  { label: 'Monte Belo', value: 'Monte Belo' },
  { label: 'Monte Belo Do Sul', value: 'Monte Belo Do Sul' },
  { label: 'Monte Blanco', value: 'Monte Blanco' },
  { label: 'Monte Blanco', value: 'Monte Blanco' },
  { label: 'Monte Buey', value: 'Monte Buey' },
  { label: 'Monte Calvario', value: 'Monte Calvario' },
  { label: 'Monte Caminetto', value: 'Monte Caminetto' },
  { label: 'Monte Carasso', value: 'Monte Carasso' },
  { label: 'Monte Carlo', value: 'Monte Carlo' },
  { label: 'Monte Carmelo', value: 'Monte Carmelo' },
  { label: 'Monte Caseros', value: 'Monte Caseros' },
  { label: 'Monte Castello Di Vibio', value: 'Monte Castello Di Vibio' },
  { label: 'Monte Castelo', value: 'Monte Castelo' },
  { label: 'Monte Castelo', value: 'Monte Castelo' },
  { label: 'Monte Cerignone', value: 'Monte Cerignone' },
  { label: 'Monte Colombo', value: 'Monte Colombo' },
  { label: 'Monte Cremasco', value: 'Monte Cremasco' },
  { label: 'Monte Cristi', value: 'Monte Cristi' },
  { label: 'Monte Cristo', value: 'Monte Cristo' },
  { label: 'Monte Das Gameleiras', value: 'Monte Das Gameleiras' },
  { label: 'Monte Del Coecillo', value: 'Monte Del Coecillo' },
  { label: 'Monte Di Malo', value: 'Monte Di Malo' },
  { label: 'Monte Di Procida', value: 'Monte Di Procida' },
  { label: 'Monte Do Carmo', value: 'Monte Do Carmo' },
  { label: 'Monte Escobedo', value: 'Monte Escobedo' },
  { label: 'Monte Estoril', value: 'Monte Estoril' },
  { label: 'Monte Estoril', value: 'Monte Estoril' },
  { label: 'Monte Formoso', value: 'Monte Formoso' },
  { label: 'Monte Giardino', value: 'Monte Giardino' },
  { label: 'Monte Giberto', value: 'Monte Giberto' },
  { label: 'Monte Gordo', value: 'Monte Gordo' },
  { label: 'Monte Grande', value: 'Monte Grande' },
  { label: 'Monte Grande', value: 'Monte Grande' },
  { label: 'Monte Grande', value: 'Monte Grande' },
  { label: 'Monte Grimano', value: 'Monte Grimano' },
  { label: 'Monte Grimano Terme', value: 'Monte Grimano Terme' },
  { label: 'Monte Horebe', value: 'Monte Horebe' },
  { label: 'Monte Kristal', value: 'Monte Kristal' },
  { label: 'Monte Lirio', value: 'Monte Lirio' },
  { label: 'Monte Llano', value: 'Monte Llano' },
  { label: 'Monte Maíz', value: 'Monte Maíz' },
  { label: 'Monte Marenzo', value: 'Monte Marenzo' },
  { label: 'Monte Migliore La Selvotta', value: 'Monte Migliore La Selvotta' },
  { label: 'Monte Mor', value: 'Monte Mor' },
  { label: 'Monte Negro', value: 'Monte Negro' },
  { label: 'Monte Obscuro', value: 'Monte Obscuro' },
  { label: 'Monte Patria', value: 'Monte Patria' },
  { label: 'Monte Plata', value: 'Monte Plata' },
  { label: 'Monte Porzio', value: 'Monte Porzio' },
  { label: 'Monte Porzio Catone', value: 'Monte Porzio Catone' },
  { label: 'Monte Real', value: 'Monte Real' },
  { label: 'Monte Redondo', value: 'Monte Redondo' },
  { label: 'Monte Redondo', value: 'Monte Redondo' },
  { label: 'Monte Redondo', value: 'Monte Redondo' },
  { label: 'Monte Rinaldo', value: 'Monte Rinaldo' },
  { label: 'Monte Rio', value: 'Monte Rio' },
  { label: 'Monte Roberto', value: 'Monte Roberto' },
  { label: 'Monte Romano', value: 'Monte Romano' },
  { label: 'Monte Salas', value: 'Monte Salas' },
  { label: 'Monte San Biagio', value: 'Monte San Biagio' },
  { label: 'Monte San Giacomo', value: 'Monte San Giacomo' },
  { label: 'Monte San Giovanni', value: 'Monte San Giovanni' },
  { label: 'Monte San Giovanni Campano', value: 'Monte San Giovanni Campano' },
  { label: 'Monte San Giovanni In Sabina', value: 'Monte San Giovanni In Sabina' },
  { label: 'Monte San Giusto', value: 'Monte San Giusto' },
  { label: 'Monte San Marino', value: 'Monte San Marino' },
  { label: 'Monte San Martino', value: 'Monte San Martino' },
  { label: 'Monte San Pietrangeli', value: 'Monte San Pietrangeli' },
  { label: 'Monte San Savino', value: 'Monte San Savino' },
  { label: 'Monte San Vito', value: 'Monte San Vito' },
  { label: 'Monte Santa Maria Tiberina', value: 'Monte Santa Maria Tiberina' },
  { label: 'Monte Santangelo', value: 'Monte Santangelo' },
  { label: 'Monte Santo', value: 'Monte Santo' },
  { label: 'Monte Santo De Minas', value: 'Monte Santo De Minas' },
  { label: 'Monte Santo Do Tocantins', value: 'Monte Santo Do Tocantins' },
  { label: 'Monte Sereno', value: 'Monte Sereno' },
  { label: 'Monte Sião', value: 'Monte Sião' },
  { label: 'Monte Urano', value: 'Monte Urano' },
  { label: 'Monte Verde', value: 'Monte Verde' },
  { label: 'Monte Verde', value: 'Monte Verde' },
  { label: 'Monte Verde Chivería', value: 'Monte Verde Chivería' },
  { label: 'Monte Vidon Combatte', value: 'Monte Vidon Combatte' },
  { label: 'Monte Vidon Corrado', value: 'Monte Vidon Corrado' },
  { label: 'Monte Vista', value: 'Monte Vista' },
  { label: 'Monteagle', value: 'Monteagle' },
  { label: 'Monteagudo', value: 'Monteagudo' },
  { label: 'Monteagudo', value: 'Monteagudo' },
  { label: 'Monteagudo De Las Salinas', value: 'Monteagudo De Las Salinas' },
  { label: 'Monteagudo De Las Vicarías', value: 'Monteagudo De Las Vicarías' },
  { label: 'Monteagudo Del Castillo', value: 'Monteagudo Del Castillo' },
  { label: 'Montealegre De Campos', value: 'Montealegre De Campos' },
  { label: 'Montealegre Del Castillo', value: 'Montealegre Del Castillo' },
  { label: 'Montearagón', value: 'Montearagón' },
  { label: 'Montebello', value: 'Montebello' },
  { label: 'Montebello', value: 'Montebello' },
  { label: 'Montebello', value: 'Montebello' },
  { label: 'Montebello', value: 'Montebello' },
  { label: 'Montebello Di Bertona', value: 'Montebello Di Bertona' },
  { label: 'Montebello Jonico', value: 'Montebello Jonico' },
  { label: 'Montebello Sul Sangro', value: 'Montebello Sul Sangro' },
  { label: 'Montebello Vicentino', value: 'Montebello Vicentino' },
  { label: 'Montebelluna', value: 'Montebelluna' },
  { label: 'Montebonello', value: 'Montebonello' },
  { label: 'Montebourg', value: 'Montebourg' },
  { label: 'Montebruno', value: 'Montebruno' },
  { label: 'Montebuono', value: 'Montebuono' },
  { label: 'Montecalvario', value: 'Montecalvario' },
  { label: 'Montecalvo In Foglia', value: 'Montecalvo In Foglia' },
  { label: 'Montecalvo Irpino', value: 'Montecalvo Irpino' },
  { label: 'Montecalvo Versiggia', value: 'Montecalvo Versiggia' },
  { label: 'Montecalvoli', value: 'Montecalvoli' },
  { label: 'Montecanal', value: 'Montecanal' },
  { label: 'Montecarlo', value: 'Montecarlo' },
  { label: 'Montecarlo', value: 'Montecarlo' },
  { label: 'Montecarotto', value: 'Montecarotto' },
  { label: 'Montecassiano', value: 'Montecassiano' },
  { label: 'Montecastello', value: 'Montecastello' },
  { label: 'Montecastrilli', value: 'Montecastrilli' },
  { label: 'Montecatini Val Di Cecina', value: 'Montecatini Val Di Cecina' },
  { label: 'Montecatini-Terme', value: 'Montecatini-Terme' },
  { label: 'Montecavolo', value: 'Montecavolo' },
  { label: 'Montecchia Di Crosara', value: 'Montecchia Di Crosara' },
  { label: 'Montecchio', value: 'Montecchio' },
  { label: 'Montecchio Emilia', value: 'Montecchio Emilia' },
  { label: 'Montecchio Maggiore-Alte Ceccato', value: 'Montecchio Maggiore-Alte Ceccato' },
  { label: 'Montecchio Precalcino', value: 'Montecchio Precalcino' },
  { label: 'Montecelio', value: 'Montecelio' },
  { label: 'Montech', value: 'Montech' },
  { label: 'Montechiaro Dacqui', value: 'Montechiaro Dacqui' },
  { label: 'Montechiaro Dasti', value: 'Montechiaro Dasti' },
  { label: 'Montechiarugolo', value: 'Montechiarugolo' },
  { label: 'Monteciccardo', value: 'Monteciccardo' },
  { label: 'Montecilfone', value: 'Montecilfone' },
  { label: 'Montecillo', value: 'Montecillo' },
  { label: 'Montecillo', value: 'Montecillo' },
  { label: 'Montecillos', value: 'Montecillos' },
  { label: 'Montecito', value: 'Montecito' },
  { label: 'Montecompatri', value: 'Montecompatri' },
  { label: 'Montecorice', value: 'Montecorice' },
  { label: 'Montecorvino Pugliano', value: 'Montecorvino Pugliano' },
  { label: 'Montecorvino Rovella', value: 'Montecorvino Rovella' },
  { label: 'Montecosaro', value: 'Montecosaro' },
  { label: 'Montecrestese', value: 'Montecrestese' },
  { label: 'Montecreto', value: 'Montecreto' },
  { label: 'Montecristi', value: 'Montecristi' },
  { label: 'Montecristo', value: 'Montecristo' },
  { label: 'Montecristo', value: 'Montecristo' },
  { label: 'Montecristo De Guerrero', value: 'Montecristo De Guerrero' },
  { label: 'Montedecoro', value: 'Montedecoro' },
  { label: 'Montederramo', value: 'Montederramo' },
  { label: 'Montedinove', value: 'Montedinove' },
  { label: 'Montedoro', value: 'Montedoro' },
  { label: 'Montefalcione', value: 'Montefalcione' },
  { label: 'Montefalco', value: 'Montefalco' },
  { label: 'Montefalcone Appennino', value: 'Montefalcone Appennino' },
  { label: 'Montefalcone Di Val Fortore', value: 'Montefalcone Di Val Fortore' },
  { label: 'Montefalcone Nel Sannio', value: 'Montefalcone Nel Sannio' },
  { label: 'Montefano', value: 'Montefano' },
  { label: 'Montefelcino', value: 'Montefelcino' },
  { label: 'Monteferrante', value: 'Monteferrante' },
  { label: 'Montefiascone', value: 'Montefiascone' },
  { label: 'Montefino', value: 'Montefino' },
  { label: 'Montefiore Conca', value: 'Montefiore Conca' },
  { label: 'Montefiore Dellaso', value: 'Montefiore Dellaso' },
  { label: 'Montefiorino', value: 'Montefiorino' },
  { label: 'Monteflavio', value: 'Monteflavio' },
  { label: 'Monteforte Cilento', value: 'Monteforte Cilento' },
  { label: 'Monteforte Dalpone', value: 'Monteforte Dalpone' },
  { label: 'Monteforte Irpino', value: 'Monteforte Irpino' },
  { label: 'Montefortino', value: 'Montefortino' },
  { label: 'Montefranco', value: 'Montefranco' },
  { label: 'Montefredane', value: 'Montefredane' },
  { label: 'Montefrío', value: 'Montefrío' },
  { label: 'Montefusco', value: 'Montefusco' },
  { label: 'Montegabbione', value: 'Montegabbione' },
  { label: 'Montegalda', value: 'Montegalda' },
  { label: 'Montegaldella', value: 'Montegaldella' },
  { label: 'Montegioco', value: 'Montegioco' },
  { label: 'Montegiordano', value: 'Montegiordano' },
  { label: 'Montegiorgio', value: 'Montegiorgio' },
  { label: 'Montego Bay', value: 'Montego Bay' },
  { label: 'Montego Hill', value: 'Montego Hill' },
  { label: 'Montegranaro', value: 'Montegranaro' },
  { label: 'Montegridolfo', value: 'Montegridolfo' },
  { label: 'Montegrino Valtravaglia', value: 'Montegrino Valtravaglia' },
  { label: 'Montegrosso', value: 'Montegrosso' },
  { label: 'Montegrosso Pian Latte', value: 'Montegrosso Pian Latte' },
  { label: 'Montegrotto Terme', value: 'Montegrotto Terme' },
  { label: 'Montegut', value: 'Montegut' },
  { label: 'Montehermoso', value: 'Montehermoso' },
  { label: 'Monteiasi', value: 'Monteiasi' },
  { label: 'Monteils', value: 'Monteils' },
  { label: 'Monteiro', value: 'Monteiro' },
  { label: 'Monteiro Lobato', value: 'Monteiro Lobato' },
  { label: 'Monteirópolis', value: 'Monteirópolis' },
  { label: 'Montejaque', value: 'Montejaque' },
  { label: 'Montejicar', value: 'Montejicar' },
  { label: 'Montejo', value: 'Montejo' },
  { label: 'Montejo De Arévalo', value: 'Montejo De Arévalo' },
  { label: 'Montejo De La Sierra', value: 'Montejo De La Sierra' },
  { label: 'Montejo De La Vega De La Serrezuela', value: 'Montejo De La Vega De La Serrezuela' },
  { label: 'Montejo De Tiermes', value: 'Montejo De Tiermes' },
  { label: 'Montelabbate', value: 'Montelabbate' },
  { label: 'Montelanico', value: 'Montelanico' },
  { label: 'Montelapiano', value: 'Montelapiano' },
  { label: 'Montelarco', value: 'Montelarco' },
  { label: 'Montelavar', value: 'Montelavar' },
  { label: 'Montéléger', value: 'Montéléger' },
  { label: 'Monteleón', value: 'Monteleón' },
  { label: 'Monteleone Di Fermo', value: 'Monteleone Di Fermo' },
  { label: 'Monteleone Di Puglia', value: 'Monteleone Di Puglia' },
  { label: 'Monteleone Di Spoleto', value: 'Monteleone Di Spoleto' },
  { label: 'Monteleone Dorvieto', value: 'Monteleone Dorvieto' },
  { label: 'Monteleone Rocca Doria', value: 'Monteleone Rocca Doria' },
  { label: 'Monteleone Sabino', value: 'Monteleone Sabino' },
  { label: 'Montelepre', value: 'Montelepre' },
  { label: 'Montelera', value: 'Montelera' },
  { label: 'Montelíbano', value: 'Montelíbano' },
  { label: 'Montelibretti', value: 'Montelibretti' },
  { label: 'Montélier', value: 'Montélier' },
  { label: 'Montélimar', value: 'Montélimar' },
  { label: 'Montella', value: 'Montella' },
  { label: 'Montellà I Martinet', value: 'Montellà I Martinet' },
  { label: 'Montellano', value: 'Montellano' },
  { label: 'Montello', value: 'Montello' },
  { label: 'Montello', value: 'Montello' },
  { label: 'Montello', value: 'Montello' },
  { label: 'Montelongo', value: 'Montelongo' },
  { label: 'Montelparo', value: 'Montelparo' },
  { label: 'Montelupo Albese', value: 'Montelupo Albese' },
  { label: 'Montelupo Fiorentino', value: 'Montelupo Fiorentino' },
  { label: 'Montelupone', value: 'Montelupone' },
  { label: 'Montemaggiore Al Metauro', value: 'Montemaggiore Al Metauro' },
  { label: 'Montemaggiore Belsito', value: 'Montemaggiore Belsito' },
  { label: 'Montemagno', value: 'Montemagno' },
  { label: 'Montemale Di Cuneo', value: 'Montemale Di Cuneo' },
  { label: 'Montemarano', value: 'Montemarano' },
  { label: 'Montemarciano', value: 'Montemarciano' },
  { label: 'Montemarzino', value: 'Montemarzino' },
  { label: 'Montemayor', value: 'Montemayor' },
  { label: 'Montemayor De Pililla', value: 'Montemayor De Pililla' },
  { label: 'Montemayor Del Río', value: 'Montemayor Del Río' },
  { label: 'Montemerlo', value: 'Montemerlo' },
  { label: 'Montemesola', value: 'Montemesola' },
  { label: 'Montemezzo', value: 'Montemezzo' },
  { label: 'Montemignaio', value: 'Montemignaio' },
  { label: 'Montemiletto', value: 'Montemiletto' },
  { label: 'Montemilone', value: 'Montemilone' },
  { label: 'Montemitro', value: 'Montemitro' },
  { label: 'Montemolín', value: 'Montemolín' },
  { label: 'Montemonaco', value: 'Montemonaco' },
  { label: 'Montemorelos', value: 'Montemorelos' },
  { label: 'Montemor-O-Novo', value: 'Montemor-O-Novo' },
  { label: 'Montemor-O-Velho', value: 'Montemor-O-Velho' },
  { label: 'Montemurlo', value: 'Montemurlo' },
  { label: 'Montemurro', value: 'Montemurro' },
  { label: 'Montenars', value: 'Montenars' },
  { label: 'Montenay', value: 'Montenay' },
  { label: 'Montendre', value: 'Montendre' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'Montenegro De Cameros', value: 'Montenegro De Cameros' },
  { label: 'Montenegro La Lana', value: 'Montenegro La Lana' },
  { label: 'Montenero Di Bisaccia', value: 'Montenero Di Bisaccia' },
  { label: 'Montenero Sabino', value: 'Montenero Sabino' },
  { label: 'Montenero Val Cocchiara', value: 'Montenero Val Cocchiara' },
  { label: 'Montenerodomo', value: 'Montenerodomo' },
  { label: 'Montenois', value: 'Montenois' },
  { label: 'Monteodorisio', value: 'Monteodorisio' },
  { label: 'Monteón', value: 'Monteón' },
  { label: 'Montepaone', value: 'Montepaone' },
  { label: 'Montepaone Lido', value: 'Montepaone Lido' },
  { label: 'Monteparano', value: 'Monteparano' },
  { label: 'Monteprandone', value: 'Monteprandone' },
  { label: 'Montepuez', value: 'Montepuez' },
  { label: 'Montepulciano', value: 'Montepulciano' },
  { label: 'Montepulciano Stazione', value: 'Montepulciano Stazione' },
  { label: 'Monterado', value: 'Monterado' },
  { label: 'Monterblanc', value: 'Monterblanc' },
  { label: 'Monterchi', value: 'Monterchi' },
  { label: 'Monterde', value: 'Monterde' },
  { label: 'Monterde De Albarracín', value: 'Monterde De Albarracín' },
  { label: 'Montereale', value: 'Montereale' },
  { label: 'Montereale Valcellina', value: 'Montereale Valcellina' },
  { label: 'Montereau-Fault-Yonne', value: 'Montereau-Fault-Yonne' },
  { label: 'Monterenzio', value: 'Monterenzio' },
  { label: 'Monterey', value: 'Monterey' },
  { label: 'Monterey', value: 'Monterey' },
  { label: 'Monterey', value: 'Monterey' },
  { label: 'Monterey', value: 'Monterey' },
  { label: 'Monterey County', value: 'Monterey County' },
  { label: 'Monterey Park', value: 'Monterey Park' },
  { label: 'Monterey Park', value: 'Monterey Park' },
  { label: 'Monterfil', value: 'Monterfil' },
  { label: 'Montería', value: 'Montería' },
  { label: 'Monteriggioni', value: 'Monteriggioni' },
  { label: 'Montero', value: 'Montero' },
  { label: 'Monteroduni', value: 'Monteroduni' },
  { label: 'Monteroni Darbia', value: 'Monteroni Darbia' },
  { label: 'Monteroni Di Lecce', value: 'Monteroni Di Lecce' },
  { label: 'Monteros', value: 'Monteros' },
  { label: 'Monterosi', value: 'Monterosi' },
  { label: 'Monterosso Al Mare', value: 'Monterosso Al Mare' },
  { label: 'Monterosso Almo', value: 'Monterosso Almo' },
  { label: 'Monterosso Calabro', value: 'Monterosso Calabro' },
  { label: 'Monterosso Grana', value: 'Monterosso Grana' },
  { label: 'Monterotondo', value: 'Monterotondo' },
  { label: 'Monterotondo Marittimo', value: 'Monterotondo Marittimo' },
  { label: 'Monterrei', value: 'Monterrei' },
  { label: 'Monterrey', value: 'Monterrey' },
  { label: 'Monterrey', value: 'Monterrey' },
  { label: 'Monterrey', value: 'Monterrey' },
  { label: 'Monterrey', value: 'Monterrey' },
  { label: 'Monterroso', value: 'Monterroso' },
  { label: 'Monterrubio', value: 'Monterrubio' },
  { label: 'Monterrubio De Armuña', value: 'Monterrubio De Armuña' },
  { label: 'Monterrubio De La Demanda', value: 'Monterrubio De La Demanda' },
  { label: 'Monterrubio De La Serena', value: 'Monterrubio De La Serena' },
  { label: 'Monterrubio De La Sierra', value: 'Monterrubio De La Sierra' },
  { label: 'Monterubbiano', value: 'Monterubbiano' },
  { label: 'Monterusciello', value: 'Monterusciello' },
  { label: 'Montes', value: 'Montes' },
  { label: 'Montes Altos', value: 'Montes Altos' },
  { label: 'Montes Claros', value: 'Montes Claros' },
  { label: 'Montes Claros De Goiás', value: 'Montes Claros De Goiás' },
  { label: 'Montes De Oca', value: 'Montes De Oca' },
  { label: 'Montes De Oro', value: 'Montes De Oro' },
  { label: 'Montesa', value: 'Montesa' },
  { label: 'Montesano', value: 'Montesano' },
  { label: 'Montesano Salentino', value: 'Montesano Salentino' },
  { label: 'Montesano Sulla Marcellana', value: 'Montesano Sulla Marcellana' },
  { label: 'Montesarchio', value: 'Montesarchio' },
  { label: 'Montesardo', value: 'Montesardo' },
  { label: 'Montescaglioso', value: 'Montescaglioso' },
  { label: 'Montescano', value: 'Montescano' },
  { label: 'Montescheno', value: 'Montescheno' },
  { label: 'Montesclaros', value: 'Montesclaros' },
  { label: 'Montescot', value: 'Montescot' },
  { label: 'Montescourt-Lizerolles', value: 'Montescourt-Lizerolles' },
  { label: 'Montescudaio', value: 'Montescudaio' },
  { label: 'Montescudo', value: 'Montescudo' },
  { label: 'Montese', value: 'Montese' },
  { label: 'Montesegale', value: 'Montesegale' },
  { label: 'Montesilvano Marina', value: 'Montesilvano Marina' },
  { label: 'Montesinos Los', value: 'Montesinos Los' },
  { label: 'Montesolaro', value: 'Montesolaro' },
  { label: 'Montespertoli', value: 'Montespertoli' },
  { label: 'Montesquieu-Volvestre', value: 'Montesquieu-Volvestre' },
  { label: 'Montesquiu', value: 'Montesquiu' },
  { label: 'Montesquiu Dalbera', value: 'Montesquiu Dalbera' },
  { label: 'Montesson', value: 'Montesson' },
  { label: 'Monteu Da Po', value: 'Monteu Da Po' },
  { label: 'Monteu Roero', value: 'Monteu Roero' },
  { label: 'Monteux', value: 'Monteux' },
  { label: 'Montevago', value: 'Montevago' },
  { label: 'Montevallo', value: 'Montevallo' },
  { label: 'Montevarchi', value: 'Montevarchi' },
  { label: 'Montevecchia', value: 'Montevecchia' },
  { label: 'Monteverde', value: 'Monteverde' },
  { label: 'Monteverdi Marittimo', value: 'Monteverdi Marittimo' },
  { label: 'Monteviale', value: 'Monteviale' },
  { label: 'Montevideo', value: 'Montevideo' },
  { label: 'Montevideo', value: 'Montevideo' },
  { label: 'Montevista', value: 'Montevista' },
  { label: 'Montevista', value: 'Montevista' },
  { label: 'Montevista', value: 'Montevista' },
  { label: 'Montévrain', value: 'Montévrain' },
  { label: 'Montezemolo', value: 'Montezemolo' },
  { label: 'Montezuma', value: 'Montezuma' },
  { label: 'Montezuma', value: 'Montezuma' },
  { label: 'Montezuma', value: 'Montezuma' },
  { label: 'Montezuma County', value: 'Montezuma County' },
  { label: 'Montfaucon', value: 'Montfaucon' },
  { label: 'Montfaucon', value: 'Montfaucon' },
  { label: 'Montfaucon-En-Velay', value: 'Montfaucon-En-Velay' },
  { label: 'Montfaucon-Montigné', value: 'Montfaucon-Montigné' },
  { label: 'Montfavet', value: 'Montfavet' },
  { label: 'Montfermeil', value: 'Montfermeil' },
  { label: 'Montferrand-Le-Château', value: 'Montferrand-Le-Château' },
  { label: 'Montferrat', value: 'Montferrat' },
  { label: 'Montferrat', value: 'Montferrat' },
  { label: 'Montferrer I Castellbò', value: 'Montferrer I Castellbò' },
  { label: 'Montferri', value: 'Montferri' },
  { label: 'Montferrier-Sur-Lez', value: 'Montferrier-Sur-Lez' },
  { label: 'Montfoort', value: 'Montfoort' },
  { label: 'Montfort', value: 'Montfort' },
  { label: 'Montfort-En-Chalosse', value: 'Montfort-En-Chalosse' },
  { label: 'Montfort-Le-Gesnois', value: 'Montfort-Le-Gesnois' },
  { label: 'Montfort-Sur-Meu', value: 'Montfort-Sur-Meu' },
  { label: 'Montfrin', value: 'Montfrin' },
  { label: 'Montgai', value: 'Montgai' },
  { label: 'Montgaillard', value: 'Montgaillard' },
  { label: 'Montgat', value: 'Montgat' },
  { label: 'Montgermont', value: 'Montgermont' },
  { label: 'Montgeron', value: 'Montgeron' },
  { label: 'Montgiscard', value: 'Montgiscard' },
  { label: 'Montgivray', value: 'Montgivray' },
  { label: 'Montgomery', value: 'Montgomery' },
  { label: 'Montgomery', value: 'Montgomery' },
  { label: 'Montgomery', value: 'Montgomery' },
  { label: 'Montgomery', value: 'Montgomery' },
  { label: 'Montgomery', value: 'Montgomery' },
  { label: 'Montgomery', value: 'Montgomery' },
  { label: 'Montgomery', value: 'Montgomery' },
  { label: 'Montgomery', value: 'Montgomery' },
  { label: 'Montgomery', value: 'Montgomery' },
  { label: 'Montgomery', value: 'Montgomery' },
  { label: 'Montgomery City', value: 'Montgomery City' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery County', value: 'Montgomery County' },
  { label: 'Montgomery Village', value: 'Montgomery Village' },
  { label: 'Montgomeryville', value: 'Montgomeryville' },
  { label: 'Montguyon', value: 'Montguyon' },
  { label: 'Monthermé', value: 'Monthermé' },
  { label: 'Monthey', value: 'Monthey' },
  { label: 'Monthey District', value: 'Monthey District' },
  { label: 'Monthyon', value: 'Monthyon' },
  { label: 'Monti', value: 'Monti' },
  { label: 'Monti', value: 'Monti' },
  { label: 'Monti Darena-Bosco Caggione', value: 'Monti Darena-Bosco Caggione' },
  { label: 'Montiano', value: 'Montiano' },
  { label: 'Monticchio', value: 'Monticchio' },
  { label: 'Monticelli', value: 'Monticelli' },
  { label: 'Monticelli Brusati', value: 'Monticelli Brusati' },
  { label: 'Monticelli Dongina', value: 'Monticelli Dongina' },
  { label: 'Monticelli Pavese', value: 'Monticelli Pavese' },
  { label: 'Monticelli Terme', value: 'Monticelli Terme' },
  { label: 'Monticelli-Fontana', value: 'Monticelli-Fontana' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello', value: 'Monticello' },
  { label: 'Monticello Brianza', value: 'Monticello Brianza' },
  { label: 'Monticello Conte Otto', value: 'Monticello Conte Otto' },
  { label: 'Monticello Dalba', value: 'Monticello Dalba' },
  { label: 'Monticello Di Fara', value: 'Monticello Di Fara' },
  { label: 'Montichelvo', value: 'Montichelvo' },
  { label: 'Montichiari', value: 'Montichiari' },
  { label: 'Monticiano', value: 'Monticiano' },
  { label: 'Montiel', value: 'Montiel' },
  { label: 'Montierchaume', value: 'Montierchaume' },
  { label: 'Montier-En-Der', value: 'Montier-En-Der' },
  { label: 'Montieri', value: 'Montieri' },
  { label: 'Montiglio', value: 'Montiglio' },
  { label: 'Montignac', value: 'Montignac' },
  { label: 'Montignano-Marzocca', value: 'Montignano-Marzocca' },
  { label: 'Montigné-Le-Brillant', value: 'Montigné-Le-Brillant' },
  { label: 'Montignoso', value: 'Montignoso' },
  { label: 'Montigny', value: 'Montigny' },
  { label: 'Montigny-En-Gohelle', value: 'Montigny-En-Gohelle' },
  { label: 'Montigny-En-Ostrevent', value: 'Montigny-En-Ostrevent' },
  { label: 'Montigny-Le-Bretonneux', value: 'Montigny-Le-Bretonneux' },
  { label: 'Montigny-Lencoup', value: 'Montigny-Lencoup' },
  { label: 'Montigny-Lès-Cormeilles', value: 'Montigny-Lès-Cormeilles' },
  { label: 'Montigny-Lès-Metz', value: 'Montigny-Lès-Metz' },
  { label: 'Montigny-Sur-Loing', value: 'Montigny-Sur-Loing' },
  { label: 'Montijo', value: 'Montijo' },
  { label: 'Montijo', value: 'Montijo' },
  { label: 'Montijo', value: 'Montijo' },
  { label: 'Montilla', value: 'Montilla' },
  { label: 'Montilla', value: 'Montilla' },
  { label: 'Montillana', value: 'Montillana' },
  { label: 'Montirone', value: 'Montirone' },
  { label: 'Montitxelvo/Montichelvo', value: 'Montitxelvo/Montichelvo' },
  { label: 'Montividiu', value: 'Montividiu' },
  { label: 'Montividiu Do Norte', value: 'Montividiu Do Norte' },
  { label: 'Montivilliers', value: 'Montivilliers' },
  { label: 'Montizón', value: 'Montizón' },
  { label: 'Montjean-Sur-Loire', value: 'Montjean-Sur-Loire' },
  { label: 'Montjoie-En-Couserans', value: 'Montjoie-En-Couserans' },
  { label: 'Montjoire', value: 'Montjoire' },
  { label: 'Mont-Joli', value: 'Mont-Joli' },
  { label: 'Montjovet', value: 'Montjovet' },
  { label: 'Montlaur', value: 'Montlaur' },
  { label: 'Mont-Laurier', value: 'Mont-Laurier' },
  { label: 'Montlebon', value: 'Montlebon' },
  { label: 'Montlhéry', value: 'Montlhéry' },
  { label: 'Montlieu-La-Garde', value: 'Montlieu-La-Garde' },
  { label: 'Montlignon', value: 'Montlignon' },
  { label: 'Montlivault', value: 'Montlivault' },
  { label: 'Montlouis-Sur-Loire', value: 'Montlouis-Sur-Loire' },
  { label: 'Montluçon', value: 'Montluçon' },
  { label: 'Montluel', value: 'Montluel' },
  { label: 'Montmacq', value: 'Montmacq' },
  { label: 'Montmagny', value: 'Montmagny' },
  { label: 'Montmagny', value: 'Montmagny' },
  { label: 'Montmain', value: 'Montmain' },
  { label: 'Montmajor', value: 'Montmajor' },
  { label: 'Montmaneu', value: 'Montmaneu' },
  { label: 'Montmarault', value: 'Montmarault' },
  { label: 'Montmartin-Sur-Mer', value: 'Montmartin-Sur-Mer' },
  { label: 'Montmédy', value: 'Montmédy' },
  { label: 'Montmélian', value: 'Montmélian' },
  { label: 'Montmell', value: 'Montmell' },
  { label: 'Montmeló', value: 'Montmeló' },
  { label: 'Montmerle-Sur-Saône', value: 'Montmerle-Sur-Saône' },
  { label: 'Montmeyran', value: 'Montmeyran' },
  { label: 'Montmirail', value: 'Montmirail' },
  { label: 'Montmoreau-Saint-Cybard', value: 'Montmoreau-Saint-Cybard' },
  { label: 'Montmorency', value: 'Montmorency' },
  { label: 'Montmorency', value: 'Montmorency' },
  { label: 'Montmorency County', value: 'Montmorency County' },
  { label: 'Montmorillon', value: 'Montmorillon' },
  { label: 'Montmorot', value: 'Montmorot' },
  { label: 'Monto', value: 'Monto' },
  { label: 'Montodine', value: 'Montodine' },
  { label: 'Montoggio', value: 'Montoggio' },
  { label: 'Montoir-De-Bretagne', value: 'Montoir-De-Bretagne' },
  { label: 'Montoire-Sur-Le-Loir', value: 'Montoire-Sur-Le-Loir' },
  { label: 'Montois-La-Montagne', value: 'Montois-La-Montagne' },
  { label: 'Montoison', value: 'Montoison' },
  { label: 'Montoliu De Lleida', value: 'Montoliu De Lleida' },
  { label: 'Montoliu De Segarra', value: 'Montoliu De Segarra' },
  { label: 'Montolivet', value: 'Montolivet' },
  { label: 'Montón', value: 'Montón' },
  { label: 'Montone', value: 'Montone' },
  { label: 'Montopoli', value: 'Montopoli' },
  { label: 'Montopoli In Sabina', value: 'Montopoli In Sabina' },
  { label: 'Montorfano', value: 'Montorfano' },
  { label: 'Montòrganize', value: 'Montòrganize' },
  { label: 'Montorio', value: 'Montorio' },
  { label: 'Montorio', value: 'Montorio' },
  { label: 'Montorio Al Vomano', value: 'Montorio Al Vomano' },
  { label: 'Montorio Nei Frentani', value: 'Montorio Nei Frentani' },
  { label: 'Montorio Romano', value: 'Montorio Romano' },
  { label: 'Montornès De Segarra', value: 'Montornès De Segarra' },
  { label: 'Montornès Del Vallès', value: 'Montornès Del Vallès' },
  { label: 'Montoro', value: 'Montoro' },
  { label: 'Montoro', value: 'Montoro' },
  { label: 'Montoro Superiore', value: 'Montoro Superiore' },
  { label: 'Montorso Vicentino', value: 'Montorso Vicentino' },
  { label: 'Montottone', value: 'Montottone' },
  { label: 'Montour County', value: 'Montour County' },
  { label: 'Montour Falls', value: 'Montour Falls' },
  { label: 'Montournais', value: 'Montournais' },
  { label: 'Montoursville', value: 'Montoursville' },
  { label: 'Montpelier', value: 'Montpelier' },
  { label: 'Montpelier', value: 'Montpelier' },
  { label: 'Montpelier', value: 'Montpelier' },
  { label: 'Montpelier', value: 'Montpelier' },
  { label: 'Montpelier', value: 'Montpelier' },
  { label: 'Montpellier', value: 'Montpellier' },
  { label: 'Montpeyroux', value: 'Montpeyroux' },
  { label: 'Montpezat-De-Quercy', value: 'Montpezat-De-Quercy' },
  { label: 'Montpon-Ménestérol', value: 'Montpon-Ménestérol' },
  { label: 'Montpont-En-Bresse', value: 'Montpont-En-Bresse' },
  { label: 'Mont-Près-Chambord', value: 'Mont-Près-Chambord' },
  { label: 'Montrabé', value: 'Montrabé' },
  { label: 'Mont-Ral', value: 'Mont-Ral' },
  { label: 'Mont-Ras', value: 'Mont-Ras' },
  { label: 'Montréal', value: 'Montréal' },
  { label: 'Montréal', value: 'Montréal' },
  { label: 'Montréal-Est', value: 'Montréal-Est' },
  { label: 'Montréal-La-Cluse', value: 'Montréal-La-Cluse' },
  { label: 'Montréal-Ouest', value: 'Montréal-Ouest' },
  { label: 'Montredon', value: 'Montredon' },
  { label: 'Montredon-Labessonnié', value: 'Montredon-Labessonnié' },
  { label: 'Montréjeau', value: 'Montréjeau' },
  { label: 'Montrem', value: 'Montrem' },
  { label: 'Montresta', value: 'Montresta' },
  { label: 'Montreuil', value: 'Montreuil' },
  { label: 'Montreuil', value: 'Montreuil' },
  { label: 'Montreuil-Aux-Lions', value: 'Montreuil-Aux-Lions' },
  { label: 'Montreuil-Bellay', value: 'Montreuil-Bellay' },
  { label: 'Montreuil-Le-Gast', value: 'Montreuil-Le-Gast' },
  { label: 'Montreuil-Sous-Pérouse', value: 'Montreuil-Sous-Pérouse' },
  { label: 'Montreuil-Sur-Ille', value: 'Montreuil-Sur-Ille' },
  { label: 'Montreux', value: 'Montreux' },
  { label: 'Montreux-Château', value: 'Montreux-Château' },
  { label: 'Montrevault', value: 'Montrevault' },
  { label: 'Montrevel-En-Bresse', value: 'Montrevel-En-Bresse' },
  { label: 'Montrichard', value: 'Montrichard' },
  { label: 'Montricoux', value: 'Montricoux' },
  { label: 'Montrodat', value: 'Montrodat' },
  { label: 'Mont-Roig Del Camp', value: 'Mont-Roig Del Camp' },
  { label: 'Montrond-Les-Bains', value: 'Montrond-Les-Bains' },
  { label: 'Montrose', value: 'Montrose' },
  { label: 'Montrose', value: 'Montrose' },
  { label: 'Montrose', value: 'Montrose' },
  { label: 'Montrose', value: 'Montrose' },
  { label: 'Montrose', value: 'Montrose' },
  { label: 'Montrose', value: 'Montrose' },
  { label: 'Montrose', value: 'Montrose' },
  { label: 'Montrose', value: 'Montrose' },
  { label: 'Montrose', value: 'Montrose' },
  { label: 'Montrose County', value: 'Montrose County' },
  { label: 'Montrose-Ghent', value: 'Montrose-Ghent' },
  { label: 'Montross', value: 'Montross' },
  { label: 'Montrottier', value: 'Montrottier' },
  { label: 'Montrouge', value: 'Montrouge' },
  { label: 'Montroy', value: 'Montroy' },
  { label: 'Mont-Royal', value: 'Mont-Royal' },
  { label: 'Montry', value: 'Montry' },
  { label: 'Monts', value: 'Monts' },
  { label: 'Mont-Saint-Aignan', value: 'Mont-Saint-Aignan' },
  { label: 'Mont-Saint-Éloi', value: 'Mont-Saint-Éloi' },
  { label: 'Mont-Saint-Grégoire', value: 'Mont-Saint-Grégoire' },
  { label: 'Mont-Saint-Guibert', value: 'Mont-Saint-Guibert' },
  { label: 'Mont-Saint-Hilaire', value: 'Mont-Saint-Hilaire' },
  { label: 'Mont-Saint-Martin', value: 'Mont-Saint-Martin' },
  { label: 'Mont-Saxonnex', value: 'Mont-Saxonnex' },
  { label: 'Montségur-Sur-Lauzon', value: 'Montségur-Sur-Lauzon' },
  { label: 'Montseny', value: 'Montseny' },
  { label: 'Montserrat', value: 'Montserrat' },
  { label: 'Montsoult', value: 'Montsoult' },
  { label: 'Mont-Sous-Vaudrey', value: 'Mont-Sous-Vaudrey' },
  { label: 'Montsûrs', value: 'Montsûrs' },
  { label: 'Mont-Tremblant', value: 'Mont-Tremblant' },
  { label: 'Montù Beccaria', value: 'Montù Beccaria' },
  { label: 'Montuïri', value: 'Montuïri' },
  { label: 'Monturque', value: 'Monturque' },
  { label: 'Montussan', value: 'Montussan' },
  { label: 'Montvale', value: 'Montvale' },
  { label: 'Montverde', value: 'Montverde' },
  { label: 'Montville', value: 'Montville' },
  { label: 'Montville Center', value: 'Montville Center' },
  { label: 'Montz', value: 'Montz' },
  { label: 'Monument', value: 'Monument' },
  { label: 'Monument Beach', value: 'Monument Beach' },
  { label: 'Monument Hills', value: 'Monument Hills' },
  { label: 'Monvalle', value: 'Monvalle' },
  { label: 'Monywa', value: 'Monywa' },
  { label: 'Monza', value: 'Monza' },
  { label: 'Monzambano', value: 'Monzambano' },
  { label: 'Monze', value: 'Monze' },
  { label: 'Monzelfeld', value: 'Monzelfeld' },
  { label: 'Monzie', value: 'Monzie' },
  { label: 'Monzingen', value: 'Monzingen' },
  { label: 'Monzón', value: 'Monzón' },
  { label: 'Monzón De Campos', value: 'Monzón De Campos' },
  { label: 'Monzuno', value: 'Monzuno' },
  { label: 'Moodiesburn', value: 'Moodiesburn' },
  { label: 'Moodus', value: 'Moodus' },
  { label: 'Moody', value: 'Moody' },
  { label: 'Moody', value: 'Moody' },
  { label: 'Moody County', value: 'Moody County' },
  { label: 'Moog', value: 'Moog' },
  { label: 'Mooirivier', value: 'Mooirivier' },
  { label: 'Mook', value: 'Mook' },
  { label: 'Mooka', value: 'Mooka' },
  { label: 'Mookane', value: 'Mookane' },
  { label: 'Mooka-Shi', value: 'Mooka-Shi' },
  { label: 'Moolap', value: 'Moolap' },
  { label: 'Mooloolaba', value: 'Mooloolaba' },
  { label: 'Moonachie', value: 'Moonachie' },
  { label: 'Moonah', value: 'Moonah' },
  { label: 'Moonbi', value: 'Moonbi' },
  { label: 'Mooncoin', value: 'Mooncoin' },
  { label: 'Moone', value: 'Moone' },
  { label: 'Moonee Beach', value: 'Moonee Beach' },
  { label: 'Moonee Ponds', value: 'Moonee Ponds' },
  { label: 'Moonee Valley', value: 'Moonee Valley' },
  { label: 'Moonta Bay', value: 'Moonta Bay' },
  { label: 'Moora', value: 'Moora' },
  { label: 'Moorabbin', value: 'Moorabbin' },
  { label: 'Moorabool', value: 'Moorabool' },
  { label: 'Moorak', value: 'Moorak' },
  { label: 'Moorcroft', value: 'Moorcroft' },
  { label: 'Moore', value: 'Moore' },
  { label: 'Moore County', value: 'Moore County' },
  { label: 'Moore County', value: 'Moore County' },
  { label: 'Moore County', value: 'Moore County' },
  { label: 'Moore Haven', value: 'Moore Haven' },
  { label: 'Moore Park Beach', value: 'Moore Park Beach' },
  { label: 'Moore Town', value: 'Moore Town' },
  { label: 'Moorebank', value: 'Moorebank' },
  { label: 'Moorefield', value: 'Moorefield' },
  { label: 'Mooreland', value: 'Mooreland' },
  { label: 'Moorenweis', value: 'Moorenweis' },
  { label: 'Moores', value: 'Moores' },
  { label: 'Moores Mill', value: 'Moores Mill' },
  { label: 'Moorestown-Lenola', value: 'Moorestown-Lenola' },
  { label: 'Mooresville', value: 'Mooresville' },
  { label: 'Mooresville', value: 'Mooresville' },
  { label: 'Moorhead', value: 'Moorhead' },
  { label: 'Moorhead', value: 'Moorhead' },
  { label: 'Mooroobool', value: 'Mooroobool' },
  { label: 'Moorooduc', value: 'Moorooduc' },
  { label: 'Moorooka', value: 'Moorooka' },
  { label: 'Mooroolbark', value: 'Mooroolbark' },
  { label: 'Mooroopna', value: 'Mooroopna' },
  { label: 'Moorpark', value: 'Moorpark' },
  { label: 'Moorreesburg', value: 'Moorreesburg' },
  { label: 'Moorrege', value: 'Moorrege' },
  { label: 'Moorslede', value: 'Moorslede' },
  { label: 'Moos', value: 'Moos' },
  { label: 'Moosach', value: 'Moosach' },
  { label: 'Moosbach', value: 'Moosbach' },
  { label: 'Moosbrunn', value: 'Moosbrunn' },
  { label: 'Moosburg', value: 'Moosburg' },
  { label: 'Moosburg', value: 'Moosburg' },
  { label: 'Moosch', value: 'Moosch' },
  { label: 'Moosdorf', value: 'Moosdorf' },
  { label: 'Moose Factory', value: 'Moose Factory' },
  { label: 'Moose Jaw', value: 'Moose Jaw' },
  { label: 'Moose Lake', value: 'Moose Lake' },
  { label: 'Moose Lake', value: 'Moose Lake' },
  { label: 'Moose Wilson Road', value: 'Moose Wilson Road' },
  { label: 'Moosic', value: 'Moosic' },
  { label: 'Moosinning', value: 'Moosinning' },
  { label: 'Mooskirchen', value: 'Mooskirchen' },
  { label: 'Moosomin', value: 'Moosomin' },
  { label: 'Moosonee', value: 'Moosonee' },
  { label: 'Moosthenning', value: 'Moosthenning' },
  { label: 'Moosup', value: 'Moosup' },
  { label: 'Mopani District Municipality', value: 'Mopani District Municipality' },
  { label: 'Mopipi', value: 'Mopipi' },
  { label: 'Mopti', value: 'Mopti' },
  { label: 'Moqavemat', value: 'Moqavemat' },
  { label: 'Moquegua', value: 'Moquegua' },
  { label: 'Mór', value: 'Mór' },
  { label: 'Mora', value: 'Mora' },
  { label: 'Mora', value: 'Mora' },
  { label: 'Mora', value: 'Mora' },
  { label: 'Mora', value: 'Mora' },
  { label: 'Mora', value: 'Mora' },
  { label: 'Mora', value: 'Mora' },
  { label: 'Mora', value: 'Mora' },
  { label: 'Mora', value: 'Mora' },
  { label: 'Mora County', value: 'Mora County' },
  { label: 'Mora De Rubielos', value: 'Mora De Rubielos' },
  { label: 'Móra Debre', value: 'Móra Debre' },
  { label: 'Móra La Nova', value: 'Móra La Nova' },
  { label: 'Mora Maharashtra', value: 'Mora Maharashtra' },
  { label: 'Mora Villalobos', value: 'Mora Villalobos' },
  { label: 'Morada', value: 'Morada' },
  { label: 'Morada Nova', value: 'Morada Nova' },
  { label: 'Morada Nova De Minas', value: 'Morada Nova De Minas' },
  { label: 'Moradabad', value: 'Moradabad' },
  { label: 'Moradillo De Roa', value: 'Moradillo De Roa' },
  { label: 'Moradlu', value: 'Moradlu' },
  { label: 'Morąg', value: 'Morąg' },
  { label: 'Moraga', value: 'Moraga' },
  { label: 'Mórahalmi Járás', value: 'Mórahalmi Járás' },
  { label: 'Mórahalom', value: 'Mórahalom' },
  { label: 'Moraine', value: 'Moraine' },
  { label: 'Morainvilliers', value: 'Morainvilliers' },
  { label: 'Moraira', value: 'Moraira' },
  { label: 'Moral De Calatrava', value: 'Moral De Calatrava' },
  { label: 'Moral De Hornuez', value: 'Moral De Hornuez' },
  { label: 'Moral De La Reina', value: 'Moral De La Reina' },
  { label: 'Moral De Sayago', value: 'Moral De Sayago' },
  { label: 'Moraleda De Zafayona', value: 'Moraleda De Zafayona' },
  { label: 'Moraleja', value: 'Moraleja' },
  { label: 'Moraleja De Enmedio', value: 'Moraleja De Enmedio' },
  { label: 'Moraleja De Las Panaderas', value: 'Moraleja De Las Panaderas' },
  { label: 'Moraleja De Matacabras', value: 'Moraleja De Matacabras' },
  { label: 'Moraleja De Sayago', value: 'Moraleja De Sayago' },
  { label: 'Moraleja Del Vino', value: 'Moraleja Del Vino' },
  { label: 'Morales', value: 'Morales' },
  { label: 'Morales', value: 'Morales' },
  { label: 'Morales', value: 'Morales' },
  { label: 'Morales', value: 'Morales' },
  { label: 'Morales', value: 'Morales' },
  { label: 'Morales De Campos', value: 'Morales De Campos' },
  { label: 'Morales De Rey', value: 'Morales De Rey' },
  { label: 'Morales De Toro', value: 'Morales De Toro' },
  { label: 'Morales De Valverde', value: 'Morales De Valverde' },
  { label: 'Morales Del Vino', value: 'Morales Del Vino' },
  { label: 'Moralillo', value: 'Moralillo' },
  { label: 'Moralina', value: 'Moralina' },
  { label: 'Moralzarzal', value: 'Moralzarzal' },
  { label: 'Moram', value: 'Moram' },
  { label: 'Moraña', value: 'Moraña' },
  { label: 'Moranbah', value: 'Moranbah' },
  { label: 'Morancé', value: 'Morancé' },
  { label: 'Morancez', value: 'Morancez' },
  { label: 'Morang', value: 'Morang' },
  { label: 'Morangis', value: 'Morangis' },
  { label: 'Moranha', value: 'Moranha' },
  { label: 'Morani', value: 'Morani' },
  { label: 'Morannes', value: 'Morannes' },
  { label: 'Morano Calabro', value: 'Morano Calabro' },
  { label: 'Morano Sul Po', value: 'Morano Sul Po' },
  { label: 'Moransengo', value: 'Moransengo' },
  { label: 'Morant Bay', value: 'Morant Bay' },
  { label: 'Morar', value: 'Morar' },
  { label: 'Morăreni', value: 'Morăreni' },
  { label: 'Morăreşti', value: 'Morăreşti' },
  { label: 'Moraro', value: 'Moraro' },
  { label: 'Mörarp', value: 'Mörarp' },
  { label: 'Moras', value: 'Moras' },
  { label: 'Morasverdes', value: 'Morasverdes' },
  { label: 'Morata De Jalón', value: 'Morata De Jalón' },
  { label: 'Morata De Jiloca', value: 'Morata De Jiloca' },
  { label: 'Morata De Tajuña', value: 'Morata De Tajuña' },
  { label: 'Moratalaz', value: 'Moratalaz' },
  { label: 'Moratalla', value: 'Moratalla' },
  { label: 'Moratilla De Los Meleros', value: 'Moratilla De Los Meleros' },
  { label: 'Moratinos', value: 'Moratinos' },
  { label: 'Moratuwa', value: 'Moratuwa' },
  { label: 'Moraújo', value: 'Moraújo' },
  { label: 'Morava', value: 'Morava' },
  { label: 'Moravany', value: 'Moravany' },
  { label: 'Moravany', value: 'Moravany' },
  { label: 'Moravče', value: 'Moravče' },
  { label: 'Moravia', value: 'Moravia' },
  { label: 'Moravia', value: 'Moravia' },
  { label: 'Moravian Falls', value: 'Moravian Falls' },
  { label: 'Moravičany', value: 'Moravičany' },
  { label: 'Moraviţa', value: 'Moraviţa' },
  { label: 'Morávka', value: 'Morávka' },
  { label: 'Moravská Nová Ves', value: 'Moravská Nová Ves' },
  { label: 'Moravská Třebová', value: 'Moravská Třebová' },
  { label: 'Moravské Budějovice', value: 'Moravské Budějovice' },
  { label: 'Moravske Toplice', value: 'Moravske Toplice' },
  { label: 'Moravský Beroun', value: 'Moravský Beroun' },
  { label: 'Moravský Krumlov', value: 'Moravský Krumlov' },
  { label: 'Moravský Písek', value: 'Moravský Písek' },
  { label: 'Moravský Žižkov', value: 'Moravský Žižkov' },
  { label: 'Morawa', value: 'Morawa' },
  { label: 'Morawica', value: 'Morawica' },
  { label: 'Moray', value: 'Moray' },
  { label: 'Morayfield', value: 'Morayfield' },
  { label: 'Morazán', value: 'Morazán' },
  { label: 'Morazán', value: 'Morazán' },
  { label: 'Morazzone', value: 'Morazzone' },
  { label: 'Morbach', value: 'Morbach' },
  { label: 'Morbecque', value: 'Morbecque' },
  { label: 'Morbegno', value: 'Morbegno' },
  { label: 'Morbello', value: 'Morbello' },
  { label: 'Morbi', value: 'Morbi' },
  { label: 'Morbier', value: 'Morbier' },
  { label: 'Morbihan', value: 'Morbihan' },
  { label: 'Morbio Inferiore', value: 'Morbio Inferiore' },
  { label: 'Mörbisch Am See', value: 'Mörbisch Am See' },
  { label: 'Mörbylånga', value: 'Mörbylånga' },
  { label: 'Morcellement Saint André', value: 'Morcellement Saint André' },
  { label: 'Morcenx', value: 'Morcenx' },
  { label: 'Morchard Bishop', value: 'Morchard Bishop' },
  { label: 'Morciano Di Leuca', value: 'Morciano Di Leuca' },
  { label: 'Morciano Di Romagna', value: 'Morciano Di Romagna' },
  { label: 'Morcillo', value: 'Morcillo' },
  { label: 'Morcín', value: 'Morcín' },
  { label: 'Morcone', value: 'Morcone' },
  { label: 'Morcott', value: 'Morcott' },
  { label: 'Mordano', value: 'Mordano' },
  { label: 'Mordelles', value: 'Mordelles' },
  { label: 'Morden', value: 'Morden' },
  { label: 'Morden', value: 'Morden' },
  { label: 'Mordialloc', value: 'Mordialloc' },
  { label: 'Mordino', value: 'Mordino' },
  { label: 'Mordovo', value: 'Mordovo' },
  { label: 'Mordy', value: 'Mordy' },
  { label: 'Moréac', value: 'Moréac' },
  { label: 'Moreau', value: 'Moreau' },
  { label: 'Morecambe', value: 'Morecambe' },
  { label: 'Moreda De Álava/Moreda Araba', value: 'Moreda De Álava/Moreda Araba' },
  { label: 'Moree', value: 'Moree' },
  { label: 'Morée', value: 'Morée' },
  { label: 'Moree Plains', value: 'Moree Plains' },
  { label: 'Morehead', value: 'Morehead' },
  { label: 'Morehead', value: 'Morehead' },
  { label: 'Morehead City', value: 'Morehead City' },
  { label: 'Morehouse Parish', value: 'Morehouse Parish' },
  { label: 'Moreilândia', value: 'Moreilândia' },
  { label: 'Moreira', value: 'Moreira' },
  { label: 'Moreira De Conegos', value: 'Moreira De Conegos' },
  { label: 'Moreira De Rei', value: 'Moreira De Rei' },
  { label: 'Moreira Sales', value: 'Moreira Sales' },
  { label: 'Morelábor', value: 'Morelábor' },
  { label: 'Moreland', value: 'Moreland' },
  { label: 'Moreland', value: 'Moreland' },
  { label: 'Moreland Hills', value: 'Moreland Hills' },
  { label: 'Morelia', value: 'Morelia' },
  { label: 'Morelia', value: 'Morelia' },
  { label: 'Morelia (Victórico Rodolfo Grajales)', value: 'Morelia (Victórico Rodolfo Grajales)' },
  { label: 'Morelita', value: 'Morelita' },
  { label: 'Morell', value: 'Morell' },
  { label: 'Morella', value: 'Morella' },
  { label: 'Morelos', value: 'Morelos' },
  { label: 'Morelos', value: 'Morelos' },
  { label: 'Morelos', value: 'Morelos' },
  { label: 'Morelos', value: 'Morelos' },
  { label: 'Morelos', value: 'Morelos' },
  { label: 'Morelos', value: 'Morelos' },
  { label: 'Morelos Cañada', value: 'Morelos Cañada' },
  { label: 'Morena', value: 'Morena' },
  { label: 'Morenci', value: 'Morenci' },
  { label: 'Morenci', value: 'Morenci' },
  { label: 'Morengo', value: 'Morengo' },
  { label: 'Moreni', value: 'Moreni' },
  { label: 'Morenilla', value: 'Morenilla' },
  { label: 'Moreno', value: 'Moreno' },
  { label: 'Moreno Valley', value: 'Moreno Valley' },
  { label: 'Morentin', value: 'Morentin' },
  { label: 'Morera De Montsant', value: 'Morera De Montsant' },
  { label: 'Morera La', value: 'Morera La' },
  { label: 'Moreruela De Los Infanzones', value: 'Moreruela De Los Infanzones' },
  { label: 'Moreruela De Tábara', value: 'Moreruela De Tábara' },
  { label: 'Mores', value: 'Mores' },
  { label: 'Morés', value: 'Morés' },
  { label: 'Moresco', value: 'Moresco' },
  { label: 'Morestel', value: 'Morestel' },
  { label: 'Morești', value: 'Morești' },
  { label: 'Moreton', value: 'Moreton' },
  { label: 'Moreton Bay', value: 'Moreton Bay' },
  { label: 'Moreton In Marsh', value: 'Moreton In Marsh' },
  { label: 'Moretonhampstead', value: 'Moretonhampstead' },
  { label: 'Moretown', value: 'Moretown' },
  { label: 'Moret-Sur-Loing', value: 'Moret-Sur-Loing' },
  { label: 'Moretta', value: 'Moretta' },
  { label: 'Moreuil', value: 'Moreuil' },
  { label: 'Morez', value: 'Morez' },
  { label: 'Morfasso', value: 'Morfasso' },
  { label: 'Mörfelden-Walldorf', value: 'Mörfelden-Walldorf' },
  { label: 'Mórfou', value: 'Mórfou' },
  { label: 'Morfovoúni', value: 'Morfovoúni' },
  { label: 'Morga', value: 'Morga' },
  { label: 'Morgan', value: 'Morgan' },
  { label: 'Morgan', value: 'Morgan' },
  { label: 'Morgan City', value: 'Morgan City' },
  { label: 'Morgan County', value: 'Morgan County' },
  { label: 'Morgan County', value: 'Morgan County' },
  { label: 'Morgan County', value: 'Morgan County' },
  { label: 'Morgan County', value: 'Morgan County' },
  { label: 'Morgan County', value: 'Morgan County' },
  { label: 'Morgan County', value: 'Morgan County' },
  { label: 'Morgan County', value: 'Morgan County' },
  { label: 'Morgan County', value: 'Morgan County' },
  { label: 'Morgan County', value: 'Morgan County' },
  { label: 'Morgan County', value: 'Morgan County' },
  { label: 'Morgan County', value: 'Morgan County' },
  { label: 'Morgan Hill', value: 'Morgan Hill' },
  { label: 'Morgan Park', value: 'Morgan Park' },
  { label: 'Morgandale', value: 'Morgandale' },
  { label: 'Morganfield', value: 'Morganfield' },
  { label: 'Morgans Forest', value: 'Morgans Forest' },
  { label: 'Morgans Pass', value: 'Morgans Pass' },
  { label: 'Morgans Point Resort', value: 'Morgans Point Resort' },
  { label: 'Morganton', value: 'Morganton' },
  { label: 'Morgantown', value: 'Morgantown' },
  { label: 'Morgantown', value: 'Morgantown' },
  { label: 'Morgantown', value: 'Morgantown' },
  { label: 'Morganville', value: 'Morganville' },
  { label: 'Morgaushi', value: 'Morgaushi' },
  { label: 'Morgaushskiy Rayon', value: 'Morgaushskiy Rayon' },
  { label: 'Morges', value: 'Morges' },
  { label: 'Morges District', value: 'Morges District' },
  { label: 'Morgex', value: 'Morgex' },
  { label: 'Morghabad', value: 'Morghabad' },
  { label: 'Morgongåva', value: 'Morgongåva' },
  { label: 'Morgongiori', value: 'Morgongiori' },
  { label: 'Morhange', value: 'Morhange' },
  { label: 'Mori', value: 'Mori' },
  { label: 'Mori', value: 'Mori' },
  { label: 'Móri Járás', value: 'Móri Járás' },
  { label: 'Moriago Della Battaglia', value: 'Moriago Della Battaglia' },
  { label: 'Moriarty', value: 'Moriarty' },
  { label: 'Morichal', value: 'Morichal' },
  { label: 'Moriches', value: 'Moriches' },
  { label: 'Moricone', value: 'Moricone' },
  { label: 'Morienval', value: 'Morienval' },
  { label: 'Morières-Lès-Avignon', value: 'Morières-Lès-Avignon' },
  { label: 'Morigaon', value: 'Morigaon' },
  { label: 'Morigerati', value: 'Morigerati' },
  { label: 'Morigny-Champigny', value: 'Morigny-Champigny' },
  { label: 'Moriguchi', value: 'Moriguchi' },
  { label: 'Moriki', value: 'Moriki' },
  { label: 'Moriles', value: 'Moriles' },
  { label: 'Morille', value: 'Morille' },
  { label: 'Morimondo', value: 'Morimondo' },
  { label: 'Morinda', value: 'Morinda' },
  { label: 'Moringen', value: 'Moringen' },
  { label: 'Morin-Heights', value: 'Morin-Heights' },
  { label: 'Moríñigo', value: 'Moríñigo' },
  { label: 'Morino', value: 'Morino' },
  { label: 'Morinville', value: 'Morinville' },
  { label: 'Morioka', value: 'Morioka' },
  { label: 'Morioka-Shi', value: 'Morioka-Shi' },
  { label: 'Moriondo Torinese', value: 'Moriondo Torinese' },
  { label: 'Moriones', value: 'Moriones' },
  { label: 'Moriones', value: 'Moriones' },
  { label: 'Moris', value: 'Moris' },
  { label: 'Moriscos', value: 'Moriscos' },
  { label: 'Morisset', value: 'Morisset' },
  { label: 'Moritzburg', value: 'Moritzburg' },
  { label: 'Moriya', value: 'Moriya' },
  { label: 'Moriyama', value: 'Moriyama' },
  { label: 'Moriyama-Shi', value: 'Moriyama-Shi' },
  { label: 'Moriya-Shi', value: 'Moriya-Shi' },
  { label: 'Morje', value: 'Morje' },
  { label: 'Morjim', value: 'Morjim' },
  { label: 'Morki', value: 'Morki' },
  { label: 'Morkinskiy Rayon', value: 'Morkinskiy Rayon' },
  { label: 'Mořkov', value: 'Mořkov' },
  { label: 'Morlaas', value: 'Morlaas' },
  { label: 'Morlaix', value: 'Morlaix' },
  { label: 'Morlanwelz-Mariemont', value: 'Morlanwelz-Mariemont' },
  { label: 'Mörlenbach', value: 'Mörlenbach' },
  { label: 'Morley', value: 'Morley' },
  { label: 'Morley', value: 'Morley' },
  { label: 'Morlupo', value: 'Morlupo' },
  { label: 'Mormaço', value: 'Mormaço' },
  { label: 'Mormanno', value: 'Mormanno' },
  { label: 'Mormant', value: 'Mormant' },
  { label: 'Mormoiron', value: 'Mormoiron' },
  { label: 'Mormugao', value: 'Mormugao' },
  { label: 'Mornac', value: 'Mornac' },
  { label: 'Mornag', value: 'Mornag' },
  { label: 'Mornago', value: 'Mornago' },
  { label: 'Mornaguia', value: 'Mornaguia' },
  { label: 'Mornant', value: 'Mornant' },
  { label: 'Mornas', value: 'Mornas' },
  { label: 'Morne Assau/Babonneau', value: 'Morne Assau/Babonneau' },
  { label: 'Morne Bonin', value: 'Morne Bonin' },
  { label: 'Morne Cayenne', value: 'Morne Cayenne' },
  { label: 'Morne Ciseaux', value: 'Morne Ciseaux' },
  { label: 'Morne Citon', value: 'Morne Citon' },
  { label: 'Morne Dor', value: 'Morne Dor' },
  { label: 'Morne Dudon', value: 'Morne Dudon' },
  { label: 'Morne Gomier', value: 'Morne Gomier' },
  { label: 'Morne Jacques', value: 'Morne Jacques' },
  { label: 'Morne La Croix', value: 'Morne La Croix' },
  { label: 'Morne Lastic/Desruisseaux', value: 'Morne Lastic/Desruisseaux' },
  { label: 'Morne Le Blanc', value: 'Morne Le Blanc' },
  { label: 'Morne Panache', value: 'Morne Panache' },
  { label: 'Morne Road', value: 'Morne Road' },
  { label: 'Morne Rouge/Marc', value: 'Morne Rouge/Marc' },
  { label: 'Morne Sion', value: 'Morne Sion' },
  { label: 'Morne Vert', value: 'Morne Vert' },
  { label: 'Morne Vient', value: 'Morne Vient' },
  { label: 'Mornese', value: 'Mornese' },
  { label: 'Mornico', value: 'Mornico' },
  { label: 'Mornico Al Serio', value: 'Mornico Al Serio' },
  { label: 'Mornico Losana', value: 'Mornico Losana' },
  { label: 'Morningside', value: 'Morningside' },
  { label: 'Morningside', value: 'Morningside' },
  { label: 'Morningside', value: 'Morningside' },
  { label: 'Morningside Heights', value: 'Morningside Heights' },
  { label: 'Mornington', value: 'Mornington' },
  { label: 'Mornington', value: 'Mornington' },
  { label: 'Mornington', value: 'Mornington' },
  { label: 'Mornington Peninsula', value: 'Mornington Peninsula' },
  { label: 'Moro', value: 'Moro' },
  { label: 'Moro', value: 'Moro' },
  { label: 'Morobuan', value: 'Morobuan' },
  { label: 'Morocco', value: 'Morocco' },
  { label: 'Morocelí', value: 'Morocelí' },
  { label: 'Morococha', value: 'Morococha' },
  { label: 'Morocoy', value: 'Morocoy' },
  { label: 'Moroe', value: 'Moroe' },
  { label: 'Moroeni', value: 'Moroeni' },
  { label: 'Morogoro', value: 'Morogoro' },
  { label: 'Morohongō', value: 'Morohongō' },
  { label: 'Moroleón', value: 'Moroleón' },
  { label: 'Morolica', value: 'Morolica' },
  { label: 'Morolo', value: 'Morolo' },
  { label: 'Moron', value: 'Moron' },
  { label: 'Morón', value: 'Morón' },
  { label: 'Morón', value: 'Morón' },
  { label: 'Morón De Almazán', value: 'Morón De Almazán' },
  { label: 'Morón De La Frontera', value: 'Morón De La Frontera' },
  { label: 'Moroncarit', value: 'Moroncarit' },
  { label: 'Morong', value: 'Morong' },
  { label: 'Morong', value: 'Morong' },
  { label: 'Morong', value: 'Morong' },
  { label: 'Morongo Valley', value: 'Morongo Valley' },
  { label: 'Moroni', value: 'Moroni' },
  { label: 'Moroni', value: 'Moroni' },
  { label: 'Moronou', value: 'Moronou' },
  { label: 'Moronta', value: 'Moronta' },
  { label: 'Moros', value: 'Moros' },
  { label: 'Morosaglia', value: 'Morosaglia' },
  { label: 'Moroto', value: 'Moroto' },
  { label: 'Morovis', value: 'Morovis' },
  { label: 'Morovis', value: 'Morovis' },
  { label: 'Morowali Regency', value: 'Morowali Regency' },
  { label: 'Morozivka', value: 'Morozivka' },
  { label: 'Morozovsk', value: 'Morozovsk' },
  { label: 'Morozzo', value: 'Morozzo' },
  { label: 'Morpará', value: 'Morpará' },
  { label: 'Morpeth', value: 'Morpeth' },
  { label: 'Morpeth', value: 'Morpeth' },
  { label: 'Morphett Vale', value: 'Morphett Vale' },
  { label: 'Morphettville', value: 'Morphettville' },
  { label: 'Morra De Sanctis', value: 'Morra De Sanctis' },
  { label: 'Morre', value: 'Morre' },
  { label: 'Morretes', value: 'Morretes' },
  { label: 'Morrill County', value: 'Morrill County' },
  { label: 'Morrilton', value: 'Morrilton' },
  { label: 'Morrinhos', value: 'Morrinhos' },
  { label: 'Morrinhos', value: 'Morrinhos' },
  { label: 'Morrinhos Do Sul', value: 'Morrinhos Do Sul' },
  { label: 'Morris', value: 'Morris' },
  { label: 'Morris', value: 'Morris' },
  { label: 'Morris', value: 'Morris' },
  { label: 'Morris', value: 'Morris' },
  { label: 'Morris', value: 'Morris' },
  { label: 'Morris County', value: 'Morris County' },
  { label: 'Morris County', value: 'Morris County' },
  { label: 'Morris County', value: 'Morris County' },
  { label: 'Morris Heights', value: 'Morris Heights' },
  { label: 'Morris Park', value: 'Morris Park' },
  { label: 'Morris Plains', value: 'Morris Plains' },
  { label: 'Morrisania', value: 'Morrisania' },
  { label: 'Morrisburg', value: 'Morrisburg' },
  { label: 'Morrison', value: 'Morrison' },
  { label: 'Morrison', value: 'Morrison' },
  { label: 'Morrison County', value: 'Morrison County' },
  { label: 'Morrisonville', value: 'Morrisonville' },
  { label: 'Morrisonville', value: 'Morrisonville' },
  { label: 'Morristown', value: 'Morristown' },
  { label: 'Morristown', value: 'Morristown' },
  { label: 'Morristown', value: 'Morristown' },
  { label: 'Morristown', value: 'Morristown' },
  { label: 'Morrisville', value: 'Morrisville' },
  { label: 'Morrisville', value: 'Morrisville' },
  { label: 'Morrisville', value: 'Morrisville' },
  { label: 'Morrisville', value: 'Morrisville' },
  { label: 'Morrito', value: 'Morrito' },
  { label: 'Morro Agudo', value: 'Morro Agudo' },
  { label: 'Morro Agudo De Goiás', value: 'Morro Agudo De Goiás' },
  { label: 'Morro Bay', value: 'Morro Bay' },
  { label: 'Morro Cabeça No Tempo', value: 'Morro Cabeça No Tempo' },
  { label: 'Morro Da Cruz', value: 'Morro Da Cruz' },
  { label: 'Morro Da Fumaça', value: 'Morro Da Fumaça' },
  { label: 'Morro Da Garça', value: 'Morro Da Garça' },
  { label: 'Morro Dalba', value: 'Morro Dalba' },
  { label: 'Morro Do Chapéu', value: 'Morro Do Chapéu' },
  { label: 'Morro Do Chapéu Do Piauí', value: 'Morro Do Chapéu Do Piauí' },
  { label: 'Morro Do Pilar', value: 'Morro Do Pilar' },
  { label: 'Morro Doro', value: 'Morro Doro' },
  { label: 'Morro Grande', value: 'Morro Grande' },
  { label: 'Morro Reatino', value: 'Morro Reatino' },
  { label: 'Morro Redondo', value: 'Morro Redondo' },
  { label: 'Morro Reuter', value: 'Morro Reuter' },
  { label: 'Morroa', value: 'Morroa' },
  { label: 'Morrone Del Sannio', value: 'Morrone Del Sannio' },
  { label: 'Mórrope', value: 'Mórrope' },
  { label: 'Morropon', value: 'Morropon' },
  { label: 'Morros', value: 'Morros' },
  { label: 'Morrovalle', value: 'Morrovalle' },
  { label: 'Morrow', value: 'Morrow' },
  { label: 'Morrow', value: 'Morrow' },
  { label: 'Morrow County', value: 'Morrow County' },
  { label: 'Morrow County', value: 'Morrow County' },
  { label: 'Mörrum', value: 'Mörrum' },
  { label: 'Morsang-Sur-Orge', value: 'Morsang-Sur-Orge' },
  { label: 'Morsano', value: 'Morsano' },
  { label: 'Morsasco', value: 'Morsasco' },
  { label: 'Morsbach', value: 'Morsbach' },
  { label: 'Morsbach', value: 'Morsbach' },
  { label: 'Mörschwil', value: 'Mörschwil' },
  { label: 'Morschwiller-Le-Bas', value: 'Morschwiller-Le-Bas' },
  { label: 'Morshansk', value: 'Morshansk' },
  { label: 'Morshyn', value: 'Morshyn' },
  { label: 'Morsi', value: 'Morsi' },
  { label: 'Morskoye', value: 'Morskoye' },
  { label: 'Morsø Kommune', value: 'Morsø Kommune' },
  { label: 'Morsum', value: 'Morsum' },
  { label: 'Mortagne-Au-Perche', value: 'Mortagne-Au-Perche' },
  { label: 'Mortagne-Du-Nord', value: 'Mortagne-Du-Nord' },
  { label: 'Mortagne-Sur-Gironde', value: 'Mortagne-Sur-Gironde' },
  { label: 'Mortagne-Sur-Sèvre', value: 'Mortagne-Sur-Sèvre' },
  { label: 'Mortágua', value: 'Mortágua' },
  { label: 'Mortain', value: 'Mortain' },
  { label: 'Mortantsch', value: 'Mortantsch' },
  { label: 'Mortara', value: 'Mortara' },
  { label: 'Mortcerf', value: 'Mortcerf' },
  { label: 'Mortdale', value: 'Mortdale' },
  { label: 'Morteau', value: 'Morteau' },
  { label: 'Mortegliano', value: 'Mortegliano' },
  { label: 'Morteni', value: 'Morteni' },
  { label: 'Morterone', value: 'Morterone' },
  { label: 'Morteros', value: 'Morteros' },
  { label: 'Mortí', value: 'Mortí' },
  { label: 'Mortka', value: 'Mortka' },
  { label: 'Mortlake', value: 'Mortlake' },
  { label: 'Mortlake', value: 'Mortlake' },
  { label: 'Mörtnäs', value: 'Mörtnäs' },
  { label: 'Morton', value: 'Morton' },
  { label: 'Morton', value: 'Morton' },
  { label: 'Morton', value: 'Morton' },
  { label: 'Morton', value: 'Morton' },
  { label: 'Morton', value: 'Morton' },
  { label: 'Morton County', value: 'Morton County' },
  { label: 'Morton County', value: 'Morton County' },
  { label: 'Morton Grove', value: 'Morton Grove' },
  { label: 'Mortrée', value: 'Mortrée' },
  { label: 'Mörtschach', value: 'Mörtschach' },
  { label: 'Mortsel', value: 'Mortsel' },
  { label: 'Mortugaba', value: 'Mortugaba' },
  { label: 'Morungaba', value: 'Morungaba' },
  { label: 'Morunglav', value: 'Morunglav' },
  { label: 'Moruț', value: 'Moruț' },
  { label: 'Moruya', value: 'Moruya' },
  { label: 'Moruzzo', value: 'Moruzzo' },
  { label: 'Morva (Hadaf)', value: 'Morva (Hadaf)' },
  { label: 'Morvillars', value: 'Morvillars' },
  { label: 'Morwa', value: 'Morwa' },
  { label: 'Morwell', value: 'Morwell' },
  { label: 'Moryakovskiy Zaton', value: 'Moryakovskiy Zaton' },
  { label: 'Moryń', value: 'Moryń' },
  { label: 'Morzine', value: 'Morzine' },
  { label: 'Mos', value: 'Mos' },
  { label: 'Mós', value: 'Mós' },
  { label: 'Mosal’Sk', value: 'Mosal’Sk' },
  { label: 'Mosal’Skiy Rayon', value: 'Mosal’Skiy Rayon' },
  { label: 'Mosar', value: 'Mosar' },
  { label: 'Mosbach', value: 'Mosbach' },
  { label: 'Moscardón', value: 'Moscardón' },
  { label: 'Moscavide', value: 'Moscavide' },
  { label: 'Moscavide E Portela', value: 'Moscavide E Portela' },
  { label: 'Moscazzano', value: 'Moscazzano' },
  { label: 'Moschendorf', value: 'Moschendorf' },
  { label: 'Moscheri', value: 'Moscheri' },
  { label: 'Moschiano', value: 'Moschiano' },
  { label: 'Mosciano Santangelo', value: 'Mosciano Santangelo' },
  { label: 'Moscow', value: 'Moscow' },
  { label: 'Moscow', value: 'Moscow' },
  { label: 'Moscow', value: 'Moscow' },
  { label: 'Moscow Mills', value: 'Moscow Mills' },
  { label: 'Moscu', value: 'Moscu' },
  { label: 'Moscufo', value: 'Moscufo' },
  { label: 'Moselle', value: 'Moselle' },
  { label: 'Möser', value: 'Möser' },
  { label: 'Moses Lake', value: 'Moses Lake' },
  { label: 'Moses Lake North', value: 'Moses Lake North' },
  { label: 'Mosesgegh', value: 'Mosesgegh' },
  { label: 'Mosetse', value: 'Mosetse' },
  { label: 'Mosfellsbaer', value: 'Mosfellsbaer' },
  { label: 'Mosfellsbær', value: 'Mosfellsbær' },
  { label: 'Mosfilotí', value: 'Mosfilotí' },
  { label: 'Mosheim', value: 'Mosheim' },
  { label: 'Moshenskoy Rayon', value: 'Moshenskoy Rayon' },
  { label: 'Moshenskoye', value: 'Moshenskoye' },
  { label: 'Moshi', value: 'Moshi' },
  { label: 'Moshi', value: 'Moshi' },
  { label: 'Moshkovo', value: 'Moshkovo' },
  { label: 'Moshkovskiy Rayon', value: 'Moshkovskiy Rayon' },
  { label: 'Moshny', value: 'Moshny' },
  { label: 'Moshrageh', value: 'Moshrageh' },
  { label: 'Mosina', value: 'Mosina' },
  { label: 'Mosinee', value: 'Mosinee' },
  { label: 'Mositai', value: 'Mositai' },
  { label: 'Mosjøen', value: 'Mosjøen' },
  { label: 'Moskenes', value: 'Moskenes' },
  { label: 'Moskháton', value: 'Moskháton' },
  { label: 'Moskovskiy', value: 'Moskovskiy' },
  { label: 'Moskovskiy', value: 'Moskovskiy' },
  { label: 'Moskovskiy', value: 'Moskovskiy' },
  { label: 'Moskovskoye', value: 'Moskovskoye' },
  { label: 'Mosman', value: 'Mosman' },
  { label: 'Mosman Park', value: 'Mosman Park' },
  { label: 'Moşna', value: 'Moşna' },
  { label: 'Moşna', value: 'Moşna' },
  { label: 'Mosnang', value: 'Mosnang' },
  { label: 'Moșneni', value: 'Moșneni' },
  { label: 'Mosnigo', value: 'Mosnigo' },
  { label: 'Moşniţa Nouă', value: 'Moşniţa Nouă' },
  { label: 'Moșnița Veche', value: 'Moșnița Veche' },
  { label: 'Moso In Passiria', value: 'Moso In Passiria' },
  { label: 'Moşoaia', value: 'Moşoaia' },
  { label: 'Mosoc Cancha', value: 'Mosoc Cancha' },
  { label: 'Mosoc Llacta', value: 'Mosoc Llacta' },
  { label: 'Mosonmagyaróvár', value: 'Mosonmagyaróvár' },
  { label: 'Mosonmagyaróvári Járás', value: 'Mosonmagyaróvári Járás' },
  { label: 'Mosonszentmiklós', value: 'Mosonszentmiklós' },
  { label: 'Mosopa', value: 'Mosopa' },
  { label: 'Mošorin', value: 'Mošorin' },
  { label: 'Mosorrofa', value: 'Mosorrofa' },
  { label: 'Mospyne', value: 'Mospyne' },
  { label: 'Mosquera', value: 'Mosquera' },
  { label: 'Mosquera', value: 'Mosquera' },
  { label: 'Mosquero', value: 'Mosquero' },
  { label: 'Mosqueruela', value: 'Mosqueruela' },
  { label: 'Mosrentgen', value: 'Mosrentgen' },
  { label: 'Moss', value: 'Moss' },
  { label: 'Moss', value: 'Moss' },
  { label: 'Moss Beach', value: 'Moss Beach' },
  { label: 'Moss Bluff', value: 'Moss Bluff' },
  { label: 'Moss Point', value: 'Moss Point' },
  { label: 'Moss Vale', value: 'Moss Vale' },
  { label: 'Mossa', value: 'Mossa' },
  { label: 'Mossâmedes', value: 'Mossâmedes' },
  { label: 'Mossano', value: 'Mossano' },
  { label: 'Mossblown', value: 'Mossblown' },
  { label: 'Mossel Bay', value: 'Mossel Bay' },
  { label: 'Mossendjo', value: 'Mossendjo' },
  { label: 'Mössingen', value: 'Mössingen' },
  { label: 'Mossley', value: 'Mossley' },
  { label: 'Mossman', value: 'Mossman' },
  { label: 'Mosso', value: 'Mosso' },
  { label: 'Mosso Santa Maria', value: 'Mosso Santa Maria' },
  { label: 'Mossoró', value: 'Mossoró' },
  { label: 'Most', value: 'Most' },
  { label: 'Möst', value: 'Möst' },
  { label: 'Mosta', value: 'Mosta' },
  { label: 'Mostaganem', value: 'Mostaganem' },
  { label: 'Mostar', value: 'Mostar' },
  { label: 'Mostardas', value: 'Mostardas' },
  { label: 'Mostazal', value: 'Mostazal' },
  { label: 'Moste', value: 'Moste' },
  { label: 'Mosteirô', value: 'Mosteirô' },
  { label: 'Mosteiros', value: 'Mosteiros' },
  { label: 'Mostek', value: 'Mostek' },
  { label: 'Moșteni', value: 'Moșteni' },
  { label: 'Mosterhamn', value: 'Mosterhamn' },
  { label: 'Mostkovice', value: 'Mostkovice' },
  { label: 'Móstoles', value: 'Móstoles' },
  { label: 'Mostovskiy Rayon', value: 'Mostovskiy Rayon' },
  { label: 'Mostovskoy', value: 'Mostovskoy' },
  { label: 'Mosty', value: 'Mosty' },
  { label: 'Mosty', value: 'Mosty' },
  { label: 'Mosty', value: 'Mosty' },
  { label: 'Mosty U Jablunkova', value: 'Mosty U Jablunkova' },
  { label: 'Mostyn', value: 'Mostyn' },
  { label: 'Mostyska', value: 'Mostyska' },
  { label: 'Mosul', value: 'Mosul' },
  { label: 'Moșuni', value: 'Moșuni' },
  { label: 'Moszczanka', value: 'Moszczanka' },
  { label: 'Moszczenica', value: 'Moszczenica' },
  { label: 'Moszczenica', value: 'Moszczenica' },
  { label: 'Mota De Altarejos', value: 'Mota De Altarejos' },
  { label: 'Mota Del Cuervo', value: 'Mota Del Cuervo' },
  { label: 'Mota Del Marqués', value: 'Mota Del Marqués' },
  { label: 'Moțăieni', value: 'Moțăieni' },
  { label: 'Motal’', value: 'Motal’' },
  { label: 'Motala', value: 'Motala' },
  { label: 'Moţăţei', value: 'Moţăţei' },
  { label: 'Motavita', value: 'Motavita' },
  { label: 'Moţca', value: 'Moţca' },
  { label: 'Motcombe', value: 'Motcombe' },
  { label: 'Motegi', value: 'Motegi' },
  { label: 'Motema', value: 'Motema' },
  { label: 'Motete', value: 'Motete' },
  { label: 'Moth', value: 'Moth' },
  { label: 'Motherwell', value: 'Motherwell' },
  { label: 'Mothihari', value: 'Mothihari' },
  { label: 'Motilla Del Palancar', value: 'Motilla Del Palancar' },
  { label: 'Motilleja', value: 'Motilleja' },
  { label: 'Motiong', value: 'Motiong' },
  { label: 'Motiong', value: 'Motiong' },
  { label: 'Motley', value: 'Motley' },
  { label: 'Motley County', value: 'Motley County' },
  { label: 'Motomachi', value: 'Motomachi' },
  { label: 'Motomiya', value: 'Motomiya' },
  { label: 'Motomiya-Shi', value: 'Motomiya-Shi' },
  { label: 'Motoşeni', value: 'Motoşeni' },
  { label: 'Motosu-Shi', value: 'Motosu-Shi' },
  { label: 'Motovatha', value: 'Motovatha' },
  { label: 'Motovun', value: 'Motovun' },
  { label: 'Motovun-Montona', value: 'Motovun-Montona' },
  { label: 'Motovylivka', value: 'Motovylivka' },
  { label: 'Motoyama', value: 'Motoyama' },
  { label: 'Motozintla', value: 'Motozintla' },
  { label: 'Motrico', value: 'Motrico' },
  { label: 'Motrico', value: 'Motrico' },
  { label: 'Motril', value: 'Motril' },
  { label: 'Motru', value: 'Motru' },
  { label: 'Mott', value: 'Mott' },
  { label: 'Mott Haven', value: 'Mott Haven' },
  { label: 'Motta', value: 'Motta' },
  { label: 'Motta Baluffi', value: 'Motta Baluffi' },
  { label: 'Motta Camastra', value: 'Motta Camastra' },
  { label: 'Motta Daffermo', value: 'Motta Daffermo' },
  { label: 'Motta Dè Conti', value: 'Motta Dè Conti' },
  { label: 'Motta Di Livenza', value: 'Motta Di Livenza' },
  { label: 'Motta Montecorvino', value: 'Motta Montecorvino' },
  { label: 'Motta San Giovanni', value: 'Motta San Giovanni' },
  { label: 'Motta Santa Lucia', value: 'Motta Santa Lucia' },
  { label: 'Motta Santanastasia', value: 'Motta Santanastasia' },
  { label: 'Motta Visconti', value: 'Motta Visconti' },
  { label: 'Mottafollone', value: 'Mottafollone' },
  { label: 'Mottalciata', value: 'Mottalciata' },
  { label: 'Motteggiana', value: 'Motteggiana' },
  { label: 'Mottella', value: 'Mottella' },
  { label: 'Motten', value: 'Motten' },
  { label: 'Möttingen', value: 'Möttingen' },
  { label: 'Mottola', value: 'Mottola' },
  { label: 'Motuca', value: 'Motuca' },
  { label: 'Motueka', value: 'Motueka' },
  { label: 'Motul', value: 'Motul' },
  { label: 'Motupe', value: 'Motupe' },
  { label: 'Motygino', value: 'Motygino' },
  { label: 'Mötz', value: 'Mötz' },
  { label: 'Mötzing', value: 'Mötzing' },
  { label: 'Mötzingen', value: 'Mötzingen' },
  { label: 'Motzorongo', value: 'Motzorongo' },
  { label: 'Mouanko', value: 'Mouanko' },
  { label: 'Mouans-Sartoux', value: 'Mouans-Sartoux' },
  { label: 'Mouchamps', value: 'Mouchamps' },
  { label: 'Mouchard', value: 'Mouchard' },
  { label: 'Mouchin', value: 'Mouchin' },
  { label: 'Moudon', value: 'Moudon' },
  { label: 'Mouen', value: 'Mouen' },
  { label: 'Mougins', value: 'Mougins' },
  { label: 'Mougon', value: 'Mougon' },
  { label: 'Mouguerre', value: 'Mouguerre' },
  { label: 'Mouhijärvi', value: 'Mouhijärvi' },
  { label: 'Mouila', value: 'Mouila' },
  { label: 'Mouilleron-En-Pareds', value: 'Mouilleron-En-Pareds' },
  { label: 'Mouilleron-Le-Captif', value: 'Mouilleron-Le-Captif' },
  { label: 'Moulay', value: 'Moulay' },
  { label: 'Moulay Bouchta', value: 'Moulay Bouchta' },
  { label: 'Moulay Slissen', value: 'Moulay Slissen' },
  { label: 'Moulay-Yacoub', value: 'Moulay-Yacoub' },
  { label: 'Moulden', value: 'Moulden' },
  { label: 'Mouldsworth', value: 'Mouldsworth' },
  { label: 'Moule A Chique', value: 'Moule A Chique' },
  { label: 'Mouleydier', value: 'Mouleydier' },
  { label: 'Mouliets-Et-Villemartin', value: 'Mouliets-Et-Villemartin' },
  { label: 'Moulins', value: 'Moulins' },
  { label: 'Moulins-Engilbert', value: 'Moulins-Engilbert' },
  { label: 'Moulins-Lès-Metz', value: 'Moulins-Lès-Metz' },
  { label: 'Moulis-En-Médoc', value: 'Moulis-En-Médoc' },
  { label: 'Moúlki', value: 'Moúlki' },
  { label: 'Moult', value: 'Moult' },
  { label: 'Moulton', value: 'Moulton' },
  { label: 'Moulton Chapel', value: 'Moulton Chapel' },
  { label: 'Moultonborough', value: 'Moultonborough' },
  { label: 'Moultrie', value: 'Moultrie' },
  { label: 'Moultrie County', value: 'Moultrie County' },
  { label: 'Moulvibazar ', value: 'Moulvibazar ' },
  { label: 'Mounana', value: 'Mounana' },
  { label: 'Mound', value: 'Mound' },
  { label: 'Mound Bayou', value: 'Mound Bayou' },
  { label: 'Mound City', value: 'Mound City' },
  { label: 'Mound City', value: 'Mound City' },
  { label: 'Mound City', value: 'Mound City' },
  { label: 'Mound City', value: 'Mound City' },
  { label: 'Moundou', value: 'Moundou' },
  { label: 'Moundridge', value: 'Moundridge' },
  { label: 'Mounds', value: 'Mounds' },
  { label: 'Mounds View', value: 'Mounds View' },
  { label: 'Moundsville', value: 'Moundsville' },
  { label: 'Moundville', value: 'Moundville' },
  { label: 'Mount Airy', value: 'Mount Airy' },
  { label: 'Mount Airy', value: 'Mount Airy' },
  { label: 'Mount Airy', value: 'Mount Airy' },
  { label: 'Mount Airy', value: 'Mount Airy' },
  { label: 'Mount Airy', value: 'Mount Airy' },
  { label: 'Mount Albert', value: 'Mount Albert' },
  { label: 'Mount Alexander', value: 'Mount Alexander' },
  { label: 'Mount Angel', value: 'Mount Angel' },
  { label: 'Mount Annan', value: 'Mount Annan' },
  { label: 'Mount Arlington', value: 'Mount Arlington' },
  { label: 'Mount Austin', value: 'Mount Austin' },
  { label: 'Mount Ayr', value: 'Mount Ayr' },
  { label: 'Mount Barker', value: 'Mount Barker' },
  { label: 'Mount Barker', value: 'Mount Barker' },
  { label: 'Mount Brydges', value: 'Mount Brydges' },
  { label: 'Mount Buller', value: 'Mount Buller' },
  { label: 'Mount Carey', value: 'Mount Carey' },
  { label: 'Mount Carmel', value: 'Mount Carmel' },
  { label: 'Mount Carmel', value: 'Mount Carmel' },
  { label: 'Mount Carmel', value: 'Mount Carmel' },
  { label: 'Mount Carmel', value: 'Mount Carmel' },
  { label: 'Mount Carroll', value: 'Mount Carroll' },
  { label: 'Mount Claremont', value: 'Mount Claremont' },
  { label: 'Mount Clear', value: 'Mount Clear' },
  { label: 'Mount Clemens', value: 'Mount Clemens' },
  { label: 'Mount Cobb', value: 'Mount Cobb' },
  { label: 'Mount Colah', value: 'Mount Colah' },
  { label: 'Mount Compass', value: 'Mount Compass' },
  { label: 'Mount Coolum', value: 'Mount Coolum' },
  { label: 'Mount Cotton', value: 'Mount Cotton' },
  { label: 'Mount Crosby', value: 'Mount Crosby' },
  { label: 'Mount Dandenong', value: 'Mount Dandenong' },
  { label: 'Mount Darwin', value: 'Mount Darwin' },
  { label: 'Mount Dora', value: 'Mount Dora' },
  { label: 'Mount Druitt', value: 'Mount Druitt' },
  { label: 'Mount Duneed', value: 'Mount Duneed' },
  { label: 'Mount Eliza', value: 'Mount Eliza' },
  { label: 'Mount Ephraim', value: 'Mount Ephraim' },
  { label: 'Mount Evelyn', value: 'Mount Evelyn' },
  { label: 'Mount Gambier', value: 'Mount Gambier' },
  { label: 'Mount Gay-Shamrock', value: 'Mount Gay-Shamrock' },
  { label: 'Mount Gilead', value: 'Mount Gilead' },
  { label: 'Mount Gilead', value: 'Mount Gilead' },
  { label: 'Mount Gravatt', value: 'Mount Gravatt' },
  { label: 'Mount Gravatt East', value: 'Mount Gravatt East' },
  { label: 'Mount Greenwood', value: 'Mount Greenwood' },
  { label: 'Mount Hagen', value: 'Mount Hagen' },
  { label: 'Mount Hawke', value: 'Mount Hawke' },
  { label: 'Mount Hawthorn', value: 'Mount Hawthorn' },
  { label: 'Mount Healthy', value: 'Mount Healthy' },
  { label: 'Mount Healthy Heights', value: 'Mount Healthy Heights' },
  { label: 'Mount Helen', value: 'Mount Helen' },
  { label: 'Mount Helena', value: 'Mount Helena' },
  { label: 'Mount Hermon', value: 'Mount Hermon' },
  { label: 'Mount Hermon', value: 'Mount Hermon' },
  { label: 'Mount Holly', value: 'Mount Holly' },
  { label: 'Mount Holly', value: 'Mount Holly' },
  { label: 'Mount Holly', value: 'Mount Holly' },
  { label: 'Mount Holly Springs', value: 'Mount Holly Springs' },
  { label: 'Mount Hood Village', value: 'Mount Hood Village' },
  { label: 'Mount Hope', value: 'Mount Hope' },
  { label: 'Mount Horeb', value: 'Mount Horeb' },
  { label: 'Mount Horeb', value: 'Mount Horeb' },
  { label: 'Mount Hutton', value: 'Mount Hutton' },
  { label: 'Mount Ida', value: 'Mount Ida' },
  { label: 'Mount Isa', value: 'Mount Isa' },
  { label: 'Mount Ivy', value: 'Mount Ivy' },
  { label: 'Mount Jackson', value: 'Mount Jackson' },
  { label: 'Mount James', value: 'Mount James' },
  { label: 'Mount Joy', value: 'Mount Joy' },
  { label: 'Mount Juliet', value: 'Mount Juliet' },
  { label: 'Mount Keira', value: 'Mount Keira' },
  { label: 'Mount Kembla', value: 'Mount Kembla' },
  { label: 'Mount Kisco', value: 'Mount Kisco' },
  { label: 'Mount Kuring-Gai', value: 'Mount Kuring-Gai' },
  { label: 'Mount Laurel', value: 'Mount Laurel' },
  { label: 'Mount Lawley', value: 'Mount Lawley' },
  { label: 'Mount Lebanon', value: 'Mount Lebanon' },
  { label: 'Mount Lebanus', value: 'Mount Lebanus' },
  { label: 'Mount Lewis', value: 'Mount Lewis' },
  { label: 'Mount Lofty', value: 'Mount Lofty' },
  { label: 'Mount Louisa', value: 'Mount Louisa' },
  { label: 'Mount Low', value: 'Mount Low' },
  { label: 'Mount Macedon', value: 'Mount Macedon' },
  { label: 'Mount Magnet', value: 'Mount Magnet' },
  { label: 'Mount Marshall', value: 'Mount Marshall' },
  { label: 'Mount Martha', value: 'Mount Martha' },
  { label: 'Mount Melville', value: 'Mount Melville' },
  { label: 'Mount Merrion', value: 'Mount Merrion' },
  { label: 'Mount Morgan', value: 'Mount Morgan' },
  { label: 'Mount Moriah', value: 'Mount Moriah' },
  { label: 'Mount Morris', value: 'Mount Morris' },
  { label: 'Mount Morris', value: 'Mount Morris' },
  { label: 'Mount Morris', value: 'Mount Morris' },
  { label: 'Mount Morris', value: 'Mount Morris' },
  { label: 'Mount Nasura', value: 'Mount Nasura' },
  { label: 'Mount Nathan', value: 'Mount Nathan' },
  { label: 'Mount Nelson', value: 'Mount Nelson' },
  { label: 'Mount Olive', value: 'Mount Olive' },
  { label: 'Mount Olive', value: 'Mount Olive' },
  { label: 'Mount Olive', value: 'Mount Olive' },
  { label: 'Mount Oliver', value: 'Mount Oliver' },
  { label: 'Mount Olivet', value: 'Mount Olivet' },
  { label: 'Mount Olympus', value: 'Mount Olympus' },
  { label: 'Mount Ommaney', value: 'Mount Ommaney' },
  { label: 'Mount Orab', value: 'Mount Orab' },
  { label: 'Mount Ousley', value: 'Mount Ousley' },
  { label: 'Mount Pearl', value: 'Mount Pearl' },
  { label: 'Mount Penn', value: 'Mount Penn' },
  { label: 'Mount Peto', value: 'Mount Peto' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Pleasant', value: 'Mount Pleasant' },
  { label: 'Mount Plymouth', value: 'Mount Plymouth' },
  { label: 'Mount Pocono', value: 'Mount Pocono' },
  { label: 'Mount Pritchard', value: 'Mount Pritchard' },
  { label: 'Mount Prospect', value: 'Mount Prospect' },
  { label: 'Mount Pulaski', value: 'Mount Pulaski' },
  { label: 'Mount Rainier', value: 'Mount Rainier' },
  { label: 'Mount Remarkable', value: 'Mount Remarkable' },
  { label: 'Mount Repose', value: 'Mount Repose' },
  { label: 'Mount Richon', value: 'Mount Richon' },
  { label: 'Mount Riverview', value: 'Mount Riverview' },
  { label: 'Mount Saint Thomas', value: 'Mount Saint Thomas' },
  { label: 'Mount Salem', value: 'Mount Salem' },
  { label: 'Mount Shasta', value: 'Mount Shasta' },
  { label: 'Mount Sheridan', value: 'Mount Sheridan' },
  { label: 'Mount Simeon District', value: 'Mount Simeon District' },
  { label: 'Mount Sinai', value: 'Mount Sinai' },
  { label: 'Mount Sterling', value: 'Mount Sterling' },
  { label: 'Mount Sterling', value: 'Mount Sterling' },
  { label: 'Mount Sterling', value: 'Mount Sterling' },
  { label: 'Mount Stewart', value: 'Mount Stewart' },
  { label: 'Mount Stuart', value: 'Mount Stuart' },
  { label: 'Mount Tarcoola', value: 'Mount Tarcoola' },
  { label: 'Mount Union', value: 'Mount Union' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vernon', value: 'Mount Vernon' },
  { label: 'Mount Vista', value: 'Mount Vista' },
  { label: 'Mount Warren Park', value: 'Mount Warren Park' },
  { label: 'Mount Warrigal', value: 'Mount Warrigal' },
  { label: 'Mount Washington', value: 'Mount Washington' },
  { label: 'Mount Waverley', value: 'Mount Waverley' },
  { label: 'Mount Wolf', value: 'Mount Wolf' },
  { label: 'Mount Zion', value: 'Mount Zion' },
  { label: 'Mount Zion', value: 'Mount Zion' },
  { label: 'Mount Zion', value: 'Mount Zion' },
  { label: 'Mountain Ash', value: 'Mountain Ash' },
  { label: 'Mountain Brook', value: 'Mountain Brook' },
  { label: 'Mountain City', value: 'Mountain City' },
  { label: 'Mountain City', value: 'Mountain City' },
  { label: 'Mountain Creek', value: 'Mountain Creek' },
  { label: 'Mountain Green', value: 'Mountain Green' },
  { label: 'Mountain Grove', value: 'Mountain Grove' },
  { label: 'Mountain Home', value: 'Mountain Home' },
  { label: 'Mountain Home', value: 'Mountain Home' },
  { label: 'Mountain Home', value: 'Mountain Home' },
  { label: 'Mountain House', value: 'Mountain House' },
  { label: 'Mountain Iron', value: 'Mountain Iron' },
  { label: 'Mountain Lake', value: 'Mountain Lake' },
  { label: 'Mountain Lake Park', value: 'Mountain Lake Park' },
  { label: 'Mountain Lakes', value: 'Mountain Lakes' },
  { label: 'Mountain Lodge Park', value: 'Mountain Lodge Park' },
  { label: 'Mountain Park', value: 'Mountain Park' },
  { label: 'Mountain Province', value: 'Mountain Province' },
  { label: 'Mountain Ranch', value: 'Mountain Ranch' },
  { label: 'Mountain Road', value: 'Mountain Road' },
  { label: 'Mountain Top', value: 'Mountain Top' },
  { label: 'Mountain View', value: 'Mountain View' },
  { label: 'Mountain View', value: 'Mountain View' },
  { label: 'Mountain View', value: 'Mountain View' },
  { label: 'Mountain View', value: 'Mountain View' },
  { label: 'Mountain View', value: 'Mountain View' },
  { label: 'Mountain View', value: 'Mountain View' },
  { label: 'Mountain View Acres', value: 'Mountain View Acres' },
  { label: 'Mountain View Gardens', value: 'Mountain View Gardens' },
  { label: 'Mountain Village', value: 'Mountain Village' },
  { label: 'Mountainaire', value: 'Mountainaire' },
  { label: 'Mountainhome', value: 'Mountainhome' },
  { label: 'Mountainside', value: 'Mountainside' },
  { label: 'Mountainside', value: 'Mountainside' },
  { label: 'Mountlake Terrace', value: 'Mountlake Terrace' },
  { label: 'Mountmellick', value: 'Mountmellick' },
  { label: 'Mountrail County', value: 'Mountrail County' },
  { label: 'Mountrath', value: 'Mountrath' },
  { label: 'Mountsorrel', value: 'Mountsorrel' },
  { label: 'Mountville', value: 'Mountville' },
  { label: 'Moura', value: 'Moura' },
  { label: 'Moura', value: 'Moura' },
  { label: 'Mourão', value: 'Mourão' },
  { label: 'Mourenx', value: 'Mourenx' },
  { label: 'Mouret', value: 'Mouret' },
  { label: 'Mouriès', value: 'Mouriès' },
  { label: 'Mourmelon-Le-Grand', value: 'Mourmelon-Le-Grand' },
  { label: 'Mourniés', value: 'Mourniés' },
  { label: 'Mouroux', value: 'Mouroux' },
  { label: 'Mours', value: 'Mours' },
  { label: 'Mours-Saint-Eusèbe', value: 'Mours-Saint-Eusèbe' },
  { label: 'Mouscron', value: 'Mouscron' },
  { label: 'Mousiyan', value: 'Mousiyan' },
  { label: 'Moussac', value: 'Moussac' },
  { label: 'Moussan', value: 'Moussan' },
  { label: 'Moussoro', value: 'Moussoro' },
  { label: 'Moussy-Le-Neuf', value: 'Moussy-Le-Neuf' },
  { label: 'Moussy-Le-Vieux', value: 'Moussy-Le-Vieux' },
  { label: 'Moustoir-Ac', value: 'Moustoir-Ac' },
  { label: 'Moutfort', value: 'Moutfort' },
  { label: 'Mouthiers-Sur-Boëme', value: 'Mouthiers-Sur-Boëme' },
  { label: 'Moutier', value: 'Moutier' },
  { label: 'Moutiers', value: 'Moutiers' },
  { label: 'Moûtiers', value: 'Moûtiers' },
  { label: 'Moutiers-Les-Mauxfaits', value: 'Moutiers-Les-Mauxfaits' },
  { label: 'Moutnice', value: 'Moutnice' },
  { label: 'Moutsamoudou', value: 'Moutsamoudou' },
  { label: 'Mouttagiáka', value: 'Mouttagiáka' },
  { label: 'Mouvaux', value: 'Mouvaux' },
  { label: 'Mouxy', value: 'Mouxy' },
  { label: 'Mouy', value: 'Mouy' },
  { label: 'Mouzaïa', value: 'Mouzaïa' },
  { label: 'Mouzaki', value: 'Mouzaki' },
  { label: 'Mouzáki', value: 'Mouzáki' },
  { label: 'Mouzeil', value: 'Mouzeil' },
  { label: 'Mouzillon', value: 'Mouzillon' },
  { label: 'Mouzon', value: 'Mouzon' },
  { label: 'Mouzourás', value: 'Mouzourás' },
  { label: 'Movila', value: 'Movila' },
  { label: 'Movila Banului', value: 'Movila Banului' },
  { label: 'Movila Miresii', value: 'Movila Miresii' },
  { label: 'Movileni', value: 'Movileni' },
  { label: 'Movileni', value: 'Movileni' },
  { label: 'Movileni', value: 'Movileni' },
  { label: 'Moviliţa', value: 'Moviliţa' },
  { label: 'Moviliţa', value: 'Moviliţa' },
  { label: 'Moville', value: 'Moville' },
  { label: 'Moville', value: 'Moville' },
  { label: 'Mowad', value: 'Mowad' },
  { label: 'Mowbray', value: 'Mowbray' },
  { label: 'Mowbray Mountain', value: 'Mowbray Mountain' },
  { label: 'Mowdaran', value: 'Mowdaran' },
  { label: 'Moweaqua', value: 'Moweaqua' },
  { label: 'Mower County', value: 'Mower County' },
  { label: 'Moy', value: 'Moy' },
  { label: 'Moya', value: 'Moya' },
  { label: 'Moya', value: 'Moya' },
  { label: 'Moya', value: 'Moya' },
  { label: 'Moyahua De Estrada', value: 'Moyahua De Estrada' },
  { label: 'Moyale', value: 'Moyale' },
  { label: 'Moyamba', value: 'Moyamba' },
  { label: 'Moyamba District', value: 'Moyamba District' },
  { label: 'Moyaux', value: 'Moyaux' },
  { label: 'Moycullen', value: 'Moycullen' },
  { label: 'Moye', value: 'Moye' },
  { label: 'Moyenmoutier', value: 'Moyenmoutier' },
  { label: 'Moyeuvre-Grande', value: 'Moyeuvre-Grande' },
  { label: 'Moyne', value: 'Moyne' },
  { label: 'Moyo', value: 'Moyo' },
  { label: 'Moyoapan', value: 'Moyoapan' },
  { label: 'Moyobamba', value: 'Moyobamba' },
  { label: 'Moyock', value: 'Moyock' },
  { label: 'Moyogalpa', value: 'Moyogalpa' },
  { label: 'Moyos', value: 'Moyos' },
  { label: 'Moyotepec', value: 'Moyotepec' },
  { label: 'Moyotepec (Moyotepec De Juárez)', value: 'Moyotepec (Moyotepec De Juárez)' },
  { label: 'Moyotzingo', value: 'Moyotzingo' },
  { label: 'Moyrazès', value: 'Moyrazès' },
  { label: 'Moyross', value: 'Moyross' },
  { label: 'Moyuela', value: 'Moyuela' },
  { label: 'Moyuta', value: 'Moyuta' },
  { label: 'Moyynkum', value: 'Moyynkum' },
  { label: 'Moyynty', value: 'Moyynty' },
  { label: 'Moza Shahwala', value: 'Moza Shahwala' },
  { label: 'Mozac', value: 'Mozac' },
  { label: 'Mozăceni', value: 'Mozăceni' },
  { label: 'Mozárbez', value: 'Mozárbez' },
  { label: 'Mozarlândia', value: 'Mozarlândia' },
  { label: 'Mozdok', value: 'Mozdok' },
  { label: 'Mozé-Sur-Louet', value: 'Mozé-Sur-Louet' },
  { label: 'Mozhaysk', value: 'Mozhaysk' },
  { label: 'Mozhayskiy Rayon', value: 'Mozhayskiy Rayon' },
  { label: 'Mozhga', value: 'Mozhga' },
  { label: 'Mozirje', value: 'Mozirje' },
  { label: 'Mozomboa', value: 'Mozomboa' },
  { label: 'Mozon', value: 'Mozon' },
  { label: 'Mozoncillo', value: 'Mozoncillo' },
  { label: 'Mozonte', value: 'Mozonte' },
  { label: 'Mozota', value: 'Mozota' },
  { label: 'Mozzagrogna', value: 'Mozzagrogna' },
  { label: 'Mozzanica', value: 'Mozzanica' },
  { label: 'Mozzate', value: 'Mozzate' },
  { label: 'Mozzecane', value: 'Mozzecane' },
  { label: 'Mozzo', value: 'Mozzo' },
  { label: 'Mozzozzin Sur', value: 'Mozzozzin Sur' },
  { label: 'Mozzozzin Sur', value: 'Mozzozzin Sur' },
  { label: 'Mpanda', value: 'Mpanda' },
  { label: 'Mpigi', value: 'Mpigi' },
  { label: 'Mpika', value: 'Mpika' },
  { label: 'Mponela', value: 'Mponela' },
  { label: 'Mpongwe', value: 'Mpongwe' },
  { label: 'Mpophomeni', value: 'Mpophomeni' },
  { label: 'Mporokoso', value: 'Mporokoso' },
  { label: 'Mpraeso', value: 'Mpraeso' },
  { label: 'Mpuguso', value: 'Mpuguso' },
  { label: 'Mpulungu', value: 'Mpulungu' },
  { label: 'Mpumalanga', value: 'Mpumalanga' },
  { label: 'Mpwapwa', value: 'Mpwapwa' },
  { label: 'Mqabba', value: 'Mqabba' },
  { label: 'Mraclin', value: 'Mraclin' },
  { label: 'Mrągowo', value: 'Mrągowo' },
  { label: 'Mrákov', value: 'Mrákov' },
  { label: 'Mrakovo', value: 'Mrakovo' },
  { label: 'Mramani', value: 'Mramani' },
  { label: 'Mramor', value: 'Mramor' },
  { label: 'Mranggen', value: 'Mranggen' },
  { label: 'Mravince', value: 'Mravince' },
  { label: 'Mrémani', value: 'Mrémani' },
  { label: 'Mrganush', value: 'Mrganush' },
  { label: 'Mrgashat', value: 'Mrgashat' },
  { label: 'Mrgashen', value: 'Mrgashen' },
  { label: 'Mrgavan', value: 'Mrgavan' },
  { label: 'Mrgavet', value: 'Mrgavet' },
  { label: 'Mrkonjić Grad', value: 'Mrkonjić Grad' },
  { label: 'Mrkopalj', value: 'Mrkopalj' },
  { label: 'Mrocza', value: 'Mrocza' },
  { label: 'Mrozów', value: 'Mrozów' },
  { label: 'Mrozy', value: 'Mrozy' },
  { label: 'Mrzezino', value: 'Mrzezino' },
  { label: 'Mrzeżyno', value: 'Mrzeżyno' },
  { label: 'Msaken', value: 'Msaken' },
  { label: 'Msanga', value: 'Msanga' },
  { label: 'Mšeno', value: 'Mšeno' },
  { label: 'Mshinskaya', value: 'Mshinskaya' },
  { label: 'Msowero', value: 'Msowero' },
  { label: 'Mstera', value: 'Mstera' },
  { label: 'Mstikhino', value: 'Mstikhino' },
  { label: 'Mstów', value: 'Mstów' },
  { label: 'Mstsislaw', value: 'Mstsislaw' },
  { label: 'Mszana', value: 'Mszana' },
  { label: 'Mszana Dolna', value: 'Mszana Dolna' },
  { label: 'Mszana Górna', value: 'Mszana Górna' },
  { label: 'Mszczonów', value: 'Mszczonów' },
  { label: 'Mtakoudja', value: 'Mtakoudja' },
  { label: 'Mtama', value: 'Mtama' },
  { label: 'Mtambile', value: 'Mtambile' },
  { label: 'Mthatha', value: 'Mthatha' },
  { label: 'Mtimbira', value: 'Mtimbira' },
  { label: 'Mtinko', value: 'Mtinko' },
  { label: 'Mtito Andei', value: 'Mtito Andei' },
  { label: 'Mto Wa Mbu', value: 'Mto Wa Mbu' },
  { label: 'Mtsamdou', value: 'Mtsamdou' },
  { label: 'Mtsensk', value: 'Mtsensk' },
  { label: 'Mtskheta', value: 'Mtskheta' },
  { label: 'Mtubatuba', value: 'Mtubatuba' },
  { label: 'Mtwango', value: 'Mtwango' },
  { label: 'Mtwara', value: 'Mtwara' },
  { label: 'Mu‘Tamadīyat Ar Rudayyif', value: 'Mu‘Tamadīyat Ar Rudayyif' },
  { label: 'Mu‘Tamadīyat Manūbah', value: 'Mu‘Tamadīyat Manūbah' },
  { label: 'Mŭ’Minobod', value: 'Mŭ’Minobod' },
  { label: 'Muan', value: 'Muan' },
  { label: 'Muaná', value: 'Muaná' },
  { label: 'Muang Alsaphangthong', value: 'Muang Alsaphangthong' },
  { label: 'Muang Atsaphan', value: 'Muang Atsaphan' },
  { label: 'Muang Bachiangchaleunsook', value: 'Muang Bachiangchaleunsook' },
  { label: 'Muang Champasak', value: 'Muang Champasak' },
  { label: 'Muang Champhon', value: 'Muang Champhon' },
  { label: 'Muang Dakchung', value: 'Muang Dakchung' },
  { label: 'Muang Houayxay', value: 'Muang Houayxay' },
  { label: 'Muang Khaleum', value: 'Muang Khaleum' },
  { label: 'Muang Không', value: 'Muang Không' },
  { label: 'Muang Khôngxédôn', value: 'Muang Khôngxédôn' },
  { label: 'Muang Lakhonphéng', value: 'Muang Lakhonphéng' },
  { label: 'Muang Laman', value: 'Muang Laman' },
  { label: 'Muang Laongam', value: 'Muang Laongam' },
  { label: 'Muang Longxan', value: 'Muang Longxan' },
  { label: 'Muang Lop Buri District', value: 'Muang Lop Buri District' },
  { label: 'Muang Louang Namtha', value: 'Muang Louang Namtha' },
  { label: 'Muang Meung', value: 'Muang Meung' },
  { label: 'Muang Mounlapamôk', value: 'Muang Mounlapamôk' },
  { label: 'Muang Nong', value: 'Muang Nong' },
  { label: 'Muang Outhoumphon', value: 'Muang Outhoumphon' },
  { label: 'Muang Paktha', value: 'Muang Paktha' },
  { label: 'Muang Pakxé', value: 'Muang Pakxé' },
  { label: 'Muang Pakxong', value: 'Muang Pakxong' },
  { label: 'Muang Pathoumphon', value: 'Muang Pathoumphon' },
  { label: 'Muang Pha Oudôm', value: 'Muang Pha Oudôm' },
  { label: 'Muang Phin', value: 'Muang Phin' },
  { label: 'Muang Phôn-Hông', value: 'Muang Phôn-Hông' },
  { label: 'Muang Phônsavan', value: 'Muang Phônsavan' },
  { label: 'Muang Phônthong', value: 'Muang Phônthong' },
  { label: 'Muang Phouvong', value: 'Muang Phouvong' },
  { label: 'Muang Samakhixai', value: 'Muang Samakhixai' },
  { label: 'Muang Samouay', value: 'Muang Samouay' },
  { label: 'Muang Sanamxai', value: 'Muang Sanamxai' },
  { label: 'Muang Sanxai', value: 'Muang Sanxai' },
  { label: 'Muang Saravan', value: 'Muang Saravan' },
  { label: 'Muang Songkhon', value: 'Muang Songkhon' },
  { label: 'Muang Soukhouma', value: 'Muang Soukhouma' },
  { label: 'Muang Ta-Ôy', value: 'Muang Ta-Ôy' },
  { label: 'Muang Thakhèk', value: 'Muang Thakhèk' },
  { label: 'Muang Thapangthong', value: 'Muang Thapangthong' },
  { label: 'Muang Thatèng', value: 'Muang Thatèng' },
  { label: 'Muang Thathôm', value: 'Muang Thathôm' },
  { label: 'Muang Tônpheung', value: 'Muang Tônpheung' },
  { label: 'Muang Toumlan', value: 'Muang Toumlan' },
  { label: 'Muang Vapi', value: 'Muang Vapi' },
  { label: 'Muang Vilabouli', value: 'Muang Vilabouli' },
  { label: 'Muang Xaibouli', value: 'Muang Xaibouli' },
  { label: 'Muang Xaiséttha', value: 'Muang Xaiséttha' },
  { label: 'Muang Xanasômboun', value: 'Muang Xanasômboun' },
  { label: 'Muang Xay', value: 'Muang Xay' },
  { label: 'Muang Xayphoothong', value: 'Muang Xayphoothong' },
  { label: 'Muang Xônbouli', value: 'Muang Xônbouli' },
  { label: 'Muan-Gun', value: 'Muan-Gun' },
  { label: 'Muar', value: 'Muar' },
  { label: 'Mubarakpur', value: 'Mubarakpur' },
  { label: 'Mubende', value: 'Mubende' },
  { label: 'Mubende District', value: 'Mubende District' },
  { label: 'Mubi', value: 'Mubi' },
  { label: 'Muborak', value: 'Muborak' },
  { label: 'Muć', value: 'Muć' },
  { label: 'Mucajaí', value: 'Mucajaí' },
  { label: 'Mucambo', value: 'Mucambo' },
  { label: 'Mucarabones', value: 'Mucarabones' },
  { label: 'Muccia', value: 'Muccia' },
  { label: 'Much', value: 'Much' },
  { label: 'Much Hadham', value: 'Much Hadham' },
  { label: 'Much Wenlock', value: 'Much Wenlock' },
  { label: 'Muchea', value: 'Muchea' },
  { label: 'Mücheln', value: 'Mücheln' },
  { label: 'Muchesh', value: 'Muchesh' },
  { label: 'Muchkapskiy', value: 'Muchkapskiy' },
  { label: 'Mucientes', value: 'Mucientes' },
  { label: 'Mücka', value: 'Mücka' },
  { label: 'Muckendorf An Der Donau', value: 'Muckendorf An Der Donau' },
  { label: 'Múcsony', value: 'Múcsony' },
  { label: 'Mucugê', value: 'Mucugê' },
  { label: 'Muçum', value: 'Muçum' },
  { label: 'Mucumpiz', value: 'Mucumpiz' },
  { label: 'Mucur', value: 'Mucur' },
  { label: 'Mucurapo', value: 'Mucurapo' },
  { label: 'Mucuri', value: 'Mucuri' },
  { label: 'Mucurici', value: 'Mucurici' },
  { label: 'Mud', value: 'Mud' },
  { label: 'Mudá', value: 'Mudá' },
  { label: 'Mudaison', value: 'Mudaison' },
  { label: 'Mudanjiang', value: 'Mudanjiang' },
  { label: 'Mudanya', value: 'Mudanya' },
  { label: 'Mudarra La', value: 'Mudarra La' },
  { label: 'Mudau', value: 'Mudau' },
  { label: 'Mudbidri', value: 'Mudbidri' },
  { label: 'Muddebihal', value: 'Muddebihal' },
  { label: 'Müden', value: 'Müden' },
  { label: 'Mudersbach', value: 'Mudersbach' },
  { label: 'Mudgal', value: 'Mudgal' },
  { label: 'Mudgee', value: 'Mudgee' },
  { label: 'Mudgeeraba', value: 'Mudgeeraba' },
  { label: 'Mudgere', value: 'Mudgere' },
  { label: 'Mudhaykhirah', value: 'Mudhaykhirah' },
  { label: 'Mudhol', value: 'Mudhol' },
  { label: 'Mudiyah', value: 'Mudiyah' },
  { label: 'Mudjimba', value: 'Mudjimba' },
  { label: 'Mudkhed', value: 'Mudkhed' },
  { label: 'Mudon', value: 'Mudon' },
  { label: 'Mudu', value: 'Mudu' },
  { label: 'Muduex', value: 'Muduex' },
  { label: 'Mudukulattur', value: 'Mudukulattur' },
  { label: 'Mudurnu', value: 'Mudurnu' },
  { label: 'Mudzi District', value: 'Mudzi District' },
  { label: 'Mueang Chonburi District', value: 'Mueang Chonburi District' },
  { label: 'Mueang Nonthaburi', value: 'Mueang Nonthaburi' },
  { label: 'Muel', value: 'Muel' },
  { label: 'Muela La', value: 'Muela La' },
  { label: 'Muelas De Los Caballeros', value: 'Muelas De Los Caballeros' },
  { label: 'Muelas Del Pan', value: 'Muelas Del Pan' },
  { label: 'Muelle De Los Bueyes', value: 'Muelle De Los Bueyes' },
  { label: 'Muenster', value: 'Muenster' },
  { label: 'Muereasca', value: 'Muereasca' },
  { label: 'Mues', value: 'Mues' },
  { label: 'Muff', value: 'Muff' },
  { label: 'Mufulira', value: 'Mufulira' },
  { label: 'Mufumbwe', value: 'Mufumbwe' },
  { label: 'Muga De Sayago', value: 'Muga De Sayago' },
  { label: 'Mugango', value: 'Mugango' },
  { label: 'Mugardos', value: 'Mugardos' },
  { label: 'Mügeln', value: 'Mügeln' },
  { label: 'Mugeni', value: 'Mugeni' },
  { label: 'Müggelheim', value: 'Müggelheim' },
  { label: 'Muggendorf', value: 'Muggendorf' },
  { label: 'Muggensturm', value: 'Muggensturm' },
  { label: 'Muggia', value: 'Muggia' },
  { label: 'Muggiò', value: 'Muggiò' },
  { label: 'Mughal Sarai', value: 'Mughal Sarai' },
  { label: 'Mughan', value: 'Mughan' },
  { label: 'Mugi', value: 'Mugi' },
  { label: 'Mugia', value: 'Mugia' },
  { label: 'Mugma', value: 'Mugma' },
  { label: 'Mugnano Del Cardinale', value: 'Mugnano Del Cardinale' },
  { label: 'Mugnano Di Napoli', value: 'Mugnano Di Napoli' },
  { label: 'Mugron', value: 'Mugron' },
  { label: 'Mugu', value: 'Mugu' },
  { label: 'Mugumu', value: 'Mugumu' },
  { label: 'Mugun', value: 'Mugun' },
  { label: 'Mugur-Aksy', value: 'Mugur-Aksy' },
  { label: 'Muhammadabad', value: 'Muhammadabad' },
  { label: 'Muhen', value: 'Muhen' },
  { label: 'Muheza', value: 'Muheza' },
  { label: 'Mühl Rosin', value: 'Mühl Rosin' },
  { label: 'Mühlacker', value: 'Mühlacker' },
  { label: 'Mühlanger', value: 'Mühlanger' },
  { label: 'Mühlau', value: 'Mühlau' },
  { label: 'Mühlau', value: 'Mühlau' },
  { label: 'Mühlbach Am Hochkönig', value: 'Mühlbach Am Hochkönig' },
  { label: 'Mühlbachl', value: 'Mühlbachl' },
  { label: 'Mühlberg', value: 'Mühlberg' },
  { label: 'Mühldorf', value: 'Mühldorf' },
  { label: 'Mühldorf', value: 'Mühldorf' },
  { label: 'Mühldorf', value: 'Mühldorf' },
  { label: 'Mühldorf', value: 'Mühldorf' },
  { label: 'Mühldorf Bei Feldbach', value: 'Mühldorf Bei Feldbach' },
  { label: 'Mühleberg', value: 'Mühleberg' },
  { label: 'Mühlehalde', value: 'Mühlehalde' },
  { label: 'Mühlen', value: 'Mühlen' },
  { label: 'Mühlen Eichsen', value: 'Mühlen Eichsen' },
  { label: 'Mühlen In Taufers', value: 'Mühlen In Taufers' },
  { label: 'Mühlenbach', value: 'Mühlenbach' },
  { label: 'Muhlenberg County', value: 'Muhlenberg County' },
  { label: 'Muhlenberg Park', value: 'Muhlenberg Park' },
  { label: 'Mühlethurnen', value: 'Mühlethurnen' },
  { label: 'Mühlgraben', value: 'Mühlgraben' },
  { label: 'Mühlhausen', value: 'Mühlhausen' },
  { label: 'Mühlhausen', value: 'Mühlhausen' },
  { label: 'Mühlhausen', value: 'Mühlhausen' },
  { label: 'Mühlhausen-Ehingen', value: 'Mühlhausen-Ehingen' },
  { label: 'Mühlheim Am Bach', value: 'Mühlheim Am Bach' },
  { label: 'Mühlheim Am Main', value: 'Mühlheim Am Main' },
  { label: 'Mühlingen', value: 'Mühlingen' },
  { label: 'Mühltroff', value: 'Mühltroff' },
  { label: 'Muhoroni', value: 'Muhoroni' },
  { label: 'Muhororo', value: 'Muhororo' },
  { label: 'Muhos', value: 'Muhos' },
  { label: 'Muhr Am See', value: 'Muhr Am See' },
  { label: 'Muhu Vald', value: 'Muhu Vald' },
  { label: 'Muiden', value: 'Muiden' },
  { label: 'Muiderberg', value: 'Muiderberg' },
  { label: 'Muides-Sur-Loire', value: 'Muides-Sur-Loire' },
  { label: 'Muikamachi', value: 'Muikamachi' },
  { label: 'Muiños', value: 'Muiños' },
  { label: 'Muir Of Ord', value: 'Muir Of Ord' },
  { label: 'Muirhead', value: 'Muirhead' },
  { label: 'Muirhead', value: 'Muirhead' },
  { label: 'Muirkirk', value: 'Muirkirk' },
  { label: 'Muisne', value: 'Muisne' },
  { label: 'Muitos Capões', value: 'Muitos Capões' },
  { label: 'Muizon', value: 'Muizon' },
  { label: 'Mujiayingzi', value: 'Mujiayingzi' },
  { label: 'Muju-Gun', value: 'Muju-Gun' },
  { label: 'Mukacheve', value: 'Mukacheve' },
  { label: 'Mukachevo Raion', value: 'Mukachevo Raion' },
  { label: 'Mukah', value: 'Mukah' },
  { label: 'Mukalla', value: 'Mukalla' },
  { label: 'Mukambal', value: 'Mukambal' },
  { label: 'Mukařov', value: 'Mukařov' },
  { label: 'Mukayras', value: 'Mukayras' },
  { label: 'Mukdahan', value: 'Mukdahan' },
  { label: 'Mukerian', value: 'Mukerian' },
  { label: 'Mukharram Al Fawqānī', value: 'Mukharram Al Fawqānī' },
  { label: 'Mukhen', value: 'Mukhen' },
  { label: 'Mukher', value: 'Mukher' },
  { label: 'Mukhorshibir’', value: 'Mukhorshibir’' },
  { label: 'Mukhorshibirskiy Rayon', value: 'Mukhorshibirskiy Rayon' },
  { label: 'Mukhtolovo', value: 'Mukhtolovo' },
  { label: 'Mukilteo', value: 'Mukilteo' },
  { label: 'Mukinbudin', value: 'Mukinbudin' },
  { label: 'Mukō Shi', value: 'Mukō Shi' },
  { label: 'Mukono', value: 'Mukono' },
  { label: 'Mukteshwar', value: 'Mukteshwar' },
  { label: 'Mukwonago', value: 'Mukwonago' },
  { label: 'Mul', value: 'Mul' },
  { label: 'Mula', value: 'Mula' },
  { label: 'Muladbucad', value: 'Muladbucad' },
  { label: 'Mulambin', value: 'Mulambin' },
  { label: 'Mulanay', value: 'Mulanay' },
  { label: 'Mulanje', value: 'Mulanje' },
  { label: 'Mulanje District', value: 'Mulanje District' },
  { label: 'Mulanur', value: 'Mulanur' },
  { label: 'Mulatupo', value: 'Mulatupo' },
  { label: 'Mulauin', value: 'Mulauin' },
  { label: 'Mulayjah', value: 'Mulayjah' },
  { label: 'Mulazzano', value: 'Mulazzano' },
  { label: 'Mulazzo', value: 'Mulazzo' },
  { label: 'Mulbagal', value: 'Mulbagal' },
  { label: 'Mulbarton', value: 'Mulbarton' },
  { label: 'Mulberry', value: 'Mulberry' },
  { label: 'Mulberry', value: 'Mulberry' },
  { label: 'Mulberry', value: 'Mulberry' },
  { label: 'Mulberry', value: 'Mulberry' },
  { label: 'Mulberry', value: 'Mulberry' },
  { label: 'Mulchén', value: 'Mulchén' },
  { label: 'Mulda', value: 'Mulda' },
  { label: 'Muldenstein', value: 'Muldenstein' },
  { label: 'Muldersdriseloop', value: 'Muldersdriseloop' },
  { label: 'Muldrow', value: 'Muldrow' },
  { label: 'Mulegé', value: 'Mulegé' },
  { label: 'Muleshoe', value: 'Muleshoe' },
  { label: 'Mulfingen', value: 'Mulfingen' },
  { label: 'Mulgoa', value: 'Mulgoa' },
  { label: 'Mulgrave', value: 'Mulgrave' },
  { label: 'Mulgrave', value: 'Mulgrave' },
  { label: 'Mulgund', value: 'Mulgund' },
  { label: 'Mülheim', value: 'Mülheim' },
  { label: 'Mülheim-Kärlich', value: 'Mülheim-Kärlich' },
  { label: 'Mulher', value: 'Mulher' },
  { label: 'Mulhouse', value: 'Mulhouse' },
  { label: 'Muli', value: 'Muli' },
  { label: 'Mulifanua', value: 'Mulifanua' },
  { label: 'Mulino', value: 'Mulino' },
  { label: 'Mulino', value: 'Mulino' },
  { label: 'Mulino', value: 'Mulino' },
  { label: 'Muliterno', value: 'Muliterno' },
  { label: 'Mulki', value: 'Mulki' },
  { label: 'Mullagh', value: 'Mullagh' },
  { label: 'Mullaloo', value: 'Mullaloo' },
  { label: 'Mullen', value: 'Mullen' },
  { label: 'Müllendorf', value: 'Müllendorf' },
  { label: 'Müllendorf', value: 'Müllendorf' },
  { label: 'Mullens', value: 'Mullens' },
  { label: 'Mulleriyawa', value: 'Mulleriyawa' },
  { label: 'Müllheim', value: 'Müllheim' },
  { label: 'Müllheim', value: 'Müllheim' },
  { label: 'Mullica Hill', value: 'Mullica Hill' },
  { label: 'Mullins', value: 'Mullins' },
  { label: 'Mullion', value: 'Mullion' },
  { label: 'Mullovka', value: 'Mullovka' },
  { label: 'Müllrose', value: 'Müllrose' },
  { label: 'Mullsjö', value: 'Mullsjö' },
  { label: 'Mullumbimby', value: 'Mullumbimby' },
  { label: 'Mulsanne', value: 'Mulsanne' },
  { label: 'Mülsen', value: 'Mülsen' },
  { label: 'Multai', value: 'Multai' },
  { label: 'Multan', value: 'Multan' },
  { label: 'Multan District', value: 'Multan District' },
  { label: 'Multé', value: 'Multé' },
  { label: 'Multia', value: 'Multia' },
  { label: 'Multnomah County', value: 'Multnomah County' },
  { label: 'Mulund', value: 'Mulund' },
  { label: 'Mulungu', value: 'Mulungu' },
  { label: 'Mulungu', value: 'Mulungu' },
  { label: 'Mulungu Do Morro', value: 'Mulungu Do Morro' },
  { label: 'Muluppilagadu', value: 'Muluppilagadu' },
  { label: 'Mulvane', value: 'Mulvane' },
  { label: 'Mulwala', value: 'Mulwala' },
  { label: 'Mumbai', value: 'Mumbai' },
  { label: 'Mumbai Suburban', value: 'Mumbai Suburban' },
  { label: 'Mumbwa', value: 'Mumbwa' },
  { label: 'Mumford', value: 'Mumford' },
  { label: 'Mumias', value: 'Mumias' },
  { label: 'Mumpf', value: 'Mumpf' },
  { label: 'Mumra', value: 'Mumra' },
  { label: 'Muna', value: 'Muna' },
  { label: 'Müna', value: 'Müna' },
  { label: 'Munai', value: 'Munai' },
  { label: 'Munakata-Shi', value: 'Munakata-Shi' },
  { label: 'Muñana', value: 'Muñana' },
  { label: 'Munayshy', value: 'Munayshy' },
  { label: 'Munayzir', value: 'Munayzir' },
  { label: 'Muncar', value: 'Muncar' },
  { label: 'Muncelu De Sus', value: 'Muncelu De Sus' },
  { label: 'Münchberg', value: 'Münchberg' },
  { label: 'Müncheberg', value: 'Müncheberg' },
  { label: 'Münchenbernsdorf', value: 'Münchenbernsdorf' },
  { label: 'Münchenbuchsee', value: 'Münchenbuchsee' },
  { label: 'Münchendorf', value: 'Münchendorf' },
  { label: 'Münchenstein', value: 'Münchenstein' },
  { label: 'Münchenwiler', value: 'Münchenwiler' },
  { label: 'Münchhausen', value: 'Münchhausen' },
  { label: 'Munchhouse', value: 'Munchhouse' },
  { label: 'Münchsmünster', value: 'Münchsmünster' },
  { label: 'Münchsteinach', value: 'Münchsteinach' },
  { label: 'Münchweiler An Der Alsenz', value: 'Münchweiler An Der Alsenz' },
  { label: 'Münchweiler An Der Rodalbe', value: 'Münchweiler An Der Rodalbe' },
  { label: 'Münchwilen', value: 'Münchwilen' },
  { label: 'Münchwilen District', value: 'Münchwilen District' },
  { label: 'Muncie', value: 'Muncie' },
  { label: 'Muncy', value: 'Muncy' },
  { label: 'Mundaka', value: 'Mundaka' },
  { label: 'Mundaraq', value: 'Mundaraq' },
  { label: 'Mundargi', value: 'Mundargi' },
  { label: 'Mundaring', value: 'Mundaring' },
  { label: 'Munday', value: 'Munday' },
  { label: 'Mundelein', value: 'Mundelein' },
  { label: 'Mundelsheim', value: 'Mundelsheim' },
  { label: 'Mundemba', value: 'Mundemba' },
  { label: 'Munderfing', value: 'Munderfing' },
  { label: 'Munderkingen', value: 'Munderkingen' },
  { label: 'Mundesley', value: 'Mundesley' },
  { label: 'Mundford', value: 'Mundford' },
  { label: 'Mundgod', value: 'Mundgod' },
  { label: 'Mundi', value: 'Mundi' },
  { label: 'Mundijong', value: 'Mundijong' },
  { label: 'Mundingburra', value: 'Mundingburra' },
  { label: 'Mundo Novo', value: 'Mundo Novo' },
  { label: 'Mundo Novo', value: 'Mundo Novo' },
  { label: 'Mundo Novo', value: 'Mundo Novo' },
  { label: 'Mundo Nuevo', value: 'Mundo Nuevo' },
  { label: 'Mundolsheim', value: 'Mundolsheim' },
  { label: 'Mundoolun', value: 'Mundoolun' },
  { label: 'Mundra', value: 'Mundra' },
  { label: 'Mundubbera', value: 'Mundubbera' },
  { label: 'Munduk', value: 'Munduk' },
  { label: 'Mundwa', value: 'Mundwa' },
  { label: 'Mundybash', value: 'Mundybash' },
  { label: 'Mundys Corner', value: 'Mundys Corner' },
  { label: 'Munébrega', value: 'Munébrega' },
  { label: 'Munera', value: 'Munera' },
  { label: 'Munford', value: 'Munford' },
  { label: 'Munford', value: 'Munford' },
  { label: 'Munfordville', value: 'Munfordville' },
  { label: 'Mungaa', value: 'Mungaa' },
  { label: 'Mungaoli', value: 'Mungaoli' },
  { label: 'Mungeli', value: 'Mungeli' },
  { label: 'Munger', value: 'Munger' },
  { label: 'Mungia', value: 'Mungia' },
  { label: 'Mungo', value: 'Mungo' },
  { label: 'Mungo', value: 'Mungo' },
  { label: 'Mungra Badshahpur', value: 'Mungra Badshahpur' },
  { label: 'Mungwi', value: 'Mungwi' },
  { label: 'Mungyeong', value: 'Mungyeong' },
  { label: 'Mungyeong-Si', value: 'Mungyeong-Si' },
  { label: 'Munhall', value: 'Munhall' },
  { label: 'Munhoz', value: 'Munhoz' },
  { label: 'Munhoz De Melo', value: 'Munhoz De Melo' },
  { label: 'Munich', value: 'Munich' },
  { label: 'Municipality Of Delfin Albano', value: 'Municipality Of Delfin Albano' },
  { label: 'Municipality Of Indanan', value: 'Municipality Of Indanan' },
  { label: 'Municipality Of Lantawan', value: 'Municipality Of Lantawan' },
  { label: 'Municipality Of Pangutaran', value: 'Municipality Of Pangutaran' },
  { label: 'Municipality Of Sultan Gumander', value: 'Municipality Of Sultan Gumander' },
  { label: 'Municipality Of Tongkil', value: 'Municipality Of Tongkil' },
  { label: 'Municipio Autónomo Alto Orinoco', value: 'Municipio Autónomo Alto Orinoco' },
  { label: 'Municipio Barinas', value: 'Municipio Barinas' },
  { label: 'Municipio De Abreus', value: 'Municipio De Abreus' },
  { label: 'Municipio De Almolonga', value: 'Municipio De Almolonga' },
  { label: 'Municipio De Alotenango', value: 'Municipio De Alotenango' },
  { label: 'Municipio De Altagracia', value: 'Municipio De Altagracia' },
  { label: 'Municipio De Antigua Guatemala', value: 'Municipio De Antigua Guatemala' },
  { label: 'Municipio De Artemisa', value: 'Municipio De Artemisa' },
  { label: 'Municipio De Asunción Mita', value: 'Municipio De Asunción Mita' },
  { label: 'Municipio De Banes', value: 'Municipio De Banes' },
  { label: 'Municipio De Bauta', value: 'Municipio De Bauta' },
  { label: 'Municipio De Bayamo', value: 'Municipio De Bayamo' },
  { label: 'Municipio De Belén', value: 'Municipio De Belén' },
  { label: 'Municipio De Buenos Aires', value: 'Municipio De Buenos Aires' },
  { label: 'Municipio De Cabaiguán', value: 'Municipio De Cabaiguán' },
  { label: 'Municipio De Cabricán', value: 'Municipio De Cabricán' },
  { label: 'Municipio De Canillá', value: 'Municipio De Canillá' },
  { label: 'Municipio De Cantel', value: 'Municipio De Cantel' },
  { label: 'Municipio De Cárdenas', value: 'Municipio De Cárdenas' },
  { label: 'Municipio De Cárdenas', value: 'Municipio De Cárdenas' },
  { label: 'Municipio De Casillas', value: 'Municipio De Casillas' },
  { label: 'Municipio De Chajul', value: 'Municipio De Chajul' },
  { label: 'Municipio De Chicaman', value: 'Municipio De Chicaman' },
  { label: 'Municipio De Chiché', value: 'Municipio De Chiché' },
  { label: 'Municipio De Chichicastenango', value: 'Municipio De Chichicastenango' },
  { label: 'Municipio De Chinique', value: 'Municipio De Chinique' },
  { label: 'Municipio De Chiquimulilla', value: 'Municipio De Chiquimulilla' },
  { label: 'Municipio De Ciego De Ávila', value: 'Municipio De Ciego De Ávila' },
  { label: 'Municipio De Cienfuegos', value: 'Municipio De Cienfuegos' },
  { label: 'Municipio De Ciudad Vieja', value: 'Municipio De Ciudad Vieja' },
  { label: 'Municipio De Coatepeque', value: 'Municipio De Coatepeque' },
  { label: 'Municipio De Colomba', value: 'Municipio De Colomba' },
  { label: 'Municipio De Concepción Chiquirichapa', value: 'Municipio De Concepción Chiquirichapa' },
  { label: 'Municipio De Concepción Tutuapa', value: 'Municipio De Concepción Tutuapa' },
  { label: 'Municipio De Consolación Del Sur', value: 'Municipio De Consolación Del Sur' },
  { label: 'Municipio De Cunén', value: 'Municipio De Cunén' },
  { label: 'Municipio De Flores', value: 'Municipio De Flores' },
  { label: 'Municipio De Flores Costa Cuca', value: 'Municipio De Flores Costa Cuca' },
  { label: 'Municipio De Florida', value: 'Municipio De Florida' },
  { label: 'Municipio De Guanajay', value: 'Municipio De Guanajay' },
  { label: 'Municipio De Guane', value: 'Municipio De Guane' },
  { label: 'Municipio De Guantánamo', value: 'Municipio De Guantánamo' },
  { label: 'Municipio De Guazacapán', value: 'Municipio De Guazacapán' },
  { label: 'Municipio De Güines', value: 'Municipio De Güines' },
  { label: 'Municipio De Holguín', value: 'Municipio De Holguín' },
  { label: 'Municipio De Ixcan', value: 'Municipio De Ixcan' },
  { label: 'Municipio De Jalapa', value: 'Municipio De Jalapa' },
  { label: 'Municipio De Jatibonico', value: 'Municipio De Jatibonico' },
  { label: 'Municipio De Jocotenango', value: 'Municipio De Jocotenango' },
  { label: 'Municipio De Joyabaj', value: 'Municipio De Joyabaj' },
  { label: 'Municipio De La Palma', value: 'Municipio De La Palma' },
  { label: 'Municipio De Los Palacios', value: 'Municipio De Los Palacios' },
  { label: 'Municipio De Magdalena Milpas Altas', value: 'Municipio De Magdalena Milpas Altas' },
  { label: 'Municipio De Malacatán', value: 'Municipio De Malacatán' },
  { label: 'Municipio De Manzanillo', value: 'Municipio De Manzanillo' },
  { label: 'Municipio De Mariel', value: 'Municipio De Mariel' },
  { label: 'Municipio De Masatepe', value: 'Municipio De Masatepe' },
  { label: 'Municipio De Matanzas', value: 'Municipio De Matanzas' },
  { label: 'Municipio De Mataquescuintla', value: 'Municipio De Mataquescuintla' },
  { label: 'Municipio De Melena Del Sur', value: 'Municipio De Melena Del Sur' },
  { label: 'Municipio De Momostenango', value: 'Municipio De Momostenango' },
  { label: 'Municipio De Morales', value: 'Municipio De Morales' },
  { label: 'Municipio De Morón', value: 'Municipio De Morón' },
  { label: 'Municipio De Moyogalpa', value: 'Municipio De Moyogalpa' },
  { label: 'Municipio De Nahualá', value: 'Municipio De Nahualá' },
  { label: 'Municipio De Nandasmo', value: 'Municipio De Nandasmo' },
  { label: 'Municipio De Niquero', value: 'Municipio De Niquero' },
  { label: 'Municipio De Niquinohomo', value: 'Municipio De Niquinohomo' },
  { label: 'Municipio De Nuevitas', value: 'Municipio De Nuevitas' },
  { label: 'Municipio De Pachalum', value: 'Municipio De Pachalum' },
  { label: 'Municipio De Palma Soriano', value: 'Municipio De Palma Soriano' },
  { label: 'Municipio De Panajachel', value: 'Municipio De Panajachel' },
  { label: 'Municipio De Patzité', value: 'Municipio De Patzité' },
  { label: 'Municipio De Placetas', value: 'Municipio De Placetas' },
  { label: 'Municipio De Poptún', value: 'Municipio De Poptún' },
  { label: 'Municipio De Potosí', value: 'Municipio De Potosí' },
  { label: 'Municipio De Puerto Barrios', value: 'Municipio De Puerto Barrios' },
  { label: 'Municipio De Rivas', value: 'Municipio De Rivas' },
  { label: 'Municipio De Sabá', value: 'Municipio De Sabá' },
  { label: 'Municipio De San Andrés', value: 'Municipio De San Andrés' },
  { label: 'Municipio De San Andrés Sajcabajá', value: 'Municipio De San Andrés Sajcabajá' },
  { label: 'Municipio De San Antonio Ilotenango', value: 'Municipio De San Antonio Ilotenango' },
  { label: 'Municipio De San Antonio Suchitepéquez', value: 'Municipio De San Antonio Suchitepéquez' },
  { label: 'Municipio De San Benito', value: 'Municipio De San Benito' },
  { label: 'Municipio De San Cristóbal', value: 'Municipio De San Cristóbal' },
  { label: 'Municipio De San Felipe', value: 'Municipio De San Felipe' },
  { label: 'Municipio De San Francisco', value: 'Municipio De San Francisco' },
  { label: 'Municipio De San Francisco De La Paz', value: 'Municipio De San Francisco De La Paz' },
  { label: 'Municipio De San Francisco Del Norte', value: 'Municipio De San Francisco Del Norte' },
  { label: 'Municipio De San Jorge', value: 'Municipio De San Jorge' },
  { label: 'Municipio De San Juan Cotzal', value: 'Municipio De San Juan Cotzal' },
  { label: 'Municipio De San Juan De Oriente', value: 'Municipio De San Juan De Oriente' },
  { label: 'Municipio De San Juan Del Sur', value: 'Municipio De San Juan Del Sur' },
  { label: 'Municipio De San Juan Ostuncalco', value: 'Municipio De San Juan Ostuncalco' },
  { label: 'Municipio De San Marcos', value: 'Municipio De San Marcos' },
  { label: 'Municipio De San Miguel Panán', value: 'Municipio De San Miguel Panán' },
  { label: 'Municipio De San Pedro Jocopilas', value: 'Municipio De San Pedro Jocopilas' },
  { label: 'Municipio De Sancti Spíritus', value: 'Municipio De Sancti Spíritus' },
  { label: 'Municipio De Santa Ana', value: 'Municipio De Santa Ana' },
  { label: 'Municipio De Santa Bárbara', value: 'Municipio De Santa Bárbara' },
  { label: 'Municipio De Santa Catarina Palopó', value: 'Municipio De Santa Catarina Palopó' },
  { label: 'Municipio De Santa Clara', value: 'Municipio De Santa Clara' },
  { label: 'Municipio De Santa Cruz La Laguna', value: 'Municipio De Santa Cruz La Laguna' },
  { label: 'Municipio De Santa Lucía Milpas Altas', value: 'Municipio De Santa Lucía Milpas Altas' },
  { label: 'Municipio De Santa María Chiquimula', value: 'Municipio De Santa María Chiquimula' },
  { label: 'Municipio De Santa María De Jesús', value: 'Municipio De Santa María De Jesús' },
  { label: 'Municipio De Santiago De Cuba', value: 'Municipio De Santiago De Cuba' },
  { label: 'Municipio De Sayaxché', value: 'Municipio De Sayaxché' },
  { label: 'Municipio De Sipacapa', value: 'Municipio De Sipacapa' },
  { label: 'Municipio De Tejutla', value: 'Municipio De Tejutla' },
  { label: 'Municipio De Texiguat', value: 'Municipio De Texiguat' },
  { label: 'Municipio De Tola', value: 'Municipio De Tola' },
  { label: 'Municipio De Totonicapán', value: 'Municipio De Totonicapán' },
  { label: 'Municipio De Trinidad', value: 'Municipio De Trinidad' },
  { label: 'Municipio De Uspantán', value: 'Municipio De Uspantán' },
  { label: 'Municipio De Zacualpa', value: 'Municipio De Zacualpa' },
  { label: 'Municipio Independencia', value: 'Municipio Independencia' },
  { label: 'Municipio José Gregorio Monagas', value: 'Municipio José Gregorio Monagas' },
  { label: 'Municipio José María Vargas', value: 'Municipio José María Vargas' },
  { label: 'Municipio Libertador', value: 'Municipio Libertador' },
  { label: 'Municipio Los Taques', value: 'Municipio Los Taques' },
  { label: 'Municipio Maturín', value: 'Municipio Maturín' },
  { label: 'Municipio Miranda', value: 'Municipio Miranda' },
  { label: 'Municipio Padre Pedro Chien', value: 'Municipio Padre Pedro Chien' },
  { label: 'Municipio Pampanito', value: 'Municipio Pampanito' },
  { label: 'Municipio San Rafael De Carvajal', value: 'Municipio San Rafael De Carvajal' },
  { label: 'Municipio Uracoa', value: 'Municipio Uracoa' },
  { label: 'Municipio Valdez', value: 'Municipio Valdez' },
  { label: 'Municipiul  Adjud', value: 'Municipiul  Adjud' },
  { label: 'Municipiul  Codlea', value: 'Municipiul  Codlea' },
  { label: 'Municipiul  Lupeni', value: 'Municipiul  Lupeni' },
  { label: 'Municipiul  Topliţa', value: 'Municipiul  Topliţa' },
  { label: 'Municipiul  Vulcan', value: 'Municipiul  Vulcan' },
  { label: 'Municipiul Aiud', value: 'Municipiul Aiud' },
  { label: 'Municipiul Alba Iulia', value: 'Municipiul Alba Iulia' },
  { label: 'Municipiul Alexandria', value: 'Municipiul Alexandria' },
  { label: 'Municipiul Arad', value: 'Municipiul Arad' },
  { label: 'Municipiul Bacãu', value: 'Municipiul Bacãu' },
  { label: 'Municipiul Bãileşti', value: 'Municipiul Bãileşti' },
  { label: 'Municipiul Bârlad', value: 'Municipiul Bârlad' },
  { label: 'Municipiul Beiuş', value: 'Municipiul Beiuş' },
  { label: 'Municipiul Bistriţa', value: 'Municipiul Bistriţa' },
  { label: 'Municipiul Blaj', value: 'Municipiul Blaj' },
  { label: 'Municipiul Botoşani', value: 'Municipiul Botoşani' },
  { label: 'Municipiul Brad', value: 'Municipiul Brad' },
  { label: 'Municipiul Brãila', value: 'Municipiul Brãila' },
  { label: 'Municipiul Braşov', value: 'Municipiul Braşov' },
  { label: 'Municipiul Buzău', value: 'Municipiul Buzău' },
  { label: 'Municipiul Calafat', value: 'Municipiul Calafat' },
  { label: 'Municipiul Călăraşi', value: 'Municipiul Călăraşi' },
  { label: 'Municipiul Câmpia Turzii', value: 'Municipiul Câmpia Turzii' },
  { label: 'Municipiul Câmpina', value: 'Municipiul Câmpina' },
  { label: 'Municipiul Câmpulung', value: 'Municipiul Câmpulung' },
  { label: 'Municipiul Câmpulung Moldovenesc', value: 'Municipiul Câmpulung Moldovenesc' },
  { label: 'Municipiul Caracal', value: 'Municipiul Caracal' },
  { label: 'Municipiul Caransebeş', value: 'Municipiul Caransebeş' },
  { label: 'Municipiul Carei', value: 'Municipiul Carei' },
  { label: 'Municipiul Cluj-Napoca', value: 'Municipiul Cluj-Napoca' },
  { label: 'Municipiul Constanţa', value: 'Municipiul Constanţa' },
  { label: 'Municipiul Craiova', value: 'Municipiul Craiova' },
  { label: 'Municipiul Curtea De Argeș', value: 'Municipiul Curtea De Argeș' },
  { label: 'Municipiul Dej', value: 'Municipiul Dej' },
  { label: 'Municipiul Deva', value: 'Municipiul Deva' },
  { label: 'Municipiul Dorohoi', value: 'Municipiul Dorohoi' },
  { label: 'Municipiul Drãgãşani', value: 'Municipiul Drãgãşani' },
  { label: 'Municipiul Drobeta-Turnu Severin', value: 'Municipiul Drobeta-Turnu Severin' },
  { label: 'Municipiul Fãgãraş', value: 'Municipiul Fãgãraş' },
  { label: 'Municipiul Fãlticeni', value: 'Municipiul Fãlticeni' },
  { label: 'Municipiul Feteşti', value: 'Municipiul Feteşti' },
  { label: 'Municipiul Focşani', value: 'Municipiul Focşani' },
  { label: 'Municipiul Galaţi', value: 'Municipiul Galaţi' },
  { label: 'Municipiul Gheorgheni', value: 'Municipiul Gheorgheni' },
  { label: 'Municipiul Gherla', value: 'Municipiul Gherla' },
  { label: 'Municipiul Giurgiu', value: 'Municipiul Giurgiu' },
  { label: 'Municipiul Hunedoara', value: 'Municipiul Hunedoara' },
  { label: 'Municipiul Huşi', value: 'Municipiul Huşi' },
  { label: 'Municipiul Iaşi', value: 'Municipiul Iaşi' },
  { label: 'Municipiul Lugoj', value: 'Municipiul Lugoj' },
  { label: 'Municipiul Mangalia', value: 'Municipiul Mangalia' },
  { label: 'Municipiul Marghita', value: 'Municipiul Marghita' },
  { label: 'Municipiul Medgidia', value: 'Municipiul Medgidia' },
  { label: 'Municipiul Mediaş', value: 'Municipiul Mediaş' },
  { label: 'Municipiul Miercurea Ciuc', value: 'Municipiul Miercurea Ciuc' },
  { label: 'Municipiul Moineşti', value: 'Municipiul Moineşti' },
  { label: 'Municipiul Moreni', value: 'Municipiul Moreni' },
  { label: 'Municipiul Motru', value: 'Municipiul Motru' },
  { label: 'Municipiul Odorheiu Secuiesc', value: 'Municipiul Odorheiu Secuiesc' },
  { label: 'Municipiul Olteniţa', value: 'Municipiul Olteniţa' },
  { label: 'Municipiul Oneşti', value: 'Municipiul Oneşti' },
  { label: 'Municipiul Oradea', value: 'Municipiul Oradea' },
  { label: 'Municipiul Orãştie', value: 'Municipiul Orãştie' },
  { label: 'Municipiul Orşova', value: 'Municipiul Orşova' },
  { label: 'Municipiul Paşcani', value: 'Municipiul Paşcani' },
  { label: 'Municipiul Petroşani', value: 'Municipiul Petroşani' },
  { label: 'Municipiul Piatra-Neamţ', value: 'Municipiul Piatra-Neamţ' },
  { label: 'Municipiul Piteşti', value: 'Municipiul Piteşti' },
  { label: 'Municipiul Ploieşti', value: 'Municipiul Ploieşti' },
  { label: 'Municipiul Rãdãuţi', value: 'Municipiul Rãdãuţi' },
  { label: 'Municipiul Râmnicu Sãrat', value: 'Municipiul Râmnicu Sãrat' },
  { label: 'Municipiul Râmnicu Vâlcea', value: 'Municipiul Râmnicu Vâlcea' },
  { label: 'Municipiul Reşiţa', value: 'Municipiul Reşiţa' },
  { label: 'Municipiul Roman', value: 'Municipiul Roman' },
  { label: 'Municipiul Roșiorii De Vede', value: 'Municipiul Roșiorii De Vede' },
  { label: 'Municipiul Sãcele', value: 'Municipiul Sãcele' },
  { label: 'Municipiul Salonta', value: 'Municipiul Salonta' },
  { label: 'Municipiul Satu Mare', value: 'Municipiul Satu Mare' },
  { label: 'Municipiul Sebeş', value: 'Municipiul Sebeş' },
  { label: 'Municipiul Sfântu Gheorghe', value: 'Municipiul Sfântu Gheorghe' },
  { label: 'Municipiul Sibiu', value: 'Municipiul Sibiu' },
  { label: 'Municipiul Slatina', value: 'Municipiul Slatina' },
  { label: 'Municipiul Suceava', value: 'Municipiul Suceava' },
  { label: 'Municipiul Târgovişte', value: 'Municipiul Târgovişte' },
  { label: 'Municipiul Târgu Jiu', value: 'Municipiul Târgu Jiu' },
  { label: 'Municipiul Târgu Secuiesc', value: 'Municipiul Târgu Secuiesc' },
  { label: 'Municipiul Tecuci', value: 'Municipiul Tecuci' },
  { label: 'Municipiul Timişoara', value: 'Municipiul Timişoara' },
  { label: 'Municipiul Tulcea', value: 'Municipiul Tulcea' },
  { label: 'Municipiul Turda', value: 'Municipiul Turda' },
  { label: 'Municipiul Turnu Magurele', value: 'Municipiul Turnu Magurele' },
  { label: 'Municipiul Urziceni', value: 'Municipiul Urziceni' },
  { label: 'Municipiul Vaslui', value: 'Municipiul Vaslui' },
  { label: 'Municipiul Vatra Dornei', value: 'Municipiul Vatra Dornei' },
  { label: 'Municipiul Zalãu', value: 'Municipiul Zalãu' },
  { label: 'Muñico', value: 'Muñico' },
  { label: 'Muniesa', value: 'Muniesa' },
  { label: 'Munilla', value: 'Munilla' },
  { label: 'Munirabad', value: 'Munirabad' },
  { label: 'Munising', value: 'Munising' },
  { label: 'Munitepec De Madero', value: 'Munitepec De Madero' },
  { label: 'Munitibar-Arbatzegi Gerrikaitz', value: 'Munitibar-Arbatzegi Gerrikaitz' },
  { label: 'Muniz', value: 'Muniz' },
  { label: 'Muniz Ferreira', value: 'Muniz Ferreira' },
  { label: 'Muniz Freire', value: 'Muniz Freire' },
  { label: 'Munka-Ljungby', value: 'Munka-Ljungby' },
  { label: 'Munkbrarup', value: 'Munkbrarup' },
  { label: 'Munkebo', value: 'Munkebo' },
  { label: 'Munkedal', value: 'Munkedal' },
  { label: 'Munkfors', value: 'Munkfors' },
  { label: 'Munkkiniemi', value: 'Munkkiniemi' },
  { label: 'Munnar', value: 'Munnar' },
  { label: 'Münnerstadt', value: 'Münnerstadt' },
  { label: 'Munningen', value: 'Munningen' },
  { label: 'Munno Para', value: 'Munno Para' },
  { label: 'Munno Para West', value: 'Munno Para West' },
  { label: 'Muñogalindo', value: 'Muñogalindo' },
  { label: 'Muñogrande', value: 'Muñogrande' },
  { label: 'Muñomer Del Peco', value: 'Muñomer Del Peco' },
  { label: 'Muñopedro', value: 'Muñopedro' },
  { label: 'Muñopepe', value: 'Muñopepe' },
  { label: 'Muñosancho', value: 'Muñosancho' },
  { label: 'Muñotello', value: 'Muñotello' },
  { label: 'Muñoveros', value: 'Muñoveros' },
  { label: 'Muñoz', value: 'Muñoz' },
  { label: 'Muñoz', value: 'Muñoz' },
  { label: 'Muñoz', value: 'Muñoz' },
  { label: 'Muñoz East', value: 'Muñoz East' },
  { label: 'Muñoz East', value: 'Muñoz East' },
  { label: 'Munroe Falls', value: 'Munroe Falls' },
  { label: 'Munruben', value: 'Munruben' },
  { label: 'Munsan', value: 'Munsan' },
  { label: 'Munsey Park', value: 'Munsey Park' },
  { label: 'Munshāt ‘Alī Āghā', value: 'Munshāt ‘Alī Āghā' },
  { label: 'Munshiganj ', value: 'Munshiganj ' },
  { label: 'Münsing', value: 'Münsing' },
  { label: 'Münsingen', value: 'Münsingen' },
  { label: 'Münsingen', value: 'Münsingen' },
  { label: 'Munsons Corners', value: 'Munsons Corners' },
  { label: 'Munster', value: 'Munster' },
  { label: 'Munster', value: 'Munster' },
  { label: 'Munster', value: 'Munster' },
  { label: 'Munster', value: 'Munster' },
  { label: 'Münster', value: 'Münster' },
  { label: 'Münster', value: 'Münster' },
  { label: 'Münster', value: 'Münster' },
  { label: 'Münster', value: 'Münster' },
  { label: 'Münsterdorf', value: 'Münsterdorf' },
  { label: 'Munstergeleen', value: 'Munstergeleen' },
  { label: 'Münsterhausen', value: 'Münsterhausen' },
  { label: 'Münsterlingen', value: 'Münsterlingen' },
  { label: 'Münstermaifeld', value: 'Münstermaifeld' },
  { label: 'Münster-Sarmsheim', value: 'Münster-Sarmsheim' },
  { label: 'Münstertal/Schwarzwald', value: 'Münstertal/Schwarzwald' },
  { label: 'Munsyari', value: 'Munsyari' },
  { label: 'Muntanyola', value: 'Muntanyola' },
  { label: 'Muntele Mic', value: 'Muntele Mic' },
  { label: 'Munteni', value: 'Munteni' },
  { label: 'Munteni', value: 'Munteni' },
  { label: 'Munteni Buzău', value: 'Munteni Buzău' },
  { label: 'Muntenii De Jos', value: 'Muntenii De Jos' },
  { label: 'Muntenii De Sus', value: 'Muntenii De Sus' },
  { label: 'Muntilan', value: 'Muntilan' },
  { label: 'Muntinlupa', value: 'Muntinlupa' },
  { label: 'Muntlix', value: 'Muntlix' },
  { label: 'Muntok', value: 'Muntok' },
  { label: 'Müntschemier', value: 'Müntschemier' },
  { label: 'Munūf', value: 'Munūf' },
  { label: 'Munxar', value: 'Munxar' },
  { label: 'Münzbach', value: 'Münzbach' },
  { label: 'Münzenberg', value: 'Münzenberg' },
  { label: 'Münzkirchen', value: 'Münzkirchen' },
  { label: 'Muolen', value: 'Muolen' },
  { label: 'Muonio', value: 'Muonio' },
  { label: 'Muotathal', value: 'Muotathal' },
  { label: 'Muquém Do São Francisco', value: 'Muquém Do São Francisco' },
  { label: 'Muquén', value: 'Muquén' },
  { label: 'Muqui', value: 'Muqui' },
  { label: 'Mura', value: 'Mura' },
  { label: 'Mura', value: 'Mura' },
  { label: 'Mura Mare', value: 'Mura Mare' },
  { label: 'Mura Mică', value: 'Mura Mică' },
  { label: 'Muradabad', value: 'Muradabad' },
  { label: 'Muradiye', value: 'Muradiye' },
  { label: 'Muradnagar', value: 'Muradnagar' },
  { label: 'Murafa', value: 'Murafa' },
  { label: 'Muragacha', value: 'Muragacha' },
  { label: 'Mūrak', value: 'Mūrak' },
  { label: 'Murakami Shi', value: 'Murakami Shi' },
  { label: 'Murakeresztúr', value: 'Murakeresztúr' },
  { label: 'Muralto', value: 'Muralto' },
  { label: 'Muramatsu', value: 'Muramatsu' },
  { label: 'Muramvya', value: 'Muramvya' },
  { label: 'Murang’A', value: 'Murang’A' },
  { label: 'Murano', value: 'Murano' },
  { label: 'Muranovo', value: 'Muranovo' },
  { label: 'Murarrie', value: 'Murarrie' },
  { label: 'Muras', value: 'Muras' },
  { label: 'Murashi', value: 'Murashi' },
  { label: 'Murashinskiy Rayon', value: 'Murashinskiy Rayon' },
  { label: 'Murat', value: 'Murat' },
  { label: 'Muratlı', value: 'Muratlı' },
  { label: 'Muratpaşa', value: 'Muratpaşa' },
  { label: 'Murau', value: 'Murau' },
  { label: 'Muravera', value: 'Muravera' },
  { label: 'Muravlenko', value: 'Muravlenko' },
  { label: 'Murayama', value: 'Murayama' },
  { label: 'Murayama Shi', value: 'Murayama Shi' },
  { label: 'Murazzano', value: 'Murazzano' },
  { label: 'Murbad', value: 'Murbad' },
  { label: 'Murbaḩ', value: 'Murbaḩ' },
  { label: 'Murça', value: 'Murça' },
  { label: 'Murça', value: 'Murça' },
  { label: 'Murchante', value: 'Murchante' },
  { label: 'Murchison', value: 'Murchison' },
  { label: 'Murcia', value: 'Murcia' },
  { label: 'Murcia', value: 'Murcia' },
  { label: 'Murcia', value: 'Murcia' },
  { label: 'Murcia', value: 'Murcia' },
  { label: 'Murcki', value: 'Murcki' },
  { label: 'Mûr-De-Bretagne', value: 'Mûr-De-Bretagne' },
  { label: 'Mur-De-Sologne', value: 'Mur-De-Sologne' },
  { label: 'Murdo', value: 'Murdo' },
  { label: 'Murdoch', value: 'Murdoch' },
  { label: 'Mure', value: 'Mure' },
  { label: 'Mureck', value: 'Mureck' },
  { label: 'Murehwa', value: 'Murehwa' },
  { label: 'Murehwa District', value: 'Murehwa District' },
  { label: 'Murello', value: 'Murello' },
  { label: 'Mureni', value: 'Mureni' },
  { label: 'Murero', value: 'Murero' },
  { label: 'Mureșeni', value: 'Mureșeni' },
  { label: 'Muret', value: 'Muret' },
  { label: 'Murfatlar', value: 'Murfatlar' },
  { label: 'Murfreesboro', value: 'Murfreesboro' },
  { label: 'Murfreesboro', value: 'Murfreesboro' },
  { label: 'Murfreesboro', value: 'Murfreesboro' },
  { label: 'Murg', value: 'Murg' },
  { label: 'Murgași', value: 'Murgași' },
  { label: 'Murgeni', value: 'Murgeni' },
  { label: 'Murgenthal', value: 'Murgenthal' },
  { label: 'Murgeşti', value: 'Murgeşti' },
  { label: 'Murgești', value: 'Murgești' },
  { label: 'Murghob', value: 'Murghob' },
  { label: 'Murgon', value: 'Murgon' },
  { label: 'Murgud', value: 'Murgud' },
  { label: 'Murgul', value: 'Murgul' },
  { label: 'Muri', value: 'Muri' },
  { label: 'Muri', value: 'Muri' },
  { label: 'Muri', value: 'Muri' },
  { label: 'Muriaé', value: 'Muriaé' },
  { label: 'Murialdo', value: 'Murialdo' },
  { label: 'Murias De Paredes', value: 'Murias De Paredes' },
  { label: 'Muribeca', value: 'Muribeca' },
  { label: 'Muricay', value: 'Muricay' },
  { label: 'Murici', value: 'Murici' },
  { label: 'Murici Dos Portelas', value: 'Murici Dos Portelas' },
  { label: 'Muricilândia', value: 'Muricilândia' },
  { label: 'Muridke', value: 'Muridke' },
  { label: 'Muriel', value: 'Muriel' },
  { label: 'Muriel De La Fuente', value: 'Muriel De La Fuente' },
  { label: 'Muriel Viejo', value: 'Muriel Viejo' },
  { label: 'Murieta', value: 'Murieta' },
  { label: 'Murighiol', value: 'Murighiol' },
  { label: 'Murillo', value: 'Murillo' },
  { label: 'Murillo Colonia', value: 'Murillo Colonia' },
  { label: 'Murillo De Gállego', value: 'Murillo De Gállego' },
  { label: 'Murillo De Río Leza', value: 'Murillo De Río Leza' },
  { label: 'Murillo El Cuende', value: 'Murillo El Cuende' },
  { label: 'Murillo El Fruto', value: 'Murillo El Fruto' },
  { label: 'Murilo', value: 'Murilo' },
  { label: 'Murilo Municipality', value: 'Murilo Municipality' },
  { label: 'Murindó', value: 'Murindó' },
  { label: 'Murino', value: 'Murino' },
  { label: 'Murisengo', value: 'Murisengo' },
  { label: 'Muriti', value: 'Muriti' },
  { label: 'Muritiba', value: 'Muritiba' },
  { label: 'Muriwai Beach', value: 'Muriwai Beach' },
  { label: 'Murla', value: 'Murla' },
  { label: 'Murliganj', value: 'Murliganj' },
  { label: 'Murlo', value: 'Murlo' },
  { label: 'Murmansk', value: 'Murmansk' },
  { label: 'Murmashi', value: 'Murmashi' },
  { label: 'Murmino', value: 'Murmino' },
  { label: 'Mūrmuiža', value: 'Mūrmuiža' },
  { label: 'Murmuri ', value: 'Murmuri ' },
  { label: 'Murnau Am Staffelsee', value: 'Murnau Am Staffelsee' },
  { label: 'Muro', value: 'Muro' },
  { label: 'Muro De Aguas', value: 'Muro De Aguas' },
  { label: 'Muro De Alcoy', value: 'Muro De Alcoy' },
  { label: 'Muro Del Alcoy', value: 'Muro Del Alcoy' },
  { label: 'Muro En Cameros', value: 'Muro En Cameros' },
  { label: 'Muro Leccese', value: 'Muro Leccese' },
  { label: 'Muro Lucano', value: 'Muro Lucano' },
  { label: 'Murom', value: 'Murom' },
  { label: 'Muromskiy Rayon', value: 'Muromskiy Rayon' },
  { label: 'Muromskoye', value: 'Muromskoye' },
  { label: 'Muromtsevo', value: 'Muromtsevo' },
  { label: 'Muromtsevo', value: 'Muromtsevo' },
  { label: 'Muromtsevskiy Rayon', value: 'Muromtsevskiy Rayon' },
  { label: 'Muron', value: 'Muron' },
  { label: 'Muroran', value: 'Muroran' },
  { label: 'Muroran-Shi', value: 'Muroran-Shi' },
  { label: 'Muros', value: 'Muros' },
  { label: 'Muros', value: 'Muros' },
  { label: 'Muros De Nalón', value: 'Muros De Nalón' },
  { label: 'Muroto', value: 'Muroto' },
  { label: 'Murovane', value: 'Murovane' },
  { label: 'Murovani Kurylivtsi', value: 'Murovani Kurylivtsi' },
  { label: 'Murów', value: 'Murów' },
  { label: 'Murowana Goślina', value: 'Murowana Goślina' },
  { label: 'Murphy', value: 'Murphy' },
  { label: 'Murphy', value: 'Murphy' },
  { label: 'Murphy', value: 'Murphy' },
  { label: 'Murphy', value: 'Murphy' },
  { label: 'Murphys', value: 'Murphys' },
  { label: 'Murphys Estates', value: 'Murphys Estates' },
  { label: 'Murphysboro', value: 'Murphysboro' },
  { label: 'Murr', value: 'Murr' },
  { label: 'Murra', value: 'Murra' },
  { label: 'Murray', value: 'Murray' },
  { label: 'Murray', value: 'Murray' },
  { label: 'Murray', value: 'Murray' },
  { label: 'Murray', value: 'Murray' },
  { label: 'Murray Bridge', value: 'Murray Bridge' },
  { label: 'Murray County', value: 'Murray County' },
  { label: 'Murray County', value: 'Murray County' },
  { label: 'Murray County', value: 'Murray County' },
  { label: 'Murrays Bay', value: 'Murrays Bay' },
  { label: 'Murraysville', value: 'Murraysville' },
  { label: 'Murree', value: 'Murree' },
  { label: 'Murrells Inlet', value: 'Murrells Inlet' },
  { label: 'Murrhardt', value: 'Murrhardt' },
  { label: 'Murrieta', value: 'Murrieta' },
  { label: 'Murrieta Hot Springs', value: 'Murrieta Hot Springs' },
  { label: 'Murrindindi', value: 'Murrindindi' },
  { label: 'Murrumba Downs', value: 'Murrumba Downs' },
  { label: 'Murrumbateman', value: 'Murrumbateman' },
  { label: 'Murrumbeena', value: 'Murrumbeena' },
  { label: 'Murrurundi', value: 'Murrurundi' },
  { label: 'Murrysville', value: 'Murrysville' },
  { label: 'Mursalimkino', value: 'Mursalimkino' },
  { label: 'Mursan', value: 'Mursan' },
  { label: 'Mûrs-Erigné', value: 'Mûrs-Erigné' },
  { label: 'Murshidabad', value: 'Murshidabad' },
  { label: 'Murshidiyah', value: 'Murshidiyah' },
  { label: 'Murska Sobota', value: 'Murska Sobota' },
  { label: 'Mursko Središće', value: 'Mursko Središće' },
  { label: 'Murtajapur', value: 'Murtajapur' },
  { label: 'Murtas', value: 'Murtas' },
  { label: 'Murten/Morat', value: 'Murten/Morat' },
  { label: 'Murter', value: 'Murter' },
  { label: 'Murter-Kornati', value: 'Murter-Kornati' },
  { label: 'Murtino', value: 'Murtino' },
  { label: 'Murton', value: 'Murton' },
  { label: 'Murtosa', value: 'Murtosa' },
  { label: 'Murud (Raigad)', value: 'Murud (Raigad)' },
  { label: 'Murud (Ratnagiri)', value: 'Murud (Ratnagiri)' },
  { label: 'Murudeshwara', value: 'Murudeshwara' },
  { label: 'Murueta', value: 'Murueta' },
  { label: 'Murum', value: 'Murum' },
  { label: 'Murun-Kuren', value: 'Murun-Kuren' },
  { label: 'Murupara', value: 'Murupara' },
  { label: 'Murutinga Do Sul', value: 'Murutinga Do Sul' },
  { label: 'Muruzábal', value: 'Muruzábal' },
  { label: 'Murviel-Lès-Béziers', value: 'Murviel-Lès-Béziers' },
  { label: 'Murviel-Lès-Montpellier', value: 'Murviel-Lès-Montpellier' },
  { label: 'Murwara', value: 'Murwara' },
  { label: 'Murweh', value: 'Murweh' },
  { label: 'Murwillumbah', value: 'Murwillumbah' },
  { label: 'Murygino', value: 'Murygino' },
  { label: 'Mürzhofen', value: 'Mürzhofen' },
  { label: 'Mürzsteg', value: 'Mürzsteg' },
  { label: 'Murzuq', value: 'Murzuq' },
  { label: 'Mürzzuschlag', value: 'Mürzzuschlag' },
  { label: 'Mus', value: 'Mus' },
  { label: 'Musa Bolaghi', value: 'Musa Bolaghi' },
  { label: 'Mūsa Khel District', value: 'Mūsa Khel District' },
  { label: 'Musabeyli', value: 'Musabeyli' },
  { label: 'Musaffah', value: 'Musaffah' },
  { label: 'Musafirkhana', value: 'Musafirkhana' },
  { label: 'Musafir-Khana', value: 'Musafir-Khana' },
  { label: 'Musalerr', value: 'Musalerr' },
  { label: 'Musano', value: 'Musano' },
  { label: 'Musan-Ŭp', value: 'Musan-Ŭp' },
  { label: 'Musanze', value: 'Musanze' },
  { label: 'Musashimurayama-Shi', value: 'Musashimurayama-Shi' },
  { label: 'Musashino', value: 'Musashino' },
  { label: 'Musashino-Shi', value: 'Musashino-Shi' },
  { label: 'Mușătești', value: 'Mușătești' },
  { label: 'Musau', value: 'Musau' },
  { label: 'Musay‘Īd', value: 'Musay‘Īd' },
  { label: 'Muscat', value: 'Muscat' },
  { label: 'Muscatine', value: 'Muscatine' },
  { label: 'Muscatine County', value: 'Muscatine County' },
  { label: 'Muschberg En Geestenberg', value: 'Muschberg En Geestenberg' },
  { label: 'Müschenbach', value: 'Müschenbach' },
  { label: 'Muschwitz', value: 'Muschwitz' },
  { label: 'Musci', value: 'Musci' },
  { label: 'Muscle Shoals', value: 'Muscle Shoals' },
  { label: 'Muscoda', value: 'Muscoda' },
  { label: 'Muscogee County', value: 'Muscogee County' },
  { label: 'Muscoline', value: 'Muscoline' },
  { label: 'Muscoy', value: 'Muscoy' },
  { label: 'Muse', value: 'Muse' },
  { label: 'Musei', value: 'Musei' },
  { label: 'Muşeniţa', value: 'Muşeniţa' },
  { label: 'Museros', value: 'Museros' },
  { label: 'Musestre', value: 'Musestre' },
  { label: 'Muşeteşti', value: 'Muşeteşti' },
  { label: 'Mushabani', value: 'Mushabani' },
  { label: 'Mushie', value: 'Mushie' },
  { label: 'Mushin', value: 'Mushin' },
  { label: 'Mushrif', value: 'Mushrif' },
  { label: 'Musile Di Piave', value: 'Musile Di Piave' },
  { label: 'Musina', value: 'Musina' },
  { label: 'Musiri', value: 'Musiri' },
  { label: 'Muskatnoye', value: 'Muskatnoye' },
  { label: 'Muskego', value: 'Muskego' },
  { label: 'Muskegon', value: 'Muskegon' },
  { label: 'Muskegon County', value: 'Muskegon County' },
  { label: 'Muskegon Heights', value: 'Muskegon Heights' },
  { label: 'Muskingum County', value: 'Muskingum County' },
  { label: 'Muskiz', value: 'Muskiz' },
  { label: 'Muskogee', value: 'Muskogee' },
  { label: 'Muskogee County', value: 'Muskogee County' },
  { label: 'Muslyumovo', value: 'Muslyumovo' },
  { label: 'Muslyumovo', value: 'Muslyumovo' },
  { label: 'Muslyumovskiy Rayon', value: 'Muslyumovskiy Rayon' },
  { label: 'Musoma', value: 'Musoma' },
  { label: 'Musselburgh', value: 'Musselburgh' },
  { label: 'Musselshell County', value: 'Musselshell County' },
  { label: 'Mussidan', value: 'Mussidan' },
  { label: 'Mussig', value: 'Mussig' },
  { label: 'Musso', value: 'Musso' },
  { label: 'Mussolente', value: 'Mussolente' },
  { label: 'Mussomeli', value: 'Mussomeli' },
  { label: 'Musson', value: 'Musson' },
  { label: 'Mussoorie', value: 'Mussoorie' },
  { label: 'Mussotto', value: 'Mussotto' },
  { label: 'Mussy-Sur-Seine', value: 'Mussy-Sur-Seine' },
  { label: 'Mustaba', value: 'Mustaba' },
  { label: 'Mustafabad', value: 'Mustafabad' },
  { label: 'Mustafabad', value: 'Mustafabad' },
  { label: 'Mustafakemalpaşa', value: 'Mustafakemalpaşa' },
  { label: 'Mustang', value: 'Mustang' },
  { label: 'Mustang ', value: 'Mustang ' },
  { label: 'Mustansiriya', value: 'Mustansiriya' },
  { label: 'Musturud', value: 'Musturud' },
  { label: 'Mustvee', value: 'Mustvee' },
  { label: 'Muswellbrook', value: 'Muswellbrook' },
  { label: 'Muszyna', value: 'Muszyna' },
  { label: 'Mut', value: 'Mut' },
  { label: 'Muta', value: 'Muta' },
  { label: 'Mutare', value: 'Mutare' },
  { label: 'Mutare District', value: 'Mutare District' },
  { label: 'Mutasa District', value: 'Mutasa District' },
  { label: 'Mutatá', value: 'Mutatá' },
  { label: 'Mutengene', value: 'Mutengene' },
  { label: 'Mutěnice', value: 'Mutěnice' },
  { label: 'Muti', value: 'Muti' },
  { label: 'Mutiloa', value: 'Mutiloa' },
  { label: 'Mutiscua', value: 'Mutiscua' },
  { label: 'Mutki', value: 'Mutki' },
  { label: 'Mutlangen', value: 'Mutlangen' },
  { label: 'Mutoko', value: 'Mutoko' },
  { label: 'Mutquín', value: 'Mutquín' },
  { label: 'Mutriku', value: 'Mutriku' },
  { label: 'Mutsalaul', value: 'Mutsalaul' },
  { label: 'Mutsu', value: 'Mutsu' },
  { label: 'Mutsu-Shi', value: 'Mutsu-Shi' },
  { label: 'Muttenz', value: 'Muttenz' },
  { label: 'Mutters', value: 'Mutters' },
  { label: 'Muttersholtz', value: 'Muttersholtz' },
  { label: 'Mutterstadt', value: 'Mutterstadt' },
  { label: 'Muttontown', value: 'Muttontown' },
  { label: 'Muttupet', value: 'Muttupet' },
  { label: 'Mutuáli', value: 'Mutuáli' },
  { label: 'Mutuípe', value: 'Mutuípe' },
  { label: 'Mutum', value: 'Mutum' },
  { label: 'Mutum Biyu', value: 'Mutum Biyu' },
  { label: 'Mutunópolis', value: 'Mutunópolis' },
  { label: 'Mutxamel', value: 'Mutxamel' },
  { label: 'Mutzig', value: 'Mutzig' },
  { label: 'Mutzschen', value: 'Mutzschen' },
  { label: 'Muurame', value: 'Muurame' },
  { label: 'Muurla', value: 'Muurla' },
  { label: 'Muvattupula', value: 'Muvattupula' },
  { label: 'Muvattupuzha', value: 'Muvattupuzha' },
  { label: 'Muwassam', value: 'Muwassam' },
  { label: 'Muxagata', value: 'Muxagata' },
  { label: 'Muxía', value: 'Muxía' },
  { label: 'Muxika', value: 'Muxika' },
  { label: 'Muxima', value: 'Muxima' },
  { label: 'Muxupip', value: 'Muxupip' },
  { label: 'Muy Muy', value: 'Muy Muy' },
  { label: 'Muyeveld', value: 'Muyeveld' },
  { label: 'Muyezerskiy', value: 'Muyezerskiy' },
  { label: 'Muyinga', value: 'Muyinga' },
  { label: 'Mŭynoq', value: 'Mŭynoq' },
  { label: 'Muyskiy Rayon', value: 'Muyskiy Rayon' },
  { label: 'Muyuka', value: 'Muyuka' },
  { label: 'Muzaffarābād', value: 'Muzaffarābād' },
  { label: 'Muzaffargarh', value: 'Muzaffargarh' },
  { label: 'Muzaffarnagar', value: 'Muzaffarnagar' },
  { label: 'Muzaffarpur', value: 'Muzaffarpur' },
  { label: 'Muzambinho', value: 'Muzambinho' },
  { label: 'Muzayri‘', value: 'Muzayri‘' },
  { label: 'Muzhi', value: 'Muzhi' },
  { label: 'Muzhiyevo', value: 'Muzhiyevo' },
  { label: 'Muzillac', value: 'Muzillac' },
  { label: 'Muzo', value: 'Muzo' },
  { label: 'Muzza Di Cornegliano Laudense', value: 'Muzza Di Cornegliano Laudense' },
  { label: 'Muzzana Del Turgnano', value: 'Muzzana Del Turgnano' },
  { label: 'Muzzano', value: 'Muzzano' },
  { label: 'Mvangué', value: 'Mvangué' },
  { label: 'Mvila', value: 'Mvila' },
  { label: 'Mvomero', value: 'Mvomero' },
  { label: 'Mvomero District', value: 'Mvomero District' },
  { label: 'Mvouni', value: 'Mvouni' },
  { label: 'Mvuma', value: 'Mvuma' },
  { label: 'Mvurwi', value: 'Mvurwi' },
  { label: 'Mwadui', value: 'Mwadui' },
  { label: 'Mwandiga', value: 'Mwandiga' },
  { label: 'Mwanga', value: 'Mwanga' },
  { label: 'Mwansanga', value: 'Mwansanga' },
  { label: 'Mwanza', value: 'Mwanza' },
  { label: 'Mwanza', value: 'Mwanza' },
  { label: 'Mwanza District', value: 'Mwanza District' },
  { label: 'Mwaro', value: 'Mwaro' },
  { label: 'Mwaya', value: 'Mwaya' },
  { label: 'Mweka', value: 'Mweka' },
  { label: 'Mwembe', value: 'Mwembe' },
  { label: 'Mwene-Ditu', value: 'Mwene-Ditu' },
  { label: 'Mwenezi District', value: 'Mwenezi District' },
  { label: 'Mwense', value: 'Mwense' },
  { label: 'Mwingi', value: 'Mwingi' },
  { label: 'Mwinilunga', value: 'Mwinilunga' },
  { label: 'Mỹ Tho', value: 'Mỹ Tho' },
  { label: 'Myadzyel', value: 'Myadzyel' },
  { label: 'Myadzyel’Ski Rayon', value: 'Myadzyel’Ski Rayon' },
  { label: 'Myagdi ', value: 'Myagdi ' },
  { label: 'Myaksa', value: 'Myaksa' },
  { label: 'Myanaung', value: 'Myanaung' },
  { label: 'Myaree', value: 'Myaree' },
  { label: 'Myasnikyan', value: 'Myasnikyan' },
  { label: 'Myatlevo', value: 'Myatlevo' },
  { label: 'Myaundzha', value: 'Myaundzha' },
  { label: 'Myawadi', value: 'Myawadi' },
  { label: 'Myaydo', value: 'Myaydo' },
  { label: 'Myazhysyatki', value: 'Myazhysyatki' },
  { label: 'Myeik', value: 'Myeik' },
  { label: 'Myers Corner', value: 'Myers Corner' },
  { label: 'Myerstown', value: 'Myerstown' },
  { label: 'Myersville', value: 'Myersville' },
  { label: 'Myersville', value: 'Myersville' },
  { label: 'Myette Gardens', value: 'Myette Gardens' },
  { label: 'Myggenäs', value: 'Myggenäs' },
  { label: 'Myhiya', value: 'Myhiya' },
  { label: 'Myhove', value: 'Myhove' },
  { label: 'Myingyan', value: 'Myingyan' },
  { label: 'Myitkyina', value: 'Myitkyina' },
  { label: 'Myjava', value: 'Myjava' },
  { label: 'Mykanów', value: 'Mykanów' },
  { label: 'Mykhaylivka', value: 'Mykhaylivka' },
  { label: 'Mykhaylivs’Kyy Rayon', value: 'Mykhaylivs’Kyy Rayon' },
  { label: 'Mýki', value: 'Mýki' },
  { label: 'Mykolaiv', value: 'Mykolaiv' },
  { label: 'Mykolayiv', value: 'Mykolayiv' },
  { label: 'Mykolayivka', value: 'Mykolayivka' },
  { label: 'Mykolayivs’Kyy Rayon', value: 'Mykolayivs’Kyy Rayon' },
  { label: 'Mykonos', value: 'Mykonos' },
  { label: 'Mylau', value: 'Mylau' },
  { label: 'Mylor', value: 'Mylor' },
  { label: 'Mylor Bridge', value: 'Mylor Bridge' },
  { label: 'Mymensingh ', value: 'Mymensingh ' },
  { label: 'Mynämäki', value: 'Mynämäki' },
  { label: 'Mynaral', value: 'Mynaral' },
  { label: 'Mynay', value: 'Mynay' },
  { label: 'Myoko-Shi', value: 'Myoko-Shi' },
  { label: 'Myorski Rayon', value: 'Myorski Rayon' },
  { label: 'Myory', value: 'Myory' },
  { label: 'Mýrdalshreppur', value: 'Mýrdalshreppur' },
  { label: 'Myre', value: 'Myre' },
  { label: 'Myrhorod', value: 'Myrhorod' },
  { label: 'Myrnohrad', value: 'Myrnohrad' },
  { label: 'Myronivka', value: 'Myronivka' },
  { label: 'Myropil', value: 'Myropil' },
  { label: 'Myrsíni', value: 'Myrsíni' },
  { label: 'Myrskylä', value: 'Myrskylä' },
  { label: 'Myrtle Bank', value: 'Myrtle Bank' },
  { label: 'Myrtle Beach', value: 'Myrtle Beach' },
  { label: 'Myrtle Creek', value: 'Myrtle Creek' },
  { label: 'Myrtle Grove', value: 'Myrtle Grove' },
  { label: 'Myrtle Grove', value: 'Myrtle Grove' },
  { label: 'Myrtle Point', value: 'Myrtle Point' },
  { label: 'Myrtleford', value: 'Myrtleford' },
  { label: 'Myrtletown', value: 'Myrtletown' },
  { label: 'Myrzakent', value: 'Myrzakent' },
  { label: 'Mysen', value: 'Mysen' },
  { label: 'Myshkavichy', value: 'Myshkavichy' },
  { label: 'Myshkin', value: 'Myshkin' },
  { label: 'Myshkinskiy Rayon', value: 'Myshkinskiy Rayon' },
  { label: 'Mysiadło', value: 'Mysiadło' },
  { label: 'Mys-Kamennyy', value: 'Mys-Kamennyy' },
  { label: 'Myskhako', value: 'Myskhako' },
  { label: 'Myski', value: 'Myski' },
  { label: 'Myślachowice', value: 'Myślachowice' },
  { label: 'Mysłakowice', value: 'Mysłakowice' },
  { label: 'Myślenice', value: 'Myślenice' },
  { label: 'Myślibórz', value: 'Myślibórz' },
  { label: 'Mysłowice', value: 'Mysłowice' },
  { label: 'Mysovoe', value: 'Mysovoe' },
  { label: 'Mystic', value: 'Mystic' },
  { label: 'Mystic Island', value: 'Mystic Island' },
  { label: 'Mysuru', value: 'Mysuru' },
  { label: 'Myszków', value: 'Myszków' },
  { label: 'Myszyniec', value: 'Myszyniec' },
  { label: 'Mytholmroyd', value: 'Mytholmroyd' },
  { label: 'Mytikas', value: 'Mytikas' },
  { label: 'Mytishchi', value: 'Mytishchi' },
  { label: 'Mytishchi Urban Okrug', value: 'Mytishchi Urban Okrug' },
  { label: 'Mýto', value: 'Mýto' },
  { label: 'Myurego', value: 'Myurego' },
  { label: 'Mzimba', value: 'Mzimba' },
  { label: 'Mzimba District', value: 'Mzimba District' },
  { label: 'Mzuzu', value: 'Mzuzu' },
  { label: 'N’Dalatando', value: 'N’Dalatando' },
  { label: 'N’Diareme Limamoulaye', value: 'N’Diareme Limamoulaye' },
  { label: 'Na Klang', value: 'Na Klang' },
  { label: 'Na Mom', value: 'Na Mom' },
  { label: 'Na Wa', value: 'Na Wa' },
  { label: 'Naagas', value: 'Naagas' },
  { label: 'Naaldwijk', value: 'Naaldwijk' },
  { label: 'Naama', value: 'Naama' },
  { label: 'Naantali', value: 'Naantali' },
  { label: 'Naarden', value: 'Naarden' },
  { label: 'Naarn Im Machlande', value: 'Naarn Im Machlande' },
  { label: 'Naas', value: 'Naas' },
  { label: 'Naas', value: 'Naas' },
  { label: 'Naastenbest', value: 'Naastenbest' },
  { label: 'Naawan', value: 'Naawan' },
  { label: 'Nabadwip', value: 'Nabadwip' },
  { label: 'Nabagram', value: 'Nabagram' },
  { label: 'Nabais', value: 'Nabais' },
  { label: 'Nabangig', value: 'Nabangig' },
  { label: 'Nabannagan West', value: 'Nabannagan West' },
  { label: 'Nabannagan West', value: 'Nabannagan West' },
  { label: 'Nabarangpur', value: 'Nabarangpur' },
  { label: 'Nabari', value: 'Nabari' },
  { label: 'Nabari-Shi', value: 'Nabari-Shi' },
  { label: 'Nabarniz', value: 'Nabarniz' },
  { label: 'Nabas', value: 'Nabas' },
  { label: 'Nabatîyé Et Tahta', value: 'Nabatîyé Et Tahta' },
  { label: 'Nabburg', value: 'Nabburg' },
  { label: 'Naberera', value: 'Naberera' },
  { label: 'Naberezhnyye Chelny', value: 'Naberezhnyye Chelny' },
  { label: 'Nabeul', value: 'Nabeul' },
  { label: 'Nabha', value: 'Nabha' },
  { label: 'Nabiac', value: 'Nabiac' },
  { label: 'Nabinagar', value: 'Nabinagar' },
  { label: 'Nabire', value: 'Nabire' },
  { label: 'Nabisar', value: 'Nabisar' },
  { label: 'Nabua', value: 'Nabua' },
  { label: 'Nabulao', value: 'Nabulao' },
  { label: 'Nabunturan', value: 'Nabunturan' },
  { label: 'Nabunturan', value: 'Nabunturan' },
  { label: 'Nabunturan', value: 'Nabunturan' },
  { label: 'Nacajuca', value: 'Nacajuca' },
  { label: 'Nacala', value: 'Nacala' },
  { label: 'Nacaome', value: 'Nacaome' },
  { label: 'Načeradec', value: 'Načeradec' },
  { label: 'Nachalovo', value: 'Nachalovo' },
  { label: 'Nachig', value: 'Nachig' },
  { label: 'Nachingwea', value: 'Nachingwea' },
  { label: 'Náchod', value: 'Náchod' },
  { label: 'Nachrodt-Wiblingwerde', value: 'Nachrodt-Wiblingwerde' },
  { label: 'Nachterstedt', value: 'Nachterstedt' },
  { label: 'Nacimiento', value: 'Nacimiento' },
  { label: 'Nacimiento', value: 'Nacimiento' },
  { label: 'Nacip Raydan', value: 'Nacip Raydan' },
  { label: 'Naciria', value: 'Naciria' },
  { label: 'Nacka', value: 'Nacka' },
  { label: 'Nackawic', value: 'Nackawic' },
  { label: 'Nackenheim', value: 'Nackenheim' },
  { label: 'Naco', value: 'Naco' },
  { label: 'Naco', value: 'Naco' },
  { label: 'Naco', value: 'Naco' },
  { label: 'Nacogdoches', value: 'Nacogdoches' },
  { label: 'Nacogdoches County', value: 'Nacogdoches County' },
  { label: 'Nácori Chico', value: 'Nácori Chico' },
  { label: 'Nacozari De García', value: 'Nacozari De García' },
  { label: 'Nacozari Viejo', value: 'Nacozari Viejo' },
  { label: 'Nacuche', value: 'Nacuche' },
  { label: 'Nada', value: 'Nada' },
  { label: 'Nadab', value: 'Nadab' },
  { label: 'Nadadores', value: 'Nadadores' },
  { label: 'Nadadouro', value: 'Nadadouro' },
  { label: 'Nadapuram', value: 'Nadapuram' },
  { label: 'Nadarzyn', value: 'Nadarzyn' },
  { label: 'Nadăș', value: 'Nadăș' },
  { label: 'Nadășa', value: 'Nadășa' },
  { label: 'Nadaun', value: 'Nadaun' },
  { label: 'Nadbai', value: 'Nadbai' },
  { label: 'Nadeș', value: 'Nadeș' },
  { label: 'Nadezhda', value: 'Nadezhda' },
  { label: 'Nadezhdinskiy Rayon', value: 'Nadezhdinskiy Rayon' },
  { label: 'Nadgaon', value: 'Nadgaon' },
  { label: 'Nadi', value: 'Nadi' },
  { label: 'Nadia District', value: 'Nadia District' },
  { label: 'Nadiad', value: 'Nadiad' },
  { label: 'Nadigaon', value: 'Nadigaon' },
  { label: 'Nădlac', value: 'Nădlac' },
  { label: 'Nador', value: 'Nador' },
  { label: 'Nădrag', value: 'Nădrag' },
  { label: 'Nadterechnyy Rayon', value: 'Nadterechnyy Rayon' },
  { label: 'Nádudvar', value: 'Nádudvar' },
  { label: 'Nadur', value: 'Nadur' },
  { label: 'Naduvannur', value: 'Naduvannur' },
  { label: 'Naduvattam', value: 'Naduvattam' },
  { label: 'Nadvirna', value: 'Nadvirna' },
  { label: 'Nadvirnyans’Kyy Rayon', value: 'Nadvirnyans’Kyy Rayon' },
  { label: 'Nadvoitsy', value: 'Nadvoitsy' },
  { label: 'Nadym', value: 'Nadym' },
  { label: 'Nadymskiy Rayon', value: 'Nadymskiy Rayon' },
  { label: 'Naein', value: 'Naein' },
  { label: 'Năeni', value: 'Năeni' },
  { label: 'Nærbø', value: 'Nærbø' },
  { label: 'Nærøy', value: 'Nærøy' },
  { label: 'Naesŏ', value: 'Naesŏ' },
  { label: 'Næstved', value: 'Næstved' },
  { label: 'Næstved Kommune', value: 'Næstved Kommune' },
  { label: 'Nafada', value: 'Nafada' },
  { label: 'Nafch', value: 'Nafch' },
  { label: 'Näfels', value: 'Näfels' },
  { label: 'Nafferton', value: 'Nafferton' },
  { label: 'Náfpaktos', value: 'Náfpaktos' },
  { label: 'Náfplio', value: 'Náfplio' },
  { label: 'Nafría De Ucero', value: 'Nafría De Ucero' },
  { label: 'Naga', value: 'Naga' },
  { label: 'Naga', value: 'Naga' },
  { label: 'Naga', value: 'Naga' },
  { label: 'Nagahama', value: 'Nagahama' },
  { label: 'Nagahama-Shi', value: 'Nagahama-Shi' },
  { label: 'Nagai', value: 'Nagai' },
  { label: 'Nagai-Shi', value: 'Nagai-Shi' },
  { label: 'Nagakute-Shi', value: 'Nagakute-Shi' },
  { label: 'Nagamangala', value: 'Nagamangala' },
  { label: 'Nagambie', value: 'Nagambie' },
  { label: 'Nagano', value: 'Nagano' },
  { label: 'Nagano', value: 'Nagano' },
  { label: 'Nagano Shi', value: 'Nagano Shi' },
  { label: 'Nagaoka', value: 'Nagaoka' },
  { label: 'Nagaoka Shi', value: 'Nagaoka Shi' },
  { label: 'Nagaokakyō Shi', value: 'Nagaokakyō Shi' },
  { label: 'Nagaon', value: 'Nagaon' },
  { label: 'Nagapattinam', value: 'Nagapattinam' },
  { label: 'Nagapur', value: 'Nagapur' },
  { label: 'Nagar', value: 'Nagar' },
  { label: 'Nagar', value: 'Nagar' },
  { label: 'Nagar Karnul', value: 'Nagar Karnul' },
  { label: 'Nagareyama', value: 'Nagareyama' },
  { label: 'Nagareyama-Shi', value: 'Nagareyama-Shi' },
  { label: 'Nagari', value: 'Nagari' },
  { label: 'Nagarote', value: 'Nagarote' },
  { label: 'Nagarukhra', value: 'Nagarukhra' },
  { label: 'Nagasaki', value: 'Nagasaki' },
  { label: 'Nagasaki-Shi', value: 'Nagasaki-Shi' },
  { label: 'Nagata', value: 'Nagata' },
  { label: 'Nagato', value: 'Nagato' },
  { label: 'Nagato Shi', value: 'Nagato Shi' },
  { label: 'Nagaur', value: 'Nagaur' },
  { label: 'Nagawa', value: 'Nagawa' },
  { label: 'Nagaybakskiy Rayon', value: 'Nagaybakskiy Rayon' },
  { label: 'Nagbacalan', value: 'Nagbacalan' },
  { label: 'Nagbacalan', value: 'Nagbacalan' },
  { label: 'Nagbalaye', value: 'Nagbalaye' },
  { label: 'Nagbukel', value: 'Nagbukel' },
  { label: 'Nagcarlan', value: 'Nagcarlan' },
  { label: 'Nagda', value: 'Nagda' },
  { label: 'Nagel', value: 'Nagel' },
  { label: 'Nagercoil', value: 'Nagercoil' },
  { label: 'Nages-Et-Solorgues', value: 'Nages-Et-Solorgues' },
  { label: 'Naggo Head', value: 'Naggo Head' },
  { label: 'Naghalin', value: 'Naghalin' },
  { label: 'Naghalin', value: 'Naghalin' },
  { label: 'Naghan', value: 'Naghan' },
  { label: 'Naghneh', value: 'Naghneh' },
  { label: 'Naghvarevi', value: 'Naghvarevi' },
  { label: 'Nagiba', value: 'Nagiba' },
  { label: 'Nagina', value: 'Nagina' },
  { label: 'Nagireddipalli', value: 'Nagireddipalli' },
  { label: 'Nagiso', value: 'Nagiso' },
  { label: 'Nagla', value: 'Nagla' },
  { label: 'Nagłowice', value: 'Nagłowice' },
  { label: 'Nago', value: 'Nago' },
  { label: 'Nago Shi', value: 'Nago Shi' },
  { label: 'Nagod', value: 'Nagod' },
  { label: 'Nagold', value: 'Nagold' },
  { label: 'Nagornyy', value: 'Nagornyy' },
  { label: 'Nagornyy', value: 'Nagornyy' },
  { label: 'Nagorsk', value: 'Nagorsk' },
  { label: 'Nagorskiy Rayon', value: 'Nagorskiy Rayon' },
  { label: 'Nagothana', value: 'Nagothana' },
  { label: 'Nago-Torbole', value: 'Nago-Torbole' },
  { label: 'Nagoya', value: 'Nagoya' },
  { label: 'Nagpandayan', value: 'Nagpandayan' },
  { label: 'Nagpandayan', value: 'Nagpandayan' },
  { label: 'Nagpur', value: 'Nagpur' },
  { label: 'Nagpur Division', value: 'Nagpur Division' },
  { label: 'Nagqu', value: 'Nagqu' },
  { label: 'Nagqu Diqu', value: 'Nagqu Diqu' },
  { label: 'Nagrakata', value: 'Nagrakata' },
  { label: 'Nagram', value: 'Nagram' },
  { label: 'Nagrota', value: 'Nagrota' },
  { label: 'Nagrumbuan', value: 'Nagrumbuan' },
  { label: 'Nagrumbuan', value: 'Nagrumbuan' },
  { label: 'Nags Head', value: 'Nags Head' },
  { label: 'Nagsaing', value: 'Nagsaing' },
  { label: 'Nagsaing', value: 'Nagsaing' },
  { label: 'Nagtipunan', value: 'Nagtipunan' },
  { label: 'Nagtipunan', value: 'Nagtipunan' },
  { label: 'Nagu', value: 'Nagu' },
  { label: 'Nagua', value: 'Nagua' },
  { label: 'Naguabo', value: 'Naguabo' },
  { label: 'Naguabo', value: 'Naguabo' },
  { label: 'Naguabo Municipio', value: 'Naguabo Municipio' },
  { label: 'Naguelguel', value: 'Naguelguel' },
  { label: 'Naguelguel', value: 'Naguelguel' },
  { label: 'Naguilayan', value: 'Naguilayan' },
  { label: 'Naguilayan', value: 'Naguilayan' },
  { label: 'Naguilian', value: 'Naguilian' },
  { label: 'Naguilian', value: 'Naguilian' },
  { label: 'Naguilian', value: 'Naguilian' },
  { label: 'Naguilian', value: 'Naguilian' },
  { label: 'Nagutskoye', value: 'Nagutskoye' },
  { label: 'Nagwa', value: 'Nagwa' },
  { label: 'Nagyatád', value: 'Nagyatád' },
  { label: 'Nagyatádi Járás', value: 'Nagyatádi Járás' },
  { label: 'Nagybajom', value: 'Nagybajom' },
  { label: 'Nagybaracska', value: 'Nagybaracska' },
  { label: 'Nagycenk', value: 'Nagycenk' },
  { label: 'Nagycserkesz', value: 'Nagycserkesz' },
  { label: 'Nagydobos', value: 'Nagydobos' },
  { label: 'Nagydorog', value: 'Nagydorog' },
  { label: 'Nagyecsed', value: 'Nagyecsed' },
  { label: 'Nagyhalász', value: 'Nagyhalász' },
  { label: 'Nagyigmánd', value: 'Nagyigmánd' },
  { label: 'Nagykálló', value: 'Nagykálló' },
  { label: 'Nagykállói Járás', value: 'Nagykállói Járás' },
  { label: 'Nagykanizsa', value: 'Nagykanizsa' },
  { label: 'Nagykanizsai Járás', value: 'Nagykanizsai Járás' },
  { label: 'Nagykáta', value: 'Nagykáta' },
  { label: 'Nagykátai Járás', value: 'Nagykátai Járás' },
  { label: 'Nagykőrös', value: 'Nagykőrös' },
  { label: 'Nagykőrösi Járás', value: 'Nagykőrösi Járás' },
  { label: 'Nagykovácsi', value: 'Nagykovácsi' },
  { label: 'Nagymányok', value: 'Nagymányok' },
  { label: 'Nagymaros', value: 'Nagymaros' },
  { label: 'Nagyoroszi', value: 'Nagyoroszi' },
  { label: 'Nagyrábé', value: 'Nagyrábé' },
  { label: 'Nagyréde', value: 'Nagyréde' },
  { label: 'Nagysáp', value: 'Nagysáp' },
  { label: 'Nagyszénás', value: 'Nagyszénás' },
  { label: 'Nagytarcsa', value: 'Nagytarcsa' },
  { label: 'Naha', value: 'Naha' },
  { label: 'Naha Shi', value: 'Naha Shi' },
  { label: 'Nahan', value: 'Nahan' },
  { label: 'Nahandar', value: 'Nahandar' },
  { label: 'Nahant', value: 'Nahant' },
  { label: 'Nahari', value: 'Nahari' },
  { label: 'Nahariyya', value: 'Nahariyya' },
  { label: 'Naharlagun', value: 'Naharlagun' },
  { label: 'Nahavand', value: 'Nahavand' },
  { label: 'Nahawan', value: 'Nahawan' },
  { label: 'Nahe', value: 'Nahe' },
  { label: 'Naḥf', value: 'Naḥf' },
  { label: 'Nāḩiyat Al Fuhūd', value: 'Nāḩiyat Al Fuhūd' },
  { label: 'Nāḩiyat Ash Shināfīyah', value: 'Nāḩiyat Ash Shināfīyah' },
  { label: 'Nahiyat Ghammas', value: 'Nahiyat Ghammas' },
  { label: 'Nāḩīyat Saddat Al Hindīyah', value: 'Nāḩīyat Saddat Al Hindīyah' },
  { label: 'Nahorkatiya', value: 'Nahorkatiya' },
  { label: 'Nahouri Province', value: 'Nahouri Province' },
  { label: 'Nahrendorf', value: 'Nahrendorf' },
  { label: 'Nahrīn', value: 'Nahrīn' },
  { label: 'Nahualá', value: 'Nahualá' },
  { label: 'Nahuatzén', value: 'Nahuatzén' },
  { label: 'Nahuizalco', value: 'Nahuizalco' },
  { label: 'Nahunta', value: 'Nahunta' },
  { label: 'Nai Harn', value: 'Nai Harn' },
  { label: 'Naic', value: 'Naic' },
  { label: 'Naica', value: 'Naica' },
  { label: 'Naidăș', value: 'Naidăș' },
  { label: 'Naifaru', value: 'Naifaru' },
  { label: 'Naigarhi', value: 'Naigarhi' },
  { label: 'Naihati', value: 'Naihati' },
  { label: 'Naila', value: 'Naila' },
  { label: 'Naili', value: 'Naili' },
  { label: 'Nailloux', value: 'Nailloux' },
  { label: 'Nailong', value: 'Nailong' },
  { label: 'Nailsea', value: 'Nailsea' },
  { label: 'Nailsworth', value: 'Nailsworth' },
  { label: 'Nailsworth', value: 'Nailsworth' },
  { label: 'Nain', value: 'Nain' },
  { label: 'Nain', value: 'Nain' },
  { label: 'Naini Tal', value: 'Naini Tal' },
  { label: 'Nainpur', value: 'Nainpur' },
  { label: 'Naintré', value: 'Naintré' },
  { label: 'Nainwa', value: 'Nainwa' },
  { label: 'Naipu', value: 'Naipu' },
  { label: 'Nairn', value: 'Nairn' },
  { label: 'Nairne', value: 'Nairne' },
  { label: 'Nairobi', value: 'Nairobi' },
  { label: 'Naisano Dua', value: 'Naisano Dua' },
  { label: 'Naistenmatka', value: 'Naistenmatka' },
  { label: 'Naisud', value: 'Naisud' },
  { label: 'Naitasiri Province', value: 'Naitasiri Province' },
  { label: 'Naivasha', value: 'Naivasha' },
  { label: 'Naizin', value: 'Naizin' },
  { label: 'Naja Ḥammādī', value: 'Naja Ḥammādī' },
  { label: 'Najaf', value: 'Najaf' },
  { label: 'Najaf Shahr', value: 'Najaf Shahr' },
  { label: 'Najafabad', value: 'Najafabad' },
  { label: 'Najafgarh', value: 'Najafgarh' },
  { label: 'Najafiyeh', value: 'Najafiyeh' },
  { label: 'Najan', value: 'Najan' },
  { label: 'Nájera', value: 'Nájera' },
  { label: 'Najibabad', value: 'Najibabad' },
  { label: 'Najrah', value: 'Najrah' },
  { label: 'Najrān', value: 'Najrān' },
  { label: 'Naju', value: 'Naju' },
  { label: 'Naju-Si', value: 'Naju-Si' },
  { label: 'Naka', value: 'Naka' },
  { label: 'Nakagami-Gun', value: 'Nakagami-Gun' },
  { label: 'Nakagawa', value: 'Nakagawa' },
  { label: 'Naka-Gun', value: 'Naka-Gun' },
  { label: 'Nakajō', value: 'Nakajō' },
  { label: 'Nakama', value: 'Nakama' },
  { label: 'Nakama Shi', value: 'Nakama Shi' },
  { label: 'Nakambala', value: 'Nakambala' },
  { label: 'Nakamura', value: 'Nakamura' },
  { label: 'Nakano', value: 'Nakano' },
  { label: 'Nakano Shi', value: 'Nakano Shi' },
  { label: 'Nakanojōmachi', value: 'Nakanojōmachi' },
  { label: 'Nakano-Ku', value: 'Nakano-Ku' },
  { label: 'Nakara', value: 'Nakara' },
  { label: 'Nakasongola', value: 'Nakasongola' },
  { label: 'Nakatosa', value: 'Nakatosa' },
  { label: 'Nakatsu', value: 'Nakatsu' },
  { label: 'Nakatsu Shi', value: 'Nakatsu Shi' },
  { label: 'Nakatsugawa', value: 'Nakatsugawa' },
  { label: 'Nakatsugawa-Shi', value: 'Nakatsugawa-Shi' },
  { label: 'Nakatunguru', value: 'Nakatunguru' },
  { label: 'Nakhabino', value: 'Nakhabino' },
  { label: 'Nakhchivan', value: 'Nakhchivan' },
  { label: 'Nakhimovskiy Rayon', value: 'Nakhimovskiy Rayon' },
  { label: 'Nakhl Taghi', value: 'Nakhl Taghi' },
  { label: 'Nakhodka', value: 'Nakhodka' },
  { label: 'Nakhon Luang', value: 'Nakhon Luang' },
  { label: 'Nakhon Nayok', value: 'Nakhon Nayok' },
  { label: 'Nakhon Nayok', value: 'Nakhon Nayok' },
  { label: 'Nakhon Pathom', value: 'Nakhon Pathom' },
  { label: 'Nakhon Phanom', value: 'Nakhon Phanom' },
  { label: 'Nakhon Ratchasima', value: 'Nakhon Ratchasima' },
  { label: 'Nakhon Sawan', value: 'Nakhon Sawan' },
  { label: 'Nakhon Si Thammarat', value: 'Nakhon Si Thammarat' },
  { label: 'Nakhon Thai', value: 'Nakhon Thai' },
  { label: 'Nakilabad', value: 'Nakilabad' },
  { label: 'Nakin', value: 'Nakin' },
  { label: 'Nakkila', value: 'Nakkila' },
  { label: 'Naklo', value: 'Naklo' },
  { label: 'Nakło', value: 'Nakło' },
  { label: 'Nakło', value: 'Nakło' },
  { label: 'Náklo', value: 'Náklo' },
  { label: 'Nakło Nad Notecią', value: 'Nakło Nad Notecią' },
  { label: 'Nako', value: 'Nako' },
  { label: 'Nakodar', value: 'Nakodar' },
  { label: 'Nakonde', value: 'Nakonde' },
  { label: 'Nakovo', value: 'Nakovo' },
  { label: 'Naksalbari', value: 'Naksalbari' },
  { label: 'Nakskov', value: 'Nakskov' },
  { label: 'Naktsang', value: 'Naktsang' },
  { label: 'Nakur', value: 'Nakur' },
  { label: 'Nakuru', value: 'Nakuru' },
  { label: 'Nakusp', value: 'Nakusp' },
  { label: 'Nal’Chik', value: 'Nal’Chik' },
  { label: 'Nala Sopara', value: 'Nala Sopara' },
  { label: 'Nalagarh', value: 'Nalagarh' },
  { label: 'Nalanda', value: 'Nalanda' },
  { label: 'Nalbach', value: 'Nalbach' },
  { label: 'Nalbandyan', value: 'Nalbandyan' },
  { label: 'Nalbant', value: 'Nalbant' },
  { label: 'Nalbari', value: 'Nalbari' },
  { label: 'Nalda', value: 'Nalda' },
  { label: 'Naldhara', value: 'Naldhara' },
  { label: 'Naldurg', value: 'Naldurg' },
  { label: 'Nalec', value: 'Nalec' },
  { label: 'Nałęczów', value: 'Nałęczów' },
  { label: 'Nalegaon', value: 'Nalegaon' },
  { label: 'Nalgonda', value: 'Nalgonda' },
  { label: 'Nalhati', value: 'Nalhati' },
  { label: 'Naliya', value: 'Naliya' },
  { label: 'Nalles', value: 'Nalles' },
  { label: 'Nalliers', value: 'Nalliers' },
  { label: 'Nallıhan', value: 'Nallıhan' },
  { label: 'Nalobikha', value: 'Nalobikha' },
  { label: 'Nalous', value: 'Nalous' },
  { label: 'Nalpur', value: 'Nalpur' },
  { label: 'Nalsian Norte', value: 'Nalsian Norte' },
  { label: 'Nalsian Norte', value: 'Nalsian Norte' },
  { label: 'Nalundan', value: 'Nalundan' },
  { label: 'Nalus', value: 'Nalus' },
  { label: 'Nalus', value: 'Nalus' },
  { label: 'Nālūt', value: 'Nālūt' },
  { label: 'Nam Định', value: 'Nam Định' },
  { label: 'Nam Som', value: 'Nam Som' },
  { label: 'Nama', value: 'Nama' },
  { label: 'Nama', value: 'Nama' },
  { label: 'Namaacha District', value: 'Namaacha District' },
  { label: 'Namagiripettai', value: 'Namagiripettai' },
  { label: 'Namakkal', value: 'Namakkal' },
  { label: 'Namakwa District Municipality', value: 'Namakwa District Municipality' },
  { label: 'Namalenga', value: 'Namalenga' },
  { label: 'Naman', value: 'Naman' },
  { label: 'Namanga', value: 'Namanga' },
  { label: 'Namangan', value: 'Namangan' },
  { label: 'Namanyere', value: 'Namanyere' },
  { label: 'Namasigüe', value: 'Namasigüe' },
  { label: 'Namasuba', value: 'Namasuba' },
  { label: 'Namatanai', value: 'Namatanai' },
  { label: 'Nambalan', value: 'Nambalan' },
  { label: 'Nambalan', value: 'Nambalan' },
  { label: 'Nambare', value: 'Nambare' },
  { label: 'Nambe', value: 'Nambe' },
  { label: 'Nambiyur', value: 'Nambiyur' },
  { label: 'Namboongan', value: 'Namboongan' },
  { label: 'Namboongan', value: 'Namboongan' },
  { label: 'Namborn', value: 'Namborn' },
  { label: 'Nambour', value: 'Nambour' },
  { label: 'Nambroca', value: 'Nambroca' },
  { label: 'Nambuangongo', value: 'Nambuangongo' },
  { label: 'Nambucca', value: 'Nambucca' },
  { label: 'Nambucca Heads', value: 'Nambucca Heads' },
  { label: 'Nambucca Shire', value: 'Nambucca Shire' },
  { label: 'Nambutalai', value: 'Nambutalai' },
  { label: 'Namchi', value: 'Namchi' },
  { label: 'Namdalseid', value: 'Namdalseid' },
  { label: 'Namegata', value: 'Namegata' },
  { label: 'Namegata-Shi', value: 'Namegata-Shi' },
  { label: 'Namerikawa', value: 'Namerikawa' },
  { label: 'Namerikawa-Shi', value: 'Namerikawa-Shi' },
  { label: 'Náměšť Na Hané', value: 'Náměšť Na Hané' },
  { label: 'Náměšť Nad Oslavou', value: 'Náměšť Nad Oslavou' },
  { label: 'Námestovo', value: 'Námestovo' },
  { label: 'Nam-Gu', value: 'Nam-Gu' },
  { label: 'Nam-Gu', value: 'Nam-Gu' },
  { label: 'Namhae-Gun', value: 'Namhae-Gun' },
  { label: 'Namie', value: 'Namie' },
  { label: 'Namikupa', value: 'Namikupa' },
  { label: 'Namileh', value: 'Namileh' },
  { label: 'Namin', value: 'Namin' },
  { label: 'Namioka', value: 'Namioka' },
  { label: 'Namiquipa', value: 'Namiquipa' },
  { label: 'Namkhana Community Development Block', value: 'Namkhana Community Development Block' },
  { label: 'Namli', value: 'Namli' },
  { label: 'Namlos', value: 'Namlos' },
  { label: 'Nămoloasa', value: 'Nămoloasa' },
  { label: 'Nămoloasa-Sat', value: 'Nămoloasa-Sat' },
  { label: 'Namoluk Municipality', value: 'Namoluk Municipality' },
  { label: 'Namosi Province', value: 'Namosi Province' },
  { label: 'Namp’O', value: 'Namp’O' },
  { label: 'Nampa', value: 'Nampa' },
  { label: 'Nampicuan', value: 'Nampicuan' },
  { label: 'Nampicuan', value: 'Nampicuan' },
  { label: 'Nampula', value: 'Nampula' },
  { label: 'Nampur', value: 'Nampur' },
  { label: 'Namrup', value: 'Namrup' },
  { label: 'Namskiy Rayon', value: 'Namskiy Rayon' },
  { label: 'Namsos', value: 'Namsos' },
  { label: 'Namsskogan', value: 'Namsskogan' },
  { label: 'Namtsy', value: 'Namtsy' },
  { label: 'Namuac', value: 'Namuac' },
  { label: 'Namuac', value: 'Namuac' },
  { label: 'Namur', value: 'Namur' },
  { label: 'Namwala', value: 'Namwala' },
  { label: 'Namwon', value: 'Namwon' },
  { label: 'Namyang-Dong', value: 'Namyang-Dong' },
  { label: 'Namyangju', value: 'Namyangju' },
  { label: 'Namyangju-Si', value: 'Namyangju-Si' },
  { label: 'Namysłów', value: 'Namysłów' },
  { label: 'Nan', value: 'Nan' },
  { label: 'Nana', value: 'Nana' },
  { label: 'Nana Glen', value: 'Nana Glen' },
  { label: 'Nanacatlán', value: 'Nanacatlán' },
  { label: 'Nanahuatípam', value: 'Nanahuatípam' },
  { label: 'Nanaimo', value: 'Nanaimo' },
  { label: 'Nanaj', value: 'Nanaj' },
  { label: 'Nānākuli', value: 'Nānākuli' },
  { label: 'Nanam', value: 'Nanam' },
  { label: 'Nanango', value: 'Nanango' },
  { label: 'Nanao Shi', value: 'Nanao Shi' },
  { label: 'Nanauta', value: 'Nanauta' },
  { label: 'Nanawale Estates', value: 'Nanawale Estates' },
  { label: 'Nanawua', value: 'Nanawua' },
  { label: 'Nancagua', value: 'Nancagua' },
  { label: 'Nancalobasaan', value: 'Nancalobasaan' },
  { label: 'Nancalobasaan', value: 'Nancalobasaan' },
  { label: 'Nancamarinan', value: 'Nancamarinan' },
  { label: 'Nancamarinan', value: 'Nancamarinan' },
  { label: 'Nance County', value: 'Nance County' },
  { label: 'Nanchang', value: 'Nanchang' },
  { label: 'Nanchital De Lázaro Cárdenas Del Río', value: 'Nanchital De Lázaro Cárdenas Del Río' },
  { label: 'Nanchong', value: 'Nanchong' },
  { label: 'Nancray', value: 'Nancray' },
  { label: 'Nancy', value: 'Nancy' },
  { label: 'Nandaime', value: 'Nandaime' },
  { label: 'Nandambakkam', value: 'Nandambakkam' },
  { label: 'Nandasmo', value: 'Nandasmo' },
  { label: 'Nandayure', value: 'Nandayure' },
  { label: 'Nanded', value: 'Nanded' },
  { label: 'Nandgaon', value: 'Nandgaon' },
  { label: 'Nandgaon', value: 'Nandgaon' },
  { label: 'Nandi Hills', value: 'Nandi Hills' },
  { label: 'Nandigama', value: 'Nandigama' },
  { label: 'Nandigram', value: 'Nandigram' },
  { label: 'Nandikotkur', value: 'Nandikotkur' },
  { label: 'Nanding', value: 'Nanding' },
  { label: 'Nandlstadt', value: 'Nandlstadt' },
  { label: 'Nandnee', value: 'Nandnee' },
  { label: 'Nandra', value: 'Nandra' },
  { label: 'Nandrin', value: 'Nandrin' },
  { label: 'Nandronga And Navosa Province', value: 'Nandronga And Navosa Province' },
  { label: 'Nandu', value: 'Nandu' },
  { label: 'Nandura', value: 'Nandura' },
  { label: 'Nandura Buzurg', value: 'Nandura Buzurg' },
  { label: 'Nandurbar', value: 'Nandurbar' },
  { label: 'Nandy', value: 'Nandy' },
  { label: 'Nandyal', value: 'Nandyal' },
  { label: 'Năneşti', value: 'Năneşti' },
  { label: 'Nănești', value: 'Nănești' },
  { label: 'Náñez González', value: 'Náñez González' },
  { label: 'Nanfeng', value: 'Nanfeng' },
  { label: 'Nang Rong', value: 'Nang Rong' },
  { label: 'Nanga Eboko', value: 'Nanga Eboko' },
  { label: 'Nangal', value: 'Nangal' },
  { label: 'Nangalisan', value: 'Nangalisan' },
  { label: 'Nangalisan', value: 'Nangalisan' },
  { label: 'Nangan', value: 'Nangan' },
  { label: 'Nangan', value: 'Nangan' },
  { label: 'Nangan', value: 'Nangan' },
  { label: 'Nangan', value: 'Nangan' },
  { label: 'Nanganga', value: 'Nanganga' },
  { label: 'Nangavalli', value: 'Nangavalli' },
  { label: 'Nangen', value: 'Nangen' },
  { label: 'Nangi', value: 'Nangi' },
  { label: 'Nangilickondan', value: 'Nangilickondan' },
  { label: 'Nangis', value: 'Nangis' },
  { label: 'Nangka', value: 'Nangka' },
  { label: 'Nangka', value: 'Nangka' },
  { label: 'Nañgka', value: 'Nañgka' },
  { label: 'Nangloi Jat', value: 'Nangloi Jat' },
  { label: 'Nangomba', value: 'Nangomba' },
  { label: 'Nangong', value: 'Nangong' },
  { label: 'Nanguneri', value: 'Nanguneri' },
  { label: 'Nangwa', value: 'Nangwa' },
  { label: 'Nanhyanga', value: 'Nanhyanga' },
  { label: 'Nänikon', value: 'Nänikon' },
  { label: 'Nänikon / Nänikon (Dorfkern)', value: 'Nänikon / Nänikon (Dorfkern)' },
  { label: 'Nanjangud', value: 'Nanjangud' },
  { label: 'Nanjing', value: 'Nanjing' },
  { label: 'Nanjing', value: 'Nanjing' },
  { label: 'Nanjō', value: 'Nanjō' },
  { label: 'Nanjō Shi', value: 'Nanjō Shi' },
  { label: 'Nankana Sahib', value: 'Nankana Sahib' },
  { label: 'Nankoku', value: 'Nankoku' },
  { label: 'Nanlong', value: 'Nanlong' },
  { label: 'Nanma', value: 'Nanma' },
  { label: 'Nanmuping', value: 'Nanmuping' },
  { label: 'Nannestad', value: 'Nannestad' },
  { label: 'Nannilam', value: 'Nannilam' },
  { label: 'Nanning', value: 'Nanning' },
  { label: 'Nanno', value: 'Nanno' },
  { label: 'Nannup', value: 'Nannup' },
  { label: 'Nannyville', value: 'Nannyville' },
  { label: 'Nanov', value: 'Nanov' },
  { label: 'Nanpara', value: 'Nanpara' },
  { label: 'Nanpean', value: 'Nanpean' },
  { label: 'Nanpiao', value: 'Nanpiao' },
  { label: 'Nanping', value: 'Nanping' },
  { label: 'Nansio', value: 'Nansio' },
  { label: 'Nans-Les-Pins', value: 'Nans-Les-Pins' },
  { label: 'Nantai', value: 'Nantai' },
  { label: 'Nantan-Shi', value: 'Nantan-Shi' },
  { label: 'Nanterre', value: 'Nanterre' },
  { label: 'Nantes', value: 'Nantes' },
  { label: 'Nantes', value: 'Nantes' },
  { label: 'Nanteuil', value: 'Nanteuil' },
  { label: 'Nanteuil-En-Vallée', value: 'Nanteuil-En-Vallée' },
  { label: 'Nanteuil-Le-Haudouin', value: 'Nanteuil-Le-Haudouin' },
  { label: 'Nanteuil-Lès-Meaux', value: 'Nanteuil-Lès-Meaux' },
  { label: 'Nantiat', value: 'Nantiat' },
  { label: 'Nanticoke', value: 'Nanticoke' },
  { label: 'Nanto Shi', value: 'Nanto Shi' },
  { label: 'Nanton', value: 'Nanton' },
  { label: 'Nantong', value: 'Nantong' },
  { label: 'Nantong', value: 'Nantong' },
  { label: 'Nanto-Shi', value: 'Nanto-Shi' },
  { label: 'Nantou', value: 'Nantou' },
  { label: 'Nantua', value: 'Nantua' },
  { label: 'Nantucket', value: 'Nantucket' },
  { label: 'Nantucket County', value: 'Nantucket County' },
  { label: 'Nantwich', value: 'Nantwich' },
  { label: 'Nanty Glo', value: 'Nanty Glo' },
  { label: 'Nantzha', value: 'Nantzha' },
  { label: 'Nanuet', value: 'Nanuet' },
  { label: 'Nanum', value: 'Nanum' },
  { label: 'Nanuque', value: 'Nanuque' },
  { label: 'Nanyamba', value: 'Nanyamba' },
  { label: 'Nanyang', value: 'Nanyang' },
  { label: 'Nanyo', value: 'Nanyo' },
  { label: 'Nanyo', value: 'Nanyo' },
  { label: 'Nanyo', value: 'Nanyo' },
  { label: 'Nanyō Shi', value: 'Nanyō Shi' },
  { label: 'Nanyuki', value: 'Nanyuki' },
  { label: 'Nanzdietschweiler', value: 'Nanzdietschweiler' },
  { label: 'Nanzhang Chengguanzhen', value: 'Nanzhang Chengguanzhen' },
  { label: 'Nanzhou', value: 'Nanzhou' },
  { label: 'Não Me Toque', value: 'Não Me Toque' },
  { label: 'Naogaon ', value: 'Naogaon ' },
  { label: 'Naolinco', value: 'Naolinco' },
  { label: 'Naolinco De Victoria', value: 'Naolinco De Victoria' },
  { label: 'Não-Me-Toque', value: 'Não-Me-Toque' },
  { label: 'Naours', value: 'Naours' },
  { label: 'Náousa', value: 'Náousa' },
  { label: 'Náousa', value: 'Náousa' },
  { label: 'Napa', value: 'Napa' },
  { label: 'Napa County', value: 'Napa County' },
  { label: 'Napajedla', value: 'Napajedla' },
  { label: 'Napalitan', value: 'Napalitan' },
  { label: 'Napanee', value: 'Napanee' },
  { label: 'Napanee Downtown', value: 'Napanee Downtown' },
  { label: 'Napanoch', value: 'Napanoch' },
  { label: 'Napari Village', value: 'Napari Village' },
  { label: 'Napasar', value: 'Napasar' },
  { label: 'Napavine', value: 'Napavine' },
  { label: 'Napenay', value: 'Napenay' },
  { label: 'Naperville', value: 'Naperville' },
  { label: 'Napier', value: 'Napier' },
  { label: 'Napierville', value: 'Napierville' },
  { label: 'Napili-Honokowai', value: 'Napili-Honokowai' },
  { label: 'Napkor', value: 'Napkor' },
  { label: 'Naples', value: 'Naples' },
  { label: 'Naples', value: 'Naples' },
  { label: 'Naples', value: 'Naples' },
  { label: 'Naples', value: 'Naples' },
  { label: 'Naples', value: 'Naples' },
  { label: 'Naples Manor', value: 'Naples Manor' },
  { label: 'Naples Park', value: 'Naples Park' },
  { label: 'Napnapan', value: 'Napnapan' },
  { label: 'Napoleon', value: 'Napoleon' },
  { label: 'Napoleon', value: 'Napoleon' },
  { label: 'Napoleon', value: 'Napoleon' },
  { label: 'Napoleonville', value: 'Napoleonville' },
  { label: 'Napoles', value: 'Napoles' },
  { label: 'Napoli', value: 'Napoli' },
  { label: 'Nappanee', value: 'Nappanee' },
  { label: 'Năpradea', value: 'Năpradea' },
  { label: 'Napranum', value: 'Napranum' },
  { label: 'Naprawa', value: 'Naprawa' },
  { label: 'Napuro', value: 'Napuro' },
  { label: 'Napuro', value: 'Napuro' },
  { label: 'Naqadeh', value: 'Naqadeh' },
  { label: 'Naque', value: 'Naque' },
  { label: 'Náquera', value: 'Náquera' },
  { label: 'Narach', value: 'Narach' },
  { label: 'Naracoorte', value: 'Naracoorte' },
  { label: 'Naracoorte And Lucindale', value: 'Naracoorte And Lucindale' },
  { label: 'Naragh', value: 'Naragh' },
  { label: 'Narail ', value: 'Narail ' },
  { label: 'Naraina', value: 'Naraina' },
  { label: 'Naraini', value: 'Naraini' },
  { label: 'Narandiba', value: 'Narandiba' },
  { label: 'Narang Mandi', value: 'Narang Mandi' },
  { label: 'Narangba', value: 'Narangba' },
  { label: 'Naranja', value: 'Naranja' },
  { label: 'Naranja De Tapia', value: 'Naranja De Tapia' },
  { label: 'Naranjal', value: 'Naranjal' },
  { label: 'Naranjal', value: 'Naranjal' },
  { label: 'Naranjal', value: 'Naranjal' },
  { label: 'Naranjito', value: 'Naranjito' },
  { label: 'Naranjito', value: 'Naranjito' },
  { label: 'Naranjito', value: 'Naranjito' },
  { label: 'Naranjito', value: 'Naranjito' },
  { label: 'Naranjito Municipio', value: 'Naranjito Municipio' },
  { label: 'Naranjo', value: 'Naranjo' },
  { label: 'Naranjo', value: 'Naranjo' },
  { label: 'Naranjos', value: 'Naranjos' },
  { label: 'Naranjos Amatlán', value: 'Naranjos Amatlán' },
  { label: 'Narara', value: 'Narara' },
  { label: 'Narasannapeta', value: 'Narasannapeta' },
  { label: 'Narasapur', value: 'Narasapur' },
  { label: 'Narasaraopet', value: 'Narasaraopet' },
  { label: 'Nara-Shi', value: 'Nara-Shi' },
  { label: 'Narashino-Shi', value: 'Narashino-Shi' },
  { label: 'Narasimharajapura', value: 'Narasimharajapura' },
  { label: 'Narasingapuram', value: 'Narasingapuram' },
  { label: 'Narathiwat', value: 'Narathiwat' },
  { label: 'Narauli', value: 'Narauli' },
  { label: 'Naraura', value: 'Naraura' },
  { label: 'Naravarikuppam', value: 'Naravarikuppam' },
  { label: 'Narayanavanam', value: 'Narayanavanam' },
  { label: 'Narayanganj ', value: 'Narayanganj ' },
  { label: 'Narayangaon', value: 'Narayangaon' },
  { label: 'Narayangarh', value: 'Narayangarh' },
  { label: 'Narayangarh', value: 'Narayangarh' },
  { label: 'Narayanpet', value: 'Narayanpet' },
  { label: 'Narayanpur', value: 'Narayanpur' },
  { label: 'Narberth', value: 'Narberth' },
  { label: 'Narberth', value: 'Narberth' },
  { label: 'Narbolia', value: 'Narbolia' },
  { label: 'Narboneta', value: 'Narboneta' },
  { label: 'Narbonne', value: 'Narbonne' },
  { label: 'Narborough', value: 'Narborough' },
  { label: 'Narcao', value: 'Narcao' },
  { label: 'Narciso Mendoza', value: 'Narciso Mendoza' },
  { label: 'Narciso Mendoza', value: 'Narciso Mendoza' },
  { label: 'Narciso Mendoza', value: 'Narciso Mendoza' },
  { label: 'Nardana', value: 'Nardana' },
  { label: 'Nardaran', value: 'Nardaran' },
  { label: 'Nardò', value: 'Nardò' },
  { label: 'Nardodipace', value: 'Nardodipace' },
  { label: 'Naregal', value: 'Naregal' },
  { label: 'Narela', value: 'Narela' },
  { label: 'Narellan', value: 'Narellan' },
  { label: 'Narellan Vale', value: 'Narellan Vale' },
  { label: 'Narembeen', value: 'Narembeen' },
  { label: 'Naremburn', value: 'Naremburn' },
  { label: 'Narendranagar', value: 'Narendranagar' },
  { label: 'Narew', value: 'Narew' },
  { label: 'Narganá', value: 'Narganá' },
  { label: 'Nargis', value: 'Nargis' },
  { label: 'Nargol', value: 'Nargol' },
  { label: 'Nargund', value: 'Nargund' },
  { label: 'Narharpur', value: 'Narharpur' },
  { label: 'Nariman Point', value: 'Nariman Point' },
  { label: 'Narimanov', value: 'Narimanov' },
  { label: 'Nariño', value: 'Nariño' },
  { label: 'Nariño', value: 'Nariño' },
  { label: 'Nariño', value: 'Nariño' },
  { label: 'Narita', value: 'Narita' },
  { label: 'Narita-Shi', value: 'Narita-Shi' },
  { label: 'Nariyah', value: 'Nariyah' },
  { label: 'Nariynteel', value: 'Nariynteel' },
  { label: 'Narjeh', value: 'Narjeh' },
  { label: 'Narkevychi', value: 'Narkevychi' },
  { label: 'Narkhed', value: 'Narkhed' },
  { label: 'Narlıdere', value: 'Narlıdere' },
  { label: 'Narmada', value: 'Narmada' },
  { label: 'Narman', value: 'Narman' },
  { label: 'Narmashir', value: 'Narmashir' },
  { label: 'Narnaul', value: 'Narnaul' },
  { label: 'Narnaund', value: 'Narnaund' },
  { label: 'Narni', value: 'Narni' },
  { label: 'Narni Scalo', value: 'Narni Scalo' },
  { label: 'Naro', value: 'Naro' },
  { label: 'Naro', value: 'Naro' },
  { label: 'Naro Moru', value: 'Naro Moru' },
  { label: 'Naroda', value: 'Naroda' },
  { label: 'Narodychi', value: 'Narodychi' },
  { label: 'Narodychi', value: 'Narodychi' },
  { label: 'Narodychi Raion', value: 'Narodychi Raion' },
  { label: 'Naro-Fominsk', value: 'Naro-Fominsk' },
  { label: 'Narok', value: 'Narok' },
  { label: 'Narol', value: 'Narol' },
  { label: 'Narón', value: 'Narón' },
  { label: 'Narooma', value: 'Narooma' },
  { label: 'Narora', value: 'Narora' },
  { label: 'Narovchat', value: 'Narovchat' },
  { label: 'Narowal', value: 'Narowal' },
  { label: 'Narowlya', value: 'Narowlya' },
  { label: 'Narra', value: 'Narra' },
  { label: 'Narrabeen', value: 'Narrabeen' },
  { label: 'Narrabri', value: 'Narrabri' },
  { label: 'Narrabundah', value: 'Narrabundah' },
  { label: 'Narragansett', value: 'Narragansett' },
  { label: 'Narragansett Pier', value: 'Narragansett Pier' },
  { label: 'Narrandera', value: 'Narrandera' },
  { label: 'Narrawallee', value: 'Narrawallee' },
  { label: 'Narraweena', value: 'Narraweena' },
  { label: 'Narre Warren', value: 'Narre Warren' },
  { label: 'Narre Warren North', value: 'Narre Warren North' },
  { label: 'Narre Warren South', value: 'Narre Warren South' },
  { label: 'Narrillos Del Álamo', value: 'Narrillos Del Álamo' },
  { label: 'Narrillos Del Rebollar', value: 'Narrillos Del Rebollar' },
  { label: 'Narrogin', value: 'Narrogin' },
  { label: 'Narromine', value: 'Narromine' },
  { label: 'Narros', value: 'Narros' },
  { label: 'Narros De Matalayegua', value: 'Narros De Matalayegua' },
  { label: 'Narros De Saldueña', value: 'Narros De Saldueña' },
  { label: 'Narros Del Castillo', value: 'Narros Del Castillo' },
  { label: 'Narros Del Puerto', value: 'Narros Del Puerto' },
  { label: 'Narrosse', value: 'Narrosse' },
  { label: 'Narrows', value: 'Narrows' },
  { label: 'Narsdorf', value: 'Narsdorf' },
  { label: 'Narsimhapur', value: 'Narsimhapur' },
  { label: 'Narsingdi ', value: 'Narsingdi ' },
  { label: 'Narsinghgarh', value: 'Narsinghgarh' },
  { label: 'Narsingi', value: 'Narsingi' },
  { label: 'Narsipatnam', value: 'Narsipatnam' },
  { label: 'Nartan', value: 'Nartan' },
  { label: 'Nartkala', value: 'Nartkala' },
  { label: 'Naruja', value: 'Naruja' },
  { label: 'Năruja', value: 'Năruja' },
  { label: 'Naruszewo', value: 'Naruszewo' },
  { label: 'Narutō', value: 'Narutō' },
  { label: 'Narutochō-Mitsuishi', value: 'Narutochō-Mitsuishi' },
  { label: 'Naruto-Shi', value: 'Naruto-Shi' },
  { label: 'Narva', value: 'Narva' },
  { label: 'Narvacan', value: 'Narvacan' },
  { label: 'Narvacan', value: 'Narvacan' },
  { label: 'Narva-Jõesuu', value: 'Narva-Jõesuu' },
  { label: 'Narva-Jõesuu Linn', value: 'Narva-Jõesuu Linn' },
  { label: 'Narvik', value: 'Narvik' },
  { label: 'Narwana', value: 'Narwana' },
  { label: 'Narwar', value: 'Narwar' },
  { label: 'Narwee', value: 'Narwee' },
  { label: 'Naryan-Mar', value: 'Naryan-Mar' },
  { label: 'Naryn', value: 'Naryn' },
  { label: 'Naryshkino', value: 'Naryshkino' },
  { label: 'Narzole', value: 'Narzole' },
  { label: 'Nasarawa', value: 'Nasarawa' },
  { label: 'Năsăud', value: 'Năsăud' },
  { label: 'Nasavrky', value: 'Nasavrky' },
  { label: 'Naschel', value: 'Naschel' },
  { label: 'Nash', value: 'Nash' },
  { label: 'Nash County', value: 'Nash County' },
  { label: 'Nashik', value: 'Nashik' },
  { label: 'Nashik Division', value: 'Nashik Division' },
  { label: 'Nashotah', value: 'Nashotah' },
  { label: 'Nashtarud', value: 'Nashtarud' },
  { label: 'Nashtifan', value: 'Nashtifan' },
  { label: 'Nashua', value: 'Nashua' },
  { label: 'Nashua', value: 'Nashua' },
  { label: 'Nashville', value: 'Nashville' },
  { label: 'Nashville', value: 'Nashville' },
  { label: 'Nashville', value: 'Nashville' },
  { label: 'Nashville', value: 'Nashville' },
  { label: 'Nashville', value: 'Nashville' },
  { label: 'Nashville', value: 'Nashville' },
  { label: 'Nashville', value: 'Nashville' },
  { label: 'Nashville', value: 'Nashville' },
  { label: 'Našice', value: 'Našice' },
  { label: 'Nasielsk', value: 'Nasielsk' },
  { label: 'Nasilava', value: 'Nasilava' },
  { label: 'Nasimshahr', value: 'Nasimshahr' },
  { label: 'Nasino-Borgo', value: 'Nasino-Borgo' },
  { label: 'Nasipit', value: 'Nasipit' },
  { label: 'Nasipit', value: 'Nasipit' },
  { label: 'Nasirabad', value: 'Nasirabad' },
  { label: 'Nasirabad', value: 'Nasirabad' },
  { label: 'Nasīrābād District', value: 'Nasīrābād District' },
  { label: 'Nasiriyah', value: 'Nasiriyah' },
  { label: 'Nasirshahr', value: 'Nasirshahr' },
  { label: 'Naso', value: 'Naso' },
  { label: 'Naspur', value: 'Naspur' },
  { label: 'Nasr Abad', value: 'Nasr Abad' },
  { label: 'Nasrabad', value: 'Nasrabad' },
  { label: 'Nasriganj', value: 'Nasriganj' },
  { label: 'Nasrullahganj', value: 'Nasrullahganj' },
  { label: 'Nassandres', value: 'Nassandres' },
  { label: 'Nassau', value: 'Nassau' },
  { label: 'Nassau', value: 'Nassau' },
  { label: 'Nassau', value: 'Nassau' },
  { label: 'Nassau Bay', value: 'Nassau Bay' },
  { label: 'Nassau County', value: 'Nassau County' },
  { label: 'Nassau County', value: 'Nassau County' },
  { label: 'Nassau Village-Ratliff', value: 'Nassau Village-Ratliff' },
  { label: 'Nassereith', value: 'Nassereith' },
  { label: 'Nässjö', value: 'Nässjö' },
  { label: 'Nassogne', value: 'Nassogne' },
  { label: 'Nastätten', value: 'Nastätten' },
  { label: 'Nastola', value: 'Nastola' },
  { label: 'Năsturelu', value: 'Năsturelu' },
  { label: 'Nasugbu', value: 'Nasugbu' },
  { label: 'Nasukarasuyama', value: 'Nasukarasuyama' },
  { label: 'Nasukarasuyama-Shi', value: 'Nasukarasuyama-Shi' },
  { label: 'Näsum', value: 'Näsum' },
  { label: 'Nasushiobara-Shi', value: 'Nasushiobara-Shi' },
  { label: 'Nasypnoe', value: 'Nasypnoe' },
  { label: 'Naszály', value: 'Naszály' },
  { label: 'Nata', value: 'Nata' },
  { label: 'Natá', value: 'Natá' },
  { label: 'Nataga', value: 'Nataga' },
  { label: 'Natagaima', value: 'Natagaima' },
  { label: 'Natal', value: 'Natal' },
  { label: 'Natalândia', value: 'Natalândia' },
  { label: 'Natalbany', value: 'Natalbany' },
  { label: 'Natales', value: 'Natales' },
  { label: 'Natalia', value: 'Natalia' },
  { label: 'Natalicio Talavera', value: 'Natalicio Talavera' },
  { label: 'Natalio', value: 'Natalio' },
  { label: 'Natalungan', value: 'Natalungan' },
  { label: 'Natanz', value: 'Natanz' },
  { label: 'Natchez', value: 'Natchez' },
  { label: 'Natchitoches', value: 'Natchitoches' },
  { label: 'Natchitoches Parish', value: 'Natchitoches Parish' },
  { label: 'Natércia', value: 'Natércia' },
  { label: 'Naters', value: 'Naters' },
  { label: 'Nathalia', value: 'Nathalia' },
  { label: 'Nathan', value: 'Nathan' },
  { label: 'Nathdwara', value: 'Nathdwara' },
  { label: 'Nati', value: 'Nati' },
  { label: 'Natick', value: 'Natick' },
  { label: 'Natile Nuovo', value: 'Natile Nuovo' },
  { label: 'National Capital District', value: 'National Capital District' },
  { label: 'National Capital Region', value: 'National Capital Region' },
  { label: 'National City', value: 'National City' },
  { label: 'National Harbor', value: 'National Harbor' },
  { label: 'National Park', value: 'National Park' },
  { label: 'Natitingou', value: 'Natitingou' },
  { label: 'Natividad', value: 'Natividad' },
  { label: 'Natividad', value: 'Natividad' },
  { label: 'Natividade', value: 'Natividade' },
  { label: 'Natividade', value: 'Natividade' },
  { label: 'Natividade Da Serra', value: 'Natividade Da Serra' },
  { label: 'Nativitas', value: 'Nativitas' },
  { label: 'Natívitas', value: 'Natívitas' },
  { label: 'Nato', value: 'Nato' },
  { label: 'Nato', value: 'Nato' },
  { label: 'Natonin', value: 'Natonin' },
  { label: 'Natonin', value: 'Natonin' },
  { label: 'Natore ', value: 'Natore ' },
  { label: 'Natori Shi', value: 'Natori Shi' },
  { label: 'Natrona County', value: 'Natrona County' },
  { label: 'Natsional’Nyy Rayon Nemetskiy', value: 'Natsional’Nyy Rayon Nemetskiy' },
  { label: 'Nattam', value: 'Nattam' },
  { label: 'Nattapian', value: 'Nattapian' },
  { label: 'Nattapian', value: 'Nattapian' },
  { label: 'Nattarasankottai', value: 'Nattarasankottai' },
  { label: 'Natternbach', value: 'Natternbach' },
  { label: 'Natters', value: 'Natters' },
  { label: 'Nattheim', value: 'Nattheim' },
  { label: 'Nättraby', value: 'Nättraby' },
  { label: 'Natuba', value: 'Natuba' },
  { label: 'Natubleng', value: 'Natubleng' },
  { label: 'Natubleng', value: 'Natubleng' },
  { label: 'Naturno', value: 'Naturno' },
  { label: 'Natyrbovo', value: 'Natyrbovo' },
  { label: 'Naucalpan De Juárez', value: 'Naucalpan De Juárez' },
  { label: 'Naucelle', value: 'Naucelle' },
  { label: 'Naucelles', value: 'Naucelles' },
  { label: 'Nauchnyy Gorodok', value: 'Nauchnyy Gorodok' },
  { label: 'Naudero', value: 'Naudero' },
  { label: 'Nauders', value: 'Nauders' },
  { label: 'Nauen', value: 'Nauen' },
  { label: 'Nauendorf', value: 'Nauendorf' },
  { label: 'Naugachhia', value: 'Naugachhia' },
  { label: 'Naugama', value: 'Naugama' },
  { label: 'Naugatuck', value: 'Naugatuck' },
  { label: 'Naujamiestis', value: 'Naujamiestis' },
  { label: 'Naujan', value: 'Naujan' },
  { label: 'Naujoji Akmene', value: 'Naujoji Akmene' },
  { label: 'Naukot', value: 'Naukot' },
  { label: 'Naukšēni', value: 'Naukšēni' },
  { label: 'Naumburg', value: 'Naumburg' },
  { label: 'Naumburg', value: 'Naumburg' },
  { label: 'Naundorf', value: 'Naundorf' },
  { label: 'Naunhof', value: 'Naunhof' },
  { label: 'Nauort', value: 'Nauort' },
  { label: 'Naupan', value: 'Naupan' },
  { label: 'Naurangpur', value: 'Naurangpur' },
  { label: 'Nauroth', value: 'Nauroth' },
  { label: 'Naurskaya', value: 'Naurskaya' },
  { label: 'Naurskiy Rayon', value: 'Naurskiy Rayon' },
  { label: 'Naushahra Virkan', value: 'Naushahra Virkan' },
  { label: 'Naushahro Firoz', value: 'Naushahro Firoz' },
  { label: 'Naushki', value: 'Naushki' },
  { label: 'Naustdal', value: 'Naustdal' },
  { label: 'Naut Aran', value: 'Naut Aran' },
  { label: 'Nauta', value: 'Nauta' },
  { label: 'Nautanwa', value: 'Nautanwa' },
  { label: 'Nautla', value: 'Nautla' },
  { label: 'Nauvoo', value: 'Nauvoo' },
  { label: 'Nauwalde', value: 'Nauwalde' },
  { label: 'Nauzontla', value: 'Nauzontla' },
  { label: 'Nava', value: 'Nava' },
  { label: 'Nava', value: 'Nava' },
  { label: 'Nava', value: 'Nava' },
  { label: 'Nava De Arévalo', value: 'Nava De Arévalo' },
  { label: 'Nava De Béjar', value: 'Nava De Béjar' },
  { label: 'Nava De Francia', value: 'Nava De Francia' },
  { label: 'Nava De La Asunción', value: 'Nava De La Asunción' },
  { label: 'Nava De Ricomalillo La', value: 'Nava De Ricomalillo La' },
  { label: 'Nava De Roa', value: 'Nava De Roa' },
  { label: 'Nava De Santiago La', value: 'Nava De Santiago La' },
  { label: 'Nava De Sotrobal', value: 'Nava De Sotrobal' },
  { label: 'Nava Del Barco', value: 'Nava Del Barco' },
  { label: 'Nava Del Rey', value: 'Nava Del Rey' },
  { label: 'Nava La', value: 'Nava La' },
  { label: 'Navacarros', value: 'Navacarros' },
  { label: 'Navacepedilla De Corneja', value: 'Navacepedilla De Corneja' },
  { label: 'Navacerrada', value: 'Navacerrada' },
  { label: 'Navaconcejo', value: 'Navaconcejo' },
  { label: 'Navadijos', value: 'Navadijos' },
  { label: 'Navaescurial', value: 'Navaescurial' },
  { label: 'Navafría', value: 'Navafría' },
  { label: 'Navahermosa', value: 'Navahermosa' },
  { label: 'Navahondilla', value: 'Navahondilla' },
  { label: 'Navailles-Angos', value: 'Navailles-Angos' },
  { label: 'Navajas', value: 'Navajas' },
  { label: 'Navajas', value: 'Navajas' },
  { label: 'Navajo', value: 'Navajo' },
  { label: 'Navajo County', value: 'Navajo County' },
  { label: 'Navajún', value: 'Navajún' },
  { label: 'Naval', value: 'Naval' },
  { label: 'Naval', value: 'Naval' },
  { label: 'Naval', value: 'Naval' },
  { label: 'Naval Academy', value: 'Naval Academy' },
  { label: 'Navalacruz', value: 'Navalacruz' },
  { label: 'Navalafuente', value: 'Navalafuente' },
  { label: 'Navalagamella', value: 'Navalagamella' },
  { label: 'Navalcán', value: 'Navalcán' },
  { label: 'Navalcarnero', value: 'Navalcarnero' },
  { label: 'Navaleno', value: 'Navaleno' },
  { label: 'Navales', value: 'Navales' },
  { label: 'Navalgund', value: 'Navalgund' },
  { label: 'Navalilla', value: 'Navalilla' },
  { label: 'Navalmanzano', value: 'Navalmanzano' },
  { label: 'Navalmoral', value: 'Navalmoral' },
  { label: 'Navalmoral De Béjar', value: 'Navalmoral De Béjar' },
  { label: 'Navalmoral De La Mata', value: 'Navalmoral De La Mata' },
  { label: 'Navalmoralejo', value: 'Navalmoralejo' },
  { label: 'Navalmorales Los', value: 'Navalmorales Los' },
  { label: 'Navalonguilla', value: 'Navalonguilla' },
  { label: 'Navalosa', value: 'Navalosa' },
  { label: 'Navalperal De Pinares', value: 'Navalperal De Pinares' },
  { label: 'Navalperal De Tormes', value: 'Navalperal De Tormes' },
  { label: 'Navalpino', value: 'Navalpino' },
  { label: 'Navalquejigo', value: 'Navalquejigo' },
  { label: 'Navalucillos Los', value: 'Navalucillos Los' },
  { label: 'Navaluenga', value: 'Navaluenga' },
  { label: 'Navalvillar De Ibor', value: 'Navalvillar De Ibor' },
  { label: 'Navalvillar De Pela', value: 'Navalvillar De Pela' },
  { label: 'Navamorales', value: 'Navamorales' },
  { label: 'Navamorcuende', value: 'Navamorcuende' },
  { label: 'Navan', value: 'Navan' },
  { label: 'Navapolatsk', value: 'Navapolatsk' },
  { label: 'Navapur', value: 'Navapur' },
  { label: 'Navaquesera', value: 'Navaquesera' },
  { label: 'Navarcles', value: 'Navarcles' },
  { label: 'Navardún', value: 'Navardún' },
  { label: 'Navares De Ayuso', value: 'Navares De Ayuso' },
  { label: 'Navares De Enmedio', value: 'Navares De Enmedio' },
  { label: 'Navares De Las Cuevas', value: 'Navares De Las Cuevas' },
  { label: 'Navaria', value: 'Navaria' },
  { label: 'Navaridas', value: 'Navaridas' },
  { label: 'Navarre', value: 'Navarre' },
  { label: 'Navarre', value: 'Navarre' },
  { label: 'Navarredonda De Gredos', value: 'Navarredonda De Gredos' },
  { label: 'Navarredonda De La Rinconada', value: 'Navarredonda De La Rinconada' },
  { label: 'Navarredonda Y San Mamés', value: 'Navarredonda Y San Mamés' },
  { label: 'Navarredondilla', value: 'Navarredondilla' },
  { label: 'Navarrenx', value: 'Navarrenx' },
  { label: 'Navarrés', value: 'Navarrés' },
  { label: 'Navarrete', value: 'Navarrete' },
  { label: 'Navarrete', value: 'Navarrete' },
  { label: 'Navarrevisca', value: 'Navarrevisca' },
  { label: 'Navarro County', value: 'Navarro County' },
  { label: 'Navas', value: 'Navas' },
  { label: 'Navas De Bureba', value: 'Navas De Bureba' },
  { label: 'Navas De Estena', value: 'Navas De Estena' },
  { label: 'Navas De Jadraque Las', value: 'Navas De Jadraque Las' },
  { label: 'Navas De Jorquera', value: 'Navas De Jorquera' },
  { label: 'Navas De La Concepción Las', value: 'Navas De La Concepción Las' },
  { label: 'Navas De Oro', value: 'Navas De Oro' },
  { label: 'Navas De Riofrío', value: 'Navas De Riofrío' },
  { label: 'Navas De San Antonio', value: 'Navas De San Antonio' },
  { label: 'Navas De San Juan', value: 'Navas De San Juan' },
  { label: 'Navas Del Madroño', value: 'Navas Del Madroño' },
  { label: 'Navas Del Marqués Las', value: 'Navas Del Marqués Las' },
  { label: 'Navas Del Rey', value: 'Navas Del Rey' },
  { label: 'Navascués', value: 'Navascués' },
  { label: 'Navascués/Nabaskoze', value: 'Navascués/Nabaskoze' },
  { label: 'Navasfrías', value: 'Navasfrías' },
  { label: 'Navashino', value: 'Navashino' },
  { label: 'Navasota', value: 'Navasota' },
  { label: 'Navassa', value: 'Navassa' },
  { label: 'Navata', value: 'Navata' },
  { label: 'Navatalgordo', value: 'Navatalgordo' },
  { label: 'Navatat', value: 'Navatat' },
  { label: 'Navatat', value: 'Navatat' },
  { label: 'Navatejares', value: 'Navatejares' },
  { label: 'Nave', value: 'Nave' },
  { label: 'Nave', value: 'Nave' },
  { label: 'Nave De Haver', value: 'Nave De Haver' },
  { label: 'Nave San Rocco', value: 'Nave San Rocco' },
  { label: 'Navedano', value: 'Navedano' },
  { label: 'Navegantes', value: 'Navegantes' },
  { label: 'Naveil', value: 'Naveil' },
  { label: 'Navelim', value: 'Navelim' },
  { label: 'Navelli', value: 'Navelli' },
  { label: 'Navenby', value: 'Navenby' },
  { label: 'Navenchauc', value: 'Navenchauc' },
  { label: 'Navenne', value: 'Navenne' },
  { label: 'Naves', value: 'Naves' },
  { label: 'Navès', value: 'Navès' },
  { label: 'Navesink', value: 'Navesink' },
  { label: 'Navezuelas', value: 'Navezuelas' },
  { label: 'Navi Mumbai', value: 'Navi Mumbai' },
  { label: 'Navia', value: 'Navia' },
  { label: 'Navia De Suarna', value: 'Navia De Suarna' },
  { label: 'Navianos De Valverde', value: 'Navianos De Valverde' },
  { label: 'Navidad', value: 'Navidad' },
  { label: 'Naviraí', value: 'Naviraí' },
  { label: 'Navlinskiy Rayon', value: 'Navlinskiy Rayon' },
  { label: 'Navlya', value: 'Navlya' },
  { label: 'Năvodari', value: 'Năvodari' },
  { label: 'Năvodari', value: 'Năvodari' },
  { label: 'Navoiy', value: 'Navoiy' },
  { label: 'Navojoa', value: 'Navojoa' },
  { label: 'Navolato', value: 'Navolato' },
  { label: 'Navoloki', value: 'Navoloki' },
  { label: 'Navotas', value: 'Navotas' },
  { label: 'Navotas', value: 'Navotas' },
  { label: 'Navrongo', value: 'Navrongo' },
  { label: 'Navsari', value: 'Navsari' },
  { label: 'Návsí U Jablunkova', value: 'Návsí U Jablunkova' },
  { label: 'Navur', value: 'Navur' },
  { label: 'Navy Yard City', value: 'Navy Yard City' },
  { label: 'Nawa', value: 'Nawa' },
  { label: 'Nawa', value: 'Nawa' },
  { label: 'Nawá', value: 'Nawá' },
  { label: 'Nawabganj', value: 'Nawabganj' },
  { label: 'Nawabganj (Barabanki)', value: 'Nawabganj (Barabanki)' },
  { label: 'Nawabganj (Bareilly)', value: 'Nawabganj (Bareilly)' },
  { label: 'Nawabshah', value: 'Nawabshah' },
  { label: 'Nawada', value: 'Nawada' },
  { label: 'Nawae', value: 'Nawae' },
  { label: 'Nawalapitiya', value: 'Nawalapitiya' },
  { label: 'Nawalgarh', value: 'Nawalgarh' },
  { label: 'Nawalparasi (West Of Bardaghat Susta)', value: 'Nawalparasi (West Of Bardaghat Susta)' },
  { label: 'Nawalpur ', value: 'Nawalpur ' },
  { label: 'Nawanshahr', value: 'Nawanshahr' },
  { label: 'Nawanshahr', value: 'Nawanshahr' },
  { label: 'Nawerewere Village', value: 'Nawerewere Village' },
  { label: 'Nawojowa', value: 'Nawojowa' },
  { label: 'Náxos', value: 'Náxos' },
  { label: 'Naxxar', value: 'Naxxar' },
  { label: 'Nay', value: 'Nay' },
  { label: 'Nay Pyi Taw', value: 'Nay Pyi Taw' },
  { label: 'Naya Bazar', value: 'Naya Bazar' },
  { label: 'Nayagarh', value: 'Nayagarh' },
  { label: 'Nayagarh District', value: 'Nayagarh District' },
  { label: 'Nayagram Community Development Block', value: 'Nayagram Community Development Block' },
  { label: 'Naydenovka', value: 'Naydenovka' },
  { label: 'Nayoro', value: 'Nayoro' },
  { label: 'Nayoro Shi', value: 'Nayoro Shi' },
  { label: 'Nayudupet', value: 'Nayudupet' },
  { label: 'Nazar', value: 'Nazar' },
  { label: 'Nazarabad', value: 'Nazarabad' },
  { label: 'Nazaré', value: 'Nazaré' },
  { label: 'Nazaré', value: 'Nazaré' },
  { label: 'Nazaré', value: 'Nazaré' },
  { label: 'Nazaré Da Mata', value: 'Nazaré Da Mata' },
  { label: 'Nazaré Do Piauí', value: 'Nazaré Do Piauí' },
  { label: 'Nazaré Paulista', value: 'Nazaré Paulista' },
  { label: 'Nazareno', value: 'Nazareno' },
  { label: 'Nazareno', value: 'Nazareno' },
  { label: 'Nazareno Etla', value: 'Nazareno Etla' },
  { label: 'Nazaret', value: 'Nazaret' },
  { label: 'Nazareth', value: 'Nazareth' },
  { label: 'Nazareth', value: 'Nazareth' },
  { label: 'Nazareth', value: 'Nazareth' },
  { label: 'Nazarezinho', value: 'Nazarezinho' },
  { label: 'Nazária', value: 'Nazária' },
  { label: 'Nazário', value: 'Nazário' },
  { label: 'Nazarje', value: 'Nazarje' },
  { label: 'Nazarkahrizi', value: 'Nazarkahrizi' },
  { label: 'Nazarovo', value: 'Nazarovo' },
  { label: 'Nazarovskiy Rayon', value: 'Nazarovskiy Rayon' },
  { label: 'Nazaryevo', value: 'Nazaryevo' },
  { label: 'Nazas', value: 'Nazas' },
  { label: 'Nazca', value: 'Nazca' },
  { label: 'Naze', value: 'Naze' },
  { label: 'Nazelles-Négron', value: 'Nazelles-Négron' },
  { label: 'Nazilli', value: 'Nazilli' },
  { label: 'Nazımiye', value: 'Nazımiye' },
  { label: 'Nazir Town', value: 'Nazir Town' },
  { label: 'Nazira', value: 'Nazira' },
  { label: 'Nazna', value: 'Nazna' },
  { label: 'Nazok-E Olya', value: 'Nazok-E Olya' },
  { label: 'Nazran’', value: 'Nazran’' },
  { label: 'Nazranovskiy Rayon', value: 'Nazranovskiy Rayon' },
  { label: 'Nazrēt', value: 'Nazrēt' },
  { label: 'Naz-Sciaves - Natz-Schabs', value: 'Naz-Sciaves - Natz-Schabs' },
  { label: 'Nazul', value: 'Nazul' },
  { label: 'Nazyvayevsk', value: 'Nazyvayevsk' },
  { label: 'Nazzano', value: 'Nazzano' },
  { label: 'Nchelenge', value: 'Nchelenge' },
  { label: 'Ncue', value: 'Ncue' },
  { label: 'Ndago', value: 'Ndago' },
  { label: 'Ndélé', value: 'Ndélé' },
  { label: 'Ndelele', value: 'Ndelele' },
  { label: 'Ndendé', value: 'Ndendé' },
  { label: 'Ndibène Dahra', value: 'Ndibène Dahra' },
  { label: 'Ndikiniméki', value: 'Ndikiniméki' },
  { label: 'Ndioum', value: 'Ndioum' },
  { label: 'Ndjamena', value: 'Ndjamena' },
  { label: 'Ndjolé', value: 'Ndjolé' },
  { label: 'Ndofane', value: 'Ndofane' },
  { label: 'Ndola', value: 'Ndola' },
  { label: 'Ndom', value: 'Ndom' },
  { label: 'Ndwedwe', value: 'Ndwedwe' },
  { label: 'Néa Alikarnassós', value: 'Néa Alikarnassós' },
  { label: 'Néa Anatolí', value: 'Néa Anatolí' },
  { label: 'Néa Anchiálos', value: 'Néa Anchiálos' },
  { label: 'Néa Apollonía', value: 'Néa Apollonía' },
  { label: 'Néa Artáki', value: 'Néa Artáki' },
  { label: 'Néa Chalkidóna', value: 'Néa Chalkidóna' },
  { label: 'Néa Éfesos', value: 'Néa Éfesos' },
  { label: 'Néa Erythraía', value: 'Néa Erythraía' },
  { label: 'Néa Filadélfeia', value: 'Néa Filadélfeia' },
  { label: 'Néa Flogitá', value: 'Néa Flogitá' },
  { label: 'Néa Fókaia', value: 'Néa Fókaia' },
  { label: 'Néa Ionía', value: 'Néa Ionía' },
  { label: 'Néa Ionía', value: 'Néa Ionía' },
  { label: 'Néa Iraklítsa', value: 'Néa Iraklítsa' },
  { label: 'Néa Kallikráteia', value: 'Néa Kallikráteia' },
  { label: 'Néa Karváli', value: 'Néa Karváli' },
  { label: 'Néa Karyá', value: 'Néa Karyá' },
  { label: 'Néa Kíos', value: 'Néa Kíos' },
  { label: 'Néa Lámpsakos', value: 'Néa Lámpsakos' },
  { label: 'Nea Lava', value: 'Nea Lava' },
  { label: 'Néa Magnisía', value: 'Néa Magnisía' },
  { label: 'Néa Mákri', value: 'Néa Mákri' },
  { label: 'Néa Málgara', value: 'Néa Málgara' },
  { label: 'Néa Manoláda', value: 'Néa Manoláda' },
  { label: 'Néa Mesimvría', value: 'Néa Mesimvría' },
  { label: 'Néa Michanióna', value: 'Néa Michanióna' },
  { label: 'Néa Moudhaniá', value: 'Néa Moudhaniá' },
  { label: 'Néa Palátia', value: 'Néa Palátia' },
  { label: 'Néa Pélla', value: 'Néa Pélla' },
  { label: 'Néa Pentéli', value: 'Néa Pentéli' },
  { label: 'Néa Péramos', value: 'Néa Péramos' },
  { label: 'Néa Péramos', value: 'Néa Péramos' },
  { label: 'Néa Plágia', value: 'Néa Plágia' },
  { label: 'Néa Poteídaia', value: 'Néa Poteídaia' },
  { label: 'Néa Potídhaia', value: 'Néa Potídhaia' },
  { label: 'Néa Róda', value: 'Néa Róda' },
  { label: 'Néa Sánta', value: 'Néa Sánta' },
  { label: 'Néa Seléfkeia', value: 'Néa Seléfkeia' },
  { label: 'Néa Smýrni', value: 'Néa Smýrni' },
  { label: 'Néa Stíra', value: 'Néa Stíra' },
  { label: 'Néa Tírins', value: 'Néa Tírins' },
  { label: 'Néa Tríglia', value: 'Néa Tríglia' },
  { label: 'Néa Vrasná', value: 'Néa Vrasná' },
  { label: 'Néa Výssa', value: 'Néa Výssa' },
  { label: 'Néa Zíchni', value: 'Néa Zíchni' },
  { label: 'Neagra', value: 'Neagra' },
  { label: 'Neagra Șarului', value: 'Neagra Șarului' },
  { label: 'Nealtican', value: 'Nealtican' },
  { label: 'Neapoli', value: 'Neapoli' },
  { label: 'Neápoli', value: 'Neápoli' },
  { label: 'Neápolis', value: 'Neápolis' },
  { label: 'Near North Side', value: 'Near North Side' },
  { label: 'Near South Side', value: 'Near South Side' },
  { label: 'Neath', value: 'Neath' },
  { label: 'Neath Port Talbot', value: 'Neath Port Talbot' },
  { label: 'Neaua', value: 'Neaua' },
  { label: 'Neaufles-Saint-Martin', value: 'Neaufles-Saint-Martin' },
  { label: 'Neauphle-Le-Château', value: 'Neauphle-Le-Château' },
  { label: 'Neba', value: 'Neba' },
  { label: 'Nebaj', value: 'Nebaj' },
  { label: 'Nebbi', value: 'Nebbi' },
  { label: 'Nebbiuno', value: 'Nebbiuno' },
  { label: 'Nebelberg', value: 'Nebelberg' },
  { label: 'Nebelschütz', value: 'Nebelschütz' },
  { label: 'Nébian', value: 'Nébian' },
  { label: 'Nebikon', value: 'Nebikon' },
  { label: 'Nebo', value: 'Nebo' },
  { label: 'Nebolchi', value: 'Nebolchi' },
  { label: 'Nebra', value: 'Nebra' },
  { label: 'Nebraska City', value: 'Nebraska City' },
  { label: 'Nebreda', value: 'Nebreda' },
  { label: 'Nebug', value: 'Nebug' },
  { label: 'Nebyliv', value: 'Nebyliv' },
  { label: 'Necaxa', value: 'Necaxa' },
  { label: 'Nechanice', value: 'Nechanice' },
  { label: 'Nechí', value: 'Nechí' },
  { label: 'Neck', value: 'Neck' },
  { label: 'Neckarbischofsheim', value: 'Neckarbischofsheim' },
  { label: 'Neckargemünd', value: 'Neckargemünd' },
  { label: 'Neckargerach', value: 'Neckargerach' },
  { label: 'Neckarsteinach', value: 'Neckarsteinach' },
  { label: 'Neckarsulm', value: 'Neckarsulm' },
  { label: 'Neckartailfingen', value: 'Neckartailfingen' },
  { label: 'Neckartenzlingen', value: 'Neckartenzlingen' },
  { label: 'Neckarwestheim', value: 'Neckarwestheim' },
  { label: 'Neckarzimmern', value: 'Neckarzimmern' },
  { label: 'Neckenmarkt', value: 'Neckenmarkt' },
  { label: 'Necoclí', value: 'Necoclí' },
  { label: 'Necoxcalco', value: 'Necoxcalco' },
  { label: 'Necoxtla', value: 'Necoxtla' },
  { label: 'Necoxtla', value: 'Necoxtla' },
  { label: 'Necşeşti', value: 'Necşeşti' },
  { label: 'Necton', value: 'Necton' },
  { label: 'Neda', value: 'Neda' },
  { label: 'Nedakonice', value: 'Nedakonice' },
  { label: 'Nedašov', value: 'Nedašov' },
  { label: 'Nedeia', value: 'Nedeia' },
  { label: 'Nedelea', value: 'Nedelea' },
  { label: 'Nedelino', value: 'Nedelino' },
  { label: 'Nedelišće', value: 'Nedelišće' },
  { label: 'Nedeljanec', value: 'Nedeljanec' },
  { label: 'Neder Holluf', value: 'Neder Holluf' },
  { label: 'Neder Vindinge', value: 'Neder Vindinge' },
  { label: 'Neder-Hardinxveld', value: 'Neder-Hardinxveld' },
  { label: 'Nederhemert', value: 'Nederhemert' },
  { label: 'Nederhemert-Noord', value: 'Nederhemert-Noord' },
  { label: 'Nederhorst Den Berg', value: 'Nederhorst Den Berg' },
  { label: 'Nederland', value: 'Nederland' },
  { label: 'Nederland', value: 'Nederland' },
  { label: 'Nederweert', value: 'Nederweert' },
  { label: 'Nederwoud', value: 'Nederwoud' },
  { label: 'Nedlands', value: 'Nedlands' },
  { label: 'Nedoharky', value: 'Nedoharky' },
  { label: 'Nedre Eiker', value: 'Nedre Eiker' },
  { label: 'Nedroma', value: 'Nedroma' },
  { label: 'Nedrow', value: 'Nedrow' },
  { label: 'Nedryhayliv', value: 'Nedryhayliv' },
  { label: 'Nedumangad', value: 'Nedumangad' },
  { label: 'Nedvědice', value: 'Nedvědice' },
  { label: 'Nedvigovka', value: 'Nedvigovka' },
  { label: 'Nędza', value: 'Nędza' },
  { label: 'Neebing', value: 'Neebing' },
  { label: 'Needamangalam', value: 'Needamangalam' },
  { label: 'Neede', value: 'Neede' },
  { label: 'Needham', value: 'Needham' },
  { label: 'Needham Market', value: 'Needham Market' },
  { label: 'Needingworth', value: 'Needingworth' },
  { label: 'Needles', value: 'Needles' },
  { label: 'Needville', value: 'Needville' },
  { label: 'Neelankarai', value: 'Neelankarai' },
  { label: 'Neem Ka Thana', value: 'Neem Ka Thana' },
  { label: 'Neemuch', value: 'Neemuch' },
  { label: 'Neenah', value: 'Neenah' },
  { label: 'Neepawa', value: 'Neepawa' },
  { label: 'Neerach', value: 'Neerach' },
  { label: 'Neerbeek', value: 'Neerbeek' },
  { label: 'Neerim South', value: 'Neerim South' },
  { label: 'Neerkant', value: 'Neerkant' },
  { label: 'Neerpelt', value: 'Neerpelt' },
  { label: 'Nefat ‘Akko', value: 'Nefat ‘Akko' },
  { label: 'Nefta', value: 'Nefta' },
  { label: 'Neftçala', value: 'Neftçala' },
  { label: 'Neftegorsk', value: 'Neftegorsk' },
  { label: 'Neftegorsk', value: 'Neftegorsk' },
  { label: 'Neftegorskiy Rayon', value: 'Neftegorskiy Rayon' },
  { label: 'Neftekamsk', value: 'Neftekamsk' },
  { label: 'Neftekumsk', value: 'Neftekumsk' },
  { label: 'Neftenbach / Dorf Neftenbach', value: 'Neftenbach / Dorf Neftenbach' },
  { label: 'Nefteyugansk', value: 'Nefteyugansk' },
  { label: 'Neftobod', value: 'Neftobod' },
  { label: 'Nefyn', value: 'Nefyn' },
  { label: 'Nefza', value: 'Nefza' },
  { label: 'Negapatam', value: 'Negapatam' },
  { label: 'Negar', value: 'Negar' },
  { label: 'Negar Khatun', value: 'Negar Khatun' },
  { label: 'Negara', value: 'Negara' },
  { label: 'Negarabad', value: 'Negarabad' },
  { label: 'Negaunee', value: 'Negaunee' },
  { label: 'Negernbötel', value: 'Negernbötel' },
  { label: 'Neghab', value: 'Neghab' },
  { label: 'Neginshahr', value: 'Neginshahr' },
  { label: 'Negoești', value: 'Negoești' },
  { label: 'Negoi', value: 'Negoi' },
  { label: 'Negoiești', value: 'Negoiești' },
  { label: 'Negoiești', value: 'Negoiești' },
  { label: 'Negombo', value: 'Negombo' },
  { label: 'Negomir', value: 'Negomir' },
  { label: 'Negoslavci', value: 'Negoslavci' },
  { label: 'Negostina', value: 'Negostina' },
  { label: 'Negotin', value: 'Negotin' },
  { label: 'Negotino', value: 'Negotino' },
  { label: 'Negotino', value: 'Negotino' },
  { label: 'Negour', value: 'Negour' },
  { label: 'Negrar', value: 'Negrar' },
  { label: 'Negraşi', value: 'Negraşi' },
  { label: 'Negredo', value: 'Negredo' },
  { label: 'Negreia', value: 'Negreia' },
  { label: 'Negreira', value: 'Negreira' },
  { label: 'Negreiros', value: 'Negreiros' },
  { label: 'Negrelos', value: 'Negrelos' },
  { label: 'Negreni', value: 'Negreni' },
  { label: 'Negreni', value: 'Negreni' },
  { label: 'Negrenii De Câmpie', value: 'Negrenii De Câmpie' },
  { label: 'Nègrepelisse', value: 'Nègrepelisse' },
  { label: 'Negreşti', value: 'Negreşti' },
  { label: 'Negrești', value: 'Negrești' },
  { label: 'Negreşti-Oaş', value: 'Negreşti-Oaş' },
  { label: 'Negrete', value: 'Negrete' },
  { label: 'Negri', value: 'Negri' },
  { label: 'Negril', value: 'Negril' },
  { label: 'Negrileasa', value: 'Negrileasa' },
  { label: 'Negrilești', value: 'Negrilești' },
  { label: 'Negrilești', value: 'Negrilești' },
  { label: 'Negrilești', value: 'Negrilești' },
  { label: 'Negrilla De Palencia', value: 'Negrilla De Palencia' },
  { label: 'Negritas', value: 'Negritas' },
  { label: 'Negrone', value: 'Negrone' },
  { label: 'Negru Vodă', value: 'Negru Vodă' },
  { label: 'Negueira De Muñiz', value: 'Negueira De Muñiz' },
  { label: 'Neẖalim', value: 'Neẖalim' },
  { label: 'Nehbandan', value: 'Nehbandan' },
  { label: 'Nehe', value: 'Nehe' },
  { label: 'Nehenjeh', value: 'Nehenjeh' },
  { label: 'Nehoiu', value: 'Nehoiu' },
  { label: 'Nehren', value: 'Nehren' },
  { label: 'Nehvizdy', value: 'Nehvizdy' },
  { label: 'Neiafu', value: 'Neiafu' },
  { label: 'Neiba', value: 'Neiba' },
  { label: 'Neidenstein', value: 'Neidenstein' },
  { label: 'Neidling', value: 'Neidling' },
  { label: 'Neidlingen', value: 'Neidlingen' },
  { label: 'Neietsu', value: 'Neietsu' },
  { label: 'Neijiang', value: 'Neijiang' },
  { label: 'Neikeng', value: 'Neikeng' },
  { label: 'Neila', value: 'Neila' },
  { label: 'Neila De San Miguel', value: 'Neila De San Miguel' },
  { label: 'Neillsville', value: 'Neillsville' },
  { label: 'Neilston', value: 'Neilston' },
  { label: 'Nein', value: 'Nein' },
  { label: 'Neinstedt', value: 'Neinstedt' },
  { label: 'Neira', value: 'Neira' },
  { label: 'Neirone', value: 'Neirone' },
  { label: 'Neiva', value: 'Neiva' },
  { label: 'Neive-Borgonovo', value: 'Neive-Borgonovo' },
  { label: 'Nejapa', value: 'Nejapa' },
  { label: 'Nejapa De Madero', value: 'Nejapa De Madero' },
  { label: 'Nejdek', value: 'Nejdek' },
  { label: 'Nejo', value: 'Nejo' },
  { label: 'Neka', value: 'Neka' },
  { label: 'Nekala', value: 'Nekala' },
  { label: 'Nekhayevskaya', value: 'Nekhayevskaya' },
  { label: 'Nekla', value: 'Nekla' },
  { label: 'Neklyudovo', value: 'Neklyudovo' },
  { label: 'Nekoosa', value: 'Nekoosa' },
  { label: 'Nekouzskiy Rayon', value: 'Nekouzskiy Rayon' },
  { label: 'Nekrasovka', value: 'Nekrasovka' },
  { label: 'Nekrasovka', value: 'Nekrasovka' },
  { label: 'Nekrasovka', value: 'Nekrasovka' },
  { label: 'Nekrasovo', value: 'Nekrasovo' },
  { label: 'Nekrasovskaya', value: 'Nekrasovskaya' },
  { label: 'Nekrasovskiy', value: 'Nekrasovskiy' },
  { label: 'Nekrasovskiy Rayon', value: 'Nekrasovskiy Rayon' },
  { label: 'Nekrasovskoye', value: 'Nekrasovskoye' },
  { label: 'Nelahozeves', value: 'Nelahozeves' },
  { label: 'Nelamangala', value: 'Nelamangala' },
  { label: 'Nelas', value: 'Nelas' },
  { label: 'Nelazskoye', value: 'Nelazskoye' },
  { label: 'Nelidovo', value: 'Nelidovo' },
  { label: 'Neligh', value: 'Neligh' },
  { label: 'Nelipyno', value: 'Nelipyno' },
  { label: 'Nellieburg', value: 'Nellieburg' },
  { label: 'Nellikkuppam', value: 'Nellikkuppam' },
  { label: 'Nellingen', value: 'Nellingen' },
  { label: 'Nellis Air Force Base', value: 'Nellis Air Force Base' },
  { label: 'Nellore', value: 'Nellore' },
  { label: 'Nelly Bay', value: 'Nelly Bay' },
  { label: 'Nellysford', value: 'Nellysford' },
  { label: 'Nelson', value: 'Nelson' },
  { label: 'Nelson', value: 'Nelson' },
  { label: 'Nelson', value: 'Nelson' },
  { label: 'Nelson', value: 'Nelson' },
  { label: 'Nelson', value: 'Nelson' },
  { label: 'Nelson', value: 'Nelson' },
  { label: 'Nelson Bay', value: 'Nelson Bay' },
  { label: 'Nelson County', value: 'Nelson County' },
  { label: 'Nelson County', value: 'Nelson County' },
  { label: 'Nelson County', value: 'Nelson County' },
  { label: 'Nelson Mandela Bay Metropolitan Municipality', value: 'Nelson Mandela Bay Metropolitan Municipality' },
  { label: 'Nelsonville', value: 'Nelsonville' },
  { label: 'Nelspruit', value: 'Nelspruit' },
  { label: 'Nema', value: 'Nema' },
  { label: 'Nema', value: 'Nema' },
  { label: 'Néma', value: 'Néma' },
  { label: 'Nema Municipality', value: 'Nema Municipality' },
  { label: 'Nemaha County', value: 'Nemaha County' },
  { label: 'Nemaha County', value: 'Nemaha County' },
  { label: 'Neman', value: 'Neman' },
  { label: 'Nemanskiy Rayon', value: 'Nemanskiy Rayon' },
  { label: 'Nembro', value: 'Nembro' },
  { label: 'Nemby', value: 'Nemby' },
  { label: 'Nemchinovka', value: 'Nemchinovka' },
  { label: 'Němčice Nad Hanou', value: 'Němčice Nad Hanou' },
  { label: 'Neméa', value: 'Neméa' },
  { label: 'Nemenčinė', value: 'Nemenčinė' },
  { label: 'Nemesnádudvar', value: 'Nemesnádudvar' },
  { label: 'Nemesvámos', value: 'Nemesvámos' },
  { label: 'Németkér', value: 'Németkér' },
  { label: 'Nemi', value: 'Nemi' },
  { label: 'Nemocón', value: 'Nemocón' },
  { label: 'Nemoli', value: 'Nemoli' },
  { label: 'Nemours', value: 'Nemours' },
  { label: 'Nemskiy Rayon', value: 'Nemskiy Rayon' },
  { label: 'Nemšová', value: 'Nemšová' },
  { label: 'Nemțișor', value: 'Nemțișor' },
  { label: 'Nemuro', value: 'Nemuro' },
  { label: 'Nemuro-Shi', value: 'Nemuro-Shi' },
  { label: 'Nemyriv', value: 'Nemyriv' },
  { label: 'Nemyryntsi', value: 'Nemyryntsi' },
  { label: 'Nena', value: 'Nena' },
  { label: 'Nena', value: 'Nena' },
  { label: 'Nenagh', value: 'Nenagh' },
  { label: 'Nenagh Bridge', value: 'Nenagh Bridge' },
  { label: 'Nenciulești', value: 'Nenciulești' },
  { label: 'Nenehuaca', value: 'Nenehuaca' },
  { label: 'Nenita', value: 'Nenita' },
  { label: 'Nenita', value: 'Nenita' },
  { label: 'Nenjiang', value: 'Nenjiang' },
  { label: 'Nennhausen', value: 'Nennhausen' },
  { label: 'Nennslingen', value: 'Nennslingen' },
  { label: 'Neno District', value: 'Neno District' },
  { label: 'Nentershausen', value: 'Nentershausen' },
  { label: 'Nentershausen', value: 'Nentershausen' },
  { label: 'Nentón', value: 'Nentón' },
  { label: 'Nenzing', value: 'Nenzing' },
  { label: 'Néo Agionéri', value: 'Néo Agionéri' },
  { label: 'Néo Petrítsi', value: 'Néo Petrítsi' },
  { label: 'Néo Psychikó', value: 'Néo Psychikó' },
  { label: 'Néo Rýsi', value: 'Néo Rýsi' },
  { label: 'Néo Soúli', value: 'Néo Soúli' },
  { label: 'Neochóri', value: 'Neochóri' },
  { label: 'Neochóri', value: 'Neochóri' },
  { label: 'Neochóri', value: 'Neochóri' },
  { label: 'Neochóri', value: 'Neochóri' },
  { label: 'Neochórion', value: 'Neochórion' },
  { label: 'Neochorópoulo', value: 'Neochorópoulo' },
  { label: 'Neochoroúda', value: 'Neochoroúda' },
  { label: 'Neodesha', value: 'Neodesha' },
  { label: 'Neoga', value: 'Neoga' },
  { label: 'Néoi Epivátes', value: 'Néoi Epivátes' },
  { label: 'Néon Monastírion', value: 'Néon Monastírion' },
  { label: 'Neoneli', value: 'Neoneli' },
  { label: 'Neópolis', value: 'Neópolis' },
  { label: 'Néos Marmarás', value: 'Néos Marmarás' },
  { label: 'Néos Mylótopos', value: 'Néos Mylótopos' },
  { label: 'Néos Oropós', value: 'Néos Oropós' },
  { label: 'Néos Skopós', value: 'Néos Skopós' },
  { label: 'Neos Voutzás', value: 'Neos Voutzás' },
  { label: 'Neosho', value: 'Neosho' },
  { label: 'Neosho County', value: 'Neosho County' },
  { label: 'Néoules', value: 'Néoules' },
  { label: 'Nepanagar', value: 'Nepanagar' },
  { label: 'Nepantla De Sor Juana Inés De La Cruz', value: 'Nepantla De Sor Juana Inés De La Cruz' },
  { label: 'Nepas', value: 'Nepas' },
  { label: 'Nepean', value: 'Nepean' },
  { label: 'Nepezzano', value: 'Nepezzano' },
  { label: 'Nephi', value: 'Nephi' },
  { label: 'Nepi', value: 'Nepi' },
  { label: 'Nepomuceno', value: 'Nepomuceno' },
  { label: 'Nepomuk', value: 'Nepomuk' },
  { label: 'Neponsit', value: 'Neponsit' },
  { label: 'Nepopualco', value: 'Nepopualco' },
  { label: 'Nepos', value: 'Nepos' },
  { label: 'Neptune Beach', value: 'Neptune Beach' },
  { label: 'Neptune City', value: 'Neptune City' },
  { label: 'Nérac', value: 'Nérac' },
  { label: 'Neral', value: 'Neral' },
  { label: 'Nerang', value: 'Nerang' },
  { label: 'Nerašte', value: 'Nerašte' },
  { label: 'Neratovice', value: 'Neratovice' },
  { label: 'Nerău', value: 'Nerău' },
  { label: 'Nerchau', value: 'Nerchau' },
  { label: 'Nerchinsk', value: 'Nerchinsk' },
  { label: 'Nerchinskiy Zavod', value: 'Nerchinskiy Zavod' },
  { label: 'Nerchinsko-Zavodskiy Rayon', value: 'Nerchinsko-Zavodskiy Rayon' },
  { label: 'Nercillac', value: 'Nercillac' },
  { label: 'Nereju', value: 'Nereju' },
  { label: 'Nereju Mic', value: 'Nereju Mic' },
  { label: 'Nerekhta', value: 'Nerekhta' },
  { label: 'Nerekhtskiy Rayon', value: 'Nerekhtskiy Rayon' },
  { label: 'Neresheim', value: 'Neresheim' },
  { label: 'Neresnytsya', value: 'Neresnytsya' },
  { label: 'Nereta', value: 'Nereta' },
  { label: 'Nereto', value: 'Nereto' },
  { label: 'Nerežišća', value: 'Nerežišća' },
  { label: 'Nerima-Ku', value: 'Nerima-Ku' },
  { label: 'Neringa', value: 'Neringa' },
  { label: 'Néris-Les-Bains', value: 'Néris-Les-Bains' },
  { label: 'Nerito', value: 'Nerito' },
  { label: 'Nerja', value: 'Nerja' },
  { label: 'Nerk’In Getashen', value: 'Nerk’In Getashen' },
  { label: 'Nerl’', value: 'Nerl’' },
  { label: 'Nerokoúros', value: 'Nerokoúros' },
  { label: 'Nerola', value: 'Nerola' },
  { label: 'Nérondes', value: 'Nérondes' },
  { label: 'Nerópolis', value: 'Nerópolis' },
  { label: 'Nerpio', value: 'Nerpio' },
  { label: 'Nersac', value: 'Nersac' },
  { label: 'Nersingen', value: 'Nersingen' },
  { label: 'Nerur', value: 'Nerur' },
  { label: 'Nerva', value: 'Nerva' },
  { label: 'Nervesa Della Battaglia', value: 'Nervesa Della Battaglia' },
  { label: 'Nerviano', value: 'Nerviano' },
  { label: 'Neryungri', value: 'Neryungri' },
  { label: 'Nes', value: 'Nes' },
  { label: 'Nes', value: 'Nes' },
  { label: 'Nes', value: 'Nes' },
  { label: 'Nes Aan De Amstel', value: 'Nes Aan De Amstel' },
  { label: 'Nesar', value: 'Nesar' },
  { label: 'Nesbyen', value: 'Nesbyen' },
  { label: 'Neschwitz', value: 'Neschwitz' },
  { label: 'Nesconset', value: 'Nesconset' },
  { label: 'Nescopeck', value: 'Nescopeck' },
  { label: 'Nesebar', value: 'Nesebar' },
  { label: 'Neshar', value: 'Neshar' },
  { label: 'Nesher', value: 'Nesher' },
  { label: 'Neshoba County', value: 'Neshoba County' },
  { label: 'Neskaupstaður', value: 'Neskaupstaður' },
  { label: 'Neskollen', value: 'Neskollen' },
  { label: 'Nesle', value: 'Nesle' },
  { label: 'Nesles', value: 'Nesles' },
  { label: 'Nesles-La-Montagne', value: 'Nesles-La-Montagne' },
  { label: 'Nesles-La-Vallée', value: 'Nesles-La-Vallée' },
  { label: 'Nesmy', value: 'Nesmy' },
  { label: 'Nesna', value: 'Nesna' },
  { label: 'Nesodden', value: 'Nesodden' },
  { label: 'Nesoddtangen', value: 'Nesoddtangen' },
  { label: 'Nesovice', value: 'Nesovice' },
  { label: 'Nespereira', value: 'Nespereira' },
  { label: 'Nespolo', value: 'Nespolo' },
  { label: 'Nesquehoning', value: 'Nesquehoning' },
  { label: 'Ness City', value: 'Ness City' },
  { label: 'Ness County', value: 'Ness County' },
  { label: 'Ness Ziona', value: 'Ness Ziona' },
  { label: 'Nessa', value: 'Nessa' },
  { label: 'Nesseby', value: 'Nesseby' },
  { label: 'Nesselwang', value: 'Nesselwang' },
  { label: 'Nesselwängle', value: 'Nesselwängle' },
  { label: 'Nesset', value: 'Nesset' },
  { label: 'Nesso', value: 'Nesso' },
  { label: 'Nestares', value: 'Nestares' },
  { label: 'Nestelbach Bei Graz', value: 'Nestelbach Bei Graz' },
  { label: 'Nestelbach Im Ilztal', value: 'Nestelbach Im Ilztal' },
  { label: 'Nesterov', value: 'Nesterov' },
  { label: 'Nesterovskaya', value: 'Nesterovskaya' },
  { label: 'Nesterovskiy Rayon', value: 'Nesterovskiy Rayon' },
  { label: 'Neston', value: 'Neston' },
  { label: 'Nestório', value: 'Nestório' },
  { label: 'Neszmély', value: 'Neszmély' },
  { label: 'Net’Inka', value: 'Net’Inka' },
  { label: 'Netanya', value: 'Netanya' },
  { label: 'Netcong', value: 'Netcong' },
  { label: 'Nether Heyford', value: 'Nether Heyford' },
  { label: 'Nether Poppleton', value: 'Nether Poppleton' },
  { label: 'Nether Stowey', value: 'Nether Stowey' },
  { label: 'Netheravon', value: 'Netheravon' },
  { label: 'Netherby', value: 'Netherby' },
  { label: 'Netherlee', value: 'Netherlee' },
  { label: 'Netherton', value: 'Netherton' },
  { label: 'Netishyn', value: 'Netishyn' },
  { label: 'Netivot', value: 'Netivot' },
  { label: 'Netley', value: 'Netley' },
  { label: 'Netley', value: 'Netley' },
  { label: 'Netolice', value: 'Netolice' },
  { label: 'Netphen', value: 'Netphen' },
  { label: 'Netro', value: 'Netro' },
  { label: 'Netrokona ', value: 'Netrokona ' },
  { label: 'Netstal', value: 'Netstal' },
  { label: 'Nett Municipality', value: 'Nett Municipality' },
  { label: 'Nettersheim', value: 'Nettersheim' },
  { label: 'Nettetal', value: 'Nettetal' },
  { label: 'Nettleham', value: 'Nettleham' },
  { label: 'Nettleton', value: 'Nettleton' },
  { label: 'Nettuno', value: 'Nettuno' },
  { label: 'Neturhat', value: 'Neturhat' },
  { label: 'Netvořice', value: 'Netvořice' },
  { label: 'Netzahualcóyotl', value: 'Netzahualcóyotl' },
  { label: 'Netzschkau', value: 'Netzschkau' },
  { label: 'Neu Darchau', value: 'Neu Darchau' },
  { label: 'Neu Isenburg', value: 'Neu Isenburg' },
  { label: 'Neu Kaliß', value: 'Neu Kaliß' },
  { label: 'Neu Wulmstorf', value: 'Neu Wulmstorf' },
  { label: 'Neu Zauche', value: 'Neu Zauche' },
  { label: 'Neualbenreuth', value: 'Neualbenreuth' },
  { label: 'Neualm', value: 'Neualm' },
  { label: 'Neu-Anspach', value: 'Neu-Anspach' },
  { label: 'Neubau', value: 'Neubau' },
  { label: 'Neuberend', value: 'Neuberend' },
  { label: 'Neuberg An Der Mürz', value: 'Neuberg An Der Mürz' },
  { label: 'Neuberg Im Burgenland', value: 'Neuberg Im Burgenland' },
  { label: 'Neubeuern', value: 'Neubeuern' },
  { label: 'Neubiberg', value: 'Neubiberg' },
  { label: 'Neubörger', value: 'Neubörger' },
  { label: 'Neubrandenburg', value: 'Neubrandenburg' },
  { label: 'Neubrück', value: 'Neubrück' },
  { label: 'Neubrück', value: 'Neubrück' },
  { label: 'Neubrunn', value: 'Neubrunn' },
  { label: 'Neubukow', value: 'Neubukow' },
  { label: 'Neubulach', value: 'Neubulach' },
  { label: 'Neuburg', value: 'Neuburg' },
  { label: 'Neuburg', value: 'Neuburg' },
  { label: 'Neuburg', value: 'Neuburg' },
  { label: 'Neuburg An Der Donau', value: 'Neuburg An Der Donau' },
  { label: 'Neuchâtel', value: 'Neuchâtel' },
  { label: 'Neuchâtel District', value: 'Neuchâtel District' },
  { label: 'Neudau', value: 'Neudau' },
  { label: 'Neudenau', value: 'Neudenau' },
  { label: 'Neudietendorf', value: 'Neudietendorf' },
  { label: 'Neudorf', value: 'Neudorf' },
  { label: 'Neudorf', value: 'Neudorf' },
  { label: 'Neudorf Bei Staatz', value: 'Neudorf Bei Staatz' },
  { label: 'Neudorf-Bornstein', value: 'Neudorf-Bornstein' },
  { label: 'Neudörfl', value: 'Neudörfl' },
  { label: 'Neudrossenfeld', value: 'Neudrossenfeld' },
  { label: 'Neue Heimat', value: 'Neue Heimat' },
  { label: 'Neue Neustadt', value: 'Neue Neustadt' },
  { label: 'Neuehrenfeld', value: 'Neuehrenfeld' },
  { label: 'Neuenbürg', value: 'Neuenbürg' },
  { label: 'Neuenburg Am Rhein', value: 'Neuenburg Am Rhein' },
  { label: 'Neuendettelsau', value: 'Neuendettelsau' },
  { label: 'Neuenhagen', value: 'Neuenhagen' },
  { label: 'Neuenhof', value: 'Neuenhof' },
  { label: 'Neuenkirch', value: 'Neuenkirch' },
  { label: 'Neuenkirchen', value: 'Neuenkirchen' },
  { label: 'Neuenkirchen', value: 'Neuenkirchen' },
  { label: 'Neuenkirchen', value: 'Neuenkirchen' },
  { label: 'Neuenmarkt', value: 'Neuenmarkt' },
  { label: 'Neuenrade', value: 'Neuenrade' },
  { label: 'Neuensalz', value: 'Neuensalz' },
  { label: 'Neuenstadt Am Kocher', value: 'Neuenstadt Am Kocher' },
  { label: 'Neuenstein', value: 'Neuenstein' },
  { label: 'Neuental', value: 'Neuental' },
  { label: 'Neuerburg', value: 'Neuerburg' },
  { label: 'Neufahrn', value: 'Neufahrn' },
  { label: 'Neufahrn Bei Freising', value: 'Neufahrn Bei Freising' },
  { label: 'Neuf-Berquin', value: 'Neuf-Berquin' },
  { label: 'Neuf-Brisach', value: 'Neuf-Brisach' },
  { label: 'Neufchâteau', value: 'Neufchâteau' },
  { label: 'Neufchâteau', value: 'Neufchâteau' },
  { label: 'Neufchâtel-En-Bray', value: 'Neufchâtel-En-Bray' },
  { label: 'Neufchâtel-Hardelot', value: 'Neufchâtel-Hardelot' },
  { label: 'Neufchef', value: 'Neufchef' },
  { label: 'Neu-Feffernitz', value: 'Neu-Feffernitz' },
  { label: 'Neufeld An Der Leitha', value: 'Neufeld An Der Leitha' },
  { label: 'Neuffen', value: 'Neuffen' },
  { label: 'Neufgrange', value: 'Neufgrange' },
  { label: 'Neufmanil', value: 'Neufmanil' },
  { label: 'Neufmoutiers-En-Brie', value: 'Neufmoutiers-En-Brie' },
  { label: 'Neufra', value: 'Neufra' },
  { label: 'Neufraunhofen', value: 'Neufraunhofen' },
  { label: 'Neufurth', value: 'Neufurth' },
  { label: 'Neugersdorf', value: 'Neugersdorf' },
  { label: 'Neu-Guntramsdorf', value: 'Neu-Guntramsdorf' },
  { label: 'Neuhardenberg', value: 'Neuhardenberg' },
  { label: 'Neuharlingersiel', value: 'Neuharlingersiel' },
  { label: 'Neuhaus', value: 'Neuhaus' },
  { label: 'Neuhaus', value: 'Neuhaus' },
  { label: 'Neuhaus Am Inn', value: 'Neuhaus Am Inn' },
  { label: 'Neuhaus Am Klausenbach', value: 'Neuhaus Am Klausenbach' },
  { label: 'Neuhaus Am Rennweg', value: 'Neuhaus Am Rennweg' },
  { label: 'Neuhaus An Der Oste', value: 'Neuhaus An Der Oste' },
  { label: 'Neuhaus An Der Pegnitz', value: 'Neuhaus An Der Pegnitz' },
  { label: 'Neuhäusel', value: 'Neuhäusel' },
  { label: 'Neuhausen', value: 'Neuhausen' },
  { label: 'Neuhausen', value: 'Neuhausen' },
  { label: 'Neuhausen', value: 'Neuhausen' },
  { label: 'Neuhausen Auf Den Fildern', value: 'Neuhausen Auf Den Fildern' },
  { label: 'Neuhaus-Schierschnitz', value: 'Neuhaus-Schierschnitz' },
  { label: 'Neuhof', value: 'Neuhof' },
  { label: 'Neuhof An Der Zenn', value: 'Neuhof An Der Zenn' },
  { label: 'Neuhofen', value: 'Neuhofen' },
  { label: 'Neuhofen An Der Krems', value: 'Neuhofen An Der Krems' },
  { label: 'Neuhofen An Der Ybbs', value: 'Neuhofen An Der Ybbs' },
  { label: 'Neu-Hohenschönhausen', value: 'Neu-Hohenschönhausen' },
  { label: 'Neuhütten', value: 'Neuhütten' },
  { label: 'Neuillé-Pont-Pierre', value: 'Neuillé-Pont-Pierre' },
  { label: 'Neuilly-En-Thelle', value: 'Neuilly-En-Thelle' },
  { label: 'Neuilly-Le-Réal', value: 'Neuilly-Le-Réal' },
  { label: 'Neuilly-Lès-Dijon', value: 'Neuilly-Lès-Dijon' },
  { label: 'Neuilly-Plaisance', value: 'Neuilly-Plaisance' },
  { label: 'Neuilly-Saint-Front', value: 'Neuilly-Saint-Front' },
  { label: 'Neuilly-Sous-Clermont', value: 'Neuilly-Sous-Clermont' },
  { label: 'Neuilly-Sur-Marne', value: 'Neuilly-Sur-Marne' },
  { label: 'Neuilly-Sur-Seine', value: 'Neuilly-Sur-Seine' },
  { label: 'Neukalen', value: 'Neukalen' },
  { label: 'Neukamperfehn', value: 'Neukamperfehn' },
  { label: 'Neukieritzsch', value: 'Neukieritzsch' },
  { label: 'Neukirch', value: 'Neukirch' },
  { label: 'Neukirch', value: 'Neukirch' },
  { label: 'Neukirch/Lausitz', value: 'Neukirch/Lausitz' },
  { label: 'Neukirchen', value: 'Neukirchen' },
  { label: 'Neukirchen', value: 'Neukirchen' },
  { label: 'Neukirchen', value: 'Neukirchen' },
  { label: 'Neukirchen', value: 'Neukirchen' },
  { label: 'Neukirchen', value: 'Neukirchen' },
  { label: 'Neukirchen Am Großvenediger', value: 'Neukirchen Am Großvenediger' },
  { label: 'Neukirchen Am Walde', value: 'Neukirchen Am Walde' },
  { label: 'Neukirchen An Der Vöckla', value: 'Neukirchen An Der Vöckla' },
  { label: 'Neukirchen Bei Lambach', value: 'Neukirchen Bei Lambach' },
  { label: 'Neukirchen-Balbini', value: 'Neukirchen-Balbini' },
  { label: 'Neukloster', value: 'Neukloster' },
  { label: 'Neukölln', value: 'Neukölln' },
  { label: 'Neulengbach', value: 'Neulengbach' },
  { label: 'Neuler', value: 'Neuler' },
  { label: 'Neulewin', value: 'Neulewin' },
  { label: 'Neulingen', value: 'Neulingen' },
  { label: 'Neulise', value: 'Neulise' },
  { label: 'Neulliac', value: 'Neulliac' },
  { label: 'Neulußheim', value: 'Neulußheim' },
  { label: 'Neum', value: 'Neum' },
  { label: 'Neumagen-Dhron', value: 'Neumagen-Dhron' },
  { label: 'Neumark', value: 'Neumark' },
  { label: 'Neumarkt Am Wallersee', value: 'Neumarkt Am Wallersee' },
  { label: 'Neumarkt An Der Ybbs', value: 'Neumarkt An Der Ybbs' },
  { label: 'Neumarkt Im Hausruckkreis', value: 'Neumarkt Im Hausruckkreis' },
  { label: 'Neumarkt Im Mühlkreis', value: 'Neumarkt Im Mühlkreis' },
  { label: 'Neumarkt In Der Oberpfalz', value: 'Neumarkt In Der Oberpfalz' },
  { label: 'Neumarkt In Steiermark', value: 'Neumarkt In Steiermark' },
  { label: 'Neumarkt-Sankt Veit', value: 'Neumarkt-Sankt Veit' },
  { label: 'Neumühle', value: 'Neumühle' },
  { label: 'Neumünster', value: 'Neumünster' },
  { label: 'Neunburg Vorm Wald', value: 'Neunburg Vorm Wald' },
  { label: 'Neundorf', value: 'Neundorf' },
  { label: 'Neung-Sur-Beuvron', value: 'Neung-Sur-Beuvron' },
  { label: 'Neunkhausen', value: 'Neunkhausen' },
  { label: 'Neunkirch', value: 'Neunkirch' },
  { label: 'Neunkirchen', value: 'Neunkirchen' },
  { label: 'Neunkirchen', value: 'Neunkirchen' },
  { label: 'Neunkirchen', value: 'Neunkirchen' },
  { label: 'Neunkirchen', value: 'Neunkirchen' },
  { label: 'Neunkirchen Am Brand', value: 'Neunkirchen Am Brand' },
  { label: 'Neunkirchen Am Main', value: 'Neunkirchen Am Main' },
  { label: 'Neunkirchen Am Sand', value: 'Neunkirchen Am Sand' },
  { label: 'Neuötting', value: 'Neuötting' },
  { label: 'Neu-Pattern', value: 'Neu-Pattern' },
  { label: 'Neupotz', value: 'Neupotz' },
  { label: 'Neuquén', value: 'Neuquén' },
  { label: 'Neureichenau', value: 'Neureichenau' },
  { label: 'Neuried', value: 'Neuried' },
  { label: 'Neuruppin', value: 'Neuruppin' },
  { label: 'Neusalza-Spremberg', value: 'Neusalza-Spremberg' },
  { label: 'Neuschönau', value: 'Neuschönau' },
  { label: 'Neuse Forest', value: 'Neuse Forest' },
  { label: 'Neusiedl', value: 'Neusiedl' },
  { label: 'Neusiedl Am See', value: 'Neusiedl Am See' },
  { label: 'Neusiedl An Der Zaya', value: 'Neusiedl An Der Zaya' },
  { label: 'Neusitz', value: 'Neusitz' },
  { label: 'Neusorg', value: 'Neusorg' },
  { label: 'Neuss', value: 'Neuss' },
  { label: 'Neussargues', value: 'Neussargues' },
  { label: 'Neustadt', value: 'Neustadt' },
  { label: 'Neustadt', value: 'Neustadt' },
  { label: 'Neustadt (Hessen)', value: 'Neustadt (Hessen)' },
  { label: 'Neustadt Am Main', value: 'Neustadt Am Main' },
  { label: 'Neustadt Am Rennsteig', value: 'Neustadt Am Rennsteig' },
  { label: 'Neustadt Am Rübenberge', value: 'Neustadt Am Rübenberge' },
  { label: 'Neustadt An Der Aisch', value: 'Neustadt An Der Aisch' },
  { label: 'Neustadt An Der Donau', value: 'Neustadt An Der Donau' },
  { label: 'Neustadt An Der Orla', value: 'Neustadt An Der Orla' },
  { label: 'Neustadt An Der Waldnaab', value: 'Neustadt An Der Waldnaab' },
  { label: 'Neustadt Bei Coburg', value: 'Neustadt Bei Coburg' },
  { label: 'Neustadt In Holstein', value: 'Neustadt In Holstein' },
  { label: 'Neustadt In Sachsen', value: 'Neustadt In Sachsen' },
  { label: 'Neustadt Vogtland', value: 'Neustadt Vogtland' },
  { label: 'Neustadt/Nord', value: 'Neustadt/Nord' },
  { label: 'Neustadt/Süd', value: 'Neustadt/Süd' },
  { label: 'Neustadt-Glewe', value: 'Neustadt-Glewe' },
  { label: 'Neustift An Der Lafnitz', value: 'Neustift An Der Lafnitz' },
  { label: 'Neustift Bei Güssing', value: 'Neustift Bei Güssing' },
  { label: 'Neustift Im Stubaital', value: 'Neustift Im Stubaital' },
  { label: 'Neustrelitz', value: 'Neustrelitz' },
  { label: 'Neutal', value: 'Neutal' },
  { label: 'Neutillmitsch', value: 'Neutillmitsch' },
  { label: 'Neutla', value: 'Neutla' },
  { label: 'Neutral Bay', value: 'Neutral Bay' },
  { label: 'Neutraubling', value: 'Neutraubling' },
  { label: 'Neutrebbin', value: 'Neutrebbin' },
  { label: 'Neu-Ulm', value: 'Neu-Ulm' },
  { label: 'Neuvecelle', value: 'Neuvecelle' },
  { label: 'Neuvéglise', value: 'Neuvéglise' },
  { label: 'Neuves-Maisons', value: 'Neuves-Maisons' },
  { label: 'Neuvic', value: 'Neuvic' },
  { label: 'Neuvic-Entier', value: 'Neuvic-Entier' },
  { label: 'Neuville', value: 'Neuville' },
  { label: 'Neuville-Aux-Bois', value: 'Neuville-Aux-Bois' },
  { label: 'Neuville-De-Poitou', value: 'Neuville-De-Poitou' },
  { label: 'Neuville-En-Ferrain', value: 'Neuville-En-Ferrain' },
  { label: 'Neuville-Les-Dames', value: 'Neuville-Les-Dames' },
  { label: 'Neuville-Saint-Rémy', value: 'Neuville-Saint-Rémy' },
  { label: 'Neuville-Saint-Vaast', value: 'Neuville-Saint-Vaast' },
  { label: 'Neuville-Sur-Ain', value: 'Neuville-Sur-Ain' },
  { label: 'Neuville-Sur-Escaut', value: 'Neuville-Sur-Escaut' },
  { label: 'Neuville-Sur-Oise', value: 'Neuville-Sur-Oise' },
  { label: 'Neuville-Sur-Saône', value: 'Neuville-Sur-Saône' },
  { label: 'Neuville-Sur-Sarthe', value: 'Neuville-Sur-Sarthe' },
  { label: 'Neuvy', value: 'Neuvy' },
  { label: 'Neuvy-Le-Roi', value: 'Neuvy-Le-Roi' },
  { label: 'Neuvy-Pailloux', value: 'Neuvy-Pailloux' },
  { label: 'Neuvy-Saint-Sépulchre', value: 'Neuvy-Saint-Sépulchre' },
  { label: 'Neuvy-Sautour', value: 'Neuvy-Sautour' },
  { label: 'Neuvy-Sur-Barangeon', value: 'Neuvy-Sur-Barangeon' },
  { label: 'Neuvy-Sur-Loire', value: 'Neuvy-Sur-Loire' },
  { label: 'Neuweiler', value: 'Neuweiler' },
  { label: 'Neuwied', value: 'Neuwied' },
  { label: 'Neuwiller-Lès-Saverne', value: 'Neuwiller-Lès-Saverne' },
  { label: 'Neuwittenbek', value: 'Neuwittenbek' },
  { label: 'Neuzelle', value: 'Neuzelle' },
  { label: 'Neuzeug', value: 'Neuzeug' },
  { label: 'Neuzina', value: 'Neuzina' },
  { label: 'Nev’Yansk', value: 'Nev’Yansk' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'Nevada City', value: 'Nevada City' },
  { label: 'Nevada County', value: 'Nevada County' },
  { label: 'Nevada County', value: 'Nevada County' },
  { label: 'Nevasa', value: 'Nevasa' },
  { label: 'Neveklov', value: 'Neveklov' },
  { label: 'Nevel’', value: 'Nevel’' },
  { label: 'Nevel’Sk', value: 'Nevel’Sk' },
  { label: 'Nevele', value: 'Nevele' },
  { label: 'Never', value: 'Never' },
  { label: 'Neverin', value: 'Neverin' },
  { label: 'Neverkino', value: 'Neverkino' },
  { label: 'Neverkinskiy Rayon', value: 'Neverkinskiy Rayon' },
  { label: 'Nevers', value: 'Nevers' },
  { label: 'Neves', value: 'Neves' },
  { label: 'Neves As', value: 'Neves As' },
  { label: 'Neves Paulista', value: 'Neves Paulista' },
  { label: 'Nevesinje', value: 'Nevesinje' },
  { label: 'Nevestino', value: 'Nevestino' },
  { label: 'Névez', value: 'Névez' },
  { label: 'Névian', value: 'Névian' },
  { label: 'Neviano', value: 'Neviano' },
  { label: 'Neviano Degli Arduini', value: 'Neviano Degli Arduini' },
  { label: 'Neviglie', value: 'Neviglie' },
  { label: 'Néville', value: 'Néville' },
  { label: 'Nevinnomyssk', value: 'Nevinnomyssk' },
  { label: 'Nevoy', value: 'Nevoy' },
  { label: 'Nevyts’Ke', value: 'Nevyts’Ke' },
  { label: 'New Administrative Capital Of Egypt', value: 'New Administrative Capital Of Egypt' },
  { label: 'New Agutaya', value: 'New Agutaya' },
  { label: 'New Albany', value: 'New Albany' },
  { label: 'New Albany', value: 'New Albany' },
  { label: 'New Albany', value: 'New Albany' },
  { label: 'New Alresford', value: 'New Alresford' },
  { label: 'New Amsterdam', value: 'New Amsterdam' },
  { label: 'New Athens', value: 'New Athens' },
  { label: 'New Auckland', value: 'New Auckland' },
  { label: 'New Augusta', value: 'New Augusta' },
  { label: 'New Baclayon', value: 'New Baclayon' },
  { label: 'New Baclayon', value: 'New Baclayon' },
  { label: 'New Baclayon', value: 'New Baclayon' },
  { label: 'New Bādāh', value: 'New Bādāh' },
  { label: 'New Baden', value: 'New Baden' },
  { label: 'New Baltimore', value: 'New Baltimore' },
  { label: 'New Baltimore', value: 'New Baltimore' },
  { label: 'New Basford', value: 'New Basford' },
  { label: 'New Bataan', value: 'New Bataan' },
  { label: 'New Bataan', value: 'New Bataan' },
  { label: 'New Batu Batu', value: 'New Batu Batu' },
  { label: 'New Batu Batu', value: 'New Batu Batu' },
  { label: 'New Beaver', value: 'New Beaver' },
  { label: 'New Bedford', value: 'New Bedford' },
  { label: 'New Beith', value: 'New Beith' },
  { label: 'New Berlin', value: 'New Berlin' },
  { label: 'New Berlin', value: 'New Berlin' },
  { label: 'New Berlinville', value: 'New Berlinville' },
  { label: 'New Bern', value: 'New Bern' },
  { label: 'New Bloomfield', value: 'New Bloomfield' },
  { label: 'New Bohol', value: 'New Bohol' },
  { label: 'New Bohol', value: 'New Bohol' },
  { label: 'New Bohol', value: 'New Bohol' },
  { label: 'New Borg El Arab', value: 'New Borg El Arab' },
  { label: 'New Boston', value: 'New Boston' },
  { label: 'New Boston', value: 'New Boston' },
  { label: 'New Boston', value: 'New Boston' },
  { label: 'New Braunfels', value: 'New Braunfels' },
  { label: 'New Bremen', value: 'New Bremen' },
  { label: 'New Brighton', value: 'New Brighton' },
  { label: 'New Brighton', value: 'New Brighton' },
  { label: 'New Brighton', value: 'New Brighton' },
  { label: 'New Britain', value: 'New Britain' },
  { label: 'New Britain', value: 'New Britain' },
  { label: 'New Brockton', value: 'New Brockton' },
  { label: 'New Brunswick', value: 'New Brunswick' },
  { label: 'New Buffalo', value: 'New Buffalo' },
  { label: 'New Burlington', value: 'New Burlington' },
  { label: 'New California', value: 'New California' },
  { label: 'New Canaan', value: 'New Canaan' },
  { label: 'New Carlisle', value: 'New Carlisle' },
  { label: 'New Carlisle', value: 'New Carlisle' },
  { label: 'New Carlisle', value: 'New Carlisle' },
  { label: 'New Carrollton', value: 'New Carrollton' },
  { label: 'New Cassel', value: 'New Cassel' },
  { label: 'New Castle', value: 'New Castle' },
  { label: 'New Castle', value: 'New Castle' },
  { label: 'New Castle', value: 'New Castle' },
  { label: 'New Castle', value: 'New Castle' },
  { label: 'New Castle', value: 'New Castle' },
  { label: 'New Castle', value: 'New Castle' },
  { label: 'New Castle', value: 'New Castle' },
  { label: 'New Castle County', value: 'New Castle County' },
  { label: 'New Castle Northwest', value: 'New Castle Northwest' },
  { label: 'New Cebu', value: 'New Cebu' },
  { label: 'New Cebu', value: 'New Cebu' },
  { label: 'New Century Ks', value: 'New Century Ks' },
  { label: 'New Chicago', value: 'New Chicago' },
  { label: 'New City', value: 'New City' },
  { label: 'New City', value: 'New City' },
  { label: 'New Columbia', value: 'New Columbia' },
  { label: 'New Concord', value: 'New Concord' },
  { label: 'New Corella', value: 'New Corella' },
  { label: 'New Corella', value: 'New Corella' },
  { label: 'New Corella', value: 'New Corella' },
  { label: 'New Cumberland', value: 'New Cumberland' },
  { label: 'New Cumberland', value: 'New Cumberland' },
  { label: 'New Cumnock', value: 'New Cumnock' },
  { label: 'New Delhi', value: 'New Delhi' },
  { label: 'New Denbigh', value: 'New Denbigh' },
  { label: 'New Development', value: 'New Development' },
  { label: 'New Dorp', value: 'New Dorp' },
  { label: 'New Dorp Beach', value: 'New Dorp Beach' },
  { label: 'New Durham', value: 'New Durham' },
  { label: 'New Eagle', value: 'New Eagle' },
  { label: 'New Egypt', value: 'New Egypt' },
  { label: 'New Ellenton', value: 'New Ellenton' },
  { label: 'New Fairfield', value: 'New Fairfield' },
  { label: 'New Farm', value: 'New Farm' },
  { label: 'New Ferry', value: 'New Ferry' },
  { label: 'New Field/Fiette', value: 'New Field/Fiette' },
  { label: 'New Forest', value: 'New Forest' },
  { label: 'New Franklin', value: 'New Franklin' },
  { label: 'New Franklin', value: 'New Franklin' },
  { label: 'New Freedom', value: 'New Freedom' },
  { label: 'New Gisborne', value: 'New Gisborne' },
  { label: 'New Glarus', value: 'New Glarus' },
  { label: 'New Glasgow', value: 'New Glasgow' },
  { label: 'New Gloucester', value: 'New Gloucester' },
  { label: 'New Green', value: 'New Green' },
  { label: 'New Grove', value: 'New Grove' },
  { label: 'New Hamburg', value: 'New Hamburg' },
  { label: 'New Hampton', value: 'New Hampton' },
  { label: 'New Hanover County', value: 'New Hanover County' },
  { label: 'New Hartford', value: 'New Hartford' },
  { label: 'New Hartford Center', value: 'New Hartford Center' },
  { label: 'New Haven', value: 'New Haven' },
  { label: 'New Haven', value: 'New Haven' },
  { label: 'New Haven', value: 'New Haven' },
  { label: 'New Haven', value: 'New Haven' },
  { label: 'New Haven', value: 'New Haven' },
  { label: 'New Haven', value: 'New Haven' },
  { label: 'New Haven County', value: 'New Haven County' },
  { label: 'New Hempstead', value: 'New Hempstead' },
  { label: 'New Holland', value: 'New Holland' },
  { label: 'New Holstein', value: 'New Holstein' },
  { label: 'New Hope', value: 'New Hope' },
  { label: 'New Hope', value: 'New Hope' },
  { label: 'New Hope', value: 'New Hope' },
  { label: 'New Hope', value: 'New Hope' },
  { label: 'New Hope', value: 'New Hope' },
  { label: 'New Hope', value: 'New Hope' },
  { label: 'New Hyde Park', value: 'New Hyde Park' },
  { label: 'New Iberia', value: 'New Iberia' },
  { label: 'New Iloilo', value: 'New Iloilo' },
  { label: 'New Iloilo', value: 'New Iloilo' },
  { label: 'New Ipswich', value: 'New Ipswich' },
  { label: 'New Johnsonville', value: 'New Johnsonville' },
  { label: 'New Kensington', value: 'New Kensington' },
  { label: 'New Kent', value: 'New Kent' },
  { label: 'New Kent County', value: 'New Kent County' },
  { label: 'New Kingman-Butler', value: 'New Kingman-Butler' },
  { label: 'New Kingston', value: 'New Kingston' },
  { label: 'New Lagao', value: 'New Lagao' },
  { label: 'New Lagao', value: 'New Lagao' },
  { label: 'New Lambton', value: 'New Lambton' },
  { label: 'New Lambton Heights', value: 'New Lambton Heights' },
  { label: 'New Lebanon', value: 'New Lebanon' },
  { label: 'New Lenox', value: 'New Lenox' },
  { label: 'New Lexington', value: 'New Lexington' },
  { label: 'New Leyte', value: 'New Leyte' },
  { label: 'New Leyte', value: 'New Leyte' },
  { label: 'New Leyte', value: 'New Leyte' },
  { label: 'New Lisbon', value: 'New Lisbon' },
  { label: 'New Llano', value: 'New Llano' },
  { label: 'New London', value: 'New London' },
  { label: 'New London', value: 'New London' },
  { label: 'New London', value: 'New London' },
  { label: 'New London', value: 'New London' },
  { label: 'New London', value: 'New London' },
  { label: 'New London', value: 'New London' },
  { label: 'New London', value: 'New London' },
  { label: 'New London County', value: 'New London County' },
  { label: 'New Longsville', value: 'New Longsville' },
  { label: 'New Madrid', value: 'New Madrid' },
  { label: 'New Madrid County', value: 'New Madrid County' },
  { label: 'New Malden', value: 'New Malden' },
  { label: 'New Market', value: 'New Market' },
  { label: 'New Market', value: 'New Market' },
  { label: 'New Market', value: 'New Market' },
  { label: 'New Market', value: 'New Market' },
  { label: 'New Market Oval', value: 'New Market Oval' },
  { label: 'New Marlborough', value: 'New Marlborough' },
  { label: 'New Marske', value: 'New Marske' },
  { label: 'New Martinsville', value: 'New Martinsville' },
  { label: 'New Maryland', value: 'New Maryland' },
  { label: 'New Matamoras', value: 'New Matamoras' },
  { label: 'New Miami', value: 'New Miami' },
  { label: 'New Middletown', value: 'New Middletown' },
  { label: 'New Milford', value: 'New Milford' },
  { label: 'New Milford', value: 'New Milford' },
  { label: 'New Mills', value: 'New Mills' },
  { label: 'New Milton', value: 'New Milton' },
  { label: 'New Mirpur', value: 'New Mirpur' },
  { label: 'New Monkland', value: 'New Monkland' },
  { label: 'New Norfolk', value: 'New Norfolk' },
  { label: 'New Orleans', value: 'New Orleans' },
  { label: 'New Oxford', value: 'New Oxford' },
  { label: 'New Palestine', value: 'New Palestine' },
  { label: 'New Paltz', value: 'New Paltz' },
  { label: 'New Panamao', value: 'New Panamao' },
  { label: 'New Panay', value: 'New Panay' },
  { label: 'New Panay', value: 'New Panay' },
  { label: 'New Pandanon', value: 'New Pandanon' },
  { label: 'New Paris', value: 'New Paris' },
  { label: 'New Paris', value: 'New Paris' },
  { label: 'New Pekin', value: 'New Pekin' },
  { label: 'New Philadelphia', value: 'New Philadelphia' },
  { label: 'New Philadelphia', value: 'New Philadelphia' },
  { label: 'New Pitsligo', value: 'New Pitsligo' },
  { label: 'New Plymouth', value: 'New Plymouth' },
  { label: 'New Plymouth', value: 'New Plymouth' },
  { label: 'New Plymouth District', value: 'New Plymouth District' },
  { label: 'New Port Richey', value: 'New Port Richey' },
  { label: 'New Port Richey East', value: 'New Port Richey East' },
  { label: 'New Prague', value: 'New Prague' },
  { label: 'New Preston', value: 'New Preston' },
  { label: 'New Providence', value: 'New Providence' },
  { label: 'New Quay', value: 'New Quay' },
  { label: 'New Richland', value: 'New Richland' },
  { label: 'New Richmond', value: 'New Richmond' },
  { label: 'New Richmond', value: 'New Richmond' },
  { label: 'New River', value: 'New River' },
  { label: 'New Roads', value: 'New Roads' },
  { label: 'New Roads', value: 'New Roads' },
  { label: 'New Rochelle', value: 'New Rochelle' },
  { label: 'New Rockford', value: 'New Rockford' },
  { label: 'New Romney', value: 'New Romney' },
  { label: 'New Ross', value: 'New Ross' },
  { label: 'New Salhia', value: 'New Salhia' },
  { label: 'New Sarpy', value: 'New Sarpy' },
  { label: 'New Shagunnu', value: 'New Shagunnu' },
  { label: 'New Sharon', value: 'New Sharon' },
  { label: 'New Sharon', value: 'New Sharon' },
  { label: 'New Shoreham', value: 'New Shoreham' },
  { label: 'New Sibonga', value: 'New Sibonga' },
  { label: 'New Sibonga', value: 'New Sibonga' },
  { label: 'New Sibonga', value: 'New Sibonga' },
  { label: 'New Smyrna Beach', value: 'New Smyrna Beach' },
  { label: 'New South Memphis', value: 'New South Memphis' },
  { label: 'New Springville', value: 'New Springville' },
  { label: 'New Square', value: 'New Square' },
  { label: 'New Stanton', value: 'New Stanton' },
  { label: 'New Stevenston', value: 'New Stevenston' },
  { label: 'New Summerfield', value: 'New Summerfield' },
  { label: 'New Tazewell', value: 'New Tazewell' },
  { label: 'New Territory', value: 'New Territory' },
  { label: 'New Town', value: 'New Town' },
  { label: 'New Town', value: 'New Town' },
  { label: 'New Tredegar', value: 'New Tredegar' },
  { label: 'New Ulm', value: 'New Ulm' },
  { label: 'New Union', value: 'New Union' },
  { label: 'New Vienna', value: 'New Vienna' },
  { label: 'New Village', value: 'New Village' },
  { label: 'New Village', value: 'New Village' },
  { label: 'New Visayas', value: 'New Visayas' },
  { label: 'New Visayas', value: 'New Visayas' },
  { label: 'New Visayas', value: 'New Visayas' },
  { label: 'New Washington', value: 'New Washington' },
  { label: 'New Waterford', value: 'New Waterford' },
  { label: 'New Waverly', value: 'New Waverly' },
  { label: 'New Westminster', value: 'New Westminster' },
  { label: 'New Whiteland', value: 'New Whiteland' },
  { label: 'New Wilmington', value: 'New Wilmington' },
  { label: 'New Windsor', value: 'New Windsor' },
  { label: 'New Windsor', value: 'New Windsor' },
  { label: 'New Works', value: 'New Works' },
  { label: 'New Yekepa', value: 'New Yekepa' },
  { label: 'New York City', value: 'New York City' },
  { label: 'New York Mills', value: 'New York Mills' },
  { label: 'New York Mills', value: 'New York Mills' },
  { label: 'Newala Kisimani', value: 'Newala Kisimani' },
  { label: 'Newara', value: 'Newara' },
  { label: 'Newark', value: 'Newark' },
  { label: 'Newark', value: 'Newark' },
  { label: 'Newark', value: 'Newark' },
  { label: 'Newark', value: 'Newark' },
  { label: 'Newark', value: 'Newark' },
  { label: 'Newark', value: 'Newark' },
  { label: 'Newark', value: 'Newark' },
  { label: 'Newark', value: 'Newark' },
  { label: 'Newark On Trent', value: 'Newark On Trent' },
  { label: 'Newarthill', value: 'Newarthill' },
  { label: 'Newaygo', value: 'Newaygo' },
  { label: 'Newaygo County', value: 'Newaygo County' },
  { label: 'Newberg', value: 'Newberg' },
  { label: 'Newbern', value: 'Newbern' },
  { label: 'Newberry', value: 'Newberry' },
  { label: 'Newberry', value: 'Newberry' },
  { label: 'Newberry', value: 'Newberry' },
  { label: 'Newberry County', value: 'Newberry County' },
  { label: 'Newbiggin-By-The-Sea', value: 'Newbiggin-By-The-Sea' },
  { label: 'Newbold Verdon', value: 'Newbold Verdon' },
  { label: 'Newborough', value: 'Newborough' },
  { label: 'Newbridge', value: 'Newbridge' },
  { label: 'Newbridge', value: 'Newbridge' },
  { label: 'Newburg', value: 'Newburg' },
  { label: 'Newburg', value: 'Newburg' },
  { label: 'Newburgh', value: 'Newburgh' },
  { label: 'Newburgh', value: 'Newburgh' },
  { label: 'Newburgh', value: 'Newburgh' },
  { label: 'Newburgh Heights', value: 'Newburgh Heights' },
  { label: 'Newburn', value: 'Newburn' },
  { label: 'Newbury', value: 'Newbury' },
  { label: 'Newbury', value: 'Newbury' },
  { label: 'Newburyport', value: 'Newburyport' },
  { label: 'Newcastle', value: 'Newcastle' },
  { label: 'Newcastle', value: 'Newcastle' },
  { label: 'Newcastle', value: 'Newcastle' },
  { label: 'Newcastle', value: 'Newcastle' },
  { label: 'Newcastle', value: 'Newcastle' },
  { label: 'Newcastle', value: 'Newcastle' },
  { label: 'Newcastle', value: 'Newcastle' },
  { label: 'Newcastle', value: 'Newcastle' },
  { label: 'Newcastle', value: 'Newcastle' },
  { label: 'Newcastle City Centre', value: 'Newcastle City Centre' },
  { label: 'Newcastle East', value: 'Newcastle East' },
  { label: 'Newcastle Emlyn', value: 'Newcastle Emlyn' },
  { label: 'Newcastle Under Lyme', value: 'Newcastle Under Lyme' },
  { label: 'Newcastle Upon Tyne', value: 'Newcastle Upon Tyne' },
  { label: 'Newcastle West', value: 'Newcastle West' },
  { label: 'Newchurch', value: 'Newchurch' },
  { label: 'Newcomb', value: 'Newcomb' },
  { label: 'Newcomerstown', value: 'Newcomerstown' },
  { label: 'Newel', value: 'Newel' },
  { label: 'Newell', value: 'Newell' },
  { label: 'Newell', value: 'Newell' },
  { label: 'Newellton', value: 'Newellton' },
  { label: 'Newent', value: 'Newent' },
  { label: 'Newfane', value: 'Newfane' },
  { label: 'Newfane', value: 'Newfane' },
  { label: 'Newfield', value: 'Newfield' },
  { label: 'Newfield', value: 'Newfield' },
  { label: 'Newhaven', value: 'Newhaven' },
  { label: 'Newick', value: 'Newick' },
  { label: 'Newington', value: 'Newington' },
  { label: 'Newington', value: 'Newington' },
  { label: 'Newington', value: 'Newington' },
  { label: 'Newington', value: 'Newington' },
  { label: 'Newington', value: 'Newington' },
  { label: 'Newkirk', value: 'Newkirk' },
  { label: 'Newland', value: 'Newland' },
  { label: 'Newlands', value: 'Newlands' },
  { label: 'Newmacher', value: 'Newmacher' },
  { label: 'Newmains', value: 'Newmains' },
  { label: 'Newman', value: 'Newman' },
  { label: 'Newman', value: 'Newman' },
  { label: 'Newmanstown', value: 'Newmanstown' },
  { label: 'Newmarket', value: 'Newmarket' },
  { label: 'Newmarket', value: 'Newmarket' },
  { label: 'Newmarket', value: 'Newmarket' },
  { label: 'Newmarket', value: 'Newmarket' },
  { label: 'Newmarket On Fergus', value: 'Newmarket On Fergus' },
  { label: 'Newmilns', value: 'Newmilns' },
  { label: 'Newnan', value: 'Newnan' },
  { label: 'Newnham', value: 'Newnham' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport', value: 'Newport' },
  { label: 'Newport Beach', value: 'Newport Beach' },
  { label: 'Newport County', value: 'Newport County' },
  { label: 'Newport East', value: 'Newport East' },
  { label: 'Newport East', value: 'Newport East' },
  { label: 'Newport News', value: 'Newport News' },
  { label: 'Newport Pagnell', value: 'Newport Pagnell' },
  { label: 'Newport-On-Tay', value: 'Newport-On-Tay' },
  { label: 'Newquay', value: 'Newquay' },
  { label: 'New-Richmond', value: 'New-Richmond' },
  { label: 'Newry', value: 'Newry' },
  { label: 'Newry Mourne And Down', value: 'Newry Mourne And Down' },
  { label: 'Newstead', value: 'Newstead' },
  { label: 'Newstead', value: 'Newstead' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton', value: 'Newton' },
  { label: 'Newton Abbot', value: 'Newton Abbot' },
  { label: 'Newton Aycliffe', value: 'Newton Aycliffe' },
  { label: 'Newton County', value: 'Newton County' },
  { label: 'Newton County', value: 'Newton County' },
  { label: 'Newton County', value: 'Newton County' },
  { label: 'Newton County', value: 'Newton County' },
  { label: 'Newton County', value: 'Newton County' },
  { label: 'Newton County', value: 'Newton County' },
  { label: 'Newton Falls', value: 'Newton Falls' },
  { label: 'Newton Ferrers', value: 'Newton Ferrers' },
  { label: 'Newton Longville', value: 'Newton Longville' },
  { label: 'Newton Mearns', value: 'Newton Mearns' },
  { label: 'Newton Poppleford', value: 'Newton Poppleford' },
  { label: 'Newton Square', value: 'Newton Square' },
  { label: 'Newton Stewart', value: 'Newton Stewart' },
  { label: 'Newtonhill', value: 'Newtonhill' },
  { label: 'Newton-Le-Willows', value: 'Newton-Le-Willows' },
  { label: 'Newtonmore', value: 'Newtonmore' },
  { label: 'Newtown', value: 'Newtown' },
  { label: 'Newtown', value: 'Newtown' },
  { label: 'Newtown', value: 'Newtown' },
  { label: 'Newtown', value: 'Newtown' },
  { label: 'Newtown', value: 'Newtown' },
  { label: 'Newtown', value: 'Newtown' },
  { label: 'Newtown', value: 'Newtown' },
  { label: 'Newtown Cunningham', value: 'Newtown Cunningham' },
  { label: 'Newtown Grant', value: 'Newtown Grant' },
  { label: 'Newtown St Boswells', value: 'Newtown St Boswells' },
  { label: 'Newtown Trim', value: 'Newtown Trim' },
  { label: 'Newtownabbey', value: 'Newtownabbey' },
  { label: 'Newtownards', value: 'Newtownards' },
  { label: 'Newtownmountkennedy', value: 'Newtownmountkennedy' },
  { label: 'Newtownstewart', value: 'Newtownstewart' },
  { label: 'Newville', value: 'Newville' },
  { label: 'Nexø', value: 'Nexø' },
  { label: 'Nexon', value: 'Nexon' },
  { label: 'Nexpan', value: 'Nexpan' },
  { label: 'Nexticapan', value: 'Nexticapan' },
  { label: 'Nextipac', value: 'Nextipac' },
  { label: 'Neya', value: 'Neya' },
  { label: 'Neyagawa', value: 'Neyagawa' },
  { label: 'Neyagawa Shi', value: 'Neyagawa Shi' },
  { label: 'Neydens', value: 'Neydens' },
  { label: 'Neyland', value: 'Neyland' },
  { label: 'Neyriz', value: 'Neyriz' },
  { label: 'Neyshabur', value: 'Neyshabur' },
  { label: 'Neyskiy Rayon', value: 'Neyskiy Rayon' },
  { label: 'Neyvo-Rudyanka', value: 'Neyvo-Rudyanka' },
  { label: 'Neyvo-Shaytanskiy', value: 'Neyvo-Shaytanskiy' },
  { label: 'Neyyattinkara', value: 'Neyyattinkara' },
  { label: 'Nez Perce County', value: 'Nez Perce County' },
  { label: 'Nezamshahr', value: 'Nezamshahr' },
  { label: 'Nezamyslice', value: 'Nezamyslice' },
  { label: 'Nezhdaninskoe', value: 'Nezhdaninskoe' },
  { label: 'Nezhinka', value: 'Nezhinka' },
  { label: 'Nezhinskiy', value: 'Nezhinskiy' },
  { label: 'Nezlobnaya', value: 'Nezlobnaya' },
  { label: 'Nezperce', value: 'Nezperce' },
  { label: 'Nezvěstice', value: 'Nezvěstice' },
  { label: 'Ngaanyatjarraku', value: 'Ngaanyatjarraku' },
  { label: 'Ngaka Modiri Molema District Municipality', value: 'Ngaka Modiri Molema District Municipality' },
  { label: 'Ngala', value: 'Ngala' },
  { label: 'Ngalangkang', value: 'Ngalangkang' },
  { label: 'Ngambé', value: 'Ngambé' },
  { label: 'Nganane', value: 'Nganane' },
  { label: 'Nganglam', value: 'Nganglam' },
  { label: 'Nganjuk', value: 'Nganjuk' },
  { label: 'Ngaoundéré', value: 'Ngaoundéré' },
  { label: 'Ngara', value: 'Ngara' },
  { label: 'Ngardmau', value: 'Ngardmau' },
  { label: 'Ngari Diqu', value: 'Ngari Diqu' },
  { label: 'Ngaruawahia', value: 'Ngaruawahia' },
  { label: 'Ngatea', value: 'Ngatea' },
  { label: 'Ngatik', value: 'Ngatik' },
  { label: 'Ngawi', value: 'Ngawi' },
  { label: 'Ngchemiangel', value: 'Ngchemiangel' },
  { label: 'Ngchesar Hamlet', value: 'Ngchesar Hamlet' },
  { label: 'Ngemplak', value: 'Ngemplak' },
  { label: 'Ngerengere', value: 'Ngerengere' },
  { label: 'Ngerkeai', value: 'Ngerkeai' },
  { label: 'Ngetkib', value: 'Ngetkib' },
  { label: 'Ngomedzap', value: 'Ngomedzap' },
  { label: 'Ngong', value: 'Ngong' },
  { label: 'Ngoro', value: 'Ngoro' },
  { label: 'Ngoro', value: 'Ngoro' },
  { label: 'Ngorongoro', value: 'Ngorongoro' },
  { label: 'Ngou', value: 'Ngou' },
  { label: 'Ngoussa', value: 'Ngoussa' },
  { label: 'Ngozi', value: 'Ngozi' },
  { label: 'Ngudu', value: 'Ngudu' },
  { label: 'Ngudzeni', value: 'Ngudzeni' },
  { label: 'Nguékhokh', value: 'Nguékhokh' },
  { label: 'Nguigmi', value: 'Nguigmi' },
  { label: 'Ngukurr', value: 'Ngukurr' },
  { label: 'Ngulu Municipality', value: 'Ngulu Municipality' },
  { label: 'Ngunguru', value: 'Ngunguru' },
  { label: 'Ngunnawal', value: 'Ngunnawal' },
  { label: 'Ngunut', value: 'Ngunut' },
  { label: 'Ngurore', value: 'Ngurore' },
  { label: 'Nguru', value: 'Nguru' },
  { label: 'Nguruka', value: 'Nguruka' },
  { label: 'Nguti', value: 'Nguti' },
  { label: 'Ngwempisi', value: 'Ngwempisi' },
  { label: 'Nha Trang', value: 'Nha Trang' },
  { label: 'Nhamatanda District', value: 'Nhamatanda District' },
  { label: 'Nhamundá', value: 'Nhamundá' },
  { label: 'Nhandeara', value: 'Nhandeara' },
  { label: 'Nhill', value: 'Nhill' },
  { label: 'Nhlangano', value: 'Nhlangano' },
  { label: 'Nhulunbuy', value: 'Nhulunbuy' },
  { label: 'Nia Valencia', value: 'Nia Valencia' },
  { label: 'Niafunké', value: 'Niafunké' },
  { label: 'Niagara', value: 'Niagara' },
  { label: 'Niagara', value: 'Niagara' },
  { label: 'Niagara County', value: 'Niagara County' },
  { label: 'Niagara Falls', value: 'Niagara Falls' },
  { label: 'Niagara Falls', value: 'Niagara Falls' },
  { label: 'Niagara Park', value: 'Niagara Park' },
  { label: 'Niamina East District', value: 'Niamina East District' },
  { label: 'Niamina West District', value: 'Niamina West District' },
  { label: 'Niamtougou', value: 'Niamtougou' },
  { label: 'Niani', value: 'Niani' },
  { label: 'Nianija District', value: 'Nianija District' },
  { label: 'Niantic', value: 'Niantic' },
  { label: 'Nianzishan', value: 'Nianzishan' },
  { label: 'Niardo', value: 'Niardo' },
  { label: 'Niasar', value: 'Niasar' },
  { label: 'Nibaliw Central', value: 'Nibaliw Central' },
  { label: 'Nibaliw Central', value: 'Nibaliw Central' },
  { label: 'Nibbar', value: 'Nibbar' },
  { label: 'Nibbiano', value: 'Nibbiano' },
  { label: 'Nibbiola', value: 'Nibbiola' },
  { label: 'Nibbixwoud', value: 'Nibbixwoud' },
  { label: 'Nibe', value: 'Nibe' },
  { label: 'Nibionno', value: 'Nibionno' },
  { label: 'Nibley', value: 'Nibley' },
  { label: 'Nibong Tebal', value: 'Nibong Tebal' },
  { label: 'Nīca', value: 'Nīca' },
  { label: 'Nicastro', value: 'Nicastro' },
  { label: 'Nice', value: 'Nice' },
  { label: 'Nice', value: 'Nice' },
  { label: 'Niceville', value: 'Niceville' },
  { label: 'Nichelino', value: 'Nichelino' },
  { label: 'Nichinan', value: 'Nichinan' },
  { label: 'Nichinan Shi', value: 'Nichinan Shi' },
  { label: 'Nichlaul', value: 'Nichlaul' },
  { label: 'Nicholas County', value: 'Nicholas County' },
  { label: 'Nicholas County', value: 'Nicholas County' },
  { label: 'Nicholasville', value: 'Nicholasville' },
  { label: 'Nicholls', value: 'Nicholls' },
  { label: 'Nicholls', value: 'Nicholls' },
  { label: 'Nichols Hills', value: 'Nichols Hills' },
  { label: 'Nichols Point', value: 'Nichols Point' },
  { label: 'Nicholson', value: 'Nicholson' },
  { label: 'Nicholson', value: 'Nicholson' },
  { label: 'Nicholson', value: 'Nicholson' },
  { label: 'Nickelsdorf', value: 'Nickelsdorf' },
  { label: 'Nickenich', value: 'Nickenich' },
  { label: 'Nickerson', value: 'Nickerson' },
  { label: 'Nickol', value: 'Nickol' },
  { label: 'Nicobar', value: 'Nicobar' },
  { label: 'Nicola Town', value: 'Nicola Town' },
  { label: 'Nicolae Bălcescu', value: 'Nicolae Bălcescu' },
  { label: 'Nicolae Bălcescu', value: 'Nicolae Bălcescu' },
  { label: 'Nicolae Bălcescu', value: 'Nicolae Bălcescu' },
  { label: 'Nicolae Bălcescu', value: 'Nicolae Bălcescu' },
  { label: 'Nicolae Bălcescu', value: 'Nicolae Bălcescu' },
  { label: 'Nicolae Bălcescu', value: 'Nicolae Bălcescu' },
  { label: 'Nicolae Titulescu', value: 'Nicolae Titulescu' },
  { label: 'Nicolás Blanco (San Pancho)', value: 'Nicolás Blanco (San Pancho)' },
  { label: 'Nicolas Bravo', value: 'Nicolas Bravo' },
  { label: 'Nicolás Bravo', value: 'Nicolás Bravo' },
  { label: 'Nicolás Bravo', value: 'Nicolás Bravo' },
  { label: 'Nicolás Bravo', value: 'Nicolás Bravo' },
  { label: 'Nicolás Bravo', value: 'Nicolás Bravo' },
  { label: 'Nicolás Bravo', value: 'Nicolás Bravo' },
  { label: 'Nicolás Bravo', value: 'Nicolás Bravo' },
  { label: 'Nicolás Bravo', value: 'Nicolás Bravo' },
  { label: 'Nicolás R Casillas', value: 'Nicolás R Casillas' },
  { label: 'Nicolás Romero', value: 'Nicolás Romero' },
  { label: 'Nicolás Ruiz', value: 'Nicolás Ruiz' },
  { label: 'Nicolau Vergueiro', value: 'Nicolau Vergueiro' },
  { label: 'Nicolești', value: 'Nicolești' },
  { label: 'Nicolești', value: 'Nicolești' },
  { label: 'Nicolet', value: 'Nicolet' },
  { label: 'Nicollet', value: 'Nicollet' },
  { label: 'Nicollet County', value: 'Nicollet County' },
  { label: 'Nicolosi', value: 'Nicolosi' },
  { label: 'Nicoma Park', value: 'Nicoma Park' },
  { label: 'Nicoreşti', value: 'Nicoreşti' },
  { label: 'Nicorvo', value: 'Nicorvo' },
  { label: 'Nicosia', value: 'Nicosia' },
  { label: 'Nicosia', value: 'Nicosia' },
  { label: 'Nicosia Municipality', value: 'Nicosia Municipality' },
  { label: 'Nicotera', value: 'Nicotera' },
  { label: 'Nicoya', value: 'Nicoya' },
  { label: 'Nicşeni', value: 'Nicşeni' },
  { label: 'Niculești', value: 'Niculești' },
  { label: 'Niculiţel', value: 'Niculiţel' },
  { label: 'Nida', value: 'Nida' },
  { label: 'Nidadavole', value: 'Nidadavole' },
  { label: 'Nidau', value: 'Nidau' },
  { label: 'Nidda', value: 'Nidda' },
  { label: 'Nidderau', value: 'Nidderau' },
  { label: 'Niddrie', value: 'Niddrie' },
  { label: 'Nideggen', value: 'Nideggen' },
  { label: 'Nidek', value: 'Nidek' },
  { label: 'Niderviller', value: 'Niderviller' },
  { label: 'Nidzica', value: 'Nidzica' },
  { label: 'Niebieszczany', value: 'Niebieszczany' },
  { label: 'Niebla', value: 'Niebla' },
  { label: 'Niebocko', value: 'Niebocko' },
  { label: 'Nieborów', value: 'Nieborów' },
  { label: 'Niebüll', value: 'Niebüll' },
  { label: 'Niebylec', value: 'Niebylec' },
  { label: 'Niechanowo', value: 'Niechanowo' },
  { label: 'Niechobrz', value: 'Niechobrz' },
  { label: 'Niedenstein', value: 'Niedenstein' },
  { label: 'Niederaichbach', value: 'Niederaichbach' },
  { label: 'Niederalm', value: 'Niederalm' },
  { label: 'Niederalteich', value: 'Niederalteich' },
  { label: 'Niederanven', value: 'Niederanven' },
  { label: 'Niederau', value: 'Niederau' },
  { label: 'Niederau', value: 'Niederau' },
  { label: 'Niederaula', value: 'Niederaula' },
  { label: 'Niederbergkirchen', value: 'Niederbergkirchen' },
  { label: 'Niederbiel', value: 'Niederbiel' },
  { label: 'Niederbipp', value: 'Niederbipp' },
  { label: 'Niederbreitbach', value: 'Niederbreitbach' },
  { label: 'Niederbreitenbach', value: 'Niederbreitenbach' },
  { label: 'Niederbronn-Les-Bains', value: 'Niederbronn-Les-Bains' },
  { label: 'Niederbüren', value: 'Niederbüren' },
  { label: 'Niedercorn', value: 'Niedercorn' },
  { label: 'Niedercunnersdorf', value: 'Niedercunnersdorf' },
  { label: 'Niederdorf', value: 'Niederdorf' },
  { label: 'Niederdorf', value: 'Niederdorf' },
  { label: 'Niederdorfelden', value: 'Niederdorfelden' },
  { label: 'Niederdorla', value: 'Niederdorla' },
  { label: 'Niederdreisbach', value: 'Niederdreisbach' },
  { label: 'Niederdürenbach', value: 'Niederdürenbach' },
  { label: 'Niederelbert', value: 'Niederelbert' },
  { label: 'Niedererbach', value: 'Niedererbach' },
  { label: 'Niedereschach', value: 'Niedereschach' },
  { label: 'Niederfell', value: 'Niederfell' },
  { label: 'Niederfeulen', value: 'Niederfeulen' },
  { label: 'Niederfischbach', value: 'Niederfischbach' },
  { label: 'Niederfrohna', value: 'Niederfrohna' },
  { label: 'Niederfüllbach', value: 'Niederfüllbach' },
  { label: 'Niederglatt', value: 'Niederglatt' },
  { label: 'Niederglatt / Niederglatt (Dorfkern)', value: 'Niederglatt / Niederglatt (Dorfkern)' },
  { label: 'Niedergörsdorf', value: 'Niedergörsdorf' },
  { label: 'Niedergösgen', value: 'Niedergösgen' },
  { label: 'Nieder-Gründau', value: 'Nieder-Gründau' },
  { label: 'Niederhaslach', value: 'Niederhaslach' },
  { label: 'Niederhasli', value: 'Niederhasli' },
  { label: 'Niederhausbergen', value: 'Niederhausbergen' },
  { label: 'Niederhelfenschwil', value: 'Niederhelfenschwil' },
  { label: 'Niederhollabrunn', value: 'Niederhollabrunn' },
  { label: 'Nieder-Ingelheim', value: 'Nieder-Ingelheim' },
  { label: 'Niederkassel', value: 'Niederkassel' },
  { label: 'Niederkirchen', value: 'Niederkirchen' },
  { label: 'Niederkirchen Bei Deidesheim', value: 'Niederkirchen Bei Deidesheim' },
  { label: 'Niederklein', value: 'Niederklein' },
  { label: 'Niederkrüchten', value: 'Niederkrüchten' },
  { label: 'Niederlangen', value: 'Niederlangen' },
  { label: 'Niederlauer', value: 'Niederlauer' },
  { label: 'Niederleis', value: 'Niederleis' },
  { label: 'Niederlenz', value: 'Niederlenz' },
  { label: 'Niedermerz', value: 'Niedermerz' },
  { label: 'Niedermohr', value: 'Niedermohr' },
  { label: 'Niedermurach', value: 'Niedermurach' },
  { label: 'Niedernai', value: 'Niedernai' },
  { label: 'Niedernberg', value: 'Niedernberg' },
  { label: 'Niederndodeleben', value: 'Niederndodeleben' },
  { label: 'Niederndorf', value: 'Niederndorf' },
  { label: 'Niederneisen', value: 'Niederneisen' },
  { label: 'Niederneukirchen', value: 'Niederneukirchen' },
  { label: 'Niedernhall', value: 'Niedernhall' },
  { label: 'Niedernhausen', value: 'Niedernhausen' },
  { label: 'Niedernsill', value: 'Niedernsill' },
  { label: 'Niedernwöhren', value: 'Niedernwöhren' },
  { label: 'Niederöblarn', value: 'Niederöblarn' },
  { label: 'Nieder-Olm', value: 'Nieder-Olm' },
  { label: 'Niederorschel', value: 'Niederorschel' },
  { label: 'Niederrad', value: 'Niederrad' },
  { label: 'Niederrieden', value: 'Niederrieden' },
  { label: 'Niederrohrdorf', value: 'Niederrohrdorf' },
  { label: 'Niederroßla', value: 'Niederroßla' },
  { label: 'Niedersachswerfen', value: 'Niedersachswerfen' },
  { label: 'Niederschaeffolsheim', value: 'Niederschaeffolsheim' },
  { label: 'Niederschöckl', value: 'Niederschöckl' },
  { label: 'Niederschöna', value: 'Niederschöna' },
  { label: 'Niederschönenfeld', value: 'Niederschönenfeld' },
  { label: 'Niederschöneweide', value: 'Niederschöneweide' },
  { label: 'Niederschönhausen', value: 'Niederschönhausen' },
  { label: 'Niederstetten', value: 'Niederstetten' },
  { label: 'Niederstotzingen', value: 'Niederstotzingen' },
  { label: 'Niederstraß', value: 'Niederstraß' },
  { label: 'Niederstriegis', value: 'Niederstriegis' },
  { label: 'Niedertaufkirchen', value: 'Niedertaufkirchen' },
  { label: 'Niederthalheim', value: 'Niederthalheim' },
  { label: 'Niederurnen', value: 'Niederurnen' },
  { label: 'Niederviehbach', value: 'Niederviehbach' },
  { label: 'Niederwangen', value: 'Niederwangen' },
  { label: 'Niederwerrn', value: 'Niederwerrn' },
  { label: 'Niederwerth', value: 'Niederwerth' },
  { label: 'Niederwiesa', value: 'Niederwiesa' },
  { label: 'Niederwinkling', value: 'Niederwinkling' },
  { label: 'Niederwölz', value: 'Niederwölz' },
  { label: 'Niederzier', value: 'Niederzier' },
  { label: 'Niederzimmern', value: 'Niederzimmern' },
  { label: 'Niederzissen', value: 'Niederzissen' },
  { label: 'Niedobczyce', value: 'Niedobczyce' },
  { label: 'Niedomice', value: 'Niedomice' },
  { label: 'Niedrzwica Duża', value: 'Niedrzwica Duża' },
  { label: 'Niedzica', value: 'Niedzica' },
  { label: 'Niedźwiada', value: 'Niedźwiada' },
  { label: 'Niedźwiada', value: 'Niedźwiada' },
  { label: 'Niedźwiedź', value: 'Niedźwiedź' },
  { label: 'Niefern-Öschelbronn', value: 'Niefern-Öschelbronn' },
  { label: 'Niegowonice', value: 'Niegowonice' },
  { label: 'Nieheim', value: 'Nieheim' },
  { label: 'Niekerk', value: 'Niekerk' },
  { label: 'Niel', value: 'Niel' },
  { label: 'Nielisz', value: 'Nielisz' },
  { label: 'Niella Belbo', value: 'Niella Belbo' },
  { label: 'Niella Tanaro', value: 'Niella Tanaro' },
  { label: 'Niemberg', value: 'Niemberg' },
  { label: 'Niemce', value: 'Niemce' },
  { label: 'Niemcza', value: 'Niemcza' },
  { label: 'Niemegk', value: 'Niemegk' },
  { label: 'Niemodlin', value: 'Niemodlin' },
  { label: 'Nienadowa', value: 'Nienadowa' },
  { label: 'Nienburg', value: 'Nienburg' },
  { label: 'Nienburg/Saale', value: 'Nienburg/Saale' },
  { label: 'Nienhagen', value: 'Nienhagen' },
  { label: 'Nienstädt', value: 'Nienstädt' },
  { label: 'Niepars', value: 'Niepars' },
  { label: 'Niepołomice', value: 'Niepołomice' },
  { label: 'Nieporęt', value: 'Nieporęt' },
  { label: 'Nieppe', value: 'Nieppe' },
  { label: 'Nierstein', value: 'Nierstein' },
  { label: 'Niesky', value: 'Niesky' },
  { label: 'Nieszawa', value: 'Nieszawa' },
  { label: 'Nieul', value: 'Nieul' },
  { label: 'Nieul-Le-Dolent', value: 'Nieul-Le-Dolent' },
  { label: 'Nieul-Lès-Saintes', value: 'Nieul-Lès-Saintes' },
  { label: 'Nieul-Sur-Mer', value: 'Nieul-Sur-Mer' },
  { label: 'Nieuw Amsterdam', value: 'Nieuw Amsterdam' },
  { label: 'Nieuw- En Sint Joosland', value: 'Nieuw- En Sint Joosland' },
  { label: 'Nieuw Nickerie', value: 'Nieuw Nickerie' },
  { label: 'Nieuw-Beijerland', value: 'Nieuw-Beijerland' },
  { label: 'Nieuw-Buinen', value: 'Nieuw-Buinen' },
  { label: 'Nieuw-Dordrecht', value: 'Nieuw-Dordrecht' },
  { label: 'Nieuwdorp', value: 'Nieuwdorp' },
  { label: 'Nieuwe Meer', value: 'Nieuwe Meer' },
  { label: 'Nieuwe Niedorp', value: 'Nieuwe Niedorp' },
  { label: 'Nieuwe Pekela', value: 'Nieuwe Pekela' },
  { label: 'Nieuwebrug', value: 'Nieuwebrug' },
  { label: 'Nieuwegein', value: 'Nieuwegein' },
  { label: 'Nieuwehorne', value: 'Nieuwehorne' },
  { label: 'Nieuwendam', value: 'Nieuwendam' },
  { label: 'Nieuwenhoorn', value: 'Nieuwenhoorn' },
  { label: 'Nieuwerkerk', value: 'Nieuwerkerk' },
  { label: 'Nieuwerkerken', value: 'Nieuwerkerken' },
  { label: 'Nieuwesluis', value: 'Nieuwesluis' },
  { label: 'Nieuwkoop', value: 'Nieuwkoop' },
  { label: 'Nieuwkuijk', value: 'Nieuwkuijk' },
  { label: 'Nieuw-Lekkerland', value: 'Nieuw-Lekkerland' },
  { label: 'Nieuw-Loosdrecht', value: 'Nieuw-Loosdrecht' },
  { label: 'Nieuw-Lotbroek', value: 'Nieuw-Lotbroek' },
  { label: 'Nieuwolda', value: 'Nieuwolda' },
  { label: 'Nieuwoord', value: 'Nieuwoord' },
  { label: 'Nieuwpoort', value: 'Nieuwpoort' },
  { label: 'Nieuwpoort', value: 'Nieuwpoort' },
  { label: 'Nieuw-Roden', value: 'Nieuw-Roden' },
  { label: 'Nieuwveen', value: 'Nieuwveen' },
  { label: 'Nieuw-Vennep', value: 'Nieuw-Vennep' },
  { label: 'Nieuw-Vossemeer', value: 'Nieuw-Vossemeer' },
  { label: 'Nieva', value: 'Nieva' },
  { label: 'Nieva De Cameros', value: 'Nieva De Cameros' },
  { label: 'Nieves', value: 'Nieves' },
  { label: 'Nieves', value: 'Nieves' },
  { label: 'Nieves', value: 'Nieves' },
  { label: 'Niévroz', value: 'Niévroz' },
  { label: 'Nigel', value: 'Nigel' },
  { label: 'Nighoj', value: 'Nighoj' },
  { label: 'Nightcliff', value: 'Nightcliff' },
  { label: 'Nigoh', value: 'Nigoh' },
  { label: 'Nigoline-Bonomelli', value: 'Nigoline-Bonomelli' },
  { label: 'Nigrán', value: 'Nigrán' },
  { label: 'Nigríta', value: 'Nigríta' },
  { label: 'Nigromante', value: 'Nigromante' },
  { label: 'Nigüelas', value: 'Nigüelas' },
  { label: 'Nigüella', value: 'Nigüella' },
  { label: 'Niharra', value: 'Niharra' },
  { label: 'Niherne', value: 'Niherne' },
  { label: 'Nihm', value: 'Nihm' },
  { label: 'Nihommatsu', value: 'Nihommatsu' },
  { label: 'Nihonmatsu Shi', value: 'Nihonmatsu Shi' },
  { label: 'Nihtaur', value: 'Nihtaur' },
  { label: 'Niigata', value: 'Niigata' },
  { label: 'Niigata Shi', value: 'Niigata Shi' },
  { label: 'Niihama', value: 'Niihama' },
  { label: 'Niihama-Shi', value: 'Niihama-Shi' },
  { label: 'Niimi', value: 'Niimi' },
  { label: 'Niimi Shi', value: 'Niimi Shi' },
  { label: 'Niitsu-Honchō', value: 'Niitsu-Honchō' },
  { label: 'Niiza-Shi', value: 'Niiza-Shi' },
  { label: 'Níjar', value: 'Níjar' },
  { label: 'Nijemci', value: 'Nijemci' },
  { label: 'Nijenheim', value: 'Nijenheim' },
  { label: 'Nijkerk', value: 'Nijkerk' },
  { label: 'Nijkerkerveen', value: 'Nijkerkerveen' },
  { label: 'Nijlen', value: 'Nijlen' },
  { label: 'Nijmegen', value: 'Nijmegen' },
  { label: 'Nijnsel', value: 'Nijnsel' },
  { label: 'Nijrees', value: 'Nijrees' },
  { label: 'Nik Abad', value: 'Nik Abad' },
  { label: 'Nik Pey', value: 'Nik Pey' },
  { label: 'Nikaho-Shi', value: 'Nikaho-Shi' },
  { label: 'Níkaia', value: 'Níkaia' },
  { label: 'Nikel', value: 'Nikel' },
  { label: 'Nikinci', value: 'Nikinci' },
  { label: 'Nikísiani', value: 'Nikísiani' },
  { label: 'Nikiski', value: 'Nikiski' },
  { label: 'Nikita', value: 'Nikita' },
  { label: 'Níkiti', value: 'Níkiti' },
  { label: 'Nikitinskiy', value: 'Nikitinskiy' },
  { label: 'Nikitsch', value: 'Nikitsch' },
  { label: 'Nikki', value: 'Nikki' },
  { label: 'Nikkō', value: 'Nikkō' },
  { label: 'Nikko-Shi', value: 'Nikko-Shi' },
  { label: 'Niklasdorf', value: 'Niklasdorf' },
  { label: 'Nikol’Sk', value: 'Nikol’Sk' },
  { label: 'Nikol’Sk', value: 'Nikol’Sk' },
  { label: 'Nikol’Sk', value: 'Nikol’Sk' },
  { label: 'Nikol’Skoye', value: 'Nikol’Skoye' },
  { label: 'Nikol’Skoye', value: 'Nikol’Skoye' },
  { label: 'Nikolaevo', value: 'Nikolaevo' },
  { label: 'Nikola-Lenivets', value: 'Nikola-Lenivets' },
  { label: 'Nikolassee', value: 'Nikolassee' },
  { label: 'Nikolayevka', value: 'Nikolayevka' },
  { label: 'Nikolayevka', value: 'Nikolayevka' },
  { label: 'Nikolayevka', value: 'Nikolayevka' },
  { label: 'Nikolayevka', value: 'Nikolayevka' },
  { label: 'Nikolayevsk', value: 'Nikolayevsk' },
  { label: 'Nikolayevskaya', value: 'Nikolayevskaya' },
  { label: 'Nikolayevskiy', value: 'Nikolayevskiy' },
  { label: 'Nikolayevsk-On-Amure', value: 'Nikolayevsk-On-Amure' },
  { label: 'Nikolina Gora', value: 'Nikolina Gora' },
  { label: 'Nikolinci', value: 'Nikolinci' },
  { label: 'Nikolo-Berëzovka', value: 'Nikolo-Berëzovka' },
  { label: 'Nikologory', value: 'Nikologory' },
  { label: 'Nikolo-Pavlovskoye', value: 'Nikolo-Pavlovskoye' },
  { label: 'Nikolsdorf', value: 'Nikolsdorf' },
  { label: 'Nikolske Raion', value: 'Nikolske Raion' },
  { label: 'Nikopol', value: 'Nikopol' },
  { label: 'Nikopol', value: 'Nikopol' },
  { label: 'Nikora', value: 'Nikora' },
  { label: 'Niksar', value: 'Niksar' },
  { label: 'Nikshahr', value: 'Nikshahr' },
  { label: 'Nikšić', value: 'Nikšić' },
  { label: 'Nikulino', value: 'Nikulino' },
  { label: 'Nikunau', value: 'Nikunau' },
  { label: 'Nil Shahr', value: 'Nil Shahr' },
  { label: 'Nilakottai', value: 'Nilakottai' },
  { label: 'Niland', value: 'Niland' },
  { label: 'Nilanga', value: 'Nilanga' },
  { label: 'Niles', value: 'Niles' },
  { label: 'Niles', value: 'Niles' },
  { label: 'Niles', value: 'Niles' },
  { label: 'Nileshwar', value: 'Nileshwar' },
  { label: 'Nilgiri', value: 'Nilgiri' },
  { label: 'Nilgiris', value: 'Nilgiris' },
  { label: 'Nīlī', value: 'Nīlī' },
  { label: 'Nillumbik', value: 'Nillumbik' },
  { label: 'Nilo', value: 'Nilo' },
  { label: 'Nilo Peçanha', value: 'Nilo Peçanha' },
  { label: 'Nilokheri', value: 'Nilokheri' },
  { label: 'Nilombot', value: 'Nilombot' },
  { label: 'Nilombot', value: 'Nilombot' },
  { label: 'Nilópolis', value: 'Nilópolis' },
  { label: 'Nilphamari ', value: 'Nilphamari ' },
  { label: 'Niltepec', value: 'Niltepec' },
  { label: 'Nilüfer', value: 'Nilüfer' },
  { label: 'Nilvange', value: 'Nilvange' },
  { label: 'Nima Milășelului', value: 'Nima Milășelului' },
  { label: 'Nima Râciului', value: 'Nima Râciului' },
  { label: 'Nimaima', value: 'Nimaima' },
  { label: 'Nimaj', value: 'Nimaj' },
  { label: 'Nimaparha', value: 'Nimaparha' },
  { label: 'Nimbahera', value: 'Nimbahera' },
  { label: 'Nimbin', value: 'Nimbin' },
  { label: 'Nimbolook', value: 'Nimbolook' },
  { label: 'Nîmes', value: 'Nîmes' },
  { label: 'Nimigea De Jos', value: 'Nimigea De Jos' },
  { label: 'Nimigea De Sus', value: 'Nimigea De Sus' },
  { label: 'Nimis', value: 'Nimis' },
  { label: 'Nimruz', value: 'Nimruz' },
  { label: 'Nimvar', value: 'Nimvar' },
  { label: 'Nin', value: 'Nin' },
  { label: 'Nina Rodrigues', value: 'Nina Rodrigues' },
  { label: 'Ninderry', value: 'Ninderry' },
  { label: 'Nindirí', value: 'Nindirí' },
  { label: 'Nindorf', value: 'Nindorf' },
  { label: 'Ninety Six', value: 'Ninety Six' },
  { label: 'Ninfield', value: 'Ninfield' },
  { label: 'Ning’An', value: 'Ning’An' },
  { label: 'Ningbo', value: 'Ningbo' },
  { label: 'Ningde', value: 'Ningde' },
  { label: 'Ninghai', value: 'Ninghai' },
  { label: 'Ninghai', value: 'Ninghai' },
  { label: 'Ningi', value: 'Ningi' },
  { label: 'Ninguno [Centro De Readaptación Social De Atlacholoaya]', value: 'Ninguno [Centro De Readaptación Social De Atlacholoaya]' },
  { label: 'Ninguno [Cereso]', value: 'Ninguno [Cereso]' },
  { label: 'Ninguno [Cereso]', value: 'Ninguno [Cereso]' },
  { label: 'Ningyang', value: 'Ningyang' },
  { label: 'Ninh Bình', value: 'Ninh Bình' },
  { label: 'Ninheira', value: 'Ninheira' },
  { label: 'Ninhue', value: 'Ninhue' },
  { label: 'Ninnekah', value: 'Ninnekah' },
  { label: 'Ninohe', value: 'Ninohe' },
  { label: 'Ninohe Shi', value: 'Ninohe Shi' },
  { label: 'Ninomiya', value: 'Ninomiya' },
  { label: 'Niños Héroes', value: 'Niños Héroes' },
  { label: 'Ninotsminda', value: 'Ninotsminda' },
  { label: 'Ninove', value: 'Ninove' },
  { label: 'Ninoy', value: 'Ninoy' },
  { label: 'Ninoy', value: 'Ninoy' },
  { label: 'Niny', value: 'Niny' },
  { label: 'Nioaque', value: 'Nioaque' },
  { label: 'Niobrara County', value: 'Niobrara County' },
  { label: 'Nioki', value: 'Nioki' },
  { label: 'Nioro', value: 'Nioro' },
  { label: 'Nioro Du Rip', value: 'Nioro Du Rip' },
  { label: 'Niort', value: 'Niort' },
  { label: 'Nioumachoua', value: 'Nioumachoua' },
  { label: 'Nioumamilima', value: 'Nioumamilima' },
  { label: 'Nipa Kutubu', value: 'Nipa Kutubu' },
  { label: 'Nipani', value: 'Nipani' },
  { label: 'Nipawin', value: 'Nipawin' },
  { label: 'Niphad', value: 'Niphad' },
  { label: 'Nipissing District', value: 'Nipissing District' },
  { label: 'Nipoã', value: 'Nipoã' },
  { label: 'Nipomo', value: 'Nipomo' },
  { label: 'Nippes', value: 'Nippes' },
  { label: 'Niquelândia', value: 'Niquelândia' },
  { label: 'Ñiquén', value: 'Ñiquén' },
  { label: 'Niquero', value: 'Niquero' },
  { label: 'Niquinohomo', value: 'Niquinohomo' },
  { label: 'Nir', value: 'Nir' },
  { label: 'Nir', value: 'Nir' },
  { label: 'Nira Narsingpur', value: 'Nira Narsingpur' },
  { label: 'Nirasaki', value: 'Nirasaki' },
  { label: 'Nirasaki-Shi', value: 'Nirasaki-Shi' },
  { label: 'Nireș', value: 'Nireș' },
  { label: 'Nirgua', value: 'Nirgua' },
  { label: 'Nirit', value: 'Nirit' },
  { label: 'Nirmal', value: 'Nirmal' },
  { label: 'Nirmali', value: 'Nirmali' },
  { label: 'Nirsa', value: 'Nirsa' },
  { label: 'Niš', value: 'Niš' },
  { label: 'Nisa', value: 'Nisa' },
  { label: 'Nisab', value: 'Nisab' },
  { label: 'Nisab', value: 'Nisab' },
  { label: 'Nišava', value: 'Nišava' },
  { label: 'Niscemi', value: 'Niscemi' },
  { label: 'Niseko Town', value: 'Niseko Town' },
  { label: 'Nishifukuma', value: 'Nishifukuma' },
  { label: 'Nishinomiya Shi', value: 'Nishinomiya Shi' },
  { label: 'Nishinomiya-Hama', value: 'Nishinomiya-Hama' },
  { label: 'Nishinoomote', value: 'Nishinoomote' },
  { label: 'Nishinoomote Shi', value: 'Nishinoomote Shi' },
  { label: 'Nishio', value: 'Nishio' },
  { label: 'Nishio-Shi', value: 'Nishio-Shi' },
  { label: 'Nishishinminato', value: 'Nishishinminato' },
  { label: 'Nishi-Tokyo-Shi', value: 'Nishi-Tokyo-Shi' },
  { label: 'Nishitōkyō-Shi', value: 'Nishitōkyō-Shi' },
  { label: 'Nishiwaki', value: 'Nishiwaki' },
  { label: 'Nishiwaki-Shi', value: 'Nishiwaki-Shi' },
  { label: 'Nishon Tumani', value: 'Nishon Tumani' },
  { label: 'Nisí', value: 'Nisí' },
  { label: 'Nísia Floresta', value: 'Nísia Floresta' },
  { label: 'Nisipari', value: 'Nisipari' },
  { label: 'Nisiporești', value: 'Nisiporești' },
  { label: 'Niskayuna', value: 'Niskayuna' },
  { label: 'Nisko', value: 'Nisko' },
  { label: 'Nispen', value: 'Nispen' },
  { label: 'Nisporeni', value: 'Nisporeni' },
  { label: 'Nissan-Lez-Enserune', value: 'Nissan-Lez-Enserune' },
  { label: 'Nissedal', value: 'Nissedal' },
  { label: 'Nissequogue', value: 'Nissequogue' },
  { label: 'Nissewaard', value: 'Nissewaard' },
  { label: 'Nisshin-Shi', value: 'Nisshin-Shi' },
  { label: 'Nissoria', value: 'Nissoria' },
  { label: 'Nisswa', value: 'Nisswa' },
  { label: 'Nistelrode', value: 'Nistelrode' },
  { label: 'Nister', value: 'Nister' },
  { label: 'Nistoreşti', value: 'Nistoreşti' },
  { label: 'Nistru', value: 'Nistru' },
  { label: 'Niţchidorf', value: 'Niţchidorf' },
  { label: 'Niterói', value: 'Niterói' },
  { label: 'Niton', value: 'Niton' },
  { label: 'Nitra', value: 'Nitra' },
  { label: 'Nitro', value: 'Nitro' },
  { label: 'Nitscha', value: 'Nitscha' },
  { label: 'Nittedal', value: 'Nittedal' },
  { label: 'Nittel', value: 'Nittel' },
  { label: 'Nittenau', value: 'Nittenau' },
  { label: 'Nittendorf', value: 'Nittendorf' },
  { label: 'Niugan', value: 'Niugan' },
  { label: 'Niugan', value: 'Niugan' },
  { label: 'Niugan', value: 'Niugan' },
  { label: 'Niulakita', value: 'Niulakita' },
  { label: 'Nivå', value: 'Nivå' },
  { label: 'Nivala', value: 'Nivala' },
  { label: 'Nívar', value: 'Nívar' },
  { label: 'Nivelles', value: 'Nivelles' },
  { label: 'Niverville', value: 'Niverville' },
  { label: 'Niverville', value: 'Niverville' },
  { label: 'Niviano', value: 'Niviano' },
  { label: 'Nivillac', value: 'Nivillac' },
  { label: 'Nivnice', value: 'Nivnice' },
  { label: 'Nivolas-Vermelle', value: 'Nivolas-Vermelle' },
  { label: 'Niwai', value: 'Niwai' },
  { label: 'Niwari', value: 'Niwari' },
  { label: 'Niwiska', value: 'Niwiska' },
  { label: 'Niwot', value: 'Niwot' },
  { label: 'Nixa', value: 'Nixa' },
  { label: 'Nixon', value: 'Nixon' },
  { label: 'Nixon', value: 'Nixon' },
  { label: 'Niyanj', value: 'Niyanj' },
  { label: 'Niyodogawa', value: 'Niyodogawa' },
  { label: 'Nizamabad', value: 'Nizamabad' },
  { label: 'Nizamabad', value: 'Nizamabad' },
  { label: 'Nizami', value: 'Nizami' },
  { label: 'Nizami Rayonu', value: 'Nizami Rayonu' },
  { label: 'Nizao', value: 'Nizao' },
  { label: 'Nizar', value: 'Nizar' },
  { label: 'Nižbor', value: 'Nižbor' },
  { label: 'Nizhneangarsk', value: 'Nizhneangarsk' },
  { label: 'Nizhnedevitsk', value: 'Nizhnedevitsk' },
  { label: 'Nizhnedevitskiy Rayon', value: 'Nizhnedevitskiy Rayon' },
  { label: 'Nizhnegorskiy', value: 'Nizhnegorskiy' },
  { label: 'Nizhnegorskiy Rayon', value: 'Nizhnegorskiy Rayon' },
  { label: 'Nizhneilimskiy Rayon', value: 'Nizhneilimskiy Rayon' },
  { label: 'Nizhneingashskiy Rayon', value: 'Nizhneingashskiy Rayon' },
  { label: 'Nizhneivkino', value: 'Nizhneivkino' },
  { label: 'Nizhnekamsk', value: 'Nizhnekamsk' },
  { label: 'Nizhnekamskiy Rayon', value: 'Nizhnekamskiy Rayon' },
  { label: 'Nizhnepavlovka', value: 'Nizhnepavlovka' },
  { label: 'Nizhnesortymskiy', value: 'Nizhnesortymskiy' },
  { label: 'Nizhnetroitskiy', value: 'Nizhnetroitskiy' },
  { label: 'Nizhneudinsk', value: 'Nizhneudinsk' },
  { label: 'Nizhneudinskiy Rayon', value: 'Nizhneudinskiy Rayon' },
  { label: 'Nizhnevartovsk', value: 'Nizhnevartovsk' },
  { label: 'Nizhneye Kazanishche', value: 'Nizhneye Kazanishche' },
  { label: 'Nizhniy Arkhyz', value: 'Nizhniy Arkhyz' },
  { label: 'Nizhniy Baskunchak', value: 'Nizhniy Baskunchak' },
  { label: 'Nizhniy Bestyakh', value: 'Nizhniy Bestyakh' },
  { label: 'Nizhniy Cherek', value: 'Nizhniy Cherek' },
  { label: 'Nizhniy Dzhengutay', value: 'Nizhniy Dzhengutay' },
  { label: 'Nizhniy Ingash', value: 'Nizhniy Ingash' },
  { label: 'Nizhniy Kislyay', value: 'Nizhniy Kislyay' },
  { label: 'Nizhniy Kuranakh', value: 'Nizhniy Kuranakh' },
  { label: 'Nizhniy Kurkuzhin', value: 'Nizhniy Kurkuzhin' },
  { label: 'Nizhniy Lomov', value: 'Nizhniy Lomov' },
  { label: 'Nizhniy Mamon', value: 'Nizhniy Mamon' },
  { label: 'Nizhniy Novgorod', value: 'Nizhniy Novgorod' },
  { label: 'Nizhniy Odes', value: 'Nizhniy Odes' },
  { label: 'Nizhniy Sayantuy', value: 'Nizhniy Sayantuy' },
  { label: 'Nizhniy Tsasuchey', value: 'Nizhniy Tsasuchey' },
  { label: 'Nizhniy Ufaley', value: 'Nizhniy Ufaley' },
  { label: 'Nizhniye Achaluki', value: 'Nizhniye Achaluki' },
  { label: 'Nizhniye Sergi', value: 'Nizhniye Sergi' },
  { label: 'Nizhniye Vyazovyye', value: 'Nizhniye Vyazovyye' },
  { label: 'Nizhny Chir', value: 'Nizhny Chir' },
  { label: 'Nizhny Tagil', value: 'Nizhny Tagil' },
  { label: 'Nizhnyaya Irga', value: 'Nizhnyaya Irga' },
  { label: 'Nizhnyaya Maktama', value: 'Nizhnyaya Maktama' },
  { label: 'Nizhnyaya Omka', value: 'Nizhnyaya Omka' },
  { label: 'Nizhnyaya Poyma', value: 'Nizhnyaya Poyma' },
  { label: 'Nizhnyaya Salda', value: 'Nizhnyaya Salda' },
  { label: 'Nizhnyaya Tavda', value: 'Nizhnyaya Tavda' },
  { label: 'Nizhnyaya Tura', value: 'Nizhnyaya Tura' },
  { label: 'Nizhyn', value: 'Nizhyn' },
  { label: 'Nizip', value: 'Nizip' },
  { label: 'Nižná', value: 'Nižná' },
  { label: 'Nizwá', value: 'Nizwá' },
  { label: 'Nizza Di Sicilia', value: 'Nizza Di Sicilia' },
  { label: 'Nizza Monferrato', value: 'Nizza Monferrato' },
  { label: 'Njeru', value: 'Njeru' },
  { label: 'Njinikom', value: 'Njinikom' },
  { label: 'Njivice', value: 'Njivice' },
  { label: 'Njombe', value: 'Njombe' },
  { label: 'Njurundabommen', value: 'Njurundabommen' },
  { label: 'Nkangala District Municipality', value: 'Nkangala District Municipality' },
  { label: 'Nkayi District', value: 'Nkayi District' },
  { label: 'Nkhaba', value: 'Nkhaba' },
  { label: 'Nkhata Bay', value: 'Nkhata Bay' },
  { label: 'Nkhata Bay District', value: 'Nkhata Bay District' },
  { label: 'Nkhotakota', value: 'Nkhotakota' },
  { label: 'Nkhotakota District', value: 'Nkhotakota District' },
  { label: 'Nkoaranga', value: 'Nkoaranga' },
  { label: 'Nkongsamba', value: 'Nkongsamba' },
  { label: 'Nkoranza North', value: 'Nkoranza North' },
  { label: 'Nkoranza South', value: 'Nkoranza South' },
  { label: 'Nkoteng', value: 'Nkoteng' },
  { label: 'Nkove', value: 'Nkove' },
  { label: 'Nkowakowa', value: 'Nkowakowa' },
  { label: 'Nkoyaphiri', value: 'Nkoyaphiri' },
  { label: 'Nkpor', value: 'Nkpor' },
  { label: 'Nkwanta North', value: 'Nkwanta North' },
  { label: 'Nkwanta South', value: 'Nkwanta South' },
  { label: 'Nkwene', value: 'Nkwene' },
  { label: 'Nnewi', value: 'Nnewi' },
  { label: 'No Bandegan', value: 'No Bandegan' },
  { label: 'No Kunda', value: 'No Kunda' },
  { label: 'Noaillan', value: 'Noaillan' },
  { label: 'Noailles', value: 'Noailles' },
  { label: 'Noailles', value: 'Noailles' },
  { label: 'Noáin (Valle De Elorz)/Noain (Elortzibar)', value: 'Noáin (Valle De Elorz)/Noain (Elortzibar)' },
  { label: 'Noakhali', value: 'Noakhali' },
  { label: 'Noale', value: 'Noale' },
  { label: 'Noalejo', value: 'Noalejo' },
  { label: 'Noamundi', value: 'Noamundi' },
  { label: 'Noank', value: 'Noank' },
  { label: 'Noardburgum', value: 'Noardburgum' },
  { label: 'Noarlunga Downs', value: 'Noarlunga Downs' },
  { label: 'Noasca', value: 'Noasca' },
  { label: 'Nöbdenitz', value: 'Nöbdenitz' },
  { label: 'Nobeoka', value: 'Nobeoka' },
  { label: 'Nobeoka-Shi', value: 'Nobeoka-Shi' },
  { label: 'Nobile-Monguzzo', value: 'Nobile-Monguzzo' },
  { label: 'Nobitz', value: 'Nobitz' },
  { label: 'Noble', value: 'Noble' },
  { label: 'Noble County', value: 'Noble County' },
  { label: 'Noble County', value: 'Noble County' },
  { label: 'Noble County', value: 'Noble County' },
  { label: 'Noble Park', value: 'Noble Park' },
  { label: 'Noble Park North', value: 'Noble Park North' },
  { label: 'Nobleboro', value: 'Nobleboro' },
  { label: 'Noblejas', value: 'Noblejas' },
  { label: 'Nobles County', value: 'Nobles County' },
  { label: 'Noblesville', value: 'Noblesville' },
  { label: 'Noboribetsu', value: 'Noboribetsu' },
  { label: 'Noboribetsu-Shi', value: 'Noboribetsu-Shi' },
  { label: 'Nobres', value: 'Nobres' },
  { label: 'Nobsa', value: 'Nobsa' },
  { label: 'Nocaima', value: 'Nocaima' },
  { label: 'Nocara', value: 'Nocara' },
  { label: 'Nocatee', value: 'Nocatee' },
  { label: 'Nocciano', value: 'Nocciano' },
  { label: 'Noceda Del Bierzo', value: 'Noceda Del Bierzo' },
  { label: 'Nocelleto', value: 'Nocelleto' },
  { label: 'Nocera Inferiore', value: 'Nocera Inferiore' },
  { label: 'Nocera Scalo', value: 'Nocera Scalo' },
  { label: 'Nocera Superiore', value: 'Nocera Superiore' },
  { label: 'Nocera Terinese', value: 'Nocera Terinese' },
  { label: 'Nocera Umbra', value: 'Nocera Umbra' },
  { label: 'Noceto', value: 'Noceto' },
  { label: 'Nochistlán De Mejía', value: 'Nochistlán De Mejía' },
  { label: 'Nöchling', value: 'Nöchling' },
  { label: 'Noci', value: 'Noci' },
  { label: 'Nociglia', value: 'Nociglia' },
  { label: 'Nocona', value: 'Nocona' },
  { label: 'Nocrich', value: 'Nocrich' },
  { label: 'Nocupétaro', value: 'Nocupétaro' },
  { label: 'Noda', value: 'Noda' },
  { label: 'Noda-Shi', value: 'Noda-Shi' },
  { label: 'Nodaway County', value: 'Nodaway County' },
  { label: 'Nødebo', value: 'Nødebo' },
  { label: 'Nodej', value: 'Nodej' },
  { label: 'Nodeland', value: 'Nodeland' },
  { label: 'Nodoushan', value: 'Nodoushan' },
  { label: 'Noé', value: 'Noé' },
  { label: 'Noe Valley', value: 'Noe Valley' },
  { label: 'Noel', value: 'Noel' },
  { label: 'Noepoli', value: 'Noepoli' },
  { label: 'Noetinger', value: 'Noetinger' },
  { label: 'Nœux-Les-Mines', value: 'Nœux-Les-Mines' },
  { label: 'Noez', value: 'Noez' },
  { label: 'Nof Ayalon', value: 'Nof Ayalon' },
  { label: 'Nofoali‘I', value: 'Nofoali‘I' },
  { label: 'Nogais As', value: 'Nogais As' },
  { label: 'Nogal De Las Huertas', value: 'Nogal De Las Huertas' },
  { label: 'Nogales', value: 'Nogales' },
  { label: 'Nogales', value: 'Nogales' },
  { label: 'Nogales', value: 'Nogales' },
  { label: 'Nogales', value: 'Nogales' },
  { label: 'Nogales', value: 'Nogales' },
  { label: 'Nogales', value: 'Nogales' },
  { label: 'Nogamerzin-Yurt', value: 'Nogamerzin-Yurt' },
  { label: 'Nogara', value: 'Nogara' },
  { label: 'Nogaredo', value: 'Nogaredo' },
  { label: 'Nogaro', value: 'Nogaro' },
  { label: 'Nogarole Rocca', value: 'Nogarole Rocca' },
  { label: 'Nogarole Vicentino', value: 'Nogarole Vicentino' },
  { label: 'Nōgata', value: 'Nōgata' },
  { label: 'Nōgata Shi', value: 'Nōgata Shi' },
  { label: 'Nogayskiy Rayon', value: 'Nogayskiy Rayon' },
  { label: 'Nogentel', value: 'Nogentel' },
  { label: 'Nogent-L’Artaud', value: 'Nogent-L’Artaud' },
  { label: 'Nogent-Le-Bas', value: 'Nogent-Le-Bas' },
  { label: 'Nogent-Le-Phaye', value: 'Nogent-Le-Phaye' },
  { label: 'Nogent-Le-Roi', value: 'Nogent-Le-Roi' },
  { label: 'Nogent-Le-Rotrou', value: 'Nogent-Le-Rotrou' },
  { label: 'Nogent-Sur-Marne', value: 'Nogent-Sur-Marne' },
  { label: 'Nogent-Sur-Oise', value: 'Nogent-Sur-Oise' },
  { label: 'Nogent-Sur-Seine', value: 'Nogent-Sur-Seine' },
  { label: 'Nogent-Sur-Vernisson', value: 'Nogent-Sur-Vernisson' },
  { label: 'Noginsk', value: 'Noginsk' },
  { label: 'Noginskiy Rayon', value: 'Noginskiy Rayon' },
  { label: 'Nogir', value: 'Nogir' },
  { label: 'Nogliki', value: 'Nogliki' },
  { label: 'Nogoyá', value: 'Nogoyá' },
  { label: 'Nogueira', value: 'Nogueira' },
  { label: 'Nogueira Da Regedoura', value: 'Nogueira Da Regedoura' },
  { label: 'Nogueira De Ramuín', value: 'Nogueira De Ramuín' },
  { label: 'Noguera De Albarracín', value: 'Noguera De Albarracín' },
  { label: 'Nogueras', value: 'Nogueras' },
  { label: 'Nogueruelas', value: 'Nogueruelas' },
  { label: 'Noha', value: 'Noha' },
  { label: 'Nohanent', value: 'Nohanent' },
  { label: 'Nohar', value: 'Nohar' },
  { label: 'Nohfelden', value: 'Nohfelden' },
  { label: 'Nohic', value: 'Nohic' },
  { label: 'Nohiyai Kolkhozobod', value: 'Nohiyai Kolkhozobod' },
  { label: 'Nohiyai Konibodom', value: 'Nohiyai Konibodom' },
  { label: 'Nohiyai Panj', value: 'Nohiyai Panj' },
  { label: 'Nohiyai Shughnon', value: 'Nohiyai Shughnon' },
  { label: 'Nohiyai Vakhsh', value: 'Nohiyai Vakhsh' },
  { label: 'Nohra', value: 'Nohra' },
  { label: 'Noia', value: 'Noia' },
  { label: 'Noicattaro', value: 'Noicattaro' },
  { label: 'Noida', value: 'Noida' },
  { label: 'Noidans-Lès-Vesoul', value: 'Noidans-Lès-Vesoul' },
  { label: 'Nointot', value: 'Nointot' },
  { label: 'Noirétable', value: 'Noirétable' },
  { label: 'Noirmoutier-En-L’Île', value: 'Noirmoutier-En-L’Île' },
  { label: 'Noiseau', value: 'Noiseau' },
  { label: 'Noisiel', value: 'Noisiel' },
  { label: 'Noisy-Le-Grand', value: 'Noisy-Le-Grand' },
  { label: 'Noisy-Le-Roi', value: 'Noisy-Le-Roi' },
  { label: 'Noisy-Le-Sec', value: 'Noisy-Le-Sec' },
  { label: 'Noisy-Sur-École', value: 'Noisy-Sur-École' },
  { label: 'Noizay', value: 'Noizay' },
  { label: 'Noja', value: 'Noja' },
  { label: 'Nojorid', value: 'Nojorid' },
  { label: 'Nokaneng', value: 'Nokaneng' },
  { label: 'Nokesville', value: 'Nokesville' },
  { label: 'Nokha', value: 'Nokha' },
  { label: 'Nokhandan', value: 'Nokhandan' },
  { label: 'Nokia', value: 'Nokia' },
  { label: 'Nokomis', value: 'Nokomis' },
  { label: 'Nokomis', value: 'Nokomis' },
  { label: 'Nola', value: 'Nola' },
  { label: 'Nola', value: 'Nola' },
  { label: 'Nolan County', value: 'Nolan County' },
  { label: 'Nolanville', value: 'Nolanville' },
  { label: 'Nolay', value: 'Nolay' },
  { label: 'Nolay', value: 'Nolay' },
  { label: 'Nolby', value: 'Nolby' },
  { label: 'Nole', value: 'Nole' },
  { label: 'Nole Düima', value: 'Nole Düima' },
  { label: 'Nolensville', value: 'Nolensville' },
  { label: 'Noli', value: 'Noli' },
  { label: 'Noling', value: 'Noling' },
  { label: 'Noling', value: 'Noling' },
  { label: 'Nolinsk', value: 'Nolinsk' },
  { label: 'Nolinskiy Rayon', value: 'Nolinskiy Rayon' },
  { label: 'Nollamara', value: 'Nollamara' },
  { label: 'Nolo', value: 'Nolo' },
  { label: 'Nolvik', value: 'Nolvik' },
  { label: 'Nomaglio', value: 'Nomaglio' },
  { label: 'Nomain', value: 'Nomain' },
  { label: 'Nombela', value: 'Nombela' },
  { label: 'Nombre De Dios', value: 'Nombre De Dios' },
  { label: 'Nombre De Dios', value: 'Nombre De Dios' },
  { label: 'Nombre De Jesús', value: 'Nombre De Jesús' },
  { label: 'Nombrevilla', value: 'Nombrevilla' },
  { label: 'Nome', value: 'Nome' },
  { label: 'Nome', value: 'Nome' },
  { label: 'Nome', value: 'Nome' },
  { label: 'Nome Census Area', value: 'Nome Census Area' },
  { label: 'Nomeny', value: 'Nomeny' },
  { label: 'Nomexy', value: 'Nomexy' },
  { label: 'Nomgon Sum', value: 'Nomgon Sum' },
  { label: 'Nomi', value: 'Nomi' },
  { label: 'Nomi Shi', value: 'Nomi Shi' },
  { label: 'Nommay', value: 'Nommay' },
  { label: 'Nommern', value: 'Nommern' },
  { label: 'Nomós Achaḯas', value: 'Nomós Achaḯas' },
  { label: 'Nomós Aitolías Kai Akarnanías', value: 'Nomós Aitolías Kai Akarnanías' },
  { label: 'Nomós Arkadías', value: 'Nomós Arkadías' },
  { label: 'Nomós Chalkidikís', value: 'Nomós Chalkidikís' },
  { label: 'Nomós Evrytanías', value: 'Nomós Evrytanías' },
  { label: 'Nomós Fokídos', value: 'Nomós Fokídos' },
  { label: 'Nomós Ileías', value: 'Nomós Ileías' },
  { label: 'Nomós Ioannínon', value: 'Nomós Ioannínon' },
  { label: 'Nomós Irakleíou', value: 'Nomós Irakleíou' },
  { label: 'Nomós Kerkýras', value: 'Nomós Kerkýras' },
  { label: 'Nomós Kozánis', value: 'Nomós Kozánis' },
  { label: 'Nomós Kykládon', value: 'Nomós Kykládon' },
  { label: 'Nomós Péllis', value: 'Nomós Péllis' },
  { label: 'Nomós Rethýmnis', value: 'Nomós Rethýmnis' },
  { label: 'Nomós Thessaloníkis', value: 'Nomós Thessaloníkis' },
  { label: 'Nomós Zakýnthou', value: 'Nomós Zakýnthou' },
  { label: 'Nomwin Municipality', value: 'Nomwin Municipality' },
  { label: 'Non Sang', value: 'Non Sang' },
  { label: 'Non Sung', value: 'Non Sung' },
  { label: 'Non Thai', value: 'Non Thai' },
  { label: 'Nonancourt', value: 'Nonancourt' },
  { label: 'Nonantola', value: 'Nonantola' },
  { label: 'Nonaspe', value: 'Nonaspe' },
  { label: 'None', value: 'None' },
  { label: 'Noney', value: 'Noney' },
  { label: 'Nong Bua', value: 'Nong Bua' },
  { label: 'Nong Bua Lamphu', value: 'Nong Bua Lamphu' },
  { label: 'Nong Chik', value: 'Nong Chik' },
  { label: 'Nong Chok', value: 'Nong Chok' },
  { label: 'Nong Khae', value: 'Nong Khae' },
  { label: 'Nong Khaem', value: 'Nong Khaem' },
  { label: 'Nong Khai', value: 'Nong Khai' },
  { label: 'Nong Ki', value: 'Nong Ki' },
  { label: 'Nong Kung Si', value: 'Nong Kung Si' },
  { label: 'Nong Phai', value: 'Nong Phai' },
  { label: 'Nong Wua So', value: 'Nong Wua So' },
  { label: 'Nongpoh', value: 'Nongpoh' },
  { label: 'Nongstoin', value: 'Nongstoin' },
  { label: 'Nonio', value: 'Nonio' },
  { label: 'Nonnenhorn', value: 'Nonnenhorn' },
  { label: 'Nonnweiler', value: 'Nonnweiler' },
  { label: 'Nonoai', value: 'Nonoai' },
  { label: 'Nonoava', value: 'Nonoava' },
  { label: 'Nonoichi', value: 'Nonoichi' },
  { label: 'Nonoichi-Shi', value: 'Nonoichi-Shi' },
  { label: 'Nonouti', value: 'Nonouti' },
  { label: 'Nonsan', value: 'Nonsan' },
  { label: 'Nonsan-Si', value: 'Nonsan-Si' },
  { label: 'Nonsuch', value: 'Nonsuch' },
  { label: 'Nontron', value: 'Nontron' },
  { label: 'Nõo', value: 'Nõo' },
  { label: 'Nõo Vald', value: 'Nõo Vald' },
  { label: 'Nookat', value: 'Nookat' },
  { label: 'Nooksack', value: 'Nooksack' },
  { label: 'Noonan', value: 'Noonan' },
  { label: 'Noorabad', value: 'Noorabad' },
  { label: 'Noordbarge', value: 'Noordbarge' },
  { label: 'Noordbeemster', value: 'Noordbeemster' },
  { label: 'Noordbroek', value: 'Noordbroek' },
  { label: 'Noordburen', value: 'Noordburen' },
  { label: 'Noorddijk', value: 'Noorddijk' },
  { label: 'Noordeinde', value: 'Noordeinde' },
  { label: 'Noordeinde', value: 'Noordeinde' },
  { label: 'Noordeloos', value: 'Noordeloos' },
  { label: 'Noordgeest', value: 'Noordgeest' },
  { label: 'Noordhoek', value: 'Noordhoek' },
  { label: 'Noord-Hofland', value: 'Noord-Hofland' },
  { label: 'Noordhorn', value: 'Noordhorn' },
  { label: 'Noord-Scharwoude', value: 'Noord-Scharwoude' },
  { label: 'Noordwijk-Binnen', value: 'Noordwijk-Binnen' },
  { label: 'Noordwijkerhout', value: 'Noordwijkerhout' },
  { label: 'Noordwolde', value: 'Noordwolde' },
  { label: 'Noormarkku', value: 'Noormarkku' },
  { label: 'Noosa Heads', value: 'Noosa Heads' },
  { label: 'Noosaville', value: 'Noosaville' },
  { label: 'Nopala De Villagran', value: 'Nopala De Villagran' },
  { label: 'Nopalapan', value: 'Nopalapan' },
  { label: 'Nopaltepec', value: 'Nopaltepec' },
  { label: 'Nopaltepec', value: 'Nopaltepec' },
  { label: 'Nopalucan', value: 'Nopalucan' },
  { label: 'Nor Armavir', value: 'Nor Armavir' },
  { label: 'Nor Carangas Province', value: 'Nor Carangas Province' },
  { label: 'Nor Geghi', value: 'Nor Geghi' },
  { label: 'Nor Gyugh', value: 'Nor Gyugh' },
  { label: 'Nor Yerznka', value: 'Nor Yerznka' },
  { label: 'Nora', value: 'Nora' },
  { label: 'Nora Springs', value: 'Nora Springs' },
  { label: 'Norabats’', value: 'Norabats’' },
  { label: 'Noragugume', value: 'Noragugume' },
  { label: 'Norak', value: 'Norak' },
  { label: 'Norakert', value: 'Norakert' },
  { label: 'Norala', value: 'Norala' },
  { label: 'Norala', value: 'Norala' },
  { label: 'Noramarg', value: 'Noramarg' },
  { label: 'Noranda', value: 'Noranda' },
  { label: 'Norashen', value: 'Norashen' },
  { label: 'Norashen', value: 'Norashen' },
  { label: 'Noratus', value: 'Noratus' },
  { label: 'Noraville', value: 'Noraville' },
  { label: 'Norbello', value: 'Norbello' },
  { label: 'Norberg', value: 'Norberg' },
  { label: 'Norbrook', value: 'Norbrook' },
  { label: 'Norcasia', value: 'Norcasia' },
  { label: 'Norcia', value: 'Norcia' },
  { label: 'Norco', value: 'Norco' },
  { label: 'Norco', value: 'Norco' },
  { label: 'Norcross', value: 'Norcross' },
  { label: 'Nord', value: 'Nord' },
  { label: 'Nord-Aurdal', value: 'Nord-Aurdal' },
  { label: 'Nordborg', value: 'Nordborg' },
  { label: 'Nordby', value: 'Nordby' },
  { label: 'Norddal', value: 'Norddal' },
  { label: 'Norddeich', value: 'Norddeich' },
  { label: 'Norddjurs Kommune', value: 'Norddjurs Kommune' },
  { label: 'Nord-Du-Québec', value: 'Nord-Du-Québec' },
  { label: 'Norden', value: 'Norden' },
  { label: 'Nordendorf', value: 'Nordendorf' },
  { label: 'Nordenham', value: 'Nordenham' },
  { label: 'Norderney', value: 'Norderney' },
  { label: 'Norderstedt', value: 'Norderstedt' },
  { label: 'Nordeste', value: 'Nordeste' },
  { label: 'Nordestina', value: 'Nordestina' },
  { label: 'Nordfjordeid', value: 'Nordfjordeid' },
  { label: 'Nord-Fron', value: 'Nord-Fron' },
  { label: 'Nordfyns Kommune', value: 'Nordfyns Kommune' },
  { label: 'Nordhalben', value: 'Nordhalben' },
  { label: 'Nordhastedt', value: 'Nordhastedt' },
  { label: 'Nordhausen', value: 'Nordhausen' },
  { label: 'Nordheim', value: 'Nordheim' },
  { label: 'Nordheim', value: 'Nordheim' },
  { label: 'Nordholz', value: 'Nordholz' },
  { label: 'Nordhorn', value: 'Nordhorn' },
  { label: 'Nordhouse', value: 'Nordhouse' },
  { label: 'Nordiyya', value: 'Nordiyya' },
  { label: 'Nordkapp', value: 'Nordkapp' },
  { label: 'Nordkirchen', value: 'Nordkirchen' },
  { label: 'Nordleda', value: 'Nordleda' },
  { label: 'Nördlingen', value: 'Nördlingen' },
  { label: 'Nordmaling', value: 'Nordmaling' },
  { label: 'Nord-Odal', value: 'Nord-Odal' },
  { label: 'Nordrach', value: 'Nordrach' },
  { label: 'Nordre Land', value: 'Nordre Land' },
  { label: 'Nordreisa', value: 'Nordreisa' },
  { label: 'Nordstemmen', value: 'Nordstemmen' },
  { label: 'Nordstranda', value: 'Nordstranda' },
  { label: 'Nordwalde', value: 'Nordwalde' },
  { label: 'Nore Og Uvdal', value: 'Nore Og Uvdal' },
  { label: 'Noreña', value: 'Noreña' },
  { label: 'Noresund', value: 'Noresund' },
  { label: 'Norfolk', value: 'Norfolk' },
  { label: 'Norfolk', value: 'Norfolk' },
  { label: 'Norfolk', value: 'Norfolk' },
  { label: 'Norfolk', value: 'Norfolk' },
  { label: 'Norfolk', value: 'Norfolk' },
  { label: 'Norfolk County', value: 'Norfolk County' },
  { label: 'Norfolk County', value: 'Norfolk County' },
  { label: 'Norg', value: 'Norg' },
  { label: 'Norheim', value: 'Norheim' },
  { label: 'Norheimsund', value: 'Norheimsund' },
  { label: 'Noria', value: 'Noria' },
  { label: 'Noria De Ángeles', value: 'Noria De Ángeles' },
  { label: 'Noria Del Borrego (Norias)', value: 'Noria Del Borrego (Norias)' },
  { label: 'Noria Nueva', value: 'Noria Nueva' },
  { label: 'Norias Del Paso Hondo', value: 'Norias Del Paso Hondo' },
  { label: 'Norias Del Refugio', value: 'Norias Del Refugio' },
  { label: 'Norilsk', value: 'Norilsk' },
  { label: 'Norland', value: 'Norland' },
  { label: 'Norlane', value: 'Norlane' },
  { label: 'Norlina', value: 'Norlina' },
  { label: 'Norma', value: 'Norma' },
  { label: 'Normal', value: 'Normal' },
  { label: 'Norman', value: 'Norman' },
  { label: 'Norman County', value: 'Norman County' },
  { label: 'Norman Gardens', value: 'Norman Gardens' },
  { label: 'Norman Gardens', value: 'Norman Gardens' },
  { label: 'Norman Park', value: 'Norman Park' },
  { label: 'Norman Wells', value: 'Norman Wells' },
  { label: 'Normandia', value: 'Normandia' },
  { label: 'Normandin', value: 'Normandin' },
  { label: 'Normandy', value: 'Normandy' },
  { label: 'Normandy', value: 'Normandy' },
  { label: 'Normandy Park', value: 'Normandy Park' },
  { label: 'Normanhurst', value: 'Normanhurst' },
  { label: 'Normanton', value: 'Normanton' },
  { label: 'Normanville', value: 'Normanville' },
  { label: 'Normanville', value: 'Normanville' },
  { label: 'Norosí', value: 'Norosí' },
  { label: 'Ñorquinco', value: 'Ñorquinco' },
  { label: 'Norra Åsum', value: 'Norra Åsum' },
  { label: 'Nørre Åby', value: 'Nørre Åby' },
  { label: 'Nørre Alslev', value: 'Nørre Alslev' },
  { label: 'Norrent-Fontes', value: 'Norrent-Fontes' },
  { label: 'Nørresundby', value: 'Nørresundby' },
  { label: 'Norrfjärden', value: 'Norrfjärden' },
  { label: 'Norridge', value: 'Norridge' },
  { label: 'Norridgewock', value: 'Norridgewock' },
  { label: 'Norris', value: 'Norris' },
  { label: 'Norris City', value: 'Norris City' },
  { label: 'Norristown', value: 'Norristown' },
  { label: 'Norrköping', value: 'Norrköping' },
  { label: 'Norroy-Lès-Pont-À-Mousson', value: 'Norroy-Lès-Pont-À-Mousson' },
  { label: 'Norrtälje', value: 'Norrtälje' },
  { label: 'Norsjö', value: 'Norsjö' },
  { label: 'Norsup', value: 'Norsup' },
  { label: 'Nortelândia', value: 'Nortelândia' },
  { label: 'Nörten-Hardenberg', value: 'Nörten-Hardenberg' },
  { label: 'Nörtershausen', value: 'Nörtershausen' },
  { label: 'North Adams', value: 'North Adams' },
  { label: 'North Adelaide', value: 'North Adelaide' },
  { label: 'North Alamo', value: 'North Alamo' },
  { label: 'North Albury', value: 'North Albury' },
  { label: 'North Amherst', value: 'North Amherst' },
  { label: 'North Amityville', value: 'North Amityville' },
  { label: 'North Andover', value: 'North Andover' },
  { label: 'North Andrews Gardens', value: 'North Andrews Gardens' },
  { label: 'North Apollo', value: 'North Apollo' },
  { label: 'North Arlington', value: 'North Arlington' },
  { label: 'North Atlanta', value: 'North Atlanta' },
  { label: 'North Attleborough Center', value: 'North Attleborough Center' },
  { label: 'North Auburn', value: 'North Auburn' },
  { label: 'North Augusta', value: 'North Augusta' },
  { label: 'North Aurora', value: 'North Aurora' },
  { label: 'North Avoca', value: 'North Avoca' },
  { label: 'North Ayrshire', value: 'North Ayrshire' },
  { label: 'North Babylon', value: 'North Babylon' },
  { label: 'North Baddesley', value: 'North Baddesley' },
  { label: 'North Balgowlah', value: 'North Balgowlah' },
  { label: 'North Ballston Spa', value: 'North Ballston Spa' },
  { label: 'North Baltimore', value: 'North Baltimore' },
  { label: 'North Barrington', value: 'North Barrington' },
  { label: 'North Bath', value: 'North Bath' },
  { label: 'North Battleford', value: 'North Battleford' },
  { label: 'North Bay', value: 'North Bay' },
  { label: 'North Bay Shore', value: 'North Bay Shore' },
  { label: 'North Bay Village', value: 'North Bay Village' },
  { label: 'North Beach', value: 'North Beach' },
  { label: 'North Beach', value: 'North Beach' },
  { label: 'North Beach Haven', value: 'North Beach Haven' },
  { label: 'North Bel Air', value: 'North Bel Air' },
  { label: 'North Belle Vernon', value: 'North Belle Vernon' },
  { label: 'North Bellmore', value: 'North Bellmore' },
  { label: 'North Bellport', value: 'North Bellport' },
  { label: 'North Bend', value: 'North Bend' },
  { label: 'North Bend', value: 'North Bend' },
  { label: 'North Bend', value: 'North Bend' },
  { label: 'North Bendigo', value: 'North Bendigo' },
  { label: 'North Bennington', value: 'North Bennington' },
  { label: 'North Bergen', value: 'North Bergen' },
  { label: 'North Berwick', value: 'North Berwick' },
  { label: 'North Berwick', value: 'North Berwick' },
  { label: 'North Bethesda', value: 'North Bethesda' },
  { label: 'North Bibb', value: 'North Bibb' },
  { label: 'North Boambee Valley', value: 'North Boambee Valley' },
  { label: 'North Bondi', value: 'North Bondi' },
  { label: 'North Booval', value: 'North Booval' },
  { label: 'North Boston', value: 'North Boston' },
  { label: 'North Bougainville', value: 'North Bougainville' },
  { label: 'North Braddock', value: 'North Braddock' },
  { label: 'North Branch', value: 'North Branch' },
  { label: 'North Branch', value: 'North Branch' },
  { label: 'North Branford', value: 'North Branford' },
  { label: 'North Brighton', value: 'North Brighton' },
  { label: 'North Brighton', value: 'North Brighton' },
  { label: 'North Brookfield', value: 'North Brookfield' },
  { label: 'North Brooksville', value: 'North Brooksville' },
  { label: 'North Browning', value: 'North Browning' },
  { label: 'North Burnett', value: 'North Burnett' },
  { label: 'North Caldwell', value: 'North Caldwell' },
  { label: 'North Canton', value: 'North Canton' },
  { label: 'North Cape May', value: 'North Cape May' },
  { label: 'North Castle', value: 'North Castle' },
  { label: 'North Catasauqua', value: 'North Catasauqua' },
  { label: 'North Cave', value: 'North Cave' },
  { label: 'North Center', value: 'North Center' },
  { label: 'North Charleroi', value: 'North Charleroi' },
  { label: 'North Charleston', value: 'North Charleston' },
  { label: 'North Chicago', value: 'North Chicago' },
  { label: 'North Chicopee', value: 'North Chicopee' },
  { label: 'North College Hill', value: 'North College Hill' },
  { label: 'North Collingham', value: 'North Collingham' },
  { label: 'North Collins', value: 'North Collins' },
  { label: 'North Conway', value: 'North Conway' },
  { label: 'North Coogee', value: 'North Coogee' },
  { label: 'North Corbin', value: 'North Corbin' },
  { label: 'North Cowichan', value: 'North Cowichan' },
  { label: 'North Creek', value: 'North Creek' },
  { label: 'North Crossett', value: 'North Crossett' },
  { label: 'North Curl Curl', value: 'North Curl Curl' },
  { label: 'North Decatur', value: 'North Decatur' },
  { label: 'North Deland', value: 'North Deland' },
  { label: 'North Delhi', value: 'North Delhi' },
  { label: 'North District', value: 'North District' },
  { label: 'North Druid Hills', value: 'North Druid Hills' },
  { label: 'North Duffield', value: 'North Duffield' },
  { label: 'North Eagle Butte', value: 'North Eagle Butte' },
  { label: 'North East', value: 'North East' },
  { label: 'North East', value: 'North East' },
  { label: 'North East Delhi', value: 'North East Delhi' },
  { label: 'North East Gonja', value: 'North East Gonja' },
  { label: 'North East Lincolnshire', value: 'North East Lincolnshire' },
  { label: 'North Eastham', value: 'North Eastham' },
  { label: 'North Edwards', value: 'North Edwards' },
  { label: 'North El Monte', value: 'North El Monte' },
  { label: 'North Elba', value: 'North Elba' },
  { label: 'North Elmham', value: 'North Elmham' },
  { label: 'North English', value: 'North English' },
  { label: 'North Epping', value: 'North Epping' },
  { label: 'North Fair Oaks', value: 'North Fair Oaks' },
  { label: 'North Falmouth', value: 'North Falmouth' },
  { label: 'North Ferriby', value: 'North Ferriby' },
  { label: 'North Fly', value: 'North Fly' },
  { label: 'North Fond Du Lac', value: 'North Fond Du Lac' },
  { label: 'North Fork', value: 'North Fork' },
  { label: 'North Fork Village', value: 'North Fork Village' },
  { label: 'North Fort Lewis', value: 'North Fort Lewis' },
  { label: 'North Fort Myers', value: 'North Fort Myers' },
  { label: 'North Fremantle', value: 'North Fremantle' },
  { label: 'North Garo Hills', value: 'North Garo Hills' },
  { label: 'North Gates', value: 'North Gates' },
  { label: 'North Geelong', value: 'North Geelong' },
  { label: 'North Goa', value: 'North Goa' },
  { label: 'North Gonja', value: 'North Gonja' },
  { label: 'North Gosford', value: 'North Gosford' },
  { label: 'North Granby', value: 'North Granby' },
  { label: 'North Great River', value: 'North Great River' },
  { label: 'North Grosvenor Dale', value: 'North Grosvenor Dale' },
  { label: 'North Guwahati', value: 'North Guwahati' },
  { label: 'North Haledon', value: 'North Haledon' },
  { label: 'North Hampton', value: 'North Hampton' },
  { label: 'North Hartsville', value: 'North Hartsville' },
  { label: 'North Haven', value: 'North Haven' },
  { label: 'North Haven', value: 'North Haven' },
  { label: 'North Haven', value: 'North Haven' },
  { label: 'North Hero', value: 'North Hero' },
  { label: 'North Highlands', value: 'North Highlands' },
  { label: 'North Hills', value: 'North Hills' },
  { label: 'North Hills', value: 'North Hills' },
  { label: 'North Hobart', value: 'North Hobart' },
  { label: 'North Hollywood', value: 'North Hollywood' },
  { label: 'North Hudson', value: 'North Hudson' },
  { label: 'North Ipswich', value: 'North Ipswich' },
  { label: 'North Judson', value: 'North Judson' },
  { label: 'North Kansas City', value: 'North Kansas City' },
  { label: 'North Kensington', value: 'North Kensington' },
  { label: 'North Key Largo', value: 'North Key Largo' },
  { label: 'North Kingstown', value: 'North Kingstown' },
  { label: 'North Kingsville', value: 'North Kingsville' },
  { label: 'North La Crosse', value: 'North La Crosse' },
  { label: 'North Lake', value: 'North Lake' },
  { label: 'North Lakeport', value: 'North Lakeport' },
  { label: 'North Lakes', value: 'North Lakes' },
  { label: 'North Lakeville', value: 'North Lakeville' },
  { label: 'North Lakhimpur', value: 'North Lakhimpur' },
  { label: 'North Lambton', value: 'North Lambton' },
  { label: 'North Lanarkshire', value: 'North Lanarkshire' },
  { label: 'North Las Vegas', value: 'North Las Vegas' },
  { label: 'North Lauderdale', value: 'North Lauderdale' },
  { label: 'North Laurel', value: 'North Laurel' },
  { label: 'North Lawndale', value: 'North Lawndale' },
  { label: 'North Leigh', value: 'North Leigh' },
  { label: 'North Lewisburg', value: 'North Lewisburg' },
  { label: 'North Liberty', value: 'North Liberty' },
  { label: 'North Liberty', value: 'North Liberty' },
  { label: 'North Lincolnshire', value: 'North Lincolnshire' },
  { label: 'North Lindenhurst', value: 'North Lindenhurst' },
  { label: 'North Little Rock', value: 'North Little Rock' },
  { label: 'North Logan', value: 'North Logan' },
  { label: 'North Luffenham', value: 'North Luffenham' },
  { label: 'North Mackay', value: 'North Mackay' },
  { label: 'North Maclean', value: 'North Maclean' },
  { label: 'North Madison', value: 'North Madison' },
  { label: 'North Madison', value: 'North Madison' },
  { label: 'North Manchester', value: 'North Manchester' },
  { label: 'North Mankato', value: 'North Mankato' },
  { label: 'North Manly', value: 'North Manly' },
  { label: 'North Massapequa', value: 'North Massapequa' },
  { label: 'North Melbourne', value: 'North Melbourne' },
  { label: 'North Merrick', value: 'North Merrick' },
  { label: 'North Miami', value: 'North Miami' },
  { label: 'North Miami Beach', value: 'North Miami Beach' },
  { label: 'North Middletown', value: 'North Middletown' },
  { label: 'North Muskegon', value: 'North Muskegon' },
  { label: 'North Myrtle Beach', value: 'North Myrtle Beach' },
  { label: 'North Narrabeen', value: 'North Narrabeen' },
  { label: 'North New Hyde Park', value: 'North New Hyde Park' },
  { label: 'North Newbald', value: 'North Newbald' },
  { label: 'North Newton', value: 'North Newton' },
  { label: 'North Nowra', value: 'North Nowra' },
  { label: 'North Oaks', value: 'North Oaks' },
  { label: 'North Ogden', value: 'North Ogden' },
  { label: 'North Olmsted', value: 'North Olmsted' },
  { label: 'North Oyster/Yellow Point', value: 'North Oyster/Yellow Point' },
  { label: 'North Palm Beach', value: 'North Palm Beach' },
  { label: 'North Parramatta', value: 'North Parramatta' },
  { label: 'North Patchogue', value: 'North Patchogue' },
  { label: 'North Pekin', value: 'North Pekin' },
  { label: 'North Pembroke', value: 'North Pembroke' },
  { label: 'North Peoria', value: 'North Peoria' },
  { label: 'North Perth', value: 'North Perth' },
  { label: 'North Perth', value: 'North Perth' },
  { label: 'North Petherton', value: 'North Petherton' },
  { label: 'North Plainfield', value: 'North Plainfield' },
  { label: 'North Plains', value: 'North Plains' },
  { label: 'North Platte', value: 'North Platte' },
  { label: 'North Plymouth', value: 'North Plymouth' },
  { label: 'North Plympton', value: 'North Plympton' },
  { label: 'North Pole', value: 'North Pole' },
  { label: 'North Port', value: 'North Port' },
  { label: 'North Portland', value: 'North Portland' },
  { label: 'North Potomac', value: 'North Potomac' },
  { label: 'North Prairie', value: 'North Prairie' },
  { label: 'North Providence', value: 'North Providence' },
  { label: 'North Puyallup', value: 'North Puyallup' },
  { label: 'North Queensferry', value: 'North Queensferry' },
  { label: 'North Randall', value: 'North Randall' },
  { label: 'North Reading', value: 'North Reading' },
  { label: 'North Redington Beach', value: 'North Redington Beach' },
  { label: 'North Richland Hills', value: 'North Richland Hills' },
  { label: 'North Richmond', value: 'North Richmond' },
  { label: 'North Richmond', value: 'North Richmond' },
  { label: 'North Ridgeville', value: 'North Ridgeville' },
  { label: 'North River Shores', value: 'North River Shores' },
  { label: 'North Riverside', value: 'North Riverside' },
  { label: 'North Rock Springs', value: 'North Rock Springs' },
  { label: 'North Rocks', value: 'North Rocks' },
  { label: 'North Royalton', value: 'North Royalton' },
  { label: 'North Ryde', value: 'North Ryde' },
  { label: 'North Saanich', value: 'North Saanich' },
  { label: 'North Saint Paul', value: 'North Saint Paul' },
  { label: 'North Salt Lake', value: 'North Salt Lake' },
  { label: 'North Sarasota', value: 'North Sarasota' },
  { label: 'North Scituate', value: 'North Scituate' },
  { label: 'North Scituate', value: 'North Scituate' },
  { label: 'North Sea', value: 'North Sea' },
  { label: 'North Seekonk', value: 'North Seekonk' },
  { label: 'North Shewa Zone', value: 'North Shewa Zone' },
  { label: 'North Shewa Zone', value: 'North Shewa Zone' },
  { label: 'North Shields', value: 'North Shields' },
  { label: 'North Shore', value: 'North Shore' },
  { label: 'North Shore', value: 'North Shore' },
  { label: 'North Sioux City', value: 'North Sioux City' },
  { label: 'North Slope Borough', value: 'North Slope Borough' },
  { label: 'North Smithfield', value: 'North Smithfield' },
  { label: 'North Somercotes', value: 'North Somercotes' },
  { label: 'North Somerset', value: 'North Somerset' },
  { label: 'North Spearfish', value: 'North Spearfish' },
  { label: 'North Springfield', value: 'North Springfield' },
  { label: 'North St Marys', value: 'North St Marys' },
  { label: 'North Stamford', value: 'North Stamford' },
  { label: 'North Star', value: 'North Star' },
  { label: 'North Strathfield', value: 'North Strathfield' },
  { label: 'North Sunderland', value: 'North Sunderland' },
  { label: 'North Sydney', value: 'North Sydney' },
  { label: 'North Syracuse', value: 'North Syracuse' },
  { label: 'North Tamworth', value: 'North Tamworth' },
  { label: 'North Tawton', value: 'North Tawton' },
  { label: 'North Terre Haute', value: 'North Terre Haute' },
  { label: 'North Thoresby', value: 'North Thoresby' },
  { label: 'North Tonawanda', value: 'North Tonawanda' },
  { label: 'North Toowoomba', value: 'North Toowoomba' },
  { label: 'North Tripura', value: 'North Tripura' },
  { label: 'North Tunica', value: 'North Tunica' },
  { label: 'North Turramurra', value: 'North Turramurra' },
  { label: 'North Tustin', value: 'North Tustin' },
  { label: 'North Vacherie', value: 'North Vacherie' },
  { label: 'North Valley', value: 'North Valley' },
  { label: 'North Valley Stream', value: 'North Valley Stream' },
  { label: 'North Vancouver', value: 'North Vancouver' },
  { label: 'North Vanlaiphai', value: 'North Vanlaiphai' },
  { label: 'North Vernon', value: 'North Vernon' },
  { label: 'North Versailles', value: 'North Versailles' },
  { label: 'North Wahgi', value: 'North Wahgi' },
  { label: 'North Wahroonga', value: 'North Wahroonga' },
  { label: 'North Wales', value: 'North Wales' },
  { label: 'North Walsham', value: 'North Walsham' },
  { label: 'North Wantagh', value: 'North Wantagh' },
  { label: 'North Ward', value: 'North Ward' },
  { label: 'North Warrandyte', value: 'North Warrandyte' },
  { label: 'North Warren', value: 'North Warren' },
  { label: 'North Wazīristān Agency', value: 'North Wazīristān Agency' },
  { label: 'North Webster', value: 'North Webster' },
  { label: 'North Weeki Wachee', value: 'North Weeki Wachee' },
  { label: 'North West Delhi', value: 'North West Delhi' },
  { label: 'North Westport', value: 'North Westport' },
  { label: 'North Wildwood', value: 'North Wildwood' },
  { label: 'North Wilkesboro', value: 'North Wilkesboro' },
  { label: 'North Willoughby', value: 'North Willoughby' },
  { label: 'North Windham', value: 'North Windham' },
  { label: 'North Wollo Zone', value: 'North Wollo Zone' },
  { label: 'North Wollongong', value: 'North Wollongong' },
  { label: 'North Wonthaggi', value: 'North Wonthaggi' },
  { label: 'North Yelm', value: 'North Yelm' },
  { label: 'North York', value: 'North York' },
  { label: 'North York', value: 'North York' },
  { label: 'North Yorkshire', value: 'North Yorkshire' },
  { label: 'North Zanesville', value: 'North Zanesville' },
  { label: 'Northallerton', value: 'Northallerton' },
  { label: 'Northam', value: 'Northam' },
  { label: 'Northam', value: 'Northam' },
  { label: 'Northampton', value: 'Northampton' },
  { label: 'Northampton', value: 'Northampton' },
  { label: 'Northampton', value: 'Northampton' },
  { label: 'Northampton County', value: 'Northampton County' },
  { label: 'Northampton County', value: 'Northampton County' },
  { label: 'Northampton County', value: 'Northampton County' },
  { label: 'Northampton Shire', value: 'Northampton Shire' },
  { label: 'Northamptonshire', value: 'Northamptonshire' },
  { label: 'Northborough', value: 'Northborough' },
  { label: 'Northborough', value: 'Northborough' },
  { label: 'Northbridge', value: 'Northbridge' },
  { label: 'Northbridge', value: 'Northbridge' },
  { label: 'Northbridge', value: 'Northbridge' },
  { label: 'Northbrook', value: 'Northbrook' },
  { label: 'Northbrook', value: 'Northbrook' },
  { label: 'Northcentral', value: 'Northcentral' },
  { label: 'Northchase', value: 'Northchase' },
  { label: 'Northcliff', value: 'Northcliff' },
  { label: 'Northcote', value: 'Northcote' },
  { label: 'Northcrest', value: 'Northcrest' },
  { label: 'Northdale', value: 'Northdale' },
  { label: 'Northeast Ithaca', value: 'Northeast Ithaca' },
  { label: 'Northeim', value: 'Northeim' },
  { label: 'Northern Areas', value: 'Northern Areas' },
  { label: 'Northern Cambria', value: 'Northern Cambria' },
  { label: 'Northern Grampians', value: 'Northern Grampians' },
  { label: 'Northern Midlands', value: 'Northern Midlands' },
  { label: 'Northern Peninsula Area', value: 'Northern Peninsula Area' },
  { label: 'Northfield', value: 'Northfield' },
  { label: 'Northfield', value: 'Northfield' },
  { label: 'Northfield', value: 'Northfield' },
  { label: 'Northfield', value: 'Northfield' },
  { label: 'Northfield', value: 'Northfield' },
  { label: 'Northfield', value: 'Northfield' },
  { label: 'Northfield', value: 'Northfield' },
  { label: 'Northfield', value: 'Northfield' },
  { label: 'Northfield', value: 'Northfield' },
  { label: 'Northgate', value: 'Northgate' },
  { label: 'Northgate', value: 'Northgate' },
  { label: 'Northglenn', value: 'Northglenn' },
  { label: 'Northiam', value: 'Northiam' },
  { label: 'Northlake', value: 'Northlake' },
  { label: 'Northlake', value: 'Northlake' },
  { label: 'Northlake', value: 'Northlake' },
  { label: 'Northlakes', value: 'Northlakes' },
  { label: 'Northleach', value: 'Northleach' },
  { label: 'Northmead', value: 'Northmead' },
  { label: 'Northolt', value: 'Northolt' },
  { label: 'Northop', value: 'Northop' },
  { label: 'Northorpe', value: 'Northorpe' },
  { label: 'Northport', value: 'Northport' },
  { label: 'Northport', value: 'Northport' },
  { label: 'Northport', value: 'Northport' },
  { label: 'Northridge', value: 'Northridge' },
  { label: 'Northridge', value: 'Northridge' },
  { label: 'Northside', value: 'Northside' },
  { label: 'Northumberland', value: 'Northumberland' },
  { label: 'Northumberland', value: 'Northumberland' },
  { label: 'Northumberland', value: 'Northumberland' },
  { label: 'Northumberland', value: 'Northumberland' },
  { label: 'Northumberland County', value: 'Northumberland County' },
  { label: 'Northumberland County', value: 'Northumberland County' },
  { label: 'Northvale', value: 'Northvale' },
  { label: 'Northview', value: 'Northview' },
  { label: 'Northville', value: 'Northville' },
  { label: 'Northville', value: 'Northville' },
  { label: 'Northwest', value: 'Northwest' },
  { label: 'Northwest Arctic Borough', value: 'Northwest Arctic Borough' },
  { label: 'Northwest Harbor', value: 'Northwest Harbor' },
  { label: 'Northwest Harborcreek', value: 'Northwest Harborcreek' },
  { label: 'Northwest Harwich', value: 'Northwest Harwich' },
  { label: 'Northwest Harwinton', value: 'Northwest Harwinton' },
  { label: 'Northwest Ithaca', value: 'Northwest Ithaca' },
  { label: 'Northwich', value: 'Northwich' },
  { label: 'Northwood', value: 'Northwood' },
  { label: 'Northwood', value: 'Northwood' },
  { label: 'Northwood', value: 'Northwood' },
  { label: 'Northwoods', value: 'Northwoods' },
  { label: 'Nortkerque', value: 'Nortkerque' },
  { label: 'Nortmoor', value: 'Nortmoor' },
  { label: 'Norton', value: 'Norton' },
  { label: 'Norton', value: 'Norton' },
  { label: 'Norton', value: 'Norton' },
  { label: 'Norton', value: 'Norton' },
  { label: 'Norton', value: 'Norton' },
  { label: 'Norton', value: 'Norton' },
  { label: 'Norton Canes', value: 'Norton Canes' },
  { label: 'Norton Center', value: 'Norton Center' },
  { label: 'Norton County', value: 'Norton County' },
  { label: 'Norton Shores', value: 'Norton Shores' },
  { label: 'Nortonville', value: 'Nortonville' },
  { label: 'Nortorf', value: 'Nortorf' },
  { label: 'Nortrup', value: 'Nortrup' },
  { label: 'Nort-Sur-Erdre', value: 'Nort-Sur-Erdre' },
  { label: 'Nörvenich', value: 'Nörvenich' },
  { label: 'Norville', value: 'Norville' },
  { label: 'Norwalk', value: 'Norwalk' },
  { label: 'Norwalk', value: 'Norwalk' },
  { label: 'Norwalk', value: 'Norwalk' },
  { label: 'Norwalk', value: 'Norwalk' },
  { label: 'Norway', value: 'Norway' },
  { label: 'Norway', value: 'Norway' },
  { label: 'Norwell', value: 'Norwell' },
  { label: 'Norwich', value: 'Norwich' },
  { label: 'Norwich', value: 'Norwich' },
  { label: 'Norwich', value: 'Norwich' },
  { label: 'Norwich', value: 'Norwich' },
  { label: 'Norwood', value: 'Norwood' },
  { label: 'Norwood', value: 'Norwood' },
  { label: 'Norwood', value: 'Norwood' },
  { label: 'Norwood', value: 'Norwood' },
  { label: 'Norwood', value: 'Norwood' },
  { label: 'Norwood', value: 'Norwood' },
  { label: 'Norwood', value: 'Norwood' },
  { label: 'Norwood', value: 'Norwood' },
  { label: 'Norwood', value: 'Norwood' },
  { label: 'Norwood', value: 'Norwood' },
  { label: 'Norwood (Historical)', value: 'Norwood (Historical)' },
  { label: 'Norwood Payneham St Peters', value: 'Norwood Payneham St Peters' },
  { label: 'Norwood Young America', value: 'Norwood Young America' },
  { label: 'Norzagaray', value: 'Norzagaray' },
  { label: 'Norzagaray', value: 'Norzagaray' },
  { label: 'Nosadello', value: 'Nosadello' },
  { label: 'Nosate', value: 'Nosate' },
  { label: 'Noshin', value: 'Noshin' },
  { label: 'Noshiro', value: 'Noshiro' },
  { label: 'Noshiro Shi', value: 'Noshiro Shi' },
  { label: 'Nosislav', value: 'Nosislav' },
  { label: 'Nosivka', value: 'Nosivka' },
  { label: 'Noşlac', value: 'Noşlac' },
  { label: 'Nosrat Abad', value: 'Nosrat Abad' },
  { label: 'Nosratabad', value: 'Nosratabad' },
  { label: 'Nosratabad-E Behraz', value: 'Nosratabad-E Behraz' },
  { label: 'Nosratabad-E Laklak', value: 'Nosratabad-E Laklak' },
  { label: 'Nossa Senhora Aparecida', value: 'Nossa Senhora Aparecida' },
  { label: 'Nossa Senhora Da Glória', value: 'Nossa Senhora Da Glória' },
  { label: 'Nossa Senhora Das Dores', value: 'Nossa Senhora Das Dores' },
  { label: 'Nossa Senhora Das Graças', value: 'Nossa Senhora Das Graças' },
  { label: 'Nossa Senhora De Fátima', value: 'Nossa Senhora De Fátima' },
  { label: 'Nossa Senhora De Lourdes', value: 'Nossa Senhora De Lourdes' },
  { label: 'Nossa Senhora De Nazaré', value: 'Nossa Senhora De Nazaré' },
  { label: 'Nossa Senhora Do Livramento', value: 'Nossa Senhora Do Livramento' },
  { label: 'Nossa Senhora Do Monte', value: 'Nossa Senhora Do Monte' },
  { label: 'Nossa Senhora Do Socorro', value: 'Nossa Senhora Do Socorro' },
  { label: 'Nossa Senhora Dos Remédios', value: 'Nossa Senhora Dos Remédios' },
  { label: 'Nossebro', value: 'Nossebro' },
  { label: 'Nossen', value: 'Nossen' },
  { label: 'Nostang', value: 'Nostang' },
  { label: 'Nöstlbach', value: 'Nöstlbach' },
  { label: 'Nostorf', value: 'Nostorf' },
  { label: 'Notaresco', value: 'Notaresco' },
  { label: 'Noto', value: 'Noto' },
  { label: 'Notodden', value: 'Notodden' },
  { label: 'Notranje Gorice', value: 'Notranje Gorice' },
  { label: 'Notre Dame', value: 'Notre Dame' },
  { label: 'Notre Dame', value: 'Notre Dame' },
  { label: 'Notre-Dame Du Mont', value: 'Notre-Dame Du Mont' },
  { label: 'Notre-Dame Limite', value: 'Notre-Dame Limite' },
  { label: 'Notre-Dame-De-Bondeville', value: 'Notre-Dame-De-Bondeville' },
  { label: 'Notre-Dame-De-Grâce', value: 'Notre-Dame-De-Grâce' },
  { label: 'Notre-Dame-De-Gravenchon', value: 'Notre-Dame-De-Gravenchon' },
  { label: 'Notre-Dame-De-Lîle-Perrot', value: 'Notre-Dame-De-Lîle-Perrot' },
  { label: 'Notre-Dame-De-Mésage', value: 'Notre-Dame-De-Mésage' },
  { label: 'Notre-Dame-De-Monts', value: 'Notre-Dame-De-Monts' },
  { label: 'Notre-Dame-De-Riez', value: 'Notre-Dame-De-Riez' },
  { label: 'Notre-Dame-De-Sanilhac', value: 'Notre-Dame-De-Sanilhac' },
  { label: 'Notre-Dame-Des-Landes', value: 'Notre-Dame-Des-Landes' },
  { label: 'Notre-Dame-Des-Prairies', value: 'Notre-Dame-Des-Prairies' },
  { label: 'Notre-Dame-Du-Lac', value: 'Notre-Dame-Du-Lac' },
  { label: 'Notre-Dame-Du-Mont-Carmel', value: 'Notre-Dame-Du-Mont-Carmel' },
  { label: 'Nötsch Im Gailtal', value: 'Nötsch Im Gailtal' },
  { label: 'Notsé', value: 'Notsé' },
  { label: 'Nottensdorf', value: 'Nottensdorf' },
  { label: 'Notting Hill', value: 'Notting Hill' },
  { label: 'Nottingham', value: 'Nottingham' },
  { label: 'Nottingham', value: 'Nottingham' },
  { label: 'Nottinghamshire', value: 'Nottinghamshire' },
  { label: 'Notton', value: 'Notton' },
  { label: 'Nottoway County', value: 'Nottoway County' },
  { label: 'Nottuln', value: 'Nottuln' },
  { label: 'Nottwil', value: 'Nottwil' },
  { label: 'Notzingen', value: 'Notzingen' },
  { label: 'Nou', value: 'Nou' },
  { label: 'Nou De Berguedà La', value: 'Nou De Berguedà La' },
  { label: 'Nou De Gaià', value: 'Nou De Gaià' },
  { label: 'Nouaceur', value: 'Nouaceur' },
  { label: 'Nouadhibou', value: 'Nouadhibou' },
  { label: 'Nouaillé-Maupertuis', value: 'Nouaillé-Maupertuis' },
  { label: 'Nouan-Le-Fuzelier', value: 'Nouan-Le-Fuzelier' },
  { label: 'Noukābād', value: 'Noukābād' },
  { label: 'Nouméa', value: 'Nouméa' },
  { label: 'Noun', value: 'Noun' },
  { label: 'Nouna', value: 'Nouna' },
  { label: 'Noupoort', value: 'Noupoort' },
  { label: 'Nourabad', value: 'Nourabad' },
  { label: 'Nousiainen', value: 'Nousiainen' },
  { label: 'Nousty', value: 'Nousty' },
  { label: 'Nouvelle Découverte', value: 'Nouvelle Découverte' },
  { label: 'Nouvelle France', value: 'Nouvelle France' },
  { label: 'Nouvion', value: 'Nouvion' },
  { label: 'Nouvion-Sur-Meuse', value: 'Nouvion-Sur-Meuse' },
  { label: 'Nouvoitou', value: 'Nouvoitou' },
  { label: 'Nouzilly', value: 'Nouzilly' },
  { label: 'Nouzonville', value: 'Nouzonville' },
  { label: 'Nov', value: 'Nov' },
  { label: 'Nova Aliança', value: 'Nova Aliança' },
  { label: 'Nova Aliança Do Ivaí', value: 'Nova Aliança Do Ivaí' },
  { label: 'Nova Alvorada', value: 'Nova Alvorada' },
  { label: 'Nova Alvorada Do Sul', value: 'Nova Alvorada Do Sul' },
  { label: 'Nova América', value: 'Nova América' },
  { label: 'Nova América Da Colina', value: 'Nova América Da Colina' },
  { label: 'Nova Andradina', value: 'Nova Andradina' },
  { label: 'Nova Araçá', value: 'Nova Araçá' },
  { label: 'Nova Aurora', value: 'Nova Aurora' },
  { label: 'Nova Aurora', value: 'Nova Aurora' },
  { label: 'Nová Baňa', value: 'Nová Baňa' },
  { label: 'Nova Bandeirantes', value: 'Nova Bandeirantes' },
  { label: 'Nova Bassano', value: 'Nova Bassano' },
  { label: 'Nova Belém', value: 'Nova Belém' },
  { label: 'Nova Boa Vista', value: 'Nova Boa Vista' },
  { label: 'Nova Borova', value: 'Nova Borova' },
  { label: 'Nova Borova', value: 'Nova Borova' },
  { label: 'Nova Brasilândia', value: 'Nova Brasilândia' },
  { label: 'Nova Brasilândia Doeste', value: 'Nova Brasilândia Doeste' },
  { label: 'Nova Bréscia', value: 'Nova Bréscia' },
  { label: 'Nova Bukovica', value: 'Nova Bukovica' },
  { label: 'Nová Bystřice', value: 'Nová Bystřice' },
  { label: 'Nova Campina', value: 'Nova Campina' },
  { label: 'Nova Canaã', value: 'Nova Canaã' },
  { label: 'Nova Canaã Do Norte', value: 'Nova Canaã Do Norte' },
  { label: 'Nova Canaã Paulista', value: 'Nova Canaã Paulista' },
  { label: 'Nova Candelária', value: 'Nova Candelária' },
  { label: 'Nova Cantu', value: 'Nova Cantu' },
  { label: 'Nova Castilho', value: 'Nova Castilho' },
  { label: 'Nová Cerekev', value: 'Nová Cerekev' },
  { label: 'Nova Chortoriia', value: 'Nova Chortoriia' },
  { label: 'Nova Colinas', value: 'Nova Colinas' },
  { label: 'Nova Crixás', value: 'Nova Crixás' },
  { label: 'Nova Crnja', value: 'Nova Crnja' },
  { label: 'Nova Cruz', value: 'Nova Cruz' },
  { label: 'Nová Dubnica', value: 'Nová Dubnica' },
  { label: 'Nova Era', value: 'Nova Era' },
  { label: 'Nova Erechim', value: 'Nova Erechim' },
  { label: 'Nova Esperança', value: 'Nova Esperança' },
  { label: 'Nova Esperança Do Piriá', value: 'Nova Esperança Do Piriá' },
  { label: 'Nova Esperança Do Sudoeste', value: 'Nova Esperança Do Sudoeste' },
  { label: 'Nova Esperança Do Sul', value: 'Nova Esperança Do Sul' },
  { label: 'Nova Europa', value: 'Nova Europa' },
  { label: 'Nova Fátima', value: 'Nova Fátima' },
  { label: 'Nova Fátima', value: 'Nova Fátima' },
  { label: 'Nova Floresta', value: 'Nova Floresta' },
  { label: 'Nova Friburgo', value: 'Nova Friburgo' },
  { label: 'Nova Glória', value: 'Nova Glória' },
  { label: 'Nova Gorica', value: 'Nova Gorica' },
  { label: 'Nova Gradiška', value: 'Nova Gradiška' },
  { label: 'Nova Granada', value: 'Nova Granada' },
  { label: 'Nova Guarita', value: 'Nova Guarita' },
  { label: 'Nova Guataporanga', value: 'Nova Guataporanga' },
  { label: 'Nova Hartz', value: 'Nova Hartz' },
  { label: 'Nova Ibiá', value: 'Nova Ibiá' },
  { label: 'Nova Iguaçu', value: 'Nova Iguaçu' },
  { label: 'Nova Iguaçu De Goiás', value: 'Nova Iguaçu De Goiás' },
  { label: 'Nova Independência', value: 'Nova Independência' },
  { label: 'Nova Iorque', value: 'Nova Iorque' },
  { label: 'Nova Ipixuna', value: 'Nova Ipixuna' },
  { label: 'Nova Itaberaba', value: 'Nova Itaberaba' },
  { label: 'Nova Itarana', value: 'Nova Itarana' },
  { label: 'Nova Kakhovka', value: 'Nova Kakhovka' },
  { label: 'Nova Lacerda', value: 'Nova Lacerda' },
  { label: 'Nova Laranjeiras', value: 'Nova Laranjeiras' },
  { label: 'Nová Lesná', value: 'Nová Lesná' },
  { label: 'Nova Levante', value: 'Nova Levante' },
  { label: 'Nova Lima', value: 'Nova Lima' },
  { label: 'Nova Londrina', value: 'Nova Londrina' },
  { label: 'Nova Luzitânia', value: 'Nova Luzitânia' },
  { label: 'Nova Mamoré', value: 'Nova Mamoré' },
  { label: 'Nova Marilândia', value: 'Nova Marilândia' },
  { label: 'Nova Maringá', value: 'Nova Maringá' },
  { label: 'Nova Mayachka', value: 'Nova Mayachka' },
  { label: 'Nova Milanese', value: 'Nova Milanese' },
  { label: 'Nova Módica', value: 'Nova Módica' },
  { label: 'Nova Monte Verde', value: 'Nova Monte Verde' },
  { label: 'Nova Mutum', value: 'Nova Mutum' },
  { label: 'Nova Nazaré', value: 'Nova Nazaré' },
  { label: 'Nova Odesa', value: 'Nova Odesa' },
  { label: 'Nova Odessa', value: 'Nova Odessa' },
  { label: 'Nova Olímpia', value: 'Nova Olímpia' },
  { label: 'Nova Olímpia', value: 'Nova Olímpia' },
  { label: 'Nova Olinda', value: 'Nova Olinda' },
  { label: 'Nova Olinda', value: 'Nova Olinda' },
  { label: 'Nova Olinda', value: 'Nova Olinda' },
  { label: 'Nova Olinda Do Maranhão', value: 'Nova Olinda Do Maranhão' },
  { label: 'Nova Olinda Do Norte', value: 'Nova Olinda Do Norte' },
  { label: 'Nova Pádua', value: 'Nova Pádua' },
  { label: 'Nová Paka', value: 'Nová Paka' },
  { label: 'Nova Palma', value: 'Nova Palma' },
  { label: 'Nova Palmeira', value: 'Nova Palmeira' },
  { label: 'Nova Pazova', value: 'Nova Pazova' },
  { label: 'Nova Petrópolis', value: 'Nova Petrópolis' },
  { label: 'Nova Ponente', value: 'Nova Ponente' },
  { label: 'Nova Ponte', value: 'Nova Ponte' },
  { label: 'Nova Porteirinha', value: 'Nova Porteirinha' },
  { label: 'Nova Praha', value: 'Nova Praha' },
  { label: 'Nova Prata', value: 'Nova Prata' },
  { label: 'Nova Prata Do Iguaçu', value: 'Nova Prata Do Iguaçu' },
  { label: 'Nova Pryluka', value: 'Nova Pryluka' },
  { label: 'Nova Ramada', value: 'Nova Ramada' },
  { label: 'Nova Redenção', value: 'Nova Redenção' },
  { label: 'Nova Resende', value: 'Nova Resende' },
  { label: 'Nová Role', value: 'Nová Role' },
  { label: 'Nova Roma', value: 'Nova Roma' },
  { label: 'Nova Roma Do Sul', value: 'Nova Roma Do Sul' },
  { label: 'Nova Rosalândia', value: 'Nova Rosalândia' },
  { label: 'Nova Russas', value: 'Nova Russas' },
  { label: 'Nova Santa Bárbara', value: 'Nova Santa Bárbara' },
  { label: 'Nova Santa Helena', value: 'Nova Santa Helena' },
  { label: 'Nova Santa Rita', value: 'Nova Santa Rita' },
  { label: 'Nova Santa Rita', value: 'Nova Santa Rita' },
  { label: 'Nova Santa Rosa', value: 'Nova Santa Rosa' },
  { label: 'Nova Serrana', value: 'Nova Serrana' },
  { label: 'Nova Sintra', value: 'Nova Sintra' },
  { label: 'Nova Siri', value: 'Nova Siri' },
  { label: 'Nova Siri Scalo', value: 'Nova Siri Scalo' },
  { label: 'Nova Soure', value: 'Nova Soure' },
  { label: 'Nova Tebas', value: 'Nova Tebas' },
  { label: 'Nova Timboteua', value: 'Nova Timboteua' },
  { label: 'Nova Trento', value: 'Nova Trento' },
  { label: 'Nova Ubiratã', value: 'Nova Ubiratã' },
  { label: 'Nova União', value: 'Nova União' },
  { label: 'Nova União', value: 'Nova União' },
  { label: 'Nova Ushytsya', value: 'Nova Ushytsya' },
  { label: 'Nova Vas', value: 'Nova Vas' },
  { label: 'Nová Včelnice', value: 'Nová Včelnice' },
  { label: 'Nova Venécia', value: 'Nova Venécia' },
  { label: 'Nova Veneza', value: 'Nova Veneza' },
  { label: 'Nova Veneza', value: 'Nova Veneza' },
  { label: 'Nova Viçosa', value: 'Nova Viçosa' },
  { label: 'Nova Vodolaha', value: 'Nova Vodolaha' },
  { label: 'Nova Xavantina', value: 'Nova Xavantina' },
  { label: 'Nova Zagora', value: 'Nova Zagora' },
  { label: 'Novaci', value: 'Novaci' },
  { label: 'Novaci', value: 'Novaci' },
  { label: 'Novaci', value: 'Novaci' },
  { label: 'Novafeltria', value: 'Novafeltria' },
  { label: 'Novagli', value: 'Novagli' },
  { label: 'Novais', value: 'Novais' },
  { label: 'Nováky', value: 'Nováky' },
  { label: 'Novalaise', value: 'Novalaise' },
  { label: 'Novaledo', value: 'Novaledo' },
  { label: 'Novales', value: 'Novales' },
  { label: 'Novalesa', value: 'Novalesa' },
  { label: 'Novalja', value: 'Novalja' },
  { label: 'Novallas', value: 'Novallas' },
  { label: 'Novallas', value: 'Novallas' },
  { label: 'Novar Gardens', value: 'Novar Gardens' },
  { label: 'Novara', value: 'Novara' },
  { label: 'Novara', value: 'Novara' },
  { label: 'Novara Di Sicilia', value: 'Novara Di Sicilia' },
  { label: 'Novate Mezzola', value: 'Novate Mezzola' },
  { label: 'Novate Milanese', value: 'Novate Milanese' },
  { label: 'Novato', value: 'Novato' },
  { label: 'Novaya Balakhna', value: 'Novaya Balakhna' },
  { label: 'Novaya Bryan’', value: 'Novaya Bryan’' },
  { label: 'Novaya Chara', value: 'Novaya Chara' },
  { label: 'Novaya Chigla', value: 'Novaya Chigla' },
  { label: 'Novaya Derevnya', value: 'Novaya Derevnya' },
  { label: 'Novaya Huta', value: 'Novaya Huta' },
  { label: 'Novaya Igirma', value: 'Novaya Igirma' },
  { label: 'Novaya Ladoga', value: 'Novaya Ladoga' },
  { label: 'Novaya Lyada', value: 'Novaya Lyada' },
  { label: 'Novaya Lyalya', value: 'Novaya Lyalya' },
  { label: 'Novaya Maka', value: 'Novaya Maka' },
  { label: 'Novaya Malykla', value: 'Novaya Malykla' },
  { label: 'Novaya Mayna', value: 'Novaya Mayna' },
  { label: 'Novaya Tavolzhanka', value: 'Novaya Tavolzhanka' },
  { label: 'Novaya Usman’', value: 'Novaya Usman’' },
  { label: 'Novaya Zaimka', value: 'Novaya Zaimka' },
  { label: 'Novazzano', value: 'Novazzano' },
  { label: 'Nove', value: 'Nove' },
  { label: 'Nove Davydkovo', value: 'Nove Davydkovo' },
  { label: 'Nové Hrady', value: 'Nové Hrady' },
  { label: 'Nové Město Na Moravě', value: 'Nové Město Na Moravě' },
  { label: 'Nové Město Nad Metují', value: 'Nové Město Nad Metují' },
  { label: 'Nové Mesto Nad Váhom', value: 'Nové Mesto Nad Váhom' },
  { label: 'Nové Město Pod Smrkem', value: 'Nové Město Pod Smrkem' },
  { label: 'Nové Sedlo', value: 'Nové Sedlo' },
  { label: 'Nové Strašecí', value: 'Nové Strašecí' },
  { label: 'Nové Syrovice', value: 'Nové Syrovice' },
  { label: 'Nové Veselí', value: 'Nové Veselí' },
  { label: 'Nové Zámky', value: 'Nové Zámky' },
  { label: 'Novéant-Sur-Moselle', value: 'Novéant-Sur-Moselle' },
  { label: 'Novedrate', value: 'Novedrate' },
  { label: 'Novegro-Tregarezzo-San Felice', value: 'Novegro-Tregarezzo-San Felice' },
  { label: 'Novelda', value: 'Novelda' },
  { label: 'Novelé/Novetlè', value: 'Novelé/Novetlè' },
  { label: 'Noveleta', value: 'Noveleta' },
  { label: 'Novellara', value: 'Novellara' },
  { label: 'Novello', value: 'Novello' },
  { label: 'Nove-Misto', value: 'Nove-Misto' },
  { label: 'Novena', value: 'Novena' },
  { label: 'Noventa', value: 'Noventa' },
  { label: 'Noventa Di Piave', value: 'Noventa Di Piave' },
  { label: 'Noventa Vicentina', value: 'Noventa Vicentina' },
  { label: 'Noverasco-Sporting Mirasole', value: 'Noverasco-Sporting Mirasole' },
  { label: 'Noves', value: 'Noves' },
  { label: 'Novés', value: 'Novés' },
  { label: 'Novgorodskiy Rayon', value: 'Novgorodskiy Rayon' },
  { label: 'Novhorodka', value: 'Novhorodka' },
  { label: 'Novhorod-Sivers’Kyy', value: 'Novhorod-Sivers’Kyy' },
  { label: 'Novi', value: 'Novi' },
  { label: 'Novi Banovci', value: 'Novi Banovci' },
  { label: 'Novi Bilokorovychi', value: 'Novi Bilokorovychi' },
  { label: 'Novi Bilokorovychi', value: 'Novi Bilokorovychi' },
  { label: 'Novi Di Modena', value: 'Novi Di Modena' },
  { label: 'Novi Grad', value: 'Novi Grad' },
  { label: 'Novi Itebej', value: 'Novi Itebej' },
  { label: 'Novi Karlovci', value: 'Novi Karlovci' },
  { label: 'Novi Kneževac', value: 'Novi Kneževac' },
  { label: 'Novi Kozarci', value: 'Novi Kozarci' },
  { label: 'Novi Ligure', value: 'Novi Ligure' },
  { label: 'Novi Pazar', value: 'Novi Pazar' },
  { label: 'Novi Petrivtsi', value: 'Novi Petrivtsi' },
  { label: 'Novi Sad', value: 'Novi Sad' },
  { label: 'Novi Sanzhary', value: 'Novi Sanzhary' },
  { label: 'Novi Šeher', value: 'Novi Šeher' },
  { label: 'Novi Slankamen', value: 'Novi Slankamen' },
  { label: 'Novi Travnik', value: 'Novi Travnik' },
  { label: 'Novi Troyany', value: 'Novi Troyany' },
  { label: 'Novi Velia', value: 'Novi Velia' },
  { label: 'Novi Vinodolski', value: 'Novi Vinodolski' },
  { label: 'Novi Vorobyi', value: 'Novi Vorobyi' },
  { label: 'Novi Zagreb', value: 'Novi Zagreb' },
  { label: 'Novichikha', value: 'Novichikha' },
  { label: 'Novichikhinskiy Rayon', value: 'Novichikhinskiy Rayon' },
  { label: 'Noviercas', value: 'Noviercas' },
  { label: 'Noviglio', value: 'Noviglio' },
  { label: 'Novigrad', value: 'Novigrad' },
  { label: 'Novigrad Općina', value: 'Novigrad Općina' },
  { label: 'Novigrad-Cittanova', value: 'Novigrad-Cittanova' },
  { label: 'Novikovo', value: 'Novikovo' },
  { label: 'Novillars', value: 'Novillars' },
  { label: 'Novillas', value: 'Novillas' },
  { label: 'Noville-Les-Bois', value: 'Noville-Les-Bois' },
  { label: 'Nóvita', value: 'Nóvita' },
  { label: 'Novki', value: 'Novki' },
  { label: 'Novo Acordo', value: 'Novo Acordo' },
  { label: 'Novo Airão', value: 'Novo Airão' },
  { label: 'Novo Alegre', value: 'Novo Alegre' },
  { label: 'Novo Aripuanã', value: 'Novo Aripuanã' },
  { label: 'Novo Barreiro', value: 'Novo Barreiro' },
  { label: 'Novo Brasil', value: 'Novo Brasil' },
  { label: 'Novo Cabrais', value: 'Novo Cabrais' },
  { label: 'Novo Čiče', value: 'Novo Čiče' },
  { label: 'Novo Cruzeiro', value: 'Novo Cruzeiro' },
  { label: 'Novo Gama', value: 'Novo Gama' },
  { label: 'Novo Hamburgo', value: 'Novo Hamburgo' },
  { label: 'Novo Horizonte', value: 'Novo Horizonte' },
  { label: 'Novo Horizonte', value: 'Novo Horizonte' },
  { label: 'Novo Horizonte', value: 'Novo Horizonte' },
  { label: 'Novo Horizonte Do Norte', value: 'Novo Horizonte Do Norte' },
  { label: 'Novo Horizonte Do Oeste', value: 'Novo Horizonte Do Oeste' },
  { label: 'Novo Horizonte Do Sul', value: 'Novo Horizonte Do Sul' },
  { label: 'Novo Itacolomi', value: 'Novo Itacolomi' },
  { label: 'Novo Jardim', value: 'Novo Jardim' },
  { label: 'Novo Lino', value: 'Novo Lino' },
  { label: 'Novo Machado', value: 'Novo Machado' },
  { label: 'Novo Mesto', value: 'Novo Mesto' },
  { label: 'Novo Miloševo', value: 'Novo Miloševo' },
  { label: 'Novo Mundo', value: 'Novo Mundo' },
  { label: 'Novo Oriente', value: 'Novo Oriente' },
  { label: 'Novo Oriente De Minas', value: 'Novo Oriente De Minas' },
  { label: 'Novo Oriente Do Piauí', value: 'Novo Oriente Do Piauí' },
  { label: 'Novo Planalto', value: 'Novo Planalto' },
  { label: 'Novo Progresso', value: 'Novo Progresso' },
  { label: 'Novo Repartimento', value: 'Novo Repartimento' },
  { label: 'Novo Santo Antônio', value: 'Novo Santo Antônio' },
  { label: 'Novo Santo Antônio', value: 'Novo Santo Antônio' },
  { label: 'Novo São Joaquim', value: 'Novo São Joaquim' },
  { label: 'Novo Selo', value: 'Novo Selo' },
  { label: 'Novo Selo', value: 'Novo Selo' },
  { label: 'Novo Selo', value: 'Novo Selo' },
  { label: 'Novo Selo Rok', value: 'Novo Selo Rok' },
  { label: 'Novo Tiradentes', value: 'Novo Tiradentes' },
  { label: 'Novo Triunfo', value: 'Novo Triunfo' },
  { label: 'Novo Virje', value: 'Novo Virje' },
  { label: 'Novo Xingu', value: 'Novo Xingu' },
  { label: 'Novoagansk', value: 'Novoagansk' },
  { label: 'Novoaleksandrovsk', value: 'Novoaleksandrovsk' },
  { label: 'Novoalekseyevskaya', value: 'Novoalekseyevskaya' },
  { label: 'Novoaltaysk', value: 'Novoaltaysk' },
  { label: 'Novoandreyevka', value: 'Novoandreyevka' },
  { label: 'Novoanninsky', value: 'Novoanninsky' },
  { label: 'Novoarkhanhel’S’K', value: 'Novoarkhanhel’S’K' },
  { label: 'Novoasbest', value: 'Novoasbest' },
  { label: 'Novoazovsk', value: 'Novoazovsk' },
  { label: 'Novobataysk', value: 'Novobataysk' },
  { label: 'Novobelokatay', value: 'Novobelokatay' },
  { label: 'Novobessergenovka', value: 'Novobessergenovka' },
  { label: 'Novobeysugskaya', value: 'Novobeysugskaya' },
  { label: 'Novobirilyussy', value: 'Novobirilyussy' },
  { label: 'Novobiryusinskiy', value: 'Novobiryusinskiy' },
  { label: 'Novoblagodarnoye', value: 'Novoblagodarnoye' },
  { label: 'Novobod', value: 'Novobod' },
  { label: 'Novobohdanivka', value: 'Novobohdanivka' },
  { label: 'Novobureyskiy', value: 'Novobureyskiy' },
  { label: 'Novocheboksarsk', value: 'Novocheboksarsk' },
  { label: 'Novocheremshansk', value: 'Novocheremshansk' },
  { label: 'Novocherkassk', value: 'Novocherkassk' },
  { label: 'Novochernorechenskiy', value: 'Novochernorechenskiy' },
  { label: 'Novoderevyankovskaya', value: 'Novoderevyankovskaya' },
  { label: 'Novodmitriyevskaya', value: 'Novodmitriyevskaya' },
  { label: 'Novodnistrovs’K', value: 'Novodnistrovs’K' },
  { label: 'Novodolinskiy', value: 'Novodolinskiy' },
  { label: 'Novodonets’Ke', value: 'Novodonets’Ke' },
  { label: 'Novodugino', value: 'Novodugino' },
  { label: 'Novodvinsk', value: 'Novodvinsk' },
  { label: 'Novodzhereliyevskaya', value: 'Novodzhereliyevskaya' },
  { label: 'Novoestoniya', value: 'Novoestoniya' },
  { label: 'Novofedorivka', value: 'Novofedorivka' },
  { label: 'Novofedorovka', value: 'Novofedorovka' },
  { label: 'Novogagatli', value: 'Novogagatli' },
  { label: 'Novogireyevo', value: 'Novogireyevo' },
  { label: 'Novogornyy', value: 'Novogornyy' },
  { label: 'Novogrigoryevka', value: 'Novogrigoryevka' },
  { label: 'Novogrudok', value: 'Novogrudok' },
  { label: 'Novogurovskiy', value: 'Novogurovskiy' },
  { label: 'Novohrad-Volynskyi', value: 'Novohrad-Volynskyi' },
  { label: 'Novohrad-Volynskyi', value: 'Novohrad-Volynskyi' },
  { label: 'Novoishimskiy', value: 'Novoishimskiy' },
  { label: 'Novoivanovka', value: 'Novoivanovka' },
  { label: 'Novoivanovskoye', value: 'Novoivanovskoye' },
  { label: 'Novokayakent', value: 'Novokayakent' },
  { label: 'Novokhopërsk', value: 'Novokhopërsk' },
  { label: 'Novokhoperskiy Rayon', value: 'Novokhoperskiy Rayon' },
  { label: 'Novokhovrino', value: 'Novokhovrino' },
  { label: 'Novokizhinginsk', value: 'Novokizhinginsk' },
  { label: 'Novokorsunskaya', value: 'Novokorsunskaya' },
  { label: 'Novokruchininskiy', value: 'Novokruchininskiy' },
  { label: 'Novokrymskoye', value: 'Novokrymskoye' },
  { label: 'Novokubansk', value: 'Novokubansk' },
  { label: 'Novokubanskiy Rayon', value: 'Novokubanskiy Rayon' },
  { label: 'Novokuybyshevsk', value: 'Novokuybyshevsk' },
  { label: 'Novokuz’Minki', value: 'Novokuz’Minki' },
  { label: 'Novokuznetsk', value: 'Novokuznetsk' },
  { label: 'Novokuznetskiy Rayon', value: 'Novokuznetskiy Rayon' },
  { label: 'Novolabinskaya', value: 'Novolabinskaya' },
  { label: 'Novolakskiy Rayon', value: 'Novolakskiy Rayon' },
  { label: 'Novolakskoye', value: 'Novolakskoye' },
  { label: 'Novoledo', value: 'Novoledo' },
  { label: 'Novoleushkovskaya', value: 'Novoleushkovskaya' },
  { label: 'Novoli', value: 'Novoli' },
  { label: 'Novolugovoye', value: 'Novolugovoye' },
  { label: 'Novolukoml’', value: 'Novolukoml’' },
  { label: 'Novomalorossiyskaya', value: 'Novomalorossiyskaya' },
  { label: 'Novomichurinsk', value: 'Novomichurinsk' },
  { label: 'Novomikhaylovskiy', value: 'Novomikhaylovskiy' },
  { label: 'Novominskaya', value: 'Novominskaya' },
  { label: 'Novomoskovs’K', value: 'Novomoskovs’K' },
  { label: 'Novomoskovsk', value: 'Novomoskovsk' },
  { label: 'Novomoskovsk Raion', value: 'Novomoskovsk Raion' },
  { label: 'Novomykolayivka', value: 'Novomykolayivka' },
  { label: 'Novomykolayivka', value: 'Novomykolayivka' },
  { label: 'Novomyrhorod', value: 'Novomyrhorod' },
  { label: 'Novomyshastovskaya', value: 'Novomyshastovskaya' },
  { label: 'Novonikol’Sk', value: 'Novonikol’Sk' },
  { label: 'Novo-Nikol’Skoye', value: 'Novo-Nikol’Skoye' },
  { label: 'Novonikolayevka', value: 'Novonikolayevka' },
  { label: 'Novonikolayevsky Volgograd Oblast', value: 'Novonikolayevsky Volgograd Oblast' },
  { label: 'Novonikolskoe', value: 'Novonikolskoe' },
  { label: 'Novonukutskiy', value: 'Novonukutskiy' },
  { label: 'Novooleksiyivka', value: 'Novooleksiyivka' },
  { label: 'Novoomskiy', value: 'Novoomskiy' },
  { label: 'Novoorlovsk', value: 'Novoorlovsk' },
  { label: 'Novoorsk', value: 'Novoorsk' },
  { label: 'Novoorskiy Rayon', value: 'Novoorskiy Rayon' },
  { label: 'Novooskol’Skiy Rayon', value: 'Novooskol’Skiy Rayon' },
  { label: 'Novoozerianka', value: 'Novoozerianka' },
  { label: 'Novoozyornoye', value: 'Novoozyornoye' },
  { label: 'Novopavlovka', value: 'Novopavlovka' },
  { label: 'Novopavlovka', value: 'Novopavlovka' },
  { label: 'Novopavlovsk', value: 'Novopavlovsk' },
  { label: 'Novo-Peredelkino', value: 'Novo-Peredelkino' },
  { label: 'Novopesterevo', value: 'Novopesterevo' },
  { label: 'Novopetrovskoye', value: 'Novopetrovskoye' },
  { label: 'Novopistsovo', value: 'Novopistsovo' },
  { label: 'Novoplatnirovskaya', value: 'Novoplatnirovskaya' },
  { label: 'Novopodrezkovo', value: 'Novopodrezkovo' },
  { label: 'Novopokrovka', value: 'Novopokrovka' },
  { label: 'Novopokrovka', value: 'Novopokrovka' },
  { label: 'Novopokrovka', value: 'Novopokrovka' },
  { label: 'Novopokrovka', value: 'Novopokrovka' },
  { label: 'Novopokrovka', value: 'Novopokrovka' },
  { label: 'Novopokrovskaya', value: 'Novopokrovskaya' },
  { label: 'Novopskov', value: 'Novopskov' },
  { label: 'Novoraychikhinsk', value: 'Novoraychikhinsk' },
  { label: 'Novorizonte', value: 'Novorizonte' },
  { label: 'Novorossiysk', value: 'Novorossiysk' },
  { label: 'Novorozhdestvenskaya', value: 'Novorozhdestvenskaya' },
  { label: 'Novorudnyy', value: 'Novorudnyy' },
  { label: 'Novorzhev', value: 'Novorzhev' },
  { label: 'Novorzhevskiy Rayon', value: 'Novorzhevskiy Rayon' },
  { label: 'Novosaratovka', value: 'Novosaratovka' },
  { label: 'Novosedlice', value: 'Novosedlice' },
  { label: 'Novosedly', value: 'Novosedly' },
  { label: 'Novosel’Skoye', value: 'Novosel’Skoye' },
  { label: 'Novosel’Ye', value: 'Novosel’Ye' },
  { label: 'Novoselec', value: 'Novoselec' },
  { label: 'Novoseleznëvo', value: 'Novoseleznëvo' },
  { label: 'Novoselitskoye', value: 'Novoselitskoye' },
  { label: 'Novoselovskoye', value: 'Novoselovskoye' },
  { label: 'Novoselytsya', value: 'Novoselytsya' },
  { label: 'Novoselytsya', value: 'Novoselytsya' },
  { label: 'Novosemeykino', value: 'Novosemeykino' },
  { label: 'Novosergiyevka', value: 'Novosergiyevka' },
  { label: 'Novoshakhtinsk', value: 'Novoshakhtinsk' },
  { label: 'Novoshakhtinskiy', value: 'Novoshakhtinskiy' },
  { label: 'Novoshcherbinovskaya', value: 'Novoshcherbinovskaya' },
  { label: 'Novosheshminsk', value: 'Novosheshminsk' },
  { label: 'Novosheshminskiy Rayon', value: 'Novosheshminskiy Rayon' },
  { label: 'Novosibirsk', value: 'Novosibirsk' },
  { label: 'Novosibirskiy Rayon', value: 'Novosibirskiy Rayon' },
  { label: 'Novosil’', value: 'Novosil’' },
  { label: 'Novosilikatnyy', value: 'Novosilikatnyy' },
  { label: 'Novosin’Kovo', value: 'Novosin’Kovo' },
  { label: 'Novosineglazovskiy', value: 'Novosineglazovskiy' },
  { label: 'Novosmolinskiy', value: 'Novosmolinskiy' },
  { label: 'Novosokol’Nicheskiy Rayon', value: 'Novosokol’Nicheskiy Rayon' },
  { label: 'Novosokol’Niki', value: 'Novosokol’Niki' },
  { label: 'Novospasskoye', value: 'Novospasskoye' },
  { label: 'Novostepnoye', value: 'Novostepnoye' },
  { label: 'Novosyolovka', value: 'Novosyolovka' },
  { label: 'Novosysoyevka', value: 'Novosysoyevka' },
  { label: 'Novo-Talitsy', value: 'Novo-Talitsy' },
  { label: 'Novoterskiy', value: 'Novoterskiy' },
  { label: 'Novotitarovskaya', value: 'Novotitarovskaya' },
  { label: 'Novotor”Yal’Skiy Rayon', value: 'Novotor”Yal’Skiy Rayon' },
  { label: 'Novotroitsk', value: 'Novotroitsk' },
  { label: 'Novotroitskaya', value: 'Novotroitskaya' },
  { label: 'Novotyryshkino', value: 'Novotyryshkino' },
  { label: 'Novoukrainskiy', value: 'Novoukrainskiy' },
  { label: 'Novoukrainskoye', value: 'Novoukrainskoye' },
  { label: 'Novoukrayinka', value: 'Novoukrayinka' },
  { label: 'Novoul’Yanovsk', value: 'Novoul’Yanovsk' },
  { label: 'Novoural’Sk', value: 'Novoural’Sk' },
  { label: 'Novousmanskiy Rayon', value: 'Novousmanskiy Rayon' },
  { label: 'Novoutkinsk', value: 'Novoutkinsk' },
  { label: 'Novouzensk', value: 'Novouzensk' },
  { label: 'Novovarshavka', value: 'Novovarshavka' },
  { label: 'Novovasylivka', value: 'Novovasylivka' },
  { label: 'Novovelichkovskaya', value: 'Novovelichkovskaya' },
  { label: 'Novovladykino', value: 'Novovladykino' },
  { label: 'Novovolyns’K', value: 'Novovolyns’K' },
  { label: 'Novovoronezh', value: 'Novovoronezh' },
  { label: 'Novovorontsovka', value: 'Novovorontsovka' },
  { label: 'Novovyazniki', value: 'Novovyazniki' },
  { label: 'Novoyavorivsk', value: 'Novoyavorivsk' },
  { label: 'Novoye', value: 'Novoye' },
  { label: 'Novoye Devyatkino', value: 'Novoye Devyatkino' },
  { label: 'Novoye Leushino', value: 'Novoye Leushino' },
  { label: 'Novozavedennoye', value: 'Novozavedennoye' },
  { label: 'Novozavidovskiy', value: 'Novozavidovskiy' },
  { label: 'Novozhilovka', value: 'Novozhilovka' },
  { label: 'Novozhivotinnoye', value: 'Novozhivotinnoye' },
  { label: 'Novozybkov', value: 'Novozybkov' },
  { label: 'Novozybkovskiy Rayon', value: 'Novozybkovskiy Rayon' },
  { label: 'Novska', value: 'Novska' },
  { label: 'Nový Bor', value: 'Nový Bor' },
  { label: 'Nový Bydžov', value: 'Nový Bydžov' },
  { label: 'Nový Hrozenkov', value: 'Nový Hrozenkov' },
  { label: 'Nový Jičín', value: 'Nový Jičín' },
  { label: 'Nový Knín', value: 'Nový Knín' },
  { label: 'Nový Malín', value: 'Nový Malín' },
  { label: 'Novy Rogachik', value: 'Novy Rogachik' },
  { label: 'Novy Svet', value: 'Novy Svet' },
  { label: 'Novy Svyerzhan’', value: 'Novy Svyerzhan’' },
  { label: 'Novyi Yarychiv', value: 'Novyi Yarychiv' },
  { label: 'Novytsya', value: 'Novytsya' },
  { label: 'Novyy', value: 'Novyy' },
  { label: 'Novyy Buh', value: 'Novyy Buh' },
  { label: 'Novyy Buyan', value: 'Novyy Buyan' },
  { label: 'Novyy Byt', value: 'Novyy Byt' },
  { label: 'Novyy Chirkey', value: 'Novyy Chirkey' },
  { label: 'Novyy Izborsk', value: 'Novyy Izborsk' },
  { label: 'Novyy Karachay', value: 'Novyy Karachay' },
  { label: 'Novyy Karanlug', value: 'Novyy Karanlug' },
  { label: 'Novyy Khushet', value: 'Novyy Khushet' },
  { label: 'Novyy Kostek', value: 'Novyy Kostek' },
  { label: 'Novyy Nekouz', value: 'Novyy Nekouz' },
  { label: 'Novyy Oskol', value: 'Novyy Oskol' },
  { label: 'Novyy Ropsk', value: 'Novyy Ropsk' },
  { label: 'Novyy Starodub', value: 'Novyy Starodub' },
  { label: 'Novyy Sulak', value: 'Novyy Sulak' },
  { label: 'Novyy Svit', value: 'Novyy Svit' },
  { label: 'Novyy Turtkul’', value: 'Novyy Turtkul’' },
  { label: 'Novyy Uoyan', value: 'Novyy Uoyan' },
  { label: 'Novyy Urengoy', value: 'Novyy Urengoy' },
  { label: 'Novyy Urgal', value: 'Novyy Urgal' },
  { label: 'Novyy Yegorlyk', value: 'Novyy Yegorlyk' },
  { label: 'Novyy Zagan', value: 'Novyy Zagan' },
  { label: 'Novyye Atagi', value: 'Novyye Atagi' },
  { label: 'Novyye Burasy', value: 'Novyye Burasy' },
  { label: 'Novyye Cherëmushki', value: 'Novyye Cherëmushki' },
  { label: 'Novyye Gorki', value: 'Novyye Gorki' },
  { label: 'Novyye Kuz’Minki', value: 'Novyye Kuz’Minki' },
  { label: 'Novyye Lapsary', value: 'Novyye Lapsary' },
  { label: 'Novyye Lyady', value: 'Novyye Lyady' },
  { label: 'Novyye Zori', value: 'Novyye Zori' },
  { label: 'Nowa Dęba', value: 'Nowa Dęba' },
  { label: 'Nowa Góra', value: 'Nowa Góra' },
  { label: 'Nowa Ruda', value: 'Nowa Ruda' },
  { label: 'Nowa Sarzyna', value: 'Nowa Sarzyna' },
  { label: 'Nowa Słupia', value: 'Nowa Słupia' },
  { label: 'Nowa Sól', value: 'Nowa Sól' },
  { label: 'Nowa Wieś', value: 'Nowa Wieś' },
  { label: 'Nowa Wieś Lęborska', value: 'Nowa Wieś Lęborska' },
  { label: 'Nowa Wieś Wielka', value: 'Nowa Wieś Wielka' },
  { label: 'Nowata', value: 'Nowata' },
  { label: 'Nowata County', value: 'Nowata County' },
  { label: 'Nowbaran', value: 'Nowbaran' },
  { label: 'Nowdan', value: 'Nowdan' },
  { label: 'Nowdeh Khanduz', value: 'Nowdeh Khanduz' },
  { label: 'Nowdeshah', value: 'Nowdeshah' },
  { label: 'Nowe', value: 'Nowe' },
  { label: 'Nowe Brzesko', value: 'Nowe Brzesko' },
  { label: 'Nowe Grocholice', value: 'Nowe Grocholice' },
  { label: 'Nowe Lipiny', value: 'Nowe Lipiny' },
  { label: 'Nowe Miasteczko', value: 'Nowe Miasteczko' },
  { label: 'Nowe Miasto', value: 'Nowe Miasto' },
  { label: 'Nowe Miasto Lubawskie', value: 'Nowe Miasto Lubawskie' },
  { label: 'Nowe Miasto Nad Pilicą', value: 'Nowe Miasto Nad Pilicą' },
  { label: 'Nowe Miasto Nad Wartą', value: 'Nowe Miasto Nad Wartą' },
  { label: 'Nowe Ostrowy', value: 'Nowe Ostrowy' },
  { label: 'Nowe Sioło', value: 'Nowe Sioło' },
  { label: 'Nowe Skalmierzyce', value: 'Nowe Skalmierzyce' },
  { label: 'Nowe Warpno', value: 'Nowe Warpno' },
  { label: 'Nowjein', value: 'Nowjein' },
  { label: 'Nowkandeh', value: 'Nowkandeh' },
  { label: 'Nowodwór', value: 'Nowodwór' },
  { label: 'Nowogard', value: 'Nowogard' },
  { label: 'Nowogród', value: 'Nowogród' },
  { label: 'Nowogród Bobrzański', value: 'Nowogród Bobrzański' },
  { label: 'Nowogródek Pomorski', value: 'Nowogródek Pomorski' },
  { label: 'Nowogrodziec', value: 'Nowogrodziec' },
  { label: 'Nowon-Gu', value: 'Nowon-Gu' },
  { label: 'Nowosielce', value: 'Nowosielce' },
  { label: 'Nowosielce-Gniewosz', value: 'Nowosielce-Gniewosz' },
  { label: 'Nowra', value: 'Nowra' },
  { label: 'Nowra Hill', value: 'Nowra Hill' },
  { label: 'Nowrangapur', value: 'Nowrangapur' },
  { label: 'Nowshahr', value: 'Nowshahr' },
  { label: 'Nowshera', value: 'Nowshera' },
  { label: 'Nowshera Cantonment', value: 'Nowshera Cantonment' },
  { label: 'Nowsud', value: 'Nowsud' },
  { label: 'Nowthen', value: 'Nowthen' },
  { label: 'Nowy Duninów', value: 'Nowy Duninów' },
  { label: 'Nowy Dwór Gdański', value: 'Nowy Dwór Gdański' },
  { label: 'Nowy Dwór Mazowiecki', value: 'Nowy Dwór Mazowiecki' },
  { label: 'Nowy Korczyn', value: 'Nowy Korczyn' },
  { label: 'Nowy Sącz', value: 'Nowy Sącz' },
  { label: 'Nowy Staw', value: 'Nowy Staw' },
  { label: 'Nowy Targ', value: 'Nowy Targ' },
  { label: 'Nowy Tomyśl', value: 'Nowy Tomyśl' },
  { label: 'Nowy Wiśnicz', value: 'Nowy Wiśnicz' },
  { label: 'Nowy Żmigród', value: 'Nowy Żmigród' },
  { label: 'Noxubee County', value: 'Noxubee County' },
  { label: 'Noyabrsk', value: 'Noyabrsk' },
  { label: 'Noyack', value: 'Noyack' },
  { label: 'Noyakert', value: 'Noyakert' },
  { label: 'Noyal-Muzillac', value: 'Noyal-Muzillac' },
  { label: 'Noyal-Pontivy', value: 'Noyal-Pontivy' },
  { label: 'Noyal-Sur-Vilaine', value: 'Noyal-Sur-Vilaine' },
  { label: 'Noyant', value: 'Noyant' },
  { label: 'Noyant-La-Gravoyère', value: 'Noyant-La-Gravoyère' },
  { label: 'Noyarey', value: 'Noyarey' },
  { label: 'Noyelles-Godault', value: 'Noyelles-Godault' },
  { label: 'Noyelles-Lès-Vermelles', value: 'Noyelles-Lès-Vermelles' },
  { label: 'Noyelles-Sous-Lens', value: 'Noyelles-Sous-Lens' },
  { label: 'Noyemberyan', value: 'Noyemberyan' },
  { label: 'Noyen-Sur-Sarthe', value: 'Noyen-Sur-Sarthe' },
  { label: 'Noyers-Sur-Cher', value: 'Noyers-Sur-Cher' },
  { label: 'Noyon', value: 'Noyon' },
  { label: 'Nozawaonsen', value: 'Nozawaonsen' },
  { label: 'Nozay', value: 'Nozay' },
  { label: 'Nozay', value: 'Nozay' },
  { label: 'Nozdrzec', value: 'Nozdrzec' },
  { label: 'Nozhay-Yurt', value: 'Nozhay-Yurt' },
  { label: 'Nozhay-Yurtovskiy Rayon', value: 'Nozhay-Yurtovskiy Rayon' },
  { label: 'Nsang', value: 'Nsang' },
  { label: 'Nsanje', value: 'Nsanje' },
  { label: 'Nsanje District', value: 'Nsanje District' },
  { label: 'Nsawam', value: 'Nsawam' },
  { label: 'Nshamba', value: 'Nshamba' },
  { label: 'Nshavan', value: 'Nshavan' },
  { label: 'Nsok', value: 'Nsok' },
  { label: 'Nsoko', value: 'Nsoko' },
  { label: 'Nsukka', value: 'Nsukka' },
  { label: 'Nsunga', value: 'Nsunga' },
  { label: 'Ntcheu', value: 'Ntcheu' },
  { label: 'Ntcheu District', value: 'Ntcheu District' },
  { label: 'Ntchisi', value: 'Ntchisi' },
  { label: 'Ntchisi District', value: 'Ntchisi District' },
  { label: 'Ntondozi', value: 'Ntondozi' },
  { label: 'Ntossoni', value: 'Ntossoni' },
  { label: 'Ntoum', value: 'Ntoum' },
  { label: 'Ntsaouéni', value: 'Ntsaouéni' },
  { label: 'Ntsoudjini', value: 'Ntsoudjini' },
  { label: 'Ntui', value: 'Ntui' },
  { label: 'Ntungamo', value: 'Ntungamo' },
  { label: 'Nuaillé', value: 'Nuaillé' },
  { label: 'Nuapada', value: 'Nuapada' },
  { label: 'Nuario', value: 'Nuario' },
  { label: 'Nübbel', value: 'Nübbel' },
  { label: 'Nubl', value: 'Nubl' },
  { label: 'Nucet', value: 'Nucet' },
  { label: 'Nucet', value: 'Nucet' },
  { label: 'Nucetto', value: 'Nucetto' },
  { label: 'Nuci', value: 'Nuci' },
  { label: 'Nucia La', value: 'Nucia La' },
  { label: 'Nuckolls County', value: 'Nuckolls County' },
  { label: 'Nucleo Industriale Di Bazzano', value: 'Nucleo Industriale Di Bazzano' },
  { label: 'Nucşoara', value: 'Nucşoara' },
  { label: 'Nudersdorf', value: 'Nudersdorf' },
  { label: 'Nudgee', value: 'Nudgee' },
  { label: 'Nüdlingen', value: 'Nüdlingen' },
  { label: 'Nudol’', value: 'Nudol’' },
  { label: 'Nuea Khlong', value: 'Nuea Khlong' },
  { label: 'Nueces County', value: 'Nueces County' },
  { label: 'Nueil-Les-Aubiers', value: 'Nueil-Les-Aubiers' },
  { label: 'Nueil-Sur-Layon', value: 'Nueil-Sur-Layon' },
  { label: 'Nuenen', value: 'Nuenen' },
  { label: 'Nueno', value: 'Nueno' },
  { label: 'Nuestra Señora Del Rosario De Caa Catí', value: 'Nuestra Señora Del Rosario De Caa Catí' },
  { label: 'Nuestro Amo', value: 'Nuestro Amo' },
  { label: 'Nueva América', value: 'Nueva América' },
  { label: 'Nueva Ameyalco', value: 'Nueva Ameyalco' },
  { label: 'Nueva Apolonia', value: 'Nueva Apolonia' },
  { label: 'Nueva Arcadia', value: 'Nueva Arcadia' },
  { label: 'Nueva Arenosa', value: 'Nueva Arenosa' },
  { label: 'Nueva Armenia', value: 'Nueva Armenia' },
  { label: 'Nueva Armenia', value: 'Nueva Armenia' },
  { label: 'Nueva Carteya', value: 'Nueva Carteya' },
  { label: 'Nueva Ciudad Guerrero', value: 'Nueva Ciudad Guerrero' },
  { label: 'Nueva Colombia', value: 'Nueva Colombia' },
  { label: 'Nueva Concepción', value: 'Nueva Concepción' },
  { label: 'Nueva Concepción', value: 'Nueva Concepción' },
  { label: 'Nueva División Del Bayo (Guatemala)', value: 'Nueva División Del Bayo (Guatemala)' },
  { label: 'Nueva Era', value: 'Nueva Era' },
  { label: 'Nueva Era', value: 'Nueva Era' },
  { label: 'Nueva Esparta', value: 'Nueva Esparta' },
  { label: 'Nueva Esperanza', value: 'Nueva Esperanza' },
  { label: 'Nueva Esperanza', value: 'Nueva Esperanza' },
  { label: 'Nueva Esperanza', value: 'Nueva Esperanza' },
  { label: 'Nueva Esperanza', value: 'Nueva Esperanza' },
  { label: 'Nueva Frontera', value: 'Nueva Frontera' },
  { label: 'Nueva Frontera', value: 'Nueva Frontera' },
  { label: 'Nueva Fuerza', value: 'Nueva Fuerza' },
  { label: 'Nueva Gerona', value: 'Nueva Gerona' },
  { label: 'Nueva Gorgona', value: 'Nueva Gorgona' },
  { label: 'Nueva Granada', value: 'Nueva Granada' },
  { label: 'Nueva Guadalupe', value: 'Nueva Guadalupe' },
  { label: 'Nueva Guinea', value: 'Nueva Guinea' },
  { label: 'Nueva Helvecia', value: 'Nueva Helvecia' },
  { label: 'Nueva Imperial', value: 'Nueva Imperial' },
  { label: 'Nueva Independencia', value: 'Nueva Independencia' },
  { label: 'Nueva Italia', value: 'Nueva Italia' },
  { label: 'Nueva Italia De Ruiz', value: 'Nueva Italia De Ruiz' },
  { label: 'Nueva Jalapa', value: 'Nueva Jalapa' },
  { label: 'Nueva Libertad', value: 'Nueva Libertad' },
  { label: 'Nueva Libertad (El Colorado)', value: 'Nueva Libertad (El Colorado)' },
  { label: 'Nueva Loja', value: 'Nueva Loja' },
  { label: 'Nueva Morelia', value: 'Nueva Morelia' },
  { label: 'Nueva Ocotepeque', value: 'Nueva Ocotepeque' },
  { label: 'Nueva Palestina', value: 'Nueva Palestina' },
  { label: 'Nueva Palmira', value: 'Nueva Palmira' },
  { label: 'Nueva Primavera', value: 'Nueva Primavera' },
  { label: 'Nueva Rosita', value: 'Nueva Rosita' },
  { label: 'Nueva Santa Rosa', value: 'Nueva Santa Rosa' },
  { label: 'Nueva Santa Rosa', value: 'Nueva Santa Rosa' },
  { label: 'Nueva Sesecapa', value: 'Nueva Sesecapa' },
  { label: 'Nueva Tenochtitlán (Rizo De Oro)', value: 'Nueva Tenochtitlán (Rizo De Oro)' },
  { label: 'Nueva Valencia', value: 'Nueva Valencia' },
  { label: 'Nueva Vida Sur', value: 'Nueva Vida Sur' },
  { label: 'Nueva Villa De Las Torres', value: 'Nueva Villa De Las Torres' },
  { label: 'Nueva Villa De Padilla', value: 'Nueva Villa De Padilla' },
  { label: 'Nuévalos', value: 'Nuévalos' },
  { label: 'Nueve De Julio', value: 'Nueve De Julio' },
  { label: 'Nueve De Julio', value: 'Nueve De Julio' },
  { label: 'Nuevitas', value: 'Nuevitas' },
  { label: 'Nuevo', value: 'Nuevo' },
  { label: 'Nuevo Amatenango', value: 'Nuevo Amatenango' },
  { label: 'Nuevo Arraiján', value: 'Nuevo Arraiján' },
  { label: 'Nuevo Balsas', value: 'Nuevo Balsas' },
  { label: 'Nuevo Baztán', value: 'Nuevo Baztán' },
  { label: 'Nuevo Belén', value: 'Nuevo Belén' },
  { label: 'Nuevo Berlín', value: 'Nuevo Berlín' },
  { label: 'Nuevo Carmen Tonapac', value: 'Nuevo Carmen Tonapac' },
  { label: 'Nuevo Casas Grandes', value: 'Nuevo Casas Grandes' },
  { label: 'Nuevo Celilac', value: 'Nuevo Celilac' },
  { label: 'Nuevo Centro De Población Agrícola El Chacón', value: 'Nuevo Centro De Población Agrícola El Chacón' },
  { label: 'Nuevo Cereso Regional De Altamira', value: 'Nuevo Cereso Regional De Altamira' },
  { label: 'Nuevo Chagres', value: 'Nuevo Chagres' },
  { label: 'Nuevo Chamelecón', value: 'Nuevo Chamelecón' },
  { label: 'Nuevo Colón', value: 'Nuevo Colón' },
  { label: 'Nuevo Crucitas', value: 'Nuevo Crucitas' },
  { label: 'Nuevo Cuscatlán', value: 'Nuevo Cuscatlán' },
  { label: 'Nuevo Emperador', value: 'Nuevo Emperador' },
  { label: 'Nuevo Francisco León', value: 'Nuevo Francisco León' },
  { label: 'Nuevo Guerrero', value: 'Nuevo Guerrero' },
  { label: 'Nuevo Ideal', value: 'Nuevo Ideal' },
  { label: 'Nuevo Imperial', value: 'Nuevo Imperial' },
  { label: 'Nuevo Ixcatlán', value: 'Nuevo Ixcatlán' },
  { label: 'Nuevo Juan Del Grijalva', value: 'Nuevo Juan Del Grijalva' },
  { label: 'Nuevo Laredo', value: 'Nuevo Laredo' },
  { label: 'Nuevo León', value: 'Nuevo León' },
  { label: 'Nuevo León', value: 'Nuevo León' },
  { label: 'Nuevo Limar', value: 'Nuevo Limar' },
  { label: 'Nuevo México', value: 'Nuevo México' },
  { label: 'Nuevo México', value: 'Nuevo México' },
  { label: 'Nuevo México Jalisco', value: 'Nuevo México Jalisco' },
  { label: 'Nuevo Michoacán (Estación Riíto)', value: 'Nuevo Michoacán (Estación Riíto)' },
  { label: 'Nuevo Milenio Valdivia', value: 'Nuevo Milenio Valdivia' },
  { label: 'Nuevo Morelos', value: 'Nuevo Morelos' },
  { label: 'Nuevo Naranjo', value: 'Nuevo Naranjo' },
  { label: 'Nuevo Necaxa', value: 'Nuevo Necaxa' },
  { label: 'Nuevo Nicapa', value: 'Nuevo Nicapa' },
  { label: 'Nuevo Pacayal', value: 'Nuevo Pacayal' },
  { label: 'Nuevo París', value: 'Nuevo París' },
  { label: 'Nuevo Paso Nazareno (Chichicazapa)', value: 'Nuevo Paso Nazareno (Chichicazapa)' },
  { label: 'Nuevo Progreso', value: 'Nuevo Progreso' },
  { label: 'Nuevo Progreso', value: 'Nuevo Progreso' },
  { label: 'Nuevo Progreso', value: 'Nuevo Progreso' },
  { label: 'Nuevo Progreso', value: 'Nuevo Progreso' },
  { label: 'Nuevo Pumpuapa (Cereso)', value: 'Nuevo Pumpuapa (Cereso)' },
  { label: 'Nuevo Refugio De Afuera (Nuevo Refugio)', value: 'Nuevo Refugio De Afuera (Nuevo Refugio)' },
  { label: 'Nuevo San Carlitos', value: 'Nuevo San Carlitos' },
  { label: 'Nuevo San Carlos', value: 'Nuevo San Carlos' },
  { label: 'Nuevo San José Independencia', value: 'Nuevo San José Independencia' },
  { label: 'Nuevo San Juan', value: 'Nuevo San Juan' },
  { label: 'Nuevo San Juan Chamula', value: 'Nuevo San Juan Chamula' },
  { label: 'Nuevo San Juan Parangaricutiro', value: 'Nuevo San Juan Parangaricutiro' },
  { label: 'Nuevo San Martín', value: 'Nuevo San Martín' },
  { label: 'Nuevo San Miguel', value: 'Nuevo San Miguel' },
  { label: 'Nuevo Sitalá', value: 'Nuevo Sitalá' },
  { label: 'Nuevo Tampaón', value: 'Nuevo Tampaón' },
  { label: 'Nuevo Tantoán', value: 'Nuevo Tantoán' },
  { label: 'Nuevo Torno Largo', value: 'Nuevo Torno Largo' },
  { label: 'Nuevo Urecho', value: 'Nuevo Urecho' },
  { label: 'Nuevo Vallarta', value: 'Nuevo Vallarta' },
  { label: 'Nuevo Vicente Guerrero', value: 'Nuevo Vicente Guerrero' },
  { label: 'Nuevo Vicente Guerrero (El Chichonal)', value: 'Nuevo Vicente Guerrero (El Chichonal)' },
  { label: 'Nuevo Vigía', value: 'Nuevo Vigía' },
  { label: 'Nuevo Volcán Chichonal', value: 'Nuevo Volcán Chichonal' },
  { label: 'Nuevo Xcán', value: 'Nuevo Xcán' },
  { label: 'Nuevo Xochimilco', value: 'Nuevo Xochimilco' },
  { label: 'Nuevo Zirosto', value: 'Nuevo Zirosto' },
  { label: 'Nuez De Ebro', value: 'Nuez De Ebro' },
  { label: 'Nufăru', value: 'Nufăru' },
  { label: 'Nufringen', value: 'Nufringen' },
  { label: 'Nugas', value: 'Nugas' },
  { label: 'Nughayshiyah', value: 'Nughayshiyah' },
  { label: 'Nughedu San Nicolò', value: 'Nughedu San Nicolò' },
  { label: 'Nughedu Santa Vittoria', value: 'Nughedu Santa Vittoria' },
  { label: 'Nuh', value: 'Nuh' },
  { label: 'Nuijamaa', value: 'Nuijamaa' },
  { label: 'Nuillé-Sur-Vicoin', value: 'Nuillé-Sur-Vicoin' },
  { label: 'Nuing', value: 'Nuing' },
  { label: 'Nuing', value: 'Nuing' },
  { label: 'Nuing', value: 'Nuing' },
  { label: 'Nuits-Saint-Georges', value: 'Nuits-Saint-Georges' },
  { label: 'Nujayl', value: 'Nujayl' },
  { label: 'Nujiang Lisuzu Zizhizhou', value: 'Nujiang Lisuzu Zizhizhou' },
  { label: 'Nuku', value: 'Nuku' },
  { label: 'Nuku‘Alofa', value: 'Nuku‘Alofa' },
  { label: 'Nukuoro Municipality', value: 'Nukuoro Municipality' },
  { label: 'Nukus', value: 'Nukus' },
  { label: 'Nule', value: 'Nule' },
  { label: 'Nules', value: 'Nules' },
  { label: 'Nullagine', value: 'Nullagine' },
  { label: 'Nulles', value: 'Nulles' },
  { label: 'Nulsen', value: 'Nulsen' },
  { label: 'Nulti', value: 'Nulti' },
  { label: 'Nulvi', value: 'Nulvi' },
  { label: 'Numaligarh', value: 'Numaligarh' },
  { label: 'Numan', value: 'Numan' },
  { label: 'Numana', value: 'Numana' },
  { label: 'Numancia', value: 'Numancia' },
  { label: 'Numancia De La Sagra', value: 'Numancia De La Sagra' },
  { label: 'Numansdorp', value: 'Numansdorp' },
  { label: 'Numão', value: 'Numão' },
  { label: 'Numarán', value: 'Numarán' },
  { label: 'Numata', value: 'Numata' },
  { label: 'Numata Shi', value: 'Numata Shi' },
  { label: 'Numazu-Shi', value: 'Numazu-Shi' },
  { label: 'Nümbrecht', value: 'Nümbrecht' },
  { label: 'Numurkah', value: 'Numurkah' },
  { label: 'Nunawading', value: 'Nunawading' },
  { label: 'Nunchía', value: 'Nunchía' },
  { label: 'Nünchritz', value: 'Nünchritz' },
  { label: 'Nunda', value: 'Nunda' },
  { label: 'Nundah', value: 'Nundah' },
  { label: 'Nuneaton', value: 'Nuneaton' },
  { label: 'Nungarin', value: 'Nungarin' },
  { label: 'Nungua', value: 'Nungua' },
  { label: 'Nunguan', value: 'Nunguan' },
  { label: 'Nunguan', value: 'Nunguan' },
  { label: 'Nungwi', value: 'Nungwi' },
  { label: 'Nunkiní', value: 'Nunkiní' },
  { label: 'Nunningen', value: 'Nunningen' },
  { label: 'Nuño Gómez', value: 'Nuño Gómez' },
  { label: 'Ñuñoa', value: 'Ñuñoa' },
  { label: 'Ñuñoa', value: 'Ñuñoa' },
  { label: 'Nuñomoral', value: 'Nuñomoral' },
  { label: 'Nunspeet', value: 'Nunspeet' },
  { label: 'Nuntași', value: 'Nuntași' },
  { label: 'Nunziata', value: 'Nunziata' },
  { label: 'Nuoro', value: 'Nuoro' },
  { label: 'Nuova Conza Della Campania', value: 'Nuova Conza Della Campania' },
  { label: 'Nuova Gibellina', value: 'Nuova Gibellina' },
  { label: 'Nuovo Centro Urbano Poggioreale', value: 'Nuovo Centro Urbano Poggioreale' },
  { label: 'Nuporanga', value: 'Nuporanga' },
  { label: 'Nuquí', value: 'Nuquí' },
  { label: 'Nur', value: 'Nur' },
  { label: 'Nur', value: 'Nur' },
  { label: 'Nurabad', value: 'Nurabad' },
  { label: 'Nurabad', value: 'Nurabad' },
  { label: 'Nurabad-E Simineh', value: 'Nurabad-E Simineh' },
  { label: 'Nurachi', value: 'Nurachi' },
  { label: 'Nuradilovo', value: 'Nuradilovo' },
  { label: 'Nuragus', value: 'Nuragus' },
  { label: 'Nurallao', value: 'Nurallao' },
  { label: 'Nuraminis', value: 'Nuraminis' },
  { label: 'Nuraxinieddu', value: 'Nuraxinieddu' },
  { label: 'Nurbahar', value: 'Nurbahar' },
  { label: 'Nurdağı', value: 'Nurdağı' },
  { label: 'Nureci', value: 'Nureci' },
  { label: 'Nürensdorf', value: 'Nürensdorf' },
  { label: 'Nurhak', value: 'Nurhak' },
  { label: 'Nurillo', value: 'Nurillo' },
  { label: 'Nurimanovskiy Rayon', value: 'Nurimanovskiy Rayon' },
  { label: 'Nurío', value: 'Nurío' },
  { label: 'Nuriootpa', value: 'Nuriootpa' },
  { label: 'Nurlat', value: 'Nurlat' },
  { label: 'Nurlatskiy Rayon', value: 'Nurlatskiy Rayon' },
  { label: 'Nurma', value: 'Nurma' },
  { label: 'Nurmahal', value: 'Nurmahal' },
  { label: 'Nurmes', value: 'Nurmes' },
  { label: 'Nurmi', value: 'Nurmi' },
  { label: 'Nurmijärvi', value: 'Nurmijärvi' },
  { label: 'Nurmo', value: 'Nurmo' },
  { label: 'Nürnberg', value: 'Nürnberg' },
  { label: 'Nurota', value: 'Nurota' },
  { label: 'Nurpur', value: 'Nurpur' },
  { label: 'Nurpur Kalan', value: 'Nurpur Kalan' },
  { label: 'Nurri', value: 'Nurri' },
  { label: 'Nur-Sultan', value: 'Nur-Sultan' },
  { label: 'Nürtingen', value: 'Nürtingen' },
  { label: 'Nurun', value: 'Nurun' },
  { label: 'Nurzec-Stacja', value: 'Nurzec-Stacja' },
  { label: 'Nus', value: 'Nus' },
  { label: 'Nusa Dua', value: 'Nusa Dua' },
  { label: 'Nusaybin', value: 'Nusaybin' },
  { label: 'Nusco', value: 'Nusco' },
  { label: 'Nuşeni', value: 'Nuşeni' },
  { label: 'Nuşfalău', value: 'Nuşfalău' },
  { label: 'Nushabad', value: 'Nushabad' },
  { label: 'Nushki', value: 'Nushki' },
  { label: 'Nußdorf', value: 'Nußdorf' },
  { label: 'Nußdorf Am Haunsberg', value: 'Nußdorf Am Haunsberg' },
  { label: 'Nußdorf Am Inn', value: 'Nußdorf Am Inn' },
  { label: 'Nusse', value: 'Nusse' },
  { label: 'Nußloch', value: 'Nußloch' },
  { label: 'Nuštar', value: 'Nuštar' },
  { label: 'Nuth', value: 'Nuth' },
  { label: 'Nutley', value: 'Nutley' },
  { label: 'Nutter Fort', value: 'Nutter Fort' },
  { label: 'Nützen', value: 'Nützen' },
  { label: 'Nuutajärvi', value: 'Nuutajärvi' },
  { label: 'Nuvolento', value: 'Nuvolento' },
  { label: 'Nuvolera', value: 'Nuvolera' },
  { label: 'Nuwakot', value: 'Nuwakot' },
  { label: 'Nuwara Eliya', value: 'Nuwara Eliya' },
  { label: 'Nuwara Eliya District', value: 'Nuwara Eliya District' },
  { label: 'Nuwaybi‘A', value: 'Nuwaybi‘A' },
  { label: 'Nuxco', value: 'Nuxco' },
  { label: 'Nuxis', value: 'Nuxis' },
  { label: 'Nuyno', value: 'Nuyno' },
  { label: 'Nuyo', value: 'Nuyo' },
  { label: 'Nuyo', value: 'Nuyo' },
  { label: 'Nüziders', value: 'Nüziders' },
  { label: 'Nuzlat Al Faqin', value: 'Nuzlat Al Faqin' },
  { label: 'Nuzvid', value: 'Nuzvid' },
  { label: 'Nyachera', value: 'Nyachera' },
  { label: 'Nyack', value: 'Nyack' },
  { label: 'Nyagan', value: 'Nyagan' },
  { label: 'Nyahururu', value: 'Nyahururu' },
  { label: 'Nyakabindi', value: 'Nyakabindi' },
  { label: 'Nyakahanga', value: 'Nyakahanga' },
  { label: 'Nyakhachava', value: 'Nyakhachava' },
  { label: 'Nyala', value: 'Nyala' },
  { label: 'Nyalikungu', value: 'Nyalikungu' },
  { label: 'Nyamanari', value: 'Nyamanari' },
  { label: 'Nyamira', value: 'Nyamira' },
  { label: 'Nyamti', value: 'Nyamti' },
  { label: 'Nyamuswa', value: 'Nyamuswa' },
  { label: 'Nyandoma', value: 'Nyandoma' },
  { label: 'Nyandomskiy Rayon', value: 'Nyandomskiy Rayon' },
  { label: 'Nyanga', value: 'Nyanga' },
  { label: 'Nyanga District', value: 'Nyanga District' },
  { label: 'Nyangao', value: 'Nyangao' },
  { label: 'Nyanguge', value: 'Nyanguge' },
  { label: 'Nyáregyháza', value: 'Nyáregyháza' },
  { label: 'Nyárlőrinc', value: 'Nyárlőrinc' },
  { label: 'Nyasvizh', value: 'Nyasvizh' },
  { label: 'Nyasvizhski Rayon', value: 'Nyasvizhski Rayon' },
  { label: 'Nyaungdon', value: 'Nyaungdon' },
  { label: 'Nyaunglebin', value: 'Nyaunglebin' },
  { label: 'Nyaungshwe', value: 'Nyaungshwe' },
  { label: 'Nyazepetrovsk', value: 'Nyazepetrovsk' },
  { label: 'Nyazepetrovskiy Rayon', value: 'Nyazepetrovskiy Rayon' },
  { label: 'Nyazura', value: 'Nyazura' },
  { label: 'Nyborg', value: 'Nyborg' },
  { label: 'Nyborg Kommune', value: 'Nyborg Kommune' },
  { label: 'Nybro', value: 'Nybro' },
  { label: 'Nyda', value: 'Nyda' },
  { label: 'Nýdek', value: 'Nýdek' },
  { label: 'Nye County', value: 'Nye County' },
  { label: 'Nyékládháza', value: 'Nyékládháza' },
  { label: 'Nyergesújfalu', value: 'Nyergesújfalu' },
  { label: 'Nyeri', value: 'Nyeri' },
  { label: 'Nyhammar', value: 'Nyhammar' },
  { label: 'Nyimba', value: 'Nyimba' },
  { label: 'Nyingchi Prefecture', value: 'Nyingchi Prefecture' },
  { label: 'Nyírábrány', value: 'Nyírábrány' },
  { label: 'Nyíracsád', value: 'Nyíracsád' },
  { label: 'Nyíradony', value: 'Nyíradony' },
  { label: 'Nyíradonyi Járás', value: 'Nyíradonyi Járás' },
  { label: 'Nyírbátor', value: 'Nyírbátor' },
  { label: 'Nyírbátori Járás', value: 'Nyírbátori Járás' },
  { label: 'Nyírbéltek', value: 'Nyírbéltek' },
  { label: 'Nyírbogát', value: 'Nyírbogát' },
  { label: 'Nyírbogdány', value: 'Nyírbogdány' },
  { label: 'Nyírcsaholy', value: 'Nyírcsaholy' },
  { label: 'Nyíregyháza', value: 'Nyíregyháza' },
  { label: 'Nyíregyházi Járás', value: 'Nyíregyházi Járás' },
  { label: 'Nyírgyulaj', value: 'Nyírgyulaj' },
  { label: 'Nyírkarász', value: 'Nyírkarász' },
  { label: 'Nyírlugos', value: 'Nyírlugos' },
  { label: 'Nyírmada', value: 'Nyírmada' },
  { label: 'Nyírmártonfalva', value: 'Nyírmártonfalva' },
  { label: 'Nyírmeggyes', value: 'Nyírmeggyes' },
  { label: 'Nyírmihálydi', value: 'Nyírmihálydi' },
  { label: 'Nyírpazony', value: 'Nyírpazony' },
  { label: 'Nyírtass', value: 'Nyírtass' },
  { label: 'Nyírtelek', value: 'Nyírtelek' },
  { label: 'Nyírvasvári', value: 'Nyírvasvári' },
  { label: 'Nykarleby', value: 'Nykarleby' },
  { label: 'Nykøbing Falster', value: 'Nykøbing Falster' },
  { label: 'Nykøbing Mors', value: 'Nykøbing Mors' },
  { label: 'Nykøbing Sjælland', value: 'Nykøbing Sjælland' },
  { label: 'Nyköping', value: 'Nyköping' },
  { label: 'Nykvarn', value: 'Nykvarn' },
  { label: 'Nymburk', value: 'Nymburk' },
  { label: 'Nynäshamn', value: 'Nynäshamn' },
  { label: 'Nyngan', value: 'Nyngan' },
  { label: 'Nyoiseau', value: 'Nyoiseau' },
  { label: 'Nyon', value: 'Nyon' },
  { label: 'Nyon District', value: 'Nyon District' },
  { label: 'Nyons', value: 'Nyons' },
  { label: 'Nyora', value: 'Nyora' },
  { label: 'Nyråd', value: 'Nyråd' },
  { label: 'Nýřany', value: 'Nýřany' },
  { label: 'Nyrkiv', value: 'Nyrkiv' },
  { label: 'Nyrob', value: 'Nyrob' },
  { label: 'Nýrsko', value: 'Nýrsko' },
  { label: 'Nysa', value: 'Nysa' },
  { label: 'Nyssa', value: 'Nyssa' },
  { label: 'Nytva', value: 'Nytva' },
  { label: 'Nyuksenitsa', value: 'Nyuksenitsa' },
  { label: 'Nyúl', value: 'Nyúl' },
  { label: 'Nyurba', value: 'Nyurba' },
  { label: 'Nyurbinsky District', value: 'Nyurbinsky District' },
  { label: 'Nyūzen', value: 'Nyūzen' },
  { label: 'Nyvky', value: 'Nyvky' },
  { label: 'Nyzhni Petrivtsi', value: 'Nyzhni Petrivtsi' },
  { label: 'Nyzhni Sirohozy', value: 'Nyzhni Sirohozy' },
  { label: 'Nyzhnya Duvanka', value: 'Nyzhnya Duvanka' },
  { label: 'Nyzhnya Krynka', value: 'Nyzhnya Krynka' },
  { label: 'Nyzhnye Selyshche', value: 'Nyzhnye Selyshche' },
  { label: 'Nyzy', value: 'Nyzy' },
  { label: 'Nzega', value: 'Nzega' },
  { label: 'Nzérékoré', value: 'Nzérékoré' },
  { label: 'Nzerekore Prefecture', value: 'Nzerekore Prefecture' },
  { label: 'Nzeto', value: 'Nzeto' },
  { label: 'Nzi', value: 'Nzi' },
  { label: 'O Barco De Valdeorras', value: 'O Barco De Valdeorras' },
  { label: 'O Carballiño', value: 'O Carballiño' },
  { label: 'O Grove', value: 'O Grove' },
  { label: 'O Incio', value: 'O Incio' },
  { label: 'O Páramo', value: 'O Páramo' },
  { label: 'O Rosal', value: 'O Rosal' },
  { label: 'O’Halloran Hill', value: 'O’Halloran Hill' },
  { label: 'Oacalco', value: 'Oacalco' },
  { label: 'Oadby', value: 'Oadby' },
  { label: 'Oak Bay', value: 'Oak Bay' },
  { label: 'Oak Bluffs', value: 'Oak Bluffs' },
  { label: 'Oak Brook', value: 'Oak Brook' },
  { label: 'Oak Cliff Place', value: 'Oak Cliff Place' },
  { label: 'Oak Creek', value: 'Oak Creek' },
  { label: 'Oak Flats', value: 'Oak Flats' },
  { label: 'Oak Forest', value: 'Oak Forest' },
  { label: 'Oak Grove', value: 'Oak Grove' },
  { label: 'Oak Grove', value: 'Oak Grove' },
  { label: 'Oak Grove', value: 'Oak Grove' },
  { label: 'Oak Grove', value: 'Oak Grove' },
  { label: 'Oak Grove', value: 'Oak Grove' },
  { label: 'Oak Grove', value: 'Oak Grove' },
  { label: 'Oak Grove', value: 'Oak Grove' },
  { label: 'Oak Grove', value: 'Oak Grove' },
  { label: 'Oak Harbor', value: 'Oak Harbor' },
  { label: 'Oak Harbor', value: 'Oak Harbor' },
  { label: 'Oak Hill', value: 'Oak Hill' },
  { label: 'Oak Hill', value: 'Oak Hill' },
  { label: 'Oak Hill', value: 'Oak Hill' },
  { label: 'Oak Hill', value: 'Oak Hill' },
  { label: 'Oak Hill', value: 'Oak Hill' },
  { label: 'Oak Hills', value: 'Oak Hills' },
  { label: 'Oak Hills', value: 'Oak Hills' },
  { label: 'Oak Hills', value: 'Oak Hills' },
  { label: 'Oak Hills Place', value: 'Oak Hills Place' },
  { label: 'Oak Island', value: 'Oak Island' },
  { label: 'Oak Lawn', value: 'Oak Lawn' },
  { label: 'Oak Leaf', value: 'Oak Leaf' },
  { label: 'Oak Park', value: 'Oak Park' },
  { label: 'Oak Park', value: 'Oak Park' },
  { label: 'Oak Park', value: 'Oak Park' },
  { label: 'Oak Park', value: 'Oak Park' },
  { label: 'Oak Park', value: 'Oak Park' },
  { label: 'Oak Park Heights', value: 'Oak Park Heights' },
  { label: 'Oak Point', value: 'Oak Point' },
  { label: 'Oak Ridge', value: 'Oak Ridge' },
  { label: 'Oak Ridge', value: 'Oak Ridge' },
  { label: 'Oak Ridge', value: 'Oak Ridge' },
  { label: 'Oak Ridge North', value: 'Oak Ridge North' },
  { label: 'Oak Trail Shores', value: 'Oak Trail Shores' },
  { label: 'Oak Valley', value: 'Oak Valley' },
  { label: 'Oak View', value: 'Oak View' },
  { label: 'Oakboro', value: 'Oakboro' },
  { label: 'Oakbrook', value: 'Oakbrook' },
  { label: 'Oakbrook Terrace', value: 'Oakbrook Terrace' },
  { label: 'Oakdale', value: 'Oakdale' },
  { label: 'Oakdale', value: 'Oakdale' },
  { label: 'Oakdale', value: 'Oakdale' },
  { label: 'Oakdale', value: 'Oakdale' },
  { label: 'Oakdale', value: 'Oakdale' },
  { label: 'Oakdale', value: 'Oakdale' },
  { label: 'Oakden', value: 'Oakden' },
  { label: 'Oakdowns', value: 'Oakdowns' },
  { label: 'Oakengates', value: 'Oakengates' },
  { label: 'Oakes', value: 'Oakes' },
  { label: 'Oakey', value: 'Oakey' },
  { label: 'Oakfield', value: 'Oakfield' },
  { label: 'Oakfield', value: 'Oakfield' },
  { label: 'Oakford', value: 'Oakford' },
  { label: 'Oakham', value: 'Oakham' },
  { label: 'Oakham', value: 'Oakham' },
  { label: 'Oakhurst', value: 'Oakhurst' },
  { label: 'Oakhurst', value: 'Oakhurst' },
  { label: 'Oakhurst', value: 'Oakhurst' },
  { label: 'Oakhurst', value: 'Oakhurst' },
  { label: 'Oakhurst', value: 'Oakhurst' },
  { label: 'Oakington', value: 'Oakington' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Oakland City', value: 'Oakland City' },
  { label: 'Oakland County', value: 'Oakland County' },
  { label: 'Oakland Park', value: 'Oakland Park' },
  { label: 'Oaklands', value: 'Oaklands' },
  { label: 'Oaklands Park', value: 'Oaklands Park' },
  { label: 'Oakleaf Plantation', value: 'Oakleaf Plantation' },
  { label: 'Oakleigh', value: 'Oakleigh' },
  { label: 'Oakleigh East', value: 'Oakleigh East' },
  { label: 'Oakleigh South', value: 'Oakleigh South' },
  { label: 'Oakley', value: 'Oakley' },
  { label: 'Oakley', value: 'Oakley' },
  { label: 'Oakley', value: 'Oakley' },
  { label: 'Oakley', value: 'Oakley' },
  { label: 'Oakley', value: 'Oakley' },
  { label: 'Oaklyn', value: 'Oaklyn' },
  { label: 'Oakmont', value: 'Oakmont' },
  { label: 'Oakport', value: 'Oakport' },
  { label: 'Oakridge', value: 'Oakridge' },
  { label: 'Oakton', value: 'Oakton' },
  { label: 'Oakville', value: 'Oakville' },
  { label: 'Oakville', value: 'Oakville' },
  { label: 'Oakville', value: 'Oakville' },
  { label: 'Oakville', value: 'Oakville' },
  { label: 'Oakwood', value: 'Oakwood' },
  { label: 'Oakwood', value: 'Oakwood' },
  { label: 'Oakwood', value: 'Oakwood' },
  { label: 'Oakwood', value: 'Oakwood' },
  { label: 'Oakwood', value: 'Oakwood' },
  { label: 'Oakwood Hills', value: 'Oakwood Hills' },
  { label: 'Oamaru', value: 'Oamaru' },
  { label: 'Ōami', value: 'Ōami' },
  { label: 'Ōamishirasato-Shi', value: 'Ōamishirasato-Shi' },
  { label: 'Oancea', value: 'Oancea' },
  { label: 'Oanțu', value: 'Oanțu' },
  { label: 'Oaqui', value: 'Oaqui' },
  { label: 'Oaqui', value: 'Oaqui' },
  { label: 'Oar', value: 'Oar' },
  { label: 'Ōarai', value: 'Ōarai' },
  { label: 'Oarba De Mureș', value: 'Oarba De Mureș' },
  { label: 'Oarda', value: 'Oarda' },
  { label: 'Oarja', value: 'Oarja' },
  { label: 'Oarja Sat', value: 'Oarja Sat' },
  { label: 'Oarţa De Jos', value: 'Oarţa De Jos' },
  { label: 'Oarța De Sus', value: 'Oarța De Sus' },
  { label: 'Oasis', value: 'Oasis' },
  { label: 'Oasis', value: 'Oasis' },
  { label: 'Oatfield', value: 'Oatfield' },
  { label: 'Oatlands', value: 'Oatlands' },
  { label: 'Oatley', value: 'Oatley' },
  { label: 'Oaxaca', value: 'Oaxaca' },
  { label: 'Oaxaca De Juárez', value: 'Oaxaca De Juárez' },
  { label: 'Oaxtepec', value: 'Oaxtepec' },
  { label: 'Ob’', value: 'Ob’' },
  { label: 'Obala', value: 'Obala' },
  { label: 'Obama', value: 'Obama' },
  { label: 'Obama-Shi', value: 'Obama-Shi' },
  { label: 'Oban', value: 'Oban' },
  { label: 'Obanazawa', value: 'Obanazawa' },
  { label: 'Obanazawa Shi', value: 'Obanazawa Shi' },
  { label: 'Obando', value: 'Obando' },
  { label: 'Obando', value: 'Obando' },
  { label: 'Obando', value: 'Obando' },
  { label: 'Obanos', value: 'Obanos' },
  { label: 'Obârşia', value: 'Obârşia' },
  { label: 'Obârșia De Câmp', value: 'Obârșia De Câmp' },
  { label: 'Obârşia-Cloşani', value: 'Obârşia-Cloşani' },
  { label: 'Obârșie', value: 'Obârșie' },
  { label: 'Obbicht', value: 'Obbicht' },
  { label: 'Obbola', value: 'Obbola' },
  { label: 'Obdach', value: 'Obdach' },
  { label: 'Obdam', value: 'Obdam' },
  { label: 'Obecnice', value: 'Obecnice' },
  { label: 'Obejo', value: 'Obejo' },
  { label: 'Obeliai', value: 'Obeliai' },
  { label: 'Obenheim', value: 'Obenheim' },
  { label: 'Oberá', value: 'Oberá' },
  { label: 'Oberaargau', value: 'Oberaargau' },
  { label: 'Oberaich', value: 'Oberaich' },
  { label: 'Oberalm', value: 'Oberalm' },
  { label: 'Oberammergau', value: 'Oberammergau' },
  { label: 'Oberasbach', value: 'Oberasbach' },
  { label: 'Oberau', value: 'Oberau' },
  { label: 'Oberaudorf', value: 'Oberaudorf' },
  { label: 'Oberaula', value: 'Oberaula' },
  { label: 'Oberbergkirchen', value: 'Oberbergkirchen' },
  { label: 'Oberbiel', value: 'Oberbiel' },
  { label: 'Oberbipp', value: 'Oberbipp' },
  { label: 'Oberboihingen', value: 'Oberboihingen' },
  { label: 'Oberbronn', value: 'Oberbronn' },
  { label: 'Oberbuchsiten', value: 'Oberbuchsiten' },
  { label: 'Oberburg', value: 'Oberburg' },
  { label: 'Obercorn', value: 'Obercorn' },
  { label: 'Obercunnersdorf', value: 'Obercunnersdorf' },
  { label: 'Oberdachstetten', value: 'Oberdachstetten' },
  { label: 'Oberderdingen', value: 'Oberderdingen' },
  { label: 'Oberdiessbach', value: 'Oberdiessbach' },
  { label: 'Oberding', value: 'Oberding' },
  { label: 'Oberdischingen', value: 'Oberdischingen' },
  { label: 'Oberdorf', value: 'Oberdorf' },
  { label: 'Oberdorf Am Hochegg', value: 'Oberdorf Am Hochegg' },
  { label: 'Oberdorf Im Burgenland', value: 'Oberdorf Im Burgenland' },
  { label: 'Oberdörfl', value: 'Oberdörfl' },
  { label: 'Oberdorla', value: 'Oberdorla' },
  { label: 'Oberdrauburg', value: 'Oberdrauburg' },
  { label: 'Oberegg', value: 'Oberegg' },
  { label: 'Oberelbert', value: 'Oberelbert' },
  { label: 'Oberelsbach', value: 'Oberelsbach' },
  { label: 'Oberengstringen', value: 'Oberengstringen' },
  { label: 'Oberengstringen / Rauchacher', value: 'Oberengstringen / Rauchacher' },
  { label: 'Oberengstringen / Sonnenberg', value: 'Oberengstringen / Sonnenberg' },
  { label: 'Oberengstringen / Zentrum', value: 'Oberengstringen / Zentrum' },
  { label: 'Oberentfelden', value: 'Oberentfelden' },
  { label: 'Oberfell', value: 'Oberfell' },
  { label: 'Ober-Flörsheim', value: 'Ober-Flörsheim' },
  { label: 'Obergäu', value: 'Obergäu' },
  { label: 'Oberglatt', value: 'Oberglatt' },
  { label: 'Oberglatt / Bahnhofquartier', value: 'Oberglatt / Bahnhofquartier' },
  { label: 'Oberglatt / Oberglatt (Dorfkern)', value: 'Oberglatt / Oberglatt (Dorfkern)' },
  { label: 'Obergösgen', value: 'Obergösgen' },
  { label: 'Ober-Grafendorf', value: 'Ober-Grafendorf' },
  { label: 'Obergriesbach', value: 'Obergriesbach' },
  { label: 'Obergum', value: 'Obergum' },
  { label: 'Obergünzburg', value: 'Obergünzburg' },
  { label: 'Obergurig', value: 'Obergurig' },
  { label: 'Oberhaag', value: 'Oberhaag' },
  { label: 'Oberhaching', value: 'Oberhaching' },
  { label: 'Oberhaid', value: 'Oberhaid' },
  { label: 'Oberhaid', value: 'Oberhaid' },
  { label: 'Oberharmersbach', value: 'Oberharmersbach' },
  { label: 'Oberhart', value: 'Oberhart' },
  { label: 'Oberhaslach', value: 'Oberhaslach' },
  { label: 'Oberhausbergen', value: 'Oberhausbergen' },
  { label: 'Oberhausen', value: 'Oberhausen' },
  { label: 'Oberhausen', value: 'Oberhausen' },
  { label: 'Oberhausen', value: 'Oberhausen' },
  { label: 'Oberhausen-Rheinhausen', value: 'Oberhausen-Rheinhausen' },
  { label: 'Oberhelfenschwil', value: 'Oberhelfenschwil' },
  { label: 'Oberhergheim', value: 'Oberhergheim' },
  { label: 'Oberhof', value: 'Oberhof' },
  { label: 'Oberhofen Am Irrsee', value: 'Oberhofen Am Irrsee' },
  { label: 'Oberhofen Im Inntal', value: 'Oberhofen Im Inntal' },
  { label: 'Oberhoffen-Sur-Moder', value: 'Oberhoffen-Sur-Moder' },
  { label: 'Oberjettingen', value: 'Oberjettingen' },
  { label: 'Oberkirch', value: 'Oberkirch' },
  { label: 'Oberkirch', value: 'Oberkirch' },
  { label: 'Oberkochen', value: 'Oberkochen' },
  { label: 'Oberkotzau', value: 'Oberkotzau' },
  { label: 'Oberkurzheim', value: 'Oberkurzheim' },
  { label: 'Oberleichtersbach', value: 'Oberleichtersbach' },
  { label: 'Oberlichtenau', value: 'Oberlichtenau' },
  { label: 'Oberlienz', value: 'Oberlienz' },
  { label: 'Oberlin', value: 'Oberlin' },
  { label: 'Oberlin', value: 'Oberlin' },
  { label: 'Oberlin', value: 'Oberlin' },
  { label: 'Oberloisdorf', value: 'Oberloisdorf' },
  { label: 'Oberlungwitz', value: 'Oberlungwitz' },
  { label: 'Oberlunkhofen', value: 'Oberlunkhofen' },
  { label: 'Obermarchtal', value: 'Obermarchtal' },
  { label: 'Obermaßfeld-Grimmenthal', value: 'Obermaßfeld-Grimmenthal' },
  { label: 'Obermehler', value: 'Obermehler' },
  { label: 'Obermeilen', value: 'Obermeilen' },
  { label: 'Obermeitingen', value: 'Obermeitingen' },
  { label: 'Obermichelbach', value: 'Obermichelbach' },
  { label: 'Obermieming', value: 'Obermieming' },
  { label: 'Obermillstatt', value: 'Obermillstatt' },
  { label: 'Obermodern-Zutzendorf', value: 'Obermodern-Zutzendorf' },
  { label: 'Ober-Mörlen', value: 'Ober-Mörlen' },
  { label: 'Obermoschel', value: 'Obermoschel' },
  { label: 'Obernai', value: 'Obernai' },
  { label: 'Obernbeck', value: 'Obernbeck' },
  { label: 'Obernberg Am Brenner', value: 'Obernberg Am Brenner' },
  { label: 'Obernberg Am Inn', value: 'Obernberg Am Inn' },
  { label: 'Obernbreit', value: 'Obernbreit' },
  { label: 'Obernburg Am Main', value: 'Obernburg Am Main' },
  { label: 'Oberndorf', value: 'Oberndorf' },
  { label: 'Oberndorf', value: 'Oberndorf' },
  { label: 'Oberndorf', value: 'Oberndorf' },
  { label: 'Oberndorf An Der Melk', value: 'Oberndorf An Der Melk' },
  { label: 'Oberndorf Bei Salzburg', value: 'Oberndorf Bei Salzburg' },
  { label: 'Oberndorf Bei Schwanenstadt', value: 'Oberndorf Bei Schwanenstadt' },
  { label: 'Oberndorf In Der Ebene', value: 'Oberndorf In Der Ebene' },
  { label: 'Oberndorf In Tirol', value: 'Oberndorf In Tirol' },
  { label: 'Oberneukirchen', value: 'Oberneukirchen' },
  { label: 'Obernfeld', value: 'Obernfeld' },
  { label: 'Obernheim', value: 'Obernheim' },
  { label: 'Obernheim-Kirchenarnbach', value: 'Obernheim-Kirchenarnbach' },
  { label: 'Obernkirchen', value: 'Obernkirchen' },
  { label: 'Obernzell', value: 'Obernzell' },
  { label: 'Obernzenn', value: 'Obernzenn' },
  { label: 'Ober-Olm', value: 'Ober-Olm' },
  { label: 'Oberon', value: 'Oberon' },
  { label: 'Oberostendorf', value: 'Oberostendorf' },
  { label: 'Oberotterbach', value: 'Oberotterbach' },
  { label: 'Oberottmarshausen', value: 'Oberottmarshausen' },
  { label: 'Oberperfuss', value: 'Oberperfuss' },
  { label: 'Oberpframmern', value: 'Oberpframmern' },
  { label: 'Oberpleichfeld', value: 'Oberpleichfeld' },
  { label: 'Oberpöring', value: 'Oberpöring' },
  { label: 'Oberpremstätten', value: 'Oberpremstätten' },
  { label: 'Oberpullendorf', value: 'Oberpullendorf' },
  { label: 'Ober-Ramstadt', value: 'Ober-Ramstadt' },
  { label: 'Oberreichenbach', value: 'Oberreichenbach' },
  { label: 'Oberreichenbach', value: 'Oberreichenbach' },
  { label: 'Oberrettenbach', value: 'Oberrettenbach' },
  { label: 'Oberreute', value: 'Oberreute' },
  { label: 'Oberried', value: 'Oberried' },
  { label: 'Oberrieden', value: 'Oberrieden' },
  { label: 'Oberrieden', value: 'Oberrieden' },
  { label: 'Oberrieden / Berg', value: 'Oberrieden / Berg' },
  { label: 'Oberrieden / Mitte', value: 'Oberrieden / Mitte' },
  { label: 'Oberriet', value: 'Oberriet' },
  { label: 'Oberriexingen', value: 'Oberriexingen' },
  { label: 'Oberröblingen', value: 'Oberröblingen' },
  { label: 'Oberrohrdorf', value: 'Oberrohrdorf' },
  { label: 'Oberrot', value: 'Oberrot' },
  { label: 'Oberrotweil', value: 'Oberrotweil' },
  { label: 'Oberrüti', value: 'Oberrüti' },
  { label: 'Ober-Saulheim', value: 'Ober-Saulheim' },
  { label: 'Oberschaeffolsheim', value: 'Oberschaeffolsheim' },
  { label: 'Oberscheinfeld', value: 'Oberscheinfeld' },
  { label: 'Oberschleißheim', value: 'Oberschleißheim' },
  { label: 'Oberschneiding', value: 'Oberschneiding' },
  { label: 'Oberschöna', value: 'Oberschöna' },
  { label: 'Oberschöneweide', value: 'Oberschöneweide' },
  { label: 'Oberschrot', value: 'Oberschrot' },
  { label: 'Oberschützen', value: 'Oberschützen' },
  { label: 'Oberschwarzach', value: 'Oberschwarzach' },
  { label: 'Oberschweinbach', value: 'Oberschweinbach' },
  { label: 'Obersdorf', value: 'Obersdorf' },
  { label: 'Obersiebenbrunn', value: 'Obersiebenbrunn' },
  { label: 'Obersiggenthal', value: 'Obersiggenthal' },
  { label: 'Obersimmental-Saanen District', value: 'Obersimmental-Saanen District' },
  { label: 'Obersinn', value: 'Obersinn' },
  { label: 'Obersöchering', value: 'Obersöchering' },
  { label: 'Obersontheim', value: 'Obersontheim' },
  { label: 'Oberstadion', value: 'Oberstadion' },
  { label: 'Oberstaufen', value: 'Oberstaufen' },
  { label: 'Oberstdorf', value: 'Oberstdorf' },
  { label: 'Oberstenfeld', value: 'Oberstenfeld' },
  { label: 'Oberstorcha', value: 'Oberstorcha' },
  { label: 'Oberstreu', value: 'Oberstreu' },
  { label: 'Obersüßbach', value: 'Obersüßbach' },
  { label: 'Obertaufkirchen', value: 'Obertaufkirchen' },
  { label: 'Oberteuringen', value: 'Oberteuringen' },
  { label: 'Oberthal', value: 'Oberthal' },
  { label: 'Oberthulba', value: 'Oberthulba' },
  { label: 'Obertilliach', value: 'Obertilliach' },
  { label: 'Obertraubling', value: 'Obertraubling' },
  { label: 'Obertrubach', value: 'Obertrubach' },
  { label: 'Obertrum Am See', value: 'Obertrum Am See' },
  { label: 'Obertshausen', value: 'Obertshausen' },
  { label: 'Obertyn', value: 'Obertyn' },
  { label: 'Oberurnen', value: 'Oberurnen' },
  { label: 'Oberursel', value: 'Oberursel' },
  { label: 'Oberuzwil', value: 'Oberuzwil' },
  { label: 'Obervellach', value: 'Obervellach' },
  { label: 'Oberviechtach', value: 'Oberviechtach' },
  { label: 'Obervogau', value: 'Obervogau' },
  { label: 'Oberwagram', value: 'Oberwagram' },
  { label: 'Oberwaltersdorf', value: 'Oberwaltersdorf' },
  { label: 'Oberwang', value: 'Oberwang' },
  { label: 'Oberwart', value: 'Oberwart' },
  { label: 'Oberweg', value: 'Oberweg' },
  { label: 'Oberweißbach', value: 'Oberweißbach' },
  { label: 'Oberwesel', value: 'Oberwesel' },
  { label: 'Oberwiera', value: 'Oberwiera' },
  { label: 'Oberwil', value: 'Oberwil' },
  { label: 'Oberwölbling', value: 'Oberwölbling' },
  { label: 'Oberwolfach', value: 'Oberwolfach' },
  { label: 'Oberwölz Stadt', value: 'Oberwölz Stadt' },
  { label: 'Oberzeiring', value: 'Oberzeiring' },
  { label: 'Oberzissen', value: 'Oberzissen' },
  { label: 'Obetz', value: 'Obetz' },
  { label: 'Obfelden', value: 'Obfelden' },
  { label: 'Obfelden / Oberlunnern', value: 'Obfelden / Oberlunnern' },
  { label: 'Obfelden / Toussen', value: 'Obfelden / Toussen' },
  { label: 'Obhausen', value: 'Obhausen' },
  { label: 'Obiaruku', value: 'Obiaruku' },
  { label: 'Óbidos', value: 'Óbidos' },
  { label: 'Óbidos', value: 'Óbidos' },
  { label: 'Obigarm', value: 'Obigarm' },
  { label: 'Obihiro', value: 'Obihiro' },
  { label: 'Obihiro Shi', value: 'Obihiro Shi' },
  { label: 'Obing', value: 'Obing' },
  { label: 'Obion', value: 'Obion' },
  { label: 'Obion County', value: 'Obion County' },
  { label: 'Obispo Trejo', value: 'Obispo Trejo' },
  { label: 'Obita', value: 'Obita' },
  { label: 'Objat', value: 'Objat' },
  { label: 'Öblarn', value: 'Öblarn' },
  { label: 'Oblesevo', value: 'Oblesevo' },
  { label: 'Obligado', value: 'Obligado' },
  { label: 'Oblivskaya', value: 'Oblivskaya' },
  { label: 'Oblong', value: 'Oblong' },
  { label: 'Obluchenskiy Rayon', value: 'Obluchenskiy Rayon' },
  { label: 'Obninsk', value: 'Obninsk' },
  { label: 'Obo', value: 'Obo' },
  { label: 'Obock', value: 'Obock' },
  { label: 'Obodivka', value: 'Obodivka' },
  { label: 'Oboga', value: 'Oboga' },
  { label: 'Obolensk', value: 'Obolensk' },
  { label: 'Obón', value: 'Obón' },
  { label: 'Obong', value: 'Obong' },
  { label: 'Obonoma', value: 'Obonoma' },
  { label: 'Oborniki', value: 'Oborniki' },
  { label: 'Oborniki Śląskie', value: 'Oborniki Śląskie' },
  { label: 'Oboyan’', value: 'Oboyan’' },
  { label: 'Oboyanskiy Rayon', value: 'Oboyanskiy Rayon' },
  { label: 'Obra', value: 'Obra' },
  { label: 'Obrajuelo', value: 'Obrajuelo' },
  { label: 'Obrazów', value: 'Obrazów' },
  { label: 'Obreja', value: 'Obreja' },
  { label: 'Obrejița', value: 'Obrejița' },
  { label: 'Obrenovac', value: 'Obrenovac' },
  { label: 'Obrien County', value: 'Obrien County' },
  { label: 'Obrier', value: 'Obrier' },
  { label: 'Obrigheim', value: 'Obrigheim' },
  { label: 'Obrigheim', value: 'Obrigheim' },
  { label: 'Obrnice', value: 'Obrnice' },
  { label: 'Obroshyne', value: 'Obroshyne' },
  { label: 'Obrovac', value: 'Obrovac' },
  { label: 'Obrovac', value: 'Obrovac' },
  { label: 'Obrowo', value: 'Obrowo' },
  { label: 'Obršani', value: 'Obršani' },
  { label: 'Obryte', value: 'Obryte' },
  { label: 'Obrzycko', value: 'Obrzycko' },
  { label: 'Obsharovka', value: 'Obsharovka' },
  { label: 'Obshtina Aksakovo', value: 'Obshtina Aksakovo' },
  { label: 'Obshtina Alfatar', value: 'Obshtina Alfatar' },
  { label: 'Obshtina Anton', value: 'Obshtina Anton' },
  { label: 'Obshtina Antonovo', value: 'Obshtina Antonovo' },
  { label: 'Obshtina Ardino', value: 'Obshtina Ardino' },
  { label: 'Obshtina Asenovgrad', value: 'Obshtina Asenovgrad' },
  { label: 'Obshtina Avren', value: 'Obshtina Avren' },
  { label: 'Obshtina Aytos', value: 'Obshtina Aytos' },
  { label: 'Obshtina Balchik', value: 'Obshtina Balchik' },
  { label: 'Obshtina Banite', value: 'Obshtina Banite' },
  { label: 'Obshtina Bansko', value: 'Obshtina Bansko' },
  { label: 'Obshtina Batak', value: 'Obshtina Batak' },
  { label: 'Obshtina Belene', value: 'Obshtina Belene' },
  { label: 'Obshtina Belitsa', value: 'Obshtina Belitsa' },
  { label: 'Obshtina Belogradchik', value: 'Obshtina Belogradchik' },
  { label: 'Obshtina Beloslav', value: 'Obshtina Beloslav' },
  { label: 'Obshtina Belovo', value: 'Obshtina Belovo' },
  { label: 'Obshtina Blagoevgrad', value: 'Obshtina Blagoevgrad' },
  { label: 'Obshtina Boboshevo', value: 'Obshtina Boboshevo' },
  { label: 'Obshtina Bobov Dol', value: 'Obshtina Bobov Dol' },
  { label: 'Obshtina Bolyarovo', value: 'Obshtina Bolyarovo' },
  { label: 'Obshtina Borino', value: 'Obshtina Borino' },
  { label: 'Obshtina Borovan', value: 'Obshtina Borovan' },
  { label: 'Obshtina Borovo', value: 'Obshtina Borovo' },
  { label: 'Obshtina Botevgrad', value: 'Obshtina Botevgrad' },
  { label: 'Obshtina Boychinovtsi', value: 'Obshtina Boychinovtsi' },
  { label: 'Obshtina Boynitsa', value: 'Obshtina Boynitsa' },
  { label: 'Obshtina Bozhurishte', value: 'Obshtina Bozhurishte' },
  { label: 'Obshtina Bratsigovo', value: 'Obshtina Bratsigovo' },
  { label: 'Obshtina Bratya Daskalovi', value: 'Obshtina Bratya Daskalovi' },
  { label: 'Obshtina Burgas', value: 'Obshtina Burgas' },
  { label: 'Obshtina Byala', value: 'Obshtina Byala' },
  { label: 'Obshtina Byala', value: 'Obshtina Byala' },
  { label: 'Obshtina Chavdar', value: 'Obshtina Chavdar' },
  { label: 'Obshtina Chelopech', value: 'Obshtina Chelopech' },
  { label: 'Obshtina Chepelare', value: 'Obshtina Chepelare' },
  { label: 'Obshtina Chernoochene', value: 'Obshtina Chernoochene' },
  { label: 'Obshtina Cherven Bryag', value: 'Obshtina Cherven Bryag' },
  { label: 'Obshtina Chiprovtsi', value: 'Obshtina Chiprovtsi' },
  { label: 'Obshtina Chirpan', value: 'Obshtina Chirpan' },
  { label: 'Obshtina Dalgopol', value: 'Obshtina Dalgopol' },
  { label: 'Obshtina Devin', value: 'Obshtina Devin' },
  { label: 'Obshtina Devnya', value: 'Obshtina Devnya' },
  { label: 'Obshtina Dimitrovgrad', value: 'Obshtina Dimitrovgrad' },
  { label: 'Obshtina Dimovo', value: 'Obshtina Dimovo' },
  { label: 'Obshtina Dobrich', value: 'Obshtina Dobrich' },
  { label: 'Obshtina Dobrich-Selska', value: 'Obshtina Dobrich-Selska' },
  { label: 'Obshtina Dolna Banya', value: 'Obshtina Dolna Banya' },
  { label: 'Obshtina Dolna Mitropolia', value: 'Obshtina Dolna Mitropolia' },
  { label: 'Obshtina Dolni Chiflik', value: 'Obshtina Dolni Chiflik' },
  { label: 'Obshtina Dolni Dabnik', value: 'Obshtina Dolni Dabnik' },
  { label: 'Obshtina Dospat', value: 'Obshtina Dospat' },
  { label: 'Obshtina Dragoman', value: 'Obshtina Dragoman' },
  { label: 'Obshtina Dryanovo', value: 'Obshtina Dryanovo' },
  { label: 'Obshtina Dulovo', value: 'Obshtina Dulovo' },
  { label: 'Obshtina Dupnitsa', value: 'Obshtina Dupnitsa' },
  { label: 'Obshtina Dve Mogili', value: 'Obshtina Dve Mogili' },
  { label: 'Obshtina Dzhebel', value: 'Obshtina Dzhebel' },
  { label: 'Obshtina Elena', value: 'Obshtina Elena' },
  { label: 'Obshtina Elhovo', value: 'Obshtina Elhovo' },
  { label: 'Obshtina Elin Pelin', value: 'Obshtina Elin Pelin' },
  { label: 'Obshtina Etropole', value: 'Obshtina Etropole' },
  { label: 'Obshtina Gabrovo', value: 'Obshtina Gabrovo' },
  { label: 'Obshtina Galabovo', value: 'Obshtina Galabovo' },
  { label: 'Obshtina Garmen', value: 'Obshtina Garmen' },
  { label: 'Obshtina General Toshevo', value: 'Obshtina General Toshevo' },
  { label: 'Obshtina Georgi Damyanovo', value: 'Obshtina Georgi Damyanovo' },
  { label: 'Obshtina Glavinitsa', value: 'Obshtina Glavinitsa' },
  { label: 'Obshtina Gorna Malina', value: 'Obshtina Gorna Malina' },
  { label: 'Obshtina Gorna Oryahovitsa', value: 'Obshtina Gorna Oryahovitsa' },
  { label: 'Obshtina Gotse Delchev', value: 'Obshtina Gotse Delchev' },
  { label: 'Obshtina Gramada', value: 'Obshtina Gramada' },
  { label: 'Obshtina Gulyantsi', value: 'Obshtina Gulyantsi' },
  { label: 'Obshtina Gurkovo', value: 'Obshtina Gurkovo' },
  { label: 'Obshtina Harmanli', value: 'Obshtina Harmanli' },
  { label: 'Obshtina Haskovo', value: 'Obshtina Haskovo' },
  { label: 'Obshtina Hayredin', value: 'Obshtina Hayredin' },
  { label: 'Obshtina Hisarya', value: 'Obshtina Hisarya' },
  { label: 'Obshtina Iskar', value: 'Obshtina Iskar' },
  { label: 'Obshtina Isperih', value: 'Obshtina Isperih' },
  { label: 'Obshtina Ivanovo', value: 'Obshtina Ivanovo' },
  { label: 'Obshtina Ivaylovgrad', value: 'Obshtina Ivaylovgrad' },
  { label: 'Obshtina Kaloyanovo', value: 'Obshtina Kaloyanovo' },
  { label: 'Obshtina Kameno', value: 'Obshtina Kameno' },
  { label: 'Obshtina Kardzhali', value: 'Obshtina Kardzhali' },
  { label: 'Obshtina Karlovo', value: 'Obshtina Karlovo' },
  { label: 'Obshtina Karnobat', value: 'Obshtina Karnobat' },
  { label: 'Obshtina Kavarna', value: 'Obshtina Kavarna' },
  { label: 'Obshtina Kaynardzha', value: 'Obshtina Kaynardzha' },
  { label: 'Obshtina Kazanlŭk', value: 'Obshtina Kazanlŭk' },
  { label: 'Obshtina Kirkovo', value: 'Obshtina Kirkovo' },
  { label: 'Obshtina Knezha', value: 'Obshtina Knezha' },
  { label: 'Obshtina Kocherinovo', value: 'Obshtina Kocherinovo' },
  { label: 'Obshtina Koprivshtitsa', value: 'Obshtina Koprivshtitsa' },
  { label: 'Obshtina Kostenets', value: 'Obshtina Kostenets' },
  { label: 'Obshtina Kostinbrod', value: 'Obshtina Kostinbrod' },
  { label: 'Obshtina Kotel', value: 'Obshtina Kotel' },
  { label: 'Obshtina Kovachevtsi', value: 'Obshtina Kovachevtsi' },
  { label: 'Obshtina Kozloduy', value: 'Obshtina Kozloduy' },
  { label: 'Obshtina Kresna', value: 'Obshtina Kresna' },
  { label: 'Obshtina Krichim', value: 'Obshtina Krichim' },
  { label: 'Obshtina Krivodol', value: 'Obshtina Krivodol' },
  { label: 'Obshtina Krushari', value: 'Obshtina Krushari' },
  { label: 'Obshtina Kubrat', value: 'Obshtina Kubrat' },
  { label: 'Obshtina Kuklen', value: 'Obshtina Kuklen' },
  { label: 'Obshtina Kula', value: 'Obshtina Kula' },
  { label: 'Obshtina Kyustendil', value: 'Obshtina Kyustendil' },
  { label: 'Obshtina Laki', value: 'Obshtina Laki' },
  { label: 'Obshtina Lesichovo', value: 'Obshtina Lesichovo' },
  { label: 'Obshtina Levski', value: 'Obshtina Levski' },
  { label: 'Obshtina Lom', value: 'Obshtina Lom' },
  { label: 'Obshtina Lovech', value: 'Obshtina Lovech' },
  { label: 'Obshtina Loznitsa', value: 'Obshtina Loznitsa' },
  { label: 'Obshtina Lyaskovets', value: 'Obshtina Lyaskovets' },
  { label: 'Obshtina Madan', value: 'Obshtina Madan' },
  { label: 'Obshtina Madzharovo', value: 'Obshtina Madzharovo' },
  { label: 'Obshtina Maglizh', value: 'Obshtina Maglizh' },
  { label: 'Obshtina Malko Tarnovo', value: 'Obshtina Malko Tarnovo' },
  { label: 'Obshtina Maritsa', value: 'Obshtina Maritsa' },
  { label: 'Obshtina Mezdra', value: 'Obshtina Mezdra' },
  { label: 'Obshtina Mineralni Bani', value: 'Obshtina Mineralni Bani' },
  { label: 'Obshtina Mirkovo', value: 'Obshtina Mirkovo' },
  { label: 'Obshtina Mizia', value: 'Obshtina Mizia' },
  { label: 'Obshtina Momchilgrad', value: 'Obshtina Momchilgrad' },
  { label: 'Obshtina Montana', value: 'Obshtina Montana' },
  { label: 'Obshtina Nedelino', value: 'Obshtina Nedelino' },
  { label: 'Obshtina Nesebar', value: 'Obshtina Nesebar' },
  { label: 'Obshtina Nevestino', value: 'Obshtina Nevestino' },
  { label: 'Obshtina Nikolaevo', value: 'Obshtina Nikolaevo' },
  { label: 'Obshtina Nikopol', value: 'Obshtina Nikopol' },
  { label: 'Obshtina Nova Zagora', value: 'Obshtina Nova Zagora' },
  { label: 'Obshtina Omurtag', value: 'Obshtina Omurtag' },
  { label: 'Obshtina Opaka', value: 'Obshtina Opaka' },
  { label: 'Obshtina Opan', value: 'Obshtina Opan' },
  { label: 'Obshtina Oryahovo', value: 'Obshtina Oryahovo' },
  { label: 'Obshtina Panagyurishte', value: 'Obshtina Panagyurishte' },
  { label: 'Obshtina Parvomay', value: 'Obshtina Parvomay' },
  { label: 'Obshtina Pavel Banya', value: 'Obshtina Pavel Banya' },
  { label: 'Obshtina Pavlikeni', value: 'Obshtina Pavlikeni' },
  { label: 'Obshtina Pazardzhik', value: 'Obshtina Pazardzhik' },
  { label: 'Obshtina Pernik', value: 'Obshtina Pernik' },
  { label: 'Obshtina Perushtitsa', value: 'Obshtina Perushtitsa' },
  { label: 'Obshtina Peshtera', value: 'Obshtina Peshtera' },
  { label: 'Obshtina Petrich', value: 'Obshtina Petrich' },
  { label: 'Obshtina Pirdop', value: 'Obshtina Pirdop' },
  { label: 'Obshtina Pleven', value: 'Obshtina Pleven' },
  { label: 'Obshtina Plovdiv', value: 'Obshtina Plovdiv' },
  { label: 'Obshtina Polski Trambesh', value: 'Obshtina Polski Trambesh' },
  { label: 'Obshtina Pomorie', value: 'Obshtina Pomorie' },
  { label: 'Obshtina Popovo', value: 'Obshtina Popovo' },
  { label: 'Obshtina Pordim', value: 'Obshtina Pordim' },
  { label: 'Obshtina Pravets', value: 'Obshtina Pravets' },
  { label: 'Obshtina Primorsko', value: 'Obshtina Primorsko' },
  { label: 'Obshtina Provadia', value: 'Obshtina Provadia' },
  { label: 'Obshtina Radnevo', value: 'Obshtina Radnevo' },
  { label: 'Obshtina Radomir', value: 'Obshtina Radomir' },
  { label: 'Obshtina Rakitovo', value: 'Obshtina Rakitovo' },
  { label: 'Obshtina Rakovski', value: 'Obshtina Rakovski' },
  { label: 'Obshtina Razgrad', value: 'Obshtina Razgrad' },
  { label: 'Obshtina Razlog', value: 'Obshtina Razlog' },
  { label: 'Obshtina Rila', value: 'Obshtina Rila' },
  { label: 'Obshtina Rodopi', value: 'Obshtina Rodopi' },
  { label: 'Obshtina Roman', value: 'Obshtina Roman' },
  { label: 'Obshtina Rudozem', value: 'Obshtina Rudozem' },
  { label: 'Obshtina Ruse', value: 'Obshtina Ruse' },
  { label: 'Obshtina Ruzhintsi', value: 'Obshtina Ruzhintsi' },
  { label: 'Obshtina Sadovo', value: 'Obshtina Sadovo' },
  { label: 'Obshtina Saedinenie', value: 'Obshtina Saedinenie' },
  { label: 'Obshtina Samokov', value: 'Obshtina Samokov' },
  { label: 'Obshtina Samuil', value: 'Obshtina Samuil' },
  { label: 'Obshtina Sandanski', value: 'Obshtina Sandanski' },
  { label: 'Obshtina Sapareva Banya', value: 'Obshtina Sapareva Banya' },
  { label: 'Obshtina Satovcha', value: 'Obshtina Satovcha' },
  { label: 'Obshtina Septemvri', value: 'Obshtina Septemvri' },
  { label: 'Obshtina Sevlievo', value: 'Obshtina Sevlievo' },
  { label: 'Obshtina Shabla', value: 'Obshtina Shabla' },
  { label: 'Obshtina Silistra', value: 'Obshtina Silistra' },
  { label: 'Obshtina Simitli', value: 'Obshtina Simitli' },
  { label: 'Obshtina Sitovo', value: 'Obshtina Sitovo' },
  { label: 'Obshtina Sliven', value: 'Obshtina Sliven' },
  { label: 'Obshtina Slivnitsa', value: 'Obshtina Slivnitsa' },
  { label: 'Obshtina Slivo Pole', value: 'Obshtina Slivo Pole' },
  { label: 'Obshtina Smolyan', value: 'Obshtina Smolyan' },
  { label: 'Obshtina Sopot', value: 'Obshtina Sopot' },
  { label: 'Obshtina Sozopol', value: 'Obshtina Sozopol' },
  { label: 'Obshtina Stamboliyski', value: 'Obshtina Stamboliyski' },
  { label: 'Obshtina Stambolovo', value: 'Obshtina Stambolovo' },
  { label: 'Obshtina Stara Zagora', value: 'Obshtina Stara Zagora' },
  { label: 'Obshtina Straldzha', value: 'Obshtina Straldzha' },
  { label: 'Obshtina Strazhitsa', value: 'Obshtina Strazhitsa' },
  { label: 'Obshtina Strelcha', value: 'Obshtina Strelcha' },
  { label: 'Obshtina Strumyani', value: 'Obshtina Strumyani' },
  { label: 'Obshtina Suhindol', value: 'Obshtina Suhindol' },
  { label: 'Obshtina Sungurlare', value: 'Obshtina Sungurlare' },
  { label: 'Obshtina Suvorovo', value: 'Obshtina Suvorovo' },
  { label: 'Obshtina Svilengrad', value: 'Obshtina Svilengrad' },
  { label: 'Obshtina Svishtov', value: 'Obshtina Svishtov' },
  { label: 'Obshtina Svoge', value: 'Obshtina Svoge' },
  { label: 'Obshtina Targovishte', value: 'Obshtina Targovishte' },
  { label: 'Obshtina Tervel', value: 'Obshtina Tervel' },
  { label: 'Obshtina Teteven', value: 'Obshtina Teteven' },
  { label: 'Obshtina Topolovgrad', value: 'Obshtina Topolovgrad' },
  { label: 'Obshtina Treklyano', value: 'Obshtina Treklyano' },
  { label: 'Obshtina Tryavna', value: 'Obshtina Tryavna' },
  { label: 'Obshtina Tsar Kaloyan', value: 'Obshtina Tsar Kaloyan' },
  { label: 'Obshtina Tsenovo', value: 'Obshtina Tsenovo' },
  { label: 'Obshtina Tundzha', value: 'Obshtina Tundzha' },
  { label: 'Obshtina Tutrakan', value: 'Obshtina Tutrakan' },
  { label: 'Obshtina Tvarditsa', value: 'Obshtina Tvarditsa' },
  { label: 'Obshtina Ugarchin', value: 'Obshtina Ugarchin' },
  { label: 'Obshtina Valchedram', value: 'Obshtina Valchedram' },
  { label: 'Obshtina Valchidol', value: 'Obshtina Valchidol' },
  { label: 'Obshtina Varna', value: 'Obshtina Varna' },
  { label: 'Obshtina Varshets', value: 'Obshtina Varshets' },
  { label: 'Obshtina Veliko Tŭrnovo', value: 'Obshtina Veliko Tŭrnovo' },
  { label: 'Obshtina Velingrad', value: 'Obshtina Velingrad' },
  { label: 'Obshtina Vetovo', value: 'Obshtina Vetovo' },
  { label: 'Obshtina Vetrino', value: 'Obshtina Vetrino' },
  { label: 'Obshtina Vidin', value: 'Obshtina Vidin' },
  { label: 'Obshtina Vratsa', value: 'Obshtina Vratsa' },
  { label: 'Obshtina Yakimovo', value: 'Obshtina Yakimovo' },
  { label: 'Obshtina Yakoruda', value: 'Obshtina Yakoruda' },
  { label: 'Obshtina Yambol', value: 'Obshtina Yambol' },
  { label: 'Obshtina Zavet', value: 'Obshtina Zavet' },
  { label: 'Obshtina Zemen', value: 'Obshtina Zemen' },
  { label: 'Obshtina Zlataritsa', value: 'Obshtina Zlataritsa' },
  { label: 'Obshtina Zlatitsa', value: 'Obshtina Zlatitsa' },
  { label: 'Obshtina Zlatograd', value: 'Obshtina Zlatograd' },
  { label: 'Obsteig', value: 'Obsteig' },
  { label: 'Obsza', value: 'Obsza' },
  { label: 'Ōbu', value: 'Ōbu' },
  { label: 'Obuase', value: 'Obuase' },
  { label: 'Obudovac', value: 'Obudovac' },
  { label: 'Obukhiv', value: 'Obukhiv' },
  { label: 'Obukhiv Raion', value: 'Obukhiv Raion' },
  { label: 'Obukhivs’Ka Mis’Krada', value: 'Obukhivs’Ka Mis’Krada' },
  { label: 'Obukhovo', value: 'Obukhovo' },
  { label: 'Obukhovo', value: 'Obukhovo' },
  { label: 'Obukhovskoye', value: 'Obukhovskoye' },
  { label: 'Obura Wonenara', value: 'Obura Wonenara' },
  { label: 'Obuse', value: 'Obuse' },
  { label: 'Ōbu-Shi', value: 'Ōbu-Shi' },
  { label: 'Obzor', value: 'Obzor' },
  { label: 'Ocala', value: 'Ocala' },
  { label: 'Ocamonte', value: 'Ocamonte' },
  { label: 'Ocampo', value: 'Ocampo' },
  { label: 'Ocampo', value: 'Ocampo' },
  { label: 'Ocampo', value: 'Ocampo' },
  { label: 'Ocampo', value: 'Ocampo' },
  { label: 'Ocampo', value: 'Ocampo' },
  { label: 'Ocampo', value: 'Ocampo' },
  { label: 'Ocampo', value: 'Ocampo' },
  { label: 'Ocampo', value: 'Ocampo' },
  { label: 'Ocaña', value: 'Ocaña' },
  { label: 'Ocaña', value: 'Ocaña' },
  { label: 'Ocaña', value: 'Ocaña' },
  { label: 'Ocara', value: 'Ocara' },
  { label: 'Ocauçu', value: 'Ocauçu' },
  { label: 'Occagno', value: 'Occagno' },
  { label: 'Occhieppo Inferiore', value: 'Occhieppo Inferiore' },
  { label: 'Occhieppo Superiore', value: 'Occhieppo Superiore' },
  { label: 'Occhiobello', value: 'Occhiobello' },
  { label: 'Occidental', value: 'Occidental' },
  { label: 'Occidente (San Francisco)', value: 'Occidente (San Francisco)' },
  { label: 'Occimiano', value: 'Occimiano' },
  { label: 'Occoquan', value: 'Occoquan' },
  { label: 'Ocean Acres', value: 'Ocean Acres' },
  { label: 'Ocean Bluff-Brant Rock', value: 'Ocean Bluff-Brant Rock' },
  { label: 'Ocean City', value: 'Ocean City' },
  { label: 'Ocean City', value: 'Ocean City' },
  { label: 'Ocean City', value: 'Ocean City' },
  { label: 'Ocean County', value: 'Ocean County' },
  { label: 'Ocean Gate', value: 'Ocean Gate' },
  { label: 'Ocean Grove', value: 'Ocean Grove' },
  { label: 'Ocean Grove', value: 'Ocean Grove' },
  { label: 'Ocean Grove', value: 'Ocean Grove' },
  { label: 'Ocean Park', value: 'Ocean Park' },
  { label: 'Ocean Pines', value: 'Ocean Pines' },
  { label: 'Ocean Pointe', value: 'Ocean Pointe' },
  { label: 'Ocean Reef', value: 'Ocean Reef' },
  { label: 'Ocean Ridge', value: 'Ocean Ridge' },
  { label: 'Ocean Shores', value: 'Ocean Shores' },
  { label: 'Ocean Shores', value: 'Ocean Shores' },
  { label: 'Ocean Springs', value: 'Ocean Springs' },
  { label: 'Ocean View', value: 'Ocean View' },
  { label: 'Oceana', value: 'Oceana' },
  { label: 'Oceana County', value: 'Oceana County' },
  { label: 'Oceano', value: 'Oceano' },
  { label: 'Oceanport', value: 'Oceanport' },
  { label: 'Oceanside', value: 'Oceanside' },
  { label: 'Oceanside', value: 'Oceanside' },
  { label: 'Ocelota', value: 'Ocelota' },
  { label: 'Ocentejo', value: 'Ocentejo' },
  { label: 'Och’Amch’Ire', value: 'Och’Amch’Ire' },
  { label: 'Ochaby', value: 'Ochaby' },
  { label: 'Ochagavía/Otsagabia', value: 'Ochagavía/Otsagabia' },
  { label: 'Ochakiv', value: 'Ochakiv' },
  { label: 'Ochakovo-Matveyevskoye', value: 'Ochakovo-Matveyevskoye' },
  { label: 'Ochanado', value: 'Ochanado' },
  { label: 'Ochánduri', value: 'Ochánduri' },
  { label: 'Ochër', value: 'Ochër' },
  { label: 'Ochi', value: 'Ochi' },
  { label: 'Ochiltree', value: 'Ochiltree' },
  { label: 'Ochiltree County', value: 'Ochiltree County' },
  { label: 'Ochkhamuri', value: 'Ochkhamuri' },
  { label: 'Ochla', value: 'Ochla' },
  { label: 'Ocho De Enero', value: 'Ocho De Enero' },
  { label: 'Ocho Rios', value: 'Ocho Rios' },
  { label: 'Ochobo', value: 'Ochobo' },
  { label: 'Ochojno', value: 'Ochojno' },
  { label: 'Ochota', value: 'Ochota' },
  { label: 'Ochotnica Dolna', value: 'Ochotnica Dolna' },
  { label: 'Ochsenfurt', value: 'Ochsenfurt' },
  { label: 'Ochsenhausen', value: 'Ochsenhausen' },
  { label: 'Ochten', value: 'Ochten' },
  { label: 'Ochtendung', value: 'Ochtendung' },
  { label: 'Ochtrup', value: 'Ochtrup' },
  { label: 'Ochusjob', value: 'Ochusjob' },
  { label: 'Ocilla', value: 'Ocilla' },
  { label: 'Ocina De Sus', value: 'Ocina De Sus' },
  { label: 'Ockelbo', value: 'Ockelbo' },
  { label: 'Ockenfels', value: 'Ockenfels' },
  { label: 'Ockenheim', value: 'Ockenheim' },
  { label: 'Öckerö', value: 'Öckerö' },
  { label: 'Ocland', value: 'Ocland' },
  { label: 'Ocna De Jos', value: 'Ocna De Jos' },
  { label: 'Ocna De Sus', value: 'Ocna De Sus' },
  { label: 'Ocna Dejului', value: 'Ocna Dejului' },
  { label: 'Ocna Mureş', value: 'Ocna Mureş' },
  { label: 'Ocna Sibiului', value: 'Ocna Sibiului' },
  { label: 'Ocna Şugatag', value: 'Ocna Şugatag' },
  { label: 'Ocniţa', value: 'Ocniţa' },
  { label: 'Ocniţa', value: 'Ocniţa' },
  { label: 'Ocnița', value: 'Ocnița' },
  { label: 'Oco', value: 'Oco' },
  { label: 'Ococh', value: 'Ococh' },
  { label: 'Ocoee', value: 'Ocoee' },
  { label: 'Ocoliş', value: 'Ocoliş' },
  { label: 'Ocoliș', value: 'Ocoliș' },
  { label: 'Ocolna', value: 'Ocolna' },
  { label: 'Ocón', value: 'Ocón' },
  { label: 'Oconahua Jalisco', value: 'Oconahua Jalisco' },
  { label: 'Oconee County', value: 'Oconee County' },
  { label: 'Oconee County', value: 'Oconee County' },
  { label: 'Oconnor', value: 'Oconnor' },
  { label: 'Oconomowoc', value: 'Oconomowoc' },
  { label: 'Oconto', value: 'Oconto' },
  { label: 'Oconto County', value: 'Oconto County' },
  { label: 'Oconto Falls', value: 'Oconto Falls' },
  { label: 'Ocopulco', value: 'Ocopulco' },
  { label: 'Ocós', value: 'Ocós' },
  { label: 'Ocosingo', value: 'Ocosingo' },
  { label: 'Ocotal', value: 'Ocotal' },
  { label: 'Ocotal Chico', value: 'Ocotal Chico' },
  { label: 'Ocote Paulino', value: 'Ocote Paulino' },
  { label: 'Ocotepec', value: 'Ocotepec' },
  { label: 'Ocotepec', value: 'Ocotepec' },
  { label: 'Ocotequila', value: 'Ocotequila' },
  { label: 'Ocotillo', value: 'Ocotillo' },
  { label: 'Ocotitlán', value: 'Ocotitlán' },
  { label: 'Ocotlamanic', value: 'Ocotlamanic' },
  { label: 'Ocotlán', value: 'Ocotlán' },
  { label: 'Ocotlán De Betancourt', value: 'Ocotlán De Betancourt' },
  { label: 'Ocotlán De Morelos', value: 'Ocotlán De Morelos' },
  { label: 'Ocotlán De Venustiano Carranza', value: 'Ocotlán De Venustiano Carranza' },
  { label: 'Ocotlán Jalisco', value: 'Ocotlán Jalisco' },
  { label: 'Ocoxaltepec', value: 'Ocoxaltepec' },
  { label: 'Ocoy', value: 'Ocoy' },
  { label: 'Ocoyoacac', value: 'Ocoyoacac' },
  { label: 'Ocozocoautla De Espinosa', value: 'Ocozocoautla De Espinosa' },
  { label: 'Ocozotepec', value: 'Ocozotepec' },
  { label: 'Ócsa', value: 'Ócsa' },
  { label: 'Őcsény', value: 'Őcsény' },
  { label: 'Öcsöd', value: 'Öcsöd' },
  { label: 'Octaviano López', value: 'Octaviano López' },
  { label: 'Octavio Paz', value: 'Octavio Paz' },
  { label: 'Octeville', value: 'Octeville' },
  { label: 'Octeville-Sur-Mer', value: 'Octeville-Sur-Mer' },
  { label: 'Octeyuco Dos Mil', value: 'Octeyuco Dos Mil' },
  { label: 'Ocú', value: 'Ocú' },
  { label: 'Ocuapan', value: 'Ocuapan' },
  { label: 'Ocuilan', value: 'Ocuilan' },
  { label: 'Ocuilan De Arteaga', value: 'Ocuilan De Arteaga' },
  { label: 'Ocuiltzapoyo', value: 'Ocuiltzapoyo' },
  { label: 'Ocuituco', value: 'Ocuituco' },
  { label: 'Ocumare Del Tuy', value: 'Ocumare Del Tuy' },
  { label: 'Ocumicho', value: 'Ocumicho' },
  { label: 'Ocurio', value: 'Ocurio' },
  { label: 'Ōda Shi', value: 'Ōda Shi' },
  { label: 'Ōdachō-Ōda', value: 'Ōdachō-Ōda' },
  { label: 'Odadar', value: 'Odadar' },
  { label: 'Odaia Manolache', value: 'Odaia Manolache' },
  { label: 'Odaia Turcului', value: 'Odaia Turcului' },
  { label: 'Odăile', value: 'Odăile' },
  { label: 'Ödåkra', value: 'Ödåkra' },
  { label: 'Odala', value: 'Odala' },
  { label: 'Odalengo Grande', value: 'Odalengo Grande' },
  { label: 'Odalengo Piccolo', value: 'Odalengo Piccolo' },
  { label: 'Odariukivka', value: 'Odariukivka' },
  { label: 'Ōdate', value: 'Ōdate' },
  { label: 'Ōdate-Shi', value: 'Ōdate-Shi' },
  { label: 'Odawara', value: 'Odawara' },
  { label: 'Odawara-Shi', value: 'Odawara-Shi' },
  { label: 'Odda', value: 'Odda' },
  { label: 'Odder', value: 'Odder' },
  { label: 'Odder Kommune', value: 'Odder Kommune' },
  { label: 'Ode', value: 'Ode' },
  { label: 'Odell', value: 'Odell' },
  { label: 'Odelzhausen', value: 'Odelzhausen' },
  { label: 'Odem', value: 'Odem' },
  { label: 'Odemira', value: 'Odemira' },
  { label: 'Ödemiş', value: 'Ödemiş' },
  { label: 'Odèn', value: 'Odèn' },
  { label: 'Òdena', value: 'Òdena' },
  { label: 'Odensbacken', value: 'Odensbacken' },
  { label: 'Odense', value: 'Odense' },
  { label: 'Odense Kommune', value: 'Odense Kommune' },
  { label: 'Odensjö', value: 'Odensjö' },
  { label: 'Odenthal', value: 'Odenthal' },
  { label: 'Odenton', value: 'Odenton' },
  { label: 'Odenville', value: 'Odenville' },
  { label: 'Oderberg', value: 'Oderberg' },
  { label: 'Oderen', value: 'Oderen' },
  { label: 'Odernheim', value: 'Odernheim' },
  { label: 'Oderzo', value: 'Oderzo' },
  { label: 'Odesa', value: 'Odesa' },
  { label: 'Ödeshög', value: 'Ödeshög' },
  { label: 'Odessa', value: 'Odessa' },
  { label: 'Odessa', value: 'Odessa' },
  { label: 'Odessa', value: 'Odessa' },
  { label: 'Odesskoye', value: 'Odesskoye' },
  { label: 'Odești', value: 'Odești' },
  { label: 'Odicon', value: 'Odicon' },
  { label: 'Odienné', value: 'Odienné' },
  { label: 'Odieta', value: 'Odieta' },
  { label: 'Odiham', value: 'Odiham' },
  { label: 'Odijk', value: 'Odijk' },
  { label: 'Odiliapeel', value: 'Odiliapeel' },
  { label: 'Odin', value: 'Odin' },
  { label: 'Odintsovo', value: 'Odintsovo' },
  { label: 'Odintsovskiy Rayon', value: 'Odintsovskiy Rayon' },
  { label: 'Odiong', value: 'Odiong' },
  { label: 'Odiong', value: 'Odiong' },
  { label: 'Odiongan', value: 'Odiongan' },
  { label: 'Odiongan', value: 'Odiongan' },
  { label: 'Odivelas', value: 'Odivelas' },
  { label: 'Odivelas', value: 'Odivelas' },
  { label: 'Odivelas Municipality', value: 'Odivelas Municipality' },
  { label: 'Odlabari', value: 'Odlabari' },
  { label: 'Odobeşti', value: 'Odobeşti' },
  { label: 'Odobeşti', value: 'Odobeşti' },
  { label: 'Odobești', value: 'Odobești' },
  { label: 'Odolanów', value: 'Odolanów' },
  { label: 'Odolena Voda', value: 'Odolena Voda' },
  { label: 'Odolo', value: 'Odolo' },
  { label: 'Odon', value: 'Odon' },
  { label: 'Odón', value: 'Odón' },
  { label: 'Odoorn', value: 'Odoorn' },
  { label: 'Odoreu', value: 'Odoreu' },
  { label: 'Odorheiu Secuiesc', value: 'Odorheiu Secuiesc' },
  { label: 'Odos', value: 'Odos' },
  { label: 'Odoyev', value: 'Odoyev' },
  { label: 'Odra', value: 'Odra' },
  { label: 'Odranci', value: 'Odranci' },
  { label: 'Odrihei', value: 'Odrihei' },
  { label: 'Odry', value: 'Odry' },
  { label: 'Odrzykoń', value: 'Odrzykoń' },
  { label: 'Odrzywół', value: 'Odrzywół' },
  { label: 'Odsan', value: 'Odsan' },
  { label: 'Odsherred Kommune', value: 'Odsherred Kommune' },
  { label: 'Ödsmål', value: 'Ödsmål' },
  { label: 'Odugattur', value: 'Odugattur' },
  { label: 'Odunpazarı', value: 'Odunpazarı' },
  { label: 'Odžak', value: 'Odžak' },
  { label: 'Odzi', value: 'Odzi' },
  { label: 'Odzun', value: 'Odzun' },
  { label: 'Oebisfelde', value: 'Oebisfelde' },
  { label: 'Oederan', value: 'Oederan' },
  { label: 'Oederquart', value: 'Oederquart' },
  { label: 'Oedheim', value: 'Oedheim' },
  { label: 'Oedt', value: 'Oedt' },
  { label: 'Oegstgeest', value: 'Oegstgeest' },
  { label: 'Oehling', value: 'Oehling' },
  { label: 'Oeiras', value: 'Oeiras' },
  { label: 'Oeiras', value: 'Oeiras' },
  { label: 'Oeiras', value: 'Oeiras' },
  { label: 'Oeiras Do Pará', value: 'Oeiras Do Pará' },
  { label: 'Oeiras E São Julião Da Barra', value: 'Oeiras E São Julião Da Barra' },
  { label: 'Oelde', value: 'Oelde' },
  { label: 'Oelixdorf', value: 'Oelixdorf' },
  { label: 'Oelsnitz', value: 'Oelsnitz' },
  { label: 'Oelwein', value: 'Oelwein' },
  { label: 'Oencia', value: 'Oencia' },
  { label: 'Oensingen', value: 'Oensingen' },
  { label: 'Oentsjerk', value: 'Oentsjerk' },
  { label: 'Oepping', value: 'Oepping' },
  { label: 'Oerel', value: 'Oerel' },
  { label: 'Oer-Erkenschwick', value: 'Oer-Erkenschwick' },
  { label: 'Oerle', value: 'Oerle' },
  { label: 'Oerlenbach', value: 'Oerlenbach' },
  { label: 'Oerlinghausen', value: 'Oerlinghausen' },
  { label: 'Oermingen', value: 'Oermingen' },
  { label: 'Oeștii Pământeni', value: 'Oeștii Pământeni' },
  { label: 'Œting', value: 'Œting' },
  { label: 'Oettingen In Bayern', value: 'Oettingen In Bayern' },
  { label: 'Oetwil', value: 'Oetwil' },
  { label: 'Oetz', value: 'Oetz' },
  { label: 'Oeversee', value: 'Oeversee' },
  { label: 'Oeynhausen', value: 'Oeynhausen' },
  { label: 'Oeyreluy', value: 'Oeyreluy' },
  { label: 'Of', value: 'Of' },
  { label: 'Ofallon', value: 'Ofallon' },
  { label: 'Ofallon', value: 'Ofallon' },
  { label: 'Ofaqim', value: 'Ofaqim' },
  { label: 'Ófehértó', value: 'Ófehértó' },
  { label: 'Ofena', value: 'Ofena' },
  { label: 'Offa', value: 'Offa' },
  { label: 'Offagna', value: 'Offagna' },
  { label: 'Offanengo', value: 'Offanengo' },
  { label: 'Offemont', value: 'Offemont' },
  { label: 'Offenau', value: 'Offenau' },
  { label: 'Offenbach', value: 'Offenbach' },
  { label: 'Offenbach An Der Queich', value: 'Offenbach An Der Queich' },
  { label: 'Offenbach-Hundheim', value: 'Offenbach-Hundheim' },
  { label: 'Offenbeek', value: 'Offenbeek' },
  { label: 'Offenberg', value: 'Offenberg' },
  { label: 'Offenburg', value: 'Offenburg' },
  { label: 'Offenhausen', value: 'Offenhausen' },
  { label: 'Offenhausen', value: 'Offenhausen' },
  { label: 'Offenheim', value: 'Offenheim' },
  { label: 'Officer', value: 'Officer' },
  { label: 'Offida', value: 'Offida' },
  { label: 'Offingen', value: 'Offingen' },
  { label: 'Offlaga', value: 'Offlaga' },
  { label: 'Offranville', value: 'Offranville' },
  { label: 'Offstein', value: 'Offstein' },
  { label: 'Offutt Air Force Base', value: 'Offutt Air Force Base' },
  { label: 'Ofterdingen', value: 'Ofterdingen' },
  { label: 'Ofterschwang', value: 'Ofterschwang' },
  { label: 'Oftersheim', value: 'Oftersheim' },
  { label: 'Oftringen', value: 'Oftringen' },
  { label: 'Ōfunato', value: 'Ōfunato' },
  { label: 'Ōfunato-Shi', value: 'Ōfunato-Shi' },
  { label: 'Oga', value: 'Oga' },
  { label: 'Ōgaki-Shi', value: 'Ōgaki-Shi' },
  { label: 'Ogallala', value: 'Ogallala' },
  { label: 'Ogaminana', value: 'Ogaminana' },
  { label: 'Oga-Shi', value: 'Oga-Shi' },
  { label: 'Ogassa', value: 'Ogassa' },
  { label: 'Ogawa', value: 'Ogawa' },
  { label: 'Ogawa', value: 'Ogawa' },
  { label: 'Ogba', value: 'Ogba' },
  { label: 'Ogbomoso', value: 'Ogbomoso' },
  { label: 'Ogden', value: 'Ogden' },
  { label: 'Ogden', value: 'Ogden' },
  { label: 'Ogden', value: 'Ogden' },
  { label: 'Ogden', value: 'Ogden' },
  { label: 'Ogden Dunes', value: 'Ogden Dunes' },
  { label: 'Ogdensburg', value: 'Ogdensburg' },
  { label: 'Ogdensburg', value: 'Ogdensburg' },
  { label: 'Ogemaw County', value: 'Ogemaw County' },
  { label: 'Ogembo', value: 'Ogembo' },
  { label: 'Ogeu-Les-Bains', value: 'Ogeu-Les-Bains' },
  { label: 'Oggau', value: 'Oggau' },
  { label: 'Oggebbio', value: 'Oggebbio' },
  { label: 'Oggiona-Santo Stefano', value: 'Oggiona-Santo Stefano' },
  { label: 'Oggiono', value: 'Oggiono' },
  { label: 'Ogíjares', value: 'Ogíjares' },
  { label: 'Ogi-Shi', value: 'Ogi-Shi' },
  { label: 'Oglala', value: 'Oglala' },
  { label: 'Oglala Lakota County', value: 'Oglala Lakota County' },
  { label: 'Oğlanqala', value: 'Oğlanqala' },
  { label: 'Ogle County', value: 'Ogle County' },
  { label: 'Oglesby', value: 'Oglesby' },
  { label: 'Oglethorpe', value: 'Oglethorpe' },
  { label: 'Oglethorpe County', value: 'Oglethorpe County' },
  { label: 'Oglianico', value: 'Oglianico' },
  { label: 'Ogliastro Cilento', value: 'Ogliastro Cilento' },
  { label: 'Oglinzi', value: 'Oglinzi' },
  { label: 'Ogmore Vale', value: 'Ogmore Vale' },
  { label: 'Ognes', value: 'Ognes' },
  { label: 'Ognevka', value: 'Ognevka' },
  { label: 'Ogod', value: 'Ogod' },
  { label: 'Ogōri-Shi', value: 'Ogōri-Shi' },
  { label: 'Ogōri-Shimogō', value: 'Ogōri-Shimogō' },
  { label: 'Ogra', value: 'Ogra' },
  { label: 'Ograda', value: 'Ograda' },
  { label: 'Ogre', value: 'Ogre' },
  { label: 'Ogretin', value: 'Ogretin' },
  { label: 'Ogrezeni', value: 'Ogrezeni' },
  { label: 'Ogrodzieniec', value: 'Ogrodzieniec' },
  { label: 'Ogtongon', value: 'Ogtongon' },
  { label: 'Ogunquit', value: 'Ogunquit' },
  { label: 'Ogurugu', value: 'Ogurugu' },
  { label: 'Oguta', value: 'Oguta' },
  { label: 'Oğuz', value: 'Oğuz' },
  { label: 'Oğuzeli', value: 'Oğuzeli' },
  { label: 'Oğuzlar', value: 'Oğuzlar' },
  { label: 'Ogwashi-Uku', value: 'Ogwashi-Uku' },
  { label: 'Ohaba', value: 'Ohaba' },
  { label: 'Ohaba Lungă', value: 'Ohaba Lungă' },
  { label: 'Ohafia-Ifigh', value: 'Ohafia-Ifigh' },
  { label: 'Ohanes', value: 'Ohanes' },
  { label: 'Ohangaron', value: 'Ohangaron' },
  { label: 'Ōhara', value: 'Ōhara' },
  { label: 'Ohatchee', value: 'Ohatchee' },
  { label: 'Ohey', value: 'Ohey' },
  { label: 'Ohiggins', value: 'Ohiggins' },
  { label: 'Ohio County', value: 'Ohio County' },
  { label: 'Ohio County', value: 'Ohio County' },
  { label: 'Ohio County', value: 'Ohio County' },
  { label: 'Ohioville', value: 'Ohioville' },
  { label: 'Ohkay Owingeh', value: 'Ohkay Owingeh' },
  { label: 'Ohlsbach', value: 'Ohlsbach' },
  { label: 'Ohlsdorf', value: 'Ohlsdorf' },
  { label: 'Ohlsdorf', value: 'Ohlsdorf' },
  { label: 'Ohlstadt', value: 'Ohlstadt' },
  { label: 'Ohlungen', value: 'Ohlungen' },
  { label: 'Ohmden', value: 'Ohmden' },
  { label: 'Öhningen', value: 'Öhningen' },
  { label: 'Ohonua', value: 'Ohonua' },
  { label: 'Ohorn', value: 'Ohorn' },
  { label: 'Ohrazenice', value: 'Ohrazenice' },
  { label: 'Ohrid', value: 'Ohrid' },
  { label: 'Ohrid Opština', value: 'Ohrid Opština' },
  { label: 'Öhringen', value: 'Öhringen' },
  { label: 'Ohuapan', value: 'Ohuapan' },
  { label: 'Ohuatipa', value: 'Ohuatipa' },
  { label: 'Ohuilapam', value: 'Ohuilapam' },
  { label: 'Ōi', value: 'Ōi' },
  { label: 'Oia', value: 'Oia' },
  { label: 'Oiã', value: 'Oiã' },
  { label: 'Oía', value: 'Oía' },
  { label: 'Oiapoque', value: 'Oiapoque' },
  { label: 'Oiartzun', value: 'Oiartzun' },
  { label: 'Oiba', value: 'Oiba' },
  { label: 'Oicatá', value: 'Oicatá' },
  { label: 'Oichalía', value: 'Oichalía' },
  { label: 'Oignies', value: 'Oignies' },
  { label: 'Ōi-Gun', value: 'Ōi-Gun' },
  { label: 'Oil City', value: 'Oil City' },
  { label: 'Oildale', value: 'Oildale' },
  { label: 'Oilton', value: 'Oilton' },
  { label: 'Oimbra', value: 'Oimbra' },
  { label: 'Oinacu', value: 'Oinacu' },
  { label: 'Oinófyta', value: 'Oinófyta' },
  { label: 'Oinville-Sur-Montcient', value: 'Oinville-Sur-Montcient' },
  { label: 'Oirlo', value: 'Oirlo' },
  { label: 'Oirsbeek', value: 'Oirsbeek' },
  { label: 'Oirschot', value: 'Oirschot' },
  { label: 'Oisemont', value: 'Oisemont' },
  { label: 'Ōiso', value: 'Ōiso' },
  { label: 'Oisseau', value: 'Oisseau' },
  { label: 'Oissel', value: 'Oissel' },
  { label: 'Oissery', value: 'Oissery' },
  { label: 'Oisterwijk', value: 'Oisterwijk' },
  { label: 'Oistins', value: 'Oistins' },
  { label: 'Oisy-Le-Verger', value: 'Oisy-Le-Verger' },
  { label: 'Ōita', value: 'Ōita' },
  { label: 'Ōita-Shi', value: 'Ōita-Shi' },
  { label: 'Oituz', value: 'Oituz' },
  { label: 'Oitz', value: 'Oitz' },
  { label: 'Ojacastro', value: 'Ojacastro' },
  { label: 'Ojai', value: 'Ojai' },
  { label: 'Ojaq', value: 'Ojaq' },
  { label: 'Ojdula', value: 'Ojdula' },
  { label: 'Ojén', value: 'Ojén' },
  { label: 'Öjersjö', value: 'Öjersjö' },
  { label: 'Ojinaga', value: 'Ojinaga' },
  { label: 'Ojite Rancho Nuevo', value: 'Ojite Rancho Nuevo' },
  { label: 'Ojitos', value: 'Ojitos' },
  { label: 'Ojiya', value: 'Ojiya' },
  { label: 'Ojiya-Shi', value: 'Ojiya-Shi' },
  { label: 'Ojo', value: 'Ojo' },
  { label: 'Ojo Caliente', value: 'Ojo Caliente' },
  { label: 'Ojo De Agua', value: 'Ojo De Agua' },
  { label: 'Ojo De Agua', value: 'Ojo De Agua' },
  { label: 'Ojo De Agua', value: 'Ojo De Agua' },
  { label: 'Ojo De Agua', value: 'Ojo De Agua' },
  { label: 'Ojo De Agua', value: 'Ojo De Agua' },
  { label: 'Ojo De Agua', value: 'Ojo De Agua' },
  { label: 'Ojo De Agua De Ballesteros', value: 'Ojo De Agua De Ballesteros' },
  { label: 'Ojo De Agua De Bucio', value: 'Ojo De Agua De Bucio' },
  { label: 'Ojo De Agua De Crucitas', value: 'Ojo De Agua De Crucitas' },
  { label: 'Ojo De Agua De La Trinidad', value: 'Ojo De Agua De La Trinidad' },
  { label: 'Ojo De Agua De Mendoza', value: 'Ojo De Agua De Mendoza' },
  { label: 'Ojo De Agua De Morán Jalisco', value: 'Ojo De Agua De Morán Jalisco' },
  { label: 'Ojo De Agua De Palmillas', value: 'Ojo De Agua De Palmillas' },
  { label: 'Ojo De Agua De Solano', value: 'Ojo De Agua De Solano' },
  { label: 'Ojo De Agua Del Refugio', value: 'Ojo De Agua Del Refugio' },
  { label: 'Ojo De Rana', value: 'Ojo De Rana' },
  { label: 'Ojo Seco', value: 'Ojo Seco' },
  { label: 'Ojocaliente', value: 'Ojocaliente' },
  { label: 'Ojodu Berger', value: 'Ojodu Berger' },
  { label: 'Ojojona', value: 'Ojojona' },
  { label: 'Ojós', value: 'Ojós' },
  { label: 'Ojos De Agua', value: 'Ojos De Agua' },
  { label: 'Ojos De Agua', value: 'Ojos De Agua' },
  { label: 'Ojos Negros', value: 'Ojos Negros' },
  { label: 'Ojos-Albos', value: 'Ojos-Albos' },
  { label: 'Ojota', value: 'Ojota' },
  { label: 'Ojrzeń', value: 'Ojrzeń' },
  { label: 'Ojuelos De Jalisco', value: 'Ojuelos De Jalisco' },
  { label: 'Ojus', value: 'Ojus' },
  { label: 'Oka', value: 'Oka' },
  { label: 'Okahandja', value: 'Okahandja' },
  { label: 'Okahao', value: 'Okahao' },
  { label: 'Okakarara', value: 'Okakarara' },
  { label: 'Okaloosa County', value: 'Okaloosa County' },
  { label: 'Okanagan', value: 'Okanagan' },
  { label: 'Okanagan Falls', value: 'Okanagan Falls' },
  { label: 'Okanogan', value: 'Okanogan' },
  { label: 'Okanogan County', value: 'Okanogan County' },
  { label: 'Okány', value: 'Okány' },
  { label: 'Okap', value: 'Okap' },
  { label: 'Okapa', value: 'Okapa' },
  { label: 'Okara', value: 'Okara' },
  { label: 'Okarche', value: 'Okarche' },
  { label: 'Okauchee Lake', value: 'Okauchee Lake' },
  { label: 'Ōkawa', value: 'Ōkawa' },
  { label: 'Ōkawa', value: 'Ōkawa' },
  { label: 'Ōkawara', value: 'Ōkawara' },
  { label: 'Ōkawa-Shi', value: 'Ōkawa-Shi' },
  { label: 'Okawville', value: 'Okawville' },
  { label: 'Okaya', value: 'Okaya' },
  { label: 'Okaya', value: 'Okaya' },
  { label: 'Okaya Shi', value: 'Okaya Shi' },
  { label: 'Okayama', value: 'Okayama' },
  { label: 'Okayama Shi', value: 'Okayama Shi' },
  { label: 'Okazaki-Shi', value: 'Okazaki-Shi' },
  { label: 'Okcheon', value: 'Okcheon' },
  { label: 'Okcheon-Gun', value: 'Okcheon-Gun' },
  { label: 'Oke Ila', value: 'Oke Ila' },
  { label: 'Oke Mesi', value: 'Oke Mesi' },
  { label: 'Okeechobee', value: 'Okeechobee' },
  { label: 'Okeechobee County', value: 'Okeechobee County' },
  { label: 'Okeene', value: 'Okeene' },
  { label: 'Okegawa', value: 'Okegawa' },
  { label: 'Okegawa-Shi', value: 'Okegawa-Shi' },
  { label: 'Okehampton', value: 'Okehampton' },
  { label: 'Okeho', value: 'Okeho' },
  { label: 'Okemah', value: 'Okemah' },
  { label: 'Okemos', value: 'Okemos' },
  { label: 'Okene', value: 'Okene' },
  { label: 'Okfuskee County', value: 'Okfuskee County' },
  { label: 'Okha', value: 'Okha' },
  { label: 'Okha', value: 'Okha' },
  { label: 'Okhaldhunga', value: 'Okhaldhunga' },
  { label: 'Okhansk', value: 'Okhansk' },
  { label: 'Okhotnikovo', value: 'Okhotnikovo' },
  { label: 'Okhotsk', value: 'Okhotsk' },
  { label: 'Okhotskoye', value: 'Okhotskoye' },
  { label: 'Okhtyrka', value: 'Okhtyrka' },
  { label: 'Okigwe', value: 'Okigwe' },
  { label: 'Okinawa', value: 'Okinawa' },
  { label: 'Okinawa Número Uno', value: 'Okinawa Número Uno' },
  { label: 'Okinawa Shi', value: 'Okinawa Shi' },
  { label: 'Okino-Klyuchi', value: 'Okino-Klyuchi' },
  { label: 'Okinskiy Rayon', value: 'Okinskiy Rayon' },
  { label: 'Okiot', value: 'Okiot' },
  { label: 'Oklahoma City', value: 'Oklahoma City' },
  { label: 'Oklahoma County', value: 'Oklahoma County' },
  { label: 'Okmulgee', value: 'Okmulgee' },
  { label: 'Okmulgee County', value: 'Okmulgee County' },
  { label: 'Okny', value: 'Okny' },
  { label: 'Okoa', value: 'Okoa' },
  { label: 'Okocim', value: 'Okocim' },
  { label: 'Okola', value: 'Okola' },
  { label: 'Okolona', value: 'Okolona' },
  { label: 'Okolona', value: 'Okolona' },
  { label: 'Okondja', value: 'Okondja' },
  { label: 'Okondo', value: 'Okondo' },
  { label: 'Okonek', value: 'Okonek' },
  { label: 'Okoneshnikovo', value: 'Okoneshnikovo' },
  { label: 'Ököritófülpös', value: 'Ököritófülpös' },
  { label: 'Okotoks', value: 'Okotoks' },
  { label: 'Okres Bánovce Nad Bebravou', value: 'Okres Bánovce Nad Bebravou' },
  { label: 'Okres Banská Bystrica', value: 'Okres Banská Bystrica' },
  { label: 'Okres Banská Štiavnica', value: 'Okres Banská Štiavnica' },
  { label: 'Okres Bardejov', value: 'Okres Bardejov' },
  { label: 'Okres Benešov', value: 'Okres Benešov' },
  { label: 'Okres Beroun', value: 'Okres Beroun' },
  { label: 'Okres Blansko', value: 'Okres Blansko' },
  { label: 'Okres Bratislava I', value: 'Okres Bratislava I' },
  { label: 'Okres Bratislava Ii', value: 'Okres Bratislava Ii' },
  { label: 'Okres Bratislava Iii', value: 'Okres Bratislava Iii' },
  { label: 'Okres Bratislava Iv', value: 'Okres Bratislava Iv' },
  { label: 'Okres Bratislava V', value: 'Okres Bratislava V' },
  { label: 'Okres Břeclav', value: 'Okres Břeclav' },
  { label: 'Okres Brezno', value: 'Okres Brezno' },
  { label: 'Okres Brno-Venkov', value: 'Okres Brno-Venkov' },
  { label: 'Okres Bruntál', value: 'Okres Bruntál' },
  { label: 'Okres Bytča', value: 'Okres Bytča' },
  { label: 'Okres Čadca', value: 'Okres Čadca' },
  { label: 'Okres Česká Lípa', value: 'Okres Česká Lípa' },
  { label: 'Okres České Budějovice', value: 'Okres České Budějovice' },
  { label: 'Okres Český Krumlov', value: 'Okres Český Krumlov' },
  { label: 'Okres Cheb', value: 'Okres Cheb' },
  { label: 'Okres Chomutov', value: 'Okres Chomutov' },
  { label: 'Okres Chrudim', value: 'Okres Chrudim' },
  { label: 'Okres Děčín', value: 'Okres Děčín' },
  { label: 'Okres Detva', value: 'Okres Detva' },
  { label: 'Okres Dolný Kubín', value: 'Okres Dolný Kubín' },
  { label: 'Okres Domažlice', value: 'Okres Domažlice' },
  { label: 'Okres Dunajská Streda', value: 'Okres Dunajská Streda' },
  { label: 'Okres Frýdek-Místek', value: 'Okres Frýdek-Místek' },
  { label: 'Okres Galanta', value: 'Okres Galanta' },
  { label: 'Okres Gelnica', value: 'Okres Gelnica' },
  { label: 'Okres Havlíčkův Brod', value: 'Okres Havlíčkův Brod' },
  { label: 'Okres Hlohovec', value: 'Okres Hlohovec' },
  { label: 'Okres Hodonín', value: 'Okres Hodonín' },
  { label: 'Okres Hradec Králové', value: 'Okres Hradec Králové' },
  { label: 'Okres Humenné', value: 'Okres Humenné' },
  { label: 'Okres Ilava', value: 'Okres Ilava' },
  { label: 'Okres Jablonec Nad Nisou', value: 'Okres Jablonec Nad Nisou' },
  { label: 'Okres Jeseník', value: 'Okres Jeseník' },
  { label: 'Okres Jičín', value: 'Okres Jičín' },
  { label: 'Okres Jihlava', value: 'Okres Jihlava' },
  { label: 'Okres Jindřichův Hradec', value: 'Okres Jindřichův Hradec' },
  { label: 'Okres Karlovy Vary', value: 'Okres Karlovy Vary' },
  { label: 'Okres Karviná', value: 'Okres Karviná' },
  { label: 'Okres Kežmarok', value: 'Okres Kežmarok' },
  { label: 'Okres Kladno', value: 'Okres Kladno' },
  { label: 'Okres Klatovy', value: 'Okres Klatovy' },
  { label: 'Okres Kolín', value: 'Okres Kolín' },
  { label: 'Okres Komárno', value: 'Okres Komárno' },
  { label: 'Okres Kosice-Okolie', value: 'Okres Kosice-Okolie' },
  { label: 'Okres Kroměříž', value: 'Okres Kroměříž' },
  { label: 'Okres Krupina', value: 'Okres Krupina' },
  { label: 'Okres Kutná Hora', value: 'Okres Kutná Hora' },
  { label: 'Okres Kysucké Nové Mesto', value: 'Okres Kysucké Nové Mesto' },
  { label: 'Okres Levice', value: 'Okres Levice' },
  { label: 'Okres Levoča', value: 'Okres Levoča' },
  { label: 'Okres Liberec', value: 'Okres Liberec' },
  { label: 'Okres Liptovský Mikuláš', value: 'Okres Liptovský Mikuláš' },
  { label: 'Okres Litoměřice', value: 'Okres Litoměřice' },
  { label: 'Okres Louny', value: 'Okres Louny' },
  { label: 'Okres Lučenec', value: 'Okres Lučenec' },
  { label: 'Okres Malacky', value: 'Okres Malacky' },
  { label: 'Okres Martin', value: 'Okres Martin' },
  { label: 'Okres Medzilaborce', value: 'Okres Medzilaborce' },
  { label: 'Okres Mělník', value: 'Okres Mělník' },
  { label: 'Okres Michalovce', value: 'Okres Michalovce' },
  { label: 'Okres Mladá Boleslav', value: 'Okres Mladá Boleslav' },
  { label: 'Okres Most', value: 'Okres Most' },
  { label: 'Okres Myjava', value: 'Okres Myjava' },
  { label: 'Okres Náchod', value: 'Okres Náchod' },
  { label: 'Okres Namestovo', value: 'Okres Namestovo' },
  { label: 'Okres Nitra', value: 'Okres Nitra' },
  { label: 'Okres Nové Mesto Nad Váhom', value: 'Okres Nové Mesto Nad Váhom' },
  { label: 'Okres Nové Zámky', value: 'Okres Nové Zámky' },
  { label: 'Okres Nový Jičín', value: 'Okres Nový Jičín' },
  { label: 'Okres Nymburk', value: 'Okres Nymburk' },
  { label: 'Okres Olomouc', value: 'Okres Olomouc' },
  { label: 'Okres Opava', value: 'Okres Opava' },
  { label: 'Okres Ostrava-Město', value: 'Okres Ostrava-Město' },
  { label: 'Okres Pardubice', value: 'Okres Pardubice' },
  { label: 'Okres Partizánske', value: 'Okres Partizánske' },
  { label: 'Okres Pelhřimov', value: 'Okres Pelhřimov' },
  { label: 'Okres Pezinok', value: 'Okres Pezinok' },
  { label: 'Okres Piešťany', value: 'Okres Piešťany' },
  { label: 'Okres Písek', value: 'Okres Písek' },
  { label: 'Okres Plzeň-Jih', value: 'Okres Plzeň-Jih' },
  { label: 'Okres Plzeň-Město', value: 'Okres Plzeň-Město' },
  { label: 'Okres Plzeň-Sever', value: 'Okres Plzeň-Sever' },
  { label: 'Okres Poltár', value: 'Okres Poltár' },
  { label: 'Okres Poprad', value: 'Okres Poprad' },
  { label: 'Okres Považská Bystrica', value: 'Okres Považská Bystrica' },
  { label: 'Okres Prachatice', value: 'Okres Prachatice' },
  { label: 'Okres Praha-Východ', value: 'Okres Praha-Východ' },
  { label: 'Okres Praha-Západ', value: 'Okres Praha-Západ' },
  { label: 'Okres Přerov', value: 'Okres Přerov' },
  { label: 'Okres Prešov', value: 'Okres Prešov' },
  { label: 'Okres Příbram', value: 'Okres Příbram' },
  { label: 'Okres Prievidza', value: 'Okres Prievidza' },
  { label: 'Okres Prostějov', value: 'Okres Prostějov' },
  { label: 'Okres Púchov', value: 'Okres Púchov' },
  { label: 'Okres Rakovník', value: 'Okres Rakovník' },
  { label: 'Okres Revúca', value: 'Okres Revúca' },
  { label: 'Okres Rokycany', value: 'Okres Rokycany' },
  { label: 'Okres Rožňava', value: 'Okres Rožňava' },
  { label: 'Okres Ružomberok', value: 'Okres Ružomberok' },
  { label: 'Okres Rychnov Nad Kněžnou', value: 'Okres Rychnov Nad Kněžnou' },
  { label: 'Okres Sabinov', value: 'Okres Sabinov' },
  { label: 'Okres Šaľa', value: 'Okres Šaľa' },
  { label: 'Okres Semily', value: 'Okres Semily' },
  { label: 'Okres Senec', value: 'Okres Senec' },
  { label: 'Okres Senica', value: 'Okres Senica' },
  { label: 'Okres Skalica', value: 'Okres Skalica' },
  { label: 'Okres Snina', value: 'Okres Snina' },
  { label: 'Okres Sobrance', value: 'Okres Sobrance' },
  { label: 'Okres Sokolov', value: 'Okres Sokolov' },
  { label: 'Okres Spišská Nová Ves', value: 'Okres Spišská Nová Ves' },
  { label: 'Okres Stará Ĺubovňa', value: 'Okres Stará Ĺubovňa' },
  { label: 'Okres Strakonice', value: 'Okres Strakonice' },
  { label: 'Okres Stropkov', value: 'Okres Stropkov' },
  { label: 'Okres Šumperk', value: 'Okres Šumperk' },
  { label: 'Okres Svidník', value: 'Okres Svidník' },
  { label: 'Okres Svitavy', value: 'Okres Svitavy' },
  { label: 'Okres Tábor', value: 'Okres Tábor' },
  { label: 'Okres Tachov', value: 'Okres Tachov' },
  { label: 'Okres Teplice', value: 'Okres Teplice' },
  { label: 'Okres Topoľčany', value: 'Okres Topoľčany' },
  { label: 'Okres Třebíč', value: 'Okres Třebíč' },
  { label: 'Okres Trebišov', value: 'Okres Trebišov' },
  { label: 'Okres Trenčín', value: 'Okres Trenčín' },
  { label: 'Okres Trnava', value: 'Okres Trnava' },
  { label: 'Okres Trutnov', value: 'Okres Trutnov' },
  { label: 'Okres Turčianske Teplice', value: 'Okres Turčianske Teplice' },
  { label: 'Okres Tvrdošín', value: 'Okres Tvrdošín' },
  { label: 'Okres Uherské Hradiště', value: 'Okres Uherské Hradiště' },
  { label: 'Okres Ústí Nad Labem', value: 'Okres Ústí Nad Labem' },
  { label: 'Okres Ústí Nad Orlicí', value: 'Okres Ústí Nad Orlicí' },
  { label: 'Okres Veľký Krtíš', value: 'Okres Veľký Krtíš' },
  { label: 'Okres Vranov Nad Topľou', value: 'Okres Vranov Nad Topľou' },
  { label: 'Okres Vsetín', value: 'Okres Vsetín' },
  { label: 'Okres Vyškov', value: 'Okres Vyškov' },
  { label: 'Okres Žarnovica', value: 'Okres Žarnovica' },
  { label: 'Okres Žďár Nad Sázavou', value: 'Okres Žďár Nad Sázavou' },
  { label: 'Okres Žiar Nad Hronom', value: 'Okres Žiar Nad Hronom' },
  { label: 'Okres Žilina', value: 'Okres Žilina' },
  { label: 'Okres Zlaté Moravce', value: 'Okres Zlaté Moravce' },
  { label: 'Okres Zlín', value: 'Okres Zlín' },
  { label: 'Okres Znojmo', value: 'Okres Znojmo' },
  { label: 'Okres Zvolen', value: 'Okres Zvolen' },
  { label: 'Okříšky', value: 'Okříšky' },
  { label: 'Okrouhlice', value: 'Okrouhlice' },
  { label: 'Okrug', value: 'Okrug' },
  { label: 'Okrug Gornji', value: 'Okrug Gornji' },
  { label: 'Oksa', value: 'Oksa' },
  { label: 'Oksbøl', value: 'Oksbøl' },
  { label: 'Øksfjord', value: 'Øksfjord' },
  { label: 'Øksnes', value: 'Øksnes' },
  { label: 'Oksovskiy', value: 'Oksovskiy' },
  { label: 'Oktibbeha County', value: 'Oktibbeha County' },
  { label: 'Oktisi', value: 'Oktisi' },
  { label: 'Oktyabr’Sk', value: 'Oktyabr’Sk' },
  { label: 'Oktyabr’Skiy', value: 'Oktyabr’Skiy' },
  { label: 'Oktyabr’Skiy', value: 'Oktyabr’Skiy' },
  { label: 'Oktyabr’Skiy', value: 'Oktyabr’Skiy' },
  { label: 'Oktyabr’Skiy', value: 'Oktyabr’Skiy' },
  { label: 'Oktyabr’Skiy Rayon', value: 'Oktyabr’Skiy Rayon' },
  { label: 'Oktyabr’Skiy Rayon', value: 'Oktyabr’Skiy Rayon' },
  { label: 'Oktyabr’Skiy Rayon', value: 'Oktyabr’Skiy Rayon' },
  { label: 'Oktyabr’Skoye', value: 'Oktyabr’Skoye' },
  { label: 'Oktyabrskoye', value: 'Oktyabrskoye' },
  { label: 'Oktyabrsky', value: 'Oktyabrsky' },
  { label: 'Okučani', value: 'Okučani' },
  { label: 'Ōkuchi-Shinohara', value: 'Ōkuchi-Shinohara' },
  { label: 'Okulovka', value: 'Okulovka' },
  { label: 'Okulovskiy Rayon', value: 'Okulovskiy Rayon' },
  { label: 'Okunevka', value: 'Okunevka' },
  { label: 'Okunoya', value: 'Okunoya' },
  { label: 'Okuta', value: 'Okuta' },
  { label: 'Ōkuwa', value: 'Ōkuwa' },
  { label: 'Ol Kalou', value: 'Ol Kalou' },
  { label: 'Ol’Ga', value: 'Ol’Ga' },
  { label: 'Ol’Ginka', value: 'Ol’Ginka' },
  { label: 'Ol’Gino', value: 'Ol’Gino' },
  { label: 'Ol’Gino', value: 'Ol’Gino' },
  { label: 'Ol’Ginskaya', value: 'Ol’Ginskaya' },
  { label: 'Ol’Ginskaya', value: 'Ol’Ginskaya' },
  { label: 'Ol’Khonskiy Rayon', value: 'Ol’Khonskiy Rayon' },
  { label: 'Ol’Khovatka', value: 'Ol’Khovatka' },
  { label: 'Ol’Khovatskiy Rayon', value: 'Ol’Khovatskiy Rayon' },
  { label: 'Ol’Skiy Rayon', value: 'Ol’Skiy Rayon' },
  { label: 'Ola', value: 'Ola' },
  { label: 'Ola', value: 'Ola' },
  { label: 'Olá', value: 'Olá' },
  { label: 'Olaberria', value: 'Olaberria' },
  { label: 'Ólafsvík', value: 'Ólafsvík' },
  { label: 'Olaibar', value: 'Olaibar' },
  { label: 'Olaine', value: 'Olaine' },
  { label: 'Olanchito', value: 'Olanchito' },
  { label: 'Olănești', value: 'Olănești' },
  { label: 'Olanu', value: 'Olanu' },
  { label: 'Olari', value: 'Olari' },
  { label: 'Olari', value: 'Olari' },
  { label: 'Olari', value: 'Olari' },
  { label: 'Olaria', value: 'Olaria' },
  { label: 'Olaszliszka', value: 'Olaszliszka' },
  { label: 'Olathe', value: 'Olathe' },
  { label: 'Olathe', value: 'Olathe' },
  { label: 'Oława', value: 'Oława' },
  { label: 'Olaya', value: 'Olaya' },
  { label: 'Olaya Herrera', value: 'Olaya Herrera' },
  { label: 'Olazagutía', value: 'Olazagutía' },
  { label: 'Olazti/Olazagutía', value: 'Olazti/Olazagutía' },
  { label: 'Olba', value: 'Olba' },
  { label: 'Olbendorf', value: 'Olbendorf' },
  { label: 'Olbernhau', value: 'Olbernhau' },
  { label: 'Olbersdorf', value: 'Olbersdorf' },
  { label: 'Olbia', value: 'Olbia' },
  { label: 'Olbramovice', value: 'Olbramovice' },
  { label: 'Ölbronn-Dürrn', value: 'Ölbronn-Dürrn' },
  { label: 'Olcea', value: 'Olcea' },
  { label: 'Olcenengo', value: 'Olcenengo' },
  { label: 'Olching', value: 'Olching' },
  { label: 'Olcott', value: 'Olcott' },
  { label: 'Olcuatitán', value: 'Olcuatitán' },
  { label: 'Old Bar', value: 'Old Bar' },
  { label: 'Old Basing', value: 'Old Basing' },
  { label: 'Old Beach', value: 'Old Beach' },
  { label: 'Old Bethpage', value: 'Old Bethpage' },
  { label: 'Old Bridge', value: 'Old Bridge' },
  { label: 'Old Brookville', value: 'Old Brookville' },
  { label: 'Old Denbigh', value: 'Old Denbigh' },
  { label: 'Old England', value: 'Old England' },
  { label: 'Old Erowal Bay', value: 'Old Erowal Bay' },
  { label: 'Old Fig Garden', value: 'Old Fig Garden' },
  { label: 'Old Forge', value: 'Old Forge' },
  { label: 'Old Greenwich', value: 'Old Greenwich' },
  { label: 'Old Guildford', value: 'Old Guildford' },
  { label: 'Old Harbour', value: 'Old Harbour' },
  { label: 'Old Harbour Bay', value: 'Old Harbour Bay' },
  { label: 'Old Harbour Road', value: 'Old Harbour Road' },
  { label: 'Old Harlow', value: 'Old Harlow' },
  { label: 'Old Jamestown', value: 'Old Jamestown' },
  { label: 'Old Jefferson', value: 'Old Jefferson' },
  { label: 'Old Kilcullen', value: 'Old Kilcullen' },
  { label: 'Old Kilpatrick', value: 'Old Kilpatrick' },
  { label: 'Old Leake', value: 'Old Leake' },
  { label: 'Old Mystic', value: 'Old Mystic' },
  { label: 'Old Noarlunga', value: 'Old Noarlunga' },
  { label: 'Old Orchard', value: 'Old Orchard' },
  { label: 'Old Orchard Beach', value: 'Old Orchard Beach' },
  { label: 'Old Panamao', value: 'Old Panamao' },
  { label: 'Old Reynella', value: 'Old Reynella' },
  { label: 'Old River-Winfree', value: 'Old River-Winfree' },
  { label: 'Old Saybrook', value: 'Old Saybrook' },
  { label: 'Old Saybrook Center', value: 'Old Saybrook Center' },
  { label: 'Old Shinyanga', value: 'Old Shinyanga' },
  { label: 'Old Tappan', value: 'Old Tappan' },
  { label: 'Old Toongabbie', value: 'Old Toongabbie' },
  { label: 'Old Town', value: 'Old Town' },
  { label: 'Old Westbury', value: 'Old Westbury' },
  { label: 'Old Windsor', value: 'Old Windsor' },
  { label: 'Oldbawn', value: 'Oldbawn' },
  { label: 'Oldbury', value: 'Oldbury' },
  { label: 'Oldcastle', value: 'Oldcastle' },
  { label: 'Oldeberkoop', value: 'Oldeberkoop' },
  { label: 'Oldebroek', value: 'Oldebroek' },
  { label: 'Oldehove', value: 'Oldehove' },
  { label: 'Oldemarkt', value: 'Oldemarkt' },
  { label: 'Oldenburg', value: 'Oldenburg' },
  { label: 'Oldenburg In Holstein', value: 'Oldenburg In Holstein' },
  { label: 'Oldendorf', value: 'Oldendorf' },
  { label: 'Oldendorf', value: 'Oldendorf' },
  { label: 'Oldenico', value: 'Oldenico' },
  { label: 'Oldenswort', value: 'Oldenswort' },
  { label: 'Oldenzaal', value: 'Oldenzaal' },
  { label: 'Olderdalen', value: 'Olderdalen' },
  { label: 'Oldham', value: 'Oldham' },
  { label: 'Oldham County', value: 'Oldham County' },
  { label: 'Oldham County', value: 'Oldham County' },
  { label: 'Oldisleben', value: 'Oldisleben' },
  { label: 'Oldmeldrum', value: 'Oldmeldrum' },
  { label: 'Oldřišov', value: 'Oldřišov' },
  { label: 'Olds', value: 'Olds' },
  { label: 'Oldsmar', value: 'Oldsmar' },
  { label: 'Olea', value: 'Olea' },
  { label: 'Olea', value: 'Olea' },
  { label: 'Olea De Boedo', value: 'Olea De Boedo' },
  { label: 'Olean', value: 'Olean' },
  { label: 'Olecko', value: 'Olecko' },
  { label: 'Olefirshchina', value: 'Olefirshchina' },
  { label: 'Oleggio', value: 'Oleggio' },
  { label: 'Oleggio Castello', value: 'Oleggio Castello' },
  { label: 'Oleiros', value: 'Oleiros' },
  { label: 'Oleiros', value: 'Oleiros' },
  { label: 'Olejua', value: 'Olejua' },
  { label: 'Oleksandrivka', value: 'Oleksandrivka' },
  { label: 'Oleksandrivka', value: 'Oleksandrivka' },
  { label: 'Oleksandrivka', value: 'Oleksandrivka' },
  { label: 'Oleksandriya', value: 'Oleksandriya' },
  { label: 'Oleksandriys’Ke', value: 'Oleksandriys’Ke' },
  { label: 'Olemps', value: 'Olemps' },
  { label: 'Olen', value: 'Olen' },
  { label: 'Ølen', value: 'Ølen' },
  { label: 'Olenegorsk', value: 'Olenegorsk' },
  { label: 'Olenevka', value: 'Olenevka' },
  { label: 'Olenino', value: 'Olenino' },
  { label: 'Olenyok', value: 'Olenyok' },
  { label: 'Olenyoksky District', value: 'Olenyoksky District' },
  { label: 'Óleo', value: 'Óleo' },
  { label: 'Olèrdola', value: 'Olèrdola' },
  { label: 'Olesa De Bonesvalls', value: 'Olesa De Bonesvalls' },
  { label: 'Olesa De Montserrat', value: 'Olesa De Montserrat' },
  { label: 'Oleșești', value: 'Oleșești' },
  { label: 'Oleshnyk', value: 'Oleshnyk' },
  { label: 'Olesko', value: 'Olesko' },
  { label: 'Oleśnica', value: 'Oleśnica' },
  { label: 'Oleśnica', value: 'Oleśnica' },
  { label: 'Olešnice', value: 'Olešnice' },
  { label: 'Olesno', value: 'Olesno' },
  { label: 'Olesno', value: 'Olesno' },
  { label: 'Oleszyce', value: 'Oleszyce' },
  { label: 'Oletta', value: 'Oletta' },
  { label: 'Olevano Di Lomellina', value: 'Olevano Di Lomellina' },
  { label: 'Olevano Romano', value: 'Olevano Romano' },
  { label: 'Olevano Sul Tusciano', value: 'Olevano Sul Tusciano' },
  { label: 'Olevs’K', value: 'Olevs’K' },
  { label: 'Olevsk', value: 'Olevsk' },
  { label: 'Oley', value: 'Oley' },
  { label: 'Olfen', value: 'Olfen' },
  { label: 'Olga', value: 'Olga' },
  { label: 'Olgiate Comasco', value: 'Olgiate Comasco' },
  { label: 'Olgiate Molgora', value: 'Olgiate Molgora' },
  { label: 'Olgiate Olona', value: 'Olgiate Olona' },
  { label: 'Ölgii', value: 'Ölgii' },
  { label: 'Olginate', value: 'Olginate' },
  { label: 'Ølgod', value: 'Ølgod' },
  { label: 'Olhalvo', value: 'Olhalvo' },
  { label: 'Olhão', value: 'Olhão' },
  { label: 'Olho Dágua', value: 'Olho Dágua' },
  { label: 'Olho Dágua Das Cunhãs', value: 'Olho Dágua Das Cunhãs' },
  { label: 'Olho Dágua Das Flores', value: 'Olho Dágua Das Flores' },
  { label: 'Olho Dágua Do Casado', value: 'Olho Dágua Do Casado' },
  { label: 'Olho Dágua Do Piauí', value: 'Olho Dágua Do Piauí' },
  { label: 'Olho Dágua Grande', value: 'Olho Dágua Grande' },
  { label: 'Olho-Dágua Do Borges', value: 'Olho-Dágua Do Borges' },
  { label: 'Olhos De Água', value: 'Olhos De Água' },
  { label: 'Olhos-Dágua', value: 'Olhos-Dágua' },
  { label: 'Olia Speciosa', value: 'Olia Speciosa' },
  { label: 'Oliana', value: 'Oliana' },
  { label: 'Olías Del Rey', value: 'Olías Del Rey' },
  { label: 'Olibo', value: 'Olibo' },
  { label: 'Oliena', value: 'Oliena' },
  { label: 'Oliete', value: 'Oliete' },
  { label: 'Olímpia', value: 'Olímpia' },
  { label: 'Olímpio Noronha', value: 'Olímpio Noronha' },
  { label: 'Olimpo', value: 'Olimpo' },
  { label: 'Olinalá', value: 'Olinalá' },
  { label: 'Olinda', value: 'Olinda' },
  { label: 'Olinda', value: 'Olinda' },
  { label: 'Olinda Nova Do Maranhão', value: 'Olinda Nova Do Maranhão' },
  { label: 'Olindina', value: 'Olindina' },
  { label: 'Olingan', value: 'Olingan' },
  { label: 'Olintepec', value: 'Olintepec' },
  { label: 'Olintepeque', value: 'Olintepeque' },
  { label: 'Olintla', value: 'Olintla' },
  { label: 'Oliola', value: 'Oliola' },
  { label: 'Olite', value: 'Olite' },
  { label: 'Olite/Erriberri', value: 'Olite/Erriberri' },
  { label: 'Olius', value: 'Olius' },
  { label: 'Oliva', value: 'Oliva' },
  { label: 'Oliva', value: 'Oliva' },
  { label: 'Oliva De La Frontera', value: 'Oliva De La Frontera' },
  { label: 'Oliva De Mérida', value: 'Oliva De Mérida' },
  { label: 'Oliva De Plasencia', value: 'Oliva De Plasencia' },
  { label: 'Oliva Gessi', value: 'Oliva Gessi' },
  { label: 'Oliva La', value: 'Oliva La' },
  { label: 'Olivadi', value: 'Olivadi' },
  { label: 'Olivais', value: 'Olivais' },
  { label: 'Olival', value: 'Olival' },
  { label: 'Olival De Basto', value: 'Olival De Basto' },
  { label: 'Olival Do Basto', value: 'Olival Do Basto' },
  { label: 'Olival Do Basto', value: 'Olival Do Basto' },
  { label: 'Olivar', value: 'Olivar' },
  { label: 'Olivar El', value: 'Olivar El' },
  { label: 'Olivares', value: 'Olivares' },
  { label: 'Olivares De Duero', value: 'Olivares De Duero' },
  { label: 'Olivares De Júcar', value: 'Olivares De Júcar' },
  { label: 'Olivarez', value: 'Olivarez' },
  { label: 'Olive Branch', value: 'Olive Branch' },
  { label: 'Olive Hill', value: 'Olive Hill' },
  { label: 'Olivedos', value: 'Olivedos' },
  { label: 'Olivehurst', value: 'Olivehurst' },
  { label: 'Oliveira', value: 'Oliveira' },
  { label: 'Oliveira', value: 'Oliveira' },
  { label: 'Oliveira De Azemeis', value: 'Oliveira De Azemeis' },
  { label: 'Oliveira De Fátima', value: 'Oliveira De Fátima' },
  { label: 'Oliveira De Frades', value: 'Oliveira De Frades' },
  { label: 'Oliveira Do Bairro', value: 'Oliveira Do Bairro' },
  { label: 'Oliveira Do Douro', value: 'Oliveira Do Douro' },
  { label: 'Oliveira Do Hospital', value: 'Oliveira Do Hospital' },
  { label: 'Oliveira Do Mondego', value: 'Oliveira Do Mondego' },
  { label: 'Oliveira Dos Brejinhos', value: 'Oliveira Dos Brejinhos' },
  { label: 'Oliveira Fortes', value: 'Oliveira Fortes' },
  { label: 'Oliveirinha', value: 'Oliveirinha' },
  { label: 'Olivella', value: 'Olivella' },
  { label: 'Olivença', value: 'Olivença' },
  { label: 'Olivenza', value: 'Olivenza' },
  { label: 'Oliver', value: 'Oliver' },
  { label: 'Oliver', value: 'Oliver' },
  { label: 'Oliver County', value: 'Oliver County' },
  { label: 'Oliver Springs', value: 'Oliver Springs' },
  { label: 'Oliveri', value: 'Oliveri' },
  { label: 'Oliver-Valdefierro', value: 'Oliver-Valdefierro' },
  { label: 'Olivet', value: 'Olivet' },
  { label: 'Olivet', value: 'Olivet' },
  { label: 'Olivet', value: 'Olivet' },
  { label: 'Olivet', value: 'Olivet' },
  { label: 'Olivet', value: 'Olivet' },
  { label: 'Oliveto Citra', value: 'Oliveto Citra' },
  { label: 'Oliveto Lucano', value: 'Oliveto Lucano' },
  { label: 'Olivetta San Michele', value: 'Olivetta San Michele' },
  { label: 'Olivette', value: 'Olivette' },
  { label: 'Olivia', value: 'Olivia' },
  { label: 'Olivia', value: 'Olivia' },
  { label: 'Olivola', value: 'Olivola' },
  { label: 'Olkhovka Volgograd Oblast', value: 'Olkhovka Volgograd Oblast' },
  { label: 'Olkusz', value: 'Olkusz' },
  { label: 'Olla', value: 'Olla' },
  { label: 'Ollagüe', value: 'Ollagüe' },
  { label: 'Ollainville', value: 'Ollainville' },
  { label: 'Ollantaytambo', value: 'Ollantaytambo' },
  { label: 'Ollastra', value: 'Ollastra' },
  { label: 'Ollauri', value: 'Ollauri' },
  { label: 'Olleria L', value: 'Olleria L' },
  { label: 'Ollersdorf Im Burgenland', value: 'Ollersdorf Im Burgenland' },
  { label: 'Ollioules', value: 'Ollioules' },
  { label: 'Ollo', value: 'Ollo' },
  { label: 'Ollolai', value: 'Ollolai' },
  { label: 'Ollomont', value: 'Ollomont' },
  { label: 'Ollon', value: 'Ollon' },
  { label: 'Olm', value: 'Olm' },
  { label: 'Olmaliq', value: 'Olmaliq' },
  { label: 'Olmeda De Cobeta', value: 'Olmeda De Cobeta' },
  { label: 'Olmeda De Jadraque La', value: 'Olmeda De Jadraque La' },
  { label: 'Olmeda De La Cuesta', value: 'Olmeda De La Cuesta' },
  { label: 'Olmeda De Las Fuentes', value: 'Olmeda De Las Fuentes' },
  { label: 'Olmeda Del Rey', value: 'Olmeda Del Rey' },
  { label: 'Olmedilla De Alarcón', value: 'Olmedilla De Alarcón' },
  { label: 'Olmedilla De Eliz', value: 'Olmedilla De Eliz' },
  { label: 'Olmedillo De Roa', value: 'Olmedillo De Roa' },
  { label: 'Olmedo', value: 'Olmedo' },
  { label: 'Olmedo', value: 'Olmedo' },
  { label: 'Olmedo De Camaces', value: 'Olmedo De Camaces' },
  { label: 'Olmeneta', value: 'Olmeneta' },
  { label: 'Olmeto', value: 'Olmeto' },
  { label: 'Olmillos De Castro', value: 'Olmillos De Castro' },
  { label: 'Olmillos De Muñó', value: 'Olmillos De Muñó' },
  { label: 'Olmito', value: 'Olmito' },
  { label: 'Olmo Al Brembo', value: 'Olmo Al Brembo' },
  { label: 'Olmo Gentile', value: 'Olmo Gentile' },
  { label: 'Olmos', value: 'Olmos' },
  { label: 'Olmos De Esgueva', value: 'Olmos De Esgueva' },
  { label: 'Olmos De Ojeda', value: 'Olmos De Ojeda' },
  { label: 'Olmos De Peñafiel', value: 'Olmos De Peñafiel' },
  { label: 'Olmos Los', value: 'Olmos Los' },
  { label: 'Olmos Park', value: 'Olmos Park' },
  { label: 'Olmsted County', value: 'Olmsted County' },
  { label: 'Olmsted Falls', value: 'Olmsted Falls' },
  { label: 'Olmué', value: 'Olmué' },
  { label: 'Olne', value: 'Olne' },
  { label: 'Olney', value: 'Olney' },
  { label: 'Olney', value: 'Olney' },
  { label: 'Olney', value: 'Olney' },
  { label: 'Olney', value: 'Olney' },
  { label: 'Olocau', value: 'Olocau' },
  { label: 'Olocau Del Rey', value: 'Olocau Del Rey' },
  { label: 'Olocuilta', value: 'Olocuilta' },
  { label: 'Olofstorp', value: 'Olofstorp' },
  { label: 'Olofström', value: 'Olofström' },
  { label: 'Ologeni', value: 'Ologeni' },
  { label: 'Olombrada', value: 'Olombrada' },
  { label: 'Olomouc', value: 'Olomouc' },
  { label: 'Olonets', value: 'Olonets' },
  { label: 'Olongapo', value: 'Olongapo' },
  { label: 'Olongapo', value: 'Olongapo' },
  { label: 'Olonne-Sur-Mer', value: 'Olonne-Sur-Mer' },
  { label: 'Olonzac', value: 'Olonzac' },
  { label: 'Olopa', value: 'Olopa' },
  { label: 'Olóriz', value: 'Olóriz' },
  { label: 'Olóriz/Oloritz', value: 'Olóriz/Oloritz' },
  { label: 'Oloron-Sainte-Marie', value: 'Oloron-Sainte-Marie' },
  { label: 'Olost', value: 'Olost' },
  { label: 'Olot', value: 'Olot' },
  { label: 'Olot', value: 'Olot' },
  { label: 'Oloví', value: 'Oloví' },
  { label: 'Olovo', value: 'Olovo' },
  { label: 'Olovyannaya', value: 'Olovyannaya' },
  { label: 'Olovyanninskiy Rayon', value: 'Olovyanninskiy Rayon' },
  { label: 'Olpad', value: 'Olpad' },
  { label: 'Olpe', value: 'Olpe' },
  { label: 'Ołpiny', value: 'Ołpiny' },
  { label: 'Olšany', value: 'Olšany' },
  { label: 'Olsberg', value: 'Olsberg' },
  { label: 'Olsbrücken', value: 'Olsbrücken' },
  { label: 'Olshanskoye', value: 'Olshanskoye' },
  { label: 'Olst', value: 'Olst' },
  { label: 'Olstorp', value: 'Olstorp' },
  { label: 'Olstorp', value: 'Olstorp' },
  { label: 'Ølstykke', value: 'Ølstykke' },
  { label: 'Olszana', value: 'Olszana' },
  { label: 'Olszanica', value: 'Olszanica' },
  { label: 'Olszanica', value: 'Olszanica' },
  { label: 'Olszanka', value: 'Olszanka' },
  { label: 'Olszówka', value: 'Olszówka' },
  { label: 'Olszówka', value: 'Olszówka' },
  { label: 'Olsztyn', value: 'Olsztyn' },
  { label: 'Olsztyn', value: 'Olsztyn' },
  { label: 'Olsztynek', value: 'Olsztynek' },
  { label: 'Olszyna', value: 'Olszyna' },
  { label: 'Olteanca', value: 'Olteanca' },
  { label: 'Olten', value: 'Olten' },
  { label: 'Olteneşti', value: 'Olteneşti' },
  { label: 'Olteni', value: 'Olteni' },
  { label: 'Olteni', value: 'Olteni' },
  { label: 'Olteniţa', value: 'Olteniţa' },
  { label: 'Oltiariq', value: 'Oltiariq' },
  { label: 'Oltina', value: 'Oltina' },
  { label: 'Oltinko‘L', value: 'Oltinko‘L' },
  { label: 'Oltinkŭl Tumani', value: 'Oltinkŭl Tumani' },
  { label: 'Oltintopkan', value: 'Oltintopkan' },
  { label: 'Olton', value: 'Olton' },
  { label: 'Oltre Brenta', value: 'Oltre Brenta' },
  { label: 'Oltre Il Colle', value: 'Oltre Il Colle' },
  { label: 'Oltressenda Alta', value: 'Oltressenda Alta' },
  { label: 'Oltrona Di San Mamette', value: 'Oltrona Di San Mamette' },
  { label: 'Oltu', value: 'Oltu' },
  { label: 'Oltza', value: 'Oltza' },
  { label: 'Oluges Les', value: 'Oluges Les' },
  { label: 'Olula De Castro', value: 'Olula De Castro' },
  { label: 'Olula Del Río', value: 'Olula Del Río' },
  { label: 'Olupona', value: 'Olupona' },
  { label: 'Olur', value: 'Olur' },
  { label: 'Oluta', value: 'Oluta' },
  { label: 'Olutanga', value: 'Olutanga' },
  { label: 'Olvan', value: 'Olvan' },
  { label: 'Olvega', value: 'Olvega' },
  { label: 'Olvena', value: 'Olvena' },
  { label: 'Olvera', value: 'Olvera' },
  { label: 'Olvés', value: 'Olvés' },
  { label: 'Olveston', value: 'Olveston' },
  { label: 'Olyavidovo', value: 'Olyavidovo' },
  { label: 'Olyka', value: 'Olyka' },
  { label: 'Olym', value: 'Olym' },
  { label: 'Olympia', value: 'Olympia' },
  { label: 'Olympia Fields', value: 'Olympia Fields' },
  { label: 'Olympia Heights', value: 'Olympia Heights' },
  { label: 'Ólynthos', value: 'Ólynthos' },
  { label: 'Olyokminsk', value: 'Olyokminsk' },
  { label: 'Olyokminsky District', value: 'Olyokminsky District' },
  { label: 'Olyphant', value: 'Olyphant' },
  { label: 'Olyshivka', value: 'Olyshivka' },
  { label: 'Olyutorskiy Rayon', value: 'Olyutorskiy Rayon' },
  { label: 'Olza', value: 'Olza' },
  { label: 'Olzai', value: 'Olzai' },
  { label: 'Oma', value: 'Oma' },
  { label: 'Ōmachi', value: 'Ōmachi' },
  { label: 'Ōmachi', value: 'Ōmachi' },
  { label: 'Ōmachi-Shi', value: 'Ōmachi-Shi' },
  { label: 'Omaezaki-Shi', value: 'Omaezaki-Shi' },
  { label: 'Ōmagari', value: 'Ōmagari' },
  { label: 'Omagh', value: 'Omagh' },
  { label: 'Omaha', value: 'Omaha' },
  { label: 'Omak', value: 'Omak' },
  { label: 'Omalur', value: 'Omalur' },
  { label: 'Ōmamachō-Ōmama', value: 'Ōmamachō-Ōmama' },
  { label: 'Oman Smart Future City', value: 'Oman Smart Future City' },
  { label: 'Omañas Las', value: 'Omañas Las' },
  { label: 'Omanjska', value: 'Omanjska' },
  { label: 'Ōmao', value: 'Ōmao' },
  { label: 'Omarska', value: 'Omarska' },
  { label: 'Omaruru', value: 'Omaruru' },
  { label: 'Ombésa', value: 'Ombésa' },
  { label: 'Omboué', value: 'Omboué' },
  { label: 'Ombúes De Lavalle', value: 'Ombúes De Lavalle' },
  { label: 'Omdurman', value: 'Omdurman' },
  { label: 'Ome', value: 'Ome' },
  { label: 'Ōme', value: 'Ōme' },
  { label: 'Omealca', value: 'Omealca' },
  { label: 'Omega', value: 'Omega' },
  { label: 'Omegna', value: 'Omegna' },
  { label: 'Omellons Els', value: 'Omellons Els' },
  { label: 'Omells De Na Gaia Els', value: 'Omells De Na Gaia Els' },
  { label: 'Omemee', value: 'Omemee' },
  { label: 'Ömerli', value: 'Ömerli' },
  { label: 'Ōme-Shi', value: 'Ōme-Shi' },
  { label: 'Ometepec', value: 'Ometepec' },
  { label: 'Ometepec', value: 'Ometepec' },
  { label: 'Omi', value: 'Omi' },
  { label: 'Omidcheh', value: 'Omidcheh' },
  { label: 'Omidiyeh', value: 'Omidiyeh' },
  { label: 'Omigawa', value: 'Omigawa' },
  { label: 'Omignano', value: 'Omignano' },
  { label: 'Ōmihachiman', value: 'Ōmihachiman' },
  { label: 'Ōmihachiman-Shi', value: 'Ōmihachiman-Shi' },
  { label: 'Ömirzaq', value: 'Ömirzaq' },
  { label: 'Omiš', value: 'Omiš' },
  { label: 'Omišalj', value: 'Omišalj' },
  { label: 'Omissy', value: 'Omissy' },
  { label: 'Omitama-Shi', value: 'Omitama-Shi' },
  { label: 'Omitlán De Juárez', value: 'Omitlán De Juárez' },
  { label: 'Ōmiya', value: 'Ōmiya' },
  { label: 'Ommatlar', value: 'Ommatlar' },
  { label: 'Ommelanderwijk', value: 'Ommelanderwijk' },
  { label: 'Ommen', value: 'Ommen' },
  { label: 'Ommoord', value: 'Ommoord' },
  { label: 'Omoa', value: 'Omoa' },
  { label: 'Omoa', value: 'Omoa' },
  { label: 'Omoku', value: 'Omoku' },
  { label: 'Omro', value: 'Omro' },
  { label: 'Omsk', value: 'Omsk' },
  { label: 'Omsukchan', value: 'Omsukchan' },
  { label: 'Omsukchanskiy Rayon', value: 'Omsukchanskiy Rayon' },
  { label: 'Omu-Aran', value: 'Omu-Aran' },
  { label: 'Omuo-Ekiti', value: 'Omuo-Ekiti' },
  { label: 'Ōmura', value: 'Ōmura' },
  { label: 'Ōmura-Shi', value: 'Ōmura-Shi' },
  { label: 'Omurtag', value: 'Omurtag' },
  { label: 'Ōmuta', value: 'Ōmuta' },
  { label: 'Ōmuta Shi', value: 'Ōmuta Shi' },
  { label: 'Omuthiya', value: 'Omuthiya' },
  { label: 'Omutinskiy', value: 'Omutinskiy' },
  { label: 'Omutinskiy Rayon', value: 'Omutinskiy Rayon' },
  { label: 'Omutninsk', value: 'Omutninsk' },
  { label: 'Omval', value: 'Omval' },
  { label: 'Omvriakí', value: 'Omvriakí' },
  { label: 'Oña', value: 'Oña' },
  { label: 'Onagawa Chō', value: 'Onagawa Chō' },
  { label: 'Onalaska', value: 'Onalaska' },
  { label: 'Onalaska', value: 'Onalaska' },
  { label: 'Onancock', value: 'Onancock' },
  { label: 'Onanì', value: 'Onanì' },
  { label: 'Onano', value: 'Onano' },
  { label: 'Onarga', value: 'Onarga' },
  { label: 'Oñati', value: 'Oñati' },
  { label: 'Onatski', value: 'Onatski' },
  { label: 'Onavas', value: 'Onavas' },
  { label: 'Onawa', value: 'Onawa' },
  { label: 'Onça De Pitangui', value: 'Onça De Pitangui' },
  { label: 'Oncala', value: 'Oncala' },
  { label: 'Oncativo', value: 'Oncativo' },
  { label: 'Once De Abril', value: 'Once De Abril' },
  { label: 'Onceşti', value: 'Onceşti' },
  { label: 'Oncești', value: 'Oncești' },
  { label: 'Oncino', value: 'Oncino' },
  { label: 'Onda', value: 'Onda' },
  { label: 'Onda Verde', value: 'Onda Verde' },
  { label: 'Ondangwa', value: 'Ondangwa' },
  { label: 'Ondara', value: 'Ondara' },
  { label: 'Ondarroa', value: 'Ondarroa' },
  { label: 'Onderdijk', value: 'Onderdijk' },
  { label: 'Ondes', value: 'Ondes' },
  { label: 'Ondjiva', value: 'Ondjiva' },
  { label: 'Ondo', value: 'Ondo' },
  { label: 'Ondokuzmayıs', value: 'Ondokuzmayıs' },
  { label: 'Ondoy', value: 'Ondoy' },
  { label: 'Ondřejov', value: 'Ondřejov' },
  { label: 'Ondres', value: 'Ondres' },
  { label: 'Onè', value: 'Onè' },
  { label: 'One Mile', value: 'One Mile' },
  { label: 'One Tree Hill', value: 'One Tree Hill' },
  { label: 'Oneaga', value: 'Oneaga' },
  { label: 'Onega', value: 'Onega' },
  { label: 'Oneida', value: 'Oneida' },
  { label: 'Oneida', value: 'Oneida' },
  { label: 'Oneida', value: 'Oneida' },
  { label: 'Oneida County', value: 'Oneida County' },
  { label: 'Oneida County', value: 'Oneida County' },
  { label: 'Oneida County', value: 'Oneida County' },
  { label: 'Oneill', value: 'Oneill' },
  { label: 'Oneonta', value: 'Oneonta' },
  { label: 'Oneonta', value: 'Oneonta' },
  { label: 'Oneop Municipality', value: 'Oneop Municipality' },
  { label: 'Onesse-Laharie', value: 'Onesse-Laharie' },
  { label: 'Onesti', value: 'Onesti' },
  { label: 'Oneta', value: 'Oneta' },
  { label: 'Onex', value: 'Onex' },
  { label: 'Onezhskiy Rayon', value: 'Onezhskiy Rayon' },
  { label: 'Onga', value: 'Onga' },
  { label: 'Onga-Gun', value: 'Onga-Gun' },
  { label: 'Ongandjera', value: 'Ongandjera' },
  { label: 'Ongjin', value: 'Ongjin' },
  { label: 'Ongole', value: 'Ongole' },
  { label: 'Ongoni', value: 'Ongoni' },
  { label: 'Onguday', value: 'Onguday' },
  { label: 'Ongudayskiy Rayon', value: 'Ongudayskiy Rayon' },
  { label: 'Ongwediva', value: 'Ongwediva' },
  { label: 'Onhaye', value: 'Onhaye' },
  { label: 'Oni', value: 'Oni' },
  { label: 'Oniceni', value: 'Oniceni' },
  { label: 'Oniceni', value: 'Oniceni' },
  { label: 'Onida', value: 'Onida' },
  { label: 'Onifai', value: 'Onifai' },
  { label: 'Oniferi', value: 'Oniferi' },
  { label: 'Onigo', value: 'Onigo' },
  { label: 'Onikişubat', value: 'Onikişubat' },
  { label: 'Onil', value: 'Onil' },
  { label: 'Onion Creek', value: 'Onion Creek' },
  { label: 'Onís', value: 'Onís' },
  { label: 'Onitsha', value: 'Onitsha' },
  { label: 'Onkaparinga', value: 'Onkaparinga' },
  { label: 'Onkaparinga Hills', value: 'Onkaparinga Hills' },
  { label: 'Onnaing', value: 'Onnaing' },
  { label: 'Onne', value: 'Onne' },
  { label: 'Önnestad', value: 'Önnestad' },
  { label: 'Ono', value: 'Ono' },
  { label: 'Ono', value: 'Ono' },
  { label: 'Ono San Pietro', value: 'Ono San Pietro' },
  { label: 'Ono Shi', value: 'Ono Shi' },
  { label: 'Ónod', value: 'Ónod' },
  { label: 'Onoda', value: 'Onoda' },
  { label: 'Ōno-Hara', value: 'Ōno-Hara' },
  { label: 'Ōnojō', value: 'Ōnojō' },
  { label: 'Ōnojō Shi', value: 'Ōnojō Shi' },
  { label: 'Onokhino', value: 'Onokhino' },
  { label: 'Onokhoy', value: 'Onokhoy' },
  { label: 'Onokivtsi', value: 'Onokivtsi' },
  { label: 'Onomichi', value: 'Onomichi' },
  { label: 'Onomichi-Shi', value: 'Onomichi-Shi' },
  { label: 'Onondaga County', value: 'Onondaga County' },
  { label: 'Onore', value: 'Onore' },
  { label: 'Ōno-Shi', value: 'Ōno-Shi' },
  { label: 'Onoto', value: 'Onoto' },
  { label: 'Onotoa', value: 'Onotoa' },
  { label: 'Onou Municipality', value: 'Onou Municipality' },
  { label: 'Onoun Municipality', value: 'Onoun Municipality' },
  { label: 'Onsala', value: 'Onsala' },
  { label: 'Ons-En-Bray', value: 'Ons-En-Bray' },
  { label: 'Onset', value: 'Onset' },
  { label: 'Onslow', value: 'Onslow' },
  { label: 'Onslow County', value: 'Onslow County' },
  { label: 'Onsŏng', value: 'Onsŏng' },
  { label: 'Onstwedde', value: 'Onstwedde' },
  { label: 'Ontario', value: 'Ontario' },
  { label: 'Ontario', value: 'Ontario' },
  { label: 'Ontario', value: 'Ontario' },
  { label: 'Ontario', value: 'Ontario' },
  { label: 'Ontario County', value: 'Ontario County' },
  { label: 'Ontígola', value: 'Ontígola' },
  { label: 'Ontiñena', value: 'Ontiñena' },
  { label: 'Ontinyent', value: 'Ontinyent' },
  { label: 'Ontonagon', value: 'Ontonagon' },
  { label: 'Ontonagon County', value: 'Ontonagon County' },
  { label: 'Ontur', value: 'Ontur' },
  { label: 'Onuca', value: 'Onuca' },
  { label: 'Onufriyivka', value: 'Onufriyivka' },
  { label: 'Onverwacht', value: 'Onverwacht' },
  { label: 'Onzaga', value: 'Onzaga' },
  { label: 'Onzain', value: 'Onzain' },
  { label: 'Onzo', value: 'Onzo' },
  { label: 'Onzonilla', value: 'Onzonilla' },
  { label: 'Oodweyne', value: 'Oodweyne' },
  { label: 'Oog In Al', value: 'Oog In Al' },
  { label: 'Ooij', value: 'Ooij' },
  { label: 'Oolitic', value: 'Oolitic' },
  { label: 'Oologah', value: 'Oologah' },
  { label: 'Oonoonba', value: 'Oonoonba' },
  { label: 'Ooralea', value: 'Ooralea' },
  { label: 'Oost Gelre', value: 'Oost Gelre' },
  { label: 'Oostburg', value: 'Oostburg' },
  { label: 'Oostburg', value: 'Oostburg' },
  { label: 'Oostdorp', value: 'Oostdorp' },
  { label: 'Oostduinkerke', value: 'Oostduinkerke' },
  { label: 'Oosteind', value: 'Oosteind' },
  { label: 'Oosteinde', value: 'Oosteinde' },
  { label: 'Oostelbeers', value: 'Oostelbeers' },
  { label: 'Oostendorp', value: 'Oostendorp' },
  { label: 'Oosterbeek', value: 'Oosterbeek' },
  { label: 'Oosterdijk', value: 'Oosterdijk' },
  { label: 'Oosterend', value: 'Oosterend' },
  { label: 'Oosterhesselen', value: 'Oosterhesselen' },
  { label: 'Oosterholt', value: 'Oosterholt' },
  { label: 'Oosterhoogebrug', value: 'Oosterhoogebrug' },
  { label: 'Oosterhout', value: 'Oosterhout' },
  { label: 'Oosterhout', value: 'Oosterhout' },
  { label: 'Oosterland', value: 'Oosterland' },
  { label: 'Oosterland', value: 'Oosterland' },
  { label: 'Oosterleek', value: 'Oosterleek' },
  { label: 'Oostermeenthe', value: 'Oostermeenthe' },
  { label: 'Oosterpark', value: 'Oosterpark' },
  { label: 'Oosterwolde', value: 'Oosterwolde' },
  { label: 'Oosterzele', value: 'Oosterzele' },
  { label: 'Oosterzij', value: 'Oosterzij' },
  { label: 'Oost-Graftdijk', value: 'Oost-Graftdijk' },
  { label: 'Oosthuizen', value: 'Oosthuizen' },
  { label: 'Oostkamp', value: 'Oostkamp' },
  { label: 'Oostkapelle', value: 'Oostkapelle' },
  { label: 'Oostknollendam', value: 'Oostknollendam' },
  { label: 'Oostmalle', value: 'Oostmalle' },
  { label: 'Oostrozebeke', value: 'Oostrozebeke' },
  { label: 'Oostrum', value: 'Oostrum' },
  { label: 'Oost-Vlieland', value: 'Oost-Vlieland' },
  { label: 'Oostvoorne', value: 'Oostvoorne' },
  { label: 'Oostwoud', value: 'Oostwoud' },
  { label: 'Oostzaan', value: 'Oostzaan' },
  { label: 'Ootmarsum', value: 'Ootmarsum' },
  { label: 'Ooty', value: 'Ooty' },
  { label: 'Ooy', value: 'Ooy' },
  { label: 'Ooyerhoek', value: 'Ooyerhoek' },
  { label: 'Opaka', value: 'Opaka' },
  { label: 'Opal Cliffs', value: 'Opal Cliffs' },
  { label: 'Opalenica', value: 'Opalenica' },
  { label: 'Opalikha', value: 'Opalikha' },
  { label: 'Opa-Locka', value: 'Opa-Locka' },
  { label: 'Ópályi', value: 'Ópályi' },
  { label: 'Opařany', value: 'Opařany' },
  { label: 'Oparino', value: 'Oparino' },
  { label: 'Opatija', value: 'Opatija' },
  { label: 'Opatoro', value: 'Opatoro' },
  { label: 'Opatov', value: 'Opatov' },
  { label: 'Opatovice Nad Labem', value: 'Opatovice Nad Labem' },
  { label: 'Opatów', value: 'Opatów' },
  { label: 'Opatów', value: 'Opatów' },
  { label: 'Opatów', value: 'Opatów' },
  { label: 'Opatówek', value: 'Opatówek' },
  { label: 'Opatowiec', value: 'Opatowiec' },
  { label: 'Opava', value: 'Opava' },
  { label: 'Općina Dubrava', value: 'Općina Dubrava' },
  { label: 'Općina Dvor', value: 'Općina Dvor' },
  { label: 'Općina Gvozd', value: 'Općina Gvozd' },
  { label: 'Općina Lanišće', value: 'Općina Lanišće' },
  { label: 'Općina Lastovo', value: 'Općina Lastovo' },
  { label: 'Opčina Ljubljana-Bežigrad', value: 'Opčina Ljubljana-Bežigrad' },
  { label: 'Opechenskiy Posad', value: 'Opechenskiy Posad' },
  { label: 'Opeinde', value: 'Opeinde' },
  { label: 'Opelika', value: 'Opelika' },
  { label: 'Opelousas', value: 'Opelousas' },
  { label: 'Opera', value: 'Opera' },
  { label: 'Opéra', value: 'Opéra' },
  { label: 'Opfenbach', value: 'Opfenbach' },
  { label: 'Opfikon', value: 'Opfikon' },
  { label: 'Öpfingen', value: 'Öpfingen' },
  { label: 'Opglabbeek', value: 'Opglabbeek' },
  { label: 'Ophemert', value: 'Ophemert' },
  { label: 'Opheusden', value: 'Opheusden' },
  { label: 'Ophoven', value: 'Ophoven' },
  { label: 'Opi', value: 'Opi' },
  { label: 'Opi', value: 'Opi' },
  { label: 'Opichen', value: 'Opichen' },
  { label: 'Opijnen', value: 'Opijnen' },
  { label: 'Opinogóra Górna', value: 'Opinogóra Górna' },
  { label: 'Opio', value: 'Opio' },
  { label: 'Opishnia', value: 'Opishnia' },
  { label: 'Opladen', value: 'Opladen' },
  { label: 'Oplotnica', value: 'Oplotnica' },
  { label: 'Opmeer', value: 'Opmeer' },
  { label: 'Opobo', value: 'Opobo' },
  { label: 'Opochka', value: 'Opochka' },
  { label: 'Opočno', value: 'Opočno' },
  { label: 'Opoczno', value: 'Opoczno' },
  { label: 'Opodepe', value: 'Opodepe' },
  { label: 'Opol', value: 'Opol' },
  { label: 'Opole', value: 'Opole' },
  { label: 'Opole Lubelskie', value: 'Opole Lubelskie' },
  { label: 'Opopeo', value: 'Opopeo' },
  { label: 'Oporapa', value: 'Oporapa' },
  { label: 'Oporelu', value: 'Oporelu' },
  { label: 'Oporów', value: 'Oporów' },
  { label: 'Opotiki', value: 'Opotiki' },
  { label: 'Opovo', value: 'Opovo' },
  { label: 'Opp', value: 'Opp' },
  { label: 'Oppach', value: 'Oppach' },
  { label: 'Oppdal', value: 'Oppdal' },
  { label: 'Oppeano', value: 'Oppeano' },
  { label: 'Oppède Le Vieux', value: 'Oppède Le Vieux' },
  { label: 'Oppegård', value: 'Oppegård' },
  { label: 'Oppenau', value: 'Oppenau' },
  { label: 'Oppenberg', value: 'Oppenberg' },
  { label: 'Oppenheim', value: 'Oppenheim' },
  { label: 'Oppenhuizen', value: 'Oppenhuizen' },
  { label: 'Oppenweiler', value: 'Oppenweiler' },
  { label: 'Opperdoes', value: 'Opperdoes' },
  { label: 'Oppido Lucano', value: 'Oppido Lucano' },
  { label: 'Oppido Mamertina', value: 'Oppido Mamertina' },
  { label: 'Oppin', value: 'Oppin' },
  { label: 'Opponitz', value: 'Opponitz' },
  { label: 'Opportunity', value: 'Opportunity' },
  { label: 'Oppurg', value: 'Oppurg' },
  { label: 'Oprisavci', value: 'Oprisavci' },
  { label: 'Oprişor', value: 'Oprişor' },
  { label: 'Oprtalj-Portole', value: 'Oprtalj-Portole' },
  { label: 'Opština [Historical] Ljubljana-Šiška', value: 'Opština [Historical] Ljubljana-Šiška' },
  { label: 'Opština Ljubljana-Center', value: 'Opština Ljubljana-Center' },
  { label: 'Opština Ljubljana-Moste-Polje', value: 'Opština Ljubljana-Moste-Polje' },
  { label: 'Opština Ljubljana-Vič-Rudnik', value: 'Opština Ljubljana-Vič-Rudnik' },
  { label: 'Opština Oštra Luka', value: 'Opština Oštra Luka' },
  { label: 'Opština Višegrad', value: 'Opština Višegrad' },
  { label: 'Optaşi', value: 'Optaşi' },
  { label: 'Opunake', value: 'Opunake' },
  { label: 'Ópusztaszer', value: 'Ópusztaszer' },
  { label: 'Opuwo', value: 'Opuwo' },
  { label: 'Opuwo Constituency', value: 'Opuwo Constituency' },
  { label: 'Opuzen', value: 'Opuzen' },
  { label: 'Opwijk', value: 'Opwijk' },
  { label: 'Oqtosh', value: 'Oqtosh' },
  { label: 'Oquawka', value: 'Oquawka' },
  { label: 'Oquillas', value: 'Oquillas' },
  { label: 'Oquirrh', value: 'Oquirrh' },
  { label: 'Oquitoa', value: 'Oquitoa' },
  { label: 'Or Tambo District Municipality', value: 'Or Tambo District Municipality' },
  { label: 'Or Yehuda', value: 'Or Yehuda' },
  { label: 'Ora', value: 'Ora' },
  { label: 'Oracabessa', value: 'Oracabessa' },
  { label: 'Oracle', value: 'Oracle' },
  { label: 'Oracon', value: 'Oracon' },
  { label: 'Oradea', value: 'Oradea' },
  { label: 'Oradell', value: 'Oradell' },
  { label: 'Oradour-Sur-Glane', value: 'Oradour-Sur-Glane' },
  { label: 'Oradour-Sur-Vayres', value: 'Oradour-Sur-Vayres' },
  { label: 'Orahovica', value: 'Orahovica' },
  { label: 'Orahovica Donja', value: 'Orahovica Donja' },
  { label: 'Orai', value: 'Orai' },
  { label: 'Oraiókastro', value: 'Oraiókastro' },
  { label: 'Oraison', value: 'Oraison' },
  { label: 'Oral', value: 'Oral' },
  { label: 'Oran', value: 'Oran' },
  { label: 'Oran', value: 'Oran' },
  { label: 'Oran', value: 'Oran' },
  { label: 'Oran Park', value: 'Oran Park' },
  { label: 'Orana', value: 'Orana' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Orange Bay', value: 'Orange Bay' },
  { label: 'Orange Bay', value: 'Orange Bay' },
  { label: 'Orange Beach', value: 'Orange Beach' },
  { label: 'Orange City', value: 'Orange City' },
  { label: 'Orange City', value: 'Orange City' },
  { label: 'Orange County', value: 'Orange County' },
  { label: 'Orange County', value: 'Orange County' },
  { label: 'Orange County', value: 'Orange County' },
  { label: 'Orange County', value: 'Orange County' },
  { label: 'Orange County', value: 'Orange County' },
  { label: 'Orange County', value: 'Orange County' },
  { label: 'Orange County', value: 'Orange County' },
  { label: 'Orange County', value: 'Orange County' },
  { label: 'Orange Cove', value: 'Orange Cove' },
  { label: 'Orange Farm', value: 'Orange Farm' },
  { label: 'Orange Grove', value: 'Orange Grove' },
  { label: 'Orange Hill', value: 'Orange Hill' },
  { label: 'Orange Hill', value: 'Orange Hill' },
  { label: 'Orange Lake', value: 'Orange Lake' },
  { label: 'Orange Municipality', value: 'Orange Municipality' },
  { label: 'Orange Park', value: 'Orange Park' },
  { label: 'Orange Walk', value: 'Orange Walk' },
  { label: 'Orangeburg', value: 'Orangeburg' },
  { label: 'Orangeburg', value: 'Orangeburg' },
  { label: 'Orangeburg County', value: 'Orangeburg County' },
  { label: 'Orangetree', value: 'Orangetree' },
  { label: 'Orangevale', value: 'Orangevale' },
  { label: 'Orangeville', value: 'Orangeville' },
  { label: 'Orangeville', value: 'Orangeville' },
  { label: 'Orangeville', value: 'Orangeville' },
  { label: 'Orani', value: 'Orani' },
  { label: 'Orani', value: 'Orani' },
  { label: 'Orani', value: 'Orani' },
  { label: 'Orania', value: 'Orania' },
  { label: 'Oranienbaum', value: 'Oranienbaum' },
  { label: 'Oranienburg', value: 'Oranienburg' },
  { label: 'Oranjemund', value: 'Oranjemund' },
  { label: 'Oranjestad', value: 'Oranjestad' },
  { label: 'Oranjestad', value: 'Oranjestad' },
  { label: 'Oranjewijk', value: 'Oranjewijk' },
  { label: 'Oranjewoud', value: 'Oranjewoud' },
  { label: 'Oranmore', value: 'Oranmore' },
  { label: 'Oranzherei', value: 'Oranzherei' },
  { label: 'Oraovica', value: 'Oraovica' },
  { label: 'Orapa', value: 'Orapa' },
  { label: 'Oras', value: 'Oras' },
  { label: 'Oras', value: 'Oras' },
  { label: 'Oraş Abrud', value: 'Oraş Abrud' },
  { label: 'Oraş Agnita', value: 'Oraş Agnita' },
  { label: 'Oraș Aleşd', value: 'Oraș Aleşd' },
  { label: 'Oraş Amara', value: 'Oraş Amara' },
  { label: 'Oraş Anina', value: 'Oraş Anina' },
  { label: 'Oraş Aninoasa', value: 'Oraş Aninoasa' },
  { label: 'Oraș Ardud', value: 'Oraș Ardud' },
  { label: 'Oraş Avrig', value: 'Oraş Avrig' },
  { label: 'Oraş Azuga', value: 'Oraş Azuga' },
  { label: 'Oraş Babadag', value: 'Oraş Babadag' },
  { label: 'Oraş Bãbeni', value: 'Oraş Bãbeni' },
  { label: 'Oraş Baia De Aramã', value: 'Oraş Baia De Aramã' },
  { label: 'Oraş Baia De Arieş', value: 'Oraş Baia De Arieş' },
  { label: 'Oraş Bãicoi', value: 'Oraş Bãicoi' },
  { label: 'Oraş Bãile Govora', value: 'Oraş Bãile Govora' },
  { label: 'Oraş Bãile Herculane', value: 'Oraş Bãile Herculane' },
  { label: 'Oraş Bãile Olãneşti', value: 'Oraş Bãile Olãneşti' },
  { label: 'Oraş Bãile Tuşnad', value: 'Oraş Bãile Tuşnad' },
  { label: 'Oraş Bãlan', value: 'Oraş Bãlan' },
  { label: 'Oraş Bãlceşti', value: 'Oraş Bãlceşti' },
  { label: 'Oraş Balş', value: 'Oraş Balş' },
  { label: 'Oraş Bãneasa', value: 'Oraş Bãneasa' },
  { label: 'Oraş Baraolt', value: 'Oraş Baraolt' },
  { label: 'Oraş Bechet', value: 'Oraş Bechet' },
  { label: 'Oraş Beclean', value: 'Oraş Beclean' },
  { label: 'Oraş Berbeşti', value: 'Oraş Berbeşti' },
  { label: 'Oraş Bereşti', value: 'Oraş Bereşti' },
  { label: 'Oraş Bicaz', value: 'Oraş Bicaz' },
  { label: 'Oraş Bocşa', value: 'Oraş Bocşa' },
  { label: 'Oraş Boldeşti-Scãeni', value: 'Oraş Boldeşti-Scãeni' },
  { label: 'Oraş Bolintin-Vale', value: 'Oraş Bolintin-Vale' },
  { label: 'Oraş Borsec', value: 'Oraş Borsec' },
  { label: 'Oraş Bragadiru', value: 'Oraş Bragadiru' },
  { label: 'Oraş Breaza', value: 'Oraş Breaza' },
  { label: 'Oraş Brezoi', value: 'Oraş Brezoi' },
  { label: 'Oraş Broşteni', value: 'Oraş Broşteni' },
  { label: 'Oraş Bucecea', value: 'Oraş Bucecea' },
  { label: 'Oraş Budeşti', value: 'Oraş Budeşti' },
  { label: 'Oraş Buftea', value: 'Oraş Buftea' },
  { label: 'Oraş Buhuşi', value: 'Oraş Buhuşi' },
  { label: 'Oraş Bumbeşti-Jiu', value: 'Oraş Bumbeşti-Jiu' },
  { label: 'Oraş Buşteni', value: 'Oraş Buşteni' },
  { label: 'Oraş Buziaş', value: 'Oraş Buziaş' },
  { label: 'Oraş Cajvana', value: 'Oraş Cajvana' },
  { label: 'Oraş Cãlan', value: 'Oraş Cãlan' },
  { label: 'Oraş Cãlimãneşti', value: 'Oraş Cãlimãneşti' },
  { label: 'Oraş Câmpeni', value: 'Oraş Câmpeni' },
  { label: 'Oraş Cãzãneşti', value: 'Oraş Cãzãneşti' },
  { label: 'Oraş Cernavodã', value: 'Oraş Cernavodã' },
  { label: 'Oraş Chişineu-Criş', value: 'Oraş Chişineu-Criş' },
  { label: 'Oraş Chitila', value: 'Oraş Chitila' },
  { label: 'Oraş Ciacova', value: 'Oraş Ciacova' },
  { label: 'Oraş Cisnãdie', value: 'Oraş Cisnãdie' },
  { label: 'Oraş Comãneşti', value: 'Oraş Comãneşti' },
  { label: 'Oraş Comarnic', value: 'Oraş Comarnic' },
  { label: 'Oraş Copşa Micã', value: 'Oraş Copşa Micã' },
  { label: 'Oraş Corabia', value: 'Oraş Corabia' },
  { label: 'Oraș Costeşti', value: 'Oraș Costeşti' },
  { label: 'Oraş Covasna', value: 'Oraş Covasna' },
  { label: 'Oraş Cristuru Secuiesc', value: 'Oraş Cristuru Secuiesc' },
  { label: 'Oraş Cugir', value: 'Oraş Cugir' },
  { label: 'Oraş Curtici', value: 'Oraş Curtici' },
  { label: 'Oraş Dãbuleni', value: 'Oraş Dãbuleni' },
  { label: 'Oraş Darabani', value: 'Oraş Darabani' },
  { label: 'Oraş Dãrmãneşti', value: 'Oraş Dãrmãneşti' },
  { label: 'Oraş Deta', value: 'Oraş Deta' },
  { label: 'Oraş Dolhasca', value: 'Oraş Dolhasca' },
  { label: 'Oraş Drãgãneşti-Olt', value: 'Oraş Drãgãneşti-Olt' },
  { label: 'Oraş Dumbrãveni', value: 'Oraş Dumbrãveni' },
  { label: 'Oraş Eforie', value: 'Oraş Eforie' },
  { label: 'Oraş Fãget', value: 'Oraş Fãget' },
  { label: 'Oraş Fãurei', value: 'Oraş Fãurei' },
  { label: 'Oraş Fieni', value: 'Oraş Fieni' },
  { label: 'Oraş Fierbinţi-Târg', value: 'Oraş Fierbinţi-Târg' },
  { label: 'Oraş Filiaşi', value: 'Oraş Filiaşi' },
  { label: 'Oraş Flãmânzi', value: 'Oraş Flãmânzi' },
  { label: 'Oraş Frasin', value: 'Oraş Frasin' },
  { label: 'Oraş Fundulea', value: 'Oraş Fundulea' },
  { label: 'Oraş Gãeşti', value: 'Oraş Gãeşti' },
  { label: 'Oraş Gãtaia', value: 'Oraş Gãtaia' },
  { label: 'Oraş Geoagiu', value: 'Oraş Geoagiu' },
  { label: 'Oraş Ghimbav', value: 'Oraş Ghimbav' },
  { label: 'Oraş Gura Humorului', value: 'Oraş Gura Humorului' },
  { label: 'Oraş Hârlãu', value: 'Oraş Hârlãu' },
  { label: 'Oraş Hârşova', value: 'Oraş Hârşova' },
  { label: 'Oraş Haţeg', value: 'Oraş Haţeg' },
  { label: 'Oraş Horezu', value: 'Oraş Horezu' },
  { label: 'Oraş Huedin', value: 'Oraş Huedin' },
  { label: 'Oraş Ianca', value: 'Oraş Ianca' },
  { label: 'Oraş Ineu', value: 'Oraş Ineu' },
  { label: 'Oraş Însurãţei', value: 'Oraş Însurãţei' },
  { label: 'Oraş Întorsura Buzãului', value: 'Oraş Întorsura Buzãului' },
  { label: 'Oraş Isaccea', value: 'Oraş Isaccea' },
  { label: 'Oraș Jibou', value: 'Oraș Jibou' },
  { label: 'Oraş Jimbolia', value: 'Oraş Jimbolia' },
  { label: 'Oraş Lehliu Garã', value: 'Oraş Lehliu Garã' },
  { label: 'Oraş Lipova', value: 'Oraş Lipova' },
  { label: 'Oraş Liteni', value: 'Oraş Liteni' },
  { label: 'Oraș Livada', value: 'Oraș Livada' },
  { label: 'Oraş Mãcin', value: 'Oraş Mãcin' },
  { label: 'Oraş Mãgurele', value: 'Oraş Mãgurele' },
  { label: 'Oraş Mãrãşeşti', value: 'Oraş Mãrãşeşti' },
  { label: 'Oraş Miercurea Sibiului', value: 'Oraş Miercurea Sibiului' },
  { label: 'Oraş Mihãileşti', value: 'Oraş Mihãileşti' },
  { label: 'Oraş Milişãuţi', value: 'Oraş Milişãuţi' },
  { label: 'Oraș Mioveni', value: 'Oraș Mioveni' },
  { label: 'Oraş Mizil', value: 'Oraş Mizil' },
  { label: 'Oraş Murfatlar', value: 'Oraş Murfatlar' },
  { label: 'Oraș Murgeni', value: 'Oraș Murgeni' },
  { label: 'Oraş Nãdlac', value: 'Oraş Nãdlac' },
  { label: 'Oraş Nãsãud', value: 'Oraş Nãsãud' },
  { label: 'Oraş Nãvodari', value: 'Oraş Nãvodari' },
  { label: 'Oraş Negreşti', value: 'Oraş Negreşti' },
  { label: 'Oraş Negreşti-Oaş', value: 'Oraş Negreşti-Oaş' },
  { label: 'Oraş Negru Vodã', value: 'Oraş Negru Vodã' },
  { label: 'Oraş Nehoiu', value: 'Oraş Nehoiu' },
  { label: 'Oraş Nucet', value: 'Oraş Nucet' },
  { label: 'Oraş Ocna Mureş', value: 'Oraş Ocna Mureş' },
  { label: 'Oraş Ocna Sibiului', value: 'Oraş Ocna Sibiului' },
  { label: 'Oraş Ocnele Mari', value: 'Oraş Ocnele Mari' },
  { label: 'Oraş Odobeşti', value: 'Oraş Odobeşti' },
  { label: 'Oraş Oraviţa', value: 'Oraş Oraviţa' },
  { label: 'Oraş Oţelu Roşu', value: 'Oraş Oţelu Roşu' },
  { label: 'Oraş Otopeni', value: 'Oraş Otopeni' },
  { label: 'Oraş Ovidiu', value: 'Oraş Ovidiu' },
  { label: 'Oraş Panciu', value: 'Oraş Panciu' },
  { label: 'Oraş Pâncota', value: 'Oraş Pâncota' },
  { label: 'Oraş Pantelimon', value: 'Oraş Pantelimon' },
  { label: 'Oraş Pãtârlagele', value: 'Oraş Pãtârlagele' },
  { label: 'Oraş Petrila', value: 'Oraş Petrila' },
  { label: 'Oraş Piatra-Olt', value: 'Oraş Piatra-Olt' },
  { label: 'Oraş Plopeni', value: 'Oraş Plopeni' },
  { label: 'Oraş Podu Iloaiei', value: 'Oraş Podu Iloaiei' },
  { label: 'Oraş Pogoanele', value: 'Oraş Pogoanele' },
  { label: 'Oraş Popeşti Leordeni', value: 'Oraş Popeşti Leordeni' },
  { label: 'Oraş Potcoava', value: 'Oraş Potcoava' },
  { label: 'Oraş Predeal', value: 'Oraş Predeal' },
  { label: 'Oraş Pucioasa', value: 'Oraş Pucioasa' },
  { label: 'Oraş Rãcari', value: 'Oraş Rãcari' },
  { label: 'Oraş Râşnov', value: 'Oraş Râşnov' },
  { label: 'Oraş Recaş', value: 'Oraş Recaş' },
  { label: 'Oraş Rovinari', value: 'Oraş Rovinari' },
  { label: 'Oraş Roznov', value: 'Oraş Roznov' },
  { label: 'Oraş Rupea', value: 'Oraş Rupea' },
  { label: 'Oraş Sãcueni', value: 'Oraş Sãcueni' },
  { label: 'Oraş Salcea', value: 'Oraş Salcea' },
  { label: 'Oraş Sãlişte', value: 'Oraş Sãlişte' },
  { label: 'Oraş Sângeorz-Bãi', value: 'Oraş Sângeorz-Bãi' },
  { label: 'Oraş Sânnicolau Mare', value: 'Oraş Sânnicolau Mare' },
  { label: 'Oraş Sântana', value: 'Oraş Sântana' },
  { label: 'Oraş Sãveni', value: 'Oraş Sãveni' },
  { label: 'Oraş Scorniceşti', value: 'Oraş Scorniceşti' },
  { label: 'Oraş Sebiş', value: 'Oraş Sebiş' },
  { label: 'Oraş Segarcea', value: 'Oraş Segarcea' },
  { label: 'Oraş Simeria', value: 'Oraş Simeria' },
  { label: 'Oraş Şimleu Silvaniei', value: 'Oraş Şimleu Silvaniei' },
  { label: 'Oraş Sinaia', value: 'Oraş Sinaia' },
  { label: 'Oraş Siret', value: 'Oraş Siret' },
  { label: 'Oraş Slãnic', value: 'Oraş Slãnic' },
  { label: 'Oraş Slãnic-Moldova', value: 'Oraş Slãnic-Moldova' },
  { label: 'Oraş Solca', value: 'Oraş Solca' },
  { label: 'Oraş Ştefãneşti', value: 'Oraş Ştefãneşti' },
  { label: 'Oraș Ştefăneşti', value: 'Oraș Ştefăneşti' },
  { label: 'Oraş Ştei', value: 'Oraş Ştei' },
  { label: 'Oraş Strehaia', value: 'Oraş Strehaia' },
  { label: 'Oraş Sulina', value: 'Oraş Sulina' },
  { label: 'Oraş Tãlmaciu', value: 'Oraş Tãlmaciu' },
  { label: 'Oraş Ţãndãrei', value: 'Oraş Ţãndãrei' },
  { label: 'Oraș Târgu Bujor', value: 'Oraș Târgu Bujor' },
  { label: 'Oraş Târgu Cãrbuneşti', value: 'Oraş Târgu Cãrbuneşti' },
  { label: 'Oraş Târgu Frumos', value: 'Oraş Târgu Frumos' },
  { label: 'Oraş Târgu Neamţ', value: 'Oraş Târgu Neamţ' },
  { label: 'Oraş Târgu Ocna', value: 'Oraş Târgu Ocna' },
  { label: 'Oraș Tăşnad', value: 'Oraș Tăşnad' },
  { label: 'Oraş Techirghiol', value: 'Oraş Techirghiol' },
  { label: 'Oraş Teiuş', value: 'Oraş Teiuş' },
  { label: 'Oraş Ţicleni', value: 'Oraş Ţicleni' },
  { label: 'Oraş Tismana', value: 'Oraş Tismana' },
  { label: 'Oraş Titu', value: 'Oraş Titu' },
  { label: 'Oraș Topoloveni', value: 'Oraș Topoloveni' },
  { label: 'Oraş Turceni', value: 'Oraş Turceni' },
  { label: 'Oraş Uricani', value: 'Oraş Uricani' },
  { label: 'Oraş Urlaţi', value: 'Oraş Urlaţi' },
  { label: 'Oraş Valea Lui Mihai', value: 'Oraş Valea Lui Mihai' },
  { label: 'Oraş Vãlenii De Munte', value: 'Oraş Vãlenii De Munte' },
  { label: 'Oraş Vânju Mare', value: 'Oraş Vânju Mare' },
  { label: 'Oraş Vaşcãu', value: 'Oraş Vaşcãu' },
  { label: 'Oraş Vicovu De Sus', value: 'Oraş Vicovu De Sus' },
  { label: 'Oraş Victoria', value: 'Oraş Victoria' },
  { label: 'Oraş Videle', value: 'Oraş Videle' },
  { label: 'Oraş Vlãhiţa', value: 'Oraş Vlãhiţa' },
  { label: 'Oraş Zãrneşti', value: 'Oraş Zãrneşti' },
  { label: 'Oraş Zimnicea', value: 'Oraş Zimnicea' },
  { label: 'Oraş Zlatna', value: 'Oraş Zlatna' },
  { label: 'Orășa', value: 'Orășa' },
  { label: 'Orašac', value: 'Orašac' },
  { label: 'Orășeni-Deal', value: 'Orășeni-Deal' },
  { label: 'Orashac', value: 'Orashac' },
  { label: 'Orašje', value: 'Orašje' },
  { label: 'Orăştie', value: 'Orăştie' },
  { label: 'Orăştioara De Sus', value: 'Orăştioara De Sus' },
  { label: 'Oraşu Nou', value: 'Oraşu Nou' },
  { label: 'Oratino', value: 'Oratino' },
  { label: 'Orativ', value: 'Orativ' },
  { label: 'Oratorio', value: 'Oratorio' },
  { label: 'Oratórios', value: 'Oratórios' },
  { label: 'Oravais', value: 'Oravais' },
  { label: 'Oraviţa', value: 'Oraviţa' },
  { label: 'Oravská Lesná', value: 'Oravská Lesná' },
  { label: 'Oravský Podzámok', value: 'Oravský Podzámok' },
  { label: 'Orba', value: 'Orba' },
  { label: 'Orba (Infiernito)', value: 'Orba (Infiernito)' },
  { label: 'Orbada La', value: 'Orbada La' },
  { label: 'Orbaizeta', value: 'Orbaizeta' },
  { label: 'Orbaneja Riopico', value: 'Orbaneja Riopico' },
  { label: 'Orbara', value: 'Orbara' },
  { label: 'Orbassano', value: 'Orbassano' },
  { label: 'Orbe', value: 'Orbe' },
  { label: 'Orbeasca De Jos', value: 'Orbeasca De Jos' },
  { label: 'Orbeasca De Sus', value: 'Orbeasca De Sus' },
  { label: 'Orbec', value: 'Orbec' },
  { label: 'Orbeni', value: 'Orbeni' },
  { label: 'Orbetello', value: 'Orbetello' },
  { label: 'Orbetello Scalo', value: 'Orbetello Scalo' },
  { label: 'Orbey', value: 'Orbey' },
  { label: 'Orbita', value: 'Orbita' },
  { label: 'Orbost', value: 'Orbost' },
  { label: 'Őrbottyán', value: 'Őrbottyán' },
  { label: 'Örbyhus', value: 'Örbyhus' },
  { label: 'Orcajo', value: 'Orcajo' },
  { label: 'Orce', value: 'Orce' },
  { label: 'Orcenico Inferiore', value: 'Orcenico Inferiore' },
  { label: 'Orcera', value: 'Orcera' },
  { label: 'Orcet', value: 'Orcet' },
  { label: 'Orchamps', value: 'Orchamps' },
  { label: 'Orchamps-Vennes', value: 'Orchamps-Vennes' },
  { label: 'Orchard City', value: 'Orchard City' },
  { label: 'Orchard Grass Hills', value: 'Orchard Grass Hills' },
  { label: 'Orchard Hills', value: 'Orchard Hills' },
  { label: 'Orchard Hills', value: 'Orchard Hills' },
  { label: 'Orchard Homes', value: 'Orchard Homes' },
  { label: 'Orchard Lake', value: 'Orchard Lake' },
  { label: 'Orchard Mesa', value: 'Orchard Mesa' },
  { label: 'Orchard Park', value: 'Orchard Park' },
  { label: 'Orchard Road', value: 'Orchard Road' },
  { label: 'Orchards', value: 'Orchards' },
  { label: 'Orcheta', value: 'Orcheta' },
  { label: 'Orchha', value: 'Orchha' },
  { label: 'Orchidlands Estates', value: 'Orchidlands Estates' },
  { label: 'Orchies', value: 'Orchies' },
  { label: 'Orchomenós', value: 'Orchomenós' },
  { label: 'Orchowo', value: 'Orchowo' },
  { label: 'Orciano Di Pesaro', value: 'Orciano Di Pesaro' },
  { label: 'Orciano Pisano', value: 'Orciano Pisano' },
  { label: 'Orcines', value: 'Orcines' },
  { label: 'Orco Feglino', value: 'Orco Feglino' },
  { label: 'Orcopampa', value: 'Orcopampa' },
  { label: 'Orcotuna', value: 'Orcotuna' },
  { label: 'Orcutt', value: 'Orcutt' },
  { label: 'Ord', value: 'Ord' },
  { label: 'Orda', value: 'Orda' },
  { label: 'Orden', value: 'Orden' },
  { label: 'Ordes', value: 'Ordes' },
  { label: 'Ordial El', value: 'Ordial El' },
  { label: 'Ordino', value: 'Ordino' },
  { label: 'Ordis', value: 'Ordis' },
  { label: 'Ordizia', value: 'Ordizia' },
  { label: 'Ordona', value: 'Ordona' },
  { label: 'Ordóñez', value: 'Ordóñez' },
  { label: 'Ordos', value: 'Ordos' },
  { label: 'Ordos Shi', value: 'Ordos Shi' },
  { label: 'Ordubad', value: 'Ordubad' },
  { label: 'Ordubad Rayon', value: 'Ordubad Rayon' },
  { label: 'Orduña De Abajo', value: 'Orduña De Abajo' },
  { label: 'Orduña De Arriba', value: 'Orduña De Arriba' },
  { label: 'Ordway', value: 'Ordway' },
  { label: 'Ordynskiy Rayon', value: 'Ordynskiy Rayon' },
  { label: 'Ordynskoye', value: 'Ordynskoye' },
  { label: 'Ordzhonikidze', value: 'Ordzhonikidze' },
  { label: 'Ordzhonikidze', value: 'Ordzhonikidze' },
  { label: 'Ordzhonikidze', value: 'Ordzhonikidze' },
  { label: 'Ordzhonikidzevskiy', value: 'Ordzhonikidzevskiy' },
  { label: 'Ore', value: 'Ore' },
  { label: 'Ore City', value: 'Ore City' },
  { label: 'Orea', value: 'Orea' },
  { label: 'Oreamuno', value: 'Oreamuno' },
  { label: 'Oreavu', value: 'Oreavu' },
  { label: 'Orebić', value: 'Orebić' },
  { label: 'Örebro', value: 'Örebro' },
  { label: 'Ořechov', value: 'Ořechov' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Oregon City', value: 'Oregon City' },
  { label: 'Oregon County', value: 'Oregon County' },
  { label: 'Öregrund', value: 'Öregrund' },
  { label: 'Orehovica', value: 'Orehovica' },
  { label: 'Orejana', value: 'Orejana' },
  { label: 'Orekhovo', value: 'Orekhovo' },
  { label: 'Orekhovo-Borisovo', value: 'Orekhovo-Borisovo' },
  { label: 'Orekhovo-Borisovo Severnoye', value: 'Orekhovo-Borisovo Severnoye' },
  { label: 'Orekhovo-Zuyevo', value: 'Orekhovo-Zuyevo' },
  { label: 'Orekhovo-Zuyevskiy Rayon', value: 'Orekhovo-Zuyevskiy Rayon' },
  { label: 'Orël', value: 'Orël' },
  { label: 'Oreland', value: 'Oreland' },
  { label: 'Orelia', value: 'Orelia' },
  { label: 'Orël-Izumrud', value: 'Orël-Izumrud' },
  { label: 'Orellana De La Sierra', value: 'Orellana De La Sierra' },
  { label: 'Orellana La Vieja', value: 'Orellana La Vieja' },
  { label: 'Orem', value: 'Orem' },
  { label: 'Orenburg', value: 'Orenburg' },
  { label: 'Orendain', value: 'Orendain' },
  { label: 'Orenhofen', value: 'Orenhofen' },
  { label: 'Orentano', value: 'Orentano' },
  { label: 'Oreoí', value: 'Oreoí' },
  { label: 'Orera', value: 'Orera' },
  { label: 'Orero', value: 'Orero' },
  { label: 'Orerokpe', value: 'Orerokpe' },
  { label: 'Orés', value: 'Orés' },
  { label: 'Orešje', value: 'Orešje' },
  { label: 'Orestiáda', value: 'Orestiáda' },
  { label: 'Orevița Mare', value: 'Orevița Mare' },
  { label: 'Orexa', value: 'Orexa' },
  { label: 'Oreye', value: 'Oreye' },
  { label: 'Orford', value: 'Orford' },
  { label: 'Orfordville', value: 'Orfordville' },
  { label: 'Organyà', value: 'Organyà' },
  { label: 'Orgaz', value: 'Orgaz' },
  { label: 'Orgelet', value: 'Orgelet' },
  { label: 'Orgères', value: 'Orgères' },
  { label: 'Orgères-En-Beauce', value: 'Orgères-En-Beauce' },
  { label: 'Orgerus', value: 'Orgerus' },
  { label: 'Orgeval', value: 'Orgeval' },
  { label: 'Orgiano', value: 'Orgiano' },
  { label: 'Órgiva', value: 'Órgiva' },
  { label: 'Orgon', value: 'Orgon' },
  { label: 'Orgosolo', value: 'Orgosolo' },
  { label: 'Orgovány', value: 'Orgovány' },
  { label: 'Orgtrud', value: 'Orgtrud' },
  { label: 'Orgueil', value: 'Orgueil' },
  { label: 'Orguz', value: 'Orguz' },
  { label: 'Orhaneli', value: 'Orhaneli' },
  { label: 'Orhangazi', value: 'Orhangazi' },
  { label: 'Orhei', value: 'Orhei' },
  { label: 'Oria', value: 'Oria' },
  { label: 'Oria', value: 'Oria' },
  { label: 'Oria Arriba', value: 'Oria Arriba' },
  { label: 'Orica', value: 'Orica' },
  { label: 'Orichevskiy Rayon', value: 'Orichevskiy Rayon' },
  { label: 'Orichi', value: 'Orichi' },
  { label: 'Oricola', value: 'Oricola' },
  { label: 'Oriental', value: 'Oriental' },
  { label: 'Oriental De Zapata', value: 'Oriental De Zapata' },
  { label: 'Oriente', value: 'Oriente' },
  { label: 'Origgio', value: 'Origgio' },
  { label: 'Origny-En-Thiérache', value: 'Origny-En-Thiérache' },
  { label: 'Origny-Sainte-Benoite', value: 'Origny-Sainte-Benoite' },
  { label: 'Orihuela', value: 'Orihuela' },
  { label: 'Orihuela Del Tremedal', value: 'Orihuela Del Tremedal' },
  { label: 'Orikhiv', value: 'Orikhiv' },
  { label: 'Orikum', value: 'Orikum' },
  { label: 'Orilla Del Monte', value: 'Orilla Del Monte' },
  { label: 'Orillia', value: 'Orillia' },
  { label: 'Orimattila', value: 'Orimattila' },
  { label: 'Orinda', value: 'Orinda' },
  { label: 'Orindiúva', value: 'Orindiúva' },
  { label: 'Orino', value: 'Orino' },
  { label: 'Orio', value: 'Orio' },
  { label: 'Orio Al Serio', value: 'Orio Al Serio' },
  { label: 'Orio Canavese', value: 'Orio Canavese' },
  { label: 'Orio Litta', value: 'Orio Litta' },
  { label: 'Oriole Beach', value: 'Oriole Beach' },
  { label: 'Oriolo', value: 'Oriolo' },
  { label: 'Oriolo Romano', value: 'Oriolo Romano' },
  { label: 'Orion', value: 'Orion' },
  { label: 'Orion', value: 'Orion' },
  { label: 'Orion', value: 'Orion' },
  { label: 'Oriovac', value: 'Oriovac' },
  { label: 'Oripää', value: 'Oripää' },
  { label: 'Orís', value: 'Orís' },
  { label: 'Oriskany', value: 'Oriskany' },
  { label: 'Orísoain', value: 'Orísoain' },
  { label: 'Orissaare', value: 'Orissaare' },
  { label: 'Oristà', value: 'Oristà' },
  { label: 'Oristano', value: 'Oristano' },
  { label: 'Orita Eruwa', value: 'Orita Eruwa' },
  { label: 'Orito', value: 'Orito' },
  { label: 'Orival', value: 'Orival' },
  { label: 'Orivesi', value: 'Orivesi' },
  { label: 'Oriximiná', value: 'Oriximiná' },
  { label: 'Orizaba', value: 'Orizaba' },
  { label: 'Orizaba', value: 'Orizaba' },
  { label: 'Orizabita', value: 'Orizabita' },
  { label: 'Orizânia', value: 'Orizânia' },
  { label: 'Orizari', value: 'Orizari' },
  { label: 'Orizona', value: 'Orizona' },
  { label: 'Ørje', value: 'Ørje' },
  { label: 'Orjonikidze', value: 'Orjonikidze' },
  { label: 'Orkdal', value: 'Orkdal' },
  { label: 'Örkelljunga', value: 'Örkelljunga' },
  { label: 'Örkény', value: 'Örkény' },
  { label: 'Orkney', value: 'Orkney' },
  { label: 'Orkney', value: 'Orkney' },
  { label: 'Orkney Islands', value: 'Orkney Islands' },
  { label: 'Orkoien', value: 'Orkoien' },
  { label: 'Orla', value: 'Orla' },
  { label: 'Orlamünde', value: 'Orlamünde' },
  { label: 'Orland', value: 'Orland' },
  { label: 'Orland', value: 'Orland' },
  { label: 'Ørland', value: 'Ørland' },
  { label: 'Orland Hills', value: 'Orland Hills' },
  { label: 'Orland Park', value: 'Orland Park' },
  { label: 'Orlândia', value: 'Orlândia' },
  { label: 'Orlando', value: 'Orlando' },
  { label: 'Orlat', value: 'Orlat' },
  { label: 'Orle', value: 'Orle' },
  { label: 'Orlea', value: 'Orlea' },
  { label: 'Orlea Nouă', value: 'Orlea Nouă' },
  { label: 'Orleans', value: 'Orleans' },
  { label: 'Orleans', value: 'Orleans' },
  { label: 'Orleans', value: 'Orleans' },
  { label: 'Orléans', value: 'Orléans' },
  { label: 'Orleans County', value: 'Orleans County' },
  { label: 'Orleans County', value: 'Orleans County' },
  { label: 'Orleans Parish', value: 'Orleans Parish' },
  { label: 'Orléat', value: 'Orléat' },
  { label: 'Orleix', value: 'Orleix' },
  { label: 'Orleşti', value: 'Orleşti' },
  { label: 'Orleton', value: 'Orleton' },
  { label: 'Orliénas', value: 'Orliénas' },
  { label: 'Orlik', value: 'Orlik' },
  { label: 'Orlinoye', value: 'Orlinoye' },
  { label: 'Orlov', value: 'Orlov' },
  { label: 'Orlová', value: 'Orlová' },
  { label: 'Orlovat', value: 'Orlovat' },
  { label: 'Orlovista', value: 'Orlovista' },
  { label: 'Orlovka', value: 'Orlovka' },
  { label: 'Orlovo', value: 'Orlovo' },
  { label: 'Orlovskiy', value: 'Orlovskiy' },
  { label: 'Orlovskiy', value: 'Orlovskiy' },
  { label: 'Orlovskiy Rayon', value: 'Orlovskiy Rayon' },
  { label: 'Orlovskoye', value: 'Orlovskoye' },
  { label: 'Orlu', value: 'Orlu' },
  { label: 'Orly', value: 'Orly' },
  { label: 'Orły', value: 'Orły' },
  { label: 'Ormaiztegi', value: 'Ormaiztegi' },
  { label: 'Ormalingen', value: 'Ormalingen' },
  { label: 'Ormara', value: 'Ormara' },
  { label: 'Ormea', value: 'Ormea' },
  { label: 'Ormeau', value: 'Ormeau' },
  { label: 'Ormeau Hills', value: 'Ormeau Hills' },
  { label: 'Ormelle', value: 'Ormelle' },
  { label: 'Ormeniş', value: 'Ormeniş' },
  { label: 'Ormeniș', value: 'Ormeniș' },
  { label: 'Ormes', value: 'Ormes' },
  { label: 'Ormesby St Margaret', value: 'Ormesby St Margaret' },
  { label: 'Ormesson-Sur-Marne', value: 'Ormesson-Sur-Marne' },
  { label: 'Ormiston', value: 'Ormiston' },
  { label: 'Ormiston', value: 'Ormiston' },
  { label: 'Ormoc', value: 'Ormoc' },
  { label: 'Ormoc', value: 'Ormoc' },
  { label: 'Ormond', value: 'Ormond' },
  { label: 'Ormond Beach', value: 'Ormond Beach' },
  { label: 'Ormond-By-The-Sea', value: 'Ormond-By-The-Sea' },
  { label: 'Ormoy', value: 'Ormoy' },
  { label: 'Ormož', value: 'Ormož' },
  { label: 'Ormskirk', value: 'Ormskirk' },
  { label: 'Ormstown', value: 'Ormstown' },
  { label: 'Ormýlia', value: 'Ormýlia' },
  { label: 'Ornago', value: 'Ornago' },
  { label: 'Ornaisons', value: 'Ornaisons' },
  { label: 'Ornans', value: 'Ornans' },
  { label: 'Ornäs', value: 'Ornäs' },
  { label: 'Ornavasso', value: 'Ornavasso' },
  { label: 'Ornbau', value: 'Ornbau' },
  { label: 'Ørnes', value: 'Ørnes' },
  { label: 'Orneta', value: 'Orneta' },
  { label: 'Ornex', value: 'Ornex' },
  { label: 'Ornica', value: 'Ornica' },
  { label: 'Ornontowice', value: 'Ornontowice' },
  { label: 'Ornós', value: 'Ornós' },
  { label: 'Örnsköldsvik', value: 'Örnsköldsvik' },
  { label: 'Oro Valley', value: 'Oro Valley' },
  { label: 'Oro Verde', value: 'Oro Verde' },
  { label: 'Orobó', value: 'Orobó' },
  { label: 'Orocó', value: 'Orocó' },
  { label: 'Orocovis', value: 'Orocovis' },
  { label: 'Orocovis', value: 'Orocovis' },
  { label: 'Orocovis Municipio', value: 'Orocovis Municipio' },
  { label: 'Orocué', value: 'Orocué' },
  { label: 'Orocuina', value: 'Orocuina' },
  { label: 'Orodel', value: 'Orodel' },
  { label: 'Orodo', value: 'Orodo' },
  { label: 'Orofino', value: 'Orofino' },
  { label: 'Oroftiana', value: 'Oroftiana' },
  { label: 'Oroiu', value: 'Oroiu' },
  { label: 'Oromocto', value: 'Oromocto' },
  { label: 'Orong', value: 'Orong' },
  { label: 'Oron-La-Ville', value: 'Oron-La-Ville' },
  { label: 'Orono', value: 'Orono' },
  { label: 'Orono', value: 'Orono' },
  { label: 'Oronoco', value: 'Oronoco' },
  { label: 'Oronogo', value: 'Oronogo' },
  { label: 'Orońsko', value: 'Orońsko' },
  { label: 'Oronz', value: 'Oronz' },
  { label: 'Oronz/Orontze', value: 'Oronz/Orontze' },
  { label: 'Oropéndolas', value: 'Oropéndolas' },
  { label: 'Oropesa', value: 'Oropesa' },
  { label: 'Oropesa', value: 'Oropesa' },
  { label: 'Oropesa Del Mar', value: 'Oropesa Del Mar' },
  { label: 'Oropesa Del Mar/Orpesa', value: 'Oropesa Del Mar/Orpesa' },
  { label: 'Oropolí', value: 'Oropolí' },
  { label: 'Oropós', value: 'Oropós' },
  { label: 'Oroqen Zizhiqi', value: 'Oroqen Zizhiqi' },
  { label: 'Oroquieta', value: 'Oroquieta' },
  { label: 'Orós', value: 'Orós' },
  { label: 'Orosei', value: 'Orosei' },
  { label: 'Orosháza', value: 'Orosháza' },
  { label: 'Orosházi Járás', value: 'Orosházi Járás' },
  { label: 'Orosi', value: 'Orosi' },
  { label: 'Orosí', value: 'Orosí' },
  { label: 'Orosia', value: 'Orosia' },
  { label: 'Oroslavje', value: 'Oroslavje' },
  { label: 'Oroso', value: 'Oroso' },
  { label: 'Oroszlány', value: 'Oroszlány' },
  { label: 'Orotava La', value: 'Orotava La' },
  { label: 'Orotelli', value: 'Orotelli' },
  { label: 'Orotina', value: 'Orotina' },
  { label: 'Orotukan', value: 'Orotukan' },
  { label: 'Oroville', value: 'Oroville' },
  { label: 'Oroville', value: 'Oroville' },
  { label: 'Oroville East', value: 'Oroville East' },
  { label: 'Oroz-Betelu/Orotz-Betelu', value: 'Oroz-Betelu/Orotz-Betelu' },
  { label: 'Orozko', value: 'Orozko' },
  { label: 'Orpí', value: 'Orpí' },
  { label: 'Orpington', value: 'Orpington' },
  { label: 'Orpund', value: 'Orpund' },
  { label: 'Orranteño', value: 'Orranteño' },
  { label: 'Orreaga/Roncesvalles', value: 'Orreaga/Roncesvalles' },
  { label: 'Orria', value: 'Orria' },
  { label: 'Orrington', value: 'Orrington' },
  { label: 'Orrios', value: 'Orrios' },
  { label: 'Òrrius', value: 'Òrrius' },
  { label: 'Orroli', value: 'Orroli' },
  { label: 'Orroroo/Carrieton', value: 'Orroroo/Carrieton' },
  { label: 'Orrville', value: 'Orrville' },
  { label: 'Orry-La-Ville', value: 'Orry-La-Ville' },
  { label: 'Orsa', value: 'Orsa' },
  { label: 'Orsago', value: 'Orsago' },
  { label: 'Orsan', value: 'Orsan' },
  { label: 'Orsant’Ia', value: 'Orsant’Ia' },
  { label: 'Orsara Bormida', value: 'Orsara Bormida' },
  { label: 'Orsara Di Puglia', value: 'Orsara Di Puglia' },
  { label: 'Orsaria', value: 'Orsaria' },
  { label: 'Orsay', value: 'Orsay' },
  { label: 'Orscholz', value: 'Orscholz' },
  { label: 'Orsenigo', value: 'Orsenigo' },
  { label: 'Orsett', value: 'Orsett' },
  { label: 'Orsha', value: 'Orsha' },
  { label: 'Orsha', value: 'Orsha' },
  { label: 'Orshanka', value: 'Orshanka' },
  { label: 'Orshanskiy Rayon', value: 'Orshanskiy Rayon' },
  { label: 'Orsières', value: 'Orsières' },
  { label: 'Orsingen-Nenzingen', value: 'Orsingen-Nenzingen' },
  { label: 'Orsk', value: 'Orsk' },
  { label: 'Ørskog', value: 'Ørskog' },
  { label: 'Ørslev', value: 'Ørslev' },
  { label: 'Orsogna', value: 'Orsogna' },
  { label: 'Orsomarso', value: 'Orsomarso' },
  { label: 'Orşova', value: 'Orşova' },
  { label: 'Orșova', value: 'Orșova' },
  { label: 'Ørsta', value: 'Ørsta' },
  { label: 'Örsundsbro', value: 'Örsundsbro' },
  { label: 'Ort Im Innkreis', value: 'Ort Im Innkreis' },
  { label: 'Orta', value: 'Orta' },
  { label: 'Orta Di Atella', value: 'Orta Di Atella' },
  { label: 'Orta Nova', value: 'Orta Nova' },
  { label: 'Orta San Giulio', value: 'Orta San Giulio' },
  { label: 'Ortaca', value: 'Ortaca' },
  { label: 'Ortacesus', value: 'Ortacesus' },
  { label: 'Ortaffa', value: 'Ortaffa' },
  { label: 'Ortahisar', value: 'Ortahisar' },
  { label: 'Ortaköy', value: 'Ortaköy' },
  { label: 'Ortaköy', value: 'Ortaköy' },
  { label: 'Orte', value: 'Orte' },
  { label: 'Orte Scalo', value: 'Orte Scalo' },
  { label: 'Ortega', value: 'Ortega' },
  { label: 'Ortelle', value: 'Ortelle' },
  { label: 'Ortenberg', value: 'Ortenberg' },
  { label: 'Ortenberg', value: 'Ortenberg' },
  { label: 'Ortenburg', value: 'Ortenburg' },
  { label: 'Ortezzano', value: 'Ortezzano' },
  { label: 'Ortgraben', value: 'Ortgraben' },
  { label: 'Orth An Der Donau', value: 'Orth An Der Donau' },
  { label: 'Orthen', value: 'Orthen' },
  { label: 'Orthez', value: 'Orthez' },
  { label: 'Ortignano Raggiolo', value: 'Ortignano Raggiolo' },
  { label: 'Ortigosa De Cameros', value: 'Ortigosa De Cameros' },
  { label: 'Ortigosa De Pestaño', value: 'Ortigosa De Pestaño' },
  { label: 'Ortigosa Del Monte', value: 'Ortigosa Del Monte' },
  { label: 'Ortigueira', value: 'Ortigueira' },
  { label: 'Ortigueira', value: 'Ortigueira' },
  { label: 'Orting', value: 'Orting' },
  { label: 'Ortisei', value: 'Ortisei' },
  { label: 'Orţişoara', value: 'Orţişoara' },
  { label: 'Orțița', value: 'Orțița' },
  { label: 'Ortiz', value: 'Ortiz' },
  { label: 'Ortona', value: 'Ortona' },
  { label: 'Ortona Dei Marsi', value: 'Ortona Dei Marsi' },
  { label: 'Ortonville', value: 'Ortonville' },
  { label: 'Ortonville', value: 'Ortonville' },
  { label: 'Ortovero', value: 'Ortovero' },
  { label: 'Ortrand', value: 'Ortrand' },
  { label: 'Ortucchio', value: 'Ortucchio' },
  { label: 'Ortuella', value: 'Ortuella' },
  { label: 'Ortueri', value: 'Ortueri' },
  { label: 'Orud’Yevo', value: 'Orud’Yevo' },
  { label: 'Orune', value: 'Orune' },
  { label: 'Oruro', value: 'Oruro' },
  { label: 'Orusco De Tajuña', value: 'Orusco De Tajuña' },
  { label: 'Orust', value: 'Orust' },
  { label: 'Orval', value: 'Orval' },
  { label: 'Orvault', value: 'Orvault' },
  { label: 'Orvieto', value: 'Orvieto' },
  { label: 'Orvieto Scalo', value: 'Orvieto Scalo' },
  { label: 'Orvin', value: 'Orvin' },
  { label: 'Orvinio', value: 'Orvinio' },
  { label: 'Orwell', value: 'Orwell' },
  { label: 'Orwell', value: 'Orwell' },
  { label: 'Orwigsburg', value: 'Orwigsburg' },
  { label: 'Orxeta', value: 'Orxeta' },
  { label: 'Oryahovo', value: 'Oryahovo' },
  { label: 'Orzech', value: 'Orzech' },
  { label: 'Orzechówka', value: 'Orzechówka' },
  { label: 'Orzesze', value: 'Orzesze' },
  { label: 'Orzhiv', value: 'Orzhiv' },
  { label: 'Orzhytsia', value: 'Orzhytsia' },
  { label: 'Orzignano', value: 'Orzignano' },
  { label: 'Orzinuovi', value: 'Orzinuovi' },
  { label: 'Orzivecchi', value: 'Orzivecchi' },
  { label: 'Orzu', value: 'Orzu' },
  { label: 'Orzueeyeh', value: 'Orzueeyeh' },
  { label: 'Orzuiyeh', value: 'Orzuiyeh' },
  { label: 'Orzysz', value: 'Orzysz' },
  { label: 'Os', value: 'Os' },
  { label: 'Os', value: 'Os' },
  { label: 'Os De Balaguer', value: 'Os De Balaguer' },
  { label: 'Osa', value: 'Osa' },
  { label: 'Osa', value: 'Osa' },
  { label: 'Osa', value: 'Osa' },
  { label: 'Osa De La Vega', value: 'Osa De La Vega' },
  { label: 'Osage', value: 'Osage' },
  { label: 'Osage Beach', value: 'Osage Beach' },
  { label: 'Osage City', value: 'Osage City' },
  { label: 'Osage County', value: 'Osage County' },
  { label: 'Osage County', value: 'Osage County' },
  { label: 'Osage County', value: 'Osage County' },
  { label: 'Osaka', value: 'Osaka' },
  { label: 'Osakarovka', value: 'Osakarovka' },
  { label: 'Ōsaka-Sayama Shi', value: 'Ōsaka-Sayama Shi' },
  { label: 'Ōsaki', value: 'Ōsaki' },
  { label: 'Ōsaki Shi', value: 'Ōsaki Shi' },
  { label: 'Osakis', value: 'Osakis' },
  { label: 'Osan', value: 'Osan' },
  { label: 'Osann-Monzel', value: 'Osann-Monzel' },
  { label: 'Osan-Si', value: 'Osan-Si' },
  { label: 'Osara', value: 'Osara' },
  { label: 'Osasco', value: 'Osasco' },
  { label: 'Osasco', value: 'Osasco' },
  { label: 'Osasio', value: 'Osasio' },
  { label: 'Ōsato-Gun', value: 'Ōsato-Gun' },
  { label: 'Osawatomie', value: 'Osawatomie' },
  { label: 'Osborne', value: 'Osborne' },
  { label: 'Osborne', value: 'Osborne' },
  { label: 'Osborne County', value: 'Osborne County' },
  { label: 'Osborne Park', value: 'Osborne Park' },
  { label: 'Osbourne Store', value: 'Osbourne Store' },
  { label: 'Osburg', value: 'Osburg' },
  { label: 'Osburn', value: 'Osburn' },
  { label: 'Osby', value: 'Osby' },
  { label: 'Oscano', value: 'Oscano' },
  { label: 'Oscar Bressane', value: 'Oscar Bressane' },
  { label: 'Oscar Soto Maynez', value: 'Oscar Soto Maynez' },
  { label: 'Osceola', value: 'Osceola' },
  { label: 'Osceola', value: 'Osceola' },
  { label: 'Osceola', value: 'Osceola' },
  { label: 'Osceola', value: 'Osceola' },
  { label: 'Osceola', value: 'Osceola' },
  { label: 'Osceola', value: 'Osceola' },
  { label: 'Osceola County', value: 'Osceola County' },
  { label: 'Osceola County', value: 'Osceola County' },
  { label: 'Osceola County', value: 'Osceola County' },
  { label: 'Osceola Mills', value: 'Osceola Mills' },
  { label: 'Oschatz', value: 'Oschatz' },
  { label: 'Oschersleben', value: 'Oschersleben' },
  { label: 'Oschiri', value: 'Oschiri' },
  { label: 'Oscoda County', value: 'Oscoda County' },
  { label: 'Osdorf', value: 'Osdorf' },
  { label: 'Osdorp', value: 'Osdorp' },
  { label: 'Osebiți', value: 'Osebiți' },
  { label: 'Osečina', value: 'Osečina' },
  { label: 'Osečná', value: 'Osečná' },
  { label: 'Oseja', value: 'Oseja' },
  { label: 'Oseja De Sajambre', value: 'Oseja De Sajambre' },
  { label: 'Osek', value: 'Osek' },
  { label: 'Osek', value: 'Osek' },
  { label: 'Osek Nad Bečvou', value: 'Osek Nad Bečvou' },
  { label: 'Osel’Ki', value: 'Osel’Ki' },
  { label: 'Osen', value: 'Osen' },
  { label: 'Osera De Ebro', value: 'Osera De Ebro' },
  { label: 'Oşeşti', value: 'Oşeşti' },
  { label: 'Osgood', value: 'Osgood' },
  { label: 'Osgoode', value: 'Osgoode' },
  { label: 'Osh', value: 'Osh' },
  { label: 'Oshakan', value: 'Oshakan' },
  { label: 'Oshakati', value: 'Oshakati' },
  { label: 'Oshawa', value: 'Oshawa' },
  { label: 'Ōshika', value: 'Ōshika' },
  { label: 'Oshikango', value: 'Oshikango' },
  { label: 'Oshkosh', value: 'Oshkosh' },
  { label: 'Oshkosh', value: 'Oshkosh' },
  { label: 'Oshnavieh', value: 'Oshnavieh' },
  { label: 'Oshnaviyeh', value: 'Oshnaviyeh' },
  { label: 'Oshodi-Isolo', value: 'Oshodi-Isolo' },
  { label: 'Oshtoran', value: 'Oshtoran' },
  { label: 'Oshtorinan', value: 'Oshtorinan' },
  { label: 'Oshtorjin', value: 'Oshtorjin' },
  { label: 'Ōshū', value: 'Ōshū' },
  { label: 'Ōshū-Shi', value: 'Ōshū-Shi' },
  { label: 'Ősi', value: 'Ősi' },
  { label: 'Osiao', value: 'Osiao' },
  { label: 'Osias', value: 'Osias' },
  { label: 'Osias', value: 'Osias' },
  { label: 'Osica De Jos', value: 'Osica De Jos' },
  { label: 'Osica De Sus', value: 'Osica De Sus' },
  { label: 'Osidda', value: 'Osidda' },
  { label: 'Osie', value: 'Osie' },
  { label: 'Osięciny', value: 'Osięciny' },
  { label: 'Osieck', value: 'Osieck' },
  { label: 'Osieczany', value: 'Osieczany' },
  { label: 'Osieczna', value: 'Osieczna' },
  { label: 'Osiedle Henrykowskie', value: 'Osiedle Henrykowskie' },
  { label: 'Osiedle Kosmonautów', value: 'Osiedle Kosmonautów' },
  { label: 'Osiedle-Nowiny', value: 'Osiedle-Nowiny' },
  { label: 'Osiek', value: 'Osiek' },
  { label: 'Osiek', value: 'Osiek' },
  { label: 'Osiek', value: 'Osiek' },
  { label: 'Osiek', value: 'Osiek' },
  { label: 'Osiek', value: 'Osiek' },
  { label: 'Osiek Jasielski', value: 'Osiek Jasielski' },
  { label: 'Osiek Mały', value: 'Osiek Mały' },
  { label: 'Osiek Nad Notecią', value: 'Osiek Nad Notecią' },
  { label: 'Osielec', value: 'Osielec' },
  { label: 'Osielsko', value: 'Osielsko' },
  { label: 'Osiglia', value: 'Osiglia' },
  { label: 'Osigo', value: 'Osigo' },
  { label: 'Osijek', value: 'Osijek' },
  { label: 'Osík', value: 'Osík' },
  { label: 'Osilnica', value: 'Osilnica' },
  { label: 'Osilo', value: 'Osilo' },
  { label: 'Osimo', value: 'Osimo' },
  { label: 'Osina', value: 'Osina' },
  { label: 'Osini', value: 'Osini' },
  { label: 'Osinki', value: 'Osinki' },
  { label: 'Osinniki', value: 'Osinniki' },
  { label: 'Osinovo', value: 'Osinovo' },
  { label: 'Osio Sopra', value: 'Osio Sopra' },
  { label: 'Osio Sotto', value: 'Osio Sotto' },
  { label: 'Osjaków', value: 'Osjaków' },
  { label: 'Osječenik', value: 'Osječenik' },
  { label: 'Oskaloosa', value: 'Oskaloosa' },
  { label: 'Oskaloosa', value: 'Oskaloosa' },
  { label: 'Oskarshamn', value: 'Oskarshamn' },
  { label: 'Oskarström', value: 'Oskarström' },
  { label: 'Oskava', value: 'Oskava' },
  { label: 'Osku', value: 'Osku' },
  { label: 'Oslavany', value: 'Oslavany' },
  { label: 'Oslip', value: 'Oslip' },
  { label: 'Osljak', value: 'Osljak' },
  { label: 'Oslo', value: 'Oslo' },
  { label: 'Oslob', value: 'Oslob' },
  { label: 'Osloß', value: 'Osloß' },
  { label: 'Osmanabad', value: 'Osmanabad' },
  { label: 'Osmancık', value: 'Osmancık' },
  { label: 'Osmaneli', value: 'Osmaneli' },
  { label: 'Osmangazi', value: 'Osmangazi' },
  { label: 'Osmate', value: 'Osmate' },
  { label: 'Osmeña', value: 'Osmeña' },
  { label: 'Osmeña', value: 'Osmeña' },
  { label: 'Ösmo', value: 'Ösmo' },
  { label: 'Osnabrück', value: 'Osnabrück' },
  { label: 'Osnago', value: 'Osnago' },
  { label: 'Ośno Lubuskie', value: 'Ośno Lubuskie' },
  { label: 'Osny', value: 'Osny' },
  { label: 'Oso El', value: 'Oso El' },
  { label: 'Oso Viejo', value: 'Oso Viejo' },
  { label: 'Osoblaha', value: 'Osoblaha' },
  { label: 'Osogbo', value: 'Osogbo' },
  { label: 'Osoi', value: 'Osoi' },
  { label: 'Osoppo', value: 'Osoppo' },
  { label: 'Osor', value: 'Osor' },
  { label: 'Oșorhei', value: 'Oșorhei' },
  { label: 'Osório', value: 'Osório' },
  { label: 'Osornillo', value: 'Osornillo' },
  { label: 'Osorno', value: 'Osorno' },
  { label: 'Osorno La Mayor', value: 'Osorno La Mayor' },
  { label: 'Osoyoos', value: 'Osoyoos' },
  { label: 'Ospedaletti', value: 'Ospedaletti' },
  { label: 'Ospedaletto', value: 'Ospedaletto' },
  { label: 'Ospedaletto', value: 'Ospedaletto' },
  { label: 'Ospedaletto', value: 'Ospedaletto' },
  { label: 'Ospedaletto Dalpinolo', value: 'Ospedaletto Dalpinolo' },
  { label: 'Ospedaletto Euganeo', value: 'Ospedaletto Euganeo' },
  { label: 'Ospedaletto Lodigiano', value: 'Ospedaletto Lodigiano' },
  { label: 'Ospedalicchio', value: 'Ospedalicchio' },
  { label: 'Ospel', value: 'Ospel' },
  { label: 'Ospina', value: 'Ospina' },
  { label: 'Ospitale Di Cadore', value: 'Ospitale Di Cadore' },
  { label: 'Ospitaletto', value: 'Ospitaletto' },
  { label: 'Osprey', value: 'Osprey' },
  { label: 'Oss', value: 'Oss' },
  { label: 'Ossa De Montiel', value: 'Ossa De Montiel' },
  { label: 'Ossago Lodigiano', value: 'Ossago Lodigiano' },
  { label: 'Ossana', value: 'Ossana' },
  { label: 'Ossé', value: 'Ossé' },
  { label: 'Osséja', value: 'Osséja' },
  { label: 'Ossendorf', value: 'Ossendorf' },
  { label: 'Ossendrecht', value: 'Ossendrecht' },
  { label: 'Osseo', value: 'Osseo' },
  { label: 'Osseo', value: 'Osseo' },
  { label: 'Ossett', value: 'Ossett' },
  { label: 'Ossi', value: 'Ossi' },
  { label: 'Ossiach', value: 'Ossiach' },
  { label: 'Ossian', value: 'Ossian' },
  { label: 'Ossimo Superiore', value: 'Ossimo Superiore' },
  { label: 'Ossining', value: 'Ossining' },
  { label: 'Ossipee', value: 'Ossipee' },
  { label: 'Oßling', value: 'Oßling' },
  { label: 'Oßmanstedt', value: 'Oßmanstedt' },
  { label: 'Osso De Cinca', value: 'Osso De Cinca' },
  { label: 'Ossó De Sió', value: 'Ossó De Sió' },
  { label: 'Ossona', value: 'Ossona' },
  { label: 'Ossora', value: 'Ossora' },
  { label: 'Ossu', value: 'Ossu' },
  { label: 'Ossuccio', value: 'Ossuccio' },
  { label: 'Ossun', value: 'Ossun' },
  { label: 'Ossun', value: 'Ossun' },
  { label: 'Ostana', value: 'Ostana' },
  { label: 'Ostanino', value: 'Ostanino' },
  { label: 'Ostankinskiy', value: 'Ostankinskiy' },
  { label: 'Ostashëvo', value: 'Ostashëvo' },
  { label: 'Ostashkov', value: 'Ostashkov' },
  { label: 'Ostashkovskiy Rayon', value: 'Ostashkovskiy Rayon' },
  { label: 'Ostaszewo', value: 'Ostaszewo' },
  { label: 'Ostbevern', value: 'Ostbevern' },
  { label: 'Osted', value: 'Osted' },
  { label: 'Osteel', value: 'Osteel' },
  { label: 'Ostellato', value: 'Ostellato' },
  { label: 'Ostelsheim', value: 'Ostelsheim' },
  { label: 'Osten', value: 'Osten' },
  { label: 'Ostend', value: 'Ostend' },
  { label: 'Ostenfeld', value: 'Ostenfeld' },
  { label: 'Osteno', value: 'Osteno' },
  { label: 'Osteno-Claino', value: 'Osteno-Claino' },
  { label: 'Oster', value: 'Oster' },
  { label: 'Oster', value: 'Oster' },
  { label: 'Osterburg', value: 'Osterburg' },
  { label: 'Osterburken', value: 'Osterburken' },
  { label: 'Österbybruk', value: 'Österbybruk' },
  { label: 'Österbymo', value: 'Österbymo' },
  { label: 'Ostercappeln', value: 'Ostercappeln' },
  { label: 'Osterfeld', value: 'Osterfeld' },
  { label: 'Osterhausen', value: 'Osterhausen' },
  { label: 'Osterhofen', value: 'Osterhofen' },
  { label: 'Osterholz-Scharmbeck', value: 'Osterholz-Scharmbeck' },
  { label: 'Osteria', value: 'Osteria' },
  { label: 'Osteria Dei Cacciatori-Stella', value: 'Osteria Dei Cacciatori-Stella' },
  { label: 'Osteria Del Gatto', value: 'Osteria Del Gatto' },
  { label: 'Osteria Grande', value: 'Osteria Grande' },
  { label: 'Osteria Nuova', value: 'Osteria Nuova' },
  { label: 'Osteria Nuova', value: 'Osteria Nuova' },
  { label: 'Östermalm', value: 'Östermalm' },
  { label: 'Ostermiething', value: 'Ostermiething' },
  { label: 'Osternienburg', value: 'Osternienburg' },
  { label: 'Osternienburger Land', value: 'Osternienburger Land' },
  { label: 'Osterode Am Harz', value: 'Osterode Am Harz' },
  { label: 'Osterøy', value: 'Osterøy' },
  { label: 'Osterrönfeld', value: 'Osterrönfeld' },
  { label: 'Osterspai', value: 'Osterspai' },
  { label: 'Östervåla', value: 'Östervåla' },
  { label: 'Osterville', value: 'Osterville' },
  { label: 'Osterwieck', value: 'Osterwieck' },
  { label: 'Osterwitz', value: 'Osterwitz' },
  { label: 'Ostfildern', value: 'Ostfildern' },
  { label: 'Östhammar', value: 'Östhammar' },
  { label: 'Ostheim', value: 'Ostheim' },
  { label: 'Ostheim', value: 'Ostheim' },
  { label: 'Ostheim Vor Der Rhön', value: 'Ostheim Vor Der Rhön' },
  { label: 'Osthofen', value: 'Osthofen' },
  { label: 'Ostiano', value: 'Ostiano' },
  { label: 'Ostiglia', value: 'Ostiglia' },
  { label: 'Ostojićevo', value: 'Ostojićevo' },
  { label: 'Ostopovice', value: 'Ostopovice' },
  { label: 'Ostoros', value: 'Ostoros' },
  { label: 'Ostra', value: 'Ostra' },
  { label: 'Ostra', value: 'Ostra' },
  { label: 'Östra Ljungby', value: 'Östra Ljungby' },
  { label: 'Oštra Luka', value: 'Oštra Luka' },
  { label: 'Ostra Vetere', value: 'Ostra Vetere' },
  { label: 'Ostrach', value: 'Ostrach' },
  { label: 'Ostrau', value: 'Ostrau' },
  { label: 'Ostrava', value: 'Ostrava' },
  { label: 'Ostravice', value: 'Ostravice' },
  { label: 'Østre Toten', value: 'Østre Toten' },
  { label: 'Ostrężnica', value: 'Ostrężnica' },
  { label: 'Ostrhauderfehn', value: 'Ostrhauderfehn' },
  { label: 'Ostricourt', value: 'Ostricourt' },
  { label: 'Östringen', value: 'Östringen' },
  { label: 'Ostritz', value: 'Ostritz' },
  { label: 'Ostróda', value: 'Ostróda' },
  { label: 'Ostrogozhsk', value: 'Ostrogozhsk' },
  { label: 'Ostroh', value: 'Ostroh' },
  { label: 'Ostrołęka', value: 'Ostrołęka' },
  { label: 'Ostroměř', value: 'Ostroměř' },
  { label: 'Ostroróg', value: 'Ostroróg' },
  { label: 'Ostroszowice', value: 'Ostroszowice' },
  { label: 'Ostrov', value: 'Ostrov' },
  { label: 'Ostrov', value: 'Ostrov' },
  { label: 'Ostrov', value: 'Ostrov' },
  { label: 'Ostrov', value: 'Ostrov' },
  { label: 'Ostrov U Macochy', value: 'Ostrov U Macochy' },
  { label: 'Ostroveni', value: 'Ostroveni' },
  { label: 'Ostrovnoy', value: 'Ostrovnoy' },
  { label: 'Ostrovskiy Rayon', value: 'Ostrovskiy Rayon' },
  { label: 'Ostrovskiy Rayon', value: 'Ostrovskiy Rayon' },
  { label: 'Ostrovskoye', value: 'Ostrovskoye' },
  { label: 'Ostrovskoye', value: 'Ostrovskoye' },
  { label: 'Ostrovtsy', value: 'Ostrovtsy' },
  { label: 'Ostrovu', value: 'Ostrovu' },
  { label: 'Ostrovu Mare', value: 'Ostrovu Mare' },
  { label: 'Ostrów', value: 'Ostrów' },
  { label: 'Ostrów Lubelski', value: 'Ostrów Lubelski' },
  { label: 'Ostrów Mazowiecka', value: 'Ostrów Mazowiecka' },
  { label: 'Ostrów Wielkopolski', value: 'Ostrów Wielkopolski' },
  { label: 'Ostrówek', value: 'Ostrówek' },
  { label: 'Ostrówek', value: 'Ostrówek' },
  { label: 'Ostrowiec Świętokrzyski', value: 'Ostrowiec Świętokrzyski' },
  { label: 'Ostrowite', value: 'Ostrowite' },
  { label: 'Ostrowite', value: 'Ostrowite' },
  { label: 'Ostrowsko', value: 'Ostrowsko' },
  { label: 'Ostrowy', value: 'Ostrowy' },
  { label: 'Ostrowy Nad Okszą', value: 'Ostrowy Nad Okszą' },
  { label: 'Ostroz’Ka Mis’Krada', value: 'Ostroz’Ka Mis’Krada' },
  { label: 'Ostrožac', value: 'Ostrožac' },
  { label: 'Ostrožská Lhota', value: 'Ostrožská Lhota' },
  { label: 'Ostrožská Nová Ves', value: 'Ostrožská Nová Ves' },
  { label: 'Ostružnica', value: 'Ostružnica' },
  { label: 'Ostrzeszów', value: 'Ostrzeszów' },
  { label: 'Ostseebad Binz', value: 'Ostseebad Binz' },
  { label: 'Ostseebad Boltenhagen', value: 'Ostseebad Boltenhagen' },
  { label: 'Ostseebad Dierhagen', value: 'Ostseebad Dierhagen' },
  { label: 'Ostseebad Göhren', value: 'Ostseebad Göhren' },
  { label: 'Ostseebad Kühlungsborn', value: 'Ostseebad Kühlungsborn' },
  { label: 'Ostseebad Prerow', value: 'Ostseebad Prerow' },
  { label: 'Ostseebad Sellin', value: 'Ostseebad Sellin' },
  { label: 'Ostseebad Zinnowitz', value: 'Ostseebad Zinnowitz' },
  { label: 'Oststeinbek', value: 'Oststeinbek' },
  { label: 'Ostuacán', value: 'Ostuacán' },
  { label: 'Ostuncalco', value: 'Ostuncalco' },
  { label: 'Ostuni', value: 'Ostuni' },
  { label: 'Ostwald', value: 'Ostwald' },
  { label: 'Osullivan Beach', value: 'Osullivan Beach' },
  { label: 'Osumacinta', value: 'Osumacinta' },
  { label: 'Osuna', value: 'Osuna' },
  { label: 'Osvaldo Cruz', value: 'Osvaldo Cruz' },
  { label: 'Osveya', value: 'Osveya' },
  { label: 'Oswego', value: 'Oswego' },
  { label: 'Oswego', value: 'Oswego' },
  { label: 'Oswego', value: 'Oswego' },
  { label: 'Oswego County', value: 'Oswego County' },
  { label: 'Oswestry', value: 'Oswestry' },
  { label: 'Oświęcim', value: 'Oświęcim' },
  { label: 'Osypenko', value: 'Osypenko' },
  { label: 'Ota', value: 'Ota' },
  { label: 'Ota', value: 'Ota' },
  { label: 'Ōta', value: 'Ōta' },
  { label: 'Otaci', value: 'Otaci' },
  { label: 'Otacílio Costa', value: 'Otacílio Costa' },
  { label: 'Otaghvar', value: 'Otaghvar' },
  { label: 'Ōtake', value: 'Ōtake' },
  { label: 'Ōtake-Shi', value: 'Ōtake-Shi' },
  { label: 'Otaki', value: 'Otaki' },
  { label: 'Ōtaki', value: 'Ōtaki' },
  { label: 'Ōta-Ku', value: 'Ōta-Ku' },
  { label: 'Otan Ayegbaju', value: 'Otan Ayegbaju' },
  { label: 'Otanche', value: 'Otanche' },
  { label: 'Otaniemi', value: 'Otaniemi' },
  { label: 'Otari', value: 'Otari' },
  { label: 'Otaru', value: 'Otaru' },
  { label: 'Otaru-Shi', value: 'Otaru-Shi' },
  { label: 'Ōta-Shi', value: 'Ōta-Shi' },
  { label: 'Otaslavice', value: 'Otaslavice' },
  { label: 'Otates', value: 'Otates' },
  { label: 'Otates', value: 'Otates' },
  { label: 'Otatitlán', value: 'Otatitlán' },
  { label: 'Otatlán', value: 'Otatlán' },
  { label: 'Otavalo', value: 'Otavalo' },
  { label: 'Otavi', value: 'Otavi' },
  { label: 'Ōtawara', value: 'Ōtawara' },
  { label: 'Ōtawara-Shi', value: 'Ōtawara-Shi' },
  { label: 'Oteapan', value: 'Oteapan' },
  { label: 'Otegen Batyra', value: 'Otegen Batyra' },
  { label: 'Oteiza', value: 'Oteiza' },
  { label: 'Otelec', value: 'Otelec' },
  { label: 'Oţeleni', value: 'Oţeleni' },
  { label: 'Otelfingen', value: 'Otelfingen' },
  { label: 'Oţelu Roşu', value: 'Oţelu Roşu' },
  { label: 'Otepää Vald', value: 'Otepää Vald' },
  { label: 'Oterleek', value: 'Oterleek' },
  { label: 'Otero', value: 'Otero' },
  { label: 'Otero County', value: 'Otero County' },
  { label: 'Otero County', value: 'Otero County' },
  { label: 'Otero De Bodas', value: 'Otero De Bodas' },
  { label: 'Otero De Herreros', value: 'Otero De Herreros' },
  { label: 'Oteşani', value: 'Oteşani' },
  { label: 'Otetelișu', value: 'Otetelișu' },
  { label: 'Otford', value: 'Otford' },
  { label: 'Othaya', value: 'Othaya' },
  { label: 'Othello', value: 'Othello' },
  { label: 'Othene', value: 'Othene' },
  { label: 'Othis', value: 'Othis' },
  { label: 'Othmarsingen', value: 'Othmarsingen' },
  { label: 'Othón P. Blanco', value: 'Othón P. Blanco' },
  { label: 'Otice', value: 'Otice' },
  { label: 'Ötigheim', value: 'Ötigheim' },
  { label: 'Otilpan', value: 'Otilpan' },
  { label: 'Otis', value: 'Otis' },
  { label: 'Otis Orchards-East Farms', value: 'Otis Orchards-East Farms' },
  { label: 'Ötisheim', value: 'Ötisheim' },
  { label: 'Otisville', value: 'Otisville' },
  { label: 'Otívar', value: 'Otívar' },
  { label: 'Otjimbingwe', value: 'Otjimbingwe' },
  { label: 'Otjiwarongo', value: 'Otjiwarongo' },
  { label: 'Otkaznoye', value: 'Otkaznoye' },
  { label: 'Otley', value: 'Otley' },
  { label: 'Otlja', value: 'Otlja' },
  { label: 'Otlukbeli', value: 'Otlukbeli' },
  { label: 'Otmuchów', value: 'Otmuchów' },
  { label: 'Otnice', value: 'Otnice' },
  { label: 'Otočac', value: 'Otočac' },
  { label: 'Otoe County', value: 'Otoe County' },
  { label: 'Otofuke', value: 'Otofuke' },
  { label: 'Otok', value: 'Otok' },
  { label: 'Otok', value: 'Otok' },
  { label: 'Otoka', value: 'Otoka' },
  { label: 'Oton', value: 'Oton' },
  { label: 'Otopeni', value: 'Otopeni' },
  { label: 'Otorohanga', value: 'Otorohanga' },
  { label: 'Otos', value: 'Otos' },
  { label: 'Ōtoyo', value: 'Ōtoyo' },
  { label: 'Otra Banda', value: 'Otra Banda' },
  { label: 'Otrada', value: 'Otrada' },
  { label: 'Otradnaya', value: 'Otradnaya' },
  { label: 'Otradnoye', value: 'Otradnoye' },
  { label: 'Otradnoye', value: 'Otradnoye' },
  { label: 'Otradnoye', value: 'Otradnoye' },
  { label: 'Otradnyy', value: 'Otradnyy' },
  { label: 'Otrado-Kubanskoye', value: 'Otrado-Kubanskoye' },
  { label: 'Otranto', value: 'Otranto' },
  { label: 'Otrębusy', value: 'Otrębusy' },
  { label: 'Otricoli', value: 'Otricoli' },
  { label: 'Otrokovice', value: 'Otrokovice' },
  { label: 'Otruba', value: 'Otruba' },
  { label: 'Otse', value: 'Otse' },
  { label: 'Otsego', value: 'Otsego' },
  { label: 'Otsego', value: 'Otsego' },
  { label: 'Otsego County', value: 'Otsego County' },
  { label: 'Otsego County', value: 'Otsego County' },
  { label: 'Ōtsuchi', value: 'Ōtsuchi' },
  { label: 'Ōtsuki', value: 'Ōtsuki' },
  { label: 'Ōtsuki', value: 'Ōtsuki' },
  { label: 'Ōtsuki-Shi', value: 'Ōtsuki-Shi' },
  { label: 'Ōtsu-Shi', value: 'Ōtsu-Shi' },
  { label: 'Otta', value: 'Otta' },
  { label: 'Ottakring', value: 'Ottakring' },
  { label: 'Ottana', value: 'Ottana' },
  { label: 'Ottange', value: 'Ottange' },
  { label: 'Ottappalam', value: 'Ottappalam' },
  { label: 'Ottati', value: 'Ottati' },
  { label: 'Ottava', value: 'Ottava' },
  { label: 'Ottaviano', value: 'Ottaviano' },
  { label: 'Ottawa', value: 'Ottawa' },
  { label: 'Ottawa', value: 'Ottawa' },
  { label: 'Ottawa', value: 'Ottawa' },
  { label: 'Ottawa', value: 'Ottawa' },
  { label: 'Ottawa County', value: 'Ottawa County' },
  { label: 'Ottawa County', value: 'Ottawa County' },
  { label: 'Ottawa County', value: 'Ottawa County' },
  { label: 'Ottawa County', value: 'Ottawa County' },
  { label: 'Ottawa Hills', value: 'Ottawa Hills' },
  { label: 'Ottenbach', value: 'Ottenbach' },
  { label: 'Ottenbach', value: 'Ottenbach' },
  { label: 'Ottendorf-Okrilla', value: 'Ottendorf-Okrilla' },
  { label: 'Ottenhofen', value: 'Ottenhofen' },
  { label: 'Ottenhofen', value: 'Ottenhofen' },
  { label: 'Ottenschlag', value: 'Ottenschlag' },
  { label: 'Ottenschlag Im Mühlkreis', value: 'Ottenschlag Im Mühlkreis' },
  { label: 'Ottensen', value: 'Ottensen' },
  { label: 'Ottensheim', value: 'Ottensheim' },
  { label: 'Ottensoos', value: 'Ottensoos' },
  { label: 'Ottenstein', value: 'Ottenstein' },
  { label: 'Ottenthal', value: 'Ottenthal' },
  { label: 'Otter', value: 'Otter' },
  { label: 'Otter Tail County', value: 'Otter Tail County' },
  { label: 'Otterbach', value: 'Otterbach' },
  { label: 'Otterbein', value: 'Otterbein' },
  { label: 'Otterberg', value: 'Otterberg' },
  { label: 'Otterburn', value: 'Otterburn' },
  { label: 'Otterburn Park', value: 'Otterburn Park' },
  { label: 'Otterfing', value: 'Otterfing' },
  { label: 'Otterlo', value: 'Otterlo' },
  { label: 'Otterndorf', value: 'Otterndorf' },
  { label: 'Ottersberg', value: 'Ottersberg' },
  { label: 'Ottershaw', value: 'Ottershaw' },
  { label: 'Ottersheim', value: 'Ottersheim' },
  { label: 'Otterstadt', value: 'Otterstadt' },
  { label: 'Ottersum', value: 'Ottersum' },
  { label: 'Ottersweier', value: 'Ottersweier' },
  { label: 'Otterswiller', value: 'Otterswiller' },
  { label: 'Otterthal', value: 'Otterthal' },
  { label: 'Otterup', value: 'Otterup' },
  { label: 'Otterwisch', value: 'Otterwisch' },
  { label: 'Ottery St Mary', value: 'Ottery St Mary' },
  { label: 'Öttevény', value: 'Öttevény' },
  { label: 'Ottiglio', value: 'Ottiglio' },
  { label: 'Ottignies', value: 'Ottignies' },
  { label: 'Ottmarsheim', value: 'Ottmarsheim' },
  { label: 'Ottnang Am Hausruck', value: 'Ottnang Am Hausruck' },
  { label: 'Ottobeuren', value: 'Ottobeuren' },
  { label: 'Ottobiano', value: 'Ottobiano' },
  { label: 'Ottobrunn', value: 'Ottobrunn' },
  { label: 'Ottone', value: 'Ottone' },
  { label: 'Ottoway', value: 'Ottoway' },
  { label: 'Ottrau', value: 'Ottrau' },
  { label: 'Ottringham', value: 'Ottringham' },
  { label: 'Ottrott', value: 'Ottrott' },
  { label: 'Ottumwa', value: 'Ottumwa' },
  { label: 'Ottweiler', value: 'Ottweiler' },
  { label: 'Otuke District', value: 'Otuke District' },
  { label: 'Otukpa', value: 'Otukpa' },
  { label: 'Otumba', value: 'Otumba' },
  { label: 'Otura', value: 'Otura' },
  { label: 'Otuzco', value: 'Otuzco' },
  { label: 'Otwock', value: 'Otwock' },
  { label: 'Otxandio', value: 'Otxandio' },
  { label: 'Otyń', value: 'Otyń' },
  { label: 'Otyniya', value: 'Otyniya' },
  { label: 'Otzing', value: 'Otzing' },
  { label: 'Ötzingen', value: 'Ötzingen' },
  { label: 'Otzoloapan', value: 'Otzoloapan' },
  { label: 'Otzolotepec', value: 'Otzolotepec' },
  { label: 'Ötztal-Bahnhof', value: 'Ötztal-Bahnhof' },
  { label: 'Ouachita County', value: 'Ouachita County' },
  { label: 'Ouachita Parish', value: 'Ouachita Parish' },
  { label: 'Ouadda', value: 'Ouadda' },
  { label: 'Ouagadougou', value: 'Ouagadougou' },
  { label: 'Ouahigouya', value: 'Ouahigouya' },
  { label: 'Oualidia', value: 'Oualidia' },
  { label: 'Ouallam', value: 'Ouallam' },
  { label: 'Ouanaminthe', value: 'Ouanaminthe' },
  { label: 'Ouanani', value: 'Ouanani' },
  { label: 'Ouanda-Djallé', value: 'Ouanda-Djallé' },
  { label: 'Ouango', value: 'Ouango' },
  { label: 'Ouani', value: 'Ouani' },
  { label: 'Ouaoula', value: 'Ouaoula' },
  { label: 'Ouardenine', value: 'Ouardenine' },
  { label: 'Ouargaye', value: 'Ouargaye' },
  { label: 'Ouargla', value: 'Ouargla' },
  { label: 'Ouargla', value: 'Ouargla' },
  { label: 'Ouarzazat', value: 'Ouarzazat' },
  { label: 'Ouarzazate', value: 'Ouarzazate' },
  { label: 'Oubritenga', value: 'Oubritenga' },
  { label: 'Ouches', value: 'Ouches' },
  { label: 'Oucques', value: 'Oucques' },
  { label: 'Oud Gastel', value: 'Oud Gastel' },
  { label: 'Oud-Beijerland', value: 'Oud-Beijerland' },
  { label: 'Oud-Caberg', value: 'Oud-Caberg' },
  { label: 'Ouddorp', value: 'Ouddorp' },
  { label: 'Oude Ijsselstreek', value: 'Oude Ijsselstreek' },
  { label: 'Oude Meer', value: 'Oude Meer' },
  { label: 'Oude Niedorp', value: 'Oude Niedorp' },
  { label: 'Oude Pekela', value: 'Oude Pekela' },
  { label: 'Oude Wetering', value: 'Oude Wetering' },
  { label: 'Oudega', value: 'Oudega' },
  { label: 'Oudehaske', value: 'Oudehaske' },
  { label: 'Oudemirdum', value: 'Oudemirdum' },
  { label: 'Oudenaarde', value: 'Oudenaarde' },
  { label: 'Oudenburg', value: 'Oudenburg' },
  { label: 'Oudendijk', value: 'Oudendijk' },
  { label: 'Ouder-Amstel', value: 'Ouder-Amstel' },
  { label: 'Ouderkerk Aan De Amstel', value: 'Ouderkerk Aan De Amstel' },
  { label: 'Ouderkerk Aan Den Ijssel', value: 'Ouderkerk Aan Den Ijssel' },
  { label: 'Oudeschild', value: 'Oudeschild' },
  { label: 'Oudeschoot', value: 'Oudeschoot' },
  { label: 'Oudesluis', value: 'Oudesluis' },
  { label: 'Oudewater', value: 'Oudewater' },
  { label: 'Oud-Heverlee', value: 'Oud-Heverlee' },
  { label: 'Oudkarspel', value: 'Oudkarspel' },
  { label: 'Oud-Loosdrecht', value: 'Oud-Loosdrecht' },
  { label: 'Oudon', value: 'Oudon' },
  { label: 'Oudorp', value: 'Oudorp' },
  { label: 'Oudtshoorn', value: 'Oudtshoorn' },
  { label: 'Oud-Turnhout', value: 'Oud-Turnhout' },
  { label: 'Oud-Vossemeer', value: 'Oud-Vossemeer' },
  { label: 'Oued Amlil', value: 'Oued Amlil' },
  { label: 'Oued El Abtal', value: 'Oued El Abtal' },
  { label: 'Oued El Alleug', value: 'Oued El Alleug' },
  { label: 'Oued Fodda', value: 'Oued Fodda' },
  { label: 'Oued Laou', value: 'Oued Laou' },
  { label: 'Oued Lill', value: 'Oued Lill' },
  { label: 'Oued Meliz', value: 'Oued Meliz' },
  { label: 'Oued Rhiou', value: 'Oued Rhiou' },
  { label: 'Oued Sly', value: 'Oued Sly' },
  { label: 'Oued Zem', value: 'Oued Zem' },
  { label: 'Oued-Ed-Dahab', value: 'Oued-Ed-Dahab' },
  { label: 'Ouellah', value: 'Ouellah' },
  { label: 'Ouésso', value: 'Ouésso' },
  { label: 'Ouest Lausannois District', value: 'Ouest Lausannois District' },
  { label: 'Ouezzane', value: 'Ouezzane' },
  { label: 'Ouezzane Province', value: 'Ouezzane Province' },
  { label: 'Ouffet', value: 'Ouffet' },
  { label: 'Ouges', value: 'Ouges' },
  { label: 'Oughterard', value: 'Oughterard' },
  { label: 'Oughtibridge', value: 'Oughtibridge' },
  { label: 'Ouhozi', value: 'Ouhozi' },
  { label: 'Ouidah', value: 'Ouidah' },
  { label: 'Ouijjane', value: 'Ouijjane' },
  { label: 'Ouistreham', value: 'Ouistreham' },
  { label: 'Oujda-Angad', value: 'Oujda-Angad' },
  { label: 'Oukaïmedene', value: 'Oukaïmedene' },
  { label: 'Oulad Frej', value: 'Oulad Frej' },
  { label: 'Oulad Tayeb', value: 'Oulad Tayeb' },
  { label: 'Oulad Teïma', value: 'Oulad Teïma' },
  { label: 'Oulainen', value: 'Oulainen' },
  { label: 'Ouled Mimoun', value: 'Ouled Mimoun' },
  { label: 'Ouled Moussa', value: 'Ouled Moussa' },
  { label: 'Oulins', value: 'Oulins' },
  { label: 'Oullins', value: 'Oullins' },
  { label: 'Oulmes', value: 'Oulmes' },
  { label: 'Oulu', value: 'Oulu' },
  { label: 'Oulunsalo', value: 'Oulunsalo' },
  { label: 'Oulx', value: 'Oulx' },
  { label: 'Oum El Bouaghi', value: 'Oum El Bouaghi' },
  { label: 'Oum Hadjer', value: 'Oum Hadjer' },
  { label: 'Oumache', value: 'Oumache' },
  { label: 'Oumé', value: 'Oumé' },
  { label: 'Oundle', value: 'Oundle' },
  { label: 'Oupeye', value: 'Oupeye' },
  { label: 'Ouranoupolis', value: 'Ouranoupolis' },
  { label: 'Ouray', value: 'Ouray' },
  { label: 'Ouray County', value: 'Ouray County' },
  { label: 'Ourém', value: 'Ourém' },
  { label: 'Ourém', value: 'Ourém' },
  { label: 'Ourense', value: 'Ourense' },
  { label: 'Ouriçangas', value: 'Ouriçangas' },
  { label: 'Ouricuri', value: 'Ouricuri' },
  { label: 'Ourilândia Do Norte', value: 'Ourilândia Do Norte' },
  { label: 'Ourimbah', value: 'Ourimbah' },
  { label: 'Ourinhos', value: 'Ourinhos' },
  { label: 'Ourique', value: 'Ourique' },
  { label: 'Ourizona', value: 'Ourizona' },
  { label: 'Ouro', value: 'Ouro' },
  { label: 'Ouro Branco', value: 'Ouro Branco' },
  { label: 'Ouro Branco', value: 'Ouro Branco' },
  { label: 'Ouro Branco', value: 'Ouro Branco' },
  { label: 'Ouro Fino', value: 'Ouro Fino' },
  { label: 'Ouro Preto', value: 'Ouro Preto' },
  { label: 'Ouro Preto Do Oeste', value: 'Ouro Preto Do Oeste' },
  { label: 'Ouro Sogui', value: 'Ouro Sogui' },
  { label: 'Ouro Velho', value: 'Ouro Velho' },
  { label: 'Ouro Verde', value: 'Ouro Verde' },
  { label: 'Ouro Verde', value: 'Ouro Verde' },
  { label: 'Ouro Verde De Goiás', value: 'Ouro Verde De Goiás' },
  { label: 'Ouro Verde De Minas', value: 'Ouro Verde De Minas' },
  { label: 'Ouro Verde Do Oeste', value: 'Ouro Verde Do Oeste' },
  { label: 'Ouroeste', value: 'Ouroeste' },
  { label: 'Ourol', value: 'Ourol' },
  { label: 'Ourolândia', value: 'Ourolândia' },
  { label: 'Ouroux-Sur-Saône', value: 'Ouroux-Sur-Saône' },
  { label: 'Ourovéni', value: 'Ourovéni' },
  { label: 'Oursbelille', value: 'Oursbelille' },
  { label: 'Ourtzagh', value: 'Ourtzagh' },
  { label: 'Ourville-En-Caux', value: 'Ourville-En-Caux' },
  { label: 'Ous', value: 'Ous' },
  { label: 'Ousse', value: 'Ousse' },
  { label: 'Oussivo', value: 'Oussivo' },
  { label: 'Oussouye', value: 'Oussouye' },
  { label: 'Outagamie County', value: 'Outagamie County' },
  { label: 'Outaouais', value: 'Outaouais' },
  { label: 'Outapi', value: 'Outapi' },
  { label: 'Outarville', value: 'Outarville' },
  { label: 'Outeiro Da Cabeça', value: 'Outeiro Da Cabeça' },
  { label: 'Outeiro De Gatos', value: 'Outeiro De Gatos' },
  { label: 'Outeiro De Rei', value: 'Outeiro De Rei' },
  { label: 'Outes', value: 'Outes' },
  { label: 'Outjo', value: 'Outjo' },
  { label: 'Outlook', value: 'Outlook' },
  { label: 'Outokumpu', value: 'Outokumpu' },
  { label: 'Outram', value: 'Outram' },
  { label: 'Outreau', value: 'Outreau' },
  { label: 'Outwell', value: 'Outwell' },
  { label: 'Ouveillan', value: 'Ouveillan' },
  { label: 'Ouvidor', value: 'Ouvidor' },
  { label: 'Ouyang', value: 'Ouyang' },
  { label: 'Ouyen', value: 'Ouyen' },
  { label: 'Ouzini', value: 'Ouzini' },
  { label: 'Ouzouer-Le-Marché', value: 'Ouzouer-Le-Marché' },
  { label: 'Ouzouer-Sur-Loire', value: 'Ouzouer-Sur-Loire' },
  { label: 'Ouzouer-Sur-Trézée', value: 'Ouzouer-Sur-Trézée' },
  { label: 'Ovacık', value: 'Ovacık' },
  { label: 'Ovacık', value: 'Ovacık' },
  { label: 'Ovada', value: 'Ovada' },
  { label: 'Ovalle', value: 'Ovalle' },
  { label: 'Ovar', value: 'Ovar' },
  { label: 'Ovaro', value: 'Ovaro' },
  { label: 'Ovča', value: 'Ovča' },
  { label: 'Ovejas', value: 'Ovejas' },
  { label: 'Ovelgönne', value: 'Ovelgönne' },
  { label: 'Over', value: 'Over' },
  { label: 'Overasselt', value: 'Overasselt' },
  { label: 'Overath', value: 'Overath' },
  { label: 'Overberg', value: 'Overberg' },
  { label: 'Overberg District Municipality', value: 'Overberg District Municipality' },
  { label: 'Overbrook', value: 'Overbrook' },
  { label: 'Overcombe', value: 'Overcombe' },
  { label: 'Overhalla', value: 'Overhalla' },
  { label: 'Overhoven', value: 'Overhoven' },
  { label: 'Overijse', value: 'Overijse' },
  { label: 'Överkalix', value: 'Överkalix' },
  { label: 'Overland', value: 'Overland' },
  { label: 'Overland Park', value: 'Overland Park' },
  { label: 'Overlea', value: 'Overlea' },
  { label: 'Overleek', value: 'Overleek' },
  { label: 'Overloon', value: 'Overloon' },
  { label: 'Overmeer', value: 'Overmeer' },
  { label: 'Overpelt', value: 'Overpelt' },
  { label: 'Overschie', value: 'Overschie' },
  { label: 'Overstrand', value: 'Overstrand' },
  { label: 'Overton', value: 'Overton' },
  { label: 'Overton', value: 'Overton' },
  { label: 'Overton', value: 'Overton' },
  { label: 'Overton County', value: 'Overton County' },
  { label: 'Övertorneå', value: 'Övertorneå' },
  { label: 'Overtown', value: 'Overtown' },
  { label: 'Överum', value: 'Överum' },
  { label: 'Overveen', value: 'Overveen' },
  { label: 'Overyata', value: 'Overyata' },
  { label: 'Oveselu', value: 'Oveselu' },
  { label: 'Ovid', value: 'Ovid' },
  { label: 'Ovidiopol', value: 'Ovidiopol' },
  { label: 'Ovidiu', value: 'Ovidiu' },
  { label: 'Oviedo', value: 'Oviedo' },
  { label: 'Oviedo', value: 'Oviedo' },
  { label: 'Oviedo', value: 'Oviedo' },
  { label: 'Oviedo', value: 'Oviedo' },
  { label: 'Oviglio', value: 'Oviglio' },
  { label: 'Ovilla', value: 'Ovilla' },
  { label: 'Ovindoli', value: 'Ovindoli' },
  { label: 'Ovodda', value: 'Ovodda' },
  { label: 'Ovoshchi', value: 'Ovoshchi' },
  { label: 'Ovraqin', value: 'Ovraqin' },
  { label: 'Øvre Eiker', value: 'Øvre Eiker' },
  { label: 'Ovriá', value: 'Ovriá' },
  { label: 'Ovruch', value: 'Ovruch' },
  { label: 'Ovruch', value: 'Ovruch' },
  { label: 'Ovruch Raion', value: 'Ovruch Raion' },
  { label: 'Ovsyanka', value: 'Ovsyanka' },
  { label: 'Ovsyanka', value: 'Ovsyanka' },
  { label: 'Owak', value: 'Owak' },
  { label: 'Owando', value: 'Owando' },
  { label: 'Owariasahi-Shi', value: 'Owariasahi-Shi' },
  { label: 'Owase', value: 'Owase' },
  { label: 'Owasso', value: 'Owasso' },
  { label: 'Owatonna', value: 'Owatonna' },
  { label: 'Owczarnia', value: 'Owczarnia' },
  { label: 'Owego', value: 'Owego' },
  { label: 'Owen', value: 'Owen' },
  { label: 'Owen County', value: 'Owen County' },
  { label: 'Owen County', value: 'Owen County' },
  { label: 'Owen Sound', value: 'Owen Sound' },
  { label: 'Owens Cross Roads', value: 'Owens Cross Roads' },
  { label: 'Owensboro', value: 'Owensboro' },
  { label: 'Owensville', value: 'Owensville' },
  { label: 'Owensville', value: 'Owensville' },
  { label: 'Owenton', value: 'Owenton' },
  { label: 'Owerri', value: 'Owerri' },
  { label: 'Owingen', value: 'Owingen' },
  { label: 'Owings', value: 'Owings' },
  { label: 'Owings Mills', value: 'Owings Mills' },
  { label: 'Owingsville', value: 'Owingsville' },
  { label: 'Owls Head', value: 'Owls Head' },
  { label: 'Owo', value: 'Owo' },
  { label: 'Owode', value: 'Owode' },
  { label: 'Owosso', value: 'Owosso' },
  { label: 'Owrteh Qamish', value: 'Owrteh Qamish' },
  { label: 'Owryad', value: 'Owryad' },
  { label: 'Owschlag', value: 'Owschlag' },
  { label: 'Owsley County', value: 'Owsley County' },
  { label: 'Owston Ferry', value: 'Owston Ferry' },
  { label: 'Owyhee County', value: 'Owyhee County' },
  { label: 'Oxapampa', value: 'Oxapampa' },
  { label: 'Oxbow', value: 'Oxbow' },
  { label: 'Oxchuc', value: 'Oxchuc' },
  { label: 'Oxcum', value: 'Oxcum' },
  { label: 'Oxeloco', value: 'Oxeloco' },
  { label: 'Oxelösund', value: 'Oxelösund' },
  { label: 'Oxenford', value: 'Oxenford' },
  { label: 'Oxenhope', value: 'Oxenhope' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford', value: 'Oxford' },
  { label: 'Oxford County', value: 'Oxford County' },
  { label: 'Oxfordshire', value: 'Oxfordshire' },
  { label: 'Oxiacaque', value: 'Oxiacaque' },
  { label: 'Oxie', value: 'Oxie' },
  { label: 'Oxílithos', value: 'Oxílithos' },
  { label: 'Oxinam', value: 'Oxinam' },
  { label: 'Oxkutzcab', value: 'Oxkutzcab' },
  { label: 'Oxkutzkab', value: 'Oxkutzkab' },
  { label: 'Oxley', value: 'Oxley' },
  { label: 'Oxley', value: 'Oxley' },
  { label: 'Oxley Park', value: 'Oxley Park' },
  { label: 'Oxley Vale', value: 'Oxley Vale' },
  { label: 'Oxnard', value: 'Oxnard' },
  { label: 'Oxoboxo River', value: 'Oxoboxo River' },
  { label: 'Oxolotán', value: 'Oxolotán' },
  { label: 'Oxon Hill', value: 'Oxon Hill' },
  { label: 'Oxon Hill-Glassmanor', value: 'Oxon Hill-Glassmanor' },
  { label: 'Oxted', value: 'Oxted' },
  { label: 'Oxtotipac', value: 'Oxtotipac' },
  { label: 'Oxtotitlán', value: 'Oxtotitlán' },
  { label: 'Oyabe', value: 'Oyabe' },
  { label: 'Oyabe Shi', value: 'Oyabe Shi' },
  { label: 'Oyace', value: 'Oyace' },
  { label: 'Oyam District', value: 'Oyam District' },
  { label: 'Oyama', value: 'Oyama' },
  { label: 'Ōyama', value: 'Ōyama' },
  { label: 'Oyama-Shi', value: 'Oyama-Shi' },
  { label: 'Oyameles De Hidalgo', value: 'Oyameles De Hidalgo' },
  { label: 'Oyan', value: 'Oyan' },
  { label: 'Oyëk', value: 'Oyëk' },
  { label: 'Oyem', value: 'Oyem' },
  { label: 'Oye-Plage', value: 'Oye-Plage' },
  { label: 'Øyer', value: 'Øyer' },
  { label: 'Øygarden', value: 'Øygarden' },
  { label: 'Oy-Mittelberg', value: 'Oy-Mittelberg' },
  { label: 'Oymyakonskiy District', value: 'Oymyakonskiy District' },
  { label: 'Oyo', value: 'Oyo' },
  { label: 'Oyon', value: 'Oyon' },
  { label: 'Oyonnax', value: 'Oyonnax' },
  { label: 'Oyón-Oion', value: 'Oyón-Oion' },
  { label: 'Oyotún', value: 'Oyotún' },
  { label: 'Oyskhara', value: 'Oyskhara' },
  { label: 'Oyster Bay', value: 'Oyster Bay' },
  { label: 'Oyster Bay', value: 'Oyster Bay' },
  { label: 'Oyster Bay Cove', value: 'Oyster Bay Cove' },
  { label: 'Oyster Creek', value: 'Oyster Creek' },
  { label: 'Øystese', value: 'Øystese' },
  { label: 'Øystre Slidre', value: 'Øystre Slidre' },
  { label: 'Oytal', value: 'Oytal' },
  { label: 'Oyten', value: 'Oyten' },
  { label: 'Oytier-Saint-Oblas', value: 'Oytier-Saint-Oblas' },
  { label: 'Oyugis', value: 'Oyugis' },
  { label: 'Oza Dos Ríos', value: 'Oza Dos Ríos' },
  { label: 'Özalp', value: 'Özalp' },
  { label: 'Ozamiz City', value: 'Ozamiz City' },
  { label: 'Ozar', value: 'Ozar' },
  { label: 'Ozark', value: 'Ozark' },
  { label: 'Ozark', value: 'Ozark' },
  { label: 'Ozark', value: 'Ozark' },
  { label: 'Ozark County', value: 'Ozark County' },
  { label: 'Ożarów', value: 'Ożarów' },
  { label: 'Ożarów', value: 'Ożarów' },
  { label: 'Ożarów Mazowiecki', value: 'Ożarów Mazowiecki' },
  { label: 'Ożarowice', value: 'Ożarowice' },
  { label: 'Ozatlán', value: 'Ozatlán' },
  { label: 'Ozaukee County', value: 'Ozaukee County' },
  { label: 'Ozd', value: 'Ozd' },
  { label: 'Ózd', value: 'Ózd' },
  { label: 'Ózdi Járás', value: 'Ózdi Járás' },
  { label: 'Ozegna', value: 'Ozegna' },
  { label: 'Ozelonacaxtla', value: 'Ozelonacaxtla' },
  { label: 'Ozerki', value: 'Ozerki' },
  { label: 'Ozerna', value: 'Ozerna' },
  { label: 'Ozerne', value: 'Ozerne' },
  { label: 'Ozerne', value: 'Ozerne' },
  { label: 'Ozerne', value: 'Ozerne' },
  { label: 'Ozernovskiy', value: 'Ozernovskiy' },
  { label: 'Ozërnyy', value: 'Ozërnyy' },
  { label: 'Ozersk', value: 'Ozersk' },
  { label: 'Ozërsk', value: 'Ozërsk' },
  { label: 'Ozerskiy Rayon', value: 'Ozerskiy Rayon' },
  { label: 'Ozëry', value: 'Ozëry' },
  { label: 'Ozherel’Ye', value: 'Ozherel’Ye' },
  { label: 'Ozieri', value: 'Ozieri' },
  { label: 'Ozimek', value: 'Ozimek' },
  { label: 'Ozoir-La-Ferrière', value: 'Ozoir-La-Ferrière' },
  { label: 'Ozolnieki', value: 'Ozolnieki' },
  { label: 'Ozona', value: 'Ozona' },
  { label: 'Ozone Park', value: 'Ozone Park' },
  { label: 'Ozora', value: 'Ozora' },
  { label: 'Ozorków', value: 'Ozorków' },
  { label: 'Ozouer-Le-Voulgis', value: 'Ozouer-Le-Voulgis' },
  { label: 'Oztotitlán', value: 'Oztotitlán' },
  { label: 'Ōzu', value: 'Ōzu' },
  { label: 'Ōzu', value: 'Ōzu' },
  { label: 'Ozubulu', value: 'Ozubulu' },
  { label: 'Ozuluama De Mascareñas', value: 'Ozuluama De Mascareñas' },
  { label: 'Ozumba De Alzate', value: 'Ozumba De Alzate' },
  { label: 'Ozun', value: 'Ozun' },
  { label: 'Ozurgeti', value: 'Ozurgeti' },
  { label: 'Ōzu-Shi', value: 'Ōzu-Shi' },
  { label: 'Özvatan', value: 'Özvatan' },
  { label: 'Ozyory Urban Okrug', value: 'Ozyory Urban Okrug' },
  { label: 'Ozzano Dellemilia', value: 'Ozzano Dellemilia' },
  { label: 'Ozzano Monferrato', value: 'Ozzano Monferrato' },
  { label: 'Ozzano Taro', value: 'Ozzano Taro' },
  { label: 'Ozzero', value: 'Ozzero' },
  { label: 'P’Asanauri', value: 'P’Asanauri' },
  { label: 'P’Ok’R Mant’Ash', value: 'P’Ok’R Mant’Ash' },
  { label: 'P’Ot’I', value: 'P’Ot’I' },
  { label: 'P’Rimorsk’Oe', value: 'P’Rimorsk’Oe' },
  { label: 'P’Shatavan', value: 'P’Shatavan' },
  { label: 'P’Yŏngsŏng', value: 'P’Yŏngsŏng' },
  { label: 'Pa Daet', value: 'Pa Daet' },
  { label: 'Pa Sang', value: 'Pa Sang' },
  { label: 'Paagahan', value: 'Paagahan' },
  { label: 'Paarl', value: 'Paarl' },
  { label: 'Påarp', value: 'Påarp' },
  { label: 'Paatan', value: 'Paatan' },
  { label: 'Paatan', value: 'Paatan' },
  { label: 'Paata-Tupunion Municipality', value: 'Paata-Tupunion Municipality' },
  { label: 'Pabal', value: 'Pabal' },
  { label: 'Pabbi', value: 'Pabbi' },
  { label: 'Pabellón De Arteaga', value: 'Pabellón De Arteaga' },
  { label: 'Pabellón De Hidalgo', value: 'Pabellón De Hidalgo' },
  { label: 'Pabianice', value: 'Pabianice' },
  { label: 'Pabillonis', value: 'Pabillonis' },
  { label: 'Pablo', value: 'Pablo' },
  { label: 'Pablo L. Sidar', value: 'Pablo L. Sidar' },
  { label: 'Pablo L. Sidar', value: 'Pablo L. Sidar' },
  { label: 'Pabna ', value: 'Pabna ' },
  { label: 'Pabneukirchen', value: 'Pabneukirchen' },
  { label: 'Pabradė', value: 'Pabradė' },
  { label: 'Pabu', value: 'Pabu' },
  { label: 'Pacaembu', value: 'Pacaembu' },
  { label: 'Pacajá', value: 'Pacajá' },
  { label: 'Pacajus', value: 'Pacajus' },
  { label: 'Pacanga', value: 'Pacanga' },
  { label: 'Pacanów', value: 'Pacanów' },
  { label: 'Pacaraima', value: 'Pacaraima' },
  { label: 'Pacasmayo', value: 'Pacasmayo' },
  { label: 'Pacatuba', value: 'Pacatuba' },
  { label: 'Pacatuba', value: 'Pacatuba' },
  { label: 'Pacayas', value: 'Pacayas' },
  { label: 'Pace', value: 'Pace' },
  { label: 'Pacé', value: 'Pacé' },
  { label: 'Pace Del Mela', value: 'Pace Del Mela' },
  { label: 'Paceco', value: 'Paceco' },
  { label: 'Pacentro', value: 'Pacentro' },
  { label: 'Paceville', value: 'Paceville' },
  { label: 'Pachalum', value: 'Pachalum' },
  { label: 'Pachavita', value: 'Pachavita' },
  { label: 'Pacheco', value: 'Pacheco' },
  { label: 'Pachelma', value: 'Pachelma' },
  { label: 'Pachino', value: 'Pachino' },
  { label: 'Pachiquita', value: 'Pachiquita' },
  { label: 'Pachmarhi', value: 'Pachmarhi' },
  { label: 'Páchna', value: 'Páchna' },
  { label: 'Páchni', value: 'Páchni' },
  { label: 'Pacho', value: 'Pacho' },
  { label: 'Pacho Nuevo', value: 'Pacho Nuevo' },
  { label: 'Pacho Viejo', value: 'Pacho Viejo' },
  { label: 'Pachora', value: 'Pachora' },
  { label: 'Pachperwa', value: 'Pachperwa' },
  { label: 'Pachu', value: 'Pachu' },
  { label: 'Pachuca De Soto', value: 'Pachuca De Soto' },
  { label: 'Pachuquilla', value: 'Pachuquilla' },
  { label: 'Paciano', value: 'Paciano' },
  { label: 'Pacific', value: 'Pacific' },
  { label: 'Pacific', value: 'Pacific' },
  { label: 'Pacific City', value: 'Pacific City' },
  { label: 'Pacific County', value: 'Pacific County' },
  { label: 'Pacific Grove', value: 'Pacific Grove' },
  { label: 'Pacific Paradise', value: 'Pacific Paradise' },
  { label: 'Pacific Pines', value: 'Pacific Pines' },
  { label: 'Pacifica', value: 'Pacifica' },
  { label: 'Paciran', value: 'Paciran' },
  { label: 'Pack', value: 'Pack' },
  { label: 'Paclolo', value: 'Paclolo' },
  { label: 'Paco', value: 'Paco' },
  { label: 'Paço De Arcos', value: 'Paço De Arcos' },
  { label: 'Paço De Arcos', value: 'Paço De Arcos' },
  { label: 'Paço Do Lumiar', value: 'Paço Do Lumiar' },
  { label: 'Paco Roman', value: 'Paco Roman' },
  { label: 'Paco Roman', value: 'Paco Roman' },
  { label: 'Pacoa', value: 'Pacoa' },
  { label: 'Pacocha', value: 'Pacocha' },
  { label: 'Pacol', value: 'Pacol' },
  { label: 'Pacolet', value: 'Pacolet' },
  { label: 'Pacora', value: 'Pacora' },
  { label: 'Pácora', value: 'Pácora' },
  { label: 'Paços Da Serra', value: 'Paços Da Serra' },
  { label: 'Paços De Brandão', value: 'Paços De Brandão' },
  { label: 'Paços De Ferreira', value: 'Paços De Ferreira' },
  { label: 'Pacoti', value: 'Pacoti' },
  { label: 'Pacov', value: 'Pacov' },
  { label: 'Pacs Del Penedès', value: 'Pacs Del Penedès' },
  { label: 'Pacsa', value: 'Pacsa' },
  { label: 'Pacú', value: 'Pacú' },
  { label: 'Pacujá', value: 'Pacujá' },
  { label: 'Păcureni', value: 'Păcureni' },
  { label: 'Păcureţi', value: 'Păcureţi' },
  { label: 'Pacyna', value: 'Pacyna' },
  { label: 'Pacy-Sur-Eure', value: 'Pacy-Sur-Eure' },
  { label: 'Paczków', value: 'Paczków' },
  { label: 'Pad Idan', value: 'Pad Idan' },
  { label: 'Pad’ Mel’Nichnaya', value: 'Pad’ Mel’Nichnaya' },
  { label: 'Padada', value: 'Padada' },
  { label: 'Padada', value: 'Padada' },
  { label: 'Padada', value: 'Padada' },
  { label: 'Padalarang', value: 'Padalarang' },
  { label: 'Padam', value: 'Padam' },
  { label: 'Padampur', value: 'Padampur' },
  { label: 'Padampur', value: 'Padampur' },
  { label: 'Padang', value: 'Padang' },
  { label: 'Padang', value: 'Padang' },
  { label: 'Padang Mat Sirat', value: 'Padang Mat Sirat' },
  { label: 'Padangsidempuan', value: 'Padangsidempuan' },
  { label: 'Padapada', value: 'Padapada' },
  { label: 'Padapada', value: 'Padapada' },
  { label: 'Padasjoki', value: 'Padasjoki' },
  { label: 'Padborg', value: 'Padborg' },
  { label: 'Padbury', value: 'Padbury' },
  { label: 'Paddaya', value: 'Paddaya' },
  { label: 'Paddaya', value: 'Paddaya' },
  { label: 'Paddhari', value: 'Paddhari' },
  { label: 'Paddington', value: 'Paddington' },
  { label: 'Paddington', value: 'Paddington' },
  { label: 'Paddock Lake', value: 'Paddock Lake' },
  { label: 'Paddock Wood', value: 'Paddock Wood' },
  { label: 'Padea', value: 'Padea' },
  { label: 'Padej', value: 'Padej' },
  { label: 'Paden City', value: 'Paden City' },
  { label: 'Padenghe Sul Garda', value: 'Padenghe Sul Garda' },
  { label: 'Padenstedt', value: 'Padenstedt' },
  { label: 'Pader', value: 'Pader' },
  { label: 'Pader Palwo', value: 'Pader Palwo' },
  { label: 'Paderborn', value: 'Paderborn' },
  { label: 'Padergnone', value: 'Padergnone' },
  { label: 'Paderna', value: 'Paderna' },
  { label: 'Paderne', value: 'Paderne' },
  { label: 'Paderne', value: 'Paderne' },
  { label: 'Paderne De Allariz', value: 'Paderne De Allariz' },
  { label: 'Padernello', value: 'Padernello' },
  { label: 'Paderno', value: 'Paderno' },
  { label: 'Paderno Dadda', value: 'Paderno Dadda' },
  { label: 'Paderno Del Grappa', value: 'Paderno Del Grappa' },
  { label: 'Paderno Dugnano', value: 'Paderno Dugnano' },
  { label: 'Paderno Franciacorta', value: 'Paderno Franciacorta' },
  { label: 'Paderno Ponchielli', value: 'Paderno Ponchielli' },
  { label: 'Padeş', value: 'Padeş' },
  { label: 'Padew Narodowa', value: 'Padew Narodowa' },
  { label: 'Padiernos', value: 'Padiernos' },
  { label: 'Padiglione', value: 'Padiglione' },
  { label: 'Padiham', value: 'Padiham' },
  { label: 'Padilla', value: 'Padilla' },
  { label: 'Padilla', value: 'Padilla' },
  { label: 'Padilla', value: 'Padilla' },
  { label: 'Padilla De Abajo', value: 'Padilla De Abajo' },
  { label: 'Padilla De Arriba', value: 'Padilla De Arriba' },
  { label: 'Padina', value: 'Padina' },
  { label: 'Padina', value: 'Padina' },
  { label: 'Padina Matei', value: 'Padina Matei' },
  { label: 'Padina Mică', value: 'Padina Mică' },
  { label: 'Padivarma', value: 'Padivarma' },
  { label: 'Padmanabhapuram', value: 'Padmanabhapuram' },
  { label: 'Padong', value: 'Padong' },
  { label: 'Padong', value: 'Padong' },
  { label: 'Padova', value: 'Padova' },
  { label: 'Padra', value: 'Padra' },
  { label: 'Padrauna', value: 'Padrauna' },
  { label: 'Padre Abad', value: 'Padre Abad' },
  { label: 'Padre Bernardo', value: 'Padre Bernardo' },
  { label: 'Padre Burgos', value: 'Padre Burgos' },
  { label: 'Padre Burgos', value: 'Padre Burgos' },
  { label: 'Padre Burgos', value: 'Padre Burgos' },
  { label: 'Padre Carvalho', value: 'Padre Carvalho' },
  { label: 'Padre Garcia', value: 'Padre Garcia' },
  { label: 'Padre Hurtado', value: 'Padre Hurtado' },
  { label: 'Padre Las Casas', value: 'Padre Las Casas' },
  { label: 'Padre Las Casas', value: 'Padre Las Casas' },
  { label: 'Padre Marcos', value: 'Padre Marcos' },
  { label: 'Padre Paraíso', value: 'Padre Paraíso' },
  { label: 'Padre Zamora', value: 'Padre Zamora' },
  { label: 'Padrenda', value: 'Padrenda' },
  { label: 'Padria', value: 'Padria' },
  { label: 'Padrón', value: 'Padrón' },
  { label: 'Padrones De Bureba', value: 'Padrones De Bureba' },
  { label: 'Padru', value: 'Padru' },
  { label: 'Padstow', value: 'Padstow' },
  { label: 'Padstow', value: 'Padstow' },
  { label: 'Padstow Heights', value: 'Padstow Heights' },
  { label: 'Paducah', value: 'Paducah' },
  { label: 'Paducah', value: 'Paducah' },
  { label: 'Padukka', value: 'Padukka' },
  { label: 'Padul', value: 'Padul' },
  { label: 'Padula', value: 'Padula' },
  { label: 'Padules', value: 'Padules' },
  { label: 'Padule-San Marco', value: 'Padule-San Marco' },
  { label: 'Paduli', value: 'Paduli' },
  { label: 'Padulle', value: 'Padulle' },
  { label: 'Pădurea', value: 'Pădurea' },
  { label: 'Pădurea Neagră', value: 'Pădurea Neagră' },
  { label: 'Pădureni', value: 'Pădureni' },
  { label: 'Pădureni', value: 'Pădureni' },
  { label: 'Pădureni', value: 'Pădureni' },
  { label: 'Pădureni', value: 'Pădureni' },
  { label: 'Pădureni', value: 'Pădureni' },
  { label: 'Pădureni', value: 'Pădureni' },
  { label: 'Paein Hoular', value: 'Paein Hoular' },
  { label: 'Paeroa', value: 'Paeroa' },
  { label: 'Paes Landim', value: 'Paes Landim' },
  { label: 'Paesana', value: 'Paesana' },
  { label: 'Paese', value: 'Paese' },
  { label: 'Paete', value: 'Paete' },
  { label: 'Páez', value: 'Páez' },
  { label: 'Páez', value: 'Páez' },
  { label: 'Pag', value: 'Pag' },
  { label: 'Pagadian', value: 'Pagadian' },
  { label: 'Pagalungan', value: 'Pagalungan' },
  { label: 'Pagalungan', value: 'Pagalungan' },
  { label: 'Pagalungan', value: 'Pagalungan' },
  { label: 'Pagalungan', value: 'Pagalungan' },
  { label: 'Pagangan', value: 'Pagangan' },
  { label: 'Pagangan', value: 'Pagangan' },
  { label: 'Pagani', value: 'Pagani' },
  { label: 'Paganica-Tempera', value: 'Paganica-Tempera' },
  { label: 'Paganico Sabino', value: 'Paganico Sabino' },
  { label: 'Pagar Alam', value: 'Pagar Alam' },
  { label: 'Pag-Asa', value: 'Pag-Asa' },
  { label: 'Pag-Asa', value: 'Pag-Asa' },
  { label: 'Pag-Asa', value: 'Pag-Asa' },
  { label: 'Pagatin', value: 'Pagatin' },
  { label: 'Pagatin', value: 'Pagatin' },
  { label: 'Pagayawan', value: 'Pagayawan' },
  { label: 'Pagazzano', value: 'Pagazzano' },
  { label: 'Pagbilao', value: 'Pagbilao' },
  { label: 'Page', value: 'Page' },
  { label: 'Page', value: 'Page' },
  { label: 'Page County', value: 'Page County' },
  { label: 'Page County', value: 'Page County' },
  { label: 'Pagedale', value: 'Pagedale' },
  { label: 'Pagėgiai', value: 'Pagėgiai' },
  { label: 'Pageland', value: 'Pageland' },
  { label: 'Paget', value: 'Paget' },
  { label: 'Pagewood', value: 'Pagewood' },
  { label: 'Paghmān', value: 'Paghmān' },
  { label: 'Paglat', value: 'Paglat' },
  { label: 'Pagliara', value: 'Pagliara' },
  { label: 'Pagliare', value: 'Pagliare' },
  { label: 'Pagliare', value: 'Pagliare' },
  { label: 'Pagliarelle', value: 'Pagliarelle' },
  { label: 'Pagliarone', value: 'Pagliarone' },
  { label: 'Paglieta', value: 'Paglieta' },
  { label: 'Pagnacco', value: 'Pagnacco' },
  { label: 'Pagno', value: 'Pagno' },
  { label: 'Pagnona', value: 'Pagnona' },
  { label: 'Pagny-Sur-Moselle', value: 'Pagny-Sur-Moselle' },
  { label: 'Pago Del Vallo Di Lauro', value: 'Pago Del Vallo Di Lauro' },
  { label: 'Pago Veiano', value: 'Pago Veiano' },
  { label: 'Pagosa Springs', value: 'Pagosa Springs' },
  { label: 'Pagouda', value: 'Pagouda' },
  { label: 'Pagsabangan', value: 'Pagsabangan' },
  { label: 'Pagsabangan', value: 'Pagsabangan' },
  { label: 'Pagsabangan', value: 'Pagsabangan' },
  { label: 'Pagsanahan Norte', value: 'Pagsanahan Norte' },
  { label: 'Pagsanahan Norte', value: 'Pagsanahan Norte' },
  { label: 'Pagsañgahan', value: 'Pagsañgahan' },
  { label: 'Pagsanghan', value: 'Pagsanghan' },
  { label: 'Pagsanghan', value: 'Pagsanghan' },
  { label: 'Pagsanjan', value: 'Pagsanjan' },
  { label: 'Pagudpud', value: 'Pagudpud' },
  { label: 'Pagudpud', value: 'Pagudpud' },
  { label: 'Pahactla', value: 'Pahactla' },
  { label: 'Pāhala', value: 'Pāhala' },
  { label: 'Paharpur', value: 'Paharpur' },
  { label: 'Pahasu', value: 'Pahasu' },
  { label: 'Pähl', value: 'Pähl' },
  { label: 'Pahleh', value: 'Pahleh' },
  { label: 'Pahlen', value: 'Pahlen' },
  { label: 'Pahlgam', value: 'Pahlgam' },
  { label: 'Pahnabad', value: 'Pahnabad' },
  { label: 'Pahokee', value: 'Pahokee' },
  { label: 'Pahrump', value: 'Pahrump' },
  { label: 'Pahua Hueca', value: 'Pahua Hueca' },
  { label: 'Pahuatlán', value: 'Pahuatlán' },
  { label: 'Pahuatlán De Valle', value: 'Pahuatlán De Valle' },
  { label: 'Pahur Maharashtra', value: 'Pahur Maharashtra' },
  { label: 'Pai', value: 'Pai' },
  { label: 'Pai Pedro', value: 'Pai Pedro' },
  { label: 'Paia', value: 'Paia' },
  { label: 'Paial', value: 'Paial' },
  { label: 'Paialvo', value: 'Paialvo' },
  { label: 'Paianía', value: 'Paianía' },
  { label: 'Paiçandu', value: 'Paiçandu' },
  { label: 'Paicol', value: 'Paicol' },
  { label: 'Paide', value: 'Paide' },
  { label: 'Paide Linn', value: 'Paide Linn' },
  { label: 'Paidha', value: 'Paidha' },
  { label: 'Paigaon', value: 'Paigaon' },
  { label: 'Paignton', value: 'Paignton' },
  { label: 'Paihia', value: 'Paihia' },
  { label: 'Paihuano', value: 'Paihuano' },
  { label: 'Paiisa', value: 'Paiisa' },
  { label: 'Paiján', value: 'Paiján' },
  { label: 'Paikpara', value: 'Paikpara' },
  { label: 'Paikuse', value: 'Paikuse' },
  { label: 'Pailin', value: 'Pailin' },
  { label: 'Pailitas', value: 'Pailitas' },
  { label: 'Paillaco', value: 'Paillaco' },
  { label: 'Pailles', value: 'Pailles' },
  { label: 'Paillet', value: 'Paillet' },
  { label: 'Pailly', value: 'Pailly' },
  { label: 'Pailón', value: 'Pailón' },
  { label: 'Paim Filho', value: 'Paim Filho' },
  { label: 'Paimboeuf', value: 'Paimboeuf' },
  { label: 'Paime', value: 'Paime' },
  { label: 'Paimio', value: 'Paimio' },
  { label: 'Paimpol', value: 'Paimpol' },
  { label: 'Paimpont', value: 'Paimpont' },
  { label: 'Paine', value: 'Paine' },
  { label: 'Paineiras', value: 'Paineiras' },
  { label: 'Painel', value: 'Painel' },
  { label: 'Painesville', value: 'Painesville' },
  { label: 'Păingeni', value: 'Păingeni' },
  { label: 'Painho', value: 'Painho' },
  { label: 'Pains', value: 'Pains' },
  { label: 'Painswick', value: 'Painswick' },
  { label: 'Paint Rock', value: 'Paint Rock' },
  { label: 'Painted Post', value: 'Painted Post' },
  { label: 'Painten', value: 'Painten' },
  { label: 'Paintla', value: 'Paintla' },
  { label: 'Paintsville', value: 'Paintsville' },
  { label: 'Paipa', value: 'Paipa' },
  { label: 'Paiporta', value: 'Paiporta' },
  { label: 'Paisanos', value: 'Paisanos' },
  { label: 'Paisco Loveno', value: 'Paisco Loveno' },
  { label: 'Paisley', value: 'Paisley' },
  { label: 'Paisley', value: 'Paisley' },
  { label: 'Paisley', value: 'Paisley' },
  { label: 'Paita', value: 'Paita' },
  { label: 'Païta', value: 'Païta' },
  { label: 'Paitan', value: 'Paitan' },
  { label: 'Paitan', value: 'Paitan' },
  { label: 'Paitan Este', value: 'Paitan Este' },
  { label: 'Paitan Este', value: 'Paitan Este' },
  { label: 'Paitan Norte', value: 'Paitan Norte' },
  { label: 'Paitan Norte', value: 'Paitan Norte' },
  { label: 'Paithan', value: 'Paithan' },
  { label: 'Paitone', value: 'Paitone' },
  { label: 'Paiva', value: 'Paiva' },
  { label: 'Paix Bouche', value: 'Paix Bouche' },
  { label: 'Paix Bouche', value: 'Paix Bouche' },
  { label: 'Paja De Sombrero', value: 'Paja De Sombrero' },
  { label: 'Pajacuarán', value: 'Pajacuarán' },
  { label: 'Pajala', value: 'Pajala' },
  { label: 'Paján', value: 'Paján' },
  { label: 'Pajapan', value: 'Pajapan' },
  { label: 'Pajapita', value: 'Pajapita' },
  { label: 'Pájara', value: 'Pájara' },
  { label: 'Pajarejos', value: 'Pajarejos' },
  { label: 'Pajares De Adaja', value: 'Pajares De Adaja' },
  { label: 'Pajares De La Laguna', value: 'Pajares De La Laguna' },
  { label: 'Pajares De La Lampreana', value: 'Pajares De La Lampreana' },
  { label: 'Pajares De Los Oteros', value: 'Pajares De Los Oteros' },
  { label: 'Pajarito', value: 'Pajarito' },
  { label: 'Pajaro', value: 'Pajaro' },
  { label: 'Pajarón', value: 'Pajarón' },
  { label: 'Pajaroncillo', value: 'Pajaroncillo' },
  { label: 'Pájaros', value: 'Pájaros' },
  { label: 'Pajas Blancas', value: 'Pajas Blancas' },
  { label: 'Pajé', value: 'Pajé' },
  { label: 'Pajęczno', value: 'Pajęczno' },
  { label: 'Pajeú Do Piauí', value: 'Pajeú Do Piauí' },
  { label: 'Pajić Polje', value: 'Pajić Polje' },
  { label: 'Pajo', value: 'Pajo' },
  { label: 'Pajo', value: 'Pajo' },
  { label: 'Pajonal', value: 'Pajonal' },
  { label: 'Paju-Si', value: 'Paju-Si' },
  { label: 'Pajusti', value: 'Pajusti' },
  { label: 'Pak Chong', value: 'Pak Chong' },
  { label: 'Pak Kret', value: 'Pak Kret' },
  { label: 'Pak Phanang', value: 'Pak Phanang' },
  { label: 'Pak Thong Chai', value: 'Pak Thong Chai' },
  { label: 'Paka', value: 'Paka' },
  { label: 'Pakala', value: 'Pakala' },
  { label: 'Pakdasht', value: 'Pakdasht' },
  { label: 'Pakenham', value: 'Pakenham' },
  { label: 'Pakenham Upper', value: 'Pakenham Upper' },
  { label: 'Pakharevka', value: 'Pakharevka' },
  { label: 'Pakhtakoron', value: 'Pakhtakoron' },
  { label: 'Pakhtaobod Tumani', value: 'Pakhtaobod Tumani' },
  { label: 'Pakiad', value: 'Pakiad' },
  { label: 'Pakil', value: 'Pakil' },
  { label: 'Pakisaji', value: 'Pakisaji' },
  { label: 'Pakki Shagwanwali', value: 'Pakki Shagwanwali' },
  { label: 'Pakokku', value: 'Pakokku' },
  { label: 'Pakość', value: 'Pakość' },
  { label: 'Pakosław', value: 'Pakosław' },
  { label: 'Pakosławice', value: 'Pakosławice' },
  { label: 'Pakoštane', value: 'Pakoštane' },
  { label: 'Pákozd', value: 'Pákozd' },
  { label: 'Pakpattan', value: 'Pakpattan' },
  { label: 'Pakrac', value: 'Pakrac' },
  { label: 'Pakruojis', value: 'Pakruojis' },
  { label: 'Paks', value: 'Paks' },
  { label: 'Pakse', value: 'Pakse' },
  { label: 'Paksi Járás', value: 'Paksi Járás' },
  { label: 'Pakur', value: 'Pakur' },
  { label: 'Pakuranga', value: 'Pakuranga' },
  { label: 'Pakxan', value: 'Pakxan' },
  { label: 'Pakxong', value: 'Pakxong' },
  { label: 'Pala', value: 'Pala' },
  { label: 'Pala', value: 'Pala' },
  { label: 'Palacagüina', value: 'Palacagüina' },
  { label: 'Palacios', value: 'Palacios' },
  { label: 'Palacios De Goda', value: 'Palacios De Goda' },
  { label: 'Palacios De La Sierra', value: 'Palacios De La Sierra' },
  { label: 'Palacios De La Valduerna', value: 'Palacios De La Valduerna' },
  { label: 'Palacios De Riopisuerga', value: 'Palacios De Riopisuerga' },
  { label: 'Palacios De Sanabria', value: 'Palacios De Sanabria' },
  { label: 'Palacios Del Arzobispo', value: 'Palacios Del Arzobispo' },
  { label: 'Palacios Del Pan', value: 'Palacios Del Pan' },
  { label: 'Palacios Del Sil', value: 'Palacios Del Sil' },
  { label: 'Palacios Y Villafranca Los', value: 'Palacios Y Villafranca Los' },
  { label: 'Palaciosrubios', value: 'Palaciosrubios' },
  { label: 'Palackattumala', value: 'Palackattumala' },
  { label: 'Palacpalac', value: 'Palacpalac' },
  { label: 'Palacpalac', value: 'Palacpalac' },
  { label: 'Paladina', value: 'Paladina' },
  { label: 'Palafolls', value: 'Palafolls' },
  { label: 'Palafrugell', value: 'Palafrugell' },
  { label: 'Palagano', value: 'Palagano' },
  { label: 'Palagao Norte', value: 'Palagao Norte' },
  { label: 'Palagao Norte', value: 'Palagao Norte' },
  { label: 'Palagianello', value: 'Palagianello' },
  { label: 'Palagiano', value: 'Palagiano' },
  { label: 'Palagonia', value: 'Palagonia' },
  { label: 'Palahanan Uno', value: 'Palahanan Uno' },
  { label: 'Palaia', value: 'Palaia' },
  { label: 'Palaiá Epídavros', value: 'Palaiá Epídavros' },
  { label: 'Palaiá Fókaia', value: 'Palaiá Fókaia' },
  { label: 'Palaífyto', value: 'Palaífyto' },
  { label: 'Palaió Fáliro', value: 'Palaió Fáliro' },
  { label: 'Palaió Tsiflíki', value: 'Palaió Tsiflíki' },
  { label: 'Palaióchora', value: 'Palaióchora' },
  { label: 'Palaiochóri', value: 'Palaiochóri' },
  { label: 'Palaiochóri', value: 'Palaiochóri' },
  { label: 'Palaiokómi', value: 'Palaiokómi' },
  { label: 'Palaiomonástiro', value: 'Palaiomonástiro' },
  { label: 'Palaiópyrgos', value: 'Palaiópyrgos' },
  { label: 'Pálairos', value: 'Pálairos' },
  { label: 'Palais De Justice', value: 'Palais De Justice' },
  { label: 'Palaiseau', value: 'Palaiseau' },
  { label: 'Palaja', value: 'Palaja' },
  { label: 'Palakkad District', value: 'Palakkad District' },
  { label: 'Palakkodu', value: 'Palakkodu' },
  { label: 'Palakollu', value: 'Palakollu' },
  { label: 'Palali', value: 'Palali' },
  { label: 'Palama', value: 'Palama' },
  { label: 'Palamás', value: 'Palamás' },
  { label: 'Palamedu', value: 'Palamedu' },
  { label: 'Palamós', value: 'Palamós' },
  { label: 'Palampas', value: 'Palampas' },
  { label: 'Palampur', value: 'Palampur' },
  { label: 'Palamu', value: 'Palamu' },
  { label: 'Palana', value: 'Palana' },
  { label: 'Palanan', value: 'Palanan' },
  { label: 'Palanas', value: 'Palanas' },
  { label: 'Palanca', value: 'Palanca' },
  { label: 'Palanca', value: 'Palanca' },
  { label: 'Palanca', value: 'Palanca' },
  { label: 'Palandöken', value: 'Palandöken' },
  { label: 'Palanga', value: 'Palanga' },
  { label: 'Palangkaraya', value: 'Palangkaraya' },
  { label: 'Palangue', value: 'Palangue' },
  { label: 'Palani', value: 'Palani' },
  { label: 'Palanit', value: 'Palanit' },
  { label: 'Palanit', value: 'Palanit' },
  { label: 'Palanpur', value: 'Palanpur' },
  { label: 'Palanques', value: 'Palanques' },
  { label: 'Palanswa', value: 'Palanswa' },
  { label: 'Palanzano', value: 'Palanzano' },
  { label: 'Palapag', value: 'Palapag' },
  { label: 'Palapag', value: 'Palapag' },
  { label: 'Palapye', value: 'Palapye' },
  { label: 'Palaroo', value: 'Palaroo' },
  { label: 'Palaroo', value: 'Palaroo' },
  { label: 'Palas De Rei', value: 'Palas De Rei' },
  { label: 'Palasa', value: 'Palasa' },
  { label: 'Palasbari', value: 'Palasbari' },
  { label: 'Palata', value: 'Palata' },
  { label: 'Pălatca', value: 'Pălatca' },
  { label: 'Palatine', value: 'Palatine' },
  { label: 'Palatka', value: 'Palatka' },
  { label: 'Palatka', value: 'Palatka' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Palau Danglesola El', value: 'Palau Danglesola El' },
  { label: 'Palau De Santa Eulàlia', value: 'Palau De Santa Eulàlia' },
  { label: 'Palau-Del-Vidre', value: 'Palau-Del-Vidre' },
  { label: 'Palauig', value: 'Palauig' },
  { label: 'Palauig', value: 'Palauig' },
  { label: 'Palau-Sator', value: 'Palau-Sator' },
  { label: 'Palau-Saverdera', value: 'Palau-Saverdera' },
  { label: 'Palau-Solità I Plegamans', value: 'Palau-Solità I Plegamans' },
  { label: 'Palavakkam', value: 'Palavakkam' },
  { label: 'Palavas-Les-Flots', value: 'Palavas-Les-Flots' },
  { label: 'Palayan', value: 'Palayan' },
  { label: 'Palayan City', value: 'Palayan City' },
  { label: 'Palazu Mare', value: 'Palazu Mare' },
  { label: 'Palazuelo De Vedija', value: 'Palazuelo De Vedija' },
  { label: 'Palazuelos De Eresma', value: 'Palazuelos De Eresma' },
  { label: 'Palazuelos De La Sierra', value: 'Palazuelos De La Sierra' },
  { label: 'Palazuelos De Muñó', value: 'Palazuelos De Muñó' },
  { label: 'Palazzago', value: 'Palazzago' },
  { label: 'Palazzo', value: 'Palazzo' },
  { label: 'Palazzo Adriano', value: 'Palazzo Adriano' },
  { label: 'Palazzo Canavese', value: 'Palazzo Canavese' },
  { label: 'Palazzo Pignano', value: 'Palazzo Pignano' },
  { label: 'Palazzo San Gervasio', value: 'Palazzo San Gervasio' },
  { label: 'Palazzolo', value: 'Palazzolo' },
  { label: 'Palazzolo Acreide', value: 'Palazzolo Acreide' },
  { label: 'Palazzolo Dello Stella', value: 'Palazzolo Dello Stella' },
  { label: 'Palazzolo Sulloglio', value: 'Palazzolo Sulloglio' },
  { label: 'Palazzolo Vercellese', value: 'Palazzolo Vercellese' },
  { label: 'Palazzuolo Sul Senio', value: 'Palazzuolo Sul Senio' },
  { label: 'Palcza', value: 'Palcza' },
  { label: 'Paldau', value: 'Paldau' },
  { label: 'Paldiski', value: 'Paldiski' },
  { label: 'Paldit', value: 'Paldit' },
  { label: 'Pale', value: 'Pale' },
  { label: 'Palekastro', value: 'Palekastro' },
  { label: 'Palekh', value: 'Palekh' },
  { label: 'Palekhskiy Rayon', value: 'Palekhskiy Rayon' },
  { label: 'Palembang', value: 'Palembang' },
  { label: 'Palena', value: 'Palena' },
  { label: 'Palena', value: 'Palena' },
  { label: 'Palencia', value: 'Palencia' },
  { label: 'Palencia', value: 'Palencia' },
  { label: 'Palencia De Negrilla', value: 'Palencia De Negrilla' },
  { label: 'Palenciana', value: 'Palenciana' },
  { label: 'Palenque', value: 'Palenque' },
  { label: 'Palenque', value: 'Palenque' },
  { label: 'Palenque', value: 'Palenque' },
  { label: 'Palenstein', value: 'Palenstein' },
  { label: 'Palenville', value: 'Palenville' },
  { label: 'Palenzuela', value: 'Palenzuela' },
  { label: 'Palera', value: 'Palera' },
  { label: 'Palermiti', value: 'Palermiti' },
  { label: 'Palermo', value: 'Palermo' },
  { label: 'Palermo', value: 'Palermo' },
  { label: 'Palermo', value: 'Palermo' },
  { label: 'Palermo', value: 'Palermo' },
  { label: 'Palermo', value: 'Palermo' },
  { label: 'Palestina', value: 'Palestina' },
  { label: 'Palestina', value: 'Palestina' },
  { label: 'Palestina', value: 'Palestina' },
  { label: 'Palestina', value: 'Palestina' },
  { label: 'Palestina', value: 'Palestina' },
  { label: 'Palestina', value: 'Palestina' },
  { label: 'Palestina De Goiás', value: 'Palestina De Goiás' },
  { label: 'Palestina De Los Altos', value: 'Palestina De Los Altos' },
  { label: 'Palestina Do Pará', value: 'Palestina Do Pará' },
  { label: 'Palestine', value: 'Palestine' },
  { label: 'Palestine', value: 'Palestine' },
  { label: 'Palestrina', value: 'Palestrina' },
  { label: 'Palestro', value: 'Palestro' },
  { label: 'Paleu', value: 'Paleu' },
  { label: 'Palézieux', value: 'Palézieux' },
  { label: 'Palfau', value: 'Palfau' },
  { label: 'Palghar', value: 'Palghar' },
  { label: 'Palghat', value: 'Palghat' },
  { label: 'Palguyod', value: 'Palguyod' },
  { label: 'Palguyod', value: 'Palguyod' },
  { label: 'Palhais', value: 'Palhais' },
  { label: 'Palhano', value: 'Palhano' },
  { label: 'Palhi', value: 'Palhi' },
  { label: 'Palhi', value: 'Palhi' },
  { label: 'Palhoça', value: 'Palhoça' },
  { label: 'Pali', value: 'Pali' },
  { label: 'Pali', value: 'Pali' },
  { label: 'Pali', value: 'Pali' },
  { label: 'Pali Raigad', value: 'Pali Raigad' },
  { label: 'Palia Kalan', value: 'Palia Kalan' },
  { label: 'Palian', value: 'Palian' },
  { label: 'Palian', value: 'Palian' },
  { label: 'Paliano', value: 'Paliano' },
  { label: 'Palidano', value: 'Palidano' },
  { label: 'Palikir - National Government Center', value: 'Palikir - National Government Center' },
  { label: 'Palima', value: 'Palima' },
  { label: 'Palimanan', value: 'Palimanan' },
  { label: 'Palimbang', value: 'Palimbang' },
  { label: 'Palimbang', value: 'Palimbang' },
  { label: 'Palín', value: 'Palín' },
  { label: 'Palinges', value: 'Palinges' },
  { label: 'Palinuro', value: 'Palinuro' },
  { label: 'Palisade', value: 'Palisade' },
  { label: 'Palisades Park', value: 'Palisades Park' },
  { label: 'Paliseul', value: 'Paliseul' },
  { label: 'Palitana', value: 'Palitana' },
  { label: 'Paliyad', value: 'Paliyad' },
  { label: 'Palizada', value: 'Palizada' },
  { label: 'Palizada', value: 'Palizada' },
  { label: 'Palizzi', value: 'Palizzi' },
  { label: 'Palizzi Marina', value: 'Palizzi Marina' },
  { label: 'Palkan', value: 'Palkan' },
  { label: 'Palkan', value: 'Palkan' },
  { label: 'Pälkäne', value: 'Pälkäne' },
  { label: 'Palkino', value: 'Palkino' },
  { label: 'Palkinskiy Rayon', value: 'Palkinskiy Rayon' },
  { label: 'Palkonda', value: 'Palkonda' },
  { label: 'Palkovice', value: 'Palkovice' },
  { label: 'Palladam', value: 'Palladam' },
  { label: 'Pallagorio', value: 'Pallagorio' },
  { label: 'Pallanza-Intra-Suna', value: 'Pallanza-Intra-Suna' },
  { label: 'Pallanzeno', value: 'Pallanzeno' },
  { label: 'Pallappatti', value: 'Pallappatti' },
  { label: 'Pallare', value: 'Pallare' },
  { label: 'Pallaresos', value: 'Pallaresos' },
  { label: 'Pallasovka', value: 'Pallasovka' },
  { label: 'Pallattur', value: 'Pallattur' },
  { label: 'Pallavaram', value: 'Pallavaram' },
  { label: 'Palle', value: 'Palle' },
  { label: 'Pallejà', value: 'Pallejà' },
  { label: 'Pallekele', value: 'Pallekele' },
  { label: 'Pallerone', value: 'Pallerone' },
  { label: 'Pallevada', value: 'Pallevada' },
  { label: 'Pallikondai', value: 'Pallikondai' },
  { label: 'Palling', value: 'Palling' },
  { label: 'Pallíni', value: 'Pallíni' },
  { label: 'Pallipattu', value: 'Pallipattu' },
  { label: 'Pallippatti', value: 'Pallippatti' },
  { label: 'Pallisa', value: 'Pallisa' },
  { label: 'Palm Aire', value: 'Palm Aire' },
  { label: 'Palm Bay', value: 'Palm Bay' },
  { label: 'Palm Beach', value: 'Palm Beach' },
  { label: 'Palm Beach', value: 'Palm Beach' },
  { label: 'Palm Beach', value: 'Palm Beach' },
  { label: 'Palm Beach County', value: 'Palm Beach County' },
  { label: 'Palm Beach Gardens', value: 'Palm Beach Gardens' },
  { label: 'Palm Beach Shores', value: 'Palm Beach Shores' },
  { label: 'Palm City', value: 'Palm City' },
  { label: 'Palm Coast', value: 'Palm Coast' },
  { label: 'Palm Cove', value: 'Palm Cove' },
  { label: 'Palm Desert', value: 'Palm Desert' },
  { label: 'Palm Harbor', value: 'Palm Harbor' },
  { label: 'Palm Island', value: 'Palm Island' },
  { label: 'Palm River-Clair Mel', value: 'Palm River-Clair Mel' },
  { label: 'Palm Springs', value: 'Palm Springs' },
  { label: 'Palm Springs', value: 'Palm Springs' },
  { label: 'Palm Springs North', value: 'Palm Springs North' },
  { label: 'Palm Valley', value: 'Palm Valley' },
  { label: 'Palm Valley', value: 'Palm Valley' },
  { label: 'Palma', value: 'Palma' },
  { label: 'Palma', value: 'Palma' },
  { label: 'Palma Campania', value: 'Palma Campania' },
  { label: 'Palma De Cervelló La', value: 'Palma De Cervelló La' },
  { label: 'Palma De Gandía', value: 'Palma De Gandía' },
  { label: 'Palma Debre', value: 'Palma Debre' },
  { label: 'Palma Del Condado La', value: 'Palma Del Condado La' },
  { label: 'Palma Del Río', value: 'Palma Del Río' },
  { label: 'Palma Di Montechiaro', value: 'Palma Di Montechiaro' },
  { label: 'Palma Gil', value: 'Palma Gil' },
  { label: 'Palma Gil', value: 'Palma Gil' },
  { label: 'Palma Gil', value: 'Palma Gil' },
  { label: 'Palma Grande', value: 'Palma Grande' },
  { label: 'Palma Pegada', value: 'Palma Pegada' },
  { label: 'Palma Sola', value: 'Palma Sola' },
  { label: 'Palma Sola', value: 'Palma Sola' },
  { label: 'Palma Sola', value: 'Palma Sola' },
  { label: 'Palma Soriano', value: 'Palma Soriano' },
  { label: 'Pálmaces De Jadraque', value: 'Pálmaces De Jadraque' },
  { label: 'Palmácia', value: 'Palmácia' },
  { label: 'Palmaner', value: 'Palmaner' },
  { label: 'Palmanova', value: 'Palmanova' },
  { label: 'Palmanova', value: 'Palmanova' },
  { label: 'Palmar', value: 'Palmar' },
  { label: 'Palmar Arriba', value: 'Palmar Arriba' },
  { label: 'Palmar Chico', value: 'Palmar Chico' },
  { label: 'Palmar De Bravo', value: 'Palmar De Bravo' },
  { label: 'Palmar De Cuautla', value: 'Palmar De Cuautla' },
  { label: 'Palmar De Ocoa', value: 'Palmar De Ocoa' },
  { label: 'Palmar De Varela', value: 'Palmar De Varela' },
  { label: 'Palmar Grande', value: 'Palmar Grande' },
  { label: 'Palmar Grande', value: 'Palmar Grande' },
  { label: 'Palmar Primero', value: 'Palmar Primero' },
  { label: 'Palmarejo', value: 'Palmarejo' },
  { label: 'Palmares', value: 'Palmares' },
  { label: 'Palmares', value: 'Palmares' },
  { label: 'Palmares Do Sul', value: 'Palmares Do Sul' },
  { label: 'Palmares Paulista', value: 'Palmares Paulista' },
  { label: 'Palmariggi', value: 'Palmariggi' },
  { label: 'Palmarito Tochapan', value: 'Palmarito Tochapan' },
  { label: 'Palmas', value: 'Palmas' },
  { label: 'Palmas', value: 'Palmas' },
  { label: 'Palmas', value: 'Palmas' },
  { label: 'Palmas Arborea', value: 'Palmas Arborea' },
  { label: 'Palmas Bellas', value: 'Palmas Bellas' },
  { label: 'Palmas De Abajo', value: 'Palmas De Abajo' },
  { label: 'Palmas De Gran Canaria Las', value: 'Palmas De Gran Canaria Las' },
  { label: 'Palmas De Monte Alto', value: 'Palmas De Monte Alto' },
  { label: 'Palmas Del Socorro', value: 'Palmas Del Socorro' },
  { label: 'Palmdale', value: 'Palmdale' },
  { label: 'Palmdale', value: 'Palmdale' },
  { label: 'Palmeira', value: 'Palmeira' },
  { label: 'Palmeira', value: 'Palmeira' },
  { label: 'Palmeira Das Missões', value: 'Palmeira Das Missões' },
  { label: 'Palmeira Do Piauí', value: 'Palmeira Do Piauí' },
  { label: 'Palmeira Doeste', value: 'Palmeira Doeste' },
  { label: 'Palmeira Dos Índios', value: 'Palmeira Dos Índios' },
  { label: 'Palmeirais', value: 'Palmeirais' },
  { label: 'Palmeirândia', value: 'Palmeirândia' },
  { label: 'Palmeirante', value: 'Palmeirante' },
  { label: 'Palmeiras', value: 'Palmeiras' },
  { label: 'Palmeiras De Goiás', value: 'Palmeiras De Goiás' },
  { label: 'Palmeiras Do Tocantins', value: 'Palmeiras Do Tocantins' },
  { label: 'Palmeirina', value: 'Palmeirina' },
  { label: 'Palmeirópolis', value: 'Palmeirópolis' },
  { label: 'Palmela', value: 'Palmela' },
  { label: 'Palmelo', value: 'Palmelo' },
  { label: 'Palmer', value: 'Palmer' },
  { label: 'Palmer', value: 'Palmer' },
  { label: 'Palmer', value: 'Palmer' },
  { label: 'Palmer', value: 'Palmer' },
  { label: 'Palmer Heights', value: 'Palmer Heights' },
  { label: 'Palmer Lake', value: 'Palmer Lake' },
  { label: 'Palmera', value: 'Palmera' },
  { label: 'Palmers Cross', value: 'Palmers Cross' },
  { label: 'Palmerston', value: 'Palmerston' },
  { label: 'Palmerston', value: 'Palmerston' },
  { label: 'Palmerston North', value: 'Palmerston North' },
  { label: 'Palmerstown', value: 'Palmerstown' },
  { label: 'Palmerton', value: 'Palmerton' },
  { label: 'Palmetto', value: 'Palmetto' },
  { label: 'Palmetto', value: 'Palmetto' },
  { label: 'Palmetto Bay', value: 'Palmetto Bay' },
  { label: 'Palmetto Estates', value: 'Palmetto Estates' },
  { label: 'Palmhurst', value: 'Palmhurst' },
  { label: 'Palmi', value: 'Palmi' },
  { label: 'Palmiano', value: 'Palmiano' },
  { label: 'Palmichal', value: 'Palmichal' },
  { label: 'Palmilla', value: 'Palmilla' },
  { label: 'Palmillas', value: 'Palmillas' },
  { label: 'Palmillas', value: 'Palmillas' },
  { label: 'Palmillas', value: 'Palmillas' },
  { label: 'Palmillas', value: 'Palmillas' },
  { label: 'Palmillas', value: 'Palmillas' },
  { label: 'Palmillas', value: 'Palmillas' },
  { label: 'Palmillas De San Juan', value: 'Palmillas De San Juan' },
  { label: 'Palminópolis', value: 'Palminópolis' },
  { label: 'Palmira', value: 'Palmira' },
  { label: 'Palmira', value: 'Palmira' },
  { label: 'Palmira', value: 'Palmira' },
  { label: 'Palmira', value: 'Palmira' },
  { label: 'Palmira', value: 'Palmira' },
  { label: 'Palmira', value: 'Palmira' },
  { label: 'Palmira Centro', value: 'Palmira Centro' },
  { label: 'Palmiste', value: 'Palmiste' },
  { label: 'Palmiste Estate', value: 'Palmiste Estate' },
  { label: 'Palmital', value: 'Palmital' },
  { label: 'Palmital', value: 'Palmital' },
  { label: 'Palmitas', value: 'Palmitas' },
  { label: 'Palmitinho', value: 'Palmitinho' },
  { label: 'Palmito', value: 'Palmito' },
  { label: 'Palmito Del Verde', value: 'Palmito Del Verde' },
  { label: 'Palmitos', value: 'Palmitos' },
  { label: 'Palmoli', value: 'Palmoli' },
  { label: 'Palmona Park', value: 'Palmona Park' },
  { label: 'Pálmonostora', value: 'Pálmonostora' },
  { label: 'Palmópolis', value: 'Palmópolis' },
  { label: 'Palmview', value: 'Palmview' },
  { label: 'Palmview South', value: 'Palmview South' },
  { label: 'Palmwoods', value: 'Palmwoods' },
  { label: 'Palmyra', value: 'Palmyra' },
  { label: 'Palmyra', value: 'Palmyra' },
  { label: 'Palmyra', value: 'Palmyra' },
  { label: 'Palmyra', value: 'Palmyra' },
  { label: 'Palmyra', value: 'Palmyra' },
  { label: 'Palmyra', value: 'Palmyra' },
  { label: 'Palmyra', value: 'Palmyra' },
  { label: 'Palmyra', value: 'Palmyra' },
  { label: 'Palo', value: 'Palo' },
  { label: 'Palo', value: 'Palo' },
  { label: 'Palo', value: 'Palo' },
  { label: 'Palo', value: 'Palo' },
  { label: 'Palo Alto', value: 'Palo Alto' },
  { label: 'Palo Alto', value: 'Palo Alto' },
  { label: 'Palo Alto', value: 'Palo Alto' },
  { label: 'Palo Alto', value: 'Palo Alto' },
  { label: 'Palo Alto County', value: 'Palo Alto County' },
  { label: 'Palo Bendito', value: 'Palo Bendito' },
  { label: 'Palo Blanco', value: 'Palo Blanco' },
  { label: 'Palo Cedro', value: 'Palo Cedro' },
  { label: 'Palo Colorado', value: 'Palo Colorado' },
  { label: 'Palo Del Colle', value: 'Palo Del Colle' },
  { label: 'Palo Gacho', value: 'Palo Gacho' },
  { label: 'Palo Mulato', value: 'Palo Mulato' },
  { label: 'Palo Negro', value: 'Palo Negro' },
  { label: 'Palo Pinto', value: 'Palo Pinto' },
  { label: 'Palo Pinto County', value: 'Palo Pinto County' },
  { label: 'Palo Santo', value: 'Palo Santo' },
  { label: 'Palo Seco', value: 'Palo Seco' },
  { label: 'Palo Seco (El Gato)', value: 'Palo Seco (El Gato)' },
  { label: 'Palocabildo', value: 'Palocabildo' },
  { label: 'Palol De Revardit', value: 'Palol De Revardit' },
  { label: 'Paloma Creek', value: 'Paloma Creek' },
  { label: 'Paloma Creek South', value: 'Paloma Creek South' },
  { label: 'Palomar', value: 'Palomar' },
  { label: 'Palomar De Arroyos', value: 'Palomar De Arroyos' },
  { label: 'Palomar El', value: 'Palomar El' },
  { label: 'Palomares', value: 'Palomares' },
  { label: 'Palomares Del Campo', value: 'Palomares Del Campo' },
  { label: 'Palomares Del Río', value: 'Palomares Del Río' },
  { label: 'Palomas', value: 'Palomas' },
  { label: 'Palomas', value: 'Palomas' },
  { label: 'Palomas', value: 'Palomas' },
  { label: 'Palomas', value: 'Palomas' },
  { label: 'Palombaio', value: 'Palombaio' },
  { label: 'Palombara Sabina', value: 'Palombara Sabina' },
  { label: 'Palombaro', value: 'Palombaro' },
  { label: 'Palomeque', value: 'Palomeque' },
  { label: 'Palomera', value: 'Palomera' },
  { label: 'Palomero', value: 'Palomero' },
  { label: 'Palomoc', value: 'Palomoc' },
  { label: 'Palomonte', value: 'Palomonte' },
  { label: 'Palompon', value: 'Palompon' },
  { label: 'Palompon', value: 'Palompon' },
  { label: 'Paloncha', value: 'Paloncha' },
  { label: 'Palopo', value: 'Palopo' },
  { label: 'Palora', value: 'Palora' },
  { label: 'Palos', value: 'Palos' },
  { label: 'Palos Altos', value: 'Palos Altos' },
  { label: 'Palos Amarillos (Palos Amarillos Yebuciví)', value: 'Palos Amarillos (Palos Amarillos Yebuciví)' },
  { label: 'Palos Blancos', value: 'Palos Blancos' },
  { label: 'Palos De La Frontera', value: 'Palos De La Frontera' },
  { label: 'Palos Heights', value: 'Palos Heights' },
  { label: 'Palos Hills', value: 'Palos Hills' },
  { label: 'Palos Park', value: 'Palos Park' },
  { label: 'Palos Verdes', value: 'Palos Verdes' },
  { label: 'Palos Verdes Estates', value: 'Palos Verdes Estates' },
  { label: 'Palosco', value: 'Palosco' },
  { label: 'Palotás', value: 'Palotás' },
  { label: 'Palotina', value: 'Palotina' },
  { label: 'Palouse', value: 'Palouse' },
  { label: 'Palpa', value: 'Palpa' },
  { label: 'Palpa ', value: 'Palpa ' },
  { label: 'Palpalá', value: 'Palpalá' },
  { label: 'Pals', value: 'Pals' },
  { label: 'Palsana', value: 'Palsana' },
  { label: 'Pålsboda', value: 'Pålsboda' },
  { label: 'Palso', value: 'Palso' },
  { label: 'Palsong', value: 'Palsong' },
  { label: 'Paltamo', value: 'Paltamo' },
  { label: 'Paltin', value: 'Paltin' },
  { label: 'Păltineni', value: 'Păltineni' },
  { label: 'Palting', value: 'Palting' },
  { label: 'Păltiniş', value: 'Păltiniş' },
  { label: 'Păltiniş', value: 'Păltiniş' },
  { label: 'Păltinoasa', value: 'Păltinoasa' },
  { label: 'Palu', value: 'Palu' },
  { label: 'Palu', value: 'Palu' },
  { label: 'Palù', value: 'Palù' },
  { label: 'Palù Del Fersina', value: 'Palù Del Fersina' },
  { label: 'Paluan', value: 'Paluan' },
  { label: 'Paludea', value: 'Paludea' },
  { label: 'Paludi', value: 'Paludi' },
  { label: 'Palusapis', value: 'Palusapis' },
  { label: 'Palusapis', value: 'Palusapis' },
  { label: 'Paluzza', value: 'Paluzza' },
  { label: 'Palwal', value: 'Palwal' },
  { label: 'Palwancha', value: 'Palwancha' },
  { label: 'Palykavichy Pyershyya', value: 'Palykavichy Pyershyya' },
  { label: 'Palzem', value: 'Palzem' },
  { label: 'Pama', value: 'Pama' },
  { label: 'Pama', value: 'Pama' },
  { label: 'Pamantingan', value: 'Pamantingan' },
  { label: 'Pamantingan', value: 'Pamantingan' },
  { label: 'Pamanukan', value: 'Pamanukan' },
  { label: 'Pamatácuaro', value: 'Pamatácuaro' },
  { label: 'Pamatawan', value: 'Pamatawan' },
  { label: 'Pamatawan', value: 'Pamatawan' },
  { label: 'Pambisan', value: 'Pambisan' },
  { label: 'Pambuhan', value: 'Pambuhan' },
  { label: 'Pambujan', value: 'Pambujan' },
  { label: 'Pambujan', value: 'Pambujan' },
  { label: 'Pameče', value: 'Pameče' },
  { label: 'Pamekasan', value: 'Pamekasan' },
  { label: 'Pameungpeuk', value: 'Pameungpeuk' },
  { label: 'Pamhagen', value: 'Pamhagen' },
  { label: 'Pamiątkowo', value: 'Pamiątkowo' },
  { label: 'Pamiers', value: 'Pamiers' },
  { label: 'Pamlico County', value: 'Pamlico County' },
  { label: 'Pampa', value: 'Pampa' },
  { label: 'Pampa Almirón', value: 'Pampa Almirón' },
  { label: 'Pampa De Los Guanacos', value: 'Pampa De Los Guanacos' },
  { label: 'Pampa Del Indio', value: 'Pampa Del Indio' },
  { label: 'Pampa Del Infierno', value: 'Pampa Del Infierno' },
  { label: 'Pampamarca', value: 'Pampamarca' },
  { label: 'Pampanal De Bolívar', value: 'Pampanal De Bolívar' },
  { label: 'Pampaneira', value: 'Pampaneira' },
  { label: 'Pamparato', value: 'Pamparato' },
  { label: 'Pampas', value: 'Pampas' },
  { label: 'Pamphret', value: 'Pamphret' },
  { label: 'Pampierstad', value: 'Pampierstad' },
  { label: 'Pampigny', value: 'Pampigny' },
  { label: 'Pampilhosa Da Serra', value: 'Pampilhosa Da Serra' },
  { label: 'Pampilhosa Do Botão', value: 'Pampilhosa Do Botão' },
  { label: 'Pamplemousses', value: 'Pamplemousses' },
  { label: 'Pamplico', value: 'Pamplico' },
  { label: 'Pampliega', value: 'Pampliega' },
  { label: 'Pamplona', value: 'Pamplona' },
  { label: 'Pamplona', value: 'Pamplona' },
  { label: 'Pamplona', value: 'Pamplona' },
  { label: 'Pamplona', value: 'Pamplona' },
  { label: 'Pamplona', value: 'Pamplona' },
  { label: 'Pamplona', value: 'Pamplona' },
  { label: 'Pamplona/Iruña', value: 'Pamplona/Iruña' },
  { label: 'Pamplonita', value: 'Pamplonita' },
  { label: 'Pampow', value: 'Pampow' },
  { label: 'Pamproux', value: 'Pamproux' },
  { label: 'Pamukkale', value: 'Pamukkale' },
  { label: 'Pamukova', value: 'Pamukova' },
  { label: 'Pamulang', value: 'Pamulang' },
  { label: 'Pamyat’ Parizhskoy Kommuny', value: 'Pamyat’ Parizhskoy Kommuny' },
  { label: 'Pan De Azúcar', value: 'Pan De Azúcar' },
  { label: 'Pana', value: 'Pana' },
  { label: 'Pana Pana', value: 'Pana Pana' },
  { label: 'Panabá', value: 'Panabá' },
  { label: 'Panabingan', value: 'Panabingan' },
  { label: 'Panabingan', value: 'Panabingan' },
  { label: 'Panabo', value: 'Panabo' },
  { label: 'Panabo', value: 'Panabo' },
  { label: 'Panabo', value: 'Panabo' },
  { label: 'Panabuan', value: 'Panabuan' },
  { label: 'Panabuan', value: 'Panabuan' },
  { label: 'Panacan', value: 'Panacan' },
  { label: 'Panaci', value: 'Panaci' },
  { label: 'Panadtaban', value: 'Panadtaban' },
  { label: 'Panadtaban', value: 'Panadtaban' },
  { label: 'Panadura', value: 'Panadura' },
  { label: 'Panagar', value: 'Panagar' },
  { label: 'Panagarh', value: 'Panagarh' },
  { label: 'Panagyurishte', value: 'Panagyurishte' },
  { label: 'Panaitólion', value: 'Panaitólion' },
  { label: 'Panajachel', value: 'Panajachel' },
  { label: 'Panaji', value: 'Panaji' },
  { label: 'Panalanoy', value: 'Panalanoy' },
  { label: 'Panalanoy', value: 'Panalanoy' },
  { label: 'Panales', value: 'Panales' },
  { label: 'Panales Jamaica (Cañones)', value: 'Panales Jamaica (Cañones)' },
  { label: 'Panalingaan', value: 'Panalingaan' },
  { label: 'Panalipan', value: 'Panalipan' },
  { label: 'Panalo-On', value: 'Panalo-On' },
  { label: 'Panama', value: 'Panama' },
  { label: 'Panamá', value: 'Panamá' },
  { label: 'Panamá', value: 'Panamá' },
  { label: 'Panama City', value: 'Panama City' },
  { label: 'Panama City Beach', value: 'Panama City Beach' },
  { label: 'Panamaram', value: 'Panamaram' },
  { label: 'Panambi', value: 'Panambi' },
  { label: 'Panambí', value: 'Panambí' },
  { label: 'Panan', value: 'Panan' },
  { label: 'Panan', value: 'Panan' },
  { label: 'Pan-An', value: 'Pan-An' },
  { label: 'Panania', value: 'Panania' },
  { label: 'Panara', value: 'Panara' },
  { label: 'Panarukan', value: 'Panarukan' },
  { label: 'Pănătău', value: 'Pănătău' },
  { label: 'Panay', value: 'Panay' },
  { label: 'Panay', value: 'Panay' },
  { label: 'Panay', value: 'Panay' },
  { label: 'Panayacan', value: 'Panayacan' },
  { label: 'Panaytayon', value: 'Panaytayon' },
  { label: 'Panazol', value: 'Panazol' },
  { label: 'Panbang', value: 'Panbang' },
  { label: 'Panbeh Dar', value: 'Panbeh Dar' },
  { label: 'Pancalieri', value: 'Pancalieri' },
  { label: 'Pancarana', value: 'Pancarana' },
  { label: 'Pancas', value: 'Pancas' },
  { label: 'Pance', value: 'Pance' },
  { label: 'Pance', value: 'Pance' },
  { label: 'Pancé', value: 'Pancé' },
  { label: 'Pânceşti', value: 'Pânceşti' },
  { label: 'Pănceşti', value: 'Pănceşti' },
  { label: 'Pančevo', value: 'Pančevo' },
  { label: 'Panch Mahals', value: 'Panch Mahals' },
  { label: 'Panchagarh ', value: 'Panchagarh ' },
  { label: 'Panchgani', value: 'Panchgani' },
  { label: 'Panchià', value: 'Panchià' },
  { label: 'Panchimalco', value: 'Panchimalco' },
  { label: 'Panchkula', value: 'Panchkula' },
  { label: 'Panchla', value: 'Panchla' },
  { label: 'Panchmahal District', value: 'Panchmahal District' },
  { label: 'Panchmura', value: 'Panchmura' },
  { label: 'Panchthar', value: 'Panchthar' },
  { label: 'Panciu', value: 'Panciu' },
  { label: 'Pancol', value: 'Pancol' },
  { label: 'Pancorbo', value: 'Pancorbo' },
  { label: 'Pâncota', value: 'Pâncota' },
  { label: 'Pancrudo', value: 'Pancrudo' },
  { label: 'Pánd', value: 'Pánd' },
  { label: 'Pandaan', value: 'Pandaan' },
  { label: 'Pandacan', value: 'Pandacan' },
  { label: 'Pandacaqui', value: 'Pandacaqui' },
  { label: 'Pandacaqui', value: 'Pandacaqui' },
  { label: 'Pandag', value: 'Pandag' },
  { label: 'Pandakan', value: 'Pandakan' },
  { label: 'Pandakan', value: 'Pandakan' },
  { label: 'Pandamatenga', value: 'Pandamatenga' },
  { label: 'Pandami', value: 'Pandami' },
  { label: 'Pandan', value: 'Pandan' },
  { label: 'Pandan', value: 'Pandan' },
  { label: 'Pandan', value: 'Pandan' },
  { label: 'Pandan Niog', value: 'Pandan Niog' },
  { label: 'Pandan Niog', value: 'Pandan Niog' },
  { label: 'Pandaria', value: 'Pandaria' },
  { label: 'Pandasan', value: 'Pandasan' },
  { label: 'Pandasan', value: 'Pandasan' },
  { label: 'Pandasan', value: 'Pandasan' },
  { label: 'Pandatarai', value: 'Pandatarai' },
  { label: 'Pandeglang', value: 'Pandeglang' },
  { label: 'Pandhana', value: 'Pandhana' },
  { label: 'Pandharpur', value: 'Pandharpur' },
  { label: 'Pandhurli', value: 'Pandhurli' },
  { label: 'Pandhurna', value: 'Pandhurna' },
  { label: 'Pandi', value: 'Pandi' },
  { label: 'Pandi', value: 'Pandi' },
  { label: 'Pandi', value: 'Pandi' },
  { label: 'Pandino', value: 'Pandino' },
  { label: 'Pando', value: 'Pando' },
  { label: 'Pando', value: 'Pando' },
  { label: 'Pando', value: 'Pando' },
  { label: 'Pandoh', value: 'Pandoh' },
  { label: 'Pandora', value: 'Pandora' },
  { label: 'Pandrup', value: 'Pandrup' },
  { label: 'Pandu', value: 'Pandu' },
  { label: 'Pandua', value: 'Pandua' },
  { label: 'Panelas', value: 'Panelas' },
  { label: 'Pănet', value: 'Pănet' },
  { label: 'Panettieri', value: 'Panettieri' },
  { label: 'Panevėžys', value: 'Panevėžys' },
  { label: 'Panevėžys City', value: 'Panevėžys City' },
  { label: 'Panevėžys District Municipality', value: 'Panevėžys District Municipality' },
  { label: 'Pang', value: 'Pang' },
  { label: 'Pang', value: 'Pang' },
  { label: 'Pangabuan', value: 'Pangabuan' },
  { label: 'Pangai', value: 'Pangai' },
  { label: 'Pangal Sur', value: 'Pangal Sur' },
  { label: 'Pangal Sur', value: 'Pangal Sur' },
  { label: 'Pangala', value: 'Pangala' },
  { label: 'Pangani', value: 'Pangani' },
  { label: 'Panganiban', value: 'Panganiban' },
  { label: 'Panganiban', value: 'Panganiban' },
  { label: 'Panganiban', value: 'Panganiban' },
  { label: 'Pangao', value: 'Pangao' },
  { label: 'Pangapisan', value: 'Pangapisan' },
  { label: 'Pangapisan', value: 'Pangapisan' },
  { label: 'Pângărăcior', value: 'Pângărăcior' },
  { label: 'Pângăraţi', value: 'Pângăraţi' },
  { label: 'Pangascasan', value: 'Pangascasan' },
  { label: 'Pangascasan', value: 'Pangascasan' },
  { label: 'Pangbourne', value: 'Pangbourne' },
  { label: 'Pangdan', value: 'Pangdan' },
  { label: 'Pangdan', value: 'Pangdan' },
  { label: 'Pangdan', value: 'Pangdan' },
  { label: 'Panghyŏn-Dong', value: 'Panghyŏn-Dong' },
  { label: 'Pangian', value: 'Pangian' },
  { label: 'Pangian', value: 'Pangian' },
  { label: 'Pangian', value: 'Pangian' },
  { label: 'Pangil', value: 'Pangil' },
  { label: 'Pangkalan Brandan', value: 'Pangkalan Brandan' },
  { label: 'Pangkalanbuun', value: 'Pangkalanbuun' },
  { label: 'Pangkalpinang', value: 'Pangkalpinang' },
  { label: 'Panglao', value: 'Panglao' },
  { label: 'Panglima Sugala', value: 'Panglima Sugala' },
  { label: 'Pangnirtung', value: 'Pangnirtung' },
  { label: 'Pango Aluquém', value: 'Pango Aluquém' },
  { label: 'Pangoa', value: 'Pangoa' },
  { label: 'Pañgobilian', value: 'Pañgobilian' },
  { label: 'Pangody', value: 'Pangody' },
  { label: 'Pangpang', value: 'Pangpang' },
  { label: 'Pangpang', value: 'Pangpang' },
  { label: 'Pangpang', value: 'Pangpang' },
  { label: 'Pangpang', value: 'Pangpang' },
  { label: 'Panguipulli', value: 'Panguipulli' },
  { label: 'Panguiranan', value: 'Panguiranan' },
  { label: 'Panguitch', value: 'Panguitch' },
  { label: 'Pangulayan', value: 'Pangulayan' },
  { label: 'Panguma', value: 'Panguma' },
  { label: 'Panguna', value: 'Panguna' },
  { label: 'Pangutaran', value: 'Pangutaran' },
  { label: 'Pangyan', value: 'Pangyan' },
  { label: 'Pangyan', value: 'Pangyan' },
  { label: 'Panhala', value: 'Panhala' },
  { label: 'Panhandle', value: 'Panhandle' },
  { label: 'Pañhé', value: 'Pañhé' },
  { label: 'Panicale', value: 'Panicale' },
  { label: 'Panighina', value: 'Panighina' },
  { label: 'Panihati', value: 'Panihati' },
  { label: 'Panikian', value: 'Panikian' },
  { label: 'Panikian', value: 'Panikian' },
  { label: 'Panikian', value: 'Panikian' },
  { label: 'Panikian', value: 'Panikian' },
  { label: 'Panikian', value: 'Panikian' },
  { label: 'Panikihan', value: 'Panikihan' },
  { label: 'Panindícuaro', value: 'Panindícuaro' },
  { label: 'Panino', value: 'Panino' },
  { label: 'Paninskiy Rayon', value: 'Paninskiy Rayon' },
  { label: 'Paniówki', value: 'Paniówki' },
  { label: 'Panipat', value: 'Panipat' },
  { label: 'Panique', value: 'Panique' },
  { label: 'Paniqui', value: 'Paniqui' },
  { label: 'Paniqui', value: 'Paniqui' },
  { label: 'Paniqui', value: 'Paniqui' },
  { label: 'Panissières', value: 'Panissières' },
  { label: 'Panitan', value: 'Panitan' },
  { label: 'Panitian', value: 'Panitian' },
  { label: 'Paniza', value: 'Paniza' },
  { label: 'Panj', value: 'Panj' },
  { label: 'Panjāb', value: 'Panjāb' },
  { label: 'Panjakent', value: 'Panjakent' },
  { label: 'Panjgūr District', value: 'Panjgūr District' },
  { label: 'Panji', value: 'Panji' },
  { label: 'Panjin Shi', value: 'Panjin Shi' },
  { label: 'Panker', value: 'Panker' },
  { label: 'Panki', value: 'Panki' },
  { label: 'Pankovka', value: 'Pankovka' },
  { label: 'Pankow', value: 'Pankow' },
  { label: 'Pankrushikha', value: 'Pankrushikha' },
  { label: 'Pankrushikhinskiy Rayon', value: 'Pankrushikhinskiy Rayon' },
  { label: 'Pankshin', value: 'Pankshin' },
  { label: 'Panlaitan', value: 'Panlaitan' },
  { label: 'Panlinlang', value: 'Panlinlang' },
  { label: 'Panna', value: 'Panna' },
  { label: 'Pannaconi', value: 'Pannaconi' },
  { label: 'Pannal', value: 'Pannal' },
  { label: 'Pannarano', value: 'Pannarano' },
  { label: 'Pannecé', value: 'Pannecé' },
  { label: 'Pannerden', value: 'Pannerden' },
  { label: 'Pannes', value: 'Pannes' },
  { label: 'Panni', value: 'Panni' },
  { label: 'Pannill Fork', value: 'Pannill Fork' },
  { label: 'Panningen', value: 'Panningen' },
  { label: 'Pannonhalma', value: 'Pannonhalma' },
  { label: 'Pannonhalmi Járás', value: 'Pannonhalmi Járás' },
  { label: 'Pano Aqil', value: 'Pano Aqil' },
  { label: 'Páno Defterá', value: 'Páno Defterá' },
  { label: 'Páno Polemídia', value: 'Páno Polemídia' },
  { label: 'Panognawan', value: 'Panognawan' },
  { label: 'Panoias De Cima', value: 'Panoias De Cima' },
  { label: 'Panola County', value: 'Panola County' },
  { label: 'Panola County', value: 'Panola County' },
  { label: 'Panora', value: 'Panora' },
  { label: 'Panorama', value: 'Panorama' },
  { label: 'Panorama', value: 'Panorama' },
  { label: 'Panórama', value: 'Panórama' },
  { label: 'Panorama Village', value: 'Panorama Village' },
  { label: 'Pánormos', value: 'Pánormos' },
  { label: 'Pánormos', value: 'Pánormos' },
  { label: 'Panotla', value: 'Panotla' },
  { label: 'Panqueba', value: 'Panqueba' },
  { label: 'Panquehue', value: 'Panquehue' },
  { label: 'Panruti', value: 'Panruti' },
  { label: 'Panschwitz-Kuckau', value: 'Panschwitz-Kuckau' },
  { label: 'Pansemal', value: 'Pansemal' },
  { label: 'Panshan', value: 'Panshan' },
  { label: 'Panshi', value: 'Panshi' },
  { label: 'Panskura', value: 'Panskura' },
  { label: 'Pansol', value: 'Pansol' },
  { label: 'Pansoy', value: 'Pansoy' },
  { label: 'Pant', value: 'Pant' },
  { label: 'Pantabangan', value: 'Pantabangan' },
  { label: 'Pantabangan', value: 'Pantabangan' },
  { label: 'Pantai Cenang', value: 'Pantai Cenang' },
  { label: 'Pantai Remis', value: 'Pantai Remis' },
  { label: 'Pantalla', value: 'Pantalla' },
  { label: 'Pantalowice', value: 'Pantalowice' },
  { label: 'Pantanal', value: 'Pantanal' },
  { label: 'Pantanal', value: 'Pantanal' },
  { label: 'Pantano Do Sul', value: 'Pantano Do Sul' },
  { label: 'Pantano Grande', value: 'Pantano Grande' },
  { label: 'Pantao', value: 'Pantao' },
  { label: 'Pantao-Ragat', value: 'Pantao-Ragat' },
  { label: 'Pantar', value: 'Pantar' },
  { label: 'Pantay Na Matanda', value: 'Pantay Na Matanda' },
  { label: 'Pantayivka', value: 'Pantayivka' },
  { label: 'Pantazi', value: 'Pantazi' },
  { label: 'Pantego', value: 'Pantego' },
  { label: 'Pantelhó', value: 'Pantelhó' },
  { label: 'Pantelimon', value: 'Pantelimon' },
  { label: 'Pantelimon', value: 'Pantelimon' },
  { label: 'Pantelimon De Jos', value: 'Pantelimon De Jos' },
  { label: 'Pantelleria', value: 'Pantelleria' },
  { label: 'Pantepec', value: 'Pantepec' },
  { label: 'Pantepec', value: 'Pantepec' },
  { label: 'Panthersville', value: 'Panthersville' },
  { label: 'Panticeu', value: 'Panticeu' },
  { label: 'Panticosa', value: 'Panticosa' },
  { label: 'Pantigliate', value: 'Pantigliate' },
  { label: 'Pantin', value: 'Pantin' },
  { label: 'Pantitlán', value: 'Pantitlán' },
  { label: 'Pantla', value: 'Pantla' },
  { label: 'Pantoja', value: 'Pantoja' },
  { label: 'Pantón', value: 'Pantón' },
  { label: 'Panton Hill', value: 'Panton Hill' },
  { label: 'Pantops', value: 'Pantops' },
  { label: 'Pantubig', value: 'Pantubig' },
  { label: 'Pantubig', value: 'Pantubig' },
  { label: 'Pantukan', value: 'Pantukan' },
  { label: 'Pantukan', value: 'Pantukan' },
  { label: 'Pantukan', value: 'Pantukan' },
  { label: 'Panuaya', value: 'Panuaya' },
  { label: 'Panubigan', value: 'Panubigan' },
  { label: 'Pánuco', value: 'Pánuco' },
  { label: 'Pánuco De Coronado', value: 'Pánuco De Coronado' },
  { label: 'Panukulan', value: 'Panukulan' },
  { label: 'Panvel', value: 'Panvel' },
  { label: 'Panvila', value: 'Panvila' },
  { label: 'Panyam', value: 'Panyam' },
  { label: 'Panyutyne', value: 'Panyutyne' },
  { label: 'Panzano In Chianti', value: 'Panzano In Chianti' },
  { label: 'Panzhihua', value: 'Panzhihua' },
  { label: 'Panzhuang', value: 'Panzhuang' },
  { label: 'Panzós', value: 'Panzós' },
  { label: 'Pão De Açúcar', value: 'Pão De Açúcar' },
  { label: 'Paoay', value: 'Paoay' },
  { label: 'Paoay', value: 'Paoay' },
  { label: 'Paola', value: 'Paola' },
  { label: 'Paola', value: 'Paola' },
  { label: 'Paola', value: 'Paola' },
  { label: 'Paoli', value: 'Paoli' },
  { label: 'Paoli', value: 'Paoli' },
  { label: 'Paolisi', value: 'Paolisi' },
  { label: 'Paolo Vi', value: 'Paolo Vi' },
  { label: 'Paombong', value: 'Paombong' },
  { label: 'Paombong', value: 'Paombong' },
  { label: 'Paonia', value: 'Paonia' },
  { label: 'Paonta Sahib', value: 'Paonta Sahib' },
  { label: 'Paoua', value: 'Paoua' },
  { label: 'Paoy Paet', value: 'Paoy Paet' },
  { label: 'Pápa', value: 'Pápa' },
  { label: 'Pāpa‘Ikou', value: 'Pāpa‘Ikou' },
  { label: 'Papagaios', value: 'Papagaios' },
  { label: 'Papágou', value: 'Papágou' },
  { label: 'Pápai Járás', value: 'Pápai Járás' },
  { label: 'Papakura', value: 'Papakura' },
  { label: 'Papaloapan', value: 'Papaloapan' },
  { label: 'Papaloctipan', value: 'Papaloctipan' },
  { label: 'Papalotla', value: 'Papalotla' },
  { label: 'Papalotla', value: 'Papalotla' },
  { label: 'Papanasam', value: 'Papanasam' },
  { label: 'Papanduva', value: 'Papanduva' },
  { label: 'Papanice', value: 'Papanice' },
  { label: 'Papanoa', value: 'Papanoa' },
  { label: 'Papantla De Olarte', value: 'Papantla De Olarte' },
  { label: 'Papar', value: 'Papar' },
  { label: 'Papasidero', value: 'Papasidero' },
  { label: 'Papatlatla', value: 'Papatlatla' },
  { label: 'Papatlazolco', value: 'Papatlazolco' },
  { label: 'Papatowai', value: 'Papatowai' },
  { label: 'Papatrigo', value: 'Papatrigo' },
  { label: 'Păpăuți', value: 'Păpăuți' },
  { label: 'Papaya', value: 'Papaya' },
  { label: 'Papaya', value: 'Papaya' },
  { label: 'Papayal', value: 'Papayal' },
  { label: 'Papenburg', value: 'Papenburg' },
  { label: 'Papendorf', value: 'Papendorf' },
  { label: 'Papendrecht', value: 'Papendrecht' },
  { label: 'Papenveer', value: 'Papenveer' },
  { label: 'Paphos', value: 'Paphos' },
  { label: 'Papillion', value: 'Papillion' },
  { label: 'Papine', value: 'Papine' },
  { label: 'Papineauville', value: 'Papineauville' },
  { label: 'Papiol El', value: 'Papiol El' },
  { label: 'Papireddippatti', value: 'Papireddippatti' },
  { label: 'Papiu Ilarian', value: 'Papiu Ilarian' },
  { label: 'Papozze', value: 'Papozze' },
  { label: 'Pappadátes', value: 'Pappadátes' },
  { label: 'Pappadhátai', value: 'Pappadhátai' },
  { label: 'Papparappatti', value: 'Papparappatti' },
  { label: 'Pappenheim', value: 'Pappenheim' },
  { label: 'Pappenheim', value: 'Pappenheim' },
  { label: 'Pappinissheri', value: 'Pappinissheri' },
  { label: 'Paprotnia', value: 'Paprotnia' },
  { label: 'Paptalaya', value: 'Paptalaya' },
  { label: 'Papudo', value: 'Papudo' },
  { label: 'Papunaua', value: 'Papunaua' },
  { label: 'Papworth Everard', value: 'Papworth Everard' },
  { label: 'Paquera', value: 'Paquera' },
  { label: 'Paquetá', value: 'Paquetá' },
  { label: 'Paquier', value: 'Paquier' },
  { label: 'Par', value: 'Par' },
  { label: 'Pará De Minas', value: 'Pará De Minas' },
  { label: 'Para Hills', value: 'Para Hills' },
  { label: 'Para Hills West', value: 'Para Hills West' },
  { label: 'Para Vista', value: 'Para Vista' },
  { label: 'Parabcan', value: 'Parabcan' },
  { label: 'Parabel’', value: 'Parabel’' },
  { label: 'Parabiago', value: 'Parabiago' },
  { label: 'Parabita', value: 'Parabita' },
  { label: 'Paraburdoo', value: 'Paraburdoo' },
  { label: 'Paracale', value: 'Paracale' },
  { label: 'Paracambi', value: 'Paracambi' },
  { label: 'Paracas', value: 'Paracas' },
  { label: 'Paracatu', value: 'Paracatu' },
  { label: 'Paracelis', value: 'Paracelis' },
  { label: 'Paracelis', value: 'Paracelis' },
  { label: 'Paracho De Verduzco', value: 'Paracho De Verduzco' },
  { label: 'Parachute', value: 'Parachute' },
  { label: 'Paraćin', value: 'Paraćin' },
  { label: 'Paracuaro', value: 'Paracuaro' },
  { label: 'Parácuaro', value: 'Parácuaro' },
  { label: 'Paracuellos', value: 'Paracuellos' },
  { label: 'Paracuellos De Jarama', value: 'Paracuellos De Jarama' },
  { label: 'Paracuellos De Jiloca', value: 'Paracuellos De Jiloca' },
  { label: 'Paracuellos De La Ribera', value: 'Paracuellos De La Ribera' },
  { label: 'Paracuru', value: 'Paracuru' },
  { label: 'Parád', value: 'Parád' },
  { label: 'Parada', value: 'Parada' },
  { label: 'Parada De Arriba', value: 'Parada De Arriba' },
  { label: 'Parada De Rubiales', value: 'Parada De Rubiales' },
  { label: 'Parada De Sil', value: 'Parada De Sil' },
  { label: 'Paradahan', value: 'Paradahan' },
  { label: 'Paradas', value: 'Paradas' },
  { label: 'Paradela', value: 'Paradela' },
  { label: 'Paradinas De San Juan', value: 'Paradinas De San Juan' },
  { label: 'Paradip Garh', value: 'Paradip Garh' },
  { label: 'Paradis', value: 'Paradis' },
  { label: 'Paradise', value: 'Paradise' },
  { label: 'Paradise', value: 'Paradise' },
  { label: 'Paradise', value: 'Paradise' },
  { label: 'Paradise', value: 'Paradise' },
  { label: 'Paradise', value: 'Paradise' },
  { label: 'Paradise', value: 'Paradise' },
  { label: 'Paradise Heights', value: 'Paradise Heights' },
  { label: 'Paradise Hills', value: 'Paradise Hills' },
  { label: 'Paradise Point', value: 'Paradise Point' },
  { label: 'Paradise Valley', value: 'Paradise Valley' },
  { label: 'Paradou', value: 'Paradou' },
  { label: 'Parádsasvár', value: 'Parádsasvár' },
  { label: 'Paradyż', value: 'Paradyż' },
  { label: 'Parafield Gardens', value: 'Parafield Gardens' },
  { label: 'Paragominas', value: 'Paragominas' },
  { label: 'Paragould', value: 'Paragould' },
  { label: 'Paraguaçu', value: 'Paraguaçu' },
  { label: 'Paraguaçu Paulista', value: 'Paraguaçu Paulista' },
  { label: 'Paraguarí', value: 'Paraguarí' },
  { label: 'Parahuén', value: 'Parahuén' },
  { label: 'Paraí', value: 'Paraí' },
  { label: 'Paraíba Do Sul', value: 'Paraíba Do Sul' },
  { label: 'Paraibano', value: 'Paraibano' },
  { label: 'Paraibuna', value: 'Paraibuna' },
  { label: 'Pâraie', value: 'Pâraie' },
  { label: 'Paraipaba', value: 'Paraipaba' },
  { label: 'Paraiso', value: 'Paraiso' },
  { label: 'Paraiso', value: 'Paraiso' },
  { label: 'Paraiso', value: 'Paraiso' },
  { label: 'Paraiso', value: 'Paraiso' },
  { label: 'Paraíso', value: 'Paraíso' },
  { label: 'Paraíso', value: 'Paraíso' },
  { label: 'Paraíso', value: 'Paraíso' },
  { label: 'Paraíso', value: 'Paraíso' },
  { label: 'Paraíso', value: 'Paraíso' },
  { label: 'Paraíso', value: 'Paraíso' },
  { label: 'Paraíso Das Águas', value: 'Paraíso Das Águas' },
  { label: 'Paraíso Del Grijalva', value: 'Paraíso Del Grijalva' },
  { label: 'Paraíso Do Norte', value: 'Paraíso Do Norte' },
  { label: 'Paraíso Do Sul', value: 'Paraíso Do Sul' },
  { label: 'Paraíso Do Tocantins', value: 'Paraíso Do Tocantins' },
  { label: 'Paraiso Novillero', value: 'Paraiso Novillero' },
  { label: 'Paraisópolis', value: 'Paraisópolis' },
  { label: 'Paraje El Mirador', value: 'Paraje El Mirador' },
  { label: 'Paraje La Pera', value: 'Paraje La Pera' },
  { label: 'Paraje Montero (Paraje Montero De Zaragoza)', value: 'Paraje Montero (Paraje Montero De Zaragoza)' },
  { label: 'Paraje Nuevo', value: 'Paraje Nuevo' },
  { label: 'Paraje Trejo (El Chaparral)', value: 'Paraje Trejo (El Chaparral)' },
  { label: 'Parakai', value: 'Parakai' },
  { label: 'Parakou', value: 'Parakou' },
  { label: 'Paralía', value: 'Paralía' },
  { label: 'Paralía', value: 'Paralía' },
  { label: 'Paralía Avlídhos', value: 'Paralía Avlídhos' },
  { label: 'Paralía Ofryníou', value: 'Paralía Ofryníou' },
  { label: 'Paralía Vérgas', value: 'Paralía Vérgas' },
  { label: 'Paralímni', value: 'Paralímni' },
  { label: 'Paralowie', value: 'Paralowie' },
  { label: 'Paramagudi', value: 'Paramagudi' },
  { label: 'Paramaribo', value: 'Paramaribo' },
  { label: 'Parambu', value: 'Parambu' },
  { label: 'Paramirim', value: 'Paramirim' },
  { label: 'Páramo', value: 'Páramo' },
  { label: 'Páramo De Boedo', value: 'Páramo De Boedo' },
  { label: 'Páramo Del Sil', value: 'Páramo Del Sil' },
  { label: 'Páramo O', value: 'Páramo O' },
  { label: 'Paramonga', value: 'Paramonga' },
  { label: 'Paramoti', value: 'Paramoti' },
  { label: 'Paramount', value: 'Paramount' },
  { label: 'Paramount-Long Meadow', value: 'Paramount-Long Meadow' },
  { label: 'Paramus', value: 'Paramus' },
  { label: 'Paramythiá', value: 'Paramythiá' },
  { label: 'Paran’Ga', value: 'Paran’Ga' },
  { label: 'Paran’Ginskiy Rayon', value: 'Paran’Ginskiy Rayon' },
  { label: 'Paraná', value: 'Paraná' },
  { label: 'Paraná', value: 'Paraná' },
  { label: 'Paranã', value: 'Paranã' },
  { label: 'Paranacity', value: 'Paranacity' },
  { label: 'Paranaguá', value: 'Paranaguá' },
  { label: 'Paranaíba', value: 'Paranaíba' },
  { label: 'Paranaiguara', value: 'Paranaiguara' },
  { label: 'Paranaíta', value: 'Paranaíta' },
  { label: 'Paranapanema', value: 'Paranapanema' },
  { label: 'Paranapoema', value: 'Paranapoema' },
  { label: 'Paranapuã', value: 'Paranapuã' },
  { label: 'Parañaque', value: 'Parañaque' },
  { label: 'Paranatama', value: 'Paranatama' },
  { label: 'Paranatinga', value: 'Paranatinga' },
  { label: 'Paranavaí', value: 'Paranavaí' },
  { label: 'Parand', value: 'Parand' },
  { label: 'Parandak', value: 'Parandak' },
  { label: 'Paranésti', value: 'Paranésti' },
  { label: 'Parang', value: 'Parang' },
  { label: 'Parang', value: 'Parang' },
  { label: 'Parang', value: 'Parang' },
  { label: 'Parang', value: 'Parang' },
  { label: 'Parang', value: 'Parang' },
  { label: 'Parang', value: 'Parang' },
  { label: 'Parangan', value: 'Parangan' },
  { label: 'Parangan', value: 'Parangan' },
  { label: 'Parangarico', value: 'Parangarico' },
  { label: 'Paranhos', value: 'Paranhos' },
  { label: 'Paranhos', value: 'Paranhos' },
  { label: 'Paraopeba', value: 'Paraopeba' },
  { label: 'Parap', value: 'Parap' },
  { label: 'Paraparaumu', value: 'Paraparaumu' },
  { label: 'Parapat', value: 'Parapat' },
  { label: 'Parapótamos', value: 'Parapótamos' },
  { label: 'Parapuã', value: 'Parapuã' },
  { label: 'Parari', value: 'Parari' },
  { label: 'Parás', value: 'Parás' },
  { label: 'Parás Nuevo León', value: 'Parás Nuevo León' },
  { label: 'Paras Rampur', value: 'Paras Rampur' },
  { label: 'Parasia', value: 'Parasia' },
  { label: 'Paratebueno', value: 'Paratebueno' },
  { label: 'Paratico', value: 'Paratico' },
  { label: 'Paratinga', value: 'Paratinga' },
  { label: 'Paratunka', value: 'Paratunka' },
  { label: 'Paraty', value: 'Paraty' },
  { label: 'Paraú', value: 'Paraú' },
  { label: 'Pârâu', value: 'Pârâu' },
  { label: 'Pârâu Crucii', value: 'Pârâu Crucii' },
  { label: 'Pârâu Mare', value: 'Pârâu Mare' },
  { label: 'Parauapebas', value: 'Parauapebas' },
  { label: 'Paraul', value: 'Paraul' },
  { label: 'Paraúna', value: 'Paraúna' },
  { label: 'Parauta', value: 'Parauta' },
  { label: 'Parava', value: 'Parava' },
  { label: 'Paravati', value: 'Paravati' },
  { label: 'Paravóla', value: 'Paravóla' },
  { label: 'Paravur Tekkumbhagam', value: 'Paravur Tekkumbhagam' },
  { label: 'Paray-Le-Monial', value: 'Paray-Le-Monial' },
  { label: 'Paray-Vieille-Poste', value: 'Paray-Vieille-Poste' },
  { label: 'Parazinho', value: 'Parazinho' },
  { label: 'Parbasdorf', value: 'Parbasdorf' },
  { label: 'Parbat ', value: 'Parbat ' },
  { label: 'Parbatipur', value: 'Parbatipur' },
  { label: 'Parbhani', value: 'Parbhani' },
  { label: 'Parbold', value: 'Parbold' },
  { label: 'Parc Estate', value: 'Parc Estate' },
  { label: 'Parc Hosingen', value: 'Parc Hosingen' },
  { label: 'Parçay-Les-Pins', value: 'Parçay-Les-Pins' },
  { label: 'Parçay-Meslay', value: 'Parçay-Meslay' },
  { label: 'Parc-Boutin', value: 'Parc-Boutin' },
  { label: 'Parcelas La Milagrosa', value: 'Parcelas La Milagrosa' },
  { label: 'Parcelas Nuevas', value: 'Parcelas Nuevas' },
  { label: 'Parcelas Peñuelas', value: 'Parcelas Peñuelas' },
  { label: 'Parcent', value: 'Parcent' },
  { label: 'Parcé-Sur-Sarthe', value: 'Parcé-Sur-Sarthe' },
  { label: 'Parchal', value: 'Parchal' },
  { label: 'Parchim', value: 'Parchim' },
  { label: 'Parchment', value: 'Parchment' },
  { label: 'Parchowo', value: 'Parchowo' },
  { label: 'Parcines', value: 'Parcines' },
  { label: 'Parco Leonardo', value: 'Parco Leonardo' },
  { label: 'Parco Scizzo-Parchitello', value: 'Parco Scizzo-Parchitello' },
  { label: 'Pârcovaci', value: 'Pârcovaci' },
  { label: 'Parczew', value: 'Parczew' },
  { label: 'Pardeeville', value: 'Pardeeville' },
  { label: 'Pardesiyya', value: 'Pardesiyya' },
  { label: 'Pardi', value: 'Pardi' },
  { label: 'Pardilhó', value: 'Pardilhó' },
  { label: 'Pardilla', value: 'Pardilla' },
  { label: 'Pardina', value: 'Pardina' },
  { label: 'Pardines', value: 'Pardines' },
  { label: 'Pardinho', value: 'Pardinho' },
  { label: 'Pardis', value: 'Pardis' },
  { label: 'Pardo', value: 'Pardo' },
  { label: 'Pardos', value: 'Pardos' },
  { label: 'Pardoşi', value: 'Pardoşi' },
  { label: 'Pardubice', value: 'Pardubice' },
  { label: 'Pare', value: 'Pare' },
  { label: 'Parè', value: 'Parè' },
  { label: 'Pareci Novo', value: 'Pareci Novo' },
  { label: 'Parecis', value: 'Parecis' },
  { label: 'Parede', value: 'Parede' },
  { label: 'Parede', value: 'Parede' },
  { label: 'Paredes', value: 'Paredes' },
  { label: 'Paredes', value: 'Paredes' },
  { label: 'Paredes', value: 'Paredes' },
  { label: 'Paredes De Coura', value: 'Paredes De Coura' },
  { label: 'Paredes De Escalona', value: 'Paredes De Escalona' },
  { label: 'Paredes De Nava', value: 'Paredes De Nava' },
  { label: 'Paredes De Sigüenza', value: 'Paredes De Sigüenza' },
  { label: 'Paredón', value: 'Paredón' },
  { label: 'Paredón', value: 'Paredón' },
  { label: 'Paredón Arriba', value: 'Paredón Arriba' },
  { label: 'Paredón Colorado', value: 'Paredón Colorado' },
  { label: 'Paredones', value: 'Paredones' },
  { label: 'Pareja', value: 'Pareja' },
  { label: 'Parekklisha', value: 'Parekklisha' },
  { label: 'Parel', value: 'Parel' },
  { label: 'Parelhas', value: 'Parelhas' },
  { label: 'Parella', value: 'Parella' },
  { label: 'Parem Municipality', value: 'Parem Municipality' },
  { label: 'Parempuyre', value: 'Parempuyre' },
  { label: 'Parenti', value: 'Parenti' },
  { label: 'Parentis-En-Born', value: 'Parentis-En-Born' },
  { label: 'Páreo', value: 'Páreo' },
  { label: 'Parepare', value: 'Parepare' },
  { label: 'Parepa-Rușani', value: 'Parepa-Rușani' },
  { label: 'Parera', value: 'Parera' },
  { label: 'Paresar', value: 'Paresar' },
  { label: 'Parete', value: 'Parete' },
  { label: 'Pareto', value: 'Pareto' },
  { label: 'Parets Del Vallès', value: 'Parets Del Vallès' },
  { label: 'Parfen’Yevo', value: 'Parfen’Yevo' },
  { label: 'Parfen’Yevskiy Rayon', value: 'Parfen’Yevskiy Rayon' },
  { label: 'Parfino', value: 'Parfino' },
  { label: 'Parfinskiy Rayon', value: 'Parfinskiy Rayon' },
  { label: 'Párga', value: 'Párga' },
  { label: 'Pârgăreşti', value: 'Pârgăreşti' },
  { label: 'Pargas', value: 'Pargas' },
  { label: 'Parghelia', value: 'Parghelia' },
  { label: 'Pargny-Sur-Saulx', value: 'Pargny-Sur-Saulx' },
  { label: 'Pargolovo', value: 'Pargolovo' },
  { label: 'Parham', value: 'Parham' },
  { label: 'Părhăuți', value: 'Părhăuți' },
  { label: 'Pariaman', value: 'Pariaman' },
  { label: 'Parian Dakula', value: 'Parian Dakula' },
  { label: 'Parian Dakula', value: 'Parian Dakula' },
  { label: 'Parichha', value: 'Parichha' },
  { label: 'Parichhatgarh', value: 'Parichhatgarh' },
  { label: 'Pariconha', value: 'Pariconha' },
  { label: 'Parícuaro', value: 'Parícuaro' },
  { label: 'Parigné', value: 'Parigné' },
  { label: 'Parigné-Le-Pôlin', value: 'Parigné-Le-Pôlin' },
  { label: 'Parigny', value: 'Parigny' },
  { label: 'Parika', value: 'Parika' },
  { label: 'Parikkala', value: 'Parikkala' },
  { label: 'Parilla', value: 'Parilla' },
  { label: 'Parincea', value: 'Parincea' },
  { label: 'Paringa', value: 'Paringa' },
  { label: 'Paringao', value: 'Paringao' },
  { label: 'Paringao', value: 'Paringao' },
  { label: 'Parintins', value: 'Parintins' },
  { label: 'Parioc Segundo', value: 'Parioc Segundo' },
  { label: 'Parioc Segundo', value: 'Parioc Segundo' },
  { label: 'Parion', value: 'Parion' },
  { label: 'Paripiranga', value: 'Paripiranga' },
  { label: 'Paripueira', value: 'Paripueira' },
  { label: 'Pariquera Açu', value: 'Pariquera Açu' },
  { label: 'Pariquera-Açu', value: 'Pariquera-Açu' },
  { label: 'Paris', value: 'Paris' },
  { label: 'Paris', value: 'Paris' },
  { label: 'Paris', value: 'Paris' },
  { label: 'Paris', value: 'Paris' },
  { label: 'Paris', value: 'Paris' },
  { label: 'Paris', value: 'Paris' },
  { label: 'Paris', value: 'Paris' },
  { label: 'Paris', value: 'Paris' },
  { label: 'Paris', value: 'Paris' },
  { label: 'Paris', value: 'Paris' },
  { label: 'París', value: 'París' },
  { label: 'Parisi', value: 'Parisi' },
  { label: 'Parista', value: 'Parista' },
  { label: 'Parista', value: 'Parista' },
  { label: 'Parit Buntar', value: 'Parit Buntar' },
  { label: 'Parit Raja', value: 'Parit Raja' },
  { label: 'Parita', value: 'Parita' },
  { label: 'Paritilla', value: 'Paritilla' },
  { label: 'Pariyapuram', value: 'Pariyapuram' },
  { label: 'Pariz', value: 'Pariz' },
  { label: 'Parizh', value: 'Parizh' },
  { label: 'Pârjol', value: 'Pârjol' },
  { label: 'Park Avenue', value: 'Park Avenue' },
  { label: 'Park City', value: 'Park City' },
  { label: 'Park City', value: 'Park City' },
  { label: 'Park City', value: 'Park City' },
  { label: 'Park City', value: 'Park City' },
  { label: 'Park County', value: 'Park County' },
  { label: 'Park County', value: 'Park County' },
  { label: 'Park County', value: 'Park County' },
  { label: 'Park Falls', value: 'Park Falls' },
  { label: 'Park Forest', value: 'Park Forest' },
  { label: 'Park Forest Village', value: 'Park Forest Village' },
  { label: 'Park Grove', value: 'Park Grove' },
  { label: 'Park Hill', value: 'Park Hill' },
  { label: 'Park Hills', value: 'Park Hills' },
  { label: 'Park Hills', value: 'Park Hills' },
  { label: 'Park Holme', value: 'Park Holme' },
  { label: 'Park Layne', value: 'Park Layne' },
  { label: 'Park Orchards', value: 'Park Orchards' },
  { label: 'Park Rapids', value: 'Park Rapids' },
  { label: 'Park Ridge', value: 'Park Ridge' },
  { label: 'Park Ridge', value: 'Park Ridge' },
  { label: 'Park Ridge', value: 'Park Ridge' },
  { label: 'Park Ridge South', value: 'Park Ridge South' },
  { label: 'Park River', value: 'Park River' },
  { label: 'Park Slope', value: 'Park Slope' },
  { label: 'Park View', value: 'Park View' },
  { label: 'Parkano', value: 'Parkano' },
  { label: 'Parkchester', value: 'Parkchester' },
  { label: 'Parkdale', value: 'Parkdale' },
  { label: 'Parke County', value: 'Parke County' },
  { label: 'Parkent', value: 'Parkent' },
  { label: 'Parker', value: 'Parker' },
  { label: 'Parker', value: 'Parker' },
  { label: 'Parker', value: 'Parker' },
  { label: 'Parker', value: 'Parker' },
  { label: 'Parker', value: 'Parker' },
  { label: 'Parker', value: 'Parker' },
  { label: 'Parker City', value: 'Parker City' },
  { label: 'Parker County', value: 'Parker County' },
  { label: 'Parkers Hill', value: 'Parkers Hill' },
  { label: 'Parkers Prairie', value: 'Parkers Prairie' },
  { label: 'Parkersburg', value: 'Parkersburg' },
  { label: 'Parkersburg', value: 'Parkersburg' },
  { label: 'Parkerville', value: 'Parkerville' },
  { label: 'Parkes', value: 'Parkes' },
  { label: 'Parkesburg', value: 'Parkesburg' },
  { label: 'Parkhill', value: 'Parkhill' },
  { label: 'Parkhurst', value: 'Parkhurst' },
  { label: 'Parkin', value: 'Parkin' },
  { label: 'Parkinson', value: 'Parkinson' },
  { label: 'Parkland', value: 'Parkland' },
  { label: 'Parkland', value: 'Parkland' },
  { label: 'Parklea', value: 'Parklea' },
  { label: 'Parkovyy', value: 'Parkovyy' },
  { label: 'Parks', value: 'Parks' },
  { label: 'Parks Road', value: 'Parks Road' },
  { label: 'Parksdale', value: 'Parksdale' },
  { label: 'Parkside', value: 'Parkside' },
  { label: 'Parkside', value: 'Parkside' },
  { label: 'Parkside', value: 'Parkside' },
  { label: 'Parkstein', value: 'Parkstein' },
  { label: 'Parkstetten', value: 'Parkstetten' },
  { label: 'Parkston', value: 'Parkston' },
  { label: 'Parkstone', value: 'Parkstone' },
  { label: 'Parksville', value: 'Parksville' },
  { label: 'Parkville', value: 'Parkville' },
  { label: 'Parkville', value: 'Parkville' },
  { label: 'Parkville', value: 'Parkville' },
  { label: 'Parkville', value: 'Parkville' },
  { label: 'Parkville', value: 'Parkville' },
  { label: 'Parkway', value: 'Parkway' },
  { label: 'Parkwood', value: 'Parkwood' },
  { label: 'Parkwood', value: 'Parkwood' },
  { label: 'Parkwood', value: 'Parkwood' },
  { label: 'Parkwood', value: 'Parkwood' },
  { label: 'Parla', value: 'Parla' },
  { label: 'Parlakimidi', value: 'Parlakimidi' },
  { label: 'Parlasco', value: 'Parlasco' },
  { label: 'Parlavà', value: 'Parlavà' },
  { label: 'Parli Vaijnath', value: 'Parli Vaijnath' },
  { label: 'Parlier', value: 'Parlier' },
  { label: 'Parluk', value: 'Parluk' },
  { label: 'Parma', value: 'Parma' },
  { label: 'Parma', value: 'Parma' },
  { label: 'Parma', value: 'Parma' },
  { label: 'Parma', value: 'Parma' },
  { label: 'Parma Heights', value: 'Parma Heights' },
  { label: 'Parmain', value: 'Parmain' },
  { label: 'Parmelia', value: 'Parmelia' },
  { label: 'Parmer County', value: 'Parmer County' },
  { label: 'Parmezzana Calzana', value: 'Parmezzana Calzana' },
  { label: 'Parnaguá', value: 'Parnaguá' },
  { label: 'Parnaíba', value: 'Parnaíba' },
  { label: 'Parnamirim', value: 'Parnamirim' },
  { label: 'Parnamirim', value: 'Parnamirim' },
  { label: 'Parnarama', value: 'Parnarama' },
  { label: 'Parnas', value: 'Parnas' },
  { label: 'Parndorf', value: 'Parndorf' },
  { label: 'Parner', value: 'Parner' },
  { label: 'Parnera', value: 'Parnera' },
  { label: 'Pärnu', value: 'Pärnu' },
  { label: 'Pärnu Linn', value: 'Pärnu Linn' },
  { label: 'Pärnu-Jaagupi', value: 'Pärnu-Jaagupi' },
  { label: 'Paro', value: 'Paro' },
  { label: 'Parobé', value: 'Parobé' },
  { label: 'Parodi Ligure', value: 'Parodi Ligure' },
  { label: 'Parol', value: 'Parol' },
  { label: 'Parola', value: 'Parola' },
  { label: 'Paroldo', value: 'Paroldo' },
  { label: 'Parole', value: 'Parole' },
  { label: 'Parolise', value: 'Parolise' },
  { label: 'Paron', value: 'Paron' },
  { label: 'Parona', value: 'Parona' },
  { label: 'Parona', value: 'Parona' },
  { label: 'Paroo', value: 'Paroo' },
  { label: 'Páros', value: 'Páros' },
  { label: 'Parottee', value: 'Parottee' },
  { label: 'Parowan', value: 'Parowan' },
  { label: 'Parque Batlle', value: 'Parque Batlle' },
  { label: 'Parque De Poblamiento Solidaridad', value: 'Parque De Poblamiento Solidaridad' },
  { label: 'Parque Industrial Ciudad Mitras', value: 'Parque Industrial Ciudad Mitras' },
  { label: 'Parque Lefevre', value: 'Parque Lefevre' },
  { label: 'Parque Rodó', value: 'Parque Rodó' },
  { label: 'Parque Urbano Napateco', value: 'Parque Urbano Napateco' },
  { label: 'Parra De Las Vegas La', value: 'Parra De Las Vegas La' },
  { label: 'Parra La', value: 'Parra La' },
  { label: 'Parral', value: 'Parral' },
  { label: 'Parral', value: 'Parral' },
  { label: 'Parral El', value: 'Parral El' },
  { label: 'Parramatta', value: 'Parramatta' },
  { label: 'Parramatta Park', value: 'Parramatta Park' },
  { label: 'Parramos', value: 'Parramos' },
  { label: 'Parrano', value: 'Parrano' },
  { label: 'Parras De Castellote Las', value: 'Parras De Castellote Las' },
  { label: 'Parras De La Fuente', value: 'Parras De La Fuente' },
  { label: 'Parravak’Ar', value: 'Parravak’Ar' },
  { label: 'Parre', value: 'Parre' },
  { label: 'Parrearra', value: 'Parrearra' },
  { label: 'Parres', value: 'Parres' },
  { label: 'Parrilla Ii', value: 'Parrilla Ii' },
  { label: 'Parrilla La', value: 'Parrilla La' },
  { label: 'Parrillas', value: 'Parrillas' },
  { label: 'Parrita', value: 'Parrita' },
  { label: 'Parrsboro', value: 'Parrsboro' },
  { label: 'Parry Sound', value: 'Parry Sound' },
  { label: 'Parry Sound District', value: 'Parry Sound District' },
  { label: 'Parry Town', value: 'Parry Town' },
  { label: 'Parsa ', value: 'Parsa ' },
  { label: 'Parsabad', value: 'Parsabad' },
  { label: 'Parsau', value: 'Parsau' },
  { label: 'Parsberg', value: 'Parsberg' },
  { label: 'Parschlug', value: 'Parschlug' },
  { label: 'Pârscov', value: 'Pârscov' },
  { label: 'Pârşcoveni', value: 'Pârşcoveni' },
  { label: 'Parseoni', value: 'Parseoni' },
  { label: 'Parshadepur', value: 'Parshadepur' },
  { label: 'Parshall', value: 'Parshall' },
  { label: 'Parsian', value: 'Parsian' },
  { label: 'Parsippany', value: 'Parsippany' },
  { label: 'Parsons', value: 'Parsons' },
  { label: 'Parsons', value: 'Parsons' },
  { label: 'Parsons', value: 'Parsons' },
  { label: 'Parsonsfield', value: 'Parsonsfield' },
  { label: 'Part Of Banana Ground', value: 'Part Of Banana Ground' },
  { label: 'Part Of Banana Ground', value: 'Part Of Banana Ground' },
  { label: 'Part Of Douglas Castle', value: 'Part Of Douglas Castle' },
  { label: 'Part Of Gutters', value: 'Part Of Gutters' },
  { label: 'Part Of Guys Hill', value: 'Part Of Guys Hill' },
  { label: 'Part Of Kellits', value: 'Part Of Kellits' },
  { label: 'Part Of Keystone', value: 'Part Of Keystone' },
  { label: 'Part Of Rock Hall', value: 'Part Of Rock Hall' },
  { label: 'Part Of Spaldings', value: 'Part Of Spaldings' },
  { label: 'Parța', value: 'Parța' },
  { label: 'Partaloa', value: 'Partaloa' },
  { label: 'Partanna', value: 'Partanna' },
  { label: 'Partapur', value: 'Partapur' },
  { label: 'Partenheim', value: 'Partenheim' },
  { label: 'Partenit', value: 'Partenit' },
  { label: 'Partenstein', value: 'Partenstein' },
  { label: 'Părteştii De Jos', value: 'Părteştii De Jos' },
  { label: 'Parthenay', value: 'Parthenay' },
  { label: 'Parthum Wan', value: 'Parthum Wan' },
  { label: 'Partido', value: 'Partido' },
  { label: 'Partido De La Sierra En Tobalina', value: 'Partido De La Sierra En Tobalina' },
  { label: 'Partille', value: 'Partille' },
  { label: 'Partington', value: 'Partington' },
  { label: 'Partinico', value: 'Partinico' },
  { label: 'Partizan', value: 'Partizan' },
  { label: 'Partizansk', value: 'Partizansk' },
  { label: 'Partizánske', value: 'Partizánske' },
  { label: 'Partizanskiy Rayon', value: 'Partizanskiy Rayon' },
  { label: 'Partizanskoye', value: 'Partizanskoye' },
  { label: 'Partizanskoye', value: 'Partizanskoye' },
  { label: 'Partizany', value: 'Partizany' },
  { label: 'Partridge Green', value: 'Partridge Green' },
  { label: 'Partsa', value: 'Partsa' },
  { label: 'Partur', value: 'Partur' },
  { label: 'Partynice', value: 'Partynice' },
  { label: 'Partyzans’Ke', value: 'Partyzans’Ke' },
  { label: 'Partyzanski Rayon', value: 'Partyzanski Rayon' },
  { label: 'Partyzany', value: 'Partyzany' },
  { label: 'Pārūn', value: 'Pārūn' },
  { label: 'Parung', value: 'Parung' },
  { label: 'Parutyne', value: 'Parutyne' },
  { label: 'Paruzzaro', value: 'Paruzzaro' },
  { label: 'Parva', value: 'Parva' },
  { label: 'Parvaraq', value: 'Parvaraq' },
  { label: 'Parvatipuram', value: 'Parvatipuram' },
  { label: 'Parvatsar', value: 'Parvatsar' },
  { label: 'Parvomay', value: 'Parvomay' },
  { label: 'Parvomaytsi', value: 'Parvomaytsi' },
  { label: 'Parwanoo', value: 'Parwanoo' },
  { label: 'Parychy', value: 'Parychy' },
  { label: 'Parys', value: 'Parys' },
  { label: 'Parysów', value: 'Parysów' },
  { label: 'Parzanica', value: 'Parzanica' },
  { label: 'Parzęczew', value: 'Parzęczew' },
  { label: 'Pas De La Casa', value: 'Pas De La Casa' },
  { label: 'Pasacao', value: 'Pasacao' },
  { label: 'Pasaco', value: 'Pasaco' },
  { label: 'Pasadena', value: 'Pasadena' },
  { label: 'Pasadena', value: 'Pasadena' },
  { label: 'Pasadena', value: 'Pasadena' },
  { label: 'Pasadena', value: 'Pasadena' },
  { label: 'Pasadena', value: 'Pasadena' },
  { label: 'Pasadena Hills', value: 'Pasadena Hills' },
  { label: 'Pasaia', value: 'Pasaia' },
  { label: 'Pasaje', value: 'Pasaje' },
  { label: 'Pasaje', value: 'Pasaje' },
  { label: 'Pasan', value: 'Pasan' },
  { label: 'Pasaquina', value: 'Pasaquina' },
  { label: 'Păsăreni', value: 'Păsăreni' },
  { label: 'Pasargad', value: 'Pasargad' },
  { label: 'Pasarkemis', value: 'Pasarkemis' },
  { label: 'Pasarón De La Vera', value: 'Pasarón De La Vera' },
  { label: 'Pasatiempo', value: 'Pasatiempo' },
  { label: 'Pasay', value: 'Pasay' },
  { label: 'Pasay', value: 'Pasay' },
  { label: 'Pasbage Korale', value: 'Pasbage Korale' },
  { label: 'Pasca', value: 'Pasca' },
  { label: 'Pascagoula', value: 'Pascagoula' },
  { label: 'Pascala Del Oro', value: 'Pascala Del Oro' },
  { label: 'Pascanas', value: 'Pascanas' },
  { label: 'Paşcani', value: 'Paşcani' },
  { label: 'Pascarola', value: 'Pascarola' },
  { label: 'Paschim Medinipur District', value: 'Paschim Medinipur District' },
  { label: 'Pasching', value: 'Pasching' },
  { label: 'Pasco', value: 'Pasco' },
  { label: 'Pasco', value: 'Pasco' },
  { label: 'Pasco County', value: 'Pasco County' },
  { label: 'Pascoag', value: 'Pascoag' },
  { label: 'Pascoe Vale', value: 'Pascoe Vale' },
  { label: 'Pascoe Vale South', value: 'Pascoe Vale South' },
  { label: 'Pascualcobo', value: 'Pascualcobo' },
  { label: 'Pas-De-Calais', value: 'Pas-De-Calais' },
  { label: 'Pasegovo', value: 'Pasegovo' },
  { label: 'Paseh', value: 'Paseh' },
  { label: 'Paseka', value: 'Paseka' },
  { label: 'Paseo De Las Cañadas', value: 'Paseo De Las Cañadas' },
  { label: 'Paseo De San Javier', value: 'Paseo De San Javier' },
  { label: 'Paseo Del Prado', value: 'Paseo Del Prado' },
  { label: 'Paseo Puente Viejo', value: 'Paseo Puente Viejo' },
  { label: 'Paseos De La Pradera', value: 'Paseos De La Pradera' },
  { label: 'Paseos De La Providencia [Fraccionamiento]', value: 'Paseos De La Providencia [Fraccionamiento]' },
  { label: 'Paseos De Las Haciendas [Fraccionamiento]', value: 'Paseos De Las Haciendas [Fraccionamiento]' },
  { label: 'Paseos De San Juan', value: 'Paseos De San Juan' },
  { label: 'Paseos Del Marqués', value: 'Paseos Del Marqués' },
  { label: 'Paseos Del Pedregal', value: 'Paseos Del Pedregal' },
  { label: 'Paseos Del Valle [Fraccionamiento]', value: 'Paseos Del Valle [Fraccionamiento]' },
  { label: 'Pasewalk', value: 'Pasewalk' },
  { label: 'Pasha', value: 'Pasha' },
  { label: 'Pashchim Champaran', value: 'Pashchim Champaran' },
  { label: 'Pashchim Singhbhum', value: 'Pashchim Singhbhum' },
  { label: 'Pashiya', value: 'Pashiya' },
  { label: 'Pashkovskiy', value: 'Pashkovskiy' },
  { label: 'Pasian', value: 'Pasian' },
  { label: 'Pasian', value: 'Pasian' },
  { label: 'Pasian', value: 'Pasian' },
  { label: 'Pasian Di Prato', value: 'Pasian Di Prato' },
  { label: 'Pasiano', value: 'Pasiano' },
  { label: 'Pasibi West', value: 'Pasibi West' },
  { label: 'Pasibi West', value: 'Pasibi West' },
  { label: 'Pasichna', value: 'Pasichna' },
  { label: 'Pasig', value: 'Pasig' },
  { label: 'Pásiga', value: 'Pásiga' },
  { label: 'Pasighat', value: 'Pasighat' },
  { label: 'Pasil', value: 'Pasil' },
  { label: 'Pasil', value: 'Pasil' },
  { label: 'Pasil', value: 'Pasil' },
  { label: 'Pašilaičiai', value: 'Pašilaičiai' },
  { label: 'Pasing', value: 'Pasing' },
  { label: 'Pasinler', value: 'Pasinler' },
  { label: 'Pásion', value: 'Pásion' },
  { label: 'Pasir Mas', value: 'Pasir Mas' },
  { label: 'Pasir Ris', value: 'Pasir Ris' },
  { label: 'Påskallavik', value: 'Påskallavik' },
  { label: 'Paskov', value: 'Paskov' },
  { label: 'Pasłęk', value: 'Pasłęk' },
  { label: 'Paslières', value: 'Paslières' },
  { label: 'Pasly', value: 'Pasly' },
  { label: 'Pasni', value: 'Pasni' },
  { label: 'Paso Blanco', value: 'Paso Blanco' },
  { label: 'Paso Blanco', value: 'Paso Blanco' },
  { label: 'Paso Bonito', value: 'Paso Bonito' },
  { label: 'Paso Canoa', value: 'Paso Canoa' },
  { label: 'Paso Canoas Arriba', value: 'Paso Canoas Arriba' },
  { label: 'Paso Carretas', value: 'Paso Carretas' },
  { label: 'Paso De Arena', value: 'Paso De Arena' },
  { label: 'Paso De Carrasco', value: 'Paso De Carrasco' },
  { label: 'Paso De Cuarenta', value: 'Paso De Cuarenta' },
  { label: 'Paso De Hidalgo (Paso De Álamos)', value: 'Paso De Hidalgo (Paso De Álamos)' },
  { label: 'Paso De La Arena', value: 'Paso De La Arena' },
  { label: 'Paso De La Patria', value: 'Paso De La Patria' },
  { label: 'Paso De Los Libres', value: 'Paso De Los Libres' },
  { label: 'Paso De Los Toros', value: 'Paso De Los Toros' },
  { label: 'Paso De Mata', value: 'Paso De Mata' },
  { label: 'Paso De Méndez', value: 'Paso De Méndez' },
  { label: 'Paso De Núñez (Buenavista)', value: 'Paso De Núñez (Buenavista)' },
  { label: 'Paso De Ovejas', value: 'Paso De Ovejas' },
  { label: 'Paso De Pirules', value: 'Paso De Pirules' },
  { label: 'Paso De Valencia', value: 'Paso De Valencia' },
  { label: 'Paso Del Correo', value: 'Paso Del Correo' },
  { label: 'Paso Del Macho', value: 'Paso Del Macho' },
  { label: 'Paso Del Toro', value: 'Paso Del Toro' },
  { label: 'Paso El', value: 'Paso El' },
  { label: 'Paso Hondo', value: 'Paso Hondo' },
  { label: 'Paso Molino', value: 'Paso Molino' },
  { label: 'Paso Morelos', value: 'Paso Morelos' },
  { label: 'Paso Nacional', value: 'Paso Nacional' },
  { label: 'Paso Nacional', value: 'Paso Nacional' },
  { label: 'Paso Nacional', value: 'Paso Nacional' },
  { label: 'Paso Real De Sarabia', value: 'Paso Real De Sarabia' },
  { label: 'Paso Robles', value: 'Paso Robles' },
  { label: 'Pasong Kawayan Primero', value: 'Pasong Kawayan Primero' },
  { label: 'Paspardo', value: 'Paspardo' },
  { label: 'Pasquotank County', value: 'Pasquotank County' },
  { label: 'Pasrur', value: 'Pasrur' },
  { label: 'Pass Christian', value: 'Pass Christian' },
  { label: 'Passa E Fica', value: 'Passa E Fica' },
  { label: 'Passa Quatro', value: 'Passa Quatro' },
  { label: 'Passa Sete', value: 'Passa Sete' },
  { label: 'Passa Tempo', value: 'Passa Tempo' },
  { label: 'Passabém', value: 'Passabém' },
  { label: 'Passage Fort', value: 'Passage Fort' },
  { label: 'Passage West', value: 'Passage West' },
  { label: 'Passagem', value: 'Passagem' },
  { label: 'Passagem', value: 'Passagem' },
  { label: 'Passagem Franca', value: 'Passagem Franca' },
  { label: 'Passagem Franca Do Piauí', value: 'Passagem Franca Do Piauí' },
  { label: 'Passaggio', value: 'Passaggio' },
  { label: 'Passaic', value: 'Passaic' },
  { label: 'Passaic County', value: 'Passaic County' },
  { label: 'Passail', value: 'Passail' },
  { label: 'Passanant I Belltall', value: 'Passanant I Belltall' },
  { label: 'Passapatanzy', value: 'Passapatanzy' },
  { label: 'Passarella', value: 'Passarella' },
  { label: 'Passart', value: 'Passart' },
  { label: 'Passau', value: 'Passau' },
  { label: 'Passa-Vinte', value: 'Passa-Vinte' },
  { label: 'Passerano Marmorito', value: 'Passerano Marmorito' },
  { label: 'Passi', value: 'Passi' },
  { label: 'Passi', value: 'Passi' },
  { label: 'Passignano Sul Trasimeno', value: 'Passignano Sul Trasimeno' },
  { label: 'Passira', value: 'Passira' },
  { label: 'Passirana', value: 'Passirana' },
  { label: 'Passirano', value: 'Passirano' },
  { label: 'Passmore Town', value: 'Passmore Town' },
  { label: 'Passo De Camaragibe', value: 'Passo De Camaragibe' },
  { label: 'Passo De Torres', value: 'Passo De Torres' },
  { label: 'Passo Di Mirabella-Pianopantano', value: 'Passo Di Mirabella-Pianopantano' },
  { label: 'Passo Di Treia', value: 'Passo Di Treia' },
  { label: 'Passo Do Sobrado', value: 'Passo Do Sobrado' },
  { label: 'Passo Fundo', value: 'Passo Fundo' },
  { label: 'Passo Ripe', value: 'Passo Ripe' },
  { label: 'Passons', value: 'Passons' },
  { label: 'Passos', value: 'Passos' },
  { label: 'Passos Maia', value: 'Passos Maia' },
  { label: 'Passoscuro', value: 'Passoscuro' },
  { label: 'Passy', value: 'Passy' },
  { label: 'Pasta', value: 'Pasta' },
  { label: 'Pastavy', value: 'Pastavy' },
  { label: 'Pastawski Rayon', value: 'Pastawski Rayon' },
  { label: 'Pasté', value: 'Pasté' },
  { label: 'Pastelera', value: 'Pastelera' },
  { label: 'Pastena', value: 'Pastena' },
  { label: 'Pasteria-Lapide', value: 'Pasteria-Lapide' },
  { label: 'Pastetten', value: 'Pastetten' },
  { label: 'Pasto', value: 'Pasto' },
  { label: 'Pastor Ortíz', value: 'Pastor Ortíz' },
  { label: 'Pastor Rovaix', value: 'Pastor Rovaix' },
  { label: 'Pastora', value: 'Pastora' },
  { label: 'Pastorano', value: 'Pastorano' },
  { label: 'Pastores', value: 'Pastores' },
  { label: 'Pastores', value: 'Pastores' },
  { label: 'Pastoría', value: 'Pastoría' },
  { label: 'Pastoriza A', value: 'Pastoriza A' },
  { label: 'Pastos', value: 'Pastos' },
  { label: 'Pastos Bons', value: 'Pastos Bons' },
  { label: 'Pastrana', value: 'Pastrana' },
  { label: 'Pastrana', value: 'Pastrana' },
  { label: 'Pastrana', value: 'Pastrana' },
  { label: 'Păstrăveni', value: 'Păstrăveni' },
  { label: 'Pastrengo', value: 'Pastrengo' },
  { label: 'Pastriz', value: 'Pastriz' },
  { label: 'Pasturana', value: 'Pasturana' },
  { label: 'Pasturo', value: 'Pasturo' },
  { label: 'Pasuquin', value: 'Pasuquin' },
  { label: 'Pasuquin', value: 'Pasuquin' },
  { label: 'Pasuruan', value: 'Pasuruan' },
  { label: 'Pasuta', value: 'Pasuta' },
  { label: 'Pasvalys', value: 'Pasvalys' },
  { label: 'Pasym', value: 'Pasym' },
  { label: 'Paszowice', value: 'Paszowice' },
  { label: 'Pásztó', value: 'Pásztó' },
  { label: 'Pásztói Járás', value: 'Pásztói Járás' },
  { label: 'Pata', value: 'Pata' },
  { label: 'Pata', value: 'Pata' },
  { label: 'Pata', value: 'Pata' },
  { label: 'Patabog', value: 'Patabog' },
  { label: 'Patacamaya', value: 'Patacamaya' },
  { label: 'Pataias', value: 'Pataias' },
  { label: 'Patambó', value: 'Patambó' },
  { label: 'Patamda', value: 'Patamda' },
  { label: 'Patamundai', value: 'Patamundai' },
  { label: 'Patan', value: 'Patan' },
  { label: 'Patan', value: 'Patan' },
  { label: 'Patan', value: 'Patan' },
  { label: 'Patan', value: 'Patan' },
  { label: 'Patancheru', value: 'Patancheru' },
  { label: 'Patani', value: 'Patani' },
  { label: 'Patao', value: 'Patao' },
  { label: 'Pătârlagele', value: 'Pătârlagele' },
  { label: 'Patarrá', value: 'Patarrá' },
  { label: 'Pataskala', value: 'Pataskala' },
  { label: 'Pataudi', value: 'Pataudi' },
  { label: 'Pataveh', value: 'Pataveh' },
  { label: 'Patawag', value: 'Patawag' },
  { label: 'Patay', value: 'Patay' },
  { label: 'Patayac', value: 'Patayac' },
  { label: 'Patayac', value: 'Patayac' },
  { label: 'Patchogue', value: 'Patchogue' },
  { label: 'Patea', value: 'Patea' },
  { label: 'Pateh Sam', value: 'Pateh Sam' },
  { label: 'Pateley Bridge', value: 'Pateley Bridge' },
  { label: 'Paterna', value: 'Paterna' },
  { label: 'Paterna De Rivera', value: 'Paterna De Rivera' },
  { label: 'Paterna Del Campo', value: 'Paterna Del Campo' },
  { label: 'Paterna Del Madera', value: 'Paterna Del Madera' },
  { label: 'Paterna Del Río', value: 'Paterna Del Río' },
  { label: 'Paternion', value: 'Paternion' },
  { label: 'Paterno', value: 'Paterno' },
  { label: 'Paternò', value: 'Paternò' },
  { label: 'Paterno Calabro', value: 'Paterno Calabro' },
  { label: 'Paternopoli', value: 'Paternopoli' },
  { label: 'Pateros', value: 'Pateros' },
  { label: 'Patersdorf', value: 'Patersdorf' },
  { label: 'Paterson', value: 'Paterson' },
  { label: 'Paterswolde', value: 'Paterswolde' },
  { label: 'Pathadumbara', value: 'Pathadumbara' },
  { label: 'Pathahewaheta', value: 'Pathahewaheta' },
  { label: 'Pathakpura', value: 'Pathakpura' },
  { label: 'Pathalgaon', value: 'Pathalgaon' },
  { label: 'Pathanamthitta', value: 'Pathanamthitta' },
  { label: 'Pathankot', value: 'Pathankot' },
  { label: 'Pathardi', value: 'Pathardi' },
  { label: 'Pathardih', value: 'Pathardih' },
  { label: 'Patharia', value: 'Patharia' },
  { label: 'Pathé', value: 'Pathé' },
  { label: 'Pathein', value: 'Pathein' },
  { label: 'Pathmos', value: 'Pathmos' },
  { label: 'Pathri', value: 'Pathri' },
  { label: 'Pathum Thani', value: 'Pathum Thani' },
  { label: 'Pati', value: 'Pati' },
  { label: 'Patía', value: 'Patía' },
  { label: 'Patiala', value: 'Patiala' },
  { label: 'Patiali', value: 'Patiali' },
  { label: 'Patience', value: 'Patience' },
  { label: 'Patigi', value: 'Patigi' },
  { label: 'Patigno', value: 'Patigno' },
  { label: 'Patikul', value: 'Patikul' },
  { label: 'Patikul', value: 'Patikul' },
  { label: 'Patillas', value: 'Patillas' },
  { label: 'Patillas', value: 'Patillas' },
  { label: 'Patillas Municipio', value: 'Patillas Municipio' },
  { label: 'Patin-Ay', value: 'Patin-Ay' },
  { label: 'Patin-Ay', value: 'Patin-Ay' },
  { label: 'Patindeguen', value: 'Patindeguen' },
  { label: 'Patindeguen', value: 'Patindeguen' },
  { label: 'Patique', value: 'Patique' },
  { label: 'Patiram', value: 'Patiram' },
  { label: 'Patis', value: 'Patis' },
  { label: 'Patitírion', value: 'Patitírion' },
  { label: 'Pativilca', value: 'Pativilca' },
  { label: 'Patla', value: 'Patla' },
  { label: 'Patlicha', value: 'Patlicha' },
  { label: 'Pátmos', value: 'Pátmos' },
  { label: 'Patna', value: 'Patna' },
  { label: 'Patna', value: 'Patna' },
  { label: 'Patnagarh', value: 'Patnagarh' },
  { label: 'Patnanungan', value: 'Patnanungan' },
  { label: 'Patnongon', value: 'Patnongon' },
  { label: 'Patnos', value: 'Patnos' },
  { label: 'Pątnów', value: 'Pątnów' },
  { label: 'Pato Bragado', value: 'Pato Bragado' },
  { label: 'Pato Branco', value: 'Pato Branco' },
  { label: 'Patonan', value: 'Patonan' },
  { label: 'Patones', value: 'Patones' },
  { label: 'Patong', value: 'Patong' },
  { label: 'Patong', value: 'Patong' },
  { label: 'Patong', value: 'Patong' },
  { label: 'Pato-O', value: 'Pato-O' },
  { label: 'Patos', value: 'Patos' },
  { label: 'Patos', value: 'Patos' },
  { label: 'Patos De Minas', value: 'Patos De Minas' },
  { label: 'Patos Do Piauí', value: 'Patos Do Piauí' },
  { label: 'Patos Fshat', value: 'Patos Fshat' },
  { label: 'Patosil', value: 'Patosil' },
  { label: 'Patpata Segundo', value: 'Patpata Segundo' },
  { label: 'Patpata Segundo', value: 'Patpata Segundo' },
  { label: 'Pátra', value: 'Pátra' },
  { label: 'Patrasaer', value: 'Patrasaer' },
  { label: 'Pătrăuţi', value: 'Pătrăuţi' },
  { label: 'Patria', value: 'Patria' },
  { label: 'Patria Nueva', value: 'Patria Nueva' },
  { label: 'Patria Nueva', value: 'Patria Nueva' },
  { label: 'Patria Nueva (San José El Contento)', value: 'Patria Nueva (San José El Contento)' },
  { label: 'Patrica', value: 'Patrica' },
  { label: 'Patrick City', value: 'Patrick City' },
  { label: 'Patrick County', value: 'Patrick County' },
  { label: 'Patrick Springs', value: 'Patrick Springs' },
  { label: 'Patrída', value: 'Patrída' },
  { label: 'Patrington', value: 'Patrington' },
  { label: 'Patrocinio', value: 'Patrocinio' },
  { label: 'Patrocínio', value: 'Patrocínio' },
  { label: 'Patrocínio Do Muriaé', value: 'Patrocínio Do Muriaé' },
  { label: 'Patrocínio Paulista', value: 'Patrocínio Paulista' },
  { label: 'Pátroha', value: 'Pátroha' },
  { label: 'Patru Frați', value: 'Patru Frați' },
  { label: 'Patsch', value: 'Patsch' },
  { label: 'Patsi', value: 'Patsi' },
  { label: 'Pattada', value: 'Pattada' },
  { label: 'Pattan', value: 'Pattan' },
  { label: 'Pattanamtitta', value: 'Pattanamtitta' },
  { label: 'Pattani', value: 'Pattani' },
  { label: 'Pattao', value: 'Pattao' },
  { label: 'Pattao', value: 'Pattao' },
  { label: 'Pattaya', value: 'Pattaya' },
  { label: 'Patten', value: 'Patten' },
  { label: 'Pattensen', value: 'Pattensen' },
  { label: 'Patterson', value: 'Patterson' },
  { label: 'Patterson', value: 'Patterson' },
  { label: 'Patterson Lakes', value: 'Patterson Lakes' },
  { label: 'Patterson Tract', value: 'Patterson Tract' },
  { label: 'Pattersons Gap', value: 'Pattersons Gap' },
  { label: 'Patti', value: 'Patti' },
  { label: 'Patti', value: 'Patti' },
  { label: 'Patti', value: 'Patti' },
  { label: 'Pattigham', value: 'Pattigham' },
  { label: 'Pattingham', value: 'Pattingham' },
  { label: 'Pattoki', value: 'Pattoki' },
  { label: 'Patton', value: 'Patton' },
  { label: 'Patton Village', value: 'Patton Village' },
  { label: 'Pattukkottai', value: 'Pattukkottai' },
  { label: 'Patu', value: 'Patu' },
  { label: 'Patù', value: 'Patù' },
  { label: 'Patuakhali ', value: 'Patuakhali ' },
  { label: 'Patuán', value: 'Patuán' },
  { label: 'Patuca', value: 'Patuca' },
  { label: 'Pătulele', value: 'Pătulele' },
  { label: 'Pătuleni', value: 'Pătuleni' },
  { label: 'Patuli', value: 'Patuli' },
  { label: 'Patulul', value: 'Patulul' },
  { label: 'Patur', value: 'Patur' },
  { label: 'Paturda', value: 'Paturda' },
  { label: 'Patuto', value: 'Patuto' },
  { label: 'Páty', value: 'Páty' },
  { label: 'Paty Do Alferes', value: 'Paty Do Alferes' },
  { label: 'Pátzcuaro', value: 'Pátzcuaro' },
  { label: 'Patzicía', value: 'Patzicía' },
  { label: 'Patzité', value: 'Patzité' },
  { label: 'Patzún', value: 'Patzún' },
  { label: 'Pau', value: 'Pau' },
  { label: 'Pau', value: 'Pau' },
  { label: 'Pau', value: 'Pau' },
  { label: 'Pau', value: 'Pau' },
  { label: 'Pau', value: 'Pau' },
  { label: 'Pau Brasil', value: 'Pau Brasil' },
  { label: 'Pau Darco', value: 'Pau Darco' },
  { label: 'Pau Darco', value: 'Pau Darco' },
  { label: 'Pau Darco Do Piauí', value: 'Pau Darco Do Piauí' },
  { label: 'Pau Dos Ferros', value: 'Pau Dos Ferros' },
  { label: 'Păuca', value: 'Păuca' },
  { label: 'Paucar Del Sara Sara', value: 'Paucar Del Sara Sara' },
  { label: 'Paucartambo', value: 'Paucartambo' },
  { label: 'Paucartambo', value: 'Paucartambo' },
  { label: 'Păucișoara', value: 'Păucișoara' },
  { label: 'Paud', value: 'Paud' },
  { label: 'Paudalho', value: 'Paudalho' },
  { label: 'Paudex', value: 'Paudex' },
  { label: 'Paudorf', value: 'Paudorf' },
  { label: 'Pauillac', value: 'Pauillac' },
  { label: 'Pauini', value: 'Pauini' },
  { label: 'Paujiles', value: 'Paujiles' },
  { label: 'Paul', value: 'Paul' },
  { label: 'Paul Island', value: 'Paul Island' },
  { label: 'Paula Cândido', value: 'Paula Cândido' },
  { label: 'Paula Freitas', value: 'Paula Freitas' },
  { label: 'Paularo', value: 'Paularo' },
  { label: 'Paulba', value: 'Paulba' },
  { label: 'Paulden', value: 'Paulden' },
  { label: 'Paulding', value: 'Paulding' },
  { label: 'Paulding County', value: 'Paulding County' },
  { label: 'Paulding County', value: 'Paulding County' },
  { label: 'Păuleni-Ciuc', value: 'Păuleni-Ciuc' },
  { label: 'Păuleşti', value: 'Păuleşti' },
  { label: 'Păuleşti', value: 'Păuleşti' },
  { label: 'Păulești', value: 'Păulești' },
  { label: 'Paulhac', value: 'Paulhac' },
  { label: 'Paulhaguet', value: 'Paulhaguet' },
  { label: 'Paulhan', value: 'Paulhan' },
  { label: 'Pauli Arbarei', value: 'Pauli Arbarei' },
  { label: 'Paulicéia', value: 'Paulicéia' },
  { label: 'Paulilatino', value: 'Paulilatino' },
  { label: 'Paulina', value: 'Paulina' },
  { label: 'Paulinenaue', value: 'Paulinenaue' },
  { label: 'Paulínia', value: 'Paulínia' },
  { label: 'Paulino Neves', value: 'Paulino Neves' },
  { label: 'Păuliş', value: 'Păuliş' },
  { label: 'Paulista', value: 'Paulista' },
  { label: 'Paulista', value: 'Paulista' },
  { label: 'Paulista Flórida', value: 'Paulista Flórida' },
  { label: 'Paulistana', value: 'Paulistana' },
  { label: 'Paulistânia', value: 'Paulistânia' },
  { label: 'Paulistas', value: 'Paulistas' },
  { label: 'Paullina', value: 'Paullina' },
  { label: 'Paullo', value: 'Paullo' },
  { label: 'Paulo Afonso', value: 'Paulo Afonso' },
  { label: 'Paulo Bento', value: 'Paulo Bento' },
  { label: 'Paulo De Faria', value: 'Paulo De Faria' },
  { label: 'Paulo Frontin', value: 'Paulo Frontin' },
  { label: 'Paulo Jacinto', value: 'Paulo Jacinto' },
  { label: 'Paulo Lopes', value: 'Paulo Lopes' },
  { label: 'Paulo Ramos', value: 'Paulo Ramos' },
  { label: 'Păuloaia', value: 'Păuloaia' },
  { label: 'Paüls', value: 'Paüls' },
  { label: 'Pauls Valley', value: 'Pauls Valley' },
  { label: 'Paulsboro', value: 'Paulsboro' },
  { label: 'Paulsstadt', value: 'Paulsstadt' },
  { label: 'Paulton', value: 'Paulton' },
  { label: 'Paulx', value: 'Paulx' },
  { label: 'Pauna', value: 'Pauna' },
  { label: 'Păuneşti', value: 'Păuneşti' },
  { label: 'Paungde', value: 'Paungde' },
  { label: 'Pauni', value: 'Pauni' },
  { label: 'Paunzhausen', value: 'Paunzhausen' },
  { label: 'Paupisi', value: 'Paupisi' },
  { label: 'Pauri', value: 'Pauri' },
  { label: 'Paurito', value: 'Paurito' },
  { label: 'Pausa', value: 'Pausa' },
  { label: 'Păuşeşti', value: 'Păuşeşti' },
  { label: 'Păușești', value: 'Păușești' },
  { label: 'Păuşeşti-Măglaşi', value: 'Păuşeşti-Măglaşi' },
  { label: 'Pavão', value: 'Pavão' },
  { label: 'Pavarolo', value: 'Pavarolo' },
  { label: 'Pavee', value: 'Pavee' },
  { label: 'Paveh', value: 'Paveh' },
  { label: 'Pavel Banya', value: 'Pavel Banya' },
  { label: 'Pavelets', value: 'Pavelets' },
  { label: 'Pavencul', value: 'Pavencul' },
  { label: 'Paverama', value: 'Paverama' },
  { label: 'Pavi Jetpur', value: 'Pavi Jetpur' },
  { label: 'Pavia', value: 'Pavia' },
  { label: 'Pavia', value: 'Pavia' },
  { label: 'Pavia Di Udine', value: 'Pavia Di Udine' },
  { label: 'Pavías', value: 'Pavías' },
  { label: 'Pavie', value: 'Pavie' },
  { label: 'Pavigliana', value: 'Pavigliana' },
  { label: 'Pavilly', value: 'Pavilly' },
  { label: 'Pāvilosta', value: 'Pāvilosta' },
  { label: 'Pavino', value: 'Pavino' },
  { label: 'Pavlikeni', value: 'Pavlikeni' },
  { label: 'Pavlíkov', value: 'Pavlíkov' },
  { label: 'Pavliš', value: 'Pavliš' },
  { label: 'Pavlivka', value: 'Pavlivka' },
  { label: 'Pavlivka', value: 'Pavlivka' },
  { label: 'Pavlodar', value: 'Pavlodar' },
  { label: 'Pavlodol’Skaya', value: 'Pavlodol’Skaya' },
  { label: 'Pavlogradka', value: 'Pavlogradka' },
  { label: 'Pavlohrad', value: 'Pavlohrad' },
  { label: 'Pavlovka', value: 'Pavlovka' },
  { label: 'Pavlovka', value: 'Pavlovka' },
  { label: 'Pavlovo', value: 'Pavlovo' },
  { label: 'Pavlovo', value: 'Pavlovo' },
  { label: 'Pavlovsk', value: 'Pavlovsk' },
  { label: 'Pavlovsk', value: 'Pavlovsk' },
  { label: 'Pavlovsk', value: 'Pavlovsk' },
  { label: 'Pavlovskaya', value: 'Pavlovskaya' },
  { label: 'Pavlovskaya Sloboda', value: 'Pavlovskaya Sloboda' },
  { label: 'Pavlovskiy', value: 'Pavlovskiy' },
  { label: 'Pavlovskiy Posad', value: 'Pavlovskiy Posad' },
  { label: 'Pavlovskiy Rayon', value: 'Pavlovskiy Rayon' },
  { label: 'Pavlovskiy Rayon', value: 'Pavlovskiy Rayon' },
  { label: 'Pavlovskiy Rayon', value: 'Pavlovskiy Rayon' },
  { label: 'Pavlovskiy Rayon', value: 'Pavlovskiy Rayon' },
  { label: 'Pavlovskiy Rayon', value: 'Pavlovskiy Rayon' },
  { label: 'Pavlysh', value: 'Pavlysh' },
  { label: 'Pavoloch', value: 'Pavoloch' },
  { label: 'Pavona', value: 'Pavona' },
  { label: 'Pavone Canavese', value: 'Pavone Canavese' },
  { label: 'Pavone Del Mella', value: 'Pavone Del Mella' },
  { label: 'Pavshino', value: 'Pavshino' },
  { label: 'Pavugada', value: 'Pavugada' },
  { label: 'Pavullo Nel Frignano', value: 'Pavullo Nel Frignano' },
  { label: 'Pavuluru', value: 'Pavuluru' },
  { label: 'Pavussu', value: 'Pavussu' },
  { label: 'Paw Paw', value: 'Paw Paw' },
  { label: 'Paw Paw Lake', value: 'Paw Paw Lake' },
  { label: 'Pawa', value: 'Pawa' },
  { label: 'Pawa', value: 'Pawa' },
  { label: 'Pawai', value: 'Pawai' },
  { label: 'Pawak', value: 'Pawak' },
  { label: 'Pawak', value: 'Pawak' },
  { label: 'Pawayan', value: 'Pawayan' },
  { label: 'Pawcatuck', value: 'Pawcatuck' },
  { label: 'Pawhuska', value: 'Pawhuska' },
  { label: 'Pawican', value: 'Pawican' },
  { label: 'Pawili', value: 'Pawili' },
  { label: 'Pawing', value: 'Pawing' },
  { label: 'Pawing', value: 'Pawing' },
  { label: 'Pawlet', value: 'Pawlet' },
  { label: 'Pawlikowice', value: 'Pawlikowice' },
  { label: 'Pawling', value: 'Pawling' },
  { label: 'Pawłosiów', value: 'Pawłosiów' },
  { label: 'Pawłów', value: 'Pawłów' },
  { label: 'Pawłowice', value: 'Pawłowice' },
  { label: 'Pawłowice-Kłokoczyce', value: 'Pawłowice-Kłokoczyce' },
  { label: 'Pawłowiczki', value: 'Pawłowiczki' },
  { label: 'Pawnee', value: 'Pawnee' },
  { label: 'Pawnee', value: 'Pawnee' },
  { label: 'Pawnee City', value: 'Pawnee City' },
  { label: 'Pawnee County', value: 'Pawnee County' },
  { label: 'Pawnee County', value: 'Pawnee County' },
  { label: 'Pawnee County', value: 'Pawnee County' },
  { label: 'Pawni', value: 'Pawni' },
  { label: 'Pawonków', value: 'Pawonków' },
  { label: 'Pawtucket', value: 'Pawtucket' },
  { label: 'Paxtakor', value: 'Paxtakor' },
  { label: 'Paxtang', value: 'Paxtang' },
  { label: 'Paxtaobod', value: 'Paxtaobod' },
  { label: 'Paxtepec', value: 'Paxtepec' },
  { label: 'Paxtle', value: 'Paxtle' },
  { label: 'Paxton', value: 'Paxton' },
  { label: 'Paxton', value: 'Paxton' },
  { label: 'Paxtonia', value: 'Paxtonia' },
  { label: 'Paya', value: 'Paya' },
  { label: 'Paya', value: 'Paya' },
  { label: 'Payabon', value: 'Payabon' },
  { label: 'Payagpur', value: 'Payagpur' },
  { label: 'Payakumbuh', value: 'Payakumbuh' },
  { label: 'Payandeh', value: 'Payandeh' },
  { label: 'Payao', value: 'Payao' },
  { label: 'Payao', value: 'Payao' },
  { label: 'Payapa', value: 'Payapa' },
  { label: 'Payas', value: 'Payas' },
  { label: 'Payerbach', value: 'Payerbach' },
  { label: 'Payerne', value: 'Payerne' },
  { label: 'Payette', value: 'Payette' },
  { label: 'Payette County', value: 'Payette County' },
  { label: 'Paymogo', value: 'Paymogo' },
  { label: 'Payne', value: 'Payne' },
  { label: 'Payne County', value: 'Payne County' },
  { label: 'Payneham', value: 'Payneham' },
  { label: 'Payneham South', value: 'Payneham South' },
  { label: 'Paynesville', value: 'Paynesville' },
  { label: 'Paynesville', value: 'Paynesville' },
  { label: 'Payns', value: 'Payns' },
  { label: 'Payo De Ojeda', value: 'Payo De Ojeda' },
  { label: 'Payo El', value: 'Payo El' },
  { label: 'Payocpoc Sur', value: 'Payocpoc Sur' },
  { label: 'Payocpoc Sur', value: 'Payocpoc Sur' },
  { label: 'Paypay', value: 'Paypay' },
  { label: 'Payrin-Augmontel', value: 'Payrin-Augmontel' },
  { label: 'Paysandú', value: 'Paysandú' },
  { label: 'Payshanba', value: 'Payshanba' },
  { label: 'Payson', value: 'Payson' },
  { label: 'Payson', value: 'Payson' },
  { label: 'Payson', value: 'Payson' },
  { label: 'Payuhan', value: 'Payuhan' },
  { label: 'Payuhan', value: 'Payuhan' },
  { label: 'Payyanur', value: 'Payyanur' },
  { label: 'Payzac', value: 'Payzac' },
  { label: 'Paz De Ariporo', value: 'Paz De Ariporo' },
  { label: 'Paz De Río', value: 'Paz De Río' },
  { label: 'Pazar', value: 'Pazar' },
  { label: 'Pazar', value: 'Pazar' },
  { label: 'Pazarcık', value: 'Pazarcık' },
  { label: 'Pazardzhik', value: 'Pazardzhik' },
  { label: 'Pazarić', value: 'Pazarić' },
  { label: 'Pazarlar', value: 'Pazarlar' },
  { label: 'Pazaryeri', value: 'Pazaryeri' },
  { label: 'Pazaryolu', value: 'Pazaryolu' },
  { label: 'Pazin', value: 'Pazin' },
  { label: 'Pázmánd', value: 'Pázmánd' },
  { label: 'Pazos De Borbén', value: 'Pazos De Borbén' },
  { label: 'Pazuengos', value: 'Pazuengos' },
  { label: 'Pazzano', value: 'Pazzano' },
  { label: 'Pchery', value: 'Pchery' },
  { label: 'Pcim', value: 'Pcim' },
  { label: 'Pčinja', value: 'Pčinja' },
  { label: 'Pé De Serra', value: 'Pé De Serra' },
  { label: 'Pea Ridge', value: 'Pea Ridge' },
  { label: 'Pea Ridge', value: 'Pea Ridge' },
  { label: 'Peabiru', value: 'Peabiru' },
  { label: 'Peabody', value: 'Peabody' },
  { label: 'Peabody', value: 'Peabody' },
  { label: 'Peace River', value: 'Peace River' },
  { label: 'Peace River Regional District', value: 'Peace River Regional District' },
  { label: 'Peaceful Valley', value: 'Peaceful Valley' },
  { label: 'Peacehaven', value: 'Peacehaven' },
  { label: 'Peach County', value: 'Peach County' },
  { label: 'Peach Lake', value: 'Peach Lake' },
  { label: 'Peach Springs', value: 'Peach Springs' },
  { label: 'Peachester', value: 'Peachester' },
  { label: 'Peachland', value: 'Peachland' },
  { label: 'Peachtree City', value: 'Peachtree City' },
  { label: 'Peachtree Corners', value: 'Peachtree Corners' },
  { label: 'Peak Hill', value: 'Peak Hill' },
  { label: 'Peakhurst', value: 'Peakhurst' },
  { label: 'Peakhurst Heights', value: 'Peakhurst Heights' },
  { label: 'Peal De Becerro', value: 'Peal De Becerro' },
  { label: 'Peapack', value: 'Peapack' },
  { label: 'Pear Tree', value: 'Pear Tree' },
  { label: 'Pear Tree Grove', value: 'Pear Tree Grove' },
  { label: 'Pearce', value: 'Pearce' },
  { label: 'Pearce', value: 'Pearce' },
  { label: 'Pearcedale', value: 'Pearcedale' },
  { label: 'Pearisburg', value: 'Pearisburg' },
  { label: 'Pearl', value: 'Pearl' },
  { label: 'Pearl Beach', value: 'Pearl Beach' },
  { label: 'Pearl City', value: 'Pearl City' },
  { label: 'Pearl River', value: 'Pearl River' },
  { label: 'Pearl River', value: 'Pearl River' },
  { label: 'Pearl River', value: 'Pearl River' },
  { label: 'Pearl River County', value: 'Pearl River County' },
  { label: 'Pearland', value: 'Pearland' },
  { label: 'Pearlington', value: 'Pearlington' },
  { label: 'Pearsall', value: 'Pearsall' },
  { label: 'Pearsall', value: 'Pearsall' },
  { label: 'Pearson', value: 'Pearson' },
  { label: 'Pearts Gap', value: 'Pearts Gap' },
  { label: 'Peasedown Saint John', value: 'Peasedown Saint John' },
  { label: 'Peaslake', value: 'Peaslake' },
  { label: 'Peaugres', value: 'Peaugres' },
  { label: 'Péaule', value: 'Péaule' },
  { label: 'Pebble Creek', value: 'Pebble Creek' },
  { label: 'Pecan Acres', value: 'Pecan Acres' },
  { label: 'Pecan Grove', value: 'Pecan Grove' },
  { label: 'Pecan Plantation', value: 'Pecan Plantation' },
  { label: 'Pecangaan', value: 'Pecangaan' },
  { label: 'Peçanha', value: 'Peçanha' },
  { label: 'Pecatonica', value: 'Pecatonica' },
  { label: 'Peccioli', value: 'Peccioli' },
  { label: 'Pecco', value: 'Pecco' },
  { label: 'Peceiu', value: 'Peceiu' },
  { label: 'Pécel', value: 'Pécel' },
  { label: 'Peceneaga', value: 'Peceneaga' },
  { label: 'Pecetto', value: 'Pecetto' },
  { label: 'Pecetto Di Valenza', value: 'Pecetto Di Valenza' },
  { label: 'Péchabou', value: 'Péchabou' },
  { label: 'Pechbonnieu', value: 'Pechbonnieu' },
  { label: 'Pechbrunn', value: 'Pechbrunn' },
  { label: 'Pechea', value: 'Pechea' },
  { label: 'Pechenga', value: 'Pechenga' },
  { label: 'Pechengskiy Rayon', value: 'Pechengskiy Rayon' },
  { label: 'Pechenizhyn', value: 'Pechenizhyn' },
  { label: 'Pechersk', value: 'Pechersk' },
  { label: 'Pechersk Raion', value: 'Pechersk Raion' },
  { label: 'Pechina', value: 'Pechina' },
  { label: 'Pechora', value: 'Pechora' },
  { label: 'Pechorskiy Rayon', value: 'Pechorskiy Rayon' },
  { label: 'Pechory', value: 'Pechory' },
  { label: 'Pechucalco', value: 'Pechucalco' },
  { label: 'Peći', value: 'Peći' },
  { label: 'Pecica', value: 'Pecica' },
  { label: 'Pećigrad', value: 'Pećigrad' },
  { label: 'Pećinci', value: 'Pećinci' },
  { label: 'Pecineaga', value: 'Pecineaga' },
  { label: 'Peciu Nou', value: 'Peciu Nou' },
  { label: 'Pecka', value: 'Pecka' },
  { label: 'Peckham', value: 'Peckham' },
  { label: 'Peckham', value: 'Peckham' },
  { label: 'Pečky', value: 'Pečky' },
  { label: 'Pecna', value: 'Pecna' },
  { label: 'Pecorara', value: 'Pecorara' },
  { label: 'Pecos', value: 'Pecos' },
  { label: 'Pecos', value: 'Pecos' },
  { label: 'Pecos County', value: 'Pecos County' },
  { label: 'Pecq', value: 'Pecq' },
  { label: 'Pecquencourt', value: 'Pecquencourt' },
  { label: 'Pécs', value: 'Pécs' },
  { label: 'Pécsi Járás', value: 'Pécsi Járás' },
  { label: 'Pécsvárad', value: 'Pécsvárad' },
  { label: 'Pécsváradi Járás', value: 'Pécsváradi Járás' },
  { label: 'Peculiar', value: 'Peculiar' },
  { label: 'Pęczniew', value: 'Pęczniew' },
  { label: 'Pedace-Perito', value: 'Pedace-Perito' },
  { label: 'Pedagaggi', value: 'Pedagaggi' },
  { label: 'Pedalino', value: 'Pedalino' },
  { label: 'Pedana', value: 'Pedana' },
  { label: 'Pedara', value: 'Pedara' },
  { label: 'Pedasí', value: 'Pedasí' },
  { label: 'Pedaso', value: 'Pedaso' },
  { label: 'Pedavena', value: 'Pedavena' },
  { label: 'Pedda Nakkalapalem', value: 'Pedda Nakkalapalem' },
  { label: 'Peddapalli', value: 'Peddapalli' },
  { label: 'Peddapuram', value: 'Peddapuram' },
  { label: 'Pedemonte', value: 'Pedemonte' },
  { label: 'Pedemonte', value: 'Pedemonte' },
  { label: 'Pederiva', value: 'Pederiva' },
  { label: 'Pedernales', value: 'Pedernales' },
  { label: 'Pedernales', value: 'Pedernales' },
  { label: 'Pedernales', value: 'Pedernales' },
  { label: 'Pédernec', value: 'Pédernec' },
  { label: 'Pederneiras', value: 'Pederneiras' },
  { label: 'Pedernoso El', value: 'Pedernoso El' },
  { label: 'Pederobba', value: 'Pederobba' },
  { label: 'Pedersöre', value: 'Pedersöre' },
  { label: 'Pedesina', value: 'Pedesina' },
  { label: 'Pedgaon', value: 'Pedgaon' },
  { label: 'Pediní', value: 'Pediní' },
  { label: 'Pedivigliano', value: 'Pedivigliano' },
  { label: 'Pedley', value: 'Pedley' },
  { label: 'Pedra', value: 'Pedra' },
  { label: 'Pedra Azul', value: 'Pedra Azul' },
  { label: 'Pedra Badejo', value: 'Pedra Badejo' },
  { label: 'Pedra Bela', value: 'Pedra Bela' },
  { label: 'Pedra Bonita', value: 'Pedra Bonita' },
  { label: 'Pedra Branca', value: 'Pedra Branca' },
  { label: 'Pedra Branca', value: 'Pedra Branca' },
  { label: 'Pedra Branca Do Amapari', value: 'Pedra Branca Do Amapari' },
  { label: 'Pedra Do Anta', value: 'Pedra Do Anta' },
  { label: 'Pedra Do Indaiá', value: 'Pedra Do Indaiá' },
  { label: 'Pedra Dourada', value: 'Pedra Dourada' },
  { label: 'Pedra Grande', value: 'Pedra Grande' },
  { label: 'Pedra Lavrada', value: 'Pedra Lavrada' },
  { label: 'Pedra Mole', value: 'Pedra Mole' },
  { label: 'Pedra Preta', value: 'Pedra Preta' },
  { label: 'Pedra Preta', value: 'Pedra Preta' },
  { label: 'Pedrafita Do Cebreiro', value: 'Pedrafita Do Cebreiro' },
  { label: 'Pedraja De Portillo La', value: 'Pedraja De Portillo La' },
  { label: 'Pedrajas De San Esteban', value: 'Pedrajas De San Esteban' },
  { label: 'Pedralba', value: 'Pedralba' },
  { label: 'Pedralba De La Pradería', value: 'Pedralba De La Pradería' },
  { label: 'Pedralva', value: 'Pedralva' },
  { label: 'Pedranópolis', value: 'Pedranópolis' },
  { label: 'Pedrão', value: 'Pedrão' },
  { label: 'Pedras Altas', value: 'Pedras Altas' },
  { label: 'Pedras De Fogo', value: 'Pedras De Fogo' },
  { label: 'Pedras De Maria Da Cruz', value: 'Pedras De Maria Da Cruz' },
  { label: 'Pedras Grandes', value: 'Pedras Grandes' },
  { label: 'Pedraza', value: 'Pedraza' },
  { label: 'Pedraza', value: 'Pedraza' },
  { label: 'Pedraza De Alba', value: 'Pedraza De Alba' },
  { label: 'Pedraza De Campos', value: 'Pedraza De Campos' },
  { label: 'Pedregal', value: 'Pedregal' },
  { label: 'Pedregal', value: 'Pedregal' },
  { label: 'Pedregal De San José', value: 'Pedregal De San José' },
  { label: 'Pedregal El', value: 'Pedregal El' },
  { label: 'Pedregoso', value: 'Pedregoso' },
  { label: 'Pedreguer', value: 'Pedreguer' },
  { label: 'Pedregulho', value: 'Pedregulho' },
  { label: 'Pedreira', value: 'Pedreira' },
  { label: 'Pedreiras', value: 'Pedreiras' },
  { label: 'Pedrengo', value: 'Pedrengo' },
  { label: 'Pedrera', value: 'Pedrera' },
  { label: 'Pedret I Marzà', value: 'Pedret I Marzà' },
  { label: 'Pedrezuela', value: 'Pedrezuela' },
  { label: 'Pedriceña', value: 'Pedriceña' },
  { label: 'Pedrinhas', value: 'Pedrinhas' },
  { label: 'Pedrinhas Paulista', value: 'Pedrinhas Paulista' },
  { label: 'Pedrinópolis', value: 'Pedrinópolis' },
  { label: 'Pedro Abad', value: 'Pedro Abad' },
  { label: 'Pedro Afonso', value: 'Pedro Afonso' },
  { label: 'Pedro Aguirre Cerda', value: 'Pedro Aguirre Cerda' },
  { label: 'Pedro Alexandre', value: 'Pedro Alexandre' },
  { label: 'Pedro Amaro', value: 'Pedro Amaro' },
  { label: 'Pedro Avelino', value: 'Pedro Avelino' },
  { label: 'Pedro Bernardo', value: 'Pedro Bernardo' },
  { label: 'Pedro Betancourt', value: 'Pedro Betancourt' },
  { label: 'Pedro Canário', value: 'Pedro Canário' },
  { label: 'Pedro Carbo', value: 'Pedro Carbo' },
  { label: 'Pedro Corto', value: 'Pedro Corto' },
  { label: 'Pedro De Toledo', value: 'Pedro De Toledo' },
  { label: 'Pedro Do Rosário', value: 'Pedro Do Rosário' },
  { label: 'Pedro Escobedo', value: 'Pedro Escobedo' },
  { label: 'Pedro García', value: 'Pedro García' },
  { label: 'Pedro Gomes', value: 'Pedro Gomes' },
  { label: 'Pedro Ibarra Hernández', value: 'Pedro Ibarra Hernández' },
  { label: 'Pedro Ii', value: 'Pedro Ii' },
  { label: 'Pedro Juan Caballero', value: 'Pedro Juan Caballero' },
  { label: 'Pedro Laurentino', value: 'Pedro Laurentino' },
  { label: 'Pedro Leopoldo', value: 'Pedro Leopoldo' },
  { label: 'Pedro Martínez', value: 'Pedro Martínez' },
  { label: 'Pedro Muñoz', value: 'Pedro Muñoz' },
  { label: 'Pedro Osório', value: 'Pedro Osório' },
  { label: 'Pedro Plains', value: 'Pedro Plains' },
  { label: 'Pedro R. Fernández', value: 'Pedro R. Fernández' },
  { label: 'Pedro Régis', value: 'Pedro Régis' },
  { label: 'Pedro Sánchez', value: 'Pedro Sánchez' },
  { label: 'Pedro Teixeira', value: 'Pedro Teixeira' },
  { label: 'Pedro Velho', value: 'Pedro Velho' },
  { label: 'Pedroche', value: 'Pedroche' },
  { label: 'Pedrógão Grande', value: 'Pedrógão Grande' },
  { label: 'Pedrola', value: 'Pedrola' },
  { label: 'Pedroñeras Las', value: 'Pedroñeras Las' },
  { label: 'Pedro-Rodríguez', value: 'Pedro-Rodríguez' },
  { label: 'Pedrosa De Duero', value: 'Pedrosa De Duero' },
  { label: 'Pedrosa De La Vega', value: 'Pedrosa De La Vega' },
  { label: 'Pedrosa De Río Úrbel', value: 'Pedrosa De Río Úrbel' },
  { label: 'Pedrosa Del Páramo', value: 'Pedrosa Del Páramo' },
  { label: 'Pedrosa Del Príncipe', value: 'Pedrosa Del Príncipe' },
  { label: 'Pedrosa Del Rey', value: 'Pedrosa Del Rey' },
  { label: 'Pedrosas Las', value: 'Pedrosas Las' },
  { label: 'Pedrosillo De Alba', value: 'Pedrosillo De Alba' },
  { label: 'Pedrosillo De Los Aires', value: 'Pedrosillo De Los Aires' },
  { label: 'Pedrosillo El Ralo', value: 'Pedrosillo El Ralo' },
  { label: 'Pedroso', value: 'Pedroso' },
  { label: 'Pedroso', value: 'Pedroso' },
  { label: 'Pedroso De Acim', value: 'Pedroso De Acim' },
  { label: 'Pedroso De La Armuña El', value: 'Pedroso De La Armuña El' },
  { label: 'Pedroso El', value: 'Pedroso El' },
  { label: 'Pedrouços', value: 'Pedrouços' },
  { label: 'Pedtad', value: 'Pedtad' },
  { label: 'Pedtad', value: 'Pedtad' },
  { label: 'Peebles', value: 'Peebles' },
  { label: 'Peebles', value: 'Peebles' },
  { label: 'Peekskill', value: 'Peekskill' },
  { label: 'Peel', value: 'Peel' },
  { label: 'Peelo', value: 'Peelo' },
  { label: 'Peer', value: 'Peer' },
  { label: 'Péfka', value: 'Péfka' },
  { label: 'Péfki', value: 'Péfki' },
  { label: 'Pefkochóri', value: 'Pefkochóri' },
  { label: 'Pega', value: 'Pega' },
  { label: 'Pegalajar', value: 'Pegalajar' },
  { label: 'Pegau', value: 'Pegau' },
  { label: 'Pégeia', value: 'Pégeia' },
  { label: 'Peggau', value: 'Peggau' },
  { label: 'Peglio', value: 'Peglio' },
  { label: 'Peglio', value: 'Peglio' },
  { label: 'Pegnitz', value: 'Pegnitz' },
  { label: 'Pego', value: 'Pego' },
  { label: 'Pego', value: 'Pego' },
  { label: 'Pego El', value: 'Pego El' },
  { label: 'Pegognaga', value: 'Pegognaga' },
  { label: 'Pegolotte', value: 'Pegolotte' },
  { label: 'Pégomas', value: 'Pégomas' },
  { label: 'Pegram', value: 'Pegram' },
  { label: 'Pegs Creek', value: 'Pegs Creek' },
  { label: 'Pegswood', value: 'Pegswood' },
  { label: 'Peguera', value: 'Peguera' },
  { label: 'Peguerinos', value: 'Peguerinos' },
  { label: 'Pegueros', value: 'Pegueros' },
  { label: 'Pehčevo', value: 'Pehčevo' },
  { label: 'Pehlivanköy', value: 'Pehlivanköy' },
  { label: 'Pehowa', value: 'Pehowa' },
  { label: 'Peia', value: 'Peia' },
  { label: 'Peij', value: 'Peij' },
  { label: 'Peillac', value: 'Peillac' },
  { label: 'Peille', value: 'Peille' },
  { label: 'Peillon', value: 'Peillon' },
  { label: 'Peillonnex', value: 'Peillonnex' },
  { label: 'Peine', value: 'Peine' },
  { label: 'Peint', value: 'Peint' },
  { label: 'Peipin', value: 'Peipin' },
  { label: 'Peipsiääre Vald', value: 'Peipsiääre Vald' },
  { label: 'Peißen', value: 'Peißen' },
  { label: 'Peißenberg', value: 'Peißenberg' },
  { label: 'Peiting', value: 'Peiting' },
  { label: 'Peitz', value: 'Peitz' },
  { label: 'Peixe', value: 'Peixe' },
  { label: 'Peixe-Boi', value: 'Peixe-Boi' },
  { label: 'Peixoto De Azevedo', value: 'Peixoto De Azevedo' },
  { label: 'Peize', value: 'Peize' },
  { label: 'Pejibaye', value: 'Pejibaye' },
  { label: 'Pejuçara', value: 'Pejuçara' },
  { label: 'Pekalongan', value: 'Pekalongan' },
  { label: 'Pekan', value: 'Pekan' },
  { label: 'Pekan Bahapal', value: 'Pekan Bahapal' },
  { label: 'Pekan Nenas', value: 'Pekan Nenas' },
  { label: 'Pekanbaru', value: 'Pekanbaru' },
  { label: 'Pekin', value: 'Pekin' },
  { label: 'Peklenica', value: 'Peklenica' },
  { label: 'Pekre', value: 'Pekre' },
  { label: 'Pelabravo', value: 'Pelabravo' },
  { label: 'Pelabuhan Klang', value: 'Pelabuhan Klang' },
  { label: 'Pelabuhanratu', value: 'Pelabuhanratu' },
  { label: 'Pelagiada', value: 'Pelagiada' },
  { label: 'Pelagićevo', value: 'Pelagićevo' },
  { label: 'Pelago', value: 'Pelago' },
  { label: 'Pelahatchie', value: 'Pelahatchie' },
  { label: 'Pelahustán', value: 'Pelahustán' },
  { label: 'Pelarco', value: 'Pelarco' },
  { label: 'Pelarrodríguez', value: 'Pelarrodríguez' },
  { label: 'Pelasgía', value: 'Pelasgía' },
  { label: 'Pelaya', value: 'Pelaya' },
  { label: 'Pelayos', value: 'Pelayos' },
  { label: 'Pelayos De La Presa', value: 'Pelayos De La Presa' },
  { label: 'Pelayos Del Arroyo', value: 'Pelayos Del Arroyo' },
  { label: 'Pełczyce', value: 'Pełczyce' },
  { label: 'Peleagonzalo', value: 'Peleagonzalo' },
  { label: 'Peleas De Abajo', value: 'Peleas De Abajo' },
  { label: 'Peleduy', value: 'Peleduy' },
  { label: 'Peléndri', value: 'Peléndri' },
  { label: 'Pelham', value: 'Pelham' },
  { label: 'Pelham', value: 'Pelham' },
  { label: 'Pelham', value: 'Pelham' },
  { label: 'Pelham', value: 'Pelham' },
  { label: 'Pelham', value: 'Pelham' },
  { label: 'Pelham Manor', value: 'Pelham Manor' },
  { label: 'Pelhřimov', value: 'Pelhřimov' },
  { label: 'Pelican Bay', value: 'Pelican Bay' },
  { label: 'Pelican Bay', value: 'Pelican Bay' },
  { label: 'Pelican Narrows', value: 'Pelican Narrows' },
  { label: 'Pelican Rapids', value: 'Pelican Rapids' },
  { label: 'Pelican Waters', value: 'Pelican Waters' },
  { label: 'Peligros', value: 'Peligros' },
  { label: 'Pelileo', value: 'Pelileo' },
  { label: 'Pélissanne', value: 'Pélissanne' },
  { label: 'Peliyagoda', value: 'Peliyagoda' },
  { label: 'Pelkosenniemi', value: 'Pelkosenniemi' },
  { label: 'Pell City', value: 'Pell City' },
  { label: 'Pell Lake', value: 'Pell Lake' },
  { label: 'Pell River', value: 'Pell River' },
  { label: 'Pella', value: 'Pella' },
  { label: 'Pella', value: 'Pella' },
  { label: 'Pélla', value: 'Pélla' },
  { label: 'Pellaro', value: 'Pellaro' },
  { label: 'Pellegrino Parmense', value: 'Pellegrino Parmense' },
  { label: 'Pellegrue', value: 'Pellegrue' },
  { label: 'Pellérd', value: 'Pellérd' },
  { label: 'Pellestrina', value: 'Pellestrina' },
  { label: 'Pellezzano', value: 'Pellezzano' },
  { label: 'Pellingen', value: 'Pellingen' },
  { label: 'Pellio Intelvi', value: 'Pellio Intelvi' },
  { label: 'Pellizzano', value: 'Pellizzano' },
  { label: 'Pello', value: 'Pello' },
  { label: 'Pellouailles-Les-Vignes', value: 'Pellouailles-Les-Vignes' },
  { label: 'Pelluhue', value: 'Pelluhue' },
  { label: 'Pellworm', value: 'Pellworm' },
  { label: 'Pelm', value: 'Pelm' },
  { label: 'Pelópi', value: 'Pelópi' },
  { label: 'Pelotas', value: 'Pelotas' },
  { label: 'Pelousey', value: 'Pelousey' },
  { label: 'Pelplin', value: 'Pelplin' },
  { label: 'Pelsall', value: 'Pelsall' },
  { label: 'Pelton', value: 'Pelton' },
  { label: 'Peltre', value: 'Peltre' },
  { label: 'Pelugo', value: 'Pelugo' },
  { label: 'Pélussin', value: 'Pélussin' },
  { label: 'Pelym', value: 'Pelym' },
  { label: 'Pemagatshel', value: 'Pemagatshel' },
  { label: 'Pemalang', value: 'Pemalang' },
  { label: 'Pemangkat', value: 'Pemangkat' },
  { label: 'Pematangsiantar', value: 'Pematangsiantar' },
  { label: 'Pemba', value: 'Pemba' },
  { label: 'Pemberton', value: 'Pemberton' },
  { label: 'Pemberton', value: 'Pemberton' },
  { label: 'Pemberton', value: 'Pemberton' },
  { label: 'Pemberton Heights', value: 'Pemberton Heights' },
  { label: 'Pemberville', value: 'Pemberville' },
  { label: 'Pemberwick', value: 'Pemberwick' },
  { label: 'Pembina County', value: 'Pembina County' },
  { label: 'Pembroke', value: 'Pembroke' },
  { label: 'Pembroke', value: 'Pembroke' },
  { label: 'Pembroke', value: 'Pembroke' },
  { label: 'Pembroke', value: 'Pembroke' },
  { label: 'Pembroke', value: 'Pembroke' },
  { label: 'Pembroke', value: 'Pembroke' },
  { label: 'Pembroke', value: 'Pembroke' },
  { label: 'Pembroke Dock', value: 'Pembroke Dock' },
  { label: 'Pembroke Hall', value: 'Pembroke Hall' },
  { label: 'Pembroke Park', value: 'Pembroke Park' },
  { label: 'Pembroke Pines', value: 'Pembroke Pines' },
  { label: 'Pembrokeshire', value: 'Pembrokeshire' },
  { label: 'Pembury', value: 'Pembury' },
  { label: 'Pemenang', value: 'Pemenang' },
  { label: 'Pemfling', value: 'Pemfling' },
  { label: 'Pemiscot County', value: 'Pemiscot County' },
  { label: 'Pemucho', value: 'Pemucho' },
  { label: 'Pemuco', value: 'Pemuco' },
  { label: 'Pemulwuy', value: 'Pemulwuy' },
  { label: 'Pemzashen', value: 'Pemzashen' },
  { label: 'Pen', value: 'Pen' },
  { label: 'Pen Argyl', value: 'Pen Argyl' },
  { label: 'Pena', value: 'Pena' },
  { label: 'Peña', value: 'Peña' },
  { label: 'Peña Blanca', value: 'Peña Blanca' },
  { label: 'Peña Blanca', value: 'Peña Blanca' },
  { label: 'Peña Blanca', value: 'Peña Blanca' },
  { label: 'Peña Colorada', value: 'Peña Colorada' },
  { label: 'Peña Del Panal', value: 'Peña Del Panal' },
  { label: 'Peña Flores (Palo Verde)', value: 'Peña Flores (Palo Verde)' },
  { label: 'Peña La', value: 'Peña La' },
  { label: 'Pena Lobo', value: 'Pena Lobo' },
  { label: 'Peña Pobre', value: 'Peña Pobre' },
  { label: 'Pena Verde', value: 'Pena Verde' },
  { label: 'Peñablanca', value: 'Peñablanca' },
  { label: 'Peñablanca', value: 'Peñablanca' },
  { label: 'Peñacaballera', value: 'Peñacaballera' },
  { label: 'Peñacerrada-Urizaharra', value: 'Peñacerrada-Urizaharra' },
  { label: 'Penacova', value: 'Penacova' },
  { label: 'Penafiel', value: 'Penafiel' },
  { label: 'Peñafiel', value: 'Peñafiel' },
  { label: 'Peñaflor', value: 'Peñaflor' },
  { label: 'Peñaflor', value: 'Peñaflor' },
  { label: 'Peñaflor De Hornija', value: 'Peñaflor De Hornija' },
  { label: 'Penaforte', value: 'Penaforte' },
  { label: 'Penagos', value: 'Penagos' },
  { label: 'Penàguila', value: 'Penàguila' },
  { label: 'Peñal', value: 'Peñal' },
  { label: 'Peñalba', value: 'Peñalba' },
  { label: 'Peñalba De Ávila', value: 'Peñalba De Ávila' },
  { label: 'Peñalén', value: 'Peñalén' },
  { label: 'Penally', value: 'Penally' },
  { label: 'Peñalolén', value: 'Peñalolén' },
  { label: 'Peñalsordo', value: 'Peñalsordo' },
  { label: 'Penalva', value: 'Penalva' },
  { label: 'Penalva Do Castelo', value: 'Penalva Do Castelo' },
  { label: 'Peñalver', value: 'Peñalver' },
  { label: 'Penamacor', value: 'Penamacor' },
  { label: 'Peñamellera Alta', value: 'Peñamellera Alta' },
  { label: 'Peñamellera Baja', value: 'Peñamellera Baja' },
  { label: 'Peñamiller', value: 'Peñamiller' },
  { label: 'Penango', value: 'Penango' },
  { label: 'Peñaparda', value: 'Peñaparda' },
  { label: 'Penápolis', value: 'Penápolis' },
  { label: 'Peñaranda', value: 'Peñaranda' },
  { label: 'Peñaranda', value: 'Peñaranda' },
  { label: 'Peñaranda De Bracamonte', value: 'Peñaranda De Bracamonte' },
  { label: 'Peñaranda De Duero', value: 'Peñaranda De Duero' },
  { label: 'Peñarandilla', value: 'Peñarandilla' },
  { label: 'Peñarol', value: 'Peñarol' },
  { label: 'Peñarroya De Tastavins', value: 'Peñarroya De Tastavins' },
  { label: 'Peñarroya-Pueblonuevo', value: 'Peñarroya-Pueblonuevo' },
  { label: 'Peñarrubia', value: 'Peñarrubia' },
  { label: 'Peñarrubia', value: 'Peñarrubia' },
  { label: 'Peñarrubia', value: 'Peñarrubia' },
  { label: 'Penarth', value: 'Penarth' },
  { label: 'Peñas Chatas', value: 'Peñas Chatas' },
  { label: 'Peñas De Riglos Las', value: 'Peñas De Riglos Las' },
  { label: 'Peñas De San Pedro', value: 'Peñas De San Pedro' },
  { label: 'Peñascosa', value: 'Peñascosa' },
  { label: 'Peñausende', value: 'Peñausende' },
  { label: 'Penbrook', value: 'Penbrook' },
  { label: 'Pencader', value: 'Pencader' },
  { label: 'Pencahue', value: 'Pencahue' },
  { label: 'Pencaitland', value: 'Pencaitland' },
  { label: 'Pěnčín', value: 'Pěnčín' },
  { label: 'Pen-Clawdd', value: 'Pen-Clawdd' },
  { label: 'Penco', value: 'Penco' },
  { label: 'Pencoed', value: 'Pencoed' },
  { label: 'Pencran', value: 'Pencran' },
  { label: 'Pencuyut', value: 'Pencuyut' },
  { label: 'Pend Oreille County', value: 'Pend Oreille County' },
  { label: 'Pendé', value: 'Pendé' },
  { label: 'Pendembu', value: 'Pendembu' },
  { label: 'Pendências', value: 'Pendências' },
  { label: 'Pender', value: 'Pender' },
  { label: 'Pender County', value: 'Pender County' },
  { label: 'Pendik', value: 'Pendik' },
  { label: 'Pendino', value: 'Pendino' },
  { label: 'Pendle Hill', value: 'Pendle Hill' },
  { label: 'Pendleton', value: 'Pendleton' },
  { label: 'Pendleton', value: 'Pendleton' },
  { label: 'Pendleton', value: 'Pendleton' },
  { label: 'Pendleton County', value: 'Pendleton County' },
  { label: 'Pendleton County', value: 'Pendleton County' },
  { label: 'Pendra', value: 'Pendra' },
  { label: 'Pendrecht', value: 'Pendrecht' },
  { label: 'Penedo', value: 'Penedo' },
  { label: 'Penedo Gordo', value: 'Penedo Gordo' },
  { label: 'Penedono', value: 'Penedono' },
  { label: 'Penela', value: 'Penela' },
  { label: 'Penelles', value: 'Penelles' },
  { label: 'Pénestin', value: 'Pénestin' },
  { label: 'Pengcheng', value: 'Pengcheng' },
  { label: 'Penghu County', value: 'Penghu County' },
  { label: 'Penguin', value: 'Penguin' },
  { label: 'Penha', value: 'Penha' },
  { label: 'Penha De Águia', value: 'Penha De Águia' },
  { label: 'Penha De França', value: 'Penha De França' },
  { label: 'Penhalonga', value: 'Penhalonga' },
  { label: 'Penhold', value: 'Penhold' },
  { label: 'Peniche', value: 'Peniche' },
  { label: 'Penicuik', value: 'Penicuik' },
  { label: 'Penig', value: 'Penig' },
  { label: 'Peníscola', value: 'Peníscola' },
  { label: 'Peníscola/Peñíscola', value: 'Peníscola/Peñíscola' },
  { label: 'Penistone', value: 'Penistone' },
  { label: 'Penitas', value: 'Penitas' },
  { label: 'Penitro', value: 'Penitro' },
  { label: 'Penja', value: 'Penja' },
  { label: 'Penjamillo De Degollado', value: 'Penjamillo De Degollado' },
  { label: 'Pénjamo', value: 'Pénjamo' },
  { label: 'Pénjamo', value: 'Pénjamo' },
  { label: 'Penkridge', value: 'Penkridge' },
  { label: 'Penkun', value: 'Penkun' },
  { label: 'Penmaenmawr', value: 'Penmaenmawr' },
  { label: 'Penn Estates', value: 'Penn Estates' },
  { label: 'Penn Hills', value: 'Penn Hills' },
  { label: 'Penn Valley', value: 'Penn Valley' },
  { label: 'Penn Wynne', value: 'Penn Wynne' },
  { label: 'Penn Yan', value: 'Penn Yan' },
  { label: 'Penna In Teverina', value: 'Penna In Teverina' },
  { label: 'Penna San Giovanni', value: 'Penna San Giovanni' },
  { label: 'Penna Santandrea', value: 'Penna Santandrea' },
  { label: 'Pennabilli', value: 'Pennabilli' },
  { label: 'Pennadam', value: 'Pennadam' },
  { label: 'Pennadomo', value: 'Pennadomo' },
  { label: 'Pennagaram', value: 'Pennagaram' },
  { label: 'Pennant Hills', value: 'Pennant Hills' },
  { label: 'Pennants', value: 'Pennants' },
  { label: 'Pennapiedimonte', value: 'Pennapiedimonte' },
  { label: 'Pennathur', value: 'Pennathur' },
  { label: 'Pennautier', value: 'Pennautier' },
  { label: 'Penndel', value: 'Penndel' },
  { label: 'Penne', value: 'Penne' },
  { label: 'Pennewang', value: 'Pennewang' },
  { label: 'Penngrove', value: 'Penngrove' },
  { label: 'Pennigsehl', value: 'Pennigsehl' },
  { label: 'Penningsveer', value: 'Penningsveer' },
  { label: 'Pennington', value: 'Pennington' },
  { label: 'Pennington', value: 'Pennington' },
  { label: 'Pennington County', value: 'Pennington County' },
  { label: 'Pennington County', value: 'Pennington County' },
  { label: 'Pennington Gap', value: 'Pennington Gap' },
  { label: 'Pennisi', value: 'Pennisi' },
  { label: 'Penns Grove', value: 'Penns Grove' },
  { label: 'Pennsauken', value: 'Pennsauken' },
  { label: 'Pennsboro', value: 'Pennsboro' },
  { label: 'Pennsburg', value: 'Pennsburg' },
  { label: 'Pennside', value: 'Pennside' },
  { label: 'Pennsport', value: 'Pennsport' },
  { label: 'Pennsville', value: 'Pennsville' },
  { label: 'Pennville', value: 'Pennville' },
  { label: 'Peno', value: 'Peno' },
  { label: 'Penobscot', value: 'Penobscot' },
  { label: 'Penobscot County', value: 'Penobscot County' },
  { label: 'Peñol', value: 'Peñol' },
  { label: 'Penola', value: 'Penola' },
  { label: 'Penonomé', value: 'Penonomé' },
  { label: 'Penovskiy Rayon', value: 'Penovskiy Rayon' },
  { label: 'Penparcau', value: 'Penparcau' },
  { label: 'Penrhyndeudraeth', value: 'Penrhyndeudraeth' },
  { label: 'Penrith', value: 'Penrith' },
  { label: 'Penrith', value: 'Penrith' },
  { label: 'Penrith Municipality', value: 'Penrith Municipality' },
  { label: 'Penrose', value: 'Penrose' },
  { label: 'Penryn', value: 'Penryn' },
  { label: 'Penryn', value: 'Penryn' },
  { label: 'Pensacola', value: 'Pensacola' },
  { label: 'Penshurst', value: 'Penshurst' },
  { label: 'Pensilva', value: 'Pensilva' },
  { label: 'Pensilvania', value: 'Pensilvania' },
  { label: 'Penta-Di-Casinca', value: 'Penta-Di-Casinca' },
  { label: 'Pentálofos', value: 'Pentálofos' },
  { label: 'Pentaplátano', value: 'Pentaplátano' },
  { label: 'Pentecoste', value: 'Pentecoste' },
  { label: 'Pentéli', value: 'Pentéli' },
  { label: 'Penthalaz', value: 'Penthalaz' },
  { label: 'Penthéréaz', value: 'Penthéréaz' },
  { label: 'Penticton', value: 'Penticton' },
  { label: 'Pentling', value: 'Pentling' },
  { label: 'Pentone', value: 'Pentone' },
  { label: 'Pentre', value: 'Pentre' },
  { label: 'Pentyrch', value: 'Pentyrch' },
  { label: 'Peñuela', value: 'Peñuela' },
  { label: 'Peñuelas', value: 'Peñuelas' },
  { label: 'Peñuelas', value: 'Peñuelas' },
  { label: 'Peñuelas', value: 'Peñuelas' },
  { label: 'Peñuelas (El Cienegal)', value: 'Peñuelas (El Cienegal)' },
  { label: 'Penugonda', value: 'Penugonda' },
  { label: 'Penukonda', value: 'Penukonda' },
  { label: 'Penvénan', value: 'Penvénan' },
  { label: 'Penwood', value: 'Penwood' },
  { label: 'Penyffordd', value: 'Penyffordd' },
  { label: 'Penygroes', value: 'Penygroes' },
  { label: 'Penz', value: 'Penz' },
  { label: 'Penza', value: 'Penza' },
  { label: 'Penzance', value: 'Penzance' },
  { label: 'Penzberg', value: 'Penzberg' },
  { label: 'Penzhinskiy Rayon', value: 'Penzhinskiy Rayon' },
  { label: 'Penzing', value: 'Penzing' },
  { label: 'Penzlin', value: 'Penzlin' },
  { label: 'Peoria', value: 'Peoria' },
  { label: 'Peoria', value: 'Peoria' },
  { label: 'Peoria County', value: 'Peoria County' },
  { label: 'Peoria Heights', value: 'Peoria Heights' },
  { label: 'Peosta', value: 'Peosta' },
  { label: 'Peotillos', value: 'Peotillos' },
  { label: 'Peotone', value: 'Peotone' },
  { label: 'Pepeekeo', value: 'Pepeekeo' },
  { label: 'Pepel', value: 'Pepel' },
  { label: 'Pépieux', value: 'Pépieux' },
  { label: 'Pepillo Salcedo', value: 'Pepillo Salcedo' },
  { label: 'Pepin County', value: 'Pepin County' },
  { label: 'Pepingen', value: 'Pepingen' },
  { label: 'Pepino', value: 'Pepino' },
  { label: 'Pepinster', value: 'Pepinster' },
  { label: 'Péplos', value: 'Péplos' },
  { label: 'Pępowo', value: 'Pępowo' },
  { label: 'Pepper', value: 'Pepper' },
  { label: 'Pepper Pike', value: 'Pepper Pike' },
  { label: 'Pepperell', value: 'Pepperell' },
  { label: 'Peppermill Village', value: 'Peppermill Village' },
  { label: 'Peppermint Grove', value: 'Peppermint Grove' },
  { label: 'Peque', value: 'Peque' },
  { label: 'Peque', value: 'Peque' },
  { label: 'Pequeri', value: 'Pequeri' },
  { label: 'Pequi', value: 'Pequi' },
  { label: 'Pequizeiro', value: 'Pequizeiro' },
  { label: 'Pequot Lakes', value: 'Pequot Lakes' },
  { label: 'Pér', value: 'Pér' },
  { label: 'Péra', value: 'Péra' },
  { label: 'Pêra', value: 'Pêra' },
  { label: 'Pêra Do Moço', value: 'Pêra Do Moço' },
  { label: 'Pera La', value: 'Pera La' },
  { label: 'Peracense', value: 'Peracense' },
  { label: 'Perach', value: 'Perach' },
  { label: 'Perachóra', value: 'Perachóra' },
  { label: 'Peradeniya', value: 'Peradeniya' },
  { label: 'Perafita', value: 'Perafita' },
  { label: 'Perafita', value: 'Perafita' },
  { label: 'Perafort', value: 'Perafort' },
  { label: 'Perai', value: 'Perai' },
  { label: 'Perai', value: 'Perai' },
  { label: 'Peraía', value: 'Peraía' },
  { label: 'Peraiyur', value: 'Peraiyur' },
  { label: 'Peral', value: 'Peral' },
  { label: 'Peral De Arlanza', value: 'Peral De Arlanza' },
  { label: 'Peral El', value: 'Peral El' },
  { label: 'Peralada', value: 'Peralada' },
  { label: 'Peraleda De La Mata', value: 'Peraleda De La Mata' },
  { label: 'Peraleda De San Román', value: 'Peraleda De San Román' },
  { label: 'Peraleda Del Zaucejo', value: 'Peraleda Del Zaucejo' },
  { label: 'Peraleja La', value: 'Peraleja La' },
  { label: 'Peralejos', value: 'Peralejos' },
  { label: 'Peralejos De Abajo', value: 'Peralejos De Abajo' },
  { label: 'Peralejos De Arriba', value: 'Peralejos De Arriba' },
  { label: 'Peralejos De Las Truchas', value: 'Peralejos De Las Truchas' },
  { label: 'Perales', value: 'Perales' },
  { label: 'Perales', value: 'Perales' },
  { label: 'Perales De Tajuña', value: 'Perales De Tajuña' },
  { label: 'Perales Del Alfambra', value: 'Perales Del Alfambra' },
  { label: 'Perales Del Puerto', value: 'Perales Del Puerto' },
  { label: 'Peralillo', value: 'Peralillo' },
  { label: 'Peralta', value: 'Peralta' },
  { label: 'Peralta', value: 'Peralta' },
  { label: 'Peralta', value: 'Peralta' },
  { label: 'Peralta', value: 'Peralta' },
  { label: 'Peralta De Alcofea', value: 'Peralta De Alcofea' },
  { label: 'Peralta De Calasanz', value: 'Peralta De Calasanz' },
  { label: 'Peralta/Azkoien', value: 'Peralta/Azkoien' },
  { label: 'Peraltilla', value: 'Peraltilla' },
  { label: 'Peralveche', value: 'Peralveche' },
  { label: 'Perama', value: 'Perama' },
  { label: 'Pérama', value: 'Pérama' },
  { label: 'Pérama', value: 'Pérama' },
  { label: 'Pérama', value: 'Pérama' },
  { label: 'Perambalur', value: 'Perambalur' },
  { label: 'Peramoga', value: 'Peramoga' },
  { label: 'Peramola', value: 'Peramola' },
  { label: 'Peranamallur', value: 'Peranamallur' },
  { label: 'Peranampattu', value: 'Peranampattu' },
  { label: 'Perano', value: 'Perano' },
  { label: 'Peranzanes', value: 'Peranzanes' },
  { label: 'Perarolo Di Cadore', value: 'Perarolo Di Cadore' },
  { label: 'Perarrúa', value: 'Perarrúa' },
  { label: 'Peravurani', value: 'Peravurani' },
  { label: 'Perbál', value: 'Perbál' },
  { label: 'Perbaungan', value: 'Perbaungan' },
  { label: 'Perca', value: 'Perca' },
  { label: 'Perchau Am Sattel', value: 'Perchau Am Sattel' },
  { label: 'Perches', value: 'Perches' },
  { label: 'Perchtoldsdorf', value: 'Perchtoldsdorf' },
  { label: 'Percile', value: 'Percile' },
  { label: 'Percoto', value: 'Percoto' },
  { label: 'Percut', value: 'Percut' },
  { label: 'Percy', value: 'Percy' },
  { label: 'Perdasdefogu', value: 'Perdasdefogu' },
  { label: 'Perdaxius', value: 'Perdaxius' },
  { label: 'Perdifumo', value: 'Perdifumo' },
  { label: 'Perdigão', value: 'Perdigão' },
  { label: 'Perdigón El', value: 'Perdigón El' },
  { label: 'Perdiguera', value: 'Perdiguera' },
  { label: 'Pérdika', value: 'Pérdika' },
  { label: 'Perdizes', value: 'Perdizes' },
  { label: 'Perdões', value: 'Perdões' },
  { label: 'Perechyn', value: 'Perechyn' },
  { label: 'Perechyn Raion', value: 'Perechyn Raion' },
  { label: 'Peredovaya', value: 'Peredovaya' },
  { label: 'Peregian Beach', value: 'Peregian Beach' },
  { label: 'Peregian Springs', value: 'Peregian Springs' },
  { label: 'Perego', value: 'Perego' },
  { label: 'Peregu Mare', value: 'Peregu Mare' },
  { label: 'Peregu Mic', value: 'Peregu Mic' },
  { label: 'Perehonivka', value: 'Perehonivka' },
  { label: 'Pereiaslav-Khmelnytskyi', value: 'Pereiaslav-Khmelnytskyi' },
  { label: 'Pereiaslav-Khmelnytskyi Raion', value: 'Pereiaslav-Khmelnytskyi Raion' },
  { label: 'Pereira', value: 'Pereira' },
  { label: 'Pereira Barreto', value: 'Pereira Barreto' },
  { label: 'Pereiras', value: 'Pereiras' },
  { label: 'Pereiro', value: 'Pereiro' },
  { label: 'Pereiro', value: 'Pereiro' },
  { label: 'Pereiro De Aguiar', value: 'Pereiro De Aguiar' },
  { label: 'Pereiro De Aguiar O', value: 'Pereiro De Aguiar O' },
  { label: 'Pereiro De Palhacana', value: 'Pereiro De Palhacana' },
  { label: 'Pereleshino', value: 'Pereleshino' },
  { label: 'Pereleshinskiy', value: 'Pereleshinskiy' },
  { label: 'Perelhal', value: 'Perelhal' },
  { label: 'Perelló El', value: 'Perelló El' },
  { label: 'Perelyub', value: 'Perelyub' },
  { label: 'Peremetnoe', value: 'Peremetnoe' },
  { label: 'Peremyshl’', value: 'Peremyshl’' },
  { label: 'Peremyshl’Skiy Rayon', value: 'Peremyshl’Skiy Rayon' },
  { label: 'Peremyshlyany', value: 'Peremyshlyany' },
  { label: 'Peren', value: 'Peren' },
  { label: 'Pereña De La Ribera', value: 'Pereña De La Ribera' },
  { label: 'Pérenchies', value: 'Pérenchies' },
  { label: 'Perené', value: 'Perené' },
  { label: 'Perenjori', value: 'Perenjori' },
  { label: 'Perepravnaya', value: 'Perepravnaya' },
  { label: 'Pereruela', value: 'Pereruela' },
  { label: 'Peresichna', value: 'Peresichna' },
  { label: 'Pereslavl’-Zalesskiy', value: 'Pereslavl’-Zalesskiy' },
  { label: 'Pereslavskiy Rayon', value: 'Pereslavskiy Rayon' },
  { label: 'Peresvet', value: 'Peresvet' },
  { label: 'Pereto', value: 'Pereto' },
  { label: 'Peretu', value: 'Peretu' },
  { label: 'Pereval’S’K', value: 'Pereval’S’K' },
  { label: 'Perevalnoye', value: 'Perevalnoye' },
  { label: 'Perevoloki', value: 'Perevoloki' },
  { label: 'Perevolotskiy', value: 'Perevolotskiy' },
  { label: 'Perevoz', value: 'Perevoz' },
  { label: 'Pereyaslovskaya', value: 'Pereyaslovskaya' },
  { label: 'Perez', value: 'Perez' },
  { label: 'Pérez', value: 'Pérez' },
  { label: 'Perez Castellanos', value: 'Perez Castellanos' },
  { label: 'Pérez De Galeana', value: 'Pérez De Galeana' },
  { label: 'Pérez Zeledón', value: 'Pérez Zeledón' },
  { label: 'Perezville', value: 'Perezville' },
  { label: 'Perfilovo', value: 'Perfilovo' },
  { label: 'Perfugas', value: 'Perfugas' },
  { label: 'Perg', value: 'Perg' },
  { label: 'Pérgamos', value: 'Pérgamos' },
  { label: 'Pergine Valdarno', value: 'Pergine Valdarno' },
  { label: 'Pergine Valsugana', value: 'Pergine Valsugana' },
  { label: 'Pergola', value: 'Pergola' },
  { label: 'Perham', value: 'Perham' },
  { label: 'Perho', value: 'Perho' },
  { label: 'Peri', value: 'Peri' },
  { label: 'Peri Mirim', value: 'Peri Mirim' },
  { label: 'Periam', value: 'Periam' },
  { label: 'Periana', value: 'Periana' },
  { label: 'Peribán De Ramos', value: 'Peribán De Ramos' },
  { label: 'Pericei', value: 'Pericei' },
  { label: 'Perico', value: 'Perico' },
  { label: 'Pericos', value: 'Pericos' },
  { label: 'Pericos', value: 'Pericos' },
  { label: 'Pericotepec', value: 'Pericotepec' },
  { label: 'Peridot', value: 'Peridot' },
  { label: 'Perieni', value: 'Perieni' },
  { label: 'Perieni', value: 'Perieni' },
  { label: 'Périer', value: 'Périer' },
  { label: 'Périers', value: 'Périers' },
  { label: 'Perieţi', value: 'Perieţi' },
  { label: 'Perieţi', value: 'Perieţi' },
  { label: 'Pérignac', value: 'Pérignac' },
  { label: 'Pérignat-Lès-Sarliève', value: 'Pérignat-Lès-Sarliève' },
  { label: 'Pérignat-Sur-Allier', value: 'Pérignat-Sur-Allier' },
  { label: 'Périgneux', value: 'Périgneux' },
  { label: 'Périgny', value: 'Périgny' },
  { label: 'Périgny', value: 'Périgny' },
  { label: 'Périgueux', value: 'Périgueux' },
  { label: 'Perii Broșteni', value: 'Perii Broșteni' },
  { label: 'Perilla De Castro', value: 'Perilla De Castro' },
  { label: 'Perinaldo', value: 'Perinaldo' },
  { label: 'Peringat', value: 'Peringat' },
  { label: 'Perinthalmanna', value: 'Perinthalmanna' },
  { label: 'Periquito', value: 'Periquito' },
  { label: 'Periş', value: 'Periş' },
  { label: 'Periș', value: 'Periș' },
  { label: 'Perişani', value: 'Perişani' },
  { label: 'Perișor', value: 'Perișor' },
  { label: 'Perişoru', value: 'Perişoru' },
  { label: 'Perișoru', value: 'Perișoru' },
  { label: 'Perístasi', value: 'Perístasi' },
  { label: 'Peristerá', value: 'Peristerá' },
  { label: 'Peristéri', value: 'Peristéri' },
  { label: 'Peristeróna', value: 'Peristeróna' },
  { label: 'Peritiba', value: 'Peritiba' },
  { label: 'Perito', value: 'Perito' },
  { label: 'Perito Moreno', value: 'Perito Moreno' },
  { label: 'Peritoró', value: 'Peritoró' },
  { label: 'Perivóli', value: 'Perivóli' },
  { label: 'Perivólia', value: 'Perivólia' },
  { label: 'Perivólia', value: 'Perivólia' },
  { label: 'Periyakulam', value: 'Periyakulam' },
  { label: 'Periyanayakkanpalaiyam', value: 'Periyanayakkanpalaiyam' },
  { label: 'Periyanegamam', value: 'Periyanegamam' },
  { label: 'Periyapatti', value: 'Periyapatti' },
  { label: 'Periyapattinam', value: 'Periyapattinam' },
  { label: 'Periyiáli', value: 'Periyiáli' },
  { label: 'Perkam', value: 'Perkam' },
  { label: 'Perkasie', value: 'Perkasie' },
  { label: 'Perkáta', value: 'Perkáta' },
  { label: 'Perkins', value: 'Perkins' },
  { label: 'Perkins County', value: 'Perkins County' },
  { label: 'Perkins County', value: 'Perkins County' },
  { label: 'Perla De Acapulco', value: 'Perla De Acapulco' },
  { label: 'Perleberg', value: 'Perleberg' },
  { label: 'Perledo', value: 'Perledo' },
  { label: 'Perlesreut', value: 'Perlesreut' },
  { label: 'Perletto', value: 'Perletto' },
  { label: 'Perlez', value: 'Perlez' },
  { label: 'Perlo', value: 'Perlo' },
  { label: 'Perloz', value: 'Perloz' },
  { label: 'Perlsdorf', value: 'Perlsdorf' },
  { label: 'Perm', value: 'Perm' },
  { label: 'Permatang Kuching', value: 'Permatang Kuching' },
  { label: 'Përmet', value: 'Përmet' },
  { label: 'Pernå', value: 'Pernå' },
  { label: 'Pernate', value: 'Pernate' },
  { label: 'Pernegg', value: 'Pernegg' },
  { label: 'Pernegg An Der Mur', value: 'Pernegg An Der Mur' },
  { label: 'Pernem', value: 'Pernem' },
  { label: 'Pernersdorf', value: 'Pernersdorf' },
  { label: 'Pernes', value: 'Pernes' },
  { label: 'Pernes-Les-Fontaines', value: 'Pernes-Les-Fontaines' },
  { label: 'Pernía La', value: 'Pernía La' },
  { label: 'Pernik', value: 'Pernik' },
  { label: 'Perniö', value: 'Perniö' },
  { label: 'Pernis', value: 'Pernis' },
  { label: 'Pernitz', value: 'Pernitz' },
  { label: 'Pernocari-Presinaci', value: 'Pernocari-Presinaci' },
  { label: 'Pernumia', value: 'Pernumia' },
  { label: 'Pero', value: 'Pero' },
  { label: 'Pero', value: 'Pero' },
  { label: 'Pêro Moniz', value: 'Pêro Moniz' },
  { label: 'Pero Pinheiro', value: 'Pero Pinheiro' },
  { label: 'Pero Pinheiro', value: 'Pero Pinheiro' },
  { label: 'Perobal', value: 'Perobal' },
  { label: 'Pérola', value: 'Pérola' },
  { label: 'Pérola Doeste', value: 'Pérola Doeste' },
  { label: 'Perolândia', value: 'Perolândia' },
  { label: 'Pérols', value: 'Pérols' },
  { label: 'Peromingo', value: 'Peromingo' },
  { label: 'Péron', value: 'Péron' },
  { label: 'Péronnas', value: 'Péronnas' },
  { label: 'Péronne', value: 'Péronne' },
  { label: 'Perosa Argentina', value: 'Perosa Argentina' },
  { label: 'Perosa Canavese', value: 'Perosa Canavese' },
  { label: 'Perosillo', value: 'Perosillo' },
  { label: 'Perote', value: 'Perote' },
  { label: 'Perou', value: 'Perou' },
  { label: 'Pérouges', value: 'Pérouges' },
  { label: 'Perovo', value: 'Perovo' },
  { label: 'Peroxa A', value: 'Peroxa A' },
  { label: 'Péroy-Les-Gombries', value: 'Péroy-Les-Gombries' },
  { label: 'Perozinho', value: 'Perozinho' },
  { label: 'Perpignan', value: 'Perpignan' },
  { label: 'Perquenco', value: 'Perquenco' },
  { label: 'Perquimans County', value: 'Perquimans County' },
  { label: 'Perranporth', value: 'Perranporth' },
  { label: 'Perranwell', value: 'Perranwell' },
  { label: 'Perre', value: 'Perre' },
  { label: 'Perrecy-Les-Forges', value: 'Perrecy-Les-Forges' },
  { label: 'Perrelos', value: 'Perrelos' },
  { label: 'Perrero', value: 'Perrero' },
  { label: 'Perreux', value: 'Perreux' },
  { label: 'Perriers-Sur-Andelle', value: 'Perriers-Sur-Andelle' },
  { label: 'Perrignier', value: 'Perrignier' },
  { label: 'Perrigny', value: 'Perrigny' },
  { label: 'Perrigny-Lès-Dijon', value: 'Perrigny-Lès-Dijon' },
  { label: 'Perris', value: 'Perris' },
  { label: 'Perros-Guirec', value: 'Perros-Guirec' },
  { label: 'Perroy', value: 'Perroy' },
  { label: 'Perrusson', value: 'Perrusson' },
  { label: 'Perry', value: 'Perry' },
  { label: 'Perry', value: 'Perry' },
  { label: 'Perry', value: 'Perry' },
  { label: 'Perry', value: 'Perry' },
  { label: 'Perry', value: 'Perry' },
  { label: 'Perry', value: 'Perry' },
  { label: 'Perry', value: 'Perry' },
  { label: 'Perry', value: 'Perry' },
  { label: 'Perry County', value: 'Perry County' },
  { label: 'Perry County', value: 'Perry County' },
  { label: 'Perry County', value: 'Perry County' },
  { label: 'Perry County', value: 'Perry County' },
  { label: 'Perry County', value: 'Perry County' },
  { label: 'Perry County', value: 'Perry County' },
  { label: 'Perry County', value: 'Perry County' },
  { label: 'Perry County', value: 'Perry County' },
  { label: 'Perry County', value: 'Perry County' },
  { label: 'Perry County', value: 'Perry County' },
  { label: 'Perry Hall', value: 'Perry Hall' },
  { label: 'Perry Heights', value: 'Perry Heights' },
  { label: 'Perry Park', value: 'Perry Park' },
  { label: 'Perryman', value: 'Perryman' },
  { label: 'Perryopolis', value: 'Perryopolis' },
  { label: 'Perrysburg', value: 'Perrysburg' },
  { label: 'Perryton', value: 'Perryton' },
  { label: 'Perryville', value: 'Perryville' },
  { label: 'Perryville', value: 'Perryville' },
  { label: 'Perryville', value: 'Perryville' },
  { label: 'Persan', value: 'Persan' },
  { label: 'Perșani', value: 'Perșani' },
  { label: 'Perşembe', value: 'Perşembe' },
  { label: 'Persenbeug', value: 'Persenbeug' },
  { label: 'Perseverancia', value: 'Perseverancia' },
  { label: 'Pershagen', value: 'Pershagen' },
  { label: 'Pershing County', value: 'Pershing County' },
  { label: 'Pershore', value: 'Pershore' },
  { label: 'Pershotravensk', value: 'Pershotravensk' },
  { label: 'Pershotravneve', value: 'Pershotravneve' },
  { label: 'Pershotravneve', value: 'Pershotravneve' },
  { label: 'Persianovka', value: 'Persianovka' },
  { label: 'Persico Dosimo', value: 'Persico Dosimo' },
  { label: 'Perșinari', value: 'Perșinari' },
  { label: 'Pers-Jussy', value: 'Pers-Jussy' },
  { label: 'Person County', value: 'Person County' },
  { label: 'Perštejn', value: 'Perštejn' },
  { label: 'Perstorp', value: 'Perstorp' },
  { label: 'Perșunari', value: 'Perșunari' },
  { label: 'Pertegada', value: 'Pertegada' },
  { label: 'Pertek', value: 'Pertek' },
  { label: 'Pertengo', value: 'Pertengo' },
  { label: 'Perth', value: 'Perth' },
  { label: 'Perth', value: 'Perth' },
  { label: 'Perth', value: 'Perth' },
  { label: 'Perth', value: 'Perth' },
  { label: 'Perth', value: 'Perth' },
  { label: 'Perth Amboy', value: 'Perth Amboy' },
  { label: 'Perth And Kinross', value: 'Perth And Kinross' },
  { label: 'Perth City Centre', value: 'Perth City Centre' },
  { label: 'Perth Town', value: 'Perth Town' },
  { label: 'Perthes', value: 'Perthes' },
  { label: 'Pertica Alta', value: 'Pertica Alta' },
  { label: 'Pertica Bassa', value: 'Pertica Bassa' },
  { label: 'Pertlstein', value: 'Pertlstein' },
  { label: 'Pertosa', value: 'Pertosa' },
  { label: 'Pertteli', value: 'Pertteli' },
  { label: 'Pertuis', value: 'Pertuis' },
  { label: 'Pertunmaa', value: 'Pertunmaa' },
  { label: 'Pertusa', value: 'Pertusa' },
  { label: 'Pertusio', value: 'Pertusio' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Peruc', value: 'Peruc' },
  { label: 'Perugia', value: 'Perugia' },
  { label: 'Perugorría', value: 'Perugorría' },
  { label: 'Peruíbe', value: 'Peruíbe' },
  { label: 'Perumbavoor', value: 'Perumbavoor' },
  { label: 'Perumpavur', value: 'Perumpavur' },
  { label: 'Perundurai', value: 'Perundurai' },
  { label: 'Perungudi', value: 'Perungudi' },
  { label: 'Perur', value: 'Perur' },
  { label: 'Perushtitsa', value: 'Perushtitsa' },
  { label: 'Perušić', value: 'Perušić' },
  { label: 'Péruwelz', value: 'Péruwelz' },
  { label: 'Pervari', value: 'Pervari' },
  { label: 'Pervoavgustovskiy', value: 'Pervoavgustovskiy' },
  { label: 'Pervomaisc', value: 'Pervomaisc' },
  { label: 'Pervomaiskyi Raion', value: 'Pervomaiskyi Raion' },
  { label: 'Pervomayka', value: 'Pervomayka' },
  { label: 'Pervomays’K', value: 'Pervomays’K' },
  { label: 'Pervomays’K', value: 'Pervomays’K' },
  { label: 'Pervomays’Kyy', value: 'Pervomays’Kyy' },
  { label: 'Pervomaysk', value: 'Pervomaysk' },
  { label: 'Pervomayskaya', value: 'Pervomayskaya' },
  { label: 'Pervomayskiy', value: 'Pervomayskiy' },
  { label: 'Pervomayskiy', value: 'Pervomayskiy' },
  { label: 'Pervomayskiy', value: 'Pervomayskiy' },
  { label: 'Pervomayskiy', value: 'Pervomayskiy' },
  { label: 'Pervomayskiy', value: 'Pervomayskiy' },
  { label: 'Pervomayskiy', value: 'Pervomayskiy' },
  { label: 'Pervomayskiy Rayon', value: 'Pervomayskiy Rayon' },
  { label: 'Pervomayskiy Rayon', value: 'Pervomayskiy Rayon' },
  { label: 'Pervomayskiy Rayon', value: 'Pervomayskiy Rayon' },
  { label: 'Pervomayskoye', value: 'Pervomayskoye' },
  { label: 'Pervomayskoye', value: 'Pervomayskoye' },
  { label: 'Pervomayskoye', value: 'Pervomayskoye' },
  { label: 'Pervomayskoye', value: 'Pervomayskoye' },
  { label: 'Pervomayskoye', value: 'Pervomayskoye' },
  { label: 'Pervomayskoye', value: 'Pervomayskoye' },
  { label: 'Pervoural’Sk', value: 'Pervoural’Sk' },
  { label: 'Pervoye Maya', value: 'Pervoye Maya' },
  { label: 'Perwang Am Grabensee', value: 'Perwang Am Grabensee' },
  { label: 'Perwez', value: 'Perwez' },
  { label: 'Péry', value: 'Péry' },
  { label: 'Perya', value: 'Perya' },
  { label: 'Perzów', value: 'Perzów' },
  { label: 'Pesac', value: 'Pesac' },
  { label: 'Pesaguero', value: 'Pesaguero' },
  { label: 'Pesaro', value: 'Pesaro' },
  { label: 'Pesca', value: 'Pesca' },
  { label: 'Pescadería', value: 'Pescadería' },
  { label: 'Pescador', value: 'Pescador' },
  { label: 'Pescaglia', value: 'Pescaglia' },
  { label: 'Pescantina', value: 'Pescantina' },
  { label: 'Pescara', value: 'Pescara' },
  { label: 'Pescarolo', value: 'Pescarolo' },
  { label: 'Pescasseroli', value: 'Pescasseroli' },
  { label: 'Pescate', value: 'Pescate' },
  { label: 'Pesceana', value: 'Pesceana' },
  { label: 'Peschadoires', value: 'Peschadoires' },
  { label: 'Peschana', value: 'Peschana' },
  { label: 'Peschanokopskoye', value: 'Peschanokopskoye' },
  { label: 'Peschanoye', value: 'Peschanoye' },
  { label: 'Pesche', value: 'Pesche' },
  { label: 'Peschici', value: 'Peschici' },
  { label: 'Peschiera Del Garda', value: 'Peschiera Del Garda' },
  { label: 'Pescia', value: 'Pescia' },
  { label: 'Pescia Romana', value: 'Pescia Romana' },
  { label: 'Pescina', value: 'Pescina' },
  { label: 'Pesco Sannita', value: 'Pesco Sannita' },
  { label: 'Pescocostanzo', value: 'Pescocostanzo' },
  { label: 'Pescolanciano', value: 'Pescolanciano' },
  { label: 'Pescopagano', value: 'Pescopagano' },
  { label: 'Pescopennataro', value: 'Pescopennataro' },
  { label: 'Pescorocchiano', value: 'Pescorocchiano' },
  { label: 'Pescosansonesco Nuovo', value: 'Pescosansonesco Nuovo' },
  { label: 'Pescosolido', value: 'Pescosolido' },
  { label: 'Pescueza', value: 'Pescueza' },
  { label: 'Pesé', value: 'Pesé' },
  { label: 'Peseggia-Gardigiano', value: 'Peseggia-Gardigiano' },
  { label: 'Peseux', value: 'Peseux' },
  { label: 'Pesga La', value: 'Pesga La' },
  { label: 'Peshawar', value: 'Peshawar' },
  { label: 'Peshki', value: 'Peshki' },
  { label: 'Peshkopi', value: 'Peshkopi' },
  { label: 'Peshkovo', value: 'Peshkovo' },
  { label: 'Peshku Tumani', value: 'Peshku Tumani' },
  { label: 'Peshtera', value: 'Peshtera' },
  { label: 'Peshtigo', value: 'Peshtigo' },
  { label: 'Peski', value: 'Peski' },
  { label: 'Peskovka', value: 'Peskovka' },
  { label: 'Pesmes', value: 'Pesmes' },
  { label: 'Pesnica Pri Mariboru', value: 'Pesnica Pri Mariboru' },
  { label: 'Peso Da Régua', value: 'Peso Da Régua' },
  { label: 'Pesochnoye', value: 'Pesochnoye' },
  { label: 'Pesochnyy', value: 'Pesochnyy' },
  { label: 'Pesoz', value: 'Pesoz' },
  { label: 'Pespire', value: 'Pespire' },
  { label: 'Pesqueira', value: 'Pesqueira' },
  { label: 'Pesqueira', value: 'Pesqueira' },
  { label: 'Pesquera', value: 'Pesquera' },
  { label: 'Pesquera De Duero', value: 'Pesquera De Duero' },
  { label: 'Pesquera La', value: 'Pesquera La' },
  { label: 'Pesquería', value: 'Pesquería' },
  { label: 'Pessac', value: 'Pessac' },
  { label: 'Pessano Con Bornago', value: 'Pessano Con Bornago' },
  { label: 'Pessina Cremonese', value: 'Pessina Cremonese' },
  { label: 'Pessinetto', value: 'Pessinetto' },
  { label: 'Pessione', value: 'Pessione' },
  { label: 'Peșteana De Jos', value: 'Peșteana De Jos' },
  { label: 'Peșteana Jiu', value: 'Peșteana Jiu' },
  { label: 'Peştera', value: 'Peştera' },
  { label: 'Peștiș', value: 'Peștiș' },
  { label: 'Peştişani', value: 'Peştişani' },
  { label: 'Peștișu Mare', value: 'Peștișu Mare' },
  { label: 'Peştişu Mic', value: 'Peştişu Mic' },
  { label: 'Pestovo', value: 'Pestovo' },
  { label: 'Pestovskiy Rayon', value: 'Pestovskiy Rayon' },
  { label: 'Pestravka', value: 'Pestravka' },
  { label: 'Pestrechinskiy Rayon', value: 'Pestrechinskiy Rayon' },
  { label: 'Pestretsy', value: 'Pestretsy' },
  { label: 'Pestyaki', value: 'Pestyaki' },
  { label: 'Pestyakovskiy Rayon', value: 'Pestyakovskiy Rayon' },
  { label: 'Péta', value: 'Péta' },
  { label: 'Petacalco', value: 'Petacalco' },
  { label: 'Petacciato', value: 'Petacciato' },
  { label: 'Petaẖ Tiqwa', value: 'Petaẖ Tiqwa' },
  { label: 'Petäjävesi', value: 'Petäjävesi' },
  { label: 'Petal', value: 'Petal' },
  { label: 'Petalcingo', value: 'Petalcingo' },
  { label: 'Petaling Jaya', value: 'Petaling Jaya' },
  { label: 'Petaluma', value: 'Petaluma' },
  { label: 'Pétange', value: 'Pétange' },
  { label: 'Petapa', value: 'Petapa' },
  { label: 'Petaquillas', value: 'Petaquillas' },
  { label: 'Petare', value: 'Petare' },
  { label: 'Petatlán', value: 'Petatlán' },
  { label: 'Petauke', value: 'Petauke' },
  { label: 'Petawawa', value: 'Petawawa' },
  { label: 'Petea', value: 'Petea' },
  { label: 'Peteinós', value: 'Peteinós' },
  { label: 'Petelea', value: 'Petelea' },
  { label: 'Peteranec', value: 'Peteranec' },
  { label: 'Peterborough', value: 'Peterborough' },
  { label: 'Peterborough', value: 'Peterborough' },
  { label: 'Peterborough', value: 'Peterborough' },
  { label: 'Peterborough', value: 'Peterborough' },
  { label: 'Peterculter', value: 'Peterculter' },
  { label: 'Peterhead', value: 'Peterhead' },
  { label: 'Peterhead', value: 'Peterhead' },
  { label: 'Peterhof', value: 'Peterhof' },
  { label: 'Péteri', value: 'Péteri' },
  { label: 'Peteritea', value: 'Peteritea' },
  { label: 'Peterlee', value: 'Peterlee' },
  { label: 'Petersaurach', value: 'Petersaurach' },
  { label: 'Petersburg', value: 'Petersburg' },
  { label: 'Petersburg', value: 'Petersburg' },
  { label: 'Petersburg', value: 'Petersburg' },
  { label: 'Petersburg', value: 'Petersburg' },
  { label: 'Petersburg', value: 'Petersburg' },
  { label: 'Petersburg', value: 'Petersburg' },
  { label: 'Petersburg', value: 'Petersburg' },
  { label: 'Petersburg Borough', value: 'Petersburg Borough' },
  { label: 'Petersdorf', value: 'Petersdorf' },
  { label: 'Petersfield', value: 'Petersfield' },
  { label: 'Petersfield', value: 'Petersfield' },
  { label: 'Petershagen', value: 'Petershagen' },
  { label: 'Petershagen', value: 'Petershagen' },
  { label: 'Petersham', value: 'Petersham' },
  { label: 'Petershausen', value: 'Petershausen' },
  { label: 'Petersville', value: 'Petersville' },
  { label: 'Pétervására', value: 'Pétervására' },
  { label: 'Pétervásárai Járás', value: 'Pétervásárai Járás' },
  { label: 'Pétfürdő', value: 'Pétfürdő' },
  { label: 'Petilia Policastro', value: 'Petilia Policastro' },
  { label: 'Petilla De Aragón', value: 'Petilla De Aragón' },
  { label: 'Petín', value: 'Petín' },
  { label: 'Petina', value: 'Petina' },
  { label: 'Pétionville', value: 'Pétionville' },
  { label: 'Petit Bel Air', value: 'Petit Bel Air' },
  { label: 'Petit Fenis', value: 'Petit Fenis' },
  { label: 'Petit Raffray', value: 'Petit Raffray' },
  { label: 'Petit Trou De Nippes', value: 'Petit Trou De Nippes' },
  { label: 'Petit Valley', value: 'Petit Valley' },
  { label: 'Petit-Couronne', value: 'Petit-Couronne' },
  { label: 'Petite Anse', value: 'Petite Anse' },
  { label: 'Petite Case Noyale', value: 'Petite Case Noyale' },
  { label: 'Petite Rivière', value: 'Petite Rivière' },
  { label: 'Petite Rivière De Nippes', value: 'Petite Rivière De Nippes' },
  { label: 'Petite-Forêt', value: 'Petite-Forêt' },
  { label: 'Petite-Rosselle', value: 'Petite-Rosselle' },
  { label: 'Petit-Mars', value: 'Petit-Mars' },
  { label: 'Petit-Noir', value: 'Petit-Noir' },
  { label: 'Petit-Réderching', value: 'Petit-Réderching' },
  { label: 'Petiville', value: 'Petiville' },
  { label: 'Petkovci', value: 'Petkovci' },
  { label: 'Petkovica', value: 'Petkovica' },
  { label: 'Petlad', value: 'Petlad' },
  { label: 'Petlalcingo', value: 'Petlalcingo' },
  { label: 'Petlawad', value: 'Petlawad' },
  { label: 'Petlovac', value: 'Petlovac' },
  { label: 'Petneháza', value: 'Petneháza' },
  { label: 'Peto', value: 'Peto' },
  { label: 'Petoa', value: 'Petoa' },
  { label: 'Petőfibánya', value: 'Petőfibánya' },
  { label: 'Petone', value: 'Petone' },
  { label: 'Petorca', value: 'Petorca' },
  { label: 'Petoskey', value: 'Petoskey' },
  { label: 'Petra', value: 'Petra' },
  { label: 'Petra', value: 'Petra' },
  { label: 'Petrăchioaia', value: 'Petrăchioaia' },
  { label: 'Petra-Dubrava', value: 'Petra-Dubrava' },
  { label: 'Petralia Soprana', value: 'Petralia Soprana' },
  { label: 'Petralia Sottana', value: 'Petralia Sottana' },
  { label: 'Petranka', value: 'Petranka' },
  { label: 'Petrella Salto', value: 'Petrella Salto' },
  { label: 'Petrella Tifernina', value: 'Petrella Tifernina' },
  { label: 'Petrenki', value: 'Petrenki' },
  { label: 'Petrer', value: 'Petrer' },
  { label: 'Petrés', value: 'Petrés' },
  { label: 'Petreşti', value: 'Petreşti' },
  { label: 'Petreşti', value: 'Petreşti' },
  { label: 'Petrești', value: 'Petrești' },
  { label: 'Petrești', value: 'Petrești' },
  { label: 'Petreştii De Jos', value: 'Petreştii De Jos' },
  { label: 'Petreu', value: 'Petreu' },
  { label: 'Petriano', value: 'Petriano' },
  { label: 'Petricani', value: 'Petricani' },
  { label: 'Petrich', value: 'Petrich' },
  { label: 'Petrie', value: 'Petrie' },
  { label: 'Petrie Terrace', value: 'Petrie Terrace' },
  { label: 'Petrignano', value: 'Petrignano' },
  { label: 'Petrijanec', value: 'Petrijanec' },
  { label: 'Petrijevci', value: 'Petrijevci' },
  { label: 'Petrikov', value: 'Petrikov' },
  { label: 'Petrila', value: 'Petrila' },
  { label: 'Petrilaca', value: 'Petrilaca' },
  { label: 'Petrilaca De Mureș', value: 'Petrilaca De Mureș' },
  { label: 'Petrinja', value: 'Petrinja' },
  { label: 'Petriolo', value: 'Petriolo' },
  { label: 'Petriş', value: 'Petriş' },
  { label: 'Petriș', value: 'Petriș' },
  { label: 'Petritoli', value: 'Petritoli' },
  { label: 'Petrivs’Ke', value: 'Petrivs’Ke' },
  { label: 'Petrizzi', value: 'Petrizzi' },
  { label: 'Petrochóri', value: 'Petrochóri' },
  { label: 'Petrodvorets', value: 'Petrodvorets' },
  { label: 'Petrogradka', value: 'Petrogradka' },
  { label: 'Petrokamenskoye', value: 'Petrokamenskoye' },
  { label: 'Pétrola', value: 'Pétrola' },
  { label: 'Petrolândia', value: 'Petrolândia' },
  { label: 'Petrolândia', value: 'Petrolândia' },
  { label: 'Petroleum County', value: 'Petroleum County' },
  { label: 'Petrolia', value: 'Petrolia' },
  { label: 'Petrolina', value: 'Petrolina' },
  { label: 'Petrolina De Goiás', value: 'Petrolina De Goiás' },
  { label: 'Petronà', value: 'Petronà' },
  { label: 'Petronell-Carnuntum', value: 'Petronell-Carnuntum' },
  { label: 'Petropavl', value: 'Petropavl' },
  { label: 'Petropavlivka', value: 'Petropavlivka' },
  { label: 'Petropavlovka', value: 'Petropavlovka' },
  { label: 'Petropavlovskaya', value: 'Petropavlovskaya' },
  { label: 'Petropavlovskaya', value: 'Petropavlovskaya' },
  { label: 'Petropavlovskiy Rayon', value: 'Petropavlovskiy Rayon' },
  { label: 'Petropavlovsk-Kamchatsky', value: 'Petropavlovsk-Kamchatsky' },
  { label: 'Petropavlovskoye', value: 'Petropavlovskoye' },
  { label: 'Petrópolis', value: 'Petrópolis' },
  { label: 'Petroşani', value: 'Petroşani' },
  { label: 'Petrosino', value: 'Petrosino' },
  { label: 'Petro-Slavyanka', value: 'Petro-Slavyanka' },
  { label: 'Petroșnița', value: 'Petroșnița' },
  { label: 'Petroúpolis', value: 'Petroúpolis' },
  { label: 'Petroússa', value: 'Petroússa' },
  { label: 'Petrov', value: 'Petrov' },
  { label: 'Petrov Val', value: 'Petrov Val' },
  { label: 'Petrova', value: 'Petrova' },
  { label: 'Petrovac Na Moru', value: 'Petrovac Na Moru' },
  { label: 'Petrovaradin', value: 'Petrovaradin' },
  { label: 'Petrove', value: 'Petrove' },
  { label: 'Petrovice', value: 'Petrovice' },
  { label: 'Petrovice U Karviné', value: 'Petrovice U Karviné' },
  { label: 'Petrovka', value: 'Petrovka' },
  { label: 'Petrovo-Dal’Neye', value: 'Petrovo-Dal’Neye' },
  { label: 'Petrovsk', value: 'Petrovsk' },
  { label: 'Petrovsk', value: 'Petrovsk' },
  { label: 'Petrovskaya', value: 'Petrovskaya' },
  { label: 'Petrovskaya', value: 'Petrovskaya' },
  { label: 'Petrovskiy', value: 'Petrovskiy' },
  { label: 'Petrovskiy', value: 'Petrovskiy' },
  { label: 'Petrovsk-Zabaykal’Skiy', value: 'Petrovsk-Zabaykal’Skiy' },
  { label: 'Petrozavodsk', value: 'Petrozavodsk' },
  { label: 'Petruro Irpino', value: 'Petruro Irpino' },
  { label: 'Petřvald', value: 'Petřvald' },
  { label: 'Petrykivka', value: 'Petrykivka' },
  { label: 'Petrykivs’Kyy Rayon', value: 'Petrykivs’Kyy Rayon' },
  { label: 'Petten', value: 'Petten' },
  { label: 'Pettenasco', value: 'Pettenasco' },
  { label: 'Pettenbach', value: 'Pettenbach' },
  { label: 'Pettendorf', value: 'Pettendorf' },
  { label: 'Pettinengo', value: 'Pettinengo' },
  { label: 'Pettineo', value: 'Pettineo' },
  { label: 'Petting', value: 'Petting' },
  { label: 'Pettis County', value: 'Pettis County' },
  { label: 'Pettnau', value: 'Pettnau' },
  { label: 'Pettneu', value: 'Pettneu' },
  { label: 'Pettoranello Del Molise', value: 'Pettoranello Del Molise' },
  { label: 'Pettorano Sul Gizio', value: 'Pettorano Sul Gizio' },
  { label: 'Pettorazza Grimani', value: 'Pettorazza Grimani' },
  { label: 'Pettstadt', value: 'Pettstadt' },
  { label: 'Petukhovo', value: 'Petukhovo' },
  { label: 'Petushinskiy Rayon', value: 'Petushinskiy Rayon' },
  { label: 'Petushki', value: 'Petushki' },
  { label: 'Petworth', value: 'Petworth' },
  { label: 'Petzenkirchen', value: 'Petzenkirchen' },
  { label: 'Peuerbach', value: 'Peuerbach' },
  { label: 'Peujard', value: 'Peujard' },
  { label: 'Peumo', value: 'Peumo' },
  { label: 'Peva', value: 'Peva' },
  { label: 'Pevek', value: 'Pevek' },
  { label: 'Pevely', value: 'Pevely' },
  { label: 'Pevensey', value: 'Pevensey' },
  { label: 'Pevensey Bay', value: 'Pevensey Bay' },
  { label: 'Peveragno', value: 'Peveragno' },
  { label: 'Pewaukee', value: 'Pewaukee' },
  { label: 'Pewee Valley', value: 'Pewee Valley' },
  { label: 'Pewel Ślemieńska', value: 'Pewel Ślemieńska' },
  { label: 'Pewel Wielka', value: 'Pewel Wielka' },
  { label: 'Pewsey', value: 'Pewsey' },
  { label: 'Peyhan', value: 'Peyhan' },
  { label: 'Peymeinade', value: 'Peymeinade' },
  { label: 'Peynier', value: 'Peynier' },
  { label: 'Peypin', value: 'Peypin' },
  { label: 'Peyrat-De-Bellac', value: 'Peyrat-De-Bellac' },
  { label: 'Peyrat-Le-Château', value: 'Peyrat-Le-Château' },
  { label: 'Peyrehorade', value: 'Peyrehorade' },
  { label: 'Peyrestortes', value: 'Peyrestortes' },
  { label: 'Peyriac-De-Mer', value: 'Peyriac-De-Mer' },
  { label: 'Peyriac-Minervois', value: 'Peyriac-Minervois' },
  { label: 'Peyrilhac', value: 'Peyrilhac' },
  { label: 'Peyrins', value: 'Peyrins' },
  { label: 'Peyrolles-En-Provence', value: 'Peyrolles-En-Provence' },
  { label: 'Peyruis', value: 'Peyruis' },
  { label: 'Peza La', value: 'Peza La' },
  { label: 'Pézenas', value: 'Pézenas' },
  { label: 'Pezens', value: 'Pezens' },
  { label: 'Pézilla-La-Rivière', value: 'Pézilla-La-Rivière' },
  { label: 'Pezinok', value: 'Pezinok' },
  { label: 'Pezmatlán', value: 'Pezmatlán' },
  { label: 'Pezuela De Las Torres', value: 'Pezuela De Las Torres' },
  { label: 'Pezzan', value: 'Pezzan' },
  { label: 'Pezzana', value: 'Pezzana' },
  { label: 'Pezzano-Filetta', value: 'Pezzano-Filetta' },
  { label: 'Pezzaze', value: 'Pezzaze' },
  { label: 'Pezze Di Greco', value: 'Pezze Di Greco' },
  { label: 'Pezzolo Valle Uzzone', value: 'Pezzolo Valle Uzzone' },
  { label: 'Pfaffenhausen', value: 'Pfaffenhausen' },
  { label: 'Pfaffenheim', value: 'Pfaffenheim' },
  { label: 'Pfaffenhofen', value: 'Pfaffenhofen' },
  { label: 'Pfaffenhofen', value: 'Pfaffenhofen' },
  { label: 'Pfaffenhofen', value: 'Pfaffenhofen' },
  { label: 'Pfaffenhofen An Der Ilm', value: 'Pfaffenhofen An Der Ilm' },
  { label: 'Pfaffenhofen An Der Roth', value: 'Pfaffenhofen An Der Roth' },
  { label: 'Pfaffenhoffen', value: 'Pfaffenhoffen' },
  { label: 'Pfaffen-Schwabenheim', value: 'Pfaffen-Schwabenheim' },
  { label: 'Pfaffenweiler', value: 'Pfaffenweiler' },
  { label: 'Pfaffhausen', value: 'Pfaffhausen' },
  { label: 'Pfäffikon', value: 'Pfäffikon' },
  { label: 'Pfäffikon', value: 'Pfäffikon' },
  { label: 'Pfäffikon / Irgenhausen', value: 'Pfäffikon / Irgenhausen' },
  { label: 'Pfäffikon / Pfäffikon (Dorfkern)', value: 'Pfäffikon / Pfäffikon (Dorfkern)' },
  { label: 'Pfaffing', value: 'Pfaffing' },
  { label: 'Pfaffing', value: 'Pfaffing' },
  { label: 'Pfaffnau', value: 'Pfaffnau' },
  { label: 'Pfaffroda', value: 'Pfaffroda' },
  { label: 'Pfaffstätten', value: 'Pfaffstätten' },
  { label: 'Pfakofen', value: 'Pfakofen' },
  { label: 'Pfalzgrafenweiler', value: 'Pfalzgrafenweiler' },
  { label: 'Pfarrkirchen', value: 'Pfarrkirchen' },
  { label: 'Pfarrkirchen Bei Bad Hall', value: 'Pfarrkirchen Bei Bad Hall' },
  { label: 'Pfarrweisach', value: 'Pfarrweisach' },
  { label: 'Pfarrwerfen', value: 'Pfarrwerfen' },
  { label: 'Pfastatt', value: 'Pfastatt' },
  { label: 'Pfatter', value: 'Pfatter' },
  { label: 'Pfedelbach', value: 'Pfedelbach' },
  { label: 'Pfeffelbach', value: 'Pfeffelbach' },
  { label: 'Pfeffenhausen', value: 'Pfeffenhausen' },
  { label: 'Pfeffingen', value: 'Pfeffingen' },
  { label: 'Pfetterhouse', value: 'Pfetterhouse' },
  { label: 'Pflach', value: 'Pflach' },
  { label: 'Pflugerville', value: 'Pflugerville' },
  { label: 'Pfofeld', value: 'Pfofeld' },
  { label: 'Pfons', value: 'Pfons' },
  { label: 'Pforzen', value: 'Pforzen' },
  { label: 'Pforzheim', value: 'Pforzheim' },
  { label: 'Pfreimd', value: 'Pfreimd' },
  { label: 'Pfronstetten', value: 'Pfronstetten' },
  { label: 'Pfronten', value: 'Pfronten' },
  { label: 'Pfulgriesheim', value: 'Pfulgriesheim' },
  { label: 'Pfullendorf', value: 'Pfullendorf' },
  { label: 'Pfullingen', value: 'Pfullingen' },
  { label: 'Pfunds', value: 'Pfunds' },
  { label: 'Pfungen', value: 'Pfungen' },
  { label: 'Pfungstadt', value: 'Pfungstadt' },
  { label: 'Pfyn', value: 'Pfyn' },
  { label: 'Phaëton', value: 'Phaëton' },
  { label: 'Phagwara', value: 'Phagwara' },
  { label: 'Phai Sali', value: 'Phai Sali' },
  { label: 'Phak Hai', value: 'Phak Hai' },
  { label: 'Phalaborwa', value: 'Phalaborwa' },
  { label: 'Phalauda', value: 'Phalauda' },
  { label: 'Phalempin', value: 'Phalempin' },
  { label: 'Phalia', value: 'Phalia' },
  { label: 'Phalodi', value: 'Phalodi' },
  { label: 'Phalombe', value: 'Phalombe' },
  { label: 'Phalombe District', value: 'Phalombe District' },
  { label: 'Phalsbourg', value: 'Phalsbourg' },
  { label: 'Phaltan', value: 'Phaltan' },
  { label: 'Phan Rang-Tháp Chàm', value: 'Phan Rang-Tháp Chàm' },
  { label: 'Phan Thiết', value: 'Phan Thiết' },
  { label: 'Phan Thong', value: 'Phan Thong' },
  { label: 'Phanat Nikhom', value: 'Phanat Nikhom' },
  { label: 'Phang Nga', value: 'Phang Nga' },
  { label: 'Phanom Sarakham', value: 'Phanom Sarakham' },
  { label: 'Phanom Thuan', value: 'Phanom Thuan' },
  { label: 'Phaphamau', value: 'Phaphamau' },
  { label: 'Phaphund', value: 'Phaphund' },
  { label: 'Phariha', value: 'Phariha' },
  { label: 'Pharr', value: 'Pharr' },
  { label: 'Phasi Charoen', value: 'Phasi Charoen' },
  { label: 'Phatthalung', value: 'Phatthalung' },
  { label: 'Phaya Thai', value: 'Phaya Thai' },
  { label: 'Phayakkhaphum Phisai', value: 'Phayakkhaphum Phisai' },
  { label: 'Phayao', value: 'Phayao' },
  { label: 'Phayuha Khiri', value: 'Phayuha Khiri' },
  { label: 'Phek', value: 'Phek' },
  { label: 'Phelan', value: 'Phelan' },
  { label: 'Phelps', value: 'Phelps' },
  { label: 'Phelps County', value: 'Phelps County' },
  { label: 'Phelps County', value: 'Phelps County' },
  { label: 'Phenix City', value: 'Phenix City' },
  { label: 'Pheona', value: 'Pheona' },
  { label: 'Pherzawl', value: 'Pherzawl' },
  { label: 'Phetchabun', value: 'Phetchabun' },
  { label: 'Phetchaburi', value: 'Phetchaburi' },
  { label: 'Phibun Mangsahan', value: 'Phibun Mangsahan' },
  { label: 'Phichit', value: 'Phichit' },
  { label: 'Phil Campbell', value: 'Phil Campbell' },
  { label: 'Philadelphia', value: 'Philadelphia' },
  { label: 'Philadelphia', value: 'Philadelphia' },
  { label: 'Philadelphia', value: 'Philadelphia' },
  { label: 'Philadelphia', value: 'Philadelphia' },
  { label: 'Philadelphia County', value: 'Philadelphia County' },
  { label: 'Philip', value: 'Philip' },
  { label: 'Philippeville', value: 'Philippeville' },
  { label: 'Philippi', value: 'Philippi' },
  { label: 'Philippsburg', value: 'Philippsburg' },
  { label: 'Philippsthal', value: 'Philippsthal' },
  { label: 'Philipsburg', value: 'Philipsburg' },
  { label: 'Philipsburg', value: 'Philipsburg' },
  { label: 'Phillaur', value: 'Phillaur' },
  { label: 'Phillip', value: 'Phillip' },
  { label: 'Phillip', value: 'Phillip' },
  { label: 'Phillip Island', value: 'Phillip Island' },
  { label: 'Phillips', value: 'Phillips' },
  { label: 'Phillips', value: 'Phillips' },
  { label: 'Phillips County', value: 'Phillips County' },
  { label: 'Phillips County', value: 'Phillips County' },
  { label: 'Phillips County', value: 'Phillips County' },
  { label: 'Phillips County', value: 'Phillips County' },
  { label: 'Phillipsburg', value: 'Phillipsburg' },
  { label: 'Phillipsburg', value: 'Phillipsburg' },
  { label: 'Phillipston', value: 'Phillipston' },
  { label: 'Philmont', value: 'Philmont' },
  { label: 'Philo', value: 'Philo' },
  { label: 'Philomath', value: 'Philomath' },
  { label: 'Phimai', value: 'Phimai' },
  { label: 'Phippsburg', value: 'Phippsburg' },
  { label: 'Phirangipuram', value: 'Phirangipuram' },
  { label: 'Phitsanulok', value: 'Phitsanulok' },
  { label: 'Phnom Penh', value: 'Phnom Penh' },
  { label: 'Pho Chai', value: 'Pho Chai' },
  { label: 'Pho Thong', value: 'Pho Thong' },
  { label: 'Phoenix', value: 'Phoenix' },
  { label: 'Phoenix', value: 'Phoenix' },
  { label: 'Phoenix', value: 'Phoenix' },
  { label: 'Phoenix', value: 'Phoenix' },
  { label: 'Phoenix', value: 'Phoenix' },
  { label: 'Phoenix Lake', value: 'Phoenix Lake' },
  { label: 'Phoenixville', value: 'Phoenixville' },
  { label: 'Phon', value: 'Phon' },
  { label: 'Phon Charoen', value: 'Phon Charoen' },
  { label: 'Phôngsali', value: 'Phôngsali' },
  { label: 'Photharam', value: 'Photharam' },
  { label: 'Phra Khanong', value: 'Phra Khanong' },
  { label: 'Phra Nakhon', value: 'Phra Nakhon' },
  { label: 'Phra Nakhon Si Ayutthaya', value: 'Phra Nakhon Si Ayutthaya' },
  { label: 'Phra Phutthabat', value: 'Phra Phutthabat' },
  { label: 'Phra Pradaeng', value: 'Phra Pradaeng' },
  { label: 'Phrae', value: 'Phrae' },
  { label: 'Phrai Bueng', value: 'Phrai Bueng' },
  { label: 'Phu Khiao', value: 'Phu Khiao' },
  { label: 'Phú Khương', value: 'Phú Khương' },
  { label: 'Phu Kradueng', value: 'Phu Kradueng' },
  { label: 'Phủ Lý', value: 'Phủ Lý' },
  { label: 'Phuket', value: 'Phuket' },
  { label: 'Phulambri', value: 'Phulambri' },
  { label: 'Phulbani', value: 'Phulbani' },
  { label: 'Phulera', value: 'Phulera' },
  { label: 'Phulpur', value: 'Phulpur' },
  { label: 'Phumĭ Véal Srê', value: 'Phumĭ Véal Srê' },
  { label: 'Phuntsholing', value: 'Phuntsholing' },
  { label: 'Phuthaditjhaba', value: 'Phuthaditjhaba' },
  { label: 'Pia', value: 'Pia' },
  { label: 'Piaçabuçu', value: 'Piaçabuçu' },
  { label: 'Piacatu', value: 'Piacatu' },
  { label: 'Piacenza', value: 'Piacenza' },
  { label: 'Piacenza Dadige', value: 'Piacenza Dadige' },
  { label: 'Piadena', value: 'Piadena' },
  { label: 'Piagapo', value: 'Piagapo' },
  { label: 'Piagge', value: 'Piagge' },
  { label: 'Piaggine', value: 'Piaggine' },
  { label: 'Pialba', value: 'Pialba' },
  { label: 'Piamborno', value: 'Piamborno' },
  { label: 'Piamonte', value: 'Piamonte' },
  { label: 'Pian Camuno', value: 'Pian Camuno' },
  { label: 'Pian Di Mugnone', value: 'Pian Di Mugnone' },
  { label: 'Pian Di Scò', value: 'Pian Di Scò' },
  { label: 'Piana Battolla', value: 'Piana Battolla' },
  { label: 'Piana Crixia', value: 'Piana Crixia' },
  { label: 'Piana Degli Albanesi', value: 'Piana Degli Albanesi' },
  { label: 'Piana Di Monte Verna', value: 'Piana Di Monte Verna' },
  { label: 'Piana San Raffaele', value: 'Piana San Raffaele' },
  { label: 'Pianazzo', value: 'Pianazzo' },
  { label: 'Piancastagnaio', value: 'Piancastagnaio' },
  { label: 'Piancó', value: 'Piancó' },
  { label: 'Piandimeleto', value: 'Piandimeleto' },
  { label: 'Piane', value: 'Piane' },
  { label: 'Piane Crati', value: 'Piane Crati' },
  { label: 'Piane Di Montegiorgio', value: 'Piane Di Montegiorgio' },
  { label: 'Piane Di Morro', value: 'Piane Di Morro' },
  { label: 'Pianella', value: 'Pianella' },
  { label: 'Pianello', value: 'Pianello' },
  { label: 'Pianello Del Lario', value: 'Pianello Del Lario' },
  { label: 'Pianello Val Tidone', value: 'Pianello Val Tidone' },
  { label: 'Pianello Vallesina', value: 'Pianello Vallesina' },
  { label: 'Pianengo', value: 'Pianengo' },
  { label: 'Pianezza', value: 'Pianezza' },
  { label: 'Pianezze', value: 'Pianezze' },
  { label: 'Pianfei', value: 'Pianfei' },
  { label: 'Piangaiano', value: 'Piangaiano' },
  { label: 'Piangipane', value: 'Piangipane' },
  { label: 'Pianico', value: 'Pianico' },
  { label: 'Pianiga', value: 'Pianiga' },
  { label: 'Pianillo', value: 'Pianillo' },
  { label: 'Piano', value: 'Piano' },
  { label: 'Piano', value: 'Piano' },
  { label: 'Piano Dei Geli', value: 'Piano Dei Geli' },
  { label: 'Piano Di Conca', value: 'Piano Di Conca' },
  { label: 'Piano Di Coreglia-Ghivizzano', value: 'Piano Di Coreglia-Ghivizzano' },
  { label: 'Piano Di Follo', value: 'Piano Di Follo' },
  { label: 'Piano Di Mommio', value: 'Piano Di Mommio' },
  { label: 'Piano Di Sorrento', value: 'Piano Di Sorrento' },
  { label: 'Piano Maglio-Blandino', value: 'Piano Maglio-Blandino' },
  { label: 'Pianoconte', value: 'Pianoconte' },
  { label: 'Pianola', value: 'Pianola' },
  { label: 'Piano-Molini Disola', value: 'Piano-Molini Disola' },
  { label: 'Pianopoli', value: 'Pianopoli' },
  { label: 'Pianoro', value: 'Pianoro' },
  { label: 'Pians', value: 'Pians' },
  { label: 'Piansano', value: 'Piansano' },
  { label: 'Piantedo', value: 'Piantedo' },
  { label: 'Pianu De Jos', value: 'Pianu De Jos' },
  { label: 'Pianu De Sus', value: 'Pianu De Sus' },
  { label: 'Pianura', value: 'Pianura' },
  { label: 'Pianura Vomano', value: 'Pianura Vomano' },
  { label: 'Piape I', value: 'Piape I' },
  { label: 'Piara Waters', value: 'Piara Waters' },
  { label: 'Piario', value: 'Piario' },
  { label: 'Pias', value: 'Pias' },
  { label: 'Pias', value: 'Pias' },
  { label: 'Pías', value: 'Pías' },
  { label: 'Piasco', value: 'Piasco' },
  { label: 'Piaseczno', value: 'Piaseczno' },
  { label: 'Piasek', value: 'Piasek' },
  { label: 'Piaski', value: 'Piaski' },
  { label: 'Piaski', value: 'Piaski' },
  { label: 'Piastów', value: 'Piastów' },
  { label: 'Piat', value: 'Piat' },
  { label: 'Piat', value: 'Piat' },
  { label: 'Piatã', value: 'Piatã' },
  { label: 'Piateda Centro', value: 'Piateda Centro' },
  { label: 'Piątek', value: 'Piątek' },
  { label: 'Piątnica', value: 'Piątnica' },
  { label: 'Piatra', value: 'Piatra' },
  { label: 'Piatra', value: 'Piatra' },
  { label: 'Piatra', value: 'Piatra' },
  { label: 'Piatra', value: 'Piatra' },
  { label: 'Piatra Neamţ', value: 'Piatra Neamţ' },
  { label: 'Piatra Olt', value: 'Piatra Olt' },
  { label: 'Piatra Şoimului', value: 'Piatra Şoimului' },
  { label: 'Piatt County', value: 'Piatt County' },
  { label: 'Piatto', value: 'Piatto' },
  { label: 'Piattoni-Villa Santantonio', value: 'Piattoni-Villa Santantonio' },
  { label: 'Piatykhatky', value: 'Piatykhatky' },
  { label: 'Piau', value: 'Piau' },
  { label: 'Piavon', value: 'Piavon' },
  { label: 'Piaxtla', value: 'Piaxtla' },
  { label: 'Piaxtla De Abajo', value: 'Piaxtla De Abajo' },
  { label: 'Piaye', value: 'Piaye' },
  { label: 'Piazza', value: 'Piazza' },
  { label: 'Piazza', value: 'Piazza' },
  { label: 'Piazza', value: 'Piazza' },
  { label: 'Piazza', value: 'Piazza' },
  { label: 'Piazza Al Serchio-San Michele', value: 'Piazza Al Serchio-San Michele' },
  { label: 'Piazza Armerina', value: 'Piazza Armerina' },
  { label: 'Piazza Brembana', value: 'Piazza Brembana' },
  { label: 'Piazza Caduti', value: 'Piazza Caduti' },
  { label: 'Piazza Del Galdo-Santangelo', value: 'Piazza Del Galdo-Santangelo' },
  { label: 'Piazza Di Pandola', value: 'Piazza Di Pandola' },
  { label: 'Piazza Roma', value: 'Piazza Roma' },
  { label: 'Piazzatorre', value: 'Piazzatorre' },
  { label: 'Piazza-Tralia-Pendolo', value: 'Piazza-Tralia-Pendolo' },
  { label: 'Piazzola', value: 'Piazzola' },
  { label: 'Piazzola Sul Brenta', value: 'Piazzola Sul Brenta' },
  { label: 'Piazzolla', value: 'Piazzolla' },
  { label: 'Piazzolo', value: 'Piazzolo' },
  { label: 'Piberegg', value: 'Piberegg' },
  { label: 'Pibrac', value: 'Pibrac' },
  { label: 'Pica', value: 'Pica' },
  { label: 'Picada Café', value: 'Picada Café' },
  { label: 'Picada Gobernador López', value: 'Picada Gobernador López' },
  { label: 'Picanya', value: 'Picanya' },
  { label: 'Picardías', value: 'Picardías' },
  { label: 'Piçarra', value: 'Piçarra' },
  { label: 'Picassent', value: 'Picassent' },
  { label: 'Picauville', value: 'Picauville' },
  { label: 'Picayune', value: 'Picayune' },
  { label: 'Picazo El', value: 'Picazo El' },
  { label: 'Piccadilly', value: 'Piccadilly' },
  { label: 'Piccarello', value: 'Piccarello' },
  { label: 'Picciano', value: 'Picciano' },
  { label: 'Picerno', value: 'Picerno' },
  { label: 'Pich', value: 'Pich' },
  { label: 'Pichátaro', value: 'Pichátaro' },
  { label: 'Pichayevo', value: 'Pichayevo' },
  { label: 'Pichhaura', value: 'Pichhaura' },
  { label: 'Pichidegua', value: 'Pichidegua' },
  { label: 'Pichilemu', value: 'Pichilemu' },
  { label: 'Pichl', value: 'Pichl' },
  { label: 'Pichl Bei Wels', value: 'Pichl Bei Wels' },
  { label: 'Pichling Bei Köflach', value: 'Pichling Bei Köflach' },
  { label: 'Picholco', value: 'Picholco' },
  { label: 'Pichucalco', value: 'Pichucalco' },
  { label: 'Picinisco', value: 'Picinisco' },
  { label: 'Picior De Munte', value: 'Picior De Munte' },
  { label: 'Pickaway County', value: 'Pickaway County' },
  { label: 'Pickens', value: 'Pickens' },
  { label: 'Pickens', value: 'Pickens' },
  { label: 'Pickens County', value: 'Pickens County' },
  { label: 'Pickens County', value: 'Pickens County' },
  { label: 'Pickens County', value: 'Pickens County' },
  { label: 'Pickering', value: 'Pickering' },
  { label: 'Pickering', value: 'Pickering' },
  { label: 'Pickerington', value: 'Pickerington' },
  { label: 'Pickett County', value: 'Pickett County' },
  { label: 'Picnic Point', value: 'Picnic Point' },
  { label: 'Picnic Point', value: 'Picnic Point' },
  { label: 'Picnic Point-North Lynnwood', value: 'Picnic Point-North Lynnwood' },
  { label: 'Pico', value: 'Pico' },
  { label: 'Pico Rivera', value: 'Pico Rivera' },
  { label: 'Pico Truncado', value: 'Pico Truncado' },
  { label: 'Picón', value: 'Picón' },
  { label: 'Picos', value: 'Picos' },
  { label: 'Picota', value: 'Picota' },
  { label: 'Picquigny', value: 'Picquigny' },
  { label: 'Picsi', value: 'Picsi' },
  { label: 'Picton', value: 'Picton' },
  { label: 'Picton', value: 'Picton' },
  { label: 'Picton', value: 'Picton' },
  { label: 'Pictou', value: 'Pictou' },
  { label: 'Pictou County', value: 'Pictou County' },
  { label: 'Picture Butte', value: 'Picture Butte' },
  { label: 'Picture Rocks', value: 'Picture Rocks' },
  { label: 'Picuí', value: 'Picuí' },
  { label: 'Picún Leufú', value: 'Picún Leufú' },
  { label: 'Pidbuzh', value: 'Pidbuzh' },
  { label: 'Piddig', value: 'Piddig' },
  { label: 'Piddig', value: 'Piddig' },
  { label: 'Pidhaytsi', value: 'Pidhaytsi' },
  { label: 'Pidhirtsi', value: 'Pidhirtsi' },
  { label: 'Pidhorodna', value: 'Pidhorodna' },
  { label: 'Pidhorodne', value: 'Pidhorodne' },
  { label: 'Pidigan', value: 'Pidigan' },
  { label: 'Pidigan', value: 'Pidigan' },
  { label: 'Piding', value: 'Piding' },
  { label: 'Pidkamin’', value: 'Pidkamin’' },
  { label: 'Pidsandawan', value: 'Pidsandawan' },
  { label: 'Pidsandawan', value: 'Pidsandawan' },
  { label: 'Pidvolochysk', value: 'Pidvolochysk' },
  { label: 'Pidvynohradiv', value: 'Pidvynohradiv' },
  { label: 'Pie Creek', value: 'Pie Creek' },
  { label: 'Pie De Gallo', value: 'Pie De Gallo' },
  { label: 'Pie Del Colle', value: 'Pie Del Colle' },
  { label: 'Pie Del Sasso', value: 'Pie Del Sasso' },
  { label: 'Pie Falcade', value: 'Pie Falcade' },
  { label: 'Piea', value: 'Piea' },
  { label: 'Piechowice', value: 'Piechowice' },
  { label: 'Piecki', value: 'Piecki' },
  { label: 'Piedade', value: 'Piedade' },
  { label: 'Piedade', value: 'Piedade' },
  { label: 'Piedade De Caratinga', value: 'Piedade De Caratinga' },
  { label: 'Piedade De Ponte Nova', value: 'Piedade De Ponte Nova' },
  { label: 'Piedade Do Rio Grande', value: 'Piedade Do Rio Grande' },
  { label: 'Piedade Dos Gerais', value: 'Piedade Dos Gerais' },
  { label: 'Piedecuesta', value: 'Piedecuesta' },
  { label: 'Piedicavallo', value: 'Piedicavallo' },
  { label: 'Piedimonte', value: 'Piedimonte' },
  { label: 'Piedimonte Etneo', value: 'Piedimonte Etneo' },
  { label: 'Piedimonte Matese', value: 'Piedimonte Matese' },
  { label: 'Piedimonte San Germano', value: 'Piedimonte San Germano' },
  { label: 'Piedimonte San Germano Alta', value: 'Piedimonte San Germano Alta' },
  { label: 'Piedimulera', value: 'Piedimulera' },
  { label: 'Piediripa', value: 'Piediripa' },
  { label: 'Piedmont', value: 'Piedmont' },
  { label: 'Piedmont', value: 'Piedmont' },
  { label: 'Piedmont', value: 'Piedmont' },
  { label: 'Piedmont', value: 'Piedmont' },
  { label: 'Piedmont', value: 'Piedmont' },
  { label: 'Piedmont', value: 'Piedmont' },
  { label: 'Piedra Blanca', value: 'Piedra Blanca' },
  { label: 'Piedra Blanca', value: 'Piedra Blanca' },
  { label: 'Piedra De Amolar', value: 'Piedra De Amolar' },
  { label: 'Piedra Del Águila', value: 'Piedra Del Águila' },
  { label: 'Piedra Gorda', value: 'Piedra Gorda' },
  { label: 'Piedra Gorda', value: 'Piedra Gorda' },
  { label: 'Piedra Labrada', value: 'Piedra Labrada' },
  { label: 'Piedra Parada', value: 'Piedra Parada' },
  { label: 'Piedra Pesada', value: 'Piedra Pesada' },
  { label: 'Piedra Pinta', value: 'Piedra Pinta' },
  { label: 'Piedrabuena', value: 'Piedrabuena' },
  { label: 'Piedrahíta', value: 'Piedrahíta' },
  { label: 'Piedrahita De Castro', value: 'Piedrahita De Castro' },
  { label: 'Piedralaves', value: 'Piedralaves' },
  { label: 'Piedramillera', value: 'Piedramillera' },
  { label: 'Piedras', value: 'Piedras' },
  { label: 'Piedras Albas', value: 'Piedras Albas' },
  { label: 'Piedras Blancas', value: 'Piedras Blancas' },
  { label: 'Piedras Blancas', value: 'Piedras Blancas' },
  { label: 'Piedras Coloradas', value: 'Piedras Coloradas' },
  { label: 'Piedras De Lumbre', value: 'Piedras De Lumbre' },
  { label: 'Piedras Gordas', value: 'Piedras Gordas' },
  { label: 'Piedras Gordas', value: 'Piedras Gordas' },
  { label: 'Piedras Negras', value: 'Piedras Negras' },
  { label: 'Piedras Negras', value: 'Piedras Negras' },
  { label: 'Piedras Negras', value: 'Piedras Negras' },
  { label: 'Piedratajada', value: 'Piedratajada' },
  { label: 'Piegaro', value: 'Piegaro' },
  { label: 'Piegolelle-San Bartolomeo', value: 'Piegolelle-San Bartolomeo' },
  { label: 'Piégut-Pluviers', value: 'Piégut-Pluviers' },
  { label: 'Piekary', value: 'Piekary' },
  { label: 'Piekary Śląskie', value: 'Piekary Śląskie' },
  { label: 'Piekielnik', value: 'Piekielnik' },
  { label: 'Piekoszów', value: 'Piekoszów' },
  { label: 'Pieksämäki', value: 'Pieksämäki' },
  { label: 'Piélagos', value: 'Piélagos' },
  { label: 'Pielenhofen', value: 'Pielenhofen' },
  { label: 'Pieleştí', value: 'Pieleştí' },
  { label: 'Pielgrzymowice', value: 'Pielgrzymowice' },
  { label: 'Piên', value: 'Piên' },
  { label: 'Piendamo', value: 'Piendamo' },
  { label: 'Pieniężno', value: 'Pieniężno' },
  { label: 'Piennes', value: 'Piennes' },
  { label: 'Pieńsk', value: 'Pieńsk' },
  { label: 'Pienza', value: 'Pienza' },
  { label: 'Piera', value: 'Piera' },
  { label: 'Pieranica', value: 'Pieranica' },
  { label: 'Pierbach', value: 'Pierbach' },
  { label: 'Pierce', value: 'Pierce' },
  { label: 'Pierce City', value: 'Pierce City' },
  { label: 'Pierce County', value: 'Pierce County' },
  { label: 'Pierce County', value: 'Pierce County' },
  { label: 'Pierce County', value: 'Pierce County' },
  { label: 'Pierce County', value: 'Pierce County' },
  { label: 'Pierce County', value: 'Pierce County' },
  { label: 'Pierceton', value: 'Pierceton' },
  { label: 'Pieria-Prato Carnico', value: 'Pieria-Prato Carnico' },
  { label: 'Pierik', value: 'Pierik' },
  { label: 'Pieris', value: 'Pieris' },
  { label: 'Piermont', value: 'Piermont' },
  { label: 'Piérnigas', value: 'Piérnigas' },
  { label: 'Pierre', value: 'Pierre' },
  { label: 'Pierre Part', value: 'Pierre Part' },
  { label: 'Pierre-Bénite', value: 'Pierre-Bénite' },
  { label: 'Pierre-Buffière', value: 'Pierre-Buffière' },
  { label: 'Pierre-Châtel', value: 'Pierre-Châtel' },
  { label: 'Pierre-De-Bresse', value: 'Pierre-De-Bresse' },
  { label: 'Pierrefeu-Du-Var', value: 'Pierrefeu-Du-Var' },
  { label: 'Pierrefitte-Nestalas', value: 'Pierrefitte-Nestalas' },
  { label: 'Pierrefitte-Sur-Seine', value: 'Pierrefitte-Sur-Seine' },
  { label: 'Pierrefonds', value: 'Pierrefonds' },
  { label: 'Pierrefontaine-Les-Varans', value: 'Pierrefontaine-Les-Varans' },
  { label: 'Pierrefort', value: 'Pierrefort' },
  { label: 'Pierrelatte', value: 'Pierrelatte' },
  { label: 'Pierrelaye', value: 'Pierrelaye' },
  { label: 'Pierrepont', value: 'Pierrepont' },
  { label: 'Pierres', value: 'Pierres' },
  { label: 'Pierrevert', value: 'Pierrevert' },
  { label: 'Pierreville', value: 'Pierreville' },
  { label: 'Pierrevillers', value: 'Pierrevillers' },
  { label: 'Pierrot', value: 'Pierrot' },
  { label: 'Pierry', value: 'Pierry' },
  { label: 'Pierściec', value: 'Pierściec' },
  { label: 'Piershil', value: 'Piershil' },
  { label: 'Pierson', value: 'Pierson' },
  { label: 'Pierz', value: 'Pierz' },
  { label: 'Pierzchnica', value: 'Pierzchnica' },
  { label: 'Piesendorf', value: 'Piesendorf' },
  { label: 'Piesport', value: 'Piesport' },
  { label: 'Piešťany', value: 'Piešťany' },
  { label: 'Piesteritz', value: 'Piesteritz' },
  { label: 'Pieszyce', value: 'Pieszyce' },
  { label: 'Piet Retief', value: 'Piet Retief' },
  { label: 'Pietà', value: 'Pietà' },
  { label: 'Pieterlen', value: 'Pieterlen' },
  { label: 'Pietermaritzburg', value: 'Pietermaritzburg' },
  { label: 'Pietra De Giorgi', value: 'Pietra De Giorgi' },
  { label: 'Pietra La Croce', value: 'Pietra La Croce' },
  { label: 'Pietra Ligure', value: 'Pietra Ligure' },
  { label: 'Pietra Marazzi', value: 'Pietra Marazzi' },
  { label: 'Pietrabbondante', value: 'Pietrabbondante' },
  { label: 'Pietrabruna', value: 'Pietrabruna' },
  { label: 'Pietracamela', value: 'Pietracamela' },
  { label: 'Pietracatella', value: 'Pietracatella' },
  { label: 'Pietracupa', value: 'Pietracupa' },
  { label: 'Pietracuta', value: 'Pietracuta' },
  { label: 'Pietradefusi', value: 'Pietradefusi' },
  { label: 'Pietraferrazzana', value: 'Pietraferrazzana' },
  { label: 'Pietrafitta', value: 'Pietrafitta' },
  { label: 'Pietragalla', value: 'Pietragalla' },
  { label: 'Pietralunga', value: 'Pietralunga' },
  { label: 'Pietramelara', value: 'Pietramelara' },
  { label: 'Pietramontecorvino', value: 'Pietramontecorvino' },
  { label: 'Pietramurata', value: 'Pietramurata' },
  { label: 'Pietranera', value: 'Pietranera' },
  { label: 'Pietranico', value: 'Pietranico' },
  { label: 'Pietrapaola', value: 'Pietrapaola' },
  { label: 'Pietrapertosa', value: 'Pietrapertosa' },
  { label: 'Pietraperzia', value: 'Pietraperzia' },
  { label: 'Pietraporzio', value: 'Pietraporzio' },
  { label: 'Pietrari', value: 'Pietrari' },
  { label: 'Pietrari', value: 'Pietrari' },
  { label: 'Pietrarii De Sus', value: 'Pietrarii De Sus' },
  { label: 'Pietraroja', value: 'Pietraroja' },
  { label: 'Pietrasanta', value: 'Pietrasanta' },
  { label: 'Pietrastornina', value: 'Pietrastornina' },
  { label: 'Pietravairano', value: 'Pietravairano' },
  { label: 'Pietre', value: 'Pietre' },
  { label: 'Pietrelcina', value: 'Pietrelcina' },
  { label: 'Pietrele', value: 'Pietrele' },
  { label: 'Pietreni', value: 'Pietreni' },
  { label: 'Pietriceaua', value: 'Pietriceaua' },
  { label: 'Pietriș', value: 'Pietriș' },
  { label: 'Pietrișu', value: 'Pietrișu' },
  { label: 'Pietroasa', value: 'Pietroasa' },
  { label: 'Pietroasa', value: 'Pietroasa' },
  { label: 'Pietroasele', value: 'Pietroasele' },
  { label: 'Pietroşani', value: 'Pietroşani' },
  { label: 'Pietroşani', value: 'Pietroşani' },
  { label: 'Pietroșani', value: 'Pietroșani' },
  { label: 'Pietrosella', value: 'Pietrosella' },
  { label: 'Pietroşiţa', value: 'Pietroşiţa' },
  { label: 'Pietrosu', value: 'Pietrosu' },
  { label: 'Pietrosu', value: 'Pietrosu' },
  { label: 'Pietrowice Wielkie', value: 'Pietrowice Wielkie' },
  { label: 'Pietrzykowice', value: 'Pietrzykowice' },
  { label: 'Pieve', value: 'Pieve' },
  { label: 'Pieve', value: 'Pieve' },
  { label: 'Pieve A Nievole', value: 'Pieve A Nievole' },
  { label: 'Pieve Al Toppo', value: 'Pieve Al Toppo' },
  { label: 'Pieve Albignola', value: 'Pieve Albignola' },
  { label: 'Pieve Dalpago', value: 'Pieve Dalpago' },
  { label: 'Pieve Del Cairo', value: 'Pieve Del Cairo' },
  { label: 'Pieve Di Bono', value: 'Pieve Di Bono' },
  { label: 'Pieve Di Cadore', value: 'Pieve Di Cadore' },
  { label: 'Pieve Di Cento', value: 'Pieve Di Cento' },
  { label: 'Pieve Di Coriano', value: 'Pieve Di Coriano' },
  { label: 'Pieve Di Ledro', value: 'Pieve Di Ledro' },
  { label: 'Pieve Di Soligo', value: 'Pieve Di Soligo' },
  { label: 'Pieve Di Teco', value: 'Pieve Di Teco' },
  { label: 'Pieve Di Zignago', value: 'Pieve Di Zignago' },
  { label: 'Pieve Dolmi', value: 'Pieve Dolmi' },
  { label: 'Pieve Emanuele', value: 'Pieve Emanuele' },
  { label: 'Pieve Fissiraga', value: 'Pieve Fissiraga' },
  { label: 'Pieve Fosciana', value: 'Pieve Fosciana' },
  { label: 'Pieve Ligure', value: 'Pieve Ligure' },
  { label: 'Pieve Porto Morone', value: 'Pieve Porto Morone' },
  { label: 'Pieve San Giacomo', value: 'Pieve San Giacomo' },
  { label: 'Pieve Santo Stefano', value: 'Pieve Santo Stefano' },
  { label: 'Pieve Tesino', value: 'Pieve Tesino' },
  { label: 'Pieve Torina', value: 'Pieve Torina' },
  { label: 'Pieve Vergonte', value: 'Pieve Vergonte' },
  { label: 'Pievebovigliana', value: 'Pievebovigliana' },
  { label: 'Pievedizio', value: 'Pievedizio' },
  { label: 'Pievepelago', value: 'Pievepelago' },
  { label: 'Pigcawayan', value: 'Pigcawayan' },
  { label: 'Pigcawayan', value: 'Pigcawayan' },
  { label: 'Pigeon', value: 'Pigeon' },
  { label: 'Pigeon Forge', value: 'Pigeon Forge' },
  { label: 'Pigeon Island', value: 'Pigeon Island' },
  { label: 'Piggott', value: 'Piggott' },
  { label: 'Piggotts', value: 'Piggotts' },
  { label: 'Piggs Peak', value: 'Piggs Peak' },
  { label: 'Pigí', value: 'Pigí' },
  { label: 'Piglio', value: 'Piglio' },
  { label: 'Pigna', value: 'Pigna' },
  { label: 'Pignan', value: 'Pignan' },
  { label: 'Pignans', value: 'Pignans' },
  { label: 'Pignataro Interamna', value: 'Pignataro Interamna' },
  { label: 'Pignataro Maggiore', value: 'Pignataro Maggiore' },
  { label: 'Pignola', value: 'Pignola' },
  { label: 'Pignon', value: 'Pignon' },
  { label: 'Pignone', value: 'Pignone' },
  { label: 'Pigra', value: 'Pigra' },
  { label: 'Pihani', value: 'Pihani' },
  { label: 'Piherarh Municipality', value: 'Piherarh Municipality' },
  { label: 'Pihtipudas', value: 'Pihtipudas' },
  { label: 'Pihuamo', value: 'Pihuamo' },
  { label: 'Piikkiö', value: 'Piikkiö' },
  { label: 'Piippola', value: 'Piippola' },
  { label: 'Piis-Emwar Municipality', value: 'Piis-Emwar Municipality' },
  { label: 'Piis-Panewu Municipality', value: 'Piis-Panewu Municipality' },
  { label: 'Pijao', value: 'Pijao' },
  { label: 'Pijijiapan', value: 'Pijijiapan' },
  { label: 'Pijiño Del Carmen', value: 'Pijiño Del Carmen' },
  { label: 'Pijnacker', value: 'Pijnacker' },
  { label: 'Pikalëvo', value: 'Pikalëvo' },
  { label: 'Pike', value: 'Pike' },
  { label: 'Pike County', value: 'Pike County' },
  { label: 'Pike County', value: 'Pike County' },
  { label: 'Pike County', value: 'Pike County' },
  { label: 'Pike County', value: 'Pike County' },
  { label: 'Pike County', value: 'Pike County' },
  { label: 'Pike County', value: 'Pike County' },
  { label: 'Pike County', value: 'Pike County' },
  { label: 'Pike County', value: 'Pike County' },
  { label: 'Pike County', value: 'Pike County' },
  { label: 'Pike County', value: 'Pike County' },
  { label: 'Pike Creek', value: 'Pike Creek' },
  { label: 'Pike Creek Valley', value: 'Pike Creek Valley' },
  { label: 'Pike Road', value: 'Pike Road' },
  { label: 'Pikérmi', value: 'Pikérmi' },
  { label: 'Pikesville', value: 'Pikesville' },
  { label: 'Piketberg', value: 'Piketberg' },
  { label: 'Piketon', value: 'Piketon' },
  { label: 'Pikeville', value: 'Pikeville' },
  { label: 'Pikeville', value: 'Pikeville' },
  { label: 'Pikine', value: 'Pikine' },
  { label: 'Pikine Department', value: 'Pikine Department' },
  { label: 'Pikit', value: 'Pikit' },
  { label: 'Pikit', value: 'Pikit' },
  { label: 'Pil’Na', value: 'Pil’Na' },
  { label: 'Pil’Ninskiy Rayon', value: 'Pil’Ninskiy Rayon' },
  { label: 'Pila', value: 'Pila' },
  { label: 'Pila', value: 'Pila' },
  { label: 'Pila', value: 'Pila' },
  { label: 'Piła', value: 'Piła' },
  { label: 'Pilaitė', value: 'Pilaitė' },
  { label: 'Pilane', value: 'Pilane' },
  { label: 'Pilani', value: 'Pilani' },
  { label: 'Pilão Arcado', value: 'Pilão Arcado' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar', value: 'Pilar' },
  { label: 'Pilar De Goiás', value: 'Pilar De Goiás' },
  { label: 'Pilar De La Horadada', value: 'Pilar De La Horadada' },
  { label: 'Pilar Do Sul', value: 'Pilar Do Sul' },
  { label: 'Pilas', value: 'Pilas' },
  { label: 'Pilastro', value: 'Pilastro' },
  { label: 'Pilate', value: 'Pilate' },
  { label: 'Pilawa', value: 'Pilawa' },
  { label: 'Piława Górna', value: 'Piława Górna' },
  { label: 'Pilcaniyeu', value: 'Pilcaniyeu' },
  { label: 'Pilcaya', value: 'Pilcaya' },
  { label: 'Pilchowice', value: 'Pilchowice' },
  { label: 'Pilcomay', value: 'Pilcomay' },
  { label: 'Pilczyce', value: 'Pilczyce' },
  { label: 'Pildești', value: 'Pildești' },
  { label: 'Pileh Jin', value: 'Pileh Jin' },
  { label: 'Piles', value: 'Piles' },
  { label: 'Piles Les', value: 'Piles Les' },
  { label: 'Piletas', value: 'Piletas' },
  { label: 'Pilgersdorf', value: 'Pilgersdorf' },
  { label: 'Pili', value: 'Pili' },
  { label: 'Pili', value: 'Pili' },
  { label: 'Pili', value: 'Pili' },
  { label: 'Pilibangan', value: 'Pilibangan' },
  { label: 'Pilibhit', value: 'Pilibhit' },
  { label: 'Pilica', value: 'Pilica' },
  { label: 'Pilig', value: 'Pilig' },
  { label: 'Pilikwe', value: 'Pilikwe' },
  { label: 'Pililla', value: 'Pililla' },
  { label: 'Pilimathalawa', value: 'Pilimathalawa' },
  { label: 'Pilis', value: 'Pilis' },
  { label: 'Pilisborosjenő', value: 'Pilisborosjenő' },
  { label: 'Piliscsaba', value: 'Piliscsaba' },
  { label: 'Piliscsév', value: 'Piliscsév' },
  { label: 'Pilismarót', value: 'Pilismarót' },
  { label: 'Pilisszántó', value: 'Pilisszántó' },
  { label: 'Pilisszentiván', value: 'Pilisszentiván' },
  { label: 'Pilisszentkereszt', value: 'Pilisszentkereszt' },
  { label: 'Pilisvörösvár', value: 'Pilisvörösvár' },
  { label: 'Pilisvörösvári Járás', value: 'Pilisvörösvári Járás' },
  { label: 'Piliv', value: 'Piliv' },
  { label: 'Pilkhua', value: 'Pilkhua' },
  { label: 'Pilkhuwa', value: 'Pilkhuwa' },
  { label: 'Pill', value: 'Pill' },
  { label: 'Píllaro', value: 'Píllaro' },
  { label: 'Pillichsdorf', value: 'Pillichsdorf' },
  { label: 'Pilníkov', value: 'Pilníkov' },
  { label: 'Pilning', value: 'Pilning' },
  { label: 'Pilões', value: 'Pilões' },
  { label: 'Pilões', value: 'Pilões' },
  { label: 'Pilõezinhos', value: 'Pilõezinhos' },
  { label: 'Piloña', value: 'Piloña' },
  { label: 'Pilot Butte', value: 'Pilot Butte' },
  { label: 'Pilot Mountain', value: 'Pilot Mountain' },
  { label: 'Pilot Point', value: 'Pilot Point' },
  { label: 'Pilot Rock', value: 'Pilot Rock' },
  { label: 'Pilotos', value: 'Pilotos' },
  { label: 'Pilsach', value: 'Pilsach' },
  { label: 'Pilsen', value: 'Pilsen' },
  { label: 'Pilsley', value: 'Pilsley' },
  { label: 'Pilsrundāle', value: 'Pilsrundāle' },
  { label: 'Pilsting', value: 'Pilsting' },
  { label: 'Piltene', value: 'Piltene' },
  { label: 'Piltown', value: 'Piltown' },
  { label: 'Pilu', value: 'Pilu' },
  { label: 'Pilzno', value: 'Pilzno' },
  { label: 'Pima', value: 'Pima' },
  { label: 'Pima County', value: 'Pima County' },
  { label: 'Pimampiro', value: 'Pimampiro' },
  { label: 'Pimbalayan', value: 'Pimbalayan' },
  { label: 'Pimbalayan', value: 'Pimbalayan' },
  { label: 'Pimenta', value: 'Pimenta' },
  { label: 'Pimenta Bueno', value: 'Pimenta Bueno' },
  { label: 'Pimenteiras', value: 'Pimenteiras' },
  { label: 'Pimenteiras Do Oeste', value: 'Pimenteiras Do Oeste' },
  { label: 'Pimentel', value: 'Pimentel' },
  { label: 'Pimentel', value: 'Pimentel' },
  { label: 'Pimentel', value: 'Pimentel' },
  { label: 'Pimento Walk', value: 'Pimento Walk' },
  { label: 'Pimienta', value: 'Pimienta' },
  { label: 'Pimienta Vieja', value: 'Pimienta Vieja' },
  { label: 'Pimlico', value: 'Pimlico' },
  { label: 'Pimmit Hills', value: 'Pimmit Hills' },
  { label: 'Pimonte', value: 'Pimonte' },
  { label: 'Pimpalgaon Baswant', value: 'Pimpalgaon Baswant' },
  { label: 'Pimpalgaon Raja', value: 'Pimpalgaon Raja' },
  { label: 'Pimpama', value: 'Pimpama' },
  { label: 'Pimperne', value: 'Pimperne' },
  { label: 'Pimpri', value: 'Pimpri' },
  { label: 'Pimpri-Chinchwad', value: 'Pimpri-Chinchwad' },
  { label: 'Piña', value: 'Piña' },
  { label: 'Piña', value: 'Piña' },
  { label: 'Piña', value: 'Piña' },
  { label: 'Piña De Campos', value: 'Piña De Campos' },
  { label: 'Pina De Ebro', value: 'Pina De Ebro' },
  { label: 'Piña De Esgueva', value: 'Piña De Esgueva' },
  { label: 'Pina De Montalgrao', value: 'Pina De Montalgrao' },
  { label: 'Pinabacdao', value: 'Pinabacdao' },
  { label: 'Pinagsabangan', value: 'Pinagsabangan' },
  { label: 'Pinagsibaan', value: 'Pinagsibaan' },
  { label: 'Piñahan', value: 'Piñahan' },
  { label: 'Piñahan', value: 'Piñahan' },
  { label: 'Pinahat', value: 'Pinahat' },
  { label: 'Pinal County', value: 'Pinal County' },
  { label: 'Pinal De Amoles', value: 'Pinal De Amoles' },
  { label: 'Pinalejo', value: 'Pinalejo' },
  { label: 'Pinamalayan', value: 'Pinamalayan' },
  { label: 'Pinambaran', value: 'Pinambaran' },
  { label: 'Pinambaran', value: 'Pinambaran' },
  { label: 'Pinamopoan', value: 'Pinamopoan' },
  { label: 'Pinamopoan', value: 'Pinamopoan' },
  { label: 'Pinamungahan', value: 'Pinamungahan' },
  { label: 'Pinangomhan', value: 'Pinangomhan' },
  { label: 'Piñar', value: 'Piñar' },
  { label: 'Pinar De Chamartín', value: 'Pinar De Chamartín' },
  { label: 'Pinar De El Hierro El', value: 'Pinar De El Hierro El' },
  { label: 'Pinar Del Río', value: 'Pinar Del Río' },
  { label: 'Pinar El', value: 'Pinar El' },
  { label: 'Pınarbaşı', value: 'Pınarbaşı' },
  { label: 'Pınarbaşı', value: 'Pınarbaşı' },
  { label: 'Pinardville', value: 'Pinardville' },
  { label: 'Pinarejo', value: 'Pinarejo' },
  { label: 'Pinarejos', value: 'Pinarejos' },
  { label: 'Pinarella', value: 'Pinarella' },
  { label: 'Pınarhisar', value: 'Pınarhisar' },
  { label: 'Pinaring', value: 'Pinaring' },
  { label: 'Pinaring', value: 'Pinaring' },
  { label: 'Pinarnegrillo', value: 'Pinarnegrillo' },
  { label: 'Pinarolo Po', value: 'Pinarolo Po' },
  { label: 'Piñas', value: 'Piñas' },
  { label: 'Pinasca-Dubbione', value: 'Pinasca-Dubbione' },
  { label: 'Pinayagan Norte', value: 'Pinayagan Norte' },
  { label: 'Pincara', value: 'Pincara' },
  { label: 'Pincehely', value: 'Pincehely' },
  { label: 'Pinch', value: 'Pinch' },
  { label: 'Pinchbeck', value: 'Pinchbeck' },
  { label: 'Pincher Creek', value: 'Pincher Creek' },
  { label: 'Pinchote', value: 'Pinchote' },
  { label: 'Pinckney', value: 'Pinckney' },
  { label: 'Pinckneyville', value: 'Pinckneyville' },
  { label: 'Pinconning', value: 'Pinconning' },
  { label: 'Pincourt', value: 'Pincourt' },
  { label: 'Pińczów', value: 'Pińczów' },
  { label: 'Pind Dadan Khan', value: 'Pind Dadan Khan' },
  { label: 'Pindaí', value: 'Pindaí' },
  { label: 'Pindamonhangaba', value: 'Pindamonhangaba' },
  { label: 'Pindangan Centro', value: 'Pindangan Centro' },
  { label: 'Pindangan Centro', value: 'Pindangan Centro' },
  { label: 'Pindaré Mirim', value: 'Pindaré Mirim' },
  { label: 'Pindaré-Mirim', value: 'Pindaré-Mirim' },
  { label: 'Pindi Bhattian', value: 'Pindi Bhattian' },
  { label: 'Pindi Gheb', value: 'Pindi Gheb' },
  { label: 'Pindiga', value: 'Pindiga' },
  { label: 'Pindoba', value: 'Pindoba' },
  { label: 'Pindobaçu', value: 'Pindobaçu' },
  { label: 'Pindorama', value: 'Pindorama' },
  { label: 'Pindorama Do Tocantins', value: 'Pindorama Do Tocantins' },
  { label: 'Pindoretama', value: 'Pindoretama' },
  { label: 'Pindushi', value: 'Pindushi' },
  { label: 'Pindwara', value: 'Pindwara' },
  { label: 'Pine', value: 'Pine' },
  { label: 'Pine Beach', value: 'Pine Beach' },
  { label: 'Pine Bluff', value: 'Pine Bluff' },
  { label: 'Pine Bluffs', value: 'Pine Bluffs' },
  { label: 'Pine Bush', value: 'Pine Bush' },
  { label: 'Pine Castle', value: 'Pine Castle' },
  { label: 'Pine City', value: 'Pine City' },
  { label: 'Pine County', value: 'Pine County' },
  { label: 'Pine Crest', value: 'Pine Crest' },
  { label: 'Pine Grove', value: 'Pine Grove' },
  { label: 'Pine Grove', value: 'Pine Grove' },
  { label: 'Pine Grove Mills', value: 'Pine Grove Mills' },
  { label: 'Pine Hill', value: 'Pine Hill' },
  { label: 'Pine Hills', value: 'Pine Hills' },
  { label: 'Pine Hills', value: 'Pine Hills' },
  { label: 'Pine Island', value: 'Pine Island' },
  { label: 'Pine Island', value: 'Pine Island' },
  { label: 'Pine Island Center', value: 'Pine Island Center' },
  { label: 'Pine Island Ridge', value: 'Pine Island Ridge' },
  { label: 'Pine Knoll Shores', value: 'Pine Knoll Shores' },
  { label: 'Pine Knot', value: 'Pine Knot' },
  { label: 'Pine Lake Park', value: 'Pine Lake Park' },
  { label: 'Pine Lawn', value: 'Pine Lawn' },
  { label: 'Pine Level', value: 'Pine Level' },
  { label: 'Pine Level', value: 'Pine Level' },
  { label: 'Pine Manor', value: 'Pine Manor' },
  { label: 'Pine Mountain', value: 'Pine Mountain' },
  { label: 'Pine Mountain', value: 'Pine Mountain' },
  { label: 'Pine Mountain Club', value: 'Pine Mountain Club' },
  { label: 'Pine Plains', value: 'Pine Plains' },
  { label: 'Pine Prairie', value: 'Pine Prairie' },
  { label: 'Pine Ridge', value: 'Pine Ridge' },
  { label: 'Pine Ridge', value: 'Pine Ridge' },
  { label: 'Pine Ridge', value: 'Pine Ridge' },
  { label: 'Pine Ridge At Crestwood', value: 'Pine Ridge At Crestwood' },
  { label: 'Pine Valley', value: 'Pine Valley' },
  { label: 'Pinebluff', value: 'Pinebluff' },
  { label: 'Pinecrest', value: 'Pinecrest' },
  { label: 'Pineda De Gigüela', value: 'Pineda De Gigüela' },
  { label: 'Pineda De La Sierra', value: 'Pineda De La Sierra' },
  { label: 'Pineda De Mar', value: 'Pineda De Mar' },
  { label: 'Pineda Trasmonte', value: 'Pineda Trasmonte' },
  { label: 'Pinedale', value: 'Pinedale' },
  { label: 'Pinedas', value: 'Pinedas' },
  { label: 'Pinega', value: 'Pinega' },
  { label: 'Pinehurst', value: 'Pinehurst' },
  { label: 'Pinehurst', value: 'Pinehurst' },
  { label: 'Pinehurst', value: 'Pinehurst' },
  { label: 'Pinehurst', value: 'Pinehurst' },
  { label: 'Piñel De Abajo', value: 'Piñel De Abajo' },
  { label: 'Piñel De Arriba', value: 'Piñel De Arriba' },
  { label: 'Pinell De Brai', value: 'Pinell De Brai' },
  { label: 'Pinell De Solsonès', value: 'Pinell De Solsonès' },
  { label: 'Pinellas County', value: 'Pinellas County' },
  { label: 'Pinellas Park', value: 'Pinellas Park' },
  { label: 'Pineridge', value: 'Pineridge' },
  { label: 'Piñero El', value: 'Piñero El' },
  { label: 'Pinerolo', value: 'Pinerolo' },
  { label: 'Pinerovka', value: 'Pinerovka' },
  { label: 'Pines', value: 'Pines' },
  { label: 'Pinet', value: 'Pinet' },
  { label: 'Pinet', value: 'Pinet' },
  { label: 'Pineta', value: 'Pineta' },
  { label: 'Pineto', value: 'Pineto' },
  { label: 'Pinetop-Lakeside', value: 'Pinetop-Lakeside' },
  { label: 'Pinetops', value: 'Pinetops' },
  { label: 'Pineville', value: 'Pineville' },
  { label: 'Pineville', value: 'Pineville' },
  { label: 'Pineville', value: 'Pineville' },
  { label: 'Pineville', value: 'Pineville' },
  { label: 'Pineville', value: 'Pineville' },
  { label: 'Pinewood', value: 'Pinewood' },
  { label: 'Pinewood Estates', value: 'Pinewood Estates' },
  { label: 'Piney', value: 'Piney' },
  { label: 'Piney', value: 'Piney' },
  { label: 'Piney Green', value: 'Piney Green' },
  { label: 'Piney Point Village', value: 'Piney Point Village' },
  { label: 'Pinezhskiy Rayon', value: 'Pinezhskiy Rayon' },
  { label: 'Pingdingshan', value: 'Pingdingshan' },
  { label: 'Pingdu', value: 'Pingdu' },
  { label: 'Pingelap Municipality', value: 'Pingelap Municipality' },
  { label: 'Pingelly', value: 'Pingelly' },
  { label: 'Pinggau', value: 'Pinggau' },
  { label: 'Pingjiang', value: 'Pingjiang' },
  { label: 'Pingliang', value: 'Pingliang' },
  { label: 'Pingnan', value: 'Pingnan' },
  { label: 'Pingo-Dágua', value: 'Pingo-Dágua' },
  { label: 'Pingree Grove', value: 'Pingree Grove' },
  { label: 'Pingshan', value: 'Pingshan' },
  { label: 'Pingtung', value: 'Pingtung' },
  { label: 'Pingxiang', value: 'Pingxiang' },
  { label: 'Pingyi', value: 'Pingyi' },
  { label: 'Pingyin', value: 'Pingyin' },
  { label: 'Pingzhuang', value: 'Pingzhuang' },
  { label: 'Pinhais', value: 'Pinhais' },
  { label: 'Pinhal', value: 'Pinhal' },
  { label: 'Pinhal Da Serra', value: 'Pinhal Da Serra' },
  { label: 'Pinhal De São Bento', value: 'Pinhal De São Bento' },
  { label: 'Pinhal Grande', value: 'Pinhal Grande' },
  { label: 'Pinhal Novo', value: 'Pinhal Novo' },
  { label: 'Pinhalão', value: 'Pinhalão' },
  { label: 'Pinhalzinho', value: 'Pinhalzinho' },
  { label: 'Pinhalzinho', value: 'Pinhalzinho' },
  { label: 'Pinhanços', value: 'Pinhanços' },
  { label: 'Pinhão', value: 'Pinhão' },
  { label: 'Pinhão', value: 'Pinhão' },
  { label: 'Pinheiral', value: 'Pinheiral' },
  { label: 'Pinheirinho Do Vale', value: 'Pinheirinho Do Vale' },
  { label: 'Pinheiro', value: 'Pinheiro' },
  { label: 'Pinheiro', value: 'Pinheiro' },
  { label: 'Pinheiro Grande', value: 'Pinheiro Grande' },
  { label: 'Pinheiro Machado', value: 'Pinheiro Machado' },
  { label: 'Pinheiro Preto', value: 'Pinheiro Preto' },
  { label: 'Pinheiros', value: 'Pinheiros' },
  { label: 'Pinheiros', value: 'Pinheiros' },
  { label: 'Pinhel', value: 'Pinhel' },
  { label: 'Piñícuaro', value: 'Piñícuaro' },
  { label: 'Pinili', value: 'Pinili' },
  { label: 'Pinili', value: 'Pinili' },
  { label: 'Pinilla De Jadraque', value: 'Pinilla De Jadraque' },
  { label: 'Pinilla De Los Barruecos', value: 'Pinilla De Los Barruecos' },
  { label: 'Pinilla De Los Moros', value: 'Pinilla De Los Moros' },
  { label: 'Pinilla De Molina', value: 'Pinilla De Molina' },
  { label: 'Pinilla De Toro', value: 'Pinilla De Toro' },
  { label: 'Pinilla Del Campo', value: 'Pinilla Del Campo' },
  { label: 'Pinilla Del Valle', value: 'Pinilla Del Valle' },
  { label: 'Pinilla Trasmonte', value: 'Pinilla Trasmonte' },
  { label: 'Pinillos', value: 'Pinillos' },
  { label: 'Pinillos', value: 'Pinillos' },
  { label: 'Pinit', value: 'Pinit' },
  { label: 'Pinjarra', value: 'Pinjarra' },
  { label: 'Pinjaur', value: 'Pinjaur' },
  { label: 'Pink', value: 'Pink' },
  { label: 'Pinkafeld', value: 'Pinkafeld' },
  { label: 'Piņķi', value: 'Piņķi' },
  { label: 'Pinneberg', value: 'Pinneberg' },
  { label: 'Pinner', value: 'Pinner' },
  { label: 'Pinnow', value: 'Pinnow' },
  { label: 'Pino Dasti', value: 'Pino Dasti' },
  { label: 'Pino De Tormes El', value: 'Pino De Tormes El' },
  { label: 'Pino Del Oro', value: 'Pino Del Oro' },
  { label: 'Pino Del Río', value: 'Pino Del Río' },
  { label: 'Pino O', value: 'Pino O' },
  { label: 'Pino Suárez', value: 'Pino Suárez' },
  { label: 'Pino Sulla Sponda Del Lago Maggiore', value: 'Pino Sulla Sponda Del Lago Maggiore' },
  { label: 'Pino Torinese', value: 'Pino Torinese' },
  { label: 'Pinocchio Di Ancona', value: 'Pinocchio Di Ancona' },
  { label: 'Pinofranqueado', value: 'Pinofranqueado' },
  { label: 'Pinokawan', value: 'Pinokawan' },
  { label: 'Pinole', value: 'Pinole' },
  { label: 'Pinoma', value: 'Pinoma' },
  { label: 'Pinoma', value: 'Pinoma' },
  { label: 'Pinon', value: 'Pinon' },
  { label: 'Piñon Hills', value: 'Piñon Hills' },
  { label: 'Piñor', value: 'Piñor' },
  { label: 'Pinos', value: 'Pinos' },
  { label: 'Pinós', value: 'Pinós' },
  { label: 'Pinós El/Pinoso', value: 'Pinós El/Pinoso' },
  { label: 'Pinos Genil', value: 'Pinos Genil' },
  { label: 'Pinos Puente', value: 'Pinos Puente' },
  { label: 'Pinoso', value: 'Pinoso' },
  { label: 'Pinotepa De Don Luis', value: 'Pinotepa De Don Luis' },
  { label: 'Pinotepa Nacional', value: 'Pinotepa Nacional' },
  { label: 'Pinsaguel', value: 'Pinsaguel' },
  { label: 'Pinsdorf', value: 'Pinsdorf' },
  { label: 'Pinseque', value: 'Pinseque' },
  { label: 'Pinsk', value: 'Pinsk' },
  { label: 'Pinson', value: 'Pinson' },
  { label: 'Pintadas', value: 'Pintadas' },
  { label: 'Pintanos Los', value: 'Pintanos Los' },
  { label: 'Pintillo', value: 'Pintillo' },
  { label: 'Pinto', value: 'Pinto' },
  { label: 'Pinto', value: 'Pinto' },
  { label: 'Pinto Bandeira', value: 'Pinto Bandeira' },
  { label: 'Pintópolis', value: 'Pintópolis' },
  { label: 'Pintuyan', value: 'Pintuyan' },
  { label: 'Pintuyan', value: 'Pintuyan' },
  { label: 'Piñuécar-Gandullas', value: 'Piñuécar-Gandullas' },
  { label: 'Pinugay', value: 'Pinugay' },
  { label: 'Pinukpuk', value: 'Pinukpuk' },
  { label: 'Pinukpuk', value: 'Pinukpuk' },
  { label: 'Pinxton', value: 'Pinxton' },
  { label: 'Pinyug', value: 'Pinyug' },
  { label: 'Pinzándaro', value: 'Pinzándaro' },
  { label: 'Pinzano Al Tagliamento', value: 'Pinzano Al Tagliamento' },
  { label: 'Pinzberg', value: 'Pinzberg' },
  { label: 'Pínzio', value: 'Pínzio' },
  { label: 'Pinzolo', value: 'Pinzolo' },
  { label: 'Pio', value: 'Pio' },
  { label: 'Pio', value: 'Pio' },
  { label: 'Pio Duran', value: 'Pio Duran' },
  { label: 'Pio Ix', value: 'Pio Ix' },
  { label: 'Pio Xii', value: 'Pio Xii' },
  { label: 'Piobbico', value: 'Piobbico' },
  { label: 'Piobesi Dalba', value: 'Piobesi Dalba' },
  { label: 'Piobesi Torinese', value: 'Piobesi Torinese' },
  { label: 'Pioche', value: 'Pioche' },
  { label: 'Piode', value: 'Piode' },
  { label: 'Piojó', value: 'Piojó' },
  { label: 'Piolenc', value: 'Piolenc' },
  { label: 'Pioltello', value: 'Pioltello' },
  { label: 'Piombino', value: 'Piombino' },
  { label: 'Piombino Dese', value: 'Piombino Dese' },
  { label: 'Pionca', value: 'Pionca' },
  { label: 'Pioneer', value: 'Pioneer' },
  { label: 'Pioneer', value: 'Pioneer' },
  { label: 'Pioneer', value: 'Pioneer' },
  { label: 'Pioneer Village', value: 'Pioneer Village' },
  { label: 'Pioner', value: 'Pioner' },
  { label: 'Pionerskiy', value: 'Pionerskiy' },
  { label: 'Pionerskiy', value: 'Pionerskiy' },
  { label: 'Pionerskoye', value: 'Pionerskoye' },
  { label: 'Pionki', value: 'Pionki' },
  { label: 'Pionsat', value: 'Pionsat' },
  { label: 'Pioppo', value: 'Pioppo' },
  { label: 'Pioraco', value: 'Pioraco' },
  { label: 'Piornal', value: 'Piornal' },
  { label: 'Piossasco', value: 'Piossasco' },
  { label: 'Piotrków Kujawski', value: 'Piotrków Kujawski' },
  { label: 'Piotrków Trybunalski', value: 'Piotrków Trybunalski' },
  { label: 'Piovà Massaia', value: 'Piovà Massaia' },
  { label: 'Piove Di Sacco-Piovega', value: 'Piove Di Sacco-Piovega' },
  { label: 'Piovene Rocchette', value: 'Piovene Rocchette' },
  { label: 'Piovera', value: 'Piovera' },
  { label: 'Pioz', value: 'Pioz' },
  { label: 'Piozzano', value: 'Piozzano' },
  { label: 'Piozzo', value: 'Piozzo' },
  { label: 'Pipalkoti', value: 'Pipalkoti' },
  { label: 'Pipar', value: 'Pipar' },
  { label: 'Pipavav', value: 'Pipavav' },
  { label: 'Pipea', value: 'Pipea' },
  { label: 'Piperton', value: 'Piperton' },
  { label: 'Pipestone', value: 'Pipestone' },
  { label: 'Pipestone County', value: 'Pipestone County' },
  { label: 'Pipili', value: 'Pipili' },
  { label: 'Pipirig', value: 'Pipirig' },
  { label: 'Piplod', value: 'Piplod' },
  { label: 'Piploda', value: 'Piploda' },
  { label: 'Pippara', value: 'Pippara' },
  { label: 'Pipraich', value: 'Pipraich' },
  { label: 'Pipri', value: 'Pipri' },
  { label: 'Pipri', value: 'Pipri' },
  { label: 'Pipriac', value: 'Pipriac' },
  { label: 'Piqua', value: 'Piqua' },
  { label: 'Piqueras', value: 'Piqueras' },
  { label: 'Piqueras Del Castillo', value: 'Piqueras Del Castillo' },
  { label: 'Piquerobi', value: 'Piquerobi' },
  { label: 'Piquet Carneiro', value: 'Piquet Carneiro' },
  { label: 'Piquete', value: 'Piquete' },
  { label: 'Piquillín', value: 'Piquillín' },
  { label: 'Pir', value: 'Pir' },
  { label: 'Pir Anbar', value: 'Pir Anbar' },
  { label: 'Pir Badam', value: 'Pir Badam' },
  { label: 'Pir Bakran', value: 'Pir Bakran' },
  { label: 'Pir Jo Goth', value: 'Pir Jo Goth' },
  { label: 'Pir Khodaverdi', value: 'Pir Khodaverdi' },
  { label: 'Pir Mahal', value: 'Pir Mahal' },
  { label: 'Pir Malu', value: 'Pir Malu' },
  { label: 'Pir Mishan', value: 'Pir Mishan' },
  { label: 'Pir Savaran', value: 'Pir Savaran' },
  { label: 'Pir Taj', value: 'Pir Taj' },
  { label: 'Pir Yusof', value: 'Pir Yusof' },
  { label: 'Pira', value: 'Pira' },
  { label: 'Piracaia', value: 'Piracaia' },
  { label: 'Piracanjuba', value: 'Piracanjuba' },
  { label: 'Piracema', value: 'Piracema' },
  { label: 'Piracés', value: 'Piracés' },
  { label: 'Piracicaba', value: 'Piracicaba' },
  { label: 'Piracuruca', value: 'Piracuruca' },
  { label: 'Piraera', value: 'Piraera' },
  { label: 'Piraeus', value: 'Piraeus' },
  { label: 'Piraí', value: 'Piraí' },
  { label: 'Piraí Do Norte', value: 'Piraí Do Norte' },
  { label: 'Piraí Do Sul', value: 'Piraí Do Sul' },
  { label: 'Piraino', value: 'Piraino' },
  { label: 'Piraju', value: 'Piraju' },
  { label: 'Pirajuba', value: 'Pirajuba' },
  { label: 'Pirajuí', value: 'Pirajuí' },
  { label: 'Pirallahı', value: 'Pirallahı' },
  { label: 'Pirambu', value: 'Pirambu' },
  { label: 'Piran', value: 'Piran' },
  { label: 'Pirané', value: 'Pirané' },
  { label: 'Piranga', value: 'Piranga' },
  { label: 'Pirangi', value: 'Pirangi' },
  { label: 'Piranguçu', value: 'Piranguçu' },
  { label: 'Piranguinho', value: 'Piranguinho' },
  { label: 'Piranhas', value: 'Piranhas' },
  { label: 'Piranhas', value: 'Piranhas' },
  { label: 'Piranshahr', value: 'Piranshahr' },
  { label: 'Pirapemas', value: 'Pirapemas' },
  { label: 'Pirapetinga', value: 'Pirapetinga' },
  { label: 'Pirapó', value: 'Pirapó' },
  { label: 'Pirapó', value: 'Pirapó' },
  { label: 'Pirapora', value: 'Pirapora' },
  { label: 'Pirapora Do Bom Jesus', value: 'Pirapora Do Bom Jesus' },
  { label: 'Pirapozinho', value: 'Pirapozinho' },
  { label: 'Piraquara', value: 'Piraquara' },
  { label: 'Piraquê', value: 'Piraquê' },
  { label: 'Pirassununga', value: 'Pirassununga' },
  { label: 'Piratini', value: 'Piratini' },
  { label: 'Piratininga', value: 'Piratininga' },
  { label: 'Piratuba', value: 'Piratuba' },
  { label: 'Piraúba', value: 'Piraúba' },
  { label: 'Pirava', value: 'Pirava' },
  { label: 'Piravam', value: 'Piravam' },
  { label: 'Pirawa', value: 'Pirawa' },
  { label: 'Pirayú', value: 'Pirayú' },
  { label: 'Piraziz', value: 'Piraziz' },
  { label: 'Pirching Am Traubenberg', value: 'Pirching Am Traubenberg' },
  { label: 'Pircity', value: 'Pircity' },
  { label: 'Pirdop', value: 'Pirdop' },
  { label: 'Pirenópolis', value: 'Pirenópolis' },
  { label: 'Pires Do Rio', value: 'Pires Do Rio' },
  { label: 'Pires Ferreira', value: 'Pires Ferreira' },
  { label: 'Piré-Sur-Seiche', value: 'Piré-Sur-Seiche' },
  { label: 'Pirey', value: 'Pirey' },
  { label: 'Piriac-Sur-Mer', value: 'Piriac-Sur-Mer' },
  { label: 'Piriápolis', value: 'Piriápolis' },
  { label: 'Piribebuy', value: 'Piribebuy' },
  { label: 'Piringsdorf', value: 'Piringsdorf' },
  { label: 'Piripá', value: 'Piripá' },
  { label: 'Piripiri', value: 'Piripiri' },
  { label: 'Piritiba', value: 'Piritiba' },
  { label: 'Piriyapatna', value: 'Piriyapatna' },
  { label: 'Pirk', value: 'Pirk' },
  { label: 'Pirka', value: 'Pirka' },
  { label: 'Pirkkala', value: 'Pirkkala' },
  { label: 'Pirmasens', value: 'Pirmasens' },
  { label: 'Pirna', value: 'Pirna' },
  { label: 'Piro', value: 'Piro' },
  { label: 'Pirogovo', value: 'Pirogovo' },
  { label: 'Pirogovskiy', value: 'Pirogovskiy' },
  { label: 'Pirojpur ', value: 'Pirojpur ' },
  { label: 'Pirok', value: 'Pirok' },
  { label: 'Pirot', value: 'Pirot' },
  { label: 'Pirou', value: 'Pirou' },
  { label: 'Pirovac', value: 'Pirovac' },
  { label: 'Pirovskoye', value: 'Pirovskoye' },
  { label: 'Pirpirituba', value: 'Pirpirituba' },
  { label: 'Pirque', value: 'Pirque' },
  { label: 'Pirtleville', value: 'Pirtleville' },
  { label: 'Pirton', value: 'Pirton' },
  { label: 'Piru', value: 'Piru' },
  { label: 'Piruz', value: 'Piruz' },
  { label: 'Pisa', value: 'Pisa' },
  { label: 'Pisac', value: 'Pisac' },
  { label: 'Pisaflores', value: 'Pisaflores' },
  { label: 'Pisaflores', value: 'Pisaflores' },
  { label: 'Pisano', value: 'Pisano' },
  { label: 'Pisarivshchina', value: 'Pisarivshchina' },
  { label: 'Pisarzowice', value: 'Pisarzowice' },
  { label: 'Pisarzowice', value: 'Pisarzowice' },
  { label: 'Pisba', value: 'Pisba' },
  { label: 'Piscataquis County', value: 'Piscataquis County' },
  { label: 'Piscataway', value: 'Piscataway' },
  { label: 'Pischeldorf', value: 'Pischeldorf' },
  { label: 'Pischelsdorf In Der Steiermark', value: 'Pischelsdorf In Der Steiermark' },
  { label: 'Pişchia', value: 'Pişchia' },
  { label: 'Piscila', value: 'Piscila' },
  { label: 'Piscina', value: 'Piscina' },
  { label: 'Piscinas', value: 'Piscinas' },
  { label: 'Piscinola', value: 'Piscinola' },
  { label: 'Pisciotta', value: 'Pisciotta' },
  { label: 'Pisco', value: 'Pisco' },
  { label: 'Piscoiu', value: 'Piscoiu' },
  { label: 'Pişcolt', value: 'Pişcolt' },
  { label: 'Piscopio', value: 'Piscopio' },
  { label: 'Piscu', value: 'Piscu' },
  { label: 'Piscu Nou', value: 'Piscu Nou' },
  { label: 'Piscu Sadovei', value: 'Piscu Sadovei' },
  { label: 'Piscu Vechi', value: 'Piscu Vechi' },
  { label: 'Písečná', value: 'Písečná' },
  { label: 'Písek', value: 'Písek' },
  { label: 'Písek', value: 'Písek' },
  { label: 'Pisgah', value: 'Pisgah' },
  { label: 'Pish Ghaleh', value: 'Pish Ghaleh' },
  { label: 'Pishcha', value: 'Pishcha' },
  { label: 'Pishchiv', value: 'Pishchiv' },
  { label: 'Pishin', value: 'Pishin' },
  { label: 'Pishin', value: 'Pishin' },
  { label: 'Pishva', value: 'Pishva' },
  { label: 'Pisignano', value: 'Pisignano' },
  { label: 'Pisignano', value: 'Pisignano' },
  { label: 'Piskavica', value: 'Piskavica' },
  { label: 'Piskent', value: 'Piskent' },
  { label: 'Piskivka', value: 'Piskivka' },
  { label: 'Piškorevci', value: 'Piškorevci' },
  { label: 'Piskorowice', value: 'Piskorowice' },
  { label: 'Pismo Beach', value: 'Pismo Beach' },
  { label: 'Pisogne', value: 'Pisogne' },
  { label: 'Pisoniano', value: 'Pisoniano' },
  { label: 'Pispala', value: 'Pispala' },
  { label: 'Pissos', value: 'Pissos' },
  { label: 'Pissotte', value: 'Pissotte' },
  { label: 'Pissoúri', value: 'Pissoúri' },
  { label: 'Pissy-Pôville', value: 'Pissy-Pôville' },
  { label: 'Píšť', value: 'Píšť' },
  { label: 'Pistakee Highlands', value: 'Pistakee Highlands' },
  { label: 'Piste', value: 'Piste' },
  { label: 'Pisticci', value: 'Pisticci' },
  { label: 'Pistoia', value: 'Pistoia' },
  { label: 'Pistorf', value: 'Pistorf' },
  { label: 'Pistrino', value: 'Pistrino' },
  { label: 'Pistsovo', value: 'Pistsovo' },
  { label: 'Pisz', value: 'Pisz' },
  { label: 'Piszczac', value: 'Piszczac' },
  { label: 'Pita', value: 'Pita' },
  { label: 'Pita Kotte', value: 'Pita Kotte' },
  { label: 'Pitahayal', value: 'Pitahayal' },
  { label: 'Pital', value: 'Pital' },
  { label: 'Pital', value: 'Pital' },
  { label: 'Pitalito', value: 'Pitalito' },
  { label: 'Pitaloza Arriba', value: 'Pitaloza Arriba' },
  { label: 'Pitampura', value: 'Pitampura' },
  { label: 'Pitanga', value: 'Pitanga' },
  { label: 'Pitangueiras', value: 'Pitangueiras' },
  { label: 'Pitangueiras', value: 'Pitangueiras' },
  { label: 'Pitangui', value: 'Pitangui' },
  { label: 'Pitarque', value: 'Pitarque' },
  { label: 'Pitaru', value: 'Pitaru' },
  { label: 'Pitcairn', value: 'Pitcairn' },
  { label: 'Piteå', value: 'Piteå' },
  { label: 'Piteasca', value: 'Piteasca' },
  { label: 'Piteglio', value: 'Piteglio' },
  { label: 'Pitelino', value: 'Pitelino' },
  { label: 'Pitelli', value: 'Pitelli' },
  { label: 'Piterka', value: 'Piterka' },
  { label: 'Piteşti', value: 'Piteşti' },
  { label: 'Pitfour', value: 'Pitfour' },
  { label: 'Pithampur', value: 'Pithampur' },
  { label: 'Pithapuram', value: 'Pithapuram' },
  { label: 'Pithári', value: 'Pithári' },
  { label: 'Pithiviers', value: 'Pithiviers' },
  { label: 'Pithiviers-Le-Vieil', value: 'Pithiviers-Le-Vieil' },
  { label: 'Pithora', value: 'Pithora' },
  { label: 'Pithoragarh', value: 'Pithoragarh' },
  { label: 'Pithoro', value: 'Pithoro' },
  { label: 'Pitiegua', value: 'Pitiegua' },
  { label: 'Pitigliano', value: 'Pitigliano' },
  { label: 'Pitillas', value: 'Pitillas' },
  { label: 'Pitimbu', value: 'Pitimbu' },
  { label: 'Pitiquito', value: 'Pitiquito' },
  { label: 'Pitkin County', value: 'Pitkin County' },
  { label: 'Pitkyaranta', value: 'Pitkyaranta' },
  { label: 'Pitkyarantskiy Rayon', value: 'Pitkyarantskiy Rayon' },
  { label: 'Pitlochry', value: 'Pitlochry' },
  { label: 'Pitman', value: 'Pitman' },
  { label: 'Pitmedden', value: 'Pitmedden' },
  { label: 'Pitmoaga', value: 'Pitmoaga' },
  { label: 'Pitoa', value: 'Pitoa' },
  { label: 'Pitogo', value: 'Pitogo' },
  { label: 'Pitogo', value: 'Pitogo' },
  { label: 'Pitogo', value: 'Pitogo' },
  { label: 'Pitogo', value: 'Pitogo' },
  { label: 'Pitogo', value: 'Pitogo' },
  { label: 'Pitogo', value: 'Pitogo' },
  { label: 'Pitomača', value: 'Pitomača' },
  { label: 'Piton', value: 'Piton' },
  { label: 'Pîtres', value: 'Pîtres' },
  { label: 'Pitrufquén', value: 'Pitrufquén' },
  { label: 'Pitschgau', value: 'Pitschgau' },
  { label: 'Pitsea', value: 'Pitsea' },
  { label: 'Pitstone', value: 'Pitstone' },
  { label: 'Pitt County', value: 'Pitt County' },
  { label: 'Pitt Meadows', value: 'Pitt Meadows' },
  { label: 'Pitt Town', value: 'Pitt Town' },
  { label: 'Pittem', value: 'Pittem' },
  { label: 'Pitten', value: 'Pitten' },
  { label: 'Pittenhart', value: 'Pittenhart' },
  { label: 'Pittenweem', value: 'Pittenweem' },
  { label: 'Pittsboro', value: 'Pittsboro' },
  { label: 'Pittsboro', value: 'Pittsboro' },
  { label: 'Pittsboro', value: 'Pittsboro' },
  { label: 'Pittsburg', value: 'Pittsburg' },
  { label: 'Pittsburg', value: 'Pittsburg' },
  { label: 'Pittsburg', value: 'Pittsburg' },
  { label: 'Pittsburg County', value: 'Pittsburg County' },
  { label: 'Pittsburgh', value: 'Pittsburgh' },
  { label: 'Pittsfield', value: 'Pittsfield' },
  { label: 'Pittsfield', value: 'Pittsfield' },
  { label: 'Pittsfield', value: 'Pittsfield' },
  { label: 'Pittsfield', value: 'Pittsfield' },
  { label: 'Pittsfield', value: 'Pittsfield' },
  { label: 'Pittsford', value: 'Pittsford' },
  { label: 'Pittston', value: 'Pittston' },
  { label: 'Pittston', value: 'Pittston' },
  { label: 'Pittsville', value: 'Pittsville' },
  { label: 'Pittsworth', value: 'Pittsworth' },
  { label: 'Pittsylvania County', value: 'Pittsylvania County' },
  { label: 'Pitzenberg', value: 'Pitzenberg' },
  { label: 'Piubega', value: 'Piubega' },
  { label: 'Piuí', value: 'Piuí' },
  { label: 'Pium', value: 'Pium' },
  { label: 'Piúma', value: 'Piúma' },
  { label: 'Piumazzo', value: 'Piumazzo' },
  { label: 'Piumhi', value: 'Piumhi' },
  { label: 'Piura', value: 'Piura' },
  { label: 'Piuro', value: 'Piuro' },
  { label: 'Piute County', value: 'Piute County' },
  { label: 'Pivdenne', value: 'Pivdenne' },
  { label: 'Piverone', value: 'Piverone' },
  { label: 'Pivijay', value: 'Pivijay' },
  { label: 'Pivka', value: 'Pivka' },
  { label: 'Pivovarikha', value: 'Pivovarikha' },
  { label: 'Piwniczna-Zdrój', value: 'Piwniczna-Zdrój' },
  { label: 'Pixley', value: 'Pixley' },
  { label: 'Pixley Ka Seme District Municipality', value: 'Pixley Ka Seme District Municipality' },
  { label: 'Pixoy', value: 'Pixoy' },
  { label: 'Pizarra', value: 'Pizarra' },
  { label: 'Pizarral', value: 'Pizarral' },
  { label: 'Pizarrete', value: 'Pizarrete' },
  { label: 'Pizhanka', value: 'Pizhanka' },
  { label: 'Pizhanskiy Rayon', value: 'Pizhanskiy Rayon' },
  { label: 'Pizhma', value: 'Pizhma' },
  { label: 'Pizhou', value: 'Pizhou' },
  { label: 'Pizitou', value: 'Pizitou' },
  { label: 'Pizzale', value: 'Pizzale' },
  { label: 'Pizzano', value: 'Pizzano' },
  { label: 'Pizzighettone', value: 'Pizzighettone' },
  { label: 'Pizzo', value: 'Pizzo' },
  { label: 'Pizzoferrato', value: 'Pizzoferrato' },
  { label: 'Pizzoli', value: 'Pizzoli' },
  { label: 'Pizzone', value: 'Pizzone' },
  { label: 'Pizzoni', value: 'Pizzoni' },
  { label: 'Pjanići', value: 'Pjanići' },
  { label: 'Pla De Santa Maria', value: 'Pla De Santa Maria' },
  { label: 'Pla Del Penedès El', value: 'Pla Del Penedès El' },
  { label: 'Plabennec', value: 'Plabennec' },
  { label: 'Placanica', value: 'Placanica' },
  { label: 'Placas', value: 'Placas' },
  { label: 'Placencia', value: 'Placencia' },
  { label: 'Placentia', value: 'Placentia' },
  { label: 'Placer', value: 'Placer' },
  { label: 'Placer', value: 'Placer' },
  { label: 'Placer', value: 'Placer' },
  { label: 'Placer County', value: 'Placer County' },
  { label: 'Placeres Del Oro', value: 'Placeres Del Oro' },
  { label: 'Placerville', value: 'Placerville' },
  { label: 'Placetas', value: 'Placetas' },
  { label: 'Placid Lakes', value: 'Placid Lakes' },
  { label: 'Plácido De Castro', value: 'Plácido De Castro' },
  { label: 'Placilla', value: 'Placilla' },
  { label: 'Placitas', value: 'Placitas' },
  { label: 'Plaffeien', value: 'Plaffeien' },
  { label: 'Plagiári', value: 'Plagiári' },
  { label: 'Plaidt', value: 'Plaidt' },
  { label: 'Plailly', value: 'Plailly' },
  { label: 'Plaimpied-Givaudins', value: 'Plaimpied-Givaudins' },
  { label: 'Plain City', value: 'Plain City' },
  { label: 'Plain City', value: 'Plain City' },
  { label: 'Plain View', value: 'Plain View' },
  { label: 'Plaine Des Papayes', value: 'Plaine Des Papayes' },
  { label: 'Plaine Du Nord', value: 'Plaine Du Nord' },
  { label: 'Plaine Magnien', value: 'Plaine Magnien' },
  { label: 'Plaine Verte', value: 'Plaine Verte' },
  { label: 'Plainedge', value: 'Plainedge' },
  { label: 'Plaine-Haute', value: 'Plaine-Haute' },
  { label: 'Plaines Des Roches', value: 'Plaines Des Roches' },
  { label: 'Plainfaing', value: 'Plainfaing' },
  { label: 'Plainfeld', value: 'Plainfeld' },
  { label: 'Plainfield', value: 'Plainfield' },
  { label: 'Plainfield', value: 'Plainfield' },
  { label: 'Plainfield', value: 'Plainfield' },
  { label: 'Plainfield', value: 'Plainfield' },
  { label: 'Plainfield Village', value: 'Plainfield Village' },
  { label: 'Plainland', value: 'Plainland' },
  { label: 'Plains', value: 'Plains' },
  { label: 'Plains', value: 'Plains' },
  { label: 'Plains', value: 'Plains' },
  { label: 'Plains', value: 'Plains' },
  { label: 'Plains', value: 'Plains' },
  { label: 'Plainsboro Center', value: 'Plainsboro Center' },
  { label: 'Plaintel', value: 'Plaintel' },
  { label: 'Plainview', value: 'Plainview' },
  { label: 'Plainview', value: 'Plainview' },
  { label: 'Plainview', value: 'Plainview' },
  { label: 'Plainview', value: 'Plainview' },
  { label: 'Plainview', value: 'Plainview' },
  { label: 'Plainville', value: 'Plainville' },
  { label: 'Plainville', value: 'Plainville' },
  { label: 'Plainville', value: 'Plainville' },
  { label: 'Plainwell', value: 'Plainwell' },
  { label: 'Plaisance', value: 'Plaisance' },
  { label: 'Plaisance', value: 'Plaisance' },
  { label: 'Plaisance-Du-Touch', value: 'Plaisance-Du-Touch' },
  { label: 'Plaisir', value: 'Plaisir' },
  { label: 'Plaistow', value: 'Plaistow' },
  { label: 'Pláka Dílesi', value: 'Pláka Dílesi' },
  { label: 'Plakhtiyivka', value: 'Plakhtiyivka' },
  { label: 'Plan', value: 'Plan' },
  { label: 'Plan De Agua Prieta', value: 'Plan De Agua Prieta' },
  { label: 'Plan De Arroyos', value: 'Plan De Arroyos' },
  { label: 'Plan De Ayala', value: 'Plan De Ayala' },
  { label: 'Plan De Ayala', value: 'Plan De Ayala' },
  { label: 'Plan De Ayala', value: 'Plan De Ayala' },
  { label: 'Plan De Ayala', value: 'Plan De Ayala' },
  { label: 'Plan De Ayala (Campo Cinco)', value: 'Plan De Ayala (Campo Cinco)' },
  { label: 'Plan De Chorcha', value: 'Plan De Chorcha' },
  { label: 'Plan De Iguala', value: 'Plan De Iguala' },
  { label: 'Plan De Las Hayas', value: 'Plan De Las Hayas' },
  { label: 'Plan Del Río', value: 'Plan Del Río' },
  { label: 'Plan Dintrod', value: 'Plan Dintrod' },
  { label: 'Plan Guanajuato (La Sandía)', value: 'Plan Guanajuato (La Sandía)' },
  { label: 'Planá', value: 'Planá' },
  { label: 'Planá Nad Lužnicí', value: 'Planá Nad Lužnicí' },
  { label: 'Planada', value: 'Planada' },
  { label: 'Planadas', value: 'Planadas' },
  { label: 'Planaltina', value: 'Planaltina' },
  { label: 'Planaltina', value: 'Planaltina' },
  { label: 'Planaltina Do Paraná', value: 'Planaltina Do Paraná' },
  { label: 'Planaltino', value: 'Planaltino' },
  { label: 'Planalto', value: 'Planalto' },
  { label: 'Planalto', value: 'Planalto' },
  { label: 'Planalto', value: 'Planalto' },
  { label: 'Planalto', value: 'Planalto' },
  { label: 'Planalto Alegre', value: 'Planalto Alegre' },
  { label: 'Planalto Da Serra', value: 'Planalto Da Serra' },
  { label: 'Plaňany', value: 'Plaňany' },
  { label: 'Planard', value: 'Planard' },
  { label: 'Plancarte', value: 'Plancarte' },
  { label: 'Plancher-Bas', value: 'Plancher-Bas' },
  { label: 'Plancher-Les-Mines', value: 'Plancher-Les-Mines' },
  { label: 'Plancoët', value: 'Plancoët' },
  { label: 'Plan-Daups-Sainte-Baume', value: 'Plan-Daups-Sainte-Baume' },
  { label: 'Plan-De-Cuques', value: 'Plan-De-Cuques' },
  { label: 'Plandište', value: 'Plandište' },
  { label: 'Plandome', value: 'Plandome' },
  { label: 'Plandome Heights', value: 'Plandome Heights' },
  { label: 'Planegg', value: 'Planegg' },
  { label: 'Planes', value: 'Planes' },
  { label: 'Planes Dhostoles Les', value: 'Planes Dhostoles Les' },
  { label: 'Planeta Rica', value: 'Planeta Rica' },
  { label: 'Planguenoual', value: 'Planguenoual' },
  { label: 'Plánice', value: 'Plánice' },
  { label: 'Planken', value: 'Planken' },
  { label: 'Plankenau', value: 'Plankenau' },
  { label: 'Plankinton', value: 'Plankinton' },
  { label: 'Plankstadt', value: 'Plankstadt' },
  { label: 'Plan-Les-Ouates', value: 'Plan-Les-Ouates' },
  { label: 'Plano', value: 'Plano' },
  { label: 'Plano', value: 'Plano' },
  { label: 'Plano', value: 'Plano' },
  { label: 'Planoles', value: 'Planoles' },
  { label: 'Planovskoye', value: 'Planovskoye' },
  { label: 'Plans De Sió Els', value: 'Plans De Sió Els' },
  { label: 'Plant City', value: 'Plant City' },
  { label: 'Plantagenet', value: 'Plantagenet' },
  { label: 'Plantagenet Shire', value: 'Plantagenet Shire' },
  { label: 'Plantation', value: 'Plantation' },
  { label: 'Plantation Mobile Home Park', value: 'Plantation Mobile Home Park' },
  { label: 'Plantersville', value: 'Plantersville' },
  { label: 'Plänterwald', value: 'Plänterwald' },
  { label: 'Planura', value: 'Planura' },
  { label: 'Plappeville', value: 'Plappeville' },
  { label: 'Plaquemine', value: 'Plaquemine' },
  { label: 'Plaquemines Parish', value: 'Plaquemines Parish' },
  { label: 'Plaridel', value: 'Plaridel' },
  { label: 'Plaridel', value: 'Plaridel' },
  { label: 'Plaridel', value: 'Plaridel' },
  { label: 'Plaridel', value: 'Plaridel' },
  { label: 'Plaridel', value: 'Plaridel' },
  { label: 'Plaridel', value: 'Plaridel' },
  { label: 'Plaridel', value: 'Plaridel' },
  { label: 'Plaridel', value: 'Plaridel' },
  { label: 'Plasencia', value: 'Plasencia' },
  { label: 'Plasencia De Jalón', value: 'Plasencia De Jalón' },
  { label: 'Plasenzuela', value: 'Plasenzuela' },
  { label: 'Plasnica', value: 'Plasnica' },
  { label: 'Plast', value: 'Plast' },
  { label: 'Plastun', value: 'Plastun' },
  { label: 'Plastunovskaya', value: 'Plastunovskaya' },
  { label: 'Plasy', value: 'Plasy' },
  { label: 'Plat Pays', value: 'Plat Pays' },
  { label: 'Plataci', value: 'Plataci' },
  { label: 'Platagata', value: 'Platagata' },
  { label: 'Platania', value: 'Platania' },
  { label: 'Platanillo', value: 'Platanillo' },
  { label: 'Platanórevma', value: 'Platanórevma' },
  { label: 'Plátanos', value: 'Plátanos' },
  { label: 'Plătăreşti', value: 'Plătăreşti' },
  { label: 'Platariá', value: 'Platariá' },
  { label: 'Plate', value: 'Plate' },
  { label: 'Plateau', value: 'Plateau' },
  { label: 'Plateliai', value: 'Plateliai' },
  { label: 'Plateros', value: 'Plateros' },
  { label: 'Platì', value: 'Platì' },
  { label: 'Platičevo', value: 'Platičevo' },
  { label: 'Platina', value: 'Platina' },
  { label: 'Platnirovskaya', value: 'Platnirovskaya' },
  { label: 'Plato', value: 'Plato' },
  { label: 'Platón Sánchez', value: 'Platón Sánchez' },
  { label: 'Platonești', value: 'Platonești' },
  { label: 'Platonovka', value: 'Platonovka' },
  { label: 'Platte', value: 'Platte' },
  { label: 'Platte City', value: 'Platte City' },
  { label: 'Platte County', value: 'Platte County' },
  { label: 'Platte County', value: 'Platte County' },
  { label: 'Platte County', value: 'Platte County' },
  { label: 'Plattekill', value: 'Plattekill' },
  { label: 'Plattenburg', value: 'Plattenburg' },
  { label: 'Platteville', value: 'Platteville' },
  { label: 'Platteville', value: 'Platteville' },
  { label: 'Plattling', value: 'Plattling' },
  { label: 'Plattsburg', value: 'Plattsburg' },
  { label: 'Plattsburgh', value: 'Plattsburgh' },
  { label: 'Plattsburgh West', value: 'Plattsburgh West' },
  { label: 'Plattsmouth', value: 'Plattsmouth' },
  { label: 'Plattsville', value: 'Plattsville' },
  { label: 'Platý', value: 'Platý' },
  { label: 'Plau Am See', value: 'Plau Am See' },
  { label: 'Plaudren', value: 'Plaudren' },
  { label: 'Plaue', value: 'Plaue' },
  { label: 'Plauen', value: 'Plauen' },
  { label: 'Plaus', value: 'Plaus' },
  { label: 'Plauzat', value: 'Plauzat' },
  { label: 'Plav', value: 'Plav' },
  { label: 'Plăvălari', value: 'Plăvălari' },
  { label: 'Pļaviņas', value: 'Pļaviņas' },
  { label: 'Plavsk', value: 'Plavsk' },
  { label: 'Plavy', value: 'Plavy' },
  { label: 'Pławno', value: 'Pławno' },
  { label: 'Playa Azul', value: 'Playa Azul' },
  { label: 'Playa Blanca', value: 'Playa Blanca' },
  { label: 'Playa Chica', value: 'Playa Chica' },
  { label: 'Playa Chiquita', value: 'Playa Chiquita' },
  { label: 'Playa De Chachalacas', value: 'Playa De Chachalacas' },
  { label: 'Playa De La Libertad', value: 'Playa De La Libertad' },
  { label: 'Playa De Las Américas', value: 'Playa De Las Américas' },
  { label: 'Playa Del Carmen', value: 'Playa Del Carmen' },
  { label: 'Playa Del Ingles', value: 'Playa Del Ingles' },
  { label: 'Playa Fortuna', value: 'Playa Fortuna' },
  { label: 'Playa Grande', value: 'Playa Grande' },
  { label: 'Playa Leona', value: 'Playa Leona' },
  { label: 'Playa Vicente', value: 'Playa Vicente' },
  { label: 'Playas', value: 'Playas' },
  { label: 'Playas De Rosarito', value: 'Playas De Rosarito' },
  { label: 'Playas Del Rosario', value: 'Playas Del Rosario' },
  { label: 'Playford', value: 'Playford' },
  { label: 'Playita', value: 'Playita' },
  { label: 'Playita Cortada', value: 'Playita Cortada' },
  { label: 'Playón Chico', value: 'Playón Chico' },
  { label: 'Płaza', value: 'Płaza' },
  { label: 'Plaza De Caisán', value: 'Plaza De Caisán' },
  { label: 'Plaza Huincul', value: 'Plaza Huincul' },
  { label: 'Plaza Nueva', value: 'Plaza Nueva' },
  { label: 'Pleak', value: 'Pleak' },
  { label: 'Plean', value: 'Plean' },
  { label: 'Pleașa', value: 'Pleașa' },
  { label: 'Pleasant Gap', value: 'Pleasant Gap' },
  { label: 'Pleasant Garden', value: 'Pleasant Garden' },
  { label: 'Pleasant Grove', value: 'Pleasant Grove' },
  { label: 'Pleasant Grove', value: 'Pleasant Grove' },
  { label: 'Pleasant Grove', value: 'Pleasant Grove' },
  { label: 'Pleasant Hill', value: 'Pleasant Hill' },
  { label: 'Pleasant Hill', value: 'Pleasant Hill' },
  { label: 'Pleasant Hill', value: 'Pleasant Hill' },
  { label: 'Pleasant Hill', value: 'Pleasant Hill' },
  { label: 'Pleasant Hill', value: 'Pleasant Hill' },
  { label: 'Pleasant Hill', value: 'Pleasant Hill' },
  { label: 'Pleasant Hills', value: 'Pleasant Hills' },
  { label: 'Pleasant Hills', value: 'Pleasant Hills' },
  { label: 'Pleasant Point', value: 'Pleasant Point' },
  { label: 'Pleasant Prairie', value: 'Pleasant Prairie' },
  { label: 'Pleasant Ridge', value: 'Pleasant Ridge' },
  { label: 'Pleasant Run', value: 'Pleasant Run' },
  { label: 'Pleasant Run Farm', value: 'Pleasant Run Farm' },
  { label: 'Pleasant Valley', value: 'Pleasant Valley' },
  { label: 'Pleasant Valley', value: 'Pleasant Valley' },
  { label: 'Pleasant Valley', value: 'Pleasant Valley' },
  { label: 'Pleasant Valley', value: 'Pleasant Valley' },
  { label: 'Pleasant View', value: 'Pleasant View' },
  { label: 'Pleasant View', value: 'Pleasant View' },
  { label: 'Pleasanton', value: 'Pleasanton' },
  { label: 'Pleasanton', value: 'Pleasanton' },
  { label: 'Pleasanton', value: 'Pleasanton' },
  { label: 'Pleasants County', value: 'Pleasants County' },
  { label: 'Pleasantville', value: 'Pleasantville' },
  { label: 'Pleasantville', value: 'Pleasantville' },
  { label: 'Pleasantville', value: 'Pleasantville' },
  { label: 'Pleașov', value: 'Pleașov' },
  { label: 'Pleasure Ridge Park', value: 'Pleasure Ridge Park' },
  { label: 'Pleaux', value: 'Pleaux' },
  { label: 'Pléchâtel', value: 'Pléchâtel' },
  { label: 'Plédéliac', value: 'Plédéliac' },
  { label: 'Plédran', value: 'Plédran' },
  { label: 'Pléguien', value: 'Pléguien' },
  { label: 'Pléhédel', value: 'Pléhédel' },
  { label: 'Pleidelsheim', value: 'Pleidelsheim' },
  { label: 'Pleiku', value: 'Pleiku' },
  { label: 'Pleine-Fougères', value: 'Pleine-Fougères' },
  { label: 'Pleinfeld', value: 'Pleinfeld' },
  { label: 'Pleiskirchen', value: 'Pleiskirchen' },
  { label: 'Pleitas', value: 'Pleitas' },
  { label: 'Plekhanovo', value: 'Plekhanovo' },
  { label: 'Plélan-Le-Grand', value: 'Plélan-Le-Grand' },
  { label: 'Plélan-Le-Petit', value: 'Plélan-Le-Petit' },
  { label: 'Plélo', value: 'Plélo' },
  { label: 'Plémet', value: 'Plémet' },
  { label: 'Plémy', value: 'Plémy' },
  { label: 'Plenas', value: 'Plenas' },
  { label: 'Plénée-Jugon', value: 'Plénée-Jugon' },
  { label: 'Pléneuf-Val-André', value: 'Pléneuf-Val-André' },
  { label: 'Pleniţa', value: 'Pleniţa' },
  { label: 'Plenty', value: 'Plenty' },
  { label: 'Plentywood', value: 'Plentywood' },
  { label: 'Plentzia', value: 'Plentzia' },
  { label: 'Plerguer', value: 'Plerguer' },
  { label: 'Plérin', value: 'Plérin' },
  { label: 'Plës', value: 'Plës' },
  { label: 'Plesching', value: 'Plesching' },
  { label: 'Plescop', value: 'Plescop' },
  { label: 'Pleşcuţa', value: 'Pleşcuţa' },
  { label: 'Plesetsk', value: 'Plesetsk' },
  { label: 'Pleshanovo', value: 'Pleshanovo' },
  { label: 'Plesio', value: 'Plesio' },
  { label: 'Pleslin-Trigavou', value: 'Pleslin-Trigavou' },
  { label: 'Plesná', value: 'Plesná' },
  { label: 'Pleśna', value: 'Pleśna' },
  { label: 'Pleșoi', value: 'Pleșoi' },
  { label: 'Pleşoiu', value: 'Pleşoiu' },
  { label: 'Plessa', value: 'Plessa' },
  { label: 'Plessala', value: 'Plessala' },
  { label: 'Plessé', value: 'Plessé' },
  { label: 'Plessisville', value: 'Plessisville' },
  { label: 'Plestan', value: 'Plestan' },
  { label: 'Plestin-Les-Grèves', value: 'Plestin-Les-Grèves' },
  { label: 'Pleszew', value: 'Pleszew' },
  { label: 'Pleternica', value: 'Pleternica' },
  { label: 'Plettenberg', value: 'Plettenberg' },
  { label: 'Plettenberg Bay', value: 'Plettenberg Bay' },
  { label: 'Pleubian', value: 'Pleubian' },
  { label: 'Pleucadeuc', value: 'Pleucadeuc' },
  { label: 'Pleudaniel', value: 'Pleudaniel' },
  { label: 'Pleudihen-Sur-Rance', value: 'Pleudihen-Sur-Rance' },
  { label: 'Pleugriffet', value: 'Pleugriffet' },
  { label: 'Pleugueneuc', value: 'Pleugueneuc' },
  { label: 'Pleumartin', value: 'Pleumartin' },
  { label: 'Pleumeleuc', value: 'Pleumeleuc' },
  { label: 'Pleumeur-Bodou', value: 'Pleumeur-Bodou' },
  { label: 'Pleumeur-Gautier', value: 'Pleumeur-Gautier' },
  { label: 'Pleurtuit', value: 'Pleurtuit' },
  { label: 'Pleuven', value: 'Pleuven' },
  { label: 'Pleven', value: 'Pleven' },
  { label: 'Plevna', value: 'Plevna' },
  { label: 'Plewiska', value: 'Plewiska' },
  { label: 'Pleyben', value: 'Pleyben' },
  { label: 'Pleyber-Christ', value: 'Pleyber-Christ' },
  { label: 'Pleyne', value: 'Pleyne' },
  { label: 'Pleystein', value: 'Pleystein' },
  { label: 'Pliego', value: 'Pliego' },
  { label: 'Pliening', value: 'Pliening' },
  { label: 'Pliezhausen', value: 'Pliezhausen' },
  { label: 'Plitvička Jezera', value: 'Plitvička Jezera' },
  { label: 'Pljevlja', value: 'Pljevlja' },
  { label: 'Ploaghe', value: 'Ploaghe' },
  { label: 'Plobannalec-Lesconil', value: 'Plobannalec-Lesconil' },
  { label: 'Plobsheim', value: 'Plobsheim' },
  { label: 'Plochingen', value: 'Plochingen' },
  { label: 'Płock', value: 'Płock' },
  { label: 'Plodio', value: 'Plodio' },
  { label: 'Plodovoye', value: 'Plodovoye' },
  { label: 'Ploemel', value: 'Ploemel' },
  { label: 'Ploemeur', value: 'Ploemeur' },
  { label: 'Ploërdut', value: 'Ploërdut' },
  { label: 'Ploeren', value: 'Ploeren' },
  { label: 'Ploërmel', value: 'Ploërmel' },
  { label: 'Plœuc-Sur-Lié', value: 'Plœuc-Sur-Lié' },
  { label: 'Ploëzal', value: 'Ploëzal' },
  { label: 'Plogastel-Saint-Germain', value: 'Plogastel-Saint-Germain' },
  { label: 'Plogoff', value: 'Plogoff' },
  { label: 'Plogonnec', value: 'Plogonnec' },
  { label: 'Ploieşti', value: 'Ploieşti' },
  { label: 'Ploieștiori', value: 'Ploieștiori' },
  { label: 'Plombières', value: 'Plombières' },
  { label: 'Plombières-Les-Bains', value: 'Plombières-Les-Bains' },
  { label: 'Plombières-Lès-Dijon', value: 'Plombières-Lès-Dijon' },
  { label: 'Plomelin', value: 'Plomelin' },
  { label: 'Plomeur', value: 'Plomeur' },
  { label: 'Plomodiern', value: 'Plomodiern' },
  { label: 'Plön', value: 'Plön' },
  { label: 'Plonéis', value: 'Plonéis' },
  { label: 'Plonéour-Lanvern', value: 'Plonéour-Lanvern' },
  { label: 'Plonévez-Du-Faou', value: 'Plonévez-Du-Faou' },
  { label: 'Plonévez-Porzay', value: 'Plonévez-Porzay' },
  { label: 'Płoniawy-Bramura', value: 'Płoniawy-Bramura' },
  { label: 'Płońsk', value: 'Płońsk' },
  { label: 'Plopana', value: 'Plopana' },
  { label: 'Plopeni', value: 'Plopeni' },
  { label: 'Plopeni', value: 'Plopeni' },
  { label: 'Plopeni', value: 'Plopeni' },
  { label: 'Plopii Slăviţeştí', value: 'Plopii Slăviţeştí' },
  { label: 'Plopiş', value: 'Plopiş' },
  { label: 'Plopiș', value: 'Plopiș' },
  { label: 'Plopşoru', value: 'Plopşoru' },
  { label: 'Plopșoru', value: 'Plopșoru' },
  { label: 'Plopu', value: 'Plopu' },
  { label: 'Plopu', value: 'Plopu' },
  { label: 'Plopu', value: 'Plopu' },
  { label: 'Plosca', value: 'Plosca' },
  { label: 'Ploscoș', value: 'Ploscoș' },
  { label: 'Ploscuțeni', value: 'Ploscuțeni' },
  { label: 'Plößberg', value: 'Plößberg' },
  { label: 'Ploștina', value: 'Ploștina' },
  { label: 'Plotnikovo', value: 'Plotnikovo' },
  { label: 'Plottier', value: 'Plottier' },
  { label: 'Płoty', value: 'Płoty' },
  { label: 'Plötzkau', value: 'Plötzkau' },
  { label: 'Plötzky', value: 'Plötzky' },
  { label: 'Plou', value: 'Plou' },
  { label: 'Plouagat', value: 'Plouagat' },
  { label: 'Plouaret', value: 'Plouaret' },
  { label: 'Plouarzel', value: 'Plouarzel' },
  { label: 'Plouasne', value: 'Plouasne' },
  { label: 'Plouay', value: 'Plouay' },
  { label: 'Ploubalay', value: 'Ploubalay' },
  { label: 'Ploubazlanec', value: 'Ploubazlanec' },
  { label: 'Ploubezre', value: 'Ploubezre' },
  { label: 'Ploudalmézeau', value: 'Ploudalmézeau' },
  { label: 'Ploudaniel', value: 'Ploudaniel' },
  { label: 'Plouëc-Du-Trieux', value: 'Plouëc-Du-Trieux' },
  { label: 'Plouédern', value: 'Plouédern' },
  { label: 'Plouénan', value: 'Plouénan' },
  { label: 'Plouër-Sur-Rance', value: 'Plouër-Sur-Rance' },
  { label: 'Plouescat', value: 'Plouescat' },
  { label: 'Plouézec', value: 'Plouézec' },
  { label: 'Plouezoch', value: 'Plouezoch' },
  { label: 'Ploufragan', value: 'Ploufragan' },
  { label: 'Plougasnou', value: 'Plougasnou' },
  { label: 'Plougastel-Daoulas', value: 'Plougastel-Daoulas' },
  { label: 'Plougonvelin', value: 'Plougonvelin' },
  { label: 'Plougonven', value: 'Plougonven' },
  { label: 'Plougoulm', value: 'Plougoulm' },
  { label: 'Plougoumelen', value: 'Plougoumelen' },
  { label: 'Plougourvest', value: 'Plougourvest' },
  { label: 'Plougrescant', value: 'Plougrescant' },
  { label: 'Plouguenast', value: 'Plouguenast' },
  { label: 'Plouguerneau', value: 'Plouguerneau' },
  { label: 'Plouguernével', value: 'Plouguernével' },
  { label: 'Plouguiel', value: 'Plouguiel' },
  { label: 'Plouguin', value: 'Plouguin' },
  { label: 'Plouha', value: 'Plouha' },
  { label: 'Plouharnel', value: 'Plouharnel' },
  { label: 'Plouhinec', value: 'Plouhinec' },
  { label: 'Plouider', value: 'Plouider' },
  { label: 'Plouigneau', value: 'Plouigneau' },
  { label: 'Plouisy', value: 'Plouisy' },
  { label: 'Ploumagoar', value: 'Ploumagoar' },
  { label: 'Ploumilliau', value: 'Ploumilliau' },
  { label: 'Ploumoguer', value: 'Ploumoguer' },
  { label: 'Plounéour-Ménez', value: 'Plounéour-Ménez' },
  { label: 'Plounéour-Trez', value: 'Plounéour-Trez' },
  { label: 'Plounéventer', value: 'Plounéventer' },
  { label: 'Plounévézel', value: 'Plounévézel' },
  { label: 'Plounévez-Lochrist', value: 'Plounévez-Lochrist' },
  { label: 'Plounévez-Moëdec', value: 'Plounévez-Moëdec' },
  { label: 'Plounévez-Quintin', value: 'Plounévez-Quintin' },
  { label: 'Plouray', value: 'Plouray' },
  { label: 'Plourin-Lès-Morlaix', value: 'Plourin-Lès-Morlaix' },
  { label: 'Plourivo', value: 'Plourivo' },
  { label: 'Plouvien', value: 'Plouvien' },
  { label: 'Plouvorn', value: 'Plouvorn' },
  { label: 'Plouzané', value: 'Plouzané' },
  { label: 'Plouzévédé', value: 'Plouzévédé' },
  { label: 'Plovdiv', value: 'Plovdiv' },
  { label: 'Plover', value: 'Plover' },
  { label: 'Plowden', value: 'Plowden' },
  { label: 'Plozévet', value: 'Plozévet' },
  { label: 'Plüderhausen', value: 'Plüderhausen' },
  { label: 'Pluduno', value: 'Pluduno' },
  { label: 'Plugari', value: 'Plugari' },
  { label: 'Plugova', value: 'Plugova' },
  { label: 'Pluguffan', value: 'Pluguffan' },
  { label: 'Pluherlin', value: 'Pluherlin' },
  { label: 'Plum', value: 'Plum' },
  { label: 'Plum Creek', value: 'Plum Creek' },
  { label: 'Plum Grove', value: 'Plum Grove' },
  { label: 'Pluma Hidalgo', value: 'Pluma Hidalgo' },
  { label: 'Plumas County', value: 'Plumas County' },
  { label: 'Plumas Lake', value: 'Plumas Lake' },
  { label: 'Plumaugat', value: 'Plumaugat' },
  { label: 'Plumbon', value: 'Plumbon' },
  { label: 'Plumelec', value: 'Plumelec' },
  { label: 'Pluméliau', value: 'Pluméliau' },
  { label: 'Plumelin', value: 'Plumelin' },
  { label: 'Plumergat', value: 'Plumergat' },
  { label: 'Plumieux', value: 'Plumieux' },
  { label: 'Plumlov', value: 'Plumlov' },
  { label: 'Plummer', value: 'Plummer' },
  { label: 'Plumpton', value: 'Plumpton' },
  { label: 'Plumpton', value: 'Plumpton' },
  { label: 'Plumpton Green', value: 'Plumpton Green' },
  { label: 'Plumsteadville', value: 'Plumsteadville' },
  { label: 'Plumtree', value: 'Plumtree' },
  { label: 'Pluneret', value: 'Pluneret' },
  { label: 'Plunge', value: 'Plunge' },
  { label: 'Plurien', value: 'Plurien' },
  { label: 'Plut', value: 'Plut' },
  { label: 'Plutarco Elías Calles (La Y Griega)', value: 'Plutarco Elías Calles (La Y Griega)' },
  { label: 'Plutonița', value: 'Plutonița' },
  { label: 'Pluvigner', value: 'Pluvigner' },
  { label: 'Pluwig', value: 'Pluwig' },
  { label: 'Plužine', value: 'Plužine' },
  { label: 'Płużnica', value: 'Płużnica' },
  { label: 'Pluzunet', value: 'Pluzunet' },
  { label: 'Plyeshchanitsy', value: 'Plyeshchanitsy' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth', value: 'Plymouth' },
  { label: 'Plymouth County', value: 'Plymouth County' },
  { label: 'Plymouth County', value: 'Plymouth County' },
  { label: 'Plymouth Meeting', value: 'Plymouth Meeting' },
  { label: 'Plympton', value: 'Plympton' },
  { label: 'Plympton', value: 'Plympton' },
  { label: 'Plympton', value: 'Plympton' },
  { label: 'Plympton Park', value: 'Plympton Park' },
  { label: 'Plymstock', value: 'Plymstock' },
  { label: 'Plyussa', value: 'Plyussa' },
  { label: 'Pniewy', value: 'Pniewy' },
  { label: 'Pnikut', value: 'Pnikut' },
  { label: 'Pniv', value: 'Pniv' },
  { label: 'Pô', value: 'Pô' },
  { label: 'Poá', value: 'Poá' },
  { label: 'Poal El', value: 'Poal El' },
  { label: 'Poarta', value: 'Poarta' },
  { label: 'Poarta Albă', value: 'Poarta Albă' },
  { label: 'Poás', value: 'Poás' },
  { label: 'Poasco-Sorigherio', value: 'Poasco-Sorigherio' },
  { label: 'Pobé', value: 'Pobé' },
  { label: 'Pobednoye', value: 'Pobednoye' },
  { label: 'Pobegi', value: 'Pobegi' },
  { label: 'Pobershau', value: 'Pobershau' },
  { label: 'Poběžovice', value: 'Poběžovice' },
  { label: 'Pobiedziska', value: 'Pobiedziska' },
  { label: 'Pobierowo', value: 'Pobierowo' },
  { label: 'Pobla De Benifassà La', value: 'Pobla De Benifassà La' },
  { label: 'Pobla De Cérvoles La', value: 'Pobla De Cérvoles La' },
  { label: 'Pobla De Claramunt La', value: 'Pobla De Claramunt La' },
  { label: 'Pobla De Farnals La', value: 'Pobla De Farnals La' },
  { label: 'Pobla De Lillet La', value: 'Pobla De Lillet La' },
  { label: 'Pobla De Mafumet', value: 'Pobla De Mafumet' },
  { label: 'Pobla De Massaluc', value: 'Pobla De Massaluc' },
  { label: 'Pobla De Montornès', value: 'Pobla De Montornès' },
  { label: 'Pobla De Segur La', value: 'Pobla De Segur La' },
  { label: 'Pobla De Vallbona La', value: 'Pobla De Vallbona La' },
  { label: 'Pobla Del Duc La', value: 'Pobla Del Duc La' },
  { label: 'Pobla Llarga La', value: 'Pobla Llarga La' },
  { label: 'Pobla Sa', value: 'Pobla Sa' },
  { label: 'Pobla Tornesa La', value: 'Pobla Tornesa La' },
  { label: 'Población De Arroyo', value: 'Población De Arroyo' },
  { label: 'Población De Campos', value: 'Población De Campos' },
  { label: 'Población De Cerrato', value: 'Población De Cerrato' },
  { label: 'Poblacion San Felipe', value: 'Poblacion San Felipe' },
  { label: 'Poblado Chulavista (El Chorizo)', value: 'Poblado Chulavista (El Chorizo)' },
  { label: 'Poblado Cinco', value: 'Poblado Cinco' },
  { label: 'Poblado Dos', value: 'Poblado Dos' },
  { label: 'Poblado Lázaro Cárdenas (La Veintiocho)', value: 'Poblado Lázaro Cárdenas (La Veintiocho)' },
  { label: 'Poblado Número Cinco', value: 'Poblado Número Cinco' },
  { label: 'Poblado Tres', value: 'Poblado Tres' },
  { label: 'Pobladura De Pelayo García', value: 'Pobladura De Pelayo García' },
  { label: 'Pobladura De Valderaduey', value: 'Pobladura De Valderaduey' },
  { label: 'Pobladura Del Valle', value: 'Pobladura Del Valle' },
  { label: 'Poblete', value: 'Poblete' },
  { label: 'Poblets Els', value: 'Poblets Els' },
  { label: 'Pobo De Dueñas El', value: 'Pobo De Dueñas El' },
  { label: 'Pobo El', value: 'Pobo El' },
  { label: 'Poboleda', value: 'Poboleda' },
  { label: 'Poboru', value: 'Poboru' },
  { label: 'Pobra De Trives A', value: 'Pobra De Trives A' },
  { label: 'Pobra Do Brollón A', value: 'Pobra Do Brollón A' },
  { label: 'Pobra Do Caramiñal A', value: 'Pobra Do Caramiñal A' },
  { label: 'Pobugskoye', value: 'Pobugskoye' },
  { label: 'Pocahontas', value: 'Pocahontas' },
  { label: 'Pocahontas', value: 'Pocahontas' },
  { label: 'Pocahontas County', value: 'Pocahontas County' },
  { label: 'Pocahontas County', value: 'Pocahontas County' },
  { label: 'Poção', value: 'Poção' },
  { label: 'Poção De Pedras', value: 'Poção De Pedras' },
  { label: 'Pocapaglia', value: 'Pocapaglia' },
  { label: 'Pocasset', value: 'Pocasset' },
  { label: 'Pocatello', value: 'Pocatello' },
  { label: 'Počátky', value: 'Počátky' },
  { label: 'Pocboc', value: 'Pocboc' },
  { label: 'Poceirão', value: 'Poceirão' },
  { label: 'Pocé-Les-Bois', value: 'Pocé-Les-Bois' },
  { label: 'Pocenia', value: 'Pocenia' },
  { label: 'Pocerski Pričinović', value: 'Pocerski Pričinović' },
  { label: 'Pocé-Sur-Cisse', value: 'Pocé-Sur-Cisse' },
  { label: 'Pochahuizco', value: 'Pochahuizco' },
  { label: 'Pochaiv', value: 'Pochaiv' },
  { label: 'Pochálcatl', value: 'Pochálcatl' },
  { label: 'Pocheon-Si', value: 'Pocheon-Si' },
  { label: 'Pochep', value: 'Pochep' },
  { label: 'Pochepskiy Rayon', value: 'Pochepskiy Rayon' },
  { label: 'Pochetnoye', value: 'Pochetnoye' },
  { label: 'Pochidia', value: 'Pochidia' },
  { label: 'Pochinki', value: 'Pochinki' },
  { label: 'Pochinkovskiy Rayon', value: 'Pochinkovskiy Rayon' },
  { label: 'Pochinok', value: 'Pochinok' },
  { label: 'Pöchlarn', value: 'Pöchlarn' },
  { label: 'Pochotillo', value: 'Pochotillo' },
  { label: 'Pochotita', value: 'Pochotita' },
  { label: 'Pochuta', value: 'Pochuta' },
  { label: 'Pochutla', value: 'Pochutla' },
  { label: 'Pocinhos', value: 'Pocinhos' },
  { label: 'Pociovaliștea', value: 'Pociovaliștea' },
  { label: 'Pocito', value: 'Pocito' },
  { label: 'Pocitos', value: 'Pocitos' },
  { label: 'Pocitos', value: 'Pocitos' },
  { label: 'Pockau', value: 'Pockau' },
  { label: 'Pöckau', value: 'Pöckau' },
  { label: 'Pocking', value: 'Pocking' },
  { label: 'Pocklington', value: 'Pocklington' },
  { label: 'Poço Branco', value: 'Poço Branco' },
  { label: 'Poço Dantas', value: 'Poço Dantas' },
  { label: 'Poço Das Antas', value: 'Poço Das Antas' },
  { label: 'Poço Das Trincheiras', value: 'Poço Das Trincheiras' },
  { label: 'Poço De José De Moura', value: 'Poço De José De Moura' },
  { label: 'Poço Do Canto', value: 'Poço Do Canto' },
  { label: 'Poço Fundo', value: 'Poço Fundo' },
  { label: 'Poço Redondo', value: 'Poço Redondo' },
  { label: 'Poço Verde', value: 'Poço Verde' },
  { label: 'Pococí', value: 'Pococí' },
  { label: 'Poções', value: 'Poções' },
  { label: 'Pocola', value: 'Pocola' },
  { label: 'Pocola', value: 'Pocola' },
  { label: 'Pocomoke City', value: 'Pocomoke City' },
  { label: 'Poconé', value: 'Poconé' },
  { label: 'Pocono Pines', value: 'Pocono Pines' },
  { label: 'Pocono Ranch Lands', value: 'Pocono Ranch Lands' },
  { label: 'Pocora', value: 'Pocora' },
  { label: 'Poços De Caldas', value: 'Poços De Caldas' },
  { label: 'Pocosol', value: 'Pocosol' },
  { label: 'Pocrane', value: 'Pocrane' },
  { label: 'Pocrí', value: 'Pocrí' },
  { label: 'Pocrí', value: 'Pocrí' },
  { label: 'Pocruia', value: 'Pocruia' },
  { label: 'Pocsaj', value: 'Pocsaj' },
  { label: 'Poctol', value: 'Poctol' },
  { label: 'Podareš', value: 'Podareš' },
  { label: 'Podari', value: 'Podari' },
  { label: 'Podbořany', value: 'Podbořany' },
  { label: 'Podbrdo', value: 'Podbrdo' },
  { label: 'Podčetrtek', value: 'Podčetrtek' },
  { label: 'Poddębice', value: 'Poddębice' },
  { label: 'Poddor’Ye', value: 'Poddor’Ye' },
  { label: 'Poddorskiy Rayon', value: 'Poddorskiy Rayon' },
  { label: 'Podebłocie', value: 'Podebłocie' },
  { label: 'Poděbrady', value: 'Poděbrady' },
  { label: 'Podedwórze', value: 'Podedwórze' },
  { label: 'Podegrodzie', value: 'Podegrodzie' },
  { label: 'Podelzig', value: 'Podelzig' },
  { label: 'Podeni', value: 'Podeni' },
  { label: 'Podenii Noi', value: 'Podenii Noi' },
  { label: 'Podenii Vechi', value: 'Podenii Vechi' },
  { label: 'Podensac', value: 'Podensac' },
  { label: 'Podenzana', value: 'Podenzana' },
  { label: 'Podenzano', value: 'Podenzano' },
  { label: 'Podersdorf Am See', value: 'Podersdorf Am See' },
  { label: 'Podgora', value: 'Podgora' },
  { label: 'Podgorač', value: 'Podgorač' },
  { label: 'Podgorenskiy', value: 'Podgorenskiy' },
  { label: 'Podgorenskiy Rayon', value: 'Podgorenskiy Rayon' },
  { label: 'Podgoria', value: 'Podgoria' },
  { label: 'Podgorica', value: 'Podgorica' },
  { label: 'Podgornaya', value: 'Podgornaya' },
  { label: 'Podgornoye', value: 'Podgornoye' },
  { label: 'Podgornoye', value: 'Podgornoye' },
  { label: 'Podgorodnyaya Pokrovka', value: 'Podgorodnyaya Pokrovka' },
  { label: 'Podgórzyn', value: 'Podgórzyn' },
  { label: 'Podhum', value: 'Podhum' },
  { label: 'Podhum', value: 'Podhum' },
  { label: 'Podil’S’K', value: 'Podil’S’K' },
  { label: 'Podil’S’Kyy Rayon', value: 'Podil’S’Kyy Rayon' },
  { label: 'Podilsk Raion', value: 'Podilsk Raion' },
  { label: 'Podington', value: 'Podington' },
  { label: 'Podivín', value: 'Podivín' },
  { label: 'Podkletnoye', value: 'Podkletnoye' },
  { label: 'Podkowa Leśna', value: 'Podkowa Leśna' },
  { label: 'Podkumskiy', value: 'Podkumskiy' },
  { label: 'Podlehnik', value: 'Podlehnik' },
  { label: 'Podlesnoye', value: 'Podlesnoye' },
  { label: 'Podlesnyy', value: 'Podlesnyy' },
  { label: 'Podłęże', value: 'Podłęże' },
  { label: 'Podnov’Ye', value: 'Podnov’Ye' },
  { label: 'Podoleni', value: 'Podoleni' },
  { label: 'Podoleni', value: 'Podoleni' },
  { label: 'Podolenii De Sus', value: 'Podolenii De Sus' },
  { label: 'Podolí', value: 'Podolí' },
  { label: 'Podolínec', value: 'Podolínec' },
  { label: 'Podolsk', value: 'Podolsk' },
  { label: 'Podosinki', value: 'Podosinki' },
  { label: 'Podosinovets', value: 'Podosinovets' },
  { label: 'Podosinovskiy Rayon', value: 'Podosinovskiy Rayon' },
  { label: 'Podporozh’Ye', value: 'Podporozh’Ye' },
  { label: 'Podporozhskiy Rayon', value: 'Podporozhskiy Rayon' },
  { label: 'Podravska Moslavina', value: 'Podravska Moslavina' },
  { label: 'Podsineye', value: 'Podsineye' },
  { label: 'Podsolnechnoye', value: 'Podsolnechnoye' },
  { label: 'Podsosnovo', value: 'Podsosnovo' },
  { label: 'Podstepki', value: 'Podstepki' },
  { label: 'Podstrana', value: 'Podstrana' },
  { label: 'Podturen', value: 'Podturen' },
  { label: 'Podu Iloaiei', value: 'Podu Iloaiei' },
  { label: 'Podu Turcului', value: 'Podu Turcului' },
  { label: 'Podunavlje District', value: 'Podunavlje District' },
  { label: 'Poduri', value: 'Poduri' },
  { label: 'Poduri', value: 'Poduri' },
  { label: 'Podvelka', value: 'Podvelka' },
  { label: 'Podvinje', value: 'Podvinje' },
  { label: 'Podwilk', value: 'Podwilk' },
  { label: 'Podyuga', value: 'Podyuga' },
  { label: 'Podzvizd', value: 'Podzvizd' },
  { label: 'Poeldijk', value: 'Poeldijk' },
  { label: 'Poeni', value: 'Poeni' },
  { label: 'Poestenkill', value: 'Poestenkill' },
  { label: 'Poey-De-Lescar', value: 'Poey-De-Lescar' },
  { label: 'Pofadder', value: 'Pofadder' },
  { label: 'Pofi', value: 'Pofi' },
  { label: 'Pogăceaua', value: 'Pogăceaua' },
  { label: 'Pogana', value: 'Pogana' },
  { label: 'Pogănești', value: 'Pogănești' },
  { label: 'Pogar', value: 'Pogar' },
  { label: 'Pogarskiy Rayon', value: 'Pogarskiy Rayon' },
  { label: 'Poggersdorf', value: 'Poggersdorf' },
  { label: 'Poggetto', value: 'Poggetto' },
  { label: 'Poggiardo', value: 'Poggiardo' },
  { label: 'Poggibonsi', value: 'Poggibonsi' },
  { label: 'Poggio A Caiano', value: 'Poggio A Caiano' },
  { label: 'Poggio Berni', value: 'Poggio Berni' },
  { label: 'Poggio Bustone', value: 'Poggio Bustone' },
  { label: 'Poggio Catino', value: 'Poggio Catino' },
  { label: 'Poggio Dei Pini', value: 'Poggio Dei Pini' },
  { label: 'Poggio Di Chiesanuova', value: 'Poggio Di Chiesanuova' },
  { label: 'Poggio Ellera', value: 'Poggio Ellera' },
  { label: 'Poggio Imperiale', value: 'Poggio Imperiale' },
  { label: 'Poggio Mirteto', value: 'Poggio Mirteto' },
  { label: 'Poggio Moiano', value: 'Poggio Moiano' },
  { label: 'Poggio Nativo', value: 'Poggio Nativo' },
  { label: 'Poggio Picenze', value: 'Poggio Picenze' },
  { label: 'Poggio Renatico', value: 'Poggio Renatico' },
  { label: 'Poggio Rusco', value: 'Poggio Rusco' },
  { label: 'Poggio San Lorenzo', value: 'Poggio San Lorenzo' },
  { label: 'Poggio San Marcello', value: 'Poggio San Marcello' },
  { label: 'Poggio San Vicino', value: 'Poggio San Vicino' },
  { label: 'Poggio Sannita', value: 'Poggio Sannita' },
  { label: 'Poggiodomo', value: 'Poggiodomo' },
  { label: 'Poggiofiorito', value: 'Poggiofiorito' },
  { label: 'Poggiomarino', value: 'Poggiomarino' },
  { label: 'Poggioreale', value: 'Poggioreale' },
  { label: 'Poggiorsini', value: 'Poggiorsini' },
  { label: 'Poggiridenti Alto', value: 'Poggiridenti Alto' },
  { label: 'Poggiridenti Piano', value: 'Poggiridenti Piano' },
  { label: 'Pöggstall', value: 'Pöggstall' },
  { label: 'Pogliano Milanese', value: 'Pogliano Milanese' },
  { label: 'Pognana Lario', value: 'Pognana Lario' },
  { label: 'Pognano', value: 'Pognano' },
  { label: 'Pogno', value: 'Pogno' },
  { label: 'Pogoanele', value: 'Pogoanele' },
  { label: 'Pogonele', value: 'Pogonele' },
  { label: 'Pogonești', value: 'Pogonești' },
  { label: 'Pogonsili', value: 'Pogonsili' },
  { label: 'Pogonsili', value: 'Pogonsili' },
  { label: 'Pogórska Wola', value: 'Pogórska Wola' },
  { label: 'Pogórze', value: 'Pogórze' },
  { label: 'Pogórze', value: 'Pogórze' },
  { label: 'Pogorzela', value: 'Pogorzela' },
  { label: 'Pogorzyce', value: 'Pogorzyce' },
  { label: 'Pogradec', value: 'Pogradec' },
  { label: 'Pogranichnyy', value: 'Pogranichnyy' },
  { label: 'Pogrzebień', value: 'Pogrzebień' },
  { label: 'Pogwizdów', value: 'Pogwizdów' },
  { label: 'Pohang', value: 'Pohang' },
  { label: 'Pohang-Si', value: 'Pohang-Si' },
  { label: 'Pohénégamook', value: 'Pohénégamook' },
  { label: 'Pohja', value: 'Pohja' },
  { label: 'Pohjaslahti', value: 'Pohjaslahti' },
  { label: 'Pöhl', value: 'Pöhl' },
  { label: 'Pöhla', value: 'Pöhla' },
  { label: 'Pohořelice', value: 'Pohořelice' },
  { label: 'Pohreby', value: 'Pohreby' },
  { label: 'Pohrebyshche', value: 'Pohrebyshche' },
  { label: 'Pohrebyshchens’Kyy Rayon', value: 'Pohrebyshchens’Kyy Rayon' },
  { label: 'Poian', value: 'Poian' },
  { label: 'Poiana', value: 'Poiana' },
  { label: 'Poiana', value: 'Poiana' },
  { label: 'Poiana', value: 'Poiana' },
  { label: 'Poiana', value: 'Poiana' },
  { label: 'Poiana', value: 'Poiana' },
  { label: 'Poiana', value: 'Poiana' },
  { label: 'Poiana', value: 'Poiana' },
  { label: 'Poiana', value: 'Poiana' },
  { label: 'Poiana Blenchii', value: 'Poiana Blenchii' },
  { label: 'Poiana Botizii', value: 'Poiana Botizii' },
  { label: 'Poiana Câmpina', value: 'Poiana Câmpina' },
  { label: 'Poiana Codrului', value: 'Poiana Codrului' },
  { label: 'Poiana Copăceni', value: 'Poiana Copăceni' },
  { label: 'Poiana Crăcăoani', value: 'Poiana Crăcăoani' },
  { label: 'Poiana Cristei', value: 'Poiana Cristei' },
  { label: 'Poiana Ilvei', value: 'Poiana Ilvei' },
  { label: 'Poiana Lacului', value: 'Poiana Lacului' },
  { label: 'Poiana Maggiore', value: 'Poiana Maggiore' },
  { label: 'Poiana Mare', value: 'Poiana Mare' },
  { label: 'Poiana Mărului', value: 'Poiana Mărului' },
  { label: 'Poiana Mărului', value: 'Poiana Mărului' },
  { label: 'Poiana Mărului (Brașov)', value: 'Poiana Mărului (Brașov)' },
  { label: 'Poiana Sibiului', value: 'Poiana Sibiului' },
  { label: 'Poiana Stampei', value: 'Poiana Stampei' },
  { label: 'Poiana Țapului', value: 'Poiana Țapului' },
  { label: 'Poiana Teiului', value: 'Poiana Teiului' },
  { label: 'Poiana Vadului', value: 'Poiana Vadului' },
  { label: 'Poianella', value: 'Poianella' },
  { label: 'Poiano', value: 'Poiano' },
  { label: 'Poiares', value: 'Poiares' },
  { label: 'Poienari', value: 'Poienari' },
  { label: 'Poienari', value: 'Poienari' },
  { label: 'Poienarii Apostoli', value: 'Poienarii Apostoli' },
  { label: 'Poienarii Burchii', value: 'Poienarii Burchii' },
  { label: 'Poieneşti', value: 'Poieneşti' },
  { label: 'Poieni', value: 'Poieni' },
  { label: 'Poieni', value: 'Poieni' },
  { label: 'Poienile', value: 'Poienile' },
  { label: 'Poienile De Sub Munte', value: 'Poienile De Sub Munte' },
  { label: 'Poienile Izei', value: 'Poienile Izei' },
  { label: 'Poienile-Mogoş', value: 'Poienile-Mogoş' },
  { label: 'Poieni-Solca', value: 'Poieni-Solca' },
  { label: 'Poienița', value: 'Poienița' },
  { label: 'Poilly-Lez-Gien', value: 'Poilly-Lez-Gien' },
  { label: 'Poim', value: 'Poim' },
  { label: 'Poinciana', value: 'Poinciana' },
  { label: 'Poindimié', value: 'Poindimié' },
  { label: 'Poing', value: 'Poing' },
  { label: 'Poinsett County', value: 'Poinsett County' },
  { label: 'Point Baker', value: 'Point Baker' },
  { label: 'Point Clare', value: 'Point Clare' },
  { label: 'Point Clear', value: 'Point Clear' },
  { label: 'Point Cook', value: 'Point Cook' },
  { label: 'Point Fortin', value: 'Point Fortin' },
  { label: 'Point Frederick', value: 'Point Frederick' },
  { label: 'Point Hill', value: 'Point Hill' },
  { label: 'Point Lonsdale', value: 'Point Lonsdale' },
  { label: 'Point Lookout', value: 'Point Lookout' },
  { label: 'Point Marion', value: 'Point Marion' },
  { label: 'Point Of Rocks', value: 'Point Of Rocks' },
  { label: 'Point Pedro', value: 'Point Pedro' },
  { label: 'Point Piper', value: 'Point Piper' },
  { label: 'Point Pleasant', value: 'Point Pleasant' },
  { label: 'Point Pleasant', value: 'Point Pleasant' },
  { label: 'Point Pleasant Beach', value: 'Point Pleasant Beach' },
  { label: 'Point Roberts', value: 'Point Roberts' },
  { label: 'Point Vernon', value: 'Point Vernon' },
  { label: 'Pointe Aux Piments', value: 'Pointe Aux Piments' },
  { label: 'Pointe Aux Sables', value: 'Pointe Aux Sables' },
  { label: 'Pointe Coupee Parish', value: 'Pointe Coupee Parish' },
  { label: 'Pointe Michel', value: 'Pointe Michel' },
  { label: 'Pointe Seraphine', value: 'Pointe Seraphine' },
  { label: 'Pointe-Calumet', value: 'Pointe-Calumet' },
  { label: 'Pointe-Claire', value: 'Pointe-Claire' },
  { label: 'Pointe-Du-Lac', value: 'Pointe-Du-Lac' },
  { label: 'Pointe-Noire', value: 'Pointe-Noire' },
  { label: 'Poio', value: 'Poio' },
  { label: 'Poirino', value: 'Poirino' },
  { label: 'Poisat', value: 'Poisat' },
  { label: 'Poissy', value: 'Poissy' },
  { label: 'Poisy', value: 'Poisy' },
  { label: 'Poitiers', value: 'Poitiers' },
  { label: 'Poix-De-Picardie', value: 'Poix-De-Picardie' },
  { label: 'Poix-Du-Nord', value: 'Poix-Du-Nord' },
  { label: 'Pojatno', value: 'Pojatno' },
  { label: 'Pojejena', value: 'Pojejena' },
  { label: 'Pojezerje', value: 'Pojezerje' },
  { label: 'Pojoaque', value: 'Pojoaque' },
  { label: 'Pojogeni', value: 'Pojogeni' },
  { label: 'Pojorâta', value: 'Pojorâta' },
  { label: 'Pojuca', value: 'Pojuca' },
  { label: 'Pokachi', value: 'Pokachi' },
  { label: 'Pokaran', value: 'Pokaran' },
  { label: 'Pokhvistnevo', value: 'Pokhvistnevo' },
  { label: 'Pokój', value: 'Pokój' },
  { label: 'Pokolbin', value: 'Pokolbin' },
  { label: 'Pokosnoye', value: 'Pokosnoye' },
  { label: 'Pokotylivka', value: 'Pokotylivka' },
  { label: 'Pokoynoye', value: 'Pokoynoye' },
  { label: 'Pokrov', value: 'Pokrov' },
  { label: 'Pokrovka', value: 'Pokrovka' },
  { label: 'Pokrovo-Prigorodnoye', value: 'Pokrovo-Prigorodnoye' },
  { label: 'Pokrovsk', value: 'Pokrovsk' },
  { label: 'Pokrovsk', value: 'Pokrovsk' },
  { label: 'Pokrovsk Raion', value: 'Pokrovsk Raion' },
  { label: 'Pokrovskoye', value: 'Pokrovskoye' },
  { label: 'Pokrovskoye', value: 'Pokrovskoye' },
  { label: 'Pokrovskoye', value: 'Pokrovskoye' },
  { label: 'Pokrovskoye', value: 'Pokrovskoye' },
  { label: 'Pokrovskoye-Streshnëvo', value: 'Pokrovskoye-Streshnëvo' },
  { label: 'Pokrówka', value: 'Pokrówka' },
  { label: 'Pokrzywnica', value: 'Pokrzywnica' },
  { label: 'Pol', value: 'Pol' },
  { label: 'Pol Dokhtar', value: 'Pol Dokhtar' },
  { label: 'Pol Sefid', value: 'Pol Sefid' },
  { label: 'Pola', value: 'Pola' },
  { label: 'Pola De Gordón La', value: 'Pola De Gordón La' },
  { label: 'Polača', value: 'Polača' },
  { label: 'Polaciones', value: 'Polaciones' },
  { label: 'Polahongon', value: 'Polahongon' },
  { label: 'Polahongon', value: 'Polahongon' },
  { label: 'Połajewo', value: 'Połajewo' },
  { label: 'Polán', value: 'Polán' },
  { label: 'Polanco', value: 'Polanco' },
  { label: 'Polanco', value: 'Polanco' },
  { label: 'Polanco', value: 'Polanco' },
  { label: 'Polańczyk', value: 'Polańczyk' },
  { label: 'Poland', value: 'Poland' },
  { label: 'Poland', value: 'Poland' },
  { label: 'Polañge', value: 'Polañge' },
  { label: 'Polañge', value: 'Polañge' },
  { label: 'Polangui', value: 'Polangui' },
  { label: 'Polanica-Zdrój', value: 'Polanica-Zdrój' },
  { label: 'Połaniec', value: 'Połaniec' },
  { label: 'Polanka Wielka', value: 'Polanka Wielka' },
  { label: 'Polanów', value: 'Polanów' },
  { label: 'Polasara', value: 'Polasara' },
  { label: 'Polateli', value: 'Polateli' },
  { label: 'Polatlı', value: 'Polatlı' },
  { label: 'Polatsk', value: 'Polatsk' },
  { label: 'Polatski Rayon', value: 'Polatski Rayon' },
  { label: 'Polavaram', value: 'Polavaram' },
  { label: 'Polaveno', value: 'Polaveno' },
  { label: 'Polazna', value: 'Polazna' },
  { label: 'Polbeth', value: 'Polbeth' },
  { label: 'Polcenigo', value: 'Polcenigo' },
  { label: 'Polch', value: 'Polch' },
  { label: 'Połczyn-Zdrój', value: 'Połczyn-Zdrój' },
  { label: 'Poldasht', value: 'Poldasht' },
  { label: 'Poldnëvoye', value: 'Poldnëvoye' },
  { label: 'Pol-E Dokhtar', value: 'Pol-E Dokhtar' },
  { label: 'Pole Ojea', value: 'Pole Ojea' },
  { label: 'Polel Diaoubé', value: 'Polel Diaoubé' },
  { label: 'Poleñino', value: 'Poleñino' },
  { label: 'Polentinos', value: 'Polentinos' },
  { label: 'Polepy', value: 'Polepy' },
  { label: 'Polesella', value: 'Polesella' },
  { label: 'Polesine Parmense', value: 'Polesine Parmense' },
  { label: 'Polešovice', value: 'Polešovice' },
  { label: 'Polessk', value: 'Polessk' },
  { label: 'Polesworth', value: 'Polesworth' },
  { label: 'Poletayevo', value: 'Poletayevo' },
  { label: 'Polevskoy', value: 'Polevskoy' },
  { label: 'Polewali', value: 'Polewali' },
  { label: 'Polgár', value: 'Polgár' },
  { label: 'Polgárdi', value: 'Polgárdi' },
  { label: 'Poli', value: 'Poli' },
  { label: 'Poli', value: 'Poli' },
  { label: 'Poli', value: 'Poli' },
  { label: 'Polia', value: 'Polia' },
  { label: 'Polianka', value: 'Polianka' },
  { label: 'Poliçan', value: 'Poliçan' },
  { label: 'Polícar', value: 'Polícar' },
  { label: 'Policarpa', value: 'Policarpa' },
  { label: 'Policastro Bussentino', value: 'Policastro Bussentino' },
  { label: 'Police', value: 'Police' },
  { label: 'Police Nad Metují', value: 'Police Nad Metují' },
  { label: 'Políchni', value: 'Políchni' },
  { label: 'Polička', value: 'Polička' },
  { label: 'Poličnik', value: 'Poličnik' },
  { label: 'Policoro', value: 'Policoro' },
  { label: 'Policzna', value: 'Policzna' },
  { label: 'Polignac', value: 'Polignac' },
  { label: 'Polignano A Mare', value: 'Polignano A Mare' },
  { label: 'Poligny', value: 'Poligny' },
  { label: 'Polígonos', value: 'Polígonos' },
  { label: 'Polillo', value: 'Polillo' },
  { label: 'Polinago', value: 'Polinago' },
  { label: 'Polino', value: 'Polino' },
  { label: 'Polinyà', value: 'Polinyà' },
  { label: 'Polinyà De Xúquer', value: 'Polinyà De Xúquer' },
  { label: 'Pólis', value: 'Pólis' },
  { label: 'Polistena', value: 'Polistena' },
  { label: 'Politiká', value: 'Politiká' },
  { label: 'Politischer Bezirk Amstetten', value: 'Politischer Bezirk Amstetten' },
  { label: 'Politischer Bezirk Baden', value: 'Politischer Bezirk Baden' },
  { label: 'Politischer Bezirk Bludenz', value: 'Politischer Bezirk Bludenz' },
  { label: 'Politischer Bezirk Braunau Am Inn', value: 'Politischer Bezirk Braunau Am Inn' },
  { label: 'Politischer Bezirk Bregenz', value: 'Politischer Bezirk Bregenz' },
  { label: 'Politischer Bezirk Bruck An Der Leitha', value: 'Politischer Bezirk Bruck An Der Leitha' },
  { label: 'Politischer Bezirk Bruck-Mürzzuschlag', value: 'Politischer Bezirk Bruck-Mürzzuschlag' },
  { label: 'Politischer Bezirk Deutschlandsberg', value: 'Politischer Bezirk Deutschlandsberg' },
  { label: 'Politischer Bezirk Dornbirn', value: 'Politischer Bezirk Dornbirn' },
  { label: 'Politischer Bezirk Eferding', value: 'Politischer Bezirk Eferding' },
  { label: 'Politischer Bezirk Feldkirch', value: 'Politischer Bezirk Feldkirch' },
  { label: 'Politischer Bezirk Freistadt', value: 'Politischer Bezirk Freistadt' },
  { label: 'Politischer Bezirk Gänserndorf', value: 'Politischer Bezirk Gänserndorf' },
  { label: 'Politischer Bezirk Gmünd', value: 'Politischer Bezirk Gmünd' },
  { label: 'Politischer Bezirk Gmunden', value: 'Politischer Bezirk Gmunden' },
  { label: 'Politischer Bezirk Graz-Umgebung', value: 'Politischer Bezirk Graz-Umgebung' },
  { label: 'Politischer Bezirk Grieskirchen', value: 'Politischer Bezirk Grieskirchen' },
  { label: 'Politischer Bezirk Güssing', value: 'Politischer Bezirk Güssing' },
  { label: 'Politischer Bezirk Hallein', value: 'Politischer Bezirk Hallein' },
  { label: 'Politischer Bezirk Hartberg-Fürstenfeld', value: 'Politischer Bezirk Hartberg-Fürstenfeld' },
  { label: 'Politischer Bezirk Hollabrunn', value: 'Politischer Bezirk Hollabrunn' },
  { label: 'Politischer Bezirk Horn', value: 'Politischer Bezirk Horn' },
  { label: 'Politischer Bezirk Imst', value: 'Politischer Bezirk Imst' },
  { label: 'Politischer Bezirk Innsbruck Land', value: 'Politischer Bezirk Innsbruck Land' },
  { label: 'Politischer Bezirk Jennersdorf', value: 'Politischer Bezirk Jennersdorf' },
  { label: 'Politischer Bezirk Kirchdorf An Der Krems', value: 'Politischer Bezirk Kirchdorf An Der Krems' },
  { label: 'Politischer Bezirk Kitzbühel', value: 'Politischer Bezirk Kitzbühel' },
  { label: 'Politischer Bezirk Korneuburg', value: 'Politischer Bezirk Korneuburg' },
  { label: 'Politischer Bezirk Krems', value: 'Politischer Bezirk Krems' },
  { label: 'Politischer Bezirk Kufstein', value: 'Politischer Bezirk Kufstein' },
  { label: 'Politischer Bezirk Landeck', value: 'Politischer Bezirk Landeck' },
  { label: 'Politischer Bezirk Leibnitz', value: 'Politischer Bezirk Leibnitz' },
  { label: 'Politischer Bezirk Leoben', value: 'Politischer Bezirk Leoben' },
  { label: 'Politischer Bezirk Lienz', value: 'Politischer Bezirk Lienz' },
  { label: 'Politischer Bezirk Liezen', value: 'Politischer Bezirk Liezen' },
  { label: 'Politischer Bezirk Lilienfeld', value: 'Politischer Bezirk Lilienfeld' },
  { label: 'Politischer Bezirk Linz-Land', value: 'Politischer Bezirk Linz-Land' },
  { label: 'Politischer Bezirk Mattersburg', value: 'Politischer Bezirk Mattersburg' },
  { label: 'Politischer Bezirk Melk', value: 'Politischer Bezirk Melk' },
  { label: 'Politischer Bezirk Mistelbach', value: 'Politischer Bezirk Mistelbach' },
  { label: 'Politischer Bezirk Mödling', value: 'Politischer Bezirk Mödling' },
  { label: 'Politischer Bezirk Murau', value: 'Politischer Bezirk Murau' },
  { label: 'Politischer Bezirk Murtal', value: 'Politischer Bezirk Murtal' },
  { label: 'Politischer Bezirk Neunkirchen', value: 'Politischer Bezirk Neunkirchen' },
  { label: 'Politischer Bezirk Neusiedl Am See', value: 'Politischer Bezirk Neusiedl Am See' },
  { label: 'Politischer Bezirk Oberpullendorf', value: 'Politischer Bezirk Oberpullendorf' },
  { label: 'Politischer Bezirk Oberwart', value: 'Politischer Bezirk Oberwart' },
  { label: 'Politischer Bezirk Perg', value: 'Politischer Bezirk Perg' },
  { label: 'Politischer Bezirk Reutte', value: 'Politischer Bezirk Reutte' },
  { label: 'Politischer Bezirk Ried Im Innkreis', value: 'Politischer Bezirk Ried Im Innkreis' },
  { label: 'Politischer Bezirk Rohrbach', value: 'Politischer Bezirk Rohrbach' },
  { label: 'Politischer Bezirk Salzburg-Umgebung', value: 'Politischer Bezirk Salzburg-Umgebung' },
  { label: 'Politischer Bezirk Sankt Johann Im Pongau', value: 'Politischer Bezirk Sankt Johann Im Pongau' },
  { label: 'Politischer Bezirk Sankt Pölten', value: 'Politischer Bezirk Sankt Pölten' },
  { label: 'Politischer Bezirk Schärding', value: 'Politischer Bezirk Schärding' },
  { label: 'Politischer Bezirk Scheibbs', value: 'Politischer Bezirk Scheibbs' },
  { label: 'Politischer Bezirk Schwaz', value: 'Politischer Bezirk Schwaz' },
  { label: 'Politischer Bezirk Steyr-Land', value: 'Politischer Bezirk Steyr-Land' },
  { label: 'Politischer Bezirk Südoststeiermark', value: 'Politischer Bezirk Südoststeiermark' },
  { label: 'Politischer Bezirk Tamsweg', value: 'Politischer Bezirk Tamsweg' },
  { label: 'Politischer Bezirk Tulln', value: 'Politischer Bezirk Tulln' },
  { label: 'Politischer Bezirk Urfahr-Umgebung', value: 'Politischer Bezirk Urfahr-Umgebung' },
  { label: 'Politischer Bezirk Vöcklabruck', value: 'Politischer Bezirk Vöcklabruck' },
  { label: 'Politischer Bezirk Voitsberg', value: 'Politischer Bezirk Voitsberg' },
  { label: 'Politischer Bezirk Waidhofen An Der Thaya', value: 'Politischer Bezirk Waidhofen An Der Thaya' },
  { label: 'Politischer Bezirk Weiz', value: 'Politischer Bezirk Weiz' },
  { label: 'Politischer Bezirk Wiener Neustadt', value: 'Politischer Bezirk Wiener Neustadt' },
  { label: 'Politischer Bezirk Zwettl', value: 'Politischer Bezirk Zwettl' },
  { label: 'Pölitz', value: 'Pölitz' },
  { label: 'Polizzi Generosa', value: 'Polizzi Generosa' },
  { label: 'Poljčane', value: 'Poljčane' },
  { label: 'Polje', value: 'Polje' },
  { label: 'Polje-Bijela', value: 'Polje-Bijela' },
  { label: 'Polk City', value: 'Polk City' },
  { label: 'Polk City', value: 'Polk City' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polk County', value: 'Polk County' },
  { label: 'Polkowice', value: 'Polkowice' },
  { label: 'Polkton', value: 'Polkton' },
  { label: 'Poll', value: 'Poll' },
  { label: 'Polla', value: 'Polla' },
  { label: 'Pollachi', value: 'Pollachi' },
  { label: 'Pollap Municipality', value: 'Pollap Municipality' },
  { label: 'Pöllau', value: 'Pöllau' },
  { label: 'Pöllauberg', value: 'Pöllauberg' },
  { label: 'Polle', value: 'Polle' },
  { label: 'Polleben', value: 'Polleben' },
  { label: 'Pollein', value: 'Pollein' },
  { label: 'Pollena Trocchia', value: 'Pollena Trocchia' },
  { label: 'Pollença', value: 'Pollença' },
  { label: 'Pollenza', value: 'Pollenza' },
  { label: 'Pollestres', value: 'Pollestres' },
  { label: 'Pollhagen', value: 'Pollhagen' },
  { label: 'Polliat', value: 'Polliat' },
  { label: 'Pollica', value: 'Pollica' },
  { label: 'Pollina', value: 'Pollina' },
  { label: 'Polling', value: 'Polling' },
  { label: 'Polling In Tirol', value: 'Polling In Tirol' },
  { label: 'Pollington', value: 'Pollington' },
  { label: 'Pollionnay', value: 'Pollionnay' },
  { label: 'Polloc', value: 'Polloc' },
  { label: 'Pollock Pines', value: 'Pollock Pines' },
  { label: 'Pollone', value: 'Pollone' },
  { label: 'Pollos', value: 'Pollos' },
  { label: 'Pollutri', value: 'Pollutri' },
  { label: 'Polminhac', value: 'Polminhac' },
  { label: 'Polmont', value: 'Polmont' },
  { label: 'Polná', value: 'Polná' },
  { label: 'Polo', value: 'Polo' },
  { label: 'Polo', value: 'Polo' },
  { label: 'Polo', value: 'Polo' },
  { label: 'Polo', value: 'Polo' },
  { label: 'Polo', value: 'Polo' },
  { label: 'Polo', value: 'Polo' },
  { label: 'Polo', value: 'Polo' },
  { label: 'Polo', value: 'Polo' },
  { label: 'Poloboc', value: 'Poloboc' },
  { label: 'Polohy', value: 'Polohy' },
  { label: 'Polokwane', value: 'Polokwane' },
  { label: 'Pololcingo', value: 'Pololcingo' },
  { label: 'Połomia', value: 'Połomia' },
  { label: 'Połomia', value: 'Połomia' },
  { label: 'Polomolok', value: 'Polomolok' },
  { label: 'Polomolok', value: 'Polomolok' },
  { label: 'Polong', value: 'Polong' },
  { label: 'Polong', value: 'Polong' },
  { label: 'Polong Norte', value: 'Polong Norte' },
  { label: 'Polong Norte', value: 'Polong Norte' },
  { label: 'Polonghera', value: 'Polonghera' },
  { label: 'Poloni', value: 'Poloni' },
  { label: 'Polonnaruwa', value: 'Polonnaruwa' },
  { label: 'Polonnaruwa District', value: 'Polonnaruwa District' },
  { label: 'Polonne', value: 'Polonne' },
  { label: 'Polonoling', value: 'Polonoling' },
  { label: 'Polonoling', value: 'Polonoling' },
  { label: 'Polonuevo', value: 'Polonuevo' },
  { label: 'Polop', value: 'Polop' },
  { label: 'Polopina', value: 'Polopina' },
  { label: 'Polopos', value: 'Polopos' },
  { label: 'Polotitlán', value: 'Polotitlán' },
  { label: 'Polotitlán De La Ilustración', value: 'Polotitlán De La Ilustración' },
  { label: 'Polotnyanyy Zavod', value: 'Polotnyanyy Zavod' },
  { label: 'Polovinnoye', value: 'Polovinnoye' },
  { label: 'Polovinnoye', value: 'Polovinnoye' },
  { label: 'Polovragi', value: 'Polovragi' },
  { label: 'Polowat Municipality', value: 'Polowat Municipality' },
  { label: 'Polpenazze Del Garda', value: 'Polpenazze Del Garda' },
  { label: 'Polperro', value: 'Polperro' },
  { label: 'Pöls', value: 'Pöls' },
  { label: 'Polsingen', value: 'Polsingen' },
  { label: 'Polska Cerekiew', value: 'Polska Cerekiew' },
  { label: 'Polski Trambesh', value: 'Polski Trambesh' },
  { label: 'Polson', value: 'Polson' },
  { label: 'Poltár', value: 'Poltár' },
  { label: 'Poltava', value: 'Poltava' },
  { label: 'Poltavka', value: 'Poltavka' },
  { label: 'Poltavka', value: 'Poltavka' },
  { label: 'Poltavsky', value: 'Poltavsky' },
  { label: 'Põltsamaa', value: 'Põltsamaa' },
  { label: 'Põltsamaa Vald', value: 'Põltsamaa Vald' },
  { label: 'Polunochnoye', value: 'Polunochnoye' },
  { label: 'Polur', value: 'Polur' },
  { label: 'Polutla', value: 'Polutla' },
  { label: 'Põlva', value: 'Põlva' },
  { label: 'Põlva Vald', value: 'Põlva Vald' },
  { label: 'Polverara', value: 'Polverara' },
  { label: 'Polverigi', value: 'Polverigi' },
  { label: 'Polvica', value: 'Polvica' },
  { label: 'Polvijärvi', value: 'Polvijärvi' },
  { label: 'Polvillos (San Bartolo Quinta Sección)', value: 'Polvillos (San Bartolo Quinta Sección)' },
  { label: 'Polyanka', value: 'Polyanka' },
  { label: 'Polyany', value: 'Polyany' },
  { label: 'Polyarnyy', value: 'Polyarnyy' },
  { label: 'Polyarnyye Zori', value: 'Polyarnyye Zori' },
  { label: 'Polydéndri', value: 'Polydéndri' },
  { label: 'Polýgyros', value: 'Polýgyros' },
  { label: 'Polykárpi', value: 'Polykárpi' },
  { label: 'Polýkastro', value: 'Polýkastro' },
  { label: 'Polysayevo', value: 'Polysayevo' },
  { label: 'Polyuc', value: 'Polyuc' },
  { label: 'Polyushko', value: 'Polyushko' },
  { label: 'Polzeath', value: 'Polzeath' },
  { label: 'Polzela', value: 'Polzela' },
  { label: 'Pölzig', value: 'Pölzig' },
  { label: 'Pom Prap Sattru Phai', value: 'Pom Prap Sattru Phai' },
  { label: 'Pomabamba', value: 'Pomabamba' },
  { label: 'Pomacuarán', value: 'Pomacuarán' },
  { label: 'Pomán', value: 'Pomán' },
  { label: 'Pomar De Valdivia', value: 'Pomar De Valdivia' },
  { label: 'Pomarance', value: 'Pomarance' },
  { label: 'Pomares', value: 'Pomares' },
  { label: 'Pomaretto', value: 'Pomaretto' },
  { label: 'Pomarez', value: 'Pomarez' },
  { label: 'Pomarico', value: 'Pomarico' },
  { label: 'Pomarkku', value: 'Pomarkku' },
  { label: 'Pomârla', value: 'Pomârla' },
  { label: 'Pomaro Monferrato', value: 'Pomaro Monferrato' },
  { label: 'Pomarolo', value: 'Pomarolo' },
  { label: 'Pomary', value: 'Pomary' },
  { label: 'Pomáz', value: 'Pomáz' },
  { label: 'Pombal', value: 'Pombal' },
  { label: 'Pombal', value: 'Pombal' },
  { label: 'Pombas', value: 'Pombas' },
  { label: 'Pombia', value: 'Pombia' },
  { label: 'Pombos', value: 'Pombos' },
  { label: 'Pomer', value: 'Pomer' },
  { label: 'Pomerode', value: 'Pomerode' },
  { label: 'Pomérols', value: 'Pomérols' },
  { label: 'Pomeroy', value: 'Pomeroy' },
  { label: 'Pomeroy', value: 'Pomeroy' },
  { label: 'Pometo', value: 'Pometo' },
  { label: 'Pomezeu', value: 'Pomezeu' },
  { label: 'Pomezí', value: 'Pomezí' },
  { label: 'Pomezia', value: 'Pomezia' },
  { label: 'Pomi', value: 'Pomi' },
  { label: 'Pomichna', value: 'Pomichna' },
  { label: 'Pomiechówek', value: 'Pomiechówek' },
  { label: 'Pomigliano Darco', value: 'Pomigliano Darco' },
  { label: 'Pomio', value: 'Pomio' },
  { label: 'Pomme', value: 'Pomme' },
  { label: 'Pommelsbrunn', value: 'Pommelsbrunn' },
  { label: 'Pommeret', value: 'Pommeret' },
  { label: 'Pommerit-Jaudy', value: 'Pommerit-Jaudy' },
  { label: 'Pommerit-Le-Vicomte', value: 'Pommerit-Le-Vicomte' },
  { label: 'Pommersfelden', value: 'Pommersfelden' },
  { label: 'Pommeuse', value: 'Pommeuse' },
  { label: 'Pommiers', value: 'Pommiers' },
  { label: 'Pomoca', value: 'Pomoca' },
  { label: 'Pomona', value: 'Pomona' },
  { label: 'Pomona', value: 'Pomona' },
  { label: 'Pomona', value: 'Pomona' },
  { label: 'Pomona', value: 'Pomona' },
  { label: 'Pomoravlje', value: 'Pomoravlje' },
  { label: 'Pomoriany', value: 'Pomoriany' },
  { label: 'Pomorie', value: 'Pomorie' },
  { label: 'Pompaire', value: 'Pompaire' },
  { label: 'Pompano Beach', value: 'Pompano Beach' },
  { label: 'Pompano Beach Highlands', value: 'Pompano Beach Highlands' },
  { label: 'Pompaples', value: 'Pompaples' },
  { label: 'Pompei', value: 'Pompei' },
  { label: 'Pompéia', value: 'Pompéia' },
  { label: 'Pompeiana', value: 'Pompeiana' },
  { label: 'Pompertuzat', value: 'Pompertuzat' },
  { label: 'Pompéu', value: 'Pompéu' },
  { label: 'Pompey', value: 'Pompey' },
  { label: 'Pompiano', value: 'Pompiano' },
  { label: 'Pompignac', value: 'Pompignac' },
  { label: 'Pompignan', value: 'Pompignan' },
  { label: 'Pomponesco', value: 'Pomponesco' },
  { label: 'Pomponne', value: 'Pomponne' },
  { label: 'Pomposo Castellanos', value: 'Pomposo Castellanos' },
  { label: 'Pompton Lakes', value: 'Pompton Lakes' },
  { label: 'Pompu', value: 'Pompu' },
  { label: 'Pomuch', value: 'Pomuch' },
  { label: 'Ponazyrevo', value: 'Ponazyrevo' },
  { label: 'Ponazyrevskiy Rayon', value: 'Ponazyrevskiy Rayon' },
  { label: 'Ponca', value: 'Ponca' },
  { label: 'Ponca City', value: 'Ponca City' },
  { label: 'Poncarale', value: 'Poncarale' },
  { label: 'Ponce', value: 'Ponce' },
  { label: 'Ponce', value: 'Ponce' },
  { label: 'Ponce Inlet', value: 'Ponce Inlet' },
  { label: 'Ponchatoula', value: 'Ponchatoula' },
  { label: 'Ponchon', value: 'Ponchon' },
  { label: 'Ponciano Arriaga', value: 'Ponciano Arriaga' },
  { label: 'Poncin', value: 'Poncin' },
  { label: 'Poncitlán Municipality', value: 'Poncitlán Municipality' },
  { label: 'Ponda', value: 'Ponda' },
  { label: 'Pondaguitan', value: 'Pondaguitan' },
  { label: 'Pondaguitan', value: 'Pondaguitan' },
  { label: 'Pondaguitan', value: 'Pondaguitan' },
  { label: 'Ponder', value: 'Ponder' },
  { label: 'Pondera County', value: 'Pondera County' },
  { label: 'Ponderano', value: 'Ponderano' },
  { label: 'Ponderay', value: 'Ponderay' },
  { label: 'Ponderosa Park', value: 'Ponderosa Park' },
  { label: 'Ponderosa Pine', value: 'Ponderosa Pine' },
  { label: 'Pondside', value: 'Pondside' },
  { label: 'Pondside', value: 'Pondside' },
  { label: 'Ponedera', value: 'Ponedera' },
  { label: 'Ponezhukay', value: 'Ponezhukay' },
  { label: 'Ponferrada', value: 'Ponferrada' },
  { label: 'Pong', value: 'Pong' },
  { label: 'Pong Nam Ron', value: 'Pong Nam Ron' },
  { label: 'Ponga', value: 'Ponga' },
  { label: 'Pongaí', value: 'Pongaí' },
  { label: 'Pongol', value: 'Pongol' },
  { label: 'Poniatowa', value: 'Poniatowa' },
  { label: 'Poniec', value: 'Poniec' },
  { label: 'Ponikiew', value: 'Ponikiew' },
  { label: 'Poniklá', value: 'Poniklá' },
  { label: 'Poninka', value: 'Poninka' },
  { label: 'Ponitz', value: 'Ponitz' },
  { label: 'Ponmana', value: 'Ponmana' },
  { label: 'Ponna Superiore', value: 'Ponna Superiore' },
  { label: 'Ponnamaravati', value: 'Ponnamaravati' },
  { label: 'Ponnampet', value: 'Ponnampet' },
  { label: 'Ponnani', value: 'Ponnani' },
  { label: 'Ponneri', value: 'Ponneri' },
  { label: 'Ponnur', value: 'Ponnur' },
  { label: 'Ponnuru', value: 'Ponnuru' },
  { label: 'Ponoarele', value: 'Ponoarele' },
  { label: 'Ponoka', value: 'Ponoka' },
  { label: 'Ponong', value: 'Ponong' },
  { label: 'Ponor', value: 'Ponor' },
  { label: 'Ponorogo', value: 'Ponorogo' },
  { label: 'Ponot', value: 'Ponot' },
  { label: 'Pons', value: 'Pons' },
  { label: 'Ponsacco', value: 'Ponsacco' },
  { label: 'Ponso', value: 'Ponso' },
  { label: 'Ponso', value: 'Ponso' },
  { label: 'Pont Cassé', value: 'Pont Cassé' },
  { label: 'Pont Darmentera', value: 'Pont Darmentera' },
  { label: 'Pont De Bar El', value: 'Pont De Bar El' },
  { label: 'Pont De Molins', value: 'Pont De Molins' },
  { label: 'Pont De Suert El', value: 'Pont De Suert El' },
  { label: 'Pont De Vilomara I Rocafort El', value: 'Pont De Vilomara I Rocafort El' },
  { label: 'Pont De Vivaux', value: 'Pont De Vivaux' },
  { label: 'Pont Rhyd-Y-Cyff', value: 'Pont Rhyd-Y-Cyff' },
  { label: 'Pont Rouge', value: 'Pont Rouge' },
  { label: 'Ponta De Pedras', value: 'Ponta De Pedras' },
  { label: 'Ponta Delgada', value: 'Ponta Delgada' },
  { label: 'Ponta Do Sol', value: 'Ponta Do Sol' },
  { label: 'Ponta Do Sol', value: 'Ponta Do Sol' },
  { label: 'Ponta Garça', value: 'Ponta Garça' },
  { label: 'Ponta Grossa', value: 'Ponta Grossa' },
  { label: 'Ponta Porã', value: 'Ponta Porã' },
  { label: 'Pont-À-Celles', value: 'Pont-À-Celles' },
  { label: 'Pontacq', value: 'Pontacq' },
  { label: 'Pontailler-Sur-Saône', value: 'Pontailler-Sur-Saône' },
  { label: 'Pontal', value: 'Pontal' },
  { label: 'Pontal Do Araguaia', value: 'Pontal Do Araguaia' },
  { label: 'Pontal Do Paraná', value: 'Pontal Do Paraná' },
  { label: 'Pontalina', value: 'Pontalina' },
  { label: 'Pontalinda', value: 'Pontalinda' },
  { label: 'Pont-À-Marcq', value: 'Pont-À-Marcq' },
  { label: 'Pont-À-Mousson', value: 'Pont-À-Mousson' },
  { label: 'Pontão', value: 'Pontão' },
  { label: 'Pontarddulais', value: 'Pontarddulais' },
  { label: 'Pontarlier', value: 'Pontarlier' },
  { label: 'Pontasserchio', value: 'Pontasserchio' },
  { label: 'Pontassieve', value: 'Pontassieve' },
  { label: 'Pont-Audemer', value: 'Pont-Audemer' },
  { label: 'Pontault-Combault', value: 'Pontault-Combault' },
  { label: 'Pont-Aven', value: 'Pont-Aven' },
  { label: 'Pont-À-Vendin', value: 'Pont-À-Vendin' },
  { label: 'Pont-Bozet', value: 'Pont-Bozet' },
  { label: 'Pont-Canavese', value: 'Pont-Canavese' },
  { label: 'Pontcarré', value: 'Pontcarré' },
  { label: 'Pontcharra', value: 'Pontcharra' },
  { label: 'Pontcharra-Sur-Turdine', value: 'Pontcharra-Sur-Turdine' },
  { label: 'Pontchâteau', value: 'Pontchâteau' },
  { label: 'Pont-Croix', value: 'Pont-Croix' },
  { label: 'Pont-D’Ain', value: 'Pont-D’Ain' },
  { label: 'Pont-De-Chéruy', value: 'Pont-De-Chéruy' },
  { label: 'Pont-De-Larn', value: 'Pont-De-Larn' },
  { label: 'Pont-De-Metz', value: 'Pont-De-Metz' },
  { label: 'Pont-De-Roide', value: 'Pont-De-Roide' },
  { label: 'Pont-De-Salars', value: 'Pont-De-Salars' },
  { label: 'Pont-De-Vaux', value: 'Pont-De-Vaux' },
  { label: 'Pont-De-Veyle', value: 'Pont-De-Veyle' },
  { label: 'Pont-Douilly', value: 'Pont-Douilly' },
  { label: 'Pont-Du-Casse', value: 'Pont-Du-Casse' },
  { label: 'Pont-Du-Château', value: 'Pont-Du-Château' },
  { label: 'Ponte', value: 'Ponte' },
  { label: 'Ponte', value: 'Ponte' },
  { label: 'Ponte A Elsa', value: 'Ponte A Elsa' },
  { label: 'Ponte A Poppi', value: 'Ponte A Poppi' },
  { label: 'Ponte A Tressa', value: 'Ponte A Tressa' },
  { label: 'Ponte Alta', value: 'Ponte Alta' },
  { label: 'Ponte Alta Do Bom Jesus', value: 'Ponte Alta Do Bom Jesus' },
  { label: 'Ponte Alta Do Norte', value: 'Ponte Alta Do Norte' },
  { label: 'Ponte Alta Do Tocantins', value: 'Ponte Alta Do Tocantins' },
  { label: 'Ponte Arche', value: 'Ponte Arche' },
  { label: 'Ponte Branca', value: 'Ponte Branca' },
  { label: 'Ponte Buggianese', value: 'Ponte Buggianese' },
  { label: 'Ponte Caffaro', value: 'Ponte Caffaro' },
  { label: 'Ponte Caldelas', value: 'Ponte Caldelas' },
  { label: 'Ponte Cingoli', value: 'Ponte Cingoli' },
  { label: 'Ponte Da Barca', value: 'Ponte Da Barca' },
  { label: 'Ponte De Lima', value: 'Ponte De Lima' },
  { label: 'Ponte De Sor', value: 'Ponte De Sor' },
  { label: 'Ponte Della Venturina', value: 'Ponte Della Venturina' },
  { label: 'Ponte Dellolio', value: 'Ponte Dellolio' },
  { label: 'Ponte Di Barbarano', value: 'Ponte Di Barbarano' },
  { label: 'Ponte Di Castegnero', value: 'Ponte Di Castegnero' },
  { label: 'Ponte Di Legno', value: 'Ponte Di Legno' },
  { label: 'Ponte Di Nanto', value: 'Ponte Di Nanto' },
  { label: 'Ponte Di Piave', value: 'Ponte Di Piave' },
  { label: 'Ponte Do Rol', value: 'Ponte Do Rol' },
  { label: 'Ponte Felcino', value: 'Ponte Felcino' },
  { label: 'Ponte Galeria-La Pisana', value: 'Ponte Galeria-La Pisana' },
  { label: 'Ponte Gardena', value: 'Ponte Gardena' },
  { label: 'Ponte In Valtellina', value: 'Ponte In Valtellina' },
  { label: 'Ponte Lambro', value: 'Ponte Lambro' },
  { label: 'Ponte Nelle Alpi', value: 'Ponte Nelle Alpi' },
  { label: 'Ponte Nelle Alpi-Polpet', value: 'Ponte Nelle Alpi-Polpet' },
  { label: 'Ponte Nizza', value: 'Ponte Nizza' },
  { label: 'Ponte Nossa', value: 'Ponte Nossa' },
  { label: 'Ponte Nova', value: 'Ponte Nova' },
  { label: 'Ponte Pattoli', value: 'Ponte Pattoli' },
  { label: 'Ponte Preta', value: 'Ponte Preta' },
  { label: 'Ponte Ronca', value: 'Ponte Ronca' },
  { label: 'Ponte San Marco', value: 'Ponte San Marco' },
  { label: 'Ponte San Nicolò', value: 'Ponte San Nicolò' },
  { label: 'Ponte San Pietro', value: 'Ponte San Pietro' },
  { label: 'Ponte Serrada', value: 'Ponte Serrada' },
  { label: 'Ponte Taro', value: 'Ponte Taro' },
  { label: 'Ponte Vedra Beach', value: 'Ponte Vedra Beach' },
  { label: 'Ponteareas', value: 'Ponteareas' },
  { label: 'Pontebba', value: 'Pontebba' },
  { label: 'Pontecagnano', value: 'Pontecagnano' },
  { label: 'Pontecchio Polesine', value: 'Pontecchio Polesine' },
  { label: 'Ponteceso', value: 'Ponteceso' },
  { label: 'Pontecesures', value: 'Pontecesures' },
  { label: 'Pontechianale', value: 'Pontechianale' },
  { label: 'Pontecorvo', value: 'Pontecorvo' },
  { label: 'Pont-Écrepin', value: 'Pont-Écrepin' },
  { label: 'Pontecurone', value: 'Pontecurone' },
  { label: 'Pontedassio', value: 'Pontedassio' },
  { label: 'Pontedera', value: 'Pontedera' },
  { label: 'Pontedeume', value: 'Pontedeume' },
  { label: 'Pontedeva', value: 'Pontedeva' },
  { label: 'Pontefract', value: 'Pontefract' },
  { label: 'Pontegradella', value: 'Pontegradella' },
  { label: 'Ponteilla', value: 'Ponteilla' },
  { label: 'Ponteland', value: 'Ponteland' },
  { label: 'Pontelandolfo', value: 'Pontelandolfo' },
  { label: 'Pontelangorino', value: 'Pontelangorino' },
  { label: 'Pontelatone', value: 'Pontelatone' },
  { label: 'Pontelongo', value: 'Pontelongo' },
  { label: 'Pontenova A', value: 'Pontenova A' },
  { label: 'Pont-En-Royans', value: 'Pont-En-Royans' },
  { label: 'Pontenure', value: 'Pontenure' },
  { label: 'Pontenx-Les-Forges', value: 'Pontenx-Les-Forges' },
  { label: 'Ponteranica', value: 'Ponteranica' },
  { label: 'Ponterio-Pian Di Porto', value: 'Ponterio-Pian Di Porto' },
  { label: 'Pontes De García Rodríguez As', value: 'Pontes De García Rodríguez As' },
  { label: 'Pontes E Lacerda', value: 'Pontes E Lacerda' },
  { label: 'Pontes Gestal', value: 'Pontes Gestal' },
  { label: 'Pontesbury', value: 'Pontesbury' },
  { label: 'Pontestazzemese', value: 'Pontestazzemese' },
  { label: 'Pontestura', value: 'Pontestura' },
  { label: 'Pontevedra', value: 'Pontevedra' },
  { label: 'Pontevedra', value: 'Pontevedra' },
  { label: 'Pontével', value: 'Pontével' },
  { label: 'Pont-Évêque', value: 'Pont-Évêque' },
  { label: 'Pontevico', value: 'Pontevico' },
  { label: 'Pontey', value: 'Pontey' },
  { label: 'Pontfaverger-Moronvilliers', value: 'Pontfaverger-Moronvilliers' },
  { label: 'Pont-Hébert', value: 'Pont-Hébert' },
  { label: 'Ponthierry', value: 'Ponthierry' },
  { label: 'Ponti', value: 'Ponti' },
  { label: 'Ponti Sul Mincio', value: 'Ponti Sul Mincio' },
  { label: 'Pontiac', value: 'Pontiac' },
  { label: 'Pontiac', value: 'Pontiac' },
  { label: 'Pontian', value: 'Pontian' },
  { label: 'Pontian Kechil', value: 'Pontian Kechil' },
  { label: 'Pontianak', value: 'Pontianak' },
  { label: 'Ponticella', value: 'Ponticella' },
  { label: 'Ponticelli', value: 'Ponticelli' },
  { label: 'Ponticino', value: 'Ponticino' },
  { label: 'Pontida', value: 'Pontida' },
  { label: 'Pontils', value: 'Pontils' },
  { label: 'Pontinha', value: 'Pontinha' },
  { label: 'Pontinha', value: 'Pontinha' },
  { label: 'Pontinia', value: 'Pontinia' },
  { label: 'Pontinvrea', value: 'Pontinvrea' },
  { label: 'Pontirolo Nuovo', value: 'Pontirolo Nuovo' },
  { label: 'Pontisméno', value: 'Pontisméno' },
  { label: 'Pontivy', value: 'Pontivy' },
  { label: 'Pont-L’Abbé', value: 'Pont-L’Abbé' },
  { label: 'Pont-L’Abbé-D’Arnoult', value: 'Pont-L’Abbé-D’Arnoult' },
  { label: 'Pont-L’Évêque', value: 'Pont-L’Évêque' },
  { label: 'Pontlevoy', value: 'Pontlevoy' },
  { label: 'Pontlliw', value: 'Pontlliw' },
  { label: 'Ponto Belo', value: 'Ponto Belo' },
  { label: 'Ponto Chique', value: 'Ponto Chique' },
  { label: 'Ponto Dos Volantes', value: 'Ponto Dos Volantes' },
  { label: 'Ponto Novo', value: 'Ponto Novo' },
  { label: 'Pontoglio', value: 'Pontoglio' },
  { label: 'Pontoise', value: 'Pontoise' },
  { label: 'Ponton Dellelce', value: 'Ponton Dellelce' },
  { label: 'Pontonnyy', value: 'Pontonnyy' },
  { label: 'Pontons', value: 'Pontons' },
  { label: 'Pontonx-Sur-Ladour', value: 'Pontonx-Sur-Ladour' },
  { label: 'Pontoon Beach', value: 'Pontoon Beach' },
  { label: 'Pontorson', value: 'Pontorson' },
  { label: 'Pontós', value: 'Pontós' },
  { label: 'Pontotoc', value: 'Pontotoc' },
  { label: 'Pontotoc County', value: 'Pontotoc County' },
  { label: 'Pontotoc County', value: 'Pontotoc County' },
  { label: 'Pontpierre', value: 'Pontpierre' },
  { label: 'Pontpoint', value: 'Pontpoint' },
  { label: 'Pontremoli', value: 'Pontremoli' },
  { label: 'Pont-Remy', value: 'Pont-Remy' },
  { label: 'Pontresina', value: 'Pontresina' },
  { label: 'Pontrieux', value: 'Pontrieux' },
  { label: 'Pont-Rouge', value: 'Pont-Rouge' },
  { label: 'Ponts', value: 'Ponts' },
  { label: 'Pont-Sainte-Marie', value: 'Pont-Sainte-Marie' },
  { label: 'Pont-Sainte-Maxence', value: 'Pont-Sainte-Maxence' },
  { label: 'Pont-Saint-Esprit', value: 'Pont-Saint-Esprit' },
  { label: 'Pont-Saint-Martin', value: 'Pont-Saint-Martin' },
  { label: 'Pont-Saint-Martin', value: 'Pont-Saint-Martin' },
  { label: 'Pont-Saint-Pierre', value: 'Pont-Saint-Pierre' },
  { label: 'Pont-Saint-Vincent', value: 'Pont-Saint-Vincent' },
  { label: 'Pont-Salomon', value: 'Pont-Salomon' },
  { label: 'Pont-Scorff', value: 'Pont-Scorff' },
  { label: 'Pont-Sur-Sambre', value: 'Pont-Sur-Sambre' },
  { label: 'Pont-Sur-Yonne', value: 'Pont-Sur-Yonne' },
  { label: 'Pontvallain', value: 'Pontvallain' },
  { label: 'Pontyates', value: 'Pontyates' },
  { label: 'Pontyberem', value: 'Pontyberem' },
  { label: 'Pontyclun', value: 'Pontyclun' },
  { label: 'Pontycymer', value: 'Pontycymer' },
  { label: 'Pontypool', value: 'Pontypool' },
  { label: 'Pontypridd', value: 'Pontypridd' },
  { label: 'Ponuga', value: 'Ponuga' },
  { label: 'Ponyon', value: 'Ponyon' },
  { label: 'Ponyri Vtoryye', value: 'Ponyri Vtoryye' },
  { label: 'Ponza', value: 'Ponza' },
  { label: 'Ponzano', value: 'Ponzano' },
  { label: 'Ponzano Di Fermo', value: 'Ponzano Di Fermo' },
  { label: 'Ponzano Monferrato', value: 'Ponzano Monferrato' },
  { label: 'Ponzano Romano', value: 'Ponzano Romano' },
  { label: 'Ponzone', value: 'Ponzone' },
  { label: 'Poojapitiya', value: 'Poojapitiya' },
  { label: 'Pool', value: 'Pool' },
  { label: 'Poole', value: 'Poole' },
  { label: 'Pooler', value: 'Pooler' },
  { label: 'Poolesville', value: 'Poolesville' },
  { label: 'Poon-A-Bayabao', value: 'Poon-A-Bayabao' },
  { label: 'Poonamalle', value: 'Poonamalle' },
  { label: 'Poopó', value: 'Poopó' },
  { label: 'Pooraka', value: 'Pooraka' },
  { label: 'Poormans Corner', value: 'Poormans Corner' },
  { label: 'Poortvliet', value: 'Poortvliet' },
  { label: 'Pop', value: 'Pop' },
  { label: 'Popasna', value: 'Popasna' },
  { label: 'Popayán', value: 'Popayán' },
  { label: 'Pope County', value: 'Pope County' },
  { label: 'Pope County', value: 'Pope County' },
  { label: 'Pope County', value: 'Pope County' },
  { label: 'Popeni', value: 'Popeni' },
  { label: 'Poperinge', value: 'Poperinge' },
  { label: 'Popeşti', value: 'Popeşti' },
  { label: 'Popeşti', value: 'Popeşti' },
  { label: 'Popeşti', value: 'Popeşti' },
  { label: 'Popeşti', value: 'Popeşti' },
  { label: 'Popești', value: 'Popești' },
  { label: 'Popești', value: 'Popești' },
  { label: 'Popești', value: 'Popești' },
  { label: 'Popeşti-Leordeni', value: 'Popeşti-Leordeni' },
  { label: 'Popielów', value: 'Popielów' },
  { label: 'Popilnia', value: 'Popilnia' },
  { label: 'Popilnia', value: 'Popilnia' },
  { label: 'Popilnia Raion', value: 'Popilnia Raion' },
  { label: 'Popivka', value: 'Popivka' },
  { label: 'Poplaca', value: 'Poplaca' },
  { label: 'Poplar Bluff', value: 'Poplar Bluff' },
  { label: 'Poplar Grove', value: 'Poplar Grove' },
  { label: 'Poplar-Cotton Center', value: 'Poplar-Cotton Center' },
  { label: 'Poplarville', value: 'Poplarville' },
  { label: 'Popo Park', value: 'Popo Park' },
  { label: 'Popola', value: 'Popola' },
  { label: 'Popoli', value: 'Popoli' },
  { label: 'Popolnah', value: 'Popolnah' },
  { label: 'Popondetta', value: 'Popondetta' },
  { label: 'Poporogi', value: 'Poporogi' },
  { label: 'Popova', value: 'Popova' },
  { label: 'Popovača', value: 'Popovača' },
  { label: 'Popovača', value: 'Popovača' },
  { label: 'Popoveni', value: 'Popoveni' },
  { label: 'Popovi', value: 'Popovi' },
  { label: 'Popovice', value: 'Popovice' },
  { label: 'Popovo', value: 'Popovo' },
  { label: 'Popovo', value: 'Popovo' },
  { label: 'Popovsky', value: 'Popovsky' },
  { label: 'Poppenbüttel', value: 'Poppenbüttel' },
  { label: 'Poppendorf', value: 'Poppendorf' },
  { label: 'Poppenhausen', value: 'Poppenhausen' },
  { label: 'Poppenhausen', value: 'Poppenhausen' },
  { label: 'Poppenricht', value: 'Poppenricht' },
  { label: 'Poppi', value: 'Poppi' },
  { label: 'Poprad', value: 'Poprad' },
  { label: 'Popricani', value: 'Popricani' },
  { label: 'Poptún', value: 'Poptún' },
  { label: 'Populina', value: 'Populina' },
  { label: 'Poputnaya', value: 'Poputnaya' },
  { label: 'Poquonock Bridge', value: 'Poquonock Bridge' },
  { label: 'Poquoson', value: 'Poquoson' },
  { label: 'Porąbka', value: 'Porąbka' },
  { label: 'Porąbka Uszewska', value: 'Porąbka Uszewska' },
  { label: 'Porac', value: 'Porac' },
  { label: 'Porac', value: 'Porac' },
  { label: 'Porais', value: 'Porais' },
  { label: 'Porais', value: 'Porais' },
  { label: 'Poraj', value: 'Poraj' },
  { label: 'Poranga', value: 'Poranga' },
  { label: 'Porangaba', value: 'Porangaba' },
  { label: 'Porangatu', value: 'Porangatu' },
  { label: 'Porano', value: 'Porano' },
  { label: 'Poraż', value: 'Poraż' },
  { label: 'Porbandar', value: 'Porbandar' },
  { label: 'Porcari', value: 'Porcari' },
  { label: 'Porcelette', value: 'Porcelette' },
  { label: 'Porcellengo', value: 'Porcellengo' },
  { label: 'Porches', value: 'Porches' },
  { label: 'Porcheville', value: 'Porcheville' },
  { label: 'Porcia', value: 'Porcia' },
  { label: 'Porcieu', value: 'Porcieu' },
  { label: 'Porciúncula', value: 'Porciúncula' },
  { label: 'Porcsalma', value: 'Porcsalma' },
  { label: 'Porcuna', value: 'Porcuna' },
  { label: 'Porcupine', value: 'Porcupine' },
  { label: 'Pordanjan', value: 'Pordanjan' },
  { label: 'Pordenone', value: 'Pordenone' },
  { label: 'Pordic', value: 'Pordic' },
  { label: 'Pordim', value: 'Pordim' },
  { label: 'Pore', value: 'Pore' },
  { label: 'Poręba', value: 'Poręba' },
  { label: 'Poręba', value: 'Poręba' },
  { label: 'Poręba Spytkowska', value: 'Poręba Spytkowska' },
  { label: 'Poręba Wielka', value: 'Poręba Wielka' },
  { label: 'Poreč', value: 'Poreč' },
  { label: 'Porecatu', value: 'Porecatu' },
  { label: 'Porech’Ye-Rybnoye', value: 'Porech’Ye-Rybnoye' },
  { label: 'Poreč-Parenzo', value: 'Poreč-Parenzo' },
  { label: 'Poretskiy Rayon', value: 'Poretskiy Rayon' },
  { label: 'Poretskoye', value: 'Poretskoye' },
  { label: 'Porfirío Díaz', value: 'Porfirío Díaz' },
  { label: 'Porgera', value: 'Porgera' },
  { label: 'Pori', value: 'Pori' },
  { label: 'Poříčany', value: 'Poříčany' },
  { label: 'Poříčí Nad Sázavou', value: 'Poříčí Nad Sázavou' },
  { label: 'Poringland', value: 'Poringland' },
  { label: 'Porirua', value: 'Porirua' },
  { label: 'Porkeri', value: 'Porkeri' },
  { label: 'Porkhov', value: 'Porkhov' },
  { label: 'Porkhovskiy Rayon', value: 'Porkhovskiy Rayon' },
  { label: 'Porlamar', value: 'Porlamar' },
  { label: 'Porlezza', value: 'Porlezza' },
  { label: 'Porlock', value: 'Porlock' },
  { label: 'Pormpuraaw', value: 'Pormpuraaw' },
  { label: 'Pornainen', value: 'Pornainen' },
  { label: 'Pornassio', value: 'Pornassio' },
  { label: 'Pörnbach', value: 'Pörnbach' },
  { label: 'Pornic', value: 'Pornic' },
  { label: 'Pornichet', value: 'Pornichet' },
  { label: 'Poro', value: 'Poro' },
  { label: 'Porohy', value: 'Porohy' },
  { label: 'Poroina Mare', value: 'Poroina Mare' },
  { label: 'Poroj', value: 'Poroj' },
  { label: 'Poronaysk', value: 'Poronaysk' },
  { label: 'Poronin', value: 'Poronin' },
  { label: 'Póros', value: 'Póros' },
  { label: 'Póros', value: 'Póros' },
  { label: 'Poroschia', value: 'Poroschia' },
  { label: 'Poroshkovo', value: 'Poroshkovo' },
  { label: 'Porosozero', value: 'Porosozero' },
  { label: 'Poroszló', value: 'Poroszló' },
  { label: 'Porotto-Cassama', value: 'Porotto-Cassama' },
  { label: 'Porpetto', value: 'Porpetto' },
  { label: 'Porporano', value: 'Porporano' },
  { label: 'Porqueira', value: 'Porqueira' },
  { label: 'Porqueres', value: 'Porqueres' },
  { label: 'Porrentruy', value: 'Porrentruy' },
  { label: 'Porrentruy District', value: 'Porrentruy District' },
  { label: 'Porrera', value: 'Porrera' },
  { label: 'Porreres', value: 'Porreres' },
  { label: 'Porretta Terme', value: 'Porretta Terme' },
  { label: 'Porriño O', value: 'Porriño O' },
  { label: 'Porsa', value: 'Porsa' },
  { label: 'Porsanger', value: 'Porsanger' },
  { label: 'Porschdorf', value: 'Porschdorf' },
  { label: 'Porsgrunn', value: 'Porsgrunn' },
  { label: 'Porspoder', value: 'Porspoder' },
  { label: 'Port À Binson', value: 'Port À Binson' },
  { label: 'Port Adelaide', value: 'Port Adelaide' },
  { label: 'Port Adelaide Enfield', value: 'Port Adelaide Enfield' },
  { label: 'Port Alberni', value: 'Port Alberni' },
  { label: 'Port Alfred', value: 'Port Alfred' },
  { label: 'Port Allegany', value: 'Port Allegany' },
  { label: 'Port Allen', value: 'Port Allen' },
  { label: 'Port Angeles', value: 'Port Angeles' },
  { label: 'Port Angeles East', value: 'Port Angeles East' },
  { label: 'Port Antonio', value: 'Port Antonio' },
  { label: 'Port Aransas', value: 'Port Aransas' },
  { label: 'Port Area', value: 'Port Area' },
  { label: 'Port Arthur', value: 'Port Arthur' },
  { label: 'Port Augusta', value: 'Port Augusta' },
  { label: 'Port Augusta West', value: 'Port Augusta West' },
  { label: 'Port Bannatyne', value: 'Port Bannatyne' },
  { label: 'Port Barre', value: 'Port Barre' },
  { label: 'Port Barrington', value: 'Port Barrington' },
  { label: 'Port Barton', value: 'Port Barton' },
  { label: 'Port Blair', value: 'Port Blair' },
  { label: 'Port Broughton', value: 'Port Broughton' },
  { label: 'Port Byron', value: 'Port Byron' },
  { label: 'Port Byron', value: 'Port Byron' },
  { label: 'Port Carbon', value: 'Port Carbon' },
  { label: 'Port Charlotte', value: 'Port Charlotte' },
  { label: 'Port Chester', value: 'Port Chester' },
  { label: 'Port Clinton', value: 'Port Clinton' },
  { label: 'Port Colborne', value: 'Port Colborne' },
  { label: 'Port Coquitlam', value: 'Port Coquitlam' },
  { label: 'Port Dalcúdia', value: 'Port Dalcúdia' },
  { label: 'Port De La Selva El', value: 'Port De La Selva El' },
  { label: 'Port De Pollença', value: 'Port De Pollença' },
  { label: 'Port De Sóller', value: 'Port De Sóller' },
  { label: 'Port Denison', value: 'Port Denison' },
  { label: 'Port Dickinson', value: 'Port Dickinson' },
  { label: 'Port Dickson', value: 'Port Dickson' },
  { label: 'Port Douglas', value: 'Port Douglas' },
  { label: 'Port Edwards', value: 'Port Edwards' },
  { label: 'Port El Kantaoui', value: 'Port El Kantaoui' },
  { label: 'Port Elizabeth', value: 'Port Elizabeth' },
  { label: 'Port Elizabeth', value: 'Port Elizabeth' },
  { label: 'Port Elliot', value: 'Port Elliot' },
  { label: 'Port Erroll', value: 'Port Erroll' },
  { label: 'Port Ewen', value: 'Port Ewen' },
  { label: 'Port Fairy', value: 'Port Fairy' },
  { label: 'Port Gibson', value: 'Port Gibson' },
  { label: 'Port Glasgow', value: 'Port Glasgow' },
  { label: 'Port Glaud', value: 'Port Glaud' },
  { label: 'Port Hacking', value: 'Port Hacking' },
  { label: 'Port Hadlock-Irondale', value: 'Port Hadlock-Irondale' },
  { label: 'Port Harcourt', value: 'Port Harcourt' },
  { label: 'Port Hawkesbury', value: 'Port Hawkesbury' },
  { label: 'Port Hedland', value: 'Port Hedland' },
  { label: 'Port Henry', value: 'Port Henry' },
  { label: 'Port Hope', value: 'Port Hope' },
  { label: 'Port Hueneme', value: 'Port Hueneme' },
  { label: 'Port Huron', value: 'Port Huron' },
  { label: 'Port Isabel', value: 'Port Isabel' },
  { label: 'Port Jefferson', value: 'Port Jefferson' },
  { label: 'Port Jefferson Station', value: 'Port Jefferson Station' },
  { label: 'Port Jervis', value: 'Port Jervis' },
  { label: 'Port Kembla', value: 'Port Kembla' },
  { label: 'Port Kennedy', value: 'Port Kennedy' },
  { label: 'Port Labelle', value: 'Port Labelle' },
  { label: 'Port Lavaca', value: 'Port Lavaca' },
  { label: 'Port Lincoln', value: 'Port Lincoln' },
  { label: 'Port Louis', value: 'Port Louis' },
  { label: 'Port Ludlow', value: 'Port Ludlow' },
  { label: 'Port Macquarie', value: 'Port Macquarie' },
  { label: 'Port Macquarie-Hastings', value: 'Port Macquarie-Hastings' },
  { label: 'Port Maria', value: 'Port Maria' },
  { label: 'Port Mathurin', value: 'Port Mathurin' },
  { label: 'Port Mcneill', value: 'Port Mcneill' },
  { label: 'Port Melbourne', value: 'Port Melbourne' },
  { label: 'Port Monmouth', value: 'Port Monmouth' },
  { label: 'Port Moody', value: 'Port Moody' },
  { label: 'Port Morant', value: 'Port Morant' },
  { label: 'Port Moresby', value: 'Port Moresby' },
  { label: 'Port Morris', value: 'Port Morris' },
  { label: 'Port Neches', value: 'Port Neches' },
  { label: 'Port Nelson', value: 'Port Nelson' },
  { label: 'Port Noarlunga', value: 'Port Noarlunga' },
  { label: 'Port Noarlunga South', value: 'Port Noarlunga South' },
  { label: 'Port Norris', value: 'Port Norris' },
  { label: 'Port Oconnor', value: 'Port Oconnor' },
  { label: 'Port Of Spain', value: 'Port Of Spain' },
  { label: 'Port Orange', value: 'Port Orange' },
  { label: 'Port Orchard', value: 'Port Orchard' },
  { label: 'Port Orford', value: 'Port Orford' },
  { label: 'Port Phillip', value: 'Port Phillip' },
  { label: 'Port Pirie', value: 'Port Pirie' },
  { label: 'Port Pirie City And Dists', value: 'Port Pirie City And Dists' },
  { label: 'Port Pirie South', value: 'Port Pirie South' },
  { label: 'Port Pirie West', value: 'Port Pirie West' },
  { label: 'Port Reading', value: 'Port Reading' },
  { label: 'Port Republic', value: 'Port Republic' },
  { label: 'Port Richey', value: 'Port Richey' },
  { label: 'Port Richmond', value: 'Port Richmond' },
  { label: 'Port Rowan', value: 'Port Rowan' },
  { label: 'Port Royal', value: 'Port Royal' },
  { label: 'Port Royal', value: 'Port Royal' },
  { label: 'Port Said', value: 'Port Said' },
  { label: 'Port Saint Joe', value: 'Port Saint Joe' },
  { label: 'Port Saint John', value: 'Port Saint John' },
  { label: 'Port Saint John’S', value: 'Port Saint John’S' },
  { label: 'Port Saint Lucie', value: 'Port Saint Lucie' },
  { label: 'Port Salerno', value: 'Port Salerno' },
  { label: 'Port Shepstone', value: 'Port Shepstone' },
  { label: 'Port Sorell', value: 'Port Sorell' },
  { label: 'Port Stanley', value: 'Port Stanley' },
  { label: 'Port Stephens Shire', value: 'Port Stephens Shire' },
  { label: 'Port Sudan', value: 'Port Sudan' },
  { label: 'Port Sulphur', value: 'Port Sulphur' },
  { label: 'Port Talbot', value: 'Port Talbot' },
  { label: 'Port Townsend', value: 'Port Townsend' },
  { label: 'Port Victoria', value: 'Port Victoria' },
  { label: 'Port Vue', value: 'Port Vue' },
  { label: 'Port Washington', value: 'Port Washington' },
  { label: 'Port Washington', value: 'Port Washington' },
  { label: 'Port Washington North', value: 'Port Washington North' },
  { label: 'Port Wentworth', value: 'Port Wentworth' },
  { label: 'Port Williams', value: 'Port Williams' },
  { label: 'Port Willunga', value: 'Port Willunga' },
  { label: 'Porta Westfalica', value: 'Porta Westfalica' },
  { label: 'Portaceli', value: 'Portaceli' },
  { label: 'Portachuelo', value: 'Portachuelo' },
  { label: 'Portacomaro', value: 'Portacomaro' },
  { label: 'Portadown', value: 'Portadown' },
  { label: 'Portaferry', value: 'Portaferry' },
  { label: 'Portage', value: 'Portage' },
  { label: 'Portage', value: 'Portage' },
  { label: 'Portage', value: 'Portage' },
  { label: 'Portage', value: 'Portage' },
  { label: 'Portage County', value: 'Portage County' },
  { label: 'Portage County', value: 'Portage County' },
  { label: 'Portage La Prairie', value: 'Portage La Prairie' },
  { label: 'Portage Lakes', value: 'Portage Lakes' },
  { label: 'Portage Park', value: 'Portage Park' },
  { label: 'Portageville', value: 'Portageville' },
  { label: 'Portaje', value: 'Portaje' },
  { label: 'Portal De Las Salinas', value: 'Portal De Las Salinas' },
  { label: 'Portalbera', value: 'Portalbera' },
  { label: 'Portalegre', value: 'Portalegre' },
  { label: 'Portalegre', value: 'Portalegre' },
  { label: 'Portales', value: 'Portales' },
  { label: 'Portalrubio De Guadamejud', value: 'Portalrubio De Guadamejud' },
  { label: 'Portão', value: 'Portão' },
  { label: 'Port-À-Piment', value: 'Port-À-Piment' },
  { label: 'Portariá', value: 'Portariá' },
  { label: 'Portariá', value: 'Portariá' },
  { label: 'Portarlington', value: 'Portarlington' },
  { label: 'Portarlington', value: 'Portarlington' },
  { label: 'Portas', value: 'Portas' },
  { label: 'Port-Au-Prince', value: 'Port-Au-Prince' },
  { label: 'Portavogie', value: 'Portavogie' },
  { label: 'Portbail', value: 'Portbail' },
  { label: 'Portbou', value: 'Portbou' },
  { label: 'Port-Brillet', value: 'Port-Brillet' },
  { label: 'Port-Cartier', value: 'Port-Cartier' },
  { label: 'Port-De-Bouc', value: 'Port-De-Bouc' },
  { label: 'Port-De-Paix', value: 'Port-De-Paix' },
  { label: 'Port-Des-Barques', value: 'Port-Des-Barques' },
  { label: 'Porte', value: 'Porte' },
  { label: 'Porteirão', value: 'Porteirão' },
  { label: 'Porteiras', value: 'Porteiras' },
  { label: 'Porteirinha', value: 'Porteirinha' },
  { label: 'Portel', value: 'Portel' },
  { label: 'Portel', value: 'Portel' },
  { label: 'Portela', value: 'Portela' },
  { label: 'Portelândia', value: 'Portelândia' },
  { label: 'Portel-Des-Corbières', value: 'Portel-Des-Corbières' },
  { label: 'Portell De Morella', value: 'Portell De Morella' },
  { label: 'Portella Di Mare', value: 'Portella Di Mare' },
  { label: 'Portella La', value: 'Portella La' },
  { label: 'Portellada La', value: 'Portellada La' },
  { label: 'Porteña', value: 'Porteña' },
  { label: 'Port-En-Bessin-Huppain', value: 'Port-En-Bessin-Huppain' },
  { label: 'Porter', value: 'Porter' },
  { label: 'Porter', value: 'Porter' },
  { label: 'Porter County', value: 'Porter County' },
  { label: 'Porter Heights', value: 'Porter Heights' },
  { label: 'Porterdale', value: 'Porterdale' },
  { label: 'Porters Mountain', value: 'Porters Mountain' },
  { label: 'Porterville', value: 'Porterville' },
  { label: 'Portes Gil', value: 'Portes Gil' },
  { label: 'Portes-Lès-Valence', value: 'Portes-Lès-Valence' },
  { label: 'Portets', value: 'Portets' },
  { label: 'Portet-Sur-Garonne', value: 'Portet-Sur-Garonne' },
  { label: 'Portezuelo', value: 'Portezuelo' },
  { label: 'Portezuelo', value: 'Portezuelo' },
  { label: 'Portezuelo', value: 'Portezuelo' },
  { label: 'Portezuelo', value: 'Portezuelo' },
  { label: 'Port-Gentil', value: 'Port-Gentil' },
  { label: 'Portglenone', value: 'Portglenone' },
  { label: 'Porthcawl', value: 'Porthcawl' },
  { label: 'Porthleven', value: 'Porthleven' },
  { label: 'Porthmadog', value: 'Porthmadog' },
  { label: 'Portici', value: 'Portici' },
  { label: 'Portico Di Caserta', value: 'Portico Di Caserta' },
  { label: 'Portico Di Romagna', value: 'Portico Di Romagna' },
  { label: 'Portico E San Benedetto', value: 'Portico E San Benedetto' },
  { label: 'Pórticos De San Antonio', value: 'Pórticos De San Antonio' },
  { label: 'Portieux', value: 'Portieux' },
  { label: 'Portigliola', value: 'Portigliola' },
  { label: 'Portilla', value: 'Portilla' },
  { label: 'Portillo', value: 'Portillo' },
  { label: 'Portillo De Soria', value: 'Portillo De Soria' },
  { label: 'Portillo De Toledo', value: 'Portillo De Toledo' },
  { label: 'Portimão', value: 'Portimão' },
  { label: 'Portiragnes', value: 'Portiragnes' },
  { label: 'Portishead', value: 'Portishead' },
  { label: 'Port-Katon', value: 'Port-Katon' },
  { label: 'Portknockie', value: 'Portknockie' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Portland Cottage', value: 'Portland Cottage' },
  { label: 'Port-La-Nouvelle', value: 'Port-La-Nouvelle' },
  { label: 'Portlaoise', value: 'Portlaoise' },
  { label: 'Portlaw', value: 'Portlaw' },
  { label: 'Portlethen', value: 'Portlethen' },
  { label: 'Port-Louis', value: 'Port-Louis' },
  { label: 'Port-Margot', value: 'Port-Margot' },
  { label: 'Portmarnock', value: 'Portmarnock' },
  { label: 'Portmore', value: 'Portmore' },
  { label: 'Portneuf', value: 'Portneuf' },
  { label: 'Porto', value: 'Porto' },
  { label: 'Porto', value: 'Porto' },
  { label: 'Porto', value: 'Porto' },
  { label: 'Porto', value: 'Porto' },
  { label: 'Porto Acre', value: 'Porto Acre' },
  { label: 'Porto Alegre', value: 'Porto Alegre' },
  { label: 'Porto Alegre Do Norte', value: 'Porto Alegre Do Norte' },
  { label: 'Porto Alegre Do Piauí', value: 'Porto Alegre Do Piauí' },
  { label: 'Porto Alegre Do Tocantins', value: 'Porto Alegre Do Tocantins' },
  { label: 'Porto Amazonas', value: 'Porto Amazonas' },
  { label: 'Porto Azzurro', value: 'Porto Azzurro' },
  { label: 'Pôrto Barra Do Ivinheima', value: 'Pôrto Barra Do Ivinheima' },
  { label: 'Porto Barreiro', value: 'Porto Barreiro' },
  { label: 'Porto Bello', value: 'Porto Bello' },
  { label: 'Porto Belo', value: 'Porto Belo' },
  { label: 'Porto Calvo', value: 'Porto Calvo' },
  { label: 'Porto Ceresio', value: 'Porto Ceresio' },
  { label: 'Porto Cervo', value: 'Porto Cervo' },
  { label: 'Porto Cesareo', value: 'Porto Cesareo' },
  { label: 'Pórto Chéli', value: 'Pórto Chéli' },
  { label: 'Porto Corsini', value: 'Porto Corsini' },
  { label: 'Porto Covo', value: 'Porto Covo' },
  { label: 'Porto Cristo', value: 'Porto Cristo' },
  { label: 'Porto Da Carne', value: 'Porto Da Carne' },
  { label: 'Porto Da Cruz', value: 'Porto Da Cruz' },
  { label: 'Porto Da Folha', value: 'Porto Da Folha' },
  { label: 'Porto Dadda', value: 'Porto Dadda' },
  { label: 'Porto De Mós', value: 'Porto De Mós' },
  { label: 'Porto De Moz', value: 'Porto De Moz' },
  { label: 'Porto De Pedras', value: 'Porto De Pedras' },
  { label: 'Porto Do Mangue', value: 'Porto Do Mangue' },
  { label: 'Porto Do Son', value: 'Porto Do Son' },
  { label: 'Porto Dos Gaúchos', value: 'Porto Dos Gaúchos' },
  { label: 'Porto Empedocle', value: 'Porto Empedocle' },
  { label: 'Porto Ercole', value: 'Porto Ercole' },
  { label: 'Porto Esperidião', value: 'Porto Esperidião' },
  { label: 'Porto Estrela', value: 'Porto Estrela' },
  { label: 'Porto Feliz', value: 'Porto Feliz' },
  { label: 'Porto Ferreira', value: 'Porto Ferreira' },
  { label: 'Porto Firme', value: 'Porto Firme' },
  { label: 'Porto Franco', value: 'Porto Franco' },
  { label: 'Porto Fuori', value: 'Porto Fuori' },
  { label: 'Porto Garibaldi', value: 'Porto Garibaldi' },
  { label: 'Porto Grande', value: 'Porto Grande' },
  { label: 'Porto Judeu', value: 'Porto Judeu' },
  { label: 'Porto Lucena', value: 'Porto Lucena' },
  { label: 'Porto Martins', value: 'Porto Martins' },
  { label: 'Porto Mauá', value: 'Porto Mauá' },
  { label: 'Porto Moniz', value: 'Porto Moniz' },
  { label: 'Porto Murtinho', value: 'Porto Murtinho' },
  { label: 'Porto Nacional', value: 'Porto Nacional' },
  { label: 'Porto Novo', value: 'Porto Novo' },
  { label: 'Porto Potenza Picena', value: 'Porto Potenza Picena' },
  { label: 'Porto Real', value: 'Porto Real' },
  { label: 'Porto Real Do Colégio', value: 'Porto Real Do Colégio' },
  { label: 'Porto Recanati', value: 'Porto Recanati' },
  { label: 'Porto Rico', value: 'Porto Rico' },
  { label: 'Porto Rico Do Maranhão', value: 'Porto Rico Do Maranhão' },
  { label: 'Porto Salvo', value: 'Porto Salvo' },
  { label: 'Porto Salvo', value: 'Porto Salvo' },
  { label: 'Porto San Giorgio', value: 'Porto San Giorgio' },
  { label: 'Porto Santelpidio', value: 'Porto Santelpidio' },
  { label: 'Porto Santo', value: 'Porto Santo' },
  { label: 'Porto Santo Stefano', value: 'Porto Santo Stefano' },
  { label: 'Porto Seguro', value: 'Porto Seguro' },
  { label: 'Porto Tolle', value: 'Porto Tolle' },
  { label: 'Porto Torres', value: 'Porto Torres' },
  { label: 'Porto União', value: 'Porto União' },
  { label: 'Porto Valtravaglia', value: 'Porto Valtravaglia' },
  { label: 'Porto Velho', value: 'Porto Velho' },
  { label: 'Porto Vera Cruz', value: 'Porto Vera Cruz' },
  { label: 'Porto Viro', value: 'Porto Viro' },
  { label: 'Porto Vitória', value: 'Porto Vitória' },
  { label: 'Porto Walter', value: 'Porto Walter' },
  { label: 'Porto Xavier', value: 'Porto Xavier' },
  { label: 'Portobelillo', value: 'Portobelillo' },
  { label: 'Portobello', value: 'Portobello' },
  { label: 'Portobelo', value: 'Portobelo' },
  { label: 'Portobuffolè', value: 'Portobuffolè' },
  { label: 'Portocannone', value: 'Portocannone' },
  { label: 'Portocolom', value: 'Portocolom' },
  { label: 'Portoferraio', value: 'Portoferraio' },
  { label: 'Portofino', value: 'Portofino' },
  { label: 'Portogruaro', value: 'Portogruaro' },
  { label: 'Portola', value: 'Portola' },
  { label: 'Portola Hills', value: 'Portola Hills' },
  { label: 'Portola Valley', value: 'Portola Valley' },
  { label: 'Port-Olry', value: 'Port-Olry' },
  { label: 'Portomaggiore', value: 'Portomaggiore' },
  { label: 'Portomarín', value: 'Portomarín' },
  { label: 'Porto-Novo', value: 'Porto-Novo' },
  { label: 'Portopalo Di Capo Passero', value: 'Portopalo Di Capo Passero' },
  { label: 'Portorož', value: 'Portorož' },
  { label: 'Portoscuso', value: 'Portoscuso' },
  { label: 'Porto-Vecchio', value: 'Porto-Vecchio' },
  { label: 'Portovelo', value: 'Portovelo' },
  { label: 'Portovenere', value: 'Portovenere' },
  { label: 'Portoviejo', value: 'Portoviejo' },
  { label: 'Portraine', value: 'Portraine' },
  { label: 'Portree', value: 'Portree' },
  { label: 'Portrush', value: 'Portrush' },
  { label: 'Port-Sainte-Foy-Et-Ponchapt', value: 'Port-Sainte-Foy-Et-Ponchapt' },
  { label: 'Port-Sainte-Marie', value: 'Port-Sainte-Marie' },
  { label: 'Port-Saint-Louis-Du-Rhône', value: 'Port-Saint-Louis-Du-Rhône' },
  { label: 'Port-Saint-Père', value: 'Port-Saint-Père' },
  { label: 'Portscatho', value: 'Portscatho' },
  { label: 'Pörtschach Am Wörthersee', value: 'Pörtschach Am Wörthersee' },
  { label: 'Portslade', value: 'Portslade' },
  { label: 'Portsmouth', value: 'Portsmouth' },
  { label: 'Portsmouth', value: 'Portsmouth' },
  { label: 'Portsmouth', value: 'Portsmouth' },
  { label: 'Portsmouth', value: 'Portsmouth' },
  { label: 'Portsmouth', value: 'Portsmouth' },
  { label: 'Portsmouth', value: 'Portsmouth' },
  { label: 'Portsmouth Heights', value: 'Portsmouth Heights' },
  { label: 'Portsoy', value: 'Portsoy' },
  { label: 'Portstewart', value: 'Portstewart' },
  { label: 'Port-Sur-Saône', value: 'Port-Sur-Saône' },
  { label: 'Portugalete', value: 'Portugalete' },
  { label: 'Pórtugos', value: 'Pórtugos' },
  { label: 'Portugués De Gálvez', value: 'Portugués De Gálvez' },
  { label: 'Portula', value: 'Portula' },
  { label: 'Portumna', value: 'Portumna' },
  { label: 'Portvendres', value: 'Portvendres' },
  { label: 'Port-Vila', value: 'Port-Vila' },
  { label: 'Porumbac', value: 'Porumbac' },
  { label: 'Porumbacu De Jos', value: 'Porumbacu De Jos' },
  { label: 'Porumbeni', value: 'Porumbeni' },
  { label: 'Porumbenii Mari', value: 'Porumbenii Mari' },
  { label: 'Porumbești', value: 'Porumbești' },
  { label: 'Porur', value: 'Porur' },
  { label: 'Porus', value: 'Porus' },
  { label: 'Porus', value: 'Porus' },
  { label: 'Porvenir', value: 'Porvenir' },
  { label: 'Porvenir', value: 'Porvenir' },
  { label: 'Porvoo', value: 'Porvoo' },
  { label: 'Porz Am Rhein', value: 'Porz Am Rhein' },
  { label: 'Porzuna', value: 'Porzuna' },
  { label: 'Posada', value: 'Posada' },
  { label: 'Posada', value: 'Posada' },
  { label: 'Posada De Valdeón', value: 'Posada De Valdeón' },
  { label: 'Posadas', value: 'Posadas' },
  { label: 'Posadas', value: 'Posadas' },
  { label: 'Poşaga De Sus', value: 'Poşaga De Sus' },
  { label: 'Posatora', value: 'Posatora' },
  { label: 'Poschiavo', value: 'Poschiavo' },
  { label: 'Posedarje', value: 'Posedarje' },
  { label: 'Poselki', value: 'Poselki' },
  { label: 'Posëlok Mar’Ino', value: 'Posëlok Mar’Ino' },
  { label: 'Posëlok Voskhod', value: 'Posëlok Voskhod' },
  { label: 'Posen', value: 'Posen' },
  { label: 'Poseritz', value: 'Poseritz' },
  { label: 'Poses', value: 'Poses' },
  { label: 'Poseștii-Pământeni', value: 'Poseștii-Pământeni' },
  { label: 'Posey County', value: 'Posey County' },
  { label: 'Poseyville', value: 'Poseyville' },
  { label: 'Poshekhon’Ye', value: 'Poshekhon’Ye' },
  { label: 'Poshekhonskiy Rayon', value: 'Poshekhonskiy Rayon' },
  { label: 'Poshtjin', value: 'Poshtjin' },
  { label: 'Poshtove', value: 'Poshtove' },
  { label: 'Posillipo', value: 'Posillipo' },
  { label: 'Posina', value: 'Posina' },
  { label: 'Posio', value: 'Posio' },
  { label: 'Positano', value: 'Positano' },
  { label: 'Poso', value: 'Poso' },
  { label: 'Posof', value: 'Posof' },
  { label: 'Posoltega', value: 'Posoltega' },
  { label: 'Pospelikha', value: 'Pospelikha' },
  { label: 'Pospelikhinskiy Rayon', value: 'Pospelikhinskiy Rayon' },
  { label: 'Possagno', value: 'Possagno' },
  { label: 'Posse', value: 'Posse' },
  { label: 'Pößneck', value: 'Pößneck' },
  { label: 'Post', value: 'Post' },
  { label: 'Post Falls', value: 'Post Falls' },
  { label: 'Posta', value: 'Posta' },
  { label: 'Posta', value: 'Posta' },
  { label: 'Poşta Câlnău', value: 'Poşta Câlnău' },
  { label: 'Posta Fibreno', value: 'Posta Fibreno' },
  { label: 'Postal', value: 'Postal' },
  { label: 'Postalesio', value: 'Postalesio' },
  { label: 'Postau', value: 'Postau' },
  { label: 'Postbauer-Heng', value: 'Postbauer-Heng' },
  { label: 'Poste De Flacq', value: 'Poste De Flacq' },
  { label: 'Posterholt', value: 'Posterholt' },
  { label: 'Postiglione', value: 'Postiglione' },
  { label: 'Postioma', value: 'Postioma' },
  { label: 'Postira', value: 'Postira' },
  { label: 'Postmasburg', value: 'Postmasburg' },
  { label: 'Postmünster', value: 'Postmünster' },
  { label: 'Posto Da Mata', value: 'Posto Da Mata' },
  { label: 'Pôsto Fiscal Rolim De Moura', value: 'Pôsto Fiscal Rolim De Moura' },
  { label: 'Postojna', value: 'Postojna' },
  { label: 'Postoloprty', value: 'Postoloprty' },
  { label: 'Postřekov', value: 'Postřekov' },
  { label: 'Postřelmov', value: 'Postřelmov' },
  { label: 'Postrer Río', value: 'Postrer Río' },
  { label: 'Postua', value: 'Postua' },
  { label: 'Postupice', value: 'Postupice' },
  { label: 'Postville', value: 'Postville' },
  { label: 'Poświętne', value: 'Poświętne' },
  { label: 'Poświętne', value: 'Poświętne' },
  { label: 'Poświętne', value: 'Poświętne' },
  { label: 'Potala Pastillo', value: 'Potala Pastillo' },
  { label: 'Potam', value: 'Potam' },
  { label: 'Potamiá', value: 'Potamiá' },
  { label: 'Potamós', value: 'Potamós' },
  { label: 'Potanino', value: 'Potanino' },
  { label: 'Potău', value: 'Potău' },
  { label: 'Potchefstroom', value: 'Potchefstroom' },
  { label: 'Potcoava', value: 'Potcoava' },
  { label: 'Potcoava', value: 'Potcoava' },
  { label: 'Potcoava Fălcoeni', value: 'Potcoava Fălcoeni' },
  { label: 'Poté', value: 'Poté' },
  { label: 'Poteau', value: 'Poteau' },
  { label: 'Poteet', value: 'Poteet' },
  { label: 'Potęgowo', value: 'Potęgowo' },
  { label: 'Potelu', value: 'Potelu' },
  { label: 'Potelych', value: 'Potelych' },
  { label: 'Potengi', value: 'Potengi' },
  { label: 'Potenza', value: 'Potenza' },
  { label: 'Potenza Picena', value: 'Potenza Picena' },
  { label: 'Potes', value: 'Potes' },
  { label: 'Poth', value: 'Poth' },
  { label: 'Pothé', value: 'Pothé' },
  { label: 'Potia', value: 'Potia' },
  { label: 'Potigny', value: 'Potigny' },
  { label: 'Potigrafu', value: 'Potigrafu' },
  { label: 'Potiivka', value: 'Potiivka' },
  { label: 'Potim', value: 'Potim' },
  { label: 'Potiraguá', value: 'Potiraguá' },
  { label: 'Potirendaba', value: 'Potirendaba' },
  { label: 'Potiretama', value: 'Potiretama' },
  { label: 'Potiskum', value: 'Potiskum' },
  { label: 'Potlogeni', value: 'Potlogeni' },
  { label: 'Potlogi', value: 'Potlogi' },
  { label: 'Potoceni', value: 'Potoceni' },
  { label: 'Potoci', value: 'Potoci' },
  { label: 'Potok Górny', value: 'Potok Górny' },
  { label: 'Potok Wielki', value: 'Potok Wielki' },
  { label: 'Potok Złoty', value: 'Potok Złoty' },
  { label: 'Potomac', value: 'Potomac' },
  { label: 'Potomac Heights', value: 'Potomac Heights' },
  { label: 'Potomac Mills', value: 'Potomac Mills' },
  { label: 'Potomac Park', value: 'Potomac Park' },
  { label: 'Potoru', value: 'Potoru' },
  { label: 'Potosi', value: 'Potosi' },
  { label: 'Potosi', value: 'Potosi' },
  { label: 'Potosí', value: 'Potosí' },
  { label: 'Potosí', value: 'Potosí' },
  { label: 'Potosí', value: 'Potosí' },
  { label: 'Potot', value: 'Potot' },
  { label: 'Pototan', value: 'Pototan' },
  { label: 'Pototano', value: 'Pototano' },
  { label: 'Potrerillo', value: 'Potrerillo' },
  { label: 'Potrerillo', value: 'Potrerillo' },
  { label: 'Potrerillo Del Norote', value: 'Potrerillo Del Norote' },
  { label: 'Potrerillos', value: 'Potrerillos' },
  { label: 'Potrerillos', value: 'Potrerillos' },
  { label: 'Potrerillos', value: 'Potrerillos' },
  { label: 'Potrerillos', value: 'Potrerillos' },
  { label: 'Potrerillos (Guanajal)', value: 'Potrerillos (Guanajal)' },
  { label: 'Potrerillos Abajo', value: 'Potrerillos Abajo' },
  { label: 'Potrerillos Arriba', value: 'Potrerillos Arriba' },
  { label: 'Potrero De Los Sánchez (Estación Techa)', value: 'Potrero De Los Sánchez (Estación Techa)' },
  { label: 'Potrero Del Llano', value: 'Potrero Del Llano' },
  { label: 'Potrero Grande', value: 'Potrero Grande' },
  { label: 'Potrero Viejo', value: 'Potrero Viejo' },
  { label: 'Potreros', value: 'Potreros' },
  { label: 'Potríes', value: 'Potríes' },
  { label: 'Potsdam', value: 'Potsdam' },
  { label: 'Potsdam', value: 'Potsdam' },
  { label: 'Potsdam', value: 'Potsdam' },
  { label: 'Potštát', value: 'Potštát' },
  { label: 'Pottawatomie County', value: 'Pottawatomie County' },
  { label: 'Pottawatomie County', value: 'Pottawatomie County' },
  { label: 'Pottawattamie County', value: 'Pottawattamie County' },
  { label: 'Pöttelsdorf', value: 'Pöttelsdorf' },
  { label: 'Pottenberg', value: 'Pottenberg' },
  { label: 'Pottenbrunn', value: 'Pottenbrunn' },
  { label: 'Pottendorf', value: 'Pottendorf' },
  { label: 'Pottenstein', value: 'Pottenstein' },
  { label: 'Pottenstein', value: 'Pottenstein' },
  { label: 'Potter County', value: 'Potter County' },
  { label: 'Potter County', value: 'Potter County' },
  { label: 'Potter County', value: 'Potter County' },
  { label: 'Potter Lake', value: 'Potter Lake' },
  { label: 'Potterne', value: 'Potterne' },
  { label: 'Potters Bar', value: 'Potters Bar' },
  { label: 'Potters Village', value: 'Potters Village' },
  { label: 'Potterspury', value: 'Potterspury' },
  { label: 'Potterville', value: 'Potterville' },
  { label: 'Pötting', value: 'Pötting' },
  { label: 'Pöttmes', value: 'Pöttmes' },
  { label: 'Potton', value: 'Potton' },
  { label: 'Potts Point', value: 'Potts Point' },
  { label: 'Pottsboro', value: 'Pottsboro' },
  { label: 'Pottschach', value: 'Pottschach' },
  { label: 'Pöttsching', value: 'Pöttsching' },
  { label: 'Pottsgrove', value: 'Pottsgrove' },
  { label: 'Pottstown', value: 'Pottstown' },
  { label: 'Pottsville', value: 'Pottsville' },
  { label: 'Pottsville', value: 'Pottsville' },
  { label: 'Pottsville', value: 'Pottsville' },
  { label: 'Pottsville Beach', value: 'Pottsville Beach' },
  { label: 'Pottum', value: 'Pottum' },
  { label: 'Potuga', value: 'Potuga' },
  { label: 'Poturo', value: 'Poturo' },
  { label: 'Potworów', value: 'Potworów' },
  { label: 'Potzneusiedl', value: 'Potzneusiedl' },
  { label: 'Pouancé', value: 'Pouancé' },
  { label: 'Pouch', value: 'Pouch' },
  { label: 'Poudre Dor', value: 'Poudre Dor' },
  { label: 'Poudre Dor Hamlet', value: 'Poudre Dor Hamlet' },
  { label: 'Poughkeepsie', value: 'Poughkeepsie' },
  { label: 'Pougues-Les-Eaux', value: 'Pougues-Les-Eaux' },
  { label: 'Pouilley-Les-Vignes', value: 'Pouilley-Les-Vignes' },
  { label: 'Pouillon', value: 'Pouillon' },
  { label: 'Pouilly-En-Auxois', value: 'Pouilly-En-Auxois' },
  { label: 'Pouilly-Lès-Feurs', value: 'Pouilly-Lès-Feurs' },
  { label: 'Pouilly-Les-Nonains', value: 'Pouilly-Les-Nonains' },
  { label: 'Pouilly-Sous-Charlieu', value: 'Pouilly-Sous-Charlieu' },
  { label: 'Pouilly-Sur-Loire', value: 'Pouilly-Sur-Loire' },
  { label: 'Poulainville', value: 'Poulainville' },
  { label: 'Pouldergat', value: 'Pouldergat' },
  { label: 'Pouldreuzic', value: 'Pouldreuzic' },
  { label: 'Pouligny-Saint-Pierre', value: 'Pouligny-Saint-Pierre' },
  { label: 'Poullan-Sur-Mer', value: 'Poullan-Sur-Mer' },
  { label: 'Poullaouen', value: 'Poullaouen' },
  { label: 'Poulsbo', value: 'Poulsbo' },
  { label: 'Poultney', value: 'Poultney' },
  { label: 'Poulton-Le-Fylde', value: 'Poulton-Le-Fylde' },
  { label: 'Poulx', value: 'Poulx' },
  { label: 'Pound Ridge', value: 'Pound Ridge' },
  { label: 'Pourham', value: 'Pourham' },
  { label: 'Pourrain', value: 'Pourrain' },
  { label: 'Pourrières', value: 'Pourrières' },
  { label: 'Pouru-Saint-Remy', value: 'Pouru-Saint-Remy' },
  { label: 'Pousada', value: 'Pousada' },
  { label: 'Pousafoles Do Bispo', value: 'Pousafoles Do Bispo' },
  { label: 'Pouso Alegre', value: 'Pouso Alegre' },
  { label: 'Pouso Alto', value: 'Pouso Alto' },
  { label: 'Pouso Novo', value: 'Pouso Novo' },
  { label: 'Pouso Redondo', value: 'Pouso Redondo' },
  { label: 'Poussan', value: 'Poussan' },
  { label: 'Pout', value: 'Pout' },
  { label: 'Pouxeux', value: 'Pouxeux' },
  { label: 'Pouzac', value: 'Pouzac' },
  { label: 'Pouzauges', value: 'Pouzauges' },
  { label: 'Povarovo', value: 'Povarovo' },
  { label: 'Považská Bystrica', value: 'Považská Bystrica' },
  { label: 'Pove Del Grappa', value: 'Pove Del Grappa' },
  { label: 'Poveda', value: 'Poveda' },
  { label: 'Poveda De La Sierra', value: 'Poveda De La Sierra' },
  { label: 'Poveda De Las Cintas', value: 'Poveda De Las Cintas' },
  { label: 'Póveda De Soria La', value: 'Póveda De Soria La' },
  { label: 'Povedilla', value: 'Povedilla' },
  { label: 'Povedniki', value: 'Povedniki' },
  { label: 'Povegliano', value: 'Povegliano' },
  { label: 'Povegliano Veronese', value: 'Povegliano Veronese' },
  { label: 'Povenets', value: 'Povenets' },
  { label: 'Poviglio', value: 'Poviglio' },
  { label: 'Povo', value: 'Povo' },
  { label: 'Póvoa De Lanhoso', value: 'Póvoa De Lanhoso' },
  { label: 'Póvoa De Santa Iria', value: 'Póvoa De Santa Iria' },
  { label: 'Póvoa De Santa Iria', value: 'Póvoa De Santa Iria' },
  { label: 'Póvoa De Santo Adrião', value: 'Póvoa De Santo Adrião' },
  { label: 'Póvoa De Santo Adrião', value: 'Póvoa De Santo Adrião' },
  { label: 'Póvoa De Varzim', value: 'Póvoa De Varzim' },
  { label: 'Póvoa Do Concelho', value: 'Póvoa Do Concelho' },
  { label: 'Povoação', value: 'Povoação' },
  { label: 'Povoletto', value: 'Povoletto' },
  { label: 'Povolzhskiy', value: 'Povolzhskiy' },
  { label: 'Povorino', value: 'Povorino' },
  { label: 'Povorinskiy Rayon', value: 'Povorinskiy Rayon' },
  { label: 'Povrly', value: 'Povrly' },
  { label: 'Powai', value: 'Powai' },
  { label: 'Powassan', value: 'Powassan' },
  { label: 'Poway', value: 'Poway' },
  { label: 'Powder River County', value: 'Powder River County' },
  { label: 'Powder Springs', value: 'Powder Springs' },
  { label: 'Powderly', value: 'Powderly' },
  { label: 'Powdersville', value: 'Powdersville' },
  { label: 'Powell', value: 'Powell' },
  { label: 'Powell', value: 'Powell' },
  { label: 'Powell County', value: 'Powell County' },
  { label: 'Powell County', value: 'Powell County' },
  { label: 'Powell River', value: 'Powell River' },
  { label: 'Powells Crossroads', value: 'Powells Crossroads' },
  { label: 'Power County', value: 'Power County' },
  { label: 'Powers Lake', value: 'Powers Lake' },
  { label: 'Poweshiek County', value: 'Poweshiek County' },
  { label: 'Powhatan County', value: 'Powhatan County' },
  { label: 'Powhatan Point', value: 'Powhatan Point' },
  { label: 'Powiat Aleksandrowski', value: 'Powiat Aleksandrowski' },
  { label: 'Powiat Augustowski', value: 'Powiat Augustowski' },
  { label: 'Powiat Bartoszycki', value: 'Powiat Bartoszycki' },
  { label: 'Powiat Będziński', value: 'Powiat Będziński' },
  { label: 'Powiat Bełchatowski', value: 'Powiat Bełchatowski' },
  { label: 'Powiat Białobrzeski', value: 'Powiat Białobrzeski' },
  { label: 'Powiat Białogardzki', value: 'Powiat Białogardzki' },
  { label: 'Powiat Białostocki', value: 'Powiat Białostocki' },
  { label: 'Powiat Bialski', value: 'Powiat Bialski' },
  { label: 'Powiat Bielski', value: 'Powiat Bielski' },
  { label: 'Powiat Bielski', value: 'Powiat Bielski' },
  { label: 'Powiat Bieruńsko-Lędziński', value: 'Powiat Bieruńsko-Lędziński' },
  { label: 'Powiat Bieszczadzki', value: 'Powiat Bieszczadzki' },
  { label: 'Powiat Biłgorajski', value: 'Powiat Biłgorajski' },
  { label: 'Powiat Bocheński', value: 'Powiat Bocheński' },
  { label: 'Powiat Bolesławiecki', value: 'Powiat Bolesławiecki' },
  { label: 'Powiat Braniewski', value: 'Powiat Braniewski' },
  { label: 'Powiat Brodnicki', value: 'Powiat Brodnicki' },
  { label: 'Powiat Brzeski', value: 'Powiat Brzeski' },
  { label: 'Powiat Brzeski', value: 'Powiat Brzeski' },
  { label: 'Powiat Brzeziński', value: 'Powiat Brzeziński' },
  { label: 'Powiat Brzozowski', value: 'Powiat Brzozowski' },
  { label: 'Powiat Buski', value: 'Powiat Buski' },
  { label: 'Powiat Bydgoski', value: 'Powiat Bydgoski' },
  { label: 'Powiat Bytowski', value: 'Powiat Bytowski' },
  { label: 'Powiat Chełmiński', value: 'Powiat Chełmiński' },
  { label: 'Powiat Chełmski', value: 'Powiat Chełmski' },
  { label: 'Powiat Chodzieski', value: 'Powiat Chodzieski' },
  { label: 'Powiat Chojnicki', value: 'Powiat Chojnicki' },
  { label: 'Powiat Choszczeński', value: 'Powiat Choszczeński' },
  { label: 'Powiat Chrzanowski', value: 'Powiat Chrzanowski' },
  { label: 'Powiat Ciechanowski', value: 'Powiat Ciechanowski' },
  { label: 'Powiat Cieszyński', value: 'Powiat Cieszyński' },
  { label: 'Powiat Czarnkowsko-Trzcianecki', value: 'Powiat Czarnkowsko-Trzcianecki' },
  { label: 'Powiat Częstochowski', value: 'Powiat Częstochowski' },
  { label: 'Powiat Człuchowski', value: 'Powiat Człuchowski' },
  { label: 'Powiat Dąbrowski', value: 'Powiat Dąbrowski' },
  { label: 'Powiat Dębicki', value: 'Powiat Dębicki' },
  { label: 'Powiat Drawski', value: 'Powiat Drawski' },
  { label: 'Powiat Działdowski', value: 'Powiat Działdowski' },
  { label: 'Powiat Dzierżoniowski', value: 'Powiat Dzierżoniowski' },
  { label: 'Powiat Elbląski', value: 'Powiat Elbląski' },
  { label: 'Powiat Ełcki', value: 'Powiat Ełcki' },
  { label: 'Powiat Garwoliński', value: 'Powiat Garwoliński' },
  { label: 'Powiat Gdański', value: 'Powiat Gdański' },
  { label: 'Powiat Giżycki', value: 'Powiat Giżycki' },
  { label: 'Powiat Gliwicki', value: 'Powiat Gliwicki' },
  { label: 'Powiat Głogowski', value: 'Powiat Głogowski' },
  { label: 'Powiat Głubczycki', value: 'Powiat Głubczycki' },
  { label: 'Powiat Gnieźnieński', value: 'Powiat Gnieźnieński' },
  { label: 'Powiat Gołdapski', value: 'Powiat Gołdapski' },
  { label: 'Powiat Goleniowski', value: 'Powiat Goleniowski' },
  { label: 'Powiat Golubsko-Dobrzyński', value: 'Powiat Golubsko-Dobrzyński' },
  { label: 'Powiat Gorlicki', value: 'Powiat Gorlicki' },
  { label: 'Powiat Górowski', value: 'Powiat Górowski' },
  { label: 'Powiat Gorzowski', value: 'Powiat Gorzowski' },
  { label: 'Powiat Gostyniński', value: 'Powiat Gostyniński' },
  { label: 'Powiat Gostyński', value: 'Powiat Gostyński' },
  { label: 'Powiat Grajewski', value: 'Powiat Grajewski' },
  { label: 'Powiat Grodziski', value: 'Powiat Grodziski' },
  { label: 'Powiat Grodziski', value: 'Powiat Grodziski' },
  { label: 'Powiat Grójecki', value: 'Powiat Grójecki' },
  { label: 'Powiat Grudziądzki', value: 'Powiat Grudziądzki' },
  { label: 'Powiat Gryficki', value: 'Powiat Gryficki' },
  { label: 'Powiat Gryfiński', value: 'Powiat Gryfiński' },
  { label: 'Powiat Hajnowski', value: 'Powiat Hajnowski' },
  { label: 'Powiat Hrubieszowski', value: 'Powiat Hrubieszowski' },
  { label: 'Powiat Iławski', value: 'Powiat Iławski' },
  { label: 'Powiat Inowrocławski', value: 'Powiat Inowrocławski' },
  { label: 'Powiat Janowski', value: 'Powiat Janowski' },
  { label: 'Powiat Jarociński', value: 'Powiat Jarociński' },
  { label: 'Powiat Jarosławski', value: 'Powiat Jarosławski' },
  { label: 'Powiat Jasielski', value: 'Powiat Jasielski' },
  { label: 'Powiat Jaworski', value: 'Powiat Jaworski' },
  { label: 'Powiat Jędrzejowski', value: 'Powiat Jędrzejowski' },
  { label: 'Powiat Jeleniogórski', value: 'Powiat Jeleniogórski' },
  { label: 'Powiat Kaliski', value: 'Powiat Kaliski' },
  { label: 'Powiat Kamiennogórski', value: 'Powiat Kamiennogórski' },
  { label: 'Powiat Kamieński', value: 'Powiat Kamieński' },
  { label: 'Powiat Kartuski', value: 'Powiat Kartuski' },
  { label: 'Powiat Kazimierski', value: 'Powiat Kazimierski' },
  { label: 'Powiat Kędzierzyńsko-Kozielski', value: 'Powiat Kędzierzyńsko-Kozielski' },
  { label: 'Powiat Kępiński', value: 'Powiat Kępiński' },
  { label: 'Powiat Kętrzyński', value: 'Powiat Kętrzyński' },
  { label: 'Powiat Kielecki', value: 'Powiat Kielecki' },
  { label: 'Powiat Kłobucki', value: 'Powiat Kłobucki' },
  { label: 'Powiat Kłodzki', value: 'Powiat Kłodzki' },
  { label: 'Powiat Kluczborski', value: 'Powiat Kluczborski' },
  { label: 'Powiat Kolbuszowski', value: 'Powiat Kolbuszowski' },
  { label: 'Powiat Kolneński', value: 'Powiat Kolneński' },
  { label: 'Powiat Kołobrzeski', value: 'Powiat Kołobrzeski' },
  { label: 'Powiat Kolski', value: 'Powiat Kolski' },
  { label: 'Powiat Konecki', value: 'Powiat Konecki' },
  { label: 'Powiat Koniński', value: 'Powiat Koniński' },
  { label: 'Powiat Kościański', value: 'Powiat Kościański' },
  { label: 'Powiat Kościerski', value: 'Powiat Kościerski' },
  { label: 'Powiat Koszaliński', value: 'Powiat Koszaliński' },
  { label: 'Powiat Kozienicki', value: 'Powiat Kozienicki' },
  { label: 'Powiat Krakowski', value: 'Powiat Krakowski' },
  { label: 'Powiat Krapkowicki', value: 'Powiat Krapkowicki' },
  { label: 'Powiat Kraśnicki', value: 'Powiat Kraśnicki' },
  { label: 'Powiat Krasnostawski', value: 'Powiat Krasnostawski' },
  { label: 'Powiat Krośnieński', value: 'Powiat Krośnieński' },
  { label: 'Powiat Krośnieński', value: 'Powiat Krośnieński' },
  { label: 'Powiat Krotoszyński', value: 'Powiat Krotoszyński' },
  { label: 'Powiat Kutnowski', value: 'Powiat Kutnowski' },
  { label: 'Powiat Kwidzyński', value: 'Powiat Kwidzyński' },
  { label: 'Powiat Łańcucki', value: 'Powiat Łańcucki' },
  { label: 'Powiat Łaski', value: 'Powiat Łaski' },
  { label: 'Powiat Lęborski', value: 'Powiat Lęborski' },
  { label: 'Powiat Łęczycki', value: 'Powiat Łęczycki' },
  { label: 'Powiat Łęczyński', value: 'Powiat Łęczyński' },
  { label: 'Powiat Legionowski', value: 'Powiat Legionowski' },
  { label: 'Powiat Legnicki', value: 'Powiat Legnicki' },
  { label: 'Powiat Leski', value: 'Powiat Leski' },
  { label: 'Powiat Leszczyński', value: 'Powiat Leszczyński' },
  { label: 'Powiat Leżajski', value: 'Powiat Leżajski' },
  { label: 'Powiat Lidzbarski', value: 'Powiat Lidzbarski' },
  { label: 'Powiat Limanowski', value: 'Powiat Limanowski' },
  { label: 'Powiat Lipnowski', value: 'Powiat Lipnowski' },
  { label: 'Powiat Lipski', value: 'Powiat Lipski' },
  { label: 'Powiat Łobeski', value: 'Powiat Łobeski' },
  { label: 'Powiat Łódzki Wschodni', value: 'Powiat Łódzki Wschodni' },
  { label: 'Powiat Łomżyński', value: 'Powiat Łomżyński' },
  { label: 'Powiat Łosicki', value: 'Powiat Łosicki' },
  { label: 'Powiat Łowicki', value: 'Powiat Łowicki' },
  { label: 'Powiat Lubaczowski', value: 'Powiat Lubaczowski' },
  { label: 'Powiat Lubański', value: 'Powiat Lubański' },
  { label: 'Powiat Lubartowski', value: 'Powiat Lubartowski' },
  { label: 'Powiat Lubelski', value: 'Powiat Lubelski' },
  { label: 'Powiat Lubiński', value: 'Powiat Lubiński' },
  { label: 'Powiat Lubliniecki', value: 'Powiat Lubliniecki' },
  { label: 'Powiat Łukowski', value: 'Powiat Łukowski' },
  { label: 'Powiat Lwówecki', value: 'Powiat Lwówecki' },
  { label: 'Powiat Makowski', value: 'Powiat Makowski' },
  { label: 'Powiat Malborski', value: 'Powiat Malborski' },
  { label: 'Powiat Miechowski', value: 'Powiat Miechowski' },
  { label: 'Powiat Międzychodzki', value: 'Powiat Międzychodzki' },
  { label: 'Powiat Międzyrzecki', value: 'Powiat Międzyrzecki' },
  { label: 'Powiat Mielecki', value: 'Powiat Mielecki' },
  { label: 'Powiat Mikołowski', value: 'Powiat Mikołowski' },
  { label: 'Powiat Milicki', value: 'Powiat Milicki' },
  { label: 'Powiat Miński', value: 'Powiat Miński' },
  { label: 'Powiat Mławski', value: 'Powiat Mławski' },
  { label: 'Powiat Mogileński', value: 'Powiat Mogileński' },
  { label: 'Powiat Moniecki', value: 'Powiat Moniecki' },
  { label: 'Powiat Mrągowski', value: 'Powiat Mrągowski' },
  { label: 'Powiat Myślenicki', value: 'Powiat Myślenicki' },
  { label: 'Powiat Myśliborski', value: 'Powiat Myśliborski' },
  { label: 'Powiat Myszkowski', value: 'Powiat Myszkowski' },
  { label: 'Powiat Nakielski', value: 'Powiat Nakielski' },
  { label: 'Powiat Namysłowski', value: 'Powiat Namysłowski' },
  { label: 'Powiat Nidzicki', value: 'Powiat Nidzicki' },
  { label: 'Powiat Niżański', value: 'Powiat Niżański' },
  { label: 'Powiat Nowodworski', value: 'Powiat Nowodworski' },
  { label: 'Powiat Nowodworski', value: 'Powiat Nowodworski' },
  { label: 'Powiat Nowomiejski', value: 'Powiat Nowomiejski' },
  { label: 'Powiat Nowosądecki', value: 'Powiat Nowosądecki' },
  { label: 'Powiat Nowosolski', value: 'Powiat Nowosolski' },
  { label: 'Powiat Nowotarski', value: 'Powiat Nowotarski' },
  { label: 'Powiat Nowotomyski', value: 'Powiat Nowotomyski' },
  { label: 'Powiat Nyski', value: 'Powiat Nyski' },
  { label: 'Powiat Obornicki', value: 'Powiat Obornicki' },
  { label: 'Powiat Oławski', value: 'Powiat Oławski' },
  { label: 'Powiat Olecki', value: 'Powiat Olecki' },
  { label: 'Powiat Oleski', value: 'Powiat Oleski' },
  { label: 'Powiat Oleśnicki', value: 'Powiat Oleśnicki' },
  { label: 'Powiat Olkuski', value: 'Powiat Olkuski' },
  { label: 'Powiat Olsztyński', value: 'Powiat Olsztyński' },
  { label: 'Powiat Opatowski', value: 'Powiat Opatowski' },
  { label: 'Powiat Opoczyński', value: 'Powiat Opoczyński' },
  { label: 'Powiat Opolski', value: 'Powiat Opolski' },
  { label: 'Powiat Opolski', value: 'Powiat Opolski' },
  { label: 'Powiat Ostródzki', value: 'Powiat Ostródzki' },
  { label: 'Powiat Ostrołęcki', value: 'Powiat Ostrołęcki' },
  { label: 'Powiat Ostrowiecki', value: 'Powiat Ostrowiecki' },
  { label: 'Powiat Ostrowski', value: 'Powiat Ostrowski' },
  { label: 'Powiat Ostrowski', value: 'Powiat Ostrowski' },
  { label: 'Powiat Ostrzeszowski', value: 'Powiat Ostrzeszowski' },
  { label: 'Powiat Oświęcimski', value: 'Powiat Oświęcimski' },
  { label: 'Powiat Otwocki', value: 'Powiat Otwocki' },
  { label: 'Powiat Pabianicki', value: 'Powiat Pabianicki' },
  { label: 'Powiat Pajęczański', value: 'Powiat Pajęczański' },
  { label: 'Powiat Parczewski', value: 'Powiat Parczewski' },
  { label: 'Powiat Piaseczyński', value: 'Powiat Piaseczyński' },
  { label: 'Powiat Pilski', value: 'Powiat Pilski' },
  { label: 'Powiat Pińczowski', value: 'Powiat Pińczowski' },
  { label: 'Powiat Piotrkowski', value: 'Powiat Piotrkowski' },
  { label: 'Powiat Piski', value: 'Powiat Piski' },
  { label: 'Powiat Pleszewski', value: 'Powiat Pleszewski' },
  { label: 'Powiat Płocki', value: 'Powiat Płocki' },
  { label: 'Powiat Płoński', value: 'Powiat Płoński' },
  { label: 'Powiat Poddębicki', value: 'Powiat Poddębicki' },
  { label: 'Powiat Policki', value: 'Powiat Policki' },
  { label: 'Powiat Polkowicki', value: 'Powiat Polkowicki' },
  { label: 'Powiat Poznański', value: 'Powiat Poznański' },
  { label: 'Powiat Proszowicki', value: 'Powiat Proszowicki' },
  { label: 'Powiat Prudnicki', value: 'Powiat Prudnicki' },
  { label: 'Powiat Pruszkowski', value: 'Powiat Pruszkowski' },
  { label: 'Powiat Przasnyski', value: 'Powiat Przasnyski' },
  { label: 'Powiat Przemyski', value: 'Powiat Przemyski' },
  { label: 'Powiat Przeworski', value: 'Powiat Przeworski' },
  { label: 'Powiat Przysuski', value: 'Powiat Przysuski' },
  { label: 'Powiat Pszczyński', value: 'Powiat Pszczyński' },
  { label: 'Powiat Pucki', value: 'Powiat Pucki' },
  { label: 'Powiat Puławski', value: 'Powiat Puławski' },
  { label: 'Powiat Pułtuski', value: 'Powiat Pułtuski' },
  { label: 'Powiat Pyrzycki', value: 'Powiat Pyrzycki' },
  { label: 'Powiat Raciborski', value: 'Powiat Raciborski' },
  { label: 'Powiat Radomski', value: 'Powiat Radomski' },
  { label: 'Powiat Radomszczański', value: 'Powiat Radomszczański' },
  { label: 'Powiat Radziejowski', value: 'Powiat Radziejowski' },
  { label: 'Powiat Radzyński', value: 'Powiat Radzyński' },
  { label: 'Powiat Rawicki', value: 'Powiat Rawicki' },
  { label: 'Powiat Rawski', value: 'Powiat Rawski' },
  { label: 'Powiat Ropczycko-Sędziszowski', value: 'Powiat Ropczycko-Sędziszowski' },
  { label: 'Powiat Rybnicki', value: 'Powiat Rybnicki' },
  { label: 'Powiat Rycki', value: 'Powiat Rycki' },
  { label: 'Powiat Rypiński', value: 'Powiat Rypiński' },
  { label: 'Powiat Rzeszowski', value: 'Powiat Rzeszowski' },
  { label: 'Powiat Sandomierski', value: 'Powiat Sandomierski' },
  { label: 'Powiat Sanocki', value: 'Powiat Sanocki' },
  { label: 'Powiat Sejneński', value: 'Powiat Sejneński' },
  { label: 'Powiat Sępoleński', value: 'Powiat Sępoleński' },
  { label: 'Powiat Siedlecki', value: 'Powiat Siedlecki' },
  { label: 'Powiat Siemiatycki', value: 'Powiat Siemiatycki' },
  { label: 'Powiat Sieradzki', value: 'Powiat Sieradzki' },
  { label: 'Powiat Sierpecki', value: 'Powiat Sierpecki' },
  { label: 'Powiat Skarżyski', value: 'Powiat Skarżyski' },
  { label: 'Powiat Skierniewicki', value: 'Powiat Skierniewicki' },
  { label: 'Powiat Sławieński', value: 'Powiat Sławieński' },
  { label: 'Powiat Słubicki', value: 'Powiat Słubicki' },
  { label: 'Powiat Słupecki', value: 'Powiat Słupecki' },
  { label: 'Powiat Słupski', value: 'Powiat Słupski' },
  { label: 'Powiat Sochaczewski', value: 'Powiat Sochaczewski' },
  { label: 'Powiat Sokołowski', value: 'Powiat Sokołowski' },
  { label: 'Powiat Sokólski', value: 'Powiat Sokólski' },
  { label: 'Powiat Średzki', value: 'Powiat Średzki' },
  { label: 'Powiat Średzki', value: 'Powiat Średzki' },
  { label: 'Powiat Śremski', value: 'Powiat Śremski' },
  { label: 'Powiat Stalowowolski', value: 'Powiat Stalowowolski' },
  { label: 'Powiat Starachowicki', value: 'Powiat Starachowicki' },
  { label: 'Powiat Stargardzki', value: 'Powiat Stargardzki' },
  { label: 'Powiat Starogardzki', value: 'Powiat Starogardzki' },
  { label: 'Powiat Staszowski', value: 'Powiat Staszowski' },
  { label: 'Powiat Strzelecki', value: 'Powiat Strzelecki' },
  { label: 'Powiat Strzelecko-Drezdenecki', value: 'Powiat Strzelecko-Drezdenecki' },
  { label: 'Powiat Strzeliński', value: 'Powiat Strzeliński' },
  { label: 'Powiat Strzyżowski', value: 'Powiat Strzyżowski' },
  { label: 'Powiat Sulęciński', value: 'Powiat Sulęciński' },
  { label: 'Powiat Suski', value: 'Powiat Suski' },
  { label: 'Powiat Suwalski', value: 'Powiat Suwalski' },
  { label: 'Powiat Świdnicki', value: 'Powiat Świdnicki' },
  { label: 'Powiat Świdnicki', value: 'Powiat Świdnicki' },
  { label: 'Powiat Świdwiński', value: 'Powiat Świdwiński' },
  { label: 'Powiat Świebodziński', value: 'Powiat Świebodziński' },
  { label: 'Powiat Świecki', value: 'Powiat Świecki' },
  { label: 'Powiat Szamotulski', value: 'Powiat Szamotulski' },
  { label: 'Powiat Szczecinecki', value: 'Powiat Szczecinecki' },
  { label: 'Powiat Szczycieński', value: 'Powiat Szczycieński' },
  { label: 'Powiat Sztumski', value: 'Powiat Sztumski' },
  { label: 'Powiat Szydłowiecki', value: 'Powiat Szydłowiecki' },
  { label: 'Powiat Tarnobrzeski', value: 'Powiat Tarnobrzeski' },
  { label: 'Powiat Tarnogórski', value: 'Powiat Tarnogórski' },
  { label: 'Powiat Tarnowski', value: 'Powiat Tarnowski' },
  { label: 'Powiat Tatrzański', value: 'Powiat Tatrzański' },
  { label: 'Powiat Tczewski', value: 'Powiat Tczewski' },
  { label: 'Powiat Tomaszowski', value: 'Powiat Tomaszowski' },
  { label: 'Powiat Tomaszowski', value: 'Powiat Tomaszowski' },
  { label: 'Powiat Toruński', value: 'Powiat Toruński' },
  { label: 'Powiat Trzebnicki', value: 'Powiat Trzebnicki' },
  { label: 'Powiat Tucholski', value: 'Powiat Tucholski' },
  { label: 'Powiat Turecki', value: 'Powiat Turecki' },
  { label: 'Powiat Wąbrzeski', value: 'Powiat Wąbrzeski' },
  { label: 'Powiat Wadowicki', value: 'Powiat Wadowicki' },
  { label: 'Powiat Wągrowiecki', value: 'Powiat Wągrowiecki' },
  { label: 'Powiat Wałbrzyski', value: 'Powiat Wałbrzyski' },
  { label: 'Powiat Wałecki', value: 'Powiat Wałecki' },
  { label: 'Powiat Warszawski Zachodni', value: 'Powiat Warszawski Zachodni' },
  { label: 'Powiat Węgorzewski', value: 'Powiat Węgorzewski' },
  { label: 'Powiat Węgrowski', value: 'Powiat Węgrowski' },
  { label: 'Powiat Wejherowski', value: 'Powiat Wejherowski' },
  { label: 'Powiat Wielicki', value: 'Powiat Wielicki' },
  { label: 'Powiat Wieluński', value: 'Powiat Wieluński' },
  { label: 'Powiat Wieruszowski', value: 'Powiat Wieruszowski' },
  { label: 'Powiat Włocławski', value: 'Powiat Włocławski' },
  { label: 'Powiat Włodawski', value: 'Powiat Włodawski' },
  { label: 'Powiat Włoszczowski', value: 'Powiat Włoszczowski' },
  { label: 'Powiat Wodzisławski', value: 'Powiat Wodzisławski' },
  { label: 'Powiat Wołomiński', value: 'Powiat Wołomiński' },
  { label: 'Powiat Wołowski', value: 'Powiat Wołowski' },
  { label: 'Powiat Wolsztyński', value: 'Powiat Wolsztyński' },
  { label: 'Powiat Wrocławski', value: 'Powiat Wrocławski' },
  { label: 'Powiat Wrzesiński', value: 'Powiat Wrzesiński' },
  { label: 'Powiat Wschowski', value: 'Powiat Wschowski' },
  { label: 'Powiat Wysokomazowiecki', value: 'Powiat Wysokomazowiecki' },
  { label: 'Powiat Wyszkowski', value: 'Powiat Wyszkowski' },
  { label: 'Powiat Ząbkowicki', value: 'Powiat Ząbkowicki' },
  { label: 'Powiat Żagański', value: 'Powiat Żagański' },
  { label: 'Powiat Zambrowski', value: 'Powiat Zambrowski' },
  { label: 'Powiat Zamojski', value: 'Powiat Zamojski' },
  { label: 'Powiat Żarski', value: 'Powiat Żarski' },
  { label: 'Powiat Zawierciański', value: 'Powiat Zawierciański' },
  { label: 'Powiat Zduńskowolski', value: 'Powiat Zduńskowolski' },
  { label: 'Powiat Zgierski', value: 'Powiat Zgierski' },
  { label: 'Powiat Zgorzelecki', value: 'Powiat Zgorzelecki' },
  { label: 'Powiat Zielonogórski', value: 'Powiat Zielonogórski' },
  { label: 'Powiat Złotoryjski', value: 'Powiat Złotoryjski' },
  { label: 'Powiat Złotowski', value: 'Powiat Złotowski' },
  { label: 'Powiat Żniński', value: 'Powiat Żniński' },
  { label: 'Powiat Żuromiński', value: 'Powiat Żuromiński' },
  { label: 'Powiat Zwoleński', value: 'Powiat Zwoleński' },
  { label: 'Powiat Żyrardowski', value: 'Powiat Żyrardowski' },
  { label: 'Powiat Żywiecki', value: 'Powiat Żywiecki' },
  { label: 'Powidz', value: 'Powidz' },
  { label: 'Pownal', value: 'Pownal' },
  { label: 'Powstańców Śląskich Wschód', value: 'Powstańców Śląskich Wschód' },
  { label: 'Powstańców Śląskich Zachód-Centrum Południow', value: 'Powstańców Śląskich Zachód-Centrum Południow' },
  { label: 'Poxdorf', value: 'Poxdorf' },
  { label: 'Poxoréo', value: 'Poxoréo' },
  { label: 'Poxoréu', value: 'Poxoréu' },
  { label: 'Poyakonda', value: 'Poyakonda' },
  { label: 'Poyales Del Hoyo', value: 'Poyales Del Hoyo' },
  { label: 'Poyang', value: 'Poyang' },
  { label: 'Poyarkovo', value: 'Poyarkovo' },
  { label: 'Poyatos', value: 'Poyatos' },
  { label: 'Poydras', value: 'Poydras' },
  { label: 'Poykovskiy', value: 'Poykovskiy' },
  { label: 'Poynette', value: 'Poynette' },
  { label: 'Poynton', value: 'Poynton' },
  { label: 'Poysdorf', value: 'Poysdorf' },
  { label: 'Pöytyä', value: 'Pöytyä' },
  { label: 'Poza De La Sal', value: 'Poza De La Sal' },
  { label: 'Poza De La Vega', value: 'Poza De La Vega' },
  { label: 'Poza Rica De Hidalgo', value: 'Poza Rica De Hidalgo' },
  { label: 'Pozal De Gallinas', value: 'Pozal De Gallinas' },
  { label: 'Pozaldez', value: 'Pozaldez' },
  { label: 'Pozalmuro', value: 'Pozalmuro' },
  { label: 'Pozán De Vero', value: 'Pozán De Vero' },
  { label: 'Pozanco', value: 'Pozanco' },
  { label: 'Pozantı', value: 'Pozantı' },
  { label: 'Požarevac', value: 'Požarevac' },
  { label: 'Požega', value: 'Požega' },
  { label: 'Pozezdrze', value: 'Pozezdrze' },
  { label: 'Pozharskoye', value: 'Pozharskoye' },
  { label: 'Pozhva', value: 'Pozhva' },
  { label: 'Pozlovice', value: 'Pozlovice' },
  { label: 'Poznań', value: 'Poznań' },
  { label: 'Pozo Alcón', value: 'Pozo Alcón' },
  { label: 'Pozo Almonte', value: 'Pozo Almonte' },
  { label: 'Pozo Cañada', value: 'Pozo Cañada' },
  { label: 'Pozo De Almoguera', value: 'Pozo De Almoguera' },
  { label: 'Pozo De Guadalajara', value: 'Pozo De Guadalajara' },
  { label: 'Pozo De Ibarra', value: 'Pozo De Ibarra' },
  { label: 'Pozo De Jarillas', value: 'Pozo De Jarillas' },
  { label: 'Pozo De Urama', value: 'Pozo De Urama' },
  { label: 'Pozo Del Molle', value: 'Pozo Del Molle' },
  { label: 'Pozo Del Tigre', value: 'Pozo Del Tigre' },
  { label: 'Pozo Dulce', value: 'Pozo Dulce' },
  { label: 'Pozoamargo', value: 'Pozoamargo' },
  { label: 'Pozoantiguo', value: 'Pozoantiguo' },
  { label: 'Pozoblanco', value: 'Pozoblanco' },
  { label: 'Pozohondo', value: 'Pozohondo' },
  { label: 'Pozo-Lorente', value: 'Pozo-Lorente' },
  { label: 'Pozondón', value: 'Pozondón' },
  { label: 'Pozořice', value: 'Pozořice' },
  { label: 'Pozorrubielos De La Mancha', value: 'Pozorrubielos De La Mancha' },
  { label: 'Pozorrubio', value: 'Pozorrubio' },
  { label: 'Pozorrubio', value: 'Pozorrubio' },
  { label: 'Pozorrubio', value: 'Pozorrubio' },
  { label: 'Pozos', value: 'Pozos' },
  { label: 'Pozos De Gamboa', value: 'Pozos De Gamboa' },
  { label: 'Pozos De Hinojo', value: 'Pozos De Hinojo' },
  { label: 'Pozuel De Ariza', value: 'Pozuel De Ariza' },
  { label: 'Pozuel Del Campo', value: 'Pozuel Del Campo' },
  { label: 'Pozuelo', value: 'Pozuelo' },
  { label: 'Pozuelo De Alarcón', value: 'Pozuelo De Alarcón' },
  { label: 'Pozuelo De Aragón', value: 'Pozuelo De Aragón' },
  { label: 'Pozuelo De Calatrava', value: 'Pozuelo De Calatrava' },
  { label: 'Pozuelo De La Orden', value: 'Pozuelo De La Orden' },
  { label: 'Pozuelo De Tábara', value: 'Pozuelo De Tábara' },
  { label: 'Pozuelo De Zarzón', value: 'Pozuelo De Zarzón' },
  { label: 'Pozuelo Del Páramo', value: 'Pozuelo Del Páramo' },
  { label: 'Pozuelo Del Rey', value: 'Pozuelo Del Rey' },
  { label: 'Pozuelo El', value: 'Pozuelo El' },
  { label: 'Pozuelos', value: 'Pozuelos' },
  { label: 'Pozuelos', value: 'Pozuelos' },
  { label: 'Pozuelos De Calatrava Los', value: 'Pozuelos De Calatrava Los' },
  { label: 'Pozza', value: 'Pozza' },
  { label: 'Pozza Di Fassa', value: 'Pozza Di Fassa' },
  { label: 'Pozzaglia Sabino', value: 'Pozzaglia Sabino' },
  { label: 'Pozzaglio', value: 'Pozzaglio' },
  { label: 'Pozzaglio Ed Uniti', value: 'Pozzaglio Ed Uniti' },
  { label: 'Pozzale-Case Nuove', value: 'Pozzale-Case Nuove' },
  { label: 'Pozzallo', value: 'Pozzallo' },
  { label: 'Pozzilli', value: 'Pozzilli' },
  { label: 'Pozzillo', value: 'Pozzillo' },
  { label: 'Pozzo Dadda', value: 'Pozzo Dadda' },
  { label: 'Pozzol Groppo', value: 'Pozzol Groppo' },
  { label: 'Pozzolengo', value: 'Pozzolengo' },
  { label: 'Pozzoleone', value: 'Pozzoleone' },
  { label: 'Pozzolo Formigaro', value: 'Pozzolo Formigaro' },
  { label: 'Pozzomaggiore', value: 'Pozzomaggiore' },
  { label: 'Pozzonovo', value: 'Pozzonovo' },
  { label: 'Pozzuoli', value: 'Pozzuoli' },
  { label: 'Pozzuolo', value: 'Pozzuolo' },
  { label: 'Pozzuolo Del Friuli', value: 'Pozzuolo Del Friuli' },
  { label: 'Pozzuolo Martesana', value: 'Pozzuolo Martesana' },
  { label: 'Pra Wet', value: 'Pra Wet' },
  { label: 'Prabhadevi', value: 'Prabhadevi' },
  { label: 'Prabhas Patan', value: 'Prabhas Patan' },
  { label: 'Prabumulih', value: 'Prabumulih' },
  { label: 'Prabuty', value: 'Prabuty' },
  { label: 'Prachatice', value: 'Prachatice' },
  { label: 'Prachin Buri', value: 'Prachin Buri' },
  { label: 'Prachovice', value: 'Prachovice' },
  { label: 'Pracht', value: 'Pracht' },
  { label: 'Prachuap Khiri Khan', value: 'Prachuap Khiri Khan' },
  { label: 'Pracinha', value: 'Pracinha' },
  { label: 'Prackenbach', value: 'Prackenbach' },
  { label: 'Pracuúba', value: 'Pracuúba' },
  { label: 'Pracze Odrzańskie-Janówek', value: 'Pracze Odrzańskie-Janówek' },
  { label: 'Pradales', value: 'Pradales' },
  { label: 'Pradalunga', value: 'Pradalunga' },
  { label: 'Pradamano', value: 'Pradamano' },
  { label: 'Prádanos De Bureba', value: 'Prádanos De Bureba' },
  { label: 'Prádanos De Ojeda', value: 'Prádanos De Ojeda' },
  { label: 'Prade', value: 'Prade' },
  { label: 'Pradejón', value: 'Pradejón' },
  { label: 'Pradell De La Teixeta', value: 'Pradell De La Teixeta' },
  { label: 'Pradelle', value: 'Pradelle' },
  { label: 'Prádena', value: 'Prádena' },
  { label: 'Prádena De Atienza', value: 'Prádena De Atienza' },
  { label: 'Prádena Del Rincón', value: 'Prádena Del Rincón' },
  { label: 'Pradera', value: 'Pradera' },
  { label: 'Praderas De San Francisco', value: 'Praderas De San Francisco' },
  { label: 'Praderas Del Potrero', value: 'Praderas Del Potrero' },
  { label: 'Prades', value: 'Prades' },
  { label: 'Prades', value: 'Prades' },
  { label: 'Prades-Le-Lez', value: 'Prades-Le-Lez' },
  { label: 'Pradilla De Ebro', value: 'Pradilla De Ebro' },
  { label: 'Pradillo', value: 'Pradillo' },
  { label: 'Pradines', value: 'Pradines' },
  { label: 'Pradl', value: 'Pradl' },
  { label: 'Pradleves', value: 'Pradleves' },
  { label: 'Prado', value: 'Prado' },
  { label: 'Prado', value: 'Prado' },
  { label: 'Prado', value: 'Prado' },
  { label: 'Prado', value: 'Prado' },
  { label: 'Prado', value: 'Prado' },
  { label: 'Prado De La Guzpeña', value: 'Prado De La Guzpeña' },
  { label: 'Prado Del Rey', value: 'Prado Del Rey' },
  { label: 'Prado Ferreira', value: 'Prado Ferreira' },
  { label: 'Prado Siongco', value: 'Prado Siongco' },
  { label: 'Prado Siongco', value: 'Prado Siongco' },
  { label: 'Pradoluengo', value: 'Pradoluengo' },
  { label: 'Pradópolis', value: 'Pradópolis' },
  { label: 'Prados', value: 'Prados' },
  { label: 'Prados', value: 'Prados' },
  { label: 'Prados De San Juan', value: 'Prados De San Juan' },
  { label: 'Prados De Santa Rosa', value: 'Prados De Santa Rosa' },
  { label: 'Prados Del Rosario', value: 'Prados Del Rosario' },
  { label: 'Prados Redondos', value: 'Prados Redondos' },
  { label: 'Prados San Francisco', value: 'Prados San Francisco' },
  { label: 'Pradosegar', value: 'Pradosegar' },
  { label: 'Præstø', value: 'Præstø' },
  { label: 'Praga Północ', value: 'Praga Północ' },
  { label: 'Praga Południe', value: 'Praga Południe' },
  { label: 'Pragal', value: 'Pragal' },
  { label: 'Pragelato-Ruà', value: 'Pragelato-Ruà' },
  { label: 'Pragersko', value: 'Pragersko' },
  { label: 'Pragoulin', value: 'Pragoulin' },
  { label: 'Prague', value: 'Prague' },
  { label: 'Prague', value: 'Prague' },
  { label: 'Prahecq', value: 'Prahecq' },
  { label: 'Prahran', value: 'Prahran' },
  { label: 'Praia', value: 'Praia' },
  { label: 'Praia A Mare', value: 'Praia A Mare' },
  { label: 'Praia Da Vitória', value: 'Praia Da Vitória' },
  { label: 'Praia Do Ribatejo', value: 'Praia Do Ribatejo' },
  { label: 'Praia Grande', value: 'Praia Grande' },
  { label: 'Praia Grande', value: 'Praia Grande' },
  { label: 'Praia Norte', value: 'Praia Norte' },
  { label: 'Praiano', value: 'Praiano' },
  { label: 'Praid', value: 'Praid' },
  { label: 'Prainha', value: 'Prainha' },
  { label: 'Prairie City', value: 'Prairie City' },
  { label: 'Prairie County', value: 'Prairie County' },
  { label: 'Prairie County', value: 'Prairie County' },
  { label: 'Prairie Creek', value: 'Prairie Creek' },
  { label: 'Prairie Du Chien', value: 'Prairie Du Chien' },
  { label: 'Prairie Du Sac', value: 'Prairie Du Sac' },
  { label: 'Prairie Grove', value: 'Prairie Grove' },
  { label: 'Prairie Grove', value: 'Prairie Grove' },
  { label: 'Prairie Heights', value: 'Prairie Heights' },
  { label: 'Prairie Ridge', value: 'Prairie Ridge' },
  { label: 'Prairie View', value: 'Prairie View' },
  { label: 'Prairie Village', value: 'Prairie Village' },
  { label: 'Prairieville', value: 'Prairieville' },
  { label: 'Prairiewood', value: 'Prairiewood' },
  { label: 'Prăjani', value: 'Prăjani' },
  { label: 'Prăjeni', value: 'Prăjeni' },
  { label: 'Prăjești', value: 'Prăjești' },
  { label: 'Prajila', value: 'Prajila' },
  { label: 'Prakasam', value: 'Prakasam' },
  { label: 'Prakasha', value: 'Prakasha' },
  { label: 'Prakhon Chai', value: 'Prakhon Chai' },
  { label: 'Pralboino', value: 'Pralboino' },
  { label: 'Prali', value: 'Prali' },
  { label: 'Pralormo', value: 'Pralormo' },
  { label: 'Pralungo', value: 'Pralungo' },
  { label: 'Pram', value: 'Pram' },
  { label: 'Pramaggiore', value: 'Pramaggiore' },
  { label: 'Pramaggiore Blessaglia', value: 'Pramaggiore Blessaglia' },
  { label: 'Prámanta', value: 'Prámanta' },
  { label: 'Prambachkirchen', value: 'Prambachkirchen' },
  { label: 'Pramet', value: 'Pramet' },
  { label: 'Pramollo', value: 'Pramollo' },
  { label: 'Pran Buri', value: 'Pran Buri' },
  { label: 'Pranchita', value: 'Pranchita' },
  { label: 'Prangins', value: 'Prangins' },
  { label: 'Prantij', value: 'Prantij' },
  { label: 'Prapor', value: 'Prapor' },
  { label: 'Prarolo', value: 'Prarolo' },
  { label: 'Praroman', value: 'Praroman' },
  { label: 'Prarostino', value: 'Prarostino' },
  { label: 'Prasco', value: 'Prasco' },
  { label: 'Prascorsano', value: 'Prascorsano' },
  { label: 'Praskoveya', value: 'Praskoveya' },
  { label: 'Přáslavice', value: 'Přáslavice' },
  { label: 'Praslin', value: 'Praslin' },
  { label: 'Praso', value: 'Praso' },
  { label: 'Praszka', value: 'Praszka' },
  { label: 'Prat', value: 'Prat' },
  { label: 'Prat De Comte', value: 'Prat De Comte' },
  { label: 'Prat De Llobregat El', value: 'Prat De Llobregat El' },
  { label: 'Prata', value: 'Prata' },
  { label: 'Prata', value: 'Prata' },
  { label: 'Prata Camportaccio', value: 'Prata Camportaccio' },
  { label: 'Prata Centro', value: 'Prata Centro' },
  { label: 'Prata Dansidonia', value: 'Prata Dansidonia' },
  { label: 'Prata Di Pordenone', value: 'Prata Di Pordenone' },
  { label: 'Prata Di Principato Ultra', value: 'Prata Di Principato Ultra' },
  { label: 'Prata Do Piauí', value: 'Prata Do Piauí' },
  { label: 'Prata Sannita Centro', value: 'Prata Sannita Centro' },
  { label: 'Pratânia', value: 'Pratânia' },
  { label: 'Pratantico-Indicatore', value: 'Pratantico-Indicatore' },
  { label: 'Pratapgarh', value: 'Pratapgarh' },
  { label: 'Pratapgarh', value: 'Pratapgarh' },
  { label: 'Pratápolis', value: 'Pratápolis' },
  { label: 'Pratau', value: 'Pratau' },
  { label: 'Pratdip', value: 'Pratdip' },
  { label: 'Pratella', value: 'Pratella' },
  { label: 'Prati', value: 'Prati' },
  { label: 'Prati', value: 'Prati' },
  { label: 'Praticello', value: 'Praticello' },
  { label: 'Pratiglione', value: 'Pratiglione' },
  { label: 'Pratinha', value: 'Pratinha' },
  { label: 'Pratissolo', value: 'Pratissolo' },
  { label: 'Prato', value: 'Prato' },
  { label: 'Prato', value: 'Prato' },
  { label: 'Prato', value: 'Prato' },
  { label: 'Prato Allo Stelvio', value: 'Prato Allo Stelvio' },
  { label: 'Prato Carnico', value: 'Prato Carnico' },
  { label: 'Prato Di Coppola', value: 'Prato Di Coppola' },
  { label: 'Prato Perillo', value: 'Prato Perillo' },
  { label: 'Prato Sesia', value: 'Prato Sesia' },
  { label: 'Pratola Peligna', value: 'Pratola Peligna' },
  { label: 'Pratola Serra', value: 'Pratola Serra' },
  { label: 'Pratole', value: 'Pratole' },
  { label: 'Pratovecchio', value: 'Pratovecchio' },
  { label: 'Prats De Lluçanès', value: 'Prats De Lluçanès' },
  { label: 'Prats De Molló', value: 'Prats De Molló' },
  { label: 'Prats De Rei Els', value: 'Prats De Rei Els' },
  { label: 'Prats I Sansor', value: 'Prats I Sansor' },
  { label: 'Pratt', value: 'Pratt' },
  { label: 'Pratt County', value: 'Pratt County' },
  { label: 'Pratteln', value: 'Pratteln' },
  { label: 'Prattville', value: 'Prattville' },
  { label: 'Pravda', value: 'Pravda' },
  { label: 'Pravda', value: 'Pravda' },
  { label: 'Pravdinsk', value: 'Pravdinsk' },
  { label: 'Pravdinskiy', value: 'Pravdinskiy' },
  { label: 'Pravets', value: 'Pravets' },
  { label: 'Pravia', value: 'Pravia' },
  { label: 'Pravisdomini', value: 'Pravisdomini' },
  { label: 'Pravoberezhnyy Rayon', value: 'Pravoberezhnyy Rayon' },
  { label: 'Pravokubanskiy', value: 'Pravokubanskiy' },
  { label: 'Pravokumskoye', value: 'Pravokumskoye' },
  { label: 'Prawdzinski', value: 'Prawdzinski' },
  { label: 'Praxedis De Guerrero', value: 'Praxedis De Guerrero' },
  { label: 'Praxedis G. Guerrero', value: 'Praxedis G. Guerrero' },
  { label: 'Praxédis Guerrero', value: 'Praxédis Guerrero' },
  { label: 'Pray', value: 'Pray' },
  { label: 'Praya', value: 'Praya' },
  { label: 'Prayagraj (Allahabad)', value: 'Prayagraj (Allahabad)' },
  { label: 'Prayssac', value: 'Prayssac' },
  { label: 'Prazeres', value: 'Prazeres' },
  { label: 'Pražmo', value: 'Pražmo' },
  { label: 'Prażmów', value: 'Prażmów' },
  { label: 'Praz-Sur-Arly', value: 'Praz-Sur-Arly' },
  { label: 'Prazzo Superiore', value: 'Prazzo Superiore' },
  { label: 'Prčanj', value: 'Prčanj' },
  { label: 'Prè Saint Didier', value: 'Prè Saint Didier' },
  { label: 'Preajba', value: 'Preajba' },
  { label: 'Preara-Moraro-Levà Nord', value: 'Preara-Moraro-Levà Nord' },
  { label: 'Préaux', value: 'Préaux' },
  { label: 'Prebbleton', value: 'Prebbleton' },
  { label: 'Prebitz', value: 'Prebitz' },
  { label: 'Preble County', value: 'Preble County' },
  { label: 'Prebold', value: 'Prebold' },
  { label: 'Precenicco', value: 'Precenicco' },
  { label: 'Préchac', value: 'Préchac' },
  { label: 'Prechistoye', value: 'Prechistoye' },
  { label: 'Preci', value: 'Preci' },
  { label: 'Précieux', value: 'Précieux' },
  { label: 'Précigné', value: 'Précigné' },
  { label: 'Précy-Sur-Oise', value: 'Précy-Sur-Oise' },
  { label: 'Predappio', value: 'Predappio' },
  { label: 'Predavac', value: 'Predavac' },
  { label: 'Predazzo', value: 'Predazzo' },
  { label: 'Preddvor', value: 'Preddvor' },
  { label: 'Predeal', value: 'Predeal' },
  { label: 'Predeşti', value: 'Predeşti' },
  { label: 'Preding', value: 'Preding' },
  { label: 'Predivinsk', value: 'Predivinsk' },
  { label: 'Předklášteří', value: 'Předklášteří' },
  { label: 'Předměřice Nad Labem', value: 'Předměřice Nad Labem' },
  { label: 'Predoi', value: 'Predoi' },
  { label: 'Predore', value: 'Predore' },
  { label: 'Predosa', value: 'Predosa' },
  { label: 'Preeceville', value: 'Preeceville' },
  { label: 'Pré-En-Pail', value: 'Pré-En-Pail' },
  { label: 'Prees', value: 'Prees' },
  { label: 'Preesall', value: 'Preesall' },
  { label: 'Preetz', value: 'Preetz' },
  { label: 'Preetz', value: 'Preetz' },
  { label: 'Préfailles', value: 'Préfailles' },
  { label: 'Préfecture De Bassar', value: 'Préfecture De Bassar' },
  { label: 'Préfecture De Dubréka', value: 'Préfecture De Dubréka' },
  { label: 'Préfecture De Forécariah', value: 'Préfecture De Forécariah' },
  { label: 'Préfecture De Guékédou', value: 'Préfecture De Guékédou' },
  { label: 'Prefecture Of Chenzhou', value: 'Prefecture Of Chenzhou' },
  { label: 'Preganziol', value: 'Preganziol' },
  { label: 'Pregarten', value: 'Pregarten' },
  { label: 'Pregassona', value: 'Pregassona' },
  { label: 'Pregnana Milanese', value: 'Pregnana Milanese' },
  { label: 'Pregola', value: 'Pregola' },
  { label: 'Pregrada', value: 'Pregrada' },
  { label: 'Pregradnaya', value: 'Pregradnaya' },
  { label: 'Pregradnoye', value: 'Pregradnoye' },
  { label: 'Preignac', value: 'Preignac' },
  { label: 'Preignan', value: 'Preignan' },
  { label: 'Preiļi', value: 'Preiļi' },
  { label: 'Preitenegg', value: 'Preitenegg' },
  { label: 'Preixana', value: 'Preixana' },
  { label: 'Preixens', value: 'Preixens' },
  { label: 'Préjano', value: 'Préjano' },
  { label: 'Prejmer', value: 'Prejmer' },
  { label: 'Preko', value: 'Preko' },
  { label: 'Prelà Castello', value: 'Prelà Castello' },
  { label: 'Prelipca', value: 'Prelipca' },
  { label: 'Prellenkirchen', value: 'Prellenkirchen' },
  { label: 'Prelog', value: 'Prelog' },
  { label: 'Přelouč', value: 'Přelouč' },
  { label: 'Preluca Nouă', value: 'Preluca Nouă' },
  { label: 'Preluca Veche', value: 'Preluca Veche' },
  { label: 'Premana', value: 'Premana' },
  { label: 'Premariacco', value: 'Premariacco' },
  { label: 'Premeno', value: 'Premeno' },
  { label: 'Premenugo', value: 'Premenugo' },
  { label: 'Prémery', value: 'Prémery' },
  { label: 'Prémesques', value: 'Prémesques' },
  { label: 'Premia', value: 'Premia' },
  { label: 'Premià De Dalt', value: 'Premià De Dalt' },
  { label: 'Premià De Mar', value: 'Premià De Mar' },
  { label: 'Premilcuore', value: 'Premilcuore' },
  { label: 'Prémilhat', value: 'Prémilhat' },
  { label: 'Premnitz', value: 'Premnitz' },
  { label: 'Premolo', value: 'Premolo' },
  { label: 'Premont', value: 'Premont' },
  { label: 'Premosello-Chiovenda', value: 'Premosello-Chiovenda' },
  { label: 'Přemyslovice', value: 'Přemyslovice' },
  { label: 'Prentiss', value: 'Prentiss' },
  { label: 'Prentiss County', value: 'Prentiss County' },
  { label: 'Prenton', value: 'Prenton' },
  { label: 'Prenzlau', value: 'Prenzlau' },
  { label: 'Prenzlauer Berg', value: 'Prenzlauer Berg' },
  { label: 'Preobrazheniye', value: 'Preobrazheniye' },
  { label: 'Preobrazhenka', value: 'Preobrazhenka' },
  { label: 'Preobrazhenskaya Volgograd Oblast', value: 'Preobrazhenskaya Volgograd Oblast' },
  { label: 'Preone', value: 'Preone' },
  { label: 'Preore', value: 'Preore' },
  { label: 'Prepezzano', value: 'Prepezzano' },
  { label: 'Prepotto', value: 'Prepotto' },
  { label: 'Přerov', value: 'Přerov' },
  { label: 'Přerov Nad Labem', value: 'Přerov Nad Labem' },
  { label: 'Presa Blanca', value: 'Presa Blanca' },
  { label: 'Presa De Rayas', value: 'Presa De Rayas' },
  { label: 'Presa Del Rosario', value: 'Presa Del Rosario' },
  { label: 'Presas', value: 'Presas' },
  { label: 'Prescot', value: 'Prescot' },
  { label: 'Prescott', value: 'Prescott' },
  { label: 'Prescott', value: 'Prescott' },
  { label: 'Prescott', value: 'Prescott' },
  { label: 'Prescott', value: 'Prescott' },
  { label: 'Prescott Valley', value: 'Prescott Valley' },
  { label: 'Préseau', value: 'Préseau' },
  { label: 'Preseglie', value: 'Preseglie' },
  { label: 'Preseka', value: 'Preseka' },
  { label: 'Presencio', value: 'Presencio' },
  { label: 'Presenzano', value: 'Presenzano' },
  { label: 'Preserje Pri Radomljah', value: 'Preserje Pri Radomljah' },
  { label: 'Preses Les', value: 'Preses Les' },
  { label: 'Presezzo', value: 'Presezzo' },
  { label: 'Presicce', value: 'Presicce' },
  { label: 'Presidencia De La Plaza', value: 'Presidencia De La Plaza' },
  { label: 'Presidencia Roca', value: 'Presidencia Roca' },
  { label: 'Presidencia Roque Sáenz Peña', value: 'Presidencia Roque Sáenz Peña' },
  { label: 'President Quirino', value: 'President Quirino' },
  { label: 'President Quirino', value: 'President Quirino' },
  { label: 'President Roxas', value: 'President Roxas' },
  { label: 'President Roxas', value: 'President Roxas' },
  { label: 'President Roxas', value: 'President Roxas' },
  { label: 'Presidente Alves', value: 'Presidente Alves' },
  { label: 'Presidente Bernardes', value: 'Presidente Bernardes' },
  { label: 'Presidente Bernardes', value: 'Presidente Bernardes' },
  { label: 'Presidente Castello Branco', value: 'Presidente Castello Branco' },
  { label: 'Presidente Castelo Branco', value: 'Presidente Castelo Branco' },
  { label: 'Presidente Dutra', value: 'Presidente Dutra' },
  { label: 'Presidente Dutra', value: 'Presidente Dutra' },
  { label: 'Presidente Echeverría (Laja Tendida)', value: 'Presidente Echeverría (Laja Tendida)' },
  { label: 'Presidente Epitácio', value: 'Presidente Epitácio' },
  { label: 'Presidente Figueiredo', value: 'Presidente Figueiredo' },
  { label: 'Presidente Franco', value: 'Presidente Franco' },
  { label: 'Presidente Getúlio', value: 'Presidente Getúlio' },
  { label: 'Presidente Jânio Quadros', value: 'Presidente Jânio Quadros' },
  { label: 'Presidente Juárez', value: 'Presidente Juárez' },
  { label: 'Presidente Juscelino', value: 'Presidente Juscelino' },
  { label: 'Presidente Juscelino', value: 'Presidente Juscelino' },
  { label: 'Presidente Kennedy', value: 'Presidente Kennedy' },
  { label: 'Presidente Kennedy', value: 'Presidente Kennedy' },
  { label: 'Presidente Kubitschek', value: 'Presidente Kubitschek' },
  { label: 'Presidente Lucena', value: 'Presidente Lucena' },
  { label: 'Presidente Médici', value: 'Presidente Médici' },
  { label: 'Presidente Médici', value: 'Presidente Médici' },
  { label: 'Presidente Nereu', value: 'Presidente Nereu' },
  { label: 'Presidente Olegário', value: 'Presidente Olegário' },
  { label: 'Presidente Prudente', value: 'Presidente Prudente' },
  { label: 'Presidente Sarney', value: 'Presidente Sarney' },
  { label: 'Presidente Tancredo Neves', value: 'Presidente Tancredo Neves' },
  { label: 'Presidente Vargas', value: 'Presidente Vargas' },
  { label: 'Presidente Venceslau', value: 'Presidente Venceslau' },
  { label: 'Presidential Lakes Estates', value: 'Presidential Lakes Estates' },
  { label: 'Presidio', value: 'Presidio' },
  { label: 'Presidio', value: 'Presidio' },
  { label: 'Presidio (Plan De Libres)', value: 'Presidio (Plan De Libres)' },
  { label: 'Presidio County', value: 'Presidio County' },
  { label: 'Presles-En-Brie', value: 'Presles-En-Brie' },
  { label: 'Presnenskiy', value: 'Presnenskiy' },
  { label: 'Prešov', value: 'Prešov' },
  { label: 'Presque Isle', value: 'Presque Isle' },
  { label: 'Presque Isle County', value: 'Presque Isle County' },
  { label: 'Presquille', value: 'Presquille' },
  { label: 'Pressana', value: 'Pressana' },
  { label: 'Pressath', value: 'Pressath' },
  { label: 'Pressbaum', value: 'Pressbaum' },
  { label: 'Presseck', value: 'Presseck' },
  { label: 'Pressig', value: 'Pressig' },
  { label: 'Prestatyn', value: 'Prestatyn' },
  { label: 'Prestbury', value: 'Prestbury' },
  { label: 'Prestbury', value: 'Prestbury' },
  { label: 'Prestea', value: 'Prestea' },
  { label: 'Presteigne', value: 'Presteigne' },
  { label: 'Prestestranda', value: 'Prestestranda' },
  { label: 'Prestfoss', value: 'Prestfoss' },
  { label: 'Přeštice', value: 'Přeštice' },
  { label: 'Preston', value: 'Preston' },
  { label: 'Preston', value: 'Preston' },
  { label: 'Preston', value: 'Preston' },
  { label: 'Preston', value: 'Preston' },
  { label: 'Preston', value: 'Preston' },
  { label: 'Preston', value: 'Preston' },
  { label: 'Preston', value: 'Preston' },
  { label: 'Preston City', value: 'Preston City' },
  { label: 'Preston County', value: 'Preston County' },
  { label: 'Preston Heights', value: 'Preston Heights' },
  { label: 'Prestonpans', value: 'Prestonpans' },
  { label: 'Prestons', value: 'Prestons' },
  { label: 'Prestonsburg', value: 'Prestonsburg' },
  { label: 'Prestwich', value: 'Prestwich' },
  { label: 'Prestwick', value: 'Prestwick' },
  { label: 'Pretoria', value: 'Pretoria' },
  { label: 'Pretoro', value: 'Pretoro' },
  { label: 'Prettin', value: 'Prettin' },
  { label: 'Pretty Bayou', value: 'Pretty Bayou' },
  { label: 'Preturo', value: 'Preturo' },
  { label: 'Pretzfeld', value: 'Pretzfeld' },
  { label: 'Pretzier', value: 'Pretzier' },
  { label: 'Pretzsch', value: 'Pretzsch' },
  { label: 'Pretzschendorf', value: 'Pretzschendorf' },
  { label: 'Preuilly-Sur-Claise', value: 'Preuilly-Sur-Claise' },
  { label: 'Preußisch Oldendorf', value: 'Preußisch Oldendorf' },
  { label: 'Preuteşti', value: 'Preuteşti' },
  { label: 'Prevalje', value: 'Prevalje' },
  { label: 'Prevalle', value: 'Prevalle' },
  { label: 'Préverenges', value: 'Préverenges' },
  { label: 'Préveza', value: 'Préveza' },
  { label: 'Prévost', value: 'Prévost' },
  { label: 'Prey Veng', value: 'Prey Veng' },
  { label: 'Prezza', value: 'Prezza' },
  { label: 'Prezzo', value: 'Prezzo' },
  { label: 'Pri Chacón', value: 'Pri Chacón' },
  { label: 'Priamurskiy', value: 'Priamurskiy' },
  { label: 'Priaranza Del Bierzo', value: 'Priaranza Del Bierzo' },
  { label: 'Priargunsk', value: 'Priargunsk' },
  { label: 'Priargunskiy Rayon', value: 'Priargunskiy Rayon' },
  { label: 'Priay', value: 'Priay' },
  { label: 'Pribaykal’Skiy Rayon', value: 'Pribaykal’Skiy Rayon' },
  { label: 'Pribești', value: 'Pribești' },
  { label: 'Pribilești', value: 'Pribilești' },
  { label: 'Pribinić', value: 'Pribinić' },
  { label: 'Pribislavec', value: 'Pribislavec' },
  { label: 'Priboieni', value: 'Priboieni' },
  { label: 'Priboiu', value: 'Priboiu' },
  { label: 'Priboj', value: 'Priboj' },
  { label: 'Priboj', value: 'Priboj' },
  { label: 'Příbor', value: 'Příbor' },
  { label: 'Příbram', value: 'Příbram' },
  { label: 'Pribrezhnyy', value: 'Pribrezhnyy' },
  { label: 'Pribylina', value: 'Pribylina' },
  { label: 'Přibyslav', value: 'Přibyslav' },
  { label: 'Pricaz', value: 'Pricaz' },
  { label: 'Price', value: 'Price' },
  { label: 'Price County', value: 'Price County' },
  { label: 'Price Town', value: 'Price Town' },
  { label: 'Prices Fork', value: 'Prices Fork' },
  { label: 'Priceville', value: 'Priceville' },
  { label: 'Prichard', value: 'Prichard' },
  { label: 'Prichsenstadt', value: 'Prichsenstadt' },
  { label: 'Pridonskoy', value: 'Pridonskoy' },
  { label: 'Pridraga', value: 'Pridraga' },
  { label: 'Priego', value: 'Priego' },
  { label: 'Priego De Córdoba', value: 'Priego De Córdoba' },
  { label: 'Priekule', value: 'Priekule' },
  { label: 'Priekulė', value: 'Priekulė' },
  { label: 'Priekuļi', value: 'Priekuļi' },
  { label: 'Priel', value: 'Priel' },
  { label: 'Prien', value: 'Prien' },
  { label: 'Prien Am Chiemsee', value: 'Prien Am Chiemsee' },
  { label: 'Prienai', value: 'Prienai' },
  { label: 'Priero', value: 'Priero' },
  { label: 'Priesendorf', value: 'Priesendorf' },
  { label: 'Prieska', value: 'Prieska' },
  { label: 'Priest River', value: 'Priest River' },
  { label: 'Priestewitz', value: 'Priestewitz' },
  { label: 'Prieta', value: 'Prieta' },
  { label: 'Prieto Diaz', value: 'Prieto Diaz' },
  { label: 'Prievidza', value: 'Prievidza' },
  { label: 'Prigen', value: 'Prigen' },
  { label: 'Prigglitz', value: 'Prigglitz' },
  { label: 'Prignano', value: 'Prignano' },
  { label: 'Prignano Cilento', value: 'Prignano Cilento' },
  { label: 'Prignano Sulla Secchia', value: 'Prignano Sulla Secchia' },
  { label: 'Prigonrieux', value: 'Prigonrieux' },
  { label: 'Prigor', value: 'Prigor' },
  { label: 'Prigoria', value: 'Prigoria' },
  { label: 'Prigorje Brdovečko', value: 'Prigorje Brdovečko' },
  { label: 'Prigorodka', value: 'Prigorodka' },
  { label: 'Prigorodnoye', value: 'Prigorodnoye' },
  { label: 'Prigorodnoye', value: 'Prigorodnoye' },
  { label: 'Prigorodnyy', value: 'Prigorodnyy' },
  { label: 'Prigorodnyy Rayon', value: 'Prigorodnyy Rayon' },
  { label: 'Prigrevica', value: 'Prigrevica' },
  { label: 'Priisk Boko', value: 'Priisk Boko' },
  { label: 'Priiskovyy', value: 'Priiskovyy' },
  { label: 'Prijedor', value: 'Prijedor' },
  { label: 'Prijepolje', value: 'Prijepolje' },
  { label: 'Příkazy', value: 'Příkazy' },
  { label: 'Prikubanskiy', value: 'Prikubanskiy' },
  { label: 'Prikubanskiy Rayon', value: 'Prikubanskiy Rayon' },
  { label: 'Priladozhskiy', value: 'Priladozhskiy' },
  { label: 'Prilep', value: 'Prilep' },
  { label: 'Prilly', value: 'Prilly' },
  { label: 'Priluzskiy Rayon', value: 'Priluzskiy Rayon' },
  { label: 'Primalkinskoye', value: 'Primalkinskoye' },
  { label: 'Primaluna', value: 'Primaluna' },
  { label: 'Primavera', value: 'Primavera' },
  { label: 'Primavera', value: 'Primavera' },
  { label: 'Primavera', value: 'Primavera' },
  { label: 'Primavera De Rondônia', value: 'Primavera De Rondônia' },
  { label: 'Primavera Do Leste', value: 'Primavera Do Leste' },
  { label: 'Primbee', value: 'Primbee' },
  { label: 'Přimda', value: 'Přimda' },
  { label: 'Primeira Cruz', value: 'Primeira Cruz' },
  { label: 'Primeiro De Maio', value: 'Primeiro De Maio' },
  { label: 'Primer Cantón', value: 'Primer Cantón' },
  { label: 'Primer Ensanche', value: 'Primer Ensanche' },
  { label: 'Primera', value: 'Primera' },
  { label: 'Primera De Analco', value: 'Primera De Analco' },
  { label: 'Primera Fracción De Crespo', value: 'Primera Fracción De Crespo' },
  { label: 'Primero De Enero', value: 'Primero De Enero' },
  { label: 'Primero De Mayo', value: 'Primero De Mayo' },
  { label: 'Primero De Mayo', value: 'Primero De Mayo' },
  { label: 'Primero De Mayo', value: 'Primero De Mayo' },
  { label: 'Primero De Mayo', value: 'Primero De Mayo' },
  { label: 'Primero De Mayo', value: 'Primero De Mayo' },
  { label: 'Primero De Mayo', value: 'Primero De Mayo' },
  { label: 'Primero De Mayo (Chupaderos)', value: 'Primero De Mayo (Chupaderos)' },
  { label: 'Primghar', value: 'Primghar' },
  { label: 'Primo Tapia', value: 'Primo Tapia' },
  { label: 'Primorka', value: 'Primorka' },
  { label: 'Primorsk', value: 'Primorsk' },
  { label: 'Primorsk', value: 'Primorsk' },
  { label: 'Primorsk', value: 'Primorsk' },
  { label: 'Primorskiy', value: 'Primorskiy' },
  { label: 'Primorskiy', value: 'Primorskiy' },
  { label: 'Primorskiy Rayon', value: 'Primorskiy Rayon' },
  { label: 'Primorskiy Rayon', value: 'Primorskiy Rayon' },
  { label: 'Primorsko', value: 'Primorsko' },
  { label: 'Primorsko-Akhtarsk', value: 'Primorsko-Akhtarsk' },
  { label: 'Primošten', value: 'Primošten' },
  { label: 'Prince Albert', value: 'Prince Albert' },
  { label: 'Prince Albert', value: 'Prince Albert' },
  { label: 'Prince Edward', value: 'Prince Edward' },
  { label: 'Prince Edward County', value: 'Prince Edward County' },
  { label: 'Prince Frederick', value: 'Prince Frederick' },
  { label: 'Prince George', value: 'Prince George' },
  { label: 'Prince George', value: 'Prince George' },
  { label: 'Prince George County', value: 'Prince George County' },
  { label: 'Prince Georges County', value: 'Prince Georges County' },
  { label: 'Prince Rupert', value: 'Prince Rupert' },
  { label: 'Prince William County', value: 'Prince William County' },
  { label: 'Princenhage', value: 'Princenhage' },
  { label: 'Princes Hill', value: 'Princes Hill' },
  { label: 'Princes Lakes', value: 'Princes Lakes' },
  { label: 'Princes Risborough', value: 'Princes Risborough' },
  { label: 'Princes Town', value: 'Princes Town' },
  { label: 'Princesa', value: 'Princesa' },
  { label: 'Princesa Isabel', value: 'Princesa Isabel' },
  { label: 'Princess Anne', value: 'Princess Anne' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton', value: 'Princeton' },
  { label: 'Princeton Junction', value: 'Princeton Junction' },
  { label: 'Princeton Meadows', value: 'Princeton Meadows' },
  { label: 'Princetown', value: 'Princetown' },
  { label: 'Princeville', value: 'Princeville' },
  { label: 'Princeville', value: 'Princeville' },
  { label: 'Princeville', value: 'Princeville' },
  { label: 'Princeville', value: 'Princeville' },
  { label: 'Princeville', value: 'Princeville' },
  { label: 'Prineville', value: 'Prineville' },
  { label: 'Pringi', value: 'Pringi' },
  { label: 'Pringy', value: 'Pringy' },
  { label: 'Pringy', value: 'Pringy' },
  { label: 'Prínos', value: 'Prínos' },
  { label: 'Prinquiau', value: 'Prinquiau' },
  { label: 'Prinsenbeek', value: 'Prinsenbeek' },
  { label: 'Prinza', value: 'Prinza' },
  { label: 'Prinzapolka', value: 'Prinzapolka' },
  { label: 'Prinzersdorf', value: 'Prinzersdorf' },
  { label: 'Priob’Ye', value: 'Priob’Ye' },
  { label: 'Priocca', value: 'Priocca' },
  { label: 'Priola', value: 'Priola' },
  { label: 'Priolo Gargallo', value: 'Priolo Gargallo' },
  { label: 'Prionezhskiy Rayon', value: 'Prionezhskiy Rayon' },
  { label: 'Prior Lake', value: 'Prior Lake' },
  { label: 'Prior Velho', value: 'Prior Velho' },
  { label: 'Prior Velho', value: 'Prior Velho' },
  { label: 'Prioro', value: 'Prioro' },
  { label: 'Priory', value: 'Priory' },
  { label: 'Priozersk', value: 'Priozersk' },
  { label: 'Priozërsk', value: 'Priozërsk' },
  { label: 'Priozersky', value: 'Priozersky' },
  { label: 'Priozyornoye', value: 'Priozyornoye' },
  { label: 'Pripoare', value: 'Pripoare' },
  { label: 'Priponeşti', value: 'Priponeşti' },
  { label: 'Prisăcani', value: 'Prisăcani' },
  { label: 'Prisdorf', value: 'Prisdorf' },
  { label: 'Priseaca', value: 'Priseaca' },
  { label: 'Prishibinskoye', value: 'Prishibinskoye' },
  { label: 'Prislonica', value: 'Prislonica' },
  { label: 'Prislop', value: 'Prislop' },
  { label: 'Příšovice', value: 'Příšovice' },
  { label: 'Prissé', value: 'Prissé' },
  { label: 'Pristen’', value: 'Pristen’' },
  { label: 'Pristenskiy Rayon', value: 'Pristenskiy Rayon' },
  { label: 'Pristol', value: 'Pristol' },
  { label: 'Priston', value: 'Priston' },
  { label: 'Pritomskiy', value: 'Pritomskiy' },
  { label: 'Prittitz', value: 'Prittitz' },
  { label: 'Prittriching', value: 'Prittriching' },
  { label: 'Pritzwalk', value: 'Pritzwalk' },
  { label: 'Priula-Colfosco', value: 'Priula-Colfosco' },
  { label: 'Priupskiy', value: 'Priupskiy' },
  { label: 'Priural’Skiy Rayon', value: 'Priural’Skiy Rayon' },
  { label: 'Privada Del Álamo', value: 'Privada Del Álamo' },
  { label: 'Privas', value: 'Privas' },
  { label: 'Privateer', value: 'Privateer' },
  { label: 'Priverno', value: 'Priverno' },
  { label: 'Privetnoye', value: 'Privetnoye' },
  { label: 'Privlaka', value: 'Privlaka' },
  { label: 'Privlaka', value: 'Privlaka' },
  { label: 'Privodino', value: 'Privodino' },
  { label: 'Privolzhsk', value: 'Privolzhsk' },
  { label: 'Privolzhskiy', value: 'Privolzhskiy' },
  { label: 'Privolzhskiy', value: 'Privolzhskiy' },
  { label: 'Privolzhskiy Rayon', value: 'Privolzhskiy Rayon' },
  { label: 'Privolzhskiy Rayon', value: 'Privolzhskiy Rayon' },
  { label: 'Privolzhskoye', value: 'Privolzhskoye' },
  { label: 'Prix-Lès-Mézières', value: 'Prix-Lès-Mézières' },
  { label: 'Priyutnoye', value: 'Priyutnoye' },
  { label: 'Priyutovo', value: 'Priyutovo' },
  { label: 'Priziac', value: 'Priziac' },
  { label: 'Prizzi', value: 'Prizzi' },
  { label: 'Prnjavor', value: 'Prnjavor' },
  { label: 'Proástio', value: 'Proástio' },
  { label: 'Proaza', value: 'Proaza' },
  { label: 'Probishtip', value: 'Probishtip' },
  { label: 'Probolinggo', value: 'Probolinggo' },
  { label: 'Proboštov', value: 'Proboštov' },
  { label: 'Probota', value: 'Probota' },
  { label: 'Probota', value: 'Probota' },
  { label: 'Probsteierhagen', value: 'Probsteierhagen' },
  { label: 'Probstzella', value: 'Probstzella' },
  { label: 'Probus', value: 'Probus' },
  { label: 'Proceno', value: 'Proceno' },
  { label: 'Prochnookopskaya', value: 'Prochnookopskaya' },
  { label: 'Próchoma', value: 'Próchoma' },
  { label: 'Prochowice', value: 'Prochowice' },
  { label: 'Procida', value: 'Procida' },
  { label: 'Proctor', value: 'Proctor' },
  { label: 'Proddatur', value: 'Proddatur' },
  { label: 'Produleşti', value: 'Produleşti' },
  { label: 'Proença-A-Nova', value: 'Proença-A-Nova' },
  { label: 'Profesor Carlos Hank González', value: 'Profesor Carlos Hank González' },
  { label: 'Professor Jamil', value: 'Professor Jamil' },
  { label: 'Profítis Ilías', value: 'Profítis Ilías' },
  { label: 'Profítis Ilías', value: 'Profítis Ilías' },
  { label: 'Profondeville', value: 'Profondeville' },
  { label: 'Profresor Roberto Barrios', value: 'Profresor Roberto Barrios' },
  { label: 'Progetto Case Bazzano', value: 'Progetto Case Bazzano' },
  { label: 'Progetto Case Sassa Nsi', value: 'Progetto Case Sassa Nsi' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso', value: 'Progreso' },
  { label: 'Progreso De Alvaro Obregon', value: 'Progreso De Alvaro Obregon' },
  { label: 'Progreso De Juárez', value: 'Progreso De Juárez' },
  { label: 'Progreso De Zaragoza', value: 'Progreso De Zaragoza' },
  { label: 'Progreso Industrial', value: 'Progreso Industrial' },
  { label: 'Progress', value: 'Progress' },
  { label: 'Progress Village', value: 'Progress Village' },
  { label: 'Progresso', value: 'Progresso' },
  { label: 'Progresso', value: 'Progresso' },
  { label: 'Progresu', value: 'Progresu' },
  { label: 'Prohn', value: 'Prohn' },
  { label: 'Prohozești', value: 'Prohozești' },
  { label: 'Prokhladnenskiy Rayon', value: 'Prokhladnenskiy Rayon' },
  { label: 'Prokhladnyy', value: 'Prokhladnyy' },
  { label: 'Prokhorovka', value: 'Prokhorovka' },
  { label: 'Prokhorovskiy Rayon', value: 'Prokhorovskiy Rayon' },
  { label: 'Prokop’Yevsk', value: 'Prokop’Yevsk' },
  { label: 'Prokop’Yevskiy Rayon', value: 'Prokop’Yevskiy Rayon' },
  { label: 'Prokópi', value: 'Prokópi' },
  { label: 'Prokudskoye', value: 'Prokudskoye' },
  { label: 'Prokuplje', value: 'Prokuplje' },
  { label: 'Proleb', value: 'Proleb' },
  { label: 'Proletar', value: 'Proletar' },
  { label: 'Proletariy', value: 'Proletariy' },
  { label: 'Proletarsk', value: 'Proletarsk' },
  { label: 'Proletarskiy', value: 'Proletarskiy' },
  { label: 'Proletarskiy', value: 'Proletarskiy' },
  { label: 'Prolisky', value: 'Prolisky' },
  { label: 'Proložac', value: 'Proložac' },
  { label: 'Prómachoi', value: 'Prómachoi' },
  { label: 'Promina', value: 'Promina' },
  { label: 'Promissão', value: 'Promissão' },
  { label: 'Promna', value: 'Promna' },
  { label: 'Promyshlennaya', value: 'Promyshlennaya' },
  { label: 'Promyshlennovskiy', value: 'Promyshlennovskiy' },
  { label: 'Promyshlennyy', value: 'Promyshlennyy' },
  { label: 'Proni', value: 'Proni' },
  { label: 'Pronsk', value: 'Pronsk' },
  { label: 'Pronstorf', value: 'Pronstorf' },
  { label: 'Pronunciamiento', value: 'Pronunciamiento' },
  { label: 'Propata', value: 'Propata' },
  { label: 'Prophetstown', value: 'Prophetstown' },
  { label: 'Propriá', value: 'Propriá' },
  { label: 'Propriano', value: 'Propriano' },
  { label: 'Proseč', value: 'Proseč' },
  { label: 'Prosecco-Contovello', value: 'Prosecco-Contovello' },
  { label: 'Prosek', value: 'Prosek' },
  { label: 'Proserpine', value: 'Proserpine' },
  { label: 'Proserpio', value: 'Proserpio' },
  { label: 'Prosotsáni', value: 'Prosotsáni' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Prospect Heights', value: 'Prospect Heights' },
  { label: 'Prospect Park', value: 'Prospect Park' },
  { label: 'Prospect Park', value: 'Prospect Park' },
  { label: 'Prospect Vale', value: 'Prospect Vale' },
  { label: 'Prosper', value: 'Prosper' },
  { label: 'Prosperidad', value: 'Prosperidad' },
  { label: 'Prosperidad', value: 'Prosperidad' },
  { label: 'Prosperidad', value: 'Prosperidad' },
  { label: 'Prosperity', value: 'Prosperity' },
  { label: 'Prosperity', value: 'Prosperity' },
  { label: 'Prosperous', value: 'Prosperous' },
  { label: 'Prossedi', value: 'Prossedi' },
  { label: 'Prosselsheim', value: 'Prosselsheim' },
  { label: 'Prosser', value: 'Prosser' },
  { label: 'Prostějov', value: 'Prostějov' },
  { label: 'Prostki', value: 'Prostki' },
  { label: 'Prosto', value: 'Prosto' },
  { label: 'Prostornoye', value: 'Prostornoye' },
  { label: 'Prostřední Bečva', value: 'Prostřední Bečva' },
  { label: 'Prosvet', value: 'Prosvet' },
  { label: 'Prószków', value: 'Prószków' },
  { label: 'Proszowice', value: 'Proszowice' },
  { label: 'Protaras', value: 'Protaras' },
  { label: 'Protásio Alves', value: 'Protásio Alves' },
  { label: 'Protección', value: 'Protección' },
  { label: 'Próti', value: 'Próti' },
  { label: 'Protivanov', value: 'Protivanov' },
  { label: 'Protivín', value: 'Protivín' },
  { label: 'Prottes', value: 'Prottes' },
  { label: 'Protvino', value: 'Protvino' },
  { label: 'Prötzel', value: 'Prötzel' },
  { label: 'Prouvy', value: 'Prouvy' },
  { label: 'Prova', value: 'Prova' },
  { label: 'Provadia', value: 'Provadia' },
  { label: 'Provaglio Diseo', value: 'Provaglio Diseo' },
  { label: 'Provaglio Val Sabbia', value: 'Provaglio Val Sabbia' },
  { label: 'Provatás', value: 'Provatás' },
  { label: 'Provencio El', value: 'Provencio El' },
  { label: 'Proves - Proveis', value: 'Proves - Proveis' },
  { label: 'Provezze', value: 'Provezze' },
  { label: 'Providence', value: 'Providence' },
  { label: 'Providence', value: 'Providence' },
  { label: 'Providence', value: 'Providence' },
  { label: 'Providence', value: 'Providence' },
  { label: 'Providence', value: 'Providence' },
  { label: 'Providence County', value: 'Providence County' },
  { label: 'Providencia', value: 'Providencia' },
  { label: 'Providencia', value: 'Providencia' },
  { label: 'Providencia', value: 'Providencia' },
  { label: 'Providencia', value: 'Providencia' },
  { label: 'Providencia', value: 'Providencia' },
  { label: 'Providencia De Nápoles', value: 'Providencia De Nápoles' },
  { label: 'Provideniya', value: 'Provideniya' },
  { label: 'Providenskiy Rayon', value: 'Providenskiy Rayon' },
  { label: 'Proville', value: 'Proville' },
  { label: 'Provin', value: 'Provin' },
  { label: 'Province De L’Oudalan', value: 'Province De L’Oudalan' },
  { label: 'Province De La Bougouriba', value: 'Province De La Bougouriba' },
  { label: 'Province De La Comoé', value: 'Province De La Comoé' },
  { label: 'Province De La Komandjoari', value: 'Province De La Komandjoari' },
  { label: 'Province De La Kompienga', value: 'Province De La Kompienga' },
  { label: 'Province De La Kossi', value: 'Province De La Kossi' },
  { label: 'Province De La Léraba', value: 'Province De La Léraba' },
  { label: 'Province De La Sissili', value: 'Province De La Sissili' },
  { label: 'Province De La Tapoa', value: 'Province De La Tapoa' },
  { label: 'Province De Liège', value: 'Province De Liège' },
  { label: 'Province De Namur', value: 'Province De Namur' },
  { label: 'Province Des Balé', value: 'Province Des Balé' },
  { label: 'Province Des Banwa', value: 'Province Des Banwa' },
  { label: 'Province Du Bam', value: 'Province Du Bam' },
  { label: 'Province Du Boulgou', value: 'Province Du Boulgou' },
  { label: 'Province Du Boulkiemdé', value: 'Province Du Boulkiemdé' },
  { label: 'Province Du Brabant Wallon', value: 'Province Du Brabant Wallon' },
  { label: 'Province Du Ganzourgou', value: 'Province Du Ganzourgou' },
  { label: 'Province Du Gourma', value: 'Province Du Gourma' },
  { label: 'Province Du Hainaut', value: 'Province Du Hainaut' },
  { label: 'Province Du Houet', value: 'Province Du Houet' },
  { label: 'Province Du Ioba', value: 'Province Du Ioba' },
  { label: 'Province Du Kénédougou', value: 'Province Du Kénédougou' },
  { label: 'Province Du Koulpélogo', value: 'Province Du Koulpélogo' },
  { label: 'Province Du Kourwéogo', value: 'Province Du Kourwéogo' },
  { label: 'Province Du Loroum', value: 'Province Du Loroum' },
  { label: 'Province Du Luxembourg', value: 'Province Du Luxembourg' },
  { label: 'Province Du Mouhoun', value: 'Province Du Mouhoun' },
  { label: 'Province Du Namentenga', value: 'Province Du Namentenga' },
  { label: 'Province Du Nayala', value: 'Province Du Nayala' },
  { label: 'Province Du Noumbièl', value: 'Province Du Noumbièl' },
  { label: 'Province Du Passoré', value: 'Province Du Passoré' },
  { label: 'Province Du Poni', value: 'Province Du Poni' },
  { label: 'Province Du Sanguié', value: 'Province Du Sanguié' },
  { label: 'Province Du Sanmatenga', value: 'Province Du Sanmatenga' },
  { label: 'Province Du Séno', value: 'Province Du Séno' },
  { label: 'Province Du Soum', value: 'Province Du Soum' },
  { label: 'Province Du Sourou', value: 'Province Du Sourou' },
  { label: 'Province Du Tuy', value: 'Province Du Tuy' },
  { label: 'Province Du Yagha', value: 'Province Du Yagha' },
  { label: 'Province Du Yatenga', value: 'Province Du Yatenga' },
  { label: 'Province Du Ziro', value: 'Province Du Ziro' },
  { label: 'Province Du Zondoma', value: 'Province Du Zondoma' },
  { label: 'Province Of  Zamboanga Del Sur', value: 'Province Of  Zamboanga Del Sur' },
  { label: 'Province Of Abra', value: 'Province Of Abra' },
  { label: 'Province Of Agusan Del Norte', value: 'Province Of Agusan Del Norte' },
  { label: 'Province Of Agusan Del Sur', value: 'Province Of Agusan Del Sur' },
  { label: 'Province Of Aklan', value: 'Province Of Aklan' },
  { label: 'Province Of Albay', value: 'Province Of Albay' },
  { label: 'Province Of Antique', value: 'Province Of Antique' },
  { label: 'Province Of Arezzo', value: 'Province Of Arezzo' },
  { label: 'Province Of Aurora', value: 'Province Of Aurora' },
  { label: 'Province Of Basilan', value: 'Province Of Basilan' },
  { label: 'Province Of Bataan', value: 'Province Of Bataan' },
  { label: 'Province Of Batanes', value: 'Province Of Batanes' },
  { label: 'Province Of Batangas', value: 'Province Of Batangas' },
  { label: 'Province Of Benguet', value: 'Province Of Benguet' },
  { label: 'Province Of Bukidnon', value: 'Province Of Bukidnon' },
  { label: 'Province Of Bulacan', value: 'Province Of Bulacan' },
  { label: 'Province Of Cagayan', value: 'Province Of Cagayan' },
  { label: 'Province Of Camarines Norte', value: 'Province Of Camarines Norte' },
  { label: 'Province Of Camarines Sur', value: 'Province Of Camarines Sur' },
  { label: 'Province Of Camiguin', value: 'Province Of Camiguin' },
  { label: 'Province Of Capiz', value: 'Province Of Capiz' },
  { label: 'Province Of Catanduanes', value: 'Province Of Catanduanes' },
  { label: 'Province Of Cavite', value: 'Province Of Cavite' },
  { label: 'Province Of Cebu', value: 'Province Of Cebu' },
  { label: 'Province Of Cotabato', value: 'Province Of Cotabato' },
  { label: 'Province Of Davao Del Norte', value: 'Province Of Davao Del Norte' },
  { label: 'Province Of Davao Del Sur', value: 'Province Of Davao Del Sur' },
  { label: 'Province Of Davao Oriental', value: 'Province Of Davao Oriental' },
  { label: 'Province Of Eastern Samar', value: 'Province Of Eastern Samar' },
  { label: 'Province Of Fermo', value: 'Province Of Fermo' },
  { label: 'Province Of Florence', value: 'Province Of Florence' },
  { label: 'Province Of Guimaras', value: 'Province Of Guimaras' },
  { label: 'Province Of Ifugao', value: 'Province Of Ifugao' },
  { label: 'Province Of Ilocos Norte', value: 'Province Of Ilocos Norte' },
  { label: 'Province Of Ilocos Sur', value: 'Province Of Ilocos Sur' },
  { label: 'Province Of Iloilo', value: 'Province Of Iloilo' },
  { label: 'Province Of Isabela', value: 'Province Of Isabela' },
  { label: 'Province Of La Union', value: 'Province Of La Union' },
  { label: 'Province Of Laguna', value: 'Province Of Laguna' },
  { label: 'Province Of Lanao Del Norte', value: 'Province Of Lanao Del Norte' },
  { label: 'Province Of Lanao Del Sur', value: 'Province Of Lanao Del Sur' },
  { label: 'Province Of Leyte', value: 'Province Of Leyte' },
  { label: 'Province Of Maguindanao', value: 'Province Of Maguindanao' },
  { label: 'Province Of Marinduque', value: 'Province Of Marinduque' },
  { label: 'Province Of Masbate', value: 'Province Of Masbate' },
  { label: 'Province Of Mindoro Occidental', value: 'Province Of Mindoro Occidental' },
  { label: 'Province Of Mindoro Oriental', value: 'Province Of Mindoro Oriental' },
  { label: 'Province Of Misamis Occidental', value: 'Province Of Misamis Occidental' },
  { label: 'Province Of Misamis Oriental', value: 'Province Of Misamis Oriental' },
  { label: 'Province Of Negros Occidental', value: 'Province Of Negros Occidental' },
  { label: 'Province Of Negros Oriental', value: 'Province Of Negros Oriental' },
  { label: 'Province Of Northern Samar', value: 'Province Of Northern Samar' },
  { label: 'Province Of Nueva Ecija', value: 'Province Of Nueva Ecija' },
  { label: 'Province Of Nueva Vizcaya', value: 'Province Of Nueva Vizcaya' },
  { label: 'Province Of Palawan', value: 'Province Of Palawan' },
  { label: 'Province Of Pampanga', value: 'Province Of Pampanga' },
  { label: 'Province Of Pangasinan', value: 'Province Of Pangasinan' },
  { label: 'Province Of Pisa', value: 'Province Of Pisa' },
  { label: 'Province Of Quezon', value: 'Province Of Quezon' },
  { label: 'Province Of Quirino', value: 'Province Of Quirino' },
  { label: 'Province Of Rizal', value: 'Province Of Rizal' },
  { label: 'Province Of Romblon', value: 'Province Of Romblon' },
  { label: 'Province Of Samar', value: 'Province Of Samar' },
  { label: 'Province Of Sarangani', value: 'Province Of Sarangani' },
  { label: 'Province Of Siquijor', value: 'Province Of Siquijor' },
  { label: 'Province Of Sorsogon', value: 'Province Of Sorsogon' },
  { label: 'Province Of South Cotabato', value: 'Province Of South Cotabato' },
  { label: 'Province Of Southern Leyte', value: 'Province Of Southern Leyte' },
  { label: 'Province Of Sultan Kudarat', value: 'Province Of Sultan Kudarat' },
  { label: 'Province Of Sulu', value: 'Province Of Sulu' },
  { label: 'Province Of Surigao Del Norte', value: 'Province Of Surigao Del Norte' },
  { label: 'Province Of Surigao Del Sur', value: 'Province Of Surigao Del Sur' },
  { label: 'Province Of Tarlac', value: 'Province Of Tarlac' },
  { label: 'Province Of Tawi-Tawi', value: 'Province Of Tawi-Tawi' },
  { label: 'Province Of Zambales', value: 'Province Of Zambales' },
  { label: 'Province Of Zamboanga Del Norte', value: 'Province Of Zamboanga Del Norte' },
  { label: 'Province Of Zamboanga Sibugay', value: 'Province Of Zamboanga Sibugay' },
  { label: 'Provincetown', value: 'Provincetown' },
  { label: 'Provincia Abuná', value: 'Provincia Abuná' },
  { label: 'Provincia Alonzo De Ibáñez', value: 'Provincia Alonzo De Ibáñez' },
  { label: 'Provincia Andrés Ibáñez', value: 'Provincia Andrés Ibáñez' },
  { label: 'Provincia Ángel Sandoval', value: 'Provincia Ángel Sandoval' },
  { label: 'Provincia Arani', value: 'Provincia Arani' },
  { label: 'Provincia Arce', value: 'Provincia Arce' },
  { label: 'Provincia Aroma', value: 'Provincia Aroma' },
  { label: 'Provincia Arque', value: 'Provincia Arque' },
  { label: 'Provincia Autonoma Di Trento', value: 'Provincia Autonoma Di Trento' },
  { label: 'Provincia Avaroa', value: 'Provincia Avaroa' },
  { label: 'Provincia Avilez', value: 'Provincia Avilez' },
  { label: 'Provincia Ayopaya', value: 'Provincia Ayopaya' },
  { label: 'Provincia Azurduy', value: 'Provincia Azurduy' },
  { label: 'Provincia Bautista Saavedra', value: 'Provincia Bautista Saavedra' },
  { label: 'Provincia Belisario Boeto', value: 'Provincia Belisario Boeto' },
  { label: 'Provincia Camacho', value: 'Provincia Camacho' },
  { label: 'Provincia Campero', value: 'Provincia Campero' },
  { label: 'Provincia Capinota', value: 'Provincia Capinota' },
  { label: 'Provincia Carangas', value: 'Provincia Carangas' },
  { label: 'Provincia Carrasco', value: 'Provincia Carrasco' },
  { label: 'Provincia Cercado', value: 'Provincia Cercado' },
  { label: 'Provincia Cercado', value: 'Provincia Cercado' },
  { label: 'Provincia Cercado', value: 'Provincia Cercado' },
  { label: 'Provincia Cercado', value: 'Provincia Cercado' },
  { label: 'Provincia Chaparé', value: 'Provincia Chaparé' },
  { label: 'Provincia Charcas', value: 'Provincia Charcas' },
  { label: 'Provincia Chayanta', value: 'Provincia Chayanta' },
  { label: 'Provincia Chiquitos', value: 'Provincia Chiquitos' },
  { label: 'Provincia Cordillera', value: 'Provincia Cordillera' },
  { label: 'Provincia Daniel Campos', value: 'Provincia Daniel Campos' },
  { label: 'Provincia De Abancay', value: 'Provincia De Abancay' },
  { label: 'Provincia De Acobamba', value: 'Provincia De Acobamba' },
  { label: 'Provincia De Acomayo', value: 'Provincia De Acomayo' },
  { label: 'Provincia De Aija', value: 'Provincia De Aija' },
  { label: 'Provincia De Alicante', value: 'Provincia De Alicante' },
  { label: 'Provincia De Ambo', value: 'Provincia De Ambo' },
  { label: 'Provincia De Andahuaylas', value: 'Provincia De Andahuaylas' },
  { label: 'Provincia De Angaraes', value: 'Provincia De Angaraes' },
  { label: 'Provincia De Anta', value: 'Provincia De Anta' },
  { label: 'Provincia De Antabamba', value: 'Provincia De Antabamba' },
  { label: 'Provincia De Antonio Raymondi', value: 'Provincia De Antonio Raymondi' },
  { label: 'Provincia De Arequipa', value: 'Provincia De Arequipa' },
  { label: 'Provincia De Ávila', value: 'Provincia De Ávila' },
  { label: 'Provincia De Ayabaca', value: 'Provincia De Ayabaca' },
  { label: 'Provincia De Aymaraes', value: 'Provincia De Aymaraes' },
  { label: 'Provincia De Azángaro', value: 'Provincia De Azángaro' },
  { label: 'Provincia De Bagua', value: 'Provincia De Bagua' },
  { label: 'Provincia De Bolívar', value: 'Provincia De Bolívar' },
  { label: 'Provincia De Bolognesi', value: 'Provincia De Bolognesi' },
  { label: 'Provincia De Bongará', value: 'Provincia De Bongará' },
  { label: 'Provincia De Burgos', value: 'Provincia De Burgos' },
  { label: 'Provincia De Cajabamba', value: 'Provincia De Cajabamba' },
  { label: 'Provincia De Cajamarca', value: 'Provincia De Cajamarca' },
  { label: 'Provincia De Cajatambo', value: 'Provincia De Cajatambo' },
  { label: 'Provincia De Calca', value: 'Provincia De Calca' },
  { label: 'Provincia De Camaná', value: 'Provincia De Camaná' },
  { label: 'Provincia De Canas', value: 'Provincia De Canas' },
  { label: 'Provincia De Canchis', value: 'Provincia De Canchis' },
  { label: 'Provincia De Cañete', value: 'Provincia De Cañete' },
  { label: 'Provincia De Cangallo', value: 'Provincia De Cangallo' },
  { label: 'Provincia De Canta', value: 'Provincia De Canta' },
  { label: 'Provincia De Cantabria', value: 'Provincia De Cantabria' },
  { label: 'Provincia De Carabaya', value: 'Provincia De Carabaya' },
  { label: 'Provincia De Caravelí', value: 'Provincia De Caravelí' },
  { label: 'Provincia De Carhuaz', value: 'Provincia De Carhuaz' },
  { label: 'Provincia De Casma', value: 'Provincia De Casma' },
  { label: 'Província De Castelló', value: 'Província De Castelló' },
  { label: 'Provincia De Castilla', value: 'Provincia De Castilla' },
  { label: 'Provincia De Castrovirreyna', value: 'Provincia De Castrovirreyna' },
  { label: 'Provincia De Caylloma', value: 'Provincia De Caylloma' },
  { label: 'Provincia De Celendín', value: 'Provincia De Celendín' },
  { label: 'Provincia De Chachapoyas', value: 'Provincia De Chachapoyas' },
  { label: 'Provincia De Chiclayo', value: 'Provincia De Chiclayo' },
  { label: 'Provincia De Chincha', value: 'Provincia De Chincha' },
  { label: 'Provincia De Chota', value: 'Provincia De Chota' },
  { label: 'Provincia De Chucuito', value: 'Provincia De Chucuito' },
  { label: 'Provincia De Chumbivilcas', value: 'Provincia De Chumbivilcas' },
  { label: 'Provincia De Chupaca', value: 'Provincia De Chupaca' },
  { label: 'Provincia De Churcampa', value: 'Provincia De Churcampa' },
  { label: 'Provincia De Concepción', value: 'Provincia De Concepción' },
  { label: 'Provincia De Condesuyos', value: 'Provincia De Condesuyos' },
  { label: 'Provincia De Contralmirante Villar', value: 'Provincia De Contralmirante Villar' },
  { label: 'Provincia De Contumazá', value: 'Provincia De Contumazá' },
  { label: 'Provincia De Coronel Portillo', value: 'Provincia De Coronel Portillo' },
  { label: 'Provincia De Corongo', value: 'Provincia De Corongo' },
  { label: 'Provincia De Cotabambas', value: 'Provincia De Cotabambas' },
  { label: 'Provincia De Cusco', value: 'Provincia De Cusco' },
  { label: 'Provincia De Cutervo', value: 'Provincia De Cutervo' },
  { label: 'Provincia De Daniel Carrión', value: 'Provincia De Daniel Carrión' },
  { label: 'Provincia De Dos De Mayo', value: 'Provincia De Dos De Mayo' },
  { label: 'Provincia De Espinar', value: 'Provincia De Espinar' },
  { label: 'Provincia De Ferreñafe', value: 'Provincia De Ferreñafe' },
  { label: 'Provincia De General Sánchez Cerro', value: 'Provincia De General Sánchez Cerro' },
  { label: 'Provincia De Grau', value: 'Provincia De Grau' },
  { label: 'Provincia De Hualgayoc', value: 'Provincia De Hualgayoc' },
  { label: 'Provincia De Huallaga', value: 'Provincia De Huallaga' },
  { label: 'Provincia De Huamalíes', value: 'Provincia De Huamalíes' },
  { label: 'Provincia De Huamanga', value: 'Provincia De Huamanga' },
  { label: 'Provincia De Huanca Sancos', value: 'Provincia De Huanca Sancos' },
  { label: 'Provincia De Huancabamba', value: 'Provincia De Huancabamba' },
  { label: 'Provincia De Huancané', value: 'Provincia De Huancané' },
  { label: 'Provincia De Huancavelica', value: 'Provincia De Huancavelica' },
  { label: 'Provincia De Huancayo', value: 'Provincia De Huancayo' },
  { label: 'Provincia De Huanta', value: 'Provincia De Huanta' },
  { label: 'Provincia De Huánuco', value: 'Provincia De Huánuco' },
  { label: 'Provincia De Huaral', value: 'Provincia De Huaral' },
  { label: 'Provincia De Huaraz', value: 'Provincia De Huaraz' },
  { label: 'Provincia De Huari', value: 'Provincia De Huari' },
  { label: 'Provincia De Huarmey', value: 'Provincia De Huarmey' },
  { label: 'Provincia De Huarochirí', value: 'Provincia De Huarochirí' },
  { label: 'Provincia De Huaylas', value: 'Provincia De Huaylas' },
  { label: 'Provincia De Huesca', value: 'Provincia De Huesca' },
  { label: 'Provincia De Ica', value: 'Provincia De Ica' },
  { label: 'Provincia De Ilo', value: 'Provincia De Ilo' },
  { label: 'Provincia De Islay', value: 'Provincia De Islay' },
  { label: 'Provincia De Jaén', value: 'Provincia De Jaén' },
  { label: 'Provincia De Jauja', value: 'Provincia De Jauja' },
  { label: 'Provincia De Junín', value: 'Provincia De Junín' },
  { label: 'Provincia De La Convención', value: 'Provincia De La Convención' },
  { label: 'Provincia De La Mar', value: 'Provincia De La Mar' },
  { label: 'Provincia De La Rioja', value: 'Provincia De La Rioja' },
  { label: 'Provincia De La Unión', value: 'Provincia De La Unión' },
  { label: 'Provincia De Lamas', value: 'Provincia De Lamas' },
  { label: 'Provincia De Lambayeque', value: 'Provincia De Lambayeque' },
  { label: 'Provincia De Lampa', value: 'Provincia De Lampa' },
  { label: 'Provincia De Las Palmas', value: 'Provincia De Las Palmas' },
  { label: 'Provincia De León', value: 'Provincia De León' },
  { label: 'Provincia De Leoncio Prado', value: 'Provincia De Leoncio Prado' },
  { label: 'Provincia De Lucanas', value: 'Provincia De Lucanas' },
  { label: 'Provincia De Luya', value: 'Provincia De Luya' },
  { label: 'Provincia De Madrid', value: 'Provincia De Madrid' },
  { label: 'Provincia De Manú', value: 'Provincia De Manú' },
  { label: 'Provincia De Marañón', value: 'Provincia De Marañón' },
  { label: 'Provincia De Mariscal Cáceres', value: 'Provincia De Mariscal Cáceres' },
  { label: 'Provincia De Mariscal Luzuriaga', value: 'Provincia De Mariscal Luzuriaga' },
  { label: 'Provincia De Mariscal Nieto', value: 'Provincia De Mariscal Nieto' },
  { label: 'Provincia De Melgar', value: 'Provincia De Melgar' },
  { label: 'Provincia De Moyobamba', value: 'Provincia De Moyobamba' },
  { label: 'Provincia De Navarra', value: 'Provincia De Navarra' },
  { label: 'Provincia De Nazca', value: 'Provincia De Nazca' },
  { label: 'Provincia De Otuzco', value: 'Provincia De Otuzco' },
  { label: 'Provincia De Oxapampa', value: 'Provincia De Oxapampa' },
  { label: 'Provincia De Pacasmayo', value: 'Provincia De Pacasmayo' },
  { label: 'Provincia De Pachitea', value: 'Provincia De Pachitea' },
  { label: 'Provincia De Paita', value: 'Provincia De Paita' },
  { label: 'Provincia De Palencia', value: 'Provincia De Palencia' },
  { label: 'Provincia De Pallasca', value: 'Provincia De Pallasca' },
  { label: 'Provincia De Palpa', value: 'Provincia De Palpa' },
  { label: 'Provincia De Parinacochas', value: 'Provincia De Parinacochas' },
  { label: 'Provincia De Paruro', value: 'Provincia De Paruro' },
  { label: 'Provincia De Pasco', value: 'Provincia De Pasco' },
  { label: 'Provincia De Pataz', value: 'Provincia De Pataz' },
  { label: 'Provincia De Paucartambo', value: 'Provincia De Paucartambo' },
  { label: 'Provincia De Pisco', value: 'Provincia De Pisco' },
  { label: 'Provincia De Piura', value: 'Provincia De Piura' },
  { label: 'Provincia De Pomabamba', value: 'Provincia De Pomabamba' },
  { label: 'Provincia De Puno', value: 'Provincia De Puno' },
  { label: 'Provincia De Quispicanchis', value: 'Provincia De Quispicanchis' },
  { label: 'Provincia De Recuay', value: 'Provincia De Recuay' },
  { label: 'Provincia De Rioja', value: 'Provincia De Rioja' },
  { label: 'Provincia De Rodríguez De Mendoza', value: 'Provincia De Rodríguez De Mendoza' },
  { label: 'Provincia De Salamanca', value: 'Provincia De Salamanca' },
  { label: 'Provincia De San Ignacio', value: 'Provincia De San Ignacio' },
  { label: 'Provincia De San Martín', value: 'Provincia De San Martín' },
  { label: 'Provincia De San Miguel', value: 'Provincia De San Miguel' },
  { label: 'Provincia De Sandia', value: 'Provincia De Sandia' },
  { label: 'Provincia De Santa', value: 'Provincia De Santa' },
  { label: 'Provincia De Santa Cruz', value: 'Provincia De Santa Cruz' },
  { label: 'Provincia De Santa Cruz De Tenerife', value: 'Provincia De Santa Cruz De Tenerife' },
  { label: 'Provincia De Santiago De Chuco', value: 'Provincia De Santiago De Chuco' },
  { label: 'Provincia De Satipo', value: 'Provincia De Satipo' },
  { label: 'Provincia De Segovia', value: 'Provincia De Segovia' },
  { label: 'Provincia De Sihuas', value: 'Provincia De Sihuas' },
  { label: 'Provincia De Soria', value: 'Provincia De Soria' },
  { label: 'Provincia De Sucre', value: 'Provincia De Sucre' },
  { label: 'Provincia De Sullana', value: 'Provincia De Sullana' },
  { label: 'Provincia De Tacna', value: 'Provincia De Tacna' },
  { label: 'Provincia De Tahuamanú', value: 'Provincia De Tahuamanú' },
  { label: 'Provincia De Talara', value: 'Provincia De Talara' },
  { label: 'Provincia De Tambopata', value: 'Provincia De Tambopata' },
  { label: 'Provincia De Tarata', value: 'Provincia De Tarata' },
  { label: 'Provincia De Tarma', value: 'Provincia De Tarma' },
  { label: 'Provincia De Tayacaja', value: 'Provincia De Tayacaja' },
  { label: 'Provincia De Teruel', value: 'Provincia De Teruel' },
  { label: 'Provincia De Trujillo', value: 'Provincia De Trujillo' },
  { label: 'Provincia De Tumbes', value: 'Provincia De Tumbes' },
  { label: 'Provincia De Urubamba', value: 'Provincia De Urubamba' },
  { label: 'Província De València', value: 'Província De València' },
  { label: 'Provincia De Valladolid', value: 'Provincia De Valladolid' },
  { label: 'Provincia De Víctor Fajardo', value: 'Provincia De Víctor Fajardo' },
  { label: 'Provincia De Vilcas Huamán', value: 'Provincia De Vilcas Huamán' },
  { label: 'Provincia De Yauli', value: 'Provincia De Yauli' },
  { label: 'Provincia De Yauyos', value: 'Provincia De Yauyos' },
  { label: 'Provincia De Yungay', value: 'Provincia De Yungay' },
  { label: 'Provincia De Zamora', value: 'Provincia De Zamora' },
  { label: 'Provincia De Zaragoza', value: 'Provincia De Zaragoza' },
  { label: 'Provincia De Zarumilla', value: 'Provincia De Zarumilla' },
  { label: 'Provincia Dell Aquila', value: 'Provincia Dell Aquila' },
  { label: 'Provincia Di Alessandria', value: 'Provincia Di Alessandria' },
  { label: 'Provincia Di Ancona', value: 'Provincia Di Ancona' },
  { label: 'Provincia Di Ascoli Piceno', value: 'Provincia Di Ascoli Piceno' },
  { label: 'Provincia Di Asti', value: 'Provincia Di Asti' },
  { label: 'Provincia Di Avellino', value: 'Provincia Di Avellino' },
  { label: 'Provincia Di Barletta - Andria - Trani', value: 'Provincia Di Barletta - Andria - Trani' },
  { label: 'Provincia Di Belluno', value: 'Provincia Di Belluno' },
  { label: 'Provincia Di Benevento', value: 'Provincia Di Benevento' },
  { label: 'Provincia Di Bergamo', value: 'Provincia Di Bergamo' },
  { label: 'Provincia Di Biella', value: 'Provincia Di Biella' },
  { label: 'Provincia Di Brescia', value: 'Provincia Di Brescia' },
  { label: 'Provincia Di Brindisi', value: 'Provincia Di Brindisi' },
  { label: 'Provincia Di Cagliari', value: 'Provincia Di Cagliari' },
  { label: 'Provincia Di Caltanissetta', value: 'Provincia Di Caltanissetta' },
  { label: 'Provincia Di Campobasso', value: 'Provincia Di Campobasso' },
  { label: 'Provincia Di Caserta', value: 'Provincia Di Caserta' },
  { label: 'Provincia Di Catanzaro', value: 'Provincia Di Catanzaro' },
  { label: 'Provincia Di Chieti', value: 'Provincia Di Chieti' },
  { label: 'Provincia Di Como', value: 'Provincia Di Como' },
  { label: 'Provincia Di Cosenza', value: 'Provincia Di Cosenza' },
  { label: 'Provincia Di Cremona', value: 'Provincia Di Cremona' },
  { label: 'Provincia Di Crotone', value: 'Provincia Di Crotone' },
  { label: 'Provincia Di Cuneo', value: 'Provincia Di Cuneo' },
  { label: 'Provincia Di Ferrara', value: 'Provincia Di Ferrara' },
  { label: 'Provincia Di Foggia', value: 'Provincia Di Foggia' },
  { label: 'Provincia Di Forlì-Cesena', value: 'Provincia Di Forlì-Cesena' },
  { label: 'Provincia Di Frosinone', value: 'Provincia Di Frosinone' },
  { label: 'Provincia Di Genova', value: 'Provincia Di Genova' },
  { label: 'Provincia Di Gorizia', value: 'Provincia Di Gorizia' },
  { label: 'Provincia Di Grosseto', value: 'Provincia Di Grosseto' },
  { label: 'Provincia Di Imperia', value: 'Provincia Di Imperia' },
  { label: 'Provincia Di Isernia', value: 'Provincia Di Isernia' },
  { label: 'Provincia Di La Spezia', value: 'Provincia Di La Spezia' },
  { label: 'Provincia Di Latina', value: 'Provincia Di Latina' },
  { label: 'Provincia Di Lecce', value: 'Provincia Di Lecce' },
  { label: 'Provincia Di Lecco', value: 'Provincia Di Lecco' },
  { label: 'Provincia Di Livorno', value: 'Provincia Di Livorno' },
  { label: 'Provincia Di Lodi', value: 'Provincia Di Lodi' },
  { label: 'Provincia Di Lucca', value: 'Provincia Di Lucca' },
  { label: 'Provincia Di Macerata', value: 'Provincia Di Macerata' },
  { label: 'Provincia Di Mantova', value: 'Provincia Di Mantova' },
  { label: 'Provincia Di Massa-Carrara', value: 'Provincia Di Massa-Carrara' },
  { label: 'Provincia Di Matera', value: 'Provincia Di Matera' },
  { label: 'Provincia Di Modena', value: 'Provincia Di Modena' },
  { label: 'Provincia Di Monza E Della Brianza', value: 'Provincia Di Monza E Della Brianza' },
  { label: 'Provincia Di Novara', value: 'Provincia Di Novara' },
  { label: 'Provincia Di Nuoro', value: 'Provincia Di Nuoro' },
  { label: 'Provincia Di Oristano', value: 'Provincia Di Oristano' },
  { label: 'Provincia Di Padova', value: 'Provincia Di Padova' },
  { label: 'Provincia Di Parma', value: 'Provincia Di Parma' },
  { label: 'Provincia Di Pavia', value: 'Provincia Di Pavia' },
  { label: 'Provincia Di Perugia', value: 'Provincia Di Perugia' },
  { label: 'Provincia Di Pesaro E Urbino', value: 'Provincia Di Pesaro E Urbino' },
  { label: 'Provincia Di Pescara', value: 'Provincia Di Pescara' },
  { label: 'Provincia Di Piacenza', value: 'Provincia Di Piacenza' },
  { label: 'Provincia Di Pistoia', value: 'Provincia Di Pistoia' },
  { label: 'Provincia Di Pordenone', value: 'Provincia Di Pordenone' },
  { label: 'Provincia Di Potenza', value: 'Provincia Di Potenza' },
  { label: 'Provincia Di Prato', value: 'Provincia Di Prato' },
  { label: 'Provincia Di Ravenna', value: 'Provincia Di Ravenna' },
  { label: 'Provincia Di Reggio Calabria', value: 'Provincia Di Reggio Calabria' },
  { label: 'Provincia Di Reggio Emilia', value: 'Provincia Di Reggio Emilia' },
  { label: 'Provincia Di Rieti', value: 'Provincia Di Rieti' },
  { label: 'Provincia Di Rimini', value: 'Provincia Di Rimini' },
  { label: 'Provincia Di Rovigo', value: 'Provincia Di Rovigo' },
  { label: 'Provincia Di Salerno', value: 'Provincia Di Salerno' },
  { label: 'Provincia Di Sassari', value: 'Provincia Di Sassari' },
  { label: 'Provincia Di Savona', value: 'Provincia Di Savona' },
  { label: 'Provincia Di Siena', value: 'Provincia Di Siena' },
  { label: 'Provincia Di Siracusa', value: 'Provincia Di Siracusa' },
  { label: 'Provincia Di Sondrio', value: 'Provincia Di Sondrio' },
  { label: 'Provincia Di Taranto', value: 'Provincia Di Taranto' },
  { label: 'Provincia Di Teramo', value: 'Provincia Di Teramo' },
  { label: 'Provincia Di Terni', value: 'Provincia Di Terni' },
  { label: 'Provincia Di Treviso', value: 'Provincia Di Treviso' },
  { label: 'Provincia Di Trieste', value: 'Provincia Di Trieste' },
  { label: 'Provincia Di Udine', value: 'Provincia Di Udine' },
  { label: 'Provincia Di Varese', value: 'Provincia Di Varese' },
  { label: 'Provincia Di Venezia', value: 'Provincia Di Venezia' },
  { label: 'Provincia Di Vercelli', value: 'Provincia Di Vercelli' },
  { label: 'Provincia Di Verona', value: 'Provincia Di Verona' },
  { label: 'Provincia Di Vibo-Valentia', value: 'Provincia Di Vibo-Valentia' },
  { label: 'Provincia Di Vicenza', value: 'Provincia Di Vicenza' },
  { label: 'Provincia Di Viterbo', value: 'Provincia Di Viterbo' },
  { label: 'Provincia Esteban Arce', value: 'Provincia Esteban Arce' },
  { label: 'Provincia Florida', value: 'Provincia Florida' },
  { label: 'Provincia Franz Tamayo', value: 'Provincia Franz Tamayo' },
  { label: 'Provincia General Bilbao', value: 'Provincia General Bilbao' },
  { label: 'Provincia General Federico Román', value: 'Provincia General Federico Román' },
  { label: 'Provincia General José Ballivián', value: 'Provincia General José Ballivián' },
  { label: 'Provincia Germán Jordán', value: 'Provincia Germán Jordán' },
  { label: 'Provincia Gran Chaco', value: 'Provincia Gran Chaco' },
  { label: 'Provincia Gualberto Villarroel', value: 'Provincia Gualberto Villarroel' },
  { label: 'Provincia Hernando Siles', value: 'Provincia Hernando Siles' },
  { label: 'Provincia Ichilo', value: 'Provincia Ichilo' },
  { label: 'Provincia Ingavi', value: 'Provincia Ingavi' },
  { label: 'Provincia Inquisivi', value: 'Provincia Inquisivi' },
  { label: 'Provincia Iténez', value: 'Provincia Iténez' },
  { label: 'Provincia Iturralde', value: 'Provincia Iturralde' },
  { label: 'Provincia Ladislao Cabrera', value: 'Provincia Ladislao Cabrera' },
  { label: 'Provincia Larecaja', value: 'Provincia Larecaja' },
  { label: 'Provincia Linares', value: 'Provincia Linares' },
  { label: 'Provincia Loayza', value: 'Provincia Loayza' },
  { label: 'Provincia Los Andes', value: 'Provincia Los Andes' },
  { label: 'Provincia Luis Calvo', value: 'Provincia Luis Calvo' },
  { label: 'Provincia Madre De Dios', value: 'Provincia Madre De Dios' },
  { label: 'Provincia Mamoré', value: 'Provincia Mamoré' },
  { label: 'Provincia Manco Kapac', value: 'Provincia Manco Kapac' },
  { label: 'Provincia Manuel María Caballero', value: 'Provincia Manuel María Caballero' },
  { label: 'Provincia Manuripi', value: 'Provincia Manuripi' },
  { label: 'Provincia Marbán', value: 'Provincia Marbán' },
  { label: 'Provincia Méndez', value: 'Provincia Méndez' },
  { label: 'Provincia Mizque', value: 'Provincia Mizque' },
  { label: 'Provincia Modesto Omiste', value: 'Provincia Modesto Omiste' },
  { label: 'Provincia Moxos', value: 'Provincia Moxos' },
  { label: 'Provincia Muñecas', value: 'Provincia Muñecas' },
  { label: 'Provincia Murillo', value: 'Provincia Murillo' },
  { label: 'Provincia Nicolás Suárez', value: 'Provincia Nicolás Suárez' },
  { label: 'Provincia Nor Chichas', value: 'Provincia Nor Chichas' },
  { label: 'Provincia Nor Cinti', value: 'Provincia Nor Cinti' },
  { label: 'Provincia Nor Lípez', value: 'Provincia Nor Lípez' },
  { label: 'Provincia Nor Yungas', value: 'Provincia Nor Yungas' },
  { label: 'Provincia Ñuflo De Chávez', value: 'Provincia Ñuflo De Chávez' },
  { label: 'Provincia O’Connor', value: 'Provincia O’Connor' },
  { label: 'Provincia Omasuyos', value: 'Provincia Omasuyos' },
  { label: 'Provincia Oropeza', value: 'Provincia Oropeza' },
  { label: 'Provincia Pacajes', value: 'Provincia Pacajes' },
  { label: 'Provincia Pantaleón Dalence', value: 'Provincia Pantaleón Dalence' },
  { label: 'Provincia Poopó', value: 'Provincia Poopó' },
  { label: 'Provincia Punata', value: 'Provincia Punata' },
  { label: 'Provincia Quijarro', value: 'Provincia Quijarro' },
  { label: 'Provincia Quillacollo', value: 'Provincia Quillacollo' },
  { label: 'Provincia Rafael Bustillo', value: 'Provincia Rafael Bustillo' },
  { label: 'Provincia Saavedra', value: 'Provincia Saavedra' },
  { label: 'Provincia Sabaya', value: 'Provincia Sabaya' },
  { label: 'Provincia Sajama', value: 'Provincia Sajama' },
  { label: 'Provincia San Pedro De Totora', value: 'Provincia San Pedro De Totora' },
  { label: 'Provincia Santiesteban', value: 'Provincia Santiesteban' },
  { label: 'Provincia Sara', value: 'Provincia Sara' },
  { label: 'Provincia Saucari', value: 'Provincia Saucari' },
  { label: 'Provincia Sud Chichas', value: 'Provincia Sud Chichas' },
  { label: 'Provincia Sud Cinti', value: 'Provincia Sud Cinti' },
  { label: 'Provincia Sud Lípez', value: 'Provincia Sud Lípez' },
  { label: 'Provincia Sud Yungas', value: 'Provincia Sud Yungas' },
  { label: 'Provincia Tapacarí', value: 'Provincia Tapacarí' },
  { label: 'Provincia Tomás Barron', value: 'Provincia Tomás Barron' },
  { label: 'Provincia Tomás Frías', value: 'Provincia Tomás Frías' },
  { label: 'Provincia Tomina', value: 'Provincia Tomina' },
  { label: 'Provincia Vaca Diez', value: 'Provincia Vaca Diez' },
  { label: 'Provincia Vallegrande', value: 'Provincia Vallegrande' },
  { label: 'Provincia Velasco', value: 'Provincia Velasco' },
  { label: 'Provincia Verbano-Cusio-Ossola', value: 'Provincia Verbano-Cusio-Ossola' },
  { label: 'Provincia Warnes', value: 'Provincia Warnes' },
  { label: 'Provincia Yacuma', value: 'Provincia Yacuma' },
  { label: 'Provincia Yamparáez', value: 'Provincia Yamparáez' },
  { label: 'Provincia Zudáñez', value: 'Provincia Zudáñez' },
  { label: 'Provincie Antwerpen', value: 'Provincie Antwerpen' },
  { label: 'Provincie Limburg', value: 'Provincie Limburg' },
  { label: 'Provincie Oost-Vlaanderen', value: 'Provincie Oost-Vlaanderen' },
  { label: 'Provincie Vlaams-Brabant', value: 'Provincie Vlaams-Brabant' },
  { label: 'Provincie West-Vlaanderen', value: 'Provincie West-Vlaanderen' },
  { label: 'Provins', value: 'Provins' },
  { label: 'Proviţa De Jos', value: 'Proviţa De Jos' },
  { label: 'Proviţa De Sus', value: 'Proviţa De Sus' },
  { label: 'Provo', value: 'Provo' },
  { label: 'Provodov-Šonov', value: 'Provodov-Šonov' },
  { label: 'Provost', value: 'Provost' },
  { label: 'Provvidenti', value: 'Provvidenti' },
  { label: 'Prowers County', value: 'Prowers County' },
  { label: 'Prozor', value: 'Prozor' },
  { label: 'Prroshyan', value: 'Prroshyan' },
  { label: 'Pršovce', value: 'Pršovce' },
  { label: 'Pru East', value: 'Pru East' },
  { label: 'Pru West', value: 'Pru West' },
  { label: 'Pruchna', value: 'Pruchna' },
  { label: 'Pruchnik', value: 'Pruchnik' },
  { label: 'Prudente De Morais', value: 'Prudente De Morais' },
  { label: 'Prudentópolis', value: 'Prudentópolis' },
  { label: 'Prudenville', value: 'Prudenville' },
  { label: 'Prudhoe', value: 'Prudhoe' },
  { label: 'Prudhoe Bay', value: 'Prudhoe Bay' },
  { label: 'Prudnik', value: 'Prudnik' },
  { label: 'Prudovoye', value: 'Prudovoye' },
  { label: 'Prudy', value: 'Prudy' },
  { label: 'Prudy', value: 'Prudy' },
  { label: 'Prudyanka', value: 'Prudyanka' },
  { label: 'Pruggern', value: 'Pruggern' },
  { label: 'Prügy', value: 'Prügy' },
  { label: 'Průhonice', value: 'Průhonice' },
  { label: 'Pruillé-Le-Chétif', value: 'Pruillé-Le-Chétif' },
  { label: 'Prullans', value: 'Prullans' },
  { label: 'Prüm', value: 'Prüm' },
  { label: 'Pruna', value: 'Pruna' },
  { label: 'Prundeni', value: 'Prundeni' },
  { label: 'Prundu', value: 'Prundu' },
  { label: 'Prundu', value: 'Prundu' },
  { label: 'Prundu Bârgăului', value: 'Prundu Bârgăului' },
  { label: 'Prunedale', value: 'Prunedale' },
  { label: 'Prunelli-Di-Fiumorbo', value: 'Prunelli-Di-Fiumorbo' },
  { label: 'Prunetto', value: 'Prunetto' },
  { label: 'Pruniers-En-Sologne', value: 'Pruniers-En-Sologne' },
  { label: 'Prunişor', value: 'Prunişor' },
  { label: 'Prušánky', value: 'Prušánky' },
  { label: 'Prusice', value: 'Prusice' },
  { label: 'Prusinovice', value: 'Prusinovice' },
  { label: 'Pruszcz', value: 'Pruszcz' },
  { label: 'Pruszcz Gdański', value: 'Pruszcz Gdański' },
  { label: 'Pruszków', value: 'Pruszków' },
  { label: 'Prutting', value: 'Prutting' },
  { label: 'Prutz', value: 'Prutz' },
  { label: 'Pruzhanski Rayon', value: 'Pruzhanski Rayon' },
  { label: 'Pruzhany', value: 'Pruzhany' },
  { label: 'Prvačina', value: 'Prvačina' },
  { label: 'Pryamitsyno', value: 'Pryamitsyno' },
  { label: 'Pryazha', value: 'Pryazha' },
  { label: 'Pryazovske Raion', value: 'Pryazovske Raion' },
  { label: 'Prykolotne', value: 'Prykolotne' },
  { label: 'Pryluky', value: 'Pryluky' },
  { label: 'Prymors’K', value: 'Prymors’K' },
  { label: 'Prymorsk Raion', value: 'Prymorsk Raion' },
  { label: 'Pryor', value: 'Pryor' },
  { label: 'Pryor Creek', value: 'Pryor Creek' },
  { label: 'Pryshyb', value: 'Pryshyb' },
  { label: 'Pryvillya', value: 'Pryvillya' },
  { label: 'Pryvol’Ny', value: 'Pryvol’Ny' },
  { label: 'Pryyutivka', value: 'Pryyutivka' },
  { label: 'Przasnysz', value: 'Przasnysz' },
  { label: 'Przechlewo', value: 'Przechlewo' },
  { label: 'Przecieszyn', value: 'Przecieszyn' },
  { label: 'Przeciszów', value: 'Przeciszów' },
  { label: 'Przecław', value: 'Przecław' },
  { label: 'Przecław', value: 'Przecław' },
  { label: 'Przedbórz', value: 'Przedbórz' },
  { label: 'Przedecz', value: 'Przedecz' },
  { label: 'Przedmieście Dubieckie', value: 'Przedmieście Dubieckie' },
  { label: 'Przędzel', value: 'Przędzel' },
  { label: 'Przeginia', value: 'Przeginia' },
  { label: 'Przelewice', value: 'Przelewice' },
  { label: 'Przemęt', value: 'Przemęt' },
  { label: 'Przemków', value: 'Przemków' },
  { label: 'Przemyśl', value: 'Przemyśl' },
  { label: 'Przesmyki', value: 'Przesmyki' },
  { label: 'Przeworno', value: 'Przeworno' },
  { label: 'Przeworsk', value: 'Przeworsk' },
  { label: 'Przewóz', value: 'Przewóz' },
  { label: 'Przodkowo', value: 'Przodkowo' },
  { label: 'Przybiernów', value: 'Przybiernów' },
  { label: 'Przyborów', value: 'Przyborów' },
  { label: 'Przyborów', value: 'Przyborów' },
  { label: 'Przyborów', value: 'Przyborów' },
  { label: 'Przybyszówka', value: 'Przybyszówka' },
  { label: 'Przygodzice', value: 'Przygodzice' },
  { label: 'Przykona', value: 'Przykona' },
  { label: 'Przyłęk', value: 'Przyłęk' },
  { label: 'Przyrów', value: 'Przyrów' },
  { label: 'Przystajń', value: 'Przystajń' },
  { label: 'Przysucha', value: 'Przysucha' },
  { label: 'Przyszowice', value: 'Przyszowice' },
  { label: 'Przytkowice', value: 'Przytkowice' },
  { label: 'Przytoczna', value: 'Przytoczna' },
  { label: 'Przytoczno', value: 'Przytoczno' },
  { label: 'Przytyk', value: 'Przytyk' },
  { label: 'Przywidz', value: 'Przywidz' },
  { label: 'Psachná', value: 'Psachná' },
  { label: 'Psary', value: 'Psary' },
  { label: 'Psary', value: 'Psary' },
  { label: 'Psary', value: 'Psary' },
  { label: 'Psáry', value: 'Psáry' },
  { label: 'Psebay', value: 'Psebay' },
  { label: 'Psedakh', value: 'Psedakh' },
  { label: 'Psevdás', value: 'Psevdás' },
  { label: 'Pshada', value: 'Pshada' },
  { label: 'Pshekhskaya', value: 'Pshekhskaya' },
  { label: 'Pshenichnoye', value: 'Pshenichnoye' },
  { label: 'Psie Pole Południe-Kiełczów', value: 'Psie Pole Południe-Kiełczów' },
  { label: 'Psimolofou', value: 'Psimolofou' },
  { label: 'Pskov', value: 'Pskov' },
  { label: 'Pskovskiy Rayon', value: 'Pskovskiy Rayon' },
  { label: 'Psychikó', value: 'Psychikó' },
  { label: 'Psygansu', value: 'Psygansu' },
  { label: 'Psyzh', value: 'Psyzh' },
  { label: 'Pszczew', value: 'Pszczew' },
  { label: 'Pszczółki', value: 'Pszczółki' },
  { label: 'Pszczyna', value: 'Pszczyna' },
  { label: 'Pszów', value: 'Pszów' },
  { label: 'Pteleós', value: 'Pteleós' },
  { label: 'Ptení', value: 'Ptení' },
  { label: 'Ptghni', value: 'Ptghni' },
  { label: 'Ptghunk’', value: 'Ptghunk’' },
  { label: 'Ptolemaḯda', value: 'Ptolemaḯda' },
  { label: 'Ptuj', value: 'Ptuj' },
  { label: 'Puácuaro', value: 'Puácuaro' },
  { label: 'Pualas', value: 'Pualas' },
  { label: 'Pualas', value: 'Pualas' },
  { label: 'Pualas', value: 'Pualas' },
  { label: 'Puan', value: 'Puan' },
  { label: 'Pubal', value: 'Pubal' },
  { label: 'Publier', value: 'Publier' },
  { label: 'Publow', value: 'Publow' },
  { label: 'Pucallpa', value: 'Pucallpa' },
  { label: 'Pucara', value: 'Pucara' },
  { label: 'Puch Bei Hallein', value: 'Puch Bei Hallein' },
  { label: 'Puch Bei Weiz', value: 'Puch Bei Weiz' },
  { label: 'Puchaczów', value: 'Puchaczów' },
  { label: 'Puchberg Am Schneeberg', value: 'Puchberg Am Schneeberg' },
  { label: 'Puchenau', value: 'Puchenau' },
  { label: 'Pucheng', value: 'Pucheng' },
  { label: 'Pucheni', value: 'Pucheni' },
  { label: 'Puchenii Mari', value: 'Puchenii Mari' },
  { label: 'Puchenii Moșneni', value: 'Puchenii Moșneni' },
  { label: 'Puchenstuben', value: 'Puchenstuben' },
  { label: 'Püchersreuth', value: 'Püchersreuth' },
  { label: 'Puchezh', value: 'Puchezh' },
  { label: 'Puchezhskiy Rayon', value: 'Puchezhskiy Rayon' },
  { label: 'Puchheim', value: 'Puchheim' },
  { label: 'Puchheim', value: 'Puchheim' },
  { label: 'Puchlkirchen Am Trattberg', value: 'Puchlkirchen Am Trattberg' },
  { label: 'Puchong', value: 'Puchong' },
  { label: 'Púchov', value: 'Púchov' },
  { label: 'Puchuncaví', value: 'Puchuncaví' },
  { label: 'Pucioasa', value: 'Pucioasa' },
  { label: 'Pučišća', value: 'Pučišća' },
  { label: 'Puck', value: 'Puck' },
  { label: 'Puckapunyal', value: 'Puckapunyal' },
  { label: 'Puckeridge', value: 'Puckeridge' },
  { label: 'Pucking', value: 'Pucking' },
  { label: 'Pucklechurch', value: 'Pucklechurch' },
  { label: 'Puçol', value: 'Puçol' },
  { label: 'Pucón', value: 'Pucón' },
  { label: 'Puconci', value: 'Puconci' },
  { label: 'Pucté', value: 'Pucté' },
  { label: 'Púcuro', value: 'Púcuro' },
  { label: 'Pudahuel', value: 'Pudahuel' },
  { label: 'Pudasjärvi', value: 'Pudasjärvi' },
  { label: 'Puddletown', value: 'Puddletown' },
  { label: 'Pudem', value: 'Pudem' },
  { label: 'Puderbach', value: 'Puderbach' },
  { label: 'Pudoc', value: 'Pudoc' },
  { label: 'Pudoc', value: 'Pudoc' },
  { label: 'Pudoc North', value: 'Pudoc North' },
  { label: 'Pudoc North', value: 'Pudoc North' },
  { label: 'Pudozh', value: 'Pudozh' },
  { label: 'Pudsey', value: 'Pudsey' },
  { label: 'Pudtol', value: 'Pudtol' },
  { label: 'Puducherry', value: 'Puducherry' },
  { label: 'Pudukkottai', value: 'Pudukkottai' },
  { label: 'Puduppatti', value: 'Puduppatti' },
  { label: 'Pudur', value: 'Pudur' },
  { label: 'Puduvayal', value: 'Puduvayal' },
  { label: 'Pueai Noi', value: 'Pueai Noi' },
  { label: 'Puebla', value: 'Puebla' },
  { label: 'Puebla', value: 'Puebla' },
  { label: 'Puebla', value: 'Puebla' },
  { label: 'Puebla De Albortón', value: 'Puebla De Albortón' },
  { label: 'Puebla De Alcocer', value: 'Puebla De Alcocer' },
  { label: 'Puebla De Alfindén', value: 'Puebla De Alfindén' },
  { label: 'Puebla De Alfindén La', value: 'Puebla De Alfindén La' },
  { label: 'Puebla De Almenara', value: 'Puebla De Almenara' },
  { label: 'Puebla De Almoradiel La', value: 'Puebla De Almoradiel La' },
  { label: 'Puebla De Arenoso', value: 'Puebla De Arenoso' },
  { label: 'Puebla De Arganzón La', value: 'Puebla De Arganzón La' },
  { label: 'Puebla De Azaba', value: 'Puebla De Azaba' },
  { label: 'Puebla De Beleña', value: 'Puebla De Beleña' },
  { label: 'Puebla De Castro La', value: 'Puebla De Castro La' },
  { label: 'Puebla De Cazalla La', value: 'Puebla De Cazalla La' },
  { label: 'Puebla De Don Fadrique', value: 'Puebla De Don Fadrique' },
  { label: 'Puebla De Don Rodrigo', value: 'Puebla De Don Rodrigo' },
  { label: 'Puebla De Guzmán', value: 'Puebla De Guzmán' },
  { label: 'Puebla De Híjar La', value: 'Puebla De Híjar La' },
  { label: 'Puebla De La Calzada', value: 'Puebla De La Calzada' },
  { label: 'Puebla De La Reina', value: 'Puebla De La Reina' },
  { label: 'Puebla De La Sierra', value: 'Puebla De La Sierra' },
  { label: 'Puebla De Lillo', value: 'Puebla De Lillo' },
  { label: 'Puebla De Los Infantes La', value: 'Puebla De Los Infantes La' },
  { label: 'Puebla De Montalbán La', value: 'Puebla De Montalbán La' },
  { label: 'Puebla De Obando', value: 'Puebla De Obando' },
  { label: 'Puebla De Pedraza', value: 'Puebla De Pedraza' },
  { label: 'Puebla De San Medel', value: 'Puebla De San Medel' },
  { label: 'Puebla De San Miguel', value: 'Puebla De San Miguel' },
  { label: 'Puebla De Sanabria', value: 'Puebla De Sanabria' },
  { label: 'Puebla De Sancho Pérez', value: 'Puebla De Sancho Pérez' },
  { label: 'Puebla De Valdavia La', value: 'Puebla De Valdavia La' },
  { label: 'Puebla De Valles', value: 'Puebla De Valles' },
  { label: 'Puebla De Valverde La', value: 'Puebla De Valverde La' },
  { label: 'Puebla De Yeltes', value: 'Puebla De Yeltes' },
  { label: 'Puebla Del Maestre', value: 'Puebla Del Maestre' },
  { label: 'Puebla Del Príncipe', value: 'Puebla Del Príncipe' },
  { label: 'Puebla Del Prior', value: 'Puebla Del Prior' },
  { label: 'Puebla Del Río La', value: 'Puebla Del Río La' },
  { label: 'Puebla Del Salvador', value: 'Puebla Del Salvador' },
  { label: 'Pueblanueva La', value: 'Pueblanueva La' },
  { label: 'Pueblica De Valverde', value: 'Pueblica De Valverde' },
  { label: 'Pueblillo', value: 'Pueblillo' },
  { label: 'Pueblito De Allende', value: 'Pueblito De Allende' },
  { label: 'Pueblito Del Rio', value: 'Pueblito Del Rio' },
  { label: 'Pueblo', value: 'Pueblo' },
  { label: 'Pueblo Bello', value: 'Pueblo Bello' },
  { label: 'Pueblo Benito García', value: 'Pueblo Benito García' },
  { label: 'Pueblo County', value: 'Pueblo County' },
  { label: 'Pueblo Hidalgo', value: 'Pueblo Hidalgo' },
  { label: 'Pueblo Hidalgo', value: 'Pueblo Hidalgo' },
  { label: 'Pueblo Juárez (La Magdalena)', value: 'Pueblo Juárez (La Magdalena)' },
  { label: 'Pueblo Libertador', value: 'Pueblo Libertador' },
  { label: 'Pueblo Madero (El Playón)', value: 'Pueblo Madero (El Playón)' },
  { label: 'Pueblo Mayo', value: 'Pueblo Mayo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo', value: 'Pueblo Nuevo' },
  { label: 'Pueblo Nuevo De Las Raíces', value: 'Pueblo Nuevo De Las Raíces' },
  { label: 'Pueblo Nuevo De Morelos', value: 'Pueblo Nuevo De Morelos' },
  { label: 'Pueblo Nuevo Tlalmimilolpan', value: 'Pueblo Nuevo Tlalmimilolpan' },
  { label: 'Pueblo Nuevo Viñas', value: 'Pueblo Nuevo Viñas' },
  { label: 'Pueblo Rico', value: 'Pueblo Rico' },
  { label: 'Pueblo Viejo', value: 'Pueblo Viejo' },
  { label: 'Pueblo Viejo', value: 'Pueblo Viejo' },
  { label: 'Pueblo Viejo', value: 'Pueblo Viejo' },
  { label: 'Pueblo Viejo', value: 'Pueblo Viejo' },
  { label: 'Pueblo Viejo', value: 'Pueblo Viejo' },
  { label: 'Pueblo Viejo', value: 'Pueblo Viejo' },
  { label: 'Pueblo Viejo', value: 'Pueblo Viejo' },
  { label: 'Pueblo Viejo', value: 'Pueblo Viejo' },
  { label: 'Pueblo West', value: 'Pueblo West' },
  { label: 'Pueblo Yaqui', value: 'Pueblo Yaqui' },
  { label: 'Pueblonuevo Del Guadiana', value: 'Pueblonuevo Del Guadiana' },
  { label: 'Pueblorrico', value: 'Pueblorrico' },
  { label: 'Pueblos Unidos', value: 'Pueblos Unidos' },
  { label: 'Puebloviejo', value: 'Puebloviejo' },
  { label: 'Puegnago Sul Garda', value: 'Puegnago Sul Garda' },
  { label: 'Puelay', value: 'Puelay' },
  { label: 'Puelay', value: 'Puelay' },
  { label: 'Puendeluna', value: 'Puendeluna' },
  { label: 'Puente Alto', value: 'Puente Alto' },
  { label: 'Puente De Camotlán', value: 'Puente De Camotlán' },
  { label: 'Puente De Domingo Flórez', value: 'Puente De Domingo Flórez' },
  { label: 'Puente De Génave', value: 'Puente De Génave' },
  { label: 'Puente De Ixtla', value: 'Puente De Ixtla' },
  { label: 'Puente De Montañana', value: 'Puente De Montañana' },
  { label: 'Puente De Piedra', value: 'Puente De Piedra' },
  { label: 'Puente De Vallecas', value: 'Puente De Vallecas' },
  { label: 'Puente Del Arzobispo El', value: 'Puente Del Arzobispo El' },
  { label: 'Puente Del Congosto', value: 'Puente Del Congosto' },
  { label: 'Puente Genil', value: 'Puente Genil' },
  { label: 'Puente Grande', value: 'Puente Grande' },
  { label: 'Puente Jula', value: 'Puente Jula' },
  { label: 'Puente La Reina', value: 'Puente La Reina' },
  { label: 'Puente La Reina De Jaca', value: 'Puente La Reina De Jaca' },
  { label: 'Puente La Reina/Gares', value: 'Puente La Reina/Gares' },
  { label: 'Puente Nacional', value: 'Puente Nacional' },
  { label: 'Puente Viesgo', value: 'Puente Viesgo' },
  { label: 'Puentecillas', value: 'Puentecillas' },
  { label: 'Puentecillas', value: 'Puentecillas' },
  { label: 'Puentecillas (Tercera Manzana De Zirahuato)', value: 'Puentecillas (Tercera Manzana De Zirahuato)' },
  { label: 'Puentedura', value: 'Puentedura' },
  { label: 'Puentes Viejas', value: 'Puentes Viejas' },
  { label: 'Puerres', value: 'Puerres' },
  { label: 'Puerta De Corral Quemado', value: 'Puerta De Corral Quemado' },
  { label: 'Puerta De Mangos', value: 'Puerta De Mangos' },
  { label: 'Puerta De Palmillas', value: 'Puerta De Palmillas' },
  { label: 'Puerta De Segura La', value: 'Puerta De Segura La' },
  { label: 'Puerta Del Llano', value: 'Puerta Del Llano' },
  { label: 'Puerta Del Monte', value: 'Puerta Del Monte' },
  { label: 'Puertas', value: 'Puertas' },
  { label: 'Puertecito De La Virgen', value: 'Puertecito De La Virgen' },
  { label: 'Puerto Adolfo Lopez Mateos', value: 'Puerto Adolfo Lopez Mateos' },
  { label: 'Puerto Alegría', value: 'Puerto Alegría' },
  { label: 'Puerto Alto', value: 'Puerto Alto' },
  { label: 'Puerto Ángel', value: 'Puerto Ángel' },
  { label: 'Puerto Arica', value: 'Puerto Arica' },
  { label: 'Puerto Armuelles', value: 'Puerto Armuelles' },
  { label: 'Puerto Asís', value: 'Puerto Asís' },
  { label: 'Puerto Aventuras', value: 'Puerto Aventuras' },
  { label: 'Puerto Ayacucho', value: 'Puerto Ayacucho' },
  { label: 'Puerto Ayora', value: 'Puerto Ayora' },
  { label: 'Puerto Baquerizo Moreno', value: 'Puerto Baquerizo Moreno' },
  { label: 'Puerto Barrios', value: 'Puerto Barrios' },
  { label: 'Puerto Bello', value: 'Puerto Bello' },
  { label: 'Puerto Bello', value: 'Puerto Bello' },
  { label: 'Puerto Bermejo', value: 'Puerto Bermejo' },
  { label: 'Puerto Berrío', value: 'Puerto Berrío' },
  { label: 'Puerto Bolívar', value: 'Puerto Bolívar' },
  { label: 'Puerto Boyacá', value: 'Puerto Boyacá' },
  { label: 'Puerto Cabello', value: 'Puerto Cabello' },
  { label: 'Puerto Cabezas', value: 'Puerto Cabezas' },
  { label: 'Puerto Caicedo', value: 'Puerto Caicedo' },
  { label: 'Puerto Caimito', value: 'Puerto Caimito' },
  { label: 'Puerto Carreño', value: 'Puerto Carreño' },
  { label: 'Puerto Castilla', value: 'Puerto Castilla' },
  { label: 'Puerto Castilla', value: 'Puerto Castilla' },
  { label: 'Puerto Ceiba', value: 'Puerto Ceiba' },
  { label: 'Puerto Colombia', value: 'Puerto Colombia' },
  { label: 'Puerto Colombia', value: 'Puerto Colombia' },
  { label: 'Puerto Concordia', value: 'Puerto Concordia' },
  { label: 'Puerto Cortés', value: 'Puerto Cortés' },
  { label: 'Puerto Cortez', value: 'Puerto Cortez' },
  { label: 'Puerto De Aguirre', value: 'Puerto De Aguirre' },
  { label: 'Puerto De Béjar', value: 'Puerto De Béjar' },
  { label: 'Puerto De Buenavista (Lázaro Cárdenas)', value: 'Puerto De Buenavista (Lázaro Cárdenas)' },
  { label: 'Puerto De Carroza', value: 'Puerto De Carroza' },
  { label: 'Puerto De Chiquihuite', value: 'Puerto De Chiquihuite' },
  { label: 'Puerto De La Cruz', value: 'Puerto De La Cruz' },
  { label: 'Puerto De Mejillones', value: 'Puerto De Mejillones' },
  { label: 'Puerto De Nieto', value: 'Puerto De Nieto' },
  { label: 'Puerto De Providencia', value: 'Puerto De Providencia' },
  { label: 'Puerto De San Vicente', value: 'Puerto De San Vicente' },
  { label: 'Puerto De Santa Cruz', value: 'Puerto De Santa Cruz' },
  { label: 'Puerto De Santa María El', value: 'Puerto De Santa María El' },
  { label: 'Puerto Del Carmen', value: 'Puerto Del Carmen' },
  { label: 'Puerto Del Rosario', value: 'Puerto Del Rosario' },
  { label: 'Puerto Del Rosario', value: 'Puerto Del Rosario' },
  { label: 'Puerto Deseado', value: 'Puerto Deseado' },
  { label: 'Puerto El Triunfo', value: 'Puerto El Triunfo' },
  { label: 'Puerto Eldorado', value: 'Puerto Eldorado' },
  { label: 'Puerto Escondido', value: 'Puerto Escondido' },
  { label: 'Puerto Escondido', value: 'Puerto Escondido' },
  { label: 'Puerto Escondido', value: 'Puerto Escondido' },
  { label: 'Puerto Escondido (Tepeolulco Puerto Escondido)', value: 'Puerto Escondido (Tepeolulco Puerto Escondido)' },
  { label: 'Puerto Esmeralda', value: 'Puerto Esmeralda' },
  { label: 'Puerto Esperanza', value: 'Puerto Esperanza' },
  { label: 'Puerto Esperanza', value: 'Puerto Esperanza' },
  { label: 'Puerto Francisco De Orellana', value: 'Puerto Francisco De Orellana' },
  { label: 'Puerto Gaitán', value: 'Puerto Gaitán' },
  { label: 'Puerto Galera', value: 'Puerto Galera' },
  { label: 'Puerto Genovevo Santiago', value: 'Puerto Genovevo Santiago' },
  { label: 'Puerto Guzmán', value: 'Puerto Guzmán' },
  { label: 'Puerto Ibicuy', value: 'Puerto Ibicuy' },
  { label: 'Puerto Iguazú', value: 'Puerto Iguazú' },
  { label: 'Puerto Inca', value: 'Puerto Inca' },
  { label: 'Puerto La Cruz', value: 'Puerto La Cruz' },
  { label: 'Puerto Lápice', value: 'Puerto Lápice' },
  { label: 'Puerto Leguízamo', value: 'Puerto Leguízamo' },
  { label: 'Puerto Lempira', value: 'Puerto Lempira' },
  { label: 'Puerto Leoni', value: 'Puerto Leoni' },
  { label: 'Puerto Libertad', value: 'Puerto Libertad' },
  { label: 'Puerto Libertador', value: 'Puerto Libertador' },
  { label: 'Puerto Lleras', value: 'Puerto Lleras' },
  { label: 'Puerto López', value: 'Puerto López' },
  { label: 'Puerto Lumbreras', value: 'Puerto Lumbreras' },
  { label: 'Puerto Madero', value: 'Puerto Madero' },
  { label: 'Puerto Madryn', value: 'Puerto Madryn' },
  { label: 'Puerto Maldonado', value: 'Puerto Maldonado' },
  { label: 'Puerto Mayor Otaño', value: 'Puerto Mayor Otaño' },
  { label: 'Puerto Montt', value: 'Puerto Montt' },
  { label: 'Puerto Moral', value: 'Puerto Moral' },
  { label: 'Puerto Morazán', value: 'Puerto Morazán' },
  { label: 'Puerto Nare', value: 'Puerto Nare' },
  { label: 'Puerto Nariño', value: 'Puerto Nariño' },
  { label: 'Puerto Obaldía', value: 'Puerto Obaldía' },
  { label: 'Puerto Octay', value: 'Puerto Octay' },
  { label: 'Puerto Padre', value: 'Puerto Padre' },
  { label: 'Puerto Palomas', value: 'Puerto Palomas' },
  { label: 'Puerto Parra', value: 'Puerto Parra' },
  { label: 'Puerto Peñasco', value: 'Puerto Peñasco' },
  { label: 'Puerto Pilón', value: 'Puerto Pilón' },
  { label: 'Puerto Piray', value: 'Puerto Piray' },
  { label: 'Puerto Píritu', value: 'Puerto Píritu' },
  { label: 'Puerto Plata', value: 'Puerto Plata' },
  { label: 'Puerto Princesa', value: 'Puerto Princesa' },
  { label: 'Puerto Quijarro', value: 'Puerto Quijarro' },
  { label: 'Puerto Real', value: 'Puerto Real' },
  { label: 'Puerto Real', value: 'Puerto Real' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Puerto Rondón', value: 'Puerto Rondón' },
  { label: 'Puerto Rosario', value: 'Puerto Rosario' },
  { label: 'Puerto Salgar', value: 'Puerto Salgar' },
  { label: 'Puerto San Carlos', value: 'Puerto San Carlos' },
  { label: 'Puerto San José', value: 'Puerto San José' },
  { label: 'Puerto Santa', value: 'Puerto Santa' },
  { label: 'Puerto Santa Cruz', value: 'Puerto Santa Cruz' },
  { label: 'Puerto Santander', value: 'Puerto Santander' },
  { label: 'Puerto Santander', value: 'Puerto Santander' },
  { label: 'Puerto Seguro', value: 'Puerto Seguro' },
  { label: 'Puerto Serrano', value: 'Puerto Serrano' },
  { label: 'Puerto Supe', value: 'Puerto Supe' },
  { label: 'Puerto Tejada', value: 'Puerto Tejada' },
  { label: 'Puerto Tirol', value: 'Puerto Tirol' },
  { label: 'Puerto Triunfo', value: 'Puerto Triunfo' },
  { label: 'Puerto Vallarta', value: 'Puerto Vallarta' },
  { label: 'Puerto Varas', value: 'Puerto Varas' },
  { label: 'Puerto Vidal', value: 'Puerto Vidal' },
  { label: 'Puerto Vilelas', value: 'Puerto Vilelas' },
  { label: 'Puerto Villamil', value: 'Puerto Villamil' },
  { label: 'Puerto Wilches', value: 'Puerto Wilches' },
  { label: 'Puerto Yeruá', value: 'Puerto Yeruá' },
  { label: 'Puértolas', value: 'Puértolas' },
  { label: 'Puertollano', value: 'Puertollano' },
  { label: 'Puertomingalvo', value: 'Puertomingalvo' },
  { label: 'Puesto De Pailas', value: 'Puesto De Pailas' },
  { label: 'Pueyo', value: 'Pueyo' },
  { label: 'Pueyo De Araguás El', value: 'Pueyo De Araguás El' },
  { label: 'Pueyo De Santa Cruz', value: 'Pueyo De Santa Cruz' },
  { label: 'Pufeşti', value: 'Pufeşti' },
  { label: 'Puga', value: 'Puga' },
  { label: 'Pugachev', value: 'Pugachev' },
  { label: 'Pugchén Mumuntic', value: 'Pugchén Mumuntic' },
  { label: 'Puget-Sur-Argens', value: 'Puget-Sur-Argens' },
  { label: 'Puget-Théniers', value: 'Puget-Théniers' },
  { label: 'Puget-Ville', value: 'Puget-Ville' },
  { label: 'Puginate', value: 'Puginate' },
  { label: 'Puglianello', value: 'Puglianello' },
  { label: 'Pugliano', value: 'Pugliano' },
  { label: 'Pugmil', value: 'Pugmil' },
  { label: 'Pugnac', value: 'Pugnac' },
  { label: 'Pugo', value: 'Pugo' },
  { label: 'Pugo', value: 'Pugo' },
  { label: 'Puhechang', value: 'Puhechang' },
  { label: 'Puhi', value: 'Puhi' },
  { label: 'Puhja', value: 'Puhja' },
  { label: 'Pühret', value: 'Pühret' },
  { label: 'Pui', value: 'Pui' },
  { label: 'Puianello', value: 'Puianello' },
  { label: 'Puia-Villanova', value: 'Puia-Villanova' },
  { label: 'Puicheric', value: 'Puicheric' },
  { label: 'Puidoux', value: 'Puidoux' },
  { label: 'Puieni', value: 'Puieni' },
  { label: 'Puieşti', value: 'Puieşti' },
  { label: 'Puieștii De Jos', value: 'Puieștii De Jos' },
  { label: 'Puiflijk', value: 'Puiflijk' },
  { label: 'Puig', value: 'Puig' },
  { label: 'Puigcerdà', value: 'Puigcerdà' },
  { label: 'Puigdàlber', value: 'Puigdàlber' },
  { label: 'Puiggròs', value: 'Puiggròs' },
  { label: 'Puigpelat', value: 'Puigpelat' },
  { label: 'Puigpunyent', value: 'Puigpunyent' },
  { label: 'Puig-Reig', value: 'Puig-Reig' },
  { label: 'Puigverd Dagramunt', value: 'Puigverd Dagramunt' },
  { label: 'Puigverd De Lleida', value: 'Puigverd De Lleida' },
  { label: 'Puilboreau', value: 'Puilboreau' },
  { label: 'Puiseaux', value: 'Puiseaux' },
  { label: 'Puiseux-En-France', value: 'Puiseux-En-France' },
  { label: 'Puissalicon', value: 'Puissalicon' },
  { label: 'Puisserguier', value: 'Puisserguier' },
  { label: 'Pujal-Coy', value: 'Pujal-Coy' },
  { label: 'Pujali', value: 'Pujali' },
  { label: 'Pujalt', value: 'Pujalt' },
  { label: 'Pujaudran', value: 'Pujaudran' },
  { label: 'Pujaut', value: 'Pujaut' },
  { label: 'Pujehun', value: 'Pujehun' },
  { label: 'Pujehun District', value: 'Pujehun District' },
  { label: 'Pujerra', value: 'Pujerra' },
  { label: 'Puji', value: 'Puji' },
  { label: 'Pujilí', value: 'Pujilí' },
  { label: 'Pujocucho', value: 'Pujocucho' },
  { label: 'Pujols', value: 'Pujols' },
  { label: 'Pukalani', value: 'Pukalani' },
  { label: 'Pukchil-Lodongjagu', value: 'Pukchil-Lodongjagu' },
  { label: 'Pukë', value: 'Pukë' },
  { label: 'Pukekohe East', value: 'Pukekohe East' },
  { label: 'Pukhavichy', value: 'Pukhavichy' },
  { label: 'Pukhavichy Raion', value: 'Pukhavichy Raion' },
  { label: 'Pukhivka', value: 'Pukhivka' },
  { label: 'Pukhrayan', value: 'Pukhrayan' },
  { label: 'Pukkila', value: 'Pukkila' },
  { label: 'Pukou', value: 'Pukou' },
  { label: 'Puksoozero', value: 'Puksoozero' },
  { label: 'Pul', value: 'Pul' },
  { label: 'Pula', value: 'Pula' },
  { label: 'Pula', value: 'Pula' },
  { label: 'Pulandian', value: 'Pulandian' },
  { label: 'Pulangbato', value: 'Pulangbato' },
  { label: 'Pula-Pola', value: 'Pula-Pola' },
  { label: 'Pulaski', value: 'Pulaski' },
  { label: 'Pulaski', value: 'Pulaski' },
  { label: 'Pulaski', value: 'Pulaski' },
  { label: 'Pulaski', value: 'Pulaski' },
  { label: 'Pulaski County', value: 'Pulaski County' },
  { label: 'Pulaski County', value: 'Pulaski County' },
  { label: 'Pulaski County', value: 'Pulaski County' },
  { label: 'Pulaski County', value: 'Pulaski County' },
  { label: 'Pulaski County', value: 'Pulaski County' },
  { label: 'Pulaski County', value: 'Pulaski County' },
  { label: 'Pulaski County', value: 'Pulaski County' },
  { label: 'Pulau Sebang', value: 'Pulau Sebang' },
  { label: 'Puławy', value: 'Puławy' },
  { label: 'Pulborough', value: 'Pulborough' },
  { label: 'Pulce', value: 'Pulce' },
  { label: 'Pul-E ‘Alam', value: 'Pul-E ‘Alam' },
  { label: 'Pul-E Khumrī', value: 'Pul-E Khumrī' },
  { label: 'Pulei', value: 'Pulei' },
  { label: 'Pulfero', value: 'Pulfero' },
  { label: 'Pulgaon', value: 'Pulgaon' },
  { label: 'Pulgar', value: 'Pulgar' },
  { label: 'Pulheim', value: 'Pulheim' },
  { label: 'Puli', value: 'Puli' },
  { label: 'Pulí', value: 'Pulí' },
  { label: 'Pulianas', value: 'Pulianas' },
  { label: 'Pulilan', value: 'Pulilan' },
  { label: 'Pulilan', value: 'Pulilan' },
  { label: 'Pulivendla', value: 'Pulivendla' },
  { label: 'Puliyangudi', value: 'Puliyangudi' },
  { label: 'Puliyur', value: 'Puliyur' },
  { label: 'Pulkau', value: 'Pulkau' },
  { label: 'Pulkkila', value: 'Pulkkila' },
  { label: 'Pullach Im Isartal', value: 'Pullach Im Isartal' },
  { label: 'Pullambadi', value: 'Pullambadi' },
  { label: 'Pullenreuth', value: 'Pullenreuth' },
  { label: 'Pullenvale', value: 'Pullenvale' },
  { label: 'Pulligny', value: 'Pulligny' },
  { label: 'Pullman', value: 'Pullman' },
  { label: 'Pulloxhill', value: 'Pulloxhill' },
  { label: 'Pully', value: 'Pully' },
  { label: 'Pulnoy', value: 'Pulnoy' },
  { label: 'Pulo', value: 'Pulo' },
  { label: 'Pulo', value: 'Pulo' },
  { label: 'Pulo', value: 'Pulo' },
  { label: 'Pulong Gubat', value: 'Pulong Gubat' },
  { label: 'Pulong Gubat', value: 'Pulong Gubat' },
  { label: 'Pulong Sampalok', value: 'Pulong Sampalok' },
  { label: 'Pulong Sampalok', value: 'Pulong Sampalok' },
  { label: 'Pulong Santa Cruz', value: 'Pulong Santa Cruz' },
  { label: 'Puloypuloy', value: 'Puloypuloy' },
  { label: 'Puloypuloy', value: 'Puloypuloy' },
  { label: 'Pulpí', value: 'Pulpí' },
  { label: 'Pulsano', value: 'Pulsano' },
  { label: 'Pułtusk', value: 'Pułtusk' },
  { label: 'Pülümür', value: 'Pülümür' },
  { label: 'Pulung Santol', value: 'Pulung Santol' },
  { label: 'Pulung Santol', value: 'Pulung Santol' },
  { label: 'Pulungmasle', value: 'Pulungmasle' },
  { label: 'Pulungmasle', value: 'Pulungmasle' },
  { label: 'Pulupandan', value: 'Pulupandan' },
  { label: 'Pulversheim', value: 'Pulversheim' },
  { label: 'Pulwama', value: 'Pulwama' },
  { label: 'Pulyny Raion', value: 'Pulyny Raion' },
  { label: 'Puma', value: 'Puma' },
  { label: 'Pumanque', value: 'Pumanque' },
  { label: 'Pumenengo', value: 'Pumenengo' },
  { label: 'Pumiao', value: 'Pumiao' },
  { label: 'Pumphrey', value: 'Pumphrey' },
  { label: 'Pumpkin Center', value: 'Pumpkin Center' },
  { label: 'Pumwani', value: 'Pumwani' },
  { label: 'Punahana', value: 'Punahana' },
  { label: 'Punākha', value: 'Punākha' },
  { label: 'Punalu‘U', value: 'Punalu‘U' },
  { label: 'Punalur', value: 'Punalur' },
  { label: 'Punang', value: 'Punang' },
  { label: 'Punao', value: 'Punao' },
  { label: 'Punasa', value: 'Punasa' },
  { label: 'Punat', value: 'Punat' },
  { label: 'Punata', value: 'Punata' },
  { label: 'Punay', value: 'Punay' },
  { label: 'Punay', value: 'Punay' },
  { label: 'Puncan', value: 'Puncan' },
  { label: 'Puncan', value: 'Puncan' },
  { label: 'Punch', value: 'Punch' },
  { label: 'Puncha Community Development Block', value: 'Puncha Community Development Block' },
  { label: 'Punchana', value: 'Punchana' },
  { label: 'Punchbowl', value: 'Punchbowl' },
  { label: 'Puńców', value: 'Puńców' },
  { label: 'Pundri', value: 'Pundri' },
  { label: 'Pune', value: 'Pune' },
  { label: 'Pune Division', value: 'Pune Division' },
  { label: 'Punganuru', value: 'Punganuru' },
  { label: 'Pungarabato', value: 'Pungarabato' },
  { label: 'Pungeşti', value: 'Pungeşti' },
  { label: 'Punggol', value: 'Punggol' },
  { label: 'Punghina', value: 'Punghina' },
  { label: 'Pung-Pang', value: 'Pung-Pang' },
  { label: 'Pung-Pang', value: 'Pung-Pang' },
  { label: 'Pung-Pang', value: 'Pung-Pang' },
  { label: 'Puning', value: 'Puning' },
  { label: 'Punitaqui', value: 'Punitaqui' },
  { label: 'Punjai Puliyampatti', value: 'Punjai Puliyampatti' },
  { label: 'Punkaharju', value: 'Punkaharju' },
  { label: 'Punkalaidun', value: 'Punkalaidun' },
  { label: 'Puno', value: 'Puno' },
  { label: 'Punolu', value: 'Punolu' },
  { label: 'Punolu', value: 'Punolu' },
  { label: 'Puńsk', value: 'Puńsk' },
  { label: 'Punta', value: 'Punta' },
  { label: 'Punta', value: 'Punta' },
  { label: 'Punta', value: 'Punta' },
  { label: 'Punta', value: 'Punta' },
  { label: 'Punta Arena', value: 'Punta Arena' },
  { label: 'Punta Arenas', value: 'Punta Arenas' },
  { label: 'Punta Cana', value: 'Punta Cana' },
  { label: 'Punta Cardón', value: 'Punta Cardón' },
  { label: 'Punta Carretas', value: 'Punta Carretas' },
  { label: 'Punta De Bombón', value: 'Punta De Bombón' },
  { label: 'Punta De Burica', value: 'Punta De Burica' },
  { label: 'Punta De La Vega', value: 'Punta De La Vega' },
  { label: 'Punta De Mujeres', value: 'Punta De Mujeres' },
  { label: 'Punta De Rieles', value: 'Punta De Rieles' },
  { label: 'Punta Del Este', value: 'Punta Del Este' },
  { label: 'Punta Espinillo', value: 'Punta Espinillo' },
  { label: 'Punta Gorda', value: 'Punta Gorda' },
  { label: 'Punta Gorda', value: 'Punta Gorda' },
  { label: 'Punta Gorda', value: 'Punta Gorda' },
  { label: 'Punta Gorda Isles', value: 'Punta Gorda Isles' },
  { label: 'Punta Laurel', value: 'Punta Laurel' },
  { label: 'Punta Marina', value: 'Punta Marina' },
  { label: 'Punta Ocote', value: 'Punta Ocote' },
  { label: 'Punta Peña', value: 'Punta Peña' },
  { label: 'Punta Piedra', value: 'Punta Piedra' },
  { label: 'Punta Rassa', value: 'Punta Rassa' },
  { label: 'Punta Róbalo', value: 'Punta Róbalo' },
  { label: 'Punta Santiago', value: 'Punta Santiago' },
  { label: 'Punta Silum', value: 'Punta Silum' },
  { label: 'Punta Umbría', value: 'Punta Umbría' },
  { label: 'Puntagorda', value: 'Puntagorda' },
  { label: 'Puntallana', value: 'Puntallana' },
  { label: 'Puntamba', value: 'Puntamba' },
  { label: 'Puntarenas', value: 'Puntarenas' },
  { label: 'Puntas De Valdéz', value: 'Puntas De Valdéz' },
  { label: 'Puntigam', value: 'Puntigam' },
  { label: 'Puntilla Aldama', value: 'Puntilla Aldama' },
  { label: 'Puntledge', value: 'Puntledge' },
  { label: 'Punto Fijo', value: 'Punto Fijo' },
  { label: 'Punuare', value: 'Punuare' },
  { label: 'Punxín', value: 'Punxín' },
  { label: 'Punxsutawney', value: 'Punxsutawney' },
  { label: 'Puolanka', value: 'Puolanka' },
  { label: 'Puos Dalpago', value: 'Puos Dalpago' },
  { label: 'Pupiales', value: 'Pupiales' },
  { label: 'Puplinge', value: 'Puplinge' },
  { label: 'Pupri', value: 'Pupri' },
  { label: 'Pupukea', value: 'Pupukea' },
  { label: 'Puqi', value: 'Puqi' },
  { label: 'Puqueldón', value: 'Puqueldón' },
  { label: 'Puquio', value: 'Puquio' },
  { label: 'Pura', value: 'Pura' },
  { label: 'Pura', value: 'Pura' },
  { label: 'Pura', value: 'Pura' },
  { label: 'Purac', value: 'Purac' },
  { label: 'Purac', value: 'Purac' },
  { label: 'Puracé', value: 'Puracé' },
  { label: 'Puračić', value: 'Puračić' },
  { label: 'Pural', value: 'Pural' },
  { label: 'Purani', value: 'Purani' },
  { label: 'Puranpur', value: 'Puranpur' },
  { label: 'Puras', value: 'Puras' },
  { label: 'Purba Champaran', value: 'Purba Champaran' },
  { label: 'Purba Medinipur District', value: 'Purba Medinipur District' },
  { label: 'Purba Singhbhum', value: 'Purba Singhbhum' },
  { label: 'Purbach Am Neusiedler See', value: 'Purbach Am Neusiedler See' },
  { label: 'Purbalingga', value: 'Purbalingga' },
  { label: 'Purcăreni', value: 'Purcăreni' },
  { label: 'Purcăreni', value: 'Purcăreni' },
  { label: 'Purcell', value: 'Purcell' },
  { label: 'Purcellville', value: 'Purcellville' },
  { label: 'Purchase', value: 'Purchase' },
  { label: 'Purchena', value: 'Purchena' },
  { label: 'Purda', value: 'Purda' },
  { label: 'Purdy', value: 'Purdy' },
  { label: 'Purdy', value: 'Purdy' },
  { label: 'Purechucho', value: 'Purechucho' },
  { label: 'Purekh', value: 'Purekh' },
  { label: 'Purén', value: 'Purén' },
  { label: 'Purépero De Echáiz', value: 'Purépero De Echáiz' },
  { label: 'Pureza', value: 'Pureza' },
  { label: 'Purfleet', value: 'Purfleet' },
  { label: 'Pürgen', value: 'Pürgen' },
  { label: 'Purgstall', value: 'Purgstall' },
  { label: 'Puri', value: 'Puri' },
  { label: 'Puricay', value: 'Puricay' },
  { label: 'Puricay', value: 'Puricay' },
  { label: 'Purificación', value: 'Purificación' },
  { label: 'Purificación', value: 'Purificación' },
  { label: 'Puriscal', value: 'Puriscal' },
  { label: 'Purísima', value: 'Purísima' },
  { label: 'Purísima', value: 'Purísima' },
  { label: 'Purísima Concepción Mayorazgo', value: 'Purísima Concepción Mayorazgo' },
  { label: 'Purísima De Arista', value: 'Purísima De Arista' },
  { label: 'Purísima De Bustos', value: 'Purísima De Bustos' },
  { label: 'Purísima De Covarrubias', value: 'Purísima De Covarrubias' },
  { label: 'Purísima De Cubos (La Purísima)', value: 'Purísima De Cubos (La Purísima)' },
  { label: 'Purísima Del Progreso', value: 'Purísima Del Progreso' },
  { label: 'Purísima Del Rincón', value: 'Purísima Del Rincón' },
  { label: 'Puriton', value: 'Puriton' },
  { label: 'Purkersdorf', value: 'Purkersdorf' },
  { label: 'Purley', value: 'Purley' },
  { label: 'Purmafi', value: 'Purmafi' },
  { label: 'Purmerend', value: 'Purmerend' },
  { label: 'Purmerland', value: 'Purmerland' },
  { label: 'Purna', value: 'Purna' },
  { label: 'Purnia', value: 'Purnia' },
  { label: 'Puro', value: 'Puro' },
  { label: 'Puro Pinget', value: 'Puro Pinget' },
  { label: 'Puro Pinget', value: 'Puro Pinget' },
  { label: 'Puroagua', value: 'Puroagua' },
  { label: 'Purovskiy Rayon', value: 'Purovskiy Rayon' },
  { label: 'Purpe', value: 'Purpe' },
  { label: 'Purral', value: 'Purral' },
  { label: 'Purranque', value: 'Purranque' },
  { label: 'Pursaklar', value: 'Pursaklar' },
  { label: 'Pursat', value: 'Pursat' },
  { label: 'Purshevo', value: 'Purshevo' },
  { label: 'Purton', value: 'Purton' },
  { label: 'Puruándiro', value: 'Puruándiro' },
  { label: 'Puruarán', value: 'Puruarán' },
  { label: 'Purujosa', value: 'Purujosa' },
  { label: 'Purulhá', value: 'Purulhá' },
  { label: 'Purulia', value: 'Purulia' },
  { label: 'Purullena', value: 'Purullena' },
  { label: 'Purus', value: 'Purus' },
  { label: 'Purushottampur', value: 'Purushottampur' },
  { label: 'Purvis', value: 'Purvis' },
  { label: 'Purwa', value: 'Purwa' },
  { label: 'Purwakarta', value: 'Purwakarta' },
  { label: 'Purwodadi', value: 'Purwodadi' },
  { label: 'Purwokerto', value: 'Purwokerto' },
  { label: 'Puryŏng', value: 'Puryŏng' },
  { label: 'Pusad', value: 'Pusad' },
  { label: 'Pusarnitz', value: 'Pusarnitz' },
  { label: 'Pušća', value: 'Pušća' },
  { label: 'Pușcași', value: 'Pușcași' },
  { label: 'Puschendorf', value: 'Puschendorf' },
  { label: 'Puschwitz', value: 'Puschwitz' },
  { label: 'Pusey', value: 'Pusey' },
  { label: 'Pushchino', value: 'Pushchino' },
  { label: 'Pushkar', value: 'Pushkar' },
  { label: 'Pushkin', value: 'Pushkin' },
  { label: 'Pushkino', value: 'Pushkino' },
  { label: 'Pushkino', value: 'Pushkino' },
  { label: 'Pushkino', value: 'Pushkino' },
  { label: 'Pushkino', value: 'Pushkino' },
  { label: 'Pushkino-Gorskiy Rayon', value: 'Pushkino-Gorskiy Rayon' },
  { label: 'Pushkinskiy Rayon', value: 'Pushkinskiy Rayon' },
  { label: 'Pushkinskiye Gory', value: 'Pushkinskiye Gory' },
  { label: 'Pushmataha County', value: 'Pushmataha County' },
  { label: 'Pusiano', value: 'Pusiano' },
  { label: 'Pusignan', value: 'Pusignan' },
  { label: 'Püspökladány', value: 'Püspökladány' },
  { label: 'Püspökladányi Járás', value: 'Püspökladányi Járás' },
  { label: 'Pussay', value: 'Pussay' },
  { label: 'Pussellawa', value: 'Pussellawa' },
  { label: 'Püssi', value: 'Püssi' },
  { label: 'Pusta', value: 'Pusta' },
  { label: 'Pusta', value: 'Pusta' },
  { label: 'Pustá Polom', value: 'Pustá Polom' },
  { label: 'Pusterwald', value: 'Pusterwald' },
  { label: 'Pustiana', value: 'Pustiana' },
  { label: 'Pustiměř', value: 'Pustiměř' },
  { label: 'Pustomyty', value: 'Pustomyty' },
  { label: 'Pustoshka', value: 'Pustoshka' },
  { label: 'Pustoshkinskiy Rayon', value: 'Pustoshkinskiy Rayon' },
  { label: 'Pustunich', value: 'Pustunich' },
  { label: 'Puszcza Mariańska', value: 'Puszcza Mariańska' },
  { label: 'Puszczykowo', value: 'Puszczykowo' },
  { label: 'Pusztaföldvár', value: 'Pusztaföldvár' },
  { label: 'Pusztaszabolcs', value: 'Pusztaszabolcs' },
  { label: 'Pusztaszer', value: 'Pusztaszer' },
  { label: 'Pusztavám', value: 'Pusztavám' },
  { label: 'Puta', value: 'Puta' },
  { label: 'Putaendo', value: 'Putaendo' },
  { label: 'Putanges-Pont-Écrepin', value: 'Putanges-Pont-Écrepin' },
  { label: 'Putat', value: 'Putat' },
  { label: 'Putatan', value: 'Putatan' },
  { label: 'Putbus', value: 'Putbus' },
  { label: 'Puteaux', value: 'Puteaux' },
  { label: 'Putëvka', value: 'Putëvka' },
  { label: 'Puteyets', value: 'Puteyets' },
  { label: 'Puth', value: 'Puth' },
  { label: 'Putian', value: 'Putian' },
  { label: 'Putiao', value: 'Putiao' },
  { label: 'Putifigari', value: 'Putifigari' },
  { label: 'Putignano', value: 'Putignano' },
  { label: 'Putinci', value: 'Putinci' },
  { label: 'Putineiu', value: 'Putineiu' },
  { label: 'Putineiu', value: 'Putineiu' },
  { label: 'Puting Kahoy', value: 'Puting Kahoy' },
  { label: 'Putinga', value: 'Putinga' },
  { label: 'Putingkahoy', value: 'Putingkahoy' },
  { label: 'Putla Villa De Guerrero', value: 'Putla Villa De Guerrero' },
  { label: 'Putlitz', value: 'Putlitz' },
  { label: 'Putlod', value: 'Putlod' },
  { label: 'Putlod', value: 'Putlod' },
  { label: 'Putlunichuchut (Vista Hermosa)', value: 'Putlunichuchut (Vista Hermosa)' },
  { label: 'Putna', value: 'Putna' },
  { label: 'Putnam', value: 'Putnam' },
  { label: 'Putnam County', value: 'Putnam County' },
  { label: 'Putnam County', value: 'Putnam County' },
  { label: 'Putnam County', value: 'Putnam County' },
  { label: 'Putnam County', value: 'Putnam County' },
  { label: 'Putnam County', value: 'Putnam County' },
  { label: 'Putnam County', value: 'Putnam County' },
  { label: 'Putnam County', value: 'Putnam County' },
  { label: 'Putnam County', value: 'Putnam County' },
  { label: 'Putnam County', value: 'Putnam County' },
  { label: 'Putnam Lake', value: 'Putnam Lake' },
  { label: 'Putney', value: 'Putney' },
  { label: 'Putney', value: 'Putney' },
  { label: 'Putnok', value: 'Putnok' },
  { label: 'Putnoki Járás', value: 'Putnoki Járás' },
  { label: 'Putol', value: 'Putol' },
  { label: 'Putra Heights', value: 'Putra Heights' },
  { label: 'Putrajaya', value: 'Putrajaya' },
  { label: 'Putre', value: 'Putre' },
  { label: 'Putscheid', value: 'Putscheid' },
  { label: 'Puttalam', value: 'Puttalam' },
  { label: 'Puttalam District', value: 'Puttalam District' },
  { label: 'Puttaparthi', value: 'Puttaparthi' },
  { label: 'Putte', value: 'Putte' },
  { label: 'Putte', value: 'Putte' },
  { label: 'Putten', value: 'Putten' },
  { label: 'Püttlingen', value: 'Püttlingen' },
  { label: 'Puttur', value: 'Puttur' },
  { label: 'Puttur', value: 'Puttur' },
  { label: 'Pütürge', value: 'Pütürge' },
  { label: 'Puțuri', value: 'Puțuri' },
  { label: 'Putyatin', value: 'Putyatin' },
  { label: 'Putyatino', value: 'Putyatino' },
  { label: 'Putyla Raion', value: 'Putyla Raion' },
  { label: 'Putyvl’', value: 'Putyvl’' },
  { label: 'Putzbrunn', value: 'Putzbrunn' },
  { label: 'Puumala', value: 'Puumala' },
  { label: 'Puurs', value: 'Puurs' },
  { label: 'Puxcatán', value: 'Puxcatán' },
  { label: 'Puxinanã', value: 'Puxinanã' },
  { label: 'Puxtla', value: 'Puxtla' },
  { label: 'Puyallup', value: 'Puyallup' },
  { label: 'Puyang', value: 'Puyang' },
  { label: 'Puyang Chengguanzhen', value: 'Puyang Chengguanzhen' },
  { label: 'Puyang Shi', value: 'Puyang Shi' },
  { label: 'Puyehue', value: 'Puyehue' },
  { label: 'Puygouzon', value: 'Puygouzon' },
  { label: 'Puy-Guillaume', value: 'Puy-Guillaume' },
  { label: 'Puy-L’Évêque', value: 'Puy-L’Évêque' },
  { label: 'Puylaurens', value: 'Puylaurens' },
  { label: 'Puyloubier', value: 'Puyloubier' },
  { label: 'Puymoyen', value: 'Puymoyen' },
  { label: 'Puyo', value: 'Puyo' },
  { label: 'Puyoô', value: 'Puyoô' },
  { label: 'Puzi', value: 'Puzi' },
  { label: 'Pwene Municipality', value: 'Pwene Municipality' },
  { label: 'Pwllheli', value: 'Pwllheli' },
  { label: 'Pyaozerskiy', value: 'Pyaozerskiy' },
  { label: 'Pyapon', value: 'Pyapon' },
  { label: 'Pyatigorsk', value: 'Pyatigorsk' },
  { label: 'Pyatigorskiy', value: 'Pyatigorskiy' },
  { label: 'Pyatikhatka', value: 'Pyatikhatka' },
  { label: 'Pyatnitskoye', value: 'Pyatnitskoye' },
  { label: 'Pyatovskiy', value: 'Pyatovskiy' },
  { label: 'Pyatryshki', value: 'Pyatryshki' },
  { label: 'Pyatypillya', value: 'Pyatypillya' },
  { label: 'Pyay', value: 'Pyay' },
  { label: 'Pychas', value: 'Pychas' },
  { label: 'Pyeongchang', value: 'Pyeongchang' },
  { label: 'Pyeongchang-Gun', value: 'Pyeongchang-Gun' },
  { label: 'Pyeongtaek', value: 'Pyeongtaek' },
  { label: 'Pyetrykaw', value: 'Pyetrykaw' },
  { label: 'Pyhäjärvi', value: 'Pyhäjärvi' },
  { label: 'Pyhäjärvi', value: 'Pyhäjärvi' },
  { label: 'Pyhäjoki', value: 'Pyhäjoki' },
  { label: 'Pyhäntä', value: 'Pyhäntä' },
  { label: 'Pyhäranta', value: 'Pyhäranta' },
  { label: 'Pyhäselkä', value: 'Pyhäselkä' },
  { label: 'Pyhra', value: 'Pyhra' },
  { label: 'Pyhtää', value: 'Pyhtää' },
  { label: 'Pyin Oo Lwin', value: 'Pyin Oo Lwin' },
  { label: 'Pyinmana', value: 'Pyinmana' },
  { label: 'Pýla', value: 'Pýla' },
  { label: 'Pylaía', value: 'Pylaía' },
  { label: 'Pyle', value: 'Pyle' },
  { label: 'Pylí', value: 'Pylí' },
  { label: 'Pýli', value: 'Pýli' },
  { label: 'Pylkönmäki', value: 'Pylkönmäki' },
  { label: 'Pýlos', value: 'Pýlos' },
  { label: 'Pylypets', value: 'Pylypets' },
  { label: 'Pymatuning Central', value: 'Pymatuning Central' },
  { label: 'Pymble', value: 'Pymble' },
  { label: 'Pyŏksŏng-Ŭp', value: 'Pyŏksŏng-Ŭp' },
  { label: 'Pyongyang', value: 'Pyongyang' },
  { label: 'Pyra', value: 'Pyra' },
  { label: 'Pyrbaum', value: 'Pyrbaum' },
  { label: 'Pyrenees', value: 'Pyrenees' },
  { label: 'Pyrgetós', value: 'Pyrgetós' },
  { label: 'Pyrgos', value: 'Pyrgos' },
  { label: 'Pýrgos', value: 'Pýrgos' },
  { label: 'Pýrgos', value: 'Pýrgos' },
  { label: 'Pyriatyn', value: 'Pyriatyn' },
  { label: 'Pyrmont', value: 'Pyrmont' },
  { label: 'Pyrzyce', value: 'Pyrzyce' },
  { label: 'Pyšely', value: 'Pyšely' },
  { label: 'Pyshchug', value: 'Pyshchug' },
  { label: 'Pyshma', value: 'Pyshma' },
  { label: 'Pyskowice', value: 'Pyskowice' },
  { label: 'Pysznica', value: 'Pysznica' },
  { label: 'Pytalovo', value: 'Pytalovo' },
  { label: 'Pytalovskiy Rayon', value: 'Pytalovskiy Rayon' },
  { label: 'Pyt-Yakh', value: 'Pyt-Yakh' },
  { label: 'Pyu', value: 'Pyu' },
  { label: 'Pyuthan ', value: 'Pyuthan ' },
  { label: 'Pyzdry', value: 'Pyzdry' },
  { label: 'Qaba', value: 'Qaba' },
  { label: 'Qabanqoli', value: 'Qabanqoli' },
  { label: 'Qabaqçöl', value: 'Qabaqçöl' },
  { label: 'Qacha’S Nek', value: 'Qacha’S Nek' },
  { label: 'Qaḑā’ Kifrī', value: 'Qaḑā’ Kifrī' },
  { label: 'Qader Khalaj', value: 'Qader Khalaj' },
  { label: 'Qaderabad', value: 'Qaderabad' },
  { label: 'Qadian', value: 'Qadian' },
  { label: 'Qadimi', value: 'Qadimi' },
  { label: 'Qadirganj', value: 'Qadirganj' },
  { label: 'Qadirpur Ran', value: 'Qadirpur Ran' },
  { label: 'Qadisiyah', value: 'Qadisiyah' },
  { label: 'Qaem Shahr', value: 'Qaem Shahr' },
  { label: 'Qaemshahr', value: 'Qaemshahr' },
  { label: 'Qaemyeh', value: 'Qaemyeh' },
  { label: 'Qaen', value: 'Qaen' },
  { label: 'Qafl Shamer', value: 'Qafl Shamer' },
  { label: 'Qafqafā', value: 'Qafqafā' },
  { label: 'Qahavand', value: 'Qahavand' },
  { label: 'Qahderijan', value: 'Qahderijan' },
  { label: 'Qahjavarestan', value: 'Qahjavarestan' },
  { label: 'Qaisumah', value: 'Qaisumah' },
  { label: 'Qajar Ab-E Sofla', value: 'Qajar Ab-E Sofla' },
  { label: 'Qal‘Ah-Ye Shahr', value: 'Qal‘Ah-Ye Shahr' },
  { label: 'Qala', value: 'Qala' },
  { label: 'Qala', value: 'Qala' },
  { label: 'Qala I Naw', value: 'Qala I Naw' },
  { label: 'Qalandarabad', value: 'Qalandarabad' },
  { label: 'Qalansīyah', value: 'Qalansīyah' },
  { label: 'Qalansuwa', value: 'Qalansuwa' },
  { label: 'Qalāt', value: 'Qalāt' },
  { label: 'Qaleh Ganj', value: 'Qaleh Ganj' },
  { label: 'Qaleh Qazi', value: 'Qaleh Qazi' },
  { label: 'Qaleh Raisi', value: 'Qaleh Raisi' },
  { label: 'Qaleh-Ye Khvajeh', value: 'Qaleh-Ye Khvajeh' },
  { label: 'Qalyūb', value: 'Qalyūb' },
  { label: 'Qamdo', value: 'Qamdo' },
  { label: 'Qamdo Shi', value: 'Qamdo Shi' },
  { label: 'Qanavat', value: 'Qanavat' },
  { label: 'Qandala', value: 'Qandala' },
  { label: 'Qandax', value: 'Qandax' },
  { label: 'Qapqal', value: 'Qapqal' },
  { label: 'Qaraçala', value: 'Qaraçala' },
  { label: 'Qaraçuxur', value: 'Qaraçuxur' },
  { label: 'Qarah', value: 'Qarah' },
  { label: 'Qārah', value: 'Qārah' },
  { label: 'Qarah Bāgh', value: 'Qarah Bāgh' },
  { label: 'Qarah Day', value: 'Qarah Day' },
  { label: 'Qarah Tegini', value: 'Qarah Tegini' },
  { label: 'Qarahziyaeddin', value: 'Qarahziyaeddin' },
  { label: 'Qaramanlı', value: 'Qaramanlı' },
  { label: 'Qaranqu Darreh', value: 'Qaranqu Darreh' },
  { label: 'Qaraton', value: 'Qaraton' },
  { label: 'Qaraūyl', value: 'Qaraūyl' },
  { label: 'Qarāwul', value: 'Qarāwul' },
  { label: 'Qaraxanlı', value: 'Qaraxanlı' },
  { label: 'Qarayeri', value: 'Qarayeri' },
  { label: 'Qarazhal', value: 'Qarazhal' },
  { label: 'Qarchak', value: 'Qarchak' },
  { label: 'Qarchī Gak', value: 'Qarchī Gak' },
  { label: 'Qardaha District', value: 'Qardaha District' },
  { label: 'Qareh Aghaj', value: 'Qareh Aghaj' },
  { label: 'Qarnabad', value: 'Qarnabad' },
  { label: 'Qarqaraly', value: 'Qarqaraly' },
  { label: 'Qarqīn', value: 'Qarqīn' },
  { label: 'Qarshi', value: 'Qarshi' },
  { label: 'Qaryat Sulūq', value: 'Qaryat Sulūq' },
  { label: 'Qasabeh', value: 'Qasabeh' },
  { label: 'Qasabestan', value: 'Qasabestan' },
  { label: 'Qasemabad', value: 'Qasemabad' },
  { label: 'Qasemabad-E Laklak', value: 'Qasemabad-E Laklak' },
  { label: 'Qashar', value: 'Qashar' },
  { label: 'Qashyr', value: 'Qashyr' },
  { label: 'Qasım Ismayılov', value: 'Qasım Ismayılov' },
  { label: 'Qasr Abu Hadi', value: 'Qasr Abu Hadi' },
  { label: 'Qaşr Al Farāfirah', value: 'Qaşr Al Farāfirah' },
  { label: 'Qasr-E Shirin', value: 'Qasr-E Shirin' },
  { label: 'Qasr-E-Qand', value: 'Qasr-E-Qand' },
  { label: 'Qatabir', value: 'Qatabir' },
  { label: 'Qaţanā', value: 'Qaţanā' },
  { label: 'Qater Owlan', value: 'Qater Owlan' },
  { label: 'Qatruyeh', value: 'Qatruyeh' },
  { label: 'Qawra', value: 'Qawra' },
  { label: 'Qax', value: 'Qax' },
  { label: 'Qax Ingiloy', value: 'Qax Ingiloy' },
  { label: 'Qaxbaş', value: 'Qaxbaş' },
  { label: 'Qayesh', value: 'Qayesh' },
  { label: 'Qazaly', value: 'Qazaly' },
  { label: 'Qazan Qarah', value: 'Qazan Qarah' },
  { label: 'Qazax', value: 'Qazax' },
  { label: 'Qazi', value: 'Qazi' },
  { label: 'Qazi Mardan', value: 'Qazi Mardan' },
  { label: 'Qazigund', value: 'Qazigund' },
  { label: 'Qazvin', value: 'Qazvin' },
  { label: 'Qeshlaq Baba Rostam', value: 'Qeshlaq Baba Rostam' },
  { label: 'Qeshlaq-E Anuch', value: 'Qeshlaq-E Anuch' },
  { label: 'Qeshlaq-E Deh Ful', value: 'Qeshlaq-E Deh Ful' },
  { label: 'Qeshlaq-E Dehnow', value: 'Qeshlaq-E Dehnow' },
  { label: 'Qeshlaq-E Mohammadi', value: 'Qeshlaq-E Mohammadi' },
  { label: 'Qeshlaq-E Najaf', value: 'Qeshlaq-E Najaf' },
  { label: 'Qeshlaq-E Pust Shuran', value: 'Qeshlaq-E Pust Shuran' },
  { label: 'Qeshlaq-E Qobad', value: 'Qeshlaq-E Qobad' },
  { label: 'Qeshlaq-E Shirazi', value: 'Qeshlaq-E Shirazi' },
  { label: 'Qeshlaq-E Sofla', value: 'Qeshlaq-E Sofla' },
  { label: 'Qeshlaq-E Valiollah', value: 'Qeshlaq-E Valiollah' },
  { label: 'Qeshm', value: 'Qeshm' },
  { label: 'Qeydar', value: 'Qeydar' },
  { label: 'Qeydli Bolagh', value: 'Qeydli Bolagh' },
  { label: 'Qiancheng', value: 'Qiancheng' },
  { label: 'Qiandongnan Miao And Dong Autonomous Prefecture', value: 'Qiandongnan Miao And Dong Autonomous Prefecture' },
  { label: 'Qianjiang', value: 'Qianjiang' },
  { label: 'Qianxinan Bouyeizu Miaozu Zizhizhou', value: 'Qianxinan Bouyeizu Miaozu Zizhizhou' },
  { label: 'Qianzhou', value: 'Qianzhou' },
  { label: 'Qiaojiang', value: 'Qiaojiang' },
  { label: 'Qiba', value: 'Qiba' },
  { label: 'Qibray', value: 'Qibray' },
  { label: 'Qibu', value: 'Qibu' },
  { label: 'Qidu', value: 'Qidu' },
  { label: 'Qila Didar Singh', value: 'Qila Didar Singh' },
  { label: 'Qila Saifullāh District', value: 'Qila Saifullāh District' },
  { label: 'Qilab', value: 'Qilab' },
  { label: 'Qimeng', value: 'Qimeng' },
  { label: 'Qinā', value: 'Qinā' },
  { label: 'Qincheng', value: 'Qincheng' },
  { label: 'Qingdao', value: 'Qingdao' },
  { label: 'Qinggang', value: 'Qinggang' },
  { label: 'Qingguang', value: 'Qingguang' },
  { label: 'Qingjiangqiao', value: 'Qingjiangqiao' },
  { label: 'Qinglang', value: 'Qinglang' },
  { label: 'Qingnian', value: 'Qingnian' },
  { label: 'Qingping', value: 'Qingping' },
  { label: 'Qingquan', value: 'Qingquan' },
  { label: 'Qingshancun', value: 'Qingshancun' },
  { label: 'Qingxi', value: 'Qingxi' },
  { label: 'Qingyang', value: 'Qingyang' },
  { label: 'Qingyang Shi', value: 'Qingyang Shi' },
  { label: 'Qingyuan', value: 'Qingyuan' },
  { label: 'Qingzhou', value: 'Qingzhou' },
  { label: 'Qinhuangdao', value: 'Qinhuangdao' },
  { label: 'Qinnan', value: 'Qinnan' },
  { label: 'Qinzhou', value: 'Qinzhou' },
  { label: 'Qionghai', value: 'Qionghai' },
  { label: 'Qionghu', value: 'Qionghu' },
  { label: 'Qiongshan', value: 'Qiongshan' },
  { label: 'Qiqihar', value: 'Qiqihar' },
  { label: 'Qīr Moāv', value: 'Qīr Moāv' },
  { label: 'Qir Va Karzin', value: 'Qir Va Karzin' },
  { label: 'Qırmızı Bazar', value: 'Qırmızı Bazar' },
  { label: 'Qırmızı Samux', value: 'Qırmızı Samux' },
  { label: 'Qiryat Ata', value: 'Qiryat Ata' },
  { label: 'Qiryat Bialik', value: 'Qiryat Bialik' },
  { label: 'Qiryat Gat', value: 'Qiryat Gat' },
  { label: 'Qiryat Moẕqin', value: 'Qiryat Moẕqin' },
  { label: 'Qiryat Shemona', value: 'Qiryat Shemona' },
  { label: 'Qiryat Yam', value: 'Qiryat Yam' },
  { label: 'Qishn', value: 'Qishn' },
  { label: 'Qitabir', value: 'Qitabir' },
  { label: 'Qıvraq', value: 'Qıvraq' },
  { label: 'Qiya', value: 'Qiya' },
  { label: 'Qızılhacılı', value: 'Qızılhacılı' },
  { label: 'Qiziltepa', value: 'Qiziltepa' },
  { label: 'Qo‘Qon', value: 'Qo‘Qon' },
  { label: 'Qo‘Rg‘Ontepa', value: 'Qo‘Rg‘Ontepa' },
  { label: 'Qobu', value: 'Qobu' },
  { label: 'Qobustan', value: 'Qobustan' },
  { label: 'Qobustan', value: 'Qobustan' },
  { label: 'Qods', value: 'Qods' },
  { label: 'Qogham', value: 'Qogham' },
  { label: 'Qohurd-E Sofla', value: 'Qohurd-E Sofla' },
  { label: 'Qoli Laleh-Ye Sofla', value: 'Qoli Laleh-Ye Sofla' },
  { label: 'Qolqolabad', value: 'Qolqolabad' },
  { label: 'Qom', value: 'Qom' },
  { label: 'Qorako’L', value: 'Qorako’L' },
  { label: 'Qormi', value: 'Qormi' },
  { label: 'Qorovulbozor', value: 'Qorovulbozor' },
  { label: 'Qorveh', value: 'Qorveh' },
  { label: 'Qorveh-E Darjazin', value: 'Qorveh-E Darjazin' },
  { label: 'Qoryooley', value: 'Qoryooley' },
  { label: 'Qotbabad', value: 'Qotbabad' },
  { label: 'Qotur', value: 'Qotur' },
  { label: 'Qquea', value: 'Qquea' },
  { label: 'Qrendi', value: 'Qrendi' },
  { label: 'Quaderni', value: 'Quaderni' },
  { label: 'Quadra', value: 'Quadra' },
  { label: 'Quadrazais', value: 'Quadrazais' },
  { label: 'Quadrelle', value: 'Quadrelle' },
  { label: 'Quadri', value: 'Quadri' },
  { label: 'Quadrivio', value: 'Quadrivio' },
  { label: 'Quaëdypre', value: 'Quaëdypre' },
  { label: 'Quagliuzzo', value: 'Quagliuzzo' },
  { label: 'Quail Creek', value: 'Quail Creek' },
  { label: 'Quail Ridge', value: 'Quail Ridge' },
  { label: 'Quail Valley', value: 'Quail Valley' },
  { label: 'Quairading', value: 'Quairading' },
  { label: 'Quakenbrück', value: 'Quakenbrück' },
  { label: 'Quakers Hill', value: 'Quakers Hill' },
  { label: 'Quakertown', value: 'Quakertown' },
  { label: 'Qualiano', value: 'Qualiano' },
  { label: 'Quamila', value: 'Quamila' },
  { label: 'Quận Ba Đình', value: 'Quận Ba Đình' },
  { label: 'Quận Hà Đông', value: 'Quận Hà Đông' },
  { label: 'Quanah', value: 'Quanah' },
  { label: 'Quảng Ngãi', value: 'Quảng Ngãi' },
  { label: 'Quantico Station', value: 'Quantico Station' },
  { label: 'Quanzhou', value: 'Quanzhou' },
  { label: 'Quar La', value: 'Quar La' },
  { label: 'Quaraí', value: 'Quaraí' },
  { label: 'Quarante', value: 'Quarante' },
  { label: 'Quaranti', value: 'Quaranti' },
  { label: 'Quarantoli', value: 'Quarantoli' },
  { label: 'Quarata', value: 'Quarata' },
  { label: 'Quaregna', value: 'Quaregna' },
  { label: 'Quaregnon', value: 'Quaregnon' },
  { label: 'Quargnento', value: 'Quargnento' },
  { label: 'Quarna Sopra', value: 'Quarna Sopra' },
  { label: 'Quarna Sotto', value: 'Quarna Sotto' },
  { label: 'Quarnbek', value: 'Quarnbek' },
  { label: 'Quarona', value: 'Quarona' },
  { label: 'Quarouble', value: 'Quarouble' },
  { label: 'Quarrata', value: 'Quarrata' },
  { label: 'Quarry Hill', value: 'Quarry Hill' },
  { label: 'Quarryville', value: 'Quarryville' },
  { label: 'Quart', value: 'Quart' },
  { label: 'Quart', value: 'Quart' },
  { label: 'Quart De Les Valls', value: 'Quart De Les Valls' },
  { label: 'Quart De Poblet', value: 'Quart De Poblet' },
  { label: 'Quarte Chemins', value: 'Quarte Chemins' },
  { label: 'Quarteira', value: 'Quarteira' },
  { label: 'Quartel Geral', value: 'Quartel Geral' },
  { label: 'Quartell', value: 'Quartell' },
  { label: 'Quarten', value: 'Quarten' },
  { label: 'Quartesana', value: 'Quartesana' },
  { label: 'Quartiano', value: 'Quartiano' },
  { label: 'Quartier Militaire', value: 'Quartier Militaire' },
  { label: 'Quartier Morin', value: 'Quartier Morin' },
  { label: 'Quarto', value: 'Quarto' },
  { label: 'Quarto Centenário', value: 'Quarto Centenário' },
  { label: 'Quarto Daltino', value: 'Quarto Daltino' },
  { label: 'Quarto Inferiore', value: 'Quarto Inferiore' },
  { label: 'Quartu Santelena', value: 'Quartu Santelena' },
  { label: 'Quartucciu', value: 'Quartucciu' },
  { label: 'Quartz Hill', value: 'Quartz Hill' },
  { label: 'Quartzsite', value: 'Quartzsite' },
  { label: 'Quasano', value: 'Quasano' },
  { label: 'Quassolo', value: 'Quassolo' },
  { label: 'Quatá', value: 'Quatá' },
  { label: 'Quatiguá', value: 'Quatiguá' },
  { label: 'Quatipuru', value: 'Quatipuru' },
  { label: 'Quatis', value: 'Quatis' },
  { label: 'Quatre Bornes', value: 'Quatre Bornes' },
  { label: 'Quatre Cocos', value: 'Quatre Cocos' },
  { label: 'Quatre Soeurs', value: 'Quatre Soeurs' },
  { label: 'Quatretonda', value: 'Quatretonda' },
  { label: 'Quatretondeta', value: 'Quatretondeta' },
  { label: 'Quatro Barras', value: 'Quatro Barras' },
  { label: 'Quatro Irmãos', value: 'Quatro Irmãos' },
  { label: 'Quatro Pontes', value: 'Quatro Pontes' },
  { label: 'Quattordio', value: 'Quattordio' },
  { label: 'Quattro Castella', value: 'Quattro Castella' },
  { label: 'Quattro Strade', value: 'Quattro Strade' },
  { label: 'Quattro Strade', value: 'Quattro Strade' },
  { label: 'Quattromiglia', value: 'Quattromiglia' },
  { label: 'Quay County', value: 'Quay County' },
  { label: 'Quba', value: 'Quba' },
  { label: 'Qubadlı', value: 'Qubadlı' },
  { label: 'Quch Tappeh', value: 'Quch Tappeh' },
  { label: 'Quchan', value: 'Quchan' },
  { label: 'Queanbeyan', value: 'Queanbeyan' },
  { label: 'Queanbeyan East', value: 'Queanbeyan East' },
  { label: 'Queanbeyan West', value: 'Queanbeyan West' },
  { label: 'Québec', value: 'Québec' },
  { label: 'Quebo', value: 'Quebo' },
  { label: 'Quebracho', value: 'Quebracho' },
  { label: 'Quebrada', value: 'Quebrada' },
  { label: 'Quebrada Bonita Adentro', value: 'Quebrada Bonita Adentro' },
  { label: 'Quebrada Canoa', value: 'Quebrada Canoa' },
  { label: 'Quebrada De Arena', value: 'Quebrada De Arena' },
  { label: 'Quebrada De Oro', value: 'Quebrada De Oro' },
  { label: 'Quebrada De Piedra', value: 'Quebrada De Piedra' },
  { label: 'Quebrada Del Rosario', value: 'Quebrada Del Rosario' },
  { label: 'Quebrada Larga', value: 'Quebrada Larga' },
  { label: 'Quebrada Seca', value: 'Quebrada Seca' },
  { label: 'Quebrada Tula', value: 'Quebrada Tula' },
  { label: 'Quebradanegra', value: 'Quebradanegra' },
  { label: 'Quebradas', value: 'Quebradas' },
  { label: 'Quebradillas', value: 'Quebradillas' },
  { label: 'Quebradillas', value: 'Quebradillas' },
  { label: 'Quebradillas Municipio', value: 'Quebradillas Municipio' },
  { label: 'Quebrangulo', value: 'Quebrangulo' },
  { label: 'Quebrantadero', value: 'Quebrantadero' },
  { label: 'Québriac', value: 'Québriac' },
  { label: 'Quecholac', value: 'Quecholac' },
  { label: 'Quechulac', value: 'Quechulac' },
  { label: 'Quechultenango', value: 'Quechultenango' },
  { label: 'Quedas Do Iguaçu', value: 'Quedas Do Iguaçu' },
  { label: 'Quédillac', value: 'Quédillac' },
  { label: 'Quedlinburg', value: 'Quedlinburg' },
  { label: 'Queen Anne', value: 'Queen Anne' },
  { label: 'Queen Annes County', value: 'Queen Annes County' },
  { label: 'Queen City', value: 'Queen City' },
  { label: 'Queen Creek', value: 'Queen Creek' },
  { label: 'Queen Victoria', value: 'Queen Victoria' },
  { label: 'Queenborough', value: 'Queenborough' },
  { label: 'Queenland', value: 'Queenland' },
  { label: 'Queens', value: 'Queens' },
  { label: 'Queens County', value: 'Queens County' },
  { label: 'Queens Park', value: 'Queens Park' },
  { label: 'Queens Park', value: 'Queens Park' },
  { label: 'Queens Village', value: 'Queens Village' },
  { label: 'Queensborough/ Tunbridge', value: 'Queensborough/ Tunbridge' },
  { label: 'Queensbury', value: 'Queensbury' },
  { label: 'Queensbury', value: 'Queensbury' },
  { label: 'Queenscliff', value: 'Queenscliff' },
  { label: 'Queenscliff', value: 'Queenscliff' },
  { label: 'Queenscliffe', value: 'Queenscliffe' },
  { label: 'Queensdale', value: 'Queensdale' },
  { label: 'Queensferry', value: 'Queensferry' },
  { label: 'Queenstown', value: 'Queenstown' },
  { label: 'Queenstown', value: 'Queenstown' },
  { label: 'Queenstown', value: 'Queenstown' },
  { label: 'Queenstown', value: 'Queenstown' },
  { label: 'Queenswood Heights', value: 'Queenswood Heights' },
  { label: 'Queenton', value: 'Queenton' },
  { label: 'Queidersbach', value: 'Queidersbach' },
  { label: 'Queijas', value: 'Queijas' },
  { label: 'Queijas', value: 'Queijas' },
  { label: 'Queilén', value: 'Queilén' },
  { label: 'Queimada Nova', value: 'Queimada Nova' },
  { label: 'Queimadas', value: 'Queimadas' },
  { label: 'Queimadas', value: 'Queimadas' },
  { label: 'Queimados', value: 'Queimados' },
  { label: 'Queiriz', value: 'Queiriz' },
  { label: 'Queiroz', value: 'Queiroz' },
  { label: 'Queis', value: 'Queis' },
  { label: 'Quel', value: 'Quel' },
  { label: 'Quelaines-Saint-Gault', value: 'Quelaines-Saint-Gault' },
  { label: 'Quelicai', value: 'Quelicai' },
  { label: 'Quelimane', value: 'Quelimane' },
  { label: 'Quellendorf', value: 'Quellendorf' },
  { label: 'Quellón', value: 'Quellón' },
  { label: 'Quelové', value: 'Quelové' },
  { label: 'Queluz', value: 'Queluz' },
  { label: 'Queluz', value: 'Queluz' },
  { label: 'Queluz', value: 'Queluz' },
  { label: 'Queluzito', value: 'Queluzito' },
  { label: 'Quemada', value: 'Quemada' },
  { label: 'Quemado De Güines', value: 'Quemado De Güines' },
  { label: 'Quemchi', value: 'Quemchi' },
  { label: 'Quéménéven', value: 'Quéménéven' },
  { label: 'Quemper-Guézennec', value: 'Quemper-Guézennec' },
  { label: 'Quemú Quemú', value: 'Quemú Quemú' },
  { label: 'Quend', value: 'Quend' },
  { label: 'Queniborough', value: 'Queniborough' },
  { label: 'Quéntar', value: 'Quéntar' },
  { label: 'Quepem', value: 'Quepem' },
  { label: 'Quepos', value: 'Quepos' },
  { label: 'Quer', value: 'Quer' },
  { label: 'Queralbs', value: 'Queralbs' },
  { label: 'Quercianella', value: 'Quercianella' },
  { label: 'Querecotillo', value: 'Querecotillo' },
  { label: 'Querência', value: 'Querência' },
  { label: 'Querência Do Norte', value: 'Querência Do Norte' },
  { label: 'Queréndaro', value: 'Queréndaro' },
  { label: 'Querendas', value: 'Querendas' },
  { label: 'Querétaro', value: 'Querétaro' },
  { label: 'Querétaro', value: 'Querétaro' },
  { label: 'Querévalo', value: 'Querévalo' },
  { label: 'Querfurt', value: 'Querfurt' },
  { label: 'Quern', value: 'Quern' },
  { label: 'Quero', value: 'Quero' },
  { label: 'Quero', value: 'Quero' },
  { label: 'Querobabi', value: 'Querobabi' },
  { label: 'Querol', value: 'Querol' },
  { label: 'Queromarca', value: 'Queromarca' },
  { label: 'Querqueville', value: 'Querqueville' },
  { label: 'Querrien', value: 'Querrien' },
  { label: 'Quesa', value: 'Quesa' },
  { label: 'Quesada', value: 'Quesada' },
  { label: 'Quesada', value: 'Quesada' },
  { label: 'Quesada', value: 'Quesada' },
  { label: 'Quesería', value: 'Quesería' },
  { label: 'Quesnel', value: 'Quesnel' },
  { label: 'Quesnoy-Sur-Deûle', value: 'Quesnoy-Sur-Deûle' },
  { label: 'Quessoy', value: 'Quessoy' },
  { label: 'Questa', value: 'Questa' },
  { label: 'Questembert', value: 'Questembert' },
  { label: 'Quetame', value: 'Quetame' },
  { label: 'Quetchehueca', value: 'Quetchehueca' },
  { label: 'Quetigny', value: 'Quetigny' },
  { label: 'Quetta', value: 'Quetta' },
  { label: 'Quetta District', value: 'Quetta District' },
  { label: 'Quettehou', value: 'Quettehou' },
  { label: 'Quettreville-Sur-Sienne', value: 'Quettreville-Sur-Sienne' },
  { label: 'Quetzalapa', value: 'Quetzalapa' },
  { label: 'Quetzalapa', value: 'Quetzalapa' },
  { label: 'Quetzaltenango', value: 'Quetzaltenango' },
  { label: 'Queula', value: 'Queula' },
  { label: 'Quevauvillers', value: 'Quevauvillers' },
  { label: 'Quevedo', value: 'Quevedo' },
  { label: 'Quevedos', value: 'Quevedos' },
  { label: 'Quéven', value: 'Quéven' },
  { label: 'Quévert', value: 'Quévert' },
  { label: 'Quévy-Le-Petit', value: 'Quévy-Le-Petit' },
  { label: 'Quezailica', value: 'Quezailica' },
  { label: 'Quezalguaque', value: 'Quezalguaque' },
  { label: 'Quezaltepeque', value: 'Quezaltepeque' },
  { label: 'Quezaltepeque', value: 'Quezaltepeque' },
  { label: 'Quezon', value: 'Quezon' },
  { label: 'Quezon', value: 'Quezon' },
  { label: 'Quezon', value: 'Quezon' },
  { label: 'Quezon', value: 'Quezon' },
  { label: 'Quezon', value: 'Quezon' },
  { label: 'Quezon', value: 'Quezon' },
  { label: 'Quezon', value: 'Quezon' },
  { label: 'Quezon', value: 'Quezon' },
  { label: 'Quezon', value: 'Quezon' },
  { label: 'Quezon City', value: 'Quezon City' },
  { label: 'Qufar', value: 'Qufar' },
  { label: 'Qufu', value: 'Qufu' },
  { label: 'Qui Nhon', value: 'Qui Nhon' },
  { label: 'Quiamoloapan', value: 'Quiamoloapan' },
  { label: 'Quiapo', value: 'Quiapo' },
  { label: 'Quibal', value: 'Quibal' },
  { label: 'Quibal', value: 'Quibal' },
  { label: 'Quibala', value: 'Quibala' },
  { label: 'Quibdó', value: 'Quibdó' },
  { label: 'Quiberon', value: 'Quiberon' },
  { label: 'Quíbor', value: 'Quíbor' },
  { label: 'Quicena', value: 'Quicena' },
  { label: 'Quick Step', value: 'Quick Step' },
  { label: 'Quickborn', value: 'Quickborn' },
  { label: 'Quierschied', value: 'Quierschied' },
  { label: 'Quiers-Sur-Bézonde', value: 'Quiers-Sur-Bézonde' },
  { label: 'Quiévrain', value: 'Quiévrain' },
  { label: 'Quiévrechain', value: 'Quiévrechain' },
  { label: 'Quiévy', value: 'Quiévy' },
  { label: 'Quiindy', value: 'Quiindy' },
  { label: 'Quijingue', value: 'Quijingue' },
  { label: 'Quijorna', value: 'Quijorna' },
  { label: 'Quila', value: 'Quila' },
  { label: 'Quilaco', value: 'Quilaco' },
  { label: 'Quilahuani', value: 'Quilahuani' },
  { label: 'Quilalí', value: 'Quilalí' },
  { label: 'Quilengues', value: 'Quilengues' },
  { label: 'Quiliano', value: 'Quiliano' },
  { label: 'Quilicura', value: 'Quilicura' },
  { label: 'Quiling', value: 'Quiling' },
  { label: 'Quiling', value: 'Quiling' },
  { label: 'Quilino', value: 'Quilino' },
  { label: 'Quillacollo', value: 'Quillacollo' },
  { label: 'Quillan', value: 'Quillan' },
  { label: 'Quillebeuf-Sur-Seine', value: 'Quillebeuf-Sur-Seine' },
  { label: 'Quilleco', value: 'Quilleco' },
  { label: 'Quillón', value: 'Quillón' },
  { label: 'Quillota', value: 'Quillota' },
  { label: 'Quilly', value: 'Quilly' },
  { label: 'Quilmaná', value: 'Quilmaná' },
  { label: 'Quilombo', value: 'Quilombo' },
  { label: 'Quilo-Quilo', value: 'Quilo-Quilo' },
  { label: 'Quilpie', value: 'Quilpie' },
  { label: 'Quilpué', value: 'Quilpué' },
  { label: 'Quimbaya', value: 'Quimbaya' },
  { label: 'Quime', value: 'Quime' },
  { label: 'Quimichis', value: 'Quimichis' },
  { label: 'Quimilí', value: 'Quimilí' },
  { label: 'Quimistán', value: 'Quimistán' },
  { label: 'Quimper', value: 'Quimper' },
  { label: 'Quimperlé', value: 'Quimperlé' },
  { label: 'Quinabigan', value: 'Quinabigan' },
  { label: 'Quinagaringan', value: 'Quinagaringan' },
  { label: 'Quinapondan', value: 'Quinapondan' },
  { label: 'Quinapundan', value: 'Quinapundan' },
  { label: 'Quinarayan', value: 'Quinarayan' },
  { label: 'Quinarayan', value: 'Quinarayan' },
  { label: 'Quincampoix', value: 'Quincampoix' },
  { label: 'Quinceo', value: 'Quinceo' },
  { label: 'Quincey', value: 'Quincey' },
  { label: 'Quinchao', value: 'Quinchao' },
  { label: 'Quinchía', value: 'Quinchía' },
  { label: 'Quincié-En-Beaujolais', value: 'Quincié-En-Beaujolais' },
  { label: 'Quincieux', value: 'Quincieux' },
  { label: 'Quincinetto', value: 'Quincinetto' },
  { label: 'Quincod', value: 'Quincod' },
  { label: 'Quincy', value: 'Quincy' },
  { label: 'Quincy', value: 'Quincy' },
  { label: 'Quincy', value: 'Quincy' },
  { label: 'Quincy', value: 'Quincy' },
  { label: 'Quincy', value: 'Quincy' },
  { label: 'Quincy', value: 'Quincy' },
  { label: 'Quincy-Sous-Sénart', value: 'Quincy-Sous-Sénart' },
  { label: 'Quincy-Voisins', value: 'Quincy-Voisins' },
  { label: 'Quindalup', value: 'Quindalup' },
  { label: 'Quindici', value: 'Quindici' },
  { label: 'Quinebaug', value: 'Quinebaug' },
  { label: 'Quingentole', value: 'Quingentole' },
  { label: 'Quingey', value: 'Quingey' },
  { label: 'Quinhámel', value: 'Quinhámel' },
  { label: 'Quinicuena', value: 'Quinicuena' },
  { label: 'Quinipot', value: 'Quinipot' },
  { label: 'Quinlan', value: 'Quinlan' },
  { label: 'Quinnesec', value: 'Quinnesec' },
  { label: 'Quinns Rocks', value: 'Quinns Rocks' },
  { label: 'Quiñonería', value: 'Quiñonería' },
  { label: 'Quinsac', value: 'Quinsac' },
  { label: 'Quinssaines', value: 'Quinssaines' },
  { label: 'Quintã De Pêro Martins', value: 'Quintã De Pêro Martins' },
  { label: 'Quinta De Tilcoco', value: 'Quinta De Tilcoco' },
  { label: 'Quinta Del Cedro', value: 'Quinta Del Cedro' },
  { label: 'Quinta Do Anjo', value: 'Quinta Do Anjo' },
  { label: 'Quinta Do Conde', value: 'Quinta Do Conde' },
  { label: 'Quinta Do Sol', value: 'Quinta Do Sol' },
  { label: 'Quinta Normal', value: 'Quinta Normal' },
  { label: 'Quintana', value: 'Quintana' },
  { label: 'Quintana De La Serena', value: 'Quintana De La Serena' },
  { label: 'Quintana Del Castillo', value: 'Quintana Del Castillo' },
  { label: 'Quintana Del Marco', value: 'Quintana Del Marco' },
  { label: 'Quintana Del Pidio', value: 'Quintana Del Pidio' },
  { label: 'Quintana Del Puente', value: 'Quintana Del Puente' },
  { label: 'Quintana Redonda', value: 'Quintana Redonda' },
  { label: 'Quintana Roo', value: 'Quintana Roo' },
  { label: 'Quintana Roo', value: 'Quintana Roo' },
  { label: 'Quintana Y Congosto', value: 'Quintana Y Congosto' },
  { label: 'Quintanabureba', value: 'Quintanabureba' },
  { label: 'Quintanaélez', value: 'Quintanaélez' },
  { label: 'Quintanaortuño', value: 'Quintanaortuño' },
  { label: 'Quintanapalla', value: 'Quintanapalla' },
  { label: 'Quintanar De La Orden', value: 'Quintanar De La Orden' },
  { label: 'Quintanar De La Sierra', value: 'Quintanar De La Sierra' },
  { label: 'Quintanar Del Rey', value: 'Quintanar Del Rey' },
  { label: 'Quintanas De Gormaz', value: 'Quintanas De Gormaz' },
  { label: 'Quintanavides', value: 'Quintanavides' },
  { label: 'Quintanilla De Arriba', value: 'Quintanilla De Arriba' },
  { label: 'Quintanilla De La Mata', value: 'Quintanilla De La Mata' },
  { label: 'Quintanilla De Onésimo', value: 'Quintanilla De Onésimo' },
  { label: 'Quintanilla De Onsoña', value: 'Quintanilla De Onsoña' },
  { label: 'Quintanilla De Trigueros', value: 'Quintanilla De Trigueros' },
  { label: 'Quintanilla De Urz', value: 'Quintanilla De Urz' },
  { label: 'Quintanilla Del Agua Y Tordueles', value: 'Quintanilla Del Agua Y Tordueles' },
  { label: 'Quintanilla Del Coco', value: 'Quintanilla Del Coco' },
  { label: 'Quintanilla Del Molar', value: 'Quintanilla Del Molar' },
  { label: 'Quintanilla Del Monte', value: 'Quintanilla Del Monte' },
  { label: 'Quintanilla Del Olmo', value: 'Quintanilla Del Olmo' },
  { label: 'Quintanilla San García', value: 'Quintanilla San García' },
  { label: 'Quintanilla Vivar', value: 'Quintanilla Vivar' },
  { label: 'Quintanillas Las', value: 'Quintanillas Las' },
  { label: 'Quintano', value: 'Quintano' },
  { label: 'Quintas De São Bartolomeu', value: 'Quintas De São Bartolomeu' },
  { label: 'Quinte West', value: 'Quinte West' },
  { label: 'Quintela De Leirado', value: 'Quintela De Leirado' },
  { label: 'Quintenas', value: 'Quintenas' },
  { label: 'Quinteño', value: 'Quinteño' },
  { label: 'Quintero', value: 'Quintero' },
  { label: 'Quintero', value: 'Quintero' },
  { label: 'Quintin', value: 'Quintin' },
  { label: 'Quintín Arauz', value: 'Quintín Arauz' },
  { label: 'Quinto', value: 'Quinto' },
  { label: 'Quinto Barrio (Ejido Cahuacán)', value: 'Quinto Barrio (Ejido Cahuacán)' },
  { label: 'Quinto Di Treviso', value: 'Quinto Di Treviso' },
  { label: 'Quinto Vercellese', value: 'Quinto Vercellese' },
  { label: 'Quinto Vicentino', value: 'Quinto Vicentino' },
  { label: 'Quinton', value: 'Quinton' },
  { label: 'Quintong', value: 'Quintong' },
  { label: 'Quintong', value: 'Quintong' },
  { label: 'Quinzano Doglio', value: 'Quinzano Doglio' },
  { label: 'Quinze De Novembro', value: 'Quinze De Novembro' },
  { label: 'Quípama', value: 'Quípama' },
  { label: 'Quipapá', value: 'Quipapá' },
  { label: 'Quipile', value: 'Quipile' },
  { label: 'Quipot', value: 'Quipot' },
  { label: 'Quipot', value: 'Quipot' },
  { label: 'Quipungo', value: 'Quipungo' },
  { label: 'Quiriego', value: 'Quiriego' },
  { label: 'Quirihue', value: 'Quirihue' },
  { label: 'Quirijnstok', value: 'Quirijnstok' },
  { label: 'Quirindi', value: 'Quirindi' },
  { label: 'Quirino', value: 'Quirino' },
  { label: 'Quirino', value: 'Quirino' },
  { label: 'Quirino', value: 'Quirino' },
  { label: 'Quirino', value: 'Quirino' },
  { label: 'Quirinópolis', value: 'Quirinópolis' },
  { label: 'Quiroga', value: 'Quiroga' },
  { label: 'Quiroga', value: 'Quiroga' },
  { label: 'Quirós', value: 'Quirós' },
  { label: 'Quiruelas De Vidriales', value: 'Quiruelas De Vidriales' },
  { label: 'Quiruvilca', value: 'Quiruvilca' },
  { label: 'Quisao', value: 'Quisao' },
  { label: 'Quismondo', value: 'Quismondo' },
  { label: 'Quisqueya', value: 'Quisqueya' },
  { label: 'Quissac', value: 'Quissac' },
  { label: 'Quissamã', value: 'Quissamã' },
  { label: 'Quistello', value: 'Quistello' },
  { label: 'Quistinic', value: 'Quistinic' },
  { label: 'Quitandinha', value: 'Quitandinha' },
  { label: 'Quitang', value: 'Quitang' },
  { label: 'Quiterianópolis', value: 'Quiterianópolis' },
  { label: 'Quitilipi', value: 'Quitilipi' },
  { label: 'Quitman', value: 'Quitman' },
  { label: 'Quitman', value: 'Quitman' },
  { label: 'Quitman', value: 'Quitman' },
  { label: 'Quitman County', value: 'Quitman County' },
  { label: 'Quitman County', value: 'Quitman County' },
  { label: 'Quito', value: 'Quito' },
  { label: 'Quitupan', value: 'Quitupan' },
  { label: 'Quitzdorf', value: 'Quitzdorf' },
  { label: 'Quivicán', value: 'Quivicán' },
  { label: 'Quixaba', value: 'Quixaba' },
  { label: 'Quixaba', value: 'Quixaba' },
  { label: 'Quixabeira', value: 'Quixabeira' },
  { label: 'Quixadá', value: 'Quixadá' },
  { label: 'Quixelô', value: 'Quixelô' },
  { label: 'Quixeramobim', value: 'Quixeramobim' },
  { label: 'Quixeré', value: 'Quixeré' },
  { label: 'Qujing', value: 'Qujing' },
  { label: 'Qulensya Wa Abd Al Kuri', value: 'Qulensya Wa Abd Al Kuri' },
  { label: 'Qulsary', value: 'Qulsary' },
  { label: 'Qumaym', value: 'Qumaym' },
  { label: 'Quorn', value: 'Quorn' },
  { label: 'Quorndon', value: 'Quorndon' },
  { label: 'Qurayyat', value: 'Qurayyat' },
  { label: 'Qŭrghontepa Tumani', value: 'Qŭrghontepa Tumani' },
  { label: 'Qŭrghonteppa', value: 'Qŭrghonteppa' },
  { label: 'Quruqsoy', value: 'Quruqsoy' },
  { label: 'Qusar', value: 'Qusar' },
  { label: 'Qushchi', value: 'Qushchi' },
  { label: 'Qushjeh', value: 'Qushjeh' },
  { label: 'Qushkhaneh', value: 'Qushkhaneh' },
  { label: 'Qŭshkŭpir', value: 'Qŭshkŭpir' },
  { label: 'Qusmuryn', value: 'Qusmuryn' },
  { label: 'Quthbullapur', value: 'Quthbullapur' },
  { label: 'Quthing', value: 'Quthing' },
  { label: 'Qutqashen', value: 'Qutqashen' },
  { label: 'Quţūr', value: 'Quţūr' },
  { label: 'Quva', value: 'Quva' },
  { label: 'Quvasoy', value: 'Quvasoy' },
  { label: 'Quwayda', value: 'Quwayda' },
  { label: 'Quwaysinā', value: 'Quwaysinā' },
  { label: 'Quwayzah', value: 'Quwayzah' },
  { label: 'Quzhou', value: 'Quzhou' },
  { label: 'Qvareli', value: 'Qvareli' },
  { label: 'Ra Province', value: 'Ra Province' },
  { label: 'Ra’S Bayrūt', value: 'Ra’S Bayrūt' },
  { label: 'Raab', value: 'Raab' },
  { label: 'Raaba', value: 'Raaba' },
  { label: 'Raabau', value: 'Raabau' },
  { label: 'Raabs An Der Thaya', value: 'Raabs An Der Thaya' },
  { label: 'Raach Am Hochgebirge', value: 'Raach Am Hochgebirge' },
  { label: 'Raahe', value: 'Raahe' },
  { label: 'Rääkkylä', value: 'Rääkkylä' },
  { label: 'Raalte', value: 'Raalte' },
  { label: 'Raam', value: 'Raam' },
  { label: 'Raamsdonk', value: 'Raamsdonk' },
  { label: 'Raamsdonksveer', value: 'Raamsdonksveer' },
  { label: 'Raanana', value: 'Raanana' },
  { label: 'Raarvihke - Røyrvik', value: 'Raarvihke - Røyrvik' },
  { label: 'Raasdorf', value: 'Raasdorf' },
  { label: 'Raaseporin', value: 'Raaseporin' },
  { label: 'Raasiku', value: 'Raasiku' },
  { label: 'Rab', value: 'Rab' },
  { label: 'Raba Wyżna', value: 'Raba Wyżna' },
  { label: 'Rabac', value: 'Rabac' },
  { label: 'Rabaçal', value: 'Rabaçal' },
  { label: 'Rábade', value: 'Rábade' },
  { label: 'Răbăgani', value: 'Răbăgani' },
  { label: 'Rabah', value: 'Rabah' },
  { label: 'Rabak', value: 'Rabak' },
  { label: 'Rabanales', value: 'Rabanales' },
  { label: 'Rabanera', value: 'Rabanera' },
  { label: 'Rabanera Del Pinar', value: 'Rabanera Del Pinar' },
  { label: 'Rábano', value: 'Rábano' },
  { label: 'Rábano De Aliste', value: 'Rábano De Aliste' },
  { label: 'Rábanos', value: 'Rábanos' },
  { label: 'Rábanos Los', value: 'Rábanos Los' },
  { label: 'Rábapatona', value: 'Rábapatona' },
  { label: 'Rabastens', value: 'Rabastens' },
  { label: 'Rabastens-De-Bigorre', value: 'Rabastens-De-Bigorre' },
  { label: 'Rabat', value: 'Rabat' },
  { label: 'Rabat', value: 'Rabat' },
  { label: 'Rabat', value: 'Rabat' },
  { label: 'Rabaul', value: 'Rabaul' },
  { label: 'Rabbi Fonti', value: 'Rabbi Fonti' },
  { label: 'Rabé De Las Calzadas', value: 'Rabé De Las Calzadas' },
  { label: 'Raben Steinfeld', value: 'Raben Steinfeld' },
  { label: 'Rabenau', value: 'Rabenau' },
  { label: 'Rabenau', value: 'Rabenau' },
  { label: 'Rabensburg', value: 'Rabensburg' },
  { label: 'Rabenstein An Der Pielach', value: 'Rabenstein An Der Pielach' },
  { label: 'Rabenwald', value: 'Rabenwald' },
  { label: 'Rābigh', value: 'Rābigh' },
  { label: 'Rabinal', value: 'Rabinal' },
  { label: 'Rąbino', value: 'Rąbino' },
  { label: 'Rabkavi', value: 'Rabkavi' },
  { label: 'Rabka-Zdrój', value: 'Rabka-Zdrój' },
  { label: 'Rablà', value: 'Rablà' },
  { label: 'Rabo De Peixe', value: 'Rabo De Peixe' },
  { label: 'Rabocheostrovsk', value: 'Rabocheostrovsk' },
  { label: 'Rabor', value: 'Rabor' },
  { label: 'Rabós', value: 'Rabós' },
  { label: 'Rabot', value: 'Rabot' },
  { label: 'Rabotki', value: 'Rabotki' },
  { label: 'Rabun County', value: 'Rabun County' },
  { label: 'Rabupura', value: 'Rabupura' },
  { label: 'Rabwah', value: 'Rabwah' },
  { label: 'Rabwah Ghran', value: 'Rabwah Ghran' },
  { label: 'Raby', value: 'Raby' },
  { label: 'Râca', value: 'Râca' },
  { label: 'Răcăciuni', value: 'Răcăciuni' },
  { label: 'Racale', value: 'Racale' },
  { label: 'Rácalmás', value: 'Rácalmás' },
  { label: 'Racalmuto', value: 'Racalmuto' },
  { label: 'Racameț', value: 'Racameț' },
  { label: 'Răcari', value: 'Răcari' },
  { label: 'Răcarii De Sus', value: 'Răcarii De Sus' },
  { label: 'Răcăşdia', value: 'Răcăşdia' },
  { label: 'Răcăuți', value: 'Răcăuți' },
  { label: 'Racconigi', value: 'Racconigi' },
  { label: 'Raccuja', value: 'Raccuja' },
  { label: 'Rače', value: 'Rače' },
  { label: 'Race Course', value: 'Race Course' },
  { label: 'Raceland', value: 'Raceland' },
  { label: 'Raceland', value: 'Raceland' },
  { label: 'Raceview', value: 'Raceview' },
  { label: 'Rạch Giá', value: 'Rạch Giá' },
  { label: 'Rachanie', value: 'Rachanie' },
  { label: 'Rachau', value: 'Rachau' },
  { label: 'Rachelu', value: 'Rachelu' },
  { label: 'Râches', value: 'Râches' },
  { label: 'Rachieri', value: 'Rachieri' },
  { label: 'Răchita', value: 'Răchita' },
  { label: 'Răchiteni', value: 'Răchiteni' },
  { label: 'Răchiţi', value: 'Răchiţi' },
  { label: 'Răchitoasa', value: 'Răchitoasa' },
  { label: 'Răchitova', value: 'Răchitova' },
  { label: 'Rachuonyo District', value: 'Rachuonyo District' },
  { label: 'Raciąż', value: 'Raciąż' },
  { label: 'Raciążek', value: 'Raciążek' },
  { label: 'Racibórz', value: 'Racibórz' },
  { label: 'Racine', value: 'Racine' },
  { label: 'Racine County', value: 'Racine County' },
  { label: 'Racines', value: 'Racines' },
  { label: 'Racines - Ratschings', value: 'Racines - Ratschings' },
  { label: 'Raciu', value: 'Raciu' },
  { label: 'Râciu', value: 'Râciu' },
  { label: 'Räckelwitz', value: 'Räckelwitz' },
  { label: 'Ráckeresztúr', value: 'Ráckeresztúr' },
  { label: 'Ráckeve', value: 'Ráckeve' },
  { label: 'Ráckevei Járás', value: 'Ráckevei Járás' },
  { label: 'Rackheath', value: 'Rackheath' },
  { label: 'Rackwitz', value: 'Rackwitz' },
  { label: 'Racławice', value: 'Racławice' },
  { label: 'Racławice', value: 'Racławice' },
  { label: 'Racławice Śląskie', value: 'Racławice Śląskie' },
  { label: 'Răcoasa', value: 'Răcoasa' },
  { label: 'Racoș', value: 'Racoș' },
  { label: 'Racova', value: 'Racova' },
  { label: 'Racoviţa', value: 'Racoviţa' },
  { label: 'Racoviţa', value: 'Racoviţa' },
  { label: 'Racoviţa', value: 'Racoviţa' },
  { label: 'Racoviţa', value: 'Racoviţa' },
  { label: 'Racovița', value: 'Racovița' },
  { label: 'Racovița', value: 'Racovița' },
  { label: 'Racoviţeni', value: 'Racoviţeni' },
  { label: 'Racquinghem', value: 'Racquinghem' },
  { label: 'Racşa', value: 'Racşa' },
  { label: 'Racu', value: 'Racu' },
  { label: 'Raczki', value: 'Raczki' },
  { label: 'Rączna', value: 'Rączna' },
  { label: 'Rada De Haro', value: 'Rada De Haro' },
  { label: 'Rada Tilly', value: 'Rada Tilly' },
  { label: 'Radā‘', value: 'Radā‘' },
  { label: 'Rădăşeni', value: 'Rădăşeni' },
  { label: 'Radashkovichy', value: 'Radashkovichy' },
  { label: 'Radaur', value: 'Radaur' },
  { label: 'Rădăuți', value: 'Rădăuți' },
  { label: 'Rădăuți-Prut', value: 'Rădăuți-Prut' },
  { label: 'Radbruch', value: 'Radbruch' },
  { label: 'Radcha', value: 'Radcha' },
  { label: 'Radchenko', value: 'Radchenko' },
  { label: 'Radcliff', value: 'Radcliff' },
  { label: 'Radcliffe', value: 'Radcliffe' },
  { label: 'Radcliffe On Trent', value: 'Radcliffe On Trent' },
  { label: 'Radda In Chianti', value: 'Radda In Chianti' },
  { label: 'Raddestorf', value: 'Raddestorf' },
  { label: 'Raddusa', value: 'Raddusa' },
  { label: 'Råde', value: 'Råde' },
  { label: 'Radeberg', value: 'Radeberg' },
  { label: 'Radebeul', value: 'Radebeul' },
  { label: 'Radeburg', value: 'Radeburg' },
  { label: 'Radeče', value: 'Radeče' },
  { label: 'Radecznica', value: 'Radecznica' },
  { label: 'Radegast', value: 'Radegast' },
  { label: 'Radelfingen', value: 'Radelfingen' },
  { label: 'Radenci', value: 'Radenci' },
  { label: 'Rădeni', value: 'Rădeni' },
  { label: 'Rădeni', value: 'Rădeni' },
  { label: 'Radenka', value: 'Radenka' },
  { label: 'Radenković', value: 'Radenković' },
  { label: 'Radens’K', value: 'Radens’K' },
  { label: 'Radenthein', value: 'Radenthein' },
  { label: 'Radès', value: 'Radès' },
  { label: 'Rădeşti', value: 'Rădeşti' },
  { label: 'Rădești', value: 'Rădești' },
  { label: 'Rădești', value: 'Rădești' },
  { label: 'Radevormwald', value: 'Radevormwald' },
  { label: 'Radfan', value: 'Radfan' },
  { label: 'Radfeld', value: 'Radfeld' },
  { label: 'Radford', value: 'Radford' },
  { label: 'Radgoszcz', value: 'Radgoszcz' },
  { label: 'Radha Kund', value: 'Radha Kund' },
  { label: 'Radhakund', value: 'Radhakund' },
  { label: 'Radhanagari', value: 'Radhanagari' },
  { label: 'Radhanpur', value: 'Radhanpur' },
  { label: 'Radibor', value: 'Radibor' },
  { label: 'Radicofani', value: 'Radicofani' },
  { label: 'Radicondoli', value: 'Radicondoli' },
  { label: 'Radiměř', value: 'Radiměř' },
  { label: 'Radinghem-En-Weppes', value: 'Radinghem-En-Weppes' },
  { label: 'Radis', value: 'Radis' },
  { label: 'Radishani', value: 'Radishani' },
  { label: 'Radishchevo', value: 'Radishchevo' },
  { label: 'Radišići', value: 'Radišići' },
  { label: 'Raditsa-Krylovka', value: 'Raditsa-Krylovka' },
  { label: 'Radium Springs', value: 'Radium Springs' },
  { label: 'Radizel', value: 'Radizel' },
  { label: 'Radków', value: 'Radków' },
  { label: 'Radlett', value: 'Radlett' },
  { label: 'Radley', value: 'Radley' },
  { label: 'Radlin', value: 'Radlin' },
  { label: 'Radlje Ob Dravi', value: 'Radlje Ob Dravi' },
  { label: 'Radłów', value: 'Radłów' },
  { label: 'Radman Al Awad', value: 'Radman Al Awad' },
  { label: 'Radna', value: 'Radna' },
  { label: 'Radnevo', value: 'Radnevo' },
  { label: 'Radnice', value: 'Radnice' },
  { label: 'Radnor', value: 'Radnor' },
  { label: 'Radoboj', value: 'Radoboj' },
  { label: 'Radocza', value: 'Radocza' },
  { label: 'Rădoiești-Deal', value: 'Rădoiești-Deal' },
  { label: 'Radojevo', value: 'Radojevo' },
  { label: 'Radolfzell', value: 'Radolfzell' },
  { label: 'Radolista', value: 'Radolista' },
  { label: 'Radom', value: 'Radom' },
  { label: 'Radomin', value: 'Radomin' },
  { label: 'Radomir', value: 'Radomir' },
  { label: 'Radomir', value: 'Radomir' },
  { label: 'Radomireşti', value: 'Radomireşti' },
  { label: 'Radomlje', value: 'Radomlje' },
  { label: 'Radomsko', value: 'Radomsko' },
  { label: 'Radomyshl', value: 'Radomyshl' },
  { label: 'Radomyshl', value: 'Radomyshl' },
  { label: 'Radomyshl Raion', value: 'Radomyshl Raion' },
  { label: 'Radomyśl', value: 'Radomyśl' },
  { label: 'Radomyšl', value: 'Radomyšl' },
  { label: 'Radomyśl Wielki', value: 'Radomyśl Wielki' },
  { label: 'Radon', value: 'Radon' },
  { label: 'Radonice', value: 'Radonice' },
  { label: 'Radostowice', value: 'Radostowice' },
  { label: 'Radoszyce', value: 'Radoszyce' },
  { label: 'Radovan', value: 'Radovan' },
  { label: 'Radovanu', value: 'Radovanu' },
  { label: 'Radovis', value: 'Radovis' },
  { label: 'Radovitskiy', value: 'Radovitskiy' },
  { label: 'Radovljica', value: 'Radovljica' },
  { label: 'Radovnica', value: 'Radovnica' },
  { label: 'Radowo Małe', value: 'Radowo Małe' },
  { label: 'Radøy', value: 'Radøy' },
  { label: 'Radslavice', value: 'Radslavice' },
  { label: 'Radstadt', value: 'Radstadt' },
  { label: 'Radstock', value: 'Radstock' },
  { label: 'Radu Vodă', value: 'Radu Vodă' },
  { label: 'Răducăneni', value: 'Răducăneni' },
  { label: 'Rădulești', value: 'Rădulești' },
  { label: 'Radumlya', value: 'Radumlya' },
  { label: 'Raduzhny', value: 'Raduzhny' },
  { label: 'Raduzhnyy', value: 'Raduzhnyy' },
  { label: 'Raduzhnyy', value: 'Raduzhnyy' },
  { label: 'Raduzhnyy', value: 'Raduzhnyy' },
  { label: 'Radvanice', value: 'Radvanice' },
  { label: 'Radviliskis', value: 'Radviliskis' },
  { label: 'Radwanice', value: 'Radwanice' },
  { label: 'Radymno', value: 'Radymno' },
  { label: 'Radyr', value: 'Radyr' },
  { label: 'Radyvyliv', value: 'Radyvyliv' },
  { label: 'Radzanów', value: 'Radzanów' },
  { label: 'Radzanowo', value: 'Radzanowo' },
  { label: 'Radziechowy', value: 'Radziechowy' },
  { label: 'Radziejów', value: 'Radziejów' },
  { label: 'Radziejowice', value: 'Radziejowice' },
  { label: 'Radziłów', value: 'Radziłów' },
  { label: 'Radzionków', value: 'Radzionków' },
  { label: 'Radziszów', value: 'Radziszów' },
  { label: 'Radzymin', value: 'Radzymin' },
  { label: 'Radzyń Chełmiński', value: 'Radzyń Chełmiński' },
  { label: 'Radzyń Podlaski', value: 'Radzyń Podlaski' },
  { label: 'Rae Town', value: 'Rae Town' },
  { label: 'Rae Vald', value: 'Rae Vald' },
  { label: 'Raebareli', value: 'Raebareli' },
  { label: 'Raedersheim', value: 'Raedersheim' },
  { label: 'Raeford', value: 'Raeford' },
  { label: 'Rælingen', value: 'Rælingen' },
  { label: 'Raeren', value: 'Raeren' },
  { label: 'Raesfeld', value: 'Raesfeld' },
  { label: 'Rafael Capo', value: 'Rafael Capo' },
  { label: 'Rafael Delgado', value: 'Rafael Delgado' },
  { label: 'Rafael Fernandes', value: 'Rafael Fernandes' },
  { label: 'Rafael Godeiro', value: 'Rafael Godeiro' },
  { label: 'Rafael Gonzalez', value: 'Rafael Gonzalez' },
  { label: 'Rafael Hernandez', value: 'Rafael Hernandez' },
  { label: 'Rafael J. García', value: 'Rafael J. García' },
  { label: 'Rafael Jambeiro', value: 'Rafael Jambeiro' },
  { label: 'Rafael Lucio', value: 'Rafael Lucio' },
  { label: 'Rafael Pascacio Gamboa', value: 'Rafael Pascacio Gamboa' },
  { label: 'Rafael Perazza', value: 'Rafael Perazza' },
  { label: 'Rafael Ramírez', value: 'Rafael Ramírez' },
  { label: 'Rafael Yáñez Sosa (El Mezquite)', value: 'Rafael Yáñez Sosa (El Mezquite)' },
  { label: 'Rafaela', value: 'Rafaela' },
  { label: 'Rafai', value: 'Rafai' },
  { label: 'Rafaila', value: 'Rafaila' },
  { label: 'Rafal', value: 'Rafal' },
  { label: 'Ráfales', value: 'Ráfales' },
  { label: 'Rafard', value: 'Rafard' },
  { label: 'Rafelbuñol/Rafelbunyol', value: 'Rafelbuñol/Rafelbunyol' },
  { label: 'Rafelcofer', value: 'Rafelcofer' },
  { label: 'Rafelguaraf', value: 'Rafelguaraf' },
  { label: 'Raffa', value: 'Raffa' },
  { label: 'Raffadali', value: 'Raffadali' },
  { label: 'Raffelstetten', value: 'Raffelstetten' },
  { label: 'Raffingora', value: 'Raffingora' },
  { label: 'Rafiganj', value: 'Rafiganj' },
  { label: 'Rafína', value: 'Rafína' },
  { label: 'Ràfol Dalmúnia El', value: 'Ràfol Dalmúnia El' },
  { label: 'Ráfol De Salem', value: 'Ráfol De Salem' },
  { label: 'Râfov', value: 'Râfov' },
  { label: 'Rafrāf', value: 'Rafrāf' },
  { label: 'Rafsanjan', value: 'Rafsanjan' },
  { label: 'Rafter J Ranch', value: 'Rafter J Ranch' },
  { label: 'Rafz', value: 'Rafz' },
  { label: 'Ragalna', value: 'Ragalna' },
  { label: 'Rágama', value: 'Rágama' },
  { label: 'Ragan Norte', value: 'Ragan Norte' },
  { label: 'Ragan Norte', value: 'Ragan Norte' },
  { label: 'Ragandang', value: 'Ragandang' },
  { label: 'Ragandang', value: 'Ragandang' },
  { label: 'Ragay', value: 'Ragay' },
  { label: 'Raggal', value: 'Raggal' },
  { label: 'Rāghistān', value: 'Rāghistān' },
  { label: 'Raghogarh', value: 'Raghogarh' },
  { label: 'Raghudebbati', value: 'Raghudebbati' },
  { label: 'Raghunathpur', value: 'Raghunathpur' },
  { label: 'Raghunathpur', value: 'Raghunathpur' },
  { label: 'Raghwan', value: 'Raghwan' },
  { label: 'Raglan', value: 'Raglan' },
  { label: 'Raglan', value: 'Raglan' },
  { label: 'Raglan', value: 'Raglan' },
  { label: 'Ragland', value: 'Ragland' },
  { label: 'Ragnitz', value: 'Ragnitz' },
  { label: 'Rágol', value: 'Rágol' },
  { label: 'Ragoli', value: 'Ragoli' },
  { label: 'Ragonvalia', value: 'Ragonvalia' },
  { label: 'Raguhn', value: 'Raguhn' },
  { label: 'Ragusa', value: 'Ragusa' },
  { label: 'Raha', value: 'Raha' },
  { label: 'Rahabah', value: 'Rahabah' },
  { label: 'Rahachow', value: 'Rahachow' },
  { label: 'Rahachowski Rayon', value: 'Rahachowski Rayon' },
  { label: 'Rahat', value: 'Rahat' },
  { label: 'Rahata', value: 'Rahata' },
  { label: 'Rahatgarh', value: 'Rahatgarh' },
  { label: 'Rahden', value: 'Rahden' },
  { label: 'Raheny', value: 'Raheny' },
  { label: 'Rahim Yar Khan', value: 'Rahim Yar Khan' },
  { label: 'Rahimabad', value: 'Rahimabad' },
  { label: 'Raḩīmah', value: 'Raḩīmah' },
  { label: 'Rahimatpur', value: 'Rahimatpur' },
  { label: 'Rahimyar Khan District', value: 'Rahimyar Khan District' },
  { label: 'Rahmanabad Malayer', value: 'Rahmanabad Malayer' },
  { label: 'Rahnsdorf', value: 'Rahnsdorf' },
  { label: 'Råholt', value: 'Råholt' },
  { label: 'Rahon', value: 'Rahon' },
  { label: 'Rahuri', value: 'Rahuri' },
  { label: 'Rahwan', value: 'Rahwan' },
  { label: 'Rahway', value: 'Rahway' },
  { label: 'Rai', value: 'Rai' },
  { label: 'Rai Coast', value: 'Rai Coast' },
  { label: 'Raia', value: 'Raia' },
  { label: 'Raiano', value: 'Raiano' },
  { label: 'Raichur', value: 'Raichur' },
  { label: 'Raiding', value: 'Raiding' },
  { label: 'Raiganj', value: 'Raiganj' },
  { label: 'Raigarh', value: 'Raigarh' },
  { label: 'Raigarh', value: 'Raigarh' },
  { label: 'Raikot', value: 'Raikot' },
  { label: 'Railaco', value: 'Railaco' },
  { label: 'Raillencourt-Sainte-Olle', value: 'Raillencourt-Sainte-Olle' },
  { label: 'Railway Estate', value: 'Railway Estate' },
  { label: 'Raimbeaucourt', value: 'Raimbeaucourt' },
  { label: 'Rain', value: 'Rain' },
  { label: 'Rain', value: 'Rain' },
  { label: 'Rainbach Im Mühlkreis', value: 'Rainbach Im Mühlkreis' },
  { label: 'Rainbow', value: 'Rainbow' },
  { label: 'Rainbow Beach', value: 'Rainbow Beach' },
  { label: 'Rainbow City', value: 'Rainbow City' },
  { label: 'Rainelle', value: 'Rainelle' },
  { label: 'Rainford', value: 'Rainford' },
  { label: 'Rainham', value: 'Rainham' },
  { label: 'Rainier', value: 'Rainier' },
  { label: 'Rainier', value: 'Rainier' },
  { label: 'Rains County', value: 'Rains County' },
  { label: 'Rainsville', value: 'Rainsville' },
  { label: 'Rainworth', value: 'Rainworth' },
  { label: 'Rainy River District', value: 'Rainy River District' },
  { label: 'Raipur', value: 'Raipur' },
  { label: 'Raipur', value: 'Raipur' },
  { label: 'Raipur', value: 'Raipur' },
  { label: 'Raireshwar', value: 'Raireshwar' },
  { label: 'Rairiz De Veiga', value: 'Rairiz De Veiga' },
  { label: 'Raisdorf', value: 'Raisdorf' },
  { label: 'Raisen', value: 'Raisen' },
  { label: 'Raisinghnagar', value: 'Raisinghnagar' },
  { label: 'Raisio', value: 'Raisio' },
  { label: 'Raismes', value: 'Raismes' },
  { label: 'Raisting', value: 'Raisting' },
  { label: 'Raitenbuch', value: 'Raitenbuch' },
  { label: 'Raiwala Bara', value: 'Raiwala Bara' },
  { label: 'Raiwind', value: 'Raiwind' },
  { label: 'Raj Nandgaon', value: 'Raj Nandgaon' },
  { label: 'Raja Jang', value: 'Raja Jang' },
  { label: 'Rajadell', value: 'Rajadell' },
  { label: 'Rajahmundry', value: 'Rajahmundry' },
  { label: 'Rajakhera', value: 'Rajakhera' },
  { label: 'Rajal Norte', value: 'Rajal Norte' },
  { label: 'Rajal Norte', value: 'Rajal Norte' },
  { label: 'Rajaldesar', value: 'Rajaldesar' },
  { label: 'Rajanna Sircilla', value: 'Rajanna Sircilla' },
  { label: 'Rajanpur', value: 'Rajanpur' },
  { label: 'Rajaori', value: 'Rajaori' },
  { label: 'Rajapalaiyam', value: 'Rajapalaiyam' },
  { label: 'Rajapolah', value: 'Rajapolah' },
  { label: 'Rajapur', value: 'Rajapur' },
  { label: 'Rajapur', value: 'Rajapur' },
  { label: 'Rajasansi', value: 'Rajasansi' },
  { label: 'Rajauri', value: 'Rajauri' },
  { label: 'Rajbari ', value: 'Rajbari ' },
  { label: 'Rajcza', value: 'Rajcza' },
  { label: 'Rajec', value: 'Rajec' },
  { label: 'Rájec-Jestřebí', value: 'Rájec-Jestřebí' },
  { label: 'Ráječko', value: 'Ráječko' },
  { label: 'Rajgarh', value: 'Rajgarh' },
  { label: 'Rajgarh', value: 'Rajgarh' },
  { label: 'Rajgarh', value: 'Rajgarh' },
  { label: 'Rajgir', value: 'Rajgir' },
  { label: 'Rajgród', value: 'Rajgród' },
  { label: 'Rajgurunagar', value: 'Rajgurunagar' },
  { label: 'Rajhrad', value: 'Rajhrad' },
  { label: 'Rajhradice', value: 'Rajhradice' },
  { label: 'Rajince', value: 'Rajince' },
  { label: 'Rajka', value: 'Rajka' },
  { label: 'Rajkot', value: 'Rajkot' },
  { label: 'Rajmahal', value: 'Rajmahal' },
  { label: 'Rajnagar', value: 'Rajnagar' },
  { label: 'Rajnagar Community Development Block', value: 'Rajnagar Community Development Block' },
  { label: 'Rajo Khanani', value: 'Rajo Khanani' },
  { label: 'Rajpipla', value: 'Rajpipla' },
  { label: 'Rajpur', value: 'Rajpur' },
  { label: 'Rajpura', value: 'Rajpura' },
  { label: 'Rajsamand', value: 'Rajsamand' },
  { label: 'Rajshahi ', value: 'Rajshahi ' },
  { label: 'Rajsko', value: 'Rajsko' },
  { label: 'Rajula', value: 'Rajula' },
  { label: 'Rajur', value: 'Rajur' },
  { label: 'Rajura', value: 'Rajura' },
  { label: 'Rajuzah', value: 'Rajuzah' },
  { label: 'Rakaia', value: 'Rakaia' },
  { label: 'Rakamaz', value: 'Rakamaz' },
  { label: 'Rakaw', value: 'Rakaw' },
  { label: 'Rakek', value: 'Rakek' },
  { label: 'Rakhiv', value: 'Rakhiv' },
  { label: 'Rakhiv Raion', value: 'Rakhiv Raion' },
  { label: 'Rakhmanovo', value: 'Rakhmanovo' },
  { label: 'Rakhyah', value: 'Rakhyah' },
  { label: 'Rakičan', value: 'Rakičan' },
  { label: 'Rakitje', value: 'Rakitje' },
  { label: 'Rakitnoye', value: 'Rakitnoye' },
  { label: 'Rakitovo', value: 'Rakitovo' },
  { label: 'Rakityanskiy Rayon', value: 'Rakityanskiy Rayon' },
  { label: 'Rakiv Lis', value: 'Rakiv Lis' },
  { label: 'Rakke', value: 'Rakke' },
  { label: 'Rakkestad', value: 'Rakkestad' },
  { label: 'Rákóczifalva', value: 'Rákóczifalva' },
  { label: 'Rákócziújfalu', value: 'Rákócziújfalu' },
  { label: 'Rakoniewice', value: 'Rakoniewice' },
  { label: 'Rakops', value: 'Rakops' },
  { label: 'Rakoshyno', value: 'Rakoshyno' },
  { label: 'Rakov Potok', value: 'Rakov Potok' },
  { label: 'Rakovník', value: 'Rakovník' },
  { label: 'Rakovski', value: 'Rakovski' },
  { label: 'Raków', value: 'Raków' },
  { label: 'Rakszawa', value: 'Rakszawa' },
  { label: 'Rakvere', value: 'Rakvere' },
  { label: 'Rakvere Vald', value: 'Rakvere Vald' },
  { label: 'Rakvice', value: 'Rakvice' },
  { label: 'Raldon', value: 'Raldon' },
  { label: 'Ralegaon', value: 'Ralegaon' },
  { label: 'Raleigh', value: 'Raleigh' },
  { label: 'Raleigh', value: 'Raleigh' },
  { label: 'Raleigh County', value: 'Raleigh County' },
  { label: 'Raleigh Hills', value: 'Raleigh Hills' },
  { label: 'Ralingen', value: 'Ralingen' },
  { label: 'Ralivka', value: 'Ralivka' },
  { label: 'Ralls', value: 'Ralls' },
  { label: 'Ralls County', value: 'Ralls County' },
  { label: 'Ralston', value: 'Ralston' },
  { label: 'Ram Das', value: 'Ram Das' },
  { label: 'Ramacca', value: 'Ramacca' },
  { label: 'Ramachandrapuram', value: 'Ramachandrapuram' },
  { label: 'Ramada', value: 'Ramada' },
  { label: 'Ramada', value: 'Ramada' },
  { label: 'Ramadi', value: 'Ramadi' },
  { label: 'Ramagundam', value: 'Ramagundam' },
  { label: 'Ramain', value: 'Ramain' },
  { label: 'Ramales De La Victoria', value: 'Ramales De La Victoria' },
  { label: 'Ramalhal', value: 'Ramalhal' },
  { label: 'Ramamangalam', value: 'Ramamangalam' },
  { label: 'Ramana', value: 'Ramana' },
  { label: 'Ramanagara', value: 'Ramanagara' },
  { label: 'Ramanathapuram', value: 'Ramanathapuram' },
  { label: 'Ramanavichy', value: 'Ramanavichy' },
  { label: 'Ramanayyapeta', value: 'Ramanayyapeta' },
  { label: 'Ramanuj Ganj', value: 'Ramanuj Ganj' },
  { label: 'Ramanum Municipality', value: 'Ramanum Municipality' },
  { label: 'Ramapuram', value: 'Ramapuram' },
  { label: 'Ramat Gan', value: 'Ramat Gan' },
  { label: 'Ramat Hasharon', value: 'Ramat Hasharon' },
  { label: 'Ramat Yishay', value: 'Ramat Yishay' },
  { label: 'Ramatuelle', value: 'Ramatuelle' },
  { label: 'Ramban', value: 'Ramban' },
  { label: 'Ramberg', value: 'Ramberg' },
  { label: 'Rambervillers', value: 'Rambervillers' },
  { label: 'Rambha', value: 'Rambha' },
  { label: 'Rambin', value: 'Rambin' },
  { label: 'Rambla La', value: 'Rambla La' },
  { label: 'Ramble', value: 'Ramble' },
  { label: 'Ramble', value: 'Ramble' },
  { label: 'Ramblewood', value: 'Ramblewood' },
  { label: 'Rambouillet', value: 'Rambouillet' },
  { label: 'Rambrouch', value: 'Rambrouch' },
  { label: 'Ramchandrapur', value: 'Ramchandrapur' },
  { label: 'Rameau', value: 'Rameau' },
  { label: 'Ramechhap', value: 'Ramechhap' },
  { label: 'Ramela', value: 'Ramela' },
  { label: 'Ramelton', value: 'Ramelton' },
  { label: 'Ramenki', value: 'Ramenki' },
  { label: 'Ramenskiy Rayon', value: 'Ramenskiy Rayon' },
  { label: 'Ramenskoye', value: 'Ramenskoye' },
  { label: 'Ramerberg', value: 'Ramerberg' },
  { label: 'Rameshki', value: 'Rameshki' },
  { label: 'Rameshkovskiy Rayon', value: 'Rameshkovskiy Rayon' },
  { label: 'Râmești', value: 'Râmești' },
  { label: 'Rameswaram', value: 'Rameswaram' },
  { label: 'Ramewadi', value: 'Ramewadi' },
  { label: 'Ramganj Mandi', value: 'Ramganj Mandi' },
  { label: 'Ramgarh', value: 'Ramgarh' },
  { label: 'Ramgarh', value: 'Ramgarh' },
  { label: 'Ramgarh', value: 'Ramgarh' },
  { label: 'Ramgundam', value: 'Ramgundam' },
  { label: 'Ramhormoz', value: 'Ramhormoz' },
  { label: 'Ramian', value: 'Ramian' },
  { label: 'Ramilândia', value: 'Ramilândia' },
  { label: 'Ramillies', value: 'Ramillies' },
  { label: 'Ramingstein', value: 'Ramingstein' },
  { label: 'Ramiola', value: 'Ramiola' },
  { label: 'Ramirás', value: 'Ramirás' },
  { label: 'Ramírez', value: 'Ramírez' },
  { label: 'Ramiriquí', value: 'Ramiriquí' },
  { label: 'Ramiro', value: 'Ramiro' },
  { label: 'Ramiseto', value: 'Ramiseto' },
  { label: 'Ramishan', value: 'Ramishan' },
  { label: 'Ramjibanpur', value: 'Ramjibanpur' },
  { label: 'Ramkola', value: 'Ramkola' },
  { label: 'Ramla', value: 'Ramla' },
  { label: 'Rammelsbach', value: 'Rammelsbach' },
  { label: 'Rammenau', value: 'Rammenau' },
  { label: 'Rammingen', value: 'Rammingen' },
  { label: 'Ramna', value: 'Ramna' },
  { label: 'Ramnagar', value: 'Ramnagar' },
  { label: 'Ramnagar', value: 'Ramnagar' },
  { label: 'Ramnagar', value: 'Ramnagar' },
  { label: 'Ramnagar', value: 'Ramnagar' },
  { label: 'Ramnagar', value: 'Ramnagar' },
  { label: 'Ramnäs', value: 'Ramnäs' },
  { label: 'Râmnicelu', value: 'Râmnicelu' },
  { label: 'Râmnicelu', value: 'Râmnicelu' },
  { label: 'Râmniceni', value: 'Râmniceni' },
  { label: 'Râmnicu Sărat', value: 'Râmnicu Sărat' },
  { label: 'Râmnicu Vâlcea', value: 'Râmnicu Vâlcea' },
  { label: 'Ramokgonami', value: 'Ramokgonami' },
  { label: 'Ramon', value: 'Ramon' },
  { label: 'Ramon', value: 'Ramon' },
  { label: 'Ramon (Municipal Capital)', value: 'Ramon (Municipal Capital)' },
  { label: 'Ramón Corona', value: 'Ramón Corona' },
  { label: 'Ramón F. Balboa', value: 'Ramón F. Balboa' },
  { label: 'Ramon Magsaysay', value: 'Ramon Magsaysay' },
  { label: 'Ramón Santana', value: 'Ramón Santana' },
  { label: 'Ramon’', value: 'Ramon’' },
  { label: 'Ramona', value: 'Ramona' },
  { label: 'Ramonchamp', value: 'Ramonchamp' },
  { label: 'Ramonskiy Rayon', value: 'Ramonskiy Rayon' },
  { label: 'Ramonville-Saint-Agne', value: 'Ramonville-Saint-Agne' },
  { label: 'Ramos', value: 'Ramos' },
  { label: 'Ramos', value: 'Ramos' },
  { label: 'Ramos', value: 'Ramos' },
  { label: 'Ramos Arizpe', value: 'Ramos Arizpe' },
  { label: 'Ramos West', value: 'Ramos West' },
  { label: 'Ramos West', value: 'Ramos West' },
  { label: 'Ramotswa', value: 'Ramotswa' },
  { label: 'Rampachodavaram', value: 'Rampachodavaram' },
  { label: 'Ramponio Verna', value: 'Ramponio Verna' },
  { label: 'Rampur', value: 'Rampur' },
  { label: 'Rampur', value: 'Rampur' },
  { label: 'Rampur Hat', value: 'Rampur Hat' },
  { label: 'Rampura', value: 'Rampura' },
  { label: 'Rampura', value: 'Rampura' },
  { label: 'Rampura', value: 'Rampura' },
  { label: 'Ramsar', value: 'Ramsar' },
  { label: 'Ramsau', value: 'Ramsau' },
  { label: 'Ramsau', value: 'Ramsau' },
  { label: 'Ramsau Am Dachstein', value: 'Ramsau Am Dachstein' },
  { label: 'Ramsau Im Zillertal', value: 'Ramsau Im Zillertal' },
  { label: 'Ramsbottom', value: 'Ramsbottom' },
  { label: 'Ramsbury', value: 'Ramsbury' },
  { label: 'Ramsen', value: 'Ramsen' },
  { label: 'Ramsen', value: 'Ramsen' },
  { label: 'Ramseur', value: 'Ramseur' },
  { label: 'Ramsey', value: 'Ramsey' },
  { label: 'Ramsey', value: 'Ramsey' },
  { label: 'Ramsey', value: 'Ramsey' },
  { label: 'Ramsey', value: 'Ramsey' },
  { label: 'Ramsey County', value: 'Ramsey County' },
  { label: 'Ramsey County', value: 'Ramsey County' },
  { label: 'Ramsgate', value: 'Ramsgate' },
  { label: 'Ramsgate', value: 'Ramsgate' },
  { label: 'Ramsgate Beach', value: 'Ramsgate Beach' },
  { label: 'Ramshir', value: 'Ramshir' },
  { label: 'Ramsin', value: 'Ramsin' },
  { label: 'Ramstein-Miesenbach', value: 'Ramstein-Miesenbach' },
  { label: 'Ramsthal', value: 'Ramsthal' },
  { label: 'Ramtek', value: 'Ramtek' },
  { label: 'Ramtown', value: 'Ramtown' },
  { label: 'Ramučiai', value: 'Ramučiai' },
  { label: 'Ramuscello', value: 'Ramuscello' },
  { label: 'Ramygala', value: 'Ramygala' },
  { label: 'Rana', value: 'Rana' },
  { label: 'Ranaghat', value: 'Ranaghat' },
  { label: 'Ranao', value: 'Ranao' },
  { label: 'Ranao', value: 'Ranao' },
  { label: 'Ranapur', value: 'Ranapur' },
  { label: 'Ranau', value: 'Ranau' },
  { label: 'Ranavav', value: 'Ranavav' },
  { label: 'Râncăciov', value: 'Râncăciov' },
  { label: 'Rancagua', value: 'Rancagua' },
  { label: 'Rancharia', value: 'Rancharia' },
  { label: 'Ranchería De Pocitos', value: 'Ranchería De Pocitos' },
  { label: 'Rancheria Payau', value: 'Rancheria Payau' },
  { label: 'Ranchettes', value: 'Ranchettes' },
  { label: 'Ranchi', value: 'Ranchi' },
  { label: 'Ranchicourt', value: 'Ranchicourt' },
  { label: 'Ranchito De Castro', value: 'Ranchito De Castro' },
  { label: 'Rancho Alegre', value: 'Rancho Alegre' },
  { label: 'Rancho Alegre', value: 'Rancho Alegre' },
  { label: 'Rancho Alegre', value: 'Rancho Alegre' },
  { label: 'Rancho Alegre', value: 'Rancho Alegre' },
  { label: 'Rancho Alegre', value: 'Rancho Alegre' },
  { label: 'Rancho Alegre [Fraccionamiento]', value: 'Rancho Alegre [Fraccionamiento]' },
  { label: 'Rancho Alegre Doeste', value: 'Rancho Alegre Doeste' },
  { label: 'Rancho Bellavista [Fraccionamiento]', value: 'Rancho Bellavista [Fraccionamiento]' },
  { label: 'Rancho Calaveras', value: 'Rancho Calaveras' },
  { label: 'Rancho Chico', value: 'Rancho Chico' },
  { label: 'Rancho Cordova', value: 'Rancho Cordova' },
  { label: 'Rancho Cucamonga', value: 'Rancho Cucamonga' },
  { label: 'Rancho De Guadalupe', value: 'Rancho De Guadalupe' },
  { label: 'Rancho De Las Lomas', value: 'Rancho De Las Lomas' },
  { label: 'Rancho Del Padre', value: 'Rancho Del Padre' },
  { label: 'Rancho Grande', value: 'Rancho Grande' },
  { label: 'Rancho La Gloria', value: 'Rancho La Gloria' },
  { label: 'Rancho Mirage', value: 'Rancho Mirage' },
  { label: 'Rancho Murieta', value: 'Rancho Murieta' },
  { label: 'Rancho Nuevo', value: 'Rancho Nuevo' },
  { label: 'Rancho Nuevo', value: 'Rancho Nuevo' },
  { label: 'Rancho Nuevo', value: 'Rancho Nuevo' },
  { label: 'Rancho Nuevo', value: 'Rancho Nuevo' },
  { label: 'Rancho Nuevo', value: 'Rancho Nuevo' },
  { label: 'Rancho Nuevo De La Cruz', value: 'Rancho Nuevo De La Cruz' },
  { label: 'Rancho Nuevo De La Luz', value: 'Rancho Nuevo De La Luz' },
  { label: 'Rancho Nuevo De Morelos (De Guadalupe)', value: 'Rancho Nuevo De Morelos (De Guadalupe)' },
  { label: 'Rancho Nuevo Del Llanito', value: 'Rancho Nuevo Del Llanito' },
  { label: 'Rancho Palos Verdes', value: 'Rancho Palos Verdes' },
  { label: 'Rancho Penasquitos', value: 'Rancho Penasquitos' },
  { label: 'Rancho Queimado', value: 'Rancho Queimado' },
  { label: 'Rancho San Diego', value: 'Rancho San Diego' },
  { label: 'Rancho Santa Fe', value: 'Rancho Santa Fe' },
  { label: 'Rancho Santa Margarita', value: 'Rancho Santa Margarita' },
  { label: 'Rancho Tehama Reserve', value: 'Rancho Tehama Reserve' },
  { label: 'Rancho Veloz', value: 'Rancho Veloz' },
  { label: 'Rancho Verde', value: 'Rancho Verde' },
  { label: 'Rancho Viejo', value: 'Rancho Viejo' },
  { label: 'Rancho Viejo', value: 'Rancho Viejo' },
  { label: 'Rancho Viejo', value: 'Rancho Viejo' },
  { label: 'Rancho Viejo', value: 'Rancho Viejo' },
  { label: 'Rancho Viejo', value: 'Rancho Viejo' },
  { label: 'Rancho Viejo', value: 'Rancho Viejo' },
  { label: 'Rancho Viejo (Rancho Nuevo De La Democracia)', value: 'Rancho Viejo (Rancho Nuevo De La Democracia)' },
  { label: 'Ranchos De Taos', value: 'Ranchos De Taos' },
  { label: 'Ranchuelo', value: 'Ranchuelo' },
  { label: 'Rancio Valcuvia', value: 'Rancio Valcuvia' },
  { label: 'Ranco', value: 'Ranco' },
  { label: 'Rancul', value: 'Rancul' },
  { label: 'Rand', value: 'Rand' },
  { label: 'Randaberg', value: 'Randaberg' },
  { label: 'Randall County', value: 'Randall County' },
  { label: 'Randallstown', value: 'Randallstown' },
  { label: 'Randalstown', value: 'Randalstown' },
  { label: 'Randan', value: 'Randan' },
  { label: 'Randazzo', value: 'Randazzo' },
  { label: 'Randburg', value: 'Randburg' },
  { label: 'Randegg', value: 'Randegg' },
  { label: 'Randenbroek', value: 'Randenbroek' },
  { label: 'Randers', value: 'Randers' },
  { label: 'Randers Kommune', value: 'Randers Kommune' },
  { label: 'Randersacker', value: 'Randersacker' },
  { label: 'Randfontein', value: 'Randfontein' },
  { label: 'Randleman', value: 'Randleman' },
  { label: 'Randogne', value: 'Randogne' },
  { label: 'Randolph', value: 'Randolph' },
  { label: 'Randolph', value: 'Randolph' },
  { label: 'Randolph', value: 'Randolph' },
  { label: 'Randolph', value: 'Randolph' },
  { label: 'Randolph', value: 'Randolph' },
  { label: 'Randolph', value: 'Randolph' },
  { label: 'Randolph', value: 'Randolph' },
  { label: 'Randolph County', value: 'Randolph County' },
  { label: 'Randolph County', value: 'Randolph County' },
  { label: 'Randolph County', value: 'Randolph County' },
  { label: 'Randolph County', value: 'Randolph County' },
  { label: 'Randolph County', value: 'Randolph County' },
  { label: 'Randolph County', value: 'Randolph County' },
  { label: 'Randolph County', value: 'Randolph County' },
  { label: 'Randolph County', value: 'Randolph County' },
  { label: 'Random Lake', value: 'Random Lake' },
  { label: 'Randudongkal', value: 'Randudongkal' },
  { label: 'Randwick', value: 'Randwick' },
  { label: 'Råneå', value: 'Råneå' },
  { label: 'Ranekouh', value: 'Ranekouh' },
  { label: 'Ranelagh', value: 'Ranelagh' },
  { label: 'Ranemsletta', value: 'Ranemsletta' },
  { label: 'Ranérou', value: 'Ranérou' },
  { label: 'Ra-Ngae', value: 'Ra-Ngae' },
  { label: 'Rangamati', value: 'Rangamati' },
  { label: 'Rangapara', value: 'Rangapara' },
  { label: 'Rangareddi', value: 'Rangareddi' },
  { label: 'Rang-Du-Fliers', value: 'Rang-Du-Fliers' },
  { label: 'Rangely', value: 'Rangely' },
  { label: 'Rangendingen', value: 'Rangendingen' },
  { label: 'Ranger', value: 'Ranger' },
  { label: 'Rangersdorf', value: 'Rangersdorf' },
  { label: 'Rangeville', value: 'Rangeville' },
  { label: 'Rangeway', value: 'Rangeway' },
  { label: 'Rangewood', value: 'Rangewood' },
  { label: 'Ranggen', value: 'Ranggen' },
  { label: 'Rangia', value: 'Rangia' },
  { label: 'Rangkasbitung', value: 'Rangkasbitung' },
  { label: 'Rangpo', value: 'Rangpo' },
  { label: 'Rangpur ', value: 'Rangpur ' },
  { label: 'Rangsdorf', value: 'Rangsdorf' },
  { label: 'Ranhados', value: 'Ranhados' },
  { label: 'Rani', value: 'Rani' },
  { label: 'Rania', value: 'Rania' },
  { label: 'Ranibennur', value: 'Ranibennur' },
  { label: 'Ranica', value: 'Ranica' },
  { label: 'Raniganj', value: 'Raniganj' },
  { label: 'Ranikhet', value: 'Ranikhet' },
  { label: 'Raning', value: 'Raning' },
  { label: 'Ranipet', value: 'Ranipet' },
  { label: 'Ranipur', value: 'Ranipur' },
  { label: 'Ranipur', value: 'Ranipur' },
  { label: 'Ranipur Barsi', value: 'Ranipur Barsi' },
  { label: 'Ranir Bazar', value: 'Ranir Bazar' },
  { label: 'Ranis', value: 'Ranis' },
  { label: 'Raniżów', value: 'Raniżów' },
  { label: 'Rankin', value: 'Rankin' },
  { label: 'Rankin', value: 'Rankin' },
  { label: 'Rankin County', value: 'Rankin County' },
  { label: 'Rankin Inlet', value: 'Rankin Inlet' },
  { label: 'Rankin Park', value: 'Rankin Park' },
  { label: 'Rankweil', value: 'Rankweil' },
  { label: 'Ranlo', value: 'Ranlo' },
  { label: 'Rannebergen', value: 'Rannebergen' },
  { label: 'Rannée', value: 'Rannée' },
  { label: 'Rannersdorf', value: 'Rannersdorf' },
  { label: 'Rannungen', value: 'Rannungen' },
  { label: 'Ranong', value: 'Ranong' },
  { label: 'Ranot', value: 'Ranot' },
  { label: 'Ranpur', value: 'Ranpur' },
  { label: 'Ránquil', value: 'Ránquil' },
  { label: 'Ranquitte', value: 'Ranquitte' },
  { label: 'Ransang', value: 'Ransang' },
  { label: 'Ransbach-Baumbach', value: 'Ransbach-Baumbach' },
  { label: 'Ransdorp', value: 'Ransdorp' },
  { label: 'Ranshofen', value: 'Ranshofen' },
  { label: 'Ranskill', value: 'Ranskill' },
  { label: 'Ransom Canyon', value: 'Ransom Canyon' },
  { label: 'Ransom County', value: 'Ransom County' },
  { label: 'Ransomville', value: 'Ransomville' },
  { label: 'Ranson', value: 'Ranson' },
  { label: 'Ranst', value: 'Ranst' },
  { label: 'Ranstadt', value: 'Ranstadt' },
  { label: 'Ranta', value: 'Ranta' },
  { label: 'Rantasalmi', value: 'Rantasalmi' },
  { label: 'Rantauprapat', value: 'Rantauprapat' },
  { label: 'Ranten', value: 'Ranten' },
  { label: 'Rantepao', value: 'Rantepao' },
  { label: 'Rantigny', value: 'Rantigny' },
  { label: 'Rantoul', value: 'Rantoul' },
  { label: 'Rantrum', value: 'Rantrum' },
  { label: 'Rantsila', value: 'Rantsila' },
  { label: 'Ranua', value: 'Ranua' },
  { label: 'Ranville', value: 'Ranville' },
  { label: 'Ranzanico', value: 'Ranzanico' },
  { label: 'Rânzești', value: 'Rânzești' },
  { label: 'Raon-Aux-Bois', value: 'Raon-Aux-Bois' },
  { label: 'Raoued', value: 'Raoued' },
  { label: 'Raoul', value: 'Raoul' },
  { label: 'Rapa', value: 'Rapa' },
  { label: 'Râpa De Jos', value: 'Râpa De Jos' },
  { label: 'Rapagnano', value: 'Rapagnano' },
  { label: 'Rapallo', value: 'Rapallo' },
  { label: 'Răpănaşu', value: 'Răpănaşu' },
  { label: 'Rapar', value: 'Rapar' },
  { label: 'Rapariegos', value: 'Rapariegos' },
  { label: 'Raphoe', value: 'Raphoe' },
  { label: 'Rapid City', value: 'Rapid City' },
  { label: 'Rapid City', value: 'Rapid City' },
  { label: 'Rapid Creek', value: 'Rapid Creek' },
  { label: 'Rapid Valley', value: 'Rapid Valley' },
  { label: 'Rapides Parish', value: 'Rapides Parish' },
  { label: 'Rapids', value: 'Rapids' },
  { label: 'Räpina', value: 'Räpina' },
  { label: 'Räpina Vald', value: 'Räpina Vald' },
  { label: 'Rapino', value: 'Rapino' },
  { label: 'Rapla', value: 'Rapla' },
  { label: 'Rapla Vald', value: 'Rapla Vald' },
  { label: 'Rapolano Terme', value: 'Rapolano Terme' },
  { label: 'Rapolla', value: 'Rapolla' },
  { label: 'Rapoltu Mare', value: 'Rapoltu Mare' },
  { label: 'Rapone', value: 'Rapone' },
  { label: 'Raposa', value: 'Raposa' },
  { label: 'Raposos', value: 'Raposos' },
  { label: 'Rapotín', value: 'Rapotín' },
  { label: 'Rapoula Do Côa', value: 'Rapoula Do Côa' },
  { label: 'Rappahannock County', value: 'Rappahannock County' },
  { label: 'Rapperswil', value: 'Rapperswil' },
  { label: 'Rappottenstein', value: 'Rappottenstein' },
  { label: 'Rapu-Rapu', value: 'Rapu-Rapu' },
  { label: 'Raqiyah', value: 'Raqiyah' },
  { label: 'Ráquira', value: 'Ráquira' },
  { label: 'Raritan', value: 'Raritan' },
  { label: 'Raron', value: 'Raron' },
  { label: 'Raron District', value: 'Raron District' },
  { label: 'Ras Al Khaimah', value: 'Ras Al Khaimah' },
  { label: 'Ras Al Khaimah City', value: 'Ras Al Khaimah City' },
  { label: 'Râs El Aïoun', value: 'Râs El Aïoun' },
  { label: 'Râs El Oued', value: 'Râs El Oued' },
  { label: 'Ras Gharib', value: 'Ras Gharib' },
  { label: 'Rasa', value: 'Rasa' },
  { label: 'Raša', value: 'Raša' },
  { label: 'Rasad', value: 'Rasad' },
  { label: 'Râşca', value: 'Râşca' },
  { label: 'Râșca', value: 'Râșca' },
  { label: 'Răscăeți', value: 'Răscăeți' },
  { label: 'Rascafría', value: 'Rascafría' },
  { label: 'Raschau', value: 'Raschau' },
  { label: 'Rascón', value: 'Rascón' },
  { label: 'Răscruci', value: 'Răscruci' },
  { label: 'Rasdorf', value: 'Rasdorf' },
  { label: 'Raseiniai', value: 'Raseiniai' },
  { label: 'Râșești', value: 'Râșești' },
  { label: 'Rasht', value: 'Rasht' },
  { label: 'Rasht', value: 'Rasht' },
  { label: 'Rashtkhar', value: 'Rashtkhar' },
  { label: 'Rași', value: 'Rași' },
  { label: 'Rasillo De Cameros El', value: 'Rasillo De Cameros El' },
  { label: 'Rasina', value: 'Rasina' },
  { label: 'Răşinari', value: 'Răşinari' },
  { label: 'Rasines', value: 'Rasines' },
  { label: 'Rasinja', value: 'Rasinja' },
  { label: 'Rasipuram', value: 'Rasipuram' },
  { label: 'Rāsk', value: 'Rāsk' },
  { label: 'Raška', value: 'Raška' },
  { label: 'Raškovice', value: 'Raškovice' },
  { label: 'Răsmireşti', value: 'Răsmireşti' },
  { label: 'Rasmussen', value: 'Rasmussen' },
  { label: 'Râşnov', value: 'Râşnov' },
  { label: 'Rasonski Rayon', value: 'Rasonski Rayon' },
  { label: 'Rasony', value: 'Rasony' },
  { label: 'Rasos', value: 'Rasos' },
  { label: 'Rasova', value: 'Rasova' },
  { label: 'Raspenava', value: 'Raspenava' },
  { label: 'Rasquera', value: 'Rasquera' },
  { label: 'Rasra', value: 'Rasra' },
  { label: 'Rassa', value: 'Rassa' },
  { label: 'Rassach', value: 'Rassach' },
  { label: 'Rasshevatskaya', value: 'Rasshevatskaya' },
  { label: 'Rassina', value: 'Rassina' },
  { label: 'Rasskazovo', value: 'Rasskazovo' },
  { label: 'Rasskazovskiy Rayon', value: 'Rasskazovskiy Rayon' },
  { label: 'Raßnitz', value: 'Raßnitz' },
  { label: 'Rassvet', value: 'Rassvet' },
  { label: 'Rassvet', value: 'Rassvet' },
  { label: 'Rast', value: 'Rast' },
  { label: 'Rastatt', value: 'Rastatt' },
  { label: 'Rastede', value: 'Rastede' },
  { label: 'Rastenberg', value: 'Rastenberg' },
  { label: 'Rastenfeld', value: 'Rastenfeld' },
  { label: 'Rastguyan', value: 'Rastguyan' },
  { label: 'Rastignano-Carteria Di Sesto', value: 'Rastignano-Carteria Di Sesto' },
  { label: 'Răstoaca', value: 'Răstoaca' },
  { label: 'Răstolița', value: 'Răstolița' },
  { label: 'Rastow', value: 'Rastow' },
  { label: 'Rastrick', value: 'Rastrick' },
  { label: 'Răsuceni', value: 'Răsuceni' },
  { label: 'Rasueros', value: 'Rasueros' },
  { label: 'Rasulabad', value: 'Rasulabad' },
  { label: 'Rasulabad-E Sofla', value: 'Rasulabad-E Sofla' },
  { label: 'Rasulnagar', value: 'Rasulnagar' },
  { label: 'Rasun Anterselva - Rasen-Antholz', value: 'Rasun Anterselva - Rasen-Antholz' },
  { label: 'Råsunda', value: 'Råsunda' },
  { label: 'Rasura', value: 'Rasura' },
  { label: 'Rasuwa', value: 'Rasuwa' },
  { label: 'Raszczyce', value: 'Raszczyce' },
  { label: 'Raszków', value: 'Raszków' },
  { label: 'Raszowa', value: 'Raszowa' },
  { label: 'Raszyn', value: 'Raszyn' },
  { label: 'Rat Burana', value: 'Rat Burana' },
  { label: 'Rataje', value: 'Rataje' },
  { label: 'Ratangarh', value: 'Ratangarh' },
  { label: 'Ratangarh', value: 'Ratangarh' },
  { label: 'Ratanpur', value: 'Ratanpur' },
  { label: 'Ratby', value: 'Ratby' },
  { label: 'Ratchaburi', value: 'Ratchaburi' },
  { label: 'Ratchathewi', value: 'Ratchathewi' },
  { label: 'Ratekau', value: 'Ratekau' },
  { label: 'Răteşti', value: 'Răteşti' },
  { label: 'Rath', value: 'Rath' },
  { label: 'Rath', value: 'Rath' },
  { label: 'Ráth Luirc', value: 'Ráth Luirc' },
  { label: 'Rathangan', value: 'Rathangan' },
  { label: 'Rathcoole', value: 'Rathcoole' },
  { label: 'Rathcormac', value: 'Rathcormac' },
  { label: 'Rathdowney', value: 'Rathdowney' },
  { label: 'Rathdrum', value: 'Rathdrum' },
  { label: 'Rathdrum', value: 'Rathdrum' },
  { label: 'Rathenow', value: 'Rathenow' },
  { label: 'Rathfriland', value: 'Rathfriland' },
  { label: 'Rathgar', value: 'Rathgar' },
  { label: 'Rathkeale', value: 'Rathkeale' },
  { label: 'Rathmannsdorf', value: 'Rathmannsdorf' },
  { label: 'Rathmines', value: 'Rathmines' },
  { label: 'Rathmines', value: 'Rathmines' },
  { label: 'Rathnew', value: 'Rathnew' },
  { label: 'Ratho', value: 'Ratho' },
  { label: 'Ratho Station', value: 'Ratho Station' },
  { label: 'Ratholo', value: 'Ratholo' },
  { label: 'Rathwire', value: 'Rathwire' },
  { label: 'Ratia', value: 'Ratia' },
  { label: 'Ratiboř', value: 'Ratiboř' },
  { label: 'Ratingen', value: 'Ratingen' },
  { label: 'Ratíškovice', value: 'Ratíškovice' },
  { label: 'Ratlam', value: 'Ratlam' },
  { label: 'Ratmalana', value: 'Ratmalana' },
  { label: 'Ratnagiri', value: 'Ratnagiri' },
  { label: 'Ratnapura', value: 'Ratnapura' },
  { label: 'Ratnapura District', value: 'Ratnapura District' },
  { label: 'Ratne', value: 'Ratne' },
  { label: 'Ratnivs’Kyy Rayon', value: 'Ratnivs’Kyy Rayon' },
  { label: 'Ratoath', value: 'Ratoath' },
  { label: 'Ratodero', value: 'Ratodero' },
  { label: 'Ratoeira', value: 'Ratoeira' },
  { label: 'Raton', value: 'Raton' },
  { label: 'Råtorp', value: 'Råtorp' },
  { label: 'Ratowice', value: 'Ratowice' },
  { label: 'Ratsch An Der Weinstraße', value: 'Ratsch An Der Weinstraße' },
  { label: 'Ratschendorf', value: 'Ratschendorf' },
  { label: 'Rattelsdorf', value: 'Rattelsdorf' },
  { label: 'Ratten', value: 'Ratten' },
  { label: 'Rattenberg', value: 'Rattenberg' },
  { label: 'Rattenberg', value: 'Rattenberg' },
  { label: 'Rattiszell', value: 'Rattiszell' },
  { label: 'Rättvik', value: 'Rättvik' },
  { label: 'Ratzeburg', value: 'Ratzeburg' },
  { label: 'Ratzersdorf An Der Traisen', value: 'Ratzersdorf An Der Traisen' },
  { label: 'Râu Alb De Jos', value: 'Râu Alb De Jos' },
  { label: 'Râu De Mori', value: 'Râu De Mori' },
  { label: 'Râu Sadului', value: 'Râu Sadului' },
  { label: 'Raub', value: 'Raub' },
  { label: 'Raubach', value: 'Raubach' },
  { label: 'Raubling', value: 'Raubling' },
  { label: 'Raubsville', value: 'Raubsville' },
  { label: 'Răuceşti', value: 'Răuceşti' },
  { label: 'Rauchenwarth', value: 'Rauchenwarth' },
  { label: 'Rauco', value: 'Rauco' },
  { label: 'Raudales Malpaso', value: 'Raudales Malpaso' },
  { label: 'Rauen', value: 'Rauen' },
  { label: 'Rauenberg', value: 'Rauenberg' },
  { label: 'Raufoss', value: 'Raufoss' },
  { label: 'Raul Soares', value: 'Raul Soares' },
  { label: 'Rauma', value: 'Rauma' },
  { label: 'Rauma', value: 'Rauma' },
  { label: 'Rauna', value: 'Rauna' },
  { label: 'Raunds', value: 'Raunds' },
  { label: 'Raunheim', value: 'Raunheim' },
  { label: 'Rauris', value: 'Rauris' },
  { label: 'Raurkela', value: 'Raurkela' },
  { label: 'Rauscedo-Domanins', value: 'Rauscedo-Domanins' },
  { label: 'Rauschenberg', value: 'Rauschenberg' },
  { label: 'Răuseni', value: 'Răuseni' },
  { label: 'Rautahat ', value: 'Rautahat ' },
  { label: 'Rautjärvi', value: 'Rautjärvi' },
  { label: 'Rauzan', value: 'Rauzan' },
  { label: 'Ravalli County', value: 'Ravalli County' },
  { label: 'Ravand-E Vosta', value: 'Ravand-E Vosta' },
  { label: 'Ravansar', value: 'Ravansar' },
  { label: 'Ravanusa', value: 'Ravanusa' },
  { label: 'Ravar', value: 'Ravar' },
  { label: 'Ravarino', value: 'Ravarino' },
  { label: 'Rava-Rus’Ka', value: 'Rava-Rus’Ka' },
  { label: 'Ravascletto', value: 'Ravascletto' },
  { label: 'Ravda', value: 'Ravda' },
  { label: 'Ravello', value: 'Ravello' },
  { label: 'Ravels', value: 'Ravels' },
  { label: 'Ravelsbach', value: 'Ravelsbach' },
  { label: 'Raven', value: 'Raven' },
  { label: 'Ravena', value: 'Ravena' },
  { label: 'Raveneau', value: 'Raveneau' },
  { label: 'Ravenel', value: 'Ravenel' },
  { label: 'Ravenel', value: 'Ravenel' },
  { label: 'Ravenhall', value: 'Ravenhall' },
  { label: 'Ravenna', value: 'Ravenna' },
  { label: 'Ravenna', value: 'Ravenna' },
  { label: 'Ravenna', value: 'Ravenna' },
  { label: 'Ravenna', value: 'Ravenna' },
  { label: 'Ravensburg', value: 'Ravensburg' },
  { label: 'Ravensdale', value: 'Ravensdale' },
  { label: 'Ravenshead', value: 'Ravenshead' },
  { label: 'Ravenshoe', value: 'Ravenshoe' },
  { label: 'Ravenstein', value: 'Ravenstein' },
  { label: 'Ravensthorpe', value: 'Ravensthorpe' },
  { label: 'Ravenstone', value: 'Ravenstone' },
  { label: 'Ravenswood', value: 'Ravenswood' },
  { label: 'Ravenswood', value: 'Ravenswood' },
  { label: 'Ravenswood', value: 'Ravenswood' },
  { label: 'Ravensworth', value: 'Ravensworth' },
  { label: 'Raveo', value: 'Raveo' },
  { label: 'Raver', value: 'Raver' },
  { label: 'Ravières', value: 'Ravières' },
  { label: 'Ravina', value: 'Ravina' },
  { label: 'Ravine Chabot', value: 'Ravine Chabot' },
  { label: 'Ravine Claire', value: 'Ravine Claire' },
  { label: 'Ravine Poisson', value: 'Ravine Poisson' },
  { label: 'Ravine Touterelle', value: 'Ravine Touterelle' },
  { label: 'Raviscanina', value: 'Raviscanina' },
  { label: 'Rävlanda', value: 'Rävlanda' },
  { label: 'Ravne', value: 'Ravne' },
  { label: 'Ravne Na Koroškem', value: 'Ravne Na Koroškem' },
  { label: 'Ravni Topolovac', value: 'Ravni Topolovac' },
  { label: 'Ravnje', value: 'Ravnje' },
  { label: 'Ravno Selo', value: 'Ravno Selo' },
  { label: 'Rawa Mazowiecka', value: 'Rawa Mazowiecka' },
  { label: 'Rāwah', value: 'Rāwah' },
  { label: 'Rawai', value: 'Rawai' },
  { label: 'Rawala Kot', value: 'Rawala Kot' },
  { label: 'Rawalpindi', value: 'Rawalpindi' },
  { label: 'Rawalpindi District', value: 'Rawalpindi District' },
  { label: 'Rawang', value: 'Rawang' },
  { label: 'Rawannawi Village', value: 'Rawannawi Village' },
  { label: 'Rawatbhata', value: 'Rawatbhata' },
  { label: 'Rawatsar', value: 'Rawatsar' },
  { label: 'Rawcliffe', value: 'Rawcliffe' },
  { label: 'Rawdon', value: 'Rawdon' },
  { label: 'Rawicz', value: 'Rawicz' },
  { label: 'Rawkhah', value: 'Rawkhah' },
  { label: 'Rawlins', value: 'Rawlins' },
  { label: 'Rawlins County', value: 'Rawlins County' },
  { label: 'Rawls Springs', value: 'Rawls Springs' },
  { label: 'Rawmarsh', value: 'Rawmarsh' },
  { label: 'Raworth', value: 'Raworth' },
  { label: 'Rawson', value: 'Rawson' },
  { label: 'Rawtenstall', value: 'Rawtenstall' },
  { label: 'Raxaul', value: 'Raxaul' },
  { label: 'Raxendorf', value: 'Raxendorf' },
  { label: 'Ray', value: 'Ray' },
  { label: 'Ray City', value: 'Ray City' },
  { label: 'Ray County', value: 'Ray County' },
  { label: 'Raya', value: 'Raya' },
  { label: 'Raya Zaragoza', value: 'Raya Zaragoza' },
  { label: 'Rayachoti', value: 'Rayachoti' },
  { label: 'Rayadrug', value: 'Rayadrug' },
  { label: 'Rayagada', value: 'Rayagada' },
  { label: 'Raybag', value: 'Raybag' },
  { label: 'Raychikhinsk', value: 'Raychikhinsk' },
  { label: 'Raydah', value: 'Raydah' },
  { label: 'Rayegan-E Sofla', value: 'Rayegan-E Sofla' },
  { label: 'Rayen', value: 'Rayen' },
  { label: 'Rayevskaya', value: 'Rayevskaya' },
  { label: 'Rayevskiy', value: 'Rayevskiy' },
  { label: 'Rayleigh', value: 'Rayleigh' },
  { label: 'Raymond', value: 'Raymond' },
  { label: 'Raymond', value: 'Raymond' },
  { label: 'Raymond', value: 'Raymond' },
  { label: 'Raymond', value: 'Raymond' },
  { label: 'Raymond', value: 'Raymond' },
  { label: 'Raymond Terrace', value: 'Raymond Terrace' },
  { label: 'Raymondville', value: 'Raymondville' },
  { label: 'Raymore', value: 'Raymore' },
  { label: 'Raymūn', value: 'Raymūn' },
  { label: 'Raymundo Enríquez', value: 'Raymundo Enríquez' },
  { label: 'Rayne', value: 'Rayne' },
  { label: 'Rayne', value: 'Rayne' },
  { label: 'Raynham', value: 'Raynham' },
  { label: 'Raynham Center', value: 'Raynham Center' },
  { label: 'Rayón', value: 'Rayón' },
  { label: 'Rayón', value: 'Rayón' },
  { label: 'Rayón', value: 'Rayón' },
  { label: 'Rayones', value: 'Rayones' },
  { label: 'Rayong', value: 'Rayong' },
  { label: 'Raypur', value: 'Raypur' },
  { label: 'Rayside-Balfour', value: 'Rayside-Balfour' },
  { label: 'Raytown', value: 'Raytown' },
  { label: 'Rayville', value: 'Rayville' },
  { label: 'Rayyis', value: 'Rayyis' },
  { label: 'Raz', value: 'Raz' },
  { label: 'Razac-Sur-L’Isle', value: 'Razac-Sur-L’Isle' },
  { label: 'Razaj', value: 'Razaj' },
  { label: 'Razam', value: 'Razam' },
  { label: 'Razampeta', value: 'Razampeta' },
  { label: 'Razan', value: 'Razan' },
  { label: 'Ražanac', value: 'Ražanac' },
  { label: 'Razaviye', value: 'Razaviye' },
  { label: 'Războieni', value: 'Războieni' },
  { label: 'Războieni-Cetate', value: 'Războieni-Cetate' },
  { label: 'Războienii De Jos', value: 'Războienii De Jos' },
  { label: 'Razdol’Nenskiy Rayon', value: 'Razdol’Nenskiy Rayon' },
  { label: 'Razdol’Noye', value: 'Razdol’Noye' },
  { label: 'Razdol’Noye', value: 'Razdol’Noye' },
  { label: 'Razdolinsk', value: 'Razdolinsk' },
  { label: 'Razeqan', value: 'Razeqan' },
  { label: 'Razès', value: 'Razès' },
  { label: 'Razgrad', value: 'Razgrad' },
  { label: 'Razi', value: 'Razi' },
  { label: 'Rāziḩ', value: 'Rāziḩ' },
  { label: 'Razini', value: 'Razini' },
  { label: 'Razkrižje', value: 'Razkrižje' },
  { label: 'Razliv', value: 'Razliv' },
  { label: 'Razlog', value: 'Razlog' },
  { label: 'Razmian', value: 'Razmian' },
  { label: 'Răzoare', value: 'Răzoare' },
  { label: 'Răzoare', value: 'Răzoare' },
  { label: 'Razole', value: 'Razole' },
  { label: 'Razorback', value: 'Razorback' },
  { label: 'Razumnoye', value: 'Razumnoye' },
  { label: 'Răzvad', value: 'Răzvad' },
  { label: 'Răzvani', value: 'Răzvani' },
  { label: 'Razvanje', value: 'Razvanje' },
  { label: 'Razvilka', value: 'Razvilka' },
  { label: 'Re', value: 'Re' },
  { label: 'Re', value: 'Re' },
  { label: 'Rea', value: 'Rea' },
  { label: 'Readfield', value: 'Readfield' },
  { label: 'Reading', value: 'Reading' },
  { label: 'Reading', value: 'Reading' },
  { label: 'Reading', value: 'Reading' },
  { label: 'Reading', value: 'Reading' },
  { label: 'Reading', value: 'Reading' },
  { label: 'Reading', value: 'Reading' },
  { label: 'Reagan County', value: 'Reagan County' },
  { label: 'Real', value: 'Real' },
  { label: 'Real', value: 'Real' },
  { label: 'Real', value: 'Real' },
  { label: 'Real', value: 'Real' },
  { label: 'Real', value: 'Real' },
  { label: 'Real County', value: 'Real County' },
  { label: 'Real De Catorce', value: 'Real De Catorce' },
  { label: 'Real De Gandía', value: 'Real De Gandía' },
  { label: 'Real De La Jara El', value: 'Real De La Jara El' },
  { label: 'Real De Montroi', value: 'Real De Montroi' },
  { label: 'Real De San Vicente El', value: 'Real De San Vicente El' },
  { label: 'Real Del Sol', value: 'Real Del Sol' },
  { label: 'Real Del Valle (El Paraíso) [Fraccionamiento]', value: 'Real Del Valle (El Paraíso) [Fraccionamiento]' },
  { label: 'Real Hacienda (Metrópolis)', value: 'Real Hacienda (Metrópolis)' },
  { label: 'Real Sitio De San Ildefonso', value: 'Real Sitio De San Ildefonso' },
  { label: 'Realejo Alto', value: 'Realejo Alto' },
  { label: 'Realejos Los', value: 'Realejos Los' },
  { label: 'Realeza', value: 'Realeza' },
  { label: 'Realicó', value: 'Realicó' },
  { label: 'Réalmont', value: 'Réalmont' },
  { label: 'Realmonte', value: 'Realmonte' },
  { label: 'Réalville', value: 'Réalville' },
  { label: 'Reamstown', value: 'Reamstown' },
  { label: 'Reana Del Roiale', value: 'Reana Del Roiale' },
  { label: 'Reana Del Rojale', value: 'Reana Del Rojale' },
  { label: 'Reano', value: 'Reano' },
  { label: 'Rebais', value: 'Rebais' },
  { label: 'Rebe', value: 'Rebe' },
  { label: 'Rebecq-Rognon', value: 'Rebecq-Rognon' },
  { label: 'Rebild Kommune', value: 'Rebild Kommune' },
  { label: 'Rebola', value: 'Rebola' },
  { label: 'Reboleira', value: 'Reboleira' },
  { label: 'Reboleiro', value: 'Reboleiro' },
  { label: 'Rebollar', value: 'Rebollar' },
  { label: 'Rebolledo De La Torre', value: 'Rebolledo De La Torre' },
  { label: 'Rebollo', value: 'Rebollo' },
  { label: 'Rebollosa De Jadraque', value: 'Rebollosa De Jadraque' },
  { label: 'Rebolosa', value: 'Rebolosa' },
  { label: 'Rebordões', value: 'Rebordões' },
  { label: 'Rebordosa', value: 'Rebordosa' },
  { label: 'Rebouças', value: 'Rebouças' },
  { label: 'Rebra', value: 'Rebra' },
  { label: 'Rebréchien', value: 'Rebréchien' },
  { label: 'Rebricea', value: 'Rebricea' },
  { label: 'Rebrikha', value: 'Rebrikha' },
  { label: 'Rebrikhinskiy Rayon', value: 'Rebrikhinskiy Rayon' },
  { label: 'Rebrişoara', value: 'Rebrişoara' },
  { label: 'Rebstein', value: 'Rebstein' },
  { label: 'Rebun Gun', value: 'Rebun Gun' },
  { label: 'Recale', value: 'Recale' },
  { label: 'Recanati', value: 'Recanati' },
  { label: 'Reçani', value: 'Reçani' },
  { label: 'Řečany Nad Labem', value: 'Řečany Nad Labem' },
  { label: 'Recarei', value: 'Recarei' },
  { label: 'Recas', value: 'Recas' },
  { label: 'Recaş', value: 'Recaş' },
  { label: 'Recco', value: 'Recco' },
  { label: 'Recea', value: 'Recea' },
  { label: 'Recea', value: 'Recea' },
  { label: 'Recea', value: 'Recea' },
  { label: 'Recea', value: 'Recea' },
  { label: 'Recea', value: 'Recea' },
  { label: 'Recea Cristur', value: 'Recea Cristur' },
  { label: 'Recetor', value: 'Recetor' },
  { label: 'Recetto', value: 'Recetto' },
  { label: 'Rechberghausen', value: 'Rechberghausen' },
  { label: 'Rechenberg-Bienenmühle', value: 'Rechenberg-Bienenmühle' },
  { label: 'Rechitsy', value: 'Rechitsy' },
  { label: 'Rechka', value: 'Rechka' },
  { label: 'Rechka-Vydrino', value: 'Rechka-Vydrino' },
  { label: 'Rechlin', value: 'Rechlin' },
  { label: 'Rechnitz', value: 'Rechnitz' },
  { label: 'Rechtenbach', value: 'Rechtenbach' },
  { label: 'Rechthalten', value: 'Rechthalten' },
  { label: 'Rechtmehring', value: 'Rechtmehring' },
  { label: 'Rechtsupweg', value: 'Rechtsupweg' },
  { label: 'Rechytsa', value: 'Rechytsa' },
  { label: 'Reci', value: 'Reci' },
  { label: 'Rečica Ob Savinji', value: 'Rečica Ob Savinji' },
  { label: 'Recife', value: 'Recife' },
  { label: 'Reckange-Sur-Mess', value: 'Reckange-Sur-Mess' },
  { label: 'Recke', value: 'Recke' },
  { label: 'Recklinghausen', value: 'Recklinghausen' },
  { label: 'Recoaro Terme', value: 'Recoaro Terme' },
  { label: 'Recodo', value: 'Recodo' },
  { label: 'Recodo', value: 'Recodo' },
  { label: 'Recoleta', value: 'Recoleta' },
  { label: 'Reconquista', value: 'Reconquista' },
  { label: 'Reconvilier', value: 'Reconvilier' },
  { label: 'Recoveco', value: 'Recoveco' },
  { label: 'Recquignies', value: 'Recquignies' },
  { label: 'Recreio', value: 'Recreio' },
  { label: 'Recreo', value: 'Recreo' },
  { label: 'Recreo', value: 'Recreo' },
  { label: 'Recsk', value: 'Recsk' },
  { label: 'Rector', value: 'Rector' },
  { label: 'Recueja La', value: 'Recueja La' },
  { label: 'Recuenco El', value: 'Recuenco El' },
  { label: 'Recuerda', value: 'Recuerda' },
  { label: 'Recursolândia', value: 'Recursolândia' },
  { label: 'Recz', value: 'Recz' },
  { label: 'Ręczno', value: 'Ręczno' },
  { label: 'Red Bank', value: 'Red Bank' },
  { label: 'Red Bank', value: 'Red Bank' },
  { label: 'Red Bank', value: 'Red Bank' },
  { label: 'Red Bank', value: 'Red Bank' },
  { label: 'Red Bay', value: 'Red Bay' },
  { label: 'Red Bluff', value: 'Red Bluff' },
  { label: 'Red Boiling Springs', value: 'Red Boiling Springs' },
  { label: 'Red Bud', value: 'Red Bud' },
  { label: 'Red Chute', value: 'Red Chute' },
  { label: 'Red Cliffs', value: 'Red Cliffs' },
  { label: 'Red Cloud', value: 'Red Cloud' },
  { label: 'Red Corral', value: 'Red Corral' },
  { label: 'Red Deer', value: 'Red Deer' },
  { label: 'Red Hill', value: 'Red Hill' },
  { label: 'Red Hill', value: 'Red Hill' },
  { label: 'Red Hill', value: 'Red Hill' },
  { label: 'Red Hill', value: 'Red Hill' },
  { label: 'Red Hill', value: 'Red Hill' },
  { label: 'Red Hills', value: 'Red Hills' },
  { label: 'Red Hills (Rural)', value: 'Red Hills (Rural)' },
  { label: 'Red Hills Gardens', value: 'Red Hills Gardens' },
  { label: 'Red Hills/ Sterling Castle', value: 'Red Hills/ Sterling Castle' },
  { label: 'Red Hook', value: 'Red Hook' },
  { label: 'Red Lake', value: 'Red Lake' },
  { label: 'Red Lake', value: 'Red Lake' },
  { label: 'Red Lake County', value: 'Red Lake County' },
  { label: 'Red Lake Falls', value: 'Red Lake Falls' },
  { label: 'Red Lick', value: 'Red Lick' },
  { label: 'Red Lion', value: 'Red Lion' },
  { label: 'Red Lodge', value: 'Red Lodge' },
  { label: 'Red Oak', value: 'Red Oak' },
  { label: 'Red Oak', value: 'Red Oak' },
  { label: 'Red Oak', value: 'Red Oak' },
  { label: 'Red Oaks Mill', value: 'Red Oaks Mill' },
  { label: 'Red River County', value: 'Red River County' },
  { label: 'Red River Parish', value: 'Red River Parish' },
  { label: 'Red Springs', value: 'Red Springs' },
  { label: 'Red Willow County', value: 'Red Willow County' },
  { label: 'Red Wing', value: 'Red Wing' },
  { label: 'Reda', value: 'Reda' },
  { label: 'Redal El', value: 'Redal El' },
  { label: 'Redan', value: 'Redan' },
  { label: 'Redan', value: 'Redan' },
  { label: 'Redange-Sur-Attert', value: 'Redange-Sur-Attert' },
  { label: 'Redavalle', value: 'Redavalle' },
  { label: 'Redbank', value: 'Redbank' },
  { label: 'Redbank Plains', value: 'Redbank Plains' },
  { label: 'Redbourn', value: 'Redbourn' },
  { label: 'Redbourne', value: 'Redbourne' },
  { label: 'Redby', value: 'Redby' },
  { label: 'Redcar', value: 'Redcar' },
  { label: 'Redcar And Cleveland', value: 'Redcar And Cleveland' },
  { label: 'Redcliff', value: 'Redcliff' },
  { label: 'Redcliffe', value: 'Redcliffe' },
  { label: 'Redding', value: 'Redding' },
  { label: 'Redding', value: 'Redding' },
  { label: 'Reddingmuirhead', value: 'Reddingmuirhead' },
  { label: 'Redditch', value: 'Redditch' },
  { label: 'Réde', value: 'Réde' },
  { label: 'Redea', value: 'Redea' },
  { label: 'Redecilla Del Camino', value: 'Redecilla Del Camino' },
  { label: 'Redecilla Del Campo', value: 'Redecilla Del Campo' },
  { label: 'Redenção', value: 'Redenção' },
  { label: 'Redenção', value: 'Redenção' },
  { label: 'Redenção Da Serra', value: 'Redenção Da Serra' },
  { label: 'Redenção Do Gurguéia', value: 'Redenção Do Gurguéia' },
  { label: 'Rédené', value: 'Rédené' },
  { label: 'Redentora', value: 'Redentora' },
  { label: 'Redessan', value: 'Redessan' },
  { label: 'Redfern', value: 'Redfern' },
  { label: 'Redfield', value: 'Redfield' },
  { label: 'Redfield', value: 'Redfield' },
  { label: 'Redford', value: 'Redford' },
  { label: 'Redgranite', value: 'Redgranite' },
  { label: 'Redhead', value: 'Redhead' },
  { label: 'Redhill', value: 'Redhill' },
  { label: 'Reding', value: 'Reding' },
  { label: 'Réding', value: 'Réding' },
  { label: 'Redington Beach', value: 'Redington Beach' },
  { label: 'Redington Shores', value: 'Redington Shores' },
  { label: 'Rediu', value: 'Rediu' },
  { label: 'Rediu', value: 'Rediu' },
  { label: 'Rediu', value: 'Rediu' },
  { label: 'Rediu', value: 'Rediu' },
  { label: 'Rediu', value: 'Rediu' },
  { label: 'Redkey', value: 'Redkey' },
  { label: 'Redkino', value: 'Redkino' },
  { label: 'Redland', value: 'Redland' },
  { label: 'Redland', value: 'Redland' },
  { label: 'Redland', value: 'Redland' },
  { label: 'Redland Bay', value: 'Redland Bay' },
  { label: 'Redlands', value: 'Redlands' },
  { label: 'Redlands', value: 'Redlands' },
  { label: 'Redleiten', value: 'Redleiten' },
  { label: 'Redlham', value: 'Redlham' },
  { label: 'Redlynch', value: 'Redlynch' },
  { label: 'Redlynch', value: 'Redlynch' },
  { label: 'Redmond', value: 'Redmond' },
  { label: 'Redmond', value: 'Redmond' },
  { label: 'Rednitzhembach', value: 'Rednitzhembach' },
  { label: 'Redon', value: 'Redon' },
  { label: 'Redonda La', value: 'Redonda La' },
  { label: 'Redondela', value: 'Redondela' },
  { label: 'Redondesco', value: 'Redondesco' },
  { label: 'Redondo', value: 'Redondo' },
  { label: 'Redondo Beach', value: 'Redondo Beach' },
  { label: 'Redován', value: 'Redován' },
  { label: 'Redruth', value: 'Redruth' },
  { label: 'Redstone Arsenal', value: 'Redstone Arsenal' },
  { label: 'Reducto', value: 'Reducto' },
  { label: 'Redueña', value: 'Redueña' },
  { label: 'Reduit', value: 'Reduit' },
  { label: 'Reduit Orchard', value: 'Reduit Orchard' },
  { label: 'Reduit Park', value: 'Reduit Park' },
  { label: 'Reduto', value: 'Reduto' },
  { label: 'Redwater', value: 'Redwater' },
  { label: 'Redway', value: 'Redway' },
  { label: 'Redwitz An Der Rodach', value: 'Redwitz An Der Rodach' },
  { label: 'Redwood', value: 'Redwood' },
  { label: 'Redwood', value: 'Redwood' },
  { label: 'Redwood', value: 'Redwood' },
  { label: 'Redwood City', value: 'Redwood City' },
  { label: 'Redwood County', value: 'Redwood County' },
  { label: 'Redwood Falls', value: 'Redwood Falls' },
  { label: 'Redwood Park', value: 'Redwood Park' },
  { label: 'Redwood Shores', value: 'Redwood Shores' },
  { label: 'Redwood Valley', value: 'Redwood Valley' },
  { label: 'Rędziny', value: 'Rędziny' },
  { label: 'Reed City', value: 'Reed City' },
  { label: 'Reed Creek', value: 'Reed Creek' },
  { label: 'Reedham', value: 'Reedham' },
  { label: 'Reedley', value: 'Reedley' },
  { label: 'Reedsburg', value: 'Reedsburg' },
  { label: 'Reedsport', value: 'Reedsport' },
  { label: 'Reedsville', value: 'Reedsville' },
  { label: 'Reedy Creek', value: 'Reedy Creek' },
  { label: 'Reef Al Fujairah City', value: 'Reef Al Fujairah City' },
  { label: 'Reek', value: 'Reek' },
  { label: 'Reepham', value: 'Reepham' },
  { label: 'Rees', value: 'Rees' },
  { label: 'Reese', value: 'Reese' },
  { label: 'Reeßum', value: 'Reeßum' },
  { label: 'Reeuwijk', value: 'Reeuwijk' },
  { label: 'Reeves County', value: 'Reeves County' },
  { label: 'Refahiye', value: 'Refahiye' },
  { label: 'Refojos De Basto', value: 'Refojos De Basto' },
  { label: 'Reform', value: 'Reform' },
  { label: 'Reforma', value: 'Reforma' },
  { label: 'Reforma', value: 'Reforma' },
  { label: 'Reforma De Pineda', value: 'Reforma De Pineda' },
  { label: 'Reforma Y Planada', value: 'Reforma Y Planada' },
  { label: 'Refrancore', value: 'Refrancore' },
  { label: 'Refrontolo', value: 'Refrontolo' },
  { label: 'Reftele', value: 'Reftele' },
  { label: 'Reftinskiy', value: 'Reftinskiy' },
  { label: 'Refuge', value: 'Refuge' },
  { label: 'Refugio', value: 'Refugio' },
  { label: 'Refugio County', value: 'Refugio County' },
  { label: 'Regalbuto', value: 'Regalbuto' },
  { label: 'Regans Ford', value: 'Regans Ford' },
  { label: 'Regau', value: 'Regau' },
  { label: 'Regen', value: 'Regen' },
  { label: 'Regencós', value: 'Regencós' },
  { label: 'Regency Downs', value: 'Regency Downs' },
  { label: 'Regeneração', value: 'Regeneração' },
  { label: 'Regensburg', value: 'Regensburg' },
  { label: 'Regensdorf', value: 'Regensdorf' },
  { label: 'Regensdorf / Feldblumen-Riedthofstrasse', value: 'Regensdorf / Feldblumen-Riedthofstrasse' },
  { label: 'Regensdorf / Hofacher-Geeren', value: 'Regensdorf / Hofacher-Geeren' },
  { label: 'Regensdorf / Obstgarten', value: 'Regensdorf / Obstgarten' },
  { label: 'Regenstauf', value: 'Regenstauf' },
  { label: 'Regente Feijó', value: 'Regente Feijó' },
  { label: 'Regents Park', value: 'Regents Park' },
  { label: 'Regents Park', value: 'Regents Park' },
  { label: 'Regesbostel', value: 'Regesbostel' },
  { label: 'Reggada', value: 'Reggada' },
  { label: 'Reggane', value: 'Reggane' },
  { label: 'Reggello', value: 'Reggello' },
  { label: 'Reggio Calabria', value: 'Reggio Calabria' },
  { label: 'Reggio Nellemilia', value: 'Reggio Nellemilia' },
  { label: 'Reggiolo', value: 'Reggiolo' },
  { label: 'Reghaïa', value: 'Reghaïa' },
  { label: 'Reghin', value: 'Reghin' },
  { label: 'Reghiu', value: 'Reghiu' },
  { label: 'Regidor', value: 'Regidor' },
  { label: 'Regierungsbezirk Arnsberg', value: 'Regierungsbezirk Arnsberg' },
  { label: 'Regierungsbezirk Darmstadt', value: 'Regierungsbezirk Darmstadt' },
  { label: 'Regierungsbezirk Detmold', value: 'Regierungsbezirk Detmold' },
  { label: 'Regierungsbezirk Gießen', value: 'Regierungsbezirk Gießen' },
  { label: 'Regierungsbezirk Kassel', value: 'Regierungsbezirk Kassel' },
  { label: 'Regierungsbezirk Köln', value: 'Regierungsbezirk Köln' },
  { label: 'Regierungsbezirk Mittelfranken', value: 'Regierungsbezirk Mittelfranken' },
  { label: 'Regierungsbezirk Münster', value: 'Regierungsbezirk Münster' },
  { label: 'Regierungsbezirk Stuttgart', value: 'Regierungsbezirk Stuttgart' },
  { label: 'Regierungsbezirk Unterfranken', value: 'Regierungsbezirk Unterfranken' },
  { label: 'Regimin', value: 'Regimin' },
  { label: 'Regina', value: 'Regina' },
  { label: 'Regina Beach', value: 'Regina Beach' },
  { label: 'Reginópolis', value: 'Reginópolis' },
  { label: 'Region Albula', value: 'Region Albula' },
  { label: 'Region Bernina', value: 'Region Bernina' },
  { label: 'Region Engiadina Bassa / Val Müstair', value: 'Region Engiadina Bassa / Val Müstair' },
  { label: 'Region Imboden', value: 'Region Imboden' },
  { label: 'Region Landquart', value: 'Region Landquart' },
  { label: 'Region Maloja', value: 'Region Maloja' },
  { label: 'Region Moesa', value: 'Region Moesa' },
  { label: 'Region Plessur', value: 'Region Plessur' },
  { label: 'Region Prättigau / Davos', value: 'Region Prättigau / Davos' },
  { label: 'Region Surselva', value: 'Region Surselva' },
  { label: 'Region Viamala', value: 'Region Viamala' },
  { label: 'Regional District Of Alberni-Clayoquot', value: 'Regional District Of Alberni-Clayoquot' },
  { label: 'Regional District Of Central Okanagan', value: 'Regional District Of Central Okanagan' },
  { label: 'Regional Municipality Of Waterloo', value: 'Regional Municipality Of Waterloo' },
  { label: 'Regis-Breitingen', value: 'Regis-Breitingen' },
  { label: 'Registro', value: 'Registro' },
  { label: 'Regla', value: 'Regla' },
  { label: 'Regnitzlosau', value: 'Regnitzlosau' },
  { label: 'Regnów', value: 'Regnów' },
  { label: 'Régny', value: 'Régny' },
  { label: 'Rego Park', value: 'Rego Park' },
  { label: 'Regoledo', value: 'Regoledo' },
  { label: 'Regolelli', value: 'Regolelli' },
  { label: 'Reguengo Grande', value: 'Reguengo Grande' },
  { label: 'Reguengos De Monsaraz', value: 'Reguengos De Monsaraz' },
  { label: 'Regueras De Arriba', value: 'Regueras De Arriba' },
  { label: 'Regueras Las', value: 'Regueras Las' },
  { label: 'Reguiba', value: 'Reguiba' },
  { label: 'Réguiny', value: 'Réguiny' },
  { label: 'Réguisheim', value: 'Réguisheim' },
  { label: 'Regulice', value: 'Regulice' },
  { label: 'Regumiel De La Sierra', value: 'Regumiel De La Sierra' },
  { label: 'Régusse', value: 'Régusse' },
  { label: 'Reha', value: 'Reha' },
  { label: 'Rehab', value: 'Rehab' },
  { label: 'Rehamna', value: 'Rehamna' },
  { label: 'Rehar', value: 'Rehar' },
  { label: 'Rehau', value: 'Rehau' },
  { label: 'Rehberg', value: 'Rehberg' },
  { label: 'Rehburg-Loccum', value: 'Rehburg-Loccum' },
  { label: 'Rehden', value: 'Rehden' },
  { label: 'Rehe', value: 'Rehe' },
  { label: 'Rehetobel', value: 'Rehetobel' },
  { label: 'Rehfelde', value: 'Rehfelde' },
  { label: 'Rehli', value: 'Rehli' },
  { label: 'Rehling', value: 'Rehling' },
  { label: 'Řehlovice', value: 'Řehlovice' },
  { label: 'Rehna', value: 'Rehna' },
  { label: 'Rehnan', value: 'Rehnan' },
  { label: 'Rehobeth', value: 'Rehobeth' },
  { label: 'Rehoboth', value: 'Rehoboth' },
  { label: 'Rehoboth', value: 'Rehoboth' },
  { label: 'Rehoboth Beach', value: 'Rehoboth Beach' },
  { label: 'Réhon', value: 'Réhon' },
  { label: 'Reẖovot', value: 'Reẖovot' },
  { label: 'Rehti', value: 'Rehti' },
  { label: 'Reichardtswerben', value: 'Reichardtswerben' },
  { label: 'Reichartshausen', value: 'Reichartshausen' },
  { label: 'Reichelsheim', value: 'Reichelsheim' },
  { label: 'Reichenau', value: 'Reichenau' },
  { label: 'Reichenau', value: 'Reichenau' },
  { label: 'Reichenau An Der Rax', value: 'Reichenau An Der Rax' },
  { label: 'Reichenau Im Mühlkreis', value: 'Reichenau Im Mühlkreis' },
  { label: 'Reichenbach', value: 'Reichenbach' },
  { label: 'Reichenbach', value: 'Reichenbach' },
  { label: 'Reichenbach An Der Fils', value: 'Reichenbach An Der Fils' },
  { label: 'Reichenbach/Vogtland', value: 'Reichenbach/Vogtland' },
  { label: 'Reichenbach-Steegen', value: 'Reichenbach-Steegen' },
  { label: 'Reichenberg', value: 'Reichenberg' },
  { label: 'Reichenburg', value: 'Reichenburg' },
  { label: 'Reichendorf', value: 'Reichendorf' },
  { label: 'Reichenfels', value: 'Reichenfels' },
  { label: 'Reichenschwand', value: 'Reichenschwand' },
  { label: 'Reichenthal', value: 'Reichenthal' },
  { label: 'Reichenwalde', value: 'Reichenwalde' },
  { label: 'Reichersbeuern', value: 'Reichersbeuern' },
  { label: 'Reichertshausen', value: 'Reichertshausen' },
  { label: 'Reichertsheim', value: 'Reichertsheim' },
  { label: 'Reichertshofen', value: 'Reichertshofen' },
  { label: 'Reichling', value: 'Reichling' },
  { label: 'Reichraming', value: 'Reichraming' },
  { label: 'Reichshoffen', value: 'Reichshoffen' },
  { label: 'Reichstett', value: 'Reichstett' },
  { label: 'Reid', value: 'Reid' },
  { label: 'Reiden', value: 'Reiden' },
  { label: 'Reidland', value: 'Reidland' },
  { label: 'Reidsville', value: 'Reidsville' },
  { label: 'Reidsville', value: 'Reidsville' },
  { label: 'Reiffton', value: 'Reiffton' },
  { label: 'Reifling', value: 'Reifling' },
  { label: 'Reigada', value: 'Reigada' },
  { label: 'Reigate', value: 'Reigate' },
  { label: 'Reignac', value: 'Reignac' },
  { label: 'Reignac-Sur-Indre', value: 'Reignac-Sur-Indre' },
  { label: 'Reignier-Ésery', value: 'Reignier-Ésery' },
  { label: 'Reigoldswil', value: 'Reigoldswil' },
  { label: 'Reil', value: 'Reil' },
  { label: 'Reilingen', value: 'Reilingen' },
  { label: 'Reillanne', value: 'Reillanne' },
  { label: 'Reíllo', value: 'Reíllo' },
  { label: 'Reimlingen', value: 'Reimlingen' },
  { label: 'Reims', value: 'Reims' },
  { label: 'Reina', value: 'Reina' },
  { label: 'Reina Mercedes', value: 'Reina Mercedes' },
  { label: 'Reina Mercedes', value: 'Reina Mercedes' },
  { label: 'Reinach', value: 'Reinach' },
  { label: 'Reinbach', value: 'Reinbach' },
  { label: 'Reinbeck', value: 'Reinbeck' },
  { label: 'Reinbek', value: 'Reinbek' },
  { label: 'Reinberg', value: 'Reinberg' },
  { label: 'Reine', value: 'Reine' },
  { label: 'Reinfeld', value: 'Reinfeld' },
  { label: 'Reingers', value: 'Reingers' },
  { label: 'Reinhardshausen', value: 'Reinhardshausen' },
  { label: 'Reinhardtsgrimma', value: 'Reinhardtsgrimma' },
  { label: 'Reinhausen', value: 'Reinhausen' },
  { label: 'Reinheim', value: 'Reinheim' },
  { label: 'Reinholds', value: 'Reinholds' },
  { label: 'Reinickendorf', value: 'Reinickendorf' },
  { label: 'Reiningue', value: 'Reiningue' },
  { label: 'Reino', value: 'Reino' },
  { label: 'Reinosa', value: 'Reinosa' },
  { label: 'Reinoso', value: 'Reinoso' },
  { label: 'Reinoso De Cerrato', value: 'Reinoso De Cerrato' },
  { label: 'Reinsberg', value: 'Reinsberg' },
  { label: 'Reinsberg', value: 'Reinsberg' },
  { label: 'Reinsdorf', value: 'Reinsdorf' },
  { label: 'Reinsfeld', value: 'Reinsfeld' },
  { label: 'Reinstorf', value: 'Reinstorf' },
  { label: 'Reinsvoll', value: 'Reinsvoll' },
  { label: 'Reisbach', value: 'Reisbach' },
  { label: 'Reischach', value: 'Reischach' },
  { label: 'Reisdorf', value: 'Reisdorf' },
  { label: 'Reisenberg', value: 'Reisenberg' },
  { label: 'Reisjärvi', value: 'Reisjärvi' },
  { label: 'Reiskirchen', value: 'Reiskirchen' },
  { label: 'Reißeck', value: 'Reißeck' },
  { label: 'Reisterstown', value: 'Reisterstown' },
  { label: 'Reit Im Winkl', value: 'Reit Im Winkl' },
  { label: 'Reitano', value: 'Reitano' },
  { label: 'Reitberg', value: 'Reitberg' },
  { label: 'Reitdiep', value: 'Reitdiep' },
  { label: 'Reiterndorf', value: 'Reiterndorf' },
  { label: 'Reith Bei Kitzbühel', value: 'Reith Bei Kitzbühel' },
  { label: 'Reith Bei Seefeld', value: 'Reith Bei Seefeld' },
  { label: 'Reith Im Alpbachtal', value: 'Reith Im Alpbachtal' },
  { label: 'Reitoca', value: 'Reitoca' },
  { label: 'Reitz', value: 'Reitz' },
  { label: 'Rejon Alei Kromera', value: 'Rejon Alei Kromera' },
  { label: 'Rejon Placu Grunwaldzkiego', value: 'Rejon Placu Grunwaldzkiego' },
  { label: 'Rejon Placu Świętego Macieja', value: 'Rejon Placu Świętego Macieja' },
  { label: 'Rejon Ulicy Borowskiej-Południe', value: 'Rejon Ulicy Borowskiej-Południe' },
  { label: 'Rejon Ulicy Klęczkowskiej', value: 'Rejon Ulicy Klęczkowskiej' },
  { label: 'Rejon Ulicy Mieleckiej', value: 'Rejon Ulicy Mieleckiej' },
  { label: 'Rejon Ulicy Saperów', value: 'Rejon Ulicy Saperów' },
  { label: 'Rejon Ulicy Traugutta', value: 'Rejon Ulicy Traugutta' },
  { label: 'Rejowiec', value: 'Rejowiec' },
  { label: 'Rejowiec Fabryczny', value: 'Rejowiec Fabryczny' },
  { label: 'Reka', value: 'Reka' },
  { label: 'Rekhasim', value: 'Rekhasim' },
  { label: 'Rekowo Dolne', value: 'Rekowo Dolne' },
  { label: 'Relizane', value: 'Relizane' },
  { label: 'Relleu', value: 'Relleu' },
  { label: 'Rellinars', value: 'Rellinars' },
  { label: 'Rellingen', value: 'Rellingen' },
  { label: 'Rello', value: 'Rello' },
  { label: 'Relva', value: 'Relva' },
  { label: 'Relvado', value: 'Relvado' },
  { label: 'Remada', value: 'Remada' },
  { label: 'Remagen', value: 'Remagen' },
  { label: 'Rémalard', value: 'Rémalard' },
  { label: 'Remanso', value: 'Remanso' },
  { label: 'Remanzacco', value: 'Remanzacco' },
  { label: 'Rembangan', value: 'Rembangan' },
  { label: 'Rembertów', value: 'Rembertów' },
  { label: 'Remchi', value: 'Remchi' },
  { label: 'Remecioara', value: 'Remecioara' },
  { label: 'Remedello', value: 'Remedello' },
  { label: 'Remedello Di Sopra', value: 'Remedello Di Sopra' },
  { label: 'Remedios', value: 'Remedios' },
  { label: 'Remedios', value: 'Remedios' },
  { label: 'Rémelfing', value: 'Rémelfing' },
  { label: 'Remerton', value: 'Remerton' },
  { label: 'Remetea', value: 'Remetea' },
  { label: 'Remetea', value: 'Remetea' },
  { label: 'Remetea', value: 'Remetea' },
  { label: 'Remetea Chioarului', value: 'Remetea Chioarului' },
  { label: 'Remetea Mare', value: 'Remetea Mare' },
  { label: 'Remeţi', value: 'Remeţi' },
  { label: 'Remeți Pe Someș', value: 'Remeți Pe Someș' },
  { label: 'Remetinec', value: 'Remetinec' },
  { label: 'Remexio', value: 'Remexio' },
  { label: 'Remich', value: 'Remich' },
  { label: 'Remicourt', value: 'Remicourt' },
  { label: 'Remígio', value: 'Remígio' },
  { label: 'Rémilly', value: 'Rémilly' },
  { label: 'Reminderville', value: 'Reminderville' },
  { label: 'Remington', value: 'Remington' },
  { label: 'Remiremont', value: 'Remiremont' },
  { label: 'Remlingen', value: 'Remlingen' },
  { label: 'Remlingen', value: 'Remlingen' },
  { label: 'Remolino', value: 'Remolino' },
  { label: 'Remolinos', value: 'Remolinos' },
  { label: 'Remondo', value: 'Remondo' },
  { label: 'Remontnoye', value: 'Remontnoye' },
  { label: 'Remouillé', value: 'Remouillé' },
  { label: 'Remoulins', value: 'Remoulins' },
  { label: 'Remptendorf', value: 'Remptendorf' },
  { label: 'Remscheid', value: 'Remscheid' },
  { label: 'Remse', value: 'Remse' },
  { label: 'Remsen', value: 'Remsen' },
  { label: 'Remsenburg-Speonk', value: 'Remsenburg-Speonk' },
  { label: 'Remuna', value: 'Remuna' },
  { label: 'Remuș', value: 'Remuș' },
  { label: 'Remy', value: 'Remy' },
  { label: 'Rena', value: 'Rena' },
  { label: 'Rena', value: 'Rena' },
  { label: 'Renage', value: 'Renage' },
  { label: 'Renaico', value: 'Renaico' },
  { label: 'Renaison', value: 'Renaison' },
  { label: 'Renala Khurd', value: 'Renala Khurd' },
  { label: 'Renapur', value: 'Renapur' },
  { label: 'Renascença', value: 'Renascença' },
  { label: 'Renate', value: 'Renate' },
  { label: 'Renau', value: 'Renau' },
  { label: 'Renavi', value: 'Renavi' },
  { label: 'Renazé', value: 'Renazé' },
  { label: 'Renazzo', value: 'Renazzo' },
  { label: 'Renca', value: 'Renca' },
  { label: 'Renče', value: 'Renče' },
  { label: 'Renchen', value: 'Renchen' },
  { label: 'Rendalen', value: 'Rendalen' },
  { label: 'Rende', value: 'Rende' },
  { label: 'Rendeux', value: 'Rendeux' },
  { label: 'Rendlesham', value: 'Rendlesham' },
  { label: 'Rendo', value: 'Rendo' },
  { label: 'Rendon', value: 'Rendon' },
  { label: 'Rendsburg', value: 'Rendsburg' },
  { label: 'Rendufe', value: 'Rendufe' },
  { label: 'Renedo De Esgueva', value: 'Renedo De Esgueva' },
  { label: 'Renedo De La Vega', value: 'Renedo De La Vega' },
  { label: 'Renens', value: 'Renens' },
  { label: 'Renera', value: 'Renera' },
  { label: 'Renescure', value: 'Renescure' },
  { label: 'Renesse', value: 'Renesse' },
  { label: 'Renfrew', value: 'Renfrew' },
  { label: 'Renfrew', value: 'Renfrew' },
  { label: 'Renfrewshire', value: 'Renfrewshire' },
  { label: 'Rengali', value: 'Rengali' },
  { label: 'Rengasdengklok', value: 'Rengasdengklok' },
  { label: 'Rengo', value: 'Rengo' },
  { label: 'Rengsdorf', value: 'Rengsdorf' },
  { label: 'Reni', value: 'Reni' },
  { label: 'Renieblas', value: 'Renieblas' },
  { label: 'Renigunta', value: 'Renigunta' },
  { label: 'Renko', value: 'Renko' },
  { label: 'Renkum', value: 'Renkum' },
  { label: 'Renmark', value: 'Renmark' },
  { label: 'Renmark Paringa', value: 'Renmark Paringa' },
  { label: 'Renmark West', value: 'Renmark West' },
  { label: 'Rennebu', value: 'Rennebu' },
  { label: 'Rennerdale', value: 'Rennerdale' },
  { label: 'Rennerod', value: 'Rennerod' },
  { label: 'Rennertshofen', value: 'Rennertshofen' },
  { label: 'Rennes', value: 'Rennes' },
  { label: 'Rennesøy', value: 'Rennesøy' },
  { label: 'Renningen', value: 'Renningen' },
  { label: 'Rennweg Am Katschberg', value: 'Rennweg Am Katschberg' },
  { label: 'Reno', value: 'Reno' },
  { label: 'Reno', value: 'Reno' },
  { label: 'Reno', value: 'Reno' },
  { label: 'Reno County', value: 'Reno County' },
  { label: 'Renon - Ritten', value: 'Renon - Ritten' },
  { label: 'Renovo', value: 'Renovo' },
  { label: 'Renown Park', value: 'Renown Park' },
  { label: 'Renqiu', value: 'Renqiu' },
  { label: 'Reńska Wieś', value: 'Reńska Wieś' },
  { label: 'Rensselaer', value: 'Rensselaer' },
  { label: 'Rensselaer', value: 'Rensselaer' },
  { label: 'Rensselaer County', value: 'Rensselaer County' },
  { label: 'Rensvik', value: 'Rensvik' },
  { label: 'Renswoude', value: 'Renswoude' },
  { label: 'Renton', value: 'Renton' },
  { label: 'Renton', value: 'Renton' },
  { label: 'Rentweinsdorf', value: 'Rentweinsdorf' },
  { label: 'Renukoot', value: 'Renukoot' },
  { label: 'Renukut', value: 'Renukut' },
  { label: 'Renville', value: 'Renville' },
  { label: 'Renville County', value: 'Renville County' },
  { label: 'Renville County', value: 'Renville County' },
  { label: 'Renwez', value: 'Renwez' },
  { label: 'Réo', value: 'Réo' },
  { label: 'Reocín', value: 'Reocín' },
  { label: 'Reoti', value: 'Reoti' },
  { label: 'Reotipur', value: 'Reotipur' },
  { label: 'Rep’Yevskiy Rayon', value: 'Rep’Yevskiy Rayon' },
  { label: 'Repalle', value: 'Repalle' },
  { label: 'Repatriación', value: 'Repatriación' },
  { label: 'Répcelak', value: 'Répcelak' },
  { label: 'Repedea', value: 'Repedea' },
  { label: 'Repelón', value: 'Repelón' },
  { label: 'Repentigny', value: 'Repentigny' },
  { label: 'Repino', value: 'Repino' },
  { label: 'Repino', value: 'Repino' },
  { label: 'Řepiště', value: 'Řepiště' },
  { label: 'Repki', value: 'Repki' },
  { label: 'Replonges', value: 'Replonges' },
  { label: 'Replot', value: 'Replot' },
  { label: 'Repnoye', value: 'Repnoye' },
  { label: 'Reppenstedt', value: 'Reppenstedt' },
  { label: 'Repton', value: 'Repton' },
  { label: 'Republic', value: 'Republic' },
  { label: 'Republic', value: 'Republic' },
  { label: 'Republic', value: 'Republic' },
  { label: 'Republic County', value: 'Republic County' },
  { label: 'Repušnica', value: 'Repušnica' },
  { label: 'Reque', value: 'Reque' },
  { label: 'Requeil', value: 'Requeil' },
  { label: 'Requejo', value: 'Requejo' },
  { label: 'Requena', value: 'Requena' },
  { label: 'Requena', value: 'Requena' },
  { label: 'Requena De Campos', value: 'Requena De Campos' },
  { label: 'Requínoa', value: 'Requínoa' },
  { label: 'Réquista', value: 'Réquista' },
  { label: 'Reriutaba', value: 'Reriutaba' },
  { label: 'Reşadiye', value: 'Reşadiye' },
  { label: 'Resana', value: 'Resana' },
  { label: 'Resarö', value: 'Resarö' },
  { label: 'Rescaldina', value: 'Rescaldina' },
  { label: 'Research', value: 'Research' },
  { label: 'Resen', value: 'Resen' },
  { label: 'Resende', value: 'Resende' },
  { label: 'Resende', value: 'Resende' },
  { label: 'Resende Costa', value: 'Resende Costa' },
  { label: 'Reserva', value: 'Reserva' },
  { label: 'Reserva Do Cabaçal', value: 'Reserva Do Cabaçal' },
  { label: 'Reserva Do Iguaçu', value: 'Reserva Do Iguaçu' },
  { label: 'Reserve', value: 'Reserve' },
  { label: 'Reserve', value: 'Reserve' },
  { label: 'Reservoir', value: 'Reservoir' },
  { label: 'Rešetari', value: 'Rešetari' },
  { label: 'Reshetikha', value: 'Reshetikha' },
  { label: 'Reshetnikovo', value: 'Reshetnikovo' },
  { label: 'Reshetylivka', value: 'Reshetylivka' },
  { label: 'Residencial Arboledas', value: 'Residencial Arboledas' },
  { label: 'Residencial Las Olas', value: 'Residencial Las Olas' },
  { label: 'Residencial Tajín', value: 'Residencial Tajín' },
  { label: 'Resinard/Babonneau', value: 'Resinard/Babonneau' },
  { label: 'Resistencia', value: 'Resistencia' },
  { label: 'Reşiţa', value: 'Reşiţa' },
  { label: 'Resiutta', value: 'Resiutta' },
  { label: 'Resko', value: 'Resko' },
  { label: 'Resolven', value: 'Resolven' },
  { label: 'Respenda De La Peña', value: 'Respenda De La Peña' },
  { label: 'Resplendor', value: 'Resplendor' },
  { label: 'Ressano Garcia', value: 'Ressano Garcia' },
  { label: 'Ressaquinha', value: 'Ressaquinha' },
  { label: 'Ressons-Sur-Matz', value: 'Ressons-Sur-Matz' },
  { label: 'Restauración', value: 'Restauración' },
  { label: 'Restigné', value: 'Restigné' },
  { label: 'Restinclières', value: 'Restinclières' },
  { label: 'Restinga', value: 'Restinga' },
  { label: 'Restinga Sêca', value: 'Restinga Sêca' },
  { label: 'Reston', value: 'Reston' },
  { label: 'Restrepo', value: 'Restrepo' },
  { label: 'Restrepo', value: 'Restrepo' },
  { label: 'Resuttano', value: 'Resuttano' },
  { label: 'Reszel', value: 'Reszel' },
  { label: 'Retalhuleu', value: 'Retalhuleu' },
  { label: 'Retamal De Llerena', value: 'Retamal De Llerena' },
  { label: 'Retamoso De La Jara', value: 'Retamoso De La Jara' },
  { label: 'Retascón', value: 'Retascón' },
  { label: 'Reteag', value: 'Reteag' },
  { label: 'Retevoiești', value: 'Retevoiești' },
  { label: 'Retford', value: 'Retford' },
  { label: 'Retgendorf', value: 'Retgendorf' },
  { label: 'Rethel', value: 'Rethel' },
  { label: 'Rethem', value: 'Rethem' },
  { label: 'Rethen', value: 'Rethen' },
  { label: 'Rethymno', value: 'Rethymno' },
  { label: 'Retie', value: 'Retie' },
  { label: 'Retiendas', value: 'Retiendas' },
  { label: 'Retiers', value: 'Retiers' },
  { label: 'Retirement', value: 'Retirement' },
  { label: 'Retirement', value: 'Retirement' },
  { label: 'Retiro', value: 'Retiro' },
  { label: 'Retiro', value: 'Retiro' },
  { label: 'Retiro', value: 'Retiro' },
  { label: 'Retiro', value: 'Retiro' },
  { label: 'Retirolândia', value: 'Retirolândia' },
  { label: 'Retkovci', value: 'Retkovci' },
  { label: 'Retonfey', value: 'Retonfey' },
  { label: 'Retorbido', value: 'Retorbido' },
  { label: 'Retortillo', value: 'Retortillo' },
  { label: 'Retortillo De Soria', value: 'Retortillo De Soria' },
  { label: 'Retournac', value: 'Retournac' },
  { label: 'Retreat', value: 'Retreat' },
  { label: 'Retreat', value: 'Retreat' },
  { label: 'Retreat', value: 'Retreat' },
  { label: 'Retrieve', value: 'Retrieve' },
  { label: 'Rétság', value: 'Rétság' },
  { label: 'Rétsági Járás', value: 'Rétsági Járás' },
  { label: 'Retschow', value: 'Retschow' },
  { label: 'Rettenbach', value: 'Rettenbach' },
  { label: 'Rettenberg', value: 'Rettenberg' },
  { label: 'Rettenegg', value: 'Rettenegg' },
  { label: 'Rettenschöss', value: 'Rettenschöss' },
  { label: 'Rettifilo-Vannullo', value: 'Rettifilo-Vannullo' },
  { label: 'Rettikhovka', value: 'Rettikhovka' },
  { label: 'Retuerta', value: 'Retuerta' },
  { label: 'Retuerta Del Bullaque', value: 'Retuerta Del Bullaque' },
  { label: 'Rety', value: 'Rety' },
  { label: 'Retz', value: 'Retz' },
  { label: 'Retznei', value: 'Retznei' },
  { label: 'Retzstadt', value: 'Retzstadt' },
  { label: 'Reugny', value: 'Reugny' },
  { label: 'Reuilly', value: 'Reuilly' },
  { label: 'Reuleuet', value: 'Reuleuet' },
  { label: 'Reunion', value: 'Reunion' },
  { label: 'Reus', value: 'Reus' },
  { label: 'Reusel', value: 'Reusel' },
  { label: 'Reußen', value: 'Reußen' },
  { label: 'Reut', value: 'Reut' },
  { label: 'Reuth', value: 'Reuth' },
  { label: 'Reuth', value: 'Reuth' },
  { label: 'Reuthe', value: 'Reuthe' },
  { label: 'Reutlingen', value: 'Reutlingen' },
  { label: 'Reutov', value: 'Reutov' },
  { label: 'Reutte', value: 'Reutte' },
  { label: 'Reuver', value: 'Reuver' },
  { label: 'Revadanda', value: 'Revadanda' },
  { label: 'Revda', value: 'Revda' },
  { label: 'Revda', value: 'Revda' },
  { label: 'Revdanda', value: 'Revdanda' },
  { label: 'Revel', value: 'Revel' },
  { label: 'Revel', value: 'Revel' },
  { label: 'Revelganj', value: 'Revelganj' },
  { label: 'Revellinos', value: 'Revellinos' },
  { label: 'Revello', value: 'Revello' },
  { label: 'Revelstoke', value: 'Revelstoke' },
  { label: 'Revenga De Campos', value: 'Revenga De Campos' },
  { label: 'Reventin-Vaugris', value: 'Reventin-Vaugris' },
  { label: 'Revere', value: 'Revere' },
  { label: 'Revere', value: 'Revere' },
  { label: 'Revesby', value: 'Revesby' },
  { label: 'Revesby Heights', value: 'Revesby Heights' },
  { label: 'Révfülöp', value: 'Révfülöp' },
  { label: 'Reviga', value: 'Reviga' },
  { label: 'Revigliasco', value: 'Revigliasco' },
  { label: 'Revigliasco Dasti', value: 'Revigliasco Dasti' },
  { label: 'Revigny-Sur-Ornain', value: 'Revigny-Sur-Ornain' },
  { label: 'Revilla De Collazos', value: 'Revilla De Collazos' },
  { label: 'Revilla Del Campo', value: 'Revilla Del Campo' },
  { label: 'Revilla Vallejera', value: 'Revilla Vallejera' },
  { label: 'Revilla Y Ahedo La', value: 'Revilla Y Ahedo La' },
  { label: 'Revillarruz', value: 'Revillarruz' },
  { label: 'Réville', value: 'Réville' },
  { label: 'Revin', value: 'Revin' },
  { label: 'Revine', value: 'Revine' },
  { label: 'Revival', value: 'Revival' },
  { label: 'Řevnice', value: 'Řevnice' },
  { label: 'Řevničov', value: 'Řevničov' },
  { label: 'Revò', value: 'Revò' },
  { label: 'Revolución Mexicana', value: 'Revolución Mexicana' },
  { label: 'Revúca', value: 'Revúca' },
  { label: 'Revyakino', value: 'Revyakino' },
  { label: 'Rewa', value: 'Rewa' },
  { label: 'Rewa Province', value: 'Rewa Province' },
  { label: 'Rewal', value: 'Rewal' },
  { label: 'Rewari', value: 'Rewari' },
  { label: 'Rexburg', value: 'Rexburg' },
  { label: 'Rexpoëde', value: 'Rexpoëde' },
  { label: 'Rey', value: 'Rey' },
  { label: 'Rey Bouba', value: 'Rey Bouba' },
  { label: 'Reyðarfjörður', value: 'Reyðarfjörður' },
  { label: 'Reyero', value: 'Reyero' },
  { label: 'Reyes', value: 'Reyes' },
  { label: 'Reyes Acozac', value: 'Reyes Acozac' },
  { label: 'Reyes Etla', value: 'Reyes Etla' },
  { label: 'Reyes Mantecón', value: 'Reyes Mantecón' },
  { label: 'Reyhan', value: 'Reyhan' },
  { label: 'Reyhanlı', value: 'Reyhanlı' },
  { label: 'Reykhólahreppur', value: 'Reykhólahreppur' },
  { label: 'Reykjanesbær', value: 'Reykjanesbær' },
  { label: 'Reykjavík', value: 'Reykjavík' },
  { label: 'Reynella', value: 'Reynella' },
  { label: 'Reynella East', value: 'Reynella East' },
  { label: 'Reynès', value: 'Reynès' },
  { label: 'Reynolds', value: 'Reynolds' },
  { label: 'Reynolds County', value: 'Reynolds County' },
  { label: 'Reynolds Heights', value: 'Reynolds Heights' },
  { label: 'Reynoldsburg', value: 'Reynoldsburg' },
  { label: 'Reynoldsville', value: 'Reynoldsville' },
  { label: 'Reynosa', value: 'Reynosa' },
  { label: 'Reyrieux', value: 'Reyrieux' },
  { label: 'Řež', value: 'Řež' },
  { label: 'Reza Baghi', value: 'Reza Baghi' },
  { label: 'Rezé', value: 'Rezé' },
  { label: 'Rēzekne', value: 'Rēzekne' },
  { label: 'Rezh', value: 'Rezh' },
  { label: 'Rezina', value: 'Rezina' },
  { label: 'Rezmondo', value: 'Rezmondo' },
  { label: 'Reznos', value: 'Reznos' },
  { label: 'Rezovac', value: 'Rezovac' },
  { label: 'Rezvankadeh', value: 'Rezvankadeh' },
  { label: 'Rezvanshahr', value: 'Rezvanshahr' },
  { label: 'Rezvanshahr', value: 'Rezvanshahr' },
  { label: 'Rezveh', value: 'Rezveh' },
  { label: 'Rezzago', value: 'Rezzago' },
  { label: 'Rezzato', value: 'Rezzato' },
  { label: 'Rezzo', value: 'Rezzo' },
  { label: 'Rezzoaglio', value: 'Rezzoaglio' },
  { label: 'Rhade', value: 'Rhade' },
  { label: 'Rhar El Melah', value: 'Rhar El Melah' },
  { label: 'Rhaunen', value: 'Rhaunen' },
  { label: 'Rhayader', value: 'Rhayader' },
  { label: 'Rhäzüns', value: 'Rhäzüns' },
  { label: 'Rhea County', value: 'Rhea County' },
  { label: 'Rheda-Wiedenbrück', value: 'Rheda-Wiedenbrück' },
  { label: 'Rhede', value: 'Rhede' },
  { label: 'Rhede', value: 'Rhede' },
  { label: 'Rheden', value: 'Rheden' },
  { label: 'Rheden', value: 'Rheden' },
  { label: 'Rheems', value: 'Rheems' },
  { label: 'Rheinau', value: 'Rheinau' },
  { label: 'Rheinbach', value: 'Rheinbach' },
  { label: 'Rheinberg', value: 'Rheinberg' },
  { label: 'Rheinböllen', value: 'Rheinböllen' },
  { label: 'Rheinbreitbach', value: 'Rheinbreitbach' },
  { label: 'Rheinbrohl', value: 'Rheinbrohl' },
  { label: 'Rheine', value: 'Rheine' },
  { label: 'Rheineck', value: 'Rheineck' },
  { label: 'Rheinfelden', value: 'Rheinfelden' },
  { label: 'Rheinfelden', value: 'Rheinfelden' },
  { label: 'Rheinsberg', value: 'Rheinsberg' },
  { label: 'Rheinstetten', value: 'Rheinstetten' },
  { label: 'Rheinzabern', value: 'Rheinzabern' },
  { label: 'Rhemes-Notre-Dame', value: 'Rhemes-Notre-Dame' },
  { label: 'Rhemes-Saint-Georges', value: 'Rhemes-Saint-Georges' },
  { label: 'Rhenen', value: 'Rhenen' },
  { label: 'Rhens', value: 'Rhens' },
  { label: 'Rheurdt', value: 'Rheurdt' },
  { label: 'Rhinau', value: 'Rhinau' },
  { label: 'Rhinebeck', value: 'Rhinebeck' },
  { label: 'Rhinelander', value: 'Rhinelander' },
  { label: 'Rhinow', value: 'Rhinow' },
  { label: 'Rho', value: 'Rho' },
  { label: 'Rhodes', value: 'Rhodes' },
  { label: 'Rhodhiss', value: 'Rhodhiss' },
  { label: 'Rhodt Unter Rietburg', value: 'Rhodt Unter Rietburg' },
  { label: 'Rhome', value: 'Rhome' },
  { label: 'Rhondda', value: 'Rhondda' },
  { label: 'Rhondda Cynon Taf', value: 'Rhondda Cynon Taf' },
  { label: 'Rhoon', value: 'Rhoon' },
  { label: 'Rhoose', value: 'Rhoose' },
  { label: 'Rhosllanerchrugog', value: 'Rhosllanerchrugog' },
  { label: 'Rhosneigr', value: 'Rhosneigr' },
  { label: 'Rhu', value: 'Rhu' },
  { label: 'Rhuddlan', value: 'Rhuddlan' },
  { label: 'Rhumspringe', value: 'Rhumspringe' },
  { label: 'Rhyl', value: 'Rhyl' },
  { label: 'Rhymney', value: 'Rhymney' },
  { label: 'Riace', value: 'Riace' },
  { label: 'Riace Marina', value: 'Riace Marina' },
  { label: 'Riachão', value: 'Riachão' },
  { label: 'Riachão', value: 'Riachão' },
  { label: 'Riachão Das Neves', value: 'Riachão Das Neves' },
  { label: 'Riachão Do Bacamarte', value: 'Riachão Do Bacamarte' },
  { label: 'Riachão Do Dantas', value: 'Riachão Do Dantas' },
  { label: 'Riachão Do Jacuípe', value: 'Riachão Do Jacuípe' },
  { label: 'Riachão Do Poço', value: 'Riachão Do Poço' },
  { label: 'Riachinho', value: 'Riachinho' },
  { label: 'Riachinho', value: 'Riachinho' },
  { label: 'Riacho Da Cruz', value: 'Riacho Da Cruz' },
  { label: 'Riacho Das Almas', value: 'Riacho Das Almas' },
  { label: 'Riacho De Santana', value: 'Riacho De Santana' },
  { label: 'Riacho De Santana', value: 'Riacho De Santana' },
  { label: 'Riacho De Santo Antônio', value: 'Riacho De Santo Antônio' },
  { label: 'Riacho Dos Cavalos', value: 'Riacho Dos Cavalos' },
  { label: 'Riacho Dos Machados', value: 'Riacho Dos Machados' },
  { label: 'Riacho Eh-Eh', value: 'Riacho Eh-Eh' },
  { label: 'Riacho Frio', value: 'Riacho Frio' },
  { label: 'Riachos', value: 'Riachos' },
  { label: 'Riachuelo', value: 'Riachuelo' },
  { label: 'Riachuelo', value: 'Riachuelo' },
  { label: 'Riachuelo', value: 'Riachuelo' },
  { label: 'Riaguas De San Bartolomé', value: 'Riaguas De San Bartolomé' },
  { label: 'Riaillé', value: 'Riaillé' },
  { label: 'Riale', value: 'Riale' },
  { label: 'Rialma', value: 'Rialma' },
  { label: 'Rialp', value: 'Rialp' },
  { label: 'Rialto', value: 'Rialto' },
  { label: 'Rialto', value: 'Rialto' },
  { label: 'Rialto', value: 'Rialto' },
  { label: 'Rianápolis', value: 'Rianápolis' },
  { label: 'Riano', value: 'Riano' },
  { label: 'Riaño', value: 'Riaño' },
  { label: 'Rians', value: 'Rians' },
  { label: 'Rians', value: 'Rians' },
  { label: 'Riantec', value: 'Riantec' },
  { label: 'Rianxo', value: 'Rianxo' },
  { label: 'Riardo', value: 'Riardo' },
  { label: 'Riasi', value: 'Riasi' },
  { label: 'Ria-Sirach', value: 'Ria-Sirach' },
  { label: 'Riaz', value: 'Riaz' },
  { label: 'Riaza', value: 'Riaza' },
  { label: 'Rib Mountain', value: 'Rib Mountain' },
  { label: 'Riba De Ave', value: 'Riba De Ave' },
  { label: 'Riba De Escalote La', value: 'Riba De Escalote La' },
  { label: 'Riba De Saelices', value: 'Riba De Saelices' },
  { label: 'Riba La', value: 'Riba La' },
  { label: 'Ribadavia', value: 'Ribadavia' },
  { label: 'Ribadedeva', value: 'Ribadedeva' },
  { label: 'Ribadeo', value: 'Ribadeo' },
  { label: 'Ribadesella', value: 'Ribadesella' },
  { label: 'Ribadumia', value: 'Ribadumia' },
  { label: 'Ribaforada', value: 'Ribaforada' },
  { label: 'Ribafrecha', value: 'Ribafrecha' },
  { label: 'Ribafria', value: 'Ribafria' },
  { label: 'Ribamar', value: 'Ribamar' },
  { label: 'Ribamar Fiquene', value: 'Ribamar Fiquene' },
  { label: 'Ribamondego', value: 'Ribamondego' },
  { label: 'Ribamontán Al Mar', value: 'Ribamontán Al Mar' },
  { label: 'Ribamontán Al Monte', value: 'Ribamontán Al Monte' },
  { label: 'Ribari', value: 'Ribari' },
  { label: 'Riba-Roja De Túria', value: 'Riba-Roja De Túria' },
  { label: 'Riba-Roja Debre', value: 'Riba-Roja Debre' },
  { label: 'Ribarroja Del Turia', value: 'Ribarroja Del Turia' },
  { label: 'Ribas De Campos', value: 'Ribas De Campos' },
  { label: 'Ribas De Sil', value: 'Ribas De Sil' },
  { label: 'Ribas Do Rio Pardo', value: 'Ribas Do Rio Pardo' },
  { label: 'Ribatejada', value: 'Ribatejada' },
  { label: 'Ribaute-Les-Tavernes', value: 'Ribaute-Les-Tavernes' },
  { label: 'Ribbesbüttel', value: 'Ribbesbüttel' },
  { label: 'Ribe', value: 'Ribe' },
  { label: 'Ribeauvillé', value: 'Ribeauvillé' },
  { label: 'Ribeira', value: 'Ribeira' },
  { label: 'Ribeira', value: 'Ribeira' },
  { label: 'Ribeira Brava', value: 'Ribeira Brava' },
  { label: 'Ribeira Brava', value: 'Ribeira Brava' },
  { label: 'Ribeira De Pena', value: 'Ribeira De Pena' },
  { label: 'Ribeira De Piquín', value: 'Ribeira De Piquín' },
  { label: 'Ribeira Do Amparo', value: 'Ribeira Do Amparo' },
  { label: 'Ribeira Do Piauí', value: 'Ribeira Do Piauí' },
  { label: 'Ribeira Do Pombal', value: 'Ribeira Do Pombal' },
  { label: 'Ribeira Dos Carinhos', value: 'Ribeira Dos Carinhos' },
  { label: 'Ribeira Grande', value: 'Ribeira Grande' },
  { label: 'Ribeira Grande', value: 'Ribeira Grande' },
  { label: 'Ribeira Seca', value: 'Ribeira Seca' },
  { label: 'Ribeirão', value: 'Ribeirão' },
  { label: 'Ribeirão', value: 'Ribeirão' },
  { label: 'Ribeirão Bonito', value: 'Ribeirão Bonito' },
  { label: 'Ribeirão Branco', value: 'Ribeirão Branco' },
  { label: 'Ribeirão Cascalheira', value: 'Ribeirão Cascalheira' },
  { label: 'Ribeirão Claro', value: 'Ribeirão Claro' },
  { label: 'Ribeirão Corrente', value: 'Ribeirão Corrente' },
  { label: 'Ribeirão Da Ilha', value: 'Ribeirão Da Ilha' },
  { label: 'Ribeirão Das Neves', value: 'Ribeirão Das Neves' },
  { label: 'Ribeirão Do Largo', value: 'Ribeirão Do Largo' },
  { label: 'Ribeirão Do Pinhal', value: 'Ribeirão Do Pinhal' },
  { label: 'Ribeirão Do Sul', value: 'Ribeirão Do Sul' },
  { label: 'Ribeirão Dos Índios', value: 'Ribeirão Dos Índios' },
  { label: 'Ribeirão Grande', value: 'Ribeirão Grande' },
  { label: 'Ribeirão Pires', value: 'Ribeirão Pires' },
  { label: 'Ribeirão Preto', value: 'Ribeirão Preto' },
  { label: 'Ribeirão Vermelho', value: 'Ribeirão Vermelho' },
  { label: 'Ribeirãozinho', value: 'Ribeirãozinho' },
  { label: 'Ribeirinha', value: 'Ribeirinha' },
  { label: 'Ribeiro Gonçalves', value: 'Ribeiro Gonçalves' },
  { label: 'Ribeirópolis', value: 'Ribeirópolis' },
  { label: 'Ribemont', value: 'Ribemont' },
  { label: 'Ribera', value: 'Ribera' },
  { label: 'Ribera Baja/Erribera Beitia', value: 'Ribera Baja/Erribera Beitia' },
  { label: 'Ribera De Arriba', value: 'Ribera De Arriba' },
  { label: 'Ribera Del Fresno', value: 'Ribera Del Fresno' },
  { label: 'Ribera Dondara', value: 'Ribera Dondara' },
  { label: 'Ribera Durgellet', value: 'Ribera Durgellet' },
  { label: 'Ribérac', value: 'Ribérac' },
  { label: 'Riberalta', value: 'Riberalta' },
  { label: 'Riberos De La Cueza', value: 'Riberos De La Cueza' },
  { label: 'Ribes De Freser', value: 'Ribes De Freser' },
  { label: 'Ribesalbes', value: 'Ribesalbes' },
  { label: 'Ri-Bhoi', value: 'Ri-Bhoi' },
  { label: 'Ribiţa', value: 'Ribiţa' },
  { label: 'Ribnica', value: 'Ribnica' },
  { label: 'Ribnica Na Pohorju', value: 'Ribnica Na Pohorju' },
  { label: 'Rîbniţa', value: 'Rîbniţa' },
  { label: 'Ribnitz-Damgarten', value: 'Ribnitz-Damgarten' },
  { label: 'Ribolla', value: 'Ribolla' },
  { label: 'Ribordone', value: 'Ribordone' },
  { label: 'Ribota', value: 'Ribota' },
  { label: 'Ricadi', value: 'Ricadi' },
  { label: 'Ricaldone', value: 'Ricaldone' },
  { label: 'Říčany', value: 'Říčany' },
  { label: 'Říčany', value: 'Říčany' },
  { label: 'Ricardo', value: 'Ricardo' },
  { label: 'Ricardo Flores Magón', value: 'Ricardo Flores Magón' },
  { label: 'Ricardo Flores Magón', value: 'Ricardo Flores Magón' },
  { label: 'Ricardo Flores Magón', value: 'Ricardo Flores Magón' },
  { label: 'Ricardo Flores Magón', value: 'Ricardo Flores Magón' },
  { label: 'Ricardo Flores Magón', value: 'Ricardo Flores Magón' },
  { label: 'Ricardo Flores Magón', value: 'Ricardo Flores Magón' },
  { label: 'Ricardo Palma', value: 'Ricardo Palma' },
  { label: 'Ricaurte', value: 'Ricaurte' },
  { label: 'Ricaurte', value: 'Ricaurte' },
  { label: 'Ricca', value: 'Ricca' },
  { label: 'Riccall', value: 'Riccall' },
  { label: 'Riccia', value: 'Riccia' },
  { label: 'Riccione', value: 'Riccione' },
  { label: 'Riccò', value: 'Riccò' },
  { label: 'Riccò Del Golfo', value: 'Riccò Del Golfo' },
  { label: 'Rice', value: 'Rice' },
  { label: 'Rice County', value: 'Rice County' },
  { label: 'Rice County', value: 'Rice County' },
  { label: 'Rice Lake', value: 'Rice Lake' },
  { label: 'Ricengo', value: 'Ricengo' },
  { label: 'Rich County', value: 'Rich County' },
  { label: 'Rich Hill', value: 'Rich Hill' },
  { label: 'Richardménil', value: 'Richardménil' },
  { label: 'Richards Bay', value: 'Richards Bay' },
  { label: 'Richardson', value: 'Richardson' },
  { label: 'Richardson', value: 'Richardson' },
  { label: 'Richardson County', value: 'Richardson County' },
  { label: 'Richard-Toll', value: 'Richard-Toll' },
  { label: 'Richboro', value: 'Richboro' },
  { label: 'Riche Fond', value: 'Riche Fond' },
  { label: 'Riche Fond/La Belle Vie', value: 'Riche Fond/La Belle Vie' },
  { label: 'Riche Fond/New Village', value: 'Riche Fond/New Village' },
  { label: 'Richebourg', value: 'Richebourg' },
  { label: 'Richebourg-Lavoué', value: 'Richebourg-Lavoué' },
  { label: 'Richelieu', value: 'Richelieu' },
  { label: 'Richelieu', value: 'Richelieu' },
  { label: 'Richelieu', value: 'Richelieu' },
  { label: 'Richemont', value: 'Richemont' },
  { label: 'Richfield', value: 'Richfield' },
  { label: 'Richfield', value: 'Richfield' },
  { label: 'Richfield', value: 'Richfield' },
  { label: 'Richfield', value: 'Richfield' },
  { label: 'Richfield Springs', value: 'Richfield Springs' },
  { label: 'Richford', value: 'Richford' },
  { label: 'Richgrove', value: 'Richgrove' },
  { label: 'Richha', value: 'Richha' },
  { label: 'Richibucto', value: 'Richibucto' },
  { label: 'Richky', value: 'Richky' },
  { label: 'Richland', value: 'Richland' },
  { label: 'Richland', value: 'Richland' },
  { label: 'Richland', value: 'Richland' },
  { label: 'Richland', value: 'Richland' },
  { label: 'Richland', value: 'Richland' },
  { label: 'Richland', value: 'Richland' },
  { label: 'Richland Center', value: 'Richland Center' },
  { label: 'Richland County', value: 'Richland County' },
  { label: 'Richland County', value: 'Richland County' },
  { label: 'Richland County', value: 'Richland County' },
  { label: 'Richland County', value: 'Richland County' },
  { label: 'Richland County', value: 'Richland County' },
  { label: 'Richland County', value: 'Richland County' },
  { label: 'Richland Hills', value: 'Richland Hills' },
  { label: 'Richland Parish', value: 'Richland Parish' },
  { label: 'Richlands', value: 'Richlands' },
  { label: 'Richlands', value: 'Richlands' },
  { label: 'Richlands', value: 'Richlands' },
  { label: 'Richlandtown', value: 'Richlandtown' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Richmond County', value: 'Richmond County' },
  { label: 'Richmond County', value: 'Richmond County' },
  { label: 'Richmond County', value: 'Richmond County' },
  { label: 'Richmond County', value: 'Richmond County' },
  { label: 'Richmond Heights', value: 'Richmond Heights' },
  { label: 'Richmond Heights', value: 'Richmond Heights' },
  { label: 'Richmond Heights', value: 'Richmond Heights' },
  { label: 'Richmond Hill', value: 'Richmond Hill' },
  { label: 'Richmond Hill', value: 'Richmond Hill' },
  { label: 'Richmond Hill', value: 'Richmond Hill' },
  { label: 'Richmond Park', value: 'Richmond Park' },
  { label: 'Richmond Park', value: 'Richmond Park' },
  { label: 'Richmond Vale', value: 'Richmond Vale' },
  { label: 'Richmond Valley', value: 'Richmond Valley' },
  { label: 'Richmond West', value: 'Richmond West' },
  { label: 'Richtenberg', value: 'Richtenberg' },
  { label: 'Richterswil', value: 'Richterswil' },
  { label: 'Richterswil / Burghalde', value: 'Richterswil / Burghalde' },
  { label: 'Richterswil / Dorfkern', value: 'Richterswil / Dorfkern' },
  { label: 'Richterswil / Richterswil (Dorfkern)', value: 'Richterswil / Richterswil (Dorfkern)' },
  { label: 'Richton', value: 'Richton' },
  { label: 'Richton Park', value: 'Richton Park' },
  { label: 'Richville', value: 'Richville' },
  { label: 'Richwiller', value: 'Richwiller' },
  { label: 'Richwood', value: 'Richwood' },
  { label: 'Richwood', value: 'Richwood' },
  { label: 'Richwood', value: 'Richwood' },
  { label: 'Richwood', value: 'Richwood' },
  { label: 'Richwood', value: 'Richwood' },
  { label: 'Ricigliano', value: 'Ricigliano' },
  { label: 'Rickenbach', value: 'Rickenbach' },
  { label: 'Rickenbach Bei Wil', value: 'Rickenbach Bei Wil' },
  { label: 'Rickert', value: 'Rickert' },
  { label: 'Rickinghall', value: 'Rickinghall' },
  { label: 'Rickling', value: 'Rickling' },
  { label: 'Rickmansworth', value: 'Rickmansworth' },
  { label: 'Ricla', value: 'Ricla' },
  { label: 'Ricote', value: 'Ricote' },
  { label: 'Ricse', value: 'Ricse' },
  { label: 'Ridder', value: 'Ridder' },
  { label: 'Ridderkerk', value: 'Ridderkerk' },
  { label: 'Riddes', value: 'Riddes' },
  { label: 'Riddle', value: 'Riddle' },
  { label: 'Rideau Park', value: 'Rideau Park' },
  { label: 'Ridge', value: 'Ridge' },
  { label: 'Ridge Manor', value: 'Ridge Manor' },
  { label: 'Ridge Wood Heights', value: 'Ridge Wood Heights' },
  { label: 'Ridgecrest', value: 'Ridgecrest' },
  { label: 'Ridgecrest', value: 'Ridgecrest' },
  { label: 'Ridgefield', value: 'Ridgefield' },
  { label: 'Ridgefield', value: 'Ridgefield' },
  { label: 'Ridgefield', value: 'Ridgefield' },
  { label: 'Ridgefield Park', value: 'Ridgefield Park' },
  { label: 'Ridgehaven', value: 'Ridgehaven' },
  { label: 'Ridgeland', value: 'Ridgeland' },
  { label: 'Ridgeland', value: 'Ridgeland' },
  { label: 'Ridgely', value: 'Ridgely' },
  { label: 'Ridgely', value: 'Ridgely' },
  { label: 'Ridgemark', value: 'Ridgemark' },
  { label: 'Ridgetop', value: 'Ridgetop' },
  { label: 'Ridgetown', value: 'Ridgetown' },
  { label: 'Ridgeville', value: 'Ridgeville' },
  { label: 'Ridgeway', value: 'Ridgeway' },
  { label: 'Ridgewood', value: 'Ridgewood' },
  { label: 'Ridgewood', value: 'Ridgewood' },
  { label: 'Ridgewood', value: 'Ridgewood' },
  { label: 'Ridgmont', value: 'Ridgmont' },
  { label: 'Ridgway', value: 'Ridgway' },
  { label: 'Riđica', value: 'Riđica' },
  { label: 'Ridley Park', value: 'Ridley Park' },
  { label: 'Ridleyton', value: 'Ridleyton' },
  { label: 'Riebiņi', value: 'Riebiņi' },
  { label: 'Riec-Sur-Belon', value: 'Riec-Sur-Belon' },
  { label: 'Ried', value: 'Ried' },
  { label: 'Ried', value: 'Ried' },
  { label: 'Ried Im Innkreis', value: 'Ried Im Innkreis' },
  { label: 'Ried Im Oberinntal', value: 'Ried Im Oberinntal' },
  { label: 'Ried Im Traunkreis', value: 'Ried Im Traunkreis' },
  { label: 'Ried Im Zillertal', value: 'Ried Im Zillertal' },
  { label: 'Ried In Der Riedmark', value: 'Ried In Der Riedmark' },
  { label: 'Riede', value: 'Riede' },
  { label: 'Rieden', value: 'Rieden' },
  { label: 'Rieden', value: 'Rieden' },
  { label: 'Rieden An Der Kötz', value: 'Rieden An Der Kötz' },
  { label: 'Riedenberg', value: 'Riedenberg' },
  { label: 'Riedenburg', value: 'Riedenburg' },
  { label: 'Rieder', value: 'Rieder' },
  { label: 'Riederich', value: 'Riederich' },
  { label: 'Riedering', value: 'Riedering' },
  { label: 'Riedholz', value: 'Riedholz' },
  { label: 'Riedisheim', value: 'Riedisheim' },
  { label: 'Riedlingen', value: 'Riedlingen' },
  { label: 'Riedlingsdorf', value: 'Riedlingsdorf' },
  { label: 'Riedstadt', value: 'Riedstadt' },
  { label: 'Riefensberg', value: 'Riefensberg' },
  { label: 'Riegel', value: 'Riegel' },
  { label: 'Riegelsberg', value: 'Riegelsberg' },
  { label: 'Riegersburg', value: 'Riegersburg' },
  { label: 'Riego De La Vega', value: 'Riego De La Vega' },
  { label: 'Riegsee', value: 'Riegsee' },
  { label: 'Riehen', value: 'Riehen' },
  { label: 'Riehl', value: 'Riehl' },
  { label: 'Rielasingen-Worblingen', value: 'Rielasingen-Worblingen' },
  { label: 'Riello', value: 'Riello' },
  { label: 'Riells I Viabrea', value: 'Riells I Viabrea' },
  { label: 'Rielves', value: 'Rielves' },
  { label: 'Riemst', value: 'Riemst' },
  { label: 'Rieneck', value: 'Rieneck' },
  { label: 'Rieni', value: 'Rieni' },
  { label: 'Riepsdorf', value: 'Riepsdorf' },
  { label: 'Riera De Gaià La', value: 'Riera De Gaià La' },
  { label: 'Ries', value: 'Ries' },
  { label: 'Riesa', value: 'Riesa' },
  { label: 'Rieschweiler-Mühlbach', value: 'Rieschweiler-Mühlbach' },
  { label: 'Riese Pio X', value: 'Riese Pio X' },
  { label: 'Rieseby', value: 'Rieseby' },
  { label: 'Riesel', value: 'Riesel' },
  { label: 'Riesi', value: 'Riesi' },
  { label: 'Rieste', value: 'Rieste' },
  { label: 'Riestedt', value: 'Riestedt' },
  { label: 'Rietavas', value: 'Rietavas' },
  { label: 'Rietberg', value: 'Rietberg' },
  { label: 'Rietheim-Weilheim', value: 'Rietheim-Weilheim' },
  { label: 'Riethnordhausen', value: 'Riethnordhausen' },
  { label: 'Riethoven', value: 'Riethoven' },
  { label: 'Rieti', value: 'Rieti' },
  { label: 'Rietschen', value: 'Rietschen' },
  { label: 'Rietz', value: 'Rietz' },
  { label: 'Rietz Neuendorf', value: 'Rietz Neuendorf' },
  { label: 'Rieulay', value: 'Rieulay' },
  { label: 'Rieumes', value: 'Rieumes' },
  { label: 'Rieupeyroux', value: 'Rieupeyroux' },
  { label: 'Rieux', value: 'Rieux' },
  { label: 'Rieux', value: 'Rieux' },
  { label: 'Rieux-En-Cambrésis', value: 'Rieux-En-Cambrésis' },
  { label: 'Rieux-Minervois', value: 'Rieux-Minervois' },
  { label: 'Rieux-Volvestre', value: 'Rieux-Volvestre' },
  { label: 'Riez', value: 'Riez' },
  { label: 'Riezlern', value: 'Riezlern' },
  { label: 'Rifaina', value: 'Rifaina' },
  { label: 'Rifiano', value: 'Rifiano' },
  { label: 'Rifle', value: 'Rifle' },
  { label: 'Rifreddo', value: 'Rifreddo' },
  { label: 'Rifu', value: 'Rifu' },
  { label: 'Riga', value: 'Riga' },
  { label: 'Rigan', value: 'Rigan' },
  { label: 'Rigaud', value: 'Rigaud' },
  { label: 'Rigby', value: 'Rigby' },
  { label: 'Riggisberg', value: 'Riggisberg' },
  { label: 'Rigmani', value: 'Rigmani' },
  { label: 'Rignac', value: 'Rignac' },
  { label: 'Rignano Flaminio', value: 'Rignano Flaminio' },
  { label: 'Rignano Garganico', value: 'Rignano Garganico' },
  { label: 'Rignano Sullarno', value: 'Rignano Sullarno' },
  { label: 'Rigo', value: 'Rigo' },
  { label: 'Rigolato', value: 'Rigolato' },
  { label: 'Riihimäki', value: 'Riihimäki' },
  { label: 'Riisipere', value: 'Riisipere' },
  { label: 'Rijab', value: 'Rijab' },
  { label: 'Rijeka', value: 'Rijeka' },
  { label: 'Rijen', value: 'Rijen' },
  { label: 'Rijkevorsel', value: 'Rijkevorsel' },
  { label: 'Rijnsaterwoude', value: 'Rijnsaterwoude' },
  { label: 'Rijnsburg', value: 'Rijnsburg' },
  { label: 'Rijpwetering', value: 'Rijpwetering' },
  { label: 'Rijsbergen', value: 'Rijsbergen' },
  { label: 'Rijsenhout', value: 'Rijsenhout' },
  { label: 'Rijsoord', value: 'Rijsoord' },
  { label: 'Rijswijk', value: 'Rijswijk' },
  { label: 'Rijswijk', value: 'Rijswijk' },
  { label: 'Rikaze', value: 'Rikaze' },
  { label: 'Rikuzentakata-Shi', value: 'Rikuzentakata-Shi' },
  { label: 'Rila', value: 'Rila' },
  { label: 'Riley County', value: 'Riley County' },
  { label: 'Rilhac-Rancon', value: 'Rilhac-Rancon' },
  { label: 'Rilievo', value: 'Rilievo' },
  { label: 'Rillieux-La-Pape', value: 'Rillieux-La-Pape' },
  { label: 'Rillington', value: 'Rillington' },
  { label: 'Rillo', value: 'Rillo' },
  { label: 'Rillo De Gallo', value: 'Rillo De Gallo' },
  { label: 'Rilly-La-Montagne', value: 'Rilly-La-Montagne' },
  { label: 'Rima', value: 'Rima' },
  { label: 'Rimasco', value: 'Rimasco' },
  { label: 'Rimavská Sobota', value: 'Rimavská Sobota' },
  { label: 'Rimbach', value: 'Rimbach' },
  { label: 'Rimbach', value: 'Rimbach' },
  { label: 'Rimbey', value: 'Rimbey' },
  { label: 'Rimbo', value: 'Rimbo' },
  { label: 'Rimella', value: 'Rimella' },
  { label: 'Rimetea', value: 'Rimetea' },
  { label: 'Rimforsa', value: 'Rimforsa' },
  { label: 'Rimini', value: 'Rimini' },
  { label: 'Rimóc', value: 'Rimóc' },
  { label: 'Rimogne', value: 'Rimogne' },
  { label: 'Rimouski', value: 'Rimouski' },
  { label: 'Rimpar', value: 'Rimpar' },
  { label: 'Rimpeso', value: 'Rimpeso' },
  { label: 'Rimpeso', value: 'Rimpeso' },
  { label: 'Rimschweiler', value: 'Rimschweiler' },
  { label: 'Rimsting', value: 'Rimsting' },
  { label: 'Rimus', value: 'Rimus' },
  { label: 'Rimus', value: 'Rimus' },
  { label: 'Rincão', value: 'Rincão' },
  { label: 'Rinchnach', value: 'Rinchnach' },
  { label: 'Rincon', value: 'Rincon' },
  { label: 'Rincon', value: 'Rincon' },
  { label: 'Rincon', value: 'Rincon' },
  { label: 'Rincón', value: 'Rincón' },
  { label: 'Rincón', value: 'Rincón' },
  { label: 'Rincón', value: 'Rincón' },
  { label: 'Rincón', value: 'Rincón' },
  { label: 'Rincón Chamula', value: 'Rincón Chamula' },
  { label: 'Rincón De Aguirre', value: 'Rincón De Aguirre' },
  { label: 'Rincón De Barrabás', value: 'Rincón De Barrabás' },
  { label: 'Rincón De Buena Vista', value: 'Rincón De Buena Vista' },
  { label: 'Rincón De Cano', value: 'Rincón De Cano' },
  { label: 'Rincón De Cedeños (Rincón De Dolores)', value: 'Rincón De Cedeños (Rincón De Dolores)' },
  { label: 'Rincón De Curungueo', value: 'Rincón De Curungueo' },
  { label: 'Rincón De Guadalupe', value: 'Rincón De Guadalupe' },
  { label: 'Rincón De Guayabitos', value: 'Rincón De Guayabitos' },
  { label: 'Rincón De Jaimes', value: 'Rincón De Jaimes' },
  { label: 'Rincón De La Victoria', value: 'Rincón De La Victoria' },
  { label: 'Rincón De López', value: 'Rincón De López' },
  { label: 'Rincón De Los Pirules', value: 'Rincón De Los Pirules' },
  { label: 'Rincón De Los Reyes', value: 'Rincón De Los Reyes' },
  { label: 'Rincón De Nicolás Romero (Cedros Tercera Manzana)', value: 'Rincón De Nicolás Romero (Cedros Tercera Manzana)' },
  { label: 'Rincón De Parangueo', value: 'Rincón De Parangueo' },
  { label: 'Rincón De Romos', value: 'Rincón De Romos' },
  { label: 'Rincón De Soto', value: 'Rincón De Soto' },
  { label: 'Rincón De Tamayo', value: 'Rincón De Tamayo' },
  { label: 'Rincón Del Centeno', value: 'Rincón Del Centeno' },
  { label: 'Rincón Del Porvenir', value: 'Rincón Del Porvenir' },
  { label: 'Rincón Hondo', value: 'Rincón Hondo' },
  { label: 'Rincón Moreno', value: 'Rincón Moreno' },
  { label: 'Rincón Municipio', value: 'Rincón Municipio' },
  { label: 'Rincón Verde', value: 'Rincón Verde' },
  { label: 'Rinconada', value: 'Rinconada' },
  { label: 'Rinconada', value: 'Rinconada' },
  { label: 'Rinconada', value: 'Rinconada' },
  { label: 'Rinconada De La Sierra La', value: 'Rinconada De La Sierra La' },
  { label: 'Rinconada De Los Ángeles', value: 'Rinconada De Los Ángeles' },
  { label: 'Rinconada La', value: 'Rinconada La' },
  { label: 'Rinconada La Loma [Fraccionamiento]', value: 'Rinconada La Loma [Fraccionamiento]' },
  { label: 'Rinconadas De San Francisco', value: 'Rinconadas De San Francisco' },
  { label: 'Rinconadas Del Bosque', value: 'Rinconadas Del Bosque' },
  { label: 'Rinconadas Del Venado I', value: 'Rinconadas Del Venado I' },
  { label: 'Rincones De La Hacienda', value: 'Rincones De La Hacienda' },
  { label: 'Rindal', value: 'Rindal' },
  { label: 'Rindge', value: 'Rindge' },
  { label: 'Rindö', value: 'Rindö' },
  { label: 'Rineh', value: 'Rineh' },
  { label: 'Riner', value: 'Riner' },
  { label: 'Ringas', value: 'Ringas' },
  { label: 'Ringe', value: 'Ringe' },
  { label: 'Ringebu', value: 'Ringebu' },
  { label: 'Ringelai', value: 'Ringelai' },
  { label: 'Ringerike', value: 'Ringerike' },
  { label: 'Ringgenberg', value: 'Ringgenberg' },
  { label: 'Ringgold', value: 'Ringgold' },
  { label: 'Ringgold', value: 'Ringgold' },
  { label: 'Ringgold County', value: 'Ringgold County' },
  { label: 'Ringim', value: 'Ringim' },
  { label: 'Ringkøbing', value: 'Ringkøbing' },
  { label: 'Ringkøbing-Skjern Kommune', value: 'Ringkøbing-Skjern Kommune' },
  { label: 'Ringleben', value: 'Ringleben' },
  { label: 'Ringling', value: 'Ringling' },
  { label: 'Ringmer', value: 'Ringmer' },
  { label: 'Ringsaker', value: 'Ringsaker' },
  { label: 'Ringsend', value: 'Ringsend' },
  { label: 'Ringsheim', value: 'Ringsheim' },
  { label: 'Ringstead', value: 'Ringstead' },
  { label: 'Ringsted', value: 'Ringsted' },
  { label: 'Ringsted Kommune', value: 'Ringsted Kommune' },
  { label: 'Ringwood', value: 'Ringwood' },
  { label: 'Ringwood', value: 'Ringwood' },
  { label: 'Ringwood', value: 'Ringwood' },
  { label: 'Ringwood East', value: 'Ringwood East' },
  { label: 'Ringwood North', value: 'Ringwood North' },
  { label: 'Rinkabyholm', value: 'Rinkabyholm' },
  { label: 'Rinn', value: 'Rinn' },
  { label: 'Rinópolis', value: 'Rinópolis' },
  { label: 'Rinteln', value: 'Rinteln' },
  { label: 'Rinxent', value: 'Rinxent' },
  { label: 'Rio', value: 'Rio' },
  { label: 'Río', value: 'Río' },
  { label: 'Río Abajo', value: 'Río Abajo' },
  { label: 'Río Abajo', value: 'Río Abajo' },
  { label: 'Rio Acima', value: 'Rio Acima' },
  { label: 'Río Alejandro', value: 'Río Alejandro' },
  { label: 'Rio Arriba County', value: 'Rio Arriba County' },
  { label: 'Rio Azul', value: 'Rio Azul' },
  { label: 'Rio Bananal', value: 'Rio Bananal' },
  { label: 'Río Blanco', value: 'Río Blanco' },
  { label: 'Río Blanco', value: 'Río Blanco' },
  { label: 'Río Blanco', value: 'Río Blanco' },
  { label: 'Río Blanco', value: 'Río Blanco' },
  { label: 'Río Blanco', value: 'Río Blanco' },
  { label: 'Rio Blanco County', value: 'Rio Blanco County' },
  { label: 'Río Blanquito', value: 'Río Blanquito' },
  { label: 'Rio Bom', value: 'Rio Bom' },
  { label: 'Rio Bonito', value: 'Rio Bonito' },
  { label: 'Río Bonito', value: 'Río Bonito' },
  { label: 'Rio Bonito Do Iguaçu', value: 'Rio Bonito Do Iguaçu' },
  { label: 'Rio Branco', value: 'Rio Branco' },
  { label: 'Rio Branco', value: 'Rio Branco' },
  { label: 'Río Branco', value: 'Río Branco' },
  { label: 'Rio Branco Do Ivaí', value: 'Rio Branco Do Ivaí' },
  { label: 'Rio Branco Do Sul', value: 'Rio Branco Do Sul' },
  { label: 'Rio Bravo', value: 'Rio Bravo' },
  { label: 'Río Bravo', value: 'Río Bravo' },
  { label: 'Río Bravo', value: 'Río Bravo' },
  { label: 'Río Bravo', value: 'Río Bravo' },
  { label: 'Rio Brilhante', value: 'Rio Brilhante' },
  { label: 'Rio Bueno', value: 'Rio Bueno' },
  { label: 'Río Bueno', value: 'Río Bueno' },
  { label: 'Río Campo', value: 'Río Campo' },
  { label: 'Río Cañas Abajo', value: 'Río Cañas Abajo' },
  { label: 'Rio Casca', value: 'Rio Casca' },
  { label: 'Río Cauto', value: 'Río Cauto' },
  { label: 'Río Ceballos', value: 'Río Ceballos' },
  { label: 'Río Chancalá', value: 'Río Chancalá' },
  { label: 'Río Chiquito', value: 'Río Chiquito' },
  { label: 'Río Chiquito', value: 'Río Chiquito' },
  { label: 'Rio Claro', value: 'Rio Claro' },
  { label: 'Rio Claro', value: 'Rio Claro' },
  { label: 'Rio Claro', value: 'Rio Claro' },
  { label: 'Río Claro', value: 'Río Claro' },
  { label: 'Río Colorado', value: 'Río Colorado' },
  { label: 'Rio Communities', value: 'Rio Communities' },
  { label: 'Río Congo', value: 'Río Congo' },
  { label: 'Rio Crespo', value: 'Rio Crespo' },
  { label: 'Río Cuarto', value: 'Río Cuarto' },
  { label: 'Rio Da Conceição', value: 'Rio Da Conceição' },
  { label: 'Rio Das Antas', value: 'Rio Das Antas' },
  { label: 'Rio Das Flores', value: 'Rio Das Flores' },
  { label: 'Rio Das Ostras', value: 'Rio Das Ostras' },
  { label: 'Rio Das Pedras', value: 'Rio Das Pedras' },
  { label: 'Rio De Contas', value: 'Rio De Contas' },
  { label: 'Rio De Janeiro', value: 'Rio De Janeiro' },
  { label: 'Río De Jesús', value: 'Río De Jesús' },
  { label: 'Rio De La Soledad', value: 'Rio De La Soledad' },
  { label: 'Rio De Loba', value: 'Rio De Loba' },
  { label: 'Rio De Medina', value: 'Rio De Medina' },
  { label: 'Rio De Mel', value: 'Rio De Mel' },
  { label: 'Rio De Mouro', value: 'Rio De Mouro' },
  { label: 'Rio De Mouro', value: 'Rio De Mouro' },
  { label: 'Río De Oro', value: 'Río De Oro' },
  { label: 'Río De Teapa', value: 'Río De Teapa' },
  { label: 'Rio Del Mar', value: 'Rio Del Mar' },
  { label: 'Rio Dell', value: 'Rio Dell' },
  { label: 'Rio Di Pusteria', value: 'Rio Di Pusteria' },
  { label: 'Rio Do Antônio', value: 'Rio Do Antônio' },
  { label: 'Rio Do Campo', value: 'Rio Do Campo' },
  { label: 'Rio Do Fogo', value: 'Rio Do Fogo' },
  { label: 'Rio Do Oeste', value: 'Rio Do Oeste' },
  { label: 'Rio Do Pires', value: 'Rio Do Pires' },
  { label: 'Rio Do Prado', value: 'Rio Do Prado' },
  { label: 'Rio Do Sul', value: 'Rio Do Sul' },
  { label: 'Rio Doce', value: 'Rio Doce' },
  { label: 'Rio Dos Bois', value: 'Rio Dos Bois' },
  { label: 'Rio Dos Cedros', value: 'Rio Dos Cedros' },
  { label: 'Rio Dos Índios', value: 'Rio Dos Índios' },
  { label: 'Río Duque', value: 'Río Duque' },
  { label: 'Rio Espera', value: 'Rio Espera' },
  { label: 'Río Esteban', value: 'Río Esteban' },
  { label: 'Río Florido', value: 'Río Florido' },
  { label: 'Rio Formoso', value: 'Rio Formoso' },
  { label: 'Rio Fortuna', value: 'Rio Fortuna' },
  { label: 'Río Frío De Juárez', value: 'Río Frío De Juárez' },
  { label: 'Río Gallegos', value: 'Río Gallegos' },
  { label: 'Rio Grande', value: 'Rio Grande' },
  { label: 'Rio Grande', value: 'Rio Grande' },
  { label: 'Río Grande', value: 'Río Grande' },
  { label: 'Río Grande', value: 'Río Grande' },
  { label: 'Río Grande', value: 'Río Grande' },
  { label: 'Río Grande', value: 'Río Grande' },
  { label: 'Río Grande', value: 'Río Grande' },
  { label: 'Río Grande', value: 'Río Grande' },
  { label: 'Río Grande', value: 'Río Grande' },
  { label: 'Río Grande', value: 'Río Grande' },
  { label: 'Río Grande', value: 'Río Grande' },
  { label: 'Río Grande', value: 'Río Grande' },
  { label: 'Rio Grande City', value: 'Rio Grande City' },
  { label: 'Rio Grande County', value: 'Rio Grande County' },
  { label: 'Rio Grande Da Serra', value: 'Rio Grande Da Serra' },
  { label: 'Rio Grande Do Piauí', value: 'Rio Grande Do Piauí' },
  { label: 'Río Grande Municipio', value: 'Río Grande Municipio' },
  { label: 'Río Guayabal De Yateras', value: 'Río Guayabal De Yateras' },
  { label: 'Río Hato', value: 'Río Hato' },
  { label: 'Rio Hondo', value: 'Rio Hondo' },
  { label: 'Río Hondo', value: 'Río Hondo' },
  { label: 'Río Hurtado', value: 'Río Hurtado' },
  { label: 'Río Ibáñez', value: 'Río Ibáñez' },
  { label: 'Río Iro', value: 'Río Iro' },
  { label: 'Río Jordán', value: 'Río Jordán' },
  { label: 'Río Lagartos', value: 'Río Lagartos' },
  { label: 'Río Laja', value: 'Río Laja' },
  { label: 'Rio Lajas', value: 'Rio Lajas' },
  { label: 'Rio Largo', value: 'Rio Largo' },
  { label: 'Rio Linda', value: 'Rio Linda' },
  { label: 'Río Lindo', value: 'Río Lindo' },
  { label: 'Rio Maior', value: 'Rio Maior' },
  { label: 'Rio Manso', value: 'Rio Manso' },
  { label: 'Rio Maria', value: 'Rio Maria' },
  { label: 'Rio Marina', value: 'Rio Marina' },
  { label: 'Rio Mau', value: 'Rio Mau' },
  { label: 'Río Mayo', value: 'Río Mayo' },
  { label: 'Rio Meão', value: 'Rio Meão' },
  { label: 'Río Medio [Granja]', value: 'Río Medio [Granja]' },
  { label: 'Rio Negrinho', value: 'Rio Negrinho' },
  { label: 'Rio Negro', value: 'Rio Negro' },
  { label: 'Rio Negro', value: 'Rio Negro' },
  { label: 'Río Negro', value: 'Río Negro' },
  { label: 'Rio Nellelba', value: 'Rio Nellelba' },
  { label: 'Rio Novo', value: 'Rio Novo' },
  { label: 'Rio Novo Do Sul', value: 'Rio Novo Do Sul' },
  { label: 'Río Pachiñe', value: 'Río Pachiñe' },
  { label: 'Rio Paranaíba', value: 'Rio Paranaíba' },
  { label: 'Rio Pardo', value: 'Rio Pardo' },
  { label: 'Rio Pardo De Minas', value: 'Rio Pardo De Minas' },
  { label: 'Río Pico', value: 'Río Pico' },
  { label: 'Río Piedras', value: 'Río Piedras' },
  { label: 'Rio Piracicaba', value: 'Rio Piracicaba' },
  { label: 'Rio Pomba', value: 'Rio Pomba' },
  { label: 'Rio Preto', value: 'Rio Preto' },
  { label: 'Rio Preto Da Eva', value: 'Rio Preto Da Eva' },
  { label: 'Rio Quente', value: 'Rio Quente' },
  { label: 'Río Quito', value: 'Río Quito' },
  { label: 'Rio Rancho', value: 'Rio Rancho' },
  { label: 'Rio Real', value: 'Rio Real' },
  { label: 'Rio Rico', value: 'Rio Rico' },
  { label: 'Río Rita', value: 'Río Rita' },
  { label: 'Rio Rufino', value: 'Rio Rufino' },
  { label: 'Rio Saliceto', value: 'Rio Saliceto' },
  { label: 'Rio Salso-Case Bernardi', value: 'Rio Salso-Case Bernardi' },
  { label: 'Río San Juan', value: 'Río San Juan' },
  { label: 'Río Seco Puente De Doria', value: 'Río Seco Puente De Doria' },
  { label: 'Río Segundo', value: 'Río Segundo' },
  { label: 'Río Segundo', value: 'Río Segundo' },
  { label: 'Río Sereno', value: 'Río Sereno' },
  { label: 'Rio Sono', value: 'Rio Sono' },
  { label: 'Rio Tavares', value: 'Rio Tavares' },
  { label: 'Río Tercero', value: 'Río Tercero' },
  { label: 'Rio Tinto', value: 'Rio Tinto' },
  { label: 'Rio Tinto', value: 'Rio Tinto' },
  { label: 'Rio Torto', value: 'Rio Torto' },
  { label: 'Rio Tuba', value: 'Rio Tuba' },
  { label: 'Río Turbio', value: 'Río Turbio' },
  { label: 'Rio Verde', value: 'Rio Verde' },
  { label: 'Rio Verde', value: 'Rio Verde' },
  { label: 'Rio Verde', value: 'Rio Verde' },
  { label: 'Río Verde', value: 'Río Verde' },
  { label: 'Río Verde', value: 'Río Verde' },
  { label: 'Río Verde Arriba', value: 'Río Verde Arriba' },
  { label: 'Rio Verde De Mato Grosso', value: 'Rio Verde De Mato Grosso' },
  { label: 'Rio Vermelho', value: 'Rio Vermelho' },
  { label: 'Río Viejo', value: 'Río Viejo' },
  { label: 'Río Viejo Primera Sección', value: 'Río Viejo Primera Sección' },
  { label: 'Rio Vista', value: 'Rio Vista' },
  { label: 'Riobamba', value: 'Riobamba' },
  { label: 'Rioblanco', value: 'Rioblanco' },
  { label: 'Riocabado', value: 'Riocabado' },
  { label: 'Riocavado De La Sierra', value: 'Riocavado De La Sierra' },
  { label: 'Riodeva', value: 'Riodeva' },
  { label: 'Riofreddo', value: 'Riofreddo' },
  { label: 'Riofrío', value: 'Riofrío' },
  { label: 'Riofrío', value: 'Riofrío' },
  { label: 'Ríofrío De Aliste', value: 'Ríofrío De Aliste' },
  { label: 'Riofrío De Riaza', value: 'Riofrío De Riaza' },
  { label: 'Riofrío Del Llano', value: 'Riofrío Del Llano' },
  { label: 'Ríogordo', value: 'Ríogordo' },
  { label: 'Riohacha', value: 'Riohacha' },
  { label: 'Rioja', value: 'Rioja' },
  { label: 'Rioja', value: 'Rioja' },
  { label: 'Riol', value: 'Riol' },
  { label: 'Riola', value: 'Riola' },
  { label: 'Riola Sardo', value: 'Riola Sardo' },
  { label: 'Riolândia', value: 'Riolândia' },
  { label: 'Riolo Terme', value: 'Riolo Terme' },
  { label: 'Ríolobos', value: 'Ríolobos' },
  { label: 'Riolunato', value: 'Riolunato' },
  { label: 'Riom', value: 'Riom' },
  { label: 'Riomaggiore', value: 'Riomaggiore' },
  { label: 'Riom-Ès-Montagnes', value: 'Riom-Ès-Montagnes' },
  { label: 'Rionansa', value: 'Rionansa' },
  { label: 'Rion-Des-Landes', value: 'Rion-Des-Landes' },
  { label: 'Rionegro', value: 'Rionegro' },
  { label: 'Rionegro', value: 'Rionegro' },
  { label: 'Rionegro Del Puente', value: 'Rionegro Del Puente' },
  { label: 'Rionero In Vulture', value: 'Rionero In Vulture' },
  { label: 'Rionero Sannitico', value: 'Rionero Sannitico' },
  { label: 'Rions', value: 'Rions' },
  { label: 'Riópar', value: 'Riópar' },
  { label: 'Riorges', value: 'Riorges' },
  { label: 'Riós', value: 'Riós' },
  { label: 'Riosa', value: 'Riosa' },
  { label: 'Rioseco De Soria', value: 'Rioseco De Soria' },
  { label: 'Rioseco De Tapia', value: 'Rioseco De Tapia' },
  { label: 'Riosucio', value: 'Riosucio' },
  { label: 'Riosucio', value: 'Riosucio' },
  { label: 'Riotord', value: 'Riotord' },
  { label: 'Ríotorto', value: 'Ríotorto' },
  { label: 'Riotuerto', value: 'Riotuerto' },
  { label: 'Rioverde', value: 'Rioverde' },
  { label: 'Rioz', value: 'Rioz' },
  { label: 'Riozinho', value: 'Riozinho' },
  { label: 'Riozzo', value: 'Riozzo' },
  { label: 'Ripa Teatina', value: 'Ripa Teatina' },
  { label: 'Ripabottoni', value: 'Ripabottoni' },
  { label: 'Ripacandida', value: 'Ripacandida' },
  { label: 'Ripailles', value: 'Ripailles' },
  { label: 'Ripalimosani', value: 'Ripalimosani' },
  { label: 'Ripalta Arpina', value: 'Ripalta Arpina' },
  { label: 'Ripalta Guerina', value: 'Ripalta Guerina' },
  { label: 'Ripalta Nuova', value: 'Ripalta Nuova' },
  { label: 'Ripa-Pozzi-Querceta-Ponterosso', value: 'Ripa-Pozzi-Querceta-Ponterosso' },
  { label: 'Riparbella', value: 'Riparbella' },
  { label: 'Ripatransone', value: 'Ripatransone' },
  { label: 'Ripe', value: 'Ripe' },
  { label: 'Ripe San Ginesio', value: 'Ripe San Ginesio' },
  { label: 'Ripi', value: 'Ripi' },
  { label: 'Ripiceni', value: 'Ripiceni' },
  { label: 'Ripky', value: 'Ripky' },
  { label: 'Ripley', value: 'Ripley' },
  { label: 'Ripley', value: 'Ripley' },
  { label: 'Ripley', value: 'Ripley' },
  { label: 'Ripley', value: 'Ripley' },
  { label: 'Ripley', value: 'Ripley' },
  { label: 'Ripley County', value: 'Ripley County' },
  { label: 'Ripley County', value: 'Ripley County' },
  { label: 'Ripoll', value: 'Ripoll' },
  { label: 'Ripollet', value: 'Ripollet' },
  { label: 'Ripon', value: 'Ripon' },
  { label: 'Ripon', value: 'Ripon' },
  { label: 'Ripon', value: 'Ripon' },
  { label: 'Riposto', value: 'Riposto' },
  { label: 'Ripponden', value: 'Ripponden' },
  { label: 'Ripponlea', value: 'Ripponlea' },
  { label: 'Riquewihr', value: 'Riquewihr' },
  { label: 'Riqueza', value: 'Riqueza' },
  { label: 'Risalpur Cantonment', value: 'Risalpur Cantonment' },
  { label: 'Risan', value: 'Risan' },
  { label: 'Risaralda', value: 'Risaralda' },
  { label: 'Risca', value: 'Risca' },
  { label: 'Rîşcani', value: 'Rîşcani' },
  { label: 'Riscle', value: 'Riscle' },
  { label: 'Risco', value: 'Risco' },
  { label: 'Riscone', value: 'Riscone' },
  { label: 'Risdam', value: 'Risdam' },
  { label: 'Risdon Park', value: 'Risdon Park' },
  { label: 'Risdon Park South', value: 'Risdon Park South' },
  { label: 'Risdon Vale', value: 'Risdon Vale' },
  { label: 'Riseley', value: 'Riseley' },
  { label: 'Rishikesh', value: 'Rishikesh' },
  { label: 'Rishiri Gun', value: 'Rishiri Gun' },
  { label: 'Rishiri Town', value: 'Rishiri Town' },
  { label: 'Rishon Leẕiyyon', value: 'Rishon Leẕiyyon' },
  { label: 'Rishra', value: 'Rishra' },
  { label: 'Rishton', value: 'Rishton' },
  { label: 'Rishton', value: 'Rishton' },
  { label: 'Rising Sun', value: 'Rising Sun' },
  { label: 'Rising Sun', value: 'Rising Sun' },
  { label: 'Rising Sun-Lebanon', value: 'Rising Sun-Lebanon' },
  { label: 'Risod', value: 'Risod' },
  { label: 'Rison', value: 'Rison' },
  { label: 'Ris-Orangis', value: 'Ris-Orangis' },
  { label: 'Rissing', value: 'Rissing' },
  { label: 'Rissing', value: 'Rissing' },
  { label: 'Ristiina', value: 'Ristiina' },
  { label: 'Ristijärvi', value: 'Ristijärvi' },
  { label: 'Ristinummi', value: 'Ristinummi' },
  { label: 'Risum-Lindholm', value: 'Risum-Lindholm' },
  { label: 'Ritang', value: 'Ritang' },
  { label: 'Ritápolis', value: 'Ritápolis' },
  { label: 'Ritchie', value: 'Ritchie' },
  { label: 'Ritchie County', value: 'Ritchie County' },
  { label: 'Ritchies', value: 'Ritchies' },
  { label: 'Riti', value: 'Riti' },
  { label: 'Ritíni', value: 'Ritíni' },
  { label: 'Rittana', value: 'Rittana' },
  { label: 'Ritterhude', value: 'Ritterhude' },
  { label: 'Rittersdorf', value: 'Rittersdorf' },
  { label: 'Rittersdorf', value: 'Rittersdorf' },
  { label: 'Rittersgrün', value: 'Rittersgrün' },
  { label: 'Rittman', value: 'Rittman' },
  { label: 'Rittō-Shi', value: 'Rittō-Shi' },
  { label: 'Ritzing', value: 'Ritzing' },
  { label: 'Ritzville', value: 'Ritzville' },
  { label: 'Riu De Cerdanya', value: 'Riu De Cerdanya' },
  { label: 'Riudarenes', value: 'Riudarenes' },
  { label: 'Riudaura', value: 'Riudaura' },
  { label: 'Riudecanyes', value: 'Riudecanyes' },
  { label: 'Riudecols', value: 'Riudecols' },
  { label: 'Riudellots De La Selva', value: 'Riudellots De La Selva' },
  { label: 'Riudoms', value: 'Riudoms' },
  { label: 'Riumors', value: 'Riumors' },
  { label: 'Riva', value: 'Riva' },
  { label: 'Riva', value: 'Riva' },
  { label: 'Riva Del Garda', value: 'Riva Del Garda' },
  { label: 'Riva Di Solto', value: 'Riva Di Solto' },
  { label: 'Riva Ligure', value: 'Riva Ligure' },
  { label: 'Riva Palacio', value: 'Riva Palacio' },
  { label: 'Riva Presso Chieri', value: 'Riva Presso Chieri' },
  { label: 'Riva San Vitale', value: 'Riva San Vitale' },
  { label: 'Riva Valdobbia', value: 'Riva Valdobbia' },
  { label: 'Rivalba', value: 'Rivalba' },
  { label: 'Rivalta', value: 'Rivalta' },
  { label: 'Rivalta Bormida', value: 'Rivalta Bormida' },
  { label: 'Rivalta Di Torino', value: 'Rivalta Di Torino' },
  { label: 'Rivamonte Agordino', value: 'Rivamonte Agordino' },
  { label: 'Rivanazzano', value: 'Rivanazzano' },
  { label: 'Rivara', value: 'Rivara' },
  { label: 'Rivarolo Canavese', value: 'Rivarolo Canavese' },
  { label: 'Rivarolo Del Re', value: 'Rivarolo Del Re' },
  { label: 'Rivarolo Mantovano', value: 'Rivarolo Mantovano' },
  { label: 'Rivarone', value: 'Rivarone' },
  { label: 'Rivarossa', value: 'Rivarossa' },
  { label: 'Rivas', value: 'Rivas' },
  { label: 'Rivash', value: 'Rivash' },
  { label: 'Rivasijan', value: 'Rivasijan' },
  { label: 'Rivas-Vaciamadrid', value: 'Rivas-Vaciamadrid' },
  { label: 'Rivazzurra', value: 'Rivazzurra' },
  { label: 'Rive', value: 'Rive' },
  { label: 'Rive Darcano', value: 'Rive Darcano' },
  { label: 'Rive-De-Gier', value: 'Rive-De-Gier' },
  { label: 'Rivedoux-Plage', value: 'Rivedoux-Plage' },
  { label: 'Rivello', value: 'Rivello' },
  { label: 'River Bend', value: 'River Bend' },
  { label: 'River Doree', value: 'River Doree' },
  { label: 'River Edge', value: 'River Edge' },
  { label: 'River Falls', value: 'River Falls' },
  { label: 'River Forest', value: 'River Forest' },
  { label: 'River Grove', value: 'River Grove' },
  { label: 'River Head', value: 'River Head' },
  { label: 'River Head', value: 'River Head' },
  { label: 'River Heads', value: 'River Heads' },
  { label: 'River Heights', value: 'River Heights' },
  { label: 'River Hills', value: 'River Hills' },
  { label: 'River Oaks', value: 'River Oaks' },
  { label: 'River Park', value: 'River Park' },
  { label: 'River Ridge', value: 'River Ridge' },
  { label: 'River Road', value: 'River Road' },
  { label: 'River Rouge', value: 'River Rouge' },
  { label: 'River Vale', value: 'River Vale' },
  { label: 'River View Park', value: 'River View Park' },
  { label: 'Rivera', value: 'Rivera' },
  { label: 'Rivera', value: 'Rivera' },
  { label: 'Riverbank', value: 'Riverbank' },
  { label: 'Riverbend', value: 'Riverbend' },
  { label: 'Riverdale', value: 'Riverdale' },
  { label: 'Riverdale', value: 'Riverdale' },
  { label: 'Riverdale', value: 'Riverdale' },
  { label: 'Riverdale', value: 'Riverdale' },
  { label: 'Riverdale', value: 'Riverdale' },
  { label: 'Riverdale', value: 'Riverdale' },
  { label: 'Riverdale Park', value: 'Riverdale Park' },
  { label: 'Riverdale Park', value: 'Riverdale Park' },
  { label: 'Rivergaro', value: 'Rivergaro' },
  { label: 'Riverhead', value: 'Riverhead' },
  { label: 'Riverhills', value: 'Riverhills' },
  { label: 'Rivers', value: 'Rivers' },
  { label: 'Riversdale', value: 'Riversdale' },
  { label: 'Riversdale', value: 'Riversdale' },
  { label: 'Riverside', value: 'Riverside' },
  { label: 'Riverside', value: 'Riverside' },
  { label: 'Riverside', value: 'Riverside' },
  { label: 'Riverside', value: 'Riverside' },
  { label: 'Riverside', value: 'Riverside' },
  { label: 'Riverside', value: 'Riverside' },
  { label: 'Riverside', value: 'Riverside' },
  { label: 'Riverside', value: 'Riverside' },
  { label: 'Riverside', value: 'Riverside' },
  { label: 'Riverside', value: 'Riverside' },
  { label: 'Riverside', value: 'Riverside' },
  { label: 'Riverside County', value: 'Riverside County' },
  { label: 'Riverside Road', value: 'Riverside Road' },
  { label: 'Riverstone', value: 'Riverstone' },
  { label: 'Riversul', value: 'Riversul' },
  { label: 'Riverton', value: 'Riverton' },
  { label: 'Riverton', value: 'Riverton' },
  { label: 'Riverton', value: 'Riverton' },
  { label: 'Riverton', value: 'Riverton' },
  { label: 'Riverton', value: 'Riverton' },
  { label: 'Riverton', value: 'Riverton' },
  { label: 'Riverton', value: 'Riverton' },
  { label: 'Riverton City', value: 'Riverton City' },
  { label: 'Rivervale', value: 'Rivervale' },
  { label: 'Riverview', value: 'Riverview' },
  { label: 'Riverview', value: 'Riverview' },
  { label: 'Riverview', value: 'Riverview' },
  { label: 'Riverview', value: 'Riverview' },
  { label: 'Riverview', value: 'Riverview' },
  { label: 'Riverview', value: 'Riverview' },
  { label: 'Riverwood', value: 'Riverwood' },
  { label: 'Riverwoods', value: 'Riverwoods' },
  { label: 'Rivery', value: 'Rivery' },
  { label: 'Rives', value: 'Rives' },
  { label: 'Rivesaltes', value: 'Rivesaltes' },
  { label: 'Rivett', value: 'Rivett' },
  { label: 'Riviera Beach', value: 'Riviera Beach' },
  { label: 'Riviera Beach', value: 'Riviera Beach' },
  { label: 'Riviera District', value: 'Riviera District' },
  { label: 'Riviera-Pays-Denhaut District', value: 'Riviera-Pays-Denhaut District' },
  { label: 'Rivière', value: 'Rivière' },
  { label: 'Rivière Des Anguilles', value: 'Rivière Des Anguilles' },
  { label: 'Riviere Des Creoles', value: 'Riviere Des Creoles' },
  { label: 'Rivière Du Poste', value: 'Rivière Du Poste' },
  { label: 'Rivière Du Rempart', value: 'Rivière Du Rempart' },
  { label: 'Rivière-Du-Loup', value: 'Rivière-Du-Loup' },
  { label: 'Rivierenkwartier', value: 'Rivierenkwartier' },
  { label: 'Rivière-Rouge', value: 'Rivière-Rouge' },
  { label: 'Rivières', value: 'Rivières' },
  { label: 'Rivière-Saas-Et-Gourby', value: 'Rivière-Saas-Et-Gourby' },
  { label: 'Rivignano', value: 'Rivignano' },
  { label: 'Rivilla De Barajas', value: 'Rivilla De Barajas' },
  { label: 'Rivisondoli', value: 'Rivisondoli' },
  { label: 'Rivne', value: 'Rivne' },
  { label: 'Rivne', value: 'Rivne' },
  { label: 'Rivnens’Ka Mis’Krada', value: 'Rivnens’Ka Mis’Krada' },
  { label: 'Rivodutri', value: 'Rivodutri' },
  { label: 'Rivoli', value: 'Rivoli' },
  { label: 'Rivoli Veronese', value: 'Rivoli Veronese' },
  { label: 'Rivolta Dadda', value: 'Rivolta Dadda' },
  { label: 'Rivotorto', value: 'Rivotorto' },
  { label: 'Rixensart', value: 'Rixensart' },
  { label: 'Rixheim', value: 'Rixheim' },
  { label: 'Riyad', value: 'Riyad' },
  { label: 'Riyadh', value: 'Riyadh' },
  { label: 'Riz', value: 'Riz' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizal', value: 'Rizal' },
  { label: 'Rizári', value: 'Rizári' },
  { label: 'Rizhao', value: 'Rizhao' },
  { label: 'Rízia', value: 'Rízia' },
  { label: 'Rizó', value: 'Rizó' },
  { label: 'Rizo De Oro', value: 'Rizo De Oro' },
  { label: 'Rizokárpaso', value: 'Rizokárpaso' },
  { label: 'Rízoma', value: 'Rízoma' },
  { label: 'Rizómata', value: 'Rizómata' },
  { label: 'Rizómylos', value: 'Rizómylos' },
  { label: 'Rizos De La Joya (Rizos Del Saucillo)', value: 'Rizos De La Joya (Rizos Del Saucillo)' },
  { label: 'Rizziconi', value: 'Rizziconi' },
  { label: 'Rjukan', value: 'Rjukan' },
  { label: 'Ro', value: 'Ro' },
  { label: 'Roa', value: 'Roa' },
  { label: 'Roade', value: 'Roade' },
  { label: 'Roales', value: 'Roales' },
  { label: 'Roales De Campos', value: 'Roales De Campos' },
  { label: 'Roaming Shores', value: 'Roaming Shores' },
  { label: 'Roan', value: 'Roan' },
  { label: 'Roan Mountain', value: 'Roan Mountain' },
  { label: 'Roana', value: 'Roana' },
  { label: 'Roane County', value: 'Roane County' },
  { label: 'Roane County', value: 'Roane County' },
  { label: 'Roanne', value: 'Roanne' },
  { label: 'Roanoke', value: 'Roanoke' },
  { label: 'Roanoke', value: 'Roanoke' },
  { label: 'Roanoke', value: 'Roanoke' },
  { label: 'Roanoke', value: 'Roanoke' },
  { label: 'Roanoke', value: 'Roanoke' },
  { label: 'Roanoke County', value: 'Roanoke County' },
  { label: 'Roanoke Rapids', value: 'Roanoke Rapids' },
  { label: 'Roapiana', value: 'Roapiana' },
  { label: 'Roaring River', value: 'Roaring River' },
  { label: 'Roaring Spring', value: 'Roaring Spring' },
  { label: 'Roaschia', value: 'Roaschia' },
  { label: 'Roascio', value: 'Roascio' },
  { label: 'Roasio', value: 'Roasio' },
  { label: 'Roata De Jos', value: 'Roata De Jos' },
  { label: 'Roata Rossi', value: 'Roata Rossi' },
  { label: 'Roatán', value: 'Roatán' },
  { label: 'Roatto', value: 'Roatto' },
  { label: 'Röbäck', value: 'Röbäck' },
  { label: 'Robassomero', value: 'Robassomero' },
  { label: 'Robat', value: 'Robat' },
  { label: 'Robat Karim', value: 'Robat Karim' },
  { label: 'Robat-E Sheverin', value: 'Robat-E Sheverin' },
  { label: 'Robat-E-Sang', value: 'Robat-E-Sang' },
  { label: 'Robbah', value: 'Robbah' },
  { label: 'Robbiate', value: 'Robbiate' },
  { label: 'Robbins', value: 'Robbins' },
  { label: 'Robbins', value: 'Robbins' },
  { label: 'Robbinsdale', value: 'Robbinsdale' },
  { label: 'Robbinsville', value: 'Robbinsville' },
  { label: 'Robbinsville', value: 'Robbinsville' },
  { label: 'Robbio', value: 'Robbio' },
  { label: 'Robe', value: 'Robe' },
  { label: 'Robeasca', value: 'Robeasca' },
  { label: 'Robecchetto Con Induno', value: 'Robecchetto Con Induno' },
  { label: 'Robecco Doglio', value: 'Robecco Doglio' },
  { label: 'Robecco Pavese', value: 'Robecco Pavese' },
  { label: 'Robecco Sul Naviglio', value: 'Robecco Sul Naviglio' },
  { label: 'Robecq', value: 'Robecq' },
  { label: 'Robegano', value: 'Robegano' },
  { label: 'Röbel', value: 'Röbel' },
  { label: 'Robella', value: 'Robella' },
  { label: 'Robersonville', value: 'Robersonville' },
  { label: 'Robert Lee', value: 'Robert Lee' },
  { label: 'Roberto Payán', value: 'Roberto Payán' },
  { label: 'Roberts', value: 'Roberts' },
  { label: 'Roberts County', value: 'Roberts County' },
  { label: 'Roberts County', value: 'Roberts County' },
  { label: 'Robertsbridge', value: 'Robertsbridge' },
  { label: 'Robertsdale', value: 'Robertsdale' },
  { label: 'Robertsfors', value: 'Robertsfors' },
  { label: 'Robertsganj', value: 'Robertsganj' },
  { label: 'Robertson', value: 'Robertson' },
  { label: 'Robertson', value: 'Robertson' },
  { label: 'Robertson', value: 'Robertson' },
  { label: 'Robertson County', value: 'Robertson County' },
  { label: 'Robertson County', value: 'Robertson County' },
  { label: 'Robertson County', value: 'Robertson County' },
  { label: 'Robertsonpet', value: 'Robertsonpet' },
  { label: 'Robertsport', value: 'Robertsport' },
  { label: 'Robertsville', value: 'Robertsville' },
  { label: 'Roberval', value: 'Roberval' },
  { label: 'Robeson County', value: 'Robeson County' },
  { label: 'Robesonia', value: 'Robesonia' },
  { label: 'Robilante', value: 'Robilante' },
  { label: 'Robina', value: 'Robina' },
  { label: 'Robins', value: 'Robins' },
  { label: 'Robins Air Force Base', value: 'Robins Air Force Base' },
  { label: 'Robins Bay', value: 'Robins Bay' },
  { label: 'Robins Hall', value: 'Robins Hall' },
  { label: 'Robinson', value: 'Robinson' },
  { label: 'Robinson', value: 'Robinson' },
  { label: 'Robinvale', value: 'Robinvale' },
  { label: 'Robinwood', value: 'Robinwood' },
  { label: 'Robion', value: 'Robion' },
  { label: 'Robīt', value: 'Robīt' },
  { label: 'Robla La', value: 'Robla La' },
  { label: 'Roblada Grande', value: 'Roblada Grande' },
  { label: 'Robladillo', value: 'Robladillo' },
  { label: 'Robleda', value: 'Robleda' },
  { label: 'Robleda-Cervantes', value: 'Robleda-Cervantes' },
  { label: 'Robledillo De Gata', value: 'Robledillo De Gata' },
  { label: 'Robledillo De La Jara', value: 'Robledillo De La Jara' },
  { label: 'Robledillo De La Vera', value: 'Robledillo De La Vera' },
  { label: 'Robledillo De Mohernando', value: 'Robledillo De Mohernando' },
  { label: 'Robledillo De Trujillo', value: 'Robledillo De Trujillo' },
  { label: 'Robledo', value: 'Robledo' },
  { label: 'Robledo De Chavela', value: 'Robledo De Chavela' },
  { label: 'Robledo De Corpes', value: 'Robledo De Corpes' },
  { label: 'Robledo Del Mazo', value: 'Robledo Del Mazo' },
  { label: 'Robledo El', value: 'Robledo El' },
  { label: 'Robledollano', value: 'Robledollano' },
  { label: 'Roblin', value: 'Roblin' },
  { label: 'Röblingen Am See', value: 'Röblingen Am See' },
  { label: 'Robliza De Cojos', value: 'Robliza De Cojos' },
  { label: 'Roblot', value: 'Roblot' },
  { label: 'Robonkon', value: 'Robonkon' },
  { label: 'Roboré', value: 'Roboré' },
  { label: 'Robregordo', value: 'Robregordo' },
  { label: 'Robres', value: 'Robres' },
  { label: 'Robres Del Castillo', value: 'Robres Del Castillo' },
  { label: 'Robstown', value: 'Robstown' },
  { label: 'Roburent', value: 'Roburent' },
  { label: 'Roby', value: 'Roby' },
  { label: 'Roca De La Sierra La', value: 'Roca De La Sierra La' },
  { label: 'Roca Del Vallès La', value: 'Roca Del Vallès La' },
  { label: 'Roca Sales', value: 'Roca Sales' },
  { label: 'Rocafort', value: 'Rocafort' },
  { label: 'Rocafort De Queralt', value: 'Rocafort De Queralt' },
  { label: 'Rocafuerte', value: 'Rocafuerte' },
  { label: 'Rocbaron', value: 'Rocbaron' },
  { label: 'Rocca Canavese', value: 'Rocca Canavese' },
  { label: 'Rocca Canterano', value: 'Rocca Canterano' },
  { label: 'Rocca Cigliè', value: 'Rocca Cigliè' },
  { label: 'Rocca Darazzo', value: 'Rocca Darazzo' },
  { label: 'Rocca Darce', value: 'Rocca Darce' },
  { label: 'Rocca De Baldi', value: 'Rocca De Baldi' },
  { label: 'Rocca Di Botte', value: 'Rocca Di Botte' },
  { label: 'Rocca Di Cambio', value: 'Rocca Di Cambio' },
  { label: 'Rocca Di Capri Leone', value: 'Rocca Di Capri Leone' },
  { label: 'Rocca Di Cave', value: 'Rocca Di Cave' },
  { label: 'Rocca Di Mezzo', value: 'Rocca Di Mezzo' },
  { label: 'Rocca Di Neto', value: 'Rocca Di Neto' },
  { label: 'Rocca Di Papa', value: 'Rocca Di Papa' },
  { label: 'Rocca Grimalda', value: 'Rocca Grimalda' },
  { label: 'Rocca Imperiale', value: 'Rocca Imperiale' },
  { label: 'Rocca Imperiale Marina', value: 'Rocca Imperiale Marina' },
  { label: 'Rocca Massima', value: 'Rocca Massima' },
  { label: 'Rocca Pia', value: 'Rocca Pia' },
  { label: 'Rocca Pietore', value: 'Rocca Pietore' },
  { label: 'Rocca Priora', value: 'Rocca Priora' },
  { label: 'Rocca San Casciano', value: 'Rocca San Casciano' },
  { label: 'Rocca San Felice', value: 'Rocca San Felice' },
  { label: 'Rocca San Giovanni', value: 'Rocca San Giovanni' },
  { label: 'Rocca Santo Stefano', value: 'Rocca Santo Stefano' },
  { label: 'Rocca Sinibalda', value: 'Rocca Sinibalda' },
  { label: 'Roccabascerana', value: 'Roccabascerana' },
  { label: 'Roccabernarda', value: 'Roccabernarda' },
  { label: 'Roccabianca', value: 'Roccabianca' },
  { label: 'Roccabruna', value: 'Roccabruna' },
  { label: 'Roccacasale', value: 'Roccacasale' },
  { label: 'Roccadaspide', value: 'Roccadaspide' },
  { label: 'Roccafiorita', value: 'Roccafiorita' },
  { label: 'Roccaforte Del Greco', value: 'Roccaforte Del Greco' },
  { label: 'Roccaforte Ligure', value: 'Roccaforte Ligure' },
  { label: 'Roccaforte Mondovì', value: 'Roccaforte Mondovì' },
  { label: 'Roccaforzata', value: 'Roccaforzata' },
  { label: 'Roccafranca', value: 'Roccafranca' },
  { label: 'Roccagiovine', value: 'Roccagiovine' },
  { label: 'Roccagloriosa', value: 'Roccagloriosa' },
  { label: 'Roccagorga', value: 'Roccagorga' },
  { label: 'Roccalbegna', value: 'Roccalbegna' },
  { label: 'Roccalumera', value: 'Roccalumera' },
  { label: 'Roccamandolfi', value: 'Roccamandolfi' },
  { label: 'Roccamena', value: 'Roccamena' },
  { label: 'Roccamonfina', value: 'Roccamonfina' },
  { label: 'Roccamorice', value: 'Roccamorice' },
  { label: 'Roccanova', value: 'Roccanova' },
  { label: 'Roccantica', value: 'Roccantica' },
  { label: 'Roccapalumba', value: 'Roccapalumba' },
  { label: 'Roccapiemonte', value: 'Roccapiemonte' },
  { label: 'Roccarainola', value: 'Roccarainola' },
  { label: 'Roccaraso', value: 'Roccaraso' },
  { label: 'Roccaromana', value: 'Roccaromana' },
  { label: 'Roccascalegna', value: 'Roccascalegna' },
  { label: 'Roccasecca', value: 'Roccasecca' },
  { label: 'Roccasecca Dei Volsci', value: 'Roccasecca Dei Volsci' },
  { label: 'Roccasecca Stazione', value: 'Roccasecca Stazione' },
  { label: 'Roccasicura', value: 'Roccasicura' },
  { label: 'Roccasparvera', value: 'Roccasparvera' },
  { label: 'Roccaspinalveti-Santa Giusta', value: 'Roccaspinalveti-Santa Giusta' },
  { label: 'Roccastrada', value: 'Roccastrada' },
  { label: 'Roccavaldina', value: 'Roccavaldina' },
  { label: 'Roccaverano', value: 'Roccaverano' },
  { label: 'Roccavignale', value: 'Roccavignale' },
  { label: 'Roccavione', value: 'Roccavione' },
  { label: 'Roccavivara', value: 'Roccavivara' },
  { label: 'Roccavivi', value: 'Roccavivi' },
  { label: 'Roccella Ionica', value: 'Roccella Ionica' },
  { label: 'Roccella Valdemone', value: 'Roccella Valdemone' },
  { label: 'Roccelletta', value: 'Roccelletta' },
  { label: 'Rocchetta', value: 'Rocchetta' },
  { label: 'Rocchetta A Volturno', value: 'Rocchetta A Volturno' },
  { label: 'Rocchetta Belbo', value: 'Rocchetta Belbo' },
  { label: 'Rocchetta Di Vara', value: 'Rocchetta Di Vara' },
  { label: 'Rocchetta Ligure', value: 'Rocchetta Ligure' },
  { label: 'Rocchetta Nervina', value: 'Rocchetta Nervina' },
  { label: 'Rocchetta Nuova', value: 'Rocchetta Nuova' },
  { label: 'Rocchetta Palafea', value: 'Rocchetta Palafea' },
  { label: 'Rocchetta Santantonio', value: 'Rocchetta Santantonio' },
  { label: 'Rocchetta Tanaro', value: 'Rocchetta Tanaro' },
  { label: 'Rocha', value: 'Rocha' },
  { label: 'Rochdale', value: 'Rochdale' },
  { label: 'Roche', value: 'Roche' },
  { label: 'Roche', value: 'Roche' },
  { label: 'Roche Terre', value: 'Roche Terre' },
  { label: 'Roche-À-Bateau', value: 'Roche-À-Bateau' },
  { label: 'Rochechouart', value: 'Rochechouart' },
  { label: 'Rochecorbon', value: 'Rochecorbon' },
  { label: 'Rochedale', value: 'Rochedale' },
  { label: 'Rochedale South', value: 'Rochedale South' },
  { label: 'Rochedo', value: 'Rochedo' },
  { label: 'Rochedo De Minas', value: 'Rochedo De Minas' },
  { label: 'Rochefort', value: 'Rochefort' },
  { label: 'Rochefort', value: 'Rochefort' },
  { label: 'Rochefort-Du-Gard', value: 'Rochefort-Du-Gard' },
  { label: 'Rochefort-Sur-Loire', value: 'Rochefort-Sur-Loire' },
  { label: 'Rochegda', value: 'Rochegda' },
  { label: 'Rochegude', value: 'Rochegude' },
  { label: 'Roche-La-Molière', value: 'Roche-La-Molière' },
  { label: 'Roche-Lez-Beaupré', value: 'Roche-Lez-Beaupré' },
  { label: 'Rochelle', value: 'Rochelle' },
  { label: 'Rochelle', value: 'Rochelle' },
  { label: 'Rochelle Park', value: 'Rochelle Park' },
  { label: 'Rochemaure', value: 'Rochemaure' },
  { label: 'Rocherlea', value: 'Rocherlea' },
  { label: 'Roches Noire', value: 'Roches Noire' },
  { label: 'Rocheservière', value: 'Rocheservière' },
  { label: 'Roches-Prémarie-Andillé', value: 'Roches-Prémarie-Andillé' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rochester Hills', value: 'Rochester Hills' },
  { label: 'Rochetaillée-Sur-Saône', value: 'Rochetaillée-Sur-Saône' },
  { label: 'Rochford', value: 'Rochford' },
  { label: 'Rochfortbridge', value: 'Rochfortbridge' },
  { label: 'Röchling-Höhe', value: 'Röchling-Höhe' },
  { label: 'Rochlitz', value: 'Rochlitz' },
  { label: 'Rochor', value: 'Rochor' },
  { label: 'Rochoso', value: 'Rochoso' },
  { label: 'Rociana Del Condado', value: 'Rociana Del Condado' },
  { label: 'Rociu', value: 'Rociu' },
  { label: 'Rock', value: 'Rock' },
  { label: 'Rock', value: 'Rock' },
  { label: 'Rock County', value: 'Rock County' },
  { label: 'Rock County', value: 'Rock County' },
  { label: 'Rock County', value: 'Rock County' },
  { label: 'Rock Creek', value: 'Rock Creek' },
  { label: 'Rock Creek', value: 'Rock Creek' },
  { label: 'Rock Falls', value: 'Rock Falls' },
  { label: 'Rock Forest', value: 'Rock Forest' },
  { label: 'Rock Hall', value: 'Rock Hall' },
  { label: 'Rock Hall', value: 'Rock Hall' },
  { label: 'Rock Hall', value: 'Rock Hall' },
  { label: 'Rock Hill', value: 'Rock Hill' },
  { label: 'Rock Hill', value: 'Rock Hill' },
  { label: 'Rock Hill', value: 'Rock Hill' },
  { label: 'Rock Island', value: 'Rock Island' },
  { label: 'Rock Island', value: 'Rock Island' },
  { label: 'Rock Island County', value: 'Rock Island County' },
  { label: 'Rock Port', value: 'Rock Port' },
  { label: 'Rock Rapids', value: 'Rock Rapids' },
  { label: 'Rock River', value: 'Rock River' },
  { label: 'Rock Spring', value: 'Rock Spring' },
  { label: 'Rock Springs', value: 'Rock Springs' },
  { label: 'Rock Valley', value: 'Rock Valley' },
  { label: 'Rockanje', value: 'Rockanje' },
  { label: 'Rockaway', value: 'Rockaway' },
  { label: 'Rockaway Beach', value: 'Rockaway Beach' },
  { label: 'Rockaway Point', value: 'Rockaway Point' },
  { label: 'Rockbank', value: 'Rockbank' },
  { label: 'Rockbridge County', value: 'Rockbridge County' },
  { label: 'Rockcastle County', value: 'Rockcastle County' },
  { label: 'Rockcreek', value: 'Rockcreek' },
  { label: 'Rockdale', value: 'Rockdale' },
  { label: 'Rockdale', value: 'Rockdale' },
  { label: 'Rockdale', value: 'Rockdale' },
  { label: 'Rockdale County', value: 'Rockdale County' },
  { label: 'Rockenberg', value: 'Rockenberg' },
  { label: 'Rockenhausen', value: 'Rockenhausen' },
  { label: 'Rockfish', value: 'Rockfish' },
  { label: 'Rockford', value: 'Rockford' },
  { label: 'Rockford', value: 'Rockford' },
  { label: 'Rockford', value: 'Rockford' },
  { label: 'Rockford', value: 'Rockford' },
  { label: 'Rockford', value: 'Rockford' },
  { label: 'Rockhampton', value: 'Rockhampton' },
  { label: 'Rockingham', value: 'Rockingham' },
  { label: 'Rockingham', value: 'Rockingham' },
  { label: 'Rockingham', value: 'Rockingham' },
  { label: 'Rockingham City Centre', value: 'Rockingham City Centre' },
  { label: 'Rockingham County', value: 'Rockingham County' },
  { label: 'Rockingham County', value: 'Rockingham County' },
  { label: 'Rockingham County', value: 'Rockingham County' },
  { label: 'Rockland', value: 'Rockland' },
  { label: 'Rockland', value: 'Rockland' },
  { label: 'Rockland County', value: 'Rockland County' },
  { label: 'Rocklea', value: 'Rocklea' },
  { label: 'Rockledge', value: 'Rockledge' },
  { label: 'Rockledge', value: 'Rockledge' },
  { label: 'Rocklin', value: 'Rocklin' },
  { label: 'Rockmart', value: 'Rockmart' },
  { label: 'Rockport', value: 'Rockport' },
  { label: 'Rockport', value: 'Rockport' },
  { label: 'Rockport', value: 'Rockport' },
  { label: 'Rockport', value: 'Rockport' },
  { label: 'Rocksprings', value: 'Rocksprings' },
  { label: 'Rockton', value: 'Rockton' },
  { label: 'Rockville', value: 'Rockville' },
  { label: 'Rockville', value: 'Rockville' },
  { label: 'Rockville', value: 'Rockville' },
  { label: 'Rockville', value: 'Rockville' },
  { label: 'Rockville', value: 'Rockville' },
  { label: 'Rockville Centre', value: 'Rockville Centre' },
  { label: 'Rockwall', value: 'Rockwall' },
  { label: 'Rockwall County', value: 'Rockwall County' },
  { label: 'Rockwell', value: 'Rockwell' },
  { label: 'Rockwell', value: 'Rockwell' },
  { label: 'Rockwell', value: 'Rockwell' },
  { label: 'Rockwell City', value: 'Rockwell City' },
  { label: 'Rockwood', value: 'Rockwood' },
  { label: 'Rockwood', value: 'Rockwood' },
  { label: 'Rockwood', value: 'Rockwood' },
  { label: 'Rocky Ford', value: 'Rocky Ford' },
  { label: 'Rocky Mount', value: 'Rocky Mount' },
  { label: 'Rocky Mount', value: 'Rocky Mount' },
  { label: 'Rocky Mountain House', value: 'Rocky Mountain House' },
  { label: 'Rocky Point', value: 'Rocky Point' },
  { label: 'Rocky Point', value: 'Rocky Point' },
  { label: 'Rocky Point', value: 'Rocky Point' },
  { label: 'Rocky Point', value: 'Rocky Point' },
  { label: 'Rocky River', value: 'Rocky River' },
  { label: 'Rocky Top', value: 'Rocky Top' },
  { label: 'Rocquencourt', value: 'Rocquencourt' },
  { label: 'Rocroi', value: 'Rocroi' },
  { label: 'Roczyny', value: 'Roczyny' },
  { label: 'Rod Baneh', value: 'Rod Baneh' },
  { label: 'Roda De Andalucía La', value: 'Roda De Andalucía La' },
  { label: 'Roda De Barà', value: 'Roda De Barà' },
  { label: 'Roda De Eresma', value: 'Roda De Eresma' },
  { label: 'Roda De Ter', value: 'Roda De Ter' },
  { label: 'Roda La', value: 'Roda La' },
  { label: 'Rodalben', value: 'Rodalben' },
  { label: 'Rodange', value: 'Rodange' },
  { label: 'Rodas', value: 'Rodas' },
  { label: 'Rodatychi', value: 'Rodatychi' },
  { label: 'Rødby', value: 'Rødby' },
  { label: 'Rødbyhavn', value: 'Rødbyhavn' },
  { label: 'Rodd Point', value: 'Rodd Point' },
  { label: 'Roddi', value: 'Roddi' },
  { label: 'Rødding', value: 'Rødding' },
  { label: 'Roddino', value: 'Roddino' },
  { label: 'Rode', value: 'Rode' },
  { label: 'Rode Heath', value: 'Rode Heath' },
  { label: 'Rodeano', value: 'Rodeano' },
  { label: 'Rödeby', value: 'Rödeby' },
  { label: 'Rodeio', value: 'Rodeio' },
  { label: 'Rodeio Bonito', value: 'Rodeio Bonito' },
  { label: 'Rodeiro', value: 'Rodeiro' },
  { label: 'Rodeiro', value: 'Rodeiro' },
  { label: 'Rødekro', value: 'Rødekro' },
  { label: 'Rodelas', value: 'Rodelas' },
  { label: 'Rodello', value: 'Rodello' },
  { label: 'Rödelsee', value: 'Rödelsee' },
  { label: 'Roden', value: 'Roden' },
  { label: 'Roden', value: 'Roden' },
  { label: 'Ródenas', value: 'Ródenas' },
  { label: 'Rodenbach', value: 'Rodenbach' },
  { label: 'Rodenbach', value: 'Rodenbach' },
  { label: 'Rodenberg', value: 'Rodenberg' },
  { label: 'Rodengo', value: 'Rodengo' },
  { label: 'Rodengo - Rodeneck', value: 'Rodengo - Rodeneck' },
  { label: 'Rodengo-Saiano', value: 'Rodengo-Saiano' },
  { label: 'Rödental', value: 'Rödental' },
  { label: 'Rodeo', value: 'Rodeo' },
  { label: 'Rodeo', value: 'Rodeo' },
  { label: 'Rodeo Viejo', value: 'Rodeo Viejo' },
  { label: 'Rodero', value: 'Rodero' },
  { label: 'Rodersdorf', value: 'Rodersdorf' },
  { label: 'Rödersheim-Gronau', value: 'Rödersheim-Gronau' },
  { label: 'Rodewisch', value: 'Rodewisch' },
  { label: 'Rodez', value: 'Rodez' },
  { label: 'Rodezno', value: 'Rodezno' },
  { label: 'Rodgau', value: 'Rodgau' },
  { label: 'Rodhítsa', value: 'Rodhítsa' },
  { label: 'Rodì', value: 'Rodì' },
  { label: 'Rodi Garganico', value: 'Rodi Garganico' },
  { label: 'Rodian', value: 'Rodian' },
  { label: 'Rodigo', value: 'Rodigo' },
  { label: 'Rodilhan', value: 'Rodilhan' },
  { label: 'Rodina', value: 'Rodina' },
  { label: 'Roding', value: 'Roding' },
  { label: 'Rödinghausen', value: 'Rödinghausen' },
  { label: 'Rodino', value: 'Rodino' },
  { label: 'Rodinskiy Rayon', value: 'Rodinskiy Rayon' },
  { label: 'Rodionovo-Nesvetaiskoye', value: 'Rodionovo-Nesvetaiskoye' },
  { label: 'Rodionovo-Nesvetayskaya', value: 'Rodionovo-Nesvetayskaya' },
  { label: 'Rodleben', value: 'Rodleben' },
  { label: 'Rodna', value: 'Rodna' },
  { label: 'Rodney Bay', value: 'Rodney Bay' },
  { label: 'Rodney Heights', value: 'Rodney Heights' },
  { label: 'Rodney Village', value: 'Rodney Village' },
  { label: 'Rodniki', value: 'Rodniki' },
  { label: 'Rodniki', value: 'Rodniki' },
  { label: 'Rodnikovo', value: 'Rodnikovo' },
  { label: 'Rodnikovskaya', value: 'Rodnikovskaya' },
  { label: 'Rodnikovskiy Rayon', value: 'Rodnikovskiy Rayon' },
  { label: 'Rodoč', value: 'Rodoč' },
  { label: 'Rododáfni', value: 'Rododáfni' },
  { label: 'Rodolfo Fernandes', value: 'Rodolfo Fernandes' },
  { label: 'Rodolfo Sánchez Taboada', value: 'Rodolfo Sánchez Taboada' },
  { label: 'Rodolívos', value: 'Rodolívos' },
  { label: 'Rodonyà', value: 'Rodonyà' },
  { label: 'Rodópoli', value: 'Rodópoli' },
  { label: 'Ródos', value: 'Ródos' },
  { label: 'Rodotópi', value: 'Rodotópi' },
  { label: 'Rødovre', value: 'Rødovre' },
  { label: 'Rødovre Kommune', value: 'Rødovre Kommune' },
  { label: 'Rødøy', value: 'Rødøy' },
  { label: 'Rodrigo', value: 'Rodrigo' },
  { label: 'Rodrígo M. Quevedo', value: 'Rodrígo M. Quevedo' },
  { label: 'Rodrigues Alves', value: 'Rodrigues Alves' },
  { label: 'Rodriguez', value: 'Rodriguez' },
  { label: 'Rodríguez', value: 'Rodríguez' },
  { label: 'Rodríguez Tejeda', value: 'Rodríguez Tejeda' },
  { label: 'Rodulfo Figueroa', value: 'Rodulfo Figueroa' },
  { label: 'Rødvig', value: 'Rødvig' },
  { label: 'Rodyns’Ke', value: 'Rodyns’Ke' },
  { label: 'Roè', value: 'Roè' },
  { label: 'Roè Volciano', value: 'Roè Volciano' },
  { label: 'Roebling', value: 'Roebling' },
  { label: 'Roebuck', value: 'Roebuck' },
  { label: 'Roebuck', value: 'Roebuck' },
  { label: 'Roehampton', value: 'Roehampton' },
  { label: 'Roehampton', value: 'Roehampton' },
  { label: 'Roeland Park', value: 'Roeland Park' },
  { label: 'Roelofarendsveen', value: 'Roelofarendsveen' },
  { label: 'Roelos De Sayago', value: 'Roelos De Sayago' },
  { label: 'Roermond', value: 'Roermond' },
  { label: 'Roeschwoog', value: 'Roeschwoog' },
  { label: 'Roeselare', value: 'Roeselare' },
  { label: 'Roeser', value: 'Roeser' },
  { label: 'Roessleville', value: 'Roessleville' },
  { label: 'Roeşti', value: 'Roeşti' },
  { label: 'Roetgen', value: 'Roetgen' },
  { label: 'Roeulx', value: 'Roeulx' },
  { label: 'Rœulx', value: 'Rœulx' },
  { label: 'Rœux', value: 'Rœux' },
  { label: 'Roézé-Sur-Sarthe', value: 'Roézé-Sur-Sarthe' },
  { label: 'Rofayyeh', value: 'Rofayyeh' },
  { label: 'Röfingen', value: 'Röfingen' },
  { label: 'Rofrano', value: 'Rofrano' },
  { label: 'Rogachev', value: 'Rogachev' },
  { label: 'Rogachëvo', value: 'Rogachëvo' },
  { label: 'Rogalinek', value: 'Rogalinek' },
  { label: 'Rogaška Slatina', value: 'Rogaška Slatina' },
  { label: 'Rogašovci', value: 'Rogašovci' },
  { label: 'Rogatec', value: 'Rogatec' },
  { label: 'Rogatica', value: 'Rogatica' },
  { label: 'Rogätz', value: 'Rogätz' },
  { label: 'Rogeno', value: 'Rogeno' },
  { label: 'Roger Mills County', value: 'Roger Mills County' },
  { label: 'Rogers', value: 'Rogers' },
  { label: 'Rogers', value: 'Rogers' },
  { label: 'Rogers', value: 'Rogers' },
  { label: 'Rogers City', value: 'Rogers City' },
  { label: 'Rogers County', value: 'Rogers County' },
  { label: 'Rogers Park', value: 'Rogers Park' },
  { label: 'Rogersville', value: 'Rogersville' },
  { label: 'Rogersville', value: 'Rogersville' },
  { label: 'Rogersville', value: 'Rogersville' },
  { label: 'Rogerville', value: 'Rogerville' },
  { label: 'Roggenburg', value: 'Roggenburg' },
  { label: 'Roggendorf', value: 'Roggendorf' },
  { label: 'Roggentin', value: 'Roggentin' },
  { label: 'Roggiano Gravina', value: 'Roggiano Gravina' },
  { label: 'Roggione', value: 'Roggione' },
  { label: 'Roggwil', value: 'Roggwil' },
  { label: 'Roggwil', value: 'Roggwil' },
  { label: 'Roghudi', value: 'Roghudi' },
  { label: 'Roghun', value: 'Roghun' },
  { label: 'Rogiet', value: 'Rogiet' },
  { label: 'Rogliano', value: 'Rogliano' },
  { label: 'Rognac', value: 'Rognac' },
  { label: 'Rognan', value: 'Rognan' },
  { label: 'Rognano', value: 'Rognano' },
  { label: 'Rognedino', value: 'Rognedino' },
  { label: 'Rognedinskiy Rayon', value: 'Rognedinskiy Rayon' },
  { label: 'Rognes', value: 'Rognes' },
  { label: 'Rogno', value: 'Rogno' },
  { label: 'Rognonas', value: 'Rognonas' },
  { label: 'Rogolo', value: 'Rogolo' },
  { label: 'Rogongon', value: 'Rogongon' },
  { label: 'Rogoredo-Valaperta-Rimoldo', value: 'Rogoredo-Valaperta-Rimoldo' },
  { label: 'Rogova', value: 'Rogova' },
  { label: 'Rogovatoye', value: 'Rogovatoye' },
  { label: 'Rogovskaya', value: 'Rogovskaya' },
  { label: 'Rogów', value: 'Rogów' },
  { label: 'Rogów', value: 'Rogów' },
  { label: 'Rogów', value: 'Rogów' },
  { label: 'Rogowo', value: 'Rogowo' },
  { label: 'Rogoz', value: 'Rogoz' },
  { label: 'Rogoza', value: 'Rogoza' },
  { label: 'Rogoznica', value: 'Rogoznica' },
  { label: 'Rogoznica Općina', value: 'Rogoznica Općina' },
  { label: 'Rogoźnik', value: 'Rogoźnik' },
  { label: 'Rogoźno', value: 'Rogoźno' },
  { label: 'Rogóźno', value: 'Rogóźno' },
  { label: 'Rogóźno', value: 'Rogóźno' },
  { label: 'Rogóźno', value: 'Rogóźno' },
  { label: 'Rogue River', value: 'Rogue River' },
  { label: 'Roha', value: 'Roha' },
  { label: 'Roha', value: 'Roha' },
  { label: 'Rohachi', value: 'Rohachi' },
  { label: 'Rohan', value: 'Rohan' },
  { label: 'Rohatec', value: 'Rohatec' },
  { label: 'Rohatyn', value: 'Rohatyn' },
  { label: 'Rohatyns’Kyy Rayon', value: 'Rohatyns’Kyy Rayon' },
  { label: 'Rohia', value: 'Rohia' },
  { label: 'Rohia', value: 'Rohia' },
  { label: 'Rohini', value: 'Rohini' },
  { label: 'Rohnert Park', value: 'Rohnert Park' },
  { label: 'Rohr', value: 'Rohr' },
  { label: 'Rohr', value: 'Rohr' },
  { label: 'Rohr', value: 'Rohr' },
  { label: 'Rohr Im Burgenland', value: 'Rohr Im Burgenland' },
  { label: 'Rohr Im Gebirge', value: 'Rohr Im Gebirge' },
  { label: 'Rohr Im Kremstal', value: 'Rohr Im Kremstal' },
  { label: 'Rohrau', value: 'Rohrau' },
  { label: 'Rohrbach', value: 'Rohrbach' },
  { label: 'Rohrbach', value: 'Rohrbach' },
  { label: 'Rohrbach', value: 'Rohrbach' },
  { label: 'Rohrbach An Der Gölsen', value: 'Rohrbach An Der Gölsen' },
  { label: 'Rohrbach An Der Lafnitz', value: 'Rohrbach An Der Lafnitz' },
  { label: 'Rohrbach Bei Mattersburg', value: 'Rohrbach Bei Mattersburg' },
  { label: 'Rohrbach-Berg', value: 'Rohrbach-Berg' },
  { label: 'Rohrbach-Lès-Bitche', value: 'Rohrbach-Lès-Bitche' },
  { label: 'Rohrberg', value: 'Rohrberg' },
  { label: 'Rohrdorf', value: 'Rohrdorf' },
  { label: 'Rohrdorf', value: 'Rohrdorf' },
  { label: 'Röhrenbach', value: 'Röhrenbach' },
  { label: 'Rohrendorf Bei Krems', value: 'Rohrendorf Bei Krems' },
  { label: 'Rohrenfels', value: 'Rohrenfels' },
  { label: 'Rohri', value: 'Rohri' },
  { label: 'Röhrmoos', value: 'Röhrmoos' },
  { label: 'Röhrnbach', value: 'Röhrnbach' },
  { label: 'Röhrsen', value: 'Röhrsen' },
  { label: 'Rohru', value: 'Rohru' },
  { label: 'Rohtak', value: 'Rohtak' },
  { label: 'Rohtas', value: 'Rohtas' },
  { label: 'Roi Et', value: 'Roi Et' },
  { label: 'Roiate', value: 'Roiate' },
  { label: 'Roiffieux', value: 'Roiffieux' },
  { label: 'Roigheim', value: 'Roigheim' },
  { label: 'Roio Del Sangro', value: 'Roio Del Sangro' },
  { label: 'Rois', value: 'Rois' },
  { label: 'Roisel', value: 'Roisel' },
  { label: 'Roissy-En-Brie', value: 'Roissy-En-Brie' },
  { label: 'Roissy-En-France', value: 'Roissy-En-France' },
  { label: 'Roitham', value: 'Roitham' },
  { label: 'Roitzsch', value: 'Roitzsch' },
  { label: 'Roja', value: 'Roja' },
  { label: 'Rojales', value: 'Rojales' },
  { label: 'Rojas', value: 'Rojas' },
  { label: 'Rojas De Cuauhtémoc', value: 'Rojas De Cuauhtémoc' },
  { label: 'Rojhan', value: 'Rojhan' },
  { label: 'Rojiște', value: 'Rojiște' },
  { label: 'Rokeby', value: 'Rokeby' },
  { label: 'Rokiciny', value: 'Rokiciny' },
  { label: 'Rokiciny', value: 'Rokiciny' },
  { label: 'Rokiciny-Kolonia', value: 'Rokiciny-Kolonia' },
  { label: 'Rokietnica', value: 'Rokietnica' },
  { label: 'Rokietnica', value: 'Rokietnica' },
  { label: 'Rokiškis', value: 'Rokiškis' },
  { label: 'Rokitno', value: 'Rokitno' },
  { label: 'Rokitno Szlacheckie', value: 'Rokitno Szlacheckie' },
  { label: 'Roknäs', value: 'Roknäs' },
  { label: 'Rokosovo', value: 'Rokosovo' },
  { label: 'Rokovci', value: 'Rokovci' },
  { label: 'Rokupr', value: 'Rokupr' },
  { label: 'Rokycany', value: 'Rokycany' },
  { label: 'Rokytne', value: 'Rokytne' },
  { label: 'Rokytne', value: 'Rokytne' },
  { label: 'Rokytne Raion', value: 'Rokytne Raion' },
  { label: 'Rokytnice', value: 'Rokytnice' },
  { label: 'Rokytnice Nad Jizerou', value: 'Rokytnice Nad Jizerou' },
  { label: 'Rokytnice V Orlických Horách', value: 'Rokytnice V Orlických Horách' },
  { label: 'Rolador', value: 'Rolador' },
  { label: 'Rolampont', value: 'Rolampont' },
  { label: 'Roland', value: 'Roland' },
  { label: 'Roland', value: 'Roland' },
  { label: 'Rolândia', value: 'Rolândia' },
  { label: 'Rolante', value: 'Rolante' },
  { label: 'Roldán', value: 'Roldán' },
  { label: 'Roldanillo', value: 'Roldanillo' },
  { label: 'Rolde', value: 'Rolde' },
  { label: 'Rolea Bier', value: 'Rolea Bier' },
  { label: 'Rolesville', value: 'Rolesville' },
  { label: 'Rolette County', value: 'Rolette County' },
  { label: 'Roletto', value: 'Roletto' },
  { label: 'Roleystone', value: 'Roleystone' },
  { label: 'Rolfs', value: 'Rolfs' },
  { label: 'Rolim De Moura', value: 'Rolim De Moura' },
  { label: 'Rolla', value: 'Rolla' },
  { label: 'Rolla', value: 'Rolla' },
  { label: 'Rollag', value: 'Rollag' },
  { label: 'Rollamienta', value: 'Rollamienta' },
  { label: 'Rollán', value: 'Rollán' },
  { label: 'Röllbach', value: 'Röllbach' },
  { label: 'Rolle', value: 'Rolle' },
  { label: 'Rolleston', value: 'Rolleston' },
  { label: 'Rolleville', value: 'Rolleville' },
  { label: 'Rolling Fork', value: 'Rolling Fork' },
  { label: 'Rolling Hills', value: 'Rolling Hills' },
  { label: 'Rolling Hills Estates', value: 'Rolling Hills Estates' },
  { label: 'Rolling Meadows', value: 'Rolling Meadows' },
  { label: 'Rollingen', value: 'Rollingen' },
  { label: 'Rollington Town', value: 'Rollington Town' },
  { label: 'Rollingwood', value: 'Rollingwood' },
  { label: 'Rollingwood', value: 'Rollingwood' },
  { label: 'Rollinsford', value: 'Rollinsford' },
  { label: 'Rolo', value: 'Rolo' },
  { label: 'Rolpa ', value: 'Rolpa ' },
  { label: 'Roma', value: 'Roma' },
  { label: 'Roma', value: 'Roma' },
  { label: 'Roma', value: 'Roma' },
  { label: 'Romagnano', value: 'Romagnano' },
  { label: 'Romagnano Sesia', value: 'Romagnano Sesia' },
  { label: 'Romagnat', value: 'Romagnat' },
  { label: 'Romagné', value: 'Romagné' },
  { label: 'Romagnese', value: 'Romagnese' },
  { label: 'Romagnieu', value: 'Romagnieu' },
  { label: 'Romagny', value: 'Romagny' },
  { label: 'Romaine', value: 'Romaine' },
  { label: 'Romainville', value: 'Romainville' },
  { label: 'Romallo', value: 'Romallo' },
  { label: 'Roma-Los Saenz', value: 'Roma-Los Saenz' },
  { label: 'Roman', value: 'Roman' },
  { label: 'Roman', value: 'Roman' },
  { label: 'Roman Forest', value: 'Roman Forest' },
  { label: 'Romana', value: 'Romana' },
  { label: 'Romana La', value: 'Romana La' },
  { label: 'Românaşi', value: 'Românaşi' },
  { label: 'Romanèche-Thorins', value: 'Romanèche-Thorins' },
  { label: 'Romanel-Sur-Lausanne', value: 'Romanel-Sur-Lausanne' },
  { label: 'Romanengo', value: 'Romanengo' },
  { label: 'Româneşti', value: 'Româneşti' },
  { label: 'Românești', value: 'Românești' },
  { label: 'Românești', value: 'Românești' },
  { label: 'Românești', value: 'Românești' },
  { label: 'Românești', value: 'Românești' },
  { label: 'Romangordo', value: 'Romangordo' },
  { label: 'Români', value: 'Români' },
  { label: 'Români', value: 'Români' },
  { label: 'Romanillos De Atienza', value: 'Romanillos De Atienza' },
  { label: 'Romaniv', value: 'Romaniv' },
  { label: 'Romaniv', value: 'Romaniv' },
  { label: 'Romano Banco', value: 'Romano Banco' },
  { label: 'Romano Canavese', value: 'Romano Canavese' },
  { label: 'Romano Dezzelino', value: 'Romano Dezzelino' },
  { label: 'Romano Di Lombardia', value: 'Romano Di Lombardia' },
  { label: 'Romanones', value: 'Romanones' },
  { label: 'Romanos', value: 'Romanos' },
  { label: 'Romanovci', value: 'Romanovci' },
  { label: 'Romanovka', value: 'Romanovka' },
  { label: 'Romanovka', value: 'Romanovka' },
  { label: 'Romanovka', value: 'Romanovka' },
  { label: 'Romanovo', value: 'Romanovo' },
  { label: 'Romanovskaya', value: 'Romanovskaya' },
  { label: 'Romanovskiy Rayon', value: 'Romanovskiy Rayon' },
  { label: 'Romans Disonzo', value: 'Romans Disonzo' },
  { label: 'Romanshorn', value: 'Romanshorn' },
  { label: 'Romans-Sur-Isère', value: 'Romans-Sur-Isère' },
  { label: 'Romanswiller', value: 'Romanswiller' },
  { label: 'Romanu', value: 'Romanu' },
  { label: 'Romanzado', value: 'Romanzado' },
  { label: 'Romaria', value: 'Romaria' },
  { label: 'Romashkino', value: 'Romashkino' },
  { label: 'Rombas', value: 'Rombas' },
  { label: 'Rombiolo', value: 'Rombiolo' },
  { label: 'Romblon', value: 'Romblon' },
  { label: 'Rome', value: 'Rome' },
  { label: 'Rome', value: 'Rome' },
  { label: 'Rome', value: 'Rome' },
  { label: 'Rome', value: 'Rome' },
  { label: 'Rome', value: 'Rome' },
  { label: 'Rome', value: 'Rome' },
  { label: 'Rome City', value: 'Rome City' },
  { label: 'Romelândia', value: 'Romelândia' },
  { label: 'Romenay', value: 'Romenay' },
  { label: 'Romeno', value: 'Romeno' },
  { label: 'Romens’Ka Mis’Krada', value: 'Romens’Ka Mis’Krada' },
  { label: 'Romentino', value: 'Romentino' },
  { label: 'Romeo', value: 'Romeo' },
  { label: 'Romeoville', value: 'Romeoville' },
  { label: 'Romeral', value: 'Romeral' },
  { label: 'Romeral El', value: 'Romeral El' },
  { label: 'Romerillo', value: 'Romerillo' },
  { label: 'Romero De Guzmán', value: 'Romero De Guzmán' },
  { label: 'Romero De Torres', value: 'Romero De Torres' },
  { label: 'Römerswil', value: 'Römerswil' },
  { label: 'Rometta', value: 'Rometta' },
  { label: 'Rometta Marea', value: 'Rometta Marea' },
  { label: 'Romford', value: 'Romford' },
  { label: 'Romhány', value: 'Romhány' },
  { label: 'Römhild', value: 'Römhild' },
  { label: 'Romillé', value: 'Romillé' },
  { label: 'Romilly-Sur-Andelle', value: 'Romilly-Sur-Andelle' },
  { label: 'Romilly-Sur-Seine', value: 'Romilly-Sur-Seine' },
  { label: 'Rominimbang', value: 'Rominimbang' },
  { label: 'Rominimbang', value: 'Rominimbang' },
  { label: 'Romita', value: 'Romita' },
  { label: 'Romito Magra', value: 'Romito Magra' },
  { label: 'Romiton', value: 'Romiton' },
  { label: 'Romme', value: 'Romme' },
  { label: 'Rommerskirchen', value: 'Rommerskirchen' },
  { label: 'Romnenskiy Rayon', value: 'Romnenskiy Rayon' },
  { label: 'Romney', value: 'Romney' },
  { label: 'Romney Marsh', value: 'Romney Marsh' },
  { label: 'Romny', value: 'Romny' },
  { label: 'Romny', value: 'Romny' },
  { label: 'Romodan', value: 'Romodan' },
  { label: 'Romodanovo', value: 'Romodanovo' },
  { label: 'Romodanovskiy Rayon', value: 'Romodanovskiy Rayon' },
  { label: 'Romoland', value: 'Romoland' },
  { label: 'Romont', value: 'Romont' },
  { label: 'Romorantin-Lanthenay', value: 'Romorantin-Lanthenay' },
  { label: 'Romos', value: 'Romos' },
  { label: 'Romrod', value: 'Romrod' },
  { label: 'Romsey', value: 'Romsey' },
  { label: 'Romsey', value: 'Romsey' },
  { label: 'Rømskog', value: 'Rømskog' },
  { label: 'Romsley', value: 'Romsley' },
  { label: 'Romuli', value: 'Romuli' },
  { label: 'Romulus', value: 'Romulus' },
  { label: 'Ron', value: 'Ron' },
  { label: 'Ron Phibun', value: 'Ron Phibun' },
  { label: 'Rona De Jos', value: 'Rona De Jos' },
  { label: 'Rona De Sus', value: 'Rona De Sus' },
  { label: 'Ronago', value: 'Ronago' },
  { label: 'Ronan', value: 'Ronan' },
  { label: 'Roncà', value: 'Roncà' },
  { label: 'Roncade', value: 'Roncade' },
  { label: 'Roncadelle', value: 'Roncadelle' },
  { label: 'Roncador', value: 'Roncador' },
  { label: 'Roncal/Erronkari', value: 'Roncal/Erronkari' },
  { label: 'Roncanova', value: 'Roncanova' },
  { label: 'Roncaro', value: 'Roncaro' },
  { label: 'Roncegno', value: 'Roncegno' },
  { label: 'Roncello', value: 'Roncello' },
  { label: 'Roncesvalles', value: 'Roncesvalles' },
  { label: 'Ronceverte', value: 'Ronceverte' },
  { label: 'Ronchamp', value: 'Ronchamp' },
  { label: 'Roncherolles-Sur-Le-Vivier', value: 'Roncherolles-Sur-Le-Vivier' },
  { label: 'Ronchi Dei Legionari', value: 'Ronchi Dei Legionari' },
  { label: 'Ronchi Di Campanile', value: 'Ronchi Di Campanile' },
  { label: 'Ronchi Valsugana', value: 'Ronchi Valsugana' },
  { label: 'Ronchin', value: 'Ronchin' },
  { label: 'Ronchis', value: 'Ronchis' },
  { label: 'Ronciglione', value: 'Ronciglione' },
  { label: 'Ronco', value: 'Ronco' },
  { label: 'Ronco Alladige', value: 'Ronco Alladige' },
  { label: 'Ronco Biellese', value: 'Ronco Biellese' },
  { label: 'Ronco Briantino', value: 'Ronco Briantino' },
  { label: 'Ronco Canavese', value: 'Ronco Canavese' },
  { label: 'Ronco Scrivia', value: 'Ronco Scrivia' },
  { label: 'Roncobello', value: 'Roncobello' },
  { label: 'Roncocesi', value: 'Roncocesi' },
  { label: 'Roncoferraro', value: 'Roncoferraro' },
  { label: 'Roncofreddo', value: 'Roncofreddo' },
  { label: 'Roncofreddo-Santa Paola', value: 'Roncofreddo-Santa Paola' },
  { label: 'Roncola', value: 'Roncola' },
  { label: 'Roncone', value: 'Roncone' },
  { label: 'Roncq', value: 'Roncq' },
  { label: 'Ronda', value: 'Ronda' },
  { label: 'Ronda', value: 'Ronda' },
  { label: 'Ronda Alta', value: 'Ronda Alta' },
  { label: 'Rondanina', value: 'Rondanina' },
  { label: 'Rønde', value: 'Rønde' },
  { label: 'Rondebosch', value: 'Rondebosch' },
  { label: 'Rondinha', value: 'Rondinha' },
  { label: 'Rondissone', value: 'Rondissone' },
  { label: 'Rondolândia', value: 'Rondolândia' },
  { label: 'Rondon', value: 'Rondon' },
  { label: 'Rondón', value: 'Rondón' },
  { label: 'Rondon Do Pará', value: 'Rondon Do Pará' },
  { label: 'Rondonópolis', value: 'Rondonópolis' },
  { label: 'Ronfe', value: 'Ronfe' },
  { label: 'Rong Kwang', value: 'Rong Kwang' },
  { label: 'Rong’An', value: 'Rong’An' },
  { label: 'Rongai', value: 'Rongai' },
  { label: 'Ronizolya', value: 'Ronizolya' },
  { label: 'Ronkonkoma', value: 'Ronkonkoma' },
  { label: 'Rönnäng', value: 'Rönnäng' },
  { label: 'Rønne', value: 'Rønne' },
  { label: 'Ronneburg', value: 'Ronneburg' },
  { label: 'Ronneby', value: 'Ronneby' },
  { label: 'Rønnede', value: 'Rønnede' },
  { label: 'Ronnenberg', value: 'Ronnenberg' },
  { label: 'Rönninge', value: 'Rönninge' },
  { label: 'Ronov Nad Doubravou', value: 'Ronov Nad Doubravou' },
  { label: 'Ronquillo El', value: 'Ronquillo El' },
  { label: 'Röns', value: 'Röns' },
  { label: 'Ronsberg', value: 'Ronsberg' },
  { label: 'Ronse', value: 'Ronse' },
  { label: 'Ronsecco', value: 'Ronsecco' },
  { label: 'Ronshausen', value: 'Ronshausen' },
  { label: 'Ronta', value: 'Ronta' },
  { label: 'Ronzo-Chienis', value: 'Ronzo-Chienis' },
  { label: 'Ronzone', value: 'Ronzone' },
  { label: 'Roodepoort', value: 'Roodepoort' },
  { label: 'Roodhouse', value: 'Roodhouse' },
  { label: 'Rooks County', value: 'Rooks County' },
  { label: 'Roorkee', value: 'Roorkee' },
  { label: 'Roos', value: 'Roos' },
  { label: 'Roosendaal', value: 'Roosendaal' },
  { label: 'Roosevelt', value: 'Roosevelt' },
  { label: 'Roosevelt', value: 'Roosevelt' },
  { label: 'Roosevelt County', value: 'Roosevelt County' },
  { label: 'Roosevelt County', value: 'Roosevelt County' },
  { label: 'Roosevelt Gardens', value: 'Roosevelt Gardens' },
  { label: 'Roosevelt Park', value: 'Roosevelt Park' },
  { label: 'Roosteren', value: 'Roosteren' },
  { label: 'Roost-Warendin', value: 'Roost-Warendin' },
  { label: 'Root', value: 'Root' },
  { label: 'Rooty Hill', value: 'Rooty Hill' },
  { label: 'Ropa', value: 'Ropa' },
  { label: 'Ropaži', value: 'Ropaži' },
  { label: 'Ropcha', value: 'Ropcha' },
  { label: 'Ropczyce', value: 'Ropczyce' },
  { label: 'Roper Gulf', value: 'Roper Gulf' },
  { label: 'Roperuelos Del Páramo', value: 'Roperuelos Del Páramo' },
  { label: 'Ropes Crossing', value: 'Ropes Crossing' },
  { label: 'Ropice', value: 'Ropice' },
  { label: 'Roppen', value: 'Roppen' },
  { label: 'Roppolo', value: 'Roppolo' },
  { label: 'Roque', value: 'Roque' },
  { label: 'Roque Gonzales', value: 'Roque Gonzales' },
  { label: 'Roquebillière', value: 'Roquebillière' },
  { label: 'Roquebrune-Cap-Martin', value: 'Roquebrune-Cap-Martin' },
  { label: 'Roquebrune-Sur-Argens', value: 'Roquebrune-Sur-Argens' },
  { label: 'Roquecourbe', value: 'Roquecourbe' },
  { label: 'Roquefort', value: 'Roquefort' },
  { label: 'Roquefort-La-Bédoule', value: 'Roquefort-La-Bédoule' },
  { label: 'Roquemaure', value: 'Roquemaure' },
  { label: 'Roquetas De Mar', value: 'Roquetas De Mar' },
  { label: 'Roquetes', value: 'Roquetes' },
  { label: 'Roquetoire', value: 'Roquetoire' },
  { label: 'Roquettes', value: 'Roquettes' },
  { label: 'Roquevaire', value: 'Roquevaire' },
  { label: 'Rora', value: 'Rora' },
  { label: 'Rorà', value: 'Rorà' },
  { label: 'Rorainópolis', value: 'Rorainópolis' },
  { label: 'Rorbas', value: 'Rorbas' },
  { label: 'Roreto', value: 'Roreto' },
  { label: 'Røros', value: 'Røros' },
  { label: 'Rorschach', value: 'Rorschach' },
  { label: 'Rørvik', value: 'Rørvik' },
  { label: 'Rosa', value: 'Rosa' },
  { label: 'Rosà', value: 'Rosà' },
  { label: 'Rosa Blanca', value: 'Rosa Blanca' },
  { label: 'Rosa Sanchez', value: 'Rosa Sanchez' },
  { label: 'Rosa Zarate', value: 'Rosa Zarate' },
  { label: 'Rosairinho', value: 'Rosairinho' },
  { label: 'Rosal De La Frontera', value: 'Rosal De La Frontera' },
  { label: 'Rosal O', value: 'Rosal O' },
  { label: 'Rosalejo', value: 'Rosalejo' },
  { label: 'Rosales', value: 'Rosales' },
  { label: 'Rosales', value: 'Rosales' },
  { label: 'Rosales', value: 'Rosales' },
  { label: 'Rosales', value: 'Rosales' },
  { label: 'Rosalie', value: 'Rosalie' },
  { label: 'Rosamond', value: 'Rosamond' },
  { label: 'Rosamorada', value: 'Rosamorada' },
  { label: 'Rosana', value: 'Rosana' },
  { label: 'Rosanna', value: 'Rosanna' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosário', value: 'Rosário' },
  { label: 'Rosário Da Limeira', value: 'Rosário Da Limeira' },
  { label: 'Rosario De Mora', value: 'Rosario De Mora' },
  { label: 'Rosario Del Tala', value: 'Rosario Del Tala' },
  { label: 'Rosário Do Catete', value: 'Rosário Do Catete' },
  { label: 'Rosário Do Ivaí', value: 'Rosário Do Ivaí' },
  { label: 'Rosário Do Sul', value: 'Rosário Do Sul' },
  { label: 'Rosario El', value: 'Rosario El' },
  { label: 'Rosário Oeste', value: 'Rosário Oeste' },
  { label: 'Rosarito', value: 'Rosarito' },
  { label: 'Rosarno', value: 'Rosarno' },
  { label: 'Rosaryville', value: 'Rosaryville' },
  { label: 'Rosas', value: 'Rosas' },
  { label: 'Rosasco', value: 'Rosasco' },
  { label: 'Rosate', value: 'Rosate' },
  { label: 'Rosazza', value: 'Rosazza' },
  { label: 'Rosbach Vor Der Höhe', value: 'Rosbach Vor Der Höhe' },
  { label: 'Roșcani', value: 'Roșcani' },
  { label: 'Roșcani', value: 'Roșcani' },
  { label: 'Roscanvel', value: 'Roscanvel' },
  { label: 'Rosche', value: 'Rosche' },
  { label: 'Röschenz', value: 'Röschenz' },
  { label: 'Roschinskiy', value: 'Roschinskiy' },
  { label: 'Röschitz', value: 'Röschitz' },
  { label: 'Rosciano', value: 'Rosciano' },
  { label: 'Rosciano', value: 'Rosciano' },
  { label: 'Roscigno', value: 'Roscigno' },
  { label: 'Rościszewo', value: 'Rościszewo' },
  { label: 'Roscoe', value: 'Roscoe' },
  { label: 'Roscoe', value: 'Roscoe' },
  { label: 'Roscoff', value: 'Roscoff' },
  { label: 'Roscommon', value: 'Roscommon' },
  { label: 'Roscommon', value: 'Roscommon' },
  { label: 'Roscommon County', value: 'Roscommon County' },
  { label: 'Roscrea', value: 'Roscrea' },
  { label: 'Rosdorf', value: 'Rosdorf' },
  { label: 'Rose', value: 'Rose' },
  { label: 'Rose Bay', value: 'Rose Bay' },
  { label: 'Rose Belle', value: 'Rose Belle' },
  { label: 'Rose Hall', value: 'Rose Hall' },
  { label: 'Rose Hall', value: 'Rose Hall' },
  { label: 'Rose Heights', value: 'Rose Heights' },
  { label: 'Rose Hill', value: 'Rose Hill' },
  { label: 'Rose Hill', value: 'Rose Hill' },
  { label: 'Rose Hill', value: 'Rose Hill' },
  { label: 'Rose Hill', value: 'Rose Hill' },
  { label: 'Rose Hill', value: 'Rose Hill' },
  { label: 'Rose Hill', value: 'Rose Hill' },
  { label: 'Rose Lodge', value: 'Rose Lodge' },
  { label: 'Rose Mount Garden', value: 'Rose Mount Garden' },
  { label: 'Rose Park', value: 'Rose Park' },
  { label: 'Rose Town', value: 'Rose Town' },
  { label: 'Roseau', value: 'Roseau' },
  { label: 'Roseau', value: 'Roseau' },
  { label: 'Roseau County', value: 'Roseau County' },
  { label: 'Roseau Valley', value: 'Roseau Valley' },
  { label: 'Rosebank', value: 'Rosebank' },
  { label: 'Rosebank', value: 'Rosebank' },
  { label: 'Rosebank', value: 'Rosebank' },
  { label: 'Rosebery', value: 'Rosebery' },
  { label: 'Rosebery', value: 'Rosebery' },
  { label: 'Roseboro', value: 'Roseboro' },
  { label: 'Rosebud', value: 'Rosebud' },
  { label: 'Rosebud', value: 'Rosebud' },
  { label: 'Rosebud', value: 'Rosebud' },
  { label: 'Rosebud County', value: 'Rosebud County' },
  { label: 'Rosebud West', value: 'Rosebud West' },
  { label: 'Roseburg', value: 'Roseburg' },
  { label: 'Roseburg North', value: 'Roseburg North' },
  { label: 'Rosedale', value: 'Rosedale' },
  { label: 'Rosedale', value: 'Rosedale' },
  { label: 'Rosedale', value: 'Rosedale' },
  { label: 'Rosedale', value: 'Rosedale' },
  { label: 'Rosedale', value: 'Rosedale' },
  { label: 'Rosedale', value: 'Rosedale' },
  { label: 'Rosegaferro', value: 'Rosegaferro' },
  { label: 'Rosegg', value: 'Rosegg' },
  { label: 'Rosehearty', value: 'Rosehearty' },
  { label: 'Rosehill', value: 'Rosehill' },
  { label: 'Roseira', value: 'Roseira' },
  { label: 'Roseland', value: 'Roseland' },
  { label: 'Roseland', value: 'Roseland' },
  { label: 'Roseland', value: 'Roseland' },
  { label: 'Roseland', value: 'Roseland' },
  { label: 'Roselands', value: 'Roselands' },
  { label: 'Roselawn', value: 'Roselawn' },
  { label: 'Rosell', value: 'Rosell' },
  { label: 'Roselle', value: 'Roselle' },
  { label: 'Roselle', value: 'Roselle' },
  { label: 'Roselle Park', value: 'Roselle Park' },
  { label: 'Rosello', value: 'Rosello' },
  { label: 'Rosemead', value: 'Rosemead' },
  { label: 'Rosemeadow', value: 'Rosemeadow' },
  { label: 'Rosemère', value: 'Rosemère' },
  { label: 'Rosemont', value: 'Rosemont' },
  { label: 'Rosemont', value: 'Rosemont' },
  { label: 'Rosemount', value: 'Rosemount' },
  { label: 'Rosemount', value: 'Rosemount' },
  { label: 'Rosemount', value: 'Rosemount' },
  { label: 'Rosemount', value: 'Rosemount' },
  { label: 'Rosenau', value: 'Rosenau' },
  { label: 'Rosenau Am Sonntagberg', value: 'Rosenau Am Sonntagberg' },
  { label: 'Rosenberg', value: 'Rosenberg' },
  { label: 'Rosenberg', value: 'Rosenberg' },
  { label: 'Rosendal', value: 'Rosendal' },
  { label: 'Rosendale', value: 'Rosendale' },
  { label: 'Rosendale Village', value: 'Rosendale Village' },
  { label: 'Rosenfeld', value: 'Rosenfeld' },
  { label: 'Rosenhayn', value: 'Rosenhayn' },
  { label: 'Rosenheim', value: 'Rosenheim' },
  { label: 'Rosenow', value: 'Rosenow' },
  { label: 'Rosental An Der Kainach', value: 'Rosental An Der Kainach' },
  { label: 'Rosenthal', value: 'Rosenthal' },
  { label: 'Rosenthal', value: 'Rosenthal' },
  { label: 'Rosenthal Am Rennsteig', value: 'Rosenthal Am Rennsteig' },
  { label: 'Rosenthal Heights', value: 'Rosenthal Heights' },
  { label: 'Rosepine', value: 'Rosepine' },
  { label: 'Rosersberg', value: 'Rosersberg' },
  { label: 'Roses', value: 'Roses' },
  { label: 'Roseţi', value: 'Roseţi' },
  { label: 'Roseto', value: 'Roseto' },
  { label: 'Roseto Capo Spulico', value: 'Roseto Capo Spulico' },
  { label: 'Roseto Degli Abruzzi', value: 'Roseto Degli Abruzzi' },
  { label: 'Roseto Valfortore', value: 'Roseto Valfortore' },
  { label: 'Rosetown', value: 'Rosetown' },
  { label: 'Rosetta', value: 'Rosetta' },
  { label: 'Rosetta', value: 'Rosetta' },
  { label: 'Roseville', value: 'Roseville' },
  { label: 'Roseville', value: 'Roseville' },
  { label: 'Roseville', value: 'Roseville' },
  { label: 'Roseville', value: 'Roseville' },
  { label: 'Roseville', value: 'Roseville' },
  { label: 'Roseville Chase', value: 'Roseville Chase' },
  { label: 'Rosewater', value: 'Rosewater' },
  { label: 'Rosewell', value: 'Rosewell' },
  { label: 'Rosewood', value: 'Rosewood' },
  { label: 'Rosewood Heights', value: 'Rosewood Heights' },
  { label: 'Rosh Ha‘Ayin', value: 'Rosh Ha‘Ayin' },
  { label: 'Rosh Pinna', value: 'Rosh Pinna' },
  { label: 'Roshal’', value: 'Roshal’' },
  { label: 'Rosharon', value: 'Rosharon' },
  { label: 'Roshchino', value: 'Roshchino' },
  { label: 'Roshchino', value: 'Roshchino' },
  { label: 'Roshchino', value: 'Roshchino' },
  { label: 'Rosheim', value: 'Rosheim' },
  { label: 'Roshni-Chu', value: 'Roshni-Chu' },
  { label: 'Roshtkhvar', value: 'Roshtkhvar' },
  { label: 'Rosia', value: 'Rosia' },
  { label: 'Roşia', value: 'Roşia' },
  { label: 'Roşia', value: 'Roşia' },
  { label: 'Roşia De Amaradia', value: 'Roşia De Amaradia' },
  { label: 'Roşia De Secaş', value: 'Roşia De Secaş' },
  { label: 'Roșia Montană', value: 'Roșia Montană' },
  { label: 'Rosice', value: 'Rosice' },
  { label: 'Rosice', value: 'Rosice' },
  { label: 'Rosiclare', value: 'Rosiclare' },
  { label: 'Rosières', value: 'Rosières' },
  { label: 'Rosières-Aux-Salines', value: 'Rosières-Aux-Salines' },
  { label: 'Rosières-En-Santerre', value: 'Rosières-En-Santerre' },
  { label: 'Rosières-Près-Troyes', value: 'Rosières-Près-Troyes' },
  { label: 'Roşieşti', value: 'Roşieşti' },
  { label: 'Rosignano Marittimo', value: 'Rosignano Marittimo' },
  { label: 'Rosignano Monferrato', value: 'Rosignano Monferrato' },
  { label: 'Rosignano Solvay-Castiglioncello', value: 'Rosignano Solvay-Castiglioncello' },
  { label: 'Rosignol', value: 'Rosignol' },
  { label: 'Roşiile', value: 'Roşiile' },
  { label: 'Rosinos De La Requejada', value: 'Rosinos De La Requejada' },
  { label: 'Roşiori', value: 'Roşiori' },
  { label: 'Roşiori', value: 'Roşiori' },
  { label: 'Roșiori', value: 'Roșiori' },
  { label: 'Roșiori', value: 'Roșiori' },
  { label: 'Roșiori', value: 'Roșiori' },
  { label: 'Roșiori', value: 'Roșiori' },
  { label: 'Roșiori', value: 'Roșiori' },
  { label: 'Roșiorii De Vede', value: 'Roșiorii De Vede' },
  { label: 'Rosita North', value: 'Rosita North' },
  { label: 'Rosita South', value: 'Rosita South' },
  { label: 'Rositz', value: 'Rositz' },
  { label: 'Roskilde', value: 'Roskilde' },
  { label: 'Roskilde Kommune', value: 'Roskilde Kommune' },
  { label: 'Roskoshnoye', value: 'Roskoshnoye' },
  { label: 'Roskoshnyy', value: 'Roskoshnyy' },
  { label: 'Roskovec', value: 'Roskovec' },
  { label: 'Roskow', value: 'Roskow' },
  { label: 'Röslau', value: 'Röslau' },
  { label: 'Roslavl’', value: 'Roslavl’' },
  { label: 'Roslin', value: 'Roslin' },
  { label: 'Roslyakovo', value: 'Roslyakovo' },
  { label: 'Roslyn', value: 'Roslyn' },
  { label: 'Roslyn Estates', value: 'Roslyn Estates' },
  { label: 'Roslyn Harbor', value: 'Roslyn Harbor' },
  { label: 'Roslyn Heights', value: 'Roslyn Heights' },
  { label: 'Rosneath', value: 'Rosneath' },
  { label: 'Rosny-Sous-Bois', value: 'Rosny-Sous-Bois' },
  { label: 'Rosny-Sur-Seine', value: 'Rosny-Sur-Seine' },
  { label: 'Rosolina', value: 'Rosolina' },
  { label: 'Rosolini', value: 'Rosolini' },
  { label: 'Rosoman', value: 'Rosoman' },
  { label: 'Rosora', value: 'Rosora' },
  { label: 'Rospez', value: 'Rospez' },
  { label: 'Rosporden', value: 'Rosporden' },
  { label: 'Rosport', value: 'Rosport' },
  { label: 'Rösrath', value: 'Rösrath' },
  { label: 'Ross', value: 'Ross' },
  { label: 'Ross', value: 'Ross' },
  { label: 'Ross', value: 'Ross' },
  { label: 'Ross County', value: 'Ross County' },
  { label: 'Ross Creek', value: 'Ross Creek' },
  { label: 'Ross On Wye', value: 'Ross On Wye' },
  { label: 'Ross’', value: 'Ross’' },
  { label: 'Rossa', value: 'Rossa' },
  { label: 'Rossana', value: 'Rossana' },
  { label: 'Rossano', value: 'Rossano' },
  { label: 'Rossano Stazione', value: 'Rossano Stazione' },
  { label: 'Rossano Veneto', value: 'Rossano Veneto' },
  { label: 'Rossau', value: 'Rossau' },
  { label: 'Rossbach', value: 'Rossbach' },
  { label: 'Roßdorf', value: 'Roßdorf' },
  { label: 'Rosselange', value: 'Rosselange' },
  { label: 'Rossell', value: 'Rossell' },
  { label: 'Rosselló', value: 'Rosselló' },
  { label: 'Rossendale', value: 'Rossendale' },
  { label: 'Rossett', value: 'Rossett' },
  { label: 'Rossford', value: 'Rossford' },
  { label: 'Roßhaupten', value: 'Roßhaupten' },
  { label: 'Rossi', value: 'Rossi' },
  { label: 'Rossiglione', value: 'Rossiglione' },
  { label: 'Roßla', value: 'Roßla' },
  { label: 'Rossland', value: 'Rossland' },
  { label: 'Rosslare', value: 'Rosslare' },
  { label: 'Roßlau', value: 'Roßlau' },
  { label: 'Rosslea', value: 'Rosslea' },
  { label: 'Roßleben', value: 'Roßleben' },
  { label: 'Rosslyn', value: 'Rosslyn' },
  { label: 'Rosslyn Park', value: 'Rosslyn Park' },
  { label: 'Rossmoor', value: 'Rossmoor' },
  { label: 'Rossmoor', value: 'Rossmoor' },
  { label: 'Rossmoor', value: 'Rossmoor' },
  { label: 'Rossmoyne', value: 'Rossmoyne' },
  { label: 'Rossmoyne', value: 'Rossmoyne' },
  { label: 'Rosso', value: 'Rosso' },
  { label: 'Rosso', value: 'Rosso' },
  { label: 'Rossosh’', value: 'Rossosh’' },
  { label: 'Rossoshanskiy Rayon', value: 'Rossoshanskiy Rayon' },
  { label: 'Roßtal', value: 'Roßtal' },
  { label: 'Rossum', value: 'Rossum' },
  { label: 'Rossum', value: 'Rossum' },
  { label: 'Rossville', value: 'Rossville' },
  { label: 'Rossville', value: 'Rossville' },
  { label: 'Rossville', value: 'Rossville' },
  { label: 'Rossville', value: 'Rossville' },
  { label: 'Rossville', value: 'Rossville' },
  { label: 'Rossville', value: 'Rossville' },
  { label: 'Roßwein', value: 'Roßwein' },
  { label: 'Røst', value: 'Røst' },
  { label: 'Rosta', value: 'Rosta' },
  { label: 'Rostam', value: 'Rostam' },
  { label: 'Rostam Kola', value: 'Rostam Kola' },
  { label: 'Rostamabad', value: 'Rostamabad' },
  { label: 'Rosthern', value: 'Rosthern' },
  { label: 'Rosto De Cão', value: 'Rosto De Cão' },
  { label: 'Rostock', value: 'Rostock' },
  { label: 'Rostokino', value: 'Rostokino' },
  { label: 'Rostov', value: 'Rostov' },
  { label: 'Rostovka', value: 'Rostovka' },
  { label: 'Rostov-Na-Donu', value: 'Rostov-Na-Donu' },
  { label: 'Rostovskiy Rayon', value: 'Rostovskiy Rayon' },
  { label: 'Rostrenen', value: 'Rostrenen' },
  { label: 'Rostrevor', value: 'Rostrevor' },
  { label: 'Rostrevor', value: 'Rostrevor' },
  { label: 'Rostusa', value: 'Rostusa' },
  { label: 'Roșu', value: 'Roșu' },
  { label: 'Rosult', value: 'Rosult' },
  { label: 'Rosvik', value: 'Rosvik' },
  { label: 'Roswell', value: 'Roswell' },
  { label: 'Roswell', value: 'Roswell' },
  { label: 'Rosyth', value: 'Rosyth' },
  { label: 'Röszke', value: 'Röszke' },
  { label: 'Rot', value: 'Rot' },
  { label: 'Rot Am See', value: 'Rot Am See' },
  { label: 'Rota', value: 'Rota' },
  { label: 'Rota Dimagna', value: 'Rota Dimagna' },
  { label: 'Rota Greca', value: 'Rota Greca' },
  { label: 'Rotan', value: 'Rotan' },
  { label: 'Rotava', value: 'Rotava' },
  { label: 'Roteglia', value: 'Roteglia' },
  { label: 'Roteiro', value: 'Roteiro' },
  { label: 'Rotella', value: 'Rotella' },
  { label: 'Rotello', value: 'Rotello' },
  { label: 'Rotenburg', value: 'Rotenburg' },
  { label: 'Rotenburg An Der Fulda', value: 'Rotenburg An Der Fulda' },
  { label: 'Roteni', value: 'Roteni' },
  { label: 'Rotenturm An Der Pinka', value: 'Rotenturm An Der Pinka' },
  { label: 'Rötgesbüttel', value: 'Rötgesbüttel' },
  { label: 'Rotglà I Corberà', value: 'Rotglà I Corberà' },
  { label: 'Rotglá Y Corbera', value: 'Rotglá Y Corbera' },
  { label: 'Roth', value: 'Roth' },
  { label: 'Roth', value: 'Roth' },
  { label: 'Rötha', value: 'Rötha' },
  { label: 'Rothau', value: 'Rothau' },
  { label: 'Rothbury', value: 'Rothbury' },
  { label: 'Röthelstein', value: 'Röthelstein' },
  { label: 'Rothem', value: 'Rothem' },
  { label: 'Röthenbach', value: 'Röthenbach' },
  { label: 'Röthenbach An Der Pegnitz', value: 'Röthenbach An Der Pegnitz' },
  { label: 'Rothenberg', value: 'Rothenberg' },
  { label: 'Rothenbuch', value: 'Rothenbuch' },
  { label: 'Rothenburg', value: 'Rothenburg' },
  { label: 'Rothenburg Ob Der Tauber', value: 'Rothenburg Ob Der Tauber' },
  { label: 'Rothenburgsort', value: 'Rothenburgsort' },
  { label: 'Rothenfels', value: 'Rothenfels' },
  { label: 'Rothenstein', value: 'Rothenstein' },
  { label: 'Rothenthurm', value: 'Rothenthurm' },
  { label: 'Rotherfield Peppard', value: 'Rotherfield Peppard' },
  { label: 'Rotherham', value: 'Rotherham' },
  { label: 'Rothes', value: 'Rothes' },
  { label: 'Rothesay', value: 'Rothesay' },
  { label: 'Rothesay Bay', value: 'Rothesay Bay' },
  { label: 'Rothienorman', value: 'Rothienorman' },
  { label: 'Röthis', value: 'Röthis' },
  { label: 'Röthlein', value: 'Röthlein' },
  { label: 'Rothley', value: 'Rothley' },
  { label: 'Rothrist', value: 'Rothrist' },
  { label: 'Rothschild', value: 'Rothschild' },
  { label: 'Rothsville', value: 'Rothsville' },
  { label: 'Rothwell', value: 'Rothwell' },
  { label: 'Rothwell', value: 'Rothwell' },
  { label: 'Rotifunk', value: 'Rotifunk' },
  { label: 'Rotkreuz', value: 'Rotkreuz' },
  { label: 'Rotmanka', value: 'Rotmanka' },
  { label: 'Rotnes', value: 'Rotnes' },
  { label: 'Rotonda', value: 'Rotonda' },
  { label: 'Rotonda', value: 'Rotonda' },
  { label: 'Rotonda West', value: 'Rotonda West' },
  { label: 'Rotondella', value: 'Rotondella' },
  { label: 'Rotondi', value: 'Rotondi' },
  { label: 'Rotorua', value: 'Rotorua' },
  { label: 'Rótova', value: 'Rótova' },
  { label: 'Rots', value: 'Rots' },
  { label: 'Rotselaar', value: 'Rotselaar' },
  { label: 'Rott', value: 'Rott' },
  { label: 'Rotta', value: 'Rotta' },
  { label: 'Rottach-Egern', value: 'Rottach-Egern' },
  { label: 'Rottanova', value: 'Rottanova' },
  { label: 'Rottenacker', value: 'Rottenacker' },
  { label: 'Rottenbach', value: 'Rottenbach' },
  { label: 'Röttenbach', value: 'Röttenbach' },
  { label: 'Rottenbuch', value: 'Rottenbuch' },
  { label: 'Rottenburg', value: 'Rottenburg' },
  { label: 'Rottenburg An Der Laaber', value: 'Rottenburg An Der Laaber' },
  { label: 'Rottendorf', value: 'Rottendorf' },
  { label: 'Rottenmann', value: 'Rottenmann' },
  { label: 'Rotterdam', value: 'Rotterdam' },
  { label: 'Rotterdam', value: 'Rotterdam' },
  { label: 'Rottevalle', value: 'Rottevalle' },
  { label: 'Rotthalmünster', value: 'Rotthalmünster' },
  { label: 'Rottingdean', value: 'Rottingdean' },
  { label: 'Röttingen', value: 'Röttingen' },
  { label: 'Rottleberode', value: 'Rottleberode' },
  { label: 'Rottne', value: 'Rottne' },
  { label: 'Rottofreno', value: 'Rottofreno' },
  { label: 'Rottweil', value: 'Rottweil' },
  { label: 'Rotunda', value: 'Rotunda' },
  { label: 'Rotunda', value: 'Rotunda' },
  { label: 'Rotunda', value: 'Rotunda' },
  { label: 'Rotunda', value: 'Rotunda' },
  { label: 'Roturas', value: 'Roturas' },
  { label: 'Rötz', value: 'Rötz' },
  { label: 'Rotzo', value: 'Rotzo' },
  { label: 'Roua', value: 'Roua' },
  { label: 'Rouached', value: 'Rouached' },
  { label: 'Rouans', value: 'Rouans' },
  { label: 'Roubaix', value: 'Roubaix' },
  { label: 'Rouchovany', value: 'Rouchovany' },
  { label: 'Roudnice Nad Labem', value: 'Roudnice Nad Labem' },
  { label: 'Rouen', value: 'Rouen' },
  { label: 'Rouet', value: 'Rouet' },
  { label: 'Rouffach', value: 'Rouffach' },
  { label: 'Rouffiac-Tolosan', value: 'Rouffiac-Tolosan' },
  { label: 'Rouffignac-Saint-Cernin-De-Reilhac', value: 'Rouffignac-Saint-Cernin-De-Reilhac' },
  { label: 'Rougé', value: 'Rougé' },
  { label: 'Rõuge', value: 'Rõuge' },
  { label: 'Rõuge Vald', value: 'Rõuge Vald' },
  { label: 'Rougemont', value: 'Rougemont' },
  { label: 'Rougemont', value: 'Rougemont' },
  { label: 'Rougemont-Le-Château', value: 'Rougemont-Le-Château' },
  { label: 'Rougiers', value: 'Rougiers' },
  { label: 'Rouhling', value: 'Rouhling' },
  { label: 'Rouiba', value: 'Rouiba' },
  { label: 'Rouillac', value: 'Rouillac' },
  { label: 'Rouillé', value: 'Rouillé' },
  { label: 'Rouillon', value: 'Rouillon' },
  { label: 'Rouissat', value: 'Rouissat' },
  { label: 'Rouissat', value: 'Rouissat' },
  { label: 'Roujan', value: 'Roujan' },
  { label: 'Roulans', value: 'Roulans' },
  { label: 'Roullet-Saint-Estèphe', value: 'Roullet-Saint-Estèphe' },
  { label: 'Roumare', value: 'Roumare' },
  { label: 'Roumazières-Loubert', value: 'Roumazières-Loubert' },
  { label: 'Round Lake', value: 'Round Lake' },
  { label: 'Round Lake Beach', value: 'Round Lake Beach' },
  { label: 'Round Lake Heights', value: 'Round Lake Heights' },
  { label: 'Round Lake Park', value: 'Round Lake Park' },
  { label: 'Round Rock', value: 'Round Rock' },
  { label: 'Roundup', value: 'Roundup' },
  { label: 'Roure', value: 'Roure' },
  { label: 'Rourell', value: 'Rourell' },
  { label: 'Rouse Hill', value: 'Rouse Hill' },
  { label: 'Rouses Point', value: 'Rouses Point' },
  { label: 'Rousies', value: 'Rousies' },
  { label: 'Rousínov', value: 'Rousínov' },
  { label: 'Roussay', value: 'Roussay' },
  { label: 'Rousset', value: 'Rousset' },
  { label: 'Roussillon', value: 'Roussillon' },
  { label: 'Roussillon', value: 'Roussillon' },
  { label: 'Rousson', value: 'Rousson' },
  { label: 'Routot', value: 'Routot' },
  { label: 'Routt County', value: 'Routt County' },
  { label: 'Rouvroy', value: 'Rouvroy' },
  { label: 'Rouvroy', value: 'Rouvroy' },
  { label: 'Rouxmesnil-Bouteilles', value: 'Rouxmesnil-Bouteilles' },
  { label: 'Rouyn-Noranda', value: 'Rouyn-Noranda' },
  { label: 'Rouziers-De-Touraine', value: 'Rouziers-De-Touraine' },
  { label: 'Rovagnate', value: 'Rovagnate' },
  { label: 'Rovaniemi', value: 'Rovaniemi' },
  { label: 'Rovasenda', value: 'Rovasenda' },
  { label: 'Rovato', value: 'Rovato' },
  { label: 'Rovegno', value: 'Rovegno' },
  { label: 'Roveleto', value: 'Roveleto' },
  { label: 'Rovellasca', value: 'Rovellasca' },
  { label: 'Rovello Porro', value: 'Rovello Porro' },
  { label: 'Roven’Ky', value: 'Roven’Ky' },
  { label: 'Roven’Skiy Rayon', value: 'Roven’Skiy Rayon' },
  { label: 'Rovensko Pod Troskami', value: 'Rovensko Pod Troskami' },
  { label: 'Roverbella', value: 'Roverbella' },
  { label: 'Roverchiara', value: 'Roverchiara' },
  { label: 'Roverè Della Luna', value: 'Roverè Della Luna' },
  { label: 'Roverè Veronese', value: 'Roverè Veronese' },
  { label: 'Roveredo Di Guà', value: 'Roveredo Di Guà' },
  { label: 'Roveredo In Piano', value: 'Roveredo In Piano' },
  { label: 'Rovereto', value: 'Rovereto' },
  { label: 'Rovereto', value: 'Rovereto' },
  { label: 'Rövershagen', value: 'Rövershagen' },
  { label: 'Rovescala', value: 'Rovescala' },
  { label: 'Rovetta', value: 'Rovetta' },
  { label: 'Roviano', value: 'Roviano' },
  { label: 'Roviés', value: 'Roviés' },
  { label: 'Rovigo', value: 'Rovigo' },
  { label: 'Rovinari', value: 'Rovinari' },
  { label: 'Rovine', value: 'Rovine' },
  { label: 'Rovinj', value: 'Rovinj' },
  { label: 'Rovinj-Rovigno', value: 'Rovinj-Rovigno' },
  { label: 'Rovira', value: 'Rovira' },
  { label: 'Rovira Arriba', value: 'Rovira Arriba' },
  { label: 'Rovišće', value: 'Rovišće' },
  { label: 'Rovito', value: 'Rovito' },
  { label: 'Rovnoye', value: 'Rovnoye' },
  { label: 'Rovnoye', value: 'Rovnoye' },
  { label: 'Rowan County', value: 'Rowan County' },
  { label: 'Rowan County', value: 'Rowan County' },
  { label: 'Rowde', value: 'Rowde' },
  { label: 'Rowhedge', value: 'Rowhedge' },
  { label: 'Rowland', value: 'Rowland' },
  { label: 'Rowland Heights', value: 'Rowland Heights' },
  { label: 'Rowlands Gill', value: 'Rowlands Gill' },
  { label: 'Rowlandsfield', value: 'Rowlandsfield' },
  { label: 'Rowlett', value: 'Rowlett' },
  { label: 'Rowley', value: 'Rowley' },
  { label: 'Rowley Regis', value: 'Rowley Regis' },
  { label: 'Rowville', value: 'Rowville' },
  { label: 'Roxana', value: 'Roxana' },
  { label: 'Roxana', value: 'Roxana' },
  { label: 'Roxas', value: 'Roxas' },
  { label: 'Roxas', value: 'Roxas' },
  { label: 'Roxas', value: 'Roxas' },
  { label: 'Roxas City', value: 'Roxas City' },
  { label: 'Roxboro', value: 'Roxboro' },
  { label: 'Roxborough Park', value: 'Roxborough Park' },
  { label: 'Roxburgh Park', value: 'Roxburgh Park' },
  { label: 'Roxbury', value: 'Roxbury' },
  { label: 'Roxby Downs', value: 'Roxby Downs' },
  { label: 'Roxheim', value: 'Roxheim' },
  { label: 'Roxton', value: 'Roxton' },
  { label: 'Roy', value: 'Roy' },
  { label: 'Royal Borough Of Windsor And Maidenhead', value: 'Royal Borough Of Windsor And Maidenhead' },
  { label: 'Royal City', value: 'Royal City' },
  { label: 'Royal Flat', value: 'Royal Flat' },
  { label: 'Royal Kunia', value: 'Royal Kunia' },
  { label: 'Royal Leamington Spa', value: 'Royal Leamington Spa' },
  { label: 'Royal Oak', value: 'Royal Oak' },
  { label: 'Royal Palm Beach', value: 'Royal Palm Beach' },
  { label: 'Royal Palm Estates', value: 'Royal Palm Estates' },
  { label: 'Royal Park', value: 'Royal Park' },
  { label: 'Royal Pines', value: 'Royal Pines' },
  { label: 'Royal Tunbridge Wells', value: 'Royal Tunbridge Wells' },
  { label: 'Royal Wootton Bassett', value: 'Royal Wootton Bassett' },
  { label: 'Royalston', value: 'Royalston' },
  { label: 'Royalton', value: 'Royalton' },
  { label: 'Royalton', value: 'Royalton' },
  { label: 'Royalton', value: 'Royalton' },
  { label: 'Royan', value: 'Royan' },
  { label: 'Royan', value: 'Royan' },
  { label: 'Royat', value: 'Royat' },
  { label: 'Roybon', value: 'Roybon' },
  { label: 'Roydon', value: 'Roydon' },
  { label: 'Roye', value: 'Roye' },
  { label: 'Roye', value: 'Roye' },
  { label: 'Royersford', value: 'Royersford' },
  { label: 'Royítika', value: 'Royítika' },
  { label: 'Røyken', value: 'Røyken' },
  { label: 'Royo El', value: 'Royo El' },
  { label: 'Røyrvik', value: 'Røyrvik' },
  { label: 'Royse City', value: 'Royse City' },
  { label: 'Royston', value: 'Royston' },
  { label: 'Royston', value: 'Royston' },
  { label: 'Royston', value: 'Royston' },
  { label: 'Royston Park', value: 'Royston Park' },
  { label: 'Royton', value: 'Royton' },
  { label: 'Royuela', value: 'Royuela' },
  { label: 'Royuela De Río Franco', value: 'Royuela De Río Franco' },
  { label: 'Roza', value: 'Roza' },
  { label: 'Rožaje', value: 'Rožaje' },
  { label: 'Rozalén Del Monte', value: 'Rozalén Del Monte' },
  { label: 'Różan', value: 'Różan' },
  { label: 'Różanka-Polanka', value: 'Różanka-Polanka' },
  { label: 'Rozas De Madrid Las', value: 'Rozas De Madrid Las' },
  { label: 'Rozas De Puerto Real', value: 'Rozas De Puerto Real' },
  { label: 'Rozas De Valdearroyo Las', value: 'Rozas De Valdearroyo Las' },
  { label: 'Rozavlea', value: 'Rozavlea' },
  { label: 'Rozay-En-Brie', value: 'Rozay-En-Brie' },
  { label: 'Rozbórz', value: 'Rozbórz' },
  { label: 'Rožďalovice', value: 'Rožďalovice' },
  { label: 'Rozdil', value: 'Rozdil' },
  { label: 'Rozdil’Na', value: 'Rozdil’Na' },
  { label: 'Rozdrażew', value: 'Rozdrażew' },
  { label: 'Rozelle', value: 'Rozelle' },
  { label: 'Rozenburg', value: 'Rozenburg' },
  { label: 'Rozendaal', value: 'Rozendaal' },
  { label: 'Rozérieulles', value: 'Rozérieulles' },
  { label: 'Rozhdestveno', value: 'Rozhdestveno' },
  { label: 'Rozhdestveno', value: 'Rozhdestveno' },
  { label: 'Rozhdestveno', value: 'Rozhdestveno' },
  { label: 'Rozhdestvenskiy', value: 'Rozhdestvenskiy' },
  { label: 'Rozhniativ', value: 'Rozhniativ' },
  { label: 'Rozhniv', value: 'Rozhniv' },
  { label: 'Rozhnyativs’Kyy Rayon', value: 'Rozhnyativs’Kyy Rayon' },
  { label: 'Rozhyshche', value: 'Rozhyshche' },
  { label: 'Rozier-En-Donzy', value: 'Rozier-En-Donzy' },
  { label: 'Rozivka', value: 'Rozivka' },
  { label: 'Rozivs’Kyy Rayon', value: 'Rozivs’Kyy Rayon' },
  { label: 'Rozkishne', value: 'Rozkishne' },
  { label: 'Roz-Landrieux', value: 'Roz-Landrieux' },
  { label: 'Rožmitál Pod Třemšínem', value: 'Rožmitál Pod Třemšínem' },
  { label: 'Rožna Dolina', value: 'Rožna Dolina' },
  { label: 'Rožňava', value: 'Rožňava' },
  { label: 'Rožnik District', value: 'Rožnik District' },
  { label: 'Roznov', value: 'Roznov' },
  { label: 'Rožnov Pod Radhoštěm', value: 'Rožnov Pod Radhoštěm' },
  { label: 'Rozogi', value: 'Rozogi' },
  { label: 'Rozoy-Sur-Serre', value: 'Rozoy-Sur-Serre' },
  { label: 'Rozprza', value: 'Rozprza' },
  { label: 'Rózsaszentmárton', value: 'Rózsaszentmárton' },
  { label: 'Roz-Sur-Couesnon', value: 'Roz-Sur-Couesnon' },
  { label: 'Rozsypne', value: 'Rozsypne' },
  { label: 'Roztoky', value: 'Roztoky' },
  { label: 'Rozvadiv', value: 'Rozvadiv' },
  { label: 'Rozwadza', value: 'Rozwadza' },
  { label: 'Rozzano', value: 'Rozzano' },
  { label: 'Rrëshen', value: 'Rrëshen' },
  { label: 'Rrethi I Beratit', value: 'Rrethi I Beratit' },
  { label: 'Rrethi I Bulqizës', value: 'Rrethi I Bulqizës' },
  { label: 'Rrethi I Delvinës', value: 'Rrethi I Delvinës' },
  { label: 'Rrethi I Devollit', value: 'Rrethi I Devollit' },
  { label: 'Rrethi I Dibrës', value: 'Rrethi I Dibrës' },
  { label: 'Rrethi I Hasit', value: 'Rrethi I Hasit' },
  { label: 'Rrethi I Kavajës', value: 'Rrethi I Kavajës' },
  { label: 'Rrethi I Kolonjës', value: 'Rrethi I Kolonjës' },
  { label: 'Rrethi I Krujës', value: 'Rrethi I Krujës' },
  { label: 'Rrethi I Kuçovës', value: 'Rrethi I Kuçovës' },
  { label: 'Rrethi I Kukësit', value: 'Rrethi I Kukësit' },
  { label: 'Rrethi I Kurbinit', value: 'Rrethi I Kurbinit' },
  { label: 'Rrethi I Malësia E Madhe', value: 'Rrethi I Malësia E Madhe' },
  { label: 'Rrethi I Mallakastrës', value: 'Rrethi I Mallakastrës' },
  { label: 'Rrethi I Matit', value: 'Rrethi I Matit' },
  { label: 'Rrethi I Shkodrës', value: 'Rrethi I Shkodrës' },
  { label: 'Rrethi I Skraparit', value: 'Rrethi I Skraparit' },
  { label: 'Rrethi I Tiranës', value: 'Rrethi I Tiranës' },
  { label: 'Rrind', value: 'Rrind' },
  { label: 'Rrogozhinë', value: 'Rrogozhinë' },
  { label: 'Rtishchevo', value: 'Rtishchevo' },
  { label: 'Rtyně V Podkrkonoší', value: 'Rtyně V Podkrkonoší' },
  { label: 'Rúa A', value: 'Rúa A' },
  { label: 'Ruabon', value: 'Ruabon' },
  { label: 'Ruakaka', value: 'Ruakaka' },
  { label: 'Ruanes', value: 'Ruanes' },
  { label: 'Ruangwa', value: 'Ruangwa' },
  { label: 'Ruardean', value: 'Ruardean' },
  { label: 'Ruaudin', value: 'Ruaudin' },
  { label: 'Rubano', value: 'Rubano' },
  { label: 'Rubbestadneset', value: 'Rubbestadneset' },
  { label: 'Rübeland', value: 'Rübeland' },
  { label: 'Rubelita', value: 'Rubelita' },
  { label: 'Rubelles', value: 'Rubelles' },
  { label: 'Rubén Carlos Gallegos Reyes Villaldama', value: 'Rubén Carlos Gallegos Reyes Villaldama' },
  { label: 'Rubena', value: 'Rubena' },
  { label: 'Rubeši', value: 'Rubeši' },
  { label: 'Rubí', value: 'Rubí' },
  { label: 'Rubí De Bracamonte', value: 'Rubí De Bracamonte' },
  { label: 'Rubiá', value: 'Rubiá' },
  { label: 'Rubiácea', value: 'Rubiácea' },
  { label: 'Rubiales', value: 'Rubiales' },
  { label: 'Rubiana', value: 'Rubiana' },
  { label: 'Rubiataba', value: 'Rubiataba' },
  { label: 'Rubidoux', value: 'Rubidoux' },
  { label: 'Rubielos De La Cérida', value: 'Rubielos De La Cérida' },
  { label: 'Rubielos De Mora', value: 'Rubielos De Mora' },
  { label: 'Rubiera', value: 'Rubiera' },
  { label: 'Rubigen', value: 'Rubigen' },
  { label: 'Rubik', value: 'Rubik' },
  { label: 'Rubim', value: 'Rubim' },
  { label: 'Rubinéia', value: 'Rubinéia' },
  { label: 'Rubio', value: 'Rubio' },
  { label: 'Rubió', value: 'Rubió' },
  { label: 'Rubio El', value: 'Rubio El' },
  { label: 'Rubite', value: 'Rubite' },
  { label: 'Rubizhans’Ka Mis’Krada', value: 'Rubizhans’Ka Mis’Krada' },
  { label: 'Rubizhne', value: 'Rubizhne' },
  { label: 'Rubla', value: 'Rubla' },
  { label: 'Rublacedo De Abajo', value: 'Rublacedo De Abajo' },
  { label: 'Rublëvo', value: 'Rublëvo' },
  { label: 'Rubtsovsk', value: 'Rubtsovsk' },
  { label: 'Rubtsovskiy Rayon', value: 'Rubtsovskiy Rayon' },
  { label: 'Ruby Estate', value: 'Ruby Estate' },
  { label: 'Rubyozhny', value: 'Rubyozhny' },
  { label: 'Rucandio', value: 'Rucandio' },
  { label: 'Rucăr', value: 'Rucăr' },
  { label: 'Rucăreni', value: 'Rucăreni' },
  { label: 'Rucava', value: 'Rucava' },
  { label: 'Ruch’I', value: 'Ruch’I' },
  { label: 'Rucheng', value: 'Rucheng' },
  { label: 'Ruciane-Nida', value: 'Ruciane-Nida' },
  { label: 'Rückersdorf', value: 'Rückersdorf' },
  { label: 'Rückersdorf', value: 'Rückersdorf' },
  { label: 'Ruckersville', value: 'Ruckersville' },
  { label: 'Rucphen', value: 'Rucphen' },
  { label: 'Rud Avar', value: 'Rud Avar' },
  { label: 'Ruda', value: 'Ruda' },
  { label: 'Ruda Maleniecka', value: 'Ruda Maleniecka' },
  { label: 'Ruda Nad Moravou', value: 'Ruda Nad Moravou' },
  { label: 'Ruda Śląska', value: 'Ruda Śląska' },
  { label: 'Rudab', value: 'Rudab' },
  { label: 'Rudabánya', value: 'Rudabánya' },
  { label: 'Ruda-Huta', value: 'Ruda-Huta' },
  { label: 'Rudan', value: 'Rudan' },
  { label: 'Rudarpur', value: 'Rudarpur' },
  { label: 'Rudauli', value: 'Rudauli' },
  { label: 'Rudawa', value: 'Rudawa' },
  { label: 'Rudbar', value: 'Rudbar' },
  { label: 'Rudbar', value: 'Rudbar' },
  { label: 'Rūdbār', value: 'Rūdbār' },
  { label: 'Ruddington', value: 'Ruddington' },
  { label: 'Rude', value: 'Rude' },
  { label: 'Rud-E Hasan-E Sofla', value: 'Rud-E Hasan-E Sofla' },
  { label: 'Rudehen', value: 'Rudehen' },
  { label: 'Rudelzhausen', value: 'Rudelzhausen' },
  { label: 'Ruden', value: 'Ruden' },
  { label: 'Rudeni', value: 'Rudeni' },
  { label: 'Ruderatshofen', value: 'Ruderatshofen' },
  { label: 'Rudersberg', value: 'Rudersberg' },
  { label: 'Rudersdal Kommune', value: 'Rudersdal Kommune' },
  { label: 'Rudersdorf', value: 'Rudersdorf' },
  { label: 'Rüderswil', value: 'Rüderswil' },
  { label: 'Ruderting', value: 'Ruderting' },
  { label: 'Rüdesheim', value: 'Rüdesheim' },
  { label: 'Rüdesheim Am Rhein', value: 'Rüdesheim Am Rhein' },
  { label: 'Rudgwick', value: 'Rudgwick' },
  { label: 'Rudiano', value: 'Rudiano' },
  { label: 'Rūdiškės', value: 'Rūdiškės' },
  { label: 'Rudka', value: 'Rudka' },
  { label: 'Rudkøbing', value: 'Rudkøbing' },
  { label: 'Rudky', value: 'Rudky' },
  { label: 'Rudna', value: 'Rudna' },
  { label: 'Rudná', value: 'Rudná' },
  { label: 'Rudna Mała', value: 'Rudna Mała' },
  { label: 'Rudna Wielka', value: 'Rudna Wielka' },
  { label: 'Rudnaya Pristan’', value: 'Rudnaya Pristan’' },
  { label: 'Rudne', value: 'Rudne' },
  { label: 'Rudnichnyy', value: 'Rudnichnyy' },
  { label: 'Rudnichnyy', value: 'Rudnichnyy' },
  { label: 'Rudnichnyy', value: 'Rudnichnyy' },
  { label: 'Rudnik', value: 'Rudnik' },
  { label: 'Rudnik', value: 'Rudnik' },
  { label: 'Rudnik', value: 'Rudnik' },
  { label: 'Rudník', value: 'Rudník' },
  { label: 'Rudnik Nad Sanem', value: 'Rudnik Nad Sanem' },
  { label: 'Rudniki', value: 'Rudniki' },
  { label: 'Rüdnitz', value: 'Rüdnitz' },
  { label: 'Rudnogorsk', value: 'Rudnogorsk' },
  { label: 'Rudnya', value: 'Rudnya' },
  { label: 'Rudnya Volgograd Oblast', value: 'Rudnya Volgograd Oblast' },
  { label: 'Rudnyy', value: 'Rudnyy' },
  { label: 'Rudnyy', value: 'Rudnyy' },
  { label: 'Rudo', value: 'Rudo' },
  { label: 'Rudolfov', value: 'Rudolfov' },
  { label: 'Rudolfstetten', value: 'Rudolfstetten' },
  { label: 'Rudolstadt', value: 'Rudolstadt' },
  { label: 'Rudow', value: 'Rudow' },
  { label: 'Rudozem', value: 'Rudozem' },
  { label: 'Rudraprayag', value: 'Rudraprayag' },
  { label: 'Rudsar', value: 'Rudsar' },
  { label: 'Rudum', value: 'Rudum' },
  { label: 'Rudy', value: 'Rudy' },
  { label: 'Rudziczka', value: 'Rudziczka' },
  { label: 'Rudziniec', value: 'Rudziniec' },
  { label: 'Rudzyensk', value: 'Rudzyensk' },
  { label: 'Rue', value: 'Rue' },
  { label: 'Rue', value: 'Rue' },
  { label: 'Rueda', value: 'Rueda' },
  { label: 'Rueda De Jalón', value: 'Rueda De Jalón' },
  { label: 'Rueda De La Sierra', value: 'Rueda De La Sierra' },
  { label: 'Rüeggisberg', value: 'Rüeggisberg' },
  { label: 'Rueglio', value: 'Rueglio' },
  { label: 'Rueil-Malmaison', value: 'Rueil-Malmaison' },
  { label: 'Ruelisheim', value: 'Ruelisheim' },
  { label: 'Ruelle-Sur-Touvre', value: 'Ruelle-Sur-Touvre' },
  { label: 'Ruen', value: 'Ruen' },
  { label: 'Ruente', value: 'Ruente' },
  { label: 'Ruesca', value: 'Ruesca' },
  { label: 'Ruesga', value: 'Ruesga' },
  { label: 'Ruffano', value: 'Ruffano' },
  { label: 'Ruffec', value: 'Ruffec' },
  { label: 'Ruffey-Lès-Echirey', value: 'Ruffey-Lès-Echirey' },
  { label: 'Ruffia', value: 'Ruffia' },
  { label: 'Ruffiac', value: 'Ruffiac' },
  { label: 'Rufford', value: 'Rufford' },
  { label: 'Ruffrè', value: 'Ruffrè' },
  { label: 'Rufina', value: 'Rufina' },
  { label: 'Rufino', value: 'Rufino' },
  { label: 'Rufisque Department', value: 'Rufisque Department' },
  { label: 'Rufling', value: 'Rufling' },
  { label: 'Rugāji', value: 'Rugāji' },
  { label: 'Rugășești', value: 'Rugășești' },
  { label: 'Rugat', value: 'Rugat' },
  { label: 'Rugby', value: 'Rugby' },
  { label: 'Rugby', value: 'Rugby' },
  { label: 'Rugeley', value: 'Rugeley' },
  { label: 'Rugendorf', value: 'Rugendorf' },
  { label: 'Rugge', value: 'Rugge' },
  { label: 'Ruggell', value: 'Ruggell' },
  { label: 'Rugineşti', value: 'Rugineşti' },
  { label: 'Ruginoasa', value: 'Ruginoasa' },
  { label: 'Ruginoasa', value: 'Ruginoasa' },
  { label: 'Rügland', value: 'Rügland' },
  { label: 'Rugles', value: 'Rugles' },
  { label: 'Rühen', value: 'Rühen' },
  { label: 'Ruhla', value: 'Ruhla' },
  { label: 'Ruhland', value: 'Ruhland' },
  { label: 'Ruhmannsfelden', value: 'Ruhmannsfelden' },
  { label: 'Ruhnu', value: 'Ruhnu' },
  { label: 'Ruhnu Vald', value: 'Ruhnu Vald' },
  { label: 'Ruhpolding', value: 'Ruhpolding' },
  { label: 'Ruhstorf', value: 'Ruhstorf' },
  { label: 'Ruhwinkel', value: 'Ruhwinkel' },
  { label: 'Ruidera', value: 'Ruidera' },
  { label: 'Ruidoso', value: 'Ruidoso' },
  { label: 'Ruidoso Downs', value: 'Ruidoso Downs' },
  { label: 'Ruigoord', value: 'Ruigoord' },
  { label: 'Ruillé-Sur-Loir', value: 'Ruillé-Sur-Loir' },
  { label: 'Ruiloba', value: 'Ruiloba' },
  { label: 'Ruinas', value: 'Ruinas' },
  { label: 'Ruinen', value: 'Ruinen' },
  { label: 'Ruinerwold', value: 'Ruinerwold' },
  { label: 'Ruino', value: 'Ruino' },
  { label: 'Ruiselede', value: 'Ruiselede' },
  { label: 'Ruislip', value: 'Ruislip' },
  { label: 'Ruitz', value: 'Ruitz' },
  { label: 'Ruíz', value: 'Ruíz' },
  { label: 'Ruiz De Montoya', value: 'Ruiz De Montoya' },
  { label: 'Ruja', value: 'Ruja' },
  { label: 'Rūjiena', value: 'Rūjiena' },
  { label: 'Rukban', value: 'Rukban' },
  { label: 'Rukungiri', value: 'Rukungiri' },
  { label: 'Rulenge', value: 'Rulenge' },
  { label: 'Ruleville', value: 'Ruleville' },
  { label: 'Rull Municipality', value: 'Rull Municipality' },
  { label: 'Rullstorf', value: 'Rullstorf' },
  { label: 'Rully', value: 'Rully' },
  { label: 'Rülzheim', value: 'Rülzheim' },
  { label: 'Rum', value: 'Rum' },
  { label: 'Ruma', value: 'Ruma' },
  { label: 'Ruma', value: 'Ruma' },
  { label: 'Rumah', value: 'Rumah' },
  { label: 'Rumat Heib', value: 'Rumat Heib' },
  { label: 'Rumboci', value: 'Rumboci' },
  { label: 'Rumburk', value: 'Rumburk' },
  { label: 'Rumegies', value: 'Rumegies' },
  { label: 'Rumelange', value: 'Rumelange' },
  { label: 'Rumenka', value: 'Rumenka' },
  { label: 'Rumersheim-Le-Haut', value: 'Rumersheim-Le-Haut' },
  { label: 'Rumes', value: 'Rumes' },
  { label: 'Rumeshkhan County', value: 'Rumeshkhan County' },
  { label: 'Rumford', value: 'Rumford' },
  { label: 'Rumia', value: 'Rumia' },
  { label: 'Rumilly', value: 'Rumilly' },
  { label: 'Rumilly-En-Cambrésis', value: 'Rumilly-En-Cambrésis' },
  { label: 'Ruminghem', value: 'Ruminghem' },
  { label: 'Rümlang', value: 'Rümlang' },
  { label: 'Rümlang / Rümlang (Dorfkern)', value: 'Rümlang / Rümlang (Dorfkern)' },
  { label: 'Rummelsburg', value: 'Rummelsburg' },
  { label: 'Rümmelsheim', value: 'Rümmelsheim' },
  { label: 'Rümmingen', value: 'Rümmingen' },
  { label: 'Rummu', value: 'Rummu' },
  { label: 'Rumney', value: 'Rumney' },
  { label: 'Rumo', value: 'Rumo' },
  { label: 'Rumoi', value: 'Rumoi' },
  { label: 'Rumoi-Shi', value: 'Rumoi-Shi' },
  { label: 'Rumonge', value: 'Rumonge' },
  { label: 'Rumorosa', value: 'Rumorosa' },
  { label: 'Rümpel', value: 'Rümpel' },
  { label: 'Rumphi', value: 'Rumphi' },
  { label: 'Rumphi District', value: 'Rumphi District' },
  { label: 'Rumska', value: 'Rumska' },
  { label: 'Rumson', value: 'Rumson' },
  { label: 'Rumst', value: 'Rumst' },
  { label: 'Rumung Municipality', value: 'Rumung Municipality' },
  { label: 'Rumuokoro', value: 'Rumuokoro' },
  { label: 'Rumuruti', value: 'Rumuruti' },
  { label: 'Rumyantsevo', value: 'Rumyantsevo' },
  { label: 'Runa', value: 'Runa' },
  { label: 'Runavík', value: 'Runavík' },
  { label: 'Runaway Bay', value: 'Runaway Bay' },
  { label: 'Runaway Bay', value: 'Runaway Bay' },
  { label: 'Runaway Bay', value: 'Runaway Bay' },
  { label: 'Runcorn', value: 'Runcorn' },
  { label: 'Runcorn', value: 'Runcorn' },
  { label: 'Runcu', value: 'Runcu' },
  { label: 'Runcu', value: 'Runcu' },
  { label: 'Runcu', value: 'Runcu' },
  { label: 'Runcu Salvei', value: 'Runcu Salvei' },
  { label: 'Runding', value: 'Runding' },
  { label: 'Rundu', value: 'Rundu' },
  { label: 'Runge', value: 'Runge' },
  { label: 'Rungis', value: 'Rungis' },
  { label: 'Rungzyung', value: 'Rungzyung' },
  { label: 'Runing', value: 'Runing' },
  { label: 'Runka', value: 'Runka' },
  { label: 'Runkel', value: 'Runkel' },
  { label: 'Runnels County', value: 'Runnels County' },
  { label: 'Runnemede', value: 'Runnemede' },
  { label: 'Running Springs', value: 'Running Springs' },
  { label: 'Runović', value: 'Runović' },
  { label: 'Runovići', value: 'Runovići' },
  { label: 'Runsong', value: 'Runsong' },
  { label: 'Ruo Municipality', value: 'Ruo Municipality' },
  { label: 'Ruokolahti', value: 'Ruokolahti' },
  { label: 'Ruoms', value: 'Ruoms' },
  { label: 'Ruoshui', value: 'Ruoshui' },
  { label: 'Ruoti', value: 'Ruoti' },
  { label: 'Ruotsinpyhtää', value: 'Ruotsinpyhtää' },
  { label: 'Ruovesi', value: 'Ruovesi' },
  { label: 'Rupandehi ', value: 'Rupandehi ' },
  { label: 'Rupea', value: 'Rupea' },
  { label: 'Rupert', value: 'Rupert' },
  { label: 'Rupià', value: 'Rupià' },
  { label: 'Rupit I Pruit', value: 'Rupit I Pruit' },
  { label: 'Rupnagar', value: 'Rupnagar' },
  { label: 'Ruppach-Goldhausen', value: 'Ruppach-Goldhausen' },
  { label: 'Rupperswil', value: 'Rupperswil' },
  { label: 'Ruppertsberg', value: 'Ruppertsberg' },
  { label: 'Ruppertshofen', value: 'Ruppertshofen' },
  { label: 'Ruppertsweiler', value: 'Ruppertsweiler' },
  { label: 'Ruppichteroth', value: 'Ruppichteroth' },
  { label: 'Ruprechtshofen', value: 'Ruprechtshofen' },
  { label: 'Rupt-Sur-Moselle', value: 'Rupt-Sur-Moselle' },
  { label: 'Rura', value: 'Rura' },
  { label: 'Rural Hall', value: 'Rural Hall' },
  { label: 'Rural Hill', value: 'Rural Hill' },
  { label: 'Rural Retreat', value: 'Rural Retreat' },
  { label: 'Rural View', value: 'Rural View' },
  { label: 'Rurange-Lès-Thionville', value: 'Rurange-Lès-Thionville' },
  { label: 'Rurópolis', value: 'Rurópolis' },
  { label: 'Rurrenabaque', value: 'Rurrenabaque' },
  { label: 'Rus', value: 'Rus' },
  { label: 'Rus', value: 'Rus' },
  { label: 'Rus', value: 'Rus' },
  { label: 'Rusakovka', value: 'Rusakovka' },
  { label: 'Rusăneşti', value: 'Rusăneşti' },
  { label: 'Rušanj', value: 'Rušanj' },
  { label: 'Rusape', value: 'Rusape' },
  { label: 'Rusca', value: 'Rusca' },
  { label: 'Rusca Montană', value: 'Rusca Montană' },
  { label: 'Rüschegg', value: 'Rüschegg' },
  { label: 'Rüschlikon', value: 'Rüschlikon' },
  { label: 'Ruščica', value: 'Ruščica' },
  { label: 'Ruscova', value: 'Ruscova' },
  { label: 'Ruse', value: 'Ruse' },
  { label: 'Ruse', value: 'Ruse' },
  { label: 'Ruše', value: 'Ruše' },
  { label: 'Ruseni', value: 'Ruseni' },
  { label: 'Rusera', value: 'Rusera' },
  { label: 'Ruşeţu', value: 'Ruşeţu' },
  { label: 'Rush City', value: 'Rush City' },
  { label: 'Rush County', value: 'Rush County' },
  { label: 'Rush County', value: 'Rush County' },
  { label: 'Rush Springs', value: 'Rush Springs' },
  { label: 'Rushcutters Bay', value: 'Rushcutters Bay' },
  { label: 'Rushden', value: 'Rushden' },
  { label: 'Rushford', value: 'Rushford' },
  { label: 'Rushinga District', value: 'Rushinga District' },
  { label: 'Rushmere', value: 'Rushmere' },
  { label: 'Rushville', value: 'Rushville' },
  { label: 'Rushville', value: 'Rushville' },
  { label: 'Rushville', value: 'Rushville' },
  { label: 'Rushworth', value: 'Rushworth' },
  { label: 'Rusiec', value: 'Rusiec' },
  { label: 'Rușii-Munți', value: 'Rușii-Munți' },
  { label: 'Rusinovo', value: 'Rusinovo' },
  { label: 'Rusinów', value: 'Rusinów' },
  { label: 'Rusk', value: 'Rusk' },
  { label: 'Rusk County', value: 'Rusk County' },
  { label: 'Rusk County', value: 'Rusk County' },
  { label: 'Ruska Poliana', value: 'Ruska Poliana' },
  { label: 'Ruskeala', value: 'Ruskeala' },
  { label: 'Ruskin', value: 'Ruskin' },
  { label: 'Ruskington', value: 'Ruskington' },
  { label: 'Rusko', value: 'Rusko' },
  { label: 'Rusko Selo', value: 'Rusko Selo' },
  { label: 'Rusnė', value: 'Rusnė' },
  { label: 'Rusocice', value: 'Rusocice' },
  { label: 'Rușor', value: 'Rușor' },
  { label: 'Russ', value: 'Russ' },
  { label: 'Russange', value: 'Russange' },
  { label: 'Russas', value: 'Russas' },
  { label: 'Russeifa', value: 'Russeifa' },
  { label: 'Russell', value: 'Russell' },
  { label: 'Russell', value: 'Russell' },
  { label: 'Russell', value: 'Russell' },
  { label: 'Russell', value: 'Russell' },
  { label: 'Russell', value: 'Russell' },
  { label: 'Russell County', value: 'Russell County' },
  { label: 'Russell County', value: 'Russell County' },
  { label: 'Russell County', value: 'Russell County' },
  { label: 'Russell County', value: 'Russell County' },
  { label: 'Russell Island', value: 'Russell Island' },
  { label: 'Russell Lea', value: 'Russell Lea' },
  { label: 'Russell Springs', value: 'Russell Springs' },
  { label: 'Russell Vale', value: 'Russell Vale' },
  { label: 'Russells Point', value: 'Russells Point' },
  { label: 'Russellton', value: 'Russellton' },
  { label: 'Russellville', value: 'Russellville' },
  { label: 'Russellville', value: 'Russellville' },
  { label: 'Russellville', value: 'Russellville' },
  { label: 'Russels', value: 'Russels' },
  { label: 'Rüsselsheim', value: 'Rüsselsheim' },
  { label: 'Russi', value: 'Russi' },
  { label: 'Russia', value: 'Russia' },
  { label: 'Russiaville', value: 'Russiaville' },
  { label: 'Russikon', value: 'Russikon' },
  { label: 'Russkaya Polyana', value: 'Russkaya Polyana' },
  { label: 'Russkinskiye', value: 'Russkinskiye' },
  { label: 'Russkiy', value: 'Russkiy' },
  { label: 'Russkiy Aktash', value: 'Russkiy Aktash' },
  { label: 'Russkiy Kameshkir', value: 'Russkiy Kameshkir' },
  { label: 'Russko-Osinovsky', value: 'Russko-Osinovsky' },
  { label: 'Russko-Vysotskoye', value: 'Russko-Vysotskoye' },
  { label: 'Rust', value: 'Rust' },
  { label: 'Rust', value: 'Rust' },
  { label: 'Rust Stadt', value: 'Rust Stadt' },
  { label: 'Rust’Avi', value: 'Rust’Avi' },
  { label: 'Rustam', value: 'Rustam' },
  { label: 'Rustaq', value: 'Rustaq' },
  { label: 'Rustburg', value: 'Rustburg' },
  { label: 'Rustenburg', value: 'Rustenburg' },
  { label: 'Rustenburg', value: 'Rustenburg' },
  { label: 'Rusthall', value: 'Rusthall' },
  { label: 'Rustington', value: 'Rustington' },
  { label: 'Ruston', value: 'Ruston' },
  { label: 'Rüstorf', value: 'Rüstorf' },
  { label: 'Ruswil', value: 'Ruswil' },
  { label: 'Rutana', value: 'Rutana' },
  { label: 'Rute', value: 'Rute' },
  { label: 'Ruteng', value: 'Ruteng' },
  { label: 'Rutesheim', value: 'Rutesheim' },
  { label: 'Rüthen', value: 'Rüthen' },
  { label: 'Rutherford', value: 'Rutherford' },
  { label: 'Rutherford', value: 'Rutherford' },
  { label: 'Rutherford', value: 'Rutherford' },
  { label: 'Rutherford', value: 'Rutherford' },
  { label: 'Rutherford College', value: 'Rutherford College' },
  { label: 'Rutherford County', value: 'Rutherford County' },
  { label: 'Rutherford County', value: 'Rutherford County' },
  { label: 'Rutherfordton', value: 'Rutherfordton' },
  { label: 'Rutherglen', value: 'Rutherglen' },
  { label: 'Rutherglen', value: 'Rutherglen' },
  { label: 'Rüthi', value: 'Rüthi' },
  { label: 'Ruthin', value: 'Ruthin' },
  { label: 'Rüti', value: 'Rüti' },
  { label: 'Rüti / Dorfzentrum Südl. Teil', value: 'Rüti / Dorfzentrum Südl. Teil' },
  { label: 'Rüti / Oberdorf', value: 'Rüti / Oberdorf' },
  { label: 'Rüti / Westlicher Dorfteil', value: 'Rüti / Westlicher Dorfteil' },
  { label: 'Rutigliano', value: 'Rutigliano' },
  { label: 'Rutino', value: 'Rutino' },
  { label: 'Rutki-Kossaki', value: 'Rutki-Kossaki' },
  { label: 'Rutland', value: 'Rutland' },
  { label: 'Rutland', value: 'Rutland' },
  { label: 'Rutland', value: 'Rutland' },
  { label: 'Rutland County', value: 'Rutland County' },
  { label: 'Rutledge', value: 'Rutledge' },
  { label: 'Rutul', value: 'Rutul' },
  { label: 'Rutul’Skiy Rayon', value: 'Rutul’Skiy Rayon' },
  { label: 'Rutzenham', value: 'Rutzenham' },
  { label: 'Ruukki', value: 'Ruukki' },
  { label: 'Ruurlo', value: 'Ruurlo' },
  { label: 'Ruviano', value: 'Ruviano' },
  { label: 'Ruvina', value: 'Ruvina' },
  { label: 'Ruvo Del Monte', value: 'Ruvo Del Monte' },
  { label: 'Ruvo Di Puglia', value: 'Ruvo Di Puglia' },
  { label: 'Ruwa', value: 'Ruwa' },
  { label: 'Ruwais', value: 'Ruwais' },
  { label: 'Ruwāndiz', value: 'Ruwāndiz' },
  { label: 'Ruy', value: 'Ruy' },
  { label: 'Ruy Barbosa', value: 'Ruy Barbosa' },
  { label: 'Ruy Barbosa', value: 'Ruy Barbosa' },
  { label: 'Ruydar', value: 'Ruydar' },
  { label: 'Ruyigi', value: 'Ruyigi' },
  { label: 'Ruyton-Xi-Towns', value: 'Ruyton-Xi-Towns' },
  { label: 'Ruza', value: 'Ruza' },
  { label: 'Ruzayevka', value: 'Ruzayevka' },
  { label: 'Ruzayevskiy Rayon', value: 'Ruzayevskiy Rayon' },
  { label: 'Ruzhany', value: 'Ruzhany' },
  { label: 'Ruzhintsi', value: 'Ruzhintsi' },
  { label: 'Ruzhou', value: 'Ruzhou' },
  { label: 'Ruzhyn', value: 'Ruzhyn' },
  { label: 'Ruzhyn (Settlement)', value: 'Ruzhyn (Settlement)' },
  { label: 'Ružomberok', value: 'Ružomberok' },
  { label: 'Ruzsa', value: 'Ruzsa' },
  { label: 'Ruzskiy Rayon', value: 'Ruzskiy Rayon' },
  { label: 'Rwamagana', value: 'Rwamagana' },
  { label: 'Ry', value: 'Ry' },
  { label: 'Ryabovo', value: 'Ryabovo' },
  { label: 'Ryazan’', value: 'Ryazan’' },
  { label: 'Ryazanskaya', value: 'Ryazanskaya' },
  { label: 'Ryazanskiy', value: 'Ryazanskiy' },
  { label: 'Ryazanskiy Rayon', value: 'Ryazanskiy Rayon' },
  { label: 'Ryazhsk', value: 'Ryazhsk' },
  { label: 'Rybache', value: 'Rybache' },
  { label: 'Rybakivka', value: 'Rybakivka' },
  { label: 'Rybarzowice', value: 'Rybarzowice' },
  { label: 'Rybatskoye', value: 'Rybatskoye' },
  { label: 'Rybczewice', value: 'Rybczewice' },
  { label: 'Rybí', value: 'Rybí' },
  { label: 'Rybie', value: 'Rybie' },
  { label: 'Rybinsk', value: 'Rybinsk' },
  { label: 'Rybinskiy Rayon', value: 'Rybinskiy Rayon' },
  { label: 'Rybitví', value: 'Rybitví' },
  { label: 'Rybnaya Sloboda', value: 'Rybnaya Sloboda' },
  { label: 'Rybnik', value: 'Rybnik' },
  { label: 'Rybno', value: 'Rybno' },
  { label: 'Rybno', value: 'Rybno' },
  { label: 'Rybno-Slobodskiy Rayon', value: 'Rybno-Slobodskiy Rayon' },
  { label: 'Rybnovskiy Rayon', value: 'Rybnovskiy Rayon' },
  { label: 'Rybnoye', value: 'Rybnoye' },
  { label: 'Rybnoye', value: 'Rybnoye' },
  { label: 'Rycerka Dolna', value: 'Rycerka Dolna' },
  { label: 'Rycerka Górna', value: 'Rycerka Górna' },
  { label: 'Rychnov Nad Kněžnou', value: 'Rychnov Nad Kněžnou' },
  { label: 'Rychtal', value: 'Rychtal' },
  { label: 'Rychvald', value: 'Rychvald' },
  { label: 'Rychwał', value: 'Rychwał' },
  { label: 'Ryczów', value: 'Ryczów' },
  { label: 'Ryczywół', value: 'Ryczywół' },
  { label: 'Ryczywół', value: 'Ryczywół' },
  { label: 'Ryd', value: 'Ryd' },
  { label: 'Ryd', value: 'Ryd' },
  { label: 'Rydaholm', value: 'Rydaholm' },
  { label: 'Rydal', value: 'Rydal' },
  { label: 'Rydalmere', value: 'Rydalmere' },
  { label: 'Ryde', value: 'Ryde' },
  { label: 'Ryde', value: 'Ryde' },
  { label: 'Rydebäck', value: 'Rydebäck' },
  { label: 'Rydsgård', value: 'Rydsgård' },
  { label: 'Rydułtowy', value: 'Rydułtowy' },
  { label: 'Rydzyna', value: 'Rydzyna' },
  { label: 'Rye', value: 'Rye' },
  { label: 'Rye', value: 'Rye' },
  { label: 'Rye', value: 'Rye' },
  { label: 'Rye', value: 'Rye' },
  { label: 'Rye Brook', value: 'Rye Brook' },
  { label: 'Ryegate', value: 'Ryegate' },
  { label: 'Rygge', value: 'Rygge' },
  { label: 'Ryggebyen', value: 'Ryggebyen' },
  { label: 'Ryglice', value: 'Ryglice' },
  { label: 'Ryhall', value: 'Ryhall' },
  { label: 'Ryhill', value: 'Ryhill' },
  { label: 'Ryhope', value: 'Ryhope' },
  { label: 'Ryjewo', value: 'Ryjewo' },
  { label: 'Rykhtychi', value: 'Rykhtychi' },
  { label: 'Ryki', value: 'Ryki' },
  { label: 'Ryl’Sk', value: 'Ryl’Sk' },
  { label: 'Ryland Heights', value: 'Ryland Heights' },
  { label: 'Rymań', value: 'Rymań' },
  { label: 'Rymanów', value: 'Rymanów' },
  { label: 'Rýmařov', value: 'Rýmařov' },
  { label: 'Rymättylä', value: 'Rymättylä' },
  { label: 'Ryn', value: 'Ryn' },
  { label: 'Ryomgård', value: 'Ryomgård' },
  { label: 'Ryōtsu-Minato', value: 'Ryōtsu-Minato' },
  { label: 'Rypefjord', value: 'Rypefjord' },
  { label: 'Rypin', value: 'Rypin' },
  { label: 'Ryton', value: 'Ryton' },
  { label: 'Ryton On Dunsmore', value: 'Ryton On Dunsmore' },
  { label: 'Rytro', value: 'Rytro' },
  { label: 'Rytwiany', value: 'Rytwiany' },
  { label: 'Ryūgasaki', value: 'Ryūgasaki' },
  { label: 'Ryūgasaki-Shi', value: 'Ryūgasaki-Shi' },
  { label: 'Ryūō', value: 'Ryūō' },
  { label: 'Ryzdvyanyy', value: 'Ryzdvyanyy' },
  { label: 'Rząska', value: 'Rząska' },
  { label: 'Rząśnik', value: 'Rząśnik' },
  { label: 'Rzeczenica', value: 'Rzeczenica' },
  { label: 'Rzeczniów', value: 'Rzeczniów' },
  { label: 'Rzeczyca', value: 'Rzeczyca' },
  { label: 'Rzeczyca', value: 'Rzeczyca' },
  { label: 'Rzekuń', value: 'Rzekuń' },
  { label: 'Rzepedź', value: 'Rzepedź' },
  { label: 'Rzepiennik Strzyżewski', value: 'Rzepiennik Strzyżewski' },
  { label: 'Rzepin', value: 'Rzepin' },
  { label: 'Rzeszotary', value: 'Rzeszotary' },
  { label: 'Rzeszów', value: 'Rzeszów' },
  { label: 'Rzewnie', value: 'Rzewnie' },
  { label: 'Rzezawa', value: 'Rzezawa' },
  { label: 'Rzgów', value: 'Rzgów' },
  { label: 'Rzgów Pierwszy', value: 'Rzgów Pierwszy' },
  { label: 'Rzhaksa', value: 'Rzhaksa' },
  { label: 'Rzhanitsa', value: 'Rzhanitsa' },
  { label: 'Rzhavki', value: 'Rzhavki' },
  { label: 'Rzhev', value: 'Rzhev' },
  { label: 'Rzhyshchiv', value: 'Rzhyshchiv' },
  { label: 'Rzozów', value: 'Rzozów' },
  { label: 'Rzyki', value: 'Rzyki' },
  { label: 'S Gravenmoer', value: 'S Gravenmoer' },
  { label: 'S.P. In Palazzi', value: 'S.P. In Palazzi' },
  { label: 'Sa Dec', value: 'Sa Dec' },
  { label: 'Sa Dec City', value: 'Sa Dec City' },
  { label: 'Sa Kaeo', value: 'Sa Kaeo' },
  { label: 'Sa Pa', value: 'Sa Pa' },
  { label: 'Sa Pobla', value: 'Sa Pobla' },
  { label: 'Şa‘Dah', value: 'Şa‘Dah' },
  { label: 'Saa', value: 'Saa' },
  { label: 'Saaban', value: 'Saaban' },
  { label: 'Saacow', value: 'Saacow' },
  { label: 'Saâcy-Sur-Marne', value: 'Saâcy-Sur-Marne' },
  { label: 'Saadatshahr', value: 'Saadatshahr' },
  { label: 'Sääksmäki', value: 'Sääksmäki' },
  { label: 'Saal', value: 'Saal' },
  { label: 'Saal', value: 'Saal' },
  { label: 'Saal An Der Saale', value: 'Saal An Der Saale' },
  { label: 'Saalbach', value: 'Saalbach' },
  { label: 'Saalfeld', value: 'Saalfeld' },
  { label: 'Saalfelden Am Steinernen Meer', value: 'Saalfelden Am Steinernen Meer' },
  { label: 'Saanen', value: 'Saanen' },
  { label: 'Saara', value: 'Saara' },
  { label: 'Saarbrücken', value: 'Saarbrücken' },
  { label: 'Saarburg', value: 'Saarburg' },
  { label: 'Saarde Vald', value: 'Saarde Vald' },
  { label: 'Saarhölzbach', value: 'Saarhölzbach' },
  { label: 'Saari', value: 'Saari' },
  { label: 'Saarijärvi', value: 'Saarijärvi' },
  { label: 'Saarlouis', value: 'Saarlouis' },
  { label: 'Saarwellingen', value: 'Saarwellingen' },
  { label: 'Saas-Fee', value: 'Saas-Fee' },
  { label: 'Saas-Grund', value: 'Saas-Grund' },
  { label: 'Saatlı', value: 'Saatlı' },
  { label: 'Saavedra', value: 'Saavedra' },
  { label: 'Saavedra', value: 'Saavedra' },
  { label: 'Saba', value: 'Saba' },
  { label: 'Sabá', value: 'Sabá' },
  { label: 'Šabac', value: 'Šabac' },
  { label: 'Sabadell', value: 'Sabadell' },
  { label: 'Sabae', value: 'Sabae' },
  { label: 'Sabae-Shi', value: 'Sabae-Shi' },
  { label: 'Sabah', value: 'Sabah' },
  { label: 'Şabāḩ As Sālim', value: 'Şabāḩ As Sālim' },
  { label: 'Sabalgarh', value: 'Sabalgarh' },
  { label: 'Sabalpur', value: 'Sabalpur' },
  { label: 'Saban', value: 'Saban' },
  { label: 'Sabana', value: 'Sabana' },
  { label: 'Sabana Buey', value: 'Sabana Buey' },
  { label: 'Sabana De La Mar', value: 'Sabana De La Mar' },
  { label: 'Sabana De Torres', value: 'Sabana De Torres' },
  { label: 'Sabana Del Puerto', value: 'Sabana Del Puerto' },
  { label: 'Sabana Eneas', value: 'Sabana Eneas' },
  { label: 'Sabana Grande', value: 'Sabana Grande' },
  { label: 'Sabana Grande', value: 'Sabana Grande' },
  { label: 'Sabana Grande', value: 'Sabana Grande' },
  { label: 'Sabana Grande', value: 'Sabana Grande' },
  { label: 'Sabana Grande De Boyá', value: 'Sabana Grande De Boyá' },
  { label: 'Sabana Grande De Palenque', value: 'Sabana Grande De Palenque' },
  { label: 'Sabana Grande Municipio', value: 'Sabana Grande Municipio' },
  { label: 'Sabana Hoyos', value: 'Sabana Hoyos' },
  { label: 'Sabana Iglesia', value: 'Sabana Iglesia' },
  { label: 'Sabana Seca', value: 'Sabana Seca' },
  { label: 'Sabana Yegua', value: 'Sabana Yegua' },
  { label: 'Sabanagrande', value: 'Sabanagrande' },
  { label: 'Sabanagrande', value: 'Sabanagrande' },
  { label: 'Sabanalarga', value: 'Sabanalarga' },
  { label: 'Sabanalarga', value: 'Sabanalarga' },
  { label: 'Sabanalarga', value: 'Sabanalarga' },
  { label: 'Sabanas De San Angel', value: 'Sabanas De San Angel' },
  { label: 'Sabanas De Xalostoc', value: 'Sabanas De Xalostoc' },
  { label: 'Sabancuy', value: 'Sabancuy' },
  { label: 'Sabaneta', value: 'Sabaneta' },
  { label: 'Sabaneta', value: 'Sabaneta' },
  { label: 'Sabaneta', value: 'Sabaneta' },
  { label: 'Sabang', value: 'Sabang' },
  { label: 'Sabang', value: 'Sabang' },
  { label: 'Sabang', value: 'Sabang' },
  { label: 'Sabang', value: 'Sabang' },
  { label: 'Sabang', value: 'Sabang' },
  { label: 'Sabang', value: 'Sabang' },
  { label: 'Sabang', value: 'Sabang' },
  { label: 'Sabang', value: 'Sabang' },
  { label: 'Sabang Indan', value: 'Sabang Indan' },
  { label: 'Sabangan', value: 'Sabangan' },
  { label: 'Sabanilla', value: 'Sabanilla' },
  { label: 'Sabanilla', value: 'Sabanilla' },
  { label: 'Sabanilla', value: 'Sabanilla' },
  { label: 'Sabanillas', value: 'Sabanillas' },
  { label: 'Sabanitas', value: 'Sabanitas' },
  { label: 'Şabanözü', value: 'Şabanözü' },
  { label: 'Săbăoani', value: 'Săbăoani' },
  { label: 'Sabar Kantha', value: 'Sabar Kantha' },
  { label: 'Sabará', value: 'Sabará' },
  { label: 'Săbăreni', value: 'Săbăreni' },
  { label: 'Sabasa', value: 'Sabasa' },
  { label: 'Sabashahr', value: 'Sabashahr' },
  { label: 'Sabathu', value: 'Sabathu' },
  { label: 'Sabattus', value: 'Sabattus' },
  { label: 'Sabaudia', value: 'Sabaudia' },
  { label: 'Sabáudia', value: 'Sabáudia' },
  { label: 'Sabbia', value: 'Sabbia' },
  { label: 'Sabbio Chiese', value: 'Sabbio Chiese' },
  { label: 'Sabbionara', value: 'Sabbionara' },
  { label: 'Sabbioneta', value: 'Sabbioneta' },
  { label: 'Sabden', value: 'Sabden' },
  { label: 'Săbed', value: 'Săbed' },
  { label: 'Sabero', value: 'Sabero' },
  { label: 'Sabetha', value: 'Sabetha' },
  { label: 'Sabhā', value: 'Sabhā' },
  { label: 'Şabḩā', value: 'Şabḩā' },
  { label: 'Sabi', value: 'Sabi' },
  { label: 'Sabidos', value: 'Sabidos' },
  { label: 'Sabile', value: 'Sabile' },
  { label: 'Sabina', value: 'Sabina' },
  { label: 'Sabinal', value: 'Sabinal' },
  { label: 'Sabinalito', value: 'Sabinalito' },
  { label: 'Sabiñán', value: 'Sabiñán' },
  { label: 'Sabiñánigo', value: 'Sabiñánigo' },
  { label: 'Sabinas', value: 'Sabinas' },
  { label: 'Sabinas Hidalgo', value: 'Sabinas Hidalgo' },
  { label: 'Sabine County', value: 'Sabine County' },
  { label: 'Sabine Parish', value: 'Sabine Parish' },
  { label: 'Sabino', value: 'Sabino' },
  { label: 'Sabinópolis', value: 'Sabinópolis' },
  { label: 'Sabinov', value: 'Sabinov' },
  { label: 'Sabinskiy Rayon', value: 'Sabinskiy Rayon' },
  { label: 'Sabiote', value: 'Sabiote' },
  { label: 'Sabir Al Mawadim', value: 'Sabir Al Mawadim' },
  { label: 'Sabirabad', value: 'Sabirabad' },
  { label: 'Săbișa', value: 'Săbișa' },
  { label: 'Sablan', value: 'Sablan' },
  { label: 'Sablan', value: 'Sablan' },
  { label: 'Sablayan', value: 'Sablayan' },
  { label: 'Sablé-Sur-Sarthe', value: 'Sablé-Sur-Sarthe' },
  { label: 'Sablet', value: 'Sablet' },
  { label: 'Sablig', value: 'Sablig' },
  { label: 'Sablig', value: 'Sablig' },
  { label: 'Sablonceaux', value: 'Sablonceaux' },
  { label: 'Sablons', value: 'Sablons' },
  { label: 'Sablons', value: 'Sablons' },
  { label: 'Sabnie', value: 'Sabnie' },
  { label: 'Sabnova', value: 'Sabnova' },
  { label: 'Saboeiro', value: 'Saboeiro' },
  { label: 'Saboyá', value: 'Saboyá' },
  { label: 'Sabran', value: 'Sabran' },
  { label: 'Şabrātah', value: 'Şabrātah' },
  { label: 'Sabres', value: 'Sabres' },
  { label: 'Sabro', value: 'Sabro' },
  { label: 'Sabrosa', value: 'Sabrosa' },
  { label: 'Sabrum', value: 'Sabrum' },
  { label: 'Sabtang', value: 'Sabtang' },
  { label: 'Sabtang', value: 'Sabtang' },
  { label: 'Sabugal', value: 'Sabugal' },
  { label: 'Sabugueiro', value: 'Sabugueiro' },
  { label: 'Sabuhah', value: 'Sabuhah' },
  { label: 'Sabunçu', value: 'Sabunçu' },
  { label: 'Şabyā', value: 'Şabyā' },
  { label: 'Sabzevar', value: 'Sabzevar' },
  { label: 'Sac City', value: 'Sac City' },
  { label: 'Sac County', value: 'Sac County' },
  { label: 'Sacaba', value: 'Sacaba' },
  { label: 'Săcădat', value: 'Săcădat' },
  { label: 'Săcădat', value: 'Săcădat' },
  { label: 'Săcălășeni', value: 'Săcălășeni' },
  { label: 'Săcălaz', value: 'Săcălaz' },
  { label: 'Săcalu De Pădure', value: 'Săcalu De Pădure' },
  { label: 'Sacalum', value: 'Sacalum' },
  { label: 'Sácama', value: 'Sácama' },
  { label: 'Sacañet', value: 'Sacañet' },
  { label: 'Sacanta', value: 'Sacanta' },
  { label: 'Sacapulas', value: 'Sacapulas' },
  { label: 'Săcăreni', value: 'Săcăreni' },
  { label: 'Săcăşeni', value: 'Săcăşeni' },
  { label: 'Sacaton', value: 'Sacaton' },
  { label: 'Sacavém', value: 'Sacavém' },
  { label: 'Sacavém', value: 'Sacavém' },
  { label: 'Sacca', value: 'Sacca' },
  { label: 'Sacco', value: 'Sacco' },
  { label: 'Saccolongo', value: 'Saccolongo' },
  { label: 'Sacecorbo', value: 'Sacecorbo' },
  { label: 'Saceda-Trasierra', value: 'Saceda-Trasierra' },
  { label: 'Sacedón', value: 'Sacedón' },
  { label: 'Săcel', value: 'Săcel' },
  { label: 'Săcel', value: 'Săcel' },
  { label: 'Săcele', value: 'Săcele' },
  { label: 'Săcele', value: 'Săcele' },
  { label: 'Săcelu', value: 'Săcelu' },
  { label: 'Săceni', value: 'Săceni' },
  { label: 'Saceruela', value: 'Saceruela' },
  { label: 'Sach’Khere', value: 'Sach’Khere' },
  { label: 'Saché', value: 'Saché' },
  { label: 'Sachendi', value: 'Sachendi' },
  { label: 'Sacheon-Si', value: 'Sacheon-Si' },
  { label: 'Sachet', value: 'Sachet' },
  { label: 'Sáchica', value: 'Sáchica' },
  { label: 'Sachin', value: 'Sachin' },
  { label: 'Sachkovichi', value: 'Sachkovichi' },
  { label: 'Sachse', value: 'Sachse' },
  { label: 'Sachseln', value: 'Sachseln' },
  { label: 'Sachsen', value: 'Sachsen' },
  { label: 'Sachsenburg', value: 'Sachsenburg' },
  { label: 'Sachsenhagen', value: 'Sachsenhagen' },
  { label: 'Sachsenhausen', value: 'Sachsenhausen' },
  { label: 'Sachsenheim', value: 'Sachsenheim' },
  { label: 'Sachsenkam', value: 'Sachsenkam' },
  { label: 'Sacile', value: 'Sacile' },
  { label: 'Sackets Harbor', value: 'Sackets Harbor' },
  { label: 'Sackville', value: 'Sackville' },
  { label: 'Saclamantón', value: 'Saclamantón' },
  { label: 'Saclas', value: 'Saclas' },
  { label: 'Saclay', value: 'Saclay' },
  { label: 'Saco', value: 'Saco' },
  { label: 'Saco Dos Limoes', value: 'Saco Dos Limoes' },
  { label: 'Sacoșu Mare', value: 'Sacoșu Mare' },
  { label: 'Sacoşu Turcesc', value: 'Sacoşu Turcesc' },
  { label: 'Sacramenia', value: 'Sacramenia' },
  { label: 'Sacramento', value: 'Sacramento' },
  { label: 'Sacramento', value: 'Sacramento' },
  { label: 'Sacramento', value: 'Sacramento' },
  { label: 'Sacramento', value: 'Sacramento' },
  { label: 'Sacramento County', value: 'Sacramento County' },
  { label: 'Sacré-Coeur', value: 'Sacré-Coeur' },
  { label: 'Sacriston', value: 'Sacriston' },
  { label: 'Sacrofano', value: 'Sacrofano' },
  { label: 'Sacu', value: 'Sacu' },
  { label: 'Săcueni', value: 'Săcueni' },
  { label: 'Săcueni', value: 'Săcueni' },
  { label: 'Săcuieu', value: 'Săcuieu' },
  { label: 'Sacy-Le-Grand', value: 'Sacy-Le-Grand' },
  { label: 'Sączów', value: 'Sączów' },
  { label: 'Sada', value: 'Sada' },
  { label: 'Sádaba', value: 'Sádaba' },
  { label: 'Sadabad', value: 'Sadabad' },
  { label: 'Sadabad', value: 'Sadabad' },
  { label: 'Şadad', value: 'Şadad' },
  { label: 'Sadadeen', value: 'Sadadeen' },
  { label: 'Sadah', value: 'Sadah' },
  { label: 'Sadalgi', value: 'Sadalgi' },
  { label: 'Sadali', value: 'Sadali' },
  { label: 'Sadanga', value: 'Sadanga' },
  { label: 'Sadanga', value: 'Sadanga' },
  { label: 'Sadao', value: 'Sadao' },
  { label: 'Sadaseopet', value: 'Sadaseopet' },
  { label: 'Sadat', value: 'Sadat' },
  { label: 'Saddle Brook', value: 'Saddle Brook' },
  { label: 'Saddle Brooke', value: 'Saddle Brooke' },
  { label: 'Saddle River', value: 'Saddle River' },
  { label: 'Sade', value: 'Sade' },
  { label: 'Sadiliyah', value: 'Sadiliyah' },
  { label: 'Sadina', value: 'Sadina' },
  { label: 'Sadiqabad', value: 'Sadiqabad' },
  { label: 'Sadirac', value: 'Sadirac' },
  { label: 'Sadki', value: 'Sadki' },
  { label: 'Sadkowice', value: 'Sadkowice' },
  { label: 'Sadleir', value: 'Sadleir' },
  { label: 'Sadliers Crossing', value: 'Sadliers Crossing' },
  { label: 'Sadlinki', value: 'Sadlinki' },
  { label: 'Sado Shi', value: 'Sado Shi' },
  { label: 'Sadov', value: 'Sadov' },
  { label: 'Sadova', value: 'Sadova' },
  { label: 'Sadova', value: 'Sadova' },
  { label: 'Sadovo', value: 'Sadovo' },
  { label: 'Sadovoye', value: 'Sadovoye' },
  { label: 'Sadovoye', value: 'Sadovoye' },
  { label: 'Sadovoye', value: 'Sadovoye' },
  { label: 'Sadovyy', value: 'Sadovyy' },
  { label: 'Sadowie', value: 'Sadowie' },
  { label: 'Sadowne', value: 'Sadowne' },
  { label: 'Sadr City', value: 'Sadr City' },
  { label: 'Sadra', value: 'Sadra' },
  { label: 'Sadri', value: 'Sadri' },
  { label: 'Sadsadan', value: 'Sadsadan' },
  { label: 'Sadsadan', value: 'Sadsadan' },
  { label: 'Sadsalan', value: 'Sadsalan' },
  { label: 'Sadsalan', value: 'Sadsalan' },
  { label: 'Sadská', value: 'Sadská' },
  { label: 'Sadu', value: 'Sadu' },
  { label: 'Sadzhavka', value: 'Sadzhavka' },
  { label: 'Sæby', value: 'Sæby' },
  { label: 'Saedinenie', value: 'Saedinenie' },
  { label: 'Saelele', value: 'Saelele' },
  { label: 'Saelices', value: 'Saelices' },
  { label: 'Saelices De La Sal', value: 'Saelices De La Sal' },
  { label: 'Saelices De Mayorga', value: 'Saelices De Mayorga' },
  { label: 'Saelices El Chico', value: 'Saelices El Chico' },
  { label: 'Saerbeck', value: 'Saerbeck' },
  { label: 'Șaeș', value: 'Șaeș' },
  { label: 'Sætre', value: 'Sætre' },
  { label: 'Saeul', value: 'Saeul' },
  { label: 'Sæveland', value: 'Sæveland' },
  { label: 'Safa Riz', value: 'Safa Riz' },
  { label: 'Safadasht', value: 'Safadasht' },
  { label: 'Safaga', value: 'Safaga' },
  { label: 'Safaiyeh', value: 'Safaiyeh' },
  { label: 'Safakulevo', value: 'Safakulevo' },
  { label: 'Safakulevskiy Rayon', value: 'Safakulevskiy Rayon' },
  { label: 'Safan', value: 'Safan' },
  { label: 'Safashahr', value: 'Safashahr' },
  { label: 'Safed', value: 'Safed' },
  { label: 'Safenwil', value: 'Safenwil' },
  { label: 'Safety Bay', value: 'Safety Bay' },
  { label: 'Safety Beach', value: 'Safety Beach' },
  { label: 'Safety Harbor', value: 'Safety Harbor' },
  { label: 'Saffig', value: 'Saffig' },
  { label: 'Säffle', value: 'Säffle' },
  { label: 'Safford', value: 'Safford' },
  { label: 'Saffré', value: 'Saffré' },
  { label: 'Saffron Walden', value: 'Saffron Walden' },
  { label: 'Safi', value: 'Safi' },
  { label: 'Safi', value: 'Safi' },
  { label: 'Safiabad', value: 'Safiabad' },
  { label: 'Safiabad', value: 'Safiabad' },
  { label: 'Safidon', value: 'Safidon' },
  { label: 'Safipur', value: 'Safipur' },
  { label: 'Safita District', value: 'Safita District' },
  { label: 'Safonovo', value: 'Safonovo' },
  { label: 'Safonovo', value: 'Safonovo' },
  { label: 'Safonovskiy Rayon', value: 'Safonovskiy Rayon' },
  { label: 'Safotu', value: 'Safotu' },
  { label: 'Safranbolu', value: 'Safranbolu' },
  { label: 'Safurdão', value: 'Safurdão' },
  { label: 'Şafwá', value: 'Şafwá' },
  { label: 'Sâg', value: 'Sâg' },
  { label: 'Şag', value: 'Şag' },
  { label: 'Sag Harbor', value: 'Sag Harbor' },
  { label: 'Saga', value: 'Saga' },
  { label: 'Saga', value: 'Saga' },
  { label: 'Saga Shi', value: 'Saga Shi' },
  { label: 'Sagacad', value: 'Sagacad' },
  { label: 'Sagada', value: 'Sagada' },
  { label: 'Sagada', value: 'Sagada' },
  { label: 'Sagadahoc County', value: 'Sagadahoc County' },
  { label: 'Sagae', value: 'Sagae' },
  { label: 'Sagae-Shi', value: 'Sagae-Shi' },
  { label: 'Sagaing', value: 'Sagaing' },
  { label: 'Sagalo', value: 'Sagalo' },
  { label: 'Sagama', value: 'Sagama' },
  { label: 'Sagamihara-Shi', value: 'Sagamihara-Shi' },
  { label: 'Sagamore', value: 'Sagamore' },
  { label: 'Sagana', value: 'Sagana' },
  { label: 'Sagana', value: 'Sagana' },
  { label: 'Sagana', value: 'Sagana' },
  { label: 'Sagana', value: 'Sagana' },
  { label: 'Sagang', value: 'Sagang' },
  { label: 'Sagan-Nur', value: 'Sagan-Nur' },
  { label: 'Sagar', value: 'Sagar' },
  { label: 'Sagar', value: 'Sagar' },
  { label: 'Sagara', value: 'Sagara' },
  { label: 'Sagard', value: 'Sagard' },
  { label: 'Sagarejo', value: 'Sagarejo' },
  { label: 'Sagàs', value: 'Sagàs' },
  { label: 'Sagasa', value: 'Sagasa' },
  { label: 'Sagasa', value: 'Sagasa' },
  { label: 'Sagasa', value: 'Sagasa' },
  { label: 'Sagauli', value: 'Sagauli' },
  { label: 'Sagay', value: 'Sagay' },
  { label: 'Sagay', value: 'Sagay' },
  { label: 'Sagbayan', value: 'Sagbayan' },
  { label: 'Săgeata', value: 'Săgeata' },
  { label: 'Saggart', value: 'Saggart' },
  { label: 'Saginaw', value: 'Saginaw' },
  { label: 'Saginaw', value: 'Saginaw' },
  { label: 'Saginaw County', value: 'Saginaw County' },
  { label: 'Saginaw Township North', value: 'Saginaw Township North' },
  { label: 'Sagliano Micca', value: 'Sagliano Micca' },
  { label: 'Sagna', value: 'Sagna' },
  { label: 'Sagnay', value: 'Sagnay' },
  { label: 'Sagopshi', value: 'Sagopshi' },
  { label: 'Sagpon', value: 'Sagpon' },
  { label: 'Sagra', value: 'Sagra' },
  { label: 'Sagrada', value: 'Sagrada' },
  { label: 'Sagrada Familia', value: 'Sagrada Familia' },
  { label: 'Sagrada Familia', value: 'Sagrada Familia' },
  { label: 'Sagrada Família', value: 'Sagrada Família' },
  { label: 'Sagrada La', value: 'Sagrada La' },
  { label: 'Sagrado', value: 'Sagrado' },
  { label: 'Sagres', value: 'Sagres' },
  { label: 'Sagres', value: 'Sagres' },
  { label: 'Sagron Mis', value: 'Sagron Mis' },
  { label: 'Şagu', value: 'Şagu' },
  { label: 'Sagua De Tánamo', value: 'Sagua De Tánamo' },
  { label: 'Sagua La Grande', value: 'Sagua La Grande' },
  { label: 'Saguache', value: 'Saguache' },
  { label: 'Saguache County', value: 'Saguache County' },
  { label: 'Saguday', value: 'Saguday' },
  { label: 'Saguday', value: 'Saguday' },
  { label: 'Sagud-Bahley', value: 'Sagud-Bahley' },
  { label: 'Sagud-Bahley', value: 'Sagud-Bahley' },
  { label: 'Saguenay', value: 'Saguenay' },
  { label: 'Saguiaran', value: 'Saguiaran' },
  { label: 'Saguiaran', value: 'Saguiaran' },
  { label: 'Saguing', value: 'Saguing' },
  { label: 'Saguing', value: 'Saguing' },
  { label: 'Sagunto', value: 'Sagunto' },
  { label: 'Sagunto', value: 'Sagunto' },
  { label: 'Sagunto', value: 'Sagunto' },
  { label: 'Sagunto/Sagunt', value: 'Sagunto/Sagunt' },
  { label: 'Sagurong', value: 'Sagurong' },
  { label: 'Sagvåg', value: 'Sagvåg' },
  { label: 'Sagy', value: 'Sagy' },
  { label: 'Sagy', value: 'Sagy' },
  { label: 'Sagz Abad', value: 'Sagz Abad' },
  { label: 'Sah', value: 'Sah' },
  { label: 'Saḩāb', value: 'Saḩāb' },
  { label: 'Saha-Gu', value: 'Saha-Gu' },
  { label: 'Sahagún', value: 'Sahagún' },
  { label: 'Sahagún', value: 'Sahagún' },
  { label: 'Sahalahti', value: 'Sahalahti' },
  { label: 'Şaḩam', value: 'Şaḩam' },
  { label: 'Saḩam Al Kaffārāt', value: 'Saḩam Al Kaffārāt' },
  { label: 'Sahamabad', value: 'Sahamabad' },
  { label: 'Sahand', value: 'Sahand' },
  { label: 'Sahapur', value: 'Sahapur' },
  { label: 'Saḩar', value: 'Saḩar' },
  { label: 'Saḩār', value: 'Saḩār' },
  { label: 'Saharanpur', value: 'Saharanpur' },
  { label: 'Saharna', value: 'Saharna' },
  { label: 'Saharsa', value: 'Saharsa' },
  { label: 'Sahaspur', value: 'Sahaspur' },
  { label: 'Sahaswan', value: 'Sahaswan' },
  { label: 'Săhăteni', value: 'Săhăteni' },
  { label: 'Sahawar', value: 'Sahawar' },
  { label: 'Şahbuz', value: 'Şahbuz' },
  { label: 'Sahcaba', value: 'Sahcaba' },
  { label: 'Saheb', value: 'Saheb' },
  { label: 'Sahibabad', value: 'Sahibabad' },
  { label: 'Sahibganj', value: 'Sahibganj' },
  { label: 'Şahinbey', value: 'Şahinbey' },
  { label: 'Sahiwal', value: 'Sahiwal' },
  { label: 'Sahline', value: 'Sahline' },
  { label: 'Sahneh', value: 'Sahneh' },
  { label: 'Sahpau', value: 'Sahpau' },
  { label: 'Sahuaral De Otero', value: 'Sahuaral De Otero' },
  { label: 'Sahuaripa', value: 'Sahuaripa' },
  { label: 'Sahuarita', value: 'Sahuarita' },
  { label: 'Sahuayo', value: 'Sahuayo' },
  { label: 'Sahuayo De Morelos', value: 'Sahuayo De Morelos' },
  { label: 'Sahugo El', value: 'Sahugo El' },
  { label: 'Sahún', value: 'Sahún' },
  { label: 'Sahurs', value: 'Sahurs' },
  { label: 'Šahy', value: 'Šahy' },
  { label: 'Sai Buri', value: 'Sai Buri' },
  { label: 'Sai Kung', value: 'Sai Kung' },
  { label: 'Sai Mai', value: 'Sai Mai' },
  { label: 'Saïda', value: 'Saïda' },
  { label: 'Saidia', value: 'Saidia' },
  { label: 'Saidiya', value: 'Saidiya' },
  { label: 'Saidpur', value: 'Saidpur' },
  { label: 'Saignelégier', value: 'Saignelégier' },
  { label: 'Saignon', value: 'Saignon' },
  { label: 'Saiha', value: 'Saiha' },
  { label: 'Saijō', value: 'Saijō' },
  { label: 'Saijō-Shi', value: 'Saijō-Shi' },
  { label: 'Saikai-Shi', value: 'Saikai-Shi' },
  { label: 'Saiki', value: 'Saiki' },
  { label: 'Saiki-Shi', value: 'Saiki-Shi' },
  { label: 'Sailana', value: 'Sailana' },
  { label: 'Sailauf', value: 'Sailauf' },
  { label: 'Saillon', value: 'Saillon' },
  { label: 'Sailly-Flibeaucourt', value: 'Sailly-Flibeaucourt' },
  { label: 'Sailly-Labourse', value: 'Sailly-Labourse' },
  { label: 'Sailly-Lez-Lannoy', value: 'Sailly-Lez-Lannoy' },
  { label: 'Sailly-Sur-La-Lys', value: 'Sailly-Sur-La-Lys' },
  { label: 'Sail-Sous-Couzan', value: 'Sail-Sous-Couzan' },
  { label: 'Sailu', value: 'Sailu' },
  { label: 'Saimbeyli', value: 'Saimbeyli' },
  { label: 'Sain Alto', value: 'Sain Alto' },
  { label: 'Sain Bajo', value: 'Sain Bajo' },
  { label: 'Sain Qaleh', value: 'Sain Qaleh' },
  { label: 'Sain-Bel', value: 'Sain-Bel' },
  { label: 'Sainghin-En-Mélantois', value: 'Sainghin-En-Mélantois' },
  { label: 'Sainghin-En-Weppes', value: 'Sainghin-En-Weppes' },
  { label: 'Sains-Du-Nord', value: 'Sains-Du-Nord' },
  { label: 'Sains-En-Amiénois', value: 'Sains-En-Amiénois' },
  { label: 'Sains-En-Gohelle', value: 'Sains-En-Gohelle' },
  { label: 'Saint Agnes', value: 'Saint Agnes' },
  { label: 'Saint Albans', value: 'Saint Albans' },
  { label: 'Saint Albans', value: 'Saint Albans' },
  { label: 'Saint Albans', value: 'Saint Albans' },
  { label: 'Saint Albans', value: 'Saint Albans' },
  { label: 'Saint Andrews', value: 'Saint Andrews' },
  { label: 'Saint Andrews', value: 'Saint Andrews' },
  { label: 'Saint Andrews', value: 'Saint Andrews' },
  { label: 'Saint Andrews', value: 'Saint Andrews' },
  { label: 'Saint Andrews Beach', value: 'Saint Andrews Beach' },
  { label: 'Saint Ann', value: 'Saint Ann' },
  { label: 'Saint Ann’S Bay', value: 'Saint Ann’S Bay' },
  { label: 'Saint Anne', value: 'Saint Anne' },
  { label: 'Saint Ansgar', value: 'Saint Ansgar' },
  { label: 'Saint Anthony', value: 'Saint Anthony' },
  { label: 'Saint Anthony', value: 'Saint Anthony' },
  { label: 'Saint Asaph', value: 'Saint Asaph' },
  { label: 'Saint Aubin', value: 'Saint Aubin' },
  { label: 'Saint Augusta', value: 'Saint Augusta' },
  { label: 'Saint Augustine', value: 'Saint Augustine' },
  { label: 'Saint Augustine Beach', value: 'Saint Augustine Beach' },
  { label: 'Saint Augustine Shores', value: 'Saint Augustine Shores' },
  { label: 'Saint Augustine South', value: 'Saint Augustine South' },
  { label: 'Saint Bees', value: 'Saint Bees' },
  { label: 'Saint Bernard', value: 'Saint Bernard' },
  { label: 'Saint Bernard', value: 'Saint Bernard' },
  { label: 'Saint Bernard', value: 'Saint Bernard' },
  { label: 'Saint Bernard Parish', value: 'Saint Bernard Parish' },
  { label: 'Saint Bonaventure', value: 'Saint Bonaventure' },
  { label: 'Saint Bonifacius', value: 'Saint Bonifacius' },
  { label: 'Saint Boswells', value: 'Saint Boswells' },
  { label: 'Saint Catherine', value: 'Saint Catherine' },
  { label: 'Saint Charles', value: 'Saint Charles' },
  { label: 'Saint Charles', value: 'Saint Charles' },
  { label: 'Saint Charles', value: 'Saint Charles' },
  { label: 'Saint Charles', value: 'Saint Charles' },
  { label: 'Saint Charles', value: 'Saint Charles' },
  { label: 'Saint Charles County', value: 'Saint Charles County' },
  { label: 'Saint Charles Parish', value: 'Saint Charles Parish' },
  { label: 'Saint Clair', value: 'Saint Clair' },
  { label: 'Saint Clair', value: 'Saint Clair' },
  { label: 'Saint Clair', value: 'Saint Clair' },
  { label: 'Saint Clair County', value: 'Saint Clair County' },
  { label: 'Saint Clair County', value: 'Saint Clair County' },
  { label: 'Saint Clair County', value: 'Saint Clair County' },
  { label: 'Saint Clair County', value: 'Saint Clair County' },
  { label: 'Saint Clair Shores', value: 'Saint Clair Shores' },
  { label: 'Saint Clairsville', value: 'Saint Clairsville' },
  { label: 'Saint Clears', value: 'Saint Clears' },
  { label: 'Saint Cloud', value: 'Saint Cloud' },
  { label: 'Saint Cloud', value: 'Saint Cloud' },
  { label: 'Saint Columb Major', value: 'Saint Columb Major' },
  { label: 'Saint Croix County', value: 'Saint Croix County' },
  { label: 'Saint Croix Falls', value: 'Saint Croix Falls' },
  { label: 'Saint Cyrus', value: 'Saint Cyrus' },
  { label: 'Saint David', value: 'Saint David' },
  { label: 'Saint David’S', value: 'Saint David’S' },
  { label: 'Saint Davids', value: 'Saint Davids' },
  { label: 'Saint Dennis', value: 'Saint Dennis' },
  { label: 'Saint Dennis', value: 'Saint Dennis' },
  { label: 'Saint Elmo', value: 'Saint Elmo' },
  { label: 'Saint Francis', value: 'Saint Francis' },
  { label: 'Saint Francis', value: 'Saint Francis' },
  { label: 'Saint Francis', value: 'Saint Francis' },
  { label: 'Saint Francis County', value: 'Saint Francis County' },
  { label: 'Saint Francisville', value: 'Saint Francisville' },
  { label: 'Saint Francois County', value: 'Saint Francois County' },
  { label: 'Saint Gabriel', value: 'Saint Gabriel' },
  { label: 'Saint George', value: 'Saint George' },
  { label: 'Saint George', value: 'Saint George' },
  { label: 'Saint George', value: 'Saint George' },
  { label: 'Saint George', value: 'Saint George' },
  { label: 'Saint George', value: 'Saint George' },
  { label: 'Saint Georges', value: 'Saint Georges' },
  { label: 'Saint Hedwig', value: 'Saint Hedwig' },
  { label: 'Saint Helen', value: 'Saint Helen' },
  { label: 'Saint Helena', value: 'Saint Helena' },
  { label: 'Saint Helena', value: 'Saint Helena' },
  { label: 'Saint Helena Parish', value: 'Saint Helena Parish' },
  { label: 'Saint Helens', value: 'Saint Helens' },
  { label: 'Saint Henry', value: 'Saint Henry' },
  { label: 'Saint Hubert', value: 'Saint Hubert' },
  { label: 'Saint Ignace', value: 'Saint Ignace' },
  { label: 'Saint Ives', value: 'Saint Ives' },
  { label: 'Saint Jacob', value: 'Saint Jacob' },
  { label: 'Saint James', value: 'Saint James' },
  { label: 'Saint James', value: 'Saint James' },
  { label: 'Saint James', value: 'Saint James' },
  { label: 'Saint James', value: 'Saint James' },
  { label: 'Saint James', value: 'Saint James' },
  { label: 'Saint James City', value: 'Saint James City' },
  { label: 'Saint James Parish', value: 'Saint James Parish' },
  { label: 'Saint Jo', value: 'Saint Jo' },
  { label: 'Saint John', value: 'Saint John' },
  { label: 'Saint John', value: 'Saint John' },
  { label: 'Saint John', value: 'Saint John' },
  { label: 'Saint John', value: 'Saint John' },
  { label: 'Saint John The Baptist Parish', value: 'Saint John The Baptist Parish' },
  { label: 'Saint John’S', value: 'Saint John’S' },
  { label: 'Saint Johns', value: 'Saint Johns' },
  { label: 'Saint Johns', value: 'Saint Johns' },
  { label: 'Saint Johns', value: 'Saint Johns' },
  { label: 'Saint Johns County', value: 'Saint Johns County' },
  { label: 'Saint Johnsbury', value: 'Saint Johnsbury' },
  { label: 'Saint Johnsville', value: 'Saint Johnsville' },
  { label: 'Saint Joseph', value: 'Saint Joseph' },
  { label: 'Saint Joseph', value: 'Saint Joseph' },
  { label: 'Saint Joseph', value: 'Saint Joseph' },
  { label: 'Saint Joseph', value: 'Saint Joseph' },
  { label: 'Saint Joseph', value: 'Saint Joseph' },
  { label: 'Saint Joseph', value: 'Saint Joseph' },
  { label: 'Saint Joseph County', value: 'Saint Joseph County' },
  { label: 'Saint Joseph County', value: 'Saint Joseph County' },
  { label: 'Saint Julien', value: 'Saint Julien' },
  { label: 'Saint Julien Dhotman', value: 'Saint Julien Dhotman' },
  { label: 'Saint Kilda', value: 'Saint Kilda' },
  { label: 'Saint Landry Parish', value: 'Saint Landry Parish' },
  { label: 'Saint Lawrence', value: 'Saint Lawrence' },
  { label: 'Saint Leo', value: 'Saint Leo' },
  { label: 'Saint Leonards', value: 'Saint Leonards' },
  { label: 'Saint Leonards', value: 'Saint Leonards' },
  { label: 'Saint Leonards-On-Sea', value: 'Saint Leonards-On-Sea' },
  { label: 'Saint Louis', value: 'Saint Louis' },
  { label: 'Saint Louis County', value: 'Saint Louis County' },
  { label: 'Saint Louis County', value: 'Saint Louis County' },
  { label: 'Saint Louis Park', value: 'Saint Louis Park' },
  { label: 'Saint Lucie County', value: 'Saint Lucie County' },
  { label: 'Saint Marcel', value: 'Saint Marcel' },
  { label: 'Saint Marie Road', value: 'Saint Marie Road' },
  { label: 'Saint Maries', value: 'Saint Maries' },
  { label: 'Saint Martin', value: 'Saint Martin' },
  { label: 'Saint Martin Parish', value: 'Saint Martin Parish' },
  { label: 'Saint Martins', value: 'Saint Martins' },
  { label: 'Saint Martinville', value: 'Saint Martinville' },
  { label: 'Saint Mary Parish', value: 'Saint Mary Parish' },
  { label: 'Saint Marys', value: 'Saint Marys' },
  { label: 'Saint Marys', value: 'Saint Marys' },
  { label: 'Saint Marys', value: 'Saint Marys' },
  { label: 'Saint Marys', value: 'Saint Marys' },
  { label: 'Saint Marys County', value: 'Saint Marys County' },
  { label: 'Saint Matthews', value: 'Saint Matthews' },
  { label: 'Saint Matthews', value: 'Saint Matthews' },
  { label: 'Saint Maurice', value: 'Saint Maurice' },
  { label: 'Saint Michael', value: 'Saint Michael' },
  { label: 'Saint Michaels', value: 'Saint Michaels' },
  { label: 'Saint Michaels', value: 'Saint Michaels' },
  { label: 'Saint Monans', value: 'Saint Monans' },
  { label: 'Saint Neots', value: 'Saint Neots' },
  { label: 'Saint Osyth', value: 'Saint Osyth' },
  { label: 'Saint Paris', value: 'Saint Paris' },
  { label: 'Saint Paul', value: 'Saint Paul' },
  { label: 'Saint Paul', value: 'Saint Paul' },
  { label: 'Saint Paul', value: 'Saint Paul' },
  { label: 'Saint Paul', value: 'Saint Paul' },
  { label: 'Saint Paul', value: 'Saint Paul' },
  { label: 'Saint Paul Park', value: 'Saint Paul Park' },
  { label: 'Saint Paul’S', value: 'Saint Paul’S' },
  { label: 'Saint Pauls', value: 'Saint Pauls' },
  { label: 'Saint Pete Beach', value: 'Saint Pete Beach' },
  { label: 'Saint Peter', value: 'Saint Peter' },
  { label: 'Saint Peter', value: 'Saint Peter' },
  { label: 'Saint Peters', value: 'Saint Peters' },
  { label: 'Saint Peters', value: 'Saint Peters' },
  { label: 'Saint Peters', value: 'Saint Peters' },
  { label: 'Saint Petersburg', value: 'Saint Petersburg' },
  { label: 'Saint Pierre', value: 'Saint Pierre' },
  { label: 'Saint Regis Park', value: 'Saint Regis Park' },
  { label: 'Saint Robert', value: 'Saint Robert' },
  { label: 'Saint Rose', value: 'Saint Rose' },
  { label: 'Saint Simon Mills', value: 'Saint Simon Mills' },
  { label: 'Saint Simons Island', value: 'Saint Simons Island' },
  { label: 'Saint Stephen', value: 'Saint Stephen' },
  { label: 'Saint Stephen', value: 'Saint Stephen' },
  { label: 'Saint Stephens', value: 'Saint Stephens' },
  { label: 'Saint Tammany Parish', value: 'Saint Tammany Parish' },
  { label: 'Saint Thomas Mount', value: 'Saint Thomas Mount' },
  { label: 'Saint-Adolphe-Dhoward', value: 'Saint-Adolphe-Dhoward' },
  { label: 'Saint-Affrique', value: 'Saint-Affrique' },
  { label: 'Saint-Agathon', value: 'Saint-Agathon' },
  { label: 'Saint-Agnant', value: 'Saint-Agnant' },
  { label: 'Saint-Agnant-De-Versillat', value: 'Saint-Agnant-De-Versillat' },
  { label: 'Saint-Agrève', value: 'Saint-Agrève' },
  { label: 'Saint-Aignan', value: 'Saint-Aignan' },
  { label: 'Saint-Aigulin', value: 'Saint-Aigulin' },
  { label: 'Saint-Alban', value: 'Saint-Alban' },
  { label: 'Saint-Alban', value: 'Saint-Alban' },
  { label: 'Saint-Alban-De-Roche', value: 'Saint-Alban-De-Roche' },
  { label: 'Saint-Alban-Leysse', value: 'Saint-Alban-Leysse' },
  { label: 'Saint-Alban-Sur-Limagnole', value: 'Saint-Alban-Sur-Limagnole' },
  { label: 'Saint-Alexandre', value: 'Saint-Alexandre' },
  { label: 'Saint-Alexandre', value: 'Saint-Alexandre' },
  { label: 'Saint-Amable', value: 'Saint-Amable' },
  { label: 'Saint-Amand', value: 'Saint-Amand' },
  { label: 'Saint-Amand-En-Puisaye', value: 'Saint-Amand-En-Puisaye' },
  { label: 'Saint-Amand-Les-Eaux', value: 'Saint-Amand-Les-Eaux' },
  { label: 'Saint-Amand-Longpré', value: 'Saint-Amand-Longpré' },
  { label: 'Saint-Amand-Montrond', value: 'Saint-Amand-Montrond' },
  { label: 'Saint-Amand-Sur-Sèvre', value: 'Saint-Amand-Sur-Sèvre' },
  { label: 'Saint-Amans-Soult', value: 'Saint-Amans-Soult' },
  { label: 'Saint-Amans-Valtoret', value: 'Saint-Amans-Valtoret' },
  { label: 'Saint-Amant-De-Boixe', value: 'Saint-Amant-De-Boixe' },
  { label: 'Saint-Amant-Tallende', value: 'Saint-Amant-Tallende' },
  { label: 'Saint-Amarin', value: 'Saint-Amarin' },
  { label: 'Saint-Ambroise', value: 'Saint-Ambroise' },
  { label: 'Saint-Ambroix', value: 'Saint-Ambroix' },
  { label: 'Saint-Amé', value: 'Saint-Amé' },
  { label: 'Saint-Amour', value: 'Saint-Amour' },
  { label: 'Saint-Andéol-Le-Château', value: 'Saint-Andéol-Le-Château' },
  { label: 'Saint-Andiol', value: 'Saint-Andiol' },
  { label: 'Saint-André', value: 'Saint-André' },
  { label: 'Saint-André-Avellin', value: 'Saint-André-Avellin' },
  { label: 'Saint-André-De-Corcy', value: 'Saint-André-De-Corcy' },
  { label: 'Saint-André-De-La-Marche', value: 'Saint-André-De-La-Marche' },
  { label: 'Saint-André-De-La-Roche', value: 'Saint-André-De-La-Roche' },
  { label: 'Saint-André-De-Sangonis', value: 'Saint-André-De-Sangonis' },
  { label: 'Saint-André-Des-Eaux', value: 'Saint-André-Des-Eaux' },
  { label: 'Saint-André-De-Seignanx', value: 'Saint-André-De-Seignanx' },
  { label: 'Saint-André-Le-Gaz', value: 'Saint-André-Le-Gaz' },
  { label: 'Saint-André-Le-Puy', value: 'Saint-André-Le-Puy' },
  { label: 'Saint-André-Les-Vergers', value: 'Saint-André-Les-Vergers' },
  { label: 'Saint-André-Lez-Lille', value: 'Saint-André-Lez-Lille' },
  { label: 'Saint-André-Sur-Orne', value: 'Saint-André-Sur-Orne' },
  { label: 'Saint-André-Sur-Vieux-Jonc', value: 'Saint-André-Sur-Vieux-Jonc' },
  { label: 'Saint-André-Treize-Voies', value: 'Saint-André-Treize-Voies' },
  { label: 'Saint-Anselme', value: 'Saint-Anselme' },
  { label: 'Saint-Antoine', value: 'Saint-Antoine' },
  { label: 'Saint-Antoine', value: 'Saint-Antoine' },
  { label: 'Saint-Antoine-De-Breuilh', value: 'Saint-Antoine-De-Breuilh' },
  { label: 'Saint-Antoine-De-Tilly', value: 'Saint-Antoine-De-Tilly' },
  { label: 'Saint-Antoine-Du-Rocher', value: 'Saint-Antoine-Du-Rocher' },
  { label: 'Saint-Antoine-Labbaye', value: 'Saint-Antoine-Labbaye' },
  { label: 'Saint-Apollinaire', value: 'Saint-Apollinaire' },
  { label: 'Saint-Armel', value: 'Saint-Armel' },
  { label: 'Saint-Arnoult', value: 'Saint-Arnoult' },
  { label: 'Saint-Arnoult-En-Yvelines', value: 'Saint-Arnoult-En-Yvelines' },
  { label: 'Saint-Astier', value: 'Saint-Astier' },
  { label: 'Saint-Aubert', value: 'Saint-Aubert' },
  { label: 'Saint-Aubin', value: 'Saint-Aubin' },
  { label: 'Saint-Aubin-De-Médoc', value: 'Saint-Aubin-De-Médoc' },
  { label: 'Saint-Aubin-Des-Bois', value: 'Saint-Aubin-Des-Bois' },
  { label: 'Saint-Aubin-Des-Châteaux', value: 'Saint-Aubin-Des-Châteaux' },
  { label: 'Saint-Aubin-Des-Ormeaux', value: 'Saint-Aubin-Des-Ormeaux' },
  { label: 'Saint-Aubin-Du-Cormier', value: 'Saint-Aubin-Du-Cormier' },
  { label: 'Saint-Aubin-En-Bray', value: 'Saint-Aubin-En-Bray' },
  { label: 'Saint-Aubin-Le-Cloud', value: 'Saint-Aubin-Le-Cloud' },
  { label: 'Saint-Aubin-Lès-Elbeuf', value: 'Saint-Aubin-Lès-Elbeuf' },
  { label: 'Saint-Aubin-Routot', value: 'Saint-Aubin-Routot' },
  { label: 'Saint-Aubin-Sauges', value: 'Saint-Aubin-Sauges' },
  { label: 'Saint-Aubin-Sur-Gaillon', value: 'Saint-Aubin-Sur-Gaillon' },
  { label: 'Saint-Aubin-Sur-Mer', value: 'Saint-Aubin-Sur-Mer' },
  { label: 'Saint-Aubin-Sur-Scie', value: 'Saint-Aubin-Sur-Scie' },
  { label: 'Saint-Augustin', value: 'Saint-Augustin' },
  { label: 'Saint-Augustin', value: 'Saint-Augustin' },
  { label: 'Saint-Augustin-De-Desmaures', value: 'Saint-Augustin-De-Desmaures' },
  { label: 'Saint-Aulaye', value: 'Saint-Aulaye' },
  { label: 'Saint-Aunès', value: 'Saint-Aunès' },
  { label: 'Saint-Aupre', value: 'Saint-Aupre' },
  { label: 'Saint-Avé', value: 'Saint-Avé' },
  { label: 'Saint-Avertin', value: 'Saint-Avertin' },
  { label: 'Saint-Avold', value: 'Saint-Avold' },
  { label: 'Saint-Ay', value: 'Saint-Ay' },
  { label: 'Saint-Baldoph', value: 'Saint-Baldoph' },
  { label: 'Saint-Barnabé', value: 'Saint-Barnabé' },
  { label: 'Saint-Barnabé', value: 'Saint-Barnabé' },
  { label: 'Saint-Barnabé-Sud', value: 'Saint-Barnabé-Sud' },
  { label: 'Saint-Barthélémy', value: 'Saint-Barthélémy' },
  { label: 'Saint-Barthélemy-De-Vals', value: 'Saint-Barthélemy-De-Vals' },
  { label: 'Saint-Basile-Le-Grand', value: 'Saint-Basile-Le-Grand' },
  { label: 'Saint-Baudelle', value: 'Saint-Baudelle' },
  { label: 'Saint-Bauzille-De-Putois', value: 'Saint-Bauzille-De-Putois' },
  { label: 'Saint-Beauzire', value: 'Saint-Beauzire' },
  { label: 'Saint-Benin-D’Azy', value: 'Saint-Benin-D’Azy' },
  { label: 'Saint-Benoît', value: 'Saint-Benoît' },
  { label: 'Saint-Benoît-De-Carmaux', value: 'Saint-Benoît-De-Carmaux' },
  { label: 'Saint-Benoît-Sur-Loire', value: 'Saint-Benoît-Sur-Loire' },
  { label: 'Saint-Berain-Sous-Sanvignes', value: 'Saint-Berain-Sous-Sanvignes' },
  { label: 'Saint-Béron', value: 'Saint-Béron' },
  { label: 'Saint-Berthevin', value: 'Saint-Berthevin' },
  { label: 'Saint-Blaise', value: 'Saint-Blaise' },
  { label: 'Saint-Boniface', value: 'Saint-Boniface' },
  { label: 'Saint-Bonnet-De-Mure', value: 'Saint-Bonnet-De-Mure' },
  { label: 'Saint-Bonnet-En-Champsaur', value: 'Saint-Bonnet-En-Champsaur' },
  { label: 'Saint-Bonnet-Le-Château', value: 'Saint-Bonnet-Le-Château' },
  { label: 'Saint-Bonnet-Les-Oules', value: 'Saint-Bonnet-Les-Oules' },
  { label: 'Saint-Bonnet-Près-Riom', value: 'Saint-Bonnet-Près-Riom' },
  { label: 'Saint-Bon-Tarentaise', value: 'Saint-Bon-Tarentaise' },
  { label: 'Saint-Branchs', value: 'Saint-Branchs' },
  { label: 'Saint-Brandan', value: 'Saint-Brandan' },
  { label: 'Saint-Brès', value: 'Saint-Brès' },
  { label: 'Saint-Brevin-Les-Pins', value: 'Saint-Brevin-Les-Pins' },
  { label: 'Saint-Briac-Sur-Mer', value: 'Saint-Briac-Sur-Mer' },
  { label: 'Saint-Brice', value: 'Saint-Brice' },
  { label: 'Saint-Brice-Courcelles', value: 'Saint-Brice-Courcelles' },
  { label: 'Saint-Brice-En-Coglès', value: 'Saint-Brice-En-Coglès' },
  { label: 'Saint-Brice-Sous-Forêt', value: 'Saint-Brice-Sous-Forêt' },
  { label: 'Saint-Brice-Sur-Vienne', value: 'Saint-Brice-Sur-Vienne' },
  { label: 'Saint-Brieuc', value: 'Saint-Brieuc' },
  { label: 'Saint-Bris-Le-Vineux', value: 'Saint-Bris-Le-Vineux' },
  { label: 'Saint-Brisson-Sur-Loire', value: 'Saint-Brisson-Sur-Loire' },
  { label: 'Saint-Broladre', value: 'Saint-Broladre' },
  { label: 'Saint-Bruno', value: 'Saint-Bruno' },
  { label: 'Saint-Bruno-De-Guigues', value: 'Saint-Bruno-De-Guigues' },
  { label: 'Saint-Bruno-De-Montarville', value: 'Saint-Bruno-De-Montarville' },
  { label: 'Saint-Calais', value: 'Saint-Calais' },
  { label: 'Saint-Cannat', value: 'Saint-Cannat' },
  { label: 'Saint-Canut', value: 'Saint-Canut' },
  { label: 'Saint-Caprais-De-Bordeaux', value: 'Saint-Caprais-De-Bordeaux' },
  { label: 'Saint-Caradec', value: 'Saint-Caradec' },
  { label: 'Saint-Carreuc', value: 'Saint-Carreuc' },
  { label: 'Saint-Cassien', value: 'Saint-Cassien' },
  { label: 'Saint-Cast-Le-Guildo', value: 'Saint-Cast-Le-Guildo' },
  { label: 'Saint-Céré', value: 'Saint-Céré' },
  { label: 'Saint-Cergue', value: 'Saint-Cergue' },
  { label: 'Saint-Cergues', value: 'Saint-Cergues' },
  { label: 'Saint-Cernin', value: 'Saint-Cernin' },
  { label: 'Saint-Césaire', value: 'Saint-Césaire' },
  { label: 'Saint-Cézaire-Sur-Siagne', value: 'Saint-Cézaire-Sur-Siagne' },
  { label: 'Saint-Chaffrey', value: 'Saint-Chaffrey' },
  { label: 'Saint-Chamas', value: 'Saint-Chamas' },
  { label: 'Saint-Chamond', value: 'Saint-Chamond' },
  { label: 'Saint-Chaptes', value: 'Saint-Chaptes' },
  { label: 'Saint-Charles', value: 'Saint-Charles' },
  { label: 'Saint-Charles', value: 'Saint-Charles' },
  { label: 'Saint-Chef', value: 'Saint-Chef' },
  { label: 'Saint-Chéron', value: 'Saint-Chéron' },
  { label: 'Saint-Chinian', value: 'Saint-Chinian' },
  { label: 'Saint-Christo-En-Jarez', value: 'Saint-Christo-En-Jarez' },
  { label: 'Saint-Christol', value: 'Saint-Christol' },
  { label: 'Saint-Christol-Lès-Alès', value: 'Saint-Christol-Lès-Alès' },
  { label: 'Saint-Christoly-De-Blaye', value: 'Saint-Christoly-De-Blaye' },
  { label: 'Saint-Christophe', value: 'Saint-Christophe' },
  { label: 'Saint-Christophe-Du-Bois', value: 'Saint-Christophe-Du-Bois' },
  { label: 'Saint-Christophe-Du-Ligneron', value: 'Saint-Christophe-Du-Ligneron' },
  { label: 'Saint-Christophe-Sur-Le-Nais', value: 'Saint-Christophe-Sur-Le-Nais' },
  { label: 'Saint-Christophe-Vallon', value: 'Saint-Christophe-Vallon' },
  { label: 'Saint-Ciers-D’Abzac', value: 'Saint-Ciers-D’Abzac' },
  { label: 'Saint-Ciers-Sur-Gironde', value: 'Saint-Ciers-Sur-Gironde' },
  { label: 'Saint-Clair-De-La-Tour', value: 'Saint-Clair-De-La-Tour' },
  { label: 'Saint-Clair-Du-Rhône', value: 'Saint-Clair-Du-Rhône' },
  { label: 'Saint-Claud', value: 'Saint-Claud' },
  { label: 'Saint-Claude', value: 'Saint-Claude' },
  { label: 'Saint-Claude-De-Diray', value: 'Saint-Claude-De-Diray' },
  { label: 'Saint-Clément', value: 'Saint-Clément' },
  { label: 'Saint-Clément', value: 'Saint-Clément' },
  { label: 'Saint-Clément-De-La-Place', value: 'Saint-Clément-De-La-Place' },
  { label: 'Saint-Clément-De-Rivière', value: 'Saint-Clément-De-Rivière' },
  { label: 'Saint-Clément-Des-Levées', value: 'Saint-Clément-Des-Levées' },
  { label: 'Saint-Cloud', value: 'Saint-Cloud' },
  { label: 'Saint-Côme-Linière', value: 'Saint-Côme-Linière' },
  { label: 'Saint-Constant', value: 'Saint-Constant' },
  { label: 'Saint-Contest', value: 'Saint-Contest' },
  { label: 'Saint-Cosme-En-Vairais', value: 'Saint-Cosme-En-Vairais' },
  { label: 'Saint-Coulomb', value: 'Saint-Coulomb' },
  { label: 'Saint-Crépin-Ibouvillers', value: 'Saint-Crépin-Ibouvillers' },
  { label: 'Saint-Crespin-Sur-Moine', value: 'Saint-Crespin-Sur-Moine' },
  { label: 'Saint-Cyprien', value: 'Saint-Cyprien' },
  { label: 'Saint-Cyprien', value: 'Saint-Cyprien' },
  { label: 'Saint-Cyprien-Plage', value: 'Saint-Cyprien-Plage' },
  { label: 'Saint-Cyr', value: 'Saint-Cyr' },
  { label: 'Saint-Cyr-En-Bourg', value: 'Saint-Cyr-En-Bourg' },
  { label: 'Saint-Cyr-En-Val', value: 'Saint-Cyr-En-Val' },
  { label: 'Saint-Cyrille-De-Wendover', value: 'Saint-Cyrille-De-Wendover' },
  { label: 'Saint-Cyr-L’École', value: 'Saint-Cyr-L’École' },
  { label: 'Saint-Cyr-Sous-Dourdan', value: 'Saint-Cyr-Sous-Dourdan' },
  { label: 'Saint-Cyr-Sur-Le-Rhône', value: 'Saint-Cyr-Sur-Le-Rhône' },
  { label: 'Saint-Cyr-Sur-Loire', value: 'Saint-Cyr-Sur-Loire' },
  { label: 'Saint-Cyr-Sur-Menthon', value: 'Saint-Cyr-Sur-Menthon' },
  { label: 'Saint-Cyr-Sur-Mer', value: 'Saint-Cyr-Sur-Mer' },
  { label: 'Saint-Cyr-Sur-Morin', value: 'Saint-Cyr-Sur-Morin' },
  { label: 'Saint-Damase', value: 'Saint-Damase' },
  { label: 'Saint-Denis', value: 'Saint-Denis' },
  { label: 'Saint-Denis', value: 'Saint-Denis' },
  { label: 'Saint-Denis-D’Oléron', value: 'Saint-Denis-D’Oléron' },
  { label: 'Saint-Denis-De-Cabanne', value: 'Saint-Denis-De-Cabanne' },
  { label: 'Saint-Denis-De-Gastines', value: 'Saint-Denis-De-Gastines' },
  { label: 'Saint-Denis-De-Pile', value: 'Saint-Denis-De-Pile' },
  { label: 'Saint-Denis-En-Bugey', value: 'Saint-Denis-En-Bugey' },
  { label: 'Saint-Denis-En-Val', value: 'Saint-Denis-En-Val' },
  { label: 'Saint-Denis-La-Chevasse', value: 'Saint-Denis-La-Chevasse' },
  { label: 'Saint-Denis-Lès-Bourg', value: 'Saint-Denis-Lès-Bourg' },
  { label: 'Saint-Denis-Les-Ponts', value: 'Saint-Denis-Les-Ponts' },
  { label: 'Saint-Denis-Sur-Richelieu', value: 'Saint-Denis-Sur-Richelieu' },
  { label: 'Saint-Denis-Sur-Sarthon', value: 'Saint-Denis-Sur-Sarthon' },
  { label: 'Saint-Désir', value: 'Saint-Désir' },
  { label: 'Saint-Didier', value: 'Saint-Didier' },
  { label: 'Saint-Didier', value: 'Saint-Didier' },
  { label: 'Saint-Didier-De-Formans', value: 'Saint-Didier-De-Formans' },
  { label: 'Saint-Didier-De-La-Tour', value: 'Saint-Didier-De-La-Tour' },
  { label: 'Saint-Didier-En-Velay', value: 'Saint-Didier-En-Velay' },
  { label: 'Saint-Didier-Sous-Riverie', value: 'Saint-Didier-Sous-Riverie' },
  { label: 'Saint-Didier-Sur-Chalaronne', value: 'Saint-Didier-Sur-Chalaronne' },
  { label: 'Saint-Dié-Des-Vosges', value: 'Saint-Dié-Des-Vosges' },
  { label: 'Saint-Dizier', value: 'Saint-Dizier' },
  { label: 'Saint-Dolay', value: 'Saint-Dolay' },
  { label: 'Saint-Domineuc', value: 'Saint-Domineuc' },
  { label: 'Saint-Donan', value: 'Saint-Donan' },
  { label: 'Saint-Donat-De-Montcalm', value: 'Saint-Donat-De-Montcalm' },
  { label: 'Saint-Doulchard', value: 'Saint-Doulchard' },
  { label: 'Saint-Drézéry', value: 'Saint-Drézéry' },
  { label: 'Saint-Dyé-Sur-Loire', value: 'Saint-Dyé-Sur-Loire' },
  { label: 'Sainte Catherine De La Jacques Cartier', value: 'Sainte Catherine De La Jacques Cartier' },
  { label: 'Sainte Genevieve', value: 'Sainte Genevieve' },
  { label: 'Sainte Genevieve County', value: 'Sainte Genevieve County' },
  { label: 'Sainte-Adèle', value: 'Sainte-Adèle' },
  { label: 'Sainte-Adresse', value: 'Sainte-Adresse' },
  { label: 'Sainte-Agathe-Des-Monts', value: 'Sainte-Agathe-Des-Monts' },
  { label: 'Sainte-Agnès', value: 'Sainte-Agnès' },
  { label: 'Sainte-Anastasie-Sur-Issole', value: 'Sainte-Anastasie-Sur-Issole' },
  { label: 'Sainte-Anne', value: 'Sainte-Anne' },
  { label: 'Sainte-Anne-Dauray', value: 'Sainte-Anne-Dauray' },
  { label: 'Sainte-Anne-De-Bellevue', value: 'Sainte-Anne-De-Bellevue' },
  { label: 'Sainte-Anne-Des-Monts', value: 'Sainte-Anne-Des-Monts' },
  { label: 'Sainte-Anne-Des-Plaines', value: 'Sainte-Anne-Des-Plaines' },
  { label: 'Sainte-Anne-Sur-Brivet', value: 'Sainte-Anne-Sur-Brivet' },
  { label: 'Sainte-Bazeille', value: 'Sainte-Bazeille' },
  { label: 'Sainte-Béatrix', value: 'Sainte-Béatrix' },
  { label: 'Sainte-Catherine', value: 'Sainte-Catherine' },
  { label: 'Sainte-Catherine', value: 'Sainte-Catherine' },
  { label: 'Sainte-Cécile', value: 'Sainte-Cécile' },
  { label: 'Sainte-Cécile-Les-Vignes', value: 'Sainte-Cécile-Les-Vignes' },
  { label: 'Sainte-Colombe', value: 'Sainte-Colombe' },
  { label: 'Sainte-Colombe', value: 'Sainte-Colombe' },
  { label: 'Sainte-Colombe-En-Bruilhois', value: 'Sainte-Colombe-En-Bruilhois' },
  { label: 'Sainte-Consorce', value: 'Sainte-Consorce' },
  { label: 'Sainte-Croix', value: 'Sainte-Croix' },
  { label: 'Sainte-Croix', value: 'Sainte-Croix' },
  { label: 'Sainte-Croix-Aux-Mines', value: 'Sainte-Croix-Aux-Mines' },
  { label: 'Sainte-Croix-En-Plaine', value: 'Sainte-Croix-En-Plaine' },
  { label: 'Saint-Édouard', value: 'Saint-Édouard' },
  { label: 'Sainte-Élisabeth', value: 'Sainte-Élisabeth' },
  { label: 'Sainte-Eulalie', value: 'Sainte-Eulalie' },
  { label: 'Sainte-Euphémie', value: 'Sainte-Euphémie' },
  { label: 'Sainte-Féréole', value: 'Sainte-Féréole' },
  { label: 'Sainte-Feyre', value: 'Sainte-Feyre' },
  { label: 'Sainte-Flaive-Des-Loups', value: 'Sainte-Flaive-Des-Loups' },
  { label: 'Sainte-Florine', value: 'Sainte-Florine' },
  { label: 'Sainte-Fortunade', value: 'Sainte-Fortunade' },
  { label: 'Sainte-Foy', value: 'Sainte-Foy' },
  { label: 'Sainte-Foy-De-Peyrolières', value: 'Sainte-Foy-De-Peyrolières' },
  { label: 'Sainte-Foy-L’Argentière', value: 'Sainte-Foy-L’Argentière' },
  { label: 'Sainte-Foy-La-Grande', value: 'Sainte-Foy-La-Grande' },
  { label: 'Sainte-Foy-Lès-Lyon', value: 'Sainte-Foy-Lès-Lyon' },
  { label: 'Sainte-Gauburge-Sainte-Colombe', value: 'Sainte-Gauburge-Sainte-Colombe' },
  { label: 'Sainte-Gemme-La-Plaine', value: 'Sainte-Gemme-La-Plaine' },
  { label: 'Sainte-Gemmes-Sur-Loire', value: 'Sainte-Gemmes-Sur-Loire' },
  { label: 'Sainte-Geneviève', value: 'Sainte-Geneviève' },
  { label: 'Sainte-Geneviève-Des-Bois', value: 'Sainte-Geneviève-Des-Bois' },
  { label: 'Sainte-Geneviève-Des-Bois', value: 'Sainte-Geneviève-Des-Bois' },
  { label: 'Sainte-Geneviève-Sur-Argence', value: 'Sainte-Geneviève-Sur-Argence' },
  { label: 'Saint-Égrève', value: 'Saint-Égrève' },
  { label: 'Sainte-Hélène', value: 'Sainte-Hélène' },
  { label: 'Sainte-Hélène', value: 'Sainte-Hélène' },
  { label: 'Sainte-Hélène-Sur-Isère', value: 'Sainte-Hélène-Sur-Isère' },
  { label: 'Sainte-Hermine', value: 'Sainte-Hermine' },
  { label: 'Sainte-Honorine-Du-Fay', value: 'Sainte-Honorine-Du-Fay' },
  { label: 'Sainte-Jamme-Sur-Sarthe', value: 'Sainte-Jamme-Sur-Sarthe' },
  { label: 'Sainte-Julie', value: 'Sainte-Julie' },
  { label: 'Sainte-Julienne', value: 'Sainte-Julienne' },
  { label: 'Sainte-Livrade-Sur-Lot', value: 'Sainte-Livrade-Sur-Lot' },
  { label: 'Sainte-Lizaigne', value: 'Sainte-Lizaigne' },
  { label: 'Saint-Éloi', value: 'Saint-Éloi' },
  { label: 'Saint-Éloy-De-Gy', value: 'Saint-Éloy-De-Gy' },
  { label: 'Saint-Éloy-Les-Mines', value: 'Saint-Éloy-Les-Mines' },
  { label: 'Sainte-Luce-Sur-Loire', value: 'Sainte-Luce-Sur-Loire' },
  { label: 'Saint-Elzéar', value: 'Saint-Elzéar' },
  { label: 'Sainte-Madeleine', value: 'Sainte-Madeleine' },
  { label: 'Sainte-Marguerite', value: 'Sainte-Marguerite' },
  { label: 'Sainte-Marguerite', value: 'Sainte-Marguerite' },
  { label: 'Sainte-Marguerite-Sur-Duclair', value: 'Sainte-Marguerite-Sur-Duclair' },
  { label: 'Sainte-Marie', value: 'Sainte-Marie' },
  { label: 'Sainte-Marie', value: 'Sainte-Marie' },
  { label: 'Sainte-Marie-Aux-Chênes', value: 'Sainte-Marie-Aux-Chênes' },
  { label: 'Sainte-Marie-Aux-Mines', value: 'Sainte-Marie-Aux-Mines' },
  { label: 'Sainte-Marie-De-Gosse', value: 'Sainte-Marie-De-Gosse' },
  { label: 'Sainte-Marie-De-Ré', value: 'Sainte-Marie-De-Ré' },
  { label: 'Sainte-Marie-Des-Champs', value: 'Sainte-Marie-Des-Champs' },
  { label: 'Sainte-Marie-Kerque', value: 'Sainte-Marie-Kerque' },
  { label: 'Sainte-Marie-Plage', value: 'Sainte-Marie-Plage' },
  { label: 'Sainte-Marthe', value: 'Sainte-Marthe' },
  { label: 'Sainte-Marthe-Sur-Le-Lac', value: 'Sainte-Marthe-Sur-Le-Lac' },
  { label: 'Sainte-Martine', value: 'Sainte-Martine' },
  { label: 'Sainte-Maure', value: 'Sainte-Maure' },
  { label: 'Sainte-Maure-De-Touraine', value: 'Sainte-Maure-De-Touraine' },
  { label: 'Sainte-Maxime', value: 'Sainte-Maxime' },
  { label: 'Sainte-Menehould', value: 'Sainte-Menehould' },
  { label: 'Sainte-Mère-Église', value: 'Sainte-Mère-Église' },
  { label: 'Saint-Émilion', value: 'Saint-Émilion' },
  { label: 'Sainte-Ode', value: 'Sainte-Ode' },
  { label: 'Saint-Épain', value: 'Saint-Épain' },
  { label: 'Sainte-Pazanne', value: 'Sainte-Pazanne' },
  { label: 'Saint-Éphrem-De-Beauce', value: 'Saint-Éphrem-De-Beauce' },
  { label: 'Sainte-Radegonde', value: 'Sainte-Radegonde' },
  { label: 'Sainte-Radegonde', value: 'Sainte-Radegonde' },
  { label: 'Saint-Erblon', value: 'Saint-Erblon' },
  { label: 'Sainte-Reine-De-Bretagne', value: 'Sainte-Reine-De-Bretagne' },
  { label: 'Saint-Erme-Outre-Et-Ramecourt', value: 'Saint-Erme-Outre-Et-Ramecourt' },
  { label: 'Saintes', value: 'Saintes' },
  { label: 'Sainte-Savine', value: 'Sainte-Savine' },
  { label: 'Sainte-Sigolène', value: 'Sainte-Sigolène' },
  { label: 'Saintes-Maries-De-La-Mer', value: 'Saintes-Maries-De-La-Mer' },
  { label: 'Sainte-Solange', value: 'Sainte-Solange' },
  { label: 'Sainte-Sophie', value: 'Sainte-Sophie' },
  { label: 'Sainte-Soulle', value: 'Sainte-Soulle' },
  { label: 'Saint-Estèphe', value: 'Saint-Estèphe' },
  { label: 'Saint-Estève', value: 'Saint-Estève' },
  { label: 'Sainte-Suzanne', value: 'Sainte-Suzanne' },
  { label: 'Sainte-Suzanne', value: 'Sainte-Suzanne' },
  { label: 'Sainte-Terre', value: 'Sainte-Terre' },
  { label: 'Sainte-Thècle', value: 'Sainte-Thècle' },
  { label: 'Sainte-Thérèse', value: 'Sainte-Thérèse' },
  { label: 'Saint-Étienne', value: 'Saint-Étienne' },
  { label: 'Saint-Étienne-Au-Mont', value: 'Saint-Étienne-Au-Mont' },
  { label: 'Saint-Étienne-De-Baïgorry', value: 'Saint-Étienne-De-Baïgorry' },
  { label: 'Saint-Étienne-De-Chigny', value: 'Saint-Étienne-De-Chigny' },
  { label: 'Saint-Étienne-De-Crossey', value: 'Saint-Étienne-De-Crossey' },
  { label: 'Saint-Etienne-De-Cuines', value: 'Saint-Etienne-De-Cuines' },
  { label: 'Saint-Étienne-De-Fontbellon', value: 'Saint-Étienne-De-Fontbellon' },
  { label: 'Saint-Étienne-De-Mer-Morte', value: 'Saint-Étienne-De-Mer-Morte' },
  { label: 'Saint-Étienne-De-Montluc', value: 'Saint-Étienne-De-Montluc' },
  { label: 'Saint-Étienne-De-Saint-Geoirs', value: 'Saint-Étienne-De-Saint-Geoirs' },
  { label: 'Saint-Étienne-Des-Oullières', value: 'Saint-Étienne-Des-Oullières' },
  { label: 'Saint-Étienne-De-Tinée', value: 'Saint-Étienne-De-Tinée' },
  { label: 'Saint-Etienne-De-Tulmont', value: 'Saint-Etienne-De-Tulmont' },
  { label: 'Saint-Étienne-Du-Bois', value: 'Saint-Étienne-Du-Bois' },
  { label: 'Saint-Étienne-Du-Bois', value: 'Saint-Étienne-Du-Bois' },
  { label: 'Saint-Étienne-Du-Grès', value: 'Saint-Étienne-Du-Grès' },
  { label: 'Saint-Étienne-Du-Rouvray', value: 'Saint-Étienne-Du-Rouvray' },
  { label: 'Saint-Étienne-En-Coglès', value: 'Saint-Étienne-En-Coglès' },
  { label: 'Saint-Étienne-Lès-Remiremont', value: 'Saint-Étienne-Lès-Remiremont' },
  { label: 'Saint-Étienne-Sur-Chalaronne', value: 'Saint-Étienne-Sur-Chalaronne' },
  { label: 'Sainte-Tulle', value: 'Sainte-Tulle' },
  { label: 'Saint-Eusèbe', value: 'Saint-Eusèbe' },
  { label: 'Saint-Eustache', value: 'Saint-Eustache' },
  { label: 'Saint-Eustache-La-Forêt', value: 'Saint-Eustache-La-Forêt' },
  { label: 'Saint-Évarzec', value: 'Saint-Évarzec' },
  { label: 'Sainte-Verge', value: 'Sainte-Verge' },
  { label: 'Saint-Fargeau', value: 'Saint-Fargeau' },
  { label: 'Saint-Fargeau-Ponthierry', value: 'Saint-Fargeau-Ponthierry' },
  { label: 'Saint-Félicien', value: 'Saint-Félicien' },
  { label: 'Saint-Félicien', value: 'Saint-Félicien' },
  { label: 'Saint-Féliu-D’Avall', value: 'Saint-Féliu-D’Avall' },
  { label: 'Saint-Félix', value: 'Saint-Félix' },
  { label: 'Saint-Félix-De-Valois', value: 'Saint-Félix-De-Valois' },
  { label: 'Saint-Félix-Lauragais', value: 'Saint-Félix-Lauragais' },
  { label: 'Saint-Fiacre-Sur-Maine', value: 'Saint-Fiacre-Sur-Maine' },
  { label: 'Saintfield', value: 'Saintfield' },
  { label: 'Saint-Florent', value: 'Saint-Florent' },
  { label: 'Saint-Florent-Des-Bois', value: 'Saint-Florent-Des-Bois' },
  { label: 'Saint-Florentin', value: 'Saint-Florentin' },
  { label: 'Saint-Florent-Le-Vieil', value: 'Saint-Florent-Le-Vieil' },
  { label: 'Saint-Florent-Sur-Auzonnet', value: 'Saint-Florent-Sur-Auzonnet' },
  { label: 'Saint-Florent-Sur-Cher', value: 'Saint-Florent-Sur-Cher' },
  { label: 'Saint-Flour', value: 'Saint-Flour' },
  { label: 'Saint-Folquin', value: 'Saint-Folquin' },
  { label: 'Saint-Fons', value: 'Saint-Fons' },
  { label: 'Saint-Forgeux', value: 'Saint-Forgeux' },
  { label: 'Saint-Fort', value: 'Saint-Fort' },
  { label: 'Saint-François', value: 'Saint-François' },
  { label: 'Saint-Fulgent', value: 'Saint-Fulgent' },
  { label: 'Saint-Gabriel', value: 'Saint-Gabriel' },
  { label: 'Saint-Galmier', value: 'Saint-Galmier' },
  { label: 'Saint-Gatien-Des-Bois', value: 'Saint-Gatien-Des-Bois' },
  { label: 'Saint-Gaudens', value: 'Saint-Gaudens' },
  { label: 'Saint-Gaultier', value: 'Saint-Gaultier' },
  { label: 'Saint-Gédéon', value: 'Saint-Gédéon' },
  { label: 'Saint-Gelais', value: 'Saint-Gelais' },
  { label: 'Saint-Gély-Du-Fesc', value: 'Saint-Gély-Du-Fesc' },
  { label: 'Saint-Gence', value: 'Saint-Gence' },
  { label: 'Saint-Genès-Champanelle', value: 'Saint-Genès-Champanelle' },
  { label: 'Saint-Genest-Lerpt', value: 'Saint-Genest-Lerpt' },
  { label: 'Saint-Genest-Malifaux', value: 'Saint-Genest-Malifaux' },
  { label: 'Saint-Gengoux-Le-National', value: 'Saint-Gengoux-Le-National' },
  { label: 'Saint-Geniès-Bellevue', value: 'Saint-Geniès-Bellevue' },
  { label: 'Saint-Geniès-De-Comolas', value: 'Saint-Geniès-De-Comolas' },
  { label: 'Saint-Geniès-De-Fontedit', value: 'Saint-Geniès-De-Fontedit' },
  { label: 'Saint-Geniès-De-Malgoirès', value: 'Saint-Geniès-De-Malgoirès' },
  { label: 'Saint-Geniès-Des-Mourgues', value: 'Saint-Geniès-Des-Mourgues' },
  { label: 'Saint-Geniez-D’Olt', value: 'Saint-Geniez-D’Olt' },
  { label: 'Saint-Genis-De-Saintonge', value: 'Saint-Genis-De-Saintonge' },
  { label: 'Saint-Génis-Des-Fontaines', value: 'Saint-Génis-Des-Fontaines' },
  { label: 'Saint-Genis-Laval', value: 'Saint-Genis-Laval' },
  { label: 'Saint-Genis-Les-Ollières', value: 'Saint-Genis-Les-Ollières' },
  { label: 'Saint-Genis-Pouilly', value: 'Saint-Genis-Pouilly' },
  { label: 'Saint-Genix-Sur-Guiers', value: 'Saint-Genix-Sur-Guiers' },
  { label: 'Saint-Geoire-En-Valdaine', value: 'Saint-Geoire-En-Valdaine' },
  { label: 'Saint-Georges', value: 'Saint-Georges' },
  { label: 'Saint-Georges-Buttavent', value: 'Saint-Georges-Buttavent' },
  { label: 'Saint-Georges-De-Commiers', value: 'Saint-Georges-De-Commiers' },
  { label: 'Saint-Georges-De-Didonne', value: 'Saint-Georges-De-Didonne' },
  { label: 'Saint-Georges-De-Luzençon', value: 'Saint-Georges-De-Luzençon' },
  { label: 'Saint-Georges-De-Mons', value: 'Saint-Georges-De-Mons' },
  { label: 'Saint-Georges-De-Montaigu', value: 'Saint-Georges-De-Montaigu' },
  { label: 'Saint-Georges-De-Pointindoux', value: 'Saint-Georges-De-Pointindoux' },
  { label: 'Saint-Georges-De-Reintembault', value: 'Saint-Georges-De-Reintembault' },
  { label: 'Saint-Georges-De-Reneins', value: 'Saint-Georges-De-Reneins' },
  { label: 'Saint-Georges-Des-Coteaux', value: 'Saint-Georges-Des-Coteaux' },
  { label: 'Saint-Georges-Des-Groseillers', value: 'Saint-Georges-Des-Groseillers' },
  { label: 'Saint-Georges-Du-Bois', value: 'Saint-Georges-Du-Bois' },
  { label: 'Saint-Georges-Du-Bois', value: 'Saint-Georges-Du-Bois' },
  { label: 'Saint-Georges-Haute-Ville', value: 'Saint-Georges-Haute-Ville' },
  { label: 'Saint-Georges-Lès-Baillargeaux', value: 'Saint-Georges-Lès-Baillargeaux' },
  { label: 'Saint-Georges-Les-Bains', value: 'Saint-Georges-Les-Bains' },
  { label: 'Saint-Georges-Sur-Allier', value: 'Saint-Georges-Sur-Allier' },
  { label: 'Saint-Georges-Sur-Baulche', value: 'Saint-Georges-Sur-Baulche' },
  { label: 'Saint-Georges-Sur-Cher', value: 'Saint-Georges-Sur-Cher' },
  { label: 'Saint-Georges-Sur-Eure', value: 'Saint-Georges-Sur-Eure' },
  { label: 'Saint-Georges-Sur-Loire', value: 'Saint-Georges-Sur-Loire' },
  { label: 'Saint-Geours-De-Maremne', value: 'Saint-Geours-De-Maremne' },
  { label: 'Saint-Gérand-Le-Puy', value: 'Saint-Gérand-Le-Puy' },
  { label: 'Saint-Géréon', value: 'Saint-Géréon' },
  { label: 'Saint-Germain', value: 'Saint-Germain' },
  { label: 'Saint-Germain', value: 'Saint-Germain' },
  { label: 'Saint-Germain-De-Grantham', value: 'Saint-Germain-De-Grantham' },
  { label: 'Saint-Germain-De-La-Grange', value: 'Saint-Germain-De-La-Grange' },
  { label: 'Saint-Germain-De-Lusignan', value: 'Saint-Germain-De-Lusignan' },
  { label: 'Saint-Germain-De-Marencennes', value: 'Saint-Germain-De-Marencennes' },
  { label: 'Saint-Germain-De-Prinçay', value: 'Saint-Germain-De-Prinçay' },
  { label: 'Saint-Germain-Des-Fossés', value: 'Saint-Germain-Des-Fossés' },
  { label: 'Saint-Germain-Des-Prés', value: 'Saint-Germain-Des-Prés' },
  { label: 'Saint-Germain-Des-Prés', value: 'Saint-Germain-Des-Prés' },
  { label: 'Saint-Germain-Du-Bois', value: 'Saint-Germain-Du-Bois' },
  { label: 'Saint-Germain-Du-Corbéis', value: 'Saint-Germain-Du-Corbéis' },
  { label: 'Saint-Germain-Du-Plain', value: 'Saint-Germain-Du-Plain' },
  { label: 'Saint-Germain-Du-Puch', value: 'Saint-Germain-Du-Puch' },
  { label: 'Saint-Germain-Du-Puy', value: 'Saint-Germain-Du-Puy' },
  { label: 'Saint-Germain-En-Coglès', value: 'Saint-Germain-En-Coglès' },
  { label: 'Saint-Germain-En-Laye', value: 'Saint-Germain-En-Laye' },
  { label: 'Saint-Germain-La-Blanche-Herbe', value: 'Saint-Germain-La-Blanche-Herbe' },
  { label: 'Saint-Germain-Laprade', value: 'Saint-Germain-Laprade' },
  { label: 'Saint-Germain-Laval', value: 'Saint-Germain-Laval' },
  { label: 'Saint-Germain-Laval', value: 'Saint-Germain-Laval' },
  { label: 'Saint-Germain-Lembron', value: 'Saint-Germain-Lembron' },
  { label: 'Saint-Germain-Lès-Arpajon', value: 'Saint-Germain-Lès-Arpajon' },
  { label: 'Saint-Germain-Les-Belles', value: 'Saint-Germain-Les-Belles' },
  { label: 'Saint-Germain-Lès-Corbeil', value: 'Saint-Germain-Lès-Corbeil' },
  { label: 'Saint-Germain-Lespinasse', value: 'Saint-Germain-Lespinasse' },
  { label: 'Saint-Germain-Nuelles', value: 'Saint-Germain-Nuelles' },
  { label: 'Saint-Germain-Sur-Avre', value: 'Saint-Germain-Sur-Avre' },
  { label: 'Saint-Germain-Sur-Moine', value: 'Saint-Germain-Sur-Moine' },
  { label: 'Saint-Germain-Sur-Morin', value: 'Saint-Germain-Sur-Morin' },
  { label: 'Saint-Germain-Village', value: 'Saint-Germain-Village' },
  { label: 'Saint-Germer-De-Fly', value: 'Saint-Germer-De-Fly' },
  { label: 'Saint-Gervais', value: 'Saint-Gervais' },
  { label: 'Saint-Gervais', value: 'Saint-Gervais' },
  { label: 'Saint-Gervais-D’Auvergne', value: 'Saint-Gervais-D’Auvergne' },
  { label: 'Saint-Gervais-En-Belin', value: 'Saint-Gervais-En-Belin' },
  { label: 'Saint-Gervais-La-Forêt', value: 'Saint-Gervais-La-Forêt' },
  { label: 'Saint-Gervais-Les-Bains', value: 'Saint-Gervais-Les-Bains' },
  { label: 'Saint-Gervais-Les-Trois-Clochers', value: 'Saint-Gervais-Les-Trois-Clochers' },
  { label: 'Saint-Gervasy', value: 'Saint-Gervasy' },
  { label: 'Saint-Ghislain', value: 'Saint-Ghislain' },
  { label: 'Saint-Gildas-De-Rhuys', value: 'Saint-Gildas-De-Rhuys' },
  { label: 'Saint-Gildas-Des-Bois', value: 'Saint-Gildas-Des-Bois' },
  { label: 'Saint-Gilles', value: 'Saint-Gilles' },
  { label: 'Saint-Gilles', value: 'Saint-Gilles' },
  { label: 'Saint-Gilles-Croix-De-Vie', value: 'Saint-Gilles-Croix-De-Vie' },
  { label: 'Saint-Giniez', value: 'Saint-Giniez' },
  { label: 'Saint-Girons', value: 'Saint-Girons' },
  { label: 'Saint-Gobain', value: 'Saint-Gobain' },
  { label: 'Saint-Gonnery', value: 'Saint-Gonnery' },
  { label: 'Saint-Gratien', value: 'Saint-Gratien' },
  { label: 'Saint-Grégoire', value: 'Saint-Grégoire' },
  { label: 'Saint-Héand', value: 'Saint-Héand' },
  { label: 'Saint-Hélen', value: 'Saint-Hélen' },
  { label: 'Saint-Henri', value: 'Saint-Henri' },
  { label: 'Saint-Henri', value: 'Saint-Henri' },
  { label: 'Saint-Herblain', value: 'Saint-Herblain' },
  { label: 'Saint-Herblon', value: 'Saint-Herblon' },
  { label: 'Sainthia', value: 'Sainthia' },
  { label: 'Saint-Hilaire', value: 'Saint-Hilaire' },
  { label: 'Saint-Hilaire', value: 'Saint-Hilaire' },
  { label: 'Saint-Hilaire-De-Brethmas', value: 'Saint-Hilaire-De-Brethmas' },
  { label: 'Saint-Hilaire-De-Chaléons', value: 'Saint-Hilaire-De-Chaléons' },
  { label: 'Saint-Hilaire-De-Clisson', value: 'Saint-Hilaire-De-Clisson' },
  { label: 'Saint-Hilaire-De-La-Côte', value: 'Saint-Hilaire-De-La-Côte' },
  { label: 'Saint-Hilaire-De-Loulay', value: 'Saint-Hilaire-De-Loulay' },
  { label: 'Saint-Hilaire-De-Riez', value: 'Saint-Hilaire-De-Riez' },
  { label: 'Saint-Hilaire-Des-Loges', value: 'Saint-Hilaire-Des-Loges' },
  { label: 'Saint-Hilaire-De-Talmont', value: 'Saint-Hilaire-De-Talmont' },
  { label: 'Saint-Hilaire-De-Villefranche', value: 'Saint-Hilaire-De-Villefranche' },
  { label: 'Saint-Hilaire-Du-Harcouët', value: 'Saint-Hilaire-Du-Harcouët' },
  { label: 'Saint-Hilaire-Du-Rosier', value: 'Saint-Hilaire-Du-Rosier' },
  { label: 'Saint-Hilaire-La-Palud', value: 'Saint-Hilaire-La-Palud' },
  { label: 'Saint-Hilaire-Lez-Cambrai', value: 'Saint-Hilaire-Lez-Cambrai' },
  { label: 'Saint-Hilaire-Petitville', value: 'Saint-Hilaire-Petitville' },
  { label: 'Saint-Hilaire-Saint-Mesmin', value: 'Saint-Hilaire-Saint-Mesmin' },
  { label: 'Saint-Hippolyte', value: 'Saint-Hippolyte' },
  { label: 'Saint-Hippolyte', value: 'Saint-Hippolyte' },
  { label: 'Saint-Hippolyte', value: 'Saint-Hippolyte' },
  { label: 'Saint-Hippolyte', value: 'Saint-Hippolyte' },
  { label: 'Saint-Hippolyte', value: 'Saint-Hippolyte' },
  { label: 'Saint-Hippolyte-Du-Fort', value: 'Saint-Hippolyte-Du-Fort' },
  { label: 'Saint-Honoré', value: 'Saint-Honoré' },
  { label: 'Saint-Hubert', value: 'Saint-Hubert' },
  { label: 'Saint-Hyacinthe', value: 'Saint-Hyacinthe' },
  { label: 'Saint-Imier', value: 'Saint-Imier' },
  { label: 'Saint-Isidore', value: 'Saint-Isidore' },
  { label: 'Saint-Ismier', value: 'Saint-Ismier' },
  { label: 'Saint-Jacques-De-La-Lande', value: 'Saint-Jacques-De-La-Lande' },
  { label: 'Saint-Jacques-Le-Mineur', value: 'Saint-Jacques-Le-Mineur' },
  { label: 'Saint-Jacques-Sur-Darnétal', value: 'Saint-Jacques-Sur-Darnétal' },
  { label: 'Saint-Jacut-Les-Pins', value: 'Saint-Jacut-Les-Pins' },
  { label: 'Saint-James', value: 'Saint-James' },
  { label: 'Saint-Jans-Cappel', value: 'Saint-Jans-Cappel' },
  { label: 'Saint-Jean', value: 'Saint-Jean' },
  { label: 'Saint-Jean', value: 'Saint-Jean' },
  { label: 'Saint-Jean Du Désert', value: 'Saint-Jean Du Désert' },
  { label: 'Saint-Jean-Baptiste', value: 'Saint-Jean-Baptiste' },
  { label: 'Saint-Jean-Bonnefonds', value: 'Saint-Jean-Bonnefonds' },
  { label: 'Saint-Jean-Brévelay', value: 'Saint-Jean-Brévelay' },
  { label: 'Saint-Jean-Cap-Ferrat', value: 'Saint-Jean-Cap-Ferrat' },
  { label: 'Saint-Jean-D’Aulps', value: 'Saint-Jean-D’Aulps' },
  { label: 'Saint-Jean-D’Illac', value: 'Saint-Jean-D’Illac' },
  { label: 'Saint-Jean-De-Boiseau', value: 'Saint-Jean-De-Boiseau' },
  { label: 'Saint-Jean-De-Bournay', value: 'Saint-Jean-De-Bournay' },
  { label: 'Saint-Jean-De-Braye', value: 'Saint-Jean-De-Braye' },
  { label: 'Saint-Jean-De-Fos', value: 'Saint-Jean-De-Fos' },
  { label: 'Saint-Jean-De-Gonville', value: 'Saint-Jean-De-Gonville' },
  { label: 'Saint-Jean-De-La-Ruelle', value: 'Saint-Jean-De-La-Ruelle' },
  { label: 'Saint-Jean-De-Liversay', value: 'Saint-Jean-De-Liversay' },
  { label: 'Saint-Jean-De-Losne', value: 'Saint-Jean-De-Losne' },
  { label: 'Saint-Jean-De-Luz', value: 'Saint-Jean-De-Luz' },
  { label: 'Saint-Jean-De-Maurienne', value: 'Saint-Jean-De-Maurienne' },
  { label: 'Saint-Jean-De-Moirans', value: 'Saint-Jean-De-Moirans' },
  { label: 'Saint-Jean-De-Monts', value: 'Saint-Jean-De-Monts' },
  { label: 'Saint-Jean-De-Muzols', value: 'Saint-Jean-De-Muzols' },
  { label: 'Saint-Jean-De-Niost', value: 'Saint-Jean-De-Niost' },
  { label: 'Saint-Jean-De-Sauves', value: 'Saint-Jean-De-Sauves' },
  { label: 'Saint-Jean-Des-Baisants', value: 'Saint-Jean-Des-Baisants' },
  { label: 'Saint-Jean-Des-Champs', value: 'Saint-Jean-Des-Champs' },
  { label: 'Saint-Jean-De-Sixt', value: 'Saint-Jean-De-Sixt' },
  { label: 'Saint-Jean-Des-Mauvrets', value: 'Saint-Jean-Des-Mauvrets' },
  { label: 'Saint-Jean-De-Soudain', value: 'Saint-Jean-De-Soudain' },
  { label: 'Saint-Jean-De-Thouars', value: 'Saint-Jean-De-Thouars' },
  { label: 'Saint-Jean-De-Védas', value: 'Saint-Jean-De-Védas' },
  { label: 'Saint-Jean-Du-Cardonnay', value: 'Saint-Jean-Du-Cardonnay' },
  { label: 'Saint-Jean-Du-Falga', value: 'Saint-Jean-Du-Falga' },
  { label: 'Saint-Jean-Du-Gard', value: 'Saint-Jean-Du-Gard' },
  { label: 'Saint-Jean-En-Royans', value: 'Saint-Jean-En-Royans' },
  { label: 'Saint-Jean-La-Poterie', value: 'Saint-Jean-La-Poterie' },
  { label: 'Saint-Jean-Le-Blanc', value: 'Saint-Jean-Le-Blanc' },
  { label: 'Saint-Jean-Les-Deux-Jumeaux', value: 'Saint-Jean-Les-Deux-Jumeaux' },
  { label: 'Saint-Jean-Le-Vieux', value: 'Saint-Jean-Le-Vieux' },
  { label: 'Saint-Jeannet', value: 'Saint-Jeannet' },
  { label: 'Saint-Jean-Pied-De-Port', value: 'Saint-Jean-Pied-De-Port' },
  { label: 'Saint-Jean-Sur-Couesnon', value: 'Saint-Jean-Sur-Couesnon' },
  { label: 'Saint-Jean-Sur-Mayenne', value: 'Saint-Jean-Sur-Mayenne' },
  { label: 'Saint-Jean-Sur-Richelieu', value: 'Saint-Jean-Sur-Richelieu' },
  { label: 'Saint-Jean-Sur-Veyle', value: 'Saint-Jean-Sur-Veyle' },
  { label: 'Saint-Jeoire', value: 'Saint-Jeoire' },
  { label: 'Saint-Jérôme', value: 'Saint-Jérôme' },
  { label: 'Saint-Jérôme', value: 'Saint-Jérôme' },
  { label: 'Saint-Joachim', value: 'Saint-Joachim' },
  { label: 'Saint-Jorioz', value: 'Saint-Jorioz' },
  { label: 'Saint-Jory', value: 'Saint-Jory' },
  { label: 'Saint-Joseph', value: 'Saint-Joseph' },
  { label: 'Saint-Joseph', value: 'Saint-Joseph' },
  { label: 'Saint-Joseph', value: 'Saint-Joseph' },
  { label: 'Saint-Joseph-De-Beauce', value: 'Saint-Joseph-De-Beauce' },
  { label: 'Saint-Joseph-De-Coleraine', value: 'Saint-Joseph-De-Coleraine' },
  { label: 'Saint-Joseph-De-Rivière', value: 'Saint-Joseph-De-Rivière' },
  { label: 'Saint-Joseph-Du-Lac', value: 'Saint-Joseph-Du-Lac' },
  { label: 'Saint-Josse', value: 'Saint-Josse' },
  { label: 'Saint-Jouan-Des-Guérets', value: 'Saint-Jouan-Des-Guérets' },
  { label: 'Saint-Jouin-Bruneval', value: 'Saint-Jouin-Bruneval' },
  { label: 'Saint-Jouvent', value: 'Saint-Jouvent' },
  { label: 'Saint-Juéry', value: 'Saint-Juéry' },
  { label: 'Saint-Julien', value: 'Saint-Julien' },
  { label: 'Saint-Julien', value: 'Saint-Julien' },
  { label: 'Saint-Julien', value: 'Saint-Julien' },
  { label: 'Saint-Julien-Chapteuil', value: 'Saint-Julien-Chapteuil' },
  { label: 'Saint-Julien-De-Concelles', value: 'Saint-Julien-De-Concelles' },
  { label: 'Saint-Julien-De-Peyrolas', value: 'Saint-Julien-De-Peyrolas' },
  { label: 'Saint-Julien-Des-Landes', value: 'Saint-Julien-Des-Landes' },
  { label: 'Saint-Julien-Du-Sault', value: 'Saint-Julien-Du-Sault' },
  { label: 'Saint-Julien-En-Born', value: 'Saint-Julien-En-Born' },
  { label: 'Saint-Julien-En-Genevois', value: 'Saint-Julien-En-Genevois' },
  { label: 'Saint-Julien-En-Saint-Alban', value: 'Saint-Julien-En-Saint-Alban' },
  { label: 'Saint-Julien-Lès-Metz', value: 'Saint-Julien-Lès-Metz' },
  { label: 'Saint-Julien-Les-Rosiers', value: 'Saint-Julien-Les-Rosiers' },
  { label: 'Saint-Julien-Les-Villas', value: 'Saint-Julien-Les-Villas' },
  { label: 'Saint-Julien-Molin-Molette', value: 'Saint-Julien-Molin-Molette' },
  { label: 'Saint-Junien', value: 'Saint-Junien' },
  { label: 'Saint-Just', value: 'Saint-Just' },
  { label: 'Saint-Just', value: 'Saint-Just' },
  { label: 'Saint-Just', value: 'Saint-Just' },
  { label: 'Saint-Just', value: 'Saint-Just' },
  { label: 'Saint-Just-Chaleyssin', value: 'Saint-Just-Chaleyssin' },
  { label: 'Saint-Just-Dardèche', value: 'Saint-Just-Dardèche' },
  { label: 'Saint-Just-De-Claix', value: 'Saint-Just-De-Claix' },
  { label: 'Saint-Just-En-Chaussée', value: 'Saint-Just-En-Chaussée' },
  { label: 'Saint-Just-En-Chevalet', value: 'Saint-Just-En-Chevalet' },
  { label: 'Saint-Just-La-Pendue', value: 'Saint-Just-La-Pendue' },
  { label: 'Saint-Just-Le-Martel', value: 'Saint-Just-Le-Martel' },
  { label: 'Saint-Just-Luzac', value: 'Saint-Just-Luzac' },
  { label: 'Saint-Just-Malmont', value: 'Saint-Just-Malmont' },
  { label: 'Saint-Just-Saint-Rambert', value: 'Saint-Just-Saint-Rambert' },
  { label: 'Saint-Just-Sauvage', value: 'Saint-Just-Sauvage' },
  { label: 'Saint-Lambert', value: 'Saint-Lambert' },
  { label: 'Saint-Lambert-De-Lauzon', value: 'Saint-Lambert-De-Lauzon' },
  { label: 'Saint-Lambert-Du-Lattay', value: 'Saint-Lambert-Du-Lattay' },
  { label: 'Saint-Lambert-La-Potherie', value: 'Saint-Lambert-La-Potherie' },
  { label: 'Saint-Lary-Soulan', value: 'Saint-Lary-Soulan' },
  { label: 'Saint-Lattier', value: 'Saint-Lattier' },
  { label: 'Saint-Laurent', value: 'Saint-Laurent' },
  { label: 'Saint-Laurent', value: 'Saint-Laurent' },
  { label: 'Saint-Laurent-Blangy', value: 'Saint-Laurent-Blangy' },
  { label: 'Saint-Laurent-De-Brévedent', value: 'Saint-Laurent-De-Brévedent' },
  { label: 'Saint-Laurent-De-Chamousset', value: 'Saint-Laurent-De-Chamousset' },
  { label: 'Saint-Laurent-De-La-Plaine', value: 'Saint-Laurent-De-La-Plaine' },
  { label: 'Saint-Laurent-De-La-Prée', value: 'Saint-Laurent-De-La-Prée' },
  { label: 'Saint-Laurent-De-La-Salanque', value: 'Saint-Laurent-De-La-Salanque' },
  { label: 'Saint-Laurent-De-Mure', value: 'Saint-Laurent-De-Mure' },
  { label: 'Saint-Laurent-Des-Arbres', value: 'Saint-Laurent-Des-Arbres' },
  { label: 'Saint-Laurent-Des-Autels', value: 'Saint-Laurent-Des-Autels' },
  { label: 'Saint-Laurent-Du-Pape', value: 'Saint-Laurent-Du-Pape' },
  { label: 'Saint-Laurent-Du-Pont', value: 'Saint-Laurent-Du-Pont' },
  { label: 'Saint-Laurent-Du-Var', value: 'Saint-Laurent-Du-Var' },
  { label: 'Saint-Laurent-En-Grandvaux', value: 'Saint-Laurent-En-Grandvaux' },
  { label: 'Saint-Laurent-En-Royans', value: 'Saint-Laurent-En-Royans' },
  { label: 'Saint-Laurent-Nouan', value: 'Saint-Laurent-Nouan' },
  { label: 'Saint-Laurent-Sur-Gorre', value: 'Saint-Laurent-Sur-Gorre' },
  { label: 'Saint-Laurent-Sur-Saône', value: 'Saint-Laurent-Sur-Saône' },
  { label: 'Saint-Laurent-Sur-Sèvre', value: 'Saint-Laurent-Sur-Sèvre' },
  { label: 'Saint-Lazare', value: 'Saint-Lazare' },
  { label: 'Saint-Lazare', value: 'Saint-Lazare' },
  { label: 'Saint-Léger', value: 'Saint-Léger' },
  { label: 'Saint-Léger-De-Montbrun', value: 'Saint-Léger-De-Montbrun' },
  { label: 'Saint-Léger-Des-Bois', value: 'Saint-Léger-Des-Bois' },
  { label: 'Saint-Léger-Des-Vignes', value: 'Saint-Léger-Des-Vignes' },
  { label: 'Saint-Léger-Du-Bourg-Denis', value: 'Saint-Léger-Du-Bourg-Denis' },
  { label: 'Saint-Léger-En-Yvelines', value: 'Saint-Léger-En-Yvelines' },
  { label: 'Saint-Léger-Lès-Domart', value: 'Saint-Léger-Lès-Domart' },
  { label: 'Saint-Léger-Les-Vignes', value: 'Saint-Léger-Les-Vignes' },
  { label: 'Saint-Léger-Sous-Cholet', value: 'Saint-Léger-Sous-Cholet' },
  { label: 'Saint-Léger-Sur-Dheune', value: 'Saint-Léger-Sur-Dheune' },
  { label: 'Saint-Léger-Sur-Roanne', value: 'Saint-Léger-Sur-Roanne' },
  { label: 'Saint-Léonard', value: 'Saint-Léonard' },
  { label: 'Saint-Léonard', value: 'Saint-Léonard' },
  { label: 'Saint-Léonard', value: 'Saint-Léonard' },
  { label: 'Saint-Léonard', value: 'Saint-Léonard' },
  { label: 'Saint-Léonard', value: 'Saint-Léonard' },
  { label: 'Saint-Léonard', value: 'Saint-Léonard' },
  { label: 'Saint-Léonard-Daston', value: 'Saint-Léonard-Daston' },
  { label: 'Saint-Léonard-De-Noblat', value: 'Saint-Léonard-De-Noblat' },
  { label: 'Saint-Léon-Sur-L’Isle', value: 'Saint-Léon-Sur-L’Isle' },
  { label: 'Saint-Leu', value: 'Saint-Leu' },
  { label: 'Saint-Leu-La-Forêt', value: 'Saint-Leu-La-Forêt' },
  { label: 'Saint-Liboire', value: 'Saint-Liboire' },
  { label: 'Saint-Lin-Laurentides', value: 'Saint-Lin-Laurentides' },
  { label: 'Saint-Livres', value: 'Saint-Livres' },
  { label: 'Saint-Lizier', value: 'Saint-Lizier' },
  { label: 'Saint-Lô', value: 'Saint-Lô' },
  { label: 'Saint-Loubès', value: 'Saint-Loubès' },
  { label: 'Saint-Louis', value: 'Saint-Louis' },
  { label: 'Saint-Louis', value: 'Saint-Louis' },
  { label: 'Saint-Louis', value: 'Saint-Louis' },
  { label: 'Saint-Louis Du Nord', value: 'Saint-Louis Du Nord' },
  { label: 'Saint-Louis Du Sud', value: 'Saint-Louis Du Sud' },
  { label: 'Saint-Louis-De-Montferrand', value: 'Saint-Louis-De-Montferrand' },
  { label: 'Saint-Loup', value: 'Saint-Loup' },
  { label: 'Saint-Loup-Cammas', value: 'Saint-Loup-Cammas' },
  { label: 'Saint-Loup-Sur-Semouse', value: 'Saint-Loup-Sur-Semouse' },
  { label: 'Saint-Lubin-Des-Joncherets', value: 'Saint-Lubin-Des-Joncherets' },
  { label: 'Saint-Lumine-De-Clisson', value: 'Saint-Lumine-De-Clisson' },
  { label: 'Saint-Lumine-De-Coutais', value: 'Saint-Lumine-De-Coutais' },
  { label: 'Saint-Lunaire', value: 'Saint-Lunaire' },
  { label: 'Saint-Lupicin', value: 'Saint-Lupicin' },
  { label: 'Saint-Lyé', value: 'Saint-Lyé' },
  { label: 'Saint-Lyphard', value: 'Saint-Lyphard' },
  { label: 'Saint-Lys', value: 'Saint-Lys' },
  { label: 'Saint-Macaire', value: 'Saint-Macaire' },
  { label: 'Saint-Macaire-En-Mauges', value: 'Saint-Macaire-En-Mauges' },
  { label: 'Saint-Maixant', value: 'Saint-Maixant' },
  { label: 'Saint-Malo', value: 'Saint-Malo' },
  { label: 'Saint-Malo-De-Guersac', value: 'Saint-Malo-De-Guersac' },
  { label: 'Saint-Malô-Du-Bois', value: 'Saint-Malô-Du-Bois' },
  { label: 'Saint-Mamert-Du-Gard', value: 'Saint-Mamert-Du-Gard' },
  { label: 'Saint-Mamet-La-Salvetat', value: 'Saint-Mamet-La-Salvetat' },
  { label: 'Saint-Mammès', value: 'Saint-Mammès' },
  { label: 'Saint-Mandé', value: 'Saint-Mandé' },
  { label: 'Saint-Mandrier-Sur-Mer', value: 'Saint-Mandrier-Sur-Mer' },
  { label: 'Saint-Manvieu', value: 'Saint-Manvieu' },
  { label: 'Saint-Manvieu-Norrey', value: 'Saint-Manvieu-Norrey' },
  { label: 'Saint-Marc', value: 'Saint-Marc' },
  { label: 'Saint-Marc-Des-Carrières', value: 'Saint-Marc-Des-Carrières' },
  { label: 'Saint-Marcel', value: 'Saint-Marcel' },
  { label: 'Saint-Marcel', value: 'Saint-Marcel' },
  { label: 'Saint-Marcel', value: 'Saint-Marcel' },
  { label: 'Saint-Marcel', value: 'Saint-Marcel' },
  { label: 'Saint-Marcel-Dardèche', value: 'Saint-Marcel-Dardèche' },
  { label: 'Saint-Marcel-Lès-Annonay', value: 'Saint-Marcel-Lès-Annonay' },
  { label: 'Saint-Marcel-Lès-Sauzet', value: 'Saint-Marcel-Lès-Sauzet' },
  { label: 'Saint-Marcel-Lès-Valence', value: 'Saint-Marcel-Lès-Valence' },
  { label: 'Saint-Marcellin', value: 'Saint-Marcellin' },
  { label: 'Saint-Marcellin-En-Forez', value: 'Saint-Marcellin-En-Forez' },
  { label: 'Saint-Marcel-Sur-Aude', value: 'Saint-Marcel-Sur-Aude' },
  { label: 'Saint-Marc-Jaumegarde', value: 'Saint-Marc-Jaumegarde' },
  { label: 'Saint-Marc-Le-Blanc', value: 'Saint-Marc-Le-Blanc' },
  { label: 'Saint-Mard', value: 'Saint-Mard' },
  { label: 'Saint-Mariens', value: 'Saint-Mariens' },
  { label: 'Saint-Mars-D’Outillé', value: 'Saint-Mars-D’Outillé' },
  { label: 'Saint-Mars-De-Coutais', value: 'Saint-Mars-De-Coutais' },
  { label: 'Saint-Mars-Du-Désert', value: 'Saint-Mars-Du-Désert' },
  { label: 'Saint-Mars-La-Brière', value: 'Saint-Mars-La-Brière' },
  { label: 'Saint-Mars-La-Jaille', value: 'Saint-Mars-La-Jaille' },
  { label: 'Saint-Martin-Au-Laërt', value: 'Saint-Martin-Au-Laërt' },
  { label: 'Saint-Martin-Belle-Roche', value: 'Saint-Martin-Belle-Roche' },
  { label: 'Saint-Martin-Bellevue', value: 'Saint-Martin-Bellevue' },
  { label: 'Saint-Martin-Boulogne', value: 'Saint-Martin-Boulogne' },
  { label: 'Saint-Martin-D’Auxigny', value: 'Saint-Martin-D’Auxigny' },
  { label: 'Saint-Martin-D’Hères', value: 'Saint-Martin-D’Hères' },
  { label: 'Saint-Martin-De-Belleville', value: 'Saint-Martin-De-Belleville' },
  { label: 'Saint-Martin-De-Boscherville', value: 'Saint-Martin-De-Boscherville' },
  { label: 'Saint-Martin-De-Crau', value: 'Saint-Martin-De-Crau' },
  { label: 'Saint-Martin-De-Fontenay', value: 'Saint-Martin-De-Fontenay' },
  { label: 'Saint-Martin-De-Hinx', value: 'Saint-Martin-De-Hinx' },
  { label: 'Saint-Martin-De-Landelles', value: 'Saint-Martin-De-Landelles' },
  { label: 'Saint-Martin-De-La-Place', value: 'Saint-Martin-De-La-Place' },
  { label: 'Saint-Martin-De-Londres', value: 'Saint-Martin-De-Londres' },
  { label: 'Saint-Martin-De-Nigelles', value: 'Saint-Martin-De-Nigelles' },
  { label: 'Saint-Martin-De-Queyrières', value: 'Saint-Martin-De-Queyrières' },
  { label: 'Saint-Martin-De-Ré', value: 'Saint-Martin-De-Ré' },
  { label: 'Saint-Martin-Des-Besaces', value: 'Saint-Martin-Des-Besaces' },
  { label: 'Saint-Martin-Des-Champs', value: 'Saint-Martin-Des-Champs' },
  { label: 'Saint-Martin-Des-Champs', value: 'Saint-Martin-Des-Champs' },
  { label: 'Saint-Martin-De-Seignanx', value: 'Saint-Martin-De-Seignanx' },
  { label: 'Saint-Martin-Des-Noyers', value: 'Saint-Martin-Des-Noyers' },
  { label: 'Saint-Martin-De-Valamas', value: 'Saint-Martin-De-Valamas' },
  { label: 'Saint-Martin-De-Valgalgues', value: 'Saint-Martin-De-Valgalgues' },
  { label: 'Saint-Martin-Du-Fouilloux', value: 'Saint-Martin-Du-Fouilloux' },
  { label: 'Saint-Martin-Du-Frêne', value: 'Saint-Martin-Du-Frêne' },
  { label: 'Saint-Martin-Du-Manoir', value: 'Saint-Martin-Du-Manoir' },
  { label: 'Saint-Martin-Du-Mont', value: 'Saint-Martin-Du-Mont' },
  { label: 'Saint-Martin-Du-Tertre', value: 'Saint-Martin-Du-Tertre' },
  { label: 'Saint-Martin-Du-Tertre', value: 'Saint-Martin-Du-Tertre' },
  { label: 'Saint-Martin-Du-Var', value: 'Saint-Martin-Du-Var' },
  { label: 'Saint-Martin-Du-Vivier', value: 'Saint-Martin-Du-Vivier' },
  { label: 'Saint-Martin-En-Bresse', value: 'Saint-Martin-En-Bresse' },
  { label: 'Saint-Martin-En-Campagne', value: 'Saint-Martin-En-Campagne' },
  { label: 'Saint-Martin-En-Haut', value: 'Saint-Martin-En-Haut' },
  { label: 'Saint-Martin-Lacaussade', value: 'Saint-Martin-Lacaussade' },
  { label: 'Saint-Martin-Lalande', value: 'Saint-Martin-Lalande' },
  { label: 'Saint-Martin-La-Plaine', value: 'Saint-Martin-La-Plaine' },
  { label: 'Saint-Martin-Le-Beau', value: 'Saint-Martin-Le-Beau' },
  { label: 'Saint-Martin-Le-Vinoux', value: 'Saint-Martin-Le-Vinoux' },
  { label: 'Saint-Martin-Longueau', value: 'Saint-Martin-Longueau' },
  { label: 'Saint-Martin-Osmonville', value: 'Saint-Martin-Osmonville' },
  { label: 'Saint-Martin-Sous-Mouzeuil', value: 'Saint-Martin-Sous-Mouzeuil' },
  { label: 'Saint-Martin-Sur-Ocre', value: 'Saint-Martin-Sur-Ocre' },
  { label: 'Saint-Martin-Sur-Oust', value: 'Saint-Martin-Sur-Oust' },
  { label: 'Saint-Martin-Vésubie', value: 'Saint-Martin-Vésubie' },
  { label: 'Saint-Mathieu', value: 'Saint-Mathieu' },
  { label: 'Saint-Mathieu', value: 'Saint-Mathieu' },
  { label: 'Saint-Mathieu-De-Tréviers', value: 'Saint-Mathieu-De-Tréviers' },
  { label: 'Saint-Mathurin', value: 'Saint-Mathurin' },
  { label: 'Saint-Mathurin-Sur-Loire', value: 'Saint-Mathurin-Sur-Loire' },
  { label: 'Saint-Maur', value: 'Saint-Maur' },
  { label: 'Saint-Maur-Des-Fossés', value: 'Saint-Maur-Des-Fossés' },
  { label: 'Saint-Maurice', value: 'Saint-Maurice' },
  { label: 'Saint-Maurice', value: 'Saint-Maurice' },
  { label: 'Saint-Maurice District', value: 'Saint-Maurice District' },
  { label: 'Saint-Maurice-De-Beynost', value: 'Saint-Maurice-De-Beynost' },
  { label: 'Saint-Maurice-De-Gourdans', value: 'Saint-Maurice-De-Gourdans' },
  { label: 'Saint-Maurice-De-Lignon', value: 'Saint-Maurice-De-Lignon' },
  { label: 'Saint-Maurice-En-Gourgois', value: 'Saint-Maurice-En-Gourgois' },
  { label: 'Saint-Maurice-La-Clouère', value: 'Saint-Maurice-La-Clouère' },
  { label: 'Saint-Maurice-La-Souterraine', value: 'Saint-Maurice-La-Souterraine' },
  { label: 'Saint-Maurice-Montcouronne', value: 'Saint-Maurice-Montcouronne' },
  { label: 'Saint-Maurice-Sur-Dargoire', value: 'Saint-Maurice-Sur-Dargoire' },
  { label: 'Saint-Maurice-Sur-Fessard', value: 'Saint-Maurice-Sur-Fessard' },
  { label: 'Saint-Maurice-Sur-Moselle', value: 'Saint-Maurice-Sur-Moselle' },
  { label: 'Saint-Mauront', value: 'Saint-Mauront' },
  { label: 'Saint-Max', value: 'Saint-Max' },
  { label: 'Saint-Maximin', value: 'Saint-Maximin' },
  { label: 'Saint-Maximin-La-Sainte-Baume', value: 'Saint-Maximin-La-Sainte-Baume' },
  { label: 'Saint-Maxire', value: 'Saint-Maxire' },
  { label: 'Saint-Médard-D’Eyrans', value: 'Saint-Médard-D’Eyrans' },
  { label: 'Saint-Médard-De-Guizières', value: 'Saint-Médard-De-Guizières' },
  { label: 'Saint-Médard-De-Mussidan', value: 'Saint-Médard-De-Mussidan' },
  { label: 'Saint-Médard-En-Jalles', value: 'Saint-Médard-En-Jalles' },
  { label: 'Saint-Médard-Sur-Ille', value: 'Saint-Médard-Sur-Ille' },
  { label: 'Saint-Méen-Le-Grand', value: 'Saint-Méen-Le-Grand' },
  { label: 'Saint-Melaine-Sur-Aubance', value: 'Saint-Melaine-Sur-Aubance' },
  { label: 'Saint-Méloir-Des-Ondes', value: 'Saint-Méloir-Des-Ondes' },
  { label: 'Saint-Même-Les-Carrières', value: 'Saint-Même-Les-Carrières' },
  { label: 'Saint-Même-Le-Tenu', value: 'Saint-Même-Le-Tenu' },
  { label: 'Saint-Memmie', value: 'Saint-Memmie' },
  { label: 'Saint-Menet', value: 'Saint-Menet' },
  { label: 'Saint-Menges', value: 'Saint-Menges' },
  { label: 'Saint-Mesmin', value: 'Saint-Mesmin' },
  { label: 'Saint-Mexant', value: 'Saint-Mexant' },
  { label: 'Saint-Michel', value: 'Saint-Michel' },
  { label: 'Saint-Michel', value: 'Saint-Michel' },
  { label: 'Saint-Michel', value: 'Saint-Michel' },
  { label: 'Saint-Michel-Chef-Chef', value: 'Saint-Michel-Chef-Chef' },
  { label: 'Saint-Michel-De-Maurienne', value: 'Saint-Michel-De-Maurienne' },
  { label: 'Saint-Michel-Des-Saints', value: 'Saint-Michel-Des-Saints' },
  { label: 'Saint-Michel-L’Observatoire', value: 'Saint-Michel-L’Observatoire' },
  { label: 'Saint-Michel-Le-Cloucq', value: 'Saint-Michel-Le-Cloucq' },
  { label: 'Saint-Michel-Mont-Mercure', value: 'Saint-Michel-Mont-Mercure' },
  { label: 'Saint-Michel-Sur-Meurthe', value: 'Saint-Michel-Sur-Meurthe' },
  { label: 'Saint-Michel-Sur-Orge', value: 'Saint-Michel-Sur-Orge' },
  { label: 'Saint-Mihiel', value: 'Saint-Mihiel' },
  { label: 'Saint-Mitre', value: 'Saint-Mitre' },
  { label: 'Saint-Mitre-Les-Remparts', value: 'Saint-Mitre-Les-Remparts' },
  { label: 'Saint-Molf', value: 'Saint-Molf' },
  { label: 'Saint-Morillon', value: 'Saint-Morillon' },
  { label: 'Saint-Nabord', value: 'Saint-Nabord' },
  { label: 'Saint-Nauphary', value: 'Saint-Nauphary' },
  { label: 'Saint-Nazaire', value: 'Saint-Nazaire' },
  { label: 'Saint-Nazaire', value: 'Saint-Nazaire' },
  { label: 'Saint-Nazaire', value: 'Saint-Nazaire' },
  { label: 'Saint-Nazaire-D’Aude', value: 'Saint-Nazaire-D’Aude' },
  { label: 'Saint-Nazaire-Les-Eymes', value: 'Saint-Nazaire-Les-Eymes' },
  { label: 'Saint-Nicolas', value: 'Saint-Nicolas' },
  { label: 'Saint-Nicolas', value: 'Saint-Nicolas' },
  { label: 'Saint-Nicolas', value: 'Saint-Nicolas' },
  { label: 'Saint-Nicolas-D’Aliermont', value: 'Saint-Nicolas-D’Aliermont' },
  { label: 'Saint-Nicolas-De-Bourgueil', value: 'Saint-Nicolas-De-Bourgueil' },
  { label: 'Saint-Nicolas-De-La-Grave', value: 'Saint-Nicolas-De-La-Grave' },
  { label: 'Saint-Nicolas-De-La-Taille', value: 'Saint-Nicolas-De-La-Taille' },
  { label: 'Saint-Nicolas-De-Port', value: 'Saint-Nicolas-De-Port' },
  { label: 'Saint-Nicolas-De-Redon', value: 'Saint-Nicolas-De-Redon' },
  { label: 'Saint-Nicolas-Du-Pélem', value: 'Saint-Nicolas-Du-Pélem' },
  { label: 'Saint-Nizier-Sous-Charlieu', value: 'Saint-Nizier-Sous-Charlieu' },
  { label: 'Saint-Nolff', value: 'Saint-Nolff' },
  { label: 'Saint-Nom-La-Bretêche', value: 'Saint-Nom-La-Bretêche' },
  { label: 'Saint-Norbert', value: 'Saint-Norbert' },
  { label: 'Saint-Omer', value: 'Saint-Omer' },
  { label: 'Saint-Omer-En-Chaussée', value: 'Saint-Omer-En-Chaussée' },
  { label: 'Saint-Orens-De-Gameville', value: 'Saint-Orens-De-Gameville' },
  { label: 'Saint-Ouen', value: 'Saint-Ouen' },
  { label: 'Saint-Ouen', value: 'Saint-Ouen' },
  { label: 'Saint-Ouen', value: 'Saint-Ouen' },
  { label: 'Saint-Ouen-Des-Alleux', value: 'Saint-Ouen-Des-Alleux' },
  { label: 'Saint-Ouën-Des-Toits', value: 'Saint-Ouën-Des-Toits' },
  { label: 'Saint-Ouen-De-Thouberville', value: 'Saint-Ouen-De-Thouberville' },
  { label: 'Saint-Ouen-Du-Tilleul', value: 'Saint-Ouen-Du-Tilleul' },
  { label: 'Saint-Ouen-En-Belin', value: 'Saint-Ouen-En-Belin' },
  { label: 'Saint-Ouen-L’Aumône', value: 'Saint-Ouen-L’Aumône' },
  { label: 'Saint-Ours', value: 'Saint-Ours' },
  { label: 'Saint-Oyen', value: 'Saint-Oyen' },
  { label: 'Saint-Pabu', value: 'Saint-Pabu' },
  { label: 'Saint-Pacôme', value: 'Saint-Pacôme' },
  { label: 'Saint-Paër', value: 'Saint-Paër' },
  { label: 'Saint-Pair-Sur-Mer', value: 'Saint-Pair-Sur-Mer' },
  { label: 'Saint-Palais', value: 'Saint-Palais' },
  { label: 'Saint-Palais-Sur-Mer', value: 'Saint-Palais-Sur-Mer' },
  { label: 'Saint-Pal-De-Mons', value: 'Saint-Pal-De-Mons' },
  { label: 'Saint-Pantaléon-De-Larche', value: 'Saint-Pantaléon-De-Larche' },
  { label: 'Saint-Pardoux', value: 'Saint-Pardoux' },
  { label: 'Saint-Pardoux-Isaac', value: 'Saint-Pardoux-Isaac' },
  { label: 'Saint-Pardoux-La-Rivière', value: 'Saint-Pardoux-La-Rivière' },
  { label: 'Saint-Pargoire', value: 'Saint-Pargoire' },
  { label: 'Saint-Parize-Le-Châtel', value: 'Saint-Parize-Le-Châtel' },
  { label: 'Saint-Parres-Aux-Tertres', value: 'Saint-Parres-Aux-Tertres' },
  { label: 'Saint-Pascal', value: 'Saint-Pascal' },
  { label: 'Saint-Paterne', value: 'Saint-Paterne' },
  { label: 'Saint-Paterne-Racan', value: 'Saint-Paterne-Racan' },
  { label: 'Saint-Pathus', value: 'Saint-Pathus' },
  { label: 'Saint-Paul', value: 'Saint-Paul' },
  { label: 'Saint-Paul', value: 'Saint-Paul' },
  { label: 'Saint-Paul', value: 'Saint-Paul' },
  { label: 'Saint-Paul-Cap-De-Joux', value: 'Saint-Paul-Cap-De-Joux' },
  { label: 'Saint-Paul-De-Fenouillet', value: 'Saint-Paul-De-Fenouillet' },
  { label: 'Saint-Paul-De-Jarrat', value: 'Saint-Paul-De-Jarrat' },
  { label: 'Saint-Paul-Des-Landes', value: 'Saint-Paul-Des-Landes' },
  { label: 'Saint-Paul-De-Varax', value: 'Saint-Paul-De-Varax' },
  { label: 'Saint-Paul-De-Varces', value: 'Saint-Paul-De-Varces' },
  { label: 'Saint-Paul-De-Vence', value: 'Saint-Paul-De-Vence' },
  { label: 'Saint-Paul-En-Chablais', value: 'Saint-Paul-En-Chablais' },
  { label: 'Saint-Paul-En-Forêt', value: 'Saint-Paul-En-Forêt' },
  { label: 'Saint-Paul-En-Jarez', value: 'Saint-Paul-En-Jarez' },
  { label: 'Saint-Paul-En-Pareds', value: 'Saint-Paul-En-Pareds' },
  { label: 'Saint-Paulet-De-Caisson', value: 'Saint-Paulet-De-Caisson' },
  { label: 'Saint-Paulien', value: 'Saint-Paulien' },
  { label: 'Saint-Paul-Lès-Dax', value: 'Saint-Paul-Lès-Dax' },
  { label: 'Saint-Paul-Lès-Romans', value: 'Saint-Paul-Lès-Romans' },
  { label: 'Saint-Paul-Trois-Châteaux', value: 'Saint-Paul-Trois-Châteaux' },
  { label: 'Saint-Pé-De-Bigorre', value: 'Saint-Pé-De-Bigorre' },
  { label: 'Saint-Pée-Sur-Nivelle', value: 'Saint-Pée-Sur-Nivelle' },
  { label: 'Saint-Péray', value: 'Saint-Péray' },
  { label: 'Saint-Perdon', value: 'Saint-Perdon' },
  { label: 'Saint-Père', value: 'Saint-Père' },
  { label: 'Saint-Père-En-Retz', value: 'Saint-Père-En-Retz' },
  { label: 'Saint-Père-Sur-Loire', value: 'Saint-Père-Sur-Loire' },
  { label: 'Saint-Perreux', value: 'Saint-Perreux' },
  { label: 'Saint-Philbert-De-Bouaine', value: 'Saint-Philbert-De-Bouaine' },
  { label: 'Saint-Philbert-De-Grand-Lieu', value: 'Saint-Philbert-De-Grand-Lieu' },
  { label: 'Saint-Philbert-Du-Peuple', value: 'Saint-Philbert-Du-Peuple' },
  { label: 'Saint-Philibert', value: 'Saint-Philibert' },
  { label: 'Saint-Philippe-De-La Prairie', value: 'Saint-Philippe-De-La Prairie' },
  { label: 'Saint-Piat', value: 'Saint-Piat' },
  { label: 'Saint-Pie', value: 'Saint-Pie' },
  { label: 'Saint-Pierre', value: 'Saint-Pierre' },
  { label: 'Saint-Pierre', value: 'Saint-Pierre' },
  { label: 'Saint-Pierre-D’Aurillac', value: 'Saint-Pierre-D’Aurillac' },
  { label: 'Saint-Pierre-D’Oléron', value: 'Saint-Pierre-D’Oléron' },
  { label: 'Saint-Pierre-De-Bœuf', value: 'Saint-Pierre-De-Bœuf' },
  { label: 'Saint-Pierre-De-Chandieu', value: 'Saint-Pierre-De-Chandieu' },
  { label: 'Saint-Pierre-De-Plesguen', value: 'Saint-Pierre-De-Plesguen' },
  { label: 'Saint-Pierre-Des-Corps', value: 'Saint-Pierre-Des-Corps' },
  { label: 'Saint-Pierre-Des-Échaubrognes', value: 'Saint-Pierre-Des-Échaubrognes' },
  { label: 'Saint-Pierre-Des-Fleurs', value: 'Saint-Pierre-Des-Fleurs' },
  { label: 'Saint-Pierre-Des-Nids', value: 'Saint-Pierre-Des-Nids' },
  { label: 'Saint-Pierre-De-Varengeville', value: 'Saint-Pierre-De-Varengeville' },
  { label: 'Saint-Pierre-Du-Chemin', value: 'Saint-Pierre-Du-Chemin' },
  { label: 'Saint-Pierre-Du-Mont', value: 'Saint-Pierre-Du-Mont' },
  { label: 'Saint-Pierre-Du-Perray', value: 'Saint-Pierre-Du-Perray' },
  { label: 'Saint-Pierre-Du-Regard', value: 'Saint-Pierre-Du-Regard' },
  { label: 'Saint-Pierre-Du-Vauvray', value: 'Saint-Pierre-Du-Vauvray' },
  { label: 'Saint-Pierre-Église', value: 'Saint-Pierre-Église' },
  { label: 'Saint-Pierre-En-Val', value: 'Saint-Pierre-En-Val' },
  { label: 'Saint-Pierre-La-Cour', value: 'Saint-Pierre-La-Cour' },
  { label: 'Saint-Pierre-La-Palud', value: 'Saint-Pierre-La-Palud' },
  { label: 'Saint-Pierre-Le-Moûtier', value: 'Saint-Pierre-Le-Moûtier' },
  { label: 'Saint-Pierre-Les-Becquets', value: 'Saint-Pierre-Les-Becquets' },
  { label: 'Saint-Pierre-Lès-Elbeuf', value: 'Saint-Pierre-Lès-Elbeuf' },
  { label: 'Saint-Pierre-Lès-Nemours', value: 'Saint-Pierre-Lès-Nemours' },
  { label: 'Saint-Pierre-Montlimart', value: 'Saint-Pierre-Montlimart' },
  { label: 'Saint-Pierre-Quiberon', value: 'Saint-Pierre-Quiberon' },
  { label: 'Saint-Pierre-Sur-Dives', value: 'Saint-Pierre-Sur-Dives' },
  { label: 'Saint-Planchers', value: 'Saint-Planchers' },
  { label: 'Saint-Pol-De-Léon', value: 'Saint-Pol-De-Léon' },
  { label: 'Saint-Pol-Sur-Mer', value: 'Saint-Pol-Sur-Mer' },
  { label: 'Saint-Pol-Sur-Ternoise', value: 'Saint-Pol-Sur-Ternoise' },
  { label: 'Saint-Pons-De-Thomières', value: 'Saint-Pons-De-Thomières' },
  { label: 'Saint-Porchaire', value: 'Saint-Porchaire' },
  { label: 'Saint-Porquier', value: 'Saint-Porquier' },
  { label: 'Saint-Pouange', value: 'Saint-Pouange' },
  { label: 'Saint-Pourçain-Sur-Sioule', value: 'Saint-Pourçain-Sur-Sioule' },
  { label: 'Saint-Prest', value: 'Saint-Prest' },
  { label: 'Saint-Prex', value: 'Saint-Prex' },
  { label: 'Saint-Priest', value: 'Saint-Priest' },
  { label: 'Saint-Priest-En-Jarez', value: 'Saint-Priest-En-Jarez' },
  { label: 'Saint-Priest-Sous-Aixe', value: 'Saint-Priest-Sous-Aixe' },
  { label: 'Saint-Priest-Taurion', value: 'Saint-Priest-Taurion' },
  { label: 'Saint-Prime', value: 'Saint-Prime' },
  { label: 'Saint-Privat', value: 'Saint-Privat' },
  { label: 'Saint-Privat', value: 'Saint-Privat' },
  { label: 'Saint-Privat-Des-Vieux', value: 'Saint-Privat-Des-Vieux' },
  { label: 'Saint-Privat-La-Montagne', value: 'Saint-Privat-La-Montagne' },
  { label: 'Saint-Prix', value: 'Saint-Prix' },
  { label: 'Saint-Projet-Saint-Constant', value: 'Saint-Projet-Saint-Constant' },
  { label: 'Saint-Prouant', value: 'Saint-Prouant' },
  { label: 'Saint-Pryvé-Saint-Mesmin', value: 'Saint-Pryvé-Saint-Mesmin' },
  { label: 'Saint-Python', value: 'Saint-Python' },
  { label: 'Saint-Quay-Perros', value: 'Saint-Quay-Perros' },
  { label: 'Saint-Quay-Portrieux', value: 'Saint-Quay-Portrieux' },
  { label: 'Saint-Quentin', value: 'Saint-Quentin' },
  { label: 'Saint-Quentin-De-Baron', value: 'Saint-Quentin-De-Baron' },
  { label: 'Saint-Quentin-En-Mauges', value: 'Saint-Quentin-En-Mauges' },
  { label: 'Saint-Quentin-En-Yvelines', value: 'Saint-Quentin-En-Yvelines' },
  { label: 'Saint-Quentin-Fallavier', value: 'Saint-Quentin-Fallavier' },
  { label: 'Saint-Quentin-La-Poterie', value: 'Saint-Quentin-La-Poterie' },
  { label: 'Saint-Quentin-Sur-Isère', value: 'Saint-Quentin-Sur-Isère' },
  { label: 'Saint-Quentin-Sur-Le-Homme', value: 'Saint-Quentin-Sur-Le-Homme' },
  { label: 'Saint-Rambert-En-Bugey', value: 'Saint-Rambert-En-Bugey' },
  { label: 'Saint-Raphaël', value: 'Saint-Raphaël' },
  { label: 'Saint-Raphaël', value: 'Saint-Raphaël' },
  { label: 'Saint-Raphaël', value: 'Saint-Raphaël' },
  { label: 'Saint-Raymond', value: 'Saint-Raymond' },
  { label: 'Saint-Rémi', value: 'Saint-Rémi' },
  { label: 'Saint-Rémi-De-Tingwick', value: 'Saint-Rémi-De-Tingwick' },
  { label: 'Saint-Rémy', value: 'Saint-Rémy' },
  { label: 'Saint-Rémy', value: 'Saint-Rémy' },
  { label: 'Saint-Rémy-De-Maurienne', value: 'Saint-Rémy-De-Maurienne' },
  { label: 'Saint-Rémy-De-Provence', value: 'Saint-Rémy-De-Provence' },
  { label: 'Saint-Rémy-En-Mauges', value: 'Saint-Rémy-En-Mauges' },
  { label: 'Saint-Rémy-En-Rollat', value: 'Saint-Rémy-En-Rollat' },
  { label: 'Saint-Rémy-Lès-Chevreuse', value: 'Saint-Rémy-Lès-Chevreuse' },
  { label: 'Saint-Rémy-Sur-Avre', value: 'Saint-Rémy-Sur-Avre' },
  { label: 'Saint-Rémy-Sur-Durolle', value: 'Saint-Rémy-Sur-Durolle' },
  { label: 'Saint-Renan', value: 'Saint-Renan' },
  { label: 'Saint-Restitut', value: 'Saint-Restitut' },
  { label: 'Saint-Rhémy', value: 'Saint-Rhémy' },
  { label: 'Saint-Riquier', value: 'Saint-Riquier' },
  { label: 'Saint-Rogatien', value: 'Saint-Rogatien' },
  { label: 'Saint-Romain-De-Benet', value: 'Saint-Romain-De-Benet' },
  { label: 'Saint-Romain-De-Colbosc', value: 'Saint-Romain-De-Colbosc' },
  { label: 'Saint-Romain-De-Jalionas', value: 'Saint-Romain-De-Jalionas' },
  { label: 'Saint-Romain-De-Popey', value: 'Saint-Romain-De-Popey' },
  { label: 'Saint-Romain-En-Gal', value: 'Saint-Romain-En-Gal' },
  { label: 'Saint-Romain-La-Motte', value: 'Saint-Romain-La-Motte' },
  { label: 'Saint-Romain-Le-Puy', value: 'Saint-Romain-Le-Puy' },
  { label: 'Saint-Romain-Sur-Cher', value: 'Saint-Romain-Sur-Cher' },
  { label: 'Saint-Romans', value: 'Saint-Romans' },
  { label: 'Saintry-Sur-Seine', value: 'Saintry-Sur-Seine' },
  { label: 'Saints', value: 'Saints' },
  { label: 'Saint-Saëns', value: 'Saint-Saëns' },
  { label: 'Saint-Samson-Sur-Rance', value: 'Saint-Samson-Sur-Rance' },
  { label: 'Saint-Satur', value: 'Saint-Satur' },
  { label: 'Saint-Saturnin', value: 'Saint-Saturnin' },
  { label: 'Saint-Saturnin', value: 'Saint-Saturnin' },
  { label: 'Saint-Saturnin', value: 'Saint-Saturnin' },
  { label: 'Saint-Saturnin-Lès-Apt', value: 'Saint-Saturnin-Lès-Apt' },
  { label: 'Saint-Saturnin-Lès-Avignon', value: 'Saint-Saturnin-Lès-Avignon' },
  { label: 'Saint-Saturnin-Sur-Loire', value: 'Saint-Saturnin-Sur-Loire' },
  { label: 'Saint-Saulve', value: 'Saint-Saulve' },
  { label: 'Saint-Sauvant', value: 'Saint-Sauvant' },
  { label: 'Saint-Sauves-Dauvergne', value: 'Saint-Sauves-Dauvergne' },
  { label: 'Saint-Sauveur', value: 'Saint-Sauveur' },
  { label: 'Saint-Sauveur', value: 'Saint-Sauveur' },
  { label: 'Saint-Sauveur', value: 'Saint-Sauveur' },
  { label: 'Saint-Sauveur', value: 'Saint-Sauveur' },
  { label: 'Saint-Sauveur', value: 'Saint-Sauveur' },
  { label: 'Saint-Sauveur', value: 'Saint-Sauveur' },
  { label: 'Saint-Sauveur-D’Aunis', value: 'Saint-Sauveur-D’Aunis' },
  { label: 'Saint-Sauveur-De-Montagut', value: 'Saint-Sauveur-De-Montagut' },
  { label: 'Saint-Sauveur-Des-Landes', value: 'Saint-Sauveur-Des-Landes' },
  { label: 'Saint-Sauveur-Des-Monts', value: 'Saint-Sauveur-Des-Monts' },
  { label: 'Saint-Sauveur-En-Rue', value: 'Saint-Sauveur-En-Rue' },
  { label: 'Saint-Sauveur-Lendelin', value: 'Saint-Sauveur-Lendelin' },
  { label: 'Saint-Sauveur-Le-Vicomte', value: 'Saint-Sauveur-Le-Vicomte' },
  { label: 'Saint-Sauveur-Sur-École', value: 'Saint-Sauveur-Sur-École' },
  { label: 'Saint-Savin', value: 'Saint-Savin' },
  { label: 'Saint-Savin', value: 'Saint-Savin' },
  { label: 'Saint-Savinien', value: 'Saint-Savinien' },
  { label: 'Saint-Savournin', value: 'Saint-Savournin' },
  { label: 'Saint-Sébastien-De-Morsent', value: 'Saint-Sébastien-De-Morsent' },
  { label: 'Saint-Sébastien-Sur-Loire', value: 'Saint-Sébastien-Sur-Loire' },
  { label: 'Saint-Selve', value: 'Saint-Selve' },
  { label: 'Saint-Senier-Sous-Avranches', value: 'Saint-Senier-Sous-Avranches' },
  { label: 'Saint-Senoux', value: 'Saint-Senoux' },
  { label: 'Saint-Sernin', value: 'Saint-Sernin' },
  { label: 'Saint-Sernin-Du-Bois', value: 'Saint-Sernin-Du-Bois' },
  { label: 'Saint-Seurin-Sur-L’Isle', value: 'Saint-Seurin-Sur-L’Isle' },
  { label: 'Saint-Sever', value: 'Saint-Sever' },
  { label: 'Saint-Sever-Calvados', value: 'Saint-Sever-Calvados' },
  { label: 'Saint-Siméon', value: 'Saint-Siméon' },
  { label: 'Saint-Siméon-De-Bressieux', value: 'Saint-Siméon-De-Bressieux' },
  { label: 'Saint-Simon', value: 'Saint-Simon' },
  { label: 'Saint-Sorlin-En-Bugey', value: 'Saint-Sorlin-En-Bugey' },
  { label: 'Saint-Sorlin-En-Valloire', value: 'Saint-Sorlin-En-Valloire' },
  { label: 'Saint-Souplet', value: 'Saint-Souplet' },
  { label: 'Saint-Soupplets', value: 'Saint-Soupplets' },
  { label: 'Saint-Sulpice', value: 'Saint-Sulpice' },
  { label: 'Saint-Sulpice-De-Cognac', value: 'Saint-Sulpice-De-Cognac' },
  { label: 'Saint-Sulpice-De-Faleyrens', value: 'Saint-Sulpice-De-Faleyrens' },
  { label: 'Saint-Sulpice-De-Pommeray', value: 'Saint-Sulpice-De-Pommeray' },
  { label: 'Saint-Sulpice-De-Royan', value: 'Saint-Sulpice-De-Royan' },
  { label: 'Saint-Sulpice-Et-Cameyrac', value: 'Saint-Sulpice-Et-Cameyrac' },
  { label: 'Saint-Sulpice-La-Forêt', value: 'Saint-Sulpice-La-Forêt' },
  { label: 'Saint-Sulpice-La-Pointe', value: 'Saint-Sulpice-La-Pointe' },
  { label: 'Saint-Sulpice-Le-Guérétois', value: 'Saint-Sulpice-Le-Guérétois' },
  { label: 'Saint-Sulpice-Les-Feuilles', value: 'Saint-Sulpice-Les-Feuilles' },
  { label: 'Saint-Sulpice-Sur-Lèze', value: 'Saint-Sulpice-Sur-Lèze' },
  { label: 'Saint-Sulpice-Sur-Risle', value: 'Saint-Sulpice-Sur-Risle' },
  { label: 'Saint-Sylvain', value: 'Saint-Sylvain' },
  { label: 'Saint-Sylvestre-Cappel', value: 'Saint-Sylvestre-Cappel' },
  { label: 'Saint-Sylvestre-Sur-Lot', value: 'Saint-Sylvestre-Sur-Lot' },
  { label: 'Saint-Symphorien', value: 'Saint-Symphorien' },
  { label: 'Saint-Symphorien-De-Lay', value: 'Saint-Symphorien-De-Lay' },
  { label: 'Saint-Symphorien-Sur-Coise', value: 'Saint-Symphorien-Sur-Coise' },
  { label: 'Saint-Thégonnec', value: 'Saint-Thégonnec' },
  { label: 'Saint-Thibault-Des-Vignes', value: 'Saint-Thibault-Des-Vignes' },
  { label: 'Saint-Thibéry', value: 'Saint-Thibéry' },
  { label: 'Saint-Thomas', value: 'Saint-Thomas' },
  { label: 'Saint-Thonan', value: 'Saint-Thonan' },
  { label: 'Saint-Thurial', value: 'Saint-Thurial' },
  { label: 'Saint-Thuriau', value: 'Saint-Thuriau' },
  { label: 'Saint-Tite', value: 'Saint-Tite' },
  { label: 'Saint-Trivier-De-Courtes', value: 'Saint-Trivier-De-Courtes' },
  { label: 'Saint-Trivier-Sur-Moignans', value: 'Saint-Trivier-Sur-Moignans' },
  { label: 'Saint-Trojan-Les-Bains', value: 'Saint-Trojan-Les-Bains' },
  { label: 'Saint-Tronc', value: 'Saint-Tronc' },
  { label: 'Saint-Tropez', value: 'Saint-Tropez' },
  { label: 'Saint-Urbain', value: 'Saint-Urbain' },
  { label: 'Saint-Urbain', value: 'Saint-Urbain' },
  { label: 'Saint-Usage', value: 'Saint-Usage' },
  { label: 'Saint-Usuge', value: 'Saint-Usuge' },
  { label: 'Saint-Uze', value: 'Saint-Uze' },
  { label: 'Saint-Vaast-La-Hougue', value: 'Saint-Vaast-La-Hougue' },
  { label: 'Saint-Valérien', value: 'Saint-Valérien' },
  { label: 'Saint-Valery-En-Caux', value: 'Saint-Valery-En-Caux' },
  { label: 'Saint-Valery-Sur-Somme', value: 'Saint-Valery-Sur-Somme' },
  { label: 'Saint-Vallier', value: 'Saint-Vallier' },
  { label: 'Saint-Vallier', value: 'Saint-Vallier' },
  { label: 'Saint-Vallier-De-Thiey', value: 'Saint-Vallier-De-Thiey' },
  { label: 'Saint-Varent', value: 'Saint-Varent' },
  { label: 'Saint-Vaury', value: 'Saint-Vaury' },
  { label: 'Saint-Venant', value: 'Saint-Venant' },
  { label: 'Saint-Vérand', value: 'Saint-Vérand' },
  { label: 'Saint-Viance', value: 'Saint-Viance' },
  { label: 'Saint-Viâtre', value: 'Saint-Viâtre' },
  { label: 'Saint-Viaud', value: 'Saint-Viaud' },
  { label: 'Saint-Victor', value: 'Saint-Victor' },
  { label: 'Saint-Victor', value: 'Saint-Victor' },
  { label: 'Saint-Victor-De-Cessieu', value: 'Saint-Victor-De-Cessieu' },
  { label: 'Saint-Victoret', value: 'Saint-Victoret' },
  { label: 'Saint-Victor-La-Coste', value: 'Saint-Victor-La-Coste' },
  { label: 'Saint-Victurnien', value: 'Saint-Victurnien' },
  { label: 'Saint-Vigor-Le-Grand', value: 'Saint-Vigor-Le-Grand' },
  { label: 'Saint-Vincent', value: 'Saint-Vincent' },
  { label: 'Saint-Vincent-De-Mercuze', value: 'Saint-Vincent-De-Mercuze' },
  { label: 'Saint-Vincent-De-Paul', value: 'Saint-Vincent-De-Paul' },
  { label: 'Saint-Vincent-Des-Landes', value: 'Saint-Vincent-Des-Landes' },
  { label: 'Saint-Vincent-De-Tyrosse', value: 'Saint-Vincent-De-Tyrosse' },
  { label: 'Saint-Vincent-Sur-Graon', value: 'Saint-Vincent-Sur-Graon' },
  { label: 'Saint-Vincent-Sur-Oust', value: 'Saint-Vincent-Sur-Oust' },
  { label: 'Saint-Vit', value: 'Saint-Vit' },
  { label: 'Saint-Vite', value: 'Saint-Vite' },
  { label: 'Saint-Vith', value: 'Saint-Vith' },
  { label: 'Saint-Vivien-De-Médoc', value: 'Saint-Vivien-De-Médoc' },
  { label: 'Saint-Vrain', value: 'Saint-Vrain' },
  { label: 'Saint-Wandrille-Rançon', value: 'Saint-Wandrille-Rançon' },
  { label: 'Saint-Witz', value: 'Saint-Witz' },
  { label: 'Saint-Xandre', value: 'Saint-Xandre' },
  { label: 'Saint-Yan', value: 'Saint-Yan' },
  { label: 'Saint-Yorre', value: 'Saint-Yorre' },
  { label: 'Saint-Yrieix-La-Perche', value: 'Saint-Yrieix-La-Perche' },
  { label: 'Saint-Yrieix-Sur-Charente', value: 'Saint-Yrieix-Sur-Charente' },
  { label: 'Saint-Yvi', value: 'Saint-Yvi' },
  { label: 'Saint-Yzan-De-Soudiac', value: 'Saint-Yzan-De-Soudiac' },
  { label: 'Saint-Zacharie', value: 'Saint-Zacharie' },
  { label: 'Saint-Zotique', value: 'Saint-Zotique' },
  { label: 'Sainyabuli', value: 'Sainyabuli' },
  { label: 'Sairang', value: 'Sairang' },
  { label: 'Sairano', value: 'Sairano' },
  { label: 'Sairé', value: 'Sairé' },
  { label: 'Saitama', value: 'Saitama' },
  { label: 'Saitama-Shi', value: 'Saitama-Shi' },
  { label: 'Saitlaw', value: 'Saitlaw' },
  { label: 'Saito-Shi', value: 'Saito-Shi' },
  { label: 'Saivres', value: 'Saivres' },
  { label: 'Saïx', value: 'Saïx' },
  { label: 'Saizerais', value: 'Saizerais' },
  { label: 'Sajan', value: 'Sajan' },
  { label: 'Sajazarra', value: 'Sajazarra' },
  { label: 'Sājir', value: 'Sājir' },
  { label: 'Šajkaš', value: 'Šajkaš' },
  { label: 'Sajóbábony', value: 'Sajóbábony' },
  { label: 'Sajókaza', value: 'Sajókaza' },
  { label: 'Sajólád', value: 'Sajólád' },
  { label: 'Sajóörös', value: 'Sajóörös' },
  { label: 'Sajószentpéter', value: 'Sajószentpéter' },
  { label: 'Sajószöged', value: 'Sajószöged' },
  { label: 'Sajóvámos', value: 'Sajóvámos' },
  { label: 'Sājūr', value: 'Sājūr' },
  { label: 'Sakado', value: 'Sakado' },
  { label: 'Sakado-Shi', value: 'Sakado-Shi' },
  { label: 'Sakae', value: 'Sakae' },
  { label: 'Sakai', value: 'Sakai' },
  { label: 'Sakai', value: 'Sakai' },
  { label: 'Sakai Shi', value: 'Sakai Shi' },
  { label: 'Sakaide Shi', value: 'Sakaide Shi' },
  { label: 'Sakaidechō', value: 'Sakaidechō' },
  { label: 'Sakaiminato', value: 'Sakaiminato' },
  { label: 'Sakaiminato Shi', value: 'Sakaiminato Shi' },
  { label: 'Sakai-Nakajima', value: 'Sakai-Nakajima' },
  { label: 'Sakai-Shi', value: 'Sakai-Shi' },
  { label: 'Sakakah', value: 'Sakakah' },
  { label: 'Sakaki', value: 'Sakaki' },
  { label: 'Sakassou', value: 'Sakassou' },
  { label: 'Sakata', value: 'Sakata' },
  { label: 'Sakata Shi', value: 'Sakata Shi' },
  { label: 'Sakawa', value: 'Sakawa' },
  { label: 'Sakchu-Ŭp', value: 'Sakchu-Ŭp' },
  { label: 'Sake', value: 'Sake' },
  { label: 'Sakété', value: 'Sakété' },
  { label: 'Sakhanu', value: 'Sakhanu' },
  { label: 'Sakharnaya Golovka', value: 'Sakharnaya Golovka' },
  { label: 'Sakharovo', value: 'Sakharovo' },
  { label: 'Sakhnīn', value: 'Sakhnīn' },
  { label: 'Sakhnovshchyna', value: 'Sakhnovshchyna' },
  { label: 'Şakhrah', value: 'Şakhrah' },
  { label: 'Saki', value: 'Saki' },
  { label: 'Saki', value: 'Saki' },
  { label: 'Sakiai', value: 'Sakiai' },
  { label: 'Sakib', value: 'Sakib' },
  { label: 'Sakiet Sidi Youssef', value: 'Sakiet Sidi Youssef' },
  { label: 'Sakit', value: 'Sakit' },
  { label: 'Sakleshpur', value: 'Sakleshpur' },
  { label: 'Sakmara', value: 'Sakmara' },
  { label: 'Sakol', value: 'Sakol' },
  { label: 'Sakoli', value: 'Sakoli' },
  { label: 'Sakon Nakhon', value: 'Sakon Nakhon' },
  { label: 'Sakrand', value: 'Sakrand' },
  { label: 'Sakri', value: 'Sakri' },
  { label: 'Saks', value: 'Saks' },
  { label: 'Sakskiy Rayon', value: 'Sakskiy Rayon' },
  { label: 'Sakskøbing', value: 'Sakskøbing' },
  { label: 'Sakti', value: 'Sakti' },
  { label: 'Saku', value: 'Saku' },
  { label: 'Saku', value: 'Saku' },
  { label: 'Saku', value: 'Saku' },
  { label: 'Saku Shi', value: 'Saku Shi' },
  { label: 'Saku Vald', value: 'Saku Vald' },
  { label: 'Sakuho', value: 'Sakuho' },
  { label: 'Sakule', value: 'Sakule' },
  { label: 'Sakura', value: 'Sakura' },
  { label: 'Sakuragawa', value: 'Sakuragawa' },
  { label: 'Sakuragawa-Shi', value: 'Sakuragawa-Shi' },
  { label: 'Sakurai', value: 'Sakurai' },
  { label: 'Sakurai-Shi', value: 'Sakurai-Shi' },
  { label: 'Sakura-Shi', value: 'Sakura-Shi' },
  { label: 'Sakura-Shi', value: 'Sakura-Shi' },
  { label: 'Šakvice', value: 'Šakvice' },
  { label: 'Säkylä', value: 'Säkylä' },
  { label: 'Sāl', value: 'Sāl' },
  { label: 'Sal Rei', value: 'Sal Rei' },
  { label: 'Sal’Sk', value: 'Sal’Sk' },
  { label: 'Sala', value: 'Sala' },
  { label: 'Sala', value: 'Sala' },
  { label: 'Sala', value: 'Sala' },
  { label: 'Šaľa', value: 'Šaľa' },
  { label: 'Sala Al Barro', value: 'Sala Al Barro' },
  { label: 'Sala Baganza', value: 'Sala Baganza' },
  { label: 'Sala Biellese', value: 'Sala Biellese' },
  { label: 'Sala Bolognese', value: 'Sala Bolognese' },
  { label: 'Sala Comacina', value: 'Sala Comacina' },
  { label: 'Sala Consilina', value: 'Sala Consilina' },
  { label: 'Sala Monferrato', value: 'Sala Monferrato' },
  { label: 'Salaberry-De-Valleyfield', value: 'Salaberry-De-Valleyfield' },
  { label: 'Sălacea', value: 'Sălacea' },
  { label: 'Salacgrīva', value: 'Salacgrīva' },
  { label: 'Salach', value: 'Salach' },
  { label: 'Saladan', value: 'Saladan' },
  { label: 'Saladas', value: 'Saladas' },
  { label: 'Saladero', value: 'Saladero' },
  { label: 'Salado', value: 'Salado' },
  { label: 'Salado', value: 'Salado' },
  { label: 'Saladoblanco', value: 'Saladoblanco' },
  { label: 'Salafchegan', value: 'Salafchegan' },
  { label: 'Salaga', value: 'Salaga' },
  { label: 'Salagnon', value: 'Salagnon' },
  { label: 'Salah Bey', value: 'Salah Bey' },
  { label: 'Salair', value: 'Salair' },
  { label: 'Salaise-Sur-Sanne', value: 'Salaise-Sur-Sanne' },
  { label: 'Şalākhid', value: 'Şalākhid' },
  { label: 'Salakta', value: 'Salakta' },
  { label: 'Şalālah', value: 'Şalālah' },
  { label: 'Salamá', value: 'Salamá' },
  { label: 'Salamá', value: 'Salamá' },
  { label: 'Salamá', value: 'Salamá' },
  { label: 'Salamah', value: 'Salamah' },
  { label: 'Salamanca', value: 'Salamanca' },
  { label: 'Salamanca', value: 'Salamanca' },
  { label: 'Salamanca', value: 'Salamanca' },
  { label: 'Salamanca', value: 'Salamanca' },
  { label: 'Salamanca', value: 'Salamanca' },
  { label: 'Salamander Bay', value: 'Salamander Bay' },
  { label: 'Salami', value: 'Salami' },
  { label: 'Salamina', value: 'Salamina' },
  { label: 'Salamina', value: 'Salamina' },
  { label: 'Salamína', value: 'Salamína' },
  { label: 'Sāland', value: 'Sāland' },
  { label: 'Sålanda', value: 'Sålanda' },
  { label: 'Salandra', value: 'Salandra' },
  { label: 'Salangen', value: 'Salangen' },
  { label: 'Salanpur Community Development Block', value: 'Salanpur Community Development Block' },
  { label: 'Salanso', value: 'Salanso' },
  { label: 'Salantai', value: 'Salantai' },
  { label: 'Salaparuta', value: 'Salaparuta' },
  { label: 'Salapungan', value: 'Salapungan' },
  { label: 'Salapungan', value: 'Salapungan' },
  { label: 'Salaqi', value: 'Salaqi' },
  { label: 'Salar', value: 'Salar' },
  { label: 'Salara', value: 'Salara' },
  { label: 'Sălard', value: 'Sălard' },
  { label: 'Sălard', value: 'Sălard' },
  { label: 'Salares', value: 'Salares' },
  { label: 'Salas', value: 'Salas' },
  { label: 'Salas Altas', value: 'Salas Altas' },
  { label: 'Salas Bajas', value: 'Salas Bajas' },
  { label: 'Salaš Crnobarski', value: 'Salaš Crnobarski' },
  { label: 'Salas De Bureba', value: 'Salas De Bureba' },
  { label: 'Salas De Los Infantes', value: 'Salas De Los Infantes' },
  { label: 'Salàs De Pallars', value: 'Salàs De Pallars' },
  { label: 'Salaš Noćajski', value: 'Salaš Noćajski' },
  { label: 'Salasco', value: 'Salasco' },
  { label: 'Salas-E Baba Jani', value: 'Salas-E Baba Jani' },
  { label: 'Salaspils', value: 'Salaspils' },
  { label: 'Salassa', value: 'Salassa' },
  { label: 'Sălaşu De Sus', value: 'Sălaşu De Sus' },
  { label: 'Sălașuri', value: 'Sălașuri' },
  { label: 'Sălăţig', value: 'Sălăţig' },
  { label: 'Salatiga', value: 'Salatiga' },
  { label: 'Sălătrucel', value: 'Sălătrucel' },
  { label: 'Sălătrucu', value: 'Sălătrucu' },
  { label: 'Salavan', value: 'Salavan' },
  { label: 'Salavat', value: 'Salavat' },
  { label: 'Salaverry', value: 'Salaverry' },
  { label: 'Salawagan', value: 'Salawagan' },
  { label: 'Salay', value: 'Salay' },
  { label: 'Salaya', value: 'Salaya' },
  { label: 'Salaza', value: 'Salaza' },
  { label: 'Salaza', value: 'Salaza' },
  { label: 'Salazar', value: 'Salazar' },
  { label: 'Salazar', value: 'Salazar' },
  { label: 'Salbert', value: 'Salbert' },
  { label: 'Salbertrand', value: 'Salbertrand' },
  { label: 'Salbris', value: 'Salbris' },
  { label: 'Salcajá', value: 'Salcajá' },
  { label: 'Salce', value: 'Salce' },
  { label: 'Salcea', value: 'Salcea' },
  { label: 'Salceda De Caselas', value: 'Salceda De Caselas' },
  { label: 'Salcedillo', value: 'Salcedillo' },
  { label: 'Salcedo', value: 'Salcedo' },
  { label: 'Salcedo', value: 'Salcedo' },
  { label: 'Salcedo', value: 'Salcedo' },
  { label: 'Salcedo', value: 'Salcedo' },
  { label: 'Salcedo', value: 'Salcedo' },
  { label: 'Salcedo', value: 'Salcedo' },
  { label: 'Salcedo', value: 'Salcedo' },
  { label: 'Salcedo', value: 'Salcedo' },
  { label: 'Salcedo', value: 'Salcedo' },
  { label: 'Salcha', value: 'Salcha' },
  { label: 'Salching', value: 'Salching' },
  { label: 'Salcia', value: 'Salcia' },
  { label: 'Salcia', value: 'Salcia' },
  { label: 'Salcia', value: 'Salcia' },
  { label: 'Salcia', value: 'Salcia' },
  { label: 'Salcia Tudor', value: 'Salcia Tudor' },
  { label: 'Sălciile', value: 'Sălciile' },
  { label: 'Šalčininkai', value: 'Šalčininkai' },
  { label: 'Sălcioara', value: 'Sălcioara' },
  { label: 'Sălcioara', value: 'Sălcioara' },
  { label: 'Sălcioara', value: 'Sălcioara' },
  { label: 'Sălcioara', value: 'Sălcioara' },
  { label: 'Salcito', value: 'Salcito' },
  { label: 'Sălciua De Sus', value: 'Sălciua De Sus' },
  { label: 'Salcombe', value: 'Salcombe' },
  { label: 'Sălcud', value: 'Sălcud' },
  { label: 'Sălcuţa', value: 'Sălcuţa' },
  { label: 'Sălcuța', value: 'Sălcuța' },
  { label: 'Saldán', value: 'Saldán' },
  { label: 'Saldaña', value: 'Saldaña' },
  { label: 'Saldaña', value: 'Saldaña' },
  { label: 'Saldaña De Burgos', value: 'Saldaña De Burgos' },
  { label: 'Saldanha', value: 'Saldanha' },
  { label: 'Saldanha Marinho', value: 'Saldanha Marinho' },
  { label: 'Saldarriaga', value: 'Saldarriaga' },
  { label: 'Saldeana', value: 'Saldeana' },
  { label: 'Saldenburg', value: 'Saldenburg' },
  { label: 'Salderes', value: 'Salderes' },
  { label: 'Saldes', value: 'Saldes' },
  { label: 'Saldías', value: 'Saldías' },
  { label: 'Saldón', value: 'Saldón' },
  { label: 'Salduero', value: 'Salduero' },
  { label: 'Saldus', value: 'Saldus' },
  { label: 'Sale', value: 'Sale' },
  { label: 'Sale', value: 'Sale' },
  { label: 'Sale', value: 'Sale' },
  { label: 'Sale', value: 'Sale' },
  { label: 'Sale Creek', value: 'Sale Creek' },
  { label: 'Sale Delle Langhe', value: 'Sale Delle Langhe' },
  { label: 'Sale Marasino', value: 'Sale Marasino' },
  { label: 'Sale San Giovanni', value: 'Sale San Giovanni' },
  { label: 'Saleh Abad', value: 'Saleh Abad' },
  { label: 'Saleh Abad', value: 'Saleh Abad' },
  { label: 'Salehabad', value: 'Salehabad' },
  { label: 'Salehie', value: 'Salehie' },
  { label: 'Salehshahr', value: 'Salehshahr' },
  { label: 'Saleilles', value: 'Saleilles' },
  { label: 'Salekhard', value: 'Salekhard' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Salem County', value: 'Salem County' },
  { label: 'Salem Heights', value: 'Salem Heights' },
  { label: 'Salemi', value: 'Salemi' },
  { label: 'Salempur', value: 'Salempur' },
  { label: 'Salento', value: 'Salento' },
  { label: 'Salento', value: 'Salento' },
  { label: 'Salerano Canavese', value: 'Salerano Canavese' },
  { label: 'Salerano Sul Lambro', value: 'Salerano Sul Lambro' },
  { label: 'Salernes', value: 'Salernes' },
  { label: 'Salerno', value: 'Salerno' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Sâles', value: 'Sâles' },
  { label: 'Sales De Llierca', value: 'Sales De Llierca' },
  { label: 'Sales Oliveira', value: 'Sales Oliveira' },
  { label: 'Salesópolis', value: 'Salesópolis' },
  { label: 'Salete', value: 'Salete' },
  { label: 'Saletto', value: 'Saletto' },
  { label: 'Saletto-San Bartolomeo', value: 'Saletto-San Bartolomeo' },
  { label: 'Saleux', value: 'Saleux' },
  { label: 'Salford', value: 'Salford' },
  { label: 'Salfords', value: 'Salfords' },
  { label: 'Salgadinho', value: 'Salgadinho' },
  { label: 'Salgadinho', value: 'Salgadinho' },
  { label: 'Salgado', value: 'Salgado' },
  { label: 'Salgado De São Félix', value: 'Salgado De São Félix' },
  { label: 'Salgado Filho', value: 'Salgado Filho' },
  { label: 'Salgar', value: 'Salgar' },
  { label: 'Salgareda', value: 'Salgareda' },
  { label: 'Salgen', value: 'Salgen' },
  { label: 'Salgesch', value: 'Salgesch' },
  { label: 'Salgótarján', value: 'Salgótarján' },
  { label: 'Salgótarjáni Járás', value: 'Salgótarjáni Járás' },
  { label: 'Salgueirais', value: 'Salgueirais' },
  { label: 'Salgueiro', value: 'Salgueiro' },
  { label: 'Salguero', value: 'Salguero' },
  { label: 'Salh', value: 'Salh' },
  { label: 'Salhany', value: 'Salhany' },
  { label: 'Sali', value: 'Sali' },
  { label: 'Sali Vercellese', value: 'Sali Vercellese' },
  { label: 'Salice Salentino', value: 'Salice Salentino' },
  { label: 'Salice Terme', value: 'Salice Terme' },
  { label: 'Saliceto', value: 'Saliceto' },
  { label: 'Salida', value: 'Salida' },
  { label: 'Salida', value: 'Salida' },
  { label: 'Salies-De-Béarn', value: 'Salies-De-Béarn' },
  { label: 'Salies-Du-Salat', value: 'Salies-Du-Salat' },
  { label: 'Saligao', value: 'Saligao' },
  { label: 'Salignac', value: 'Salignac' },
  { label: 'Salignac-Eyvigues', value: 'Salignac-Eyvigues' },
  { label: 'Saligny', value: 'Saligny' },
  { label: 'Saligny', value: 'Saligny' },
  { label: 'Salihli', value: 'Salihli' },
  { label: 'Salihorsk', value: 'Salihorsk' },
  { label: 'Salilak', value: 'Salilak' },
  { label: 'Salillas', value: 'Salillas' },
  { label: 'Salillas De Jalón', value: 'Salillas De Jalón' },
  { label: 'Salima', value: 'Salima' },
  { label: 'Salima District', value: 'Salima District' },
  { label: 'Salimani', value: 'Salimani' },
  { label: 'Salimbalan', value: 'Salimbalan' },
  { label: 'Salimbao', value: 'Salimbao' },
  { label: 'Salimbao', value: 'Salimbao' },
  { label: 'Salina', value: 'Salina' },
  { label: 'Salina', value: 'Salina' },
  { label: 'Salina', value: 'Salina' },
  { label: 'Salina', value: 'Salina' },
  { label: 'Salina Cruz', value: 'Salina Cruz' },
  { label: 'Salinas', value: 'Salinas' },
  { label: 'Salinas', value: 'Salinas' },
  { label: 'Salinas', value: 'Salinas' },
  { label: 'Salinas', value: 'Salinas' },
  { label: 'Salinas', value: 'Salinas' },
  { label: 'Salinas', value: 'Salinas' },
  { label: 'Salinas', value: 'Salinas' },
  { label: 'Salinas', value: 'Salinas' },
  { label: 'Salinas', value: 'Salinas' },
  { label: 'Salinas Da Margarida', value: 'Salinas Da Margarida' },
  { label: 'Salinas De Hidalgo', value: 'Salinas De Hidalgo' },
  { label: 'Salinas De Oro', value: 'Salinas De Oro' },
  { label: 'Salinas De Oro/Jaitz', value: 'Salinas De Oro/Jaitz' },
  { label: 'Salinas De Pisuerga', value: 'Salinas De Pisuerga' },
  { label: 'Salinas Del Manzano', value: 'Salinas Del Manzano' },
  { label: 'Salinas Victoria', value: 'Salinas Victoria' },
  { label: 'Salindres', value: 'Salindres' },
  { label: 'Saline', value: 'Saline' },
  { label: 'Saline', value: 'Saline' },
  { label: 'Saline', value: 'Saline' },
  { label: 'Saline County', value: 'Saline County' },
  { label: 'Saline County', value: 'Saline County' },
  { label: 'Saline County', value: 'Saline County' },
  { label: 'Saline County', value: 'Saline County' },
  { label: 'Saline County', value: 'Saline County' },
  { label: 'Saline Ioniche', value: 'Saline Ioniche' },
  { label: 'Salinera Colán', value: 'Salinera Colán' },
  { label: 'Salines Ses', value: 'Salines Ses' },
  { label: 'Salineville', value: 'Salineville' },
  { label: 'Salingogan', value: 'Salingogan' },
  { label: 'Salinillas De Bureba', value: 'Salinillas De Bureba' },
  { label: 'Salino', value: 'Salino' },
  { label: 'Salinópolis', value: 'Salinópolis' },
  { label: 'Salins', value: 'Salins' },
  { label: 'Salins-Les-Bains', value: 'Salins-Les-Bains' },
  { label: 'Salins-Les-Thermes', value: 'Salins-Les-Thermes' },
  { label: 'Salinungan Proper', value: 'Salinungan Proper' },
  { label: 'Salinungan Proper', value: 'Salinungan Proper' },
  { label: 'Salionze', value: 'Salionze' },
  { label: 'Salıpazarı', value: 'Salıpazarı' },
  { label: 'Salir De Matos', value: 'Salir De Matos' },
  { label: 'Salisano', value: 'Salisano' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury', value: 'Salisbury' },
  { label: 'Salisbury Downs', value: 'Salisbury Downs' },
  { label: 'Salisbury East', value: 'Salisbury East' },
  { label: 'Salisbury Heights', value: 'Salisbury Heights' },
  { label: 'Salisbury North', value: 'Salisbury North' },
  { label: 'Salisbury Park', value: 'Salisbury Park' },
  { label: 'Salisbury Plain', value: 'Salisbury Plain' },
  { label: 'Sălişte', value: 'Sălişte' },
  { label: 'Săliște', value: 'Săliște' },
  { label: 'Săliștea', value: 'Săliștea' },
  { label: 'Săliştea De Sus', value: 'Săliştea De Sus' },
  { label: 'Săliştea-Deal', value: 'Săliştea-Deal' },
  { label: 'Salitral', value: 'Salitral' },
  { label: 'Salitral', value: 'Salitral' },
  { label: 'Salitral De Carrera', value: 'Salitral De Carrera' },
  { label: 'Salitre', value: 'Salitre' },
  { label: 'Salitrillo', value: 'Salitrillo' },
  { label: 'Salitrillo', value: 'Salitrillo' },
  { label: 'Salitrillo', value: 'Salitrillo' },
  { label: 'Salitrillos', value: 'Salitrillos' },
  { label: 'Salitto-Valle', value: 'Salitto-Valle' },
  { label: 'Salix', value: 'Salix' },
  { label: 'Salizzole', value: 'Salizzole' },
  { label: 'Şalkhad', value: 'Şalkhad' },
  { label: 'Salkhad District', value: 'Salkhad District' },
  { label: 'Salla', value: 'Salla' },
  { label: 'Salla', value: 'Salla' },
  { label: 'Sallama', value: 'Sallama' },
  { label: 'Sallanches', value: 'Sallanches' },
  { label: 'Sal-Lapadan', value: 'Sal-Lapadan' },
  { label: 'Sallaumines', value: 'Sallaumines' },
  { label: 'Salle', value: 'Salle' },
  { label: 'Sallebœuf', value: 'Sallebœuf' },
  { label: 'Sallent', value: 'Sallent' },
  { label: 'Sallent De Gállego', value: 'Sallent De Gállego' },
  { label: 'Sallertaine', value: 'Sallertaine' },
  { label: 'Salles', value: 'Salles' },
  { label: 'Salles-Curan', value: 'Salles-Curan' },
  { label: 'Salles-La-Source', value: 'Salles-La-Source' },
  { label: 'Salles-Sur-Mer', value: 'Salles-Sur-Mer' },
  { label: 'Sallgast', value: 'Sallgast' },
  { label: 'Sallingberg', value: 'Sallingberg' },
  { label: 'Sallins', value: 'Sallins' },
  { label: 'Sallisaw', value: 'Sallisaw' },
  { label: 'Salluit', value: 'Salluit' },
  { label: 'Sallynoggin', value: 'Sallynoggin' },
  { label: 'Salman Shahr', value: 'Salman Shahr' },
  { label: 'Salmas', value: 'Salmas' },
  { label: 'Salmerón', value: 'Salmerón' },
  { label: 'Salmeroncillos', value: 'Salmeroncillos' },
  { label: 'Salmi', value: 'Salmi' },
  { label: 'Salmo', value: 'Salmo' },
  { label: 'Salmon', value: 'Salmon' },
  { label: 'Salmon Arm', value: 'Salmon Arm' },
  { label: 'Salmon Brook', value: 'Salmon Brook' },
  { label: 'Salmon Creek', value: 'Salmon Creek' },
  { label: 'Salmoral', value: 'Salmoral' },
  { label: 'Salmour', value: 'Salmour' },
  { label: 'Salmourão', value: 'Salmourão' },
  { label: 'Salmsach', value: 'Salmsach' },
  { label: 'Salmtal', value: 'Salmtal' },
  { label: 'Sălnița', value: 'Sălnița' },
  { label: 'Salo', value: 'Salo' },
  { label: 'Salò', value: 'Salò' },
  { label: 'Saloá', value: 'Saloá' },
  { label: 'Salobral', value: 'Salobral' },
  { label: 'Salobre', value: 'Salobre' },
  { label: 'Salobreña', value: 'Salobreña' },
  { label: 'Saloğlu', value: 'Saloğlu' },
  { label: 'Salogon', value: 'Salogon' },
  { label: 'Salome', value: 'Salome' },
  { label: 'Salomé', value: 'Salomé' },
  { label: 'Salomó', value: 'Salomó' },
  { label: 'Salon', value: 'Salon' },
  { label: 'Salon-De-Provence', value: 'Salon-De-Provence' },
  { label: 'Salonta', value: 'Salonta' },
  { label: 'Salor', value: 'Salor' },
  { label: 'Salorino', value: 'Salorino' },
  { label: 'Salorno', value: 'Salorno' },
  { label: 'Salou', value: 'Salou' },
  { label: 'Salouël', value: 'Salouël' },
  { label: 'Šalovci', value: 'Šalovci' },
  { label: 'Şalpazarı', value: 'Şalpazarı' },
  { label: 'Salqīn', value: 'Salqīn' },
  { label: 'Salreu', value: 'Salreu' },
  { label: 'Salsacate', value: 'Salsacate' },
  { label: 'Salsburgh', value: 'Salsburgh' },
  { label: 'Salses-Le-Château', value: 'Salses-Le-Château' },
  { label: 'Sălsig', value: 'Sălsig' },
  { label: 'Salsipuedes', value: 'Salsipuedes' },
  { label: 'Salsipuedes', value: 'Salsipuedes' },
  { label: 'Salsomaggiore Terme', value: 'Salsomaggiore Terme' },
  { label: 'Salt', value: 'Salt' },
  { label: 'Salt Ash', value: 'Salt Ash' },
  { label: 'Salt Lake City', value: 'Salt Lake City' },
  { label: 'Salt Lake County', value: 'Salt Lake County' },
  { label: 'Salt Marsh', value: 'Salt Marsh' },
  { label: 'Salt River', value: 'Salt River' },
  { label: 'Salt Spring', value: 'Salt Spring' },
  { label: 'Salt Spring Island', value: 'Salt Spring Island' },
  { label: 'Salta', value: 'Salta' },
  { label: 'Sâlța', value: 'Sâlța' },
  { label: 'Saltabarranca', value: 'Saltabarranca' },
  { label: 'Saltair', value: 'Saltair' },
  { label: 'Saltara', value: 'Saltara' },
  { label: 'Saltash', value: 'Saltash' },
  { label: 'Saltburn-By-The-Sea', value: 'Saltburn-By-The-Sea' },
  { label: 'Saltcoats', value: 'Saltcoats' },
  { label: 'Saltdal', value: 'Saltdal' },
  { label: 'Salter Point', value: 'Salter Point' },
  { label: 'Salteras', value: 'Salteras' },
  { label: 'Saltford', value: 'Saltford' },
  { label: 'Saltibus', value: 'Saltibus' },
  { label: 'Saltillo', value: 'Saltillo' },
  { label: 'Saltillo', value: 'Saltillo' },
  { label: 'Saltillo', value: 'Saltillo' },
  { label: 'Saltillo', value: 'Saltillo' },
  { label: 'Saltillo', value: 'Saltillo' },
  { label: 'Saltinho', value: 'Saltinho' },
  { label: 'Saltinho', value: 'Saltinho' },
  { label: 'Salto', value: 'Salto' },
  { label: 'Salto', value: 'Salto' },
  { label: 'Salto Da Divisa', value: 'Salto Da Divisa' },
  { label: 'Salto De Agua', value: 'Salto De Agua' },
  { label: 'Salto De Pirapora', value: 'Salto De Pirapora' },
  { label: 'Salto Del Guairá', value: 'Salto Del Guairá' },
  { label: 'Salto Do Céu', value: 'Salto Do Céu' },
  { label: 'Salto Do Itararé', value: 'Salto Do Itararé' },
  { label: 'Salto Do Jacuí', value: 'Salto Do Jacuí' },
  { label: 'Salto Do Lontra', value: 'Salto Do Lontra' },
  { label: 'Salto Grande', value: 'Salto Grande' },
  { label: 'Salto Veloso', value: 'Salto Veloso' },
  { label: 'Salton City', value: 'Salton City' },
  { label: 'Saltpond', value: 'Saltpond' },
  { label: 'Saltrio', value: 'Saltrio' },
  { label: 'Saltsjöbaden', value: 'Saltsjöbaden' },
  { label: 'Saltvik', value: 'Saltvik' },
  { label: 'Saltville', value: 'Saltville' },
  { label: 'Saltykovka', value: 'Saltykovka' },
  { label: 'Saluda', value: 'Saluda' },
  { label: 'Saluda', value: 'Saluda' },
  { label: 'Saluda County', value: 'Saluda County' },
  { label: 'Saludecio', value: 'Saludecio' },
  { label: 'Salug', value: 'Salug' },
  { label: 'Saluggia', value: 'Saluggia' },
  { label: 'Saluk', value: 'Saluk' },
  { label: 'Salumbar', value: 'Salumbar' },
  { label: 'Salunayan', value: 'Salunayan' },
  { label: 'Salunayan', value: 'Salunayan' },
  { label: 'Salunga', value: 'Salunga' },
  { label: 'Salur', value: 'Salur' },
  { label: 'Salussola', value: 'Salussola' },
  { label: 'Saluzzo', value: 'Saluzzo' },
  { label: 'Salva', value: 'Salva' },
  { label: 'Salvacañete', value: 'Salvacañete' },
  { label: 'Salvacion', value: 'Salvacion' },
  { label: 'Salvacion', value: 'Salvacion' },
  { label: 'Salvacion', value: 'Salvacion' },
  { label: 'Salvacion', value: 'Salvacion' },
  { label: 'Salvacion', value: 'Salvacion' },
  { label: 'Salvacion', value: 'Salvacion' },
  { label: 'Salvacion', value: 'Salvacion' },
  { label: 'Salvación', value: 'Salvación' },
  { label: 'Salvacion I', value: 'Salvacion I' },
  { label: 'Salvacion I', value: 'Salvacion I' },
  { label: 'Salvada', value: 'Salvada' },
  { label: 'Salvadiós', value: 'Salvadiós' },
  { label: 'Salvador', value: 'Salvador' },
  { label: 'Salvador', value: 'Salvador' },
  { label: 'Salvador Das Missões', value: 'Salvador Das Missões' },
  { label: 'Salvador De Zapardiel', value: 'Salvador De Zapardiel' },
  { label: 'Salvador Díaz Mirón', value: 'Salvador Díaz Mirón' },
  { label: 'Salvador Do Sul', value: 'Salvador Do Sul' },
  { label: 'Salvador Rosas Magallón', value: 'Salvador Rosas Magallón' },
  { label: 'Salvador Urbina', value: 'Salvador Urbina' },
  { label: 'Salvaleón', value: 'Salvaleón' },
  { label: 'Salvaleón De Higüey', value: 'Salvaleón De Higüey' },
  { label: 'Salvan', value: 'Salvan' },
  { label: 'Salvaterra', value: 'Salvaterra' },
  { label: 'Salvaterra', value: 'Salvaterra' },
  { label: 'Salvaterra De Magos', value: 'Salvaterra De Magos' },
  { label: 'Salvaterra De Miño', value: 'Salvaterra De Miño' },
  { label: 'Salvatierra', value: 'Salvatierra' },
  { label: 'Salvatierra De Esca', value: 'Salvatierra De Esca' },
  { label: 'Salvatierra De Los Barros', value: 'Salvatierra De Los Barros' },
  { label: 'Salvatierra De Santiago', value: 'Salvatierra De Santiago' },
  { label: 'Salvatierra De Tormes', value: 'Salvatierra De Tormes' },
  { label: 'Salvatierra/Agurain', value: 'Salvatierra/Agurain' },
  { label: 'Salvatronda', value: 'Salvatronda' },
  { label: 'Salve', value: 'Salve' },
  { label: 'Salviac', value: 'Salviac' },
  { label: 'Salvirola', value: 'Salvirola' },
  { label: 'Salvitelle', value: 'Salvitelle' },
  { label: 'Salvo Marina', value: 'Salvo Marina' },
  { label: 'Salwá', value: 'Salwá' },
  { label: 'Sály', value: 'Sály' },
  { label: 'Salyan', value: 'Salyan' },
  { label: 'Salyan ', value: 'Salyan ' },
  { label: 'Salyersville', value: 'Salyersville' },
  { label: 'Salym', value: 'Salym' },
  { label: 'Salz', value: 'Salz' },
  { label: 'Salza Di Pinerolo', value: 'Salza Di Pinerolo' },
  { label: 'Salza Irpina', value: 'Salza Irpina' },
  { label: 'Salzadella La', value: 'Salzadella La' },
  { label: 'Salzano', value: 'Salzano' },
  { label: 'Salzbergen', value: 'Salzbergen' },
  { label: 'Salzburg', value: 'Salzburg' },
  { label: 'Salzburg Stadt', value: 'Salzburg Stadt' },
  { label: 'Salzgitter', value: 'Salzgitter' },
  { label: 'Salzhausen', value: 'Salzhausen' },
  { label: 'Salzhemmendorf', value: 'Salzhemmendorf' },
  { label: 'Salzkotten', value: 'Salzkotten' },
  { label: 'Salzmünde', value: 'Salzmünde' },
  { label: 'Salzwedel', value: 'Salzwedel' },
  { label: 'Salzweg', value: 'Salzweg' },
  { label: 'Sam Marino', value: 'Sam Marino' },
  { label: 'Sam Phran', value: 'Sam Phran' },
  { label: 'Sam Rayburn', value: 'Sam Rayburn' },
  { label: 'Sam Roi Yot', value: 'Sam Roi Yot' },
  { label: 'Sama', value: 'Sama' },
  { label: 'Šamac', value: 'Šamac' },
  { label: 'Samacá', value: 'Samacá' },
  { label: 'Samachique', value: 'Samachique' },
  { label: 'Samadet', value: 'Samadet' },
  { label: 'Samagaltay', value: 'Samagaltay' },
  { label: 'Samahil', value: 'Samahil' },
  { label: 'Samaipata', value: 'Samaipata' },
  { label: 'Samakhiali', value: 'Samakhiali' },
  { label: 'Samakhvalavichy', value: 'Samakhvalavichy' },
  { label: 'Samal', value: 'Samal' },
  { label: 'Samal', value: 'Samal' },
  { label: 'Samal', value: 'Samal' },
  { label: 'Samal', value: 'Samal' },
  { label: 'Samal', value: 'Samal' },
  { label: 'Samalayuca', value: 'Samalayuca' },
  { label: 'Samalkha', value: 'Samalkha' },
  { label: 'Samalkot', value: 'Samalkot' },
  { label: 'Samālūţ', value: 'Samālūţ' },
  { label: 'Samamea', value: 'Samamea' },
  { label: 'Samamiya', value: 'Samamiya' },
  { label: 'Saman', value: 'Saman' },
  { label: 'Samaná', value: 'Samaná' },
  { label: 'Samaná', value: 'Samaná' },
  { label: 'Samandağ', value: 'Samandağ' },
  { label: 'Samaniego', value: 'Samaniego' },
  { label: 'Samaniego', value: 'Samaniego' },
  { label: 'Samannūd', value: 'Samannūd' },
  { label: 'Samara', value: 'Samara' },
  { label: 'Sámara', value: 'Sámara' },
  { label: 'Samarai', value: 'Samarai' },
  { label: 'Samarai Murua', value: 'Samarai Murua' },
  { label: 'Samarate', value: 'Samarate' },
  { label: 'Samarinda', value: 'Samarinda' },
  { label: 'Samarineşti', value: 'Samarineşti' },
  { label: 'Samarkand', value: 'Samarkand' },
  { label: 'Samarkanda', value: 'Samarkanda' },
  { label: 'Samaro', value: 'Samaro' },
  { label: 'Sāmarrā’', value: 'Sāmarrā’' },
  { label: 'Samarskoye', value: 'Samarskoye' },
  { label: 'Samashki', value: 'Samashki' },
  { label: 'Samassi', value: 'Samassi' },
  { label: 'Samastipur', value: 'Samastipur' },
  { label: 'Samatan', value: 'Samatan' },
  { label: 'Samatzai', value: 'Samatzai' },
  { label: 'Samayac', value: 'Samayac' },
  { label: 'Samba', value: 'Samba' },
  { label: 'Sambaíba', value: 'Sambaíba' },
  { label: 'Sambalpur', value: 'Sambalpur' },
  { label: 'Sambas', value: 'Sambas' },
  { label: 'Sâmbăta', value: 'Sâmbăta' },
  { label: 'Sâmbăta De Sus', value: 'Sâmbăta De Sus' },
  { label: 'Sâmbăteni', value: 'Sâmbăteni' },
  { label: 'Sambayanihan Peoples Village', value: 'Sambayanihan Peoples Village' },
  { label: 'Sambek', value: 'Sambek' },
  { label: 'Sambhal', value: 'Sambhal' },
  { label: 'Sambhar', value: 'Sambhar' },
  { label: 'Sambiase', value: 'Sambiase' },
  { label: 'Sambir', value: 'Sambir' },
  { label: 'Sambo Creek', value: 'Sambo Creek' },
  { label: 'Samboal', value: 'Samboal' },
  { label: 'Samboan', value: 'Samboan' },
  { label: 'Samborondón', value: 'Samborondón' },
  { label: 'Samborzec', value: 'Samborzec' },
  { label: 'Sâmbotin', value: 'Sâmbotin' },
  { label: 'Sambrial', value: 'Sambrial' },
  { label: 'Sâmbriaș', value: 'Sâmbriaș' },
  { label: 'Sambruson', value: 'Sambruson' },
  { label: 'Sambú', value: 'Sambú' },
  { label: 'Sambuca', value: 'Sambuca' },
  { label: 'Sambuca Di Sicilia', value: 'Sambuca Di Sicilia' },
  { label: 'Sambuca Pistoiese', value: 'Sambuca Pistoiese' },
  { label: 'Sambuceto', value: 'Sambuceto' },
  { label: 'Sambuci', value: 'Sambuci' },
  { label: 'Sambuco', value: 'Sambuco' },
  { label: 'Sambughe', value: 'Sambughe' },
  { label: 'Sambu-Gun', value: 'Sambu-Gun' },
  { label: 'Sambuluan', value: 'Sambuluan' },
  { label: 'Sambuluan', value: 'Sambuluan' },
  { label: 'Sâmbureşti', value: 'Sâmbureşti' },
  { label: 'Samcheok-Si', value: 'Samcheok-Si' },
  { label: 'Samdari', value: 'Samdari' },
  { label: 'Samdrup Jongkhar', value: 'Samdrup Jongkhar' },
  { label: 'Same', value: 'Same' },
  { label: 'Same', value: 'Same' },
  { label: 'Same', value: 'Same' },
  { label: 'Samedan', value: 'Samedan' },
  { label: 'Sameice', value: 'Sameice' },
  { label: 'Sameiro', value: 'Sameiro' },
  { label: 'Samen', value: 'Samen' },
  { label: 'Samen', value: 'Samen' },
  { label: 'Saméon', value: 'Saméon' },
  { label: 'Samer', value: 'Samer' },
  { label: 'Samerberg', value: 'Samerberg' },
  { label: 'Samford Valley', value: 'Samford Valley' },
  { label: 'Samfya', value: 'Samfya' },
  { label: 'Samho-Rodongjagu', value: 'Samho-Rodongjagu' },
  { label: 'Sami', value: 'Sami' },
  { label: 'Sámi', value: 'Sámi' },
  { label: 'Sami District', value: 'Sami District' },
  { label: 'Samil', value: 'Samil' },
  { label: 'Saminaka', value: 'Saminaka' },
  { label: 'Samir De Los Caños', value: 'Samir De Los Caños' },
  { label: 'Şāmitah', value: 'Şāmitah' },
  { label: 'Şammā', value: 'Şammā' },
  { label: 'Sammamish', value: 'Sammamish' },
  { label: 'Sammatti', value: 'Sammatti' },
  { label: 'Sammeron', value: 'Sammeron' },
  { label: 'Sammersdorf', value: 'Sammersdorf' },
  { label: 'Sammichele Di Bari', value: 'Sammichele Di Bari' },
  { label: 'Samnanger', value: 'Samnanger' },
  { label: 'Samo', value: 'Samo' },
  { label: 'Samobor', value: 'Samobor' },
  { label: 'Samoded', value: 'Samoded' },
  { label: 'Samoëns', value: 'Samoëns' },
  { label: 'Samois-Sur-Seine', value: 'Samois-Sur-Seine' },
  { label: 'Samokov', value: 'Samokov' },
  { label: 'Samokov', value: 'Samokov' },
  { label: 'Samolaco', value: 'Samolaco' },
  { label: 'Samoljica', value: 'Samoljica' },
  { label: 'Samon', value: 'Samon' },
  { label: 'Samon', value: 'Samon' },
  { label: 'Samone', value: 'Samone' },
  { label: 'Samone', value: 'Samone' },
  { label: 'Samora Correia', value: 'Samora Correia' },
  { label: 'Samoreau', value: 'Samoreau' },
  { label: 'Šamorín', value: 'Šamorín' },
  { label: 'Samos', value: 'Samos' },
  { label: 'Samoš', value: 'Samoš' },
  { label: 'Samoset', value: 'Samoset' },
  { label: 'Samothráki', value: 'Samothráki' },
  { label: 'Samotíšky', value: 'Samotíšky' },
  { label: 'Samouco', value: 'Samouco' },
  { label: 'Samoylovka', value: 'Samoylovka' },
  { label: 'Samoylovsky', value: 'Samoylovsky' },
  { label: 'Sampacho', value: 'Sampacho' },
  { label: 'Sampagar', value: 'Sampagar' },
  { label: 'Sampaio', value: 'Sampaio' },
  { label: 'Sampaloc', value: 'Sampaloc' },
  { label: 'Sampaloc', value: 'Sampaloc' },
  { label: 'Sampaloc', value: 'Sampaloc' },
  { label: 'Sampang', value: 'Sampang' },
  { label: 'Sampao', value: 'Sampao' },
  { label: 'Sampao', value: 'Sampao' },
  { label: 'Sampao', value: 'Sampao' },
  { label: 'Sampao', value: 'Sampao' },
  { label: 'Sampao', value: 'Sampao' },
  { label: 'Samper De Calanda', value: 'Samper De Calanda' },
  { label: 'Samper Del Salz', value: 'Samper Del Salz' },
  { label: 'Sampeyre', value: 'Sampeyre' },
  { label: 'Sampford Peverell', value: 'Sampford Peverell' },
  { label: 'Samphanthawong', value: 'Samphanthawong' },
  { label: 'Sampiro', value: 'Sampiro' },
  { label: 'Sampit', value: 'Sampit' },
  { label: 'Sampov Meas', value: 'Sampov Meas' },
  { label: 'Sampson County', value: 'Sampson County' },
  { label: 'Sampsonievskiy', value: 'Sampsonievskiy' },
  { label: 'Sampués', value: 'Sampués' },
  { label: 'Sampurskiy Rayon', value: 'Sampurskiy Rayon' },
  { label: 'Samrala', value: 'Samrala' },
  { label: 'Samraong', value: 'Samraong' },
  { label: 'Samsami', value: 'Samsami' },
  { label: 'Samsat', value: 'Samsat' },
  { label: 'Samsø Kommune', value: 'Samsø Kommune' },
  { label: 'Samson', value: 'Samson' },
  { label: 'Samson', value: 'Samson' },
  { label: 'Samstagern', value: 'Samstagern' },
  { label: 'Şamşud', value: 'Şamşud' },
  { label: 'Samsula-Spruce Creek', value: 'Samsula-Spruce Creek' },
  { label: 'Samswegen', value: 'Samswegen' },
  { label: 'Samtens', value: 'Samtens' },
  { label: 'Samthar', value: 'Samthar' },
  { label: 'Samtredia', value: 'Samtredia' },
  { label: 'Samtse', value: 'Samtse' },
  { label: 'Samudrapur', value: 'Samudrapur' },
  { label: 'Samuels Prospect', value: 'Samuels Prospect' },
  { label: 'Samugheo', value: 'Samugheo' },
  { label: 'Samuhú', value: 'Samuhú' },
  { label: 'Samuil', value: 'Samuil' },
  { label: 'Samur', value: 'Samur' },
  { label: 'Samur', value: 'Samur' },
  { label: 'Samus’', value: 'Samus’' },
  { label: 'Samut Prakan', value: 'Samut Prakan' },
  { label: 'Samut Sakhon', value: 'Samut Sakhon' },
  { label: 'Samut Songkhram', value: 'Samut Songkhram' },
  { label: 'Samux', value: 'Samux' },
  { label: 'Samuxlu', value: 'Samuxlu' },
  { label: 'San', value: 'San' },
  { label: 'San Acateno', value: 'San Acateno' },
  { label: 'San Adrián', value: 'San Adrián' },
  { label: 'San Adrián De Juarros', value: 'San Adrián De Juarros' },
  { label: 'San Adrián Del Valle', value: 'San Adrián Del Valle' },
  { label: 'San Agustin', value: 'San Agustin' },
  { label: 'San Agustin', value: 'San Agustin' },
  { label: 'San Agustin', value: 'San Agustin' },
  { label: 'San Agustin', value: 'San Agustin' },
  { label: 'San Agustin', value: 'San Agustin' },
  { label: 'San Agustin', value: 'San Agustin' },
  { label: 'San Agustin', value: 'San Agustin' },
  { label: 'San Agustin', value: 'San Agustin' },
  { label: 'San Agustín', value: 'San Agustín' },
  { label: 'San Agustín', value: 'San Agustín' },
  { label: 'San Agustín', value: 'San Agustín' },
  { label: 'San Agustín', value: 'San Agustín' },
  { label: 'San Agustín', value: 'San Agustín' },
  { label: 'San Agustín', value: 'San Agustín' },
  { label: 'San Agustín', value: 'San Agustín' },
  { label: 'San Agustín', value: 'San Agustín' },
  { label: 'San Agustín Acasaguastlán', value: 'San Agustín Acasaguastlán' },
  { label: 'San Agustín Amatengo', value: 'San Agustín Amatengo' },
  { label: 'San Agustín Atenango', value: 'San Agustín Atenango' },
  { label: 'San Agustín Atzompa', value: 'San Agustín Atzompa' },
  { label: 'San Agustín Berros', value: 'San Agustín Berros' },
  { label: 'San Agustín Buenavista', value: 'San Agustín Buenavista' },
  { label: 'San Agustín Chayuco', value: 'San Agustín Chayuco' },
  { label: 'San Agustín Citlali', value: 'San Agustín Citlali' },
  { label: 'San Agustín De Las Flores', value: 'San Agustín De Las Flores' },
  { label: 'San Agustin De Las Juntas', value: 'San Agustin De Las Juntas' },
  { label: 'San Agustín De Las Palmas', value: 'San Agustín De Las Palmas' },
  { label: 'San Agustín De Valle Fértil', value: 'San Agustín De Valle Fértil' },
  { label: 'San Agustín Del Guadalix', value: 'San Agustín Del Guadalix' },
  { label: 'San Agustín Del Maíz', value: 'San Agustín Del Maíz' },
  { label: 'San Agustín Del Palmar', value: 'San Agustín Del Palmar' },
  { label: 'San Agustín Del Pozo', value: 'San Agustín Del Pozo' },
  { label: 'San Agustín Del Pulque', value: 'San Agustín Del Pulque' },
  { label: 'San Agustín Etla', value: 'San Agustín Etla' },
  { label: 'San Agustín Huitzizilapan', value: 'San Agustín Huitzizilapan' },
  { label: 'San Agustín Huixaxtla', value: 'San Agustín Huixaxtla' },
  { label: 'San Agustín Loxicha', value: 'San Agustín Loxicha' },
  { label: 'San Agustín Mextepec', value: 'San Agustín Mextepec' },
  { label: 'San Agustín Mimbres', value: 'San Agustín Mimbres' },
  { label: 'San Agustín Oapan', value: 'San Agustín Oapan' },
  { label: 'San Agustín Tlaxco', value: 'San Agustín Tlaxco' },
  { label: 'San Agustín Tlaxiaca', value: 'San Agustín Tlaxiaca' },
  { label: 'San Agustín Yatareni', value: 'San Agustín Yatareni' },
  { label: 'San Agustín Zapotlán', value: 'San Agustín Zapotlán' },
  { label: 'San Alberto', value: 'San Alberto' },
  { label: 'San Alberto', value: 'San Alberto' },
  { label: 'San Alejandro', value: 'San Alejandro' },
  { label: 'San Alejandro', value: 'San Alejandro' },
  { label: 'San Alejo', value: 'San Alejo' },
  { label: 'San Alejo', value: 'San Alejo' },
  { label: 'San Alfonso', value: 'San Alfonso' },
  { label: 'San Alfonso', value: 'San Alfonso' },
  { label: 'San Alfonso', value: 'San Alfonso' },
  { label: 'San Amaro', value: 'San Amaro' },
  { label: 'San Andreas', value: 'San Andreas' },
  { label: 'San Andres', value: 'San Andres' },
  { label: 'San Andres', value: 'San Andres' },
  { label: 'San Andres', value: 'San Andres' },
  { label: 'San Andres', value: 'San Andres' },
  { label: 'San Andrés', value: 'San Andrés' },
  { label: 'San Andrés', value: 'San Andrés' },
  { label: 'San Andrés', value: 'San Andrés' },
  { label: 'San Andrés', value: 'San Andrés' },
  { label: 'San Andrés', value: 'San Andrés' },
  { label: 'San Andrés', value: 'San Andrés' },
  { label: 'San Andrés', value: 'San Andrés' },
  { label: 'San Andrés', value: 'San Andrés' },
  { label: 'San Andrés (San Andrés Chichayotla)', value: 'San Andrés (San Andrés Chichayotla)' },
  { label: 'San Andrés Ahuashuatepec', value: 'San Andrés Ahuashuatepec' },
  { label: 'San Andrés Azumiatla', value: 'San Andrés Azumiatla' },
  { label: 'San Andrés Cacaloapan', value: 'San Andrés Cacaloapan' },
  { label: 'San Andrés Calpan', value: 'San Andrés Calpan' },
  { label: 'San Andrés Chicahuaxtla', value: 'San Andrés Chicahuaxtla' },
  { label: 'San Andrés Cholula', value: 'San Andrés Cholula' },
  { label: 'San Andrés Cohamiata', value: 'San Andrés Cohamiata' },
  { label: 'San Andrés Coru', value: 'San Andrés Coru' },
  { label: 'San Andrés Cuexcontitlán', value: 'San Andrés Cuexcontitlán' },
  { label: 'San Andrés De Cuerquía', value: 'San Andrés De Cuerquía' },
  { label: 'San Andrés De La Cal', value: 'San Andrés De La Cal' },
  { label: 'San Andrés De Las Peras', value: 'San Andrés De Las Peras' },
  { label: 'San Andrés De Los Gama', value: 'San Andrés De Los Gama' },
  { label: 'San Andrés De Sotavento', value: 'San Andrés De Sotavento' },
  { label: 'San Andrés Del Congosto', value: 'San Andrés Del Congosto' },
  { label: 'San Andrés Del Pedregal', value: 'San Andrés Del Pedregal' },
  { label: 'San Andrés Del Rabanedo', value: 'San Andrés Del Rabanedo' },
  { label: 'San Andrés Del Rey', value: 'San Andrés Del Rey' },
  { label: 'San Andrés Dinicuiti', value: 'San Andrés Dinicuiti' },
  { label: 'San Andrés Duraznal', value: 'San Andrés Duraznal' },
  { label: 'San Andrés Enguaro', value: 'San Andrés Enguaro' },
  { label: 'San Andrés Hidalgo', value: 'San Andrés Hidalgo' },
  { label: 'San Andrés Huaxpaltepec', value: 'San Andrés Huaxpaltepec' },
  { label: 'San Andrés Huayápam', value: 'San Andrés Huayápam' },
  { label: 'San Andrés Hueyacatitla', value: 'San Andrés Hueyacatitla' },
  { label: 'San Andrés Itzapa', value: 'San Andrés Itzapa' },
  { label: 'San Andrés Ixtlahuaca', value: 'San Andrés Ixtlahuaca' },
  { label: 'San Andrés Ixtlán', value: 'San Andrés Ixtlán' },
  { label: 'San Andrés Metla', value: 'San Andrés Metla' },
  { label: 'San Andrés Mixquic', value: 'San Andrés Mixquic' },
  { label: 'San Andrés Nicolás Bravo', value: 'San Andrés Nicolás Bravo' },
  { label: 'San Andrés Ocotepec', value: 'San Andrés Ocotepec' },
  { label: 'San Andrés Ocotlán', value: 'San Andrés Ocotlán' },
  { label: 'San Andrés Paxtlán', value: 'San Andrés Paxtlán' },
  { label: 'San Andrés Payuca', value: 'San Andrés Payuca' },
  { label: 'San Andrés Playa Encantada (El Podrido)', value: 'San Andrés Playa Encantada (El Podrido)' },
  { label: 'San Andrés Sajcabajá', value: 'San Andrés Sajcabajá' },
  { label: 'San Andrés Semetabaj', value: 'San Andrés Semetabaj' },
  { label: 'San Andrés Sinaxtla', value: 'San Andrés Sinaxtla' },
  { label: 'San Andrés Solaga', value: 'San Andrés Solaga' },
  { label: 'San Andrés Tenejapan', value: 'San Andrés Tenejapan' },
  { label: 'San Andrés Teotilalpam', value: 'San Andrés Teotilalpam' },
  { label: 'San Andrés Tepetitlán', value: 'San Andrés Tepetitlán' },
  { label: 'San Andrés Timilpan', value: 'San Andrés Timilpan' },
  { label: 'San Andrés Tlalamac', value: 'San Andrés Tlalamac' },
  { label: 'San Andrés Tuxtla', value: 'San Andrés Tuxtla' },
  { label: 'San Andrés Villa Seca', value: 'San Andrés Villa Seca' },
  { label: 'San Andrés Xecul', value: 'San Andrés Xecul' },
  { label: 'San Andrés Y Sauces', value: 'San Andrés Y Sauces' },
  { label: 'San Andrés Yaá', value: 'San Andrés Yaá' },
  { label: 'San Andrés Yahuitlalpan', value: 'San Andrés Yahuitlalpan' },
  { label: 'San Andrés Zabache', value: 'San Andrés Zabache' },
  { label: 'San Andrés Zautla', value: 'San Andrés Zautla' },
  { label: 'San Andrés Ziróndaro', value: 'San Andrés Ziróndaro' },
  { label: 'San Andros', value: 'San Andros' },
  { label: 'San Angel', value: 'San Angel' },
  { label: 'San Ángel', value: 'San Ángel' },
  { label: 'San Ángel', value: 'San Ángel' },
  { label: 'San Angelo', value: 'San Angelo' },
  { label: 'San Anselmo', value: 'San Anselmo' },
  { label: 'San Antero', value: 'San Antero' },
  { label: 'San Anton', value: 'San Anton' },
  { label: 'San Anton', value: 'San Anton' },
  { label: 'San Antonino Castillo Velasco', value: 'San Antonino Castillo Velasco' },
  { label: 'San Antonino El Alto', value: 'San Antonino El Alto' },
  { label: 'San Antonino Monte Verde', value: 'San Antonino Monte Verde' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Antonio Acahualco', value: 'San Antonio Acahualco' },
  { label: 'San Antonio Aguas Calientes', value: 'San Antonio Aguas Calientes' },
  { label: 'San Antonio Alpanocan', value: 'San Antonio Alpanocan' },
  { label: 'San Antonio Arrazola', value: 'San Antonio Arrazola' },
  { label: 'San Antonio Atotonilco', value: 'San Antonio Atotonilco' },
  { label: 'San Antonio Bonixi', value: 'San Antonio Bonixi' },
  { label: 'San Antonio Buenavista', value: 'San Antonio Buenavista' },
  { label: 'San Antonio Buenavista', value: 'San Antonio Buenavista' },
  { label: 'San Antonio Bulujib', value: 'San Antonio Bulujib' },
  { label: 'San Antonio Calichar', value: 'San Antonio Calichar' },
  { label: 'San Antonio Cañada', value: 'San Antonio Cañada' },
  { label: 'San Antonio Cárdenas', value: 'San Antonio Cárdenas' },
  { label: 'San Antonio Chiverías', value: 'San Antonio Chiverías' },
  { label: 'San Antonio Coyahuacán', value: 'San Antonio Coyahuacán' },
  { label: 'San Antonio De Alamitos', value: 'San Antonio De Alamitos' },
  { label: 'San Antonio De Benagéber', value: 'San Antonio De Benagéber' },
  { label: 'San Antonio De Corrales', value: 'San Antonio De Corrales' },
  { label: 'San Antonio De Cortés', value: 'San Antonio De Cortés' },
  { label: 'San Antonio De Flores', value: 'San Antonio De Flores' },
  { label: 'San Antonio De Flores', value: 'San Antonio De Flores' },
  { label: 'San Antonio De La Cal', value: 'San Antonio De La Cal' },
  { label: 'San Antonio De La Cal', value: 'San Antonio De La Cal' },
  { label: 'San Antonio De La Cuesta', value: 'San Antonio De La Cuesta' },
  { label: 'San Antonio De La Laguna', value: 'San Antonio De La Laguna' },
  { label: 'San Antonio De Las Alazanas', value: 'San Antonio De Las Alazanas' },
  { label: 'San Antonio De Las Huertas', value: 'San Antonio De Las Huertas' },
  { label: 'San Antonio De Las Huertas', value: 'San Antonio De Las Huertas' },
  { label: 'San Antonio De Las Palmas', value: 'San Antonio De Las Palmas' },
  { label: 'San Antonio De Litín', value: 'San Antonio De Litín' },
  { label: 'San Antonio De Los Altos', value: 'San Antonio De Los Altos' },
  { label: 'San Antonio De Los Baños', value: 'San Antonio De Los Baños' },
  { label: 'San Antonio De Los Barrera', value: 'San Antonio De Los Barrera' },
  { label: 'San Antonio De Los Cobres', value: 'San Antonio De Los Cobres' },
  { label: 'San Antonio De Los Horcones', value: 'San Antonio De Los Horcones' },
  { label: 'San Antonio De Los Morales', value: 'San Antonio De Los Morales' },
  { label: 'San Antonio De Los Ríos', value: 'San Antonio De Los Ríos' },
  { label: 'San Antonio De Los Tepetates', value: 'San Antonio De Los Tepetates' },
  { label: 'San Antonio De Los Vázquez', value: 'San Antonio De Los Vázquez' },
  { label: 'San Antonio De Oriente', value: 'San Antonio De Oriente' },
  { label: 'San Antonio De Palé', value: 'San Antonio De Palé' },
  { label: 'San Antonio De Peñuelas', value: 'San Antonio De Peñuelas' },
  { label: 'San Antonio De Putina', value: 'San Antonio De Putina' },
  { label: 'San Antonio De Rivas', value: 'San Antonio De Rivas' },
  { label: 'San Antonio De Romerillo', value: 'San Antonio De Romerillo' },
  { label: 'San Antonio Del Cipres', value: 'San Antonio Del Cipres' },
  { label: 'San Antonio Del Coyote', value: 'San Antonio Del Coyote' },
  { label: 'San Antonio Del Estrecho', value: 'San Antonio Del Estrecho' },
  { label: 'San Antonio Del Monte', value: 'San Antonio Del Monte' },
  { label: 'San Antonio Del Monte', value: 'San Antonio Del Monte' },
  { label: 'San Antonio Del Norte', value: 'San Antonio Del Norte' },
  { label: 'San Antonio Del Puente', value: 'San Antonio Del Puente' },
  { label: 'San Antonio Del Rosario', value: 'San Antonio Del Rosario' },
  { label: 'San Antonio Del Sur', value: 'San Antonio Del Sur' },
  { label: 'San Antonio Del Táchira', value: 'San Antonio Del Táchira' },
  { label: 'San Antonio Del Tequendama', value: 'San Antonio Del Tequendama' },
  { label: 'San Antonio El Chico', value: 'San Antonio El Chico' },
  { label: 'San Antonio El Grande', value: 'San Antonio El Grande' },
  { label: 'San Antonio El Llanito', value: 'San Antonio El Llanito' },
  { label: 'San Antonio El Paso', value: 'San Antonio El Paso' },
  { label: 'San Antonio El Rico', value: 'San Antonio El Rico' },
  { label: 'San Antonio Eménguaro', value: 'San Antonio Eménguaro' },
  { label: 'San Antonio Enchisi', value: 'San Antonio Enchisi' },
  { label: 'San Antonio Gallardo', value: 'San Antonio Gallardo' },
  { label: 'San Antonio Guaracha', value: 'San Antonio Guaracha' },
  { label: 'San Antonio Heights', value: 'San Antonio Heights' },
  { label: 'San Antonio Huista', value: 'San Antonio Huista' },
  { label: 'San Antonio Huitepec', value: 'San Antonio Huitepec' },
  { label: 'San Antonio Ilotenango', value: 'San Antonio Ilotenango' },
  { label: 'San Antonio Juárez', value: 'San Antonio Juárez' },
  { label: 'San Antonio La Isla', value: 'San Antonio La Isla' },
  { label: 'San Antonio La Labor', value: 'San Antonio La Labor' },
  { label: 'San Antonio La Paz', value: 'San Antonio La Paz' },
  { label: 'San Antonio La Portilla', value: 'San Antonio La Portilla' },
  { label: 'San Antonio Las Palmas', value: 'San Antonio Las Palmas' },
  { label: 'San Antonio Matlahuacales', value: 'San Antonio Matlahuacales' },
  { label: 'San Antonio Mihuacán', value: 'San Antonio Mihuacán' },
  { label: 'San Antonio Molinos', value: 'San Antonio Molinos' },
  { label: 'San Antonio Nixini', value: 'San Antonio Nixini' },
  { label: 'San Antonio Ocopetlatlán', value: 'San Antonio Ocopetlatlán' },
  { label: 'San Antonio Ocotlán', value: 'San Antonio Ocotlán' },
  { label: 'San Antonio Oeste', value: 'San Antonio Oeste' },
  { label: 'San Antonio Palopó', value: 'San Antonio Palopó' },
  { label: 'San Antonio Portezuelo', value: 'San Antonio Portezuelo' },
  { label: 'San Antonio Primero', value: 'San Antonio Primero' },
  { label: 'San Antonio Rayón', value: 'San Antonio Rayón' },
  { label: 'San Antonio Sacatepéquez', value: 'San Antonio Sacatepéquez' },
  { label: 'San Antonio Sahcabchén', value: 'San Antonio Sahcabchén' },
  { label: 'San Antonio Soledad', value: 'San Antonio Soledad' },
  { label: 'San Antonio Solís', value: 'San Antonio Solís' },
  { label: 'San Antonio Suchitepéquez', value: 'San Antonio Suchitepéquez' },
  { label: 'San Antonio Tariácuri', value: 'San Antonio Tariácuri' },
  { label: 'San Antonio Tecolco', value: 'San Antonio Tecolco' },
  { label: 'San Antonio Tecómitl', value: 'San Antonio Tecómitl' },
  { label: 'San Antonio Tedzidz', value: 'San Antonio Tedzidz' },
  { label: 'San Antonio Tepetlapa', value: 'San Antonio Tepetlapa' },
  { label: 'San Antonio Texas', value: 'San Antonio Texas' },
  { label: 'San Antonio Tlacamilco', value: 'San Antonio Tlacamilco' },
  { label: 'San Antonio Tlaltecahuacán', value: 'San Antonio Tlaltecahuacán' },
  { label: 'San Antonio Tlatenco', value: 'San Antonio Tlatenco' },
  { label: 'San Antonio Villalongín', value: 'San Antonio Villalongín' },
  { label: 'San Antonio Virreyes', value: 'San Antonio Virreyes' },
  { label: 'San Antonio Xahuento', value: 'San Antonio Xahuento' },
  { label: 'San Antonio Xoquitla', value: 'San Antonio Xoquitla' },
  { label: 'San Antonio Zaragoza', value: 'San Antonio Zaragoza' },
  { label: 'San Aquilino', value: 'San Aquilino' },
  { label: 'San Asensio', value: 'San Asensio' },
  { label: 'San Atenógenes (La Villita)', value: 'San Atenógenes (La Villita)' },
  { label: 'San Augustine', value: 'San Augustine' },
  { label: 'San Augustine County', value: 'San Augustine County' },
  { label: 'San Baltazar Atlimeyaya', value: 'San Baltazar Atlimeyaya' },
  { label: 'San Baltazar Chichicapam', value: 'San Baltazar Chichicapam' },
  { label: 'San Baltazar Guelavila', value: 'San Baltazar Guelavila' },
  { label: 'San Baltazar Loxicha', value: 'San Baltazar Loxicha' },
  { label: 'San Baltazar Yatzachi El Bajo', value: 'San Baltazar Yatzachi El Bajo' },
  { label: 'San Bartolito Tlaltelolco', value: 'San Bartolito Tlaltelolco' },
  { label: 'San Bartolo', value: 'San Bartolo' },
  { label: 'San Bartolo', value: 'San Bartolo' },
  { label: 'San Bartolo', value: 'San Bartolo' },
  { label: 'San Bartolo', value: 'San Bartolo' },
  { label: 'San Bartolo', value: 'San Bartolo' },
  { label: 'San Bartolo Coyotepec', value: 'San Bartolo Coyotepec' },
  { label: 'San Bartolo Coyotespec', value: 'San Bartolo Coyotespec' },
  { label: 'San Bartolo Cuautlalpan', value: 'San Bartolo Cuautlalpan' },
  { label: 'San Bartolo Cuitareo', value: 'San Bartolo Cuitareo' },
  { label: 'San Bartolo De Berrios', value: 'San Bartolo De Berrios' },
  { label: 'San Bartolo Del Llano', value: 'San Bartolo Del Llano' },
  { label: 'San Bartolo Del Llano (San Isidro)', value: 'San Bartolo Del Llano (San Isidro)' },
  { label: 'San Bartolo Del Progreso', value: 'San Bartolo Del Progreso' },
  { label: 'San Bartolo El Viejo', value: 'San Bartolo El Viejo' },
  { label: 'San Bartolo Lanzados', value: 'San Bartolo Lanzados' },
  { label: 'San Bartolo Morelos', value: 'San Bartolo Morelos' },
  { label: 'San Bartolo Oxtotitlán', value: 'San Bartolo Oxtotitlán' },
  { label: 'San Bartolo Ozocalpan', value: 'San Bartolo Ozocalpan' },
  { label: 'San Bartolo Tutotepec', value: 'San Bartolo Tutotepec' },
  { label: 'San Bartolo Yautepec', value: 'San Bartolo Yautepec' },
  { label: 'San Bartolomé', value: 'San Bartolomé' },
  { label: 'San Bartolomé', value: 'San Bartolomé' },
  { label: 'San Bartolomé', value: 'San Bartolomé' },
  { label: 'San Bartolomé Aguas Calientes', value: 'San Bartolomé Aguas Calientes' },
  { label: 'San Bartolomé Atlatlahuca', value: 'San Bartolomé Atlatlahuca' },
  { label: 'San Bartolomé Ayautla', value: 'San Bartolomé Ayautla' },
  { label: 'San Bartolomé Coatepec', value: 'San Bartolomé Coatepec' },
  { label: 'San Bartolomé Cuahuixmatlac', value: 'San Bartolomé Cuahuixmatlac' },
  { label: 'San Bartolomé De Béjar', value: 'San Bartolomé De Béjar' },
  { label: 'San Bartolomé De Corneja', value: 'San Bartolomé De Corneja' },
  { label: 'San Bartolomé De La Torre', value: 'San Bartolomé De La Torre' },
  { label: 'San Bartolomé De Las Abiertas', value: 'San Bartolomé De Las Abiertas' },
  { label: 'San Bartolomé De Pinares', value: 'San Bartolomé De Pinares' },
  { label: 'San Bartolomé De Tirajana', value: 'San Bartolomé De Tirajana' },
  { label: 'San Bartolomé Hueyapan', value: 'San Bartolomé Hueyapan' },
  { label: 'San Bartolomé Jocotenango', value: 'San Bartolomé Jocotenango' },
  { label: 'San Bartolomé Loxícha', value: 'San Bartolomé Loxícha' },
  { label: 'San Bartolomé Milpas Altas', value: 'San Bartolomé Milpas Altas' },
  { label: 'San Bartolomé Quialana', value: 'San Bartolomé Quialana' },
  { label: 'San Bartolomé Tenango', value: 'San Bartolomé Tenango' },
  { label: 'San Bartolomé Tlaltelulco', value: 'San Bartolomé Tlaltelulco' },
  { label: 'San Bartolome Xicomulco', value: 'San Bartolome Xicomulco' },
  { label: 'San Bartolomeo', value: 'San Bartolomeo' },
  { label: 'San Bartolomeo', value: 'San Bartolomeo' },
  { label: 'San Bartolomeo Al Mare', value: 'San Bartolomeo Al Mare' },
  { label: 'San Bartolomeo In Bosco', value: 'San Bartolomeo In Bosco' },
  { label: 'San Bartolomeo In Galdo', value: 'San Bartolomeo In Galdo' },
  { label: 'San Bartolomeo Val Cavargna', value: 'San Bartolomeo Val Cavargna' },
  { label: 'San Basile', value: 'San Basile' },
  { label: 'San Basilio', value: 'San Basilio' },
  { label: 'San Basilio', value: 'San Basilio' },
  { label: 'San Basilio', value: 'San Basilio' },
  { label: 'San Basilio', value: 'San Basilio' },
  { label: 'San Bassano', value: 'San Bassano' },
  { label: 'San Bautista', value: 'San Bautista' },
  { label: 'San Bellino', value: 'San Bellino' },
  { label: 'San Benedetto Belbo', value: 'San Benedetto Belbo' },
  { label: 'San Benedetto Dei Marsi', value: 'San Benedetto Dei Marsi' },
  { label: 'San Benedetto Del Tronto', value: 'San Benedetto Del Tronto' },
  { label: 'San Benedetto In Perillis', value: 'San Benedetto In Perillis' },
  { label: 'San Benedetto Po', value: 'San Benedetto Po' },
  { label: 'San Benedetto Ullano', value: 'San Benedetto Ullano' },
  { label: 'San Benedetto Val Di Sambro', value: 'San Benedetto Val Di Sambro' },
  { label: 'San Benigno Canavese', value: 'San Benigno Canavese' },
  { label: 'San Benito', value: 'San Benito' },
  { label: 'San Benito', value: 'San Benito' },
  { label: 'San Benito', value: 'San Benito' },
  { label: 'San Benito', value: 'San Benito' },
  { label: 'San Benito', value: 'San Benito' },
  { label: 'San Benito', value: 'San Benito' },
  { label: 'San Benito', value: 'San Benito' },
  { label: 'San Benito', value: 'San Benito' },
  { label: 'San Benito', value: 'San Benito' },
  { label: 'San Benito Abad', value: 'San Benito Abad' },
  { label: 'San Benito County', value: 'San Benito County' },
  { label: 'San Benito Encinal', value: 'San Benito Encinal' },
  { label: 'San Benito Xaltocan', value: 'San Benito Xaltocan' },
  { label: 'San Bernabé De Las Canteras', value: 'San Bernabé De Las Canteras' },
  { label: 'San Bernabé Temoxtitla', value: 'San Bernabé Temoxtitla' },
  { label: 'San Bernardino', value: 'San Bernardino' },
  { label: 'San Bernardino', value: 'San Bernardino' },
  { label: 'San Bernardino', value: 'San Bernardino' },
  { label: 'San Bernardino', value: 'San Bernardino' },
  { label: 'San Bernardino County', value: 'San Bernardino County' },
  { label: 'San Bernardino De Milpillas Chico', value: 'San Bernardino De Milpillas Chico' },
  { label: 'San Bernardino Lagunas', value: 'San Bernardino Lagunas' },
  { label: 'San Bernardino Tlaxcalancingo', value: 'San Bernardino Tlaxcalancingo' },
  { label: 'San Bernardino Verbano', value: 'San Bernardino Verbano' },
  { label: 'San Bernardo', value: 'San Bernardo' },
  { label: 'San Bernardo', value: 'San Bernardo' },
  { label: 'San Bernardo', value: 'San Bernardo' },
  { label: 'San Bernardo', value: 'San Bernardo' },
  { label: 'San Bernardo', value: 'San Bernardo' },
  { label: 'San Bernardo', value: 'San Bernardo' },
  { label: 'San Bernardo', value: 'San Bernardo' },
  { label: 'San Bernardo', value: 'San Bernardo' },
  { label: 'San Bernardo', value: 'San Bernardo' },
  { label: 'San Bernardo', value: 'San Bernardo' },
  { label: 'San Bernardo Del Viento', value: 'San Bernardo Del Viento' },
  { label: 'San Bernardo Mixtepec', value: 'San Bernardo Mixtepec' },
  { label: 'San Bernardo Nuevo León', value: 'San Bernardo Nuevo León' },
  { label: 'San Bernardo Peña Blanca', value: 'San Bernardo Peña Blanca' },
  { label: 'San Bernardo Tlalmimilolpan', value: 'San Bernardo Tlalmimilolpan' },
  { label: 'San Biagio', value: 'San Biagio' },
  { label: 'San Biagio', value: 'San Biagio' },
  { label: 'San Biagio', value: 'San Biagio' },
  { label: 'San Biagio', value: 'San Biagio' },
  { label: 'San Biagio Della Cima', value: 'San Biagio Della Cima' },
  { label: 'San Biagio Di Callalta', value: 'San Biagio Di Callalta' },
  { label: 'San Biagio Platani', value: 'San Biagio Platani' },
  { label: 'San Biagio Saracinisco', value: 'San Biagio Saracinisco' },
  { label: 'San Biase', value: 'San Biase' },
  { label: 'San Blas', value: 'San Blas' },
  { label: 'San Blas', value: 'San Blas' },
  { label: 'San Blas', value: 'San Blas' },
  { label: 'San Blas Atempa', value: 'San Blas Atempa' },
  { label: 'San Blas-Canillejas', value: 'San Blas-Canillejas' },
  { label: 'San Bonifacio', value: 'San Bonifacio' },
  { label: 'San Borja', value: 'San Borja' },
  { label: 'San Bovio-San Felice', value: 'San Bovio-San Felice' },
  { label: 'San Brancato', value: 'San Brancato' },
  { label: 'San Bruno', value: 'San Bruno' },
  { label: 'San Buenaventura', value: 'San Buenaventura' },
  { label: 'San Buenaventura', value: 'San Buenaventura' },
  { label: 'San Buenaventura', value: 'San Buenaventura' },
  { label: 'San Buenaventura', value: 'San Buenaventura' },
  { label: 'San Buenaventura', value: 'San Buenaventura' },
  { label: 'San Buenaventura', value: 'San Buenaventura' },
  { label: 'San Buenaventura', value: 'San Buenaventura' },
  { label: 'San Buenaventura Atempan', value: 'San Buenaventura Atempan' },
  { label: 'San Buenaventura Tecaltzingo', value: 'San Buenaventura Tecaltzingo' },
  { label: 'San Buono', value: 'San Buono' },
  { label: 'San Calixto', value: 'San Calixto' },
  { label: 'San Calogero', value: 'San Calogero' },
  { label: 'San Candido', value: 'San Candido' },
  { label: 'San Canzian Disonzo', value: 'San Canzian Disonzo' },
  { label: 'San Caralampio', value: 'San Caralampio' },
  { label: 'San Carlo', value: 'San Carlo' },
  { label: 'San Carlo Allarena', value: 'San Carlo Allarena' },
  { label: 'San Carlo Canavese', value: 'San Carlo Canavese' },
  { label: 'San Carlo-Condofuri Marina', value: 'San Carlo-Condofuri Marina' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos', value: 'San Carlos' },
  { label: 'San Carlos Autopan', value: 'San Carlos Autopan' },
  { label: 'San Carlos Centro', value: 'San Carlos Centro' },
  { label: 'San Carlos De Bariloche', value: 'San Carlos De Bariloche' },
  { label: 'San Carlos De Guaroa', value: 'San Carlos De Guaroa' },
  { label: 'San Carlos De Río Negro', value: 'San Carlos De Río Negro' },
  { label: 'San Carlos Del Valle', value: 'San Carlos Del Valle' },
  { label: 'San Carlos Del Zulia', value: 'San Carlos Del Zulia' },
  { label: 'San Carlos Park', value: 'San Carlos Park' },
  { label: 'San Carlos Sija', value: 'San Carlos Sija' },
  { label: 'San Carlos Yautepec', value: 'San Carlos Yautepec' },
  { label: 'San Casciano Dei Bagni', value: 'San Casciano Dei Bagni' },
  { label: 'San Casciano In Val Di Pesa', value: 'San Casciano In Val Di Pesa' },
  { label: 'San Casimiro', value: 'San Casimiro' },
  { label: 'San Casimiro', value: 'San Casimiro' },
  { label: 'San Cassiano', value: 'San Cassiano' },
  { label: 'San Cassiano', value: 'San Cassiano' },
  { label: 'San Castrese', value: 'San Castrese' },
  { label: 'San Cataldo', value: 'San Cataldo' },
  { label: 'San Cayetano', value: 'San Cayetano' },
  { label: 'San Cayetano', value: 'San Cayetano' },
  { label: 'San Cayetano', value: 'San Cayetano' },
  { label: 'San Cayetano', value: 'San Cayetano' },
  { label: 'San Cayetano', value: 'San Cayetano' },
  { label: 'San Cebrián De Campos', value: 'San Cebrián De Campos' },
  { label: 'San Cebrián De Castro', value: 'San Cebrián De Castro' },
  { label: 'San Cebrián De Mazote', value: 'San Cebrián De Mazote' },
  { label: 'San Cebrián De Mudá', value: 'San Cebrián De Mudá' },
  { label: 'San Celestio', value: 'San Celestio' },
  { label: 'San Cesareo', value: 'San Cesareo' },
  { label: 'San Cesario Di Lecce', value: 'San Cesario Di Lecce' },
  { label: 'San Cesario Sul Panaro', value: 'San Cesario Sul Panaro' },
  { label: 'San Chirico Nuovo', value: 'San Chirico Nuovo' },
  { label: 'San Chirico Raparo', value: 'San Chirico Raparo' },
  { label: 'San Cibrao Das Viñas', value: 'San Cibrao Das Viñas' },
  { label: 'San Cipirello', value: 'San Cipirello' },
  { label: 'San Cipriano Daversa', value: 'San Cipriano Daversa' },
  { label: 'San Cipriano Picentino', value: 'San Cipriano Picentino' },
  { label: 'San Cipriano Po', value: 'San Cipriano Po' },
  { label: 'San Cipriano-S.Barbara-Centinale', value: 'San Cipriano-S.Barbara-Centinale' },
  { label: 'San Ciro De Acosta', value: 'San Ciro De Acosta' },
  { label: 'San Ciro-Ulmi-Filci', value: 'San Ciro-Ulmi-Filci' },
  { label: 'San Clemente', value: 'San Clemente' },
  { label: 'San Clemente', value: 'San Clemente' },
  { label: 'San Clemente', value: 'San Clemente' },
  { label: 'San Clemente', value: 'San Clemente' },
  { label: 'San Clemente', value: 'San Clemente' },
  { label: 'San Clemente', value: 'San Clemente' },
  { label: 'San Clemente', value: 'San Clemente' },
  { label: 'San Clemente', value: 'San Clemente' },
  { label: 'San Clemente', value: 'San Clemente' },
  { label: 'San Clemente', value: 'San Clemente' },
  { label: 'San Clemente De Lima', value: 'San Clemente De Lima' },
  { label: 'San Colombano Al Lambro', value: 'San Colombano Al Lambro' },
  { label: 'San Colombano Belmonte', value: 'San Colombano Belmonte' },
  { label: 'San Cono', value: 'San Cono' },
  { label: 'San Cosme', value: 'San Cosme' },
  { label: 'San Cosme Atlamaxac', value: 'San Cosme Atlamaxac' },
  { label: 'San Cosme Y Damián', value: 'San Cosme Y Damián' },
  { label: 'San Cosmo Albanese', value: 'San Cosmo Albanese' },
  { label: 'San Costantino Albanese', value: 'San Costantino Albanese' },
  { label: 'San Costantino Calabro', value: 'San Costantino Calabro' },
  { label: 'San Costanzo', value: 'San Costanzo' },
  { label: 'San Cristobal', value: 'San Cristobal' },
  { label: 'San Cristobal', value: 'San Cristobal' },
  { label: 'San Cristobal', value: 'San Cristobal' },
  { label: 'San Cristóbal', value: 'San Cristóbal' },
  { label: 'San Cristóbal', value: 'San Cristóbal' },
  { label: 'San Cristóbal', value: 'San Cristóbal' },
  { label: 'San Cristóbal', value: 'San Cristóbal' },
  { label: 'San Cristóbal', value: 'San Cristóbal' },
  { label: 'San Cristóbal', value: 'San Cristóbal' },
  { label: 'San Cristóbal', value: 'San Cristóbal' },
  { label: 'San Cristóbal', value: 'San Cristóbal' },
  { label: 'San Cristóbal', value: 'San Cristóbal' },
  { label: 'San Cristóbal Acasaguastlán', value: 'San Cristóbal Acasaguastlán' },
  { label: 'San Cristóbal Amatlán', value: 'San Cristóbal Amatlán' },
  { label: 'San Cristóbal Amoltepec', value: 'San Cristóbal Amoltepec' },
  { label: 'San Cristóbal Chacón', value: 'San Cristóbal Chacón' },
  { label: 'San Cristóbal Cucho', value: 'San Cristóbal Cucho' },
  { label: 'San Cristóbal De Boedo', value: 'San Cristóbal De Boedo' },
  { label: 'San Cristóbal De Cuéllar', value: 'San Cristóbal De Cuéllar' },
  { label: 'San Cristóbal De Entreviñas', value: 'San Cristóbal De Entreviñas' },
  { label: 'San Cristóbal De La Barranca Municipality', value: 'San Cristóbal De La Barranca Municipality' },
  { label: 'San Cristóbal De La Cuesta', value: 'San Cristóbal De La Cuesta' },
  { label: 'San Cristóbal De La Laguna', value: 'San Cristóbal De La Laguna' },
  { label: 'San Cristóbal De La Polantera', value: 'San Cristóbal De La Polantera' },
  { label: 'San Cristóbal De La Vega', value: 'San Cristóbal De La Vega' },
  { label: 'San Cristóbal De Las Casas', value: 'San Cristóbal De Las Casas' },
  { label: 'San Cristóbal De Segovia', value: 'San Cristóbal De Segovia' },
  { label: 'San Cristóbal Hidalgo', value: 'San Cristóbal Hidalgo' },
  { label: 'San Cristóbal Honduras', value: 'San Cristóbal Honduras' },
  { label: 'San Cristóbal Lachirioag', value: 'San Cristóbal Lachirioag' },
  { label: 'San Cristóbal Los Nava', value: 'San Cristóbal Los Nava' },
  { label: 'San Cristóbal Nexquipayac', value: 'San Cristóbal Nexquipayac' },
  { label: 'San Cristóbal Totonicapán', value: 'San Cristóbal Totonicapán' },
  { label: 'San Cristóbal Verapaz', value: 'San Cristóbal Verapaz' },
  { label: 'San Cristóbal Xochimilpa', value: 'San Cristóbal Xochimilpa' },
  { label: 'San Cristóbal Zapotitlán', value: 'San Cristóbal Zapotitlán' },
  { label: 'San Cristoforo', value: 'San Cristoforo' },
  { label: 'San Cristovo De Cea', value: 'San Cristovo De Cea' },
  { label: 'San Damaso', value: 'San Damaso' },
  { label: 'San Damián Texoloc', value: 'San Damián Texoloc' },
  { label: 'San Damiano Al Colle', value: 'San Damiano Al Colle' },
  { label: 'San Damiano Dasti', value: 'San Damiano Dasti' },
  { label: 'San Damiano Macra', value: 'San Damiano Macra' },
  { label: 'San Daniele Del Friuli', value: 'San Daniele Del Friuli' },
  { label: 'San Daniele Po', value: 'San Daniele Po' },
  { label: 'San Defendente', value: 'San Defendente' },
  { label: 'San Demetrio Corone', value: 'San Demetrio Corone' },
  { label: 'San Demetrio Ne Vestini', value: 'San Demetrio Ne Vestini' },
  { label: 'San Didero', value: 'San Didero' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'San Diego Alcalá', value: 'San Diego Alcalá' },
  { label: 'San Diego Country Estates', value: 'San Diego Country Estates' },
  { label: 'San Diego County', value: 'San Diego County' },
  { label: 'San Diego De Alcalá', value: 'San Diego De Alcalá' },
  { label: 'San Diego De Alejandría', value: 'San Diego De Alejandría' },
  { label: 'San Diego De La Unión', value: 'San Diego De La Unión' },
  { label: 'San Diego De Los Baños', value: 'San Diego De Los Baños' },
  { label: 'San Diego Del Cerrito', value: 'San Diego Del Cerrito' },
  { label: 'San Diego El Organal', value: 'San Diego El Organal' },
  { label: 'San Diego Huehuecalco', value: 'San Diego Huehuecalco' },
  { label: 'San Diego La Huerta', value: 'San Diego La Huerta' },
  { label: 'San Diego La Mesa Tochimiltzingo', value: 'San Diego La Mesa Tochimiltzingo' },
  { label: 'San Diego Linares', value: 'San Diego Linares' },
  { label: 'San Dimas', value: 'San Dimas' },
  { label: 'San Dionisio', value: 'San Dionisio' },
  { label: 'San Dionisio', value: 'San Dionisio' },
  { label: 'San Dionisio Del Mar', value: 'San Dionisio Del Mar' },
  { label: 'San Dionisio Ocotepec', value: 'San Dionisio Ocotepec' },
  { label: 'San Dionisio Ocotlán', value: 'San Dionisio Ocotlán' },
  { label: 'San Donà Di Piave', value: 'San Donà Di Piave' },
  { label: 'San Donaci', value: 'San Donaci' },
  { label: 'San Donato', value: 'San Donato' },
  { label: 'San Donato Di Lecce', value: 'San Donato Di Lecce' },
  { label: 'San Donato Di Ninea', value: 'San Donato Di Ninea' },
  { label: 'San Donato Milanese', value: 'San Donato Milanese' },
  { label: 'San Donato Val Di Comino', value: 'San Donato Val Di Comino' },
  { label: 'San Donnino', value: 'San Donnino' },
  { label: 'San Eduardo', value: 'San Eduardo' },
  { label: 'San Eduardo', value: 'San Eduardo' },
  { label: 'San Eduardo', value: 'San Eduardo' },
  { label: 'San Elías', value: 'San Elías' },
  { label: 'San Elizario', value: 'San Elizario' },
  { label: 'San Emiliano', value: 'San Emiliano' },
  { label: 'San Emilio', value: 'San Emilio' },
  { label: 'San Emilio', value: 'San Emilio' },
  { label: 'San Enrique', value: 'San Enrique' },
  { label: 'San Estanislao', value: 'San Estanislao' },
  { label: 'San Esteban', value: 'San Esteban' },
  { label: 'San Esteban', value: 'San Esteban' },
  { label: 'San Esteban', value: 'San Esteban' },
  { label: 'San Esteban', value: 'San Esteban' },
  { label: 'San Esteban', value: 'San Esteban' },
  { label: 'San Esteban (San Miguel Tateposco)', value: 'San Esteban (San Miguel Tateposco)' },
  { label: 'San Esteban Atatlahuca', value: 'San Esteban Atatlahuca' },
  { label: 'San Esteban De Abajo', value: 'San Esteban De Abajo' },
  { label: 'San Esteban De Gormaz', value: 'San Esteban De Gormaz' },
  { label: 'San Esteban De La Sierra', value: 'San Esteban De La Sierra' },
  { label: 'San Esteban De Litera', value: 'San Esteban De Litera' },
  { label: 'San Esteban De Los Patos', value: 'San Esteban De Los Patos' },
  { label: 'San Esteban De Nogales', value: 'San Esteban De Nogales' },
  { label: 'San Esteban De Zapardiel', value: 'San Esteban De Zapardiel' },
  { label: 'San Esteban Del Molar', value: 'San Esteban Del Molar' },
  { label: 'San Esteban Del Valle', value: 'San Esteban Del Valle' },
  { label: 'San Esteban Tizatlán', value: 'San Esteban Tizatlán' },
  { label: 'San Eugenio', value: 'San Eugenio' },
  { label: 'San Eugenio', value: 'San Eugenio' },
  { label: 'San Fabian', value: 'San Fabian' },
  { label: 'San Fabian', value: 'San Fabian' },
  { label: 'San Fabian', value: 'San Fabian' },
  { label: 'San Fabian', value: 'San Fabian' },
  { label: 'San Fabián', value: 'San Fabián' },
  { label: 'San Fedele Intelvi', value: 'San Fedele Intelvi' },
  { label: 'San Fedele Superiore', value: 'San Fedele Superiore' },
  { label: 'San Fedele-Lusignano', value: 'San Fedele-Lusignano' },
  { label: 'San Fele', value: 'San Fele' },
  { label: 'San Felice', value: 'San Felice' },
  { label: 'San Felice', value: 'San Felice' },
  { label: 'San Felice A Cancello', value: 'San Felice A Cancello' },
  { label: 'San Felice Circeo', value: 'San Felice Circeo' },
  { label: 'San Felice Del Benaco', value: 'San Felice Del Benaco' },
  { label: 'San Felice Del Molise', value: 'San Felice Del Molise' },
  { label: 'San Felice Sul Panaro', value: 'San Felice Sul Panaro' },
  { label: 'San Felices', value: 'San Felices' },
  { label: 'San Felices De Buelna', value: 'San Felices De Buelna' },
  { label: 'San Felices De Los Gallegos', value: 'San Felices De Los Gallegos' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe', value: 'San Felipe' },
  { label: 'San Felipe Aztatán', value: 'San Felipe Aztatán' },
  { label: 'San Felipe Cihualtepec', value: 'San Felipe Cihualtepec' },
  { label: 'San Felipe Coamango', value: 'San Felipe Coamango' },
  { label: 'San Felipe De Híjar', value: 'San Felipe De Híjar' },
  { label: 'San Felipe De Los Herreros', value: 'San Felipe De Los Herreros' },
  { label: 'San Felipe Del Progreso', value: 'San Felipe Del Progreso' },
  { label: 'San Felipe El Mirasol', value: 'San Felipe El Mirasol' },
  { label: 'San Felipe Hidalgo', value: 'San Felipe Hidalgo' },
  { label: 'San Felipe Jalapa De Díaz', value: 'San Felipe Jalapa De Díaz' },
  { label: 'San Felipe Los Alzati (Colonia Nueva)', value: 'San Felipe Los Alzati (Colonia Nueva)' },
  { label: 'San Felipe Old', value: 'San Felipe Old' },
  { label: 'San Felipe Old', value: 'San Felipe Old' },
  { label: 'San Felipe Orizatlán', value: 'San Felipe Orizatlán' },
  { label: 'San Felipe Otlaltepec', value: 'San Felipe Otlaltepec' },
  { label: 'San Felipe Pueblo', value: 'San Felipe Pueblo' },
  { label: 'San Felipe Pueblo Nuevo', value: 'San Felipe Pueblo Nuevo' },
  { label: 'San Felipe Quiriceo', value: 'San Felipe Quiriceo' },
  { label: 'San Felipe Santiago', value: 'San Felipe Santiago' },
  { label: 'San Felipe Sultepec', value: 'San Felipe Sultepec' },
  { label: 'San Felipe Tejalápam', value: 'San Felipe Tejalápam' },
  { label: 'San Felipe Teotitlán', value: 'San Felipe Teotitlán' },
  { label: 'San Felipe Tílpam', value: 'San Felipe Tílpam' },
  { label: 'San Felipe Tizapa', value: 'San Felipe Tizapa' },
  { label: 'San Felipe Tlalmimilolpan', value: 'San Felipe Tlalmimilolpan' },
  { label: 'San Felipe Usila', value: 'San Felipe Usila' },
  { label: 'San Félix', value: 'San Félix' },
  { label: 'San Félix', value: 'San Félix' },
  { label: 'San Félix Hidalgo', value: 'San Félix Hidalgo' },
  { label: 'San Félix Rijo', value: 'San Félix Rijo' },
  { label: 'San Ferdinando', value: 'San Ferdinando' },
  { label: 'San Ferdinando', value: 'San Ferdinando' },
  { label: 'San Ferdinando Di Puglia', value: 'San Ferdinando Di Puglia' },
  { label: 'San Fermo Della Battaglia', value: 'San Fermo Della Battaglia' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando', value: 'San Fernando' },
  { label: 'San Fernando De Apure', value: 'San Fernando De Apure' },
  { label: 'San Fernando De Atabapo', value: 'San Fernando De Atabapo' },
  { label: 'San Fernando De Henares', value: 'San Fernando De Henares' },
  { label: 'San Fernando De Monte Cristi', value: 'San Fernando De Monte Cristi' },
  { label: 'San Fernando Del Valle De Catamarca', value: 'San Fernando Del Valle De Catamarca' },
  { label: 'San Fernando Poblacion', value: 'San Fernando Poblacion' },
  { label: 'San Fidenzio', value: 'San Fidenzio' },
  { label: 'San Fili', value: 'San Fili' },
  { label: 'San Filippo Del Mela', value: 'San Filippo Del Mela' },
  { label: 'San Fior Di Sopra', value: 'San Fior Di Sopra' },
  { label: 'San Fiorano', value: 'San Fiorano' },
  { label: 'San Floriano', value: 'San Floriano' },
  { label: 'San Floriano Del Collio', value: 'San Floriano Del Collio' },
  { label: 'San Floriano-Olmi', value: 'San Floriano-Olmi' },
  { label: 'San Floro', value: 'San Floro' },
  { label: 'San Francesco', value: 'San Francesco' },
  { label: 'San Francesco Al Campo', value: 'San Francesco Al Campo' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'San Francisco (Baños De Agua Caliente)', value: 'San Francisco (Baños De Agua Caliente)' },
  { label: 'San Francisco (El Calvito)', value: 'San Francisco (El Calvito)' },
  { label: 'San Francisco (Mata Clara)', value: 'San Francisco (Mata Clara)' },
  { label: 'San Francisco Acuautla', value: 'San Francisco Acuautla' },
  { label: 'San Francisco Atotonilco', value: 'San Francisco Atotonilco' },
  { label: 'San Francisco Ayotuzco', value: 'San Francisco Ayotuzco' },
  { label: 'San Francisco Cajonos', value: 'San Francisco Cajonos' },
  { label: 'San Francisco Chalchihuapan', value: 'San Francisco Chalchihuapan' },
  { label: 'San Francisco Chejé', value: 'San Francisco Chejé' },
  { label: 'San Francisco Chimalpa', value: 'San Francisco Chimalpa' },
  { label: 'San Francisco Chindúa', value: 'San Francisco Chindúa' },
  { label: 'San Francisco Coatlán', value: 'San Francisco Coatlán' },
  { label: 'San Francisco Cozoaltepec', value: 'San Francisco Cozoaltepec' },
  { label: 'San Francisco Cuapa', value: 'San Francisco Cuapa' },
  { label: 'San Francisco Cuautlancingo', value: 'San Francisco Cuautlancingo' },
  { label: 'San Francisco Cuaxusco', value: 'San Francisco Cuaxusco' },
  { label: 'San Francisco Cuayalab', value: 'San Francisco Cuayalab' },
  { label: 'San Francisco Cuexcontzi', value: 'San Francisco Cuexcontzi' },
  { label: 'San Francisco De Arriba', value: 'San Francisco De Arriba' },
  { label: 'San Francisco De Asís', value: 'San Francisco De Asís' },
  { label: 'San Francisco De Becerra', value: 'San Francisco De Becerra' },
  { label: 'San Francisco De Borja', value: 'San Francisco De Borja' },
  { label: 'San Francisco De Conchos', value: 'San Francisco De Conchos' },
  { label: 'San Francisco De Cones', value: 'San Francisco De Cones' },
  { label: 'San Francisco De Coray', value: 'San Francisco De Coray' },
  { label: 'San Francisco De Durán', value: 'San Francisco De Durán' },
  { label: 'San Francisco De Guzmán', value: 'San Francisco De Guzmán' },
  { label: 'San Francisco De Horizonte (Horizonte)', value: 'San Francisco De Horizonte (Horizonte)' },
  { label: 'San Francisco De La Palma', value: 'San Francisco De La Palma' },
  { label: 'San Francisco De La Paz', value: 'San Francisco De La Paz' },
  { label: 'San Francisco De Laishí', value: 'San Francisco De Laishí' },
  { label: 'San Francisco De Los Romo', value: 'San Francisco De Los Romo' },
  { label: 'San Francisco De Macorís', value: 'San Francisco De Macorís' },
  { label: 'San Francisco De Ojuera', value: 'San Francisco De Ojuera' },
  { label: 'San Francisco De Opalaca', value: 'San Francisco De Opalaca' },
  { label: 'San Francisco De Rivas', value: 'San Francisco De Rivas' },
  { label: 'San Francisco De Tacuichamona', value: 'San Francisco De Tacuichamona' },
  { label: 'San Francisco De Yojoa', value: 'San Francisco De Yojoa' },
  { label: 'San Francisco Del Chañar', value: 'San Francisco Del Chañar' },
  { label: 'San Francisco Del Mar', value: 'San Francisco Del Mar' },
  { label: 'San Francisco Del Mar Viejo', value: 'San Francisco Del Mar Viejo' },
  { label: 'San Francisco Del Mezquital', value: 'San Francisco Del Mezquital' },
  { label: 'San Francisco Del Monte De Oro', value: 'San Francisco Del Monte De Oro' },
  { label: 'San Francisco Del Oro', value: 'San Francisco Del Oro' },
  { label: 'San Francisco Del Rincón', value: 'San Francisco Del Rincón' },
  { label: 'San Francisco Del Valle', value: 'San Francisco Del Valle' },
  { label: 'San Francisco El Alto', value: 'San Francisco El Alto' },
  { label: 'San Francisco Grande', value: 'San Francisco Grande' },
  { label: 'San Francisco Huehuetlán', value: 'San Francisco Huehuetlán' },
  { label: 'San Francisco Independencia (Santa María Aserradero)', value: 'San Francisco Independencia (Santa María Aserradero)' },
  { label: 'San Francisco Ixhuatan', value: 'San Francisco Ixhuatan' },
  { label: 'San Francisco Jaconá', value: 'San Francisco Jaconá' },
  { label: 'San Francisco Jaltepetongo', value: 'San Francisco Jaltepetongo' },
  { label: 'San Francisco Javier', value: 'San Francisco Javier' },
  { label: 'San Francisco Javier', value: 'San Francisco Javier' },
  { label: 'San Francisco Kobén', value: 'San Francisco Kobén' },
  { label: 'San Francisco La Unión', value: 'San Francisco La Unión' },
  { label: 'San Francisco Lachigoló', value: 'San Francisco Lachigoló' },
  { label: 'San Francisco Logueche', value: 'San Francisco Logueche' },
  { label: 'San Francisco Loxicha', value: 'San Francisco Loxicha' },
  { label: 'San Francisco Magú', value: 'San Francisco Magú' },
  { label: 'San Francisco Mazapa', value: 'San Francisco Mazapa' },
  { label: 'San Francisco Menéndez', value: 'San Francisco Menéndez' },
  { label: 'San Francisco Mihualtepec', value: 'San Francisco Mihualtepec' },
  { label: 'San Francisco Nacaxtle', value: 'San Francisco Nacaxtle' },
  { label: 'San Francisco Nuxaño', value: 'San Francisco Nuxaño' },
  { label: 'San Francisco Ocotlán', value: 'San Francisco Ocotlán' },
  { label: 'San Francisco Oxtotilpan', value: 'San Francisco Oxtotilpan' },
  { label: 'San Francisco Oxtutla', value: 'San Francisco Oxtutla' },
  { label: 'San Francisco Ozolotepec', value: 'San Francisco Ozolotepec' },
  { label: 'San Francisco Ozomatlán', value: 'San Francisco Ozomatlán' },
  { label: 'San Francisco Peribán', value: 'San Francisco Peribán' },
  { label: 'San Francisco Pujiltic', value: 'San Francisco Pujiltic' },
  { label: 'San Francisco Putla', value: 'San Francisco Putla' },
  { label: 'San Francisco Sola', value: 'San Francisco Sola' },
  { label: 'San Francisco Solis', value: 'San Francisco Solis' },
  { label: 'San Francisco Tecoxpa', value: 'San Francisco Tecoxpa' },
  { label: 'San Francisco Telixtlahuaca', value: 'San Francisco Telixtlahuaca' },
  { label: 'San Francisco Temetzontla', value: 'San Francisco Temetzontla' },
  { label: 'San Francisco Tenopalco', value: 'San Francisco Tenopalco' },
  { label: 'San Francisco Tepeolulco', value: 'San Francisco Tepeolulco' },
  { label: 'San Francisco Tepexoxica', value: 'San Francisco Tepexoxica' },
  { label: 'San Francisco Tepeyecac', value: 'San Francisco Tepeyecac' },
  { label: 'San Francisco Tetetla', value: 'San Francisco Tetetla' },
  { label: 'San Francisco Tlacuilohcan', value: 'San Francisco Tlacuilohcan' },
  { label: 'San Francisco Tlalcilalcalpan', value: 'San Francisco Tlalcilalcalpan' },
  { label: 'San Francisco Tláloc', value: 'San Francisco Tláloc' },
  { label: 'San Francisco Tlaltica', value: 'San Francisco Tlaltica' },
  { label: 'San Francisco Tutla', value: 'San Francisco Tutla' },
  { label: 'San Francisco Xochicuautla', value: 'San Francisco Xochicuautla' },
  { label: 'San Francisco Zacacalco', value: 'San Francisco Zacacalco' },
  { label: 'San Francisco Zacapexpan', value: 'San Francisco Zacapexpan' },
  { label: 'San Francisco Zapotitlán', value: 'San Francisco Zapotitlán' },
  { label: 'San Fratello', value: 'San Fratello' },
  { label: 'San Fulgencio', value: 'San Fulgencio' },
  { label: 'San Gabriel', value: 'San Gabriel' },
  { label: 'San Gabriel', value: 'San Gabriel' },
  { label: 'San Gabriel', value: 'San Gabriel' },
  { label: 'San Gabriel', value: 'San Gabriel' },
  { label: 'San Gabriel', value: 'San Gabriel' },
  { label: 'San Gabriel', value: 'San Gabriel' },
  { label: 'San Gabriel', value: 'San Gabriel' },
  { label: 'San Gabriel', value: 'San Gabriel' },
  { label: 'San Gabriel  Jalisco', value: 'San Gabriel  Jalisco' },
  { label: 'San Gabriel Azteca', value: 'San Gabriel Azteca' },
  { label: 'San Gabriel Chilac', value: 'San Gabriel Chilac' },
  { label: 'San Gabriel First', value: 'San Gabriel First' },
  { label: 'San Gabriel First', value: 'San Gabriel First' },
  { label: 'San Gabriel Ixtla', value: 'San Gabriel Ixtla' },
  { label: 'San Gabriel Las Palmas', value: 'San Gabriel Las Palmas' },
  { label: 'San Gabriel Mixtepec', value: 'San Gabriel Mixtepec' },
  { label: 'San Gabriel Tetzoyocán', value: 'San Gabriel Tetzoyocán' },
  { label: 'San Gabriel Y San Ignacio', value: 'San Gabriel Y San Ignacio' },
  { label: 'San Gabriel Zepayautla', value: 'San Gabriel Zepayautla' },
  { label: 'San Gabriele-Mondonuovo', value: 'San Gabriele-Mondonuovo' },
  { label: 'San García De Ingelmos', value: 'San García De Ingelmos' },
  { label: 'San Gaspar', value: 'San Gaspar' },
  { label: 'San Gaspar', value: 'San Gaspar' },
  { label: 'San Gaspar De Los Reyes', value: 'San Gaspar De Los Reyes' },
  { label: 'San Gaspar Ixchil', value: 'San Gaspar Ixchil' },
  { label: 'San Gaspar Tlahuelilpan', value: 'San Gaspar Tlahuelilpan' },
  { label: 'San Gaspar Tonatico', value: 'San Gaspar Tonatico' },
  { label: 'San Gavino Monreale', value: 'San Gavino Monreale' },
  { label: 'San Gemini', value: 'San Gemini' },
  { label: 'San Genesio', value: 'San Genesio' },
  { label: 'San Genesio Atesino', value: 'San Genesio Atesino' },
  { label: 'San Genesio Ed Uniti', value: 'San Genesio Ed Uniti' },
  { label: 'San Gennaro Vesuviano', value: 'San Gennaro Vesuviano' },
  { label: 'San Germán', value: 'San Germán' },
  { label: 'San Germán', value: 'San Germán' },
  { label: 'San Germán', value: 'San Germán' },
  { label: 'San Germán Municipio', value: 'San Germán Municipio' },
  { label: 'San Germano Chisone', value: 'San Germano Chisone' },
  { label: 'San Germano Dei Berici', value: 'San Germano Dei Berici' },
  { label: 'San Germano Vercellese', value: 'San Germano Vercellese' },
  { label: 'San Gervasio Bresciano', value: 'San Gervasio Bresciano' },
  { label: 'San Giacomo', value: 'San Giacomo' },
  { label: 'San Giacomo', value: 'San Giacomo' },
  { label: 'San Giacomo', value: 'San Giacomo' },
  { label: 'San Giacomo', value: 'San Giacomo' },
  { label: 'San Giacomo Degli Schiavoni', value: 'San Giacomo Degli Schiavoni' },
  { label: 'San Giacomo Delle Segnate', value: 'San Giacomo Delle Segnate' },
  { label: 'San Giacomo Filippo', value: 'San Giacomo Filippo' },
  { label: 'San Giacomo Vercellese', value: 'San Giacomo Vercellese' },
  { label: 'San Gil', value: 'San Gil' },
  { label: 'San Ġiljan (St. Julians)', value: 'San Ġiljan (St. Julians)' },
  { label: 'San Gillio', value: 'San Gillio' },
  { label: 'San Gimignano', value: 'San Gimignano' },
  { label: 'San Ginesio', value: 'San Ginesio' },
  { label: 'San Giorgio', value: 'San Giorgio' },
  { label: 'San Giorgio', value: 'San Giorgio' },
  { label: 'San Giorgio', value: 'San Giorgio' },
  { label: 'San Giorgio A Cremano', value: 'San Giorgio A Cremano' },
  { label: 'San Giorgio A Liri', value: 'San Giorgio A Liri' },
  { label: 'San Giorgio Al Tagliamento-Pozzi', value: 'San Giorgio Al Tagliamento-Pozzi' },
  { label: 'San Giorgio Albanese', value: 'San Giorgio Albanese' },
  { label: 'San Giorgio Canavese', value: 'San Giorgio Canavese' },
  { label: 'San Giorgio Del Sannio', value: 'San Giorgio Del Sannio' },
  { label: 'San Giorgio Della Richinvelda', value: 'San Giorgio Della Richinvelda' },
  { label: 'San Giorgio Delle Pertiche', value: 'San Giorgio Delle Pertiche' },
  { label: 'San Giorgio Di Livenza', value: 'San Giorgio Di Livenza' },
  { label: 'San Giorgio Di Lomellina', value: 'San Giorgio Di Lomellina' },
  { label: 'San Giorgio Di Nogaro', value: 'San Giorgio Di Nogaro' },
  { label: 'San Giorgio Di Pesaro', value: 'San Giorgio Di Pesaro' },
  { label: 'San Giorgio Di Piano', value: 'San Giorgio Di Piano' },
  { label: 'San Giorgio In Bosco', value: 'San Giorgio In Bosco' },
  { label: 'San Giorgio In Salici', value: 'San Giorgio In Salici' },
  { label: 'San Giorgio Ionico', value: 'San Giorgio Ionico' },
  { label: 'San Giorgio La Molara', value: 'San Giorgio La Molara' },
  { label: 'San Giorgio Lucano', value: 'San Giorgio Lucano' },
  { label: 'San Giorgio Monferrato', value: 'San Giorgio Monferrato' },
  { label: 'San Giorgio Morgeto', value: 'San Giorgio Morgeto' },
  { label: 'San Giorgio Piacentino', value: 'San Giorgio Piacentino' },
  { label: 'San Giorgio Scarampi', value: 'San Giorgio Scarampi' },
  { label: 'San Giorgio Su Legnano', value: 'San Giorgio Su Legnano' },
  { label: 'San Giorio', value: 'San Giorio' },
  { label: 'San Giovanni', value: 'San Giovanni' },
  { label: 'San Giovanni', value: 'San Giovanni' },
  { label: 'San Giovanni', value: 'San Giovanni' },
  { label: 'San Giovanni A Piro', value: 'San Giovanni A Piro' },
  { label: 'San Giovanni A Teduccio', value: 'San Giovanni A Teduccio' },
  { label: 'San Giovanni Al Natisone', value: 'San Giovanni Al Natisone' },
  { label: 'San Giovanni Bianco', value: 'San Giovanni Bianco' },
  { label: 'San Giovanni Dasso', value: 'San Giovanni Dasso' },
  { label: 'San Giovanni Del Dosso', value: 'San Giovanni Del Dosso' },
  { label: 'San Giovanni Di Gerace', value: 'San Giovanni Di Gerace' },
  { label: 'San Giovanni Gemini', value: 'San Giovanni Gemini' },
  { label: 'San Giovanni Ilarione', value: 'San Giovanni Ilarione' },
  { label: 'San Giovanni In Croce', value: 'San Giovanni In Croce' },
  { label: 'San Giovanni In Fiore', value: 'San Giovanni In Fiore' },
  { label: 'San Giovanni In Galdo', value: 'San Giovanni In Galdo' },
  { label: 'San Giovanni In Marignano', value: 'San Giovanni In Marignano' },
  { label: 'San Giovanni In Persiceto', value: 'San Giovanni In Persiceto' },
  { label: 'San Giovanni Incarico', value: 'San Giovanni Incarico' },
  { label: 'San Giovanni La Punta', value: 'San Giovanni La Punta' },
  { label: 'San Giovanni Lipioni', value: 'San Giovanni Lipioni' },
  { label: 'San Giovanni Lupatoto', value: 'San Giovanni Lupatoto' },
  { label: 'San Giovanni Rotondo', value: 'San Giovanni Rotondo' },
  { label: 'San Giovanni Suergiu', value: 'San Giovanni Suergiu' },
  { label: 'San Giovanni Valdarno', value: 'San Giovanni Valdarno' },
  { label: 'San Giovanni-Patoni', value: 'San Giovanni-Patoni' },
  { label: 'San Giovanni-San Bernardino', value: 'San Giovanni-San Bernardino' },
  { label: 'San Giuliano Del Sannio', value: 'San Giuliano Del Sannio' },
  { label: 'San Giuliano Di Puglia', value: 'San Giuliano Di Puglia' },
  { label: 'San Giuliano Milanese', value: 'San Giuliano Milanese' },
  { label: 'San Giuliano Terme', value: 'San Giuliano Terme' },
  { label: 'San Giuliano Vecchio', value: 'San Giuliano Vecchio' },
  { label: 'San Giuseppe', value: 'San Giuseppe' },
  { label: 'San Giuseppe', value: 'San Giuseppe' },
  { label: 'San Giuseppe', value: 'San Giuseppe' },
  { label: 'San Giuseppe Jato', value: 'San Giuseppe Jato' },
  { label: 'San Giuseppe Le Prata-Cotropagno', value: 'San Giuseppe Le Prata-Cotropagno' },
  { label: 'San Giuseppe Vesuviano', value: 'San Giuseppe Vesuviano' },
  { label: 'San Giustino', value: 'San Giustino' },
  { label: 'San Giustino Valdarno', value: 'San Giustino Valdarno' },
  { label: 'San Giusto Canavese', value: 'San Giusto Canavese' },
  { label: 'San Godenzo', value: 'San Godenzo' },
  { label: 'San Gregorio', value: 'San Gregorio' },
  { label: 'San Gregorio', value: 'San Gregorio' },
  { label: 'San Gregorio', value: 'San Gregorio' },
  { label: 'San Gregorio', value: 'San Gregorio' },
  { label: 'San Gregorio', value: 'San Gregorio' },
  { label: 'San Gregorio', value: 'San Gregorio' },
  { label: 'San Gregorio', value: 'San Gregorio' },
  { label: 'San Gregorio Atzompa', value: 'San Gregorio Atzompa' },
  { label: 'San Gregorio Aztotoacan', value: 'San Gregorio Aztotoacan' },
  { label: 'San Gregorio Cuautzingo', value: 'San Gregorio Cuautzingo' },
  { label: 'San Gregorio Da Sassola', value: 'San Gregorio Da Sassola' },
  { label: 'San Gregorio De Nigua', value: 'San Gregorio De Nigua' },
  { label: 'San Gregorio Di Catania', value: 'San Gregorio Di Catania' },
  { label: 'San Gregorio Dippona', value: 'San Gregorio Dippona' },
  { label: 'San Gregorio Magno', value: 'San Gregorio Magno' },
  { label: 'San Gregorio Matese', value: 'San Gregorio Matese' },
  { label: 'San Gregorio Nelle Alpi', value: 'San Gregorio Nelle Alpi' },
  { label: 'San Guillermo', value: 'San Guillermo' },
  { label: 'San Guillermo', value: 'San Guillermo' },
  { label: 'San Gustavo', value: 'San Gustavo' },
  { label: 'San Ġwann', value: 'San Ġwann' },
  { label: 'San Hipólito', value: 'San Hipólito' },
  { label: 'San Hipólito Chimalpa', value: 'San Hipólito Chimalpa' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio', value: 'San Ignacio' },
  { label: 'San Ignacio De Hidalgo', value: 'San Ignacio De Hidalgo' },
  { label: 'San Ignacio De Rivera (Ojo De Agua)', value: 'San Ignacio De Rivera (Ojo De Agua)' },
  { label: 'San Ignacio De Sabaneta', value: 'San Ignacio De Sabaneta' },
  { label: 'San Ignacio De San José Parangueo', value: 'San Ignacio De San José Parangueo' },
  { label: 'San Ignacio De Tupile', value: 'San Ignacio De Tupile' },
  { label: 'San Ignacio De Velasco', value: 'San Ignacio De Velasco' },
  { label: 'San Ignacio Municipality Jalisco', value: 'San Ignacio Municipality Jalisco' },
  { label: 'San Ignacio Nopala', value: 'San Ignacio Nopala' },
  { label: 'San Ignacio Río Muerto', value: 'San Ignacio Río Muerto' },
  { label: 'San Ildefonso', value: 'San Ildefonso' },
  { label: 'San Ildefonso', value: 'San Ildefonso' },
  { label: 'San Ildefonso', value: 'San Ildefonso' },
  { label: 'San Ildefonso', value: 'San Ildefonso' },
  { label: 'San Ildefonso', value: 'San Ildefonso' },
  { label: 'San Ildefonso', value: 'San Ildefonso' },
  { label: 'San Ildefonso', value: 'San Ildefonso' },
  { label: 'San Ildefonso', value: 'San Ildefonso' },
  { label: 'San Ildefonso Amatlán', value: 'San Ildefonso Amatlán' },
  { label: 'San Ildefonso Tultepec', value: 'San Ildefonso Tultepec' },
  { label: 'San Ildefonso Villa Alta', value: 'San Ildefonso Villa Alta' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro', value: 'San Isidro' },
  { label: 'San Isidro (El Reservado)', value: 'San Isidro (El Reservado)' },
  { label: 'San Isidro Alta Huerta', value: 'San Isidro Alta Huerta' },
  { label: 'San Isidro Apango', value: 'San Isidro Apango' },
  { label: 'San Isidro Boxipe', value: 'San Isidro Boxipe' },
  { label: 'San Isidro Buen Suceso', value: 'San Isidro Buen Suceso' },
  { label: 'San Isidro Buenavista', value: 'San Isidro Buenavista' },
  { label: 'San Isidro De Curuguaty', value: 'San Isidro De Curuguaty' },
  { label: 'San Isidro De Gamboa', value: 'San Isidro De Gamboa' },
  { label: 'San Isidro De La Concepción', value: 'San Isidro De La Concepción' },
  { label: 'San Isidro De La Estacada', value: 'San Isidro De La Estacada' },
  { label: 'San Isidro De Los Sauces', value: 'San Isidro De Los Sauces' },
  { label: 'San Isidro El Berro', value: 'San Isidro El Berro' },
  { label: 'San Isidro Gallinero (El Gallinero)', value: 'San Isidro Gallinero (El Gallinero)' },
  { label: 'San Isidro Huilotepec', value: 'San Isidro Huilotepec' },
  { label: 'San Isidro Las Banderas', value: 'San Isidro Las Banderas' },
  { label: 'San Isidro Mazatepec', value: 'San Isidro Mazatepec' },
  { label: 'San Isidro Miranda', value: 'San Isidro Miranda' },
  { label: 'San Isidro Monjas', value: 'San Isidro Monjas' },
  { label: 'San Isidro Monterrosas', value: 'San Isidro Monterrosas' },
  { label: 'San Isidro Palotal', value: 'San Isidro Palotal' },
  { label: 'San Isidro Vista Hermosa', value: 'San Isidro Vista Hermosa' },
  { label: 'San Isidro Xoteapan (San Isidro Texcaltitán)', value: 'San Isidro Xoteapan (San Isidro Texcaltitán)' },
  { label: 'San Jacintito', value: 'San Jacintito' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto', value: 'San Jacinto' },
  { label: 'San Jacinto Amilpas', value: 'San Jacinto Amilpas' },
  { label: 'San Jacinto County', value: 'San Jacinto County' },
  { label: 'San Jacinto Del Cauca', value: 'San Jacinto Del Cauca' },
  { label: 'San Jacinto Jalisco', value: 'San Jacinto Jalisco' },
  { label: 'San Jacopo Al Girone', value: 'San Jacopo Al Girone' },
  { label: 'San Javier', value: 'San Javier' },
  { label: 'San Javier', value: 'San Javier' },
  { label: 'San Javier', value: 'San Javier' },
  { label: 'San Javier', value: 'San Javier' },
  { label: 'San Javier', value: 'San Javier' },
  { label: 'San Javier', value: 'San Javier' },
  { label: 'San Jeronimito', value: 'San Jeronimito' },
  { label: 'San Jerónimo', value: 'San Jerónimo' },
  { label: 'San Jerónimo', value: 'San Jerónimo' },
  { label: 'San Jerónimo', value: 'San Jerónimo' },
  { label: 'San Jerónimo', value: 'San Jerónimo' },
  { label: 'San Jerónimo', value: 'San Jerónimo' },
  { label: 'San Jerónimo', value: 'San Jerónimo' },
  { label: 'San Jerónimo', value: 'San Jerónimo' },
  { label: 'San Jerónimo', value: 'San Jerónimo' },
  { label: 'San Jerónimo', value: 'San Jerónimo' },
  { label: 'San Jerónimo Acazulco', value: 'San Jerónimo Acazulco' },
  { label: 'San Jerónimo Almoloya', value: 'San Jerónimo Almoloya' },
  { label: 'San Jerónimo Amanalco', value: 'San Jerónimo Amanalco' },
  { label: 'San Jerónimo Axochitlán', value: 'San Jerónimo Axochitlán' },
  { label: 'San Jerónimo Bonchete', value: 'San Jerónimo Bonchete' },
  { label: 'San Jerónimo Chicahualco', value: 'San Jerónimo Chicahualco' },
  { label: 'San Jerónimo Coaltepec', value: 'San Jerónimo Coaltepec' },
  { label: 'San Jerónimo Coatlán', value: 'San Jerónimo Coatlán' },
  { label: 'San Jerónimo Coyula', value: 'San Jerónimo Coyula' },
  { label: 'San Jerónimo Cuatro Vientos', value: 'San Jerónimo Cuatro Vientos' },
  { label: 'San Jerónimo De Araceo', value: 'San Jerónimo De Araceo' },
  { label: 'San Jerónimo De Juárez', value: 'San Jerónimo De Juárez' },
  { label: 'San Jerónimo De Los Jarros', value: 'San Jerónimo De Los Jarros' },
  { label: 'San Jerónimo El Grande', value: 'San Jerónimo El Grande' },
  { label: 'San Jerónimo Ixtapantongo', value: 'San Jerónimo Ixtapantongo' },
  { label: 'San Jerónimo Ocotitla', value: 'San Jerónimo Ocotitla' },
  { label: 'San Jerónimo Purenchecuaro', value: 'San Jerónimo Purenchecuaro' },
  { label: 'San Jerónimo Silacayoapilla', value: 'San Jerónimo Silacayoapilla' },
  { label: 'San Jerónimo Sosola', value: 'San Jerónimo Sosola' },
  { label: 'San Jerónimo Taviche', value: 'San Jerónimo Taviche' },
  { label: 'San Jerónimo Tecóatl', value: 'San Jerónimo Tecóatl' },
  { label: 'San Jerónimo Tlacochahuaya', value: 'San Jerónimo Tlacochahuaya' },
  { label: 'San Jerónimo Totoltepec', value: 'San Jerónimo Totoltepec' },
  { label: 'San Jerónimo Tulijá', value: 'San Jerónimo Tulijá' },
  { label: 'San Jerónimo Xayacatlán', value: 'San Jerónimo Xayacatlán' },
  { label: 'San Jerónimo Yahuiche', value: 'San Jerónimo Yahuiche' },
  { label: 'San Jerónimo Zacualpan', value: 'San Jerónimo Zacualpan' },
  { label: 'San Joaquin', value: 'San Joaquin' },
  { label: 'San Joaquin', value: 'San Joaquin' },
  { label: 'San Joaquin', value: 'San Joaquin' },
  { label: 'San Joaquin', value: 'San Joaquin' },
  { label: 'San Joaquin', value: 'San Joaquin' },
  { label: 'San Joaquín', value: 'San Joaquín' },
  { label: 'San Joaquín', value: 'San Joaquín' },
  { label: 'San Joaquín', value: 'San Joaquín' },
  { label: 'San Joaquín', value: 'San Joaquín' },
  { label: 'San Joaquín', value: 'San Joaquín' },
  { label: 'San Joaquín', value: 'San Joaquín' },
  { label: 'San Joaquín Coapango', value: 'San Joaquín Coapango' },
  { label: 'San Joaquin County', value: 'San Joaquin County' },
  { label: 'San Joaquín Del Monte', value: 'San Joaquín Del Monte' },
  { label: 'San Joaquin Hills', value: 'San Joaquin Hills' },
  { label: 'San Joaquín Zorrillos', value: 'San Joaquín Zorrillos' },
  { label: 'San Jorge', value: 'San Jorge' },
  { label: 'San Jorge', value: 'San Jorge' },
  { label: 'San Jorge', value: 'San Jorge' },
  { label: 'San Jorge', value: 'San Jorge' },
  { label: 'San Jorge', value: 'San Jorge' },
  { label: 'San Jorge', value: 'San Jorge' },
  { label: 'San Jorge Nuchita', value: 'San Jorge Nuchita' },
  { label: 'San Jorge Pueblo Nuevo', value: 'San Jorge Pueblo Nuevo' },
  { label: 'San Jorge Tezoquipan', value: 'San Jorge Tezoquipan' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José', value: 'San José' },
  { label: 'San José Acatempa', value: 'San José Acatempa' },
  { label: 'San José Acateno', value: 'San José Acateno' },
  { label: 'San José Alchichica', value: 'San José Alchichica' },
  { label: 'San José Atlán', value: 'San José Atlán' },
  { label: 'San José Atoyatenco', value: 'San José Atoyatenco' },
  { label: 'San José Ayuquila', value: 'San José Ayuquila' },
  { label: 'San José Aztatla', value: 'San José Aztatla' },
  { label: 'San José Barbabosa', value: 'San José Barbabosa' },
  { label: 'San José Boqui', value: 'San José Boqui' },
  { label: 'San José Boxay', value: 'San José Boxay' },
  { label: 'San José Buena Vista', value: 'San José Buena Vista' },
  { label: 'San José Buenavista', value: 'San José Buenavista' },
  { label: 'San José Buenavista', value: 'San José Buenavista' },
  { label: 'San José Buenavista El Grande', value: 'San José Buenavista El Grande' },
  { label: 'San José Carpinteros', value: 'San José Carpinteros' },
  { label: 'San José Casas Caídas', value: 'San José Casas Caídas' },
  { label: 'San José Chacalapa', value: 'San José Chacalapa' },
  { label: 'San José Chacayá', value: 'San José Chacayá' },
  { label: 'San José Chalmita', value: 'San José Chalmita' },
  { label: 'San José Chapayal', value: 'San José Chapayal' },
  { label: 'San José Chiapa', value: 'San José Chiapa' },
  { label: 'San José Chiltepec', value: 'San José Chiltepec' },
  { label: 'San José Comalco', value: 'San José Comalco' },
  { label: 'San José Corral Blanco', value: 'San José Corral Blanco' },
  { label: 'San José Cuamantzingo', value: 'San José Cuamantzingo' },
  { label: 'San José Cuaro', value: 'San José Cuaro' },
  { label: 'San José Cuyachapa', value: 'San José Cuyachapa' },
  { label: 'San José De Abajo', value: 'San José De Abajo' },
  { label: 'San José De Abajo [Unidad Habitacional]', value: 'San José De Abajo [Unidad Habitacional]' },
  { label: 'San José De Agua Azul', value: 'San José De Agua Azul' },
  { label: 'San José De Albán', value: 'San José De Albán' },
  { label: 'San José De Aura', value: 'San José De Aura' },
  { label: 'San José De Ayala', value: 'San José De Ayala' },
  { label: 'San José De Bacum', value: 'San José De Bacum' },
  { label: 'San José De Bernalejo (El Guayabo)', value: 'San José De Bernalejo (El Guayabo)' },
  { label: 'San José De Bocay', value: 'San José De Bocay' },
  { label: 'San Jose De Buan', value: 'San Jose De Buan' },
  { label: 'San Jose De Buan', value: 'San Jose De Buan' },
  { label: 'San José De Castellanos', value: 'San José De Castellanos' },
  { label: 'San José De Cervera', value: 'San José De Cervera' },
  { label: 'San José De Colinas', value: 'San José De Colinas' },
  { label: 'San José De Comayagua', value: 'San José De Comayagua' },
  { label: 'San José De Copán', value: 'San José De Copán' },
  { label: 'San José De Cuatro Caminos', value: 'San José De Cuatro Caminos' },
  { label: 'San José De Cusmapa', value: 'San José De Cusmapa' },
  { label: 'San José De Durán (Los Troncoso)', value: 'San José De Durán (Los Troncoso)' },
  { label: 'San José De Feliciano', value: 'San José De Feliciano' },
  { label: 'San José De Gracia', value: 'San José De Gracia' },
  { label: 'San José De Gracia', value: 'San José De Gracia' },
  { label: 'San José De Gracia', value: 'San José De Gracia' },
  { label: 'San José De Gracia', value: 'San José De Gracia' },
  { label: 'San José De Gracia', value: 'San José De Gracia' },
  { label: 'San Jose De Gracia Jalisco', value: 'San Jose De Gracia Jalisco' },
  { label: 'San José De Guanajuato', value: 'San José De Guanajuato' },
  { label: 'San José De Guanipa', value: 'San José De Guanipa' },
  { label: 'San José De Guaymas', value: 'San José De Guaymas' },
  { label: 'San José De Jáchal', value: 'San José De Jáchal' },
  { label: 'San José De La Dormida', value: 'San José De La Dormida' },
  { label: 'San José De La Era', value: 'San José De La Era' },
  { label: 'San José De La Laja', value: 'San José De La Laja' },
  { label: 'San José De La Montaña', value: 'San José De La Montaña' },
  { label: 'San José De La Montaña', value: 'San José De La Montaña' },
  { label: 'San José De La Paz', value: 'San José De La Paz' },
  { label: 'San José De Las Conchas', value: 'San José De Las Conchas' },
  { label: 'San José De Las Flores', value: 'San José De Las Flores' },
  { label: 'San José De Las Flores', value: 'San José De Las Flores' },
  { label: 'San José De Las Lajas', value: 'San José De Las Lajas' },
  { label: 'San José De Las Matas', value: 'San José De Las Matas' },
  { label: 'San José De Las Pilas', value: 'San José De Las Pilas' },
  { label: 'San José De Llanos', value: 'San José De Llanos' },
  { label: 'San Jose De Los Olvera', value: 'San Jose De Los Olvera' },
  { label: 'San José De Los Remates', value: 'San José De Los Remates' },
  { label: 'San José De Los Reynoso', value: 'San José De Los Reynoso' },
  { label: 'San Jose De Lourdes', value: 'San Jose De Lourdes' },
  { label: 'San José De Maipo', value: 'San José De Maipo' },
  { label: 'San José De Mayo', value: 'San José De Mayo' },
  { label: 'San José De Medina', value: 'San José De Medina' },
  { label: 'San José De Mendoza', value: 'San José De Mendoza' },
  { label: 'San José De Miranda', value: 'San José De Miranda' },
  { label: 'San José De Ocoa', value: 'San José De Ocoa' },
  { label: 'San José De Pare', value: 'San José De Pare' },
  { label: 'San José De Raíces', value: 'San José De Raíces' },
  { label: 'San José De Rancho Nuevo (Los Arrieros)', value: 'San José De Rancho Nuevo (Los Arrieros)' },
  { label: 'San José De Río Tinto', value: 'San José De Río Tinto' },
  { label: 'San José De Tapia', value: 'San José De Tapia' },
  { label: 'San José De Tarros', value: 'San José De Tarros' },
  { label: 'San José De Uré', value: 'San José De Uré' },
  { label: 'San José De Viñedo', value: 'San José De Viñedo' },
  { label: 'San José De Zaragoza', value: 'San José De Zaragoza' },
  { label: 'San José Del Boquerón', value: 'San José Del Boquerón' },
  { label: 'San José Del Cabo', value: 'San José Del Cabo' },
  { label: 'San José Del Caliche (El Caliche)', value: 'San José Del Caliche (El Caliche)' },
  { label: 'San José Del Castillo', value: 'San José Del Castillo' },
  { label: 'San José Del Cerrito De Camargo', value: 'San José Del Cerrito De Camargo' },
  { label: 'San José Del Fragua', value: 'San José Del Fragua' },
  { label: 'San José Del Golfo', value: 'San José Del Golfo' },
  { label: 'San José Del Guaviare', value: 'San José Del Guaviare' },
  { label: 'San José Del Jagüey', value: 'San José Del Jagüey' },
  { label: 'San Jose Del Monte', value: 'San Jose Del Monte' },
  { label: 'San Jose Del Monte', value: 'San Jose Del Monte' },
  { label: 'San José Del Palmar', value: 'San José Del Palmar' },
  { label: 'San José Del Potrero', value: 'San José Del Potrero' },
  { label: 'San José Del Potrero', value: 'San José Del Potrero' },
  { label: 'San José Del Progreso', value: 'San José Del Progreso' },
  { label: 'San José Del Progreso', value: 'San José Del Progreso' },
  { label: 'San José Del Puente', value: 'San José Del Puente' },
  { label: 'San José Del Rincón Centro', value: 'San José Del Rincón Centro' },
  { label: 'San José Del Rodeo', value: 'San José Del Rodeo' },
  { label: 'San José Del Sitio', value: 'San José Del Sitio' },
  { label: 'San José Del Tapanco', value: 'San José Del Tapanco' },
  { label: 'San José Del Torreón', value: 'San José Del Torreón' },
  { label: 'San José Del Tunal', value: 'San José Del Tunal' },
  { label: 'San José Del Valle', value: 'San José Del Valle' },
  { label: 'San José Del Valle', value: 'San José Del Valle' },
  { label: 'San José El Alto', value: 'San José El Alto' },
  { label: 'San José El Cuartel', value: 'San José El Cuartel' },
  { label: 'San José El Ídolo', value: 'San José El Ídolo' },
  { label: 'San José El Llanito', value: 'San José El Llanito' },
  { label: 'San José El Nuevo', value: 'San José El Nuevo' },
  { label: 'San José El Saladillo (El Saladillo)', value: 'San José El Saladillo (El Saladillo)' },
  { label: 'San José El Vidrio', value: 'San José El Vidrio' },
  { label: 'San José Estancia Grande', value: 'San José Estancia Grande' },
  { label: 'San José Guadalupe Otzacatipan', value: 'San José Guadalupe Otzacatipan' },
  { label: 'San José Huipana', value: 'San José Huipana' },
  { label: 'San José Independencia', value: 'San José Independencia' },
  { label: 'San José Itho', value: 'San José Itho' },
  { label: 'San José Iturbide', value: 'San José Iturbide' },
  { label: 'San José Ixtapa', value: 'San José Ixtapa' },
  { label: 'San José Ixtapa (Barrio Viejo)', value: 'San José Ixtapa (Barrio Viejo)' },
  { label: 'San José La Arada', value: 'San José La Arada' },
  { label: 'San José La Garzona', value: 'San José La Garzona' },
  { label: 'San José La Hacienda', value: 'San José La Hacienda' },
  { label: 'San José Lachiguirí', value: 'San José Lachiguirí' },
  { label: 'San José Las Chicharras', value: 'San José Las Chicharras' },
  { label: 'San José Las Lomas', value: 'San José Las Lomas' },
  { label: 'San José Las Palmas', value: 'San José Las Palmas' },
  { label: 'San José Linares', value: 'San José Linares' },
  { label: 'San José Manzanitos', value: 'San José Manzanitos' },
  { label: 'San José Mezapa Sección I', value: 'San José Mezapa Sección I' },
  { label: 'San José Monte Chiquito', value: 'San José Monte Chiquito' },
  { label: 'San José Neria', value: 'San José Neria' },
  { label: 'San José Ocotillos', value: 'San José Ocotillos' },
  { label: 'San José Ojetenam', value: 'San José Ojetenam' },
  { label: 'San José Ojetenán', value: 'San José Ojetenán' },
  { label: 'San José Ozumba', value: 'San José Ozumba' },
  { label: 'San José Pathé', value: 'San José Pathé' },
  { label: 'San José Piedras Negras', value: 'San José Piedras Negras' },
  { label: 'San José Pinula', value: 'San José Pinula' },
  { label: 'San José Poaquil', value: 'San José Poaquil' },
  { label: 'San José Poliutla', value: 'San José Poliutla' },
  { label: 'San José Río Manzo', value: 'San José Río Manzo' },
  { label: 'San Jose Solís', value: 'San Jose Solís' },
  { label: 'San José Súchil', value: 'San José Súchil' },
  { label: 'San José Teacalco', value: 'San José Teacalco' },
  { label: 'San José Tejamanil', value: 'San José Tejamanil' },
  { label: 'San José Temascatío', value: 'San José Temascatío' },
  { label: 'San José Tenango', value: 'San José Tenango' },
  { label: 'San José Tenejapa', value: 'San José Tenejapa' },
  { label: 'San José Tenería (Tenería)', value: 'San José Tenería (Tenería)' },
  { label: 'San José Tepeyahualco', value: 'San José Tepeyahualco' },
  { label: 'San José Tepuzas', value: 'San José Tepuzas' },
  { label: 'San José Tetel', value: 'San José Tetel' },
  { label: 'San José Tilapa', value: 'San José Tilapa' },
  { label: 'San José Tlacotitlán', value: 'San José Tlacotitlán' },
  { label: 'San José Tlacuitlapan', value: 'San José Tlacuitlapan' },
  { label: 'San José Toxi', value: 'San José Toxi' },
  { label: 'San José Tzal', value: 'San José Tzal' },
  { label: 'San José Viborillas', value: 'San José Viborillas' },
  { label: 'San José Villa De Allende', value: 'San José Villa De Allende' },
  { label: 'San José Villarreal', value: 'San José Villarreal' },
  { label: 'San José Vista Hermosa', value: 'San José Vista Hermosa' },
  { label: 'San José Xicohténcatl', value: 'San José Xicohténcatl' },
  { label: 'San José Yashitinín', value: 'San José Yashitinín' },
  { label: 'San José Yocnajab', value: 'San José Yocnajab' },
  { label: 'San Josecito', value: 'San Josecito' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Juan Achichilco', value: 'San Juan Achichilco' },
  { label: 'San Juan Achiutla', value: 'San Juan Achiutla' },
  { label: 'San Juan Ahuehueco', value: 'San Juan Ahuehueco' },
  { label: 'San Juan Ahuehueyo', value: 'San Juan Ahuehueyo' },
  { label: 'San Juan Amecac', value: 'San Juan Amecac' },
  { label: 'San Juan Atenco', value: 'San Juan Atenco' },
  { label: 'San Juan Atepec', value: 'San Juan Atepec' },
  { label: 'San Juan Atezcapan', value: 'San Juan Atezcapan' },
  { label: 'San Juan Atitán', value: 'San Juan Atitán' },
  { label: 'San Juan Atzingo', value: 'San Juan Atzingo' },
  { label: 'San Juan Atzompa', value: 'San Juan Atzompa' },
  { label: 'San Juan Bautista', value: 'San Juan Bautista' },
  { label: 'San Juan Bautista', value: 'San Juan Bautista' },
  { label: 'San Juan Bautista', value: 'San Juan Bautista' },
  { label: 'San Juan Bautista', value: 'San Juan Bautista' },
  { label: 'San Juan Bautista', value: 'San Juan Bautista' },
  { label: 'San Juan Bautista', value: 'San Juan Bautista' },
  { label: 'San Juan Bautista Cacalote', value: 'San Juan Bautista Cacalote' },
  { label: 'San Juan Bautista Jayacatlán', value: 'San Juan Bautista Jayacatlán' },
  { label: 'San Juan Bautista La Raya', value: 'San Juan Bautista La Raya' },
  { label: 'San Juan Bautista Lo De Soto', value: 'San Juan Bautista Lo De Soto' },
  { label: 'San Juan Benito Juárez (San Juan Tararameo)', value: 'San Juan Benito Juárez (San Juan Tararameo)' },
  { label: 'San Juan Cabeza Del Río', value: 'San Juan Cabeza Del Río' },
  { label: 'San Juan Cacahuatepec', value: 'San Juan Cacahuatepec' },
  { label: 'San Juan Capistrano', value: 'San Juan Capistrano' },
  { label: 'San Juan Chamelco', value: 'San Juan Chamelco' },
  { label: 'San Juan Chilateca', value: 'San Juan Chilateca' },
  { label: 'San Juan Coajomulco', value: 'San Juan Coajomulco' },
  { label: 'San Juan Coatzospam', value: 'San Juan Coatzospam' },
  { label: 'San Juan Colón', value: 'San Juan Colón' },
  { label: 'San Juan Colorado', value: 'San Juan Colorado' },
  { label: 'San Juan Cosalá', value: 'San Juan Cosalá' },
  { label: 'San Juan Cote Ejido', value: 'San Juan Cote Ejido' },
  { label: 'San Juan Cotzal', value: 'San Juan Cotzal' },
  { label: 'San Juan Cotzocón', value: 'San Juan Cotzocón' },
  { label: 'San Juan County', value: 'San Juan County' },
  { label: 'San Juan County', value: 'San Juan County' },
  { label: 'San Juan County', value: 'San Juan County' },
  { label: 'San Juan County', value: 'San Juan County' },
  { label: 'San Juan Coxtocan', value: 'San Juan Coxtocan' },
  { label: 'San Juan Cuauhtémoc', value: 'San Juan Cuauhtémoc' },
  { label: 'San Juan Cuautla', value: 'San Juan Cuautla' },
  { label: 'San Juan Daxthi', value: 'San Juan Daxthi' },
  { label: 'San Juan De Abajo', value: 'San Juan De Abajo' },
  { label: 'San Juan De Abajo', value: 'San Juan De Abajo' },
  { label: 'San Juan De Alicante', value: 'San Juan De Alicante' },
  { label: 'San Juan De Arama', value: 'San Juan De Arama' },
  { label: 'San Juan De Aznalfarache', value: 'San Juan De Aznalfarache' },
  { label: 'San Juan De Betulia', value: 'San Juan De Betulia' },
  { label: 'San Juan De Dios', value: 'San Juan De Dios' },
  { label: 'San Juan De Dios', value: 'San Juan De Dios' },
  { label: 'San Juan De Dios (Naranjas De Dios)', value: 'San Juan De Dios (Naranjas De Dios)' },
  { label: 'San Juan De Énova', value: 'San Juan De Énova' },
  { label: 'San Juan De Flores', value: 'San Juan De Flores' },
  { label: 'San Juan De Gredos', value: 'San Juan De Gredos' },
  { label: 'San Juan De Guadalupe', value: 'San Juan De Guadalupe' },
  { label: 'San Juan De La Costa', value: 'San Juan De La Costa' },
  { label: 'San Juan De La Encinilla', value: 'San Juan De La Encinilla' },
  { label: 'San Juan De La Maguana', value: 'San Juan De La Maguana' },
  { label: 'San Juan De La Nava', value: 'San Juan De La Nava' },
  { label: 'San Juan De La Rambla', value: 'San Juan De La Rambla' },
  { label: 'San Juan De La Vaquería', value: 'San Juan De La Vaquería' },
  { label: 'San Juan De La Vega', value: 'San Juan De La Vega' },
  { label: 'San Juan De Las Huertas', value: 'San Juan De Las Huertas' },
  { label: 'San Juan De Las Manzanas', value: 'San Juan De Las Manzanas' },
  { label: 'San Juan De Limay', value: 'San Juan De Limay' },
  { label: 'San Juan De Los Arcos', value: 'San Juan De Los Arcos' },
  { label: 'San Juan De Los Cues', value: 'San Juan De Los Cues' },
  { label: 'San Juan De Los Jarros', value: 'San Juan De Los Jarros' },
  { label: 'San Juan De Los Lagos', value: 'San Juan De Los Lagos' },
  { label: 'San Juan De Los Morros', value: 'San Juan De Los Morros' },
  { label: 'San Juan De Los Platanos', value: 'San Juan De Los Platanos' },
  { label: 'San Juan De Los Reyes (Luis Valenzuela)', value: 'San Juan De Los Reyes (Luis Valenzuela)' },
  { label: 'San Juan De Manapiare', value: 'San Juan De Manapiare' },
  { label: 'San Juan De Mata', value: 'San Juan De Mata' },
  { label: 'San Juan De Mata', value: 'San Juan De Mata' },
  { label: 'San Juan De Moró', value: 'San Juan De Moró' },
  { label: 'San Juan De Opoa', value: 'San Juan De Opoa' },
  { label: 'San Juan De Oriente', value: 'San Juan De Oriente' },
  { label: 'San Juan De Otates', value: 'San Juan De Otates' },
  { label: 'San Juan De Plan', value: 'San Juan De Plan' },
  { label: 'San Juan De Planes', value: 'San Juan De Planes' },
  { label: 'San Juan De Río Coco', value: 'San Juan De Río Coco' },
  { label: 'San Juan De Río Seco', value: 'San Juan De Río Seco' },
  { label: 'San Juan De Sabinas', value: 'San Juan De Sabinas' },
  { label: 'San Juan De Urabá', value: 'San Juan De Urabá' },
  { label: 'San Juan De Viña', value: 'San Juan De Viña' },
  { label: 'San Juan Del Cesar', value: 'San Juan Del Cesar' },
  { label: 'San Juan Del Estado', value: 'San Juan Del Estado' },
  { label: 'San Juan Del Llanito', value: 'San Juan Del Llanito' },
  { label: 'San Juan Del Molinillo', value: 'San Juan Del Molinillo' },
  { label: 'San Juan Del Monte', value: 'San Juan Del Monte' },
  { label: 'San Juan Del Olmo', value: 'San Juan Del Olmo' },
  { label: 'San Juan Del Paraná', value: 'San Juan Del Paraná' },
  { label: 'San Juan Del Puerto', value: 'San Juan Del Puerto' },
  { label: 'San Juan Del Río', value: 'San Juan Del Río' },
  { label: 'San Juan Del Río', value: 'San Juan Del Río' },
  { label: 'San Juan Del Rio Del Centauro Del Norte', value: 'San Juan Del Rio Del Centauro Del Norte' },
  { label: 'San Juan Del Sur', value: 'San Juan Del Sur' },
  { label: 'San Juan Del Surutú', value: 'San Juan Del Surutú' },
  { label: 'San Juan Diuxi', value: 'San Juan Diuxi' },
  { label: 'San Juan Epatlán', value: 'San Juan Epatlán' },
  { label: 'San Juan Ermita', value: 'San Juan Ermita' },
  { label: 'San Juan Evangelista', value: 'San Juan Evangelista' },
  { label: 'San Juan Grande', value: 'San Juan Grande' },
  { label: 'San Juan Guarita', value: 'San Juan Guarita' },
  { label: 'San Juan Guelavía', value: 'San Juan Guelavía' },
  { label: 'San Juan Guichicovi', value: 'San Juan Guichicovi' },
  { label: 'San Juan Hueyapan', value: 'San Juan Hueyapan' },
  { label: 'San Juan Huiluco', value: 'San Juan Huiluco' },
  { label: 'San Juan Ihualtepec', value: 'San Juan Ihualtepec' },
  { label: 'San Juan Ixcaquixtla', value: 'San Juan Ixcaquixtla' },
  { label: 'San Juan Ixcoy', value: 'San Juan Ixcoy' },
  { label: 'San Juan Ixtayopan', value: 'San Juan Ixtayopan' },
  { label: 'San Juan Jalpa Centro', value: 'San Juan Jalpa Centro' },
  { label: 'San Juan Jaltepec', value: 'San Juan Jaltepec' },
  { label: 'San Juan Jaripeo', value: 'San Juan Jaripeo' },
  { label: 'San Juan Jicayán', value: 'San Juan Jicayán' },
  { label: 'San Juan Juquila', value: 'San Juan Juquila' },
  { label: 'San Juan Juquila Vijanos', value: 'San Juan Juquila Vijanos' },
  { label: 'San Juan La Isla', value: 'San Juan La Isla' },
  { label: 'San Juan La Laguna', value: 'San Juan La Laguna' },
  { label: 'San Juan Lachao', value: 'San Juan Lachao' },
  { label: 'San Juán Lachigalla', value: 'San Juán Lachigalla' },
  { label: 'San Juan Lagunas', value: 'San Juan Lagunas' },
  { label: 'San Juan Mazatlán', value: 'San Juan Mazatlán' },
  { label: 'San Juan Metaltepec', value: 'San Juan Metaltepec' },
  { label: 'San Juan Nepomuceno', value: 'San Juan Nepomuceno' },
  { label: 'San Juan Nepomuceno', value: 'San Juan Nepomuceno' },
  { label: 'San Juan Ñumí', value: 'San Juan Ñumí' },
  { label: 'San Juan Opico', value: 'San Juan Opico' },
  { label: 'San Juan Ozolotepec', value: 'San Juan Ozolotepec' },
  { label: 'San Juan Pan De Arriba', value: 'San Juan Pan De Arriba' },
  { label: 'San Juan Pancoac', value: 'San Juan Pancoac' },
  { label: 'San Juan Petlapa', value: 'San Juan Petlapa' },
  { label: 'San Juan Pueblo', value: 'San Juan Pueblo' },
  { label: 'San Juan Pueblo Nuevo', value: 'San Juan Pueblo Nuevo' },
  { label: 'San Juan Quetzalcoapan', value: 'San Juan Quetzalcoapan' },
  { label: 'San Juan Quiahue', value: 'San Juan Quiahue' },
  { label: 'San Juan Quiotepec', value: 'San Juan Quiotepec' },
  { label: 'San Juan Raboso', value: 'San Juan Raboso' },
  { label: 'San Juan Sacatepéquez', value: 'San Juan Sacatepéquez' },
  { label: 'San Juan Sautla', value: 'San Juan Sautla' },
  { label: 'San Juan Sayultepec', value: 'San Juan Sayultepec' },
  { label: 'San Juan Seco De Valencia', value: 'San Juan Seco De Valencia' },
  { label: 'San Juan Solís', value: 'San Juan Solís' },
  { label: 'San Juan Tabaá', value: 'San Juan Tabaá' },
  { label: 'San Juan Tahitic', value: 'San Juan Tahitic' },
  { label: 'San Juan Tecomatlán', value: 'San Juan Tecomatlán' },
  { label: 'San Juan Tecuaco', value: 'San Juan Tecuaco' },
  { label: 'San Juan Tehuixtitlán', value: 'San Juan Tehuixtitlán' },
  { label: 'San Juan Teitipac', value: 'San Juan Teitipac' },
  { label: 'San Juan Tejaluca', value: 'San Juan Tejaluca' },
  { label: 'San Juan Tejupa', value: 'San Juan Tejupa' },
  { label: 'San Juan Tepa', value: 'San Juan Tepa' },
  { label: 'San Juan Tepecoculco', value: 'San Juan Tepecoculco' },
  { label: 'San Juan Teposcolula', value: 'San Juan Teposcolula' },
  { label: 'San Juan Tepulco', value: 'San Juan Tepulco' },
  { label: 'San Juan Tetla', value: 'San Juan Tetla' },
  { label: 'San Juan Texcalpan', value: 'San Juan Texcalpan' },
  { label: 'San Juan Tezongo', value: 'San Juan Tezongo' },
  { label: 'San Juan Tilapa', value: 'San Juan Tilapa' },
  { label: 'San Juan Tilcuautla', value: 'San Juan Tilcuautla' },
  { label: 'San Juan Tizahuapán', value: 'San Juan Tizahuapán' },
  { label: 'San Juan Tlacotompa (Tlacotompa)', value: 'San Juan Tlacotompa (Tlacotompa)' },
  { label: 'San Juan Tomasquillo Herradura', value: 'San Juan Tomasquillo Herradura' },
  { label: 'San Juan Totolapan', value: 'San Juan Totolapan' },
  { label: 'San Juan Totolcintla', value: 'San Juan Totolcintla' },
  { label: 'San Juan Tuxco', value: 'San Juan Tuxco' },
  { label: 'San Juan Tuxtepec', value: 'San Juan Tuxtepec' },
  { label: 'San Juan Volador', value: 'San Juan Volador' },
  { label: 'San Juan Xiutetelco', value: 'San Juan Xiutetelco' },
  { label: 'San Juan Xochiaca', value: 'San Juan Xochiaca' },
  { label: 'San Juan Xoconusco', value: 'San Juan Xoconusco' },
  { label: 'San Juan Y San Pedro Tezompa', value: 'San Juan Y San Pedro Tezompa' },
  { label: 'San Juan Yaee', value: 'San Juan Yaee' },
  { label: 'San Juan Yatzona', value: 'San Juan Yatzona' },
  { label: 'San Juan Yautepec', value: 'San Juan Yautepec' },
  { label: 'San Juan Zapotitlán', value: 'San Juan Zapotitlán' },
  { label: 'San Juan Zitlaltepec', value: 'San Juan Zitlaltepec' },
  { label: 'San Juanico Sector Uno', value: 'San Juanico Sector Uno' },
  { label: 'San Juanito', value: 'San Juanito' },
  { label: 'San Juanito', value: 'San Juanito' },
  { label: 'San Juanito De Escobedo', value: 'San Juanito De Escobedo' },
  { label: 'San Juanito Itzícuaro', value: 'San Juanito Itzícuaro' },
  { label: 'San Julian', value: 'San Julian' },
  { label: 'San Julian', value: 'San Julian' },
  { label: 'San Julian', value: 'San Julian' },
  { label: 'San Julián', value: 'San Julián' },
  { label: 'San Julián', value: 'San Julián' },
  { label: 'San Julián', value: 'San Julián' },
  { label: 'San Julio', value: 'San Julio' },
  { label: 'San Justo', value: 'San Justo' },
  { label: 'San Justo', value: 'San Justo' },
  { label: 'San Justo', value: 'San Justo' },
  { label: 'San Justo De La Vega', value: 'San Justo De La Vega' },
  { label: 'San Kamphaeng', value: 'San Kamphaeng' },
  { label: 'San Lawrenz', value: 'San Lawrenz' },
  { label: 'San Lazzaro', value: 'San Lazzaro' },
  { label: 'San Leandro', value: 'San Leandro' },
  { label: 'San Leo', value: 'San Leo' },
  { label: 'San Leon', value: 'San Leon' },
  { label: 'San Leonardo', value: 'San Leonardo' },
  { label: 'San Leonardo', value: 'San Leonardo' },
  { label: 'San Leonardo', value: 'San Leonardo' },
  { label: 'San Leonardo', value: 'San Leonardo' },
  { label: 'San Leonardo De Yagüe', value: 'San Leonardo De Yagüe' },
  { label: 'San Leonardo In Passiria', value: 'San Leonardo In Passiria' },
  { label: 'San Leoncio Jamaya', value: 'San Leoncio Jamaya' },
  { label: 'San Leone Mosè', value: 'San Leone Mosè' },
  { label: 'San Leucio Del Sannio-Cavuoti', value: 'San Leucio Del Sannio-Cavuoti' },
  { label: 'San Liberale', value: 'San Liberale' },
  { label: 'San Llorente', value: 'San Llorente' },
  { label: 'San Lorenzello', value: 'San Lorenzello' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo', value: 'San Lorenzo' },
  { label: 'San Lorenzo Achiotepec', value: 'San Lorenzo Achiotepec' },
  { label: 'San Lorenzo Acopilco', value: 'San Lorenzo Acopilco' },
  { label: 'San Lorenzo Al Mare', value: 'San Lorenzo Al Mare' },
  { label: 'San Lorenzo Albarradas', value: 'San Lorenzo Albarradas' },
  { label: 'San Lorenzo Axocomanitla', value: 'San Lorenzo Axocomanitla' },
  { label: 'San Lorenzo Bellizzi', value: 'San Lorenzo Bellizzi' },
  { label: 'San Lorenzo Cacaotepec', value: 'San Lorenzo Cacaotepec' },
  { label: 'San Lorenzo Chiautzingo', value: 'San Lorenzo Chiautzingo' },
  { label: 'San Lorenzo Cuauhtenco', value: 'San Lorenzo Cuauhtenco' },
  { label: 'San Lorenzo Cuaunecuiltitla', value: 'San Lorenzo Cuaunecuiltitla' },
  { label: 'San Lorenzo De Calatrava', value: 'San Lorenzo De Calatrava' },
  { label: 'San Lorenzo De El Escorial', value: 'San Lorenzo De El Escorial' },
  { label: 'San Lorenzo De Esmeraldas', value: 'San Lorenzo De Esmeraldas' },
  { label: 'San Lorenzo De La Parrilla', value: 'San Lorenzo De La Parrilla' },
  { label: 'San Lorenzo De Tormes', value: 'San Lorenzo De Tormes' },
  { label: 'San Lorenzo Del Vallo', value: 'San Lorenzo Del Vallo' },
  { label: 'San Lorenzo Di Rovetta', value: 'San Lorenzo Di Rovetta' },
  { label: 'San Lorenzo Di Sebato', value: 'San Lorenzo Di Sebato' },
  { label: 'San Lorenzo Huehuetitlán', value: 'San Lorenzo Huehuetitlán' },
  { label: 'San Lorenzo In Banale', value: 'San Lorenzo In Banale' },
  { label: 'San Lorenzo In Campo', value: 'San Lorenzo In Campo' },
  { label: 'San Lorenzo Isontino', value: 'San Lorenzo Isontino' },
  { label: 'San Lorenzo Itzícuaro', value: 'San Lorenzo Itzícuaro' },
  { label: 'San Lorenzo Joya De Rodríguez', value: 'San Lorenzo Joya De Rodríguez' },
  { label: 'San Lorenzo Maggiore', value: 'San Lorenzo Maggiore' },
  { label: 'San Lorenzo Malacota', value: 'San Lorenzo Malacota' },
  { label: 'San Lorenzo Nenamicoyan', value: 'San Lorenzo Nenamicoyan' },
  { label: 'San Lorenzo Nuovo', value: 'San Lorenzo Nuovo' },
  { label: 'San Lorenzo Oyamel', value: 'San Lorenzo Oyamel' },
  { label: 'San Lorenzo Queréndaro', value: 'San Lorenzo Queréndaro' },
  { label: 'San Lorenzo Sayula', value: 'San Lorenzo Sayula' },
  { label: 'San Lorenzo Sóltepec', value: 'San Lorenzo Sóltepec' },
  { label: 'San Lorenzo Tenochtitlán', value: 'San Lorenzo Tenochtitlán' },
  { label: 'San Lorenzo Tlacotepec', value: 'San Lorenzo Tlacotepec' },
  { label: 'San Lorenzo Tlacoyucan', value: 'San Lorenzo Tlacoyucan' },
  { label: 'San Lorenzo Tlalmimilolpan', value: 'San Lorenzo Tlalmimilolpan' },
  { label: 'San Lorenzo Tlaxipehuala', value: 'San Lorenzo Tlaxipehuala' },
  { label: 'San Lorenzo Toxico', value: 'San Lorenzo Toxico' },
  { label: 'San Lorenzo Victoria', value: 'San Lorenzo Victoria' },
  { label: 'San Lorenzo Vista Hermosa', value: 'San Lorenzo Vista Hermosa' },
  { label: 'San Lorenzo Xaltelulco', value: 'San Lorenzo Xaltelulco' },
  { label: 'San Luca', value: 'San Luca' },
  { label: 'San Lucas', value: 'San Lucas' },
  { label: 'San Lucas', value: 'San Lucas' },
  { label: 'San Lucas', value: 'San Lucas' },
  { label: 'San Lucas', value: 'San Lucas' },
  { label: 'San Lucas', value: 'San Lucas' },
  { label: 'San Lucas', value: 'San Lucas' },
  { label: 'San Lucas', value: 'San Lucas' },
  { label: 'San Lucas Amalinalco', value: 'San Lucas Amalinalco' },
  { label: 'San Lucas Atzala', value: 'San Lucas Atzala' },
  { label: 'San Lucas Camotlán', value: 'San Lucas Camotlán' },
  { label: 'San Lucas Del Pulque', value: 'San Lucas Del Pulque' },
  { label: 'San Lucas El Grande', value: 'San Lucas El Grande' },
  { label: 'San Lucas El Viejo', value: 'San Lucas El Viejo' },
  { label: 'San Lucas Evangelista', value: 'San Lucas Evangelista' },
  { label: 'San Lucas Huarirapeo (La Mesa De San Lucas)', value: 'San Lucas Huarirapeo (La Mesa De San Lucas)' },
  { label: 'San Lucas Huitzilhuacán', value: 'San Lucas Huitzilhuacán' },
  { label: 'San Lucas Ocotepec', value: 'San Lucas Ocotepec' },
  { label: 'San Lucas Ojitlán', value: 'San Lucas Ojitlán' },
  { label: 'San Lucas Pío', value: 'San Lucas Pío' },
  { label: 'San Lucas Quiavini', value: 'San Lucas Quiavini' },
  { label: 'San Lucas Sacatepéquez', value: 'San Lucas Sacatepéquez' },
  { label: 'San Lucas Tecopilco', value: 'San Lucas Tecopilco' },
  { label: 'San Lucas Texcaltitlán', value: 'San Lucas Texcaltitlán' },
  { label: 'San Lucas Tlacochcalco', value: 'San Lucas Tlacochcalco' },
  { label: 'San Lucas Tolimán', value: 'San Lucas Tolimán' },
  { label: 'San Lucas Totolmaloya', value: 'San Lucas Totolmaloya' },
  { label: 'San Lucas Zoquiápam', value: 'San Lucas Zoquiápam' },
  { label: 'San Luciano', value: 'San Luciano' },
  { label: 'San Lucido', value: 'San Lucido' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'San Luis Acatlán', value: 'San Luis Acatlán' },
  { label: 'San Luis Ajajalpan', value: 'San Luis Ajajalpan' },
  { label: 'San Luis Amatlán', value: 'San Luis Amatlán' },
  { label: 'San Luis Anáhuac (Toriles)', value: 'San Luis Anáhuac (Toriles)' },
  { label: 'San Luis Apizaquito', value: 'San Luis Apizaquito' },
  { label: 'San Luis Ayucán', value: 'San Luis Ayucán' },
  { label: 'San Luis Boro', value: 'San Luis Boro' },
  { label: 'San Luis Coyotzingo', value: 'San Luis Coyotzingo' },
  { label: 'San Luis De Agua Caliente', value: 'San Luis De Agua Caliente' },
  { label: 'San Luis De Gaceno', value: 'San Luis De Gaceno' },
  { label: 'San Luis De La Loma', value: 'San Luis De La Loma' },
  { label: 'San Luis De La Paz', value: 'San Luis De La Paz' },
  { label: 'San Luis De Letras', value: 'San Luis De Letras' },
  { label: 'San Luis De Lozada', value: 'San Luis De Lozada' },
  { label: 'San Luis De Palenque', value: 'San Luis De Palenque' },
  { label: 'San Luis De Planes', value: 'San Luis De Planes' },
  { label: 'San Luis De Sincé', value: 'San Luis De Sincé' },
  { label: 'San Luis Del Palmar', value: 'San Luis Del Palmar' },
  { label: 'San Luis Ixcán', value: 'San Luis Ixcán' },
  { label: 'San Luis Jilotepeque', value: 'San Luis Jilotepeque' },
  { label: 'San Luis Obispo', value: 'San Luis Obispo' },
  { label: 'San Luis Obispo County', value: 'San Luis Obispo County' },
  { label: 'San Luis Potosí', value: 'San Luis Potosí' },
  { label: 'San Luis Río Colorado', value: 'San Luis Río Colorado' },
  { label: 'San Luis San Pedro', value: 'San Luis San Pedro' },
  { label: 'San Luis Soyatlán', value: 'San Luis Soyatlán' },
  { label: 'San Luis Taxhimay', value: 'San Luis Taxhimay' },
  { label: 'San Luis Tecuhautitlán', value: 'San Luis Tecuhautitlán' },
  { label: 'San Luis Tehuiloyocan', value: 'San Luis Tehuiloyocan' },
  { label: 'San Lupo', value: 'San Lupo' },
  { label: 'San Luqueño', value: 'San Luqueño' },
  { label: 'San Mamés De Burgos', value: 'San Mamés De Burgos' },
  { label: 'San Mamés De Campos', value: 'San Mamés De Campos' },
  { label: 'San Mamete', value: 'San Mamete' },
  { label: 'San Mango Daquino', value: 'San Mango Daquino' },
  { label: 'San Mango Piemonte', value: 'San Mango Piemonte' },
  { label: 'San Mango Sul Calore', value: 'San Mango Sul Calore' },
  { label: 'San Manuel', value: 'San Manuel' },
  { label: 'San Manuel', value: 'San Manuel' },
  { label: 'San Manuel', value: 'San Manuel' },
  { label: 'San Manuel', value: 'San Manuel' },
  { label: 'San Manuel', value: 'San Manuel' },
  { label: 'San Manuel', value: 'San Manuel' },
  { label: 'San Manuel', value: 'San Manuel' },
  { label: 'San Manuel', value: 'San Manuel' },
  { label: 'San Manuel Chaparrón', value: 'San Manuel Chaparrón' },
  { label: 'San Manuel Colohete', value: 'San Manuel Colohete' },
  { label: 'San Marcelino', value: 'San Marcelino' },
  { label: 'San Marcelino', value: 'San Marcelino' },
  { label: 'San Marcellino', value: 'San Marcellino' },
  { label: 'San Marcello', value: 'San Marcello' },
  { label: 'San Marcello Pistoiese', value: 'San Marcello Pistoiese' },
  { label: 'San Marcelo', value: 'San Marcelo' },
  { label: 'San Marcial Ozolotepec', value: 'San Marcial Ozolotepec' },
  { label: 'San Marco', value: 'San Marco' },
  { label: 'San Marco Argentano', value: 'San Marco Argentano' },
  { label: 'San Marco Dalunzio', value: 'San Marco Dalunzio' },
  { label: 'San Marco Dei Cavoti', value: 'San Marco Dei Cavoti' },
  { label: 'San Marco Evangelista', value: 'San Marco Evangelista' },
  { label: 'San Marco In Lamis', value: 'San Marco In Lamis' },
  { label: 'San Marco La Catola', value: 'San Marco La Catola' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos', value: 'San Marcos' },
  { label: 'San Marcos Arteaga', value: 'San Marcos Arteaga' },
  { label: 'San Marcos Atesquilapan', value: 'San Marcos Atesquilapan' },
  { label: 'San Marcos Contla', value: 'San Marcos Contla' },
  { label: 'San Marcos De Caiquin', value: 'San Marcos De Caiquin' },
  { label: 'San Marcos De Colón', value: 'San Marcos De Colón' },
  { label: 'San Marcos De La Cruz', value: 'San Marcos De La Cruz' },
  { label: 'San Marcos De La Loma', value: 'San Marcos De La Loma' },
  { label: 'San Marcos De La Sierra', value: 'San Marcos De La Sierra' },
  { label: 'San Marcos De León', value: 'San Marcos De León' },
  { label: 'San Marcos Eloxochitlán', value: 'San Marcos Eloxochitlán' },
  { label: 'San Marcos Guaquilpan', value: 'San Marcos Guaquilpan' },
  { label: 'San Marcos Huixtoco', value: 'San Marcos Huixtoco' },
  { label: 'San Marcos La Laguna', value: 'San Marcos La Laguna' },
  { label: 'San Marcos Nepantla', value: 'San Marcos Nepantla' },
  { label: 'San Marcos Tecomaxusco', value: 'San Marcos Tecomaxusco' },
  { label: 'San Marcos Tlacoyalco', value: 'San Marcos Tlacoyalco' },
  { label: 'San Marcos Tlazalpan', value: 'San Marcos Tlazalpan' },
  { label: 'San Marcos Yachihuacaltepec', value: 'San Marcos Yachihuacaltepec' },
  { label: 'San Marcos Zacatepec', value: 'San Marcos Zacatepec' },
  { label: 'San Mariano', value: 'San Mariano' },
  { label: 'San Mariano', value: 'San Mariano' },
  { label: 'San Mariano', value: 'San Mariano' },
  { label: 'San Mariano', value: 'San Mariano' },
  { label: 'San Mariano', value: 'San Mariano' },
  { label: 'San Mariano', value: 'San Mariano' },
  { label: 'San Mariano', value: 'San Mariano' },
  { label: 'San Mariano', value: 'San Mariano' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'San Martin', value: 'San Martin' },
  { label: 'San Martin', value: 'San Martin' },
  { label: 'San Martin', value: 'San Martin' },
  { label: 'San Martín', value: 'San Martín' },
  { label: 'San Martín', value: 'San Martín' },
  { label: 'San Martín', value: 'San Martín' },
  { label: 'San Martín', value: 'San Martín' },
  { label: 'San Martín', value: 'San Martín' },
  { label: 'San Martín', value: 'San Martín' },
  { label: 'San Martín', value: 'San Martín' },
  { label: 'San Martín', value: 'San Martín' },
  { label: 'San Martín', value: 'San Martín' },
  { label: 'San Martín Ahuatepec', value: 'San Martín Ahuatepec' },
  { label: 'San Martín Azcatepec', value: 'San Martín Azcatepec' },
  { label: 'San Martín Cachihuapan', value: 'San Martín Cachihuapan' },
  { label: 'San Martín Caltenco', value: 'San Martín Caltenco' },
  { label: 'San Martín Chalchicuautla', value: 'San Martín Chalchicuautla' },
  { label: 'San Martín Coapaxtongo', value: 'San Martín Coapaxtongo' },
  { label: 'San Martín Cuautlalpan', value: 'San Martín Cuautlalpan' },
  { label: 'San Martín De Bolaños', value: 'San Martín De Bolaños' },
  { label: 'San Martín De Boniches', value: 'San Martín De Boniches' },
  { label: 'San Martín De Elines', value: 'San Martín De Elines' },
  { label: 'San Martín De Hidalgo', value: 'San Martín De Hidalgo' },
  { label: 'San Martín De La Vega', value: 'San Martín De La Vega' },
  { label: 'San Martín De La Vega Del Alberche', value: 'San Martín De La Vega Del Alberche' },
  { label: 'San Martín De La Virgen De Moncayo', value: 'San Martín De La Virgen De Moncayo' },
  { label: 'San Martín De Las Pirámides', value: 'San Martín De Las Pirámides' },
  { label: 'San Martín De Loba', value: 'San Martín De Loba' },
  { label: 'San Martín De Los Andes', value: 'San Martín De Los Andes' },
  { label: 'San Martín De Los Canseco', value: 'San Martín De Los Canseco' },
  { label: 'San Martín De Montalbán', value: 'San Martín De Montalbán' },
  { label: 'San Martín De Oscos', value: 'San Martín De Oscos' },
  { label: 'San Martín De Pusa', value: 'San Martín De Pusa' },
  { label: 'San Martín De Rubiales', value: 'San Martín De Rubiales' },
  { label: 'San Martín De Trevejo', value: 'San Martín De Trevejo' },
  { label: 'San Martín De Unx', value: 'San Martín De Unx' },
  { label: 'San Martín De Valdeiglesias', value: 'San Martín De Valdeiglesias' },
  { label: 'San Martín De Valderaduey', value: 'San Martín De Valderaduey' },
  { label: 'San Martín De Valvení', value: 'San Martín De Valvení' },
  { label: 'San Martín Del Castañar', value: 'San Martín Del Castañar' },
  { label: 'San Martín Del Pimpollar', value: 'San Martín Del Pimpollar' },
  { label: 'San Martín Del Rey Aurelio', value: 'San Martín Del Rey Aurelio' },
  { label: 'San Martín Del Río', value: 'San Martín Del Río' },
  { label: 'San Martín Ejido', value: 'San Martín Ejido' },
  { label: 'San Martín Esperilla', value: 'San Martín Esperilla' },
  { label: 'San Martín Florida', value: 'San Martín Florida' },
  { label: 'San Martín Itunyoso', value: 'San Martín Itunyoso' },
  { label: 'San Martín Jilotepeque', value: 'San Martín Jilotepeque' },
  { label: 'San Martín Lachila', value: 'San Martín Lachila' },
  { label: 'San Martín Mazateopan', value: 'San Martín Mazateopan' },
  { label: 'San Martín Obispo (San Martín San Pedro)', value: 'San Martín Obispo (San Martín San Pedro)' },
  { label: 'San Martín Ojo De Agua', value: 'San Martín Ojo De Agua' },
  { label: 'San Martín Pachivia (Pachivia)', value: 'San Martín Pachivia (Pachivia)' },
  { label: 'San Martín Peras', value: 'San Martín Peras' },
  { label: 'San Martín Sacatepéquez', value: 'San Martín Sacatepéquez' },
  { label: 'San Martín Tequesquipan (Tequesquipan)', value: 'San Martín Tequesquipan (Tequesquipan)' },
  { label: 'San Martín Texmelucan', value: 'San Martín Texmelucan' },
  { label: 'San Martin Texmelucan De Labastida', value: 'San Martin Texmelucan De Labastida' },
  { label: 'San Martín Tilcajete', value: 'San Martín Tilcajete' },
  { label: 'San Martín Tlamapa', value: 'San Martín Tlamapa' },
  { label: 'San Martín Tlapala', value: 'San Martín Tlapala' },
  { label: 'San Martín Toltepec', value: 'San Martín Toltepec' },
  { label: 'San Martín Totolán', value: 'San Martín Totolán' },
  { label: 'San Martín Tuchicuitlapilco', value: 'San Martín Tuchicuitlapilco' },
  { label: 'San Martín Y Mudrián', value: 'San Martín Y Mudrián' },
  { label: 'San Martín Zapotitlán', value: 'San Martín Zapotitlán' },
  { label: 'San Martino', value: 'San Martino' },
  { label: 'San Martino', value: 'San Martino' },
  { label: 'San Martino', value: 'San Martino' },
  { label: 'San Martino', value: 'San Martino' },
  { label: 'San Martino Al Cimino', value: 'San Martino Al Cimino' },
  { label: 'San Martino Al Tagliamento', value: 'San Martino Al Tagliamento' },
  { label: 'San Martino Alfieri', value: 'San Martino Alfieri' },
  { label: 'San Martino Bassa', value: 'San Martino Bassa' },
  { label: 'San Martino Buon Albergo', value: 'San Martino Buon Albergo' },
  { label: 'San Martino Canavese', value: 'San Martino Canavese' },
  { label: 'San Martino Dagri', value: 'San Martino Dagri' },
  { label: 'San Martino Dallargine', value: 'San Martino Dallargine' },
  { label: 'San Martino Dei Mulini', value: 'San Martino Dei Mulini' },
  { label: 'San Martino Del Lago', value: 'San Martino Del Lago' },
  { label: 'San Martino Della Battaglia', value: 'San Martino Della Battaglia' },
  { label: 'San Martino Di Finita', value: 'San Martino Di Finita' },
  { label: 'San Martino Di Lupari', value: 'San Martino Di Lupari' },
  { label: 'San Martino Di Venezze', value: 'San Martino Di Venezze' },
  { label: 'San Martino In Argine', value: 'San Martino In Argine' },
  { label: 'San Martino In Badia', value: 'San Martino In Badia' },
  { label: 'San Martino In Campo', value: 'San Martino In Campo' },
  { label: 'San Martino In Freddana-Monsagrati', value: 'San Martino In Freddana-Monsagrati' },
  { label: 'San Martino In Passiria', value: 'San Martino In Passiria' },
  { label: 'San Martino In Pensilis', value: 'San Martino In Pensilis' },
  { label: 'San Martino In Rio', value: 'San Martino In Rio' },
  { label: 'San Martino In Strada', value: 'San Martino In Strada' },
  { label: 'San Martino In Trignano', value: 'San Martino In Trignano' },
  { label: 'San Martino Sannita', value: 'San Martino Sannita' },
  { label: 'San Martino Siccomario', value: 'San Martino Siccomario' },
  { label: 'San Martino Sulla Marrucina', value: 'San Martino Sulla Marrucina' },
  { label: 'San Martino Valle Caudina', value: 'San Martino Valle Caudina' },
  { label: 'San Marzano Di San Giuseppe', value: 'San Marzano Di San Giuseppe' },
  { label: 'San Marzano Oliveto', value: 'San Marzano Oliveto' },
  { label: 'San Marzano Sul Sarno', value: 'San Marzano Sul Sarno' },
  { label: 'San Massimo', value: 'San Massimo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo', value: 'San Mateo' },
  { label: 'San Mateo Almomoloha', value: 'San Mateo Almomoloha' },
  { label: 'San Mateo Atarasquíllo', value: 'San Mateo Atarasquíllo' },
  { label: 'San Mateo Atenco', value: 'San Mateo Atenco' },
  { label: 'San Mateo Ayecac', value: 'San Mateo Ayecac' },
  { label: 'San Mateo Cajonos', value: 'San Mateo Cajonos' },
  { label: 'San Mateo Capulhuac', value: 'San Mateo Capulhuac' },
  { label: 'San Mateo Capultitlán', value: 'San Mateo Capultitlán' },
  { label: 'San Mateo Coapexco', value: 'San Mateo Coapexco' },
  { label: 'San Mateo County', value: 'San Mateo County' },
  { label: 'San Mateo De Gállego', value: 'San Mateo De Gállego' },
  { label: 'San Mateo Del Mar', value: 'San Mateo Del Mar' },
  { label: 'San Mateo Doctor Arroyo', value: 'San Mateo Doctor Arroyo' },
  { label: 'San Mateo El Viejo', value: 'San Mateo El Viejo' },
  { label: 'San Mateo Etlatongo', value: 'San Mateo Etlatongo' },
  { label: 'San Mateo Huitzilzingo', value: 'San Mateo Huitzilzingo' },
  { label: 'San Mateo Ixtacalco', value: 'San Mateo Ixtacalco' },
  { label: 'San Mateo Ixtatán', value: 'San Mateo Ixtatán' },
  { label: 'San Mateo Ixtlahuaca', value: 'San Mateo Ixtlahuaca' },
  { label: 'San Mateo Mozoquilpan', value: 'San Mateo Mozoquilpan' },
  { label: 'San Mateo Nejápam', value: 'San Mateo Nejápam' },
  { label: 'San Mateo Otzacatipan', value: 'San Mateo Otzacatipan' },
  { label: 'San Mateo Ozolco', value: 'San Mateo Ozolco' },
  { label: 'San Mateo Parra', value: 'San Mateo Parra' },
  { label: 'San Mateo Piñas', value: 'San Mateo Piñas' },
  { label: 'San Mateo Sindihui', value: 'San Mateo Sindihui' },
  { label: 'San Mateo Soltepec', value: 'San Mateo Soltepec' },
  { label: 'San Mateo Tecalco', value: 'San Mateo Tecalco' },
  { label: 'San Mateo Texcalyacac', value: 'San Mateo Texcalyacac' },
  { label: 'San Mateo Tlalchichilpan', value: 'San Mateo Tlalchichilpan' },
  { label: 'San Mateo Xoloc', value: 'San Mateo Xoloc' },
  { label: 'San Mateo Yoloxochitlán', value: 'San Mateo Yoloxochitlán' },
  { label: 'San Matías', value: 'San Matías' },
  { label: 'San Matías', value: 'San Matías' },
  { label: 'San Matías Atzala', value: 'San Matías Atzala' },
  { label: 'San Matías Cuijingo', value: 'San Matías Cuijingo' },
  { label: 'San Matías Tepetomatitlán', value: 'San Matías Tepetomatitlán' },
  { label: 'San Matias Tlalancaleca', value: 'San Matias Tlalancaleca' },
  { label: 'San Maurizio', value: 'San Maurizio' },
  { label: 'San Maurizio Dopaglio', value: 'San Maurizio Dopaglio' },
  { label: 'San Mauro', value: 'San Mauro' },
  { label: 'San Mauro A Mare', value: 'San Mauro A Mare' },
  { label: 'San Mauro Castelverde', value: 'San Mauro Castelverde' },
  { label: 'San Mauro Cilento', value: 'San Mauro Cilento' },
  { label: 'San Mauro Di Saline', value: 'San Mauro Di Saline' },
  { label: 'San Mauro Forte', value: 'San Mauro Forte' },
  { label: 'San Mauro La Bruca', value: 'San Mauro La Bruca' },
  { label: 'San Mauro Marchesato', value: 'San Mauro Marchesato' },
  { label: 'San Mauro Pascoli', value: 'San Mauro Pascoli' },
  { label: 'San Mauro Torinese', value: 'San Mauro Torinese' },
  { label: 'San Melchor Betaza', value: 'San Melchor Betaza' },
  { label: 'San Michele', value: 'San Michele' },
  { label: 'San Michele', value: 'San Michele' },
  { label: 'San Michele', value: 'San Michele' },
  { label: 'San Michele Al Tagliamento', value: 'San Michele Al Tagliamento' },
  { label: 'San Michele Alladige', value: 'San Michele Alladige' },
  { label: 'San Michele Dei Mucchietti', value: 'San Michele Dei Mucchietti' },
  { label: 'San Michele Di Ganzaria', value: 'San Michele Di Ganzaria' },
  { label: 'San Michele Di Piave', value: 'San Michele Di Piave' },
  { label: 'San Michele Di Serino', value: 'San Michele Di Serino' },
  { label: 'San Michele Mondovì', value: 'San Michele Mondovì' },
  { label: 'San Michele Salentino', value: 'San Michele Salentino' },
  { label: 'San Michele Tiorre', value: 'San Michele Tiorre' },
  { label: 'San Michele-San Giorgio', value: 'San Michele-San Giorgio' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel', value: 'San Miguel' },
  { label: 'San Miguel Abejones', value: 'San Miguel Abejones' },
  { label: 'San Miguel Acatán', value: 'San Miguel Acatán' },
  { label: 'San Miguel Achiutla', value: 'San Miguel Achiutla' },
  { label: 'San Miguel Agua Bendita', value: 'San Miguel Agua Bendita' },
  { label: 'San Miguel Aguacomulican', value: 'San Miguel Aguacomulican' },
  { label: 'San Miguel Ajusco', value: 'San Miguel Ajusco' },
  { label: 'San Miguel Almaya', value: 'San Miguel Almaya' },
  { label: 'San Miguel Almoloyan', value: 'San Miguel Almoloyan' },
  { label: 'San Miguel Aloápam', value: 'San Miguel Aloápam' },
  { label: 'San Miguel Amazcala', value: 'San Miguel Amazcala' },
  { label: 'San Miguel Ameyalco', value: 'San Miguel Ameyalco' },
  { label: 'San Miguel Analco', value: 'San Miguel Analco' },
  { label: 'San Miguel Atepoxco', value: 'San Miguel Atepoxco' },
  { label: 'San Miguel Atlamajac', value: 'San Miguel Atlamajac' },
  { label: 'San Miguel Atlapulco', value: 'San Miguel Atlapulco' },
  { label: 'San Miguel Axoxuca', value: 'San Miguel Axoxuca' },
  { label: 'San Miguel Balderas', value: 'San Miguel Balderas' },
  { label: 'San Miguel Canoa', value: 'San Miguel Canoa' },
  { label: 'San Miguel Cerezo (El Cerezo)', value: 'San Miguel Cerezo (El Cerezo)' },
  { label: 'San Miguel Chicaj', value: 'San Miguel Chicaj' },
  { label: 'San Miguel Chimalapa', value: 'San Miguel Chimalapa' },
  { label: 'San Miguel Coatlán', value: 'San Miguel Coatlán' },
  { label: 'San Miguel Coatlinchán', value: 'San Miguel Coatlinchán' },
  { label: 'San Miguel Contla', value: 'San Miguel Contla' },
  { label: 'San Miguel County', value: 'San Miguel County' },
  { label: 'San Miguel County', value: 'San Miguel County' },
  { label: 'San Miguel Cuyutlán', value: 'San Miguel Cuyutlán' },
  { label: 'San Miguel De Abona', value: 'San Miguel De Abona' },
  { label: 'San Miguel De Aguayo', value: 'San Miguel De Aguayo' },
  { label: 'San Miguel De Allende', value: 'San Miguel De Allende' },
  { label: 'San Miguel De Bernuy', value: 'San Miguel De Bernuy' },
  { label: 'San Miguel De Cauri', value: 'San Miguel De Cauri' },
  { label: 'San Miguel De Corneja', value: 'San Miguel De Corneja' },
  { label: 'San Miguel De Cruces', value: 'San Miguel De Cruces' },
  { label: 'San Miguel De Horcasitas', value: 'San Miguel De Horcasitas' },
  { label: 'San Miguel De La Paz', value: 'San Miguel De La Paz' },
  { label: 'San Miguel De La Ribera', value: 'San Miguel De La Ribera' },
  { label: 'San Miguel De La Sierra Ayutla', value: 'San Miguel De La Sierra Ayutla' },
  { label: 'San Miguel De La Victoria', value: 'San Miguel De La Victoria' },
  { label: 'San Miguel De Las Palmas', value: 'San Miguel De Las Palmas' },
  { label: 'San Miguel De Meruelo', value: 'San Miguel De Meruelo' },
  { label: 'San Miguel De Salcedo', value: 'San Miguel De Salcedo' },
  { label: 'San Miguel De Salinas', value: 'San Miguel De Salinas' },
  { label: 'San Miguel De Sema', value: 'San Miguel De Sema' },
  { label: 'San Miguel De Serrezuela', value: 'San Miguel De Serrezuela' },
  { label: 'San Miguel De Tucumán', value: 'San Miguel De Tucumán' },
  { label: 'San Miguel De Valero', value: 'San Miguel De Valero' },
  { label: 'San Miguel Del Arenal', value: 'San Miguel Del Arenal' },
  { label: 'San Miguel Del Arroyo', value: 'San Miguel Del Arroyo' },
  { label: 'San Miguel Del Centro', value: 'San Miguel Del Centro' },
  { label: 'San Miguel Del Cinca', value: 'San Miguel Del Cinca' },
  { label: 'San Miguel Del Milagro', value: 'San Miguel Del Milagro' },
  { label: 'San Miguel Del Padrón', value: 'San Miguel Del Padrón' },
  { label: 'San Miguel Del Pino', value: 'San Miguel Del Pino' },
  { label: 'San Miguel Del Progreso', value: 'San Miguel Del Progreso' },
  { label: 'San Miguel Del Puerto', value: 'San Miguel Del Puerto' },
  { label: 'San Miguel Del Robledo', value: 'San Miguel Del Robledo' },
  { label: 'San Miguel Del Valle', value: 'San Miguel Del Valle' },
  { label: 'San Miguel Del Valle', value: 'San Miguel Del Valle' },
  { label: 'San Miguel Dueñas', value: 'San Miguel Dueñas' },
  { label: 'San Miguel Ejutla', value: 'San Miguel Ejutla' },
  { label: 'San Miguel El Alto', value: 'San Miguel El Alto' },
  { label: 'San Miguel El Grande', value: 'San Miguel El Grande' },
  { label: 'San Miguel El Grande', value: 'San Miguel El Grande' },
  { label: 'San Miguel El Grande Villa Juárez', value: 'San Miguel El Grande Villa Juárez' },
  { label: 'San Miguel Eménguaro', value: 'San Miguel Eménguaro' },
  { label: 'San Miguel Enyege', value: 'San Miguel Enyege' },
  { label: 'San Miguel Epejan', value: 'San Miguel Epejan' },
  { label: 'San Miguel Espejo', value: 'San Miguel Espejo' },
  { label: 'San Miguel Figueroa', value: 'San Miguel Figueroa' },
  { label: 'San Miguel Hila', value: 'San Miguel Hila' },
  { label: 'San Miguel Ixitlán', value: 'San Miguel Ixitlán' },
  { label: 'San Miguel Ixtahuacán', value: 'San Miguel Ixtahuacán' },
  { label: 'San Miguel Ixtapan', value: 'San Miguel Ixtapan' },
  { label: 'San Miguel Jaltocan', value: 'San Miguel Jaltocan' },
  { label: 'San Miguel La Sardina', value: 'San Miguel La Sardina' },
  { label: 'San Miguel Laderas', value: 'San Miguel Laderas' },
  { label: 'San Miguel Mecatepec', value: 'San Miguel Mecatepec' },
  { label: 'San Miguel Mimlapan', value: 'San Miguel Mimlapan' },
  { label: 'San Miguel Mixtepec', value: 'San Miguel Mixtepec' },
  { label: 'San Miguel Monteverde', value: 'San Miguel Monteverde' },
  { label: 'San Miguel Ocotenco', value: 'San Miguel Ocotenco' },
  { label: 'San Miguel Octopan', value: 'San Miguel Octopan' },
  { label: 'San Miguel Oxtotilpan', value: 'San Miguel Oxtotilpan' },
  { label: 'San Miguel Panán', value: 'San Miguel Panán' },
  { label: 'San Miguel Panixtlahuaca', value: 'San Miguel Panixtlahuaca' },
  { label: 'San Miguel Papaxtla', value: 'San Miguel Papaxtla' },
  { label: 'San Miguel Peras', value: 'San Miguel Peras' },
  { label: 'San Miguel Quetzaltepec', value: 'San Miguel Quetzaltepec' },
  { label: 'San Miguel Sigüilá', value: 'San Miguel Sigüilá' },
  { label: 'San Miguel Suchixtepec', value: 'San Miguel Suchixtepec' },
  { label: 'San Miguel Tecomatlán', value: 'San Miguel Tecomatlán' },
  { label: 'San Miguel Tecomatlán', value: 'San Miguel Tecomatlán' },
  { label: 'San Miguel Tecpan', value: 'San Miguel Tecpan' },
  { label: 'San Miguel Tecuiciapan', value: 'San Miguel Tecuiciapan' },
  { label: 'San Miguel Tecuitlapa', value: 'San Miguel Tecuitlapa' },
  { label: 'San Miguel Tenango', value: 'San Miguel Tenango' },
  { label: 'San Miguel Tenango', value: 'San Miguel Tenango' },
  { label: 'San Miguel Tenextatiloyan', value: 'San Miguel Tenextatiloyan' },
  { label: 'San Miguel Tenochtitlán', value: 'San Miguel Tenochtitlán' },
  { label: 'San Miguel Tetepelcingo', value: 'San Miguel Tetepelcingo' },
  { label: 'San Miguel Tianguistenco', value: 'San Miguel Tianguistenco' },
  { label: 'San Miguel Tianguizolco', value: 'San Miguel Tianguizolco' },
  { label: 'San Miguel Tilquiapam', value: 'San Miguel Tilquiapam' },
  { label: 'San Miguel Tlacamama', value: 'San Miguel Tlacamama' },
  { label: 'San Miguel Tlacotepec', value: 'San Miguel Tlacotepec' },
  { label: 'San Miguel Tlaixpan', value: 'San Miguel Tlaixpan' },
  { label: 'San Miguel Tlalpoalán', value: 'San Miguel Tlalpoalán' },
  { label: 'San Miguel Tlamahuco', value: 'San Miguel Tlamahuco' },
  { label: 'San Miguel Topilejo', value: 'San Miguel Topilejo' },
  { label: 'San Miguel Totocuitlapilco', value: 'San Miguel Totocuitlapilco' },
  { label: 'San Miguel Totolapan', value: 'San Miguel Totolapan' },
  { label: 'San Miguel Totoltepec', value: 'San Miguel Totoltepec' },
  { label: 'San Miguel Vindho', value: 'San Miguel Vindho' },
  { label: 'San Miguel Xochitecatitla', value: 'San Miguel Xochitecatitla' },
  { label: 'San Miguel Xoltepec', value: 'San Miguel Xoltepec' },
  { label: 'San Miguel Xoxtla', value: 'San Miguel Xoxtla' },
  { label: 'San Miguel Yuxtepec', value: 'San Miguel Yuxtepec' },
  { label: 'San Miguel Zapotitlan', value: 'San Miguel Zapotitlan' },
  { label: 'San Miguel Zapotitlán', value: 'San Miguel Zapotitlán' },
  { label: 'San Miguel Zoapan', value: 'San Miguel Zoapan' },
  { label: 'San Miguel Zozutla', value: 'San Miguel Zozutla' },
  { label: 'San Miguelito', value: 'San Miguelito' },
  { label: 'San Miguelito', value: 'San Miguelito' },
  { label: 'San Miguelito', value: 'San Miguelito' },
  { label: 'San Miguelito', value: 'San Miguelito' },
  { label: 'San Miguelito', value: 'San Miguelito' },
  { label: 'San Miguelito', value: 'San Miguelito' },
  { label: 'San Millán De La Cogolla', value: 'San Millán De La Cogolla' },
  { label: 'San Millán De Lara', value: 'San Millán De Lara' },
  { label: 'San Millán De Los Caballeros', value: 'San Millán De Los Caballeros' },
  { label: 'San Millán De Yécora', value: 'San Millán De Yécora' },
  { label: 'San Millán/Donemiliaga', value: 'San Millán/Donemiliaga' },
  { label: 'San Miniato', value: 'San Miniato' },
  { label: 'San Miniato Basso', value: 'San Miniato Basso' },
  { label: 'San Morales', value: 'San Morales' },
  { label: 'San Muñoz', value: 'San Muñoz' },
  { label: 'San Narciso', value: 'San Narciso' },
  { label: 'San Narciso', value: 'San Narciso' },
  { label: 'San Narciso', value: 'San Narciso' },
  { label: 'San Nazario', value: 'San Nazario' },
  { label: 'San Nazzaro', value: 'San Nazzaro' },
  { label: 'San Nazzaro Sesia', value: 'San Nazzaro Sesia' },
  { label: 'San Nazzaro Val Cavargna', value: 'San Nazzaro Val Cavargna' },
  { label: 'San Niccolò Di Celle', value: 'San Niccolò Di Celle' },
  { label: 'San Nico', value: 'San Nico' },
  { label: 'San Nicola', value: 'San Nicola' },
  { label: 'San Nicola', value: 'San Nicola' },
  { label: 'San Nicola Arcella', value: 'San Nicola Arcella' },
  { label: 'San Nicola Baronia', value: 'San Nicola Baronia' },
  { label: 'San Nicola Da Crissa', value: 'San Nicola Da Crissa' },
  { label: 'San Nicola Dellalto', value: 'San Nicola Dellalto' },
  { label: 'San Nicola La Strada', value: 'San Nicola La Strada' },
  { label: 'San Nicola Manfredi', value: 'San Nicola Manfredi' },
  { label: 'San Nicolas', value: 'San Nicolas' },
  { label: 'San Nicolas', value: 'San Nicolas' },
  { label: 'San Nicolas', value: 'San Nicolas' },
  { label: 'San Nicolas', value: 'San Nicolas' },
  { label: 'San Nicolas', value: 'San Nicolas' },
  { label: 'San Nicolas', value: 'San Nicolas' },
  { label: 'San Nicolas', value: 'San Nicolas' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás', value: 'San Nicolás' },
  { label: 'San Nicolás Amealco', value: 'San Nicolás Amealco' },
  { label: 'San Nicolas Buenos Aires', value: 'San Nicolas Buenos Aires' },
  { label: 'San Nicolás Coatepec', value: 'San Nicolás Coatepec' },
  { label: 'San Nicolás De Bari', value: 'San Nicolás De Bari' },
  { label: 'San Nicolás De La Condesa', value: 'San Nicolás De La Condesa' },
  { label: 'San Nicolás De La Torre', value: 'San Nicolás De La Torre' },
  { label: 'San Nicolás De Las Flores', value: 'San Nicolás De Las Flores' },
  { label: 'San Nicolás De Los Agustinos', value: 'San Nicolás De Los Agustinos' },
  { label: 'San Nicolás De Los Garza', value: 'San Nicolás De Los Garza' },
  { label: 'San Nicolás De Los Ranchos', value: 'San Nicolás De Los Ranchos' },
  { label: 'San Nicolás Del Cármen', value: 'San Nicolás Del Cármen' },
  { label: 'San Nicolás Del Puerto', value: 'San Nicolás Del Puerto' },
  { label: 'San Nicolás El Chico', value: 'San Nicolás El Chico' },
  { label: 'San Nicolás El Oro', value: 'San Nicolás El Oro' },
  { label: 'San Nicolás Guadalupe', value: 'San Nicolás Guadalupe' },
  { label: 'San Nicolás Obispo', value: 'San Nicolás Obispo' },
  { label: 'San Nicolás Parangueo', value: 'San Nicolás Parangueo' },
  { label: 'San Nicolás Peralta', value: 'San Nicolás Peralta' },
  { label: 'San Nicolás Quialana', value: 'San Nicolás Quialana' },
  { label: 'San Nicolás Solís', value: 'San Nicolás Solís' },
  { label: 'San Nicolás Tecomatlán', value: 'San Nicolás Tecomatlán' },
  { label: 'San Nicolás Temascatío', value: 'San Nicolás Temascatío' },
  { label: 'San Nicolás Tlazala', value: 'San Nicolás Tlazala' },
  { label: 'San Nicolás Tolentino', value: 'San Nicolás Tolentino' },
  { label: 'San Nicolás Tolentino', value: 'San Nicolás Tolentino' },
  { label: 'San Nicolás Tolentino', value: 'San Nicolás Tolentino' },
  { label: 'San Nicolás Zecalacoayan', value: 'San Nicolás Zecalacoayan' },
  { label: 'San Nicolás Zoyapetlayoca', value: 'San Nicolás Zoyapetlayoca' },
  { label: 'San Nicolò', value: 'San Nicolò' },
  { label: 'San Nicolò', value: 'San Nicolò' },
  { label: 'San Nicolò', value: 'San Nicolò' },
  { label: 'San Nicolò A Tordino', value: 'San Nicolò A Tordino' },
  { label: 'San Nicolò Comelico', value: 'San Nicolò Comelico' },
  { label: 'San Nicolò Darcidano', value: 'San Nicolò Darcidano' },
  { label: 'San Nicologerrei', value: 'San Nicologerrei' },
  { label: 'San Onofre', value: 'San Onofre' },
  { label: 'San Pa Tong', value: 'San Pa Tong' },
  { label: 'San Pablito', value: 'San Pablito' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo', value: 'San Pablo' },
  { label: 'San Pablo Ahuatempa', value: 'San Pablo Ahuatempa' },
  { label: 'San Pablo Anicano', value: 'San Pablo Anicano' },
  { label: 'San Pablo Atlazalpan', value: 'San Pablo Atlazalpan' },
  { label: 'San Pablo Atotonilco', value: 'San Pablo Atotonilco' },
  { label: 'San Pablo Atzompa', value: 'San Pablo Atzompa' },
  { label: 'San Pablo Autopan', value: 'San Pablo Autopan' },
  { label: 'San Pablo Coatlán', value: 'San Pablo Coatlán' },
  { label: 'San Pablo Cuatro Venados', value: 'San Pablo Cuatro Venados' },
  { label: 'San Pablo De Borbur', value: 'San Pablo De Borbur' },
  { label: 'San Pablo De La Moraleja', value: 'San Pablo De La Moraleja' },
  { label: 'San Pablo De Las Salinas', value: 'San Pablo De Las Salinas' },
  { label: 'San Pablo De Las Tunas', value: 'San Pablo De Las Tunas' },
  { label: 'San Pablo De Los Gallos', value: 'San Pablo De Los Gallos' },
  { label: 'San Pablo De Los Montes', value: 'San Pablo De Los Montes' },
  { label: 'San Pablo De Los Remedios', value: 'San Pablo De Los Remedios' },
  { label: 'San Pablo El Grande', value: 'San Pablo El Grande' },
  { label: 'San Pablo Etla', value: 'San Pablo Etla' },
  { label: 'San Pablo Güilá', value: 'San Pablo Güilá' },
  { label: 'San Pablo Huacano', value: 'San Pablo Huacano' },
  { label: 'San Pablo Huantepec', value: 'San Pablo Huantepec' },
  { label: 'San Pablo Huitzo', value: 'San Pablo Huitzo' },
  { label: 'San Pablo Huixtepec', value: 'San Pablo Huixtepec' },
  { label: 'San Pablo Ixayoc', value: 'San Pablo Ixayoc' },
  { label: 'San Pablo Ixquitlán', value: 'San Pablo Ixquitlán' },
  { label: 'San Pablo Jocopilas', value: 'San Pablo Jocopilas' },
  { label: 'San Pablo La Laguna', value: 'San Pablo La Laguna' },
  { label: 'San Pablo Macuiltianguis', value: 'San Pablo Macuiltianguis' },
  { label: 'San Pablo Nuevo Abajo', value: 'San Pablo Nuevo Abajo' },
  { label: 'San Pablo Oztotepec', value: 'San Pablo Oztotepec' },
  { label: 'San Pablo Pejo', value: 'San Pablo Pejo' },
  { label: 'San Pablo Pixtún', value: 'San Pablo Pixtún' },
  { label: 'San Pablo Tacachico', value: 'San Pablo Tacachico' },
  { label: 'San Pablo Tecalco', value: 'San Pablo Tecalco' },
  { label: 'San Pablo Tejalpa', value: 'San Pablo Tejalpa' },
  { label: 'San Pablo Tolimán', value: 'San Pablo Tolimán' },
  { label: 'San Pablo Viejo Abajo', value: 'San Pablo Viejo Abajo' },
  { label: 'San Pablo Villa De Mitla', value: 'San Pablo Villa De Mitla' },
  { label: 'San Pancrazio', value: 'San Pancrazio' },
  { label: 'San Pancrazio', value: 'San Pancrazio' },
  { label: 'San Pancrazio Salentino', value: 'San Pancrazio Salentino' },
  { label: 'San Panfilo Docre', value: 'San Panfilo Docre' },
  { label: 'San Paolo', value: 'San Paolo' },
  { label: 'San Paolo', value: 'San Paolo' },
  { label: 'San Paolo', value: 'San Paolo' },
  { label: 'San Paolo Albanese', value: 'San Paolo Albanese' },
  { label: 'San Paolo Bel Sito', value: 'San Paolo Bel Sito' },
  { label: 'San Paolo Dargon', value: 'San Paolo Dargon' },
  { label: 'San Paolo Di Civitate', value: 'San Paolo Di Civitate' },
  { label: 'San Paolo Di Jesi', value: 'San Paolo Di Jesi' },
  { label: 'San Paolo Solbrito', value: 'San Paolo Solbrito' },
  { label: 'San Pascual', value: 'San Pascual' },
  { label: 'San Pascual', value: 'San Pascual' },
  { label: 'San Pascual', value: 'San Pascual' },
  { label: 'San Pascual', value: 'San Pascual' },
  { label: 'San Pascual', value: 'San Pascual' },
  { label: 'San Pascual', value: 'San Pascual' },
  { label: 'San Pasqual', value: 'San Pasqual' },
  { label: 'San Patricio', value: 'San Patricio' },
  { label: 'San Patricio', value: 'San Patricio' },
  { label: 'San Patricio', value: 'San Patricio' },
  { label: 'San Patricio County', value: 'San Patricio County' },
  { label: 'San Patricio Jalisco', value: 'San Patricio Jalisco' },
  { label: 'San Pawl Il-Baħar', value: 'San Pawl Il-Baħar' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro', value: 'San Pedro' },
  { label: 'San Pedro Abajo', value: 'San Pedro Abajo' },
  { label: 'San Pedro Amuzgos', value: 'San Pedro Amuzgos' },
  { label: 'San Pedro Apartado', value: 'San Pedro Apartado' },
  { label: 'San Pedro Apartado', value: 'San Pedro Apartado' },
  { label: 'San Pedro Apatlaco', value: 'San Pedro Apatlaco' },
  { label: 'San Pedro Apóstol', value: 'San Pedro Apóstol' },
  { label: 'San Pedro Arriba', value: 'San Pedro Arriba' },
  { label: 'San Pedro Atlapulco', value: 'San Pedro Atlapulco' },
  { label: 'San Pedro Atmatla', value: 'San Pedro Atmatla' },
  { label: 'San Pedro Atocpan', value: 'San Pedro Atocpan' },
  { label: 'San Pedro Atoyac', value: 'San Pedro Atoyac' },
  { label: 'San Pedro Ayampuc', value: 'San Pedro Ayampuc' },
  { label: 'San Pedro Benito Juárez', value: 'San Pedro Benito Juárez' },
  { label: 'San Pedro Bercianos', value: 'San Pedro Bercianos' },
  { label: 'San Pedro Buenavista', value: 'San Pedro Buenavista' },
  { label: 'San Pedro Cacahuatepec', value: 'San Pedro Cacahuatepec' },
  { label: 'San Pedro Carchá', value: 'San Pedro Carchá' },
  { label: 'San Pedro Chiautzingo', value: 'San Pedro Chiautzingo' },
  { label: 'San Pedro Chimay', value: 'San Pedro Chimay' },
  { label: 'San Pedro Chochula', value: 'San Pedro Chochula' },
  { label: 'San Pedro Cholula', value: 'San Pedro Cholula' },
  { label: 'San Pedro Cholula', value: 'San Pedro Cholula' },
  { label: 'San Pedro Comitancillo', value: 'San Pedro Comitancillo' },
  { label: 'San Pedro Coxcaltepec Cántaros', value: 'San Pedro Coxcaltepec Cántaros' },
  { label: 'San Pedro Coyutla', value: 'San Pedro Coyutla' },
  { label: 'San Pedro Cuayuca', value: 'San Pedro Cuayuca' },
  { label: 'San Pedro Cuitlapan', value: 'San Pedro Cuitlapan' },
  { label: 'San Pedro De Atacama', value: 'San Pedro De Atacama' },
  { label: 'San Pedro De Cajas', value: 'San Pedro De Cajas' },
  { label: 'San Pedro De Cartago', value: 'San Pedro De Cartago' },
  { label: 'San Pedro De Ceque', value: 'San Pedro De Ceque' },
  { label: 'San Pedro De Copán', value: 'San Pedro De Copán' },
  { label: 'San Pedro De Gaíllos', value: 'San Pedro De Gaíllos' },
  { label: 'San Pedro De Ibarra', value: 'San Pedro De Ibarra' },
  { label: 'San Pedro De Jujuy', value: 'San Pedro De Jujuy' },
  { label: 'San Pedro De La Cueva', value: 'San Pedro De La Cueva' },
  { label: 'San Pedro De La Hortaliza (Ejido Almoloyán)', value: 'San Pedro De La Hortaliza (Ejido Almoloyán)' },
  { label: 'San Pedro De La Nave-Almendra', value: 'San Pedro De La Nave-Almendra' },
  { label: 'San Pedro De La Paz', value: 'San Pedro De La Paz' },
  { label: 'San Pedro De Latarce', value: 'San Pedro De Latarce' },
  { label: 'San Pedro De Lloc', value: 'San Pedro De Lloc' },
  { label: 'San Pedro De Los Baños', value: 'San Pedro De Los Baños' },
  { label: 'San Pedro De Los Metates', value: 'San Pedro De Los Metates' },
  { label: 'San Pedro De Los Milagros', value: 'San Pedro De Los Milagros' },
  { label: 'San Pedro De Los Naranjos', value: 'San Pedro De Los Naranjos' },
  { label: 'San Pedro De Los Sauces', value: 'San Pedro De Los Sauces' },
  { label: 'San Pedro De Macorís', value: 'San Pedro De Macorís' },
  { label: 'San Pedro De Mérida', value: 'San Pedro De Mérida' },
  { label: 'San Pedro De Rozados', value: 'San Pedro De Rozados' },
  { label: 'San Pedro De Tutule', value: 'San Pedro De Tutule' },
  { label: 'San Pedro De Uraba', value: 'San Pedro De Uraba' },
  { label: 'San Pedro De Ycuamandiyú', value: 'San Pedro De Ycuamandiyú' },
  { label: 'San Pedro Del Arroyo', value: 'San Pedro Del Arroyo' },
  { label: 'San Pedro Del Espino', value: 'San Pedro Del Espino' },
  { label: 'San Pedro Del Gallo', value: 'San Pedro Del Gallo' },
  { label: 'San Pedro Del Paraná', value: 'San Pedro Del Paraná' },
  { label: 'San Pedro Del Pinatar', value: 'San Pedro Del Pinatar' },
  { label: 'San Pedro Del Rincón', value: 'San Pedro Del Rincón' },
  { label: 'San Pedro Del Romeral', value: 'San Pedro Del Romeral' },
  { label: 'San Pedro Del Rosal', value: 'San Pedro Del Rosal' },
  { label: 'San Pedro Del Valle', value: 'San Pedro Del Valle' },
  { label: 'San Pedro Denxhi Centro', value: 'San Pedro Denxhi Centro' },
  { label: 'San Pedro Ecatepec', value: 'San Pedro Ecatepec' },
  { label: 'San Pedro El Alto', value: 'San Pedro El Alto' },
  { label: 'San Pedro El Alto', value: 'San Pedro El Alto' },
  { label: 'San Pedro Garza Garcia', value: 'San Pedro Garza Garcia' },
  { label: 'San Pedro Guasave', value: 'San Pedro Guasave' },
  { label: 'San Pedro Huamelula', value: 'San Pedro Huamelula' },
  { label: 'San Pedro Huaquilpan', value: 'San Pedro Huaquilpan' },
  { label: 'San Pedro Huilotepec', value: 'San Pedro Huilotepec' },
  { label: 'San Pedro Huitzapula Norte (Huitzapula Norte)', value: 'San Pedro Huitzapula Norte (Huitzapula Norte)' },
  { label: 'San Pedro Huitzizilapan', value: 'San Pedro Huitzizilapan' },
  { label: 'San Pedro Itzicán', value: 'San Pedro Itzicán' },
  { label: 'San Pedro Itztla', value: 'San Pedro Itztla' },
  { label: 'San Pedro Ixcatlán', value: 'San Pedro Ixcatlán' },
  { label: 'San Pedro Ixtlahuaca', value: 'San Pedro Ixtlahuaca' },
  { label: 'San Pedro Jácuaro', value: 'San Pedro Jácuaro' },
  { label: 'San Pedro Jicayán', value: 'San Pedro Jicayán' },
  { label: 'San Pédro Jocopilas', value: 'San Pédro Jocopilas' },
  { label: 'San Pedro Juchaltengo', value: 'San Pedro Juchaltengo' },
  { label: 'San Pedro La Joya', value: 'San Pedro La Joya' },
  { label: 'San Pedro La Laguna', value: 'San Pedro La Laguna' },
  { label: 'San Pedro Lagunillas', value: 'San Pedro Lagunillas' },
  { label: 'San Pedro Las Playas', value: 'San Pedro Las Playas' },
  { label: 'San Pedro Limón', value: 'San Pedro Limón' },
  { label: 'San Pedro Los Baños', value: 'San Pedro Los Baños' },
  { label: 'San Pedro Manrique', value: 'San Pedro Manrique' },
  { label: 'San Pedro Martir', value: 'San Pedro Martir' },
  { label: 'San Pedro Mártir', value: 'San Pedro Mártir' },
  { label: 'San Pedro Mártir', value: 'San Pedro Mártir' },
  { label: 'San Pedro Mártir Quiechapa', value: 'San Pedro Mártir Quiechapa' },
  { label: 'San Pedro Mártir Yucuxaco', value: 'San Pedro Mártir Yucuxaco' },
  { label: 'San Pedro Masahuat', value: 'San Pedro Masahuat' },
  { label: 'San Pedro Matamoros', value: 'San Pedro Matamoros' },
  { label: 'San Pedro Mixtepec', value: 'San Pedro Mixtepec' },
  { label: 'San Pedro Muñoztla', value: 'San Pedro Muñoztla' },
  { label: 'San Pedro Necta', value: 'San Pedro Necta' },
  { label: 'San Pedro Nexapa', value: 'San Pedro Nexapa' },
  { label: 'San Pedro Nichtalucum', value: 'San Pedro Nichtalucum' },
  { label: 'San Pedro Ocopetatillo', value: 'San Pedro Ocopetatillo' },
  { label: 'San Pedro Ocotepec', value: 'San Pedro Ocotepec' },
  { label: 'San Pedro One', value: 'San Pedro One' },
  { label: 'San Pedro Ozumacín', value: 'San Pedro Ozumacín' },
  { label: 'San Pedro Palmiches', value: 'San Pedro Palmiches' },
  { label: 'San Pedro Petlacotla', value: 'San Pedro Petlacotla' },
  { label: 'San Pedro Piedra Gorda', value: 'San Pedro Piedra Gorda' },
  { label: 'San Pedro Pinula', value: 'San Pedro Pinula' },
  { label: 'San Pedro Pochutla', value: 'San Pedro Pochutla' },
  { label: 'San Pedro Quiatoni', value: 'San Pedro Quiatoni' },
  { label: 'San Pedro Río Mayo', value: 'San Pedro Río Mayo' },
  { label: 'San Pedro Sacatepéquez', value: 'San Pedro Sacatepéquez' },
  { label: 'San Pedro Sacatepéquez', value: 'San Pedro Sacatepéquez' },
  { label: 'San Pedro Sochiápam', value: 'San Pedro Sochiápam' },
  { label: 'San Pedro Sula', value: 'San Pedro Sula' },
  { label: 'San Pedro Tapanatepec', value: 'San Pedro Tapanatepec' },
  { label: 'San Pedro Tarímbaro', value: 'San Pedro Tarímbaro' },
  { label: 'San Pedro Taviche', value: 'San Pedro Taviche' },
  { label: 'San Pedro Techuchulco', value: 'San Pedro Techuchulco' },
  { label: 'San Pedro Tejalpa', value: 'San Pedro Tejalpa' },
  { label: 'San Pedro Temamatla', value: 'San Pedro Temamatla' },
  { label: 'San Pedro Tenango', value: 'San Pedro Tenango' },
  { label: 'San Pedro Tenayac', value: 'San Pedro Tenayac' },
  { label: 'San Pedro Tepetitlán', value: 'San Pedro Tepetitlán' },
  { label: 'San Pedro Tetitlán', value: 'San Pedro Tetitlán' },
  { label: 'San Pedro Teutila', value: 'San Pedro Teutila' },
  { label: 'San Pedro Tidaá', value: 'San Pedro Tidaá' },
  { label: 'San Pedro Tlachichilco', value: 'San Pedro Tlachichilco' },
  { label: 'San Pedro Tlacotepec', value: 'San Pedro Tlacotepec' },
  { label: 'San Pedro Tlaltizapan', value: 'San Pedro Tlaltizapan' },
  { label: 'San Pedro Tlanixco', value: 'San Pedro Tlanixco' },
  { label: 'San Pedro Tlaolantongo', value: 'San Pedro Tlaolantongo' },
  { label: 'San Pedro Tlapacoyan', value: 'San Pedro Tlapacoyan' },
  { label: 'San Pedro Tlaquilpan', value: 'San Pedro Tlaquilpan' },
  { label: 'San Pedro Topiltepec', value: 'San Pedro Topiltepec' },
  { label: 'San Pedro Totolápam', value: 'San Pedro Totolápam' },
  { label: 'San Pedro Totoltepec', value: 'San Pedro Totoltepec' },
  { label: 'San Pedro Tulixtlahuaca', value: 'San Pedro Tulixtlahuaca' },
  { label: 'San Pedro Tultepec', value: 'San Pedro Tultepec' },
  { label: 'San Pedro Xalcaltzinco', value: 'San Pedro Xalcaltzinco' },
  { label: 'San Pedro Xalpa', value: 'San Pedro Xalpa' },
  { label: 'San Pedro Xochiteotla', value: 'San Pedro Xochiteotla' },
  { label: 'San Pedro Y San Pablo Teposcolula', value: 'San Pedro Y San Pablo Teposcolula' },
  { label: 'San Pedro Y San Pablo Tequistepec', value: 'San Pedro Y San Pablo Tequistepec' },
  { label: 'San Pedro Yancuitlalpan', value: 'San Pedro Yancuitlalpan' },
  { label: 'San Pedro Yeloixtlahuaca', value: 'San Pedro Yeloixtlahuaca' },
  { label: 'San Pedro Yólox', value: 'San Pedro Yólox' },
  { label: 'San Pedro Zacachimalpa', value: 'San Pedro Zacachimalpa' },
  { label: 'San Pedro Zacapa', value: 'San Pedro Zacapa' },
  { label: 'San Pedro Zictepec', value: 'San Pedro Zictepec' },
  { label: 'San Pelayo', value: 'San Pelayo' },
  { label: 'San Pelayo', value: 'San Pelayo' },
  { label: 'San Pelayo De Guareña', value: 'San Pelayo De Guareña' },
  { label: 'San Pellegrino Terme', value: 'San Pellegrino Terme' },
  { label: 'San Pier Disonzo', value: 'San Pier Disonzo' },
  { label: 'San Pier Niceto', value: 'San Pier Niceto' },
  { label: 'San Pierino', value: 'San Pierino' },
  { label: 'San Piero A Sieve', value: 'San Piero A Sieve' },
  { label: 'San Piero In Bagno', value: 'San Piero In Bagno' },
  { label: 'San Piero Patti', value: 'San Piero Patti' },
  { label: 'San Pietro', value: 'San Pietro' },
  { label: 'San Pietro', value: 'San Pietro' },
  { label: 'San Pietro', value: 'San Pietro' },
  { label: 'San Pietro A Maida', value: 'San Pietro A Maida' },
  { label: 'San Pietro A Patierno', value: 'San Pietro A Patierno' },
  { label: 'San Pietro Al Natisone', value: 'San Pietro Al Natisone' },
  { label: 'San Pietro Al Tanagro', value: 'San Pietro Al Tanagro' },
  { label: 'San Pietro Apostolo', value: 'San Pietro Apostolo' },
  { label: 'San Pietro Avellana', value: 'San Pietro Avellana' },
  { label: 'San Pietro Belvedere', value: 'San Pietro Belvedere' },
  { label: 'San Pietro Capofiume', value: 'San Pietro Capofiume' },
  { label: 'San Pietro Clarenza', value: 'San Pietro Clarenza' },
  { label: 'San Pietro Di Cadore', value: 'San Pietro Di Cadore' },
  { label: 'San Pietro Di Caridà', value: 'San Pietro Di Caridà' },
  { label: 'San Pietro Di Feletto', value: 'San Pietro Di Feletto' },
  { label: 'San Pietro Di Morubio', value: 'San Pietro Di Morubio' },
  { label: 'San Pietro Dolba', value: 'San Pietro Dolba' },
  { label: 'San Pietro In Amantea', value: 'San Pietro In Amantea' },
  { label: 'San Pietro In Cariano', value: 'San Pietro In Cariano' },
  { label: 'San Pietro In Casale', value: 'San Pietro In Casale' },
  { label: 'San Pietro In Cerro', value: 'San Pietro In Cerro' },
  { label: 'San Pietro In Gu', value: 'San Pietro In Gu' },
  { label: 'San Pietro In Guarano', value: 'San Pietro In Guarano' },
  { label: 'San Pietro In Lama', value: 'San Pietro In Lama' },
  { label: 'San Pietro In Vincoli', value: 'San Pietro In Vincoli' },
  { label: 'San Pietro In Volta', value: 'San Pietro In Volta' },
  { label: 'San Pietro Infine', value: 'San Pietro Infine' },
  { label: 'San Pietro Mosezzo', value: 'San Pietro Mosezzo' },
  { label: 'San Pietro Mussolino', value: 'San Pietro Mussolino' },
  { label: 'San Pietro Val Lemina', value: 'San Pietro Val Lemina' },
  { label: 'San Pietro Valdastico', value: 'San Pietro Valdastico' },
  { label: 'San Pietro Vernotico', value: 'San Pietro Vernotico' },
  { label: 'San Pietro Viminario', value: 'San Pietro Viminario' },
  { label: 'San Pio Delle Camere', value: 'San Pio Delle Camere' },
  { label: 'San Policarpio', value: 'San Policarpio' },
  { label: 'San Policarpio', value: 'San Policarpio' },
  { label: 'San Policarpo', value: 'San Policarpo' },
  { label: 'San Policarpo', value: 'San Policarpo' },
  { label: 'San Polo', value: 'San Polo' },
  { label: 'San Polo Dei Cavalieri', value: 'San Polo Dei Cavalieri' },
  { label: 'San Polo Denza', value: 'San Polo Denza' },
  { label: 'San Polo Di Piave', value: 'San Polo Di Piave' },
  { label: 'San Polomatese', value: 'San Polomatese' },
  { label: 'San Ponso', value: 'San Ponso' },
  { label: 'San Possidonio', value: 'San Possidonio' },
  { label: 'San Potito Sannitico', value: 'San Potito Sannitico' },
  { label: 'San Potito Ultra', value: 'San Potito Ultra' },
  { label: 'San Prisco', value: 'San Prisco' },
  { label: 'San Procopio', value: 'San Procopio' },
  { label: 'San Prospero', value: 'San Prospero' },
  { label: 'San Quintin', value: 'San Quintin' },
  { label: 'San Quintin', value: 'San Quintin' },
  { label: 'San Quintin', value: 'San Quintin' },
  { label: 'San Quintín', value: 'San Quintín' },
  { label: 'San Quintín', value: 'San Quintín' },
  { label: 'San Quirico', value: 'San Quirico' },
  { label: 'San Quirico Dorcia', value: 'San Quirico Dorcia' },
  { label: 'San Quirino', value: 'San Quirino' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael', value: 'San Rafael' },
  { label: 'San Rafael Abajo', value: 'San Rafael Abajo' },
  { label: 'San Rafael Arriba', value: 'San Rafael Arriba' },
  { label: 'San Rafael Calería', value: 'San Rafael Calería' },
  { label: 'San Rafael Del Norte', value: 'San Rafael Del Norte' },
  { label: 'San Rafael Del Río', value: 'San Rafael Del Río' },
  { label: 'San Rafael Del Sur', value: 'San Rafael Del Sur' },
  { label: 'San Rafael Del Yuma', value: 'San Rafael Del Yuma' },
  { label: 'San Rafael Ixtapalucan', value: 'San Rafael Ixtapalucan' },
  { label: 'San Rafael La Independencia', value: 'San Rafael La Independencia' },
  { label: 'San Rafael Las Flores', value: 'San Rafael Las Flores' },
  { label: 'San Rafael Oriente', value: 'San Rafael Oriente' },
  { label: 'San Rafael Petzal', value: 'San Rafael Petzal' },
  { label: 'San Rafael Pie De La Cuesta', value: 'San Rafael Pie De La Cuesta' },
  { label: 'San Rafael Río Seco', value: 'San Rafael Río Seco' },
  { label: 'San Rafael Tecario', value: 'San Rafael Tecario' },
  { label: 'San Rafael Tenanyecac', value: 'San Rafael Tenanyecac' },
  { label: 'San Rafael Tepatlaxco', value: 'San Rafael Tepatlaxco' },
  { label: 'San Rafael Tlanalapan', value: 'San Rafael Tlanalapan' },
  { label: 'San Rafael Zaragoza', value: 'San Rafael Zaragoza' },
  { label: 'San Raffaele Cimena', value: 'San Raffaele Cimena' },
  { label: 'San Raimundo', value: 'San Raimundo' },
  { label: 'San Ramon', value: 'San Ramon' },
  { label: 'San Ramon', value: 'San Ramon' },
  { label: 'San Ramon', value: 'San Ramon' },
  { label: 'San Ramon', value: 'San Ramon' },
  { label: 'San Ramón', value: 'San Ramón' },
  { label: 'San Ramón', value: 'San Ramón' },
  { label: 'San Ramón', value: 'San Ramón' },
  { label: 'San Ramón', value: 'San Ramón' },
  { label: 'San Ramón', value: 'San Ramón' },
  { label: 'San Ramón', value: 'San Ramón' },
  { label: 'San Ramón De La Nueva Orán', value: 'San Ramón De La Nueva Orán' },
  { label: 'San Raymundo Jalpam', value: 'San Raymundo Jalpam' },
  { label: 'San Remigio', value: 'San Remigio' },
  { label: 'San Remigio', value: 'San Remigio' },
  { label: 'San Remigio', value: 'San Remigio' },
  { label: 'San Remigio', value: 'San Remigio' },
  { label: 'San Remigio', value: 'San Remigio' },
  { label: 'San Remo', value: 'San Remo' },
  { label: 'San Remo', value: 'San Remo' },
  { label: 'San Remo', value: 'San Remo' },
  { label: 'San Ricardo', value: 'San Ricardo' },
  { label: 'San Ricardo', value: 'San Ricardo' },
  { label: 'San Ricardo', value: 'San Ricardo' },
  { label: 'San Ricardo', value: 'San Ricardo' },
  { label: 'San Roberto', value: 'San Roberto' },
  { label: 'San Rocco', value: 'San Rocco' },
  { label: 'San Rocco', value: 'San Rocco' },
  { label: 'San Rocco', value: 'San Rocco' },
  { label: 'San Rocco', value: 'San Rocco' },
  { label: 'San Rocco Al Porto', value: 'San Rocco Al Porto' },
  { label: 'San Roman', value: 'San Roman' },
  { label: 'San Román De Cameros', value: 'San Román De Cameros' },
  { label: 'San Román De Hornija', value: 'San Román De Hornija' },
  { label: 'San Román De La Cuba', value: 'San Román De La Cuba' },
  { label: 'San Román De Los Montes', value: 'San Román De Los Montes' },
  { label: 'San Romano', value: 'San Romano' },
  { label: 'San Romano In Garfagnana', value: 'San Romano In Garfagnana' },
  { label: 'San Roque', value: 'San Roque' },
  { label: 'San Roque', value: 'San Roque' },
  { label: 'San Roque', value: 'San Roque' },
  { label: 'San Roque', value: 'San Roque' },
  { label: 'San Roque', value: 'San Roque' },
  { label: 'San Roque', value: 'San Roque' },
  { label: 'San Roque', value: 'San Roque' },
  { label: 'San Roque', value: 'San Roque' },
  { label: 'San Roque', value: 'San Roque' },
  { label: 'San Roque', value: 'San Roque' },
  { label: 'San Roque', value: 'San Roque' },
  { label: 'San Roque Dau First', value: 'San Roque Dau First' },
  { label: 'San Roque Dau First', value: 'San Roque Dau First' },
  { label: 'San Roque De Montes', value: 'San Roque De Montes' },
  { label: 'San Roque De Riomiera', value: 'San Roque De Riomiera' },
  { label: 'San Roque De Torres', value: 'San Roque De Torres' },
  { label: 'San Rosendo', value: 'San Rosendo' },
  { label: 'San Rufo', value: 'San Rufo' },
  { label: 'San Saba', value: 'San Saba' },
  { label: 'San Saba County', value: 'San Saba County' },
  { label: 'San Sadurniño', value: 'San Sadurniño' },
  { label: 'San Salvador', value: 'San Salvador' },
  { label: 'San Salvador', value: 'San Salvador' },
  { label: 'San Salvador', value: 'San Salvador' },
  { label: 'San Salvador', value: 'San Salvador' },
  { label: 'San Salvador', value: 'San Salvador' },
  { label: 'San Salvador (Naranjillo)', value: 'San Salvador (Naranjillo)' },
  { label: 'San Salvador Atenco', value: 'San Salvador Atenco' },
  { label: 'San Salvador Cuauhtenco', value: 'San Salvador Cuauhtenco' },
  { label: 'San Salvador De Jujuy', value: 'San Salvador De Jujuy' },
  { label: 'San Salvador El Seco', value: 'San Salvador El Seco' },
  { label: 'San Salvador El Verde', value: 'San Salvador El Verde' },
  { label: 'San Salvador Tizatlalli', value: 'San Salvador Tizatlalli' },
  { label: 'San Salvador Torrecillas', value: 'San Salvador Torrecillas' },
  { label: 'San Salvatore', value: 'San Salvatore' },
  { label: 'San Salvatore Di Fitalia', value: 'San Salvatore Di Fitalia' },
  { label: 'San Salvatore Monferrato', value: 'San Salvatore Monferrato' },
  { label: 'San Salvatore Telesino', value: 'San Salvatore Telesino' },
  { label: 'San Salvo', value: 'San Salvo' },
  { label: 'San Sebastian', value: 'San Sebastian' },
  { label: 'San Sebastian', value: 'San Sebastian' },
  { label: 'San Sebastian', value: 'San Sebastian' },
  { label: 'San Sebastian', value: 'San Sebastian' },
  { label: 'San Sebastian', value: 'San Sebastian' },
  { label: 'San Sebastian', value: 'San Sebastian' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián', value: 'San Sebastián' },
  { label: 'San Sebastián [Fraccionamiento]', value: 'San Sebastián [Fraccionamiento]' },
  { label: 'San Sebastián Abasolo', value: 'San Sebastián Abasolo' },
  { label: 'San Sebastián Alcomunga', value: 'San Sebastián Alcomunga' },
  { label: 'San Sebastián Buenos Aires', value: 'San Sebastián Buenos Aires' },
  { label: 'San Sebastián Chimalpa', value: 'San Sebastián Chimalpa' },
  { label: 'San Sebastián Coatán', value: 'San Sebastián Coatán' },
  { label: 'San Sebastián Coatlán', value: 'San Sebastián Coatlán' },
  { label: 'San Sebastián De Aparicio', value: 'San Sebastián De Aparicio' },
  { label: 'San Sebastián De Buenavista', value: 'San Sebastián De Buenavista' },
  { label: 'San Sebastián De La Gomera', value: 'San Sebastián De La Gomera' },
  { label: 'San Sebastián De La Gomera', value: 'San Sebastián De La Gomera' },
  { label: 'San Sebastián De Las Barrancas Sur', value: 'San Sebastián De Las Barrancas Sur' },
  { label: 'San Sebastián De Los Ballesteros', value: 'San Sebastián De Los Ballesteros' },
  { label: 'San Sebastián De Los Reyes', value: 'San Sebastián De Los Reyes' },
  { label: 'San Sebastián De Mariquita', value: 'San Sebastián De Mariquita' },
  { label: 'San Sebastián De Salitre', value: 'San Sebastián De Salitre' },
  { label: 'San Sebastián De Yalí', value: 'San Sebastián De Yalí' },
  { label: 'San Sebastián Del Monte', value: 'San Sebastián Del Monte' },
  { label: 'San Sebastián Del Oeste', value: 'San Sebastián Del Oeste' },
  { label: 'San Sebastián Etla', value: 'San Sebastián Etla' },
  { label: 'San Sebastián Huehuetenango', value: 'San Sebastián Huehuetenango' },
  { label: 'San Sebastián Ixcapa', value: 'San Sebastián Ixcapa' },
  { label: 'San Sebastián Nicananduta', value: 'San Sebastián Nicananduta' },
  { label: 'San Sebastián Nopalera', value: 'San Sebastián Nopalera' },
  { label: 'San Sebastián Río Hondo', value: 'San Sebastián Río Hondo' },
  { label: 'San Sebastián Tecomaxtlahuaca', value: 'San Sebastián Tecomaxtlahuaca' },
  { label: 'San Sebastian Teitipac', value: 'San Sebastian Teitipac' },
  { label: 'San Sebastián Tenochtitlán', value: 'San Sebastián Tenochtitlán' },
  { label: 'San Sebastián Teteles', value: 'San Sebastián Teteles' },
  { label: 'San Sebastián Tlacotepec', value: 'San Sebastián Tlacotepec' },
  { label: 'San Sebastián Tutla', value: 'San Sebastián Tutla' },
  { label: 'San Sebastián Villanueva', value: 'San Sebastián Villanueva' },
  { label: 'San Sebastián Xolalpa', value: 'San Sebastián Xolalpa' },
  { label: 'San Sebastiano', value: 'San Sebastiano' },
  { label: 'San Sebastiano Al Vesuvio', value: 'San Sebastiano Al Vesuvio' },
  { label: 'San Sebastiano Curone', value: 'San Sebastiano Curone' },
  { label: 'San Sebastiano Da Po', value: 'San Sebastiano Da Po' },
  { label: 'San Secondo', value: 'San Secondo' },
  { label: 'San Secondo Di Pinerolo', value: 'San Secondo Di Pinerolo' },
  { label: 'San Secondo Parmense', value: 'San Secondo Parmense' },
  { label: 'San Severino Lucano', value: 'San Severino Lucano' },
  { label: 'San Severino Marche', value: 'San Severino Marche' },
  { label: 'San Severo', value: 'San Severo' },
  { label: 'San Silvestre De Guzmán', value: 'San Silvestre De Guzmán' },
  { label: 'San Simeón Xipetzingo', value: 'San Simeón Xipetzingo' },
  { label: 'San Simon', value: 'San Simon' },
  { label: 'San Simon', value: 'San Simon' },
  { label: 'San Simón', value: 'San Simón' },
  { label: 'San Simón', value: 'San Simón' },
  { label: 'San Simón Almolongas', value: 'San Simón Almolongas' },
  { label: 'San Simón Atzitzintla', value: 'San Simón Atzitzintla' },
  { label: 'San Simón De Guerrero', value: 'San Simón De Guerrero' },
  { label: 'San Simón De La Laguna', value: 'San Simón De La Laguna' },
  { label: 'San Simón El Alto', value: 'San Simón El Alto' },
  { label: 'San Simón Tlatlahuquitepec', value: 'San Simón Tlatlahuquitepec' },
  { label: 'San Simonito', value: 'San Simonito' },
  { label: 'San Siro', value: 'San Siro' },
  { label: 'San Sossio Baronia', value: 'San Sossio Baronia' },
  { label: 'San Sostene', value: 'San Sostene' },
  { label: 'San Sosti', value: 'San Sosti' },
  { label: 'San Soucis', value: 'San Soucis' },
  { label: 'San Sperate', value: 'San Sperate' },
  { label: 'San Stino Di Livenza', value: 'San Stino Di Livenza' },
  { label: 'San Tadeo', value: 'San Tadeo' },
  { label: 'San Tammaro', value: 'San Tammaro' },
  { label: 'San Tan Valley', value: 'San Tan Valley' },
  { label: 'San Teodoro', value: 'San Teodoro' },
  { label: 'San Teodoro', value: 'San Teodoro' },
  { label: 'San Teodoro', value: 'San Teodoro' },
  { label: 'San Terenziano', value: 'San Terenziano' },
  { label: 'San Terenzo', value: 'San Terenzo' },
  { label: 'San Tirso De Abres', value: 'San Tirso De Abres' },
  { label: 'San Tommaso Tre Archi', value: 'San Tommaso Tre Archi' },
  { label: 'San Torcuato', value: 'San Torcuato' },
  { label: 'San Valentino', value: 'San Valentino' },
  { label: 'San Valentino In Abruzzo Citeriore', value: 'San Valentino In Abruzzo Citeriore' },
  { label: 'San Valentino Torio', value: 'San Valentino Torio' },
  { label: 'San Venanzio', value: 'San Venanzio' },
  { label: 'San Venanzo', value: 'San Venanzo' },
  { label: 'San Vendemiano', value: 'San Vendemiano' },
  { label: 'San Vendemiano-Fossamerlo', value: 'San Vendemiano-Fossamerlo' },
  { label: 'San Vero Milis', value: 'San Vero Milis' },
  { label: 'San Vicent Del Raspeig', value: 'San Vicent Del Raspeig' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente', value: 'San Vicente' },
  { label: 'San Vicente (Calpulalpan Tercera Manzana)', value: 'San Vicente (Calpulalpan Tercera Manzana)' },
  { label: 'San Vicente Boquerón', value: 'San Vicente Boquerón' },
  { label: 'San Vicente Centenario', value: 'San Vicente Centenario' },
  { label: 'San Vicente Chimalhuacán', value: 'San Vicente Chimalhuacán' },
  { label: 'San Vicente Coatlán', value: 'San Vicente Coatlán' },
  { label: 'San Vicente Coyotepec', value: 'San Vicente Coyotepec' },
  { label: 'San Vicente De Alcántara', value: 'San Vicente De Alcántara' },
  { label: 'San Vicente De Arévalo', value: 'San Vicente De Arévalo' },
  { label: 'San Vicente De Bique', value: 'San Vicente De Bique' },
  { label: 'San Vicente De Cañete', value: 'San Vicente De Cañete' },
  { label: 'San Vicente De Chucurí', value: 'San Vicente De Chucurí' },
  { label: 'San Vicente De Flores', value: 'San Vicente De Flores' },
  { label: 'San Vicente De Juárez', value: 'San Vicente De Juárez' },
  { label: 'San Vicente De La Barquera', value: 'San Vicente De La Barquera' },
  { label: 'San Vicente De La Cabeza', value: 'San Vicente De La Cabeza' },
  { label: 'San Vicente De La Sonsierra', value: 'San Vicente De La Sonsierra' },
  { label: 'San Vicente De Moravia', value: 'San Vicente De Moravia' },
  { label: 'San Vicente Del Caguán', value: 'San Vicente Del Caguán' },
  { label: 'San Vicente Del Palacio', value: 'San Vicente Del Palacio' },
  { label: 'San Vicente Del Raspeig/Sant Vicent Del Raspeig', value: 'San Vicente Del Raspeig/Sant Vicent Del Raspeig' },
  { label: 'San Vicente Del Valle', value: 'San Vicente Del Valle' },
  { label: 'San Vicente El Alto', value: 'San Vicente El Alto' },
  { label: 'San Vicente Ferrer', value: 'San Vicente Ferrer' },
  { label: 'San Vicente Ferrer', value: 'San Vicente Ferrer' },
  { label: 'San Vicente La Mesilla', value: 'San Vicente La Mesilla' },
  { label: 'San Vicente Lachixío', value: 'San Vicente Lachixío' },
  { label: 'San Vicente Nuñu', value: 'San Vicente Nuñu' },
  { label: 'San Vicente Pacaya', value: 'San Vicente Pacaya' },
  { label: 'San Vicente Palapa', value: 'San Vicente Palapa' },
  { label: 'San Vicente Piñas', value: 'San Vicente Piñas' },
  { label: 'San Vicente Tancuayalab', value: 'San Vicente Tancuayalab' },
  { label: 'San Vicente Zoyatlán', value: 'San Vicente Zoyatlán' },
  { label: 'San Víctor Arriba', value: 'San Víctor Arriba' },
  { label: 'San Vigilio', value: 'San Vigilio' },
  { label: 'San Vigilio', value: 'San Vigilio' },
  { label: 'San Vincente', value: 'San Vincente' },
  { label: 'San Vincente', value: 'San Vincente' },
  { label: 'San Vincenzo', value: 'San Vincenzo' },
  { label: 'San Vincenzo', value: 'San Vincenzo' },
  { label: 'San Vincenzo La Costa', value: 'San Vincenzo La Costa' },
  { label: 'San Vincenzo Valle Roveto', value: 'San Vincenzo Valle Roveto' },
  { label: 'San Vitaliano', value: 'San Vitaliano' },
  { label: 'San Vitero', value: 'San Vitero' },
  { label: 'San Vito', value: 'San Vito' },
  { label: 'San Vito', value: 'San Vito' },
  { label: 'San Vito', value: 'San Vito' },
  { label: 'San Vito', value: 'San Vito' },
  { label: 'San Vito Al Mantico', value: 'San Vito Al Mantico' },
  { label: 'San Vito Al Tagliamento', value: 'San Vito Al Tagliamento' },
  { label: 'San Vito Al Torre', value: 'San Vito Al Torre' },
  { label: 'San Vito Chietino', value: 'San Vito Chietino' },
  { label: 'San Vito Dei Normanni', value: 'San Vito Dei Normanni' },
  { label: 'San Vito Di Fagagna', value: 'San Vito Di Fagagna' },
  { label: 'San Vito Di Leguzzano', value: 'San Vito Di Leguzzano' },
  { label: 'San Vito Lo Capo', value: 'San Vito Lo Capo' },
  { label: 'San Vito Romano', value: 'San Vito Romano' },
  { label: 'San Vito Sullo Ionio', value: 'San Vito Sullo Ionio' },
  { label: 'San Vito-Cerreto', value: 'San Vito-Cerreto' },
  { label: 'San Vittore', value: 'San Vittore' },
  { label: 'San Vittore Del Lazio', value: 'San Vittore Del Lazio' },
  { label: 'San Vittore Olona', value: 'San Vittore Olona' },
  { label: 'San Vittoria In Matenano', value: 'San Vittoria In Matenano' },
  { label: 'San Xoán De Río', value: 'San Xoán De Río' },
  { label: 'San Ysidro', value: 'San Ysidro' },
  { label: 'San Zeno', value: 'San Zeno' },
  { label: 'San Zeno Di Montagna', value: 'San Zeno Di Montagna' },
  { label: 'San Zeno Naviglio', value: 'San Zeno Naviglio' },
  { label: 'San Zenón', value: 'San Zenón' },
  { label: 'San Zenone', value: 'San Zenone' },
  { label: 'San Zenone Al Lambro', value: 'San Zenone Al Lambro' },
  { label: 'San Zenone Al Po', value: 'San Zenone Al Po' },
  { label: 'San Zenone Degli Ezzelini', value: 'San Zenone Degli Ezzelini' },
  { label: 'San Zeno-San Giuseppe', value: 'San Zeno-San Giuseppe' },
  { label: 'Saña', value: 'Saña' },
  { label: 'Sanaa', value: 'Sanaa' },
  { label: 'Sanad', value: 'Sanad' },
  { label: 'Sanada', value: 'Sanada' },
  { label: 'Sanahcat', value: 'Sanahcat' },
  { label: 'Sanaj', value: 'Sanaj' },
  { label: 'Sanalona', value: 'Sanalona' },
  { label: 'Sanand', value: 'Sanand' },
  { label: 'Sanandaj', value: 'Sanandaj' },
  { label: 'Sânandrei', value: 'Sânandrei' },
  { label: 'Sananduva', value: 'Sananduva' },
  { label: 'Sanarate', value: 'Sanarate' },
  { label: 'Sanarica', value: 'Sanarica' },
  { label: 'Sanary-Sur-Mer', value: 'Sanary-Sur-Mer' },
  { label: 'Sanata Lucía', value: 'Sanata Lucía' },
  { label: 'Sanatoga', value: 'Sanatoga' },
  { label: 'Sanaüja', value: 'Sanaüja' },
  { label: 'Sanaur', value: 'Sanaur' },
  { label: 'Sanawad', value: 'Sanawad' },
  { label: 'Sanborn', value: 'Sanborn' },
  { label: 'Sanborn', value: 'Sanborn' },
  { label: 'Sanborn County', value: 'Sanborn County' },
  { label: 'Sanbornton', value: 'Sanbornton' },
  { label: 'Sanbornville', value: 'Sanbornville' },
  { label: 'Sancaktepe', value: 'Sancaktepe' },
  { label: 'Sancé', value: 'Sancé' },
  { label: 'Sancedo', value: 'Sancedo' },
  { label: 'Sâncel', value: 'Sâncel' },
  { label: 'Sancerre', value: 'Sancerre' },
  { label: 'Sancey-Le-Grand', value: 'Sancey-Le-Grand' },
  { label: 'Sanchahe', value: 'Sanchahe' },
  { label: 'Sanchazi', value: 'Sanchazi' },
  { label: 'Sancheong-Gun', value: 'Sancheong-Gun' },
  { label: 'Sánchez', value: 'Sánchez' },
  { label: 'Sánchez', value: 'Sánchez' },
  { label: 'Sanchez Carrion', value: 'Sanchez Carrion' },
  { label: 'Sanchez Mira', value: 'Sanchez Mira' },
  { label: 'Sanchi', value: 'Sanchi' },
  { label: 'Sanchidrián', value: 'Sanchidrián' },
  { label: 'Sanchón De La Ribera', value: 'Sanchón De La Ribera' },
  { label: 'Sanchón De La Sagrada', value: 'Sanchón De La Sagrada' },
  { label: 'Sanchonuño', value: 'Sanchonuño' },
  { label: 'Sanchor', value: 'Sanchor' },
  { label: 'Sanchorreja', value: 'Sanchorreja' },
  { label: 'Sanchotello', value: 'Sanchotello' },
  { label: 'Sanchursk', value: 'Sanchursk' },
  { label: 'Sanchurskiy Rayon', value: 'Sanchurskiy Rayon' },
  { label: 'Sanclerlândia', value: 'Sanclerlândia' },
  { label: 'Sancoale', value: 'Sancoale' },
  { label: 'Sancoins', value: 'Sancoins' },
  { label: 'Sâncrai', value: 'Sâncrai' },
  { label: 'Sâncrai', value: 'Sâncrai' },
  { label: 'Sâncrăieni', value: 'Sâncrăieni' },
  { label: 'Sâncraiu', value: 'Sâncraiu' },
  { label: 'Sâncraiu De Mureș', value: 'Sâncraiu De Mureș' },
  { label: 'Sancti Spíritus', value: 'Sancti Spíritus' },
  { label: 'Sancti-Spíritus', value: 'Sancti-Spíritus' },
  { label: 'Sanctorum', value: 'Sanctorum' },
  { label: 'Sanctórum', value: 'Sanctórum' },
  { label: 'Sanctuary Point', value: 'Sanctuary Point' },
  { label: 'Sand', value: 'Sand' },
  { label: 'Sand', value: 'Sand' },
  { label: 'Sand', value: 'Sand' },
  { label: 'Sand De Feu', value: 'Sand De Feu' },
  { label: 'Sand Hill', value: 'Sand Hill' },
  { label: 'Sand Lake', value: 'Sand Lake' },
  { label: 'Sand Point', value: 'Sand Point' },
  { label: 'Sand Springs', value: 'Sand Springs' },
  { label: 'Sanda Shi', value: 'Sanda Shi' },
  { label: 'Sandachō', value: 'Sandachō' },
  { label: 'Sandakan', value: 'Sandakan' },
  { label: 'Sandalfoot Cove', value: 'Sandalfoot Cove' },
  { label: 'Sandane', value: 'Sandane' },
  { label: 'Sandanski', value: 'Sandanski' },
  { label: 'Sandared', value: 'Sandared' },
  { label: 'Sandarne', value: 'Sandarne' },
  { label: 'Sandata', value: 'Sandata' },
  { label: 'Sandau', value: 'Sandau' },
  { label: 'Sandayong Sur', value: 'Sandayong Sur' },
  { label: 'Sandbach', value: 'Sandbach' },
  { label: 'Sandbank', value: 'Sandbank' },
  { label: 'Sandberg', value: 'Sandberg' },
  { label: 'Sande', value: 'Sande' },
  { label: 'Sande', value: 'Sande' },
  { label: 'Sande', value: 'Sande' },
  { label: 'Sande', value: 'Sande' },
  { label: 'Sande', value: 'Sande' },
  { label: 'Sandefjord', value: 'Sandefjord' },
  { label: 'Sanders County', value: 'Sanders County' },
  { label: 'Sandersdorf', value: 'Sandersdorf' },
  { label: 'Sandersleben', value: 'Sandersleben' },
  { label: 'Sanderson', value: 'Sanderson' },
  { label: 'Sandersville', value: 'Sandersville' },
  { label: 'Sandesneben', value: 'Sandesneben' },
  { label: 'Sandford', value: 'Sandford' },
  { label: 'Sandford', value: 'Sandford' },
  { label: 'Sandgate', value: 'Sandgate' },
  { label: 'Sandgerði', value: 'Sandgerði' },
  { label: 'Sandhausen', value: 'Sandhausen' },
  { label: 'Sandhurst', value: 'Sandhurst' },
  { label: 'Sandi', value: 'Sandi' },
  { label: 'Sandia Heights', value: 'Sandia Heights' },
  { label: 'Sandia Knolls', value: 'Sandia Knolls' },
  { label: 'Sandiás', value: 'Sandiás' },
  { label: 'Sandiat Centro', value: 'Sandiat Centro' },
  { label: 'Sandiat Centro', value: 'Sandiat Centro' },
  { label: 'Sandigliano', value: 'Sandigliano' },
  { label: 'Sandıklı', value: 'Sandıklı' },
  { label: 'Sandila', value: 'Sandila' },
  { label: 'Sandillon', value: 'Sandillon' },
  { label: 'Sandim', value: 'Sandim' },
  { label: 'Sandnes', value: 'Sandnes' },
  { label: 'Sandnessjøen', value: 'Sandnessjøen' },
  { label: 'Sando', value: 'Sando' },
  { label: 'Sandolândia', value: 'Sandolândia' },
  { label: 'Sandolot', value: 'Sandolot' },
  { label: 'Sandomierz', value: 'Sandomierz' },
  { label: 'Sandomil', value: 'Sandomil' },
  { label: 'Sândominic', value: 'Sândominic' },
  { label: 'Sandoná', value: 'Sandoná' },
  { label: 'Sándorfalva', value: 'Sándorfalva' },
  { label: 'Sandoval', value: 'Sandoval' },
  { label: 'Sandoval County', value: 'Sandoval County' },
  { label: 'Sandovalina', value: 'Sandovalina' },
  { label: 'Sandovo', value: 'Sandovo' },
  { label: 'Sandovskiy Rayon', value: 'Sandovskiy Rayon' },
  { label: 'Sandown', value: 'Sandown' },
  { label: 'Sandown', value: 'Sandown' },
  { label: 'Sandøy', value: 'Sandøy' },
  { label: 'Sandpoint', value: 'Sandpoint' },
  { label: 'Sandrà', value: 'Sandrà' },
  { label: 'Şandra', value: 'Şandra' },
  { label: 'Sandrigo', value: 'Sandrigo' },
  { label: 'Sandringham', value: 'Sandringham' },
  { label: 'Sandringham', value: 'Sandringham' },
  { label: 'Šandrovac', value: 'Šandrovac' },
  { label: 'Șandru', value: 'Șandru' },
  { label: 'Sands Point', value: 'Sands Point' },
  { label: 'Sandside', value: 'Sandside' },
  { label: 'Sandsli', value: 'Sandsli' },
  { label: 'Sandstedt', value: 'Sandstedt' },
  { label: 'Sandston', value: 'Sandston' },
  { label: 'Sandstone', value: 'Sandstone' },
  { label: 'Sandstone', value: 'Sandstone' },
  { label: 'Sandstone Point', value: 'Sandstone Point' },
  { label: 'Sandu', value: 'Sandu' },
  { label: 'Sănduleni', value: 'Sănduleni' },
  { label: 'Sănduleşti', value: 'Sănduleşti' },
  { label: 'Sandur', value: 'Sandur' },
  { label: 'Sandur', value: 'Sandur' },
  { label: 'Sandusky', value: 'Sandusky' },
  { label: 'Sandusky', value: 'Sandusky' },
  { label: 'Sandusky County', value: 'Sandusky County' },
  { label: 'Sandviken', value: 'Sandviken' },
  { label: 'Sandweiler', value: 'Sandweiler' },
  { label: 'Sandwell', value: 'Sandwell' },
  { label: 'Sandwich', value: 'Sandwich' },
  { label: 'Sandwich', value: 'Sandwich' },
  { label: 'Sandwich', value: 'Sandwich' },
  { label: 'Sandwich', value: 'Sandwich' },
  { label: 'Sandwick', value: 'Sandwick' },
  { label: 'Sandy', value: 'Sandy' },
  { label: 'Sandy', value: 'Sandy' },
  { label: 'Sandy', value: 'Sandy' },
  { label: 'Sandy', value: 'Sandy' },
  { label: 'Sandy Bay', value: 'Sandy Bay' },
  { label: 'Sandy Bay', value: 'Sandy Bay' },
  { label: 'Sandy Bay', value: 'Sandy Bay' },
  { label: 'Sandy Bay', value: 'Sandy Bay' },
  { label: 'Sandy Beach', value: 'Sandy Beach' },
  { label: 'Sandy Hills', value: 'Sandy Hills' },
  { label: 'Sandy Hook', value: 'Sandy Hook' },
  { label: 'Sandy Point Town', value: 'Sandy Point Town' },
  { label: 'Sandy Springs', value: 'Sandy Springs' },
  { label: 'Sandy Valley', value: 'Sandy Valley' },
  { label: 'Sandyford', value: 'Sandyford' },
  { label: 'Sandymount', value: 'Sandymount' },
  { label: 'Sanem', value: 'Sanem' },
  { label: 'Sanet Y Negrals', value: 'Sanet Y Negrals' },
  { label: 'Sanfins', value: 'Sanfins' },
  { label: 'Sanford', value: 'Sanford' },
  { label: 'Sanford', value: 'Sanford' },
  { label: 'Sanford', value: 'Sanford' },
  { label: 'Sanfrè', value: 'Sanfrè' },
  { label: 'Sanfront', value: 'Sanfront' },
  { label: 'Sangalhos', value: 'Sangalhos' },
  { label: 'Sangali', value: 'Sangali' },
  { label: 'Sangameshwar', value: 'Sangameshwar' },
  { label: 'Sangamner', value: 'Sangamner' },
  { label: 'Sangamon County', value: 'Sangamon County' },
  { label: 'Sangan', value: 'Sangan' },
  { label: 'Sangano', value: 'Sangano' },
  { label: 'Sangão', value: 'Sangão' },
  { label: 'Sangar', value: 'Sangar' },
  { label: 'Sangar', value: 'Sangar' },
  { label: 'Sangarcía', value: 'Sangarcía' },
  { label: 'Sangareddi', value: 'Sangareddi' },
  { label: 'Sangaree', value: 'Sangaree' },
  { label: 'Sangaria', value: 'Sangaria' },
  { label: 'Sangarrén', value: 'Sangarrén' },
  { label: 'Sanga-Sanga', value: 'Sanga-Sanga' },
  { label: 'Sanga-Sanga', value: 'Sanga-Sanga' },
  { label: 'Sangat', value: 'Sangat' },
  { label: 'Sangatte', value: 'Sangatte' },
  { label: 'Sangay', value: 'Sangay' },
  { label: 'Sañgay', value: 'Sañgay' },
  { label: 'Sangdevin', value: 'Sangdevin' },
  { label: 'Sang-E Chārak', value: 'Sang-E Chārak' },
  { label: 'Sângeorgiu De Câmpie', value: 'Sângeorgiu De Câmpie' },
  { label: 'Sângeorgiu De Mureș', value: 'Sângeorgiu De Mureș' },
  { label: 'Sângeorgiu De Pădure', value: 'Sângeorgiu De Pădure' },
  { label: 'Sângeorz-Băi', value: 'Sângeorz-Băi' },
  { label: 'Sanger', value: 'Sanger' },
  { label: 'Sanger', value: 'Sanger' },
  { label: 'Sânger', value: 'Sânger' },
  { label: 'Sangerhausen', value: 'Sangerhausen' },
  { label: 'Sângeru', value: 'Sângeru' },
  { label: 'Sângeru De Pădure', value: 'Sângeru De Pădure' },
  { label: 'Sangerville', value: 'Sangerville' },
  { label: 'Sanggau', value: 'Sanggau' },
  { label: 'Sanghan', value: 'Sanghan' },
  { label: 'Sanghan', value: 'Sanghan' },
  { label: 'Sanghar', value: 'Sanghar' },
  { label: 'Sangiano', value: 'Sangiano' },
  { label: 'Sangīn', value: 'Sangīn' },
  { label: 'Sangineto', value: 'Sangineto' },
  { label: 'Sangju', value: 'Sangju' },
  { label: 'Sangkari', value: 'Sangkari' },
  { label: 'Sangkhla Buri', value: 'Sangkhla Buri' },
  { label: 'Sangkhom', value: 'Sangkhom' },
  { label: 'Sangkom Thmei', value: 'Sangkom Thmei' },
  { label: 'Sangla Hill', value: 'Sangla Hill' },
  { label: 'Sangli', value: 'Sangli' },
  { label: 'Sangmélima', value: 'Sangmélima' },
  { label: 'Sangmu', value: 'Sangmu' },
  { label: 'Sangod', value: 'Sangod' },
  { label: 'Sangola', value: 'Sangola' },
  { label: 'Sangolquí', value: 'Sangolquí' },
  { label: 'Sangonera La Verde', value: 'Sangonera La Verde' },
  { label: 'Sangrampur Maharashtra', value: 'Sangrampur Maharashtra' },
  { label: 'Sangre Grande', value: 'Sangre Grande' },
  { label: 'Sangrur', value: 'Sangrur' },
  { label: 'Sangsŏng-Ni', value: 'Sangsŏng-Ni' },
  { label: 'Sanguem', value: 'Sanguem' },
  { label: 'Sangüesa/Zangoza', value: 'Sangüesa/Zangoza' },
  { label: 'Sanguéya', value: 'Sanguéya' },
  { label: 'Sanguinet', value: 'Sanguinet' },
  { label: 'Sanguinetti', value: 'Sanguinetti' },
  { label: 'Sanguinetto', value: 'Sanguinetto' },
  { label: 'Sangzi', value: 'Sangzi' },
  { label: 'Sanhan', value: 'Sanhan' },
  { label: 'Sanharó', value: 'Sanharó' },
  { label: 'Sâniacob', value: 'Sâniacob' },
  { label: 'Sanibel', value: 'Sanibel' },
  { label: 'Sanica', value: 'Sanica' },
  { label: 'Sanilac County', value: 'Sanilac County' },
  { label: 'Sâniob', value: 'Sâniob' },
  { label: 'Sanislău', value: 'Sanislău' },
  { label: 'Sânișor', value: 'Sânișor' },
  { label: 'Sanivarsante', value: 'Sanivarsante' },
  { label: 'Sanjō Shi', value: 'Sanjō Shi' },
  { label: 'Sanjuzan', value: 'Sanjuzan' },
  { label: 'Sanjwal', value: 'Sanjwal' },
  { label: 'Sanka', value: 'Sanka' },
  { label: 'Sankanan', value: 'Sankanan' },
  { label: 'Sankarpur', value: 'Sankarpur' },
  { label: 'Sankelmark', value: 'Sankelmark' },
  { label: 'Sankeshwar', value: 'Sankeshwar' },
  { label: 'Sankhast', value: 'Sankhast' },
  { label: 'Sankheda', value: 'Sankheda' },
  { label: 'Sankhuwasabha ', value: 'Sankhuwasabha ' },
  { label: 'Sankrail', value: 'Sankrail' },
  { label: 'Sankt Aegidi', value: 'Sankt Aegidi' },
  { label: 'Sankt Aegyd Am Neuwalde', value: 'Sankt Aegyd Am Neuwalde' },
  { label: 'Sankt Andrä', value: 'Sankt Andrä' },
  { label: 'Sankt Andrä Am Zicksee', value: 'Sankt Andrä Am Zicksee' },
  { label: 'Sankt Andrä Im Lungau', value: 'Sankt Andrä Im Lungau' },
  { label: 'Sankt Andrä Vor Dem Hagenthale', value: 'Sankt Andrä Vor Dem Hagenthale' },
  { label: 'Sankt Andreasberg', value: 'Sankt Andreasberg' },
  { label: 'Sankt Anna Am Aigen', value: 'Sankt Anna Am Aigen' },
  { label: 'Sankt Anton Im Montafon', value: 'Sankt Anton Im Montafon' },
  { label: 'Sankt Antoni', value: 'Sankt Antoni' },
  { label: 'Sankt Augustin', value: 'Sankt Augustin' },
  { label: 'Sankt Bartholomä', value: 'Sankt Bartholomä' },
  { label: 'Sankt Blasen', value: 'Sankt Blasen' },
  { label: 'Sankt Blasien', value: 'Sankt Blasien' },
  { label: 'Sankt Corona Am Wechsel', value: 'Sankt Corona Am Wechsel' },
  { label: 'Sankt Dionysen', value: 'Sankt Dionysen' },
  { label: 'Sankt Egidien', value: 'Sankt Egidien' },
  { label: 'Sankt Egyden An Der Drau', value: 'Sankt Egyden An Der Drau' },
  { label: 'Sankt Englmar', value: 'Sankt Englmar' },
  { label: 'Sankt Florian', value: 'Sankt Florian' },
  { label: 'Sankt Gallen', value: 'Sankt Gallen' },
  { label: 'Sankt Gallen', value: 'Sankt Gallen' },
  { label: 'Sankt Gallenkappel', value: 'Sankt Gallenkappel' },
  { label: 'Sankt Gallenkirch', value: 'Sankt Gallenkirch' },
  { label: 'Sankt Gangloff', value: 'Sankt Gangloff' },
  { label: 'Sankt Georgen Am Fillmannsbach', value: 'Sankt Georgen Am Fillmannsbach' },
  { label: 'Sankt Georgen Am Längsee', value: 'Sankt Georgen Am Längsee' },
  { label: 'Sankt Georgen Am Leithagebirge', value: 'Sankt Georgen Am Leithagebirge' },
  { label: 'Sankt Georgen Am Ybbsfelde', value: 'Sankt Georgen Am Ybbsfelde' },
  { label: 'Sankt Georgen An Der Gusen', value: 'Sankt Georgen An Der Gusen' },
  { label: 'Sankt Georgen An Der Leys', value: 'Sankt Georgen An Der Leys' },
  { label: 'Sankt Georgen An Der Stiefing', value: 'Sankt Georgen An Der Stiefing' },
  { label: 'Sankt Georgen Bei Obernberg Am Inn', value: 'Sankt Georgen Bei Obernberg Am Inn' },
  { label: 'Sankt Georgen Im Attergau', value: 'Sankt Georgen Im Attergau' },
  { label: 'Sankt Georgen Im Lavanttal', value: 'Sankt Georgen Im Lavanttal' },
  { label: 'Sankt Georgen Im Schwarzwald', value: 'Sankt Georgen Im Schwarzwald' },
  { label: 'Sankt Georgen Ob Judenburg', value: 'Sankt Georgen Ob Judenburg' },
  { label: 'Sankt Georgen Ob Murau', value: 'Sankt Georgen Ob Murau' },
  { label: 'Sankt Gilgen', value: 'Sankt Gilgen' },
  { label: 'Sankt Goar', value: 'Sankt Goar' },
  { label: 'Sankt Goarshausen', value: 'Sankt Goarshausen' },
  { label: 'Sankt Gotthard Im Mühlkreis', value: 'Sankt Gotthard Im Mühlkreis' },
  { label: 'Sankt Ilgen', value: 'Sankt Ilgen' },
  { label: 'Sankt Ingbert', value: 'Sankt Ingbert' },
  { label: 'Sankt Jakob', value: 'Sankt Jakob' },
  { label: 'Sankt Jakob Im Rosental', value: 'Sankt Jakob Im Rosental' },
  { label: 'Sankt Jakob Im Walde', value: 'Sankt Jakob Im Walde' },
  { label: 'Sankt Jakob-Breitenau', value: 'Sankt Jakob-Breitenau' },
  { label: 'Sankt Johann', value: 'Sankt Johann' },
  { label: 'Sankt Johann Am Tauern', value: 'Sankt Johann Am Tauern' },
  { label: 'Sankt Johann Bei Herberstein', value: 'Sankt Johann Bei Herberstein' },
  { label: 'Sankt Johann Im Pongau', value: 'Sankt Johann Im Pongau' },
  { label: 'Sankt Johann Im Saggautal', value: 'Sankt Johann Im Saggautal' },
  { label: 'Sankt Johann In Der Haide', value: 'Sankt Johann In Der Haide' },
  { label: 'Sankt Johann In Tirol', value: 'Sankt Johann In Tirol' },
  { label: 'Sankt Josef (Weststeiermark)', value: 'Sankt Josef (Weststeiermark)' },
  { label: 'Sankt Julian', value: 'Sankt Julian' },
  { label: 'Sankt Kanzian Am Klopeiner See', value: 'Sankt Kanzian Am Klopeiner See' },
  { label: 'Sankt Katharein An Der Laming', value: 'Sankt Katharein An Der Laming' },
  { label: 'Sankt Katharinen', value: 'Sankt Katharinen' },
  { label: 'Sankt Kathrein Am Hauenstein', value: 'Sankt Kathrein Am Hauenstein' },
  { label: 'Sankt Kathrein Am Offenegg', value: 'Sankt Kathrein Am Offenegg' },
  { label: 'Sankt Kilian', value: 'Sankt Kilian' },
  { label: 'Sankt Lambrecht', value: 'Sankt Lambrecht' },
  { label: 'Sankt Leonhard', value: 'Sankt Leonhard' },
  { label: 'Sankt Leonhard', value: 'Sankt Leonhard' },
  { label: 'Sankt Leonhard', value: 'Sankt Leonhard' },
  { label: 'Sankt Leonhard Am Forst', value: 'Sankt Leonhard Am Forst' },
  { label: 'Sankt Leonhard Am Hornerwald', value: 'Sankt Leonhard Am Hornerwald' },
  { label: 'Sankt Leonhard Am Wonneberg', value: 'Sankt Leonhard Am Wonneberg' },
  { label: 'Sankt Leon-Rot', value: 'Sankt Leon-Rot' },
  { label: 'Sankt Lorenz', value: 'Sankt Lorenz' },
  { label: 'Sankt Lorenzen Am Wechsel', value: 'Sankt Lorenzen Am Wechsel' },
  { label: 'Sankt Lorenzen Bei Knittelfeld', value: 'Sankt Lorenzen Bei Knittelfeld' },
  { label: 'Sankt Lorenzen Bei Scheifling', value: 'Sankt Lorenzen Bei Scheifling' },
  { label: 'Sankt Lorenzen Im Mürztal', value: 'Sankt Lorenzen Im Mürztal' },
  { label: 'Sankt Magdalen', value: 'Sankt Magdalen' },
  { label: 'Sankt Marein Bei Graz', value: 'Sankt Marein Bei Graz' },
  { label: 'Sankt Marein Bei Knittelfeld', value: 'Sankt Marein Bei Knittelfeld' },
  { label: 'Sankt Marein Bei Neumarkt', value: 'Sankt Marein Bei Neumarkt' },
  { label: 'Sankt Marein Im Mürztal', value: 'Sankt Marein Im Mürztal' },
  { label: 'Sankt Margareten Im Rosental', value: 'Sankt Margareten Im Rosental' },
  { label: 'Sankt Margarethen', value: 'Sankt Margarethen' },
  { label: 'Sankt Margarethen An Der Raab', value: 'Sankt Margarethen An Der Raab' },
  { label: 'Sankt Margarethen An Der Sierning', value: 'Sankt Margarethen An Der Sierning' },
  { label: 'Sankt Margarethen Bei Knittelfeld', value: 'Sankt Margarethen Bei Knittelfeld' },
  { label: 'Sankt Margarethen Im Burgenland', value: 'Sankt Margarethen Im Burgenland' },
  { label: 'Sankt Margarethen Im Lungau', value: 'Sankt Margarethen Im Lungau' },
  { label: 'Sankt Märgen', value: 'Sankt Märgen' },
  { label: 'Sankt Margrethen', value: 'Sankt Margrethen' },
  { label: 'Sankt Marien', value: 'Sankt Marien' },
  { label: 'Sankt Martin', value: 'Sankt Martin' },
  { label: 'Sankt Martin', value: 'Sankt Martin' },
  { label: 'Sankt Martin', value: 'Sankt Martin' },
  { label: 'Sankt Martin', value: 'Sankt Martin' },
  { label: 'Sankt Martin Am Grimming', value: 'Sankt Martin Am Grimming' },
  { label: 'Sankt Martin Am Ybbsfelde', value: 'Sankt Martin Am Ybbsfelde' },
  { label: 'Sankt Martin An Der Raab', value: 'Sankt Martin An Der Raab' },
  { label: 'Sankt Martin Bei Lofer', value: 'Sankt Martin Bei Lofer' },
  { label: 'Sankt Martin Im Mühlkreis', value: 'Sankt Martin Im Mühlkreis' },
  { label: 'Sankt Martin Im Sulmtal', value: 'Sankt Martin Im Sulmtal' },
  { label: 'Sankt Michael Im Burgenland', value: 'Sankt Michael Im Burgenland' },
  { label: 'Sankt Michael Im Lungau', value: 'Sankt Michael Im Lungau' },
  { label: 'Sankt Michael In Obersteiermark', value: 'Sankt Michael In Obersteiermark' },
  { label: 'Sankt Michaelisdonn', value: 'Sankt Michaelisdonn' },
  { label: 'Sankt Niklaus', value: 'Sankt Niklaus' },
  { label: 'Sankt Nikolai Im Sausal', value: 'Sankt Nikolai Im Sausal' },
  { label: 'Sankt Nikolai Im Sölktal', value: 'Sankt Nikolai Im Sölktal' },
  { label: 'Sankt Nikolai Ob Draßling', value: 'Sankt Nikolai Ob Draßling' },
  { label: 'Sankt Oswald Bei Freistadt', value: 'Sankt Oswald Bei Freistadt' },
  { label: 'Sankt Oswald Bei Plankenwarth', value: 'Sankt Oswald Bei Plankenwarth' },
  { label: 'Sankt Oswald Ob Eibiswald', value: 'Sankt Oswald Ob Eibiswald' },
  { label: 'Sankt Pantaleon', value: 'Sankt Pantaleon' },
  { label: 'Sankt Paul Im Lavanttal', value: 'Sankt Paul Im Lavanttal' },
  { label: 'Sankt Peter', value: 'Sankt Peter' },
  { label: 'Sankt Peter', value: 'Sankt Peter' },
  { label: 'Sankt Peter', value: 'Sankt Peter' },
  { label: 'Sankt Peter Am Hart', value: 'Sankt Peter Am Hart' },
  { label: 'Sankt Peter Am Kammersberg', value: 'Sankt Peter Am Kammersberg' },
  { label: 'Sankt Peter Am Ottersbach', value: 'Sankt Peter Am Ottersbach' },
  { label: 'Sankt Peter Im Sulmtal', value: 'Sankt Peter Im Sulmtal' },
  { label: 'Sankt Peter In Der Au Markt', value: 'Sankt Peter In Der Au Markt' },
  { label: 'Sankt Peter Ob Judenburg', value: 'Sankt Peter Ob Judenburg' },
  { label: 'Sankt Peter-Freienstein', value: 'Sankt Peter-Freienstein' },
  { label: 'Sankt Peter-Ording', value: 'Sankt Peter-Ording' },
  { label: 'Sankt Peterzell', value: 'Sankt Peterzell' },
  { label: 'Sankt Pölten', value: 'Sankt Pölten' },
  { label: 'Sankt Pölten Stadt', value: 'Sankt Pölten Stadt' },
  { label: 'Sankt Radegund Bei Graz', value: 'Sankt Radegund Bei Graz' },
  { label: 'Sankt Ruprecht', value: 'Sankt Ruprecht' },
  { label: 'Sankt Ruprecht An Der Raab', value: 'Sankt Ruprecht An Der Raab' },
  { label: 'Sankt Sebastian', value: 'Sankt Sebastian' },
  { label: 'Sankt Stefan', value: 'Sankt Stefan' },
  { label: 'Sankt Stefan Im Gailtal', value: 'Sankt Stefan Im Gailtal' },
  { label: 'Sankt Stefan Im Rosental', value: 'Sankt Stefan Im Rosental' },
  { label: 'Sankt Stefan Ob Leoben', value: 'Sankt Stefan Ob Leoben' },
  { label: 'Sankt Stefan Ob Stainz', value: 'Sankt Stefan Ob Stainz' },
  { label: 'Sankt Stephan', value: 'Sankt Stephan' },
  { label: 'Sankt Ulrich Am Pillersee', value: 'Sankt Ulrich Am Pillersee' },
  { label: 'Sankt Ulrich Am Waasen', value: 'Sankt Ulrich Am Waasen' },
  { label: 'Sankt Ulrich Bei Steyr', value: 'Sankt Ulrich Bei Steyr' },
  { label: 'Sankt Urban', value: 'Sankt Urban' },
  { label: 'Sankt Valentin', value: 'Sankt Valentin' },
  { label: 'Sankt Veit Am Vogau', value: 'Sankt Veit Am Vogau' },
  { label: 'Sankt Veit An Der Glan', value: 'Sankt Veit An Der Glan' },
  { label: 'Sankt Veit An Der Glan District', value: 'Sankt Veit An Der Glan District' },
  { label: 'Sankt Veit An Der Gölsen', value: 'Sankt Veit An Der Gölsen' },
  { label: 'Sankt Veit Im Pongau', value: 'Sankt Veit Im Pongau' },
  { label: 'Sankt Veit In Defereggen', value: 'Sankt Veit In Defereggen' },
  { label: 'Sankt Wendel', value: 'Sankt Wendel' },
  { label: 'Sankt Wolfgang', value: 'Sankt Wolfgang' },
  { label: 'Sankt Wolfgang Im Salzkammergut', value: 'Sankt Wolfgang Im Salzkammergut' },
  { label: 'Sankwia', value: 'Sankwia' },
  { label: 'Sanlibo', value: 'Sanlibo' },
  { label: 'Sanlibo', value: 'Sanlibo' },
  { label: 'Sanlúcar De Barrameda', value: 'Sanlúcar De Barrameda' },
  { label: 'Sanlúcar De Guadiana', value: 'Sanlúcar De Guadiana' },
  { label: 'Sanlúcar La Mayor', value: 'Sanlúcar La Mayor' },
  { label: 'Sanluri', value: 'Sanluri' },
  { label: 'Sanmao', value: 'Sanmao' },
  { label: 'Sânmărghita', value: 'Sânmărghita' },
  { label: 'Sânmărghita', value: 'Sânmărghita' },
  { label: 'Sânmartin', value: 'Sânmartin' },
  { label: 'Sânmartin', value: 'Sânmartin' },
  { label: 'Sânmărtin', value: 'Sânmărtin' },
  { label: 'San-Martino-Di-Lota', value: 'San-Martino-Di-Lota' },
  { label: 'Sânmărtinu De Câmpie', value: 'Sânmărtinu De Câmpie' },
  { label: 'Sânmihai De Pădure', value: 'Sânmihai De Pădure' },
  { label: 'Sânmihaiu Almaşului', value: 'Sânmihaiu Almaşului' },
  { label: 'Sânmihaiu De Câmpie', value: 'Sânmihaiu De Câmpie' },
  { label: 'Sânmihaiu Român', value: 'Sânmihaiu Român' },
  { label: 'Sanming', value: 'Sanming' },
  { label: 'Sanmu', value: 'Sanmu' },
  { label: 'Sanmu-Shi', value: 'Sanmu-Shi' },
  { label: 'Sann', value: 'Sann' },
  { label: 'Sannat', value: 'Sannat' },
  { label: 'Sannazzaro De Burgondi', value: 'Sannazzaro De Burgondi' },
  { label: 'Sannerville', value: 'Sannerville' },
  { label: 'Sannicandro Di Bari', value: 'Sannicandro Di Bari' },
  { label: 'Sannicandro Garganico', value: 'Sannicandro Garganico' },
  { label: 'Sânnicoară', value: 'Sânnicoară' },
  { label: 'Sannicola', value: 'Sannicola' },
  { label: 'San-Nicolao', value: 'San-Nicolao' },
  { label: 'Sânnicolau Mare', value: 'Sânnicolau Mare' },
  { label: 'Sânnicolau Român', value: 'Sânnicolau Român' },
  { label: 'Sanniki', value: 'Sanniki' },
  { label: 'Sannikovo', value: 'Sannikovo' },
  { label: 'Sanniquellie', value: 'Sanniquellie' },
  { label: 'Sannois', value: 'Sannois' },
  { label: 'Sano', value: 'Sano' },
  { label: 'Sanok', value: 'Sanok' },
  { label: 'Sano-Shi', value: 'Sano-Shi' },
  { label: 'Šanov', value: 'Šanov' },
  { label: 'Sânpaul', value: 'Sânpaul' },
  { label: 'Sânpaul', value: 'Sânpaul' },
  { label: 'San-Pédro', value: 'San-Pédro' },
  { label: 'Sanpete County', value: 'Sanpete County' },
  { label: 'Sânpetru', value: 'Sânpetru' },
  { label: 'Sânpetru De Câmpie', value: 'Sânpetru De Câmpie' },
  { label: 'Sânpetru German', value: 'Sânpetru German' },
  { label: 'Sânpetru Mare', value: 'Sânpetru Mare' },
  { label: 'Sanqaçal', value: 'Sanqaçal' },
  { label: 'Sanquelim', value: 'Sanquelim' },
  { label: 'Sanquhar', value: 'Sanquhar' },
  { label: 'Sans Souci', value: 'Sans Souci' },
  { label: 'Sans Souci', value: 'Sans Souci' },
  { label: 'Sansac-De-Marmiesse', value: 'Sansac-De-Marmiesse' },
  { label: 'Sansanné-Mango', value: 'Sansanné-Mango' },
  { label: 'Sansare', value: 'Sansare' },
  { label: 'Sansepolcro', value: 'Sansepolcro' },
  { label: 'Sansha', value: 'Sansha' },
  { label: 'Sanshui', value: 'Sanshui' },
  { label: 'Sânsimion', value: 'Sânsimion' },
  { label: 'Sânsimion', value: 'Sânsimion' },
  { label: 'Sanski Most', value: 'Sanski Most' },
  { label: 'Sansol', value: 'Sansol' },
  { label: 'Sansom Park', value: 'Sansom Park' },
  { label: 'Șanț', value: 'Șanț' },
  { label: 'Sant Adrià De Besòs', value: 'Sant Adrià De Besòs' },
  { label: 'Sant Agustí De Lluçanès', value: 'Sant Agustí De Lluçanès' },
  { label: 'Sant Andreu De La Barca', value: 'Sant Andreu De La Barca' },
  { label: 'Sant Andreu De Llavaneres', value: 'Sant Andreu De Llavaneres' },
  { label: 'Sant Andreu De Sureda', value: 'Sant Andreu De Sureda' },
  { label: 'Sant Andreu Salou', value: 'Sant Andreu Salou' },
  { label: 'Sant Aniol De Finestres', value: 'Sant Aniol De Finestres' },
  { label: 'Sant Antoni De Portmany', value: 'Sant Antoni De Portmany' },
  { label: 'Sant Antoni De Vilamajor', value: 'Sant Antoni De Vilamajor' },
  { label: 'Sant Bartomeu Del Grau', value: 'Sant Bartomeu Del Grau' },
  { label: 'Sant Boi De Llobregat', value: 'Sant Boi De Llobregat' },
  { label: 'Sant Boi De Lluçanès', value: 'Sant Boi De Lluçanès' },
  { label: 'Sant Carles De La Ràpita', value: 'Sant Carles De La Ràpita' },
  { label: 'Sant Cebrià De Vallalta', value: 'Sant Cebrià De Vallalta' },
  { label: 'Sant Celoni', value: 'Sant Celoni' },
  { label: 'Sant Climent De Llobregat', value: 'Sant Climent De Llobregat' },
  { label: 'Sant Climent Sescebes', value: 'Sant Climent Sescebes' },
  { label: 'Sant Cugat Del Vallès', value: 'Sant Cugat Del Vallès' },
  { label: 'Sant Cugat Sesgarrigues', value: 'Sant Cugat Sesgarrigues' },
  { label: 'Sant Esteve De La Sarga', value: 'Sant Esteve De La Sarga' },
  { label: 'Sant Esteve De Palautordera', value: 'Sant Esteve De Palautordera' },
  { label: 'Sant Esteve Sesrovires', value: 'Sant Esteve Sesrovires' },
  { label: 'Sant Feliu De Buixalleu', value: 'Sant Feliu De Buixalleu' },
  { label: 'Sant Feliu De Codines', value: 'Sant Feliu De Codines' },
  { label: 'Sant Feliu De Guíxols', value: 'Sant Feliu De Guíxols' },
  { label: 'Sant Feliu De Llobregat', value: 'Sant Feliu De Llobregat' },
  { label: 'Sant Feliu De Pallerols', value: 'Sant Feliu De Pallerols' },
  { label: 'Sant Feliu Sasserra', value: 'Sant Feliu Sasserra' },
  { label: 'Sant Ferriol', value: 'Sant Ferriol' },
  { label: 'Sant Fost De Campsentelles', value: 'Sant Fost De Campsentelles' },
  { label: 'Sant Francesc De Formentera', value: 'Sant Francesc De Formentera' },
  { label: 'Sant Fruitós De Bages', value: 'Sant Fruitós De Bages' },
  { label: 'Sant Gregori', value: 'Sant Gregori' },
  { label: 'Sant Guim De Freixenet', value: 'Sant Guim De Freixenet' },
  { label: 'Sant Guim De La Plana', value: 'Sant Guim De La Plana' },
  { label: 'Sant Hilari Sacalm', value: 'Sant Hilari Sacalm' },
  { label: 'Sant Hipòlit De Voltregà', value: 'Sant Hipòlit De Voltregà' },
  { label: 'Sant Iscle De Vallalta', value: 'Sant Iscle De Vallalta' },
  { label: 'Sant Jaume De Frontanyà', value: 'Sant Jaume De Frontanyà' },
  { label: 'Sant Jaume De Llierca', value: 'Sant Jaume De Llierca' },
  { label: 'Sant Jaume Dels Domenys', value: 'Sant Jaume Dels Domenys' },
  { label: 'Sant Jaume Denveja', value: 'Sant Jaume Denveja' },
  { label: 'Sant Joan', value: 'Sant Joan' },
  { label: 'Sant Joan Dalacant', value: 'Sant Joan Dalacant' },
  { label: 'Sant Joan De Labritja', value: 'Sant Joan De Labritja' },
  { label: 'Sant Joan De Les Abadesses', value: 'Sant Joan De Les Abadesses' },
  { label: 'Sant Joan De Mollet', value: 'Sant Joan De Mollet' },
  { label: 'Sant Joan De Moró', value: 'Sant Joan De Moró' },
  { label: 'Sant Joan De Pladecorts', value: 'Sant Joan De Pladecorts' },
  { label: 'Sant Joan De Vilatorrada', value: 'Sant Joan De Vilatorrada' },
  { label: 'Sant Joan Despí', value: 'Sant Joan Despí' },
  { label: 'Sant Joan Les Fonts', value: 'Sant Joan Les Fonts' },
  { label: 'Sant Joanet', value: 'Sant Joanet' },
  { label: 'Sant Jordi', value: 'Sant Jordi' },
  { label: 'Sant Jordi Desvalls', value: 'Sant Jordi Desvalls' },
  { label: 'Sant Jordi/San Jorge', value: 'Sant Jordi/San Jorge' },
  { label: 'Sant Josep De Sa Talaia', value: 'Sant Josep De Sa Talaia' },
  { label: 'Sant Julià De Cerdanyola', value: 'Sant Julià De Cerdanyola' },
  { label: 'Sant Julià De Lòria', value: 'Sant Julià De Lòria' },
  { label: 'Sant Julià De Ramis', value: 'Sant Julià De Ramis' },
  { label: 'Sant Julià De Vilatorta', value: 'Sant Julià De Vilatorta' },
  { label: 'Sant Julià Del Llor I Bonmatí', value: 'Sant Julià Del Llor I Bonmatí' },
  { label: 'Sant Just Desvern', value: 'Sant Just Desvern' },
  { label: 'Sant Kabir Nagar', value: 'Sant Kabir Nagar' },
  { label: 'Sant Llorenç De Cerdans', value: 'Sant Llorenç De Cerdans' },
  { label: 'Sant Llorenç De La Muga', value: 'Sant Llorenç De La Muga' },
  { label: 'Sant Llorenç De Morunys', value: 'Sant Llorenç De Morunys' },
  { label: 'Sant Llorenç Des Cardassar', value: 'Sant Llorenç Des Cardassar' },
  { label: 'Sant Llorenç Dhortons', value: 'Sant Llorenç Dhortons' },
  { label: 'Sant Llorenç Savall', value: 'Sant Llorenç Savall' },
  { label: 'Sant Lluís', value: 'Sant Lluís' },
  { label: 'Sant Martí Dalbars', value: 'Sant Martí Dalbars' },
  { label: 'Sant Martí De Centelles', value: 'Sant Martí De Centelles' },
  { label: 'Sant Martí De Llémena', value: 'Sant Martí De Llémena' },
  { label: 'Sant Martí De Riucorb', value: 'Sant Martí De Riucorb' },
  { label: 'Sant Martí De Tous', value: 'Sant Martí De Tous' },
  { label: 'Sant Martí Sarroca', value: 'Sant Martí Sarroca' },
  { label: 'Sant Martí Sesgueioles', value: 'Sant Martí Sesgueioles' },
  { label: 'Sant Martí Vell', value: 'Sant Martí Vell' },
  { label: 'Sant Mateu', value: 'Sant Mateu' },
  { label: 'Sant Mateu De Bages', value: 'Sant Mateu De Bages' },
  { label: 'Sant Miquel De Campmajor', value: 'Sant Miquel De Campmajor' },
  { label: 'Sant Miquel De Fluvià', value: 'Sant Miquel De Fluvià' },
  { label: 'Sant Mori', value: 'Sant Mori' },
  { label: 'Sant Pau De Segúries', value: 'Sant Pau De Segúries' },
  { label: 'Sant Pere De Ribes', value: 'Sant Pere De Ribes' },
  { label: 'Sant Pere De Riudebitlles', value: 'Sant Pere De Riudebitlles' },
  { label: 'Sant Pere De Torelló', value: 'Sant Pere De Torelló' },
  { label: 'Sant Pere De Vilamajor', value: 'Sant Pere De Vilamajor' },
  { label: 'Sant Pere Pescador', value: 'Sant Pere Pescador' },
  { label: 'Sant Pere Sallavinera', value: 'Sant Pere Sallavinera' },
  { label: 'Sant Pol De Mar', value: 'Sant Pol De Mar' },
  { label: 'Sant Quintí De Mediona', value: 'Sant Quintí De Mediona' },
  { label: 'Sant Quirze De Besora', value: 'Sant Quirze De Besora' },
  { label: 'Sant Quirze Del Vallès', value: 'Sant Quirze Del Vallès' },
  { label: 'Sant Quirze Safaja', value: 'Sant Quirze Safaja' },
  { label: 'Sant Ramon', value: 'Sant Ramon' },
  { label: 'Sant Ravi Das Nagar', value: 'Sant Ravi Das Nagar' },
  { label: 'Sant Sadurní Danoia', value: 'Sant Sadurní Danoia' },
  { label: 'Sant Sadurní Dosormort', value: 'Sant Sadurní Dosormort' },
  { label: 'Sant Salvador De Guardiola', value: 'Sant Salvador De Guardiola' },
  { label: 'Sant Vicenç De Castellet', value: 'Sant Vicenç De Castellet' },
  { label: 'Sant Vicenç De Montalt', value: 'Sant Vicenç De Montalt' },
  { label: 'Sant Vicenç De Torelló', value: 'Sant Vicenç De Torelló' },
  { label: 'Sant Vicenç Dels Horts', value: 'Sant Vicenç Dels Horts' },
  { label: 'Santa', value: 'Santa' },
  { label: 'Santa', value: 'Santa' },
  { label: 'Santa Adelaida', value: 'Santa Adelaida' },
  { label: 'Santa Adélia', value: 'Santa Adélia' },
  { label: 'Santa Albertina', value: 'Santa Albertina' },
  { label: 'Santa Amalia', value: 'Santa Amalia' },
  { label: 'Santa Amélia', value: 'Santa Amélia' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana', value: 'Santa Ana' },
  { label: 'Santa Ana Acozautla', value: 'Santa Ana Acozautla' },
  { label: 'Santa Ana Ahuehuepan', value: 'Santa Ana Ahuehuepan' },
  { label: 'Santa Ana Amatlán', value: 'Santa Ana Amatlán' },
  { label: 'Santa Ana Arriba', value: 'Santa Ana Arriba' },
  { label: 'Santa Ana Azcapotzaltongo', value: 'Santa Ana Azcapotzaltongo' },
  { label: 'Santa Ana Batha', value: 'Santa Ana Batha' },
  { label: 'Santa Ana Chapitiro', value: 'Santa Ana Chapitiro' },
  { label: 'Santa Ana Chiautempan', value: 'Santa Ana Chiautempan' },
  { label: 'Santa Ana Coapan', value: 'Santa Ana Coapan' },
  { label: 'Santa Ana Coatepec', value: 'Santa Ana Coatepec' },
  { label: 'Santa Ana De Allende', value: 'Santa Ana De Allende' },
  { label: 'Santa Ana De Guerrero (El Cascabel)', value: 'Santa Ana De Guerrero (El Cascabel)' },
  { label: 'Santa Ana De Pusa', value: 'Santa Ana De Pusa' },
  { label: 'Santa Ana De Yacuma', value: 'Santa Ana De Yacuma' },
  { label: 'Santa Ana De Yusguare', value: 'Santa Ana De Yusguare' },
  { label: 'Santa Ana Del Pilar', value: 'Santa Ana Del Pilar' },
  { label: 'Santa Ana Del Valle', value: 'Santa Ana Del Valle' },
  { label: 'Santa Ana Hueytlalpan', value: 'Santa Ana Hueytlalpan' },
  { label: 'Santa Ana Huista', value: 'Santa Ana Huista' },
  { label: 'Santa Ana Ixtlahuaca (Santa Ana Ixtlahuacingo)', value: 'Santa Ana Ixtlahuaca (Santa Ana Ixtlahuacingo)' },
  { label: 'Santa Ana Ixtlahuatzingo (Santa Ana)', value: 'Santa Ana Ixtlahuatzingo (Santa Ana)' },
  { label: 'Santa Ana Jilotzingo', value: 'Santa Ana Jilotzingo' },
  { label: 'Santa Ana Jilotzingo', value: 'Santa Ana Jilotzingo' },
  { label: 'Santa Ana La Ladera', value: 'Santa Ana La Ladera' },
  { label: 'Santa Ana La Real', value: 'Santa Ana La Real' },
  { label: 'Santa Ana Maya', value: 'Santa Ana Maya' },
  { label: 'Santa Ana Mayorazgo', value: 'Santa Ana Mayorazgo' },
  { label: 'Santa Ana Necoxtla', value: 'Santa Ana Necoxtla' },
  { label: 'Santa Ana Nextlalpan', value: 'Santa Ana Nextlalpan' },
  { label: 'Santa Ana Nichi Ejido', value: 'Santa Ana Nichi Ejido' },
  { label: 'Santa Ana Pacueco', value: 'Santa Ana Pacueco' },
  { label: 'Santa Ana Tlachiahualpa', value: 'Santa Ana Tlachiahualpa' },
  { label: 'Santa Ana Tlacotenco', value: 'Santa Ana Tlacotenco' },
  { label: 'Santa Ana Tlapacoyan', value: 'Santa Ana Tlapacoyan' },
  { label: 'Santa Ana Tzacuala', value: 'Santa Ana Tzacuala' },
  { label: 'Santa Ana Xalmimilulco', value: 'Santa Ana Xalmimilulco' },
  { label: 'Santa Ana Zegache', value: 'Santa Ana Zegache' },
  { label: 'Santa Ana Zicatecoyan', value: 'Santa Ana Zicatecoyan' },
  { label: 'Santa Ana Zirosto', value: 'Santa Ana Zirosto' },
  { label: 'Santa Angel', value: 'Santa Angel' },
  { label: 'Santa Anita', value: 'Santa Anita' },
  { label: 'Santa Anita', value: 'Santa Anita' },
  { label: 'Santa Anita', value: 'Santa Anita' },
  { label: 'Santa Anita', value: 'Santa Anita' },
  { label: 'Santa Anita', value: 'Santa Anita' },
  { label: 'Santa Anita Huiloac', value: 'Santa Anita Huiloac' },
  { label: 'Santa Anna', value: 'Santa Anna' },
  { label: 'Santa Apolonia', value: 'Santa Apolonia' },
  { label: 'Santa Apolonia', value: 'Santa Apolonia' },
  { label: 'Santa Apolonia Teacalco', value: 'Santa Apolonia Teacalco' },
  { label: 'Santa Barbara', value: 'Santa Barbara' },
  { label: 'Santa Barbara', value: 'Santa Barbara' },
  { label: 'Santa Barbara', value: 'Santa Barbara' },
  { label: 'Santa Barbara', value: 'Santa Barbara' },
  { label: 'Santa Barbara', value: 'Santa Barbara' },
  { label: 'Santa Barbara', value: 'Santa Barbara' },
  { label: 'Santa Barbara', value: 'Santa Barbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Bárbara', value: 'Santa Bárbara' },
  { label: 'Santa Barbara County', value: 'Santa Barbara County' },
  { label: 'Santa Bárbara De Casa', value: 'Santa Bárbara De Casa' },
  { label: 'Santa Bárbara De Goiás', value: 'Santa Bárbara De Goiás' },
  { label: 'Santa Bárbara De La Cueva', value: 'Santa Bárbara De La Cueva' },
  { label: 'Santa Bárbara De Nexe', value: 'Santa Bárbara De Nexe' },
  { label: 'Santa Bárbara De Pinto', value: 'Santa Bárbara De Pinto' },
  { label: 'Santa Bárbara Do Leste', value: 'Santa Bárbara Do Leste' },
  { label: 'Santa Bárbara Do Monte Verde', value: 'Santa Bárbara Do Monte Verde' },
  { label: 'Santa Bárbara Do Pará', value: 'Santa Bárbara Do Pará' },
  { label: 'Santa Bárbara Do Sul', value: 'Santa Bárbara Do Sul' },
  { label: 'Santa Bárbara Do Tugúrio', value: 'Santa Bárbara Do Tugúrio' },
  { label: 'Santa Bárbara Doeste', value: 'Santa Bárbara Doeste' },
  { label: 'Santa Bernardina', value: 'Santa Bernardina' },
  { label: 'Santa Branca', value: 'Santa Branca' },
  { label: 'Santa Brigida', value: 'Santa Brigida' },
  { label: 'Santa Brigida', value: 'Santa Brigida' },
  { label: 'Santa Brígida', value: 'Santa Brígida' },
  { label: 'Santa Brígida', value: 'Santa Brígida' },
  { label: 'Santa Carmem', value: 'Santa Carmem' },
  { label: 'Santa Casilda', value: 'Santa Casilda' },
  { label: 'Santa Catalina', value: 'Santa Catalina' },
  { label: 'Santa Catalina', value: 'Santa Catalina' },
  { label: 'Santa Catalina', value: 'Santa Catalina' },
  { label: 'Santa Catalina', value: 'Santa Catalina' },
  { label: 'Santa Catalina', value: 'Santa Catalina' },
  { label: 'Santa Catalina', value: 'Santa Catalina' },
  { label: 'Santa Catalina', value: 'Santa Catalina' },
  { label: 'Santa Catalina Norte', value: 'Santa Catalina Norte' },
  { label: 'Santa Catalina Quierí', value: 'Santa Catalina Quierí' },
  { label: 'Santa Catalina Sur', value: 'Santa Catalina Sur' },
  { label: 'Santa Catarina', value: 'Santa Catarina' },
  { label: 'Santa Catarina', value: 'Santa Catarina' },
  { label: 'Santa Catarina', value: 'Santa Catarina' },
  { label: 'Santa Catarina', value: 'Santa Catarina' },
  { label: 'Santa Catarina', value: 'Santa Catarina' },
  { label: 'Santa Catarina', value: 'Santa Catarina' },
  { label: 'Santa Catarina', value: 'Santa Catarina' },
  { label: 'Santa Catarina', value: 'Santa Catarina' },
  { label: 'Santa Catarina', value: 'Santa Catarina' },
  { label: 'Santa Catarina', value: 'Santa Catarina' },
  { label: 'Santa Catarina Ayometla', value: 'Santa Catarina Ayometla' },
  { label: 'Santa Catarina Ayotzingo', value: 'Santa Catarina Ayotzingo' },
  { label: 'Santa Catarina Barahona', value: 'Santa Catarina Barahona' },
  { label: 'Santa Catarina Cuixtla', value: 'Santa Catarina Cuixtla' },
  { label: 'Santa Catarina Da Serra', value: 'Santa Catarina Da Serra' },
  { label: 'Santa Catarina De Tepehuanes', value: 'Santa Catarina De Tepehuanes' },
  { label: 'Santa Catarina Del Monte', value: 'Santa Catarina Del Monte' },
  { label: 'Santa Catarina Ixtahuacán', value: 'Santa Catarina Ixtahuacán' },
  { label: 'Santa Catarina Juquila', value: 'Santa Catarina Juquila' },
  { label: 'Santa Catarina Loxicha', value: 'Santa Catarina Loxicha' },
  { label: 'Santa Catarina Mechoacán', value: 'Santa Catarina Mechoacán' },
  { label: 'Santa Catarina Minas', value: 'Santa Catarina Minas' },
  { label: 'Santa Catarina Mita', value: 'Santa Catarina Mita' },
  { label: 'Santa Catarina Palopó', value: 'Santa Catarina Palopó' },
  { label: 'Santa Catarina Pinula', value: 'Santa Catarina Pinula' },
  { label: 'Santa Catarina Quiané', value: 'Santa Catarina Quiané' },
  { label: 'Santa Catarina Roatina', value: 'Santa Catarina Roatina' },
  { label: 'Santa Catarina Tayata', value: 'Santa Catarina Tayata' },
  { label: 'Santa Catarina Tlaltempan', value: 'Santa Catarina Tlaltempan' },
  { label: 'Santa Catarina Villanueva', value: 'Santa Catarina Villanueva' },
  { label: 'Santa Catarina Yecahuizotl', value: 'Santa Catarina Yecahuizotl' },
  { label: 'Santa Caterina Albanese', value: 'Santa Caterina Albanese' },
  { label: 'Santa Caterina Dello Ionio', value: 'Santa Caterina Dello Ionio' },
  { label: 'Santa Caterina Dello Ionio Marina', value: 'Santa Caterina Dello Ionio Marina' },
  { label: 'Santa Caterina Villarmosa', value: 'Santa Caterina Villarmosa' },
  { label: 'Santa Cecilia', value: 'Santa Cecilia' },
  { label: 'Santa Cecilia', value: 'Santa Cecilia' },
  { label: 'Santa Cecília', value: 'Santa Cecília' },
  { label: 'Santa Cecília', value: 'Santa Cecília' },
  { label: 'Santa Cecília De Voltregà', value: 'Santa Cecília De Voltregà' },
  { label: 'Santa Cecilia Del Alcor', value: 'Santa Cecilia Del Alcor' },
  { label: 'Santa Cecília Do Pavão', value: 'Santa Cecília Do Pavão' },
  { label: 'Santa Cecília Do Sul', value: 'Santa Cecília Do Sul' },
  { label: 'Santa Cecilia Jalieza', value: 'Santa Cecilia Jalieza' },
  { label: 'Santa Cecilia Tepetitlán', value: 'Santa Cecilia Tepetitlán' },
  { label: 'Santa Cesarea Terme', value: 'Santa Cesarea Terme' },
  { label: 'Santa Cilia', value: 'Santa Cilia' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara', value: 'Santa Clara' },
  { label: 'Santa Clara County', value: 'Santa Clara County' },
  { label: 'Santa Clara De Avedillo', value: 'Santa Clara De Avedillo' },
  { label: 'Santa Clara De Juárez', value: 'Santa Clara De Juárez' },
  { label: 'Santa Clara De Olimar', value: 'Santa Clara De Olimar' },
  { label: 'Santa Clara De Valladares', value: 'Santa Clara De Valladares' },
  { label: 'Santa Clara Del Cobre', value: 'Santa Clara Del Cobre' },
  { label: 'Santa Clara Del Tule', value: 'Santa Clara Del Tule' },
  { label: 'Santa Clara Do Sul', value: 'Santa Clara Do Sul' },
  { label: 'Santa Clara Doeste', value: 'Santa Clara Doeste' },
  { label: 'Santa Clara Huitziltepec', value: 'Santa Clara Huitziltepec' },
  { label: 'Santa Clara La Laguna', value: 'Santa Clara La Laguna' },
  { label: 'Santa Clara Pueblo', value: 'Santa Clara Pueblo' },
  { label: 'Santa Clarita', value: 'Santa Clarita' },
  { label: 'Santa Claus', value: 'Santa Claus' },
  { label: 'Santa Coloma', value: 'Santa Coloma' },
  { label: 'Santa Coloma De Cervelló', value: 'Santa Coloma De Cervelló' },
  { label: 'Santa Coloma De Farners', value: 'Santa Coloma De Farners' },
  { label: 'Santa Coloma De Gramenet', value: 'Santa Coloma De Gramenet' },
  { label: 'Santa Coloma De Queralt', value: 'Santa Coloma De Queralt' },
  { label: 'Santa Colomba De Curueño', value: 'Santa Colomba De Curueño' },
  { label: 'Santa Colomba De Las Monjas', value: 'Santa Colomba De Las Monjas' },
  { label: 'Santa Colomba De Somoza', value: 'Santa Colomba De Somoza' },
  { label: 'Santa Comba', value: 'Santa Comba' },
  { label: 'Santa Comba', value: 'Santa Comba' },
  { label: 'Santa Comba Dão', value: 'Santa Comba Dão' },
  { label: 'Santa Corinna', value: 'Santa Corinna' },
  { label: 'Santa Cristina', value: 'Santa Cristina' },
  { label: 'Santa Cristina', value: 'Santa Cristina' },
  { label: 'Santa Cristina Daro', value: 'Santa Cristina Daro' },
  { label: 'Santa Cristina Daspromonte', value: 'Santa Cristina Daspromonte' },
  { label: 'Santa Cristina De La Polvorosa', value: 'Santa Cristina De La Polvorosa' },
  { label: 'Santa Cristina De Valmadrigal', value: 'Santa Cristina De Valmadrigal' },
  { label: 'Santa Cristina Gela', value: 'Santa Cristina Gela' },
  { label: 'Santa Cristina Valgardena', value: 'Santa Cristina Valgardena' },
  { label: 'Santa Croce', value: 'Santa Croce' },
  { label: 'Santa Croce Camerina', value: 'Santa Croce Camerina' },
  { label: 'Santa Croce Del Sannio', value: 'Santa Croce Del Sannio' },
  { label: 'Santa Croce Di Magliano', value: 'Santa Croce Di Magliano' },
  { label: 'Santa Croce Scuole', value: 'Santa Croce Scuole' },
  { label: 'Santa Croce Sullarno', value: 'Santa Croce Sullarno' },
  { label: 'Santa Croya De Tera', value: 'Santa Croya De Tera' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Cruz Acapa', value: 'Santa Cruz Acapa' },
  { label: 'Santa Cruz Acatepec', value: 'Santa Cruz Acatepec' },
  { label: 'Santa Cruz Ajajalpan', value: 'Santa Cruz Ajajalpan' },
  { label: 'Santa Cruz Amilpas', value: 'Santa Cruz Amilpas' },
  { label: 'Santa Cruz Aquiahuac', value: 'Santa Cruz Aquiahuac' },
  { label: 'Santa Cruz Atizapán', value: 'Santa Cruz Atizapán' },
  { label: 'Santa Cruz Ayotuxco', value: 'Santa Cruz Ayotuxco' },
  { label: 'Santa Cruz Balanyá', value: 'Santa Cruz Balanyá' },
  { label: 'Santa Cruz Bombatevi', value: 'Santa Cruz Bombatevi' },
  { label: 'Santa Cruz Cabrália', value: 'Santa Cruz Cabrália' },
  { label: 'Santa Cruz County', value: 'Santa Cruz County' },
  { label: 'Santa Cruz County', value: 'Santa Cruz County' },
  { label: 'Santa Cruz Cuauhtenco', value: 'Santa Cruz Cuauhtenco' },
  { label: 'Santa Cruz Cuautomatitla', value: 'Santa Cruz Cuautomatitla' },
  { label: 'Santa Cruz Da Baixa Verde', value: 'Santa Cruz Da Baixa Verde' },
  { label: 'Santa Cruz Da Conceição', value: 'Santa Cruz Da Conceição' },
  { label: 'Santa Cruz Da Esperança', value: 'Santa Cruz Da Esperança' },
  { label: 'Santa Cruz Da Graciosa', value: 'Santa Cruz Da Graciosa' },
  { label: 'Santa Cruz Da Vitória', value: 'Santa Cruz Da Vitória' },
  { label: 'Santa Cruz Das Flores', value: 'Santa Cruz Das Flores' },
  { label: 'Santa Cruz Das Palmeiras', value: 'Santa Cruz Das Palmeiras' },
  { label: 'Santa Cruz De Barahona', value: 'Santa Cruz De Barahona' },
  { label: 'Santa Cruz De Barcenas', value: 'Santa Cruz De Barcenas' },
  { label: 'Santa Cruz De Bezana', value: 'Santa Cruz De Bezana' },
  { label: 'Santa Cruz De Boedo', value: 'Santa Cruz De Boedo' },
  { label: 'Santa Cruz De Bravo', value: 'Santa Cruz De Bravo' },
  { label: 'Santa Cruz De El Seibo', value: 'Santa Cruz De El Seibo' },
  { label: 'Santa Cruz De Goiás', value: 'Santa Cruz De Goiás' },
  { label: 'Santa Cruz De Grío', value: 'Santa Cruz De Grío' },
  { label: 'Santa Cruz De Juárez', value: 'Santa Cruz De Juárez' },
  { label: 'Santa Cruz De Juventino Rosas', value: 'Santa Cruz De Juventino Rosas' },
  { label: 'Santa Cruz De La Loma (Tepetates)', value: 'Santa Cruz De La Loma (Tepetates)' },
  { label: 'Santa Cruz De La Palma', value: 'Santa Cruz De La Palma' },
  { label: 'Santa Cruz De La Palma', value: 'Santa Cruz De La Palma' },
  { label: 'Santa Cruz De La Salceda', value: 'Santa Cruz De La Salceda' },
  { label: 'Santa Cruz De La Serós', value: 'Santa Cruz De La Serós' },
  { label: 'Santa Cruz De La Sierra', value: 'Santa Cruz De La Sierra' },
  { label: 'Santa Cruz De La Sierra', value: 'Santa Cruz De La Sierra' },
  { label: 'Santa Cruz De La Soledad', value: 'Santa Cruz De La Soledad' },
  { label: 'Santa Cruz De La Zarza', value: 'Santa Cruz De La Zarza' },
  { label: 'Santa Cruz De Las Flores Tlajomulco De Zúñiga Jalisco', value: 'Santa Cruz De Las Flores Tlajomulco De Zúñiga Jalisco' },
  { label: 'Santa Cruz De Los Cáñamos', value: 'Santa Cruz De Los Cáñamos' },
  { label: 'Santa Cruz De Marchena', value: 'Santa Cruz De Marchena' },
  { label: 'Santa Cruz De Minas', value: 'Santa Cruz De Minas' },
  { label: 'Santa Cruz De Moncayo', value: 'Santa Cruz De Moncayo' },
  { label: 'Santa Cruz De Monte Castelo', value: 'Santa Cruz De Monte Castelo' },
  { label: 'Santa Cruz De Moya', value: 'Santa Cruz De Moya' },
  { label: 'Santa Cruz De Mudela', value: 'Santa Cruz De Mudela' },
  { label: 'Santa Cruz De Nogueras', value: 'Santa Cruz De Nogueras' },
  { label: 'Santa Cruz De Paniagua', value: 'Santa Cruz De Paniagua' },
  { label: 'Santa Cruz De Pinares', value: 'Santa Cruz De Pinares' },
  { label: 'Santa Cruz De Salinas', value: 'Santa Cruz De Salinas' },
  { label: 'Santa Cruz De Tenerife', value: 'Santa Cruz De Tenerife' },
  { label: 'Santa Cruz De Tenerife', value: 'Santa Cruz De Tenerife' },
  { label: 'Santa Cruz De Yanguas', value: 'Santa Cruz De Yanguas' },
  { label: 'Santa Cruz De Yojoa', value: 'Santa Cruz De Yojoa' },
  { label: 'Santa Cruz Del Astillero', value: 'Santa Cruz Del Astillero' },
  { label: 'Santa Cruz Del Comercio', value: 'Santa Cruz Del Comercio' },
  { label: 'Santa Cruz Del Monte', value: 'Santa Cruz Del Monte' },
  { label: 'Santa Cruz Del Norte', value: 'Santa Cruz Del Norte' },
  { label: 'Santa Cruz Del Quiché', value: 'Santa Cruz Del Quiché' },
  { label: 'Santa Cruz Del Retamar', value: 'Santa Cruz Del Retamar' },
  { label: 'Santa Cruz Del Sur', value: 'Santa Cruz Del Sur' },
  { label: 'Santa Cruz Del Valle', value: 'Santa Cruz Del Valle' },
  { label: 'Santa Cruz Del Valle', value: 'Santa Cruz Del Valle' },
  { label: 'Santa Cruz Del Valle Urbión', value: 'Santa Cruz Del Valle Urbión' },
  { label: 'Santa Cruz Do Arari', value: 'Santa Cruz Do Arari' },
  { label: 'Santa Cruz Do Bispo', value: 'Santa Cruz Do Bispo' },
  { label: 'Santa Cruz Do Capibaribe', value: 'Santa Cruz Do Capibaribe' },
  { label: 'Santa Cruz Do Escalvado', value: 'Santa Cruz Do Escalvado' },
  { label: 'Santa Cruz Do Piauí', value: 'Santa Cruz Do Piauí' },
  { label: 'Santa Cruz Do Rio Pardo', value: 'Santa Cruz Do Rio Pardo' },
  { label: 'Santa Cruz Do Sul', value: 'Santa Cruz Do Sul' },
  { label: 'Santa Cruz Do Xingu', value: 'Santa Cruz Do Xingu' },
  { label: 'Santa Cruz Dos Milagres', value: 'Santa Cruz Dos Milagres' },
  { label: 'Santa Cruz El Grande', value: 'Santa Cruz El Grande' },
  { label: 'Santa Cruz El Porvenir', value: 'Santa Cruz El Porvenir' },
  { label: 'Santa Cruz Escandón', value: 'Santa Cruz Escandón' },
  { label: 'Santa Cruz Ex-Hacienda', value: 'Santa Cruz Ex-Hacienda' },
  { label: 'Santa Cruz Huitziltepec', value: 'Santa Cruz Huitziltepec' },
  { label: 'Santa Cruz Huitzizilapan', value: 'Santa Cruz Huitzizilapan' },
  { label: 'Santa Cruz La Laguna', value: 'Santa Cruz La Laguna' },
  { label: 'Santa Cruz Lachixolana', value: 'Santa Cruz Lachixolana' },
  { label: 'Santa Cruz Luján', value: 'Santa Cruz Luján' },
  { label: 'Santa Cruz Mextepec', value: 'Santa Cruz Mextepec' },
  { label: 'Santa Cruz Mixtepec', value: 'Santa Cruz Mixtepec' },
  { label: 'Santa Cruz Muluá', value: 'Santa Cruz Muluá' },
  { label: 'Santa Cruz Naranjo', value: 'Santa Cruz Naranjo' },
  { label: 'Santa Cruz Nundaco', value: 'Santa Cruz Nundaco' },
  { label: 'Santa Cruz Otlatla', value: 'Santa Cruz Otlatla' },
  { label: 'Santa Cruz Ozolotepec', value: 'Santa Cruz Ozolotepec' },
  { label: 'Santa Cruz Papalutla', value: 'Santa Cruz Papalutla' },
  { label: 'Santa Cruz Pocitos', value: 'Santa Cruz Pocitos' },
  { label: 'Santa Cruz Pueblo', value: 'Santa Cruz Pueblo' },
  { label: 'Santa Cruz Pueblo Nuevo', value: 'Santa Cruz Pueblo Nuevo' },
  { label: 'Santa Cruz Pueblo Nuevo (Pueblo Nuevo)', value: 'Santa Cruz Pueblo Nuevo (Pueblo Nuevo)' },
  { label: 'Santa Cruz Tacache De Mina', value: 'Santa Cruz Tacache De Mina' },
  { label: 'Santa Cruz Tayata', value: 'Santa Cruz Tayata' },
  { label: 'Santa Cruz Tetela', value: 'Santa Cruz Tetela' },
  { label: 'Santa Cruz Texmalaquilla', value: 'Santa Cruz Texmalaquilla' },
  { label: 'Santa Cruz Verapaz', value: 'Santa Cruz Verapaz' },
  { label: 'Santa Cruz Xaltetela', value: 'Santa Cruz Xaltetela' },
  { label: 'Santa Cruz Xitla', value: 'Santa Cruz Xitla' },
  { label: 'Santa Cruz Xoxocotlán', value: 'Santa Cruz Xoxocotlán' },
  { label: 'Santa Cruz Yucucani', value: 'Santa Cruz Yucucani' },
  { label: 'Santa Domenica', value: 'Santa Domenica' },
  { label: 'Santa Domenica Talao', value: 'Santa Domenica Talao' },
  { label: 'Santa Domenica Vittoria', value: 'Santa Domenica Vittoria' },
  { label: 'Santa Efigênia De Minas', value: 'Santa Efigênia De Minas' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena', value: 'Santa Elena' },
  { label: 'Santa Elena De Jamuz', value: 'Santa Elena De Jamuz' },
  { label: 'Santa Elena De Uairén', value: 'Santa Elena De Uairén' },
  { label: 'Santa Elena Paliseca', value: 'Santa Elena Paliseca' },
  { label: 'Santa Elisabetta', value: 'Santa Elisabetta' },
  { label: 'Santa Engrácia', value: 'Santa Engrácia' },
  { label: 'Santa Engracia Del Jubera', value: 'Santa Engracia Del Jubera' },
  { label: 'Santa Ernestina', value: 'Santa Ernestina' },
  { label: 'Santa Eufemia', value: 'Santa Eufemia' },
  { label: 'Santa Eufemia', value: 'Santa Eufemia' },
  { label: 'Santa Eufémia', value: 'Santa Eufémia' },
  { label: 'Santa Eufemia Del Arroyo', value: 'Santa Eufemia Del Arroyo' },
  { label: 'Santa Eufemia Del Barco', value: 'Santa Eufemia Del Barco' },
  { label: 'Santa Eufemia Lamezia', value: 'Santa Eufemia Lamezia' },
  { label: 'Santa Eugènia', value: 'Santa Eugènia' },
  { label: 'Santa Eugènia De Berga', value: 'Santa Eugènia De Berga' },
  { label: 'Santa Eulalia', value: 'Santa Eulalia' },
  { label: 'Santa Eulalia', value: 'Santa Eulalia' },
  { label: 'Santa Eulalia', value: 'Santa Eulalia' },
  { label: 'Santa Eulália', value: 'Santa Eulália' },
  { label: 'Santa Eulalia Bajera', value: 'Santa Eulalia Bajera' },
  { label: 'Santa Eulalia De Gállego', value: 'Santa Eulalia De Gállego' },
  { label: 'Santa Eulalia De Oscos', value: 'Santa Eulalia De Oscos' },
  { label: 'Santa Eulàlia De Riuprimer', value: 'Santa Eulàlia De Riuprimer' },
  { label: 'Santa Eulàlia De Ronçana', value: 'Santa Eulàlia De Ronçana' },
  { label: 'Santa Eulalia Del Río', value: 'Santa Eulalia Del Río' },
  { label: 'Santa Eulària Des Riu', value: 'Santa Eulària Des Riu' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santa Fé', value: 'Santa Fé' },
  { label: 'Santa Fé', value: 'Santa Fé' },
  { label: 'Santa Fé', value: 'Santa Fé' },
  { label: 'Santa Fé', value: 'Santa Fé' },
  { label: 'Santa Fé', value: 'Santa Fé' },
  { label: 'Santa Fe County', value: 'Santa Fe County' },
  { label: 'Santa Fe De Antioquia', value: 'Santa Fe De Antioquia' },
  { label: 'Santa Fé De Goiás', value: 'Santa Fé De Goiás' },
  { label: 'Santa Fé De La Laguna', value: 'Santa Fé De La Laguna' },
  { label: 'Santa Fe De La Purísima', value: 'Santa Fe De La Purísima' },
  { label: 'Santa Fé De Minas', value: 'Santa Fé De Minas' },
  { label: 'Santa Fe De Mondújar', value: 'Santa Fe De Mondújar' },
  { label: 'Santa Fe Del Penedès', value: 'Santa Fe Del Penedès' },
  { label: 'Santa Fe Del Río', value: 'Santa Fe Del Río' },
  { label: 'Santa Fé Do Araguaia', value: 'Santa Fé Do Araguaia' },
  { label: 'Santa Fé Do Sul', value: 'Santa Fé Do Sul' },
  { label: 'Santa Fe Springs', value: 'Santa Fe Springs' },
  { label: 'Santa Fe Tepetlapa', value: 'Santa Fe Tepetlapa' },
  { label: 'Santa Fe Y La Mar', value: 'Santa Fe Y La Mar' },
  { label: 'Santa Filomena', value: 'Santa Filomena' },
  { label: 'Santa Filomena', value: 'Santa Filomena' },
  { label: 'Santa Filomena', value: 'Santa Filomena' },
  { label: 'Santa Filomena Do Maranhão', value: 'Santa Filomena Do Maranhão' },
  { label: 'Santa Fiora', value: 'Santa Fiora' },
  { label: 'Santa Flavia', value: 'Santa Flavia' },
  { label: 'Santa Gadea Del Cid', value: 'Santa Gadea Del Cid' },
  { label: 'Santa Gertrudes', value: 'Santa Gertrudes' },
  { label: 'Santa Gertrudis', value: 'Santa Gertrudis' },
  { label: 'Santa Gertrudis', value: 'Santa Gertrudis' },
  { label: 'Santa Giuletta', value: 'Santa Giuletta' },
  { label: 'Santa Giusta', value: 'Santa Giusta' },
  { label: 'Santa Giustina', value: 'Santa Giustina' },
  { label: 'Santa Giustina', value: 'Santa Giustina' },
  { label: 'Santa Giustina In Colle', value: 'Santa Giustina In Colle' },
  { label: 'Santa Helena', value: 'Santa Helena' },
  { label: 'Santa Helena', value: 'Santa Helena' },
  { label: 'Santa Helena', value: 'Santa Helena' },
  { label: 'Santa Helena', value: 'Santa Helena' },
  { label: 'Santa Helena De Goiás', value: 'Santa Helena De Goiás' },
  { label: 'Santa Helena De Minas', value: 'Santa Helena De Minas' },
  { label: 'Santa Helena Del Opón', value: 'Santa Helena Del Opón' },
  { label: 'Santa Ignacia', value: 'Santa Ignacia' },
  { label: 'Santa Ignacia', value: 'Santa Ignacia' },
  { label: 'Santa Inés', value: 'Santa Inés' },
  { label: 'Santa Inês', value: 'Santa Inês' },
  { label: 'Santa Inês', value: 'Santa Inês' },
  { label: 'Santa Inês', value: 'Santa Inês' },
  { label: 'Santa Inês', value: 'Santa Inês' },
  { label: 'Santa Inés De Zaragoza', value: 'Santa Inés De Zaragoza' },
  { label: 'Santa Inés Del Monte', value: 'Santa Inés Del Monte' },
  { label: 'Santa Inés Tecuexcomac', value: 'Santa Inés Tecuexcomac' },
  { label: 'Santa Inés Varela La Luz', value: 'Santa Inés Varela La Luz' },
  { label: 'Santa Ines West', value: 'Santa Ines West' },
  { label: 'Santa Ines West', value: 'Santa Ines West' },
  { label: 'Santa Inés Yatzeche', value: 'Santa Inés Yatzeche' },
  { label: 'Santa Iria Da Azóia', value: 'Santa Iria Da Azóia' },
  { label: 'Santa Iria Da Azóia', value: 'Santa Iria Da Azóia' },
  { label: 'Santa Iria De Azoia', value: 'Santa Iria De Azoia' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel', value: 'Santa Isabel' },
  { label: 'Santa Isabel [Fraccionamiento]', value: 'Santa Isabel [Fraccionamiento]' },
  { label: 'Santa Isabel Chalma', value: 'Santa Isabel Chalma' },
  { label: 'Santa Isabel Cholula', value: 'Santa Isabel Cholula' },
  { label: 'Santa Isabel Do Ivaí', value: 'Santa Isabel Do Ivaí' },
  { label: 'Santa Isabel Do Rio Negro', value: 'Santa Isabel Do Rio Negro' },
  { label: 'Santa Isabel Ixtapan', value: 'Santa Isabel Ixtapan' },
  { label: 'Santa Isabel Municipio', value: 'Santa Isabel Municipio' },
  { label: 'Santa Isabel Xiloxoxtla', value: 'Santa Isabel Xiloxoxtla' },
  { label: 'Santa Izabel Do Oeste', value: 'Santa Izabel Do Oeste' },
  { label: 'Santa Izabel Do Pará', value: 'Santa Izabel Do Pará' },
  { label: 'Santa Josefa', value: 'Santa Josefa' },
  { label: 'Santa Josefa', value: 'Santa Josefa' },
  { label: 'Santa Juana', value: 'Santa Juana' },
  { label: 'Santa Juana', value: 'Santa Juana' },
  { label: 'Santa Juana Centro', value: 'Santa Juana Centro' },
  { label: 'Santa Juana Primera Sección', value: 'Santa Juana Primera Sección' },
  { label: 'Santa Juliana', value: 'Santa Juliana' },
  { label: 'Santa Juliana', value: 'Santa Juliana' },
  { label: 'Santa Juliana', value: 'Santa Juliana' },
  { label: 'Santa Justa', value: 'Santa Justa' },
  { label: 'Santa Justina', value: 'Santa Justina' },
  { label: 'Santa Justina Ecatepec', value: 'Santa Justina Ecatepec' },
  { label: 'Santa Leopoldina', value: 'Santa Leopoldina' },
  { label: 'Santa Llogaia Dàlguema', value: 'Santa Llogaia Dàlguema' },
  { label: 'Santa Luce', value: 'Santa Luce' },
  { label: 'Santa Lucia', value: 'Santa Lucia' },
  { label: 'Santa Lucia', value: 'Santa Lucia' },
  { label: 'Santa Lucia', value: 'Santa Lucia' },
  { label: 'Santa Lucia', value: 'Santa Lucia' },
  { label: 'Santa Lucia', value: 'Santa Lucia' },
  { label: 'Santa Lucia', value: 'Santa Lucia' },
  { label: 'Santa Lucia', value: 'Santa Lucia' },
  { label: 'Santa Lucia', value: 'Santa Lucia' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lucía', value: 'Santa Lucía' },
  { label: 'Santa Lúcia', value: 'Santa Lúcia' },
  { label: 'Santa Lúcia', value: 'Santa Lúcia' },
  { label: 'Santa Lucía Cosamaloapan', value: 'Santa Lucía Cosamaloapan' },
  { label: 'Santa Lucía Cotzumalguapa', value: 'Santa Lucía Cotzumalguapa' },
  { label: 'Santa Lucía De Tirajana', value: 'Santa Lucía De Tirajana' },
  { label: 'Santa Lucía Del Camino', value: 'Santa Lucía Del Camino' },
  { label: 'Santa Lucia Del Mela', value: 'Santa Lucia Del Mela' },
  { label: 'Santa Lucia Di Piave', value: 'Santa Lucia Di Piave' },
  { label: 'Santa Lucia Di Serino', value: 'Santa Lucia Di Serino' },
  { label: 'Santa Lucia La Reforma', value: 'Santa Lucia La Reforma' },
  { label: 'Santa Lucía Milpas Altas', value: 'Santa Lucía Milpas Altas' },
  { label: 'Santa Lucía Ocotlán', value: 'Santa Lucía Ocotlán' },
  { label: 'Santa Lucía Potrerillo', value: 'Santa Lucía Potrerillo' },
  { label: 'Santa Lucía Teotepec', value: 'Santa Lucía Teotepec' },
  { label: 'Santa Lucía Utatlán', value: 'Santa Lucía Utatlán' },
  { label: 'Santa Luċija', value: 'Santa Luċija' },
  { label: 'Santa Luċija, Gozo', value: 'Santa Luċija, Gozo' },
  { label: 'Santa Luz', value: 'Santa Luz' },
  { label: 'Santa Luzia', value: 'Santa Luzia' },
  { label: 'Santa Luzia', value: 'Santa Luzia' },
  { label: 'Santa Luzia', value: 'Santa Luzia' },
  { label: 'Santa Luzia', value: 'Santa Luzia' },
  { label: 'Santa Luzia', value: 'Santa Luzia' },
  { label: 'Santa Luzia Do Itanhy', value: 'Santa Luzia Do Itanhy' },
  { label: 'Santa Luzia Do Norte', value: 'Santa Luzia Do Norte' },
  { label: 'Santa Luzia Do Pará', value: 'Santa Luzia Do Pará' },
  { label: 'Santa Luzia Do Paruá', value: 'Santa Luzia Do Paruá' },
  { label: 'Santa Luzia Doeste', value: 'Santa Luzia Doeste' },
  { label: 'Santa Magdalena', value: 'Santa Magdalena' },
  { label: 'Santa Magdalena', value: 'Santa Magdalena' },
  { label: 'Santa Magdalena De Pulpis', value: 'Santa Magdalena De Pulpis' },
  { label: 'Santa Magdalena Jicotlán', value: 'Santa Magdalena Jicotlán' },
  { label: 'Santa Marcela', value: 'Santa Marcela' },
  { label: 'Santa Marcela', value: 'Santa Marcela' },
  { label: 'Santa Mare', value: 'Santa Mare' },
  { label: 'Santa Margalida', value: 'Santa Margalida' },
  { label: 'Santa Margarida', value: 'Santa Margarida' },
  { label: 'Santa Margarida Da Coutada', value: 'Santa Margarida Da Coutada' },
  { label: 'Santa Margarida De Montbui', value: 'Santa Margarida De Montbui' },
  { label: 'Santa Margarida Do Sul', value: 'Santa Margarida Do Sul' },
  { label: 'Santa Margarida I Els Monjos', value: 'Santa Margarida I Els Monjos' },
  { label: 'Santa Margarita', value: 'Santa Margarita' },
  { label: 'Santa Margarita', value: 'Santa Margarita' },
  { label: 'Santa Margarita', value: 'Santa Margarita' },
  { label: 'Santa Margarita Huitepec', value: 'Santa Margarita Huitepec' },
  { label: 'Santa Margherita', value: 'Santa Margherita' },
  { label: 'Santa Margherita Dadige', value: 'Santa Margherita Dadige' },
  { label: 'Santa Margherita Di Belice', value: 'Santa Margherita Di Belice' },
  { label: 'Santa Margherita Di Staffora', value: 'Santa Margherita Di Staffora' },
  { label: 'Santa Margherita Ligure', value: 'Santa Margherita Ligure' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa Maria', value: 'Santa Maria' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Santa Maria A Monte', value: 'Santa Maria A Monte' },
  { label: 'Santa Maria A Vico', value: 'Santa Maria A Vico' },
  { label: 'Santa María Acatepec', value: 'Santa María Acatepec' },
  { label: 'Santa María Actipac', value: 'Santa María Actipac' },
  { label: 'Santa María Acú', value: 'Santa María Acú' },
  { label: 'Santa María Ajoloapan', value: 'Santa María Ajoloapan' },
  { label: 'Santa María Ajoloapan', value: 'Santa María Ajoloapan' },
  { label: 'Santa María Alotepec', value: 'Santa María Alotepec' },
  { label: 'Santa María Amajac', value: 'Santa María Amajac' },
  { label: 'Santa Maria Amealco', value: 'Santa Maria Amealco' },
  { label: 'Santa María Apaxco', value: 'Santa María Apaxco' },
  { label: 'Santa María Apazco', value: 'Santa María Apazco' },
  { label: 'Santa Maria Apparente', value: 'Santa Maria Apparente' },
  { label: 'Santa María Aranzazú (Santa María)', value: 'Santa María Aranzazú (Santa María)' },
  { label: 'Santa María Asunción', value: 'Santa María Asunción' },
  { label: 'Santa María Atarasquillo', value: 'Santa María Atarasquillo' },
  { label: 'Santa María Atexcac', value: 'Santa María Atexcac' },
  { label: 'Santa María Atlihuetzian', value: 'Santa María Atlihuetzian' },
  { label: 'Santa María Atzompa', value: 'Santa María Atzompa' },
  { label: 'Santa María Batha', value: 'Santa María Batha' },
  { label: 'Santa María Begoña', value: 'Santa María Begoña' },
  { label: 'Santa María Camotlán', value: 'Santa María Camotlán' },
  { label: 'Santa María Canchesdá', value: 'Santa María Canchesdá' },
  { label: 'Santa Maria Capua Vetere', value: 'Santa Maria Capua Vetere' },
  { label: 'Santa María Chachoápam', value: 'Santa María Chachoápam' },
  { label: 'Santa María Chico Ometepec', value: 'Santa María Chico Ometepec' },
  { label: 'Santa María Chilapa De Díaz', value: 'Santa María Chilapa De Díaz' },
  { label: 'Santa María Chimalapa', value: 'Santa María Chimalapa' },
  { label: 'Santa María Chimalhuacán', value: 'Santa María Chimalhuacán' },
  { label: 'Santa María Chiquimula', value: 'Santa María Chiquimula' },
  { label: 'Santa María Citendejé', value: 'Santa María Citendejé' },
  { label: 'Santa Maria Coatepec', value: 'Santa Maria Coatepec' },
  { label: 'Santa Maria Codifiume', value: 'Santa Maria Codifiume' },
  { label: 'Santa Maria Coghinas', value: 'Santa Maria Coghinas' },
  { label: 'Santa María Colotepec', value: 'Santa María Colotepec' },
  { label: 'Santa María Cortijo', value: 'Santa María Cortijo' },
  { label: 'Santa María Coyotepec', value: 'Santa María Coyotepec' },
  { label: 'Santa María Cuevas', value: 'Santa María Cuevas' },
  { label: 'Santa Maria Da Boa Vista', value: 'Santa Maria Da Boa Vista' },
  { label: 'Santa Maria Da Feira', value: 'Santa Maria Da Feira' },
  { label: 'Santa Maria Da Serra', value: 'Santa Maria Da Serra' },
  { label: 'Santa Maria Da Vitória', value: 'Santa Maria Da Vitória' },
  { label: 'Santa Maria Das Barreiras', value: 'Santa Maria Das Barreiras' },
  { label: 'Santa Maria De Belém', value: 'Santa Maria De Belém' },
  { label: 'Santa Maria De Besora', value: 'Santa Maria De Besora' },
  { label: 'Santa María De Cayón', value: 'Santa María De Cayón' },
  { label: 'Santa Maria De Corcó', value: 'Santa Maria De Corcó' },
  { label: 'Santa María De Dulcis', value: 'Santa María De Dulcis' },
  { label: 'Santa María De Enmedio', value: 'Santa María De Enmedio' },
  { label: 'Santa María De Guadalupe', value: 'Santa María De Guadalupe' },
  { label: 'Santa María De Guaymas', value: 'Santa María De Guaymas' },
  { label: 'Santa María De Guía De Gran Canaria', value: 'Santa María De Guía De Gran Canaria' },
  { label: 'Santa María De Huerta', value: 'Santa María De Huerta' },
  { label: 'Santa Maria De Itabira', value: 'Santa Maria De Itabira' },
  { label: 'Santa María De Jesús', value: 'Santa María De Jesús' },
  { label: 'Santa Maria De Jetibá', value: 'Santa Maria De Jetibá' },
  { label: 'Santa María De La Alameda', value: 'Santa María De La Alameda' },
  { label: 'Santa María De La Isla', value: 'Santa María De La Isla' },
  { label: 'Santa María De La Paz', value: 'Santa María De La Paz' },
  { label: 'Santa María De La Vega', value: 'Santa María De La Vega' },
  { label: 'Santa María De Las Hoyas', value: 'Santa María De Las Hoyas' },
  { label: 'Santa María De Los Ángeles', value: 'Santa María De Los Ángeles' },
  { label: 'Santa María De Los Ángeles', value: 'Santa María De Los Ángeles' },
  { label: 'Santa María De Los Ángeles', value: 'Santa María De Los Ángeles' },
  { label: 'Santa María De Los Caballeros', value: 'Santa María De Los Caballeros' },
  { label: 'Santa María De Los Llanos', value: 'Santa María De Los Llanos' },
  { label: 'Santa Maria De Martorelles', value: 'Santa Maria De Martorelles' },
  { label: 'Santa Maria De Merlès', value: 'Santa Maria De Merlès' },
  { label: 'Santa Maria De Miralles', value: 'Santa Maria De Miralles' },
  { label: 'Santa María De Ordás', value: 'Santa María De Ordás' },
  { label: 'Santa Maria De Palautordera', value: 'Santa Maria De Palautordera' },
  { label: 'Santa María De Sando', value: 'Santa María De Sando' },
  { label: 'Santa María De Valverde', value: 'Santa María De Valverde' },
  { label: 'Santa Maria Degli Angeli', value: 'Santa Maria Degli Angeli' },
  { label: 'Santa Maria Dei Sabbioni', value: 'Santa Maria Dei Sabbioni' },
  { label: 'Santa María Del Arroyo', value: 'Santa María Del Arroyo' },
  { label: 'Santa María Del Berrocal', value: 'Santa María Del Berrocal' },
  { label: 'Santa María Del Buáraje', value: 'Santa María Del Buáraje' },
  { label: 'Santa María Del Camí', value: 'Santa María Del Camí' },
  { label: 'Santa María Del Campo', value: 'Santa María Del Campo' },
  { label: 'Santa María Del Campo Rus', value: 'Santa María Del Campo Rus' },
  { label: 'Santa Maria Del Cedro', value: 'Santa Maria Del Cedro' },
  { label: 'Santa María Del Cubillo', value: 'Santa María Del Cubillo' },
  { label: 'Santa María Del Invierno', value: 'Santa María Del Invierno' },
  { label: 'Santa María Del Llano', value: 'Santa María Del Llano' },
  { label: 'Santa María Del Mercadillo', value: 'Santa María Del Mercadillo' },
  { label: 'Santa Maria Del Molise', value: 'Santa Maria Del Molise' },
  { label: 'Santa María Del Monte', value: 'Santa María Del Monte' },
  { label: 'Santa María Del Monte', value: 'Santa María Del Monte' },
  { label: 'Santa María Del Monte De Cea', value: 'Santa María Del Monte De Cea' },
  { label: 'Santa María Del Oro', value: 'Santa María Del Oro' },
  { label: 'Santa María Del Oro', value: 'Santa María Del Oro' },
  { label: 'Santa María Del Páramo', value: 'Santa María Del Páramo' },
  { label: 'Santa María Del Real', value: 'Santa María Del Real' },
  { label: 'Santa María Del Refugio', value: 'Santa María Del Refugio' },
  { label: 'Santa María Del Río', value: 'Santa María Del Río' },
  { label: 'Santa María Del Rosario', value: 'Santa María Del Rosario' },
  { label: 'Santa María Del Tiétar', value: 'Santa María Del Tiétar' },
  { label: 'Santa María Del Tule', value: 'Santa María Del Tule' },
  { label: 'Santa María Del Val', value: 'Santa María Del Val' },
  { label: 'Santa María Del Valle', value: 'Santa María Del Valle' },
  { label: 'Santa Maria Della Versa', value: 'Santa Maria Della Versa' },
  { label: 'Santa Maria Di Licodia', value: 'Santa Maria Di Licodia' },
  { label: 'Santa Maria Di Non', value: 'Santa Maria Di Non' },
  { label: 'Santa Maria Di Sala', value: 'Santa Maria Di Sala' },
  { label: 'Santa Maria Do Cambucá', value: 'Santa Maria Do Cambucá' },
  { label: 'Santa Maria Do Castelo E São Miguel', value: 'Santa Maria Do Castelo E São Miguel' },
  { label: 'Santa Maria Do Herval', value: 'Santa Maria Do Herval' },
  { label: 'Santa Maria Do Oeste', value: 'Santa Maria Do Oeste' },
  { label: 'Santa Maria Do Pará', value: 'Santa Maria Do Pará' },
  { label: 'Santa Maria Do Salto', value: 'Santa Maria Do Salto' },
  { label: 'Santa Maria Do Suaçuí', value: 'Santa Maria Do Suaçuí' },
  { label: 'Santa Maria Do Tocantins', value: 'Santa Maria Do Tocantins' },
  { label: 'Santa Maria Doló', value: 'Santa Maria Doló' },
  { label: 'Santa Maria Dos Olivais', value: 'Santa Maria Dos Olivais' },
  { label: 'Santa Maria E São Miguel', value: 'Santa Maria E São Miguel' },
  { label: 'Santa María Ecatepec', value: 'Santa María Ecatepec' },
  { label: 'Santa María Endare', value: 'Santa María Endare' },
  { label: 'Santa María Guadalupe Tecola', value: 'Santa María Guadalupe Tecola' },
  { label: 'Santa María Guelacé', value: 'Santa María Guelacé' },
  { label: 'Santa María Guenagati', value: 'Santa María Guenagati' },
  { label: 'Santa Maria Hoè', value: 'Santa Maria Hoè' },
  { label: 'Santa María Huamelula', value: 'Santa María Huamelula' },
  { label: 'Santa María Huatulco', value: 'Santa María Huatulco' },
  { label: 'Santa María Huazolotitlán', value: 'Santa María Huazolotitlán' },
  { label: 'Santa María Huecatitla', value: 'Santa María Huecatitla' },
  { label: 'Santa María Huexoculco', value: 'Santa María Huexoculco' },
  { label: 'Santa María Huiramangaro (San Juan Tumbio)', value: 'Santa María Huiramangaro (San Juan Tumbio)' },
  { label: 'Santa María Ilucan', value: 'Santa María Ilucan' },
  { label: 'Santa Maria Imbaro', value: 'Santa Maria Imbaro' },
  { label: 'Santa María Ipalapa', value: 'Santa María Ipalapa' },
  { label: 'Santa María Ixhuatán', value: 'Santa María Ixhuatán' },
  { label: 'Santa María Ixtiyucán', value: 'Santa María Ixtiyucán' },
  { label: 'Santa María Ixtulco', value: 'Santa María Ixtulco' },
  { label: 'Santa María Jacatepec', value: 'Santa María Jacatepec' },
  { label: 'Santa María Jajalpa', value: 'Santa María Jajalpa' },
  { label: 'Santa María Jalapa Del Marqués', value: 'Santa María Jalapa Del Marqués' },
  { label: 'Santa María Jicaltepec', value: 'Santa María Jicaltepec' },
  { label: 'Santa María La Alta', value: 'Santa María La Alta' },
  { label: 'Santa María La Asunción', value: 'Santa María La Asunción' },
  { label: 'Santa María La Asunción', value: 'Santa María La Asunción' },
  { label: 'Santa María La Calera', value: 'Santa María La Calera' },
  { label: 'Santa Maria La Carità', value: 'Santa Maria La Carità' },
  { label: 'Santa Maria La Fossa', value: 'Santa Maria La Fossa' },
  { label: 'Santa Maria La Longa', value: 'Santa Maria La Longa' },
  { label: 'Santa María La Real De Nieva', value: 'Santa María La Real De Nieva' },
  { label: 'Santa Maria La Stella', value: 'Santa Maria La Stella' },
  { label: 'Santa María Lachixío', value: 'Santa María Lachixío' },
  { label: 'Santa Maria Macua', value: 'Santa Maria Macua' },
  { label: 'Santa Maria Madalena', value: 'Santa Maria Madalena' },
  { label: 'Santa Maria Maddalena', value: 'Santa Maria Maddalena' },
  { label: 'Santa María Magdalena', value: 'Santa María Magdalena' },
  { label: 'Santa María Magdalena', value: 'Santa María Magdalena' },
  { label: 'Santa María Magdalena Cahuacán', value: 'Santa María Magdalena Cahuacán' },
  { label: 'Santa María Magdalena Ocotitlán', value: 'Santa María Magdalena Ocotitlán' },
  { label: 'Santa Maria Maggiore', value: 'Santa Maria Maggiore' },
  { label: 'Santa María Malacatepec', value: 'Santa María Malacatepec' },
  { label: 'Santa María Mazatla', value: 'Santa María Mazatla' },
  { label: 'Santa María Mixtequilla', value: 'Santa María Mixtequilla' },
  { label: 'Santa María Nativitas', value: 'Santa María Nativitas' },
  { label: 'Santa María Nativitas', value: 'Santa María Nativitas' },
  { label: 'Santa María Nativitas', value: 'Santa María Nativitas' },
  { label: 'Santa Maria Navarrese', value: 'Santa Maria Navarrese' },
  { label: 'Santa María Nduayaco', value: 'Santa María Nduayaco' },
  { label: 'Santa María Nenetzintla', value: 'Santa María Nenetzintla' },
  { label: 'Santa María Nepopualco', value: 'Santa María Nepopualco' },
  { label: 'Santa Maria Nuova', value: 'Santa Maria Nuova' },
  { label: 'Santa Maria Nuova', value: 'Santa Maria Nuova' },
  { label: 'Santa María Palapa', value: 'Santa María Palapa' },
  { label: 'Santa María Pápalo', value: 'Santa María Pápalo' },
  { label: 'Santa María Petapa', value: 'Santa María Petapa' },
  { label: 'Santa María Pipioltepec (Pipioltepec)', value: 'Santa María Pipioltepec (Pipioltepec)' },
  { label: 'Santa María Puxmetacán', value: 'Santa María Puxmetacán' },
  { label: 'Santa María Quelites', value: 'Santa María Quelites' },
  { label: 'Santa María Quiegolani', value: 'Santa María Quiegolani' },
  { label: 'Santa María Rayón', value: 'Santa María Rayón' },
  { label: 'Santa María Rivarredonda', value: 'Santa María Rivarredonda' },
  { label: 'Santa María Sola', value: 'Santa María Sola' },
  { label: 'Santa María Tatetla', value: 'Santa María Tatetla' },
  { label: 'Santa María Techachalco', value: 'Santa María Techachalco' },
  { label: 'Santa María Tecuanulco', value: 'Santa María Tecuanulco' },
  { label: 'Santa María Temaxcalapa', value: 'Santa María Temaxcalapa' },
  { label: 'Santa María Temaxcaltepec', value: 'Santa María Temaxcaltepec' },
  { label: 'Santa María Teopoxco', value: 'Santa María Teopoxco' },
  { label: 'Santa María Tepantlali', value: 'Santa María Tepantlali' },
  { label: 'Santa María Texcalac', value: 'Santa María Texcalac' },
  { label: 'Santa María Texmelucan', value: 'Santa María Texmelucan' },
  { label: 'Santa María Tiltepec', value: 'Santa María Tiltepec' },
  { label: 'Santa María Tlalixtac', value: 'Santa María Tlalixtac' },
  { label: 'Santa María Tlalmimilolpan', value: 'Santa María Tlalmimilolpan' },
  { label: 'Santa María Tonameca', value: 'Santa María Tonameca' },
  { label: 'Santa María Totoltepec', value: 'Santa María Totoltepec' },
  { label: 'Santa María Transpontina', value: 'Santa María Transpontina' },
  { label: 'Santa María Urapicho', value: 'Santa María Urapicho' },
  { label: 'Santa María Velato', value: 'Santa María Velato' },
  { label: 'Santa María Visitación', value: 'Santa María Visitación' },
  { label: 'Santa María Xadani', value: 'Santa María Xadani' },
  { label: 'Santa María Xigui', value: 'Santa María Xigui' },
  { label: 'Santa María Xonacatepec', value: 'Santa María Xonacatepec' },
  { label: 'Santa María Yavesía', value: 'Santa María Yavesía' },
  { label: 'Santa María Yolotepec', value: 'Santa María Yolotepec' },
  { label: 'Santa María Yucuhiti', value: 'Santa María Yucuhiti' },
  { label: 'Santa María Yucunicoco', value: 'Santa María Yucunicoco' },
  { label: 'Santa María Zacatepec', value: 'Santa María Zacatepec' },
  { label: 'Santa María Zacatepec', value: 'Santa María Zacatepec' },
  { label: 'Santa María Zaniza', value: 'Santa María Zaniza' },
  { label: 'Santa María Zapotitlán', value: 'Santa María Zapotitlán' },
  { label: 'Santa María Zolotepec', value: 'Santa María Zolotepec' },
  { label: 'Santa María Zoquitlán', value: 'Santa María Zoquitlán' },
  { label: 'Santa Mariana', value: 'Santa Mariana' },
  { label: 'Santa Marina', value: 'Santa Marina' },
  { label: 'Santa Marina Del Rey', value: 'Santa Marina Del Rey' },
  { label: 'Santa Marina Salina', value: 'Santa Marina Salina' },
  { label: 'Santa Marinella', value: 'Santa Marinella' },
  { label: 'Santa Marinha', value: 'Santa Marinha' },
  { label: 'Santa Marta', value: 'Santa Marta' },
  { label: 'Santa Marta', value: 'Santa Marta' },
  { label: 'Santa Marta', value: 'Santa Marta' },
  { label: 'Santa Marta', value: 'Santa Marta' },
  { label: 'Santa Marta De Magasca', value: 'Santa Marta De Magasca' },
  { label: 'Santa Marta De Penaguião', value: 'Santa Marta De Penaguião' },
  { label: 'Santa Marta De Tormes', value: 'Santa Marta De Tormes' },
  { label: 'Santa Marta Del Cerro', value: 'Santa Marta Del Cerro' },
  { label: 'Santa Martha', value: 'Santa Martha' },
  { label: 'Santa Martha', value: 'Santa Martha' },
  { label: 'Santa Martha', value: 'Santa Martha' },
  { label: 'Santa Martha Chichihualtepec', value: 'Santa Martha Chichihualtepec' },
  { label: 'Santa Martha Hidalgo', value: 'Santa Martha Hidalgo' },
  { label: 'Santa Matilde', value: 'Santa Matilde' },
  { label: 'Santa Mercedes', value: 'Santa Mercedes' },
  { label: 'Santa Monica', value: 'Santa Monica' },
  { label: 'Santa Monica', value: 'Santa Monica' },
  { label: 'Santa Monica', value: 'Santa Monica' },
  { label: 'Santa Monica', value: 'Santa Monica' },
  { label: 'Santa Monica', value: 'Santa Monica' },
  { label: 'Santa Monica', value: 'Santa Monica' },
  { label: 'Santa Monica', value: 'Santa Monica' },
  { label: 'Santa Mónica', value: 'Santa Mónica' },
  { label: 'Santa Mónica', value: 'Santa Mónica' },
  { label: 'Santa Mónica', value: 'Santa Mónica' },
  { label: 'Santa Mônica', value: 'Santa Mônica' },
  { label: 'Santa Monica-Cella', value: 'Santa Monica-Cella' },
  { label: 'Santa Ninfa', value: 'Santa Ninfa' },
  { label: 'Santa Nino', value: 'Santa Nino' },
  { label: 'Santa Olalla', value: 'Santa Olalla' },
  { label: 'Santa Olalla De Bureba', value: 'Santa Olalla De Bureba' },
  { label: 'Santa Olalla Del Cala', value: 'Santa Olalla Del Cala' },
  { label: 'Santa Oliva', value: 'Santa Oliva' },
  { label: 'Santa Paolina', value: 'Santa Paolina' },
  { label: 'Santa Pau', value: 'Santa Pau' },
  { label: 'Santa Paula', value: 'Santa Paula' },
  { label: 'Santa Paz', value: 'Santa Paz' },
  { label: 'Santa Perpètua De Mogoda', value: 'Santa Perpètua De Mogoda' },
  { label: 'Santa Pola', value: 'Santa Pola' },
  { label: 'Santa Ponsa', value: 'Santa Ponsa' },
  { label: 'Santa Praxedes', value: 'Santa Praxedes' },
  { label: 'Santa Praxedes', value: 'Santa Praxedes' },
  { label: 'Santa Quitéria', value: 'Santa Quitéria' },
  { label: 'Santa Quitéria Do Maranhão', value: 'Santa Quitéria Do Maranhão' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita', value: 'Santa Rita' },
  { label: 'Santa Rita Aplaya', value: 'Santa Rita Aplaya' },
  { label: 'Santa Rita Arriba', value: 'Santa Rita Arriba' },
  { label: 'Santa Rita Copan', value: 'Santa Rita Copan' },
  { label: 'Santa Rita De Caldas', value: 'Santa Rita De Caldas' },
  { label: 'Santa Rita De Cássia', value: 'Santa Rita De Cássia' },
  { label: 'Santa Rita De Ibitipoca', value: 'Santa Rita De Ibitipoca' },
  { label: 'Santa Rita De Jacutinga', value: 'Santa Rita De Jacutinga' },
  { label: 'Santa Rita De Minas', value: 'Santa Rita De Minas' },
  { label: 'Santa Rita Do Araguaia', value: 'Santa Rita Do Araguaia' },
  { label: 'Santa Rita Do Itueto', value: 'Santa Rita Do Itueto' },
  { label: 'Santa Rita Do Novo Destino', value: 'Santa Rita Do Novo Destino' },
  { label: 'Santa Rita Do Pardo', value: 'Santa Rita Do Pardo' },
  { label: 'Santa Rita Do Passa Quatro', value: 'Santa Rita Do Passa Quatro' },
  { label: 'Santa Rita Do Sapucaí', value: 'Santa Rita Do Sapucaí' },
  { label: 'Santa Rita Do Tocantins', value: 'Santa Rita Do Tocantins' },
  { label: 'Santa Rita Do Trivelato', value: 'Santa Rita Do Trivelato' },
  { label: 'Santa Rita Doeste', value: 'Santa Rita Doeste' },
  { label: 'Santa Rita Tlahuapan', value: 'Santa Rita Tlahuapan' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa', value: 'Santa Rosa' },
  { label: 'Santa Rosa (El Huizache)', value: 'Santa Rosa (El Huizache)' },
  { label: 'Santa Rosa (Santa Bárbara)', value: 'Santa Rosa (Santa Bárbara)' },
  { label: 'Santa Rosa Abata', value: 'Santa Rosa Abata' },
  { label: 'Santa Rosa Caxtlahuaca', value: 'Santa Rosa Caxtlahuaca' },
  { label: 'Santa Rosa County', value: 'Santa Rosa County' },
  { label: 'Santa Rosa Da Serra', value: 'Santa Rosa Da Serra' },
  { label: 'Santa Rosa De Aguán', value: 'Santa Rosa De Aguán' },
  { label: 'Santa Rosa De Cabal', value: 'Santa Rosa De Cabal' },
  { label: 'Santa Rosa De Calamuchita', value: 'Santa Rosa De Calamuchita' },
  { label: 'Santa Rosa De Copán', value: 'Santa Rosa De Copán' },
  { label: 'Santa Rosa De Goiás', value: 'Santa Rosa De Goiás' },
  { label: 'Santa Rosa De Lima', value: 'Santa Rosa De Lima' },
  { label: 'Santa Rosa De Lima', value: 'Santa Rosa De Lima' },
  { label: 'Santa Rosa De Lima', value: 'Santa Rosa De Lima' },
  { label: 'Santa Rosa De Lima', value: 'Santa Rosa De Lima' },
  { label: 'Santa Rosa De Lima', value: 'Santa Rosa De Lima' },
  { label: 'Santa Rosa De Lima', value: 'Santa Rosa De Lima' },
  { label: 'Santa Rosa De Lima', value: 'Santa Rosa De Lima' },
  { label: 'Santa Rosa De Lima', value: 'Santa Rosa De Lima' },
  { label: 'Santa Rosa De Osos', value: 'Santa Rosa De Osos' },
  { label: 'Santa Rosa De Río Primero', value: 'Santa Rosa De Río Primero' },
  { label: 'Santa Rosa De Rivas', value: 'Santa Rosa De Rivas' },
  { label: 'Santa Rosa De Tastil', value: 'Santa Rosa De Tastil' },
  { label: 'Santa Rosa De Viterbo', value: 'Santa Rosa De Viterbo' },
  { label: 'Santa Rosa De Viterbo', value: 'Santa Rosa De Viterbo' },
  { label: 'Santa Rosa Del Conlara', value: 'Santa Rosa Del Conlara' },
  { label: 'Santa Rosa Del Peñón', value: 'Santa Rosa Del Peñón' },
  { label: 'Santa Rosa Del Sara', value: 'Santa Rosa Del Sara' },
  { label: 'Santa Rosa Del Sur', value: 'Santa Rosa Del Sur' },
  { label: 'Santa Rosa Do Piauí', value: 'Santa Rosa Do Piauí' },
  { label: 'Santa Rosa Do Purus', value: 'Santa Rosa Do Purus' },
  { label: 'Santa Rosa Do Sul', value: 'Santa Rosa Do Sul' },
  { label: 'Santa Rosa Do Tocantins', value: 'Santa Rosa Do Tocantins' },
  { label: 'Santa Rosa Jauregui', value: 'Santa Rosa Jauregui' },
  { label: 'Santa Rosa Loma Larga', value: 'Santa Rosa Loma Larga' },
  { label: 'Santa Rosa Los Angeles', value: 'Santa Rosa Los Angeles' },
  { label: 'Santa Rosa Misiones', value: 'Santa Rosa Misiones' },
  { label: 'Santa Rosa Segundo', value: 'Santa Rosa Segundo' },
  { label: 'Santa Rosa Sur', value: 'Santa Rosa Sur' },
  { label: 'Santa Rosa Treinta', value: 'Santa Rosa Treinta' },
  { label: 'Santa Rosa Xajay', value: 'Santa Rosa Xajay' },
  { label: 'Santa Rosalia', value: 'Santa Rosalia' },
  { label: 'Santa Rosalía', value: 'Santa Rosalía' },
  { label: 'Santa Rosalía', value: 'Santa Rosalía' },
  { label: 'Santa Rosalía Jalisco', value: 'Santa Rosalía Jalisco' },
  { label: 'Santa Rufina', value: 'Santa Rufina' },
  { label: 'Santa Salete', value: 'Santa Salete' },
  { label: 'Santa Severina', value: 'Santa Severina' },
  { label: 'Santa Sofia', value: 'Santa Sofia' },
  { label: 'Santa Sofía', value: 'Santa Sofía' },
  { label: 'Santa Sofia Depiro', value: 'Santa Sofia Depiro' },
  { label: 'Santa Susana', value: 'Santa Susana' },
  { label: 'Santa Susanna', value: 'Santa Susanna' },
  { label: 'Santa Sylvina', value: 'Santa Sylvina' },
  { label: 'Santa Tecla', value: 'Santa Tecla' },
  { label: 'Santa Tecla-Castelpagano', value: 'Santa Tecla-Castelpagano' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa', value: 'Santa Teresa' },
  { label: 'Santa Teresa Del Tuy', value: 'Santa Teresa Del Tuy' },
  { label: 'Santa Teresa Di Riva', value: 'Santa Teresa Di Riva' },
  { label: 'Santa Teresa First', value: 'Santa Teresa First' },
  { label: 'Santa Teresa First', value: 'Santa Teresa First' },
  { label: 'Santa Teresa Gallura', value: 'Santa Teresa Gallura' },
  { label: 'Santa Teresinha', value: 'Santa Teresinha' },
  { label: 'Santa Teresita', value: 'Santa Teresita' },
  { label: 'Santa Teresita', value: 'Santa Teresita' },
  { label: 'Santa Teresita', value: 'Santa Teresita' },
  { label: 'Santa Teresita', value: 'Santa Teresita' },
  { label: 'Santa Teresita', value: 'Santa Teresita' },
  { label: 'Santa Teresita De Don Diego', value: 'Santa Teresita De Don Diego' },
  { label: 'Santa Tereza', value: 'Santa Tereza' },
  { label: 'Santa Tereza De Goiás', value: 'Santa Tereza De Goiás' },
  { label: 'Santa Tereza Do Oeste', value: 'Santa Tereza Do Oeste' },
  { label: 'Santa Tereza Do Tocantins', value: 'Santa Tereza Do Tocantins' },
  { label: 'Santa Terezinha', value: 'Santa Terezinha' },
  { label: 'Santa Terezinha', value: 'Santa Terezinha' },
  { label: 'Santa Terezinha', value: 'Santa Terezinha' },
  { label: 'Santa Terezinha', value: 'Santa Terezinha' },
  { label: 'Santa Terezinha De Goiás', value: 'Santa Terezinha De Goiás' },
  { label: 'Santa Terezinha De Itaipu', value: 'Santa Terezinha De Itaipu' },
  { label: 'Santa Terezinha Do Progresso', value: 'Santa Terezinha Do Progresso' },
  { label: 'Santa Terezinha Do Tocantins', value: 'Santa Terezinha Do Tocantins' },
  { label: 'Santa Úrsula', value: 'Santa Úrsula' },
  { label: 'Santa Úrsula', value: 'Santa Úrsula' },
  { label: 'Santa Valburga', value: 'Santa Valburga' },
  { label: 'Santa Venera', value: 'Santa Venera' },
  { label: 'Santa Venerina', value: 'Santa Venerina' },
  { label: 'Santa Venetia', value: 'Santa Venetia' },
  { label: 'Santa Verónica', value: 'Santa Verónica' },
  { label: 'Santa Vitória', value: 'Santa Vitória' },
  { label: 'Santa Vitória Do Palmar', value: 'Santa Vitória Do Palmar' },
  { label: 'Santa Vittoria', value: 'Santa Vittoria' },
  { label: 'Santa Vittoria Dalba', value: 'Santa Vittoria Dalba' },
  { label: 'Santa Ynez', value: 'Santa Ynez' },
  { label: 'Santacara', value: 'Santacara' },
  { label: 'Santacruz', value: 'Santacruz' },
  { label: 'Santadi', value: 'Santadi' },
  { label: 'Santaella', value: 'Santaella' },
  { label: 'Santagapito', value: 'Santagapito' },
  { label: 'Santagata Bolognese', value: 'Santagata Bolognese' },
  { label: 'Santagata Degoti', value: 'Santagata Degoti' },
  { label: 'Santagata Del Bianco', value: 'Santagata Del Bianco' },
  { label: 'Santagata Di Esaro', value: 'Santagata Di Esaro' },
  { label: 'Santagata Di Militello', value: 'Santagata Di Militello' },
  { label: 'Santagata Di Puglia', value: 'Santagata Di Puglia' },
  { label: 'Santagata Feltria', value: 'Santagata Feltria' },
  { label: 'Santagata Fossili', value: 'Santagata Fossili' },
  { label: 'Santagata Li Battiati', value: 'Santagata Li Battiati' },
  { label: 'Santagata Martesana', value: 'Santagata Martesana' },
  { label: 'Santagata Sui Due Golfi', value: 'Santagata Sui Due Golfi' },
  { label: 'Santagata Sul Santerno', value: 'Santagata Sul Santerno' },
  { label: 'Santagnello', value: 'Santagnello' },
  { label: 'Santagostino', value: 'Santagostino' },
  { label: 'Santalbano Stura', value: 'Santalbano Stura' },
  { label: 'Santalberto', value: 'Santalberto' },
  { label: 'Santalberto', value: 'Santalberto' },
  { label: 'Santalbino', value: 'Santalbino' },
  { label: 'Santalessio Con Vialone', value: 'Santalessio Con Vialone' },
  { label: 'Santalessio In Aspromonte', value: 'Santalessio In Aspromonte' },
  { label: 'Santalessio Siculo', value: 'Santalessio Siculo' },
  { label: 'Santalfio', value: 'Santalfio' },
  { label: 'Santaliestra Y San Quílez', value: 'Santaliestra Y San Quílez' },
  { label: 'Santa-Lucia-Di-Moriani', value: 'Santa-Lucia-Di-Moriani' },
  { label: 'Santaluz', value: 'Santaluz' },
  { label: 'Santa-Maria-Di-Lota', value: 'Santa-Maria-Di-Lota' },
  { label: 'Sântămăria-Orlea', value: 'Sântămăria-Orlea' },
  { label: 'Santambrogio Di Torino', value: 'Santambrogio Di Torino' },
  { label: 'Santambrogio Di Valpollicella', value: 'Santambrogio Di Valpollicella' },
  { label: 'Santambrogio Sul Garigliano', value: 'Santambrogio Sul Garigliano' },
  { label: 'Santana', value: 'Santana' },
  { label: 'Santana', value: 'Santana' },
  { label: 'Santana', value: 'Santana' },
  { label: 'Santana', value: 'Santana' },
  { label: 'Sântana', value: 'Sântana' },
  { label: 'Santana Da Azinha', value: 'Santana Da Azinha' },
  { label: 'Santana Da Boa Vista', value: 'Santana Da Boa Vista' },
  { label: 'Santana Da Ponte Pensa', value: 'Santana Da Ponte Pensa' },
  { label: 'Santana Da Vargem', value: 'Santana Da Vargem' },
  { label: 'Santana De Cataguases', value: 'Santana De Cataguases' },
  { label: 'Santana De Mangueira', value: 'Santana De Mangueira' },
  { label: 'Sântana De Mureș', value: 'Sântana De Mureș' },
  { label: 'Santana De Parnaíba', value: 'Santana De Parnaíba' },
  { label: 'Santana De Pirapama', value: 'Santana De Pirapama' },
  { label: 'Santana Do Acaraú', value: 'Santana Do Acaraú' },
  { label: 'Santana Do Araguaia', value: 'Santana Do Araguaia' },
  { label: 'Santana Do Cariri', value: 'Santana Do Cariri' },
  { label: 'Santana Do Deserto', value: 'Santana Do Deserto' },
  { label: 'Santana Do Garambéu', value: 'Santana Do Garambéu' },
  { label: 'Santana Do Ipanema', value: 'Santana Do Ipanema' },
  { label: 'Santana Do Itararé', value: 'Santana Do Itararé' },
  { label: 'Santana Do Jacaré', value: 'Santana Do Jacaré' },
  { label: 'Santana Do Livramento', value: 'Santana Do Livramento' },
  { label: 'Santana Do Livramento', value: 'Santana Do Livramento' },
  { label: 'Santana Do Manhuaçu', value: 'Santana Do Manhuaçu' },
  { label: 'Santana Do Maranhão', value: 'Santana Do Maranhão' },
  { label: 'Santana Do Matos', value: 'Santana Do Matos' },
  { label: 'Santana Do Mundaú', value: 'Santana Do Mundaú' },
  { label: 'Santana Do Paraíso', value: 'Santana Do Paraíso' },
  { label: 'Santana Do Piauí', value: 'Santana Do Piauí' },
  { label: 'Santana Do Riacho', value: 'Santana Do Riacho' },
  { label: 'Santana Do São Francisco', value: 'Santana Do São Francisco' },
  { label: 'Santana Do Seridó', value: 'Santana Do Seridó' },
  { label: 'Santana Dos Garrotes', value: 'Santana Dos Garrotes' },
  { label: 'Santana Dos Montes', value: 'Santana Dos Montes' },
  { label: 'Santanastasia', value: 'Santanastasia' },
  { label: 'Santanatolia Di Narco', value: 'Santanatolia Di Narco' },
  { label: 'Santander', value: 'Santander' },
  { label: 'Santander De Quilichao', value: 'Santander De Quilichao' },
  { label: 'Santander Jiménez', value: 'Santander Jiménez' },
  { label: 'Santander Poblacion', value: 'Santander Poblacion' },
  { label: 'Santandrà', value: 'Santandrà' },
  { label: 'Santandrea', value: 'Santandrea' },
  { label: 'Santandrea', value: 'Santandrea' },
  { label: 'Santandrea Apostolo Dello Ionio', value: 'Santandrea Apostolo Dello Ionio' },
  { label: 'Santandrea Del Garigliano', value: 'Santandrea Del Garigliano' },
  { label: 'Santandrea Di Conza', value: 'Santandrea Di Conza' },
  { label: 'Santandrea Frius', value: 'Santandrea Frius' },
  { label: 'Santandrea In Casale', value: 'Santandrea In Casale' },
  { label: 'Santandrea Ionio Marina', value: 'Santandrea Ionio Marina' },
  { label: 'Santandrea-Pizzone-Ciamprisco', value: 'Santandrea-Pizzone-Ciamprisco' },
  { label: 'Sântandrei', value: 'Sântandrei' },
  { label: 'Santangelo', value: 'Santangelo' },
  { label: 'Santangelo', value: 'Santangelo' },
  { label: 'Santangelo A Cupolo', value: 'Santangelo A Cupolo' },
  { label: 'Santangelo A Fasanella', value: 'Santangelo A Fasanella' },
  { label: 'Santangelo A Scala', value: 'Santangelo A Scala' },
  { label: 'Santangelo Allesca', value: 'Santangelo Allesca' },
  { label: 'Santangelo Dalife', value: 'Santangelo Dalife' },
  { label: 'Santangelo Dei Lombardi', value: 'Santangelo Dei Lombardi' },
  { label: 'Santangelo Del Pesco', value: 'Santangelo Del Pesco' },
  { label: 'Santangelo Di Brolo', value: 'Santangelo Di Brolo' },
  { label: 'Santangelo Di Piove Di Sacco', value: 'Santangelo Di Piove Di Sacco' },
  { label: 'Santangelo In Formis', value: 'Santangelo In Formis' },
  { label: 'Santangelo In Lizzola', value: 'Santangelo In Lizzola' },
  { label: 'Santangelo In Pontano', value: 'Santangelo In Pontano' },
  { label: 'Santangelo In Vado', value: 'Santangelo In Vado' },
  { label: 'Santangelo In Villa-Giglio', value: 'Santangelo In Villa-Giglio' },
  { label: 'Santangelo Le Fratte', value: 'Santangelo Le Fratte' },
  { label: 'Santangelo Limosano', value: 'Santangelo Limosano' },
  { label: 'Santangelo Lodigiano', value: 'Santangelo Lodigiano' },
  { label: 'Santangelo Lomellina', value: 'Santangelo Lomellina' },
  { label: 'Santangelo Muxaro', value: 'Santangelo Muxaro' },
  { label: 'Santangelo Romano', value: 'Santangelo Romano' },
  { label: 'Santanna', value: 'Santanna' },
  { label: 'Santanna Arresi', value: 'Santanna Arresi' },
  { label: 'Santanna Dalfaedo', value: 'Santanna Dalfaedo' },
  { label: 'Santanópolis', value: 'Santanópolis' },
  { label: 'Santantimo', value: 'Santantimo' },
  { label: 'Santantioco', value: 'Santantioco' },
  { label: 'Santantonino Di Susa', value: 'Santantonino Di Susa' },
  { label: 'Santantonio', value: 'Santantonio' },
  { label: 'Santantonio', value: 'Santantonio' },
  { label: 'Santantonio', value: 'Santantonio' },
  { label: 'Santantonio', value: 'Santantonio' },
  { label: 'Santantonio Abate', value: 'Santantonio Abate' },
  { label: 'Santantonio Di Gallura', value: 'Santantonio Di Gallura' },
  { label: 'Santanyí', value: 'Santanyí' },
  { label: 'Santapollinare', value: 'Santapollinare' },
  { label: 'Santapollinare', value: 'Santapollinare' },
  { label: 'Santaquin', value: 'Santaquin' },
  { label: 'Santarcangelo', value: 'Santarcangelo' },
  { label: 'Santarcangelo', value: 'Santarcangelo' },
  { label: 'Santarcangelo Trimonte', value: 'Santarcangelo Trimonte' },
  { label: 'Santarém', value: 'Santarém' },
  { label: 'Santarém', value: 'Santarém' },
  { label: 'Santarém Novo', value: 'Santarém Novo' },
  { label: 'Santarpino', value: 'Santarpino' },
  { label: 'Santarsenio', value: 'Santarsenio' },
  { label: 'Santas Martas', value: 'Santas Martas' },
  { label: 'Santău', value: 'Santău' },
  { label: 'Sante Marie', value: 'Sante Marie' },
  { label: 'Santec', value: 'Santec' },
  { label: 'Santed', value: 'Santed' },
  { label: 'Santee', value: 'Santee' },
  { label: 'Santegidio Alla Vibrata', value: 'Santegidio Alla Vibrata' },
  { label: 'Santegidio Del Monte Albino', value: 'Santegidio Del Monte Albino' },
  { label: 'Santelena', value: 'Santelena' },
  { label: 'Santelena Irpina', value: 'Santelena Irpina' },
  { label: 'Santelena Sannita', value: 'Santelena Sannita' },
  { label: 'Santelia', value: 'Santelia' },
  { label: 'Santelia A Pianisi', value: 'Santelia A Pianisi' },
  { label: 'Santelia Fiumerapido', value: 'Santelia Fiumerapido' },
  { label: 'Santelpidio A Mare', value: 'Santelpidio A Mare' },
  { label: 'Santena', value: 'Santena' },
  { label: 'Santeny', value: 'Santeny' },
  { label: 'Santeramo In Colle', value: 'Santeramo In Colle' },
  { label: 'Santerno', value: 'Santerno' },
  { label: 'Santervás De Campos', value: 'Santervás De Campos' },
  { label: 'Santervás De La Vega', value: 'Santervás De La Vega' },
  { label: 'Santes', value: 'Santes' },
  { label: 'Santeufemia A Maiella', value: 'Santeufemia A Maiella' },
  { label: 'Santeufemia Daspromonte', value: 'Santeufemia Daspromonte' },
  { label: 'Santeusanio Del Sangro', value: 'Santeusanio Del Sangro' },
  { label: 'Santeusanio Forconese', value: 'Santeusanio Forconese' },
  { label: 'Santhià', value: 'Santhià' },
  { label: 'Santi Cosma E Damiano', value: 'Santi Cosma E Damiano' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago', value: 'Santiago' },
  { label: 'Santiago Acatlán', value: 'Santiago Acatlán' },
  { label: 'Santiago Acayutlán', value: 'Santiago Acayutlán' },
  { label: 'Santiago Acozac', value: 'Santiago Acozac' },
  { label: 'Santiago Acutzilapan', value: 'Santiago Acutzilapan' },
  { label: 'Santiago Alseseca', value: 'Santiago Alseseca' },
  { label: 'Santiago Amoltepec', value: 'Santiago Amoltepec' },
  { label: 'Santiago Analco', value: 'Santiago Analco' },
  { label: 'Santiago Apostol', value: 'Santiago Apostol' },
  { label: 'Santiago Astata', value: 'Santiago Astata' },
  { label: 'Santiago Atitlán', value: 'Santiago Atitlán' },
  { label: 'Santiago Atzitzihuacán', value: 'Santiago Atzitzihuacán' },
  { label: 'Santiago Ayuquililla', value: 'Santiago Ayuquililla' },
  { label: 'Santiago Azajo', value: 'Santiago Azajo' },
  { label: 'Santiago Bayacora', value: 'Santiago Bayacora' },
  { label: 'Santiago Cacaloxtepec', value: 'Santiago Cacaloxtepec' },
  { label: 'Santiago Capitiro', value: 'Santiago Capitiro' },
  { label: 'Santiago Casandeje', value: 'Santiago Casandeje' },
  { label: 'Santiago Centro', value: 'Santiago Centro' },
  { label: 'Santiago Chazumba', value: 'Santiago Chazumba' },
  { label: 'Santiago Chilixtlahuaca', value: 'Santiago Chilixtlahuaca' },
  { label: 'Santiago Chimalpa (Chimalpa)', value: 'Santiago Chimalpa (Chimalpa)' },
  { label: 'Santiago Chimaltenango', value: 'Santiago Chimaltenango' },
  { label: 'Santiago Choápam', value: 'Santiago Choápam' },
  { label: 'Santiago Citendejé', value: 'Santiago Citendejé' },
  { label: 'Santiago Coachochitlan', value: 'Santiago Coachochitlan' },
  { label: 'Santiago Coltzingo', value: 'Santiago Coltzingo' },
  { label: 'Santiago Comaltepec', value: 'Santiago Comaltepec' },
  { label: 'Santiago Cuaula', value: 'Santiago Cuaula' },
  { label: 'Santiago Cuautenco', value: 'Santiago Cuautenco' },
  { label: 'Santiago Cuautlalpan', value: 'Santiago Cuautlalpan' },
  { label: 'Santiago Cuixtla', value: 'Santiago Cuixtla' },
  { label: 'Santiago De Alcántara', value: 'Santiago De Alcántara' },
  { label: 'Santiago De Anaya', value: 'Santiago De Anaya' },
  { label: 'Santiago De Baney', value: 'Santiago De Baney' },
  { label: 'Santiago De Calatrava', value: 'Santiago De Calatrava' },
  { label: 'Santiago De Cao', value: 'Santiago De Cao' },
  { label: 'Santiago De Chuco', value: 'Santiago De Chuco' },
  { label: 'Santiago De Compostela', value: 'Santiago De Compostela' },
  { label: 'Santiago De Cuba', value: 'Santiago De Cuba' },
  { label: 'Santiago De Cuenda', value: 'Santiago De Cuenda' },
  { label: 'Santiago De La Peña', value: 'Santiago De La Peña' },
  { label: 'Santiago De La Puebla', value: 'Santiago De La Puebla' },
  { label: 'Santiago De La Ribera', value: 'Santiago De La Ribera' },
  { label: 'Santiago De Las Vegas', value: 'Santiago De Las Vegas' },
  { label: 'Santiago De Los Caballeros', value: 'Santiago De Los Caballeros' },
  { label: 'Santiago De María', value: 'Santiago De María' },
  { label: 'Santiago De Pochotitán', value: 'Santiago De Pochotitán' },
  { label: 'Santiago De Querétaro', value: 'Santiago De Querétaro' },
  { label: 'Santiago De Surco', value: 'Santiago De Surco' },
  { label: 'Santiago De Tolú', value: 'Santiago De Tolú' },
  { label: 'Santiago De Veraguas', value: 'Santiago De Veraguas' },
  { label: 'Santiago Del Campo', value: 'Santiago Del Campo' },
  { label: 'Santiago Del Collado', value: 'Santiago Del Collado' },
  { label: 'Santiago Del Estero', value: 'Santiago Del Estero' },
  { label: 'Santiago Del Monte', value: 'Santiago Del Monte' },
  { label: 'Santiago Del Río', value: 'Santiago Del Río' },
  { label: 'Santiago Del Teide', value: 'Santiago Del Teide' },
  { label: 'Santiago Del Tormes', value: 'Santiago Del Tormes' },
  { label: 'Santiago Del Torno', value: 'Santiago Del Torno' },
  { label: 'Santiago Do Cacém', value: 'Santiago Do Cacém' },
  { label: 'Santiago Do Sul', value: 'Santiago Do Sul' },
  { label: 'Santiago Dos Velhos', value: 'Santiago Dos Velhos' },
  { label: 'Santiago El Pinar', value: 'Santiago El Pinar' },
  { label: 'Santiago Etla', value: 'Santiago Etla' },
  { label: 'Santiago Huajolotitlán', value: 'Santiago Huajolotitlán' },
  { label: 'Santiago Huauclilla', value: 'Santiago Huauclilla' },
  { label: 'Santiago Huaxolotipac', value: 'Santiago Huaxolotipac' },
  { label: 'Santiago Huitlapaltepec', value: 'Santiago Huitlapaltepec' },
  { label: 'Santiago Ihuitlán Plumas', value: 'Santiago Ihuitlán Plumas' },
  { label: 'Santiago Ixcuintepec', value: 'Santiago Ixcuintepec' },
  { label: 'Santiago Ixcuintla', value: 'Santiago Ixcuintla' },
  { label: 'Santiago Ixtaltepec', value: 'Santiago Ixtaltepec' },
  { label: 'Santiago Ixtayutla', value: 'Santiago Ixtayutla' },
  { label: 'Santiago Jaltepec', value: 'Santiago Jaltepec' },
  { label: 'Santiago Jicayán', value: 'Santiago Jicayán' },
  { label: 'Santiago Jocotepec', value: 'Santiago Jocotepec' },
  { label: 'Santiago Juxtlahuaca', value: 'Santiago Juxtlahuaca' },
  { label: 'Santiago Lachiguiri', value: 'Santiago Lachiguiri' },
  { label: 'Santiago Lalopa', value: 'Santiago Lalopa' },
  { label: 'Santiago Laollaga', value: 'Santiago Laollaga' },
  { label: 'Santiago Laxopa', value: 'Santiago Laxopa' },
  { label: 'Santiago Llano Grande', value: 'Santiago Llano Grande' },
  { label: 'Santiago Malacatepec', value: 'Santiago Malacatepec' },
  { label: 'Santiago Mamalhuazuca', value: 'Santiago Mamalhuazuca' },
  { label: 'Santiago Maravatío', value: 'Santiago Maravatío' },
  { label: 'Santiago Matatlán', value: 'Santiago Matatlán' },
  { label: 'Santiago Michac', value: 'Santiago Michac' },
  { label: 'Santiago Millas', value: 'Santiago Millas' },
  { label: 'Santiago Miltepec', value: 'Santiago Miltepec' },
  { label: 'Santiago Momoxpan', value: 'Santiago Momoxpan' },
  { label: 'Santiago Nejapilla', value: 'Santiago Nejapilla' },
  { label: 'Santiago Niltepec', value: 'Santiago Niltepec' },
  { label: 'Santiago Nonualco', value: 'Santiago Nonualco' },
  { label: 'Santiago Nundiche', value: 'Santiago Nundiche' },
  { label: 'Santiago Nuyoó', value: 'Santiago Nuyoó' },
  { label: 'Santiago Oxtempan', value: 'Santiago Oxtempan' },
  { label: 'Santiago Oxthoc', value: 'Santiago Oxthoc' },
  { label: 'Santiago Oxtotitlán', value: 'Santiago Oxtotitlán' },
  { label: 'Santiago Papasquiaro', value: 'Santiago Papasquiaro' },
  { label: 'Santiago Pojcol', value: 'Santiago Pojcol' },
  { label: 'Santiago Puriatzícuaro', value: 'Santiago Puriatzícuaro' },
  { label: 'Santiago Puringla', value: 'Santiago Puringla' },
  { label: 'Santiago Quiavicuzas', value: 'Santiago Quiavicuzas' },
  { label: 'Santiago Sacatepéquez', value: 'Santiago Sacatepéquez' },
  { label: 'Santiago Sochiapan', value: 'Santiago Sochiapan' },
  { label: 'Santiago Suchilquitongo', value: 'Santiago Suchilquitongo' },
  { label: 'Santiago Tangamandapio', value: 'Santiago Tangamandapio' },
  { label: 'Santiago Tejocotillos', value: 'Santiago Tejocotillos' },
  { label: 'Santiago Temple', value: 'Santiago Temple' },
  { label: 'Santiago Tenango', value: 'Santiago Tenango' },
  { label: 'Santiago Tenango', value: 'Santiago Tenango' },
  { label: 'Santiago Tepatlaxco', value: 'Santiago Tepatlaxco' },
  { label: 'Santiago Tepeticpac', value: 'Santiago Tepeticpac' },
  { label: 'Santiago Tepetitlán', value: 'Santiago Tepetitlán' },
  { label: 'Santiago Tepextla', value: 'Santiago Tepextla' },
  { label: 'Santiago Tepeyahualco', value: 'Santiago Tepeyahualco' },
  { label: 'Santiago Tepopula', value: 'Santiago Tepopula' },
  { label: 'Santiago Tetepec', value: 'Santiago Tetepec' },
  { label: 'Santiago Texcalcingo', value: 'Santiago Texcalcingo' },
  { label: 'Santiago Textitlán', value: 'Santiago Textitlán' },
  { label: 'Santiago Teyahualco', value: 'Santiago Teyahualco' },
  { label: 'Santiago Tezontlale', value: 'Santiago Tezontlale' },
  { label: 'Santiago Tianguistenco De Galeana', value: 'Santiago Tianguistenco De Galeana' },
  { label: 'Santiago Tilantongo', value: 'Santiago Tilantongo' },
  { label: 'Santiago Tilapa', value: 'Santiago Tilapa' },
  { label: 'Santiago Tílapa', value: 'Santiago Tílapa' },
  { label: 'Santiago Tillo', value: 'Santiago Tillo' },
  { label: 'Santiago Tlacochcalco', value: 'Santiago Tlacochcalco' },
  { label: 'Santiago Tlacotepec', value: 'Santiago Tlacotepec' },
  { label: 'Santiago Tlaltepoxco', value: 'Santiago Tlaltepoxco' },
  { label: 'Santiago Tlapacoya', value: 'Santiago Tlapacoya' },
  { label: 'Santiago Tlapanaloya', value: 'Santiago Tlapanaloya' },
  { label: 'Santiago Tlautla', value: 'Santiago Tlautla' },
  { label: 'Santiago Tolman', value: 'Santiago Tolman' },
  { label: 'Santiago Tulantepec', value: 'Santiago Tulantepec' },
  { label: 'Santiago Tuxtla', value: 'Santiago Tuxtla' },
  { label: 'Santiago Undameo', value: 'Santiago Undameo' },
  { label: 'Santiago Vázquez', value: 'Santiago Vázquez' },
  { label: 'Santiago Xanica', value: 'Santiago Xanica' },
  { label: 'Santiago Yaitepec', value: 'Santiago Yaitepec' },
  { label: 'Santiago Yancuictlalpan', value: 'Santiago Yancuictlalpan' },
  { label: 'Santiago Yancuitlalpan', value: 'Santiago Yancuitlalpan' },
  { label: 'Santiago Yeché', value: 'Santiago Yeché' },
  { label: 'Santiago Yogana', value: 'Santiago Yogana' },
  { label: 'Santiago Yolomécatl', value: 'Santiago Yolomécatl' },
  { label: 'Santiago Yosondúa', value: 'Santiago Yosondúa' },
  { label: 'Santiago Zacatepec', value: 'Santiago Zacatepec' },
  { label: 'Santiago Zacualuca', value: 'Santiago Zacualuca' },
  { label: 'Santiago Zoochila', value: 'Santiago Zoochila' },
  { label: 'Santiago-Pontones', value: 'Santiago-Pontones' },
  { label: 'Santiaguillo', value: 'Santiaguillo' },
  { label: 'Santiaguito', value: 'Santiaguito' },
  { label: 'Santiaguito (Santiaguito De Velázquez)', value: 'Santiaguito (Santiaguito De Velázquez)' },
  { label: 'Santiaguito Tlalcilalcalli', value: 'Santiaguito Tlalcilalcalli' },
  { label: 'Santibáñez De Béjar', value: 'Santibáñez De Béjar' },
  { label: 'Santibáñez De Ecla', value: 'Santibáñez De Ecla' },
  { label: 'Santibáñez De Esgueva', value: 'Santibáñez De Esgueva' },
  { label: 'Santibáñez De La Peña', value: 'Santibáñez De La Peña' },
  { label: 'Santibáñez De La Sierra', value: 'Santibáñez De La Sierra' },
  { label: 'Santibáñez De Tera', value: 'Santibáñez De Tera' },
  { label: 'Santibáñez De Valcorba', value: 'Santibáñez De Valcorba' },
  { label: 'Santibáñez De Vidriales', value: 'Santibáñez De Vidriales' },
  { label: 'Santibáñez Del Val', value: 'Santibáñez Del Val' },
  { label: 'Santibáñez El Alto', value: 'Santibáñez El Alto' },
  { label: 'Santibáñez El Bajo', value: 'Santibáñez El Bajo' },
  { label: 'Santilario Dello Ionio', value: 'Santilario Dello Ionio' },
  { label: 'Santilario Denza', value: 'Santilario Denza' },
  { label: 'Santillán', value: 'Santillán' },
  { label: 'Santillana', value: 'Santillana' },
  { label: 'Santillana Del Mar', value: 'Santillana Del Mar' },
  { label: 'Sântimbru', value: 'Sântimbru' },
  { label: 'Sântimbru', value: 'Sântimbru' },
  { label: 'Santino', value: 'Santino' },
  { label: 'Sântioana', value: 'Sântioana' },
  { label: 'Sântioana De Mureș', value: 'Sântioana De Mureș' },
  { label: 'Sântion', value: 'Sântion' },
  { label: 'Santiponce', value: 'Santiponce' },
  { label: 'Santippolito', value: 'Santippolito' },
  { label: 'Santipur', value: 'Santipur' },
  { label: 'Santisimo Rosario', value: 'Santisimo Rosario' },
  { label: 'Santiso', value: 'Santiso' },
  { label: 'Santisteban Del Puerto', value: 'Santisteban Del Puerto' },
  { label: 'Santiurde De Reinosa', value: 'Santiurde De Reinosa' },
  { label: 'Santiurde De Toranzo', value: 'Santiurde De Toranzo' },
  { label: 'Santiuste', value: 'Santiuste' },
  { label: 'Santiuste De Pedraza', value: 'Santiuste De Pedraza' },
  { label: 'Santiuste De San Juan Bautista', value: 'Santiuste De San Juan Bautista' },
  { label: 'Santiz', value: 'Santiz' },
  { label: 'Santo Adriano', value: 'Santo Adriano' },
  { label: 'Santo Afonso', value: 'Santo Afonso' },
  { label: 'Santo Amaro', value: 'Santo Amaro' },
  { label: 'Santo Amaro Da Imperatriz', value: 'Santo Amaro Da Imperatriz' },
  { label: 'Santo Amaro Das Brotas', value: 'Santo Amaro Das Brotas' },
  { label: 'Santo Amaro Do Maranhão', value: 'Santo Amaro Do Maranhão' },
  { label: 'Santo Anastácio', value: 'Santo Anastácio' },
  { label: 'Santo André', value: 'Santo André' },
  { label: 'Santo André', value: 'Santo André' },
  { label: 'Santo André', value: 'Santo André' },
  { label: 'Santo André', value: 'Santo André' },
  { label: 'Santo Ângelo', value: 'Santo Ângelo' },
  { label: 'Santo Antão Do Tojal', value: 'Santo Antão Do Tojal' },
  { label: 'Santo Antão Do Tojal', value: 'Santo Antão Do Tojal' },
  { label: 'Santo António', value: 'Santo António' },
  { label: 'Santo Antônio', value: 'Santo Antônio' },
  { label: 'Santo Antônio Da Alegria', value: 'Santo Antônio Da Alegria' },
  { label: 'Santo Antônio Da Barra', value: 'Santo Antônio Da Barra' },
  { label: 'Santo António Da Charneca', value: 'Santo António Da Charneca' },
  { label: 'Santo Antônio Da Patrulha', value: 'Santo Antônio Da Patrulha' },
  { label: 'Santo Antônio Da Platina', value: 'Santo Antônio Da Platina' },
  { label: 'Santo Antônio Das Missões', value: 'Santo Antônio Das Missões' },
  { label: 'Santo Antônio De Goiás', value: 'Santo Antônio De Goiás' },
  { label: 'Santo Antônio De Jesus', value: 'Santo Antônio De Jesus' },
  { label: 'Santo Antônio De Lisboa', value: 'Santo Antônio De Lisboa' },
  { label: 'Santo Antônio De Pádua', value: 'Santo Antônio De Pádua' },
  { label: 'Santo Antônio De Posse', value: 'Santo Antônio De Posse' },
  { label: 'Santo Antônio Do Amparo', value: 'Santo Antônio Do Amparo' },
  { label: 'Santo Antônio Do Aracanguá', value: 'Santo Antônio Do Aracanguá' },
  { label: 'Santo Antônio Do Aventureiro', value: 'Santo Antônio Do Aventureiro' },
  { label: 'Santo Antônio Do Caiuá', value: 'Santo Antônio Do Caiuá' },
  { label: 'Santo Antônio Do Descoberto', value: 'Santo Antônio Do Descoberto' },
  { label: 'Santo Antônio Do Grama', value: 'Santo Antônio Do Grama' },
  { label: 'Santo Antônio Do Içá', value: 'Santo Antônio Do Içá' },
  { label: 'Santo Antônio Do Itambé', value: 'Santo Antônio Do Itambé' },
  { label: 'Santo Antônio Do Jacinto', value: 'Santo Antônio Do Jacinto' },
  { label: 'Santo Antônio Do Jardim', value: 'Santo Antônio Do Jardim' },
  { label: 'Santo Antônio Do Leste', value: 'Santo Antônio Do Leste' },
  { label: 'Santo Antônio Do Leverger', value: 'Santo Antônio Do Leverger' },
  { label: 'Santo Antônio Do Monte', value: 'Santo Antônio Do Monte' },
  { label: 'Santo Antônio Do Palma', value: 'Santo Antônio Do Palma' },
  { label: 'Santo Antônio Do Paraíso', value: 'Santo Antônio Do Paraíso' },
  { label: 'Santo Antônio Do Pinhal', value: 'Santo Antônio Do Pinhal' },
  { label: 'Santo Antônio Do Planalto', value: 'Santo Antônio Do Planalto' },
  { label: 'Santo Antônio Do Retiro', value: 'Santo Antônio Do Retiro' },
  { label: 'Santo Antônio Do Rio Abaixo', value: 'Santo Antônio Do Rio Abaixo' },
  { label: 'Santo Antônio Do Sudoeste', value: 'Santo Antônio Do Sudoeste' },
  { label: 'Santo Antônio Do Tauá', value: 'Santo Antônio Do Tauá' },
  { label: 'Santo António Dos Cavaleiros', value: 'Santo António Dos Cavaleiros' },
  { label: 'Santo Antônio Dos Lopes', value: 'Santo Antônio Dos Lopes' },
  { label: 'Santo Antônio Dos Milagres', value: 'Santo Antônio Dos Milagres' },
  { label: 'Santo António Dos Olivais', value: 'Santo António Dos Olivais' },
  { label: 'Santo Augusto', value: 'Santo Augusto' },
  { label: 'Santo Condestável', value: 'Santo Condestável' },
  { label: 'Santo Cristo', value: 'Santo Cristo' },
  { label: 'Santo Cristo', value: 'Santo Cristo' },
  { label: 'Santo Cristo', value: 'Santo Cristo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo', value: 'Santo Domingo' },
  { label: 'Santo Domingo Albarradas', value: 'Santo Domingo Albarradas' },
  { label: 'Santo Domingo Armenta', value: 'Santo Domingo Armenta' },
  { label: 'Santo Domingo Atoyatempan', value: 'Santo Domingo Atoyatempan' },
  { label: 'Santo Domingo Aztacameca', value: 'Santo Domingo Aztacameca' },
  { label: 'Santo Domingo Chihuitán', value: 'Santo Domingo Chihuitán' },
  { label: 'Santo Domingo De Guzmán', value: 'Santo Domingo De Guzmán' },
  { label: 'Santo Domingo De La Calzada', value: 'Santo Domingo De La Calzada' },
  { label: 'Santo Domingo De Las Palmas', value: 'Santo Domingo De Las Palmas' },
  { label: 'Santo Domingo De Las Posadas', value: 'Santo Domingo De Las Posadas' },
  { label: 'Santo Domingo De Los Colorados', value: 'Santo Domingo De Los Colorados' },
  { label: 'Santo Domingo De Morelos', value: 'Santo Domingo De Morelos' },
  { label: 'Santo Domingo De Pirón', value: 'Santo Domingo De Pirón' },
  { label: 'Santo Domingo De Silos', value: 'Santo Domingo De Silos' },
  { label: 'Santo Domingo Este', value: 'Santo Domingo Este' },
  { label: 'Santo Domingo Huehuetlán', value: 'Santo Domingo Huehuetlán' },
  { label: 'Santo Domingo Jalieza', value: 'Santo Domingo Jalieza' },
  { label: 'Santo Domingo Kesté', value: 'Santo Domingo Kesté' },
  { label: 'Santo Domingo Ocotitlán', value: 'Santo Domingo Ocotitlán' },
  { label: 'Santo Domingo Oeste', value: 'Santo Domingo Oeste' },
  { label: 'Santo Domingo Ozolotepec', value: 'Santo Domingo Ozolotepec' },
  { label: 'Santo Domingo Petapa', value: 'Santo Domingo Petapa' },
  { label: 'Santo Domingo Pueblo', value: 'Santo Domingo Pueblo' },
  { label: 'Santo Domingo Roayaga', value: 'Santo Domingo Roayaga' },
  { label: 'Santo Domingo Shomege (Shomege)', value: 'Santo Domingo Shomege (Shomege)' },
  { label: 'Santo Domingo Suchitepéquez', value: 'Santo Domingo Suchitepéquez' },
  { label: 'Santo Domingo Tehuantepec', value: 'Santo Domingo Tehuantepec' },
  { label: 'Santo Domingo Tepuxtepec', value: 'Santo Domingo Tepuxtepec' },
  { label: 'Santo Domingo Tlatayapam', value: 'Santo Domingo Tlatayapam' },
  { label: 'Santo Domingo Tomaltepec', value: 'Santo Domingo Tomaltepec' },
  { label: 'Santo Domingo Tonalá', value: 'Santo Domingo Tonalá' },
  { label: 'Santo Domingo Xenacoj', value: 'Santo Domingo Xenacoj' },
  { label: 'Santo Domingo Yanhuitlán', value: 'Santo Domingo Yanhuitlán' },
  { label: 'Santo Domingo Yodohino', value: 'Santo Domingo Yodohino' },
  { label: 'Santo Domingo Zanatepec', value: 'Santo Domingo Zanatepec' },
  { label: 'Santo Domingo-Caudilla', value: 'Santo Domingo-Caudilla' },
  { label: 'Santo Estêvão', value: 'Santo Estêvão' },
  { label: 'Santo Estêvão', value: 'Santo Estêvão' },
  { label: 'Santo Estêvão Das Galés', value: 'Santo Estêvão Das Galés' },
  { label: 'Santo Expedito', value: 'Santo Expedito' },
  { label: 'Santo Expedito Do Sul', value: 'Santo Expedito Do Sul' },
  { label: 'Santo Hipólito', value: 'Santo Hipólito' },
  { label: 'Santo Inácio', value: 'Santo Inácio' },
  { label: 'Santo Inácio Do Piauí', value: 'Santo Inácio Do Piauí' },
  { label: 'Santo Isidoro', value: 'Santo Isidoro' },
  { label: 'Santo Isidoro', value: 'Santo Isidoro' },
  { label: 'Santo Izidoro', value: 'Santo Izidoro' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño', value: 'Santo Niño' },
  { label: 'Santo Niño Aguanaval', value: 'Santo Niño Aguanaval' },
  { label: 'Santo Nombre', value: 'Santo Nombre' },
  { label: 'Santo Pipó', value: 'Santo Pipó' },
  { label: 'Santo Quintino', value: 'Santo Quintino' },
  { label: 'Santo Rosario', value: 'Santo Rosario' },
  { label: 'Santo Rosario', value: 'Santo Rosario' },
  { label: 'Santo Stefano', value: 'Santo Stefano' },
  { label: 'Santo Stefano Al Mare', value: 'Santo Stefano Al Mare' },
  { label: 'Santo Stefano Belbo', value: 'Santo Stefano Belbo' },
  { label: 'Santo Stefano Daveto', value: 'Santo Stefano Daveto' },
  { label: 'Santo Stefano Del Sole', value: 'Santo Stefano Del Sole' },
  { label: 'Santo Stefano Di Cadore', value: 'Santo Stefano Di Cadore' },
  { label: 'Santo Stefano Di Camastra', value: 'Santo Stefano Di Camastra' },
  { label: 'Santo Stefano Di Magra', value: 'Santo Stefano Di Magra' },
  { label: 'Santo Stefano Di Rogliano', value: 'Santo Stefano Di Rogliano' },
  { label: 'Santo Stefano Di Sessanio', value: 'Santo Stefano Di Sessanio' },
  { label: 'Santo Stefano In Aspromonte', value: 'Santo Stefano In Aspromonte' },
  { label: 'Santo Stefano Lodigiano', value: 'Santo Stefano Lodigiano' },
  { label: 'Santo Stefano Quisquina', value: 'Santo Stefano Quisquina' },
  { label: 'Santo Stefano Roero', value: 'Santo Stefano Roero' },
  { label: 'Santo Stefano Ticino', value: 'Santo Stefano Ticino' },
  { label: 'Santo Stefano-Bonaldo', value: 'Santo Stefano-Bonaldo' },
  { label: 'Santo Stefano-Carraie', value: 'Santo Stefano-Carraie' },
  { label: 'Santo Tirso', value: 'Santo Tirso' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomas', value: 'Santo Tomas' },
  { label: 'Santo Tomás', value: 'Santo Tomás' },
  { label: 'Santo Tomás', value: 'Santo Tomás' },
  { label: 'Santo Tomás', value: 'Santo Tomás' },
  { label: 'Santo Tomás', value: 'Santo Tomás' },
  { label: 'Santo Tomás', value: 'Santo Tomás' },
  { label: 'Santo Tomás', value: 'Santo Tomás' },
  { label: 'Santo Tomás Atzingo', value: 'Santo Tomás Atzingo' },
  { label: 'Santo Tomás Chautla', value: 'Santo Tomás Chautla' },
  { label: 'Santo Tomás Chiconautla', value: 'Santo Tomás Chiconautla' },
  { label: 'Santo Tomás De Jánico', value: 'Santo Tomás De Jánico' },
  { label: 'Santo Tomás De Los Plátanos', value: 'Santo Tomás De Los Plátanos' },
  { label: 'Santo Tomás Del Norte', value: 'Santo Tomás Del Norte' },
  { label: 'Santo Tomás Huatzindeo', value: 'Santo Tomás Huatzindeo' },
  { label: 'Santo Tomás Jalieza', value: 'Santo Tomás Jalieza' },
  { label: 'Santo Tomás La Concordia', value: 'Santo Tomás La Concordia' },
  { label: 'Santo Tomás La Unión', value: 'Santo Tomás La Unión' },
  { label: 'Santo Tomás Mazaltepec', value: 'Santo Tomás Mazaltepec' },
  { label: 'Santo Tomás Ocotepec', value: 'Santo Tomás Ocotepec' },
  { label: 'Santo Tomás Tamazulapam', value: 'Santo Tomás Tamazulapam' },
  { label: 'Santo Tomé', value: 'Santo Tomé' },
  { label: 'Santo Tomé', value: 'Santo Tomé' },
  { label: 'Santo Tomé', value: 'Santo Tomé' },
  { label: 'Santo Tomé De Zabarcos', value: 'Santo Tomé De Zabarcos' },
  { label: 'Santo Tomé Del Puerto', value: 'Santo Tomé Del Puerto' },
  { label: 'Santok', value: 'Santok' },
  { label: 'Santokhgarh', value: 'Santokhgarh' },
  { label: 'Santol', value: 'Santol' },
  { label: 'Santol', value: 'Santol' },
  { label: 'Santol', value: 'Santol' },
  { label: 'Santol', value: 'Santol' },
  { label: 'Santol', value: 'Santol' },
  { label: 'Santomenna', value: 'Santomenna' },
  { label: 'Santomera', value: 'Santomera' },
  { label: 'Santomero', value: 'Santomero' },
  { label: 'Santomobono Terme', value: 'Santomobono Terme' },
  { label: 'Santoña', value: 'Santoña' },
  { label: 'Santonofrio', value: 'Santonofrio' },
  { label: 'Santopadre', value: 'Santopadre' },
  { label: 'Santópolis Do Aguapeí', value: 'Santópolis Do Aguapeí' },
  { label: 'Santor', value: 'Santor' },
  { label: 'Santorcaz', value: 'Santorcaz' },
  { label: 'Santoreste', value: 'Santoreste' },
  { label: 'Santorso', value: 'Santorso' },
  { label: 'Santorsola', value: 'Santorsola' },
  { label: 'Santorsola Terme', value: 'Santorsola Terme' },
  { label: 'Santos', value: 'Santos' },
  { label: 'Santos De La Humosa Los', value: 'Santos De La Humosa Los' },
  { label: 'Santos De Maimona Los', value: 'Santos De Maimona Los' },
  { label: 'Santos Dumont', value: 'Santos Dumont' },
  { label: 'Santos Los', value: 'Santos Los' },
  { label: 'Santos Reyes Nopala', value: 'Santos Reyes Nopala' },
  { label: 'Santos Reyes Pápalo', value: 'Santos Reyes Pápalo' },
  { label: 'Santos Reyes Tepejillo', value: 'Santos Reyes Tepejillo' },
  { label: 'Santos Reyes Yucuná', value: 'Santos Reyes Yucuná' },
  { label: 'Santoshpur', value: 'Santoshpur' },
  { label: 'Santos-O-Velho', value: 'Santos-O-Velho' },
  { label: 'Santos-O-Velho', value: 'Santos-O-Velho' },
  { label: 'Santovenia', value: 'Santovenia' },
  { label: 'Santovenia De La Valdoncina', value: 'Santovenia De La Valdoncina' },
  { label: 'Santovenia De Pisuerga', value: 'Santovenia De Pisuerga' },
  { label: 'Santoy', value: 'Santoy' },
  { label: 'Santoyo', value: 'Santoyo' },
  { label: 'Santpedor', value: 'Santpedor' },
  { label: 'Santpoort', value: 'Santpoort' },
  { label: 'Sântu', value: 'Sântu' },
  { label: 'Santu Lussurgiu', value: 'Santu Lussurgiu' },
  { label: 'Santuario', value: 'Santuario' },
  { label: 'Santurce', value: 'Santurce' },
  { label: 'Santurde De Rioja', value: 'Santurde De Rioja' },
  { label: 'Santurdejo', value: 'Santurdejo' },
  { label: 'Santuri Community Development Block', value: 'Santuri Community Development Block' },
  { label: 'Santurtzi', value: 'Santurtzi' },
  { label: 'Santyoku', value: 'Santyoku' },
  { label: 'Sanuki-Shi', value: 'Sanuki-Shi' },
  { label: 'Sânvăsii', value: 'Sânvăsii' },
  { label: 'Sanvignes-Les-Mines', value: 'Sanvignes-Les-Mines' },
  { label: 'Sanvordem', value: 'Sanvordem' },
  { label: 'Sanwer', value: 'Sanwer' },
  { label: 'Sanxenxo', value: 'Sanxenxo' },
  { label: 'Sanya', value: 'Sanya' },
  { label: 'Sanyōonoda Shi', value: 'Sanyōonoda Shi' },
  { label: 'Sanza', value: 'Sanza' },
  { label: 'Sanzeno', value: 'Sanzeno' },
  { label: 'Sânzieni', value: 'Sânzieni' },
  { label: 'Sanzoles', value: 'Sanzoles' },
  { label: 'São Bartolomeu', value: 'São Bartolomeu' },
  { label: 'São Bartolomeu', value: 'São Bartolomeu' },
  { label: 'São Bartolomeu', value: 'São Bartolomeu' },
  { label: 'São Bartolomeu De Messines', value: 'São Bartolomeu De Messines' },
  { label: 'São Bartolomeu Dos Galegos', value: 'São Bartolomeu Dos Galegos' },
  { label: 'São Benedito', value: 'São Benedito' },
  { label: 'São Benedito Do Rio Preto', value: 'São Benedito Do Rio Preto' },
  { label: 'São Benedito Do Sul', value: 'São Benedito Do Sul' },
  { label: 'São Bentinho', value: 'São Bentinho' },
  { label: 'São Bento', value: 'São Bento' },
  { label: 'São Bento', value: 'São Bento' },
  { label: 'São Bento Abade', value: 'São Bento Abade' },
  { label: 'São Bento Do Norte', value: 'São Bento Do Norte' },
  { label: 'São Bento Do Sapucaí', value: 'São Bento Do Sapucaí' },
  { label: 'São Bento Do Sul', value: 'São Bento Do Sul' },
  { label: 'São Bento Do Tocantins', value: 'São Bento Do Tocantins' },
  { label: 'São Bento Do Trairí', value: 'São Bento Do Trairí' },
  { label: 'São Bento Do Una', value: 'São Bento Do Una' },
  { label: 'São Bernardino', value: 'São Bernardino' },
  { label: 'São Bernardo', value: 'São Bernardo' },
  { label: 'São Bernardo Do Campo', value: 'São Bernardo Do Campo' },
  { label: 'São Bonifácio', value: 'São Bonifácio' },
  { label: 'São Borja', value: 'São Borja' },
  { label: 'São Brás', value: 'São Brás' },
  { label: 'São Brás', value: 'São Brás' },
  { label: 'São Brás De Alportel', value: 'São Brás De Alportel' },
  { label: 'São Brás Do Suaçuí', value: 'São Brás Do Suaçuí' },
  { label: 'São Braz Do Piauí', value: 'São Braz Do Piauí' },
  { label: 'São Caetano De Odivelas', value: 'São Caetano De Odivelas' },
  { label: 'São Caetano Do Sul', value: 'São Caetano Do Sul' },
  { label: 'São Caitano', value: 'São Caitano' },
  { label: 'São Carlos', value: 'São Carlos' },
  { label: 'São Carlos', value: 'São Carlos' },
  { label: 'São Carlos Do Ivaí', value: 'São Carlos Do Ivaí' },
  { label: 'São Cristóvão', value: 'São Cristóvão' },
  { label: 'São Cristóvão Do Sul', value: 'São Cristóvão Do Sul' },
  { label: 'São Cristóvão E São Lourenço', value: 'São Cristóvão E São Lourenço' },
  { label: 'São Desidério', value: 'São Desidério' },
  { label: 'São Domingos', value: 'São Domingos' },
  { label: 'São Domingos', value: 'São Domingos' },
  { label: 'São Domingos', value: 'São Domingos' },
  { label: 'São Domingos', value: 'São Domingos' },
  { label: 'São Domingos', value: 'São Domingos' },
  { label: 'São Domingos', value: 'São Domingos' },
  { label: 'São Domingos Das Dores', value: 'São Domingos Das Dores' },
  { label: 'São Domingos De Benfica', value: 'São Domingos De Benfica' },
  { label: 'São Domingos De Rana', value: 'São Domingos De Rana' },
  { label: 'São Domingos De Rana', value: 'São Domingos De Rana' },
  { label: 'São Domingos Do Araguaia', value: 'São Domingos Do Araguaia' },
  { label: 'São Domingos Do Azeitão', value: 'São Domingos Do Azeitão' },
  { label: 'São Domingos Do Capim', value: 'São Domingos Do Capim' },
  { label: 'São Domingos Do Cariri', value: 'São Domingos Do Cariri' },
  { label: 'São Domingos Do Maranhão', value: 'São Domingos Do Maranhão' },
  { label: 'São Domingos Do Norte', value: 'São Domingos Do Norte' },
  { label: 'São Domingos Do Prata', value: 'São Domingos Do Prata' },
  { label: 'São Domingos Do Sul', value: 'São Domingos Do Sul' },
  { label: 'São Felipe', value: 'São Felipe' },
  { label: 'São Felipe Doeste', value: 'São Felipe Doeste' },
  { label: 'São Félix', value: 'São Félix' },
  { label: 'São Félix Da Marinha', value: 'São Félix Da Marinha' },
  { label: 'São Félix De Balsas', value: 'São Félix De Balsas' },
  { label: 'São Félix De Minas', value: 'São Félix De Minas' },
  { label: 'São Félix Do Araguaia', value: 'São Félix Do Araguaia' },
  { label: 'São Félix Do Coribe', value: 'São Félix Do Coribe' },
  { label: 'São Félix Do Piauí', value: 'São Félix Do Piauí' },
  { label: 'São Félix Do Tocantins', value: 'São Félix Do Tocantins' },
  { label: 'São Félix Do Xingu', value: 'São Félix Do Xingu' },
  { label: 'São Fernando', value: 'São Fernando' },
  { label: 'São Fidélis', value: 'São Fidélis' },
  { label: 'São Filipe', value: 'São Filipe' },
  { label: 'São Francisco', value: 'São Francisco' },
  { label: 'São Francisco', value: 'São Francisco' },
  { label: 'São Francisco', value: 'São Francisco' },
  { label: 'São Francisco', value: 'São Francisco' },
  { label: 'São Francisco De Assis', value: 'São Francisco De Assis' },
  { label: 'São Francisco De Assis Do Piauí', value: 'São Francisco De Assis Do Piauí' },
  { label: 'São Francisco De Goiás', value: 'São Francisco De Goiás' },
  { label: 'São Francisco De Itabapoana', value: 'São Francisco De Itabapoana' },
  { label: 'São Francisco De Paula', value: 'São Francisco De Paula' },
  { label: 'São Francisco De Paula', value: 'São Francisco De Paula' },
  { label: 'São Francisco De Sales', value: 'São Francisco De Sales' },
  { label: 'São Francisco Do Brejão', value: 'São Francisco Do Brejão' },
  { label: 'São Francisco Do Conde', value: 'São Francisco Do Conde' },
  { label: 'São Francisco Do Glória', value: 'São Francisco Do Glória' },
  { label: 'São Francisco Do Guaporé', value: 'São Francisco Do Guaporé' },
  { label: 'São Francisco Do Maranhão', value: 'São Francisco Do Maranhão' },
  { label: 'São Francisco Do Oeste', value: 'São Francisco Do Oeste' },
  { label: 'São Francisco Do Pará', value: 'São Francisco Do Pará' },
  { label: 'São Francisco Do Piauí', value: 'São Francisco Do Piauí' },
  { label: 'São Francisco Do Sul', value: 'São Francisco Do Sul' },
  { label: 'São Francisco Xavier', value: 'São Francisco Xavier' },
  { label: 'São Gabriel', value: 'São Gabriel' },
  { label: 'São Gabriel', value: 'São Gabriel' },
  { label: 'São Gabriel Da Cachoeira', value: 'São Gabriel Da Cachoeira' },
  { label: 'São Gabriel Da Palha', value: 'São Gabriel Da Palha' },
  { label: 'São Gabriel Do Oeste', value: 'São Gabriel Do Oeste' },
  { label: 'São Geraldo', value: 'São Geraldo' },
  { label: 'São Geraldo Da Piedade', value: 'São Geraldo Da Piedade' },
  { label: 'São Geraldo Do Araguaia', value: 'São Geraldo Do Araguaia' },
  { label: 'São Geraldo Do Baixio', value: 'São Geraldo Do Baixio' },
  { label: 'São Gonçalo', value: 'São Gonçalo' },
  { label: 'São Gonçalo Do Abaeté', value: 'São Gonçalo Do Abaeté' },
  { label: 'São Gonçalo Do Amarante', value: 'São Gonçalo Do Amarante' },
  { label: 'São Gonçalo Do Amarante', value: 'São Gonçalo Do Amarante' },
  { label: 'São Gonçalo Do Gurguéia', value: 'São Gonçalo Do Gurguéia' },
  { label: 'São Gonçalo Do Pará', value: 'São Gonçalo Do Pará' },
  { label: 'São Gonçalo Do Piauí', value: 'São Gonçalo Do Piauí' },
  { label: 'São Gonçalo Do Rio Abaixo', value: 'São Gonçalo Do Rio Abaixo' },
  { label: 'São Gonçalo Do Rio Preto', value: 'São Gonçalo Do Rio Preto' },
  { label: 'São Gonçalo Do Sapucaí', value: 'São Gonçalo Do Sapucaí' },
  { label: 'São Gonçalo Dos Campos', value: 'São Gonçalo Dos Campos' },
  { label: 'São Gotardo', value: 'São Gotardo' },
  { label: 'São Jerônimo', value: 'São Jerônimo' },
  { label: 'São Jerônimo Da Serra', value: 'São Jerônimo Da Serra' },
  { label: 'São João', value: 'São João' },
  { label: 'São João', value: 'São João' },
  { label: 'São João', value: 'São João' },
  { label: 'São João', value: 'São João' },
  { label: 'São João Batista', value: 'São João Batista' },
  { label: 'São João Batista', value: 'São João Batista' },
  { label: 'São João Batista Do Glória', value: 'São João Batista Do Glória' },
  { label: 'São João Da Baliza', value: 'São João Da Baliza' },
  { label: 'São João Da Barra', value: 'São João Da Barra' },
  { label: 'São João Da Boa Vista', value: 'São João Da Boa Vista' },
  { label: 'São João Da Canabrava', value: 'São João Da Canabrava' },
  { label: 'São João Da Fronteira', value: 'São João Da Fronteira' },
  { label: 'São João Da Lagoa', value: 'São João Da Lagoa' },
  { label: 'São João Da Madeira', value: 'São João Da Madeira' },
  { label: 'São João Da Mata', value: 'São João Da Mata' },
  { label: 'São João Da Paraúna', value: 'São João Da Paraúna' },
  { label: 'São João Da Pesqueira', value: 'São João Da Pesqueira' },
  { label: 'São João Da Ponta', value: 'São João Da Ponta' },
  { label: 'São João Da Ponte', value: 'São João Da Ponte' },
  { label: 'São João Da Serra', value: 'São João Da Serra' },
  { label: 'São João Da Talha', value: 'São João Da Talha' },
  { label: 'São João Da Talha', value: 'São João Da Talha' },
  { label: 'São João Da Urtiga', value: 'São João Da Urtiga' },
  { label: 'São João Da Varjota', value: 'São João Da Varjota' },
  { label: 'São João Daliança', value: 'São João Daliança' },
  { label: 'São João Das Duas Pontes', value: 'São João Das Duas Pontes' },
  { label: 'São João Das Lampas', value: 'São João Das Lampas' },
  { label: 'São João Das Lampas', value: 'São João Das Lampas' },
  { label: 'São João Das Missões', value: 'São João Das Missões' },
  { label: 'São João De Areias', value: 'São João De Areias' },
  { label: 'São João De Brito', value: 'São João De Brito' },
  { label: 'São João De Deus', value: 'São João De Deus' },
  { label: 'São João De Iracema', value: 'São João De Iracema' },
  { label: 'São João De Meriti', value: 'São João De Meriti' },
  { label: 'São João De Pirabas', value: 'São João De Pirabas' },
  { label: 'São João De Ver', value: 'São João De Ver' },
  { label: 'São João Del Rei', value: 'São João Del Rei' },
  { label: 'São João Do Araguaia', value: 'São João Do Araguaia' },
  { label: 'São João Do Arraial', value: 'São João Do Arraial' },
  { label: 'São João Do Caiuá', value: 'São João Do Caiuá' },
  { label: 'São João Do Cariri', value: 'São João Do Cariri' },
  { label: 'São João Do Carú', value: 'São João Do Carú' },
  { label: 'São João Do Itaperiú', value: 'São João Do Itaperiú' },
  { label: 'São João Do Ivaí', value: 'São João Do Ivaí' },
  { label: 'São João Do Jaguaribe', value: 'São João Do Jaguaribe' },
  { label: 'São João Do Manhuaçu', value: 'São João Do Manhuaçu' },
  { label: 'São João Do Manteninha', value: 'São João Do Manteninha' },
  { label: 'São João Do Oeste', value: 'São João Do Oeste' },
  { label: 'São João Do Oriente', value: 'São João Do Oriente' },
  { label: 'São João Do Pacuí', value: 'São João Do Pacuí' },
  { label: 'São João Do Paraíso', value: 'São João Do Paraíso' },
  { label: 'São João Do Paraíso', value: 'São João Do Paraíso' },
  { label: 'São João Do Pau Dalho', value: 'São João Do Pau Dalho' },
  { label: 'São João Do Piauí', value: 'São João Do Piauí' },
  { label: 'São João Do Polêsine', value: 'São João Do Polêsine' },
  { label: 'São João Do Rio Do Peixe', value: 'São João Do Rio Do Peixe' },
  { label: 'São João Do Sabugi', value: 'São João Do Sabugi' },
  { label: 'São João Do Soter', value: 'São João Do Soter' },
  { label: 'São João Do Sul', value: 'São João Do Sul' },
  { label: 'São João Do Tigre', value: 'São João Do Tigre' },
  { label: 'São João Do Triunfo', value: 'São João Do Triunfo' },
  { label: 'São João Dos Inhamuns', value: 'São João Dos Inhamuns' },
  { label: 'São João Dos Montes', value: 'São João Dos Montes' },
  { label: 'São João Dos Montes', value: 'São João Dos Montes' },
  { label: 'São João Dos Patos', value: 'São João Dos Patos' },
  { label: 'São João Evangelista', value: 'São João Evangelista' },
  { label: 'São João Nepomuceno', value: 'São João Nepomuceno' },
  { label: 'São Joaquim', value: 'São Joaquim' },
  { label: 'São Joaquim Da Barra', value: 'São Joaquim Da Barra' },
  { label: 'São Joaquim De Bicas', value: 'São Joaquim De Bicas' },
  { label: 'São Joaquim Do Monte', value: 'São Joaquim Do Monte' },
  { label: 'São Jorge', value: 'São Jorge' },
  { label: 'São Jorge', value: 'São Jorge' },
  { label: 'São Jorge De Arroios', value: 'São Jorge De Arroios' },
  { label: 'São Jorge Do Ivaí', value: 'São Jorge Do Ivaí' },
  { label: 'São Jorge Do Patrocínio', value: 'São Jorge Do Patrocínio' },
  { label: 'São Jorge Doeste', value: 'São Jorge Doeste' },
  { label: 'São José', value: 'São José' },
  { label: 'São José', value: 'São José' },
  { label: 'São José Da Barra', value: 'São José Da Barra' },
  { label: 'São José Da Bela Vista', value: 'São José Da Bela Vista' },
  { label: 'São José Da Boa Vista', value: 'São José Da Boa Vista' },
  { label: 'São José Da Coroa Grande', value: 'São José Da Coroa Grande' },
  { label: 'São José Da Lagoa Tapada', value: 'São José Da Lagoa Tapada' },
  { label: 'São José Da Laje', value: 'São José Da Laje' },
  { label: 'São José Da Lapa', value: 'São José Da Lapa' },
  { label: 'São José Da Safira', value: 'São José Da Safira' },
  { label: 'São José Da Tapera', value: 'São José Da Tapera' },
  { label: 'São José Da Varginha', value: 'São José Da Varginha' },
  { label: 'São José Da Vitória', value: 'São José Da Vitória' },
  { label: 'São José Das Missões', value: 'São José Das Missões' },
  { label: 'São José Das Palmeiras', value: 'São José Das Palmeiras' },
  { label: 'São José De Caiana', value: 'São José De Caiana' },
  { label: 'São José De Espinharas', value: 'São José De Espinharas' },
  { label: 'São José De Mipibu', value: 'São José De Mipibu' },
  { label: 'São José De Piranhas', value: 'São José De Piranhas' },
  { label: 'São José De Princesa', value: 'São José De Princesa' },
  { label: 'São José De Ribamar', value: 'São José De Ribamar' },
  { label: 'São José De Ubá', value: 'São José De Ubá' },
  { label: 'São José Do Alegre', value: 'São José Do Alegre' },
  { label: 'São José Do Barreiro', value: 'São José Do Barreiro' },
  { label: 'São José Do Belmonte', value: 'São José Do Belmonte' },
  { label: 'São José Do Bonfim', value: 'São José Do Bonfim' },
  { label: 'São José Do Brejo Do Cruz', value: 'São José Do Brejo Do Cruz' },
  { label: 'São José Do Calçado', value: 'São José Do Calçado' },
  { label: 'São José Do Campestre', value: 'São José Do Campestre' },
  { label: 'São José Do Cedro', value: 'São José Do Cedro' },
  { label: 'São José Do Cerrito', value: 'São José Do Cerrito' },
  { label: 'São José Do Divino', value: 'São José Do Divino' },
  { label: 'São José Do Divino', value: 'São José Do Divino' },
  { label: 'São José Do Egito', value: 'São José Do Egito' },
  { label: 'São José Do Goiabal', value: 'São José Do Goiabal' },
  { label: 'São José Do Herval', value: 'São José Do Herval' },
  { label: 'São José Do Hortêncio', value: 'São José Do Hortêncio' },
  { label: 'São José Do Inhacorá', value: 'São José Do Inhacorá' },
  { label: 'São José Do Jacuípe', value: 'São José Do Jacuípe' },
  { label: 'São José Do Jacuri', value: 'São José Do Jacuri' },
  { label: 'São José Do Mantimento', value: 'São José Do Mantimento' },
  { label: 'São José Do Norte', value: 'São José Do Norte' },
  { label: 'São José Do Ouro', value: 'São José Do Ouro' },
  { label: 'São José Do Peixe', value: 'São José Do Peixe' },
  { label: 'São José Do Piauí', value: 'São José Do Piauí' },
  { label: 'São José Do Povo', value: 'São José Do Povo' },
  { label: 'São José Do Rio Claro', value: 'São José Do Rio Claro' },
  { label: 'São José Do Rio Pardo', value: 'São José Do Rio Pardo' },
  { label: 'São José Do Rio Preto', value: 'São José Do Rio Preto' },
  { label: 'São José Do Sabugi', value: 'São José Do Sabugi' },
  { label: 'São José Do Seridó', value: 'São José Do Seridó' },
  { label: 'São José Do Sul', value: 'São José Do Sul' },
  { label: 'São José Do Vale Do Rio Preto', value: 'São José Do Vale Do Rio Preto' },
  { label: 'São José Do Xingu', value: 'São José Do Xingu' },
  { label: 'São José Dos Ausentes', value: 'São José Dos Ausentes' },
  { label: 'São José Dos Basílios', value: 'São José Dos Basílios' },
  { label: 'São José Dos Campos', value: 'São José Dos Campos' },
  { label: 'São José Dos Cordeiros', value: 'São José Dos Cordeiros' },
  { label: 'São José Dos Pinhais', value: 'São José Dos Pinhais' },
  { label: 'São José Dos Quatro Marcos', value: 'São José Dos Quatro Marcos' },
  { label: 'São José Dos Ramos', value: 'São José Dos Ramos' },
  { label: 'São Julião', value: 'São Julião' },
  { label: 'São Julião', value: 'São Julião' },
  { label: 'São Julião Do Tojal', value: 'São Julião Do Tojal' },
  { label: 'São Leopoldo', value: 'São Leopoldo' },
  { label: 'São Lourenço', value: 'São Lourenço' },
  { label: 'São Lourenço Da Mata', value: 'São Lourenço Da Mata' },
  { label: 'São Lourenço Da Serra', value: 'São Lourenço Da Serra' },
  { label: 'São Lourenço Do Oeste', value: 'São Lourenço Do Oeste' },
  { label: 'São Lourenço Do Piauí', value: 'São Lourenço Do Piauí' },
  { label: 'São Lourenço Do Sul', value: 'São Lourenço Do Sul' },
  { label: 'São Ludgero', value: 'São Ludgero' },
  { label: 'São Luis', value: 'São Luis' },
  { label: 'São Luís', value: 'São Luís' },
  { label: 'São Luís', value: 'São Luís' },
  { label: 'São Luís De Montes Belos', value: 'São Luís De Montes Belos' },
  { label: 'São Luís Do Curu', value: 'São Luís Do Curu' },
  { label: 'São Luis Do Piauí', value: 'São Luis Do Piauí' },
  { label: 'São Luís Do Quitunde', value: 'São Luís Do Quitunde' },
  { label: 'São Luís Gonzaga Do Maranhão', value: 'São Luís Gonzaga Do Maranhão' },
  { label: 'São Luiz Do Norte', value: 'São Luiz Do Norte' },
  { label: 'São Luiz Do Paraitinga', value: 'São Luiz Do Paraitinga' },
  { label: 'São Luiz Gonzaga', value: 'São Luiz Gonzaga' },
  { label: 'São Mamede', value: 'São Mamede' },
  { label: 'São Mamede', value: 'São Mamede' },
  { label: 'São Mamede De Infesta', value: 'São Mamede De Infesta' },
  { label: 'São Manoel Do Paraná', value: 'São Manoel Do Paraná' },
  { label: 'São Manuel', value: 'São Manuel' },
  { label: 'São Marcos', value: 'São Marcos' },
  { label: 'São Marcos', value: 'São Marcos' },
  { label: 'São Marcos Da Serra', value: 'São Marcos Da Serra' },
  { label: 'São Martinho', value: 'São Martinho' },
  { label: 'São Martinho', value: 'São Martinho' },
  { label: 'São Martinho', value: 'São Martinho' },
  { label: 'São Martinho', value: 'São Martinho' },
  { label: 'São Martinho Da Serra', value: 'São Martinho Da Serra' },
  { label: 'São Martinho Do Porto', value: 'São Martinho Do Porto' },
  { label: 'São Mateus', value: 'São Mateus' },
  { label: 'São Mateus', value: 'São Mateus' },
  { label: 'São Mateus Do Maranhão', value: 'São Mateus Do Maranhão' },
  { label: 'São Mateus Do Sul', value: 'São Mateus Do Sul' },
  { label: 'São Miguel', value: 'São Miguel' },
  { label: 'São Miguel', value: 'São Miguel' },
  { label: 'São Miguel Arcanjo', value: 'São Miguel Arcanjo' },
  { label: 'São Miguel Da Baixa Grande', value: 'São Miguel Da Baixa Grande' },
  { label: 'São Miguel Da Boa Vista', value: 'São Miguel Da Boa Vista' },
  { label: 'São Miguel Da Guarda', value: 'São Miguel Da Guarda' },
  { label: 'São Miguel Das Matas', value: 'São Miguel Das Matas' },
  { label: 'São Miguel Das Missões', value: 'São Miguel Das Missões' },
  { label: 'São Miguel De Alcainça', value: 'São Miguel De Alcainça' },
  { label: 'São Miguel De Rio Torto', value: 'São Miguel De Rio Torto' },
  { label: 'São Miguel De Taipu', value: 'São Miguel De Taipu' },
  { label: 'São Miguel Do Aleixo', value: 'São Miguel Do Aleixo' },
  { label: 'São Miguel Do Anta', value: 'São Miguel Do Anta' },
  { label: 'São Miguel Do Araguaia', value: 'São Miguel Do Araguaia' },
  { label: 'São Miguel Do Couto', value: 'São Miguel Do Couto' },
  { label: 'São Miguel Do Fidalgo', value: 'São Miguel Do Fidalgo' },
  { label: 'São Miguel Do Gostoso', value: 'São Miguel Do Gostoso' },
  { label: 'São Miguel Do Guamá', value: 'São Miguel Do Guamá' },
  { label: 'São Miguel Do Guaporé', value: 'São Miguel Do Guaporé' },
  { label: 'São Miguel Do Iguaçu', value: 'São Miguel Do Iguaçu' },
  { label: 'São Miguel Do Oeste', value: 'São Miguel Do Oeste' },
  { label: 'São Miguel Do Passa Quatro', value: 'São Miguel Do Passa Quatro' },
  { label: 'São Miguel Do Tapuio', value: 'São Miguel Do Tapuio' },
  { label: 'São Miguel Do Tocantins', value: 'São Miguel Do Tocantins' },
  { label: 'São Miguel Dos Campos', value: 'São Miguel Dos Campos' },
  { label: 'São Miguel Dos Milagres', value: 'São Miguel Dos Milagres' },
  { label: 'São Nicolau', value: 'São Nicolau' },
  { label: 'São Nicolau', value: 'São Nicolau' },
  { label: 'São Paio', value: 'São Paio' },
  { label: 'São Patrício', value: 'São Patrício' },
  { label: 'São Paulo', value: 'São Paulo' },
  { label: 'São Paulo', value: 'São Paulo' },
  { label: 'São Paulo Das Missões', value: 'São Paulo Das Missões' },
  { label: 'São Paulo De Olivença', value: 'São Paulo De Olivença' },
  { label: 'São Paulo Do Potengi', value: 'São Paulo Do Potengi' },
  { label: 'São Pedro', value: 'São Pedro' },
  { label: 'São Pedro', value: 'São Pedro' },
  { label: 'São Pedro', value: 'São Pedro' },
  { label: 'São Pedro', value: 'São Pedro' },
  { label: 'São Pedro Da Água Branca', value: 'São Pedro Da Água Branca' },
  { label: 'São Pedro Da Aldeia', value: 'São Pedro Da Aldeia' },
  { label: 'São Pedro Da Cadeira', value: 'São Pedro Da Cadeira' },
  { label: 'São Pedro Da Cadeira', value: 'São Pedro Da Cadeira' },
  { label: 'São Pedro Da Cipa', value: 'São Pedro Da Cipa' },
  { label: 'São Pedro Da Cova', value: 'São Pedro Da Cova' },
  { label: 'São Pedro Da Serra', value: 'São Pedro Da Serra' },
  { label: 'São Pedro Da União', value: 'São Pedro Da União' },
  { label: 'São Pedro Das Missões', value: 'São Pedro Das Missões' },
  { label: 'São Pedro De Alcântara', value: 'São Pedro De Alcântara' },
  { label: 'São Pedro De Alva', value: 'São Pedro De Alva' },
  { label: 'São Pedro De Penaferrim', value: 'São Pedro De Penaferrim' },
  { label: 'São Pedro De Rio Seco', value: 'São Pedro De Rio Seco' },
  { label: 'São Pedro Do Butiá', value: 'São Pedro Do Butiá' },
  { label: 'São Pedro Do Iguaçu', value: 'São Pedro Do Iguaçu' },
  { label: 'São Pedro Do Ivaí', value: 'São Pedro Do Ivaí' },
  { label: 'São Pedro Do Paraná', value: 'São Pedro Do Paraná' },
  { label: 'São Pedro Do Piauí', value: 'São Pedro Do Piauí' },
  { label: 'São Pedro Do Suaçuí', value: 'São Pedro Do Suaçuí' },
  { label: 'São Pedro Do Sul', value: 'São Pedro Do Sul' },
  { label: 'São Pedro Do Sul', value: 'São Pedro Do Sul' },
  { label: 'São Pedro Do Turvo', value: 'São Pedro Do Turvo' },
  { label: 'São Pedro Dos Crentes', value: 'São Pedro Dos Crentes' },
  { label: 'São Pedro Dos Ferros', value: 'São Pedro Dos Ferros' },
  { label: 'São Rafael', value: 'São Rafael' },
  { label: 'São Raimundo Das Mangabeiras', value: 'São Raimundo Das Mangabeiras' },
  { label: 'São Raimundo Do Doca Bezerra', value: 'São Raimundo Do Doca Bezerra' },
  { label: 'São Raimundo Nonato', value: 'São Raimundo Nonato' },
  { label: 'São Roberto', value: 'São Roberto' },
  { label: 'São Romão', value: 'São Romão' },
  { label: 'São Romão', value: 'São Romão' },
  { label: 'São Romão Do Coronado', value: 'São Romão Do Coronado' },
  { label: 'São Roque', value: 'São Roque' },
  { label: 'São Roque', value: 'São Roque' },
  { label: 'São Roque', value: 'São Roque' },
  { label: 'São Roque', value: 'São Roque' },
  { label: 'São Roque De Minas', value: 'São Roque De Minas' },
  { label: 'São Roque Do Canaã', value: 'São Roque Do Canaã' },
  { label: 'São Roque Do Pico', value: 'São Roque Do Pico' },
  { label: 'São Salvador Do Tocantins', value: 'São Salvador Do Tocantins' },
  { label: 'São Sebastião', value: 'São Sebastião' },
  { label: 'São Sebastião', value: 'São Sebastião' },
  { label: 'São Sebastião', value: 'São Sebastião' },
  { label: 'São Sebastião Da Amoreira', value: 'São Sebastião Da Amoreira' },
  { label: 'São Sebastião Da Bela Vista', value: 'São Sebastião Da Bela Vista' },
  { label: 'São Sebastião Da Boa Vista', value: 'São Sebastião Da Boa Vista' },
  { label: 'São Sebastião Da Grama', value: 'São Sebastião Da Grama' },
  { label: 'São Sebastião Da Pedreira', value: 'São Sebastião Da Pedreira' },
  { label: 'São Sebastião Da Vargem Alegre', value: 'São Sebastião Da Vargem Alegre' },
  { label: 'São Sebastião De Lagoa De Roça', value: 'São Sebastião De Lagoa De Roça' },
  { label: 'São Sebastião Do Alto', value: 'São Sebastião Do Alto' },
  { label: 'São Sebastião Do Anta', value: 'São Sebastião Do Anta' },
  { label: 'São Sebastião Do Caí', value: 'São Sebastião Do Caí' },
  { label: 'São Sebastião Do Maranhão', value: 'São Sebastião Do Maranhão' },
  { label: 'São Sebastião Do Oeste', value: 'São Sebastião Do Oeste' },
  { label: 'São Sebastião Do Paraíso', value: 'São Sebastião Do Paraíso' },
  { label: 'São Sebastião Do Passé', value: 'São Sebastião Do Passé' },
  { label: 'São Sebastião Do Rio Preto', value: 'São Sebastião Do Rio Preto' },
  { label: 'São Sebastião Do Rio Verde', value: 'São Sebastião Do Rio Verde' },
  { label: 'São Sebastião Do Tocantins', value: 'São Sebastião Do Tocantins' },
  { label: 'São Sebastião Do Uatumã', value: 'São Sebastião Do Uatumã' },
  { label: 'São Sebastião Do Umbuzeiro', value: 'São Sebastião Do Umbuzeiro' },
  { label: 'São Sepé', value: 'São Sepé' },
  { label: 'São Simão', value: 'São Simão' },
  { label: 'São Simão', value: 'São Simão' },
  { label: 'São Teotónio', value: 'São Teotónio' },
  { label: 'São Thomé Das Letras', value: 'São Thomé Das Letras' },
  { label: 'São Tiago', value: 'São Tiago' },
  { label: 'São Tomás De Aquino', value: 'São Tomás De Aquino' },
  { label: 'São Tomé', value: 'São Tomé' },
  { label: 'São Tomé', value: 'São Tomé' },
  { label: 'São Tomé', value: 'São Tomé' },
  { label: 'São Valentim', value: 'São Valentim' },
  { label: 'São Valentim Do Sul', value: 'São Valentim Do Sul' },
  { label: 'São Valério', value: 'São Valério' },
  { label: 'São Valério Do Sul', value: 'São Valério Do Sul' },
  { label: 'São Vendelino', value: 'São Vendelino' },
  { label: 'São Vicente', value: 'São Vicente' },
  { label: 'São Vicente', value: 'São Vicente' },
  { label: 'São Vicente', value: 'São Vicente' },
  { label: 'São Vicente', value: 'São Vicente' },
  { label: 'São Vicente', value: 'São Vicente' },
  { label: 'São Vicente De Fora', value: 'São Vicente De Fora' },
  { label: 'São Vicente De Minas', value: 'São Vicente De Minas' },
  { label: 'São Vicente Do Paul', value: 'São Vicente Do Paul' },
  { label: 'São Vicente Do Seridó', value: 'São Vicente Do Seridó' },
  { label: 'São Vicente Do Sul', value: 'São Vicente Do Sul' },
  { label: 'São Vicente Ferrer', value: 'São Vicente Ferrer' },
  { label: 'São Vicente Férrer', value: 'São Vicente Férrer' },
  { label: 'Saoli', value: 'Saoli' },
  { label: 'Saonara', value: 'Saonara' },
  { label: 'Saône', value: 'Saône' },
  { label: 'Saoner', value: 'Saoner' },
  { label: 'Saoula', value: 'Saoula' },
  { label: 'Sapa', value: 'Sapa' },
  { label: 'Sapa', value: 'Sapa' },
  { label: 'Sapad', value: 'Sapad' },
  { label: 'Sapad', value: 'Sapad' },
  { label: 'Sapadun', value: 'Sapadun' },
  { label: 'Sapadun', value: 'Sapadun' },
  { label: 'Sapanca', value: 'Sapanca' },
  { label: 'Sapang', value: 'Sapang' },
  { label: 'Sapang', value: 'Sapang' },
  { label: 'Sapang Buho', value: 'Sapang Buho' },
  { label: 'Sapang Buho', value: 'Sapang Buho' },
  { label: 'Sapang Dalaga', value: 'Sapang Dalaga' },
  { label: 'Săpânţa', value: 'Săpânţa' },
  { label: 'Sapareva Banya', value: 'Sapareva Banya' },
  { label: 'Șapartoc', value: 'Șapartoc' },
  { label: 'Sapa-Sapa', value: 'Sapa-Sapa' },
  { label: 'Sapataria', value: 'Sapataria' },
  { label: 'Sapatgram', value: 'Sapatgram' },
  { label: 'Sapcote', value: 'Sapcote' },
  { label: 'Sapé', value: 'Sapé' },
  { label: 'Sapeaçu', value: 'Sapeaçu' },
  { label: 'Sapele', value: 'Sapele' },
  { label: 'Sapernoye', value: 'Sapernoye' },
  { label: 'Sapërnyy', value: 'Sapërnyy' },
  { label: 'Sápes', value: 'Sápes' },
  { label: 'Sapezal', value: 'Sapezal' },
  { label: 'Saphan Sung', value: 'Saphan Sung' },
  { label: 'Şaphane', value: 'Şaphane' },
  { label: 'Saphire', value: 'Saphire' },
  { label: 'Sapian', value: 'Sapian' },
  { label: 'Sapioris', value: 'Sapioris' },
  { label: 'Sapiranga', value: 'Sapiranga' },
  { label: 'Sapna', value: 'Sapna' },
  { label: 'Săpoca', value: 'Săpoca' },
  { label: 'Sapol', value: 'Sapol' },
  { label: 'Sapol', value: 'Sapol' },
  { label: 'Saponara', value: 'Saponara' },
  { label: 'Saponara Marittima', value: 'Saponara Marittima' },
  { label: 'Sapopema', value: 'Sapopema' },
  { label: 'Saposoa', value: 'Saposoa' },
  { label: 'Sapotskin', value: 'Sapotskin' },
  { label: 'Sapouy', value: 'Sapouy' },
  { label: 'Sapozhok', value: 'Sapozhok' },
  { label: 'Sappada', value: 'Sappada' },
  { label: 'Sappemeer', value: 'Sappemeer' },
  { label: 'Sapphire Beach', value: 'Sapphire Beach' },
  { label: 'Sappington', value: 'Sappington' },
  { label: 'Sapporo', value: 'Sapporo' },
  { label: 'Sapporo-Shi', value: 'Sapporo-Shi' },
  { label: 'Sapri', value: 'Sapri' },
  { label: 'Saptari ', value: 'Saptari ' },
  { label: 'Sapu Padidu', value: 'Sapu Padidu' },
  { label: 'Sapu Padidu', value: 'Sapu Padidu' },
  { label: 'Sapucaí', value: 'Sapucaí' },
  { label: 'Sapucaia', value: 'Sapucaia' },
  { label: 'Sapucaia', value: 'Sapucaia' },
  { label: 'Sapucaia', value: 'Sapucaia' },
  { label: 'Sapucaia Do Sul', value: 'Sapucaia Do Sul' },
  { label: 'Sapucaí-Mirim', value: 'Sapucaí-Mirim' },
  { label: 'Sapulpa', value: 'Sapulpa' },
  { label: 'Săpunari', value: 'Săpunari' },
  { label: 'Sapuyes', value: 'Sapuyes' },
  { label: 'Sapwuahfik Municipality', value: 'Sapwuahfik Municipality' },
  { label: 'Saqayn', value: 'Saqayn' },
  { label: 'Saqqez', value: 'Saqqez' },
  { label: 'Saquarema', value: 'Saquarema' },
  { label: 'Saquisilí', value: 'Saquisilí' },
  { label: 'Sar Dasht', value: 'Sar Dasht' },
  { label: 'Sar Duran', value: 'Sar Duran' },
  { label: 'Sara', value: 'Sara' },
  { label: 'Sara Kunda', value: 'Sara Kunda' },
  { label: 'Sarab', value: 'Sarab' },
  { label: 'Sarabbagh', value: 'Sarabbagh' },
  { label: 'Sarab-E Dowreh', value: 'Sarab-E Dowreh' },
  { label: 'Sarab-E Tajar', value: 'Sarab-E Tajar' },
  { label: 'Sarabia', value: 'Sarabia' },
  { label: 'Sarableh', value: 'Sarableh' },
  { label: 'Saraburi', value: 'Saraburi' },
  { label: 'Saracena', value: 'Saracena' },
  { label: 'Sarachy', value: 'Sarachy' },
  { label: 'Saracinesco', value: 'Saracinesco' },
  { label: 'Sarafinești', value: 'Sarafinești' },
  { label: 'Sarafovo', value: 'Sarafovo' },
  { label: 'Sarahan', value: 'Sarahan' },
  { label: 'Sarahart’', value: 'Sarahart’' },
  { label: 'Sarai', value: 'Sarai' },
  { label: 'Sarai Akil', value: 'Sarai Akil' },
  { label: 'Sarai Alamgir', value: 'Sarai Alamgir' },
  { label: 'Sarai Ekdil', value: 'Sarai Ekdil' },
  { label: 'Sarai Mir', value: 'Sarai Mir' },
  { label: 'Sarai Naurang', value: 'Sarai Naurang' },
  { label: 'Sarai Sidhu', value: 'Sarai Sidhu' },
  { label: 'Saraikela', value: 'Saraikela' },
  { label: 'Saraipali', value: 'Saraipali' },
  { label: 'Saraiu', value: 'Saraiu' },
  { label: 'Saraj', value: 'Saraj' },
  { label: 'Sarajevo', value: 'Sarajevo' },
  { label: 'Sarakhs', value: 'Sarakhs' },
  { label: 'Saraktash', value: 'Saraktash' },
  { label: 'Saraktashskiy Rayon', value: 'Saraktashskiy Rayon' },
  { label: 'Saraland', value: 'Saraland' },
  { label: 'Saramej', value: 'Saramej' },
  { label: 'Saran', value: 'Saran' },
  { label: 'Saran', value: 'Saran' },
  { label: 'Sarana', value: 'Sarana' },
  { label: 'Saranac', value: 'Saranac' },
  { label: 'Saranac Lake', value: 'Saranac Lake' },
  { label: 'Saranap', value: 'Saranap' },
  { label: 'Sáránd', value: 'Sáránd' },
  { label: 'Sarandë', value: 'Sarandë' },
  { label: 'Sarandi', value: 'Sarandi' },
  { label: 'Sarandi', value: 'Sarandi' },
  { label: 'Sarandí Del Yi', value: 'Sarandí Del Yi' },
  { label: 'Sarandí Grande', value: 'Sarandí Grande' },
  { label: 'Sarangani', value: 'Sarangani' },
  { label: 'Sarangani', value: 'Sarangani' },
  { label: 'Sarangani', value: 'Sarangani' },
  { label: 'Sarangarh', value: 'Sarangarh' },
  { label: 'Sarangkheda', value: 'Sarangkheda' },
  { label: 'Sarangpur', value: 'Sarangpur' },
  { label: 'Saranpaul’', value: 'Saranpaul’' },
  { label: 'Saransk', value: 'Saransk' },
  { label: 'Sarany', value: 'Sarany' },
  { label: 'Sarapiquí', value: 'Sarapiquí' },
  { label: 'Sarapuí', value: 'Sarapuí' },
  { label: 'Sarapul', value: 'Sarapul' },
  { label: 'Sarapul’Skiy Rayon', value: 'Sarapul’Skiy Rayon' },
  { label: 'Sarāqib', value: 'Sarāqib' },
  { label: 'Sarar', value: 'Sarar' },
  { label: 'Sarasău', value: 'Sarasău' },
  { label: 'Sarasota', value: 'Sarasota' },
  { label: 'Sarasota County', value: 'Sarasota County' },
  { label: 'Sarasota Springs', value: 'Sarasota Springs' },
  { label: 'Sarata', value: 'Sarata' },
  { label: 'Sărata', value: 'Sărata' },
  { label: 'Sărata', value: 'Sărata' },
  { label: 'Saratak', value: 'Saratak' },
  { label: 'Sărățeni', value: 'Sărățeni' },
  { label: 'Sărățeni', value: 'Sărățeni' },
  { label: 'Saratoga', value: 'Saratoga' },
  { label: 'Saratoga', value: 'Saratoga' },
  { label: 'Saratoga', value: 'Saratoga' },
  { label: 'Saratoga County', value: 'Saratoga County' },
  { label: 'Saratoga Springs', value: 'Saratoga Springs' },
  { label: 'Saratoga Springs', value: 'Saratoga Springs' },
  { label: 'Saratov', value: 'Saratov' },
  { label: 'Saratovskaya', value: 'Saratovskaya' },
  { label: 'Saratovskiy Rayon', value: 'Saratovskiy Rayon' },
  { label: 'Sărăuad', value: 'Sărăuad' },
  { label: 'Sarauli', value: 'Sarauli' },
  { label: 'Saravak', value: 'Saravak' },
  { label: 'Saravale', value: 'Saravale' },
  { label: 'Saravan', value: 'Saravan' },
  { label: 'Saravena', value: 'Saravena' },
  { label: 'Saravia', value: 'Saravia' },
  { label: 'Saray', value: 'Saray' },
  { label: 'Saray', value: 'Saray' },
  { label: 'Saray', value: 'Saray' },
  { label: 'Saraya', value: 'Saraya' },
  { label: 'Sarayan', value: 'Sarayan' },
  { label: 'Saraydüzü', value: 'Saraydüzü' },
  { label: 'Sarayjuq', value: 'Sarayjuq' },
  { label: 'Saraykent', value: 'Saraykent' },
  { label: 'Sarayköy', value: 'Sarayköy' },
  { label: 'Sarayönü', value: 'Sarayönü' },
  { label: 'Saraza', value: 'Saraza' },
  { label: 'Sarbaz', value: 'Sarbaz' },
  { label: 'Sarbazan', value: 'Sarbazan' },
  { label: 'Sârbeni', value: 'Sârbeni' },
  { label: 'Sarbhon', value: 'Sarbhon' },
  { label: 'Sârbi', value: 'Sârbi' },
  { label: 'Sârbi', value: 'Sârbi' },
  { label: 'Sârbi', value: 'Sârbi' },
  { label: 'Sarbishe', value: 'Sarbishe' },
  { label: 'Sarbisheh', value: 'Sarbisheh' },
  { label: 'Sárbogárd', value: 'Sárbogárd' },
  { label: 'Sárbogárdi Járás', value: 'Sárbogárdi Járás' },
  { label: 'Sarcedo', value: 'Sarcedo' },
  { label: 'Sarcelles', value: 'Sarcelles' },
  { label: 'Sarconi', value: 'Sarconi' },
  { label: 'Sarcoxie', value: 'Sarcoxie' },
  { label: 'Șard', value: 'Șard' },
  { label: 'Sardara', value: 'Sardara' },
  { label: 'Sardarabad', value: 'Sardarabad' },
  { label: 'Sardarapat', value: 'Sardarapat' },
  { label: 'Sardarshahr', value: 'Sardarshahr' },
  { label: 'Sardasht', value: 'Sardasht' },
  { label: 'Sardasht Bashagard', value: 'Sardasht Bashagard' },
  { label: 'Sardhana', value: 'Sardhana' },
  { label: 'Šardice', value: 'Šardice' },
  { label: 'Sardigliano', value: 'Sardigliano' },
  { label: 'Sardinal', value: 'Sardinal' },
  { label: 'Sardinata', value: 'Sardinata' },
  { label: 'Sardínia', value: 'Sardínia' },
  { label: 'Sardis', value: 'Sardis' },
  { label: 'Sardis', value: 'Sardis' },
  { label: 'Sardis City', value: 'Sardis City' },
  { label: 'Sardoá', value: 'Sardoá' },
  { label: 'Sardoal', value: 'Sardoal' },
  { label: 'Sardoi', value: 'Sardoi' },
  { label: 'Sardón De Duero', value: 'Sardón De Duero' },
  { label: 'Sardón De Los Frailes', value: 'Sardón De Los Frailes' },
  { label: 'Sardroud', value: 'Sardroud' },
  { label: 'Șardu Nirajului', value: 'Șardu Nirajului' },
  { label: 'Sardulgarh', value: 'Sardulgarh' },
  { label: 'Sare', value: 'Sare' },
  { label: 'Sar-E Pul', value: 'Sar-E Pul' },
  { label: 'Sarego', value: 'Sarego' },
  { label: 'Sarein', value: 'Sarein' },
  { label: 'Sarenal', value: 'Sarenal' },
  { label: 'Sarenga', value: 'Sarenga' },
  { label: 'Sarentino', value: 'Sarentino' },
  { label: 'Särevere', value: 'Särevere' },
  { label: 'Sareyn', value: 'Sareyn' },
  { label: 'Sarezzano', value: 'Sarezzano' },
  { label: 'Sarezzo', value: 'Sarezzo' },
  { label: 'Sarfariyab', value: 'Sarfariyab' },
  { label: 'Sargans', value: 'Sargans' },
  { label: 'Sargatskoye', value: 'Sargatskoye' },
  { label: 'Sargaz', value: 'Sargaz' },
  { label: 'Sargazy', value: 'Sargazy' },
  { label: 'Sargé-Lès-Le-Mans', value: 'Sargé-Lès-Le-Mans' },
  { label: 'Sargėnai', value: 'Sargėnai' },
  { label: 'Sargent County', value: 'Sargent County' },
  { label: 'Sargentes De La Lora', value: 'Sargentes De La Lora' },
  { label: 'Sargé-Sur-Braye', value: 'Sargé-Sur-Braye' },
  { label: 'Sargodha', value: 'Sargodha' },
  { label: 'Sargur', value: 'Sargur' },
  { label: 'Sarh', value: 'Sarh' },
  { label: 'Sari', value: 'Sari' },
  { label: 'Sariaya', value: 'Sariaya' },
  { label: 'Sáric', value: 'Sáric' },
  { label: 'Sarıcakaya', value: 'Sarıcakaya' },
  { label: 'Sarıçam', value: 'Sarıçam' },
  { label: 'Sarichioi', value: 'Sarichioi' },
  { label: 'Sariego', value: 'Sariego' },
  { label: 'Sariegos', value: 'Sariegos' },
  { label: 'Sarıgöl', value: 'Sarıgöl' },
  { label: 'Sarigyugh', value: 'Sarigyugh' },
  { label: 'Sarijlu', value: 'Sarijlu' },
  { label: 'Sarıkamış', value: 'Sarıkamış' },
  { label: 'Sarıkaya', value: 'Sarıkaya' },
  { label: 'Sarikei', value: 'Sarikei' },
  { label: 'Sarila', value: 'Sarila' },
  { label: 'Sarilhos Pequenos', value: 'Sarilhos Pequenos' },
  { label: 'Sarina', value: 'Sarina' },
  { label: 'Sarine District', value: 'Sarine District' },
  { label: 'Sariñena', value: 'Sariñena' },
  { label: 'Sarıoğlan', value: 'Sarıoğlan' },
  { label: 'Sárisáp', value: 'Sárisáp' },
  { label: 'Sari-Solenzara', value: 'Sari-Solenzara' },
  { label: 'Sarita', value: 'Sarita' },
  { label: 'Sarıveliler', value: 'Sarıveliler' },
  { label: 'Sariwŏn', value: 'Sariwŏn' },
  { label: 'Sarıyahşi', value: 'Sarıyahşi' },
  { label: 'Sarıyer', value: 'Sarıyer' },
  { label: 'Sarız', value: 'Sarız' },
  { label: 'Sarka Ghat', value: 'Sarka Ghat' },
  { label: 'Sarkad', value: 'Sarkad' },
  { label: 'Sarkadi Járás', value: 'Sarkadi Járás' },
  { label: 'Sarkan', value: 'Sarkan' },
  { label: 'Sarkand', value: 'Sarkand' },
  { label: 'Sárkeresztúr', value: 'Sárkeresztúr' },
  { label: 'Sarkhej', value: 'Sarkhej' },
  { label: 'Sarkhon Kalateh', value: 'Sarkhon Kalateh' },
  { label: 'Sarkhun', value: 'Sarkhun' },
  { label: 'Şarkikaraağaç', value: 'Şarkikaraağaç' },
  { label: 'Särkisalo', value: 'Särkisalo' },
  { label: 'Şarkışla', value: 'Şarkışla' },
  { label: 'Şarköy', value: 'Şarköy' },
  { label: 'Sarlahi ', value: 'Sarlahi ' },
  { label: 'Sarlat-La-Canéda', value: 'Sarlat-La-Canéda' },
  { label: 'Sarleinsbach', value: 'Sarleinsbach' },
  { label: 'Sarmakovo', value: 'Sarmakovo' },
  { label: 'Sarmanovo', value: 'Sarmanovo' },
  { label: 'Sarmanovskiy Rayon', value: 'Sarmanovskiy Rayon' },
  { label: 'Sărmaş', value: 'Sărmaş' },
  { label: 'Şărmăşag', value: 'Şărmăşag' },
  { label: 'Sărmășel', value: 'Sărmășel' },
  { label: 'Sărmașu', value: 'Sărmașu' },
  { label: 'Sarmato', value: 'Sarmato' },
  { label: 'Sarmede', value: 'Sarmede' },
  { label: 'Sármellék', value: 'Sármellék' },
  { label: 'Sarmenstorf', value: 'Sarmenstorf' },
  { label: 'Sarmeola', value: 'Sarmeola' },
  { label: 'Sarmiento', value: 'Sarmiento' },
  { label: 'Sarmīn', value: 'Sarmīn' },
  { label: 'Sarmizegetusa', value: 'Sarmizegetusa' },
  { label: 'Sarnaki', value: 'Sarnaki' },
  { label: 'Sarnano', value: 'Sarnano' },
  { label: 'Sarnen', value: 'Sarnen' },
  { label: 'Sarnia', value: 'Sarnia' },
  { label: 'Sarnico', value: 'Sarnico' },
  { label: 'Sarnitsa', value: 'Sarnitsa' },
  { label: 'Sarnitsa Obshtina', value: 'Sarnitsa Obshtina' },
  { label: 'Sarno', value: 'Sarno' },
  { label: 'Sarnonico', value: 'Sarnonico' },
  { label: 'Sarnów', value: 'Sarnów' },
  { label: 'Sarny', value: 'Sarny' },
  { label: 'Saro', value: 'Saro' },
  { label: 'Särö', value: 'Särö' },
  { label: 'Saṟōbī', value: 'Saṟōbī' },
  { label: 'Saronída', value: 'Saronída' },
  { label: 'Saronno', value: 'Saronno' },
  { label: 'Saroogh', value: 'Saroogh' },
  { label: 'Șaroș Pe Târnave', value: 'Șaroș Pe Târnave' },
  { label: 'Sárosd', value: 'Sárosd' },
  { label: 'Sárospatak', value: 'Sárospatak' },
  { label: 'Sárospataki Járás', value: 'Sárospataki Járás' },
  { label: 'Sarot', value: 'Sarot' },
  { label: 'Sarov', value: 'Sarov' },
  { label: 'Sarpang', value: 'Sarpang' },
  { label: 'Sarpinskiy Rayon', value: 'Sarpinskiy Rayon' },
  { label: 'Sarpol Zahab', value: 'Sarpol Zahab' },
  { label: 'Sarpsborg', value: 'Sarpsborg' },
  { label: 'Sarpy County', value: 'Sarpy County' },
  { label: 'Sarracín', value: 'Sarracín' },
  { label: 'Sarral', value: 'Sarral' },
  { label: 'Sarralbe', value: 'Sarralbe' },
  { label: 'Sarras', value: 'Sarras' },
  { label: 'Sarrat', value: 'Sarrat' },
  { label: 'Sarrat', value: 'Sarrat' },
  { label: 'Sarratella', value: 'Sarratella' },
  { label: 'Sarreaus', value: 'Sarreaus' },
  { label: 'Sarrebourg', value: 'Sarrebourg' },
  { label: 'Sarreguemines', value: 'Sarreguemines' },
  { label: 'Sarreinsming', value: 'Sarreinsming' },
  { label: 'Sárrétudvari', value: 'Sárrétudvari' },
  { label: 'Sarre-Union', value: 'Sarre-Union' },
  { label: 'Sarrewerden', value: 'Sarrewerden' },
  { label: 'Sarria', value: 'Sarria' },
  { label: 'Sarrià De Ter', value: 'Sarrià De Ter' },
  { label: 'Sarrians', value: 'Sarrians' },
  { label: 'Sarriés/Sartze', value: 'Sarriés/Sartze' },
  { label: 'Sarriguren', value: 'Sarriguren' },
  { label: 'Sarrión', value: 'Sarrión' },
  { label: 'Sarroca De Bellera', value: 'Sarroca De Bellera' },
  { label: 'Sarroca De Lleida', value: 'Sarroca De Lleida' },
  { label: 'Sarroch', value: 'Sarroch' },
  { label: 'Sarrola', value: 'Sarrola' },
  { label: 'Sarry', value: 'Sarry' },
  { label: 'Sars', value: 'Sars' },
  { label: 'Sarsina', value: 'Sarsina' },
  { label: 'Sars-Poteries', value: 'Sars-Poteries' },
  { label: 'Sarstedt', value: 'Sarstedt' },
  { label: 'Sárszentmihály', value: 'Sárszentmihály' },
  { label: 'Sartaguda', value: 'Sartaguda' },
  { label: 'Sartajada', value: 'Sartajada' },
  { label: 'Sartana', value: 'Sartana' },
  { label: 'Sartano', value: 'Sartano' },
  { label: 'Sarteano', value: 'Sarteano' },
  { label: 'Sartell', value: 'Sartell' },
  { label: 'Sartène', value: 'Sartène' },
  { label: 'Sarthe', value: 'Sarthe' },
  { label: 'Sárti', value: 'Sárti' },
  { label: 'Sartilly', value: 'Sartilly' },
  { label: 'Sartirana Lomellina', value: 'Sartirana Lomellina' },
  { label: 'Sartrouville', value: 'Sartrouville' },
  { label: 'Şaru Dornei', value: 'Şaru Dornei' },
  { label: 'Sarubera', value: 'Sarubera' },
  { label: 'Saruhanlı', value: 'Saruhanlı' },
  { label: 'Saruja', value: 'Saruja' },
  { label: 'Sarukhan', value: 'Sarukhan' },
  { label: 'Sarule', value: 'Sarule' },
  { label: 'Săruleşti', value: 'Săruleşti' },
  { label: 'Săruleşti', value: 'Săruleşti' },
  { label: 'Sărulești-Gară', value: 'Sărulești-Gară' },
  { label: 'Sarupathar', value: 'Sarupathar' },
  { label: 'Sarurpur', value: 'Sarurpur' },
  { label: 'Sarutaiá', value: 'Sarutaiá' },
  { label: 'Sarvabad', value: 'Sarvabad' },
  { label: 'Sárvár', value: 'Sárvár' },
  { label: 'Sárvári Járás', value: 'Sárvári Járás' },
  { label: 'Sarvaš', value: 'Sarvaš' },
  { label: 'Sarvestan', value: 'Sarvestan' },
  { label: 'Sarwar', value: 'Sarwar' },
  { label: 'Saryaghash', value: 'Saryaghash' },
  { label: 'Saryaghash Aūdany', value: 'Saryaghash Aūdany' },
  { label: 'Sary-Bash', value: 'Sary-Bash' },
  { label: 'Saryg-Sep', value: 'Saryg-Sep' },
  { label: 'Sarykemer', value: 'Sarykemer' },
  { label: 'Saryozek', value: 'Saryozek' },
  { label: 'Saryshaghan', value: 'Saryshaghan' },
  { label: 'Sary-Tyuz', value: 'Sary-Tyuz' },
  { label: 'Sarzana', value: 'Sarzana' },
  { label: 'Sarzeau', value: 'Sarzeau' },
  { label: 'Sarzedo', value: 'Sarzedo' },
  { label: 'Sas Van Gent', value: 'Sas Van Gent' },
  { label: 'Sasaguri', value: 'Sasaguri' },
  { label: 'Sasaima', value: 'Sasaima' },
  { label: 'Sasaltitla', value: 'Sasaltitla' },
  { label: 'Sasamón', value: 'Sasamón' },
  { label: 'Sasang-Gu', value: 'Sasang-Gu' },
  { label: 'Săsar', value: 'Săsar' },
  { label: 'Sasayama', value: 'Sasayama' },
  { label: 'Sasayama-Shi', value: 'Sasayama-Shi' },
  { label: 'Sasbach', value: 'Sasbach' },
  { label: 'Sasbachwalden', value: 'Sasbachwalden' },
  { label: 'Sasca Montană', value: 'Sasca Montană' },
  { label: 'Saschiz', value: 'Saschiz' },
  { label: 'Săsciori', value: 'Săsciori' },
  { label: 'Sascut', value: 'Sascut' },
  { label: 'Sascut-Sat', value: 'Sascut-Sat' },
  { label: 'Sásd', value: 'Sásd' },
  { label: 'Sasebo Shi', value: 'Sasebo Shi' },
  { label: 'Sasel', value: 'Sasel' },
  { label: 'Sasi', value: 'Sasi' },
  { label: 'Šašinci', value: 'Šašinci' },
  { label: 'Saskatoon', value: 'Saskatoon' },
  { label: 'Saskylakh', value: 'Saskylakh' },
  { label: 'Sasmuan', value: 'Sasmuan' },
  { label: 'Sasni', value: 'Sasni' },
  { label: 'Sasnovy Bor', value: 'Sasnovy Bor' },
  { label: 'Sasolburg', value: 'Sasolburg' },
  { label: 'Sason', value: 'Sason' },
  { label: 'Sasovo', value: 'Sasovo' },
  { label: 'Sąspów', value: 'Sąspów' },
  { label: 'Sassa', value: 'Sassa' },
  { label: 'Sassacci', value: 'Sassacci' },
  { label: 'Sassafras', value: 'Sassafras' },
  { label: 'Sassandra', value: 'Sassandra' },
  { label: 'Sassano', value: 'Sassano' },
  { label: 'Sassari', value: 'Sassari' },
  { label: 'Sassello', value: 'Sassello' },
  { label: 'Sassenage', value: 'Sassenage' },
  { label: 'Sassenay', value: 'Sassenay' },
  { label: 'Sassenberg', value: 'Sassenberg' },
  { label: 'Sassenburg', value: 'Sassenburg' },
  { label: 'Sassenheim', value: 'Sassenheim' },
  { label: 'Sassetta', value: 'Sassetta' },
  { label: 'Sassi', value: 'Sassi' },
  { label: 'Sassinoro', value: 'Sassinoro' },
  { label: 'Saßnitz', value: 'Saßnitz' },
  { label: 'Sasso Di Castalda', value: 'Sasso Di Castalda' },
  { label: 'Sasso Marconi', value: 'Sasso Marconi' },
  { label: 'Sassocorvaro', value: 'Sassocorvaro' },
  { label: 'Sassofeltrio', value: 'Sassofeltrio' },
  { label: 'Sassoferrato', value: 'Sassoferrato' },
  { label: 'Sassuolo', value: 'Sassuolo' },
  { label: 'Sástago', value: 'Sástago' },
  { label: 'Sastamala', value: 'Sastamala' },
  { label: 'Sastobe', value: 'Sastobe' },
  { label: 'Sastre', value: 'Sastre' },
  { label: 'Sasunik', value: 'Sasunik' },
  { label: 'Saswad', value: 'Saswad' },
  { label: 'Sasykoli', value: 'Sasykoli' },
  { label: 'Satalice', value: 'Satalice' },
  { label: 'Satan', value: 'Satan' },
  { label: 'Satan', value: 'Satan' },
  { label: 'Satana', value: 'Satana' },
  { label: 'Satanta', value: 'Satanta' },
  { label: 'Sátão', value: 'Sátão' },
  { label: 'Satapuala', value: 'Satapuala' },
  { label: 'Satar', value: 'Satar' },
  { label: 'Satara', value: 'Satara' },
  { label: 'Satara Division', value: 'Satara Division' },
  { label: 'Satarovsky', value: 'Satarovsky' },
  { label: 'Satawal Municipality', value: 'Satawal Municipality' },
  { label: 'Satchinez', value: 'Satchinez' },
  { label: 'Satellite Beach', value: 'Satellite Beach' },
  { label: 'Săteni', value: 'Săteni' },
  { label: 'Säter', value: 'Säter' },
  { label: 'Sathamba', value: 'Sathamba' },
  { label: 'Sathankulam', value: 'Sathankulam' },
  { label: 'Sathon', value: 'Sathon' },
  { label: 'Sathonay-Camp', value: 'Sathonay-Camp' },
  { label: 'Sathonay-Village', value: 'Sathonay-Village' },
  { label: 'Sathupalli', value: 'Sathupalli' },
  { label: 'Sathyamangalam', value: 'Sathyamangalam' },
  { label: 'Saticoy', value: 'Saticoy' },
  { label: 'Satigny', value: 'Satigny' },
  { label: 'Sätila', value: 'Sätila' },
  { label: 'Satillieu', value: 'Satillieu' },
  { label: 'Satinka', value: 'Satinka' },
  { label: 'Satipo', value: 'Satipo' },
  { label: 'Sátiro Dias', value: 'Sátiro Dias' },
  { label: 'Satis', value: 'Satis' },
  { label: 'Satita', value: 'Satita' },
  { label: 'Sativanorte', value: 'Sativanorte' },
  { label: 'Sativasur', value: 'Sativasur' },
  { label: 'Satka', value: 'Satka' },
  { label: 'Satkhira ', value: 'Satkhira ' },
  { label: 'Satkinskiy Rayon', value: 'Satkinskiy Rayon' },
  { label: 'Sătmărel', value: 'Sătmărel' },
  { label: 'Satna', value: 'Satna' },
  { label: 'Satnica Đakovačka', value: 'Satnica Đakovačka' },
  { label: 'Sätofta', value: 'Sätofta' },
  { label: 'Satolas-Et-Bonce', value: 'Satolas-Et-Bonce' },
  { label: 'Sátoraljaújhely', value: 'Sátoraljaújhely' },
  { label: 'Sátoraljaújhelyi Járás', value: 'Sátoraljaújhelyi Járás' },
  { label: 'Šatov', value: 'Šatov' },
  { label: 'Satovcha', value: 'Satovcha' },
  { label: 'Satowan Municipality', value: 'Satowan Municipality' },
  { label: 'Satow-Oberhagen', value: 'Satow-Oberhagen' },
  { label: 'Satpati', value: 'Satpati' },
  { label: 'Satriano', value: 'Satriano' },
  { label: 'Satriano Di Lucania', value: 'Satriano Di Lucania' },
  { label: 'Satrikh', value: 'Satrikh' },
  { label: 'Satrup', value: 'Satrup' },
  { label: 'Sat-Șugatag', value: 'Sat-Șugatag' },
  { label: 'Satsuma', value: 'Satsuma' },
  { label: 'Satsumasendai', value: 'Satsumasendai' },
  { label: 'Satsumasendai Shi', value: 'Satsumasendai Shi' },
  { label: 'Sattahip', value: 'Sattahip' },
  { label: 'Satte', value: 'Satte' },
  { label: 'Satte Shi', value: 'Satte Shi' },
  { label: 'Satteins', value: 'Satteins' },
  { label: 'Sattel', value: 'Sattel' },
  { label: 'Satteldorf', value: 'Satteldorf' },
  { label: 'Sattenapalle', value: 'Sattenapalle' },
  { label: 'Sattledt', value: 'Sattledt' },
  { label: 'Sattur', value: 'Sattur' },
  { label: 'Satu Mare', value: 'Satu Mare' },
  { label: 'Satu Mare', value: 'Satu Mare' },
  { label: 'Satu Mare', value: 'Satu Mare' },
  { label: 'Satu Mare', value: 'Satu Mare' },
  { label: 'Satu Nou', value: 'Satu Nou' },
  { label: 'Satu Nou', value: 'Satu Nou' },
  { label: 'Satu Nou', value: 'Satu Nou' },
  { label: 'Satu Nou', value: 'Satu Nou' },
  { label: 'Satu Nou', value: 'Satu Nou' },
  { label: 'Satu Nou Calopăr', value: 'Satu Nou Calopăr' },
  { label: 'Satu Nou De Jos', value: 'Satu Nou De Jos' },
  { label: 'Satu Nou De Sus', value: 'Satu Nou De Sus' },
  { label: 'Satuba', value: 'Satuba' },
  { label: 'Satubinha', value: 'Satubinha' },
  { label: 'Satuek', value: 'Satuek' },
  { label: 'Satulung', value: 'Satulung' },
  { label: 'Satun', value: 'Satun' },
  { label: 'Saturnino M. Laspiur', value: 'Saturnino M. Laspiur' },
  { label: 'Satwas', value: 'Satwas' },
  { label: 'Saubara', value: 'Saubara' },
  { label: 'Saubens', value: 'Saubens' },
  { label: 'Saubion', value: 'Saubion' },
  { label: 'Saubraz', value: 'Saubraz' },
  { label: 'Saubrigues', value: 'Saubrigues' },
  { label: 'Sauca', value: 'Sauca' },
  { label: 'Saúca', value: 'Saúca' },
  { label: 'Săuca', value: 'Săuca' },
  { label: 'Saucats', value: 'Saucats' },
  { label: 'Sauce', value: 'Sauce' },
  { label: 'Sauce De Luna', value: 'Sauce De Luna' },
  { label: 'Sauceda De La Borda', value: 'Sauceda De La Borda' },
  { label: 'Saucedilla', value: 'Saucedilla' },
  { label: 'Saucejo El', value: 'Saucejo El' },
  { label: 'Saucelle', value: 'Saucelle' },
  { label: 'Săucești', value: 'Săucești' },
  { label: 'Saucier', value: 'Saucier' },
  { label: 'Saucillo', value: 'Saucillo' },
  { label: 'Sauda', value: 'Sauda' },
  { label: 'Saudade Do Iguaçu', value: 'Saudade Do Iguaçu' },
  { label: 'Saudades', value: 'Saudades' },
  { label: 'Sauðárkrókur', value: 'Sauðárkrókur' },
  { label: 'Saúde', value: 'Saúde' },
  { label: 'Saue', value: 'Saue' },
  { label: 'Saue Vald', value: 'Saue Vald' },
  { label: 'Sauensiek', value: 'Sauensiek' },
  { label: 'Sauerlach', value: 'Sauerlach' },
  { label: 'Sauga', value: 'Sauga' },
  { label: 'Saugerties', value: 'Saugerties' },
  { label: 'Saugerties South', value: 'Saugerties South' },
  { label: 'Saughall', value: 'Saughall' },
  { label: 'Saugnac-Et-Cambran', value: 'Saugnac-Et-Cambran' },
  { label: 'Saugor', value: 'Saugor' },
  { label: 'Saugues', value: 'Saugues' },
  { label: 'Saugus', value: 'Saugus' },
  { label: 'Sauherad', value: 'Sauherad' },
  { label: 'Saujon', value: 'Saujon' },
  { label: 'Sauk Centre', value: 'Sauk Centre' },
  { label: 'Sauk City', value: 'Sauk City' },
  { label: 'Sauk County', value: 'Sauk County' },
  { label: 'Sauk Rapids', value: 'Sauk Rapids' },
  { label: 'Sauk Village', value: 'Sauk Village' },
  { label: 'Saukville', value: 'Saukville' },
  { label: 'Saulce-Sur-Rhône', value: 'Saulce-Sur-Rhône' },
  { label: 'Saulcy-Sur-Meurthe', value: 'Saulcy-Sur-Meurthe' },
  { label: 'Sauldorf', value: 'Sauldorf' },
  { label: 'Săuleşti', value: 'Săuleşti' },
  { label: 'Saulgau', value: 'Saulgau' },
  { label: 'Saulgé', value: 'Saulgé' },
  { label: 'Saulgrub', value: 'Saulgrub' },
  { label: 'Șăulia', value: 'Șăulia' },
  { label: 'Saulieu', value: 'Saulieu' },
  { label: 'Șăulița', value: 'Șăulița' },
  { label: 'Saulkrasti', value: 'Saulkrasti' },
  { label: 'Saulnes', value: 'Saulnes' },
  { label: 'Saulny', value: 'Saulny' },
  { label: 'Sault', value: 'Sault' },
  { label: 'Sault Ste. Marie', value: 'Sault Ste. Marie' },
  { label: 'Sault Ste. Marie', value: 'Sault Ste. Marie' },
  { label: 'Saultain', value: 'Saultain' },
  { label: 'Sault-Brénaz', value: 'Sault-Brénaz' },
  { label: 'Sault-Lès-Rethel', value: 'Sault-Lès-Rethel' },
  { label: 'Saulx-Les-Chartreux', value: 'Saulx-Les-Chartreux' },
  { label: 'Saulxures-Lès-Nancy', value: 'Saulxures-Lès-Nancy' },
  { label: 'Saulxures-Sur-Moselotte', value: 'Saulxures-Sur-Moselotte' },
  { label: 'Saulzoir', value: 'Saulzoir' },
  { label: 'Saumur', value: 'Saumur' },
  { label: 'Saundatti', value: 'Saundatti' },
  { label: 'Saunders County', value: 'Saunders County' },
  { label: 'Saundersfoot', value: 'Saundersfoot' },
  { label: 'Sauquillo De Cabezas', value: 'Sauquillo De Cabezas' },
  { label: 'Sauri', value: 'Sauri' },
  { label: 'Saurikh', value: 'Saurikh' },
  { label: 'Saurimo', value: 'Saurimo' },
  { label: 'Sauris Di Sotto', value: 'Sauris Di Sotto' },
  { label: 'Saus  Camallera I Llampaies', value: 'Saus  Camallera I Llampaies' },
  { label: 'Șăușa', value: 'Șăușa' },
  { label: 'Sausalito', value: 'Sausalito' },
  { label: 'Sausar', value: 'Sausar' },
  { label: 'Sausheim', value: 'Sausheim' },
  { label: 'Saussan', value: 'Saussan' },
  { label: 'Saussay', value: 'Saussay' },
  { label: 'Sausset-Les-Pins', value: 'Sausset-Les-Pins' },
  { label: 'Saut', value: 'Saut' },
  { label: 'Sauta', value: 'Sauta' },
  { label: 'Sautens', value: 'Sautens' },
  { label: 'Sauteurs', value: 'Sauteurs' },
  { label: 'Sautron', value: 'Sautron' },
  { label: 'Sauvagnon', value: 'Sauvagnon' },
  { label: 'Sauve', value: 'Sauve' },
  { label: 'Sauverny', value: 'Sauverny' },
  { label: 'Sauveterre', value: 'Sauveterre' },
  { label: 'Sauveterre-De-Béarn', value: 'Sauveterre-De-Béarn' },
  { label: 'Sauveterre-De-Guyenne', value: 'Sauveterre-De-Guyenne' },
  { label: 'Sauvian', value: 'Sauvian' },
  { label: 'Sauviat-Sur-Vige', value: 'Sauviat-Sur-Vige' },
  { label: 'Sauvigny-Les-Bois', value: 'Sauvigny-Les-Bois' },
  { label: 'Sauvo', value: 'Sauvo' },
  { label: 'Sauxillanges', value: 'Sauxillanges' },
  { label: 'Sauz De Calera', value: 'Sauz De Calera' },
  { label: 'Sauzal', value: 'Sauzal' },
  { label: 'Sauzal El', value: 'Sauzal El' },
  { label: 'Sauzay', value: 'Sauzay' },
  { label: 'Sauze Di Cesana', value: 'Sauze Di Cesana' },
  { label: 'Sauze Doulx', value: 'Sauze Doulx' },
  { label: 'Sauzet', value: 'Sauzet' },
  { label: 'Sauzé-Vaussais', value: 'Sauzé-Vaussais' },
  { label: 'Sava', value: 'Sava' },
  { label: 'Săvădisla', value: 'Săvădisla' },
  { label: 'Savadkuh', value: 'Savadkuh' },
  { label: 'Savage', value: 'Savage' },
  { label: 'Savage', value: 'Savage' },
  { label: 'Savália', value: 'Savália' },
  { label: 'Savallà Del Comtat', value: 'Savallà Del Comtat' },
  { label: 'Savalou', value: 'Savalou' },
  { label: 'Savanna', value: 'Savanna' },
  { label: 'Savannah', value: 'Savannah' },
  { label: 'Savannah', value: 'Savannah' },
  { label: 'Savannah', value: 'Savannah' },
  { label: 'Savannah', value: 'Savannah' },
  { label: 'Savannah Bight', value: 'Savannah Bight' },
  { label: 'Savannah-La-Mar Business Dist.', value: 'Savannah-La-Mar Business Dist.' },
  { label: 'Savannakhet', value: 'Savannakhet' },
  { label: 'Savanna-La-Mar', value: 'Savanna-La-Mar' },
  { label: 'Savannes', value: 'Savannes' },
  { label: 'Savannesgeorge/Constitution', value: 'Savannesgeorge/Constitution' },
  { label: 'Savantvadi', value: 'Savantvadi' },
  { label: 'Savanur', value: 'Savanur' },
  { label: 'Sävar', value: 'Sävar' },
  { label: 'Savarkundla', value: 'Savarkundla' },
  { label: 'Savarna-Conventello', value: 'Savarna-Conventello' },
  { label: 'Săvârşin', value: 'Săvârşin' },
  { label: 'Savasleyka', value: 'Savasleyka' },
  { label: 'Savasse', value: 'Savasse' },
  { label: 'Sävast', value: 'Sävast' },
  { label: 'Savaştepe', value: 'Savaştepe' },
  { label: 'Savave Village', value: 'Savave Village' },
  { label: 'Savda', value: 'Savda' },
  { label: 'Savé', value: 'Savé' },
  { label: 'Saveh', value: 'Saveh' },
  { label: 'Savelli', value: 'Savelli' },
  { label: 'Savelugu', value: 'Savelugu' },
  { label: 'Savenay', value: 'Savenay' },
  { label: 'Săveni', value: 'Săveni' },
  { label: 'Săveni', value: 'Săveni' },
  { label: 'Savennières', value: 'Savennières' },
  { label: 'Saverdun', value: 'Saverdun' },
  { label: 'Saverne', value: 'Saverne' },
  { label: 'Saviano', value: 'Saviano' },
  { label: 'Savièse', value: 'Savièse' },
  { label: 'Savigliano', value: 'Savigliano' },
  { label: 'Savignano Irpino', value: 'Savignano Irpino' },
  { label: 'Savignano Sul Panaro', value: 'Savignano Sul Panaro' },
  { label: 'Savignano Sul Rubicone', value: 'Savignano Sul Rubicone' },
  { label: 'Savigné', value: 'Savigné' },
  { label: 'Savigné-Sur-Lathan', value: 'Savigné-Sur-Lathan' },
  { label: 'Savigneux', value: 'Savigneux' },
  { label: 'Savigno', value: 'Savigno' },
  { label: 'Savignone', value: 'Savignone' },
  { label: 'Savigny', value: 'Savigny' },
  { label: 'Savigny', value: 'Savigny' },
  { label: 'Savigny-En-Sancerre', value: 'Savigny-En-Sancerre' },
  { label: 'Savigny-En-Véron', value: 'Savigny-En-Véron' },
  { label: 'Savigny-Lès-Beaune', value: 'Savigny-Lès-Beaune' },
  { label: 'Savigny-Le-Temple', value: 'Savigny-Le-Temple' },
  { label: 'Savigny-Lévescault', value: 'Savigny-Lévescault' },
  { label: 'Savigny-Sur-Braye', value: 'Savigny-Sur-Braye' },
  { label: 'Savigny-Sur-Orge', value: 'Savigny-Sur-Orge' },
  { label: 'Saviñao O', value: 'Saviñao O' },
  { label: 'Săvineşti', value: 'Săvineşti' },
  { label: 'Savinka', value: 'Savinka' },
  { label: 'Savino', value: 'Savino' },
  { label: 'Savinskaya', value: 'Savinskaya' },
  { label: 'Savinskiy Rayon', value: 'Savinskiy Rayon' },
  { label: 'Savio', value: 'Savio' },
  { label: 'Saviore', value: 'Saviore' },
  { label: 'Savitaipale', value: 'Savitaipale' },
  { label: 'Sävja', value: 'Sävja' },
  { label: 'Savlaj', value: 'Savlaj' },
  { label: 'Savli', value: 'Savli' },
  { label: 'Šavnik', value: 'Šavnik' },
  { label: 'Savoca', value: 'Savoca' },
  { label: 'Savogna', value: 'Savogna' },
  { label: 'Savogna Disonzo', value: 'Savogna Disonzo' },
  { label: 'Savoia Di Lucania', value: 'Savoia Di Lucania' },
  { label: 'Savoie', value: 'Savoie' },
  { label: 'Savojbolagh', value: 'Savojbolagh' },
  { label: 'Savona', value: 'Savona' },
  { label: 'Savonera', value: 'Savonera' },
  { label: 'Savonlinna', value: 'Savonlinna' },
  { label: 'Savonnières', value: 'Savonnières' },
  { label: 'Savonranta', value: 'Savonranta' },
  { label: 'Savoy', value: 'Savoy' },
  { label: 'Savran', value: 'Savran' },
  { label: 'Şavşat', value: 'Şavşat' },
  { label: 'Sävsjö', value: 'Sävsjö' },
  { label: 'Savukoski', value: 'Savukoski' },
  { label: 'Savur', value: 'Savur' },
  { label: 'Savvinskaya Sloboda', value: 'Savvinskaya Sloboda' },
  { label: 'Savy-Berlette', value: 'Savy-Berlette' },
  { label: 'Savyetski Rayon', value: 'Savyetski Rayon' },
  { label: 'Savyntsi', value: 'Savyntsi' },
  { label: 'Savyon', value: 'Savyon' },
  { label: 'Saw Creek', value: 'Saw Creek' },
  { label: 'Sawa Sawa', value: 'Sawa Sawa' },
  { label: 'Sawai Madhopur', value: 'Sawai Madhopur' },
  { label: 'Sawākin', value: 'Sawākin' },
  { label: 'Sawang Daen Din', value: 'Sawang Daen Din' },
  { label: 'Sawang Wirawong', value: 'Sawang Wirawong' },
  { label: 'Sawangan', value: 'Sawangan' },
  { label: 'Sawankhalok', value: 'Sawankhalok' },
  { label: 'Sawantvadi', value: 'Sawantvadi' },
  { label: 'Sawara', value: 'Sawara' },
  { label: 'Sawbridgeworth', value: 'Sawbridgeworth' },
  { label: 'Sawgrass', value: 'Sawgrass' },
  { label: 'Sawin', value: 'Sawin' },
  { label: 'Sawkta', value: 'Sawkta' },
  { label: 'Sawla-Tuna-Kalba', value: 'Sawla-Tuna-Kalba' },
  { label: 'Sawmills', value: 'Sawmills' },
  { label: 'Sawston', value: 'Sawston' },
  { label: 'Sawtell', value: 'Sawtell' },
  { label: 'Sawtry', value: 'Sawtry' },
  { label: 'Sawyer County', value: 'Sawyer County' },
  { label: 'Sawyers', value: 'Sawyers' },
  { label: 'Sawyerwood', value: 'Sawyerwood' },
  { label: 'Sax', value: 'Sax' },
  { label: 'Saxapahaw', value: 'Saxapahaw' },
  { label: 'Saxilby', value: 'Saxilby' },
  { label: 'Saxmundham', value: 'Saxmundham' },
  { label: 'Saxon', value: 'Saxon' },
  { label: 'Saxon', value: 'Saxon' },
  { label: 'Saxonburg', value: 'Saxonburg' },
  { label: 'Saxtorpsskogen', value: 'Saxtorpsskogen' },
  { label: 'Say', value: 'Say' },
  { label: 'Sayago', value: 'Sayago' },
  { label: 'Sayalkudi', value: 'Sayalkudi' },
  { label: 'Sayalonga', value: 'Sayalonga' },
  { label: 'Sayama', value: 'Sayama' },
  { label: 'Sayán', value: 'Sayán' },
  { label: 'Sayanogorsk', value: 'Sayanogorsk' },
  { label: 'Sayansk', value: 'Sayansk' },
  { label: 'Sayansk', value: 'Sayansk' },
  { label: 'Sayanskiy Rayon', value: 'Sayanskiy Rayon' },
  { label: 'Sayaq', value: 'Sayaq' },
  { label: 'Sayat', value: 'Sayat' },
  { label: 'Saýat', value: 'Saýat' },
  { label: 'Sayat’-Nova', value: 'Sayat’-Nova' },
  { label: 'Sayatón', value: 'Sayatón' },
  { label: 'Sayaxché', value: 'Sayaxché' },
  { label: 'Saybrook Manor', value: 'Saybrook Manor' },
  { label: 'Sayda', value: 'Sayda' },
  { label: 'Şaydnāyā', value: 'Şaydnāyā' },
  { label: 'Sayhāt', value: 'Sayhāt' },
  { label: 'Sayhut', value: 'Sayhut' },
  { label: 'Saykhin', value: 'Saykhin' },
  { label: 'Sayla', value: 'Sayla' },
  { label: 'Saylorsburg', value: 'Saylorsburg' },
  { label: 'Saylorville', value: 'Saylorville' },
  { label: 'Säynätsalo', value: 'Säynätsalo' },
  { label: 'Sayötesh', value: 'Sayötesh' },
  { label: 'Sayre', value: 'Sayre' },
  { label: 'Sayre', value: 'Sayre' },
  { label: 'Sayreville', value: 'Sayreville' },
  { label: 'Sayreville Junction', value: 'Sayreville Junction' },
  { label: 'Saysain', value: 'Saysain' },
  { label: 'Saysain', value: 'Saysain' },
  { label: 'Sayula', value: 'Sayula' },
  { label: 'Sayula De Alemán', value: 'Sayula De Alemán' },
  { label: 'Sayulilla', value: 'Sayulilla' },
  { label: 'Sayulita', value: 'Sayulita' },
  { label: 'Sayun', value: 'Sayun' },
  { label: 'Sayville', value: 'Sayville' },
  { label: 'Sayyān', value: 'Sayyān' },
  { label: 'Sázava', value: 'Sázava' },
  { label: 'Saze', value: 'Saze' },
  { label: 'Sazes Da Beira', value: 'Sazes Da Beira' },
  { label: 'Saziyan', value: 'Saziyan' },
  { label: 'Sazonovo', value: 'Sazonovo' },
  { label: 'Sbiba', value: 'Sbiba' },
  { label: 'Sbikha', value: 'Sbikha' },
  { label: 'Scaër', value: 'Scaër' },
  { label: 'Scăeşti', value: 'Scăeşti' },
  { label: 'Scafa', value: 'Scafa' },
  { label: 'Scafati', value: 'Scafati' },
  { label: 'Scaggsville', value: 'Scaggsville' },
  { label: 'Scagnello', value: 'Scagnello' },
  { label: 'Scala', value: 'Scala' },
  { label: 'Scala Coeli', value: 'Scala Coeli' },
  { label: 'Scalby', value: 'Scalby' },
  { label: 'Scaldasole', value: 'Scaldasole' },
  { label: 'Scalea', value: 'Scalea' },
  { label: 'Scalenghe', value: 'Scalenghe' },
  { label: 'Scaletta Zanclea', value: 'Scaletta Zanclea' },
  { label: 'Scalloway', value: 'Scalloway' },
  { label: 'Scalo Di Baragiano', value: 'Scalo Di Baragiano' },
  { label: 'Scalo Romagnano Al Monte', value: 'Scalo Romagnano Al Monte' },
  { label: 'Scaltenigo', value: 'Scaltenigo' },
  { label: 'Scampia', value: 'Scampia' },
  { label: 'Scampitella', value: 'Scampitella' },
  { label: 'Scandale', value: 'Scandale' },
  { label: 'Scandia', value: 'Scandia' },
  { label: 'Scandiano', value: 'Scandiano' },
  { label: 'Scandicci', value: 'Scandicci' },
  { label: 'Scandolara Ravara', value: 'Scandolara Ravara' },
  { label: 'Scandolara Ripa Doglio', value: 'Scandolara Ripa Doglio' },
  { label: 'Scandriglia', value: 'Scandriglia' },
  { label: 'Scannabue-Cascine Capri', value: 'Scannabue-Cascine Capri' },
  { label: 'Scanno', value: 'Scanno' },
  { label: 'Scano Al Brembo', value: 'Scano Al Brembo' },
  { label: 'Scano Di Montiferro', value: 'Scano Di Montiferro' },
  { label: 'Scansano', value: 'Scansano' },
  { label: 'Scânteia', value: 'Scânteia' },
  { label: 'Scânteia', value: 'Scânteia' },
  { label: 'Scânteiești', value: 'Scânteiești' },
  { label: 'Scanzano Jonico', value: 'Scanzano Jonico' },
  { label: 'Scanzorosciate', value: 'Scanzorosciate' },
  { label: 'Scapoli', value: 'Scapoli' },
  { label: 'Scappoose', value: 'Scappoose' },
  { label: 'Scarborough', value: 'Scarborough' },
  { label: 'Scarborough', value: 'Scarborough' },
  { label: 'Scarborough', value: 'Scarborough' },
  { label: 'Scarborough', value: 'Scarborough' },
  { label: 'Scarborough', value: 'Scarborough' },
  { label: 'Scarborough', value: 'Scarborough' },
  { label: 'Scarcelli', value: 'Scarcelli' },
  { label: 'Scarcroft', value: 'Scarcroft' },
  { label: 'Scardovari', value: 'Scardovari' },
  { label: 'Scario', value: 'Scario' },
  { label: 'Scărişoara', value: 'Scărişoara' },
  { label: 'Scărişoara', value: 'Scărişoara' },
  { label: 'Scarlino', value: 'Scarlino' },
  { label: 'Scarlino Scalo', value: 'Scarlino Scalo' },
  { label: 'Scarmagno', value: 'Scarmagno' },
  { label: 'Scarnafigi', value: 'Scarnafigi' },
  { label: 'Scarness', value: 'Scarness' },
  { label: 'Scarperia', value: 'Scarperia' },
  { label: 'Scarsdale', value: 'Scarsdale' },
  { label: 'Scawby', value: 'Scawby' },
  { label: 'Sceaux', value: 'Sceaux' },
  { label: 'Scena', value: 'Scena' },
  { label: 'Scena - Schenna', value: 'Scena - Schenna' },
  { label: 'Scenic', value: 'Scenic' },
  { label: 'Scenic Oaks', value: 'Scenic Oaks' },
  { label: 'Scenic Rim', value: 'Scenic Rim' },
  { label: 'Scerne', value: 'Scerne' },
  { label: 'Scerni', value: 'Scerni' },
  { label: 'Scey-Sur-Saône-Et-Saint-Albin', value: 'Scey-Sur-Saône-Et-Saint-Albin' },
  { label: 'Schaafheim', value: 'Schaafheim' },
  { label: 'Schaalby', value: 'Schaalby' },
  { label: 'Schaan', value: 'Schaan' },
  { label: 'Schachen', value: 'Schachen' },
  { label: 'Schachen Bei Vorau', value: 'Schachen Bei Vorau' },
  { label: 'Schachendorf', value: 'Schachendorf' },
  { label: 'Schacht-Audorf', value: 'Schacht-Audorf' },
  { label: 'Schäffern', value: 'Schäffern' },
  { label: 'Schaffhausen', value: 'Schaffhausen' },
  { label: 'Schafflund', value: 'Schafflund' },
  { label: 'Schafisheim', value: 'Schafisheim' },
  { label: 'Schafstädt', value: 'Schafstädt' },
  { label: 'Schäftlarn', value: 'Schäftlarn' },
  { label: 'Schafwiesen', value: 'Schafwiesen' },
  { label: 'Schagen', value: 'Schagen' },
  { label: 'Schagerbrug', value: 'Schagerbrug' },
  { label: 'Schaijk', value: 'Schaijk' },
  { label: 'Schalchen', value: 'Schalchen' },
  { label: 'Schalchham', value: 'Schalchham' },
  { label: 'Schalkau', value: 'Schalkau' },
  { label: 'Schalkhaar', value: 'Schalkhaar' },
  { label: 'Schalksmühle', value: 'Schalksmühle' },
  { label: 'Schalkwijk', value: 'Schalkwijk' },
  { label: 'Schall Circle', value: 'Schall Circle' },
  { label: 'Schallstadt', value: 'Schallstadt' },
  { label: 'Schandorf', value: 'Schandorf' },
  { label: 'Schänis', value: 'Schänis' },
  { label: 'Schapen', value: 'Schapen' },
  { label: 'Scharans', value: 'Scharans' },
  { label: 'Scharbeutz', value: 'Scharbeutz' },
  { label: 'Schardam', value: 'Schardam' },
  { label: 'Schardenberg', value: 'Schardenberg' },
  { label: 'Schärding', value: 'Schärding' },
  { label: 'Schärding Vorstadt', value: 'Schärding Vorstadt' },
  { label: 'Scharendijke', value: 'Scharendijke' },
  { label: 'Scharfenstein', value: 'Scharfenstein' },
  { label: 'Scharn', value: 'Scharn' },
  { label: 'Scharndorf', value: 'Scharndorf' },
  { label: 'Scharnebeck', value: 'Scharnebeck' },
  { label: 'Scharnegoutum', value: 'Scharnegoutum' },
  { label: 'Scharnitz', value: 'Scharnitz' },
  { label: 'Scharnstein', value: 'Scharnstein' },
  { label: 'Scharwoude', value: 'Scharwoude' },
  { label: 'Schashagen', value: 'Schashagen' },
  { label: 'Schattdorf', value: 'Schattdorf' },
  { label: 'Schattendorf', value: 'Schattendorf' },
  { label: 'Schattwald', value: 'Schattwald' },
  { label: 'Schauenstein', value: 'Schauenstein' },
  { label: 'Schaufling', value: 'Schaufling' },
  { label: 'Schaumburg', value: 'Schaumburg' },
  { label: 'Schechen', value: 'Schechen' },
  { label: 'Schechingen', value: 'Schechingen' },
  { label: 'Scheden', value: 'Scheden' },
  { label: 'Scheemda', value: 'Scheemda' },
  { label: 'Scheer', value: 'Scheer' },
  { label: 'Scheeßel', value: 'Scheeßel' },
  { label: 'Scheffau Am Tennengebirge', value: 'Scheffau Am Tennengebirge' },
  { label: 'Scheffau Am Wilden Kaiser', value: 'Scheffau Am Wilden Kaiser' },
  { label: 'Scheggia', value: 'Scheggia' },
  { label: 'Scheggino', value: 'Scheggino' },
  { label: 'Scheia', value: 'Scheia' },
  { label: 'Scheia', value: 'Scheia' },
  { label: 'Scheibbs', value: 'Scheibbs' },
  { label: 'Scheibenberg', value: 'Scheibenberg' },
  { label: 'Scheidegg', value: 'Scheidegg' },
  { label: 'Scheifling', value: 'Scheifling' },
  { label: 'Scheinfeld', value: 'Scheinfeld' },
  { label: 'Scheiu De Jos', value: 'Scheiu De Jos' },
  { label: 'Scheiu De Sus', value: 'Scheiu De Sus' },
  { label: 'Schela', value: 'Schela' },
  { label: 'Schelfstadt', value: 'Schelfstadt' },
  { label: 'Schelklingen', value: 'Schelklingen' },
  { label: 'Schelle', value: 'Schelle' },
  { label: 'Schellenberg', value: 'Schellenberg' },
  { label: 'Schellerten', value: 'Schellerten' },
  { label: 'Schellhorn', value: 'Schellhorn' },
  { label: 'Schellingwoude', value: 'Schellingwoude' },
  { label: 'Schellinkhout', value: 'Schellinkhout' },
  { label: 'Schelluinen', value: 'Schelluinen' },
  { label: 'Schenectady', value: 'Schenectady' },
  { label: 'Schenectady County', value: 'Schenectady County' },
  { label: 'Schenefeld', value: 'Schenefeld' },
  { label: 'Schengen', value: 'Schengen' },
  { label: 'Schenkendöbern', value: 'Schenkendöbern' },
  { label: 'Schenkenfelden', value: 'Schenkenfelden' },
  { label: 'Schenkenzell', value: 'Schenkenzell' },
  { label: 'Schenklengsfeld', value: 'Schenklengsfeld' },
  { label: 'Schenkon', value: 'Schenkon' },
  { label: 'Schererville', value: 'Schererville' },
  { label: 'Schermbeck', value: 'Schermbeck' },
  { label: 'Schermen', value: 'Schermen' },
  { label: 'Schermerhorn', value: 'Schermerhorn' },
  { label: 'Schernberg', value: 'Schernberg' },
  { label: 'Scherpenisse', value: 'Scherpenisse' },
  { label: 'Scherpenzeel', value: 'Scherpenzeel' },
  { label: 'Scherstetten', value: 'Scherstetten' },
  { label: 'Schertz', value: 'Schertz' },
  { label: 'Scherwiller', value: 'Scherwiller' },
  { label: 'Scheßlitz', value: 'Scheßlitz' },
  { label: 'Scheuerfeld', value: 'Scheuerfeld' },
  { label: 'Scheuring', value: 'Scheuring' },
  { label: 'Scheveningen', value: 'Scheveningen' },
  { label: 'Scheyern', value: 'Scheyern' },
  { label: 'Schiavi Di Abruzzo', value: 'Schiavi Di Abruzzo' },
  { label: 'Schiavon', value: 'Schiavon' },
  { label: 'Schiebroek', value: 'Schiebroek' },
  { label: 'Schiedam', value: 'Schiedam' },
  { label: 'Schieder-Schwalenberg', value: 'Schieder-Schwalenberg' },
  { label: 'Schiefling Am See', value: 'Schiefling Am See' },
  { label: 'Schieren', value: 'Schieren' },
  { label: 'Schierling', value: 'Schierling' },
  { label: 'Schiermonnikoog', value: 'Schiermonnikoog' },
  { label: 'Schiers', value: 'Schiers' },
  { label: 'Schiffdorf', value: 'Schiffdorf' },
  { label: 'Schifferstadt', value: 'Schifferstadt' },
  { label: 'Schifflange', value: 'Schifflange' },
  { label: 'Schiffweiler', value: 'Schiffweiler' },
  { label: 'Schignano', value: 'Schignano' },
  { label: 'Schijndel', value: 'Schijndel' },
  { label: 'Schilberg', value: 'Schilberg' },
  { label: 'Schildau', value: 'Schildau' },
  { label: 'Schilde', value: 'Schilde' },
  { label: 'Schildorn', value: 'Schildorn' },
  { label: 'Schildwolde', value: 'Schildwolde' },
  { label: 'Schiller Park', value: 'Schiller Park' },
  { label: 'Schillig', value: 'Schillig' },
  { label: 'Schillingen', value: 'Schillingen' },
  { label: 'Schillingsfürst', value: 'Schillingsfürst' },
  { label: 'Schilpario', value: 'Schilpario' },
  { label: 'Schiltach', value: 'Schiltach' },
  { label: 'Schiltberg', value: 'Schiltberg' },
  { label: 'Schiltigheim', value: 'Schiltigheim' },
  { label: 'Schimatári', value: 'Schimatári' },
  { label: 'Schimmert', value: 'Schimmert' },
  { label: 'Schindellegi', value: 'Schindellegi' },
  { label: 'Schinnen', value: 'Schinnen' },
  { label: 'Schinveld', value: 'Schinveld' },
  { label: 'Schinznach Bad', value: 'Schinznach Bad' },
  { label: 'Schinznach Dorf', value: 'Schinznach Dorf' },
  { label: 'Schio', value: 'Schio' },
  { label: 'Schiphol-Rijk', value: 'Schiphol-Rijk' },
  { label: 'Schipkau', value: 'Schipkau' },
  { label: 'Schipluiden', value: 'Schipluiden' },
  { label: 'Schirgiswalde', value: 'Schirgiswalde' },
  { label: 'Schirmeck', value: 'Schirmeck' },
  { label: 'Schirmitz', value: 'Schirmitz' },
  { label: 'Schirnding', value: 'Schirnding' },
  { label: 'Schirrhein', value: 'Schirrhein' },
  { label: 'Schísma Eloúndas', value: 'Schísma Eloúndas' },
  { label: 'Schitu', value: 'Schitu' },
  { label: 'Schitu', value: 'Schitu' },
  { label: 'Schitu', value: 'Schitu' },
  { label: 'Schitu Frumoasa', value: 'Schitu Frumoasa' },
  { label: 'Schitu-Duca', value: 'Schitu-Duca' },
  { label: 'Schitu-Goleşti', value: 'Schitu-Goleşti' },
  { label: 'Schiulești', value: 'Schiulești' },
  { label: 'Schivenoglia', value: 'Schivenoglia' },
  { label: 'Schkeuditz', value: 'Schkeuditz' },
  { label: 'Schkölen', value: 'Schkölen' },
  { label: 'Schkopau', value: 'Schkopau' },
  { label: 'Schladen', value: 'Schladen' },
  { label: 'Schladming', value: 'Schladming' },
  { label: 'Schlagsdorf', value: 'Schlagsdorf' },
  { label: 'Schlaitdorf', value: 'Schlaitdorf' },
  { label: 'Schlaiten', value: 'Schlaiten' },
  { label: 'Schlaitz', value: 'Schlaitz' },
  { label: 'Schlangen', value: 'Schlangen' },
  { label: 'Schlangenbad', value: 'Schlangenbad' },
  { label: 'Schlat', value: 'Schlat' },
  { label: 'Schlatt', value: 'Schlatt' },
  { label: 'Schleben', value: 'Schleben' },
  { label: 'Schleching', value: 'Schleching' },
  { label: 'Schleedorf', value: 'Schleedorf' },
  { label: 'Schlegel', value: 'Schlegel' },
  { label: 'Schlehdorf', value: 'Schlehdorf' },
  { label: 'Schleicher County', value: 'Schleicher County' },
  { label: 'Schleid', value: 'Schleid' },
  { label: 'Schleiden', value: 'Schleiden' },
  { label: 'Schleife', value: 'Schleife' },
  { label: 'Schleinbach', value: 'Schleinbach' },
  { label: 'Schleitheim', value: 'Schleitheim' },
  { label: 'Schleiz', value: 'Schleiz' },
  { label: 'Schleswig', value: 'Schleswig' },
  { label: 'Schlettau', value: 'Schlettau' },
  { label: 'Schleusingen', value: 'Schleusingen' },
  { label: 'Schley County', value: 'Schley County' },
  { label: 'Schlieben', value: 'Schlieben' },
  { label: 'Schliengen', value: 'Schliengen' },
  { label: 'Schlier', value: 'Schlier' },
  { label: 'Schlierbach', value: 'Schlierbach' },
  { label: 'Schlierbach', value: 'Schlierbach' },
  { label: 'Schlieren', value: 'Schlieren' },
  { label: 'Schlieren / Boden', value: 'Schlieren / Boden' },
  { label: 'Schlieren / Engstingerquartier', value: 'Schlieren / Engstingerquartier' },
  { label: 'Schlieren / Freiestrasse', value: 'Schlieren / Freiestrasse' },
  { label: 'Schlieren / Kamp', value: 'Schlieren / Kamp' },
  { label: 'Schlieren / Spital', value: 'Schlieren / Spital' },
  { label: 'Schlieren / Zentrum', value: 'Schlieren / Zentrum' },
  { label: 'Schliersee', value: 'Schliersee' },
  { label: 'Schlins', value: 'Schlins' },
  { label: 'Schlitters', value: 'Schlitters' },
  { label: 'Schlitz', value: 'Schlitz' },
  { label: 'Schloßvippach', value: 'Schloßvippach' },
  { label: 'Schlotheim', value: 'Schlotheim' },
  { label: 'Schluchsee', value: 'Schluchsee' },
  { label: 'Schlüchtern', value: 'Schlüchtern' },
  { label: 'Schlüsselfeld', value: 'Schlüsselfeld' },
  { label: 'Schlusser', value: 'Schlusser' },
  { label: 'Schlüsslberg', value: 'Schlüsslberg' },
  { label: 'Schmalfeld', value: 'Schmalfeld' },
  { label: 'Schmalkalden', value: 'Schmalkalden' },
  { label: 'Schmallenberg', value: 'Schmallenberg' },
  { label: 'Schmargendorf', value: 'Schmargendorf' },
  { label: 'Schmelz', value: 'Schmelz' },
  { label: 'Schmerikon', value: 'Schmerikon' },
  { label: 'Schmidgaden', value: 'Schmidgaden' },
  { label: 'Schmidmühlen', value: 'Schmidmühlen' },
  { label: 'Schmiechen', value: 'Schmiechen' },
  { label: 'Schmiedeberg', value: 'Schmiedeberg' },
  { label: 'Schmiedefeld', value: 'Schmiedefeld' },
  { label: 'Schmiedefeld Am Rennsteig', value: 'Schmiedefeld Am Rennsteig' },
  { label: 'Schmitten', value: 'Schmitten' },
  { label: 'Schmitten', value: 'Schmitten' },
  { label: 'Schmöckwitz', value: 'Schmöckwitz' },
  { label: 'Schmölln', value: 'Schmölln' },
  { label: 'Schnaitsee', value: 'Schnaitsee' },
  { label: 'Schnaittach', value: 'Schnaittach' },
  { label: 'Schnaittenbach', value: 'Schnaittenbach' },
  { label: 'Schneckenlohe', value: 'Schneckenlohe' },
  { label: 'Schnecksville', value: 'Schnecksville' },
  { label: 'Schneeberg', value: 'Schneeberg' },
  { label: 'Schnega', value: 'Schnega' },
  { label: 'Schneidlingen', value: 'Schneidlingen' },
  { label: 'Schneizlreuth', value: 'Schneizlreuth' },
  { label: 'Schnelldorf', value: 'Schnelldorf' },
  { label: 'Schnepfau', value: 'Schnepfau' },
  { label: 'Schnersheim', value: 'Schnersheim' },
  { label: 'Schneverdingen', value: 'Schneverdingen' },
  { label: 'Schnifis', value: 'Schnifis' },
  { label: 'Schnürpflingen', value: 'Schnürpflingen' },
  { label: 'Schobüll', value: 'Schobüll' },
  { label: 'Schochwitz', value: 'Schochwitz' },
  { label: 'Schöder', value: 'Schöder' },
  { label: 'Schœneck', value: 'Schœneck' },
  { label: 'Schoeneck', value: 'Schoeneck' },
  { label: 'Schöffengrund', value: 'Schöffengrund' },
  { label: 'Schofield', value: 'Schofield' },
  { label: 'Schofield Barracks', value: 'Schofield Barracks' },
  { label: 'Schofields', value: 'Schofields' },
  { label: 'Schöftland', value: 'Schöftland' },
  { label: 'Schöfweg', value: 'Schöfweg' },
  { label: 'Schoharie', value: 'Schoharie' },
  { label: 'Schoharie County', value: 'Schoharie County' },
  { label: 'Scholefield', value: 'Scholefield' },
  { label: 'Scholes', value: 'Scholes' },
  { label: 'Schöllkrippen', value: 'Schöllkrippen' },
  { label: 'Schöllnach', value: 'Schöllnach' },
  { label: 'Schömberg', value: 'Schömberg' },
  { label: 'Schomyslitsa', value: 'Schomyslitsa' },
  { label: 'Schonach Im Schwarzwald', value: 'Schonach Im Schwarzwald' },
  { label: 'Schönaich', value: 'Schönaich' },
  { label: 'Schönau', value: 'Schönau' },
  { label: 'Schönau', value: 'Schönau' },
  { label: 'Schönau Am Königssee', value: 'Schönau Am Königssee' },
  { label: 'Schönau An Der Triesting', value: 'Schönau An Der Triesting' },
  { label: 'Schönau Im Schwarzwald', value: 'Schönau Im Schwarzwald' },
  { label: 'Schönau-Berzdorf', value: 'Schönau-Berzdorf' },
  { label: 'Schönbach', value: 'Schönbach' },
  { label: 'Schönbach', value: 'Schönbach' },
  { label: 'Schönberg', value: 'Schönberg' },
  { label: 'Schönberg', value: 'Schönberg' },
  { label: 'Schönberg Im Stubaital', value: 'Schönberg Im Stubaital' },
  { label: 'Schönborn', value: 'Schönborn' },
  { label: 'Schönburg', value: 'Schönburg' },
  { label: 'Schöndorf', value: 'Schöndorf' },
  { label: 'Schondorf Am Ammersee', value: 'Schondorf Am Ammersee' },
  { label: 'Schondra', value: 'Schondra' },
  { label: 'Schönebeck', value: 'Schönebeck' },
  { label: 'Schöneberg', value: 'Schöneberg' },
  { label: 'Schöneck', value: 'Schöneck' },
  { label: 'Schönecken', value: 'Schönecken' },
  { label: 'Schönefeld', value: 'Schönefeld' },
  { label: 'Schöneiche', value: 'Schöneiche' },
  { label: 'Schönenberg-Kübelberg', value: 'Schönenberg-Kübelberg' },
  { label: 'Schönenbuch', value: 'Schönenbuch' },
  { label: 'Schönenwerd', value: 'Schönenwerd' },
  { label: 'Schönering', value: 'Schönering' },
  { label: 'Schönewalde', value: 'Schönewalde' },
  { label: 'Schönfeld', value: 'Schönfeld' },
  { label: 'Schongau', value: 'Schongau' },
  { label: 'Schöngeising', value: 'Schöngeising' },
  { label: 'Schönhausen', value: 'Schönhausen' },
  { label: 'Schönheide', value: 'Schönheide' },
  { label: 'Schöningen', value: 'Schöningen' },
  { label: 'Schönkirchen', value: 'Schönkirchen' },
  { label: 'Schönkirchen-Reyersdorf', value: 'Schönkirchen-Reyersdorf' },
  { label: 'Schönsee', value: 'Schönsee' },
  { label: 'Schönstedt', value: 'Schönstedt' },
  { label: 'Schonstett', value: 'Schonstett' },
  { label: 'Schönthal', value: 'Schönthal' },
  { label: 'Schonungen', value: 'Schonungen' },
  { label: 'Schönwald', value: 'Schönwald' },
  { label: 'Schönwald', value: 'Schönwald' },
  { label: 'Schönwalde Am Bungsberg', value: 'Schönwalde Am Bungsberg' },
  { label: 'Schönwies', value: 'Schönwies' },
  { label: 'Schoolcraft', value: 'Schoolcraft' },
  { label: 'Schoolcraft County', value: 'Schoolcraft County' },
  { label: 'Schoondijke', value: 'Schoondijke' },
  { label: 'Schoonebeek', value: 'Schoonebeek' },
  { label: 'Schoonhoven', value: 'Schoonhoven' },
  { label: 'Schoonoord', value: 'Schoonoord' },
  { label: 'Schoonrewoerd', value: 'Schoonrewoerd' },
  { label: 'Schoorl', value: 'Schoorl' },
  { label: 'Schoorldam', value: 'Schoorldam' },
  { label: 'Schopfheim', value: 'Schopfheim' },
  { label: 'Schopfloch', value: 'Schopfloch' },
  { label: 'Schopfloch', value: 'Schopfloch' },
  { label: 'Schopp', value: 'Schopp' },
  { label: 'Schöppenstedt', value: 'Schöppenstedt' },
  { label: 'Schoppernau', value: 'Schoppernau' },
  { label: 'Schöppingen', value: 'Schöppingen' },
  { label: 'Schörfling', value: 'Schörfling' },
  { label: 'Schorndorf', value: 'Schorndorf' },
  { label: 'Schorndorf', value: 'Schorndorf' },
  { label: 'Schornsheim', value: 'Schornsheim' },
  { label: 'Schortens', value: 'Schortens' },
  { label: 'Schoten', value: 'Schoten' },
  { label: 'Schotten', value: 'Schotten' },
  { label: 'Schottwien', value: 'Schottwien' },
  { label: 'Schötz', value: 'Schötz' },
  { label: 'Schouweiler', value: 'Schouweiler' },
  { label: 'Schouwen-Duiveland', value: 'Schouwen-Duiveland' },
  { label: 'Schramberg', value: 'Schramberg' },
  { label: 'Schraplau', value: 'Schraplau' },
  { label: 'Schrassig', value: 'Schrassig' },
  { label: 'Schrattenbach', value: 'Schrattenbach' },
  { label: 'Schrattenberg', value: 'Schrattenberg' },
  { label: 'Schrattenthal', value: 'Schrattenthal' },
  { label: 'Schrecksbach', value: 'Schrecksbach' },
  { label: 'Schrems', value: 'Schrems' },
  { label: 'Schrems Bei Frohnleiten', value: 'Schrems Bei Frohnleiten' },
  { label: 'Schriesheim', value: 'Schriesheim' },
  { label: 'Schriever', value: 'Schriever' },
  { label: 'Schrobenhausen', value: 'Schrobenhausen' },
  { label: 'Schröcken', value: 'Schröcken' },
  { label: 'Schroeder', value: 'Schroeder' },
  { label: 'Schrozberg', value: 'Schrozberg' },
  { label: 'Schruns', value: 'Schruns' },
  { label: 'Schübelbach', value: 'Schübelbach' },
  { label: 'Schuby', value: 'Schuby' },
  { label: 'Schulenburg', value: 'Schulenburg' },
  { label: 'Schülp', value: 'Schülp' },
  { label: 'Schulzendorf', value: 'Schulzendorf' },
  { label: 'Schüpfen', value: 'Schüpfen' },
  { label: 'Schüpfheim', value: 'Schüpfheim' },
  { label: 'Schutsboom', value: 'Schutsboom' },
  { label: 'Schuttertal', value: 'Schuttertal' },
  { label: 'Schutterwald', value: 'Schutterwald' },
  { label: 'Schüttorf', value: 'Schüttorf' },
  { label: 'Schuttrange', value: 'Schuttrange' },
  { label: 'Schützen Am Gebirge', value: 'Schützen Am Gebirge' },
  { label: 'Schuyler', value: 'Schuyler' },
  { label: 'Schuyler County', value: 'Schuyler County' },
  { label: 'Schuyler County', value: 'Schuyler County' },
  { label: 'Schuyler County', value: 'Schuyler County' },
  { label: 'Schuylerville', value: 'Schuylerville' },
  { label: 'Schuylkill County', value: 'Schuylkill County' },
  { label: 'Schuylkill Haven', value: 'Schuylkill Haven' },
  { label: 'Schwaan', value: 'Schwaan' },
  { label: 'Schwabach', value: 'Schwabach' },
  { label: 'Schwabenheim', value: 'Schwabenheim' },
  { label: 'Schwabhausen', value: 'Schwabhausen' },
  { label: 'Schwäbisch Gmünd', value: 'Schwäbisch Gmünd' },
  { label: 'Schwäbisch Hall', value: 'Schwäbisch Hall' },
  { label: 'Schwabmünchen', value: 'Schwabmünchen' },
  { label: 'Schwabsoien', value: 'Schwabsoien' },
  { label: 'Schwabstedt', value: 'Schwabstedt' },
  { label: 'Schwaderloch', value: 'Schwaderloch' },
  { label: 'Schwadorf', value: 'Schwadorf' },
  { label: 'Schwaförden', value: 'Schwaförden' },
  { label: 'Schwaig', value: 'Schwaig' },
  { label: 'Schwaigern', value: 'Schwaigern' },
  { label: 'Schwaikheim', value: 'Schwaikheim' },
  { label: 'Schwalbach', value: 'Schwalbach' },
  { label: 'Schwalbach', value: 'Schwalbach' },
  { label: 'Schwalbach Am Taunus', value: 'Schwalbach Am Taunus' },
  { label: 'Schwallungen', value: 'Schwallungen' },
  { label: 'Schwalmstadt', value: 'Schwalmstadt' },
  { label: 'Schwalmtal', value: 'Schwalmtal' },
  { label: 'Schwalmtal', value: 'Schwalmtal' },
  { label: 'Schwanau', value: 'Schwanau' },
  { label: 'Schwanberg', value: 'Schwanberg' },
  { label: 'Schwanden', value: 'Schwanden' },
  { label: 'Schwandorf In Bayern', value: 'Schwandorf In Bayern' },
  { label: 'Schwanebeck', value: 'Schwanebeck' },
  { label: 'Schwanenstadt', value: 'Schwanenstadt' },
  { label: 'Schwanewede', value: 'Schwanewede' },
  { label: 'Schwanfeld', value: 'Schwanfeld' },
  { label: 'Schwangau', value: 'Schwangau' },
  { label: 'Schwarme', value: 'Schwarme' },
  { label: 'Schwarmstedt', value: 'Schwarmstedt' },
  { label: 'Schwarza', value: 'Schwarza' },
  { label: 'Schwarzach', value: 'Schwarzach' },
  { label: 'Schwarzach', value: 'Schwarzach' },
  { label: 'Schwarzach', value: 'Schwarzach' },
  { label: 'Schwarzach Im Pongau', value: 'Schwarzach Im Pongau' },
  { label: 'Schwarzau Am Steinfeld', value: 'Schwarzau Am Steinfeld' },
  { label: 'Schwarzau Im Gebirge', value: 'Schwarzau Im Gebirge' },
  { label: 'Schwarzau Im Schwarzautal', value: 'Schwarzau Im Schwarzautal' },
  { label: 'Schwarzenau', value: 'Schwarzenau' },
  { label: 'Schwarzenbach', value: 'Schwarzenbach' },
  { label: 'Schwarzenbach', value: 'Schwarzenbach' },
  { label: 'Schwarzenbach An Der Pielach', value: 'Schwarzenbach An Der Pielach' },
  { label: 'Schwarzenbach An Der Saale', value: 'Schwarzenbach An Der Saale' },
  { label: 'Schwarzenbek', value: 'Schwarzenbek' },
  { label: 'Schwarzenberg', value: 'Schwarzenberg' },
  { label: 'Schwarzenberg', value: 'Schwarzenberg' },
  { label: 'Schwarzenberg', value: 'Schwarzenberg' },
  { label: 'Schwarzenborn', value: 'Schwarzenborn' },
  { label: 'Schwarzenbruck', value: 'Schwarzenbruck' },
  { label: 'Schwarzenfeld', value: 'Schwarzenfeld' },
  { label: 'Schwarzheide', value: 'Schwarzheide' },
  { label: 'Schwarzhofen', value: 'Schwarzhofen' },
  { label: 'Schwaz', value: 'Schwaz' },
  { label: 'Schwebheim', value: 'Schwebheim' },
  { label: 'Schwechat', value: 'Schwechat' },
  { label: 'Schwedelbach', value: 'Schwedelbach' },
  { label: 'Schwedt (Oder)', value: 'Schwedt (Oder)' },
  { label: 'Schwegenheim', value: 'Schwegenheim' },
  { label: 'Schweich', value: 'Schweich' },
  { label: 'Schweigen-Rechtenbach', value: 'Schweigen-Rechtenbach' },
  { label: 'Schweiggers', value: 'Schweiggers' },
  { label: 'Schweighouse-Sur-Moder', value: 'Schweighouse-Sur-Moder' },
  { label: 'Schweina', value: 'Schweina' },
  { label: 'Schweinbach', value: 'Schweinbach' },
  { label: 'Schweinfurt', value: 'Schweinfurt' },
  { label: 'Schweitenkirchen', value: 'Schweitenkirchen' },
  { label: 'Schweizer-Reneke', value: 'Schweizer-Reneke' },
  { label: 'Schwellbrunn', value: 'Schwellbrunn' },
  { label: 'Schwelm', value: 'Schwelm' },
  { label: 'Schwendau', value: 'Schwendau' },
  { label: 'Schwendi', value: 'Schwendi' },
  { label: 'Schwendt', value: 'Schwendt' },
  { label: 'Schwenksville', value: 'Schwenksville' },
  { label: 'Schwenningen', value: 'Schwenningen' },
  { label: 'Schwenningen', value: 'Schwenningen' },
  { label: 'Schwepnitz', value: 'Schwepnitz' },
  { label: 'Schwerin', value: 'Schwerin' },
  { label: 'Schwertberg', value: 'Schwertberg' },
  { label: 'Schwerte', value: 'Schwerte' },
  { label: 'Schwerzenbach', value: 'Schwerzenbach' },
  { label: 'Schwerzenbach / Blatten', value: 'Schwerzenbach / Blatten' },
  { label: 'Schwerzenbach / Chimli', value: 'Schwerzenbach / Chimli' },
  { label: 'Schwetzingen', value: 'Schwetzingen' },
  { label: 'Schwieberdingen', value: 'Schwieberdingen' },
  { label: 'Schwindegg', value: 'Schwindegg' },
  { label: 'Schwindratzheim', value: 'Schwindratzheim' },
  { label: 'Schwoich', value: 'Schwoich' },
  { label: 'Schwörstadt', value: 'Schwörstadt' },
  { label: 'Schwyz', value: 'Schwyz' },
  { label: 'Schyschaky', value: 'Schyschaky' },
  { label: 'Sciacca', value: 'Sciacca' },
  { label: 'Sciara', value: 'Sciara' },
  { label: 'Sciarborasca', value: 'Sciarborasca' },
  { label: 'Sciaves', value: 'Sciaves' },
  { label: 'Scicli', value: 'Scicli' },
  { label: 'Scido', value: 'Scido' },
  { label: 'Sciez', value: 'Sciez' },
  { label: 'Scilla', value: 'Scilla' },
  { label: 'Scillato', value: 'Scillato' },
  { label: 'Ścinawa', value: 'Ścinawa' },
  { label: 'Sciolze', value: 'Sciolze' },
  { label: 'Scionzier', value: 'Scionzier' },
  { label: 'Scioto County', value: 'Scioto County' },
  { label: 'Sciotodale', value: 'Sciotodale' },
  { label: 'Scisciano', value: 'Scisciano' },
  { label: 'Scissors', value: 'Scissors' },
  { label: 'Scituate', value: 'Scituate' },
  { label: 'Sclafani Bagni', value: 'Sclafani Bagni' },
  { label: 'Scoarţa', value: 'Scoarţa' },
  { label: 'Scobey', value: 'Scobey' },
  { label: 'Scobinţi', value: 'Scobinţi' },
  { label: 'Scoglitti', value: 'Scoglitti' },
  { label: 'Scole', value: 'Scole' },
  { label: 'Scomigo', value: 'Scomigo' },
  { label: 'Scone', value: 'Scone' },
  { label: 'Scone', value: 'Scone' },
  { label: 'Scontrone', value: 'Scontrone' },
  { label: 'Scopa', value: 'Scopa' },
  { label: 'Scopello', value: 'Scopello' },
  { label: 'Scoppito', value: 'Scoppito' },
  { label: 'Scorbé-Clairvaux', value: 'Scorbé-Clairvaux' },
  { label: 'Scordia', value: 'Scordia' },
  { label: 'Scoresby', value: 'Scoresby' },
  { label: 'Scorniceşti', value: 'Scorniceşti' },
  { label: 'Scorrano', value: 'Scorrano' },
  { label: 'Scorţaru Nou', value: 'Scorţaru Nou' },
  { label: 'Scorțaru Vechi', value: 'Scorțaru Vechi' },
  { label: 'Scorţeni', value: 'Scorţeni' },
  { label: 'Scorţeni', value: 'Scorţeni' },
  { label: 'Scortichino', value: 'Scortichino' },
  { label: 'Scorţoasa', value: 'Scorţoasa' },
  { label: 'Scorton', value: 'Scorton' },
  { label: 'Scorzè', value: 'Scorzè' },
  { label: 'Scotby', value: 'Scotby' },
  { label: 'Scotch Plains', value: 'Scotch Plains' },
  { label: 'Scotchtown', value: 'Scotchtown' },
  { label: 'Scotia', value: 'Scotia' },
  { label: 'Scotland', value: 'Scotland' },
  { label: 'Scotland County', value: 'Scotland County' },
  { label: 'Scotland County', value: 'Scotland County' },
  { label: 'Scotland Neck', value: 'Scotland Neck' },
  { label: 'Scott', value: 'Scott' },
  { label: 'Scott Air Force Base', value: 'Scott Air Force Base' },
  { label: 'Scott City', value: 'Scott City' },
  { label: 'Scott City', value: 'Scott City' },
  { label: 'Scott County', value: 'Scott County' },
  { label: 'Scott County', value: 'Scott County' },
  { label: 'Scott County', value: 'Scott County' },
  { label: 'Scott County', value: 'Scott County' },
  { label: 'Scott County', value: 'Scott County' },
  { label: 'Scott County', value: 'Scott County' },
  { label: 'Scott County', value: 'Scott County' },
  { label: 'Scott County', value: 'Scott County' },
  { label: 'Scott County', value: 'Scott County' },
  { label: 'Scott County', value: 'Scott County' },
  { label: 'Scott County', value: 'Scott County' },
  { label: 'Scott Lake', value: 'Scott Lake' },
  { label: 'Scottburgh', value: 'Scottburgh' },
  { label: 'Scottdale', value: 'Scottdale' },
  { label: 'Scottdale', value: 'Scottdale' },
  { label: 'Scotter', value: 'Scotter' },
  { label: 'Scotts Bluff County', value: 'Scotts Bluff County' },
  { label: 'Scotts Hall', value: 'Scotts Hall' },
  { label: 'Scotts Mill', value: 'Scotts Mill' },
  { label: 'Scotts Pass', value: 'Scotts Pass' },
  { label: 'Scotts Valley', value: 'Scotts Valley' },
  { label: 'Scottsbluff', value: 'Scottsbluff' },
  { label: 'Scottsboro', value: 'Scottsboro' },
  { label: 'Scottsburg', value: 'Scottsburg' },
  { label: 'Scottsdale', value: 'Scottsdale' },
  { label: 'Scottsdale', value: 'Scottsdale' },
  { label: 'Scottsville', value: 'Scottsville' },
  { label: 'Scottsville', value: 'Scottsville' },
  { label: 'Scottville', value: 'Scottville' },
  { label: 'Scranton', value: 'Scranton' },
  { label: 'Screven County', value: 'Screven County' },
  { label: 'Scrioaştea', value: 'Scrioaştea' },
  { label: 'Sculia', value: 'Sculia' },
  { label: 'Scullin', value: 'Scullin' },
  { label: 'Scundu', value: 'Scundu' },
  { label: 'Scunthorpe', value: 'Scunthorpe' },
  { label: 'Scuol', value: 'Scuol' },
  { label: 'Scurcola Marsicana', value: 'Scurcola Marsicana' },
  { label: 'Scurelle', value: 'Scurelle' },
  { label: 'Scurry County', value: 'Scurry County' },
  { label: 'Scurta', value: 'Scurta' },
  { label: 'Scurta', value: 'Scurta' },
  { label: 'Scurtești', value: 'Scurtești' },
  { label: 'Scurtești', value: 'Scurtești' },
  { label: 'Scurtu Mare', value: 'Scurtu Mare' },
  { label: 'Scurzolengo', value: 'Scurzolengo' },
  { label: 'Scutelnici', value: 'Scutelnici' },
  { label: 'Scy-Chazelles', value: 'Scy-Chazelles' },
  { label: 'Sé', value: 'Sé' },
  { label: 'Sea Breeze', value: 'Sea Breeze' },
  { label: 'Sea Bright', value: 'Sea Bright' },
  { label: 'Sea Cliff', value: 'Sea Cliff' },
  { label: 'Sea Girt', value: 'Sea Girt' },
  { label: 'Sea Isle City', value: 'Sea Isle City' },
  { label: 'Sea Ranch', value: 'Sea Ranch' },
  { label: 'Seabeck', value: 'Seabeck' },
  { label: 'Seabra', value: 'Seabra' },
  { label: 'Seabrook', value: 'Seabrook' },
  { label: 'Seabrook', value: 'Seabrook' },
  { label: 'Seabrook', value: 'Seabrook' },
  { label: 'Seabrook', value: 'Seabrook' },
  { label: 'Seabrook Farms', value: 'Seabrook Farms' },
  { label: 'Seabrook Island', value: 'Seabrook Island' },
  { label: 'Seaca', value: 'Seaca' },
  { label: 'Seaca', value: 'Seaca' },
  { label: 'Seaca De Câmp', value: 'Seaca De Câmp' },
  { label: 'Seaca De Pădure', value: 'Seaca De Pădure' },
  { label: 'Seacliff', value: 'Seacliff' },
  { label: 'Seacliff', value: 'Seacliff' },
  { label: 'Seacliff Park', value: 'Seacliff Park' },
  { label: 'Seacombe Gardens', value: 'Seacombe Gardens' },
  { label: 'Seacombe Heights', value: 'Seacombe Heights' },
  { label: 'Seadrift', value: 'Seadrift' },
  { label: 'Seafield', value: 'Seafield' },
  { label: 'Seaford', value: 'Seaford' },
  { label: 'Seaford', value: 'Seaford' },
  { label: 'Seaford', value: 'Seaford' },
  { label: 'Seaford', value: 'Seaford' },
  { label: 'Seaford', value: 'Seaford' },
  { label: 'Seaford Meadows', value: 'Seaford Meadows' },
  { label: 'Seaford Rise', value: 'Seaford Rise' },
  { label: 'Seaford Town', value: 'Seaford Town' },
  { label: 'Seaforth', value: 'Seaforth' },
  { label: 'Seaforth', value: 'Seaforth' },
  { label: 'Seaforth', value: 'Seaforth' },
  { label: 'Seagate', value: 'Seagate' },
  { label: 'Seagoville', value: 'Seagoville' },
  { label: 'Seagraves', value: 'Seagraves' },
  { label: 'Seaham', value: 'Seaham' },
  { label: 'Seaholme', value: 'Seaholme' },
  { label: 'Seahouses', value: 'Seahouses' },
  { label: 'Seal Beach', value: 'Seal Beach' },
  { label: 'Sealy', value: 'Sealy' },
  { label: 'Seano', value: 'Seano' },
  { label: 'Seara', value: 'Seara' },
  { label: 'Searcy', value: 'Searcy' },
  { label: 'Searcy County', value: 'Searcy County' },
  { label: 'Searingtown', value: 'Searingtown' },
  { label: 'Searles Valley', value: 'Searles Valley' },
  { label: 'Searsmont', value: 'Searsmont' },
  { label: 'Seascale', value: 'Seascale' },
  { label: 'Seaside', value: 'Seaside' },
  { label: 'Seaside', value: 'Seaside' },
  { label: 'Seaside', value: 'Seaside' },
  { label: 'Seaside', value: 'Seaside' },
  { label: 'Seaside Heights', value: 'Seaside Heights' },
  { label: 'Seaside Park', value: 'Seaside Park' },
  { label: 'Seat Pleasant', value: 'Seat Pleasant' },
  { label: 'Seatac', value: 'Seatac' },
  { label: 'Seaton', value: 'Seaton' },
  { label: 'Seaton', value: 'Seaton' },
  { label: 'Seaton Crescent', value: 'Seaton Crescent' },
  { label: 'Seaton Delaval', value: 'Seaton Delaval' },
  { label: 'Seattle', value: 'Seattle' },
  { label: 'Seaview', value: 'Seaview' },
  { label: 'Seaview Downs', value: 'Seaview Downs' },
  { label: 'Seaview Gardens', value: 'Seaview Gardens' },
  { label: 'Seaward Pen', value: 'Seaward Pen' },
  { label: 'Sebadelhe', value: 'Sebadelhe' },
  { label: 'Sebadelhe Da Serra', value: 'Sebadelhe Da Serra' },
  { label: 'Sebaste', value: 'Sebaste' },
  { label: 'Sebastian', value: 'Sebastian' },
  { label: 'Sebastian', value: 'Sebastian' },
  { label: 'Sebastian County', value: 'Sebastian County' },
  { label: 'Sebastián Elcano', value: 'Sebastián Elcano' },
  { label: 'Sebastián Lerdo De Tejada', value: 'Sebastián Lerdo De Tejada' },
  { label: 'Sebastián Lerdo De Tejada', value: 'Sebastián Lerdo De Tejada' },
  { label: 'Sebastian Pagador Province', value: 'Sebastian Pagador Province' },
  { label: 'Sebastianópolis Do Sul', value: 'Sebastianópolis Do Sul' },
  { label: 'Sebastião Barros', value: 'Sebastião Barros' },
  { label: 'Sebastião Laranjeiras', value: 'Sebastião Laranjeiras' },
  { label: 'Sebastião Leal', value: 'Sebastião Leal' },
  { label: 'Sebastopol', value: 'Sebastopol' },
  { label: 'Sebastopol', value: 'Sebastopol' },
  { label: 'Sebastopol', value: 'Sebastopol' },
  { label: 'Sébazac-Concourès', value: 'Sébazac-Concourès' },
  { label: 'Sebdou', value: 'Sebdou' },
  { label: 'Seben', value: 'Seben' },
  { label: 'Seberang Perai', value: 'Seberang Perai' },
  { label: 'Seberi', value: 'Seberi' },
  { label: 'Sebersdorf', value: 'Sebersdorf' },
  { label: 'Sebeş', value: 'Sebeş' },
  { label: 'Sebeș', value: 'Sebeș' },
  { label: 'Sebeșel', value: 'Sebeșel' },
  { label: 'Sebeta', value: 'Sebeta' },
  { label: 'Sebewaing', value: 'Sebewaing' },
  { label: 'Sebezh', value: 'Sebezh' },
  { label: 'Sebezhskiy Rayon', value: 'Sebezhskiy Rayon' },
  { label: 'Sebina', value: 'Sebina' },
  { label: 'Şebinkarahisar', value: 'Şebinkarahisar' },
  { label: 'Sebiş', value: 'Sebiş' },
  { label: 'Sebkha', value: 'Sebkha' },
  { label: 'Sebnitz', value: 'Sebnitz' },
  { label: 'Seboncourt', value: 'Seboncourt' },
  { label: 'Seborga', value: 'Seborga' },
  { label: 'Sebourg', value: 'Sebourg' },
  { label: 'Sebree', value: 'Sebree' },
  { label: 'Sebring', value: 'Sebring' },
  { label: 'Sebring', value: 'Sebring' },
  { label: 'Sebrovo', value: 'Sebrovo' },
  { label: 'Sebu', value: 'Sebu' },
  { label: 'Sebúlcor', value: 'Sebúlcor' },
  { label: 'Seč', value: 'Seč' },
  { label: 'Seča', value: 'Seča' },
  { label: 'Seca La', value: 'Seca La' },
  { label: 'Sečanj', value: 'Sečanj' },
  { label: 'Secăria', value: 'Secăria' },
  { label: 'Secaş', value: 'Secaş' },
  { label: 'Secastilla', value: 'Secastilla' },
  { label: 'Secaucus', value: 'Secaucus' },
  { label: 'Secchiano', value: 'Secchiano' },
  { label: 'Sección Del Cerrito', value: 'Sección Del Cerrito' },
  { label: 'Secemin', value: 'Secemin' },
  { label: 'Sechelt', value: 'Sechelt' },
  { label: 'Sechenovo', value: 'Sechenovo' },
  { label: 'Sechenovskiy Rayon', value: 'Sechenovskiy Rayon' },
  { label: 'Sechura', value: 'Sechura' },
  { label: 'Secinaro', value: 'Secinaro' },
  { label: 'Seciu', value: 'Seciu' },
  { label: 'Seck', value: 'Seck' },
  { label: 'Seckach', value: 'Seckach' },
  { label: 'Seckau', value: 'Seckau' },
  { label: 'Seclì', value: 'Seclì' },
  { label: 'Seclin', value: 'Seclin' },
  { label: 'Secondigliano', value: 'Secondigliano' },
  { label: 'Secondigny', value: 'Secondigny' },
  { label: 'Sečovce', value: 'Sečovce' },
  { label: 'Secret Harbour', value: 'Secret Harbour' },
  { label: 'Sector', value: 'Sector' },
  { label: 'Sector H Tres', value: 'Sector H Tres' },
  { label: 'Secu', value: 'Secu' },
  { label: 'Secugnago', value: 'Secugnago' },
  { label: 'Secui', value: 'Secui' },
  { label: 'Secuieni', value: 'Secuieni' },
  { label: 'Secuieni', value: 'Secuieni' },
  { label: 'Secuita', value: 'Secuita' },
  { label: 'Secunda', value: 'Secunda' },
  { label: 'Secunderabad', value: 'Secunderabad' },
  { label: 'Security-Widefield', value: 'Security-Widefield' },
  { label: 'Secusigiu', value: 'Secusigiu' },
  { label: 'Seda', value: 'Seda' },
  { label: 'Seda', value: 'Seda' },
  { label: 'Sedalia', value: 'Sedalia' },
  { label: 'Sedan', value: 'Sedan' },
  { label: 'Sedan', value: 'Sedan' },
  { label: 'Sedarak', value: 'Sedarak' },
  { label: 'Sedaví', value: 'Sedaví' },
  { label: 'Sedbergh', value: 'Sedbergh' },
  { label: 'Sedco Hills', value: 'Sedco Hills' },
  { label: 'Seddon', value: 'Seddon' },
  { label: 'Seddouk', value: 'Seddouk' },
  { label: 'Sede Nova', value: 'Sede Nova' },
  { label: 'Sedegliano', value: 'Sedegliano' },
  { label: 'Sedeh', value: 'Sedeh' },
  { label: 'Sedeh Lenjan', value: 'Sedeh Lenjan' },
  { label: 'Sedel’Nikovskiy Rayon', value: 'Sedel’Nikovskiy Rayon' },
  { label: 'Sedella', value: 'Sedella' },
  { label: 'Seden', value: 'Seden' },
  { label: 'Sederot', value: 'Sederot' },
  { label: 'Sedgefield', value: 'Sedgefield' },
  { label: 'Sedgepond', value: 'Sedgepond' },
  { label: 'Sedgwick', value: 'Sedgwick' },
  { label: 'Sedgwick', value: 'Sedgwick' },
  { label: 'Sedgwick County', value: 'Sedgwick County' },
  { label: 'Sedgwick County', value: 'Sedgwick County' },
  { label: 'Sédhiou', value: 'Sédhiou' },
  { label: 'Sedibeng District Municipality', value: 'Sedibeng District Municipality' },
  { label: 'Sedico', value: 'Sedico' },
  { label: 'Sediles', value: 'Sediles' },
  { label: 'Sedilo', value: 'Sedilo' },
  { label: 'Sedini', value: 'Sedini' },
  { label: 'Sedkyrkeshch', value: 'Sedkyrkeshch' },
  { label: 'Sedlarevo', value: 'Sedlarevo' },
  { label: 'Sedlčany', value: 'Sedlčany' },
  { label: 'Sedlec', value: 'Sedlec' },
  { label: 'Sedlice', value: 'Sedlice' },
  { label: 'Sedliště', value: 'Sedliště' },
  { label: 'Sedlnice', value: 'Sedlnice' },
  { label: 'Sedniv', value: 'Sedniv' },
  { label: 'Sedona', value: 'Sedona' },
  { label: 'Sedrata', value: 'Sedrata' },
  { label: 'Sedriano', value: 'Sedriano' },
  { label: 'Sedrina', value: 'Sedrina' },
  { label: 'Sedro-Woolley', value: 'Sedro-Woolley' },
  { label: 'Šeduva', value: 'Šeduva' },
  { label: 'Sędziejowice', value: 'Sędziejowice' },
  { label: 'Sędziszów', value: 'Sędziszów' },
  { label: 'Sędziszów Małopolski', value: 'Sędziszów Małopolski' },
  { label: 'See', value: 'See' },
  { label: 'Seeb', value: 'Seeb' },
  { label: 'Seebach', value: 'Seebach' },
  { label: 'Seebach', value: 'Seebach' },
  { label: 'Seebach', value: 'Seebach' },
  { label: 'Seebad Bansin', value: 'Seebad Bansin' },
  { label: 'Seebad Heringsdorf', value: 'Seebad Heringsdorf' },
  { label: 'Seebenstein', value: 'Seebenstein' },
  { label: 'Seeberg', value: 'Seeberg' },
  { label: 'Seebergen', value: 'Seebergen' },
  { label: 'Seeboden', value: 'Seeboden' },
  { label: 'Seeburg', value: 'Seeburg' },
  { label: 'Seedorf', value: 'Seedorf' },
  { label: 'Seedorf', value: 'Seedorf' },
  { label: 'Seedorf', value: 'Seedorf' },
  { label: 'Seedorf', value: 'Seedorf' },
  { label: 'Seefeld', value: 'Seefeld' },
  { label: 'Seefeld In Tirol', value: 'Seefeld In Tirol' },
  { label: 'Seeg', value: 'Seeg' },
  { label: 'Seeham', value: 'Seeham' },
  { label: 'Seehausen', value: 'Seehausen' },
  { label: 'Seehausen Am Staffelsee', value: 'Seehausen Am Staffelsee' },
  { label: 'Seeheilbad Graal-Müritz', value: 'Seeheilbad Graal-Müritz' },
  { label: 'Seeheim-Jugenheim', value: 'Seeheim-Jugenheim' },
  { label: 'Seehof', value: 'Seehof' },
  { label: 'Seekirchen Am Wallersee', value: 'Seekirchen Am Wallersee' },
  { label: 'Seekonk', value: 'Seekonk' },
  { label: 'Seeland District', value: 'Seeland District' },
  { label: 'Seelbach', value: 'Seelbach' },
  { label: 'Seeley', value: 'Seeley' },
  { label: 'Seeley Lake', value: 'Seeley Lake' },
  { label: 'Seelingstädt', value: 'Seelingstädt' },
  { label: 'Seelitz', value: 'Seelitz' },
  { label: 'Seelow', value: 'Seelow' },
  { label: 'Seelyville', value: 'Seelyville' },
  { label: 'Seelze', value: 'Seelze' },
  { label: 'Seend', value: 'Seend' },
  { label: 'Seengen', value: 'Seengen' },
  { label: 'Seeon-Seebruck', value: 'Seeon-Seebruck' },
  { label: 'Seer Green', value: 'Seer Green' },
  { label: 'Sées', value: 'Sées' },
  { label: 'Seesen', value: 'Seesen' },
  { label: 'Seeshaupt', value: 'Seeshaupt' },
  { label: 'Seethawaka', value: 'Seethawaka' },
  { label: 'Seevetal', value: 'Seevetal' },
  { label: 'Seewalchen', value: 'Seewalchen' },
  { label: 'Seewalchen', value: 'Seewalchen' },
  { label: 'Séez', value: 'Séez' },
  { label: 'Şefaatli', value: 'Şefaatli' },
  { label: 'Seferihisar', value: 'Seferihisar' },
  { label: 'Seffner', value: 'Seffner' },
  { label: 'Sefid Khaneh', value: 'Sefid Khaneh' },
  { label: 'Sefid Sang', value: 'Sefid Sang' },
  { label: 'Sefid Shahr', value: 'Sefid Shahr' },
  { label: 'Sefiddasht', value: 'Sefiddasht' },
  { label: 'Sefkerin', value: 'Sefkerin' },
  { label: 'Sefophe', value: 'Sefophe' },
  { label: 'Sefro', value: 'Sefro' },
  { label: 'Sefrou', value: 'Sefrou' },
  { label: 'Seftigen', value: 'Seftigen' },
  { label: 'Sefton', value: 'Sefton' },
  { label: 'Sefton', value: 'Sefton' },
  { label: 'Sefton Park', value: 'Sefton Park' },
  { label: 'Sefwi-Akontombra', value: 'Sefwi-Akontombra' },
  { label: 'Sefwi-Wiawso', value: 'Sefwi-Wiawso' },
  { label: 'Segamat', value: 'Segamat' },
  { label: 'Segarcea', value: 'Segarcea' },
  { label: 'Segarcea Vale', value: 'Segarcea Vale' },
  { label: 'Segarcea-Deal', value: 'Segarcea-Deal' },
  { label: 'Segariu', value: 'Segariu' },
  { label: 'Segart', value: 'Segart' },
  { label: 'Segavi', value: 'Segavi' },
  { label: 'Segbwema', value: 'Segbwema' },
  { label: 'Segeltorp', value: 'Segeltorp' },
  { label: 'Segesd', value: 'Segesd' },
  { label: 'Seget', value: 'Seget' },
  { label: 'Seget Vranjica', value: 'Seget Vranjica' },
  { label: 'Segezha', value: 'Segezha' },
  { label: 'Seggauberg', value: 'Seggauberg' },
  { label: 'Seggebruch', value: 'Seggebruch' },
  { label: 'Seggiano', value: 'Seggiano' },
  { label: 'Seghill', value: 'Seghill' },
  { label: 'Seglingen', value: 'Seglingen' },
  { label: 'Segni', value: 'Segni' },
  { label: 'Ségny', value: 'Ségny' },
  { label: 'Segonzac', value: 'Segonzac' },
  { label: 'Segonzano', value: 'Segonzano' },
  { label: 'Segorbe', value: 'Segorbe' },
  { label: 'Ségou', value: 'Ségou' },
  { label: 'Segovia', value: 'Segovia' },
  { label: 'Segovia', value: 'Segovia' },
  { label: 'Segrate', value: 'Segrate' },
  { label: 'Segré', value: 'Segré' },
  { label: 'Segredo', value: 'Segredo' },
  { label: 'Séguéla', value: 'Séguéla' },
  { label: 'Seguí', value: 'Seguí' },
  { label: 'Seguin', value: 'Seguin' },
  { label: 'Segundo Ensanche', value: 'Segundo Ensanche' },
  { label: 'Segura', value: 'Segura' },
  { label: 'Segura De La Sierra', value: 'Segura De La Sierra' },
  { label: 'Segura De León', value: 'Segura De León' },
  { label: 'Segura De Los Baños', value: 'Segura De Los Baños' },
  { label: 'Segura De Toro', value: 'Segura De Toro' },
  { label: 'Segurilla', value: 'Segurilla' },
  { label: 'Segusino', value: 'Segusino' },
  { label: 'Seh Qaleh', value: 'Seh Qaleh' },
  { label: 'Sehithwa', value: 'Sehithwa' },
  { label: 'Şehitkamil', value: 'Şehitkamil' },
  { label: 'Sehlde', value: 'Sehlde' },
  { label: 'Sehlem', value: 'Sehlem' },
  { label: 'Sehlen', value: 'Sehlen' },
  { label: 'Sehnde', value: 'Sehnde' },
  { label: 'Sehore', value: 'Sehore' },
  { label: 'Sehwan', value: 'Sehwan' },
  { label: 'Şehzadeler', value: 'Şehzadeler' },
  { label: 'Seia', value: 'Seia' },
  { label: 'Seïada', value: 'Seïada' },
  { label: 'Seibersbach', value: 'Seibersbach' },
  { label: 'Seibersdorf', value: 'Seibersdorf' },
  { label: 'Şeíca Mare', value: 'Şeíca Mare' },
  { label: 'Şeica Mică', value: 'Şeica Mică' },
  { label: 'Seichamps', value: 'Seichamps' },
  { label: 'Seiches-Sur-Le-Loir', value: 'Seiches-Sur-Le-Loir' },
  { label: 'Seidu', value: 'Seidu' },
  { label: 'Seiersberg', value: 'Seiersberg' },
  { label: 'Seiffen', value: 'Seiffen' },
  { label: 'Seifhennersdorf', value: 'Seifhennersdorf' },
  { label: 'Seignelay', value: 'Seignelay' },
  { label: 'Seignosse', value: 'Seignosse' },
  { label: 'Seigy', value: 'Seigy' },
  { label: 'Seilh', value: 'Seilh' },
  { label: 'Seilhac', value: 'Seilhac' },
  { label: 'Seillans', value: 'Seillans' },
  { label: 'Seimeni', value: 'Seimeni' },
  { label: 'Seinäjoki', value: 'Seinäjoki' },
  { label: 'Seine-Et-Marne', value: 'Seine-Et-Marne' },
  { label: 'Seine-Maritime', value: 'Seine-Maritime' },
  { label: 'Seine-Port', value: 'Seine-Port' },
  { label: 'Seine-Saint-Denis', value: 'Seine-Saint-Denis' },
  { label: 'Seingbouse', value: 'Seingbouse' },
  { label: 'Seini', value: 'Seini' },
  { label: 'Seinsheim', value: 'Seinsheim' },
  { label: 'Seira', value: 'Seira' },
  { label: 'Seis De Enero', value: 'Seis De Enero' },
  { label: 'Seis De Enero', value: 'Seis De Enero' },
  { label: 'Seis De Octubre', value: 'Seis De Octubre' },
  { label: 'Seis De Octubre (Santo Niño)', value: 'Seis De Octubre (Santo Niño)' },
  { label: 'Seissan', value: 'Seissan' },
  { label: 'Seitenberg', value: 'Seitenberg' },
  { label: 'Seitenstetten Markt', value: 'Seitenstetten Markt' },
  { label: 'Şeitin', value: 'Şeitin' },
  { label: 'Seitingen-Oberflacht', value: 'Seitingen-Oberflacht' },
  { label: 'Seixal', value: 'Seixal' },
  { label: 'Seixas', value: 'Seixas' },
  { label: 'Seixo Do Côa', value: 'Seixo Do Côa' },
  { label: 'Seiyo-Shi', value: 'Seiyo-Shi' },
  { label: 'Seizieme Mille', value: 'Seizieme Mille' },
  { label: 'Sejenane', value: 'Sejenane' },
  { label: 'Sejny', value: 'Sejny' },
  { label: 'Sejong', value: 'Sejong' },
  { label: 'Sejzi', value: 'Sejzi' },
  { label: 'Seka', value: 'Seka' },
  { label: 'Sekadau', value: 'Sekadau' },
  { label: 'Sekhukhune District Municipality', value: 'Sekhukhune District Municipality' },
  { label: 'Sekirnik', value: 'Sekirnik' },
  { label: 'Seki-Shi', value: 'Seki-Shi' },
  { label: 'Sekoma', value: 'Sekoma' },
  { label: 'Sekondi-Takoradi', value: 'Sekondi-Takoradi' },
  { label: 'Šekovići', value: 'Šekovići' },
  { label: 'Sękowa', value: 'Sękowa' },
  { label: 'Sekseūil', value: 'Sekseūil' },
  { label: 'Sel', value: 'Sel' },
  { label: 'Sel’Tso', value: 'Sel’Tso' },
  { label: 'Selah', value: 'Selah' },
  { label: 'Selargius', value: 'Selargius' },
  { label: 'Şelaru', value: 'Şelaru' },
  { label: 'Selas', value: 'Selas' },
  { label: 'Selaya', value: 'Selaya' },
  { label: 'Selayar Islands Regency', value: 'Selayar Islands Regency' },
  { label: 'Selb', value: 'Selb' },
  { label: 'Selbach', value: 'Selbach' },
  { label: 'Selbitz', value: 'Selbitz' },
  { label: 'Selbu', value: 'Selbu' },
  { label: 'Selby', value: 'Selby' },
  { label: 'Selby', value: 'Selby' },
  { label: 'Selby', value: 'Selby' },
  { label: 'Selby-On-The-Bay', value: 'Selby-On-The-Bay' },
  { label: 'Selbyville', value: 'Selbyville' },
  { label: 'Selca', value: 'Selca' },
  { label: 'Selce', value: 'Selce' },
  { label: 'Selce', value: 'Selce' },
  { label: 'Selcetta', value: 'Selcetta' },
  { label: 'Selci', value: 'Selci' },
  { label: 'Selci-Lama', value: 'Selci-Lama' },
  { label: 'Selçuk', value: 'Selçuk' },
  { label: 'Selçuklu', value: 'Selçuklu' },
  { label: 'Selden', value: 'Selden' },
  { label: 'Séléa', value: 'Séléa' },
  { label: 'Selegas', value: 'Selegas' },
  { label: 'Selemdzhinskiy Rayon', value: 'Selemdzhinskiy Rayon' },
  { label: 'Selendi', value: 'Selendi' },
  { label: 'Selenduma', value: 'Selenduma' },
  { label: 'Selenginskiy Rayon', value: 'Selenginskiy Rayon' },
  { label: 'Selenicë', value: 'Selenicë' },
  { label: 'Selent', value: 'Selent' },
  { label: 'Sélero', value: 'Sélero' },
  { label: 'Sélestat', value: 'Sélestat' },
  { label: 'Seleuš', value: 'Seleuš' },
  { label: 'Seleuş', value: 'Seleuş' },
  { label: 'Seleuș', value: 'Seleuș' },
  { label: 'Selezni', value: 'Selezni' },
  { label: 'Selezyan', value: 'Selezyan' },
  { label: 'Selfoss', value: 'Selfoss' },
  { label: 'Selho', value: 'Selho' },
  { label: 'Sélibaby', value: 'Sélibaby' },
  { label: 'Seligenstadt', value: 'Seligenstadt' },
  { label: 'Selikhino', value: 'Selikhino' },
  { label: 'Selim', value: 'Selim' },
  { label: 'Şelimbăr', value: 'Şelimbăr' },
  { label: 'Selínia', value: 'Selínia' },
  { label: 'Selino Basso', value: 'Selino Basso' },
  { label: 'Selinsgrove', value: 'Selinsgrove' },
  { label: 'Selishche', value: 'Selishche' },
  { label: 'Selizharovo', value: 'Selizharovo' },
  { label: 'Selizharovskiy Rayon', value: 'Selizharovskiy Rayon' },
  { label: 'Selje', value: 'Selje' },
  { label: 'Seljord', value: 'Seljord' },
  { label: 'Selkirk', value: 'Selkirk' },
  { label: 'Selkirk', value: 'Selkirk' },
  { label: 'Sella', value: 'Sella' },
  { label: 'Sellano', value: 'Sellano' },
  { label: 'Sellenbüren', value: 'Sellenbüren' },
  { label: 'Sellent', value: 'Sellent' },
  { label: 'Sellero', value: 'Sellero' },
  { label: 'Sellersburg', value: 'Sellersburg' },
  { label: 'Sellersville', value: 'Sellersville' },
  { label: 'Selles-Saint-Denis', value: 'Selles-Saint-Denis' },
  { label: 'Selles-Sur-Cher', value: 'Selles-Sur-Cher' },
  { label: 'Sellia', value: 'Sellia' },
  { label: 'Sellia Marina', value: 'Sellia Marina' },
  { label: 'Sellicks Beach', value: 'Sellicks Beach' },
  { label: 'Sellin', value: 'Sellin' },
  { label: 'Sellingen', value: 'Sellingen' },
  { label: 'Sellrain', value: 'Sellrain' },
  { label: 'Sells', value: 'Sells' },
  { label: 'Sellye', value: 'Sellye' },
  { label: 'Sellyei Járás', value: 'Sellyei Járás' },
  { label: 'Selm', value: 'Selm' },
  { label: 'Selma', value: 'Selma' },
  { label: 'Selma', value: 'Selma' },
  { label: 'Selma', value: 'Selma' },
  { label: 'Selma', value: 'Selma' },
  { label: 'Selmer', value: 'Selmer' },
  { label: 'Selmont-West Selmont', value: 'Selmont-West Selmont' },
  { label: 'Selmsdorf', value: 'Selmsdorf' },
  { label: 'Selnica Ob Dravi', value: 'Selnica Ob Dravi' },
  { label: 'Selnica Ob Muri', value: 'Selnica Ob Muri' },
  { label: 'Selogiri', value: 'Selogiri' },
  { label: 'Seloncourt', value: 'Seloncourt' },
  { label: 'Selongey', value: 'Selongey' },
  { label: 'Selouane', value: 'Selouane' },
  { label: 'Selseleh', value: 'Selseleh' },
  { label: 'Selsey', value: 'Selsey' },
  { label: 'Selsingen', value: 'Selsingen' },
  { label: 'Selters', value: 'Selters' },
  { label: 'Selters', value: 'Selters' },
  { label: 'Seltisberg', value: 'Seltisberg' },
  { label: 'Seltjarnarnes', value: 'Seltjarnarnes' },
  { label: 'Selty', value: 'Selty' },
  { label: 'Seltz', value: 'Seltz' },
  { label: 'Selu', value: 'Selu' },
  { label: 'Selva', value: 'Selva' },
  { label: 'Selva', value: 'Selva' },
  { label: 'Selva', value: 'Selva' },
  { label: 'Selva De Mar La', value: 'Selva De Mar La' },
  { label: 'Selva Dei Molini', value: 'Selva Dei Molini' },
  { label: 'Selva Del Camp', value: 'Selva Del Camp' },
  { label: 'Selva Del Montello', value: 'Selva Del Montello' },
  { label: 'Selva Di Cadore', value: 'Selva Di Cadore' },
  { label: 'Selva Di Progno', value: 'Selva Di Progno' },
  { label: 'Selvatelle', value: 'Selvatelle' },
  { label: 'Selvazzano Dentro', value: 'Selvazzano Dentro' },
  { label: 'Selve Marcone', value: 'Selve Marcone' },
  { label: 'Selvigny', value: 'Selvigny' },
  { label: 'Selvik', value: 'Selvik' },
  { label: 'Selvino', value: 'Selvino' },
  { label: 'Selvíria', value: 'Selvíria' },
  { label: 'Selwerd', value: 'Selwerd' },
  { label: 'Selwyn District', value: 'Selwyn District' },
  { label: 'Selyatino', value: 'Selyatino' },
  { label: 'Selydivs’Ka Mis’Krada', value: 'Selydivs’Ka Mis’Krada' },
  { label: 'Selydove', value: 'Selydove' },
  { label: 'Selzach', value: 'Selzach' },
  { label: 'Selzen', value: 'Selzen' },
  { label: 'Selzthal', value: 'Selzthal' },
  { label: 'Sem', value: 'Sem' },
  { label: 'Semaleh', value: 'Semaleh' },
  { label: 'Sémalens', value: 'Sémalens' },
  { label: 'Semaphore', value: 'Semaphore' },
  { label: 'Semaphore Park', value: 'Semaphore Park' },
  { label: 'Semaphore South', value: 'Semaphore South' },
  { label: 'Semarang', value: 'Semarang' },
  { label: 'Sembabule', value: 'Sembabule' },
  { label: 'Sembach', value: 'Sembach' },
  { label: 'Sembawang', value: 'Sembawang' },
  { label: 'Sémbé', value: 'Sémbé' },
  { label: 'Semblançay', value: 'Semblançay' },
  { label: 'Semboku-Shi', value: 'Semboku-Shi' },
  { label: 'Şemdinli', value: 'Şemdinli' },
  { label: 'Sémé', value: 'Sémé' },
  { label: 'Séméac', value: 'Séméac' },
  { label: 'Semelhe', value: 'Semelhe' },
  { label: 'Semeljci', value: 'Semeljci' },
  { label: 'Semenivka', value: 'Semenivka' },
  { label: 'Semenivka', value: 'Semenivka' },
  { label: 'Semënov', value: 'Semënov' },
  { label: 'Semënovskoye', value: 'Semënovskoye' },
  { label: 'Sementina', value: 'Sementina' },
  { label: 'Semenyih', value: 'Semenyih' },
  { label: 'Semera', value: 'Semera' },
  { label: 'Semestene', value: 'Semestene' },
  { label: 'Semey', value: 'Semey' },
  { label: 'Semiana', value: 'Semiana' },
  { label: 'Semibratovo', value: 'Semibratovo' },
  { label: 'Semič', value: 'Semič' },
  { label: 'Semikarakorsk', value: 'Semikarakorsk' },
  { label: 'Semiletka', value: 'Semiletka' },
  { label: 'Semillas', value: 'Semillas' },
  { label: 'Semiluki', value: 'Semiluki' },
  { label: 'Semilukskiy Rayon', value: 'Semilukskiy Rayon' },
  { label: 'Semily', value: 'Semily' },
  { label: 'Seminara', value: 'Seminara' },
  { label: 'Seminole', value: 'Seminole' },
  { label: 'Seminole', value: 'Seminole' },
  { label: 'Seminole', value: 'Seminole' },
  { label: 'Seminole County', value: 'Seminole County' },
  { label: 'Seminole County', value: 'Seminole County' },
  { label: 'Seminole County', value: 'Seminole County' },
  { label: 'Seminole Manor', value: 'Seminole Manor' },
  { label: 'Semiozerje', value: 'Semiozerje' },
  { label: 'Semirara', value: 'Semirara' },
  { label: 'Semirom', value: 'Semirom' },
  { label: 'Semisotka', value: 'Semisotka' },
  { label: 'Semkhoz', value: 'Semkhoz' },
  { label: 'Semlac', value: 'Semlac' },
  { label: 'Semmering', value: 'Semmering' },
  { label: 'Semmes', value: 'Semmes' },
  { label: 'Semnan', value: 'Semnan' },
  { label: 'Semonte-Casamorcia', value: 'Semonte-Casamorcia' },
  { label: 'Semoy', value: 'Semoy' },
  { label: 'Sempach', value: 'Sempach' },
  { label: 'Šempas', value: 'Šempas' },
  { label: 'Sem-Peixe', value: 'Sem-Peixe' },
  { label: 'Sempere', value: 'Sempere' },
  { label: 'Šempeter Pri Gorici', value: 'Šempeter Pri Gorici' },
  { label: 'Šempeter V Savinj. Dolini', value: 'Šempeter V Savinj. Dolini' },
  { label: 'Semporna', value: 'Semporna' },
  { label: 'Semproniano', value: 'Semproniano' },
  { label: 'Semriach', value: 'Semriach' },
  { label: 'Semur-En-Auxois', value: 'Semur-En-Auxois' },
  { label: 'Semussac', value: 'Semussac' },
  { label: 'Semut', value: 'Semut' },
  { label: 'Semut', value: 'Semut' },
  { label: 'Semypolky', value: 'Semypolky' },
  { label: 'Sen Monorom', value: 'Sen Monorom' },
  { label: 'Sena', value: 'Sena' },
  { label: 'Sena De Luna', value: 'Sena De Luna' },
  { label: 'Sena Madureira', value: 'Sena Madureira' },
  { label: 'Senada', value: 'Senada' },
  { label: 'Senador Alexandre Costa', value: 'Senador Alexandre Costa' },
  { label: 'Senador Amaral', value: 'Senador Amaral' },
  { label: 'Senador Canedo', value: 'Senador Canedo' },
  { label: 'Senador Cortes', value: 'Senador Cortes' },
  { label: 'Senador Elói De Souza', value: 'Senador Elói De Souza' },
  { label: 'Senador Firmino', value: 'Senador Firmino' },
  { label: 'Senador Georgino Avelino', value: 'Senador Georgino Avelino' },
  { label: 'Senador Guiomard', value: 'Senador Guiomard' },
  { label: 'Senador José Bento', value: 'Senador José Bento' },
  { label: 'Senador José Porfírio', value: 'Senador José Porfírio' },
  { label: 'Senador La Rocque', value: 'Senador La Rocque' },
  { label: 'Senador Modestino Gonçalves', value: 'Senador Modestino Gonçalves' },
  { label: 'Senador Pompeu', value: 'Senador Pompeu' },
  { label: 'Senador Rui Palmeira', value: 'Senador Rui Palmeira' },
  { label: 'Senador Sá', value: 'Senador Sá' },
  { label: 'Senador Salgado Filho', value: 'Senador Salgado Filho' },
  { label: 'Senago', value: 'Senago' },
  { label: 'Senahú', value: 'Senahú' },
  { label: 'Senak’I', value: 'Senak’I' },
  { label: 'Senale', value: 'Senale' },
  { label: 'Senales', value: 'Senales' },
  { label: 'Senan', value: 'Senan' },
  { label: 'Senanga', value: 'Senanga' },
  { label: 'Senapati', value: 'Senapati' },
  { label: 'Sénas', value: 'Sénas' },
  { label: 'Senath', value: 'Senath' },
  { label: 'Senatobia', value: 'Senatobia' },
  { label: 'Sencelles', value: 'Sencelles' },
  { label: 'Sencha', value: 'Sencha' },
  { label: 'Šenčur', value: 'Šenčur' },
  { label: 'Send', value: 'Send' },
  { label: 'Sendafa', value: 'Sendafa' },
  { label: 'Sendai', value: 'Sendai' },
  { label: 'Senden', value: 'Senden' },
  { label: 'Senden', value: 'Senden' },
  { label: 'Sendenhorst', value: 'Sendenhorst' },
  { label: 'Senderk', value: 'Senderk' },
  { label: 'Sendhwa', value: 'Sendhwa' },
  { label: 'Sendim', value: 'Sendim' },
  { label: 'Şendreni', value: 'Şendreni' },
  { label: 'Şendriceni', value: 'Şendriceni' },
  { label: 'Séné', value: 'Séné' },
  { label: 'Sene East', value: 'Sene East' },
  { label: 'Sene West', value: 'Sene West' },
  { label: 'Senec', value: 'Senec' },
  { label: 'Seneca', value: 'Seneca' },
  { label: 'Seneca', value: 'Seneca' },
  { label: 'Seneca', value: 'Seneca' },
  { label: 'Seneca', value: 'Seneca' },
  { label: 'Seneca', value: 'Seneca' },
  { label: 'Seneca County', value: 'Seneca County' },
  { label: 'Seneca County', value: 'Seneca County' },
  { label: 'Seneca Falls', value: 'Seneca Falls' },
  { label: 'Seneca Knolls', value: 'Seneca Knolls' },
  { label: 'Seneffe', value: 'Seneffe' },
  { label: 'Seneghe', value: 'Seneghe' },
  { label: 'Senekal', value: 'Senekal' },
  { label: 'Senerchia', value: 'Senerchia' },
  { label: 'Senereuș', value: 'Senereuș' },
  { label: 'Senés', value: 'Senés' },
  { label: 'Senés De Alcubierre', value: 'Senés De Alcubierre' },
  { label: 'Senftenberg', value: 'Senftenberg' },
  { label: 'Senftenberg', value: 'Senftenberg' },
  { label: 'Sengenthal', value: 'Sengenthal' },
  { label: 'Sengés', value: 'Sengés' },
  { label: 'Senggigi', value: 'Senggigi' },
  { label: 'Sengiley', value: 'Sengiley' },
  { label: 'Sengkang', value: 'Sengkang' },
  { label: 'Sengkang', value: 'Sengkang' },
  { label: 'Senglea', value: 'Senglea' },
  { label: 'Senguio', value: 'Senguio' },
  { label: 'Senhor Do Bonfim', value: 'Senhor Do Bonfim' },
  { label: 'Senhora Da Hora', value: 'Senhora Da Hora' },
  { label: 'Senhora Da Luz', value: 'Senhora Da Luz' },
  { label: 'Senhora De Oliveira', value: 'Senhora De Oliveira' },
  { label: 'Senhora Do Porto', value: 'Senhora Do Porto' },
  { label: 'Senhora Do Rosário', value: 'Senhora Do Rosário' },
  { label: 'Senhora Dos Remédios', value: 'Senhora Dos Remédios' },
  { label: 'Sénia', value: 'Sénia' },
  { label: 'Senica', value: 'Senica' },
  { label: 'Senice Na Hané', value: 'Senice Na Hané' },
  { label: 'Senieji Trakai', value: 'Senieji Trakai' },
  { label: 'Seniga', value: 'Seniga' },
  { label: 'Senigallia', value: 'Senigallia' },
  { label: 'Senija', value: 'Senija' },
  { label: 'Senillosa', value: 'Senillosa' },
  { label: 'Senirkent', value: 'Senirkent' },
  { label: 'Senis', value: 'Senis' },
  { label: 'Senise', value: 'Senise' },
  { label: 'Senj', value: 'Senj' },
  { label: 'Şenkaya', value: 'Şenkaya' },
  { label: 'Šenkovec', value: 'Šenkovec' },
  { label: 'Senlis', value: 'Senlis' },
  { label: 'Senna Comasco', value: 'Senna Comasco' },
  { label: 'Senna Lodigiana', value: 'Senna Lodigiana' },
  { label: 'Sennan Shi', value: 'Sennan Shi' },
  { label: 'Sennariolo', value: 'Sennariolo' },
  { label: 'Sennecey-Le-Grand', value: 'Sennecey-Le-Grand' },
  { label: 'Sennecey-Lès-Dijon', value: 'Sennecey-Lès-Dijon' },
  { label: 'Senneterre', value: 'Senneterre' },
  { label: 'Sennewitz', value: 'Sennewitz' },
  { label: 'Sennfeld', value: 'Sennfeld' },
  { label: 'Sennori', value: 'Sennori' },
  { label: 'Sennoy', value: 'Sennoy' },
  { label: 'Sennwald', value: 'Sennwald' },
  { label: 'Seno', value: 'Seno' },
  { label: 'Senoia', value: 'Senoia' },
  { label: 'Senokosnoye', value: 'Senokosnoye' },
  { label: 'Senonches', value: 'Senonches' },
  { label: 'Senones', value: 'Senones' },
  { label: 'Señor', value: 'Señor' },
  { label: 'Señor Del Pozo', value: 'Señor Del Pozo' },
  { label: 'Senorbì', value: 'Senorbì' },
  { label: 'Šenov', value: 'Šenov' },
  { label: 'Senovo', value: 'Senovo' },
  { label: 'Senovo', value: 'Senovo' },
  { label: 'Şenpazar', value: 'Şenpazar' },
  { label: 'Sens', value: 'Sens' },
  { label: 'Sens-De-Bretagne', value: 'Sens-De-Bretagne' },
  { label: 'Sense District', value: 'Sense District' },
  { label: 'Sensenti', value: 'Sensenti' },
  { label: 'Sensuntepeque', value: 'Sensuntepeque' },
  { label: 'Senta', value: 'Senta' },
  { label: 'Senterada', value: 'Senterada' },
  { label: 'Sentheim', value: 'Sentheim' },
  { label: 'Šentilj V Slov. Goricah', value: 'Šentilj V Slov. Goricah' },
  { label: 'Sentinela Do Sul', value: 'Sentinela Do Sul' },
  { label: 'Sentispac', value: 'Sentispac' },
  { label: 'Sentiu De Sió La', value: 'Sentiu De Sió La' },
  { label: 'Šentjernej', value: 'Šentjernej' },
  { label: 'Šentjur', value: 'Šentjur' },
  { label: 'Sentmenat', value: 'Sentmenat' },
  { label: 'Sento Sé', value: 'Sento Sé' },
  { label: 'Šentrupert', value: 'Šentrupert' },
  { label: 'Šentvid District', value: 'Šentvid District' },
  { label: 'Šentvid Pri Stični', value: 'Šentvid Pri Stični' },
  { label: 'Senyera', value: 'Senyera' },
  { label: 'Seocheon-Gun', value: 'Seocheon-Gun' },
  { label: 'Seocho-Gu', value: 'Seocho-Gu' },
  { label: 'Seodaemun-Gu', value: 'Seodaemun-Gu' },
  { label: 'Seo-Gu', value: 'Seo-Gu' },
  { label: 'Seo-Gu', value: 'Seo-Gu' },
  { label: 'Seo-Gu', value: 'Seo-Gu' },
  { label: 'Seogwipo', value: 'Seogwipo' },
  { label: 'Seohara', value: 'Seohara' },
  { label: 'Seon', value: 'Seon' },
  { label: 'Seondha', value: 'Seondha' },
  { label: 'Seongbuk-Gu', value: 'Seongbuk-Gu' },
  { label: 'Seongdong-Gu', value: 'Seongdong-Gu' },
  { label: 'Seonghwan', value: 'Seonghwan' },
  { label: 'Seongju-Gun', value: 'Seongju-Gun' },
  { label: 'Seongnam-Si', value: 'Seongnam-Si' },
  { label: 'Seoni', value: 'Seoni' },
  { label: 'Seoni', value: 'Seoni' },
  { label: 'Seoni Malwa', value: 'Seoni Malwa' },
  { label: 'Seorinarayan', value: 'Seorinarayan' },
  { label: 'Seosan', value: 'Seosan' },
  { label: 'Seosan-Si', value: 'Seosan-Si' },
  { label: 'Seoul', value: 'Seoul' },
  { label: 'Sepatan', value: 'Sepatan' },
  { label: 'Sepekov', value: 'Sepekov' },
  { label: 'Sepid Dasht', value: 'Sepid Dasht' },
  { label: 'Sepidan', value: 'Sepidan' },
  { label: 'Sepino', value: 'Sepino' },
  { label: 'Sępolno', value: 'Sępolno' },
  { label: 'Sępólno Krajeńskie', value: 'Sępólno Krajeńskie' },
  { label: 'Sępopol', value: 'Sępopol' },
  { label: 'Seppiana', value: 'Seppiana' },
  { label: 'Șepreuș', value: 'Șepreuș' },
  { label: 'Septème', value: 'Septème' },
  { label: 'Septèmes-Les-Vallons', value: 'Septèmes-Les-Vallons' },
  { label: 'Septemvri', value: 'Septemvri' },
  { label: 'Septeuil', value: 'Septeuil' },
  { label: 'Septfonds', value: 'Septfonds' },
  { label: 'Septfontaines', value: 'Septfontaines' },
  { label: 'Sept-Îles', value: 'Sept-Îles' },
  { label: 'Sepuka', value: 'Sepuka' },
  { label: 'Sepulcro-Hilario', value: 'Sepulcro-Hilario' },
  { label: 'Sepúlveda', value: 'Sepúlveda' },
  { label: 'Sequals', value: 'Sequals' },
  { label: 'Sequatchie County', value: 'Sequatchie County' },
  { label: 'Sequedin', value: 'Sequedin' },
  { label: 'Sequeira', value: 'Sequeira' },
  { label: 'Sequeiros', value: 'Sequeiros' },
  { label: 'Sequera De Fresno', value: 'Sequera De Fresno' },
  { label: 'Sequera De Haza La', value: 'Sequera De Haza La' },
  { label: 'Sequeros', value: 'Sequeros' },
  { label: 'Sequim', value: 'Sequim' },
  { label: 'Sequoyah County', value: 'Sequoyah County' },
  { label: 'Serabu', value: 'Serabu' },
  { label: 'Serafimovich', value: 'Serafimovich' },
  { label: 'Serafimovskiy', value: 'Serafimovskiy' },
  { label: 'Serafina Corrêa', value: 'Serafina Corrêa' },
  { label: 'Seraincourt', value: 'Seraincourt' },
  { label: 'Seraing', value: 'Seraing' },
  { label: 'Seram', value: 'Seram' },
  { label: 'Serampore', value: 'Serampore' },
  { label: 'Serang', value: 'Serang' },
  { label: 'Serangoon', value: 'Serangoon' },
  { label: 'Serasa', value: 'Serasa' },
  { label: 'Seravezza', value: 'Seravezza' },
  { label: 'Şerbăneşti', value: 'Şerbăneşti' },
  { label: 'Șerbănești', value: 'Șerbănești' },
  { label: 'Șerbănești', value: 'Șerbănești' },
  { label: 'Șerbăuți', value: 'Șerbăuți' },
  { label: 'Șerbeni', value: 'Șerbeni' },
  { label: 'Șerboeni', value: 'Șerboeni' },
  { label: 'Şercaia', value: 'Şercaia' },
  { label: 'Serchhip', value: 'Serchhip' },
  { label: 'Serdanu', value: 'Serdanu' },
  { label: 'Serdar', value: 'Serdar' },
  { label: 'Serdiana', value: 'Serdiana' },
  { label: 'Serdivan', value: 'Serdivan' },
  { label: 'Serdobsk', value: 'Serdobsk' },
  { label: 'Serebriya', value: 'Serebriya' },
  { label: 'Serebryanka', value: 'Serebryanka' },
  { label: 'Serebryanka', value: 'Serebryanka' },
  { label: 'Serebryano-Prudskiy Rayon', value: 'Serebryano-Prudskiy Rayon' },
  { label: 'Serebryanyy Bor', value: 'Serebryanyy Bor' },
  { label: 'Serebryanyye Prudy', value: 'Serebryanyye Prudy' },
  { label: 'Seredeyskiy', value: 'Seredeyskiy' },
  { label: 'Serëdka', value: 'Serëdka' },
  { label: 'Serednye', value: 'Serednye' },
  { label: 'Serednye Vodyane', value: 'Serednye Vodyane' },
  { label: 'Seredyna-Buda', value: 'Seredyna-Buda' },
  { label: 'Şereflikoçhisar', value: 'Şereflikoçhisar' },
  { label: 'Seregélyes', value: 'Seregélyes' },
  { label: 'Seregno', value: 'Seregno' },
  { label: 'Séreilhac', value: 'Séreilhac' },
  { label: 'Serekunda', value: 'Serekunda' },
  { label: 'Serémange-Erzange', value: 'Serémange-Erzange' },
  { label: 'Seremban', value: 'Seremban' },
  { label: 'Seren Del Grappa', value: 'Seren Del Grappa' },
  { label: 'Serenada', value: 'Serenada' },
  { label: 'Serendah', value: 'Serendah' },
  { label: 'Serenje', value: 'Serenje' },
  { label: 'Sérent', value: 'Sérent' },
  { label: 'Seres', value: 'Seres' },
  { label: 'Sérézin-Du-Rhône', value: 'Sérézin-Du-Rhône' },
  { label: 'Serfaus', value: 'Serfaus' },
  { label: 'Sergach', value: 'Sergach' },
  { label: 'Sergachskiy Rayon', value: 'Sergachskiy Rayon' },
  { label: 'Sergeant Bluff', value: 'Sergeant Bluff' },
  { label: 'Sergeyevka', value: 'Sergeyevka' },
  { label: 'Sergeyevka', value: 'Sergeyevka' },
  { label: 'Sergines', value: 'Sergines' },
  { label: 'Sergio Butrón Casas', value: 'Sergio Butrón Casas' },
  { label: 'Sergio Osmeña Sr', value: 'Sergio Osmeña Sr' },
  { label: 'Sergio Villaseñor', value: 'Sergio Villaseñor' },
  { label: 'Sergiyev Posad', value: 'Sergiyev Posad' },
  { label: 'Sergiyevo-Posadskiy Rayon', value: 'Sergiyevo-Posadskiy Rayon' },
  { label: 'Sergnano', value: 'Sergnano' },
  { label: 'Sergokala', value: 'Sergokala' },
  { label: 'Sergokalinskiy Rayon', value: 'Sergokalinskiy Rayon' },
  { label: 'Sergy', value: 'Sergy' },
  { label: 'Serhetabat', value: 'Serhetabat' },
  { label: 'Serhiyivka', value: 'Serhiyivka' },
  { label: 'Seria', value: 'Seria' },
  { label: 'Seriate', value: 'Seriate' },
  { label: 'Šerići', value: 'Šerići' },
  { label: 'Sericita', value: 'Sericita' },
  { label: 'Sérifontaine', value: 'Sérifontaine' },
  { label: 'Sérifos', value: 'Sérifos' },
  { label: 'Sérignan', value: 'Sérignan' },
  { label: 'Sérignan-Du-Comtat', value: 'Sérignan-Du-Comtat' },
  { label: 'Sérigné', value: 'Sérigné' },
  { label: 'Serik', value: 'Serik' },
  { label: 'Serilingampalle', value: 'Serilingampalle' },
  { label: 'Serina', value: 'Serina' },
  { label: 'Seringueiras', value: 'Seringueiras' },
  { label: 'Serinhisar', value: 'Serinhisar' },
  { label: 'Serino', value: 'Serino' },
  { label: 'Serinyà', value: 'Serinyà' },
  { label: 'Sério', value: 'Sério' },
  { label: 'Seririt', value: 'Seririt' },
  { label: 'Serish Abad', value: 'Serish Abad' },
  { label: 'Seritinga', value: 'Seritinga' },
  { label: 'Serkan', value: 'Serkan' },
  { label: 'Serle', value: 'Serle' },
  { label: 'Sermaises', value: 'Sermaises' },
  { label: 'Sermaize-Les-Bains', value: 'Sermaize-Les-Bains' },
  { label: 'Sermérieu', value: 'Sermérieu' },
  { label: 'Sermide', value: 'Sermide' },
  { label: 'Sermoise-Sur-Loire', value: 'Sermoise-Sur-Loire' },
  { label: 'Sermonde', value: 'Sermonde' },
  { label: 'Sermoneta', value: 'Sermoneta' },
  { label: 'Serna Del Monte La', value: 'Serna Del Monte La' },
  { label: 'Serna La', value: 'Serna La' },
  { label: 'Sernaglia Della Battaglia', value: 'Sernaglia Della Battaglia' },
  { label: 'Sernancelhe', value: 'Sernancelhe' },
  { label: 'Sernhac', value: 'Sernhac' },
  { label: 'Serniki', value: 'Serniki' },
  { label: 'Sernio', value: 'Sernio' },
  { label: 'Sernovodsk', value: 'Sernovodsk' },
  { label: 'Sernovodsk', value: 'Sernovodsk' },
  { label: 'Sernur', value: 'Sernur' },
  { label: 'Sernurskiy Rayon', value: 'Sernurskiy Rayon' },
  { label: 'Seroa', value: 'Seroa' },
  { label: 'Serock', value: 'Serock' },
  { label: 'Serokomla', value: 'Serokomla' },
  { label: 'Serole', value: 'Serole' },
  { label: 'Serón', value: 'Serón' },
  { label: 'Serón De Nágima', value: 'Serón De Nágima' },
  { label: 'Serone', value: 'Serone' },
  { label: 'Serooskerke', value: 'Serooskerke' },
  { label: 'Seropédica', value: 'Seropédica' },
  { label: 'Seròs', value: 'Seròs' },
  { label: 'Serov', value: 'Serov' },
  { label: 'Serow', value: 'Serow' },
  { label: 'Serowe', value: 'Serowe' },
  { label: 'Serpa', value: 'Serpa' },
  { label: 'Serpaize', value: 'Serpaize' },
  { label: 'Serpentine', value: 'Serpentine' },
  { label: 'Serpentine-Jarrahdale', value: 'Serpentine-Jarrahdale' },
  { label: 'Serpneve', value: 'Serpneve' },
  { label: 'Serpong', value: 'Serpong' },
  { label: 'Serpukhov', value: 'Serpukhov' },
  { label: 'Serpukhovskiy Rayon', value: 'Serpukhovskiy Rayon' },
  { label: 'Serpur', value: 'Serpur' },
  { label: 'Serques', value: 'Serques' },
  { label: 'Serqueux', value: 'Serqueux' },
  { label: 'Serquigny', value: 'Serquigny' },
  { label: 'Serra', value: 'Serra' },
  { label: 'Serra', value: 'Serra' },
  { label: 'Serra Alta', value: 'Serra Alta' },
  { label: 'Serra Azul', value: 'Serra Azul' },
  { label: 'Serra Azul De Minas', value: 'Serra Azul De Minas' },
  { label: 'Serra Branca', value: 'Serra Branca' },
  { label: 'Serra Caiada', value: 'Serra Caiada' },
  { label: 'Serra Da Raiz', value: 'Serra Da Raiz' },
  { label: 'Serra Da Saudade', value: 'Serra Da Saudade' },
  { label: 'Serra Daiello', value: 'Serra Daiello' },
  { label: 'Serra De Conti', value: 'Serra De Conti' },
  { label: 'Serra De Daró', value: 'Serra De Daró' },
  { label: 'Serra De El-Rei', value: 'Serra De El-Rei' },
  { label: 'Serra De São Bento', value: 'Serra De São Bento' },
  { label: 'Serra Di Maiolo', value: 'Serra Di Maiolo' },
  { label: 'Serra Do Mel', value: 'Serra Do Mel' },
  { label: 'Serra Do Navio', value: 'Serra Do Navio' },
  { label: 'Serra Do Ramalho', value: 'Serra Do Ramalho' },
  { label: 'Serra Do Salitre', value: 'Serra Do Salitre' },
  { label: 'Serra Dos Aimorés', value: 'Serra Dos Aimorés' },
  { label: 'Serra Dourada', value: 'Serra Dourada' },
  { label: 'Serra Grande', value: 'Serra Grande' },
  { label: 'Serra Negra', value: 'Serra Negra' },
  { label: 'Serra Negra Do Norte', value: 'Serra Negra Do Norte' },
  { label: 'Serra Nova Dourada', value: 'Serra Nova Dourada' },
  { label: 'Serra Pedace', value: 'Serra Pedace' },
  { label: 'Serra Preta', value: 'Serra Preta' },
  { label: 'Serra Redonda', value: 'Serra Redonda' },
  { label: 'Serra Riccò', value: 'Serra Riccò' },
  { label: 'Serra San Bruno', value: 'Serra San Bruno' },
  { label: 'Serra San Quirico', value: 'Serra San Quirico' },
  { label: 'Serra Santabbondio', value: 'Serra Santabbondio' },
  { label: 'Serra Talhada', value: 'Serra Talhada' },
  { label: 'Serracapriola', value: 'Serracapriola' },
  { label: 'Serrada', value: 'Serrada' },
  { label: 'Serrada La', value: 'Serrada La' },
  { label: 'Serradifalco', value: 'Serradifalco' },
  { label: 'Serradilla', value: 'Serradilla' },
  { label: 'Serradilla Del Arroyo', value: 'Serradilla Del Arroyo' },
  { label: 'Serradilla Del Llano', value: 'Serradilla Del Llano' },
  { label: 'Serralunga Dalba', value: 'Serralunga Dalba' },
  { label: 'Serralunga Di Crea', value: 'Serralunga Di Crea' },
  { label: 'Serramanna', value: 'Serramanna' },
  { label: 'Serramazzoni', value: 'Serramazzoni' },
  { label: 'Serramezzana', value: 'Serramezzana' },
  { label: 'Serramonacesca', value: 'Serramonacesca' },
  { label: 'Serrana', value: 'Serrana' },
  { label: 'Serrania', value: 'Serrania' },
  { label: 'Serranillos', value: 'Serranillos' },
  { label: 'Serranillos Del Valle', value: 'Serranillos Del Valle' },
  { label: 'Serrano', value: 'Serrano' },
  { label: 'Serrano', value: 'Serrano' },
  { label: 'Serrano', value: 'Serrano' },
  { label: 'Serrano Do Maranhão', value: 'Serrano Do Maranhão' },
  { label: 'Serranópolis', value: 'Serranópolis' },
  { label: 'Serranópolis De Minas', value: 'Serranópolis De Minas' },
  { label: 'Serranópolis Do Iguaçu', value: 'Serranópolis Do Iguaçu' },
  { label: 'Serranos', value: 'Serranos' },
  { label: 'Serrapetrona', value: 'Serrapetrona' },
  { label: 'Serrara Fontana', value: 'Serrara Fontana' },
  { label: 'Serraria', value: 'Serraria' },
  { label: 'Serrastretta', value: 'Serrastretta' },
  { label: 'Serrata', value: 'Serrata' },
  { label: 'Serravalle', value: 'Serravalle' },
  { label: 'Serravalle', value: 'Serravalle' },
  { label: 'Serravalle A Po', value: 'Serravalle A Po' },
  { label: 'Serravalle Di Chienti', value: 'Serravalle Di Chienti' },
  { label: 'Serravalle Langhe', value: 'Serravalle Langhe' },
  { label: 'Serravalle Pistoiese', value: 'Serravalle Pistoiese' },
  { label: 'Serravalle Scrivia', value: 'Serravalle Scrivia' },
  { label: 'Serravalle Sesia', value: 'Serravalle Sesia' },
  { label: 'Serre', value: 'Serre' },
  { label: 'Serre', value: 'Serre' },
  { label: 'Serre Di Rapolano', value: 'Serre Di Rapolano' },
  { label: 'Serrejón', value: 'Serrejón' },
  { label: 'Serre-Les-Sapins', value: 'Serre-Les-Sapins' },
  { label: 'Serrenti', value: 'Serrenti' },
  { label: 'Serres', value: 'Serres' },
  { label: 'Sérres', value: 'Sérres' },
  { label: 'Serres-Castet', value: 'Serres-Castet' },
  { label: 'Serrezuela', value: 'Serrezuela' },
  { label: 'Serri', value: 'Serri' },
  { label: 'Serrières', value: 'Serrières' },
  { label: 'Serrières-De-Briord', value: 'Serrières-De-Briord' },
  { label: 'Serrig', value: 'Serrig' },
  { label: 'Serrinha', value: 'Serrinha' },
  { label: 'Serrinha', value: 'Serrinha' },
  { label: 'Serrinha Dos Pintos', value: 'Serrinha Dos Pintos' },
  { label: 'Serris', value: 'Serris' },
  { label: 'Serrita', value: 'Serrita' },
  { label: 'Serro', value: 'Serro' },
  { label: 'Serrolândia', value: 'Serrolândia' },
  { label: 'Serrone', value: 'Serrone' },
  { label: 'Serrungarina', value: 'Serrungarina' },
  { label: 'Sersale', value: 'Sersale' },
  { label: 'Sersheim', value: 'Sersheim' },
  { label: 'Sertã', value: 'Sertã' },
  { label: 'Sertaneja', value: 'Sertaneja' },
  { label: 'Sertânia', value: 'Sertânia' },
  { label: 'Sertanópolis', value: 'Sertanópolis' },
  { label: 'Sertão', value: 'Sertão' },
  { label: 'Sertão Santana', value: 'Sertão Santana' },
  { label: 'Sertãozinho', value: 'Sertãozinho' },
  { label: 'Sertãozinho', value: 'Sertãozinho' },
  { label: 'Sertolovo', value: 'Sertolovo' },
  { label: 'Serua Province', value: 'Serua Province' },
  { label: 'Serula', value: 'Serula' },
  { label: 'Servas', value: 'Servas' },
  { label: 'Sérvia', value: 'Sérvia' },
  { label: 'Servian', value: 'Servian' },
  { label: 'Servigliano', value: 'Servigliano' },
  { label: 'Servo', value: 'Servo' },
  { label: 'Servon', value: 'Servon' },
  { label: 'Servon-Sur-Vilaine', value: 'Servon-Sur-Vilaine' },
  { label: 'Seryshevo', value: 'Seryshevo' },
  { label: 'Seryshevskiy Rayon', value: 'Seryshevskiy Rayon' },
  { label: 'Serzedelo', value: 'Serzedelo' },
  { label: 'Serzedo', value: 'Serzedo' },
  { label: 'Serzedo', value: 'Serzedo' },
  { label: 'Serzhen’-Yurt', value: 'Serzhen’-Yurt' },
  { label: 'Ses Salines', value: 'Ses Salines' },
  { label: 'Sesa', value: 'Sesa' },
  { label: 'Seseña', value: 'Seseña' },
  { label: 'Sesfontein Constituency', value: 'Sesfontein Constituency' },
  { label: 'Seshcha', value: 'Seshcha' },
  { label: 'Sesheke', value: 'Sesheke' },
  { label: 'Sesimbra', value: 'Sesimbra' },
  { label: 'Šeškinė', value: 'Šeškinė' },
  { label: 'Sesma', value: 'Sesma' },
  { label: 'Sesori', value: 'Sesori' },
  { label: 'Sesquilé', value: 'Sesquilé' },
  { label: 'Sessa Aurunca', value: 'Sessa Aurunca' },
  { label: 'Sessa Cilento', value: 'Sessa Cilento' },
  { label: 'Sessame', value: 'Sessame' },
  { label: 'Sessano Del Molise', value: 'Sessano Del Molise' },
  { label: 'Sessenheim', value: 'Sessenheim' },
  { label: 'Sesser', value: 'Sesser' },
  { label: 'Seßlach', value: 'Seßlach' },
  { label: 'Sesta Godano', value: 'Sesta Godano' },
  { label: 'Šestajovice', value: 'Šestajovice' },
  { label: 'Sestao', value: 'Sestao' },
  { label: 'Sesteadero', value: 'Sesteadero' },
  { label: 'Sestino', value: 'Sestino' },
  { label: 'Sesto', value: 'Sesto' },
  { label: 'Sesto Al Reghena', value: 'Sesto Al Reghena' },
  { label: 'Sesto Calende', value: 'Sesto Calende' },
  { label: 'Sesto Campano', value: 'Sesto Campano' },
  { label: 'Sesto Ed Uniti', value: 'Sesto Ed Uniti' },
  { label: 'Sesto Fiorentino', value: 'Sesto Fiorentino' },
  { label: 'Sesto Imolese', value: 'Sesto Imolese' },
  { label: 'Sesto San Giovanni', value: 'Sesto San Giovanni' },
  { label: 'Sestola', value: 'Sestola' },
  { label: 'Sestri Levante', value: 'Sestri Levante' },
  { label: 'Sestrica', value: 'Sestrica' },
  { label: 'Sestriere', value: 'Sestriere' },
  { label: 'Sestroretsk', value: 'Sestroretsk' },
  { label: 'Sestu', value: 'Sestu' },
  { label: 'Sesué', value: 'Sesué' },
  { label: 'Sesvete', value: 'Sesvete' },
  { label: 'Setagaya-Ku', value: 'Setagaya-Ku' },
  { label: 'Setakamachi-Takayanagi', value: 'Setakamachi-Takayanagi' },
  { label: 'Setaq', value: 'Setaq' },
  { label: 'Setauket-East Setauket', value: 'Setauket-East Setauket' },
  { label: 'Setcases', value: 'Setcases' },
  { label: 'Sète', value: 'Sète' },
  { label: 'Sete Barras', value: 'Sete Barras' },
  { label: 'Sete De Setembro', value: 'Sete De Setembro' },
  { label: 'Sete Lagoas', value: 'Sete Lagoas' },
  { label: 'Sete Quedas', value: 'Sete Quedas' },
  { label: 'Setenil De Las Bodegas', value: 'Setenil De Las Bodegas' },
  { label: 'Setermoen', value: 'Setermoen' },
  { label: 'Seth', value: 'Seth' },
  { label: 'Seth Ward', value: 'Seth Ward' },
  { label: 'Setharja Old', value: 'Setharja Old' },
  { label: 'Sétif', value: 'Sétif' },
  { label: 'Setiles', value: 'Setiles' },
  { label: 'Seto', value: 'Seto' },
  { label: 'Seto-Shi', value: 'Seto-Shi' },
  { label: 'Setouchi Shi', value: 'Setouchi Shi' },
  { label: 'Settala', value: 'Settala' },
  { label: 'Settat', value: 'Settat' },
  { label: 'Settat Province', value: 'Settat Province' },
  { label: 'Settefrati', value: 'Settefrati' },
  { label: 'Setteville', value: 'Setteville' },
  { label: 'Setti Fatma', value: 'Setti Fatma' },
  { label: 'Settime', value: 'Settime' },
  { label: 'Settimo', value: 'Settimo' },
  { label: 'Settimo', value: 'Settimo' },
  { label: 'Settimo Milanese', value: 'Settimo Milanese' },
  { label: 'Settimo Rottaro', value: 'Settimo Rottaro' },
  { label: 'Settimo San Pietro', value: 'Settimo San Pietro' },
  { label: 'Settimo Torinese', value: 'Settimo Torinese' },
  { label: 'Settimo Vittone', value: 'Settimo Vittone' },
  { label: 'Settingiano', value: 'Settingiano' },
  { label: 'Settle', value: 'Settle' },
  { label: 'Settsu Shi', value: 'Settsu Shi' },
  { label: 'Setúbal', value: 'Setúbal' },
  { label: 'Setubinha', value: 'Setubinha' },
  { label: 'Setun’', value: 'Setun’' },
  { label: 'Setzu', value: 'Setzu' },
  { label: 'Seu Durgell La', value: 'Seu Durgell La' },
  { label: 'Seubersdorf', value: 'Seubersdorf' },
  { label: 'Seuca', value: 'Seuca' },
  { label: 'Seugy', value: 'Seugy' },
  { label: 'Seui', value: 'Seui' },
  { label: 'Seukendorf', value: 'Seukendorf' },
  { label: 'Șeulia De Mureș', value: 'Șeulia De Mureș' },
  { label: 'Seulingen', value: 'Seulingen' },
  { label: 'Seulo', value: 'Seulo' },
  { label: 'Seurre', value: 'Seurre' },
  { label: 'Seuzach / Seuzach (Dorf)', value: 'Seuzach / Seuzach (Dorf)' },
  { label: 'Seuzach Dorf', value: 'Seuzach Dorf' },
  { label: 'Seuzach Dorf / Breite-Weid', value: 'Seuzach Dorf / Breite-Weid' },
  { label: 'Seva', value: 'Seva' },
  { label: 'Sevagram', value: 'Sevagram' },
  { label: 'Sevan', value: 'Sevan' },
  { label: 'Ševarice', value: 'Ševarice' },
  { label: 'Sevastianá', value: 'Sevastianá' },
  { label: 'Sevastopol', value: 'Sevastopol' },
  { label: 'Sevegliano', value: 'Sevegliano' },
  { label: 'Sevelen', value: 'Sevelen' },
  { label: 'Seven Corners', value: 'Seven Corners' },
  { label: 'Seven Fields', value: 'Seven Fields' },
  { label: 'Seven Hills', value: 'Seven Hills' },
  { label: 'Seven Hills', value: 'Seven Hills' },
  { label: 'Seven Hills', value: 'Seven Hills' },
  { label: 'Seven Lakes', value: 'Seven Lakes' },
  { label: 'Seven Mile Beach', value: 'Seven Mile Beach' },
  { label: 'Seven Oaks', value: 'Seven Oaks' },
  { label: 'Seven Pagodas', value: 'Seven Pagodas' },
  { label: 'Seven Points', value: 'Seven Points' },
  { label: 'Seven Rivers', value: 'Seven Rivers' },
  { label: 'Seven Sisters', value: 'Seven Sisters' },
  { label: 'Seven Trees', value: 'Seven Trees' },
  { label: 'Sevenoaks', value: 'Sevenoaks' },
  { label: 'Seventeen Mile Rocks', value: 'Seventeen Mile Rocks' },
  { label: 'Sevenum', value: 'Sevenum' },
  { label: 'Sever Do Vouga', value: 'Sever Do Vouga' },
  { label: 'Sévérac', value: 'Sévérac' },
  { label: 'Sévérac-Le-Château', value: 'Sévérac-Le-Château' },
  { label: 'Severance', value: 'Severance' },
  { label: 'Severiano De Almeida', value: 'Severiano De Almeida' },
  { label: 'Severiano Melo', value: 'Severiano Melo' },
  { label: 'Severin', value: 'Severin' },
  { label: 'Severínia', value: 'Severínia' },
  { label: 'Severka', value: 'Severka' },
  { label: 'Severka', value: 'Severka' },
  { label: 'Severn', value: 'Severn' },
  { label: 'Severn Beach', value: 'Severn Beach' },
  { label: 'Severna Park', value: 'Severna Park' },
  { label: 'Severnoye', value: 'Severnoye' },
  { label: 'Severnoye', value: 'Severnoye' },
  { label: 'Severnoye', value: 'Severnoye' },
  { label: 'Severnyy', value: 'Severnyy' },
  { label: 'Severnyy', value: 'Severnyy' },
  { label: 'Severnyy', value: 'Severnyy' },
  { label: 'Severnyy Rayon', value: 'Severnyy Rayon' },
  { label: 'Severnyy-Kospashskiy', value: 'Severnyy-Kospashskiy' },
  { label: 'Severobaykal’Sk', value: 'Severobaykal’Sk' },
  { label: 'Severo-Baykal’Skiy Rayon', value: 'Severo-Baykal’Skiy Rayon' },
  { label: 'Severodvinsk', value: 'Severodvinsk' },
  { label: 'Severo-Kuril’Sk', value: 'Severo-Kuril’Sk' },
  { label: 'Severomorsk', value: 'Severomorsk' },
  { label: 'Severomuysk', value: 'Severomuysk' },
  { label: 'Severoural’Sk', value: 'Severoural’Sk' },
  { label: 'Severo-Vostotchnyi Bank', value: 'Severo-Vostotchnyi Bank' },
  { label: 'Severo-Yeniseyskiy', value: 'Severo-Yeniseyskiy' },
  { label: 'Severo-Yeniseyskiy Rayon', value: 'Severo-Yeniseyskiy Rayon' },
  { label: 'Severo-Zadonsk', value: 'Severo-Zadonsk' },
  { label: 'Seversk', value: 'Seversk' },
  { label: 'Severskaya', value: 'Severskaya' },
  { label: 'Seveso', value: 'Seveso' },
  { label: 'Ševětín', value: 'Ševětín' },
  { label: 'Ševica', value: 'Ševica' },
  { label: 'Sevier County', value: 'Sevier County' },
  { label: 'Sevier County', value: 'Sevier County' },
  { label: 'Sevier County', value: 'Sevier County' },
  { label: 'Sevierville', value: 'Sevierville' },
  { label: 'Sévignac', value: 'Sévignac' },
  { label: 'Sevilla', value: 'Sevilla' },
  { label: 'Sevilla', value: 'Sevilla' },
  { label: 'Sevilla', value: 'Sevilla' },
  { label: 'Sevilla La Nueva', value: 'Sevilla La Nueva' },
  { label: 'Seville', value: 'Seville' },
  { label: 'Seville', value: 'Seville' },
  { label: 'Seville Grove', value: 'Seville Grove' },
  { label: 'Sevilleja De La Jara', value: 'Sevilleja De La Jara' },
  { label: 'Sevina', value: 'Sevina' },
  { label: 'Sevlievo', value: 'Sevlievo' },
  { label: 'Sevnica', value: 'Sevnica' },
  { label: 'Sevran', value: 'Sevran' },
  { label: 'Sèvres', value: 'Sèvres' },
  { label: 'Sèvres-Anxaumont', value: 'Sèvres-Anxaumont' },
  { label: 'Sevrey', value: 'Sevrey' },
  { label: 'Sevrier', value: 'Sevrier' },
  { label: 'Sevsk', value: 'Sevsk' },
  { label: 'Sevskiy Rayon', value: 'Sevskiy Rayon' },
  { label: 'Sewalls Point', value: 'Sewalls Point' },
  { label: 'Sewanee', value: 'Sewanee' },
  { label: 'Seward', value: 'Seward' },
  { label: 'Seward', value: 'Seward' },
  { label: 'Seward County', value: 'Seward County' },
  { label: 'Seward County', value: 'Seward County' },
  { label: 'Sewaren', value: 'Sewaren' },
  { label: 'Sewickley', value: 'Sewickley' },
  { label: 'Sewri', value: 'Sewri' },
  { label: 'Sexau', value: 'Sexau' },
  { label: 'Sexbierum', value: 'Sexbierum' },
  { label: 'Sexmoan', value: 'Sexmoan' },
  { label: 'Sexsmith', value: 'Sexsmith' },
  { label: 'Seybaplaya', value: 'Seybaplaya' },
  { label: 'Seybothenreuth', value: 'Seybothenreuth' },
  { label: 'Seyda', value: 'Seyda' },
  { label: 'Seydi', value: 'Seydi' },
  { label: 'Seydikemer', value: 'Seydikemer' },
  { label: 'Seydiler', value: 'Seydiler' },
  { label: 'Seydişehir', value: 'Seydişehir' },
  { label: 'Seydun', value: 'Seydun' },
  { label: 'Seyé', value: 'Seyé' },
  { label: 'Seyedan', value: 'Seyedan' },
  { label: 'Seyhan', value: 'Seyhan' },
  { label: 'Seyitgazi', value: 'Seyitgazi' },
  { label: 'Seymchan', value: 'Seymchan' },
  { label: 'Seymour', value: 'Seymour' },
  { label: 'Seymour', value: 'Seymour' },
  { label: 'Seymour', value: 'Seymour' },
  { label: 'Seymour', value: 'Seymour' },
  { label: 'Seymour', value: 'Seymour' },
  { label: 'Seymour', value: 'Seymour' },
  { label: 'Seymour', value: 'Seymour' },
  { label: 'Seymour Lands', value: 'Seymour Lands' },
  { label: 'Seyne-Les-Alpes', value: 'Seyne-Les-Alpes' },
  { label: 'Seynod', value: 'Seynod' },
  { label: 'Seyring', value: 'Seyring' },
  { label: 'Seysses', value: 'Seysses' },
  { label: 'Seyssinet-Pariset', value: 'Seyssinet-Pariset' },
  { label: 'Seyssins', value: 'Seyssins' },
  { label: 'Seyssuel', value: 'Seyssuel' },
  { label: 'Sežana', value: 'Sežana' },
  { label: 'Sézanne', value: 'Sézanne' },
  { label: 'Sezemice', value: 'Sezemice' },
  { label: 'Sezimovo Ústí', value: 'Sezimovo Ústí' },
  { label: 'Sezzadio', value: 'Sezzadio' },
  { label: 'Sezze', value: 'Sezze' },
  { label: 'Sezze Scalo', value: 'Sezze Scalo' },
  { label: 'Sfântu Gheorghe', value: 'Sfântu Gheorghe' },
  { label: 'Sfântu Gheorghe', value: 'Sfântu Gheorghe' },
  { label: 'Sfântu Gheorghe', value: 'Sfântu Gheorghe' },
  { label: 'Sfântu Gheorghe', value: 'Sfântu Gheorghe' },
  { label: 'Sfaranda', value: 'Sfaranda' },
  { label: 'Sfârcea', value: 'Sfârcea' },
  { label: 'Sfax', value: 'Sfax' },
  { label: 'Sfendámi', value: 'Sfendámi' },
  { label: 'Sferracavallo', value: 'Sferracavallo' },
  { label: 'Sferracavallo', value: 'Sferracavallo' },
  { label: 'Sfinţeşti', value: 'Sfinţeşti' },
  { label: 'Sfissef', value: 'Sfissef' },
  { label: 'Sforzacosta', value: 'Sforzacosta' },
  { label: 'Sfruz', value: 'Sfruz' },
  { label: 'Sgonico', value: 'Sgonico' },
  { label: 'S-Graveland', value: 'S-Graveland' },
  { label: 'S-Gravenland', value: 'S-Gravenland' },
  { label: 'S-Gravenzande', value: 'S-Gravenzande' },
  { label: 'Sgurgola', value: 'Sgurgola' },
  { label: 'Sha Tin', value: 'Sha Tin' },
  { label: 'Sha Tin Wai', value: 'Sha Tin Wai' },
  { label: 'Sha`Tha', value: 'Sha`Tha' },
  { label: 'Shaab', value: 'Shaab' },
  { label: 'Shaami-Yurt', value: 'Shaami-Yurt' },
  { label: 'Shabab', value: 'Shabab' },
  { label: 'Shabalinskiy Rayon', value: 'Shabalinskiy Rayon' },
  { label: 'Shaban Kareh', value: 'Shaban Kareh' },
  { label: 'Shabel’Skoye', value: 'Shabel’Skoye' },
  { label: 'Shabestar', value: 'Shabestar' },
  { label: 'Shabla', value: 'Shabla' },
  { label: 'Shablykino', value: 'Shablykino' },
  { label: 'Shabo', value: 'Shabo' },
  { label: 'Shabqadar', value: 'Shabqadar' },
  { label: 'Shabrovskiy', value: 'Shabrovskiy' },
  { label: 'Shaburnovo', value: 'Shaburnovo' },
  { label: 'Shache', value: 'Shache' },
  { label: 'Shackelford', value: 'Shackelford' },
  { label: 'Shackelford County', value: 'Shackelford County' },
  { label: 'Shackle Island', value: 'Shackle Island' },
  { label: 'Shadaa', value: 'Shadaa' },
  { label: 'Shadegan', value: 'Shadegan' },
  { label: 'Shadeland', value: 'Shadeland' },
  { label: 'Shademaneh', value: 'Shademaneh' },
  { label: 'Shadipur Julana', value: 'Shadipur Julana' },
  { label: 'Shadmehr', value: 'Shadmehr' },
  { label: 'Shadow Hills', value: 'Shadow Hills' },
  { label: 'Shadoxhurst', value: 'Shadoxhurst' },
  { label: 'Shadrinsk', value: 'Shadrinsk' },
  { label: 'Shadwell', value: 'Shadwell' },
  { label: 'Shady Cove', value: 'Shady Cove' },
  { label: 'Shady Hills', value: 'Shady Hills' },
  { label: 'Shady Hollow', value: 'Shady Hollow' },
  { label: 'Shady Shores', value: 'Shady Shores' },
  { label: 'Shady Side', value: 'Shady Side' },
  { label: 'Shady Spring', value: 'Shady Spring' },
  { label: 'Shadyside', value: 'Shadyside' },
  { label: 'Shafer', value: 'Shafer' },
  { label: 'Shaffa', value: 'Shaffa' },
  { label: 'Shafranovo', value: 'Shafranovo' },
  { label: 'Shaft', value: 'Shaft' },
  { label: 'Shafter', value: 'Shafter' },
  { label: 'Shaftesbury', value: 'Shaftesbury' },
  { label: 'Shaghat', value: 'Shaghat' },
  { label: 'Shagonar', value: 'Shagonar' },
  { label: 'Shah Alam', value: 'Shah Alam' },
  { label: 'Shahabad', value: 'Shahabad' },
  { label: 'Shahabad', value: 'Shahabad' },
  { label: 'Shahabad', value: 'Shahabad' },
  { label: 'Shahada', value: 'Shahada' },
  { label: 'Shahan', value: 'Shahan' },
  { label: 'Shahapur', value: 'Shahapur' },
  { label: 'Shahārah', value: 'Shahārah' },
  { label: 'Shahbā', value: 'Shahbā' },
  { label: 'Shahba District', value: 'Shahba District' },
  { label: 'Shahbaz', value: 'Shahbaz' },
  { label: 'Shahbazpur', value: 'Shahbazpur' },
  { label: 'Shahbuz Rayon', value: 'Shahbuz Rayon' },
  { label: 'Shahdad', value: 'Shahdad' },
  { label: 'Shahdad Kot', value: 'Shahdad Kot' },
  { label: 'Shahdadpur', value: 'Shahdadpur' },
  { label: 'Shahdol', value: 'Shahdol' },
  { label: 'Shahecheng', value: 'Shahecheng' },
  { label: 'Shahedieh', value: 'Shahedieh' },
  { label: 'Shahedshahr', value: 'Shahedshahr' },
  { label: 'Shahganj', value: 'Shahganj' },
  { label: 'Shahgarh', value: 'Shahgarh' },
  { label: 'Shahi', value: 'Shahi' },
  { label: 'Shahid Bhagat Singh Nagar', value: 'Shahid Bhagat Singh Nagar' },
  { label: 'Shahin Dej', value: 'Shahin Dej' },
  { label: 'Shahin Shahr', value: 'Shahin Shahr' },
  { label: 'Shahjahanpur', value: 'Shahjahanpur' },
  { label: 'Shahkot', value: 'Shahkot' },
  { label: 'Shahkot Tehsil', value: 'Shahkot Tehsil' },
  { label: 'Shahmirzad', value: 'Shahmirzad' },
  { label: 'Shahpur', value: 'Shahpur' },
  { label: 'Shahpur', value: 'Shahpur' },
  { label: 'Shahpur', value: 'Shahpur' },
  { label: 'Shahpur', value: 'Shahpur' },
  { label: 'Shahpur', value: 'Shahpur' },
  { label: 'Shahpur', value: 'Shahpur' },
  { label: 'Shahpur Chakar', value: 'Shahpur Chakar' },
  { label: 'Shahpura', value: 'Shahpura' },
  { label: 'Shahpura', value: 'Shahpura' },
  { label: 'Shahr Sultan', value: 'Shahr Sultan' },
  { label: 'Shahrabad', value: 'Shahrabad' },
  { label: 'Shahrak', value: 'Shahrak' },
  { label: 'Shahrak Mes Sarcheshmeh', value: 'Shahrak Mes Sarcheshmeh' },
  { label: 'Shahrak-E Emam Hasan', value: 'Shahrak-E Emam Hasan' },
  { label: 'Shahrbabak', value: 'Shahrbabak' },
  { label: 'Shahrekord', value: 'Shahrekord' },
  { label: 'Shahr-E-Rey', value: 'Shahr-E-Rey' },
  { label: 'Shahreza', value: 'Shahreza' },
  { label: 'Shahrezu', value: 'Shahrezu' },
  { label: 'Shahriar', value: 'Shahriar' },
  { label: 'Shahrikhon Tumani', value: 'Shahrikhon Tumani' },
  { label: 'Shahrinav', value: 'Shahrinav' },
  { label: 'Shahrisabz', value: 'Shahrisabz' },
  { label: 'Shahritus', value: 'Shahritus' },
  { label: 'Shahrixon', value: 'Shahrixon' },
  { label: 'Shahrkian', value: 'Shahrkian' },
  { label: 'Shahroud', value: 'Shahroud' },
  { label: 'Shahrud', value: 'Shahrud' },
  { label: 'Shahumyan', value: 'Shahumyan' },
  { label: 'Shahumyan', value: 'Shahumyan' },
  { label: 'Shailer Park', value: 'Shailer Park' },
  { label: 'Shajapur', value: 'Shajapur' },
  { label: 'Shajiang', value: 'Shajiang' },
  { label: 'Shakargarh', value: 'Shakargarh' },
  { label: 'Shakawe', value: 'Shakawe' },
  { label: 'Shaker Heights', value: 'Shaker Heights' },
  { label: 'Shakhan', value: 'Shakhan' },
  { label: 'Shakhi', value: 'Shakhi' },
  { label: 'Shakhovskaya', value: 'Shakhovskaya' },
  { label: 'Shakhovskoy Rayon', value: 'Shakhovskoy Rayon' },
  { label: 'Shakhta', value: 'Shakhta' },
  { label: 'Shakhtars’K', value: 'Shakhtars’K' },
  { label: 'Shakhtersk', value: 'Shakhtersk' },
  { label: 'Shakhtinsk', value: 'Shakhtinsk' },
  { label: 'Shakhty', value: 'Shakhty' },
  { label: 'Shakhun’Ya', value: 'Shakhun’Ya' },
  { label: 'Shakiso', value: 'Shakiso' },
  { label: 'Shakopee', value: 'Shakopee' },
  { label: 'Shal', value: 'Shal' },
  { label: 'Shal’Skiy', value: 'Shal’Skiy' },
  { label: 'Shalakusha', value: 'Shalakusha' },
  { label: 'Shalamzar ', value: 'Shalamzar ' },
  { label: 'Shalazhi', value: 'Shalazhi' },
  { label: 'Shalbourne', value: 'Shalbourne' },
  { label: 'Shalfleet', value: 'Shalfleet' },
  { label: 'Shali', value: 'Shali' },
  { label: 'Shali', value: 'Shali' },
  { label: 'Shalinskiy Rayon', value: 'Shalinskiy Rayon' },
  { label: 'Shalinskoye', value: 'Shalinskoye' },
  { label: 'Shalkar', value: 'Shalkar' },
  { label: 'Shallotte', value: 'Shallotte' },
  { label: 'Shallowater', value: 'Shallowater' },
  { label: 'Shalman', value: 'Shalman' },
  { label: 'Shalqar', value: 'Shalqar' },
  { label: 'Shalqīya', value: 'Shalqīya' },
  { label: 'Shalushka', value: 'Shalushka' },
  { label: 'Shalvey', value: 'Shalvey' },
  { label: 'Shalya', value: 'Shalya' },
  { label: 'Shalyhyne', value: 'Shalyhyne' },
  { label: 'Sham Churasi', value: 'Sham Churasi' },
  { label: 'Shama Junction', value: 'Shama Junction' },
  { label: 'Shamakhi', value: 'Shamakhi' },
  { label: 'Shamanka', value: 'Shamanka' },
  { label: 'Shamary', value: 'Shamary' },
  { label: 'Shambu', value: 'Shambu' },
  { label: 'Shamgarh', value: 'Shamgarh' },
  { label: 'Shamil’Kala', value: 'Shamil’Kala' },
  { label: 'Shamil’Skiy Rayon', value: 'Shamil’Skiy Rayon' },
  { label: 'Shamkhal', value: 'Shamkhal' },
  { label: 'Shamkhal-Termen', value: 'Shamkhal-Termen' },
  { label: 'Shamkhor', value: 'Shamkhor' },
  { label: 'Shamli', value: 'Shamli' },
  { label: 'Shamokin', value: 'Shamokin' },
  { label: 'Shamokin Dam', value: 'Shamokin Dam' },
  { label: 'Shamrock', value: 'Shamrock' },
  { label: 'Shamsabad', value: 'Shamsabad' },
  { label: 'Shamsabad', value: 'Shamsabad' },
  { label: 'Shamva', value: 'Shamva' },
  { label: 'Shamva District', value: 'Shamva District' },
  { label: 'Shanabad', value: 'Shanabad' },
  { label: 'Shancheng', value: 'Shancheng' },
  { label: 'Shandiz', value: 'Shandiz' },
  { label: 'Shandon', value: 'Shandon' },
  { label: 'Shanga', value: 'Shanga' },
  { label: 'Shangaly', value: 'Shangaly' },
  { label: 'Shangani', value: 'Shangani' },
  { label: 'Shangcang', value: 'Shangcang' },
  { label: 'Shanghai', value: 'Shanghai' },
  { label: 'Shangjie', value: 'Shangjie' },
  { label: 'Shangmei', value: 'Shangmei' },
  { label: 'Shangqiu', value: 'Shangqiu' },
  { label: 'Shangrao', value: 'Shangrao' },
  { label: 'Shangri-La', value: 'Shangri-La' },
  { label: 'Shangyu', value: 'Shangyu' },
  { label: 'Shangzhi', value: 'Shangzhi' },
  { label: 'Shanhaiguan', value: 'Shanhaiguan' },
  { label: 'Shanhecun', value: 'Shanhecun' },
  { label: 'Shani', value: 'Shani' },
  { label: 'Shanjur', value: 'Shanjur' },
  { label: 'Shankargarh', value: 'Shankargarh' },
  { label: 'Shankill', value: 'Shankill' },
  { label: 'Shanklin', value: 'Shanklin' },
  { label: 'Shanmen', value: 'Shanmen' },
  { label: 'Shannan Diqu', value: 'Shannan Diqu' },
  { label: 'Shannon', value: 'Shannon' },
  { label: 'Shannon', value: 'Shannon' },
  { label: 'Shannon', value: 'Shannon' },
  { label: 'Shannon', value: 'Shannon' },
  { label: 'Shannon County', value: 'Shannon County' },
  { label: 'Shannon Hills', value: 'Shannon Hills' },
  { label: 'Shannondale', value: 'Shannondale' },
  { label: 'Shanor-Northvue', value: 'Shanor-Northvue' },
  { label: 'Shanting', value: 'Shanting' },
  { label: 'Shantobe', value: 'Shantobe' },
  { label: 'Shantou', value: 'Shantou' },
  { label: 'Shanwei', value: 'Shanwei' },
  { label: 'Shanxia', value: 'Shanxia' },
  { label: 'Shanyang', value: 'Shanyang' },
  { label: 'Shaoguan', value: 'Shaoguan' },
  { label: 'Shaowu', value: 'Shaowu' },
  { label: 'Shaoxing', value: 'Shaoxing' },
  { label: 'Shap', value: 'Shap' },
  { label: 'Shaping', value: 'Shaping' },
  { label: 'Shapingba District', value: 'Shapingba District' },
  { label: 'Shapleigh', value: 'Shapleigh' },
  { label: 'Shapur Abad', value: 'Shapur Abad' },
  { label: 'Shaqlāwah', value: 'Shaqlāwah' },
  { label: 'Shar', value: 'Shar' },
  { label: 'Shar’Inskiy Rayon', value: 'Shar’Inskiy Rayon' },
  { label: 'Shar’Ya', value: 'Shar’Ya' },
  { label: 'Sharab Ar Rawnah', value: 'Sharab Ar Rawnah' },
  { label: 'Sharab As Salam', value: 'Sharab As Salam' },
  { label: 'Sharabian', value: 'Sharabian' },
  { label: 'Sharafkhaneh', value: 'Sharafkhaneh' },
  { label: 'Sharalday', value: 'Sharalday' },
  { label: 'Sharan', value: 'Sharan' },
  { label: 'Sharanga', value: 'Sharanga' },
  { label: 'Sharangskiy Rayon', value: 'Sharangskiy Rayon' },
  { label: 'Sharanur', value: 'Sharanur' },
  { label: 'Sharas', value: 'Sharas' },
  { label: 'Sharhorod', value: 'Sharhorod' },
  { label: 'Shariatpur ', value: 'Shariatpur ' },
  { label: 'Sharif Abad', value: 'Sharif Abad' },
  { label: 'Sharifabad', value: 'Sharifabad' },
  { label: 'Sharifabad-E Quzan', value: 'Sharifabad-E Quzan' },
  { label: 'Sharifabad-E Tajar', value: 'Sharifabad-E Tajar' },
  { label: 'Shariff Aguak', value: 'Shariff Aguak' },
  { label: 'Sharjah', value: 'Sharjah' },
  { label: 'Shark Bay', value: 'Shark Bay' },
  { label: 'Shark River Hills', value: 'Shark River Hills' },
  { label: 'Sharkan', value: 'Sharkan' },
  { label: 'Sharkawshchyna', value: 'Sharkawshchyna' },
  { label: 'Sharkawshchynski Rayon', value: 'Sharkawshchynski Rayon' },
  { label: 'Sharkey County', value: 'Sharkey County' },
  { label: 'Sharlston', value: 'Sharlston' },
  { label: 'Sharlyk', value: 'Sharlyk' },
  { label: 'Sharm El-Sheikh', value: 'Sharm El-Sheikh' },
  { label: 'Sharnbrook', value: 'Sharnbrook' },
  { label: 'Sharon', value: 'Sharon' },
  { label: 'Sharon', value: 'Sharon' },
  { label: 'Sharon', value: 'Sharon' },
  { label: 'Sharon', value: 'Sharon' },
  { label: 'Sharon', value: 'Sharon' },
  { label: 'Sharon Hill', value: 'Sharon Hill' },
  { label: 'Sharon Springs', value: 'Sharon Springs' },
  { label: 'Sharonville', value: 'Sharonville' },
  { label: 'Sharp County', value: 'Sharp County' },
  { label: 'Sharpes', value: 'Sharpes' },
  { label: 'Sharpness', value: 'Sharpness' },
  { label: 'Sharpsburg', value: 'Sharpsburg' },
  { label: 'Sharpsburg', value: 'Sharpsburg' },
  { label: 'Sharpsville', value: 'Sharpsville' },
  { label: 'Sharqpur', value: 'Sharqpur' },
  { label: 'Sharur City', value: 'Sharur City' },
  { label: 'Sharypovo', value: 'Sharypovo' },
  { label: 'Sharypovskiy Rayon', value: 'Sharypovskiy Rayon' },
  { label: 'Shashemenē', value: 'Shashemenē' },
  { label: 'Shashi', value: 'Shashi' },
  { label: 'Shashūbay', value: 'Shashūbay' },
  { label: 'Shasta', value: 'Shasta' },
  { label: 'Shasta County', value: 'Shasta County' },
  { label: 'Shasta Lake', value: 'Shasta Lake' },
  { label: 'Shatalovo', value: 'Shatalovo' },
  { label: 'Shatin', value: 'Shatin' },
  { label: 'Shatki', value: 'Shatki' },
  { label: 'Shatoy', value: 'Shatoy' },
  { label: 'Shatrovo', value: 'Shatrovo' },
  { label: 'Shats’K', value: 'Shats’K' },
  { label: 'Shats’Kyy Rayon', value: 'Shats’Kyy Rayon' },
  { label: 'Shatsk', value: 'Shatsk' },
  { label: 'Shattuck', value: 'Shattuck' },
  { label: 'Shatura', value: 'Shatura' },
  { label: 'Shaturtorf', value: 'Shaturtorf' },
  { label: 'Shatzal', value: 'Shatzal' },
  { label: 'Shaunavon', value: 'Shaunavon' },
  { label: 'Shavand', value: 'Shavand' },
  { label: 'Shavano Park', value: 'Shavano Park' },
  { label: 'Shavertown', value: 'Shavertown' },
  { label: 'Shaw', value: 'Shaw' },
  { label: 'Shaw', value: 'Shaw' },
  { label: 'Shaw', value: 'Shaw' },
  { label: 'Shaw Heights', value: 'Shaw Heights' },
  { label: 'Shawano', value: 'Shawano' },
  { label: 'Shawano County', value: 'Shawano County' },
  { label: 'Shawbury', value: 'Shawbury' },
  { label: 'Shawinigan', value: 'Shawinigan' },
  { label: 'Shawnee', value: 'Shawnee' },
  { label: 'Shawnee', value: 'Shawnee' },
  { label: 'Shawnee County', value: 'Shawnee County' },
  { label: 'Shawnee Hills', value: 'Shawnee Hills' },
  { label: 'Shawnee Land', value: 'Shawnee Land' },
  { label: 'Shawneetown', value: 'Shawneetown' },
  { label: 'Shawsville', value: 'Shawsville' },
  { label: 'Shawville', value: 'Shawville' },
  { label: 'Shaydon', value: 'Shaydon' },
  { label: 'Shazand', value: 'Shazand' },
  { label: 'Shazikou', value: 'Shazikou' },
  { label: 'Shchastya', value: 'Shchastya' },
  { label: 'Shchebetovka', value: 'Shchebetovka' },
  { label: 'Shcheglovo', value: 'Shcheglovo' },
  { label: 'Shchëkino', value: 'Shchëkino' },
  { label: 'Shchelkovo', value: 'Shchelkovo' },
  { label: 'Shchëlkovskiy Rayon', value: 'Shchëlkovskiy Rayon' },
  { label: 'Shchelkun', value: 'Shchelkun' },
  { label: 'Shcherbinka', value: 'Shcherbinka' },
  { label: 'Shcherbynivka', value: 'Shcherbynivka' },
  { label: 'Shchigry', value: 'Shchigry' },
  { label: 'Shchors', value: 'Shchors' },
  { label: 'Shchuchinsk', value: 'Shchuchinsk' },
  { label: 'Shchuchyn', value: 'Shchuchyn' },
  { label: 'Shchuchynski Rayon', value: 'Shchuchynski Rayon' },
  { label: 'Shchukino', value: 'Shchukino' },
  { label: 'Shchyolkino', value: 'Shchyolkino' },
  { label: 'Shchyrets', value: 'Shchyrets' },
  { label: 'Shearwater', value: 'Shearwater' },
  { label: 'Shebalino', value: 'Shebalino' },
  { label: 'Shebalinskiy Rayon', value: 'Shebalinskiy Rayon' },
  { label: 'Shebekino', value: 'Shebekino' },
  { label: 'Shebekinskiy Rayon', value: 'Shebekinskiy Rayon' },
  { label: 'Sheberta', value: 'Sheberta' },
  { label: 'Sheboygan', value: 'Sheboygan' },
  { label: 'Sheboygan County', value: 'Sheboygan County' },
  { label: 'Sheboygan Falls', value: 'Sheboygan Falls' },
  { label: 'Shebunino', value: 'Shebunino' },
  { label: 'Shedbal', value: 'Shedbal' },
  { label: 'Shediac', value: 'Shediac' },
  { label: 'Shediac Bridge-Shediac River', value: 'Shediac Bridge-Shediac River' },
  { label: 'Shedok', value: 'Shedok' },
  { label: 'Sheepshead Bay', value: 'Sheepshead Bay' },
  { label: 'S-Heerenberg', value: 'S-Heerenberg' },
  { label: 'Sheerness', value: 'Sheerness' },
  { label: 'Sheffield', value: 'Sheffield' },
  { label: 'Sheffield', value: 'Sheffield' },
  { label: 'Sheffield', value: 'Sheffield' },
  { label: 'Sheffield', value: 'Sheffield' },
  { label: 'Sheffield', value: 'Sheffield' },
  { label: 'Sheffield', value: 'Sheffield' },
  { label: 'Sheffield', value: 'Sheffield' },
  { label: 'Sheffield Lake', value: 'Sheffield Lake' },
  { label: 'Shefford', value: 'Shefford' },
  { label: 'Shefield', value: 'Shefield' },
  { label: 'Shegaon', value: 'Shegaon' },
  { label: 'Sheidow Park', value: 'Sheidow Park' },
  { label: 'Sheik Maaruf', value: 'Sheik Maaruf' },
  { label: 'Sheikhpura', value: 'Sheikhpura' },
  { label: 'Sheikhupura', value: 'Sheikhupura' },
  { label: 'Sheka Zone', value: 'Sheka Zone' },
  { label: 'Sheki', value: 'Sheki' },
  { label: 'Sheksna', value: 'Sheksna' },
  { label: 'Sheksninskiy Rayon', value: 'Sheksninskiy Rayon' },
  { label: 'Shelabolikha', value: 'Shelabolikha' },
  { label: 'Shelabolikhinskiy Rayon', value: 'Shelabolikhinskiy Rayon' },
  { label: 'Shelbina', value: 'Shelbina' },
  { label: 'Shelburn', value: 'Shelburn' },
  { label: 'Shelburne', value: 'Shelburne' },
  { label: 'Shelburne', value: 'Shelburne' },
  { label: 'Shelburne', value: 'Shelburne' },
  { label: 'Shelburne Falls', value: 'Shelburne Falls' },
  { label: 'Shelby', value: 'Shelby' },
  { label: 'Shelby', value: 'Shelby' },
  { label: 'Shelby', value: 'Shelby' },
  { label: 'Shelby', value: 'Shelby' },
  { label: 'Shelby', value: 'Shelby' },
  { label: 'Shelby', value: 'Shelby' },
  { label: 'Shelby County', value: 'Shelby County' },
  { label: 'Shelby County', value: 'Shelby County' },
  { label: 'Shelby County', value: 'Shelby County' },
  { label: 'Shelby County', value: 'Shelby County' },
  { label: 'Shelby County', value: 'Shelby County' },
  { label: 'Shelby County', value: 'Shelby County' },
  { label: 'Shelby County', value: 'Shelby County' },
  { label: 'Shelby County', value: 'Shelby County' },
  { label: 'Shelby County', value: 'Shelby County' },
  { label: 'Shelbyville', value: 'Shelbyville' },
  { label: 'Shelbyville', value: 'Shelbyville' },
  { label: 'Shelbyville', value: 'Shelbyville' },
  { label: 'Shelbyville', value: 'Shelbyville' },
  { label: 'Shelbyville', value: 'Shelbyville' },
  { label: 'Sheldon', value: 'Sheldon' },
  { label: 'Sheldon', value: 'Sheldon' },
  { label: 'Sheldon', value: 'Sheldon' },
  { label: 'Sheldon', value: 'Sheldon' },
  { label: 'Sheldon', value: 'Sheldon' },
  { label: 'Shelekhov', value: 'Shelekhov' },
  { label: 'Shelkovichnoye', value: 'Shelkovichnoye' },
  { label: 'Shëlkovskaya', value: 'Shëlkovskaya' },
  { label: 'Shelkovskiy Rayon', value: 'Shelkovskiy Rayon' },
  { label: 'Shell Cove', value: 'Shell Cove' },
  { label: 'Shell Knob', value: 'Shell Knob' },
  { label: 'Shell Lake', value: 'Shell Lake' },
  { label: 'Shell Point', value: 'Shell Point' },
  { label: 'Shell Rock', value: 'Shell Rock' },
  { label: 'Shell Valley', value: 'Shell Valley' },
  { label: 'Shellbrook', value: 'Shellbrook' },
  { label: 'Shelley', value: 'Shelley' },
  { label: 'Shelley', value: 'Shelley' },
  { label: 'Shelley', value: 'Shelley' },
  { label: 'Shellharbour', value: 'Shellharbour' },
  { label: 'Shelly Beach', value: 'Shelly Beach' },
  { label: 'Shelomi', value: 'Shelomi' },
  { label: 'Shelopugino', value: 'Shelopugino' },
  { label: 'Shelter Island', value: 'Shelter Island' },
  { label: 'Shelter Island Heights', value: 'Shelter Island Heights' },
  { label: 'Shelton', value: 'Shelton' },
  { label: 'Shelton', value: 'Shelton' },
  { label: 'Shelton', value: 'Shelton' },
  { label: 'Sheltozero', value: 'Sheltozero' },
  { label: 'Shelui', value: 'Shelui' },
  { label: 'Shemëtovo', value: 'Shemëtovo' },
  { label: 'Shemiranat', value: 'Shemiranat' },
  { label: 'Shemonaīkha', value: 'Shemonaīkha' },
  { label: 'Shemordan', value: 'Shemordan' },
  { label: 'Shemshak', value: 'Shemshak' },
  { label: 'Shemursha', value: 'Shemursha' },
  { label: 'Shemurshinskiy Rayon', value: 'Shemurshinskiy Rayon' },
  { label: 'Shemysheyka', value: 'Shemysheyka' },
  { label: 'Shenandoah', value: 'Shenandoah' },
  { label: 'Shenandoah', value: 'Shenandoah' },
  { label: 'Shenandoah', value: 'Shenandoah' },
  { label: 'Shenandoah', value: 'Shenandoah' },
  { label: 'Shenandoah', value: 'Shenandoah' },
  { label: 'Shenandoah County', value: 'Shenandoah County' },
  { label: 'Shenandoah Farms', value: 'Shenandoah Farms' },
  { label: 'Shenandoah Heights', value: 'Shenandoah Heights' },
  { label: 'Shenavan', value: 'Shenavan' },
  { label: 'Shenavan', value: 'Shenavan' },
  { label: 'Shendabad', value: 'Shendabad' },
  { label: 'Shendi', value: 'Shendi' },
  { label: 'Shëngjin', value: 'Shëngjin' },
  { label: 'Shengli', value: 'Shengli' },
  { label: 'Shenjiamen', value: 'Shenjiamen' },
  { label: 'Shenkursk', value: 'Shenkursk' },
  { label: 'Shenkurskiy Rayon', value: 'Shenkurskiy Rayon' },
  { label: 'Shenley', value: 'Shenley' },
  { label: 'Shennongjia', value: 'Shennongjia' },
  { label: 'Shenorock', value: 'Shenorock' },
  { label: 'Shenstone', value: 'Shenstone' },
  { label: 'Shentala', value: 'Shentala' },
  { label: 'Shenton Park', value: 'Shenton Park' },
  { label: 'Shenyang', value: 'Shenyang' },
  { label: 'Shenzhen', value: 'Shenzhen' },
  { label: 'Sheoganj', value: 'Sheoganj' },
  { label: 'Sheohar', value: 'Sheohar' },
  { label: 'Sheopur', value: 'Sheopur' },
  { label: 'Shepetivka', value: 'Shepetivka' },
  { label: 'Shepherd', value: 'Shepherd' },
  { label: 'Shepherd', value: 'Shepherd' },
  { label: 'Shepherdstown', value: 'Shepherdstown' },
  { label: 'Shepherdsville', value: 'Shepherdsville' },
  { label: 'Shepherdswell', value: 'Shepherdswell' },
  { label: 'Shepley', value: 'Shepley' },
  { label: 'Shepparton', value: 'Shepparton' },
  { label: 'Shepparton East', value: 'Shepparton East' },
  { label: 'Shepperton', value: 'Shepperton' },
  { label: 'Shepshed', value: 'Shepshed' },
  { label: 'Shepsi', value: 'Shepsi' },
  { label: 'Shepton Mallet', value: 'Shepton Mallet' },
  { label: 'Sherafat', value: 'Sherafat' },
  { label: 'Sheragul', value: 'Sheragul' },
  { label: 'Sherbakul’', value: 'Sherbakul’' },
  { label: 'Sherborn', value: 'Sherborn' },
  { label: 'Sherborne', value: 'Sherborne' },
  { label: 'Sherborne St John', value: 'Sherborne St John' },
  { label: 'Sherbrooke', value: 'Sherbrooke' },
  { label: 'Sherburn', value: 'Sherburn' },
  { label: 'Sherburn', value: 'Sherburn' },
  { label: 'Sherburn Hill', value: 'Sherburn Hill' },
  { label: 'Sherburn In Elmet', value: 'Sherburn In Elmet' },
  { label: 'Sherburne', value: 'Sherburne' },
  { label: 'Sherburne County', value: 'Sherburne County' },
  { label: 'Sheregesh', value: 'Sheregesh' },
  { label: 'Sheremet’Yevskiy', value: 'Sheremet’Yevskiy' },
  { label: 'Shergarh', value: 'Shergarh' },
  { label: 'Sherghati', value: 'Sherghati' },
  { label: 'Sheridan', value: 'Sheridan' },
  { label: 'Sheridan', value: 'Sheridan' },
  { label: 'Sheridan', value: 'Sheridan' },
  { label: 'Sheridan', value: 'Sheridan' },
  { label: 'Sheridan', value: 'Sheridan' },
  { label: 'Sheridan', value: 'Sheridan' },
  { label: 'Sheridan', value: 'Sheridan' },
  { label: 'Sheridan County', value: 'Sheridan County' },
  { label: 'Sheridan County', value: 'Sheridan County' },
  { label: 'Sheridan County', value: 'Sheridan County' },
  { label: 'Sheridan County', value: 'Sheridan County' },
  { label: 'Sheridan County', value: 'Sheridan County' },
  { label: 'Sheriff Hutton', value: 'Sheriff Hutton' },
  { label: 'Sheringham', value: 'Sheringham' },
  { label: 'Sherington', value: 'Sherington' },
  { label: 'Sherkaly', value: 'Sherkaly' },
  { label: 'Sherkot', value: 'Sherkot' },
  { label: 'Sherman', value: 'Sherman' },
  { label: 'Sherman', value: 'Sherman' },
  { label: 'Sherman', value: 'Sherman' },
  { label: 'Sherman County', value: 'Sherman County' },
  { label: 'Sherman County', value: 'Sherman County' },
  { label: 'Sherman County', value: 'Sherman County' },
  { label: 'Sherman County', value: 'Sherman County' },
  { label: 'Sherman Oaks', value: 'Sherman Oaks' },
  { label: 'Sherpur ', value: 'Sherpur ' },
  { label: 'Sherrelwood', value: 'Sherrelwood' },
  { label: 'Sherrill', value: 'Sherrill' },
  { label: 'Sherrills Ford', value: 'Sherrills Ford' },
  { label: 'Sherston', value: 'Sherston' },
  { label: 'Shertallai', value: 'Shertallai' },
  { label: 'S-Hertogenbosch', value: 'S-Hertogenbosch' },
  { label: 'Sherwood', value: 'Sherwood' },
  { label: 'Sherwood', value: 'Sherwood' },
  { label: 'Sherwood', value: 'Sherwood' },
  { label: 'Sherwood', value: 'Sherwood' },
  { label: 'Sherwood Content', value: 'Sherwood Content' },
  { label: 'Sherwood Forest', value: 'Sherwood Forest' },
  { label: 'Sherwood Manor', value: 'Sherwood Manor' },
  { label: 'Sherwood Park', value: 'Sherwood Park' },
  { label: 'Sherwood Shores', value: 'Sherwood Shores' },
  { label: 'Sheshdeh', value: 'Sheshdeh' },
  { label: 'Sheshory', value: 'Sheshory' },
  { label: 'Sheshtamad', value: 'Sheshtamad' },
  { label: 'Shestakovo', value: 'Shestakovo' },
  { label: 'Shetland Islands', value: 'Shetland Islands' },
  { label: 'Shetpe', value: 'Shetpe' },
  { label: 'Sheung Shui', value: 'Sheung Shui' },
  { label: 'Shevchenkivs’Kyy Rayon', value: 'Shevchenkivs’Kyy Rayon' },
  { label: 'Shevchenkove', value: 'Shevchenkove' },
  { label: 'Shevchenkove', value: 'Shevchenkove' },
  { label: 'Shevchenkove', value: 'Shevchenkove' },
  { label: 'Sheverin', value: 'Sheverin' },
  { label: 'Shevgaon', value: 'Shevgaon' },
  { label: 'Shevington', value: 'Shevington' },
  { label: 'Shevlyakovo', value: 'Shevlyakovo' },
  { label: 'Sheyban', value: 'Sheyban' },
  { label: 'Sheykh Jarrah', value: 'Sheykh Jarrah' },
  { label: 'Shi`B `Amir', value: 'Shi`B `Amir' },
  { label: 'Shiawassee County', value: 'Shiawassee County' },
  { label: 'Shibam', value: 'Shibam' },
  { label: 'Shibām Kawkabān', value: 'Shibām Kawkabān' },
  { label: 'Shibata', value: 'Shibata' },
  { label: 'Shibata Shi', value: 'Shibata Shi' },
  { label: 'Shibetsu', value: 'Shibetsu' },
  { label: 'Shibetsu Shi', value: 'Shibetsu Shi' },
  { label: 'Shibīn Al Kawm', value: 'Shibīn Al Kawm' },
  { label: 'Shibīn Al Qanāṭir', value: 'Shibīn Al Qanāṭir' },
  { label: 'Shibirghān', value: 'Shibirghān' },
  { label: 'Shibli', value: 'Shibli' },
  { label: 'Shibnagar', value: 'Shibnagar' },
  { label: 'Shibukawa', value: 'Shibukawa' },
  { label: 'Shibukawa-Shi', value: 'Shibukawa-Shi' },
  { label: 'Shibushi', value: 'Shibushi' },
  { label: 'Shibushi-Shi', value: 'Shibushi-Shi' },
  { label: 'Shibuya-Ku', value: 'Shibuya-Ku' },
  { label: 'Shido', value: 'Shido' },
  { label: 'Shieldhill', value: 'Shieldhill' },
  { label: 'Shields', value: 'Shields' },
  { label: 'Shīeli', value: 'Shīeli' },
  { label: 'Shifnal', value: 'Shifnal' },
  { label: 'Shiggaon', value: 'Shiggaon' },
  { label: 'Shigony', value: 'Shigony' },
  { label: 'Shiguai', value: 'Shiguai' },
  { label: 'Shihezi', value: 'Shihezi' },
  { label: 'Shiida', value: 'Shiida' },
  { label: 'Shijak', value: 'Shijak' },
  { label: 'Shijiang', value: 'Shijiang' },
  { label: 'Shijiaochang', value: 'Shijiaochang' },
  { label: 'Shijiazhuang', value: 'Shijiazhuang' },
  { label: 'Shijiazhuang Shi', value: 'Shijiazhuang Shi' },
  { label: 'Shijing', value: 'Shijing' },
  { label: 'Shijonawate Shi', value: 'Shijonawate Shi' },
  { label: 'Shikarpur', value: 'Shikarpur' },
  { label: 'Shikarpur', value: 'Shikarpur' },
  { label: 'Shikarpur', value: 'Shikarpur' },
  { label: 'Shikarpur (Bulandshahr)', value: 'Shikarpur (Bulandshahr)' },
  { label: 'Shikhany', value: 'Shikhany' },
  { label: 'Shikhazany', value: 'Shikhazany' },
  { label: 'Shiki', value: 'Shiki' },
  { label: 'Shiki Shi', value: 'Shiki Shi' },
  { label: 'Shikohabad', value: 'Shikohabad' },
  { label: 'Shikoku-Chūō Shi', value: 'Shikoku-Chūō Shi' },
  { label: 'Shikotan', value: 'Shikotan' },
  { label: 'Shikrapur', value: 'Shikrapur' },
  { label: 'Shil’Da', value: 'Shil’Da' },
  { label: 'Shilandar-E Sofla', value: 'Shilandar-E Sofla' },
  { label: 'Shilaorencun', value: 'Shilaorencun' },
  { label: 'Shilbottle', value: 'Shilbottle' },
  { label: 'Shildon', value: 'Shildon' },
  { label: 'Shilin', value: 'Shilin' },
  { label: 'Shilka', value: 'Shilka' },
  { label: 'Shilkinskiy Rayon', value: 'Shilkinskiy Rayon' },
  { label: 'Shillingstone', value: 'Shillingstone' },
  { label: 'Shillington', value: 'Shillington' },
  { label: 'Shillington', value: 'Shillington' },
  { label: 'Shillong', value: 'Shillong' },
  { label: 'Shilo', value: 'Shilo' },
  { label: 'Shiloh', value: 'Shiloh' },
  { label: 'Shiloh', value: 'Shiloh' },
  { label: 'Shiloh', value: 'Shiloh' },
  { label: 'Shilong', value: 'Shilong' },
  { label: 'Shilovo', value: 'Shilovo' },
  { label: 'Shilovo', value: 'Shilovo' },
  { label: 'Shima', value: 'Shima' },
  { label: 'Shima', value: 'Shima' },
  { label: 'Shimabara', value: 'Shimabara' },
  { label: 'Shimabara-Shi', value: 'Shimabara-Shi' },
  { label: 'Shimada', value: 'Shimada' },
  { label: 'Shimada-Shi', value: 'Shimada-Shi' },
  { label: 'Shimanovsk', value: 'Shimanovsk' },
  { label: 'Shimanovskiy Rayon', value: 'Shimanovskiy Rayon' },
  { label: 'Shimanto', value: 'Shimanto' },
  { label: 'Shima-Shi', value: 'Shima-Shi' },
  { label: 'Shimianzhuang', value: 'Shimianzhuang' },
  { label: 'Shimla', value: 'Shimla' },
  { label: 'Shimoda', value: 'Shimoda' },
  { label: 'Shimoda-Shi', value: 'Shimoda-Shi' },
  { label: 'Shimodate', value: 'Shimodate' },
  { label: 'Shimo-Furano', value: 'Shimo-Furano' },
  { label: 'Shimoga', value: 'Shimoga' },
  { label: 'Shimojō', value: 'Shimojō' },
  { label: 'Shimokizukuri', value: 'Shimokizukuri' },
  { label: 'Shimoni', value: 'Shimoni' },
  { label: 'Shimonoseki Shi', value: 'Shimonoseki Shi' },
  { label: 'Shimorskoye', value: 'Shimorskoye' },
  { label: 'Shimosuwa', value: 'Shimosuwa' },
  { label: 'Shimotoda', value: 'Shimotoda' },
  { label: 'Shimotsuke-Shi', value: 'Shimotsuke-Shi' },
  { label: 'Shimotsuma-Shi', value: 'Shimotsuma-Shi' },
  { label: 'Shimsk', value: 'Shimsk' },
  { label: 'Shimskiy Rayon', value: 'Shimskiy Rayon' },
  { label: 'Shin’Ichi', value: 'Shin’Ichi' },
  { label: 'Shinagawa-Ku', value: 'Shinagawa-Ku' },
  { label: 'Shinano', value: 'Shinano' },
  { label: 'Shināş', value: 'Shināş' },
  { label: 'Shīnḏanḏ', value: 'Shīnḏanḏ' },
  { label: 'Shiner', value: 'Shiner' },
  { label: 'Shingle Springs', value: 'Shingle Springs' },
  { label: 'Shinglehouse', value: 'Shinglehouse' },
  { label: 'Shingletown', value: 'Shingletown' },
  { label: 'Shingli Bala', value: 'Shingli Bala' },
  { label: 'Shingū', value: 'Shingū' },
  { label: 'Shingū', value: 'Shingū' },
  { label: 'Shingū-Shi', value: 'Shingū-Shi' },
  { label: 'Shinile Zone', value: 'Shinile Zone' },
  { label: 'Shinjō', value: 'Shinjō' },
  { label: 'Shinjō Shi', value: 'Shinjō Shi' },
  { label: 'Shinjuku-Ku', value: 'Shinjuku-Ku' },
  { label: 'Shinnecock Hills', value: 'Shinnecock Hills' },
  { label: 'Shinnston', value: 'Shinnston' },
  { label: 'Shinpokh', value: 'Shinpokh' },
  { label: 'Shinshiro', value: 'Shinshiro' },
  { label: 'Shinshiro-Shi', value: 'Shinshiro-Shi' },
  { label: 'Shinuhayr', value: 'Shinuhayr' },
  { label: 'Shinyanga', value: 'Shinyanga' },
  { label: 'Shiogama', value: 'Shiogama' },
  { label: 'Shiojiri', value: 'Shiojiri' },
  { label: 'Shiojiri', value: 'Shiojiri' },
  { label: 'Shiojiri-Shi', value: 'Shiojiri-Shi' },
  { label: 'Shiozawa', value: 'Shiozawa' },
  { label: 'Ship Bottom', value: 'Ship Bottom' },
  { label: 'Shipdham', value: 'Shipdham' },
  { label: 'Shipham', value: 'Shipham' },
  { label: 'Shipitsyno', value: 'Shipitsyno' },
  { label: 'Shipka', value: 'Shipka' },
  { label: 'Shipley', value: 'Shipley' },
  { label: 'Shippagan', value: 'Shippagan' },
  { label: 'Shippensburg', value: 'Shippensburg' },
  { label: 'Shiprock', value: 'Shiprock' },
  { label: 'Shipston On Stour', value: 'Shipston On Stour' },
  { label: 'Shipton Under Wychwood', value: 'Shipton Under Wychwood' },
  { label: 'Shipunovo', value: 'Shipunovo' },
  { label: 'Shipunovskiy Rayon', value: 'Shipunovskiy Rayon' },
  { label: 'Shipyard', value: 'Shipyard' },
  { label: 'Shiqi', value: 'Shiqi' },
  { label: 'Shiqian', value: 'Shiqian' },
  { label: 'Shiqiao', value: 'Shiqiao' },
  { label: 'Shir Barat', value: 'Shir Barat' },
  { label: 'Shira', value: 'Shira' },
  { label: 'Shira`Ayn', value: 'Shira`Ayn' },
  { label: 'Shiraguppi', value: 'Shiraguppi' },
  { label: 'Shirahamachō-Usazakiminami', value: 'Shirahamachō-Usazakiminami' },
  { label: 'Shirak', value: 'Shirak' },
  { label: 'Shirakamut', value: 'Shirakamut' },
  { label: 'Shirakawa Shi', value: 'Shirakawa Shi' },
  { label: 'Shirala', value: 'Shirala' },
  { label: 'Shiraoi', value: 'Shiraoi' },
  { label: 'Shiraoka', value: 'Shiraoka' },
  { label: 'Shiraoka-Shi', value: 'Shiraoka-Shi' },
  { label: 'Shiraz', value: 'Shiraz' },
  { label: 'Shirbīn', value: 'Shirbīn' },
  { label: 'Shirdi', value: 'Shirdi' },
  { label: 'Shirebrook', value: 'Shirebrook' },
  { label: 'Shiremanstown', value: 'Shiremanstown' },
  { label: 'Shiremoor', value: 'Shiremoor' },
  { label: 'Shirgah', value: 'Shirgah' },
  { label: 'Shirgaon', value: 'Shirgaon' },
  { label: 'Shirhatti', value: 'Shirhatti' },
  { label: 'Shirin Su', value: 'Shirin Su' },
  { label: 'Shiringushi', value: 'Shiringushi' },
  { label: 'Shirland', value: 'Shirland' },
  { label: 'Shirley', value: 'Shirley' },
  { label: 'Shirley', value: 'Shirley' },
  { label: 'Shirley', value: 'Shirley' },
  { label: 'Shirley Castle', value: 'Shirley Castle' },
  { label: 'Shirochanka', value: 'Shirochanka' },
  { label: 'Shiroi', value: 'Shiroi' },
  { label: 'Shiroishi', value: 'Shiroishi' },
  { label: 'Shiroi-Shi', value: 'Shiroi-Shi' },
  { label: 'Shiroishi Shi', value: 'Shiroishi Shi' },
  { label: 'Shirokaya Rechka', value: 'Shirokaya Rechka' },
  { label: 'Shirokiy', value: 'Shirokiy' },
  { label: 'Shirokov', value: 'Shirokov' },
  { label: 'Shirokovskiy', value: 'Shirokovskiy' },
  { label: 'Shirokoye', value: 'Shirokoye' },
  { label: 'Shirol', value: 'Shirol' },
  { label: 'Shirone', value: 'Shirone' },
  { label: 'Shirpur', value: 'Shirpur' },
  { label: 'Shirud', value: 'Shirud' },
  { label: 'Shirud', value: 'Shirud' },
  { label: 'Shirvan', value: 'Shirvan' },
  { label: 'Shirwal', value: 'Shirwal' },
  { label: 'Shishgarh', value: 'Shishgarh' },
  { label: 'Shishkin Les', value: 'Shishkin Les' },
  { label: 'Shisō-Shi', value: 'Shisō-Shi' },
  { label: 'Shisui', value: 'Shisui' },
  { label: 'Shitanjing', value: 'Shitanjing' },
  { label: 'Shitkino', value: 'Shitkino' },
  { label: 'Shivaji Nagar', value: 'Shivaji Nagar' },
  { label: 'Shively', value: 'Shively' },
  { label: 'Shivpuri', value: 'Shivpuri' },
  { label: 'Shivrajpur', value: 'Shivrajpur' },
  { label: 'Shivrajpur', value: 'Shivrajpur' },
  { label: 'Shiwan', value: 'Shiwan' },
  { label: 'Shixing', value: 'Shixing' },
  { label: 'Shiyan', value: 'Shiyan' },
  { label: 'Shizilu', value: 'Shizilu' },
  { label: 'Shizuishan', value: 'Shizuishan' },
  { label: 'Shizukuishi', value: 'Shizukuishi' },
  { label: 'Shizunai-Furukawachō', value: 'Shizunai-Furukawachō' },
  { label: 'Shizuoka', value: 'Shizuoka' },
  { label: 'Shizuoka-Shi', value: 'Shizuoka-Shi' },
  { label: 'Shklo', value: 'Shklo' },
  { label: 'Shklow', value: 'Shklow' },
  { label: 'Shklowski Rayon', value: 'Shklowski Rayon' },
  { label: 'Shkodër', value: 'Shkodër' },
  { label: 'Shkol’Noye', value: 'Shkol’Noye' },
  { label: 'Shkotovo', value: 'Shkotovo' },
  { label: 'Shkotovskiy Rayon', value: 'Shkotovskiy Rayon' },
  { label: 'Shkurinskaya', value: 'Shkurinskaya' },
  { label: 'Shlissel’Burg', value: 'Shlissel’Burg' },
  { label: 'Shnogh', value: 'Shnogh' },
  { label: 'Sho‘Rchi', value: 'Sho‘Rchi' },
  { label: 'Shoal Bay', value: 'Shoal Bay' },
  { label: 'Shoal Creek', value: 'Shoal Creek' },
  { label: 'Shoalhaven Heads', value: 'Shoalhaven Heads' },
  { label: 'Shoalhaven Shire', value: 'Shoalhaven Shire' },
  { label: 'Shoalin Grotto', value: 'Shoalin Grotto' },
  { label: 'Shoals', value: 'Shoals' },
  { label: 'Shoalwater', value: 'Shoalwater' },
  { label: 'Shōbara', value: 'Shōbara' },
  { label: 'Shōbara-Shi', value: 'Shōbara-Shi' },
  { label: 'Shōbu', value: 'Shōbu' },
  { label: 'Shoctic', value: 'Shoctic' },
  { label: 'Shoemakersville', value: 'Shoemakersville' },
  { label: 'Shofirkon', value: 'Shofirkon' },
  { label: 'Shoham', value: 'Shoham' },
  { label: 'Shohimardon', value: 'Shohimardon' },
  { label: 'Shokan', value: 'Shokan' },
  { label: 'Shokhaibٍ', value: 'Shokhaibٍ' },
  { label: 'Sholinghur', value: 'Sholinghur' },
  { label: 'Sholokhove', value: 'Sholokhove' },
  { label: 'Sholokhovskiy', value: 'Sholokhovskiy' },
  { label: 'Shomolu', value: 'Shomolu' },
  { label: 'Shonbeh', value: 'Shonbeh' },
  { label: 'Shonguy', value: 'Shonguy' },
  { label: 'Shool Abad', value: 'Shool Abad' },
  { label: 'Shooshtar', value: 'Shooshtar' },
  { label: 'Shoqan', value: 'Shoqan' },
  { label: 'Shorapani', value: 'Shorapani' },
  { label: 'Shorapur', value: 'Shorapur' },
  { label: 'Shoreacres', value: 'Shoreacres' },
  { label: 'Shoreham-By-Sea', value: 'Shoreham-By-Sea' },
  { label: 'Shoreline', value: 'Shoreline' },
  { label: 'Shoreview', value: 'Shoreview' },
  { label: 'Shorewell Park', value: 'Shorewell Park' },
  { label: 'Shorewood', value: 'Shorewood' },
  { label: 'Shorewood', value: 'Shorewood' },
  { label: 'Shorewood', value: 'Shorewood' },
  { label: 'Shorewood Forest', value: 'Shorewood Forest' },
  { label: 'Shorewood Hills', value: 'Shorewood Hills' },
  { label: 'Shorewood-Tower Hills-Harbert', value: 'Shorewood-Tower Hills-Harbert' },
  { label: 'Shorjah', value: 'Shorjah' },
  { label: 'Shorkot', value: 'Shorkot' },
  { label: 'Shorkot', value: 'Shorkot' },
  { label: 'Shorncliffe', value: 'Shorncliffe' },
  { label: 'Short Hills', value: 'Short Hills' },
  { label: 'Short Pump', value: 'Short Pump' },
  { label: 'Shortandy', value: 'Shortandy' },
  { label: 'Shortland', value: 'Shortland' },
  { label: 'Shortlands', value: 'Shortlands' },
  { label: 'Shortsville', value: 'Shortsville' },
  { label: 'Shoshone', value: 'Shoshone' },
  { label: 'Shoshone County', value: 'Shoshone County' },
  { label: 'Shostka', value: 'Shostka' },
  { label: 'Shotley Gate', value: 'Shotley Gate' },
  { label: 'Shotts', value: 'Shotts' },
  { label: 'Shouguang', value: 'Shouguang' },
  { label: 'Shoushan', value: 'Shoushan' },
  { label: 'Shovgenovskiy', value: 'Shovgenovskiy' },
  { label: 'Shovgenovskiy Rayon', value: 'Shovgenovskiy Rayon' },
  { label: 'Show Low', value: 'Show Low' },
  { label: 'Showot', value: 'Showot' },
  { label: 'Showt', value: 'Showt' },
  { label: 'Shpola', value: 'Shpola' },
  { label: 'Shrawasti', value: 'Shrawasti' },
  { label: 'Shreve', value: 'Shreve' },
  { label: 'Shreveport', value: 'Shreveport' },
  { label: 'Shrewsbury', value: 'Shrewsbury' },
  { label: 'Shrewsbury', value: 'Shrewsbury' },
  { label: 'Shrewsbury', value: 'Shrewsbury' },
  { label: 'Shrewsbury', value: 'Shrewsbury' },
  { label: 'Shrewsbury', value: 'Shrewsbury' },
  { label: 'Shrewton', value: 'Shrewton' },
  { label: 'Shrigonda', value: 'Shrigonda' },
  { label: 'Shrirangapattana', value: 'Shrirangapattana' },
  { label: 'Shrivenham', value: 'Shrivenham' },
  { label: 'Shropshire', value: 'Shropshire' },
  { label: 'Shrub Oak', value: 'Shrub Oak' },
  { label: 'Shtip', value: 'Shtip' },
  { label: 'Shtormovoye', value: 'Shtormovoye' },
  { label: 'Shturmovoye', value: 'Shturmovoye' },
  { label: 'Shtykovo', value: 'Shtykovo' },
  { label: 'Shuakhevi', value: 'Shuakhevi' },
  { label: 'Shuangcheng', value: 'Shuangcheng' },
  { label: 'Shuanghejiedao', value: 'Shuanghejiedao' },
  { label: 'Shuangjiang', value: 'Shuangjiang' },
  { label: 'Shuangjiang', value: 'Shuangjiang' },
  { label: 'Shuangxi', value: 'Shuangxi' },
  { label: 'Shuangxi', value: 'Shuangxi' },
  { label: 'Shuangyang', value: 'Shuangyang' },
  { label: 'Shuangyashan', value: 'Shuangyashan' },
  { label: 'Shuangzhu', value: 'Shuangzhu' },
  { label: 'Shubarköl', value: 'Shubarköl' },
  { label: 'Shubarkuduk', value: 'Shubarkuduk' },
  { label: 'Shubarshi', value: 'Shubarshi' },
  { label: 'Shubenka', value: 'Shubenka' },
  { label: 'Shubra', value: 'Shubra' },
  { label: 'Shudayag', value: 'Shudayag' },
  { label: 'Shugurovo', value: 'Shugurovo' },
  { label: 'Shuiche', value: 'Shuiche' },
  { label: 'Shuidatian', value: 'Shuidatian' },
  { label: 'Shuikou', value: 'Shuikou' },
  { label: 'Shujaabad', value: 'Shujaabad' },
  { label: 'Shujalpur', value: 'Shujalpur' },
  { label: 'Shul’Gin Log', value: 'Shul’Gin Log' },
  { label: 'Shulan', value: 'Shulan' },
  { label: 'Shullsburg', value: 'Shullsburg' },
  { label: 'Shumen', value: 'Shumen' },
  { label: 'Shumerlinskiy Rayon', value: 'Shumerlinskiy Rayon' },
  { label: 'Shumerlya', value: 'Shumerlya' },
  { label: 'Shumeyka', value: 'Shumeyka' },
  { label: 'Shumikha', value: 'Shumikha' },
  { label: 'Shumikhinskiy', value: 'Shumikhinskiy' },
  { label: 'Shumikhinskiy Rayon', value: 'Shumikhinskiy Rayon' },
  { label: 'Shumilinski Rayon', value: 'Shumilinski Rayon' },
  { label: 'Shumsk', value: 'Shumsk' },
  { label: 'Shumskiy', value: 'Shumskiy' },
  { label: 'Shumyachi', value: 'Shumyachi' },
  { label: 'Shun’Ga', value: 'Shun’Ga' },
  { label: 'Shūnan Shi', value: 'Shūnan Shi' },
  { label: 'Shunyi', value: 'Shunyi' },
  { label: 'Shuozhou', value: 'Shuozhou' },
  { label: 'Shupiyan', value: 'Shupiyan' },
  { label: 'Shurdington', value: 'Shurdington' },
  { label: 'Shŭrob', value: 'Shŭrob' },
  { label: 'Shurugwi', value: 'Shurugwi' },
  { label: 'Shurugwi District', value: 'Shurugwi District' },
  { label: 'Shuryshkarskiy Rayon', value: 'Shuryshkarskiy Rayon' },
  { label: 'Shusf', value: 'Shusf' },
  { label: 'Shush', value: 'Shush' },
  { label: 'Shush Ab', value: 'Shush Ab' },
  { label: 'Shushary', value: 'Shushary' },
  { label: 'Shushenskiy Rayon', value: 'Shushenskiy Rayon' },
  { label: 'Shushenskoye', value: 'Shushenskoye' },
  { label: 'Shushi', value: 'Shushi' },
  { label: 'Shushtar', value: 'Shushtar' },
  { label: 'Shutesbury', value: 'Shutesbury' },
  { label: 'Shuvakish', value: 'Shuvakish' },
  { label: 'Shuvalovo', value: 'Shuvalovo' },
  { label: 'Shuya', value: 'Shuya' },
  { label: 'Shuya', value: 'Shuya' },
  { label: 'Shuyesheh', value: 'Shuyesheh' },
  { label: 'Shuyskiy Rayon', value: 'Shuyskiy Rayon' },
  { label: 'Shuyskoye', value: 'Shuyskoye' },
  { label: 'Shvartsevskiy', value: 'Shvartsevskiy' },
  { label: 'Shwebo', value: 'Shwebo' },
  { label: 'Shyamnagar West Bengal', value: 'Shyamnagar West Bengal' },
  { label: 'Shyghanaq', value: 'Shyghanaq' },
  { label: 'Shypyntsi', value: 'Shypyntsi' },
  { label: 'Shyroke', value: 'Shyroke' },
  { label: 'Shyroke', value: 'Shyroke' },
  { label: 'Shyroke Raion', value: 'Shyroke Raion' },
  { label: 'Shyryayeve', value: 'Shyryayeve' },
  { label: 'Si Bun Rueang', value: 'Si Bun Rueang' },
  { label: 'Si Chiang Mai', value: 'Si Chiang Mai' },
  { label: 'Si Kunda', value: 'Si Kunda' },
  { label: 'Si Racha', value: 'Si Racha' },
  { label: 'Si Sa Ket', value: 'Si Sa Ket' },
  { label: 'Si Satchanalai', value: 'Si Satchanalai' },
  { label: 'Siachoque', value: 'Siachoque' },
  { label: 'Siah Cheshmeh', value: 'Siah Cheshmeh' },
  { label: 'Siah Goleh', value: 'Siah Goleh' },
  { label: 'Siahkal', value: 'Siahkal' },
  { label: 'Siahmansur', value: 'Siahmansur' },
  { label: 'Siahrood', value: 'Siahrood' },
  { label: 'Sialkot', value: 'Sialkot' },
  { label: 'Siamaggiore', value: 'Siamaggiore' },
  { label: 'Siamanna', value: 'Siamanna' },
  { label: 'Siano', value: 'Siano' },
  { label: 'Siano', value: 'Siano' },
  { label: 'Sianów', value: 'Sianów' },
  { label: 'Siapiccia', value: 'Siapiccia' },
  { label: 'Siari', value: 'Siari' },
  { label: 'Siasi', value: 'Siasi' },
  { label: 'Siasi', value: 'Siasi' },
  { label: 'Siátista', value: 'Siátista' },
  { label: 'Siaton', value: 'Siaton' },
  { label: 'Šiauliai', value: 'Šiauliai' },
  { label: 'Siavonga', value: 'Siavonga' },
  { label: 'Siavonga District', value: 'Siavonga District' },
  { label: 'Siay', value: 'Siay' },
  { label: 'Siaya', value: 'Siaya' },
  { label: 'Siayan', value: 'Siayan' },
  { label: 'Sib Dar', value: 'Sib Dar' },
  { label: 'Sib Va Suran', value: 'Sib Va Suran' },
  { label: 'Sibacá', value: 'Sibacá' },
  { label: 'Sibagat', value: 'Sibagat' },
  { label: 'Sibagat', value: 'Sibagat' },
  { label: 'Sibaguan', value: 'Sibaguan' },
  { label: 'Sibah', value: 'Sibah' },
  { label: 'Sibalom', value: 'Sibalom' },
  { label: 'Sibanicú', value: 'Sibanicú' },
  { label: 'Sibaniljá Pocolum', value: 'Sibaniljá Pocolum' },
  { label: 'Sibari', value: 'Sibari' },
  { label: 'Sibaté', value: 'Sibaté' },
  { label: 'Sibay', value: 'Sibay' },
  { label: 'Sibbe', value: 'Sibbe' },
  { label: 'Sibbesse', value: 'Sibbesse' },
  { label: 'Sibbhult', value: 'Sibbhult' },
  { label: 'Sibbo', value: 'Sibbo' },
  { label: 'Šibenik', value: 'Šibenik' },
  { label: 'Sibi', value: 'Sibi' },
  { label: 'Sibilia', value: 'Sibilia' },
  { label: 'Sibinal', value: 'Sibinal' },
  { label: 'Sibinj', value: 'Sibinj' },
  { label: 'Sibiril', value: 'Sibiril' },
  { label: 'Sibirskiy', value: 'Sibirskiy' },
  { label: 'Sibiti', value: 'Sibiti' },
  { label: 'Sibiu', value: 'Sibiu' },
  { label: 'Sible Hedingham', value: 'Sible Hedingham' },
  { label: 'Sibley', value: 'Sibley' },
  { label: 'Sibley', value: 'Sibley' },
  { label: 'Sibley County', value: 'Sibley County' },
  { label: 'Sibolga', value: 'Sibolga' },
  { label: 'Sibonga', value: 'Sibonga' },
  { label: 'Şibot', value: 'Şibot' },
  { label: 'Sibratsgfäll', value: 'Sibratsgfäll' },
  { label: 'Sibsagar', value: 'Sibsagar' },
  { label: 'Sibsey', value: 'Sibsey' },
  { label: 'Sibsu', value: 'Sibsu' },
  { label: 'Sibu', value: 'Sibu' },
  { label: 'Sibucao', value: 'Sibucao' },
  { label: 'Sibuco', value: 'Sibuco' },
  { label: 'Sibul', value: 'Sibul' },
  { label: 'Sibul', value: 'Sibul' },
  { label: 'Sibulan', value: 'Sibulan' },
  { label: 'Sibulan', value: 'Sibulan' },
  { label: 'Sibulan', value: 'Sibulan' },
  { label: 'Sibulan', value: 'Sibulan' },
  { label: 'Sibulan', value: 'Sibulan' },
  { label: 'Sibulao', value: 'Sibulao' },
  { label: 'Sibundoy', value: 'Sibundoy' },
  { label: 'Sibut', value: 'Sibut' },
  { label: 'Sibutao', value: 'Sibutao' },
  { label: 'Sic', value: 'Sic' },
  { label: 'Sicamous', value: 'Sicamous' },
  { label: 'Sicaya', value: 'Sicaya' },
  { label: 'Sicele', value: 'Sicele' },
  { label: 'Sicheviţa', value: 'Sicheviţa' },
  { label: 'Sicienko', value: 'Sicienko' },
  { label: 'Sicignano Degli Alburni', value: 'Sicignano Degli Alburni' },
  { label: 'Sickenhausen', value: 'Sickenhausen' },
  { label: 'Sicklerville', value: 'Sicklerville' },
  { label: 'Sickte', value: 'Sickte' },
  { label: 'Șiclău', value: 'Șiclău' },
  { label: 'Siclong', value: 'Siclong' },
  { label: 'Siclong', value: 'Siclong' },
  { label: 'Sico Uno', value: 'Sico Uno' },
  { label: 'Sicuani', value: 'Sicuani' },
  { label: 'Şicula', value: 'Şicula' },
  { label: 'Siculeni', value: 'Siculeni' },
  { label: 'Siculiana', value: 'Siculiana' },
  { label: 'Sidama Zone', value: 'Sidama Zone' },
  { label: 'Sidamon', value: 'Sidamon' },
  { label: 'Sidareja', value: 'Sidareja' },
  { label: 'Sidcup', value: 'Sidcup' },
  { label: 'Siddapur', value: 'Siddapur' },
  { label: 'Siddeburen', value: 'Siddeburen' },
  { label: 'Siddharthnagar', value: 'Siddharthnagar' },
  { label: 'Siddhaur', value: 'Siddhaur' },
  { label: 'Siddhpur', value: 'Siddhpur' },
  { label: 'Siddi', value: 'Siddi' },
  { label: 'Siddipet', value: 'Siddipet' },
  { label: 'Siderno', value: 'Siderno' },
  { label: 'Siderópolis', value: 'Siderópolis' },
  { label: 'Sidhauli', value: 'Sidhauli' },
  { label: 'Sidhi', value: 'Sidhi' },
  { label: 'Sidhpura', value: 'Sidhpura' },
  { label: 'Sidi Abdelli', value: 'Sidi Abdelli' },
  { label: 'Sidi Aïssa', value: 'Sidi Aïssa' },
  { label: 'Sidi Akkacha', value: 'Sidi Akkacha' },
  { label: 'Sidi Ali Boussidi', value: 'Sidi Ali Boussidi' },
  { label: 'Sidi Alouane', value: 'Sidi Alouane' },
  { label: 'Sidi Amrane', value: 'Sidi Amrane' },
  { label: 'Sidi Amrane', value: 'Sidi Amrane' },
  { label: 'Sidi Bel Abbès', value: 'Sidi Bel Abbès' },
  { label: 'Sidi Ben Nour', value: 'Sidi Ben Nour' },
  { label: 'Sidi Bennour', value: 'Sidi Bennour' },
  { label: 'Sidi Bishr', value: 'Sidi Bishr' },
  { label: 'Sidi Bou Ali', value: 'Sidi Bou Ali' },
  { label: 'Sidi Bou Saïd', value: 'Sidi Bou Saïd' },
  { label: 'Sidi Bousber', value: 'Sidi Bousber' },
  { label: 'Sidi Bouzid', value: 'Sidi Bouzid' },
  { label: 'Sidi Brahim', value: 'Sidi Brahim' },
  { label: 'Sidi Ech Chahmi', value: 'Sidi Ech Chahmi' },
  { label: 'Sidi El Hani', value: 'Sidi El Hani' },
  { label: 'Sidi Hamadouche', value: 'Sidi Hamadouche' },
  { label: 'Sidi Hamzah', value: 'Sidi Hamzah' },
  { label: 'Sidi Ifni', value: 'Sidi Ifni' },
  { label: 'Sidi Ifni', value: 'Sidi Ifni' },
  { label: 'Sidi Jaber', value: 'Sidi Jaber' },
  { label: 'Sidi Khaled', value: 'Sidi Khaled' },
  { label: 'Sidi Mérouane', value: 'Sidi Mérouane' },
  { label: 'Sidi Moussa', value: 'Sidi Moussa' },
  { label: 'Sidi Okba', value: 'Sidi Okba' },
  { label: 'Sidi Qacem', value: 'Sidi Qacem' },
  { label: 'Sidi Rahhal', value: 'Sidi Rahhal' },
  { label: 'Sidi Redouane', value: 'Sidi Redouane' },
  { label: 'Sīdī Sālim', value: 'Sīdī Sālim' },
  { label: 'Sidi Senoussi سيدي سنوسي', value: 'Sidi Senoussi سيدي سنوسي' },
  { label: 'Sidi Slimane', value: 'Sidi Slimane' },
  { label: 'Sidi Slimane', value: 'Sidi Slimane' },
  { label: 'Sidi Smai’Il', value: 'Sidi Smai’Il' },
  { label: 'Sidi Thabet', value: 'Sidi Thabet' },
  { label: 'Sidi Yacoub', value: 'Sidi Yacoub' },
  { label: 'Sidi Yahia', value: 'Sidi Yahia' },
  { label: 'Sidi Yahia El Gharb', value: 'Sidi Yahia El Gharb' },
  { label: 'Sidi-Kacem', value: 'Sidi-Kacem' },
  { label: 'Sidirókastro', value: 'Sidirókastro' },
  { label: 'Sidlaghatta', value: 'Sidlaghatta' },
  { label: 'Sidmouth', value: 'Sidmouth' },
  { label: 'Sidney', value: 'Sidney' },
  { label: 'Sidney', value: 'Sidney' },
  { label: 'Sidney', value: 'Sidney' },
  { label: 'Sidney', value: 'Sidney' },
  { label: 'Sidney', value: 'Sidney' },
  { label: 'Sidney', value: 'Sidney' },
  { label: 'Sidney', value: 'Sidney' },
  { label: 'Sidoarjo', value: 'Sidoarjo' },
  { label: 'Sidon', value: 'Sidon' },
  { label: 'Sidqābād', value: 'Sidqābād' },
  { label: 'Sidrolândia', value: 'Sidrolândia' },
  { label: 'Sidvokodvo', value: 'Sidvokodvo' },
  { label: 'Sidzina', value: 'Sidzina' },
  { label: 'Sidzina', value: 'Sidzina' },
  { label: 'Siebeldingen', value: 'Siebeldingen' },
  { label: 'Siebnen', value: 'Siebnen' },
  { label: 'Siechnice', value: 'Siechnice' },
  { label: 'Sieci', value: 'Sieci' },
  { label: 'Siedenburg', value: 'Siedenburg' },
  { label: 'Siedlce', value: 'Siedlce' },
  { label: 'Siedlec', value: 'Siedlec' },
  { label: 'Siedleczka', value: 'Siedleczka' },
  { label: 'Siedliska', value: 'Siedliska' },
  { label: 'Siedliska', value: 'Siedliska' },
  { label: 'Siedlisko', value: 'Siedlisko' },
  { label: 'Siefersheim', value: 'Siefersheim' },
  { label: 'Siegburg', value: 'Siegburg' },
  { label: 'Siegelsbach', value: 'Siegelsbach' },
  { label: 'Siegen', value: 'Siegen' },
  { label: 'Siegenburg', value: 'Siegenburg' },
  { label: 'Siegendorf Im Burgenland', value: 'Siegendorf Im Burgenland' },
  { label: 'Siegersdorf Bei Herberstein', value: 'Siegersdorf Bei Herberstein' },
  { label: 'Sieggraben', value: 'Sieggraben' },
  { label: 'Sieghartskirchen', value: 'Sieghartskirchen' },
  { label: 'Siegsdorf', value: 'Siegsdorf' },
  { label: 'Siek', value: 'Siek' },
  { label: 'Siekierczyn', value: 'Siekierczyn' },
  { label: 'Sielenbach', value: 'Sielenbach' },
  { label: 'Siem Reap', value: 'Siem Reap' },
  { label: 'Siemensstadt', value: 'Siemensstadt' },
  { label: 'Siemianowice Śląskie', value: 'Siemianowice Śląskie' },
  { label: 'Siemiątkowo', value: 'Siemiątkowo' },
  { label: 'Siemiatycze', value: 'Siemiatycze' },
  { label: 'Siemiechów', value: 'Siemiechów' },
  { label: 'Siemień', value: 'Siemień' },
  { label: 'Siemkowice', value: 'Siemkowice' },
  { label: 'Siempre Viva', value: 'Siempre Viva' },
  { label: 'Siempre Viva', value: 'Siempre Viva' },
  { label: 'Siena', value: 'Siena' },
  { label: 'Sienes', value: 'Sienes' },
  { label: 'Sieniawa', value: 'Sieniawa' },
  { label: 'Sieniawa', value: 'Sieniawa' },
  { label: 'Sieniawa', value: 'Sieniawa' },
  { label: 'Sieniawa Żarska', value: 'Sieniawa Żarska' },
  { label: 'Sienna Plantation', value: 'Sienna Plantation' },
  { label: 'Siennica', value: 'Siennica' },
  { label: 'Siennica Różana', value: 'Siennica Różana' },
  { label: 'Sienno', value: 'Sienno' },
  { label: 'Siepraw', value: 'Siepraw' },
  { label: 'Sieradz', value: 'Sieradz' },
  { label: 'Sieradza', value: 'Sieradza' },
  { label: 'Sieraków', value: 'Sieraków' },
  { label: 'Sieraków Śląski', value: 'Sieraków Śląski' },
  { label: 'Sierakowice', value: 'Sierakowice' },
  { label: 'Sierck-Les-Bains', value: 'Sierck-Les-Bains' },
  { label: 'Sierentz', value: 'Sierentz' },
  { label: 'Sierksdorf', value: 'Sierksdorf' },
  { label: 'Sierndorf', value: 'Sierndorf' },
  { label: 'Sierning', value: 'Sierning' },
  { label: 'Siero', value: 'Siero' },
  { label: 'Sieroszewice', value: 'Sieroszewice' },
  { label: 'Sierpc', value: 'Sierpc' },
  { label: 'Sierpe La', value: 'Sierpe La' },
  { label: 'Sierra Blanca', value: 'Sierra Blanca' },
  { label: 'Sierra Bullones', value: 'Sierra Bullones' },
  { label: 'Sierra Colorada', value: 'Sierra Colorada' },
  { label: 'Sierra County', value: 'Sierra County' },
  { label: 'Sierra County', value: 'Sierra County' },
  { label: 'Sierra De Agua', value: 'Sierra De Agua' },
  { label: 'Sierra De Fuentes', value: 'Sierra De Fuentes' },
  { label: 'Sierra De Guadalupe', value: 'Sierra De Guadalupe' },
  { label: 'Sierra De Luna', value: 'Sierra De Luna' },
  { label: 'Sierra De Yeguas', value: 'Sierra De Yeguas' },
  { label: 'Sierra Engarcerán', value: 'Sierra Engarcerán' },
  { label: 'Sierra Gorda', value: 'Sierra Gorda' },
  { label: 'Sierra Grande', value: 'Sierra Grande' },
  { label: 'Sierra Madre', value: 'Sierra Madre' },
  { label: 'Sierra Mojada', value: 'Sierra Mojada' },
  { label: 'Sierra Papacal', value: 'Sierra Papacal' },
  { label: 'Sierra View', value: 'Sierra View' },
  { label: 'Sierra Vista', value: 'Sierra Vista' },
  { label: 'Sierra Vista Southeast', value: 'Sierra Vista Southeast' },
  { label: 'Sierra-Engarcerán', value: 'Sierra-Engarcerán' },
  { label: 'Sierre', value: 'Sierre' },
  { label: 'Sierre District', value: 'Sierre District' },
  { label: 'Sierro', value: 'Sierro' },
  { label: 'Siersdorf', value: 'Siersdorf' },
  { label: 'Siershahn', value: 'Siershahn' },
  { label: 'Siersleben', value: 'Siersleben' },
  { label: 'Siesta Acres', value: 'Siesta Acres' },
  { label: 'Siesta Key', value: 'Siesta Key' },
  { label: 'Siesta Shores', value: 'Siesta Shores' },
  { label: 'Siétamo', value: 'Siétamo' },
  { label: 'Siete Aguas', value: 'Siete Aguas' },
  { label: 'Siete Iglesias De Trabancos', value: 'Siete Iglesias De Trabancos' },
  { label: 'Sieteiglesias De Tormes', value: 'Sieteiglesias De Tormes' },
  { label: 'Sietesz', value: 'Sietesz' },
  { label: 'Şieu', value: 'Şieu' },
  { label: 'Șieu', value: 'Șieu' },
  { label: 'Şieu-Măgheruş', value: 'Şieu-Măgheruş' },
  { label: 'Şieu-Odorhei', value: 'Şieu-Odorhei' },
  { label: 'Şieuţ', value: 'Şieuţ' },
  { label: 'Sievershütten', value: 'Sievershütten' },
  { label: 'Sieverstedt', value: 'Sieverstedt' },
  { label: 'Sievi', value: 'Sievi' },
  { label: 'Siewierz', value: 'Siewierz' },
  { label: 'Siezenheim', value: 'Siezenheim' },
  { label: 'Sig', value: 'Sig' },
  { label: 'Sigaboy', value: 'Sigaboy' },
  { label: 'Sigaboy', value: 'Sigaboy' },
  { label: 'Sigaboy', value: 'Sigaboy' },
  { label: 'Sigaozhuang', value: 'Sigaozhuang' },
  { label: 'Sigay', value: 'Sigay' },
  { label: 'Sigay', value: 'Sigay' },
  { label: 'Sigayevo', value: 'Sigayevo' },
  { label: 'Sigdal', value: 'Sigdal' },
  { label: 'Sigean', value: 'Sigean' },
  { label: 'Sigefredo Pacheco', value: 'Sigefredo Pacheco' },
  { label: 'Sigeres', value: 'Sigeres' },
  { label: 'Sigetec', value: 'Sigetec' },
  { label: 'Siggelkow', value: 'Siggelkow' },
  { label: 'Siġġiewi', value: 'Siġġiewi' },
  { label: 'Sighetu Marmaţiei', value: 'Sighetu Marmaţiei' },
  { label: 'Sighișoara', value: 'Sighișoara' },
  { label: 'Sighnaghi', value: 'Sighnaghi' },
  { label: 'Sighnaghis Munitsip’Alit’Et’I', value: 'Sighnaghis Munitsip’Alit’Et’I' },
  { label: 'Sigillo', value: 'Sigillo' },
  { label: 'Sigiriya', value: 'Sigiriya' },
  { label: 'Sigless', value: 'Sigless' },
  { label: 'Sigli', value: 'Sigli' },
  { label: 'Siglufjörður', value: 'Siglufjörður' },
  { label: 'Sigma', value: 'Sigma' },
  { label: 'Sigmaringen', value: 'Sigmaringen' },
  { label: 'Sigmaringendorf', value: 'Sigmaringendorf' },
  { label: 'Sigmarszell', value: 'Sigmarszell' },
  { label: 'Sigmundsherberg', value: 'Sigmundsherberg' },
  { label: 'Signa', value: 'Signa' },
  { label: 'Signal Hill', value: 'Signal Hill' },
  { label: 'Signal Mountain', value: 'Signal Mountain' },
  { label: 'Signau', value: 'Signau' },
  { label: 'Signes', value: 'Signes' },
  { label: 'Signy-Le-Petit', value: 'Signy-Le-Petit' },
  { label: 'Sigolsheim', value: 'Sigolsheim' },
  { label: 'Sigourney', value: 'Sigourney' },
  { label: 'Sigriswil', value: 'Sigriswil' },
  { label: 'Sigtuna', value: 'Sigtuna' },
  { label: 'Siguatepeque', value: 'Siguatepeque' },
  { label: 'Sigüenza', value: 'Sigüenza' },
  { label: 'Sigüés', value: 'Sigüés' },
  { label: 'Siguinon', value: 'Siguinon' },
  { label: 'Siguinon', value: 'Siguinon' },
  { label: 'Siguiri', value: 'Siguiri' },
  { label: 'Siguiri Prefecture', value: 'Siguiri Prefecture' },
  { label: 'Sigulda', value: 'Sigulda' },
  { label: 'Sigwe Inkhundla', value: 'Sigwe Inkhundla' },
  { label: 'Sihanoukville', value: 'Sihanoukville' },
  { label: 'Siheung', value: 'Siheung' },
  { label: 'Sihlea', value: 'Sihlea' },
  { label: 'Sihochac', value: 'Sihochac' },
  { label: 'Sihor', value: 'Sihor' },
  { label: 'Sihora', value: 'Sihora' },
  { label: 'Sihuapan', value: 'Sihuapan' },
  { label: 'Siikainen', value: 'Siikainen' },
  { label: 'Siikajoki', value: 'Siikajoki' },
  { label: 'Siis Municipality', value: 'Siis Municipality' },
  { label: 'Sijbekarspel', value: 'Sijbekarspel' },
  { label: 'Sijua', value: 'Sijua' },
  { label: 'Sijunjung', value: 'Sijunjung' },
  { label: 'Sikandarabad', value: 'Sikandarabad' },
  { label: 'Sikandarpur', value: 'Sikandarpur' },
  { label: 'Sikandra', value: 'Sikandra' },
  { label: 'Sikandra Rao', value: 'Sikandra Rao' },
  { label: 'Sikandrabad', value: 'Sikandrabad' },
  { label: 'Sikar', value: 'Sikar' },
  { label: 'Sikasso', value: 'Sikasso' },
  { label: 'Sikatuna', value: 'Sikatuna' },
  { label: 'Sikeston', value: 'Sikeston' },
  { label: 'Sikirevci', value: 'Sikirevci' },
  { label: 'Sikka', value: 'Sikka' },
  { label: 'Siklós', value: 'Siklós' },
  { label: 'Siklósi Járás', value: 'Siklósi Járás' },
  { label: 'Sikonge', value: 'Sikonge' },
  { label: 'Sikyón', value: 'Sikyón' },
  { label: 'Silab', value: 'Silab' },
  { label: 'Silacayoápam', value: 'Silacayoápam' },
  { label: 'Silae', value: 'Silae' },
  { label: 'Silago', value: 'Silago' },
  { label: 'Silago', value: 'Silago' },
  { label: 'Šilainiai', value: 'Šilainiai' },
  { label: 'Šilalė', value: 'Šilalė' },
  { label: 'Silandro', value: 'Silandro' },
  { label: 'Silang', value: 'Silang' },
  { label: 'Silanga', value: 'Silanga' },
  { label: 'Silanga', value: 'Silanga' },
  { label: 'Silanus', value: 'Silanus' },
  { label: 'Silao', value: 'Silao' },
  { label: 'Silao', value: 'Silao' },
  { label: 'Silao De La Victoria', value: 'Silao De La Victoria' },
  { label: 'Silapathar', value: 'Silapathar' },
  { label: 'Silberstedt', value: 'Silberstedt' },
  { label: 'Silbertal', value: 'Silbertal' },
  { label: 'Silca', value: 'Silca' },
  { label: 'Silchar', value: 'Silchar' },
  { label: 'Şile', value: 'Şile' },
  { label: 'Silea', value: 'Silea' },
  { label: 'Șilea Nirajului', value: 'Șilea Nirajului' },
  { label: 'Sileby', value: 'Sileby' },
  { label: 'Silenen', value: 'Silenen' },
  { label: 'Silent Hill', value: 'Silent Hill' },
  { label: 'Siler City', value: 'Siler City' },
  { label: 'Siles', value: 'Siles' },
  { label: 'Silets’', value: 'Silets’' },
  { label: 'Siletz', value: 'Siletz' },
  { label: 'Šilheřovice', value: 'Šilheřovice' },
  { label: 'Silì', value: 'Silì' },
  { label: 'Siliana', value: 'Siliana' },
  { label: 'Silifke', value: 'Silifke' },
  { label: 'Siligo', value: 'Siligo' },
  { label: 'Siliguri', value: 'Siliguri' },
  { label: 'Silikatnyy', value: 'Silikatnyy' },
  { label: 'Şilindia', value: 'Şilindia' },
  { label: 'Șilindru', value: 'Șilindru' },
  { label: 'Siliqua', value: 'Siliqua' },
  { label: 'Siliştea', value: 'Siliştea' },
  { label: 'Siliştea', value: 'Siliştea' },
  { label: 'Siliştea', value: 'Siliştea' },
  { label: 'Siliștea', value: 'Siliștea' },
  { label: 'Siliştea Crucii', value: 'Siliştea Crucii' },
  { label: 'Siliștea Gumești', value: 'Siliștea Gumești' },
  { label: 'Siliștea Snagovului', value: 'Siliștea Snagovului' },
  { label: 'Silistra', value: 'Silistra' },
  { label: 'Silius', value: 'Silius' },
  { label: 'Silivașu De Câmpie', value: 'Silivașu De Câmpie' },
  { label: 'Silivri', value: 'Silivri' },
  { label: 'Siljan', value: 'Siljan' },
  { label: 'Siljansnäs', value: 'Siljansnäs' },
  { label: 'Silkeborg', value: 'Silkeborg' },
  { label: 'Silkeborg Kommune', value: 'Silkeborg Kommune' },
  { label: 'Silkstone', value: 'Silkstone' },
  { label: 'Silkstone', value: 'Silkstone' },
  { label: 'Silla', value: 'Silla' },
  { label: 'Sillamäe', value: 'Sillamäe' },
  { label: 'Sillano', value: 'Sillano' },
  { label: 'Sillans', value: 'Sillans' },
  { label: 'Sillanwali', value: 'Sillanwali' },
  { label: 'Sillavengo', value: 'Sillavengo' },
  { label: 'Sillawit', value: 'Sillawit' },
  { label: 'Sillawit', value: 'Sillawit' },
  { label: 'Silleda', value: 'Silleda' },
  { label: 'Sillé-Le-Guillaume', value: 'Sillé-Le-Guillaume' },
  { label: 'Sillenstede', value: 'Sillenstede' },
  { label: 'Sillery', value: 'Sillery' },
  { label: 'Sil-Li', value: 'Sil-Li' },
  { label: 'Sillian', value: 'Sillian' },
  { label: 'Sillingy', value: 'Sillingy' },
  { label: 'Sillod', value: 'Sillod' },
  { label: 'Sillon', value: 'Sillon' },
  { label: 'Silloth', value: 'Silloth' },
  { label: 'Silly', value: 'Silly' },
  { label: 'Silly-Le-Long', value: 'Silly-Le-Long' },
  { label: 'Siloah', value: 'Siloah' },
  { label: 'Siloam Springs', value: 'Siloam Springs' },
  { label: 'Silongin', value: 'Silongin' },
  { label: 'Silopi', value: 'Silopi' },
  { label: 'Silos', value: 'Silos' },
  { label: 'Silos Los', value: 'Silos Los' },
  { label: 'Sils', value: 'Sils' },
  { label: 'Silsbee', value: 'Silsbee' },
  { label: 'Silsden', value: 'Silsden' },
  { label: 'Silsoe', value: 'Silsoe' },
  { label: 'Silt', value: 'Silt' },
  { label: 'Siltepec', value: 'Siltepec' },
  { label: 'Siluko', value: 'Siluko' },
  { label: 'Silute', value: 'Silute' },
  { label: 'Silva', value: 'Silva' },
  { label: 'Silva Jardim', value: 'Silva Jardim' },
  { label: 'Silvalde', value: 'Silvalde' },
  { label: 'Silvan', value: 'Silvan' },
  { label: 'Silvan', value: 'Silvan' },
  { label: 'Silvana', value: 'Silvana' },
  { label: 'Silvania', value: 'Silvania' },
  { label: 'Silvânia', value: 'Silvânia' },
  { label: 'Silvano Dorba', value: 'Silvano Dorba' },
  { label: 'Silvano Pietra', value: 'Silvano Pietra' },
  { label: 'Silvanópolis', value: 'Silvanópolis' },
  { label: 'Silvaplana', value: 'Silvaplana' },
  { label: 'Silvar', value: 'Silvar' },
  { label: 'Silvassa', value: 'Silvassa' },
  { label: 'Silveira', value: 'Silveira' },
  { label: 'Silveira', value: 'Silveira' },
  { label: 'Silveira Martins', value: 'Silveira Martins' },
  { label: 'Silveirânia', value: 'Silveirânia' },
  { label: 'Silveiras', value: 'Silveiras' },
  { label: 'Silver Bay', value: 'Silver Bay' },
  { label: 'Silver Berry', value: 'Silver Berry' },
  { label: 'Silver Bow County', value: 'Silver Bow County' },
  { label: 'Silver City', value: 'Silver City' },
  { label: 'Silver Creek', value: 'Silver Creek' },
  { label: 'Silver End', value: 'Silver End' },
  { label: 'Silver Firs', value: 'Silver Firs' },
  { label: 'Silver Grove', value: 'Silver Grove' },
  { label: 'Silver Hill', value: 'Silver Hill' },
  { label: 'Silver Lake', value: 'Silver Lake' },
  { label: 'Silver Lake', value: 'Silver Lake' },
  { label: 'Silver Lake', value: 'Silver Lake' },
  { label: 'Silver Lake', value: 'Silver Lake' },
  { label: 'Silver Lake', value: 'Silver Lake' },
  { label: 'Silver Lake', value: 'Silver Lake' },
  { label: 'Silver Lakes', value: 'Silver Lakes' },
  { label: 'Silver Ridge', value: 'Silver Ridge' },
  { label: 'Silver Sands', value: 'Silver Sands' },
  { label: 'Silver Spring', value: 'Silver Spring' },
  { label: 'Silver Springs', value: 'Silver Springs' },
  { label: 'Silver Springs', value: 'Silver Springs' },
  { label: 'Silver Springs Shores', value: 'Silver Springs Shores' },
  { label: 'Silver Summit', value: 'Silver Summit' },
  { label: 'Silverdale', value: 'Silverdale' },
  { label: 'Silverdale', value: 'Silverdale' },
  { label: 'Silverdale', value: 'Silverdale' },
  { label: 'Silverstone', value: 'Silverstone' },
  { label: 'Silverthorne', value: 'Silverthorne' },
  { label: 'Silverton', value: 'Silverton' },
  { label: 'Silverton', value: 'Silverton' },
  { label: 'Silverton', value: 'Silverton' },
  { label: 'Silverton', value: 'Silverton' },
  { label: 'Silverton', value: 'Silverton' },
  { label: 'Silverwater', value: 'Silverwater' },
  { label: 'Silves', value: 'Silves' },
  { label: 'Silves', value: 'Silves' },
  { label: 'Silvi', value: 'Silvi' },
  { label: 'Silvia', value: 'Silvia' },
  { label: 'Silvianópolis', value: 'Silvianópolis' },
  { label: 'Silvino Lobos', value: 'Silvino Lobos' },
  { label: 'Silvino Lobos', value: 'Silvino Lobos' },
  { label: 'Silvis', value: 'Silvis' },
  { label: 'Silvolde', value: 'Silvolde' },
  { label: 'Silz', value: 'Silz' },
  { label: 'Sim', value: 'Sim' },
  { label: 'Sima', value: 'Sima' },
  { label: 'Simacota', value: 'Simacota' },
  { label: 'Simala', value: 'Simala' },
  { label: 'Simala', value: 'Simala' },
  { label: 'Simancas', value: 'Simancas' },
  { label: 'Şimand', value: 'Şimand' },
  { label: 'Simandre', value: 'Simandre' },
  { label: 'Simandres', value: 'Simandres' },
  { label: 'Simanggang', value: 'Simanggang' },
  { label: 'Šimanovci', value: 'Šimanovci' },
  { label: 'Símantra', value: 'Símantra' },
  { label: 'Simanu Sur', value: 'Simanu Sur' },
  { label: 'Simanu Sur', value: 'Simanu Sur' },
  { label: 'Simão Dias', value: 'Simão Dias' },
  { label: 'Simão Pereira', value: 'Simão Pereira' },
  { label: 'Simaria', value: 'Simaria' },
  { label: 'Simat De La Valldigna', value: 'Simat De La Valldigna' },
  { label: 'Simav', value: 'Simav' },
  { label: 'Simaxis', value: 'Simaxis' },
  { label: 'Simbach', value: 'Simbach' },
  { label: 'Simbach Am Inn', value: 'Simbach Am Inn' },
  { label: 'Simbahan', value: 'Simbahan' },
  { label: 'Simbakoro', value: 'Simbakoro' },
  { label: 'Simbario', value: 'Simbario' },
  { label: 'Simcoe', value: 'Simcoe' },
  { label: 'Simdega', value: 'Simdega' },
  { label: 'Simeiz', value: 'Simeiz' },
  { label: 'Simenqian', value: 'Simenqian' },
  { label: 'Simeonovgrad', value: 'Simeonovgrad' },
  { label: 'Simeprodeso (Colectivo Nuevo)', value: 'Simeprodeso (Colectivo Nuevo)' },
  { label: 'Simeria', value: 'Simeria' },
  { label: 'Simeykyne', value: 'Simeykyne' },
  { label: 'Simferopol', value: 'Simferopol' },
  { label: 'Simferopol Raion', value: 'Simferopol Raion' },
  { label: 'Simga', value: 'Simga' },
  { label: 'Simi Valley', value: 'Simi Valley' },
  { label: 'Şimian', value: 'Şimian' },
  { label: 'Şimian', value: 'Şimian' },
  { label: 'Simiane-Collongue', value: 'Simiane-Collongue' },
  { label: 'Simijaca', value: 'Simijaca' },
  { label: 'Simimbaan', value: 'Simimbaan' },
  { label: 'Simimbaan', value: 'Simimbaan' },
  { label: 'Simin-E Abaru', value: 'Simin-E Abaru' },
  { label: 'Simin-E Zagheh', value: 'Simin-E Zagheh' },
  { label: 'Siminicea', value: 'Siminicea' },
  { label: 'Siminoc', value: 'Siminoc' },
  { label: 'Siminshahr', value: 'Siminshahr' },
  { label: 'Simira', value: 'Simira' },
  { label: 'Șimișna', value: 'Șimișna' },
  { label: 'Simití', value: 'Simití' },
  { label: 'Simitli', value: 'Simitli' },
  { label: 'Şimleu Silvaniei', value: 'Şimleu Silvaniei' },
  { label: 'Simmelsdorf', value: 'Simmelsdorf' },
  { label: 'Simmerath', value: 'Simmerath' },
  { label: 'Simmering', value: 'Simmering' },
  { label: 'Simmern', value: 'Simmern' },
  { label: 'Simmersfeld', value: 'Simmersfeld' },
  { label: 'Simmertal', value: 'Simmertal' },
  { label: 'Simmesport', value: 'Simmesport' },
  { label: 'Simmineh', value: 'Simmineh' },
  { label: 'Simmozheim', value: 'Simmozheim' },
  { label: 'Simnas', value: 'Simnas' },
  { label: 'Şimnicu De Sus', value: 'Şimnicu De Sus' },
  { label: 'Simo', value: 'Simo' },
  { label: 'Simoca', value: 'Simoca' },
  { label: 'Simod', value: 'Simod' },
  { label: 'Simod', value: 'Simod' },
  { label: 'Simod', value: 'Simod' },
  { label: 'Simões', value: 'Simões' },
  { label: 'Simões Filho', value: 'Simões Filho' },
  { label: 'Simojovel', value: 'Simojovel' },
  { label: 'Simojovel De Allende', value: 'Simojovel De Allende' },
  { label: 'Simolândia', value: 'Simolândia' },
  { label: 'Șimon', value: 'Șimon' },
  { label: 'Simón Sarlat', value: 'Simón Sarlat' },
  { label: 'Simonésia', value: 'Simonésia' },
  { label: 'Simoneşti', value: 'Simoneşti' },
  { label: 'Simonton Lake', value: 'Simonton Lake' },
  { label: 'Simontornya', value: 'Simontornya' },
  { label: 'Simorgh County', value: 'Simorgh County' },
  { label: 'Simpang', value: 'Simpang' },
  { label: 'Simpang Empat', value: 'Simpang Empat' },
  { label: 'Simpelveld', value: 'Simpelveld' },
  { label: 'Simplício Mendes', value: 'Simplício Mendes' },
  { label: 'Simpson', value: 'Simpson' },
  { label: 'Simpson County', value: 'Simpson County' },
  { label: 'Simpson County', value: 'Simpson County' },
  { label: 'Simpsonville', value: 'Simpsonville' },
  { label: 'Simpsonville', value: 'Simpsonville' },
  { label: 'Simrishamn', value: 'Simrishamn' },
  { label: 'Simsbury Center', value: 'Simsbury Center' },
  { label: 'Simuay', value: 'Simuay' },
  { label: 'Simuay', value: 'Simuay' },
  { label: 'Simunul', value: 'Simunul' },
  { label: 'Simunul', value: 'Simunul' },
  { label: 'Sin', value: 'Sin' },
  { label: 'Sinaballaj', value: 'Sinaballaj' },
  { label: 'Sinabang', value: 'Sinabang' },
  { label: 'Sinabelkirchen', value: 'Sinabelkirchen' },
  { label: 'Sinacaban', value: 'Sinacaban' },
  { label: 'Sinagra', value: 'Sinagra' },
  { label: 'Sinagra', value: 'Sinagra' },
  { label: 'Sīnah', value: 'Sīnah' },
  { label: 'Sinahua', value: 'Sinahua' },
  { label: 'Sinahuiza', value: 'Sinahuiza' },
  { label: 'Sinaia', value: 'Sinaia' },
  { label: 'Sinait', value: 'Sinait' },
  { label: 'Sinait', value: 'Sinait' },
  { label: 'Sinait', value: 'Sinait' },
  { label: 'Sinait', value: 'Sinait' },
  { label: 'Sinala', value: 'Sinala' },
  { label: 'Sinaloa', value: 'Sinaloa' },
  { label: 'Sinaloa', value: 'Sinaloa' },
  { label: 'Sinaloa De Leyva', value: 'Sinaloa De Leyva' },
  { label: 'Sinalunga', value: 'Sinalunga' },
  { label: 'Sinamar', value: 'Sinamar' },
  { label: 'Sinamar', value: 'Sinamar' },
  { label: 'Sinan', value: 'Sinan' },
  { label: 'Sinanche', value: 'Sinanche' },
  { label: 'Sinan-Gun', value: 'Sinan-Gun' },
  { label: 'Sinanju', value: 'Sinanju' },
  { label: 'Sinanpaşa', value: 'Sinanpaşa' },
  { label: 'Sinapan', value: 'Sinapan' },
  { label: 'Sinarcas', value: 'Sinarcas' },
  { label: 'Sinasina Yonggamugl', value: 'Sinasina Yonggamugl' },
  { label: 'Sinawilan', value: 'Sinawilan' },
  { label: 'Sinawilan', value: 'Sinawilan' },
  { label: 'Sinawilan', value: 'Sinawilan' },
  { label: 'Sinayawan', value: 'Sinayawan' },
  { label: 'Sinayawan', value: 'Sinayawan' },
  { label: 'Sinayawan', value: 'Sinayawan' },
  { label: 'Sinazongwe', value: 'Sinazongwe' },
  { label: 'Șinca Nouă', value: 'Șinca Nouă' },
  { label: 'Şinca Veche', value: 'Şinca Veche' },
  { label: 'Șincai', value: 'Șincai' },
  { label: 'Șincai-Fânațe', value: 'Șincai-Fânațe' },
  { label: 'Sincan', value: 'Sincan' },
  { label: 'Sincelejo', value: 'Sincelejo' },
  { label: 'Sinceny', value: 'Sinceny' },
  { label: 'Sincik', value: 'Sincik' },
  { label: 'Sindal', value: 'Sindal' },
  { label: 'Sindangan', value: 'Sindangan' },
  { label: 'Sindelfingen', value: 'Sindelfingen' },
  { label: 'Sindelsdorf', value: 'Sindelsdorf' },
  { label: 'Sindewahi', value: 'Sindewahi' },
  { label: 'Sindgi', value: 'Sindgi' },
  { label: 'Sindhnur', value: 'Sindhnur' },
  { label: 'Sindhudurg', value: 'Sindhudurg' },
  { label: 'Sindhuli', value: 'Sindhuli' },
  { label: 'Sindhupalchok', value: 'Sindhupalchok' },
  { label: 'Sindi', value: 'Sindi' },
  { label: 'Sindi', value: 'Sindi' },
  { label: 'Sindia', value: 'Sindia' },
  { label: 'Sındırgı', value: 'Sındırgı' },
  { label: 'Sindkheda', value: 'Sindkheda' },
  { label: 'Sindon', value: 'Sindon' },
  { label: 'Sindon', value: 'Sindon' },
  { label: 'Sindor', value: 'Sindor' },
  { label: 'Síndos', value: 'Síndos' },
  { label: 'Sindou', value: 'Sindou' },
  { label: 'Șindrești', value: 'Șindrești' },
  { label: 'Șindrilari', value: 'Șindrilari' },
  { label: 'Sinegorsk', value: 'Sinegorsk' },
  { label: 'Sinegorskiy', value: 'Sinegorskiy' },
  { label: 'Sinegorye', value: 'Sinegorye' },
  { label: 'Sines', value: 'Sines' },
  { label: 'Sineşti', value: 'Sineşti' },
  { label: 'Sineşti', value: 'Sineşti' },
  { label: 'Sineşti', value: 'Sineşti' },
  { label: 'Sinești', value: 'Sinești' },
  { label: 'Sineu', value: 'Sineu' },
  { label: 'Sinfin', value: 'Sinfin' },
  { label: 'Sinfra', value: 'Sinfra' },
  { label: 'Sing Buri', value: 'Sing Buri' },
  { label: 'Singa', value: 'Singa' },
  { label: 'Singac', value: 'Singac' },
  { label: 'Singanallur', value: 'Singanallur' },
  { label: 'Singani', value: 'Singani' },
  { label: 'Singaparna', value: 'Singaparna' },
  { label: 'Singapay', value: 'Singapay' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Singapperumalkovil', value: 'Singapperumalkovil' },
  { label: 'Singapur', value: 'Singapur' },
  { label: 'Singaraja', value: 'Singaraja' },
  { label: 'Singarayakonda', value: 'Singarayakonda' },
  { label: 'Singen', value: 'Singen' },
  { label: 'Sîngera', value: 'Sîngera' },
  { label: 'Sîngerei', value: 'Sîngerei' },
  { label: 'Singhofen', value: 'Singhofen' },
  { label: 'Singida', value: 'Singida' },
  { label: 'Singkamas', value: 'Singkamas' },
  { label: 'Singkawang', value: 'Singkawang' },
  { label: 'Singkil', value: 'Singkil' },
  { label: 'Singleton', value: 'Singleton' },
  { label: 'Singleton', value: 'Singleton' },
  { label: 'Singleton Heights', value: 'Singleton Heights' },
  { label: 'Singojuruh', value: 'Singojuruh' },
  { label: 'Singoli', value: 'Singoli' },
  { label: 'Singosari', value: 'Singosari' },
  { label: 'Singra', value: 'Singra' },
  { label: 'Singrauli', value: 'Singrauli' },
  { label: 'Singtam', value: 'Singtam' },
  { label: 'Singuilucan', value: 'Singuilucan' },
  { label: 'Singur', value: 'Singur' },
  { label: 'Singureni', value: 'Singureni' },
  { label: 'Sinhyeon', value: 'Sinhyeon' },
  { label: 'Sini', value: 'Sini' },
  { label: 'Sini', value: 'Sini' },
  { label: 'Sinigo', value: 'Sinigo' },
  { label: 'Sinilian First', value: 'Sinilian First' },
  { label: 'Sinilian First', value: 'Sinilian First' },
  { label: 'Siniloan', value: 'Siniloan' },
  { label: 'Sinimbu', value: 'Sinimbu' },
  { label: 'Sinio', value: 'Sinio' },
  { label: 'Siniscola', value: 'Siniscola' },
  { label: 'Sinisian', value: 'Sinisian' },
  { label: 'Sinitsyno', value: 'Sinitsyno' },
  { label: 'Sinj', value: 'Sinj' },
  { label: 'Sinjai', value: 'Sinjai' },
  { label: 'Sinjar', value: 'Sinjar' },
  { label: 'Sinjhoro', value: 'Sinjhoro' },
  { label: 'Sinking Spring', value: 'Sinking Spring' },
  { label: 'Sinlabajos', value: 'Sinlabajos' },
  { label: 'Sin-Le-Noble', value: 'Sin-Le-Noble' },
  { label: 'Sinmak', value: 'Sinmak' },
  { label: 'Sînmartin', value: 'Sînmartin' },
  { label: 'Sinn', value: 'Sinn' },
  { label: 'Sinnai', value: 'Sinnai' },
  { label: 'Sinnamon Park', value: 'Sinnamon Park' },
  { label: 'Sinnar', value: 'Sinnar' },
  { label: 'Sinnar', value: 'Sinnar' },
  { label: 'Sinnersdorf', value: 'Sinnersdorf' },
  { label: 'Sinodskoye', value: 'Sinodskoye' },
  { label: 'Sinolon', value: 'Sinolon' },
  { label: 'Sinolon', value: 'Sinolon' },
  { label: 'Sinonoc', value: 'Sinonoc' },
  { label: 'Sinop', value: 'Sinop' },
  { label: 'Sinopoli', value: 'Sinopoli' },
  { label: 'Sinor', value: 'Sinor' },
  { label: 'Sinošević', value: 'Sinošević' },
  { label: 'Sins', value: 'Sins' },
  { label: 'Sinsang-Ni', value: 'Sinsang-Ni' },
  { label: 'Sinsheim', value: 'Sinsheim' },
  { label: 'Sint Annaparochie', value: 'Sint Annaparochie' },
  { label: 'Sint Anthonis', value: 'Sint Anthonis' },
  { label: 'Sint Jacobiparochie', value: 'Sint Jacobiparochie' },
  { label: 'Sint Jansklooster', value: 'Sint Jansklooster' },
  { label: 'Sint Joost', value: 'Sint Joost' },
  { label: 'Sint Laurens', value: 'Sint Laurens' },
  { label: 'Sint Maartenszee', value: 'Sint Maartenszee' },
  { label: 'Sint Nicolaasga', value: 'Sint Nicolaasga' },
  { label: 'Sint Odiliënberg', value: 'Sint Odiliënberg' },
  { label: 'Sint Pancras', value: 'Sint Pancras' },
  { label: 'Sint Philipsland', value: 'Sint Philipsland' },
  { label: 'Sint Willebrord', value: 'Sint Willebrord' },
  { label: 'Sint-Amands', value: 'Sint-Amands' },
  { label: 'Sintang', value: 'Sintang' },
  { label: 'Sintansin', value: 'Sintansin' },
  { label: 'Sintea Mare', value: 'Sintea Mare' },
  { label: 'Şintereag', value: 'Şintereag' },
  { label: 'Sintești', value: 'Sintești' },
  { label: 'Şinteu', value: 'Şinteu' },
  { label: 'Sint-Genesius-Rode', value: 'Sint-Genesius-Rode' },
  { label: 'Sint-Gillis-Waas', value: 'Sint-Gillis-Waas' },
  { label: 'Sintjohannesga', value: 'Sintjohannesga' },
  { label: 'Sint-Joris', value: 'Sint-Joris' },
  { label: 'Sint-Katelijne-Waver', value: 'Sint-Katelijne-Waver' },
  { label: 'Sint-Kruis', value: 'Sint-Kruis' },
  { label: 'Sint-Laureins', value: 'Sint-Laureins' },
  { label: 'Sint-Lievens-Houtem', value: 'Sint-Lievens-Houtem' },
  { label: 'Sint-Maria-Lierde', value: 'Sint-Maria-Lierde' },
  { label: 'Sint-Martens-Latem', value: 'Sint-Martens-Latem' },
  { label: 'Sint-Martens-Lennik', value: 'Sint-Martens-Lennik' },
  { label: 'Sint-Michielsgestel', value: 'Sint-Michielsgestel' },
  { label: 'Sint-Niklaas', value: 'Sint-Niklaas' },
  { label: 'Sint-Oedenrode', value: 'Sint-Oedenrode' },
  { label: 'Sinton', value: 'Sinton' },
  { label: 'Sint-Pieters-Leeuw', value: 'Sint-Pieters-Leeuw' },
  { label: 'Sint-Pieters-Voeren', value: 'Sint-Pieters-Voeren' },
  { label: 'Sintra', value: 'Sintra' },
  { label: 'Sintra', value: 'Sintra' },
  { label: 'Sintra (Town)', value: 'Sintra (Town)' },
  { label: 'Sint-Truiden', value: 'Sint-Truiden' },
  { label: 'Sinuapa', value: 'Sinuapa' },
  { label: 'Sinubong', value: 'Sinubong' },
  { label: 'Sinubong', value: 'Sinubong' },
  { label: 'Sinubong', value: 'Sinubong' },
  { label: 'Sinŭiju', value: 'Sinŭiju' },
  { label: 'Sinuknipan', value: 'Sinuknipan' },
  { label: 'Sinyavino', value: 'Sinyavino' },
  { label: 'Sinyavskoye', value: 'Sinyavskoye' },
  { label: 'Sinzheim', value: 'Sinzheim' },
  { label: 'Sinzig', value: 'Sinzig' },
  { label: 'Sinzing', value: 'Sinzing' },
  { label: 'Siocon', value: 'Siocon' },
  { label: 'Siófok', value: 'Siófok' },
  { label: 'Siófoki Járás', value: 'Siófoki Járás' },
  { label: 'Sioguí Abajo', value: 'Sioguí Abajo' },
  { label: 'Sioguí Arriba', value: 'Sioguí Arriba' },
  { label: 'Sion District', value: 'Sion District' },
  { label: 'Sion Farm', value: 'Sion Farm' },
  { label: 'Sion Mills', value: 'Sion Mills' },
  { label: 'Sion Mumbai', value: 'Sion Mumbai' },
  { label: 'Sion-Les-Mines', value: 'Sion-Les-Mines' },
  { label: 'Sionogan', value: 'Sionogan' },
  { label: 'Sionogan', value: 'Sionogan' },
  { label: 'Sioux Center', value: 'Sioux Center' },
  { label: 'Sioux City', value: 'Sioux City' },
  { label: 'Sioux County', value: 'Sioux County' },
  { label: 'Sioux County', value: 'Sioux County' },
  { label: 'Sioux County', value: 'Sioux County' },
  { label: 'Sioux Falls', value: 'Sioux Falls' },
  { label: 'Sioux Lookout', value: 'Sioux Lookout' },
  { label: 'Sipacapa', value: 'Sipacapa' },
  { label: 'Sipalay', value: 'Sipalay' },
  { label: 'Siparia', value: 'Siparia' },
  { label: 'Sipe Sipe', value: 'Sipe Sipe' },
  { label: 'Sipí', value: 'Sipí' },
  { label: 'Siping', value: 'Siping' },
  { label: 'Šipkovica', value: 'Šipkovica' },
  { label: 'Sipocot', value: 'Sipocot' },
  { label: 'Şipote', value: 'Şipote' },
  { label: 'Șipotu', value: 'Șipotu' },
  { label: 'Šipovo', value: 'Šipovo' },
  { label: 'Sippersfeld', value: 'Sippersfeld' },
  { label: 'Sipplingen', value: 'Sipplingen' },
  { label: 'Sippy Downs', value: 'Sippy Downs' },
  { label: 'Siqueira Campos', value: 'Siqueira Campos' },
  { label: 'Siqueros', value: 'Siqueros' },
  { label: 'Siquijor', value: 'Siquijor' },
  { label: 'Siquinalá', value: 'Siquinalá' },
  { label: 'Siquirres', value: 'Siquirres' },
  { label: 'Sir Powys', value: 'Sir Powys' },
  { label: 'Sira', value: 'Sira' },
  { label: 'Sirač', value: 'Sirač' },
  { label: 'Siracusa', value: 'Siracusa' },
  { label: 'Siraha ', value: 'Siraha ' },
  { label: 'Sirajganj ', value: 'Sirajganj ' },
  { label: 'Şiran', value: 'Şiran' },
  { label: 'Sirari', value: 'Sirari' },
  { label: 'Sirathu', value: 'Sirathu' },
  { label: 'Siravand', value: 'Siravand' },
  { label: 'Siraway', value: 'Siraway' },
  { label: 'Sirdal', value: 'Sirdal' },
  { label: 'Sirdan', value: 'Sirdan' },
  { label: 'Sirdaryo', value: 'Sirdaryo' },
  { label: 'Siren', value: 'Siren' },
  { label: 'Siret', value: 'Siret' },
  { label: 'Sireţel', value: 'Sireţel' },
  { label: 'Sireuil', value: 'Sireuil' },
  { label: 'Sirhind-Fategarh', value: 'Sirhind-Fategarh' },
  { label: 'Şiria', value: 'Şiria' },
  { label: 'Sirib', value: 'Sirib' },
  { label: 'Sirib', value: 'Sirib' },
  { label: 'Sirib', value: 'Sirib' },
  { label: 'Sirignano', value: 'Sirignano' },
  { label: 'Sirik', value: 'Sirik' },
  { label: 'Şirineasa', value: 'Şirineasa' },
  { label: 'Sirinhaém', value: 'Sirinhaém' },
  { label: 'Siriri', value: 'Siriri' },
  { label: 'Siris', value: 'Siris' },
  { label: 'Sirjan', value: 'Sirjan' },
  { label: 'Sirkān', value: 'Sirkān' },
  { label: 'Sirkazhi', value: 'Sirkazhi' },
  { label: 'Sirmaur', value: 'Sirmaur' },
  { label: 'Sirmaur', value: 'Sirmaur' },
  { label: 'Sirmione', value: 'Sirmione' },
  { label: 'Şirna', value: 'Şirna' },
  { label: 'Sirnach', value: 'Sirnach' },
  { label: 'Sirnitz', value: 'Sirnitz' },
  { label: 'Sirohi', value: 'Sirohi' },
  { label: 'Sirok', value: 'Sirok' },
  { label: 'Široki Brijeg', value: 'Široki Brijeg' },
  { label: 'Široko Polje', value: 'Široko Polje' },
  { label: 'Sirolo', value: 'Sirolo' },
  { label: 'Sironcha', value: 'Sironcha' },
  { label: 'Sirone', value: 'Sirone' },
  { label: 'Sironj', value: 'Sironj' },
  { label: 'Sironko', value: 'Sironko' },
  { label: 'Siror', value: 'Siror' },
  { label: 'Sirpur', value: 'Sirpur' },
  { label: 'Sirre', value: 'Sirre' },
  { label: 'Sirsa', value: 'Sirsa' },
  { label: 'Sirsa', value: 'Sirsa' },
  { label: 'Sirsaganj', value: 'Sirsaganj' },
  { label: 'Sirsi', value: 'Sirsi' },
  { label: 'Sirsi', value: 'Sirsi' },
  { label: 'Sirsilla', value: 'Sirsilla' },
  { label: 'Sirta', value: 'Sirta' },
  { label: 'Sirte', value: 'Sirte' },
  { label: 'Sirtori', value: 'Sirtori' },
  { label: 'Siruela', value: 'Siruela' },
  { label: 'Siruguppa', value: 'Siruguppa' },
  { label: 'Siruma', value: 'Siruma' },
  { label: 'Sirumugai', value: 'Sirumugai' },
  { label: 'Sirur', value: 'Sirur' },
  { label: 'Sirvan', value: 'Sirvan' },
  { label: 'Şirvan', value: 'Şirvan' },
  { label: 'Şirvan', value: 'Şirvan' },
  { label: 'Sirvintos', value: 'Sirvintos' },
  { label: 'Sirwah', value: 'Sirwah' },
  { label: 'Sis', value: 'Sis' },
  { label: 'Sis', value: 'Sis' },
  { label: 'Sisa', value: 'Sisa' },
  { label: 'Sisak', value: 'Sisak' },
  { label: 'Sisakht', value: 'Sisakht' },
  { label: 'Sisal', value: 'Sisal' },
  { label: 'Sisamón', value: 'Sisamón' },
  { label: 'Sisante', value: 'Sisante' },
  { label: 'Sisauli', value: 'Sisauli' },
  { label: 'Sisavan', value: 'Sisavan' },
  { label: 'Sisco', value: 'Sisco' },
  { label: 'Sisco Heights', value: 'Sisco Heights' },
  { label: 'Şişeşti', value: 'Şişeşti' },
  { label: 'Șisești', value: 'Șisești' },
  { label: 'Sishilichengzi', value: 'Sishilichengzi' },
  { label: 'Sishui', value: 'Sishui' },
  { label: 'Sísion', value: 'Sísion' },
  { label: 'Siskiyou County', value: 'Siskiyou County' },
  { label: 'Şişli', value: 'Şişli' },
  { label: 'Sisoguichi', value: 'Sisoguichi' },
  { label: 'Sison', value: 'Sison' },
  { label: 'Sison', value: 'Sison' },
  { label: 'Sison', value: 'Sison' },
  { label: 'Sison', value: 'Sison' },
  { label: 'Sisonke District Municipality', value: 'Sisonke District Municipality' },
  { label: 'Sisophon', value: 'Sisophon' },
  { label: 'Sissa', value: 'Sissa' },
  { label: 'Sissach', value: 'Sissach' },
  { label: 'Sisseton', value: 'Sisseton' },
  { label: 'Sissinghurst', value: 'Sissinghurst' },
  { label: 'Sissonne', value: 'Sissonne' },
  { label: 'Sissonville', value: 'Sissonville' },
  { label: 'Sistaneh', value: 'Sistaneh' },
  { label: 'Sista-Palkino', value: 'Sista-Palkino' },
  { label: 'Şiştarovăţ', value: 'Şiştarovăţ' },
  { label: 'Sisteron', value: 'Sisteron' },
  { label: 'Sisters', value: 'Sisters' },
  { label: 'Sistersville', value: 'Sistersville' },
  { label: 'Sistiana-Visogliano', value: 'Sistiana-Visogliano' },
  { label: 'Sistranda', value: 'Sistranda' },
  { label: 'Sistrans', value: 'Sistrans' },
  { label: 'Siswa Bazar', value: 'Siswa Bazar' },
  { label: 'Sita Buzăului', value: 'Sita Buzăului' },
  { label: 'Sīta Road', value: 'Sīta Road' },
  { label: 'Sitagroí', value: 'Sitagroí' },
  { label: 'Sitalá', value: 'Sitalá' },
  { label: 'Sitamarhi', value: 'Sitamarhi' },
  { label: 'Sitamau', value: 'Sitamau' },
  { label: 'Sitangkai', value: 'Sitangkai' },
  { label: 'Sitangkai', value: 'Sitangkai' },
  { label: 'Sitapur', value: 'Sitapur' },
  { label: 'Sitarganj', value: 'Sitarganj' },
  { label: 'Šitbořice', value: 'Šitbořice' },
  { label: 'Siteki', value: 'Siteki' },
  { label: 'Sitges', value: 'Sitges' },
  { label: 'Sitia', value: 'Sitia' },
  { label: 'Sitilpech', value: 'Sitilpech' },
  { label: 'Sítio Dabadia', value: 'Sítio Dabadia' },
  { label: 'Sitio De Xitlapehua', value: 'Sitio De Xitlapehua' },
  { label: 'Sítio Do Mato', value: 'Sítio Do Mato' },
  { label: 'Sítio Do Quinto', value: 'Sítio Do Quinto' },
  { label: 'Sitio Ejido', value: 'Sitio Ejido' },
  { label: 'Sítio Novo', value: 'Sítio Novo' },
  { label: 'Sítio Novo', value: 'Sítio Novo' },
  { label: 'Sítio Novo Do Tocantins', value: 'Sítio Novo Do Tocantins' },
  { label: 'Sitio Prado', value: 'Sitio Prado' },
  { label: 'Sitionuevo', value: 'Sitionuevo' },
  { label: 'Sitka', value: 'Sitka' },
  { label: 'Sitka City And Borough', value: 'Sitka City And Borough' },
  { label: 'Sitniki', value: 'Sitniki' },
  { label: 'Sitno', value: 'Sitno' },
  { label: 'Sitovo', value: 'Sitovo' },
  { label: 'Sitpach', value: 'Sitpach' },
  { label: 'Sitrah', value: 'Sitrah' },
  { label: 'Sittard', value: 'Sittard' },
  { label: 'Sitten', value: 'Sitten' },
  { label: 'Sittensen', value: 'Sittensen' },
  { label: 'Sittersdorf', value: 'Sittersdorf' },
  { label: 'Sittingbourne', value: 'Sittingbourne' },
  { label: 'Sittwe', value: 'Sittwe' },
  { label: 'Situbondo', value: 'Situbondo' },
  { label: 'Sitzendorf', value: 'Sitzendorf' },
  { label: 'Sitzendorf An Der Schmida', value: 'Sitzendorf An Der Schmida' },
  { label: 'Siukh', value: 'Siukh' },
  { label: 'Siuna', value: 'Siuna' },
  { label: 'Siuntio', value: 'Siuntio' },
  { label: 'Siurana', value: 'Siurana' },
  { label: 'Siurgus Donigala', value: 'Siurgus Donigala' },
  { label: 'Siusi', value: 'Siusi' },
  { label: 'Siva', value: 'Siva' },
  { label: 'Sivaganga', value: 'Sivaganga' },
  { label: 'Sivagiri', value: 'Sivagiri' },
  { label: 'Sivakasi', value: 'Sivakasi' },
  { label: 'Sivaki', value: 'Sivaki' },
  { label: 'Sivakovka', value: 'Sivakovka' },
  { label: 'Sivala East Godavari District', value: 'Sivala East Godavari District' },
  { label: 'Sivaslı', value: 'Sivaslı' },
  { label: 'Siverek', value: 'Siverek' },
  { label: 'Sivers’K', value: 'Sivers’K' },
  { label: 'Siverskiy', value: 'Siverskiy' },
  { label: 'Siviano', value: 'Siviano' },
  { label: 'Siviral', value: 'Siviral' },
  { label: 'Șivița', value: 'Șivița' },
  { label: 'Sivrice', value: 'Sivrice' },
  { label: 'Sivrihisar', value: 'Sivrihisar' },
  { label: 'Siwa Oasis', value: 'Siwa Oasis' },
  { label: 'Siwan', value: 'Siwan' },
  { label: 'Siwana', value: 'Siwana' },
  { label: 'Six Mile', value: 'Six Mile' },
  { label: 'Six Shooter Canyon', value: 'Six Shooter Canyon' },
  { label: 'Sixaola', value: 'Sixaola' },
  { label: 'Six-Fours-Les-Plages', value: 'Six-Fours-Les-Plages' },
  { label: 'Sixmile Run', value: 'Sixmile Run' },
  { label: 'Sixmilebridge', value: 'Sixmilebridge' },
  { label: 'Sixteen Mile Stand', value: 'Sixteen Mile Stand' },
  { label: 'Sixt-Sur-Aff', value: 'Sixt-Sur-Aff' },
  { label: 'Siyabuswa', value: 'Siyabuswa' },
  { label: 'Siyanda District Municipality', value: 'Siyanda District Municipality' },
  { label: 'Siziano', value: 'Siziano' },
  { label: 'Sizovka', value: 'Sizovka' },
  { label: 'Sizun', value: 'Sizun' },
  { label: 'Sizyy Bugor', value: 'Sizyy Bugor' },
  { label: 'Sizzano', value: 'Sizzano' },
  { label: 'Sjenica', value: 'Sjenica' },
  { label: 'Sjöbo', value: 'Sjöbo' },
  { label: 'Sjøholt', value: 'Sjøholt' },
  { label: 'Sjølyststranda', value: 'Sjølyststranda' },
  { label: 'Sjömarken', value: 'Sjömarken' },
  { label: 'Sjóvar', value: 'Sjóvar' },
  { label: 'Sjøvegan', value: 'Sjøvegan' },
  { label: 'Sjuntorp', value: 'Sjuntorp' },
  { label: 'Škabrnja', value: 'Škabrnja' },
  { label: 'Skadovs’K', value: 'Skadovs’K' },
  { label: 'Skælskør', value: 'Skælskør' },
  { label: 'Skaerbaek', value: 'Skaerbaek' },
  { label: 'Skævinge', value: 'Skævinge' },
  { label: 'Skaftárhreppur', value: 'Skaftárhreppur' },
  { label: 'Skagabyggð', value: 'Skagabyggð' },
  { label: 'Skagen', value: 'Skagen' },
  { label: 'Skagit County', value: 'Skagit County' },
  { label: 'Skagway Municipality', value: 'Skagway Municipality' },
  { label: 'Skaidiškės', value: 'Skaidiškės' },
  { label: 'Skała', value: 'Skała' },
  { label: 'Skála', value: 'Skála' },
  { label: 'Skála', value: 'Skála' },
  { label: 'Skála Oropoú', value: 'Skála Oropoú' },
  { label: 'Skalánion', value: 'Skalánion' },
  { label: 'Skala-Podil’S’Ka', value: 'Skala-Podil’S’Ka' },
  { label: 'Skalat', value: 'Skalat' },
  { label: 'Skálavík', value: 'Skálavík' },
  { label: 'Skalbmierz', value: 'Skalbmierz' },
  { label: 'Skålevik', value: 'Skålevik' },
  { label: 'Skalica', value: 'Skalica' },
  { label: 'Skalistoye', value: 'Skalistoye' },
  { label: 'Skalmierzyce', value: 'Skalmierzyce' },
  { label: 'Skalná', value: 'Skalná' },
  { label: 'Skamania County', value: 'Skamania County' },
  { label: 'Skanderborg', value: 'Skanderborg' },
  { label: 'Skanderborg Kommune', value: 'Skanderborg Kommune' },
  { label: 'Skaneateles', value: 'Skaneateles' },
  { label: 'Skanes', value: 'Skanes' },
  { label: 'Skånland', value: 'Skånland' },
  { label: 'Skänninge', value: 'Skänninge' },
  { label: 'Skänninge', value: 'Skänninge' },
  { label: 'Skanör Med Falsterbo', value: 'Skanör Med Falsterbo' },
  { label: 'Skąpe', value: 'Skąpe' },
  { label: 'Skara', value: 'Skara' },
  { label: 'Skarbimierz Osiedle', value: 'Skarbimierz Osiedle' },
  { label: 'Skärblacka', value: 'Skärblacka' },
  { label: 'Skardu', value: 'Skardu' },
  { label: 'Skåre', value: 'Skåre' },
  { label: 'Skåre', value: 'Skåre' },
  { label: 'Skärhamn', value: 'Skärhamn' },
  { label: 'Skarmagkás', value: 'Skarmagkás' },
  { label: 'Skarnes', value: 'Skarnes' },
  { label: 'Skarszewy', value: 'Skarszewy' },
  { label: 'Skaryszew', value: 'Skaryszew' },
  { label: 'Skarżysko Kościelne', value: 'Skarżysko Kościelne' },
  { label: 'Skarżysko-Kamienna', value: 'Skarżysko-Kamienna' },
  { label: 'Skatepark', value: 'Skatepark' },
  { label: 'Skattkärr', value: 'Skattkärr' },
  { label: 'Skaun', value: 'Skaun' },
  { label: 'Skawica', value: 'Skawica' },
  { label: 'Skawina', value: 'Skawina' },
  { label: 'Skawinki', value: 'Skawinki' },
  { label: 'Skedsmo', value: 'Skedsmo' },
  { label: 'Skegness', value: 'Skegness' },
  { label: 'Skegrie', value: 'Skegrie' },
  { label: 'Skeiða- Og Gnúpverjahreppur', value: 'Skeiða- Og Gnúpverjahreppur' },
  { label: 'Skeldon', value: 'Skeldon' },
  { label: 'Skelivka', value: 'Skelivka' },
  { label: 'Skellefteå', value: 'Skellefteå' },
  { label: 'Skelleftehamn', value: 'Skelleftehamn' },
  { label: 'Skellingthorpe', value: 'Skellingthorpe' },
  { label: 'Skelmersdale', value: 'Skelmersdale' },
  { label: 'Skelmorlie', value: 'Skelmorlie' },
  { label: 'Skelton', value: 'Skelton' },
  { label: 'Skennars Head', value: 'Skennars Head' },
  { label: 'Skępe', value: 'Skępe' },
  { label: 'Skepplanda', value: 'Skepplanda' },
  { label: 'Skerries', value: 'Skerries' },
  { label: 'Skhidnytsya', value: 'Skhidnytsya' },
  { label: 'Skhira', value: 'Skhira' },
  { label: 'Skhirate', value: 'Skhirate' },
  { label: 'Skhirate-Temara', value: 'Skhirate-Temara' },
  { label: 'Skhodnya', value: 'Skhodnya' },
  { label: 'Ski', value: 'Ski' },
  { label: 'Skiáthos', value: 'Skiáthos' },
  { label: 'Skiatook', value: 'Skiatook' },
  { label: 'Skibbereen', value: 'Skibbereen' },
  { label: 'Skibby', value: 'Skibby' },
  { label: 'Skibo', value: 'Skibo' },
  { label: 'Skidaway Island', value: 'Skidaway Island' },
  { label: 'Skidby', value: 'Skidby' },
  { label: 'Skidel’', value: 'Skidel’' },
  { label: 'Skidway Lake', value: 'Skidway Lake' },
  { label: 'Skien', value: 'Skien' },
  { label: 'Skierbieszów', value: 'Skierbieszów' },
  { label: 'Skierniewice', value: 'Skierniewice' },
  { label: 'Skikda', value: 'Skikda' },
  { label: 'Skillingaryd', value: 'Skillingaryd' },
  { label: 'Skinningrove', value: 'Skinningrove' },
  { label: 'Skinnskatteberg', value: 'Skinnskatteberg' },
  { label: 'Skippack', value: 'Skippack' },
  { label: 'Skippers Corner', value: 'Skippers Corner' },
  { label: 'Skipsea', value: 'Skipsea' },
  { label: 'Skipton', value: 'Skipton' },
  { label: 'Skiptvet', value: 'Skiptvet' },
  { label: 'Skivarp', value: 'Skivarp' },
  { label: 'Skive', value: 'Skive' },
  { label: 'Skive Kommune', value: 'Skive Kommune' },
  { label: 'Skjærhalden', value: 'Skjærhalden' },
  { label: 'Skjåk', value: 'Skjåk' },
  { label: 'Skjeberg', value: 'Skjeberg' },
  { label: 'Skjern', value: 'Skjern' },
  { label: 'Skjervøy', value: 'Skjervøy' },
  { label: 'Škocjan', value: 'Škocjan' },
  { label: 'Skoczów', value: 'Skoczów' },
  { label: 'Skodje', value: 'Skodje' },
  { label: 'Škofja Loka', value: 'Škofja Loka' },
  { label: 'Škofljica', value: 'Škofljica' },
  { label: 'Skoger', value: 'Skoger' },
  { label: 'Skoghall', value: 'Skoghall' },
  { label: 'Skogn', value: 'Skogn' },
  { label: 'Skogstorp', value: 'Skogstorp' },
  { label: 'Skogsvågen', value: 'Skogsvågen' },
  { label: 'Skoki', value: 'Skoki' },
  { label: 'Skokie', value: 'Skokie' },
  { label: 'Skokovi', value: 'Skokovi' },
  { label: 'Skole', value: 'Skole' },
  { label: 'Skolkovo', value: 'Skolkovo' },
  { label: 'Sköllersta', value: 'Sköllersta' },
  { label: 'Skołyszyn', value: 'Skołyszyn' },
  { label: 'Skomielna Czarna', value: 'Skomielna Czarna' },
  { label: 'Skomlin', value: 'Skomlin' },
  { label: 'Skópelos', value: 'Skópelos' },
  { label: 'Skopin', value: 'Skopin' },
  { label: 'Skopje', value: 'Skopje' },
  { label: 'Skoppum', value: 'Skoppum' },
  { label: 'Skopun', value: 'Skopun' },
  { label: 'Skórcz', value: 'Skórcz' },
  { label: 'Skorodnoye', value: 'Skorodnoye' },
  { label: 'Skorogoszcz', value: 'Skorogoszcz' },
  { label: 'Skoropuskovskiy', value: 'Skoropuskovskiy' },
  { label: 'Skoroszyce', value: 'Skoroszyce' },
  { label: 'Skørping', value: 'Skørping' },
  { label: 'Skorradalshreppur', value: 'Skorradalshreppur' },
  { label: 'Skórzec', value: 'Skórzec' },
  { label: 'Skotoússa', value: 'Skotoússa' },
  { label: 'Skotterud', value: 'Skotterud' },
  { label: 'Skottsund', value: 'Skottsund' },
  { label: 'Skoútari', value: 'Skoútari' },
  { label: 'Skovby', value: 'Skovby' },
  { label: 'Skövde', value: 'Skövde' },
  { label: 'Skovorodino', value: 'Skovorodino' },
  { label: 'Skovorodinskiy Rayon', value: 'Skovorodinskiy Rayon' },
  { label: 'Skowhegan', value: 'Skowhegan' },
  { label: 'Skrad', value: 'Skrad' },
  { label: 'Skradin', value: 'Skradin' },
  { label: 'Skrbeň', value: 'Skrbeň' },
  { label: 'Skrea', value: 'Skrea' },
  { label: 'Skreia', value: 'Skreia' },
  { label: 'Skrīveri', value: 'Skrīveri' },
  { label: 'Škrljevo', value: 'Škrljevo' },
  { label: 'Skrunda', value: 'Skrunda' },
  { label: 'Skrwilno', value: 'Skrwilno' },
  { label: 'Skrzydlna', value: 'Skrzydlna' },
  { label: 'Skrzyszów', value: 'Skrzyszów' },
  { label: 'Skrzyszów', value: 'Skrzyszów' },
  { label: 'Skudai', value: 'Skudai' },
  { label: 'Skudeneshavn', value: 'Skudeneshavn' },
  { label: 'Skuhrov Nad Bělou', value: 'Skuhrov Nad Bělou' },
  { label: 'Skui', value: 'Skui' },
  { label: 'Skulsk', value: 'Skulsk' },
  { label: 'Skultorp', value: 'Skultorp' },
  { label: 'Skultuna', value: 'Skultuna' },
  { label: 'Skuodas', value: 'Skuodas' },
  { label: 'Skuratovskiy', value: 'Skuratovskiy' },
  { label: 'Skurup', value: 'Skurup' },
  { label: 'Skuteč', value: 'Skuteč' },
  { label: 'Skutskär', value: 'Skutskär' },
  { label: 'Skútustaðahreppur', value: 'Skútustaðahreppur' },
  { label: 'Skúvoy', value: 'Skúvoy' },
  { label: 'Skvortsovo', value: 'Skvortsovo' },
  { label: 'Skvyra', value: 'Skvyra' },
  { label: 'Skvyrs’Kyy Rayon', value: 'Skvyrs’Kyy Rayon' },
  { label: 'Skwierzyna', value: 'Skwierzyna' },
  { label: 'Sky Lake', value: 'Sky Lake' },
  { label: 'Sky Valley', value: 'Sky Valley' },
  { label: 'Skýdra', value: 'Skýdra' },
  { label: 'Skye', value: 'Skye' },
  { label: 'Skyline Acres', value: 'Skyline Acres' },
  { label: 'Skyline View', value: 'Skyline View' },
  { label: 'Skyline-Ganipa', value: 'Skyline-Ganipa' },
  { label: 'Skýros', value: 'Skýros' },
  { label: 'Slabada', value: 'Slabada' },
  { label: 'Slacks Creek', value: 'Slacks Creek' },
  { label: 'Slade Point', value: 'Slade Point' },
  { label: 'Sládkovičovo', value: 'Sládkovičovo' },
  { label: 'Sladkovo', value: 'Sladkovo' },
  { label: 'Sladkovskiy Rayon', value: 'Sladkovskiy Rayon' },
  { label: 'Sladna', value: 'Sladna' },
  { label: 'Slagelse', value: 'Slagelse' },
  { label: 'Slagelse Kommune', value: 'Slagelse Kommune' },
  { label: 'Slaley', value: 'Slaley' },
  { label: 'Slamannan', value: 'Slamannan' },
  { label: 'Slane', value: 'Slane' },
  { label: 'Slangerup', value: 'Slangerup' },
  { label: 'Slănic', value: 'Slănic' },
  { label: 'Slănic', value: 'Slănic' },
  { label: 'Slănic-Moldova', value: 'Slănic-Moldova' },
  { label: 'Slantsevskiy Rayon', value: 'Slantsevskiy Rayon' },
  { label: 'Slantsy', value: 'Slantsy' },
  { label: 'Slaný', value: 'Slaný' },
  { label: 'Šlapanice', value: 'Šlapanice' },
  { label: 'Slashchyovskaya', value: 'Slashchyovskaya' },
  { label: 'Slater', value: 'Slater' },
  { label: 'Slater', value: 'Slater' },
  { label: 'Slater-Marietta', value: 'Slater-Marietta' },
  { label: 'Slatina', value: 'Slatina' },
  { label: 'Slatina', value: 'Slatina' },
  { label: 'Slatina', value: 'Slatina' },
  { label: 'Slatiňany', value: 'Slatiňany' },
  { label: 'Slatina-Timiş', value: 'Slatina-Timiş' },
  { label: 'Slatine', value: 'Slatine' },
  { label: 'Slatington', value: 'Slatington' },
  { label: 'Slatinice', value: 'Slatinice' },
  { label: 'Slatino', value: 'Slatino' },
  { label: 'Slătioara', value: 'Slătioara' },
  { label: 'Slătioara', value: 'Slătioara' },
  { label: 'Slaton', value: 'Slaton' },
  { label: 'Slatyne', value: 'Slatyne' },
  { label: 'Slaughterville', value: 'Slaughterville' },
  { label: 'Slava Cercheză', value: 'Slava Cercheză' },
  { label: 'Slava Rusă', value: 'Slava Rusă' },
  { label: 'Slave Lake', value: 'Slave Lake' },
  { label: 'Slăveni', value: 'Slăveni' },
  { label: 'Slavgorod', value: 'Slavgorod' },
  { label: 'Slavgorodskoye', value: 'Slavgorodskoye' },
  { label: 'Slavičín', value: 'Slavičín' },
  { label: 'Slavkov', value: 'Slavkov' },
  { label: 'Slavkov U Brna', value: 'Slavkov U Brna' },
  { label: 'Slavniy', value: 'Slavniy' },
  { label: 'Slavnoye', value: 'Slavnoye' },
  { label: 'Slavonice', value: 'Slavonice' },
  { label: 'Slavonski Brod', value: 'Slavonski Brod' },
  { label: 'Slavsk', value: 'Slavsk' },
  { label: 'Slavske', value: 'Slavske' },
  { label: 'Slavskiy Rayon', value: 'Slavskiy Rayon' },
  { label: 'Slavuta', value: 'Slavuta' },
  { label: 'Slavutych', value: 'Slavutych' },
  { label: 'Slavyanka', value: 'Slavyanka' },
  { label: 'Slavyanovo', value: 'Slavyanovo' },
  { label: 'Slavyansk-Na-Kubani', value: 'Slavyansk-Na-Kubani' },
  { label: 'Slavyanskoye', value: 'Slavyanskoye' },
  { label: 'Slavyansky Rayon', value: 'Slavyansky Rayon' },
  { label: 'Sława', value: 'Sława' },
  { label: 'Sławatycze', value: 'Sławatycze' },
  { label: 'Slawharad', value: 'Slawharad' },
  { label: 'Slawi', value: 'Slawi' },
  { label: 'Sławięcice', value: 'Sławięcice' },
  { label: 'Sławków', value: 'Sławków' },
  { label: 'Sławno', value: 'Sławno' },
  { label: 'Sławno', value: 'Sławno' },
  { label: 'Sławoborze', value: 'Sławoborze' },
  { label: 'Slayton', value: 'Slayton' },
  { label: 'Sleaford', value: 'Sleaford' },
  { label: 'Sleen', value: 'Sleen' },
  { label: 'Sleepy Eye', value: 'Sleepy Eye' },
  { label: 'Sleepy Hollow', value: 'Sleepy Hollow' },
  { label: 'Sleepy Hollow', value: 'Sleepy Hollow' },
  { label: 'Sleepy Hollow', value: 'Sleepy Hollow' },
  { label: 'Sleepy Hollow', value: 'Sleepy Hollow' },
  { label: 'Sleights', value: 'Sleights' },
  { label: 'Ślemień', value: 'Ślemień' },
  { label: 'Slepče', value: 'Slepče' },
  { label: 'Ślesin', value: 'Ślesin' },
  { label: 'Ślesin', value: 'Ślesin' },
  { label: 'Slidell', value: 'Slidell' },
  { label: 'Sliedrecht', value: 'Sliedrecht' },
  { label: 'Sliema', value: 'Sliema' },
  { label: 'Sligo', value: 'Sligo' },
  { label: 'Sligoville', value: 'Sligoville' },
  { label: 'Slikkerveer', value: 'Slikkerveer' },
  { label: 'Slimnic', value: 'Slimnic' },
  { label: 'Şlinfah', value: 'Şlinfah' },
  { label: 'Slinfold', value: 'Slinfold' },
  { label: 'Slinger', value: 'Slinger' },
  { label: 'Slipe', value: 'Slipe' },
  { label: 'Slippery Rock', value: 'Slippery Rock' },
  { label: 'Sliven', value: 'Sliven' },
  { label: 'Slivileşti', value: 'Slivileşti' },
  { label: 'Slivnitsa', value: 'Slivnitsa' },
  { label: 'Slivno', value: 'Slivno' },
  { label: 'Slivo Pole', value: 'Slivo Pole' },
  { label: 'Śliwice', value: 'Śliwice' },
  { label: 'Sloan', value: 'Sloan' },
  { label: 'Sloatsburg', value: 'Sloatsburg' },
  { label: 'Slobidka', value: 'Slobidka' },
  { label: 'Sloboda', value: 'Sloboda' },
  { label: 'Sloboda', value: 'Sloboda' },
  { label: 'Slobodka', value: 'Slobodka' },
  { label: 'Slobodnica', value: 'Slobodnica' },
  { label: 'Slobodskoy', value: 'Slobodskoy' },
  { label: 'Slobodskoy Rayon', value: 'Slobodskoy Rayon' },
  { label: 'Slobozhans’Ke', value: 'Slobozhans’Ke' },
  { label: 'Slobozia', value: 'Slobozia' },
  { label: 'Slobozia', value: 'Slobozia' },
  { label: 'Slobozia', value: 'Slobozia' },
  { label: 'Slobozia', value: 'Slobozia' },
  { label: 'Slobozia', value: 'Slobozia' },
  { label: 'Slobozia', value: 'Slobozia' },
  { label: 'Slobozia', value: 'Slobozia' },
  { label: 'Slobozia Bradului', value: 'Slobozia Bradului' },
  { label: 'Slobozia Conachi', value: 'Slobozia Conachi' },
  { label: 'Slobozia Moara', value: 'Slobozia Moara' },
  { label: 'Slobozia-Câmpineanca', value: 'Slobozia-Câmpineanca' },
  { label: 'Slobozia-Ciorăşti', value: 'Slobozia-Ciorăşti' },
  { label: 'Slobozia-Mândra', value: 'Slobozia-Mândra' },
  { label: 'Slochteren', value: 'Slochteren' },
  { label: 'Slocomb', value: 'Slocomb' },
  { label: 'Słomniki', value: 'Słomniki' },
  { label: 'Slon', value: 'Slon' },
  { label: 'Slonim', value: 'Slonim' },
  { label: 'Słońsk', value: 'Słońsk' },
  { label: 'Slootdorp', value: 'Slootdorp' },
  { label: 'Slope County', value: 'Slope County' },
  { label: 'Słopnice', value: 'Słopnice' },
  { label: 'Sloten', value: 'Sloten' },
  { label: 'Sloterdijk', value: 'Sloterdijk' },
  { label: 'Slotermeer', value: 'Slotermeer' },
  { label: 'Slotervaart (Former Borough)', value: 'Slotervaart (Former Borough)' },
  { label: 'Słotowa', value: 'Słotowa' },
  { label: 'Slottsbron', value: 'Slottsbron' },
  { label: 'Slough', value: 'Slough' },
  { label: 'Slov`Yanoserbsk', value: 'Slov`Yanoserbsk' },
  { label: 'Slovechne', value: 'Slovechne' },
  { label: 'Slovenj Gradec', value: 'Slovenj Gradec' },
  { label: 'Slovenska Bistrica', value: 'Slovenska Bistrica' },
  { label: 'Slovenske Konjice', value: 'Slovenske Konjice' },
  { label: 'Slovenski Javornik', value: 'Slovenski Javornik' },
  { label: 'Sloviansk', value: 'Sloviansk' },
  { label: 'Sloviansk Raion', value: 'Sloviansk Raion' },
  { label: 'Słubice', value: 'Słubice' },
  { label: 'Słubice', value: 'Słubice' },
  { label: 'Sluderno', value: 'Sluderno' },
  { label: 'Sluis', value: 'Sluis' },
  { label: 'Sluiskil', value: 'Sluiskil' },
  { label: 'Sluisoord', value: 'Sluisoord' },
  { label: 'Šluknov', value: 'Šluknov' },
  { label: 'Słupca', value: 'Słupca' },
  { label: 'Słupia', value: 'Słupia' },
  { label: 'Słupia', value: 'Słupia' },
  { label: 'Słupia Pod Kępnem', value: 'Słupia Pod Kępnem' },
  { label: 'Słupiec', value: 'Słupiec' },
  { label: 'Słupno', value: 'Słupno' },
  { label: 'Słupsk', value: 'Słupsk' },
  { label: 'Slušovice', value: 'Slušovice' },
  { label: 'Slutsk', value: 'Slutsk' },
  { label: 'Slutski Rayon', value: 'Slutski Rayon' },
  { label: 'Slyudyanka', value: 'Slyudyanka' },
  { label: 'Slyudyanskiy Rayon', value: 'Slyudyanskiy Rayon' },
  { label: 'Smach Mean Chey', value: 'Smach Mean Chey' },
  { label: 'Smackover', value: 'Smackover' },
  { label: 'Smala', value: 'Smala' },
  { label: 'Smålandsstenar', value: 'Smålandsstenar' },
  { label: 'Smalyavitski Rayon', value: 'Smalyavitski Rayon' },
  { label: 'Smara', value: 'Smara' },
  { label: 'Smarano', value: 'Smarano' },
  { label: 'Šmarca', value: 'Šmarca' },
  { label: 'Smârdan', value: 'Smârdan' },
  { label: 'Smârdan', value: 'Smârdan' },
  { label: 'Smârdan', value: 'Smârdan' },
  { label: 'Smārde', value: 'Smārde' },
  { label: 'Smârdioasa', value: 'Smârdioasa' },
  { label: 'Smarhon’', value: 'Smarhon’' },
  { label: 'Šmarje Pri Jelšah', value: 'Šmarje Pri Jelšah' },
  { label: 'Šmarje-Sap', value: 'Šmarje-Sap' },
  { label: 'Šmarjeta', value: 'Šmarjeta' },
  { label: 'Šmartno Ob Paki', value: 'Šmartno Ob Paki' },
  { label: 'Šmartno Pri Litiji', value: 'Šmartno Pri Litiji' },
  { label: 'Šmartno Pri Slovenj Gradcu', value: 'Šmartno Pri Slovenj Gradcu' },
  { label: 'Smarves', value: 'Smarves' },
  { label: 'Smečno', value: 'Smečno' },
  { label: 'Smedby', value: 'Smedby' },
  { label: 'Smedby', value: 'Smedby' },
  { label: 'Smederevo', value: 'Smederevo' },
  { label: 'Smederevska Palanka', value: 'Smederevska Palanka' },
  { label: 'Smedjebacken', value: 'Smedjebacken' },
  { label: 'Smeeni', value: 'Smeeni' },
  { label: 'Smęgorzów', value: 'Smęgorzów' },
  { label: 'Smerillo', value: 'Smerillo' },
  { label: 'Smethport', value: 'Smethport' },
  { label: 'Smethwick', value: 'Smethwick' },
  { label: 'Smętowo Graniczne', value: 'Smętowo Graniczne' },
  { label: 'Smidary', value: 'Smidary' },
  { label: 'Smidovich', value: 'Smidovich' },
  { label: 'Smidovichskiy Rayon', value: 'Smidovichskiy Rayon' },
  { label: 'Śmigiel', value: 'Śmigiel' },
  { label: 'Smila', value: 'Smila' },
  { label: 'Smilavichy', value: 'Smilavichy' },
  { label: 'Śmiłowo', value: 'Śmiłowo' },
  { label: 'Smiltene', value: 'Smiltene' },
  { label: 'Smimou', value: 'Smimou' },
  { label: 'Smínthi', value: 'Smínthi' },
  { label: 'Smiřice', value: 'Smiřice' },
  { label: 'Smirna', value: 'Smirna' },
  { label: 'Smirnovo', value: 'Smirnovo' },
  { label: 'Smirnykh', value: 'Smirnykh' },
  { label: 'Smith', value: 'Smith' },
  { label: 'Smith Center', value: 'Smith Center' },
  { label: 'Smith County', value: 'Smith County' },
  { label: 'Smith County', value: 'Smith County' },
  { label: 'Smith County', value: 'Smith County' },
  { label: 'Smith County', value: 'Smith County' },
  { label: 'Smith Mills', value: 'Smith Mills' },
  { label: 'Smith Valley', value: 'Smith Valley' },
  { label: 'Smithers', value: 'Smithers' },
  { label: 'Smithfield', value: 'Smithfield' },
  { label: 'Smithfield', value: 'Smithfield' },
  { label: 'Smithfield', value: 'Smithfield' },
  { label: 'Smithfield', value: 'Smithfield' },
  { label: 'Smithfield', value: 'Smithfield' },
  { label: 'Smithfield', value: 'Smithfield' },
  { label: 'Smithfield', value: 'Smithfield' },
  { label: 'Smithfield Plains', value: 'Smithfield Plains' },
  { label: 'Smithland', value: 'Smithland' },
  { label: 'Smiths Falls', value: 'Smiths Falls' },
  { label: 'Smiths Lake', value: 'Smiths Lake' },
  { label: 'Smiths Station', value: 'Smiths Station' },
  { label: 'Smithsburg', value: 'Smithsburg' },
  { label: 'Smithton', value: 'Smithton' },
  { label: 'Smithton', value: 'Smithton' },
  { label: 'Smithton', value: 'Smithton' },
  { label: 'Smithtown', value: 'Smithtown' },
  { label: 'Smithville', value: 'Smithville' },
  { label: 'Smithville', value: 'Smithville' },
  { label: 'Smithville', value: 'Smithville' },
  { label: 'Smithville', value: 'Smithville' },
  { label: 'Smithville', value: 'Smithville' },
  { label: 'Smithville', value: 'Smithville' },
  { label: 'Smithville-Sanders', value: 'Smithville-Sanders' },
  { label: 'Smögen', value: 'Smögen' },
  { label: 'Smoke Rise', value: 'Smoke Rise' },
  { label: 'Smokey Point', value: 'Smokey Point' },
  { label: 'Smokvica', value: 'Smokvica' },
  { label: 'Smoky Lake', value: 'Smoky Lake' },
  { label: 'Smøla', value: 'Smøla' },
  { label: 'Smołdzino', value: 'Smołdzino' },
  { label: 'Smolec', value: 'Smolec' },
  { label: 'Smolenice', value: 'Smolenice' },
  { label: 'Smolenka', value: 'Smolenka' },
  { label: 'Smolenshchina', value: 'Smolenshchina' },
  { label: 'Smolensk', value: 'Smolensk' },
  { label: 'Smolenskaya', value: 'Smolenskaya' },
  { label: 'Smolenskiy Rayon', value: 'Smolenskiy Rayon' },
  { label: 'Smolenskoye', value: 'Smolenskoye' },
  { label: 'Smolino', value: 'Smolino' },
  { label: 'Smolovivshchina', value: 'Smolovivshchina' },
  { label: 'Smolyan', value: 'Smolyan' },
  { label: 'Smolyaninovo', value: 'Smolyaninovo' },
  { label: 'Smørumnedre', value: 'Smørumnedre' },
  { label: 'Smotrych', value: 'Smotrych' },
  { label: 'Smržice', value: 'Smržice' },
  { label: 'Smržovka', value: 'Smržovka' },
  { label: 'Smulţi', value: 'Smulţi' },
  { label: 'Smyga', value: 'Smyga' },
  { label: 'Smygehamn', value: 'Smygehamn' },
  { label: 'Smyków', value: 'Smyków' },
  { label: 'Smyrna', value: 'Smyrna' },
  { label: 'Smyrna', value: 'Smyrna' },
  { label: 'Smyrna', value: 'Smyrna' },
  { label: 'Smyshlyayevka', value: 'Smyshlyayevka' },
  { label: 'Smyth County', value: 'Smyth County' },
  { label: 'Smythes Creek', value: 'Smythes Creek' },
  { label: 'Smythesdale', value: 'Smythesdale' },
  { label: 'Snåase', value: 'Snåase' },
  { label: 'Snåase - Snåsa', value: 'Snåase - Snåsa' },
  { label: 'Snæfellsbær', value: 'Snæfellsbær' },
  { label: 'Snagov', value: 'Snagov' },
  { label: 'Snaith', value: 'Snaith' },
  { label: 'Sneads', value: 'Sneads' },
  { label: 'Sneads Ferry', value: 'Sneads Ferry' },
  { label: 'Sneedville', value: 'Sneedville' },
  { label: 'Sneek', value: 'Sneek' },
  { label: 'Snegiri', value: 'Snegiri' },
  { label: 'Snejbjerg', value: 'Snejbjerg' },
  { label: 'Snellville', value: 'Snellville' },
  { label: 'Snettisham', value: 'Snettisham' },
  { label: 'Snezhinsk', value: 'Snezhinsk' },
  { label: 'Snezhnogorsk', value: 'Snezhnogorsk' },
  { label: 'Snezhnogorsk', value: 'Snezhnogorsk' },
  { label: 'Śniadowo', value: 'Śniadowo' },
  { label: 'Sniatyn', value: 'Sniatyn' },
  { label: 'Snihurivka', value: 'Snihurivka' },
  { label: 'Snillfjord', value: 'Snillfjord' },
  { label: 'Snina', value: 'Snina' },
  { label: 'Snizhne', value: 'Snizhne' },
  { label: 'Snodland', value: 'Snodland' },
  { label: 'Snoghøj', value: 'Snoghøj' },
  { label: 'Snohomish', value: 'Snohomish' },
  { label: 'Snohomish County', value: 'Snohomish County' },
  { label: 'Snoqualmie', value: 'Snoqualmie' },
  { label: 'Snovskyy Rayon', value: 'Snovskyy Rayon' },
  { label: 'Snow', value: 'Snow' },
  { label: 'Snow Hill', value: 'Snow Hill' },
  { label: 'Snow Hill', value: 'Snow Hill' },
  { label: 'Snow Hill', value: 'Snow Hill' },
  { label: 'Snowdon', value: 'Snowdon' },
  { label: 'Snowflake', value: 'Snowflake' },
  { label: 'Snowmass Village', value: 'Snowmass Village' },
  { label: 'Snug', value: 'Snug' },
  { label: 'Snuol', value: 'Snuol' },
  { label: 'Snyatyns’Kyy Rayon', value: 'Snyatyns’Kyy Rayon' },
  { label: 'Snyder', value: 'Snyder' },
  { label: 'Snyder', value: 'Snyder' },
  { label: 'Snyder County', value: 'Snyder County' },
  { label: 'Snyderville', value: 'Snyderville' },
  { label: 'So‘X Tumani', value: 'So‘X Tumani' },
  { label: 'Soacha', value: 'Soacha' },
  { label: 'Soalkuchi', value: 'Soalkuchi' },
  { label: 'Soap Lake', value: 'Soap Lake' },
  { label: 'Șoard', value: 'Șoard' },
  { label: 'Şoarş', value: 'Şoarş' },
  { label: 'Soatá', value: 'Soatá' },
  { label: 'Soave', value: 'Soave' },
  { label: 'Soave', value: 'Soave' },
  { label: 'Soba', value: 'Soba' },
  { label: 'Soba', value: 'Soba' },
  { label: 'Sober', value: 'Sober' },
  { label: 'Soběslav', value: 'Soběslav' },
  { label: 'Sobhodero', value: 'Sobhodero' },
  { label: 'Sobienie Jeziory', value: 'Sobienie Jeziory' },
  { label: 'Sobinka', value: 'Sobinka' },
  { label: 'Sobinskiy Rayon', value: 'Sobinskiy Rayon' },
  { label: 'Sobków', value: 'Sobków' },
  { label: 'Sobolevskiy Rayon', value: 'Sobolevskiy Rayon' },
  { label: 'Sobolew', value: 'Sobolew' },
  { label: 'Sobolivka', value: 'Sobolivka' },
  { label: 'Soboth', value: 'Soboth' },
  { label: 'Sobotín', value: 'Sobotín' },
  { label: 'Sobotka', value: 'Sobotka' },
  { label: 'Sobótka', value: 'Sobótka' },
  { label: 'Sobradiel', value: 'Sobradiel' },
  { label: 'Sobradillo', value: 'Sobradillo' },
  { label: 'Sobradinho', value: 'Sobradinho' },
  { label: 'Sobradinho', value: 'Sobradinho' },
  { label: 'Sobrado', value: 'Sobrado' },
  { label: 'Sobrado', value: 'Sobrado' },
  { label: 'Sobrado', value: 'Sobrado' },
  { label: 'Sobrado', value: 'Sobrado' },
  { label: 'Sobral', value: 'Sobral' },
  { label: 'Sobral Da Abelheira', value: 'Sobral Da Abelheira' },
  { label: 'Sobral Da Serra', value: 'Sobral Da Serra' },
  { label: 'Sobral De Monte Agraço', value: 'Sobral De Monte Agraço' },
  { label: 'Sobral De Monte Agraço', value: 'Sobral De Monte Agraço' },
  { label: 'Sobral Pichorro', value: 'Sobral Pichorro' },
  { label: 'Sobrália', value: 'Sobrália' },
  { label: 'Sobralinho', value: 'Sobralinho' },
  { label: 'Sobralinho', value: 'Sobralinho' },
  { label: 'Sobrance', value: 'Sobrance' },
  { label: 'Sobreda', value: 'Sobreda' },
  { label: 'Sobreira', value: 'Sobreira' },
  { label: 'Sobremunt', value: 'Sobremunt' },
  { label: 'Sobrescobio', value: 'Sobrescobio' },
  { label: 'Sobue', value: 'Sobue' },
  { label: 'Sóc Trăng', value: 'Sóc Trăng' },
  { label: 'Soca', value: 'Soca' },
  { label: 'Socastee', value: 'Socastee' },
  { label: 'Socavón (El Carmen)', value: 'Socavón (El Carmen)' },
  { label: 'Soccavo', value: 'Soccavo' },
  { label: 'Soccher-Paiane-Casan-Arsie', value: 'Soccher-Paiane-Casan-Arsie' },
  { label: 'Socetu', value: 'Socetu' },
  { label: 'Socha', value: 'Socha' },
  { label: 'Sochaczew', value: 'Sochaczew' },
  { label: 'Söchau', value: 'Söchau' },
  { label: 'Sochaux', value: 'Sochaux' },
  { label: 'Sochi', value: 'Sochi' },
  { label: 'Sochiapa', value: 'Sochiapa' },
  { label: 'Sochocin', value: 'Sochocin' },
  { label: 'Sochós', value: 'Sochós' },
  { label: 'Söchtenau', value: 'Söchtenau' },
  { label: 'Soci', value: 'Soci' },
  { label: 'Soci', value: 'Soci' },
  { label: 'Social Circle', value: 'Social Circle' },
  { label: 'Sociedad', value: 'Sociedad' },
  { label: 'Society Hill', value: 'Society Hill' },
  { label: 'Socodor', value: 'Socodor' },
  { label: 'Socol', value: 'Socol' },
  { label: 'Socoltenango', value: 'Socoltenango' },
  { label: 'Socolu De Câmpie', value: 'Socolu De Câmpie' },
  { label: 'Socond', value: 'Socond' },
  { label: 'Soconusco', value: 'Soconusco' },
  { label: 'Soconusco', value: 'Soconusco' },
  { label: 'Socorro', value: 'Socorro' },
  { label: 'Socorro', value: 'Socorro' },
  { label: 'Socorro', value: 'Socorro' },
  { label: 'Socorro', value: 'Socorro' },
  { label: 'Socorro', value: 'Socorro' },
  { label: 'Socorro', value: 'Socorro' },
  { label: 'Socorro', value: 'Socorro' },
  { label: 'Socorro', value: 'Socorro' },
  { label: 'Socorro County', value: 'Socorro County' },
  { label: 'Socorro Do Piauí', value: 'Socorro Do Piauí' },
  { label: 'Socotá', value: 'Socotá' },
  { label: 'Socovos', value: 'Socovos' },
  { label: 'Socuéllamos', value: 'Socuéllamos' },
  { label: 'Socx', value: 'Socx' },
  { label: 'Soda Bay', value: 'Soda Bay' },
  { label: 'Soda Springs', value: 'Soda Springs' },
  { label: 'Sodankylä', value: 'Sodankylä' },
  { label: 'Soddì', value: 'Soddì' },
  { label: 'Soddy-Daisy', value: 'Soddy-Daisy' },
  { label: 'Sodegaura-Shi', value: 'Sodegaura-Shi' },
  { label: 'Södel', value: 'Södel' },
  { label: 'Söderfors', value: 'Söderfors' },
  { label: 'Söderfors', value: 'Söderfors' },
  { label: 'Söderhamn', value: 'Söderhamn' },
  { label: 'Söderköping', value: 'Söderköping' },
  { label: 'Södermalm', value: 'Södermalm' },
  { label: 'Soderstorf', value: 'Soderstorf' },
  { label: 'Södertälje', value: 'Södertälje' },
  { label: 'Sodhra', value: 'Sodhra' },
  { label: 'Södingberg', value: 'Södingberg' },
  { label: 'Sodo', value: 'Sodo' },
  { label: 'Šodolovci', value: 'Šodolovci' },
  { label: 'Sodomeni', value: 'Sodomeni' },
  { label: 'Sodpur', value: 'Sodpur' },
  { label: 'Södra Sandby', value: 'Södra Sandby' },
  { label: 'Södra Sandby', value: 'Södra Sandby' },
  { label: 'Södra Sunderbyn', value: 'Södra Sunderbyn' },
  { label: 'Södra Vi', value: 'Södra Vi' },
  { label: 'Sodražica', value: 'Sodražica' },
  { label: 'Sodus', value: 'Sodus' },
  { label: 'Soe', value: 'Soe' },
  { label: 'Soeng Sang', value: 'Soeng Sang' },
  { label: 'Soerendonk', value: 'Soerendonk' },
  { label: 'Soest', value: 'Soest' },
  { label: 'Soest', value: 'Soest' },
  { label: 'Soestdijk', value: 'Soestdijk' },
  { label: 'Sofádes', value: 'Sofádes' },
  { label: 'Sofia', value: 'Sofia' },
  { label: 'Sofifi', value: 'Sofifi' },
  { label: 'Sofiivka', value: 'Sofiivka' },
  { label: 'Sofikón', value: 'Sofikón' },
  { label: 'Sofiyivka', value: 'Sofiyivka' },
  { label: 'Sofiyivska Borschagivka', value: 'Sofiyivska Borschagivka' },
  { label: 'Sofo-Birnin-Gwari', value: 'Sofo-Birnin-Gwari' },
  { label: 'Sofrino', value: 'Sofrino' },
  { label: 'Şofronea', value: 'Şofronea' },
  { label: 'Søften', value: 'Søften' },
  { label: 'Sogamoso', value: 'Sogamoso' },
  { label: 'Sögel', value: 'Sögel' },
  { label: 'Soghad', value: 'Soghad' },
  { label: 'Sogliano Al Rubicone', value: 'Sogliano Al Rubicone' },
  { label: 'Sogliano Cavour', value: 'Sogliano Cavour' },
  { label: 'Soglio', value: 'Soglio' },
  { label: 'Sogndal', value: 'Sogndal' },
  { label: 'Søgne', value: 'Søgne' },
  { label: 'Sogod', value: 'Sogod' },
  { label: 'Sogod', value: 'Sogod' },
  { label: 'Sogod', value: 'Sogod' },
  { label: 'Sogod', value: 'Sogod' },
  { label: 'Sogod', value: 'Sogod' },
  { label: 'Söğüt', value: 'Söğüt' },
  { label: 'Söğütlü', value: 'Söğütlü' },
  { label: 'Sohag', value: 'Sohag' },
  { label: 'Sohagi', value: 'Sohagi' },
  { label: 'Sohagpur', value: 'Sohagpur' },
  { label: 'Soham', value: 'Soham' },
  { label: 'Sohar', value: 'Sohar' },
  { label: 'Sohatu', value: 'Sohatu' },
  { label: 'Sohbatpur', value: 'Sohbatpur' },
  { label: 'Sohe', value: 'Sohe' },
  { label: 'Sohland', value: 'Sohland' },
  { label: 'Sohland Am Rotstein', value: 'Sohland Am Rotstein' },
  { label: 'Söhlde', value: 'Söhlde' },
  { label: 'Sohna', value: 'Sohna' },
  { label: 'Sohodol', value: 'Sohodol' },
  { label: 'Sohodol', value: 'Sohodol' },
  { label: 'Sohodol', value: 'Sohodol' },
  { label: 'Sohren', value: 'Sohren' },
  { label: 'Sohrevard', value: 'Sohrevard' },
  { label: 'Soiano', value: 'Soiano' },
  { label: 'Soibada', value: 'Soibada' },
  { label: 'Soignies', value: 'Soignies' },
  { label: 'Soignolles-En-Brie', value: 'Soignolles-En-Brie' },
  { label: 'Şoimari', value: 'Şoimari' },
  { label: 'Şoimi', value: 'Şoimi' },
  { label: 'Şoimuş', value: 'Şoimuş' },
  { label: 'Șoimuș', value: 'Șoimuș' },
  { label: 'Soings-En-Sologne', value: 'Soings-En-Sologne' },
  { label: 'Soini', value: 'Soini' },
  { label: 'Soio', value: 'Soio' },
  { label: 'Soissons', value: 'Soissons' },
  { label: 'Soisy-Sous-Montmorency', value: 'Soisy-Sous-Montmorency' },
  { label: 'Soisy-Sur-École', value: 'Soisy-Sur-École' },
  { label: 'Soisy-Sur-Seine', value: 'Soisy-Sur-Seine' },
  { label: 'Sōja', value: 'Sōja' },
  { label: 'Sōja Shi', value: 'Sōja Shi' },
  { label: 'Sojas', value: 'Sojas' },
  { label: 'Sojat', value: 'Sojat' },
  { label: 'Sojitra', value: 'Sojitra' },
  { label: 'Sojuela', value: 'Sojuela' },
  { label: 'Sōka', value: 'Sōka' },
  { label: 'Sōka Shi', value: 'Sōka Shi' },
  { label: 'Sokal’', value: 'Sokal’' },
  { label: 'Sokaraja', value: 'Sokaraja' },
  { label: 'Sokcho', value: 'Sokcho' },
  { label: 'Sokcho-Si', value: 'Sokcho-Si' },
  { label: 'Söke', value: 'Söke' },
  { label: 'Sokehs Municipality', value: 'Sokehs Municipality' },
  { label: 'Sokhatska Balka', value: 'Sokhatska Balka' },
  { label: 'Sokhumi', value: 'Sokhumi' },
  { label: 'Sokndal', value: 'Sokndal' },
  { label: 'Soko', value: 'Soko' },
  { label: 'Sokodé', value: 'Sokodé' },
  { label: 'Sokol', value: 'Sokol' },
  { label: 'Sokol', value: 'Sokol' },
  { label: 'Sokol', value: 'Sokol' },
  { label: 'Sokol', value: 'Sokol' },
  { label: 'Sokol', value: 'Sokol' },
  { label: 'Sokol', value: 'Sokol' },
  { label: 'Sokol’Niki', value: 'Sokol’Niki' },
  { label: 'Sokol’Skiy Rayon', value: 'Sokol’Skiy Rayon' },
  { label: 'Sokol’Skoye', value: 'Sokol’Skoye' },
  { label: 'Sokolac', value: 'Sokolac' },
  { label: 'Sokolinoye', value: 'Sokolinoye' },
  { label: 'Sokółka', value: 'Sokółka' },
  { label: 'Sokolnice', value: 'Sokolnice' },
  { label: 'Sokolniki', value: 'Sokolniki' },
  { label: 'Sokolniki', value: 'Sokolniki' },
  { label: 'Sokolo', value: 'Sokolo' },
  { label: 'Sokolov', value: 'Sokolov' },
  { label: 'Sokolovica', value: 'Sokolovica' },
  { label: 'Sokolovo', value: 'Sokolovo' },
  { label: 'Sokolovo Brdo', value: 'Sokolovo Brdo' },
  { label: 'Sokolovo-Kundryuchenskiy', value: 'Sokolovo-Kundryuchenskiy' },
  { label: 'Sokolovskoye', value: 'Sokolovskoye' },
  { label: 'Sokolovyy', value: 'Sokolovyy' },
  { label: 'Sokołów Małopolski', value: 'Sokołów Małopolski' },
  { label: 'Sokołów Podlaski', value: 'Sokołów Podlaski' },
  { label: 'Sokoły', value: 'Sokoły' },
  { label: 'Sokone', value: 'Sokone' },
  { label: 'Sokoni', value: 'Sokoni' },
  { label: 'Sokoto', value: 'Sokoto' },
  { label: 'Sokulukskiy Rayon', value: 'Sokulukskiy Rayon' },
  { label: 'Sokur', value: 'Sokur' },
  { label: 'Sokyryany', value: 'Sokyryany' },
  { label: 'Sól', value: 'Sól' },
  { label: 'Sol’-Iletsk', value: 'Sol’-Iletsk' },
  { label: 'Sol’Tsy', value: 'Sol’Tsy' },
  { label: 'Sol’Vychegodsk', value: 'Sol’Vychegodsk' },
  { label: 'Sola', value: 'Sola' },
  { label: 'Sola', value: 'Sola' },
  { label: 'Solagna', value: 'Solagna' },
  { label: 'Solaize', value: 'Solaize' },
  { label: 'Solak', value: 'Solak' },
  { label: 'Solan', value: 'Solan' },
  { label: 'Solana', value: 'Solana' },
  { label: 'Solana', value: 'Solana' },
  { label: 'Solana', value: 'Solana' },
  { label: 'Solana Beach', value: 'Solana Beach' },
  { label: 'Solana De Ávila', value: 'Solana De Ávila' },
  { label: 'Solana De Los Barros', value: 'Solana De Los Barros' },
  { label: 'Solana De Rioalmar', value: 'Solana De Rioalmar' },
  { label: 'Solana Del Pino', value: 'Solana Del Pino' },
  { label: 'Solana La', value: 'Solana La' },
  { label: 'Solanas', value: 'Solanas' },
  { label: 'Solânea', value: 'Solânea' },
  { label: 'Solanillos Del Extremo', value: 'Solanillos Del Extremo' },
  { label: 'Solano', value: 'Solano' },
  { label: 'Solano', value: 'Solano' },
  { label: 'Solano', value: 'Solano' },
  { label: 'Solano County', value: 'Solano County' },
  { label: 'Solap', value: 'Solap' },
  { label: 'Solapur', value: 'Solapur' },
  { label: 'Solara', value: 'Solara' },
  { label: 'Solarana', value: 'Solarana' },
  { label: 'Solarino', value: 'Solarino' },
  { label: 'Solaro', value: 'Solaro' },
  { label: 'Solarolo', value: 'Solarolo' },
  { label: 'Solarolo', value: 'Solarolo' },
  { label: 'Solarolo Rainerio', value: 'Solarolo Rainerio' },
  { label: 'Solarussa', value: 'Solarussa' },
  { label: 'Solbiate', value: 'Solbiate' },
  { label: 'Solbiate Arno', value: 'Solbiate Arno' },
  { label: 'Solbiate Olona', value: 'Solbiate Olona' },
  { label: 'Solbjerg', value: 'Solbjerg' },
  { label: 'Solca', value: 'Solca' },
  { label: 'Solčava', value: 'Solčava' },
  { label: 'Şoldăneşti', value: 'Şoldăneşti' },
  { label: 'Soldano', value: 'Soldano' },
  { label: 'Şoldanu', value: 'Şoldanu' },
  { label: 'Soldato-Aleksandrovskoye', value: 'Soldato-Aleksandrovskoye' },
  { label: 'Soldatskaya', value: 'Soldatskaya' },
  { label: 'Sölden', value: 'Sölden' },
  { label: 'Sölden', value: 'Sölden' },
  { label: 'Soldiers Hill', value: 'Soldiers Hill' },
  { label: 'Soldiers Hill', value: 'Soldiers Hill' },
  { label: 'Soldiers Point', value: 'Soldiers Point' },
  { label: 'Soldotna', value: 'Soldotna' },
  { label: 'Solec Kujawski', value: 'Solec Kujawski' },
  { label: 'Solec Nad Wisłą', value: 'Solec Nad Wisłą' },
  { label: 'Solec-Zdrój', value: 'Solec-Zdrój' },
  { label: 'Soledad', value: 'Soledad' },
  { label: 'Soledad', value: 'Soledad' },
  { label: 'Soledad', value: 'Soledad' },
  { label: 'Soledad', value: 'Soledad' },
  { label: 'Soledad', value: 'Soledad' },
  { label: 'Soledad Atzompa', value: 'Soledad Atzompa' },
  { label: 'Soledad De Doblado', value: 'Soledad De Doblado' },
  { label: 'Soledad De Graciano Sánchez', value: 'Soledad De Graciano Sánchez' },
  { label: 'Soledad Etla', value: 'Soledad Etla' },
  { label: 'Soledad Morelos', value: 'Soledad Morelos' },
  { label: 'Soledad Nueva', value: 'Soledad Nueva' },
  { label: 'Soledad Salinas', value: 'Soledad Salinas' },
  { label: 'Soledade', value: 'Soledade' },
  { label: 'Soledade', value: 'Soledade' },
  { label: 'Soledade De Minas', value: 'Soledade De Minas' },
  { label: 'Soledar', value: 'Soledar' },
  { label: 'Soleh Bon', value: 'Soleh Bon' },
  { label: 'Soleminis', value: 'Soleminis' },
  { label: 'Solera De Gabaldón', value: 'Solera De Gabaldón' },
  { label: 'Soleràs El', value: 'Soleràs El' },
  { label: 'Solero', value: 'Solero' },
  { label: 'Solers', value: 'Solers' },
  { label: 'Solesino', value: 'Solesino' },
  { label: 'Solesmes', value: 'Solesmes' },
  { label: 'Solesmes', value: 'Solesmes' },
  { label: 'Soleşti', value: 'Soleşti' },
  { label: 'Soleto', value: 'Soleto' },
  { label: 'Soletskiy Rayon', value: 'Soletskiy Rayon' },
  { label: 'Soleuvre', value: 'Soleuvre' },
  { label: 'Solferino', value: 'Solferino' },
  { label: 'Solginskiy', value: 'Solginskiy' },
  { label: 'Solgne', value: 'Solgne' },
  { label: 'Solhan', value: 'Solhan' },
  { label: 'Solidão', value: 'Solidão' },
  { label: 'Soliedra', value: 'Soliedra' },
  { label: 'Soliera', value: 'Soliera' },
  { label: 'Soliers', value: 'Soliers' },
  { label: 'Soligalich', value: 'Soligalich' },
  { label: 'Soligalichskiy Rayon', value: 'Soligalichskiy Rayon' },
  { label: 'Solignac', value: 'Solignac' },
  { label: 'Solignac-Sur-Loire', value: 'Solignac-Sur-Loire' },
  { label: 'Solignano', value: 'Solignano' },
  { label: 'Solignano Nuovo', value: 'Solignano Nuovo' },
  { label: 'Solihull', value: 'Solihull' },
  { label: 'Solikamsk', value: 'Solikamsk' },
  { label: 'Solim', value: 'Solim' },
  { label: 'Solima', value: 'Solima' },
  { label: 'Soliman', value: 'Soliman' },
  { label: 'Solin', value: 'Solin' },
  { label: 'Solina', value: 'Solina' },
  { label: 'Solingen', value: 'Solingen' },
  { label: 'Solís De Mataojo', value: 'Solís De Mataojo' },
  { label: 'Solita', value: 'Solita' },
  { label: 'Solivella', value: 'Solivella' },
  { label: 'Soljani', value: 'Soljani' },
  { label: 'Solkan', value: 'Solkan' },
  { label: 'Söll', value: 'Söll' },
  { label: 'Sollana', value: 'Sollana' },
  { label: 'Sollebrunn', value: 'Sollebrunn' },
  { label: 'Sollefteå', value: 'Sollefteå' },
  { label: 'Sollenau', value: 'Sollenau' },
  { label: 'Sollentuna', value: 'Sollentuna' },
  { label: 'Sóller', value: 'Sóller' },
  { label: 'Solliès-Pont', value: 'Solliès-Pont' },
  { label: 'Solliès-Toucas', value: 'Solliès-Toucas' },
  { label: 'Solliès-Ville', value: 'Solliès-Ville' },
  { label: 'Sollstedt', value: 'Sollstedt' },
  { label: 'Solms', value: 'Solms' },
  { label: 'Solna', value: 'Solna' },
  { label: 'Solnechnaya Dolina', value: 'Solnechnaya Dolina' },
  { label: 'Solnechniy', value: 'Solnechniy' },
  { label: 'Solnechniy', value: 'Solnechniy' },
  { label: 'Solnechnogorsk', value: 'Solnechnogorsk' },
  { label: 'Solnechnogorskiy Rayon', value: 'Solnechnogorskiy Rayon' },
  { label: 'Solnechnogorskoye', value: 'Solnechnogorskoye' },
  { label: 'Solnechnoye', value: 'Solnechnoye' },
  { label: 'Solnechnoye', value: 'Solnechnoye' },
  { label: 'Solnechnoye', value: 'Solnechnoye' },
  { label: 'Solnechnyy', value: 'Solnechnyy' },
  { label: 'Solnechnyy', value: 'Solnechnyy' },
  { label: 'Solnechnyy', value: 'Solnechnyy' },
  { label: 'Solnhofen', value: 'Solnhofen' },
  { label: 'Solnice', value: 'Solnice' },
  { label: 'Solntsevo', value: 'Solntsevo' },
  { label: 'Solntsevo', value: 'Solntsevo' },
  { label: 'Solntsevskiy Rayon', value: 'Solntsevskiy Rayon' },
  { label: 'Solo', value: 'Solo' },
  { label: 'Solocma', value: 'Solocma' },
  { label: 'Solofra', value: 'Solofra' },
  { label: 'Solok', value: 'Solok' },
  { label: 'Sololá', value: 'Sololá' },
  { label: 'Soloma', value: 'Soloma' },
  { label: 'Solomenskoye', value: 'Solomenskoye' },
  { label: 'Solomianka Rayon', value: 'Solomianka Rayon' },
  { label: 'Solomon', value: 'Solomon' },
  { label: 'Solomons', value: 'Solomons' },
  { label: 'Solomontown', value: 'Solomontown' },
  { label: 'Solon', value: 'Solon' },
  { label: 'Solon', value: 'Solon' },
  { label: 'Solone', value: 'Solone' },
  { label: 'Solone Raion', value: 'Solone Raion' },
  { label: 'Soloneshenskiy Rayon', value: 'Soloneshenskiy Rayon' },
  { label: 'Soloneshnoye', value: 'Soloneshnoye' },
  { label: 'Soloneț', value: 'Soloneț' },
  { label: 'Solonghello', value: 'Solonghello' },
  { label: 'Solonka', value: 'Solonka' },
  { label: 'Solonópole', value: 'Solonópole' },
  { label: 'Solonţ', value: 'Solonţ' },
  { label: 'Solonytsivka', value: 'Solonytsivka' },
  { label: 'Solopaca', value: 'Solopaca' },
  { label: 'Solórzano', value: 'Solórzano' },
  { label: 'Solosancho', value: 'Solosancho' },
  { label: 'Solosolo', value: 'Solosolo' },
  { label: 'Solosuchiapa', value: 'Solosuchiapa' },
  { label: 'Solotcha', value: 'Solotcha' },
  { label: 'Solothurn', value: 'Solothurn' },
  { label: 'Solotvyn', value: 'Solotvyn' },
  { label: 'Solotvyno', value: 'Solotvyno' },
  { label: 'Solovăstru', value: 'Solovăstru' },
  { label: 'Solovjevsk', value: 'Solovjevsk' },
  { label: 'Solre-Le-Château', value: 'Solre-Le-Château' },
  { label: 'Solrød', value: 'Solrød' },
  { label: 'Solrød Kommune', value: 'Solrød Kommune' },
  { label: 'Solrød Strand', value: 'Solrød Strand' },
  { label: 'Solsona', value: 'Solsona' },
  { label: 'Solsona', value: 'Solsona' },
  { label: 'Solsona', value: 'Solsona' },
  { label: 'Solt', value: 'Solt' },
  { label: 'Šolta', value: 'Šolta' },
  { label: 'Soltanabad', value: 'Soltanabad' },
  { label: 'Soltanieh', value: 'Soltanieh' },
  { label: 'Soltanshahr', value: 'Soltanshahr' },
  { label: 'Soltau', value: 'Soltau' },
  { label: 'Soltendieck', value: 'Soltendieck' },
  { label: 'Soltepec', value: 'Soltepec' },
  { label: 'Solto Collina', value: 'Solto Collina' },
  { label: 'Solton', value: 'Solton' },
  { label: 'Soltonskiy Rayon', value: 'Soltonskiy Rayon' },
  { label: 'Soltvadkert', value: 'Soltvadkert' },
  { label: 'Sołtysowice', value: 'Sołtysowice' },
  { label: 'Solukhumbu', value: 'Solukhumbu' },
  { label: 'Solulan', value: 'Solulan' },
  { label: 'Solund', value: 'Solund' },
  { label: 'Soluno-Dmitriyevskoye', value: 'Soluno-Dmitriyevskoye' },
  { label: 'Solvang', value: 'Solvang' },
  { label: 'Solvay', value: 'Solvay' },
  { label: 'Sölvesborg', value: 'Sölvesborg' },
  { label: 'Solwezi', value: 'Solwezi' },
  { label: 'Soly', value: 'Soly' },
  { label: 'Solyanka', value: 'Solyanka' },
  { label: 'Solymár', value: 'Solymár' },
  { label: 'Solza', value: 'Solza' },
  { label: 'Soma', value: 'Soma' },
  { label: 'Soma', value: 'Soma' },
  { label: 'Sōma', value: 'Sōma' },
  { label: 'Sōma Shi', value: 'Sōma Shi' },
  { label: 'Somaglia', value: 'Somaglia' },
  { label: 'Somain', value: 'Somain' },
  { label: 'Somanda', value: 'Somanda' },
  { label: 'Somano', value: 'Somano' },
  { label: 'Sombor', value: 'Sombor' },
  { label: 'Sombreffe', value: 'Sombreffe' },
  { label: 'Sombrio', value: 'Sombrio' },
  { label: 'Şomcuta Mare', value: 'Şomcuta Mare' },
  { label: 'Someh Sara', value: 'Someh Sara' },
  { label: 'Somerdale', value: 'Somerdale' },
  { label: 'Someren', value: 'Someren' },
  { label: 'Someren-Eind', value: 'Someren-Eind' },
  { label: 'Somero', value: 'Somero' },
  { label: 'Somers', value: 'Somers' },
  { label: 'Somers', value: 'Somers' },
  { label: 'Somers', value: 'Somers' },
  { label: 'Somers', value: 'Somers' },
  { label: 'Somers Point', value: 'Somers Point' },
  { label: 'Somersby', value: 'Somersby' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset', value: 'Somerset' },
  { label: 'Somerset County', value: 'Somerset County' },
  { label: 'Somerset County', value: 'Somerset County' },
  { label: 'Somerset County', value: 'Somerset County' },
  { label: 'Somerset County', value: 'Somerset County' },
  { label: 'Somerset East', value: 'Somerset East' },
  { label: 'Somersham', value: 'Somersham' },
  { label: 'Somersworth', value: 'Somersworth' },
  { label: 'Somerton', value: 'Somerton' },
  { label: 'Somerton', value: 'Somerton' },
  { label: 'Somerton', value: 'Somerton' },
  { label: 'Somerton Park', value: 'Somerton Park' },
  { label: 'Sõmeru', value: 'Sõmeru' },
  { label: 'Somervell County', value: 'Somervell County' },
  { label: 'Somerville', value: 'Somerville' },
  { label: 'Somerville', value: 'Somerville' },
  { label: 'Somerville', value: 'Somerville' },
  { label: 'Somerville', value: 'Somerville' },
  { label: 'Somerville', value: 'Somerville' },
  { label: 'Somerville', value: 'Somerville' },
  { label: 'Someshwar', value: 'Someshwar' },
  { label: 'Someş-Odorhei', value: 'Someş-Odorhei' },
  { label: 'Someș-Uileac', value: 'Someș-Uileac' },
  { label: 'Somié', value: 'Somié' },
  { label: 'Somiedo', value: 'Somiedo' },
  { label: 'Somita', value: 'Somita' },
  { label: 'Somma Lombardo', value: 'Somma Lombardo' },
  { label: 'Somma Vesuviana', value: 'Somma Vesuviana' },
  { label: 'Sommacampagna', value: 'Sommacampagna' },
  { label: 'Sommariva Del Bosco', value: 'Sommariva Del Bosco' },
  { label: 'Sommariva Perno', value: 'Sommariva Perno' },
  { label: 'Sommatino', value: 'Sommatino' },
  { label: 'Somme', value: 'Somme' },
  { label: 'Somme-Leuze', value: 'Somme-Leuze' },
  { label: 'Sommerach', value: 'Sommerach' },
  { label: 'Sömmerda', value: 'Sömmerda' },
  { label: 'Sommerein', value: 'Sommerein' },
  { label: 'Sommerhausen', value: 'Sommerhausen' },
  { label: 'Sommerkahl', value: 'Sommerkahl' },
  { label: 'Sommersdorf', value: 'Sommersdorf' },
  { label: 'Sommières', value: 'Sommières' },
  { label: 'Sommo', value: 'Sommo' },
  { label: 'Sømna', value: 'Sømna' },
  { label: 'Somogyvár', value: 'Somogyvár' },
  { label: 'Somolinos', value: 'Somolinos' },
  { label: 'Somonauk', value: 'Somonauk' },
  { label: 'Somondoco', value: 'Somondoco' },
  { label: 'Somonino', value: 'Somonino' },
  { label: 'Somontín', value: 'Somontín' },
  { label: 'Somosierra', value: 'Somosierra' },
  { label: 'Somoskőújfalu', value: 'Somoskőújfalu' },
  { label: 'Șomoștelnic', value: 'Șomoștelnic' },
  { label: 'Somotillo', value: 'Somotillo' },
  { label: 'Somoto', value: 'Somoto' },
  { label: 'Somova', value: 'Somova' },
  { label: 'Somovo', value: 'Somovo' },
  { label: 'Somozas As', value: 'Somozas As' },
  { label: 'Sompeta', value: 'Sompeta' },
  { label: 'Sompolno', value: 'Sompolno' },
  { label: 'Somușca', value: 'Somușca' },
  { label: 'Somvarpet', value: 'Somvarpet' },
  { label: 'Son', value: 'Son' },
  { label: 'Son Ferrer', value: 'Son Ferrer' },
  { label: 'Sơn La', value: 'Sơn La' },
  { label: 'Son Servera', value: 'Son Servera' },
  { label: 'Sơn Tây', value: 'Sơn Tây' },
  { label: 'Sona', value: 'Sona' },
  { label: 'Soná', value: 'Soná' },
  { label: 'Şona', value: 'Şona' },
  { label: 'Sonada', value: 'Sonada' },
  { label: 'Sonaguera', value: 'Sonaguera' },
  { label: 'Sonala', value: 'Sonala' },
  { label: 'Sonamukhi', value: 'Sonamukhi' },
  { label: 'Sonamura', value: 'Sonamura' },
  { label: 'Sonari', value: 'Sonari' },
  { label: 'Sonarpur Community Development Block', value: 'Sonarpur Community Development Block' },
  { label: 'Sonbhadra', value: 'Sonbhadra' },
  { label: 'Sŏnbong', value: 'Sŏnbong' },
  { label: 'Sonchamp', value: 'Sonchamp' },
  { label: 'Soncino', value: 'Soncino' },
  { label: 'Sondalo', value: 'Sondalo' },
  { label: 'Sønder Bjert', value: 'Sønder Bjert' },
  { label: 'Sønderborg', value: 'Sønderborg' },
  { label: 'Sønderborg Kommune', value: 'Sønderborg Kommune' },
  { label: 'Sondershausen', value: 'Sondershausen' },
  { label: 'Søndersø', value: 'Søndersø' },
  { label: 'Sondheim Vor Der Rhön', value: 'Sondheim Vor Der Rhön' },
  { label: 'Sondika', value: 'Sondika' },
  { label: 'Søndre Land', value: 'Søndre Land' },
  { label: 'Sondrio', value: 'Sondrio' },
  { label: 'Sonegaon', value: 'Sonegaon' },
  { label: 'Soneja', value: 'Soneja' },
  { label: 'Sonepur', value: 'Sonepur' },
  { label: 'Sông Cầu', value: 'Sông Cầu' },
  { label: 'Songadh', value: 'Songadh' },
  { label: 'Songavazzo', value: 'Songavazzo' },
  { label: 'Songculan', value: 'Songculan' },
  { label: 'Songdalen', value: 'Songdalen' },
  { label: 'Songea', value: 'Songea' },
  { label: 'Songeons', value: 'Songeons' },
  { label: 'Songgangdong', value: 'Songgangdong' },
  { label: 'Songir', value: 'Songir' },
  { label: 'Songjiang', value: 'Songjiang' },
  { label: 'Songjianghe', value: 'Songjianghe' },
  { label: 'Songkan', value: 'Songkan' },
  { label: 'Songkhla', value: 'Songkhla' },
  { label: 'Songling', value: 'Songling' },
  { label: 'Songling', value: 'Songling' },
  { label: 'Songnim', value: 'Songnim' },
  { label: 'Songpa-Gu', value: 'Songpa-Gu' },
  { label: 'Songwa', value: 'Songwa' },
  { label: 'Songyang', value: 'Songyang' },
  { label: 'Songyuan', value: 'Songyuan' },
  { label: 'Soni', value: 'Soni' },
  { label: 'Sonico', value: 'Sonico' },
  { label: 'Sonina', value: 'Sonina' },
  { label: 'Sonipat', value: 'Sonipat' },
  { label: 'Sonitpur', value: 'Sonitpur' },
  { label: 'Sonkovo', value: 'Sonkovo' },
  { label: 'Sonnay', value: 'Sonnay' },
  { label: 'Sonnaz', value: 'Sonnaz' },
  { label: 'Sonnberg Im Mühlkreis', value: 'Sonnberg Im Mühlkreis' },
  { label: 'Sonneberg', value: 'Sonneberg' },
  { label: 'Sonneborn', value: 'Sonneborn' },
  { label: 'Sonnefeld', value: 'Sonnefeld' },
  { label: 'Sonnen', value: 'Sonnen' },
  { label: 'Sonnewalde', value: 'Sonnewalde' },
  { label: 'Sonnhalde', value: 'Sonnhalde' },
  { label: 'Sonning Common', value: 'Sonning Common' },
  { label: 'Sonnino', value: 'Sonnino' },
  { label: 'Sonntagberg', value: 'Sonntagberg' },
  { label: 'Sonoita', value: 'Sonoita' },
  { label: 'Sonoma', value: 'Sonoma' },
  { label: 'Sonoma County', value: 'Sonoma County' },
  { label: 'Sonora', value: 'Sonora' },
  { label: 'Sonora', value: 'Sonora' },
  { label: 'Sonora', value: 'Sonora' },
  { label: 'Sonora', value: 'Sonora' },
  { label: 'Sonqor', value: 'Sonqor' },
  { label: 'Sonquil', value: 'Sonquil' },
  { label: 'Sonquil', value: 'Sonquil' },
  { label: 'Sonsbeck', value: 'Sonsbeck' },
  { label: 'Sonseca', value: 'Sonseca' },
  { label: 'Sońsk', value: 'Sońsk' },
  { label: 'Sonson', value: 'Sonson' },
  { label: 'Sonsonate', value: 'Sonsonate' },
  { label: 'Sonsorol Village', value: 'Sonsorol Village' },
  { label: 'Sonta', value: 'Sonta' },
  { label: 'Sontecomapan', value: 'Sontecomapan' },
  { label: 'Sontheim', value: 'Sontheim' },
  { label: 'Sontheim An Der Brenz', value: 'Sontheim An Der Brenz' },
  { label: 'Sonthofen', value: 'Sonthofen' },
  { label: 'Sontra', value: 'Sontra' },
  { label: 'Sonvad', value: 'Sonvad' },
  { label: 'Sonzacate', value: 'Sonzacate' },
  { label: 'Sonzay', value: 'Sonzay' },
  { label: 'Soo Shi', value: 'Soo Shi' },
  { label: 'Sooke', value: 'Sooke' },
  { label: 'Soomar', value: 'Soomar' },
  { label: 'Sooretama', value: 'Sooretama' },
  { label: 'Soorts-Hossegor', value: 'Soorts-Hossegor' },
  { label: 'Sooss', value: 'Sooss' },
  { label: 'Şopârliţa', value: 'Şopârliţa' },
  { label: 'Sopeira', value: 'Sopeira' },
  { label: 'Sopelana', value: 'Sopelana' },
  { label: 'Soperton', value: 'Soperton' },
  { label: 'Sopetrán', value: 'Sopetrán' },
  { label: 'Sophia', value: 'Sophia' },
  { label: 'Sopje', value: 'Sopje' },
  { label: 'Soplaviento', value: 'Soplaviento' },
  { label: 'Sopó', value: 'Sopó' },
  { label: 'Soponya', value: 'Soponya' },
  { label: 'Soportújar', value: 'Soportújar' },
  { label: 'Soporu De Câmpie', value: 'Soporu De Câmpie' },
  { label: 'Sopot', value: 'Sopot' },
  { label: 'Sopot', value: 'Sopot' },
  { label: 'Sopotnia Wielka', value: 'Sopotnia Wielka' },
  { label: 'Sopotnica', value: 'Sopotnica' },
  { label: 'Şopotu Nou', value: 'Şopotu Nou' },
  { label: 'Soprabolzano', value: 'Soprabolzano' },
  { label: 'Soprana', value: 'Soprana' },
  { label: 'Sopron', value: 'Sopron' },
  { label: 'Soproni Járás', value: 'Soproni Járás' },
  { label: 'Sopuerta', value: 'Sopuerta' },
  { label: 'Sopur', value: 'Sopur' },
  { label: 'Soquel', value: 'Soquel' },
  { label: 'Sora', value: 'Sora' },
  { label: 'Sora', value: 'Sora' },
  { label: 'Sora', value: 'Sora' },
  { label: 'Sorá', value: 'Sorá' },
  { label: 'Sorab', value: 'Sorab' },
  { label: 'Soracá', value: 'Soracá' },
  { label: 'Sorada', value: 'Sorada' },
  { label: 'Soraga', value: 'Soraga' },
  { label: 'Soragna', value: 'Soragna' },
  { label: 'Söråker', value: 'Söråker' },
  { label: 'Soraluze/Placencia De Las Armas', value: 'Soraluze/Placencia De Las Armas' },
  { label: 'Soran', value: 'Soran' },
  { label: 'Soran', value: 'Soran' },
  { label: 'Sorano', value: 'Sorano' },
  { label: 'Sorata', value: 'Sorata' },
  { label: 'Sør-Aurdal', value: 'Sør-Aurdal' },
  { label: 'Sorbara', value: 'Sorbara' },
  { label: 'Sorbas', value: 'Sorbas' },
  { label: 'Sorbhog', value: 'Sorbhog' },
  { label: 'Sorbiers', value: 'Sorbiers' },
  { label: 'Sorbo San Basile', value: 'Sorbo San Basile' },
  { label: 'Sorbo Serpico', value: 'Sorbo Serpico' },
  { label: 'Sorbolo', value: 'Sorbolo' },
  { label: 'Sordevolo', value: 'Sordevolo' },
  { label: 'Sordillos', value: 'Sordillos' },
  { label: 'Sordio', value: 'Sordio' },
  { label: 'Soreang', value: 'Soreang' },
  { label: 'Sorède', value: 'Sorède' },
  { label: 'Sorell', value: 'Sorell' },
  { label: 'Sorel-Moussel', value: 'Sorel-Moussel' },
  { label: 'Sorel-Tracy', value: 'Sorel-Tracy' },
  { label: 'Sorengo', value: 'Sorengo' },
  { label: 'Soresina', value: 'Soresina' },
  { label: 'Soreze', value: 'Soreze' },
  { label: 'Sorèze', value: 'Sorèze' },
  { label: 'Sørfold', value: 'Sørfold' },
  { label: 'Sörforsa', value: 'Sörforsa' },
  { label: 'Sør-Fron', value: 'Sør-Fron' },
  { label: 'Sorgà', value: 'Sorgà' },
  { label: 'Sörgenloch', value: 'Sörgenloch' },
  { label: 'Sorges', value: 'Sorges' },
  { label: 'Sorgono', value: 'Sorgono' },
  { label: 'Sorgues', value: 'Sorgues' },
  { label: 'Sorgun', value: 'Sorgun' },
  { label: 'Sori', value: 'Sori' },
  { label: 'Sori Malé', value: 'Sori Malé' },
  { label: 'Soria', value: 'Soria' },
  { label: 'Sorianello', value: 'Sorianello' },
  { label: 'Soriano Calabro', value: 'Soriano Calabro' },
  { label: 'Soriano Nel Cimino', value: 'Soriano Nel Cimino' },
  { label: 'Sorico', value: 'Sorico' },
  { label: 'Sorigny', value: 'Sorigny' },
  { label: 'Soriguera', value: 'Soriguera' },
  { label: 'Sorihuela', value: 'Sorihuela' },
  { label: 'Sorihuela Del Guadalimar', value: 'Sorihuela Del Guadalimar' },
  { label: 'Soriso', value: 'Soriso' },
  { label: 'Sorisole', value: 'Sorisole' },
  { label: 'Soritor', value: 'Soritor' },
  { label: 'Sorkh Kand', value: 'Sorkh Kand' },
  { label: 'Sorkh Roud', value: 'Sorkh Roud' },
  { label: 'Sorkheh', value: 'Sorkheh' },
  { label: 'Sorlada', value: 'Sorlada' },
  { label: 'Sørland', value: 'Sørland' },
  { label: 'Sormano', value: 'Sormano' },
  { label: 'Sormiou', value: 'Sormiou' },
  { label: 'Sornay', value: 'Sornay' },
  { label: 'Soro', value: 'Soro' },
  { label: 'Sorø', value: 'Sorø' },
  { label: 'Sorø Kommune', value: 'Sorø Kommune' },
  { label: 'Soroa', value: 'Soroa' },
  { label: 'Soroca', value: 'Soroca' },
  { label: 'Sorocaba', value: 'Sorocaba' },
  { label: 'Sorochinsk', value: 'Sorochinsk' },
  { label: 'Sør-Odal', value: 'Sør-Odal' },
  { label: 'Sorokino', value: 'Sorokino' },
  { label: 'Sorokyne', value: 'Sorokyne' },
  { label: 'Sorokynskyi Rayon', value: 'Sorokynskyi Rayon' },
  { label: 'Șoromiclea', value: 'Șoromiclea' },
  { label: 'Soron', value: 'Soron' },
  { label: 'Sorong', value: 'Sorong' },
  { label: 'Soroti', value: 'Soroti' },
  { label: 'Sorradile', value: 'Sorradile' },
  { label: 'Sørreisa', value: 'Sørreisa' },
  { label: 'Sorrento', value: 'Sorrento' },
  { label: 'Sorrento', value: 'Sorrento' },
  { label: 'Sorrento', value: 'Sorrento' },
  { label: 'Sorrento', value: 'Sorrento' },
  { label: 'Sorrento Valley', value: 'Sorrento Valley' },
  { label: 'Sorriso', value: 'Sorriso' },
  { label: 'Sorsele', value: 'Sorsele' },
  { label: 'Sorsk', value: 'Sorsk' },
  { label: 'Sorso', value: 'Sorso' },
  { label: 'Sorsogon', value: 'Sorsogon' },
  { label: 'Sort', value: 'Sort' },
  { label: 'Sortaval’Skiy Rayon', value: 'Sortaval’Skiy Rayon' },
  { label: 'Sortavala', value: 'Sortavala' },
  { label: 'Sortelha', value: 'Sortelha' },
  { label: 'Sortino', value: 'Sortino' },
  { label: 'Sortland', value: 'Sortland' },
  { label: 'Sortova', value: 'Sortova' },
  { label: 'Sørum', value: 'Sørum' },
  { label: 'Sørumsand', value: 'Sørumsand' },
  { label: 'Sörup', value: 'Sörup' },
  { label: 'Sørvágur', value: 'Sørvágur' },
  { label: 'Sør-Varanger', value: 'Sør-Varanger' },
  { label: 'Sorvilán', value: 'Sorvilán' },
  { label: 'Sorzano', value: 'Sorzano' },
  { label: 'Sos Del Rey Católico', value: 'Sos Del Rey Católico' },
  { label: 'Sos’Va', value: 'Sos’Va' },
  { label: 'Sosa', value: 'Sosa' },
  { label: 'Sosa', value: 'Sosa' },
  { label: 'Sosándra', value: 'Sosándra' },
  { label: 'Sōsa-Shi', value: 'Sōsa-Shi' },
  { label: 'Sösdala', value: 'Sösdala' },
  { label: 'Sosenskiy', value: 'Sosenskiy' },
  { label: 'Soses', value: 'Soses' },
  { label: 'Soskovo', value: 'Soskovo' },
  { label: 'Sóskút', value: 'Sóskút' },
  { label: 'Sośnica', value: 'Sośnica' },
  { label: 'Sośnicowice', value: 'Sośnicowice' },
  { label: 'Sośnie', value: 'Sośnie' },
  { label: 'Sośno', value: 'Sośno' },
  { label: 'Sosnogorsk', value: 'Sosnogorsk' },
  { label: 'Sosnovaya Polyana', value: 'Sosnovaya Polyana' },
  { label: 'Sosnovka', value: 'Sosnovka' },
  { label: 'Sosnovka', value: 'Sosnovka' },
  { label: 'Sosnovka', value: 'Sosnovka' },
  { label: 'Sosnovka', value: 'Sosnovka' },
  { label: 'Sosnovka', value: 'Sosnovka' },
  { label: 'Sosnovka', value: 'Sosnovka' },
  { label: 'Sosnovo', value: 'Sosnovo' },
  { label: 'Sosnovoborsk', value: 'Sosnovoborsk' },
  { label: 'Sosnovoborsk', value: 'Sosnovoborsk' },
  { label: 'Sosnovo-Ozerskoye', value: 'Sosnovo-Ozerskoye' },
  { label: 'Sosnovskiy Rayon', value: 'Sosnovskiy Rayon' },
  { label: 'Sosnovskiy Rayon', value: 'Sosnovskiy Rayon' },
  { label: 'Sosnovskoye', value: 'Sosnovskoye' },
  { label: 'Sosnovyy Bor', value: 'Sosnovyy Bor' },
  { label: 'Sosnowica', value: 'Sosnowica' },
  { label: 'Sosnowice', value: 'Sosnowice' },
  { label: 'Sosnowiec', value: 'Sosnowiec' },
  { label: 'Sosnówka', value: 'Sosnówka' },
  { label: 'Sosnówka', value: 'Sosnówka' },
  { label: 'Sosnytsya', value: 'Sosnytsya' },
  { label: 'Sospel', value: 'Sospel' },
  { label: 'Sospiro', value: 'Sospiro' },
  { label: 'Sospirolo', value: 'Sospirolo' },
  { label: 'Sossano', value: 'Sossano' },
  { label: 'Sossêgo', value: 'Sossêgo' },
  { label: 'Šoštanj', value: 'Šoštanj' },
  { label: 'Sostegno', value: 'Sostegno' },
  { label: 'Sostro District', value: 'Sostro District' },
  { label: 'Sosúa', value: 'Sosúa' },
  { label: 'Sot De Chera', value: 'Sot De Chera' },
  { label: 'Sot De Ferrer', value: 'Sot De Ferrer' },
  { label: 'Sotalbo', value: 'Sotalbo' },
  { label: 'Şotânga', value: 'Şotânga' },
  { label: 'Sotaquirá', value: 'Sotaquirá' },
  { label: 'Sotará', value: 'Sotará' },
  { label: 'Soteapan', value: 'Soteapan' },
  { label: 'Sotelo', value: 'Sotelo' },
  { label: 'Sotés', value: 'Sotés' },
  { label: 'Sotik', value: 'Sotik' },
  { label: 'Sotik Post', value: 'Sotik Post' },
  { label: 'Sotillo', value: 'Sotillo' },
  { label: 'Sotillo De La Adrada', value: 'Sotillo De La Adrada' },
  { label: 'Sotillo De La Ribera', value: 'Sotillo De La Ribera' },
  { label: 'Sotillo De Las Palomas', value: 'Sotillo De Las Palomas' },
  { label: 'Sotillo Del Rincón', value: 'Sotillo Del Rincón' },
  { label: 'Sotillo El', value: 'Sotillo El' },
  { label: 'Sotíra', value: 'Sotíra' },
  { label: 'Sotkamo', value: 'Sotkamo' },
  { label: 'Sotnikovo', value: 'Sotnikovo' },
  { label: 'Sotnikovskoye', value: 'Sotnikovskoye' },
  { label: 'Soto De Cerrato', value: 'Soto De Cerrato' },
  { label: 'Soto De La Vega', value: 'Soto De La Vega' },
  { label: 'Soto Del Barco', value: 'Soto Del Barco' },
  { label: 'Soto Del Real', value: 'Soto Del Real' },
  { label: 'Soto En Cameros', value: 'Soto En Cameros' },
  { label: 'Soto La Marina', value: 'Soto La Marina' },
  { label: 'Soto Y Amío', value: 'Soto Y Amío' },
  { label: 'Sotobañado Y Priorato', value: 'Sotobañado Y Priorato' },
  { label: 'Sotodosos', value: 'Sotodosos' },
  { label: 'Sotonera La', value: 'Sotonera La' },
  { label: 'Sotorribas', value: 'Sotorribas' },
  { label: 'Sotosalbos', value: 'Sotosalbos' },
  { label: 'Sotoserrano', value: 'Sotoserrano' },
  { label: 'Sotouboua', value: 'Sotouboua' },
  { label: 'Sotragero', value: 'Sotragero' },
  { label: 'Sotresgudo', value: 'Sotresgudo' },
  { label: 'Şotrile', value: 'Şotrile' },
  { label: 'Sottens', value: 'Sottens' },
  { label: 'Sottevast', value: 'Sottevast' },
  { label: 'Sotteville-Lès-Rouen', value: 'Sotteville-Lès-Rouen' },
  { label: 'Sotto Il Monte Giovanni Xxiii', value: 'Sotto Il Monte Giovanni Xxiii' },
  { label: 'Sottocastello', value: 'Sottocastello' },
  { label: 'Sottoselva', value: 'Sottoselva' },
  { label: 'Sottrum', value: 'Sottrum' },
  { label: 'Sotuta', value: 'Sotuta' },
  { label: 'Soual', value: 'Soual' },
  { label: 'Soubise', value: 'Soubise' },
  { label: 'Soucelles', value: 'Soucelles' },
  { label: 'Souchez', value: 'Souchez' },
  { label: 'Soucht', value: 'Soucht' },
  { label: 'Soucieu-En-Jarrest', value: 'Soucieu-En-Jarrest' },
  { label: 'Soucy', value: 'Soucy' },
  { label: 'Soúda', value: 'Soúda' },
  { label: 'Soudan', value: 'Soudan' },
  { label: 'Souderton', value: 'Souderton' },
  { label: 'Soues', value: 'Soues' },
  { label: 'Souesmes', value: 'Souesmes' },
  { label: 'Souffelweyersheim', value: 'Souffelweyersheim' },
  { label: 'Soufflenheim', value: 'Soufflenheim' },
  { label: 'Soufian', value: 'Soufian' },
  { label: 'Souflí', value: 'Souflí' },
  { label: 'Soufrière', value: 'Soufrière' },
  { label: 'Soufrière', value: 'Soufrière' },
  { label: 'Soufriere Estate', value: 'Soufriere Estate' },
  { label: 'Sougueur', value: 'Sougueur' },
  { label: 'Souillac', value: 'Souillac' },
  { label: 'Souillac', value: 'Souillac' },
  { label: 'Souk Ahras', value: 'Souk Ahras' },
  { label: 'Soulac-Sur-Mer', value: 'Soulac-Sur-Mer' },
  { label: 'Soulaines-Sur-Aubance', value: 'Soulaines-Sur-Aubance' },
  { label: 'Soulaire-Et-Bourg', value: 'Soulaire-Et-Bourg' },
  { label: 'Soulgé-Sur-Ouette', value: 'Soulgé-Sur-Ouette' },
  { label: 'Souligné-Sous-Ballon', value: 'Souligné-Sous-Ballon' },
  { label: 'Soullans', value: 'Soullans' },
  { label: 'Soulsbyville', value: 'Soulsbyville' },
  { label: 'Soultzeren', value: 'Soultzeren' },
  { label: 'Soultz-Haut-Rhin', value: 'Soultz-Haut-Rhin' },
  { label: 'Soultzmatt', value: 'Soultzmatt' },
  { label: 'Soultz-Sous-Forêts', value: 'Soultz-Sous-Forêts' },
  { label: 'Souma', value: 'Souma' },
  { label: 'Soumagne', value: 'Soumagne' },
  { label: 'Soumoulou', value: 'Soumoulou' },
  { label: 'Sound Beach', value: 'Sound Beach' },
  { label: 'Soúni-Zanakiá', value: 'Soúni-Zanakiá' },
  { label: 'Souppes-Sur-Loing', value: 'Souppes-Sur-Loing' },
  { label: 'Souprosse', value: 'Souprosse' },
  { label: 'Souq Larb’A Al Gharb', value: 'Souq Larb’A Al Gharb' },
  { label: 'Sour El Ghozlane', value: 'Sour El Ghozlane' },
  { label: 'Sour Lake', value: 'Sour Lake' },
  { label: 'Souraïde', value: 'Souraïde' },
  { label: 'Souran', value: 'Souran' },
  { label: 'Sourcieux-Les-Mines', value: 'Sourcieux-Les-Mines' },
  { label: 'Sourdeval', value: 'Sourdeval' },
  { label: 'Sourdun', value: 'Sourdun' },
  { label: 'Soure', value: 'Soure' },
  { label: 'Soure', value: 'Soure' },
  { label: 'Souris', value: 'Souris' },
  { label: 'Souris', value: 'Souris' },
  { label: 'Souro Pires', value: 'Souro Pires' },
  { label: 'Sourotí', value: 'Sourotí' },
  { label: 'Soúrpi', value: 'Soúrpi' },
  { label: 'Sours', value: 'Sours' },
  { label: 'Sourzac', value: 'Sourzac' },
  { label: 'Sousa', value: 'Sousa' },
  { label: 'Sousel', value: 'Sousel' },
  { label: 'Soussans', value: 'Soussans' },
  { label: 'Sousse', value: 'Sousse' },
  { label: 'Soustons', value: 'Soustons' },
  { label: 'South Alamo', value: 'South Alamo' },
  { label: 'South Albury', value: 'South Albury' },
  { label: 'South Amboy', value: 'South Amboy' },
  { label: 'South Amherst', value: 'South Amherst' },
  { label: 'South Amherst', value: 'South Amherst' },
  { label: 'South Andaman', value: 'South Andaman' },
  { label: 'South Apopka', value: 'South Apopka' },
  { label: 'South Ashburnham', value: 'South Ashburnham' },
  { label: 'South Ayrshire', value: 'South Ayrshire' },
  { label: 'South Barre', value: 'South Barre' },
  { label: 'South Barrington', value: 'South Barrington' },
  { label: 'South Bathurst', value: 'South Bathurst' },
  { label: 'South Bay', value: 'South Bay' },
  { label: 'South Beach', value: 'South Beach' },
  { label: 'South Beach', value: 'South Beach' },
  { label: 'South Bel Air', value: 'South Bel Air' },
  { label: 'South Belmar', value: 'South Belmar' },
  { label: 'South Beloit', value: 'South Beloit' },
  { label: 'South Bend', value: 'South Bend' },
  { label: 'South Bend', value: 'South Bend' },
  { label: 'South Benfleet', value: 'South Benfleet' },
  { label: 'South Berwick', value: 'South Berwick' },
  { label: 'South Bloomfield', value: 'South Bloomfield' },
  { label: 'South Blooming Grove', value: 'South Blooming Grove' },
  { label: 'South Boston', value: 'South Boston' },
  { label: 'South Boston', value: 'South Boston' },
  { label: 'South Bougainville', value: 'South Bougainville' },
  { label: 'South Bound Brook', value: 'South Bound Brook' },
  { label: 'South Bowenfels', value: 'South Bowenfels' },
  { label: 'South Bradenton', value: 'South Bradenton' },
  { label: 'South Brent', value: 'South Brent' },
  { label: 'South Brighton', value: 'South Brighton' },
  { label: 'South Brisbane', value: 'South Brisbane' },
  { label: 'South Brooksville', value: 'South Brooksville' },
  { label: 'South Browning', value: 'South Browning' },
  { label: 'South Bunbury', value: 'South Bunbury' },
  { label: 'South Burlington', value: 'South Burlington' },
  { label: 'South Burnett', value: 'South Burnett' },
  { label: 'South Canal', value: 'South Canal' },
  { label: 'South Carnarvon', value: 'South Carnarvon' },
  { label: 'South Carthage', value: 'South Carthage' },
  { label: 'South Cave', value: 'South Cave' },
  { label: 'South Cerney', value: 'South Cerney' },
  { label: 'South Chailey', value: 'South Chailey' },
  { label: 'South Charleston', value: 'South Charleston' },
  { label: 'South Charleston', value: 'South Charleston' },
  { label: 'South Chicago', value: 'South Chicago' },
  { label: 'South Chicago Heights', value: 'South Chicago Heights' },
  { label: 'South Cleveland', value: 'South Cleveland' },
  { label: 'South Coatesville', value: 'South Coatesville' },
  { label: 'South Collingham', value: 'South Collingham' },
  { label: 'South Congaree', value: 'South Congaree' },
  { label: 'South Connellsville', value: 'South Connellsville' },
  { label: 'South Coogee', value: 'South Coogee' },
  { label: 'South Corning', value: 'South Corning' },
  { label: 'South Coventry', value: 'South Coventry' },
  { label: 'South Croydon', value: 'South Croydon' },
  { label: 'South Daytona', value: 'South Daytona' },
  { label: 'South Deerfield', value: 'South Deerfield' },
  { label: 'South Delhi', value: 'South Delhi' },
  { label: 'South Dennis', value: 'South Dennis' },
  { label: 'South District', value: 'South District' },
  { label: 'South Dos Palos', value: 'South Dos Palos' },
  { label: 'South Dublin', value: 'South Dublin' },
  { label: 'South Duxbury', value: 'South Duxbury' },
  { label: 'South El Monte', value: 'South El Monte' },
  { label: 'South Elgin', value: 'South Elgin' },
  { label: 'South Eliot', value: 'South Eliot' },
  { label: 'South Elmsall', value: 'South Elmsall' },
  { label: 'South Euclid', value: 'South Euclid' },
  { label: 'South Fallsburg', value: 'South Fallsburg' },
  { label: 'South Farmingdale', value: 'South Farmingdale' },
  { label: 'South Floral Park', value: 'South Floral Park' },
  { label: 'South Fly', value: 'South Fly' },
  { label: 'South Fremantle', value: 'South Fremantle' },
  { label: 'South Fulton', value: 'South Fulton' },
  { label: 'South Garo Hills', value: 'South Garo Hills' },
  { label: 'South Gastonia', value: 'South Gastonia' },
  { label: 'South Gate', value: 'South Gate' },
  { label: 'South Gate', value: 'South Gate' },
  { label: 'South Gate Ridge', value: 'South Gate Ridge' },
  { label: 'South Gippsland', value: 'South Gippsland' },
  { label: 'South Gladstone', value: 'South Gladstone' },
  { label: 'South Glens Falls', value: 'South Glens Falls' },
  { label: 'South Gloucestershire', value: 'South Gloucestershire' },
  { label: 'South Goa', value: 'South Goa' },
  { label: 'South Gondar Zone', value: 'South Gondar Zone' },
  { label: 'South Grafton', value: 'South Grafton' },
  { label: 'South Granville', value: 'South Granville' },
  { label: 'South Greeley', value: 'South Greeley' },
  { label: 'South Greensburg', value: 'South Greensburg' },
  { label: 'South Guildford', value: 'South Guildford' },
  { label: 'South Gull Lake', value: 'South Gull Lake' },
  { label: 'South Hadley', value: 'South Hadley' },
  { label: 'South Harting', value: 'South Harting' },
  { label: 'South Haven', value: 'South Haven' },
  { label: 'South Haven', value: 'South Haven' },
  { label: 'South Hayling', value: 'South Hayling' },
  { label: 'South Hedland', value: 'South Hedland' },
  { label: 'South Hempstead', value: 'South Hempstead' },
  { label: 'South Henderson', value: 'South Henderson' },
  { label: 'South Hetton', value: 'South Hetton' },
  { label: 'South Highpoint', value: 'South Highpoint' },
  { label: 'South Hill', value: 'South Hill' },
  { label: 'South Hill', value: 'South Hill' },
  { label: 'South Hill', value: 'South Hill' },
  { label: 'South Hobart', value: 'South Hobart' },
  { label: 'South Holland', value: 'South Holland' },
  { label: 'South Hooksett', value: 'South Hooksett' },
  { label: 'South Houston', value: 'South Houston' },
  { label: 'South Huntington', value: 'South Huntington' },
  { label: 'South Huron', value: 'South Huron' },
  { label: 'South Hurstville', value: 'South Hurstville' },
  { label: 'South Hutchinson', value: 'South Hutchinson' },
  { label: 'South Jacksonville', value: 'South Jacksonville' },
  { label: 'South Jordan', value: 'South Jordan' },
  { label: 'South Jordan Heights', value: 'South Jordan Heights' },
  { label: 'South Kalgoorlie', value: 'South Kalgoorlie' },
  { label: 'South Kempsey', value: 'South Kempsey' },
  { label: 'South Kensington', value: 'South Kensington' },
  { label: 'South Kingstown', value: 'South Kingstown' },
  { label: 'South Kingsville', value: 'South Kingsville' },
  { label: 'South Kolan', value: 'South Kolan' },
  { label: 'South Lake', value: 'South Lake' },
  { label: 'South Lake Tahoe', value: 'South Lake Tahoe' },
  { label: 'South Lanarkshire', value: 'South Lanarkshire' },
  { label: 'South Lancaster', value: 'South Lancaster' },
  { label: 'South Launceston', value: 'South Launceston' },
  { label: 'South Laurel', value: 'South Laurel' },
  { label: 'South Lawndale', value: 'South Lawndale' },
  { label: 'South Lebanon', value: 'South Lebanon' },
  { label: 'South Lebanon', value: 'South Lebanon' },
  { label: 'South Lismore', value: 'South Lismore' },
  { label: 'South Littleton', value: 'South Littleton' },
  { label: 'South Lockport', value: 'South Lockport' },
  { label: 'South Luffenham', value: 'South Luffenham' },
  { label: 'South Lyon', value: 'South Lyon' },
  { label: 'South Mackay', value: 'South Mackay' },
  { label: 'South Maclean', value: 'South Maclean' },
  { label: 'South Melbourne', value: 'South Melbourne' },
  { label: 'South Miami', value: 'South Miami' },
  { label: 'South Miami Heights', value: 'South Miami Heights' },
  { label: 'South Milford', value: 'South Milford' },
  { label: 'South Milwaukee', value: 'South Milwaukee' },
  { label: 'South Molton', value: 'South Molton' },
  { label: 'South Monroe', value: 'South Monroe' },
  { label: 'South Morang', value: 'South Morang' },
  { label: 'South Murwillumbah', value: 'South Murwillumbah' },
  { label: 'South Nowra', value: 'South Nowra' },
  { label: 'South Nutfield', value: 'South Nutfield' },
  { label: 'South Nyack', value: 'South Nyack' },
  { label: 'South Ockendon', value: 'South Ockendon' },
  { label: 'South Ogden', value: 'South Ogden' },
  { label: 'South Old Bridge', value: 'South Old Bridge' },
  { label: 'South Orange', value: 'South Orange' },
  { label: 'South Oroville', value: 'South Oroville' },
  { label: 'South Padre Island', value: 'South Padre Island' },
  { label: 'South Palm Beach', value: 'South Palm Beach' },
  { label: 'South Paris', value: 'South Paris' },
  { label: 'South Park', value: 'South Park' },
  { label: 'South Park Township', value: 'South Park Township' },
  { label: 'South Pasadena', value: 'South Pasadena' },
  { label: 'South Pasadena', value: 'South Pasadena' },
  { label: 'South Patrick Shores', value: 'South Patrick Shores' },
  { label: 'South Peabody', value: 'South Peabody' },
  { label: 'South Pekin', value: 'South Pekin' },
  { label: 'South Pender Harbour', value: 'South Pender Harbour' },
  { label: 'South Penrith', value: 'South Penrith' },
  { label: 'South Perth', value: 'South Perth' },
  { label: 'South Petherton', value: 'South Petherton' },
  { label: 'South Pittsburg', value: 'South Pittsburg' },
  { label: 'South Plainfield', value: 'South Plainfield' },
  { label: 'South Plympton', value: 'South Plympton' },
  { label: 'South Point', value: 'South Point' },
  { label: 'South Point', value: 'South Point' },
  { label: 'South Portland', value: 'South Portland' },
  { label: 'South Portland Gardens', value: 'South Portland Gardens' },
  { label: 'South Pottstown', value: 'South Pottstown' },
  { label: 'South Riding', value: 'South Riding' },
  { label: 'South River', value: 'South River' },
  { label: 'South River', value: 'South River' },
  { label: 'South Rockwood', value: 'South Rockwood' },
  { label: 'South Rosemary', value: 'South Rosemary' },
  { label: 'South Roxana', value: 'South Roxana' },
  { label: 'South Russell', value: 'South Russell' },
  { label: 'South Saint Paul', value: 'South Saint Paul' },
  { label: 'South Salt Lake', value: 'South Salt Lake' },
  { label: 'South San Francisco', value: 'South San Francisco' },
  { label: 'South San Gabriel', value: 'South San Gabriel' },
  { label: 'South San Jose Hills', value: 'South San Jose Hills' },
  { label: 'South Sanford', value: 'South Sanford' },
  { label: 'South Sarasota', value: 'South Sarasota' },
  { label: 'South Shields', value: 'South Shields' },
  { label: 'South Shore', value: 'South Shore' },
  { label: 'South Shore', value: 'South Shore' },
  { label: 'South Sioux City', value: 'South Sioux City' },
  { label: 'South Suffolk', value: 'South Suffolk' },
  { label: 'South Sumter', value: 'South Sumter' },
  { label: 'South Taft', value: 'South Taft' },
  { label: 'South Tamworth', value: 'South Tamworth' },
  { label: 'South Tangerang', value: 'South Tangerang' },
  { label: 'South Taranaki District', value: 'South Taranaki District' },
  { label: 'South Temple', value: 'South Temple' },
  { label: 'South Thomaston', value: 'South Thomaston' },
  { label: 'South Toms River', value: 'South Toms River' },
  { label: 'South Toowoomba', value: 'South Toowoomba' },
  { label: 'South Townsville', value: 'South Townsville' },
  { label: 'South Tripura', value: 'South Tripura' },
  { label: 'South Tucson', value: 'South Tucson' },
  { label: 'South Turramurra', value: 'South Turramurra' },
  { label: 'South Tyneside', value: 'South Tyneside' },
  { label: 'South Ubian', value: 'South Ubian' },
  { label: 'South Uniontown', value: 'South Uniontown' },
  { label: 'South Upi', value: 'South Upi' },
  { label: 'South Upi', value: 'South Upi' },
  { label: 'South Vacherie', value: 'South Vacherie' },
  { label: 'South Valley', value: 'South Valley' },
  { label: 'South Valley Stream', value: 'South Valley Stream' },
  { label: 'South Venice', value: 'South Venice' },
  { label: 'South Vineland', value: 'South Vineland' },
  { label: 'South Waikato District', value: 'South Waikato District' },
  { label: 'South Wairarapa District', value: 'South Wairarapa District' },
  { label: 'South Waverly', value: 'South Waverly' },
  { label: 'South Wazīristān Agency', value: 'South Wazīristān Agency' },
  { label: 'South Weber', value: 'South Weber' },
  { label: 'South Wenatchee', value: 'South Wenatchee' },
  { label: 'South Wentworthville', value: 'South Wentworthville' },
  { label: 'South West Delhi', value: 'South West Delhi' },
  { label: 'South West Garo Hills', value: 'South West Garo Hills' },
  { label: 'South West Khasi Hills', value: 'South West Khasi Hills' },
  { label: 'South West Rocks', value: 'South West Rocks' },
  { label: 'South Whitley', value: 'South Whitley' },
  { label: 'South Whittier', value: 'South Whittier' },
  { label: 'South Willard', value: 'South Willard' },
  { label: 'South Williamsport', value: 'South Williamsport' },
  { label: 'South Windham', value: 'South Windham' },
  { label: 'South Windham', value: 'South Windham' },
  { label: 'South Windsor', value: 'South Windsor' },
  { label: 'South Windsor', value: 'South Windsor' },
  { label: 'South Wingfield', value: 'South Wingfield' },
  { label: 'South Witham', value: 'South Witham' },
  { label: 'South Wollo Zone', value: 'South Wollo Zone' },
  { label: 'South Woodstock', value: 'South Woodstock' },
  { label: 'South Yarmouth', value: 'South Yarmouth' },
  { label: 'South Yarra', value: 'South Yarra' },
  { label: 'South Yuba City', value: 'South Yuba City' },
  { label: 'South Yunderup', value: 'South Yunderup' },
  { label: 'South Zanesville', value: 'South Zanesville' },
  { label: 'Southall', value: 'Southall' },
  { label: 'Southam', value: 'Southam' },
  { label: 'Southampton', value: 'Southampton' },
  { label: 'Southampton', value: 'Southampton' },
  { label: 'Southampton', value: 'Southampton' },
  { label: 'Southampton', value: 'Southampton' },
  { label: 'Southampton County', value: 'Southampton County' },
  { label: 'Southaven', value: 'Southaven' },
  { label: 'Southbank', value: 'Southbank' },
  { label: 'Southborough', value: 'Southborough' },
  { label: 'Southbridge', value: 'Southbridge' },
  { label: 'Southbury', value: 'Southbury' },
  { label: 'Southcentral', value: 'Southcentral' },
  { label: 'Southchase', value: 'Southchase' },
  { label: 'Southchurch Village', value: 'Southchurch Village' },
  { label: 'Southeast Arcadia', value: 'Southeast Arcadia' },
  { label: 'Southeast Fairbanks Census Area', value: 'Southeast Fairbanks Census Area' },
  { label: 'Southeastern Tigray Zone', value: 'Southeastern Tigray Zone' },
  { label: 'Southend-On-Sea', value: 'Southend-On-Sea' },
  { label: 'Southern Downs', value: 'Southern Downs' },
  { label: 'Southern Gateway', value: 'Southern Gateway' },
  { label: 'Southern Grampians', value: 'Southern Grampians' },
  { label: 'Southern Mallee', value: 'Southern Mallee' },
  { label: 'Southern Midlands', value: 'Southern Midlands' },
  { label: 'Southern Pines', value: 'Southern Pines' },
  { label: 'Southern River', value: 'Southern River' },
  { label: 'Southern Shops', value: 'Southern Shops' },
  { label: 'Southern Shores', value: 'Southern Shores' },
  { label: 'Southern Tigray Zone', value: 'Southern Tigray Zone' },
  { label: 'Southern View', value: 'Southern View' },
  { label: 'Southery', value: 'Southery' },
  { label: 'Southfield', value: 'Southfield' },
  { label: 'Southfield', value: 'Southfield' },
  { label: 'Southgate', value: 'Southgate' },
  { label: 'Southgate', value: 'Southgate' },
  { label: 'Southgate', value: 'Southgate' },
  { label: 'Southgate', value: 'Southgate' },
  { label: 'Southglenn', value: 'Southglenn' },
  { label: 'Southlake', value: 'Southlake' },
  { label: 'Southland District', value: 'Southland District' },
  { label: 'Southmayd', value: 'Southmayd' },
  { label: 'Southminster', value: 'Southminster' },
  { label: 'Southmont', value: 'Southmont' },
  { label: 'Southmont', value: 'Southmont' },
  { label: 'Southold', value: 'Southold' },
  { label: 'Southowram', value: 'Southowram' },
  { label: 'Southport', value: 'Southport' },
  { label: 'Southport', value: 'Southport' },
  { label: 'Southport', value: 'Southport' },
  { label: 'Southport', value: 'Southport' },
  { label: 'Southport', value: 'Southport' },
  { label: 'Southport', value: 'Southport' },
  { label: 'Southsea', value: 'Southsea' },
  { label: 'Southside', value: 'Southside' },
  { label: 'Southside', value: 'Southside' },
  { label: 'Southside', value: 'Southside' },
  { label: 'Southside', value: 'Southside' },
  { label: 'Southside', value: 'Southside' },
  { label: 'Southside Place', value: 'Southside Place' },
  { label: 'Southwater', value: 'Southwater' },
  { label: 'Southwell', value: 'Southwell' },
  { label: 'Southwest', value: 'Southwest' },
  { label: 'Southwest Greensburg', value: 'Southwest Greensburg' },
  { label: 'Southwest Ranches', value: 'Southwest Ranches' },
  { label: 'Southwick', value: 'Southwick' },
  { label: 'Southwick', value: 'Southwick' },
  { label: 'Southwold', value: 'Southwold' },
  { label: 'Southwood Acres', value: 'Southwood Acres' },
  { label: 'Southworth', value: 'Southworth' },
  { label: 'Souto', value: 'Souto' },
  { label: 'Souto', value: 'Souto' },
  { label: 'Souto Da Carpalhosa', value: 'Souto Da Carpalhosa' },
  { label: 'Souto De Aguiar Da Beira', value: 'Souto De Aguiar Da Beira' },
  { label: 'Souto Maior', value: 'Souto Maior' },
  { label: 'Souto Soares', value: 'Souto Soares' },
  { label: 'Soutomaior', value: 'Soutomaior' },
  { label: 'Souvigny', value: 'Souvigny' },
  { label: 'Souzga', value: 'Souzga' },
  { label: 'Şovarna', value: 'Şovarna' },
  { label: 'Sovata', value: 'Sovata' },
  { label: 'Sover', value: 'Sover' },
  { label: 'Soverato Marina', value: 'Soverato Marina' },
  { label: 'Soverato Superiore', value: 'Soverato Superiore' },
  { label: 'Sovere', value: 'Sovere' },
  { label: 'Soveria Mannelli', value: 'Soveria Mannelli' },
  { label: 'Soveria Simeri', value: 'Soveria Simeri' },
  { label: 'Soverzene', value: 'Soverzene' },
  { label: 'Sovet', value: 'Sovet' },
  { label: 'Sovetabad', value: 'Sovetabad' },
  { label: 'Sovetsk', value: 'Sovetsk' },
  { label: 'Sovetsk', value: 'Sovetsk' },
  { label: 'Sovetskaya', value: 'Sovetskaya' },
  { label: 'Sovetskaya', value: 'Sovetskaya' },
  { label: 'Sovetskaya', value: 'Sovetskaya' },
  { label: 'Sovetskaya Gavan’', value: 'Sovetskaya Gavan’' },
  { label: 'Sovetskiy', value: 'Sovetskiy' },
  { label: 'Sovetskiy', value: 'Sovetskiy' },
  { label: 'Sovetskiy', value: 'Sovetskiy' },
  { label: 'Sovetskiy', value: 'Sovetskiy' },
  { label: 'Sovetskiy', value: 'Sovetskiy' },
  { label: 'Sovetskiy Rayon', value: 'Sovetskiy Rayon' },
  { label: 'Sovetskiy Rayon', value: 'Sovetskiy Rayon' },
  { label: 'Sovetskiy Rayon', value: 'Sovetskiy Rayon' },
  { label: 'Sovetskoye', value: 'Sovetskoye' },
  { label: 'Sovetskoye', value: 'Sovetskoye' },
  { label: 'Sovetskoye', value: 'Sovetskoye' },
  { label: 'Sovetsky District', value: 'Sovetsky District' },
  { label: 'Sovicille', value: 'Sovicille' },
  { label: 'Sovico', value: 'Sovico' },
  { label: 'Sovietskyi Raion', value: 'Sovietskyi Raion' },
  { label: 'Sovizzo', value: 'Sovizzo' },
  { label: 'Sovkhoznoye', value: 'Sovkhoznoye' },
  { label: 'Sovkhoznyy', value: 'Sovkhoznyy' },
  { label: 'Sovkhoznyy', value: 'Sovkhoznyy' },
  { label: 'Sovramonte', value: 'Sovramonte' },
  { label: 'Sowerby Bridge', value: 'Sowerby Bridge' },
  { label: 'Soweto', value: 'Soweto' },
  { label: 'Soyaló', value: 'Soyaló' },
  { label: 'Soyaniquilpan', value: 'Soyaniquilpan' },
  { label: 'Soyapango', value: 'Soyapango' },
  { label: 'Soyata', value: 'Soyata' },
  { label: 'Soyataco', value: 'Soyataco' },
  { label: 'Soyatitán', value: 'Soyatitán' },
  { label: 'Soyatlán De Afuera', value: 'Soyatlán De Afuera' },
  { label: 'Soyatlán Del Oro', value: 'Soyatlán Del Oro' },
  { label: 'Soyaux', value: 'Soyaux' },
  { label: 'Soyen', value: 'Soyen' },
  { label: 'Soygaon', value: 'Soygaon' },
  { label: 'Soyibug', value: 'Soyibug' },
  { label: 'Soyons', value: 'Soyons' },
  { label: 'Soyopa', value: 'Soyopa' },
  { label: 'Soyung', value: 'Soyung' },
  { label: 'Soyung', value: 'Soyung' },
  { label: 'Sozaq Aūdany', value: 'Sozaq Aūdany' },
  { label: 'Sozimskiy', value: 'Sozimskiy' },
  { label: 'Sozopol', value: 'Sozopol' },
  { label: 'Sozzago', value: 'Sozzago' },
  { label: 'Spa', value: 'Spa' },
  { label: 'Spaarndam-Oost', value: 'Spaarndam-Oost' },
  { label: 'Spaarndam-West', value: 'Spaarndam-West' },
  { label: 'Spaarnwoude', value: 'Spaarnwoude' },
  { label: 'Spabrücken', value: 'Spabrücken' },
  { label: 'Spackenkill', value: 'Spackenkill' },
  { label: 'Spadafora', value: 'Spadafora' },
  { label: 'Spadola', value: 'Spadola' },
  { label: 'Spaichingen', value: 'Spaichingen' },
  { label: 'Spainkbos', value: 'Spainkbos' },
  { label: 'Spakenburg', value: 'Spakenburg' },
  { label: 'Spalding', value: 'Spalding' },
  { label: 'Spalding', value: 'Spalding' },
  { label: 'Spalding County', value: 'Spalding County' },
  { label: 'Spaldings', value: 'Spaldings' },
  { label: 'Spálené Poříčí', value: 'Spálené Poříčí' },
  { label: 'Spalt', value: 'Spalt' },
  { label: 'Spanaway', value: 'Spanaway' },
  { label: 'Spanbroek', value: 'Spanbroek' },
  { label: 'Spandaryan', value: 'Spandaryan' },
  { label: 'Spandau', value: 'Spandau' },
  { label: 'Spangen', value: 'Spangen' },
  { label: 'Spangenberg', value: 'Spangenberg' },
  { label: 'Spangler', value: 'Spangler' },
  { label: 'Spanish Fork', value: 'Spanish Fork' },
  { label: 'Spanish Fort', value: 'Spanish Fort' },
  { label: 'Spanish Lake', value: 'Spanish Lake' },
  { label: 'Spanish Springs', value: 'Spanish Springs' },
  { label: 'Spanish Town', value: 'Spanish Town' },
  { label: 'Spanish Town Central', value: 'Spanish Town Central' },
  { label: 'Spanish Wells', value: 'Spanish Wells' },
  { label: 'Spannberg', value: 'Spannberg' },
  { label: 'Spanţov', value: 'Spanţov' },
  { label: 'Sparanise', value: 'Sparanise' },
  { label: 'Spardorf', value: 'Spardorf' },
  { label: 'Sparkill', value: 'Sparkill' },
  { label: 'Sparks', value: 'Sparks' },
  { label: 'Sparks', value: 'Sparks' },
  { label: 'Sparks', value: 'Sparks' },
  { label: 'Sparneck', value: 'Sparneck' },
  { label: 'Sparone', value: 'Sparone' },
  { label: 'Sparta', value: 'Sparta' },
  { label: 'Sparta', value: 'Sparta' },
  { label: 'Sparta', value: 'Sparta' },
  { label: 'Sparta', value: 'Sparta' },
  { label: 'Sparta', value: 'Sparta' },
  { label: 'Sparta', value: 'Sparta' },
  { label: 'Sparta', value: 'Sparta' },
  { label: 'Sparta', value: 'Sparta' },
  { label: 'Sparta', value: 'Sparta' },
  { label: 'Spartanburg', value: 'Spartanburg' },
  { label: 'Spartanburg County', value: 'Spartanburg County' },
  { label: 'Spárti', value: 'Spárti' },
  { label: 'Spartimento', value: 'Spartimento' },
  { label: 'Sparwood', value: 'Sparwood' },
  { label: 'Spas', value: 'Spas' },
  { label: 'Spas’Ke', value: 'Spas’Ke' },
  { label: 'Spas-Demensk', value: 'Spas-Demensk' },
  { label: 'Spas-Demenskiy Rayon', value: 'Spas-Demenskiy Rayon' },
  { label: 'Spas-Klepiki', value: 'Spas-Klepiki' },
  { label: 'Spassk', value: 'Spassk' },
  { label: 'Spassk', value: 'Spassk' },
  { label: 'Spassk-Dal’Niy', value: 'Spassk-Dal’Niy' },
  { label: 'Spasskiy Rayon', value: 'Spasskiy Rayon' },
  { label: 'Spasskiy Rayon', value: 'Spasskiy Rayon' },
  { label: 'Spasskiy Rayon', value: 'Spasskiy Rayon' },
  { label: 'Spasskoye', value: 'Spasskoye' },
  { label: 'Spasskoye', value: 'Spasskoye' },
  { label: 'Spasskoye-Lutovinovo', value: 'Spasskoye-Lutovinovo' },
  { label: 'Spassk-Ryazanskiy', value: 'Spassk-Ryazanskiy' },
  { label: 'Spas-Zaulok', value: 'Spas-Zaulok' },
  { label: 'Spáta', value: 'Spáta' },
  { label: 'Spătărei', value: 'Spătărei' },
  { label: 'Spatenhof', value: 'Spatenhof' },
  { label: 'Spaubeek', value: 'Spaubeek' },
  { label: 'Spay', value: 'Spay' },
  { label: 'Spay', value: 'Spay' },
  { label: 'Spearfish', value: 'Spearfish' },
  { label: 'Spearman', value: 'Spearman' },
  { label: 'Spearwood', value: 'Spearwood' },
  { label: 'Specchia', value: 'Specchia' },
  { label: 'Spechbach', value: 'Spechbach' },
  { label: 'Spechtenkamp', value: 'Spechtenkamp' },
  { label: 'Speedway', value: 'Speedway' },
  { label: 'Speelheide', value: 'Speelheide' },
  { label: 'Speers', value: 'Speers' },
  { label: 'Speers Point', value: 'Speers Point' },
  { label: 'Speicher', value: 'Speicher' },
  { label: 'Speicher', value: 'Speicher' },
  { label: 'Speichersdorf', value: 'Speichersdorf' },
  { label: 'Speightstown', value: 'Speightstown' },
  { label: 'Speinshart', value: 'Speinshart' },
  { label: 'Spekholzerheide', value: 'Spekholzerheide' },
  { label: 'Speldhurst', value: 'Speldhurst' },
  { label: 'Spelle', value: 'Spelle' },
  { label: 'Spello', value: 'Spello' },
  { label: 'Spence', value: 'Spence' },
  { label: 'Spencer', value: 'Spencer' },
  { label: 'Spencer', value: 'Spencer' },
  { label: 'Spencer', value: 'Spencer' },
  { label: 'Spencer', value: 'Spencer' },
  { label: 'Spencer', value: 'Spencer' },
  { label: 'Spencer', value: 'Spencer' },
  { label: 'Spencer', value: 'Spencer' },
  { label: 'Spencer', value: 'Spencer' },
  { label: 'Spencer County', value: 'Spencer County' },
  { label: 'Spencer County', value: 'Spencer County' },
  { label: 'Spencer Park', value: 'Spencer Park' },
  { label: 'Spencerport', value: 'Spencerport' },
  { label: 'Spencerville', value: 'Spencerville' },
  { label: 'Spencerville', value: 'Spencerville' },
  { label: 'Spencerville', value: 'Spencerville' },
  { label: 'Spenge', value: 'Spenge' },
  { label: 'Spennymoor', value: 'Spennymoor' },
  { label: 'Spentrup', value: 'Spentrup' },
  { label: 'Spera', value: 'Spera' },
  { label: 'Spéracèdes', value: 'Spéracèdes' },
  { label: 'Spercheiáda', value: 'Spercheiáda' },
  { label: 'Sperchógeia', value: 'Sperchógeia' },
  { label: 'Sperenberg', value: 'Sperenberg' },
  { label: 'Spergau', value: 'Spergau' },
  { label: 'Speriețeni', value: 'Speriețeni' },
  { label: 'Sperlinga', value: 'Sperlinga' },
  { label: 'Sperlonga', value: 'Sperlonga' },
  { label: 'Spermezeu', value: 'Spermezeu' },
  { label: 'Sperone', value: 'Sperone' },
  { label: 'Sperry', value: 'Sperry' },
  { label: 'Spessa', value: 'Spessa' },
  { label: 'Spetalen', value: 'Spetalen' },
  { label: 'Spétses', value: 'Spétses' },
  { label: 'Speyer', value: 'Speyer' },
  { label: 'Spézet', value: 'Spézet' },
  { label: 'Spezzano Albanese', value: 'Spezzano Albanese' },
  { label: 'Spezzano Della Sila', value: 'Spezzano Della Sila' },
  { label: 'Spezzano Piccolo', value: 'Spezzano Piccolo' },
  { label: 'Spianate', value: 'Spianate' },
  { label: 'Spiazzo', value: 'Spiazzo' },
  { label: 'Spicchio-Sovigliana', value: 'Spicchio-Sovigliana' },
  { label: 'Spicer', value: 'Spicer' },
  { label: 'Spicheren', value: 'Spicheren' },
  { label: 'Spicy Hill', value: 'Spicy Hill' },
  { label: 'Spiczyn', value: 'Spiczyn' },
  { label: 'Spiegelau', value: 'Spiegelau' },
  { label: 'Spiegelberg', value: 'Spiegelberg' },
  { label: 'Spielberg Bei Knittelfeld', value: 'Spielberg Bei Knittelfeld' },
  { label: 'Spielfeld', value: 'Spielfeld' },
  { label: 'Spierdijk', value: 'Spierdijk' },
  { label: 'Spiesen-Elversberg', value: 'Spiesen-Elversberg' },
  { label: 'Spiesheim', value: 'Spiesheim' },
  { label: 'Spiez', value: 'Spiez' },
  { label: 'Spigno Monferrato', value: 'Spigno Monferrato' },
  { label: 'Spigno Saturnia Inferiore', value: 'Spigno Saturnia Inferiore' },
  { label: 'Spigno Saturnia Superiore', value: 'Spigno Saturnia Superiore' },
  { label: 'Spijk', value: 'Spijk' },
  { label: 'Spijkenisse', value: 'Spijkenisse' },
  { label: 'Spijkerboor', value: 'Spijkerboor' },
  { label: 'Spilamberto', value: 'Spilamberto' },
  { label: 'Spilimbergo', value: 'Spilimbergo' },
  { label: 'Spilinga', value: 'Spilinga' },
  { label: 'Spillern', value: 'Spillern' },
  { label: 'Spilsby', value: 'Spilsby' },
  { label: 'Spinadesco', value: 'Spinadesco' },
  { label: 'Spinazzola', value: 'Spinazzola' },
  { label: 'Spindale', value: 'Spindale' },
  { label: 'Špindlerův Mlýn', value: 'Špindlerův Mlýn' },
  { label: 'Spinea-Orgnano', value: 'Spinea-Orgnano' },
  { label: 'Spineda', value: 'Spineda' },
  { label: 'Spinete', value: 'Spinete' },
  { label: 'Spineto Scrivia', value: 'Spineto Scrivia' },
  { label: 'Spinetoli', value: 'Spinetoli' },
  { label: 'Spinetta Marengo', value: 'Spinetta Marengo' },
  { label: 'Spinimbecco', value: 'Spinimbecco' },
  { label: 'Spink County', value: 'Spink County' },
  { label: 'Spinnerstown', value: 'Spinnerstown' },
  { label: 'Spino Dadda', value: 'Spino Dadda' },
  { label: 'Spinone Al Lago', value: 'Spinone Al Lago' },
  { label: 'Spinoso', value: 'Spinoso' },
  { label: 'Spinuş', value: 'Spinuş' },
  { label: 'Spirano', value: 'Spirano' },
  { label: 'Spiridonovka', value: 'Spiridonovka' },
  { label: 'Spirit Lake', value: 'Spirit Lake' },
  { label: 'Spirit Lake', value: 'Spirit Lake' },
  { label: 'Spirit River', value: 'Spirit River' },
  { label: 'Spiro', value: 'Spiro' },
  { label: 'Spirovo', value: 'Spirovo' },
  { label: 'Spirovskiy Rayon', value: 'Spirovskiy Rayon' },
  { label: 'Spiru Haret', value: 'Spiru Haret' },
  { label: 'Špišić Bukovica', value: 'Špišić Bukovica' },
  { label: 'Spišská Belá', value: 'Spišská Belá' },
  { label: 'Spišská Nová Ves', value: 'Spišská Nová Ves' },
  { label: 'Spišské Podhradie', value: 'Spišské Podhradie' },
  { label: 'Spitak', value: 'Spitak' },
  { label: 'Spital Am Pyhrn', value: 'Spital Am Pyhrn' },
  { label: 'Spital Am Semmering', value: 'Spital Am Semmering' },
  { label: 'Spitsevka', value: 'Spitsevka' },
  { label: 'Spittal', value: 'Spittal' },
  { label: 'Spittal An Der Drau', value: 'Spittal An Der Drau' },
  { label: 'Spittal An Der Drau District', value: 'Spittal An Der Drau District' },
  { label: 'Spitz', value: 'Spitz' },
  { label: 'Spixworth', value: 'Spixworth' },
  { label: 'Splendora', value: 'Splendora' },
  { label: 'Split', value: 'Split' },
  { label: 'Spodnja Hajdina', value: 'Spodnja Hajdina' },
  { label: 'Spodnja Idrija', value: 'Spodnja Idrija' },
  { label: 'Spodnje Hoče', value: 'Spodnje Hoče' },
  { label: 'Spodnje Škofije', value: 'Spodnje Škofije' },
  { label: 'Spodnji Duplek', value: 'Spodnji Duplek' },
  { label: 'Spofforth', value: 'Spofforth' },
  { label: 'Spokane', value: 'Spokane' },
  { label: 'Spokane County', value: 'Spokane County' },
  { label: 'Spokane Valley', value: 'Spokane Valley' },
  { label: 'Spokoynaya', value: 'Spokoynaya' },
  { label: 'Spoleto', value: 'Spoleto' },
  { label: 'Spoltore', value: 'Spoltore' },
  { label: 'Spongano', value: 'Spongano' },
  { label: 'Spooner', value: 'Spooner' },
  { label: 'Spoorwijk', value: 'Spoorwijk' },
  { label: 'Spormaggiore', value: 'Spormaggiore' },
  { label: 'Sporminore', value: 'Sporminore' },
  { label: 'Spornitz', value: 'Spornitz' },
  { label: 'Spot Valley', value: 'Spot Valley' },
  { label: 'Spotorno', value: 'Spotorno' },
  { label: 'Spotswood', value: 'Spotswood' },
  { label: 'Spotswood', value: 'Spotswood' },
  { label: 'Spotsylvania County', value: 'Spotsylvania County' },
  { label: 'Spotsylvania Courthouse', value: 'Spotsylvania Courthouse' },
  { label: 'Spraitbach', value: 'Spraitbach' },
  { label: 'Sprakensehl', value: 'Sprakensehl' },
  { label: 'Sprâncenata', value: 'Sprâncenata' },
  { label: 'Spratton', value: 'Spratton' },
  { label: 'Spratzern', value: 'Spratzern' },
  { label: 'Spreenhagen', value: 'Spreenhagen' },
  { label: 'Spreitenbach', value: 'Spreitenbach' },
  { label: 'Sprendlingen', value: 'Sprendlingen' },
  { label: 'Sprengenbos', value: 'Sprengenbos' },
  { label: 'Sprengenweg-Noord', value: 'Sprengenweg-Noord' },
  { label: 'Sprenkelaar', value: 'Sprenkelaar' },
  { label: 'Spresiano', value: 'Spresiano' },
  { label: 'Spreyton', value: 'Spreyton' },
  { label: 'Spriana', value: 'Spriana' },
  { label: 'Sprimont', value: 'Sprimont' },
  { label: 'Spring', value: 'Spring' },
  { label: 'Şpring', value: 'Şpring' },
  { label: 'Spring Arbor', value: 'Spring Arbor' },
  { label: 'Spring Bank', value: 'Spring Bank' },
  { label: 'Spring City', value: 'Spring City' },
  { label: 'Spring City', value: 'Spring City' },
  { label: 'Spring City', value: 'Spring City' },
  { label: 'Spring Creek', value: 'Spring Creek' },
  { label: 'Spring Farm', value: 'Spring Farm' },
  { label: 'Spring Garden', value: 'Spring Garden' },
  { label: 'Spring Garden', value: 'Spring Garden' },
  { label: 'Spring Glen', value: 'Spring Glen' },
  { label: 'Spring Green', value: 'Spring Green' },
  { label: 'Spring Ground', value: 'Spring Ground' },
  { label: 'Spring Grove', value: 'Spring Grove' },
  { label: 'Spring Grove', value: 'Spring Grove' },
  { label: 'Spring Grove', value: 'Spring Grove' },
  { label: 'Spring Hill', value: 'Spring Hill' },
  { label: 'Spring Hill', value: 'Spring Hill' },
  { label: 'Spring Hill', value: 'Spring Hill' },
  { label: 'Spring Hill', value: 'Spring Hill' },
  { label: 'Spring Hill', value: 'Spring Hill' },
  { label: 'Spring Hope', value: 'Spring Hope' },
  { label: 'Spring House', value: 'Spring House' },
  { label: 'Spring Lake', value: 'Spring Lake' },
  { label: 'Spring Lake', value: 'Spring Lake' },
  { label: 'Spring Lake', value: 'Spring Lake' },
  { label: 'Spring Lake Heights', value: 'Spring Lake Heights' },
  { label: 'Spring Lake Park', value: 'Spring Lake Park' },
  { label: 'Spring Mount', value: 'Spring Mount' },
  { label: 'Spring Park', value: 'Spring Park' },
  { label: 'Spring Ridge', value: 'Spring Ridge' },
  { label: 'Spring Ridge', value: 'Spring Ridge' },
  { label: 'Spring Valley', value: 'Spring Valley' },
  { label: 'Spring Valley', value: 'Spring Valley' },
  { label: 'Spring Valley', value: 'Spring Valley' },
  { label: 'Spring Valley', value: 'Spring Valley' },
  { label: 'Spring Valley', value: 'Spring Valley' },
  { label: 'Spring Valley', value: 'Spring Valley' },
  { label: 'Spring Valley', value: 'Spring Valley' },
  { label: 'Spring Valley', value: 'Spring Valley' },
  { label: 'Spring Valley Lake', value: 'Spring Valley Lake' },
  { label: 'Springbok', value: 'Springbok' },
  { label: 'Springboro', value: 'Springboro' },
  { label: 'Springbrook', value: 'Springbrook' },
  { label: 'Springdale', value: 'Springdale' },
  { label: 'Springdale', value: 'Springdale' },
  { label: 'Springdale', value: 'Springdale' },
  { label: 'Springdale', value: 'Springdale' },
  { label: 'Springdale', value: 'Springdale' },
  { label: 'Springdale', value: 'Springdale' },
  { label: 'Springdale', value: 'Springdale' },
  { label: 'Springdale Heights', value: 'Springdale Heights' },
  { label: 'Springe', value: 'Springe' },
  { label: 'Springerville', value: 'Springerville' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield', value: 'Springfield' },
  { label: 'Springfield Gardens', value: 'Springfield Gardens' },
  { label: 'Springfield Lakes', value: 'Springfield Lakes' },
  { label: 'Springhill', value: 'Springhill' },
  { label: 'Springhill', value: 'Springhill' },
  { label: 'Springs', value: 'Springs' },
  { label: 'Springs', value: 'Springs' },
  { label: 'Springside', value: 'Springside' },
  { label: 'Springsure', value: 'Springsure' },
  { label: 'Springtown', value: 'Springtown' },
  { label: 'Springvale', value: 'Springvale' },
  { label: 'Springvale', value: 'Springvale' },
  { label: 'Springvale', value: 'Springvale' },
  { label: 'Springvale South', value: 'Springvale South' },
  { label: 'Springview', value: 'Springview' },
  { label: 'Springville', value: 'Springville' },
  { label: 'Springville', value: 'Springville' },
  { label: 'Springville', value: 'Springville' },
  { label: 'Springville', value: 'Springville' },
  { label: 'Springville', value: 'Springville' },
  { label: 'Springwood', value: 'Springwood' },
  { label: 'Springwood', value: 'Springwood' },
  { label: 'Sproatley', value: 'Sproatley' },
  { label: 'Sprockhövel', value: 'Sprockhövel' },
  { label: 'Spruce Grove', value: 'Spruce Grove' },
  { label: 'Spruce Pine', value: 'Spruce Pine' },
  { label: 'Sprundel', value: 'Sprundel' },
  { label: 'Spry', value: 'Spry' },
  { label: 'Spulber', value: 'Spulber' },
  { label: 'Spur', value: 'Spur' },
  { label: 'Spur Tree', value: 'Spur Tree' },
  { label: 'Spurgeon', value: 'Spurgeon' },
  { label: 'Spuyten Duyvil', value: 'Spuyten Duyvil' },
  { label: 'Spuž', value: 'Spuž' },
  { label: 'Spycker', value: 'Spycker' },
  { label: 'Spydeberg', value: 'Spydeberg' },
  { label: 'Spytihněv', value: 'Spytihněv' },
  { label: 'Spytkowice', value: 'Spytkowice' },
  { label: 'Squaw Valley', value: 'Squaw Valley' },
  { label: 'Squillace', value: 'Squillace' },
  { label: 'Squillace Lido', value: 'Squillace Lido' },
  { label: 'Squinzano', value: 'Squinzano' },
  { label: 'Sračinec', value: 'Sračinec' },
  { label: 'Srae Ambel', value: 'Srae Ambel' },
  { label: 'Sragen', value: 'Sragen' },
  { label: 'Sravana Belgola', value: 'Sravana Belgola' },
  { label: 'Srbac', value: 'Srbac' },
  { label: 'Srbica', value: 'Srbica' },
  { label: 'Srbinovo', value: 'Srbinovo' },
  { label: 'Srebrenica', value: 'Srebrenica' },
  { label: 'Srebrenik', value: 'Srebrenik' },
  { label: 'Sredets', value: 'Sredets' },
  { label: 'Središče Ob Dravi', value: 'Središče Ob Dravi' },
  { label: 'Srednebelaya', value: 'Srednebelaya' },
  { label: 'Srednekanskiy Rayon', value: 'Srednekanskiy Rayon' },
  { label: 'Srednekolymsk', value: 'Srednekolymsk' },
  { label: 'Srednekolymsky District', value: 'Srednekolymsky District' },
  { label: 'Sredneuralsk', value: 'Sredneuralsk' },
  { label: 'Sredniy', value: 'Sredniy' },
  { label: 'Sredniy Ikorets', value: 'Sredniy Ikorets' },
  { label: 'Sredno Konjare', value: 'Sredno Konjare' },
  { label: 'Srednyaya Akhtuba', value: 'Srednyaya Akhtuba' },
  { label: 'Srednyaya Yelyuzan’', value: 'Srednyaya Yelyuzan’' },
  { label: 'Śrem', value: 'Śrem' },
  { label: 'Sremčica', value: 'Sremčica' },
  { label: 'Sremska Mitrovica', value: 'Sremska Mitrovica' },
  { label: 'Sremski Karlovci', value: 'Sremski Karlovci' },
  { label: 'Sretensk', value: 'Sretensk' },
  { label: 'Sretenskiy Rayon', value: 'Sretenskiy Rayon' },
  { label: 'Sri Dungargarh', value: 'Sri Dungargarh' },
  { label: 'Sri Jayewardenepura Kotte', value: 'Sri Jayewardenepura Kotte' },
  { label: 'Sri Madhopur', value: 'Sri Madhopur' },
  { label: 'Sri Muktsar Sahib', value: 'Sri Muktsar Sahib' },
  { label: 'Sribne', value: 'Sribne' },
  { label: 'Srikakulam', value: 'Srikakulam' },
  { label: 'Srikhanda', value: 'Srikhanda' },
  { label: 'Srimushnam', value: 'Srimushnam' },
  { label: 'Srinagar', value: 'Srinagar' },
  { label: 'Srinagar', value: 'Srinagar' },
  { label: 'Sringeri', value: 'Sringeri' },
  { label: 'Srinivaspur', value: 'Srinivaspur' },
  { label: 'Srinjine', value: 'Srinjine' },
  { label: 'Sriperumbudur', value: 'Sriperumbudur' },
  { label: 'Sriramnagar', value: 'Sriramnagar' },
  { label: 'Srirampur', value: 'Srirampur' },
  { label: 'Srisailain', value: 'Srisailain' },
  { label: 'Srivaikuntam', value: 'Srivaikuntam' },
  { label: 'Srivardhan', value: 'Srivardhan' },
  { label: 'Srivilliputhur', value: 'Srivilliputhur' },
  { label: 'Srmast', value: 'Srmast' },
  { label: 'Środa Śląska', value: 'Środa Śląska' },
  { label: 'Środa Wielkopolska', value: 'Środa Wielkopolska' },
  { label: 'Śródmieście', value: 'Śródmieście' },
  { label: 'Srŏk Âk Phnŭm', value: 'Srŏk Âk Phnŭm' },
  { label: 'Srŏk Ândong Méas', value: 'Srŏk Ândong Méas' },
  { label: 'Srŏk Ângkôr Borei', value: 'Srŏk Ângkôr Borei' },
  { label: 'Srŏk Ângkôr Thum', value: 'Srŏk Ângkôr Thum' },
  { label: 'Srŏk Bâ Kêv', value: 'Srŏk Bâ Kêv' },
  { label: 'Srŏk Ban Lŭng', value: 'Srŏk Ban Lŭng' },
  { label: 'Srŏk Banăn', value: 'Srŏk Banăn' },
  { label: 'Srŏk Bântéay Âmpĭl', value: 'Srŏk Bântéay Âmpĭl' },
  { label: 'Srŏk Basedth', value: 'Srŏk Basedth' },
  { label: 'Srŏk Bathéay', value: 'Srŏk Bathéay' },
  { label: 'Srŏk Batum Sakôr', value: 'Srŏk Batum Sakôr' },
  { label: 'Srŏk Bâvĭl', value: 'Srŏk Bâvĭl' },
  { label: 'Srŏk Borei Cholsar', value: 'Srŏk Borei Cholsar' },
  { label: 'Srŏk Ch’Êh Sên', value: 'Srŏk Ch’Êh Sên' },
  { label: 'Srŏk Chhêb', value: 'Srŏk Chhêb' },
  { label: 'Srŏk Chol Kiri', value: 'Srŏk Chol Kiri' },
  { label: 'Srŏk Chŭm Kiri', value: 'Srŏk Chŭm Kiri' },
  { label: 'Srŏk Dâmnăk Châng’Aeur', value: 'Srŏk Dâmnăk Châng’Aeur' },
  { label: 'Srŏk Dângtóng', value: 'Srŏk Dângtóng' },
  { label: 'Srŏk Kaev Seima', value: 'Srŏk Kaev Seima' },
  { label: 'Srŏk Kâmpóng Léav', value: 'Srŏk Kâmpóng Léav' },
  { label: 'Srŏk Kândiĕng', value: 'Srŏk Kândiĕng' },
  { label: 'Srŏk Khsăch Kândal', value: 'Srŏk Khsăch Kândal' },
  { label: 'Srŏk Kông Pĭsei', value: 'Srŏk Kông Pĭsei' },
  { label: 'Srŏk Koun Mom', value: 'Srŏk Koun Mom' },
  { label: 'Srŏk Kulén', value: 'Srŏk Kulén' },
  { label: 'Srŏk Malai', value: 'Srŏk Malai' },
  { label: 'Srŏk Mésang', value: 'Srŏk Mésang' },
  { label: 'Srŏk Môndôl Seima', value: 'Srŏk Môndôl Seima' },
  { label: 'Srŏk Ŏdŏngk', value: 'Srŏk Ŏdŏngk' },
  { label: 'Srŏk Ou Chum', value: 'Srŏk Ou Chum' },
  { label: 'Srŏk Ou Ya Dav', value: 'Srŏk Ou Ya Dav' },
  { label: 'Srŏk Pech Chreada', value: 'Srŏk Pech Chreada' },
  { label: 'Srŏk Prasat Bakong', value: 'Srŏk Prasat Bakong' },
  { label: 'Srŏk Preăh Sdéch', value: 'Srŏk Preăh Sdéch' },
  { label: 'Srŏk Rotanak Mondol', value: 'Srŏk Rotanak Mondol' },
  { label: 'Srŏk Rôviĕng', value: 'Srŏk Rôviĕng' },
  { label: 'Srŏk Sameakki Mean Chey', value: 'Srŏk Sameakki Mean Chey' },
  { label: 'Srŏk Sâmraông', value: 'Srŏk Sâmraông' },
  { label: 'Srŏk Srêsén', value: 'Srŏk Srêsén' },
  { label: 'Srok Stueng Hav', value: 'Srok Stueng Hav' },
  { label: 'Srŏk Svay Chék', value: 'Srŏk Svay Chék' },
  { label: 'Srŏk Svay Chrŭm', value: 'Srŏk Svay Chrŭm' },
  { label: 'Srŏk Ta Vêng', value: 'Srŏk Ta Vêng' },
  { label: 'Srŏk Tbêng Méanchey', value: 'Srŏk Tbêng Méanchey' },
  { label: 'Srŏk Trâpeăng Prasat', value: 'Srŏk Trâpeăng Prasat' },
  { label: 'Srok Tuek Chhou', value: 'Srok Tuek Chhou' },
  { label: 'Srŏk Véal Vêng', value: 'Srŏk Véal Vêng' },
  { label: 'Srokowo', value: 'Srokowo' },
  { label: 'Srono', value: 'Srono' },
  { label: 'Srostki', value: 'Srostki' },
  { label: 'Srpska Crnja', value: 'Srpska Crnja' },
  { label: 'Srpski Itebej', value: 'Srpski Itebej' },
  { label: 'Srubec', value: 'Srubec' },
  { label: 'St Agnes', value: 'St Agnes' },
  { label: 'St Albans', value: 'St Albans' },
  { label: 'St Albans Park', value: 'St Albans Park' },
  { label: 'St Andrews', value: 'St Andrews' },
  { label: 'St Anton Am Arlberg', value: 'St Anton Am Arlberg' },
  { label: 'St Aubin', value: 'St Aubin' },
  { label: 'St Austell', value: 'St Austell' },
  { label: 'St Clair', value: 'St Clair' },
  { label: 'St George', value: 'St George' },
  { label: 'St George Ranges', value: 'St George Ranges' },
  { label: 'St Georges', value: 'St Georges' },
  { label: 'St Helen Estate', value: 'St Helen Estate' },
  { label: 'St Helena', value: 'St Helena' },
  { label: 'St Helens', value: 'St Helens' },
  { label: 'St Helens', value: 'St Helens' },
  { label: 'St Helens Park', value: 'St Helens Park' },
  { label: 'St Huberts Island', value: 'St Huberts Island' },
  { label: 'St Ives', value: 'St Ives' },
  { label: 'St Ives Chase', value: 'St Ives Chase' },
  { label: 'St James', value: 'St James' },
  { label: 'St Johns Park', value: 'St Johns Park' },
  { label: 'St Johnsbury', value: 'St Johnsbury' },
  { label: 'St Judes Highway', value: 'St Judes Highway' },
  { label: 'St Just', value: 'St Just' },
  { label: 'St Kilda East', value: 'St Kilda East' },
  { label: 'St Kilda West', value: 'St Kilda West' },
  { label: 'St Lawrence', value: 'St Lawrence' },
  { label: 'St Lawrence Estate', value: 'St Lawrence Estate' },
  { label: 'St Leonards', value: 'St Leonards' },
  { label: 'St Leonards', value: 'St Leonards' },
  { label: 'St Marys', value: 'St Marys' },
  { label: 'St Marys', value: 'St Marys' },
  { label: 'St Marys Bay', value: 'St Marys Bay' },
  { label: 'St Morris', value: 'St Morris' },
  { label: 'St Peters', value: 'St Peters' },
  { label: 'St Phillip', value: 'St Phillip' },
  { label: 'St. Adolphe', value: 'St. Adolphe' },
  { label: 'St. Agathen Und Perau', value: 'St. Agathen Und Perau' },
  { label: 'St. Albert', value: 'St. Albert' },
  { label: 'St. Andrä', value: 'St. Andrä' },
  { label: 'St. Anns Bay', value: 'St. Anns Bay' },
  { label: 'St. Anthony', value: 'St. Anthony' },
  { label: 'St. Catharines', value: 'St. Catharines' },
  { label: 'St. Dacre', value: 'St. Dacre' },
  { label: 'St. Day', value: 'St. Day' },
  { label: 'St. Georg', value: 'St. Georg' },
  { label: 'St. George', value: 'St. George' },
  { label: 'St. Georges', value: 'St. Georges' },
  { label: 'St. Georges Basin', value: 'St. Georges Basin' },
  { label: 'St. Helens', value: 'St. Helens' },
  { label: 'St. Johns', value: 'St. Johns' },
  { label: 'St. Johns', value: 'St. Johns' },
  { label: 'St. Johns', value: 'St. Johns' },
  { label: 'St. Johns', value: 'St. Johns' },
  { label: 'St. Johns East', value: 'St. Johns East' },
  { label: 'St. Johns West', value: 'St. Johns West' },
  { label: 'St. Joseph Estate', value: 'St. Joseph Estate' },
  { label: 'St. Lawrence County', value: 'St. Lawrence County' },
  { label: 'St. Leonards', value: 'St. Leonards' },
  { label: 'St. Louis', value: 'St. Louis' },
  { label: 'St. Margareten Im Rosental', value: 'St. Margareten Im Rosental' },
  { label: 'St. Margarets Bay', value: 'St. Margarets Bay' },
  { label: 'St. Martin Of Tennengebirge', value: 'St. Martin Of Tennengebirge' },
  { label: 'St. Marys', value: 'St. Marys' },
  { label: 'St. Moritz', value: 'St. Moritz' },
  { label: 'St. Paul Im Lavanttal', value: 'St. Paul Im Lavanttal' },
  { label: 'St. Pauli', value: 'St. Pauli' },
  { label: 'St. Pauls', value: 'St. Pauls' },
  { label: 'St. Peters', value: 'St. Peters' },
  { label: 'St. Petersburg', value: 'St. Petersburg' },
  { label: 'St. Stefan Im Gailtal', value: 'St. Stefan Im Gailtal' },
  { label: 'St. Thomas', value: 'St. Thomas' },
  { label: 'St. Urban', value: 'St. Urban' },
  { label: 'St. Veit An Der Glan', value: 'St. Veit An Der Glan' },
  { label: 'St. Veiter Vorstadt', value: 'St. Veiter Vorstadt' },
  { label: 'St. Vincent', value: 'St. Vincent' },
  { label: 'Staaken', value: 'Staaken' },
  { label: 'Staatsliedenkwartier', value: 'Staatsliedenkwartier' },
  { label: 'Staatz', value: 'Staatz' },
  { label: 'Stabat', value: 'Stabat' },
  { label: 'Stabbia', value: 'Stabbia' },
  { label: 'Stäbelow', value: 'Stäbelow' },
  { label: 'Stabio', value: 'Stabio' },
  { label: 'Stabłowice', value: 'Stabłowice' },
  { label: 'Stabroek', value: 'Stabroek' },
  { label: 'Stachy', value: 'Stachy' },
  { label: 'Stacy', value: 'Stacy' },
  { label: 'Stadbroek', value: 'Stadbroek' },
  { label: 'Stade', value: 'Stade' },
  { label: 'Stadecken-Elsheim', value: 'Stadecken-Elsheim' },
  { label: 'Stadelhofen', value: 'Stadelhofen' },
  { label: 'Staden', value: 'Staden' },
  { label: 'Stadensen', value: 'Stadensen' },
  { label: 'Stadium Gardens', value: 'Stadium Gardens' },
  { label: 'Stadl An Der Mur', value: 'Stadl An Der Mur' },
  { label: 'Stadl-Hausruck', value: 'Stadl-Hausruck' },
  { label: 'Stadl-Paura', value: 'Stadl-Paura' },
  { label: 'Stadl-Traun', value: 'Stadl-Traun' },
  { label: 'Stadsfenne', value: 'Stadsfenne' },
  { label: 'Stadskanaal', value: 'Stadskanaal' },
  { label: 'Stadt Wehlen', value: 'Stadt Wehlen' },
  { label: 'Stadtallendorf', value: 'Stadtallendorf' },
  { label: 'Stadtbergen', value: 'Stadtbergen' },
  { label: 'Stadtbredimus', value: 'Stadtbredimus' },
  { label: 'Stadthagen', value: 'Stadthagen' },
  { label: 'Stadtilm', value: 'Stadtilm' },
  { label: 'Stadtkyll', value: 'Stadtkyll' },
  { label: 'Stadtlauringen', value: 'Stadtlauringen' },
  { label: 'Stadtlengsfeld', value: 'Stadtlengsfeld' },
  { label: 'Stadtlohn', value: 'Stadtlohn' },
  { label: 'Stadtoldendorf', value: 'Stadtoldendorf' },
  { label: 'Stadtprozelten', value: 'Stadtprozelten' },
  { label: 'Stadtrandsiedlung Malchow', value: 'Stadtrandsiedlung Malchow' },
  { label: 'Stadtroda', value: 'Stadtroda' },
  { label: 'Stadtschlaining', value: 'Stadtschlaining' },
  { label: 'Stadtsteinach', value: 'Stadtsteinach' },
  { label: 'Stadum', value: 'Stadum' },
  { label: 'Stäfa', value: 'Stäfa' },
  { label: 'Staffa', value: 'Staffa' },
  { label: 'Staffanstorp', value: 'Staffanstorp' },
  { label: 'Staffelbach', value: 'Staffelbach' },
  { label: 'Staffelfelden', value: 'Staffelfelden' },
  { label: 'Staffolo', value: 'Staffolo' },
  { label: 'Stafford', value: 'Stafford' },
  { label: 'Stafford', value: 'Stafford' },
  { label: 'Stafford', value: 'Stafford' },
  { label: 'Stafford', value: 'Stafford' },
  { label: 'Stafford', value: 'Stafford' },
  { label: 'Stafford', value: 'Stafford' },
  { label: 'Stafford County', value: 'Stafford County' },
  { label: 'Stafford County', value: 'Stafford County' },
  { label: 'Stafford Heights', value: 'Stafford Heights' },
  { label: 'Stafford Springs', value: 'Stafford Springs' },
  { label: 'Staffordshire', value: 'Staffordshire' },
  { label: 'Stagecoach', value: 'Stagecoach' },
  { label: 'Staggia', value: 'Staggia' },
  { label: 'Stagno Lombardo', value: 'Stagno Lombardo' },
  { label: 'Stagsden', value: 'Stagsden' },
  { label: 'Šťáhlavy', value: 'Šťáhlavy' },
  { label: 'Stahnsdorf', value: 'Stahnsdorf' },
  { label: 'Staicele', value: 'Staicele' },
  { label: 'Staig', value: 'Staig' },
  { label: 'Stainach', value: 'Stainach' },
  { label: 'Stainburn', value: 'Stainburn' },
  { label: 'Staindrop', value: 'Staindrop' },
  { label: 'Staines', value: 'Staines' },
  { label: 'Stainforth', value: 'Stainforth' },
  { label: 'Stains', value: 'Stains' },
  { label: 'Stainz', value: 'Stainz' },
  { label: 'Stainz Bei Straden', value: 'Stainz Bei Straden' },
  { label: 'Staiti', value: 'Staiti' },
  { label: 'Stajićevo', value: 'Stajićevo' },
  { label: 'Stakeford', value: 'Stakeford' },
  { label: 'Stakhanovka', value: 'Stakhanovka' },
  { label: 'Stal’Noye', value: 'Stal’Noye' },
  { label: 'Stalbe', value: 'Stalbe' },
  { label: 'Stalbridge', value: 'Stalbridge' },
  { label: 'Stalden', value: 'Stalden' },
  { label: 'Staletti', value: 'Staletti' },
  { label: 'Stalham', value: 'Stalham' },
  { label: 'Stalís', value: 'Stalís' },
  { label: 'Stall', value: 'Stall' },
  { label: 'Stallarholmen', value: 'Stallarholmen' },
  { label: 'Stallavena-Lugo', value: 'Stallavena-Lugo' },
  { label: 'Stallehr', value: 'Stallehr' },
  { label: 'Stallhof', value: 'Stallhof' },
  { label: 'Stallhofen', value: 'Stallhofen' },
  { label: 'Stallingborough', value: 'Stallingborough' },
  { label: 'Stallings', value: 'Stallings' },
  { label: 'Stallion Springs', value: 'Stallion Springs' },
  { label: 'Stallwang', value: 'Stallwang' },
  { label: 'Stalowa Wola', value: 'Stalowa Wola' },
  { label: 'Stâlpeni', value: 'Stâlpeni' },
  { label: 'Stâlpu', value: 'Stâlpu' },
  { label: 'Stalybridge', value: 'Stalybridge' },
  { label: 'Stamáta', value: 'Stamáta' },
  { label: 'Stamate', value: 'Stamate' },
  { label: 'Stambach', value: 'Stambach' },
  { label: 'Stambaugh Iron River', value: 'Stambaugh Iron River' },
  { label: 'Stamboliyski', value: 'Stamboliyski' },
  { label: 'Stamford', value: 'Stamford' },
  { label: 'Stamford', value: 'Stamford' },
  { label: 'Stamford', value: 'Stamford' },
  { label: 'Stamford', value: 'Stamford' },
  { label: 'Stamford Bridge', value: 'Stamford Bridge' },
  { label: 'Stammbach', value: 'Stammbach' },
  { label: 'Stammham', value: 'Stammham' },
  { label: 'Stampersgat', value: 'Stampersgat' },
  { label: 'Stamps', value: 'Stamps' },
  { label: 'Stams', value: 'Stams' },
  { label: 'Stamsried', value: 'Stamsried' },
  { label: 'Stamullin', value: 'Stamullin' },
  { label: 'Stan’Kava', value: 'Stan’Kava' },
  { label: 'Stâna', value: 'Stâna' },
  { label: 'Stanaford', value: 'Stanaford' },
  { label: 'Stanardsville', value: 'Stanardsville' },
  { label: 'Stanari', value: 'Stanari' },
  { label: 'Stan-Bekhtemir', value: 'Stan-Bekhtemir' },
  { label: 'Stanberry', value: 'Stanberry' },
  { label: 'Stanbridge', value: 'Stanbridge' },
  { label: 'Stânca', value: 'Stânca' },
  { label: 'Stancea', value: 'Stancea' },
  { label: 'Stânceni', value: 'Stânceni' },
  { label: 'Stâncești', value: 'Stâncești' },
  { label: 'Stăncești', value: 'Stăncești' },
  { label: 'Stăncuţa', value: 'Stăncuţa' },
  { label: 'Standdaarbuiten', value: 'Standdaarbuiten' },
  { label: 'Standerton', value: 'Standerton' },
  { label: 'Standish', value: 'Standish' },
  { label: 'Standlake', value: 'Standlake' },
  { label: 'Standon', value: 'Standon' },
  { label: 'Stăneşti', value: 'Stăneşti' },
  { label: 'Stăneşti', value: 'Stăneşti' },
  { label: 'Stănești', value: 'Stănești' },
  { label: 'Stanfield', value: 'Stanfield' },
  { label: 'Stanfield', value: 'Stanfield' },
  { label: 'Stanford', value: 'Stanford' },
  { label: 'Stanford', value: 'Stanford' },
  { label: 'Stanford', value: 'Stanford' },
  { label: 'Stanford In The Vale', value: 'Stanford In The Vale' },
  { label: 'Stanford-Le-Hope', value: 'Stanford-Le-Hope' },
  { label: 'Stângăceaua', value: 'Stângăceaua' },
  { label: 'Stångby', value: 'Stångby' },
  { label: 'Stange', value: 'Stange' },
  { label: 'Stanghe', value: 'Stanghe' },
  { label: 'Stanghella', value: 'Stanghella' },
  { label: 'Stanhope', value: 'Stanhope' },
  { label: 'Stanhope', value: 'Stanhope' },
  { label: 'Stanhope Gardens', value: 'Stanhope Gardens' },
  { label: 'Stănileşti', value: 'Stănileşti' },
  { label: 'Stănişeşti', value: 'Stănişeşti' },
  { label: 'Stanišić', value: 'Stanišić' },
  { label: 'Stanislaus County', value: 'Stanislaus County' },
  { label: 'Stanislav', value: 'Stanislav' },
  { label: 'Stanisław Dolny', value: 'Stanisław Dolny' },
  { label: 'Stanisław Górny', value: 'Stanisław Górny' },
  { label: 'Stanisławice', value: 'Stanisławice' },
  { label: 'Stanisławów', value: 'Stanisławów' },
  { label: 'Stanisławów Pierwszy', value: 'Stanisławów Pierwszy' },
  { label: 'Stăniţa', value: 'Stăniţa' },
  { label: 'Staňkov', value: 'Staňkov' },
  { label: 'Stanley', value: 'Stanley' },
  { label: 'Stanley', value: 'Stanley' },
  { label: 'Stanley', value: 'Stanley' },
  { label: 'Stanley', value: 'Stanley' },
  { label: 'Stanley', value: 'Stanley' },
  { label: 'Stanley', value: 'Stanley' },
  { label: 'Stanley', value: 'Stanley' },
  { label: 'Stanley County', value: 'Stanley County' },
  { label: 'Stanley Peninsula', value: 'Stanley Peninsula' },
  { label: 'Stanleytown', value: 'Stanleytown' },
  { label: 'Stanly County', value: 'Stanly County' },
  { label: 'Stanmore', value: 'Stanmore' },
  { label: 'Stanmore', value: 'Stanmore' },
  { label: 'Stannington', value: 'Stannington' },
  { label: 'Stános', value: 'Stános' },
  { label: 'Stanovlyanskiy Rayon', value: 'Stanovlyanskiy Rayon' },
  { label: 'Stanovoye', value: 'Stanovoye' },
  { label: 'Stanowice', value: 'Stanowice' },
  { label: 'Stanowice', value: 'Stanowice' },
  { label: 'Stans', value: 'Stans' },
  { label: 'Stans', value: 'Stans' },
  { label: 'Stansbury Park', value: 'Stansbury Park' },
  { label: 'Stansstad', value: 'Stansstad' },
  { label: 'Stansted Mountfitchet', value: 'Stansted Mountfitchet' },
  { label: 'Stanthorpe', value: 'Stanthorpe' },
  { label: 'Stanton', value: 'Stanton' },
  { label: 'Stanton', value: 'Stanton' },
  { label: 'Stanton', value: 'Stanton' },
  { label: 'Stanton', value: 'Stanton' },
  { label: 'Stanton', value: 'Stanton' },
  { label: 'Stanton', value: 'Stanton' },
  { label: 'Stanton County', value: 'Stanton County' },
  { label: 'Stanton County', value: 'Stanton County' },
  { label: 'Stantsia Staritsa', value: 'Stantsia Staritsa' },
  { label: 'Stantsionno-Oyashinskiy', value: 'Stantsionno-Oyashinskiy' },
  { label: 'Stantsiya Novyy Afon', value: 'Stantsiya Novyy Afon' },
  { label: 'Stanwell Park', value: 'Stanwell Park' },
  { label: 'Stanwick', value: 'Stanwick' },
  { label: 'Stanwood', value: 'Stanwood' },
  { label: 'Stanytsya Luhans’Ka', value: 'Stanytsya Luhans’Ka' },
  { label: 'Stanz Bei Landeck', value: 'Stanz Bei Landeck' },
  { label: 'Stanzach', value: 'Stanzach' },
  { label: 'Stapelburg', value: 'Stapelburg' },
  { label: 'Stapelfeld', value: 'Stapelfeld' },
  { label: 'Staphorst', value: 'Staphorst' },
  { label: 'Staplehurst', value: 'Staplehurst' },
  { label: 'Staples', value: 'Staples' },
  { label: 'Stapleton', value: 'Stapleton' },
  { label: 'Stapleton', value: 'Stapleton' },
  { label: 'Stąporków', value: 'Stąporków' },
  { label: 'Star', value: 'Star' },
  { label: 'Star City', value: 'Star City' },
  { label: 'Star City', value: 'Star City' },
  { label: 'Star Dojran', value: 'Star Dojran' },
  { label: 'Star Valley', value: 'Star Valley' },
  { label: 'Star Valley Ranch', value: 'Star Valley Ranch' },
  { label: 'Star’', value: 'Star’' },
  { label: 'Stara Błotnica', value: 'Stara Błotnica' },
  { label: 'Stara Dąbrowa', value: 'Stara Dąbrowa' },
  { label: 'Stará Huť', value: 'Stará Huť' },
  { label: 'Stara Kamienica', value: 'Stara Kamienica' },
  { label: 'Stara Kiszewa', value: 'Stara Kiszewa' },
  { label: 'Stara Kornica', value: 'Stara Kornica' },
  { label: 'Stara Kresna', value: 'Stara Kresna' },
  { label: 'Stará Ľubovňa', value: 'Stará Ľubovňa' },
  { label: 'Stará Paka', value: 'Stará Paka' },
  { label: 'Stara Pazova', value: 'Stara Pazova' },
  { label: 'Stara Syniava', value: 'Stara Syniava' },
  { label: 'Stará Turá', value: 'Stará Turá' },
  { label: 'Stará Ves Nad Ondřejnicí', value: 'Stará Ves Nad Ondřejnicí' },
  { label: 'Stara Vyzhivka', value: 'Stara Vyzhivka' },
  { label: 'Stara Wieś', value: 'Stara Wieś' },
  { label: 'Stara Zagora', value: 'Stara Zagora' },
  { label: 'Starachowice', value: 'Starachowice' },
  { label: 'Staradarozhski Rayon', value: 'Staradarozhski Rayon' },
  { label: 'Staranzano', value: 'Staranzano' },
  { label: 'Staraya', value: 'Staraya' },
  { label: 'Staraya Akkermanovka', value: 'Staraya Akkermanovka' },
  { label: 'Staraya Derevnya', value: 'Staraya Derevnya' },
  { label: 'Staraya Kulatka', value: 'Staraya Kulatka' },
  { label: 'Staraya Kupavna', value: 'Staraya Kupavna' },
  { label: 'Staraya Ladoga', value: 'Staraya Ladoga' },
  { label: 'Staraya Mayna', value: 'Staraya Mayna' },
  { label: 'Staraya Poltavka', value: 'Staraya Poltavka' },
  { label: 'Staraya Russa', value: 'Staraya Russa' },
  { label: 'Staraya Stanitsa', value: 'Staraya Stanitsa' },
  { label: 'Staraya Stanitsa', value: 'Staraya Stanitsa' },
  { label: 'Staraya Sunzha', value: 'Staraya Sunzha' },
  { label: 'Staraya Toropa', value: 'Staraya Toropa' },
  { label: 'Staraya Vichuga', value: 'Staraya Vichuga' },
  { label: 'Starbuck', value: 'Starbuck' },
  { label: 'Starcevica', value: 'Starcevica' },
  { label: 'Starchiojd', value: 'Starchiojd' },
  { label: 'Stârciu', value: 'Stârciu' },
  { label: 'Starcross', value: 'Starcross' },
  { label: 'Starcza', value: 'Starcza' },
  { label: 'Stare Babice', value: 'Stare Babice' },
  { label: 'Stare Bogaczowice', value: 'Stare Bogaczowice' },
  { label: 'Stare Czarnowo', value: 'Stare Czarnowo' },
  { label: 'Staré Hradiště', value: 'Staré Hradiště' },
  { label: 'Stare Juchy', value: 'Stare Juchy' },
  { label: 'Staré Křečany', value: 'Staré Křečany' },
  { label: 'Stare Kurowo', value: 'Stare Kurowo' },
  { label: 'Staré Město', value: 'Staré Město' },
  { label: 'Staré Město', value: 'Staré Město' },
  { label: 'Staré Město', value: 'Staré Město' },
  { label: 'Staré Město', value: 'Staré Město' },
  { label: 'Stare Miasto', value: 'Stare Miasto' },
  { label: 'Stare Miasto', value: 'Stare Miasto' },
  { label: 'Staré Nechanice', value: 'Staré Nechanice' },
  { label: 'Stare Pole', value: 'Stare Pole' },
  { label: 'Stařeč', value: 'Stařeč' },
  { label: 'Stargard', value: 'Stargard' },
  { label: 'Stari Banovci', value: 'Stari Banovci' },
  { label: 'Stari Bar', value: 'Stari Bar' },
  { label: 'Stari Bohorodchany', value: 'Stari Bohorodchany' },
  { label: 'Stari Grad', value: 'Stari Grad' },
  { label: 'Stari Grad', value: 'Stari Grad' },
  { label: 'Stari Jankovci', value: 'Stari Jankovci' },
  { label: 'Stari Kuty', value: 'Stari Kuty' },
  { label: 'Stari Lec', value: 'Stari Lec' },
  { label: 'Stari Perkovci', value: 'Stari Perkovci' },
  { label: 'Staříč', value: 'Staříč' },
  { label: 'Starigrad', value: 'Starigrad' },
  { label: 'Staritsa', value: 'Staritsa' },
  { label: 'Staritskiy Rayon', value: 'Staritskiy Rayon' },
  { label: 'Stark County', value: 'Stark County' },
  { label: 'Stark County', value: 'Stark County' },
  { label: 'Stark County', value: 'Stark County' },
  { label: 'Starke', value: 'Starke' },
  { label: 'Starke County', value: 'Starke County' },
  { label: 'Starksboro', value: 'Starksboro' },
  { label: 'Starkville', value: 'Starkville' },
  { label: 'Starlight Village', value: 'Starlight Village' },
  { label: 'Starnberg', value: 'Starnberg' },
  { label: 'Starnmeer', value: 'Starnmeer' },
  { label: 'Staroaleyskoye', value: 'Staroaleyskoye' },
  { label: 'Starobachaty', value: 'Starobachaty' },
  { label: 'Starobaltachevo', value: 'Starobaltachevo' },
  { label: 'Starobelokurikha', value: 'Starobelokurikha' },
  { label: 'Starobesheve', value: 'Starobesheve' },
  { label: 'Starobil’S’K', value: 'Starobil’S’K' },
  { label: 'Starobin', value: 'Starobin' },
  { label: 'Starocherkasskaya', value: 'Starocherkasskaya' },
  { label: 'Staroderevyankovskaya', value: 'Staroderevyankovskaya' },
  { label: 'Starodub', value: 'Starodub' },
  { label: 'Starodubskiy Rayon', value: 'Starodubskiy Rayon' },
  { label: 'Starodubskoye', value: 'Starodubskoye' },
  { label: 'Starodzhereliyevskaya', value: 'Starodzhereliyevskaya' },
  { label: 'Starogard Gdański', value: 'Starogard Gdański' },
  { label: 'Starokamyshinsk', value: 'Starokamyshinsk' },
  { label: 'Starokorsunskaya', value: 'Starokorsunskaya' },
  { label: 'Starokostiantyniv', value: 'Starokostiantyniv' },
  { label: 'Starokozache', value: 'Starokozache' },
  { label: 'Starokrzepice', value: 'Starokrzepice' },
  { label: 'Starokucherganovka', value: 'Starokucherganovka' },
  { label: 'Starokulatkinskiy Rayon', value: 'Starokulatkinskiy Rayon' },
  { label: 'Staroleushkovskaya', value: 'Staroleushkovskaya' },
  { label: 'Starominskaya', value: 'Starominskaya' },
  { label: 'Staromyshastovskaya', value: 'Staromyshastovskaya' },
  { label: 'Staronizhestebliyevskaya', value: 'Staronizhestebliyevskaya' },
  { label: 'Staropavlovskaya', value: 'Staropavlovskaya' },
  { label: 'Staropesterevo', value: 'Staropesterevo' },
  { label: 'Staropyshminsk', value: 'Staropyshminsk' },
  { label: 'Starorusskiy Rayon', value: 'Starorusskiy Rayon' },
  { label: 'Staroshaygovskiy Rayon', value: 'Staroshaygovskiy Rayon' },
  { label: 'Staroshcherbinovskaya', value: 'Staroshcherbinovskaya' },
  { label: 'Starosubkhangulovo', value: 'Starosubkhangulovo' },
  { label: 'Starotimoshkino', value: 'Starotimoshkino' },
  { label: 'Starotitarovskaya', value: 'Starotitarovskaya' },
  { label: 'Staroutkinsk', value: 'Staroutkinsk' },
  { label: 'Starovelichkovskaya', value: 'Starovelichkovskaya' },
  { label: 'Starowa Góra', value: 'Starowa Góra' },
  { label: 'Staroye Arakchino', value: 'Staroye Arakchino' },
  { label: 'Staroye Drozhzhanoye', value: 'Staroye Drozhzhanoye' },
  { label: 'Staroye Selo', value: 'Staroye Selo' },
  { label: 'Staroyur’Yevo', value: 'Staroyur’Yevo' },
  { label: 'Starozhilovo', value: 'Starozhilovo' },
  { label: 'Staroźreby', value: 'Staroźreby' },
  { label: 'Starr County', value: 'Starr County' },
  { label: 'Starše', value: 'Starše' },
  { label: 'Startforth', value: 'Startforth' },
  { label: 'Starup', value: 'Starup' },
  { label: 'Starý Bohumín', value: 'Starý Bohumín' },
  { label: 'Starý Jičín', value: 'Starý Jičín' },
  { label: 'Starý Kolín', value: 'Starý Kolín' },
  { label: 'Stary Krym', value: 'Stary Krym' },
  { label: 'Starý Plzenec', value: 'Starý Plzenec' },
  { label: 'Stary Sącz', value: 'Stary Sącz' },
  { label: 'Stary Targ', value: 'Stary Targ' },
  { label: 'Stary Wiśnicz', value: 'Stary Wiśnicz' },
  { label: 'Stary Zamość', value: 'Stary Zamość' },
  { label: 'Starychi', value: 'Starychi' },
  { label: 'Staryy Cherek', value: 'Staryy Cherek' },
  { label: 'Staryy Dobrotvir', value: 'Staryy Dobrotvir' },
  { label: 'Staryy Krym', value: 'Staryy Krym' },
  { label: 'Staryy Malgobek', value: 'Staryy Malgobek' },
  { label: 'Staryy Merchyk', value: 'Staryy Merchyk' },
  { label: 'Staryy Nadym', value: 'Staryy Nadym' },
  { label: 'Staryy Oskol', value: 'Staryy Oskol' },
  { label: 'Staryy Saltiv', value: 'Staryy Saltiv' },
  { label: 'Staryy Sambir', value: 'Staryy Sambir' },
  { label: 'Staryy Togul', value: 'Staryy Togul' },
  { label: 'Staryy Urukh', value: 'Staryy Urukh' },
  { label: 'Staryya Darohi', value: 'Staryya Darohi' },
  { label: 'Staryye Atagi', value: 'Staryye Atagi' },
  { label: 'Staryye Ozinki', value: 'Staryye Ozinki' },
  { label: 'Starza Vecchia', value: 'Starza Vecchia' },
  { label: 'Staßfurt', value: 'Staßfurt' },
  { label: 'Staszów', value: 'Staszów' },
  { label: 'State Center', value: 'State Center' },
  { label: 'State College', value: 'State College' },
  { label: 'State Line', value: 'State Line' },
  { label: 'Stateburg', value: 'Stateburg' },
  { label: 'Staten Island', value: 'Staten Island' },
  { label: 'Statenkwartier', value: 'Statenkwartier' },
  { label: 'Statenville', value: 'Statenville' },
  { label: 'Statesboro', value: 'Statesboro' },
  { label: 'Statesville', value: 'Statesville' },
  { label: 'Statham', value: 'Statham' },
  { label: 'Stathmós Mourión', value: 'Stathmós Mourión' },
  { label: 'Statte', value: 'Statte' },
  { label: 'Stattegg', value: 'Stattegg' },
  { label: 'Stattersdorf', value: 'Stattersdorf' },
  { label: 'Statzendorf', value: 'Statzendorf' },
  { label: 'Stăuceni', value: 'Stăuceni' },
  { label: 'Stăuceni', value: 'Stăuceni' },
  { label: 'Stauchitz', value: 'Stauchitz' },
  { label: 'Staudach-Egerndach', value: 'Staudach-Egerndach' },
  { label: 'Staudernheim', value: 'Staudernheim' },
  { label: 'Staufen', value: 'Staufen' },
  { label: 'Staufen', value: 'Staufen' },
  { label: 'Staufenberg', value: 'Staufenberg' },
  { label: 'Staunton', value: 'Staunton' },
  { label: 'Staunton', value: 'Staunton' },
  { label: 'Stavanger', value: 'Stavanger' },
  { label: 'Stăvaru', value: 'Stăvaru' },
  { label: 'Staveley', value: 'Staveley' },
  { label: 'Stavelot', value: 'Stavelot' },
  { label: 'Stavenisse', value: 'Stavenisse' },
  { label: 'Stavern', value: 'Stavern' },
  { label: 'Stavnsholt', value: 'Stavnsholt' },
  { label: 'Stavráki', value: 'Stavráki' },
  { label: 'Stavropol’', value: 'Stavropol’' },
  { label: 'Stavrós', value: 'Stavrós' },
  { label: 'Stavrós', value: 'Stavrós' },
  { label: 'Stavroúpoli', value: 'Stavroúpoli' },
  { label: 'Stavrovo', value: 'Stavrovo' },
  { label: 'Stavsnäs', value: 'Stavsnäs' },
  { label: 'Stavtrup', value: 'Stavtrup' },
  { label: 'Stavyshche', value: 'Stavyshche' },
  { label: 'Stavyshche', value: 'Stavyshche' },
  { label: 'Stawbtsowski Rayon', value: 'Stawbtsowski Rayon' },
  { label: 'Stawell', value: 'Stawell' },
  { label: 'Stawiguda', value: 'Stawiguda' },
  { label: 'Stawiski', value: 'Stawiski' },
  { label: 'Stawiszyn', value: 'Stawiszyn' },
  { label: 'Stayki', value: 'Stayki' },
  { label: 'Stayton', value: 'Stayton' },
  { label: 'Stazione', value: 'Stazione' },
  { label: 'Stazione', value: 'Stazione' },
  { label: 'Stazione Di Allerona', value: 'Stazione Di Allerona' },
  { label: 'Stazione Di Padule', value: 'Stazione Di Padule' },
  { label: 'Stazione Masotti', value: 'Stazione Masotti' },
  { label: 'Stazione Montalto-Coretto', value: 'Stazione Montalto-Coretto' },
  { label: 'Stazione Valmozzola', value: 'Stazione Valmozzola' },
  { label: 'Stazione-Fornola', value: 'Stazione-Fornola' },
  { label: 'Stazzano', value: 'Stazzano' },
  { label: 'Stazzona', value: 'Stazzona' },
  { label: 'Steamboat Springs', value: 'Steamboat Springs' },
  { label: 'Stearns', value: 'Stearns' },
  { label: 'Stearns County', value: 'Stearns County' },
  { label: 'Stebliv', value: 'Stebliv' },
  { label: 'Stebnyk', value: 'Stebnyk' },
  { label: 'Stěbořice', value: 'Stěbořice' },
  { label: 'Štěchovice', value: 'Štěchovice' },
  { label: 'Steckborn', value: 'Steckborn' },
  { label: 'Stede Broec', value: 'Stede Broec' },
  { label: 'Stedesdorf', value: 'Stedesdorf' },
  { label: 'Stedman', value: 'Stedman' },
  { label: 'Stedten', value: 'Stedten' },
  { label: 'Steeden', value: 'Steeden' },
  { label: 'Steeg', value: 'Steeg' },
  { label: 'Steele', value: 'Steele' },
  { label: 'Steele', value: 'Steele' },
  { label: 'Steele', value: 'Steele' },
  { label: 'Steele County', value: 'Steele County' },
  { label: 'Steele County', value: 'Steele County' },
  { label: 'Steeleville', value: 'Steeleville' },
  { label: 'Steelton', value: 'Steelton' },
  { label: 'Steelville', value: 'Steelville' },
  { label: 'Steenbecque', value: 'Steenbecque' },
  { label: 'Steenbergen', value: 'Steenbergen' },
  { label: 'Steenderen', value: 'Steenderen' },
  { label: 'Steene', value: 'Steene' },
  { label: 'Steenokkerzeel', value: 'Steenokkerzeel' },
  { label: 'Steensel', value: 'Steensel' },
  { label: 'Steenvoorde', value: 'Steenvoorde' },
  { label: 'Steenwerck', value: 'Steenwerck' },
  { label: 'Steenwijk', value: 'Steenwijk' },
  { label: 'Steenwijkerwold', value: 'Steenwijkerwold' },
  { label: 'Steep Falls', value: 'Steep Falls' },
  { label: 'Steeple Bumpstead', value: 'Steeple Bumpstead' },
  { label: 'Steeple Claydon', value: 'Steeple Claydon' },
  { label: 'Steer Town', value: 'Steer Town' },
  { label: 'Steeton', value: 'Steeton' },
  { label: 'Ştefan Cel Mare', value: 'Ştefan Cel Mare' },
  { label: 'Ştefan Cel Mare', value: 'Ştefan Cel Mare' },
  { label: 'Ştefan Cel Mare', value: 'Ştefan Cel Mare' },
  { label: 'Ştefan Cel Mare', value: 'Ştefan Cel Mare' },
  { label: 'Ştefan Cel Mare', value: 'Ştefan Cel Mare' },
  { label: 'Ștefan Cel Mare', value: 'Ștefan Cel Mare' },
  { label: 'Ştefan Vodă', value: 'Ştefan Vodă' },
  { label: 'Ştefan Vodă', value: 'Ştefan Vodă' },
  { label: 'Ștefan Vodă', value: 'Ștefan Vodă' },
  { label: 'Stefanaconi', value: 'Stefanaconi' },
  { label: 'Ștefanca', value: 'Ștefanca' },
  { label: 'Ștefăneaca', value: 'Ștefăneaca' },
  { label: 'Ştefăneşti', value: 'Ştefăneşti' },
  { label: 'Ştefăneşti', value: 'Ştefăneşti' },
  { label: 'Ștefănești', value: 'Ștefănești' },
  { label: 'Ştefăneştii De Jos', value: 'Ştefăneştii De Jos' },
  { label: 'Ştefăneşti-Sat', value: 'Ştefăneşti-Sat' },
  { label: 'Stefanovíkeio', value: 'Stefanovíkeio' },
  { label: 'Ştefeşti', value: 'Ştefeşti' },
  { label: 'Steffisburg', value: 'Steffisburg' },
  { label: 'Stegaurach', value: 'Stegaurach' },
  { label: 'Stege', value: 'Stege' },
  { label: 'Stegen', value: 'Stegen' },
  { label: 'Steger', value: 'Steger' },
  { label: 'Stegersbach', value: 'Stegersbach' },
  { label: 'Stegeslag', value: 'Stegeslag' },
  { label: 'Steglitz', value: 'Steglitz' },
  { label: 'Stegna', value: 'Stegna' },
  { label: 'Stehag', value: 'Stehag' },
  { label: 'Ștei', value: 'Ștei' },
  { label: 'Steierdorf', value: 'Steierdorf' },
  { label: 'Steigen', value: 'Steigen' },
  { label: 'Steilacoom', value: 'Steilacoom' },
  { label: 'Steilshoop', value: 'Steilshoop' },
  { label: 'Steimbke', value: 'Steimbke' },
  { label: 'Steimel', value: 'Steimel' },
  { label: 'Stein', value: 'Stein' },
  { label: 'Stein', value: 'Stein' },
  { label: 'Stein', value: 'Stein' },
  { label: 'Stein', value: 'Stein' },
  { label: 'Stein', value: 'Stein' },
  { label: 'Stein Am Rhein', value: 'Stein Am Rhein' },
  { label: 'Stein An Der Donau', value: 'Stein An Der Donau' },
  { label: 'Steina', value: 'Steina' },
  { label: 'Steinabrückl', value: 'Steinabrückl' },
  { label: 'Steinach', value: 'Steinach' },
  { label: 'Steinach', value: 'Steinach' },
  { label: 'Steinach', value: 'Steinach' },
  { label: 'Steinach Am Brenner', value: 'Steinach Am Brenner' },
  { label: 'Steinakirchen Am Forst', value: 'Steinakirchen Am Forst' },
  { label: 'Steinau An Der Straße', value: 'Steinau An Der Straße' },
  { label: 'Steinbach', value: 'Steinbach' },
  { label: 'Steinbach', value: 'Steinbach' },
  { label: 'Steinbach', value: 'Steinbach' },
  { label: 'Steinbach', value: 'Steinbach' },
  { label: 'Steinbach Am Attersee', value: 'Steinbach Am Attersee' },
  { label: 'Steinbach Am Taunus', value: 'Steinbach Am Taunus' },
  { label: 'Steinbach-Hallenberg', value: 'Steinbach-Hallenberg' },
  { label: 'Steinberg', value: 'Steinberg' },
  { label: 'Steinbergkirche', value: 'Steinbergkirche' },
  { label: 'Steinbourg', value: 'Steinbourg' },
  { label: 'Steinbrunn', value: 'Steinbrunn' },
  { label: 'Steindorf Am Ossiacher See', value: 'Steindorf Am Ossiacher See' },
  { label: 'Steinen', value: 'Steinen' },
  { label: 'Steinen', value: 'Steinen' },
  { label: 'Steinenbronn', value: 'Steinenbronn' },
  { label: 'Steinerkirchen An Der Traun', value: 'Steinerkirchen An Der Traun' },
  { label: 'Steinfeld', value: 'Steinfeld' },
  { label: 'Steinfeld', value: 'Steinfeld' },
  { label: 'Steinfeld', value: 'Steinfeld' },
  { label: 'Steinfeld', value: 'Steinfeld' },
  { label: 'Steinfeld', value: 'Steinfeld' },
  { label: 'Steinfort', value: 'Steinfort' },
  { label: 'Steinfurt', value: 'Steinfurt' },
  { label: 'Steingaden', value: 'Steingaden' },
  { label: 'Steinhagen', value: 'Steinhagen' },
  { label: 'Steinhagen', value: 'Steinhagen' },
  { label: 'Steinhatchee', value: 'Steinhatchee' },
  { label: 'Steinhaus', value: 'Steinhaus' },
  { label: 'Steinhausen', value: 'Steinhausen' },
  { label: 'Steinheid', value: 'Steinheid' },
  { label: 'Steinheim', value: 'Steinheim' },
  { label: 'Steinheim', value: 'Steinheim' },
  { label: 'Steinheim Am Albuch', value: 'Steinheim Am Albuch' },
  { label: 'Steinheim Am Der Murr', value: 'Steinheim Am Der Murr' },
  { label: 'Steinhöfel', value: 'Steinhöfel' },
  { label: 'Steinhöring', value: 'Steinhöring' },
  { label: 'Steinhorst', value: 'Steinhorst' },
  { label: 'Steinigtwolmsdorf', value: 'Steinigtwolmsdorf' },
  { label: 'Steinkirchen', value: 'Steinkirchen' },
  { label: 'Steinkirchen', value: 'Steinkirchen' },
  { label: 'Steinkjer', value: 'Steinkjer' },
  { label: 'Steinmauern', value: 'Steinmauern' },
  { label: 'Steinmaur', value: 'Steinmaur' },
  { label: 'Steinsel', value: 'Steinsel' },
  { label: 'Steinsfeld', value: 'Steinsfeld' },
  { label: 'Steinshamn', value: 'Steinshamn' },
  { label: 'Steinway', value: 'Steinway' },
  { label: 'Steinweiler', value: 'Steinweiler' },
  { label: 'Steinwenden', value: 'Steinwenden' },
  { label: 'Steinwiesen', value: 'Steinwiesen' },
  { label: 'Steíri', value: 'Steíri' },
  { label: 'Steißlingen', value: 'Steißlingen' },
  { label: 'Stejărenii', value: 'Stejărenii' },
  { label: 'Stejari', value: 'Stejari' },
  { label: 'Stejaru', value: 'Stejaru' },
  { label: 'Stejaru', value: 'Stejaru' },
  { label: 'Stejaru', value: 'Stejaru' },
  { label: 'Stejera', value: 'Stejera' },
  { label: 'Stejeriș', value: 'Stejeriș' },
  { label: 'Stekene', value: 'Stekene' },
  { label: 'Stella', value: 'Stella' },
  { label: 'Stella', value: 'Stella' },
  { label: 'Stella', value: 'Stella' },
  { label: 'Stella Cilento', value: 'Stella Cilento' },
  { label: 'Stellanello', value: 'Stellanello' },
  { label: 'Stelle', value: 'Stelle' },
  { label: 'Stellenbosch', value: 'Stellenbosch' },
  { label: 'Stellingen', value: 'Stellingen' },
  { label: 'Stelnica', value: 'Stelnica' },
  { label: 'Stelvio', value: 'Stelvio' },
  { label: 'Stelzenberg', value: 'Stelzenberg' },
  { label: 'Stenay', value: 'Stenay' },
  { label: 'Stendal', value: 'Stendal' },
  { label: 'Stende', value: 'Stende' },
  { label: 'Stenhamra', value: 'Stenhamra' },
  { label: 'Stenhousemuir', value: 'Stenhousemuir' },
  { label: 'Stenico', value: 'Stenico' },
  { label: 'Steninge', value: 'Steninge' },
  { label: 'Stenjevec', value: 'Stenjevec' },
  { label: 'Stenkullen', value: 'Stenkullen' },
  { label: 'Stenlille', value: 'Stenlille' },
  { label: 'Stenløse', value: 'Stenløse' },
  { label: 'Štěnovice', value: 'Štěnovice' },
  { label: 'Stenstorp', value: 'Stenstorp' },
  { label: 'Stenstorp', value: 'Stenstorp' },
  { label: 'Stenungsund', value: 'Stenungsund' },
  { label: 'Stenzengreith', value: 'Stenzengreith' },
  { label: 'Step’Anavan', value: 'Step’Anavan' },
  { label: 'Step’Antsminda', value: 'Step’Antsminda' },
  { label: 'Stepanivka', value: 'Stepanivka' },
  { label: 'Stepanivka', value: 'Stepanivka' },
  { label: 'Štěpánkovice', value: 'Štěpánkovice' },
  { label: 'Štěpánov', value: 'Štěpánov' },
  { label: 'Stepanovićevo', value: 'Stepanovićevo' },
  { label: 'Stepantsevo', value: 'Stepantsevo' },
  { label: 'Stepekolk', value: 'Stepekolk' },
  { label: 'Stephanshart', value: 'Stephanshart' },
  { label: 'Stephanskirchen', value: 'Stephanskirchen' },
  { label: 'Stephansposching', value: 'Stephansposching' },
  { label: 'Stephens City', value: 'Stephens City' },
  { label: 'Stephens County', value: 'Stephens County' },
  { label: 'Stephens County', value: 'Stephens County' },
  { label: 'Stephens County', value: 'Stephens County' },
  { label: 'Stephenson County', value: 'Stephenson County' },
  { label: 'Stephenville', value: 'Stephenville' },
  { label: 'Stephenville', value: 'Stephenville' },
  { label: 'Stephenville Crossing', value: 'Stephenville Crossing' },
  { label: 'Stepnica', value: 'Stepnica' },
  { label: 'Stepnogorsk', value: 'Stepnogorsk' },
  { label: 'Stepnoye', value: 'Stepnoye' },
  { label: 'Stepnoye', value: 'Stepnoye' },
  { label: 'Stepnoye', value: 'Stepnoye' },
  { label: 'Stepnyak', value: 'Stepnyak' },
  { label: 'Stepojevac', value: 'Stepojevac' },
  { label: 'Stepove', value: 'Stepove' },
  { label: 'Stepps', value: 'Stepps' },
  { label: 'Sterdyń', value: 'Sterdyń' },
  { label: 'Sterlibashevo', value: 'Sterlibashevo' },
  { label: 'Sterling', value: 'Sterling' },
  { label: 'Sterling', value: 'Sterling' },
  { label: 'Sterling', value: 'Sterling' },
  { label: 'Sterling', value: 'Sterling' },
  { label: 'Sterling', value: 'Sterling' },
  { label: 'Sterling', value: 'Sterling' },
  { label: 'Sterling City', value: 'Sterling City' },
  { label: 'Sterling County', value: 'Sterling County' },
  { label: 'Sterling Heights', value: 'Sterling Heights' },
  { label: 'Sterlington', value: 'Sterlington' },
  { label: 'Sterlitamak', value: 'Sterlitamak' },
  { label: 'Sternatia', value: 'Sternatia' },
  { label: 'Sternberg', value: 'Sternberg' },
  { label: 'Šternberk', value: 'Šternberk' },
  { label: 'Sternenfels', value: 'Sternenfels' },
  { label: 'Sterpoaia', value: 'Sterpoaia' },
  { label: 'Sterrenberg', value: 'Sterrenberg' },
  { label: 'Sterup', value: 'Sterup' },
  { label: 'Stęszew', value: 'Stęszew' },
  { label: 'Štětí', value: 'Štětí' },
  { label: 'Stetseva', value: 'Stetseva' },
  { label: 'Stetson', value: 'Stetson' },
  { label: 'Stetteldorf Am Wagram', value: 'Stetteldorf Am Wagram' },
  { label: 'Stetten', value: 'Stetten' },
  { label: 'Stetten', value: 'Stetten' },
  { label: 'Stetten', value: 'Stetten' },
  { label: 'Stetten Am Kalten Markt', value: 'Stetten Am Kalten Markt' },
  { label: 'Stettfeld', value: 'Stettfeld' },
  { label: 'Stettfurt', value: 'Stettfurt' },
  { label: 'Stettin', value: 'Stettin' },
  { label: 'Stettlen', value: 'Stettlen' },
  { label: 'Stettler', value: 'Stettler' },
  { label: 'Steuben', value: 'Steuben' },
  { label: 'Steuben County', value: 'Steuben County' },
  { label: 'Steuben County', value: 'Steuben County' },
  { label: 'Steubenville', value: 'Steubenville' },
  { label: 'Steuerberg', value: 'Steuerberg' },
  { label: 'Stevenage', value: 'Stevenage' },
  { label: 'Stevens County', value: 'Stevens County' },
  { label: 'Stevens County', value: 'Stevens County' },
  { label: 'Stevens County', value: 'Stevens County' },
  { label: 'Stevens Point', value: 'Stevens Point' },
  { label: 'Stevenson', value: 'Stevenson' },
  { label: 'Stevenson', value: 'Stevenson' },
  { label: 'Stevenson Ranch', value: 'Stevenson Ranch' },
  { label: 'Stevenston', value: 'Stevenston' },
  { label: 'Stevensville', value: 'Stevensville' },
  { label: 'Stevensville', value: 'Stevensville' },
  { label: 'Stevensville', value: 'Stevensville' },
  { label: 'Steventon', value: 'Steventon' },
  { label: 'Stevington', value: 'Stevington' },
  { label: 'Stevns Kommune', value: 'Stevns Kommune' },
  { label: 'Stewart County', value: 'Stewart County' },
  { label: 'Stewart County', value: 'Stewart County' },
  { label: 'Stewart Manor', value: 'Stewart Manor' },
  { label: 'Stewart Town', value: 'Stewart Town' },
  { label: 'Stewartby', value: 'Stewartby' },
  { label: 'Stewarton', value: 'Stewarton' },
  { label: 'Stewartstown', value: 'Stewartstown' },
  { label: 'Stewartville', value: 'Stewartville' },
  { label: 'Stewartville', value: 'Stewartville' },
  { label: 'Stewkley', value: 'Stewkley' },
  { label: 'Steyerberg', value: 'Steyerberg' },
  { label: 'Steyning', value: 'Steyning' },
  { label: 'Steynton', value: 'Steynton' },
  { label: 'Steyr', value: 'Steyr' },
  { label: 'Steyr Stadt', value: 'Steyr Stadt' },
  { label: 'Steyregg', value: 'Steyregg' },
  { label: 'Stěžery', value: 'Stěžery' },
  { label: 'Stężyca', value: 'Stężyca' },
  { label: 'Stężyca', value: 'Stężyca' },
  { label: 'Stezzano', value: 'Stezzano' },
  { label: 'Stia', value: 'Stia' },
  { label: 'Stiava', value: 'Stiava' },
  { label: 'Stichtse Vecht', value: 'Stichtse Vecht' },
  { label: 'Stickney', value: 'Stickney' },
  { label: 'Stickney', value: 'Stickney' },
  { label: 'Sticlăria', value: 'Sticlăria' },
  { label: 'Stiefenhofen', value: 'Stiefenhofen' },
  { label: 'Stiens', value: 'Stiens' },
  { label: 'Stienta', value: 'Stienta' },
  { label: 'Stierop', value: 'Stierop' },
  { label: 'Stige', value: 'Stige' },
  { label: 'Stigler', value: 'Stigler' },
  { label: 'Stigliano', value: 'Stigliano' },
  { label: 'Stigliano', value: 'Stigliano' },
  { label: 'Stignano', value: 'Stignano' },
  { label: 'Stigtomta', value: 'Stigtomta' },
  { label: 'Stijena', value: 'Stijena' },
  { label: 'Stiles', value: 'Stiles' },
  { label: 'Stilfontein', value: 'Stilfontein' },
  { label: 'Still', value: 'Still' },
  { label: 'Stilling', value: 'Stilling' },
  { label: 'Stillington', value: 'Stillington' },
  { label: 'Stillman Valley', value: 'Stillman Valley' },
  { label: 'Stillwater', value: 'Stillwater' },
  { label: 'Stillwater', value: 'Stillwater' },
  { label: 'Stillwater', value: 'Stillwater' },
  { label: 'Stillwater County', value: 'Stillwater County' },
  { label: 'Stilo', value: 'Stilo' },
  { label: 'Stilton', value: 'Stilton' },
  { label: 'Stilwell', value: 'Stilwell' },
  { label: 'Stimigliano', value: 'Stimigliano' },
  { label: 'Stimpfach', value: 'Stimpfach' },
  { label: 'Stinatz', value: 'Stinatz' },
  { label: 'Stinnett', value: 'Stinnett' },
  { label: 'Stintino', value: 'Stintino' },
  { label: 'Stio', value: 'Stio' },
  { label: 'Stiring-Wendel', value: 'Stiring-Wendel' },
  { label: 'Stirling', value: 'Stirling' },
  { label: 'Stirling', value: 'Stirling' },
  { label: 'Stirling', value: 'Stirling' },
  { label: 'Stirling', value: 'Stirling' },
  { label: 'Stirling', value: 'Stirling' },
  { label: 'Stirling North', value: 'Stirling North' },
  { label: 'Štitar', value: 'Štitar' },
  { label: 'Štitar', value: 'Štitar' },
  { label: 'Stithians', value: 'Stithians' },
  { label: 'Štítina', value: 'Štítina' },
  { label: 'Štítná Nad Vláří', value: 'Štítná Nad Vláří' },
  { label: 'Štíty', value: 'Štíty' },
  { label: 'Ştiubieni', value: 'Ştiubieni' },
  { label: 'Ştiuca', value: 'Ştiuca' },
  { label: 'Stiwoll', value: 'Stiwoll' },
  { label: 'Stizhkivs’Ke', value: 'Stizhkivs’Ke' },
  { label: 'St-Jean-Port-Joli', value: 'St-Jean-Port-Joli' },
  { label: 'Stjepan-Polje', value: 'Stjepan-Polje' },
  { label: 'Stjørdal', value: 'Stjørdal' },
  { label: 'Stjørdalshalsen', value: 'Stjørdalshalsen' },
  { label: 'Stobreč', value: 'Stobreč' },
  { label: 'Stochov', value: 'Stochov' },
  { label: 'Stock', value: 'Stock' },
  { label: 'Stock Island', value: 'Stock Island' },
  { label: 'Stockach', value: 'Stockach' },
  { label: 'Stockaryd', value: 'Stockaryd' },
  { label: 'Stockbridge', value: 'Stockbridge' },
  { label: 'Stockbridge', value: 'Stockbridge' },
  { label: 'Stockbridge', value: 'Stockbridge' },
  { label: 'Stockdale', value: 'Stockdale' },
  { label: 'Stockelsdorf', value: 'Stockelsdorf' },
  { label: 'Stockenboi', value: 'Stockenboi' },
  { label: 'Stockerau', value: 'Stockerau' },
  { label: 'Stockheim', value: 'Stockheim' },
  { label: 'Stockholm', value: 'Stockholm' },
  { label: 'Stockport', value: 'Stockport' },
  { label: 'Stocksbridge', value: 'Stocksbridge' },
  { label: 'Stöckse', value: 'Stöckse' },
  { label: 'Stocksfield', value: 'Stocksfield' },
  { label: 'Stockstadt Am Main', value: 'Stockstadt Am Main' },
  { label: 'Stockstadt Am Rhein', value: 'Stockstadt Am Rhein' },
  { label: 'Stockton', value: 'Stockton' },
  { label: 'Stockton', value: 'Stockton' },
  { label: 'Stockton', value: 'Stockton' },
  { label: 'Stockton', value: 'Stockton' },
  { label: 'Stockton', value: 'Stockton' },
  { label: 'Stockton', value: 'Stockton' },
  { label: 'Stockton Heath', value: 'Stockton Heath' },
  { label: 'Stockton Springs', value: 'Stockton Springs' },
  { label: 'Stockton-On-Tees', value: 'Stockton-On-Tees' },
  { label: 'Stockvik', value: 'Stockvik' },
  { label: 'Stockville', value: 'Stockville' },
  { label: 'Stoczek', value: 'Stoczek' },
  { label: 'Stoczek Łukowski', value: 'Stoczek Łukowski' },
  { label: 'Stod', value: 'Stod' },
  { label: 'Stoddard County', value: 'Stoddard County' },
  { label: 'Stodolishche', value: 'Stodolishche' },
  { label: 'Stödtlen', value: 'Stödtlen' },
  { label: 'Stoeneşti', value: 'Stoeneşti' },
  { label: 'Stoeneşti', value: 'Stoeneşti' },
  { label: 'Stoeneşti', value: 'Stoeneşti' },
  { label: 'Stoeneşti', value: 'Stoeneşti' },
  { label: 'Stoenești', value: 'Stoenești' },
  { label: 'Stoholm', value: 'Stoholm' },
  { label: 'Stoicăneşti', value: 'Stoicăneşti' },
  { label: 'Stoiceni', value: 'Stoiceni' },
  { label: 'Stoileşti', value: 'Stoileşti' },
  { label: 'Stoina', value: 'Stoina' },
  { label: 'Stojakovo', value: 'Stojakovo' },
  { label: 'Stoke Ferry', value: 'Stoke Ferry' },
  { label: 'Stoke Gabriel', value: 'Stoke Gabriel' },
  { label: 'Stoke Gifford', value: 'Stoke Gifford' },
  { label: 'Stoke Golding', value: 'Stoke Golding' },
  { label: 'Stoke Goldington', value: 'Stoke Goldington' },
  { label: 'Stoke Poges', value: 'Stoke Poges' },
  { label: 'Stoke Prior', value: 'Stoke Prior' },
  { label: 'Stokenchurch', value: 'Stokenchurch' },
  { label: 'Stoke-On-Trent', value: 'Stoke-On-Trent' },
  { label: 'Stokes County', value: 'Stokes County' },
  { label: 'Stokesdale', value: 'Stokesdale' },
  { label: 'Stokesley', value: 'Stokesley' },
  { label: 'Stoke-Sub-Hamdon', value: 'Stoke-Sub-Hamdon' },
  { label: 'Stokke', value: 'Stokke' },
  { label: 'Stokmarknes', value: 'Stokmarknes' },
  { label: 'Štoky', value: 'Štoky' },
  { label: 'Stolac', value: 'Stolac' },
  { label: 'Stolberg', value: 'Stolberg' },
  { label: 'Stolberg', value: 'Stolberg' },
  { label: 'Stolbishchi', value: 'Stolbishchi' },
  { label: 'Stolbovaya', value: 'Stolbovaya' },
  { label: 'Stolbovoye', value: 'Stolbovoye' },
  { label: 'Stolichna Obshtina', value: 'Stolichna Obshtina' },
  { label: 'Stolin', value: 'Stolin' },
  { label: 'Stolinski Rayon', value: 'Stolinski Rayon' },
  { label: 'Stollberg', value: 'Stollberg' },
  { label: 'Stollhofen', value: 'Stollhofen' },
  { label: 'Stolniceni', value: 'Stolniceni' },
  { label: 'Stolniceni-Prăjescu', value: 'Stolniceni-Prăjescu' },
  { label: 'Stolnici', value: 'Stolnici' },
  { label: 'Stolpe', value: 'Stolpe' },
  { label: 'Stolpen', value: 'Stolpen' },
  { label: 'Stolwijk', value: 'Stolwijk' },
  { label: 'Stolzenau', value: 'Stolzenau' },
  { label: 'Stompetoren', value: 'Stompetoren' },
  { label: 'Ston', value: 'Ston' },
  { label: 'Stonava', value: 'Stonava' },
  { label: 'Stone', value: 'Stone' },
  { label: 'Stone County', value: 'Stone County' },
  { label: 'Stone County', value: 'Stone County' },
  { label: 'Stone County', value: 'Stone County' },
  { label: 'Stone Mountain', value: 'Stone Mountain' },
  { label: 'Stone Park', value: 'Stone Park' },
  { label: 'Stone Ridge', value: 'Stone Ridge' },
  { label: 'Stoneboro', value: 'Stoneboro' },
  { label: 'Stonecrest', value: 'Stonecrest' },
  { label: 'Stonefield', value: 'Stonefield' },
  { label: 'Stonegate', value: 'Stonegate' },
  { label: 'Stoneham', value: 'Stoneham' },
  { label: 'Stonehaven', value: 'Stonehaven' },
  { label: 'Stonehenge', value: 'Stonehenge' },
  { label: 'Stonehouse', value: 'Stonehouse' },
  { label: 'Stonehouse', value: 'Stonehouse' },
  { label: 'Stonesfield', value: 'Stonesfield' },
  { label: 'Stoneville', value: 'Stoneville' },
  { label: 'Stoneville', value: 'Stoneville' },
  { label: 'Stonewall', value: 'Stonewall' },
  { label: 'Stonewall', value: 'Stonewall' },
  { label: 'Stonewall', value: 'Stonewall' },
  { label: 'Stonewall County', value: 'Stonewall County' },
  { label: 'Stonewood', value: 'Stonewood' },
  { label: 'Stoney Point', value: 'Stoney Point' },
  { label: 'Stoney Stanton', value: 'Stoney Stanton' },
  { label: 'Stoneyburn', value: 'Stoneyburn' },
  { label: 'Stonington', value: 'Stonington' },
  { label: 'Stonnington', value: 'Stonnington' },
  { label: 'Stony Brook', value: 'Stony Brook' },
  { label: 'Stony Creek Mills', value: 'Stony Creek Mills' },
  { label: 'Stony Hill', value: 'Stony Hill' },
  { label: 'Stony Plain', value: 'Stony Plain' },
  { label: 'Stony Point', value: 'Stony Point' },
  { label: 'Stony Point', value: 'Stony Point' },
  { label: 'Stony Point', value: 'Stony Point' },
  { label: 'Stony Prairie', value: 'Stony Prairie' },
  { label: 'Stonybrook', value: 'Stonybrook' },
  { label: 'Stonyfell', value: 'Stonyfell' },
  { label: 'Stoob', value: 'Stoob' },
  { label: 'Stöpen', value: 'Stöpen' },
  { label: 'Stopnica', value: 'Stopnica' },
  { label: 'Storå', value: 'Storå' },
  { label: 'Stora Höga', value: 'Stora Höga' },
  { label: 'Stord', value: 'Stord' },
  { label: 'Stordal', value: 'Stordal' },
  { label: 'Štore', value: 'Štore' },
  { label: 'Store Heddinge', value: 'Store Heddinge' },
  { label: 'Storebø', value: 'Storebø' },
  { label: 'Storebro', value: 'Storebro' },
  { label: 'Stor-Elvdal', value: 'Stor-Elvdal' },
  { label: 'Storeşti', value: 'Storeşti' },
  { label: 'Storey County', value: 'Storey County' },
  { label: 'Storfjord', value: 'Storfjord' },
  { label: 'Storfors', value: 'Storfors' },
  { label: 'Storkow', value: 'Storkow' },
  { label: 'Storm Lake', value: 'Storm Lake' },
  { label: 'Stormstown', value: 'Stormstown' },
  { label: 'Stornara', value: 'Stornara' },
  { label: 'Stornarella', value: 'Stornarella' },
  { label: 'Stornești', value: 'Stornești' },
  { label: 'Stornoway', value: 'Stornoway' },
  { label: 'Störnstein', value: 'Störnstein' },
  { label: 'Storo', value: 'Storo' },
  { label: 'Storobăneasa', value: 'Storobăneasa' },
  { label: 'Storozhevaya', value: 'Storozhevaya' },
  { label: 'Storozhnytsya', value: 'Storozhnytsya' },
  { label: 'Storrington', value: 'Storrington' },
  { label: 'Storrs', value: 'Storrs' },
  { label: 'Storslett', value: 'Storslett' },
  { label: 'Storsteinnes', value: 'Storsteinnes' },
  { label: 'Storuman', value: 'Storuman' },
  { label: 'Storvik', value: 'Storvik' },
  { label: 'Storvik', value: 'Storvik' },
  { label: 'Storvorde', value: 'Storvorde' },
  { label: 'Storvreta', value: 'Storvreta' },
  { label: 'Story City', value: 'Story City' },
  { label: 'Story County', value: 'Story County' },
  { label: 'Stoßdorf', value: 'Stoßdorf' },
  { label: 'Stößen', value: 'Stößen' },
  { label: 'Stössing', value: 'Stössing' },
  { label: 'Stosswihr', value: 'Stosswihr' },
  { label: 'Stoszowice', value: 'Stoszowice' },
  { label: 'Stotfold', value: 'Stotfold' },
  { label: 'Stötten Am Auerberg', value: 'Stötten Am Auerberg' },
  { label: 'Stottville', value: 'Stottville' },
  { label: 'Stöttwang', value: 'Stöttwang' },
  { label: 'Stotzheim', value: 'Stotzheim' },
  { label: 'Stotzing', value: 'Stotzing' },
  { label: 'Stoughton', value: 'Stoughton' },
  { label: 'Stoughton', value: 'Stoughton' },
  { label: 'Stoumont', value: 'Stoumont' },
  { label: 'Stourbridge', value: 'Stourbridge' },
  { label: 'Stourport-On-Severn', value: 'Stourport-On-Severn' },
  { label: 'Stover', value: 'Stover' },
  { label: 'Støvring', value: 'Støvring' },
  { label: 'Stow', value: 'Stow' },
  { label: 'Stow', value: 'Stow' },
  { label: 'Stow On The Wold', value: 'Stow On The Wold' },
  { label: 'Stowbtsy', value: 'Stowbtsy' },
  { label: 'Stowe', value: 'Stowe' },
  { label: 'Stowe', value: 'Stowe' },
  { label: 'Stowell', value: 'Stowell' },
  { label: 'Stowmarket', value: 'Stowmarket' },
  { label: 'Stra', value: 'Stra' },
  { label: 'Strabane', value: 'Strabane' },
  { label: 'Strabychovo', value: 'Strabychovo' },
  { label: 'Strachocina', value: 'Strachocina' },
  { label: 'Strachocin-Wojnów', value: 'Strachocin-Wojnów' },
  { label: 'Strachówka', value: 'Strachówka' },
  { label: 'Strada', value: 'Strada' },
  { label: 'Strada', value: 'Strada' },
  { label: 'Strada In Chianti', value: 'Strada In Chianti' },
  { label: 'Stradbally', value: 'Stradbally' },
  { label: 'Stradbroke', value: 'Stradbroke' },
  { label: 'Stradella', value: 'Stradella' },
  { label: 'Straden', value: 'Straden' },
  { label: 'Straelen', value: 'Straelen' },
  { label: 'Strafford', value: 'Strafford' },
  { label: 'Strafford', value: 'Strafford' },
  { label: 'Strafford County', value: 'Strafford County' },
  { label: 'Strahoninec', value: 'Strahoninec' },
  { label: 'Strai', value: 'Strai' },
  { label: 'Straja', value: 'Straja' },
  { label: 'Straja', value: 'Straja' },
  { label: 'Strakonice', value: 'Strakonice' },
  { label: 'Straldzha', value: 'Straldzha' },
  { label: 'Stralendorf', value: 'Stralendorf' },
  { label: 'Strallegg', value: 'Strallegg' },
  { label: 'Stralsund', value: 'Stralsund' },
  { label: 'Strâmbeni', value: 'Strâmbeni' },
  { label: 'Štramberk', value: 'Štramberk' },
  { label: 'Strambinello', value: 'Strambinello' },
  { label: 'Strambino', value: 'Strambino' },
  { label: 'Strâmbu-Băiuț', value: 'Strâmbu-Băiuț' },
  { label: 'Strà-Montanara-Pieve', value: 'Strà-Montanara-Pieve' },
  { label: 'Stramproy', value: 'Stramproy' },
  { label: 'Strâmtura', value: 'Strâmtura' },
  { label: 'Strâmtura', value: 'Strâmtura' },
  { label: 'Strančice', value: 'Strančice' },
  { label: 'Strand', value: 'Strand' },
  { label: 'Stranda', value: 'Stranda' },
  { label: 'Strandabyggð', value: 'Strandabyggð' },
  { label: 'Strandby', value: 'Strandby' },
  { label: 'Strande', value: 'Strande' },
  { label: 'Strandhill', value: 'Strandhill' },
  { label: 'Strängnäs', value: 'Strängnäs' },
  { label: 'Strangolagalli', value: 'Strangolagalli' },
  { label: 'Strání', value: 'Strání' },
  { label: 'Stranraer', value: 'Stranraer' },
  { label: 'Străoane', value: 'Străoane' },
  { label: 'Strasbourg', value: 'Strasbourg' },
  { label: 'Strasburg', value: 'Strasburg' },
  { label: 'Strasburg', value: 'Strasburg' },
  { label: 'Strasburg', value: 'Strasburg' },
  { label: 'Strasburg', value: 'Strasburg' },
  { label: 'Strasburg', value: 'Strasburg' },
  { label: 'Strășeni', value: 'Strășeni' },
  { label: 'Strašice', value: 'Strašice' },
  { label: 'Strass', value: 'Strass' },
  { label: 'Strass Im Zillertal', value: 'Strass Im Zillertal' },
  { label: 'Straß In Steiermark', value: 'Straß In Steiermark' },
  { label: 'Straßberg', value: 'Straßberg' },
  { label: 'Straßburg', value: 'Straßburg' },
  { label: 'Straßburg-Stadt', value: 'Straßburg-Stadt' },
  { label: 'Strassen', value: 'Strassen' },
  { label: 'Strassen', value: 'Strassen' },
  { label: 'Strassengel', value: 'Strassengel' },
  { label: 'Straßgang', value: 'Straßgang' },
  { label: 'Strassham', value: 'Strassham' },
  { label: 'Strasshof An Der Nordbahn', value: 'Strasshof An Der Nordbahn' },
  { label: 'Straßkirchen', value: 'Straßkirchen' },
  { label: 'Straßlach-Dingharting', value: 'Straßlach-Dingharting' },
  { label: 'Strasswalchen', value: 'Strasswalchen' },
  { label: 'Straszydle', value: 'Straszydle' },
  { label: 'Straszyn', value: 'Straszyn' },
  { label: 'Străteni', value: 'Străteni' },
  { label: 'Stratfield Mortimer', value: 'Stratfield Mortimer' },
  { label: 'Stratford', value: 'Stratford' },
  { label: 'Stratford', value: 'Stratford' },
  { label: 'Stratford', value: 'Stratford' },
  { label: 'Stratford', value: 'Stratford' },
  { label: 'Stratford', value: 'Stratford' },
  { label: 'Stratford', value: 'Stratford' },
  { label: 'Stratford', value: 'Stratford' },
  { label: 'Stratford', value: 'Stratford' },
  { label: 'Stratford', value: 'Stratford' },
  { label: 'Stratford-Upon-Avon', value: 'Stratford-Upon-Avon' },
  { label: 'Strathalbyn', value: 'Strathalbyn' },
  { label: 'Strathalbyn', value: 'Strathalbyn' },
  { label: 'Stratham Station', value: 'Stratham Station' },
  { label: 'Strathaven', value: 'Strathaven' },
  { label: 'Strathblane', value: 'Strathblane' },
  { label: 'Strathbogie', value: 'Strathbogie' },
  { label: 'Strathbogie', value: 'Strathbogie' },
  { label: 'Strathdale', value: 'Strathdale' },
  { label: 'Strathfield', value: 'Strathfield' },
  { label: 'Strathfield South', value: 'Strathfield South' },
  { label: 'Strathfieldsaye', value: 'Strathfieldsaye' },
  { label: 'Strathmerton', value: 'Strathmerton' },
  { label: 'Strathmore', value: 'Strathmore' },
  { label: 'Strathmore', value: 'Strathmore' },
  { label: 'Strathmore', value: 'Strathmore' },
  { label: 'Strathmore', value: 'Strathmore' },
  { label: 'Strathpeffer', value: 'Strathpeffer' },
  { label: 'Strathpine', value: 'Strathpine' },
  { label: 'Stratmoor', value: 'Stratmoor' },
  { label: 'Stratónion', value: 'Stratónion' },
  { label: 'Stratton', value: 'Stratton' },
  { label: 'Stratton', value: 'Stratton' },
  { label: 'Stratzing', value: 'Stratzing' },
  { label: 'Straubing', value: 'Straubing' },
  { label: 'Straume', value: 'Straume' },
  { label: 'Straume', value: 'Straume' },
  { label: 'Straumen', value: 'Straumen' },
  { label: 'Straumen', value: 'Straumen' },
  { label: 'Straupitz', value: 'Straupitz' },
  { label: 'Strausberg', value: 'Strausberg' },
  { label: 'Straußfurt', value: 'Straußfurt' },
  { label: 'Stravignino', value: 'Stravignino' },
  { label: 'Strawberry', value: 'Strawberry' },
  { label: 'Strawberry', value: 'Strawberry' },
  { label: 'Strawberry Point', value: 'Strawberry Point' },
  { label: 'Strawczyn', value: 'Strawczyn' },
  { label: 'Stráž', value: 'Stráž' },
  { label: 'Stráž Nad Nisou', value: 'Stráž Nad Nisou' },
  { label: 'Stráž Pod Ralskem', value: 'Stráž Pod Ralskem' },
  { label: 'Straža', value: 'Straža' },
  { label: 'Strazhitsa', value: 'Strazhitsa' },
  { label: 'Strážná', value: 'Strážná' },
  { label: 'Strážnice', value: 'Strážnice' },
  { label: 'Strážov', value: 'Strážov' },
  { label: 'Strážske', value: 'Strážske' },
  { label: 'Štrba', value: 'Štrba' },
  { label: 'Streaky Bay', value: 'Streaky Bay' },
  { label: 'Streamwood', value: 'Streamwood' },
  { label: 'Streatley', value: 'Streatley' },
  { label: 'Streator', value: 'Streator' },
  { label: 'Street', value: 'Street' },
  { label: 'Streetly', value: 'Streetly' },
  { label: 'Streetsboro', value: 'Streetsboro' },
  { label: 'Stregna', value: 'Stregna' },
  { label: 'Strehaia', value: 'Strehaia' },
  { label: 'Strehla', value: 'Strehla' },
  { label: 'Strejeşti', value: 'Strejeşti' },
  { label: 'Strejeștii De Sus', value: 'Strejeștii De Sus' },
  { label: 'Strejnicu', value: 'Strejnicu' },
  { label: 'Strelcha', value: 'Strelcha' },
  { label: 'Strelci', value: 'Strelci' },
  { label: 'Střelice', value: 'Střelice' },
  { label: 'Strelitsa', value: 'Strelitsa' },
  { label: 'Strelka', value: 'Strelka' },
  { label: 'Strelka', value: 'Strelka' },
  { label: 'Strelna', value: 'Strelna' },
  { label: 'Strem', value: 'Strem' },
  { label: 'Strembo', value: 'Strembo' },
  { label: 'Stremţ', value: 'Stremţ' },
  { label: 'Stremț', value: 'Stremț' },
  { label: 'Strenči', value: 'Strenči' },
  { label: 'Strengberg', value: 'Strengberg' },
  { label: 'Strengelbach', value: 'Strengelbach' },
  { label: 'Strengen', value: 'Strengen' },
  { label: 'Strensall', value: 'Strensall' },
  { label: 'Stresa', value: 'Stresa' },
  { label: 'Stretford', value: 'Stretford' },
  { label: 'Stretham', value: 'Stretham' },
  { label: 'Strettoia', value: 'Strettoia' },
  { label: 'Stretton', value: 'Stretton' },
  { label: 'Strevi', value: 'Strevi' },
  { label: 'Strezhevoy', value: 'Strezhevoy' },
  { label: 'Striano', value: 'Striano' },
  { label: 'Strib', value: 'Strib' },
  { label: 'Stříbro', value: 'Stříbro' },
  { label: 'Strigno', value: 'Strigno' },
  { label: 'Strijen', value: 'Strijen' },
  { label: 'Strijp', value: 'Strijp' },
  { label: 'Strimnica', value: 'Strimnica' },
  { label: 'Strizhi', value: 'Strizhi' },
  { label: 'Strizivojna', value: 'Strizivojna' },
  { label: 'Střížkov', value: 'Střížkov' },
  { label: 'Strmec', value: 'Strmec' },
  { label: 'Strmilov', value: 'Strmilov' },
  { label: 'Ströbeck', value: 'Ströbeck' },
  { label: 'Strobl', value: 'Strobl' },
  { label: 'Strøby Egede', value: 'Strøby Egede' },
  { label: 'Stroe', value: 'Stroe' },
  { label: 'Stroeşti', value: 'Stroeşti' },
  { label: 'Stroești', value: 'Stroești' },
  { label: 'Stroet', value: 'Stroet' },
  { label: 'Strogino', value: 'Strogino' },
  { label: 'Strogonovka', value: 'Strogonovka' },
  { label: 'Stroiești', value: 'Stroiești' },
  { label: 'Stroiești', value: 'Stroiești' },
  { label: 'Stroitel’', value: 'Stroitel’' },
  { label: 'Stromberg', value: 'Stromberg' },
  { label: 'Stromiec', value: 'Stromiec' },
  { label: 'Strömma', value: 'Strömma' },
  { label: 'Stromness', value: 'Stromness' },
  { label: 'Stromsburg', value: 'Stromsburg' },
  { label: 'Strömsnäsbruk', value: 'Strömsnäsbruk' },
  { label: 'Strömstad', value: 'Strömstad' },
  { label: 'Stromyn’', value: 'Stromyn’' },
  { label: 'Stroncone', value: 'Stroncone' },
  { label: 'Strong', value: 'Strong' },
  { label: 'Strongoli', value: 'Strongoli' },
  { label: 'Strongsville', value: 'Strongsville' },
  { label: 'Stronie', value: 'Stronie' },
  { label: 'Stronie Śląskie', value: 'Stronie Śląskie' },
  { label: 'Stronsdorf', value: 'Stronsdorf' },
  { label: 'Strood', value: 'Strood' },
  { label: 'Stropkov', value: 'Stropkov' },
  { label: 'Stroppiana', value: 'Stroppiana' },
  { label: 'Stroppo', value: 'Stroppo' },
  { label: 'Stroud', value: 'Stroud' },
  { label: 'Stroud', value: 'Stroud' },
  { label: 'Stroudsburg', value: 'Stroudsburg' },
  { label: 'Strövelstorp', value: 'Strövelstorp' },
  { label: 'Stróża', value: 'Stróża' },
  { label: 'Strozza', value: 'Strozza' },
  { label: 'Strudà', value: 'Strudà' },
  { label: 'Struer', value: 'Struer' },
  { label: 'Struer Kommune', value: 'Struer Kommune' },
  { label: 'Struga', value: 'Struga' },
  { label: 'Strugari', value: 'Strugari' },
  { label: 'Strugi-Krasnyye', value: 'Strugi-Krasnyye' },
  { label: 'Strugo-Krasnenskiy Rayon', value: 'Strugo-Krasnenskiy Rayon' },
  { label: 'Struie', value: 'Struie' },
  { label: 'Strullendorf', value: 'Strullendorf' },
  { label: 'Strum', value: 'Strum' },
  { label: 'Strumica', value: 'Strumica' },
  { label: 'Strumień', value: 'Strumień' },
  { label: 'Strumyani', value: 'Strumyani' },
  { label: 'Strunga', value: 'Strunga' },
  { label: 'Strunino', value: 'Strunino' },
  { label: 'Strunkovice Nad Blanicí', value: 'Strunkovice Nad Blanicí' },
  { label: 'Struppen', value: 'Struppen' },
  { label: 'Struthers', value: 'Struthers' },
  { label: 'Struvenhütten', value: 'Struvenhütten' },
  { label: 'Stryi', value: 'Stryi' },
  { label: 'Stryker', value: 'Stryker' },
  { label: 'Stryków', value: 'Stryków' },
  { label: 'Strymonikó', value: 'Strymonikó' },
  { label: 'Stryn', value: 'Stryn' },
  { label: 'Stryszawa', value: 'Stryszawa' },
  { label: 'Stryszów', value: 'Stryszów' },
  { label: 'Strzałkowo', value: 'Strzałkowo' },
  { label: 'Strzebiń', value: 'Strzebiń' },
  { label: 'Strzegom', value: 'Strzegom' },
  { label: 'Strzegowo', value: 'Strzegowo' },
  { label: 'Strzelce', value: 'Strzelce' },
  { label: 'Strzelce Krajeńskie', value: 'Strzelce Krajeńskie' },
  { label: 'Strzelce Opolskie', value: 'Strzelce Opolskie' },
  { label: 'Strzelce Wielkie', value: 'Strzelce Wielkie' },
  { label: 'Strzeleczki', value: 'Strzeleczki' },
  { label: 'Strzelin', value: 'Strzelin' },
  { label: 'Strzelno', value: 'Strzelno' },
  { label: 'Strzelno', value: 'Strzelno' },
  { label: 'Strzyżów', value: 'Strzyżów' },
  { label: 'Strzyżowice', value: 'Strzyżowice' },
  { label: 'Strzyżowice', value: 'Strzyżowice' },
  { label: 'Stuart', value: 'Stuart' },
  { label: 'Stuart', value: 'Stuart' },
  { label: 'Stuart', value: 'Stuart' },
  { label: 'Stuart', value: 'Stuart' },
  { label: 'Stuart Park', value: 'Stuart Park' },
  { label: 'Stuarts Draft', value: 'Stuarts Draft' },
  { label: 'Stubbekøbing', value: 'Stubbekøbing' },
  { label: 'Stubenberg', value: 'Stubenberg' },
  { label: 'Stubenberg', value: 'Stubenberg' },
  { label: 'Stubičke Toplice', value: 'Stubičke Toplice' },
  { label: 'Stubline', value: 'Stubline' },
  { label: 'Stubno', value: 'Stubno' },
  { label: 'Studená', value: 'Studená' },
  { label: 'Studenec', value: 'Studenec' },
  { label: 'Studeničane', value: 'Studeničane' },
  { label: 'Studénka', value: 'Studénka' },
  { label: 'Studenok', value: 'Studenok' },
  { label: 'Studenzen', value: 'Studenzen' },
  { label: 'Studina', value: 'Studina' },
  { label: 'Studinița', value: 'Studinița' },
  { label: 'Studio City', value: 'Studio City' },
  { label: 'Studley', value: 'Studley' },
  { label: 'Studzienice', value: 'Studzienice' },
  { label: 'Studzionka', value: 'Studzionka' },
  { label: 'Stueng Traeng', value: 'Stueng Traeng' },
  { label: 'Stuhlfelden', value: 'Stuhlfelden' },
  { label: 'Stühlingen', value: 'Stühlingen' },
  { label: 'Stuhr', value: 'Stuhr' },
  { label: 'Stukovo', value: 'Stukovo' },
  { label: 'Stulln', value: 'Stulln' },
  { label: 'Stulovo', value: 'Stulovo' },
  { label: 'Stulpicani', value: 'Stulpicani' },
  { label: 'Stumm', value: 'Stumm' },
  { label: 'Stummerberg', value: 'Stummerberg' },
  { label: 'Stung Treng', value: 'Stung Treng' },
  { label: 'Stupava', value: 'Stupava' },
  { label: 'Stupino', value: 'Stupino' },
  { label: 'Stupinskiy Rayon', value: 'Stupinskiy Rayon' },
  { label: 'Stupnik', value: 'Stupnik' },
  { label: 'Stupsk', value: 'Stupsk' },
  { label: 'Sturbridge', value: 'Sturbridge' },
  { label: 'Sturefors', value: 'Sturefors' },
  { label: 'Sturge Town', value: 'Sturge Town' },
  { label: 'Sturgeon', value: 'Sturgeon' },
  { label: 'Sturgeon Bay', value: 'Sturgeon Bay' },
  { label: 'Sturgis', value: 'Sturgis' },
  { label: 'Sturgis', value: 'Sturgis' },
  { label: 'Sturgis', value: 'Sturgis' },
  { label: 'Sturkö', value: 'Sturkö' },
  { label: 'Šturlić', value: 'Šturlić' },
  { label: 'Sturminster Marshall', value: 'Sturminster Marshall' },
  { label: 'Sturminster Newton', value: 'Sturminster Newton' },
  { label: 'Sturno', value: 'Sturno' },
  { label: 'Štúrovo', value: 'Štúrovo' },
  { label: 'Sturry', value: 'Sturry' },
  { label: 'Sturt', value: 'Sturt' },
  { label: 'Sturtevant', value: 'Sturtevant' },
  { label: 'Stutsman County', value: 'Stutsman County' },
  { label: 'Stutterheim', value: 'Stutterheim' },
  { label: 'Stuttgart', value: 'Stuttgart' },
  { label: 'Stuttgart', value: 'Stuttgart' },
  { label: 'Stuttgart Feuerbach', value: 'Stuttgart Feuerbach' },
  { label: 'Stuttgart Mühlhausen', value: 'Stuttgart Mühlhausen' },
  { label: 'Stuttgart-Ost', value: 'Stuttgart-Ost' },
  { label: 'Stützengrün', value: 'Stützengrün' },
  { label: 'Stützerbach', value: 'Stützerbach' },
  { label: 'Stykkishólmur', value: 'Stykkishólmur' },
  { label: 'Stylída', value: 'Stylída' },
  { label: 'Styrsö', value: 'Styrsö' },
  { label: 'Su Planu', value: 'Su Planu' },
  { label: 'Sua', value: 'Sua' },
  { label: 'Suai', value: 'Suai' },
  { label: 'Suaita', value: 'Suaita' },
  { label: 'Sual', value: 'Sual' },
  { label: 'Sual', value: 'Sual' },
  { label: 'Suaman', value: 'Suaman' },
  { label: 'Suamico', value: 'Suamico' },
  { label: 'Suan', value: 'Suan' },
  { label: 'Suances', value: 'Suances' },
  { label: 'Suanluang', value: 'Suanluang' },
  { label: 'Suaqui Grande', value: 'Suaqui Grande' },
  { label: 'Suar', value: 'Suar' },
  { label: 'Suardi', value: 'Suardi' },
  { label: 'Suárez', value: 'Suárez' },
  { label: 'Suárez', value: 'Suárez' },
  { label: 'Suárez', value: 'Suárez' },
  { label: 'Suatu', value: 'Suatu' },
  { label: 'Suay', value: 'Suay' },
  { label: 'Suaza', value: 'Suaza' },
  { label: 'Sub Pădure', value: 'Sub Pădure' },
  { label: 'Suba', value: 'Suba' },
  { label: 'Subachoque', value: 'Subachoque' },
  { label: 'Subang Jaya', value: 'Subang Jaya' },
  { label: 'Subarnapur', value: 'Subarnapur' },
  { label: 'Subaykhān', value: 'Subaykhān' },
  { label: 'Subbiano', value: 'Subbiano' },
  { label: 'Subcetate', value: 'Subcetate' },
  { label: 'Subiaco', value: 'Subiaco' },
  { label: 'Subiaco', value: 'Subiaco' },
  { label: 'Subic', value: 'Subic' },
  { label: 'Subic', value: 'Subic' },
  { label: 'Subic', value: 'Subic' },
  { label: 'Subingen', value: 'Subingen' },
  { label: 'Subirana', value: 'Subirana' },
  { label: 'Subirats', value: 'Subirats' },
  { label: 'Subkhankulovo', value: 'Subkhankulovo' },
  { label: 'Subkowy', value: 'Subkowy' },
  { label: 'Sublette', value: 'Sublette' },
  { label: 'Sublette County', value: 'Sublette County' },
  { label: 'Sublimity', value: 'Sublimity' },
  { label: 'Subotica', value: 'Subotica' },
  { label: 'Subottsi', value: 'Subottsi' },
  { label: 'Subtanjalla', value: 'Subtanjalla' },
  { label: 'Subteniente López', value: 'Subteniente López' },
  { label: 'Subusub', value: 'Subusub' },
  { label: 'Subusub', value: 'Subusub' },
  { label: 'Succasunna', value: 'Succasunna' },
  { label: 'Success', value: 'Success' },
  { label: 'Success', value: 'Success' },
  { label: 'Succivo', value: 'Succivo' },
  { label: 'Suceagu', value: 'Suceagu' },
  { label: 'Suceava', value: 'Suceava' },
  { label: 'Sucé-Sur-Erdre', value: 'Sucé-Sur-Erdre' },
  { label: 'Suceveni', value: 'Suceveni' },
  { label: 'Suceviţa', value: 'Suceviţa' },
  { label: 'Sucha', value: 'Sucha' },
  { label: 'Sucha Beskidzka', value: 'Sucha Beskidzka' },
  { label: 'Suchá Loz', value: 'Suchá Loz' },
  { label: 'Suchań', value: 'Suchań' },
  { label: 'Suchdol', value: 'Suchdol' },
  { label: 'Suchdol Nad Lužnicí', value: 'Suchdol Nad Lužnicí' },
  { label: 'Suchedniów', value: 'Suchedniów' },
  { label: 'Suchha', value: 'Suchha' },
  { label: 'Suchiapa', value: 'Suchiapa' },
  { label: 'Suchiate', value: 'Suchiate' },
  { label: 'Súchil', value: 'Súchil' },
  { label: 'Suchilapan Del Río', value: 'Suchilapan Del Río' },
  { label: 'Suchindram', value: 'Suchindram' },
  { label: 'Suchitlán', value: 'Suchitlán' },
  { label: 'Suchitlán', value: 'Suchitlán' },
  { label: 'Suchitoto', value: 'Suchitoto' },
  { label: 'Suchohrdly', value: 'Suchohrdly' },
  { label: 'Suchowola', value: 'Suchowola' },
  { label: 'Suchożebry', value: 'Suchożebry' },
  { label: 'Suchy Dąb', value: 'Suchy Dąb' },
  { label: 'Suchy Las', value: 'Suchy Las' },
  { label: 'Sucilá', value: 'Sucilá' },
  { label: 'Suciu De Jos', value: 'Suciu De Jos' },
  { label: 'Suciu De Sus', value: 'Suciu De Sus' },
  { label: 'Sucopó', value: 'Sucopó' },
  { label: 'Sucre', value: 'Sucre' },
  { label: 'Sucre', value: 'Sucre' },
  { label: 'Sucre', value: 'Sucre' },
  { label: 'Sucre', value: 'Sucre' },
  { label: 'Sucre', value: 'Sucre' },
  { label: 'Suc-Tuc', value: 'Suc-Tuc' },
  { label: 'Sucúa', value: 'Sucúa' },
  { label: 'Sucupira', value: 'Sucupira' },
  { label: 'Sucupira Do Norte', value: 'Sucupira Do Norte' },
  { label: 'Sucupira Do Riachão', value: 'Sucupira Do Riachão' },
  { label: 'Sucy-En-Brie', value: 'Sucy-En-Brie' },
  { label: 'Sud Carangas Province', value: 'Sud Carangas Province' },
  { label: 'Sud Mennucci', value: 'Sud Mennucci' },
  { label: 'Suda', value: 'Suda' },
  { label: 'Sudak', value: 'Sudak' },
  { label: 'Sudanell', value: 'Sudanell' },
  { label: 'Sudbrooke', value: 'Sudbrooke' },
  { label: 'Sudbury', value: 'Sudbury' },
  { label: 'Sudbury', value: 'Sudbury' },
  { label: 'Sudbury', value: 'Sudbury' },
  { label: 'Sud-Comoé', value: 'Sud-Comoé' },
  { label: 'Sudden Valley', value: 'Sudden Valley' },
  { label: 'Suddendorf', value: 'Suddendorf' },
  { label: 'Sudejan', value: 'Sudejan' },
  { label: 'Süderbrarup', value: 'Süderbrarup' },
  { label: 'Suderburg', value: 'Suderburg' },
  { label: 'Südergellersen', value: 'Südergellersen' },
  { label: 'Süderlügum', value: 'Süderlügum' },
  { label: 'Süderstapel', value: 'Süderstapel' },
  { label: 'Sudipen', value: 'Sudipen' },
  { label: 'Sudipen', value: 'Sudipen' },
  { label: 'Sudislavl’', value: 'Sudislavl’' },
  { label: 'Sudislavskiy Rayon', value: 'Sudislavskiy Rayon' },
  { label: 'Sudiţi', value: 'Sudiţi' },
  { label: 'Sudiți', value: 'Sudiți' },
  { label: 'Sudivka', value: 'Sudivka' },
  { label: 'Sudkov', value: 'Sudkov' },
  { label: 'Sudley', value: 'Sudley' },
  { label: 'Südlohn', value: 'Südlohn' },
  { label: 'Sudogda', value: 'Sudogda' },
  { label: 'Sudogodskiy Rayon', value: 'Sudogodskiy Rayon' },
  { label: 'Sudoměřice', value: 'Sudoměřice' },
  { label: 'Sudova Vyshnya', value: 'Sudova Vyshnya' },
  { label: 'Sudoverf’', value: 'Sudoverf’' },
  { label: 'Sudowol', value: 'Sudowol' },
  { label: 'Sudwalde', value: 'Sudwalde' },
  { label: 'Sûdwest Fryslân', value: 'Sûdwest Fryslân' },
  { label: 'Sudzal', value: 'Sudzal' },
  { label: 'Sudzha', value: 'Sudzha' },
  { label: 'Sueca', value: 'Sueca' },
  { label: 'Sueglio', value: 'Sueglio' },
  { label: 'Suellacabras', value: 'Suellacabras' },
  { label: 'Suelli', value: 'Suelli' },
  { label: 'Suello', value: 'Suello' },
  { label: 'Sueras/Suera', value: 'Sueras/Suera' },
  { label: 'Suesca', value: 'Suesca' },
  { label: 'Suetschach', value: 'Suetschach' },
  { label: 'Suèvres', value: 'Suèvres' },
  { label: 'Sueyoshichō-Ninokata', value: 'Sueyoshichō-Ninokata' },
  { label: 'Suez', value: 'Suez' },
  { label: 'Sūf', value: 'Sūf' },
  { label: 'Sufālat Samā’Il', value: 'Sufālat Samā’Il' },
  { label: 'Suffern', value: 'Suffern' },
  { label: 'Suffield Depot', value: 'Suffield Depot' },
  { label: 'Suffolk', value: 'Suffolk' },
  { label: 'Suffolk', value: 'Suffolk' },
  { label: 'Suffolk County', value: 'Suffolk County' },
  { label: 'Suffolk County', value: 'Suffolk County' },
  { label: 'Suffolk Park', value: 'Suffolk Park' },
  { label: 'Suflí', value: 'Suflí' },
  { label: 'Şugag', value: 'Şugag' },
  { label: 'Sugal', value: 'Sugal' },
  { label: 'Sugal', value: 'Sugal' },
  { label: 'Sugal', value: 'Sugal' },
  { label: 'Sugar City', value: 'Sugar City' },
  { label: 'Sugar Creek', value: 'Sugar Creek' },
  { label: 'Sugar Grove', value: 'Sugar Grove' },
  { label: 'Sugar Hill', value: 'Sugar Hill' },
  { label: 'Sugar Land', value: 'Sugar Land' },
  { label: 'Sugarcreek', value: 'Sugarcreek' },
  { label: 'Sugarcreek', value: 'Sugarcreek' },
  { label: 'Sugarcreek Police Dept', value: 'Sugarcreek Police Dept' },
  { label: 'Sugarland Run', value: 'Sugarland Run' },
  { label: 'Sugarmill Woods', value: 'Sugarmill Woods' },
  { label: 'Șugău', value: 'Șugău' },
  { label: 'Sugbongkogon', value: 'Sugbongkogon' },
  { label: 'Sugcad', value: 'Sugcad' },
  { label: 'Sugenheim', value: 'Sugenheim' },
  { label: 'Suginami-Ku', value: 'Suginami-Ku' },
  { label: 'Sugito', value: 'Sugito' },
  { label: 'Sugod', value: 'Sugod' },
  { label: 'Sugpon', value: 'Sugpon' },
  { label: 'Sugpon', value: 'Sugpon' },
  { label: 'Suhaia', value: 'Suhaia' },
  { label: 'Suharău', value: 'Suharău' },
  { label: 'Suḩayl Shibām', value: 'Suḩayl Shibām' },
  { label: 'Sühbaatar', value: 'Sühbaatar' },
  { label: 'Suhindol', value: 'Suhindol' },
  { label: 'Suhl', value: 'Suhl' },
  { label: 'Suhlendorf', value: 'Suhlendorf' },
  { label: 'Suhopolje', value: 'Suhopolje' },
  { label: 'Suhr', value: 'Suhr' },
  { label: 'Suhuleț', value: 'Suhuleț' },
  { label: 'Suhum', value: 'Suhum' },
  { label: 'Suhurlui', value: 'Suhurlui' },
  { label: 'Şuhut', value: 'Şuhut' },
  { label: 'Suibara', value: 'Suibara' },
  { label: 'Suicheng', value: 'Suicheng' },
  { label: 'Şuici', value: 'Şuici' },
  { label: 'Suifenhe', value: 'Suifenhe' },
  { label: 'Suihua', value: 'Suihua' },
  { label: 'Suileng', value: 'Suileng' },
  { label: 'Suining', value: 'Suining' },
  { label: 'Suippes', value: 'Suippes' },
  { label: 'Suisio', value: 'Suisio' },
  { label: 'Suisun', value: 'Suisun' },
  { label: 'Suita', value: 'Suita' },
  { label: 'Suita Shi', value: 'Suita Shi' },
  { label: 'Suitland', value: 'Suitland' },
  { label: 'Suitland-Silver Hill', value: 'Suitland-Silver Hill' },
  { label: 'Suixi', value: 'Suixi' },
  { label: 'Suizhou', value: 'Suizhou' },
  { label: 'Sujangarh', value: 'Sujangarh' },
  { label: 'Sujiatun', value: 'Sujiatun' },
  { label: 'Sukabumi', value: 'Sukabumi' },
  { label: 'Sukagawa', value: 'Sukagawa' },
  { label: 'Sukagawa Shi', value: 'Sukagawa Shi' },
  { label: 'Sukarrieta', value: 'Sukarrieta' },
  { label: 'Suket', value: 'Suket' },
  { label: 'Sukhaya Buyvola', value: 'Sukhaya Buyvola' },
  { label: 'Sukheke Mandi', value: 'Sukheke Mandi' },
  { label: 'Sukhinichi', value: 'Sukhinichi' },
  { label: 'Sukhinichskiy Rayon', value: 'Sukhinichskiy Rayon' },
  { label: 'Sukhobezvodnoye', value: 'Sukhobezvodnoye' },
  { label: 'Sukhobuzimskoye', value: 'Sukhobuzimskoye' },
  { label: 'Sukhodol', value: 'Sukhodol' },
  { label: 'Sukhothai', value: 'Sukhothai' },
  { label: 'Sukhovolya', value: 'Sukhovolya' },
  { label: 'Sukhoy Log', value: 'Sukhoy Log' },
  { label: 'Sukko', value: 'Sukko' },
  { label: 'Sukkozero', value: 'Sukkozero' },
  { label: 'Sukkur', value: 'Sukkur' },
  { label: 'Suklayin', value: 'Suklayin' },
  { label: 'Suklayin', value: 'Suklayin' },
  { label: 'Sukošan', value: 'Sukošan' },
  { label: 'Sükösd', value: 'Sükösd' },
  { label: 'Sukow', value: 'Sukow' },
  { label: 'Sukpak', value: 'Sukpak' },
  { label: 'Suksun', value: 'Suksun' },
  { label: 'Sukth', value: 'Sukth' },
  { label: 'Sukumo', value: 'Sukumo' },
  { label: 'Sukuta', value: 'Sukuta' },
  { label: 'Sukuta', value: 'Sukuta' },
  { label: 'Sul Brasil', value: 'Sul Brasil' },
  { label: 'Sula', value: 'Sula' },
  { label: 'Sula', value: 'Sula' },
  { label: 'Sula', value: 'Sula' },
  { label: 'Sula', value: 'Sula' },
  { label: 'Sulaco', value: 'Sulaco' },
  { label: 'Sulak', value: 'Sulak' },
  { label: 'Sulakyurt', value: 'Sulakyurt' },
  { label: 'Sūlam', value: 'Sūlam' },
  { label: 'Sulangan', value: 'Sulangan' },
  { label: 'Sulangan', value: 'Sulangan' },
  { label: 'Sulangan', value: 'Sulangan' },
  { label: 'Sulangan', value: 'Sulangan' },
  { label: 'Sulat', value: 'Sulat' },
  { label: 'Sulat', value: 'Sulat' },
  { label: 'Sulaym', value: 'Sulaym' },
  { label: 'Sulbiate', value: 'Sulbiate' },
  { label: 'Sulbiny Górne', value: 'Sulbiny Górne' },
  { label: 'Suldal', value: 'Suldal' },
  { label: 'Sulechów', value: 'Sulechów' },
  { label: 'Sulęcin', value: 'Sulęcin' },
  { label: 'Sulęczyno', value: 'Sulęczyno' },
  { label: 'Suleikh', value: 'Suleikh' },
  { label: 'Suleja', value: 'Suleja' },
  { label: 'Sulejów', value: 'Sulejów' },
  { label: 'Sulejówek', value: 'Sulejówek' },
  { label: 'Şuletea', value: 'Şuletea' },
  { label: 'Süleymanpaşa', value: 'Süleymanpaşa' },
  { label: 'Suleyman-Stal’Skiy Rayon', value: 'Suleyman-Stal’Skiy Rayon' },
  { label: 'Sülfeld', value: 'Sülfeld' },
  { label: 'Sulgen', value: 'Sulgen' },
  { label: 'Sulijeh', value: 'Sulijeh' },
  { label: 'Sulików', value: 'Sulików' },
  { label: 'Sulina', value: 'Sulina' },
  { label: 'Sulina', value: 'Sulina' },
  { label: 'Sulingen', value: 'Sulingen' },
  { label: 'Sulit', value: 'Sulit' },
  { label: 'Sulit', value: 'Sulit' },
  { label: 'Sulița', value: 'Sulița' },
  { label: 'Sulkava', value: 'Sulkava' },
  { label: 'Sułkowice', value: 'Sułkowice' },
  { label: 'Sułkowice', value: 'Sułkowice' },
  { label: 'Sullana', value: 'Sullana' },
  { label: 'Sulligent', value: 'Sulligent' },
  { label: 'Sullivan', value: 'Sullivan' },
  { label: 'Sullivan', value: 'Sullivan' },
  { label: 'Sullivan', value: 'Sullivan' },
  { label: 'Sullivan', value: 'Sullivan' },
  { label: 'Sullivan City', value: 'Sullivan City' },
  { label: 'Sullivan County', value: 'Sullivan County' },
  { label: 'Sullivan County', value: 'Sullivan County' },
  { label: 'Sullivan County', value: 'Sullivan County' },
  { label: 'Sullivan County', value: 'Sullivan County' },
  { label: 'Sullivan County', value: 'Sullivan County' },
  { label: 'Sullivan County', value: 'Sullivan County' },
  { label: 'Sullivans Island', value: 'Sullivans Island' },
  { label: 'Sully County', value: 'Sully County' },
  { label: 'Sully-Sur-Loire', value: 'Sully-Sur-Loire' },
  { label: 'Sulmierzyce', value: 'Sulmierzyce' },
  { label: 'Sulmierzyce', value: 'Sulmierzyce' },
  { label: 'Sulmona', value: 'Sulmona' },
  { label: 'Sulniac', value: 'Sulniac' },
  { label: 'Süloğlu', value: 'Süloğlu' },
  { label: 'Sulop', value: 'Sulop' },
  { label: 'Sulop', value: 'Sulop' },
  { label: 'Sulop', value: 'Sulop' },
  { label: 'Sułoszowa', value: 'Sułoszowa' },
  { label: 'Sułów', value: 'Sułów' },
  { label: 'Sułów', value: 'Sułów' },
  { label: 'Sulphur', value: 'Sulphur' },
  { label: 'Sulphur', value: 'Sulphur' },
  { label: 'Sulphur Springs', value: 'Sulphur Springs' },
  { label: 'Sulphur Springs', value: 'Sulphur Springs' },
  { label: 'Sulphur Springs', value: 'Sulphur Springs' },
  { label: 'Sülstorf', value: 'Sülstorf' },
  { label: 'Sultan', value: 'Sultan' },
  { label: 'Sultan Kudarat', value: 'Sultan Kudarat' },
  { label: 'Sultan Kudarat', value: 'Sultan Kudarat' },
  { label: 'Sultan Sa Barongis', value: 'Sultan Sa Barongis' },
  { label: 'Sultan Sumagka', value: 'Sultan Sumagka' },
  { label: 'Sultana', value: 'Sultana' },
  { label: 'Sultanbeyli', value: 'Sultanbeyli' },
  { label: 'Sultandağı', value: 'Sultandağı' },
  { label: 'Sultangazi', value: 'Sultangazi' },
  { label: 'Sultanhanı', value: 'Sultanhanı' },
  { label: 'Sultanhisar', value: 'Sultanhisar' },
  { label: 'Sultanpur', value: 'Sultanpur' },
  { label: 'Sultanpur', value: 'Sultanpur' },
  { label: 'Sultanpur', value: 'Sultanpur' },
  { label: 'Sultanpur Lodhi', value: 'Sultanpur Lodhi' },
  { label: 'Sultepec', value: 'Sultepec' },
  { label: 'Sulucan', value: 'Sulucan' },
  { label: 'Sulucan', value: 'Sulucan' },
  { label: 'Suluktu', value: 'Suluktu' },
  { label: 'Suluova', value: 'Suluova' },
  { label: 'Sulur', value: 'Sulur' },
  { label: 'Suluru', value: 'Suluru' },
  { label: 'Sulusaray', value: 'Sulusaray' },
  { label: 'Sulya', value: 'Sulya' },
  { label: 'Sülysáp', value: 'Sülysáp' },
  { label: 'Sulz', value: 'Sulz' },
  { label: 'Sulz', value: 'Sulz' },
  { label: 'Sulz', value: 'Sulz' },
  { label: 'Sulz Am Neckar', value: 'Sulz Am Neckar' },
  { label: 'Sulzano', value: 'Sulzano' },
  { label: 'Sulzbach', value: 'Sulzbach' },
  { label: 'Sulzbach', value: 'Sulzbach' },
  { label: 'Sulzbach Am Main', value: 'Sulzbach Am Main' },
  { label: 'Sulzbach An Der Murr', value: 'Sulzbach An Der Murr' },
  { label: 'Sulzbach-Rosenberg', value: 'Sulzbach-Rosenberg' },
  { label: 'Sulzberg', value: 'Sulzberg' },
  { label: 'Sulzberg', value: 'Sulzberg' },
  { label: 'Sulzburg', value: 'Sulzburg' },
  { label: 'Sulzdorf', value: 'Sulzdorf' },
  { label: 'Sulzemoos', value: 'Sulzemoos' },
  { label: 'Sulzfeld', value: 'Sulzfeld' },
  { label: 'Sulzfeld', value: 'Sulzfeld' },
  { label: 'Sulzfeld Am Main', value: 'Sulzfeld Am Main' },
  { label: 'Sulzheim', value: 'Sulzheim' },
  { label: 'Sulzheim', value: 'Sulzheim' },
  { label: 'Sulztal An Der Weinstraße', value: 'Sulztal An Der Weinstraße' },
  { label: 'Suma', value: 'Suma' },
  { label: 'Suma De Hidalgo', value: 'Suma De Hidalgo' },
  { label: 'Sumabnit', value: 'Sumabnit' },
  { label: 'Sumabnit', value: 'Sumabnit' },
  { label: 'Sumacàrcer', value: 'Sumacàrcer' },
  { label: 'Šumadija', value: 'Šumadija' },
  { label: 'Sumag', value: 'Sumag' },
  { label: 'Sumagui', value: 'Sumagui' },
  { label: 'Sumalig', value: 'Sumalig' },
  { label: 'Sumampa', value: 'Sumampa' },
  { label: 'Sumaré', value: 'Sumaré' },
  { label: 'Sumas', value: 'Sumas' },
  { label: 'Šumatac', value: 'Šumatac' },
  { label: 'Sumaymah', value: 'Sumaymah' },
  { label: 'Sumba', value: 'Sumba' },
  { label: 'Sumbal', value: 'Sumbal' },
  { label: 'Sumbas', value: 'Sumbas' },
  { label: 'Sumbatan-Diza', value: 'Sumbatan-Diza' },
  { label: 'Sumbawa Besar', value: 'Sumbawa Besar' },
  { label: 'Sumbawanga', value: 'Sumbawanga' },
  { label: 'Sumbe', value: 'Sumbe' },
  { label: 'Sumbe', value: 'Sumbe' },
  { label: 'Sumber', value: 'Sumber' },
  { label: 'Sumberpucung', value: 'Sumberpucung' },
  { label: 'Sumbuya', value: 'Sumbuya' },
  { label: 'Sumé', value: 'Sumé' },
  { label: 'Sumedang', value: 'Sumedang' },
  { label: 'Sumedang Utara', value: 'Sumedang Utara' },
  { label: 'Sümeg', value: 'Sümeg' },
  { label: 'Sümegi Járás', value: 'Sümegi Járás' },
  { label: 'Sumène', value: 'Sumène' },
  { label: 'Sumenep', value: 'Sumenep' },
  { label: 'Sumiainen', value: 'Sumiainen' },
  { label: 'Šumice', value: 'Šumice' },
  { label: 'Sumida-Ku', value: 'Sumida-Ku' },
  { label: 'Sumidero', value: 'Sumidero' },
  { label: 'Sumidero', value: 'Sumidero' },
  { label: 'Sumidouro', value: 'Sumidouro' },
  { label: 'Sumilao', value: 'Sumilao' },
  { label: 'Sumirago', value: 'Sumirago' },
  { label: 'Sumisip', value: 'Sumisip' },
  { label: 'Sumisip', value: 'Sumisip' },
  { label: 'Sumiswald', value: 'Sumiswald' },
  { label: 'Sumiton', value: 'Sumiton' },
  { label: 'Sumkar', value: 'Sumkar' },
  { label: 'Sumkino', value: 'Sumkino' },
  { label: 'Summaga', value: 'Summaga' },
  { label: 'Summer Hill', value: 'Summer Hill' },
  { label: 'Summer Hill', value: 'Summer Hill' },
  { label: 'Summerdale', value: 'Summerdale' },
  { label: 'Summerfield', value: 'Summerfield' },
  { label: 'Summerfield', value: 'Summerfield' },
  { label: 'Summerfield', value: 'Summerfield' },
  { label: 'Summerhill', value: 'Summerhill' },
  { label: 'Summerland', value: 'Summerland' },
  { label: 'Summerland', value: 'Summerland' },
  { label: 'Summerland Point', value: 'Summerland Point' },
  { label: 'Summerlin South', value: 'Summerlin South' },
  { label: 'Summers County', value: 'Summers County' },
  { label: 'Summersdale', value: 'Summersdale' },
  { label: 'Summerset', value: 'Summerset' },
  { label: 'Summerside', value: 'Summerside' },
  { label: 'Summerside', value: 'Summerside' },
  { label: 'Summersville', value: 'Summersville' },
  { label: 'Summerville', value: 'Summerville' },
  { label: 'Summerville', value: 'Summerville' },
  { label: 'Summit', value: 'Summit' },
  { label: 'Summit', value: 'Summit' },
  { label: 'Summit', value: 'Summit' },
  { label: 'Summit', value: 'Summit' },
  { label: 'Summit', value: 'Summit' },
  { label: 'Summit County', value: 'Summit County' },
  { label: 'Summit County', value: 'Summit County' },
  { label: 'Summit County', value: 'Summit County' },
  { label: 'Summit Hill', value: 'Summit Hill' },
  { label: 'Summit Park', value: 'Summit Park' },
  { label: 'Summit View', value: 'Summit View' },
  { label: 'Summonte', value: 'Summonte' },
  { label: 'Sumner', value: 'Sumner' },
  { label: 'Sumner', value: 'Sumner' },
  { label: 'Sumner', value: 'Sumner' },
  { label: 'Sumner County', value: 'Sumner County' },
  { label: 'Sumner County', value: 'Sumner County' },
  { label: 'Sumoto', value: 'Sumoto' },
  { label: 'Sumoto Shi', value: 'Sumoto Shi' },
  { label: 'Sumpango', value: 'Sumpango' },
  { label: 'Šumperk', value: 'Šumperk' },
  { label: 'Sumpong', value: 'Sumpong' },
  { label: 'Sumqayıt', value: 'Sumqayıt' },
  { label: 'Sumrall', value: 'Sumrall' },
  { label: 'Sumter', value: 'Sumter' },
  { label: 'Sumter County', value: 'Sumter County' },
  { label: 'Sumter County', value: 'Sumter County' },
  { label: 'Sumter County', value: 'Sumter County' },
  { label: 'Sumter County', value: 'Sumter County' },
  { label: 'Sumulicë', value: 'Sumulicë' },
  { label: 'Sumusţā As Sulţānī', value: 'Sumusţā As Sulţānī' },
  { label: 'Sumy', value: 'Sumy' },
  { label: 'Sun City', value: 'Sun City' },
  { label: 'Sun City', value: 'Sun City' },
  { label: 'Sun City Center', value: 'Sun City Center' },
  { label: 'Sun City West', value: 'Sun City West' },
  { label: 'Sun Kunda', value: 'Sun Kunda' },
  { label: 'Sun Lakes', value: 'Sun Lakes' },
  { label: 'Sun Prairie', value: 'Sun Prairie' },
  { label: 'Sun Prairie', value: 'Sun Prairie' },
  { label: 'Sun Valley', value: 'Sun Valley' },
  { label: 'Sun Valley', value: 'Sun Valley' },
  { label: 'Sun Valley', value: 'Sun Valley' },
  { label: 'Sun Valley', value: 'Sun Valley' },
  { label: 'Sun Valley', value: 'Sun Valley' },
  { label: 'Sun Village', value: 'Sun Village' },
  { label: 'Suna', value: 'Suna' },
  { label: 'Sunagawa', value: 'Sunagawa' },
  { label: 'Sunagawa-Shi', value: 'Sunagawa-Shi' },
  { label: 'Sunam', value: 'Sunam' },
  { label: 'Sunamganj ', value: 'Sunamganj ' },
  { label: 'Sunan', value: 'Sunan' },
  { label: 'Sunapee', value: 'Sunapee' },
  { label: 'Sunbilla', value: 'Sunbilla' },
  { label: 'Sunbilt', value: 'Sunbilt' },
  { label: 'Sunbury', value: 'Sunbury' },
  { label: 'Sunbury', value: 'Sunbury' },
  { label: 'Sunbury', value: 'Sunbury' },
  { label: 'Sunbury-On-Thames', value: 'Sunbury-On-Thames' },
  { label: 'Sunch’Ŏn', value: 'Sunch’Ŏn' },
  { label: 'Sunchales', value: 'Sunchales' },
  { label: 'Sunchang-Gun', value: 'Sunchang-Gun' },
  { label: 'Suncheon', value: 'Suncheon' },
  { label: 'Suncheon-Si', value: 'Suncheon-Si' },
  { label: 'Sünching', value: 'Sünching' },
  { label: 'Suncho Corral', value: 'Suncho Corral' },
  { label: 'Suncoast Estates', value: 'Suncoast Estates' },
  { label: 'Suncook', value: 'Suncook' },
  { label: 'Şuncuiuş', value: 'Şuncuiuş' },
  { label: 'Sund', value: 'Sund' },
  { label: 'Sund', value: 'Sund' },
  { label: 'Sunda', value: 'Sunda' },
  { label: 'Sundance', value: 'Sundance' },
  { label: 'Sundargarh', value: 'Sundargarh' },
  { label: 'Sundarnagar', value: 'Sundarnagar' },
  { label: 'Sundby', value: 'Sundby' },
  { label: 'Sundbyberg', value: 'Sundbyberg' },
  { label: 'Sunderland', value: 'Sunderland' },
  { label: 'Sunderland', value: 'Sunderland' },
  { label: 'Sunderland', value: 'Sunderland' },
  { label: 'Sundern', value: 'Sundern' },
  { label: 'Sundhoffen', value: 'Sundhoffen' },
  { label: 'Sundhouse', value: 'Sundhouse' },
  { label: 'Sundown', value: 'Sundown' },
  { label: 'Sundre', value: 'Sundre' },
  { label: 'Sundridge', value: 'Sundridge' },
  { label: 'Sunds', value: 'Sunds' },
  { label: 'Sundsbruk', value: 'Sundsbruk' },
  { label: 'Sundsvall', value: 'Sundsvall' },
  { label: 'Sundumbili', value: 'Sundumbili' },
  { label: 'Sunel', value: 'Sunel' },
  { label: 'Sunflower', value: 'Sunflower' },
  { label: 'Sunflower County', value: 'Sunflower County' },
  { label: 'Sung Noen', value: 'Sung Noen' },
  { label: 'Sungai', value: 'Sungai' },
  { label: 'Sungai Besar', value: 'Sungai Besar' },
  { label: 'Su-Ngai Kolok', value: 'Su-Ngai Kolok' },
  { label: 'Sungai Pelek New Village', value: 'Sungai Pelek New Village' },
  { label: 'Sungai Penuh', value: 'Sungai Penuh' },
  { label: 'Sungai Petani', value: 'Sungai Petani' },
  { label: 'Sungai Udang', value: 'Sungai Udang' },
  { label: 'Sungailiat', value: 'Sungailiat' },
  { label: 'Sŭngam-Nodongjagu', value: 'Sŭngam-Nodongjagu' },
  { label: 'Sunggal', value: 'Sunggal' },
  { label: 'Sŭngjibaegam', value: 'Sŭngjibaegam' },
  { label: 'Sungurlare', value: 'Sungurlare' },
  { label: 'Sungurlu', value: 'Sungurlu' },
  { label: 'Suni', value: 'Suni' },
  { label: 'Sunja', value: 'Sunja' },
  { label: 'Sunland', value: 'Sunland' },
  { label: 'Sunland Park', value: 'Sunland Park' },
  { label: 'Sunman', value: 'Sunman' },
  { label: 'Sunndal', value: 'Sunndal' },
  { label: 'Sunndalsøra', value: 'Sunndalsøra' },
  { label: 'Sunne', value: 'Sunne' },
  { label: 'Sunning Hill', value: 'Sunning Hill' },
  { label: 'Sunny Acres', value: 'Sunny Acres' },
  { label: 'Sunny Isles Beach', value: 'Sunny Isles Beach' },
  { label: 'Sunnybank', value: 'Sunnybank' },
  { label: 'Sunnybank Hills', value: 'Sunnybank Hills' },
  { label: 'Sunnyside', value: 'Sunnyside' },
  { label: 'Sunnyside', value: 'Sunnyside' },
  { label: 'Sunnyside', value: 'Sunnyside' },
  { label: 'Sunnyside', value: 'Sunnyside' },
  { label: 'Sunnyside-Tahoe City', value: 'Sunnyside-Tahoe City' },
  { label: 'Sunnyslope', value: 'Sunnyslope' },
  { label: 'Sunnyslope', value: 'Sunnyslope' },
  { label: 'Sunnyvale', value: 'Sunnyvale' },
  { label: 'Sunnyvale', value: 'Sunnyvale' },
  { label: 'Suno', value: 'Suno' },
  { label: 'Sunray', value: 'Sunray' },
  { label: 'Sunrise', value: 'Sunrise' },
  { label: 'Sunrise Beach', value: 'Sunrise Beach' },
  { label: 'Sunrise Lake', value: 'Sunrise Lake' },
  { label: 'Sunrise Manor', value: 'Sunrise Manor' },
  { label: 'Sunriver', value: 'Sunriver' },
  { label: 'Sunsari', value: 'Sunsari' },
  { label: 'Sunset', value: 'Sunset' },
  { label: 'Sunset', value: 'Sunset' },
  { label: 'Sunset', value: 'Sunset' },
  { label: 'Sunset', value: 'Sunset' },
  { label: 'Sunset Beach', value: 'Sunset Beach' },
  { label: 'Sunset Beach', value: 'Sunset Beach' },
  { label: 'Sunset Beach', value: 'Sunset Beach' },
  { label: 'Sunset Hills', value: 'Sunset Hills' },
  { label: 'Sunset Park', value: 'Sunset Park' },
  { label: 'Sunshine', value: 'Sunshine' },
  { label: 'Sunshine Bay', value: 'Sunshine Bay' },
  { label: 'Sunshine Beach', value: 'Sunshine Beach' },
  { label: 'Sunshine Coast', value: 'Sunshine Coast' },
  { label: 'Sunshine North', value: 'Sunshine North' },
  { label: 'Sunshine Ranches', value: 'Sunshine Ranches' },
  { label: 'Sunshine West', value: 'Sunshine West' },
  { label: 'Sunskiy Rayon', value: 'Sunskiy Rayon' },
  { label: 'Suntar', value: 'Suntar' },
  { label: 'Suntarskiy District', value: 'Suntarskiy District' },
  { label: 'Suntikoppa', value: 'Suntikoppa' },
  { label: 'Sunyani', value: 'Sunyani' },
  { label: 'Sunyani West', value: 'Sunyani West' },
  { label: 'Sunyer', value: 'Sunyer' },
  { label: 'Sunzha', value: 'Sunzha' },
  { label: 'Sunzha', value: 'Sunzha' },
  { label: 'Sunzhenskiy Rayon', value: 'Sunzhenskiy Rayon' },
  { label: 'Suodenniemi', value: 'Suodenniemi' },
  { label: 'Suohe', value: 'Suohe' },
  { label: 'Suolahti', value: 'Suolahti' },
  { label: 'Suomusjärvi', value: 'Suomusjärvi' },
  { label: 'Suomussalmi', value: 'Suomussalmi' },
  { label: 'Suoniemi', value: 'Suoniemi' },
  { label: 'Suoyarvi', value: 'Suoyarvi' },
  { label: 'Suoyarvskiy Rayon', value: 'Suoyarvskiy Rayon' },
  { label: 'Suozhen', value: 'Suozhen' },
  { label: 'Supatá', value: 'Supatá' },
  { label: 'Supaul', value: 'Supaul' },
  { label: 'Supe', value: 'Supe' },
  { label: 'Supedi', value: 'Supedi' },
  { label: 'Superior', value: 'Superior' },
  { label: 'Superior', value: 'Superior' },
  { label: 'Superior', value: 'Superior' },
  { label: 'Superior', value: 'Superior' },
  { label: 'Superior', value: 'Superior' },
  { label: 'Supersano', value: 'Supersano' },
  { label: 'Supetar', value: 'Supetar' },
  { label: 'Supetarska Draga', value: 'Supetarska Draga' },
  { label: 'Suphan Buri', value: 'Suphan Buri' },
  { label: 'Supía', value: 'Supía' },
  { label: 'Supino', value: 'Supino' },
  { label: 'Șupitca', value: 'Șupitca' },
  { label: 'Suplac', value: 'Suplac' },
  { label: 'Suplacu De Barcău', value: 'Suplacu De Barcău' },
  { label: 'Süplingen', value: 'Süplingen' },
  { label: 'Suponevo', value: 'Suponevo' },
  { label: 'Süpplingen', value: 'Süpplingen' },
  { label: 'Supraśl', value: 'Supraśl' },
  { label: 'Supreme', value: 'Supreme' },
  { label: 'Supuru De Jos', value: 'Supuru De Jos' },
  { label: 'Suq', value: 'Suq' },
  { label: 'Suq Suwayq', value: 'Suq Suwayq' },
  { label: 'Suqian', value: 'Suqian' },
  { label: 'Suquamish', value: 'Suquamish' },
  { label: 'Suqubiya', value: 'Suqubiya' },
  { label: 'Sur', value: 'Sur' },
  { label: 'Sur', value: 'Sur' },
  { label: 'Súr', value: 'Súr' },
  { label: 'Sura', value: 'Sura' },
  { label: 'Şura Mare', value: 'Şura Mare' },
  { label: 'Şura Mică', value: 'Şura Mică' },
  { label: 'Şuraabad', value: 'Şuraabad' },
  { label: 'Surab', value: 'Surab' },
  { label: 'Surabaya', value: 'Surabaya' },
  { label: 'Surahammar', value: 'Surahammar' },
  { label: 'Suraia', value: 'Suraia' },
  { label: 'Surajgarh', value: 'Surajgarh' },
  { label: 'Surak', value: 'Surak' },
  { label: 'Surakarta', value: 'Surakarta' },
  { label: 'Surallah', value: 'Surallah' },
  { label: 'Surallah', value: 'Surallah' },
  { label: 'Surami', value: 'Surami' },
  { label: 'Suran', value: 'Suran' },
  { label: 'Şūrān', value: 'Şūrān' },
  { label: 'Surandai', value: 'Surandai' },
  { label: 'Surani', value: 'Surani' },
  { label: 'Surano', value: 'Surano' },
  { label: 'Šurany', value: 'Šurany' },
  { label: 'Surat', value: 'Surat' },
  { label: 'Surat Thani', value: 'Surat Thani' },
  { label: 'Suratá', value: 'Suratá' },
  { label: 'Suratgarh', value: 'Suratgarh' },
  { label: 'Suraż', value: 'Suraż' },
  { label: 'Surazh', value: 'Surazh' },
  { label: 'Surazhskiy Rayon', value: 'Surazhskiy Rayon' },
  { label: 'Surberg', value: 'Surberg' },
  { label: 'Surbiton', value: 'Surbiton' },
  { label: 'Surbo', value: 'Surbo' },
  { label: 'Surbourg', value: 'Surbourg' },
  { label: 'Surčin', value: 'Surčin' },
  { label: 'Șurdești', value: 'Șurdești' },
  { label: 'Surdila-Găiseanca', value: 'Surdila-Găiseanca' },
  { label: 'Surdila-Greci', value: 'Surdila-Greci' },
  { label: 'Surdo', value: 'Surdo' },
  { label: 'Surduc', value: 'Surduc' },
  { label: 'Surduk', value: 'Surduk' },
  { label: 'Surdulești', value: 'Surdulești' },
  { label: 'Surenavan', value: 'Surenavan' },
  { label: 'Surendranagar', value: 'Surendranagar' },
  { label: 'Sureshjan', value: 'Sureshjan' },
  { label: 'Suresnes', value: 'Suresnes' },
  { label: 'Surf City', value: 'Surf City' },
  { label: 'Surf City', value: 'Surf City' },
  { label: 'Surf Coast', value: 'Surf Coast' },
  { label: 'Surfers Paradise', value: 'Surfers Paradise' },
  { label: 'Surfside', value: 'Surfside' },
  { label: 'Surfside', value: 'Surfside' },
  { label: 'Surfside Beach', value: 'Surfside Beach' },
  { label: 'Surgana', value: 'Surgana' },
  { label: 'Surgères', value: 'Surgères' },
  { label: 'Surgoinsville', value: 'Surgoinsville' },
  { label: 'Surguja', value: 'Surguja' },
  { label: 'Surgut', value: 'Surgut' },
  { label: 'Surgut', value: 'Surgut' },
  { label: 'Surhuisterveen', value: 'Surhuisterveen' },
  { label: 'Suri', value: 'Suri' },
  { label: 'Súria', value: 'Súria' },
  { label: 'Surianwan', value: 'Surianwan' },
  { label: 'Suriapet', value: 'Suriapet' },
  { label: 'Surigao', value: 'Surigao' },
  { label: 'Surigao City', value: 'Surigao City' },
  { label: 'Surin', value: 'Surin' },
  { label: 'Surinam', value: 'Surinam' },
  { label: 'Surkhakhi', value: 'Surkhakhi' },
  { label: 'Surkhet ', value: 'Surkhet ' },
  { label: 'Surkhpur', value: 'Surkhpur' },
  { label: 'Şurmān', value: 'Şurmān' },
  { label: 'Surmaq', value: 'Surmaq' },
  { label: 'Sürmene', value: 'Sürmene' },
  { label: 'Surnadal', value: 'Surnadal' },
  { label: 'Surok', value: 'Surok' },
  { label: 'Surovatikha', value: 'Surovatikha' },
  { label: 'Surovikino', value: 'Surovikino' },
  { label: 'Surprise', value: 'Surprise' },
  { label: 'Surrey', value: 'Surrey' },
  { label: 'Surrey', value: 'Surrey' },
  { label: 'Surrey', value: 'Surrey' },
  { label: 'Surrey Downs', value: 'Surrey Downs' },
  { label: 'Surrey Hills', value: 'Surrey Hills' },
  { label: 'Surry', value: 'Surry' },
  { label: 'Surry', value: 'Surry' },
  { label: 'Surry County', value: 'Surry County' },
  { label: 'Surry County', value: 'Surry County' },
  { label: 'Surry Hills', value: 'Surry Hills' },
  { label: 'Surs’Ko-Mykhaylivka', value: 'Surs’Ko-Mykhaylivka' },
  { label: 'Sursee', value: 'Sursee' },
  { label: 'Sursee District', value: 'Sursee District' },
  { label: 'Sursk', value: 'Sursk' },
  { label: 'Surskoye', value: 'Surskoye' },
  { label: 'Surtainville', value: 'Surtainville' },
  { label: 'Surtajin', value: 'Surtajin' },
  { label: 'Surte', value: 'Surte' },
  { label: 'Surubim', value: 'Surubim' },
  { label: 'Suruç', value: 'Suruç' },
  { label: 'Surulere', value: 'Surulere' },
  { label: 'Surup', value: 'Surup' },
  { label: 'Surup', value: 'Surup' },
  { label: 'Surup', value: 'Surup' },
  { label: 'Survilliers', value: 'Survilliers' },
  { label: 'Surwold', value: 'Surwold' },
  { label: 'Sury-Le-Comtal', value: 'Sury-Le-Comtal' },
  { label: 'Surzur', value: 'Surzur' },
  { label: 'Susa', value: 'Susa' },
  { label: 'Susa', value: 'Susa' },
  { label: 'Susacón', value: 'Susacón' },
  { label: 'Susaki', value: 'Susaki' },
  { label: 'Susangerd', value: 'Susangerd' },
  { label: 'Şuşani', value: 'Şuşani' },
  { label: 'Susanino', value: 'Susanino' },
  { label: 'Susanino', value: 'Susanino' },
  { label: 'Šušanj', value: 'Šušanj' },
  { label: 'Susanville', value: 'Susanville' },
  { label: 'Susegana', value: 'Susegana' },
  { label: 'Suşehri', value: 'Suşehri' },
  { label: 'Süsel', value: 'Süsel' },
  { label: 'Susella', value: 'Susella' },
  { label: 'Suseni', value: 'Suseni' },
  { label: 'Suseni', value: 'Suseni' },
  { label: 'Suseni', value: 'Suseni' },
  { label: 'Suseni Socetu', value: 'Suseni Socetu' },
  { label: 'Susenii Bârgăului', value: 'Susenii Bârgăului' },
  { label: 'Suseong-Gu', value: 'Suseong-Gu' },
  { label: 'Sušica', value: 'Sušica' },
  { label: 'Sušice', value: 'Sušice' },
  { label: 'Susiec', value: 'Susiec' },
  { label: 'Susinos Del Páramo', value: 'Susinos Del Páramo' },
  { label: 'Suslonger', value: 'Suslonger' },
  { label: 'Suslovo', value: 'Suslovo' },
  { label: 'Susner', value: 'Susner' },
  { label: 'Suso', value: 'Suso' },
  { label: 'Suso', value: 'Suso' },
  { label: 'Susono', value: 'Susono' },
  { label: 'Susono Shi', value: 'Susono Shi' },
  { label: 'Suspiro Picacho', value: 'Suspiro Picacho' },
  { label: 'Susqueda', value: 'Susqueda' },
  { label: 'Susquehanna', value: 'Susquehanna' },
  { label: 'Susquehanna County', value: 'Susquehanna County' },
  { label: 'Susquehanna Trails', value: 'Susquehanna Trails' },
  { label: 'Sussargues', value: 'Sussargues' },
  { label: 'Süßen', value: 'Süßen' },
  { label: 'Sussex', value: 'Sussex' },
  { label: 'Sussex', value: 'Sussex' },
  { label: 'Sussex', value: 'Sussex' },
  { label: 'Sussex', value: 'Sussex' },
  { label: 'Sussex County', value: 'Sussex County' },
  { label: 'Sussex County', value: 'Sussex County' },
  { label: 'Sussex County', value: 'Sussex County' },
  { label: 'Sussex Inlet', value: 'Sussex Inlet' },
  { label: 'Sussuapara', value: 'Sussuapara' },
  { label: 'Süstedt', value: 'Süstedt' },
  { label: 'Susticacán', value: 'Susticacán' },
  { label: 'Sustinente', value: 'Sustinente' },
  { label: 'Sustrum', value: 'Sustrum' },
  { label: 'Susuman', value: 'Susuman' },
  { label: 'Susupuato De Guerrero', value: 'Susupuato De Guerrero' },
  { label: 'Susurluk', value: 'Susurluk' },
  { label: 'Susuz', value: 'Susuz' },
  { label: 'Susz', value: 'Susz' },
  { label: 'Suszec', value: 'Suszec' },
  { label: 'Sutamarchán', value: 'Sutamarchán' },
  { label: 'Sutatausa', value: 'Sutatausa' },
  { label: 'Sutatenza', value: 'Sutatenza' },
  { label: 'Sütçüler', value: 'Sütçüler' },
  { label: 'Sutera', value: 'Sutera' },
  { label: 'Sutești', value: 'Sutești' },
  { label: 'Şuţeşti', value: 'Şuţeşti' },
  { label: 'Sutherland', value: 'Sutherland' },
  { label: 'Sutherland', value: 'Sutherland' },
  { label: 'Sutherland Shire', value: 'Sutherland Shire' },
  { label: 'Sutherlin', value: 'Sutherlin' },
  { label: 'Sutjeska', value: 'Sutjeska' },
  { label: 'Sut-Khol’', value: 'Sut-Khol’' },
  { label: 'Sutlaq', value: 'Sutlaq' },
  { label: 'Šuto Orizare', value: 'Šuto Orizare' },
  { label: 'Sutomore', value: 'Sutomore' },
  { label: 'Sutrapada', value: 'Sutrapada' },
  { label: 'Sutri', value: 'Sutri' },
  { label: 'Sutrio', value: 'Sutrio' },
  { label: 'Sutter', value: 'Sutter' },
  { label: 'Sutter County', value: 'Sutter County' },
  { label: 'Sutter Creek', value: 'Sutter Creek' },
  { label: 'Sutterton', value: 'Sutterton' },
  { label: 'Süttő', value: 'Süttő' },
  { label: 'Sutton', value: 'Sutton' },
  { label: 'Sutton', value: 'Sutton' },
  { label: 'Sutton', value: 'Sutton' },
  { label: 'Sutton', value: 'Sutton' },
  { label: 'Sutton', value: 'Sutton' },
  { label: 'Sutton', value: 'Sutton' },
  { label: 'Sutton', value: 'Sutton' },
  { label: 'Sutton', value: 'Sutton' },
  { label: 'Sutton Benger', value: 'Sutton Benger' },
  { label: 'Sutton Bonington', value: 'Sutton Bonington' },
  { label: 'Sutton Bridge', value: 'Sutton Bridge' },
  { label: 'Sutton Coldfield', value: 'Sutton Coldfield' },
  { label: 'Sutton County', value: 'Sutton County' },
  { label: 'Sutton Courtenay', value: 'Sutton Courtenay' },
  { label: 'Sutton In Ashfield', value: 'Sutton In Ashfield' },
  { label: 'Sutton On Trent', value: 'Sutton On Trent' },
  { label: 'Sutton Upon Derwent', value: 'Sutton Upon Derwent' },
  { label: 'Sutton-Alpine', value: 'Sutton-Alpine' },
  { label: 'Sutukoba', value: 'Sutukoba' },
  { label: 'Sutukung', value: 'Sutukung' },
  { label: 'Sutysky', value: 'Sutysky' },
  { label: 'Suure-Jaani', value: 'Suure-Jaani' },
  { label: 'Suva', value: 'Suva' },
  { label: 'Suveica', value: 'Suveica' },
  { label: 'Suvereto', value: 'Suvereto' },
  { label: 'Suvorov', value: 'Suvorov' },
  { label: 'Suvorovo', value: 'Suvorovo' },
  { label: 'Suvorovo', value: 'Suvorovo' },
  { label: 'Suvorovskaya', value: 'Suvorovskaya' },
  { label: 'Suvorovskiy Rayon', value: 'Suvorovskiy Rayon' },
  { label: 'Suvorovskoye', value: 'Suvorovskoye' },
  { label: 'Suwa', value: 'Suwa' },
  { label: 'Suwa', value: 'Suwa' },
  { label: 'Suwa Shi', value: 'Suwa Shi' },
  { label: 'Suwadah', value: 'Suwadah' },
  { label: 'Suwałki', value: 'Suwałki' },
  { label: 'Suwanee', value: 'Suwanee' },
  { label: 'Suwannaphum', value: 'Suwannaphum' },
  { label: 'Suwannee County', value: 'Suwannee County' },
  { label: 'Suwayr', value: 'Suwayr' },
  { label: 'Şuwayr', value: 'Şuwayr' },
  { label: 'Suways', value: 'Suways' },
  { label: 'Suwon', value: 'Suwon' },
  { label: 'Suya', value: 'Suya' },
  { label: 'Suyan', value: 'Suyan' },
  { label: 'Suyan', value: 'Suyan' },
  { label: 'Suyeong-Gu', value: 'Suyeong-Gu' },
  { label: 'Suykbulak', value: 'Suykbulak' },
  { label: 'Suyo', value: 'Suyo' },
  { label: 'Suyo', value: 'Suyo' },
  { label: 'Suzā', value: 'Suzā' },
  { label: 'Suzak', value: 'Suzak' },
  { label: 'Suzaka', value: 'Suzaka' },
  { label: 'Suzaka', value: 'Suzaka' },
  { label: 'Suzaka-Shi', value: 'Suzaka-Shi' },
  { label: 'Suzanápolis', value: 'Suzanápolis' },
  { label: 'Suzano', value: 'Suzano' },
  { label: 'Suzdal’', value: 'Suzdal’' },
  { label: 'Suzdal’Skiy Rayon', value: 'Suzdal’Skiy Rayon' },
  { label: 'Suze-La-Rousse', value: 'Suze-La-Rousse' },
  { label: 'Suzëmka', value: 'Suzëmka' },
  { label: 'Suzemskiy Rayon', value: 'Suzemskiy Rayon' },
  { label: 'Suzhou', value: 'Suzhou' },
  { label: 'Suzhou', value: 'Suzhou' },
  { label: 'Suzhou', value: 'Suzhou' },
  { label: 'Suzhou Shi', value: 'Suzhou Shi' },
  { label: 'Suz-On', value: 'Suz-On' },
  { label: 'Suz-On', value: 'Suz-On' },
  { label: 'Suz-On', value: 'Suz-On' },
  { label: 'Suzu', value: 'Suzu' },
  { label: 'Suzu Shi', value: 'Suzu Shi' },
  { label: 'Suzuka', value: 'Suzuka' },
  { label: 'Suzuka-Shi', value: 'Suzuka-Shi' },
  { label: 'Suzun', value: 'Suzun' },
  { label: 'Suzzara', value: 'Suzzara' },
  { label: 'Sv. Ana V Slov. Goricah', value: 'Sv. Ana V Slov. Goricah' },
  { label: 'Sv. Anton', value: 'Sv. Anton' },
  { label: 'Sv. Duh', value: 'Sv. Duh' },
  { label: 'Sv. Trojica V Slov. Goricah', value: 'Sv. Trojica V Slov. Goricah' },
  { label: 'Svalbarðsstrandarhreppur', value: 'Svalbarðsstrandarhreppur' },
  { label: 'Svaliava Raion', value: 'Svaliava Raion' },
  { label: 'Svalöv', value: 'Svalöv' },
  { label: 'Svalsta', value: 'Svalsta' },
  { label: 'Svalyava', value: 'Svalyava' },
  { label: 'Svanesund', value: 'Svanesund' },
  { label: 'Svängsta', value: 'Svängsta' },
  { label: 'Svärdsjö', value: 'Svärdsjö' },
  { label: 'Svärtinge', value: 'Svärtinge' },
  { label: 'Svarychiv', value: 'Svarychiv' },
  { label: 'Svatava', value: 'Svatava' },
  { label: 'Svatkovo', value: 'Svatkovo' },
  { label: 'Svatove', value: 'Svatove' },
  { label: 'Svätý Anton', value: 'Svätý Anton' },
  { label: 'Svätý Jur', value: 'Svätý Jur' },
  { label: 'Svay Leu', value: 'Svay Leu' },
  { label: 'Svay Rieng', value: 'Svay Rieng' },
  { label: 'Svebølle', value: 'Svebølle' },
  { label: 'Svecha', value: 'Svecha' },
  { label: 'Svedala', value: 'Svedala' },
  { label: 'Sveio', value: 'Sveio' },
  { label: 'Sveitarfélagið Skagafjörður', value: 'Sveitarfélagið Skagafjörður' },
  { label: 'Svejbæk', value: 'Svejbæk' },
  { label: 'Svelgen', value: 'Svelgen' },
  { label: 'Svelvik', value: 'Svelvik' },
  { label: 'Sven’', value: 'Sven’' },
  { label: 'Švenčionėliai', value: 'Švenčionėliai' },
  { label: 'Svencionys', value: 'Svencionys' },
  { label: 'Svendborg', value: 'Svendborg' },
  { label: 'Svendborg Kommune', value: 'Svendborg Kommune' },
  { label: 'Svenljunga', value: 'Svenljunga' },
  { label: 'Svensson Heights', value: 'Svensson Heights' },
  { label: 'Svenstrup', value: 'Svenstrup' },
  { label: 'Šventoji', value: 'Šventoji' },
  { label: 'Sverdlovs’K', value: 'Sverdlovs’K' },
  { label: 'Sverdlovs’Ka Mis’Krada', value: 'Sverdlovs’Ka Mis’Krada' },
  { label: 'Sverdlovskiy', value: 'Sverdlovskiy' },
  { label: 'Sveta Nedelja', value: 'Sveta Nedelja' },
  { label: 'Sveta Nedelja', value: 'Sveta Nedelja' },
  { label: 'Sveta Nedjelja', value: 'Sveta Nedjelja' },
  { label: 'Sveti Đurđ', value: 'Sveti Đurđ' },
  { label: 'Sveti Filip I Jakov', value: 'Sveti Filip I Jakov' },
  { label: 'Sveti Juraj Na Bregu', value: 'Sveti Juraj Na Bregu' },
  { label: 'Sveti Jurij Ob Ščavnici', value: 'Sveti Jurij Ob Ščavnici' },
  { label: 'Sveti Križ Začretje', value: 'Sveti Križ Začretje' },
  { label: 'Sveti Lovreč', value: 'Sveti Lovreč' },
  { label: 'Sveti Nikole', value: 'Sveti Nikole' },
  { label: 'Sveti Tomaž', value: 'Sveti Tomaž' },
  { label: 'Sveti Vlas', value: 'Sveti Vlas' },
  { label: 'Světlá Nad Sázavou', value: 'Světlá Nad Sázavou' },
  { label: 'Svetlanovskiy', value: 'Svetlanovskiy' },
  { label: 'Svetlaya', value: 'Svetlaya' },
  { label: 'Svetlodarsk', value: 'Svetlodarsk' },
  { label: 'Svetlogorsk', value: 'Svetlogorsk' },
  { label: 'Svetlogorsk', value: 'Svetlogorsk' },
  { label: 'Svetlograd', value: 'Svetlograd' },
  { label: 'Svetlopolyansk', value: 'Svetlopolyansk' },
  { label: 'Svetloye', value: 'Svetloye' },
  { label: 'Svetly Yar', value: 'Svetly Yar' },
  { label: 'Svetlyy', value: 'Svetlyy' },
  { label: 'Svetlyy', value: 'Svetlyy' },
  { label: 'Svetlyy', value: 'Svetlyy' },
  { label: 'Svetlyy', value: 'Svetlyy' },
  { label: 'Svetogorsk', value: 'Svetogorsk' },
  { label: 'Sviadnov', value: 'Sviadnov' },
  { label: 'Sviblovo', value: 'Sviblovo' },
  { label: 'Svidník', value: 'Svidník' },
  { label: 'Švihov', value: 'Švihov' },
  { label: 'Svilengrad', value: 'Svilengrad' },
  { label: 'Sviniţa', value: 'Sviniţa' },
  { label: 'Svinninge', value: 'Svinninge' },
  { label: 'Svir', value: 'Svir' },
  { label: 'Svirsk', value: 'Svirsk' },
  { label: 'Svishtov', value: 'Svishtov' },
  { label: 'Svislach', value: 'Svislach' },
  { label: 'Svislach', value: 'Svislach' },
  { label: 'Svit', value: 'Svit' },
  { label: 'Svitávka', value: 'Svitávka' },
  { label: 'Svitavy', value: 'Svitavy' },
  { label: 'Svitlovods’K', value: 'Svitlovods’K' },
  { label: 'Svityaz’', value: 'Svityaz’' },
  { label: 'Sviyazhsk', value: 'Sviyazhsk' },
  { label: 'Svoboda Nad Úpou', value: 'Svoboda Nad Úpou' },
  { label: 'Svobodnenskiy Rayon', value: 'Svobodnenskiy Rayon' },
  { label: 'Svobodnyy', value: 'Svobodnyy' },
  { label: 'Svobodnyy', value: 'Svobodnyy' },
  { label: 'Svobodnyy', value: 'Svobodnyy' },
  { label: 'Svobody', value: 'Svobody' },
  { label: 'Svodín', value: 'Svodín' },
  { label: 'Svodna', value: 'Svodna' },
  { label: 'Svoge', value: 'Svoge' },
  { label: 'Svogerslev', value: 'Svogerslev' },
  { label: 'Svolvær', value: 'Svolvær' },
  { label: 'Svorónos', value: 'Svorónos' },
  { label: 'Svratka', value: 'Svratka' },
  { label: 'Svyatogorsk', value: 'Svyatogorsk' },
  { label: 'Svyatoshyns’Kyy Rayon', value: 'Svyatoshyns’Kyy Rayon' },
  { label: 'Svyetlahorsk', value: 'Svyetlahorsk' },
  { label: 'Swabi', value: 'Swabi' },
  { label: 'Swabia', value: 'Swabia' },
  { label: 'Swabys Hope', value: 'Swabys Hope' },
  { label: 'Swadlincote', value: 'Swadlincote' },
  { label: 'Swaffham', value: 'Swaffham' },
  { label: 'Swain County', value: 'Swain County' },
  { label: 'Swainsboro', value: 'Swainsboro' },
  { label: 'Swakopmund', value: 'Swakopmund' },
  { label: 'Swakopmund Constituency', value: 'Swakopmund Constituency' },
  { label: 'Swallowfield', value: 'Swallowfield' },
  { label: 'Swamimalai', value: 'Swamimalai' },
  { label: 'Swampscott', value: 'Swampscott' },
  { label: 'Swan', value: 'Swan' },
  { label: 'Swan Hill', value: 'Swan Hill' },
  { label: 'Swan Hills', value: 'Swan Hills' },
  { label: 'Swan River', value: 'Swan River' },
  { label: 'Swan View', value: 'Swan View' },
  { label: 'Swanage', value: 'Swanage' },
  { label: 'Swanbourne', value: 'Swanbourne' },
  { label: 'Swanley', value: 'Swanley' },
  { label: 'Swanmore', value: 'Swanmore' },
  { label: 'Swannanoa', value: 'Swannanoa' },
  { label: 'Swanquarter', value: 'Swanquarter' },
  { label: 'Swansboro', value: 'Swansboro' },
  { label: 'Swanscombe', value: 'Swanscombe' },
  { label: 'Swansea', value: 'Swansea' },
  { label: 'Swansea', value: 'Swansea' },
  { label: 'Swansea', value: 'Swansea' },
  { label: 'Swansea', value: 'Swansea' },
  { label: 'Swanton', value: 'Swanton' },
  { label: 'Swanton', value: 'Swanton' },
  { label: 'Swanville', value: 'Swanville' },
  { label: 'Swanzey', value: 'Swanzey' },
  { label: 'Swarthmoor', value: 'Swarthmoor' },
  { label: 'Swarthmore', value: 'Swarthmore' },
  { label: 'Swartz', value: 'Swartz' },
  { label: 'Swartz Creek', value: 'Swartz Creek' },
  { label: 'Swartzville', value: 'Swartzville' },
  { label: 'Swarupnagar Community Development Block', value: 'Swarupnagar Community Development Block' },
  { label: 'Swarzędz', value: 'Swarzędz' },
  { label: 'Swatar', value: 'Swatar' },
  { label: 'Swavesey', value: 'Swavesey' },
  { label: 'Sway', value: 'Sway' },
  { label: 'Swedesboro', value: 'Swedesboro' },
  { label: 'Swedru', value: 'Swedru' },
  { label: 'Sweeny', value: 'Sweeny' },
  { label: 'Sweet Grass County', value: 'Sweet Grass County' },
  { label: 'Sweet Home', value: 'Sweet Home' },
  { label: 'Sweet Springs', value: 'Sweet Springs' },
  { label: 'Sweetser', value: 'Sweetser' },
  { label: 'Sweetwater', value: 'Sweetwater' },
  { label: 'Sweetwater', value: 'Sweetwater' },
  { label: 'Sweetwater', value: 'Sweetwater' },
  { label: 'Sweetwater County', value: 'Sweetwater County' },
  { label: 'Swellendam', value: 'Swellendam' },
  { label: 'Swepsonville', value: 'Swepsonville' },
  { label: 'Świątniki Górne', value: 'Świątniki Górne' },
  { label: 'Świdnica', value: 'Świdnica' },
  { label: 'Świdnica', value: 'Świdnica' },
  { label: 'Świdnik', value: 'Świdnik' },
  { label: 'Świdwin', value: 'Świdwin' },
  { label: 'Świebodzice', value: 'Świebodzice' },
  { label: 'Świebodzin', value: 'Świebodzin' },
  { label: 'Świecie', value: 'Świecie' },
  { label: 'Świecie Nad Osą', value: 'Świecie Nad Osą' },
  { label: 'Święciechowa', value: 'Święciechowa' },
  { label: 'Świedziebnia', value: 'Świedziebnia' },
  { label: 'Świekatowo', value: 'Świekatowo' },
  { label: 'Swieqi', value: 'Swieqi' },
  { label: 'Świeradów-Zdrój', value: 'Świeradów-Zdrój' },
  { label: 'Świercze', value: 'Świercze' },
  { label: 'Świerczów', value: 'Świerczów' },
  { label: 'Świerklaniec', value: 'Świerklaniec' },
  { label: 'Świerklany Dolne', value: 'Świerklany Dolne' },
  { label: 'Świerklany Górne', value: 'Świerklany Górne' },
  { label: 'Świerzawa', value: 'Świerzawa' },
  { label: 'Świerzno', value: 'Świerzno' },
  { label: 'Święta Katarzyna', value: 'Święta Katarzyna' },
  { label: 'Swiętajno', value: 'Swiętajno' },
  { label: 'Świętochłowice', value: 'Świętochłowice' },
  { label: 'Swift County', value: 'Swift County' },
  { label: 'Swift Current', value: 'Swift Current' },
  { label: 'Swift River', value: 'Swift River' },
  { label: 'Swift Trail Junction', value: 'Swift Trail Junction' },
  { label: 'Świlcza', value: 'Świlcza' },
  { label: 'Swillington', value: 'Swillington' },
  { label: 'Swindon', value: 'Swindon' },
  { label: 'Swineshead', value: 'Swineshead' },
  { label: 'Swinford', value: 'Swinford' },
  { label: 'Świnice Warckie', value: 'Świnice Warckie' },
  { label: 'Świnna', value: 'Świnna' },
  { label: 'Świnoujście', value: 'Świnoujście' },
  { label: 'Swinton', value: 'Swinton' },
  { label: 'Swisher County', value: 'Swisher County' },
  { label: 'Swissvale', value: 'Swissvale' },
  { label: 'Switzerland County', value: 'Switzerland County' },
  { label: 'Swojczyce', value: 'Swojczyce' },
  { label: 'Swords', value: 'Swords' },
  { label: 'Swoyersville', value: 'Swoyersville' },
  { label: 'Syamzha', value: 'Syamzha' },
  { label: 'Syamzhenskiy Rayon', value: 'Syamzhenskiy Rayon' },
  { label: 'Syangja ', value: 'Syangja ' },
  { label: 'Syanno', value: 'Syanno' },
  { label: 'Syas’Stroy', value: 'Syas’Stroy' },
  { label: 'Syava', value: 'Syava' },
  { label: 'Sycamore', value: 'Sycamore' },
  { label: 'Sychëvka', value: 'Sychëvka' },
  { label: 'Sychëvka', value: 'Sychëvka' },
  { label: 'Sychëvo', value: 'Sychëvo' },
  { label: 'Syców', value: 'Syców' },
  { label: 'Syddjurs Kommune', value: 'Syddjurs Kommune' },
  { label: 'Sydenham', value: 'Sydenham' },
  { label: 'Sydenham', value: 'Sydenham' },
  { label: 'Sydney', value: 'Sydney' },
  { label: 'Sydney', value: 'Sydney' },
  { label: 'Sydney Central Business District', value: 'Sydney Central Business District' },
  { label: 'Sydney Mines', value: 'Sydney Mines' },
  { label: 'Sydney Olympic Park', value: 'Sydney Olympic Park' },
  { label: 'Syedove', value: 'Syedove' },
  { label: 'Syenitsa', value: 'Syenitsa' },
  { label: 'Syennyenski Rayon', value: 'Syennyenski Rayon' },
  { label: 'Syevyerodonets’K', value: 'Syevyerodonets’K' },
  { label: 'Syfteland', value: 'Syfteland' },
  { label: 'Syke', value: 'Syke' },
  { label: 'Sykesville', value: 'Sykesville' },
  { label: 'Sykesville', value: 'Sykesville' },
  { label: 'Sykiá', value: 'Sykiá' },
  { label: 'Sykiá', value: 'Sykiá' },
  { label: 'Sykiés', value: 'Sykiés' },
  { label: 'Sykkylven', value: 'Sykkylven' },
  { label: 'Syktyvkar', value: 'Syktyvkar' },
  { label: 'Sylacauga', value: 'Sylacauga' },
  { label: 'Sylhet ', value: 'Sylhet ' },
  { label: 'Sylivainiótika', value: 'Sylivainiótika' },
  { label: 'Sylt-Ost', value: 'Sylt-Ost' },
  { label: 'Sylva', value: 'Sylva' },
  { label: 'Sylva', value: 'Sylva' },
  { label: 'Sylva', value: 'Sylva' },
  { label: 'Sylvan Beach', value: 'Sylvan Beach' },
  { label: 'Sylvan Lake', value: 'Sylvan Lake' },
  { label: 'Sylvan Lake', value: 'Sylvan Lake' },
  { label: 'Sylvan Springs', value: 'Sylvan Springs' },
  { label: 'Sylvania', value: 'Sylvania' },
  { label: 'Sylvania', value: 'Sylvania' },
  { label: 'Sylvania', value: 'Sylvania' },
  { label: 'Sylvania', value: 'Sylvania' },
  { label: 'Sylvania Waters', value: 'Sylvania Waters' },
  { label: 'Sylvester', value: 'Sylvester' },
  { label: 'Sými', value: 'Sými' },
  { label: 'Symington', value: 'Symington' },
  { label: 'Synel’Nykove', value: 'Synel’Nykove' },
  { label: 'Synevyr', value: 'Synevyr' },
  { label: 'Synevyrska Poliana', value: 'Synevyrska Poliana' },
  { label: 'Synoikismós Chavaríou', value: 'Synoikismós Chavaríou' },
  { label: 'Syntul', value: 'Syntul' },
  { label: 'Synya', value: 'Synya' },
  { label: 'Syomkava', value: 'Syomkava' },
  { label: 'Syosset', value: 'Syosset' },
  { label: 'Sypniewo', value: 'Sypniewo' },
  { label: 'Sypniewo', value: 'Sypniewo' },
  { label: 'Sypniewo', value: 'Sypniewo' },
  { label: 'Syracuse', value: 'Syracuse' },
  { label: 'Syracuse', value: 'Syracuse' },
  { label: 'Syracuse', value: 'Syracuse' },
  { label: 'Syracuse', value: 'Syracuse' },
  { label: 'Syracuse', value: 'Syracuse' },
  { label: 'Syrau', value: 'Syrau' },
  { label: 'Syriam', value: 'Syriam' },
  { label: 'Syrostan', value: 'Syrostan' },
  { label: 'Syrskoye', value: 'Syrskoye' },
  { label: 'Syrtych', value: 'Syrtych' },
  { label: 'Syrynia', value: 'Syrynia' },
  { label: 'Sysert’', value: 'Sysert’' },
  { label: 'Sysertskiy Rayon', value: 'Sysertskiy Rayon' },
  { label: 'Sysmä', value: 'Sysmä' },
  { label: 'Syston', value: 'Syston' },
  { label: 'Syumsi', value: 'Syumsi' },
  { label: 'Sywell', value: 'Sywell' },
  { label: 'Syzran’', value: 'Syzran’' },
  { label: 'Szabadbattyán', value: 'Szabadbattyán' },
  { label: 'Szabadkígyós', value: 'Szabadkígyós' },
  { label: 'Szabadszállás', value: 'Szabadszállás' },
  { label: 'Szada', value: 'Szada' },
  { label: 'Szadek', value: 'Szadek' },
  { label: 'Szaflary', value: 'Szaflary' },
  { label: 'Szajol', value: 'Szajol' },
  { label: 'Szakoly', value: 'Szakoly' },
  { label: 'Szákszend', value: 'Szákszend' },
  { label: 'Szalkszentmárton', value: 'Szalkszentmárton' },
  { label: 'Szamocin', value: 'Szamocin' },
  { label: 'Szamosszeg', value: 'Szamosszeg' },
  { label: 'Szamotuły', value: 'Szamotuły' },
  { label: 'Szank', value: 'Szank' },
  { label: 'Szany', value: 'Szany' },
  { label: 'Szárliget', value: 'Szárliget' },
  { label: 'Szarów', value: 'Szarów' },
  { label: 'Szarvas', value: 'Szarvas' },
  { label: 'Szarvasi Járás', value: 'Szarvasi Járás' },
  { label: 'Szastarka', value: 'Szastarka' },
  { label: 'Szászvár', value: 'Szászvár' },
  { label: 'Szatymaz', value: 'Szatymaz' },
  { label: 'Százhalombatta', value: 'Százhalombatta' },
  { label: 'Szczaniec', value: 'Szczaniec' },
  { label: 'Szczawnica', value: 'Szczawnica' },
  { label: 'Szczawno-Zdrój', value: 'Szczawno-Zdrój' },
  { label: 'Szczebrzeszyn', value: 'Szczebrzeszyn' },
  { label: 'Szczecin', value: 'Szczecin' },
  { label: 'Szczecinek', value: 'Szczecinek' },
  { label: 'Szczekociny', value: 'Szczekociny' },
  { label: 'Szczepanów', value: 'Szczepanów' },
  { label: 'Szczerbice', value: 'Szczerbice' },
  { label: 'Szczerców', value: 'Szczerców' },
  { label: 'Szczucin', value: 'Szczucin' },
  { label: 'Szczuczyn', value: 'Szczuczyn' },
  { label: 'Szczurowa', value: 'Szczurowa' },
  { label: 'Szczutowo', value: 'Szczutowo' },
  { label: 'Szczyrk', value: 'Szczyrk' },
  { label: 'Szczytna', value: 'Szczytna' },
  { label: 'Szczytno', value: 'Szczytno' },
  { label: 'Szécsény', value: 'Szécsény' },
  { label: 'Szécsényi Járás', value: 'Szécsényi Járás' },
  { label: 'Szedres', value: 'Szedres' },
  { label: 'Szeged', value: 'Szeged' },
  { label: 'Szegedi Járás', value: 'Szegedi Járás' },
  { label: 'Szeghalmi Járás', value: 'Szeghalmi Járás' },
  { label: 'Szeghalom', value: 'Szeghalom' },
  { label: 'Szegvár', value: 'Szegvár' },
  { label: 'Székesfehérvár', value: 'Székesfehérvár' },
  { label: 'Székesfehérvári Járás', value: 'Székesfehérvári Járás' },
  { label: 'Székkutas', value: 'Székkutas' },
  { label: 'Szekszárd', value: 'Szekszárd' },
  { label: 'Szekszárdi Járás', value: 'Szekszárdi Járás' },
  { label: 'Szelevény', value: 'Szelevény' },
  { label: 'Szelków', value: 'Szelków' },
  { label: 'Szemud', value: 'Szemud' },
  { label: 'Szendrő', value: 'Szendrő' },
  { label: 'Szentendre', value: 'Szentendre' },
  { label: 'Szentendrei Járás', value: 'Szentendrei Járás' },
  { label: 'Szentes', value: 'Szentes' },
  { label: 'Szentesi Járás', value: 'Szentesi Járás' },
  { label: 'Szentgálpuszta', value: 'Szentgálpuszta' },
  { label: 'Szentgotthárd', value: 'Szentgotthárd' },
  { label: 'Szentgotthárdi Járás', value: 'Szentgotthárdi Járás' },
  { label: 'Szentistván', value: 'Szentistván' },
  { label: 'Szentkirály', value: 'Szentkirály' },
  { label: 'Szentkirályszabadja', value: 'Szentkirályszabadja' },
  { label: 'Szentlőrinc', value: 'Szentlőrinc' },
  { label: 'Szentlőrinci Járás', value: 'Szentlőrinci Járás' },
  { label: 'Szentlőrinckáta', value: 'Szentlőrinckáta' },
  { label: 'Szentmártonkáta', value: 'Szentmártonkáta' },
  { label: 'Szepietowo', value: 'Szepietowo' },
  { label: 'Szerencs', value: 'Szerencs' },
  { label: 'Szerencsi Járás', value: 'Szerencsi Járás' },
  { label: 'Szerzyny', value: 'Szerzyny' },
  { label: 'Szigetcsép', value: 'Szigetcsép' },
  { label: 'Szigethalom', value: 'Szigethalom' },
  { label: 'Szigetszentmiklós', value: 'Szigetszentmiklós' },
  { label: 'Szigetszentmiklósi Járás', value: 'Szigetszentmiklósi Járás' },
  { label: 'Szigetújfalu', value: 'Szigetújfalu' },
  { label: 'Szigetvár', value: 'Szigetvár' },
  { label: 'Szigetvári Járás', value: 'Szigetvári Járás' },
  { label: 'Szihalom', value: 'Szihalom' },
  { label: 'Szikszó', value: 'Szikszó' },
  { label: 'Szikszói Járás', value: 'Szikszói Járás' },
  { label: 'Szilvásvárad', value: 'Szilvásvárad' },
  { label: 'Szirmabesenyő', value: 'Szirmabesenyő' },
  { label: 'Szklarska Poręba', value: 'Szklarska Poręba' },
  { label: 'Szlachta', value: 'Szlachta' },
  { label: 'Szlichtyngowa', value: 'Szlichtyngowa' },
  { label: 'Szob', value: 'Szob' },
  { label: 'Szobi Járás', value: 'Szobi Járás' },
  { label: 'Sződ', value: 'Sződ' },
  { label: 'Sződliget', value: 'Sződliget' },
  { label: 'Szolnok', value: 'Szolnok' },
  { label: 'Szolnoki Járás', value: 'Szolnoki Járás' },
  { label: 'Szombathely', value: 'Szombathely' },
  { label: 'Szombathelyi Járás', value: 'Szombathelyi Járás' },
  { label: 'Szomód', value: 'Szomód' },
  { label: 'Szomor', value: 'Szomor' },
  { label: 'Szőny', value: 'Szőny' },
  { label: 'Szprotawa', value: 'Szprotawa' },
  { label: 'Szreńsk', value: 'Szreńsk' },
  { label: 'Sztum', value: 'Sztum' },
  { label: 'Sztutowo', value: 'Sztutowo' },
  { label: 'Szubin', value: 'Szubin' },
  { label: 'Szumowo', value: 'Szumowo' },
  { label: 'Szurdokpüspöki', value: 'Szurdokpüspöki' },
  { label: 'Szydłów', value: 'Szydłów' },
  { label: 'Szydłowiec', value: 'Szydłowiec' },
  { label: 'Szydłowo', value: 'Szydłowo' },
  { label: 'Szydłowo', value: 'Szydłowo' },
  { label: 'Szynwałd', value: 'Szynwałd' },
  { label: 'T Hofke', value: 'T Hofke' },
  { label: 'T Horntje', value: 'T Horntje' },
  { label: 'T Kabel', value: 'T Kabel' },
  { label: 'T Nopeind', value: 'T Nopeind' },
  { label: 'T Veld', value: 'T Veld' },
  { label: 'T Zand', value: 'T Zand' },
  { label: 'T’Aebaek', value: 'T’Aebaek' },
  { label: 'T’Alin', value: 'T’Alin' },
  { label: 'T’Et’Ri Tsqaro', value: 'T’Et’Ri Tsqaro' },
  { label: 'T’Ongch’Ŏn-Ŭp', value: 'T’Ongch’Ŏn-Ŭp' },
  { label: 'Ta Khmau', value: 'Ta Khmau' },
  { label: 'Ta Municipality', value: 'Ta Municipality' },
  { label: 'Ta‘Izz', value: 'Ta‘Izz' },
  { label: 'Ta’ Xbiex', value: 'Ta’ Xbiex' },
  { label: 'Taal', value: 'Taal' },
  { label: 'Taastrup', value: 'Taastrup' },
  { label: 'Tab', value: 'Tab' },
  { label: 'Tabaan', value: 'Tabaan' },
  { label: 'Tabaan', value: 'Tabaan' },
  { label: 'Tabacao', value: 'Tabacao' },
  { label: 'Tabacao', value: 'Tabacao' },
  { label: 'Tabachnoye', value: 'Tabachnoye' },
  { label: 'Tabaco', value: 'Tabaco' },
  { label: 'Tabaga', value: 'Tabaga' },
  { label: 'Tabaí', value: 'Tabaí' },
  { label: 'Tabālah', value: 'Tabālah' },
  { label: 'Tabalong', value: 'Tabalong' },
  { label: 'Tabalosos', value: 'Tabalosos' },
  { label: 'Tabanac', value: 'Tabanac' },
  { label: 'Tabanan', value: 'Tabanan' },
  { label: 'Tabanera De Cerrato', value: 'Tabanera De Cerrato' },
  { label: 'Tabanera De Valdavia', value: 'Tabanera De Valdavia' },
  { label: 'Tabanera La Luenga', value: 'Tabanera La Luenga' },
  { label: 'Tabango', value: 'Tabango' },
  { label: 'Tabango', value: 'Tabango' },
  { label: 'Tabanović', value: 'Tabanović' },
  { label: 'Tabaporã', value: 'Tabaporã' },
  { label: 'Tabapuã', value: 'Tabapuã' },
  { label: 'Tabaquite', value: 'Tabaquite' },
  { label: 'Tabăra', value: 'Tabăra' },
  { label: 'Tábara', value: 'Tábara' },
  { label: 'Tábara Arriba', value: 'Tábara Arriba' },
  { label: 'Tăbărăști', value: 'Tăbărăști' },
  { label: 'Tabarjal', value: 'Tabarjal' },
  { label: 'Tabarka', value: 'Tabarka' },
  { label: 'Tabarz', value: 'Tabarz' },
  { label: 'Tabas', value: 'Tabas' },
  { label: 'Tabas', value: 'Tabas' },
  { label: 'Tabas Masina', value: 'Tabas Masina' },
  { label: 'Tabasalu', value: 'Tabasalu' },
  { label: 'Tabasaranskiy Rayon', value: 'Tabasaranskiy Rayon' },
  { label: 'Tabasco', value: 'Tabasco' },
  { label: 'Tabatinga', value: 'Tabatinga' },
  { label: 'Tabatinga', value: 'Tabatinga' },
  { label: 'Taber', value: 'Taber' },
  { label: 'Tabera De Abajo', value: 'Tabera De Abajo' },
  { label: 'Taberg', value: 'Taberg' },
  { label: 'Tabernas', value: 'Tabernas' },
  { label: 'Taberno', value: 'Taberno' },
  { label: 'Tabi Járás', value: 'Tabi Járás' },
  { label: 'Tabia Sid Bel Abbés', value: 'Tabia Sid Bel Abbés' },
  { label: 'Tabiago-Cibrone', value: 'Tabiago-Cibrone' },
  { label: 'Tabiauan', value: 'Tabiauan' },
  { label: 'Tabiauan', value: 'Tabiauan' },
  { label: 'Tabid', value: 'Tabid' },
  { label: 'Tabina', value: 'Tabina' },
  { label: 'Tabinay', value: 'Tabinay' },
  { label: 'Tabing', value: 'Tabing' },
  { label: 'Tabing', value: 'Tabing' },
  { label: 'Tabio', value: 'Tabio' },
  { label: 'Tabio', value: 'Tabio' },
  { label: 'Tabio', value: 'Tabio' },
  { label: 'Tabira', value: 'Tabira' },
  { label: 'Tabiteuea', value: 'Tabiteuea' },
  { label: 'Tablac', value: 'Tablac' },
  { label: 'Tablac', value: 'Tablac' },
  { label: 'Tablas', value: 'Tablas' },
  { label: 'Tablas', value: 'Tablas' },
  { label: 'Tăblășeni', value: 'Tăblășeni' },
  { label: 'Table Top', value: 'Table Top' },
  { label: 'Tabligbo', value: 'Tabligbo' },
  { label: 'Taboada', value: 'Taboada' },
  { label: 'Taboadela', value: 'Taboadela' },
  { label: 'Taboão Da Serra', value: 'Taboão Da Serra' },
  { label: 'Taboc', value: 'Taboc' },
  { label: 'Tabocas Do Brejo Velho', value: 'Tabocas Do Brejo Velho' },
  { label: 'Tabogon', value: 'Tabogon' },
  { label: 'Taboleiro Grande', value: 'Taboleiro Grande' },
  { label: 'Tabon', value: 'Tabon' },
  { label: 'Tabon', value: 'Tabon' },
  { label: 'Tabon', value: 'Tabon' },
  { label: 'Tabon', value: 'Tabon' },
  { label: 'Tabonoc', value: 'Tabonoc' },
  { label: 'Tabonok', value: 'Tabonok' },
  { label: 'Tabontabon', value: 'Tabontabon' },
  { label: 'Tabontabon', value: 'Tabontabon' },
  { label: 'Tabor', value: 'Tabor' },
  { label: 'Tábor', value: 'Tábor' },
  { label: 'Tabor City', value: 'Tabor City' },
  { label: 'Tabora', value: 'Tabora' },
  { label: 'Táborfalva', value: 'Táborfalva' },
  { label: 'Tabory', value: 'Tabory' },
  { label: 'Tabory', value: 'Tabory' },
  { label: 'Taboshar', value: 'Taboshar' },
  { label: 'Tabossi', value: 'Tabossi' },
  { label: 'Tabou', value: 'Tabou' },
  { label: 'Tabriz', value: 'Tabriz' },
  { label: 'Tabu', value: 'Tabu' },
  { label: 'Tábua', value: 'Tábua' },
  { label: 'Tabuaço', value: 'Tabuaço' },
  { label: 'Tabuaeran', value: 'Tabuaeran' },
  { label: 'Tabuan', value: 'Tabuan' },
  { label: 'Tabuating', value: 'Tabuating' },
  { label: 'Tabuating', value: 'Tabuating' },
  { label: 'Tabuc Pontevedra', value: 'Tabuc Pontevedra' },
  { label: 'Tabuelan', value: 'Tabuelan' },
  { label: 'Tabuenca', value: 'Tabuenca' },
  { label: 'Tabug', value: 'Tabug' },
  { label: 'Tabug', value: 'Tabug' },
  { label: 'Tabuk', value: 'Tabuk' },
  { label: 'Tabuk', value: 'Tabuk' },
  { label: 'Tabuk', value: 'Tabuk' },
  { label: 'Tabuleiro', value: 'Tabuleiro' },
  { label: 'Tabuleiro Do Norte', value: 'Tabuleiro Do Norte' },
  { label: 'Tabunok', value: 'Tabunok' },
  { label: 'Tabuny', value: 'Tabuny' },
  { label: 'Tabursuq', value: 'Tabursuq' },
  { label: 'Tabwakea Village', value: 'Tabwakea Village' },
  { label: 'Tacadang', value: 'Tacadang' },
  { label: 'Tacadang', value: 'Tacadang' },
  { label: 'Tacaimbó', value: 'Tacaimbó' },
  { label: 'Tacámbaro', value: 'Tacámbaro' },
  { label: 'Tacámbaro De Codallos', value: 'Tacámbaro De Codallos' },
  { label: 'Tacaná', value: 'Tacaná' },
  { label: 'Tacaratu', value: 'Tacaratu' },
  { label: 'Tacarigua', value: 'Tacarigua' },
  { label: 'Tacátzcuaro', value: 'Tacátzcuaro' },
  { label: 'Taceno', value: 'Taceno' },
  { label: 'Tacheng', value: 'Tacheng' },
  { label: 'Tacheng Diqu', value: 'Tacheng Diqu' },
  { label: 'Tacherting', value: 'Tacherting' },
  { label: 'Tachikawa-Shi', value: 'Tachikawa-Shi' },
  { label: 'Tachilek', value: 'Tachilek' },
  { label: 'Taching Am See', value: 'Taching Am See' },
  { label: 'Tachov', value: 'Tachov' },
  { label: 'Taciba', value: 'Taciba' },
  { label: 'Tacicuaro', value: 'Tacicuaro' },
  { label: 'Tacima', value: 'Tacima' },
  { label: 'Tacligan', value: 'Tacligan' },
  { label: 'Tacloban', value: 'Tacloban' },
  { label: 'Tacloban', value: 'Tacloban' },
  { label: 'Taclobo', value: 'Taclobo' },
  { label: 'Tacna', value: 'Tacna' },
  { label: 'Taco Pozo', value: 'Taco Pozo' },
  { label: 'Tacoaleche', value: 'Tacoaleche' },
  { label: 'Tacoignières', value: 'Tacoignières' },
  { label: 'Tacoma', value: 'Tacoma' },
  { label: 'Tacony', value: 'Tacony' },
  { label: 'Tacopan', value: 'Tacopan' },
  { label: 'Tacoronte', value: 'Tacoronte' },
  { label: 'Tacotalpa', value: 'Tacotalpa' },
  { label: 'Tactic', value: 'Tactic' },
  { label: 'Tacuarembó', value: 'Tacuarembó' },
  { label: 'Tacuarendí', value: 'Tacuarendí' },
  { label: 'Tacuatí', value: 'Tacuatí' },
  { label: 'Tacub', value: 'Tacub' },
  { label: 'Tacuba', value: 'Tacuba' },
  { label: 'Tacuba Nueva', value: 'Tacuba Nueva' },
  { label: 'Tacubaya', value: 'Tacubaya' },
  { label: 'Tacuro (Santa María Tacuro)', value: 'Tacuro (Santa María Tacuro)' },
  { label: 'Tacurong', value: 'Tacurong' },
  { label: 'Tacurong', value: 'Tacurong' },
  { label: 'Tacuru', value: 'Tacuru' },
  { label: 'Tăcuta', value: 'Tăcuta' },
  { label: 'Tadasuni', value: 'Tadasuni' },
  { label: 'Tadcaster', value: 'Tadcaster' },
  { label: 'Taden', value: 'Taden' },
  { label: 'Tadepalle', value: 'Tadepalle' },
  { label: 'Tadepallegudem', value: 'Tadepallegudem' },
  { label: 'Tadian', value: 'Tadian' },
  { label: 'Tadian', value: 'Tadian' },
  { label: 'Tādif', value: 'Tādif' },
  { label: 'Tadjourah', value: 'Tadjourah' },
  { label: 'Tadley', value: 'Tadley' },
  { label: 'Tadmaït', value: 'Tadmaït' },
  { label: 'Tadmur', value: 'Tadmur' },
  { label: 'Tadmur District', value: 'Tadmur District' },
  { label: 'Tadó', value: 'Tadó' },
  { label: 'Tadotsu', value: 'Tadotsu' },
  { label: 'Tadpatri', value: 'Tadpatri' },
  { label: 'Tadrart', value: 'Tadrart' },
  { label: 'Tadten', value: 'Tadten' },
  { label: 'Tadworth', value: 'Tadworth' },
  { label: 'Taean-Gun', value: 'Taean-Gun' },
  { label: 'Taebaek-Si', value: 'Taebaek-Si' },
  { label: 'Taebla', value: 'Taebla' },
  { label: 'Taesal-Li', value: 'Taesal-Li' },
  { label: 'Tafalla', value: 'Tafalla' },
  { label: 'Ţafas', value: 'Ţafas' },
  { label: 'Täferrot', value: 'Täferrot' },
  { label: 'Tafers', value: 'Tafers' },
  { label: 'Tafetán', value: 'Tafetán' },
  { label: 'Tafí Del Valle', value: 'Tafí Del Valle' },
  { label: 'Tafí Viejo', value: 'Tafí Viejo' },
  { label: 'Tafo', value: 'Tafo' },
  { label: 'Tafraout', value: 'Tafraout' },
  { label: 'Tafresh', value: 'Tafresh' },
  { label: 'Tafrijan', value: 'Tafrijan' },
  { label: 'Taft', value: 'Taft' },
  { label: 'Taft', value: 'Taft' },
  { label: 'Taft', value: 'Taft' },
  { label: 'Taft', value: 'Taft' },
  { label: 'Taft', value: 'Taft' },
  { label: 'Taft', value: 'Taft' },
  { label: 'Taft Heights', value: 'Taft Heights' },
  { label: 'Taft Mosswood', value: 'Taft Mosswood' },
  { label: 'Taft Southwest (Historical)', value: 'Taft Southwest (Historical)' },
  { label: 'Taftanāz', value: 'Taftanāz' },
  { label: 'Täfteå', value: 'Täfteå' },
  { label: 'Tafunsak Municipality', value: 'Tafunsak Municipality' },
  { label: 'Ţaga', value: 'Ţaga' },
  { label: 'Tagajō Shi', value: 'Tagajō Shi' },
  { label: 'Tagakpan', value: 'Tagakpan' },
  { label: 'Tagakpan', value: 'Tagakpan' },
  { label: 'Tagakpan', value: 'Tagakpan' },
  { label: 'Tagamanent', value: 'Tagamanent' },
  { label: 'Tagana-An', value: 'Tagana-An' },
  { label: 'Tagana-An', value: 'Tagana-An' },
  { label: 'Taganak', value: 'Taganak' },
  { label: 'Taganrog', value: 'Taganrog' },
  { label: 'Taganskiy', value: 'Taganskiy' },
  { label: 'Tagapul-An', value: 'Tagapul-An' },
  { label: 'Tagapul-An', value: 'Tagapul-An' },
  { label: 'Tagas', value: 'Tagas' },
  { label: 'Tagasilay', value: 'Tagasilay' },
  { label: 'Tagawa', value: 'Tagawa' },
  { label: 'Tagawa Shi', value: 'Tagawa Shi' },
  { label: 'Tagāw-Bāy', value: 'Tagāw-Bāy' },
  { label: 'Tagaytay', value: 'Tagaytay' },
  { label: 'Tagbacan Ibaba', value: 'Tagbacan Ibaba' },
  { label: 'Tagbak', value: 'Tagbak' },
  { label: 'Tagbilaran City', value: 'Tagbilaran City' },
  { label: 'Tagbina', value: 'Tagbina' },
  { label: 'Tagbina', value: 'Tagbina' },
  { label: 'Tagbita', value: 'Tagbita' },
  { label: 'Tagbubungang Diot', value: 'Tagbubungang Diot' },
  { label: 'Tagbubungang Diot', value: 'Tagbubungang Diot' },
  { label: 'Tagburos', value: 'Tagburos' },
  { label: 'Tagcatong', value: 'Tagcatong' },
  { label: 'Tagcatong', value: 'Tagcatong' },
  { label: 'Tagdanua', value: 'Tagdanua' },
  { label: 'Tagdanua', value: 'Tagdanua' },
  { label: 'Tagdanua', value: 'Tagdanua' },
  { label: 'Tagelswangen', value: 'Tagelswangen' },
  { label: 'Tägerwilen', value: 'Tägerwilen' },
  { label: 'Taggì', value: 'Taggì' },
  { label: 'Taggia', value: 'Taggia' },
  { label: 'Taghazout', value: 'Taghazout' },
  { label: 'Tagiura', value: 'Tagiura' },
  { label: 'Tagkawayan Sabang', value: 'Tagkawayan Sabang' },
  { label: 'Tagliacozzo', value: 'Tagliacozzo' },
  { label: 'Tagliaferro', value: 'Tagliaferro' },
  { label: 'Taglio', value: 'Taglio' },
  { label: 'Taglio Di Po', value: 'Taglio Di Po' },
  { label: 'Tagliolo Monferrato', value: 'Tagliolo Monferrato' },
  { label: 'Tagmersheim', value: 'Tagmersheim' },
  { label: 'Tagnanan', value: 'Tagnanan' },
  { label: 'Tagnanan', value: 'Tagnanan' },
  { label: 'Tagnanan', value: 'Tagnanan' },
  { label: 'Tago', value: 'Tago' },
  { label: 'Tago', value: 'Tago' },
  { label: 'Tagob', value: 'Tagob' },
  { label: 'Tagoloan', value: 'Tagoloan' },
  { label: 'Tagondaing', value: 'Tagondaing' },
  { label: 'Tagoytoy', value: 'Tagoytoy' },
  { label: 'Tagta', value: 'Tagta' },
  { label: 'Taguaí', value: 'Taguaí' },
  { label: 'Taguatinga', value: 'Taguatinga' },
  { label: 'Tagudin', value: 'Tagudin' },
  { label: 'Tagudin', value: 'Tagudin' },
  { label: 'Taguig', value: 'Taguig' },
  { label: 'Taguing', value: 'Taguing' },
  { label: 'Taguing', value: 'Taguing' },
  { label: 'Taguisa', value: 'Taguisa' },
  { label: 'Taguisa', value: 'Taguisa' },
  { label: 'Taguitic', value: 'Taguitic' },
  { label: 'Tagum', value: 'Tagum' },
  { label: 'Tagum', value: 'Tagum' },
  { label: 'Tagum Norte', value: 'Tagum Norte' },
  { label: 'Tagusao', value: 'Tagusao' },
  { label: 'Taha La', value: 'Taha La' },
  { label: 'Tahal', value: 'Tahal' },
  { label: 'Tahara', value: 'Tahara' },
  { label: 'Tahara-Shi', value: 'Tahara-Shi' },
  { label: 'Tahdzibichen', value: 'Tahdzibichen' },
  { label: 'Tahdziu', value: 'Tahdziu' },
  { label: 'Tahe', value: 'Tahe' },
  { label: 'Tahitótfalu', value: 'Tahitótfalu' },
  { label: 'Tahla', value: 'Tahla' },
  { label: 'Tahlequah', value: 'Tahlequah' },
  { label: 'Tahmek', value: 'Tahmek' },
  { label: 'Tahmoor', value: 'Tahmoor' },
  { label: 'Tahoe Vista', value: 'Tahoe Vista' },
  { label: 'Tahoka', value: 'Tahoka' },
  { label: 'Tahoma', value: 'Tahoma' },
  { label: 'Tahoua', value: 'Tahoua' },
  { label: 'Ţahţā', value: 'Ţahţā' },
  { label: 'Tahunabad', value: 'Tahunabad' },
  { label: 'Tai Mei Tuk', value: 'Tai Mei Tuk' },
  { label: 'Tai O', value: 'Tai O' },
  { label: 'Tai Po', value: 'Tai Po' },
  { label: 'Tai’An', value: 'Tai’An' },
  { label: 'Taiaçu', value: 'Taiaçu' },
  { label: 'Taibach', value: 'Taibach' },
  { label: 'Taibet', value: 'Taibet' },
  { label: 'Taibon Agordino', value: 'Taibon Agordino' },
  { label: 'Taichung', value: 'Taichung' },
  { label: 'Taichung City', value: 'Taichung City' },
  { label: 'Taif', value: 'Taif' },
  { label: 'Taigum', value: 'Taigum' },
  { label: 'Taihe', value: 'Taihe' },
  { label: 'Taihecun', value: 'Taihecun' },
  { label: 'Taikang', value: 'Taikang' },
  { label: 'Tailai', value: 'Tailai' },
  { label: 'Tailândia', value: 'Tailândia' },
  { label: 'Tailem Bend', value: 'Tailem Bend' },
  { label: 'Tailevu Province', value: 'Tailevu Province' },
  { label: 'Taillades', value: 'Taillades' },
  { label: 'Taillecourt', value: 'Taillecourt' },
  { label: 'Tain', value: 'Tain' },
  { label: 'Tain', value: 'Tain' },
  { label: 'Tainai', value: 'Tainai' },
  { label: 'Tainai Shi', value: 'Tainai Shi' },
  { label: 'Tainan', value: 'Tainan' },
  { label: 'Tain-L’Hermitage', value: 'Tain-L’Hermitage' },
  { label: 'Taino', value: 'Taino' },
  { label: 'Tainter Lake', value: 'Tainter Lake' },
  { label: 'Taintrux', value: 'Taintrux' },
  { label: 'Taio', value: 'Taio' },
  { label: 'Taió', value: 'Taió' },
  { label: 'Taiobeiras', value: 'Taiobeiras' },
  { label: 'Taipa', value: 'Taipa' },
  { label: 'Taipalsaari', value: 'Taipalsaari' },
  { label: 'Taipana', value: 'Taipana' },
  { label: 'Taipas Do Tocantins', value: 'Taipas Do Tocantins' },
  { label: 'Taipei', value: 'Taipei' },
  { label: 'Taipei City', value: 'Taipei City' },
  { label: 'Taiping', value: 'Taiping' },
  { label: 'Taiping', value: 'Taiping' },
  { label: 'Taipu', value: 'Taipu' },
  { label: 'Tairan Camp', value: 'Tairan Camp' },
  { label: 'Tairan Camp', value: 'Tairan Camp' },
  { label: 'Tairua', value: 'Tairua' },
  { label: 'Taishan', value: 'Taishan' },
  { label: 'Taiskirchen Im Innkreis', value: 'Taiskirchen Im Innkreis' },
  { label: 'Taissy', value: 'Taissy' },
  { label: 'Taitō-Ku', value: 'Taitō-Ku' },
  { label: 'Taitung', value: 'Taitung' },
  { label: 'Taitung City', value: 'Taitung City' },
  { label: 'Taiúva', value: 'Taiúva' },
  { label: 'Taivalkoski', value: 'Taivalkoski' },
  { label: 'Taivassalo', value: 'Taivassalo' },
  { label: 'Taixing', value: 'Taixing' },
  { label: 'Taiynsha', value: 'Taiynsha' },
  { label: 'Taiyuan', value: 'Taiyuan' },
  { label: 'Taizhou', value: 'Taizhou' },
  { label: 'Taizhou', value: 'Taizhou' },
  { label: 'Taizhou', value: 'Taizhou' },
  { label: 'Tajahuerce', value: 'Tajahuerce' },
  { label: 'Tajao', value: 'Tajao' },
  { label: 'Tajar-E Samen', value: 'Tajar-E Samen' },
  { label: 'Tajerouine', value: 'Tajerouine' },
  { label: 'Tajimi', value: 'Tajimi' },
  { label: 'Tajimi-Shi', value: 'Tajimi-Shi' },
  { label: 'Tajiri', value: 'Tajiri' },
  { label: 'Tajpur', value: 'Tajpur' },
  { label: 'Tajrish', value: 'Tajrish' },
  { label: 'Tajueco', value: 'Tajueco' },
  { label: 'Tajumulco', value: 'Tajumulco' },
  { label: 'Tak', value: 'Tak' },
  { label: 'Tak Bai', value: 'Tak Bai' },
  { label: 'Takab', value: 'Takab' },
  { label: 'Takagi', value: 'Takagi' },
  { label: 'Taka-Gun', value: 'Taka-Gun' },
  { label: 'Takahagi', value: 'Takahagi' },
  { label: 'Takahama', value: 'Takahama' },
  { label: 'Takahama-Shi', value: 'Takahama-Shi' },
  { label: 'Takahashi', value: 'Takahashi' },
  { label: 'Takahashi Shi', value: 'Takahashi Shi' },
  { label: 'Takahata', value: 'Takahata' },
  { label: 'Takaishi', value: 'Takaishi' },
  { label: 'Takaishi Shi', value: 'Takaishi Shi' },
  { label: 'Takaka', value: 'Takaka' },
  { label: 'Takamaka', value: 'Takamaka' },
  { label: 'Takamatsu', value: 'Takamatsu' },
  { label: 'Takamatsu Shi', value: 'Takamatsu Shi' },
  { label: 'Takamori', value: 'Takamori' },
  { label: 'Takanabe', value: 'Takanabe' },
  { label: 'Takanini', value: 'Takanini' },
  { label: 'Takanosu', value: 'Takanosu' },
  { label: 'Takaoka', value: 'Takaoka' },
  { label: 'Takaoka Shi', value: 'Takaoka Shi' },
  { label: 'Takarazuka', value: 'Takarazuka' },
  { label: 'Takarazuka Shi', value: 'Takarazuka Shi' },
  { label: 'Takasago Shi', value: 'Takasago Shi' },
  { label: 'Takasaki', value: 'Takasaki' },
  { label: 'Takasaki Shi', value: 'Takasaki Shi' },
  { label: 'Takashima', value: 'Takashima' },
  { label: 'Takashima-Shi', value: 'Takashima-Shi' },
  { label: 'Takatsuki', value: 'Takatsuki' },
  { label: 'Takatsuki Shi', value: 'Takatsuki Shi' },
  { label: 'Takaungu', value: 'Takaungu' },
  { label: 'Takayama', value: 'Takayama' },
  { label: 'Takayama Shi', value: 'Takayama Shi' },
  { label: 'Takdah', value: 'Takdah' },
  { label: 'Takedamachi', value: 'Takedamachi' },
  { label: 'Takefu', value: 'Takefu' },
  { label: 'Takehara', value: 'Takehara' },
  { label: 'Takehara-Shi', value: 'Takehara-Shi' },
  { label: 'Takeley', value: 'Takeley' },
  { label: 'Takelsa', value: 'Takelsa' },
  { label: 'Takeo', value: 'Takeo' },
  { label: 'Takeo Shi', value: 'Takeo Shi' },
  { label: 'Takeochō-Takeo', value: 'Takeochō-Takeo' },
  { label: 'Takestan', value: 'Takestan' },
  { label: 'Taketa-Shi', value: 'Taketa-Shi' },
  { label: 'Taketoyo', value: 'Taketoyo' },
  { label: 'Takhatgarh', value: 'Takhatgarh' },
  { label: 'Takhatpur', value: 'Takhatpur' },
  { label: 'Takhli', value: 'Takhli' },
  { label: 'Takht', value: 'Takht' },
  { label: 'Takhtamukay', value: 'Takhtamukay' },
  { label: 'Takhtamukayskiy Rayon', value: 'Takhtamukayskiy Rayon' },
  { label: 'Takhtamygda', value: 'Takhtamygda' },
  { label: 'Takhtiabad', value: 'Takhtiabad' },
  { label: 'Taki', value: 'Taki' },
  { label: 'Takikawa', value: 'Takikawa' },
  { label: 'Takikawa-Shi', value: 'Takikawa-Shi' },
  { label: 'Takizawa-Shi', value: 'Takizawa-Shi' },
  { label: 'Taklibhan', value: 'Taklibhan' },
  { label: 'Takoma Park', value: 'Takoma Park' },
  { label: 'Takoradi', value: 'Takoradi' },
  { label: 'Taksimo', value: 'Taksimo' },
  { label: 'Taksony', value: 'Taksony' },
  { label: 'Taktaharkány', value: 'Taktaharkány' },
  { label: 'Taktaszada', value: 'Taktaszada' },
  { label: 'Taku Shi', value: 'Taku Shi' },
  { label: 'Takub', value: 'Takub' },
  { label: 'Takub', value: 'Takub' },
  { label: 'Takub', value: 'Takub' },
  { label: 'Takum', value: 'Takum' },
  { label: 'Tal', value: 'Tal' },
  { label: 'Tal I Mun Doc', value: 'Tal I Mun Doc' },
  { label: 'Tal I Mun Doc', value: 'Tal I Mun Doc' },
  { label: 'Tal’Menka', value: 'Tal’Menka' },
  { label: 'Tal’Menskiy Rayon', value: 'Tal’Menskiy Rayon' },
  { label: 'Tala', value: 'Tala' },
  { label: 'Tala', value: 'Tala' },
  { label: 'Talā', value: 'Talā' },
  { label: 'Tála', value: 'Tála' },
  { label: 'Tala Bay', value: 'Tala Bay' },
  { label: 'Tala La', value: 'Tala La' },
  { label: 'Talabaan', value: 'Talabaan' },
  { label: 'Talaban', value: 'Talaban' },
  { label: 'Talachyn', value: 'Talachyn' },
  { label: 'Talacogon', value: 'Talacogon' },
  { label: 'Talacogon', value: 'Talacogon' },
  { label: 'Talaga', value: 'Talaga' },
  { label: 'Talaga', value: 'Talaga' },
  { label: 'Talaga', value: 'Talaga' },
  { label: 'Talagang', value: 'Talagang' },
  { label: 'Talagante', value: 'Talagante' },
  { label: 'Talagutong', value: 'Talagutong' },
  { label: 'Talagutong', value: 'Talagutong' },
  { label: 'Talagutong', value: 'Talagutong' },
  { label: 'Talahib Payap', value: 'Talahib Payap' },
  { label: 'Talahiban I', value: 'Talahiban I' },
  { label: 'Talaibon', value: 'Talaibon' },
  { label: 'Talaigua Nuevo', value: 'Talaigua Nuevo' },
  { label: 'Talaja', value: 'Talaja' },
  { label: 'Talakag', value: 'Talakag' },
  { label: 'Talakan', value: 'Talakan' },
  { label: 'Talalora', value: 'Talalora' },
  { label: 'Talalora', value: 'Talalora' },
  { label: 'Talamanca', value: 'Talamanca' },
  { label: 'Talamanca', value: 'Talamanca' },
  { label: 'Talamanca De Jarama', value: 'Talamanca De Jarama' },
  { label: 'Talamantes', value: 'Talamantes' },
  { label: 'Talamba', value: 'Talamba' },
  { label: 'Talamello', value: 'Talamello' },
  { label: 'Talamona', value: 'Talamona' },
  { label: 'Talana', value: 'Talana' },
  { label: 'Talang', value: 'Talang' },
  { label: 'Talang', value: 'Talang' },
  { label: 'Talanga', value: 'Talanga' },
  { label: 'Talange', value: 'Talange' },
  { label: 'Talangnan', value: 'Talangnan' },
  { label: 'Talant', value: 'Talant' },
  { label: 'Talara', value: 'Talara' },
  { label: 'Talarn', value: 'Talarn' },
  { label: 'Talarrubias', value: 'Talarrubias' },
  { label: 'Talas', value: 'Talas' },
  { label: 'Talas', value: 'Talas' },
  { label: 'Talasea', value: 'Talasea' },
  { label: 'Talashkino', value: 'Talashkino' },
  { label: 'Talasskiy Rayon', value: 'Talasskiy Rayon' },
  { label: 'Talatona', value: 'Talatona' },
  { label: 'Talatuoya', value: 'Talatuoya' },
  { label: 'Talaván', value: 'Talaván' },
  { label: 'Talavera', value: 'Talavera' },
  { label: 'Talavera', value: 'Talavera' },
  { label: 'Talavera', value: 'Talavera' },
  { label: 'Talavera', value: 'Talavera' },
  { label: 'Talavera De La Reina', value: 'Talavera De La Reina' },
  { label: 'Talavera La Real', value: 'Talavera La Real' },
  { label: 'Talaveruela De La Vera', value: 'Talaveruela De La Vera' },
  { label: 'Talawakele', value: 'Talawakele' },
  { label: 'Talaya', value: 'Talaya' },
  { label: 'Talayan', value: 'Talayan' },
  { label: 'Talayan', value: 'Talayan' },
  { label: 'Talayuela', value: 'Talayuela' },
  { label: 'Talayuelas', value: 'Talayuelas' },
  { label: 'Talbahat', value: 'Talbahat' },
  { label: 'Talbid', value: 'Talbid' },
  { label: 'Talbot County', value: 'Talbot County' },
  { label: 'Talbot County', value: 'Talbot County' },
  { label: 'Talbotton', value: 'Talbotton' },
  { label: 'Talca', value: 'Talca' },
  { label: 'Talcahuano', value: 'Talcahuano' },
  { label: 'Talcher', value: 'Talcher' },
  { label: 'Taldan', value: 'Taldan' },
  { label: 'Taldom', value: 'Taldom' },
  { label: 'Taldomskiy Rayon', value: 'Taldomskiy Rayon' },
  { label: 'Taldykorgan', value: 'Taldykorgan' },
  { label: 'Talea', value: 'Talea' },
  { label: 'Taleb Abad', value: 'Taleb Abad' },
  { label: 'Talegaon Dabhade', value: 'Talegaon Dabhade' },
  { label: 'Talegaon Dhamdhere', value: 'Talegaon Dhamdhere' },
  { label: 'Taleggio', value: 'Taleggio' },
  { label: 'Taleh Jerd-E Sofla', value: 'Taleh Jerd-E Sofla' },
  { label: 'Taleigao', value: 'Taleigao' },
  { label: 'Talen', value: 'Talen' },
  { label: 'Talence', value: 'Talence' },
  { label: 'Talensac', value: 'Talensac' },
  { label: 'Talent', value: 'Talent' },
  { label: 'Taleqan', value: 'Taleqan' },
  { label: 'Tales', value: 'Tales' },
  { label: 'Talesh', value: 'Talesh' },
  { label: 'Talgarth', value: 'Talgarth' },
  { label: 'Talghar', value: 'Talghar' },
  { label: 'Talgram', value: 'Talgram' },
  { label: 'Talgua', value: 'Talgua' },
  { label: 'Talhar', value: 'Talhar' },
  { label: 'Talheim', value: 'Talheim' },
  { label: 'Taliaferro County', value: 'Taliaferro County' },
  { label: 'Talibon', value: 'Talibon' },
  { label: 'Táliga', value: 'Táliga' },
  { label: 'Taligaman', value: 'Taligaman' },
  { label: 'Taligaman', value: 'Taligaman' },
  { label: 'Talihina', value: 'Talihina' },
  { label: 'Talikota', value: 'Talikota' },
  { label: 'Taling Chan', value: 'Taling Chan' },
  { label: 'Taliouine', value: 'Taliouine' },
  { label: 'Talipan', value: 'Talipan' },
  { label: 'Talipao', value: 'Talipao' },
  { label: 'Talipao', value: 'Talipao' },
  { label: 'Taliparamba', value: 'Taliparamba' },
  { label: 'Talipaw', value: 'Talipaw' },
  { label: 'Talipaw', value: 'Talipaw' },
  { label: 'Talisay', value: 'Talisay' },
  { label: 'Talisay', value: 'Talisay' },
  { label: 'Talisay', value: 'Talisay' },
  { label: 'Talisay', value: 'Talisay' },
  { label: 'Talisay', value: 'Talisay' },
  { label: 'Talisay', value: 'Talisay' },
  { label: 'Talisay', value: 'Talisay' },
  { label: 'Talisay', value: 'Talisay' },
  { label: 'Talisay', value: 'Talisay' },
  { label: 'Talisayan', value: 'Talisayan' },
  { label: 'Talisayan', value: 'Talisayan' },
  { label: 'Talisayan', value: 'Talisayan' },
  { label: 'Talisayan', value: 'Talisayan' },
  { label: 'Talismã', value: 'Talismã' },
  { label: 'Talismán', value: 'Talismán' },
  { label: 'Talitay', value: 'Talitay' },
  { label: 'Talitsa', value: 'Talitsa' },
  { label: 'Talitsy', value: 'Talitsy' },
  { label: 'Ţalkhā', value: 'Ţalkhā' },
  { label: 'Talkhooncheh', value: 'Talkhooncheh' },
  { label: 'Tálknafjarðarhreppur', value: 'Tálknafjarðarhreppur' },
  { label: 'Tall ‘Afar', value: 'Tall ‘Afar' },
  { label: 'Tall Abyaḑ', value: 'Tall Abyaḑ' },
  { label: 'Tall Rif‘At', value: 'Tall Rif‘At' },
  { label: 'Tall Salḩab', value: 'Tall Salḩab' },
  { label: 'Talla', value: 'Talla' },
  { label: 'Tallaboa', value: 'Tallaboa' },
  { label: 'Tallaboa Alta', value: 'Tallaboa Alta' },
  { label: 'Tallada Dempordà La', value: 'Tallada Dempordà La' },
  { label: 'Talladega', value: 'Talladega' },
  { label: 'Talladega County', value: 'Talladega County' },
  { label: 'Tallaght', value: 'Tallaght' },
  { label: 'Tallahassee', value: 'Tallahassee' },
  { label: 'Tallahatchie County', value: 'Tallahatchie County' },
  { label: 'Tallai', value: 'Tallai' },
  { label: 'Tallangatta', value: 'Tallangatta' },
  { label: 'Tallapoosa', value: 'Tallapoosa' },
  { label: 'Tallapoosa County', value: 'Tallapoosa County' },
  { label: 'Tallard', value: 'Tallard' },
  { label: 'Tallassee', value: 'Tallassee' },
  { label: 'Tallbīsah', value: 'Tallbīsah' },
  { label: 'Tallboda', value: 'Tallboda' },
  { label: 'Tallebudgera', value: 'Tallebudgera' },
  { label: 'Tallebudgera Valley', value: 'Tallebudgera Valley' },
  { label: 'Tallende', value: 'Tallende' },
  { label: 'Tallinn', value: 'Tallinn' },
  { label: 'Tallkalakh', value: 'Tallkalakh' },
  { label: 'Tallkayf', value: 'Tallkayf' },
  { label: 'Tallmadge', value: 'Tallmadge' },
  { label: 'Talloires', value: 'Talloires' },
  { label: 'Tallulah', value: 'Tallulah' },
  { label: 'Tállya', value: 'Tállya' },
  { label: 'Tălmăcel', value: 'Tălmăcel' },
  { label: 'Tălmaciu', value: 'Tălmaciu' },
  { label: 'Talmage', value: 'Talmage' },
  { label: 'Talmas', value: 'Talmas' },
  { label: 'Talmassons', value: 'Talmassons' },
  { label: 'Talnakh', value: 'Talnakh' },
  { label: 'Talne', value: 'Talne' },
  { label: 'Taloc', value: 'Taloc' },
  { label: 'Taloda', value: 'Taloda' },
  { label: 'Talode', value: 'Talode' },
  { label: 'Talodi', value: 'Talodi' },
  { label: 'Taloga', value: 'Taloga' },
  { label: 'Talokgañgan', value: 'Talokgañgan' },
  { label: 'Talol', value: 'Talol' },
  { label: 'Talomo', value: 'Talomo' },
  { label: 'Talomo', value: 'Talomo' },
  { label: 'Talomo', value: 'Talomo' },
  { label: 'Talon', value: 'Talon' },
  { label: 'Taloqan', value: 'Taloqan' },
  { label: 'Talospatang', value: 'Talospatang' },
  { label: 'Talospatang', value: 'Talospatang' },
  { label: 'Talovaya', value: 'Talovaya' },
  { label: 'Talovyy', value: 'Talovyy' },
  { label: 'Taloy', value: 'Taloy' },
  { label: 'Taloy', value: 'Taloy' },
  { label: 'Taloy', value: 'Taloy' },
  { label: 'Taloy', value: 'Taloy' },
  { label: 'Talpa De Allende', value: 'Talpa De Allende' },
  { label: 'Talpa-Ogrăzile', value: 'Talpa-Ogrăzile' },
  { label: 'Tălpaș', value: 'Tălpaș' },
  { label: 'Talpa-Trivalea', value: 'Talpa-Trivalea' },
  { label: 'Talpe', value: 'Talpe' },
  { label: 'Tălpigi', value: 'Tălpigi' },
  { label: 'Talponada', value: 'Talponada' },
  { label: 'Talpoș', value: 'Talpoș' },
  { label: 'Talshik', value: 'Talshik' },
  { label: 'Talsi', value: 'Talsi' },
  { label: 'Taltal', value: 'Taltal' },
  { label: 'Taltal', value: 'Taltal' },
  { label: 'Taltal', value: 'Taltal' },
  { label: 'Talty', value: 'Talty' },
  { label: 'Talubatib', value: 'Talubatib' },
  { label: 'Talugtug', value: 'Talugtug' },
  { label: 'Talugtug', value: 'Talugtug' },
  { label: 'Taluksangay', value: 'Taluksangay' },
  { label: 'Talusan', value: 'Talusan' },
  { label: 'Taluya', value: 'Taluya' },
  { label: 'Taluya', value: 'Taluya' },
  { label: 'Taluyers', value: 'Taluyers' },
  { label: 'Talveila', value: 'Talveila' },
  { label: 'Talvern', value: 'Talvern' },
  { label: 'Talwandi Bhai', value: 'Talwandi Bhai' },
  { label: 'Talwara', value: 'Talwara' },
  { label: 'Tal-Y-Bont', value: 'Tal-Y-Bont' },
  { label: 'Talysarn', value: 'Talysarn' },
  { label: 'Talzemt', value: 'Talzemt' },
  { label: 'Tam Kỳ', value: 'Tam Kỳ' },
  { label: 'Tama', value: 'Tama' },
  { label: 'Tama County', value: 'Tama County' },
  { label: 'Tămădău Mare', value: 'Tămădău Mare' },
  { label: 'Tamaghzah', value: 'Tamaghzah' },
  { label: 'Tamahú', value: 'Tamahú' },
  { label: 'Tamai', value: 'Tamai' },
  { label: 'Tămaia', value: 'Tămaia' },
  { label: 'Tamajón', value: 'Tamajón' },
  { label: 'Tamaki', value: 'Tamaki' },
  { label: 'Tamala', value: 'Tamala' },
  { label: 'Tamalameque', value: 'Tamalameque' },
  { label: 'Tamale', value: 'Tamale' },
  { label: 'Tamalín', value: 'Tamalín' },
  { label: 'Tamalous', value: 'Tamalous' },
  { label: 'Tamalpais Valley', value: 'Tamalpais Valley' },
  { label: 'Tamalpais-Homestead Valley', value: 'Tamalpais-Homestead Valley' },
  { label: 'Tamames', value: 'Tamames' },
  { label: 'Tamamura', value: 'Tamamura' },
  { label: 'Tamán', value: 'Tamán' },
  { label: 'Taman Rajawali', value: 'Taman Rajawali' },
  { label: 'Taman Senai', value: 'Taman Senai' },
  { label: 'Taman’', value: 'Taman’' },
  { label: 'Tamana', value: 'Tamana' },
  { label: 'Tamana', value: 'Tamana' },
  { label: 'Tamana Shi', value: 'Tamana Shi' },
  { label: 'Tamanar', value: 'Tamanar' },
  { label: 'Tamandaré', value: 'Tamandaré' },
  { label: 'Tamándaro', value: 'Tamándaro' },
  { label: 'Tamanhos', value: 'Tamanhos' },
  { label: 'Tamano', value: 'Tamano' },
  { label: 'Tamano Shi', value: 'Tamano Shi' },
  { label: 'Tamanrasset', value: 'Tamanrasset' },
  { label: 'Tamápatz', value: 'Tamápatz' },
  { label: 'Tamaqua', value: 'Tamaqua' },
  { label: 'Támara', value: 'Támara' },
  { label: 'Támara', value: 'Támara' },
  { label: 'Támara De Campos', value: 'Támara De Campos' },
  { label: 'Tamarac', value: 'Tamarac' },
  { label: 'Tamarack', value: 'Tamarack' },
  { label: 'Tamarama', value: 'Tamarama' },
  { label: 'Tamarana', value: 'Tamarana' },
  { label: 'Tamarin', value: 'Tamarin' },
  { label: 'Tamarit De Llitera / Tamarite De Litera', value: 'Tamarit De Llitera / Tamarite De Litera' },
  { label: 'Tamarite De Litera', value: 'Tamarite De Litera' },
  { label: 'Tamariz De Campos', value: 'Tamariz De Campos' },
  { label: 'Tamarón', value: 'Tamarón' },
  { label: 'Tamasane', value: 'Tamasane' },
  { label: 'Tămașda', value: 'Tămașda' },
  { label: 'Tămăşeni', value: 'Tămăşeni' },
  { label: 'Tămășești', value: 'Tămășești' },
  { label: 'Tămășeu', value: 'Tămășeu' },
  { label: 'Tama-Shi', value: 'Tama-Shi' },
  { label: 'Tamaşi', value: 'Tamaşi' },
  { label: 'Tamași', value: 'Tamași' },
  { label: 'Tamási', value: 'Tamási' },
  { label: 'Tamási Járás', value: 'Tamási Járás' },
  { label: 'Tamasopo', value: 'Tamasopo' },
  { label: 'Tamatam Municipality', value: 'Tamatam Municipality' },
  { label: 'Tamaulipas', value: 'Tamaulipas' },
  { label: 'Tamayo', value: 'Tamayo' },
  { label: 'Tamayo', value: 'Tamayo' },
  { label: 'Tamayo', value: 'Tamayo' },
  { label: 'Tamayong', value: 'Tamayong' },
  { label: 'Tamayong', value: 'Tamayong' },
  { label: 'Tamayong', value: 'Tamayong' },
  { label: 'Tamazola', value: 'Tamazola' },
  { label: 'Tamazula', value: 'Tamazula' },
  { label: 'Tamazula', value: 'Tamazula' },
  { label: 'Tamazula', value: 'Tamazula' },
  { label: 'Tamazula De Gordiano', value: 'Tamazula De Gordiano' },
  { label: 'Tamazula De Victoria', value: 'Tamazula De Victoria' },
  { label: 'Tamazulápam Del Espíritu Santo', value: 'Tamazulápam Del Espíritu Santo' },
  { label: 'Tamazulapam Villa Del Progreso', value: 'Tamazulapam Villa Del Progreso' },
  { label: 'Tamazulita Jalisco', value: 'Tamazulita Jalisco' },
  { label: 'Tamazunchale', value: 'Tamazunchale' },
  { label: 'Tambac', value: 'Tambac' },
  { label: 'Tambaca', value: 'Tambaca' },
  { label: 'Tambach-Dietharz', value: 'Tambach-Dietharz' },
  { label: 'Tambacounda', value: 'Tambacounda' },
  { label: 'Tambacounda Department', value: 'Tambacounda Department' },
  { label: 'Tambak', value: 'Tambak' },
  { label: 'Tambak', value: 'Tambak' },
  { label: 'Tambalan', value: 'Tambalan' },
  { label: 'Tambalisa', value: 'Tambalisa' },
  { label: 'Tambaram', value: 'Tambaram' },
  { label: 'Tambaú', value: 'Tambaú' },
  { label: 'Tambilil', value: 'Tambilil' },
  { label: 'Tambilil', value: 'Tambilil' },
  { label: 'Tambla', value: 'Tambla' },
  { label: 'Tambo', value: 'Tambo' },
  { label: 'Tambo', value: 'Tambo' },
  { label: 'Tambo', value: 'Tambo' },
  { label: 'Tambo', value: 'Tambo' },
  { label: 'Tambo', value: 'Tambo' },
  { label: 'Tambo', value: 'Tambo' },
  { label: 'Tambo Grande', value: 'Tambo Grande' },
  { label: 'Tamboara', value: 'Tamboara' },
  { label: 'Tâmboeşti', value: 'Tâmboeşti' },
  { label: 'Tambong', value: 'Tambong' },
  { label: 'Tambongon', value: 'Tambongon' },
  { label: 'Tambopata', value: 'Tambopata' },
  { label: 'Tamboril', value: 'Tamboril' },
  { label: 'Tamboril', value: 'Tamboril' },
  { label: 'Tamboril Do Piauí', value: 'Tamboril Do Piauí' },
  { label: 'Tamborine', value: 'Tamborine' },
  { label: 'Tamborine Mountain', value: 'Tamborine Mountain' },
  { label: 'Tambov', value: 'Tambov' },
  { label: 'Tambovka', value: 'Tambovka' },
  { label: 'Tambovka', value: 'Tambovka' },
  { label: 'Tambovskiy Rayon', value: 'Tambovskiy Rayon' },
  { label: 'Tambre', value: 'Tambre' },
  { label: 'Tambul Nebilyer', value: 'Tambul Nebilyer' },
  { label: 'Tambulig', value: 'Tambulig' },
  { label: 'Tambuwal', value: 'Tambuwal' },
  { label: 'Tamcuime', value: 'Tamcuime' },
  { label: 'Tame', value: 'Tame' },
  { label: 'Tamellaht', value: 'Tamellaht' },
  { label: 'Tamenglong', value: 'Tamenglong' },
  { label: 'Tamerna Djedida', value: 'Tamerna Djedida' },
  { label: 'Támesis', value: 'Támesis' },
  { label: 'Tamiahua', value: 'Tamiahua' },
  { label: 'Tamiami', value: 'Tamiami' },
  { label: 'Taminango', value: 'Taminango' },
  { label: 'Tamins', value: 'Tamins' },
  { label: 'Tamisan', value: 'Tamisan' },
  { label: 'Tamisan', value: 'Tamisan' },
  { label: 'Tamisan', value: 'Tamisan' },
  { label: 'Tamiso', value: 'Tamiso' },
  { label: 'Ţāmiyah', value: 'Ţāmiyah' },
  { label: 'Tamlang', value: 'Tamlang' },
  { label: 'Tamluk', value: 'Tamluk' },
  { label: 'Tamm', value: 'Tamm' },
  { label: 'Tammela', value: 'Tammela' },
  { label: 'Tammin', value: 'Tammin' },
  { label: 'Tâmna', value: 'Tâmna' },
  { label: 'Tamnag', value: 'Tamnag' },
  { label: 'Tamnag', value: 'Tamnag' },
  { label: 'Tamontaka', value: 'Tamontaka' },
  { label: 'Tamoowoug', value: 'Tamoowoug' },
  { label: 'Tamorong', value: 'Tamorong' },
  { label: 'Tamorong', value: 'Tamorong' },
  { label: 'Tamorot', value: 'Tamorot' },
  { label: 'Tampa', value: 'Tampa' },
  { label: 'Tâmpa', value: 'Tâmpa' },
  { label: 'Tampacán', value: 'Tampacán' },
  { label: 'Tampakan', value: 'Tampakan' },
  { label: 'Tampakan', value: 'Tampakan' },
  { label: 'Tampakan', value: 'Tampakan' },
  { label: 'Tampakan', value: 'Tampakan' },
  { label: 'Tamparan', value: 'Tamparan' },
  { label: 'Tamparan', value: 'Tamparan' },
  { label: 'Tampate', value: 'Tampate' },
  { label: 'Tampayan', value: 'Tampayan' },
  { label: 'Tampemoche', value: 'Tampemoche' },
  { label: 'Tampere', value: 'Tampere' },
  { label: 'Tampico', value: 'Tampico' },
  { label: 'Tampico Alto', value: 'Tampico Alto' },
  { label: 'Tampilisan', value: 'Tampilisan' },
  { label: 'Tampin', value: 'Tampin' },
  { label: 'Tampines', value: 'Tampines' },
  { label: 'Tampiquito', value: 'Tampiquito' },
  { label: 'Tampocon', value: 'Tampocon' },
  { label: 'Tamra', value: 'Tamra' },
  { label: 'Tamri', value: 'Tamri' },
  { label: 'Tamsalu', value: 'Tamsalu' },
  { label: 'Tamshiyacu', value: 'Tamshiyacu' },
  { label: 'Tamsweg', value: 'Tamsweg' },
  { label: 'Tamugan', value: 'Tamugan' },
  { label: 'Tamugan', value: 'Tamugan' },
  { label: 'Tamugan', value: 'Tamugan' },
  { label: 'Tamuín', value: 'Tamuín' },
  { label: 'Tamulte De Las Sabanas', value: 'Tamulte De Las Sabanas' },
  { label: 'Tamura', value: 'Tamura' },
  { label: 'Tamura-Shi', value: 'Tamura-Shi' },
  { label: 'Tamurejo', value: 'Tamurejo' },
  { label: 'Tamuzan', value: 'Tamuzan' },
  { label: 'Tamworth', value: 'Tamworth' },
  { label: 'Tamworth', value: 'Tamworth' },
  { label: 'Tamworth', value: 'Tamworth' },
  { label: 'Tamworth Municipality', value: 'Tamworth Municipality' },
  { label: 'Tân An', value: 'Tân An' },
  { label: 'Tana', value: 'Tana' },
  { label: 'Tanabe', value: 'Tanabe' },
  { label: 'Tanabe-Shi', value: 'Tanabe-Shi' },
  { label: 'Tanabi', value: 'Tanabi' },
  { label: 'Tanaco', value: 'Tanaco' },
  { label: 'Tanacu', value: 'Tanacu' },
  { label: 'Tanah Merah', value: 'Tanah Merah' },
  { label: 'Tanah Rata', value: 'Tanah Rata' },
  { label: 'Tanahun ', value: 'Tanahun ' },
  { label: 'Tanaina', value: 'Tanaina' },
  { label: 'Tanais', value: 'Tanais' },
  { label: 'Tanakpur', value: 'Tanakpur' },
  { label: 'Tanalt', value: 'Tanalt' },
  { label: 'Tanamacoyan', value: 'Tanamacoyan' },
  { label: 'Tananger', value: 'Tananger' },
  { label: 'Tanaquillo', value: 'Tanaquillo' },
  { label: 'Tanashichō', value: 'Tanashichō' },
  { label: 'Tănăsoaia', value: 'Tănăsoaia' },
  { label: 'Tanauan', value: 'Tanauan' },
  { label: 'Tanauan', value: 'Tanauan' },
  { label: 'Tanauan', value: 'Tanauan' },
  { label: 'Tanawha', value: 'Tanawha' },
  { label: 'Tanay', value: 'Tanay' },
  { label: 'Tanay', value: 'Tanay' },
  { label: 'Tanba-Shi', value: 'Tanba-Shi' },
  { label: 'Tanbei', value: 'Tanbei' },
  { label: 'Tancacha', value: 'Tancacha' },
  { label: 'Tancanhuitz', value: 'Tancanhuitz' },
  { label: 'Tancarville', value: 'Tancarville' },
  { label: 'Tancazahuela', value: 'Tancazahuela' },
  { label: 'Tancítaro', value: 'Tancítaro' },
  { label: 'Tancoco', value: 'Tancoco' },
  { label: 'Tanda', value: 'Tanda' },
  { label: 'Tanda', value: 'Tanda' },
  { label: 'Tanda', value: 'Tanda' },
  { label: 'Tandag', value: 'Tandag' },
  { label: 'Tandag', value: 'Tandag' },
  { label: 'Tandahimba', value: 'Tandahimba' },
  { label: 'Tandaltī', value: 'Tandaltī' },
  { label: 'Ţăndărei', value: 'Ţăndărei' },
  { label: 'Tandayag', value: 'Tandayag' },
  { label: 'Tandel', value: 'Tandel' },
  { label: 'Tandlianwala', value: 'Tandlianwala' },
  { label: 'Tando Adam', value: 'Tando Adam' },
  { label: 'Tando Allahyar', value: 'Tando Allahyar' },
  { label: 'Tando Bago', value: 'Tando Bago' },
  { label: 'Tando Jam', value: 'Tando Jam' },
  { label: 'Tando Mitha Khan', value: 'Tando Mitha Khan' },
  { label: 'Tando Muhammad Khan', value: 'Tando Muhammad Khan' },
  { label: 'Tandoc', value: 'Tandoc' },
  { label: 'Tandoc', value: 'Tandoc' },
  { label: 'Tandragee', value: 'Tandragee' },
  { label: 'Tandubas', value: 'Tandubas' },
  { label: 'Tandur', value: 'Tandur' },
  { label: 'Tandzumadz', value: 'Tandzumadz' },
  { label: 'Tandzut', value: 'Tandzut' },
  { label: 'Taneto', value: 'Taneto' },
  { label: 'Tanetze De Zaragoza', value: 'Tanetze De Zaragoza' },
  { label: 'Taney County', value: 'Taney County' },
  { label: 'Taneytown', value: 'Taneytown' },
  { label: 'Tanfield', value: 'Tanfield' },
  { label: 'Tang Eram', value: 'Tang Eram' },
  { label: 'Tang Valley', value: 'Tang Valley' },
  { label: 'Tanga', value: 'Tanga' },
  { label: 'Tangail ', value: 'Tangail ' },
  { label: 'Tangal', value: 'Tangal' },
  { label: 'Tangalan', value: 'Tangalan' },
  { label: 'Tangalle', value: 'Tangalle' },
  { label: 'Tangancícuaro', value: 'Tangancícuaro' },
  { label: 'Tangancícuaro De Arista', value: 'Tangancícuaro De Arista' },
  { label: 'Tanganhuato', value: 'Tanganhuato' },
  { label: 'Tânganu', value: 'Tânganu' },
  { label: 'Tangará', value: 'Tangará' },
  { label: 'Tangará', value: 'Tangará' },
  { label: 'Tangará Da Serra', value: 'Tangará Da Serra' },
  { label: 'Tangelo Park', value: 'Tangelo Park' },
  { label: 'Tangent', value: 'Tangent' },
  { label: 'Tangerang', value: 'Tangerang' },
  { label: 'Tanger-Assilah', value: 'Tanger-Assilah' },
  { label: 'Tangerhütte', value: 'Tangerhütte' },
  { label: 'Tangerine', value: 'Tangerine' },
  { label: 'Tangermünde', value: 'Tangermünde' },
  { label: 'Tangestan', value: 'Tangestan' },
  { label: 'Tanggul', value: 'Tanggul' },
  { label: 'Tanggulangin', value: 'Tanggulangin' },
  { label: 'Tangi', value: 'Tangi' },
  { label: 'Tangier', value: 'Tangier' },
  { label: 'Tangipahoa Parish', value: 'Tangipahoa Parish' },
  { label: 'Tangjiafang', value: 'Tangjiafang' },
  { label: 'Tangjiazhuang', value: 'Tangjiazhuang' },
  { label: 'Tangjin', value: 'Tangjin' },
  { label: 'Tangkak', value: 'Tangkak' },
  { label: 'Tangke', value: 'Tangke' },
  { label: 'Tangkou', value: 'Tangkou' },
  { label: 'Tangle River', value: 'Tangle River' },
  { label: 'Tanglewilde', value: 'Tanglewilde' },
  { label: 'Tanglewilde-Thompson Place', value: 'Tanglewilde-Thompson Place' },
  { label: 'Tangmere', value: 'Tangmere' },
  { label: 'Tangnan', value: 'Tangnan' },
  { label: 'Tañgo', value: 'Tañgo' },
  { label: 'Tañgo', value: 'Tañgo' },
  { label: 'Tangping', value: 'Tangping' },
  { label: 'Tangshan', value: 'Tangshan' },
  { label: 'Tangshan Shi', value: 'Tangshan Shi' },
  { label: 'Tangstedt', value: 'Tangstedt' },
  { label: 'Tangua', value: 'Tangua' },
  { label: 'Tanguá', value: 'Tanguá' },
  { label: 'Tangub', value: 'Tangub' },
  { label: 'Tanguieta', value: 'Tanguieta' },
  { label: 'Tangwani', value: 'Tangwani' },
  { label: 'Tangzhai', value: 'Tangzhai' },
  { label: 'Tanhaçu', value: 'Tanhaçu' },
  { label: 'Tanhuato De Guerrero', value: 'Tanhuato De Guerrero' },
  { label: 'Tanhuixco', value: 'Tanhuixco' },
  { label: 'Taniche', value: 'Taniche' },
  { label: 'Tanilba Bay', value: 'Tanilba Bay' },
  { label: 'Taninges', value: 'Taninges' },
  { label: 'Taniperla', value: 'Taniperla' },
  { label: 'Tanjay', value: 'Tanjay' },
  { label: 'Tanjong Pagar', value: 'Tanjong Pagar' },
  { label: 'Tanjore', value: 'Tanjore' },
  { label: 'Tanjung Pandan', value: 'Tanjung Pandan' },
  { label: 'Tanjung Pinang', value: 'Tanjung Pinang' },
  { label: 'Tanjung Selor', value: 'Tanjung Selor' },
  { label: 'Tanjung Sepat', value: 'Tanjung Sepat' },
  { label: 'Tanjung Tokong', value: 'Tanjung Tokong' },
  { label: 'Tanjungagung', value: 'Tanjungagung' },
  { label: 'Tanjungbalai', value: 'Tanjungbalai' },
  { label: 'Tanjungtiram', value: 'Tanjungtiram' },
  { label: 'Tank', value: 'Tank' },
  { label: 'Tankaman', value: 'Tankaman' },
  { label: 'Tankara', value: 'Tankara' },
  { label: 'Tankerton', value: 'Tankerton' },
  { label: 'Tankhoy', value: 'Tankhoy' },
  { label: 'Tankovoye', value: 'Tankovoye' },
  { label: 'Tankuché', value: 'Tankuché' },
  { label: 'Tanlad', value: 'Tanlad' },
  { label: 'Tanlad', value: 'Tanlad' },
  { label: 'Tanlad', value: 'Tanlad' },
  { label: 'Tanlay', value: 'Tanlay' },
  { label: 'Tann', value: 'Tann' },
  { label: 'Tann', value: 'Tann' },
  { label: 'Tann', value: 'Tann' },
  { label: 'Tann / Tann (Dorfkern)', value: 'Tann / Tann (Dorfkern)' },
  { label: 'Tanna', value: 'Tanna' },
  { label: 'Tannenberg', value: 'Tannenberg' },
  { label: 'Tannenbergsthal', value: 'Tannenbergsthal' },
  { label: 'Tanner', value: 'Tanner' },
  { label: 'Tanneron', value: 'Tanneron' },
  { label: 'Tannersville', value: 'Tannersville' },
  { label: 'Tännesberg', value: 'Tännesberg' },
  { label: 'Tannhausen', value: 'Tannhausen' },
  { label: 'Tannheim', value: 'Tannheim' },
  { label: 'Tannheim', value: 'Tannheim' },
  { label: 'Tannum Sands', value: 'Tannum Sands' },
  { label: 'Tano', value: 'Tano' },
  { label: 'Tano North', value: 'Tano North' },
  { label: 'Tano South', value: 'Tano South' },
  { label: 'Tanolong', value: 'Tanolong' },
  { label: 'Tanolong', value: 'Tanolong' },
  { label: 'Tanout', value: 'Tanout' },
  { label: 'Tanque', value: 'Tanque' },
  { label: 'Tanque Darca', value: 'Tanque Darca' },
  { label: 'Tanque Do Piauí', value: 'Tanque Do Piauí' },
  { label: 'Tanque El', value: 'Tanque El' },
  { label: 'Tanque Novo', value: 'Tanque Novo' },
  { label: 'Tanque Verde', value: 'Tanque Verde' },
  { label: 'Tanques', value: 'Tanques' },
  { label: 'Tanquián De Escobedo', value: 'Tanquián De Escobedo' },
  { label: 'Tanquinho', value: 'Tanquinho' },
  { label: 'Tanrake Village', value: 'Tanrake Village' },
  { label: 'Tansa', value: 'Tansa' },
  { label: 'Tansley', value: 'Tansley' },
  { label: 'Tan-Tan', value: 'Tan-Tan' },
  { label: 'Tantangan', value: 'Tantangan' },
  { label: 'Tantangan', value: 'Tantangan' },
  { label: 'Ţânţăreni', value: 'Ţânţăreni' },
  { label: 'Tântava', value: 'Tântava' },
  { label: 'Tantima', value: 'Tantima' },
  { label: 'Tantou', value: 'Tantou' },
  { label: 'Tantoyuca', value: 'Tantoyuca' },
  { label: 'Tanudan', value: 'Tanudan' },
  { label: 'Tanudan', value: 'Tanudan' },
  { label: 'Tanuku', value: 'Tanuku' },
  { label: 'Tanuma', value: 'Tanuma' },
  { label: 'Tanūmah', value: 'Tanūmah' },
  { label: 'Tanumshede', value: 'Tanumshede' },
  { label: 'Tanunda', value: 'Tanunda' },
  { label: 'Tanushimarumachi-Toyoki', value: 'Tanushimarumachi-Toyoki' },
  { label: 'Tanute', value: 'Tanute' },
  { label: 'Tanvald', value: 'Tanvald' },
  { label: 'Tanwan', value: 'Tanwan' },
  { label: 'Tanza', value: 'Tanza' },
  { label: 'Tanza', value: 'Tanza' },
  { label: 'Tanza', value: 'Tanza' },
  { label: 'Tanzybey', value: 'Tanzybey' },
  { label: 'Taormina', value: 'Taormina' },
  { label: 'Taoru', value: 'Taoru' },
  { label: 'Taos', value: 'Taos' },
  { label: 'Taos', value: 'Taos' },
  { label: 'Taos County', value: 'Taos County' },
  { label: 'Taos Pueblo', value: 'Taos Pueblo' },
  { label: 'Taouloukoult', value: 'Taouloukoult' },
  { label: 'Taounate', value: 'Taounate' },
  { label: 'Taourirt', value: 'Taourirt' },
  { label: 'Taoyuan', value: 'Taoyuan' },
  { label: 'Taoyuan City', value: 'Taoyuan City' },
  { label: 'Taozhuang', value: 'Taozhuang' },
  { label: 'Tapa', value: 'Tapa' },
  { label: 'Tapa Vald', value: 'Tapa Vald' },
  { label: 'Tapachula', value: 'Tapachula' },
  { label: 'Tapah Road', value: 'Tapah Road' },
  { label: 'Tapalapa', value: 'Tapalapa' },
  { label: 'Tapalapan', value: 'Tapalapan' },
  { label: 'Tapalpa', value: 'Tapalpa' },
  { label: 'Tapanalá', value: 'Tapanalá' },
  { label: 'Taparuba', value: 'Taparuba' },
  { label: 'Tapas', value: 'Tapas' },
  { label: 'Tapauá', value: 'Tapauá' },
  { label: 'Tapayan', value: 'Tapayan' },
  { label: 'Tapayan', value: 'Tapayan' },
  { label: 'Tapejara', value: 'Tapejara' },
  { label: 'Tapejara', value: 'Tapejara' },
  { label: 'Tapel', value: 'Tapel' },
  { label: 'Tapel', value: 'Tapel' },
  { label: 'Tapera', value: 'Tapera' },
  { label: 'Tapera', value: 'Tapera' },
  { label: 'Taperoá', value: 'Taperoá' },
  { label: 'Taperoá', value: 'Taperoá' },
  { label: 'Taperoo', value: 'Taperoo' },
  { label: 'Tapes', value: 'Tapes' },
  { label: 'Tapfheim', value: 'Tapfheim' },
  { label: 'Taphan Hin', value: 'Taphan Hin' },
  { label: 'Tapi', value: 'Tapi' },
  { label: 'Tapia', value: 'Tapia' },
  { label: 'Tapia', value: 'Tapia' },
  { label: 'Tapia', value: 'Tapia' },
  { label: 'Tapia De Casariego', value: 'Tapia De Casariego' },
  { label: 'Tapias De Santa Cruz (Pedro Ruiz González)', value: 'Tapias De Santa Cruz (Pedro Ruiz González)' },
  { label: 'Tapijulapa', value: 'Tapijulapa' },
  { label: 'Tapikan', value: 'Tapikan' },
  { label: 'Tapikan', value: 'Tapikan' },
  { label: 'Tapilon', value: 'Tapilon' },
  { label: 'Tapilula', value: 'Tapilula' },
  { label: 'Tápióbicske', value: 'Tápióbicske' },
  { label: 'Tápiógyörgye', value: 'Tápiógyörgye' },
  { label: 'Tapioles', value: 'Tapioles' },
  { label: 'Tapion', value: 'Tapion' },
  { label: 'Tápióság', value: 'Tápióság' },
  { label: 'Tápiószecső', value: 'Tápiószecső' },
  { label: 'Tápiószele', value: 'Tápiószele' },
  { label: 'Tápiószentmárton', value: 'Tápiószentmárton' },
  { label: 'Tápiószőlős', value: 'Tápiószőlős' },
  { label: 'Tapira', value: 'Tapira' },
  { label: 'Tapira', value: 'Tapira' },
  { label: 'Tapiraí', value: 'Tapiraí' },
  { label: 'Tapiraí', value: 'Tapiraí' },
  { label: 'Tapiramutá', value: 'Tapiramutá' },
  { label: 'Tapiratiba', value: 'Tapiratiba' },
  { label: 'Táplánszentkereszt', value: 'Táplánszentkereszt' },
  { label: 'Taplejung', value: 'Taplejung' },
  { label: 'Tapolca', value: 'Tapolca' },
  { label: 'Tapolcai Járás', value: 'Tapolcai Járás' },
  { label: 'Tapon', value: 'Tapon' },
  { label: 'Taponnat-Fleurignac', value: 'Taponnat-Fleurignac' },
  { label: 'Tapotzingo', value: 'Tapotzingo' },
  { label: 'Tappahannock', value: 'Tappahannock' },
  { label: 'Tappan', value: 'Tappan' },
  { label: 'Tappeh Yazdan', value: 'Tappeh Yazdan' },
  { label: 'Tappeh-Ye Dibi', value: 'Tappeh-Ye Dibi' },
  { label: 'Tappenbeck', value: 'Tappenbeck' },
  { label: 'Tapping', value: 'Tapping' },
  { label: 'Tapul', value: 'Tapul' },
  { label: 'Tapurah', value: 'Tapurah' },
  { label: 'Taqanak', value: 'Taqanak' },
  { label: 'Taquara', value: 'Taquara' },
  { label: 'Taquaraçu De Minas', value: 'Taquaraçu De Minas' },
  { label: 'Taquaral', value: 'Taquaral' },
  { label: 'Taquaral De Goiás', value: 'Taquaral De Goiás' },
  { label: 'Taquarana', value: 'Taquarana' },
  { label: 'Taquari', value: 'Taquari' },
  { label: 'Taquaritinga', value: 'Taquaritinga' },
  { label: 'Taquaritinga Do Norte', value: 'Taquaritinga Do Norte' },
  { label: 'Taquarituba', value: 'Taquarituba' },
  { label: 'Taquarivaí', value: 'Taquarivaí' },
  { label: 'Taquaruçu Do Sul', value: 'Taquaruçu Do Sul' },
  { label: 'Taquarussu', value: 'Taquarussu' },
  { label: 'Taquile', value: 'Taquile' },
  { label: 'Tar', value: 'Tar' },
  { label: 'Tar', value: 'Tar' },
  { label: 'Tara', value: 'Tara' },
  { label: 'Tara', value: 'Tara' },
  { label: 'Tara', value: 'Tara' },
  { label: 'Tara Hills', value: 'Tara Hills' },
  { label: 'Tarabai', value: 'Tarabai' },
  { label: 'Tarabha', value: 'Tarabha' },
  { label: 'Tarabuco', value: 'Tarabuco' },
  { label: 'Taraclia', value: 'Taraclia' },
  { label: 'Taradale', value: 'Taradale' },
  { label: 'Taradeau', value: 'Taradeau' },
  { label: 'Taradell', value: 'Taradell' },
  { label: 'Taragual', value: 'Taragual' },
  { label: 'Taragudo', value: 'Taragudo' },
  { label: 'Taraira', value: 'Taraira' },
  { label: 'Taraka', value: 'Taraka' },
  { label: 'Tarakan', value: 'Tarakan' },
  { label: 'Tarakaniv', value: 'Tarakaniv' },
  { label: 'Tarakeswar', value: 'Tarakeswar' },
  { label: 'Taraklı', value: 'Taraklı' },
  { label: 'Taramangalam', value: 'Taramangalam' },
  { label: 'Taramundi', value: 'Taramundi' },
  { label: 'Tarana', value: 'Tarana' },
  { label: 'Taranagar', value: 'Taranagar' },
  { label: 'Tarancón', value: 'Tarancón' },
  { label: 'Tarandacuao', value: 'Tarandacuao' },
  { label: 'Taranganba', value: 'Taranganba' },
  { label: 'Tarangnan', value: 'Tarangnan' },
  { label: 'Tarangnan', value: 'Tarangnan' },
  { label: 'Tarano', value: 'Tarano' },
  { label: 'Taranta Peligna', value: 'Taranta Peligna' },
  { label: 'Tarantasca', value: 'Tarantasca' },
  { label: 'Taranto', value: 'Taranto' },
  { label: 'Tarapacá', value: 'Tarapacá' },
  { label: 'Tarapoto', value: 'Tarapoto' },
  { label: 'Tarapur', value: 'Tarapur' },
  { label: 'Taraqayah', value: 'Taraqayah' },
  { label: 'Tarare', value: 'Tarare' },
  { label: 'Tarariras', value: 'Tarariras' },
  { label: 'Taraš', value: 'Taraš' },
  { label: 'Taraschanka', value: 'Taraschanka' },
  { label: 'Tarascon', value: 'Tarascon' },
  { label: 'Tarascon-Sur-Ariège', value: 'Tarascon-Sur-Ariège' },
  { label: 'Tarashcha', value: 'Tarashcha' },
  { label: 'Tarasovka', value: 'Tarasovka' },
  { label: 'Tarasovskiy', value: 'Tarasovskiy' },
  { label: 'Tarata', value: 'Tarata' },
  { label: 'Tarata', value: 'Tarata' },
  { label: 'Tarauacá', value: 'Tarauacá' },
  { label: 'Taravilla', value: 'Taravilla' },
  { label: 'Tarawa', value: 'Tarawa' },
  { label: 'Taraz', value: 'Taraz' },
  { label: 'Tarazá', value: 'Tarazá' },
  { label: 'Tarazona', value: 'Tarazona' },
  { label: 'Tarazona De Guareña', value: 'Tarazona De Guareña' },
  { label: 'Tarazona De La Mancha', value: 'Tarazona De La Mancha' },
  { label: 'Tarbagatay', value: 'Tarbagatay' },
  { label: 'Tarbagatay', value: 'Tarbagatay' },
  { label: 'Tàrbena', value: 'Tàrbena' },
  { label: 'Tarbert', value: 'Tarbert' },
  { label: 'Tarbes', value: 'Tarbes' },
  { label: 'Tarbolton', value: 'Tarbolton' },
  { label: 'Tarboro', value: 'Tarboro' },
  { label: 'Tărcaia', value: 'Tărcaia' },
  { label: 'Tarcal', value: 'Tarcal' },
  { label: 'Tarcău', value: 'Tarcău' },
  { label: 'Tarcea', value: 'Tarcea' },
  { label: 'Tarcento', value: 'Tarcento' },
  { label: 'Tarcoola Beach', value: 'Tarcoola Beach' },
  { label: 'Tarczyn', value: 'Tarczyn' },
  { label: 'Tardáguila', value: 'Tardáguila' },
  { label: 'Tardajos', value: 'Tardajos' },
  { label: 'Tardelcuende', value: 'Tardelcuende' },
  { label: 'Tardeo', value: 'Tardeo' },
  { label: 'Tardienta', value: 'Tardienta' },
  { label: 'Tardos', value: 'Tardos' },
  { label: 'Tarécuato', value: 'Tarécuato' },
  { label: 'Taree', value: 'Taree' },
  { label: 'Tarejero', value: 'Tarejero' },
  { label: 'Taremskoye', value: 'Taremskoye' },
  { label: 'Taren Point', value: 'Taren Point' },
  { label: 'Tarentum', value: 'Tarentum' },
  { label: 'Taretán', value: 'Taretán' },
  { label: 'Taretán', value: 'Taretán' },
  { label: 'Tarfaya', value: 'Tarfaya' },
  { label: 'Targanice', value: 'Targanice' },
  { label: 'Targon', value: 'Targon' },
  { label: 'Targovishte', value: 'Targovishte' },
  { label: 'Târgovişte', value: 'Târgovişte' },
  { label: 'Targówek', value: 'Targówek' },
  { label: 'Targowisko', value: 'Targowisko' },
  { label: 'Târgşoru Vechi', value: 'Târgşoru Vechi' },
  { label: 'Târgu Bujor', value: 'Târgu Bujor' },
  { label: 'Târgu Cărbuneşti', value: 'Târgu Cărbuneşti' },
  { label: 'Târgu Frumos', value: 'Târgu Frumos' },
  { label: 'Târgu Jiu', value: 'Târgu Jiu' },
  { label: 'Târgu Lăpuş', value: 'Târgu Lăpuş' },
  { label: 'Târgu Mureș', value: 'Târgu Mureș' },
  { label: 'Târgu Neamţ', value: 'Târgu Neamţ' },
  { label: 'Târgu Ocna', value: 'Târgu Ocna' },
  { label: 'Târgu Secuiesc', value: 'Târgu Secuiesc' },
  { label: 'Târgu Trotuş', value: 'Târgu Trotuş' },
  { label: 'Targuist', value: 'Targuist' },
  { label: 'Târguşor', value: 'Târguşor' },
  { label: 'Tarhuna', value: 'Tarhuna' },
  { label: 'Tari', value: 'Tari' },
  { label: 'Tari Pori', value: 'Tari Pori' },
  { label: 'Táriba', value: 'Táriba' },
  { label: 'Tăriceni', value: 'Tăriceni' },
  { label: 'Tariego De Cerrato', value: 'Tariego De Cerrato' },
  { label: 'Tarifa', value: 'Tarifa' },
  { label: 'Tariffville', value: 'Tariffville' },
  { label: 'Tarija', value: 'Tarija' },
  { label: 'Tariji', value: 'Tariji' },
  { label: 'Tariji', value: 'Tariji' },
  { label: 'Tarik Darreh-Ye Bala', value: 'Tarik Darreh-Ye Bala' },
  { label: 'Tarikere', value: 'Tarikere' },
  { label: 'Tarim', value: 'Tarim' },
  { label: 'Tarímbaro', value: 'Tarímbaro' },
  { label: 'Tarimoro', value: 'Tarimoro' },
  { label: 'Taringa', value: 'Taringa' },
  { label: 'Tarinkot', value: 'Tarinkot' },
  { label: 'Tariric', value: 'Tariric' },
  { label: 'Tariverde', value: 'Tariverde' },
  { label: 'Tarján', value: 'Tarján' },
  { label: 'Tark', value: 'Tark' },
  { label: 'Tárkány', value: 'Tárkány' },
  { label: 'Tarkhinabad', value: 'Tarkhinabad' },
  { label: 'Tarkhovka', value: 'Tarkhovka' },
  { label: 'Tarki', value: 'Tarki' },
  { label: 'Tarkio', value: 'Tarkio' },
  { label: 'Tarko-Sale', value: 'Tarko-Sale' },
  { label: 'Tarkwa', value: 'Tarkwa' },
  { label: 'Tarlac City', value: 'Tarlac City' },
  { label: 'Tarlac City', value: 'Tarlac City' },
  { label: 'Tarleton', value: 'Tarleton' },
  { label: 'Târlişua', value: 'Târlişua' },
  { label: 'Tarłów', value: 'Tarłów' },
  { label: 'Tărlungeni', value: 'Tărlungeni' },
  { label: 'Tarm', value: 'Tarm' },
  { label: 'Tarma', value: 'Tarma' },
  { label: 'Tarmstedt', value: 'Tarmstedt' },
  { label: 'Tarn', value: 'Tarn' },
  { label: 'Tarn Taran Sahib', value: 'Tarn Taran Sahib' },
  { label: 'Tarna Mare', value: 'Tarna Mare' },
  { label: 'Tarnalelesz', value: 'Tarnalelesz' },
  { label: 'Tarnaörs', value: 'Tarnaörs' },
  { label: 'Târnava', value: 'Târnava' },
  { label: 'Târnava', value: 'Târnava' },
  { label: 'Târnăveni', value: 'Târnăveni' },
  { label: 'Tarnawatka', value: 'Tarnawatka' },
  { label: 'Tårnby', value: 'Tårnby' },
  { label: 'Tårnby Kommune', value: 'Tårnby Kommune' },
  { label: 'Tarneit', value: 'Tarneit' },
  { label: 'Tarnobrzeg', value: 'Tarnobrzeg' },
  { label: 'Tarnogród', value: 'Tarnogród' },
  { label: 'Tarnogskiy Gorodok', value: 'Tarnogskiy Gorodok' },
  { label: 'Tarnogskiy Rayon', value: 'Tarnogskiy Rayon' },
  { label: 'Tárnok', value: 'Tárnok' },
  { label: 'Tarnos', value: 'Tarnos' },
  { label: 'Târnova', value: 'Târnova' },
  { label: 'Târnova', value: 'Târnova' },
  { label: 'Tarnow', value: 'Tarnow' },
  { label: 'Tarnów', value: 'Tarnów' },
  { label: 'Tarnów Opolski', value: 'Tarnów Opolski' },
  { label: 'Tarnowiec', value: 'Tarnowiec' },
  { label: 'Tarnowiec', value: 'Tarnowiec' },
  { label: 'Tarnówka', value: 'Tarnówka' },
  { label: 'Tarnowo Podgórne', value: 'Tarnowo Podgórne' },
  { label: 'Tarnowskie Góry', value: 'Tarnowskie Góry' },
  { label: 'Tärnsjö', value: 'Tärnsjö' },
  { label: 'Taroda', value: 'Taroda' },
  { label: 'Tarom', value: 'Tarom' },
  { label: 'Tarong', value: 'Tarong' },
  { label: 'Taronik', value: 'Taronik' },
  { label: 'Taroona', value: 'Taroona' },
  { label: 'Tarouca', value: 'Tarouca' },
  { label: 'Taroudannt', value: 'Taroudannt' },
  { label: 'Taroudant', value: 'Taroudant' },
  { label: 'Tarp', value: 'Tarp' },
  { label: 'Tarpa', value: 'Tarpa' },
  { label: 'Tarpey Village', value: 'Tarpey Village' },
  { label: 'Tarpon Springs', value: 'Tarpon Springs' },
  { label: 'Tarporley', value: 'Tarporley' },
  { label: 'Tarq Rud', value: 'Tarq Rud' },
  { label: 'Tarqui', value: 'Tarqui' },
  { label: 'Tarquinia', value: 'Tarquinia' },
  { label: 'Tarrafal', value: 'Tarrafal' },
  { label: 'Tarrafal De São Nicolau', value: 'Tarrafal De São Nicolau' },
  { label: 'Tarrafas', value: 'Tarrafas' },
  { label: 'Tarragindi', value: 'Tarragindi' },
  { label: 'Tarragona', value: 'Tarragona' },
  { label: 'Tarragona', value: 'Tarragona' },
  { label: 'Tarragona', value: 'Tarragona' },
  { label: 'Tarragona', value: 'Tarragona' },
  { label: 'Tarrant', value: 'Tarrant' },
  { label: 'Tarrant County', value: 'Tarrant County' },
  { label: 'Tarrawanna', value: 'Tarrawanna' },
  { label: 'Tarrazú', value: 'Tarrazú' },
  { label: 'Tàrrega', value: 'Tàrrega' },
  { label: 'Tarrenz', value: 'Tarrenz' },
  { label: 'Tarrés', value: 'Tarrés' },
  { label: 'Tarro', value: 'Tarro' },
  { label: 'Tarroja De Segarra', value: 'Tarroja De Segarra' },
  { label: 'Tarrytown', value: 'Tarrytown' },
  { label: 'Tårs', value: 'Tårs' },
  { label: 'Tarsia', value: 'Tarsia' },
  { label: 'Tarskoye', value: 'Tarskoye' },
  { label: 'Tarso', value: 'Tarso' },
  { label: 'Târşolţ', value: 'Târşolţ' },
  { label: 'Târşolţel', value: 'Târşolţel' },
  { label: 'Tarsouat', value: 'Tarsouat' },
  { label: 'Tarsus', value: 'Tarsus' },
  { label: 'Tartagal', value: 'Tartagal' },
  { label: 'Tartanedo', value: 'Tartanedo' },
  { label: 'Tartano', value: 'Tartano' },
  { label: 'Tartaro', value: 'Tartaro' },
  { label: 'Tartarugalzinho', value: 'Tartarugalzinho' },
  { label: 'Tartas', value: 'Tartas' },
  { label: 'Tărtăşeşti', value: 'Tărtăşeşti' },
  { label: 'Tartiki', value: 'Tartiki' },
  { label: 'Tartouss', value: 'Tartouss' },
  { label: 'Tartu', value: 'Tartu' },
  { label: 'Tartu Linn', value: 'Tartu Linn' },
  { label: 'Tartu Vald', value: 'Tartu Vald' },
  { label: 'Tarub', value: 'Tarub' },
  { label: 'Tarui', value: 'Tarui' },
  { label: 'Tarumã', value: 'Tarumã' },
  { label: 'Tarumirim', value: 'Tarumirim' },
  { label: 'Tarumizu', value: 'Tarumizu' },
  { label: 'Tarumizu Shi', value: 'Tarumizu Shi' },
  { label: 'Tarumovka', value: 'Tarumovka' },
  { label: 'Tarumovskiy Rayon', value: 'Tarumovskiy Rayon' },
  { label: 'Tarusa', value: 'Tarusa' },
  { label: 'Tarusan', value: 'Tarusan' },
  { label: 'Tarusskiy Rayon', value: 'Tarusskiy Rayon' },
  { label: 'Tārūt', value: 'Tārūt' },
  { label: 'Tarutyne', value: 'Tarutyne' },
  { label: 'Tar-Vabriga-Torre Abrega', value: 'Tar-Vabriga-Torre Abrega' },
  { label: 'Tarvasjoki', value: 'Tarvasjoki' },
  { label: 'Tarvin', value: 'Tarvin' },
  { label: 'Tarvisio', value: 'Tarvisio' },
  { label: 'Tarzo', value: 'Tarzo' },
  { label: 'Tas Tappeh', value: 'Tas Tappeh' },
  { label: 'Tășad', value: 'Tășad' },
  { label: 'Tasbandi', value: 'Tasbandi' },
  { label: 'Tasböget', value: 'Tasböget' },
  { label: 'Taşca', value: 'Taşca' },
  { label: 'Tasco', value: 'Tasco' },
  { label: 'Tascott', value: 'Tascott' },
  { label: 'Tasek Glugor', value: 'Tasek Glugor' },
  { label: 'Taseyevo', value: 'Taseyevo' },
  { label: 'Tasgaon', value: 'Tasgaon' },
  { label: 'Tashan', value: 'Tashan' },
  { label: 'Tashanta', value: 'Tashanta' },
  { label: 'Tashara', value: 'Tashara' },
  { label: 'Tashir', value: 'Tashir' },
  { label: 'Tashkent', value: 'Tashkent' },
  { label: 'Tash-Kumyr', value: 'Tash-Kumyr' },
  { label: 'Tashla', value: 'Tashla' },
  { label: 'Tashtagol', value: 'Tashtagol' },
  { label: 'Tashtagol’Skiy Rayon', value: 'Tashtagol’Skiy Rayon' },
  { label: 'Tashtyp', value: 'Tashtyp' },
  { label: 'Tasikmalaya', value: 'Tasikmalaya' },
  { label: 'Tasīl', value: 'Tasīl' },
  { label: 'Taşkent', value: 'Taşkent' },
  { label: 'Taşköprü', value: 'Taşköprü' },
  { label: 'Taşlıçay', value: 'Taşlıçay' },
  { label: 'Tasman Peninsula', value: 'Tasman Peninsula' },
  { label: 'Tăşnad', value: 'Tăşnad' },
  { label: 'Taşova', value: 'Taşova' },
  { label: 'Tasovčići', value: 'Tasovčići' },
  { label: 'Tasovice', value: 'Tasovice' },
  { label: 'Tasqala', value: 'Tasqala' },
  { label: 'Tasquillo', value: 'Tasquillo' },
  { label: 'Tasran', value: 'Tasran' },
  { label: 'Tass', value: 'Tass' },
  { label: 'Tassarolo', value: 'Tassarolo' },
  { label: 'Tassin-La-Demi-Lune', value: 'Tassin-La-Demi-Lune' },
  { label: 'Tasso Fragoso', value: 'Tasso Fragoso' },
  { label: 'Tassullo', value: 'Tassullo' },
  { label: 'Tasuj', value: 'Tasuj' },
  { label: 'Taszár', value: 'Taszár' },
  { label: 'Tata', value: 'Tata' },
  { label: 'Tatabánya', value: 'Tatabánya' },
  { label: 'Tatahuicapá', value: 'Tatahuicapá' },
  { label: 'Tatahuicapan', value: 'Tatahuicapan' },
  { label: 'Tataltepec De Valdés', value: 'Tataltepec De Valdés' },
  { label: 'Tatamy', value: 'Tatamy' },
  { label: 'Tataouine', value: 'Tataouine' },
  { label: 'Tatar Olia', value: 'Tatar Olia' },
  { label: 'Tătărani', value: 'Tătărani' },
  { label: 'Tătărani', value: 'Tătărani' },
  { label: 'Tătărăni', value: 'Tătărăni' },
  { label: 'Tătăranu', value: 'Tătăranu' },
  { label: 'Tătărăști', value: 'Tătărăști' },
  { label: 'Tătărăștii De Jos', value: 'Tătărăștii De Jos' },
  { label: 'Tătărăștii De Sus', value: 'Tătărăștii De Sus' },
  { label: 'Tatarbunary', value: 'Tatarbunary' },
  { label: 'Tatariv', value: 'Tatariv' },
  { label: 'Tatarka', value: 'Tatarka' },
  { label: 'Tatarsk', value: 'Tatarsk' },
  { label: 'Tatarskaya Kargala', value: 'Tatarskaya Kargala' },
  { label: 'Tatarskaya Pishlya', value: 'Tatarskaya Pishlya' },
  { label: 'Tatarskiy Rayon', value: 'Tatarskiy Rayon' },
  { label: 'Tătaru', value: 'Tătaru' },
  { label: 'Tătaru', value: 'Tătaru' },
  { label: 'Tătăruşi', value: 'Tătăruşi' },
  { label: 'Tatatila', value: 'Tatatila' },
  { label: 'Tataurovo', value: 'Tataurovo' },
  { label: 'Tatauzoquico', value: 'Tatauzoquico' },
  { label: 'Tate County', value: 'Tate County' },
  { label: 'Tatebayashi', value: 'Tatebayashi' },
  { label: 'Tatebayashi-Shi', value: 'Tatebayashi-Shi' },
  { label: 'Tateshina', value: 'Tateshina' },
  { label: 'Tateyama', value: 'Tateyama' },
  { label: 'Tateyama-Shi', value: 'Tateyama-Shi' },
  { label: 'Tathra', value: 'Tathra' },
  { label: 'Tatiara', value: 'Tatiara' },
  { label: 'Tating', value: 'Tating' },
  { label: 'Tatinghem', value: 'Tatinghem' },
  { label: 'Tatishchevo', value: 'Tatishchevo' },
  { label: 'Tatou', value: 'Tatou' },
  { label: 'Tatoxcac', value: 'Tatoxcac' },
  { label: 'Tatsinskiy', value: 'Tatsinskiy' },
  { label: 'Tatsuno', value: 'Tatsuno' },
  { label: 'Tatsuno', value: 'Tatsuno' },
  { label: 'Tatsunochō-Tominaga', value: 'Tatsunochō-Tominaga' },
  { label: 'Tatsuno-Shi', value: 'Tatsuno-Shi' },
  { label: 'Tattayyangarpettai', value: 'Tattayyangarpettai' },
  { label: 'Tattendorf', value: 'Tattendorf' },
  { label: 'Tattenhall', value: 'Tattenhall' },
  { label: 'Tattnall County', value: 'Tattnall County' },
  { label: 'Tatton', value: 'Tatton' },
  { label: 'Tatuí', value: 'Tatuí' },
  { label: 'Tătuleşti', value: 'Tătuleşti' },
  { label: 'Tatum', value: 'Tatum' },
  { label: 'Tatumbla', value: 'Tatumbla' },
  { label: 'Tatura', value: 'Tatura' },
  { label: 'Tatvan', value: 'Tatvan' },
  { label: 'Tau', value: 'Tau' },
  { label: 'Tău', value: 'Tău' },
  { label: 'Tauá', value: 'Tauá' },
  { label: 'Taubaté', value: 'Taubaté' },
  { label: 'Tauberbischofsheim', value: 'Tauberbischofsheim' },
  { label: 'Taucha', value: 'Taucha' },
  { label: 'Tauche', value: 'Tauche' },
  { label: 'Täuffelen', value: 'Täuffelen' },
  { label: 'Taufkirchen', value: 'Taufkirchen' },
  { label: 'Taufkirchen An Der Trattnach', value: 'Taufkirchen An Der Trattnach' },
  { label: 'Taugl', value: 'Taugl' },
  { label: 'Taujica', value: 'Taujica' },
  { label: 'Taulabé', value: 'Taulabé' },
  { label: 'Taulé', value: 'Taulé' },
  { label: 'Taulignan', value: 'Taulignan' },
  { label: 'Taulov', value: 'Taulov' },
  { label: 'Taungdwingyi', value: 'Taungdwingyi' },
  { label: 'Taunggyi', value: 'Taunggyi' },
  { label: 'Taungoh', value: 'Taungoh' },
  { label: 'Taungoh', value: 'Taungoh' },
  { label: 'Taungoo', value: 'Taungoo' },
  { label: 'Taunsa', value: 'Taunsa' },
  { label: 'Taunton', value: 'Taunton' },
  { label: 'Taunton', value: 'Taunton' },
  { label: 'Taunusstein', value: 'Taunusstein' },
  { label: 'Tauplitz', value: 'Tauplitz' },
  { label: 'Taupo', value: 'Taupo' },
  { label: 'Taupont', value: 'Taupont' },
  { label: 'Taura', value: 'Taura' },
  { label: 'Taurage', value: 'Taurage' },
  { label: 'Tauramena', value: 'Tauramena' },
  { label: 'Tauranga', value: 'Tauranga' },
  { label: 'Taurano', value: 'Taurano' },
  { label: 'Taurasi', value: 'Taurasi' },
  { label: 'Tăureni', value: 'Tăureni' },
  { label: 'Tăureni', value: 'Tăureni' },
  { label: 'Tauriac', value: 'Tauriac' },
  { label: 'Taurianova', value: 'Taurianova' },
  { label: 'Taurisano', value: 'Taurisano' },
  { label: 'Tausa', value: 'Tausa' },
  { label: 'Tauscha', value: 'Tauscha' },
  { label: 'Taūshyq', value: 'Taūshyq' },
  { label: 'Tauste', value: 'Tauste' },
  { label: 'Tauţ', value: 'Tauţ' },
  { label: 'Tăut', value: 'Tăut' },
  { label: 'Tautenhain', value: 'Tautenhain' },
  { label: 'Tăuteu', value: 'Tăuteu' },
  { label: 'Tăuții De Sus', value: 'Tăuții De Sus' },
  { label: 'Tăuţii-Măgherăuş', value: 'Tăuţii-Măgherăuş' },
  { label: 'Tauxigny', value: 'Tauxigny' },
  { label: 'Tavagnacco', value: 'Tavagnacco' },
  { label: 'Tavagnasco', value: 'Tavagnasco' },
  { label: 'Tavaleh', value: 'Tavaleh' },
  { label: 'Tavaneh', value: 'Tavaneh' },
  { label: 'Tavannes', value: 'Tavannes' },
  { label: 'Tavarede', value: 'Tavarede' },
  { label: 'Tavares', value: 'Tavares' },
  { label: 'Tavares', value: 'Tavares' },
  { label: 'Tavares', value: 'Tavares' },
  { label: 'Tavarnelle Val Di Pesa', value: 'Tavarnelle Val Di Pesa' },
  { label: 'Tavarnuzze', value: 'Tavarnuzze' },
  { label: 'Tavas', value: 'Tavas' },
  { label: 'Tavaux', value: 'Tavaux' },
  { label: 'Tavazzano', value: 'Tavazzano' },
  { label: 'Tavda', value: 'Tavda' },
  { label: 'Taveh', value: 'Taveh' },
  { label: 'Tavel', value: 'Tavel' },
  { label: 'Tavenna', value: 'Tavenna' },
  { label: 'Tavera', value: 'Tavera' },
  { label: 'Taverna', value: 'Taverna' },
  { label: 'Taverna Ravindola', value: 'Taverna Ravindola' },
  { label: 'Taverne Darbia', value: 'Taverne Darbia' },
  { label: 'Tavernelle', value: 'Tavernelle' },
  { label: 'Tavernelle', value: 'Tavernelle' },
  { label: 'Tavernelle', value: 'Tavernelle' },
  { label: 'Tavernerio', value: 'Tavernerio' },
  { label: 'Tavernes Blanques', value: 'Tavernes Blanques' },
  { label: 'Tavernes De La Valldigna', value: 'Tavernes De La Valldigna' },
  { label: 'Tavernier', value: 'Tavernier' },
  { label: 'Tavernola Bergamasca', value: 'Tavernola Bergamasca' },
  { label: 'Tavernole', value: 'Tavernole' },
  { label: 'Tavèrnoles', value: 'Tavèrnoles' },
  { label: 'Taverny', value: 'Taverny' },
  { label: 'Tavers', value: 'Tavers' },
  { label: 'Tavertet', value: 'Tavertet' },
  { label: 'Taveta', value: 'Taveta' },
  { label: 'Taviano', value: 'Taviano' },
  { label: 'Tavigliano', value: 'Tavigliano' },
  { label: 'Tavilan-E Sofla', value: 'Tavilan-E Sofla' },
  { label: 'Tavira', value: 'Tavira' },
  { label: 'Taviran', value: 'Taviran' },
  { label: 'Taviran', value: 'Taviran' },
  { label: 'Tavistock', value: 'Tavistock' },
  { label: 'Tavistock', value: 'Tavistock' },
  { label: 'Tavo', value: 'Tavo' },
  { label: 'Tavoleto', value: 'Tavoleto' },
  { label: 'Tavrichanka', value: 'Tavrichanka' },
  { label: 'Tavricheskoye', value: 'Tavricheskoye' },
  { label: 'Távros', value: 'Távros' },
  { label: 'Tavşanlı', value: 'Tavşanlı' },
  { label: 'Tavullia', value: 'Tavullia' },
  { label: 'Tawagan', value: 'Tawagan' },
  { label: 'Tawala', value: 'Tawala' },
  { label: 'Tawan Tawan', value: 'Tawan Tawan' },
  { label: 'Tawan Tawan', value: 'Tawan Tawan' },
  { label: 'Tawan Tawan', value: 'Tawan Tawan' },
  { label: 'Tawang', value: 'Tawang' },
  { label: 'Tawaramoto', value: 'Tawaramoto' },
  { label: 'Tawas City', value: 'Tawas City' },
  { label: 'Tawau', value: 'Tawau' },
  { label: 'Tawern', value: 'Tawern' },
  { label: 'Taxach', value: 'Taxach' },
  { label: 'Taxadho', value: 'Taxadho' },
  { label: 'Taxco', value: 'Taxco' },
  { label: 'Taxco El Viejo', value: 'Taxco El Viejo' },
  { label: 'Taxenbach', value: 'Taxenbach' },
  { label: 'Taxiárches', value: 'Taxiárches' },
  { label: 'Taxisco', value: 'Taxisco' },
  { label: 'Taxtes', value: 'Taxtes' },
  { label: 'Tây Ninh', value: 'Tây Ninh' },
  { label: 'Tayabas', value: 'Tayabas' },
  { label: 'Tayabas Ibaba', value: 'Tayabas Ibaba' },
  { label: 'Tayabo', value: 'Tayabo' },
  { label: 'Tayabo', value: 'Tayabo' },
  { label: 'Tayahua', value: 'Tayahua' },
  { label: 'Tayaman', value: 'Tayaman' },
  { label: 'Tayasan', value: 'Tayasan' },
  { label: 'Taybad', value: 'Taybad' },
  { label: 'Tayeeglow', value: 'Tayeeglow' },
  { label: 'Tayga', value: 'Tayga' },
  { label: 'Tayginka', value: 'Tayginka' },
  { label: 'Tayirove', value: 'Tayirove' },
  { label: 'Taylor', value: 'Taylor' },
  { label: 'Taylor', value: 'Taylor' },
  { label: 'Taylor', value: 'Taylor' },
  { label: 'Taylor', value: 'Taylor' },
  { label: 'Taylor', value: 'Taylor' },
  { label: 'Taylor', value: 'Taylor' },
  { label: 'Taylor County', value: 'Taylor County' },
  { label: 'Taylor County', value: 'Taylor County' },
  { label: 'Taylor County', value: 'Taylor County' },
  { label: 'Taylor County', value: 'Taylor County' },
  { label: 'Taylor County', value: 'Taylor County' },
  { label: 'Taylor County', value: 'Taylor County' },
  { label: 'Taylor County', value: 'Taylor County' },
  { label: 'Taylor Creek', value: 'Taylor Creek' },
  { label: 'Taylor Lake Village', value: 'Taylor Lake Village' },
  { label: 'Taylor Mill', value: 'Taylor Mill' },
  { label: 'Taylors', value: 'Taylors' },
  { label: 'Taylors Falls', value: 'Taylors Falls' },
  { label: 'Taylors Hill', value: 'Taylors Hill' },
  { label: 'Taylors Lakes', value: 'Taylors Lakes' },
  { label: 'Taylorsville', value: 'Taylorsville' },
  { label: 'Taylorsville', value: 'Taylorsville' },
  { label: 'Taylorsville', value: 'Taylorsville' },
  { label: 'Taylorsville', value: 'Taylorsville' },
  { label: 'Taylorville', value: 'Taylorville' },
  { label: 'Taymyrsky Dolgano-Nenetsky District', value: 'Taymyrsky Dolgano-Nenetsky District' },
  { label: 'Tayoltita', value: 'Tayoltita' },
  { label: 'Taypano', value: 'Taypano' },
  { label: 'Tayport', value: 'Tayport' },
  { label: 'Taysan', value: 'Taysan' },
  { label: 'Tayshet', value: 'Tayshet' },
  { label: 'Tayshetskiy Rayon', value: 'Tayshetskiy Rayon' },
  { label: 'Taytay', value: 'Taytay' },
  { label: 'Taytay', value: 'Taytay' },
  { label: 'Taytay', value: 'Taytay' },
  { label: 'Taytayan', value: 'Taytayan' },
  { label: 'Taytayan', value: 'Taytayan' },
  { label: 'Taytayan', value: 'Taytayan' },
  { label: 'Taytayan', value: 'Taytayan' },
  { label: 'Taytsy', value: 'Taytsy' },
  { label: 'Tayturka', value: 'Tayturka' },
  { label: 'Tayu', value: 'Tayu' },
  { label: 'Tayud', value: 'Tayud' },
  { label: 'Tayug', value: 'Tayug' },
  { label: 'Tayug', value: 'Tayug' },
  { label: 'Tayum', value: 'Tayum' },
  { label: 'Tayum', value: 'Tayum' },
  { label: 'Taywanak Ilaya', value: 'Taywanak Ilaya' },
  { label: 'Ţayyibat Al Imām', value: 'Ţayyibat Al Imām' },
  { label: 'Tayzhina', value: 'Tayzhina' },
  { label: 'Taza', value: 'Taza' },
  { label: 'Taza De Agua Ojo Zarco', value: 'Taza De Agua Ojo Zarco' },
  { label: 'Tazacorte', value: 'Tazacorte' },
  { label: 'Tazakand', value: 'Tazakand' },
  { label: 'Tazakend', value: 'Tazakend' },
  { label: 'Tazaquil', value: 'Tazaquil' },
  { label: 'Taze Shar', value: 'Taze Shar' },
  { label: 'Tazehnab-E Sofla', value: 'Tazehnab-E Sofla' },
  { label: 'Tazerka', value: 'Tazerka' },
  { label: 'Tazewell', value: 'Tazewell' },
  { label: 'Tazewell', value: 'Tazewell' },
  { label: 'Tazewell County', value: 'Tazewell County' },
  { label: 'Tazewell County', value: 'Tazewell County' },
  { label: 'Tazian', value: 'Tazian' },
  { label: 'Tázlár', value: 'Tázlár' },
  { label: 'Tazlău', value: 'Tazlău' },
  { label: 'Taznakht', value: 'Taznakht' },
  { label: 'Tazoult-Lambese', value: 'Tazoult-Lambese' },
  { label: 'Tazovskiy', value: 'Tazovskiy' },
  { label: 'Tazumbo', value: 'Tazumbo' },
  { label: 'Tbeng Meanchey', value: 'Tbeng Meanchey' },
  { label: 'Tbilisi', value: 'Tbilisi' },
  { label: 'Tbilisskaya', value: 'Tbilisskaya' },
  { label: 'Tboli', value: 'Tboli' },
  { label: 'Tchamba', value: 'Tchamba' },
  { label: 'Tchaourou', value: 'Tchaourou' },
  { label: 'Tchibanga', value: 'Tchibanga' },
  { label: 'Tchintabaraden', value: 'Tchintabaraden' },
  { label: 'Tcholliré', value: 'Tcholliré' },
  { label: 'Tchula', value: 'Tchula' },
  { label: 'Tczew', value: 'Tczew' },
  { label: 'Tczów', value: 'Tczów' },
  { label: 'Te Anau', value: 'Te Anau' },
  { label: 'Te Kauwhata', value: 'Te Kauwhata' },
  { label: 'Tea', value: 'Tea' },
  { label: 'Tea Gardens', value: 'Tea Gardens' },
  { label: 'Tea Tree Gully', value: 'Tea Tree Gully' },
  { label: 'Teabo', value: 'Teabo' },
  { label: 'Teaca', value: 'Teaca' },
  { label: 'Teacalco', value: 'Teacalco' },
  { label: 'Teacalco De Dorantes (San José Teacalco)', value: 'Teacalco De Dorantes (San José Teacalco)' },
  { label: 'Teacapan', value: 'Teacapan' },
  { label: 'Teague', value: 'Teague' },
  { label: 'Teana', value: 'Teana' },
  { label: 'Teaneck', value: 'Teaneck' },
  { label: 'Teano', value: 'Teano' },
  { label: 'Teaoraereke Village', value: 'Teaoraereke Village' },
  { label: 'Teapa', value: 'Teapa' },
  { label: 'Tearce', value: 'Tearce' },
  { label: 'Teasc', value: 'Teasc' },
  { label: 'Teaticket', value: 'Teaticket' },
  { label: 'Teayo', value: 'Teayo' },
  { label: 'Teays Valley', value: 'Teays Valley' },
  { label: 'Teba', value: 'Teba' },
  { label: 'Tebag East', value: 'Tebag East' },
  { label: 'Tebag East', value: 'Tebag East' },
  { label: 'Tébar', value: 'Tébar' },
  { label: 'Tebario', value: 'Tebario' },
  { label: 'Tebesbest', value: 'Tebesbest' },
  { label: 'Tebesbest', value: 'Tebesbest' },
  { label: 'Tébessa', value: 'Tébessa' },
  { label: 'Tebingtinggi', value: 'Tebingtinggi' },
  { label: 'Tebosa', value: 'Tebosa' },
  { label: 'Téboursouk', value: 'Téboursouk' },
  { label: 'Tecacahuaco', value: 'Tecacahuaco' },
  { label: 'Tecajec', value: 'Tecajec' },
  { label: 'Tecali', value: 'Tecali' },
  { label: 'Tecalitlán Municipality', value: 'Tecalitlán Municipality' },
  { label: 'Tecalpulco', value: 'Tecalpulco' },
  { label: 'Tecama', value: 'Tecama' },
  { label: 'Tecámac', value: 'Tecámac' },
  { label: 'Tecámac De Felipe Villanueva', value: 'Tecámac De Felipe Villanueva' },
  { label: 'Tecamachalco', value: 'Tecamachalco' },
  { label: 'Tecamachalco', value: 'Tecamachalco' },
  { label: 'Tecamalucan', value: 'Tecamalucan' },
  { label: 'Tecario', value: 'Tecario' },
  { label: 'Tecate', value: 'Tecate' },
  { label: 'Tecax', value: 'Tecax' },
  { label: 'Tecaxic', value: 'Tecaxic' },
  { label: 'Tecchiena', value: 'Tecchiena' },
  { label: 'Teceu Mic', value: 'Teceu Mic' },
  { label: 'Techachaltitla', value: 'Techachaltitla' },
  { label: 'Techaluta De Montenegro', value: 'Techaluta De Montenegro' },
  { label: 'Techelsberg', value: 'Techelsberg' },
  { label: 'Techichili', value: 'Techichili' },
  { label: 'Techiman', value: 'Techiman' },
  { label: 'Techiman North', value: 'Techiman North' },
  { label: 'Techirghiol', value: 'Techirghiol' },
  { label: 'Tecka', value: 'Tecka' },
  { label: 'Tecklenburg', value: 'Tecklenburg' },
  { label: 'Teckomatorp', value: 'Teckomatorp' },
  { label: 'Tecoac (Santa María Nativitas)', value: 'Tecoac (Santa María Nativitas)' },
  { label: 'Tecoh', value: 'Tecoh' },
  { label: 'Tecolapan', value: 'Tecolapan' },
  { label: 'Tecolcuautla', value: 'Tecolcuautla' },
  { label: 'Tecolotlán', value: 'Tecolotlán' },
  { label: 'Tecolots', value: 'Tecolots' },
  { label: 'Tecoltepec', value: 'Tecoltepec' },
  { label: 'Tecoluca', value: 'Tecoluca' },
  { label: 'Tecolutla', value: 'Tecolutla' },
  { label: 'Tecoma', value: 'Tecoma' },
  { label: 'Tecomán', value: 'Tecomán' },
  { label: 'Tecomatán', value: 'Tecomatán' },
  { label: 'Tecomatepec', value: 'Tecomatepec' },
  { label: 'Tecomates', value: 'Tecomates' },
  { label: 'Tecomatlán', value: 'Tecomatlán' },
  { label: 'Tecomavaca', value: 'Tecomavaca' },
  { label: 'Tecominoacán', value: 'Tecominoacán' },
  { label: 'Tečovice', value: 'Tečovice' },
  { label: 'Tecozautla', value: 'Tecozautla' },
  { label: 'Técpan De Galeana', value: 'Técpan De Galeana' },
  { label: 'Tecpán Guatemala', value: 'Tecpán Guatemala' },
  { label: 'Tecpantzacoalco', value: 'Tecpantzacoalco' },
  { label: 'Tecpatán', value: 'Tecpatán' },
  { label: 'Tectitán', value: 'Tectitán' },
  { label: 'Tecuala', value: 'Tecuala' },
  { label: 'Tecualilla', value: 'Tecualilla' },
  { label: 'Tecuanipa', value: 'Tecuanipa' },
  { label: 'Tecuanipan', value: 'Tecuanipan' },
  { label: 'Tecuattitan', value: 'Tecuattitan' },
  { label: 'Tecuci', value: 'Tecuci' },
  { label: 'Tecuci', value: 'Tecuci' },
  { label: 'Tecuexcontitlán (Tecoescontitlán)', value: 'Tecuexcontitlán (Tecoescontitlán)' },
  { label: 'Tecumseh', value: 'Tecumseh' },
  { label: 'Tecumseh', value: 'Tecumseh' },
  { label: 'Tecumseh', value: 'Tecumseh' },
  { label: 'Tedder', value: 'Tedder' },
  { label: 'Teddington', value: 'Teddington' },
  { label: 'Teeli', value: 'Teeli' },
  { label: 'Teeriniemi', value: 'Teeriniemi' },
  { label: 'Teesdale', value: 'Teesdale' },
  { label: 'Teesdorf', value: 'Teesdorf' },
  { label: 'Tefé', value: 'Tefé' },
  { label: 'Tefenni', value: 'Tefenni' },
  { label: 'Tefeya', value: 'Tefeya' },
  { label: 'Tega Cay', value: 'Tega Cay' },
  { label: 'Tegal', value: 'Tegal' },
  { label: 'Tegel', value: 'Tegel' },
  { label: 'Tegelen', value: 'Tegelen' },
  { label: 'Tegerfelden', value: 'Tegerfelden' },
  { label: 'Tegernheim', value: 'Tegernheim' },
  { label: 'Tegernsee', value: 'Tegernsee' },
  { label: 'Teggiano-Macchiaroli', value: 'Teggiano-Macchiaroli' },
  { label: 'Tegh', value: 'Tegh' },
  { label: 'Teghalimet', value: 'Teghalimet' },
  { label: 'Teghra', value: 'Teghra' },
  { label: 'Tegina', value: 'Tegina' },
  { label: 'Téglás', value: 'Téglás' },
  { label: 'Teglio', value: 'Teglio' },
  { label: 'Teglio Veneto', value: 'Teglio Veneto' },
  { label: 'Tegoleto', value: 'Tegoleto' },
  { label: 'Teguajinal', value: 'Teguajinal' },
  { label: 'Tegucigalpa', value: 'Tegucigalpa' },
  { label: 'Tegueste', value: 'Tegueste' },
  { label: 'Teguise', value: 'Teguise' },
  { label: 'Tegul’Det', value: 'Tegul’Det' },
  { label: 'Tegul’Detskiy Rayon', value: 'Tegul’Detskiy Rayon' },
  { label: 'Tehachapi', value: 'Tehachapi' },
  { label: 'Tehama County', value: 'Tehama County' },
  { label: 'Tehran', value: 'Tehran' },
  { label: 'Tehri', value: 'Tehri' },
  { label: 'Tehri-Garhwal', value: 'Tehri-Garhwal' },
  { label: 'Tehuacán', value: 'Tehuacán' },
  { label: 'Tehuetlán', value: 'Tehuetlán' },
  { label: 'Tehuilotepec', value: 'Tehuilotepec' },
  { label: 'Tehuipango', value: 'Tehuipango' },
  { label: 'Tehuitzingo', value: 'Tehuitzingo' },
  { label: 'Tehuixtla', value: 'Tehuixtla' },
  { label: 'Tehuixtla', value: 'Tehuixtla' },
  { label: 'Tehumardi', value: 'Tehumardi' },
  { label: 'Teià', value: 'Teià' },
  { label: 'Teicha', value: 'Teicha' },
  { label: 'Teichwolframsdorf', value: 'Teichwolframsdorf' },
  { label: 'Teignmouth', value: 'Teignmouth' },
  { label: 'Teillé', value: 'Teillé' },
  { label: 'Teiș', value: 'Teiș' },
  { label: 'Teişani', value: 'Teişani' },
  { label: 'Teisendorf', value: 'Teisendorf' },
  { label: 'Teising', value: 'Teising' },
  { label: 'Teisko', value: 'Teisko' },
  { label: 'Teisnach', value: 'Teisnach' },
  { label: 'Teistungen', value: 'Teistungen' },
  { label: 'Teiu', value: 'Teiu' },
  { label: 'Teiuş', value: 'Teiuş' },
  { label: 'Teixeira', value: 'Teixeira' },
  { label: 'Teixeira', value: 'Teixeira' },
  { label: 'Teixeira A', value: 'Teixeira A' },
  { label: 'Teixeira De Freitas', value: 'Teixeira De Freitas' },
  { label: 'Teixeira Soares', value: 'Teixeira Soares' },
  { label: 'Teixeiras', value: 'Teixeiras' },
  { label: 'Teixeirópolis', value: 'Teixeirópolis' },
  { label: 'Teixoso', value: 'Teixoso' },
  { label: 'Tejada', value: 'Tejada' },
  { label: 'Tejadillos', value: 'Tejadillos' },
  { label: 'Tejado', value: 'Tejado' },
  { label: 'Tejado El', value: 'Tejado El' },
  { label: 'Tejalpa', value: 'Tejalpa' },
  { label: 'Tejaluca', value: 'Tejaluca' },
  { label: 'Tejar', value: 'Tejar' },
  { label: 'Tejeda', value: 'Tejeda' },
  { label: 'Tejeda De Tiétar', value: 'Tejeda De Tiétar' },
  { label: 'Tejeda Y Segoyuela', value: 'Tejeda Y Segoyuela' },
  { label: 'Tejen', value: 'Tejen' },
  { label: 'Tejocote De Calera', value: 'Tejocote De Calera' },
  { label: 'Tejuçuoca', value: 'Tejuçuoca' },
  { label: 'Tejupá', value: 'Tejupá' },
  { label: 'Tejupilco', value: 'Tejupilco' },
  { label: 'Tejupilco De Hidalgo', value: 'Tejupilco De Hidalgo' },
  { label: 'Tejutla', value: 'Tejutla' },
  { label: 'Tekal De Venegas', value: 'Tekal De Venegas' },
  { label: 'Tekamah', value: 'Tekamah' },
  { label: 'Tékane', value: 'Tékane' },
  { label: 'Tekanpur', value: 'Tekanpur' },
  { label: 'Tekanto', value: 'Tekanto' },
  { label: 'Tekari', value: 'Tekari' },
  { label: 'Tekax', value: 'Tekax' },
  { label: 'Tekeli', value: 'Tekeli' },
  { label: 'Tekik De Regil', value: 'Tekik De Regil' },
  { label: 'Tekit', value: 'Tekit' },
  { label: 'Tekkalakote', value: 'Tekkalakote' },
  { label: 'Tekkali', value: 'Tekkali' },
  { label: 'Tekkeköy', value: 'Tekkeköy' },
  { label: 'Tekman', value: 'Tekman' },
  { label: 'Tekmeh Dash', value: 'Tekmeh Dash' },
  { label: 'Tekoh', value: 'Tekoh' },
  { label: 'Tekom', value: 'Tekom' },
  { label: 'Tekstil’Shchiki', value: 'Tekstil’Shchiki' },
  { label: 'Tel Aviv', value: 'Tel Aviv' },
  { label: 'Tel Mond', value: 'Tel Mond' },
  { label: 'Tel’Ma', value: 'Tel’Ma' },
  { label: 'Tela', value: 'Tela' },
  { label: 'Telabastagan', value: 'Telabastagan' },
  { label: 'Telabastagan', value: 'Telabastagan' },
  { label: 'Telafas', value: 'Telafas' },
  { label: 'Telafas', value: 'Telafas' },
  { label: 'Telaga Batu', value: 'Telaga Batu' },
  { label: 'Telagh', value: 'Telagh' },
  { label: 'Telarah', value: 'Telarah' },
  { label: 'Telatyn', value: 'Telatyn' },
  { label: 'Telavi', value: 'Telavi' },
  { label: 'Telbang', value: 'Telbang' },
  { label: 'Telbang', value: 'Telbang' },
  { label: 'Telč', value: 'Telč' },
  { label: 'Telchac Pueblo', value: 'Telchac Pueblo' },
  { label: 'Telchac Puerto', value: 'Telchac Puerto' },
  { label: 'Telchaquillo', value: 'Telchaquillo' },
  { label: 'Telcișor', value: 'Telcișor' },
  { label: 'Telciu', value: 'Telciu' },
  { label: 'Telcruz', value: 'Telcruz' },
  { label: 'Telde', value: 'Telde' },
  { label: 'Teldeniya', value: 'Teldeniya' },
  { label: 'Teleac', value: 'Teleac' },
  { label: 'Telec', value: 'Telec' },
  { label: 'Telefomin', value: 'Telefomin' },
  { label: 'Telega', value: 'Telega' },
  { label: 'Telêmaco Borba', value: 'Telêmaco Borba' },
  { label: 'Telén', value: 'Telén' },
  { label: 'Teleneşti', value: 'Teleneşti' },
  { label: 'Teleormanu', value: 'Teleormanu' },
  { label: 'Telerghma', value: 'Telerghma' },
  { label: 'Telese', value: 'Telese' },
  { label: 'Teleşti', value: 'Teleşti' },
  { label: 'Telfair County', value: 'Telfair County' },
  { label: 'Telfer', value: 'Telfer' },
  { label: 'Telfes Im Stubai', value: 'Telfes Im Stubai' },
  { label: 'Telford', value: 'Telford' },
  { label: 'Telford', value: 'Telford' },
  { label: 'Telford And Wrekin', value: 'Telford And Wrekin' },
  { label: 'Telfs', value: 'Telfs' },
  { label: 'Telgate', value: 'Telgate' },
  { label: 'Telgruc-Sur-Mer', value: 'Telgruc-Sur-Mer' },
  { label: 'Telgte', value: 'Telgte' },
  { label: 'Telha', value: 'Telha' },
  { label: 'Telhara', value: 'Telhara' },
  { label: 'Telica', value: 'Telica' },
  { label: 'Télimélé', value: 'Télimélé' },
  { label: 'Telimele Prefecture', value: 'Telimele Prefecture' },
  { label: 'Telina', value: 'Telina' },
  { label: 'Teliu', value: 'Teliu' },
  { label: 'Teliucu Inferior', value: 'Teliucu Inferior' },
  { label: 'Telixtac', value: 'Telixtac' },
  { label: 'Telki', value: 'Telki' },
  { label: 'Tell Abyad District', value: 'Tell Abyad District' },
  { label: 'Tell City', value: 'Tell City' },
  { label: 'Tella-Sin', value: 'Tella-Sin' },
  { label: 'Teller County', value: 'Teller County' },
  { label: 'Tellico Village', value: 'Tellico Village' },
  { label: 'Tellin', value: 'Tellin' },
  { label: 'Tellingstedt', value: 'Tellingstedt' },
  { label: 'Tello', value: 'Tello' },
  { label: 'Telluride', value: 'Telluride' },
  { label: 'Țelna', value: 'Țelna' },
  { label: 'Telnice', value: 'Telnice' },
  { label: 'Teloché', value: 'Teloché' },
  { label: 'Telões', value: 'Telões' },
  { label: 'Teloloapan', value: 'Teloloapan' },
  { label: 'Telopea', value: 'Telopea' },
  { label: 'Telouet', value: 'Telouet' },
  { label: 'Teloxtoc', value: 'Teloxtoc' },
  { label: 'Telpaneca', value: 'Telpaneca' },
  { label: 'Telpatlán', value: 'Telpatlán' },
  { label: 'Telsiai', value: 'Telsiai' },
  { label: 'Telti', value: 'Telti' },
  { label: 'Teltipán De Juárez', value: 'Teltipán De Juárez' },
  { label: 'Teltow', value: 'Teltow' },
  { label: 'Teluk Intan', value: 'Teluk Intan' },
  { label: 'Teluk Nibung', value: 'Teluk Nibung' },
  { label: 'Teluknaga', value: 'Teluknaga' },
  { label: 'Telve', value: 'Telve' },
  { label: 'Telve Di Sopra', value: 'Telve Di Sopra' },
  { label: 'Tema', value: 'Tema' },
  { label: 'Temaiku Village', value: 'Temaiku Village' },
  { label: 'Temalacaco', value: 'Temalacaco' },
  { label: 'Temalacayuca', value: 'Temalacayuca' },
  { label: 'Temamatla', value: 'Temamatla' },
  { label: 'Temango', value: 'Temango' },
  { label: 'Temapache', value: 'Temapache' },
  { label: 'Temara', value: 'Temara' },
  { label: 'Temascal', value: 'Temascal' },
  { label: 'Temascalapa', value: 'Temascalapa' },
  { label: 'Temascalcingo', value: 'Temascalcingo' },
  { label: 'Temascaltepec', value: 'Temascaltepec' },
  { label: 'Temascaltepec De González', value: 'Temascaltepec De González' },
  { label: 'Temastián (La Cantera)', value: 'Temastián (La Cantera)' },
  { label: 'Temax', value: 'Temax' },
  { label: 'Tembisa', value: 'Tembisa' },
  { label: 'Tembleque', value: 'Tembleque' },
  { label: 'Temecula', value: 'Temecula' },
  { label: 'Temeke', value: 'Temeke' },
  { label: 'Temelec', value: 'Temelec' },
  { label: 'Témeni', value: 'Témeni' },
  { label: 'Temerluh', value: 'Temerluh' },
  { label: 'Temextla', value: 'Temextla' },
  { label: 'Temimilcingo', value: 'Temimilcingo' },
  { label: 'Temir', value: 'Temir' },
  { label: 'Temiraul', value: 'Temiraul' },
  { label: 'Temirgoyevskaya', value: 'Temirgoyevskaya' },
  { label: 'Temirlanovka', value: 'Temirlanovka' },
  { label: 'Temirtau', value: 'Temirtau' },
  { label: 'Temirtau', value: 'Temirtau' },
  { label: 'Témiscaming', value: 'Témiscaming' },
  { label: 'Temiskaming Shores', value: 'Temiskaming Shores' },
  { label: 'Temixco', value: 'Temixco' },
  { label: 'Temizhbekskaya', value: 'Temizhbekskaya' },
  { label: 'Temizhbekskaya', value: 'Temizhbekskaya' },
  { label: 'Tëmkino', value: 'Tëmkino' },
  { label: 'Temnikov', value: 'Temnikov' },
  { label: 'Temnikovskiy Rayon', value: 'Temnikovskiy Rayon' },
  { label: 'Temoac', value: 'Temoac' },
  { label: 'Temoaya', value: 'Temoaya' },
  { label: 'Temora', value: 'Temora' },
  { label: 'Temora Municipality', value: 'Temora Municipality' },
  { label: 'Témoris', value: 'Témoris' },
  { label: 'Temósachic', value: 'Temósachic' },
  { label: 'Temozon', value: 'Temozon' },
  { label: 'Tempe', value: 'Tempe' },
  { label: 'Tempe', value: 'Tempe' },
  { label: 'Tempe Junction', value: 'Tempe Junction' },
  { label: 'Tempelhof', value: 'Tempelhof' },
  { label: 'Temperance', value: 'Temperance' },
  { label: 'Tempio Pausania', value: 'Tempio Pausania' },
  { label: 'Temple', value: 'Temple' },
  { label: 'Temple', value: 'Temple' },
  { label: 'Temple', value: 'Temple' },
  { label: 'Temple', value: 'Temple' },
  { label: 'Temple City', value: 'Temple City' },
  { label: 'Temple Hall', value: 'Temple Hall' },
  { label: 'Temple Hills', value: 'Temple Hills' },
  { label: 'Temple Terrace', value: 'Temple Terrace' },
  { label: 'Templecombe', value: 'Templecombe' },
  { label: 'Templemars', value: 'Templemars' },
  { label: 'Templemore', value: 'Templemore' },
  { label: 'Templeogue', value: 'Templeogue' },
  { label: 'Templepatrick', value: 'Templepatrick' },
  { label: 'Templestowe', value: 'Templestowe' },
  { label: 'Templestowe Lower', value: 'Templestowe Lower' },
  { label: 'Templeton', value: 'Templeton' },
  { label: 'Templeton', value: 'Templeton' },
  { label: 'Templeton', value: 'Templeton' },
  { label: 'Templeuve-En-Pévèle', value: 'Templeuve-En-Pévèle' },
  { label: 'Templin', value: 'Templin' },
  { label: 'Tempoal', value: 'Tempoal' },
  { label: 'Tempoal De Sánchez', value: 'Tempoal De Sánchez' },
  { label: 'Tempsford', value: 'Tempsford' },
  { label: 'Temriuk', value: 'Temriuk' },
  { label: 'Temruksky Rayon', value: 'Temruksky Rayon' },
  { label: 'Temryuk', value: 'Temryuk' },
  { label: 'Temse', value: 'Temse' },
  { label: 'Temù', value: 'Temù' },
  { label: 'Temuco', value: 'Temuco' },
  { label: 'Temyasovo', value: 'Temyasovo' },
  { label: 'Ten Boer', value: 'Ten Boer' },
  { label: 'Ten Mile Run', value: 'Ten Mile Run' },
  { label: 'Ten’Gushevo', value: 'Ten’Gushevo' },
  { label: 'Ten’Gushevskiy Rayon', value: 'Ten’Gushevskiy Rayon' },
  { label: 'Tena', value: 'Tena' },
  { label: 'Tena', value: 'Tena' },
  { label: 'Tenabo', value: 'Tenabo' },
  { label: 'Tenafly', value: 'Tenafly' },
  { label: 'Tenaha', value: 'Tenaha' },
  { label: 'Tenamaxtlán', value: 'Tenamaxtlán' },
  { label: 'Tenambit', value: 'Tenambit' },
  { label: 'Tenampa', value: 'Tenampa' },
  { label: 'Tenampulco', value: 'Tenampulco' },
  { label: 'Tenancingo', value: 'Tenancingo' },
  { label: 'Tenancingo', value: 'Tenancingo' },
  { label: 'Tenancingo', value: 'Tenancingo' },
  { label: 'Tenancingo De Degollado', value: 'Tenancingo De Degollado' },
  { label: 'Tenango', value: 'Tenango' },
  { label: 'Tenango', value: 'Tenango' },
  { label: 'Tenango', value: 'Tenango' },
  { label: 'Tenango De Arista', value: 'Tenango De Arista' },
  { label: 'Tenango De Doria', value: 'Tenango De Doria' },
  { label: 'Tenango De Las Flores', value: 'Tenango De Las Flores' },
  { label: 'Tenango Del Aire', value: 'Tenango Del Aire' },
  { label: 'Tenango El Nuevo', value: 'Tenango El Nuevo' },
  { label: 'Tenantitla', value: 'Tenantitla' },
  { label: 'Tenares', value: 'Tenares' },
  { label: 'Tenay', value: 'Tenay' },
  { label: 'Tenayuca', value: 'Tenayuca' },
  { label: 'Tenbury Wells', value: 'Tenbury Wells' },
  { label: 'Tenby', value: 'Tenby' },
  { label: 'Tencarola', value: 'Tencarola' },
  { label: 'Tence', value: 'Tence' },
  { label: 'Tencin', value: 'Tencin' },
  { label: 'Tenczynek', value: 'Tenczynek' },
  { label: 'Tende', value: 'Tende' },
  { label: 'Tendilla', value: 'Tendilla' },
  { label: 'Tendō', value: 'Tendō' },
  { label: 'Tendō Shi', value: 'Tendō Shi' },
  { label: 'Tendu', value: 'Tendu' },
  { label: 'Tendukheda', value: 'Tendukheda' },
  { label: 'Tenebrón', value: 'Tenebrón' },
  { label: 'Tenenexpan', value: 'Tenenexpan' },
  { label: 'Ténenkou', value: 'Ténenkou' },
  { label: 'Tenente Ananias', value: 'Tenente Ananias' },
  { label: 'Tenente Laurentino Cruz', value: 'Tenente Laurentino Cruz' },
  { label: 'Tenente Portela', value: 'Tenente Portela' },
  { label: 'Tenería', value: 'Tenería' },
  { label: 'Tenería Del Santuario', value: 'Tenería Del Santuario' },
  { label: 'Tenerife', value: 'Tenerife' },
  { label: 'Teneriffe', value: 'Teneriffe' },
  { label: 'Tenexio', value: 'Tenexio' },
  { label: 'Tenexpa', value: 'Tenexpa' },
  { label: 'Tenextepango', value: 'Tenextepango' },
  { label: 'Tenezara', value: 'Tenezara' },
  { label: 'Tengelic', value: 'Tengelic' },
  { label: 'Tengen', value: 'Tengen' },
  { label: 'Tengnoupal', value: 'Tengnoupal' },
  { label: 'Tengüecho', value: 'Tengüecho' },
  { label: 'Tenhult', value: 'Tenhult' },
  { label: 'Teningen', value: 'Teningen' },
  { label: 'Tenino', value: 'Tenino' },
  { label: 'Tenira', value: 'Tenira' },
  { label: 'Tenistoye', value: 'Tenistoye' },
  { label: 'Tenixtepec', value: 'Tenixtepec' },
  { label: 'Tenja', value: 'Tenja' },
  { label: 'Tenjay', value: 'Tenjay' },
  { label: 'Tenjo', value: 'Tenjo' },
  { label: 'Tenkodogo', value: 'Tenkodogo' },
  { label: 'Tenna', value: 'Tenna' },
  { label: 'Tennant Creek', value: 'Tennant Creek' },
  { label: 'Tennenbronn', value: 'Tennenbronn' },
  { label: 'Tennessee Ridge', value: 'Tennessee Ridge' },
  { label: 'Tenneville', value: 'Tenneville' },
  { label: 'Tennie', value: 'Tennie' },
  { label: 'Tennille', value: 'Tennille' },
  { label: 'Tenno', value: 'Tenno' },
  { label: 'Tennō', value: 'Tennō' },
  { label: 'Tennyson', value: 'Tennyson' },
  { label: 'Tennyson Point', value: 'Tennyson Point' },
  { label: 'Teno', value: 'Teno' },
  { label: 'Tenochtitlán', value: 'Tenochtitlán' },
  { label: 'Tenório', value: 'Tenório' },
  { label: 'Tenosique', value: 'Tenosique' },
  { label: 'Tenri', value: 'Tenri' },
  { label: 'Tenri-Shi', value: 'Tenri-Shi' },
  { label: 'Tensas Parish', value: 'Tensas Parish' },
  { label: 'Tensta', value: 'Tensta' },
  { label: 'Tenteling', value: 'Tenteling' },
  { label: 'Tenterden', value: 'Tenterden' },
  { label: 'Tenterfield', value: 'Tenterfield' },
  { label: 'Tenterfield Municipality', value: 'Tenterfield Municipality' },
  { label: 'Tentic', value: 'Tentic' },
  { label: 'Tenza', value: 'Tenza' },
  { label: 'Teo', value: 'Teo' },
  { label: 'Teocalco', value: 'Teocalco' },
  { label: 'Teocaltiche', value: 'Teocaltiche' },
  { label: 'Teocelo', value: 'Teocelo' },
  { label: 'Teococuilco De Marcos Pérez', value: 'Teococuilco De Marcos Pérez' },
  { label: 'Teocuitatlán De Corona', value: 'Teocuitatlán De Corona' },
  { label: 'Teodoro Sampaio', value: 'Teodoro Sampaio' },
  { label: 'Teodoro Sampaio', value: 'Teodoro Sampaio' },
  { label: 'Teodoro Schmidt', value: 'Teodoro Schmidt' },
  { label: 'Teofani', value: 'Teofani' },
  { label: 'Teofilândia', value: 'Teofilândia' },
  { label: 'Teófilo Otoni', value: 'Teófilo Otoni' },
  { label: 'Teojomulco', value: 'Teojomulco' },
  { label: 'Teolândia', value: 'Teolândia' },
  { label: 'Teolocholco', value: 'Teolocholco' },
  { label: 'Teoloyucan', value: 'Teoloyucan' },
  { label: 'Teontepec', value: 'Teontepec' },
  { label: 'Teonthar', value: 'Teonthar' },
  { label: 'Teopantlán', value: 'Teopantlán' },
  { label: 'Teopisca', value: 'Teopisca' },
  { label: 'Teor', value: 'Teor' },
  { label: 'Teora', value: 'Teora' },
  { label: 'Teorama', value: 'Teorama' },
  { label: 'Teotihuacán', value: 'Teotihuacán' },
  { label: 'Teotihuacán De Arista', value: 'Teotihuacán De Arista' },
  { label: 'Teotitlán', value: 'Teotitlán' },
  { label: 'Teotitlán Del Valle', value: 'Teotitlán Del Valle' },
  { label: 'Teotlalco', value: 'Teotlalco' },
  { label: 'Teotlaltzingo', value: 'Teotlaltzingo' },
  { label: 'Teotônio Vilela', value: 'Teotônio Vilela' },
  { label: 'Tepache', value: 'Tepache' },
  { label: 'Tepakan', value: 'Tepakan' },
  { label: 'Tepakán', value: 'Tepakán' },
  { label: 'Tepalcatepec', value: 'Tepalcatepec' },
  { label: 'Tepalcatepec', value: 'Tepalcatepec' },
  { label: 'Tepalcingo', value: 'Tepalcingo' },
  { label: 'Tepanco', value: 'Tepanco' },
  { label: 'Tepango', value: 'Tepango' },
  { label: 'Tepango De Rodríguez', value: 'Tepango De Rodríguez' },
  { label: 'Tepanguare', value: 'Tepanguare' },
  { label: 'Tepapayeca', value: 'Tepapayeca' },
  { label: 'Tepatepec', value: 'Tepatepec' },
  { label: 'Tepatitlán De Morelos', value: 'Tepatitlán De Morelos' },
  { label: 'Tepatlaxco', value: 'Tepatlaxco' },
  { label: 'Tepatlaxco De Hidalgo', value: 'Tepatlaxco De Hidalgo' },
  { label: 'Tepazolco', value: 'Tepazolco' },
  { label: 'Tepeaca', value: 'Tepeaca' },
  { label: 'Tepeapulco', value: 'Tepeapulco' },
  { label: 'Tepebaşı', value: 'Tepebaşı' },
  { label: 'Tepec', value: 'Tepec' },
  { label: 'Tepechicotlán', value: 'Tepechicotlán' },
  { label: 'Tepechitlán', value: 'Tepechitlán' },
  { label: 'Tepecoacuilco De Trujano', value: 'Tepecoacuilco De Trujano' },
  { label: 'Tepehuacán De Guerrero', value: 'Tepehuacán De Guerrero' },
  { label: 'Tepehuanes', value: 'Tepehuanes' },
  { label: 'Tepeitic', value: 'Tepeitic' },
  { label: 'Tepeji Del Río De Ocampo', value: 'Tepeji Del Río De Ocampo' },
  { label: 'Tepelenë', value: 'Tepelenë' },
  { label: 'Tepenixtlahuaca', value: 'Tepenixtlahuaca' },
  { label: 'Tepeojuma', value: 'Tepeojuma' },
  { label: 'Tepepa', value: 'Tepepa' },
  { label: 'Tepetates', value: 'Tepetates' },
  { label: 'Tepeteno De Iturbide', value: 'Tepeteno De Iturbide' },
  { label: 'Tepetitán', value: 'Tepetitán' },
  { label: 'Tepetitán Reyeshogpan De Hidalgo', value: 'Tepetitán Reyeshogpan De Hidalgo' },
  { label: 'Tepetitla', value: 'Tepetitla' },
  { label: 'Tepetitla', value: 'Tepetitla' },
  { label: 'Tepetitlán', value: 'Tepetitlán' },
  { label: 'Tepetitlán', value: 'Tepetitlán' },
  { label: 'Tepetixtla', value: 'Tepetixtla' },
  { label: 'Tepetlacolco', value: 'Tepetlacolco' },
  { label: 'Tepetlán', value: 'Tepetlán' },
  { label: 'Tepetlaoxtoc', value: 'Tepetlaoxtoc' },
  { label: 'Tepetlaoxtoc De Hidalgo', value: 'Tepetlaoxtoc De Hidalgo' },
  { label: 'Tepetlapa', value: 'Tepetlapa' },
  { label: 'Tepetlixpa', value: 'Tepetlixpa' },
  { label: 'Tepetongo', value: 'Tepetongo' },
  { label: 'Tepetongo', value: 'Tepetongo' },
  { label: 'Tepetzingo', value: 'Tepetzingo' },
  { label: 'Tepetzingo', value: 'Tepetzingo' },
  { label: 'Tepetzingo', value: 'Tepetzingo' },
  { label: 'Tepetzintla', value: 'Tepetzintla' },
  { label: 'Tepetzintla', value: 'Tepetzintla' },
  { label: 'Tepetzitzintla', value: 'Tepetzitzintla' },
  { label: 'Tepexco', value: 'Tepexco' },
  { label: 'Tepexi De Rodríguez', value: 'Tepexi De Rodríguez' },
  { label: 'Tepexititla', value: 'Tepexititla' },
  { label: 'Tepexpan', value: 'Tepexpan' },
  { label: 'Tepeyahualco', value: 'Tepeyahualco' },
  { label: 'Tepeyahualco De Cuauhtémoc', value: 'Tepeyahualco De Cuauhtémoc' },
  { label: 'Tepeyanco', value: 'Tepeyanco' },
  { label: 'Tepeyehualco De Hidalgo', value: 'Tepeyehualco De Hidalgo' },
  { label: 'Tepezalá', value: 'Tepezalá' },
  { label: 'Tepic', value: 'Tepic' },
  { label: 'Tepich', value: 'Tepich' },
  { label: 'Tëplaya Gora', value: 'Tëplaya Gora' },
  { label: 'Teple', value: 'Teple' },
  { label: 'Teplice', value: 'Teplice' },
  { label: 'Teplice Nad Metují', value: 'Teplice Nad Metují' },
  { label: 'Teplodar', value: 'Teplodar' },
  { label: 'Tëplo-Ogarëvskiy Rayon', value: 'Tëplo-Ogarëvskiy Rayon' },
  { label: 'Teplovka', value: 'Teplovka' },
  { label: 'Tëploye', value: 'Tëploye' },
  { label: 'Teplyk', value: 'Teplyk' },
  { label: 'Tepojaco', value: 'Tepojaco' },
  { label: 'Tepotzotlán', value: 'Tepotzotlán' },
  { label: 'Tepoxtepec', value: 'Tepoxtepec' },
  { label: 'Tepozcuautla', value: 'Tepozcuautla' },
  { label: 'Tepoztlán', value: 'Tepoztlán' },
  { label: 'Ţepu', value: 'Ţepu' },
  { label: 'Tepusco', value: 'Tepusco' },
  { label: 'Tepusteca', value: 'Tepusteca' },
  { label: 'Tepuxtepec', value: 'Tepuxtepec' },
  { label: 'Tequepexpan', value: 'Tequepexpan' },
  { label: 'Tequesquitengo', value: 'Tequesquitengo' },
  { label: 'Tequesquitlán', value: 'Tequesquitlán' },
  { label: 'Tequesta', value: 'Tequesta' },
  { label: 'Tequexquináhuac', value: 'Tequexquináhuac' },
  { label: 'Tequicuilco', value: 'Tequicuilco' },
  { label: 'Tequila', value: 'Tequila' },
  { label: 'Tequila', value: 'Tequila' },
  { label: 'Tequisistlán', value: 'Tequisistlán' },
  { label: 'Tequisistlán Primero', value: 'Tequisistlán Primero' },
  { label: 'Tequisquiapan', value: 'Tequisquiapan' },
  { label: 'Tequisquiapan', value: 'Tequisquiapan' },
  { label: 'Tequixquiac', value: 'Tequixquiac' },
  { label: 'Ter Apel', value: 'Ter Apel' },
  { label: 'Téra', value: 'Téra' },
  { label: 'Tera Department', value: 'Tera Department' },
  { label: 'Teraina', value: 'Teraina' },
  { label: 'Teralba', value: 'Teralba' },
  { label: 'Teramo', value: 'Teramo' },
  { label: 'Terang', value: 'Terang' },
  { label: 'Terbanggi Besar', value: 'Terbanggi Besar' },
  { label: 'Terborg', value: 'Terborg' },
  { label: 'Terbregge', value: 'Terbregge' },
  { label: 'Terbunskiy Rayon', value: 'Terbunskiy Rayon' },
  { label: 'Terbuny', value: 'Terbuny' },
  { label: 'Tercan', value: 'Tercan' },
  { label: 'Tercé', value: 'Tercé' },
  { label: 'Tercera Manzana De Zaragoza', value: 'Tercera Manzana De Zaragoza' },
  { label: 'Terchová', value: 'Terchová' },
  { label: 'Tercis-Les-Bains', value: 'Tercis-Les-Bains' },
  { label: 'Terdal', value: 'Terdal' },
  { label: 'Terdiek', value: 'Terdiek' },
  { label: 'Terdobbiate', value: 'Terdobbiate' },
  { label: 'Terebeşti', value: 'Terebeşti' },
  { label: 'Terebovlya', value: 'Terebovlya' },
  { label: 'Teregova', value: 'Teregova' },
  { label: 'Terek', value: 'Terek' },
  { label: 'Terekli-Mekteb', value: 'Terekli-Mekteb' },
  { label: 'Terelle', value: 'Terelle' },
  { label: 'Teremendo', value: 'Teremendo' },
  { label: 'Teremia Mare', value: 'Teremia Mare' },
  { label: 'Teren’Gul’Skiy Rayon', value: 'Teren’Gul’Skiy Rayon' },
  { label: 'Terenos', value: 'Terenos' },
  { label: 'Terenozek', value: 'Terenozek' },
  { label: 'Terento', value: 'Terento' },
  { label: 'Terenure', value: 'Terenure' },
  { label: 'Terenzo', value: 'Terenzo' },
  { label: 'Teresa', value: 'Teresa' },
  { label: 'Teresa', value: 'Teresa' },
  { label: 'Teresa De Cofrentes', value: 'Teresa De Cofrentes' },
  { label: 'Teresin', value: 'Teresin' },
  { label: 'Teresina', value: 'Teresina' },
  { label: 'Teresina De Goiás', value: 'Teresina De Goiás' },
  { label: 'Teresita', value: 'Teresita' },
  { label: 'Teresita', value: 'Teresita' },
  { label: 'Teresópolis', value: 'Teresópolis' },
  { label: 'Terespol', value: 'Terespol' },
  { label: 'Tereszpol', value: 'Tereszpol' },
  { label: 'Tereze', value: 'Tereze' },
  { label: 'Terezín', value: 'Terezín' },
  { label: 'Terezinha', value: 'Terezinha' },
  { label: 'Terezópolis De Goiás', value: 'Terezópolis De Goiás' },
  { label: 'Terfens', value: 'Terfens' },
  { label: 'Tergnier', value: 'Tergnier' },
  { label: 'Tergu', value: 'Tergu' },
  { label: 'Terha', value: 'Terha' },
  { label: 'Terhathum', value: 'Terhathum' },
  { label: 'Terheijden', value: 'Terheijden' },
  { label: 'Teribërka', value: 'Teribërka' },
  { label: 'Terkoest', value: 'Terkoest' },
  { label: 'Terlago', value: 'Terlago' },
  { label: 'Terlano', value: 'Terlano' },
  { label: 'Terlizzi', value: 'Terlizzi' },
  { label: 'Termal', value: 'Termal' },
  { label: 'Termas De Río Hondo', value: 'Termas De Río Hondo' },
  { label: 'Terme', value: 'Terme' },
  { label: 'Terme', value: 'Terme' },
  { label: 'Termeno', value: 'Termeno' },
  { label: 'Térmens', value: 'Térmens' },
  { label: 'Termianak', value: 'Termianak' },
  { label: 'Terminal De Providencia', value: 'Terminal De Providencia' },
  { label: 'Termini Imerese', value: 'Termini Imerese' },
  { label: 'Termoli', value: 'Termoli' },
  { label: 'Termonfeckin', value: 'Termonfeckin' },
  { label: 'Ternaard', value: 'Ternaard' },
  { label: 'Ternat', value: 'Ternat' },
  { label: 'Ternate', value: 'Ternate' },
  { label: 'Ternate', value: 'Ternate' },
  { label: 'Ternate', value: 'Ternate' },
  { label: 'Ternay', value: 'Ternay' },
  { label: 'Ternberg', value: 'Ternberg' },
  { label: 'Ternengo', value: 'Ternengo' },
  { label: 'Terneuzen', value: 'Terneuzen' },
  { label: 'Ternevskaya', value: 'Ternevskaya' },
  { label: 'Terney', value: 'Terney' },
  { label: 'Terni', value: 'Terni' },
  { label: 'Ternitz', value: 'Ternitz' },
  { label: 'Terno Disola', value: 'Terno Disola' },
  { label: 'Ternopil', value: 'Ternopil' },
  { label: 'Ternovka', value: 'Ternovka' },
  { label: 'Ternovka', value: 'Ternovka' },
  { label: 'Ternovskiy Rayon', value: 'Ternovskiy Rayon' },
  { label: 'Ternuvate', value: 'Ternuvate' },
  { label: 'Terny', value: 'Terny' },
  { label: 'Terontola', value: 'Terontola' },
  { label: 'Teror', value: 'Teror' },
  { label: 'Teroual', value: 'Teroual' },
  { label: 'Terpeziţa', value: 'Terpeziţa' },
  { label: 'Terpinnya', value: 'Terpinnya' },
  { label: 'Terpní', value: 'Terpní' },
  { label: 'Terque', value: 'Terque' },
  { label: 'Terra Alta', value: 'Terra Alta' },
  { label: 'Terra Alta', value: 'Terra Alta' },
  { label: 'Terra Bella', value: 'Terra Bella' },
  { label: 'Terra Boa', value: 'Terra Boa' },
  { label: 'Terra De Areia', value: 'Terra De Areia' },
  { label: 'Terra Mar', value: 'Terra Mar' },
  { label: 'Terra Nova', value: 'Terra Nova' },
  { label: 'Terra Nova', value: 'Terra Nova' },
  { label: 'Terra Nova Do Norte', value: 'Terra Nova Do Norte' },
  { label: 'Terra Rica', value: 'Terra Rica' },
  { label: 'Terra Roxa', value: 'Terra Roxa' },
  { label: 'Terra Roxa', value: 'Terra Roxa' },
  { label: 'Terra Santa', value: 'Terra Santa' },
  { label: 'Terrabona', value: 'Terrabona' },
  { label: 'Terrace', value: 'Terrace' },
  { label: 'Terrace Heights', value: 'Terrace Heights' },
  { label: 'Terrace Heights', value: 'Terrace Heights' },
  { label: 'Terrace Park', value: 'Terrace Park' },
  { label: 'Terracina', value: 'Terracina' },
  { label: 'Terrades', value: 'Terrades' },
  { label: 'Terradillos', value: 'Terradillos' },
  { label: 'Terradillos De Esgueva', value: 'Terradillos De Esgueva' },
  { label: 'Terradura', value: 'Terradura' },
  { label: 'Terragnolo', value: 'Terragnolo' },
  { label: 'Terråk', value: 'Terråk' },
  { label: 'Terralba', value: 'Terralba' },
  { label: 'Terramuggus', value: 'Terramuggus' },
  { label: 'Terranora', value: 'Terranora' },
  { label: 'Terranova Da Sibari', value: 'Terranova Da Sibari' },
  { label: 'Terranova Dei Passerini', value: 'Terranova Dei Passerini' },
  { label: 'Terranova Di Pollino', value: 'Terranova Di Pollino' },
  { label: 'Terranova Sappo Minulio', value: 'Terranova Sappo Minulio' },
  { label: 'Terranuova Bracciolini', value: 'Terranuova Bracciolini' },
  { label: 'Terrarossa', value: 'Terrarossa' },
  { label: 'Terras De Bouro', value: 'Terras De Bouro' },
  { label: 'Terrasini', value: 'Terrasini' },
  { label: 'Terrassa', value: 'Terrassa' },
  { label: 'Terrassa Padovana', value: 'Terrassa Padovana' },
  { label: 'Terrasse-Des-Pins', value: 'Terrasse-Des-Pins' },
  { label: 'Terrasson-Lavilledieu', value: 'Terrasson-Lavilledieu' },
  { label: 'Terrateig', value: 'Terrateig' },
  { label: 'Terravecchia', value: 'Terravecchia' },
  { label: 'Terraverde-Corte Palasio', value: 'Terraverde-Corte Palasio' },
  { label: 'Terrazas Del Valle', value: 'Terrazas Del Valle' },
  { label: 'Terrazzo', value: 'Terrazzo' },
  { label: 'Terre Du Lac', value: 'Terre Du Lac' },
  { label: 'Terre Haute', value: 'Terre Haute' },
  { label: 'Terre Haute', value: 'Terre Haute' },
  { label: 'Terre Hill', value: 'Terre Hill' },
  { label: 'Terre Rouge', value: 'Terre Rouge' },
  { label: 'Terrebonne', value: 'Terrebonne' },
  { label: 'Terrebonne', value: 'Terrebonne' },
  { label: 'Terrebonne Parish', value: 'Terrebonne Parish' },
  { label: 'Terrell', value: 'Terrell' },
  { label: 'Terrell County', value: 'Terrell County' },
  { label: 'Terrell County', value: 'Terrell County' },
  { label: 'Terrell Hills', value: 'Terrell Hills' },
  { label: 'Terrenate', value: 'Terrenate' },
  { label: 'Terrenho', value: 'Terrenho' },
  { label: 'Terrer', value: 'Terrer' },
  { label: 'Terres', value: 'Terres' },
  { label: 'Terricciola', value: 'Terricciola' },
  { label: 'Terriente', value: 'Terriente' },
  { label: 'Terrigal', value: 'Terrigal' },
  { label: 'Terrinches', value: 'Terrinches' },
  { label: 'Terrington Saint John', value: 'Terrington Saint John' },
  { label: 'Terrington St Clement', value: 'Terrington St Clement' },
  { label: 'Territoire De Belfort', value: 'Territoire De Belfort' },
  { label: 'Terroba', value: 'Terroba' },
  { label: 'Terrossa', value: 'Terrossa' },
  { label: 'Terrugem', value: 'Terrugem' },
  { label: 'Terrugem', value: 'Terrugem' },
  { label: 'Terruggia', value: 'Terruggia' },
  { label: 'Terry', value: 'Terry' },
  { label: 'Terry', value: 'Terry' },
  { label: 'Terry County', value: 'Terry County' },
  { label: 'Terry Hills', value: 'Terry Hills' },
  { label: 'Terrytown', value: 'Terrytown' },
  { label: 'Terrytown', value: 'Terrytown' },
  { label: 'Terryville', value: 'Terryville' },
  { label: 'Terryville', value: 'Terryville' },
  { label: 'Tersa', value: 'Tersa' },
  { label: 'Terschuur', value: 'Terschuur' },
  { label: 'Tersefánou', value: 'Tersefánou' },
  { label: 'Terskaya', value: 'Terskaya' },
  { label: 'Terskiy Rayon', value: 'Terskiy Rayon' },
  { label: 'Terskiy Rayon', value: 'Terskiy Rayon' },
  { label: 'Terskol', value: 'Terskol' },
  { label: 'Tertenia', value: 'Tertenia' },
  { label: 'Terter', value: 'Terter' },
  { label: 'Teruel', value: 'Teruel' },
  { label: 'Teruel', value: 'Teruel' },
  { label: 'Tervakoski', value: 'Tervakoski' },
  { label: 'Tervel', value: 'Tervel' },
  { label: 'Tērvete', value: 'Tērvete' },
  { label: 'Terville', value: 'Terville' },
  { label: 'Tervola', value: 'Tervola' },
  { label: 'Tervuren', value: 'Tervuren' },
  { label: 'Terwinselen', value: 'Terwinselen' },
  { label: 'Terwolde', value: 'Terwolde' },
  { label: 'Teryayevo', value: 'Teryayevo' },
  { label: 'Terzaga', value: 'Terzaga' },
  { label: 'Terzigno', value: 'Terzigno' },
  { label: 'Terzo', value: 'Terzo' },
  { label: 'Terzo Daquileia', value: 'Terzo Daquileia' },
  { label: 'Terzolas', value: 'Terzolas' },
  { label: 'Terzorio', value: 'Terzorio' },
  { label: 'Tesalia', value: 'Tesalia' },
  { label: 'Tešanj', value: 'Tešanj' },
  { label: 'Tešanjka', value: 'Tešanjka' },
  { label: 'Těšany', value: 'Těšany' },
  { label: 'Tesechoacan', value: 'Tesechoacan' },
  { label: 'Teseney', value: 'Teseney' },
  { label: 'Tesero', value: 'Tesero' },
  { label: 'Těšetice', value: 'Těšetice' },
  { label: 'Teshi Old Town', value: 'Teshi Old Town' },
  { label: 'Teșila', value: 'Teșila' },
  { label: 'Tesimo', value: 'Tesimo' },
  { label: 'Tesistán', value: 'Tesistán' },
  { label: 'Teslić', value: 'Teslić' },
  { label: 'Teslui', value: 'Teslui' },
  { label: 'Teslui', value: 'Teslui' },
  { label: 'Tesoco', value: 'Tesoco' },
  { label: 'Tesouro', value: 'Tesouro' },
  { label: 'Tespe', value: 'Tespe' },
  { label: 'Tessala', value: 'Tessala' },
  { label: 'Tessaoua', value: 'Tessaoua' },
  { label: 'Tessé-La-Madeleine', value: 'Tessé-La-Madeleine' },
  { label: 'Tessenderlo', value: 'Tessenderlo' },
  { label: 'Tessennano', value: 'Tessennano' },
  { label: 'Tessera', value: 'Tessera' },
  { label: 'Tesserete', value: 'Tesserete' },
  { label: 'Tessin', value: 'Tessin' },
  { label: 'Tessy-Sur-Vire', value: 'Tessy-Sur-Vire' },
  { label: 'Testerazo', value: 'Testerazo' },
  { label: 'Testico', value: 'Testico' },
  { label: 'Testour', value: 'Testour' },
  { label: 'Tét', value: 'Tét' },
  { label: 'Tétange', value: 'Tétange' },
  { label: 'Tetbury', value: 'Tetbury' },
  { label: 'Tețcoiu', value: 'Tețcoiu' },
  { label: 'Tete', value: 'Tete' },
  { label: 'Tete Chemin', value: 'Tete Chemin' },
  { label: 'Tete Morne', value: 'Tete Morne' },
  { label: 'Tete Morne/Morne Andrew', value: 'Tete Morne/Morne Andrew' },
  { label: 'Tetecala', value: 'Tetecala' },
  { label: 'Tetecalita', value: 'Tetecalita' },
  { label: 'Tetecolala', value: 'Tetecolala' },
  { label: 'Téteghem', value: 'Téteghem' },
  { label: 'Tetela', value: 'Tetela' },
  { label: 'Tetela De Ocampo', value: 'Tetela De Ocampo' },
  { label: 'Tetela Del Volcán', value: 'Tetela Del Volcán' },
  { label: 'Tetelcingo', value: 'Tetelcingo' },
  { label: 'Tetelco', value: 'Tetelco' },
  { label: 'Teteles De Avila Castillo', value: 'Teteles De Avila Castillo' },
  { label: 'Tetelilla', value: 'Tetelilla' },
  { label: 'Teteltzingo', value: 'Teteltzingo' },
  { label: 'Tetepango', value: 'Tetepango' },
  { label: 'Teteringen', value: 'Teteringen' },
  { label: 'Teterow', value: 'Teterow' },
  { label: 'Teteven', value: 'Teteven' },
  { label: 'Teti', value: 'Teti' },
  { label: 'Téti Járás', value: 'Téti Járás' },
  { label: 'Tetiiv', value: 'Tetiiv' },
  { label: 'Tetillas', value: 'Tetillas' },
  { label: 'Teting-Sur-Nied', value: 'Teting-Sur-Nied' },
  { label: 'Tetipac', value: 'Tetipac' },
  { label: 'Tetitlán', value: 'Tetitlán' },
  { label: 'Tetitlán', value: 'Tetitlán' },
  { label: 'Tetiz', value: 'Tetiz' },
  { label: 'Tetla', value: 'Tetla' },
  { label: 'Tetla', value: 'Tetla' },
  { label: 'Tetlanohcán', value: 'Tetlanohcán' },
  { label: 'Tetlatzinga', value: 'Tetlatzinga' },
  { label: 'Tetlaxco', value: 'Tetlaxco' },
  { label: 'Tetney', value: 'Tetney' },
  { label: 'Tetoiu', value: 'Tetoiu' },
  { label: 'Teton County', value: 'Teton County' },
  { label: 'Teton County', value: 'Teton County' },
  { label: 'Teton County', value: 'Teton County' },
  { label: 'Tetouan', value: 'Tetouan' },
  { label: 'Tetovo', value: 'Tetovo' },
  { label: 'Tetrits’Q’Alos Munitsip’Alit’Et’I', value: 'Tetrits’Q’Alos Munitsip’Alit’Et’I' },
  { label: 'Tettau', value: 'Tettau' },
  { label: 'Tettenweis', value: 'Tettenweis' },
  { label: 'Tetti Neirotti', value: 'Tetti Neirotti' },
  { label: 'Tettnang', value: 'Tettnang' },
  { label: 'Tettorosso', value: 'Tettorosso' },
  { label: 'Tetuán De Las Victorias', value: 'Tetuán De Las Victorias' },
  { label: 'Tetyushi', value: 'Tetyushi' },
  { label: 'Tetyushskiy Rayon', value: 'Tetyushskiy Rayon' },
  { label: 'Tetzacual', value: 'Tetzacual' },
  { label: 'Teublitz', value: 'Teublitz' },
  { label: 'Teuchel', value: 'Teuchel' },
  { label: 'Teuchern', value: 'Teuchern' },
  { label: 'Teuchezhskiy Rayon', value: 'Teuchezhskiy Rayon' },
  { label: 'Teuchitlán Jalisco', value: 'Teuchitlán Jalisco' },
  { label: 'Teufen', value: 'Teufen' },
  { label: 'Teufenbach', value: 'Teufenbach' },
  { label: 'Teufenthal', value: 'Teufenthal' },
  { label: 'Teugn', value: 'Teugn' },
  { label: 'Teúl De González Ortega', value: 'Teúl De González Ortega' },
  { label: 'Teulada', value: 'Teulada' },
  { label: 'Teulada', value: 'Teulada' },
  { label: 'Teunz', value: 'Teunz' },
  { label: 'Teupasenti', value: 'Teupasenti' },
  { label: 'Teupitz', value: 'Teupitz' },
  { label: 'Teuschnitz', value: 'Teuschnitz' },
  { label: 'Teustepe', value: 'Teustepe' },
  { label: 'Teutônia', value: 'Teutônia' },
  { label: 'Teutopolis', value: 'Teutopolis' },
  { label: 'Teutschenthal', value: 'Teutschenthal' },
  { label: 'Teuva', value: 'Teuva' },
  { label: 'Teverga', value: 'Teverga' },
  { label: 'Teverola', value: 'Teverola' },
  { label: 'Teviston', value: 'Teviston' },
  { label: 'Tevragh-Zeina', value: 'Tevragh-Zeina' },
  { label: 'Tevriz', value: 'Tevriz' },
  { label: 'Tewai Siassi', value: 'Tewai Siassi' },
  { label: 'Tewantin', value: 'Tewantin' },
  { label: 'Tewkesbury', value: 'Tewkesbury' },
  { label: 'Tewksbury', value: 'Tewksbury' },
  { label: 'Texalpan De Abajo', value: 'Texalpan De Abajo' },
  { label: 'Texalpan De Arriba', value: 'Texalpan De Arriba' },
  { label: 'Texan De Palomeque', value: 'Texan De Palomeque' },
  { label: 'Texanna', value: 'Texanna' },
  { label: 'Texarkana', value: 'Texarkana' },
  { label: 'Texarkana', value: 'Texarkana' },
  { label: 'Texas City', value: 'Texas City' },
  { label: 'Texas County', value: 'Texas County' },
  { label: 'Texas County', value: 'Texas County' },
  { label: 'Texca', value: 'Texca' },
  { label: 'Texcaco', value: 'Texcaco' },
  { label: 'Texcala', value: 'Texcala' },
  { label: 'Texcala', value: 'Texcala' },
  { label: 'Texcalapa De Juárez', value: 'Texcalapa De Juárez' },
  { label: 'Texcaltepec', value: 'Texcaltepec' },
  { label: 'Texcaltic', value: 'Texcaltic' },
  { label: 'Texcaltitán Xoteapan (Texcaltitán)', value: 'Texcaltitán Xoteapan (Texcaltitán)' },
  { label: 'Texcaltitlán', value: 'Texcaltitlán' },
  { label: 'Texcalyacac', value: 'Texcalyacac' },
  { label: 'Texcapa', value: 'Texcapa' },
  { label: 'Texcapilla', value: 'Texcapilla' },
  { label: 'Texcatepec', value: 'Texcatepec' },
  { label: 'Texcatepec', value: 'Texcatepec' },
  { label: 'Texcatitlán', value: 'Texcatitlán' },
  { label: 'Texcoco De Mora', value: 'Texcoco De Mora' },
  { label: 'Texel', value: 'Texel' },
  { label: 'Texhuacán', value: 'Texhuacán' },
  { label: 'Texico', value: 'Texico' },
  { label: 'Texíguat', value: 'Texíguat' },
  { label: 'Texin', value: 'Texin' },
  { label: 'Texistepec', value: 'Texistepec' },
  { label: 'Texistepeque', value: 'Texistepeque' },
  { label: 'Texmelucan', value: 'Texmelucan' },
  { label: 'Texmola', value: 'Texmola' },
  { label: 'Texocoyohuac', value: 'Texocoyohuac' },
  { label: 'Teya', value: 'Teya' },
  { label: 'Teya', value: 'Teya' },
  { label: 'Teyarett', value: 'Teyarett' },
  { label: 'Teyateyaneng', value: 'Teyateyaneng' },
  { label: 'Teykovo', value: 'Teykovo' },
  { label: 'Teykovskiy Rayon', value: 'Teykovskiy Rayon' },
  { label: 'Teymourlou', value: 'Teymourlou' },
  { label: 'Teynham', value: 'Teynham' },
  { label: 'Teyran', value: 'Teyran' },
  { label: 'Tezapotla', value: 'Tezapotla' },
  { label: 'Tezhuatepec', value: 'Tezhuatepec' },
  { label: 'Teziutlan', value: 'Teziutlan' },
  { label: 'Tezoatlán De Segura Y Luna', value: 'Tezoatlán De Segura Y Luna' },
  { label: 'Tezonapa', value: 'Tezonapa' },
  { label: 'Tezonteopan De Bonilla', value: 'Tezonteopan De Bonilla' },
  { label: 'Tezontepec', value: 'Tezontepec' },
  { label: 'Tezontepec De Aldama', value: 'Tezontepec De Aldama' },
  { label: 'Tezoquipa', value: 'Tezoquipa' },
  { label: 'Tezoyuca', value: 'Tezoyuca' },
  { label: 'Tezoyuca', value: 'Tezoyuca' },
  { label: 'Tezpur', value: 'Tezpur' },
  { label: 'Tezu', value: 'Tezu' },
  { label: 'Tezze', value: 'Tezze' },
  { label: 'Tha Bo', value: 'Tha Bo' },
  { label: 'Tha Kham', value: 'Tha Kham' },
  { label: 'Tha Khanon', value: 'Tha Khanon' },
  { label: 'Tha Mai', value: 'Tha Mai' },
  { label: 'Tha Maka', value: 'Tha Maka' },
  { label: 'Tha Muang', value: 'Tha Muang' },
  { label: 'Tha Ruea', value: 'Tha Ruea' },
  { label: 'Tha Yang', value: 'Tha Yang' },
  { label: 'Thaba Nchu', value: 'Thaba Nchu' },
  { label: 'Thaba-Tseka', value: 'Thaba-Tseka' },
  { label: 'Thabazimbi', value: 'Thabazimbi' },
  { label: 'Thabeban', value: 'Thabeban' },
  { label: 'Thabo Mofutsanyana District Municipality', value: 'Thabo Mofutsanyana District Municipality' },
  { label: 'Thái Bình', value: 'Thái Bình' },
  { label: 'Thái Nguyên', value: 'Thái Nguyên' },
  { label: 'Thairé', value: 'Thairé' },
  { label: 'Thakhèk', value: 'Thakhèk' },
  { label: 'Thakurdwara', value: 'Thakurdwara' },
  { label: 'Thakurganj', value: 'Thakurganj' },
  { label: 'Thakurgaon ', value: 'Thakurgaon ' },
  { label: 'Thal', value: 'Thal' },
  { label: 'Thal', value: 'Thal' },
  { label: 'Thal', value: 'Thal' },
  { label: 'Thal', value: 'Thal' },
  { label: 'Thala', value: 'Thala' },
  { label: 'Thalassery', value: 'Thalassery' },
  { label: 'Thale', value: 'Thale' },
  { label: 'Thaleischweiler-Fröschen', value: 'Thaleischweiler-Fröschen' },
  { label: 'Thalfang', value: 'Thalfang' },
  { label: 'Thalgau', value: 'Thalgau' },
  { label: 'Thalheim', value: 'Thalheim' },
  { label: 'Thalheim', value: 'Thalheim' },
  { label: 'Thalheim Bei Wels', value: 'Thalheim Bei Wels' },
  { label: 'Thallwitz', value: 'Thallwitz' },
  { label: 'Thalmassing', value: 'Thalmassing' },
  { label: 'Thalner', value: 'Thalner' },
  { label: 'Thalwil', value: 'Thalwil' },
  { label: 'Thalwil / Berg', value: 'Thalwil / Berg' },
  { label: 'Thalwil / Dorfkern', value: 'Thalwil / Dorfkern' },
  { label: 'Thalwil / Nord', value: 'Thalwil / Nord' },
  { label: 'Thalwil / See', value: 'Thalwil / See' },
  { label: 'Thalwil / Süd', value: 'Thalwil / Süd' },
  { label: 'Tham Phannara', value: 'Tham Phannara' },
  { label: 'Thamaga', value: 'Thamaga' },
  { label: 'Thamazo', value: 'Thamazo' },
  { label: 'Thame', value: 'Thame' },
  { label: 'Thames', value: 'Thames' },
  { label: 'Thames Ditton', value: 'Thames Ditton' },
  { label: 'Thamud', value: 'Thamud' },
  { label: 'Than', value: 'Than' },
  { label: 'Thana Bhawan', value: 'Thana Bhawan' },
  { label: 'Thanatpin', value: 'Thanatpin' },
  { label: 'Thandla', value: 'Thandla' },
  { label: 'Thane', value: 'Thane' },
  { label: 'Thanesar', value: 'Thanesar' },
  { label: 'Thang', value: 'Thang' },
  { label: 'Thangadh', value: 'Thangadh' },
  { label: 'Thanh Hóa', value: 'Thanh Hóa' },
  { label: 'Thành Phố Cam Ranh', value: 'Thành Phố Cam Ranh' },
  { label: 'Thành Phố Mỹ Tho', value: 'Thành Phố Mỹ Tho' },
  { label: 'Thành Phố Nha Trang', value: 'Thành Phố Nha Trang' },
  { label: 'Thành Phố Phan Thiết', value: 'Thành Phố Phan Thiết' },
  { label: 'Thành Phố Uông Bí', value: 'Thành Phố Uông Bí' },
  { label: 'Thành Phố Việt Trì', value: 'Thành Phố Việt Trì' },
  { label: 'Thành Phố Vũng Tàu', value: 'Thành Phố Vũng Tàu' },
  { label: 'Thanjavur', value: 'Thanjavur' },
  { label: 'Thann', value: 'Thann' },
  { label: 'Thanna Mandi', value: 'Thanna Mandi' },
  { label: 'Thannhausen', value: 'Thannhausen' },
  { label: 'Thanniyam', value: 'Thanniyam' },
  { label: 'Thanstein', value: 'Thanstein' },
  { label: 'Thaon', value: 'Thaon' },
  { label: 'Thaon-Les-Vosges', value: 'Thaon-Les-Vosges' },
  { label: 'Thap Khlo', value: 'Thap Khlo' },
  { label: 'Thap Than', value: 'Thap Than' },
  { label: 'Thaphalanxay', value: 'Thaphalanxay' },
  { label: 'Tharad', value: 'Tharad' },
  { label: 'Tharandt', value: 'Tharandt' },
  { label: 'Tharangambadi', value: 'Tharangambadi' },
  { label: 'Tharu Shah', value: 'Tharu Shah' },
  { label: 'Tharwah', value: 'Tharwah' },
  { label: 'Tharyarwady', value: 'Tharyarwady' },
  { label: 'Thásos', value: 'Thásos' },
  { label: 'Thasra', value: 'Thasra' },
  { label: 'That Phanom', value: 'That Phanom' },
  { label: 'Thatcham', value: 'Thatcham' },
  { label: 'Thatcher', value: 'Thatcher' },
  { label: 'Thaton', value: 'Thaton' },
  { label: 'Thatta', value: 'Thatta' },
  { label: 'Thaur', value: 'Thaur' },
  { label: 'Thawi Watthana', value: 'Thawi Watthana' },
  { label: 'Thaxted', value: 'Thaxted' },
  { label: 'Thaya', value: 'Thaya' },
  { label: 'Thayer', value: 'Thayer' },
  { label: 'Thayer County', value: 'Thayer County' },
  { label: 'Thayetmyo', value: 'Thayetmyo' },
  { label: 'Thayngen', value: 'Thayngen' },
  { label: 'The Acreage', value: 'The Acreage' },
  { label: 'The Basin', value: 'The Basin' },
  { label: 'The Boldons', value: 'The Boldons' },
  { label: 'The Bottom', value: 'The Bottom' },
  { label: 'The Bottom', value: 'The Bottom' },
  { label: 'The Bronx', value: 'The Bronx' },
  { label: 'The Colony', value: 'The Colony' },
  { label: 'The Coorong', value: 'The Coorong' },
  { label: 'The Crossings', value: 'The Crossings' },
  { label: 'The Dalles', value: 'The Dalles' },
  { label: 'The Dangs', value: 'The Dangs' },
  { label: 'The Entrance', value: 'The Entrance' },
  { label: 'The Entrance North', value: 'The Entrance North' },
  { label: 'The Galena Territory', value: 'The Galena Territory' },
  { label: 'The Gap', value: 'The Gap' },
  { label: 'The Gap', value: 'The Gap' },
  { label: 'The Gemfields', value: 'The Gemfields' },
  { label: 'The Hague', value: 'The Hague' },
  { label: 'The Hammocks', value: 'The Hammocks' },
  { label: 'The Hideout', value: 'The Hideout' },
  { label: 'The Hill', value: 'The Hill' },
  { label: 'The Hills', value: 'The Hills' },
  { label: 'The Hills Shire', value: 'The Hills Shire' },
  { label: 'The Junction', value: 'The Junction' },
  { label: 'The Meadows', value: 'The Meadows' },
  { label: 'The Morne', value: 'The Morne' },
  { label: 'The Oaks', value: 'The Oaks' },
  { label: 'The Pas', value: 'The Pas' },
  { label: 'The Patch', value: 'The Patch' },
  { label: 'The Pinery', value: 'The Pinery' },
  { label: 'The Plains', value: 'The Plains' },
  { label: 'The Ponds', value: 'The Ponds' },
  { label: 'The Range', value: 'The Range' },
  { label: 'The Rock', value: 'The Rock' },
  { label: 'The Rocks', value: 'The Rocks' },
  { label: 'The Scottish Borders', value: 'The Scottish Borders' },
  { label: 'The Vale', value: 'The Vale' },
  { label: 'The Village', value: 'The Village' },
  { label: 'The Village Of Indian Hill', value: 'The Village Of Indian Hill' },
  { label: 'The Villages', value: 'The Villages' },
  { label: 'The Vines', value: 'The Vines' },
  { label: 'The Woodlands', value: 'The Woodlands' },
  { label: 'Theale', value: 'Theale' },
  { label: 'Thebarton', value: 'Thebarton' },
  { label: 'Thebong', value: 'Thebong' },
  { label: 'Thedford', value: 'Thedford' },
  { label: 'Théding', value: 'Théding' },
  { label: 'Thedinghausen', value: 'Thedinghausen' },
  { label: 'Theereheide', value: 'Theereheide' },
  { label: 'Theilheim', value: 'Theilheim' },
  { label: 'Theillay', value: 'Theillay' },
  { label: 'Theisseil', value: 'Theisseil' },
  { label: 'Theißen', value: 'Theißen' },
  { label: 'Theix', value: 'Theix' },
  { label: 'Theizé', value: 'Theizé' },
  { label: 'Thélus', value: 'Thélus' },
  { label: 'Themar', value: 'Themar' },
  { label: 'Thénac', value: 'Thénac' },
  { label: 'Thénezay', value: 'Thénezay' },
  { label: 'Theni', value: 'Theni' },
  { label: 'Thenia', value: 'Thenia' },
  { label: 'Theniet El Had', value: 'Theniet El Had' },
  { label: 'Thenkasi', value: 'Thenkasi' },
  { label: 'Thenon', value: 'Thenon' },
  { label: 'Thenzawl', value: 'Thenzawl' },
  { label: 'Theobroma', value: 'Theobroma' },
  { label: 'Theodore', value: 'Theodore' },
  { label: 'Theodore', value: 'Theodore' },
  { label: 'Theodrine', value: 'Theodrine' },
  { label: 'Theog', value: 'Theog' },
  { label: 'Théoule-Sur-Mer', value: 'Théoule-Sur-Mer' },
  { label: 'Theresa', value: 'Theresa' },
  { label: 'Theresienfeld', value: 'Theresienfeld' },
  { label: 'Thermal', value: 'Thermal' },
  { label: 'Thermalito', value: 'Thermalito' },
  { label: 'Thérmi', value: 'Thérmi' },
  { label: 'Thérmo', value: 'Thérmo' },
  { label: 'Thermopolis', value: 'Thermopolis' },
  { label: 'Thérouanne', value: 'Thérouanne' },
  { label: 'Therwil', value: 'Therwil' },
  { label: 'Thésée', value: 'Thésée' },
  { label: 'Thespiés', value: 'Thespiés' },
  { label: 'Thesprotikó', value: 'Thesprotikó' },
  { label: 'Thessalon', value: 'Thessalon' },
  { label: 'Thessaloníki', value: 'Thessaloníki' },
  { label: 'Thetford', value: 'Thetford' },
  { label: 'Thetford-Mines', value: 'Thetford-Mines' },
  { label: 'Theuma', value: 'Theuma' },
  { label: 'Theunissen', value: 'Theunissen' },
  { label: 'Theux', value: 'Theux' },
  { label: 'Theydon Bois', value: 'Theydon Bois' },
  { label: 'Theys', value: 'Theys' },
  { label: 'Théza', value: 'Théza' },
  { label: 'Thézan-Lès-Béziers', value: 'Thézan-Lès-Béziers' },
  { label: 'Thị Trấn Tân Thành', value: 'Thị Trấn Tân Thành' },
  { label: 'Thị Xã Bắc Kạn', value: 'Thị Xã Bắc Kạn' },
  { label: 'Thị Xã Chí Linh', value: 'Thị Xã Chí Linh' },
  { label: 'Thị Xã Gò Công', value: 'Thị Xã Gò Công' },
  { label: 'Thị Xã Mưòng Lay', value: 'Thị Xã Mưòng Lay' },
  { label: 'Thị Xã Ninh Hòa', value: 'Thị Xã Ninh Hòa' },
  { label: 'Thị Xã Phú Mỹ', value: 'Thị Xã Phú Mỹ' },
  { label: 'Thị Xã Phú Thọ', value: 'Thị Xã Phú Thọ' },
  { label: 'Thị Xã Phước Long', value: 'Thị Xã Phước Long' },
  { label: 'Thiais', value: 'Thiais' },
  { label: 'Thiant', value: 'Thiant' },
  { label: 'Thibar', value: 'Thibar' },
  { label: 'Thiberville', value: 'Thiberville' },
  { label: 'Thibodaux', value: 'Thibodaux' },
  { label: 'Thief River Falls', value: 'Thief River Falls' },
  { label: 'Thiells', value: 'Thiells' },
  { label: 'Thiendorf', value: 'Thiendorf' },
  { label: 'Thiene', value: 'Thiene' },
  { label: 'Thiensville', value: 'Thiensville' },
  { label: 'Thierachern', value: 'Thierachern' },
  { label: 'Thierhaupten', value: 'Thierhaupten' },
  { label: 'Thiers', value: 'Thiers' },
  { label: 'Thiers', value: 'Thiers' },
  { label: 'Thiersheim', value: 'Thiersheim' },
  { label: 'Thiers-Sur-Thève', value: 'Thiers-Sur-Thève' },
  { label: 'Thierstein', value: 'Thierstein' },
  { label: 'Thierville-Sur-Meuse', value: 'Thierville-Sur-Meuse' },
  { label: 'Thiès', value: 'Thiès' },
  { label: 'Thiès Nones', value: 'Thiès Nones' },
  { label: 'Thiesi', value: 'Thiesi' },
  { label: 'Thika', value: 'Thika' },
  { label: 'Thil', value: 'Thil' },
  { label: 'Thil', value: 'Thil' },
  { label: 'Thilay', value: 'Thilay' },
  { label: 'Thilouze', value: 'Thilouze' },
  { label: 'Thimbirigasyaya', value: 'Thimbirigasyaya' },
  { label: 'Thimphu', value: 'Thimphu' },
  { label: 'Thinadhoo', value: 'Thinadhoo' },
  { label: 'Þingeyjarsveit', value: 'Þingeyjarsveit' },
  { label: 'Thionville', value: 'Thionville' },
  { label: 'Thiotte', value: 'Thiotte' },
  { label: 'Third Lake', value: 'Third Lake' },
  { label: 'Thirlmere', value: 'Thirlmere' },
  { label: 'Thiron Gardais', value: 'Thiron Gardais' },
  { label: 'Thirroul', value: 'Thirroul' },
  { label: 'Thirsk', value: 'Thirsk' },
  { label: 'Thirukattupalli', value: 'Thirukattupalli' },
  { label: 'Thiruthani', value: 'Thiruthani' },
  { label: 'Thiruvaiyaru', value: 'Thiruvaiyaru' },
  { label: 'Thiruvallur', value: 'Thiruvallur' },
  { label: 'Thiruvananthapuram', value: 'Thiruvananthapuram' },
  { label: 'Thiruvarur', value: 'Thiruvarur' },
  { label: 'Thiruvidaimaruthur', value: 'Thiruvidaimaruthur' },
  { label: 'Thise', value: 'Thise' },
  { label: 'Thisted', value: 'Thisted' },
  { label: 'Thisted Kommune', value: 'Thisted Kommune' },
  { label: 'Thívai', value: 'Thívai' },
  { label: 'Thiverny', value: 'Thiverny' },
  { label: 'Thiverval-Grignon', value: 'Thiverval-Grignon' },
  { label: 'Thiviers', value: 'Thiviers' },
  { label: 'Thizy-Les-Bourgs', value: 'Thizy-Les-Bourgs' },
  { label: 'Thoen', value: 'Thoen' },
  { label: 'Thohoyandou', value: 'Thohoyandou' },
  { label: 'Thới Lai', value: 'Thới Lai' },
  { label: 'Thoiry', value: 'Thoiry' },
  { label: 'Thoiry', value: 'Thoiry' },
  { label: 'Thoissey', value: 'Thoissey' },
  { label: 'Tholen', value: 'Tholen' },
  { label: 'Tholey', value: 'Tholey' },
  { label: 'Thomas', value: 'Thomas' },
  { label: 'Thomas County', value: 'Thomas County' },
  { label: 'Thomas County', value: 'Thomas County' },
  { label: 'Thomas County', value: 'Thomas County' },
  { label: 'Thomasberg', value: 'Thomasberg' },
  { label: 'Thomasboro', value: 'Thomasboro' },
  { label: 'Thomasburg', value: 'Thomasburg' },
  { label: 'Thomassique', value: 'Thomassique' },
  { label: 'Thomaston', value: 'Thomaston' },
  { label: 'Thomaston', value: 'Thomaston' },
  { label: 'Thomaston', value: 'Thomaston' },
  { label: 'Thomaston', value: 'Thomaston' },
  { label: 'Thomastown', value: 'Thomastown' },
  { label: 'Thomastown', value: 'Thomastown' },
  { label: 'Thomasville', value: 'Thomasville' },
  { label: 'Thomasville', value: 'Thomasville' },
  { label: 'Thomasville', value: 'Thomasville' },
  { label: 'Thomatal', value: 'Thomatal' },
  { label: 'Thomazeau', value: 'Thomazeau' },
  { label: 'Thomery', value: 'Thomery' },
  { label: 'Thomm', value: 'Thomm' },
  { label: 'Thomonde', value: 'Thomonde' },
  { label: 'Thompson', value: 'Thompson' },
  { label: 'Thompson', value: 'Thompson' },
  { label: 'Thompson', value: 'Thompson' },
  { label: 'Thompson Falls', value: 'Thompson Falls' },
  { label: 'Thompson Town', value: 'Thompson Town' },
  { label: 'Thompsons Station', value: 'Thompsons Station' },
  { label: 'Thompsonville', value: 'Thompsonville' },
  { label: 'Thompsonville', value: 'Thompsonville' },
  { label: 'Thomson', value: 'Thomson' },
  { label: 'Thomson', value: 'Thomson' },
  { label: 'Thon Buri', value: 'Thon Buri' },
  { label: 'Thônes', value: 'Thônes' },
  { label: 'Thônex', value: 'Thônex' },
  { label: 'Thongwa', value: 'Thongwa' },
  { label: 'Thonon-Les-Bains', value: 'Thonon-Les-Bains' },
  { label: 'Thonotosassa', value: 'Thonotosassa' },
  { label: 'Thoothukudi', value: 'Thoothukudi' },
  { label: 'Thoreau', value: 'Thoreau' },
  { label: 'Thorens-Glières', value: 'Thorens-Glières' },
  { label: 'Thorigné', value: 'Thorigné' },
  { label: 'Thorigné-Sur-Dué', value: 'Thorigné-Sur-Dué' },
  { label: 'Thorigny-Sur-Marne', value: 'Thorigny-Sur-Marne' },
  { label: 'Thorigny-Sur-Oreuse', value: 'Thorigny-Sur-Oreuse' },
  { label: 'Thörl', value: 'Thörl' },
  { label: 'Þorlákshöfn', value: 'Þorlákshöfn' },
  { label: 'Thorn', value: 'Thorn' },
  { label: 'Thornaby-On-Tees', value: 'Thornaby-On-Tees' },
  { label: 'Thornbury', value: 'Thornbury' },
  { label: 'Thornbury', value: 'Thornbury' },
  { label: 'Thorndale', value: 'Thorndale' },
  { label: 'Thorndale', value: 'Thorndale' },
  { label: 'Thorne', value: 'Thorne' },
  { label: 'Thorner', value: 'Thorner' },
  { label: 'Thorneside', value: 'Thorneside' },
  { label: 'Thorney', value: 'Thorney' },
  { label: 'Thorngumbald', value: 'Thorngumbald' },
  { label: 'Thornhill', value: 'Thornhill' },
  { label: 'Thornhill', value: 'Thornhill' },
  { label: 'Thornlands', value: 'Thornlands' },
  { label: 'Thornleigh', value: 'Thornleigh' },
  { label: 'Thornley', value: 'Thornley' },
  { label: 'Thornlie', value: 'Thornlie' },
  { label: 'Thornliebank', value: 'Thornliebank' },
  { label: 'Thornport', value: 'Thornport' },
  { label: 'Thornton', value: 'Thornton' },
  { label: 'Thornton', value: 'Thornton' },
  { label: 'Thornton', value: 'Thornton' },
  { label: 'Thornton', value: 'Thornton' },
  { label: 'Thornton', value: 'Thornton' },
  { label: 'Thornton', value: 'Thornton' },
  { label: 'Thornton', value: 'Thornton' },
  { label: 'Thornton Dale', value: 'Thornton Dale' },
  { label: 'Thornton Heath', value: 'Thornton Heath' },
  { label: 'Thornton-Cleveleys', value: 'Thornton-Cleveleys' },
  { label: 'Thorntown', value: 'Thorntown' },
  { label: 'Thornwood', value: 'Thornwood' },
  { label: 'Thorold', value: 'Thorold' },
  { label: 'Thorp', value: 'Thorp' },
  { label: 'Thorp Arch', value: 'Thorp Arch' },
  { label: 'Thorpe Hamlet', value: 'Thorpe Hamlet' },
  { label: 'Thorpe Le Soken', value: 'Thorpe Le Soken' },
  { label: 'Thorsby', value: 'Thorsby' },
  { label: 'Thouarcé', value: 'Thouarcé' },
  { label: 'Thouaré-Sur-Loire', value: 'Thouaré-Sur-Loire' },
  { label: 'Thouars', value: 'Thouars' },
  { label: 'Thoubal', value: 'Thoubal' },
  { label: 'Thouría', value: 'Thouría' },
  { label: 'Thourotte', value: 'Thourotte' },
  { label: 'Thousand Oaks', value: 'Thousand Oaks' },
  { label: 'Thousand Palms', value: 'Thousand Palms' },
  { label: 'Thrakomakedónes', value: 'Thrakomakedónes' },
  { label: 'Thrapsanón', value: 'Thrapsanón' },
  { label: 'Thrapston', value: 'Thrapston' },
  { label: 'Three Crosses', value: 'Three Crosses' },
  { label: 'Three Forks', value: 'Three Forks' },
  { label: 'Three Hills', value: 'Three Hills' },
  { label: 'Three Lakes', value: 'Three Lakes' },
  { label: 'Three Lakes', value: 'Three Lakes' },
  { label: 'Three Legged Cross', value: 'Three Legged Cross' },
  { label: 'Three Mile River', value: 'Three Mile River' },
  { label: 'Three Oaks', value: 'Three Oaks' },
  { label: 'Three Oaks', value: 'Three Oaks' },
  { label: 'Three Oaks/ Glendale', value: 'Three Oaks/ Glendale' },
  { label: 'Three Points', value: 'Three Points' },
  { label: 'Three Rivers', value: 'Three Rivers' },
  { label: 'Three Rivers', value: 'Three Rivers' },
  { label: 'Three Rivers', value: 'Three Rivers' },
  { label: 'Three Rivers', value: 'Three Rivers' },
  { label: 'Three Rivers', value: 'Three Rivers' },
  { label: 'Three Springs', value: 'Three Springs' },
  { label: 'Three Way', value: 'Three Way' },
  { label: 'Thrimshing', value: 'Thrimshing' },
  { label: 'Thrissur', value: 'Thrissur' },
  { label: 'Throckmorton', value: 'Throckmorton' },
  { label: 'Throckmorton County', value: 'Throckmorton County' },
  { label: 'Throgs Neck', value: 'Throgs Neck' },
  { label: 'Throop', value: 'Throop' },
  { label: 'Thủ Dầu Một', value: 'Thủ Dầu Một' },
  { label: 'Thuellin', value: 'Thuellin' },
  { label: 'Thueyts', value: 'Thueyts' },
  { label: 'Thuin', value: 'Thuin' },
  { label: 'Thuine', value: 'Thuine' },
  { label: 'Thuir', value: 'Thuir' },
  { label: 'Thul', value: 'Thul' },
  { label: 'Thula', value: 'Thula' },
  { label: 'Thulamahashi', value: 'Thulamahashi' },
  { label: 'Thum', value: 'Thum' },
  { label: 'Thumeries', value: 'Thumeries' },
  { label: 'Thumersbach', value: 'Thumersbach' },
  { label: 'Thumgaon', value: 'Thumgaon' },
  { label: 'Thumpane', value: 'Thumpane' },
  { label: 'Thun', value: 'Thun' },
  { label: 'Thun District', value: 'Thun District' },
  { label: 'Thunder Bay', value: 'Thunder Bay' },
  { label: 'Thunder Bay District', value: 'Thunder Bay District' },
  { label: 'Thunderbolt', value: 'Thunderbolt' },
  { label: 'Thundorf', value: 'Thundorf' },
  { label: 'Thundorf In Unterfranken', value: 'Thundorf In Unterfranken' },
  { label: 'Thung Khru', value: 'Thung Khru' },
  { label: 'Thung Song', value: 'Thung Song' },
  { label: 'Thüngen', value: 'Thüngen' },
  { label: 'Thüngersheim', value: 'Thüngersheim' },
  { label: 'Thun-Saint-Amand', value: 'Thun-Saint-Amand' },
  { label: 'Thür', value: 'Thür' },
  { label: 'Thuré', value: 'Thuré' },
  { label: 'Thurgoona', value: 'Thurgoona' },
  { label: 'Thüringen', value: 'Thüringen' },
  { label: 'Thurins', value: 'Thurins' },
  { label: 'Thurlby', value: 'Thurlby' },
  { label: 'Thurles', value: 'Thurles' },
  { label: 'Thurlton', value: 'Thurlton' },
  { label: 'Thurmansbang', value: 'Thurmansbang' },
  { label: 'Thurmond', value: 'Thurmond' },
  { label: 'Thurmont', value: 'Thurmont' },
  { label: 'Thurn', value: 'Thurn' },
  { label: 'Thurnau', value: 'Thurnau' },
  { label: 'Thurø By', value: 'Thurø By' },
  { label: 'Thursday Island', value: 'Thursday Island' },
  { label: 'Thurso', value: 'Thurso' },
  { label: 'Thurso', value: 'Thurso' },
  { label: 'Thurston', value: 'Thurston' },
  { label: 'Thurston County', value: 'Thurston County' },
  { label: 'Thurston County', value: 'Thurston County' },
  { label: 'Thury-Harcourt', value: 'Thury-Harcourt' },
  { label: 'Thusis', value: 'Thusis' },
  { label: 'Thuwal', value: 'Thuwal' },
  { label: 'Thyborøn', value: 'Thyborøn' },
  { label: 'Thyez', value: 'Thyez' },
  { label: 'Thyolo', value: 'Thyolo' },
  { label: 'Thyolo District', value: 'Thyolo District' },
  { label: 'Thyrnau', value: 'Thyrnau' },
  { label: 'Ti Boug', value: 'Ti Boug' },
  { label: 'Ti Colon', value: 'Ti Colon' },
  { label: 'Ti Delcer', value: 'Ti Delcer' },
  { label: 'Ti Port-De-Paix', value: 'Ti Port-De-Paix' },
  { label: 'Ti Riviere', value: 'Ti Riviere' },
  { label: 'Ti Rocher', value: 'Ti Rocher' },
  { label: 'Ti Rocher', value: 'Ti Rocher' },
  { label: 'Tia Mare', value: 'Tia Mare' },
  { label: 'Tiachiv Raion', value: 'Tiachiv Raion' },
  { label: 'Tiadiaye', value: 'Tiadiaye' },
  { label: 'Tiahuanaco', value: 'Tiahuanaco' },
  { label: 'Tiana', value: 'Tiana' },
  { label: 'Tiana', value: 'Tiana' },
  { label: 'Tianchang', value: 'Tianchang' },
  { label: 'Tianfu', value: 'Tianfu' },
  { label: 'Tianguá', value: 'Tianguá' },
  { label: 'Tianguismanalco', value: 'Tianguismanalco' },
  { label: 'Tianguistengo', value: 'Tianguistengo' },
  { label: 'Tianguistengo (La Romera)', value: 'Tianguistengo (La Romera)' },
  { label: 'Tianguistongo', value: 'Tianguistongo' },
  { label: 'Tianjin', value: 'Tianjin' },
  { label: 'Tianpeng', value: 'Tianpeng' },
  { label: 'Tianshui', value: 'Tianshui' },
  { label: 'Tiaong', value: 'Tiaong' },
  { label: 'Tiaret', value: 'Tiaret' },
  { label: 'Tías', value: 'Tías' },
  { label: 'Tiassalé', value: 'Tiassalé' },
  { label: 'Tibacuy', value: 'Tibacuy' },
  { label: 'Tibagi', value: 'Tibagi' },
  { label: 'Tibagon', value: 'Tibagon' },
  { label: 'Tibagon', value: 'Tibagon' },
  { label: 'Tibagon', value: 'Tibagon' },
  { label: 'Tibaná', value: 'Tibaná' },
  { label: 'Ţibana', value: 'Ţibana' },
  { label: 'Tibanbang', value: 'Tibanbang' },
  { label: 'Tibanbang', value: 'Tibanbang' },
  { label: 'Tibanbang', value: 'Tibanbang' },
  { label: 'Ţibăneşti', value: 'Ţibăneşti' },
  { label: 'Tibás', value: 'Tibás' },
  { label: 'Tibasosa', value: 'Tibasosa' },
  { label: 'Tibati', value: 'Tibati' },
  { label: 'Tibau', value: 'Tibau' },
  { label: 'Tibau Do Sul', value: 'Tibau Do Sul' },
  { label: 'Țibeni', value: 'Țibeni' },
  { label: 'Tiberias', value: 'Tiberias' },
  { label: 'Tibi', value: 'Tibi' },
  { label: 'Tibiao', value: 'Tibiao' },
  { label: 'Tibigan', value: 'Tibigan' },
  { label: 'Tibiri', value: 'Tibiri' },
  { label: 'Tibirita', value: 'Tibirita' },
  { label: 'Tiblawan', value: 'Tiblawan' },
  { label: 'Tiblawan', value: 'Tiblawan' },
  { label: 'Tiblawan', value: 'Tiblawan' },
  { label: 'Tibnah', value: 'Tibnah' },
  { label: 'Tibolón', value: 'Tibolón' },
  { label: 'Tibro', value: 'Tibro' },
  { label: 'Tibshelf', value: 'Tibshelf' },
  { label: 'Tibú', value: 'Tibú' },
  { label: 'Ţibucani', value: 'Ţibucani' },
  { label: 'Tibur', value: 'Tibur' },
  { label: 'Tiburon', value: 'Tiburon' },
  { label: 'Tiburon', value: 'Tiburon' },
  { label: 'Tiburones', value: 'Tiburones' },
  { label: 'Ticala-An', value: 'Ticala-An' },
  { label: 'Țicău', value: 'Țicău' },
  { label: 'Tice', value: 'Tice' },
  { label: 'Ticehurst', value: 'Ticehurst' },
  { label: 'Ticengo', value: 'Ticengo' },
  { label: 'Tichá', value: 'Tichá' },
  { label: 'Tichigan', value: 'Tichigan' },
  { label: 'Tichileşti', value: 'Tichileşti' },
  { label: 'Ticineto', value: 'Ticineto' },
  { label: 'Ticino', value: 'Ticino' },
  { label: 'Tickhill', value: 'Tickhill' },
  { label: 'Tickton', value: 'Tickton' },
  { label: 'Ţicleni', value: 'Ţicleni' },
  { label: 'Ticonderoga', value: 'Ticonderoga' },
  { label: 'Ticopó', value: 'Ticopó' },
  { label: 'Ticuantepe', value: 'Ticuantepe' },
  { label: 'Ticul', value: 'Ticul' },
  { label: 'Ticumán', value: 'Ticumán' },
  { label: 'Ticuşu Vechi', value: 'Ticuşu Vechi' },
  { label: 'Ticvaniu Mare', value: 'Ticvaniu Mare' },
  { label: 'Tidaholm', value: 'Tidaholm' },
  { label: 'Tiddische', value: 'Tiddische' },
  { label: 'Tideswell', value: 'Tideswell' },
  { label: 'Tidili Mesfioua', value: 'Tidili Mesfioua' },
  { label: 'Tidman', value: 'Tidman' },
  { label: 'Tidman', value: 'Tidman' },
  { label: 'Tidworth', value: 'Tidworth' },
  { label: 'Tiebas-Muruarte De Reta', value: 'Tiebas-Muruarte De Reta' },
  { label: 'Tiébo', value: 'Tiébo' },
  { label: 'Tiedra', value: 'Tiedra' },
  { label: 'Tiefenbach', value: 'Tiefenbach' },
  { label: 'Tiefenbach', value: 'Tiefenbach' },
  { label: 'Tiefenbronn', value: 'Tiefenbronn' },
  { label: 'Tiefencastel', value: 'Tiefencastel' },
  { label: 'Tiefenort', value: 'Tiefenort' },
  { label: 'Tiefgraben', value: 'Tiefgraben' },
  { label: 'Tiel', value: 'Tiel' },
  { label: 'Tieli', value: 'Tieli' },
  { label: 'Tieling', value: 'Tieling' },
  { label: 'Tieling Shi', value: 'Tieling Shi' },
  { label: 'Tielmes', value: 'Tielmes' },
  { label: 'Tielt', value: 'Tielt' },
  { label: 'Tiemblo El', value: 'Tiemblo El' },
  { label: 'Tienen', value: 'Tienen' },
  { label: 'Tienray', value: 'Tienray' },
  { label: 'Tiep', value: 'Tiep' },
  { label: 'Tiep', value: 'Tiep' },
  { label: 'Tiercé', value: 'Tiercé' },
  { label: 'Tierga', value: 'Tierga' },
  { label: 'Tiergarten', value: 'Tiergarten' },
  { label: 'Tieri', value: 'Tieri' },
  { label: 'Tierp', value: 'Tierp' },
  { label: 'Tierra Amarilla', value: 'Tierra Amarilla' },
  { label: 'Tierra Amarilla', value: 'Tierra Amarilla' },
  { label: 'Tierra Blanca', value: 'Tierra Blanca' },
  { label: 'Tierra Blanca', value: 'Tierra Blanca' },
  { label: 'Tierra Blanca', value: 'Tierra Blanca' },
  { label: 'Tierra Blanca', value: 'Tierra Blanca' },
  { label: 'Tierra Blanca', value: 'Tierra Blanca' },
  { label: 'Tierra Blanca Booxter', value: 'Tierra Blanca Booxter' },
  { label: 'Tierra Buena', value: 'Tierra Buena' },
  { label: 'Tierra Colorada', value: 'Tierra Colorada' },
  { label: 'Tierra Colorada', value: 'Tierra Colorada' },
  { label: 'Tierra Fría', value: 'Tierra Fría' },
  { label: 'Tierra Larga (Campo Nuevo)', value: 'Tierra Larga (Campo Nuevo)' },
  { label: 'Tierra Negra', value: 'Tierra Negra' },
  { label: 'Tierra Nueva', value: 'Tierra Nueva' },
  { label: 'Tierra Nueva', value: 'Tierra Nueva' },
  { label: 'Tierra Verde', value: 'Tierra Verde' },
  { label: 'Tierra Y Libertad', value: 'Tierra Y Libertad' },
  { label: 'Tierralta', value: 'Tierralta' },
  { label: 'Tierras Coloradas', value: 'Tierras Coloradas' },
  { label: 'Tierras Coloradas (San Pedro)', value: 'Tierras Coloradas (San Pedro)' },
  { label: 'Tierras Nuevas Poniente', value: 'Tierras Nuevas Poniente' },
  { label: 'Tierz', value: 'Tierz' },
  { label: 'Tierzo', value: 'Tierzo' },
  { label: 'Tieschen', value: 'Tieschen' },
  { label: 'Tietê', value: 'Tietê' },
  { label: 'Tieton', value: 'Tieton' },
  { label: 'Ţifeşti', value: 'Ţifeşti' },
  { label: 'Tiffauges', value: 'Tiffauges' },
  { label: 'Tiffin', value: 'Tiffin' },
  { label: 'Tiffin', value: 'Tiffin' },
  { label: 'Tiflet', value: 'Tiflet' },
  { label: 'Tift County', value: 'Tift County' },
  { label: 'Tifton', value: 'Tifton' },
  { label: 'Ţigănaşi', value: 'Ţigănaşi' },
  { label: 'Ţigăneşti', value: 'Ţigăneşti' },
  { label: 'Tigao', value: 'Tigao' },
  { label: 'Tigao', value: 'Tigao' },
  { label: 'Tigaon', value: 'Tigaon' },
  { label: 'Tigard', value: 'Tigard' },
  { label: 'Tigbao', value: 'Tigbao' },
  { label: 'Tigbao', value: 'Tigbao' },
  { label: 'Tigbauan', value: 'Tigbauan' },
  { label: 'Tigbaw', value: 'Tigbaw' },
  { label: 'Tigbinan', value: 'Tigbinan' },
  { label: 'Tiger Point', value: 'Tiger Point' },
  { label: 'Tigerville', value: 'Tigerville' },
  { label: 'Tigery', value: 'Tigery' },
  { label: 'Tiggiano', value: 'Tiggiano' },
  { label: 'Tighes Hill', value: 'Tighes Hill' },
  { label: 'Tigil’', value: 'Tigil’' },
  { label: 'Tigil’Skiy Rayon', value: 'Tigil’Skiy Rayon' },
  { label: 'Tiglauigan', value: 'Tiglauigan' },
  { label: 'Tiglieto', value: 'Tiglieto' },
  { label: 'Tigliole', value: 'Tigliole' },
  { label: 'Țigmandru', value: 'Țigmandru' },
  { label: 'Tignapalan', value: 'Tignapalan' },
  { label: 'Tignère', value: 'Tignère' },
  { label: 'Tignes', value: 'Tignes' },
  { label: 'Tignieu', value: 'Tignieu' },
  { label: 'Tignoan', value: 'Tignoan' },
  { label: 'Tigpalay', value: 'Tigpalay' },
  { label: 'Tigri', value: 'Tigri' },
  { label: 'Tigrinhos', value: 'Tigrinhos' },
  { label: 'Tigtabon', value: 'Tigtabon' },
  { label: 'Tiguha', value: 'Tiguha' },
  { label: 'Tigui', value: 'Tigui' },
  { label: 'Tiguib', value: 'Tiguib' },
  { label: 'Tiguion', value: 'Tiguion' },
  { label: 'Tiguisan', value: 'Tiguisan' },
  { label: 'Tigum', value: 'Tigum' },
  { label: 'Tigveni', value: 'Tigveni' },
  { label: 'Tigwav', value: 'Tigwav' },
  { label: 'Tigy', value: 'Tigy' },
  { label: 'Tiha Bârgăului', value: 'Tiha Bârgăului' },
  { label: 'Tihany', value: 'Tihany' },
  { label: 'Tihău', value: 'Tihău' },
  { label: 'Tiholop', value: 'Tiholop' },
  { label: 'Tihosuco', value: 'Tihosuco' },
  { label: 'Tihuatlan', value: 'Tihuatlan' },
  { label: 'Tijara', value: 'Tijara' },
  { label: 'Tijarafe', value: 'Tijarafe' },
  { label: 'Tijeras', value: 'Tijeras' },
  { label: 'Tijnje', value: 'Tijnje' },
  { label: 'Tíjola', value: 'Tíjola' },
  { label: 'Tijuana', value: 'Tijuana' },
  { label: 'Tijucas', value: 'Tijucas' },
  { label: 'Tijucas Do Sul', value: 'Tijucas Do Sul' },
  { label: 'Tikaitnagar', value: 'Tikaitnagar' },
  { label: 'Tikamgarh', value: 'Tikamgarh' },
  { label: 'Tikhoretsk', value: 'Tikhoretsk' },
  { label: 'Tikhoretskiy Rayon', value: 'Tikhoretskiy Rayon' },
  { label: 'Tikhvin', value: 'Tikhvin' },
  { label: 'Tikhvinskiy Rayon', value: 'Tikhvinskiy Rayon' },
  { label: 'Tiki Island', value: 'Tiki Island' },
  { label: 'Tikinmul', value: 'Tikinmul' },
  { label: 'Tikiw', value: 'Tikiw' },
  { label: 'Tikiw', value: 'Tikiw' },
  { label: 'Tiko', value: 'Tiko' },
  { label: 'Tikri', value: 'Tikri' },
  { label: 'Tikrīt', value: 'Tikrīt' },
  { label: 'Tiksi', value: 'Tiksi' },
  { label: 'Tikuch', value: 'Tikuch' },
  { label: 'Tila', value: 'Tila' },
  { label: 'Tilapa', value: 'Tilapa' },
  { label: 'Tilapan', value: 'Tilapan' },
  { label: 'Tilarán', value: 'Tilarán' },
  { label: 'Tilburg', value: 'Tilburg' },
  { label: 'Tilbury', value: 'Tilbury' },
  { label: 'Tilcara', value: 'Tilcara' },
  { label: 'Tilden', value: 'Tilden' },
  { label: 'Tileagd', value: 'Tileagd' },
  { label: 'Tilhar', value: 'Tilhar' },
  { label: 'Tilichiki', value: 'Tilichiki' },
  { label: 'Tilik', value: 'Tilik' },
  { label: 'Tiling', value: 'Tiling' },
  { label: 'Tilisarao', value: 'Tilisarao' },
  { label: 'Tilişca', value: 'Tilişca' },
  { label: 'Tílisos', value: 'Tílisos' },
  { label: 'Tillabéri', value: 'Tillabéri' },
  { label: 'Tillaberi Department', value: 'Tillaberi Department' },
  { label: 'Tillamook', value: 'Tillamook' },
  { label: 'Tillamook County', value: 'Tillamook County' },
  { label: 'Tillberga', value: 'Tillberga' },
  { label: 'Tillé', value: 'Tillé' },
  { label: 'Tillicoultry', value: 'Tillicoultry' },
  { label: 'Tillières', value: 'Tillières' },
  { label: 'Tillières-Sur-Avre', value: 'Tillières-Sur-Avre' },
  { label: 'Tillman County', value: 'Tillman County' },
  { label: 'Tillmans Corner', value: 'Tillmans Corner' },
  { label: 'Tillmitsch', value: 'Tillmitsch' },
  { label: 'Tillmitsch Links Der Laßnitz', value: 'Tillmitsch Links Der Laßnitz' },
  { label: 'Tillo', value: 'Tillo' },
  { label: 'Tilloy-Lès-Mofflaines', value: 'Tilloy-Lès-Mofflaines' },
  { label: 'Tillson', value: 'Tillson' },
  { label: 'Tilly-Sur-Seulles', value: 'Tilly-Sur-Seulles' },
  { label: 'Tilomar', value: 'Tilomar' },
  { label: 'Tilques', value: 'Tilques' },
  { label: 'Tilsahri', value: 'Tilsahri' },
  { label: 'Tiltepec', value: 'Tiltepec' },
  { label: 'Tiltepec', value: 'Tiltepec' },
  { label: 'Tiltil', value: 'Tiltil' },
  { label: 'Tilton', value: 'Tilton' },
  { label: 'Tilton', value: 'Tilton' },
  { label: 'Tilton-Northfield', value: 'Tilton-Northfield' },
  { label: 'Tiltonsville', value: 'Tiltonsville' },
  { label: 'Tilzapotla', value: 'Tilzapotla' },
  { label: 'Tim', value: 'Tim' },
  { label: 'Timana', value: 'Timana' },
  { label: 'Timaru', value: 'Timaru' },
  { label: 'Timaru District', value: 'Timaru District' },
  { label: 'Timashëvsk', value: 'Timashëvsk' },
  { label: 'Timaukel', value: 'Timaukel' },
  { label: 'Timbaúba', value: 'Timbaúba' },
  { label: 'Timbaúba Dos Batistas', value: 'Timbaúba Dos Batistas' },
  { label: 'Timbé Do Sul', value: 'Timbé Do Sul' },
  { label: 'Timbedra', value: 'Timbedra' },
  { label: 'Timber Lake', value: 'Timber Lake' },
  { label: 'Timber Pines', value: 'Timber Pines' },
  { label: 'Timberlake', value: 'Timberlake' },
  { label: 'Timberlane', value: 'Timberlane' },
  { label: 'Timberville', value: 'Timberville' },
  { label: 'Timberwood Park', value: 'Timberwood Park' },
  { label: 'Timbío', value: 'Timbío' },
  { label: 'Timbiquí', value: 'Timbiquí' },
  { label: 'Timbiras', value: 'Timbiras' },
  { label: 'Timbó', value: 'Timbó' },
  { label: 'Timbó Grande', value: 'Timbó Grande' },
  { label: 'Timboon', value: 'Timboon' },
  { label: 'Timbuktu', value: 'Timbuktu' },
  { label: 'Timburi', value: 'Timburi' },
  { label: 'Time', value: 'Time' },
  { label: 'Timelkam', value: 'Timelkam' },
  { label: 'Timenitz', value: 'Timenitz' },
  { label: 'Timezgadiouine', value: 'Timezgadiouine' },
  { label: 'Timi Jan', value: 'Timi Jan' },
  { label: 'Timilpan', value: 'Timilpan' },
  { label: 'Timimoun', value: 'Timimoun' },
  { label: 'Timiryazevo', value: 'Timiryazevo' },
  { label: 'Timiryazevskiy', value: 'Timiryazevskiy' },
  { label: 'Timiryazevskoye', value: 'Timiryazevskoye' },
  { label: 'Timişeşti', value: 'Timişeşti' },
  { label: 'Timiskaming District', value: 'Timiskaming District' },
  { label: 'Timişoara', value: 'Timişoara' },
  { label: 'Timizart', value: 'Timizart' },
  { label: 'Timmaspe', value: 'Timmaspe' },
  { label: 'Timmendorfer Strand', value: 'Timmendorfer Strand' },
  { label: 'Timmenrode', value: 'Timmenrode' },
  { label: 'Timmernabben', value: 'Timmernabben' },
  { label: 'Timmins', value: 'Timmins' },
  { label: 'Timmonsville', value: 'Timmonsville' },
  { label: 'Timon', value: 'Timon' },
  { label: 'Timonan', value: 'Timonan' },
  { label: 'Timonium', value: 'Timonium' },
  { label: 'Timóteo', value: 'Timóteo' },
  { label: 'Timoulilt', value: 'Timoulilt' },
  { label: 'Timpas', value: 'Timpas' },
  { label: 'Timperley', value: 'Timperley' },
  { label: 'Timpson', value: 'Timpson' },
  { label: 'Timrå', value: 'Timrå' },
  { label: 'Timrat', value: 'Timrat' },
  { label: 'Timsbury', value: 'Timsbury' },
  { label: 'Timucuy', value: 'Timucuy' },
  { label: 'Tin Can Bay', value: 'Tin Can Bay' },
  { label: 'Tinaan', value: 'Tinaan' },
  { label: 'Tinagacan', value: 'Tinagacan' },
  { label: 'Tinago', value: 'Tinago' },
  { label: 'Tinajas', value: 'Tinajas' },
  { label: 'Tinajas', value: 'Tinajas' },
  { label: 'Tinajas', value: 'Tinajas' },
  { label: 'Tinajitas', value: 'Tinajitas' },
  { label: 'Tinajo', value: 'Tinajo' },
  { label: 'Tinalmud', value: 'Tinalmud' },
  { label: 'Tinambac', value: 'Tinambac' },
  { label: 'Tinambacan', value: 'Tinambacan' },
  { label: 'Tinambacan', value: 'Tinambacan' },
  { label: 'Tinampo', value: 'Tinampo' },
  { label: 'Tinana', value: 'Tinana' },
  { label: 'Tinang', value: 'Tinang' },
  { label: 'Tinang', value: 'Tinang' },
  { label: 'Tinaogan', value: 'Tinaogan' },
  { label: 'Tinaquillo', value: 'Tinaquillo' },
  { label: 'Tinawagan', value: 'Tinawagan' },
  { label: 'Tinca', value: 'Tinca' },
  { label: 'Tinchebray', value: 'Tinchebray' },
  { label: 'Tincontlán', value: 'Tincontlán' },
  { label: 'Tinde', value: 'Tinde' },
  { label: 'Tindivanam', value: 'Tindivanam' },
  { label: 'Tindog', value: 'Tindog' },
  { label: 'Tindouf', value: 'Tindouf' },
  { label: 'Tindwari', value: 'Tindwari' },
  { label: 'Tineg', value: 'Tineg' },
  { label: 'Tineo', value: 'Tineo' },
  { label: 'Tinga', value: 'Tinga' },
  { label: 'Tingalpa', value: 'Tingalpa' },
  { label: 'Tingambato', value: 'Tingambato' },
  { label: 'Tingdong', value: 'Tingdong' },
  { label: 'Tingewick', value: 'Tingewick' },
  { label: 'Tinghir', value: 'Tinghir' },
  { label: 'Tinghir Province', value: 'Tinghir Province' },
  { label: 'Tingi', value: 'Tingi' },
  { label: 'Tingira Heights', value: 'Tingira Heights' },
  { label: 'Tingjiang', value: 'Tingjiang' },
  { label: 'Tinglayan', value: 'Tinglayan' },
  { label: 'Tinglayan', value: 'Tinglayan' },
  { label: 'Tinglev', value: 'Tinglev' },
  { label: 'Tingloy', value: 'Tingloy' },
  { label: 'Tingo María', value: 'Tingo María' },
  { label: 'Tingsryd', value: 'Tingsryd' },
  { label: 'Tingüindín', value: 'Tingüindín' },
  { label: 'Tingvoll', value: 'Tingvoll' },
  { label: 'Tinicum', value: 'Tinicum' },
  { label: 'Tinieblas De La Sierra', value: 'Tinieblas De La Sierra' },
  { label: 'Tiniguiban', value: 'Tiniguiban' },
  { label: 'Tinjacá', value: 'Tinjacá' },
  { label: 'Tinley Park', value: 'Tinley Park' },
  { label: 'Tinlot', value: 'Tinlot' },
  { label: 'Tinn', value: 'Tinn' },
  { label: 'Tinnanur', value: 'Tinnanur' },
  { label: 'Tinnum', value: 'Tinnum' },
  { label: 'Tinnura', value: 'Tinnura' },
  { label: 'Tinoc', value: 'Tinoc' },
  { label: 'Tinogasta', value: 'Tinogasta' },
  { label: 'Tinogboc', value: 'Tinogboc' },
  { label: 'Tinonee', value: 'Tinonee' },
  { label: 'Tinongan', value: 'Tinongan' },
  { label: 'Tínos', value: 'Tínos' },
  { label: 'Tiñosillos', value: 'Tiñosillos' },
  { label: 'Tinosu', value: 'Tinosu' },
  { label: 'Tinoto', value: 'Tinoto' },
  { label: 'Tinoto', value: 'Tinoto' },
  { label: 'Tinqueux', value: 'Tinqueux' },
  { label: 'Tinskoy', value: 'Tinskoy' },
  { label: 'Tinsukia', value: 'Tinsukia' },
  { label: 'Tinta', value: 'Tinta' },
  { label: 'Tintafor', value: 'Tintafor' },
  { label: 'Tintagel', value: 'Tintagel' },
  { label: 'Țintea', value: 'Țintea' },
  { label: 'Tinténiac', value: 'Tinténiac' },
  { label: 'Tintern', value: 'Tintern' },
  { label: 'Ţinteşti', value: 'Ţinteşti' },
  { label: 'Tintigny', value: 'Tintigny' },
  { label: 'Tintina', value: 'Tintina' },
  { label: 'Tinton Falls', value: 'Tinton Falls' },
  { label: 'Tinubuan', value: 'Tinubuan' },
  { label: 'Tinum', value: 'Tinum' },
  { label: 'Tinutulan', value: 'Tinutulan' },
  { label: 'Tinutulan', value: 'Tinutulan' },
  { label: 'Tinwald', value: 'Tinwald' },
  { label: 'Tinyahuarco', value: 'Tinyahuarco' },
  { label: 'Tio Hugo', value: 'Tio Hugo' },
  { label: 'Tío Pujio', value: 'Tío Pujio' },
  { label: 'Tioga', value: 'Tioga' },
  { label: 'Tioga County', value: 'Tioga County' },
  { label: 'Tioga County', value: 'Tioga County' },
  { label: 'Tione Degli Abruzzi', value: 'Tione Degli Abruzzi' },
  { label: 'Tione Di Trento', value: 'Tione Di Trento' },
  { label: 'Tionesta', value: 'Tionesta' },
  { label: 'Tionk Essil', value: 'Tionk Essil' },
  { label: 'Tipacoque', value: 'Tipacoque' },
  { label: 'Țipar', value: 'Țipar' },
  { label: 'Tiparak', value: 'Tiparak' },
  { label: 'Tipasa', value: 'Tipasa' },
  { label: 'Tipaz', value: 'Tipaz' },
  { label: 'Tipitapa', value: 'Tipitapa' },
  { label: 'Tipolo', value: 'Tipolo' },
  { label: 'Tipo-Tipo', value: 'Tipo-Tipo' },
  { label: 'Tipo-Tipo', value: 'Tipo-Tipo' },
  { label: 'Tipp City', value: 'Tipp City' },
  { label: 'Tippah County', value: 'Tippah County' },
  { label: 'Tippecanoe County', value: 'Tippecanoe County' },
  { label: 'Tipperary', value: 'Tipperary' },
  { label: 'Tippi', value: 'Tippi' },
  { label: 'Țiptelnic', value: 'Țiptelnic' },
  { label: 'Tipton', value: 'Tipton' },
  { label: 'Tipton', value: 'Tipton' },
  { label: 'Tipton', value: 'Tipton' },
  { label: 'Tipton', value: 'Tipton' },
  { label: 'Tipton', value: 'Tipton' },
  { label: 'Tipton', value: 'Tipton' },
  { label: 'Tipton County', value: 'Tipton County' },
  { label: 'Tipton County', value: 'Tipton County' },
  { label: 'Tiptonville', value: 'Tiptonville' },
  { label: 'Tiptree', value: 'Tiptree' },
  { label: 'Tiptur', value: 'Tiptur' },
  { label: 'Tiquicheo', value: 'Tiquicheo' },
  { label: 'Tiquicheo De Nicolás Romero', value: 'Tiquicheo De Nicolás Romero' },
  { label: 'Tiquipaya', value: 'Tiquipaya' },
  { label: 'Tiquisate', value: 'Tiquisate' },
  { label: 'Tiquisio', value: 'Tiquisio' },
  { label: 'Tīr Pul', value: 'Tīr Pul' },
  { label: 'Tira Sujanpur', value: 'Tira Sujanpur' },
  { label: 'Tiradentes', value: 'Tiradentes' },
  { label: 'Tiradentes Do Sul', value: 'Tiradentes Do Sul' },
  { label: 'Tirah', value: 'Tirah' },
  { label: 'Tiran', value: 'Tiran' },
  { label: 'Tirana', value: 'Tirana' },
  { label: 'Tirano', value: 'Tirano' },
  { label: 'Tirap', value: 'Tirap' },
  { label: 'Tirapu', value: 'Tirapu' },
  { label: 'Tiraque Province', value: 'Tiraque Province' },
  { label: 'Tiraspol', value: 'Tiraspol' },
  { label: 'Tiraspolul Nou', value: 'Tiraspolul Nou' },
  { label: 'Tirat Karmel', value: 'Tirat Karmel' },
  { label: 'Tire', value: 'Tire' },
  { label: 'Tiream', value: 'Tiream' },
  { label: 'Tirebolu', value: 'Tirebolu' },
  { label: 'Tīreļi', value: 'Tīreļi' },
  { label: 'Tireo Arriba', value: 'Tireo Arriba' },
  { label: 'Tires', value: 'Tires' },
  { label: 'Tireu', value: 'Tireu' },
  { label: 'Tirgo', value: 'Tirgo' },
  { label: 'Tirhanimîne', value: 'Tirhanimîne' },
  { label: 'Tírig', value: 'Tírig' },
  { label: 'Tirimia', value: 'Tirimia' },
  { label: 'Tirimioara', value: 'Tirimioara' },
  { label: 'Tiríndaro', value: 'Tiríndaro' },
  { label: 'Tiring', value: 'Tiring' },
  { label: 'Tiriolo', value: 'Tiriolo' },
  { label: 'Tirípetio', value: 'Tirípetio' },
  { label: 'Tirlyanskiy', value: 'Tirlyanskiy' },
  { label: 'Tirmitine', value: 'Tirmitine' },
  { label: 'Tirmiz', value: 'Tirmiz' },
  { label: 'Tirodi', value: 'Tirodi' },
  { label: 'Tirolo', value: 'Tirolo' },
  { label: 'Tiros', value: 'Tiros' },
  { label: 'Tirour', value: 'Tirour' },
  { label: 'Tirpersdorf', value: 'Tirpersdorf' },
  { label: 'Tirschenreuth', value: 'Tirschenreuth' },
  { label: 'Tirthahalli', value: 'Tirthahalli' },
  { label: 'Tirúa', value: 'Tirúa' },
  { label: 'Tiruchchendur', value: 'Tiruchchendur' },
  { label: 'Tiruchengode', value: 'Tiruchengode' },
  { label: 'Tiruchirappalli', value: 'Tiruchirappalli' },
  { label: 'Tirukkoyilur', value: 'Tirukkoyilur' },
  { label: 'Tirumakudal Narsipur', value: 'Tirumakudal Narsipur' },
  { label: 'Tirumala', value: 'Tirumala' },
  { label: 'Tirumullaivasal', value: 'Tirumullaivasal' },
  { label: 'Tirunelveli', value: 'Tirunelveli' },
  { label: 'Tirunelveli Kattabo', value: 'Tirunelveli Kattabo' },
  { label: 'Tirupati', value: 'Tirupati' },
  { label: 'Tiruppalaikudi', value: 'Tiruppalaikudi' },
  { label: 'Tirupparangunram', value: 'Tirupparangunram' },
  { label: 'Tiruppur', value: 'Tiruppur' },
  { label: 'Tiruppuvanam', value: 'Tiruppuvanam' },
  { label: 'Tirur', value: 'Tirur' },
  { label: 'Tiruttangal', value: 'Tiruttangal' },
  { label: 'Tiruvalla', value: 'Tiruvalla' },
  { label: 'Tiruvannamalai', value: 'Tiruvannamalai' },
  { label: 'Tiruvottiyur', value: 'Tiruvottiyur' },
  { label: 'Tírvia', value: 'Tírvia' },
  { label: 'Tisa', value: 'Tisa' },
  { label: 'Tisaiyanvilai', value: 'Tisaiyanvilai' },
  { label: 'Tisbury', value: 'Tisbury' },
  { label: 'Tisdale', value: 'Tisdale' },
  { label: 'Tishchenskoye', value: 'Tishchenskoye' },
  { label: 'Tishomingo', value: 'Tishomingo' },
  { label: 'Tishomingo County', value: 'Tishomingo County' },
  { label: 'Tišice', value: 'Tišice' },
  { label: 'Tisieu', value: 'Tisieu' },
  { label: 'Tišina', value: 'Tišina' },
  { label: 'Tisma', value: 'Tisma' },
  { label: 'Tismana', value: 'Tismana' },
  { label: 'Tisno', value: 'Tisno' },
  { label: 'Tišnov', value: 'Tišnov' },
  { label: 'Tisovec', value: 'Tisovec' },
  { label: 'Tissemsilt', value: 'Tissemsilt' },
  { label: 'Tissi', value: 'Tissi' },
  { label: 'Tisul’', value: 'Tisul’' },
  { label: 'Tiszaalpár', value: 'Tiszaalpár' },
  { label: 'Tiszabercel', value: 'Tiszabercel' },
  { label: 'Tiszabezdéd', value: 'Tiszabezdéd' },
  { label: 'Tiszabő', value: 'Tiszabő' },
  { label: 'Tiszabura', value: 'Tiszabura' },
  { label: 'Tiszacsege', value: 'Tiszacsege' },
  { label: 'Tiszadada', value: 'Tiszadada' },
  { label: 'Tiszadob', value: 'Tiszadob' },
  { label: 'Tiszaeszlár', value: 'Tiszaeszlár' },
  { label: 'Tiszaföldvár', value: 'Tiszaföldvár' },
  { label: 'Tiszafüred', value: 'Tiszafüred' },
  { label: 'Tiszafüredi Járás', value: 'Tiszafüredi Járás' },
  { label: 'Tiszahegyes', value: 'Tiszahegyes' },
  { label: 'Tiszakarád', value: 'Tiszakarád' },
  { label: 'Tiszakécske', value: 'Tiszakécske' },
  { label: 'Tiszakécskei Járás', value: 'Tiszakécskei Járás' },
  { label: 'Tiszakeszi', value: 'Tiszakeszi' },
  { label: 'Tiszalök', value: 'Tiszalök' },
  { label: 'Tiszalúc', value: 'Tiszalúc' },
  { label: 'Tiszanagyfalu', value: 'Tiszanagyfalu' },
  { label: 'Tiszanána', value: 'Tiszanána' },
  { label: 'Tiszapüspöki', value: 'Tiszapüspöki' },
  { label: 'Tiszaroff', value: 'Tiszaroff' },
  { label: 'Tiszasüly', value: 'Tiszasüly' },
  { label: 'Tiszaszentimre', value: 'Tiszaszentimre' },
  { label: 'Tiszaszőlős', value: 'Tiszaszőlős' },
  { label: 'Tiszaújváros', value: 'Tiszaújváros' },
  { label: 'Tiszaújvárosi Járás', value: 'Tiszaújvárosi Járás' },
  { label: 'Tiszavasvári', value: 'Tiszavasvári' },
  { label: 'Tiszavasvári Járás', value: 'Tiszavasvári Járás' },
  { label: 'Tit Mellil', value: 'Tit Mellil' },
  { label: 'Titabar', value: 'Titabar' },
  { label: 'Titagarh', value: 'Titagarh' },
  { label: 'Titaguas', value: 'Titaguas' },
  { label: 'Titao', value: 'Titao' },
  { label: 'Titay', value: 'Titay' },
  { label: 'Titel', value: 'Titel' },
  { label: 'Titeşti', value: 'Titeşti' },
  { label: 'Titești', value: 'Titești' },
  { label: 'Titiana', value: 'Titiana' },
  { label: 'Titirangi', value: 'Titirangi' },
  { label: 'Titiribí', value: 'Titiribí' },
  { label: 'Titisee-Neustadt', value: 'Titisee-Neustadt' },
  { label: 'Titkanlu', value: 'Titkanlu' },
  { label: 'Titlagarh', value: 'Titlagarh' },
  { label: 'Tito', value: 'Tito' },
  { label: 'Titron', value: 'Titron' },
  { label: 'Tittling', value: 'Tittling' },
  { label: 'Tittmoning', value: 'Tittmoning' },
  { label: 'Titu', value: 'Titu' },
  { label: 'Titulcia', value: 'Titulcia' },
  { label: 'Titus County', value: 'Titus County' },
  { label: 'Titusville', value: 'Titusville' },
  { label: 'Titusville', value: 'Titusville' },
  { label: 'Titz', value: 'Titz' },
  { label: 'Tiur', value: 'Tiur' },
  { label: 'Tiurana', value: 'Tiurana' },
  { label: 'Tivaouane', value: 'Tivaouane' },
  { label: 'Tivat', value: 'Tivat' },
  { label: 'Tivenys', value: 'Tivenys' },
  { label: 'Tiverton', value: 'Tiverton' },
  { label: 'Tiverton', value: 'Tiverton' },
  { label: 'Tivissa', value: 'Tivissa' },
  { label: 'Tivoli', value: 'Tivoli' },
  { label: 'Tivoli', value: 'Tivoli' },
  { label: 'Tivoli', value: 'Tivoli' },
  { label: 'Tivoli', value: 'Tivoli' },
  { label: 'Tivoli Gardens', value: 'Tivoli Gardens' },
  { label: 'Tivolille Pasquali-Merenzata', value: 'Tivolille Pasquali-Merenzata' },
  { label: 'Tiwi', value: 'Tiwi' },
  { label: 'Tiwi', value: 'Tiwi' },
  { label: 'Tiwi', value: 'Tiwi' },
  { label: 'Tiwi Islands', value: 'Tiwi Islands' },
  { label: 'Tixcacalcupul', value: 'Tixcacalcupul' },
  { label: 'Tixcacaltuyub', value: 'Tixcacaltuyub' },
  { label: 'Tixcancal', value: 'Tixcancal' },
  { label: 'Tixhualactún', value: 'Tixhualactún' },
  { label: 'Tixkokob', value: 'Tixkokob' },
  { label: 'Tixmadeje Chiquito', value: 'Tixmadeje Chiquito' },
  { label: 'Tixmehuac', value: 'Tixmehuac' },
  { label: 'Tixpehual', value: 'Tixpehual' },
  { label: 'Tixtla De Guerrero', value: 'Tixtla De Guerrero' },
  { label: 'Tixtlancingo', value: 'Tixtlancingo' },
  { label: 'Tizapán El Alto', value: 'Tizapán El Alto' },
  { label: 'Tizayuca', value: 'Tizayuca' },
  { label: 'Tizi Gheniff', value: 'Tizi Gheniff' },
  { label: 'Tizi Ouzou', value: 'Tizi Ouzou' },
  { label: 'Tizi Rached', value: 'Tizi Rached' },
  { label: 'Tizimín', value: 'Tizimín' },
  { label: 'Tizi-N-Tleta', value: 'Tizi-N-Tleta' },
  { label: 'Tiznit', value: 'Tiznit' },
  { label: 'Tiztoutine', value: 'Tiztoutine' },
  { label: 'Tizzano Val Parma', value: 'Tizzano Val Parma' },
  { label: 'Tjæreborg', value: 'Tjæreborg' },
  { label: 'Tjeldsund', value: 'Tjeldsund' },
  { label: 'Tjøme', value: 'Tjøme' },
  { label: 'Tjörneshreppur', value: 'Tjörneshreppur' },
  { label: 'Tjuvkil', value: 'Tjuvkil' },
  { label: 'Tkon', value: 'Tkon' },
  { label: 'Tlacamilco', value: 'Tlacamilco' },
  { label: 'Tlachaloya', value: 'Tlachaloya' },
  { label: 'Tlachichila', value: 'Tlachichila' },
  { label: 'Tlachichilco', value: 'Tlachichilco' },
  { label: 'Tlachichilco', value: 'Tlachichilco' },
  { label: 'Tlachichuca', value: 'Tlachichuca' },
  { label: 'Tlacoachistlahuaca', value: 'Tlacoachistlahuaca' },
  { label: 'Tlacoapa', value: 'Tlacoapa' },
  { label: 'Tlacoatzintepec', value: 'Tlacoatzintepec' },
  { label: 'Tlacoaxtla', value: 'Tlacoaxtla' },
  { label: 'Tlacojalpan', value: 'Tlacojalpan' },
  { label: 'Tlacolula', value: 'Tlacolula' },
  { label: 'Tlacolula De Matamoros', value: 'Tlacolula De Matamoros' },
  { label: 'Tlacolulan', value: 'Tlacolulan' },
  { label: 'Tlacomulco', value: 'Tlacomulco' },
  { label: 'Tlacomulco', value: 'Tlacomulco' },
  { label: 'Tlacotalpan', value: 'Tlacotalpan' },
  { label: 'Tlacote El Bajo', value: 'Tlacote El Bajo' },
  { label: 'Tlacotepec', value: 'Tlacotepec' },
  { label: 'Tlacotepec', value: 'Tlacotepec' },
  { label: 'Tlacotepec', value: 'Tlacotepec' },
  { label: 'Tlacotepec (San Mateo)', value: 'Tlacotepec (San Mateo)' },
  { label: 'Tlacotepec De Benito Juárez', value: 'Tlacotepec De Benito Juárez' },
  { label: 'Tlacotepec De José Manzo', value: 'Tlacotepec De José Manzo' },
  { label: 'Tlacotepec De Mejía', value: 'Tlacotepec De Mejía' },
  { label: 'Tlacpac', value: 'Tlacpac' },
  { label: 'Tlacuilola', value: 'Tlacuilola' },
  { label: 'Tlacuilolapan', value: 'Tlacuilolapan' },
  { label: 'Tlacuilotepec', value: 'Tlacuilotepec' },
  { label: 'Tlacuitapan', value: 'Tlacuitapan' },
  { label: 'Tlacuitlapa', value: 'Tlacuitlapa' },
  { label: 'Tláhuac', value: 'Tláhuac' },
  { label: 'Tlahualilo De Zaragoza', value: 'Tlahualilo De Zaragoza' },
  { label: 'Tlahuapa', value: 'Tlahuapa' },
  { label: 'Tlahuapan', value: 'Tlahuapan' },
  { label: 'Tlahuelilpan', value: 'Tlahuelilpan' },
  { label: 'Tlahuelompa (San Francisco Tlahuelompa)', value: 'Tlahuelompa (San Francisco Tlahuelompa)' },
  { label: 'Tlahuiltepa', value: 'Tlahuiltepa' },
  { label: 'Tlahuitoltepec', value: 'Tlahuitoltepec' },
  { label: 'Tlaixpan', value: 'Tlaixpan' },
  { label: 'Tlajomulco De Zuñiga', value: 'Tlajomulco De Zuñiga' },
  { label: 'Tlalchapa', value: 'Tlalchapa' },
  { label: 'Tlalchiyahualica', value: 'Tlalchiyahualica' },
  { label: 'Tlalconteno', value: 'Tlalconteno' },
  { label: 'Tlalcozotitlán', value: 'Tlalcozotitlán' },
  { label: 'Tlalixcoyan', value: 'Tlalixcoyan' },
  { label: 'Tlalixtac De Cabrera', value: 'Tlalixtac De Cabrera' },
  { label: 'Tlalixtaquilla', value: 'Tlalixtaquilla' },
  { label: 'Tlalmanalco', value: 'Tlalmanalco' },
  { label: 'Tlalminulpa', value: 'Tlalminulpa' },
  { label: 'Tlalnelhuayocan', value: 'Tlalnelhuayocan' },
  { label: 'Tlalnepantla', value: 'Tlalnepantla' },
  { label: 'Tlalnepantla', value: 'Tlalnepantla' },
  { label: 'Tlalnepantla', value: 'Tlalnepantla' },
  { label: 'Tlalnepantla De Baz', value: 'Tlalnepantla De Baz' },
  { label: 'Tlalpan', value: 'Tlalpan' },
  { label: 'Tlalpujahua De Rayón', value: 'Tlalpujahua De Rayón' },
  { label: 'Tlaltecahuacán', value: 'Tlaltecahuacán' },
  { label: 'Tlaltempanapa', value: 'Tlaltempanapa' },
  { label: 'Tlaltenango', value: 'Tlaltenango' },
  { label: 'Tlaltenango De Sánchez Román', value: 'Tlaltenango De Sánchez Román' },
  { label: 'Tlaltenanguito', value: 'Tlaltenanguito' },
  { label: 'Tlaltepango', value: 'Tlaltepango' },
  { label: 'Tlaltetela', value: 'Tlaltetela' },
  { label: 'Tlaltetelco (San Miguel Tlaltetelco)', value: 'Tlaltetelco (San Miguel Tlaltetelco)' },
  { label: 'Tlaltizapán', value: 'Tlaltizapán' },
  { label: 'Tlamacazapa', value: 'Tlamacazapa' },
  { label: 'Tlamaco (San Gerónimo Tlamaco)', value: 'Tlamaco (San Gerónimo Tlamaco)' },
  { label: 'Tlamanca', value: 'Tlamanca' },
  { label: 'Tlamanca De Hernández', value: 'Tlamanca De Hernández' },
  { label: 'Tlamatoca', value: 'Tlamatoca' },
  { label: 'Tlamaya Grande', value: 'Tlamaya Grande' },
  { label: 'Tlaminca', value: 'Tlaminca' },
  { label: 'Tlamixtlahuacan', value: 'Tlamixtlahuacan' },
  { label: 'Tlanalapa', value: 'Tlanalapa' },
  { label: 'Tlanalapan', value: 'Tlanalapan' },
  { label: 'Tlanchinol', value: 'Tlanchinol' },
  { label: 'Tlancualpican', value: 'Tlancualpican' },
  { label: 'Tlanepantla', value: 'Tlanepantla' },
  { label: 'Tlanipatla', value: 'Tlanipatla' },
  { label: 'Tlaola', value: 'Tlaola' },
  { label: 'Tlapa De Comonfort', value: 'Tlapa De Comonfort' },
  { label: 'Tlapacoya', value: 'Tlapacoya' },
  { label: 'Tlapacoyan', value: 'Tlapacoyan' },
  { label: 'Tlapala', value: 'Tlapala' },
  { label: 'Tlapanalá', value: 'Tlapanalá' },
  { label: 'Tlapanaloya', value: 'Tlapanaloya' },
  { label: 'Tlapehuala', value: 'Tlapehuala' },
  { label: 'Tlaquepaque', value: 'Tlaquepaque' },
  { label: 'Tlaquiltenango', value: 'Tlaquiltenango' },
  { label: 'Tlaquiltepec', value: 'Tlaquiltepec' },
  { label: 'Tlatempa', value: 'Tlatempa' },
  { label: 'Tlatempan', value: 'Tlatempan' },
  { label: 'Tlatenchi', value: 'Tlatenchi' },
  { label: 'Tlatilpa', value: 'Tlatilpa' },
  { label: 'Tlatlauquitepec', value: 'Tlatlauquitepec' },
  { label: 'Tlatlauquitepec', value: 'Tlatlauquitepec' },
  { label: 'Tlatlaya', value: 'Tlatlaya' },
  { label: 'Tlatzala', value: 'Tlatzala' },
  { label: 'Tlatzala', value: 'Tlatzala' },
  { label: 'Tlaxcala', value: 'Tlaxcala' },
  { label: 'Tlaxcalantongo', value: 'Tlaxcalantongo' },
  { label: 'Tlaxcalilla', value: 'Tlaxcalilla' },
  { label: 'Tlaxcalixtlahuaca', value: 'Tlaxcalixtlahuaca' },
  { label: 'Tlaxco', value: 'Tlaxco' },
  { label: 'Tlaxco', value: 'Tlaxco' },
  { label: 'Tlaxco (Santiago Tlaxco)', value: 'Tlaxco (Santiago Tlaxco)' },
  { label: 'Tlaxcoapan', value: 'Tlaxcoapan' },
  { label: 'Tlaxinga (Clatzinga)', value: 'Tlaxinga (Clatzinga)' },
  { label: 'Tlaxmalac', value: 'Tlaxmalac' },
  { label: 'Tlaxocoyucan', value: 'Tlaxocoyucan' },
  { label: 'Tlaxpanaloya', value: 'Tlaxpanaloya' },
  { label: 'Tlayacapan', value: 'Tlayacapan' },
  { label: 'Tlayehualancingo', value: 'Tlayehualancingo' },
  { label: 'Tlazala De Fabela', value: 'Tlazala De Fabela' },
  { label: 'Tlazazalca', value: 'Tlazazalca' },
  { label: 'Tlemcen', value: 'Tlemcen' },
  { label: 'Tlilapan', value: 'Tlilapan' },
  { label: 'Tlixco', value: 'Tlixco' },
  { label: 'Tlmače', value: 'Tlmače' },
  { label: 'Tłuchowo', value: 'Tłuchowo' },
  { label: 'Tlučná', value: 'Tlučná' },
  { label: 'Tlumach', value: 'Tlumach' },
  { label: 'Tlumačov', value: 'Tlumačov' },
  { label: 'Tlumats’Kyy Rayon', value: 'Tlumats’Kyy Rayon' },
  { label: 'Tłuszcz', value: 'Tłuszcz' },
  { label: 'Tlyarata', value: 'Tlyarata' },
  { label: 'Tlyaratinskiy Rayon', value: 'Tlyaratinskiy Rayon' },
  { label: 'Tlyustenkhabl’', value: 'Tlyustenkhabl’' },
  { label: 'Tmourghout', value: 'Tmourghout' },
  { label: 'To‘Rqao‘Rg‘On', value: 'To‘Rqao‘Rg‘On' },
  { label: 'Toa Alta', value: 'Toa Alta' },
  { label: 'Toa Alta', value: 'Toa Alta' },
  { label: 'Toa Alta Municipio', value: 'Toa Alta Municipio' },
  { label: 'Toa Baja', value: 'Toa Baja' },
  { label: 'Toa Baja', value: 'Toa Baja' },
  { label: 'Toa Payoh', value: 'Toa Payoh' },
  { label: 'Toabré', value: 'Toabré' },
  { label: 'Toaca', value: 'Toaca' },
  { label: 'Tóalmás', value: 'Tóalmás' },
  { label: 'Toamasina', value: 'Toamasina' },
  { label: 'Toamasina I', value: 'Toamasina I' },
  { label: 'Toamasina Ii', value: 'Toamasina Ii' },
  { label: 'Toano', value: 'Toano' },
  { label: 'Toast', value: 'Toast' },
  { label: 'Toba', value: 'Toba' },
  { label: 'Toba', value: 'Toba' },
  { label: 'Toba La', value: 'Toba La' },
  { label: 'Toba Tek Singh', value: 'Toba Tek Singh' },
  { label: 'Tobaccoville', value: 'Tobaccoville' },
  { label: 'Tobadill', value: 'Tobadill' },
  { label: 'Tobaj', value: 'Tobaj' },
  { label: 'Tobane', value: 'Tobane' },
  { label: 'Tobar', value: 'Tobar' },
  { label: 'Tobarra', value: 'Tobarra' },
  { label: 'Toba-Shi', value: 'Toba-Shi' },
  { label: 'Tobatí', value: 'Tobatí' },
  { label: 'Tobed', value: 'Tobed' },
  { label: 'Tobelo', value: 'Tobelo' },
  { label: 'Tobercurry', value: 'Tobercurry' },
  { label: 'Tobermory', value: 'Tobermory' },
  { label: 'Tobermory', value: 'Tobermory' },
  { label: 'Tōbetsu', value: 'Tōbetsu' },
  { label: 'Tobi Village', value: 'Tobi Village' },
  { label: 'Tobía', value: 'Tobía' },
  { label: 'Tobias Barreto', value: 'Tobias Barreto' },
  { label: 'Tobias Fornier', value: 'Tobias Fornier' },
  { label: 'Tobobe', value: 'Tobobe' },
  { label: 'Tobol', value: 'Tobol' },
  { label: 'Tobol’Sk', value: 'Tobol’Sk' },
  { label: 'Toboliu', value: 'Toboliu' },
  { label: 'Tobosi', value: 'Tobosi' },
  { label: 'Toboso', value: 'Toboso' },
  { label: 'Toboso El', value: 'Toboso El' },
  { label: 'Toboy', value: 'Toboy' },
  { label: 'Toboy', value: 'Toboy' },
  { label: 'Tobuan', value: 'Tobuan' },
  { label: 'Tobuan', value: 'Tobuan' },
  { label: 'Toca', value: 'Toca' },
  { label: 'Tocache', value: 'Tocache' },
  { label: 'Tocaima', value: 'Tocaima' },
  { label: 'Tocancipá', value: 'Tocancipá' },
  { label: 'Tocane-Saint-Apre', value: 'Tocane-Saint-Apre' },
  { label: 'Tocantínia', value: 'Tocantínia' },
  { label: 'Tocantinópolis', value: 'Tocantinópolis' },
  { label: 'Tocantins', value: 'Tocantins' },
  { label: 'Tocatlán', value: 'Tocatlán' },
  { label: 'Tocco Caudio', value: 'Tocco Caudio' },
  { label: 'Tocco Da Casauria', value: 'Tocco Da Casauria' },
  { label: 'Toccoa', value: 'Toccoa' },
  { label: 'Toceno', value: 'Toceno' },
  { label: 'Tocha', value: 'Tocha' },
  { label: 'Tochigi-Shi', value: 'Tochigi-Shi' },
  { label: 'Tochilkin', value: 'Tochilkin' },
  { label: 'Tochimilco', value: 'Tochimilco' },
  { label: 'Tochio-Honchō', value: 'Tochio-Honchō' },
  { label: 'Tochtepec', value: 'Tochtepec' },
  { label: 'Tocileni', value: 'Tocileni' },
  { label: 'Tocina', value: 'Tocina' },
  { label: 'Töcksfors', value: 'Töcksfors' },
  { label: 'Tocoa', value: 'Tocoa' },
  { label: 'Tocob Leglemal', value: 'Tocob Leglemal' },
  { label: 'Tococ East', value: 'Tococ East' },
  { label: 'Tococ East', value: 'Tococ East' },
  { label: 'Tocok', value: 'Tocok' },
  { label: 'Tocok', value: 'Tocok' },
  { label: 'Tocopilla', value: 'Tocopilla' },
  { label: 'Tocos Do Moji', value: 'Tocos Do Moji' },
  { label: 'Tocoy', value: 'Tocoy' },
  { label: 'Tocuila', value: 'Tocuila' },
  { label: 'Tocumbo', value: 'Tocumbo' },
  { label: 'Tocumen', value: 'Tocumen' },
  { label: 'Tocumwal', value: 'Tocumwal' },
  { label: 'Tocuyito', value: 'Tocuyito' },
  { label: 'Todabhim', value: 'Todabhim' },
  { label: 'Todaraisingh', value: 'Todaraisingh' },
  { label: 'Toda-Shi', value: 'Toda-Shi' },
  { label: 'Todd County', value: 'Todd County' },
  { label: 'Todd County', value: 'Todd County' },
  { label: 'Todd County', value: 'Todd County' },
  { label: 'Todd Creek', value: 'Todd Creek' },
  { label: 'Toddington', value: 'Toddington' },
  { label: 'Todenbüttel', value: 'Todenbüttel' },
  { label: 'Todendorf', value: 'Todendorf' },
  { label: 'Todesfelde', value: 'Todesfelde' },
  { label: 'Todi', value: 'Todi' },
  { label: 'Todireni', value: 'Todireni' },
  { label: 'Todireşti', value: 'Todireşti' },
  { label: 'Todireşti', value: 'Todireşti' },
  { label: 'Todireşti', value: 'Todireşti' },
  { label: 'Todmorden', value: 'Todmorden' },
  { label: 'Todolella', value: 'Todolella' },
  { label: 'Todorovo', value: 'Todorovo' },
  { label: 'Todos Santos', value: 'Todos Santos' },
  { label: 'Todos Santos Cuchumatán', value: 'Todos Santos Cuchumatán' },
  { label: 'Todtenweis', value: 'Todtenweis' },
  { label: 'Todtmoos', value: 'Todtmoos' },
  { label: 'Todtnau', value: 'Todtnau' },
  { label: 'Todwick', value: 'Todwick' },
  { label: 'Toén', value: 'Toén' },
  { label: 'Tofalău', value: 'Tofalău' },
  { label: 'Toffen', value: 'Toffen' },
  { label: 'Toffia', value: 'Toffia' },
  { label: 'Tofield', value: 'Tofield' },
  { label: 'Tofino', value: 'Tofino' },
  { label: 'Toflea', value: 'Toflea' },
  { label: 'Tofol', value: 'Tofol' },
  { label: 'Tofte', value: 'Tofte' },
  { label: 'Toftlund', value: 'Toftlund' },
  { label: 'Toftrees', value: 'Toftrees' },
  { label: 'Toga', value: 'Toga' },
  { label: 'Toga Village', value: 'Toga Village' },
  { label: 'Tōgane', value: 'Tōgane' },
  { label: 'Tōgane-Shi', value: 'Tōgane-Shi' },
  { label: 'Togari', value: 'Togari' },
  { label: 'Togba', value: 'Togba' },
  { label: 'Töging Am Inn', value: 'Töging Am Inn' },
  { label: 'Togitsu', value: 'Togitsu' },
  { label: 'Toguchin', value: 'Toguchin' },
  { label: 'Togüí', value: 'Togüí' },
  { label: 'Togul', value: 'Togul' },
  { label: 'Togul’Skiy Rayon', value: 'Togul’Skiy Rayon' },
  { label: 'Togur', value: 'Togur' },
  { label: 'Tohana', value: 'Tohana' },
  { label: 'Tohanu Nou', value: 'Tohanu Nou' },
  { label: 'Tohat', value: 'Tohat' },
  { label: 'Tohid', value: 'Tohid' },
  { label: 'Tohmajärvi', value: 'Tohmajärvi' },
  { label: 'Toholampi', value: 'Toholampi' },
  { label: 'Toijala', value: 'Toijala' },
  { label: 'Toila', value: 'Toila' },
  { label: 'Toirano', value: 'Toirano' },
  { label: 'Toivakka', value: 'Toivakka' },
  { label: 'Tojo Una-Una Regency', value: 'Tojo Una-Una Regency' },
  { label: 'Tojos Los', value: 'Tojos Los' },
  { label: 'Tojšići', value: 'Tojšići' },
  { label: 'Tok', value: 'Tok' },
  { label: 'Tōkai-Shi', value: 'Tōkai-Shi' },
  { label: 'Tokaj', value: 'Tokaj' },
  { label: 'Tokaji Járás', value: 'Tokaji Járás' },
  { label: 'Tōkamachi', value: 'Tōkamachi' },
  { label: 'Tōkamachi-Shi', value: 'Tōkamachi-Shi' },
  { label: 'Tokār', value: 'Tokār' },
  { label: 'Tokarevka', value: 'Tokarevka' },
  { label: 'Tokarevo', value: 'Tokarevo' },
  { label: 'Tokarevskiy Rayon', value: 'Tokarevskiy Rayon' },
  { label: 'Tokarnia', value: 'Tokarnia' },
  { label: 'Toki', value: 'Toki' },
  { label: 'Toki-Shi', value: 'Toki-Shi' },
  { label: 'Tokke', value: 'Tokke' },
  { label: 'Tokmak', value: 'Tokmak' },
  { label: 'Tokmak Raion', value: 'Tokmak Raion' },
  { label: 'Tokmok', value: 'Tokmok' },
  { label: 'Tokodaltáró', value: 'Tokodaltáró' },
  { label: 'Tököl', value: 'Tököl' },
  { label: 'Tokombere', value: 'Tokombere' },
  { label: 'Tokoname', value: 'Tokoname' },
  { label: 'Tokoname-Shi', value: 'Tokoname-Shi' },
  { label: 'Tokonou', value: 'Tokonou' },
  { label: 'Tokoroa', value: 'Tokoroa' },
  { label: 'Tokorozawa', value: 'Tokorozawa' },
  { label: 'Tokorozawa-Shi', value: 'Tokorozawa-Shi' },
  { label: 'Toksovo', value: 'Toksovo' },
  { label: 'Toktogul', value: 'Toktogul' },
  { label: 'Toktogul District', value: 'Toktogul District' },
  { label: 'Tokur', value: 'Tokur' },
  { label: 'Tokushima', value: 'Tokushima' },
  { label: 'Tokushima Shi', value: 'Tokushima Shi' },
  { label: 'Tokuyama', value: 'Tokuyama' },
  { label: 'Tokyo', value: 'Tokyo' },
  { label: 'Tol’Yatti', value: 'Tol’Yatti' },
  { label: 'Tola', value: 'Tola' },
  { label: 'Tolbaños', value: 'Tolbaños' },
  { label: 'Tolbazy', value: 'Tolbazy' },
  { label: 'Tolcayuca', value: 'Tolcayuca' },
  { label: 'Tolcsva', value: 'Tolcsva' },
  { label: 'Toldal', value: 'Toldal' },
  { label: 'Tolé', value: 'Tolé' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Toledo', value: 'Toledo' },
  { label: 'Tolensom Municipality', value: 'Tolensom Municipality' },
  { label: 'Tolentino', value: 'Tolentino' },
  { label: 'Tolfa', value: 'Tolfa' },
  { label: 'Tolga', value: 'Tolga' },
  { label: 'Tolga', value: 'Tolga' },
  { label: 'Tolga', value: 'Tolga' },
  { label: 'Tolhuin', value: 'Tolhuin' },
  { label: 'Țolici', value: 'Țolici' },
  { label: 'Tolimán', value: 'Tolimán' },
  { label: 'Tolimán Municipality', value: 'Tolimán Municipality' },
  { label: 'Tolk', value: 'Tolk' },
  { label: 'Tolkamer', value: 'Tolkamer' },
  { label: 'Tolkmicko', value: 'Tolkmicko' },
  { label: 'Tolland', value: 'Tolland' },
  { label: 'Tolland', value: 'Tolland' },
  { label: 'Tolland County', value: 'Tolland County' },
  { label: 'Tollarp', value: 'Tollarp' },
  { label: 'Tollegno', value: 'Tollegno' },
  { label: 'Tollesbury', value: 'Tollesbury' },
  { label: 'Tolleshunt Knights', value: 'Tolleshunt Knights' },
  { label: 'Tolleson', value: 'Tolleson' },
  { label: 'Tollevast', value: 'Tollevast' },
  { label: 'Tollgate', value: 'Tollgate' },
  { label: 'Tollo', value: 'Tollo' },
  { label: 'Tollos', value: 'Tollos' },
  { label: 'Tølløse', value: 'Tølløse' },
  { label: 'Tollwitz', value: 'Tollwitz' },
  { label: 'Tolmachevo', value: 'Tolmachevo' },
  { label: 'Tolmachëvo', value: 'Tolmachëvo' },
  { label: 'Tolmezzo', value: 'Tolmezzo' },
  { label: 'Tolmin', value: 'Tolmin' },
  { label: 'Tolna', value: 'Tolna' },
  { label: 'Tolnai Járás', value: 'Tolnai Járás' },
  { label: 'Tolocirio', value: 'Tolocirio' },
  { label: 'Tolome', value: 'Tolome' },
  { label: 'Tolón', value: 'Tolón' },
  { label: 'Tolono', value: 'Tolono' },
  { label: 'Tolosa', value: 'Tolosa' },
  { label: 'Tolosa', value: 'Tolosa' },
  { label: 'Tolosa', value: 'Tolosa' },
  { label: 'Tolosa', value: 'Tolosa' },
  { label: 'Tolox', value: 'Tolox' },
  { label: 'Tolstopal’Tsevo', value: 'Tolstopal’Tsevo' },
  { label: 'Tolstoy-Yurt', value: 'Tolstoy-Yurt' },
  { label: 'Toltén', value: 'Toltén' },
  { label: 'Töltéstava', value: 'Töltéstava' },
  { label: 'Tolú Viejo', value: 'Tolú Viejo' },
  { label: 'Toluca', value: 'Toluca' },
  { label: 'Toluca', value: 'Toluca' },
  { label: 'Toluca De Guadalupe', value: 'Toluca De Guadalupe' },
  { label: 'Tolva', value: 'Tolva' },
  { label: 'Tolve', value: 'Tolve' },
  { label: 'Tom Bean', value: 'Tom Bean' },
  { label: 'Tom Green County', value: 'Tom Green County' },
  { label: 'Tom Price', value: 'Tom Price' },
  { label: 'Toma', value: 'Toma' },
  { label: 'Tomado', value: 'Tomado' },
  { label: 'Tomado', value: 'Tomado' },
  { label: 'Tomah', value: 'Tomah' },
  { label: 'Tomahawk', value: 'Tomahawk' },
  { label: 'Tomakin', value: 'Tomakin' },
  { label: 'Tomakivka', value: 'Tomakivka' },
  { label: 'Tomakomai', value: 'Tomakomai' },
  { label: 'Tomakomai Shi', value: 'Tomakomai Shi' },
  { label: 'Tomalá', value: 'Tomalá' },
  { label: 'Tomaquilapa', value: 'Tomaquilapa' },
  { label: 'Tomar', value: 'Tomar' },
  { label: 'Tomar Do Geru', value: 'Tomar Do Geru' },
  { label: 'Tomares', value: 'Tomares' },
  { label: 'Tomari', value: 'Tomari' },
  { label: 'Tomarovka', value: 'Tomarovka' },
  { label: 'Tomarza', value: 'Tomarza' },
  { label: 'Tomás Gomensoro', value: 'Tomás Gomensoro' },
  { label: 'Tomas Oppus', value: 'Tomas Oppus' },
  { label: 'Tomas Oppus', value: 'Tomas Oppus' },
  { label: 'Tomaševac', value: 'Tomaševac' },
  { label: 'Tomashpil’', value: 'Tomashpil’' },
  { label: 'Tomaszkowice', value: 'Tomaszkowice' },
  { label: 'Tomaszów Lubelski', value: 'Tomaszów Lubelski' },
  { label: 'Tomaszów Mazowiecki', value: 'Tomaszów Mazowiecki' },
  { label: 'Tomatlán', value: 'Tomatlán' },
  { label: 'Tomatlán', value: 'Tomatlán' },
  { label: 'Tomatlán', value: 'Tomatlán' },
  { label: 'Tomazina', value: 'Tomazina' },
  { label: 'Tomball', value: 'Tomball' },
  { label: 'Tombeau Bay', value: 'Tombeau Bay' },
  { label: 'Tombelle', value: 'Tombelle' },
  { label: 'Tomblaine', value: 'Tomblaine' },
  { label: 'Tombod', value: 'Tombod' },
  { label: 'Tombod', value: 'Tombod' },
  { label: 'Tombodu', value: 'Tombodu' },
  { label: 'Tombolo', value: 'Tombolo' },
  { label: 'Tombongon', value: 'Tombongon' },
  { label: 'Tombongon', value: 'Tombongon' },
  { label: 'Tombongon', value: 'Tombongon' },
  { label: 'Tombos', value: 'Tombos' },
  { label: 'Tombstone', value: 'Tombstone' },
  { label: 'Tombu', value: 'Tombu' },
  { label: 'Tome', value: 'Tome' },
  { label: 'Tomé', value: 'Tomé' },
  { label: 'Tomé Açu', value: 'Tomé Açu' },
  { label: 'Tome Shi', value: 'Tome Shi' },
  { label: 'Tomé-Açu', value: 'Tomé-Açu' },
  { label: 'Tomelilla', value: 'Tomelilla' },
  { label: 'Tomelloso', value: 'Tomelloso' },
  { label: 'Tomelopitos', value: 'Tomelopitos' },
  { label: 'Tomendán', value: 'Tomendán' },
  { label: 'Tomerong', value: 'Tomerong' },
  { label: 'Tomeşti', value: 'Tomeşti' },
  { label: 'Tomeşti', value: 'Tomeşti' },
  { label: 'Tomeşti', value: 'Tomeşti' },
  { label: 'Tomești', value: 'Tomești' },
  { label: 'Tōmi', value: 'Tōmi' },
  { label: 'Tōmi', value: 'Tōmi' },
  { label: 'Tōmi Shi', value: 'Tōmi Shi' },
  { label: 'Tomice', value: 'Tomice' },
  { label: 'Tomigusuku', value: 'Tomigusuku' },
  { label: 'Tomigusuku-Shi', value: 'Tomigusuku-Shi' },
  { label: 'Tomil Municipality', value: 'Tomil Municipality' },
  { label: 'Tomilino', value: 'Tomilino' },
  { label: 'Tomingad', value: 'Tomingad' },
  { label: 'Tominhao', value: 'Tominhao' },
  { label: 'Tomiño', value: 'Tomiño' },
  { label: 'Tomioka', value: 'Tomioka' },
  { label: 'Tomioka-Shi', value: 'Tomioka-Shi' },
  { label: 'Tomisato-Shi', value: 'Tomisato-Shi' },
  { label: 'Tomislavgrad', value: 'Tomislavgrad' },
  { label: 'Tomiya', value: 'Tomiya' },
  { label: 'Tommerup', value: 'Tommerup' },
  { label: 'Tommerup Stationsby', value: 'Tommerup Stationsby' },
  { label: 'Tommot', value: 'Tommot' },
  { label: 'Tomnatic', value: 'Tomnatic' },
  { label: 'Tomobe', value: 'Tomobe' },
  { label: 'Tomochic', value: 'Tomochic' },
  { label: 'Tomohon', value: 'Tomohon' },
  { label: 'Tomok Bolon', value: 'Tomok Bolon' },
  { label: 'Tömörkény', value: 'Tömörkény' },
  { label: 'Tompa', value: 'Tompa' },
  { label: 'Tompkins County', value: 'Tompkins County' },
  { label: 'Tompkinsville', value: 'Tompkinsville' },
  { label: 'Tompkinsville', value: 'Tompkinsville' },
  { label: 'Tomponskiy District', value: 'Tomponskiy District' },
  { label: 'Tomra', value: 'Tomra' },
  { label: 'Toms River', value: 'Toms River' },
  { label: 'Tomşani', value: 'Tomşani' },
  { label: 'Tomşani', value: 'Tomşani' },
  { label: 'Tomsk', value: 'Tomsk' },
  { label: 'Tomskiy Rayon', value: 'Tomskiy Rayon' },
  { label: 'Tomter', value: 'Tomter' },
  { label: 'Tona', value: 'Tona' },
  { label: 'Tona', value: 'Tona' },
  { label: 'Tonacatepeque', value: 'Tonacatepeque' },
  { label: 'Tonadico', value: 'Tonadico' },
  { label: 'Tonalá', value: 'Tonalá' },
  { label: 'Tonalá', value: 'Tonalá' },
  { label: 'Tonalá', value: 'Tonalá' },
  { label: 'Tonalá', value: 'Tonalá' },
  { label: 'Tonalaco', value: 'Tonalaco' },
  { label: 'Tonalapa', value: 'Tonalapa' },
  { label: 'Tonalapa Del Sur', value: 'Tonalapa Del Sur' },
  { label: 'Tonalapan', value: 'Tonalapan' },
  { label: 'Tonalixco', value: 'Tonalixco' },
  { label: 'Tonalixco', value: 'Tonalixco' },
  { label: 'Tonami Shi', value: 'Tonami Shi' },
  { label: 'Tonanitla', value: 'Tonanitla' },
  { label: 'Tonantins', value: 'Tonantins' },
  { label: 'Tonara', value: 'Tonara' },
  { label: 'Tonasket', value: 'Tonasket' },
  { label: 'Tonatico', value: 'Tonatico' },
  { label: 'Tonawanda', value: 'Tonawanda' },
  { label: 'Tonaya', value: 'Tonaya' },
  { label: 'Tonayán', value: 'Tonayán' },
  { label: 'Tonbridge', value: 'Tonbridge' },
  { label: 'Tonciu', value: 'Tonciu' },
  { label: 'Tonco', value: 'Tonco' },
  { label: 'Tondabayashi Shi', value: 'Tondabayashi Shi' },
  { label: 'Tondabayashichō', value: 'Tondabayashichō' },
  { label: 'Tondano', value: 'Tondano' },
  { label: 'Tondela', value: 'Tondela' },
  { label: 'Tønder', value: 'Tønder' },
  { label: 'Tønder Kommune', value: 'Tønder Kommune' },
  { label: 'Tondi', value: 'Tondi' },
  { label: 'Tondo', value: 'Tondo' },
  { label: 'Tondod', value: 'Tondod' },
  { label: 'Tondod', value: 'Tondod' },
  { label: 'Tondol', value: 'Tondol' },
  { label: 'Tondol', value: 'Tondol' },
  { label: 'Tondon', value: 'Tondon' },
  { label: 'Tonekabon', value: 'Tonekabon' },
  { label: 'Tonengo', value: 'Tonengo' },
  { label: 'Tonengo-Casale', value: 'Tonengo-Casale' },
  { label: 'Tonezza Del Cimone', value: 'Tonezza Del Cimone' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Tongala', value: 'Tongala' },
  { label: 'Tonganoxie', value: 'Tonganoxie' },
  { label: 'Tongchuan', value: 'Tongchuan' },
  { label: 'Tongchuanshi', value: 'Tongchuanshi' },
  { label: 'Tongelre', value: 'Tongelre' },
  { label: 'Tongeren', value: 'Tongeren' },
  { label: 'Tongging', value: 'Tongging' },
  { label: 'Tonggu', value: 'Tonggu' },
  { label: 'Tonghae', value: 'Tonghae' },
  { label: 'Tonghua', value: 'Tonghua' },
  { label: 'Tonghua Shi', value: 'Tonghua Shi' },
  { label: 'Tongliao', value: 'Tongliao' },
  { label: 'Tongole', value: 'Tongole' },
  { label: 'Tongouson', value: 'Tongouson' },
  { label: 'Tongouson', value: 'Tongouson' },
  { label: 'Tongren', value: 'Tongren' },
  { label: 'Tongren Diqu', value: 'Tongren Diqu' },
  { label: 'Tongshan', value: 'Tongshan' },
  { label: 'Tongwan', value: 'Tongwan' },
  { label: 'Tongzhou', value: 'Tongzhou' },
  { label: 'Toniataba', value: 'Toniataba' },
  { label: 'Tonila', value: 'Tonila' },
  { label: 'Tönisvorst', value: 'Tönisvorst' },
  { label: 'Tonk', value: 'Tonk' },
  { label: 'Tonka Bay', value: 'Tonka Bay' },
  { label: 'Tonkawa', value: 'Tonkawa' },
  { label: 'Tonkino', value: 'Tonkino' },
  { label: 'Tonkolili District', value: 'Tonkolili District' },
  { label: 'Tonkpi', value: 'Tonkpi' },
  { label: 'Tonnara Di Bonagia', value: 'Tonnara Di Bonagia' },
  { label: 'Tonnarella', value: 'Tonnarella' },
  { label: 'Tonnay-Boutonne', value: 'Tonnay-Boutonne' },
  { label: 'Tonnay-Charente', value: 'Tonnay-Charente' },
  { label: 'Tonneins', value: 'Tonneins' },
  { label: 'Tonnerre', value: 'Tonnerre' },
  { label: 'Tönning', value: 'Tönning' },
  { label: 'Tōno', value: 'Tōno' },
  { label: 'Tonoas Municipality', value: 'Tonoas Municipality' },
  { label: 'Tonopah', value: 'Tonopah' },
  { label: 'Tōno-Shi', value: 'Tōno-Shi' },
  { label: 'Tonoshō', value: 'Tonoshō' },
  { label: 'Tonosí', value: 'Tonosí' },
  { label: 'Tonota', value: 'Tonota' },
  { label: 'Tonquédec', value: 'Tonquédec' },
  { label: 'Tønsberg', value: 'Tønsberg' },
  { label: 'Tonshalovo', value: 'Tonshalovo' },
  { label: 'Tonshayevo', value: 'Tonshayevo' },
  { label: 'Tonstad', value: 'Tonstad' },
  { label: 'Tontitown', value: 'Tontitown' },
  { label: 'Tonto Basin', value: 'Tonto Basin' },
  { label: 'Tonya', value: 'Tonya' },
  { label: 'Tonypandy', value: 'Tonypandy' },
  { label: 'Tonyrefail', value: 'Tonyrefail' },
  { label: 'Toodeshk', value: 'Toodeshk' },
  { label: 'Toodyay', value: 'Toodyay' },
  { label: 'Tooele', value: 'Tooele' },
  { label: 'Tooele County', value: 'Tooele County' },
  { label: 'Toogoolawah', value: 'Toogoolawah' },
  { label: 'Toogoom', value: 'Toogoom' },
  { label: 'Tool', value: 'Tool' },
  { label: 'Toole County', value: 'Toole County' },
  { label: 'Toombs County', value: 'Toombs County' },
  { label: 'Toong', value: 'Toong' },
  { label: 'Toongabbie', value: 'Toongabbie' },
  { label: 'Tōon-Shi', value: 'Tōon-Shi' },
  { label: 'Tooradin', value: 'Tooradin' },
  { label: 'Toorak', value: 'Toorak' },
  { label: 'Toorak Gardens', value: 'Toorak Gardens' },
  { label: 'Toora-Khem', value: 'Toora-Khem' },
  { label: 'Toormina', value: 'Toormina' },
  { label: 'Tootgarook', value: 'Tootgarook' },
  { label: 'Tootsi', value: 'Tootsi' },
  { label: 'Toowong', value: 'Toowong' },
  { label: 'Toowoomba', value: 'Toowoomba' },
  { label: 'Top Hill', value: 'Top Hill' },
  { label: 'Top Hill', value: 'Top Hill' },
  { label: 'Țopa', value: 'Țopa' },
  { label: 'Tópaga', value: 'Tópaga' },
  { label: 'Topaipí', value: 'Topaipí' },
  { label: 'Topalu', value: 'Topalu' },
  { label: 'Topana', value: 'Topana' },
  { label: 'Topanga', value: 'Topanga' },
  { label: 'Topas', value: 'Topas' },
  { label: 'Topaz Ranch Estates', value: 'Topaz Ranch Estates' },
  { label: 'Topchanchi', value: 'Topchanchi' },
  { label: 'Topchikha', value: 'Topchikha' },
  { label: 'Topdac', value: 'Topdac' },
  { label: 'Topdac', value: 'Topdac' },
  { label: 'Topeka', value: 'Topeka' },
  { label: 'Topeka', value: 'Topeka' },
  { label: 'Töpen', value: 'Töpen' },
  { label: 'Topes De Collantes', value: 'Topes De Collantes' },
  { label: 'Topi', value: 'Topi' },
  { label: 'Topia', value: 'Topia' },
  { label: 'Topilco De Juárez', value: 'Topilco De Juárez' },
  { label: 'Topile', value: 'Topile' },
  { label: 'Topiltepec', value: 'Topiltepec' },
  { label: 'Topki', value: 'Topki' },
  { label: 'Topleţ', value: 'Topleţ' },
  { label: 'Toplica', value: 'Toplica' },
  { label: 'Topliceni', value: 'Topliceni' },
  { label: 'Topliţa', value: 'Topliţa' },
  { label: 'Toplița', value: 'Toplița' },
  { label: 'Topol’Noye', value: 'Topol’Noye' },
  { label: 'Topolčani', value: 'Topolčani' },
  { label: 'Topoľčany', value: 'Topoľčany' },
  { label: 'Topolëvo', value: 'Topolëvo' },
  { label: 'Topolița', value: 'Topolița' },
  { label: 'Topólka', value: 'Topólka' },
  { label: 'Topolná', value: 'Topolná' },
  { label: 'Topolobampo', value: 'Topolobampo' },
  { label: 'Topolog', value: 'Topolog' },
  { label: 'Topolovăţu Mare', value: 'Topolovăţu Mare' },
  { label: 'Topoloveni', value: 'Topoloveni' },
  { label: 'Topolovgrad', value: 'Topolovgrad' },
  { label: 'Topolovo', value: 'Topolovo' },
  { label: 'Topolšica', value: 'Topolšica' },
  { label: 'Toporu', value: 'Toporu' },
  { label: 'Topory', value: 'Topory' },
  { label: 'Topory', value: 'Topory' },
  { label: 'Toporzysko', value: 'Toporzysko' },
  { label: 'Toppenish', value: 'Toppenish' },
  { label: 'Toppenstedt', value: 'Toppenstedt' },
  { label: 'Topraisar', value: 'Topraisar' },
  { label: 'Toprakkale', value: 'Toprakkale' },
  { label: 'Topsfield', value: 'Topsfield' },
  { label: 'Topsham', value: 'Topsham' },
  { label: 'Topsham', value: 'Topsham' },
  { label: 'Topton', value: 'Topton' },
  { label: 'Toquerville', value: 'Toquerville' },
  { label: 'Toques', value: 'Toques' },
  { label: 'Tor Lupara', value: 'Tor Lupara' },
  { label: 'Tora', value: 'Tora' },
  { label: 'Torà', value: 'Torà' },
  { label: 'Toraille', value: 'Toraille' },
  { label: 'Torak', value: 'Torak' },
  { label: 'Toral De Los Guzmanes', value: 'Toral De Los Guzmanes' },
  { label: 'Toral De Los Vados', value: 'Toral De Los Vados' },
  { label: 'Torano Castello', value: 'Torano Castello' },
  { label: 'Torano Nuovo', value: 'Torano Nuovo' },
  { label: 'Torás', value: 'Torás' },
  { label: 'Torata', value: 'Torata' },
  { label: 'Torba', value: 'Torba' },
  { label: 'Torbalı', value: 'Torbalı' },
  { label: 'Torbat Heydariyeh', value: 'Torbat Heydariyeh' },
  { label: 'Torbat-E-Jam', value: 'Torbat-E-Jam' },
  { label: 'Torbay', value: 'Torbay' },
  { label: 'Torbeck', value: 'Torbeck' },
  { label: 'Torbeyevo', value: 'Torbeyevo' },
  { label: 'Torbole Casaglia', value: 'Torbole Casaglia' },
  { label: 'Torcé', value: 'Torcé' },
  { label: 'Torcegno', value: 'Torcegno' },
  { label: 'Torchiara', value: 'Torchiara' },
  { label: 'Torchiarolo', value: 'Torchiarolo' },
  { label: 'Torchiati', value: 'Torchiati' },
  { label: 'Torchione-Moia', value: 'Torchione-Moia' },
  { label: 'Torcy', value: 'Torcy' },
  { label: 'Torcy', value: 'Torcy' },
  { label: 'Torda', value: 'Torda' },
  { label: 'Tordehumos', value: 'Tordehumos' },
  { label: 'Tordellego', value: 'Tordellego' },
  { label: 'Tordelrábano', value: 'Tordelrábano' },
  { label: 'Tordera', value: 'Tordera' },
  { label: 'Tordesillas', value: 'Tordesillas' },
  { label: 'Tordesilos', value: 'Tordesilos' },
  { label: 'Tordillos', value: 'Tordillos' },
  { label: 'Tordinci', value: 'Tordinci' },
  { label: 'Tordoia', value: 'Tordoia' },
  { label: 'Tordómar', value: 'Tordómar' },
  { label: 'Töre', value: 'Töre' },
  { label: 'Töreboda', value: 'Töreboda' },
  { label: 'Torekov', value: 'Torekov' },
  { label: 'Torella Dei Lombardi', value: 'Torella Dei Lombardi' },
  { label: 'Torella Del Sannio', value: 'Torella Del Sannio' },
  { label: 'Torelli-Torrette', value: 'Torelli-Torrette' },
  { label: 'Torelló', value: 'Torelló' },
  { label: 'Toreno', value: 'Toreno' },
  { label: 'Toreo Bajo (El Toreo Bajo)', value: 'Toreo Bajo (El Toreo Bajo)' },
  { label: 'Toretsk', value: 'Toretsk' },
  { label: 'Torfaen County Borough', value: 'Torfaen County Borough' },
  { label: 'Torfou', value: 'Torfou' },
  { label: 'Torfyanoy', value: 'Torfyanoy' },
  { label: 'Torgau', value: 'Torgau' },
  { label: 'Torgelow', value: 'Torgelow' },
  { label: 'Torghabeh', value: 'Torghabeh' },
  { label: 'Torghay', value: 'Torghay' },
  { label: 'Torgiano', value: 'Torgiano' },
  { label: 'Torgnon', value: 'Torgnon' },
  { label: 'Torhout', value: 'Torhout' },
  { label: 'Tori Fatehpur', value: 'Tori Fatehpur' },
  { label: 'Tori Vald', value: 'Tori Vald' },
  { label: 'Toribio', value: 'Toribio' },
  { label: 'Toride', value: 'Toride' },
  { label: 'Toride-Shi', value: 'Toride-Shi' },
  { label: 'Tori-Fatehpur', value: 'Tori-Fatehpur' },
  { label: 'Torigni-Sur-Vire', value: 'Torigni-Sur-Vire' },
  { label: 'Torija', value: 'Torija' },
  { label: 'Toril', value: 'Toril' },
  { label: 'Toril Y Masegoso', value: 'Toril Y Masegoso' },
  { label: 'Torino', value: 'Torino' },
  { label: 'Torino Di Sangro', value: 'Torino Di Sangro' },
  { label: 'Toritama', value: 'Toritama' },
  { label: 'Toritori', value: 'Toritori' },
  { label: 'Toritori', value: 'Toritori' },
  { label: 'Toritto', value: 'Toritto' },
  { label: 'Torixoréu', value: 'Torixoréu' },
  { label: 'Torkalaki', value: 'Torkalaki' },
  { label: 'Torkaman', value: 'Torkaman' },
  { label: 'Torkanivka', value: 'Torkanivka' },
  { label: 'Torla', value: 'Torla' },
  { label: 'Torlengua', value: 'Torlengua' },
  { label: 'Torlino Vimercati', value: 'Torlino Vimercati' },
  { label: 'Tormac', value: 'Tormac' },
  { label: 'Tormantos', value: 'Tormantos' },
  { label: 'Tormellas', value: 'Tormellas' },
  { label: 'Tormestorp', value: 'Tormestorp' },
  { label: 'Tormón', value: 'Tormón' },
  { label: 'Tormos', value: 'Tormos' },
  { label: 'Torms Els', value: 'Torms Els' },
  { label: 'Tornabé', value: 'Tornabé' },
  { label: 'Tornabous', value: 'Tornabous' },
  { label: 'Tornaco', value: 'Tornaco' },
  { label: 'Tornadizo El', value: 'Tornadizo El' },
  { label: 'Tornadizos De Ávila', value: 'Tornadizos De Ávila' },
  { label: 'Tornado', value: 'Tornado' },
  { label: 'Tornareccio', value: 'Tornareccio' },
  { label: 'Tornata', value: 'Tornata' },
  { label: 'Tornavacas', value: 'Tornavacas' },
  { label: 'Tornesch', value: 'Tornesch' },
  { label: 'Tornillo', value: 'Tornillo' },
  { label: 'Tornimparte', value: 'Tornimparte' },
  { label: 'Tornio', value: 'Tornio' },
  { label: 'Torno', value: 'Torno' },
  { label: 'Torno El', value: 'Torno El' },
  { label: 'Tornolo', value: 'Tornolo' },
  { label: 'Tornos', value: 'Tornos' },
  { label: 'Tornyospálca', value: 'Tornyospálca' },
  { label: 'Toro', value: 'Toro' },
  { label: 'Toro', value: 'Toro' },
  { label: 'Toro', value: 'Toro' },
  { label: 'Toro Canyon', value: 'Toro Canyon' },
  { label: 'Toro El', value: 'Toro El' },
  { label: 'Törökbálint', value: 'Törökbálint' },
  { label: 'Törökszentmiklós', value: 'Törökszentmiklós' },
  { label: 'Törökszentmiklósi Járás', value: 'Törökszentmiklósi Járás' },
  { label: 'Toronto', value: 'Toronto' },
  { label: 'Toronto', value: 'Toronto' },
  { label: 'Toronto', value: 'Toronto' },
  { label: 'Toronto County', value: 'Toronto County' },
  { label: 'Toropets', value: 'Toropets' },
  { label: 'Toropetskiy Rayon', value: 'Toropetskiy Rayon' },
  { label: 'Toropi', value: 'Toropi' },
  { label: 'Tororo', value: 'Tororo' },
  { label: 'Toroslar', value: 'Toroslar' },
  { label: 'Torpè', value: 'Torpè' },
  { label: 'Torphins', value: 'Torphins' },
  { label: 'Torpoint', value: 'Torpoint' },
  { label: 'Torquay', value: 'Torquay' },
  { label: 'Torquay', value: 'Torquay' },
  { label: 'Torquay', value: 'Torquay' },
  { label: 'Torquemada', value: 'Torquemada' },
  { label: 'Torraca', value: 'Torraca' },
  { label: 'Torralba', value: 'Torralba' },
  { label: 'Torralba', value: 'Torralba' },
  { label: 'Torralba De Aragón', value: 'Torralba De Aragón' },
  { label: 'Torralba De Calatrava', value: 'Torralba De Calatrava' },
  { label: 'Torralba De Los Frailes', value: 'Torralba De Los Frailes' },
  { label: 'Torralba De Los Sisones', value: 'Torralba De Los Sisones' },
  { label: 'Torralba De Oropesa', value: 'Torralba De Oropesa' },
  { label: 'Torralba De Ribota', value: 'Torralba De Ribota' },
  { label: 'Torralba Del Pinar', value: 'Torralba Del Pinar' },
  { label: 'Torralba Del Río', value: 'Torralba Del Río' },
  { label: 'Torralbilla', value: 'Torralbilla' },
  { label: 'Torrance', value: 'Torrance' },
  { label: 'Torrance', value: 'Torrance' },
  { label: 'Torrance County', value: 'Torrance County' },
  { label: 'Torrazza Coste', value: 'Torrazza Coste' },
  { label: 'Torrazza Dei Mandelli', value: 'Torrazza Dei Mandelli' },
  { label: 'Torrazza Piemonte', value: 'Torrazza Piemonte' },
  { label: 'Torrazzo', value: 'Torrazzo' },
  { label: 'Torre Alháquime', value: 'Torre Alháquime' },
  { label: 'Torre Annunziata', value: 'Torre Annunziata' },
  { label: 'Torre Beretti', value: 'Torre Beretti' },
  { label: 'Torre Boldone', value: 'Torre Boldone' },
  { label: 'Torre Bormida', value: 'Torre Bormida' },
  { label: 'Torre Caietani', value: 'Torre Caietani' },
  { label: 'Torre Canavese', value: 'Torre Canavese' },
  { label: 'Torre Caracciolo', value: 'Torre Caracciolo' },
  { label: 'Torre Colonna-Sperone', value: 'Torre Colonna-Sperone' },
  { label: 'Torre Darese', value: 'Torre Darese' },
  { label: 'Torre De Arcas', value: 'Torre De Arcas' },
  { label: 'Torre De Busi', value: 'Torre De Busi' },
  { label: 'Torre De Cabdella La', value: 'Torre De Cabdella La' },
  { label: 'Torre De Claramunt La', value: 'Torre De Claramunt La' },
  { label: 'Torre De Don Miguel', value: 'Torre De Don Miguel' },
  { label: 'Torre De Esgueva', value: 'Torre De Esgueva' },
  { label: 'Torre De Espanyol', value: 'Torre De Espanyol' },
  { label: 'Torre De Esteban Hambrán La', value: 'Torre De Esteban Hambrán La' },
  { label: 'Torre De Fontaubella', value: 'Torre De Fontaubella' },
  { label: 'Torre De Juan Abad', value: 'Torre De Juan Abad' },
  { label: 'Torre De La Horadada', value: 'Torre De La Horadada' },
  { label: 'Torre De Las Arcas', value: 'Torre De Las Arcas' },
  { label: 'Torre De Miguel Sesmero', value: 'Torre De Miguel Sesmero' },
  { label: 'Torre De Moncorvo', value: 'Torre De Moncorvo' },
  { label: 'Torre De Negri', value: 'Torre De Negri' },
  { label: 'Torre De Passeri', value: 'Torre De Passeri' },
  { label: 'Torre De Pedra', value: 'Torre De Pedra' },
  { label: 'Torre De Peñafiel', value: 'Torre De Peñafiel' },
  { label: 'Torre De Picenardi', value: 'Torre De Picenardi' },
  { label: 'Torre De Roveri', value: 'Torre De Roveri' },
  { label: 'Torre De Santa María', value: 'Torre De Santa María' },
  { label: 'Torre Del Bierzo', value: 'Torre Del Bierzo' },
  { label: 'Torre Del Burgo', value: 'Torre Del Burgo' },
  { label: 'Torre Del Campo', value: 'Torre Del Campo' },
  { label: 'Torre Del Compte', value: 'Torre Del Compte' },
  { label: 'Torre Del Greco', value: 'Torre Del Greco' },
  { label: 'Torre Del Lago Puccini', value: 'Torre Del Lago Puccini' },
  { label: 'Torre Del Mangano', value: 'Torre Del Mangano' },
  { label: 'Torre Del Valle La', value: 'Torre Del Valle La' },
  { label: 'Torre Den Besora La', value: 'Torre Den Besora La' },
  { label: 'Torre Den Doménec La', value: 'Torre Den Doménec La' },
  { label: 'Torre Di Mosto', value: 'Torre Di Mosto' },
  { label: 'Torre Di Ruggiero', value: 'Torre Di Ruggiero' },
  { label: 'Torre Di Santa Maria', value: 'Torre Di Santa Maria' },
  { label: 'Torre Disola', value: 'Torre Disola' },
  { label: 'Torre Do Terrenho', value: 'Torre Do Terrenho' },
  { label: 'Torre En Cameros', value: 'Torre En Cameros' },
  { label: 'Torre La', value: 'Torre La' },
  { label: 'Torre La Ribera', value: 'Torre La Ribera' },
  { label: 'Torre Le Nocelle', value: 'Torre Le Nocelle' },
  { label: 'Torre Los Negros', value: 'Torre Los Negros' },
  { label: 'Torre Melissa', value: 'Torre Melissa' },
  { label: 'Torre Mondovì', value: 'Torre Mondovì' },
  { label: 'Torre Orsaia', value: 'Torre Orsaia' },
  { label: 'Torre Pallavicina', value: 'Torre Pallavicina' },
  { label: 'Torre Pellice', value: 'Torre Pellice' },
  { label: 'Torre San Giorgio', value: 'Torre San Giorgio' },
  { label: 'Torre San Giovanni', value: 'Torre San Giovanni' },
  { label: 'Torre San Patrizio', value: 'Torre San Patrizio' },
  { label: 'Torre Santa Susanna', value: 'Torre Santa Susanna' },
  { label: 'Torre Val De San Pedro', value: 'Torre Val De San Pedro' },
  { label: 'Torreadrada', value: 'Torreadrada' },
  { label: 'Torreano', value: 'Torreano' },
  { label: 'Torrebaja', value: 'Torrebaja' },
  { label: 'Torrebelvicino', value: 'Torrebelvicino' },
  { label: 'Torrebesses', value: 'Torrebesses' },
  { label: 'Torreblacos', value: 'Torreblacos' },
  { label: 'Torreblanca', value: 'Torreblanca' },
  { label: 'Torreblascopedro', value: 'Torreblascopedro' },
  { label: 'Torrebruna', value: 'Torrebruna' },
  { label: 'Torrecaballeros', value: 'Torrecaballeros' },
  { label: 'Torrecampo', value: 'Torrecampo' },
  { label: 'Torre-Cardela', value: 'Torre-Cardela' },
  { label: 'Torrechiva', value: 'Torrechiva' },
  { label: 'Torrecilla De Alcañiz', value: 'Torrecilla De Alcañiz' },
  { label: 'Torrecilla De La Abadesa', value: 'Torrecilla De La Abadesa' },
  { label: 'Torrecilla De La Jara', value: 'Torrecilla De La Jara' },
  { label: 'Torrecilla De La Orden', value: 'Torrecilla De La Orden' },
  { label: 'Torrecilla De La Torre', value: 'Torrecilla De La Torre' },
  { label: 'Torrecilla De Los Ángeles', value: 'Torrecilla De Los Ángeles' },
  { label: 'Torrecilla Del Monte', value: 'Torrecilla Del Monte' },
  { label: 'Torrecilla Del Pinar', value: 'Torrecilla Del Pinar' },
  { label: 'Torrecilla Del Rebollar', value: 'Torrecilla Del Rebollar' },
  { label: 'Torrecilla En Cameros', value: 'Torrecilla En Cameros' },
  { label: 'Torrecilla Sobre Alesanco', value: 'Torrecilla Sobre Alesanco' },
  { label: 'Torrecillas De La Tiesa', value: 'Torrecillas De La Tiesa' },
  { label: 'Torrecuadrada De Molina', value: 'Torrecuadrada De Molina' },
  { label: 'Torrecuadradilla', value: 'Torrecuadradilla' },
  { label: 'Torrecuso', value: 'Torrecuso' },
  { label: 'Torredembarra', value: 'Torredembarra' },
  { label: 'Torredonjimeno', value: 'Torredonjimeno' },
  { label: 'Torrefarrera', value: 'Torrefarrera' },
  { label: 'Torrefeta I Florejacs', value: 'Torrefeta I Florejacs' },
  { label: 'Torregalindo', value: 'Torregalindo' },
  { label: 'Torregamones', value: 'Torregamones' },
  { label: 'Torreglia', value: 'Torreglia' },
  { label: 'Torregrossa', value: 'Torregrossa' },
  { label: 'Torregrotta', value: 'Torregrotta' },
  { label: 'Torrehermosa', value: 'Torrehermosa' },
  { label: 'Torreiglesias', value: 'Torreiglesias' },
  { label: 'Torreilles', value: 'Torreilles' },
  { label: 'Torrejón De Ardoz', value: 'Torrejón De Ardoz' },
  { label: 'Torrejón De La Calzada', value: 'Torrejón De La Calzada' },
  { label: 'Torrejón De Velasco', value: 'Torrejón De Velasco' },
  { label: 'Torrejón Del Rey', value: 'Torrejón Del Rey' },
  { label: 'Torrejón El Rubio', value: 'Torrejón El Rubio' },
  { label: 'Torrejoncillo', value: 'Torrejoncillo' },
  { label: 'Torrejoncillo Del Rey', value: 'Torrejoncillo Del Rey' },
  { label: 'Torrelacárcel', value: 'Torrelacárcel' },
  { label: 'Torrelaguna', value: 'Torrelaguna' },
  { label: 'Torrelameu', value: 'Torrelameu' },
  { label: 'Torrelapaja', value: 'Torrelapaja' },
  { label: 'Torrelara', value: 'Torrelara' },
  { label: 'Torrelavega', value: 'Torrelavega' },
  { label: 'Torrelavit', value: 'Torrelavit' },
  { label: 'Torrella', value: 'Torrella' },
  { label: 'Torrellas', value: 'Torrellas' },
  { label: 'Torrelles De Foix', value: 'Torrelles De Foix' },
  { label: 'Torrelles De Llobregat', value: 'Torrelles De Llobregat' },
  { label: 'Torrelobatón', value: 'Torrelobatón' },
  { label: 'Torrelodones', value: 'Torrelodones' },
  { label: 'Torremaggiore', value: 'Torremaggiore' },
  { label: 'Torremanzanas/Torre De Les Maçanes La', value: 'Torremanzanas/Torre De Les Maçanes La' },
  { label: 'Torremayor', value: 'Torremayor' },
  { label: 'Torremejía', value: 'Torremejía' },
  { label: 'Torremenga', value: 'Torremenga' },
  { label: 'Torremocha', value: 'Torremocha' },
  { label: 'Torremocha De Jadraque', value: 'Torremocha De Jadraque' },
  { label: 'Torremocha De Jarama', value: 'Torremocha De Jarama' },
  { label: 'Torremocha De Jiloca', value: 'Torremocha De Jiloca' },
  { label: 'Torremocha Del Campo', value: 'Torremocha Del Campo' },
  { label: 'Torremocha Del Pinar', value: 'Torremocha Del Pinar' },
  { label: 'Torremochuela', value: 'Torremochuela' },
  { label: 'Torremolinos', value: 'Torremolinos' },
  { label: 'Torremontalbo', value: 'Torremontalbo' },
  { label: 'Torremormojón', value: 'Torremormojón' },
  { label: 'Torrenieri', value: 'Torrenieri' },
  { label: 'Torrenova', value: 'Torrenova' },
  { label: 'Torrens', value: 'Torrens' },
  { label: 'Torrens Park', value: 'Torrens Park' },
  { label: 'Torrensville', value: 'Torrensville' },
  { label: 'Torrent', value: 'Torrent' },
  { label: 'Torrent', value: 'Torrent' },
  { label: 'Torrente De Cinca', value: 'Torrente De Cinca' },
  { label: 'Torrenueva', value: 'Torrenueva' },
  { label: 'Torreón', value: 'Torreón' },
  { label: 'Torreorgaz', value: 'Torreorgaz' },
  { label: 'Torre-Pacheco', value: 'Torre-Pacheco' },
  { label: 'Torrepadre', value: 'Torrepadre' },
  { label: 'Torreperogil', value: 'Torreperogil' },
  { label: 'Torrequemada', value: 'Torrequemada' },
  { label: 'Torres', value: 'Torres' },
  { label: 'Torres', value: 'Torres' },
  { label: 'Torres', value: 'Torres' },
  { label: 'Torres', value: 'Torres' },
  { label: 'Torres De Albánchez', value: 'Torres De Albánchez' },
  { label: 'Torres De Albarracín', value: 'Torres De Albarracín' },
  { label: 'Torres De Alcanadre', value: 'Torres De Alcanadre' },
  { label: 'Torres De Barbués', value: 'Torres De Barbués' },
  { label: 'Torres De Berrellén', value: 'Torres De Berrellén' },
  { label: 'Torres De Cotillas Las', value: 'Torres De Cotillas Las' },
  { label: 'Torres De La Alameda', value: 'Torres De La Alameda' },
  { label: 'Torres De Segre', value: 'Torres De Segre' },
  { label: 'Torres Del Carrizal', value: 'Torres Del Carrizal' },
  { label: 'Torres Del Paine', value: 'Torres Del Paine' },
  { label: 'Torres Del Río', value: 'Torres Del Río' },
  { label: 'Torres Novas', value: 'Torres Novas' },
  { label: 'Torres Strait Island Region', value: 'Torres Strait Island Region' },
  { label: 'Torres Torres', value: 'Torres Torres' },
  { label: 'Torres Vedras', value: 'Torres Vedras' },
  { label: 'Torres Vedras', value: 'Torres Vedras' },
  { label: 'Torresandino', value: 'Torresandino' },
  { label: 'Torrescárcela', value: 'Torrescárcela' },
  { label: 'Torreselle', value: 'Torreselle' },
  { label: 'Torre-Serona', value: 'Torre-Serona' },
  { label: 'Torresina', value: 'Torresina' },
  { label: 'Torresmenudas', value: 'Torresmenudas' },
  { label: 'Torretta', value: 'Torretta' },
  { label: 'Torretta', value: 'Torretta' },
  { label: 'Torretta-Scalzapecora', value: 'Torretta-Scalzapecora' },
  { label: 'Torrevecchia', value: 'Torrevecchia' },
  { label: 'Torrevecchia Pia', value: 'Torrevecchia Pia' },
  { label: 'Torrevelilla', value: 'Torrevelilla' },
  { label: 'Torrevieja', value: 'Torrevieja' },
  { label: 'Torri Del Benaco', value: 'Torri Del Benaco' },
  { label: 'Torri Di Quartesolo', value: 'Torri Di Quartesolo' },
  { label: 'Torri In Sabina', value: 'Torri In Sabina' },
  { label: 'Torriana', value: 'Torriana' },
  { label: 'Torrice', value: 'Torrice' },
  { label: 'Torricella', value: 'Torricella' },
  { label: 'Torricella', value: 'Torricella' },
  { label: 'Torricella Del Pizzo', value: 'Torricella Del Pizzo' },
  { label: 'Torricella In Sabina', value: 'Torricella In Sabina' },
  { label: 'Torricella Peligna', value: 'Torricella Peligna' },
  { label: 'Torricella Verzate', value: 'Torricella Verzate' },
  { label: 'Torrico', value: 'Torrico' },
  { label: 'Torriglia', value: 'Torriglia' },
  { label: 'Torrijas', value: 'Torrijas' },
  { label: 'Torrijo De La Cañada', value: 'Torrijo De La Cañada' },
  { label: 'Torrijo Del Campo', value: 'Torrijo Del Campo' },
  { label: 'Torrijos', value: 'Torrijos' },
  { label: 'Torrijos', value: 'Torrijos' },
  { label: 'Torrile', value: 'Torrile' },
  { label: 'Tørring', value: 'Tørring' },
  { label: 'Torrington', value: 'Torrington' },
  { label: 'Torrington', value: 'Torrington' },
  { label: 'Torrinha', value: 'Torrinha' },
  { label: 'Torrion Quartara', value: 'Torrion Quartara' },
  { label: 'Torrioni', value: 'Torrioni' },
  { label: 'Torrita Di Siena', value: 'Torrita Di Siena' },
  { label: 'Torrita Tiberina', value: 'Torrita Tiberina' },
  { label: 'Torroella De Fluvià', value: 'Torroella De Fluvià' },
  { label: 'Torroella De Montgrí', value: 'Torroella De Montgrí' },
  { label: 'Torroja Del Priorat', value: 'Torroja Del Priorat' },
  { label: 'Torrox', value: 'Torrox' },
  { label: 'Torrozelo', value: 'Torrozelo' },
  { label: 'Torrubia', value: 'Torrubia' },
  { label: 'Torrubia De Soria', value: 'Torrubia De Soria' },
  { label: 'Torrubia Del Campo', value: 'Torrubia Del Campo' },
  { label: 'Torrubia Del Castillo', value: 'Torrubia Del Castillo' },
  { label: 'Torsås', value: 'Torsås' },
  { label: 'Torsby', value: 'Torsby' },
  { label: 'Torshälla', value: 'Torshälla' },
  { label: 'Tórshavn', value: 'Tórshavn' },
  { label: 'Torsken', value: 'Torsken' },
  { label: 'Torslanda', value: 'Torslanda' },
  { label: 'Tortel', value: 'Tortel' },
  { label: 'Törtel', value: 'Törtel' },
  { label: 'Tortellà', value: 'Tortellà' },
  { label: 'Tortí', value: 'Tortí' },
  { label: 'Tórtola De Henares', value: 'Tórtola De Henares' },
  { label: 'Tórtoles', value: 'Tórtoles' },
  { label: 'Tórtoles De Esgueva', value: 'Tórtoles De Esgueva' },
  { label: 'Tortolì', value: 'Tortolì' },
  { label: 'Tortolita', value: 'Tortolita' },
  { label: 'Tortoman', value: 'Tortoman' },
  { label: 'Tortona', value: 'Tortona' },
  { label: 'Tortora', value: 'Tortora' },
  { label: 'Tortora Marina', value: 'Tortora Marina' },
  { label: 'Tortorella', value: 'Tortorella' },
  { label: 'Tortoreto', value: 'Tortoreto' },
  { label: 'Tortoreto Lido', value: 'Tortoreto Lido' },
  { label: 'Tortorici', value: 'Tortorici' },
  { label: 'Tortosa', value: 'Tortosa' },
  { label: 'Tortosa', value: 'Tortosa' },
  { label: 'Tortuera', value: 'Tortuera' },
  { label: 'Tortuero', value: 'Tortuero' },
  { label: 'Tortum', value: 'Tortum' },
  { label: 'Torul', value: 'Torul' },
  { label: 'Toruń', value: 'Toruń' },
  { label: 'Torup', value: 'Torup' },
  { label: 'Tõrva', value: 'Tõrva' },
  { label: 'Torvaianica', value: 'Torvaianica' },
  { label: 'Torvaianica Alta', value: 'Torvaianica Alta' },
  { label: 'Torviscosa', value: 'Torviscosa' },
  { label: 'Torvizcón', value: 'Torvizcón' },
  { label: 'Torzhok', value: 'Torzhok' },
  { label: 'Torzhokskiy Rayon', value: 'Torzhokskiy Rayon' },
  { label: 'Torzym', value: 'Torzym' },
  { label: 'Tosa', value: 'Tosa' },
  { label: 'Tosagua', value: 'Tosagua' },
  { label: 'Tosantos', value: 'Tosantos' },
  { label: 'Tosashimizu', value: 'Tosashimizu' },
  { label: 'Toscanella', value: 'Toscanella' },
  { label: 'Toscolano Maderno', value: 'Toscolano Maderno' },
  { label: 'Toses', value: 'Toses' },
  { label: 'Tosham', value: 'Tosham' },
  { label: 'Toshbuloq', value: 'Toshbuloq' },
  { label: 'Toshima-Ku', value: 'Toshima-Ku' },
  { label: 'Toshkivka', value: 'Toshkivka' },
  { label: 'Toshloq', value: 'Toshloq' },
  { label: 'Tosnenskiy Rayon', value: 'Tosnenskiy Rayon' },
  { label: 'Tosno', value: 'Tosno' },
  { label: 'Tosos', value: 'Tosos' },
  { label: 'Tossa De Mar', value: 'Tossa De Mar' },
  { label: 'Tosse', value: 'Tosse' },
  { label: 'Tossiat', value: 'Tossiat' },
  { label: 'Tossicia', value: 'Tossicia' },
  { label: 'Tostado', value: 'Tostado' },
  { label: 'Tostedt', value: 'Tostedt' },
  { label: 'Tosu Shi', value: 'Tosu Shi' },
  { label: 'Tosya', value: 'Tosya' },
  { label: 'Tószeg', value: 'Tószeg' },
  { label: 'Toszek', value: 'Toszek' },
  { label: 'Tot’Ma', value: 'Tot’Ma' },
  { label: 'Tota', value: 'Tota' },
  { label: 'Totalán', value: 'Totalán' },
  { label: 'Totana', value: 'Totana' },
  { label: 'Totanés', value: 'Totanés' },
  { label: 'Totatiche Municipality', value: 'Totatiche Municipality' },
  { label: 'Totemskiy Rayon', value: 'Totemskiy Rayon' },
  { label: 'Tôtes', value: 'Tôtes' },
  { label: 'Toteşti', value: 'Toteşti' },
  { label: 'Tótkomlós', value: 'Tótkomlós' },
  { label: 'Totnes', value: 'Totnes' },
  { label: 'Totness', value: 'Totness' },
  { label: 'Totoești', value: 'Totoești' },
  { label: 'Totogalpa', value: 'Totogalpa' },
  { label: 'Totolac', value: 'Totolac' },
  { label: 'Totolan', value: 'Totolan' },
  { label: 'Totolapa', value: 'Totolapa' },
  { label: 'Totolapa', value: 'Totolapa' },
  { label: 'Totolapan', value: 'Totolapan' },
  { label: 'Totolmajac', value: 'Totolmajac' },
  { label: 'Totoltepec', value: 'Totoltepec' },
  { label: 'Totoltepec De Guerrero', value: 'Totoltepec De Guerrero' },
  { label: 'Totomixtlahuaca', value: 'Totomixtlahuaca' },
  { label: 'Totonicapán', value: 'Totonicapán' },
  { label: 'Totontepec Villa De Morelos', value: 'Totontepec Villa De Morelos' },
  { label: 'Totora', value: 'Totora' },
  { label: 'Totoral', value: 'Totoral' },
  { label: 'Totoral', value: 'Totoral' },
  { label: 'Totoras', value: 'Totoras' },
  { label: 'Totoró', value: 'Totoró' },
  { label: 'Tototepec', value: 'Tototepec' },
  { label: 'Tototlán', value: 'Tototlán' },
  { label: 'Totowa', value: 'Totowa' },
  { label: 'Totskoye', value: 'Totskoye' },
  { label: 'Tottenham', value: 'Tottenham' },
  { label: 'Totternhoe', value: 'Totternhoe' },
  { label: 'Tottijärvi', value: 'Tottijärvi' },
  { label: 'Tottington', value: 'Tottington' },
  { label: 'Totton', value: 'Totton' },
  { label: 'Tottori-Shi', value: 'Tottori-Shi' },
  { label: 'Totutla', value: 'Totutla' },
  { label: 'Touba', value: 'Touba' },
  { label: 'Touba', value: 'Touba' },
  { label: 'Touça', value: 'Touça' },
  { label: 'Toucy', value: 'Toucy' },
  { label: 'Toufflers', value: 'Toufflers' },
  { label: 'Tougan', value: 'Tougan' },
  { label: 'Touggourt', value: 'Touggourt' },
  { label: 'Touggourt', value: 'Touggourt' },
  { label: 'Toughkenamon', value: 'Toughkenamon' },
  { label: 'Tougué', value: 'Tougué' },
  { label: 'Tougue Prefecture', value: 'Tougue Prefecture' },
  { label: 'Toujouonine', value: 'Toujouonine' },
  { label: 'Toukh', value: 'Toukh' },
  { label: 'Toukley', value: 'Toukley' },
  { label: 'Toul', value: 'Toul' },
  { label: 'Toulaud', value: 'Toulaud' },
  { label: 'Toulenne', value: 'Toulenne' },
  { label: 'Toulépleu Gueré', value: 'Toulépleu Gueré' },
  { label: 'Toulon', value: 'Toulon' },
  { label: 'Toulon', value: 'Toulon' },
  { label: 'Toulon-Sur-Allier', value: 'Toulon-Sur-Allier' },
  { label: 'Toulon-Sur-Arroux', value: 'Toulon-Sur-Arroux' },
  { label: 'Toulouges', value: 'Toulouges' },
  { label: 'Toulouse', value: 'Toulouse' },
  { label: 'Toumodi', value: 'Toumodi' },
  { label: 'Toungo', value: 'Toungo' },
  { label: 'Touques', value: 'Touques' },
  { label: 'Touquin', value: 'Touquin' },
  { label: 'Tour Koenig', value: 'Tour Koenig' },
  { label: 'Tourais', value: 'Tourais' },
  { label: 'Tourbes', value: 'Tourbes' },
  { label: 'Tourcoing', value: 'Tourcoing' },
  { label: 'Tourlaville', value: 'Tourlaville' },
  { label: 'Tournai', value: 'Tournai' },
  { label: 'Tournan-En-Brie', value: 'Tournan-En-Brie' },
  { label: 'Tournay', value: 'Tournay' },
  { label: 'Tournefeuille', value: 'Tournefeuille' },
  { label: 'Tournehem-Sur-La-Hem', value: 'Tournehem-Sur-La-Hem' },
  { label: 'Tournes', value: 'Tournes' },
  { label: 'Tournon-Saint-Martin', value: 'Tournon-Saint-Martin' },
  { label: 'Tournon-Sur-Rhône', value: 'Tournon-Sur-Rhône' },
  { label: 'Tournus', value: 'Tournus' },
  { label: 'Touro', value: 'Touro' },
  { label: 'Touros', value: 'Touros' },
  { label: 'Tourouvre', value: 'Tourouvre' },
  { label: 'Tourrette-Levens', value: 'Tourrette-Levens' },
  { label: 'Tourrettes-Sur-Loup', value: 'Tourrettes-Sur-Loup' },
  { label: 'Tours', value: 'Tours' },
  { label: 'Tours-Sur-Marne', value: 'Tours-Sur-Marne' },
  { label: 'Tourves', value: 'Tourves' },
  { label: 'Tourville-La-Rivière', value: 'Tourville-La-Rivière' },
  { label: 'Tourville-Sur-Arques', value: 'Tourville-Sur-Arques' },
  { label: 'Tourville-Sur-Odon', value: 'Tourville-Sur-Odon' },
  { label: 'Toury', value: 'Toury' },
  { label: 'Tous', value: 'Tous' },
  { label: 'Toussieu', value: 'Toussieu' },
  { label: 'Toutainville', value: 'Toutainville' },
  { label: 'Toutlemonde', value: 'Toutlemonde' },
  { label: 'Touvois', value: 'Touvois' },
  { label: 'Touvre', value: 'Touvre' },
  { label: 'Touying', value: 'Touying' },
  { label: 'Touza', value: 'Touza' },
  { label: 'Toužim', value: 'Toužim' },
  { label: 'Tovačov', value: 'Tovačov' },
  { label: 'Tovarkovo', value: 'Tovarkovo' },
  { label: 'Tovarkovskiy', value: 'Tovarkovskiy' },
  { label: 'Tovarnik', value: 'Tovarnik' },
  { label: 'Tovo Di Santagata', value: 'Tovo Di Santagata' },
  { label: 'Tovo San Giacomo', value: 'Tovo San Giacomo' },
  { label: 'Tovste', value: 'Tovste' },
  { label: 'Tovuz', value: 'Tovuz' },
  { label: 'Tow Law', value: 'Tow Law' },
  { label: 'Towada Shi', value: 'Towada Shi' },
  { label: 'Towamensing Trails', value: 'Towamensing Trails' },
  { label: 'Towanda', value: 'Towanda' },
  { label: 'Towanda', value: 'Towanda' },
  { label: 'Towaoc', value: 'Towaoc' },
  { label: 'Towcester', value: 'Towcester' },
  { label: 'Tower', value: 'Tower' },
  { label: 'Tower City', value: 'Tower City' },
  { label: 'Tower Hill', value: 'Tower Hill' },
  { label: 'Tower Hill/Moy Hall', value: 'Tower Hill/Moy Hall' },
  { label: 'Tower Lake', value: 'Tower Lake' },
  { label: 'Town', value: 'Town' },
  { label: 'Town', value: 'Town' },
  { label: 'Town And Country', value: 'Town And Country' },
  { label: 'Town And Country', value: 'Town And Country' },
  { label: 'Town Creek', value: 'Town Creek' },
  { label: 'Town Head', value: 'Town Head' },
  { label: 'Town Line', value: 'Town Line' },
  { label: 'Town N Country', value: 'Town N Country' },
  { label: 'Town Row', value: 'Town Row' },
  { label: 'Towner', value: 'Towner' },
  { label: 'Towner County', value: 'Towner County' },
  { label: 'Townhill', value: 'Townhill' },
  { label: 'Towns County', value: 'Towns County' },
  { label: 'Townsend', value: 'Townsend' },
  { label: 'Townsend', value: 'Townsend' },
  { label: 'Townsend', value: 'Townsend' },
  { label: 'Townshend', value: 'Townshend' },
  { label: 'Townsville', value: 'Townsville' },
  { label: 'Townview', value: 'Townview' },
  { label: 'Towong', value: 'Towong' },
  { label: 'Towradgi', value: 'Towradgi' },
  { label: 'Towson', value: 'Towson' },
  { label: 'Toxpalan', value: 'Toxpalan' },
  { label: 'Toxtla', value: 'Toxtla' },
  { label: 'Toyama Shi', value: 'Toyama Shi' },
  { label: 'Tōyō', value: 'Tōyō' },
  { label: 'Toyoake', value: 'Toyoake' },
  { label: 'Toyoake-Shi', value: 'Toyoake-Shi' },
  { label: 'Toyohama', value: 'Toyohama' },
  { label: 'Toyohashi', value: 'Toyohashi' },
  { label: 'Toyohashi-Shi', value: 'Toyohashi-Shi' },
  { label: 'Toyokawa', value: 'Toyokawa' },
  { label: 'Toyokawa-Shi', value: 'Toyokawa-Shi' },
  { label: 'Toyonaka', value: 'Toyonaka' },
  { label: 'Toyonaka Shi', value: 'Toyonaka Shi' },
  { label: 'Toyooka', value: 'Toyooka' },
  { label: 'Toyooka', value: 'Toyooka' },
  { label: 'Toyooka-Shi', value: 'Toyooka-Shi' },
  { label: 'Toyós', value: 'Toyós' },
  { label: 'Toyoshina', value: 'Toyoshina' },
  { label: 'Toyota-Shi', value: 'Toyota-Shi' },
  { label: 'Töysä', value: 'Töysä' },
  { label: 'Toza', value: 'Toza' },
  { label: 'Tozeur', value: 'Tozeur' },
  { label: 'Tozihuic', value: 'Tozihuic' },
  { label: 'Tozkhurmato', value: 'Tozkhurmato' },
  { label: 'Tozongo', value: 'Tozongo' },
  { label: 'Tpig', value: 'Tpig' },
  { label: 'Tqibuli', value: 'Tqibuli' },
  { label: 'Tqvarcheli', value: 'Tqvarcheli' },
  { label: 'Tr’Okhizbenka', value: 'Tr’Okhizbenka' },
  { label: 'Trá Mhór', value: 'Trá Mhór' },
  { label: 'Trà Vinh', value: 'Trà Vinh' },
  { label: 'Trabada', value: 'Trabada' },
  { label: 'Trabadelo', value: 'Trabadelo' },
  { label: 'Trabanca', value: 'Trabanca' },
  { label: 'Trabazos', value: 'Trabazos' },
  { label: 'Traben-Trarbach', value: 'Traben-Trarbach' },
  { label: 'Trabia', value: 'Trabia' },
  { label: 'Trabiju', value: 'Trabiju' },
  { label: 'Trabitz', value: 'Trabitz' },
  { label: 'Trąbki', value: 'Trąbki' },
  { label: 'Trąbki', value: 'Trąbki' },
  { label: 'Trąbki Wielkie', value: 'Trąbki Wielkie' },
  { label: 'Trablice', value: 'Trablice' },
  { label: 'Traboch', value: 'Traboch' },
  { label: 'Trabuco Canyon', value: 'Trabuco Canyon' },
  { label: 'Tracadie-Sheila', value: 'Tracadie-Sheila' },
  { label: 'Trachselwald', value: 'Trachselwald' },
  { label: 'Tracuateua', value: 'Tracuateua' },
  { label: 'Tracunhaém', value: 'Tracunhaém' },
  { label: 'Tracy', value: 'Tracy' },
  { label: 'Tracy', value: 'Tracy' },
  { label: 'Tracy City', value: 'Tracy City' },
  { label: 'Tracy-Le-Mont', value: 'Tracy-Le-Mont' },
  { label: 'Tracyton', value: 'Tracyton' },
  { label: 'Tradate', value: 'Tradate' },
  { label: 'Træna', value: 'Træna' },
  { label: 'Traer', value: 'Traer' },
  { label: 'Trafalgar', value: 'Trafalgar' },
  { label: 'Trafalgar', value: 'Trafalgar' },
  { label: 'Trafalgar Park', value: 'Trafalgar Park' },
  { label: 'Trafaria', value: 'Trafaria' },
  { label: 'Trafford', value: 'Trafford' },
  { label: 'Trafford', value: 'Trafford' },
  { label: 'Trafford Park', value: 'Trafford Park' },
  { label: 'Tragacete', value: 'Tragacete' },
  { label: 'Traganón', value: 'Traganón' },
  { label: 'Tragliatella Campitello', value: 'Tragliatella Campitello' },
  { label: 'Tragwein', value: 'Tragwein' },
  { label: 'Trahütten', value: 'Trahütten' },
  { label: 'Traian', value: 'Traian' },
  { label: 'Traian', value: 'Traian' },
  { label: 'Traian', value: 'Traian' },
  { label: 'Traian', value: 'Traian' },
  { label: 'Traian', value: 'Traian' },
  { label: 'Traian', value: 'Traian' },
  { label: 'Traian', value: 'Traian' },
  { label: 'Traian Vuia', value: 'Traian Vuia' },
  { label: 'Traíd', value: 'Traíd' },
  { label: 'Traiguén', value: 'Traiguén' },
  { label: 'Traiguera', value: 'Traiguera' },
  { label: 'Trail', value: 'Trail' },
  { label: 'Trail Creek', value: 'Trail Creek' },
  { label: 'Traill County', value: 'Traill County' },
  { label: 'Train', value: 'Train' },
  { label: 'Trainer', value: 'Trainer' },
  { label: 'Traînou', value: 'Traînou' },
  { label: 'Traipu', value: 'Traipu' },
  { label: 'Trairão', value: 'Trairão' },
  { label: 'Trairi', value: 'Trairi' },
  { label: 'Traisen', value: 'Traisen' },
  { label: 'Traiskirchen', value: 'Traiskirchen' },
  { label: 'Traismauer', value: 'Traismauer' },
  { label: 'Trăisteni', value: 'Trăisteni' },
  { label: 'Traitsching', value: 'Traitsching' },
  { label: 'Trajano De Moraes', value: 'Trajano De Moraes' },
  { label: 'Trakai', value: 'Trakai' },
  { label: 'Tral', value: 'Tral' },
  { label: 'Tralee', value: 'Tralee' },
  { label: 'Tramacastiel', value: 'Tramacastiel' },
  { label: 'Tramacastilla', value: 'Tramacastilla' },
  { label: 'Tramaced', value: 'Tramaced' },
  { label: 'Tramagal', value: 'Tramagal' },
  { label: 'Tramandaí', value: 'Tramandaí' },
  { label: 'Tramatza', value: 'Tramatza' },
  { label: 'Trambileno', value: 'Trambileno' },
  { label: 'Tramelan', value: 'Tramelan' },
  { label: 'Tramonti', value: 'Tramonti' },
  { label: 'Tramonti Di Sopra', value: 'Tramonti Di Sopra' },
  { label: 'Tramonti Di Sotto', value: 'Tramonti Di Sotto' },
  { label: 'Tramoyes', value: 'Tramoyes' },
  { label: 'Tramutola', value: 'Tramutola' },
  { label: 'Tran', value: 'Tran' },
  { label: 'Tran', value: 'Tran' },
  { label: 'Tran', value: 'Tran' },
  { label: 'Trana', value: 'Trana' },
  { label: 'Tranås', value: 'Tranås' },
  { label: 'Tranbjerg', value: 'Tranbjerg' },
  { label: 'Tranby', value: 'Tranby' },
  { label: 'Tranca', value: 'Tranca' },
  { label: 'Trancas', value: 'Trancas' },
  { label: 'Trancoso', value: 'Trancoso' },
  { label: 'Trancoso', value: 'Trancoso' },
  { label: 'Tranemo', value: 'Tranemo' },
  { label: 'Tranent', value: 'Tranent' },
  { label: 'Trang', value: 'Trang' },
  { label: 'Trangé', value: 'Trangé' },
  { label: 'Trangie', value: 'Trangie' },
  { label: 'Trani', value: 'Trani' },
  { label: 'Tranmere', value: 'Tranmere' },
  { label: 'Tranmere', value: 'Tranmere' },
  { label: 'Tranøy', value: 'Tranøy' },
  { label: 'Tranquebar', value: 'Tranquebar' },
  { label: 'Tranqueras', value: 'Tranqueras' },
  { label: 'Tranquility', value: 'Tranquility' },
  { label: 'Transacqua', value: 'Transacqua' },
  { label: 'Trans-En-Provence', value: 'Trans-En-Provence' },
  { label: 'Transfiguración', value: 'Transfiguración' },
  { label: 'Transito Tular', value: 'Transito Tular' },
  { label: 'Transporte', value: 'Transporte' },
  { label: 'Transylvania County', value: 'Transylvania County' },
  { label: 'Traona', value: 'Traona' },
  { label: 'Trapani', value: 'Trapani' },
  { label: 'Trapiche', value: 'Trapiche' },
  { label: 'Trapiche De Abra', value: 'Trapiche De Abra' },
  { label: 'Trapiche Del Rosario', value: 'Trapiche Del Rosario' },
  { label: 'Trapiche Viejo', value: 'Trapiche Viejo' },
  { label: 'Traplice', value: 'Traplice' },
  { label: 'Trappe', value: 'Trappe' },
  { label: 'Trappe', value: 'Trappe' },
  { label: 'Trappenkamp', value: 'Trappenkamp' },
  { label: 'Trappes', value: 'Trappes' },
  { label: 'Trappeto', value: 'Trappeto' },
  { label: 'Trappstadt', value: 'Trappstadt' },
  { label: 'Traralgon', value: 'Traralgon' },
  { label: 'Trarego', value: 'Trarego' },
  { label: 'Trarivi', value: 'Trarivi' },
  { label: 'Tras Cerros', value: 'Tras Cerros' },
  { label: 'Trasacco', value: 'Trasacco' },
  { label: 'Trasaghis', value: 'Trasaghis' },
  { label: 'Trashi Yangtse', value: 'Trashi Yangtse' },
  { label: 'Trashigang', value: 'Trashigang' },
  { label: 'Trasierra', value: 'Trasierra' },
  { label: 'Träslövsläge', value: 'Träslövsläge' },
  { label: 'Trasmiras', value: 'Trasmiras' },
  { label: 'Trasmoz', value: 'Trasmoz' },
  { label: 'Trasobares', value: 'Trasobares' },
  { label: 'Traspinedo', value: 'Traspinedo' },
  { label: 'Trasquera', value: 'Trasquera' },
  { label: 'Trassem', value: 'Trassem' },
  { label: 'Trat', value: 'Trat' },
  { label: 'Tratalias', value: 'Tratalias' },
  { label: 'Trattenbach', value: 'Trattenbach' },
  { label: 'Traun', value: 'Traun' },
  { label: 'Traunkirchen', value: 'Traunkirchen' },
  { label: 'Traunreut', value: 'Traunreut' },
  { label: 'Traunstein', value: 'Traunstein' },
  { label: 'Trausdorf An Der Wulka', value: 'Trausdorf An Der Wulka' },
  { label: 'Trausella', value: 'Trausella' },
  { label: 'Trausnitz', value: 'Trausnitz' },
  { label: 'Trautmannsdorf An Der Leitha', value: 'Trautmannsdorf An Der Leitha' },
  { label: 'Trautmannsdorf In Oststeiermark', value: 'Trautmannsdorf In Oststeiermark' },
  { label: 'Trautskirchen', value: 'Trautskirchen' },
  { label: 'Travacò Siccomario', value: 'Travacò Siccomario' },
  { label: 'Travagliato', value: 'Travagliato' },
  { label: 'Travanca', value: 'Travanca' },
  { label: 'Travancinha', value: 'Travancinha' },
  { label: 'Travancore', value: 'Travancore' },
  { label: 'Travedona Monate', value: 'Travedona Monate' },
  { label: 'Travelers Rest', value: 'Travelers Rest' },
  { label: 'Travemünde', value: 'Travemünde' },
  { label: 'Travers', value: 'Travers' },
  { label: 'Traverse City', value: 'Traverse City' },
  { label: 'Traverse County', value: 'Traverse County' },
  { label: 'Traversella', value: 'Traversella' },
  { label: 'Traversetolo', value: 'Traversetolo' },
  { label: 'Traves', value: 'Traves' },
  { label: 'Travesía', value: 'Travesía' },
  { label: 'Travesio', value: 'Travesio' },
  { label: 'Travesseiro', value: 'Travesseiro' },
  { label: 'Travilah', value: 'Travilah' },
  { label: 'Travis County', value: 'Travis County' },
  { label: 'Travis Ranch', value: 'Travis Ranch' },
  { label: 'Travneve', value: 'Travneve' },
  { label: 'Travnik', value: 'Travnik' },
  { label: 'Travo', value: 'Travo' },
  { label: 'Trawniki', value: 'Trawniki' },
  { label: 'Trawsfynydd', value: 'Trawsfynydd' },
  { label: 'Trayning', value: 'Trayning' },
  { label: 'Trazo', value: 'Trazo' },
  { label: 'Trbovlje', value: 'Trbovlje' },
  { label: 'Tre Fontane', value: 'Tre Fontane' },
  { label: 'Treadlight', value: 'Treadlight' },
  { label: 'Treasure Beach', value: 'Treasure Beach' },
  { label: 'Treasure County', value: 'Treasure County' },
  { label: 'Treasure Island', value: 'Treasure Island' },
  { label: 'Treasure Lake', value: 'Treasure Lake' },
  { label: 'Trebaseleghe', value: 'Trebaseleghe' },
  { label: 'Trebbin', value: 'Trebbin' },
  { label: 'Trebbio', value: 'Trebbio' },
  { label: 'Trebbio', value: 'Trebbio' },
  { label: 'Trebbo', value: 'Trebbo' },
  { label: 'Třebechovice Pod Orebem', value: 'Třebechovice Pod Orebem' },
  { label: 'Trebendorf', value: 'Trebendorf' },
  { label: 'Třebenice', value: 'Třebenice' },
  { label: 'Trèbes', value: 'Trèbes' },
  { label: 'Trebesing', value: 'Trebesing' },
  { label: 'Trébeurden', value: 'Trébeurden' },
  { label: 'Trebgast', value: 'Trebgast' },
  { label: 'Třebíč', value: 'Třebíč' },
  { label: 'Trebinje', value: 'Trebinje' },
  { label: 'Trebisacce', value: 'Trebisacce' },
  { label: 'Trebišov', value: 'Trebišov' },
  { label: 'Trebitz', value: 'Trebitz' },
  { label: 'Trebnje', value: 'Trebnje' },
  { label: 'Třeboň', value: 'Třeboň' },
  { label: 'Třebotov', value: 'Třebotov' },
  { label: 'Trebsen', value: 'Trebsen' },
  { label: 'Trebujena', value: 'Trebujena' },
  { label: 'Trebukhiv', value: 'Trebukhiv' },
  { label: 'Trebur', value: 'Trebur' },
  { label: 'Trecasali', value: 'Trecasali' },
  { label: 'Trecase', value: 'Trecase' },
  { label: 'Trecastagni', value: 'Trecastagni' },
  { label: 'Trecate', value: 'Trecate' },
  { label: 'Trecchina', value: 'Trecchina' },
  { label: 'Trecella', value: 'Trecella' },
  { label: 'Trecenta', value: 'Trecenta' },
  { label: 'Trechtingshausen', value: 'Trechtingshausen' },
  { label: 'Trédarzec', value: 'Trédarzec' },
  { label: 'Tredegar', value: 'Tredegar' },
  { label: 'Tredozio', value: 'Tredozio' },
  { label: 'Trédrez-Locquémeau', value: 'Trédrez-Locquémeau' },
  { label: 'Treeton', value: 'Treeton' },
  { label: 'Trefacio', value: 'Trefacio' },
  { label: 'Treffelstein', value: 'Treffelstein' },
  { label: 'Treffen', value: 'Treffen' },
  { label: 'Treffiagat', value: 'Treffiagat' },
  { label: 'Treffléan', value: 'Treffléan' },
  { label: 'Treffort-Cuisiat', value: 'Treffort-Cuisiat' },
  { label: 'Treffurt', value: 'Treffurt' },
  { label: 'Trefnant', value: 'Trefnant' },
  { label: 'Tregaron', value: 'Tregaron' },
  { label: 'Tregarth', value: 'Tregarth' },
  { label: 'Tregasio', value: 'Tregasio' },
  { label: 'Trégastel', value: 'Trégastel' },
  { label: 'Tregear', value: 'Tregear' },
  { label: 'Treggiaia', value: 'Treggiaia' },
  { label: 'Treglio', value: 'Treglio' },
  { label: 'Treglwang', value: 'Treglwang' },
  { label: 'Tregnago', value: 'Tregnago' },
  { label: 'Trego County', value: 'Trego County' },
  { label: 'Treguaco', value: 'Treguaco' },
  { label: 'Tréguier', value: 'Tréguier' },
  { label: 'Trégunc', value: 'Trégunc' },
  { label: 'Treharris', value: 'Treharris' },
  { label: 'Trei Sate', value: 'Trei Sate' },
  { label: 'Treia', value: 'Treia' },
  { label: 'Treia', value: 'Treia' },
  { label: 'Treibach', value: 'Treibach' },
  { label: 'Treignac', value: 'Treignac' },
  { label: 'Treillières', value: 'Treillières' },
  { label: 'Treinta Y Tres', value: 'Treinta Y Tres' },
  { label: 'Treis-Karden', value: 'Treis-Karden' },
  { label: 'Treiso', value: 'Treiso' },
  { label: 'Treixedo', value: 'Treixedo' },
  { label: 'Treize-Septiers', value: 'Treize-Septiers' },
  { label: 'Trejos', value: 'Trejos' },
  { label: 'Trekanten', value: 'Trekanten' },
  { label: 'Trëkhgornyy', value: 'Trëkhgornyy' },
  { label: 'Trélazé', value: 'Trélazé' },
  { label: 'Trélévern', value: 'Trélévern' },
  { label: 'Trelew', value: 'Trelew' },
  { label: 'Trélex', value: 'Trélex' },
  { label: 'Trélissac', value: 'Trélissac' },
  { label: 'Trélivan', value: 'Trélivan' },
  { label: 'Trelleborg', value: 'Trelleborg' },
  { label: 'Trélon', value: 'Trélon' },
  { label: 'Tremblay', value: 'Tremblay' },
  { label: 'Tremblay-En-France', value: 'Tremblay-En-France' },
  { label: 'Tremedal', value: 'Tremedal' },
  { label: 'Tremedal De Tormes', value: 'Tremedal De Tormes' },
  { label: 'Tremelo', value: 'Tremelo' },
  { label: 'Tremembé', value: 'Tremembé' },
  { label: 'Tremenico', value: 'Tremenico' },
  { label: 'Trémentines', value: 'Trémentines' },
  { label: 'Trémery', value: 'Trémery' },
  { label: 'Tremestieri Etneo', value: 'Tremestieri Etneo' },
  { label: 'Tréméven', value: 'Tréméven' },
  { label: 'Tremezzo', value: 'Tremezzo' },
  { label: 'Tremignon', value: 'Tremignon' },
  { label: 'Tremont', value: 'Tremont' },
  { label: 'Tremont', value: 'Tremont' },
  { label: 'Tremont', value: 'Tremont' },
  { label: 'Tremont', value: 'Tremont' },
  { label: 'Tremonton', value: 'Tremonton' },
  { label: 'Třemošná', value: 'Třemošná' },
  { label: 'Třemošnice', value: 'Třemošnice' },
  { label: 'Tremp', value: 'Tremp' },
  { label: 'Trempealeau', value: 'Trempealeau' },
  { label: 'Trempealeau County', value: 'Trempealeau County' },
  { label: 'Tremsbüttel', value: 'Tremsbüttel' },
  { label: 'Tremseh', value: 'Tremseh' },
  { label: 'Trémuson', value: 'Trémuson' },
  { label: 'Trench Town', value: 'Trench Town' },
  { label: 'Trenčianske Teplice', value: 'Trenčianske Teplice' },
  { label: 'Trenčín', value: 'Trenčín' },
  { label: 'Trendelburg', value: 'Trendelburg' },
  { label: 'Trenel', value: 'Trenel' },
  { label: 'Trenggalek', value: 'Trenggalek' },
  { label: 'Trent Woods', value: 'Trent Woods' },
  { label: 'Trenta', value: 'Trenta' },
  { label: 'Trentham', value: 'Trentham' },
  { label: 'Trentinara', value: 'Trentinara' },
  { label: 'Trento', value: 'Trento' },
  { label: 'Trento', value: 'Trento' },
  { label: 'Trento', value: 'Trento' },
  { label: 'Trentola-Ducenta', value: 'Trentola-Ducenta' },
  { label: 'Trenton', value: 'Trenton' },
  { label: 'Trenton', value: 'Trenton' },
  { label: 'Trenton', value: 'Trenton' },
  { label: 'Trenton', value: 'Trenton' },
  { label: 'Trenton', value: 'Trenton' },
  { label: 'Trenton', value: 'Trenton' },
  { label: 'Trenton', value: 'Trenton' },
  { label: 'Trenton', value: 'Trenton' },
  { label: 'Trenton', value: 'Trenton' },
  { label: 'Trenton', value: 'Trenton' },
  { label: 'Trenton', value: 'Trenton' },
  { label: 'Trentwood', value: 'Trentwood' },
  { label: 'Trenzano', value: 'Trenzano' },
  { label: 'Tréon', value: 'Tréon' },
  { label: 'Treorchy', value: 'Treorchy' },
  { label: 'Treponti', value: 'Treponti' },
  { label: 'Treppo Carnico', value: 'Treppo Carnico' },
  { label: 'Treppo Grande', value: 'Treppo Grande' },
  { label: 'Trept', value: 'Trept' },
  { label: 'Trepuzzi', value: 'Trepuzzi' },
  { label: 'Trequanda', value: 'Trequanda' },
  { label: 'Tres', value: 'Tres' },
  { label: 'Três Arroios', value: 'Três Arroios' },
  { label: 'Três Barras', value: 'Três Barras' },
  { label: 'Três Barras Do Paraná', value: 'Três Barras Do Paraná' },
  { label: 'Três Cachoeiras', value: 'Três Cachoeiras' },
  { label: 'Tres Cantos', value: 'Tres Cantos' },
  { label: 'Tres Capones', value: 'Tres Capones' },
  { label: 'Tres Cerros', value: 'Tres Cerros' },
  { label: 'Três Corações', value: 'Três Corações' },
  { label: 'Três Coroas', value: 'Três Coroas' },
  { label: 'Tres Cruces', value: 'Tres Cruces' },
  { label: 'Três De Maio', value: 'Três De Maio' },
  { label: 'Tres De Mayo', value: 'Tres De Mayo' },
  { label: 'Tres Estrellas', value: 'Tres Estrellas' },
  { label: 'Três Forquilhas', value: 'Três Forquilhas' },
  { label: 'Três Fronteiras', value: 'Três Fronteiras' },
  { label: 'Tres Garantías', value: 'Tres Garantías' },
  { label: 'Tres Isletas', value: 'Tres Isletas' },
  { label: 'Três Lagoas', value: 'Três Lagoas' },
  { label: 'Tres Marías', value: 'Tres Marías' },
  { label: 'Três Marias', value: 'Três Marias' },
  { label: 'Tres Mezquites', value: 'Tres Mezquites' },
  { label: 'Tres Ombues', value: 'Tres Ombues' },
  { label: 'Três Palmeiras', value: 'Três Palmeiras' },
  { label: 'Tres Palos', value: 'Tres Palos' },
  { label: 'Três Passos', value: 'Três Passos' },
  { label: 'Tres Picos', value: 'Tres Picos' },
  { label: 'Três Pontas', value: 'Três Pontas' },
  { label: 'Tres Quebradas', value: 'Tres Quebradas' },
  { label: 'Três Ranchos', value: 'Três Ranchos' },
  { label: 'Tres Ríos', value: 'Tres Ríos' },
  { label: 'Três Rios', value: 'Três Rios' },
  { label: 'Tres Valles', value: 'Tres Valles' },
  { label: 'Tres Villas Las', value: 'Tres Villas Las' },
  { label: 'Tres Zapotes', value: 'Tres Zapotes' },
  { label: 'Tresana', value: 'Tresana' },
  { label: 'Tresbœuf', value: 'Tresbœuf' },
  { label: 'Trescasas', value: 'Trescasas' },
  { label: 'Trescine', value: 'Trescine' },
  { label: 'Trescore Balneario', value: 'Trescore Balneario' },
  { label: 'Trescore Cremasco', value: 'Trescore Cremasco' },
  { label: 'Tresigallo-Final Di Rero', value: 'Tresigallo-Final Di Rero' },
  { label: 'Tresivio', value: 'Tresivio' },
  { label: 'Tresjuncos', value: 'Tresjuncos' },
  { label: 'Tresnuraghes', value: 'Tresnuraghes' },
  { label: 'Trespaderne', value: 'Trespaderne' },
  { label: 'Tresques', value: 'Tresques' },
  { label: 'Tressange', value: 'Tressange' },
  { label: 'Tresserve', value: 'Tresserve' },
  { label: 'Tresses', value: 'Tresses' },
  { label: 'Tressin', value: 'Tressin' },
  { label: 'Třešť', value: 'Třešť' },
  { label: 'Trestia', value: 'Trestia' },
  { label: 'Trestiana', value: 'Trestiana' },
  { label: 'Trestieni', value: 'Trestieni' },
  { label: 'Trestina', value: 'Trestina' },
  { label: 'Tresviso', value: 'Tresviso' },
  { label: 'Tretiy Severnyy', value: 'Tretiy Severnyy' },
  { label: 'Trets', value: 'Trets' },
  { label: 'Tretten', value: 'Tretten' },
  { label: 'Treuchtlingen', value: 'Treuchtlingen' },
  { label: 'Treuddyn', value: 'Treuddyn' },
  { label: 'Treuen', value: 'Treuen' },
  { label: 'Treuenbrietzen', value: 'Treuenbrietzen' },
  { label: 'Treutlen County', value: 'Treutlen County' },
  { label: 'Trévago', value: 'Trévago' },
  { label: 'Trevallyn', value: 'Trevallyn' },
  { label: 'Trévé', value: 'Trévé' },
  { label: 'Trevélez', value: 'Trevélez' },
  { label: 'Trevelin', value: 'Trevelin' },
  { label: 'Trevenzuolo', value: 'Trevenzuolo' },
  { label: 'Trevi', value: 'Trevi' },
  { label: 'Trevi Nel Lazio', value: 'Trevi Nel Lazio' },
  { label: 'Treviana', value: 'Treviana' },
  { label: 'Trevico', value: 'Trevico' },
  { label: 'Treviglio', value: 'Treviglio' },
  { label: 'Trevignano', value: 'Trevignano' },
  { label: 'Trevignano Romano', value: 'Trevignano Romano' },
  { label: 'Treville', value: 'Treville' },
  { label: 'Treviolo', value: 'Treviolo' },
  { label: 'Treviso', value: 'Treviso' },
  { label: 'Treviso', value: 'Treviso' },
  { label: 'Trévol', value: 'Trévol' },
  { label: 'Trevorton', value: 'Trevorton' },
  { label: 'Trevose', value: 'Trevose' },
  { label: 'Trévou-Tréguignec', value: 'Trévou-Tréguignec' },
  { label: 'Trévoux', value: 'Trévoux' },
  { label: 'Trexlertown', value: 'Trexlertown' },
  { label: 'Treyvaux', value: 'Treyvaux' },
  { label: 'Treze De Maio', value: 'Treze De Maio' },
  { label: 'Treze Tílias', value: 'Treze Tílias' },
  { label: 'Treznea', value: 'Treznea' },
  { label: 'Trezzano Rosa', value: 'Trezzano Rosa' },
  { label: 'Trezzano Sul Naviglio', value: 'Trezzano Sul Naviglio' },
  { label: 'Trezzo Sulladda', value: 'Trezzo Sulladda' },
  { label: 'Trezzo Tinella', value: 'Trezzo Tinella' },
  { label: 'Trezzone', value: 'Trezzone' },
  { label: 'Trhové Sviny', value: 'Trhové Sviny' },
  { label: 'Trhový Štěpánov', value: 'Trhový Štěpánov' },
  { label: 'Triacastela', value: 'Triacastela' },
  { label: 'Triaize', value: 'Triaize' },
  { label: 'Triana', value: 'Triana' },
  { label: 'Triandaíika', value: 'Triandaíika' },
  { label: 'Triandría', value: 'Triandría' },
  { label: 'Triangle', value: 'Triangle' },
  { label: 'Tribaldos', value: 'Tribaldos' },
  { label: 'Tribano', value: 'Tribano' },
  { label: 'Triberg', value: 'Triberg' },
  { label: 'Tribes Hill', value: 'Tribes Hill' },
  { label: 'Tribiano', value: 'Tribiano' },
  { label: 'Tribogna', value: 'Tribogna' },
  { label: 'Tribsees', value: 'Tribsees' },
  { label: 'Tribune', value: 'Tribune' },
  { label: 'Tribunj', value: 'Tribunj' },
  { label: 'Tribuswinkel', value: 'Tribuswinkel' },
  { label: 'Tricarico', value: 'Tricarico' },
  { label: 'Tricase', value: 'Tricase' },
  { label: 'Tricerro', value: 'Tricerro' },
  { label: 'Tricesimo', value: 'Tricesimo' },
  { label: 'Trichiana', value: 'Trichiana' },
  { label: 'Tricht', value: 'Tricht' },
  { label: 'Tricio', value: 'Tricio' },
  { label: 'Tri-City', value: 'Tri-City' },
  { label: 'Tricot', value: 'Tricot' },
  { label: 'Triebel', value: 'Triebel' },
  { label: 'Trieben', value: 'Trieben' },
  { label: 'Triebendorf', value: 'Triebendorf' },
  { label: 'Triebes', value: 'Triebes' },
  { label: 'Trie-Château', value: 'Trie-Château' },
  { label: 'Triei', value: 'Triei' },
  { label: 'Triel-Sur-Seine', value: 'Triel-Sur-Seine' },
  { label: 'Triengen', value: 'Triengen' },
  { label: 'Trier', value: 'Trier' },
  { label: 'Trierweiler', value: 'Trierweiler' },
  { label: 'Triesen', value: 'Triesen' },
  { label: 'Triesenberg', value: 'Triesenberg' },
  { label: 'Trieste', value: 'Trieste' },
  { label: 'Trie-Sur-Baïse', value: 'Trie-Sur-Baïse' },
  { label: 'Trieux', value: 'Trieux' },
  { label: 'Trifeşti', value: 'Trifeşti' },
  { label: 'Trifeşti', value: 'Trifeşti' },
  { label: 'Triftern', value: 'Triftern' },
  { label: 'Trige', value: 'Trige' },
  { label: 'Trigg', value: 'Trigg' },
  { label: 'Trigg County', value: 'Trigg County' },
  { label: 'Triggiano', value: 'Triggiano' },
  { label: 'Triginto', value: 'Triginto' },
  { label: 'Trignac', value: 'Trignac' },
  { label: 'Trigolo', value: 'Trigolo' },
  { label: 'Triguères', value: 'Triguères' },
  { label: 'Trigueros', value: 'Trigueros' },
  { label: 'Trigueros Del Valle', value: 'Trigueros Del Valle' },
  { label: 'Trijueque', value: 'Trijueque' },
  { label: 'Tríkala', value: 'Tríkala' },
  { label: 'Tríkala', value: 'Tríkala' },
  { label: 'Tríkeri', value: 'Tríkeri' },
  { label: 'Tríkomo', value: 'Tríkomo' },
  { label: 'Tri-Lakes', value: 'Tri-Lakes' },
  { label: 'Trilj', value: 'Trilj' },
  { label: 'Trillo', value: 'Trillo' },
  { label: 'Trílofos', value: 'Trílofos' },
  { label: 'Trilport', value: 'Trilport' },
  { label: 'Trim', value: 'Trim' },
  { label: 'Trimbach', value: 'Trimbach' },
  { label: 'Trimbak', value: 'Trimbak' },
  { label: 'Trimble County', value: 'Trimble County' },
  { label: 'Trimdon', value: 'Trimdon' },
  { label: 'Trimdon Grange', value: 'Trimdon Grange' },
  { label: 'Trimmis', value: 'Trimmis' },
  { label: 'Trimsaran', value: 'Trimsaran' },
  { label: 'Trincomalee', value: 'Trincomalee' },
  { label: 'Trincomalee District', value: 'Trincomalee District' },
  { label: 'Trindade', value: 'Trindade' },
  { label: 'Trindade', value: 'Trindade' },
  { label: 'Trindade', value: 'Trindade' },
  { label: 'Trindade', value: 'Trindade' },
  { label: 'Trindade Do Sul', value: 'Trindade Do Sul' },
  { label: 'Třinec', value: 'Třinec' },
  { label: 'Tring', value: 'Tring' },
  { label: 'Trinidad', value: 'Trinidad' },
  { label: 'Trinidad', value: 'Trinidad' },
  { label: 'Trinidad', value: 'Trinidad' },
  { label: 'Trinidad', value: 'Trinidad' },
  { label: 'Trinidad', value: 'Trinidad' },
  { label: 'Trinidad', value: 'Trinidad' },
  { label: 'Trinidad', value: 'Trinidad' },
  { label: 'Trinidad', value: 'Trinidad' },
  { label: 'Trinidad De Copán', value: 'Trinidad De Copán' },
  { label: 'Trinidad De Viguera', value: 'Trinidad De Viguera' },
  { label: 'Trinidad García De La Cadena', value: 'Trinidad García De La Cadena' },
  { label: 'Trinidad Zaachila', value: 'Trinidad Zaachila' },
  { label: 'Trinità', value: 'Trinità' },
  { label: 'Trinità Dagultu', value: 'Trinità Dagultu' },
  { label: 'Trinità Dagultu E Vignola', value: 'Trinità Dagultu E Vignola' },
  { label: 'Trinitapoli', value: 'Trinitapoli' },
  { label: 'Trinity', value: 'Trinity' },
  { label: 'Trinity', value: 'Trinity' },
  { label: 'Trinity', value: 'Trinity' },
  { label: 'Trinity', value: 'Trinity' },
  { label: 'Trinity', value: 'Trinity' },
  { label: 'Trinity Beach', value: 'Trinity Beach' },
  { label: 'Trinity County', value: 'Trinity County' },
  { label: 'Trinity County', value: 'Trinity County' },
  { label: 'Trinity Gardens', value: 'Trinity Gardens' },
  { label: 'Trinity Park', value: 'Trinity Park' },
  { label: 'Trinity Ville', value: 'Trinity Ville' },
  { label: 'Trinityville', value: 'Trinityville' },
  { label: 'Trino', value: 'Trino' },
  { label: 'Trins', value: 'Trins' },
  { label: 'Trinta', value: 'Trinta' },
  { label: 'Trinwillershagen', value: 'Trinwillershagen' },
  { label: 'Triolet', value: 'Triolet' },
  { label: 'Triollo', value: 'Triollo' },
  { label: 'Trion', value: 'Trion' },
  { label: 'Triora', value: 'Triora' },
  { label: 'Trip', value: 'Trip' },
  { label: 'Tripi', value: 'Tripi' },
  { label: 'Tripoli', value: 'Tripoli' },
  { label: 'Tripoli', value: 'Tripoli' },
  { label: 'Tripoli', value: 'Tripoli' },
  { label: 'Trípoli', value: 'Trípoli' },
  { label: 'Tripp County', value: 'Tripp County' },
  { label: 'Trippstadt', value: 'Trippstadt' },
  { label: 'Triptis', value: 'Triptis' },
  { label: 'Triscina', value: 'Triscina' },
  { label: 'Trisobbio', value: 'Trisobbio' },
  { label: 'Trissino', value: 'Trissino' },
  { label: 'Tristach', value: 'Tristach' },
  { label: 'Tritenii De Jos', value: 'Tritenii De Jos' },
  { label: 'Tritenii De Sus', value: 'Tritenii De Sus' },
  { label: 'Trith-Saint-Léger', value: 'Trith-Saint-Léger' },
  { label: 'Trittau', value: 'Trittau' },
  { label: 'Trittenheim', value: 'Trittenheim' },
  { label: 'Triuggio', value: 'Triuggio' },
  { label: 'Triunfo', value: 'Triunfo' },
  { label: 'Triunfo', value: 'Triunfo' },
  { label: 'Triunfo', value: 'Triunfo' },
  { label: 'Triunfo Potiguar', value: 'Triunfo Potiguar' },
  { label: 'Trivalea-Moşteni', value: 'Trivalea-Moşteni' },
  { label: 'Trivento', value: 'Trivento' },
  { label: 'Trivero-Prativero-Ponzone', value: 'Trivero-Prativero-Ponzone' },
  { label: 'Trivigliano', value: 'Trivigliano' },
  { label: 'Trivignano', value: 'Trivignano' },
  { label: 'Trivignano Udinese', value: 'Trivignano Udinese' },
  { label: 'Trivigno', value: 'Trivigno' },
  { label: 'Trivolzio', value: 'Trivolzio' },
  { label: 'Trizay', value: 'Trizay' },
  { label: 'Trizidela Do Vale', value: 'Trizidela Do Vale' },
  { label: 'Trmice', value: 'Trmice' },
  { label: 'Trn', value: 'Trn' },
  { label: 'Trnava', value: 'Trnava' },
  { label: 'Trnava', value: 'Trnava' },
  { label: 'Trnovlje Pri Celju', value: 'Trnovlje Pri Celju' },
  { label: 'Trnovo District', value: 'Trnovo District' },
  { label: 'Trnovska Vas', value: 'Trnovska Vas' },
  { label: 'Troarn', value: 'Troarn' },
  { label: 'Trochtelfingen', value: 'Trochtelfingen' },
  { label: 'Trodena', value: 'Trodena' },
  { label: 'Trodica', value: 'Trodica' },
  { label: 'Trofa', value: 'Trofa' },
  { label: 'Trofaiach', value: 'Trofaiach' },
  { label: 'Trofarello', value: 'Trofarello' },
  { label: 'Trofors', value: 'Trofors' },
  { label: 'Trogen', value: 'Trogen' },
  { label: 'Trogen', value: 'Trogen' },
  { label: 'Trogir', value: 'Trogir' },
  { label: 'Trøgstad', value: 'Trøgstad' },
  { label: 'Troia', value: 'Troia' },
  { label: 'Troianul', value: 'Troianul' },
  { label: 'Troiany', value: 'Troiany' },
  { label: 'Troina', value: 'Troina' },
  { label: 'Troinex', value: 'Troinex' },
  { label: 'Trois Boutiques', value: 'Trois Boutiques' },
  { label: 'Trois Piton', value: 'Trois Piton' },
  { label: 'Troisdorf', value: 'Troisdorf' },
  { label: 'Trois-Ponts', value: 'Trois-Ponts' },
  { label: 'Trois-Rivières', value: 'Trois-Rivières' },
  { label: 'Troissereux', value: 'Troissereux' },
  { label: 'Troistorrents', value: 'Troistorrents' },
  { label: 'Troisvierges', value: 'Troisvierges' },
  { label: 'Troița', value: 'Troița' },
  { label: 'Troitsk', value: 'Troitsk' },
  { label: 'Troitsk', value: 'Troitsk' },
  { label: 'Troitsk', value: 'Troitsk' },
  { label: 'Troitskaya', value: 'Troitskaya' },
  { label: 'Troitskaya', value: 'Troitskaya' },
  { label: 'Troitskiy', value: 'Troitskiy' },
  { label: 'Troitskiy', value: 'Troitskiy' },
  { label: 'Troitsko-Pechorsk', value: 'Troitsko-Pechorsk' },
  { label: 'Troitsko-Pechorskiy Rayon', value: 'Troitsko-Pechorskiy Rayon' },
  { label: 'Troitskoye', value: 'Troitskoye' },
  { label: 'Troitskoye', value: 'Troitskoye' },
  { label: 'Troitskoye', value: 'Troitskoye' },
  { label: 'Troitskoye', value: 'Troitskoye' },
  { label: 'Troitskoye', value: 'Troitskoye' },
  { label: 'Troja', value: 'Troja' },
  { label: 'Trojanovice', value: 'Trojanovice' },
  { label: 'Trojanów', value: 'Trojanów' },
  { label: 'Trojas', value: 'Trojas' },
  { label: 'Trojes', value: 'Trojes' },
  { label: 'Trollenhagen', value: 'Trollenhagen' },
  { label: 'Trollhättan', value: 'Trollhättan' },
  { label: 'Trombas', value: 'Trombas' },
  { label: 'Trombay', value: 'Trombay' },
  { label: 'Trombudo Central', value: 'Trombudo Central' },
  { label: 'Tromello', value: 'Tromello' },
  { label: 'Tromsø', value: 'Tromsø' },
  { label: 'Tronca', value: 'Tronca' },
  { label: 'Tronchón', value: 'Tronchón' },
  { label: 'Tronconal', value: 'Tronconal' },
  { label: 'Tronconal', value: 'Tronconal' },
  { label: 'Trondheim', value: 'Trondheim' },
  { label: 'Trongsa', value: 'Trongsa' },
  { label: 'Trönninge', value: 'Trönninge' },
  { label: 'Trontano', value: 'Trontano' },
  { label: 'Tronville-En-Barrois', value: 'Tronville-En-Barrois' },
  { label: 'Tronzano Lago Maggiore', value: 'Tronzano Lago Maggiore' },
  { label: 'Tronzano Vercellese', value: 'Tronzano Vercellese' },
  { label: 'Troon', value: 'Troon' },
  { label: 'Trooper', value: 'Trooper' },
  { label: 'Trooz', value: 'Trooz' },
  { label: 'Troparëvo', value: 'Troparëvo' },
  { label: 'Tropea', value: 'Tropea' },
  { label: 'Trophy Club', value: 'Trophy Club' },
  { label: 'Trørød', value: 'Trørød' },
  { label: 'Trosa', value: 'Trosa' },
  { label: 'Trosly-Breuil', value: 'Trosly-Breuil' },
  { label: 'Trosna', value: 'Trosna' },
  { label: 'Trossin', value: 'Trossin' },
  { label: 'Trössing', value: 'Trössing' },
  { label: 'Trossingen', value: 'Trossingen' },
  { label: 'Tröstau', value: 'Tröstau' },
  { label: 'Trostberg An Der Alz', value: 'Trostberg An Der Alz' },
  { label: 'Trostyanets’', value: 'Trostyanets’' },
  { label: 'Troszyn', value: 'Troszyn' },
  { label: 'Trott Park', value: 'Trott Park' },
  { label: 'Trotwood', value: 'Trotwood' },
  { label: 'Trou Aux Biches', value: 'Trou Aux Biches' },
  { label: 'Trou Cochan/Marc', value: 'Trou Cochan/Marc' },
  { label: 'Trou Deau Douce', value: 'Trou Deau Douce' },
  { label: 'Trou Du Nord', value: 'Trou Du Nord' },
  { label: 'Trou Florent/Marc', value: 'Trou Florent/Marc' },
  { label: 'Trou Rouge', value: 'Trou Rouge' },
  { label: 'Troubelice', value: 'Troubelice' },
  { label: 'Troubky', value: 'Troubky' },
  { label: 'Troubsko', value: 'Troubsko' },
  { label: 'Trouillas', value: 'Trouillas' },
  { label: 'Troúlloi', value: 'Troúlloi' },
  { label: 'Troumassee', value: 'Troumassee' },
  { label: 'Troup', value: 'Troup' },
  { label: 'Troup County', value: 'Troup County' },
  { label: 'Trousdale County', value: 'Trousdale County' },
  { label: 'Trout Hall', value: 'Trout Hall' },
  { label: 'Troutdale', value: 'Troutdale' },
  { label: 'Troutman', value: 'Troutman' },
  { label: 'Trouville-Sur-Mer', value: 'Trouville-Sur-Mer' },
  { label: 'Trouy', value: 'Trouy' },
  { label: 'Troviggiano', value: 'Troviggiano' },
  { label: 'Trovo', value: 'Trovo' },
  { label: 'Trowbridge', value: 'Trowbridge' },
  { label: 'Trowbridge Park', value: 'Trowbridge Park' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troy', value: 'Troy' },
  { label: 'Troyan', value: 'Troyan' },
  { label: 'Troyebratskiy', value: 'Troyebratskiy' },
  { label: 'Troyes', value: 'Troyes' },
  { label: 'Trpinja', value: 'Trpinja' },
  { label: 'Tršić', value: 'Tršić' },
  { label: 'Tršice', value: 'Tršice' },
  { label: 'Trstená', value: 'Trstená' },
  { label: 'Trstenik', value: 'Trstenik' },
  { label: 'Trub', value: 'Trub' },
  { label: 'Trubchevsk', value: 'Trubchevsk' },
  { label: 'Trubchevskiy Rayon', value: 'Trubchevskiy Rayon' },
  { label: 'Trubschachen', value: 'Trubschachen' },
  { label: 'Truccazzano', value: 'Truccazzano' },
  { label: 'Truchas', value: 'Truchas' },
  { label: 'Truchtersheim', value: 'Truchtersheim' },
  { label: 'Trucios-Turtzioz', value: 'Trucios-Turtzioz' },
  { label: 'Truckee', value: 'Truckee' },
  { label: 'Trucksville', value: 'Trucksville' },
  { label: 'Trucuk', value: 'Trucuk' },
  { label: 'Trudarmeyskiy', value: 'Trudarmeyskiy' },
  { label: 'Trudfront', value: 'Trudfront' },
  { label: 'Trudobelikovskiy', value: 'Trudobelikovskiy' },
  { label: 'Trudovoye', value: 'Trudovoye' },
  { label: 'Trudovoye', value: 'Trudovoye' },
  { label: 'Truganina', value: 'Truganina' },
  { label: 'Trujillanos', value: 'Trujillanos' },
  { label: 'Trujillo', value: 'Trujillo' },
  { label: 'Trujillo', value: 'Trujillo' },
  { label: 'Trujillo', value: 'Trujillo' },
  { label: 'Trujillo', value: 'Trujillo' },
  { label: 'Trujillo', value: 'Trujillo' },
  { label: 'Trujillo Alto', value: 'Trujillo Alto' },
  { label: 'Trujillo Alto', value: 'Trujillo Alto' },
  { label: 'Trujillo Alto Municipio', value: 'Trujillo Alto Municipio' },
  { label: 'Trulben', value: 'Trulben' },
  { label: 'Truman', value: 'Truman' },
  { label: 'Trumann', value: 'Trumann' },
  { label: 'Trumansburg', value: 'Trumansburg' },
  { label: 'Trumau', value: 'Trumau' },
  { label: 'Trumbull', value: 'Trumbull' },
  { label: 'Trumbull County', value: 'Trumbull County' },
  { label: 'Trun', value: 'Trun' },
  { label: 'Trun', value: 'Trun' },
  { label: 'Trunding', value: 'Trunding' },
  { label: 'Trunkelsberg', value: 'Trunkelsberg' },
  { label: 'Trunovskoye', value: 'Trunovskoye' },
  { label: 'Truro', value: 'Truro' },
  { label: 'Truro', value: 'Truro' },
  { label: 'Truro', value: 'Truro' },
  { label: 'Truro', value: 'Truro' },
  { label: 'Truşeşti', value: 'Truşeşti' },
  { label: 'Trusetal', value: 'Trusetal' },
  { label: 'Truskavets’', value: 'Truskavets’' },
  { label: 'Truskaw', value: 'Truskaw' },
  { label: 'Truskolasy', value: 'Truskolasy' },
  { label: 'Trussville', value: 'Trussville' },
  { label: 'Truth Or Consequences', value: 'Truth Or Consequences' },
  { label: 'Trutnov', value: 'Trutnov' },
  { label: 'Truyes', value: 'Truyes' },
  { label: 'Tryavna', value: 'Tryavna' },
  { label: 'Tryńcza', value: 'Tryńcza' },
  { label: 'Tryon', value: 'Tryon' },
  { label: 'Tryon', value: 'Tryon' },
  { label: 'Trypillia', value: 'Trypillia' },
  { label: 'Trysil', value: 'Trysil' },
  { label: 'Tržačka Raštela', value: 'Tržačka Raštela' },
  { label: 'Trzciana', value: 'Trzciana' },
  { label: 'Trzciana', value: 'Trzciana' },
  { label: 'Trzcianka', value: 'Trzcianka' },
  { label: 'Trzciel', value: 'Trzciel' },
  { label: 'Trzcinica', value: 'Trzcinica' },
  { label: 'Trzcinica', value: 'Trzcinica' },
  { label: 'Trzcińsko Zdrój', value: 'Trzcińsko Zdrój' },
  { label: 'Trzebiatów', value: 'Trzebiatów' },
  { label: 'Trzebiechów', value: 'Trzebiechów' },
  { label: 'Trzebiel', value: 'Trzebiel' },
  { label: 'Trzebielino', value: 'Trzebielino' },
  { label: 'Trzebieszów', value: 'Trzebieszów' },
  { label: 'Trzebinia', value: 'Trzebinia' },
  { label: 'Trzebinia', value: 'Trzebinia' },
  { label: 'Trzebnica', value: 'Trzebnica' },
  { label: 'Trzebownisko', value: 'Trzebownisko' },
  { label: 'Trzebunia', value: 'Trzebunia' },
  { label: 'Trzemeśnia', value: 'Trzemeśnia' },
  { label: 'Trzemeszno', value: 'Trzemeszno' },
  { label: 'Tržič', value: 'Tržič' },
  { label: 'Trzin', value: 'Trzin' },
  { label: 'Trzydnik Duży', value: 'Trzydnik Duży' },
  { label: 'Ts’Alk’Is Munitsip’Alit’Et’I', value: 'Ts’Alk’Is Munitsip’Alit’Et’I' },
  { label: 'Ts’Khinvali', value: 'Ts’Khinvali' },
  { label: 'Tsáda', value: 'Tsáda' },
  { label: 'Tsafe', value: 'Tsafe' },
  { label: 'Tsagan Aman', value: 'Tsagan Aman' },
  { label: 'Tsaghkaber', value: 'Tsaghkaber' },
  { label: 'Tsaghkadzor', value: 'Tsaghkadzor' },
  { label: 'Tsaghkahovit', value: 'Tsaghkahovit' },
  { label: 'Tsaghkunk’', value: 'Tsaghkunk’' },
  { label: 'Tsaghveri', value: 'Tsaghveri' },
  { label: 'Tsaile', value: 'Tsaile' },
  { label: 'Tsalenjikha', value: 'Tsalenjikha' },
  { label: 'Tsalka', value: 'Tsalka' },
  { label: 'Tsar Kaloyan', value: 'Tsar Kaloyan' },
  { label: 'Tsarevo', value: 'Tsarevo' },
  { label: 'Tsaritsyno', value: 'Tsaritsyno' },
  { label: 'Tsarychanka', value: 'Tsarychanka' },
  { label: 'Tsarychans’Kyy Rayon', value: 'Tsarychans’Kyy Rayon' },
  { label: 'Tsawwassen', value: 'Tsawwassen' },
  { label: 'Tschachoritsch', value: 'Tschachoritsch' },
  { label: 'Tschagguns', value: 'Tschagguns' },
  { label: 'Tschanigraben', value: 'Tschanigraben' },
  { label: 'Tschernitz', value: 'Tschernitz' },
  { label: 'Tselina', value: 'Tselina' },
  { label: 'Tselinnoye', value: 'Tselinnoye' },
  { label: 'Tselinnoye', value: 'Tselinnoye' },
  { label: 'Tselinnoye', value: 'Tselinnoye' },
  { label: 'Tsementnozavodskiy', value: 'Tsementnozavodskiy' },
  { label: 'Tsementnyy', value: 'Tsementnyy' },
  { label: 'Tsengel', value: 'Tsengel' },
  { label: 'Tsengel', value: 'Tsengel' },
  { label: 'Tsenovo', value: 'Tsenovo' },
  { label: 'Tsentoroy', value: 'Tsentoroy' },
  { label: 'Tsentral’Ny Rayon', value: 'Tsentral’Ny Rayon' },
  { label: 'Tsentral’Nyy', value: 'Tsentral’Nyy' },
  { label: 'Tsentral’Nyy Administrativnyy Okrug', value: 'Tsentral’Nyy Administrativnyy Okrug' },
  { label: 'Tséri', value: 'Tséri' },
  { label: 'Tses', value: 'Tses' },
  { label: 'Tsetserleg', value: 'Tsetserleg' },
  { label: 'Tseung Kwan O', value: 'Tseung Kwan O' },
  { label: 'Tsévié', value: 'Tsévié' },
  { label: 'Tshabong', value: 'Tshabong' },
  { label: 'Tshalunang', value: 'Tshalunang' },
  { label: 'Tshaneni', value: 'Tshaneni' },
  { label: 'Tshela', value: 'Tshela' },
  { label: 'Tshikapa', value: 'Tshikapa' },
  { label: 'Tsiatsan', value: 'Tsiatsan' },
  { label: 'Tsibanobalka', value: 'Tsibanobalka' },
  { label: 'Tsibulev', value: 'Tsibulev' },
  { label: 'Tsidjé', value: 'Tsidjé' },
  { label: 'Tsiflikópoulo', value: 'Tsiflikópoulo' },
  { label: 'Tsikalariá', value: 'Tsikalariá' },
  { label: 'Tsikhisdziri', value: 'Tsikhisdziri' },
  { label: 'Tsil’Na', value: 'Tsil’Na' },
  { label: 'Tsil’Ninskiy Rayon', value: 'Tsil’Ninskiy Rayon' },
  { label: 'Tsim Sha Tsui', value: 'Tsim Sha Tsui' },
  { label: 'Tsimbeo', value: 'Tsimbeo' },
  { label: 'Tsimkavichy', value: 'Tsimkavichy' },
  { label: 'Tsimlyansk', value: 'Tsimlyansk' },
  { label: 'Tsinandali', value: 'Tsinandali' },
  { label: 'Tsiolkovskiy', value: 'Tsiolkovskiy' },
  { label: 'Tsirang', value: 'Tsirang' },
  { label: 'Tsivil’Sk', value: 'Tsivil’Sk' },
  { label: 'Tsivil’Skiy Rayon', value: 'Tsivil’Skiy Rayon' },
  { label: 'Tsnori', value: 'Tsnori' },
  { label: 'Tsotíli', value: 'Tsotíli' },
  { label: 'Tsotsin-Yurt', value: 'Tsotsin-Yurt' },
  { label: 'Tsovagyugh', value: 'Tsovagyugh' },
  { label: 'Tsovak', value: 'Tsovak' },
  { label: 'Tsovasar', value: 'Tsovasar' },
  { label: 'Tsovazard', value: 'Tsovazard' },
  { label: 'Tsovinar', value: 'Tsovinar' },
  { label: 'Tsqaltubo', value: 'Tsqaltubo' },
  { label: 'Tsrar Sharif', value: 'Tsrar Sharif' },
  { label: 'Tsu', value: 'Tsu' },
  { label: 'Tsubame', value: 'Tsubame' },
  { label: 'Tsubame Shi', value: 'Tsubame Shi' },
  { label: 'Tsubata', value: 'Tsubata' },
  { label: 'Tsuchiura-Shi', value: 'Tsuchiura-Shi' },
  { label: 'Tsuen Wan', value: 'Tsuen Wan' },
  { label: 'Tsugaru', value: 'Tsugaru' },
  { label: 'Tsugaru Shi', value: 'Tsugaru Shi' },
  { label: 'Tsuiki', value: 'Tsuiki' },
  { label: 'Tsukawaki', value: 'Tsukawaki' },
  { label: 'Tsukuba', value: 'Tsukuba' },
  { label: 'Tsukubamirai', value: 'Tsukubamirai' },
  { label: 'Tsukubamirai-Shi', value: 'Tsukubamirai-Shi' },
  { label: 'Tsukuba-Shi', value: 'Tsukuba-Shi' },
  { label: 'Tsukumi-Shi', value: 'Tsukumi-Shi' },
  { label: 'Tsukumiura', value: 'Tsukumiura' },
  { label: 'Tsuma', value: 'Tsuma' },
  { label: 'Tsumadinskiy Rayon', value: 'Tsumadinskiy Rayon' },
  { label: 'Tsumeb', value: 'Tsumeb' },
  { label: 'Tsuno', value: 'Tsuno' },
  { label: 'Tsuntinskiy Rayon', value: 'Tsuntinskiy Rayon' },
  { label: 'Tsurib', value: 'Tsurib' },
  { label: 'Tsuruga', value: 'Tsuruga' },
  { label: 'Tsuruga-Shi', value: 'Tsuruga-Shi' },
  { label: 'Tsurugashima-Shi', value: 'Tsurugashima-Shi' },
  { label: 'Tsurugi-Asahimachi', value: 'Tsurugi-Asahimachi' },
  { label: 'Tsuruoka', value: 'Tsuruoka' },
  { label: 'Tsuruoka Shi', value: 'Tsuruoka Shi' },
  { label: 'Tsurusaki', value: 'Tsurusaki' },
  { label: 'Tsuru-Shi', value: 'Tsuru-Shi' },
  { label: 'Tsu-Shi', value: 'Tsu-Shi' },
  { label: 'Tsushima', value: 'Tsushima' },
  { label: 'Tsushima Shi', value: 'Tsushima Shi' },
  { label: 'Tsushima-Shi', value: 'Tsushima-Shi' },
  { label: 'Tsuyama', value: 'Tsuyama' },
  { label: 'Tsuyama-Shi', value: 'Tsuyama-Shi' },
  { label: 'Tsvetochnoye', value: 'Tsvetochnoye' },
  { label: 'Tsvitkove', value: 'Tsvitkove' },
  { label: 'Tsybli', value: 'Tsybli' },
  { label: 'Tsybulevka', value: 'Tsybulevka' },
  { label: 'Tsyelyakhany', value: 'Tsyelyakhany' },
  { label: 'Tsyurupyns’K', value: 'Tsyurupyns’K' },
  { label: 'Tual', value: 'Tual' },
  { label: 'Tualatin', value: 'Tualatin' },
  { label: 'Tuam', value: 'Tuam' },
  { label: 'Tuao', value: 'Tuao' },
  { label: 'Tuao', value: 'Tuao' },
  { label: 'Tuapse', value: 'Tuapse' },
  { label: 'Tuapsinskiy Rayon', value: 'Tuapsinskiy Rayon' },
  { label: 'Tuart Hill', value: 'Tuart Hill' },
  { label: 'Tuba', value: 'Tuba' },
  { label: 'Tuba City', value: 'Tuba City' },
  { label: 'Tubac', value: 'Tubac' },
  { label: 'Tubajon', value: 'Tubajon' },
  { label: 'Tubajon', value: 'Tubajon' },
  { label: 'Tubalan', value: 'Tubalan' },
  { label: 'Tubalan', value: 'Tubalan' },
  { label: 'Tubalan', value: 'Tubalan' },
  { label: 'Tuban', value: 'Tuban' },
  { label: 'Tuban', value: 'Tuban' },
  { label: 'Tuban', value: 'Tuban' },
  { label: 'Tuban', value: 'Tuban' },
  { label: 'Tuban', value: 'Tuban' },
  { label: 'Tuban', value: 'Tuban' },
  { label: 'Tubao', value: 'Tubao' },
  { label: 'Tubao', value: 'Tubao' },
  { label: 'Tubao', value: 'Tubao' },
  { label: 'Tubará', value: 'Tubará' },
  { label: 'Tubaran', value: 'Tubaran' },
  { label: 'Tubaran', value: 'Tubaran' },
  { label: 'Tubarão', value: 'Tubarão' },
  { label: 'Ţubarjal', value: 'Ţubarjal' },
  { label: 'Tubay', value: 'Tubay' },
  { label: 'Tubay', value: 'Tubay' },
  { label: 'Tubbergen', value: 'Tubbergen' },
  { label: 'Tubigagmanoc', value: 'Tubigagmanoc' },
  { label: 'Tubigan', value: 'Tubigan' },
  { label: 'Tubilla Del Agua', value: 'Tubilla Del Agua' },
  { label: 'Tubilla Del Lago', value: 'Tubilla Del Lago' },
  { label: 'Tübingen', value: 'Tübingen' },
  { label: 'Tübingen Region', value: 'Tübingen Region' },
  { label: 'Tubinskiy', value: 'Tubinskiy' },
  { label: 'Tubize', value: 'Tubize' },
  { label: 'Tublay', value: 'Tublay' },
  { label: 'Tublay', value: 'Tublay' },
  { label: 'Tubli', value: 'Tubli' },
  { label: 'Tubmanburg', value: 'Tubmanburg' },
  { label: 'Tubo', value: 'Tubo' },
  { label: 'Tubod', value: 'Tubod' },
  { label: 'Tubod', value: 'Tubod' },
  { label: 'Tubod', value: 'Tubod' },
  { label: 'Tubod', value: 'Tubod' },
  { label: 'Tubod', value: 'Tubod' },
  { label: 'Tubod', value: 'Tubod' },
  { label: 'Tubod', value: 'Tubod' },
  { label: 'Tubod-Dugoan', value: 'Tubod-Dugoan' },
  { label: 'Tubre', value: 'Tubre' },
  { label: 'Tubualá', value: 'Tubualá' },
  { label: 'Tuburan', value: 'Tuburan' },
  { label: 'Tuburan', value: 'Tuburan' },
  { label: 'Tuburan', value: 'Tuburan' },
  { label: 'Tuburan', value: 'Tuburan' },
  { label: 'Tubutama', value: 'Tubutama' },
  { label: 'Tucacas', value: 'Tucacas' },
  { label: 'Tucano', value: 'Tucano' },
  { label: 'Tucapel', value: 'Tucapel' },
  { label: 'Tucdao', value: 'Tucdao' },
  { label: 'Tucdao', value: 'Tucdao' },
  { label: 'Tučepi', value: 'Tučepi' },
  { label: 'Tuchaqaz', value: 'Tuchaqaz' },
  { label: 'Tucheim', value: 'Tucheim' },
  { label: 'Tuchenbach', value: 'Tuchenbach' },
  { label: 'Tuchín', value: 'Tuchín' },
  { label: 'Tuchkovo', value: 'Tuchkovo' },
  { label: 'Tuchlovice', value: 'Tuchlovice' },
  { label: 'Tuchola', value: 'Tuchola' },
  { label: 'Tuchoměřice', value: 'Tuchoměřice' },
  { label: 'Tuchomie', value: 'Tuchomie' },
  { label: 'Tuchów', value: 'Tuchów' },
  { label: 'Tuckahoe', value: 'Tuckahoe' },
  { label: 'Tuckahoe', value: 'Tuckahoe' },
  { label: 'Tucker', value: 'Tucker' },
  { label: 'Tucker', value: 'Tucker' },
  { label: 'Tucker County', value: 'Tucker County' },
  { label: 'Tucker’S Town', value: 'Tucker’S Town' },
  { label: 'Tuckerman', value: 'Tuckerman' },
  { label: 'Tuckerton', value: 'Tuckerton' },
  { label: 'Tucquegnieux', value: 'Tucquegnieux' },
  { label: 'Tucson', value: 'Tucson' },
  { label: 'Tucson Estates', value: 'Tucson Estates' },
  { label: 'Tucta', value: 'Tucta' },
  { label: 'Tucumã', value: 'Tucumã' },
  { label: 'Tucumcari', value: 'Tucumcari' },
  { label: 'Túcume', value: 'Túcume' },
  { label: 'Tucunduva', value: 'Tucunduva' },
  { label: 'Tucupita', value: 'Tucupita' },
  { label: 'Tucuran', value: 'Tucuran' },
  { label: 'Tucurrique', value: 'Tucurrique' },
  { label: 'Tucurú', value: 'Tucurú' },
  { label: 'Tucuruí', value: 'Tucuruí' },
  { label: 'Tuczępy', value: 'Tuczępy' },
  { label: 'Tuczno', value: 'Tuczno' },
  { label: 'Tudanca', value: 'Tudanca' },
  { label: 'Tudela', value: 'Tudela' },
  { label: 'Tudela', value: 'Tudela' },
  { label: 'Tudela', value: 'Tudela' },
  { label: 'Tudela De Duero', value: 'Tudela De Duero' },
  { label: 'Tudelilla', value: 'Tudelilla' },
  { label: 'Tuding', value: 'Tuding' },
  { label: 'Tuding', value: 'Tuding' },
  { label: 'Tudor Vladimirescu', value: 'Tudor Vladimirescu' },
  { label: 'Tudor Vladimirescu', value: 'Tudor Vladimirescu' },
  { label: 'Tudor Vladimirescu', value: 'Tudor Vladimirescu' },
  { label: 'Tudor Vladimirescu', value: 'Tudor Vladimirescu' },
  { label: 'Tudora', value: 'Tudora' },
  { label: 'Tuéjar', value: 'Tuéjar' },
  { label: 'Tuen Mun        ', value: 'Tuen Mun        ' },
  { label: 'Tuenno', value: 'Tuenno' },
  { label: 'Tuensang', value: 'Tuensang' },
  { label: 'Tuensang District', value: 'Tuensang District' },
  { label: 'Tufanbeyli', value: 'Tufanbeyli' },
  { label: 'Tufanganj', value: 'Tufanganj' },
  { label: 'Tufara', value: 'Tufara' },
  { label: 'Tufeni', value: 'Tufeni' },
  { label: 'Tufeni', value: 'Tufeni' },
  { label: 'Tufeşti', value: 'Tufeşti' },
  { label: 'Tuffé', value: 'Tuffé' },
  { label: 'Tufilândia', value: 'Tufilândia' },
  { label: 'Tufillo', value: 'Tufillo' },
  { label: 'Tufino', value: 'Tufino' },
  { label: 'Tufo', value: 'Tufo' },
  { label: 'Tuftonboro', value: 'Tuftonboro' },
  { label: 'Tuganay', value: 'Tuganay' },
  { label: 'Tuganay', value: 'Tuganay' },
  { label: 'Tuganay', value: 'Tuganay' },
  { label: 'Tugas', value: 'Tugas' },
  { label: 'Tugaya', value: 'Tugaya' },
  { label: 'Tugaya', value: 'Tugaya' },
  { label: 'Tugbong', value: 'Tugbong' },
  { label: 'Tugbong', value: 'Tugbong' },
  { label: 'Tugdan', value: 'Tugdan' },
  { label: 'Tuggen', value: 'Tuggen' },
  { label: 'Tuggerawong', value: 'Tuggerawong' },
  { label: 'Tūghyl', value: 'Tūghyl' },
  { label: 'Tuglie', value: 'Tuglie' },
  { label: 'Ţuglui', value: 'Ţuglui' },
  { label: 'Tugolesskiy Bor', value: 'Tugolesskiy Bor' },
  { label: 'Tugos', value: 'Tugos' },
  { label: 'Tuguegarao', value: 'Tuguegarao' },
  { label: 'Tuguegarao', value: 'Tuguegarao' },
  { label: 'Tuguegarao City', value: 'Tuguegarao City' },
  { label: 'Tugulym', value: 'Tugulym' },
  { label: 'Tugun', value: 'Tugun' },
  { label: 'Tuhian', value: 'Tuhian' },
  { label: 'Tui', value: 'Tui' },
  { label: 'Tuikwerd', value: 'Tuikwerd' },
  { label: 'Tuili', value: 'Tuili' },
  { label: 'Tuim', value: 'Tuim' },
  { label: 'Tuin', value: 'Tuin' },
  { label: 'Tuindorp', value: 'Tuindorp' },
  { label: 'Tuindorp Oostzaan', value: 'Tuindorp Oostzaan' },
  { label: 'Tuineje', value: 'Tuineje' },
  { label: 'Tuitjenhorn', value: 'Tuitjenhorn' },
  { label: 'Tuiuti', value: 'Tuiuti' },
  { label: 'Tujunga', value: 'Tujunga' },
  { label: 'Tuk', value: 'Tuk' },
  { label: 'Tuka', value: 'Tuka' },
  { label: 'Tuka', value: 'Tuka' },
  { label: 'Tukan', value: 'Tukan' },
  { label: 'Tukayevskiy Rayon', value: 'Tukayevskiy Rayon' },
  { label: 'Tukhlia', value: 'Tukhlia' },
  { label: 'Tūkrah', value: 'Tūkrah' },
  { label: 'Tuktuk Sonak', value: 'Tuktuk Sonak' },
  { label: 'Tukums', value: 'Tukums' },
  { label: 'Tukuyu', value: 'Tukuyu' },
  { label: 'Tukwila', value: 'Tukwila' },
  { label: 'Tukzār', value: 'Tukzār' },
  { label: 'Tula', value: 'Tula' },
  { label: 'Tula', value: 'Tula' },
  { label: 'Tula', value: 'Tula' },
  { label: 'Tula', value: 'Tula' },
  { label: 'Tula', value: 'Tula' },
  { label: 'Tula De Allende', value: 'Tula De Allende' },
  { label: 'Tulagi', value: 'Tulagi' },
  { label: 'Tulalip', value: 'Tulalip' },
  { label: 'Tulalip Bay', value: 'Tulalip Bay' },
  { label: 'Tulancingo', value: 'Tulancingo' },
  { label: 'Tulancingo De Bravo', value: 'Tulancingo De Bravo' },
  { label: 'Tulangan Utara', value: 'Tulangan Utara' },
  { label: 'Tulantongo', value: 'Tulantongo' },
  { label: 'Tulapam', value: 'Tulapam' },
  { label: 'Tulare', value: 'Tulare' },
  { label: 'Tulare County', value: 'Tulare County' },
  { label: 'Tularosa', value: 'Tularosa' },
  { label: 'Tülau', value: 'Tülau' },
  { label: 'Tulay', value: 'Tulay' },
  { label: 'Tulay Na Lupa', value: 'Tulay Na Lupa' },
  { label: 'Tulbing', value: 'Tulbing' },
  { label: 'Tulca', value: 'Tulca' },
  { label: 'Tulcán', value: 'Tulcán' },
  { label: 'Tulcea', value: 'Tulcea' },
  { label: 'Tulchyn', value: 'Tulchyn' },
  { label: 'Tulcingo', value: 'Tulcingo' },
  { label: 'Tulcingo De Valle', value: 'Tulcingo De Valle' },
  { label: 'Tulebras', value: 'Tulebras' },
  { label: 'Tulette', value: 'Tulette' },
  { label: 'Tulfes', value: 'Tulfes' },
  { label: 'Tulgheş', value: 'Tulgheş' },
  { label: 'Tulghieș', value: 'Tulghieș' },
  { label: 'Tuli', value: 'Tuli' },
  { label: 'Tuli', value: 'Tuli' },
  { label: 'Tuli', value: 'Tuli' },
  { label: 'Tulia', value: 'Tulia' },
  { label: 'Tulillos De Abajo', value: 'Tulillos De Abajo' },
  { label: 'Tulimán', value: 'Tulimán' },
  { label: 'Tulimanca', value: 'Tulimanca' },
  { label: 'Tulin', value: 'Tulin' },
  { label: 'Tulipanes', value: 'Tulipanes' },
  { label: 'Tuliszków', value: 'Tuliszków' },
  { label: 'Tuljapur', value: 'Tuljapur' },
  { label: 'Tulki Tappeh', value: 'Tulki Tappeh' },
  { label: 'Tullahoma', value: 'Tullahoma' },
  { label: 'Tullamarine', value: 'Tullamarine' },
  { label: 'Tullamore', value: 'Tullamore' },
  { label: 'Tulle', value: 'Tulle' },
  { label: 'Tullibody', value: 'Tullibody' },
  { label: 'Tullinge', value: 'Tullinge' },
  { label: 'Tullins', value: 'Tullins' },
  { label: 'Tulln', value: 'Tulln' },
  { label: 'Tullnerbach-Lawies', value: 'Tullnerbach-Lawies' },
  { label: 'Tullow', value: 'Tullow' },
  { label: 'Tully', value: 'Tully' },
  { label: 'Tullyallen', value: 'Tullyallen' },
  { label: 'Tullytown', value: 'Tullytown' },
  { label: 'Tulnici', value: 'Tulnici' },
  { label: 'Tułowice', value: 'Tułowice' },
  { label: 'Tulsa', value: 'Tulsa' },
  { label: 'Tulsa County', value: 'Tulsa County' },
  { label: 'Tulsipur', value: 'Tulsipur' },
  { label: 'Tultepec', value: 'Tultepec' },
  { label: 'Tultitlán', value: 'Tultitlán' },
  { label: 'Tultitlán De Mariano Escobedo', value: 'Tultitlán De Mariano Escobedo' },
  { label: 'Tulu Bolo', value: 'Tulu Bolo' },
  { label: 'Tuluá', value: 'Tuluá' },
  { label: 'Tuluceşti', value: 'Tuluceşti' },
  { label: 'Tulum', value: 'Tulum' },
  { label: 'Tulun', value: 'Tulun' },
  { label: 'Tulunan', value: 'Tulunan' },
  { label: 'Tulunan', value: 'Tulunan' },
  { label: 'Tulungagung', value: 'Tulungagung' },
  { label: 'Tulunskiy Rayon', value: 'Tulunskiy Rayon' },
  { label: 'Tulyushka', value: 'Tulyushka' },
  { label: 'Tuma', value: 'Tuma' },
  { label: 'Tumaco', value: 'Tumaco' },
  { label: 'Tumak', value: 'Tumak' },
  { label: 'Tumakuru', value: 'Tumakuru' },
  { label: 'Tumalaytay', value: 'Tumalaytay' },
  { label: 'Tumalim', value: 'Tumalim' },
  { label: 'Tumarbong', value: 'Tumarbong' },
  { label: 'Tumauini', value: 'Tumauini' },
  { label: 'Tumauini', value: 'Tumauini' },
  { label: 'Tumayr', value: 'Tumayr' },
  { label: 'Tumba', value: 'Tumba' },
  { label: 'Tumbagaan', value: 'Tumbagaan' },
  { label: 'Tumbagaan', value: 'Tumbagaan' },
  { label: 'Tumbala', value: 'Tumbala' },
  { label: 'Tumbarumba', value: 'Tumbarumba' },
  { label: 'Tumbes', value: 'Tumbes' },
  { label: 'Tumbi', value: 'Tumbi' },
  { label: 'Tumbi Vmbi', value: 'Tumbi Vmbi' },
  { label: 'Tumbiscatío De Ruiz', value: 'Tumbiscatío De Ruiz' },
  { label: 'Tumble', value: 'Tumble' },
  { label: 'Tumbler Ridge', value: 'Tumbler Ridge' },
  { label: 'Tumbotino', value: 'Tumbotino' },
  { label: 'Tumby Bay', value: 'Tumby Bay' },
  { label: 'Tumcon Ilawod', value: 'Tumcon Ilawod' },
  { label: 'Tumen', value: 'Tumen' },
  { label: 'Tumiritinga', value: 'Tumiritinga' },
  { label: 'Tumpat', value: 'Tumpat' },
  { label: 'Tumsar', value: 'Tumsar' },
  { label: 'Tumut', value: 'Tumut' },
  { label: 'Tumwater', value: 'Tumwater' },
  { label: 'Tunápolis', value: 'Tunápolis' },
  { label: 'Tunapuna', value: 'Tunapuna' },
  { label: 'Tunari', value: 'Tunari' },
  { label: 'Tunas', value: 'Tunas' },
  { label: 'Tunas Do Paraná', value: 'Tunas Do Paraná' },
  { label: 'Tuncingo', value: 'Tuncingo' },
  { label: 'Tuncurry', value: 'Tuncurry' },
  { label: 'Tundla', value: 'Tundla' },
  { label: 'Tunduma', value: 'Tunduma' },
  { label: 'Tune', value: 'Tune' },
  { label: 'Tuneiras Do Oeste', value: 'Tuneiras Do Oeste' },
  { label: 'Tunelu-Teliu', value: 'Tunelu-Teliu' },
  { label: 'Tunes', value: 'Tunes' },
  { label: 'Tunga', value: 'Tunga' },
  { label: 'Tunga', value: 'Tunga' },
  { label: 'Tungao', value: 'Tungao' },
  { label: 'Tungareo', value: 'Tungareo' },
  { label: 'Tungasuca', value: 'Tungasuca' },
  { label: 'Tungawan', value: 'Tungawan' },
  { label: 'Tunggol', value: 'Tunggol' },
  { label: 'Tunggol', value: 'Tunggol' },
  { label: 'Tungol', value: 'Tungol' },
  { label: 'Tungol', value: 'Tungol' },
  { label: 'Tungor', value: 'Tungor' },
  { label: 'Tuni', value: 'Tuni' },
  { label: 'Tunica', value: 'Tunica' },
  { label: 'Tunica County', value: 'Tunica County' },
  { label: 'Tunica Resorts', value: 'Tunica Resorts' },
  { label: 'Tuningen', value: 'Tuningen' },
  { label: 'Tunis', value: 'Tunis' },
  { label: 'Tunititlán', value: 'Tunititlán' },
  { label: 'Tunja', value: 'Tunja' },
  { label: 'Tunkas', value: 'Tunkas' },
  { label: 'Tunkhannock', value: 'Tunkhannock' },
  { label: 'Tunoshna', value: 'Tunoshna' },
  { label: 'Tunstall', value: 'Tunstall' },
  { label: 'Tuntange', value: 'Tuntange' },
  { label: 'Tuntenhausen', value: 'Tuntenhausen' },
  { label: 'Tuntum', value: 'Tuntum' },
  { label: 'Tununguá', value: 'Tununguá' },
  { label: 'Tunyogmatolcs', value: 'Tunyogmatolcs' },
  { label: 'Tuod', value: 'Tuod' },
  { label: 'Tuokou', value: 'Tuokou' },
  { label: 'Tuolumne City', value: 'Tuolumne City' },
  { label: 'Tuolumne County', value: 'Tuolumne County' },
  { label: 'Tuoro Sul Trasimeno', value: 'Tuoro Sul Trasimeno' },
  { label: 'Tup Aghaj', value: 'Tup Aghaj' },
  { label: 'Tupã', value: 'Tupã' },
  { label: 'Tupaciguara', value: 'Tupaciguara' },
  { label: 'Tupambaé', value: 'Tupambaé' },
  { label: 'Tupanatinga', value: 'Tupanatinga' },
  { label: 'Tupanci Do Sul', value: 'Tupanci Do Sul' },
  { label: 'Tupanciretã', value: 'Tupanciretã' },
  { label: 'Tupandi', value: 'Tupandi' },
  { label: 'Tupang', value: 'Tupang' },
  { label: 'Tupang', value: 'Tupang' },
  { label: 'Tuparendi', value: 'Tuparendi' },
  { label: 'Tuparetama', value: 'Tuparetama' },
  { label: 'Tupãssi', value: 'Tupãssi' },
  { label: 'Tupataro', value: 'Tupataro' },
  { label: 'Tupátaro', value: 'Tupátaro' },
  { label: 'Tupelo', value: 'Tupelo' },
  { label: 'Tupesy', value: 'Tupesy' },
  { label: 'Tupi', value: 'Tupi' },
  { label: 'Tupi', value: 'Tupi' },
  { label: 'Tupi Paulista', value: 'Tupi Paulista' },
  { label: 'Tupilaţi', value: 'Tupilaţi' },
  { label: 'Tupirama', value: 'Tupirama' },
  { label: 'Tupiratins', value: 'Tupiratins' },
  { label: 'Tupiza', value: 'Tupiza' },
  { label: 'Tuplice', value: 'Tuplice' },
  { label: 'Tupper Lake', value: 'Tupper Lake' },
  { label: 'Tupsan', value: 'Tupsan' },
  { label: 'Túquerres', value: 'Túquerres' },
  { label: 'Tur', value: 'Tur' },
  { label: 'Tur Al Bahah', value: 'Tur Al Bahah' },
  { label: 'Tura', value: 'Tura' },
  { label: 'Tura', value: 'Tura' },
  { label: 'Tura', value: 'Tura' },
  { label: 'Tura', value: 'Tura' },
  { label: 'Tura Beach', value: 'Tura Beach' },
  { label: 'Turabah', value: 'Turabah' },
  { label: 'Turaif', value: 'Turaif' },
  { label: 'Turaiyur', value: 'Turaiyur' },
  { label: 'Turan', value: 'Turan' },
  { label: 'Turangi', value: 'Turangi' },
  { label: 'Turania', value: 'Turania' },
  { label: 'Turanj', value: 'Turanj' },
  { label: 'Turano Lodigiano', value: 'Turano Lodigiano' },
  { label: 'Turar Ryskulov', value: 'Turar Ryskulov' },
  { label: 'Turate', value: 'Turate' },
  { label: 'Turaw', value: 'Turaw' },
  { label: 'Turba', value: 'Turba' },
  { label: 'Turbaco', value: 'Turbaco' },
  { label: 'Turbaná', value: 'Turbaná' },
  { label: 'Turbat', value: 'Turbat' },
  { label: 'Turbe', value: 'Turbe' },
  { label: 'Turbenthal', value: 'Turbenthal' },
  { label: 'Turbigo', value: 'Turbigo' },
  { label: 'Turbiv', value: 'Turbiv' },
  { label: 'Turbo', value: 'Turbo' },
  { label: 'Turburea', value: 'Turburea' },
  { label: 'Turceni', value: 'Turceni' },
  { label: 'Turcești', value: 'Turcești' },
  { label: 'Turcia', value: 'Turcia' },
  { label: 'Turčianske Teplice', value: 'Turčianske Teplice' },
  { label: 'Turcifal', value: 'Turcifal' },
  { label: 'Turcineşti', value: 'Turcineşti' },
  { label: 'Turcio Segunda Sección', value: 'Turcio Segunda Sección' },
  { label: 'Turckheim', value: 'Turckheim' },
  { label: 'Turcoaia', value: 'Turcoaia' },
  { label: 'Turda', value: 'Turda' },
  { label: 'Turda', value: 'Turda' },
  { label: 'Turdaş', value: 'Turdaş' },
  { label: 'Tureac', value: 'Tureac' },
  { label: 'Turégano', value: 'Turégano' },
  { label: 'Tureh', value: 'Tureh' },
  { label: 'Turek', value: 'Turek' },
  { label: 'Tureni', value: 'Tureni' },
  { label: 'Turets-Bayary', value: 'Turets-Bayary' },
  { label: 'Turgen', value: 'Turgen' },
  { label: 'Turgenevka', value: 'Turgenevka' },
  { label: 'Turgenevo', value: 'Turgenevo' },
  { label: 'Turgi', value: 'Turgi' },
  { label: 'Turgoyak', value: 'Turgoyak' },
  { label: 'Turgutlu', value: 'Turgutlu' },
  { label: 'Turhal', value: 'Turhal' },
  { label: 'Turi', value: 'Turi' },
  { label: 'Türi', value: 'Türi' },
  { label: 'Türi Vald', value: 'Türi Vald' },
  { label: 'Turia', value: 'Turia' },
  { label: 'Turiaçu', value: 'Turiaçu' },
  { label: 'Turicato', value: 'Turicato' },
  { label: 'Turícuaro', value: 'Turícuaro' },
  { label: 'Turija', value: 'Turija' },
  { label: 'Turilândia', value: 'Turilândia' },
  { label: 'Turin', value: 'Turin' },
  { label: 'Turinsk', value: 'Turinsk' },
  { label: 'Turinskaya Sloboda', value: 'Turinskaya Sloboda' },
  { label: 'Turís', value: 'Turís' },
  { label: 'Turiscai', value: 'Turiscai' },
  { label: 'Turiúba', value: 'Turiúba' },
  { label: 'Turjaci', value: 'Turjaci' },
  { label: 'Türje', value: 'Türje' },
  { label: 'Turka', value: 'Turka' },
  { label: 'Turka', value: 'Turka' },
  { label: 'Türkan', value: 'Türkan' },
  { label: 'Türkeli', value: 'Türkeli' },
  { label: 'Türkenfeld', value: 'Türkenfeld' },
  { label: 'Turkestan', value: 'Turkestan' },
  { label: 'Túrkeve', value: 'Túrkeve' },
  { label: 'Türkheim', value: 'Türkheim' },
  { label: 'Turki', value: 'Turki' },
  { label: 'Turkman Chay', value: 'Turkman Chay' },
  { label: 'Türkmenabat', value: 'Türkmenabat' },
  { label: 'Türkmenbaşy', value: 'Türkmenbaşy' },
  { label: 'Türkoğlu', value: 'Türkoğlu' },
  { label: 'Turku', value: 'Turku' },
  { label: 'Turleque', value: 'Turleque' },
  { label: 'Turley', value: 'Turley' },
  { label: 'Turlock', value: 'Turlock' },
  { label: 'Turluianu', value: 'Turluianu' },
  { label: 'Turmalina', value: 'Turmalina' },
  { label: 'Turmalina', value: 'Turmalina' },
  { label: 'Turmequé', value: 'Turmequé' },
  { label: 'Turmero', value: 'Turmero' },
  { label: 'Turmi', value: 'Turmi' },
  { label: 'Turnau', value: 'Turnau' },
  { label: 'Turner', value: 'Turner' },
  { label: 'Turner', value: 'Turner' },
  { label: 'Turner', value: 'Turner' },
  { label: 'Turner County', value: 'Turner County' },
  { label: 'Turner County', value: 'Turner County' },
  { label: 'Turners', value: 'Turners' },
  { label: 'Turners Beach', value: 'Turners Beach' },
  { label: 'Turners Falls', value: 'Turners Falls' },
  { label: 'Turnersville', value: 'Turnersville' },
  { label: 'Turnhout', value: 'Turnhout' },
  { label: 'Türnich', value: 'Türnich' },
  { label: 'Turnišče', value: 'Turnišče' },
  { label: 'Türnitz', value: 'Türnitz' },
  { label: 'Turnov', value: 'Turnov' },
  { label: 'Turnu', value: 'Turnu' },
  { label: 'Turnu Măgurele', value: 'Turnu Măgurele' },
  { label: 'Turnu Roşu', value: 'Turnu Roşu' },
  { label: 'Turnu Ruieni', value: 'Turnu Ruieni' },
  { label: 'Turobin', value: 'Turobin' },
  { label: 'Turochak', value: 'Turochak' },
  { label: 'Turón', value: 'Turón' },
  { label: 'Turośń Kościelna', value: 'Turośń Kościelna' },
  { label: 'Tuross Head', value: 'Tuross Head' },
  { label: 'Turpan', value: 'Turpan' },
  { label: 'Turpan Diqu', value: 'Turpan Diqu' },
  { label: 'Turpin Hills', value: 'Turpin Hills' },
  { label: 'Turquel', value: 'Turquel' },
  { label: 'Turramurra', value: 'Turramurra' },
  { label: 'Turre', value: 'Turre' },
  { label: 'Turrella', value: 'Turrella' },
  { label: 'Turretot', value: 'Turretot' },
  { label: 'Turri', value: 'Turri' },
  { label: 'Turriaco', value: 'Turriaco' },
  { label: 'Turrialba', value: 'Turrialba' },
  { label: 'Turriff', value: 'Turriff' },
  { label: 'Turrillas', value: 'Turrillas' },
  { label: 'Turrivalignani', value: 'Turrivalignani' },
  { label: 'Turrubares', value: 'Turrubares' },
  { label: 'Tursi', value: 'Tursi' },
  { label: 'Tursunzoda', value: 'Tursunzoda' },
  { label: 'Turt', value: 'Turt' },
  { label: 'Turţ', value: 'Turţ' },
  { label: 'Turtas', value: 'Turtas' },
  { label: 'Turtle Creek', value: 'Turtle Creek' },
  { label: 'Turtle Islands', value: 'Turtle Islands' },
  { label: 'Turtle Lake', value: 'Turtle Lake' },
  { label: 'Turtmann', value: 'Turtmann' },
  { label: 'Turuachi', value: 'Turuachi' },
  { label: 'Turuçu', value: 'Turuçu' },
  { label: 'Turukhansk', value: 'Turukhansk' },
  { label: 'Turulung', value: 'Turulung' },
  { label: 'Turuntayevo', value: 'Turuntayevo' },
  { label: 'Tururu', value: 'Tururu' },
  { label: 'Turuvekere', value: 'Turuvekere' },
  { label: 'Turvânia', value: 'Turvânia' },
  { label: 'Turvelândia', value: 'Turvelândia' },
  { label: 'Turvey Park', value: 'Turvey Park' },
  { label: 'Turvo', value: 'Turvo' },
  { label: 'Turvo', value: 'Turvo' },
  { label: 'Turvolândia', value: 'Turvolândia' },
  { label: 'Turza Śląska', value: 'Turza Śląska' },
  { label: 'Turze Pole', value: 'Turze Pole' },
  { label: 'Turzovka', value: 'Turzovka' },
  { label: 'Tusa', value: 'Tusa' },
  { label: 'Tuşba', value: 'Tuşba' },
  { label: 'Tuscaloosa', value: 'Tuscaloosa' },
  { label: 'Tuscaloosa County', value: 'Tuscaloosa County' },
  { label: 'Tuscania', value: 'Tuscania' },
  { label: 'Tuscarawas', value: 'Tuscarawas' },
  { label: 'Tuscarawas County', value: 'Tuscarawas County' },
  { label: 'Tuscola', value: 'Tuscola' },
  { label: 'Tuscola County', value: 'Tuscola County' },
  { label: 'Tusculum', value: 'Tusculum' },
  { label: 'Tuscumbia', value: 'Tuscumbia' },
  { label: 'Tuscumbia', value: 'Tuscumbia' },
  { label: 'Tușinu', value: 'Tușinu' },
  { label: 'Tusk-E Sofla', value: 'Tusk-E Sofla' },
  { label: 'Tuskegee', value: 'Tuskegee' },
  { label: 'Tusmore', value: 'Tusmore' },
  { label: 'Tuşnad', value: 'Tuşnad' },
  { label: 'Tușnadu Nou', value: 'Tușnadu Nou' },
  { label: 'Tussenhausen', value: 'Tussenhausen' },
  { label: 'Tüßling', value: 'Tüßling' },
  { label: 'Tustin', value: 'Tustin' },
  { label: 'Tuszyn', value: 'Tuszyn' },
  { label: 'Tut', value: 'Tut' },
  { label: 'Tuta', value: 'Tuta' },
  { label: 'Tuta', value: 'Tuta' },
  { label: 'Tutak', value: 'Tutak' },
  { label: 'Tutal', value: 'Tutal' },
  { label: 'Tutana', value: 'Tutana' },
  { label: 'Tutay', value: 'Tutay' },
  { label: 'Tutayev', value: 'Tutayev' },
  { label: 'Tutayevskiy Rayon', value: 'Tutayevskiy Rayon' },
  { label: 'Tutazá', value: 'Tutazá' },
  { label: 'Tutepec', value: 'Tutepec' },
  { label: 'Tutin', value: 'Tutin' },
  { label: 'Tutkabon', value: 'Tutkabon' },
  { label: 'Tutóia', value: 'Tutóia' },
  { label: 'Tutong', value: 'Tutong' },
  { label: 'Ţuţora', value: 'Ţuţora' },
  { label: 'Tutova', value: 'Tutova' },
  { label: 'Tutow', value: 'Tutow' },
  { label: 'Tutrakan', value: 'Tutrakan' },
  { label: 'Tüttendorf', value: 'Tüttendorf' },
  { label: 'Tuttle', value: 'Tuttle' },
  { label: 'Tuttlingen', value: 'Tuttlingen' },
  { label: 'Tutu', value: 'Tutu' },
  { label: 'Tutuala', value: 'Tutuala' },
  { label: 'Tutubigan', value: 'Tutubigan' },
  { label: 'Tutubigan', value: 'Tutubigan' },
  { label: 'Tuturano', value: 'Tuturano' },
  { label: 'Tutwiler', value: 'Tutwiler' },
  { label: 'Tutzing', value: 'Tutzing' },
  { label: 'Tuulos', value: 'Tuulos' },
  { label: 'Tuupovaara', value: 'Tuupovaara' },
  { label: 'Tuusula', value: 'Tuusula' },
  { label: 'Tux', value: 'Tux' },
  { label: 'Tuxcacuesco', value: 'Tuxcacuesco' },
  { label: 'Tuxcueca', value: 'Tuxcueca' },
  { label: 'Tuxford', value: 'Tuxford' },
  { label: 'Túxpam De Rodríguez Cano', value: 'Túxpam De Rodríguez Cano' },
  { label: 'Tuxpan', value: 'Tuxpan' },
  { label: 'Tuxpan', value: 'Tuxpan' },
  { label: 'Tuxpan', value: 'Tuxpan' },
  { label: 'Tuxpan', value: 'Tuxpan' },
  { label: 'Tuxpan De Bolaños', value: 'Tuxpan De Bolaños' },
  { label: 'Tuxpanguillo', value: 'Tuxpanguillo' },
  { label: 'Tuxtepec', value: 'Tuxtepec' },
  { label: 'Tuxtilla', value: 'Tuxtilla' },
  { label: 'Tuxtla', value: 'Tuxtla' },
  { label: 'Tuxtla', value: 'Tuxtla' },
  { label: 'Tuxtla Chico', value: 'Tuxtla Chico' },
  { label: 'Tuy', value: 'Tuy' },
  { label: 'Tuy Hòa', value: 'Tuy Hòa' },
  { label: 'Tuyan', value: 'Tuyan' },
  { label: 'Tuyan', value: 'Tuyan' },
  { label: 'Tuyên Quang', value: 'Tuyên Quang' },
  { label: 'Tuymazy', value: 'Tuymazy' },
  { label: 'Tuyserkan', value: 'Tuyserkan' },
  { label: 'Tŭytepa', value: 'Tŭytepa' },
  { label: 'Tuyum', value: 'Tuyum' },
  { label: 'Tuzamapan', value: 'Tuzamapan' },
  { label: 'Tuzamapan', value: 'Tuzamapan' },
  { label: 'Tuzantán', value: 'Tuzantán' },
  { label: 'Tuzantla', value: 'Tuzantla' },
  { label: 'Tuzha', value: 'Tuzha' },
  { label: 'Tuzhai', value: 'Tuzhai' },
  { label: 'Tuzi', value: 'Tuzi' },
  { label: 'Tuzla', value: 'Tuzla' },
  { label: 'Tuzla', value: 'Tuzla' },
  { label: 'Tuzla', value: 'Tuzla' },
  { label: 'Tuzluca', value: 'Tuzluca' },
  { label: 'Tuzlukçu', value: 'Tuzlukçu' },
  { label: 'Tužno', value: 'Tužno' },
  { label: 'Tuzsér', value: 'Tuzsér' },
  { label: 'Tuzuapan', value: 'Tuzuapan' },
  { label: 'Tvååker', value: 'Tvååker' },
  { label: 'Tvardița', value: 'Tvardița' },
  { label: 'Tvarditsa', value: 'Tvarditsa' },
  { label: 'Tvarožná', value: 'Tvarožná' },
  { label: 'Tveit', value: 'Tveit' },
  { label: 'Tver', value: 'Tver' },
  { label: 'Tvøroyri', value: 'Tvøroyri' },
  { label: 'Tvrdonice', value: 'Tvrdonice' },
  { label: 'Tvrdošín', value: 'Tvrdošín' },
  { label: 'Twain Harte', value: 'Twain Harte' },
  { label: 'Twante', value: 'Twante' },
  { label: 'Twardawa', value: 'Twardawa' },
  { label: 'Twardogóra', value: 'Twardogóra' },
  { label: 'Twechar', value: 'Twechar' },
  { label: 'Tweed', value: 'Tweed' },
  { label: 'Tweed', value: 'Tweed' },
  { label: 'Tweed Heads', value: 'Tweed Heads' },
  { label: 'Tweed Heads South', value: 'Tweed Heads South' },
  { label: 'Tweed Heads West', value: 'Tweed Heads West' },
  { label: 'Tweedbank', value: 'Tweedbank' },
  { label: 'Tweede Exloërmond', value: 'Tweede Exloërmond' },
  { label: 'Twekkelerveld', value: 'Twekkelerveld' },
  { label: 'Twello', value: 'Twello' },
  { label: 'Tweng', value: 'Tweng' },
  { label: 'Twentynine Palms', value: 'Twentynine Palms' },
  { label: 'Twiggs County', value: 'Twiggs County' },
  { label: 'Twijzel', value: 'Twijzel' },
  { label: 'Twijzelerheide', value: 'Twijzelerheide' },
  { label: 'Twin City', value: 'Twin City' },
  { label: 'Twin Falls', value: 'Twin Falls' },
  { label: 'Twin Falls County', value: 'Twin Falls County' },
  { label: 'Twin Grove', value: 'Twin Grove' },
  { label: 'Twin Lake', value: 'Twin Lake' },
  { label: 'Twin Lakes', value: 'Twin Lakes' },
  { label: 'Twin Lakes', value: 'Twin Lakes' },
  { label: 'Twin Lakes', value: 'Twin Lakes' },
  { label: 'Twin Lakes', value: 'Twin Lakes' },
  { label: 'Twin Lakes', value: 'Twin Lakes' },
  { label: 'Twin Lakes', value: 'Twin Lakes' },
  { label: 'Twin Rivers', value: 'Twin Rivers' },
  { label: 'Twin Waters', value: 'Twin Waters' },
  { label: 'Twinsburg', value: 'Twinsburg' },
  { label: 'Twisk', value: 'Twisk' },
  { label: 'Twistringen', value: 'Twistringen' },
  { label: 'Two Harbors', value: 'Two Harbors' },
  { label: 'Two Hills', value: 'Two Hills' },
  { label: 'Two Rivers', value: 'Two Rivers' },
  { label: 'Two Rocks', value: 'Two Rocks' },
  { label: 'Two Wells', value: 'Two Wells' },
  { label: 'Twon-Brass', value: 'Twon-Brass' },
  { label: 'Tworków', value: 'Tworków' },
  { label: 'Tworóg', value: 'Tworóg' },
  { label: 'Twyford', value: 'Twyford' },
  { label: 'Tyabb', value: 'Tyabb' },
  { label: 'Tyachiv', value: 'Tyachiv' },
  { label: 'Tyarlevo', value: 'Tyarlevo' },
  { label: 'Tyazhinskiy', value: 'Tyazhinskiy' },
  { label: 'Tybee Island', value: 'Tybee Island' },
  { label: 'Tycheró', value: 'Tycheró' },
  { label: 'Tychowo', value: 'Tychowo' },
  { label: 'Tychy', value: 'Tychy' },
  { label: 'Tycroes', value: 'Tycroes' },
  { label: 'Tyczyn', value: 'Tyczyn' },
  { label: 'Tydal', value: 'Tydal' },
  { label: 'Tye', value: 'Tye' },
  { label: 'Tygda', value: 'Tygda' },
  { label: 'Tygelsjö', value: 'Tygelsjö' },
  { label: 'Tyhee', value: 'Tyhee' },
  { label: 'Tykocin', value: 'Tykocin' },
  { label: 'Tyldesley', value: 'Tyldesley' },
  { label: 'Tyler', value: 'Tyler' },
  { label: 'Tyler', value: 'Tyler' },
  { label: 'Tyler County', value: 'Tyler County' },
  { label: 'Tyler County', value: 'Tyler County' },
  { label: 'Tylertown', value: 'Tylertown' },
  { label: 'Tylicz', value: 'Tylicz' },
  { label: 'Tymbark', value: 'Tymbark' },
  { label: 'Tymoshivka', value: 'Tymoshivka' },
  { label: 'Tymovskoye', value: 'Tymovskoye' },
  { label: 'Tympáki', value: 'Tympáki' },
  { label: 'Týn Nad Vltavou', value: 'Týn Nad Vltavou' },
  { label: 'Tynaarlo', value: 'Tynaarlo' },
  { label: 'Tynda', value: 'Tynda' },
  { label: 'Tyndall', value: 'Tyndall' },
  { label: 'Tyndall Air Force Base', value: 'Tyndall Air Force Base' },
  { label: 'Tyndinskiy Rayon', value: 'Tyndinskiy Rayon' },
  { label: 'Týnec', value: 'Týnec' },
  { label: 'Týnec Nad Labem', value: 'Týnec Nad Labem' },
  { label: 'Týnec Nad Sázavou', value: 'Týnec Nad Sázavou' },
  { label: 'Tynemouth', value: 'Tynemouth' },
  { label: 'Tyngsboro', value: 'Tyngsboro' },
  { label: 'Tyniec Mały', value: 'Tyniec Mały' },
  { label: 'Týniště Nad Orlicí', value: 'Týniště Nad Orlicí' },
  { label: 'Tynne', value: 'Tynne' },
  { label: 'Tynset', value: 'Tynset' },
  { label: 'Tyoply Stan', value: 'Tyoply Stan' },
  { label: 'Tyrawa Wołoska', value: 'Tyrawa Wołoska' },
  { label: 'Tyre', value: 'Tyre' },
  { label: 'Tyret’ Pervaya', value: 'Tyret’ Pervaya' },
  { label: 'Tyringe', value: 'Tyringe' },
  { label: 'Tyrlaching', value: 'Tyrlaching' },
  { label: 'Tyrma', value: 'Tyrma' },
  { label: 'Tyrnävä', value: 'Tyrnävä' },
  { label: 'Tyrnyauz', value: 'Tyrnyauz' },
  { label: 'Tyro', value: 'Tyro' },
  { label: 'Tyrone', value: 'Tyrone' },
  { label: 'Tyrone', value: 'Tyrone' },
  { label: 'Tyrrell County', value: 'Tyrrell County' },
  { label: 'Tysfjord', value: 'Tysfjord' },
  { label: 'Tyshkivka', value: 'Tyshkivka' },
  { label: 'Tysmenychany', value: 'Tysmenychany' },
  { label: 'Tysmenyts’Kyy Rayon', value: 'Tysmenyts’Kyy Rayon' },
  { label: 'Tysmenytsya', value: 'Tysmenytsya' },
  { label: 'Tysnes', value: 'Tysnes' },
  { label: 'Tysons Corner', value: 'Tysons Corner' },
  { label: 'Tysvær', value: 'Tysvær' },
  { label: 'Tyszowce', value: 'Tyszowce' },
  { label: 'Tytherington', value: 'Tytherington' },
  { label: 'Tytsjerk', value: 'Tytsjerk' },
  { label: 'Tytuvėnėliai', value: 'Tytuvėnėliai' },
  { label: 'Tyube', value: 'Tyube' },
  { label: 'Tyubuk', value: 'Tyubuk' },
  { label: 'Tyukalinsk', value: 'Tyukalinsk' },
  { label: 'Tyukhtet', value: 'Tyukhtet' },
  { label: 'Tyukod', value: 'Tyukod' },
  { label: 'Tyul’Gan', value: 'Tyul’Gan' },
  { label: 'Tyul’Kubas', value: 'Tyul’Kubas' },
  { label: 'Tyulyachi', value: 'Tyulyachi' },
  { label: 'Tyulyachinskiy Rayon', value: 'Tyulyachinskiy Rayon' },
  { label: 'Tyumen', value: 'Tyumen' },
  { label: 'Tyumentsevo', value: 'Tyumentsevo' },
  { label: 'Tyumentsevskiy Rayon', value: 'Tyumentsevskiy Rayon' },
  { label: 'Tyup', value: 'Tyup' },
  { label: 'Tyvriv', value: 'Tyvriv' },
  { label: 'Tywyn', value: 'Tywyn' },
  { label: 'Tzajalá', value: 'Tzajalá' },
  { label: 'Tzajalchén', value: 'Tzajalchén' },
  { label: 'Tzaneen', value: 'Tzaneen' },
  { label: 'Tzaquiviljok', value: 'Tzaquiviljok' },
  { label: 'Tzeltal', value: 'Tzeltal' },
  { label: 'Tzicatlacoyan', value: 'Tzicatlacoyan' },
  { label: 'Tzicatlán', value: 'Tzicatlán' },
  { label: 'Tzicatlán', value: 'Tzicatlán' },
  { label: 'Tzimol', value: 'Tzimol' },
  { label: 'Tzinacapan', value: 'Tzinacapan' },
  { label: 'Tzinil', value: 'Tzinil' },
  { label: 'Tzintzimeo', value: 'Tzintzimeo' },
  { label: 'Tzintzingareo', value: 'Tzintzingareo' },
  { label: 'Tzintzuntzan', value: 'Tzintzuntzan' },
  { label: 'Tziritzícuaro', value: 'Tziritzícuaro' },
  { label: 'Tziscao', value: 'Tziscao' },
  { label: 'Tzitzio', value: 'Tzitzio' },
  { label: 'Tzoeptic', value: 'Tzoeptic' },
  { label: 'Tzompantepec', value: 'Tzompantepec' },
  { label: 'Tzontehuitz', value: 'Tzontehuitz' },
  { label: 'Tzopilja', value: 'Tzopilja' },
  { label: 'Tzucacab', value: 'Tzucacab' },
  { label: 'Tzummarum', value: 'Tzummarum' },
  { label: 'U Municipality', value: 'U Municipality' },
  { label: 'Uaboe', value: 'Uaboe' },
  { label: 'Uacon', value: 'Uacon' },
  { label: 'Uacon', value: 'Uacon' },
  { label: 'Uacu Cungo', value: 'Uacu Cungo' },
  { label: 'Ualog', value: 'Ualog' },
  { label: 'Uarini', value: 'Uarini' },
  { label: 'Uatocarabau', value: 'Uatocarabau' },
  { label: 'Uatolari', value: 'Uatolari' },
  { label: 'Uauá', value: 'Uauá' },
  { label: 'Uayalceh De Peón', value: 'Uayalceh De Peón' },
  { label: 'Uayma', value: 'Uayma' },
  { label: 'Uba', value: 'Uba' },
  { label: 'Ubá', value: 'Ubá' },
  { label: 'Übach-Palenberg', value: 'Übach-Palenberg' },
  { label: 'Ubachsberg', value: 'Ubachsberg' },
  { label: 'Ubaí', value: 'Ubaí' },
  { label: 'Ubaíra', value: 'Ubaíra' },
  { label: 'Ubaitaba', value: 'Ubaitaba' },
  { label: 'Ubajara', value: 'Ubajara' },
  { label: 'Ubajay', value: 'Ubajay' },
  { label: 'Ubalá', value: 'Ubalá' },
  { label: 'Ubaporanga', value: 'Ubaporanga' },
  { label: 'Ubaque', value: 'Ubaque' },
  { label: 'Ubarana', value: 'Ubarana' },
  { label: 'Ubari', value: 'Ubari' },
  { label: 'Ubatã', value: 'Ubatã' },
  { label: 'Ubatuba', value: 'Ubatuba' },
  { label: 'Ubauro', value: 'Ubauro' },
  { label: 'Ubay', value: 'Ubay' },
  { label: 'Ube', value: 'Ube' },
  { label: 'Úbeda', value: 'Úbeda' },
  { label: 'Übelbach', value: 'Übelbach' },
  { label: 'Uberaba', value: 'Uberaba' },
  { label: 'Uberach', value: 'Uberach' },
  { label: 'Überherrn', value: 'Überherrn' },
  { label: 'Uberlândia', value: 'Uberlândia' },
  { label: 'Überlingen', value: 'Überlingen' },
  { label: 'Übersaxen', value: 'Übersaxen' },
  { label: 'Übersbach', value: 'Übersbach' },
  { label: 'Übersee', value: 'Übersee' },
  { label: 'Ubiaja', value: 'Ubiaja' },
  { label: 'Ubiale', value: 'Ubiale' },
  { label: 'Ubiale Clanezzo', value: 'Ubiale Clanezzo' },
  { label: 'Ubide', value: 'Ubide' },
  { label: 'Ubilio García', value: 'Ubilio García' },
  { label: 'Ubinskoye', value: 'Ubinskoye' },
  { label: 'Ubirajara', value: 'Ubirajara' },
  { label: 'Ubiratã', value: 'Ubiratã' },
  { label: 'Ubiretama', value: 'Ubiretama' },
  { label: 'Uboldo', value: 'Uboldo' },
  { label: 'Ubon Ratchathani', value: 'Ubon Ratchathani' },
  { label: 'Ubrique', value: 'Ubrique' },
  { label: 'Ubstadt-Weiher', value: 'Ubstadt-Weiher' },
  { label: 'Ubud', value: 'Ubud' },
  { label: 'Ubungo', value: 'Ubungo' },
  { label: 'Ucacha', value: 'Ucacha' },
  { label: 'Ucar', value: 'Ucar' },
  { label: 'Ucareo', value: 'Ucareo' },
  { label: 'Ucea De Jos', value: 'Ucea De Jos' },
  { label: 'Ucea De Sus', value: 'Ucea De Sus' },
  { label: 'Uceda', value: 'Uceda' },
  { label: 'Ucero', value: 'Ucero' },
  { label: 'Uchalan', value: 'Uchalan' },
  { label: 'Uchaly', value: 'Uchaly' },
  { label: 'Uchaud', value: 'Uchaud' },
  { label: 'Uchaux', value: 'Uchaux' },
  { label: 'Uchiza', value: 'Uchiza' },
  { label: 'Uchkeken', value: 'Uchkeken' },
  { label: 'Uchkent', value: 'Uchkent' },
  { label: 'Uchkulan', value: 'Uchkulan' },
  { label: 'Uchoa', value: 'Uchoa' },
  { label: 'Uchqŭrghon Shahri', value: 'Uchqŭrghon Shahri' },
  { label: 'Uchte', value: 'Uchte' },
  { label: 'Üchtelhausen', value: 'Üchtelhausen' },
  { label: 'Uchtspringe', value: 'Uchtspringe' },
  { label: 'Uci', value: 'Uci' },
  { label: 'Uciechów', value: 'Uciechów' },
  { label: 'Uckange', value: 'Uckange' },
  { label: 'Uckfield', value: 'Uckfield' },
  { label: 'Uclés', value: 'Uclés' },
  { label: 'Ucluelet', value: 'Ucluelet' },
  { label: 'Ucon', value: 'Ucon' },
  { label: 'Ucria', value: 'Ucria' },
  { label: 'Ucú', value: 'Ucú' },
  { label: 'Úcua', value: 'Úcua' },
  { label: 'Ucum', value: 'Ucum' },
  { label: 'Uda', value: 'Uda' },
  { label: 'Uda', value: 'Uda' },
  { label: 'Uda Shi', value: 'Uda Shi' },
  { label: 'Udachny', value: 'Udachny' },
  { label: 'Uda-Clocociov', value: 'Uda-Clocociov' },
  { label: 'Udadumbara', value: 'Udadumbara' },
  { label: 'Udaipur', value: 'Udaipur' },
  { label: 'Udaipur', value: 'Udaipur' },
  { label: 'Udaipura', value: 'Udaipura' },
  { label: 'Udalguri', value: 'Udalguri' },
  { label: 'Udangudi', value: 'Udangudi' },
  { label: 'Udanin', value: 'Udanin' },
  { label: 'Uda-Paciurea', value: 'Uda-Paciurea' },
  { label: 'Udapalatha', value: 'Udapalatha' },
  { label: 'Udarnyy', value: 'Udarnyy' },
  { label: 'Udarnyy', value: 'Udarnyy' },
  { label: 'Udayagiri', value: 'Udayagiri' },
  { label: 'Udayapur', value: 'Udayapur' },
  { label: 'Uddel', value: 'Uddel' },
  { label: 'Uddevalla', value: 'Uddevalla' },
  { label: 'Uddiawan', value: 'Uddiawan' },
  { label: 'Uddiawan', value: 'Uddiawan' },
  { label: 'Uddingston', value: 'Uddingston' },
  { label: 'Udel’Naya', value: 'Udel’Naya' },
  { label: 'Udel’Naya', value: 'Udel’Naya' },
  { label: 'Uden', value: 'Uden' },
  { label: 'Udenhausen', value: 'Udenhausen' },
  { label: 'Udenheim', value: 'Udenheim' },
  { label: 'Udenhout', value: 'Udenhout' },
  { label: 'Uder', value: 'Uder' },
  { label: 'Uderns', value: 'Uderns' },
  { label: 'Üdersdorf', value: 'Üdersdorf' },
  { label: 'Udeşti', value: 'Udeşti' },
  { label: 'Udgir', value: 'Udgir' },
  { label: 'Udhailiyah', value: 'Udhailiyah' },
  { label: 'Udham Singh Nagar', value: 'Udham Singh Nagar' },
  { label: 'Udhampur', value: 'Udhampur' },
  { label: 'Udi', value: 'Udi' },
  { label: 'Udías', value: 'Udías' },
  { label: 'Udimskiy', value: 'Udimskiy' },
  { label: 'Udine', value: 'Udine' },
  { label: 'Údlice', value: 'Údlice' },
  { label: 'Udligenswil', value: 'Udligenswil' },
  { label: 'Udobnaya', value: 'Udobnaya' },
  { label: 'Udomlya', value: 'Udomlya' },
  { label: 'Udon Thani', value: 'Udon Thani' },
  { label: 'Udot-Fonuweisom Municipality', value: 'Udot-Fonuweisom Municipality' },
  { label: 'Udpura', value: 'Udpura' },
  { label: 'Ududumbara', value: 'Ududumbara' },
  { label: 'Udumalaippettai', value: 'Udumalaippettai' },
  { label: 'Udunuwara', value: 'Udunuwara' },
  { label: 'Udupi', value: 'Udupi' },
  { label: 'Udupu', value: 'Udupu' },
  { label: 'Ueberstorf', value: 'Ueberstorf' },
  { label: 'Uebigau', value: 'Uebigau' },
  { label: 'Ueckermünde', value: 'Ueckermünde' },
  { label: 'Ueda', value: 'Ueda' },
  { label: 'Ueda', value: 'Ueda' },
  { label: 'Ueda Shi', value: 'Ueda Shi' },
  { label: 'Uedem', value: 'Uedem' },
  { label: 'Uehlfeld', value: 'Uehlfeld' },
  { label: 'Uehrde', value: 'Uehrde' },
  { label: 'Uekimachi-Mōno', value: 'Uekimachi-Mōno' },
  { label: 'Uelsen', value: 'Uelsen' },
  { label: 'Uelversheim', value: 'Uelversheim' },
  { label: 'Uelzen', value: 'Uelzen' },
  { label: 'Uenglingen', value: 'Uenglingen' },
  { label: 'Ueno-Ebisumachi', value: 'Ueno-Ebisumachi' },
  { label: 'Uenohara', value: 'Uenohara' },
  { label: 'Uenohara-Shi', value: 'Uenohara-Shi' },
  { label: 'Uerikon', value: 'Uerikon' },
  { label: 'Uerkheim', value: 'Uerkheim' },
  { label: 'Uetendorf', value: 'Uetendorf' },
  { label: 'Uetersen', value: 'Uetersen' },
  { label: 'Uetikon', value: 'Uetikon' },
  { label: 'Uetikon / Grossdorf', value: 'Uetikon / Grossdorf' },
  { label: 'Uettingen', value: 'Uettingen' },
  { label: 'Uetze', value: 'Uetze' },
  { label: 'Ufa', value: 'Ufa' },
  { label: 'Ufer', value: 'Ufer' },
  { label: 'Uffculme', value: 'Uffculme' },
  { label: 'Uffelte', value: 'Uffelte' },
  { label: 'Uffenheim', value: 'Uffenheim' },
  { label: 'Uffholtz', value: 'Uffholtz' },
  { label: 'Uffing', value: 'Uffing' },
  { label: 'Ufimskiy', value: 'Ufimskiy' },
  { label: 'Uftrungen', value: 'Uftrungen' },
  { label: 'Uga', value: 'Uga' },
  { label: 'Ugac Sur', value: 'Ugac Sur' },
  { label: 'Ugac Sur', value: 'Ugac Sur' },
  { label: 'Ugad', value: 'Ugad' },
  { label: 'Ugad', value: 'Ugad' },
  { label: 'Ugao-Miraballes', value: 'Ugao-Miraballes' },
  { label: 'Ugarchin', value: 'Ugarchin' },
  { label: 'Ugbokpo', value: 'Ugbokpo' },
  { label: 'Ugchelen', value: 'Ugchelen' },
  { label: 'Ugena', value: 'Ugena' },
  { label: 'Ugento', value: 'Ugento' },
  { label: 'Ugep', value: 'Ugep' },
  { label: 'Uggiano La Chiesa', value: 'Uggiano La Chiesa' },
  { label: 'Uggiano Montefusco', value: 'Uggiano Montefusco' },
  { label: 'Uggia-Pazzera-Bizzarrino', value: 'Uggia-Pazzera-Bizzarrino' },
  { label: 'Uggiate Trevano', value: 'Uggiate Trevano' },
  { label: 'Ughelli', value: 'Ughelli' },
  { label: 'Ugíjar', value: 'Ugíjar' },
  { label: 'Ugine', value: 'Ugine' },
  { label: 'Uglegorsk', value: 'Uglegorsk' },
  { label: 'Uglegorskiy', value: 'Uglegorskiy' },
  { label: 'Uglegorskiy Rayon', value: 'Uglegorskiy Rayon' },
  { label: 'Uglekamensk', value: 'Uglekamensk' },
  { label: 'Uglerodovskiy', value: 'Uglerodovskiy' },
  { label: 'Ugleural’Skiy', value: 'Ugleural’Skiy' },
  { label: 'Uglezavodsk', value: 'Uglezavodsk' },
  { label: 'Uglich', value: 'Uglich' },
  { label: 'Uglichskiy Rayon', value: 'Uglichskiy Rayon' },
  { label: 'Ugljan', value: 'Ugljan' },
  { label: 'Ugljevik', value: 'Ugljevik' },
  { label: 'Uglovka', value: 'Uglovka' },
  { label: 'Uglovoye', value: 'Uglovoye' },
  { label: 'Uglovoye', value: 'Uglovoye' },
  { label: 'Uglovskoye', value: 'Uglovskoye' },
  { label: 'Uglyanets', value: 'Uglyanets' },
  { label: 'Ugoofaaru', value: 'Ugoofaaru' },
  { label: 'Ugra', value: 'Ugra' },
  { label: 'Ugrinovci', value: 'Ugrinovci' },
  { label: 'Ugu', value: 'Ugu' },
  { label: 'Ugu District Municipality', value: 'Ugu District Municipality' },
  { label: 'Uğurludağ', value: 'Uğurludağ' },
  { label: 'Ugweno', value: 'Ugweno' },
  { label: 'Uharte-Arakil', value: 'Uharte-Arakil' },
  { label: 'Uherce Mineralne', value: 'Uherce Mineralne' },
  { label: 'Uherské Hradiště', value: 'Uherské Hradiště' },
  { label: 'Uherský Brod', value: 'Uherský Brod' },
  { label: 'Uherský Ostroh', value: 'Uherský Ostroh' },
  { label: 'Uhingen', value: 'Uhingen' },
  { label: 'Uhland', value: 'Uhland' },
  { label: 'Uhldingen-Mühlhofen', value: 'Uhldingen-Mühlhofen' },
  { label: 'Ühlingen-Birkendorf', value: 'Ühlingen-Birkendorf' },
  { label: 'Uhlířské Janovice', value: 'Uhlířské Janovice' },
  { label: 'Uhniv', value: 'Uhniv' },
  { label: 'Uhrichsville', value: 'Uhrichsville' },
  { label: 'Uhryniv', value: 'Uhryniv' },
  { label: 'Uhwiesen', value: 'Uhwiesen' },
  { label: 'Uhyst', value: 'Uhyst' },
  { label: 'Uibaí', value: 'Uibaí' },
  { label: 'Uíbh Fhailí', value: 'Uíbh Fhailí' },
  { label: 'Uichteritz', value: 'Uichteritz' },
  { label: 'Uíge', value: 'Uíge' },
  { label: 'Uijeongbu-Si', value: 'Uijeongbu-Si' },
  { label: 'Ŭiju', value: 'Ŭiju' },
  { label: 'Uila', value: 'Uila' },
  { label: 'Uileacu De Beiuș', value: 'Uileacu De Beiuș' },
  { label: 'Uinskoye', value: 'Uinskoye' },
  { label: 'Uinta County', value: 'Uinta County' },
  { label: 'Uintah', value: 'Uintah' },
  { label: 'Uintah County', value: 'Uintah County' },
  { label: 'Uioara De Jos', value: 'Uioara De Jos' },
  { label: 'Uiramutã', value: 'Uiramutã' },
  { label: 'Uirapuru', value: 'Uirapuru' },
  { label: 'Uiraúna', value: 'Uiraúna' },
  { label: 'Uiryeong-Gun', value: 'Uiryeong-Gun' },
  { label: 'Uiseong-Gun', value: 'Uiseong-Gun' },
  { label: 'Uitdam', value: 'Uitdam' },
  { label: 'Uitenhage', value: 'Uitenhage' },
  { label: 'Uitgeest', value: 'Uitgeest' },
  { label: 'Uithoorn', value: 'Uithoorn' },
  { label: 'Uithuizen', value: 'Uithuizen' },
  { label: 'Uithuizermeeden', value: 'Uithuizermeeden' },
  { label: 'Uitikon', value: 'Uitikon' },
  { label: 'Uivar', value: 'Uivar' },
  { label: 'Uiwang-Si', value: 'Uiwang-Si' },
  { label: 'Ujados', value: 'Ujados' },
  { label: 'Ujar', value: 'Ujar' },
  { label: 'Ujazd', value: 'Ujazd' },
  { label: 'Ujazd', value: 'Ujazd' },
  { label: 'Újezd', value: 'Újezd' },
  { label: 'Újezd', value: 'Újezd' },
  { label: 'Újfehértó', value: 'Újfehértó' },
  { label: 'Ujhani', value: 'Ujhani' },
  { label: 'Újhartyán', value: 'Újhartyán' },
  { label: 'Uji', value: 'Uji' },
  { label: 'Uji Shi', value: 'Uji Shi' },
  { label: 'Ujiie', value: 'Ujiie' },
  { label: 'Ujjain', value: 'Ujjain' },
  { label: 'Újkígyós', value: 'Újkígyós' },
  { label: 'Ujście', value: 'Ujście' },
  { label: 'Ujsoły', value: 'Ujsoły' },
  { label: 'Újszász', value: 'Újszász' },
  { label: 'Újszilvás', value: 'Újszilvás' },
  { label: 'Ujué', value: 'Ujué' },
  { label: 'Uk', value: 'Uk' },
  { label: 'Ukata', value: 'Ukata' },
  { label: 'Ukholovo', value: 'Ukholovo' },
  { label: 'Ukhrul', value: 'Ukhrul' },
  { label: 'Ukhta', value: 'Ukhta' },
  { label: 'Uki Shi', value: 'Uki Shi' },
  { label: 'Ukiah', value: 'Ukiah' },
  { label: 'Ukiha Shi', value: 'Ukiha Shi' },
  { label: 'Uklana', value: 'Uklana' },
  { label: 'Ukmerge', value: 'Ukmerge' },
  { label: 'Ukrainka', value: 'Ukrainka' },
  { label: 'Ukrainka', value: 'Ukrainka' },
  { label: 'Ukromnoye', value: 'Ukromnoye' },
  { label: 'Ukúm', value: 'Ukúm' },
  { label: 'Ukwa', value: 'Ukwa' },
  { label: 'Ul’Yanovka', value: 'Ul’Yanovka' },
  { label: 'Ul’Yanovskiy Rayon', value: 'Ul’Yanovskiy Rayon' },
  { label: 'Ula', value: 'Ula' },
  { label: 'Ula', value: 'Ula' },
  { label: 'Ula', value: 'Ula' },
  { label: 'Ula', value: 'Ula' },
  { label: 'Ula', value: 'Ula' },
  { label: 'Ulà Tirso', value: 'Ulà Tirso' },
  { label: 'Ulaangom', value: 'Ulaangom' },
  { label: 'Ulaanhudag', value: 'Ulaanhudag' },
  { label: 'Ulan Bator', value: 'Ulan Bator' },
  { label: 'Ulanga', value: 'Ulanga' },
  { label: 'Ulanhot', value: 'Ulanhot' },
  { label: 'Ulaniv', value: 'Ulaniv' },
  { label: 'Ulanów', value: 'Ulanów' },
  { label: 'Ulan-Ude', value: 'Ulan-Ude' },
  { label: 'Ulapane', value: 'Ulapane' },
  { label: 'Ulaş', value: 'Ulaş' },
  { label: 'Ulassai', value: 'Ulassai' },
  { label: 'Ulbroka', value: 'Ulbroka' },
  { label: 'Ulceby', value: 'Ulceby' },
  { label: 'Ulcinj', value: 'Ulcinj' },
  { label: 'Ulea', value: 'Ulea' },
  { label: 'Ulefoss', value: 'Ulefoss' },
  { label: 'Uleila Del Campo', value: 'Uleila Del Campo' },
  { label: 'Ülenurme', value: 'Ülenurme' },
  { label: 'Ulestraten', value: 'Ulestraten' },
  { label: 'Uletovskiy Rayon', value: 'Uletovskiy Rayon' },
  { label: 'Ulety', value: 'Ulety' },
  { label: 'Ulëz', value: 'Ulëz' },
  { label: 'Ułęż', value: 'Ułęż' },
  { label: 'Ulfborg', value: 'Ulfborg' },
  { label: 'Ulhasnagar', value: 'Ulhasnagar' },
  { label: 'Ulhówek', value: 'Ulhówek' },
  { label: 'Ulianópolis', value: 'Ulianópolis' },
  { label: 'Uliastay', value: 'Uliastay' },
  { label: 'Ulieș', value: 'Ulieș' },
  { label: 'Ulieşti', value: 'Ulieşti' },
  { label: 'Ulimang', value: 'Ulimang' },
  { label: 'Uling', value: 'Uling' },
  { label: 'Ulithi Municipality', value: 'Ulithi Municipality' },
  { label: 'Uliveto Terme', value: 'Uliveto Terme' },
  { label: 'Uljin-Gun', value: 'Uljin-Gun' },
  { label: 'Uljma', value: 'Uljma' },
  { label: 'Ulju-Gun', value: 'Ulju-Gun' },
  { label: 'Ülken', value: 'Ülken' },
  { label: 'Ullà', value: 'Ullà' },
  { label: 'Ulladulla', value: 'Ulladulla' },
  { label: 'Ullal', value: 'Ullal' },
  { label: 'Ullapool', value: 'Ullapool' },
  { label: 'Ullastrell', value: 'Ullastrell' },
  { label: 'Ullastret', value: 'Ullastret' },
  { label: 'Ullava', value: 'Ullava' },
  { label: 'Ulldecona', value: 'Ulldecona' },
  { label: 'Ulldemolins', value: 'Ulldemolins' },
  { label: 'Ullensaker', value: 'Ullensaker' },
  { label: 'Ullensvang', value: 'Ullensvang' },
  { label: 'Ullerslev', value: 'Ullerslev' },
  { label: 'Üllés', value: 'Üllés' },
  { label: 'Ulleung-Gun', value: 'Ulleung-Gun' },
  { label: 'Üllő', value: 'Üllő' },
  { label: 'Ulloa', value: 'Ulloa' },
  { label: 'Ulluaya', value: 'Ulluaya' },
  { label: 'Ullubiyaul', value: 'Ullubiyaul' },
  { label: 'Ully-Saint-Georges', value: 'Ully-Saint-Georges' },
  { label: 'Ulm', value: 'Ulm' },
  { label: 'Ulma', value: 'Ulma' },
  { label: 'Ulmen', value: 'Ulmen' },
  { label: 'Ulmeni', value: 'Ulmeni' },
  { label: 'Ulmeni', value: 'Ulmeni' },
  { label: 'Ulmeni', value: 'Ulmeni' },
  { label: 'Ulmi', value: 'Ulmi' },
  { label: 'Ulmi', value: 'Ulmi' },
  { label: 'Ulmoasa', value: 'Ulmoasa' },
  { label: 'Ulmu', value: 'Ulmu' },
  { label: 'Ulmu', value: 'Ulmu' },
  { label: 'Ulricehamn', value: 'Ulricehamn' },
  { label: 'Ulrichsberg', value: 'Ulrichsberg' },
  { label: 'Ulrichskirchen', value: 'Ulrichskirchen' },
  { label: 'Ulrichstein', value: 'Ulrichstein' },
  { label: 'Ulrome', value: 'Ulrome' },
  { label: 'Ulrum', value: 'Ulrum' },
  { label: 'Ulsan', value: 'Ulsan' },
  { label: 'Ulstein', value: 'Ulstein' },
  { label: 'Ulsteinvik', value: 'Ulsteinvik' },
  { label: 'Ulsteinvik Weather Pws Station', value: 'Ulsteinvik Weather Pws Station' },
  { label: 'Ulster County', value: 'Ulster County' },
  { label: 'Ulster Spring', value: 'Ulster Spring' },
  { label: 'Ulstrup', value: 'Ulstrup' },
  { label: 'Ultimo', value: 'Ultimo' },
  { label: 'Ultimo - Ulten', value: 'Ultimo - Ulten' },
  { label: 'Ultramort', value: 'Ultramort' },
  { label: 'Ultzama', value: 'Ultzama' },
  { label: 'Ulu Tiram', value: 'Ulu Tiram' },
  { label: 'Uluberia', value: 'Uluberia' },
  { label: 'Ulubey', value: 'Ulubey' },
  { label: 'Ulubey', value: 'Ulubey' },
  { label: 'Uluborlu', value: 'Uluborlu' },
  { label: 'Uludere', value: 'Uludere' },
  { label: 'Ulug‘Nor Tumani', value: 'Ulug‘Nor Tumani' },
  { label: 'Ulukışla', value: 'Ulukışla' },
  { label: 'Ulukulevo', value: 'Ulukulevo' },
  { label: 'Ulundi', value: 'Ulundi' },
  { label: 'Ulus', value: 'Ulus' },
  { label: 'Ulu-Telyak', value: 'Ulu-Telyak' },
  { label: 'Ulvenhout', value: 'Ulvenhout' },
  { label: 'Ulverston', value: 'Ulverston' },
  { label: 'Ulverstone', value: 'Ulverstone' },
  { label: 'Ulvik', value: 'Ulvik' },
  { label: 'Ulvila', value: 'Ulvila' },
  { label: 'Ulyanovsk', value: 'Ulyanovsk' },
  { label: 'Ulyanovskiy Rayon', value: 'Ulyanovskiy Rayon' },
  { label: 'Ulychne', value: 'Ulychne' },
  { label: 'Ulysses', value: 'Ulysses' },
  { label: 'Um Jar Al Gharbiyya', value: 'Um Jar Al Gharbiyya' },
  { label: 'Umabay', value: 'Umabay' },
  { label: 'Umag', value: 'Umag' },
  { label: 'Umaganhan', value: 'Umaganhan' },
  { label: 'Umaganhan', value: 'Umaganhan' },
  { label: 'Umag-Umago', value: 'Umag-Umago' },
  { label: 'Umaji', value: 'Umaji' },
  { label: 'Uman', value: 'Uman' },
  { label: 'Uman', value: 'Uman' },
  { label: 'Umanday Centro', value: 'Umanday Centro' },
  { label: 'Umanday Centro', value: 'Umanday Centro' },
  { label: 'Uman-Fonuweisom Municipality', value: 'Uman-Fonuweisom Municipality' },
  { label: 'Umarga', value: 'Umarga' },
  { label: 'Umari', value: 'Umari' },
  { label: 'Umaria', value: 'Umaria' },
  { label: 'Umarizal', value: 'Umarizal' },
  { label: 'Umarkhed', value: 'Umarkhed' },
  { label: 'Umarkot', value: 'Umarkot' },
  { label: 'Umarkot', value: 'Umarkot' },
  { label: 'Umarpada', value: 'Umarpada' },
  { label: 'Umatilla', value: 'Umatilla' },
  { label: 'Umatilla', value: 'Umatilla' },
  { label: 'Umatilla County', value: 'Umatilla County' },
  { label: 'Umba', value: 'Umba' },
  { label: 'Umbaúba', value: 'Umbaúba' },
  { label: 'Umberg', value: 'Umberg' },
  { label: 'Umbertide', value: 'Umbertide' },
  { label: 'Umbita', value: 'Umbita' },
  { label: 'Umbrăreşti', value: 'Umbrăreşti' },
  { label: 'Umbrărești-Deal', value: 'Umbrărești-Deal' },
  { label: 'Umbrete', value: 'Umbrete' },
  { label: 'Umbrías', value: 'Umbrías' },
  { label: 'Umbriatico', value: 'Umbriatico' },
  { label: 'Umburanas', value: 'Umburanas' },
  { label: 'Umburatiba', value: 'Umburatiba' },
  { label: 'Umbuzeiro', value: 'Umbuzeiro' },
  { label: 'Umeå', value: 'Umeå' },
  { label: 'Umerkot', value: 'Umerkot' },
  { label: 'Umerkot District', value: 'Umerkot District' },
  { label: 'Umet', value: 'Umet' },
  { label: 'Umgungundlovu', value: 'Umgungundlovu' },
  { label: 'Umhausen', value: 'Umhausen' },
  { label: 'Umi', value: 'Umi' },
  { label: 'Umina Beach', value: 'Umina Beach' },
  { label: 'Umingan', value: 'Umingan' },
  { label: 'Umingan', value: 'Umingan' },
  { label: 'Umiray', value: 'Umiray' },
  { label: 'Umiray', value: 'Umiray' },
  { label: 'Umirim', value: 'Umirim' },
  { label: 'Umka', value: 'Umka' },
  { label: 'Umkhanyakude District Municipality', value: 'Umkhanyakude District Municipality' },
  { label: 'Umkirch', value: 'Umkirch' },
  { label: 'Umm Al Qiţţayn', value: 'Umm Al Qiţţayn' },
  { label: 'Umm Al Quwain', value: 'Umm Al Quwain' },
  { label: 'Umm Al Quwain City', value: 'Umm Al Quwain City' },
  { label: 'Umm As Sāhik', value: 'Umm As Sāhik' },
  { label: 'Umm As Summāq', value: 'Umm As Summāq' },
  { label: 'Umm Bāb', value: 'Umm Bāb' },
  { label: 'Umm El Faḥm', value: 'Umm El Faḥm' },
  { label: 'Umm Kaddadah', value: 'Umm Kaddadah' },
  { label: 'Umm Lajj', value: 'Umm Lajj' },
  { label: 'Umm Qaşr', value: 'Umm Qaşr' },
  { label: 'Umm Qays', value: 'Umm Qays' },
  { label: 'Umm Radamah', value: 'Umm Radamah' },
  { label: 'Umm Ruwaba', value: 'Umm Ruwaba' },
  { label: 'Umm Şalāl Muḩammad', value: 'Umm Şalāl Muḩammad' },
  { label: 'Ummendorf', value: 'Ummendorf' },
  { label: 'Ummendorf', value: 'Ummendorf' },
  { label: 'Ummern', value: 'Ummern' },
  { label: 'Umrala', value: 'Umrala' },
  { label: 'Ümraniye', value: 'Ümraniye' },
  { label: 'Umred', value: 'Umred' },
  { label: 'Umreth', value: 'Umreth' },
  { label: 'Umri', value: 'Umri' },
  { label: 'Umri', value: 'Umri' },
  { label: 'Umuahia', value: 'Umuahia' },
  { label: 'Umuarama', value: 'Umuarama' },
  { label: 'Umunede', value: 'Umunede' },
  { label: 'Umzingwane District', value: 'Umzingwane District' },
  { label: 'Umzinyathi District Municipality', value: 'Umzinyathi District Municipality' },
  { label: 'Un', value: 'Un' },
  { label: 'Un', value: 'Un' },
  { label: 'Una', value: 'Una' },
  { label: 'Una', value: 'Una' },
  { label: 'Una', value: 'Una' },
  { label: 'Uña', value: 'Uña' },
  { label: 'Uña De Quintana', value: 'Uña De Quintana' },
  { label: 'Unadilla', value: 'Unadilla' },
  { label: 'Unadilla', value: 'Unadilla' },
  { label: 'Unaí', value: 'Unaí' },
  { label: 'Unakoti', value: 'Unakoti' },
  { label: 'Unalaska', value: 'Unalaska' },
  { label: 'Unanderra', value: 'Unanderra' },
  { label: 'Únanov', value: 'Únanov' },
  { label: 'Unanu Municipality', value: 'Unanu Municipality' },
  { label: 'Unawatuna', value: 'Unawatuna' },
  { label: 'Uncastillo', value: 'Uncastillo' },
  { label: 'Uncasville', value: 'Uncasville' },
  { label: 'Unciti', value: 'Unciti' },
  { label: 'Undenheim', value: 'Undenheim' },
  { label: 'Underdale', value: 'Underdale' },
  { label: 'Underwood', value: 'Underwood' },
  { label: 'Underwood-Petersville', value: 'Underwood-Petersville' },
  { label: 'Undory', value: 'Undory' },
  { label: 'Undués De Lerda', value: 'Undués De Lerda' },
  { label: 'Undurkhaan', value: 'Undurkhaan' },
  { label: 'Undy', value: 'Undy' },
  { label: 'Une', value: 'Une' },
  { label: 'Unecha', value: 'Unecha' },
  { label: 'Unechskiy Rayon', value: 'Unechskiy Rayon' },
  { label: 'Ungaran', value: 'Ungaran' },
  { label: 'Ungca', value: 'Ungca' },
  { label: 'Ungenach', value: 'Ungenach' },
  { label: 'Ungerdorf', value: 'Ungerdorf' },
  { label: 'Ungerhausen', value: 'Ungerhausen' },
  { label: 'Ungersheim', value: 'Ungersheim' },
  { label: 'Unggai Bena', value: 'Unggai Bena' },
  { label: 'Ungheni', value: 'Ungheni' },
  { label: 'Ungheni', value: 'Ungheni' },
  { label: 'Ungheni', value: 'Ungheni' },
  { label: 'Ungra', value: 'Ungra' },
  { label: 'Ungsang', value: 'Ungsang' },
  { label: 'Ungsang-Nodongjagu', value: 'Ungsang-Nodongjagu' },
  { label: 'Unguía', value: 'Unguía' },
  { label: 'Unguraş', value: 'Unguraş' },
  { label: 'Unguraș', value: 'Unguraș' },
  { label: 'Ungureni', value: 'Ungureni' },
  { label: 'Ungureni', value: 'Ungureni' },
  { label: 'Ungureni', value: 'Ungureni' },
  { label: 'Ungureni', value: 'Ungureni' },
  { label: 'Ungureni', value: 'Ungureni' },
  { label: 'Ungureni-Jianu', value: 'Ungureni-Jianu' },
  { label: 'Unguriu', value: 'Unguriu' },
  { label: 'Ungus-Ungus', value: 'Ungus-Ungus' },
  { label: 'Ungus-Ungus', value: 'Ungus-Ungus' },
  { label: 'Unhel', value: 'Unhel' },
  { label: 'Unhos', value: 'Unhos' },
  { label: 'Unhos', value: 'Unhos' },
  { label: 'Unhošť', value: 'Unhošť' },
  { label: 'Uni', value: 'Uni' },
  { label: 'União', value: 'União' },
  { label: 'União Da Serra', value: 'União Da Serra' },
  { label: 'União Da Vitória', value: 'União Da Vitória' },
  { label: 'União De Minas', value: 'União De Minas' },
  { label: 'União Do Oeste', value: 'União Do Oeste' },
  { label: 'União Do Sul', value: 'União Do Sul' },
  { label: 'União Dos Palmares', value: 'União Dos Palmares' },
  { label: 'União Paulista', value: 'União Paulista' },
  { label: 'Uniara', value: 'Uniara' },
  { label: 'Unicoi', value: 'Unicoi' },
  { label: 'Unicoi County', value: 'Unicoi County' },
  { label: 'Uničov', value: 'Uničov' },
  { label: 'Unidad', value: 'Unidad' },
  { label: 'Unidad', value: 'Unidad' },
  { label: 'Unidad Acaquilpan', value: 'Unidad Acaquilpan' },
  { label: 'Unidad Grajales Infonavit', value: 'Unidad Grajales Infonavit' },
  { label: 'Unidad Habitacional Antonio Osorio De León (Bojay)', value: 'Unidad Habitacional Antonio Osorio De León (Bojay)' },
  { label: 'Unidad Habitacional José María Morelos Y Pavón', value: 'Unidad Habitacional José María Morelos Y Pavón' },
  { label: 'Unidad Habitacional Mariano Matamoros', value: 'Unidad Habitacional Mariano Matamoros' },
  { label: 'Unidad Habitacional Rinconada Acolapa', value: 'Unidad Habitacional Rinconada Acolapa' },
  { label: 'Unidad Habitacional Santa Teresa', value: 'Unidad Habitacional Santa Teresa' },
  { label: 'Unidad San Miguel Jagüeyes', value: 'Unidad San Miguel Jagüeyes' },
  { label: 'Unidades Habitacionales', value: 'Unidades Habitacionales' },
  { label: 'Unidos', value: 'Unidos' },
  { label: 'Unidos', value: 'Unidos' },
  { label: 'Unidos Avanzamos', value: 'Unidos Avanzamos' },
  { label: 'Uniejów', value: 'Uniejów' },
  { label: 'Unieux', value: 'Unieux' },
  { label: 'Uniflor', value: 'Uniflor' },
  { label: 'Uninskiy Rayon', value: 'Uninskiy Rayon' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Union', value: 'Union' },
  { label: 'Unión', value: 'Unión' },
  { label: 'Unión', value: 'Unión' },
  { label: 'Unión Agropecuarios Lázaro Cárdenas Del Norte', value: 'Unión Agropecuarios Lázaro Cárdenas Del Norte' },
  { label: 'Union Beach', value: 'Union Beach' },
  { label: 'Unión Buena Vista', value: 'Unión Buena Vista' },
  { label: 'Unión Buenavista', value: 'Unión Buenavista' },
  { label: 'Unión Chocó', value: 'Unión Chocó' },
  { label: 'Union City', value: 'Union City' },
  { label: 'Union City', value: 'Union City' },
  { label: 'Union City', value: 'Union City' },
  { label: 'Union City', value: 'Union City' },
  { label: 'Union City', value: 'Union City' },
  { label: 'Union City', value: 'Union City' },
  { label: 'Union City', value: 'Union City' },
  { label: 'Union City', value: 'Union City' },
  { label: 'Union City', value: 'Union City' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Union County', value: 'Union County' },
  { label: 'Unión De Azuero', value: 'Unión De Azuero' },
  { label: 'Unión De Campos La', value: 'Unión De Campos La' },
  { label: 'Unión De Corrientes', value: 'Unión De Corrientes' },
  { label: 'Unión De Reyes', value: 'Unión De Reyes' },
  { label: 'Unión De San Antonio', value: 'Unión De San Antonio' },
  { label: 'Unión De Tula', value: 'Unión De Tula' },
  { label: 'Unión Ejidal Tierra Y Libertad', value: 'Unión Ejidal Tierra Y Libertad' },
  { label: 'Union Gap', value: 'Union Gap' },
  { label: 'Union Grove', value: 'Union Grove' },
  { label: 'Union Hall', value: 'Union Hall' },
  { label: 'Unión Hidalgo', value: 'Unión Hidalgo' },
  { label: 'Union Hill-Novelty Hill', value: 'Union Hill-Novelty Hill' },
  { label: 'Unión Juárez', value: 'Unión Juárez' },
  { label: 'Unión La', value: 'Unión La' },
  { label: 'Unión Panamericana', value: 'Unión Panamericana' },
  { label: 'Union Parish', value: 'Union Parish' },
  { label: 'Union Park', value: 'Union Park' },
  { label: 'Union Park', value: 'Union Park' },
  { label: 'Union Point', value: 'Union Point' },
  { label: 'Unión Roja', value: 'Unión Roja' },
  { label: 'Unión Santeña', value: 'Unión Santeña' },
  { label: 'Union Springs', value: 'Union Springs' },
  { label: 'Union Springs', value: 'Union Springs' },
  { label: 'Union Terrace', value: 'Union Terrace' },
  { label: 'Union Vale', value: 'Union Vale' },
  { label: 'Unión Y Progreso', value: 'Unión Y Progreso' },
  { label: 'Union/Ti Morne', value: 'Union/Ti Morne' },
  { label: 'Uniondale', value: 'Uniondale' },
  { label: 'Unionport', value: 'Unionport' },
  { label: 'Uniontown', value: 'Uniontown' },
  { label: 'Uniontown', value: 'Uniontown' },
  { label: 'Uniontown', value: 'Uniontown' },
  { label: 'Unionville', value: 'Unionville' },
  { label: 'Unionville', value: 'Unionville' },
  { label: 'Unionville', value: 'Unionville' },
  { label: 'Unionville', value: 'Unionville' },
  { label: 'Unirea', value: 'Unirea' },
  { label: 'Unirea', value: 'Unirea' },
  { label: 'Unirea', value: 'Unirea' },
  { label: 'Unirea', value: 'Unirea' },
  { label: 'Unirea', value: 'Unirea' },
  { label: 'Unirea', value: 'Unirea' },
  { label: 'Unisan', value: 'Unisan' },
  { label: 'Unisław', value: 'Unisław' },
  { label: 'Unistalda', value: 'Unistalda' },
  { label: 'Unity', value: 'Unity' },
  { label: 'Unity', value: 'Unity' },
  { label: 'Unity Hall', value: 'Unity Hall' },
  { label: 'Universal City', value: 'Universal City' },
  { label: 'Universal City', value: 'Universal City' },
  { label: 'University', value: 'University' },
  { label: 'University', value: 'University' },
  { label: 'University', value: 'University' },
  { label: 'University Center', value: 'University Center' },
  { label: 'University City', value: 'University City' },
  { label: 'University Gardens', value: 'University Gardens' },
  { label: 'University Heights', value: 'University Heights' },
  { label: 'University Heights', value: 'University Heights' },
  { label: 'University Heights', value: 'University Heights' },
  { label: 'University Park', value: 'University Park' },
  { label: 'University Park', value: 'University Park' },
  { label: 'University Park', value: 'University Park' },
  { label: 'University Park', value: 'University Park' },
  { label: 'University Park', value: 'University Park' },
  { label: 'University Place', value: 'University Place' },
  { label: 'Unjha', value: 'Unjha' },
  { label: 'Unkel', value: 'Unkel' },
  { label: 'Unken', value: 'Unken' },
  { label: 'Unley', value: 'Unley' },
  { label: 'Unley Park', value: 'Unley Park' },
  { label: 'Unlingen', value: 'Unlingen' },
  { label: 'Unna', value: 'Unna' },
  { label: 'Unnan Shi', value: 'Unnan Shi' },
  { label: 'Unnao', value: 'Unnao' },
  { label: 'Unnau', value: 'Unnau' },
  { label: 'Unquillo', value: 'Unquillo' },
  { label: 'Unseburg', value: 'Unseburg' },
  { label: 'Unţeni', value: 'Unţeni' },
  { label: 'Unter-Abtsteinach', value: 'Unter-Abtsteinach' },
  { label: 'Unterägeri', value: 'Unterägeri' },
  { label: 'Unterammergau', value: 'Unterammergau' },
  { label: 'Unterauersbach', value: 'Unterauersbach' },
  { label: 'Unterbergla', value: 'Unterbergla' },
  { label: 'Unterbreizbach', value: 'Unterbreizbach' },
  { label: 'Unterdießen', value: 'Unterdießen' },
  { label: 'Unterdietfurt', value: 'Unterdietfurt' },
  { label: 'Untere Fellach', value: 'Untere Fellach' },
  { label: 'Unteregg', value: 'Unteregg' },
  { label: 'Untereisenfeld', value: 'Untereisenfeld' },
  { label: 'Untereisesheim', value: 'Untereisesheim' },
  { label: 'Unterengstringen', value: 'Unterengstringen' },
  { label: 'Unterensingen', value: 'Unterensingen' },
  { label: 'Unterfladnitz', value: 'Unterfladnitz' },
  { label: 'Unterföhring', value: 'Unterföhring' },
  { label: 'Unterfrauenhaid', value: 'Unterfrauenhaid' },
  { label: 'Untergriesbach', value: 'Untergriesbach' },
  { label: 'Untergruppenbach', value: 'Untergruppenbach' },
  { label: 'Unterhaching', value: 'Unterhaching' },
  { label: 'Unterhausen', value: 'Unterhausen' },
  { label: 'Unteriberg', value: 'Unteriberg' },
  { label: 'Unterjettingen', value: 'Unterjettingen' },
  { label: 'Unterjosefstal', value: 'Unterjosefstal' },
  { label: 'Unterkirnach', value: 'Unterkirnach' },
  { label: 'Unterkohlstätten', value: 'Unterkohlstätten' },
  { label: 'Unterkrozingen', value: 'Unterkrozingen' },
  { label: 'Unterkulm', value: 'Unterkulm' },
  { label: 'Unterlamm', value: 'Unterlamm' },
  { label: 'Unterlangkampfen', value: 'Unterlangkampfen' },
  { label: 'Unterleinleiter', value: 'Unterleinleiter' },
  { label: 'Unterlunkhofen', value: 'Unterlunkhofen' },
  { label: 'Unterlüß', value: 'Unterlüß' },
  { label: 'Untermaßfeld', value: 'Untermaßfeld' },
  { label: 'Untermeitingen', value: 'Untermeitingen' },
  { label: 'Untermerzbach', value: 'Untermerzbach' },
  { label: 'Untermünkheim', value: 'Untermünkheim' },
  { label: 'Unternberg', value: 'Unternberg' },
  { label: 'Unterneukirchen', value: 'Unterneukirchen' },
  { label: 'Unterperfuss', value: 'Unterperfuss' },
  { label: 'Unterpleichfeld', value: 'Unterpleichfeld' },
  { label: 'Unterpremstätten', value: 'Unterpremstätten' },
  { label: 'Unterreichenbach', value: 'Unterreichenbach' },
  { label: 'Unterreit', value: 'Unterreit' },
  { label: 'Unterschleißheim', value: 'Unterschleißheim' },
  { label: 'Unterschneidheim', value: 'Unterschneidheim' },
  { label: 'Unterseen', value: 'Unterseen' },
  { label: 'Untersiebenbrunn', value: 'Untersiebenbrunn' },
  { label: 'Untersiemau', value: 'Untersiemau' },
  { label: 'Untersiggenthal', value: 'Untersiggenthal' },
  { label: 'Untersteinach', value: 'Untersteinach' },
  { label: 'Unterstinkenbrunn', value: 'Unterstinkenbrunn' },
  { label: 'Untertauern', value: 'Untertauern' },
  { label: 'Unterthingau', value: 'Unterthingau' },
  { label: 'Untertilliach', value: 'Untertilliach' },
  { label: 'Untervaz', value: 'Untervaz' },
  { label: 'Unterwagram', value: 'Unterwagram' },
  { label: 'Unterwaltersdorf', value: 'Unterwaltersdorf' },
  { label: 'Unterwart', value: 'Unterwart' },
  { label: 'Unterwellenborn', value: 'Unterwellenborn' },
  { label: 'Unterwössen', value: 'Unterwössen' },
  { label: 'Untolovo', value: 'Untolovo' },
  { label: 'Untrasried', value: 'Untrasried' },
  { label: 'Untsukul’', value: 'Untsukul’' },
  { label: 'Untsukul’Skiy Rayon', value: 'Untsukul’Skiy Rayon' },
  { label: 'Unverre', value: 'Unverre' },
  { label: 'Úny', value: 'Úny' },
  { label: 'Uny Lelant', value: 'Uny Lelant' },
  { label: 'Ünye', value: 'Ünye' },
  { label: 'Unzad', value: 'Unzad' },
  { label: 'Unzad', value: 'Unzad' },
  { label: 'Unzen-Shi', value: 'Unzen-Shi' },
  { label: 'Unzué', value: 'Unzué' },
  { label: 'Unzué/Untzue', value: 'Unzué/Untzue' },
  { label: 'Uonuma', value: 'Uonuma' },
  { label: 'Uonuma Shi', value: 'Uonuma Shi' },
  { label: 'Uozu', value: 'Uozu' },
  { label: 'Uozu Shi', value: 'Uozu Shi' },
  { label: 'Upala', value: 'Upala' },
  { label: 'Upanema', value: 'Upanema' },
  { label: 'Upata', value: 'Upata' },
  { label: 'Upchurch', value: 'Upchurch' },
  { label: 'Upgant-Schott', value: 'Upgant-Schott' },
  { label: 'Upi', value: 'Upi' },
  { label: 'Upi', value: 'Upi' },
  { label: 'Upi', value: 'Upi' },
  { label: 'Upi', value: 'Upi' },
  { label: 'Úpice', value: 'Úpice' },
  { label: 'Upie', value: 'Upie' },
  { label: 'Upig', value: 'Upig' },
  { label: 'Upig', value: 'Upig' },
  { label: 'Upington', value: 'Upington' },
  { label: 'Upland', value: 'Upland' },
  { label: 'Upland', value: 'Upland' },
  { label: 'Upland', value: 'Upland' },
  { label: 'Upleta', value: 'Upleta' },
  { label: 'Upminster', value: 'Upminster' },
  { label: 'Upornaya', value: 'Upornaya' },
  { label: 'Uporovo', value: 'Uporovo' },
  { label: 'Uporovskiy Rayon', value: 'Uporovskiy Rayon' },
  { label: 'Uppal Kalan', value: 'Uppal Kalan' },
  { label: 'Upper Alton', value: 'Upper Alton' },
  { label: 'Upper Arlington', value: 'Upper Arlington' },
  { label: 'Upper Baddibu', value: 'Upper Baddibu' },
  { label: 'Upper Basildon', value: 'Upper Basildon' },
  { label: 'Upper Bavaria', value: 'Upper Bavaria' },
  { label: 'Upper Bear Creek', value: 'Upper Bear Creek' },
  { label: 'Upper Brookville', value: 'Upper Brookville' },
  { label: 'Upper Burnie', value: 'Upper Burnie' },
  { label: 'Upper Caboolture', value: 'Upper Caboolture' },
  { label: 'Upper Coomera', value: 'Upper Coomera' },
  { label: 'Upper Dir', value: 'Upper Dir' },
  { label: 'Upper Franconia', value: 'Upper Franconia' },
  { label: 'Upper Fruitland', value: 'Upper Fruitland' },
  { label: 'Upper Gascoyne', value: 'Upper Gascoyne' },
  { label: 'Upper Grand Lagoon', value: 'Upper Grand Lagoon' },
  { label: 'Upper Hunter Shire', value: 'Upper Hunter Shire' },
  { label: 'Upper Hutt', value: 'Upper Hutt' },
  { label: 'Upper Island Cove', value: 'Upper Island Cove' },
  { label: 'Upper Kedron', value: 'Upper Kedron' },
  { label: 'Upper Klinan', value: 'Upper Klinan' },
  { label: 'Upper Klinan', value: 'Upper Klinan' },
  { label: 'Upper Lachlan Shire', value: 'Upper Lachlan Shire' },
  { label: 'Upper Lake', value: 'Upper Lake' },
  { label: 'Upper Langwith', value: 'Upper Langwith' },
  { label: 'Upper Marlboro', value: 'Upper Marlboro' },
  { label: 'Upper Montclair', value: 'Upper Montclair' },
  { label: 'Upper Mount Gravatt', value: 'Upper Mount Gravatt' },
  { label: 'Upper Niumi District', value: 'Upper Niumi District' },
  { label: 'Upper Nyack', value: 'Upper Nyack' },
  { label: 'Upper Palatinate', value: 'Upper Palatinate' },
  { label: 'Upper Pohatcong', value: 'Upper Pohatcong' },
  { label: 'Upper Poppleton', value: 'Upper Poppleton' },
  { label: 'Upper Saddle River', value: 'Upper Saddle River' },
  { label: 'Upper Saint Clair', value: 'Upper Saint Clair' },
  { label: 'Upper Saloum', value: 'Upper Saloum' },
  { label: 'Upper San Mateo', value: 'Upper San Mateo' },
  { label: 'Upper San Mateo', value: 'Upper San Mateo' },
  { label: 'Upper Sandusky', value: 'Upper Sandusky' },
  { label: 'Upper Siang', value: 'Upper Siang' },
  { label: 'Upper Subansiri', value: 'Upper Subansiri' },
  { label: 'Uppiliyapuram', value: 'Uppiliyapuram' },
  { label: 'Uppingham', value: 'Uppingham' },
  { label: 'Upplands Väsby', value: 'Upplands Väsby' },
  { label: 'Uppsala', value: 'Uppsala' },
  { label: 'Upshur County', value: 'Upshur County' },
  { label: 'Upshur County', value: 'Upshur County' },
  { label: 'Upson County', value: 'Upson County' },
  { label: 'Uptar', value: 'Uptar' },
  { label: 'Upton', value: 'Upton' },
  { label: 'Upton', value: 'Upton' },
  { label: 'Upton', value: 'Upton' },
  { label: 'Upton County', value: 'Upton County' },
  { label: 'Upton Scudamore', value: 'Upton Scudamore' },
  { label: 'Upton Upon Severn', value: 'Upton Upon Severn' },
  { label: 'Uptown', value: 'Uptown' },
  { label: 'Upwell', value: 'Upwell' },
  { label: 'Upwey', value: 'Upwey' },
  { label: 'Uqair', value: 'Uqair' },
  { label: 'Ur District', value: 'Ur District' },
  { label: 'Ura Vajgurore', value: 'Ura Vajgurore' },
  { label: 'Urago Doglio', value: 'Urago Doglio' },
  { label: 'Uraí', value: 'Uraí' },
  { label: 'Ural', value: 'Ural' },
  { label: 'Uralets', value: 'Uralets' },
  { label: 'Uralla', value: 'Uralla' },
  { label: 'Uralo-Kavkaz', value: 'Uralo-Kavkaz' },
  { label: 'Uraman Takht', value: 'Uraman Takht' },
  { label: 'Uramita', value: 'Uramita' },
  { label: 'Uran', value: 'Uran' },
  { label: 'Urandi', value: 'Urandi' },
  { label: 'Urangan', value: 'Urangan' },
  { label: 'Urania', value: 'Urania' },
  { label: 'Urânia', value: 'Urânia' },
  { label: 'Uras', value: 'Uras' },
  { label: 'Urasoe Shi', value: 'Urasoe Shi' },
  { label: 'Uravakonda', value: 'Uravakonda' },
  { label: 'Uray', value: 'Uray' },
  { label: 'Urayasu', value: 'Urayasu' },
  { label: 'Urayasu-Shi', value: 'Urayasu-Shi' },
  { label: 'Urazovka', value: 'Urazovka' },
  { label: 'Urazovo', value: 'Urazovo' },
  { label: 'Urb. Santo Domingo', value: 'Urb. Santo Domingo' },
  { label: 'Urbach', value: 'Urbach' },
  { label: 'Urbach', value: 'Urbach' },
  { label: 'Urbach-Überdorf', value: 'Urbach-Überdorf' },
  { label: 'Urbana', value: 'Urbana' },
  { label: 'Urbana', value: 'Urbana' },
  { label: 'Urbana', value: 'Urbana' },
  { label: 'Urbana', value: 'Urbana' },
  { label: 'Urbana', value: 'Urbana' },
  { label: 'Urbancrest', value: 'Urbancrest' },
  { label: 'Urbandale', value: 'Urbandale' },
  { label: 'Urbania', value: 'Urbania' },
  { label: 'Urbano Santos', value: 'Urbano Santos' },
  { label: 'Urbar', value: 'Urbar' },
  { label: 'Urbe', value: 'Urbe' },
  { label: 'Úrbel Del Castillo', value: 'Úrbel Del Castillo' },
  { label: 'Urbino', value: 'Urbino' },
  { label: 'Urbisaglia', value: 'Urbisaglia' },
  { label: 'Urbiztondo', value: 'Urbiztondo' },
  { label: 'Urbiztondo', value: 'Urbiztondo' },
  { label: 'Urca', value: 'Urca' },
  { label: 'Určice', value: 'Určice' },
  { label: 'Urcos', value: 'Urcos' },
  { label: 'Urcuit', value: 'Urcuit' },
  { label: 'Urda', value: 'Urda' },
  { label: 'Urdaneta', value: 'Urdaneta' },
  { label: 'Urdaneta', value: 'Urdaneta' },
  { label: 'Urdari', value: 'Urdari' },
  { label: 'Urdazubi/Urdax', value: 'Urdazubi/Urdax' },
  { label: 'Urdiain', value: 'Urdiain' },
  { label: 'Urdiales Del Páramo', value: 'Urdiales Del Páramo' },
  { label: 'Urdinarrain', value: 'Urdinarrain' },
  { label: 'Urdoma', value: 'Urdoma' },
  { label: 'Urdorf', value: 'Urdorf' },
  { label: 'Urdorf / Bodenfeld', value: 'Urdorf / Bodenfeld' },
  { label: 'Urdorf / Moos', value: 'Urdorf / Moos' },
  { label: 'Urdorf / Oberurdorf', value: 'Urdorf / Oberurdorf' },
  { label: 'Urduliz', value: 'Urduliz' },
  { label: 'Urduña/Orduña', value: 'Urduña/Orduña' },
  { label: 'Urechcha', value: 'Urechcha' },
  { label: 'Urecheni', value: 'Urecheni' },
  { label: 'Urecheşti', value: 'Urecheşti' },
  { label: 'Urecheşti', value: 'Urecheşti' },
  { label: 'Urecho', value: 'Urecho' },
  { label: 'Urek’I', value: 'Urek’I' },
  { label: 'Urén', value: 'Urén' },
  { label: 'Uren’', value: 'Uren’' },
  { label: 'Urengoy', value: 'Urengoy' },
  { label: 'Ures', value: 'Ures' },
  { label: 'Ureshino Shi', value: 'Ureshino Shi' },
  { label: 'Ureterp', value: 'Ureterp' },
  { label: 'Urganch', value: 'Urganch' },
  { label: 'Urgeses', value: 'Urgeses' },
  { label: 'Urgnano', value: 'Urgnano' },
  { label: 'Ürgüp', value: 'Ürgüp' },
  { label: 'Urgut', value: 'Urgut' },
  { label: 'Uri', value: 'Uri' },
  { label: 'Uri', value: 'Uri' },
  { label: 'Úri', value: 'Úri' },
  { label: 'Uriangato', value: 'Uriangato' },
  { label: 'Uribe', value: 'Uribe' },
  { label: 'Uribia', value: 'Uribia' },
  { label: 'Uriburu', value: 'Uriburu' },
  { label: 'Uricani', value: 'Uricani' },
  { label: 'Uricea', value: 'Uricea' },
  { label: 'Uricho', value: 'Uricho' },
  { label: 'Urik', value: 'Urik' },
  { label: 'Uriménil', value: 'Uriménil' },
  { label: 'Uripitio', value: 'Uripitio' },
  { label: 'Urique', value: 'Urique' },
  { label: 'Urireo', value: 'Urireo' },
  { label: 'Urisiu De Jos', value: 'Urisiu De Jos' },
  { label: 'Urisiu De Sus', value: 'Urisiu De Sus' },
  { label: 'Uritsk', value: 'Uritsk' },
  { label: 'Uriu', value: 'Uriu' },
  { label: 'Urjala', value: 'Urjala' },
  { label: 'Urk', value: 'Urk' },
  { label: 'Urkabustaiz', value: 'Urkabustaiz' },
  { label: 'Urkarakh', value: 'Urkarakh' },
  { label: 'Úrkút', value: 'Úrkút' },
  { label: 'Urla', value: 'Urla' },
  { label: 'Urlaţi', value: 'Urlaţi' },
  { label: 'Urleasca', value: 'Urleasca' },
  { label: 'Urleta', value: 'Urleta' },
  { label: 'Urma', value: 'Urma' },
  { label: 'Urman', value: 'Urman' },
  { label: 'Urmarskiy Rayon', value: 'Urmarskiy Rayon' },
  { label: 'Urmary', value: 'Urmary' },
  { label: 'Urmatt', value: 'Urmatt' },
  { label: 'Urmeniş', value: 'Urmeniş' },
  { label: 'Urmeniș', value: 'Urmeniș' },
  { label: 'Urmia', value: 'Urmia' },
  { label: 'Urmitz', value: 'Urmitz' },
  { label: 'Urmond', value: 'Urmond' },
  { label: 'Urmston', value: 'Urmston' },
  { label: 'Urnäsch', value: 'Urnäsch' },
  { label: 'Urnieta', value: 'Urnieta' },
  { label: 'Üröm', value: 'Üröm' },
  { label: 'Uromi', value: 'Uromi' },
  { label: 'Urones De Castroponce', value: 'Urones De Castroponce' },
  { label: 'Urozhaynoye', value: 'Urozhaynoye' },
  { label: 'Urozhaynoye', value: 'Urozhaynoye' },
  { label: 'Urrácal', value: 'Urrácal' },
  { label: 'Urrao', value: 'Urrao' },
  { label: 'Urraul Alto', value: 'Urraul Alto' },
  { label: 'Urraul Bajo', value: 'Urraul Bajo' },
  { label: 'Urraween', value: 'Urraween' },
  { label: 'Urrea De Gaén', value: 'Urrea De Gaén' },
  { label: 'Urrea De Jalón', value: 'Urrea De Jalón' },
  { label: 'Urretxu', value: 'Urretxu' },
  { label: 'Urriés', value: 'Urriés' },
  { label: 'Urrotz', value: 'Urrotz' },
  { label: 'Urroz-Villa', value: 'Urroz-Villa' },
  { label: 'Urrugne', value: 'Urrugne' },
  { label: 'Urrut', value: 'Urrut' },
  { label: 'Ursa', value: 'Ursa' },
  { label: 'Ursberg', value: 'Ursberg' },
  { label: 'Ursem', value: 'Ursem' },
  { label: 'Urseni', value: 'Urseni' },
  { label: 'Ursensollen', value: 'Ursensollen' },
  { label: 'Urshel’Skiy', value: 'Urshel’Skiy' },
  { label: 'Urși', value: 'Urși' },
  { label: 'Ursk', value: 'Ursk' },
  { label: 'Ursoaia', value: 'Ursoaia' },
  { label: 'Urspringen', value: 'Urspringen' },
  { label: 'Ursulo Galván', value: 'Ursulo Galván' },
  { label: 'Úrsulo Galván', value: 'Úrsulo Galván' },
  { label: 'Ursus', value: 'Ursus' },
  { label: 'Ursviken', value: 'Ursviken' },
  { label: 'Ursynów', value: 'Ursynów' },
  { label: 'Urszulin', value: 'Urszulin' },
  { label: 'Urt', value: 'Urt' },
  { label: 'Ŭrtaowul', value: 'Ŭrtaowul' },
  { label: 'Urtenen', value: 'Urtenen' },
  { label: 'Uru', value: 'Uru' },
  { label: 'Uruachi', value: 'Uruachi' },
  { label: 'Uruaçu', value: 'Uruaçu' },
  { label: 'Uruana', value: 'Uruana' },
  { label: 'Uruana De Minas', value: 'Uruana De Minas' },
  { label: 'Uruapan', value: 'Uruapan' },
  { label: 'Uruará', value: 'Uruará' },
  { label: 'Urubamba', value: 'Urubamba' },
  { label: 'Urubichá', value: 'Urubichá' },
  { label: 'Urubici', value: 'Urubici' },
  { label: 'Uruburetama', value: 'Uruburetama' },
  { label: 'Urucânia', value: 'Urucânia' },
  { label: 'Urucará', value: 'Urucará' },
  { label: 'Uruçuca', value: 'Uruçuca' },
  { label: 'Uruçuí', value: 'Uruçuí' },
  { label: 'Urucuia', value: 'Urucuia' },
  { label: 'Urucurituba', value: 'Urucurituba' },
  { label: 'Urueña', value: 'Urueña' },
  { label: 'Urueñas', value: 'Urueñas' },
  { label: 'Uruétaro', value: 'Uruétaro' },
  { label: 'Urugi', value: 'Urugi' },
  { label: 'Uruguaiana', value: 'Uruguaiana' },
  { label: 'Urukh', value: 'Urukh' },
  { label: 'Uruli Kanchan', value: 'Uruli Kanchan' },
  { label: 'Uruma Shi', value: 'Uruma Shi' },
  { label: 'Urumita', value: 'Urumita' },
  { label: 'Ürümqi', value: 'Ürümqi' },
  { label: 'Urumqi Shi', value: 'Urumqi Shi' },
  { label: 'Urunga', value: 'Urunga' },
  { label: 'Uruñuela', value: 'Uruñuela' },
  { label: 'Uruobo-Okija', value: 'Uruobo-Okija' },
  { label: 'Uruoca', value: 'Uruoca' },
  { label: 'Urupá', value: 'Urupá' },
  { label: 'Urupema', value: 'Urupema' },
  { label: 'Urupês', value: 'Urupês' },
  { label: 'Ururi', value: 'Ururi' },
  { label: 'Urús', value: 'Urús' },
  { label: 'Urusha', value: 'Urusha' },
  { label: 'Urus-Martan', value: 'Urus-Martan' },
  { label: 'Urus-Martanovskiy Rayon', value: 'Urus-Martanovskiy Rayon' },
  { label: 'Urussanga', value: 'Urussanga' },
  { label: 'Urussu', value: 'Urussu' },
  { label: 'Urutaí', value: 'Urutaí' },
  { label: 'Uruzgān', value: 'Uruzgān' },
  { label: 'Urvan’', value: 'Urvan’' },
  { label: 'Urvanskiy Rayon', value: 'Urvanskiy Rayon' },
  { label: 'Urville-Nacqueville', value: 'Urville-Nacqueville' },
  { label: 'Uryupinsk', value: 'Uryupinsk' },
  { label: 'Uryv-Pokrovka', value: 'Uryv-Pokrovka' },
  { label: 'Urzainqui', value: 'Urzainqui' },
  { label: 'Urzainqui/Urzainki', value: 'Urzainqui/Urzainki' },
  { label: 'Urzędów', value: 'Urzędów' },
  { label: 'Urzejowice', value: 'Urzejowice' },
  { label: 'Urzhar', value: 'Urzhar' },
  { label: 'Urzhum', value: 'Urzhum' },
  { label: 'Urzica', value: 'Urzica' },
  { label: 'Urziceanca', value: 'Urziceanca' },
  { label: 'Urziceni', value: 'Urziceni' },
  { label: 'Urziceni', value: 'Urziceni' },
  { label: 'Urzicuţa', value: 'Urzicuţa' },
  { label: 'Urzuf', value: 'Urzuf' },
  { label: 'Urzulei', value: 'Urzulei' },
  { label: 'Urzy', value: 'Urzy' },
  { label: 'Us', value: 'Us' },
  { label: 'Usa', value: 'Usa' },
  { label: 'Usa River', value: 'Usa River' },
  { label: 'Usa Shi', value: 'Usa Shi' },
  { label: 'Usab', value: 'Usab' },
  { label: 'Usagara', value: 'Usagara' },
  { label: 'Usagre', value: 'Usagre' },
  { label: 'Usakos', value: 'Usakos' },
  { label: 'Usatove', value: 'Usatove' },
  { label: 'Usawan', value: 'Usawan' },
  { label: 'Usaylan', value: 'Usaylan' },
  { label: 'Uście Gorlickie', value: 'Uście Gorlickie' },
  { label: 'Uścimów Stary', value: 'Uścimów Stary' },
  { label: 'Uscio', value: 'Uscio' },
  { label: 'Used', value: 'Used' },
  { label: 'Usedom', value: 'Usedom' },
  { label: 'Usehat', value: 'Usehat' },
  { label: 'Useldange', value: 'Useldange' },
  { label: 'Usellus', value: 'Usellus' },
  { label: 'Usera', value: 'Usera' },
  { label: 'Useras/Useres Les', value: 'Useras/Useres Les' },
  { label: 'Usevia', value: 'Usevia' },
  { label: 'Usfan', value: 'Usfan' },
  { label: 'Ushachy', value: 'Ushachy' },
  { label: 'Ushash', value: 'Ushash' },
  { label: 'Ushaw Moor', value: 'Ushaw Moor' },
  { label: 'Ushayrah', value: 'Ushayrah' },
  { label: 'Usher', value: 'Usher' },
  { label: 'Ushi', value: 'Ushi' },
  { label: 'Ushibukamachi', value: 'Ushibukamachi' },
  { label: 'Ushiku', value: 'Ushiku' },
  { label: 'Ushiku-Shi', value: 'Ushiku-Shi' },
  { label: 'Ushirombo', value: 'Ushirombo' },
  { label: 'Ush-Tyube', value: 'Ush-Tyube' },
  { label: 'Ushuaia', value: 'Ushuaia' },
  { label: 'Ushumun', value: 'Ushumun' },
  { label: 'Usiacurí', value: 'Usiacurí' },
  { label: 'Usiazh', value: 'Usiazh' },
  { label: 'Usilampatti', value: 'Usilampatti' },
  { label: 'Usingen', value: 'Usingen' },
  { label: 'Usini', value: 'Usini' },
  { label: 'Usino Bundi', value: 'Usino Bundi' },
  { label: 'Usinsk', value: 'Usinsk' },
  { label: 'Usipa', value: 'Usipa' },
  { label: 'Usisha', value: 'Usisha' },
  { label: 'Usje', value: 'Usje' },
  { label: 'Usk', value: 'Usk' },
  { label: 'Uska', value: 'Uska' },
  { label: 'Üsküdar', value: 'Üsküdar' },
  { label: 'Uslar', value: 'Uslar' },
  { label: 'Usmajac', value: 'Usmajac' },
  { label: 'Usman’', value: 'Usman’' },
  { label: 'Usmanskiy Rayon', value: 'Usmanskiy Rayon' },
  { label: 'Usmate-Velate', value: 'Usmate-Velate' },
  { label: 'Usogorsk', value: 'Usogorsk' },
  { label: 'Usoke', value: 'Usoke' },
  { label: 'Usol’Skiy Rayon', value: 'Usol’Skiy Rayon' },
  { label: 'Usol’Ye', value: 'Usol’Ye' },
  { label: 'Usol’Ye-Sibirskoye', value: 'Usol’Ye-Sibirskoye' },
  { label: 'Uson', value: 'Uson' },
  { label: 'Úsov', value: 'Úsov' },
  { label: 'Uspantán', value: 'Uspantán' },
  { label: 'Uspenka', value: 'Uspenka' },
  { label: 'Uspenka', value: 'Uspenka' },
  { label: 'Uspenskaya', value: 'Uspenskaya' },
  { label: 'Uspenskoye', value: 'Uspenskoye' },
  { label: 'Uspero', value: 'Uspero' },
  { label: 'Usquert', value: 'Usquert' },
  { label: 'Ussac', value: 'Ussac' },
  { label: 'Ussana', value: 'Ussana' },
  { label: 'Ussaramanna', value: 'Ussaramanna' },
  { label: 'Ussassai', value: 'Ussassai' },
  { label: 'Usseaux', value: 'Usseaux' },
  { label: 'Usseglio', value: 'Usseglio' },
  { label: 'Ussel', value: 'Ussel' },
  { label: 'Usson-Du-Poitou', value: 'Usson-Du-Poitou' },
  { label: 'Usson-En-Forez', value: 'Usson-En-Forez' },
  { label: 'Ussuriysk', value: 'Ussuriysk' },
  { label: 'Ust’-Abakan', value: 'Ust’-Abakan' },
  { label: 'Ust’-Bagaryak', value: 'Ust’-Bagaryak' },
  { label: 'Ust’-Barguzin', value: 'Ust’-Barguzin' },
  { label: 'Ust’-Bol’Sheretskiy Rayon', value: 'Ust’-Bol’Sheretskiy Rayon' },
  { label: 'Ust’-Charyshskaya Pristan’', value: 'Ust’-Charyshskaya Pristan’' },
  { label: 'Ust’-Donetskiy', value: 'Ust’-Donetskiy' },
  { label: 'Ust’-Dzheguta', value: 'Ust’-Dzheguta' },
  { label: 'Ust’-Ilimsk', value: 'Ust’-Ilimsk' },
  { label: 'Ust’-Isha', value: 'Ust’-Isha' },
  { label: 'Ust’-Ishim', value: 'Ust’-Ishim' },
  { label: 'Ust’-Izhora', value: 'Ust’-Izhora' },
  { label: 'Ust’-Kachka', value: 'Ust’-Kachka' },
  { label: 'Ust’-Kalmanka', value: 'Ust’-Kalmanka' },
  { label: 'Ust’-Kamchatsk Staryy', value: 'Ust’-Kamchatsk Staryy' },
  { label: 'Ust’-Kamchatskiy Rayon', value: 'Ust’-Kamchatskiy Rayon' },
  { label: 'Ust’-Kan', value: 'Ust’-Kan' },
  { label: 'Ust’-Karsk', value: 'Ust’-Karsk' },
  { label: 'Ust’-Katav', value: 'Ust’-Katav' },
  { label: 'Ust’-Kishert’', value: 'Ust’-Kishert’' },
  { label: 'Ust’-Koksa', value: 'Ust’-Koksa' },
  { label: 'Ust’-Koksinskiy Rayon', value: 'Ust’-Koksinskiy Rayon' },
  { label: 'Ust’-Kubinskiy Rayon', value: 'Ust’-Kubinskiy Rayon' },
  { label: 'Ust’-Kulom', value: 'Ust’-Kulom' },
  { label: 'Ust’-Kut', value: 'Ust’-Kut' },
  { label: 'Ust’-Kutskiy Rayon', value: 'Ust’-Kutskiy Rayon' },
  { label: 'Ust’-Labinsk', value: 'Ust’-Labinsk' },
  { label: 'Ust’-Luga', value: 'Ust’-Luga' },
  { label: 'Ust’-Omchug', value: 'Ust’-Omchug' },
  { label: 'Ust’-Ordynskiy', value: 'Ust’-Ordynskiy' },
  { label: 'Ust’-Shonosha', value: 'Ust’-Shonosha' },
  { label: 'Ūst’-Talovka', value: 'Ūst’-Talovka' },
  { label: 'Ust’-Tarka', value: 'Ust’-Tarka' },
  { label: 'Ust’-Tsilemskiy Rayon', value: 'Ust’-Tsilemskiy Rayon' },
  { label: 'Ust’-Uda', value: 'Ust’-Uda' },
  { label: 'Ust’-Udinskiy Rayon', value: 'Ust’-Udinskiy Rayon' },
  { label: 'Ust’-Ulagan', value: 'Ust’-Ulagan' },
  { label: 'Ust’Yanka', value: 'Ust’Yanka' },
  { label: 'Ust’Yanskiy Rayon', value: 'Ust’Yanskiy Rayon' },
  { label: 'Ust’-Yanskiy Rayon', value: 'Ust’-Yanskiy Rayon' },
  { label: 'Ust’Ye', value: 'Ust’Ye' },
  { label: 'Usta Muhammad', value: 'Usta Muhammad' },
  { label: 'Ust-Aldansky District', value: 'Ust-Aldansky District' },
  { label: 'Ustaritz', value: 'Ustaritz' },
  { label: 'Úštěk', value: 'Úštěk' },
  { label: 'Uster', value: 'Uster' },
  { label: 'Uster / Gschwader', value: 'Uster / Gschwader' },
  { label: 'Uster / Kirch-Uster', value: 'Uster / Kirch-Uster' },
  { label: 'Uster / Nieder-Uster', value: 'Uster / Nieder-Uster' },
  { label: 'Uster / Nossikon', value: 'Uster / Nossikon' },
  { label: 'Uster / Ober-Uster', value: 'Uster / Ober-Uster' },
  { label: 'Ustersbach', value: 'Ustersbach' },
  { label: 'Ústí Nad Orlicí', value: 'Ústí Nad Orlicí' },
  { label: 'Ustia', value: 'Ustia' },
  { label: 'Ustica', value: 'Ustica' },
  { label: 'Ustikolina', value: 'Ustikolina' },
  { label: 'Ustka', value: 'Ustka' },
  { label: 'Ust-Kamenogorsk', value: 'Ust-Kamenogorsk' },
  { label: 'Ust-Kuyga', value: 'Ust-Kuyga' },
  { label: 'Ust-Maya', value: 'Ust-Maya' },
  { label: 'Ust-Nera', value: 'Ust-Nera' },
  { label: 'Ustroń', value: 'Ustroń' },
  { label: 'Ustronie Morskie', value: 'Ustronie Morskie' },
  { label: 'Ustrzyki Dolne', value: 'Ustrzyki Dolne' },
  { label: 'Ust-Tsilma', value: 'Ust-Tsilma' },
  { label: 'Ustupo', value: 'Ustupo' },
  { label: 'Ustynivka', value: 'Ustynivka' },
  { label: 'Ustyuzhenskiy Rayon', value: 'Ustyuzhenskiy Rayon' },
  { label: 'Ustyuzhna', value: 'Ustyuzhna' },
  { label: 'Usukhchay', value: 'Usukhchay' },
  { label: 'Usuki', value: 'Usuki' },
  { label: 'Usuki Shi', value: 'Usuki Shi' },
  { label: 'Usulután', value: 'Usulután' },
  { label: 'Usurbil', value: 'Usurbil' },
  { label: 'Ususău', value: 'Ususău' },
  { label: 'Usvyaty', value: 'Usvyaty' },
  { label: 'Usyatskoye', value: 'Usyatskoye' },
  { label: 'Uta', value: 'Uta' },
  { label: 'Utabi', value: 'Utabi' },
  { label: 'Utah County', value: 'Utah County' },
  { label: 'Utajärvi', value: 'Utajärvi' },
  { label: 'Utakarra', value: 'Utakarra' },
  { label: 'Utamysh', value: 'Utamysh' },
  { label: 'Utande', value: 'Utande' },
  { label: 'Utashinai', value: 'Utashinai' },
  { label: 'Utashinai-Shi', value: 'Utashinai-Shi' },
  { label: 'Utcubamba', value: 'Utcubamba' },
  { label: 'Utebo', value: 'Utebo' },
  { label: 'Utena', value: 'Utena' },
  { label: 'Uterga', value: 'Uterga' },
  { label: 'Utete', value: 'Utete' },
  { label: 'Utevka', value: 'Utevka' },
  { label: 'Uthai Thani', value: 'Uthai Thani' },
  { label: 'Uthal', value: 'Uthal' },
  { label: 'Uthleben', value: 'Uthleben' },
  { label: 'Uthlede', value: 'Uthlede' },
  { label: 'Uthukela District Municipality', value: 'Uthukela District Municipality' },
  { label: 'Uthungulu District Municipality', value: 'Uthungulu District Municipality' },
  { label: 'Utica', value: 'Utica' },
  { label: 'Utica', value: 'Utica' },
  { label: 'Utica', value: 'Utica' },
  { label: 'Utica', value: 'Utica' },
  { label: 'Útica', value: 'Útica' },
  { label: 'Utiel', value: 'Utiel' },
  { label: 'Utila', value: 'Utila' },
  { label: 'Utinga', value: 'Utinga' },
  { label: 'Utirá', value: 'Utirá' },
  { label: 'Utkivka', value: 'Utkivka' },
  { label: 'Utmah', value: 'Utmah' },
  { label: 'Utmanzai', value: 'Utmanzai' },
  { label: 'Uto', value: 'Uto' },
  { label: 'Uto Shi', value: 'Uto Shi' },
  { label: 'Utorgosh', value: 'Utorgosh' },
  { label: 'Utran', value: 'Utran' },
  { label: 'Utraula', value: 'Utraula' },
  { label: 'Utrecht', value: 'Utrecht' },
  { label: 'Utrecht', value: 'Utrecht' },
  { label: 'Utrera', value: 'Utrera' },
  { label: 'Utrillas', value: 'Utrillas' },
  { label: 'Utschtal', value: 'Utschtal' },
  { label: 'Utsira', value: 'Utsira' },
  { label: 'Utsjoki', value: 'Utsjoki' },
  { label: 'Utsmiyurt', value: 'Utsmiyurt' },
  { label: 'Utsunomiya', value: 'Utsunomiya' },
  { label: 'Utsunomiya-Shi', value: 'Utsunomiya-Shi' },
  { label: 'Uttamapalaiyam', value: 'Uttamapalaiyam' },
  { label: 'Uttar Bastar Kanker', value: 'Uttar Bastar Kanker' },
  { label: 'Uttar Dinajpur District', value: 'Uttar Dinajpur District' },
  { label: 'Uttar Kannada', value: 'Uttar Kannada' },
  { label: 'Uttaradit', value: 'Uttaradit' },
  { label: 'Uttarkashi', value: 'Uttarkashi' },
  { label: 'Uttarpara Kotrung', value: 'Uttarpara Kotrung' },
  { label: 'Uttendorf', value: 'Uttendorf' },
  { label: 'Uttendorf', value: 'Uttendorf' },
  { label: 'Uttenreuth', value: 'Uttenreuth' },
  { label: 'Uttenweiler', value: 'Uttenweiler' },
  { label: 'Uttigen', value: 'Uttigen' },
  { label: 'Utting Am Ammersee', value: 'Utting Am Ammersee' },
  { label: 'Uttiramerur', value: 'Uttiramerur' },
  { label: 'Uttoxeter', value: 'Uttoxeter' },
  { label: 'Uttukkuli', value: 'Uttukkuli' },
  { label: 'Uttwil', value: 'Uttwil' },
  { label: 'Utuado', value: 'Utuado' },
  { label: 'Utuado', value: 'Utuado' },
  { label: 'Utuado Barrio-Pueblo', value: 'Utuado Barrio-Pueblo' },
  { label: 'Utvin', value: 'Utvin' },
  { label: 'Utwe Municipality', value: 'Utwe Municipality' },
  { label: 'Utzenaich', value: 'Utzenaich' },
  { label: 'Utzenstorf', value: 'Utzenstorf' },
  { label: 'Uuemõisa', value: 'Uuemõisa' },
  { label: 'Uulu', value: 'Uulu' },
  { label: 'Uurainen', value: 'Uurainen' },
  { label: 'Uusikaupunki', value: 'Uusikaupunki' },
  { label: 'Uva', value: 'Uva' },
  { label: 'Uvalde', value: 'Uvalde' },
  { label: 'Uvalde County', value: 'Uvalde County' },
  { label: 'Uvalde Estates', value: 'Uvalde Estates' },
  { label: 'Úvaly', value: 'Úvaly' },
  { label: 'Uvarovka', value: 'Uvarovka' },
  { label: 'Uvarovka', value: 'Uvarovka' },
  { label: 'Uvarovo', value: 'Uvarovo' },
  { label: 'Uvarovo', value: 'Uvarovo' },
  { label: 'Uvary', value: 'Uvary' },
  { label: 'Uvat', value: 'Uvat' },
  { label: 'Uvel’Skiy', value: 'Uvel’Skiy' },
  { label: 'Uvel’Skiy Rayon', value: 'Uvel’Skiy Rayon' },
  { label: 'Uvinza', value: 'Uvinza' },
  { label: 'Uvira', value: 'Uvira' },
  { label: 'Uwajima', value: 'Uwajima' },
  { label: 'Uwajima-Shi', value: 'Uwajima-Shi' },
  { label: 'Uwelini', value: 'Uwelini' },
  { label: 'Uxbridge', value: 'Uxbridge' },
  { label: 'Uxbridge', value: 'Uxbridge' },
  { label: 'Uxegney', value: 'Uxegney' },
  { label: 'Uxem', value: 'Uxem' },
  { label: 'Üxheim', value: 'Üxheim' },
  { label: 'Uxpanapa', value: 'Uxpanapa' },
  { label: 'Uyaan', value: 'Uyaan' },
  { label: 'Uyaan', value: 'Uyaan' },
  { label: 'Uyar', value: 'Uyar' },
  { label: 'Uyarskiy Rayon', value: 'Uyarskiy Rayon' },
  { label: 'Uychi', value: 'Uychi' },
  { label: 'Uyemskiy', value: 'Uyemskiy' },
  { label: 'Uyo', value: 'Uyo' },
  { label: 'Üyönch', value: 'Üyönch' },
  { label: 'Uyong', value: 'Uyong' },
  { label: 'Uyong', value: 'Uyong' },
  { label: 'Uyovu', value: 'Uyovu' },
  { label: 'Uyskiy Rayon', value: 'Uyskiy Rayon' },
  { label: 'Uyskoye', value: 'Uyskoye' },
  { label: 'Uyugan', value: 'Uyugan' },
  { label: 'Uyugan', value: 'Uyugan' },
  { label: 'Uyuni', value: 'Uyuni' },
  { label: 'Uyutnoye', value: 'Uyutnoye' },
  { label: 'Uzda', value: 'Uzda' },
  { label: 'Uzdin', value: 'Uzdin' },
  { label: 'Uzdzyenski Rayon', value: 'Uzdzyenski Rayon' },
  { label: 'Uzein', value: 'Uzein' },
  { label: 'Uzeir', value: 'Uzeir' },
  { label: 'Uzemain', value: 'Uzemain' },
  { label: 'Uzerche', value: 'Uzerche' },
  { label: 'Uzès', value: 'Uzès' },
  { label: 'Uzeta', value: 'Uzeta' },
  { label: 'Uzgen', value: 'Uzgen' },
  { label: 'Uzgen District', value: 'Uzgen District' },
  { label: 'Uzhgorod', value: 'Uzhgorod' },
  { label: 'Uzhhorod Raion', value: 'Uzhhorod Raion' },
  { label: 'Uzhur', value: 'Uzhur' },
  { label: 'Užice', value: 'Užice' },
  { label: 'Uzlovaya', value: 'Uzlovaya' },
  { label: 'Uznach', value: 'Uznach' },
  { label: 'Uztárroz/Uztarroze', value: 'Uztárroz/Uztarroze' },
  { label: 'Üzümlü', value: 'Üzümlü' },
  { label: 'Uzundere', value: 'Uzundere' },
  { label: 'Uzunköprü', value: 'Uzunköprü' },
  { label: 'Uzunovo', value: 'Uzunovo' },
  { label: 'Uzunu', value: 'Uzunu' },
  { label: 'Uzveće', value: 'Uzveće' },
  { label: 'Uzwil', value: 'Uzwil' },
  { label: 'Uzyn', value: 'Uzyn' },
  { label: 'Uzzano', value: 'Uzzano' },
  { label: 'V.S.K.Valasai (Dindigul-Dist.)', value: 'V.S.K.Valasai (Dindigul-Dist.)' },
  { label: 'Vaala', value: 'Vaala' },
  { label: 'Vaale', value: 'Vaale' },
  { label: 'Vaals', value: 'Vaals' },
  { label: 'Vaartbroek', value: 'Vaartbroek' },
  { label: 'Vaas', value: 'Vaas' },
  { label: 'Vaasa', value: 'Vaasa' },
  { label: 'Vaassen', value: 'Vaassen' },
  { label: 'Vabalninkas', value: 'Vabalninkas' },
  { label: 'Vác', value: 'Vác' },
  { label: 'Văcăreni', value: 'Văcăreni' },
  { label: 'Văcăreşti', value: 'Văcăreşti' },
  { label: 'Vacaria', value: 'Vacaria' },
  { label: 'Vacarisses', value: 'Vacarisses' },
  { label: 'Vacaville', value: 'Vacaville' },
  { label: 'Vaccarizzo Albanese', value: 'Vaccarizzo Albanese' },
  { label: 'Vacchereccia', value: 'Vacchereccia' },
  { label: 'Vaccheria', value: 'Vaccheria' },
  { label: 'Vacenovice', value: 'Vacenovice' },
  { label: 'Vacha', value: 'Vacha' },
  { label: 'Vacha', value: 'Vacha' },
  { label: 'Vachendorf', value: 'Vachendorf' },
  { label: 'Vachskiy Rayon', value: 'Vachskiy Rayon' },
  { label: 'Váci Járás', value: 'Váci Járás' },
  { label: 'Václavovice', value: 'Václavovice' },
  { label: 'Vacoas', value: 'Vacoas' },
  { label: 'Vacon', value: 'Vacon' },
  { label: 'Vacone', value: 'Vacone' },
  { label: 'Vacov', value: 'Vacov' },
  { label: 'Vacqueyras', value: 'Vacqueyras' },
  { label: 'Vacquiers', value: 'Vacquiers' },
  { label: 'Vacri', value: 'Vacri' },
  { label: 'Vácszentlászló', value: 'Vácszentlászló' },
  { label: 'Văculeşti', value: 'Văculeşti' },
  { label: 'Vad', value: 'Vad' },
  { label: 'Vad', value: 'Vad' },
  { label: 'Vad', value: 'Vad' },
  { label: 'Vada', value: 'Vada' },
  { label: 'Vada', value: 'Vada' },
  { label: 'Vadakku Valliyur', value: 'Vadakku Valliyur' },
  { label: 'Vadakku Viravanallur', value: 'Vadakku Viravanallur' },
  { label: 'Vadamadurai', value: 'Vadamadurai' },
  { label: 'Vădaș', value: 'Vădaș' },
  { label: 'Vădastra', value: 'Vădastra' },
  { label: 'Vădăstriţa', value: 'Vădăstriţa' },
  { label: 'Vadena', value: 'Vadena' },
  { label: 'Vădeni', value: 'Vădeni' },
  { label: 'Vadgam', value: 'Vadgam' },
  { label: 'Vadgaon', value: 'Vadgaon' },
  { label: 'Vadhana', value: 'Vadhana' },
  { label: 'Vadigenhalli', value: 'Vadigenhalli' },
  { label: 'Vadillo', value: 'Vadillo' },
  { label: 'Vadillo De La Guareña', value: 'Vadillo De La Guareña' },
  { label: 'Vadillo De La Sierra', value: 'Vadillo De La Sierra' },
  { label: 'Vadinsk', value: 'Vadinsk' },
  { label: 'Vadippatti', value: 'Vadippatti' },
  { label: 'Vadlamuru', value: 'Vadlamuru' },
  { label: 'Vadlapudi', value: 'Vadlapudi' },
  { label: 'Vadnagar', value: 'Vadnagar' },
  { label: 'Vadnais Heights', value: 'Vadnais Heights' },
  { label: 'Vadner', value: 'Vadner' },
  { label: 'Vado', value: 'Vado' },
  { label: 'Vado', value: 'Vado' },
  { label: 'Vado Ancho', value: 'Vado Ancho' },
  { label: 'Vado Centro', value: 'Vado Centro' },
  { label: 'Vado Ligure', value: 'Vado Ligure' },
  { label: 'Vadocondes', value: 'Vadocondes' },
  { label: 'Vadodara', value: 'Vadodara' },
  { label: 'Vadsø', value: 'Vadsø' },
  { label: 'Vadstena', value: 'Vadstena' },
  { label: 'Vadu', value: 'Vadu' },
  { label: 'Vadu Crişului', value: 'Vadu Crişului' },
  { label: 'Vadu Izei', value: 'Vadu Izei' },
  { label: 'Vadu Moldovei', value: 'Vadu Moldovei' },
  { label: 'Vadu Moţilor', value: 'Vadu Moţilor' },
  { label: 'Vadu Părului', value: 'Vadu Părului' },
  { label: 'Vadu Paşii', value: 'Vadu Paşii' },
  { label: 'Vadu Roșca', value: 'Vadu Roșca' },
  { label: 'Vadu Săpat', value: 'Vadu Săpat' },
  { label: 'Vadu Sorești', value: 'Vadu Sorești' },
  { label: 'Vadue', value: 'Vadue' },
  { label: 'Vadul Lui Vodă', value: 'Vadul Lui Vodă' },
  { label: 'Vadum', value: 'Vadum' },
  { label: 'Vădurele', value: 'Vădurele' },
  { label: 'Vaduz', value: 'Vaduz' },
  { label: 'Værløse', value: 'Værløse' },
  { label: 'Værøy', value: 'Værøy' },
  { label: 'Vafr-E Jin', value: 'Vafr-E Jin' },
  { label: 'Vågå', value: 'Vågå' },
  { label: 'Vågåmo', value: 'Vågåmo' },
  { label: 'Vågan', value: 'Vågan' },
  { label: 'Vágar', value: 'Vágar' },
  { label: 'Vagator', value: 'Vagator' },
  { label: 'Vagay', value: 'Vagay' },
  { label: 'Vagayskiy Rayon', value: 'Vagayskiy Rayon' },
  { label: 'Vagen', value: 'Vagen' },
  { label: 'Vaggeryd', value: 'Vaggeryd' },
  { label: 'Vagharshapat', value: 'Vagharshapat' },
  { label: 'Vaghashen', value: 'Vaghashen' },
  { label: 'Vaghodia', value: 'Vaghodia' },
  { label: 'Vaghodia Ina', value: 'Vaghodia Ina' },
  { label: 'Vágia', value: 'Vágia' },
  { label: 'Văgiuleşti', value: 'Văgiuleşti' },
  { label: 'Vaglia', value: 'Vaglia' },
  { label: 'Vaglio Basilicata', value: 'Vaglio Basilicata' },
  { label: 'Vaglio Serra', value: 'Vaglio Serra' },
  { label: 'Vagney', value: 'Vagney' },
  { label: 'Vagnhärad', value: 'Vagnhärad' },
  { label: 'Vago', value: 'Vago' },
  { label: 'Vagonoremont', value: 'Vagonoremont' },
  { label: 'Vagos', value: 'Vagos' },
  { label: 'Vågsøy', value: 'Vågsøy' },
  { label: 'Vágur', value: 'Vágur' },
  { label: 'Vahagni', value: 'Vahagni' },
  { label: 'Vähäkyrö', value: 'Vähäkyrö' },
  { label: 'Vahan', value: 'Vahan' },
  { label: 'Vahandeh', value: 'Vahandeh' },
  { label: 'Vahdat', value: 'Vahdat' },
  { label: 'Vahdat District', value: 'Vahdat District' },
  { label: 'Vahdatiyeh', value: 'Vahdatiyeh' },
  { label: 'Vahidieh', value: 'Vahidieh' },
  { label: 'Vahman', value: 'Vahman' },
  { label: 'Vahnan', value: 'Vahnan' },
  { label: 'Vahto', value: 'Vahto' },
  { label: 'Vaiano', value: 'Vaiano' },
  { label: 'Vaiano Cremasco', value: 'Vaiano Cremasco' },
  { label: 'Vaiatu', value: 'Vaiatu' },
  { label: 'Vaida', value: 'Vaida' },
  { label: 'Vaida-Cămăraș', value: 'Vaida-Cămăraș' },
  { label: 'Vaidacuta', value: 'Vaidacuta' },
  { label: 'Vaideeni', value: 'Vaideeni' },
  { label: 'Vaideiu', value: 'Vaideiu' },
  { label: 'Vaie', value: 'Vaie' },
  { label: 'Vaiges', value: 'Vaiges' },
  { label: 'Vaighan', value: 'Vaighan' },
  { label: 'Vaihingen An Der Enz', value: 'Vaihingen An Der Enz' },
  { label: 'Vaijapur', value: 'Vaijapur' },
  { label: 'Vaikam', value: 'Vaikam' },
  { label: 'Väike-Maarja', value: 'Väike-Maarja' },
  { label: 'Väike-Maarja Vald', value: 'Väike-Maarja Vald' },
  { label: 'Vail', value: 'Vail' },
  { label: 'Vail', value: 'Vail' },
  { label: 'Vailate', value: 'Vailate' },
  { label: 'Vailhauquès', value: 'Vailhauquès' },
  { label: 'Vailima', value: 'Vailima' },
  { label: 'Vailly-Sur-Aisne', value: 'Vailly-Sur-Aisne' },
  { label: 'Vailoa', value: 'Vailoa' },
  { label: 'Vails Gate', value: 'Vails Gate' },
  { label: 'Vaini', value: 'Vaini' },
  { label: 'Vaiņode', value: 'Vaiņode' },
  { label: 'Vairag', value: 'Vairag' },
  { label: 'Vairano-Patenora', value: 'Vairano-Patenora' },
  { label: 'Vairão', value: 'Vairão' },
  { label: 'Vairé', value: 'Vairé' },
  { label: 'Vaires-Sur-Marne', value: 'Vaires-Sur-Marne' },
  { label: 'Vaishali', value: 'Vaishali' },
  { label: 'Vaison-La-Romaine', value: 'Vaison-La-Romaine' },
  { label: 'Vaiusu', value: 'Vaiusu' },
  { label: 'Vaivre-Et-Montoille', value: 'Vaivre-Et-Montoille' },
  { label: 'Vaja', value: 'Vaja' },
  { label: 'Vajargah', value: 'Vajargah' },
  { label: 'Vajol La', value: 'Vajol La' },
  { label: 'Vajont', value: 'Vajont' },
  { label: 'Vajszló', value: 'Vajszló' },
  { label: 'Vakarai', value: 'Vakarai' },
  { label: 'Vakfıkebir', value: 'Vakfıkebir' },
  { label: 'Vakhrushev', value: 'Vakhrushev' },
  { label: 'Vakhrushevo', value: 'Vakhrushevo' },
  { label: 'Vakhrushi', value: 'Vakhrushi' },
  { label: 'Vakhsh', value: 'Vakhsh' },
  { label: 'Vakhtan', value: 'Vakhtan' },
  { label: 'Vaksdal', value: 'Vaksdal' },
  { label: 'Vaksince', value: 'Vaksince' },
  { label: 'Vál', value: 'Vál' },
  { label: 'Val De San Lorenzo', value: 'Val De San Lorenzo' },
  { label: 'Val De San Martín', value: 'Val De San Martín' },
  { label: 'Val De San Vicente', value: 'Val De San Vicente' },
  { label: 'Val Della Torre', value: 'Val Della Torre' },
  { label: 'Val Di Cava', value: 'Val Di Cava' },
  { label: 'Val Do Dubra', value: 'Val Do Dubra' },
  { label: 'Val Masino', value: 'Val Masino' },
  { label: 'Val Rezzo', value: 'Val Rezzo' },
  { label: 'Val Thorens', value: 'Val Thorens' },
  { label: 'Val Verde', value: 'Val Verde' },
  { label: 'Val Verde County', value: 'Val Verde County' },
  { label: 'Val Verde Park', value: 'Val Verde Park' },
  { label: 'Valacloche', value: 'Valacloche' },
  { label: 'Valadares', value: 'Valadares' },
  { label: 'Valadeces', value: 'Valadeces' },
  { label: 'Valado De Frades', value: 'Valado De Frades' },
  { label: 'Valadouro O', value: 'Valadouro O' },
  { label: 'Valady', value: 'Valady' },
  { label: 'Valandovo', value: 'Valandovo' },
  { label: 'Valangaiman', value: 'Valangaiman' },
  { label: 'Valašská Bystřice', value: 'Valašská Bystřice' },
  { label: 'Valašská Polanka', value: 'Valašská Polanka' },
  { label: 'Valašské Klobouky', value: 'Valašské Klobouky' },
  { label: 'Valašské Meziříčí', value: 'Valašské Meziříčí' },
  { label: 'Valatie', value: 'Valatie' },
  { label: 'Valavanur', value: 'Valavanur' },
  { label: 'Valbandon', value: 'Valbandon' },
  { label: 'Vålberg', value: 'Vålberg' },
  { label: 'Valbo', value: 'Valbo' },
  { label: 'Valbom', value: 'Valbom' },
  { label: 'Valbom', value: 'Valbom' },
  { label: 'Valbona', value: 'Valbona' },
  { label: 'Valbonne', value: 'Valbonne' },
  { label: 'Valbrona', value: 'Valbrona' },
  { label: 'Valbuena De Duero', value: 'Valbuena De Duero' },
  { label: 'Valbuena De Pisuerga', value: 'Valbuena De Pisuerga' },
  { label: 'Valburg', value: 'Valburg' },
  { label: 'Valcabado', value: 'Valcabado' },
  { label: 'Vâlcăneşti', value: 'Vâlcăneşti' },
  { label: 'Vălcani', value: 'Vălcani' },
  { label: 'Valcanneto', value: 'Valcanneto' },
  { label: 'Valcău De Jos', value: 'Valcău De Jos' },
  { label: 'Vâlcele', value: 'Vâlcele' },
  { label: 'Vâlcele', value: 'Vâlcele' },
  { label: 'Vâlcele', value: 'Vâlcele' },
  { label: 'Vâlcele', value: 'Vâlcele' },
  { label: 'Vâlcelele', value: 'Vâlcelele' },
  { label: 'Vâlcelele', value: 'Vâlcelele' },
  { label: 'Vâlcelele De Sus', value: 'Vâlcelele De Sus' },
  { label: 'Valchedram', value: 'Valchedram' },
  { label: 'Valcheta', value: 'Valcheta' },
  { label: 'Valchidol', value: 'Valchidol' },
  { label: 'Valcourt', value: 'Valcourt' },
  { label: 'Val-D’Isère', value: 'Val-D’Isère' },
  { label: 'Valda', value: 'Valda' },
  { label: 'Valdagno', value: 'Valdagno' },
  { label: 'Valdahon', value: 'Valdahon' },
  { label: 'Valdáliga', value: 'Valdáliga' },
  { label: 'Valdaora Di Mezzo', value: 'Valdaora Di Mezzo' },
  { label: 'Valdaracete', value: 'Valdaracete' },
  { label: 'Valdarachas', value: 'Valdarachas' },
  { label: 'Valdastillas', value: 'Valdastillas' },
  { label: 'Val-David', value: 'Val-David' },
  { label: 'Valday', value: 'Valday' },
  { label: 'Valdayskiy Rayon', value: 'Valdayskiy Rayon' },
  { label: 'Valdealgorfa', value: 'Valdealgorfa' },
  { label: 'Valdeande', value: 'Valdeande' },
  { label: 'Valdearcos De La Vega', value: 'Valdearcos De La Vega' },
  { label: 'Valdearenas', value: 'Valdearenas' },
  { label: 'Valdeavellano', value: 'Valdeavellano' },
  { label: 'Valdeavellano De Tera', value: 'Valdeavellano De Tera' },
  { label: 'Valdeavero', value: 'Valdeavero' },
  { label: 'Valdeaveruelo', value: 'Valdeaveruelo' },
  { label: 'Valdecaballeros', value: 'Valdecaballeros' },
  { label: 'Valdecañas De Tajo', value: 'Valdecañas De Tajo' },
  { label: 'Valdecarros', value: 'Valdecarros' },
  { label: 'Valdecasa', value: 'Valdecasa' },
  { label: 'Valdecolmenas Los', value: 'Valdecolmenas Los' },
  { label: 'Valdeconcha', value: 'Valdeconcha' },
  { label: 'Valdecuenca', value: 'Valdecuenca' },
  { label: 'Valdefinjas', value: 'Valdefinjas' },
  { label: 'Valdeflores', value: 'Valdeflores' },
  { label: 'Valdefresno', value: 'Valdefresno' },
  { label: 'Valdefuentes', value: 'Valdefuentes' },
  { label: 'Valdefuentes De Sangusín', value: 'Valdefuentes De Sangusín' },
  { label: 'Valdefuentes Del Páramo', value: 'Valdefuentes Del Páramo' },
  { label: 'Valdeganga', value: 'Valdeganga' },
  { label: 'Valdegeña', value: 'Valdegeña' },
  { label: 'Valdegovía/Gaubea', value: 'Valdegovía/Gaubea' },
  { label: 'Valdegrudas', value: 'Valdegrudas' },
  { label: 'Valdehijaderos', value: 'Valdehijaderos' },
  { label: 'Valdehorna', value: 'Valdehorna' },
  { label: 'Valdehúncar', value: 'Valdehúncar' },
  { label: 'Valdelacalzada', value: 'Valdelacalzada' },
  { label: 'Valdelacasa', value: 'Valdelacasa' },
  { label: 'Valdelacasa De Tajo', value: 'Valdelacasa De Tajo' },
  { label: 'Valdelageve', value: 'Valdelageve' },
  { label: 'Valdelagua Del Cerro', value: 'Valdelagua Del Cerro' },
  { label: 'Valdelaguna', value: 'Valdelaguna' },
  { label: 'Valdelarco', value: 'Valdelarco' },
  { label: 'Valdelcubo', value: 'Valdelcubo' },
  { label: 'Valdelinares', value: 'Valdelinares' },
  { label: 'Valdelosa', value: 'Valdelosa' },
  { label: 'Valdeltormo', value: 'Valdeltormo' },
  { label: 'Valdelugueros', value: 'Valdelugueros' },
  { label: 'Valdemadera', value: 'Valdemadera' },
  { label: 'Valdemaluque', value: 'Valdemaluque' },
  { label: 'Valdemanco', value: 'Valdemanco' },
  { label: 'Valdemanco Del Esteras', value: 'Valdemanco Del Esteras' },
  { label: 'Valdemaqueda', value: 'Valdemaqueda' },
  { label: 'Val-De-Marne', value: 'Val-De-Marne' },
  { label: 'Valdemārpils', value: 'Valdemārpils' },
  { label: 'Valdemarsvik', value: 'Valdemarsvik' },
  { label: 'Valdemeca', value: 'Valdemeca' },
  { label: 'Val-De-Meuse', value: 'Val-De-Meuse' },
  { label: 'Valdemierque', value: 'Valdemierque' },
  { label: 'Valdemora', value: 'Valdemora' },
  { label: 'Valdemorales', value: 'Valdemorales' },
  { label: 'Valdemorillo', value: 'Valdemorillo' },
  { label: 'Valdemorillo De La Sierra', value: 'Valdemorillo De La Sierra' },
  { label: 'Valdemoro', value: 'Valdemoro' },
  { label: 'Valdemoro-Sierra', value: 'Valdemoro-Sierra' },
  { label: 'Valdenebro', value: 'Valdenebro' },
  { label: 'Valdenebro De Los Valles', value: 'Valdenebro De Los Valles' },
  { label: 'Valdengo', value: 'Valdengo' },
  { label: 'Valdenuño Fernández', value: 'Valdenuño Fernández' },
  { label: 'Valdeobispo', value: 'Valdeobispo' },
  { label: 'Valdeolea', value: 'Valdeolea' },
  { label: 'Valdeolivas', value: 'Valdeolivas' },
  { label: 'Valdeolmillos', value: 'Valdeolmillos' },
  { label: 'Valdeolmos', value: 'Valdeolmos' },
  { label: 'Valdeolmos-Alalpardo', value: 'Valdeolmos-Alalpardo' },
  { label: 'Valdepeñas', value: 'Valdepeñas' },
  { label: 'Valdepeñas De Jaén', value: 'Valdepeñas De Jaén' },
  { label: 'Valdepeñas De La Sierra', value: 'Valdepeñas De La Sierra' },
  { label: 'Valdepiélago', value: 'Valdepiélago' },
  { label: 'Valdepiélagos', value: 'Valdepiélagos' },
  { label: 'Valdepolo', value: 'Valdepolo' },
  { label: 'Valdeprado', value: 'Valdeprado' },
  { label: 'Valdeprado Del Río', value: 'Valdeprado Del Río' },
  { label: 'Valdeprados', value: 'Valdeprados' },
  { label: 'Valderas', value: 'Valderas' },
  { label: 'Val-De-Reuil', value: 'Val-De-Reuil' },
  { label: 'Valderice', value: 'Valderice' },
  { label: 'Valderrábano', value: 'Valderrábano' },
  { label: 'Valderrama', value: 'Valderrama' },
  { label: 'Valderrebollo', value: 'Valderrebollo' },
  { label: 'Valderredible', value: 'Valderredible' },
  { label: 'Valderrey', value: 'Valderrey' },
  { label: 'Valderrobres', value: 'Valderrobres' },
  { label: 'Valderrodilla', value: 'Valderrodilla' },
  { label: 'Valderrodrigo', value: 'Valderrodrigo' },
  { label: 'Valderrueda', value: 'Valderrueda' },
  { label: 'Val-De-Ruz District', value: 'Val-De-Ruz District' },
  { label: 'Valdés', value: 'Valdés' },
  { label: 'Valdesamario', value: 'Valdesamario' },
  { label: 'Valdescorriel', value: 'Valdescorriel' },
  { label: 'Valdese', value: 'Valdese' },
  { label: 'Val-Des-Monts', value: 'Val-Des-Monts' },
  { label: 'Valdesotos', value: 'Valdesotos' },
  { label: 'Valdestillas', value: 'Valdestillas' },
  { label: 'Valdetorres', value: 'Valdetorres' },
  { label: 'Valdetorres De Jarama', value: 'Valdetorres De Jarama' },
  { label: 'Valdetórtola', value: 'Valdetórtola' },
  { label: 'Val-De-Travers District', value: 'Val-De-Travers District' },
  { label: 'Valde-Ucieza', value: 'Valde-Ucieza' },
  { label: 'Valdevacas De Montejo', value: 'Valdevacas De Montejo' },
  { label: 'Valdevacas Y Guijar', value: 'Valdevacas Y Guijar' },
  { label: 'Valdeverdeja', value: 'Valdeverdeja' },
  { label: 'Valdevimbre', value: 'Valdevimbre' },
  { label: 'Valdez', value: 'Valdez' },
  { label: 'Valdez', value: 'Valdez' },
  { label: 'Valdezate', value: 'Valdezate' },
  { label: 'Valdez-Cordova Census Area', value: 'Valdez-Cordova Census Area' },
  { label: 'Valdice', value: 'Valdice' },
  { label: 'Valdice', value: 'Valdice' },
  { label: 'Valdieri', value: 'Valdieri' },
  { label: 'Valdilecha', value: 'Valdilecha' },
  { label: 'Valdina', value: 'Valdina' },
  { label: 'Valdisotto', value: 'Valdisotto' },
  { label: 'Valdivia', value: 'Valdivia' },
  { label: 'Valdivia', value: 'Valdivia' },
  { label: 'Valdobbiadene', value: 'Valdobbiadene' },
  { label: 'Valdoie', value: 'Valdoie' },
  { label: 'Val-Dor', value: 'Val-Dor' },
  { label: 'Valdorros', value: 'Valdorros' },
  { label: 'Valdosta', value: 'Valdosta' },
  { label: 'Valdottavo', value: 'Valdottavo' },
  { label: 'Valdoviño', value: 'Valdoviño' },
  { label: 'Valduggia', value: 'Valduggia' },
  { label: 'Valdujo', value: 'Valdujo' },
  { label: 'Valdunciel', value: 'Valdunciel' },
  { label: 'Valdunquillo', value: 'Valdunquillo' },
  { label: 'Vale', value: 'Vale' },
  { label: 'Vale', value: 'Vale' },
  { label: 'Vale', value: 'Vale' },
  { label: 'Vale Da Amoreira', value: 'Vale Da Amoreira' },
  { label: 'Vale Da Mula', value: 'Vale Da Mula' },
  { label: 'Vale De Amoreira', value: 'Vale De Amoreira' },
  { label: 'Vale De Azares', value: 'Vale De Azares' },
  { label: 'Vale De Cambra', value: 'Vale De Cambra' },
  { label: 'Vale De Espinho', value: 'Vale De Espinho' },
  { label: 'Vale De Estrela', value: 'Vale De Estrela' },
  { label: 'Vale De Figueira', value: 'Vale De Figueira' },
  { label: 'Vale De Santarém', value: 'Vale De Santarém' },
  { label: 'Vale De São Domingos', value: 'Vale De São Domingos' },
  { label: 'Vale Do Anari', value: 'Vale Do Anari' },
  { label: 'Vale Do Paraíso', value: 'Vale Do Paraíso' },
  { label: 'Vale Do Paraíso', value: 'Vale Do Paraíso' },
  { label: 'Vale Do Seixo', value: 'Vale Do Seixo' },
  { label: 'Vale Do Sol', value: 'Vale Do Sol' },
  { label: 'Vale Flor', value: 'Vale Flor' },
  { label: 'Vale Of Glamorgan', value: 'Vale Of Glamorgan' },
  { label: 'Vale Of Leven', value: 'Vale Of Leven' },
  { label: 'Vale Park', value: 'Vale Park' },
  { label: 'Vale Real', value: 'Vale Real' },
  { label: 'Vale Verde', value: 'Vale Verde' },
  { label: 'Valea', value: 'Valea' },
  { label: 'Valea Albeștiului', value: 'Valea Albeștiului' },
  { label: 'Valea Argovei', value: 'Valea Argovei' },
  { label: 'Valea Arini', value: 'Valea Arini' },
  { label: 'Valea Arinilor', value: 'Valea Arinilor' },
  { label: 'Valea Bolvașnița', value: 'Valea Bolvașnița' },
  { label: 'Valea Borcutului', value: 'Valea Borcutului' },
  { label: 'Valea Călugărească', value: 'Valea Călugărească' },
  { label: 'Valea Cânepii', value: 'Valea Cânepii' },
  { label: 'Valea Caselor', value: 'Valea Caselor' },
  { label: 'Valea Chioarului', value: 'Valea Chioarului' },
  { label: 'Valea Ciorii', value: 'Valea Ciorii' },
  { label: 'Valea Cireșului', value: 'Valea Cireșului' },
  { label: 'Valea Crişului', value: 'Valea Crişului' },
  { label: 'Valea Cucului', value: 'Valea Cucului' },
  { label: 'Valea Cufundoasă', value: 'Valea Cufundoasă' },
  { label: 'Valea Dacilor', value: 'Valea Dacilor' },
  { label: 'Valea Dăii', value: 'Valea Dăii' },
  { label: 'Valea Danului', value: 'Valea Danului' },
  { label: 'Valea Dragului', value: 'Valea Dragului' },
  { label: 'Valea Dulce', value: 'Valea Dulce' },
  { label: 'Valea Frăției', value: 'Valea Frăției' },
  { label: 'Valea Glodului', value: 'Valea Glodului' },
  { label: 'Valea Grecului', value: 'Valea Grecului' },
  { label: 'Valea Hotarului', value: 'Valea Hotarului' },
  { label: 'Valea Iaşului', value: 'Valea Iaşului' },
  { label: 'Valea Iclandului', value: 'Valea Iclandului' },
  { label: 'Valea Ierii', value: 'Valea Ierii' },
  { label: 'Valea Izvoarelor', value: 'Valea Izvoarelor' },
  { label: 'Valea Largă', value: 'Valea Largă' },
  { label: 'Valea Leurzii', value: 'Valea Leurzii' },
  { label: 'Valea Lui Ion', value: 'Valea Lui Ion' },
  { label: 'Valea Lui Mihai', value: 'Valea Lui Mihai' },
  { label: 'Valea Lungă Alba Romania', value: 'Valea Lungă Alba Romania' },
  { label: 'Valea Lungă Ogrea', value: 'Valea Lungă Ogrea' },
  { label: 'Valea Lungă-Cricov', value: 'Valea Lungă-Cricov' },
  { label: 'Valea Lupului', value: 'Valea Lupului' },
  { label: 'Valea Măcrișului', value: 'Valea Măcrișului' },
  { label: 'Valea Mare', value: 'Valea Mare' },
  { label: 'Valea Mare', value: 'Valea Mare' },
  { label: 'Valea Mare', value: 'Valea Mare' },
  { label: 'Valea Mare', value: 'Valea Mare' },
  { label: 'Valea Mare', value: 'Valea Mare' },
  { label: 'Valea Mare', value: 'Valea Mare' },
  { label: 'Valea Mare Pravăț', value: 'Valea Mare Pravăț' },
  { label: 'Valea Mare-Podgoria', value: 'Valea Mare-Podgoria' },
  { label: 'Valea Mărului', value: 'Valea Mărului' },
  { label: 'Valea Merilor', value: 'Valea Merilor' },
  { label: 'Valea Moldovei', value: 'Valea Moldovei' },
  { label: 'Valea Neagră', value: 'Valea Neagră' },
  { label: 'Valea Nucarilor', value: 'Valea Nucarilor' },
  { label: 'Valea Pădurii', value: 'Valea Pădurii' },
  { label: 'Valea Părului', value: 'Valea Părului' },
  { label: 'Valea Părului', value: 'Valea Părului' },
  { label: 'Valea Popii', value: 'Valea Popii' },
  { label: 'Valea Râmnicului', value: 'Valea Râmnicului' },
  { label: 'Valea Rece', value: 'Valea Rece' },
  { label: 'Valea Rece', value: 'Valea Rece' },
  { label: 'Valea Roșie', value: 'Valea Roșie' },
  { label: 'Valea Salciei', value: 'Valea Salciei' },
  { label: 'Valea Sânmărtinului', value: 'Valea Sânmărtinului' },
  { label: 'Valea Sânpetrului', value: 'Valea Sânpetrului' },
  { label: 'Valea Șapartocului', value: 'Valea Șapartocului' },
  { label: 'Valea Sării', value: 'Valea Sării' },
  { label: 'Valea Sasului', value: 'Valea Sasului' },
  { label: 'Valea Seacă', value: 'Valea Seacă' },
  { label: 'Valea Seacă', value: 'Valea Seacă' },
  { label: 'Valea Seacă', value: 'Valea Seacă' },
  { label: 'Valea Seacă', value: 'Valea Seacă' },
  { label: 'Valea Seacă', value: 'Valea Seacă' },
  { label: 'Valea Șoșii', value: 'Valea Șoșii' },
  { label: 'Valea Stanciului', value: 'Valea Stanciului' },
  { label: 'Valea Stejarului', value: 'Valea Stejarului' },
  { label: 'Valea Strâmbă', value: 'Valea Strâmbă' },
  { label: 'Valea Șurii', value: 'Valea Șurii' },
  { label: 'Valea Târsei', value: 'Valea Târsei' },
  { label: 'Valea Teilor', value: 'Valea Teilor' },
  { label: 'Valea Ulieșului', value: 'Valea Ulieșului' },
  { label: 'Valea Ungurului', value: 'Valea Ungurului' },
  { label: 'Valea Urieșului', value: 'Valea Urieșului' },
  { label: 'Valea Ursului', value: 'Valea Ursului' },
  { label: 'Valea Viilor', value: 'Valea Viilor' },
  { label: 'Valea Vinului', value: 'Valea Vinului' },
  { label: 'Valea Vișeului', value: 'Valea Vișeului' },
  { label: 'Valea Voievozilor', value: 'Valea Voievozilor' },
  { label: 'Válega', value: 'Válega' },
  { label: 'Valeggio', value: 'Valeggio' },
  { label: 'Valeggio Sul Mincio', value: 'Valeggio Sul Mincio' },
  { label: 'Valença', value: 'Valença' },
  { label: 'Valença', value: 'Valença' },
  { label: 'Valença', value: 'Valença' },
  { label: 'Valença Do Piauí', value: 'Valença Do Piauí' },
  { label: 'Valençay', value: 'Valençay' },
  { label: 'Valence', value: 'Valence' },
  { label: 'Valence', value: 'Valence' },
  { label: 'Valence-D’Albigeois', value: 'Valence-D’Albigeois' },
  { label: 'Valence-Sur-Baïse', value: 'Valence-Sur-Baïse' },
  { label: 'Valencia', value: 'Valencia' },
  { label: 'Valencia', value: 'Valencia' },
  { label: 'Valencia', value: 'Valencia' },
  { label: 'Valencia', value: 'Valencia' },
  { label: 'Valencia', value: 'Valencia' },
  { label: 'Valencia', value: 'Valencia' },
  { label: 'Valencia', value: 'Valencia' },
  { label: 'Valencia', value: 'Valencia' },
  { label: 'Valencia County', value: 'Valencia County' },
  { label: 'Valencia De Alcántara', value: 'Valencia De Alcántara' },
  { label: 'Valencia De Cerro Gordo', value: 'Valencia De Cerro Gordo' },
  { label: 'Valencia De Don Juan', value: 'Valencia De Don Juan' },
  { label: 'Valencia De Las Torres', value: 'Valencia De Las Torres' },
  { label: 'Valencia Del Mombuey', value: 'Valencia Del Mombuey' },
  { label: 'Valencia Del Ventoso', value: 'Valencia Del Ventoso' },
  { label: 'Valencia West', value: 'Valencia West' },
  { label: 'Valencianita', value: 'Valencianita' },
  { label: 'Valenciennes', value: 'Valenciennes' },
  { label: 'Valencin', value: 'Valencin' },
  { label: 'Valencina De La Concepción', value: 'Valencina De La Concepción' },
  { label: 'Valendries', value: 'Valendries' },
  { label: 'Văleni', value: 'Văleni' },
  { label: 'Văleni', value: 'Văleni' },
  { label: 'Văleni', value: 'Văleni' },
  { label: 'Văleni', value: 'Văleni' },
  { label: 'Văleni', value: 'Văleni' },
  { label: 'Văleni-Dâmbovița', value: 'Văleni-Dâmbovița' },
  { label: 'Vălenii', value: 'Vălenii' },
  { label: 'Vălenii De Munte', value: 'Vălenii De Munte' },
  { label: 'Vălenii De Mureș', value: 'Vălenii De Mureș' },
  { label: 'Vălenii Lăpușului', value: 'Vălenii Lăpușului' },
  { label: 'Vălenii Șomcutei', value: 'Vălenii Șomcutei' },
  { label: 'Văleni-Podgoria', value: 'Văleni-Podgoria' },
  { label: 'Valensole', value: 'Valensole' },
  { label: 'Valentano', value: 'Valentano' },
  { label: 'Valente', value: 'Valente' },
  { label: 'Valente Diaz', value: 'Valente Diaz' },
  { label: 'Valentigney', value: 'Valentigney' },
  { label: 'Valentim Gentil', value: 'Valentim Gentil' },
  { label: 'Valentín Gómez Farías', value: 'Valentín Gómez Farías' },
  { label: 'Valentine', value: 'Valentine' },
  { label: 'Valentine', value: 'Valentine' },
  { label: 'Valentino', value: 'Valentino' },
  { label: 'Valenton', value: 'Valenton' },
  { label: 'Valenza', value: 'Valenza' },
  { label: 'Valenzano', value: 'Valenzano' },
  { label: 'Valenzatico', value: 'Valenzatico' },
  { label: 'Valenzuela', value: 'Valenzuela' },
  { label: 'Valenzuela', value: 'Valenzuela' },
  { label: 'Valenzuela De Calatrava', value: 'Valenzuela De Calatrava' },
  { label: 'Våler', value: 'Våler' },
  { label: 'Våler', value: 'Våler' },
  { label: 'Valera', value: 'Valera' },
  { label: 'Valera Fratta', value: 'Valera Fratta' },
  { label: 'Valeras Las', value: 'Valeras Las' },
  { label: 'Valergues', value: 'Valergues' },
  { label: 'Valerianovsk', value: 'Valerianovsk' },
  { label: 'Valerik', value: 'Valerik' },
  { label: 'Valerio Trujano', value: 'Valerio Trujano' },
  { label: 'Valero', value: 'Valero' },
  { label: 'Valezim', value: 'Valezim' },
  { label: 'Valfabbrica', value: 'Valfabbrica' },
  { label: 'Valfarta', value: 'Valfarta' },
  { label: 'Valfenera', value: 'Valfenera' },
  { label: 'Valfermoso De Tajuña', value: 'Valfermoso De Tajuña' },
  { label: 'Valff', value: 'Valff' },
  { label: 'Valfin-Lès-Saint-Claude', value: 'Valfin-Lès-Saint-Claude' },
  { label: 'Valfloriana', value: 'Valfloriana' },
  { label: 'Valframbert', value: 'Valframbert' },
  { label: 'Valga', value: 'Valga' },
  { label: 'Valga', value: 'Valga' },
  { label: 'Valganna', value: 'Valganna' },
  { label: 'Valgañón', value: 'Valgañón' },
  { label: 'Valgatara', value: 'Valgatara' },
  { label: 'Valgioie', value: 'Valgioie' },
  { label: 'Valgoglio', value: 'Valgoglio' },
  { label: 'Valgrana', value: 'Valgrana' },
  { label: 'Valgreghentino', value: 'Valgreghentino' },
  { label: 'Valgrisenche', value: 'Valgrisenche' },
  { label: 'Valguarnera Caropepe', value: 'Valguarnera Caropepe' },
  { label: 'Valhalla', value: 'Valhalla' },
  { label: 'Valhelhas', value: 'Valhelhas' },
  { label: 'Valhermoso', value: 'Valhermoso' },
  { label: 'Valhermoso De La Fuente', value: 'Valhermoso De La Fuente' },
  { label: 'Vali Mohammad', value: 'Vali Mohammad' },
  { label: 'Vali Sir Ab', value: 'Vali Sir Ab' },
  { label: 'Valinda', value: 'Valinda' },
  { label: 'Valinhos', value: 'Valinhos' },
  { label: 'Vălişoara', value: 'Vălişoara' },
  { label: 'Vălișoara', value: 'Vălișoara' },
  { label: 'Văliug', value: 'Văliug' },
  { label: 'Valje', value: 'Valje' },
  { label: 'Valjevo', value: 'Valjevo' },
  { label: 'Valjunquera', value: 'Valjunquera' },
  { label: 'Valka', value: 'Valka' },
  { label: 'Valkeakoski', value: 'Valkeakoski' },
  { label: 'Valkenburg', value: 'Valkenburg' },
  { label: 'Valkenburg', value: 'Valkenburg' },
  { label: 'Valkenburg Aan De Geul', value: 'Valkenburg Aan De Geul' },
  { label: 'Valkenswaard', value: 'Valkenswaard' },
  { label: 'Valkkoog', value: 'Valkkoog' },
  { label: 'Valkó', value: 'Valkó' },
  { label: 'Valky', value: 'Valky' },
  { label: 'Vall Dalba', value: 'Vall Dalba' },
  { label: 'Vall Dalcalà La', value: 'Vall Dalcalà La' },
  { label: 'Vall De Almonacid', value: 'Vall De Almonacid' },
  { label: 'Vall De Bianya La', value: 'Vall De Bianya La' },
  { label: 'Vall De Boí La', value: 'Vall De Boí La' },
  { label: 'Vall De Cardós', value: 'Vall De Cardós' },
  { label: 'Vall De Ebo', value: 'Vall De Ebo' },
  { label: 'Vall De Gallinera', value: 'Vall De Gallinera' },
  { label: 'Vall De Laguar La', value: 'Vall De Laguar La' },
  { label: 'Vall Debo La', value: 'Vall Debo La' },
  { label: 'Vall Den Bas La', value: 'Vall Den Bas La' },
  { label: 'Vall Duixó La', value: 'Vall Duixó La' },
  { label: 'Valla', value: 'Valla' },
  { label: 'Vallà', value: 'Vallà' },
  { label: 'Valla Beach', value: 'Valla Beach' },
  { label: 'Vallabh Vidyanagar', value: 'Vallabh Vidyanagar' },
  { label: 'Vallabhipur', value: 'Vallabhipur' },
  { label: 'Vallabrègues', value: 'Vallabrègues' },
  { label: 'Vallada', value: 'Vallada' },
  { label: 'Vallada Agordina', value: 'Vallada Agordina' },
  { label: 'Valladolid', value: 'Valladolid' },
  { label: 'Valladolid', value: 'Valladolid' },
  { label: 'Valladolid', value: 'Valladolid' },
  { label: 'Valladolid', value: 'Valladolid' },
  { label: 'Valladolid', value: 'Valladolid' },
  { label: 'Vallalta', value: 'Vallalta' },
  { label: 'Vallam', value: 'Vallam' },
  { label: 'Vallanca', value: 'Vallanca' },
  { label: 'Vallanzengo', value: 'Vallanzengo' },
  { label: 'Vallarsa', value: 'Vallarsa' },
  { label: 'Vallarta De Bureba', value: 'Vallarta De Bureba' },
  { label: 'Vallat', value: 'Vallat' },
  { label: 'Vallata', value: 'Vallata' },
  { label: 'Vallauris', value: 'Vallauris' },
  { label: 'Vallbona Danoia', value: 'Vallbona Danoia' },
  { label: 'Vallbona De Les Monges', value: 'Vallbona De Les Monges' },
  { label: 'Vallcebre', value: 'Vallcebre' },
  { label: 'Vallclara', value: 'Vallclara' },
  { label: 'Vallda', value: 'Vallda' },
  { label: 'Valldemossa', value: 'Valldemossa' },
  { label: 'Valle Agricola', value: 'Valle Agricola' },
  { label: 'Valle Alto', value: 'Valle Alto' },
  { label: 'Valle Aurina - Ahrntal', value: 'Valle Aurina - Ahrntal' },
  { label: 'Valle Castellana', value: 'Valle Castellana' },
  { label: 'Valle Daosta', value: 'Valle Daosta' },
  { label: 'Valle De Abdalajís', value: 'Valle De Abdalajís' },
  { label: 'Valle De Allende', value: 'Valle De Allende' },
  { label: 'Valle De Altomira El', value: 'Valle De Altomira El' },
  { label: 'Valle De Ángeles', value: 'Valle De Ángeles' },
  { label: 'Valle De Ángeles', value: 'Valle De Ángeles' },
  { label: 'Valle De Banderas', value: 'Valle De Banderas' },
  { label: 'Valle De Bardají', value: 'Valle De Bardají' },
  { label: 'Valle De Bravo', value: 'Valle De Bravo' },
  { label: 'Valle De Cerrato', value: 'Valle De Cerrato' },
  { label: 'Valle De Guadalupe Municipality', value: 'Valle De Guadalupe Municipality' },
  { label: 'Valle De Hecho', value: 'Valle De Hecho' },
  { label: 'Valle De Juárez', value: 'Valle De Juárez' },
  { label: 'Valle De Juárez', value: 'Valle De Juárez' },
  { label: 'Valle De La Pascua', value: 'Valle De La Pascua' },
  { label: 'Valle De La Serena', value: 'Valle De La Serena' },
  { label: 'Valle De Las Navas', value: 'Valle De Las Navas' },
  { label: 'Valle De Lierp', value: 'Valle De Lierp' },
  { label: 'Valle De Lincoln', value: 'Valle De Lincoln' },
  { label: 'Valle De Los Girasoles [Fraccionamiento]', value: 'Valle De Los Girasoles [Fraccionamiento]' },
  { label: 'Valle De Losa', value: 'Valle De Losa' },
  { label: 'Valle De Manzanedo', value: 'Valle De Manzanedo' },
  { label: 'Valle De Matamoros', value: 'Valle De Matamoros' },
  { label: 'Valle De Mena', value: 'Valle De Mena' },
  { label: 'Valle De Oca', value: 'Valle De Oca' },
  { label: 'Valle De San José', value: 'Valle De San José' },
  { label: 'Valle De San Juan', value: 'Valle De San Juan' },
  { label: 'Valle De Santa Ana', value: 'Valle De Santa Ana' },
  { label: 'Valle De Santiago', value: 'Valle De Santiago' },
  { label: 'Valle De Santibáñez', value: 'Valle De Santibáñez' },
  { label: 'Valle De Sedano', value: 'Valle De Sedano' },
  { label: 'Valle De Tabladillo', value: 'Valle De Tabladillo' },
  { label: 'Valle De Tobalina', value: 'Valle De Tobalina' },
  { label: 'Valle De Trápaga-Trapagaran', value: 'Valle De Trápaga-Trapagaran' },
  { label: 'Valle De Valdebezana', value: 'Valle De Valdebezana' },
  { label: 'Valle De Valdelaguna', value: 'Valle De Valdelaguna' },
  { label: 'Valle De Valdelucio', value: 'Valle De Valdelucio' },
  { label: 'Valle De Vaquerías', value: 'Valle De Vaquerías' },
  { label: 'Valle De Vázquez', value: 'Valle De Vázquez' },
  { label: 'Valle De Villaverde', value: 'Valle De Villaverde' },
  { label: 'Valle De Yerri/Deierri', value: 'Valle De Yerri/Deierri' },
  { label: 'Valle De Zamanzas', value: 'Valle De Zamanzas' },
  { label: 'Valle De Zaragoza', value: 'Valle De Zaragoza' },
  { label: 'Valle Del Guamuez', value: 'Valle Del Guamuez' },
  { label: 'Valle Del Retortillo', value: 'Valle Del Retortillo' },
  { label: 'Valle Del Río', value: 'Valle Del Río' },
  { label: 'Valle Del Risco', value: 'Valle Del Risco' },
  { label: 'Valle Del Rosario', value: 'Valle Del Rosario' },
  { label: 'Valle Del Zalabí', value: 'Valle Del Zalabí' },
  { label: 'Valle Dellangelo', value: 'Valle Dellangelo' },
  { label: 'Valle Di Cadore', value: 'Valle Di Cadore' },
  { label: 'Valle Di Casies - Gsies', value: 'Valle Di Casies - Gsies' },
  { label: 'Valle Di Maddaloni', value: 'Valle Di Maddaloni' },
  { label: 'Valle Di Vado', value: 'Valle Di Vado' },
  { label: 'Valle Dorado Inn', value: 'Valle Dorado Inn' },
  { label: 'Valle E Castello', value: 'Valle E Castello' },
  { label: 'Valle El', value: 'Valle El' },
  { label: 'Valle Gran Rey', value: 'Valle Gran Rey' },
  { label: 'Valle Hermoso', value: 'Valle Hermoso' },
  { label: 'Valle Hermoso', value: 'Valle Hermoso' },
  { label: 'Valle Hermoso', value: 'Valle Hermoso' },
  { label: 'Valle Huejúcar (Fraccionamiento Popular) [Fraccionamiento]', value: 'Valle Huejúcar (Fraccionamiento Popular) [Fraccionamiento]' },
  { label: 'Valle Lomellina', value: 'Valle Lomellina' },
  { label: 'Valle Luz', value: 'Valle Luz' },
  { label: 'Valle Martella', value: 'Valle Martella' },
  { label: 'Valle Mosso', value: 'Valle Mosso' },
  { label: 'Valle Nacional', value: 'Valle Nacional' },
  { label: 'Valle Rico', value: 'Valle Rico' },
  { label: 'Valle Salimbene', value: 'Valle Salimbene' },
  { label: 'Valle San Bartolomeo', value: 'Valle San Bartolomeo' },
  { label: 'Valle San Francisco', value: 'Valle San Francisco' },
  { label: 'Valle San Nicolao', value: 'Valle San Nicolao' },
  { label: 'Valle Santa', value: 'Valle Santa' },
  { label: 'Valle Verde', value: 'Valle Verde' },
  { label: 'Valle Vista', value: 'Valle Vista' },
  { label: 'Valle Vista', value: 'Valle Vista' },
  { label: 'Vallebona', value: 'Vallebona' },
  { label: 'Vallecillo', value: 'Vallecillo' },
  { label: 'Vallecillo', value: 'Vallecillo' },
  { label: 'Vallecillo', value: 'Vallecillo' },
  { label: 'Vallecillo El', value: 'Vallecillo El' },
  { label: 'Vallecitos De Zaragoza', value: 'Vallecitos De Zaragoza' },
  { label: 'Vallecorsa', value: 'Vallecorsa' },
  { label: 'Vallecrosia', value: 'Vallecrosia' },
  { label: 'Vallecupa', value: 'Vallecupa' },
  { label: 'Valledolmo', value: 'Valledolmo' },
  { label: 'Valledoria', value: 'Valledoria' },
  { label: 'Valledupar', value: 'Valledupar' },
  { label: 'Vallée Des Prêtres', value: 'Vallée Des Prêtres' },
  { label: 'Vallee Pitot', value: 'Vallee Pitot' },
  { label: 'Vallée-Jonction', value: 'Vallée-Jonction' },
  { label: 'Vallefiorita', value: 'Vallefiorita' },
  { label: 'Valleggia', value: 'Valleggia' },
  { label: 'Vallegrande', value: 'Vallegrande' },
  { label: 'Vallehermosa', value: 'Vallehermosa' },
  { label: 'Vallehermoso', value: 'Vallehermoso' },
  { label: 'Vallehermoso', value: 'Vallehermoso' },
  { label: 'Valleiry', value: 'Valleiry' },
  { label: 'Vallejera', value: 'Vallejera' },
  { label: 'Vallejera De Riofrío', value: 'Vallejera De Riofrío' },
  { label: 'Vallejo', value: 'Vallejo' },
  { label: 'Vallejo (Porvenir Vallejo)', value: 'Vallejo (Porvenir Vallejo)' },
  { label: 'Vallejuelo', value: 'Vallejuelo' },
  { label: 'Vallelado', value: 'Vallelado' },
  { label: 'Vallelonga', value: 'Vallelonga' },
  { label: 'Vallelunga Pratameno', value: 'Vallelunga Pratameno' },
  { label: 'Vallemaggia District', value: 'Vallemaggia District' },
  { label: 'Vallemaio', value: 'Vallemaio' },
  { label: 'Vallenar', value: 'Vallenar' },
  { label: 'Vallendar', value: 'Vallendar' },
  { label: 'Vallensbæk Kommune', value: 'Vallensbæk Kommune' },
  { label: 'Vallensbæk Strand', value: 'Vallensbæk Strand' },
  { label: 'Vallentuna', value: 'Vallentuna' },
  { label: 'Vallepietra', value: 'Vallepietra' },
  { label: 'Vallerano', value: 'Vallerano' },
  { label: 'Valleraugue', value: 'Valleraugue' },
  { label: 'Vallermosa', value: 'Vallermosa' },
  { label: 'Vallerotonda', value: 'Vallerotonda' },
  { label: 'Valleroy', value: 'Valleroy' },
  { label: 'Vallerriquito', value: 'Vallerriquito' },
  { label: 'Valleruela De Pedraza', value: 'Valleruela De Pedraza' },
  { label: 'Valleruela De Sepúlveda', value: 'Valleruela De Sepúlveda' },
  { label: 'Vallés', value: 'Vallés' },
  { label: 'Valles De Palenzuela', value: 'Valles De Palenzuela' },
  { label: 'Vallesa De La Guareña', value: 'Vallesa De La Guareña' },
  { label: 'Vallesaccarda', value: 'Vallesaccarda' },
  { label: 'Vallese', value: 'Vallese' },
  { label: 'Valleseco', value: 'Valleseco' },
  { label: 'Vallet', value: 'Vallet' },
  { label: 'Valletta', value: 'Valletta' },
  { label: 'Valleve', value: 'Valleve' },
  { label: 'Valley', value: 'Valley' },
  { label: 'Valley', value: 'Valley' },
  { label: 'Valley', value: 'Valley' },
  { label: 'Valley', value: 'Valley' },
  { label: 'Valley Center', value: 'Valley Center' },
  { label: 'Valley Center', value: 'Valley Center' },
  { label: 'Valley City', value: 'Valley City' },
  { label: 'Valley Cottage', value: 'Valley Cottage' },
  { label: 'Valley County', value: 'Valley County' },
  { label: 'Valley County', value: 'Valley County' },
  { label: 'Valley County', value: 'Valley County' },
  { label: 'Valley East', value: 'Valley East' },
  { label: 'Valley Falls', value: 'Valley Falls' },
  { label: 'Valley Falls', value: 'Valley Falls' },
  { label: 'Valley Falls', value: 'Valley Falls' },
  { label: 'Valley Glen', value: 'Valley Glen' },
  { label: 'Valley Grande', value: 'Valley Grande' },
  { label: 'Valley Green', value: 'Valley Green' },
  { label: 'Valley Heights', value: 'Valley Heights' },
  { label: 'Valley Hill', value: 'Valley Hill' },
  { label: 'Valley Mills', value: 'Valley Mills' },
  { label: 'Valley Of Peace', value: 'Valley Of Peace' },
  { label: 'Valley Park', value: 'Valley Park' },
  { label: 'Valley Springs', value: 'Valley Springs' },
  { label: 'Valley Station', value: 'Valley Station' },
  { label: 'Valley Stream', value: 'Valley Stream' },
  { label: 'Valley View', value: 'Valley View' },
  { label: 'Valley View', value: 'Valley View' },
  { label: 'Valley View', value: 'Valley View' },
  { label: 'Valleymount', value: 'Valleymount' },
  { label: 'Valleyview', value: 'Valleyview' },
  { label: 'Vallfogona De Balaguer', value: 'Vallfogona De Balaguer' },
  { label: 'Vallfogona De Ripollès', value: 'Vallfogona De Ripollès' },
  { label: 'Vallfogona De Riucorb', value: 'Vallfogona De Riucorb' },
  { label: 'Vallgorguina', value: 'Vallgorguina' },
  { label: 'Valli', value: 'Valli' },
  { label: 'Valli Del Pasubio', value: 'Valli Del Pasubio' },
  { label: 'Vallibona', value: 'Vallibona' },
  { label: 'Vallières', value: 'Vallières' },
  { label: 'Vallinfreda', value: 'Vallinfreda' },
  { label: 'Vallio Terme', value: 'Vallio Terme' },
  { label: 'Valliquerville', value: 'Valliquerville' },
  { label: 'Vallirana', value: 'Vallirana' },
  { label: 'Vall-Llobrega', value: 'Vall-Llobrega' },
  { label: 'Vallmoll', value: 'Vallmoll' },
  { label: 'Vallo Della Lucania', value: 'Vallo Della Lucania' },
  { label: 'Vallo Torinese', value: 'Vallo Torinese' },
  { label: 'Valloire', value: 'Valloire' },
  { label: 'Vallon-En-Sully', value: 'Vallon-En-Sully' },
  { label: 'Vallonga', value: 'Vallonga' },
  { label: 'Vallon-Pont-D’Arc', value: 'Vallon-Pont-D’Arc' },
  { label: 'Vallorbe', value: 'Vallorbe' },
  { label: 'Valloriate', value: 'Valloriate' },
  { label: 'Vallromanes', value: 'Vallromanes' },
  { label: 'Valls', value: 'Valls' },
  { label: 'Valls Daguilar Les', value: 'Valls Daguilar Les' },
  { label: 'Valls De Valira Les', value: 'Valls De Valira Les' },
  { label: 'Valluércanes', value: 'Valluércanes' },
  { label: 'Valmacca', value: 'Valmacca' },
  { label: 'Valmadonna', value: 'Valmadonna' },
  { label: 'Valmadrera-Caserta', value: 'Valmadrera-Caserta' },
  { label: 'Valmadrid', value: 'Valmadrid' },
  { label: 'Valmala', value: 'Valmala' },
  { label: 'Valmala', value: 'Valmala' },
  { label: 'Valmeyer', value: 'Valmeyer' },
  { label: 'Valmiera', value: 'Valmiera' },
  { label: 'Valmojado', value: 'Valmojado' },
  { label: 'Valmondois', value: 'Valmondois' },
  { label: 'Valmont', value: 'Valmont' },
  { label: 'Valmontone', value: 'Valmontone' },
  { label: 'Valmorea', value: 'Valmorea' },
  { label: 'Val-Morin', value: 'Val-Morin' },
  { label: 'Valnegra', value: 'Valnegra' },
  { label: 'Valognes', value: 'Valognes' },
  { label: 'Valongo', value: 'Valongo' },
  { label: 'Valongo', value: 'Valongo' },
  { label: 'Válor', value: 'Válor' },
  { label: 'Valoria La Buena', value: 'Valoria La Buena' },
  { label: 'Valozhyn', value: 'Valozhyn' },
  { label: 'Valpaços', value: 'Valpaços' },
  { label: 'Valpalmas', value: 'Valpalmas' },
  { label: 'Valparai', value: 'Valparai' },
  { label: 'Valparaiso', value: 'Valparaiso' },
  { label: 'Valparaiso', value: 'Valparaiso' },
  { label: 'Valparaíso', value: 'Valparaíso' },
  { label: 'Valparaíso', value: 'Valparaíso' },
  { label: 'Valparaíso', value: 'Valparaíso' },
  { label: 'Valparaíso', value: 'Valparaíso' },
  { label: 'Valparaíso', value: 'Valparaíso' },
  { label: 'Valparaíso De Goiás', value: 'Valparaíso De Goiás' },
  { label: 'Valpelline', value: 'Valpelline' },
  { label: 'Valperga', value: 'Valperga' },
  { label: 'Valpovo', value: 'Valpovo' },
  { label: 'Valpoy', value: 'Valpoy' },
  { label: 'Valprato Soana', value: 'Valprato Soana' },
  { label: 'Valras-Plage', value: 'Valras-Plage' },
  { label: 'Valréas', value: 'Valréas' },
  { label: 'Valrico', value: 'Valrico' },
  { label: 'Valros', value: 'Valros' },
  { label: 'Vals', value: 'Vals' },
  { label: 'Vals Platz', value: 'Vals Platz' },
  { label: 'Valsad', value: 'Valsad' },
  { label: 'Valsalabroso', value: 'Valsalabroso' },
  { label: 'Valsalobre', value: 'Valsalobre' },
  { label: 'Valsamáta', value: 'Valsamáta' },
  { label: 'Valsang', value: 'Valsang' },
  { label: 'Valsavarenche', value: 'Valsavarenche' },
  { label: 'Valseca', value: 'Valseca' },
  { label: 'Valsequillo', value: 'Valsequillo' },
  { label: 'Valsequillo De Gran Canaria', value: 'Valsequillo De Gran Canaria' },
  { label: 'Valsinni', value: 'Valsinni' },
  { label: 'Vals-Les-Bains', value: 'Vals-Les-Bains' },
  { label: 'Vals-Près-Le-Puy', value: 'Vals-Près-Le-Puy' },
  { label: 'Valstagna', value: 'Valstagna' },
  { label: 'Valstrona', value: 'Valstrona' },
  { label: 'Valtablado Del Río', value: 'Valtablado Del Río' },
  { label: 'Valtajeros', value: 'Valtajeros' },
  { label: 'Valteró', value: 'Valteró' },
  { label: 'Valthe', value: 'Valthe' },
  { label: 'Valthermond', value: 'Valthermond' },
  { label: 'Valtice', value: 'Valtice' },
  { label: 'Valtiendas', value: 'Valtiendas' },
  { label: 'Valtierra', value: 'Valtierra' },
  { label: 'Valtierrilla', value: 'Valtierrilla' },
  { label: 'Valtimo', value: 'Valtimo' },
  { label: 'Valtopina', value: 'Valtopina' },
  { label: 'Valtorres', value: 'Valtorres' },
  { label: 'Valtorta', value: 'Valtorta' },
  { label: 'Valtournenche', value: 'Valtournenche' },
  { label: 'Valu Lui Traian', value: 'Valu Lui Traian' },
  { label: 'Vălureni', value: 'Vălureni' },
  { label: 'Valuyki', value: 'Valuyki' },
  { label: 'Valuyskiy Rayon', value: 'Valuyskiy Rayon' },
  { label: 'Valva', value: 'Valva' },
  { label: 'Valvasone', value: 'Valvasone' },
  { label: 'Valvedditturai', value: 'Valvedditturai' },
  { label: 'Valverde', value: 'Valverde' },
  { label: 'Valverde', value: 'Valverde' },
  { label: 'Valverde', value: 'Valverde' },
  { label: 'Valverde', value: 'Valverde' },
  { label: 'Valverde De Alcalá', value: 'Valverde De Alcalá' },
  { label: 'Valverde De Burguillos', value: 'Valverde De Burguillos' },
  { label: 'Valverde De Campos', value: 'Valverde De Campos' },
  { label: 'Valverde De Júcar', value: 'Valverde De Júcar' },
  { label: 'Valverde De La Vera', value: 'Valverde De La Vera' },
  { label: 'Valverde De La Virgen', value: 'Valverde De La Virgen' },
  { label: 'Valverde De Leganés', value: 'Valverde De Leganés' },
  { label: 'Valverde De Llerena', value: 'Valverde De Llerena' },
  { label: 'Valverde De Los Arroyos', value: 'Valverde De Los Arroyos' },
  { label: 'Valverde De Mérida', value: 'Valverde De Mérida' },
  { label: 'Valverde De Valdelacasa', value: 'Valverde De Valdelacasa' },
  { label: 'Valverde Del Camino', value: 'Valverde Del Camino' },
  { label: 'Valverde Del Fresno', value: 'Valverde Del Fresno' },
  { label: 'Valverde Del Majano', value: 'Valverde Del Majano' },
  { label: 'Valverde Vega', value: 'Valverde Vega' },
  { label: 'Valverde-Enrique', value: 'Valverde-Enrique' },
  { label: 'Valverdejo', value: 'Valverdejo' },
  { label: 'Valverdón', value: 'Valverdón' },
  { label: 'Valvestino', value: 'Valvestino' },
  { label: 'Valzemola', value: 'Valzemola' },
  { label: 'Vama', value: 'Vama' },
  { label: 'Vama', value: 'Vama' },
  { label: 'Vama Buzăului', value: 'Vama Buzăului' },
  { label: 'Vamberk', value: 'Vamberk' },
  { label: 'Vambolieri', value: 'Vambolieri' },
  { label: 'Vamdrup', value: 'Vamdrup' },
  { label: 'Vammala', value: 'Vammala' },
  { label: 'Vamo', value: 'Vamo' },
  { label: 'Vamos Tamaulipas', value: 'Vamos Tamaulipas' },
  { label: 'Vámosgyörk', value: 'Vámosgyörk' },
  { label: 'Vámospércs', value: 'Vámospércs' },
  { label: 'Vampula', value: 'Vampula' },
  { label: 'Vamvakófyto', value: 'Vamvakófyto' },
  { label: 'Van', value: 'Van' },
  { label: 'Van Alstyne', value: 'Van Alstyne' },
  { label: 'Van Buren', value: 'Van Buren' },
  { label: 'Van Buren', value: 'Van Buren' },
  { label: 'Van Buren', value: 'Van Buren' },
  { label: 'Van Buren County', value: 'Van Buren County' },
  { label: 'Van Buren County', value: 'Van Buren County' },
  { label: 'Van Buren County', value: 'Van Buren County' },
  { label: 'Van Buren County', value: 'Van Buren County' },
  { label: 'Van Ewijcksluis', value: 'Van Ewijcksluis' },
  { label: 'Van Horn', value: 'Van Horn' },
  { label: 'Van Lear', value: 'Van Lear' },
  { label: 'Van Meter', value: 'Van Meter' },
  { label: 'Van Nest', value: 'Van Nest' },
  { label: 'Van Nuys', value: 'Van Nuys' },
  { label: 'Van Vleck', value: 'Van Vleck' },
  { label: 'Van Wert', value: 'Van Wert' },
  { label: 'Van Wert County', value: 'Van Wert County' },
  { label: 'Van Wyksvlei', value: 'Van Wyksvlei' },
  { label: 'Van Zandt County', value: 'Van Zandt County' },
  { label: 'Vana-Antsla', value: 'Vana-Antsla' },
  { label: 'Vanadjou', value: 'Vanadjou' },
  { label: 'Vanadzor', value: 'Vanadzor' },
  { label: 'Vanak', value: 'Vanak' },
  { label: 'Vanala', value: 'Vanala' },
  { label: 'Vanambouani', value: 'Vanambouani' },
  { label: 'Vanard', value: 'Vanard' },
  { label: 'Vanáton', value: 'Vanáton' },
  { label: 'Vânători', value: 'Vânători' },
  { label: 'Vânători', value: 'Vânători' },
  { label: 'Vânători', value: 'Vânători' },
  { label: 'Vânători', value: 'Vânători' },
  { label: 'Vânători', value: 'Vânători' },
  { label: 'Vânători', value: 'Vânători' },
  { label: 'Vânătorii Mari', value: 'Vânătorii Mari' },
  { label: 'Vânătorii Mici', value: 'Vânătorii Mici' },
  { label: 'Vânători-Neamţ', value: 'Vânători-Neamţ' },
  { label: 'Vanavara', value: 'Vanavara' },
  { label: 'Vance', value: 'Vance' },
  { label: 'Vance County', value: 'Vance County' },
  { label: 'Vanceburg', value: 'Vanceburg' },
  { label: 'Vancleave', value: 'Vancleave' },
  { label: 'Vancouver', value: 'Vancouver' },
  { label: 'Vancouver', value: 'Vancouver' },
  { label: 'Vandalia', value: 'Vandalia' },
  { label: 'Vandalia', value: 'Vandalia' },
  { label: 'Vandalia', value: 'Vandalia' },
  { label: 'Vandalur', value: 'Vandalur' },
  { label: 'Vandans', value: 'Vandans' },
  { label: 'Vandarabad', value: 'Vandarabad' },
  { label: 'Vandavasi', value: 'Vandavasi' },
  { label: 'Vandellòs I Hospitalet De Infant', value: 'Vandellòs I Hospitalet De Infant' },
  { label: 'Vandenberg Air Force Base', value: 'Vandenberg Air Force Base' },
  { label: 'Vandenberg Village', value: 'Vandenberg Village' },
  { label: 'Vander', value: 'Vander' },
  { label: 'Vanderbijlpark', value: 'Vanderbijlpark' },
  { label: 'Vanderburgh County', value: 'Vanderburgh County' },
  { label: 'Vandercook Lake', value: 'Vandercook Lake' },
  { label: 'Vandergrift', value: 'Vandergrift' },
  { label: 'Vanderhoof', value: 'Vanderhoof' },
  { label: 'Vandiver', value: 'Vandiver' },
  { label: 'Vandœuvre-Lès-Nancy', value: 'Vandœuvre-Lès-Nancy' },
  { label: 'Vandœuvres', value: 'Vandœuvres' },
  { label: 'Vandoies - Vintl', value: 'Vandoies - Vintl' },
  { label: 'Vandoies Di Sotto', value: 'Vandoies Di Sotto' },
  { label: 'Vändra', value: 'Vändra' },
  { label: 'Vanegas', value: 'Vanegas' },
  { label: 'Vänersborg', value: 'Vänersborg' },
  { label: 'Vang', value: 'Vang' },
  { label: 'Vangaon', value: 'Vangaon' },
  { label: 'Vangaži', value: 'Vangaži' },
  { label: 'Vangviang', value: 'Vangviang' },
  { label: 'Vani', value: 'Vani' },
  { label: 'Vanier', value: 'Vanier' },
  { label: 'Vanimo', value: 'Vanimo' },
  { label: 'Vanimo Green', value: 'Vanimo Green' },
  { label: 'Vanini', value: 'Vanini' },
  { label: 'Vanino', value: 'Vanino' },
  { label: 'Vaniyambadi', value: 'Vaniyambadi' },
  { label: 'Vânjuleţ', value: 'Vânjuleţ' },
  { label: 'Vânju-Mare', value: 'Vânju-Mare' },
  { label: 'Vank', value: 'Vank' },
  { label: 'Vanløse', value: 'Vanløse' },
  { label: 'Vännäs', value: 'Vännäs' },
  { label: 'Vännäsby', value: 'Vännäsby' },
  { label: 'Vannes', value: 'Vannes' },
  { label: 'Vansa I Fórnols La', value: 'Vansa I Fórnols La' },
  { label: 'Vansbro', value: 'Vansbro' },
  { label: 'Vansda', value: 'Vansda' },
  { label: 'Vanse', value: 'Vanse' },
  { label: 'Vantaa', value: 'Vantaa' },
  { label: 'Vanthli', value: 'Vanthli' },
  { label: 'Vanves', value: 'Vanves' },
  { label: 'Vanylven', value: 'Vanylven' },
  { label: 'Vanzaghello', value: 'Vanzaghello' },
  { label: 'Vanzago', value: 'Vanzago' },
  { label: 'Vanzone', value: 'Vanzone' },
  { label: 'Vápenná', value: 'Vápenná' },
  { label: 'Vapi', value: 'Vapi' },
  { label: 'Vapnyarka', value: 'Vapnyarka' },
  { label: 'Vaprio Dadda', value: 'Vaprio Dadda' },
  { label: 'Vaprio Dagogna', value: 'Vaprio Dagogna' },
  { label: 'Vaquerias', value: 'Vaquerias' },
  { label: 'Var', value: 'Var' },
  { label: 'Vara', value: 'Vara' },
  { label: 'Vara De Rey', value: 'Vara De Rey' },
  { label: 'Varadero', value: 'Varadero' },
  { label: 'Varades', value: 'Varades' },
  { label: 'Vărădia', value: 'Vărădia' },
  { label: 'Vărădia De Mureş', value: 'Vărădia De Mureş' },
  { label: 'Varago', value: 'Varago' },
  { label: 'Vărai', value: 'Vărai' },
  { label: 'Varakļāni', value: 'Varakļāni' },
  { label: 'Varallo', value: 'Varallo' },
  { label: 'Varallo Pombia', value: 'Varallo Pombia' },
  { label: 'Varamin', value: 'Varamin' },
  { label: 'Varanasi', value: 'Varanasi' },
  { label: 'Varangaon', value: 'Varangaon' },
  { label: 'Varangéville', value: 'Varangéville' },
  { label: 'Varano Borghi', value: 'Varano Borghi' },
  { label: 'Varano De Melegari', value: 'Varano De Melegari' },
  { label: 'Varapodio', value: 'Varapodio' },
  { label: 'Varash', value: 'Varash' },
  { label: 'Vărăşti', value: 'Vărăşti' },
  { label: 'Vărăști', value: 'Vărăști' },
  { label: 'Văratec', value: 'Văratec' },
  { label: 'Varavi', value: 'Varavi' },
  { label: 'Varayeneh', value: 'Varayeneh' },
  { label: 'Varazaneh', value: 'Varazaneh' },
  { label: 'Varaždin', value: 'Varaždin' },
  { label: 'Varazze', value: 'Varazze' },
  { label: 'Varberg', value: 'Varberg' },
  { label: 'Vărbila', value: 'Vărbila' },
  { label: 'Vărbilău', value: 'Vărbilău' },
  { label: 'Vårby', value: 'Vårby' },
  { label: 'Varca', value: 'Varca' },
  { label: 'Varchaq', value: 'Varchaq' },
  { label: 'Vârciorog', value: 'Vârciorog' },
  { label: 'Vârciorova', value: 'Vârciorova' },
  { label: 'Varco Sabino', value: 'Varco Sabino' },
  { label: 'Várda', value: 'Várda' },
  { label: 'Vardablur', value: 'Vardablur' },
  { label: 'Vardadzor', value: 'Vardadzor' },
  { label: 'Vardaman', value: 'Vardaman' },
  { label: 'Vardane', value: 'Vardane' },
  { label: 'Vardanjan ', value: 'Vardanjan ' },
  { label: 'Vardavard-E Sofla', value: 'Vardavard-E Sofla' },
  { label: 'Vardavard-E Vosta', value: 'Vardavard-E Vosta' },
  { label: 'Varde', value: 'Varde' },
  { label: 'Varde Kommune', value: 'Varde Kommune' },
  { label: 'Vardenik', value: 'Vardenik' },
  { label: 'Vardenis', value: 'Vardenis' },
  { label: 'Vardø', value: 'Vardø' },
  { label: 'Vare Chiquichuca', value: 'Vare Chiquichuca' },
  { label: 'Varedo', value: 'Varedo' },
  { label: 'Varel', value: 'Varel' },
  { label: 'Varen', value: 'Varen' },
  { label: 'Varena', value: 'Varena' },
  { label: 'Varėna', value: 'Varėna' },
  { label: 'Varengeville-Sur-Mer', value: 'Varengeville-Sur-Mer' },
  { label: 'Varenikovskaya', value: 'Varenikovskaya' },
  { label: 'Varenna', value: 'Varenna' },
  { label: 'Varennes', value: 'Varennes' },
  { label: 'Varennes-Jarcy', value: 'Varennes-Jarcy' },
  { label: 'Varennes-Le-Grand', value: 'Varennes-Le-Grand' },
  { label: 'Varennes-Saint-Sauveur', value: 'Varennes-Saint-Sauveur' },
  { label: 'Varennes-Sur-Allier', value: 'Varennes-Sur-Allier' },
  { label: 'Varennes-Sur-Loire', value: 'Varennes-Sur-Loire' },
  { label: 'Varennes-Sur-Seine', value: 'Varennes-Sur-Seine' },
  { label: 'Varennes-Vauzelles', value: 'Varennes-Vauzelles' },
  { label: 'Vareš', value: 'Vareš' },
  { label: 'Varese', value: 'Varese' },
  { label: 'Varese Ligure', value: 'Varese Ligure' },
  { label: 'Varetz', value: 'Varetz' },
  { label: 'Vârfu Câmpului', value: 'Vârfu Câmpului' },
  { label: 'Vârfuri', value: 'Vârfuri' },
  { label: 'Vârfurile', value: 'Vârfurile' },
  { label: 'Vårgårda', value: 'Vårgårda' },
  { label: 'Vargas', value: 'Vargas' },
  { label: 'Vargas', value: 'Vargas' },
  { label: 'Vargas', value: 'Vargas' },
  { label: 'Vargashi', value: 'Vargashi' },
  { label: 'Vargashinskiy Rayon', value: 'Vargashinskiy Rayon' },
  { label: 'Vărgata', value: 'Vărgata' },
  { label: 'Vargeão', value: 'Vargeão' },
  { label: 'Vargem', value: 'Vargem' },
  { label: 'Vargem', value: 'Vargem' },
  { label: 'Vargem Alegre', value: 'Vargem Alegre' },
  { label: 'Vargem Alta', value: 'Vargem Alta' },
  { label: 'Vargem Bonita', value: 'Vargem Bonita' },
  { label: 'Vargem Bonita', value: 'Vargem Bonita' },
  { label: 'Vargem Grande', value: 'Vargem Grande' },
  { label: 'Vargem Grande Do Rio Pardo', value: 'Vargem Grande Do Rio Pardo' },
  { label: 'Vargem Grande Do Sul', value: 'Vargem Grande Do Sul' },
  { label: 'Vargem Grande Paulista', value: 'Vargem Grande Paulista' },
  { label: 'Várgesztes', value: 'Várgesztes' },
  { label: 'Vârghiş', value: 'Vârghiş' },
  { label: 'Varginha', value: 'Varginha' },
  { label: 'Vargön', value: 'Vargön' },
  { label: 'Varhaug', value: 'Varhaug' },
  { label: 'Vári', value: 'Vári' },
  { label: 'Vári', value: 'Vári' },
  { label: 'Variaş', value: 'Variaş' },
  { label: 'Varilhes', value: 'Varilhes' },
  { label: 'Varin', value: 'Varin' },
  { label: 'Varisella', value: 'Varisella' },
  { label: 'Varjão', value: 'Varjão' },
  { label: 'Varjão De Minas', value: 'Varjão De Minas' },
  { label: 'Varjota', value: 'Varjota' },
  { label: 'Varkala', value: 'Varkala' },
  { label: 'Varkaneh', value: 'Varkaneh' },
  { label: 'Varlamovo', value: 'Varlamovo' },
  { label: 'Vârlezi', value: 'Vârlezi' },
  { label: 'Varmo', value: 'Varmo' },
  { label: 'Varna', value: 'Varna' },
  { label: 'Varna', value: 'Varna' },
  { label: 'Varna', value: 'Varna' },
  { label: 'Varna', value: 'Varna' },
  { label: 'Varnamkhast', value: 'Varnamkhast' },
  { label: 'Värnamo', value: 'Värnamo' },
  { label: 'Varnávas', value: 'Varnávas' },
  { label: 'Varnavino', value: 'Varnavino' },
  { label: 'Varnavinskiy Rayon', value: 'Varnavinskiy Rayon' },
  { label: 'Varnell', value: 'Varnell' },
  { label: 'Varnenskiy Rayon', value: 'Varnenskiy Rayon' },
  { label: 'Varniai', value: 'Varniai' },
  { label: 'Varniţa', value: 'Varniţa' },
  { label: 'Varnița', value: 'Varnița' },
  { label: 'Varnsdorf', value: 'Varnsdorf' },
  { label: 'Varnville', value: 'Varnville' },
  { label: 'Varois-Et-Chaignot', value: 'Varois-Et-Chaignot' },
  { label: 'Városföld', value: 'Városföld' },
  { label: 'Varoška Rijeka', value: 'Varoška Rijeka' },
  { label: 'Várpalota', value: 'Várpalota' },
  { label: 'Várpalotai Járás', value: 'Várpalotai Járás' },
  { label: 'Varqestan', value: 'Varqestan' },
  { label: 'Varrains', value: 'Varrains' },
  { label: 'Varreddes', value: 'Varreddes' },
  { label: 'Varrel', value: 'Varrel' },
  { label: 'Varre-Sai', value: 'Varre-Sai' },
  { label: 'Vars', value: 'Vars' },
  { label: 'Vărşag', value: 'Vărşag' },
  { label: 'Varser', value: 'Varser' },
  { label: 'Varshets', value: 'Varshets' },
  { label: 'Varsi', value: 'Varsi' },
  { label: 'Varsity Lakes', value: 'Varsity Lakes' },
  { label: 'Värska', value: 'Värska' },
  { label: 'Vârşolţ', value: 'Vârşolţ' },
  { label: 'Varsseveld', value: 'Varsseveld' },
  { label: 'Vårsta', value: 'Vårsta' },
  { label: 'Vartej', value: 'Vartej' },
  { label: 'Vârteju', value: 'Vârteju' },
  { label: 'Vârteşcoiu', value: 'Vârteşcoiu' },
  { label: 'Vartholomió', value: 'Vartholomió' },
  { label: 'Varto', value: 'Varto' },
  { label: 'Vârtoapele De Jos', value: 'Vârtoapele De Jos' },
  { label: 'Vârtoapele De Sus', value: 'Vârtoapele De Sus' },
  { label: 'Vârtop', value: 'Vârtop' },
  { label: 'Varva', value: 'Varva' },
  { label: 'Varvásaina', value: 'Varvásaina' },
  { label: 'Varvazin', value: 'Varvazin' },
  { label: 'Vârvoru De Jos', value: 'Vârvoru De Jos' },
  { label: 'Vary', value: 'Vary' },
  { label: 'Varybóbi', value: 'Varybóbi' },
  { label: 'Varzaneh', value: 'Varzaneh' },
  { label: 'Varzaqan', value: 'Varzaqan' },
  { label: 'Várzea', value: 'Várzea' },
  { label: 'Várzea', value: 'Várzea' },
  { label: 'Várzea', value: 'Várzea' },
  { label: 'Várzea Alegre', value: 'Várzea Alegre' },
  { label: 'Várzea Branca', value: 'Várzea Branca' },
  { label: 'Várzea Da Palma', value: 'Várzea Da Palma' },
  { label: 'Várzea Da Roça', value: 'Várzea Da Roça' },
  { label: 'Várzea De Meruge', value: 'Várzea De Meruge' },
  { label: 'Várzea Do Poço', value: 'Várzea Do Poço' },
  { label: 'Várzea Grande', value: 'Várzea Grande' },
  { label: 'Várzea Grande', value: 'Várzea Grande' },
  { label: 'Várzea Nova', value: 'Várzea Nova' },
  { label: 'Várzea Paulista', value: 'Várzea Paulista' },
  { label: 'Varzedo', value: 'Varzedo' },
  { label: 'Varzelândia', value: 'Varzelândia' },
  { label: 'Varzeqān', value: 'Varzeqān' },
  { label: 'Varzi', value: 'Varzi' },
  { label: 'Varzo', value: 'Varzo' },
  { label: 'Varzob', value: 'Varzob' },
  { label: 'Varzob District', value: 'Varzob District' },
  { label: 'Varzy', value: 'Varzy' },
  { label: 'Vas', value: 'Vas' },
  { label: 'Vas’Kovo', value: 'Vas’Kovo' },
  { label: 'Vasa', value: 'Vasa' },
  { label: 'Vășad', value: 'Vășad' },
  { label: 'Vasaj', value: 'Vasaj' },
  { label: 'Vasanello', value: 'Vasanello' },
  { label: 'Vásárosnamény', value: 'Vásárosnamény' },
  { label: 'Vásárosnaményi Járás', value: 'Vásárosnaményi Járás' },
  { label: 'Vasastan', value: 'Vasastan' },
  { label: 'Vasavad', value: 'Vasavad' },
  { label: 'Vaşcău', value: 'Vaşcău' },
  { label: 'Vasco Da Gama', value: 'Vasco Da Gama' },
  { label: 'Vascoveiro', value: 'Vascoveiro' },
  { label: 'Vashi', value: 'Vashi' },
  { label: 'Vashkivtsi', value: 'Vashkivtsi' },
  { label: 'Vashon', value: 'Vashon' },
  { label: 'Vashur', value: 'Vashur' },
  { label: 'Vasia', value: 'Vasia' },
  { label: 'Vasian', value: 'Vasian' },
  { label: 'Vasil’Sursk', value: 'Vasil’Sursk' },
  { label: 'Vasil’Yevo', value: 'Vasil’Yevo' },
  { label: 'Vasil’Yevskiy Mokh', value: 'Vasil’Yevskiy Mokh' },
  { label: 'Vasilaţi', value: 'Vasilaţi' },
  { label: 'Vasileostrovskiy Rayon', value: 'Vasileostrovskiy Rayon' },
  { label: 'Vasilevo', value: 'Vasilevo' },
  { label: 'Vasiliká', value: 'Vasiliká' },
  { label: 'Vasilikí', value: 'Vasilikí' },
  { label: 'Vasilikón', value: 'Vasilikón' },
  { label: 'Vasilivka', value: 'Vasilivka' },
  { label: 'Vasilyevichy', value: 'Vasilyevichy' },
  { label: 'Vasilyevka', value: 'Vasilyevka' },
  { label: 'Vasind', value: 'Vasind' },
  { label: 'Vaskelovo', value: 'Vaskelovo' },
  { label: 'Vaskút', value: 'Vaskút' },
  { label: 'Vasleh', value: 'Vasleh' },
  { label: 'Vasles', value: 'Vasles' },
  { label: 'Vaslui', value: 'Vaslui' },
  { label: 'Vaso', value: 'Vaso' },
  { label: 'Vasoldsberg', value: 'Vasoldsberg' },
  { label: 'Vassalboro', value: 'Vassalboro' },
  { label: 'Vassar', value: 'Vassar' },
  { label: 'Vasse', value: 'Vasse' },
  { label: 'Vasselay', value: 'Vasselay' },
  { label: 'Vassena', value: 'Vassena' },
  { label: 'Vassouras', value: 'Vassouras' },
  { label: 'Vassy', value: 'Vassy' },
  { label: 'Väståboland', value: 'Väståboland' },
  { label: 'Västanfjärd', value: 'Västanfjärd' },
  { label: 'Västerås', value: 'Västerås' },
  { label: 'Västervik', value: 'Västervik' },
  { label: 'Vasto', value: 'Vasto' },
  { label: 'Vastogirardi', value: 'Vastogirardi' },
  { label: 'Västra Frölunda', value: 'Västra Frölunda' },
  { label: 'Västra Hagen', value: 'Västra Hagen' },
  { label: 'Vasudevanallur', value: 'Vasudevanallur' },
  { label: 'Vasvár', value: 'Vasvár' },
  { label: 'Vasvári Járás', value: 'Vasvári Járás' },
  { label: 'Vasylevsky Ostrov', value: 'Vasylevsky Ostrov' },
  { label: 'Vasylivka', value: 'Vasylivka' },
  { label: 'Vasylkiv', value: 'Vasylkiv' },
  { label: 'Vasylkiv Raion', value: 'Vasylkiv Raion' },
  { label: 'Vasyshcheve', value: 'Vasyshcheve' },
  { label: 'Vasyurinskaya', value: 'Vasyurinskaya' },
  { label: 'Vaţa De Jos', value: 'Vaţa De Jos' },
  { label: 'Vatakara', value: 'Vatakara' },
  { label: 'Vataman', value: 'Vataman' },
  { label: 'Vatan', value: 'Vatan' },
  { label: 'Vataša', value: 'Vataša' },
  { label: 'Vătava', value: 'Vătava' },
  { label: 'Vaterstetten', value: 'Vaterstetten' },
  { label: 'Vathí', value: 'Vathí' },
  { label: 'Vathý', value: 'Vathý' },
  { label: 'Vathýlakkos', value: 'Vathýlakkos' },
  { label: 'Vatne', value: 'Vatne' },
  { label: 'Vatra', value: 'Vatra' },
  { label: 'Vatra Dornei', value: 'Vatra Dornei' },
  { label: 'Vatra Moldoviţei', value: 'Vatra Moldoviţei' },
  { label: 'Vatrop', value: 'Vatrop' },
  { label: 'Vattalkundu', value: 'Vattalkundu' },
  { label: 'Vattaro', value: 'Vattaro' },
  { label: 'Vattholma', value: 'Vattholma' },
  { label: 'Vatul', value: 'Vatul' },
  { label: 'Vatutine', value: 'Vatutine' },
  { label: 'Vatutinki', value: 'Vatutinki' },
  { label: 'Vatutino', value: 'Vatutino' },
  { label: 'Vau I Dejës', value: 'Vau I Dejës' },
  { label: 'Vauchrétien', value: 'Vauchrétien' },
  { label: 'Vaucluse', value: 'Vaucluse' },
  { label: 'Vaucouleurs', value: 'Vaucouleurs' },
  { label: 'Vaucresson', value: 'Vaucresson' },
  { label: 'Vauda Canavese Superiore', value: 'Vauda Canavese Superiore' },
  { label: 'Vaudelnay', value: 'Vaudelnay' },
  { label: 'Vaudreuil-Dorion', value: 'Vaudreuil-Dorion' },
  { label: 'Vaudry', value: 'Vaudry' },
  { label: 'Vaughan', value: 'Vaughan' },
  { label: 'Vaughnsfield', value: 'Vaughnsfield' },
  { label: 'Vaugneray', value: 'Vaugneray' },
  { label: 'Vaugrigneuse', value: 'Vaugrigneuse' },
  { label: 'Vauhallan', value: 'Vauhallan' },
  { label: 'Vaujours', value: 'Vaujours' },
  { label: 'Vaulnaveys-Le-Bas', value: 'Vaulnaveys-Le-Bas' },
  { label: 'Vaulnaveys-Le-Haut', value: 'Vaulnaveys-Le-Haut' },
  { label: 'Vaulx-En-Velin', value: 'Vaulx-En-Velin' },
  { label: 'Vaulx-Milieu', value: 'Vaulx-Milieu' },
  { label: 'Vaulx-Vraucourt', value: 'Vaulx-Vraucourt' },
  { label: 'Vaumoise', value: 'Vaumoise' },
  { label: 'Vauréal', value: 'Vauréal' },
  { label: 'Vauvert', value: 'Vauvert' },
  { label: 'Vaux-En-Bugey', value: 'Vaux-En-Bugey' },
  { label: 'Vaux-Le-Pénil', value: 'Vaux-Le-Pénil' },
  { label: 'Vaux-Sur-Mer', value: 'Vaux-Sur-Mer' },
  { label: 'Vaux-Sur-Seine', value: 'Vaux-Sur-Seine' },
  { label: 'Vaux-Sur-Sûre', value: 'Vaux-Sur-Sûre' },
  { label: 'Vavoua', value: 'Vavoua' },
  { label: 'Vavozh', value: 'Vavozh' },
  { label: 'Vavuniya', value: 'Vavuniya' },
  { label: 'Vavuniya District', value: 'Vavuniya District' },
  { label: 'Vaxholm', value: 'Vaxholm' },
  { label: 'Växjö', value: 'Växjö' },
  { label: 'Vay', value: 'Vay' },
  { label: 'Vayalar', value: 'Vayalar' },
  { label: 'Vayk’', value: 'Vayk’' },
  { label: 'Vayrac', value: 'Vayrac' },
  { label: 'Vayres', value: 'Vayres' },
  { label: 'Vazante', value: 'Vazante' },
  { label: 'Vazhiny', value: 'Vazhiny' },
  { label: 'Vázquez', value: 'Vázquez' },
  { label: 'Vázquez De Coronado', value: 'Vázquez De Coronado' },
  { label: 'Vazvan', value: 'Vazvan' },
  { label: 'Vazzano', value: 'Vazzano' },
  { label: 'Vazzola', value: 'Vazzola' },
  { label: 'Včelná', value: 'Včelná' },
  { label: 'Veauche', value: 'Veauche' },
  { label: 'Veazie', value: 'Veazie' },
  { label: 'Veberöd', value: 'Veberöd' },
  { label: 'Vecchiano-Nodica', value: 'Vecchiano-Nodica' },
  { label: 'Vechelde', value: 'Vechelde' },
  { label: 'Vechigen', value: 'Vechigen' },
  { label: 'Vechta', value: 'Vechta' },
  { label: 'Veciana', value: 'Veciana' },
  { label: 'Vecilla La', value: 'Vecilla La' },
  { label: 'Vecindario', value: 'Vecindario' },
  { label: 'Vecinos', value: 'Vecinos' },
  { label: 'Veckenstedt', value: 'Veckenstedt' },
  { label: 'Vecoux', value: 'Vecoux' },
  { label: 'Vecpiebalga', value: 'Vecpiebalga' },
  { label: 'Vecsés', value: 'Vecsés' },
  { label: 'Vecsési Járás', value: 'Vecsési Járás' },
  { label: 'Vecumnieki', value: 'Vecumnieki' },
  { label: 'Vecvārkava', value: 'Vecvārkava' },
  { label: 'Vedano Al Lambro', value: 'Vedano Al Lambro' },
  { label: 'Vedano Olona', value: 'Vedano Olona' },
  { label: 'Vedaraniyam', value: 'Vedaraniyam' },
  { label: 'Vedasandur', value: 'Vedasandur' },
  { label: 'Vedavågen', value: 'Vedavågen' },
  { label: 'Veddasca', value: 'Veddasca' },
  { label: 'Veddige', value: 'Veddige' },
  { label: 'Vedea', value: 'Vedea' },
  { label: 'Vedea', value: 'Vedea' },
  { label: 'Vedea', value: 'Vedea' },
  { label: 'Vedelago', value: 'Vedelago' },
  { label: 'Vedène', value: 'Vedène' },
  { label: 'Vedeno', value: 'Vedeno' },
  { label: 'Vedenskiy Rayon', value: 'Vedenskiy Rayon' },
  { label: 'Vedeseta', value: 'Vedeseta' },
  { label: 'Vedi', value: 'Vedi' },
  { label: 'Vedra', value: 'Vedra' },
  { label: 'Veduggio Con Colzano', value: 'Veduggio Con Colzano' },
  { label: 'Veedersburg', value: 'Veedersburg' },
  { label: 'Veen', value: 'Veen' },
  { label: 'Veendam', value: 'Veendam' },
  { label: 'Veenendaal', value: 'Veenendaal' },
  { label: 'Veenhuizen', value: 'Veenhuizen' },
  { label: 'Veenoord', value: 'Veenoord' },
  { label: 'Vefsn', value: 'Vefsn' },
  { label: 'Vega', value: 'Vega' },
  { label: 'Vega', value: 'Vega' },
  { label: 'Vega Alta', value: 'Vega Alta' },
  { label: 'Vega Alta', value: 'Vega Alta' },
  { label: 'Vega Alta Municipio', value: 'Vega Alta Municipio' },
  { label: 'Vega Baja', value: 'Vega Baja' },
  { label: 'Vega Baja', value: 'Vega Baja' },
  { label: 'Vega De Alatorre', value: 'Vega De Alatorre' },
  { label: 'Vega De Espinareda', value: 'Vega De Espinareda' },
  { label: 'Vega De Infanzones', value: 'Vega De Infanzones' },
  { label: 'Vega De Liébana', value: 'Vega De Liébana' },
  { label: 'Vega De Pas', value: 'Vega De Pas' },
  { label: 'Vega De Ruiponce', value: 'Vega De Ruiponce' },
  { label: 'Vega De San Mateo', value: 'Vega De San Mateo' },
  { label: 'Vega De Santa María', value: 'Vega De Santa María' },
  { label: 'Vega De Tera', value: 'Vega De Tera' },
  { label: 'Vega De Tirados', value: 'Vega De Tirados' },
  { label: 'Vega De Valcarce', value: 'Vega De Valcarce' },
  { label: 'Vega De Valdetronco', value: 'Vega De Valdetronco' },
  { label: 'Vega De Villalobos', value: 'Vega De Villalobos' },
  { label: 'Vega Del Codorno', value: 'Vega Del Codorno' },
  { label: 'Vega Del Rosario', value: 'Vega Del Rosario' },
  { label: 'Vega Del Sol', value: 'Vega Del Sol' },
  { label: 'Vegacervera', value: 'Vegacervera' },
  { label: 'Vegachí', value: 'Vegachí' },
  { label: 'Vegadeo', value: 'Vegadeo' },
  { label: 'Vegalatrave', value: 'Vegalatrave' },
  { label: 'Veganzones', value: 'Veganzones' },
  { label: 'Vegaquemada', value: 'Vegaquemada' },
  { label: 'Vegas De La Soledad Y Soledad Dos', value: 'Vegas De La Soledad Y Soledad Dos' },
  { label: 'Vegas De Matute', value: 'Vegas De Matute' },
  { label: 'Vegas Del Condado', value: 'Vegas Del Condado' },
  { label: 'Vegas Del Genil', value: 'Vegas Del Genil' },
  { label: 'Vegaviana', value: 'Vegaviana' },
  { label: 'Vegesack', value: 'Vegesack' },
  { label: 'Veggiano', value: 'Veggiano' },
  { label: 'Veggia-Villalunga', value: 'Veggia-Villalunga' },
  { label: 'Veghel', value: 'Veghel' },
  { label: 'Veglie', value: 'Veglie' },
  { label: 'Veglio', value: 'Veglio' },
  { label: 'Vegreville', value: 'Vegreville' },
  { label: 'Végueta', value: 'Végueta' },
  { label: 'Veguillas De La Sierra', value: 'Veguillas De La Sierra' },
  { label: 'Veguillas Las', value: 'Veguillas Las' },
  { label: 'Vehmaa', value: 'Vehmaa' },
  { label: 'Veiga A', value: 'Veiga A' },
  { label: 'Veigné', value: 'Veigné' },
  { label: 'Veigy', value: 'Veigy' },
  { label: 'Veilsdorf', value: 'Veilsdorf' },
  { label: 'Veinge', value: 'Veinge' },
  { label: 'Veinte De Noviembre', value: 'Veinte De Noviembre' },
  { label: 'Veinte De Noviembre', value: 'Veinte De Noviembre' },
  { label: 'Veinte De Noviembre', value: 'Veinte De Noviembre' },
  { label: 'Veinte De Noviembre', value: 'Veinte De Noviembre' },
  { label: 'Veinte De Noviembre (Santa Lucía)', value: 'Veinte De Noviembre (Santa Lucía)' },
  { label: 'Veinticinco De Mayo', value: 'Veinticinco De Mayo' },
  { label: 'Veinticinco De Mayo', value: 'Veinticinco De Mayo' },
  { label: 'Veintidós De Febrero', value: 'Veintidós De Febrero' },
  { label: 'Veintiuno De Agosto', value: 'Veintiuno De Agosto' },
  { label: 'Veisiejai', value: 'Veisiejai' },
  { label: 'Veitsbronn', value: 'Veitsbronn' },
  { label: 'Veitsch', value: 'Veitsch' },
  { label: 'Veitshöchheim', value: 'Veitshöchheim' },
  { label: 'Vejalpur', value: 'Vejalpur' },
  { label: 'Vejano', value: 'Vejano' },
  { label: 'Vejbystrand', value: 'Vejbystrand' },
  { label: 'Vejen', value: 'Vejen' },
  { label: 'Vejen Kommune', value: 'Vejen Kommune' },
  { label: 'Vejer De La Frontera', value: 'Vejer De La Frontera' },
  { label: 'Vejle', value: 'Vejle' },
  { label: 'Vejle Kommune', value: 'Vejle Kommune' },
  { label: 'Vejprnice', value: 'Vejprnice' },
  { label: 'Vejprty', value: 'Vejprty' },
  { label: 'Veksø', value: 'Veksø' },
  { label: 'Vel’Sk', value: 'Vel’Sk' },
  { label: 'Vel’Skiy Rayon', value: 'Vel’Skiy Rayon' },
  { label: 'Vela', value: 'Vela' },
  { label: 'Vela', value: 'Vela' },
  { label: 'Vela Luka', value: 'Vela Luka' },
  { label: 'Velada', value: 'Velada' },
  { label: 'Veladero', value: 'Veladero' },
  { label: 'Velagići', value: 'Velagići' },
  { label: 'Velaine-En-Haye', value: 'Velaine-En-Haye' },
  { label: 'Velaines', value: 'Velaines' },
  { label: 'Velamazán', value: 'Velamazán' },
  { label: 'Velankanni', value: 'Velankanni' },
  { label: 'Velardeña', value: 'Velardeña' },
  { label: 'Velars-Sur-Ouche', value: 'Velars-Sur-Ouche' },
  { label: 'Velas', value: 'Velas' },
  { label: 'Velas Maharashtra', value: 'Velas Maharashtra' },
  { label: 'Velasca', value: 'Velasca' },
  { label: 'Velascálvaro', value: 'Velascálvaro' },
  { label: 'Velasco Ibarra', value: 'Velasco Ibarra' },
  { label: 'Velaux', value: 'Velaux' },
  { label: 'Velayos', value: 'Velayos' },
  { label: 'Velázquez', value: 'Velázquez' },
  { label: 'Velbert', value: 'Velbert' },
  { label: 'Velburg', value: 'Velburg' },
  { label: 'Velçan', value: 'Velçan' },
  { label: 'Velda Village', value: 'Velda Village' },
  { label: 'Velda Village Hills', value: 'Velda Village Hills' },
  { label: 'Velden', value: 'Velden' },
  { label: 'Velden Am Wörther See', value: 'Velden Am Wörther See' },
  { label: 'Velden Am Wörthersee', value: 'Velden Am Wörthersee' },
  { label: 'Veldhoven', value: 'Veldhoven' },
  { label: 'Veldhuizen', value: 'Veldhuizen' },
  { label: 'Velefique', value: 'Velefique' },
  { label: 'Velehrad', value: 'Velehrad' },
  { label: 'Velemín', value: 'Velemín' },
  { label: 'Velen', value: 'Velen' },
  { label: 'Velence', value: 'Velence' },
  { label: 'Veles', value: 'Veles' },
  { label: 'Velešín', value: 'Velešín' },
  { label: 'Veleso', value: 'Veleso' },
  { label: 'Velesta', value: 'Velesta' },
  { label: 'Velestíno', value: 'Velestíno' },
  { label: 'Vélez', value: 'Vélez' },
  { label: 'Vélez De Benaudalla', value: 'Vélez De Benaudalla' },
  { label: 'Vélez-Blanco', value: 'Vélez-Blanco' },
  { label: 'Vélez-Málaga', value: 'Vélez-Málaga' },
  { label: 'Vélez-Rubio', value: 'Vélez-Rubio' },
  { label: 'Velezzo Lomellina', value: 'Velezzo Lomellina' },
  { label: 'Velgast', value: 'Velgast' },
  { label: 'Velika', value: 'Velika' },
  { label: 'Velika Gorica', value: 'Velika Gorica' },
  { label: 'Velika Greda', value: 'Velika Greda' },
  { label: 'Velika Kladuša', value: 'Velika Kladuša' },
  { label: 'Velika Kopanica', value: 'Velika Kopanica' },
  { label: 'Velika Mlaka', value: 'Velika Mlaka' },
  { label: 'Velika Moštanica', value: 'Velika Moštanica' },
  { label: 'Velika Obarska', value: 'Velika Obarska' },
  { label: 'Velika Ostrna', value: 'Velika Ostrna' },
  { label: 'Velika Pisanica', value: 'Velika Pisanica' },
  { label: 'Velika Polana', value: 'Velika Polana' },
  { label: 'Velika Rudka', value: 'Velika Rudka' },
  { label: 'Velike Lašče', value: 'Velike Lašče' },
  { label: 'Velikent', value: 'Velikent' },
  { label: 'Veliki Budishcha', value: 'Veliki Budishcha' },
  { label: 'Veliki Drvenik', value: 'Veliki Drvenik' },
  { label: 'Veliki Gaj', value: 'Veliki Gaj' },
  { label: 'Veliki Grđevac', value: 'Veliki Grđevac' },
  { label: 'Velikiy Novgorod', value: 'Velikiy Novgorod' },
  { label: 'Velikiy Ustyug', value: 'Velikiy Ustyug' },
  { label: 'Velikiye Borki', value: 'Velikiye Borki' },
  { label: 'Velikiye Luki', value: 'Velikiye Luki' },
  { label: 'Veliko Središte', value: 'Veliko Središte' },
  { label: 'Veliko Tŭrnovo', value: 'Veliko Tŭrnovo' },
  { label: 'Velikodvorskiy', value: 'Velikodvorskiy' },
  { label: 'Velikolukskiy Rayon', value: 'Velikolukskiy Rayon' },
  { label: 'Velikooktyabr’Skiy', value: 'Velikooktyabr’Skiy' },
  { label: 'Velikopetrovka', value: 'Velikopetrovka' },
  { label: 'Velikoustyugskiy Rayon', value: 'Velikoustyugskiy Rayon' },
  { label: 'Velikovechnoye', value: 'Velikovechnoye' },
  { label: 'Velilla', value: 'Velilla' },
  { label: 'Velilla De Cinca', value: 'Velilla De Cinca' },
  { label: 'Velilla De Ebro', value: 'Velilla De Ebro' },
  { label: 'Velilla De Jiloca', value: 'Velilla De Jiloca' },
  { label: 'Velilla De La Sierra', value: 'Velilla De La Sierra' },
  { label: 'Velilla De Los Ajos', value: 'Velilla De Los Ajos' },
  { label: 'Velilla De San Antonio', value: 'Velilla De San Antonio' },
  { label: 'Velilla Del Río Carrión', value: 'Velilla Del Río Carrión' },
  { label: 'Velim', value: 'Velim' },
  { label: 'Velimirovac', value: 'Velimirovac' },
  { label: 'Vélines', value: 'Vélines' },
  { label: 'Vélingara', value: 'Vélingara' },
  { label: 'Velingrad', value: 'Velingrad' },
  { label: 'Velizh', value: 'Velizh' },
  { label: 'Vélizy-Villacoublay', value: 'Vélizy-Villacoublay' },
  { label: 'Veljusa', value: 'Veljusa' },
  { label: 'Velká Bíteš', value: 'Velká Bíteš' },
  { label: 'Velká Bystřice', value: 'Velká Bystřice' },
  { label: 'Velká Dobrá', value: 'Velká Dobrá' },
  { label: 'Velká Hleďsebe', value: 'Velká Hleďsebe' },
  { label: 'Velká Nad Veličkou', value: 'Velká Nad Veličkou' },
  { label: 'Velká Polom', value: 'Velká Polom' },
  { label: 'Velké Bílovice', value: 'Velké Bílovice' },
  { label: 'Velké Březno', value: 'Velké Březno' },
  { label: 'Velké Hamry', value: 'Velké Hamry' },
  { label: 'Velké Heraltice', value: 'Velké Heraltice' },
  { label: 'Velké Hoštice', value: 'Velké Hoštice' },
  { label: 'Velké Karlovice', value: 'Velké Karlovice' },
  { label: 'Velké Losiny', value: 'Velké Losiny' },
  { label: 'Velké Meziříčí', value: 'Velké Meziříčí' },
  { label: 'Velké Němčice', value: 'Velké Němčice' },
  { label: 'Velké Opatovice', value: 'Velké Opatovice' },
  { label: 'Velké Pavlovice', value: 'Velké Pavlovice' },
  { label: 'Velké Popovice', value: 'Velké Popovice' },
  { label: 'Velké Poříčí', value: 'Velké Poříčí' },
  { label: 'Velké Přílepy', value: 'Velké Přílepy' },
  { label: 'Velké Svatoňovice', value: 'Velké Svatoňovice' },
  { label: 'Velkua', value: 'Velkua' },
  { label: 'Velký Beranov', value: 'Velký Beranov' },
  { label: 'Veľký Krtíš', value: 'Veľký Krtíš' },
  { label: 'Veľký Meder', value: 'Veľký Meder' },
  { label: 'Velký Osek', value: 'Velký Osek' },
  { label: 'Velký Šenov', value: 'Velký Šenov' },
  { label: 'Velký Týnec', value: 'Velký Týnec' },
  { label: 'Velký Újezd', value: 'Velký Újezd' },
  { label: 'Vellanur', value: 'Vellanur' },
  { label: 'Vellberg', value: 'Vellberg' },
  { label: 'Velleron', value: 'Velleron' },
  { label: 'Vellés La', value: 'Vellés La' },
  { label: 'Velletri', value: 'Velletri' },
  { label: 'Vellezzo Bellini', value: 'Vellezzo Bellini' },
  { label: 'Vellinge', value: 'Vellinge' },
  { label: 'Vellisca', value: 'Vellisca' },
  { label: 'Velliza', value: 'Velliza' },
  { label: 'Vellmar', value: 'Vellmar' },
  { label: 'Vellón El', value: 'Vellón El' },
  { label: 'Vellore', value: 'Vellore' },
  { label: 'Velm', value: 'Velm' },
  { label: 'Velneshwar', value: 'Velneshwar' },
  { label: 'Velo', value: 'Velo' },
  { label: 'Vélo', value: 'Vélo' },
  { label: 'Velo Dastico', value: 'Velo Dastico' },
  { label: 'Velo Veronese', value: 'Velo Veronese' },
  { label: 'Velosa', value: 'Velosa' },
  { label: 'Velp', value: 'Velp' },
  { label: 'Velpke', value: 'Velpke' },
  { label: 'Velsen', value: 'Velsen' },
  { label: 'Velsen-Zuid', value: 'Velsen-Zuid' },
  { label: 'Velserbroek', value: 'Velserbroek' },
  { label: 'Velten', value: 'Velten' },
  { label: 'Veltheim', value: 'Veltheim' },
  { label: 'Veltheim', value: 'Veltheim' },
  { label: 'Veltruby', value: 'Veltruby' },
  { label: 'Veltrusy', value: 'Veltrusy' },
  { label: 'Veltum', value: 'Veltum' },
  { label: 'Velturno', value: 'Velturno' },
  { label: 'Velur', value: 'Velur' },
  { label: 'Velva', value: 'Velva' },
  { label: 'Velvary', value: 'Velvary' },
  { label: 'Velventós', value: 'Velventós' },
  { label: 'Velyka Bahachka', value: 'Velyka Bahachka' },
  { label: 'Velyka Bilozerka', value: 'Velyka Bilozerka' },
  { label: 'Velyka Dymerka', value: 'Velyka Dymerka' },
  { label: 'Velyka Lepetykha', value: 'Velyka Lepetykha' },
  { label: 'Velyka Mykhaylivka', value: 'Velyka Mykhaylivka' },
  { label: 'Velyka Oleksandrivka', value: 'Velyka Oleksandrivka' },
  { label: 'Velyka Pysarivka', value: 'Velyka Pysarivka' },
  { label: 'Velyki Berehy', value: 'Velyki Berehy' },
  { label: 'Velyki Kopani', value: 'Velyki Kopani' },
  { label: 'Velyki Korovyntsi', value: 'Velyki Korovyntsi' },
  { label: 'Velyki Mezhyrichi', value: 'Velyki Mezhyrichi' },
  { label: 'Velyki Sorochyntsi', value: 'Velyki Sorochyntsi' },
  { label: 'Velykobilozers’Kyy Rayon', value: 'Velykobilozers’Kyy Rayon' },
  { label: 'Velykoploske', value: 'Velykoploske' },
  { label: 'Velykyi Bereznyi', value: 'Velykyi Bereznyi' },
  { label: 'Velykyi Bereznyi Raion', value: 'Velykyi Bereznyi Raion' },
  { label: 'Velykyy Burluk', value: 'Velykyy Burluk' },
  { label: 'Velykyy Klyuchiv', value: 'Velykyy Klyuchiv' },
  { label: 'Vemalwada', value: 'Vemalwada' },
  { label: 'Vémars', value: 'Vémars' },
  { label: 'Vemasse', value: 'Vemasse' },
  { label: 'Ven', value: 'Ven' },
  { label: 'Venadillo', value: 'Venadillo' },
  { label: 'Venado Tuerto', value: 'Venado Tuerto' },
  { label: 'Venafro', value: 'Venafro' },
  { label: 'Venâncio Aires', value: 'Venâncio Aires' },
  { label: 'Venango County', value: 'Venango County' },
  { label: 'Venansault', value: 'Venansault' },
  { label: 'Venarey-Les-Laumes', value: 'Venarey-Les-Laumes' },
  { label: 'Venaria Reale', value: 'Venaria Reale' },
  { label: 'Venarotta', value: 'Venarotta' },
  { label: 'Venasca', value: 'Venasca' },
  { label: 'Venasque', value: 'Venasque' },
  { label: 'Venaus', value: 'Venaus' },
  { label: 'Vence', value: 'Vence' },
  { label: 'Venceremos', value: 'Venceremos' },
  { label: 'Venchi', value: 'Venchi' },
  { label: 'Vencillón', value: 'Vencillón' },
  { label: 'Venda Do Pinheiro', value: 'Venda Do Pinheiro' },
  { label: 'Venda Do Pinheiro', value: 'Venda Do Pinheiro' },
  { label: 'Venda Nova', value: 'Venda Nova' },
  { label: 'Venda Nova Do Imigrante', value: 'Venda Nova Do Imigrante' },
  { label: 'Vendargues', value: 'Vendargues' },
  { label: 'Vendas Novas', value: 'Vendas Novas' },
  { label: 'Vendat', value: 'Vendat' },
  { label: 'Vendays-Montalivet', value: 'Vendays-Montalivet' },
  { label: 'Vendegies-Sur-Écaillon', value: 'Vendegies-Sur-Écaillon' },
  { label: 'Vendenheim', value: 'Vendenheim' },
  { label: 'Vendeuvre-Du-Poitou', value: 'Vendeuvre-Du-Poitou' },
  { label: 'Vendeuvre-Sur-Barse', value: 'Vendeuvre-Sur-Barse' },
  { label: 'Vendeville', value: 'Vendeville' },
  { label: 'Vendin-Lès-Béthune', value: 'Vendin-Lès-Béthune' },
  { label: 'Vendin-Le-Vieil', value: 'Vendin-Le-Vieil' },
  { label: 'Vendœuvres', value: 'Vendœuvres' },
  { label: 'Vendôme', value: 'Vendôme' },
  { label: 'Vendone', value: 'Vendone' },
  { label: 'Vendrell', value: 'Vendrell' },
  { label: 'Vendrennes', value: 'Vendrennes' },
  { label: 'Vendres', value: 'Vendres' },
  { label: 'Vendrogno', value: 'Vendrogno' },
  { label: 'Vendryně', value: 'Vendryně' },
  { label: 'Vendychany', value: 'Vendychany' },
  { label: 'Venecia', value: 'Venecia' },
  { label: 'Venecia', value: 'Venecia' },
  { label: 'Venecia', value: 'Venecia' },
  { label: 'Venegazzù', value: 'Venegazzù' },
  { label: 'Venegono Inferiore', value: 'Venegono Inferiore' },
  { label: 'Venegono Superiore', value: 'Venegono Superiore' },
  { label: 'Vénéjan', value: 'Vénéjan' },
  { label: 'Venelles', value: 'Venelles' },
  { label: 'Venerque', value: 'Venerque' },
  { label: 'Venersborg', value: 'Venersborg' },
  { label: 'Veneta', value: 'Veneta' },
  { label: 'Venetian Village', value: 'Venetian Village' },
  { label: 'Venetico Marina', value: 'Venetico Marina' },
  { label: 'Venetico Superiore', value: 'Venetico Superiore' },
  { label: 'Venette', value: 'Venette' },
  { label: 'Veneux-Les-Sablons', value: 'Veneux-Les-Sablons' },
  { label: 'Venëv', value: 'Venëv' },
  { label: 'Venevskiy Rayon', value: 'Venevskiy Rayon' },
  { label: 'Venezuela', value: 'Venezuela' },
  { label: 'Venezzano', value: 'Venezzano' },
  { label: 'Vengavasal', value: 'Vengavasal' },
  { label: 'Vengerovo', value: 'Vengerovo' },
  { label: 'Vengurla', value: 'Vengurla' },
  { label: 'Venha-Ver', value: 'Venha-Ver' },
  { label: 'Venhorst', value: 'Venhorst' },
  { label: 'Venhuizen', value: 'Venhuizen' },
  { label: 'Venialbo', value: 'Venialbo' },
  { label: 'Veniano', value: 'Veniano' },
  { label: 'Venice', value: 'Venice' },
  { label: 'Venice', value: 'Venice' },
  { label: 'Venice', value: 'Venice' },
  { label: 'Venice', value: 'Venice' },
  { label: 'Venice Gardens', value: 'Venice Gardens' },
  { label: 'Venilale', value: 'Venilale' },
  { label: 'Venise-En-Québec', value: 'Venise-En-Québec' },
  { label: 'Vénissieux', value: 'Vénissieux' },
  { label: 'Venizel', value: 'Venizel' },
  { label: 'Venkatagiri', value: 'Venkatagiri' },
  { label: 'Venlo', value: 'Venlo' },
  { label: 'Vennecy', value: 'Vennecy' },
  { label: 'Vennesla', value: 'Vennesla' },
  { label: 'Venosa', value: 'Venosa' },
  { label: 'Venoy', value: 'Venoy' },
  { label: 'Venray', value: 'Venray' },
  { label: 'Venta', value: 'Venta' },
  { label: 'Venta De Baños', value: 'Venta De Baños' },
  { label: 'Venta De Bravo', value: 'Venta De Bravo' },
  { label: 'Venta De Ocotillos', value: 'Venta De Ocotillos' },
  { label: 'Venta Del Moro', value: 'Venta Del Moro' },
  { label: 'Venta Grande', value: 'Venta Grande' },
  { label: 'Ventabren', value: 'Ventabren' },
  { label: 'Ventalló', value: 'Ventalló' },
  { label: 'Ventana', value: 'Ventana' },
  { label: 'Ventanas', value: 'Ventanas' },
  { label: 'Ventania', value: 'Ventania' },
  { label: 'Ventaquemada', value: 'Ventaquemada' },
  { label: 'Ventas Con Peña Aguilera Las', value: 'Ventas Con Peña Aguilera Las' },
  { label: 'Ventas De Huelma', value: 'Ventas De Huelma' },
  { label: 'Ventas De Retamosa Las', value: 'Ventas De Retamosa Las' },
  { label: 'Ventas De San Julián Las', value: 'Ventas De San Julián Las' },
  { label: 'Venteira', value: 'Venteira' },
  { label: 'Ventersburg', value: 'Ventersburg' },
  { label: 'Venticano', value: 'Venticano' },
  { label: 'Ventimiglia', value: 'Ventimiglia' },
  { label: 'Ventimiglia Di Sicilia', value: 'Ventimiglia Di Sicilia' },
  { label: 'Ventiseri', value: 'Ventiseri' },
  { label: 'Ventnor', value: 'Ventnor' },
  { label: 'Ventnor City', value: 'Ventnor City' },
  { label: 'Ventoquipa', value: 'Ventoquipa' },
  { label: 'Ventosa', value: 'Ventosa' },
  { label: 'Ventosa', value: 'Ventosa' },
  { label: 'Ventosa', value: 'Ventosa' },
  { label: 'Ventosa De La Cuesta', value: 'Ventosa De La Cuesta' },
  { label: 'Ventosa Del Río Almar', value: 'Ventosa Del Río Almar' },
  { label: 'Ventosilla Y Tejadilla', value: 'Ventosilla Y Tejadilla' },
  { label: 'Ventoso', value: 'Ventoso' },
  { label: 'Ventotene', value: 'Ventotene' },
  { label: 'Ventrosa', value: 'Ventrosa' },
  { label: 'Ventspils', value: 'Ventspils' },
  { label: 'Ventsy', value: 'Ventsy' },
  { label: 'Ventura', value: 'Ventura' },
  { label: 'Ventura County', value: 'Ventura County' },
  { label: 'Venturada', value: 'Venturada' },
  { label: 'Venturina', value: 'Venturina' },
  { label: 'Venturosa', value: 'Venturosa' },
  { label: 'Venus', value: 'Venus' },
  { label: 'Venus', value: 'Venus' },
  { label: 'Venusberg', value: 'Venusberg' },
  { label: 'Venustiano Carranza', value: 'Venustiano Carranza' },
  { label: 'Venustiano Carranza', value: 'Venustiano Carranza' },
  { label: 'Venustiano Carranza', value: 'Venustiano Carranza' },
  { label: 'Venustiano Carranza', value: 'Venustiano Carranza' },
  { label: 'Venustiano Carranza', value: 'Venustiano Carranza' },
  { label: 'Venustiano Carranza', value: 'Venustiano Carranza' },
  { label: 'Venustiano Carranza (Peña Blanca)', value: 'Venustiano Carranza (Peña Blanca)' },
  { label: 'Venzone', value: 'Venzone' },
  { label: 'Vép', value: 'Vép' },
  { label: 'Vepagunta', value: 'Vepagunta' },
  { label: 'Vera', value: 'Vera' },
  { label: 'Vera', value: 'Vera' },
  { label: 'Vera', value: 'Vera' },
  { label: 'Vera Cruz', value: 'Vera Cruz' },
  { label: 'Vera Cruz', value: 'Vera Cruz' },
  { label: 'Vera Cruz', value: 'Vera Cruz' },
  { label: 'Vera Cruz', value: 'Vera Cruz' },
  { label: 'Vera Cruz Do Oeste', value: 'Vera Cruz Do Oeste' },
  { label: 'Vera De Moncayo', value: 'Vera De Moncayo' },
  { label: 'Vera Mendes', value: 'Vera Mendes' },
  { label: 'Veracruz', value: 'Veracruz' },
  { label: 'Veracruz', value: 'Veracruz' },
  { label: 'Veracruz', value: 'Veracruz' },
  { label: 'Veracruz', value: 'Veracruz' },
  { label: 'Veradale', value: 'Veradale' },
  { label: 'Veragua Arriba', value: 'Veragua Arriba' },
  { label: 'Verano', value: 'Verano' },
  { label: 'Verano - Voeran', value: 'Verano - Voeran' },
  { label: 'Verano Brianza', value: 'Verano Brianza' },
  { label: 'Veranópolis', value: 'Veranópolis' },
  { label: 'Verapaz', value: 'Verapaz' },
  { label: 'Veraval', value: 'Veraval' },
  { label: 'Verbania', value: 'Verbania' },
  { label: 'Verberie', value: 'Verberie' },
  { label: 'Verbicaro', value: 'Verbicaro' },
  { label: 'Verbier', value: 'Verbier' },
  { label: 'Verbilki', value: 'Verbilki' },
  { label: 'Verbiţa', value: 'Verbiţa' },
  { label: 'Verbivka', value: 'Verbivka' },
  { label: 'Verblyany', value: 'Verblyany' },
  { label: 'Vercana', value: 'Vercana' },
  { label: 'Verceia', value: 'Verceia' },
  { label: 'Vercelli', value: 'Vercelli' },
  { label: 'Vercel-Villedieu-Le-Camp', value: 'Vercel-Villedieu-Le-Camp' },
  { label: 'Verchères', value: 'Verchères' },
  { label: 'Verchnia Rozhanka', value: 'Verchnia Rozhanka' },
  { label: 'Vercurago', value: 'Vercurago' },
  { label: 'Verd', value: 'Verd' },
  { label: 'Verdal', value: 'Verdal' },
  { label: 'Verde Village', value: 'Verde Village' },
  { label: 'Verdejante', value: 'Verdejante' },
  { label: 'Verdelândia', value: 'Verdelândia' },
  { label: 'Verdellino', value: 'Verdellino' },
  { label: 'Verdello', value: 'Verdello' },
  { label: 'Verden', value: 'Verden' },
  { label: 'Verderio Inferiore', value: 'Verderio Inferiore' },
  { label: 'Verderio Superiore', value: 'Verderio Superiore' },
  { label: 'Verdi', value: 'Verdi' },
  { label: 'Verdigris', value: 'Verdigris' },
  { label: 'Verdú', value: 'Verdú' },
  { label: 'Verdun', value: 'Verdun' },
  { label: 'Verdun', value: 'Verdun' },
  { label: 'Verduno', value: 'Verduno' },
  { label: 'Verdun-Sur-Garonne', value: 'Verdun-Sur-Garonne' },
  { label: 'Verdun-Sur-Le-Doubs', value: 'Verdun-Sur-Le-Doubs' },
  { label: 'Verduron', value: 'Verduron' },
  { label: 'Verê', value: 'Verê' },
  { label: 'Verea', value: 'Verea' },
  { label: 'Vereda', value: 'Vereda' },
  { label: 'Veredinha', value: 'Veredinha' },
  { label: 'Vereeniging', value: 'Vereeniging' },
  { label: 'Verenchanka', value: 'Verenchanka' },
  { label: 'Verendin', value: 'Verendin' },
  { label: 'Veresayevo', value: 'Veresayevo' },
  { label: 'Veresegyház', value: 'Veresegyház' },
  { label: 'Vereshchagino', value: 'Vereshchagino' },
  { label: 'Veresna', value: 'Veresna' },
  { label: 'Vereşti', value: 'Vereşti' },
  { label: 'Véretz', value: 'Véretz' },
  { label: 'Vereya', value: 'Vereya' },
  { label: 'Verfeil', value: 'Verfeil' },
  { label: 'Vergano-Villa', value: 'Vergano-Villa' },
  { label: 'Vergara', value: 'Vergara' },
  { label: 'Vergara', value: 'Vergara' },
  { label: 'Vergato', value: 'Vergato' },
  { label: 'Vergel', value: 'Vergel' },
  { label: 'Vergennes', value: 'Vergennes' },
  { label: 'Verger El', value: 'Verger El' },
  { label: 'Verges', value: 'Verges' },
  { label: 'Vergèze', value: 'Vergèze' },
  { label: 'Verghereto', value: 'Verghereto' },
  { label: 'Vergiate', value: 'Vergiate' },
  { label: 'Vergigny', value: 'Vergigny' },
  { label: 'Vergína', value: 'Vergína' },
  { label: 'Vergnasco', value: 'Vergnasco' },
  { label: 'Vergongheon', value: 'Vergongheon' },
  { label: 'Vergt', value: 'Vergt' },
  { label: 'Verguleasa', value: 'Verguleasa' },
  { label: 'Verín', value: 'Verín' },
  { label: 'Verin Artashat', value: 'Verin Artashat' },
  { label: 'Verin Dvin', value: 'Verin Dvin' },
  { label: 'Verin Getashen', value: 'Verin Getashen' },
  { label: 'Vérines', value: 'Vérines' },
  { label: 'Veringenstadt', value: 'Veringenstadt' },
  { label: 'Verishen', value: 'Verishen' },
  { label: 'Veríssimo', value: 'Veríssimo' },
  { label: 'Verkh-Chebula', value: 'Verkh-Chebula' },
  { label: 'Verkh-Katunskoye', value: 'Verkh-Katunskoye' },
  { label: 'Verkhn’Odniprovs’K', value: 'Verkhn’Odniprovs’K' },
  { label: 'Verkhnebakanskiy', value: 'Verkhnebakanskiy' },
  { label: 'Verkhnechusovskiye Gorodki', value: 'Verkhnechusovskiye Gorodki' },
  { label: 'Verkhnedneprovskiy', value: 'Verkhnedneprovskiy' },
  { label: 'Verkhnekolymsky District', value: 'Verkhnekolymsky District' },
  { label: 'Verkhnelandekhovskiy Rayon', value: 'Verkhnelandekhovskiy Rayon' },
  { label: 'Verkhnesadovoye', value: 'Verkhnesadovoye' },
  { label: 'Verkhnetulomskiy', value: 'Verkhnetulomskiy' },
  { label: 'Verkhneural’Sk', value: 'Verkhneural’Sk' },
  { label: 'Verkhneuslonskiy Rayon', value: 'Verkhneuslonskiy Rayon' },
  { label: 'Verkhnevilyuysk', value: 'Verkhnevilyuysk' },
  { label: 'Verkhnevilyuysky District', value: 'Verkhnevilyuysky District' },
  { label: 'Verkhneyarkeyevo', value: 'Verkhneyarkeyevo' },
  { label: 'Verkhneye Dubrovo', value: 'Verkhneye Dubrovo' },
  { label: 'Verkhneye Kazanishche', value: 'Verkhneye Kazanishche' },
  { label: 'Verkh-Neyvinskiy', value: 'Verkh-Neyvinskiy' },
  { label: 'Verkhniachka', value: 'Verkhniachka' },
  { label: 'Verkhnie Synevydne', value: 'Verkhnie Synevydne' },
  { label: 'Verkhniy Avzyan', value: 'Verkhniy Avzyan' },
  { label: 'Verkhniy Baskunchak', value: 'Verkhniy Baskunchak' },
  { label: 'Verkhniy Bekhtemir', value: 'Verkhniy Bekhtemir' },
  { label: 'Verkhniy Dashkesan', value: 'Verkhniy Dashkesan' },
  { label: 'Verkhniy Fiagdon', value: 'Verkhniy Fiagdon' },
  { label: 'Verkhniy Kurkuzhin', value: 'Verkhniy Kurkuzhin' },
  { label: 'Verkhniy Landekh', value: 'Verkhniy Landekh' },
  { label: 'Verkhniy Lomov', value: 'Verkhniy Lomov' },
  { label: 'Verkhniy Mamon', value: 'Verkhniy Mamon' },
  { label: 'Verkhniy Rohachyk', value: 'Verkhniy Rohachyk' },
  { label: 'Verkhniy Tagil', value: 'Verkhniy Tagil' },
  { label: 'Verkhniy Ufaley', value: 'Verkhniy Ufaley' },
  { label: 'Verkhniy Uslon', value: 'Verkhniy Uslon' },
  { label: 'Verkhniy Yasenov', value: 'Verkhniy Yasenov' },
  { label: 'Verkhniye Achaluki', value: 'Verkhniye Achaluki' },
  { label: 'Verkhniye Kayrakty', value: 'Verkhniye Kayrakty' },
  { label: 'Verkhniye Kigi', value: 'Verkhniye Kigi' },
  { label: 'Verkhniye Osel’Ki', value: 'Verkhniye Osel’Ki' },
  { label: 'Verkhniye Sergi', value: 'Verkhniye Sergi' },
  { label: 'Verkhniye Tatyshly', value: 'Verkhniye Tatyshly' },
  { label: 'Verkhnya Syrovatka', value: 'Verkhnya Syrovatka' },
  { label: 'Verkhnyaya Balkariya', value: 'Verkhnyaya Balkariya' },
  { label: 'Verkhnyaya Belka', value: 'Verkhnyaya Belka' },
  { label: 'Verkhnyaya Inta', value: 'Verkhnyaya Inta' },
  { label: 'Verkhnyaya Khava', value: 'Verkhnyaya Khava' },
  { label: 'Verkhnyaya Maksakovka', value: 'Verkhnyaya Maksakovka' },
  { label: 'Verkhnyaya Pyshma', value: 'Verkhnyaya Pyshma' },
  { label: 'Verkhnyaya Salda', value: 'Verkhnyaya Salda' },
  { label: 'Verkhnyaya Sinyachikha', value: 'Verkhnyaya Sinyachikha' },
  { label: 'Verkhnyaya Sysert’', value: 'Verkhnyaya Sysert’' },
  { label: 'Verkhnyaya Tishanka', value: 'Verkhnyaya Tishanka' },
  { label: 'Verkhnyaya Toyma', value: 'Verkhnyaya Toyma' },
  { label: 'Verkhnyaya Tura', value: 'Verkhnyaya Tura' },
  { label: 'Verkhorechye', value: 'Verkhorechye' },
  { label: 'Verkhoshizhem’Ye', value: 'Verkhoshizhem’Ye' },
  { label: 'Verkhotur’Ye', value: 'Verkhotur’Ye' },
  { label: 'Verkhov’Ye', value: 'Verkhov’Ye' },
  { label: 'Verkhovazh’Ye', value: 'Verkhovazh’Ye' },
  { label: 'Verkhovazhskiy Rayon', value: 'Verkhovazhskiy Rayon' },
  { label: 'Verkhovyna', value: 'Verkhovyna' },
  { label: 'Verkhoyansk', value: 'Verkhoyansk' },
  { label: 'Verkhoyansky District', value: 'Verkhoyansky District' },
  { label: 'Verkhozim', value: 'Verkhozim' },
  { label: 'Verkh-Suetka', value: 'Verkh-Suetka' },
  { label: 'Verkh-Tula', value: 'Verkh-Tula' },
  { label: 'Verkh-Usugli', value: 'Verkh-Usugli' },
  { label: 'Verl', value: 'Verl' },
  { label: 'Verla', value: 'Verla' },
  { label: 'Verlaine', value: 'Verlaine' },
  { label: 'Verlinghem', value: 'Verlinghem' },
  { label: 'Vermand', value: 'Vermand' },
  { label: 'Vermelha', value: 'Vermelha' },
  { label: 'Vermelho Novo', value: 'Vermelho Novo' },
  { label: 'Vermelles', value: 'Vermelles' },
  { label: 'Vermenton', value: 'Vermenton' },
  { label: 'Vermeş', value: 'Vermeş' },
  { label: 'Vermești', value: 'Vermești' },
  { label: 'Vermezzo', value: 'Vermezzo' },
  { label: 'Vermiglio', value: 'Vermiglio' },
  { label: 'Vermilion', value: 'Vermilion' },
  { label: 'Vermilion', value: 'Vermilion' },
  { label: 'Vermilion County', value: 'Vermilion County' },
  { label: 'Vermilion Parish', value: 'Vermilion Parish' },
  { label: 'Vermilion-On-The-Lake', value: 'Vermilion-On-The-Lake' },
  { label: 'Vermillion', value: 'Vermillion' },
  { label: 'Vermillion County', value: 'Vermillion County' },
  { label: 'Vermiosa', value: 'Vermiosa' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Vermont South', value: 'Vermont South' },
  { label: 'Vernaison', value: 'Vernaison' },
  { label: 'Vernal', value: 'Vernal' },
  { label: 'Vernante', value: 'Vernante' },
  { label: 'Vernantes', value: 'Vernantes' },
  { label: 'Vernasca', value: 'Vernasca' },
  { label: 'Vernashen', value: 'Vernashen' },
  { label: 'Vernate', value: 'Vernate' },
  { label: 'Vernayaz', value: 'Vernayaz' },
  { label: 'Vernazza', value: 'Vernazza' },
  { label: 'Vern-D’Anjou', value: 'Vern-D’Anjou' },
  { label: 'Vernègues', value: 'Vernègues' },
  { label: 'Verneřice', value: 'Verneřice' },
  { label: 'Verneşti', value: 'Verneşti' },
  { label: 'Vernet', value: 'Vernet' },
  { label: 'Vernet-Les-Bains', value: 'Vernet-Les-Bains' },
  { label: 'Verneuil-En-Halatte', value: 'Verneuil-En-Halatte' },
  { label: 'Verneuil-Sur-Avre', value: 'Verneuil-Sur-Avre' },
  { label: 'Verneuil-Sur-Seine', value: 'Verneuil-Sur-Seine' },
  { label: 'Verneuil-Sur-Vienne', value: 'Verneuil-Sur-Vienne' },
  { label: 'Vernier', value: 'Vernier' },
  { label: 'Vernio', value: 'Vernio' },
  { label: 'Verniolle', value: 'Verniolle' },
  { label: 'Vernioz', value: 'Vernioz' },
  { label: 'Vernoil-Le-Fourrier', value: 'Vernoil-Le-Fourrier' },
  { label: 'Vernole', value: 'Vernole' },
  { label: 'Vernon', value: 'Vernon' },
  { label: 'Vernon', value: 'Vernon' },
  { label: 'Vernon', value: 'Vernon' },
  { label: 'Vernon', value: 'Vernon' },
  { label: 'Vernon', value: 'Vernon' },
  { label: 'Vernon Center', value: 'Vernon Center' },
  { label: 'Vernon County', value: 'Vernon County' },
  { label: 'Vernon County', value: 'Vernon County' },
  { label: 'Vernon Hills', value: 'Vernon Hills' },
  { label: 'Vernon Parish', value: 'Vernon Parish' },
  { label: 'Vernon Valley', value: 'Vernon Valley' },
  { label: 'Vernonia', value: 'Vernonia' },
  { label: 'Vernosc-Lès-Annonay', value: 'Vernosc-Lès-Annonay' },
  { label: 'Vernouillet', value: 'Vernouillet' },
  { label: 'Vernouillet', value: 'Vernouillet' },
  { label: 'Vernou-La-Celle-Sur-Seine', value: 'Vernou-La-Celle-Sur-Seine' },
  { label: 'Vernou-Sur-Brenne', value: 'Vernou-Sur-Brenne' },
  { label: 'Vernoux-En-Vivarais', value: 'Vernoux-En-Vivarais' },
  { label: 'Vern-Sur-Seiche', value: 'Vern-Sur-Seiche' },
  { label: 'Verny', value: 'Verny' },
  { label: 'Vero Beach', value: 'Vero Beach' },
  { label: 'Vero Beach South', value: 'Vero Beach South' },
  { label: 'Verőce', value: 'Verőce' },
  { label: 'Véroia', value: 'Véroia' },
  { label: 'Verolanuova', value: 'Verolanuova' },
  { label: 'Verolavecchia', value: 'Verolavecchia' },
  { label: 'Verolengo', value: 'Verolengo' },
  { label: 'Veroli', value: 'Veroli' },
  { label: 'Véron', value: 'Véron' },
  { label: 'Verona', value: 'Verona' },
  { label: 'Verona', value: 'Verona' },
  { label: 'Verona', value: 'Verona' },
  { label: 'Verona', value: 'Verona' },
  { label: 'Verona', value: 'Verona' },
  { label: 'Verona', value: 'Verona' },
  { label: 'Verona', value: 'Verona' },
  { label: 'Verona Walk', value: 'Verona Walk' },
  { label: 'Veronella', value: 'Veronella' },
  { label: 'Věrovany', value: 'Věrovany' },
  { label: 'Veřovice', value: 'Veřovice' },
  { label: 'Verpelét', value: 'Verpelét' },
  { label: 'Verplanck', value: 'Verplanck' },
  { label: 'Verquin', value: 'Verquin' },
  { label: 'Verran', value: 'Verran' },
  { label: 'Verrayes', value: 'Verrayes' },
  { label: 'Verres', value: 'Verres' },
  { label: 'Verrettes', value: 'Verrettes' },
  { label: 'Verretto', value: 'Verretto' },
  { label: 'Verrières', value: 'Verrières' },
  { label: 'Verrières-Le-Buisson', value: 'Verrières-Le-Buisson' },
  { label: 'Verrone', value: 'Verrone' },
  { label: 'Verrua Po', value: 'Verrua Po' },
  { label: 'Verrua Savoia', value: 'Verrua Savoia' },
  { label: 'Versailles', value: 'Versailles' },
  { label: 'Versailles', value: 'Versailles' },
  { label: 'Versailles', value: 'Versailles' },
  { label: 'Versailles', value: 'Versailles' },
  { label: 'Versailles', value: 'Versailles' },
  { label: 'Versailles', value: 'Versailles' },
  { label: 'Versalles', value: 'Versalles' },
  { label: 'Verscio', value: 'Verscio' },
  { label: 'Verșeni', value: 'Verșeni' },
  { label: 'Verșești', value: 'Verșești' },
  { label: 'Vershina Tei', value: 'Vershina Tei' },
  { label: 'Vershino-Darasunskiy', value: 'Vershino-Darasunskiy' },
  { label: 'Vershino-Shakhtaminskiy', value: 'Vershino-Shakhtaminskiy' },
  { label: 'Versmold', value: 'Versmold' },
  { label: 'Versoix', value: 'Versoix' },
  { label: 'Verson', value: 'Verson' },
  { label: 'Versonnex', value: 'Versonnex' },
  { label: 'Vers-Pont-Du-Gard', value: 'Vers-Pont-Du-Gard' },
  { label: 'Ver-Sur-Launette', value: 'Ver-Sur-Launette' },
  { label: 'Ver-Sur-Mer', value: 'Ver-Sur-Mer' },
  { label: 'Vertaizon', value: 'Vertaizon' },
  { label: 'Vertavillo', value: 'Vertavillo' },
  { label: 'Vertemate Con Minoprio', value: 'Vertemate Con Minoprio' },
  { label: 'Vert-En-Drouais', value: 'Vert-En-Drouais' },
  { label: 'Vertente Do Lério', value: 'Vertente Do Lério' },
  { label: 'Vertentes', value: 'Vertentes' },
  { label: 'Vérteskethely', value: 'Vérteskethely' },
  { label: 'Vértessomló', value: 'Vértessomló' },
  { label: 'Vértesszőlős', value: 'Vértesszőlős' },
  { label: 'Vértestolna', value: 'Vértestolna' },
  { label: 'Vertheuil', value: 'Vertheuil' },
  { label: 'Vertientes', value: 'Vertientes' },
  { label: 'Vert-Le-Grand', value: 'Vert-Le-Grand' },
  { label: 'Vert-Le-Petit', value: 'Vert-Le-Petit' },
  { label: 'Verton', value: 'Verton' },
  { label: 'Vertou', value: 'Vertou' },
  { label: 'Vertova', value: 'Vertova' },
  { label: 'Vert-Saint-Denis', value: 'Vert-Saint-Denis' },
  { label: 'Vertus', value: 'Vertus' },
  { label: 'Verucchio', value: 'Verucchio' },
  { label: 'Veruela', value: 'Veruela' },
  { label: 'Veruela', value: 'Veruela' },
  { label: 'Veruno', value: 'Veruno' },
  { label: 'Verviers', value: 'Verviers' },
  { label: 'Vervins', value: 'Vervins' },
  { label: 'Vervio', value: 'Vervio' },
  { label: 'Vervò', value: 'Vervò' },
  { label: 'Verwood', value: 'Verwood' },
  { label: 'Veržej', value: 'Veržej' },
  { label: 'Verzenay', value: 'Verzenay' },
  { label: 'Verzino', value: 'Verzino' },
  { label: 'Verzuolo', value: 'Verzuolo' },
  { label: 'Verzy', value: 'Verzy' },
  { label: 'Ves’Yegonsk', value: 'Ves’Yegonsk' },
  { label: 'Ves’Yegonskiy Rayon', value: 'Ves’Yegonskiy Rayon' },
  { label: 'Vescovado', value: 'Vescovado' },
  { label: 'Vescovana', value: 'Vescovana' },
  { label: 'Vescovato', value: 'Vescovato' },
  { label: 'Vescovato', value: 'Vescovato' },
  { label: 'Vesele', value: 'Vesele' },
  { label: 'Vesele', value: 'Vesele' },
  { label: 'Veselí Nad Lužnicí', value: 'Veselí Nad Lužnicí' },
  { label: 'Veselí Nad Moravou', value: 'Veselí Nad Moravou' },
  { label: 'Veselivka', value: 'Veselivka' },
  { label: 'Veselovka', value: 'Veselovka' },
  { label: 'Veseloyarsk', value: 'Veseloyarsk' },
  { label: 'Veselynove', value: 'Veselynove' },
  { label: 'Vesëlyy', value: 'Vesëlyy' },
  { label: 'Veseuș', value: 'Veseuș' },
  { label: 'Veshchevo', value: 'Veshchevo' },
  { label: 'Veshenskaya', value: 'Veshenskaya' },
  { label: 'Veshkayma', value: 'Veshkayma' },
  { label: 'Veshnyaki', value: 'Veshnyaki' },
  { label: 'Vesht', value: 'Vesht' },
  { label: 'Vesilahti', value: 'Vesilahti' },
  { label: 'Vesime', value: 'Vesime' },
  { label: 'Vesoul', value: 'Vesoul' },
  { label: 'Vespasiano', value: 'Vespasiano' },
  { label: 'Vespasiano Corrêa', value: 'Vespasiano Corrêa' },
  { label: 'Vespella De Gaià', value: 'Vespella De Gaià' },
  { label: 'Vespolate', value: 'Vespolate' },
  { label: 'Vessalico', value: 'Vessalico' },
  { label: 'Vesseaux', value: 'Vesseaux' },
  { label: 'Vestavia Hills', value: 'Vestavia Hills' },
  { label: 'Vestbjerg', value: 'Vestbjerg' },
  { label: 'Vestby', value: 'Vestby' },
  { label: 'Vestbygd', value: 'Vestbygd' },
  { label: 'Vestec', value: 'Vestec' },
  { label: 'Veștem', value: 'Veștem' },
  { label: 'Vestenanova', value: 'Vestenanova' },
  { label: 'Vestenbergsgreuth', value: 'Vestenbergsgreuth' },
  { label: 'Vester Hassing', value: 'Vester Hassing' },
  { label: 'Vester-Skerninge', value: 'Vester-Skerninge' },
  { label: 'Vesthimmerland Kommune', value: 'Vesthimmerland Kommune' },
  { label: 'Vestiaria', value: 'Vestiaria' },
  { label: 'Vestignè', value: 'Vestignè' },
  { label: 'Vestmanna', value: 'Vestmanna' },
  { label: 'Vestmannaeyjabær', value: 'Vestmannaeyjabær' },
  { label: 'Vestmannaeyjar', value: 'Vestmannaeyjar' },
  { label: 'Vestnes', value: 'Vestnes' },
  { label: 'Vestone', value: 'Vestone' },
  { label: 'Vestre Slidre', value: 'Vestre Slidre' },
  { label: 'Vestre Toten', value: 'Vestre Toten' },
  { label: 'Vestreno', value: 'Vestreno' },
  { label: 'Vestric-Et-Candiac', value: 'Vestric-Et-Candiac' },
  { label: 'Vestvågøy', value: 'Vestvågøy' },
  { label: 'Veszprém', value: 'Veszprém' },
  { label: 'Veszprémi Járás', value: 'Veszprémi Járás' },
  { label: 'Vésztő', value: 'Vésztő' },
  { label: 'Veța', value: 'Veța' },
  { label: 'Vetapalem', value: 'Vetapalem' },
  { label: 'Vetas', value: 'Vetas' },
  { label: 'Vețca', value: 'Vețca' },
  { label: 'Veţel', value: 'Veţel' },
  { label: 'Veteli', value: 'Veteli' },
  { label: 'Veternigo', value: 'Veternigo' },
  { label: 'Veternik', value: 'Veternik' },
  { label: 'Vetiş', value: 'Vetiş' },
  { label: 'Vetlanda', value: 'Vetlanda' },
  { label: 'Vetluga', value: 'Vetluga' },
  { label: 'Vetluzhskiy', value: 'Vetluzhskiy' },
  { label: 'Vetluzhskiy', value: 'Vetluzhskiy' },
  { label: 'Vetluzhskiy Rayon', value: 'Vetluzhskiy Rayon' },
  { label: 'Vetovo', value: 'Vetovo' },
  { label: 'Vetralla', value: 'Vetralla' },
  { label: 'Vétraz-Monthoux', value: 'Vétraz-Monthoux' },
  { label: 'Vetrino', value: 'Vetrino' },
  { label: 'Vetrişoaia', value: 'Vetrişoaia' },
  { label: 'Větřní', value: 'Větřní' },
  { label: 'Vétroz', value: 'Vétroz' },
  { label: 'Vetschau', value: 'Vetschau' },
  { label: 'Vettaikkaranpudur', value: 'Vettaikkaranpudur' },
  { label: 'Vettavalam', value: 'Vettavalam' },
  { label: 'Vettelschoß', value: 'Vettelschoß' },
  { label: 'Vetto', value: 'Vetto' },
  { label: 'Vettur', value: 'Vettur' },
  { label: 'Vettweiß', value: 'Vettweiß' },
  { label: 'Veurey-Voroize', value: 'Veurey-Voroize' },
  { label: 'Veurne', value: 'Veurne' },
  { label: 'Vevay', value: 'Vevay' },
  { label: 'Vevčani', value: 'Vevčani' },
  { label: 'Vevelstad', value: 'Vevelstad' },
  { label: 'Veverská Bítýška', value: 'Veverská Bítýška' },
  { label: 'Vevey', value: 'Vevey' },
  { label: 'Veveyse District', value: 'Veveyse District' },
  { label: 'Vex', value: 'Vex' },
  { label: 'Veydelevka', value: 'Veydelevka' },
  { label: 'Veymandoo', value: 'Veymandoo' },
  { label: 'Veynes', value: 'Veynes' },
  { label: 'Veyno', value: 'Veyno' },
  { label: 'Veyrac', value: 'Veyrac' },
  { label: 'Veyras', value: 'Veyras' },
  { label: 'Veyre-Monton', value: 'Veyre-Monton' },
  { label: 'Veyrier', value: 'Veyrier' },
  { label: 'Veyrier-Du-Lac', value: 'Veyrier-Du-Lac' },
  { label: 'Veys', value: 'Veys' },
  { label: 'Veysian', value: 'Veysian' },
  { label: 'Veza', value: 'Veza' },
  { label: 'Vezdemarbán', value: 'Vezdemarbán' },
  { label: 'Vézelise', value: 'Vézelise' },
  { label: 'Vezendan', value: 'Vezendan' },
  { label: 'Vézénobres', value: 'Vézénobres' },
  { label: 'Vezin-Le-Coquet', value: 'Vezin-Le-Coquet' },
  { label: 'Vezins', value: 'Vezins' },
  { label: 'Vezirköprü', value: 'Vezirköprü' },
  { label: 'Vezza Dalba', value: 'Vezza Dalba' },
  { label: 'Vezza Doglio', value: 'Vezza Doglio' },
  { label: 'Vezzano', value: 'Vezzano' },
  { label: 'Vezzano Ligure', value: 'Vezzano Ligure' },
  { label: 'Vezzano Sul Crostolo', value: 'Vezzano Sul Crostolo' },
  { label: 'Vezzi Portio', value: 'Vezzi Portio' },
  { label: 'Vhembe District Municipality', value: 'Vhembe District Municipality' },
  { label: 'Vi', value: 'Vi' },
  { label: 'Vị Thanh', value: 'Vị Thanh' },
  { label: 'Via Lippia', value: 'Via Lippia' },
  { label: 'Viacamp Y Litera', value: 'Viacamp Y Litera' },
  { label: 'Viadana', value: 'Viadana' },
  { label: 'Viadanica', value: 'Viadanica' },
  { label: 'Viadutos', value: 'Viadutos' },
  { label: 'Viagrande', value: 'Viagrande' },
  { label: 'Viale', value: 'Viale' },
  { label: 'Viale', value: 'Viale' },
  { label: 'Vialfrè', value: 'Vialfrè' },
  { label: 'Vialonga', value: 'Vialonga' },
  { label: 'Vialonga', value: 'Vialonga' },
  { label: 'Viamão', value: 'Viamão' },
  { label: 'Viamonte', value: 'Viamonte' },
  { label: 'Vian', value: 'Vian' },
  { label: 'Viana', value: 'Viana' },
  { label: 'Viana', value: 'Viana' },
  { label: 'Viana', value: 'Viana' },
  { label: 'Viana', value: 'Viana' },
  { label: 'Viana De Cega', value: 'Viana De Cega' },
  { label: 'Viana De Duero', value: 'Viana De Duero' },
  { label: 'Viana De Jadraque', value: 'Viana De Jadraque' },
  { label: 'Viana Do Alentejo', value: 'Viana Do Alentejo' },
  { label: 'Viana Do Bolo', value: 'Viana Do Bolo' },
  { label: 'Viana Do Castelo', value: 'Viana Do Castelo' },
  { label: 'Viandar De La Vera', value: 'Viandar De La Vera' },
  { label: 'Vianden', value: 'Vianden' },
  { label: 'Vianen', value: 'Vianen' },
  { label: 'Vianí', value: 'Vianí' },
  { label: 'Vianne', value: 'Vianne' },
  { label: 'Viano', value: 'Viano' },
  { label: 'Vianópolis', value: 'Vianópolis' },
  { label: 'Vianos', value: 'Vianos' },
  { label: 'Viareggio', value: 'Viareggio' },
  { label: 'Viarigi', value: 'Viarigi' },
  { label: 'Viarmes', value: 'Viarmes' },
  { label: 'Vias', value: 'Vias' },
  { label: 'Viator', value: 'Viator' },
  { label: 'Vibble', value: 'Vibble' },
  { label: 'Vibo Valentia', value: 'Vibo Valentia' },
  { label: 'Vibonati', value: 'Vibonati' },
  { label: 'Viborg', value: 'Viborg' },
  { label: 'Viborg Kommune', value: 'Viborg Kommune' },
  { label: 'Viborillas', value: 'Viborillas' },
  { label: 'Vibraye', value: 'Vibraye' },
  { label: 'Viby', value: 'Viby' },
  { label: 'Vic', value: 'Vic' },
  { label: 'Vič District', value: 'Vič District' },
  { label: 'Vicálvaro', value: 'Vicálvaro' },
  { label: 'Vicalvi', value: 'Vicalvi' },
  { label: 'Vicam', value: 'Vicam' },
  { label: 'Vícar', value: 'Vícar' },
  { label: 'Vicarello', value: 'Vicarello' },
  { label: 'Vicari', value: 'Vicari' },
  { label: 'Vicaria', value: 'Vicaria' },
  { label: 'Vicchio', value: 'Vicchio' },
  { label: 'Vice', value: 'Vice' },
  { label: 'Vicea', value: 'Vicea' },
  { label: 'Vicedo O', value: 'Vicedo O' },
  { label: 'Vic-En-Bigorre', value: 'Vic-En-Bigorre' },
  { label: 'Vicência', value: 'Vicência' },
  { label: 'Vicente Camalote', value: 'Vicente Camalote' },
  { label: 'Vicente Dutra', value: 'Vicente Dutra' },
  { label: 'Vicente Guerrero', value: 'Vicente Guerrero' },
  { label: 'Vicente Guerrero', value: 'Vicente Guerrero' },
  { label: 'Vicente Guerrero', value: 'Vicente Guerrero' },
  { label: 'Vicente Guerrero', value: 'Vicente Guerrero' },
  { label: 'Vicente Guerrero', value: 'Vicente Guerrero' },
  { label: 'Vicente Guerrero', value: 'Vicente Guerrero' },
  { label: 'Vicente Guerrero', value: 'Vicente Guerrero' },
  { label: 'Vicente Guerrero', value: 'Vicente Guerrero' },
  { label: 'Vicente Guerrero', value: 'Vicente Guerrero' },
  { label: 'Vicente Guerrero (San Javier)', value: 'Vicente Guerrero (San Javier)' },
  { label: 'Vicente Herrera', value: 'Vicente Herrera' },
  { label: 'Vicente Noble', value: 'Vicente Noble' },
  { label: 'Vicentina', value: 'Vicentina' },
  { label: 'Vicentinópolis', value: 'Vicentinópolis' },
  { label: 'Vicenza', value: 'Vicenza' },
  { label: 'Vic-Fezensac', value: 'Vic-Fezensac' },
  { label: 'Vichadero', value: 'Vichadero' },
  { label: 'Vichten', value: 'Vichten' },
  { label: 'Vichuga', value: 'Vichuga' },
  { label: 'Vichugskiy Rayon', value: 'Vichugskiy Rayon' },
  { label: 'Vichuquén', value: 'Vichuquén' },
  { label: 'Vichy', value: 'Vichy' },
  { label: 'Vicién', value: 'Vicién' },
  { label: 'Viciomaggio', value: 'Viciomaggio' },
  { label: 'Vicksburg', value: 'Vicksburg' },
  { label: 'Vicksburg', value: 'Vicksburg' },
  { label: 'Vic-La-Gardiole', value: 'Vic-La-Gardiole' },
  { label: 'Vic-Le-Comte', value: 'Vic-Le-Comte' },
  { label: 'Vico Canavese', value: 'Vico Canavese' },
  { label: 'Vico Del Gargano', value: 'Vico Del Gargano' },
  { label: 'Vico Equense', value: 'Vico Equense' },
  { label: 'Vico Nel Lazio', value: 'Vico Nel Lazio' },
  { label: 'Vicofertile', value: 'Vicofertile' },
  { label: 'Vicoforte', value: 'Vicoforte' },
  { label: 'Vicoli', value: 'Vicoli' },
  { label: 'Vicolungo', value: 'Vicolungo' },
  { label: 'Vicomoscano', value: 'Vicomoscano' },
  { label: 'Vicopisano', value: 'Vicopisano' },
  { label: 'Viçosa', value: 'Viçosa' },
  { label: 'Viçosa', value: 'Viçosa' },
  { label: 'Viçosa', value: 'Viçosa' },
  { label: 'Viçosa Do Ceará', value: 'Viçosa Do Ceará' },
  { label: 'Vicovaro', value: 'Vicovaro' },
  { label: 'Vicovu De Jos', value: 'Vicovu De Jos' },
  { label: 'Vicovu De Sus', value: 'Vicovu De Sus' },
  { label: 'Vicq', value: 'Vicq' },
  { label: 'Vicq-Sur-Breuilh', value: 'Vicq-Sur-Breuilh' },
  { label: 'Vicques', value: 'Vicques' },
  { label: 'Vic-Sur-Aisne', value: 'Vic-Sur-Aisne' },
  { label: 'Vic-Sur-Cère', value: 'Vic-Sur-Cère' },
  { label: 'Vic-Sur-Seille', value: 'Vic-Sur-Seille' },
  { label: 'Victor', value: 'Victor' },
  { label: 'Victor', value: 'Victor' },
  { label: 'Victor Graeff', value: 'Victor Graeff' },
  { label: 'Victor Harbor', value: 'Victor Harbor' },
  { label: 'Víctor Rosales', value: 'Víctor Rosales' },
  { label: 'Victor Vlad Delamarina', value: 'Victor Vlad Delamarina' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Victoria County', value: 'Victoria County' },
  { label: 'Victoria Daly', value: 'Victoria Daly' },
  { label: 'Victoria De Acentejo La', value: 'Victoria De Acentejo La' },
  { label: 'Victoria De Cortazar', value: 'Victoria De Cortazar' },
  { label: 'Victoria De Durango', value: 'Victoria De Durango' },
  { label: 'Victoria Falls', value: 'Victoria Falls' },
  { label: 'Victoria Island', value: 'Victoria Island' },
  { label: 'Victoria La', value: 'Victoria La' },
  { label: 'Victoria Park', value: 'Victoria Park' },
  { label: 'Victoria Plains', value: 'Victoria Plains' },
  { label: 'Victoria Point', value: 'Victoria Point' },
  { label: 'Victoria Town', value: 'Victoria Town' },
  { label: 'Victorias', value: 'Victorias' },
  { label: 'Victoriaville', value: 'Victoriaville' },
  { label: 'Victorica', value: 'Victorica' },
  { label: 'Victorville', value: 'Victorville' },
  { label: 'Victory Gardens', value: 'Victory Gardens' },
  { label: 'Victory Lakes', value: 'Victory Lakes' },
  { label: 'Vicuña', value: 'Vicuña' },
  { label: 'Vicuña Mackenna', value: 'Vicuña Mackenna' },
  { label: 'Vid De Bureba La', value: 'Vid De Bureba La' },
  { label: 'Vid De Ojeda La', value: 'Vid De Ojeda La' },
  { label: 'Vid Y Barrios La', value: 'Vid Y Barrios La' },
  { label: 'Vida Mejor I', value: 'Vida Mejor I' },
  { label: 'Vidais', value: 'Vidais' },
  { label: 'Vidal Ramos', value: 'Vidal Ramos' },
  { label: 'Vidalengo', value: 'Vidalengo' },
  { label: 'Vidalia', value: 'Vidalia' },
  { label: 'Vidalia', value: 'Vidalia' },
  { label: 'Vidángoz/Bidankoze', value: 'Vidángoz/Bidankoze' },
  { label: 'Vidardo', value: 'Vidardo' },
  { label: 'Viðareiði', value: 'Viðareiði' },
  { label: 'Vidauban', value: 'Vidauban' },
  { label: 'Vidayanes', value: 'Vidayanes' },
  { label: 'Vidče', value: 'Vidče' },
  { label: 'Viddalba', value: 'Viddalba' },
  { label: 'Vide', value: 'Vide' },
  { label: 'Vide Bouteille', value: 'Vide Bouteille' },
  { label: 'Vide Entre Vinhas', value: 'Vide Entre Vinhas' },
  { label: 'Videbæk', value: 'Videbæk' },
  { label: 'Videira', value: 'Videira' },
  { label: 'Videle', value: 'Videle' },
  { label: 'Videm', value: 'Videm' },
  { label: 'Videm Pri Ptuju', value: 'Videm Pri Ptuju' },
  { label: 'Videmala', value: 'Videmala' },
  { label: 'Videmonte', value: 'Videmonte' },
  { label: 'Vidigueira', value: 'Vidigueira' },
  { label: 'Vidigulfo', value: 'Vidigulfo' },
  { label: 'Vidim', value: 'Vidim' },
  { label: 'Vidin', value: 'Vidin' },
  { label: 'Vidisha', value: 'Vidisha' },
  { label: 'Vidnava', value: 'Vidnava' },
  { label: 'Vidnoye', value: 'Vidnoye' },
  { label: 'Vídola La', value: 'Vídola La' },
  { label: 'Vidor', value: 'Vidor' },
  { label: 'Vidor', value: 'Vidor' },
  { label: 'Vidoši', value: 'Vidoši' },
  { label: 'Vidovci', value: 'Vidovci' },
  { label: 'Vidovec', value: 'Vidovec' },
  { label: 'Vidra', value: 'Vidra' },
  { label: 'Vidra', value: 'Vidra' },
  { label: 'Vidra', value: 'Vidra' },
  { label: 'Vidrà', value: 'Vidrà' },
  { label: 'Vidracco', value: 'Vidracco' },
  { label: 'Vidrasău', value: 'Vidrasău' },
  { label: 'Vidreres', value: 'Vidreres' },
  { label: 'Vidyayevo', value: 'Vidyayevo' },
  { label: 'Vidzy', value: 'Vidzy' },
  { label: 'Viechtach', value: 'Viechtach' },
  { label: 'Viechtwang', value: 'Viechtwang' },
  { label: 'Viedma', value: 'Viedma' },
  { label: 'Viehhausen', value: 'Viehhausen' },
  { label: 'Viehhofen', value: 'Viehhofen' },
  { label: 'Viehofen', value: 'Viehofen' },
  { label: 'Vieille Chapelle', value: 'Vieille Chapelle' },
  { label: 'Vieille-Brioude', value: 'Vieille-Brioude' },
  { label: 'Vieille-Église', value: 'Vieille-Église' },
  { label: 'Vieille-Toulouse', value: 'Vieille-Toulouse' },
  { label: 'Vieillevigne', value: 'Vieillevigne' },
  { label: 'Vieira De Leiria', value: 'Vieira De Leiria' },
  { label: 'Vieira Do Minho', value: 'Vieira Do Minho' },
  { label: 'Vieiras', value: 'Vieiras' },
  { label: 'Vieirópolis', value: 'Vieirópolis' },
  { label: 'Viejo Daan Banua', value: 'Viejo Daan Banua' },
  { label: 'Viekšniai', value: 'Viekšniai' },
  { label: 'Vielha E Mijaran', value: 'Vielha E Mijaran' },
  { label: 'Vielle-Saint-Girons', value: 'Vielle-Saint-Girons' },
  { label: 'Vielmur-Sur-Agout', value: 'Vielmur-Sur-Agout' },
  { label: 'Vielsalm', value: 'Vielsalm' },
  { label: 'Vienenburg', value: 'Vienenburg' },
  { label: 'Vienna', value: 'Vienna' },
  { label: 'Vienna', value: 'Vienna' },
  { label: 'Vienna', value: 'Vienna' },
  { label: 'Vienna', value: 'Vienna' },
  { label: 'Vienna', value: 'Vienna' },
  { label: 'Vienna', value: 'Vienna' },
  { label: 'Vienna Bend', value: 'Vienna Bend' },
  { label: 'Viennay', value: 'Viennay' },
  { label: 'Vienne', value: 'Vienne' },
  { label: 'Vienne', value: 'Vienne' },
  { label: 'Vienne-En-Val', value: 'Vienne-En-Val' },
  { label: 'Vientiane', value: 'Vientiane' },
  { label: 'Viento Frío', value: 'Viento Frío' },
  { label: 'Vieques', value: 'Vieques' },
  { label: 'Vieques', value: 'Vieques' },
  { label: 'Vieques Municipality', value: 'Vieques Municipality' },
  { label: 'Viera East', value: 'Viera East' },
  { label: 'Viera West', value: 'Viera West' },
  { label: 'Viereck', value: 'Viereck' },
  { label: 'Viereth-Trunstadt', value: 'Viereth-Trunstadt' },
  { label: 'Vierkirchen', value: 'Vierkirchen' },
  { label: 'Vierlas', value: 'Vierlas' },
  { label: 'Viernau', value: 'Viernau' },
  { label: 'Viernheim', value: 'Viernheim' },
  { label: 'Vierpolders', value: 'Vierpolders' },
  { label: 'Vierșani', value: 'Vierșani' },
  { label: 'Viersen', value: 'Viersen' },
  { label: 'Vieru', value: 'Vieru' },
  { label: 'Vierzon', value: 'Vierzon' },
  { label: 'Viesca', value: 'Viesca' },
  { label: 'Viesīte', value: 'Viesīte' },
  { label: 'Viesly', value: 'Viesly' },
  { label: 'Vieste', value: 'Vieste' },
  { label: 'Việt Trì', value: 'Việt Trì' },
  { label: 'Vietri Di Potenza', value: 'Vietri Di Potenza' },
  { label: 'Vietri Sul Mare', value: 'Vietri Sul Mare' },
  { label: 'Vieux Fort', value: 'Vieux Fort' },
  { label: 'Vieux Fort/Laborie Highway', value: 'Vieux Fort/Laborie Highway' },
  { label: 'Vieux Grand Port', value: 'Vieux Grand Port' },
  { label: 'Vieux-Berquin', value: 'Vieux-Berquin' },
  { label: 'Vieux-Boucau-Les-Bains', value: 'Vieux-Boucau-Les-Bains' },
  { label: 'Vieux-Charmont', value: 'Vieux-Charmont' },
  { label: 'Vieux-Condé', value: 'Vieux-Condé' },
  { label: 'Vieux-Thann', value: 'Vieux-Thann' },
  { label: 'Vievis', value: 'Vievis' },
  { label: 'View Park-Windsor Hills', value: 'View Park-Windsor Hills' },
  { label: 'Viewbank', value: 'Viewbank' },
  { label: 'Viewpark', value: 'Viewpark' },
  { label: 'Vif', value: 'Vif' },
  { label: 'Viforâta', value: 'Viforâta' },
  { label: 'Viforoasa', value: 'Viforoasa' },
  { label: 'Viga', value: 'Viga' },
  { label: 'Viga', value: 'Viga' },
  { label: 'Viga', value: 'Viga' },
  { label: 'Vigan', value: 'Vigan' },
  { label: 'Vigan', value: 'Vigan' },
  { label: 'Viganella', value: 'Viganella' },
  { label: 'Viganello', value: 'Viganello' },
  { label: 'Viganò', value: 'Viganò' },
  { label: 'Vigano San Martino', value: 'Vigano San Martino' },
  { label: 'Vigarano Mainarda', value: 'Vigarano Mainarda' },
  { label: 'Vigarano Pieve', value: 'Vigarano Pieve' },
  { label: 'Vigardolo', value: 'Vigardolo' },
  { label: 'Vigasio', value: 'Vigasio' },
  { label: 'Vigatto', value: 'Vigatto' },
  { label: 'Vigaun', value: 'Vigaun' },
  { label: 'Vige', value: 'Vige' },
  { label: 'Vigeland', value: 'Vigeland' },
  { label: 'Vigeois', value: 'Vigeois' },
  { label: 'Vigevano', value: 'Vigevano' },
  { label: 'Viggianello', value: 'Viggianello' },
  { label: 'Viggiano', value: 'Viggiano' },
  { label: 'Viggiù', value: 'Viggiù' },
  { label: 'Vighignolo', value: 'Vighignolo' },
  { label: 'Vighizzolo', value: 'Vighizzolo' },
  { label: 'Vighizzolo Deste', value: 'Vighizzolo Deste' },
  { label: 'Vigia', value: 'Vigia' },
  { label: 'Vigía Del Fuerte', value: 'Vigía Del Fuerte' },
  { label: 'Vigie', value: 'Vigie' },
  { label: 'Vigliano Biellese', value: 'Vigliano Biellese' },
  { label: 'Vigliano Dasti', value: 'Vigliano Dasti' },
  { label: 'Vigliano-Bettolino', value: 'Vigliano-Bettolino' },
  { label: 'Vignacastrisi', value: 'Vignacastrisi' },
  { label: 'Vignacourt', value: 'Vignacourt' },
  { label: 'Vignale Monferrato', value: 'Vignale Monferrato' },
  { label: 'Vignale Riotorto', value: 'Vignale Riotorto' },
  { label: 'Vignanello', value: 'Vignanello' },
  { label: 'Vignate', value: 'Vignate' },
  { label: 'Vigneulles-Lès-Hattonchâtel', value: 'Vigneulles-Lès-Hattonchâtel' },
  { label: 'Vigneux-De-Bretagne', value: 'Vigneux-De-Bretagne' },
  { label: 'Vigneux-Sur-Seine', value: 'Vigneux-Sur-Seine' },
  { label: 'Vignoc', value: 'Vignoc' },
  { label: 'Vignola', value: 'Vignola' },
  { label: 'Vignola', value: 'Vignola' },
  { label: 'Vignola', value: 'Vignola' },
  { label: 'Vignole Borbera', value: 'Vignole Borbera' },
  { label: 'Vignole-Olmi', value: 'Vignole-Olmi' },
  { label: 'Vignolo', value: 'Vignolo' },
  { label: 'Vignone', value: 'Vignone' },
  { label: 'Vignot', value: 'Vignot' },
  { label: 'Vignoux-Sur-Barangeon', value: 'Vignoux-Sur-Barangeon' },
  { label: 'Vigny', value: 'Vigny' },
  { label: 'Vigo', value: 'Vigo' },
  { label: 'Vigo', value: 'Vigo' },
  { label: 'Vigo County', value: 'Vigo County' },
  { label: 'Vigo Di Cadore', value: 'Vigo Di Cadore' },
  { label: 'Vigo Di Fassa', value: 'Vigo Di Fassa' },
  { label: 'Vigo Di Ton', value: 'Vigo Di Ton' },
  { label: 'Vigodarzere', value: 'Vigodarzere' },
  { label: 'Vigolo', value: 'Vigolo' },
  { label: 'Vigolo Vattaro', value: 'Vigolo Vattaro' },
  { label: 'Vigolzone', value: 'Vigolzone' },
  { label: 'Vigone', value: 'Vigone' },
  { label: 'Vigonovo', value: 'Vigonovo' },
  { label: 'Vigonovo-Fontanafredda', value: 'Vigonovo-Fontanafredda' },
  { label: 'Vigonza', value: 'Vigonza' },
  { label: 'Vigonzone', value: 'Vigonzone' },
  { label: 'Vigrestad', value: 'Vigrestad' },
  { label: 'Viguera', value: 'Viguera' },
  { label: 'Viguí', value: 'Viguí' },
  { label: 'Viguzzolo', value: 'Viguzzolo' },
  { label: 'Vigy', value: 'Vigy' },
  { label: 'Vihanti', value: 'Vihanti' },
  { label: 'Vihari', value: 'Vihari' },
  { label: 'Vihiers', value: 'Vihiers' },
  { label: 'Vihti', value: 'Vihti' },
  { label: 'Viiala', value: 'Viiala' },
  { label: 'Viile', value: 'Viile' },
  { label: 'Viile', value: 'Viile' },
  { label: 'Viile Apei', value: 'Viile Apei' },
  { label: 'Viile Satu Mare', value: 'Viile Satu Mare' },
  { label: 'Viile Tecii', value: 'Viile Tecii' },
  { label: 'Viilor', value: 'Viilor' },
  { label: 'Viimsi', value: 'Viimsi' },
  { label: 'Viimsi Vald', value: 'Viimsi Vald' },
  { label: 'Viiratsi', value: 'Viiratsi' },
  { label: 'Viişoara', value: 'Viişoara' },
  { label: 'Viişoara', value: 'Viişoara' },
  { label: 'Viişoara', value: 'Viişoara' },
  { label: 'Viişoara', value: 'Viişoara' },
  { label: 'Viişoara', value: 'Viişoara' },
  { label: 'Viişoara', value: 'Viişoara' },
  { label: 'Viișoara', value: 'Viișoara' },
  { label: 'Viișoara', value: 'Viișoara' },
  { label: 'Viișoara', value: 'Viișoara' },
  { label: 'Viișoara', value: 'Viișoara' },
  { label: 'Viișoara', value: 'Viișoara' },
  { label: 'Viișoara', value: 'Viișoara' },
  { label: 'Viitasaari', value: 'Viitasaari' },
  { label: 'Vijapur', value: 'Vijapur' },
  { label: 'Vijayapura', value: 'Vijayapura' },
  { label: 'Vijayapuri', value: 'Vijayapuri' },
  { label: 'Vijayawada', value: 'Vijayawada' },
  { label: 'Vijaydurg', value: 'Vijaydurg' },
  { label: 'Vijes', value: 'Vijes' },
  { label: 'Vijfhuizen', value: 'Vijfhuizen' },
  { label: 'Vijlen', value: 'Vijlen' },
  { label: 'Vik', value: 'Vik' },
  { label: 'Vik', value: 'Vik' },
  { label: 'Vikarabad', value: 'Vikarabad' },
  { label: 'Vikarbyn', value: 'Vikarbyn' },
  { label: 'Vikasnagar', value: 'Vikasnagar' },
  { label: 'Viken', value: 'Viken' },
  { label: 'Viken', value: 'Viken' },
  { label: 'Vikersund', value: 'Vikersund' },
  { label: 'Vikeså', value: 'Vikeså' },
  { label: 'Vikevåg', value: 'Vikevåg' },
  { label: 'Vikhorevka', value: 'Vikhorevka' },
  { label: 'Vikhroli', value: 'Vikhroli' },
  { label: 'Vikindu', value: 'Vikindu' },
  { label: 'Viking', value: 'Viking' },
  { label: 'Vikingstad', value: 'Vikingstad' },
  { label: 'Vikna', value: 'Vikna' },
  { label: 'Vikravandi', value: 'Vikravandi' },
  { label: 'Viktorsberg', value: 'Viktorsberg' },
  { label: 'Viktring', value: 'Viktring' },
  { label: 'Viktringer Vorstadt', value: 'Viktringer Vorstadt' },
  { label: 'Vikulovo', value: 'Vikulovo' },
  { label: 'Vikýřovice', value: 'Vikýřovice' },
  { label: 'Vil’Ne', value: 'Vil’Ne' },
  { label: 'Vila Bela Da Santíssima Trindade', value: 'Vila Bela Da Santíssima Trindade' },
  { label: 'Vila Boa', value: 'Vila Boa' },
  { label: 'Vila Boa', value: 'Vila Boa' },
  { label: 'Vila Boa Do Mondego', value: 'Vila Boa Do Mondego' },
  { label: 'Vila Chã', value: 'Vila Chã' },
  { label: 'Vila Chã De Ourique', value: 'Vila Chã De Ourique' },
  { label: 'Vila Cortês Da Serra', value: 'Vila Cortês Da Serra' },
  { label: 'Vila Cortês Do Mondego', value: 'Vila Cortês Do Mondego' },
  { label: 'Vila Cova', value: 'Vila Cova' },
  { label: 'Vila Cova À Coelheira', value: 'Vila Cova À Coelheira' },
  { label: 'Vila De Cruces', value: 'Vila De Cruces' },
  { label: 'Vila De Rei', value: 'Vila De Rei' },
  { label: 'Vila Do Bispo', value: 'Vila Do Bispo' },
  { label: 'Vila Do Conde', value: 'Vila Do Conde' },
  { label: 'Vila Do Maio', value: 'Vila Do Maio' },
  { label: 'Vila Do Porto', value: 'Vila Do Porto' },
  { label: 'Vila Do Touro', value: 'Vila Do Touro' },
  { label: 'Vila Fernando', value: 'Vila Fernando' },
  { label: 'Vila Flor', value: 'Vila Flor' },
  { label: 'Vila Flor', value: 'Vila Flor' },
  { label: 'Vila Flores', value: 'Vila Flores' },
  { label: 'Vila Franca Da Serra', value: 'Vila Franca Da Serra' },
  { label: 'Vila Franca Das Naves', value: 'Vila Franca Das Naves' },
  { label: 'Vila Franca De Xira', value: 'Vila Franca De Xira' },
  { label: 'Vila Franca De Xira', value: 'Vila Franca De Xira' },
  { label: 'Vila Franca Do Campo', value: 'Vila Franca Do Campo' },
  { label: 'Vila Franca Do Deão', value: 'Vila Franca Do Deão' },
  { label: 'Vila Franca Do Rosário', value: 'Vila Franca Do Rosário' },
  { label: 'Vila Frescainha', value: 'Vila Frescainha' },
  { label: 'Vila Garcia', value: 'Vila Garcia' },
  { label: 'Vila Lângaro', value: 'Vila Lângaro' },
  { label: 'Vila Maria', value: 'Vila Maria' },
  { label: 'Vila Meã', value: 'Vila Meã' },
  { label: 'Vila Nova Da Barquinha', value: 'Vila Nova Da Barquinha' },
  { label: 'Vila Nova Da Rainha', value: 'Vila Nova Da Rainha' },
  { label: 'Vila Nova Da Telha', value: 'Vila Nova Da Telha' },
  { label: 'Vila Nova De Cacela', value: 'Vila Nova De Cacela' },
  { label: 'Vila Nova De Famalicão', value: 'Vila Nova De Famalicão' },
  { label: 'Vila Nova De Foz Côa', value: 'Vila Nova De Foz Côa' },
  { label: 'Vila Nova De Gaia', value: 'Vila Nova De Gaia' },
  { label: 'Vila Nova De Milfontes', value: 'Vila Nova De Milfontes' },
  { label: 'Vila Nova De Paiva', value: 'Vila Nova De Paiva' },
  { label: 'Vila Nova De Poiares', value: 'Vila Nova De Poiares' },
  { label: 'Vila Nova De São Pedro', value: 'Vila Nova De São Pedro' },
  { label: 'Vila Nova De Tazem', value: 'Vila Nova De Tazem' },
  { label: 'Vila Nova Do Piauí', value: 'Vila Nova Do Piauí' },
  { label: 'Vila Nova Do Sul', value: 'Vila Nova Do Sul' },
  { label: 'Vila Nova Dos Martírios', value: 'Vila Nova Dos Martírios' },
  { label: 'Vila Pavão', value: 'Vila Pavão' },
  { label: 'Vila Pouca De Aguiar', value: 'Vila Pouca De Aguiar' },
  { label: 'Vila Propício', value: 'Vila Propício' },
  { label: 'Vila Real', value: 'Vila Real' },
  { label: 'Vila Real De Santo António', value: 'Vila Real De Santo António' },
  { label: 'Vila Rica', value: 'Vila Rica' },
  { label: 'Vila Ruiva', value: 'Vila Ruiva' },
  { label: 'Vila Seca', value: 'Vila Seca' },
  { label: 'Vila Soeiro Do Chão', value: 'Vila Soeiro Do Chão' },
  { label: 'Vila Valério', value: 'Vila Valério' },
  { label: 'Vila Velha', value: 'Vila Velha' },
  { label: 'Vila Velha De Ródão', value: 'Vila Velha De Ródão' },
  { label: 'Vila Verde', value: 'Vila Verde' },
  { label: 'Vila Verde', value: 'Vila Verde' },
  { label: 'Vila Verde', value: 'Vila Verde' },
  { label: 'Vila Verde Dos Francos', value: 'Vila Verde Dos Francos' },
  { label: 'Vila Viçosa', value: 'Vila Viçosa' },
  { label: 'Vilabella', value: 'Vilabella' },
  { label: 'Vilabertran', value: 'Vilabertran' },
  { label: 'Vilablareix', value: 'Vilablareix' },
  { label: 'Vilaboa', value: 'Vilaboa' },
  { label: 'Vilaça', value: 'Vilaça' },
  { label: 'Vilada', value: 'Vilada' },
  { label: 'Viladamat', value: 'Viladamat' },
  { label: 'Viladasens', value: 'Viladasens' },
  { label: 'Viladecans', value: 'Viladecans' },
  { label: 'Viladecavalls', value: 'Viladecavalls' },
  { label: 'Vilademuls', value: 'Vilademuls' },
  { label: 'Viladrau', value: 'Viladrau' },
  { label: 'Vilafamés', value: 'Vilafamés' },
  { label: 'Vilafant', value: 'Vilafant' },
  { label: 'Vilaflor', value: 'Vilaflor' },
  { label: 'Vilafranca De Bonany', value: 'Vilafranca De Bonany' },
  { label: 'Vilafranca Del Penedès', value: 'Vilafranca Del Penedès' },
  { label: 'Vilagarcía De Arousa', value: 'Vilagarcía De Arousa' },
  { label: 'Vilagrassa', value: 'Vilagrassa' },
  { label: 'Vilajuïga', value: 'Vilajuïga' },
  { label: 'Viļaka', value: 'Viļaka' },
  { label: 'Vilalba', value: 'Vilalba' },
  { label: 'Vilalba Dels Arcs', value: 'Vilalba Dels Arcs' },
  { label: 'Vilalba Sasserra', value: 'Vilalba Sasserra' },
  { label: 'Vilaller', value: 'Vilaller' },
  { label: 'Vilallonga De Ter', value: 'Vilallonga De Ter' },
  { label: 'Vilallonga Del Camp', value: 'Vilallonga Del Camp' },
  { label: 'Vilallonga Dels Monts', value: 'Vilallonga Dels Monts' },
  { label: 'Vilamacolum', value: 'Vilamacolum' },
  { label: 'Vilamalla', value: 'Vilamalla' },
  { label: 'Vilamaniscle', value: 'Vilamaniscle' },
  { label: 'Vilamarín', value: 'Vilamarín' },
  { label: 'Vilamartín De Valdeorras', value: 'Vilamartín De Valdeorras' },
  { label: 'Vilamarxant', value: 'Vilamarxant' },
  { label: 'Vilamòs', value: 'Vilamòs' },
  { label: 'Vilamoura', value: 'Vilamoura' },
  { label: 'Vilanant', value: 'Vilanant' },
  { label: 'Viļāni', value: 'Viļāni' },
  { label: 'Vilanova Dalcolea', value: 'Vilanova Dalcolea' },
  { label: 'Vilanova De Arousa', value: 'Vilanova De Arousa' },
  { label: 'Vilanova De Bellpuig', value: 'Vilanova De Bellpuig' },
  { label: 'Vilanova De La Barca', value: 'Vilanova De La Barca' },
  { label: 'Vilanova De Laguda', value: 'Vilanova De Laguda' },
  { label: 'Vilanova De Meià', value: 'Vilanova De Meià' },
  { label: 'Vilanova De Prades', value: 'Vilanova De Prades' },
  { label: 'Vilanova De Sau', value: 'Vilanova De Sau' },
  { label: 'Vilanova De Segrià', value: 'Vilanova De Segrià' },
  { label: 'Vilanova Del Camí', value: 'Vilanova Del Camí' },
  { label: 'Vilanova Del Vallès', value: 'Vilanova Del Vallès' },
  { label: 'Vilanova Descornalbou', value: 'Vilanova Descornalbou' },
  { label: 'Vilanova I La Geltrú', value: 'Vilanova I La Geltrú' },
  { label: 'Vilaplana', value: 'Vilaplana' },
  { label: 'Vilar', value: 'Vilar' },
  { label: 'Vilar De Amargo', value: 'Vilar De Amargo' },
  { label: 'Vilar De Andorinho', value: 'Vilar De Andorinho' },
  { label: 'Vilar De Barrio', value: 'Vilar De Barrio' },
  { label: 'Vilar De Canes', value: 'Vilar De Canes' },
  { label: 'Vilar De Figos', value: 'Vilar De Figos' },
  { label: 'Vilar De Santos', value: 'Vilar De Santos' },
  { label: 'Vilar Do Paraíso', value: 'Vilar Do Paraíso' },
  { label: 'Vilar Formoso', value: 'Vilar Formoso' },
  { label: 'Vilar Maior', value: 'Vilar Maior' },
  { label: 'Vilar Torpim', value: 'Vilar Torpim' },
  { label: 'Vilardevós', value: 'Vilardevós' },
  { label: 'Vila-Real', value: 'Vila-Real' },
  { label: 'Vilares', value: 'Vilares' },
  { label: 'Vilarinho', value: 'Vilarinho' },
  { label: 'Vilariño De Conso', value: 'Vilariño De Conso' },
  { label: 'Vilarmaior', value: 'Vilarmaior' },
  { label: 'Vila-Rodona', value: 'Vila-Rodona' },
  { label: 'Vilas County', value: 'Vilas County' },
  { label: 'Vila-Sacra', value: 'Vila-Sacra' },
  { label: 'Vila-Sana', value: 'Vila-Sana' },
  { label: 'Vilasantar', value: 'Vilasantar' },
  { label: 'Vila-Seca', value: 'Vila-Seca' },
  { label: 'Vilassar De Dalt', value: 'Vilassar De Dalt' },
  { label: 'Vilassar De Mar', value: 'Vilassar De Mar' },
  { label: 'Vilattikulam', value: 'Vilattikulam' },
  { label: 'Vilaür', value: 'Vilaür' },
  { label: 'Vilavella La', value: 'Vilavella La' },
  { label: 'Vilaverd', value: 'Vilaverd' },
  { label: 'Vilaxoán', value: 'Vilaxoán' },
  { label: 'Vilches', value: 'Vilches' },
  { label: 'Vilcún', value: 'Vilcún' },
  { label: 'Vildbjerg', value: 'Vildbjerg' },
  { label: 'Vile Parle', value: 'Vile Parle' },
  { label: 'Vilela', value: 'Vilela' },
  { label: 'Vilella Alta La', value: 'Vilella Alta La' },
  { label: 'Vilella Baixa La', value: 'Vilella Baixa La' },
  { label: 'Vilémov', value: 'Vilémov' },
  { label: 'Vilémov', value: 'Vilémov' },
  { label: 'Vileña', value: 'Vileña' },
  { label: 'Vilenka', value: 'Vilenka' },
  { label: 'Vilgertshofen', value: 'Vilgertshofen' },
  { label: 'Vilhelmina', value: 'Vilhelmina' },
  { label: 'Vilhena', value: 'Vilhena' },
  { label: 'Vília', value: 'Vília' },
  { label: 'Viligili', value: 'Viligili' },
  { label: 'Vilino', value: 'Vilino' },
  { label: 'Viljakkala', value: 'Viljakkala' },
  { label: 'Viljandi', value: 'Viljandi' },
  { label: 'Viljandi Vald', value: 'Viljandi Vald' },
  { label: 'Viljevo', value: 'Viljevo' },
  { label: 'Viljoenskroon', value: 'Viljoenskroon' },
  { label: 'Vilkaviskis', value: 'Vilkaviskis' },
  { label: 'Vilkaviškis District Municipality', value: 'Vilkaviškis District Municipality' },
  { label: 'Vilkija', value: 'Vilkija' },
  { label: 'Vilkpėdė', value: 'Vilkpėdė' },
  { label: 'Villa', value: 'Villa' },
  { label: 'Villa', value: 'Villa' },
  { label: 'Villa', value: 'Villa' },
  { label: 'Villa Adriana', value: 'Villa Adriana' },
  { label: 'Villa Aglipay', value: 'Villa Aglipay' },
  { label: 'Villa Aglipay', value: 'Villa Aglipay' },
  { label: 'Villa Alberto Andrés Alvarado Arámburo', value: 'Villa Alberto Andrés Alvarado Arámburo' },
  { label: 'Villa Aldama', value: 'Villa Aldama' },
  { label: 'Villa Aldama', value: 'Villa Aldama' },
  { label: 'Villa Alegre', value: 'Villa Alegre' },
  { label: 'Villa Alemana', value: 'Villa Alemana' },
  { label: 'Villa Allende', value: 'Villa Allende' },
  { label: 'Villa Alta', value: 'Villa Alta' },
  { label: 'Villa Altagracia', value: 'Villa Altagracia' },
  { label: 'Villa Ángela', value: 'Villa Ángela' },
  { label: 'Villa Ascasubi', value: 'Villa Ascasubi' },
  { label: 'Villa Atamisqui', value: 'Villa Atamisqui' },
  { label: 'Villa Avila Camacho', value: 'Villa Avila Camacho' },
  { label: 'Villa Azueta', value: 'Villa Azueta' },
  { label: 'Villa Bartolomea', value: 'Villa Bartolomea' },
  { label: 'Villa Basilica', value: 'Villa Basilica' },
  { label: 'Villa Basilio Nievas', value: 'Villa Basilio Nievas' },
  { label: 'Villa Berna', value: 'Villa Berna' },
  { label: 'Villa Berthet', value: 'Villa Berthet' },
  { label: 'Villa Biscossi', value: 'Villa Biscossi' },
  { label: 'Villa Bisonó', value: 'Villa Bisonó' },
  { label: 'Villa Bruzual', value: 'Villa Bruzual' },
  { label: 'Villa Bustos', value: 'Villa Bustos' },
  { label: 'Villa Caldari', value: 'Villa Caldari' },
  { label: 'Villa Canales', value: 'Villa Canales' },
  { label: 'Villa Cañás', value: 'Villa Cañás' },
  { label: 'Villa Carcina', value: 'Villa Carcina' },
  { label: 'Villa Carlos Paz', value: 'Villa Carlos Paz' },
  { label: 'Villa Carmen', value: 'Villa Carmen' },
  { label: 'Villa Caro', value: 'Villa Caro' },
  { label: 'Villa Castelli', value: 'Villa Castelli' },
  { label: 'Villa Ceccolini', value: 'Villa Ceccolini' },
  { label: 'Villa Celiera', value: 'Villa Celiera' },
  { label: 'Villa Chalcatongo De Hidalgo', value: 'Villa Chalcatongo De Hidalgo' },
  { label: 'Villa Ciambra', value: 'Villa Ciambra' },
  { label: 'Villa Collemandina', value: 'Villa Collemandina' },
  { label: 'Villa Comaltitlán', value: 'Villa Comaltitlán' },
  { label: 'Villa Concepción Del Tío', value: 'Villa Concepción Del Tío' },
  { label: 'Villa Constitución', value: 'Villa Constitución' },
  { label: 'Villa Constitución', value: 'Villa Constitución' },
  { label: 'Villa Consuelo', value: 'Villa Consuelo' },
  { label: 'Villa Corona', value: 'Villa Corona' },
  { label: 'Villa Cortese', value: 'Villa Cortese' },
  { label: 'Villa Corzo', value: 'Villa Corzo' },
  { label: 'Villa Cuauhtémoc', value: 'Villa Cuauhtémoc' },
  { label: 'Villa Cuauhtémoc', value: 'Villa Cuauhtémoc' },
  { label: 'Villa Cura Brochero', value: 'Villa Cura Brochero' },
  { label: 'Villa Dadda', value: 'Villa Dadda' },
  { label: 'Villa Dagri', value: 'Villa Dagri' },
  { label: 'Villa Dalmè', value: 'Villa Dalmè' },
  { label: 'Villa Dasolo', value: 'Villa Dasolo' },
  { label: 'Villa De Almoloya De Juárez', value: 'Villa De Almoloya De Juárez' },
  { label: 'Villa De Cos', value: 'Villa De Cos' },
  { label: 'Villa De Costa Rica', value: 'Villa De Costa Rica' },
  { label: 'Villa De Cura', value: 'Villa De Cura' },
  { label: 'Villa De Don Fadrique La', value: 'Villa De Don Fadrique La' },
  { label: 'Villa De El Carmen Tequexquitla', value: 'Villa De El Carmen Tequexquitla' },
  { label: 'Villa De Etla', value: 'Villa De Etla' },
  { label: 'Villa De Guadalupe', value: 'Villa De Guadalupe' },
  { label: 'Villa De Las Flores', value: 'Villa De Las Flores' },
  { label: 'Villa De Leyva', value: 'Villa De Leyva' },
  { label: 'Villa De Los Niños', value: 'Villa De Los Niños' },
  { label: 'Villa De Mazo', value: 'Villa De Mazo' },
  { label: 'Villa De Ramos', value: 'Villa De Ramos' },
  { label: 'Villa De Reyes', value: 'Villa De Reyes' },
  { label: 'Villa De San Antonio', value: 'Villa De San Antonio' },
  { label: 'Villa De San Diego De Ubate', value: 'Villa De San Diego De Ubate' },
  { label: 'Villa De San Francisco', value: 'Villa De San Francisco' },
  { label: 'Villa De Soto', value: 'Villa De Soto' },
  { label: 'Villa De Tamazulápam Del Progreso', value: 'Villa De Tamazulápam Del Progreso' },
  { label: 'Villa De Tututepec De Melchor Ocampo', value: 'Villa De Tututepec De Melchor Ocampo' },
  { label: 'Villa De Vallecas', value: 'Villa De Vallecas' },
  { label: 'Villa De Ves', value: 'Villa De Ves' },
  { label: 'Villa De Zaachila', value: 'Villa De Zaachila' },
  { label: 'Villa Del Bosco', value: 'Villa Del Bosco' },
  { label: 'Villa Del Campo', value: 'Villa Del Campo' },
  { label: 'Villa Del Campo', value: 'Villa Del Campo' },
  { label: 'Villa Del Carbón', value: 'Villa Del Carbón' },
  { label: 'Villa Del Carmen', value: 'Villa Del Carmen' },
  { label: 'Villa Del Conte', value: 'Villa Del Conte' },
  { label: 'Villa Del Dique', value: 'Villa Del Dique' },
  { label: 'Villa Del Prado', value: 'Villa Del Prado' },
  { label: 'Villa Del Rey', value: 'Villa Del Rey' },
  { label: 'Villa Del Río', value: 'Villa Del Río' },
  { label: 'Villa Del Rosario', value: 'Villa Del Rosario' },
  { label: 'Villa Del Rosario', value: 'Villa Del Rosario' },
  { label: 'Villa Del Rosario', value: 'Villa Del Rosario' },
  { label: 'Villa Del Totoral', value: 'Villa Del Totoral' },
  { label: 'Villa Di Briano', value: 'Villa Di Briano' },
  { label: 'Villa Di Chiavenna', value: 'Villa Di Chiavenna' },
  { label: 'Villa Di Serio', value: 'Villa Di Serio' },
  { label: 'Villa Díaz Ordaz', value: 'Villa Díaz Ordaz' },
  { label: 'Villa Dogna', value: 'Villa Dogna' },
  { label: 'Villa Dolores', value: 'Villa Dolores' },
  { label: 'Villa Doneta', value: 'Villa Doneta' },
  { label: 'Villa El Carmen', value: 'Villa El Carmen' },
  { label: 'Villa Elisa', value: 'Villa Elisa' },
  { label: 'Villa Elisa', value: 'Villa Elisa' },
  { label: 'Villa Elisa', value: 'Villa Elisa' },
  { label: 'Villa Emiliano Zapata', value: 'Villa Emiliano Zapata' },
  { label: 'Villa Emiliano Zapata', value: 'Villa Emiliano Zapata' },
  { label: 'Villa Emilio Carranza', value: 'Villa Emilio Carranza' },
  { label: 'Villa Escolar', value: 'Villa Escolar' },
  { label: 'Villa Española', value: 'Villa Española' },
  { label: 'Villa Estense', value: 'Villa Estense' },
  { label: 'Villa Faraldi', value: 'Villa Faraldi' },
  { label: 'Villa Florida', value: 'Villa Florida' },
  { label: 'Villa Fontana', value: 'Villa Fontana' },
  { label: 'Villa Fornace', value: 'Villa Fornace' },
  { label: 'Villa Francisca', value: 'Villa Francisca' },
  { label: 'Villa García', value: 'Villa García' },
  { label: 'Villa García', value: 'Villa García' },
  { label: 'Villa General Belgrano', value: 'Villa General Belgrano' },
  { label: 'Villa General Guemes', value: 'Villa General Guemes' },
  { label: 'Villa General Mitre', value: 'Villa General Mitre' },
  { label: 'Villa General Roca', value: 'Villa General Roca' },
  { label: 'Villa Giardino', value: 'Villa Giardino' },
  { label: 'Villa González', value: 'Villa González' },
  { label: 'Villa González Ortega', value: 'Villa González Ortega' },
  { label: 'Villa Gregorio García', value: 'Villa Gregorio García' },
  { label: 'Villa Grove', value: 'Villa Grove' },
  { label: 'Villa Guardia', value: 'Villa Guardia' },
  { label: 'Villa Guerrero', value: 'Villa Guerrero' },
  { label: 'Villa Guerrero', value: 'Villa Guerrero' },
  { label: 'Villa Guerrero Municipality', value: 'Villa Guerrero Municipality' },
  { label: 'Villa Hayes', value: 'Villa Hayes' },
  { label: 'Villa Hermosa', value: 'Villa Hermosa' },
  { label: 'Villa Hermosa (Las Pozas)', value: 'Villa Hermosa (Las Pozas)' },
  { label: 'Villa Hernandarias', value: 'Villa Hernandarias' },
  { label: 'Villa Hidalgo', value: 'Villa Hidalgo' },
  { label: 'Villa Hidalgo', value: 'Villa Hidalgo' },
  { label: 'Villa Hidalgo', value: 'Villa Hidalgo' },
  { label: 'Villa Hidalgo', value: 'Villa Hidalgo' },
  { label: 'Villa Hidalgo', value: 'Villa Hidalgo' },
  { label: 'Villa Hidalgo', value: 'Villa Hidalgo' },
  { label: 'Villa Hidalgo', value: 'Villa Hidalgo' },
  { label: 'Villa Hidalgo', value: 'Villa Hidalgo' },
  { label: 'Villa Hidalgo (El Cubo)', value: 'Villa Hidalgo (El Cubo)' },
  { label: 'Villa Hills', value: 'Villa Hills' },
  { label: 'Villa Huidobro', value: 'Villa Huidobro' },
  { label: 'Villa Independencia', value: 'Villa Independencia' },
  { label: 'Villa Insurgentes (El Calabazal)', value: 'Villa Insurgentes (El Calabazal)' },
  { label: 'Villa Isabela', value: 'Villa Isabela' },
  { label: 'Villa Isla', value: 'Villa Isla' },
  { label: 'Villa Isla', value: 'Villa Isla' },
  { label: 'Villa Jaragua', value: 'Villa Jaragua' },
  { label: 'Villa Jiménez', value: 'Villa Jiménez' },
  { label: 'Villa Juárez', value: 'Villa Juárez' },
  { label: 'Villa Juárez', value: 'Villa Juárez' },
  { label: 'Villa Juárez', value: 'Villa Juárez' },
  { label: 'Villa Juárez', value: 'Villa Juárez' },
  { label: 'Villa Juárez', value: 'Villa Juárez' },
  { label: 'Villa La Angostura', value: 'Villa La Angostura' },
  { label: 'Villa La Venta', value: 'Villa La Venta' },
  { label: 'Villa Lagarina', value: 'Villa Lagarina' },
  { label: 'Villa Las Esperanzas', value: 'Villa Las Esperanzas' },
  { label: 'Villa Las Flores', value: 'Villa Las Flores' },
  { label: 'Villa Las Rosas', value: 'Villa Las Rosas' },
  { label: 'Villa Latina', value: 'Villa Latina' },
  { label: 'Villa Lázaro Cárdenas', value: 'Villa Lázaro Cárdenas' },
  { label: 'Villa Lempa', value: 'Villa Lempa' },
  { label: 'Villa Licenciado Jesús Terán (Calvillito)', value: 'Villa Licenciado Jesús Terán (Calvillito)' },
  { label: 'Villa Literno', value: 'Villa Literno' },
  { label: 'Villa Lourdes', value: 'Villa Lourdes' },
  { label: 'Villa Lugano', value: 'Villa Lugano' },
  { label: 'Villa Luvianos', value: 'Villa Luvianos' },
  { label: 'Villa Madero', value: 'Villa Madero' },
  { label: 'Villa Madero', value: 'Villa Madero' },
  { label: 'Villa Madero', value: 'Villa Madero' },
  { label: 'Villa Magna', value: 'Villa Magna' },
  { label: 'Villa Mainero', value: 'Villa Mainero' },
  { label: 'Villa Mantero', value: 'Villa Mantero' },
  { label: 'Villa María', value: 'Villa María' },
  { label: 'Villa María Grande', value: 'Villa María Grande' },
  { label: 'Villa Mariano Matamoros', value: 'Villa Mariano Matamoros' },
  { label: 'Villa Mercedes', value: 'Villa Mercedes' },
  { label: 'Villa Minozzo', value: 'Villa Minozzo' },
  { label: 'Villa Montemorelos', value: 'Villa Montemorelos' },
  { label: 'Villa Morelos', value: 'Villa Morelos' },
  { label: 'Villa Morelos', value: 'Villa Morelos' },
  { label: 'Villa Mugueta', value: 'Villa Mugueta' },
  { label: 'Villa Muñoz', value: 'Villa Muñoz' },
  { label: 'Villa Musone', value: 'Villa Musone' },
  { label: 'Villa Nicolás Bravo', value: 'Villa Nicolás Bravo' },
  { label: 'Villa Nueva', value: 'Villa Nueva' },
  { label: 'Villa Nueva', value: 'Villa Nueva' },
  { label: 'Villa Nueva', value: 'Villa Nueva' },
  { label: 'Villa Ocampo', value: 'Villa Ocampo' },
  { label: 'Villa Ocampo', value: 'Villa Ocampo' },
  { label: 'Villa Ojo De Agua', value: 'Villa Ojo De Agua' },
  { label: 'Villa Opicina', value: 'Villa Opicina' },
  { label: 'Villa Ortúzar', value: 'Villa Ortúzar' },
  { label: 'Villa Paranacito', value: 'Villa Paranacito' },
  { label: 'Villa Park', value: 'Villa Park' },
  { label: 'Villa Park', value: 'Villa Park' },
  { label: 'Villa Paula De Sarmiento', value: 'Villa Paula De Sarmiento' },
  { label: 'Villa Pedergnano', value: 'Villa Pedergnano' },
  { label: 'Villa Pesqueira', value: 'Villa Pesqueira' },
  { label: 'Villa Pigna', value: 'Villa Pigna' },
  { label: 'Villa Poma', value: 'Villa Poma' },
  { label: 'Villa Potenza', value: 'Villa Potenza' },
  { label: 'Villa Progreso', value: 'Villa Progreso' },
  { label: 'Villa Purificación', value: 'Villa Purificación' },
  { label: 'Villa Raspa', value: 'Villa Raspa' },
  { label: 'Villa Raverio', value: 'Villa Raverio' },
  { label: 'Villa Reducción', value: 'Villa Reducción' },
  { label: 'Villa Regina', value: 'Villa Regina' },
  { label: 'Villa Rendena', value: 'Villa Rendena' },
  { label: 'Villa Rica', value: 'Villa Rica' },
  { label: 'Villa Rica', value: 'Villa Rica' },
  { label: 'Villa Rica', value: 'Villa Rica' },
  { label: 'Villa Ridge', value: 'Villa Ridge' },
  { label: 'Villa Riva', value: 'Villa Riva' },
  { label: 'Villa Rosa', value: 'Villa Rosa' },
  { label: 'Villa Rosario', value: 'Villa Rosario' },
  { label: 'Villa Rotaria', value: 'Villa Rotaria' },
  { label: 'Villa Rumipal', value: 'Villa Rumipal' },
  { label: 'Villa San Filippo', value: 'Villa San Filippo' },
  { label: 'Villa San Giovanni', value: 'Villa San Giovanni' },
  { label: 'Villa San Giovanni In Tuscia', value: 'Villa San Giovanni In Tuscia' },
  { label: 'Villa San Giuseppe', value: 'Villa San Giuseppe' },
  { label: 'Villa San Pietro', value: 'Villa San Pietro' },
  { label: 'Villa San Secondo', value: 'Villa San Secondo' },
  { label: 'Villa Sandino', value: 'Villa Sandino' },
  { label: 'Villa Santa Lucia', value: 'Villa Santa Lucia' },
  { label: 'Villa Santa Lucia Degli Abruzzi', value: 'Villa Santa Lucia Degli Abruzzi' },
  { label: 'Villa Santa Maria', value: 'Villa Santa Maria' },
  { label: 'Villa Santa Rita', value: 'Villa Santa Rita' },
  { label: 'Villa Santangelo', value: 'Villa Santangelo' },
  { label: 'Villa Santantonio', value: 'Villa Santantonio' },
  { label: 'Villa Santiago', value: 'Villa Santiago' },
  { label: 'Villa Santina', value: 'Villa Santina' },
  { label: 'Villa Santo Stefano', value: 'Villa Santo Stefano' },
  { label: 'Villa Sara', value: 'Villa Sara' },
  { label: 'Villa Sola De Vega', value: 'Villa Sola De Vega' },
  { label: 'Villa Soriano', value: 'Villa Soriano' },
  { label: 'Villa Talea De Castro', value: 'Villa Talea De Castro' },
  { label: 'Villa Tapia', value: 'Villa Tapia' },
  { label: 'Villa Tecolutilla', value: 'Villa Tecolutilla' },
  { label: 'Villa Tejúpam De La Unión', value: 'Villa Tejúpam De La Unión' },
  { label: 'Villa Trinidad', value: 'Villa Trinidad' },
  { label: 'Villa Tulumba', value: 'Villa Tulumba' },
  { label: 'Villa Tupac Amaru', value: 'Villa Tupac Amaru' },
  { label: 'Villa Unida', value: 'Villa Unida' },
  { label: 'Villa Union', value: 'Villa Union' },
  { label: 'Villa Unión', value: 'Villa Unión' },
  { label: 'Villa Unión', value: 'Villa Unión' },
  { label: 'Villa Unión', value: 'Villa Unión' },
  { label: 'Villa Urbi Del Rey', value: 'Villa Urbi Del Rey' },
  { label: 'Villa Urquiza', value: 'Villa Urquiza' },
  { label: 'Villa Valeria', value: 'Villa Valeria' },
  { label: 'Villa Vásquez', value: 'Villa Vásquez' },
  { label: 'Villa Verde', value: 'Villa Verde' },
  { label: 'Villa Verucchio', value: 'Villa Verucchio' },
  { label: 'Villa Vicente Guerrero', value: 'Villa Vicente Guerrero' },
  { label: 'Villa Vicente Guerrero', value: 'Villa Vicente Guerrero' },
  { label: 'Villa Vicentina', value: 'Villa Vicentina' },
  { label: 'Villa Victoria', value: 'Villa Victoria' },
  { label: 'Villa Victoria', value: 'Villa Victoria' },
  { label: 'Villa Yapacaní', value: 'Villa Yapacaní' },
  { label: 'Villa Zaragoza', value: 'Villa Zaragoza' },
  { label: 'Villa Zempoala', value: 'Villa Zempoala' },
  { label: 'Villa-Agnedo', value: 'Villa-Agnedo' },
  { label: 'Villaba', value: 'Villaba' },
  { label: 'Villaba', value: 'Villaba' },
  { label: 'Villabáñez', value: 'Villabáñez' },
  { label: 'Villabaruz De Campos', value: 'Villabaruz De Campos' },
  { label: 'Villabassa', value: 'Villabassa' },
  { label: 'Villabasta De Valdavia', value: 'Villabasta De Valdavia' },
  { label: 'Villabate', value: 'Villabate' },
  { label: 'Villabé', value: 'Villabé' },
  { label: 'Villablanca', value: 'Villablanca' },
  { label: 'Villablino', value: 'Villablino' },
  { label: 'Villabona', value: 'Villabona' },
  { label: 'Villa-Borgo', value: 'Villa-Borgo' },
  { label: 'Villabrágima', value: 'Villabrágima' },
  { label: 'Villabraz', value: 'Villabraz' },
  { label: 'Villabrázaro', value: 'Villabrázaro' },
  { label: 'Villabruna-Umin', value: 'Villabruna-Umin' },
  { label: 'Villabuena De Álava/Eskuernaga', value: 'Villabuena De Álava/Eskuernaga' },
  { label: 'Villabuena Del Puente', value: 'Villabuena Del Puente' },
  { label: 'Villacañas', value: 'Villacañas' },
  { label: 'Villacarralón', value: 'Villacarralón' },
  { label: 'Villacarriedo', value: 'Villacarriedo' },
  { label: 'Villacarrillo', value: 'Villacarrillo' },
  { label: 'Villacastín', value: 'Villacastín' },
  { label: 'Villach', value: 'Villach' },
  { label: 'Villacher Vorstadt', value: 'Villacher Vorstadt' },
  { label: 'Villachiara', value: 'Villachiara' },
  { label: 'Villach-Innere Stadt', value: 'Villach-Innere Stadt' },
  { label: 'Villach-Land District', value: 'Villach-Land District' },
  { label: 'Villachuato', value: 'Villachuato' },
  { label: 'Villacid De Campos', value: 'Villacid De Campos' },
  { label: 'Villacidaler', value: 'Villacidaler' },
  { label: 'Villacidro', value: 'Villacidro' },
  { label: 'Villaciervos', value: 'Villaciervos' },
  { label: 'Villaco', value: 'Villaco' },
  { label: 'Villaconancio', value: 'Villaconancio' },
  { label: 'Villaconejos', value: 'Villaconejos' },
  { label: 'Villaconejos De Trabaque', value: 'Villaconejos De Trabaque' },
  { label: 'Villada', value: 'Villada' },
  { label: 'Villadangos Del Páramo', value: 'Villadangos Del Páramo' },
  { label: 'Villadeati', value: 'Villadeati' },
  { label: 'Villadecanes', value: 'Villadecanes' },
  { label: 'Villademor De La Vega', value: 'Villademor De La Vega' },
  { label: 'Villadepera', value: 'Villadepera' },
  { label: 'Villadiego', value: 'Villadiego' },
  { label: 'Villadose', value: 'Villadose' },
  { label: 'Villadossola', value: 'Villadossola' },
  { label: 'Villadoz', value: 'Villadoz' },
  { label: 'Villaeles De Valdavia', value: 'Villaeles De Valdavia' },
  { label: 'Villaescusa', value: 'Villaescusa' },
  { label: 'Villaescusa De Haro', value: 'Villaescusa De Haro' },
  { label: 'Villaescusa De Roa', value: 'Villaescusa De Roa' },
  { label: 'Villaescusa La Sombría', value: 'Villaescusa La Sombría' },
  { label: 'Villaespasa', value: 'Villaespasa' },
  { label: 'Villafáfila', value: 'Villafáfila' },
  { label: 'Villafalletto', value: 'Villafalletto' },
  { label: 'Villafamés', value: 'Villafamés' },
  { label: 'Villafeliche', value: 'Villafeliche' },
  { label: 'Villaferrueña', value: 'Villaferrueña' },
  { label: 'Villaflor', value: 'Villaflor' },
  { label: 'Villaflores', value: 'Villaflores' },
  { label: 'Villaflores', value: 'Villaflores' },
  { label: 'Villafrades De Campos', value: 'Villafrades De Campos' },
  { label: 'Villafranca', value: 'Villafranca' },
  { label: 'Villafranca', value: 'Villafranca' },
  { label: 'Villafranca Dasti', value: 'Villafranca Dasti' },
  { label: 'Villafranca De Córdoba', value: 'Villafranca De Córdoba' },
  { label: 'Villafranca De Duero', value: 'Villafranca De Duero' },
  { label: 'Villafranca De Ebro', value: 'Villafranca De Ebro' },
  { label: 'Villafranca De La Sierra', value: 'Villafranca De La Sierra' },
  { label: 'Villafranca De Los Barros', value: 'Villafranca De Los Barros' },
  { label: 'Villafranca De Los Caballeros', value: 'Villafranca De Los Caballeros' },
  { label: 'Villafranca Del Bierzo', value: 'Villafranca Del Bierzo' },
  { label: 'Villafranca Del Campo', value: 'Villafranca Del Campo' },
  { label: 'Villafranca Del Cid', value: 'Villafranca Del Cid' },
  { label: 'Villafranca Del Cid/Vilafranca', value: 'Villafranca Del Cid/Vilafranca' },
  { label: 'Villafranca Di Verona', value: 'Villafranca Di Verona' },
  { label: 'Villafranca In Lunigiana', value: 'Villafranca In Lunigiana' },
  { label: 'Villafranca Montes De Oca', value: 'Villafranca Montes De Oca' },
  { label: 'Villafranca Padovana', value: 'Villafranca Padovana' },
  { label: 'Villafranca Piemonte', value: 'Villafranca Piemonte' },
  { label: 'Villafranca Sicula', value: 'Villafranca Sicula' },
  { label: 'Villafranca Tirrena', value: 'Villafranca Tirrena' },
  { label: 'Villafrati', value: 'Villafrati' },
  { label: 'Villafrechós', value: 'Villafrechós' },
  { label: 'Villafruela', value: 'Villafruela' },
  { label: 'Villafuerte', value: 'Villafuerte' },
  { label: 'Villafufre', value: 'Villafufre' },
  { label: 'Villaga', value: 'Villaga' },
  { label: 'Villagalijo', value: 'Villagalijo' },
  { label: 'Villaganzerla', value: 'Villaganzerla' },
  { label: 'Villagarcía De Campos', value: 'Villagarcía De Campos' },
  { label: 'Villagarcía De La Torre', value: 'Villagarcía De La Torre' },
  { label: 'Villagarcía Del Llano', value: 'Villagarcía Del Llano' },
  { label: 'Villagarzón', value: 'Villagarzón' },
  { label: 'Villagatón', value: 'Villagatón' },
  { label: 'Village', value: 'Village' },
  { label: 'Village', value: 'Village' },
  { label: 'Village', value: 'Village' },
  { label: 'Village', value: 'Village' },
  { label: 'Village', value: 'Village' },
  { label: 'Village Green', value: 'Village Green' },
  { label: 'Village Green-Green Ridge', value: 'Village Green-Green Ridge' },
  { label: 'Village Of Alamrah', value: 'Village Of Alamrah' },
  { label: 'Village Of Campton Hills', value: 'Village Of Campton Hills' },
  { label: 'Village Of Oak Creek (Big Park)', value: 'Village Of Oak Creek (Big Park)' },
  { label: 'Village Of The Branch', value: 'Village Of The Branch' },
  { label: 'Village Park', value: 'Village Park' },
  { label: 'Village Saint George', value: 'Village Saint George' },
  { label: 'Village Shires', value: 'Village Shires' },
  { label: 'Village/Petite Bourgh', value: 'Village/Petite Bourgh' },
  { label: 'Village-Neuf', value: 'Village-Neuf' },
  { label: 'Villageriz', value: 'Villageriz' },
  { label: 'Villages Of Oriole', value: 'Villages Of Oriole' },
  { label: 'Villaggio Del Pino-Le Ginestre', value: 'Villaggio Del Pino-Le Ginestre' },
  { label: 'Villaggio Del Sole', value: 'Villaggio Del Sole' },
  { label: 'Villaggio Montegrappa', value: 'Villaggio Montegrappa' },
  { label: 'Villaggio Residenziale', value: 'Villaggio Residenziale' },
  { label: 'Villagómez', value: 'Villagómez' },
  { label: 'Villagómez La Nueva', value: 'Villagómez La Nueva' },
  { label: 'Villagonzalo', value: 'Villagonzalo' },
  { label: 'Villagonzalo De Tormes', value: 'Villagonzalo De Tormes' },
  { label: 'Villagonzalo Pedernales', value: 'Villagonzalo Pedernales' },
  { label: 'Villagonzalo-Pedernales', value: 'Villagonzalo-Pedernales' },
  { label: 'Villagrán', value: 'Villagrán' },
  { label: 'Villagrán', value: 'Villagrán' },
  { label: 'Villagrande', value: 'Villagrande' },
  { label: 'Villagrande', value: 'Villagrande' },
  { label: 'Villagrande Strisaili', value: 'Villagrande Strisaili' },
  { label: 'Villaguattera', value: 'Villaguattera' },
  { label: 'Villaguay', value: 'Villaguay' },
  { label: 'Villahán', value: 'Villahán' },
  { label: 'Villaharta', value: 'Villaharta' },
  { label: 'Villahermosa', value: 'Villahermosa' },
  { label: 'Villahermosa', value: 'Villahermosa' },
  { label: 'Villahermosa', value: 'Villahermosa' },
  { label: 'Villahermosa', value: 'Villahermosa' },
  { label: 'Villahermosa Del Campo', value: 'Villahermosa Del Campo' },
  { label: 'Villahermosa Del Río', value: 'Villahermosa Del Río' },
  { label: 'Villahermosa Yaluma', value: 'Villahermosa Yaluma' },
  { label: 'Villaherreros', value: 'Villaherreros' },
  { label: 'Villahoz', value: 'Villahoz' },
  { label: 'Villaines-La-Juhel', value: 'Villaines-La-Juhel' },
  { label: 'Villair-Amerique', value: 'Villair-Amerique' },
  { label: 'Villajoyosa', value: 'Villajoyosa' },
  { label: 'Villajoyosa/Vila Joiosa La', value: 'Villajoyosa/Vila Joiosa La' },
  { label: 'Villalaco', value: 'Villalaco' },
  { label: 'Villalago', value: 'Villalago' },
  { label: 'Villalán De Campos', value: 'Villalán De Campos' },
  { label: 'Villalar De Los Comuneros', value: 'Villalar De Los Comuneros' },
  { label: 'Villalazán', value: 'Villalazán' },
  { label: 'Villalba', value: 'Villalba' },
  { label: 'Villalba', value: 'Villalba' },
  { label: 'Villalba', value: 'Villalba' },
  { label: 'Villalba', value: 'Villalba' },
  { label: 'Villalba De Duero', value: 'Villalba De Duero' },
  { label: 'Villalba De Guardo', value: 'Villalba De Guardo' },
  { label: 'Villalba De La Lampreana', value: 'Villalba De La Lampreana' },
  { label: 'Villalba De La Loma', value: 'Villalba De La Loma' },
  { label: 'Villalba De La Sierra', value: 'Villalba De La Sierra' },
  { label: 'Villalba De Los Alcores', value: 'Villalba De Los Alcores' },
  { label: 'Villalba De Los Barros', value: 'Villalba De Los Barros' },
  { label: 'Villalba De Los Llanos', value: 'Villalba De Los Llanos' },
  { label: 'Villalba De Perejil', value: 'Villalba De Perejil' },
  { label: 'Villalba De Rioja', value: 'Villalba De Rioja' },
  { label: 'Villalba Del Alcor', value: 'Villalba Del Alcor' },
  { label: 'Villalba Del Rey', value: 'Villalba Del Rey' },
  { label: 'Villalba Municipio', value: 'Villalba Municipio' },
  { label: 'Villalbarba', value: 'Villalbarba' },
  { label: 'Villalbese', value: 'Villalbese' },
  { label: 'Villalbilla', value: 'Villalbilla' },
  { label: 'Villalbilla De Burgos', value: 'Villalbilla De Burgos' },
  { label: 'Villalbilla De Gumiel', value: 'Villalbilla De Gumiel' },
  { label: 'Villalcampo', value: 'Villalcampo' },
  { label: 'Villalcázar De Sirga', value: 'Villalcázar De Sirga' },
  { label: 'Villalcón', value: 'Villalcón' },
  { label: 'Villaldama', value: 'Villaldama' },
  { label: 'Villaldama Nuevo León', value: 'Villaldama Nuevo León' },
  { label: 'Villaldemiro', value: 'Villaldemiro' },
  { label: 'Villalengua', value: 'Villalengua' },
  { label: 'Villalfonsina', value: 'Villalfonsina' },
  { label: 'Villalgordo Del Júcar', value: 'Villalgordo Del Júcar' },
  { label: 'Villalgordo Del Marquesado', value: 'Villalgordo Del Marquesado' },
  { label: 'Villalmanzo', value: 'Villalmanzo' },
  { label: 'Villalobar De Rioja', value: 'Villalobar De Rioja' },
  { label: 'Villalobón', value: 'Villalobón' },
  { label: 'Villalobos', value: 'Villalobos' },
  { label: 'Villalón De Campos', value: 'Villalón De Campos' },
  { label: 'Villalonga', value: 'Villalonga' },
  { label: 'Villalonso', value: 'Villalonso' },
  { label: 'Villalpando', value: 'Villalpando' },
  { label: 'Villalpardo', value: 'Villalpardo' },
  { label: 'Villalube', value: 'Villalube' },
  { label: 'Villaluenga De La Sagra', value: 'Villaluenga De La Sagra' },
  { label: 'Villaluenga De La Vega', value: 'Villaluenga De La Vega' },
  { label: 'Villaluenga Del Rosario', value: 'Villaluenga Del Rosario' },
  { label: 'Villalvernia', value: 'Villalvernia' },
  { label: 'Villamagna', value: 'Villamagna' },
  { label: 'Villamaina', value: 'Villamaina' },
  { label: 'Villamalea', value: 'Villamalea' },
  { label: 'Villamalur', value: 'Villamalur' },
  { label: 'Villamañán', value: 'Villamañán' },
  { label: 'Villamandos', value: 'Villamandos' },
  { label: 'Villamanín', value: 'Villamanín' },
  { label: 'Villamanrique', value: 'Villamanrique' },
  { label: 'Villamanrique De La Condesa', value: 'Villamanrique De La Condesa' },
  { label: 'Villamanrique De Tajo', value: 'Villamanrique De Tajo' },
  { label: 'Villamanta', value: 'Villamanta' },
  { label: 'Villamantilla', value: 'Villamantilla' },
  { label: 'Villamar', value: 'Villamar' },
  { label: 'Villamar', value: 'Villamar' },
  { label: 'Villamaría', value: 'Villamaría' },
  { label: 'Villamartín', value: 'Villamartín' },
  { label: 'Villamartín De Campos', value: 'Villamartín De Campos' },
  { label: 'Villamartín De Don Sancho', value: 'Villamartín De Don Sancho' },
  { label: 'Villamarzana', value: 'Villamarzana' },
  { label: 'Villamassargia', value: 'Villamassargia' },
  { label: 'Villamayor', value: 'Villamayor' },
  { label: 'Villamayor De Calatrava', value: 'Villamayor De Calatrava' },
  { label: 'Villamayor De Campos', value: 'Villamayor De Campos' },
  { label: 'Villamayor De Gállego', value: 'Villamayor De Gállego' },
  { label: 'Villamayor De Los Montes', value: 'Villamayor De Los Montes' },
  { label: 'Villamayor De Monjardín', value: 'Villamayor De Monjardín' },
  { label: 'Villamayor De Santiago', value: 'Villamayor De Santiago' },
  { label: 'Villamayor De Treviño', value: 'Villamayor De Treviño' },
  { label: 'Villambistia', value: 'Villambistia' },
  { label: 'Villamediana', value: 'Villamediana' },
  { label: 'Villamediana De Iregua', value: 'Villamediana De Iregua' },
  { label: 'Villamedianilla', value: 'Villamedianilla' },
  { label: 'Villamejil', value: 'Villamejil' },
  { label: 'Villamena', value: 'Villamena' },
  { label: 'Villameriel', value: 'Villameriel' },
  { label: 'Villamesías', value: 'Villamesías' },
  { label: 'Villamiel', value: 'Villamiel' },
  { label: 'Villamiel De La Sierra', value: 'Villamiel De La Sierra' },
  { label: 'Villamiel De Toledo', value: 'Villamiel De Toledo' },
  { label: 'Villaminaya', value: 'Villaminaya' },
  { label: 'Villamiroglio', value: 'Villamiroglio' },
  { label: 'Villammare', value: 'Villammare' },
  { label: 'Villamol', value: 'Villamol' },
  { label: 'Villamontán De La Valduerna', value: 'Villamontán De La Valduerna' },
  { label: 'Villamontes', value: 'Villamontes' },
  { label: 'Villamor', value: 'Villamor' },
  { label: 'Villamor', value: 'Villamor' },
  { label: 'Villamor De Los Escuderos', value: 'Villamor De Los Escuderos' },
  { label: 'Villamoratiel De Las Matas', value: 'Villamoratiel De Las Matas' },
  { label: 'Villamorelos', value: 'Villamorelos' },
  { label: 'Villamoronta', value: 'Villamoronta' },
  { label: 'Villamuelas', value: 'Villamuelas' },
  { label: 'Villamuera De La Cueza', value: 'Villamuera De La Cueza' },
  { label: 'Villamuriel De Campos', value: 'Villamuriel De Campos' },
  { label: 'Villamuriel De Cerrato', value: 'Villamuriel De Cerrato' },
  { label: 'Villán De Tordesillas', value: 'Villán De Tordesillas' },
  { label: 'Villa-Nabian', value: 'Villa-Nabian' },
  { label: 'Villanázar', value: 'Villanázar' },
  { label: 'Villandro', value: 'Villandro' },
  { label: 'Villandro - Villanders', value: 'Villandro - Villanders' },
  { label: 'Villandry', value: 'Villandry' },
  { label: 'Villangómez', value: 'Villangómez' },
  { label: 'Villano Beach', value: 'Villano Beach' },
  { label: 'Villanova', value: 'Villanova' },
  { label: 'Villanova', value: 'Villanova' },
  { label: 'Villanova', value: 'Villanova' },
  { label: 'Villanova', value: 'Villanova' },
  { label: 'Villanova', value: 'Villanova' },
  { label: 'Villanova', value: 'Villanova' },
  { label: 'Villanova', value: 'Villanova' },
  { label: 'Villanova', value: 'Villanova' },
  { label: 'Villanova Biellese', value: 'Villanova Biellese' },
  { label: 'Villanova Canavese', value: 'Villanova Canavese' },
  { label: 'Villanova Dalbenga', value: 'Villanova Dalbenga' },
  { label: 'Villanova Dardenghi', value: 'Villanova Dardenghi' },
  { label: 'Villanova Dasti', value: 'Villanova Dasti' },
  { label: 'Villanova Del Battista', value: 'Villanova Del Battista' },
  { label: 'Villanova Del Ghebbo Canton', value: 'Villanova Del Ghebbo Canton' },
  { label: 'Villanova Del Sillaro', value: 'Villanova Del Sillaro' },
  { label: 'Villanova Marchesana', value: 'Villanova Marchesana' },
  { label: 'Villanova Mondovì', value: 'Villanova Mondovì' },
  { label: 'Villanova Monferrato', value: 'Villanova Monferrato' },
  { label: 'Villanova Monteleone', value: 'Villanova Monteleone' },
  { label: 'Villanova Solaro', value: 'Villanova Solaro' },
  { label: 'Villanova Truschedu', value: 'Villanova Truschedu' },
  { label: 'Villanova Tulo', value: 'Villanova Tulo' },
  { label: 'Villanovaforru', value: 'Villanovaforru' },
  { label: 'Villanovafranca', value: 'Villanovafranca' },
  { label: 'Villanterio', value: 'Villanterio' },
  { label: 'Villanúa', value: 'Villanúa' },
  { label: 'Villanubla', value: 'Villanubla' },
  { label: 'Villanueva', value: 'Villanueva' },
  { label: 'Villanueva', value: 'Villanueva' },
  { label: 'Villanueva', value: 'Villanueva' },
  { label: 'Villanueva', value: 'Villanueva' },
  { label: 'Villanueva', value: 'Villanueva' },
  { label: 'Villanueva', value: 'Villanueva' },
  { label: 'Villanueva', value: 'Villanueva' },
  { label: 'Villanueva', value: 'Villanueva' },
  { label: 'Villanueva De Alcardete', value: 'Villanueva De Alcardete' },
  { label: 'Villanueva De Alcorón', value: 'Villanueva De Alcorón' },
  { label: 'Villanueva De Algaidas', value: 'Villanueva De Algaidas' },
  { label: 'Villanueva De Argaño', value: 'Villanueva De Argaño' },
  { label: 'Villanueva De Argecilla', value: 'Villanueva De Argecilla' },
  { label: 'Villanueva De Ávila', value: 'Villanueva De Ávila' },
  { label: 'Villanueva De Azoague', value: 'Villanueva De Azoague' },
  { label: 'Villanueva De Bogas', value: 'Villanueva De Bogas' },
  { label: 'Villanueva De Cameros', value: 'Villanueva De Cameros' },
  { label: 'Villanueva De Campeán', value: 'Villanueva De Campeán' },
  { label: 'Villanueva De Carazo', value: 'Villanueva De Carazo' },
  { label: 'Villanueva De Castellón', value: 'Villanueva De Castellón' },
  { label: 'Villanueva De Córdoba', value: 'Villanueva De Córdoba' },
  { label: 'Villanueva De Duero', value: 'Villanueva De Duero' },
  { label: 'Villanueva De Gállego', value: 'Villanueva De Gállego' },
  { label: 'Villanueva De Gómez', value: 'Villanueva De Gómez' },
  { label: 'Villanueva De Gormaz', value: 'Villanueva De Gormaz' },
  { label: 'Villanueva De Guadamejud', value: 'Villanueva De Guadamejud' },
  { label: 'Villanueva De Gumiel', value: 'Villanueva De Gumiel' },
  { label: 'Villanueva De Huerva', value: 'Villanueva De Huerva' },
  { label: 'Villanueva De Jiloca', value: 'Villanueva De Jiloca' },
  { label: 'Villanueva De La Cañada', value: 'Villanueva De La Cañada' },
  { label: 'Villanueva De La Concepción', value: 'Villanueva De La Concepción' },
  { label: 'Villanueva De La Condesa', value: 'Villanueva De La Condesa' },
  { label: 'Villanueva De La Fuente', value: 'Villanueva De La Fuente' },
  { label: 'Villanueva De La Jara', value: 'Villanueva De La Jara' },
  { label: 'Villanueva De La Reina', value: 'Villanueva De La Reina' },
  { label: 'Villanueva De La Serena', value: 'Villanueva De La Serena' },
  { label: 'Villanueva De La Sierra', value: 'Villanueva De La Sierra' },
  { label: 'Villanueva De La Torre', value: 'Villanueva De La Torre' },
  { label: 'Villanueva De La Vera', value: 'Villanueva De La Vera' },
  { label: 'Villanueva De Las Cruces', value: 'Villanueva De Las Cruces' },
  { label: 'Villanueva De Las Manzanas', value: 'Villanueva De Las Manzanas' },
  { label: 'Villanueva De Las Peras', value: 'Villanueva De Las Peras' },
  { label: 'Villanueva De Las Torres', value: 'Villanueva De Las Torres' },
  { label: 'Villanueva De Los Caballeros', value: 'Villanueva De Los Caballeros' },
  { label: 'Villanueva De Los Castillejos', value: 'Villanueva De Los Castillejos' },
  { label: 'Villanueva De Los Infantes', value: 'Villanueva De Los Infantes' },
  { label: 'Villanueva De Oscos', value: 'Villanueva De Oscos' },
  { label: 'Villanueva De Perales', value: 'Villanueva De Perales' },
  { label: 'Villanueva De San Carlos', value: 'Villanueva De San Carlos' },
  { label: 'Villanueva De San Juan', value: 'Villanueva De San Juan' },
  { label: 'Villanueva De San Mancio', value: 'Villanueva De San Mancio' },
  { label: 'Villanueva De Sigena', value: 'Villanueva De Sigena' },
  { label: 'Villanueva De Tapia', value: 'Villanueva De Tapia' },
  { label: 'Villanueva De Teba', value: 'Villanueva De Teba' },
  { label: 'Villanueva De Viver', value: 'Villanueva De Viver' },
  { label: 'Villanueva Del Aceral', value: 'Villanueva Del Aceral' },
  { label: 'Villanueva Del Ariscal', value: 'Villanueva Del Ariscal' },
  { label: 'Villanueva Del Arzobispo', value: 'Villanueva Del Arzobispo' },
  { label: 'Villanueva Del Campillo', value: 'Villanueva Del Campillo' },
  { label: 'Villanueva Del Campo', value: 'Villanueva Del Campo' },
  { label: 'Villanueva Del Conde', value: 'Villanueva Del Conde' },
  { label: 'Villanueva Del Duque', value: 'Villanueva Del Duque' },
  { label: 'Villanueva Del Fresno', value: 'Villanueva Del Fresno' },
  { label: 'Villanueva Del Pardillo', value: 'Villanueva Del Pardillo' },
  { label: 'Villanueva Del Rebollar', value: 'Villanueva Del Rebollar' },
  { label: 'Villanueva Del Rebollar De La Sierra', value: 'Villanueva Del Rebollar De La Sierra' },
  { label: 'Villanueva Del Rey', value: 'Villanueva Del Rey' },
  { label: 'Villanueva Del Río Segura', value: 'Villanueva Del Río Segura' },
  { label: 'Villanueva Del Río Y Minas', value: 'Villanueva Del Río Y Minas' },
  { label: 'Villanueva Del Rosario', value: 'Villanueva Del Rosario' },
  { label: 'Villanueva Del Trabuco', value: 'Villanueva Del Trabuco' },
  { label: 'Villanueva Mesía', value: 'Villanueva Mesía' },
  { label: 'Villanuño De Valdavia', value: 'Villanuño De Valdavia' },
  { label: 'Villanuova', value: 'Villanuova' },
  { label: 'Villanuova Sul Clisi', value: 'Villanuova Sul Clisi' },
  { label: 'Villány', value: 'Villány' },
  { label: 'Villaobispo De Otero', value: 'Villaobispo De Otero' },
  { label: 'Villaornate Y Castro', value: 'Villaornate Y Castro' },
  { label: 'Villapalacios', value: 'Villapalacios' },
  { label: 'Villapark', value: 'Villapark' },
  { label: 'Villaperuccio', value: 'Villaperuccio' },
  { label: 'Villapiana', value: 'Villapiana' },
  { label: 'Villapiana Lido', value: 'Villapiana Lido' },
  { label: 'Villapinta', value: 'Villapinta' },
  { label: 'Villapinzón', value: 'Villapinzón' },
  { label: 'Villaprovedo', value: 'Villaprovedo' },
  { label: 'Villaputzu', value: 'Villaputzu' },
  { label: 'Villaquejida', value: 'Villaquejida' },
  { label: 'Villaquilambre', value: 'Villaquilambre' },
  { label: 'Villaquirán De La Puebla', value: 'Villaquirán De La Puebla' },
  { label: 'Villaquirán De Los Infantes', value: 'Villaquirán De Los Infantes' },
  { label: 'Villar De Argañán', value: 'Villar De Argañán' },
  { label: 'Villar De Arnedo El', value: 'Villar De Arnedo El' },
  { label: 'Villar De Cañas', value: 'Villar De Cañas' },
  { label: 'Villar De Ciervo', value: 'Villar De Ciervo' },
  { label: 'Villar De Corneja', value: 'Villar De Corneja' },
  { label: 'Villar De Domingo García', value: 'Villar De Domingo García' },
  { label: 'Villar De Fallaves', value: 'Villar De Fallaves' },
  { label: 'Villar De Gallimazo', value: 'Villar De Gallimazo' },
  { label: 'Villar De La Encina', value: 'Villar De La Encina' },
  { label: 'Villar De La Yegua', value: 'Villar De La Yegua' },
  { label: 'Villar De Los Navarros', value: 'Villar De Los Navarros' },
  { label: 'Villar De Olalla', value: 'Villar De Olalla' },
  { label: 'Villar De Peralonso', value: 'Villar De Peralonso' },
  { label: 'Villar De Plasencia', value: 'Villar De Plasencia' },
  { label: 'Villar De Rena', value: 'Villar De Rena' },
  { label: 'Villar De Samaniego', value: 'Villar De Samaniego' },
  { label: 'Villar De Torre', value: 'Villar De Torre' },
  { label: 'Villar Del Ala', value: 'Villar Del Ala' },
  { label: 'Villar Del Arzobispo', value: 'Villar Del Arzobispo' },
  { label: 'Villar Del Buey', value: 'Villar Del Buey' },
  { label: 'Villar Del Campo', value: 'Villar Del Campo' },
  { label: 'Villar Del Cobo', value: 'Villar Del Cobo' },
  { label: 'Villar Del Humo', value: 'Villar Del Humo' },
  { label: 'Villar Del Infantado', value: 'Villar Del Infantado' },
  { label: 'Villar Del Olmo', value: 'Villar Del Olmo' },
  { label: 'Villar Del Pedroso', value: 'Villar Del Pedroso' },
  { label: 'Villar Del Pozo', value: 'Villar Del Pozo' },
  { label: 'Villar Del Rey', value: 'Villar Del Rey' },
  { label: 'Villar Del Río', value: 'Villar Del Río' },
  { label: 'Villar Del Salz', value: 'Villar Del Salz' },
  { label: 'Villar Dora', value: 'Villar Dora' },
  { label: 'Villar Focchiardo', value: 'Villar Focchiardo' },
  { label: 'Villar Pellice', value: 'Villar Pellice' },
  { label: 'Villar Perosa', value: 'Villar Perosa' },
  { label: 'Villar San Costanzo', value: 'Villar San Costanzo' },
  { label: 'Villar Y Velasco', value: 'Villar Y Velasco' },
  { label: 'Villaralbo', value: 'Villaralbo' },
  { label: 'Villaralto', value: 'Villaralto' },
  { label: 'Villarbasse', value: 'Villarbasse' },
  { label: 'Villarboit', value: 'Villarboit' },
  { label: 'Villarcayo De Merindad De Castilla La Vieja', value: 'Villarcayo De Merindad De Castilla La Vieja' },
  { label: 'Villard-Bonnot', value: 'Villard-Bonnot' },
  { label: 'Villard-De-Lans', value: 'Villard-De-Lans' },
  { label: 'Villardeciervos', value: 'Villardeciervos' },
  { label: 'Villardefrades', value: 'Villardefrades' },
  { label: 'Villardiegua De La Ribera', value: 'Villardiegua De La Ribera' },
  { label: 'Villárdiga', value: 'Villárdiga' },
  { label: 'Villardompardo', value: 'Villardompardo' },
  { label: 'Villardondiego', value: 'Villardondiego' },
  { label: 'Villareal', value: 'Villareal' },
  { label: 'Villareal', value: 'Villareal' },
  { label: 'Villareggia', value: 'Villareggia' },
  { label: 'Villarejo', value: 'Villarejo' },
  { label: 'Villarejo De Fuentes', value: 'Villarejo De Fuentes' },
  { label: 'Villarejo De La Peñuela', value: 'Villarejo De La Peñuela' },
  { label: 'Villarejo De Montalbán', value: 'Villarejo De Montalbán' },
  { label: 'Villarejo De Órbigo', value: 'Villarejo De Órbigo' },
  { label: 'Villarejo De Salvanés', value: 'Villarejo De Salvanés' },
  { label: 'Villarejo Del Valle', value: 'Villarejo Del Valle' },
  { label: 'Villarejo-Periesteban', value: 'Villarejo-Periesteban' },
  { label: 'Villares De Jadraque', value: 'Villares De Jadraque' },
  { label: 'Villares De La Reina', value: 'Villares De La Reina' },
  { label: 'Villares De Órbigo', value: 'Villares De Órbigo' },
  { label: 'Villares De Soria Los', value: 'Villares De Soria Los' },
  { label: 'Villares De Yeltes', value: 'Villares De Yeltes' },
  { label: 'Villares Del Saz', value: 'Villares Del Saz' },
  { label: 'Villares Los', value: 'Villares Los' },
  { label: 'Villargondran', value: 'Villargondran' },
  { label: 'Villargordo Del Cabriel', value: 'Villargordo Del Cabriel' },
  { label: 'Villaricca', value: 'Villaricca' },
  { label: 'Villariezo', value: 'Villariezo' },
  { label: 'Villarino De Los Aires', value: 'Villarino De Los Aires' },
  { label: 'Villarluengo', value: 'Villarluengo' },
  { label: 'Villarmayor', value: 'Villarmayor' },
  { label: 'Villarmentero De Campos', value: 'Villarmentero De Campos' },
  { label: 'Villarmentero De Esgueva', value: 'Villarmentero De Esgueva' },
  { label: 'Villarmuerto', value: 'Villarmuerto' },
  { label: 'Villaromagnano', value: 'Villaromagnano' },
  { label: 'Villarosa', value: 'Villarosa' },
  { label: 'Villarosa', value: 'Villarosa' },
  { label: 'Villarosa', value: 'Villarosa' },
  { label: 'Villarotta', value: 'Villarotta' },
  { label: 'Villarpando', value: 'Villarpando' },
  { label: 'Villarquemado', value: 'Villarquemado' },
  { label: 'Villarrabé', value: 'Villarrabé' },
  { label: 'Villarramiel', value: 'Villarramiel' },
  { label: 'Villarrasa', value: 'Villarrasa' },
  { label: 'Villarreal De Huerva', value: 'Villarreal De Huerva' },
  { label: 'Villarrica', value: 'Villarrica' },
  { label: 'Villarrica', value: 'Villarrica' },
  { label: 'Villarrica', value: 'Villarrica' },
  { label: 'Villarrín De Campos', value: 'Villarrín De Campos' },
  { label: 'Villarrobledo', value: 'Villarrobledo' },
  { label: 'Villarrodrigo', value: 'Villarrodrigo' },
  { label: 'Villarroya', value: 'Villarroya' },
  { label: 'Villarroya De La Sierra', value: 'Villarroya De La Sierra' },
  { label: 'Villarroya De Los Pinares', value: 'Villarroya De Los Pinares' },
  { label: 'Villarroya Del Campo', value: 'Villarroya Del Campo' },
  { label: 'Villarrubia De Los Ojos', value: 'Villarrubia De Los Ojos' },
  { label: 'Villarrubia De Santiago', value: 'Villarrubia De Santiago' },
  { label: 'Villarrubio', value: 'Villarrubio' },
  { label: 'Villars', value: 'Villars' },
  { label: 'Villar-Saint-Pancrace', value: 'Villar-Saint-Pancrace' },
  { label: 'Villars-Les-Dombes', value: 'Villars-Les-Dombes' },
  { label: 'Villars-Sur-Glâne', value: 'Villars-Sur-Glâne' },
  { label: 'Villars-Sur-Ollon', value: 'Villars-Sur-Ollon' },
  { label: 'Villarta', value: 'Villarta' },
  { label: 'Villarta De Los Montes', value: 'Villarta De Los Montes' },
  { label: 'Villarta De San Juan', value: 'Villarta De San Juan' },
  { label: 'Villarta-Quintana', value: 'Villarta-Quintana' },
  { label: 'Villas', value: 'Villas' },
  { label: 'Villas', value: 'Villas' },
  { label: 'Villas De Alcalá', value: 'Villas De Alcalá' },
  { label: 'Villas De Irapuato', value: 'Villas De Irapuato' },
  { label: 'Villas De La Hacienda [Fraccionamiento]', value: 'Villas De La Hacienda [Fraccionamiento]' },
  { label: 'Villas De La Laguna', value: 'Villas De La Laguna' },
  { label: 'Villas De La Loma', value: 'Villas De La Loma' },
  { label: 'Villas De La Ventosa', value: 'Villas De La Ventosa' },
  { label: 'Villas Del Campo', value: 'Villas Del Campo' },
  { label: 'Villas Fundadores [Fraccionamiento]', value: 'Villas Fundadores [Fraccionamiento]' },
  { label: 'Villasabariego', value: 'Villasabariego' },
  { label: 'Villasalto', value: 'Villasalto' },
  { label: 'Villasana De Mena', value: 'Villasana De Mena' },
  { label: 'Villasandino', value: 'Villasandino' },
  { label: 'Villasanta', value: 'Villasanta' },
  { label: 'Villasarracino', value: 'Villasarracino' },
  { label: 'Villasayas', value: 'Villasayas' },
  { label: 'Villasbuenas', value: 'Villasbuenas' },
  { label: 'Villasbuenas De Gata', value: 'Villasbuenas De Gata' },
  { label: 'Villasdardo', value: 'Villasdardo' },
  { label: 'Villaseca De Arciel', value: 'Villaseca De Arciel' },
  { label: 'Villaseca De Henares', value: 'Villaseca De Henares' },
  { label: 'Villaseca De La Sagra', value: 'Villaseca De La Sagra' },
  { label: 'Villaseca De Uceda', value: 'Villaseca De Uceda' },
  { label: 'Villaseco De Los Gamitos', value: 'Villaseco De Los Gamitos' },
  { label: 'Villaseco De Los Reyes', value: 'Villaseco De Los Reyes' },
  { label: 'Villaseco Del Pan', value: 'Villaseco Del Pan' },
  { label: 'Villaselán', value: 'Villaselán' },
  { label: 'Villasequilla', value: 'Villasequilla' },
  { label: 'Villaseta', value: 'Villaseta' },
  { label: 'Villasexmir', value: 'Villasexmir' },
  { label: 'Villasila De Valdavia', value: 'Villasila De Valdavia' },
  { label: 'Villasimius', value: 'Villasimius' },
  { label: 'Villasis', value: 'Villasis' },
  { label: 'Villasis', value: 'Villasis' },
  { label: 'Villasmundo', value: 'Villasmundo' },
  { label: 'Villasor', value: 'Villasor' },
  { label: 'Villaspeciosa', value: 'Villaspeciosa' },
  { label: 'Villasrubias', value: 'Villasrubias' },
  { label: 'Villassio', value: 'Villassio' },
  { label: 'Villastar', value: 'Villastar' },
  { label: 'Villastellone', value: 'Villastellone' },
  { label: 'Villasur De Herreros', value: 'Villasur De Herreros' },
  { label: 'Villata', value: 'Villata' },
  { label: 'Villatobas', value: 'Villatobas' },
  { label: 'Villatora', value: 'Villatora' },
  { label: 'Villatoro', value: 'Villatoro' },
  { label: 'Villatorres', value: 'Villatorres' },
  { label: 'Villatoya', value: 'Villatoya' },
  { label: 'Villatuelda', value: 'Villatuelda' },
  { label: 'Villatuerta', value: 'Villatuerta' },
  { label: 'Villaturde', value: 'Villaturde' },
  { label: 'Villaturiel', value: 'Villaturiel' },
  { label: 'Villaudric', value: 'Villaudric' },
  { label: 'Villaumbrales', value: 'Villaumbrales' },
  { label: 'Villaurbana', value: 'Villaurbana' },
  { label: 'Villava/Atarrabia', value: 'Villava/Atarrabia' },
  { label: 'Villavaliente', value: 'Villavaliente' },
  { label: 'Villavallelonga', value: 'Villavallelonga' },
  { label: 'Villavaquerín', value: 'Villavaquerín' },
  { label: 'Villavelayo', value: 'Villavelayo' },
  { label: 'Villavellid', value: 'Villavellid' },
  { label: 'Villavendimio', value: 'Villavendimio' },
  { label: 'Villaverde', value: 'Villaverde' },
  { label: 'Villaverde', value: 'Villaverde' },
  { label: 'Villaverde De Guadalimar', value: 'Villaverde De Guadalimar' },
  { label: 'Villaverde De Guareña', value: 'Villaverde De Guareña' },
  { label: 'Villaverde De Íscar', value: 'Villaverde De Íscar' },
  { label: 'Villaverde De Medina', value: 'Villaverde De Medina' },
  { label: 'Villaverde De Montejo', value: 'Villaverde De Montejo' },
  { label: 'Villaverde De Rioja', value: 'Villaverde De Rioja' },
  { label: 'Villaverde Del Monte', value: 'Villaverde Del Monte' },
  { label: 'Villaverde Del Río', value: 'Villaverde Del Río' },
  { label: 'Villaverde Y Pasaconsol', value: 'Villaverde Y Pasaconsol' },
  { label: 'Villaverde-Mogina', value: 'Villaverde-Mogina' },
  { label: 'Villaverla', value: 'Villaverla' },
  { label: 'Villaveza De Valverde', value: 'Villaveza De Valverde' },
  { label: 'Villaveza Del Agua', value: 'Villaveza Del Agua' },
  { label: 'Villavicencio', value: 'Villavicencio' },
  { label: 'Villavicencio De Los Caballeros', value: 'Villavicencio De Los Caballeros' },
  { label: 'Villaviciosa', value: 'Villaviciosa' },
  { label: 'Villaviciosa', value: 'Villaviciosa' },
  { label: 'Villaviciosa', value: 'Villaviciosa' },
  { label: 'Villaviciosa De Córdoba', value: 'Villaviciosa De Córdoba' },
  { label: 'Villaviciosa De Odón', value: 'Villaviciosa De Odón' },
  { label: 'Villavieja', value: 'Villavieja' },
  { label: 'Villavieja', value: 'Villavieja' },
  { label: 'Villavieja De Yeltes', value: 'Villavieja De Yeltes' },
  { label: 'Villavieja Del Lozoya', value: 'Villavieja Del Lozoya' },
  { label: 'Villaviudas', value: 'Villaviudas' },
  { label: 'Villawood', value: 'Villawood' },
  { label: 'Villayerno Morquillas', value: 'Villayerno Morquillas' },
  { label: 'Villayón', value: 'Villayón' },
  { label: 'Villaz', value: 'Villaz' },
  { label: 'Villazala', value: 'Villazala' },
  { label: 'Villazanzo De Valderaduey', value: 'Villazanzo De Valderaduey' },
  { label: 'Villazón', value: 'Villazón' },
  { label: 'Villazopeque', value: 'Villazopeque' },
  { label: 'Villaz-Saint-Pierre', value: 'Villaz-Saint-Pierre' },
  { label: 'Villé', value: 'Villé' },
  { label: 'Ville Bague', value: 'Ville Bague' },
  { label: 'Ville Platte', value: 'Ville Platte' },
  { label: 'Ville Sur Sarre', value: 'Ville Sur Sarre' },
  { label: 'Villebarou', value: 'Villebarou' },
  { label: 'Villebernier', value: 'Villebernier' },
  { label: 'Villeblevin', value: 'Villeblevin' },
  { label: 'Villebois', value: 'Villebois' },
  { label: 'Villebon-Sur-Yvette', value: 'Villebon-Sur-Yvette' },
  { label: 'Villebret', value: 'Villebret' },
  { label: 'Villecresnes', value: 'Villecresnes' },
  { label: 'Villecroze', value: 'Villecroze' },
  { label: 'Ville-D’Avray', value: 'Ville-D’Avray' },
  { label: 'Villeda Morales', value: 'Villeda Morales' },
  { label: 'Villedieu-La-Blouère', value: 'Villedieu-La-Blouère' },
  { label: 'Villedieu-Les-Poêles', value: 'Villedieu-Les-Poêles' },
  { label: 'Villedieu-Sur-Indre', value: 'Villedieu-Sur-Indre' },
  { label: 'Villedômer', value: 'Villedômer' },
  { label: 'Villefagnan', value: 'Villefagnan' },
  { label: 'Villefontaine', value: 'Villefontaine' },
  { label: 'Villefranche', value: 'Villefranche' },
  { label: 'Villefranche-D’Allier', value: 'Villefranche-D’Allier' },
  { label: 'Villefranche-Dalbigeois', value: 'Villefranche-Dalbigeois' },
  { label: 'Villefranche-De-Lauragais', value: 'Villefranche-De-Lauragais' },
  { label: 'Villefranche-De-Rouergue', value: 'Villefranche-De-Rouergue' },
  { label: 'Villefranche-Sur-Cher', value: 'Villefranche-Sur-Cher' },
  { label: 'Villefranche-Sur-Mer', value: 'Villefranche-Sur-Mer' },
  { label: 'Villefranche-Sur-Saône', value: 'Villefranche-Sur-Saône' },
  { label: 'Villefranque', value: 'Villefranque' },
  { label: 'Villegailhenc', value: 'Villegailhenc' },
  { label: 'Villegas', value: 'Villegas' },
  { label: 'Villegouge', value: 'Villegouge' },
  { label: 'Villeguillo', value: 'Villeguillo' },
  { label: 'Villejuif', value: 'Villejuif' },
  { label: 'Villejust', value: 'Villejust' },
  { label: 'Villel', value: 'Villel' },
  { label: 'Villel De Mesa', value: 'Villel De Mesa' },
  { label: 'Ville-La-Grand', value: 'Ville-La-Grand' },
  { label: 'Villelaure', value: 'Villelaure' },
  { label: 'Villelongue-De-La-Salanque', value: 'Villelongue-De-La-Salanque' },
  { label: 'Villemandeur', value: 'Villemandeur' },
  { label: 'Ville-Marie', value: 'Ville-Marie' },
  { label: 'Villemeux-Sur-Eure', value: 'Villemeux-Sur-Eure' },
  { label: 'Villemoirieu', value: 'Villemoirieu' },
  { label: 'Villemoisson-Sur-Orge', value: 'Villemoisson-Sur-Orge' },
  { label: 'Villemolaque', value: 'Villemolaque' },
  { label: 'Villemomble', value: 'Villemomble' },
  { label: 'Villemoustaussou', value: 'Villemoustaussou' },
  { label: 'Villemur-Sur-Tarn', value: 'Villemur-Sur-Tarn' },
  { label: 'Villena', value: 'Villena' },
  { label: 'Villenauxe-La-Grande', value: 'Villenauxe-La-Grande' },
  { label: 'Villenave-D’Ornon', value: 'Villenave-D’Ornon' },
  { label: 'Villenbach', value: 'Villenbach' },
  { label: 'Villeneuve', value: 'Villeneuve' },
  { label: 'Villeneuve', value: 'Villeneuve' },
  { label: 'Villeneuve', value: 'Villeneuve' },
  { label: 'Villeneuve', value: 'Villeneuve' },
  { label: 'Villeneuve', value: 'Villeneuve' },
  { label: 'Villeneuve-D’Olmes', value: 'Villeneuve-D’Olmes' },
  { label: 'Villeneuve-Dascq', value: 'Villeneuve-Dascq' },
  { label: 'Villeneuve-De-Berg', value: 'Villeneuve-De-Berg' },
  { label: 'Villeneuve-De-La-Raho', value: 'Villeneuve-De-La-Raho' },
  { label: 'Villeneuve-De-Marsan', value: 'Villeneuve-De-Marsan' },
  { label: 'Villeneuve-De-Rivière', value: 'Villeneuve-De-Rivière' },
  { label: 'Villeneuve-La-Comptal', value: 'Villeneuve-La-Comptal' },
  { label: 'Villeneuve-La-Garenne', value: 'Villeneuve-La-Garenne' },
  { label: 'Villeneuve-La-Guyard', value: 'Villeneuve-La-Guyard' },
  { label: 'Villeneuve-La-Rivière', value: 'Villeneuve-La-Rivière' },
  { label: 'Villeneuve-Le-Comte', value: 'Villeneuve-Le-Comte' },
  { label: 'Villeneuve-Le-Roi', value: 'Villeneuve-Le-Roi' },
  { label: 'Villeneuve-Lès-Avignon', value: 'Villeneuve-Lès-Avignon' },
  { label: 'Villeneuve-Lès-Béziers', value: 'Villeneuve-Lès-Béziers' },
  { label: 'Villeneuve-Lès-Bouloc', value: 'Villeneuve-Lès-Bouloc' },
  { label: 'Villeneuve-Lès-Maguelone', value: 'Villeneuve-Lès-Maguelone' },
  { label: 'Villeneuve-Les-Sablons', value: 'Villeneuve-Les-Sablons' },
  { label: 'Villeneuve-Loubet', value: 'Villeneuve-Loubet' },
  { label: 'Villeneuve-Saint-Georges', value: 'Villeneuve-Saint-Georges' },
  { label: 'Villeneuve-Saint-Germain', value: 'Villeneuve-Saint-Germain' },
  { label: 'Villeneuve-Sur-Bellot', value: 'Villeneuve-Sur-Bellot' },
  { label: 'Villeneuve-Sur-Lot', value: 'Villeneuve-Sur-Lot' },
  { label: 'Villeneuve-Sur-Yonne', value: 'Villeneuve-Sur-Yonne' },
  { label: 'Villeneuve-Tolosane', value: 'Villeneuve-Tolosane' },
  { label: 'Villennes-Sur-Seine', value: 'Villennes-Sur-Seine' },
  { label: 'Villenouvelle', value: 'Villenouvelle' },
  { label: 'Villenoy', value: 'Villenoy' },
  { label: 'Villeparisis', value: 'Villeparisis' },
  { label: 'Villepinte', value: 'Villepinte' },
  { label: 'Villepinte', value: 'Villepinte' },
  { label: 'Villepreux', value: 'Villepreux' },
  { label: 'Villeréal', value: 'Villeréal' },
  { label: 'Villerest', value: 'Villerest' },
  { label: 'Villereversure', value: 'Villereversure' },
  { label: 'Villerías De Campos', value: 'Villerías De Campos' },
  { label: 'Villers-Bocage', value: 'Villers-Bocage' },
  { label: 'Villers-Bocage', value: 'Villers-Bocage' },
  { label: 'Villers-Bretonneux', value: 'Villers-Bretonneux' },
  { label: 'Villers-Cotterêts', value: 'Villers-Cotterêts' },
  { label: 'Villers-Écalles', value: 'Villers-Écalles' },
  { label: 'Villers-En-Cauchies', value: 'Villers-En-Cauchies' },
  { label: 'Villersexel', value: 'Villersexel' },
  { label: 'Villers-La-Montagne', value: 'Villers-La-Montagne' },
  { label: 'Villers-La-Ville', value: 'Villers-La-Ville' },
  { label: 'Villers-Le-Bouillet', value: 'Villers-Le-Bouillet' },
  { label: 'Villers-Le-Lac', value: 'Villers-Le-Lac' },
  { label: 'Villers-Lès-Nancy', value: 'Villers-Lès-Nancy' },
  { label: 'Villers-Outréaux', value: 'Villers-Outréaux' },
  { label: 'Villers-Pol', value: 'Villers-Pol' },
  { label: 'Villers-Saint-Paul', value: 'Villers-Saint-Paul' },
  { label: 'Villers-Semeuse', value: 'Villers-Semeuse' },
  { label: 'Villers-Sous-Saint-Leu', value: 'Villers-Sous-Saint-Leu' },
  { label: 'Villers-Sur-Coudun', value: 'Villers-Sur-Coudun' },
  { label: 'Villers-Sur-Mer', value: 'Villers-Sur-Mer' },
  { label: 'Villerupt', value: 'Villerupt' },
  { label: 'Ville-Sous-Anjou', value: 'Ville-Sous-Anjou' },
  { label: 'Ville-Sous-La-Ferté', value: 'Ville-Sous-La-Ferté' },
  { label: 'Villesse', value: 'Villesse' },
  { label: 'Villes-Sur-Auzon', value: 'Villes-Sur-Auzon' },
  { label: 'Villeta', value: 'Villeta' },
  { label: 'Villetaneuse', value: 'Villetaneuse' },
  { label: 'Villetelle', value: 'Villetelle' },
  { label: 'Villetta Barrea', value: 'Villetta Barrea' },
  { label: 'Villette', value: 'Villette' },
  { label: 'Villette-De-Vienne', value: 'Villette-De-Vienne' },
  { label: 'Villeurbanne', value: 'Villeurbanne' },
  { label: 'Villevaudé', value: 'Villevaudé' },
  { label: 'Villevêque', value: 'Villevêque' },
  { label: 'Villeveyrac', value: 'Villeveyrac' },
  { label: 'Villevieille', value: 'Villevieille' },
  { label: 'Villevocance', value: 'Villevocance' },
  { label: 'Villey-Saint-Étienne', value: 'Villey-Saint-Étienne' },
  { label: 'Villié-Morgon', value: 'Villié-Morgon' },
  { label: 'Villiers', value: 'Villiers' },
  { label: 'Villiers-En-Lieu', value: 'Villiers-En-Lieu' },
  { label: 'Villiers-En-Plaine', value: 'Villiers-En-Plaine' },
  { label: 'Villiers-Le-Bâcle', value: 'Villiers-Le-Bâcle' },
  { label: 'Villiers-Le-Bel', value: 'Villiers-Le-Bel' },
  { label: 'Villiers-Le-Morhier', value: 'Villiers-Le-Morhier' },
  { label: 'Villiers-Saint-Fréderic', value: 'Villiers-Saint-Fréderic' },
  { label: 'Villiers-Saint-Georges', value: 'Villiers-Saint-Georges' },
  { label: 'Villiers-Sur-Loir', value: 'Villiers-Sur-Loir' },
  { label: 'Villiers-Sur-Marne', value: 'Villiers-Sur-Marne' },
  { label: 'Villiers-Sur-Morin', value: 'Villiers-Sur-Morin' },
  { label: 'Villiers-Sur-Orge', value: 'Villiers-Sur-Orge' },
  { label: 'Villigen', value: 'Villigen' },
  { label: 'Villimpenta', value: 'Villimpenta' },
  { label: 'Villingendorf', value: 'Villingendorf' },
  { label: 'Villingen-Schwenningen', value: 'Villingen-Schwenningen' },
  { label: 'Villisca', value: 'Villisca' },
  { label: 'Villmar', value: 'Villmar' },
  { label: 'Villmergen', value: 'Villmergen' },
  { label: 'Villnachern', value: 'Villnachern' },
  { label: 'Villodre', value: 'Villodre' },
  { label: 'Villodrigo', value: 'Villodrigo' },
  { label: 'Villoldo', value: 'Villoldo' },
  { label: 'Villongo', value: 'Villongo' },
  { label: 'Víllora', value: 'Víllora' },
  { label: 'Villores', value: 'Villores' },
  { label: 'Villoría', value: 'Villoría' },
  { label: 'Villoruebo', value: 'Villoruebo' },
  { label: 'Villoruela', value: 'Villoruela' },
  { label: 'Villoslada De Cameros', value: 'Villoslada De Cameros' },
  { label: 'Villota Del Páramo', value: 'Villota Del Páramo' },
  { label: 'Villotta', value: 'Villotta' },
  { label: 'Villovieco', value: 'Villovieco' },
  { label: 'Villupuram', value: 'Villupuram' },
  { label: 'Vilminore', value: 'Vilminore' },
  { label: 'Vilminore Di Scalve', value: 'Vilminore Di Scalve' },
  { label: 'Vilniansk Raion', value: 'Vilniansk Raion' },
  { label: 'Vilnius', value: 'Vilnius' },
  { label: 'Vilnius District Municipality', value: 'Vilnius District Municipality' },
  { label: 'Vilobí Del Penedès', value: 'Vilobí Del Penedès' },
  { label: 'Vilobí Donyar', value: 'Vilobí Donyar' },
  { label: 'Viloco', value: 'Viloco' },
  { label: 'Vilonia', value: 'Vilonia' },
  { label: 'Vilopriu', value: 'Vilopriu' },
  { label: 'Viloria', value: 'Viloria' },
  { label: 'Viloria De Rioja', value: 'Viloria De Rioja' },
  { label: 'Vilosell El', value: 'Vilosell El' },
  { label: 'Vilovatovo', value: 'Vilovatovo' },
  { label: 'Vilovo', value: 'Vilovo' },
  { label: 'Vilppula', value: 'Vilppula' },
  { label: 'Vils', value: 'Vils' },
  { label: 'Vilsbiburg', value: 'Vilsbiburg' },
  { label: 'Vilseck', value: 'Vilseck' },
  { label: 'Vilsheim', value: 'Vilsheim' },
  { label: 'Vilshofen', value: 'Vilshofen' },
  { label: 'Vilueña La', value: 'Vilueña La' },
  { label: 'Vilvestre', value: 'Vilvestre' },
  { label: 'Vilviestre Del Pinar', value: 'Vilviestre Del Pinar' },
  { label: 'Vilvoorde', value: 'Vilvoorde' },
  { label: 'Vilya', value: 'Vilya' },
  { label: 'Vilyeyka', value: 'Vilyeyka' },
  { label: 'Vilyeyski Rayon', value: 'Vilyeyski Rayon' },
  { label: 'Vilyuchinsk', value: 'Vilyuchinsk' },
  { label: 'Vilyuysk', value: 'Vilyuysk' },
  { label: 'Vilyuyskiy Rayon', value: 'Vilyuyskiy Rayon' },
  { label: 'Vilyuysky District', value: 'Vilyuysky District' },
  { label: 'Vima Mare', value: 'Vima Mare' },
  { label: 'Vima Mică', value: 'Vima Mică' },
  { label: 'Vimbodí I Poblet', value: 'Vimbodí I Poblet' },
  { label: 'Vimeiro', value: 'Vimeiro' },
  { label: 'Vimeiro', value: 'Vimeiro' },
  { label: 'Vimercate', value: 'Vimercate' },
  { label: 'Vimianzo', value: 'Vimianzo' },
  { label: 'Vimines', value: 'Vimines' },
  { label: 'Vimioso', value: 'Vimioso' },
  { label: 'Vimmerby', value: 'Vimmerby' },
  { label: 'Vimodrone', value: 'Vimodrone' },
  { label: 'Vimory', value: 'Vimory' },
  { label: 'Vimoutiers', value: 'Vimoutiers' },
  { label: 'Vimpeli', value: 'Vimpeli' },
  { label: 'Vimperk', value: 'Vimperk' },
  { label: 'Vimy', value: 'Vimy' },
  { label: 'Vina', value: 'Vina' },
  { label: 'Viña Del Mar', value: 'Viña Del Mar' },
  { label: 'Vinaceite', value: 'Vinaceite' },
  { label: 'Vinadio', value: 'Vinadio' },
  { label: 'Vinaixa', value: 'Vinaixa' },
  { label: 'Viñales', value: 'Viñales' },
  { label: 'Vinalesa', value: 'Vinalesa' },
  { label: 'Vinalhaven', value: 'Vinalhaven' },
  { label: 'Vinařice', value: 'Vinařice' },
  { label: 'Vinaròs', value: 'Vinaròs' },
  { label: 'Viñas', value: 'Viñas' },
  { label: 'Viñas Del Sol', value: 'Viñas Del Sol' },
  { label: 'Vinassan', value: 'Vinassan' },
  { label: 'Vinay', value: 'Vinay' },
  { label: 'Vinça', value: 'Vinça' },
  { label: 'Vincennes', value: 'Vincennes' },
  { label: 'Vincennes', value: 'Vincennes' },
  { label: 'Vincent', value: 'Vincent' },
  { label: 'Vincent', value: 'Vincent' },
  { label: 'Vincent', value: 'Vincent' },
  { label: 'Vincent', value: 'Vincent' },
  { label: 'Vincentia', value: 'Vincentia' },
  { label: 'Vincentown', value: 'Vincentown' },
  { label: 'Vinces', value: 'Vinces' },
  { label: 'Vincey', value: 'Vincey' },
  { label: 'Vinchhiya', value: 'Vinchhiya' },
  { label: 'Vinchiaturo', value: 'Vinchiaturo' },
  { label: 'Vinchina', value: 'Vinchina' },
  { label: 'Vinchio', value: 'Vinchio' },
  { label: 'Vinchur', value: 'Vinchur' },
  { label: 'Vinci', value: 'Vinci' },
  { label: 'Vinco', value: 'Vinco' },
  { label: 'Vindafjord', value: 'Vindafjord' },
  { label: 'Vindeby', value: 'Vindeby' },
  { label: 'Vindel', value: 'Vindel' },
  { label: 'Vindeln', value: 'Vindeln' },
  { label: 'Vinderei', value: 'Vinderei' },
  { label: 'Vinderup', value: 'Vinderup' },
  { label: 'Vindhyachal', value: 'Vindhyachal' },
  { label: 'Vindinge', value: 'Vindinge' },
  { label: 'Vine Grove', value: 'Vine Grove' },
  { label: 'Vine Hill', value: 'Vine Hill' },
  { label: 'Vinebre', value: 'Vinebre' },
  { label: 'Viñegra De Moraña', value: 'Viñegra De Moraña' },
  { label: 'Vineland', value: 'Vineland' },
  { label: 'Vineland', value: 'Vineland' },
  { label: 'Vineland', value: 'Vineland' },
  { label: 'Vinerea', value: 'Vinerea' },
  { label: 'Vineuil', value: 'Vineuil' },
  { label: 'Vineuil-Saint-Firmin', value: 'Vineuil-Saint-Firmin' },
  { label: 'Vineyard', value: 'Vineyard' },
  { label: 'Vineyard', value: 'Vineyard' },
  { label: 'Vineyard', value: 'Vineyard' },
  { label: 'Vineyard Haven', value: 'Vineyard Haven' },
  { label: 'Vineyard Town', value: 'Vineyard Town' },
  { label: 'Vineyards', value: 'Vineyards' },
  { label: 'Vinež', value: 'Vinež' },
  { label: 'Vinga', value: 'Vinga' },
  { label: 'Vingåker', value: 'Vingåker' },
  { label: 'Vingt Cinq', value: 'Vingt Cinq' },
  { label: 'Vinh', value: 'Vinh' },
  { label: 'Vĩnh Long', value: 'Vĩnh Long' },
  { label: 'Vĩnh Thạnh', value: 'Vĩnh Thạnh' },
  { label: 'Vĩnh Yên', value: 'Vĩnh Yên' },
  { label: 'Vinhais', value: 'Vinhais' },
  { label: 'Vinhedo', value: 'Vinhedo' },
  { label: 'Vinhó', value: 'Vinhó' },
  { label: 'Vinica', value: 'Vinica' },
  { label: 'Vinica', value: 'Vinica' },
  { label: 'Viničné Šumice', value: 'Viničné Šumice' },
  { label: 'Viniegra De Abajo', value: 'Viniegra De Abajo' },
  { label: 'Viniegra De Arriba', value: 'Viniegra De Arriba' },
  { label: 'Vinings', value: 'Vinings' },
  { label: 'Vinita', value: 'Vinita' },
  { label: 'Vinita Park', value: 'Vinita Park' },
  { label: 'Vinje', value: 'Vinje' },
  { label: 'Vinkebrug', value: 'Vinkebrug' },
  { label: 'Vinkeveen', value: 'Vinkeveen' },
  { label: 'Vinkovci', value: 'Vinkovci' },
  { label: 'Vinné', value: 'Vinné' },
  { label: 'Vinneuf', value: 'Vinneuf' },
  { label: 'Vinni', value: 'Vinni' },
  { label: 'Vinni Vald', value: 'Vinni Vald' },
  { label: 'Vinninga', value: 'Vinninga' },
  { label: 'Vinningen', value: 'Vinningen' },
  { label: 'Vinnitskiy Rayon', value: 'Vinnitskiy Rayon' },
  { label: 'Vinnitskoye', value: 'Vinnitskoye' },
  { label: 'Vinnitsy', value: 'Vinnitsy' },
  { label: 'Vinnytsia', value: 'Vinnytsia' },
  { label: 'Vinodolska Općina', value: 'Vinodolska Općina' },
  { label: 'Vinogradnoye', value: 'Vinogradnoye' },
  { label: 'Vinogradnyy', value: 'Vinogradnyy' },
  { label: 'Vinogradovo', value: 'Vinogradovo' },
  { label: 'Vinogradovo', value: 'Vinogradovo' },
  { label: 'Vinon-Sur-Verdon', value: 'Vinon-Sur-Verdon' },
  { label: 'Vinosady', value: 'Vinosady' },
  { label: 'Vinovo', value: 'Vinovo' },
  { label: 'Vinsady', value: 'Vinsady' },
  { label: 'Vinslöv', value: 'Vinslöv' },
  { label: 'Vinsobres', value: 'Vinsobres' },
  { label: 'Vinstra', value: 'Vinstra' },
  { label: 'Vintar', value: 'Vintar' },
  { label: 'Vintar', value: 'Vintar' },
  { label: 'Vintere', value: 'Vintere' },
  { label: 'Vintilă Vodă', value: 'Vintilă Vodă' },
  { label: 'Vintileanca', value: 'Vintileanca' },
  { label: 'Vintileasca', value: 'Vintileasca' },
  { label: 'Vintířov', value: 'Vintířov' },
  { label: 'Vinton', value: 'Vinton' },
  { label: 'Vinton', value: 'Vinton' },
  { label: 'Vinton', value: 'Vinton' },
  { label: 'Vinton', value: 'Vinton' },
  { label: 'Vinton County', value: 'Vinton County' },
  { label: 'Vintrosa', value: 'Vintrosa' },
  { label: 'Vințu De Jos', value: 'Vințu De Jos' },
  { label: 'Viñuela', value: 'Viñuela' },
  { label: 'Viñuelas', value: 'Viñuelas' },
  { label: 'Vinuesa', value: 'Vinuesa' },
  { label: 'Vinukonda', value: 'Vinukonda' },
  { label: 'Vinyols I Els Arcs', value: 'Vinyols I Els Arcs' },
  { label: 'Vinzaglio', value: 'Vinzaglio' },
  { label: 'Vinzili', value: 'Vinzili' },
  { label: 'Vinzons', value: 'Vinzons' },
  { label: 'Viöl', value: 'Viöl' },
  { label: 'Viola', value: 'Viola' },
  { label: 'Viola', value: 'Viola' },
  { label: 'Violaines', value: 'Violaines' },
  { label: 'Violay', value: 'Violay' },
  { label: 'Violès', value: 'Violès' },
  { label: 'Violet', value: 'Violet' },
  { label: 'Violí Charáki', value: 'Violí Charáki' },
  { label: 'Vion', value: 'Vion' },
  { label: 'Vione', value: 'Vione' },
  { label: 'Vionnaz', value: 'Vionnaz' },
  { label: 'Viotá', value: 'Viotá' },
  { label: 'Vipava', value: 'Vipava' },
  { label: 'Vipereşti', value: 'Vipereşti' },
  { label: 'Vipiteno', value: 'Vipiteno' },
  { label: 'Vipperød', value: 'Vipperød' },
  { label: 'Viqueque', value: 'Viqueque' },
  { label: 'Vir', value: 'Vir' },
  { label: 'Vir', value: 'Vir' },
  { label: 'Virac', value: 'Virac' },
  { label: 'Viracachá', value: 'Viracachá' },
  { label: 'Viradouro', value: 'Viradouro' },
  { label: 'Viraganur', value: 'Viraganur' },
  { label: 'Viramgam', value: 'Viramgam' },
  { label: 'Viranşehir', value: 'Viranşehir' },
  { label: 'Virar', value: 'Virar' },
  { label: 'Virarajendrapet', value: 'Virarajendrapet' },
  { label: 'Virazeil', value: 'Virazeil' },
  { label: 'Virbalis', value: 'Virbalis' },
  { label: 'Virden', value: 'Virden' },
  { label: 'Virden', value: 'Virden' },
  { label: 'Vire', value: 'Vire' },
  { label: 'Vireux-Molhain', value: 'Vireux-Molhain' },
  { label: 'Vireux-Wallerand', value: 'Vireux-Wallerand' },
  { label: 'Virey-Le-Grand', value: 'Virey-Le-Grand' },
  { label: 'Virgem Da Lapa', value: 'Virgem Da Lapa' },
  { label: 'Virgen', value: 'Virgen' },
  { label: 'Virgil', value: 'Virgil' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Virgínia', value: 'Virgínia' },
  { label: 'Virginia Beach', value: 'Virginia Beach' },
  { label: 'Virginia City', value: 'Virginia City' },
  { label: 'Virginia City', value: 'Virginia City' },
  { label: 'Virginia Gardens', value: 'Virginia Gardens' },
  { label: 'Virginia Water', value: 'Virginia Water' },
  { label: 'Virginias', value: 'Virginias' },
  { label: 'Virginópolis', value: 'Virginópolis' },
  { label: 'Virgolândia', value: 'Virgolândia' },
  { label: 'Viriat', value: 'Viriat' },
  { label: 'Viriato', value: 'Viriato' },
  { label: 'Viriato', value: 'Viriato' },
  { label: 'Virieu', value: 'Virieu' },
  { label: 'Virieu-Le-Grand', value: 'Virieu-Le-Grand' },
  { label: 'Viriville', value: 'Viriville' },
  { label: 'Virje', value: 'Virje' },
  { label: 'Virklund', value: 'Virklund' },
  { label: 'Virle Piemonte', value: 'Virle Piemonte' },
  { label: 'Virlya', value: 'Virlya' },
  { label: 'Virmond', value: 'Virmond' },
  { label: 'Viroflay', value: 'Viroflay' },
  { label: 'Virojoki', value: 'Virojoki' },
  { label: 'Viroqua', value: 'Viroqua' },
  { label: 'Virós', value: 'Virós' },
  { label: 'Virovitica', value: 'Virovitica' },
  { label: 'Virpur', value: 'Virpur' },
  { label: 'Virrat', value: 'Virrat' },
  { label: 'Virserum', value: 'Virserum' },
  { label: 'Virtasalmi', value: 'Virtasalmi' },
  { label: 'Virton', value: 'Virton' },
  { label: 'Viru', value: 'Viru' },
  { label: 'Virudhunagar', value: 'Virudhunagar' },
  { label: 'Viru-Nigula Vald', value: 'Viru-Nigula Vald' },
  { label: 'Viry', value: 'Viry' },
  { label: 'Viry-Châtillon', value: 'Viry-Châtillon' },
  { label: 'Viry-Noureuil', value: 'Viry-Noureuil' },
  { label: 'Vis', value: 'Vis' },
  { label: 'Visaginas', value: 'Visaginas' },
  { label: 'Visakhapatnam', value: 'Visakhapatnam' },
  { label: 'Visalia', value: 'Visalia' },
  { label: 'Visan', value: 'Visan' },
  { label: 'Vişani', value: 'Vişani' },
  { label: 'Visano', value: 'Visano' },
  { label: 'Visavadar', value: 'Visavadar' },
  { label: 'Visbek', value: 'Visbek' },
  { label: 'Visby', value: 'Visby' },
  { label: 'Vische', value: 'Vische' },
  { label: 'Visciano', value: 'Visciano' },
  { label: 'Visco', value: 'Visco' },
  { label: 'Visconde Do Rio Branco', value: 'Visconde Do Rio Branco' },
  { label: 'Viscri', value: 'Viscri' },
  { label: 'Visé', value: 'Visé' },
  { label: 'Visegrád', value: 'Visegrád' },
  { label: 'Višegrad', value: 'Višegrad' },
  { label: 'Viseu', value: 'Viseu' },
  { label: 'Viseu', value: 'Viseu' },
  { label: 'Vişeu De Jos', value: 'Vişeu De Jos' },
  { label: 'Vișeu De Mijloc', value: 'Vișeu De Mijloc' },
  { label: 'Vişeu De Sus', value: 'Vişeu De Sus' },
  { label: 'Vishennoye', value: 'Vishennoye' },
  { label: 'Vishnëvogorsk', value: 'Vishnëvogorsk' },
  { label: 'Vishnyakovskiye Dachi', value: 'Vishnyakovskiye Dachi' },
  { label: 'Vishnyeva', value: 'Vishnyeva' },
  { label: 'Vishnyovka', value: 'Vishnyovka' },
  { label: 'Vishow', value: 'Vishow' },
  { label: 'Visiedo', value: 'Visiedo' },
  { label: 'Visim', value: 'Visim' },
  { label: 'Vişina', value: 'Vişina' },
  { label: 'Vişina', value: 'Vişina' },
  { label: 'Vișina Nouă', value: 'Vișina Nouă' },
  { label: 'Vișinelu', value: 'Vișinelu' },
  { label: 'Vişineşti', value: 'Vişineşti' },
  { label: 'Visitacion Valley', value: 'Visitacion Valley' },
  { label: 'Viskafors', value: 'Viskafors' },
  { label: 'Viškovci', value: 'Viškovci' },
  { label: 'Viškovo', value: 'Viškovo' },
  { label: 'Vislanda', value: 'Vislanda' },
  { label: 'Visnagar', value: 'Visnagar' },
  { label: 'Vişniovca', value: 'Vişniovca' },
  { label: 'Višnjan-Visignano', value: 'Višnjan-Visignano' },
  { label: 'Višnjevac', value: 'Višnjevac' },
  { label: 'Višnjićevo', value: 'Višnjićevo' },
  { label: 'Višňova', value: 'Višňova' },
  { label: 'Višňové', value: 'Višňové' },
  { label: 'Viso De San Juan El', value: 'Viso De San Juan El' },
  { label: 'Viso Del Alcor El', value: 'Viso Del Alcor El' },
  { label: 'Viso Del Marqués', value: 'Viso Del Marqués' },
  { label: 'Viso El', value: 'Viso El' },
  { label: 'Visoko', value: 'Visoko' },
  { label: 'Visone', value: 'Visone' },
  { label: 'Visp', value: 'Visp' },
  { label: 'Visp District', value: 'Visp District' },
  { label: 'Visperterminen', value: 'Visperterminen' },
  { label: 'Visselhövede', value: 'Visselhövede' },
  { label: 'Vissenbjerg', value: 'Vissenbjerg' },
  { label: 'Visso', value: 'Visso' },
  { label: 'Vista', value: 'Vista' },
  { label: 'Vista Alegre', value: 'Vista Alegre' },
  { label: 'Vista Alegre', value: 'Vista Alegre' },
  { label: 'Vista Alegre', value: 'Vista Alegre' },
  { label: 'Vista Alegre', value: 'Vista Alegre' },
  { label: 'Vista Alegre Do Alto', value: 'Vista Alegre Do Alto' },
  { label: 'Vista Alegre Do Prata', value: 'Vista Alegre Do Prata' },
  { label: 'Vista Center', value: 'Vista Center' },
  { label: 'Vista Gaúcha', value: 'Vista Gaúcha' },
  { label: 'Vista Hermosa', value: 'Vista Hermosa' },
  { label: 'Vista Hermosa', value: 'Vista Hermosa' },
  { label: 'Vista Hermosa', value: 'Vista Hermosa' },
  { label: 'Vista Hermosa', value: 'Vista Hermosa' },
  { label: 'Vista Hermosa', value: 'Vista Hermosa' },
  { label: 'Vista Hermosa', value: 'Vista Hermosa' },
  { label: 'Vista Hermosa (Cuasinada)', value: 'Vista Hermosa (Cuasinada)' },
  { label: 'Vista Hermosa De Negrete', value: 'Vista Hermosa De Negrete' },
  { label: 'Vista Santa Rosa', value: 'Vista Santa Rosa' },
  { label: 'Vista Serrana', value: 'Vista Serrana' },
  { label: 'Vistabella', value: 'Vistabella' },
  { label: 'Vistabella Del Maestrazgo', value: 'Vistabella Del Maestrazgo' },
  { label: 'Vistahermosa', value: 'Vistahermosa' },
  { label: 'Vistarino', value: 'Vistarino' },
  { label: 'Vistas Del Maguey [Fraccionamiento]', value: 'Vistas Del Maguey [Fraccionamiento]' },
  { label: 'Viștea De Jos', value: 'Viștea De Jos' },
  { label: 'Visthá', value: 'Visthá' },
  { label: 'Vistino', value: 'Vistino' },
  { label: 'Vistrorio', value: 'Vistrorio' },
  { label: 'Vita', value: 'Vita' },
  { label: 'Vita', value: 'Vita' },
  { label: 'Vita Maharashtra', value: 'Vita Maharashtra' },
  { label: 'Vitacura', value: 'Vitacura' },
  { label: 'Vitali', value: 'Vitali' },
  { label: 'Vităneşti', value: 'Vităneşti' },
  { label: 'Vitănești', value: 'Vitănești' },
  { label: 'Vitanje', value: 'Vitanje' },
  { label: 'Vite', value: 'Vite' },
  { label: 'Vitebsk', value: 'Vitebsk' },
  { label: 'Viterbo', value: 'Viterbo' },
  { label: 'Viterbo', value: 'Viterbo' },
  { label: 'Vitez', value: 'Vitez' },
  { label: 'Viticuso', value: 'Viticuso' },
  { label: 'Vitigliano', value: 'Vitigliano' },
  { label: 'Vitigudino', value: 'Vitigudino' },
  { label: 'Vitim', value: 'Vitim' },
  { label: 'Vitina', value: 'Vitina' },
  { label: 'Vitinia', value: 'Vitinia' },
  { label: 'Vitis', value: 'Vitis' },
  { label: 'Vítkov', value: 'Vítkov' },
  { label: 'Vito', value: 'Vito' },
  { label: 'Vito', value: 'Vito' },
  { label: 'Vitomarci', value: 'Vitomarci' },
  { label: 'Vitomireşti', value: 'Vitomireşti' },
  { label: 'Vitor Meireles', value: 'Vitor Meireles' },
  { label: 'Vitorchiano', value: 'Vitorchiano' },
  { label: 'Vitória', value: 'Vitória' },
  { label: 'Vitória Brasil', value: 'Vitória Brasil' },
  { label: 'Vitória Da Conquista', value: 'Vitória Da Conquista' },
  { label: 'Vitória Das Missões', value: 'Vitória Das Missões' },
  { label: 'Vitória De Santo Antão', value: 'Vitória De Santo Antão' },
  { label: 'Vitória Do Jari', value: 'Vitória Do Jari' },
  { label: 'Vitória Do Mearim', value: 'Vitória Do Mearim' },
  { label: 'Vitória Do Xingu', value: 'Vitória Do Xingu' },
  { label: 'Vitoria-Gasteiz', value: 'Vitoria-Gasteiz' },
  { label: 'Vitorino', value: 'Vitorino' },
  { label: 'Vitorino Freire', value: 'Vitorino Freire' },
  { label: 'Vitré', value: 'Vitré' },
  { label: 'Vitrolles', value: 'Vitrolles' },
  { label: 'Vitry-Aux-Loges', value: 'Vitry-Aux-Loges' },
  { label: 'Vitry-En-Artois', value: 'Vitry-En-Artois' },
  { label: 'Vitry-Le-François', value: 'Vitry-Le-François' },
  { label: 'Vitry-Sur-Orne', value: 'Vitry-Sur-Orne' },
  { label: 'Vitry-Sur-Seine', value: 'Vitry-Sur-Seine' },
  { label: 'Vitteaux', value: 'Vitteaux' },
  { label: 'Vittel', value: 'Vittel' },
  { label: 'Vittoria', value: 'Vittoria' },
  { label: 'Vittorio Veneto', value: 'Vittorio Veneto' },
  { label: 'Vittorito', value: 'Vittorito' },
  { label: 'Vittsjö', value: 'Vittsjö' },
  { label: 'Vittuone', value: 'Vittuone' },
  { label: 'Vitulano', value: 'Vitulano' },
  { label: 'Vitulazio', value: 'Vitulazio' },
  { label: 'Vityazevo', value: 'Vityazevo' },
  { label: 'Vitznau', value: 'Vitznau' },
  { label: 'Viù', value: 'Viù' },
  { label: 'Viuz-En-Sallaz', value: 'Viuz-En-Sallaz' },
  { label: 'Viuz-La-Chiésaz', value: 'Viuz-La-Chiésaz' },
  { label: 'Viva Cárdenas', value: 'Viva Cárdenas' },
  { label: 'Viva México', value: 'Viva México' },
  { label: 'Vivaro', value: 'Vivaro' },
  { label: 'Vivaro Romano', value: 'Vivaro Romano' },
  { label: 'Viveash', value: 'Viveash' },
  { label: 'Viveiro', value: 'Viveiro' },
  { label: 'Vivel Del Río Martín', value: 'Vivel Del Río Martín' },
  { label: 'Viver', value: 'Viver' },
  { label: 'Viver I Serrateix', value: 'Viver I Serrateix' },
  { label: 'Viverone', value: 'Viverone' },
  { label: 'Viveros', value: 'Viveros' },
  { label: 'Vivian', value: 'Vivian' },
  { label: 'Vivier-Au-Court', value: 'Vivier-Au-Court' },
  { label: 'Viviers', value: 'Viviers' },
  { label: 'Viviers-Du-Lac', value: 'Viviers-Du-Lac' },
  { label: 'Viviers-Lès-Montagnes', value: 'Viviers-Lès-Montagnes' },
  { label: 'Viviez', value: 'Viviez' },
  { label: 'Vivonne', value: 'Vivonne' },
  { label: 'Vivy', value: 'Vivy' },
  { label: 'Vix', value: 'Vix' },
  { label: 'Viytivka', value: 'Viytivka' },
  { label: 'Vizal San Pablo', value: 'Vizal San Pablo' },
  { label: 'Vizal San Pablo', value: 'Vizal San Pablo' },
  { label: 'Vizal Santo Niño', value: 'Vizal Santo Niño' },
  { label: 'Vizal Santo Niño', value: 'Vizal Santo Niño' },
  { label: 'Vizantea-Mânăstirească', value: 'Vizantea-Mânăstirească' },
  { label: 'Vizarrón', value: 'Vizarrón' },
  { label: 'Vizcachane', value: 'Vizcachane' },
  { label: 'Vizcaínos', value: 'Vizcaínos' },
  { label: 'Vize', value: 'Vize' },
  { label: 'Vizela', value: 'Vizela' },
  { label: 'Vizela', value: 'Vizela' },
  { label: 'Vizianagaram', value: 'Vizianagaram' },
  { label: 'Vizianagaram District', value: 'Vizianagaram District' },
  { label: 'Vizille', value: 'Vizille' },
  { label: 'Vižinada-Visinada', value: 'Vižinada-Visinada' },
  { label: 'Vizinga', value: 'Vizinga' },
  { label: 'Viziru', value: 'Viziru' },
  { label: 'Vizmanos', value: 'Vizmanos' },
  { label: 'Víznar', value: 'Víznar' },
  { label: 'Vizovice', value: 'Vizovice' },
  { label: 'Vizurești', value: 'Vizurești' },
  { label: 'Vizzini', value: 'Vizzini' },
  { label: 'Vizzola Ticino', value: 'Vizzola Ticino' },
  { label: 'Vizzolo Predabissi', value: 'Vizzolo Predabissi' },
  { label: 'Vkhodnoy', value: 'Vkhodnoy' },
  { label: 'Vlaardingen', value: 'Vlaardingen' },
  { label: 'Vlachiótis', value: 'Vlachiótis' },
  { label: 'Vlachópoulo', value: 'Vlachópoulo' },
  { label: 'Vlachovice', value: 'Vlachovice' },
  { label: 'Vlachovo Březí', value: 'Vlachovo Březí' },
  { label: 'Vlad Ţepeş', value: 'Vlad Ţepeş' },
  { label: 'Vlad Țepeș', value: 'Vlad Țepeș' },
  { label: 'Vlădaia', value: 'Vlădaia' },
  { label: 'Vlădeni', value: 'Vlădeni' },
  { label: 'Vlădeni', value: 'Vlădeni' },
  { label: 'Vlădeni', value: 'Vlădeni' },
  { label: 'Vlădeni', value: 'Vlădeni' },
  { label: 'Vlădeni-Deal', value: 'Vlădeni-Deal' },
  { label: 'Vlădeşti', value: 'Vlădeşti' },
  { label: 'Vlădeşti', value: 'Vlădeşti' },
  { label: 'Vlădești', value: 'Vlădești' },
  { label: 'Vladikavkaz', value: 'Vladikavkaz' },
  { label: 'Vlădila', value: 'Vlădila' },
  { label: 'Vladimir', value: 'Vladimir' },
  { label: 'Vladimirci', value: 'Vladimirci' },
  { label: 'Vladimirescu', value: 'Vladimirescu' },
  { label: 'Vladimiro-Aleksandrovskoye', value: 'Vladimiro-Aleksandrovskoye' },
  { label: 'Vladimirovac', value: 'Vladimirovac' },
  { label: 'Vladimirovka', value: 'Vladimirovka' },
  { label: 'Vladimirovo', value: 'Vladimirovo' },
  { label: 'Vladimirskaya', value: 'Vladimirskaya' },
  { label: 'Vladimirskoye', value: 'Vladimirskoye' },
  { label: 'Vladislav', value: 'Vladislav' },
  { label: 'Vladislavci', value: 'Vladislavci' },
  { label: 'Vladislavovka', value: 'Vladislavovka' },
  { label: 'Vladivostok', value: 'Vladivostok' },
  { label: 'Vlăduleni', value: 'Vlăduleni' },
  { label: 'Vlagtwedde', value: 'Vlagtwedde' },
  { label: 'Vlăhiţa', value: 'Vlăhiţa' },
  { label: 'Vlajkovac', value: 'Vlajkovac' },
  { label: 'Vlașca', value: 'Vlașca' },
  { label: 'Vlăsceni', value: 'Vlăsceni' },
  { label: 'Vlasenica', value: 'Vlasenica' },
  { label: 'Vlasikha', value: 'Vlasikha' },
  { label: 'Vlašim', value: 'Vlašim' },
  { label: 'Vlăsineşti', value: 'Vlăsineşti' },
  { label: 'Vlčnov', value: 'Vlčnov' },
  { label: 'Vlčnov', value: 'Vlčnov' },
  { label: 'Vledder', value: 'Vledder' },
  { label: 'Vleuten', value: 'Vleuten' },
  { label: 'Vliedberg', value: 'Vliedberg' },
  { label: 'Vlierden', value: 'Vlierden' },
  { label: 'Vlietwijk', value: 'Vlietwijk' },
  { label: 'Vlissingen', value: 'Vlissingen' },
  { label: 'Vlkoš', value: 'Vlkoš' },
  { label: 'Vlodrop', value: 'Vlodrop' },
  { label: 'Vlokhoven', value: 'Vlokhoven' },
  { label: 'Vlorë', value: 'Vlorë' },
  { label: 'Vlotho', value: 'Vlotho' },
  { label: 'Vlycháda', value: 'Vlycháda' },
  { label: 'Vnanje Gorice', value: 'Vnanje Gorice' },
  { label: 'Vniissok', value: 'Vniissok' },
  { label: 'Vnorovy', value: 'Vnorovy' },
  { label: 'Vnukovo', value: 'Vnukovo' },
  { label: 'Vò', value: 'Vò' },
  { label: 'Vobarno', value: 'Vobarno' },
  { label: 'Vobbia', value: 'Vobbia' },
  { label: 'Vocca', value: 'Vocca' },
  { label: 'Voćin', value: 'Voćin' },
  { label: 'Vockerode', value: 'Vockerode' },
  { label: 'Vöcklabruck', value: 'Vöcklabruck' },
  { label: 'Vöcklamarkt', value: 'Vöcklamarkt' },
  { label: 'Vodiana Balka', value: 'Vodiana Balka' },
  { label: 'Vodice', value: 'Vodice' },
  { label: 'Vodice', value: 'Vodice' },
  { label: 'Vođinci', value: 'Vođinci' },
  { label: 'Vodňany', value: 'Vodňany' },
  { label: 'Vodnjan', value: 'Vodnjan' },
  { label: 'Vodnjan-Dignano', value: 'Vodnjan-Dignano' },
  { label: 'Vodnyy', value: 'Vodnyy' },
  { label: 'Vodo', value: 'Vodo' },
  { label: 'Vodskov', value: 'Vodskov' },
  { label: 'Vodstroy', value: 'Vodstroy' },
  { label: 'Vodyane', value: 'Vodyane' },
  { label: 'Voerde', value: 'Voerde' },
  { label: 'Voerendaal', value: 'Voerendaal' },
  { label: 'Voetin', value: 'Voetin' },
  { label: 'Vœuil-Et-Giget', value: 'Vœuil-Et-Giget' },
  { label: 'Vogan', value: 'Vogan' },
  { label: 'Vogar', value: 'Vogar' },
  { label: 'Vogau', value: 'Vogau' },
  { label: 'Vogelenzang', value: 'Vogelenzang' },
  { label: 'Vogelkwartier', value: 'Vogelkwartier' },
  { label: 'Vögelsen', value: 'Vögelsen' },
  { label: 'Vogelwijk', value: 'Vogelwijk' },
  { label: 'Voghera', value: 'Voghera' },
  { label: 'Voghiera', value: 'Voghiera' },
  { label: 'Voglans', value: 'Voglans' },
  { label: 'Vogogna', value: 'Vogogna' },
  { label: 'Vogošća', value: 'Vogošća' },
  { label: 'Vogt', value: 'Vogt' },
  { label: 'Vogtareuth', value: 'Vogtareuth' },
  { label: 'Vogtsburg', value: 'Vogtsburg' },
  { label: 'Vohburg An Der Donau', value: 'Vohburg An Der Donau' },
  { label: 'Vohenstrauß', value: 'Vohenstrauß' },
  { label: 'Vohibinany', value: 'Vohibinany' },
  { label: 'Vöhl', value: 'Vöhl' },
  { label: 'Võhma', value: 'Võhma' },
  { label: 'Vöhrenbach', value: 'Vöhrenbach' },
  { label: 'Vöhringen', value: 'Vöhringen' },
  { label: 'Vöhringen', value: 'Vöhringen' },
  { label: 'Voi', value: 'Voi' },
  { label: 'Voiceşti', value: 'Voiceşti' },
  { label: 'Void-Vacon', value: 'Void-Vacon' },
  { label: 'Voievodeasa', value: 'Voievodeasa' },
  { label: 'Voigtstedt', value: 'Voigtstedt' },
  { label: 'Voila', value: 'Voila' },
  { label: 'Voineasa', value: 'Voineasa' },
  { label: 'Voineasa', value: 'Voineasa' },
  { label: 'Voineşti', value: 'Voineşti' },
  { label: 'Voineşti', value: 'Voineşti' },
  { label: 'Voineşti', value: 'Voineşti' },
  { label: 'Voinești', value: 'Voinești' },
  { label: 'Voiniceni', value: 'Voiniceni' },
  { label: 'Voinjama', value: 'Voinjama' },
  { label: 'Voinka', value: 'Voinka' },
  { label: 'Voiron', value: 'Voiron' },
  { label: 'Voisenon', value: 'Voisenon' },
  { label: 'Voisins-Le-Bretonneux', value: 'Voisins-Le-Bretonneux' },
  { label: 'Voiteg', value: 'Voiteg' },
  { label: 'Voiteștii Din Vale', value: 'Voiteștii Din Vale' },
  { label: 'Voitinel', value: 'Voitinel' },
  { label: 'Voitsberg', value: 'Voitsberg' },
  { label: 'Voivodeni', value: 'Voivodeni' },
  { label: 'Voivozi', value: 'Voivozi' },
  { label: 'Vojens', value: 'Vojens' },
  { label: 'Vojka', value: 'Vojka' },
  { label: 'Vojkovice', value: 'Vojkovice' },
  { label: 'Vojnik', value: 'Vojnik' },
  { label: 'Vojvoda Stepa', value: 'Vojvoda Stepa' },
  { label: 'Voka', value: 'Voka' },
  { label: 'Vokhaïkó', value: 'Vokhaïkó' },
  { label: 'Vokhma', value: 'Vokhma' },
  { label: 'Vokhomskiy Rayon', value: 'Vokhomskiy Rayon' },
  { label: 'Vokhtoga', value: 'Vokhtoga' },
  { label: 'Vol’No-Nadezhdinskoye', value: 'Vol’No-Nadezhdinskoye' },
  { label: 'Vol’Noye', value: 'Vol’Noye' },
  { label: 'Vol’Nyy Aul', value: 'Vol’Nyy Aul' },
  { label: 'Vol’Sk', value: 'Vol’Sk' },
  { label: 'Vol’Skiy Rayon', value: 'Vol’Skiy Rayon' },
  { label: 'Vólakas', value: 'Vólakas' },
  { label: 'Volano', value: 'Volano' },
  { label: 'Volargne', value: 'Volargne' },
  { label: 'Volary', value: 'Volary' },
  { label: 'Volcán', value: 'Volcán' },
  { label: 'Volcano', value: 'Volcano' },
  { label: 'Volchanets', value: 'Volchanets' },
  { label: 'Volchansk', value: 'Volchansk' },
  { label: 'Volchikha', value: 'Volchikha' },
  { label: 'Volchikhinskiy Rayon', value: 'Volchikhinskiy Rayon' },
  { label: 'Volčja Draga', value: 'Volčja Draga' },
  { label: 'Volda', value: 'Volda' },
  { label: 'Volders', value: 'Volders' },
  { label: 'Voldijn', value: 'Voldijn' },
  { label: 'Voldöpp', value: 'Voldöpp' },
  { label: 'Volendam', value: 'Volendam' },
  { label: 'Volet', value: 'Volet' },
  { label: 'Volga', value: 'Volga' },
  { label: 'Volga', value: 'Volga' },
  { label: 'Volgelsheim', value: 'Volgelsheim' },
  { label: 'Volgodonsk', value: 'Volgodonsk' },
  { label: 'Volgograd', value: 'Volgograd' },
  { label: 'Volgo-Kaspiyskiy', value: 'Volgo-Kaspiyskiy' },
  { label: 'Volgorechensk', value: 'Volgorechensk' },
  { label: 'Voljevac', value: 'Voljevac' },
  { label: 'Volkach', value: 'Volkach' },
  { label: 'Volkel', value: 'Volkel' },
  { label: 'Völkendorf', value: 'Völkendorf' },
  { label: 'Volkenschwand', value: 'Volkenschwand' },
  { label: 'Völkermarkt', value: 'Völkermarkt' },
  { label: 'Völkermarkt District', value: 'Völkermarkt District' },
  { label: 'Völkermarkter Vorstadt', value: 'Völkermarkter Vorstadt' },
  { label: 'Völkershausen', value: 'Völkershausen' },
  { label: 'Volkertshausen', value: 'Volkertshausen' },
  { label: 'Volketswil / Volketswil (Dorf)', value: 'Volketswil / Volketswil (Dorf)' },
  { label: 'Volkhov', value: 'Volkhov' },
  { label: 'Volkhovskiy', value: 'Volkhovskiy' },
  { label: 'Volkhovskiy Rayon', value: 'Volkhovskiy Rayon' },
  { label: 'Völklingen', value: 'Völklingen' },
  { label: 'Volkmarsen', value: 'Volkmarsen' },
  { label: 'Volkovysk', value: 'Volkovysk' },
  { label: 'Volksrust', value: 'Volksrust' },
  { label: 'Volkstedt', value: 'Volkstedt' },
  { label: 'Volla', value: 'Volla' },
  { label: 'Vollenhove', value: 'Vollenhove' },
  { label: 'Vollersode', value: 'Vollersode' },
  { label: 'Volmerange-Les-Mines', value: 'Volmerange-Les-Mines' },
  { label: 'Volney', value: 'Volney' },
  { label: 'Volnovakha', value: 'Volnovakha' },
  { label: 'Volo', value: 'Volo' },
  { label: 'Volochayevka Vtoraya', value: 'Volochayevka Vtoraya' },
  { label: 'Volochayevskoye', value: 'Volochayevskoye' },
  { label: 'Volodarka', value: 'Volodarka' },
  { label: 'Volodarsk', value: 'Volodarsk' },
  { label: 'Volodarskiy', value: 'Volodarskiy' },
  { label: 'Volodarskogo', value: 'Volodarskogo' },
  { label: 'Volodarskoye', value: 'Volodarskoye' },
  { label: 'Voloder', value: 'Voloder' },
  { label: 'Volodymyrets’', value: 'Volodymyrets’' },
  { label: 'Volodymyr-Volynskyi', value: 'Volodymyr-Volynskyi' },
  { label: 'Vologda', value: 'Vologda' },
  { label: 'Vologodskiy Rayon', value: 'Vologodskiy Rayon' },
  { label: 'Voloiac', value: 'Voloiac' },
  { label: 'Voloka', value: 'Voloka' },
  { label: 'Volokolamsk', value: 'Volokolamsk' },
  { label: 'Volokolamskiy Rayon', value: 'Volokolamskiy Rayon' },
  { label: 'Volokonovka', value: 'Volokonovka' },
  { label: 'Volokonovskiy Rayon', value: 'Volokonovskiy Rayon' },
  { label: 'Volongo', value: 'Volongo' },
  { label: 'Volonne', value: 'Volonne' },
  { label: 'Volos', value: 'Volos' },
  { label: 'Voloshka', value: 'Voloshka' },
  { label: 'Volosovo', value: 'Volosovo' },
  { label: 'Volosovskiy Rayon', value: 'Volosovskiy Rayon' },
  { label: 'Volot', value: 'Volot' },
  { label: 'Volotovskiy Rayon', value: 'Volotovskiy Rayon' },
  { label: 'Volovăţ', value: 'Volovăţ' },
  { label: 'Volovets', value: 'Volovets' },
  { label: 'Volovets Raion', value: 'Volovets Raion' },
  { label: 'Volovo', value: 'Volovo' },
  { label: 'Volovo', value: 'Volovo' },
  { label: 'Volovskiy Rayon', value: 'Volovskiy Rayon' },
  { label: 'Volpago Del Montello', value: 'Volpago Del Montello' },
  { label: 'Volpara', value: 'Volpara' },
  { label: 'Volpedo', value: 'Volpedo' },
  { label: 'Volpeglino', value: 'Volpeglino' },
  { label: 'Volpiano', value: 'Volpiano' },
  { label: 'Völpke', value: 'Völpke' },
  { label: 'Völs', value: 'Völs' },
  { label: 'Volstroff', value: 'Volstroff' },
  { label: 'Volta Grande', value: 'Volta Grande' },
  { label: 'Volta Mantovana', value: 'Volta Mantovana' },
  { label: 'Volta Redonda', value: 'Volta Redonda' },
  { label: 'Voltaggio', value: 'Voltaggio' },
  { label: 'Voltago', value: 'Voltago' },
  { label: 'Voltago Agordino', value: 'Voltago Agordino' },
  { label: 'Voltana', value: 'Voltana' },
  { label: 'Volterra', value: 'Volterra' },
  { label: 'Voltido', value: 'Voltido' },
  { label: 'Voltlage', value: 'Voltlage' },
  { label: 'Volturara Appula', value: 'Volturara Appula' },
  { label: 'Volturara Irpina', value: 'Volturara Irpina' },
  { label: 'Volturino', value: 'Volturino' },
  { label: 'Voluntari', value: 'Voluntari' },
  { label: 'Voluntari City', value: 'Voluntari City' },
  { label: 'Volusia County', value: 'Volusia County' },
  { label: 'Volvera', value: 'Volvera' },
  { label: 'Volvic', value: 'Volvic' },
  { label: 'Volx', value: 'Volx' },
  { label: 'Volxheim', value: 'Volxheim' },
  { label: 'Volya', value: 'Volya' },
  { label: 'Volyně', value: 'Volyně' },
  { label: 'Volzhsk', value: 'Volzhsk' },
  { label: 'Volzhskiy', value: 'Volzhskiy' },
  { label: 'Volzhskiy Rayon', value: 'Volzhskiy Rayon' },
  { label: 'Volzhsky', value: 'Volzhsky' },
  { label: 'Vom', value: 'Vom' },
  { label: 'Vomero', value: 'Vomero' },
  { label: 'Vomp', value: 'Vomp' },
  { label: 'Von Ormy', value: 'Von Ormy' },
  { label: 'Vondelwijk', value: 'Vondelwijk' },
  { label: 'Vónitsa', value: 'Vónitsa' },
  { label: 'Vonnas', value: 'Vonnas' },
  { label: 'Vonore', value: 'Vonore' },
  { label: 'Vonyarcvashegy', value: 'Vonyarcvashegy' },
  { label: 'Voorburg', value: 'Voorburg' },
  { label: 'Voorhees', value: 'Voorhees' },
  { label: 'Voorheesville', value: 'Voorheesville' },
  { label: 'Voorhout', value: 'Voorhout' },
  { label: 'Voorschoten', value: 'Voorschoten' },
  { label: 'Voorst', value: 'Voorst' },
  { label: 'Voorthuizen', value: 'Voorthuizen' },
  { label: 'Vörå', value: 'Vörå' },
  { label: 'Voranava', value: 'Voranava' },
  { label: 'Vorau', value: 'Vorau' },
  { label: 'Vorbach', value: 'Vorbach' },
  { label: 'Vorchdorf', value: 'Vorchdorf' },
  { label: 'Vordemwald', value: 'Vordemwald' },
  { label: 'Vorden', value: 'Vorden' },
  { label: 'Vorderhornbach', value: 'Vorderhornbach' },
  { label: 'Vordernberg', value: 'Vordernberg' },
  { label: 'Vorderthal', value: 'Vorderthal' },
  { label: 'Vorderthiersee', value: 'Vorderthiersee' },
  { label: 'Vordingborg', value: 'Vordingborg' },
  { label: 'Vordingborg Kommune', value: 'Vordingborg Kommune' },
  { label: 'Vordorf', value: 'Vordorf' },
  { label: 'Vorë', value: 'Vorë' },
  { label: 'Voreppe', value: 'Voreppe' },
  { label: 'Vorey', value: 'Vorey' },
  { label: 'Vorgashor', value: 'Vorgashor' },
  { label: 'Vorkuta', value: 'Vorkuta' },
  { label: 'Vormedal', value: 'Vormedal' },
  { label: 'Vormsi Vald', value: 'Vormsi Vald' },
  { label: 'Vorniceni', value: 'Vorniceni' },
  { label: 'Vorob’Yevskiy Rayon', value: 'Vorob’Yevskiy Rayon' },
  { label: 'Vorob’Yovo', value: 'Vorob’Yovo' },
  { label: 'Vorobyovo', value: 'Vorobyovo' },
  { label: 'Vorokhta', value: 'Vorokhta' },
  { label: 'Voróklini', value: 'Voróklini' },
  { label: 'Vorona', value: 'Vorona' },
  { label: 'Vorona Teodoru', value: 'Vorona Teodoru' },
  { label: 'Voronezh', value: 'Voronezh' },
  { label: 'Voronkov', value: 'Voronkov' },
  { label: 'Voronovytsya', value: 'Voronovytsya' },
  { label: 'Vorontsovka', value: 'Vorontsovka' },
  { label: 'Vorontsovka', value: 'Vorontsovka' },
  { label: 'Voroshilovskiy District Volgograd', value: 'Voroshilovskiy District Volgograd' },
  { label: 'Voroshnëvo', value: 'Voroshnëvo' },
  { label: 'Vorotynets', value: 'Vorotynets' },
  { label: 'Vorotynsk', value: 'Vorotynsk' },
  { label: 'Vorozhba', value: 'Vorozhba' },
  { label: 'Vorra', value: 'Vorra' },
  { label: 'Vorselaar', value: 'Vorselaar' },
  { label: 'Vorsha', value: 'Vorsha' },
  { label: 'Vorsma', value: 'Vorsma' },
  { label: 'Vorstenbosch', value: 'Vorstenbosch' },
  { label: 'Vörstetten', value: 'Vörstetten' },
  { label: 'Vorţa', value: 'Vorţa' },
  { label: 'Võru', value: 'Võru' },
  { label: 'Võru Vald', value: 'Võru Vald' },
  { label: 'Vorukh', value: 'Vorukh' },
  { label: 'Vorwerk', value: 'Vorwerk' },
  { label: 'Vorzel’', value: 'Vorzel’' },
  { label: 'Vose’', value: 'Vose’' },
  { label: 'Vösendorf', value: 'Vösendorf' },
  { label: 'Vosges', value: 'Vosges' },
  { label: 'Voskehask', value: 'Voskehask' },
  { label: 'Voskehat', value: 'Voskehat' },
  { label: 'Vosketap’', value: 'Vosketap’' },
  { label: 'Voskevan', value: 'Voskevan' },
  { label: 'Voskevaz', value: 'Voskevaz' },
  { label: 'Voskhod', value: 'Voskhod' },
  { label: 'Voskopojë', value: 'Voskopojë' },
  { label: 'Voskresensk', value: 'Voskresensk' },
  { label: 'Voskresenskiy Rayon', value: 'Voskresenskiy Rayon' },
  { label: 'Voskresenskiy Rayon', value: 'Voskresenskiy Rayon' },
  { label: 'Voskresenskiy Rayon', value: 'Voskresenskiy Rayon' },
  { label: 'Voskresenskoye', value: 'Voskresenskoye' },
  { label: 'Voskresenskoye', value: 'Voskresenskoye' },
  { label: 'Voskresenskoye', value: 'Voskresenskoye' },
  { label: 'Voșlăbeni', value: 'Voșlăbeni' },
  { label: 'Voss', value: 'Voss' },
  { label: 'Vosselaar', value: 'Vosselaar' },
  { label: 'Vostan', value: 'Vostan' },
  { label: 'Vostochnoe Degunino', value: 'Vostochnoe Degunino' },
  { label: 'Vostochny', value: 'Vostochny' },
  { label: 'Vostochnyy', value: 'Vostochnyy' },
  { label: 'Vostochnyy', value: 'Vostochnyy' },
  { label: 'Vostochnyy', value: 'Vostochnyy' },
  { label: 'Vostok', value: 'Vostok' },
  { label: 'Vostok', value: 'Vostok' },
  { label: 'Vostryakovo', value: 'Vostryakovo' },
  { label: 'Votice', value: 'Votice' },
  { label: 'Votkinsk', value: 'Votkinsk' },
  { label: 'Voto', value: 'Voto' },
  { label: 'Votorantim', value: 'Votorantim' },
  { label: 'Vottignasco', value: 'Vottignasco' },
  { label: 'Votuporanga', value: 'Votuporanga' },
  { label: 'Vouani', value: 'Vouani' },
  { label: 'Vougy', value: 'Vougy' },
  { label: 'Vouillé', value: 'Vouillé' },
  { label: 'Voujeaucourt', value: 'Voujeaucourt' },
  { label: 'Voúla', value: 'Voúla' },
  { label: 'Voulangis', value: 'Voulangis' },
  { label: 'Voulgaréli', value: 'Voulgaréli' },
  { label: 'Vouliagméni', value: 'Vouliagméni' },
  { label: 'Voulx', value: 'Voulx' },
  { label: 'Vouneuil-Sous-Biard', value: 'Vouneuil-Sous-Biard' },
  { label: 'Vouneuil-Sur-Vienne', value: 'Vouneuil-Sur-Vienne' },
  { label: 'Vounoplagiá', value: 'Vounoplagiá' },
  { label: 'Vourey', value: 'Vourey' },
  { label: 'Vourles', value: 'Vourles' },
  { label: 'Voutezac', value: 'Voutezac' },
  { label: 'Vouvray', value: 'Vouvray' },
  { label: 'Vouvry', value: 'Vouvry' },
  { label: 'Vouzela', value: 'Vouzela' },
  { label: 'Vouziers', value: 'Vouziers' },
  { label: 'Vouzon', value: 'Vouzon' },
  { label: 'Vovchans’K', value: 'Vovchans’K' },
  { label: 'Voves', value: 'Voves' },
  { label: 'Voyager Point', value: 'Voyager Point' },
  { label: 'Voykovo', value: 'Voykovo' },
  { label: 'Voyskovitsy', value: 'Voyskovitsy' },
  { label: 'Voyutychi', value: 'Voyutychi' },
  { label: 'Voyvozh', value: 'Voyvozh' },
  { label: 'Vozdvizhenka', value: 'Vozdvizhenka' },
  { label: 'Vozhega', value: 'Vozhega' },
  { label: 'Vozmediano', value: 'Vozmediano' },
  { label: 'Voznesen’Ye', value: 'Voznesen’Ye' },
  { label: 'Voznesenka', value: 'Voznesenka' },
  { label: 'Voznesensk', value: 'Voznesensk' },
  { label: 'Voznesenskaya', value: 'Voznesenskaya' },
  { label: 'Voznesenskaya', value: 'Voznesenskaya' },
  { label: 'Voznesenskiy', value: 'Voznesenskiy' },
  { label: 'Voznesenskoye', value: 'Voznesenskoye' },
  { label: 'Vrå', value: 'Vrå' },
  { label: 'Vráble', value: 'Vráble' },
  { label: 'Vrachnaíika', value: 'Vrachnaíika' },
  { label: 'Vracov', value: 'Vracov' },
  { label: 'Vradiyivka', value: 'Vradiyivka' },
  { label: 'Vrakháti', value: 'Vrakháti' },
  { label: 'Vraná', value: 'Vraná' },
  { label: 'Vrâncioaia', value: 'Vrâncioaia' },
  { label: 'Vrané Nad Vltavou', value: 'Vrané Nad Vltavou' },
  { label: 'Vrănești', value: 'Vrănești' },
  { label: 'Vraneštica', value: 'Vraneštica' },
  { label: 'Vrangel’', value: 'Vrangel’' },
  { label: 'Vrani', value: 'Vrani' },
  { label: 'Vranić', value: 'Vranić' },
  { label: 'Vranje', value: 'Vranje' },
  { label: 'Vranjic', value: 'Vranjic' },
  { label: 'Vranov Nad Topľou', value: 'Vranov Nad Topľou' },
  { label: 'Vranovice', value: 'Vranovice' },
  { label: 'Vransko', value: 'Vransko' },
  { label: 'Vrapčište', value: 'Vrapčište' },
  { label: 'Vrata', value: 'Vrata' },
  { label: 'Vratimov', value: 'Vratimov' },
  { label: 'Vratišinec', value: 'Vratišinec' },
  { label: 'Vratnica', value: 'Vratnica' },
  { label: 'Vratsa', value: 'Vratsa' },
  { label: 'Vrbanja', value: 'Vrbanja' },
  { label: 'Vrbas', value: 'Vrbas' },
  { label: 'Vrbátky', value: 'Vrbátky' },
  { label: 'Vrbice', value: 'Vrbice' },
  { label: 'Vrbice', value: 'Vrbice' },
  { label: 'Vrbnik', value: 'Vrbnik' },
  { label: 'Vrbno Pod Pradědem', value: 'Vrbno Pod Pradědem' },
  { label: 'Vrbov', value: 'Vrbov' },
  { label: 'Vrbové', value: 'Vrbové' },
  { label: 'Vrbovec', value: 'Vrbovec' },
  { label: 'Vrbovec', value: 'Vrbovec' },
  { label: 'Vrbovsko', value: 'Vrbovsko' },
  { label: 'Vrchlabí', value: 'Vrchlabí' },
  { label: 'Vrdnik', value: 'Vrdnik' },
  { label: 'Vrdy', value: 'Vrdy' },
  { label: 'Vred', value: 'Vred' },
  { label: 'Vrede', value: 'Vrede' },
  { label: 'Vredefort', value: 'Vredefort' },
  { label: 'Vreden', value: 'Vreden' },
  { label: 'Vredenburg', value: 'Vredenburg' },
  { label: 'Vredenburg', value: 'Vredenburg' },
  { label: 'Vredendal', value: 'Vredendal' },
  { label: 'Vreed-En-Hoop', value: 'Vreed-En-Hoop' },
  { label: 'Vrees', value: 'Vrees' },
  { label: 'Vreeswijk', value: 'Vreeswijk' },
  { label: 'Vreewijk', value: 'Vreewijk' },
  { label: 'Vřesina', value: 'Vřesina' },
  { label: 'Vrgorac', value: 'Vrgorac' },
  { label: 'Vrhnika', value: 'Vrhnika' },
  { label: 'Vriddhachalam', value: 'Vriddhachalam' },
  { label: 'Vrieheide', value: 'Vrieheide' },
  { label: 'Vries', value: 'Vries' },
  { label: 'Vriezenveen', value: 'Vriezenveen' },
  { label: 'Vrigne-Aux-Bois', value: 'Vrigne-Aux-Bois' },
  { label: 'Vrigstad', value: 'Vrigstad' },
  { label: 'Vrilissia', value: 'Vrilissia' },
  { label: 'Vrindavan', value: 'Vrindavan' },
  { label: 'Vrlika', value: 'Vrlika' },
  { label: 'Vrnjačka Banja', value: 'Vrnjačka Banja' },
  { label: 'Vrnograč', value: 'Vrnograč' },
  { label: 'Vrontoú', value: 'Vrontoú' },
  { label: 'Vroomshoop', value: 'Vroomshoop' },
  { label: 'Vroutek', value: 'Vroutek' },
  { label: 'Vrpolje', value: 'Vrpolje' },
  { label: 'Vršac', value: 'Vršac' },
  { label: 'Vrsar', value: 'Vrsar' },
  { label: 'Vrsar-Orsera', value: 'Vrsar-Orsera' },
  { label: 'Vrsi', value: 'Vrsi' },
  { label: 'Vrtojba', value: 'Vrtojba' },
  { label: 'Vrútky', value: 'Vrútky' },
  { label: 'Vrutok', value: 'Vrutok' },
  { label: 'Vryburg', value: 'Vryburg' },
  { label: 'Vryheid', value: 'Vryheid' },
  { label: 'Vrýses', value: 'Vrýses' },
  { label: 'Všemina', value: 'Všemina' },
  { label: 'Všenory', value: 'Všenory' },
  { label: 'Všeruby', value: 'Všeruby' },
  { label: 'Všestary', value: 'Všestary' },
  { label: 'Všetaty', value: 'Všetaty' },
  { label: 'Vsetín', value: 'Vsetín' },
  { label: 'Vsevolozhsk', value: 'Vsevolozhsk' },
  { label: 'Vsevolozhskiy Rayon', value: 'Vsevolozhskiy Rayon' },
  { label: 'Vuadens', value: 'Vuadens' },
  { label: 'Vuarrens', value: 'Vuarrens' },
  { label: 'Vue', value: 'Vue' },
  { label: 'Vught', value: 'Vught' },
  { label: 'Vuhlehirs’K', value: 'Vuhlehirs’K' },
  { label: 'Vuka', value: 'Vuka' },
  { label: 'Vukatanë', value: 'Vukatanë' },
  { label: 'Vukovar', value: 'Vukovar' },
  { label: 'Vukovije Donje', value: 'Vukovije Donje' },
  { label: 'Vuktyl', value: 'Vuktyl' },
  { label: 'Vulaines-Sur-Seine', value: 'Vulaines-Sur-Seine' },
  { label: 'Vulcan', value: 'Vulcan' },
  { label: 'Vulcan', value: 'Vulcan' },
  { label: 'Vulcan', value: 'Vulcan' },
  { label: 'Vulcan', value: 'Vulcan' },
  { label: 'Vulcana Băi', value: 'Vulcana Băi' },
  { label: 'Vulcana De Sus', value: 'Vulcana De Sus' },
  { label: 'Vulcana-Pandele', value: 'Vulcana-Pandele' },
  { label: 'Vulcăneasa', value: 'Vulcăneasa' },
  { label: 'Vulcăneşti', value: 'Vulcăneşti' },
  { label: 'Vulpeni', value: 'Vulpeni' },
  { label: 'Vultureni', value: 'Vultureni' },
  { label: 'Vultureni', value: 'Vultureni' },
  { label: 'Vultureşti', value: 'Vultureşti' },
  { label: 'Vultureşti', value: 'Vultureşti' },
  { label: 'Vultureşti', value: 'Vultureşti' },
  { label: 'Vulturești', value: 'Vulturești' },
  { label: 'Vulturu', value: 'Vulturu' },
  { label: 'Vulturu', value: 'Vulturu' },
  { label: 'Vũng Tàu', value: 'Vũng Tàu' },
  { label: 'Vuokatti', value: 'Vuokatti' },
  { label: 'Vuolijoki', value: 'Vuolijoki' },
  { label: 'Vuores', value: 'Vuores' },
  { label: 'Vuosaari', value: 'Vuosaari' },
  { label: 'Vuren', value: 'Vuren' },
  { label: 'Vurğun', value: 'Vurğun' },
  { label: 'Vurnarskiy Rayon', value: 'Vurnarskiy Rayon' },
  { label: 'Vurnary', value: 'Vurnary' },
  { label: 'Vurpăr', value: 'Vurpăr' },
  { label: 'Vurpăr', value: 'Vurpăr' },
  { label: 'Vutcani', value: 'Vutcani' },
  { label: 'Vuvulane', value: 'Vuvulane' },
  { label: 'Vuyyuru', value: 'Vuyyuru' },
  { label: 'Vuzenica', value: 'Vuzenica' },
  { label: 'Vvedenka', value: 'Vvedenka' },
  { label: 'Vvedenskoye', value: 'Vvedenskoye' },
  { label: 'Vyalikaya Byerastavitsa', value: 'Vyalikaya Byerastavitsa' },
  { label: 'Vyaliki Trastsyanets', value: 'Vyaliki Trastsyanets' },
  { label: 'Vyara', value: 'Vyara' },
  { label: 'Vyartsilya', value: 'Vyartsilya' },
  { label: 'Vyatskiye Polyany', value: 'Vyatskiye Polyany' },
  { label: 'Vyaz’Ma', value: 'Vyaz’Ma' },
  { label: 'Vyazemskiy', value: 'Vyazemskiy' },
  { label: 'Vyazniki', value: 'Vyazniki' },
  { label: 'Vyazovaya', value: 'Vyazovaya' },
  { label: 'Vybor', value: 'Vybor' },
  { label: 'Vyborg', value: 'Vyborg' },
  { label: 'Vychegodskiy', value: 'Vychegodskiy' },
  { label: 'Vydrino', value: 'Vydrino' },
  { label: 'Vydropuzhsk', value: 'Vydropuzhsk' },
  { label: 'Vyerkhnyadzvinsk', value: 'Vyerkhnyadzvinsk' },
  { label: 'Vyerkhnyadzvinski Rayon', value: 'Vyerkhnyadzvinski Rayon' },
  { label: 'Vyetka', value: 'Vyetka' },
  { label: 'Vyetkawski Rayon', value: 'Vyetkawski Rayon' },
  { label: 'Vygonichi', value: 'Vygonichi' },
  { label: 'Vygonichskiy Rayon', value: 'Vygonichskiy Rayon' },
  { label: 'Vykhino-Zhulebino', value: 'Vykhino-Zhulebino' },
  { label: 'Vyksa', value: 'Vyksa' },
  { label: 'Vylkove', value: 'Vylkove' },
  { label: 'Vylok', value: 'Vylok' },
  { label: 'Vynohradiv', value: 'Vynohradiv' },
  { label: 'Vynohradiv Raion', value: 'Vynohradiv Raion' },
  { label: 'Vynohradivka', value: 'Vynohradivka' },
  { label: 'Vynohradove', value: 'Vynohradove' },
  { label: 'Vypolzovo', value: 'Vypolzovo' },
  { label: 'Vyritsa', value: 'Vyritsa' },
  { label: 'Výronas', value: 'Výronas' },
  { label: 'Vysehrad', value: 'Vysehrad' },
  { label: 'Vyselki', value: 'Vyselki' },
  { label: 'Vyselkovskiy Rayon', value: 'Vyselkovskiy Rayon' },
  { label: 'Vysha', value: 'Vysha' },
  { label: 'Vyshchetarasivka', value: 'Vyshchetarasivka' },
  { label: 'Vyshestebliyevskaya', value: 'Vyshestebliyevskaya' },
  { label: 'Vyshgorodskiy Rayon', value: 'Vyshgorodskiy Rayon' },
  { label: 'Vyshhorod', value: 'Vyshhorod' },
  { label: 'Vyshkov', value: 'Vyshkov' },
  { label: 'Vyshkovo', value: 'Vyshkovo' },
  { label: 'Vyshneve', value: 'Vyshneve' },
  { label: 'Vyshneve', value: 'Vyshneve' },
  { label: 'Vyshnevolotskiy Rayon', value: 'Vyshnevolotskiy Rayon' },
  { label: 'Vyshneye Dolgoye', value: 'Vyshneye Dolgoye' },
  { label: 'Vyshniy Volochëk', value: 'Vyshniy Volochëk' },
  { label: 'Vyškov', value: 'Vyškov' },
  { label: 'Vyšné Ružbachy', value: 'Vyšné Ružbachy' },
  { label: 'Vysočany', value: 'Vysočany' },
  { label: 'Vysokaya Gora', value: 'Vysokaya Gora' },
  { label: 'Vysokaye', value: 'Vysokaye' },
  { label: 'Vysoké Mýto', value: 'Vysoké Mýto' },
  { label: 'Vysoké Nad Jizerou', value: 'Vysoké Nad Jizerou' },
  { label: 'Vysoké Tatry', value: 'Vysoké Tatry' },
  { label: 'Vysokinichi', value: 'Vysokinichi' },
  { label: 'Vysokiy', value: 'Vysokiy' },
  { label: 'Vysokogornyy', value: 'Vysokogornyy' },
  { label: 'Vysokogorskiy Rayon', value: 'Vysokogorskiy Rayon' },
  { label: 'Vysokopillya', value: 'Vysokopillya' },
  { label: 'Vysokovsk', value: 'Vysokovsk' },
  { label: 'Vysokoye', value: 'Vysokoye' },
  { label: 'Vysokyy', value: 'Vysokyy' },
  { label: 'Vysotsk', value: 'Vysotsk' },
  { label: 'Vysotskoye', value: 'Vysotskoye' },
  { label: 'Vyšší Brod', value: 'Vyšší Brod' },
  { label: 'Vytegorskiy Rayon', value: 'Vytegorskiy Rayon' },
  { label: 'Vytegra', value: 'Vytegra' },
  { label: 'Vyyezdnoye', value: 'Vyyezdnoye' },
  { label: 'Vyzhnytsya', value: 'Vyzhnytsya' },
  { label: 'Vzmorye', value: 'Vzmorye' },
  { label: 'Wa', value: 'Wa' },
  { label: 'Waabs', value: 'Waabs' },
  { label: 'Waadhoeke', value: 'Waadhoeke' },
  { label: 'Waajid', value: 'Waajid' },
  { label: 'Waake', value: 'Waake' },
  { label: 'Waakirchen', value: 'Waakirchen' },
  { label: 'Waal', value: 'Waal' },
  { label: 'Waalre', value: 'Waalre' },
  { label: 'Waalwijk', value: 'Waalwijk' },
  { label: 'Waarde', value: 'Waarde' },
  { label: 'Waardenburg', value: 'Waardenburg' },
  { label: 'Waarder', value: 'Waarder' },
  { label: 'Waarland', value: 'Waarland' },
  { label: 'Waarschoot', value: 'Waarschoot' },
  { label: 'Waasmunster', value: 'Waasmunster' },
  { label: 'Wabag', value: 'Wabag' },
  { label: 'Wabana', value: 'Wabana' },
  { label: 'Wabash', value: 'Wabash' },
  { label: 'Wabash County', value: 'Wabash County' },
  { label: 'Wabash County', value: 'Wabash County' },
  { label: 'Wabasha', value: 'Wabasha' },
  { label: 'Wabasha County', value: 'Wabasha County' },
  { label: 'Wabasso Beach', value: 'Wabasso Beach' },
  { label: 'Wabaunsee County', value: 'Wabaunsee County' },
  { label: 'Wabern', value: 'Wabern' },
  { label: 'Wąbrzeźno', value: 'Wąbrzeźno' },
  { label: 'Wabu', value: 'Wabu' },
  { label: 'Wachau', value: 'Wachau' },
  { label: 'Wacheng', value: 'Wacheng' },
  { label: 'Wachenheim', value: 'Wachenheim' },
  { label: 'Wachenroth', value: 'Wachenroth' },
  { label: 'Wąchock', value: 'Wąchock' },
  { label: 'Wachtberg', value: 'Wachtberg' },
  { label: 'Wachtebeke', value: 'Wachtebeke' },
  { label: 'Wachtendonk', value: 'Wachtendonk' },
  { label: 'Wächtersbach', value: 'Wächtersbach' },
  { label: 'Wacken', value: 'Wacken' },
  { label: 'Wackernheim', value: 'Wackernheim' },
  { label: 'Wackerow', value: 'Wackerow' },
  { label: 'Wackersberg', value: 'Wackersberg' },
  { label: 'Wackersdorf', value: 'Wackersdorf' },
  { label: 'Waco', value: 'Waco' },
  { label: 'Wacol', value: 'Wacol' },
  { label: 'Waconia', value: 'Waconia' },
  { label: 'Wacousta', value: 'Wacousta' },
  { label: 'Wad Az Zāki', value: 'Wad Az Zāki' },
  { label: 'Wad Medani', value: 'Wad Medani' },
  { label: 'Wad Rāwah', value: 'Wad Rāwah' },
  { label: 'Wadala', value: 'Wadala' },
  { label: 'Wadalba', value: 'Wadalba' },
  { label: 'Waddān', value: 'Waddān' },
  { label: 'Waddesdon', value: 'Waddesdon' },
  { label: 'Waddington', value: 'Waddington' },
  { label: 'Waddinxveen', value: 'Waddinxveen' },
  { label: 'Wade', value: 'Wade' },
  { label: 'Wade Hampton', value: 'Wade Hampton' },
  { label: 'Wadebridge', value: 'Wadebridge' },
  { label: 'Wadena', value: 'Wadena' },
  { label: 'Wadena', value: 'Wadena' },
  { label: 'Wadena County', value: 'Wadena County' },
  { label: 'Wädenswil', value: 'Wädenswil' },
  { label: 'Wädenswil / Boller-Giessen', value: 'Wädenswil / Boller-Giessen' },
  { label: 'Wädenswil / Büelen', value: 'Wädenswil / Büelen' },
  { label: 'Wädenswil / Dorf (Wädenswil)', value: 'Wädenswil / Dorf (Wädenswil)' },
  { label: 'Wädenswil / Eichweid', value: 'Wädenswil / Eichweid' },
  { label: 'Wädenswil / Hangenmoos', value: 'Wädenswil / Hangenmoos' },
  { label: 'Wädenswil / Leihof-Mühlebach', value: 'Wädenswil / Leihof-Mühlebach' },
  { label: 'Wädenswil / Untermosen-Fuhr', value: 'Wädenswil / Untermosen-Fuhr' },
  { label: 'Wadern', value: 'Wadern' },
  { label: 'Wadersloh', value: 'Wadersloh' },
  { label: 'Wadesboro', value: 'Wadesboro' },
  { label: 'Wadeye', value: 'Wadeye' },
  { label: 'Wadgaon', value: 'Wadgaon' },
  { label: 'Wadgassen', value: 'Wadgassen' },
  { label: 'Wadhai', value: 'Wadhai' },
  { label: 'Wadhrah', value: 'Wadhrah' },
  { label: 'Wadhurst', value: 'Wadhurst' },
  { label: 'Wadhwan', value: 'Wadhwan' },
  { label: 'Wadi', value: 'Wadi' },
  { label: 'Wadi Al Ayn', value: 'Wadi Al Ayn' },
  { label: 'Wadi Al Jalil', value: 'Wadi Al Jalil' },
  { label: 'Wādī As Sīr', value: 'Wādī As Sīr' },
  { label: 'Wading River', value: 'Wading River' },
  { label: 'Wadley', value: 'Wadley' },
  { label: 'Wadner', value: 'Wadner' },
  { label: 'Wadowice', value: 'Wadowice' },
  { label: 'Wadowice Górne', value: 'Wadowice Górne' },
  { label: 'Wadsworth', value: 'Wadsworth' },
  { label: 'Wadsworth', value: 'Wadsworth' },
  { label: 'Wadwani', value: 'Wadwani' },
  { label: 'Wadway', value: 'Wadway' },
  { label: 'Wadworth', value: 'Wadworth' },
  { label: 'Waegwan', value: 'Waegwan' },
  { label: 'Waeng', value: 'Waeng' },
  { label: 'Wafangdian', value: 'Wafangdian' },
  { label: 'Waffenbrunn', value: 'Waffenbrunn' },
  { label: 'Wag Hemra Zone', value: 'Wag Hemra Zone' },
  { label: 'Wagait', value: 'Wagait' },
  { label: 'Wagaman', value: 'Wagaman' },
  { label: 'Waganiec', value: 'Waganiec' },
  { label: 'Wagar', value: 'Wagar' },
  { label: 'Wagenberg', value: 'Wagenberg' },
  { label: 'Wagenborgen', value: 'Wagenborgen' },
  { label: 'Wagenfeld', value: 'Wagenfeld' },
  { label: 'Wagenhausen', value: 'Wagenhausen' },
  { label: 'Wagenhoff', value: 'Wagenhoff' },
  { label: 'Wageningen', value: 'Wageningen' },
  { label: 'Wageningen', value: 'Wageningen' },
  { label: 'Wagga Wagga', value: 'Wagga Wagga' },
  { label: 'Waggaman', value: 'Waggaman' },
  { label: 'Waggrakine', value: 'Waggrakine' },
  { label: 'Waghai', value: 'Waghai' },
  { label: 'Waghäusel', value: 'Waghäusel' },
  { label: 'Wagholi', value: 'Wagholi' },
  { label: 'Wagin', value: 'Wagin' },
  { label: 'Waging Am See', value: 'Waging Am See' },
  { label: 'Wagini', value: 'Wagini' },
  { label: 'Wagna', value: 'Wagna' },
  { label: 'Wagner', value: 'Wagner' },
  { label: 'Wagner', value: 'Wagner' },
  { label: 'Wagnergraben', value: 'Wagnergraben' },
  { label: 'Wagnitz', value: 'Wagnitz' },
  { label: 'Wagoner', value: 'Wagoner' },
  { label: 'Wagoner County', value: 'Wagoner County' },
  { label: 'Wagrain', value: 'Wagrain' },
  { label: 'Wagrain', value: 'Wagrain' },
  { label: 'Wagram', value: 'Wagram' },
  { label: 'Wągrowiec', value: 'Wągrowiec' },
  { label: 'Wah', value: 'Wah' },
  { label: 'Wahagnies', value: 'Wahagnies' },
  { label: 'Wahgunyah', value: 'Wahgunyah' },
  { label: 'Wahiawā', value: 'Wahiawā' },
  { label: 'Wahkiakum County', value: 'Wahkiakum County' },
  { label: 'Wahl', value: 'Wahl' },
  { label: 'Wahlen', value: 'Wahlen' },
  { label: 'Wahlitz', value: 'Wahlitz' },
  { label: 'Wahlkreis Rheintal', value: 'Wahlkreis Rheintal' },
  { label: 'Wahlkreis Rorschach', value: 'Wahlkreis Rorschach' },
  { label: 'Wahlkreis Sarganserland', value: 'Wahlkreis Sarganserland' },
  { label: 'Wahlkreis See-Gaster', value: 'Wahlkreis See-Gaster' },
  { label: 'Wahlkreis St. Gallen', value: 'Wahlkreis St. Gallen' },
  { label: 'Wahlkreis Toggenburg', value: 'Wahlkreis Toggenburg' },
  { label: 'Wahlkreis Werdenberg', value: 'Wahlkreis Werdenberg' },
  { label: 'Wahlkreis Wil', value: 'Wahlkreis Wil' },
  { label: 'Wahlstedt', value: 'Wahlstedt' },
  { label: 'Wahneta', value: 'Wahneta' },
  { label: 'Wahn-Heide', value: 'Wahn-Heide' },
  { label: 'Wahoo', value: 'Wahoo' },
  { label: 'Wahpeton', value: 'Wahpeton' },
  { label: 'Wahrenholz', value: 'Wahrenholz' },
  { label: 'Wahroonga', value: 'Wahroonga' },
  { label: 'Wai', value: 'Wai' },
  { label: 'Waialua', value: 'Waialua' },
  { label: 'Waianae', value: 'Waianae' },
  { label: 'Waiblingen', value: 'Waiblingen' },
  { label: 'Waibstadt', value: 'Waibstadt' },
  { label: 'Waidhaus', value: 'Waidhaus' },
  { label: 'Waidhofen', value: 'Waidhofen' },
  { label: 'Waidhofen An Der Thaya', value: 'Waidhofen An Der Thaya' },
  { label: 'Waidhofen An Der Ybbs', value: 'Waidhofen An Der Ybbs' },
  { label: 'Waidhofen An Der Ybbs Stadt', value: 'Waidhofen An Der Ybbs Stadt' },
  { label: 'Waidmannsfeld', value: 'Waidmannsfeld' },
  { label: 'Waidmannslust', value: 'Waidmannslust' },
  { label: 'Waidring', value: 'Waidring' },
  { label: 'Waiern', value: 'Waiern' },
  { label: 'Waigolshausen', value: 'Waigolshausen' },
  { label: 'Waihee-Waiehu', value: 'Waihee-Waiehu' },
  { label: 'Waihi', value: 'Waihi' },
  { label: 'Waihi Beach', value: 'Waihi Beach' },
  { label: 'Waikapū', value: 'Waikapū' },
  { label: 'Waikerie', value: 'Waikerie' },
  { label: 'Waikiki', value: 'Waikiki' },
  { label: 'Waikoloa', value: 'Waikoloa' },
  { label: 'Wailea', value: 'Wailea' },
  { label: 'Wailly', value: 'Wailly' },
  { label: 'Wailua', value: 'Wailua' },
  { label: 'Wailua Homesteads', value: 'Wailua Homesteads' },
  { label: 'Wailuku', value: 'Wailuku' },
  { label: 'Waimakariri District', value: 'Waimakariri District' },
  { label: 'Waimalu', value: 'Waimalu' },
  { label: 'Waimanalo', value: 'Waimanalo' },
  { label: 'Waimānalo Beach', value: 'Waimānalo Beach' },
  { label: 'Waimate North', value: 'Waimate North' },
  { label: 'Waimea', value: 'Waimea' },
  { label: 'Waimes', value: 'Waimes' },
  { label: 'Wain', value: 'Wain' },
  { label: 'Wainaku', value: 'Wainaku' },
  { label: 'Wainfleet All Saints', value: 'Wainfleet All Saints' },
  { label: 'Waingapu', value: 'Waingapu' },
  { label: 'Wainwright', value: 'Wainwright' },
  { label: 'Waiouru', value: 'Waiouru' },
  { label: 'Waipahu', value: 'Waipahu' },
  { label: 'Waipawa', value: 'Waipawa' },
  { label: 'Waipi‘O Acres', value: 'Waipi‘O Acres' },
  { label: 'Waipio', value: 'Waipio' },
  { label: 'Wairoa', value: 'Wairoa' },
  { label: 'Waischenfeld', value: 'Waischenfeld' },
  { label: 'Wait-A-Bit', value: 'Wait-A-Bit' },
  { label: 'Waitakere', value: 'Waitakere' },
  { label: 'Waitangi', value: 'Waitangi' },
  { label: 'Waitara', value: 'Waitara' },
  { label: 'Waitara', value: 'Waitara' },
  { label: 'Waite Park', value: 'Waite Park' },
  { label: 'Waitsburg', value: 'Waitsburg' },
  { label: 'Waiuku', value: 'Waiuku' },
  { label: 'Waizenkirchen', value: 'Waizenkirchen' },
  { label: 'Wajima Shi', value: 'Wajima Shi' },
  { label: 'Wajir', value: 'Wajir' },
  { label: 'Wakad', value: 'Wakad' },
  { label: 'Wakarusa', value: 'Wakarusa' },
  { label: 'Wakatobi Regency', value: 'Wakatobi Regency' },
  { label: 'Wakayama', value: 'Wakayama' },
  { label: 'Wakayama Shi', value: 'Wakayama Shi' },
  { label: 'Wake County', value: 'Wake County' },
  { label: 'Wake Forest', value: 'Wake Forest' },
  { label: 'Wake Village', value: 'Wake Village' },
  { label: 'Wakeeney', value: 'Wakeeney' },
  { label: 'Wakefield', value: 'Wakefield' },
  { label: 'Wakefield', value: 'Wakefield' },
  { label: 'Wakefield', value: 'Wakefield' },
  { label: 'Wakefield', value: 'Wakefield' },
  { label: 'Wakefield', value: 'Wakefield' },
  { label: 'Wakefield', value: 'Wakefield' },
  { label: 'Wakefield', value: 'Wakefield' },
  { label: 'Wakefield', value: 'Wakefield' },
  { label: 'Wakefield', value: 'Wakefield' },
  { label: 'Wakefield', value: 'Wakefield' },
  { label: 'Wakefield-Peacedale', value: 'Wakefield-Peacedale' },
  { label: 'Wakeley', value: 'Wakeley' },
  { label: 'Wakema', value: 'Wakema' },
  { label: 'Wakeman', value: 'Wakeman' },
  { label: 'Wakerley', value: 'Wakerley' },
  { label: 'Wākhān', value: 'Wākhān' },
  { label: 'Wakimachi', value: 'Wakimachi' },
  { label: 'Wakiso', value: 'Wakiso' },
  { label: 'Wakiso District', value: 'Wakiso District' },
  { label: 'Wakkanai', value: 'Wakkanai' },
  { label: 'Wakkanai Shi', value: 'Wakkanai Shi' },
  { label: 'Wako', value: 'Wako' },
  { label: 'Wakō-Shi', value: 'Wakō-Shi' },
  { label: 'Waksmund', value: 'Waksmund' },
  { label: 'Wakulla County', value: 'Wakulla County' },
  { label: 'Wakuya', value: 'Wakuya' },
  { label: 'Walajapet', value: 'Walajapet' },
  { label: 'Walberton', value: 'Walberton' },
  { label: 'Walbourg', value: 'Walbourg' },
  { label: 'Walbridge', value: 'Walbridge' },
  { label: 'Wałbrzych', value: 'Wałbrzych' },
  { label: 'Walce', value: 'Walce' },
  { label: 'Walcha', value: 'Walcha' },
  { label: 'Walchen', value: 'Walchen' },
  { label: 'Walchsee', value: 'Walchsee' },
  { label: 'Walchum', value: 'Walchum' },
  { label: 'Walchwil', value: 'Walchwil' },
  { label: 'Walcott', value: 'Walcott' },
  { label: 'Walcourt', value: 'Walcourt' },
  { label: 'Wałcz', value: 'Wałcz' },
  { label: 'Wald', value: 'Wald' },
  { label: 'Wald', value: 'Wald' },
  { label: 'Wald', value: 'Wald' },
  { label: 'Wald Am Schoberpaß', value: 'Wald Am Schoberpaß' },
  { label: 'Wald Im Pinzgau', value: 'Wald Im Pinzgau' },
  { label: 'Wald Rabi', value: 'Wald Rabi' },
  { label: 'Waldalgesheim', value: 'Waldalgesheim' },
  { label: 'Waldaschaff', value: 'Waldaschaff' },
  { label: 'Waldbillig', value: 'Waldbillig' },
  { label: 'Waldböckelheim', value: 'Waldböckelheim' },
  { label: 'Waldbredimus', value: 'Waldbredimus' },
  { label: 'Waldbreitbach', value: 'Waldbreitbach' },
  { label: 'Waldbröl', value: 'Waldbröl' },
  { label: 'Waldbrunn', value: 'Waldbrunn' },
  { label: 'Waldbrunn', value: 'Waldbrunn' },
  { label: 'Waldburg', value: 'Waldburg' },
  { label: 'Waldbüttelbrunn', value: 'Waldbüttelbrunn' },
  { label: 'Waldeck', value: 'Waldeck' },
  { label: 'Waldegg', value: 'Waldegg' },
  { label: 'Waldems', value: 'Waldems' },
  { label: 'Walden', value: 'Walden' },
  { label: 'Walden', value: 'Walden' },
  { label: 'Walden', value: 'Walden' },
  { label: 'Waldenbuch', value: 'Waldenbuch' },
  { label: 'Waldenburg', value: 'Waldenburg' },
  { label: 'Waldenburg', value: 'Waldenburg' },
  { label: 'Waldenburg', value: 'Waldenburg' },
  { label: 'Waldenstein', value: 'Waldenstein' },
  { label: 'Walderbach', value: 'Walderbach' },
  { label: 'Waldershof', value: 'Waldershof' },
  { label: 'Walderston', value: 'Walderston' },
  { label: 'Waldfeucht', value: 'Waldfeucht' },
  { label: 'Waldfischbach-Burgalben', value: 'Waldfischbach-Burgalben' },
  { label: 'Waldhausen', value: 'Waldhausen' },
  { label: 'Waldheim', value: 'Waldheim' },
  { label: 'Waldighofen', value: 'Waldighofen' },
  { label: 'Walding', value: 'Walding' },
  { label: 'Waldkappel', value: 'Waldkappel' },
  { label: 'Waldkirch', value: 'Waldkirch' },
  { label: 'Waldkirch', value: 'Waldkirch' },
  { label: 'Waldkirchen', value: 'Waldkirchen' },
  { label: 'Waldkirchen', value: 'Waldkirchen' },
  { label: 'Waldkirchen Am Wesen', value: 'Waldkirchen Am Wesen' },
  { label: 'Waldkirchen An Der Thaya', value: 'Waldkirchen An Der Thaya' },
  { label: 'Waldkraiburg', value: 'Waldkraiburg' },
  { label: 'Wald-Michelbach', value: 'Wald-Michelbach' },
  { label: 'Waldmohr', value: 'Waldmohr' },
  { label: 'Waldmünchen', value: 'Waldmünchen' },
  { label: 'Waldneukirchen', value: 'Waldneukirchen' },
  { label: 'Waldo', value: 'Waldo' },
  { label: 'Waldo', value: 'Waldo' },
  { label: 'Waldo County', value: 'Waldo County' },
  { label: 'Waldoboro', value: 'Waldoboro' },
  { label: 'Waldon', value: 'Waldon' },
  { label: 'Waldorf', value: 'Waldorf' },
  { label: 'Waldport', value: 'Waldport' },
  { label: 'Waldprechting', value: 'Waldprechting' },
  { label: 'Waldrach', value: 'Waldrach' },
  { label: 'Waldron', value: 'Waldron' },
  { label: 'Waldsassen', value: 'Waldsassen' },
  { label: 'Waldsee', value: 'Waldsee' },
  { label: 'Waldshut-Tiengen', value: 'Waldshut-Tiengen' },
  { label: 'Waldsieversdorf', value: 'Waldsieversdorf' },
  { label: 'Waldstatt', value: 'Waldstatt' },
  { label: 'Waldstetten', value: 'Waldstetten' },
  { label: 'Waldstetten', value: 'Waldstetten' },
  { label: 'Waldthurn', value: 'Waldthurn' },
  { label: 'Waldwick', value: 'Waldwick' },
  { label: 'Waldzell', value: 'Waldzell' },
  { label: 'Walenstadt', value: 'Walenstadt' },
  { label: 'Wales', value: 'Wales' },
  { label: 'Wales', value: 'Wales' },
  { label: 'Wales', value: 'Wales' },
  { label: 'Walferdange', value: 'Walferdange' },
  { label: 'Walford', value: 'Walford' },
  { label: 'Walgaon', value: 'Walgaon' },
  { label: 'Walgett', value: 'Walgett' },
  { label: 'Walhain-Saint-Paul', value: 'Walhain-Saint-Paul' },
  { label: 'Walhalla', value: 'Walhalla' },
  { label: 'Walheim', value: 'Walheim' },
  { label: 'Walichnowy', value: 'Walichnowy' },
  { label: 'Walim', value: 'Walim' },
  { label: 'Walincourt-Selvigny', value: 'Walincourt-Selvigny' },
  { label: 'Waliso', value: 'Waliso' },
  { label: 'Walkden', value: 'Walkden' },
  { label: 'Walkenried', value: 'Walkenried' },
  { label: 'Walker', value: 'Walker' },
  { label: 'Walker', value: 'Walker' },
  { label: 'Walker', value: 'Walker' },
  { label: 'Walker County', value: 'Walker County' },
  { label: 'Walker County', value: 'Walker County' },
  { label: 'Walker County', value: 'Walker County' },
  { label: 'Walker Mill', value: 'Walker Mill' },
  { label: 'Walkern', value: 'Walkern' },
  { label: 'Walkers Wood', value: 'Walkers Wood' },
  { label: 'Walkerston', value: 'Walkerston' },
  { label: 'Walkersville', value: 'Walkersville' },
  { label: 'Walkerton', value: 'Walkerton' },
  { label: 'Walkertown', value: 'Walkertown' },
  { label: 'Walkertshofen', value: 'Walkertshofen' },
  { label: 'Walkervale', value: 'Walkervale' },
  { label: 'Walkerville', value: 'Walkerville' },
  { label: 'Walki', value: 'Walki' },
  { label: 'Walkington', value: 'Walkington' },
  { label: 'Walkley Heights', value: 'Walkley Heights' },
  { label: 'Walkringen', value: 'Walkringen' },
  { label: 'Wall Ferraz', value: 'Wall Ferraz' },
  { label: 'Walla Walla', value: 'Walla Walla' },
  { label: 'Walla Walla County', value: 'Walla Walla County' },
  { label: 'Walla Walla East', value: 'Walla Walla East' },
  { label: 'Wallace', value: 'Wallace' },
  { label: 'Wallace', value: 'Wallace' },
  { label: 'Wallace', value: 'Wallace' },
  { label: 'Wallace County', value: 'Wallace County' },
  { label: 'Wallacia', value: 'Wallacia' },
  { label: 'Wallajahbad', value: 'Wallajahbad' },
  { label: 'Wallalong', value: 'Wallalong' },
  { label: 'Wallan', value: 'Wallan' },
  { label: 'Wallaroo', value: 'Wallaroo' },
  { label: 'Wallasey', value: 'Wallasey' },
  { label: 'Wallburg', value: 'Wallburg' },
  { label: 'Walldorf', value: 'Walldorf' },
  { label: 'Walldorf', value: 'Walldorf' },
  { label: 'Walldürn', value: 'Walldürn' },
  { label: 'Walled Lake', value: 'Walled Lake' },
  { label: 'Wallendorf', value: 'Wallendorf' },
  { label: 'Wallenfels', value: 'Wallenfels' },
  { label: 'Wallenhorst', value: 'Wallenhorst' },
  { label: 'Wallenpaupack Lake Estates', value: 'Wallenpaupack Lake Estates' },
  { label: 'Waller', value: 'Waller' },
  { label: 'Waller', value: 'Waller' },
  { label: 'Waller County', value: 'Waller County' },
  { label: 'Wallerawang', value: 'Wallerawang' },
  { label: 'Wallerfangen', value: 'Wallerfangen' },
  { label: 'Wallerfing', value: 'Wallerfing' },
  { label: 'Wallern An Der Trattnach', value: 'Wallern An Der Trattnach' },
  { label: 'Wallern Im Burgenland', value: 'Wallern Im Burgenland' },
  { label: 'Wallers', value: 'Wallers' },
  { label: 'Wallersdorf', value: 'Wallersdorf' },
  { label: 'Wallerstein', value: 'Wallerstein' },
  { label: 'Wallertheim', value: 'Wallertheim' },
  { label: 'Wallgau', value: 'Wallgau' },
  { label: 'Wallhausen', value: 'Wallhausen' },
  { label: 'Wallhausen', value: 'Wallhausen' },
  { label: 'Wallhausen', value: 'Wallhausen' },
  { label: 'Wallingford', value: 'Wallingford' },
  { label: 'Wallingford', value: 'Wallingford' },
  { label: 'Wallingford Center', value: 'Wallingford Center' },
  { label: 'Wallington', value: 'Wallington' },
  { label: 'Wallington', value: 'Wallington' },
  { label: 'Wallis', value: 'Wallis' },
  { label: 'Wallisellen', value: 'Wallisellen' },
  { label: 'Wallisellen / Rieden', value: 'Wallisellen / Rieden' },
  { label: 'Wallisellen / Wallisellen-Ost', value: 'Wallisellen / Wallisellen-Ost' },
  { label: 'Wallisellen / Wallisellen-West', value: 'Wallisellen / Wallisellen-West' },
  { label: 'Wallkill', value: 'Wallkill' },
  { label: 'Wallmenroth', value: 'Wallmenroth' },
  { label: 'Wallmerod', value: 'Wallmerod' },
  { label: 'Walloon', value: 'Walloon' },
  { label: 'Wallowa County', value: 'Wallowa County' },
  { label: 'Walls', value: 'Walls' },
  { label: 'Wallsee', value: 'Wallsee' },
  { label: 'Wallsend', value: 'Wallsend' },
  { label: 'Wallsend', value: 'Wallsend' },
  { label: 'Walluf', value: 'Walluf' },
  { label: 'Wallwitz', value: 'Wallwitz' },
  { label: 'Walnut', value: 'Walnut' },
  { label: 'Walnut', value: 'Walnut' },
  { label: 'Walnut Cove', value: 'Walnut Cove' },
  { label: 'Walnut Creek', value: 'Walnut Creek' },
  { label: 'Walnut Grove', value: 'Walnut Grove' },
  { label: 'Walnut Grove', value: 'Walnut Grove' },
  { label: 'Walnut Grove', value: 'Walnut Grove' },
  { label: 'Walnut Grove', value: 'Walnut Grove' },
  { label: 'Walnut Grove', value: 'Walnut Grove' },
  { label: 'Walnut Hill', value: 'Walnut Hill' },
  { label: 'Walnut Park', value: 'Walnut Park' },
  { label: 'Walnut Ridge', value: 'Walnut Ridge' },
  { label: 'Walnutport', value: 'Walnutport' },
  { label: 'Walpersbach', value: 'Walpersbach' },
  { label: 'Walpertskirchen', value: 'Walpertskirchen' },
  { label: 'Walpole', value: 'Walpole' },
  { label: 'Walpole Island', value: 'Walpole Island' },
  { label: 'Wals', value: 'Wals' },
  { label: 'Walsall', value: 'Walsall' },
  { label: 'Walscheid', value: 'Walscheid' },
  { label: 'Walschleben', value: 'Walschleben' },
  { label: 'Walsden', value: 'Walsden' },
  { label: 'Walsdorf', value: 'Walsdorf' },
  { label: 'Walsenburg', value: 'Walsenburg' },
  { label: 'Walserfeld', value: 'Walserfeld' },
  { label: 'Walsh County', value: 'Walsh County' },
  { label: 'Walsrode', value: 'Walsrode' },
  { label: 'Waltendorf', value: 'Waltendorf' },
  { label: 'Waltenhofen', value: 'Waltenhofen' },
  { label: 'Waltenschwil', value: 'Waltenschwil' },
  { label: 'Walterboro', value: 'Walterboro' },
  { label: 'Walterganj', value: 'Walterganj' },
  { label: 'Walters', value: 'Walters' },
  { label: 'Waltershausen', value: 'Waltershausen' },
  { label: 'Walthall', value: 'Walthall' },
  { label: 'Walthall County', value: 'Walthall County' },
  { label: 'Waltham', value: 'Waltham' },
  { label: 'Waltham', value: 'Waltham' },
  { label: 'Waltham Abbey', value: 'Waltham Abbey' },
  { label: 'Waltham Cross', value: 'Waltham Cross' },
  { label: 'Waltham Gardens', value: 'Waltham Gardens' },
  { label: 'Walthourville', value: 'Walthourville' },
  { label: 'Walton', value: 'Walton' },
  { label: 'Walton', value: 'Walton' },
  { label: 'Walton', value: 'Walton' },
  { label: 'Walton', value: 'Walton' },
  { label: 'Walton County', value: 'Walton County' },
  { label: 'Walton County', value: 'Walton County' },
  { label: 'Walton Hills', value: 'Walton Hills' },
  { label: 'Walton Park', value: 'Walton Park' },
  { label: 'Walton-On-Thames', value: 'Walton-On-Thames' },
  { label: 'Walton-On-The-Naze', value: 'Walton-On-The-Naze' },
  { label: 'Waltrop', value: 'Waltrop' },
  { label: 'Walvis Bay', value: 'Walvis Bay' },
  { label: 'Walworth', value: 'Walworth' },
  { label: 'Walworth County', value: 'Walworth County' },
  { label: 'Walworth County', value: 'Walworth County' },
  { label: 'Walzenhausen', value: 'Walzenhausen' },
  { label: 'Wamac', value: 'Wamac' },
  { label: 'Wamba', value: 'Wamba' },
  { label: 'Wamba', value: 'Wamba' },
  { label: 'Wamba', value: 'Wamba' },
  { label: 'Wamberal', value: 'Wamberal' },
  { label: 'Wamboin', value: 'Wamboin' },
  { label: 'Wambrechies', value: 'Wambrechies' },
  { label: 'Wamego', value: 'Wamego' },
  { label: 'Wampsville', value: 'Wampsville' },
  { label: 'Wampusirpi', value: 'Wampusirpi' },
  { label: 'Wamrong', value: 'Wamrong' },
  { label: 'Wamuran', value: 'Wamuran' },
  { label: 'Wan Chai', value: 'Wan Chai' },
  { label: 'Wana', value: 'Wana' },
  { label: 'Wanaka', value: 'Wanaka' },
  { label: 'Wanakah', value: 'Wanakah' },
  { label: 'Wanamassa', value: 'Wanamassa' },
  { label: 'Wanament', value: 'Wanament' },
  { label: 'Wanamingo', value: 'Wanamingo' },
  { label: 'Wanaque', value: 'Wanaque' },
  { label: 'Wanaraja', value: 'Wanaraja' },
  { label: 'Wanatah', value: 'Wanatah' },
  { label: 'Wanborough', value: 'Wanborough' },
  { label: 'Wanchese', value: 'Wanchese' },
  { label: 'Wanda', value: 'Wanda' },
  { label: 'Wandal', value: 'Wandal' },
  { label: 'Wandana Heights', value: 'Wandana Heights' },
  { label: 'Wandering', value: 'Wandering' },
  { label: 'Wanderlândia', value: 'Wanderlândia' },
  { label: 'Wanderley', value: 'Wanderley' },
  { label: 'Wandersleben', value: 'Wandersleben' },
  { label: 'Wanderup', value: 'Wanderup' },
  { label: 'Wandi', value: 'Wandi' },
  { label: 'Wandignies-Hamage', value: 'Wandignies-Hamage' },
  { label: 'Wandin North', value: 'Wandin North' },
  { label: 'Wandina', value: 'Wandina' },
  { label: 'Wandlitz', value: 'Wandlitz' },
  { label: 'Wandoan', value: 'Wandoan' },
  { label: 'Wando-Gun', value: 'Wando-Gun' },
  { label: 'Wandong', value: 'Wandong' },
  { label: 'Wandsbek', value: 'Wandsbek' },
  { label: 'Wanfried', value: 'Wanfried' },
  { label: 'Wang', value: 'Wang' },
  { label: 'Wang', value: 'Wang' },
  { label: 'Wang Nam Yen', value: 'Wang Nam Yen' },
  { label: 'Wang Noi', value: 'Wang Noi' },
  { label: 'Wang Nuea', value: 'Wang Nuea' },
  { label: 'Wang Sai Phun', value: 'Wang Sai Phun' },
  { label: 'Wang Saphung', value: 'Wang Saphung' },
  { label: 'Wang Thong Lang', value: 'Wang Thong Lang' },
  { label: 'Wañgan', value: 'Wañgan' },
  { label: 'Wañgan', value: 'Wañgan' },
  { label: 'Wañgan', value: 'Wañgan' },
  { label: 'Wanganui', value: 'Wanganui' },
  { label: 'Wangaratta', value: 'Wangaratta' },
  { label: 'Wangdue Phodrang', value: 'Wangdue Phodrang' },
  { label: 'Wangels', value: 'Wangels' },
  { label: 'Wangen', value: 'Wangen' },
  { label: 'Wangen', value: 'Wangen' },
  { label: 'Wangen', value: 'Wangen' },
  { label: 'Wangen An Der Aare', value: 'Wangen An Der Aare' },
  { label: 'Wangenbourg-Engenthal', value: 'Wangenbourg-Engenthal' },
  { label: 'Wangerooge', value: 'Wangerooge' },
  { label: 'Wängi', value: 'Wängi' },
  { label: 'Wangi Wangi', value: 'Wangi Wangi' },
  { label: 'Wangkui', value: 'Wangkui' },
  { label: 'Wängle', value: 'Wängle' },
  { label: 'Wangon', value: 'Wangon' },
  { label: 'Wangqing', value: 'Wangqing' },
  { label: 'Wangqinzhuang', value: 'Wangqinzhuang' },
  { label: 'Wanguri', value: 'Wanguri' },
  { label: 'Wani', value: 'Wani' },
  { label: 'Wanju', value: 'Wanju' },
  { label: 'Wankaner', value: 'Wankaner' },
  { label: 'Wankendorf', value: 'Wankendorf' },
  { label: 'Wanlaweyn', value: 'Wanlaweyn' },
  { label: 'Wanna', value: 'Wanna' },
  { label: 'Wannanup', value: 'Wannanup' },
  { label: 'Wanneroo', value: 'Wanneroo' },
  { label: 'Wanniassa', value: 'Wanniassa' },
  { label: 'Wanning', value: 'Wanning' },
  { label: 'Wannsee', value: 'Wannsee' },
  { label: 'Wannweil', value: 'Wannweil' },
  { label: 'Wanparti', value: 'Wanparti' },
  { label: 'Wanroij', value: 'Wanroij' },
  { label: 'Wansford', value: 'Wansford' },
  { label: 'Wansleben', value: 'Wansleben' },
  { label: 'Wanssum', value: 'Wanssum' },
  { label: 'Wantage', value: 'Wantage' },
  { label: 'Wantagh', value: 'Wantagh' },
  { label: 'Wantirna', value: 'Wantirna' },
  { label: 'Wantirna South', value: 'Wantirna South' },
  { label: 'Wantouqiao', value: 'Wantouqiao' },
  { label: 'Wanxian', value: 'Wanxian' },
  { label: 'Wanze', value: 'Wanze' },
  { label: 'Wanzhou District', value: 'Wanzhou District' },
  { label: 'Wanzleben', value: 'Wanzleben' },
  { label: 'Wao', value: 'Wao' },
  { label: 'Wao', value: 'Wao' },
  { label: 'Waoundé', value: 'Waoundé' },
  { label: 'Wapakoneta', value: 'Wapakoneta' },
  { label: 'Wapato', value: 'Wapato' },
  { label: 'Wapello', value: 'Wapello' },
  { label: 'Wapello County', value: 'Wapello County' },
  { label: 'Wapenamanda', value: 'Wapenamanda' },
  { label: 'Wapenveld', value: 'Wapenveld' },
  { label: 'Wapi Pathum', value: 'Wapi Pathum' },
  { label: 'Wąpielsk', value: 'Wąpielsk' },
  { label: 'Wapno', value: 'Wapno' },
  { label: 'Wappingers Falls', value: 'Wappingers Falls' },
  { label: 'Waqqāş', value: 'Waqqāş' },
  { label: 'Warabi-Shi', value: 'Warabi-Shi' },
  { label: 'Warabrook', value: 'Warabrook' },
  { label: 'Warah', value: 'Warah' },
  { label: 'Waramanga', value: 'Waramanga' },
  { label: 'Warana', value: 'Warana' },
  { label: 'Warang', value: 'Warang' },
  { label: 'Warangal', value: 'Warangal' },
  { label: 'Waraseoni', value: 'Waraseoni' },
  { label: 'Waratah', value: 'Waratah' },
  { label: 'Waratah West', value: 'Waratah West' },
  { label: 'Waratah/Wynyard', value: 'Waratah/Wynyard' },
  { label: 'Warboys', value: 'Warboys' },
  { label: 'Warburg', value: 'Warburg' },
  { label: 'Warburton', value: 'Warburton' },
  { label: 'Warburton', value: 'Warburton' },
  { label: 'Warcq', value: 'Warcq' },
  { label: 'Ward', value: 'Ward' },
  { label: 'Ward County', value: 'Ward County' },
  { label: 'Ward County', value: 'Ward County' },
  { label: 'Ward Of Chaguanas', value: 'Ward Of Chaguanas' },
  { label: 'Ward Of Diego Martin', value: 'Ward Of Diego Martin' },
  { label: 'Ward Of Siparia', value: 'Ward Of Siparia' },
  { label: 'Warden', value: 'Warden' },
  { label: 'Wardenburg', value: 'Wardenburg' },
  { label: 'Warder', value: 'Warder' },
  { label: 'Wardha', value: 'Wardha' },
  { label: 'Wardija', value: 'Wardija' },
  { label: 'Wardle', value: 'Wardle' },
  { label: 'Wardow', value: 'Wardow' },
  { label: 'Wardrecques', value: 'Wardrecques' },
  { label: 'Wardsville', value: 'Wardsville' },
  { label: 'Ware', value: 'Ware' },
  { label: 'Ware', value: 'Ware' },
  { label: 'Ware County', value: 'Ware County' },
  { label: 'Ware Shoals', value: 'Ware Shoals' },
  { label: 'Wareemba', value: 'Wareemba' },
  { label: 'Waregem', value: 'Waregem' },
  { label: 'Wareham', value: 'Wareham' },
  { label: 'Wareham Center', value: 'Wareham Center' },
  { label: 'Waremme', value: 'Waremme' },
  { label: 'Waren', value: 'Waren' },
  { label: 'Warendorf', value: 'Warendorf' },
  { label: 'Waretown', value: 'Waretown' },
  { label: 'Warffum', value: 'Warffum' },
  { label: 'Warga', value: 'Warga' },
  { label: 'Wargnies-Le-Grand', value: 'Wargnies-Le-Grand' },
  { label: 'Wargrave', value: 'Wargrave' },
  { label: 'Warhem', value: 'Warhem' },
  { label: 'Warialda', value: 'Warialda' },
  { label: 'Warilla', value: 'Warilla' },
  { label: 'Warin', value: 'Warin' },
  { label: 'Warin Chamrap', value: 'Warin Chamrap' },
  { label: 'Waringstown', value: 'Waringstown' },
  { label: 'Waris Aliganj', value: 'Waris Aliganj' },
  { label: 'Warka', value: 'Warka' },
  { label: 'Warken', value: 'Warken' },
  { label: 'Warkworth', value: 'Warkworth' },
  { label: 'Warkworth', value: 'Warkworth' },
  { label: 'Warlingham', value: 'Warlingham' },
  { label: 'Warlubie', value: 'Warlubie' },
  { label: 'Warluis', value: 'Warluis' },
  { label: 'Warm Beach', value: 'Warm Beach' },
  { label: 'Warm Mineral Springs', value: 'Warm Mineral Springs' },
  { label: 'Warm Springs', value: 'Warm Springs' },
  { label: 'Warm Springs', value: 'Warm Springs' },
  { label: 'Warm Springs', value: 'Warm Springs' },
  { label: 'Warman', value: 'Warman' },
  { label: 'Warmbad', value: 'Warmbad' },
  { label: 'Warmbad-Judendorf', value: 'Warmbad-Judendorf' },
  { label: 'Warmbaths', value: 'Warmbaths' },
  { label: 'Warmenhuizen', value: 'Warmenhuizen' },
  { label: 'Warmensteinach', value: 'Warmensteinach' },
  { label: 'Warmeriville', value: 'Warmeriville' },
  { label: 'Warminister', value: 'Warminister' },
  { label: 'Warminster', value: 'Warminster' },
  { label: 'Warminster Heights', value: 'Warminster Heights' },
  { label: 'Warmond', value: 'Warmond' },
  { label: 'Warmsen', value: 'Warmsen' },
  { label: 'Warnbro', value: 'Warnbro' },
  { label: 'Warnemünde', value: 'Warnemünde' },
  { label: 'Warner', value: 'Warner' },
  { label: 'Warner', value: 'Warner' },
  { label: 'Warner Robins', value: 'Warner Robins' },
  { label: 'Warners Bay', value: 'Warners Bay' },
  { label: 'Warnes', value: 'Warnes' },
  { label: 'Warngau', value: 'Warngau' },
  { label: 'Warnham', value: 'Warnham' },
  { label: 'Warnice', value: 'Warnice' },
  { label: 'Warnow', value: 'Warnow' },
  { label: 'Warnsveld', value: 'Warnsveld' },
  { label: 'Waroona', value: 'Waroona' },
  { label: 'Warora', value: 'Warora' },
  { label: 'Warr Acres', value: 'Warr Acres' },
  { label: 'Warracknabeal', value: 'Warracknabeal' },
  { label: 'Warradale', value: 'Warradale' },
  { label: 'Warragamba', value: 'Warragamba' },
  { label: 'Warragul', value: 'Warragul' },
  { label: 'Warrandyte', value: 'Warrandyte' },
  { label: 'Warrane', value: 'Warrane' },
  { label: 'Warranwood', value: 'Warranwood' },
  { label: 'Warrawee', value: 'Warrawee' },
  { label: 'Warrawong', value: 'Warrawong' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren', value: 'Warren' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren County', value: 'Warren County' },
  { label: 'Warren Park', value: 'Warren Park' },
  { label: 'Warren Shire', value: 'Warren Shire' },
  { label: 'Warren Township', value: 'Warren Township' },
  { label: 'Warrenpoint', value: 'Warrenpoint' },
  { label: 'Warrensburg', value: 'Warrensburg' },
  { label: 'Warrensburg', value: 'Warrensburg' },
  { label: 'Warrensburg', value: 'Warrensburg' },
  { label: 'Warrensville Heights', value: 'Warrensville Heights' },
  { label: 'Warrenton', value: 'Warrenton' },
  { label: 'Warrenton', value: 'Warrenton' },
  { label: 'Warrenton', value: 'Warrenton' },
  { label: 'Warrenton', value: 'Warrenton' },
  { label: 'Warrenton', value: 'Warrenton' },
  { label: 'Warrenton', value: 'Warrenton' },
  { label: 'Warrenville', value: 'Warrenville' },
  { label: 'Warrenville', value: 'Warrenville' },
  { label: 'Warri', value: 'Warri' },
  { label: 'Warrick County', value: 'Warrick County' },
  { label: 'Warriewood', value: 'Warriewood' },
  { label: 'Warrimoo', value: 'Warrimoo' },
  { label: 'Warrington', value: 'Warrington' },
  { label: 'Warrington', value: 'Warrington' },
  { label: 'Warrior', value: 'Warrior' },
  { label: 'Warrnambool', value: 'Warrnambool' },
  { label: 'Warroad', value: 'Warroad' },
  { label: 'Warrumbungle Shire', value: 'Warrumbungle Shire' },
  { label: 'Warsaw', value: 'Warsaw' },
  { label: 'Warsaw', value: 'Warsaw' },
  { label: 'Warsaw', value: 'Warsaw' },
  { label: 'Warsaw', value: 'Warsaw' },
  { label: 'Warsaw', value: 'Warsaw' },
  { label: 'Warsaw', value: 'Warsaw' },
  { label: 'Warsaw', value: 'Warsaw' },
  { label: 'Warsaw', value: 'Warsaw' },
  { label: 'Warson Woods', value: 'Warson Woods' },
  { label: 'Warsop', value: 'Warsop' },
  { label: 'Warsop', value: 'Warsop' },
  { label: 'Warstein', value: 'Warstein' },
  { label: 'Warszawa', value: 'Warszawa' },
  { label: 'Warta', value: 'Warta' },
  { label: 'Wartberg', value: 'Wartberg' },
  { label: 'Wartberg An Der Krems', value: 'Wartberg An Der Krems' },
  { label: 'Wartberg Ob Der Aist', value: 'Wartberg Ob Der Aist' },
  { label: 'Wartburg', value: 'Wartburg' },
  { label: 'Wartenberg', value: 'Wartenberg' },
  { label: 'Wartenberg', value: 'Wartenberg' },
  { label: 'Warth', value: 'Warth' },
  { label: 'Warth', value: 'Warth' },
  { label: 'Warthausen', value: 'Warthausen' },
  { label: 'Wartkowice', value: 'Wartkowice' },
  { label: 'Wartmannsroth', value: 'Wartmannsroth' },
  { label: 'Wartmannstetten', value: 'Wartmannstetten' },
  { label: 'Warton', value: 'Warton' },
  { label: 'Warud', value: 'Warud' },
  { label: 'Warwick', value: 'Warwick' },
  { label: 'Warwick', value: 'Warwick' },
  { label: 'Warwick', value: 'Warwick' },
  { label: 'Warwick', value: 'Warwick' },
  { label: 'Warwick', value: 'Warwick' },
  { label: 'Warwick', value: 'Warwick' },
  { label: 'Warwick', value: 'Warwick' },
  { label: 'Warwick Farm', value: 'Warwick Farm' },
  { label: 'Warwick/Daban', value: 'Warwick/Daban' },
  { label: 'Warwickshire', value: 'Warwickshire' },
  { label: 'Wasaga Beach', value: 'Wasaga Beach' },
  { label: 'Wasagu', value: 'Wasagu' },
  { label: 'Wasatch County', value: 'Wasatch County' },
  { label: 'Wasbek', value: 'Wasbek' },
  { label: 'Wasbüttel', value: 'Wasbüttel' },
  { label: 'Wäschenbeuren', value: 'Wäschenbeuren' },
  { label: 'Wasco', value: 'Wasco' },
  { label: 'Wasco', value: 'Wasco' },
  { label: 'Wasco County', value: 'Wasco County' },
  { label: 'Waseca', value: 'Waseca' },
  { label: 'Waseca County', value: 'Waseca County' },
  { label: 'Washakie County', value: 'Washakie County' },
  { label: 'Washburn', value: 'Washburn' },
  { label: 'Washburn', value: 'Washburn' },
  { label: 'Washburn', value: 'Washburn' },
  { label: 'Washburn County', value: 'Washburn County' },
  { label: 'Washḩah', value: 'Washḩah' },
  { label: 'Washim', value: 'Washim' },
  { label: 'Washingborough', value: 'Washingborough' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington County', value: 'Washington County' },
  { label: 'Washington Court House', value: 'Washington Court House' },
  { label: 'Washington D.C.', value: 'Washington D.C.' },
  { label: 'Washington Gardens', value: 'Washington Gardens' },
  { label: 'Washington Heights', value: 'Washington Heights' },
  { label: 'Washington Mills', value: 'Washington Mills' },
  { label: 'Washington Parish', value: 'Washington Parish' },
  { label: 'Washington Park', value: 'Washington Park' },
  { label: 'Washington Park', value: 'Washington Park' },
  { label: 'Washington Terrace', value: 'Washington Terrace' },
  { label: 'Washingtonville', value: 'Washingtonville' },
  { label: 'Washita County', value: 'Washita County' },
  { label: 'Washoe County', value: 'Washoe County' },
  { label: 'Washougal', value: 'Washougal' },
  { label: 'Washtenaw County', value: 'Washtenaw County' },
  { label: 'Washwood Heath', value: 'Washwood Heath' },
  { label: 'Wasilków', value: 'Wasilków' },
  { label: 'Wasilla', value: 'Wasilla' },
  { label: 'Wasitah', value: 'Wasitah' },
  { label: 'Waskaganish', value: 'Waskaganish' },
  { label: 'Waskom', value: 'Waskom' },
  { label: 'Waslala', value: 'Waslala' },
  { label: 'Waśniów', value: 'Waśniów' },
  { label: 'Wąsosz', value: 'Wąsosz' },
  { label: 'Wąsosz', value: 'Wąsosz' },
  { label: 'Waspán', value: 'Waspán' },
  { label: 'Waspik', value: 'Waspik' },
  { label: 'Wasquehal', value: 'Wasquehal' },
  { label: 'Wasseiges', value: 'Wasseiges' },
  { label: 'Wasselonne', value: 'Wasselonne' },
  { label: 'Wassenaar', value: 'Wassenaar' },
  { label: 'Wassenach', value: 'Wassenach' },
  { label: 'Wassenberg', value: 'Wassenberg' },
  { label: 'Wasserbillig', value: 'Wasserbillig' },
  { label: 'Wasserburg', value: 'Wasserburg' },
  { label: 'Wasserburg Am Inn', value: 'Wasserburg Am Inn' },
  { label: 'Wasserleben', value: 'Wasserleben' },
  { label: 'Wasserliesch', value: 'Wasserliesch' },
  { label: 'Wasserlosen', value: 'Wasserlosen' },
  { label: 'Wassertrüdingen', value: 'Wassertrüdingen' },
  { label: 'Wassigny', value: 'Wassigny' },
  { label: 'Wassu', value: 'Wassu' },
  { label: 'Wassy', value: 'Wassy' },
  { label: 'Wasungen', value: 'Wasungen' },
  { label: 'Waswanipi', value: 'Waswanipi' },
  { label: 'Watadeniya', value: 'Watadeniya' },
  { label: 'Watampone', value: 'Watampone' },
  { label: 'Watanobbi', value: 'Watanobbi' },
  { label: 'Watari', value: 'Watari' },
  { label: 'Watari-Gun', value: 'Watari-Gun' },
  { label: 'Watauga', value: 'Watauga' },
  { label: 'Watauga County', value: 'Watauga County' },
  { label: 'Watchet', value: 'Watchet' },
  { label: 'Watchtower', value: 'Watchtower' },
  { label: 'Watchung', value: 'Watchung' },
  { label: 'Watchwell', value: 'Watchwell' },
  { label: 'Water Eaton', value: 'Water Eaton' },
  { label: 'Water Island', value: 'Water Island' },
  { label: 'Water Lane', value: 'Water Lane' },
  { label: 'Water Mill', value: 'Water Mill' },
  { label: 'Water Orton', value: 'Water Orton' },
  { label: 'Water Valley', value: 'Water Valley' },
  { label: 'Water Works', value: 'Water Works' },
  { label: 'Waterakkers', value: 'Waterakkers' },
  { label: 'Waterbeach', value: 'Waterbeach' },
  { label: 'Waterberg District Municipality', value: 'Waterberg District Municipality' },
  { label: 'Waterboro', value: 'Waterboro' },
  { label: 'Waterbury', value: 'Waterbury' },
  { label: 'Waterbury', value: 'Waterbury' },
  { label: 'Waterdonken', value: 'Waterdonken' },
  { label: 'Waterflow', value: 'Waterflow' },
  { label: 'Waterfoot', value: 'Waterfoot' },
  { label: 'Waterford', value: 'Waterford' },
  { label: 'Waterford', value: 'Waterford' },
  { label: 'Waterford', value: 'Waterford' },
  { label: 'Waterford', value: 'Waterford' },
  { label: 'Waterford', value: 'Waterford' },
  { label: 'Waterford', value: 'Waterford' },
  { label: 'Waterford', value: 'Waterford' },
  { label: 'Waterford', value: 'Waterford' },
  { label: 'Waterford', value: 'Waterford' },
  { label: 'Waterford', value: 'Waterford' },
  { label: 'Waterford West', value: 'Waterford West' },
  { label: 'Watergang', value: 'Watergang' },
  { label: 'Watergraafsmeer', value: 'Watergraafsmeer' },
  { label: 'Watergrasshill', value: 'Watergrasshill' },
  { label: 'Waterhouse', value: 'Waterhouse' },
  { label: 'Wateringbury', value: 'Wateringbury' },
  { label: 'Wateringskant', value: 'Wateringskant' },
  { label: 'Waterland', value: 'Waterland' },
  { label: 'Waterloo', value: 'Waterloo' },
  { label: 'Waterloo', value: 'Waterloo' },
  { label: 'Waterloo', value: 'Waterloo' },
  { label: 'Waterloo', value: 'Waterloo' },
  { label: 'Waterloo', value: 'Waterloo' },
  { label: 'Waterloo', value: 'Waterloo' },
  { label: 'Waterloo', value: 'Waterloo' },
  { label: 'Waterloo', value: 'Waterloo' },
  { label: 'Waterloo', value: 'Waterloo' },
  { label: 'Waterloo', value: 'Waterloo' },
  { label: 'Waterloo', value: 'Waterloo' },
  { label: 'Waterloo Corner', value: 'Waterloo Corner' },
  { label: 'Waterlooville', value: 'Waterlooville' },
  { label: 'Waterman', value: 'Waterman' },
  { label: 'Watermans Bay', value: 'Watermans Bay' },
  { label: 'Watermount', value: 'Watermount' },
  { label: 'Watertown', value: 'Watertown' },
  { label: 'Watertown', value: 'Watertown' },
  { label: 'Watertown', value: 'Watertown' },
  { label: 'Watertown', value: 'Watertown' },
  { label: 'Watertown', value: 'Watertown' },
  { label: 'Watertown', value: 'Watertown' },
  { label: 'Watertown', value: 'Watertown' },
  { label: 'Watertown', value: 'Watertown' },
  { label: 'Waterview Heights', value: 'Waterview Heights' },
  { label: 'Waterville', value: 'Waterville' },
  { label: 'Waterville', value: 'Waterville' },
  { label: 'Waterville', value: 'Waterville' },
  { label: 'Waterville', value: 'Waterville' },
  { label: 'Waterville', value: 'Waterville' },
  { label: 'Watervliet', value: 'Watervliet' },
  { label: 'Watervliet', value: 'Watervliet' },
  { label: 'Waterways', value: 'Waterways' },
  { label: 'Waterworks', value: 'Waterworks' },
  { label: 'Watford', value: 'Watford' },
  { label: 'Watford', value: 'Watford' },
  { label: 'Watford City', value: 'Watford City' },
  { label: 'Wath Upon Dearne', value: 'Wath Upon Dearne' },
  { label: 'Watham', value: 'Watham' },
  { label: 'Wathena', value: 'Wathena' },
  { label: 'Wathlingen', value: 'Wathlingen' },
  { label: 'Watkins Glen', value: 'Watkins Glen' },
  { label: 'Watkinsville', value: 'Watkinsville' },
  { label: 'Watlington', value: 'Watlington' },
  { label: 'Watonga', value: 'Watonga' },
  { label: 'Watonwan County', value: 'Watonwan County' },
  { label: 'Watrous', value: 'Watrous' },
  { label: 'Watsa', value: 'Watsa' },
  { label: 'Watseka', value: 'Watseka' },
  { label: 'Watson', value: 'Watson' },
  { label: 'Watson', value: 'Watson' },
  { label: 'Watson Lake', value: 'Watson Lake' },
  { label: 'Watsonia', value: 'Watsonia' },
  { label: 'Watsonia North', value: 'Watsonia North' },
  { label: 'Watsontown', value: 'Watsontown' },
  { label: 'Watsonville', value: 'Watsonville' },
  { label: 'Watt Town', value: 'Watt Town' },
  { label: 'Wattala', value: 'Wattala' },
  { label: 'Wattegama', value: 'Wattegama' },
  { label: 'Watten', value: 'Watten' },
  { label: 'Wattenbek', value: 'Wattenbek' },
  { label: 'Wattenberg', value: 'Wattenberg' },
  { label: 'Wattenheim', value: 'Wattenheim' },
  { label: 'Wattens', value: 'Wattens' },
  { label: 'Wattenwil', value: 'Wattenwil' },
  { label: 'Watterson Park', value: 'Watterson Park' },
  { label: 'Wattignies', value: 'Wattignies' },
  { label: 'Wattisham', value: 'Wattisham' },
  { label: 'Wattle Grove', value: 'Wattle Grove' },
  { label: 'Wattle Grove', value: 'Wattle Grove' },
  { label: 'Wattle Park', value: 'Wattle Park' },
  { label: 'Wattle Ponds', value: 'Wattle Ponds' },
  { label: 'Wattle Range', value: 'Wattle Range' },
  { label: 'Wattleglen', value: 'Wattleglen' },
  { label: 'Wattmannshagen', value: 'Wattmannshagen' },
  { label: 'Watton', value: 'Watton' },
  { label: 'Watton At Stone', value: 'Watton At Stone' },
  { label: 'Wattrelos', value: 'Wattrelos' },
  { label: 'Watts Mills', value: 'Watts Mills' },
  { label: 'Wattsville', value: 'Wattsville' },
  { label: 'Wattwil', value: 'Wattwil' },
  { label: 'Wattwiller', value: 'Wattwiller' },
  { label: 'Wau', value: 'Wau' },
  { label: 'Waubach', value: 'Waubach' },
  { label: 'Wauchope', value: 'Wauchope' },
  { label: 'Wauchula', value: 'Wauchula' },
  { label: 'Wauconda', value: 'Wauconda' },
  { label: 'Waukee', value: 'Waukee' },
  { label: 'Waukegan', value: 'Waukegan' },
  { label: 'Waukesha', value: 'Waukesha' },
  { label: 'Waukesha County', value: 'Waukesha County' },
  { label: 'Waukomis', value: 'Waukomis' },
  { label: 'Waukon', value: 'Waukon' },
  { label: 'Wauna', value: 'Wauna' },
  { label: 'Waunakee', value: 'Waunakee' },
  { label: 'Waupaca', value: 'Waupaca' },
  { label: 'Waupaca County', value: 'Waupaca County' },
  { label: 'Waupun', value: 'Waupun' },
  { label: 'Wauregan', value: 'Wauregan' },
  { label: 'Waurika', value: 'Waurika' },
  { label: 'Waurn Ponds', value: 'Waurn Ponds' },
  { label: 'Wausau', value: 'Wausau' },
  { label: 'Wauseon', value: 'Wauseon' },
  { label: 'Waushara County', value: 'Waushara County' },
  { label: 'Wautoma', value: 'Wautoma' },
  { label: 'Wauwatosa', value: 'Wauwatosa' },
  { label: 'Wauwil', value: 'Wauwil' },
  { label: 'Waveland', value: 'Waveland' },
  { label: 'Wavell Heights', value: 'Wavell Heights' },
  { label: 'Waver', value: 'Waver' },
  { label: 'Waverley', value: 'Waverley' },
  { label: 'Waverley', value: 'Waverley' },
  { label: 'Waverly', value: 'Waverly' },
  { label: 'Waverly', value: 'Waverly' },
  { label: 'Waverly', value: 'Waverly' },
  { label: 'Waverly', value: 'Waverly' },
  { label: 'Waverly', value: 'Waverly' },
  { label: 'Waverly', value: 'Waverly' },
  { label: 'Waverly', value: 'Waverly' },
  { label: 'Waverly', value: 'Waverly' },
  { label: 'Waverly', value: 'Waverly' },
  { label: 'Waverton', value: 'Waverton' },
  { label: 'Wavignies', value: 'Wavignies' },
  { label: 'Wavre', value: 'Wavre' },
  { label: 'Wavrechain-Sous-Denain', value: 'Wavrechain-Sous-Denain' },
  { label: 'Wavrin', value: 'Wavrin' },
  { label: 'Wawa', value: 'Wawa' },
  { label: 'Wawa', value: 'Wawa' },
  { label: 'Wawa', value: 'Wawa' },
  { label: 'Wawa', value: 'Wawa' },
  { label: 'Wawarsing', value: 'Wawarsing' },
  { label: 'Wawer', value: 'Wawer' },
  { label: 'Wawina', value: 'Wawina' },
  { label: 'Wąwolnica', value: 'Wąwolnica' },
  { label: 'Wawrzeńczyce', value: 'Wawrzeńczyce' },
  { label: 'Waxahachie', value: 'Waxahachie' },
  { label: 'Waxhaw', value: 'Waxhaw' },
  { label: 'Waxweiler', value: 'Waxweiler' },
  { label: 'Wayanad', value: 'Wayanad' },
  { label: 'Waycross', value: 'Waycross' },
  { label: 'Wayland', value: 'Wayland' },
  { label: 'Wayland', value: 'Wayland' },
  { label: 'Wayland', value: 'Wayland' },
  { label: 'Waymart', value: 'Waymart' },
  { label: 'Wayne', value: 'Wayne' },
  { label: 'Wayne', value: 'Wayne' },
  { label: 'Wayne', value: 'Wayne' },
  { label: 'Wayne', value: 'Wayne' },
  { label: 'Wayne', value: 'Wayne' },
  { label: 'Wayne', value: 'Wayne' },
  { label: 'Wayne', value: 'Wayne' },
  { label: 'Wayne City', value: 'Wayne City' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne County', value: 'Wayne County' },
  { label: 'Wayne Heights', value: 'Wayne Heights' },
  { label: 'Waynesboro', value: 'Waynesboro' },
  { label: 'Waynesboro', value: 'Waynesboro' },
  { label: 'Waynesboro', value: 'Waynesboro' },
  { label: 'Waynesboro', value: 'Waynesboro' },
  { label: 'Waynesboro', value: 'Waynesboro' },
  { label: 'Waynesburg', value: 'Waynesburg' },
  { label: 'Waynesville', value: 'Waynesville' },
  { label: 'Waynesville', value: 'Waynesville' },
  { label: 'Waynesville', value: 'Waynesville' },
  { label: 'Wayville', value: 'Wayville' },
  { label: 'Wayzata', value: 'Wayzata' },
  { label: 'Waziers', value: 'Waziers' },
  { label: 'Wazirabad', value: 'Wazirabad' },
  { label: 'Wazireya', value: 'Wazireya' },
  { label: 'Wazirganj', value: 'Wazirganj' },
  { label: 'Weakley County', value: 'Weakley County' },
  { label: 'Weare', value: 'Weare' },
  { label: 'Weather Station', value: 'Weather Station' },
  { label: 'Weatherby Lake', value: 'Weatherby Lake' },
  { label: 'Weatherford', value: 'Weatherford' },
  { label: 'Weatherford', value: 'Weatherford' },
  { label: 'Weatherly', value: 'Weatherly' },
  { label: 'Weatogue', value: 'Weatogue' },
  { label: 'Weaver', value: 'Weaver' },
  { label: 'Weaverham', value: 'Weaverham' },
  { label: 'Weaverville', value: 'Weaverville' },
  { label: 'Weaverville', value: 'Weaverville' },
  { label: 'Webb', value: 'Webb' },
  { label: 'Webb City', value: 'Webb City' },
  { label: 'Webb County', value: 'Webb County' },
  { label: 'Webberville', value: 'Webberville' },
  { label: 'Weber City', value: 'Weber City' },
  { label: 'Weber County', value: 'Weber County' },
  { label: 'Webster', value: 'Webster' },
  { label: 'Webster', value: 'Webster' },
  { label: 'Webster', value: 'Webster' },
  { label: 'Webster', value: 'Webster' },
  { label: 'Webster', value: 'Webster' },
  { label: 'Webster City', value: 'Webster City' },
  { label: 'Webster County', value: 'Webster County' },
  { label: 'Webster County', value: 'Webster County' },
  { label: 'Webster County', value: 'Webster County' },
  { label: 'Webster County', value: 'Webster County' },
  { label: 'Webster County', value: 'Webster County' },
  { label: 'Webster County', value: 'Webster County' },
  { label: 'Webster County', value: 'Webster County' },
  { label: 'Webster Groves', value: 'Webster Groves' },
  { label: 'Webster Parish', value: 'Webster Parish' },
  { label: 'Webster Springs', value: 'Webster Springs' },
  { label: 'Webuye', value: 'Webuye' },
  { label: 'Wechingen', value: 'Wechingen' },
  { label: 'Wechselburg', value: 'Wechselburg' },
  { label: 'Wed Alnkil', value: 'Wed Alnkil' },
  { label: 'Weddelbrook', value: 'Weddelbrook' },
  { label: 'Weddersleben', value: 'Weddersleben' },
  { label: 'Weddin', value: 'Weddin' },
  { label: 'Wedding', value: 'Wedding' },
  { label: 'Weddingstedt', value: 'Weddingstedt' },
  { label: 'Weddington', value: 'Weddington' },
  { label: 'Wedel', value: 'Wedel' },
  { label: 'Wedgefield', value: 'Wedgefield' },
  { label: 'Wedgefield', value: 'Wedgefield' },
  { label: 'Wedgewood', value: 'Wedgewood' },
  { label: 'Wedi', value: 'Wedi' },
  { label: 'Wedmore', value: 'Wedmore' },
  { label: 'Wednesbury', value: 'Wednesbury' },
  { label: 'Wednesfield', value: 'Wednesfield' },
  { label: 'Wedowee', value: 'Wedowee' },
  { label: 'Wee Waa', value: 'Wee Waa' },
  { label: 'Weed', value: 'Weed' },
  { label: 'Weedon Beck', value: 'Weedon Beck' },
  { label: 'Weedon Centre', value: 'Weedon Centre' },
  { label: 'Weedpatch', value: 'Weedpatch' },
  { label: 'Weedsport', value: 'Weedsport' },
  { label: 'Weehawken', value: 'Weehawken' },
  { label: 'Weeki Wachee Gardens', value: 'Weeki Wachee Gardens' },
  { label: 'Weener', value: 'Weener' },
  { label: 'Weeping Water', value: 'Weeping Water' },
  { label: 'Weer', value: 'Weer' },
  { label: 'Weerberg', value: 'Weerberg' },
  { label: 'Weerestein', value: 'Weerestein' },
  { label: 'Weert', value: 'Weert' },
  { label: 'Wees', value: 'Wees' },
  { label: 'Weesen', value: 'Weesen' },
  { label: 'Weesp', value: 'Weesp' },
  { label: 'Weetangera', value: 'Weetangera' },
  { label: 'Weeting', value: 'Weeting' },
  { label: 'Weeze', value: 'Weeze' },
  { label: 'Wefensleben', value: 'Wefensleben' },
  { label: 'Weferlingen', value: 'Weferlingen' },
  { label: 'Wegberg', value: 'Wegberg' },
  { label: 'Wegeleben', value: 'Wegeleben' },
  { label: 'Wegenstetten', value: 'Wegenstetten' },
  { label: 'Weggis', value: 'Weggis' },
  { label: 'Węgierska Górka', value: 'Węgierska Górka' },
  { label: 'Węgliniec', value: 'Węgliniec' },
  { label: 'Węglówka', value: 'Węglówka' },
  { label: 'Węgorzewo', value: 'Węgorzewo' },
  { label: 'Węgorzyno', value: 'Węgorzyno' },
  { label: 'Węgrów', value: 'Węgrów' },
  { label: 'Węgry', value: 'Węgry' },
  { label: 'Węgrzce Wielkie', value: 'Węgrzce Wielkie' },
  { label: 'Wegscheid', value: 'Wegscheid' },
  { label: 'Wehingen', value: 'Wehingen' },
  { label: 'Wehr', value: 'Wehr' },
  { label: 'Wehr', value: 'Wehr' },
  { label: 'Wehrheim', value: 'Wehrheim' },
  { label: 'Wehringen', value: 'Wehringen' },
  { label: 'Weibern', value: 'Weibern' },
  { label: 'Weibern', value: 'Weibern' },
  { label: 'Weibersbrunn', value: 'Weibersbrunn' },
  { label: 'Weichering', value: 'Weichering' },
  { label: 'Weichs', value: 'Weichs' },
  { label: 'Weichselbaum', value: 'Weichselbaum' },
  { label: 'Weida', value: 'Weida' },
  { label: 'Weiden', value: 'Weiden' },
  { label: 'Weiden Am See', value: 'Weiden Am See' },
  { label: 'Weiden Bei Rechnitz', value: 'Weiden Bei Rechnitz' },
  { label: 'Weidenbach', value: 'Weidenbach' },
  { label: 'Weidenstetten', value: 'Weidenstetten' },
  { label: 'Weidenthal', value: 'Weidenthal' },
  { label: 'Weidevenne', value: 'Weidevenne' },
  { label: 'Weidhausen Bei Coburg', value: 'Weidhausen Bei Coburg' },
  { label: 'Weiding', value: 'Weiding' },
  { label: 'Weidling', value: 'Weidling' },
  { label: 'Weifang', value: 'Weifang' },
  { label: 'Weigelsdorf', value: 'Weigelsdorf' },
  { label: 'Weigelstown', value: 'Weigelstown' },
  { label: 'Weigendorf', value: 'Weigendorf' },
  { label: 'Weihai', value: 'Weihai' },
  { label: 'Weihenzell', value: 'Weihenzell' },
  { label: 'Weiherhammer', value: 'Weiherhammer' },
  { label: 'Weihmichl', value: 'Weihmichl' },
  { label: 'Weijpoort', value: 'Weijpoort' },
  { label: 'Weikendorf', value: 'Weikendorf' },
  { label: 'Weikersdorf Am Steinfelde', value: 'Weikersdorf Am Steinfelde' },
  { label: 'Weikersheim', value: 'Weikersheim' },
  { label: 'Weil', value: 'Weil' },
  { label: 'Weil Am Rhein', value: 'Weil Am Rhein' },
  { label: 'Weil Der Stadt', value: 'Weil Der Stadt' },
  { label: 'Weil Im Schönbuch', value: 'Weil Im Schönbuch' },
  { label: 'Weilbach', value: 'Weilbach' },
  { label: 'Weilbach', value: 'Weilbach' },
  { label: 'Weilburg', value: 'Weilburg' },
  { label: 'Weiler', value: 'Weiler' },
  { label: 'Weiler', value: 'Weiler' },
  { label: 'Weilerbach', value: 'Weilerbach' },
  { label: 'Weiler-La-Tour', value: 'Weiler-La-Tour' },
  { label: 'Weilersbach', value: 'Weilersbach' },
  { label: 'Weiler-Simmerberg', value: 'Weiler-Simmerberg' },
  { label: 'Weilerswist', value: 'Weilerswist' },
  { label: 'Weilheim', value: 'Weilheim' },
  { label: 'Weilheim', value: 'Weilheim' },
  { label: 'Weilheim An Der Teck', value: 'Weilheim An Der Teck' },
  { label: 'Weilmünster', value: 'Weilmünster' },
  { label: 'Weiltingen', value: 'Weiltingen' },
  { label: 'Weimar', value: 'Weimar' },
  { label: 'Weimar', value: 'Weimar' },
  { label: 'Weinan', value: 'Weinan' },
  { label: 'Weinbach', value: 'Weinbach' },
  { label: 'Weinböhla', value: 'Weinböhla' },
  { label: 'Weinburg', value: 'Weinburg' },
  { label: 'Weinburg Am Saßbach', value: 'Weinburg Am Saßbach' },
  { label: 'Weinfelden', value: 'Weinfelden' },
  { label: 'Weinfelden District', value: 'Weinfelden District' },
  { label: 'Weingarten', value: 'Weingarten' },
  { label: 'Weingarten', value: 'Weingarten' },
  { label: 'Weingraben', value: 'Weingraben' },
  { label: 'Weinheim', value: 'Weinheim' },
  { label: 'Weining', value: 'Weining' },
  { label: 'Weiningen', value: 'Weiningen' },
  { label: 'Weinsberg', value: 'Weinsberg' },
  { label: 'Weinsheim', value: 'Weinsheim' },
  { label: 'Weinstadt-Endersbach', value: 'Weinstadt-Endersbach' },
  { label: 'Weinzierl Am Walde', value: 'Weinzierl Am Walde' },
  { label: 'Weinzierl Bei Krems', value: 'Weinzierl Bei Krems' },
  { label: 'Weipa', value: 'Weipa' },
  { label: 'Weirton', value: 'Weirton' },
  { label: 'Weirton Heights', value: 'Weirton Heights' },
  { label: 'Weischlitz', value: 'Weischlitz' },
  { label: 'Weisel', value: 'Weisel' },
  { label: 'Weisen', value: 'Weisen' },
  { label: 'Weisenbach', value: 'Weisenbach' },
  { label: 'Weisendorf', value: 'Weisendorf' },
  { label: 'Weiser', value: 'Weiser' },
  { label: 'Weiskirchen', value: 'Weiskirchen' },
  { label: 'Weismain', value: 'Weismain' },
  { label: 'Weissach', value: 'Weissach' },
  { label: 'Weissach', value: 'Weissach' },
  { label: 'Weißandt-Gölzau', value: 'Weißandt-Gölzau' },
  { label: 'Weißbach', value: 'Weißbach' },
  { label: 'Weißdorf', value: 'Weißdorf' },
  { label: 'Weissenbach Am Lech', value: 'Weissenbach Am Lech' },
  { label: 'Weissenbach An Der Triesting', value: 'Weissenbach An Der Triesting' },
  { label: 'Weißenbach Bei Liezen', value: 'Weißenbach Bei Liezen' },
  { label: 'Weißenberg', value: 'Weißenberg' },
  { label: 'Weißenborn', value: 'Weißenborn' },
  { label: 'Weißenborn', value: 'Weißenborn' },
  { label: 'Weißenborn-Lüderode', value: 'Weißenborn-Lüderode' },
  { label: 'Weißenbrunn', value: 'Weißenbrunn' },
  { label: 'Weißenburg In Bayern', value: 'Weißenburg In Bayern' },
  { label: 'Weißenfels', value: 'Weißenfels' },
  { label: 'Weißenhorn', value: 'Weißenhorn' },
  { label: 'Weißenkirchen Im Attergau', value: 'Weißenkirchen Im Attergau' },
  { label: 'Weißenkirchen In Der Wachau', value: 'Weißenkirchen In Der Wachau' },
  { label: 'Weißenohe', value: 'Weißenohe' },
  { label: 'Weißensberg', value: 'Weißensberg' },
  { label: 'Weißensee', value: 'Weißensee' },
  { label: 'Weißensee', value: 'Weißensee' },
  { label: 'Weißensee', value: 'Weißensee' },
  { label: 'Weißenstadt', value: 'Weißenstadt' },
  { label: 'Weißenstein', value: 'Weißenstein' },
  { label: 'Weißenthurm', value: 'Weißenthurm' },
  { label: 'Weißig', value: 'Weißig' },
  { label: 'Weißkeißel', value: 'Weißkeißel' },
  { label: 'Weisskirchen An Der Traun', value: 'Weisskirchen An Der Traun' },
  { label: 'Weißkirchen In Steiermark', value: 'Weißkirchen In Steiermark' },
  { label: 'Weisslingen', value: 'Weisslingen' },
  { label: 'Weissport East', value: 'Weissport East' },
  { label: 'Weißwasser', value: 'Weißwasser' },
  { label: 'Weistrach', value: 'Weistrach' },
  { label: 'Weiswampach', value: 'Weiswampach' },
  { label: 'Weisweil', value: 'Weisweil' },
  { label: 'Weitbruch', value: 'Weitbruch' },
  { label: 'Weitefeld', value: 'Weitefeld' },
  { label: 'Weiten', value: 'Weiten' },
  { label: 'Weiten', value: 'Weiten' },
  { label: 'Weitenhagen', value: 'Weitenhagen' },
  { label: 'Weitensfeld', value: 'Weitensfeld' },
  { label: 'Weitensfeld Im Gurktal', value: 'Weitensfeld Im Gurktal' },
  { label: 'Weitersburg', value: 'Weitersburg' },
  { label: 'Weitersfeld', value: 'Weitersfeld' },
  { label: 'Weitersfelden', value: 'Weitersfelden' },
  { label: 'Weiterstadt', value: 'Weiterstadt' },
  { label: 'Weiteveen', value: 'Weiteveen' },
  { label: 'Weitnau', value: 'Weitnau' },
  { label: 'Weitra', value: 'Weitra' },
  { label: 'Weitramsdorf', value: 'Weitramsdorf' },
  { label: 'Weiwangzhuang', value: 'Weiwangzhuang' },
  { label: 'Weiz', value: 'Weiz' },
  { label: 'Wejherowo', value: 'Wejherowo' },
  { label: 'Wekerom', value: 'Wekerom' },
  { label: 'Wekiwa Springs', value: 'Wekiwa Springs' },
  { label: 'Welahan', value: 'Welahan' },
  { label: 'Welamboda', value: 'Welamboda' },
  { label: 'Welberg', value: 'Welberg' },
  { label: 'Welby', value: 'Welby' },
  { label: 'Welch', value: 'Welch' },
  { label: 'Welcome', value: 'Welcome' },
  { label: 'Welcome', value: 'Welcome' },
  { label: 'Welcome Beach', value: 'Welcome Beach' },
  { label: 'Welcome Hall', value: 'Welcome Hall' },
  { label: 'Weld County', value: 'Weld County' },
  { label: 'Welden', value: 'Welden' },
  { label: 'Weldon', value: 'Weldon' },
  { label: 'Weldon', value: 'Weldon' },
  { label: 'Weldon Spring', value: 'Weldon Spring' },
  { label: 'Weleri', value: 'Weleri' },
  { label: 'Welford', value: 'Welford' },
  { label: 'Welgelegen', value: 'Welgelegen' },
  { label: 'Weligama', value: 'Weligama' },
  { label: 'Welisara', value: 'Welisara' },
  { label: 'Welkenraedt', value: 'Welkenraedt' },
  { label: 'Welkom', value: 'Welkom' },
  { label: 'Well', value: 'Well' },
  { label: 'Welland', value: 'Welland' },
  { label: 'Wellard', value: 'Wellard' },
  { label: 'Wellawaya', value: 'Wellawaya' },
  { label: 'Wellborn', value: 'Wellborn' },
  { label: 'Welle', value: 'Welle' },
  { label: 'Wellen', value: 'Wellen' },
  { label: 'Wellen', value: 'Wellen' },
  { label: 'Wellendingen', value: 'Wellendingen' },
  { label: 'Wellerlooi', value: 'Wellerlooi' },
  { label: 'Wellesbourne', value: 'Wellesbourne' },
  { label: 'Wellesbourne Mountford', value: 'Wellesbourne Mountford' },
  { label: 'Wellesley', value: 'Wellesley' },
  { label: 'Wellesley', value: 'Wellesley' },
  { label: 'Wellesley Islands', value: 'Wellesley Islands' },
  { label: 'Wellfleet', value: 'Wellfleet' },
  { label: 'Wellford', value: 'Wellford' },
  { label: 'Wellin', value: 'Wellin' },
  { label: 'Welling', value: 'Welling' },
  { label: 'Wellingara Ba', value: 'Wellingara Ba' },
  { label: 'Wellingborough', value: 'Wellingborough' },
  { label: 'Wellingsbüttel', value: 'Wellingsbüttel' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Wellington City', value: 'Wellington City' },
  { label: 'Wellington Point', value: 'Wellington Point' },
  { label: 'Wellman', value: 'Wellman' },
  { label: 'Wellow', value: 'Wellow' },
  { label: 'Wells', value: 'Wells' },
  { label: 'Wells', value: 'Wells' },
  { label: 'Wells', value: 'Wells' },
  { label: 'Wells', value: 'Wells' },
  { label: 'Wells Beach Station', value: 'Wells Beach Station' },
  { label: 'Wells Branch', value: 'Wells Branch' },
  { label: 'Wells County', value: 'Wells County' },
  { label: 'Wells County', value: 'Wells County' },
  { label: 'Wellsboro', value: 'Wellsboro' },
  { label: 'Wellsburg', value: 'Wellsburg' },
  { label: 'Wellsford', value: 'Wellsford' },
  { label: 'Wells-Next-The-Sea', value: 'Wells-Next-The-Sea' },
  { label: 'Wellston', value: 'Wellston' },
  { label: 'Wellston', value: 'Wellston' },
  { label: 'Wellsville', value: 'Wellsville' },
  { label: 'Wellsville', value: 'Wellsville' },
  { label: 'Wellsville', value: 'Wellsville' },
  { label: 'Wellsville', value: 'Wellsville' },
  { label: 'Wellsville', value: 'Wellsville' },
  { label: 'Wellton', value: 'Wellton' },
  { label: 'Weloy Municipality', value: 'Weloy Municipality' },
  { label: 'Wels', value: 'Wels' },
  { label: 'Wels Stadt', value: 'Wels Stadt' },
  { label: 'Welschbillig', value: 'Welschbillig' },
  { label: 'Welschenrohr', value: 'Welschenrohr' },
  { label: 'Welsh', value: 'Welsh' },
  { label: 'Welshpool', value: 'Welshpool' },
  { label: 'Wels-Land', value: 'Wels-Land' },
  { label: 'Welsleben', value: 'Welsleben' },
  { label: 'Welver', value: 'Welver' },
  { label: 'Welwyn', value: 'Welwyn' },
  { label: 'Welwyn Garden City', value: 'Welwyn Garden City' },
  { label: 'Welzheim', value: 'Welzheim' },
  { label: 'Welzow', value: 'Welzow' },
  { label: 'Wem', value: 'Wem' },
  { label: 'Wembley', value: 'Wembley' },
  { label: 'Wembley', value: 'Wembley' },
  { label: 'Wembley', value: 'Wembley' },
  { label: 'Wembley Downs', value: 'Wembley Downs' },
  { label: 'Wembury', value: 'Wembury' },
  { label: 'Wemding', value: 'Wemding' },
  { label: 'Wemeldinge', value: 'Wemeldinge' },
  { label: 'Wemmel', value: 'Wemmel' },
  { label: 'Wemyss Bay', value: 'Wemyss Bay' },
  { label: 'Wenatchee', value: 'Wenatchee' },
  { label: 'Wenceslao Escalante', value: 'Wenceslao Escalante' },
  { label: 'Wenceslau Braz', value: 'Wenceslau Braz' },
  { label: 'Wenceslau Braz', value: 'Wenceslau Braz' },
  { label: 'Wenceslau Guimarães', value: 'Wenceslau Guimarães' },
  { label: 'Wenchang', value: 'Wenchang' },
  { label: 'Wenchi', value: 'Wenchi' },
  { label: 'Wendeburg', value: 'Wendeburg' },
  { label: 'Wendell', value: 'Wendell' },
  { label: 'Wendell', value: 'Wendell' },
  { label: 'Wendell', value: 'Wendell' },
  { label: 'Wendelsheim', value: 'Wendelsheim' },
  { label: 'Wendelstein', value: 'Wendelstein' },
  { label: 'Wenden', value: 'Wenden' },
  { label: 'Wendisch Evern', value: 'Wendisch Evern' },
  { label: 'Wendisch Rietz', value: 'Wendisch Rietz' },
  { label: 'Wendling', value: 'Wendling' },
  { label: 'Wendlingen Am Neckar', value: 'Wendlingen Am Neckar' },
  { label: 'Wendo', value: 'Wendo' },
  { label: 'Wendorf', value: 'Wendorf' },
  { label: 'Wendouree', value: 'Wendouree' },
  { label: 'Wendover', value: 'Wendover' },
  { label: 'Wendover', value: 'Wendover' },
  { label: 'Wendover', value: 'Wendover' },
  { label: 'Wendtorf', value: 'Wendtorf' },
  { label: 'Wenduine', value: 'Wenduine' },
  { label: 'Weng', value: 'Weng' },
  { label: 'Weng Im Innkreis', value: 'Weng Im Innkreis' },
  { label: 'Wenham', value: 'Wenham' },
  { label: 'Wenjī', value: 'Wenjī' },
  { label: 'Wenlan', value: 'Wenlan' },
  { label: 'Wenling', value: 'Wenling' },
  { label: 'Wennigsen', value: 'Wennigsen' },
  { label: 'Wenns', value: 'Wenns' },
  { label: 'Weno', value: 'Weno' },
  { label: 'Weno Municipality', value: 'Weno Municipality' },
  { label: 'Wenonah', value: 'Wenonah' },
  { label: 'Wenquan', value: 'Wenquan' },
  { label: 'Wenshan City', value: 'Wenshan City' },
  { label: 'Wenshan Zhuangzu Miaozu Zizhizhou', value: 'Wenshan Zhuangzu Miaozu Zizhizhou' },
  { label: 'Wenshang', value: 'Wenshang' },
  { label: 'Wenshui', value: 'Wenshui' },
  { label: 'Wentorf Bei Hamburg', value: 'Wentorf Bei Hamburg' },
  { label: 'Wentworth', value: 'Wentworth' },
  { label: 'Wentworth', value: 'Wentworth' },
  { label: 'Wentworth', value: 'Wentworth' },
  { label: 'Wentworth Falls', value: 'Wentworth Falls' },
  { label: 'Wentworth Point', value: 'Wentworth Point' },
  { label: 'Wentworthville', value: 'Wentworthville' },
  { label: 'Wentzville', value: 'Wentzville' },
  { label: 'Wenvoe', value: 'Wenvoe' },
  { label: 'Wenxing', value: 'Wenxing' },
  { label: 'Wenzenbach', value: 'Wenzenbach' },
  { label: 'Wenzendorf', value: 'Wenzendorf' },
  { label: 'Wenzhou', value: 'Wenzhou' },
  { label: 'Weobley', value: 'Weobley' },
  { label: 'Weppersdorf', value: 'Weppersdorf' },
  { label: 'Wer', value: 'Wer' },
  { label: 'Werbach', value: 'Werbach' },
  { label: 'Werben', value: 'Werben' },
  { label: 'Werbkowice', value: 'Werbkowice' },
  { label: 'Werda', value: 'Werda' },
  { label: 'Werda', value: 'Werda' },
  { label: 'Werdau', value: 'Werdau' },
  { label: 'Werder', value: 'Werder' },
  { label: 'Werdervorstadt', value: 'Werdervorstadt' },
  { label: 'Werdohl', value: 'Werdohl' },
  { label: 'Were Īlu', value: 'Were Īlu' },
  { label: 'Werfen', value: 'Werfen' },
  { label: 'Werfenweng', value: 'Werfenweng' },
  { label: 'Werftpfuhl', value: 'Werftpfuhl' },
  { label: 'Werkendam', value: 'Werkendam' },
  { label: 'Werkhoven', value: 'Werkhoven' },
  { label: 'Werl', value: 'Werl' },
  { label: 'Werlte', value: 'Werlte' },
  { label: 'Wermelskirchen', value: 'Wermelskirchen' },
  { label: 'Wermsdorf', value: 'Wermsdorf' },
  { label: 'Wernau', value: 'Wernau' },
  { label: 'Wernberg', value: 'Wernberg' },
  { label: 'Wernberg-Köblitz', value: 'Wernberg-Köblitz' },
  { label: 'Werndorf', value: 'Werndorf' },
  { label: 'Werne', value: 'Werne' },
  { label: 'Werneck', value: 'Werneck' },
  { label: 'Wernersberg', value: 'Wernersberg' },
  { label: 'Wernersdorf', value: 'Wernersdorf' },
  { label: 'Wernersville', value: 'Wernersville' },
  { label: 'Werneuchen', value: 'Werneuchen' },
  { label: 'Wernhout', value: 'Wernhout' },
  { label: 'Wernigerode', value: 'Wernigerode' },
  { label: 'Wernshausen', value: 'Wernshausen' },
  { label: 'Wernstein Am Inn', value: 'Wernstein Am Inn' },
  { label: 'Werota', value: 'Werota' },
  { label: 'Werpeloh', value: 'Werpeloh' },
  { label: 'Werribee', value: 'Werribee' },
  { label: 'Werribee South', value: 'Werribee South' },
  { label: 'Werrington', value: 'Werrington' },
  { label: 'Werrington County', value: 'Werrington County' },
  { label: 'Werrington Downs', value: 'Werrington Downs' },
  { label: 'Werris Creek', value: 'Werris Creek' },
  { label: 'Wertach', value: 'Wertach' },
  { label: 'Wertheim', value: 'Wertheim' },
  { label: 'Werthenstein', value: 'Werthenstein' },
  { label: 'Werther', value: 'Werther' },
  { label: 'Wertingen', value: 'Wertingen' },
  { label: 'Weru', value: 'Weru' },
  { label: 'Wervershoof', value: 'Wervershoof' },
  { label: 'Wervicq-Sud', value: 'Wervicq-Sud' },
  { label: 'Wervik', value: 'Wervik' },
  { label: 'Wesburn', value: 'Wesburn' },
  { label: 'Wescosville', value: 'Wescosville' },
  { label: 'Wesel', value: 'Wesel' },
  { label: 'Weselberg', value: 'Weselberg' },
  { label: 'Wesenberg', value: 'Wesenberg' },
  { label: 'Wesendorf', value: 'Wesendorf' },
  { label: 'Weslaco', value: 'Weslaco' },
  { label: 'Wesley', value: 'Wesley' },
  { label: 'Wesley Chapel', value: 'Wesley Chapel' },
  { label: 'Wesley Chapel', value: 'Wesley Chapel' },
  { label: 'Wesley Hills', value: 'Wesley Hills' },
  { label: 'Wesleyville', value: 'Wesleyville' },
  { label: 'Wesoła', value: 'Wesoła' },
  { label: 'Wesoła', value: 'Wesoła' },
  { label: 'Wesselburen', value: 'Wesselburen' },
  { label: 'Wesseling', value: 'Wesseling' },
  { label: 'Wesseln', value: 'Wesseln' },
  { label: 'Wesselsbron', value: 'Wesselsbron' },
  { label: 'Wessem', value: 'Wessem' },
  { label: 'Wessington Springs', value: 'Wessington Springs' },
  { label: 'Weßling', value: 'Weßling' },
  { label: 'Wessobrunn', value: 'Wessobrunn' },
  { label: 'Wesson', value: 'Wesson' },
  { label: 'West', value: 'West' },
  { label: 'West Albany', value: 'West Albany' },
  { label: 'West Albury', value: 'West Albury' },
  { label: 'West Alexandria', value: 'West Alexandria' },
  { label: 'West Allis', value: 'West Allis' },
  { label: 'West And East Lealman', value: 'West And East Lealman' },
  { label: 'West Arnhem', value: 'West Arnhem' },
  { label: 'West Arthur', value: 'West Arthur' },
  { label: 'West Athens', value: 'West Athens' },
  { label: 'West Babylon', value: 'West Babylon' },
  { label: 'West Ballina', value: 'West Ballina' },
  { label: 'West Baraboo', value: 'West Baraboo' },
  { label: 'West Barnstable', value: 'West Barnstable' },
  { label: 'West Bathurst', value: 'West Bathurst' },
  { label: 'West Baton Rouge Parish', value: 'West Baton Rouge Parish' },
  { label: 'West Bay Shore', value: 'West Bay Shore' },
  { label: 'West Beach', value: 'West Beach' },
  { label: 'West Beach', value: 'West Beach' },
  { label: 'West Belmar', value: 'West Belmar' },
  { label: 'West Bend', value: 'West Bend' },
  { label: 'West Bergholt', value: 'West Bergholt' },
  { label: 'West Berkshire', value: 'West Berkshire' },
  { label: 'West Betuwe', value: 'West Betuwe' },
  { label: 'West Bishop', value: 'West Bishop' },
  { label: 'West Blocton', value: 'West Blocton' },
  { label: 'West Bloomfield Township', value: 'West Bloomfield Township' },
  { label: 'West Bountiful', value: 'West Bountiful' },
  { label: 'West Boylston', value: 'West Boylston' },
  { label: 'West Bradenton', value: 'West Bradenton' },
  { label: 'West Branch', value: 'West Branch' },
  { label: 'West Branch', value: 'West Branch' },
  { label: 'West Brattleboro', value: 'West Brattleboro' },
  { label: 'West Bridgewater', value: 'West Bridgewater' },
  { label: 'West Bridgford', value: 'West Bridgford' },
  { label: 'West Bromwich', value: 'West Bromwich' },
  { label: 'West Brookfield', value: 'West Brookfield' },
  { label: 'West Buechel', value: 'West Buechel' },
  { label: 'West Burlington', value: 'West Burlington' },
  { label: 'West Busselton', value: 'West Busselton' },
  { label: 'West Byfleet', value: 'West Byfleet' },
  { label: 'West Calder', value: 'West Calder' },
  { label: 'West Canton', value: 'West Canton' },
  { label: 'West Cape May', value: 'West Cape May' },
  { label: 'West Carroll Parish', value: 'West Carroll Parish' },
  { label: 'West Carrollton City', value: 'West Carrollton City' },
  { label: 'West Carson', value: 'West Carson' },
  { label: 'West Carthage', value: 'West Carthage' },
  { label: 'West Chatham', value: 'West Chatham' },
  { label: 'West Chester', value: 'West Chester' },
  { label: 'West Chicago', value: 'West Chicago' },
  { label: 'West Clandon', value: 'West Clandon' },
  { label: 'West Clarkston-Highland', value: 'West Clarkston-Highland' },
  { label: 'West Coast', value: 'West Coast' },
  { label: 'West Coast District Municipality', value: 'West Coast District Municipality' },
  { label: 'West Coker', value: 'West Coker' },
  { label: 'West Columbia', value: 'West Columbia' },
  { label: 'West Columbia', value: 'West Columbia' },
  { label: 'West Concord', value: 'West Concord' },
  { label: 'West Conshohocken', value: 'West Conshohocken' },
  { label: 'West Coon Rapids', value: 'West Coon Rapids' },
  { label: 'West Cornforth', value: 'West Cornforth' },
  { label: 'West Covina', value: 'West Covina' },
  { label: 'West Crossett', value: 'West Crossett' },
  { label: 'West Croydon', value: 'West Croydon' },
  { label: 'West Cumberland', value: 'West Cumberland' },
  { label: 'West Deland', value: 'West Deland' },
  { label: 'West Delhi', value: 'West Delhi' },
  { label: 'West Dennis', value: 'West Dennis' },
  { label: 'West Des Moines', value: 'West Des Moines' },
  { label: 'West District', value: 'West District' },
  { label: 'West Down Town', value: 'West Down Town' },
  { label: 'West Drayton', value: 'West Drayton' },
  { label: 'West Dunbartonshire', value: 'West Dunbartonshire' },
  { label: 'West Dundee', value: 'West Dundee' },
  { label: 'West Easton', value: 'West Easton' },
  { label: 'West Elkridge', value: 'West Elkridge' },
  { label: 'West Elmira', value: 'West Elmira' },
  { label: 'West Elsdon', value: 'West Elsdon' },
  { label: 'West End', value: 'West End' },
  { label: 'West End', value: 'West End' },
  { label: 'West End', value: 'West End' },
  { label: 'West End', value: 'West End' },
  { label: 'West End', value: 'West End' },
  { label: 'West End', value: 'West End' },
  { label: 'West End Of London', value: 'West End Of London' },
  { label: 'West End-Cobb Town', value: 'West End-Cobb Town' },
  { label: 'West Englewood', value: 'West Englewood' },
  { label: 'West Fairview', value: 'West Fairview' },
  { label: 'West Falls Church', value: 'West Falls Church' },
  { label: 'West Falmouth', value: 'West Falmouth' },
  { label: 'West Fargo', value: 'West Fargo' },
  { label: 'West Feliciana Parish', value: 'West Feliciana Parish' },
  { label: 'West Ferriday', value: 'West Ferriday' },
  { label: 'West Footscray', value: 'West Footscray' },
  { label: 'West Fork', value: 'West Fork' },
  { label: 'West Frankfort', value: 'West Frankfort' },
  { label: 'West Freehold', value: 'West Freehold' },
  { label: 'West Garfield Park', value: 'West Garfield Park' },
  { label: 'West Garo Hills', value: 'West Garo Hills' },
  { label: 'West Gate', value: 'West Gate' },
  { label: 'West Gate', value: 'West Gate' },
  { label: 'West Gladstone', value: 'West Gladstone' },
  { label: 'West Glendive', value: 'West Glendive' },
  { label: 'West Glens Falls', value: 'West Glens Falls' },
  { label: 'West Godavari', value: 'West Godavari' },
  { label: 'West Gonja', value: 'West Gonja' },
  { label: 'West Gosford', value: 'West Gosford' },
  { label: 'West Green', value: 'West Green' },
  { label: 'West Greenwich', value: 'West Greenwich' },
  { label: 'West Grove', value: 'West Grove' },
  { label: 'West Gulfport', value: 'West Gulfport' },
  { label: 'West Haddon', value: 'West Haddon' },
  { label: 'West Hallam', value: 'West Hallam' },
  { label: 'West Hamburg', value: 'West Hamburg' },
  { label: 'West Hammond', value: 'West Hammond' },
  { label: 'West Harerghe Zone', value: 'West Harerghe Zone' },
  { label: 'West Hartford', value: 'West Hartford' },
  { label: 'West Hattiesburg', value: 'West Hattiesburg' },
  { label: 'West Haven', value: 'West Haven' },
  { label: 'West Haven', value: 'West Haven' },
  { label: 'West Haven', value: 'West Haven' },
  { label: 'West Haven', value: 'West Haven' },
  { label: 'West Haven-Sylvan', value: 'West Haven-Sylvan' },
  { label: 'West Haverstraw', value: 'West Haverstraw' },
  { label: 'West Hazleton', value: 'West Hazleton' },
  { label: 'West Helena', value: 'West Helena' },
  { label: 'West Hempstead', value: 'West Hempstead' },
  { label: 'West Hill', value: 'West Hill' },
  { label: 'West Hills', value: 'West Hills' },
  { label: 'West Hills', value: 'West Hills' },
  { label: 'West Hills', value: 'West Hills' },
  { label: 'West Hindmarsh', value: 'West Hindmarsh' },
  { label: 'West Hobart', value: 'West Hobart' },
  { label: 'West Hollywood', value: 'West Hollywood' },
  { label: 'West Hollywood', value: 'West Hollywood' },
  { label: 'West Homestead', value: 'West Homestead' },
  { label: 'West Horsley', value: 'West Horsley' },
  { label: 'West Hoxton', value: 'West Hoxton' },
  { label: 'West Hurley', value: 'West Hurley' },
  { label: 'West Ilsley', value: 'West Ilsley' },
  { label: 'West Ishpeming', value: 'West Ishpeming' },
  { label: 'West Islip', value: 'West Islip' },
  { label: 'West Jaintia Hills', value: 'West Jaintia Hills' },
  { label: 'West Jefferson', value: 'West Jefferson' },
  { label: 'West Jefferson', value: 'West Jefferson' },
  { label: 'West Jerusalem', value: 'West Jerusalem' },
  { label: 'West Jordan', value: 'West Jordan' },
  { label: 'West Kameng', value: 'West Kameng' },
  { label: 'West Kelowna', value: 'West Kelowna' },
  { label: 'West Kempsey', value: 'West Kempsey' },
  { label: 'West Kennebunk', value: 'West Kennebunk' },
  { label: 'West Khasi Hills', value: 'West Khasi Hills' },
  { label: 'West Kilbride', value: 'West Kilbride' },
  { label: 'West Kingsdown', value: 'West Kingsdown' },
  { label: 'West Kirby', value: 'West Kirby' },
  { label: 'West Kittanning', value: 'West Kittanning' },
  { label: 'West Lafayette', value: 'West Lafayette' },
  { label: 'West Lafayette', value: 'West Lafayette' },
  { label: 'West Lake Hills', value: 'West Lake Hills' },
  { label: 'West Lake Sammamish', value: 'West Lake Sammamish' },
  { label: 'West Lake Stevens', value: 'West Lake Stevens' },
  { label: 'West Lakes', value: 'West Lakes' },
  { label: 'West Lakes Shore', value: 'West Lakes Shore' },
  { label: 'West Lamington', value: 'West Lamington' },
  { label: 'West Launceston', value: 'West Launceston' },
  { label: 'West Laurel', value: 'West Laurel' },
  { label: 'West Lawn', value: 'West Lawn' },
  { label: 'West Lawn', value: 'West Lawn' },
  { label: 'West Leechburg', value: 'West Leechburg' },
  { label: 'West Leederville', value: 'West Leederville' },
  { label: 'West Liberty', value: 'West Liberty' },
  { label: 'West Liberty', value: 'West Liberty' },
  { label: 'West Liberty', value: 'West Liberty' },
  { label: 'West Liberty', value: 'West Liberty' },
  { label: 'West Linn', value: 'West Linn' },
  { label: 'West Linton', value: 'West Linton' },
  { label: 'West Little River', value: 'West Little River' },
  { label: 'West Livingston', value: 'West Livingston' },
  { label: 'West Long Branch', value: 'West Long Branch' },
  { label: 'West Longview', value: 'West Longview' },
  { label: 'West Lorne', value: 'West Lorne' },
  { label: 'West Lothian', value: 'West Lothian' },
  { label: 'West Lynchburg', value: 'West Lynchburg' },
  { label: 'West Mackay', value: 'West Mackay' },
  { label: 'West Malling', value: 'West Malling' },
  { label: 'West Mamprusi', value: 'West Mamprusi' },
  { label: 'West Marion', value: 'West Marion' },
  { label: 'West Mayfield', value: 'West Mayfield' },
  { label: 'West Melbourne', value: 'West Melbourne' },
  { label: 'West Melbourne', value: 'West Melbourne' },
  { label: 'West Memphis', value: 'West Memphis' },
  { label: 'West Menlo Park', value: 'West Menlo Park' },
  { label: 'West Mersea', value: 'West Mersea' },
  { label: 'West Miami', value: 'West Miami' },
  { label: 'West Mifflin', value: 'West Mifflin' },
  { label: 'West Milford', value: 'West Milford' },
  { label: 'West Milton', value: 'West Milton' },
  { label: 'West Milwaukee', value: 'West Milwaukee' },
  { label: 'West Modesto', value: 'West Modesto' },
  { label: 'West Molesey', value: 'West Molesey' },
  { label: 'West Monroe', value: 'West Monroe' },
  { label: 'West Monroe', value: 'West Monroe' },
  { label: 'West Moonah', value: 'West Moonah' },
  { label: 'West Mountain', value: 'West Mountain' },
  { label: 'West New York', value: 'West New York' },
  { label: 'West Newbury', value: 'West Newbury' },
  { label: 'West Newton', value: 'West Newton' },
  { label: 'West Norriton', value: 'West Norriton' },
  { label: 'West Nowra', value: 'West Nowra' },
  { label: 'West Nyack', value: 'West Nyack' },
  { label: 'West Ocean City', value: 'West Ocean City' },
  { label: 'West Odessa', value: 'West Odessa' },
  { label: 'West Orange', value: 'West Orange' },
  { label: 'West Orange', value: 'West Orange' },
  { label: 'West Palm Beach', value: 'West Palm Beach' },
  { label: 'West Paris', value: 'West Paris' },
  { label: 'West Park', value: 'West Park' },
  { label: 'West Park', value: 'West Park' },
  { label: 'West Pasco', value: 'West Pasco' },
  { label: 'West Pennant Hills', value: 'West Pennant Hills' },
  { label: 'West Pensacola', value: 'West Pensacola' },
  { label: 'West Peoria', value: 'West Peoria' },
  { label: 'West Perrine', value: 'West Perrine' },
  { label: 'West Perth', value: 'West Perth' },
  { label: 'West Pittston', value: 'West Pittston' },
  { label: 'West Plains', value: 'West Plains' },
  { label: 'West Pleasant View', value: 'West Pleasant View' },
  { label: 'West Point', value: 'West Point' },
  { label: 'West Point', value: 'West Point' },
  { label: 'West Point', value: 'West Point' },
  { label: 'West Point', value: 'West Point' },
  { label: 'West Point', value: 'West Point' },
  { label: 'West Point', value: 'West Point' },
  { label: 'West Portsmouth', value: 'West Portsmouth' },
  { label: 'West Puente Valley', value: 'West Puente Valley' },
  { label: 'West Punjab', value: 'West Punjab' },
  { label: 'West Pymble', value: 'West Pymble' },
  { label: 'West Rainton', value: 'West Rainton' },
  { label: 'West Raleigh', value: 'West Raleigh' },
  { label: 'West Rancho Dominguez', value: 'West Rancho Dominguez' },
  { label: 'West Rand District Municipality', value: 'West Rand District Municipality' },
  { label: 'West Reading', value: 'West Reading' },
  { label: 'West Richland', value: 'West Richland' },
  { label: 'West Ridge', value: 'West Ridge' },
  { label: 'West Rockhampton', value: 'West Rockhampton' },
  { label: 'West Rutland', value: 'West Rutland' },
  { label: 'West Ryde', value: 'West Ryde' },
  { label: 'West Sacramento', value: 'West Sacramento' },
  { label: 'West Saint Paul', value: 'West Saint Paul' },
  { label: 'West Salem', value: 'West Salem' },
  { label: 'West Salem', value: 'West Salem' },
  { label: 'West Samoset', value: 'West Samoset' },
  { label: 'West Sand Lake', value: 'West Sand Lake' },
  { label: 'West Sayville', value: 'West Sayville' },
  { label: 'West Scarborough', value: 'West Scarborough' },
  { label: 'West Sedona', value: 'West Sedona' },
  { label: 'West Seneca', value: 'West Seneca' },
  { label: 'West Sharyland', value: 'West Sharyland' },
  { label: 'West Siang', value: 'West Siang' },
  { label: 'West Side Highway', value: 'West Side Highway' },
  { label: 'West Simsbury', value: 'West Simsbury' },
  { label: 'West Slope', value: 'West Slope' },
  { label: 'West Springfield', value: 'West Springfield' },
  { label: 'West Springfield', value: 'West Springfield' },
  { label: 'West St. Paul', value: 'West St. Paul' },
  { label: 'West Stockbridge', value: 'West Stockbridge' },
  { label: 'West Sussex', value: 'West Sussex' },
  { label: 'West Swanzey', value: 'West Swanzey' },
  { label: 'West Tamar', value: 'West Tamar' },
  { label: 'West Tamworth', value: 'West Tamworth' },
  { label: 'West Tawakoni', value: 'West Tawakoni' },
  { label: 'West Terre Haute', value: 'West Terre Haute' },
  { label: 'West Thurrock', value: 'West Thurrock' },
  { label: 'West Tisbury', value: 'West Tisbury' },
  { label: 'West Torrington', value: 'West Torrington' },
  { label: 'West Town', value: 'West Town' },
  { label: 'West Tripura', value: 'West Tripura' },
  { label: 'West Ulverstone', value: 'West Ulverstone' },
  { label: 'West Union', value: 'West Union' },
  { label: 'West Union', value: 'West Union' },
  { label: 'West Union', value: 'West Union' },
  { label: 'West Unity', value: 'West Unity' },
  { label: 'West University Place', value: 'West University Place' },
  { label: 'West Valley', value: 'West Valley' },
  { label: 'West Valley City', value: 'West Valley City' },
  { label: 'West Vancouver', value: 'West Vancouver' },
  { label: 'West Vero Corridor', value: 'West Vero Corridor' },
  { label: 'West View', value: 'West View' },
  { label: 'West Wallsend', value: 'West Wallsend' },
  { label: 'West Walton', value: 'West Walton' },
  { label: 'West Wareham', value: 'West Wareham' },
  { label: 'West Warrenton', value: 'West Warrenton' },
  { label: 'West Warwick', value: 'West Warwick' },
  { label: 'West Wellega Zone', value: 'West Wellega Zone' },
  { label: 'West Wellow', value: 'West Wellow' },
  { label: 'West Wenatchee', value: 'West Wenatchee' },
  { label: 'West Wendover', value: 'West Wendover' },
  { label: 'West Whittier-Los Nietos', value: 'West Whittier-Los Nietos' },
  { label: 'West Wickham', value: 'West Wickham' },
  { label: 'West Wimmera', value: 'West Wimmera' },
  { label: 'West Wodonga', value: 'West Wodonga' },
  { label: 'West Wollongong', value: 'West Wollongong' },
  { label: 'West Woombye', value: 'West Woombye' },
  { label: 'West Wyalong', value: 'West Wyalong' },
  { label: 'West Wyoming', value: 'West Wyoming' },
  { label: 'West Wyomissing', value: 'West Wyomissing' },
  { label: 'West Yarmouth', value: 'West Yarmouth' },
  { label: 'West Yellowstone', value: 'West Yellowstone' },
  { label: 'West York', value: 'West York' },
  { label: 'Westall Group/The Mangue', value: 'Westall Group/The Mangue' },
  { label: 'Westbeemster', value: 'Westbeemster' },
  { label: 'Westborough', value: 'Westborough' },
  { label: 'Westbourne Park', value: 'Westbourne Park' },
  { label: 'Westbrook', value: 'Westbrook' },
  { label: 'Westbrook', value: 'Westbrook' },
  { label: 'Westbrook Center', value: 'Westbrook Center' },
  { label: 'Westbury', value: 'Westbury' },
  { label: 'Westbury', value: 'Westbury' },
  { label: 'Westbury', value: 'Westbury' },
  { label: 'Westby', value: 'Westby' },
  { label: 'Westchase', value: 'Westchase' },
  { label: 'Westchester', value: 'Westchester' },
  { label: 'Westchester', value: 'Westchester' },
  { label: 'Westchester County', value: 'Westchester County' },
  { label: 'Westcliffe', value: 'Westcliffe' },
  { label: 'Westcliff-On-Sea', value: 'Westcliff-On-Sea' },
  { label: 'Westcott', value: 'Westcott' },
  { label: 'Westcourt', value: 'Westcourt' },
  { label: 'Westdale', value: 'Westdale' },
  { label: 'Westdorpe', value: 'Westdorpe' },
  { label: 'Weste', value: 'Weste' },
  { label: 'Westeinde', value: 'Westeinde' },
  { label: 'Westend', value: 'Westend' },
  { label: 'Westendorf', value: 'Westendorf' },
  { label: 'Westendorf', value: 'Westendorf' },
  { label: 'Westeneng', value: 'Westeneng' },
  { label: 'Westenenk', value: 'Westenenk' },
  { label: 'Westensee', value: 'Westensee' },
  { label: 'Westerbork', value: 'Westerbork' },
  { label: 'Westerburg', value: 'Westerburg' },
  { label: 'Westeregeln', value: 'Westeregeln' },
  { label: 'Westergate', value: 'Westergate' },
  { label: 'Westergellersen', value: 'Westergellersen' },
  { label: 'Westerhaar-Vriezenveensewijk', value: 'Westerhaar-Vriezenveensewijk' },
  { label: 'Westerham', value: 'Westerham' },
  { label: 'Westerhausen', value: 'Westerhausen' },
  { label: 'Westerheim', value: 'Westerheim' },
  { label: 'Westerheim', value: 'Westerheim' },
  { label: 'Westerholt', value: 'Westerholt' },
  { label: 'Westerhorn', value: 'Westerhorn' },
  { label: 'Westerhoven', value: 'Westerhoven' },
  { label: 'Westerkappeln', value: 'Westerkappeln' },
  { label: 'Westerland', value: 'Westerland' },
  { label: 'Westerland', value: 'Westerland' },
  { label: 'Westerlee', value: 'Westerlee' },
  { label: 'Westerleigh', value: 'Westerleigh' },
  { label: 'Westerlo', value: 'Westerlo' },
  { label: 'Westerly', value: 'Westerly' },
  { label: 'Western Downs', value: 'Western Downs' },
  { label: 'Western Lake', value: 'Western Lake' },
  { label: 'Western Rukum', value: 'Western Rukum' },
  { label: 'Western Springs', value: 'Western Springs' },
  { label: 'Westernport', value: 'Westernport' },
  { label: 'Wester-Ohrstedt', value: 'Wester-Ohrstedt' },
  { label: 'Westerrönfeld', value: 'Westerrönfeld' },
  { label: 'Westerstede', value: 'Westerstede' },
  { label: 'Westerstetten', value: 'Westerstetten' },
  { label: 'Westerville', value: 'Westerville' },
  { label: 'Westervoort', value: 'Westervoort' },
  { label: 'Westerwolde', value: 'Westerwolde' },
  { label: 'Westerzicht', value: 'Westerzicht' },
  { label: 'Westfália', value: 'Westfália' },
  { label: 'Westfield', value: 'Westfield' },
  { label: 'Westfield', value: 'Westfield' },
  { label: 'Westfield', value: 'Westfield' },
  { label: 'Westfield', value: 'Westfield' },
  { label: 'Westfield', value: 'Westfield' },
  { label: 'Westfield', value: 'Westfield' },
  { label: 'Westfield', value: 'Westfield' },
  { label: 'Westfield Center', value: 'Westfield Center' },
  { label: 'Westford', value: 'Westford' },
  { label: 'Westgate On Sea', value: 'Westgate On Sea' },
  { label: 'West-Graftdijk', value: 'West-Graftdijk' },
  { label: 'Westhagen', value: 'Westhagen' },
  { label: 'Westhampton', value: 'Westhampton' },
  { label: 'Westhampton', value: 'Westhampton' },
  { label: 'Westhampton Beach', value: 'Westhampton Beach' },
  { label: 'Westhausen', value: 'Westhausen' },
  { label: 'Westhaven-Moonstone', value: 'Westhaven-Moonstone' },
  { label: 'Westheim', value: 'Westheim' },
  { label: 'Westheim', value: 'Westheim' },
  { label: 'Westhill', value: 'Westhill' },
  { label: 'Westhofen', value: 'Westhofen' },
  { label: 'Westhoffen', value: 'Westhoffen' },
  { label: 'Westhoughton', value: 'Westhoughton' },
  { label: 'Westkapelle', value: 'Westkapelle' },
  { label: 'West-Knollendam', value: 'West-Knollendam' },
  { label: 'Westlake', value: 'Westlake' },
  { label: 'Westlake', value: 'Westlake' },
  { label: 'Westlake', value: 'Westlake' },
  { label: 'Westlake', value: 'Westlake' },
  { label: 'Westlake', value: 'Westlake' },
  { label: 'Westlake Village', value: 'Westlake Village' },
  { label: 'Westland', value: 'Westland' },
  { label: 'Westlaren', value: 'Westlaren' },
  { label: 'Westleigh', value: 'Westleigh' },
  { label: 'Westlock', value: 'Westlock' },
  { label: 'Westmaas', value: 'Westmaas' },
  { label: 'Westmead', value: 'Westmead' },
  { label: 'Westmeadows', value: 'Westmeadows' },
  { label: 'Westmere', value: 'Westmere' },
  { label: 'Westminster', value: 'Westminster' },
  { label: 'Westminster', value: 'Westminster' },
  { label: 'Westminster', value: 'Westminster' },
  { label: 'Westminster', value: 'Westminster' },
  { label: 'Westminster', value: 'Westminster' },
  { label: 'Westminster', value: 'Westminster' },
  { label: 'Westminster', value: 'Westminster' },
  { label: 'Westmont', value: 'Westmont' },
  { label: 'Westmont', value: 'Westmont' },
  { label: 'Westmont', value: 'Westmont' },
  { label: 'Westmoreland', value: 'Westmoreland' },
  { label: 'Westmoreland', value: 'Westmoreland' },
  { label: 'Westmoreland', value: 'Westmoreland' },
  { label: 'Westmoreland County', value: 'Westmoreland County' },
  { label: 'Westmoreland County', value: 'Westmoreland County' },
  { label: 'Westmorland', value: 'Westmorland' },
  { label: 'Westmount', value: 'Westmount' },
  { label: 'Weston', value: 'Weston' },
  { label: 'Weston', value: 'Weston' },
  { label: 'Weston', value: 'Weston' },
  { label: 'Weston', value: 'Weston' },
  { label: 'Weston', value: 'Weston' },
  { label: 'Weston', value: 'Weston' },
  { label: 'Weston', value: 'Weston' },
  { label: 'Weston', value: 'Weston' },
  { label: 'Weston', value: 'Weston' },
  { label: 'Weston County', value: 'Weston County' },
  { label: 'Weston Lakes', value: 'Weston Lakes' },
  { label: 'Weston Mills', value: 'Weston Mills' },
  { label: 'Weston Turville', value: 'Weston Turville' },
  { label: 'Westonaria', value: 'Westonaria' },
  { label: 'Westonia', value: 'Westonia' },
  { label: 'Westoning', value: 'Westoning' },
  { label: 'Weston-Super-Mare', value: 'Weston-Super-Mare' },
  { label: 'Westonzoyland', value: 'Westonzoyland' },
  { label: 'Westover', value: 'Westover' },
  { label: 'Westover', value: 'Westover' },
  { label: 'Westphalia', value: 'Westphalia' },
  { label: 'Westport', value: 'Westport' },
  { label: 'Westport', value: 'Westport' },
  { label: 'Westport', value: 'Westport' },
  { label: 'Westport', value: 'Westport' },
  { label: 'Westport', value: 'Westport' },
  { label: 'Westport', value: 'Westport' },
  { label: 'Westquarter', value: 'Westquarter' },
  { label: 'West-Souburg', value: 'West-Souburg' },
  { label: 'Weststadt', value: 'Weststadt' },
  { label: 'West-Terschelling', value: 'West-Terschelling' },
  { label: 'Westvale', value: 'Westvale' },
  { label: 'Westview', value: 'Westview' },
  { label: 'Westville', value: 'Westville' },
  { label: 'Westville', value: 'Westville' },
  { label: 'Westville', value: 'Westville' },
  { label: 'Westville', value: 'Westville' },
  { label: 'Westway', value: 'Westway' },
  { label: 'Westwego', value: 'Westwego' },
  { label: 'Westwood', value: 'Westwood' },
  { label: 'Westwood', value: 'Westwood' },
  { label: 'Westwood', value: 'Westwood' },
  { label: 'Westwood', value: 'Westwood' },
  { label: 'Westwood', value: 'Westwood' },
  { label: 'Westwood', value: 'Westwood' },
  { label: 'Westwood', value: 'Westwood' },
  { label: 'Westwood Lake', value: 'Westwood Lake' },
  { label: 'Westworth', value: 'Westworth' },
  { label: 'Westwoud', value: 'Westwoud' },
  { label: 'Westzaan', value: 'Westzaan' },
  { label: 'Wetaskiwin', value: 'Wetaskiwin' },
  { label: 'Wete', value: 'Wete' },
  { label: 'Weteringbrug', value: 'Weteringbrug' },
  { label: 'Wetheral', value: 'Wetheral' },
  { label: 'Wetherby', value: 'Wetherby' },
  { label: 'Wetherill Park', value: 'Wetherill Park' },
  { label: 'Wetherington', value: 'Wetherington' },
  { label: 'Wethersfield', value: 'Wethersfield' },
  { label: 'Wetschen', value: 'Wetschen' },
  { label: 'Wetter', value: 'Wetter' },
  { label: 'Wetter (Ruhr)', value: 'Wetter (Ruhr)' },
  { label: 'Wetteren', value: 'Wetteren' },
  { label: 'Wetterzeube', value: 'Wetterzeube' },
  { label: 'Wettin', value: 'Wettin' },
  { label: 'Wettingen', value: 'Wettingen' },
  { label: 'Wettmannstätten', value: 'Wettmannstätten' },
  { label: 'Wettolsheim', value: 'Wettolsheim' },
  { label: 'Wettringen', value: 'Wettringen' },
  { label: 'Wettswil / Ausser-Dorf', value: 'Wettswil / Ausser-Dorf' },
  { label: 'Wettswil / Wettswil (Dorf)', value: 'Wettswil / Wettswil (Dorf)' },
  { label: 'Wetumka', value: 'Wetumka' },
  { label: 'Wetumpka', value: 'Wetumpka' },
  { label: 'Wetwang', value: 'Wetwang' },
  { label: 'Wetzel County', value: 'Wetzel County' },
  { label: 'Wetzelsdorf', value: 'Wetzelsdorf' },
  { label: 'Wetzendorf', value: 'Wetzendorf' },
  { label: 'Wetzikon', value: 'Wetzikon' },
  { label: 'Wetzikon / Kempten', value: 'Wetzikon / Kempten' },
  { label: 'Wetzikon / Ober-Wetzikon', value: 'Wetzikon / Ober-Wetzikon' },
  { label: 'Wetzikon / Robenhausen', value: 'Wetzikon / Robenhausen' },
  { label: 'Wetzikon / Unter-Wetzikon', value: 'Wetzikon / Unter-Wetzikon' },
  { label: 'Wetzlar', value: 'Wetzlar' },
  { label: 'Wevelgem', value: 'Wevelgem' },
  { label: 'Wewahitchka', value: 'Wewahitchka' },
  { label: 'Weweantic', value: 'Weweantic' },
  { label: 'Wewelsfleth', value: 'Wewelsfleth' },
  { label: 'Wewoka', value: 'Wewoka' },
  { label: 'Wexford County', value: 'Wexford County' },
  { label: 'Weyarn', value: 'Weyarn' },
  { label: 'Weyauwega', value: 'Weyauwega' },
  { label: 'Weybridge', value: 'Weybridge' },
  { label: 'Weyburn', value: 'Weyburn' },
  { label: 'Weyer', value: 'Weyer' },
  { label: 'Weyerbusch', value: 'Weyerbusch' },
  { label: 'Weyers Cave', value: 'Weyers Cave' },
  { label: 'Weyersheim', value: 'Weyersheim' },
  { label: 'Weyhausen', value: 'Weyhausen' },
  { label: 'Weymontachie', value: 'Weymontachie' },
  { label: 'Weymouth', value: 'Weymouth' },
  { label: 'Weymouth', value: 'Weymouth' },
  { label: 'Weyregg', value: 'Weyregg' },
  { label: 'Wezembeek-Oppem', value: 'Wezembeek-Oppem' },
  { label: 'Whakatane', value: 'Whakatane' },
  { label: 'Whalan', value: 'Whalan' },
  { label: 'Whale Beach', value: 'Whale Beach' },
  { label: 'Whaley Bridge', value: 'Whaley Bridge' },
  { label: 'Whalley', value: 'Whalley' },
  { label: 'Whangamata', value: 'Whangamata' },
  { label: 'Whangarei', value: 'Whangarei' },
  { label: 'Whaplode', value: 'Whaplode' },
  { label: 'Wharton', value: 'Wharton' },
  { label: 'Wharton', value: 'Wharton' },
  { label: 'Wharton', value: 'Wharton' },
  { label: 'Wharton County', value: 'Wharton County' },
  { label: 'Whatcom County', value: 'Whatcom County' },
  { label: 'Whately', value: 'Whately' },
  { label: 'Wheat Ridge', value: 'Wheat Ridge' },
  { label: 'Wheathampstead', value: 'Wheathampstead' },
  { label: 'Wheatland', value: 'Wheatland' },
  { label: 'Wheatland', value: 'Wheatland' },
  { label: 'Wheatland County', value: 'Wheatland County' },
  { label: 'Wheatley', value: 'Wheatley' },
  { label: 'Wheatley Heights', value: 'Wheatley Heights' },
  { label: 'Wheaton', value: 'Wheaton' },
  { label: 'Wheaton', value: 'Wheaton' },
  { label: 'Wheaton', value: 'Wheaton' },
  { label: 'Wheaton Aston', value: 'Wheaton Aston' },
  { label: 'Wheeler', value: 'Wheeler' },
  { label: 'Wheeler County', value: 'Wheeler County' },
  { label: 'Wheeler County', value: 'Wheeler County' },
  { label: 'Wheeler County', value: 'Wheeler County' },
  { label: 'Wheeler County', value: 'Wheeler County' },
  { label: 'Wheeler Heights', value: 'Wheeler Heights' },
  { label: 'Wheelerfield', value: 'Wheelerfield' },
  { label: 'Wheelers Hill', value: 'Wheelers Hill' },
  { label: 'Wheelersburg', value: 'Wheelersburg' },
  { label: 'Wheeling', value: 'Wheeling' },
  { label: 'Wheeling', value: 'Wheeling' },
  { label: 'Wheldrake', value: 'Wheldrake' },
  { label: 'Whetstone', value: 'Whetstone' },
  { label: 'Whickham', value: 'Whickham' },
  { label: 'Whimple', value: 'Whimple' },
  { label: 'Whiskey Creek', value: 'Whiskey Creek' },
  { label: 'Whispering Pines', value: 'Whispering Pines' },
  { label: 'Whistler', value: 'Whistler' },
  { label: 'Whitaker', value: 'Whitaker' },
  { label: 'Whitburn', value: 'Whitburn' },
  { label: 'Whitburn', value: 'Whitburn' },
  { label: 'Whitby', value: 'Whitby' },
  { label: 'Whitchurch', value: 'Whitchurch' },
  { label: 'White Bear Lake', value: 'White Bear Lake' },
  { label: 'White Bluff', value: 'White Bluff' },
  { label: 'White Castle', value: 'White Castle' },
  { label: 'White Center', value: 'White Center' },
  { label: 'White City', value: 'White City' },
  { label: 'White City', value: 'White City' },
  { label: 'White City', value: 'White City' },
  { label: 'White City', value: 'White City' },
  { label: 'White Cloud', value: 'White Cloud' },
  { label: 'White County', value: 'White County' },
  { label: 'White County', value: 'White County' },
  { label: 'White County', value: 'White County' },
  { label: 'White County', value: 'White County' },
  { label: 'White County', value: 'White County' },
  { label: 'White Gum Valley', value: 'White Gum Valley' },
  { label: 'White Hall', value: 'White Hall' },
  { label: 'White Hall', value: 'White Hall' },
  { label: 'White Hall', value: 'White Hall' },
  { label: 'White Haven', value: 'White Haven' },
  { label: 'White Hill', value: 'White Hill' },
  { label: 'White Hills', value: 'White Hills' },
  { label: 'White Horse', value: 'White Horse' },
  { label: 'White Horses', value: 'White Horses' },
  { label: 'White House', value: 'White House' },
  { label: 'White Island Shores', value: 'White Island Shores' },
  { label: 'White Marsh', value: 'White Marsh' },
  { label: 'White Meadow Lake', value: 'White Meadow Lake' },
  { label: 'White Mountain Lake', value: 'White Mountain Lake' },
  { label: 'White Oak', value: 'White Oak' },
  { label: 'White Oak', value: 'White Oak' },
  { label: 'White Oak', value: 'White Oak' },
  { label: 'White Oak', value: 'White Oak' },
  { label: 'White Pigeon', value: 'White Pigeon' },
  { label: 'White Pine', value: 'White Pine' },
  { label: 'White Pine County', value: 'White Pine County' },
  { label: 'White Plains', value: 'White Plains' },
  { label: 'White Plains', value: 'White Plains' },
  { label: 'White River', value: 'White River' },
  { label: 'White River', value: 'White River' },
  { label: 'White River Junction', value: 'White River Junction' },
  { label: 'White Rock', value: 'White Rock' },
  { label: 'White Rock', value: 'White Rock' },
  { label: 'White Rock', value: 'White Rock' },
  { label: 'White Salmon', value: 'White Salmon' },
  { label: 'White Sands', value: 'White Sands' },
  { label: 'White Settlement', value: 'White Settlement' },
  { label: 'White Sulphur Springs', value: 'White Sulphur Springs' },
  { label: 'White Sulphur Springs', value: 'White Sulphur Springs' },
  { label: 'White Waltham', value: 'White Waltham' },
  { label: 'Whitebridge', value: 'Whitebridge' },
  { label: 'Whitecourt', value: 'Whitecourt' },
  { label: 'Whitecraig', value: 'Whitecraig' },
  { label: 'Whitefield', value: 'Whitefield' },
  { label: 'Whitefield', value: 'Whitefield' },
  { label: 'Whitefield', value: 'Whitefield' },
  { label: 'Whitefish', value: 'Whitefish' },
  { label: 'Whitefish Bay', value: 'Whitefish Bay' },
  { label: 'Whitegate', value: 'Whitegate' },
  { label: 'Whitehall', value: 'Whitehall' },
  { label: 'Whitehall', value: 'Whitehall' },
  { label: 'Whitehall', value: 'Whitehall' },
  { label: 'Whitehall', value: 'Whitehall' },
  { label: 'Whitehall', value: 'Whitehall' },
  { label: 'Whitehall', value: 'Whitehall' },
  { label: 'Whitehall', value: 'Whitehall' },
  { label: 'Whitehall Township', value: 'Whitehall Township' },
  { label: 'Whitehaven', value: 'Whitehaven' },
  { label: 'Whitehead', value: 'Whitehead' },
  { label: 'Whitehills', value: 'Whitehills' },
  { label: 'Whitehorse', value: 'Whitehorse' },
  { label: 'Whitehorse', value: 'Whitehorse' },
  { label: 'Whitehouse', value: 'Whitehouse' },
  { label: 'Whitehouse', value: 'Whitehouse' },
  { label: 'Whitehouse', value: 'Whitehouse' },
  { label: 'Whitehouse Station', value: 'Whitehouse Station' },
  { label: 'Whiteland', value: 'Whiteland' },
  { label: 'Whiteman Air Force Base', value: 'Whiteman Air Force Base' },
  { label: 'Whitemarsh Island', value: 'Whitemarsh Island' },
  { label: 'Whiteparish', value: 'Whiteparish' },
  { label: 'Whiteriver', value: 'Whiteriver' },
  { label: 'Whitesboro', value: 'Whitesboro' },
  { label: 'Whitesboro', value: 'Whitesboro' },
  { label: 'Whitesboro', value: 'Whitesboro' },
  { label: 'Whitesboro', value: 'Whitesboro' },
  { label: 'Whitesboro-Burleigh', value: 'Whitesboro-Burleigh' },
  { label: 'Whitesburg', value: 'Whitesburg' },
  { label: 'Whiteside County', value: 'Whiteside County' },
  { label: 'Whitestone', value: 'Whitestone' },
  { label: 'Whitestown', value: 'Whitestown' },
  { label: 'Whiteville', value: 'Whiteville' },
  { label: 'Whiteville', value: 'Whiteville' },
  { label: 'Whitewater', value: 'Whitewater' },
  { label: 'Whitewright', value: 'Whitewright' },
  { label: 'Whitfield', value: 'Whitfield' },
  { label: 'Whitfield', value: 'Whitfield' },
  { label: 'Whitfield', value: 'Whitfield' },
  { label: 'Whitfield County', value: 'Whitfield County' },
  { label: 'Whitfield Town', value: 'Whitfield Town' },
  { label: 'Whithorn', value: 'Whithorn' },
  { label: 'Whitianga', value: 'Whitianga' },
  { label: 'Whiting', value: 'Whiting' },
  { label: 'Whiting', value: 'Whiting' },
  { label: 'Whitinsville', value: 'Whitinsville' },
  { label: 'Whitland', value: 'Whitland' },
  { label: 'Whitley', value: 'Whitley' },
  { label: 'Whitley Bay', value: 'Whitley Bay' },
  { label: 'Whitley City', value: 'Whitley City' },
  { label: 'Whitley County', value: 'Whitley County' },
  { label: 'Whitley County', value: 'Whitley County' },
  { label: 'Whitman', value: 'Whitman' },
  { label: 'Whitman', value: 'Whitman' },
  { label: 'Whitman County', value: 'Whitman County' },
  { label: 'Whitmire', value: 'Whitmire' },
  { label: 'Whitmore Lake', value: 'Whitmore Lake' },
  { label: 'Whitmore Village', value: 'Whitmore Village' },
  { label: 'Whitney', value: 'Whitney' },
  { label: 'Whitney', value: 'Whitney' },
  { label: 'Whitstable', value: 'Whitstable' },
  { label: 'Whitsunday', value: 'Whitsunday' },
  { label: 'Whitsundays', value: 'Whitsundays' },
  { label: 'Whittier', value: 'Whittier' },
  { label: 'Whittingham', value: 'Whittingham' },
  { label: 'Whittingham', value: 'Whittingham' },
  { label: 'Whittington', value: 'Whittington' },
  { label: 'Whittington', value: 'Whittington' },
  { label: 'Whittlesea', value: 'Whittlesea' },
  { label: 'Whittlesea', value: 'Whittlesea' },
  { label: 'Whittlesey', value: 'Whittlesey' },
  { label: 'Whittlesford', value: 'Whittlesford' },
  { label: 'Whitwell', value: 'Whitwell' },
  { label: 'Whitwell', value: 'Whitwell' },
  { label: 'Whitworth', value: 'Whitworth' },
  { label: 'Whyalla', value: 'Whyalla' },
  { label: 'Whyalla Jenkins', value: 'Whyalla Jenkins' },
  { label: 'Whyalla Norrie', value: 'Whyalla Norrie' },
  { label: 'Whyalla Playford', value: 'Whyalla Playford' },
  { label: 'Whyalla Stuart', value: 'Whyalla Stuart' },
  { label: 'Whyteleafe', value: 'Whyteleafe' },
  { label: 'Wiang Sa', value: 'Wiang Sa' },
  { label: 'Wiązów', value: 'Wiązów' },
  { label: 'Wiązownica', value: 'Wiązownica' },
  { label: 'Wibaux', value: 'Wibaux' },
  { label: 'Wibaux County', value: 'Wibaux County' },
  { label: 'Wichelen', value: 'Wichelen' },
  { label: 'Wichian Buri', value: 'Wichian Buri' },
  { label: 'Wichit', value: 'Wichit' },
  { label: 'Wichita', value: 'Wichita' },
  { label: 'Wichita County', value: 'Wichita County' },
  { label: 'Wichita County', value: 'Wichita County' },
  { label: 'Wichita Falls', value: 'Wichita Falls' },
  { label: 'Wichtrach', value: 'Wichtrach' },
  { label: 'Wick', value: 'Wick' },
  { label: 'Wick', value: 'Wick' },
  { label: 'Wick', value: 'Wick' },
  { label: 'Wickede', value: 'Wickede' },
  { label: 'Wickenburg', value: 'Wickenburg' },
  { label: 'Wickepin', value: 'Wickepin' },
  { label: 'Wickerham Manor-Fisher', value: 'Wickerham Manor-Fisher' },
  { label: 'Wickford', value: 'Wickford' },
  { label: 'Wickham', value: 'Wickham' },
  { label: 'Wickham', value: 'Wickham' },
  { label: 'Wickham', value: 'Wickham' },
  { label: 'Wickham Bishops', value: 'Wickham Bishops' },
  { label: 'Wickham Market', value: 'Wickham Market' },
  { label: 'Wickliffe', value: 'Wickliffe' },
  { label: 'Wickliffe', value: 'Wickliffe' },
  { label: 'Wicklow', value: 'Wicklow' },
  { label: 'Wickwar', value: 'Wickwar' },
  { label: 'Wicomico County', value: 'Wicomico County' },
  { label: 'Widawa', value: 'Widawa' },
  { label: 'Widawa-Lipa Piotrowska-Polanowice', value: 'Widawa-Lipa Piotrowska-Polanowice' },
  { label: 'Widdern', value: 'Widdern' },
  { label: 'Widensolen', value: 'Widensolen' },
  { label: 'Wideopen', value: 'Wideopen' },
  { label: 'Widnes', value: 'Widnes' },
  { label: 'Widuchowa', value: 'Widuchowa' },
  { label: 'Więcbork', value: 'Więcbork' },
  { label: 'Wieczfnia Kościelna', value: 'Wieczfnia Kościelna' },
  { label: 'Wieda', value: 'Wieda' },
  { label: 'Wiedemar', value: 'Wiedemar' },
  { label: 'Wiedensahl', value: 'Wiedensahl' },
  { label: 'Wiedergeltingen', value: 'Wiedergeltingen' },
  { label: 'Wiederstedt', value: 'Wiederstedt' },
  { label: 'Wiedlisbach', value: 'Wiedlisbach' },
  { label: 'Wiednitz', value: 'Wiednitz' },
  { label: 'Wiefelstede', value: 'Wiefelstede' },
  { label: 'Wiehe', value: 'Wiehe' },
  { label: 'Wiehl', value: 'Wiehl' },
  { label: 'Wiek', value: 'Wiek' },
  { label: 'Większyce', value: 'Większyce' },
  { label: 'Wielbark', value: 'Wielbark' },
  { label: 'Wieleń', value: 'Wieleń' },
  { label: 'Wielgie', value: 'Wielgie' },
  { label: 'Wielichowo', value: 'Wielichowo' },
  { label: 'Wieliczka', value: 'Wieliczka' },
  { label: 'Wieliszew', value: 'Wieliszew' },
  { label: 'Wielka Nieszawka', value: 'Wielka Nieszawka' },
  { label: 'Wielka Wieś', value: 'Wielka Wieś' },
  { label: 'Wielka Wieś', value: 'Wielka Wieś' },
  { label: 'Wielki Kack', value: 'Wielki Kack' },
  { label: 'Wielkie Oczy', value: 'Wielkie Oczy' },
  { label: 'Wielopole Skrzyńskie', value: 'Wielopole Skrzyńskie' },
  { label: 'Wielowieś', value: 'Wielowieś' },
  { label: 'Wielsbeke', value: 'Wielsbeke' },
  { label: 'Wieluń', value: 'Wieluń' },
  { label: 'Wiemersdorf', value: 'Wiemersdorf' },
  { label: 'Wiener Neudorf', value: 'Wiener Neudorf' },
  { label: 'Wiener Neustadt', value: 'Wiener Neustadt' },
  { label: 'Wiener Neustadt Stadt', value: 'Wiener Neustadt Stadt' },
  { label: 'Wienersdorf', value: 'Wienersdorf' },
  { label: 'Wienhausen', value: 'Wienhausen' },
  { label: 'Wieniawa', value: 'Wieniawa' },
  { label: 'Wieprz', value: 'Wieprz' },
  { label: 'Wieprz', value: 'Wieprz' },
  { label: 'Wierden', value: 'Wierden' },
  { label: 'Wieren', value: 'Wieren' },
  { label: 'Wieringerwaard', value: 'Wieringerwaard' },
  { label: 'Wieringerwerf', value: 'Wieringerwerf' },
  { label: 'Wiernsheim', value: 'Wiernsheim' },
  { label: 'Wieruszów', value: 'Wieruszów' },
  { label: 'Wierzawice', value: 'Wierzawice' },
  { label: 'Wierzbica', value: 'Wierzbica' },
  { label: 'Wierzbinek', value: 'Wierzbinek' },
  { label: 'Wierzbna', value: 'Wierzbna' },
  { label: 'Wierzbno', value: 'Wierzbno' },
  { label: 'Wierzbno', value: 'Wierzbno' },
  { label: 'Wierzchlas', value: 'Wierzchlas' },
  { label: 'Wierzchosławice', value: 'Wierzchosławice' },
  { label: 'Wierzchosławice', value: 'Wierzchosławice' },
  { label: 'Wierzchowo', value: 'Wierzchowo' },
  { label: 'Wierzchucino', value: 'Wierzchucino' },
  { label: 'Wies', value: 'Wies' },
  { label: 'Wiesa', value: 'Wiesa' },
  { label: 'Wiesau', value: 'Wiesau' },
  { label: 'Wiesbaden', value: 'Wiesbaden' },
  { label: 'Wieselburg', value: 'Wieselburg' },
  { label: 'Wiesen', value: 'Wiesen' },
  { label: 'Wiesen', value: 'Wiesen' },
  { label: 'Wiesenau', value: 'Wiesenau' },
  { label: 'Wiesenbach', value: 'Wiesenbach' },
  { label: 'Wiesenburg', value: 'Wiesenburg' },
  { label: 'Wiesendangen / Wiesendangen (Dorf)', value: 'Wiesendangen / Wiesendangen (Dorf)' },
  { label: 'Wiesenfelden', value: 'Wiesenfelden' },
  { label: 'Wiesensteig', value: 'Wiesensteig' },
  { label: 'Wiesent', value: 'Wiesent' },
  { label: 'Wiesenthau', value: 'Wiesenthau' },
  { label: 'Wiesentheid', value: 'Wiesentheid' },
  { label: 'Wiesfleck', value: 'Wiesfleck' },
  { label: 'Wiesing', value: 'Wiesing' },
  { label: 'Wiesloch', value: 'Wiesloch' },
  { label: 'Wiesmath', value: 'Wiesmath' },
  { label: 'Wiesmoor', value: 'Wiesmoor' },
  { label: 'Wiesthal', value: 'Wiesthal' },
  { label: 'Wiesviller', value: 'Wiesviller' },
  { label: 'Wieszowa', value: 'Wieszowa' },
  { label: 'Wietmarschen', value: 'Wietmarschen' },
  { label: 'Wietrzychowice', value: 'Wietrzychowice' },
  { label: 'Wietze', value: 'Wietze' },
  { label: 'Wietzen', value: 'Wietzen' },
  { label: 'Wietzendorf', value: 'Wietzendorf' },
  { label: 'Wigan', value: 'Wigan' },
  { label: 'Wiggensbach', value: 'Wiggensbach' },
  { label: 'Wiggins', value: 'Wiggins' },
  { label: 'Wigmore', value: 'Wigmore' },
  { label: 'Wignehies', value: 'Wignehies' },
  { label: 'Wigoltingen', value: 'Wigoltingen' },
  { label: 'Wigston Magna', value: 'Wigston Magna' },
  { label: 'Wigton', value: 'Wigton' },
  { label: 'Wihr-Au-Val', value: 'Wihr-Au-Val' },
  { label: 'Wijbosch', value: 'Wijbosch' },
  { label: 'Wijchen', value: 'Wijchen' },
  { label: 'Wijdemeren', value: 'Wijdemeren' },
  { label: 'Wijdenes', value: 'Wijdenes' },
  { label: 'Wijdewormer', value: 'Wijdewormer' },
  { label: 'Wijewo', value: 'Wijewo' },
  { label: 'Wijhe', value: 'Wijhe' },
  { label: 'Wijk Aan Zee', value: 'Wijk Aan Zee' },
  { label: 'Wijk Bij Duurstede', value: 'Wijk Bij Duurstede' },
  { label: 'Wijlre', value: 'Wijlre' },
  { label: 'Wijnandsrade', value: 'Wijnandsrade' },
  { label: 'Wijnegem', value: 'Wijnegem' },
  { label: 'Wijnjewoude', value: 'Wijnjewoude' },
  { label: 'Wikon', value: 'Wikon' },
  { label: 'Wil', value: 'Wil' },
  { label: 'Wila', value: 'Wila' },
  { label: 'Wilamowice', value: 'Wilamowice' },
  { label: 'Wilanów', value: 'Wilanów' },
  { label: 'Wilbarger County', value: 'Wilbarger County' },
  { label: 'Wilber', value: 'Wilber' },
  { label: 'Wilberforce', value: 'Wilberforce' },
  { label: 'Wilberforce', value: 'Wilberforce' },
  { label: 'Wilberfoss', value: 'Wilberfoss' },
  { label: 'Wilbraham', value: 'Wilbraham' },
  { label: 'Wilburgstetten', value: 'Wilburgstetten' },
  { label: 'Wilburton', value: 'Wilburton' },
  { label: 'Wilburton', value: 'Wilburton' },
  { label: 'Wilchingen', value: 'Wilchingen' },
  { label: 'Wilcox County', value: 'Wilcox County' },
  { label: 'Wilcox County', value: 'Wilcox County' },
  { label: 'Wilcza', value: 'Wilcza' },
  { label: 'Wilczogóra', value: 'Wilczogóra' },
  { label: 'Wilczyce', value: 'Wilczyce' },
  { label: 'Wilczyce', value: 'Wilczyce' },
  { label: 'Wilczyn', value: 'Wilczyn' },
  { label: 'Wild Peach Village', value: 'Wild Peach Village' },
  { label: 'Wild Rose', value: 'Wild Rose' },
  { label: 'Wildalpen', value: 'Wildalpen' },
  { label: 'Wildberg', value: 'Wildberg' },
  { label: 'Wildemann', value: 'Wildemann' },
  { label: 'Wildenberg', value: 'Wildenberg' },
  { label: 'Wildenfels', value: 'Wildenfels' },
  { label: 'Wildenhain', value: 'Wildenhain' },
  { label: 'Wilder', value: 'Wilder' },
  { label: 'Wilder', value: 'Wilder' },
  { label: 'Wilder', value: 'Wilder' },
  { label: 'Wildermieming', value: 'Wildermieming' },
  { label: 'Wilderness Rim', value: 'Wilderness Rim' },
  { label: 'Wilderswil', value: 'Wilderswil' },
  { label: 'Wildervank', value: 'Wildervank' },
  { label: 'Wildeshausen', value: 'Wildeshausen' },
  { label: 'Wildflecken', value: 'Wildflecken' },
  { label: 'Wildhaus', value: 'Wildhaus' },
  { label: 'Wildomar', value: 'Wildomar' },
  { label: 'Wildon', value: 'Wildon' },
  { label: 'Wildpoldsried', value: 'Wildpoldsried' },
  { label: 'Wildschönau', value: 'Wildschönau' },
  { label: 'Wildsteig', value: 'Wildsteig' },
  { label: 'Wildwood', value: 'Wildwood' },
  { label: 'Wildwood', value: 'Wildwood' },
  { label: 'Wildwood', value: 'Wildwood' },
  { label: 'Wildwood', value: 'Wildwood' },
  { label: 'Wildwood', value: 'Wildwood' },
  { label: 'Wildwood Crest', value: 'Wildwood Crest' },
  { label: 'Wildwood Lake', value: 'Wildwood Lake' },
  { label: 'Wiley Ford', value: 'Wiley Ford' },
  { label: 'Wiley Park', value: 'Wiley Park' },
  { label: 'Wilfersdorf', value: 'Wilfersdorf' },
  { label: 'Wilfleinsdorf', value: 'Wilfleinsdorf' },
  { label: 'Wilga', value: 'Wilga' },
  { label: 'Wilgartswiesen', value: 'Wilgartswiesen' },
  { label: 'Wilhelminadorp', value: 'Wilhelminadorp' },
  { label: 'Wilhelmsburg', value: 'Wilhelmsburg' },
  { label: 'Wilhelmsdorf', value: 'Wilhelmsdorf' },
  { label: 'Wilhelmsdorf', value: 'Wilhelmsdorf' },
  { label: 'Wilhelmsfeld', value: 'Wilhelmsfeld' },
  { label: 'Wilhelmshaven', value: 'Wilhelmshaven' },
  { label: 'Wilhelmsruh', value: 'Wilhelmsruh' },
  { label: 'Wilhelmstadt', value: 'Wilhelmstadt' },
  { label: 'Wilhelmsthal', value: 'Wilhelmsthal' },
  { label: 'Wilhermsdorf', value: 'Wilhermsdorf' },
  { label: 'Wilkau-Haßlau', value: 'Wilkau-Haßlau' },
  { label: 'Wilkes County', value: 'Wilkes County' },
  { label: 'Wilkes County', value: 'Wilkes County' },
  { label: 'Wilkes-Barre', value: 'Wilkes-Barre' },
  { label: 'Wilkesboro', value: 'Wilkesboro' },
  { label: 'Wilkie', value: 'Wilkie' },
  { label: 'Wilkin County', value: 'Wilkin County' },
  { label: 'Wilkinsburg', value: 'Wilkinsburg' },
  { label: 'Wilkinson County', value: 'Wilkinson County' },
  { label: 'Wilkinson County', value: 'Wilkinson County' },
  { label: 'Wilkinson Heights', value: 'Wilkinson Heights' },
  { label: 'Wilkołaz', value: 'Wilkołaz' },
  { label: 'Wilków', value: 'Wilków' },
  { label: 'Wilków', value: 'Wilków' },
  { label: 'Wilków', value: 'Wilków' },
  { label: 'Wilkowice', value: 'Wilkowice' },
  { label: 'Wilkowice', value: 'Wilkowice' },
  { label: 'Will County', value: 'Will County' },
  { label: 'Willacoochee', value: 'Willacoochee' },
  { label: 'Willacy County', value: 'Willacy County' },
  { label: 'Willagee', value: 'Willagee' },
  { label: 'Willamina', value: 'Willamina' },
  { label: 'Willand', value: 'Willand' },
  { label: 'Willanzheim', value: 'Willanzheim' },
  { label: 'Willard', value: 'Willard' },
  { label: 'Willard', value: 'Willard' },
  { label: 'Willard', value: 'Willard' },
  { label: 'Willaston', value: 'Willaston' },
  { label: 'Willaston', value: 'Willaston' },
  { label: 'Willcox', value: 'Willcox' },
  { label: 'Willebadessen', value: 'Willebadessen' },
  { label: 'Willebroek', value: 'Willebroek' },
  { label: 'Willems', value: 'Willems' },
  { label: 'Willendorf Am Steinfelde', value: 'Willendorf Am Steinfelde' },
  { label: 'Willenhall', value: 'Willenhall' },
  { label: 'Willerwald', value: 'Willerwald' },
  { label: 'Willetton', value: 'Willetton' },
  { label: 'Williams', value: 'Williams' },
  { label: 'Williams', value: 'Williams' },
  { label: 'Williams', value: 'Williams' },
  { label: 'Williams', value: 'Williams' },
  { label: 'Williams Bay', value: 'Williams Bay' },
  { label: 'Williams County', value: 'Williams County' },
  { label: 'Williams County', value: 'Williams County' },
  { label: 'Williams Lake', value: 'Williams Lake' },
  { label: 'Williams Landing', value: 'Williams Landing' },
  { label: 'Williamsburg', value: 'Williamsburg' },
  { label: 'Williamsburg', value: 'Williamsburg' },
  { label: 'Williamsburg', value: 'Williamsburg' },
  { label: 'Williamsburg', value: 'Williamsburg' },
  { label: 'Williamsburg', value: 'Williamsburg' },
  { label: 'Williamsburg', value: 'Williamsburg' },
  { label: 'Williamsburg', value: 'Williamsburg' },
  { label: 'Williamsburg', value: 'Williamsburg' },
  { label: 'Williamsburg County', value: 'Williamsburg County' },
  { label: 'Williamsfield', value: 'Williamsfield' },
  { label: 'Williamsfield', value: 'Williamsfield' },
  { label: 'Williamson', value: 'Williamson' },
  { label: 'Williamson', value: 'Williamson' },
  { label: 'Williamson', value: 'Williamson' },
  { label: 'Williamson County', value: 'Williamson County' },
  { label: 'Williamson County', value: 'Williamson County' },
  { label: 'Williamson County', value: 'Williamson County' },
  { label: 'Williamsport', value: 'Williamsport' },
  { label: 'Williamsport', value: 'Williamsport' },
  { label: 'Williamsport', value: 'Williamsport' },
  { label: 'Williamsport', value: 'Williamsport' },
  { label: 'Williamston', value: 'Williamston' },
  { label: 'Williamston', value: 'Williamston' },
  { label: 'Williamston', value: 'Williamston' },
  { label: 'Williamstown', value: 'Williamstown' },
  { label: 'Williamstown', value: 'Williamstown' },
  { label: 'Williamstown', value: 'Williamstown' },
  { label: 'Williamstown', value: 'Williamstown' },
  { label: 'Williamstown', value: 'Williamstown' },
  { label: 'Williamstown', value: 'Williamstown' },
  { label: 'Williamstown', value: 'Williamstown' },
  { label: 'Williamstown', value: 'Williamstown' },
  { label: 'Williamstown North', value: 'Williamstown North' },
  { label: 'Williamsville', value: 'Williamsville' },
  { label: 'Williamsville', value: 'Williamsville' },
  { label: 'Williamtown', value: 'Williamtown' },
  { label: 'Willich', value: 'Willich' },
  { label: 'Willimantic', value: 'Willimantic' },
  { label: 'Willingboro', value: 'Willingboro' },
  { label: 'Willingen', value: 'Willingen' },
  { label: 'Willingham', value: 'Willingham' },
  { label: 'Willingshausen', value: 'Willingshausen' },
  { label: 'Willington', value: 'Willington' },
  { label: 'Willis', value: 'Willis' },
  { label: 'Willisau', value: 'Willisau' },
  { label: 'Willisau District', value: 'Willisau District' },
  { label: 'Williston', value: 'Williston' },
  { label: 'Williston', value: 'Williston' },
  { label: 'Williston', value: 'Williston' },
  { label: 'Williston', value: 'Williston' },
  { label: 'Williston Highlands', value: 'Williston Highlands' },
  { label: 'Williston Park', value: 'Williston Park' },
  { label: 'Williton', value: 'Williton' },
  { label: 'Willits', value: 'Willits' },
  { label: 'Willmar', value: 'Willmar' },
  { label: 'Willmering', value: 'Willmering' },
  { label: 'Willmot', value: 'Willmot' },
  { label: 'Willoughby', value: 'Willoughby' },
  { label: 'Willoughby', value: 'Willoughby' },
  { label: 'Willoughby East', value: 'Willoughby East' },
  { label: 'Willoughby Hills', value: 'Willoughby Hills' },
  { label: 'Willow', value: 'Willow' },
  { label: 'Willow Creek', value: 'Willow Creek' },
  { label: 'Willow Grove', value: 'Willow Grove' },
  { label: 'Willow Oak', value: 'Willow Oak' },
  { label: 'Willow Park', value: 'Willow Park' },
  { label: 'Willow Springs', value: 'Willow Springs' },
  { label: 'Willow Springs', value: 'Willow Springs' },
  { label: 'Willow Street', value: 'Willow Street' },
  { label: 'Willow Vale', value: 'Willow Vale' },
  { label: 'Willow Valley', value: 'Willow Valley' },
  { label: 'Willowbank', value: 'Willowbank' },
  { label: 'Willowbrook', value: 'Willowbrook' },
  { label: 'Willowbrook', value: 'Willowbrook' },
  { label: 'Willowbrook', value: 'Willowbrook' },
  { label: 'Willowdale', value: 'Willowdale' },
  { label: 'Willowdene', value: 'Willowdene' },
  { label: 'Willowick', value: 'Willowick' },
  { label: 'Willowmore', value: 'Willowmore' },
  { label: 'Willows', value: 'Willows' },
  { label: 'Wills Point', value: 'Wills Point' },
  { label: 'Willstätt', value: 'Willstätt' },
  { label: 'Willunga', value: 'Willunga' },
  { label: 'Wilmcote', value: 'Wilmcote' },
  { label: 'Wilmer', value: 'Wilmer' },
  { label: 'Wilmerding', value: 'Wilmerding' },
  { label: 'Wilmersdorf', value: 'Wilmersdorf' },
  { label: 'Wilmette', value: 'Wilmette' },
  { label: 'Wilmington', value: 'Wilmington' },
  { label: 'Wilmington', value: 'Wilmington' },
  { label: 'Wilmington', value: 'Wilmington' },
  { label: 'Wilmington', value: 'Wilmington' },
  { label: 'Wilmington', value: 'Wilmington' },
  { label: 'Wilmington', value: 'Wilmington' },
  { label: 'Wilmington Island', value: 'Wilmington Island' },
  { label: 'Wilmington Manor', value: 'Wilmington Manor' },
  { label: 'Wilmore', value: 'Wilmore' },
  { label: 'Wilmot', value: 'Wilmot' },
  { label: 'Wilmslow', value: 'Wilmslow' },
  { label: 'Wilnis', value: 'Wilnis' },
  { label: 'Wilnsdorf', value: 'Wilnsdorf' },
  { label: 'Wilsden', value: 'Wilsden' },
  { label: 'Wilsdruff', value: 'Wilsdruff' },
  { label: 'Wilson', value: 'Wilson' },
  { label: 'Wilson', value: 'Wilson' },
  { label: 'Wilson', value: 'Wilson' },
  { label: 'Wilson', value: 'Wilson' },
  { label: 'Wilson', value: 'Wilson' },
  { label: 'Wilson', value: 'Wilson' },
  { label: 'Wilson County', value: 'Wilson County' },
  { label: 'Wilson County', value: 'Wilson County' },
  { label: 'Wilson County', value: 'Wilson County' },
  { label: 'Wilson County', value: 'Wilson County' },
  { label: 'Wilson-Conococheague', value: 'Wilson-Conococheague' },
  { label: 'Wilsons Mills', value: 'Wilsons Mills' },
  { label: 'Wilsons Run', value: 'Wilsons Run' },
  { label: 'Wilsonton', value: 'Wilsonton' },
  { label: 'Wilsonton Heights', value: 'Wilsonton Heights' },
  { label: 'Wilsonville', value: 'Wilsonville' },
  { label: 'Wilsonville', value: 'Wilsonville' },
  { label: 'Wilstead', value: 'Wilstead' },
  { label: 'Wilstedt', value: 'Wilstedt' },
  { label: 'Wilster', value: 'Wilster' },
  { label: 'Wilston', value: 'Wilston' },
  { label: 'Wilsum', value: 'Wilsum' },
  { label: 'Wilten', value: 'Wilten' },
  { label: 'Wilthen', value: 'Wilthen' },
  { label: 'Wiltingen', value: 'Wiltingen' },
  { label: 'Wilton', value: 'Wilton' },
  { label: 'Wilton', value: 'Wilton' },
  { label: 'Wilton', value: 'Wilton' },
  { label: 'Wilton', value: 'Wilton' },
  { label: 'Wilton', value: 'Wilton' },
  { label: 'Wilton', value: 'Wilton' },
  { label: 'Wilton', value: 'Wilton' },
  { label: 'Wilton Gardens/ Rema', value: 'Wilton Gardens/ Rema' },
  { label: 'Wilton Manors', value: 'Wilton Manors' },
  { label: 'Wiltons Yard/Grave Yard', value: 'Wiltons Yard/Grave Yard' },
  { label: 'Wiltshire', value: 'Wiltshire' },
  { label: 'Wiltz', value: 'Wiltz' },
  { label: 'Wiluna', value: 'Wiluna' },
  { label: 'Wilwerwiltz', value: 'Wilwerwiltz' },
  { label: 'Wima', value: 'Wima' },
  { label: 'Wimauma', value: 'Wimauma' },
  { label: 'Wimberley', value: 'Wimberley' },
  { label: 'Wimblington', value: 'Wimblington' },
  { label: 'Wimborne Minster', value: 'Wimborne Minster' },
  { label: 'Wimereux', value: 'Wimereux' },
  { label: 'Wimille', value: 'Wimille' },
  { label: 'Wimmelburg', value: 'Wimmelburg' },
  { label: 'Wimmenau', value: 'Wimmenau' },
  { label: 'Wimmenum', value: 'Wimmenum' },
  { label: 'Wimmis', value: 'Wimmis' },
  { label: 'Wimpassing', value: 'Wimpassing' },
  { label: 'Wimpassing An Der Leitha', value: 'Wimpassing An Der Leitha' },
  { label: 'Wimpassing Im Schwarzatale', value: 'Wimpassing Im Schwarzatale' },
  { label: 'Wimsbach', value: 'Wimsbach' },
  { label: 'Wimsheim', value: 'Wimsheim' },
  { label: 'Winamac', value: 'Winamac' },
  { label: 'Winburg', value: 'Winburg' },
  { label: 'Wincanton', value: 'Wincanton' },
  { label: 'Winchburgh', value: 'Winchburgh' },
  { label: 'Winchcombe', value: 'Winchcombe' },
  { label: 'Winchelsea', value: 'Winchelsea' },
  { label: 'Winchelsea Beach', value: 'Winchelsea Beach' },
  { label: 'Winchendon', value: 'Winchendon' },
  { label: 'Wincheringen', value: 'Wincheringen' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester', value: 'Winchester' },
  { label: 'Winchester Center', value: 'Winchester Center' },
  { label: 'Wincrange', value: 'Wincrange' },
  { label: 'Wind Gap', value: 'Wind Gap' },
  { label: 'Wind Lake', value: 'Wind Lake' },
  { label: 'Wind Point', value: 'Wind Point' },
  { label: 'Windach', value: 'Windach' },
  { label: 'Windale', value: 'Windale' },
  { label: 'Windang', value: 'Windang' },
  { label: 'Windaroo', value: 'Windaroo' },
  { label: 'Windber', value: 'Windber' },
  { label: 'Windberg', value: 'Windberg' },
  { label: 'Windcrest', value: 'Windcrest' },
  { label: 'Windeby', value: 'Windeby' },
  { label: 'Windelsbach', value: 'Windelsbach' },
  { label: 'Windemere', value: 'Windemere' },
  { label: 'Winden', value: 'Winden' },
  { label: 'Winden Am See', value: 'Winden Am See' },
  { label: 'Winder', value: 'Winder' },
  { label: 'Windermere', value: 'Windermere' },
  { label: 'Windermere', value: 'Windermere' },
  { label: 'Windesheim', value: 'Windesheim' },
  { label: 'Windhaag Bei Freistadt', value: 'Windhaag Bei Freistadt' },
  { label: 'Windhaag Bei Perg', value: 'Windhaag Bei Perg' },
  { label: 'Windhag', value: 'Windhag' },
  { label: 'Windhagen', value: 'Windhagen' },
  { label: 'Windham', value: 'Windham' },
  { label: 'Windham', value: 'Windham' },
  { label: 'Windham', value: 'Windham' },
  { label: 'Windham County', value: 'Windham County' },
  { label: 'Windham County', value: 'Windham County' },
  { label: 'Windhausen', value: 'Windhausen' },
  { label: 'Windhoek', value: 'Windhoek' },
  { label: 'Windigsteig', value: 'Windigsteig' },
  { label: 'Windisch', value: 'Windisch' },
  { label: 'Windischeschenbach', value: 'Windischeschenbach' },
  { label: 'Windischgarsten', value: 'Windischgarsten' },
  { label: 'Windischleuba', value: 'Windischleuba' },
  { label: 'Windlesham', value: 'Windlesham' },
  { label: 'Windom', value: 'Windom' },
  { label: 'Window Rock', value: 'Window Rock' },
  { label: 'Windradyne', value: 'Windradyne' },
  { label: 'Windsbach', value: 'Windsbach' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Windsor Castle', value: 'Windsor Castle' },
  { label: 'Windsor County', value: 'Windsor County' },
  { label: 'Windsor Downs', value: 'Windsor Downs' },
  { label: 'Windsor Forest', value: 'Windsor Forest' },
  { label: 'Windsor Gardens', value: 'Windsor Gardens' },
  { label: 'Windsor Heights', value: 'Windsor Heights' },
  { label: 'Windsor Locks', value: 'Windsor Locks' },
  { label: 'Windwardside', value: 'Windwardside' },
  { label: 'Windwardside', value: 'Windwardside' },
  { label: 'Windy Hills', value: 'Windy Hills' },
  { label: 'Windygates', value: 'Windygates' },
  { label: 'Wines', value: 'Wines' },
  { label: 'Wines', value: 'Wines' },
  { label: 'Wines', value: 'Wines' },
  { label: 'Winfield', value: 'Winfield' },
  { label: 'Winfield', value: 'Winfield' },
  { label: 'Winfield', value: 'Winfield' },
  { label: 'Winfield', value: 'Winfield' },
  { label: 'Winfield', value: 'Winfield' },
  { label: 'Winfield', value: 'Winfield' },
  { label: 'Winfield', value: 'Winfield' },
  { label: 'Winfield', value: 'Winfield' },
  { label: 'Winford', value: 'Winford' },
  { label: 'Wing', value: 'Wing' },
  { label: 'Wingate', value: 'Wingate' },
  { label: 'Wingate', value: 'Wingate' },
  { label: 'Wingecarribee', value: 'Wingecarribee' },
  { label: 'Wingene', value: 'Wingene' },
  { label: 'Wingen-Sur-Moder', value: 'Wingen-Sur-Moder' },
  { label: 'Wingerode', value: 'Wingerode' },
  { label: 'Wingersheim', value: 'Wingersheim' },
  { label: 'Wingerworth', value: 'Wingerworth' },
  { label: 'Wingham', value: 'Wingham' },
  { label: 'Wingham', value: 'Wingham' },
  { label: 'Wingham', value: 'Wingham' },
  { label: 'Wingles', value: 'Wingles' },
  { label: 'Wingrave', value: 'Wingrave' },
  { label: 'Wingst', value: 'Wingst' },
  { label: 'Winhöring', value: 'Winhöring' },
  { label: 'Winifreda', value: 'Winifreda' },
  { label: 'Wink', value: 'Wink' },
  { label: 'Winkel', value: 'Winkel' },
  { label: 'Winkelhaid', value: 'Winkelhaid' },
  { label: 'Winkewijert', value: 'Winkewijert' },
  { label: 'Winklarn', value: 'Winklarn' },
  { label: 'Winklarn', value: 'Winklarn' },
  { label: 'Winkleigh', value: 'Winkleigh' },
  { label: 'Winkler', value: 'Winkler' },
  { label: 'Winkler County', value: 'Winkler County' },
  { label: 'Winklern', value: 'Winklern' },
  { label: 'Winklern Bei Oberwölz', value: 'Winklern Bei Oberwölz' },
  { label: 'Winlock', value: 'Winlock' },
  { label: 'Winmalee', value: 'Winmalee' },
  { label: 'Winn Parish', value: 'Winn Parish' },
  { label: 'Winneba', value: 'Winneba' },
  { label: 'Winnebago', value: 'Winnebago' },
  { label: 'Winnebago', value: 'Winnebago' },
  { label: 'Winnebago County', value: 'Winnebago County' },
  { label: 'Winnebago County', value: 'Winnebago County' },
  { label: 'Winnebago County', value: 'Winnebago County' },
  { label: 'Winneconne', value: 'Winneconne' },
  { label: 'Winnemucca', value: 'Winnemucca' },
  { label: 'Winnenden', value: 'Winnenden' },
  { label: 'Winner', value: 'Winner' },
  { label: 'Winneshiek County', value: 'Winneshiek County' },
  { label: 'Winnetka', value: 'Winnetka' },
  { label: 'Winnett', value: 'Winnett' },
  { label: 'Winnezeele', value: 'Winnezeele' },
  { label: 'Winnfield', value: 'Winnfield' },
  { label: 'Winnica', value: 'Winnica' },
  { label: 'Winnie', value: 'Winnie' },
  { label: 'Winningen', value: 'Winningen' },
  { label: 'Winnipeg', value: 'Winnipeg' },
  { label: 'Winnsboro', value: 'Winnsboro' },
  { label: 'Winnsboro', value: 'Winnsboro' },
  { label: 'Winnsboro', value: 'Winnsboro' },
  { label: 'Winnsboro Mills', value: 'Winnsboro Mills' },
  { label: 'Winnweiler', value: 'Winnweiler' },
  { label: 'Winona', value: 'Winona' },
  { label: 'Winona', value: 'Winona' },
  { label: 'Winona', value: 'Winona' },
  { label: 'Winona County', value: 'Winona County' },
  { label: 'Winona Lake', value: 'Winona Lake' },
  { label: 'Winooski', value: 'Winooski' },
  { label: 'Winschoten', value: 'Winschoten' },
  { label: 'Winscombe', value: 'Winscombe' },
  { label: 'Winseler', value: 'Winseler' },
  { label: 'Winsen', value: 'Winsen' },
  { label: 'Winsford', value: 'Winsford' },
  { label: 'Wińsko', value: 'Wińsko' },
  { label: 'Winslow', value: 'Winslow' },
  { label: 'Winslow', value: 'Winslow' },
  { label: 'Winslow', value: 'Winslow' },
  { label: 'Winsted', value: 'Winsted' },
  { label: 'Winsted', value: 'Winsted' },
  { label: 'Winston', value: 'Winston' },
  { label: 'Winston', value: 'Winston' },
  { label: 'Winston', value: 'Winston' },
  { label: 'Winston County', value: 'Winston County' },
  { label: 'Winston County', value: 'Winston County' },
  { label: 'Winston Hills', value: 'Winston Hills' },
  { label: 'Winston-Salem', value: 'Winston-Salem' },
  { label: 'Winsum', value: 'Winsum' },
  { label: 'Winter Beach', value: 'Winter Beach' },
  { label: 'Winter Garden', value: 'Winter Garden' },
  { label: 'Winter Gardens', value: 'Winter Gardens' },
  { label: 'Winter Haven', value: 'Winter Haven' },
  { label: 'Winter Park', value: 'Winter Park' },
  { label: 'Winter Springs', value: 'Winter Springs' },
  { label: 'Winterbach', value: 'Winterbach' },
  { label: 'Winterberg', value: 'Winterberg' },
  { label: 'Winterbourne', value: 'Winterbourne' },
  { label: 'Winterhausen', value: 'Winterhausen' },
  { label: 'Winterhude', value: 'Winterhude' },
  { label: 'Winterlingen', value: 'Winterlingen' },
  { label: 'Winterport', value: 'Winterport' },
  { label: 'Winters', value: 'Winters' },
  { label: 'Winters', value: 'Winters' },
  { label: 'Wintersdorf', value: 'Wintersdorf' },
  { label: 'Winterset', value: 'Winterset' },
  { label: 'Wintersville', value: 'Wintersville' },
  { label: 'Winterswijk', value: 'Winterswijk' },
  { label: 'Winterthur', value: 'Winterthur' },
  { label: 'Winterton', value: 'Winterton' },
  { label: 'Winterville', value: 'Winterville' },
  { label: 'Winterville', value: 'Winterville' },
  { label: 'Winthrop', value: 'Winthrop' },
  { label: 'Winthrop', value: 'Winthrop' },
  { label: 'Winthrop', value: 'Winthrop' },
  { label: 'Winthrop', value: 'Winthrop' },
  { label: 'Winthrop Harbor', value: 'Winthrop Harbor' },
  { label: 'Winton', value: 'Winton' },
  { label: 'Winton', value: 'Winton' },
  { label: 'Winton', value: 'Winton' },
  { label: 'Winton', value: 'Winton' },
  { label: 'Wintzenheim', value: 'Wintzenheim' },
  { label: 'Winwick', value: 'Winwick' },
  { label: 'Winzendorf', value: 'Winzendorf' },
  { label: 'Winzer', value: 'Winzer' },
  { label: 'Wipfeld', value: 'Wipfeld' },
  { label: 'Wippenham', value: 'Wippenham' },
  { label: 'Wipperdorf', value: 'Wipperdorf' },
  { label: 'Wipperfürth', value: 'Wipperfürth' },
  { label: 'Wippra', value: 'Wippra' },
  { label: 'Wiradesa', value: 'Wiradesa' },
  { label: 'Wirdum', value: 'Wirdum' },
  { label: 'Wirdum', value: 'Wirdum' },
  { label: 'Wirefence', value: 'Wirefence' },
  { label: 'Wirges', value: 'Wirges' },
  { label: 'Wiri', value: 'Wiri' },
  { label: 'Wirksworth', value: 'Wirksworth' },
  { label: 'Wirsberg', value: 'Wirsberg' },
  { label: 'Wirt County', value: 'Wirt County' },
  { label: 'Wisbech', value: 'Wisbech' },
  { label: 'Wiscasset', value: 'Wiscasset' },
  { label: 'Wisch', value: 'Wisch' },
  { label: 'Wisches', value: 'Wisches' },
  { label: 'Wischhafen', value: 'Wischhafen' },
  { label: 'Wisconsin Dells', value: 'Wisconsin Dells' },
  { label: 'Wisconsin Rapids', value: 'Wisconsin Rapids' },
  { label: 'Wise', value: 'Wise' },
  { label: 'Wise County', value: 'Wise County' },
  { label: 'Wise County', value: 'Wise County' },
  { label: 'Wiset Chaichan', value: 'Wiset Chaichan' },
  { label: 'Wishart', value: 'Wishart' },
  { label: 'Wishaw', value: 'Wishaw' },
  { label: 'Wiskitki', value: 'Wiskitki' },
  { label: 'Wisła', value: 'Wisła' },
  { label: 'Wiślica', value: 'Wiślica' },
  { label: 'Wismar', value: 'Wismar' },
  { label: 'Wisner', value: 'Wisner' },
  { label: 'Wiśniew', value: 'Wiśniew' },
  { label: 'Wiśniewo', value: 'Wiśniewo' },
  { label: 'Wiśniowa', value: 'Wiśniowa' },
  { label: 'Wiśniowa', value: 'Wiśniowa' },
  { label: 'Wispl', value: 'Wispl' },
  { label: 'Wissant', value: 'Wissant' },
  { label: 'Wisselaar', value: 'Wisselaar' },
  { label: 'Wissembourg', value: 'Wissembourg' },
  { label: 'Wissen', value: 'Wissen' },
  { label: 'Wissenkerke', value: 'Wissenkerke' },
  { label: 'Wissous', value: 'Wissous' },
  { label: 'Wistedt', value: 'Wistedt' },
  { label: 'Wister', value: 'Wister' },
  { label: 'Wisznice', value: 'Wisznice' },
  { label: 'Witanowice', value: 'Witanowice' },
  { label: 'Witaszyce', value: 'Witaszyce' },
  { label: 'Witbank', value: 'Witbank' },
  { label: 'Witchford', value: 'Witchford' },
  { label: 'Witham', value: 'Witham' },
  { label: 'Withamsville', value: 'Withamsville' },
  { label: 'Withcott', value: 'Withcott' },
  { label: 'Witheridge', value: 'Witheridge' },
  { label: 'Withernsea', value: 'Withernsea' },
  { label: 'Withers', value: 'Withers' },
  { label: 'Witkowice', value: 'Witkowice' },
  { label: 'Witkowo', value: 'Witkowo' },
  { label: 'Witley', value: 'Witley' },
  { label: 'Witmarsum', value: 'Witmarsum' },
  { label: 'Witmarsum', value: 'Witmarsum' },
  { label: 'Witney', value: 'Witney' },
  { label: 'Witnica', value: 'Witnica' },
  { label: 'Witonia', value: 'Witonia' },
  { label: 'Witry-Lès-Reims', value: 'Witry-Lès-Reims' },
  { label: 'Witta', value: 'Witta' },
  { label: 'Wittelsheim', value: 'Wittelsheim' },
  { label: 'Wittelshofen', value: 'Wittelshofen' },
  { label: 'Witten', value: 'Witten' },
  { label: 'Wittenau', value: 'Wittenau' },
  { label: 'Wittenbach', value: 'Wittenbach' },
  { label: 'Wittenberg', value: 'Wittenberg' },
  { label: 'Wittenberg', value: 'Wittenberg' },
  { label: 'Wittenberge', value: 'Wittenberge' },
  { label: 'Wittenburg', value: 'Wittenburg' },
  { label: 'Wittenförden', value: 'Wittenförden' },
  { label: 'Wittenhagen', value: 'Wittenhagen' },
  { label: 'Wittenheim', value: 'Wittenheim' },
  { label: 'Witterda', value: 'Witterda' },
  { label: 'Wittering', value: 'Wittering' },
  { label: 'Wittgensdorf', value: 'Wittgensdorf' },
  { label: 'Wittichenau', value: 'Wittichenau' },
  { label: 'Wittingen', value: 'Wittingen' },
  { label: 'Wittisheim', value: 'Wittisheim' },
  { label: 'Wittislingen', value: 'Wittislingen' },
  { label: 'Wittlich', value: 'Wittlich' },
  { label: 'Wittmar', value: 'Wittmar' },
  { label: 'Wittmund', value: 'Wittmund' },
  { label: 'Wittnau', value: 'Wittnau' },
  { label: 'Wittnau', value: 'Wittnau' },
  { label: 'Witton Gilbert', value: 'Witton Gilbert' },
  { label: 'Wittorf', value: 'Wittorf' },
  { label: 'Wittstock', value: 'Wittstock' },
  { label: 'Witu', value: 'Witu' },
  { label: 'Witzenhausen', value: 'Witzenhausen' },
  { label: 'Witzhave', value: 'Witzhave' },
  { label: 'Witzmannsberg', value: 'Witzmannsberg' },
  { label: 'Wiveliscombe', value: 'Wiveliscombe' },
  { label: 'Wivelsfield Green', value: 'Wivelsfield Green' },
  { label: 'Wivenhoe', value: 'Wivenhoe' },
  { label: 'Wiwilí', value: 'Wiwilí' },
  { label: 'Wixom', value: 'Wixom' },
  { label: 'Wizernes', value: 'Wizernes' },
  { label: 'Wizna', value: 'Wizna' },
  { label: 'Władysławów', value: 'Władysławów' },
  { label: 'Władysławowo', value: 'Władysławowo' },
  { label: 'Wleń', value: 'Wleń' },
  { label: 'Włochy', value: 'Włochy' },
  { label: 'Włocławek', value: 'Włocławek' },
  { label: 'Włodawa', value: 'Włodawa' },
  { label: 'Włodowice', value: 'Włodowice' },
  { label: 'Włosienica', value: 'Włosienica' },
  { label: 'Włoszakowice', value: 'Włoszakowice' },
  { label: 'Włoszczowa', value: 'Włoszczowa' },
  { label: 'Wobkent', value: 'Wobkent' },
  { label: 'Wobkent Tumani', value: 'Wobkent Tumani' },
  { label: 'Wobulenzi', value: 'Wobulenzi' },
  { label: 'Woburn', value: 'Woburn' },
  { label: 'Woburn', value: 'Woburn' },
  { label: 'Woburn Sands', value: 'Woburn Sands' },
  { label: 'Wodonga', value: 'Wodonga' },
  { label: 'Wodynie', value: 'Wodynie' },
  { label: 'Wodzierady', value: 'Wodzierady' },
  { label: 'Wodzisław', value: 'Wodzisław' },
  { label: 'Wodzisław Śląski', value: 'Wodzisław Śląski' },
  { label: 'Woensdrecht', value: 'Woensdrecht' },
  { label: 'Woenselse Heide', value: 'Woenselse Heide' },
  { label: 'Woerden', value: 'Woerden' },
  { label: 'Woerth', value: 'Woerth' },
  { label: 'Wofford Heights', value: 'Wofford Heights' },
  { label: 'Wognum', value: 'Wognum' },
  { label: 'Wohldorf-Ohlstedt', value: 'Wohldorf-Ohlstedt' },
  { label: 'Wohlen', value: 'Wohlen' },
  { label: 'Wohlen', value: 'Wohlen' },
  { label: 'Wohltorf', value: 'Wohltorf' },
  { label: 'Wohnbach', value: 'Wohnbach' },
  { label: 'Wöhrden', value: 'Wöhrden' },
  { label: 'Wohyń', value: 'Wohyń' },
  { label: 'Woincourt', value: 'Woincourt' },
  { label: 'Woippy', value: 'Woippy' },
  { label: 'Wojaszówka', value: 'Wojaszówka' },
  { label: 'Wojciechów', value: 'Wojciechów' },
  { label: 'Wojciechowice', value: 'Wojciechowice' },
  { label: 'Wojcieszków', value: 'Wojcieszków' },
  { label: 'Wojcieszów', value: 'Wojcieszów' },
  { label: 'Wojkowice', value: 'Wojkowice' },
  { label: 'Wojnicz', value: 'Wojnicz' },
  { label: 'Wojsławice', value: 'Wojsławice' },
  { label: 'Wojszyce', value: 'Wojszyce' },
  { label: 'Wokha', value: 'Wokha' },
  { label: 'Woking', value: 'Woking' },
  { label: 'Wokingham', value: 'Wokingham' },
  { label: 'Wola', value: 'Wola' },
  { label: 'Wola', value: 'Wola' },
  { label: 'Wola Batorska', value: 'Wola Batorska' },
  { label: 'Wola Dębińska', value: 'Wola Dębińska' },
  { label: 'Wola Filipowska', value: 'Wola Filipowska' },
  { label: 'Wola Jachowa', value: 'Wola Jachowa' },
  { label: 'Wola Krzysztoporska', value: 'Wola Krzysztoporska' },
  { label: 'Wola Radziszowska', value: 'Wola Radziszowska' },
  { label: 'Wola Rębkowska', value: 'Wola Rębkowska' },
  { label: 'Wola Sernicka', value: 'Wola Sernicka' },
  { label: 'Wola Uhruska', value: 'Wola Uhruska' },
  { label: 'Wola Zabierzowska', value: 'Wola Zabierzowska' },
  { label: 'Wola Żarczycka', value: 'Wola Żarczycka' },
  { label: 'Wolanów', value: 'Wolanów' },
  { label: 'Wolayita Zone', value: 'Wolayita Zone' },
  { label: 'Wolbórz', value: 'Wolbórz' },
  { label: 'Wolbrom', value: 'Wolbrom' },
  { label: 'Wolcott', value: 'Wolcott' },
  { label: 'Wolcott', value: 'Wolcott' },
  { label: 'Wolcottville', value: 'Wolcottville' },
  { label: 'Wołczyn', value: 'Wołczyn' },
  { label: 'Wold Newton', value: 'Wold Newton' },
  { label: 'Woldegk', value: 'Woldegk' },
  { label: 'Wolder', value: 'Wolder' },
  { label: 'Woldingham', value: 'Woldingham' },
  { label: 'Woleai Municipality', value: 'Woleai Municipality' },
  { label: 'Wolf Creek', value: 'Wolf Creek' },
  { label: 'Wolf Lake', value: 'Wolf Lake' },
  { label: 'Wolf Point', value: 'Wolf Point' },
  { label: 'Wolf Trap', value: 'Wolf Trap' },
  { label: 'Wolfach', value: 'Wolfach' },
  { label: 'Wolfau', value: 'Wolfau' },
  { label: 'Wolfdale', value: 'Wolfdale' },
  { label: 'Wolfe City', value: 'Wolfe City' },
  { label: 'Wolfe County', value: 'Wolfe County' },
  { label: 'Wolfeboro', value: 'Wolfeboro' },
  { label: 'Wolfegg', value: 'Wolfegg' },
  { label: 'Wolfen', value: 'Wolfen' },
  { label: 'Wolfenbüttel', value: 'Wolfenbüttel' },
  { label: 'Wolfenschiessen', value: 'Wolfenschiessen' },
  { label: 'Wolfern', value: 'Wolfern' },
  { label: 'Wolferode', value: 'Wolferode' },
  { label: 'Wolfersdorf', value: 'Wolfersdorf' },
  { label: 'Wölfersheim', value: 'Wölfersheim' },
  { label: 'Wolferstadt', value: 'Wolferstadt' },
  { label: 'Wolfertschwenden', value: 'Wolfertschwenden' },
  { label: 'Wolfforth', value: 'Wolfforth' },
  { label: 'Wolfgantzen', value: 'Wolfgantzen' },
  { label: 'Wolfhagen', value: 'Wolfhagen' },
  { label: 'Wolfhausen', value: 'Wolfhausen' },
  { label: 'Wolfheze', value: 'Wolfheze' },
  { label: 'Wolfhurst', value: 'Wolfhurst' },
  { label: 'Wölfis', value: 'Wölfis' },
  { label: 'Wolfisheim', value: 'Wolfisheim' },
  { label: 'Wölflinswil', value: 'Wölflinswil' },
  { label: 'Wölfnitz', value: 'Wölfnitz' },
  { label: 'Wolfpassing', value: 'Wolfpassing' },
  { label: 'Wolframs-Eschenbach', value: 'Wolframs-Eschenbach' },
  { label: 'Wolfratshausen', value: 'Wolfratshausen' },
  { label: 'Wolfsbach', value: 'Wolfsbach' },
  { label: 'Wolfsberg', value: 'Wolfsberg' },
  { label: 'Wolfsberg District', value: 'Wolfsberg District' },
  { label: 'Wolfsberg Im Schwarzautal', value: 'Wolfsberg Im Schwarzautal' },
  { label: 'Wolfsbos', value: 'Wolfsbos' },
  { label: 'Wolfsburg', value: 'Wolfsburg' },
  { label: 'Wolfschlugen', value: 'Wolfschlugen' },
  { label: 'Wolfsegg', value: 'Wolfsegg' },
  { label: 'Wolfsegg Am Hausruck', value: 'Wolfsegg Am Hausruck' },
  { label: 'Wolfsgraben', value: 'Wolfsgraben' },
  { label: 'Wolfstein', value: 'Wolfstein' },
  { label: 'Wolfsthal', value: 'Wolfsthal' },
  { label: 'Wolfurt', value: 'Wolfurt' },
  { label: 'Wolfville', value: 'Wolfville' },
  { label: 'Wolfwil', value: 'Wolfwil' },
  { label: 'Wolgast', value: 'Wolgast' },
  { label: 'Wolhusen', value: 'Wolhusen' },
  { label: 'Wolin', value: 'Wolin' },
  { label: 'Wólka Niedźwiedzka', value: 'Wólka Niedźwiedzka' },
  { label: 'Wólka Pełkińska', value: 'Wólka Pełkińska' },
  { label: 'Wólka Podleśna', value: 'Wólka Podleśna' },
  { label: 'Wólka Tanewska', value: 'Wólka Tanewska' },
  { label: 'Wolken', value: 'Wolken' },
  { label: 'Wolkenstein', value: 'Wolkenstein' },
  { label: 'Wolkersdorf Im Weinviertel', value: 'Wolkersdorf Im Weinviertel' },
  { label: 'Wolkramshausen', value: 'Wolkramshausen' },
  { label: 'Wollaston', value: 'Wollaston' },
  { label: 'Wollbach', value: 'Wollbach' },
  { label: 'Wollerau', value: 'Wollerau' },
  { label: 'Wöllersdorf', value: 'Wöllersdorf' },
  { label: 'Wollert', value: 'Wollert' },
  { label: 'Wolli Creek', value: 'Wolli Creek' },
  { label: 'Wollochet', value: 'Wollochet' },
  { label: 'Wollondilly', value: 'Wollondilly' },
  { label: 'Wollongbar', value: 'Wollongbar' },
  { label: 'Wollongong', value: 'Wollongong' },
  { label: 'Wollongong City Centre', value: 'Wollongong City Centre' },
  { label: 'Wöllstein', value: 'Wöllstein' },
  { label: 'Wollstonecraft', value: 'Wollstonecraft' },
  { label: 'Wolmaransstad', value: 'Wolmaransstad' },
  { label: 'Wolmirsleben', value: 'Wolmirsleben' },
  { label: 'Wolmirstedt', value: 'Wolmirstedt' },
  { label: 'Wolnzach', value: 'Wolnzach' },
  { label: 'Wołomin', value: 'Wołomin' },
  { label: 'Wołów', value: 'Wołów' },
  { label: 'Wołowice', value: 'Wołowice' },
  { label: 'Wolpertshausen', value: 'Wolpertshausen' },
  { label: 'Wolpertswende', value: 'Wolpertswende' },
  { label: 'Wolphaartsdijk', value: 'Wolphaartsdijk' },
  { label: 'Wölpinghausen', value: 'Wölpinghausen' },
  { label: 'Wolsdorf', value: 'Wolsdorf' },
  { label: 'Wolsingham', value: 'Wolsingham' },
  { label: 'Wolston', value: 'Wolston' },
  { label: 'Wolsztyn', value: 'Wolsztyn' },
  { label: 'Woltersdorf', value: 'Woltersdorf' },
  { label: 'Woltersdorf', value: 'Woltersdorf' },
  { label: 'Wolvega', value: 'Wolvega' },
  { label: 'Wolvercote', value: 'Wolvercote' },
  { label: 'Wolverhampton', value: 'Wolverhampton' },
  { label: 'Wolverine Lake', value: 'Wolverine Lake' },
  { label: 'Wombourn', value: 'Wombourn' },
  { label: 'Wombwell', value: 'Wombwell' },
  { label: 'Womelsdorf', value: 'Womelsdorf' },
  { label: 'Wommelgem', value: 'Wommelgem' },
  { label: 'Wommels', value: 'Wommels' },
  { label: 'Wondai', value: 'Wondai' },
  { label: 'Wonder Lake', value: 'Wonder Lake' },
  { label: 'Wondunna', value: 'Wondunna' },
  { label: 'Wonei Municipality', value: 'Wonei Municipality' },
  { label: 'Wonfurt', value: 'Wonfurt' },
  { label: 'Wong Tai Sin', value: 'Wong Tai Sin' },
  { label: 'Wonga Park', value: 'Wonga Park' },
  { label: 'Wongaling Beach', value: 'Wongaling Beach' },
  { label: 'Wongan-Ballidu', value: 'Wongan-Ballidu' },
  { label: 'Wongawallan', value: 'Wongawallan' },
  { label: 'Wongsorejo', value: 'Wongsorejo' },
  { label: 'Wŏnju', value: 'Wŏnju' },
  { label: 'Wonju-Si', value: 'Wonju-Si' },
  { label: 'Wonopringgo', value: 'Wonopringgo' },
  { label: 'Wonosobo', value: 'Wonosobo' },
  { label: 'Wŏnsan', value: 'Wŏnsan' },
  { label: 'Wonsees', value: 'Wonsees' },
  { label: 'Wonthaggi', value: 'Wonthaggi' },
  { label: 'Wonthella', value: 'Wonthella' },
  { label: 'Wood County', value: 'Wood County' },
  { label: 'Wood County', value: 'Wood County' },
  { label: 'Wood County', value: 'Wood County' },
  { label: 'Wood County', value: 'Wood County' },
  { label: 'Wood Dale', value: 'Wood Dale' },
  { label: 'Wood River', value: 'Wood River' },
  { label: 'Wood River', value: 'Wood River' },
  { label: 'Wood Street Village', value: 'Wood Street Village' },
  { label: 'Wood Village', value: 'Wood Village' },
  { label: 'Woodacre', value: 'Woodacre' },
  { label: 'Woodanilling', value: 'Woodanilling' },
  { label: 'Woodberry', value: 'Woodberry' },
  { label: 'Woodbine', value: 'Woodbine' },
  { label: 'Woodbine', value: 'Woodbine' },
  { label: 'Woodbine', value: 'Woodbine' },
  { label: 'Woodbine', value: 'Woodbine' },
  { label: 'Woodborough', value: 'Woodborough' },
  { label: 'Woodbourne', value: 'Woodbourne' },
  { label: 'Woodbranch', value: 'Woodbranch' },
  { label: 'Woodbridge', value: 'Woodbridge' },
  { label: 'Woodbridge', value: 'Woodbridge' },
  { label: 'Woodbridge', value: 'Woodbridge' },
  { label: 'Woodbridge', value: 'Woodbridge' },
  { label: 'Woodbridge', value: 'Woodbridge' },
  { label: 'Woodbridge', value: 'Woodbridge' },
  { label: 'Woodburn', value: 'Woodburn' },
  { label: 'Woodburn', value: 'Woodburn' },
  { label: 'Woodburn', value: 'Woodburn' },
  { label: 'Woodbury', value: 'Woodbury' },
  { label: 'Woodbury', value: 'Woodbury' },
  { label: 'Woodbury', value: 'Woodbury' },
  { label: 'Woodbury', value: 'Woodbury' },
  { label: 'Woodbury', value: 'Woodbury' },
  { label: 'Woodbury', value: 'Woodbury' },
  { label: 'Woodbury Center', value: 'Woodbury Center' },
  { label: 'Woodbury County', value: 'Woodbury County' },
  { label: 'Woodbury Heights', value: 'Woodbury Heights' },
  { label: 'Woodchurch', value: 'Woodchurch' },
  { label: 'Woodcliff Lake', value: 'Woodcliff Lake' },
  { label: 'Woodcote', value: 'Woodcote' },
  { label: 'Woodcreek', value: 'Woodcreek' },
  { label: 'Woodcrest', value: 'Woodcrest' },
  { label: 'Woodcroft', value: 'Woodcroft' },
  { label: 'Woodcroft', value: 'Woodcroft' },
  { label: 'Woodend', value: 'Woodend' },
  { label: 'Woodend', value: 'Woodend' },
  { label: 'Woodend', value: 'Woodend' },
  { label: 'Woodfield', value: 'Woodfield' },
  { label: 'Woodfin', value: 'Woodfin' },
  { label: 'Woodford', value: 'Woodford' },
  { label: 'Woodford', value: 'Woodford' },
  { label: 'Woodford', value: 'Woodford' },
  { label: 'Woodford', value: 'Woodford' },
  { label: 'Woodford County', value: 'Woodford County' },
  { label: 'Woodford County', value: 'Woodford County' },
  { label: 'Woodford Green', value: 'Woodford Green' },
  { label: 'Woodford Hill', value: 'Woodford Hill' },
  { label: 'Woodford Park', value: 'Woodford Park' },
  { label: 'Woodgate', value: 'Woodgate' },
  { label: 'Woodhall', value: 'Woodhall' },
  { label: 'Woodhall Spa', value: 'Woodhall Spa' },
  { label: 'Woodhaven', value: 'Woodhaven' },
  { label: 'Woodhaven', value: 'Woodhaven' },
  { label: 'Woodinville', value: 'Woodinville' },
  { label: 'Woodlake', value: 'Woodlake' },
  { label: 'Woodlake', value: 'Woodlake' },
  { label: 'Woodland', value: 'Woodland' },
  { label: 'Woodland', value: 'Woodland' },
  { label: 'Woodland Beach', value: 'Woodland Beach' },
  { label: 'Woodland Heights', value: 'Woodland Heights' },
  { label: 'Woodland Hills', value: 'Woodland Hills' },
  { label: 'Woodland Hills', value: 'Woodland Hills' },
  { label: 'Woodland Park', value: 'Woodland Park' },
  { label: 'Woodland Park', value: 'Woodland Park' },
  { label: 'Woodlands', value: 'Woodlands' },
  { label: 'Woodlands', value: 'Woodlands' },
  { label: 'Woodlawn', value: 'Woodlawn' },
  { label: 'Woodlawn', value: 'Woodlawn' },
  { label: 'Woodlawn', value: 'Woodlawn' },
  { label: 'Woodlawn', value: 'Woodlawn' },
  { label: 'Woodlawn', value: 'Woodlawn' },
  { label: 'Woodlawn Beach', value: 'Woodlawn Beach' },
  { label: 'Woodlyn', value: 'Woodlyn' },
  { label: 'Wood-Lynne', value: 'Wood-Lynne' },
  { label: 'Woodmere', value: 'Woodmere' },
  { label: 'Woodmere', value: 'Woodmere' },
  { label: 'Woodmont', value: 'Woodmont' },
  { label: 'Woodmoor', value: 'Woodmoor' },
  { label: 'Woodmore', value: 'Woodmore' },
  { label: 'Woodpark', value: 'Woodpark' },
  { label: 'Woodpark', value: 'Woodpark' },
  { label: 'Woodridge', value: 'Woodridge' },
  { label: 'Woodridge', value: 'Woodridge' },
  { label: 'Woodridge', value: 'Woodridge' },
  { label: 'Wood-Ridge', value: 'Wood-Ridge' },
  { label: 'Woodrising', value: 'Woodrising' },
  { label: 'Woodroffe', value: 'Woodroffe' },
  { label: 'Woodrow', value: 'Woodrow' },
  { label: 'Woodruff', value: 'Woodruff' },
  { label: 'Woodruff County', value: 'Woodruff County' },
  { label: 'Woods County', value: 'Woods County' },
  { label: 'Woods Creek', value: 'Woods Creek' },
  { label: 'Woods Cross', value: 'Woods Cross' },
  { label: 'Woodsboro', value: 'Woodsboro' },
  { label: 'Woodsboro', value: 'Woodsboro' },
  { label: 'Woodsetts', value: 'Woodsetts' },
  { label: 'Woodsfield', value: 'Woodsfield' },
  { label: 'Woodside', value: 'Woodside' },
  { label: 'Woodside', value: 'Woodside' },
  { label: 'Woodside', value: 'Woodside' },
  { label: 'Woodside', value: 'Woodside' },
  { label: 'Woodside', value: 'Woodside' },
  { label: 'Woodside East', value: 'Woodside East' },
  { label: 'Woodson County', value: 'Woodson County' },
  { label: 'Woodson Terrace', value: 'Woodson Terrace' },
  { label: 'Woodstock', value: 'Woodstock' },
  { label: 'Woodstock', value: 'Woodstock' },
  { label: 'Woodstock', value: 'Woodstock' },
  { label: 'Woodstock', value: 'Woodstock' },
  { label: 'Woodstock', value: 'Woodstock' },
  { label: 'Woodstock', value: 'Woodstock' },
  { label: 'Woodstock', value: 'Woodstock' },
  { label: 'Woodstock', value: 'Woodstock' },
  { label: 'Woodstock', value: 'Woodstock' },
  { label: 'Woodstock', value: 'Woodstock' },
  { label: 'Woodstown', value: 'Woodstown' },
  { label: 'Woodsville', value: 'Woodsville' },
  { label: 'Woodsville', value: 'Woodsville' },
  { label: 'Woodvale', value: 'Woodvale' },
  { label: 'Woodville', value: 'Woodville' },
  { label: 'Woodville', value: 'Woodville' },
  { label: 'Woodville', value: 'Woodville' },
  { label: 'Woodville', value: 'Woodville' },
  { label: 'Woodville', value: 'Woodville' },
  { label: 'Woodville', value: 'Woodville' },
  { label: 'Woodville', value: 'Woodville' },
  { label: 'Woodville Gardens', value: 'Woodville Gardens' },
  { label: 'Woodville North', value: 'Woodville North' },
  { label: 'Woodville Park', value: 'Woodville Park' },
  { label: 'Woodville South', value: 'Woodville South' },
  { label: 'Woodville West', value: 'Woodville West' },
  { label: 'Woodward', value: 'Woodward' },
  { label: 'Woodward', value: 'Woodward' },
  { label: 'Woodward County', value: 'Woodward County' },
  { label: 'Woodway', value: 'Woodway' },
  { label: 'Woodway', value: 'Woodway' },
  { label: 'Woodworth', value: 'Woodworth' },
  { label: 'Woody Point', value: 'Woody Point' },
  { label: 'Wool', value: 'Wool' },
  { label: 'Woolavington', value: 'Woolavington' },
  { label: 'Woolde', value: 'Woolde' },
  { label: 'Wooldrik', value: 'Wooldrik' },
  { label: 'Wooler', value: 'Wooler' },
  { label: 'Woolgoolga', value: 'Woolgoolga' },
  { label: 'Woollahra', value: 'Woollahra' },
  { label: 'Woolley', value: 'Woolley' },
  { label: 'Woolloomooloo', value: 'Woolloomooloo' },
  { label: 'Woolloongabba', value: 'Woolloongabba' },
  { label: 'Woolooware', value: 'Woolooware' },
  { label: 'Wooloowin', value: 'Wooloowin' },
  { label: 'Woolpit', value: 'Woolpit' },
  { label: 'Woolwich', value: 'Woolwich' },
  { label: 'Woombye', value: 'Woombye' },
  { label: 'Woongarrah', value: 'Woongarrah' },
  { label: 'Woonona', value: 'Woonona' },
  { label: 'Woonsocket', value: 'Woonsocket' },
  { label: 'Woonsocket', value: 'Woonsocket' },
  { label: 'Woorabinda', value: 'Woorabinda' },
  { label: 'Woori Yallock', value: 'Woori Yallock' },
  { label: 'Woorim', value: 'Woorim' },
  { label: 'Wooroloo', value: 'Wooroloo' },
  { label: 'Woorree', value: 'Woorree' },
  { label: 'Wooster', value: 'Wooster' },
  { label: 'Wootton', value: 'Wootton' },
  { label: 'Worb', value: 'Worb' },
  { label: 'Worben', value: 'Worben' },
  { label: 'Worbis', value: 'Worbis' },
  { label: 'Worcester', value: 'Worcester' },
  { label: 'Worcester', value: 'Worcester' },
  { label: 'Worcester', value: 'Worcester' },
  { label: 'Worcester', value: 'Worcester' },
  { label: 'Worcester County', value: 'Worcester County' },
  { label: 'Worcester County', value: 'Worcester County' },
  { label: 'Worcester Park', value: 'Worcester Park' },
  { label: 'Worcestershire', value: 'Worcestershire' },
  { label: 'Worden', value: 'Worden' },
  { label: 'Wördern', value: 'Wördern' },
  { label: 'Woree', value: 'Woree' },
  { label: 'Wörgl', value: 'Wörgl' },
  { label: 'Woringen', value: 'Woringen' },
  { label: 'Workington', value: 'Workington' },
  { label: 'Worksop', value: 'Worksop' },
  { label: 'Workum', value: 'Workum' },
  { label: 'Worlaby', value: 'Worlaby' },
  { label: 'Worland', value: 'Worland' },
  { label: 'Worli', value: 'Worli' },
  { label: 'Wörlitz', value: 'Wörlitz' },
  { label: 'Wormeldange', value: 'Wormeldange' },
  { label: 'Wormerland', value: 'Wormerland' },
  { label: 'Wormerveer', value: 'Wormerveer' },
  { label: 'Wormhout', value: 'Wormhout' },
  { label: 'Wormley', value: 'Wormley' },
  { label: 'Wormleysburg', value: 'Wormleysburg' },
  { label: 'Worms', value: 'Worms' },
  { label: 'Worodougou', value: 'Worodougou' },
  { label: 'Worongary', value: 'Worongary' },
  { label: 'Woronora', value: 'Woronora' },
  { label: 'Woronora Heights', value: 'Woronora Heights' },
  { label: 'Worpswede', value: 'Worpswede' },
  { label: 'Worrigee', value: 'Worrigee' },
  { label: 'Wörrstadt', value: 'Wörrstadt' },
  { label: 'Wörschach', value: 'Wörschach' },
  { label: 'Wört', value: 'Wört' },
  { label: 'Wörterberg', value: 'Wörterberg' },
  { label: 'Worth', value: 'Worth' },
  { label: 'Wörth', value: 'Wörth' },
  { label: 'Wörth Am Main', value: 'Wörth Am Main' },
  { label: 'Wörth Am Rhein', value: 'Wörth Am Rhein' },
  { label: 'Wörth An Der Donau', value: 'Wörth An Der Donau' },
  { label: 'Wörth An Der Isar', value: 'Wörth An Der Isar' },
  { label: 'Wörth An Der Lafnitz', value: 'Wörth An Der Lafnitz' },
  { label: 'Worth County', value: 'Worth County' },
  { label: 'Worth County', value: 'Worth County' },
  { label: 'Worth County', value: 'Worth County' },
  { label: 'Wortham', value: 'Wortham' },
  { label: 'Worthing', value: 'Worthing' },
  { label: 'Worthington', value: 'Worthington' },
  { label: 'Worthington', value: 'Worthington' },
  { label: 'Worthington', value: 'Worthington' },
  { label: 'Worthington', value: 'Worthington' },
  { label: 'Worthington Hills', value: 'Worthington Hills' },
  { label: 'Wörthsee', value: 'Wörthsee' },
  { label: 'Worton', value: 'Worton' },
  { label: 'Wössingen', value: 'Wössingen' },
  { label: 'Wote', value: 'Wote' },
  { label: 'Wotton-Under-Edge', value: 'Wotton-Under-Edge' },
  { label: 'Woubrugge', value: 'Woubrugge' },
  { label: 'Woudenberg', value: 'Woudenberg' },
  { label: 'Woudhuis', value: 'Woudhuis' },
  { label: 'Woudrichem', value: 'Woudrichem' },
  { label: 'Woudsend', value: 'Woudsend' },
  { label: 'Wouldham', value: 'Wouldham' },
  { label: 'Woustviller', value: 'Woustviller' },
  { label: 'Wouw', value: 'Wouw' },
  { label: 'Woxall', value: 'Woxall' },
  { label: 'Woy Woy', value: 'Woy Woy' },
  { label: 'Woźniki', value: 'Woźniki' },
  { label: 'Wożniki', value: 'Wożniki' },
  { label: 'Wragby', value: 'Wragby' },
  { label: 'Wrangell', value: 'Wrangell' },
  { label: 'Wrawby', value: 'Wrawby' },
  { label: 'Wray', value: 'Wray' },
  { label: 'Wrea Green', value: 'Wrea Green' },
  { label: 'Wręczyca Wielka', value: 'Wręczyca Wielka' },
  { label: 'Wremen', value: 'Wremen' },
  { label: 'Wrens', value: 'Wrens' },
  { label: 'Wrentham', value: 'Wrentham' },
  { label: 'Wrestedt', value: 'Wrestedt' },
  { label: 'Wrexham', value: 'Wrexham' },
  { label: 'Wriedel', value: 'Wriedel' },
  { label: 'Wriezen', value: 'Wriezen' },
  { label: 'Wright', value: 'Wright' },
  { label: 'Wright', value: 'Wright' },
  { label: 'Wright', value: 'Wright' },
  { label: 'Wright', value: 'Wright' },
  { label: 'Wright', value: 'Wright' },
  { label: 'Wright City', value: 'Wright City' },
  { label: 'Wright County', value: 'Wright County' },
  { label: 'Wright County', value: 'Wright County' },
  { label: 'Wright County', value: 'Wright County' },
  { label: 'Wright-Patterson Afb', value: 'Wright-Patterson Afb' },
  { label: 'Wrightsboro', value: 'Wrightsboro' },
  { label: 'Wrightstown', value: 'Wrightstown' },
  { label: 'Wrightsville', value: 'Wrightsville' },
  { label: 'Wrightsville', value: 'Wrightsville' },
  { label: 'Wrightsville', value: 'Wrightsville' },
  { label: 'Wrightsville Beach', value: 'Wrightsville Beach' },
  { label: 'Wrightwood', value: 'Wrightwood' },
  { label: 'Wrington', value: 'Wrington' },
  { label: 'Wrist', value: 'Wrist' },
  { label: 'Writtle', value: 'Writtle' },
  { label: 'Wróblew', value: 'Wróblew' },
  { label: 'Wrocław', value: 'Wrocław' },
  { label: 'Wronki', value: 'Wronki' },
  { label: 'Wrotham', value: 'Wrotham' },
  { label: 'Wroughton', value: 'Wroughton' },
  { label: 'Wroxall', value: 'Wroxall' },
  { label: 'Wrząsowice', value: 'Wrząsowice' },
  { label: 'Września', value: 'Września' },
  { label: 'Wschowa', value: 'Wschowa' },
  { label: 'Wubao', value: 'Wubao' },
  { label: 'Wuchang', value: 'Wuchang' },
  { label: 'Wucheng', value: 'Wucheng' },
  { label: 'Wuchuan', value: 'Wuchuan' },
  { label: 'Wuda', value: 'Wuda' },
  { label: 'Wudinna', value: 'Wudinna' },
  { label: 'Wudmath', value: 'Wudmath' },
  { label: 'Wuhai', value: 'Wuhai' },
  { label: 'Wuhan', value: 'Wuhan' },
  { label: 'Wuhu', value: 'Wuhu' },
  { label: 'Wujal Wujal', value: 'Wujal Wujal' },
  { label: 'Wukari', value: 'Wukari' },
  { label: 'Wulagi', value: 'Wulagi' },
  { label: 'Wulfen', value: 'Wulfen' },
  { label: 'Wülfershausen', value: 'Wülfershausen' },
  { label: 'Wülfrath', value: 'Wülfrath' },
  { label: 'Wulfsen', value: 'Wulfsen' },
  { label: 'Wulften', value: 'Wulften' },
  { label: 'Wulguru', value: 'Wulguru' },
  { label: 'Wuli', value: 'Wuli' },
  { label: 'Wulingyuan', value: 'Wulingyuan' },
  { label: 'Wulkaprodersdorf', value: 'Wulkaprodersdorf' },
  { label: 'Wulkenzin', value: 'Wulkenzin' },
  { label: 'Wülknitz', value: 'Wülknitz' },
  { label: 'Wulkuraka', value: 'Wulkuraka' },
  { label: 'Wullersdorf', value: 'Wullersdorf' },
  { label: 'Wulsbüttel', value: 'Wulsbüttel' },
  { label: 'Wum', value: 'Wum' },
  { label: 'Wundanyi', value: 'Wundanyi' },
  { label: 'Wundowie', value: 'Wundowie' },
  { label: 'Wundschuh', value: 'Wundschuh' },
  { label: 'Wünnenberg', value: 'Wünnenberg' },
  { label: 'Wünschendorf', value: 'Wünschendorf' },
  { label: 'Wunsiedel', value: 'Wunsiedel' },
  { label: 'Wunstorf', value: 'Wunstorf' },
  { label: 'Wuppertal', value: 'Wuppertal' },
  { label: 'Würenlingen', value: 'Würenlingen' },
  { label: 'Würenlos', value: 'Würenlos' },
  { label: 'Würflach', value: 'Würflach' },
  { label: 'Wurmannsquick', value: 'Wurmannsquick' },
  { label: 'Wurmberg', value: 'Wurmberg' },
  { label: 'Würmla', value: 'Würmla' },
  { label: 'Wurmlingen', value: 'Wurmlingen' },
  { label: 'Wurmsham', value: 'Wurmsham' },
  { label: 'Wurno', value: 'Wurno' },
  { label: 'Wurruk', value: 'Wurruk' },
  { label: 'Wurrumiyanga', value: 'Wurrumiyanga' },
  { label: 'Würselen', value: 'Würselen' },
  { label: 'Wurtland', value: 'Wurtland' },
  { label: 'Wurtsboro', value: 'Wurtsboro' },
  { label: 'Wurtulla', value: 'Wurtulla' },
  { label: 'Wurzbach', value: 'Wurzbach' },
  { label: 'Würzburg', value: 'Würzburg' },
  { label: 'Wurzen', value: 'Wurzen' },
  { label: 'Wusab Al Ali', value: 'Wusab Al Ali' },
  { label: 'Wusab As Safil', value: 'Wusab As Safil' },
  { label: 'Wuse', value: 'Wuse' },
  { label: 'Wusong', value: 'Wusong' },
  { label: 'Wüstenrot', value: 'Wüstenrot' },
  { label: 'Wusterhausen', value: 'Wusterhausen' },
  { label: 'Wusterhusen', value: 'Wusterhusen' },
  { label: 'Wustermark', value: 'Wustermark' },
  { label: 'Wusterwitz', value: 'Wusterwitz' },
  { label: 'Wustrow', value: 'Wustrow' },
  { label: 'Wustrow', value: 'Wustrow' },
  { label: 'Wutöschingen', value: 'Wutöschingen' },
  { label: 'Wuustwezel', value: 'Wuustwezel' },
  { label: 'Wuwei', value: 'Wuwei' },
  { label: 'Wuxi', value: 'Wuxi' },
  { label: 'Wuxi', value: 'Wuxi' },
  { label: 'Wuxi', value: 'Wuxi' },
  { label: 'Wuxue', value: 'Wuxue' },
  { label: 'Wuyang', value: 'Wuyang' },
  { label: 'Wuyang', value: 'Wuyang' },
  { label: 'Wuyishan', value: 'Wuyishan' },
  { label: 'Wuyo', value: 'Wuyo' },
  { label: 'Wuyucun', value: 'Wuyucun' },
  { label: 'Wuzhen', value: 'Wuzhen' },
  { label: 'Wuzhong', value: 'Wuzhong' },
  { label: 'Wuzhou', value: 'Wuzhou' },
  { label: 'Wy Yung', value: 'Wy Yung' },
  { label: 'Wyalkatchem', value: 'Wyalkatchem' },
  { label: 'Wyandanch', value: 'Wyandanch' },
  { label: 'Wyandot County', value: 'Wyandot County' },
  { label: 'Wyandotte', value: 'Wyandotte' },
  { label: 'Wyandotte County', value: 'Wyandotte County' },
  { label: 'Wychbold', value: 'Wychbold' },
  { label: 'Wyckoff', value: 'Wyckoff' },
  { label: 'Wydminy', value: 'Wydminy' },
  { label: 'Wye', value: 'Wye' },
  { label: 'Wyee', value: 'Wyee' },
  { label: 'Wyee Point', value: 'Wyee Point' },
  { label: 'Wyhl', value: 'Wyhl' },
  { label: 'Wyk Auf Föhr', value: 'Wyk Auf Föhr' },
  { label: 'Wykagyl', value: 'Wykagyl' },
  { label: 'Wylam', value: 'Wylam' },
  { label: 'Wyldwood', value: 'Wyldwood' },
  { label: 'Wylie', value: 'Wylie' },
  { label: 'Wymeswold', value: 'Wymeswold' },
  { label: 'Wymiarki', value: 'Wymiarki' },
  { label: 'Wymore', value: 'Wymore' },
  { label: 'Wynantskill', value: 'Wynantskill' },
  { label: 'Wynau', value: 'Wynau' },
  { label: 'Wyncote', value: 'Wyncote' },
  { label: 'Wyndham', value: 'Wyndham' },
  { label: 'Wyndham', value: 'Wyndham' },
  { label: 'Wyndham Vale', value: 'Wyndham Vale' },
  { label: 'Wyndham-East Kimberley', value: 'Wyndham-East Kimberley' },
  { label: 'Wyndmoor', value: 'Wyndmoor' },
  { label: 'Wynigen', value: 'Wynigen' },
  { label: 'Wynn Vale', value: 'Wynn Vale' },
  { label: 'Wynne', value: 'Wynne' },
  { label: 'Wynnewood', value: 'Wynnewood' },
  { label: 'Wynnum', value: 'Wynnum' },
  { label: 'Wynnum West', value: 'Wynnum West' },
  { label: 'Wynyard', value: 'Wynyard' },
  { label: 'Wynyard', value: 'Wynyard' },
  { label: 'Wyoming', value: 'Wyoming' },
  { label: 'Wyoming', value: 'Wyoming' },
  { label: 'Wyoming', value: 'Wyoming' },
  { label: 'Wyoming', value: 'Wyoming' },
  { label: 'Wyoming', value: 'Wyoming' },
  { label: 'Wyoming', value: 'Wyoming' },
  { label: 'Wyoming', value: 'Wyoming' },
  { label: 'Wyoming County', value: 'Wyoming County' },
  { label: 'Wyoming County', value: 'Wyoming County' },
  { label: 'Wyoming County', value: 'Wyoming County' },
  { label: 'Wyomissing', value: 'Wyomissing' },
  { label: 'Wyomissing Hills', value: 'Wyomissing Hills' },
  { label: 'Wyong', value: 'Wyong' },
  { label: 'Wyongah', value: 'Wyongah' },
  { label: 'Wyreema', value: 'Wyreema' },
  { label: 'Wyry', value: 'Wyry' },
  { label: 'Wyrzysk', value: 'Wyrzysk' },
  { label: 'Wyśmierzyce', value: 'Wyśmierzyce' },
  { label: 'Wysoka', value: 'Wysoka' },
  { label: 'Wysoka', value: 'Wysoka' },
  { label: 'Wysoka', value: 'Wysoka' },
  { label: 'Wysoka', value: 'Wysoka' },
  { label: 'Wysoka Głogowska', value: 'Wysoka Głogowska' },
  { label: 'Wysoka Strzyżowska', value: 'Wysoka Strzyżowska' },
  { label: 'Wysokie', value: 'Wysokie' },
  { label: 'Wysokie Mazowieckie', value: 'Wysokie Mazowieckie' },
  { label: 'Wyssachen', value: 'Wyssachen' },
  { label: 'Wyszki', value: 'Wyszki' },
  { label: 'Wyszków', value: 'Wyszków' },
  { label: 'Wyszogród', value: 'Wyszogród' },
  { label: 'Wythe County', value: 'Wythe County' },
  { label: 'Wytheville', value: 'Wytheville' },
  { label: 'X Cabil', value: 'X Cabil' },
  { label: 'Xaaga', value: 'Xaaga' },
  { label: 'Xacaxomulco', value: 'Xacaxomulco' },
  { label: 'Xaçmaz', value: 'Xaçmaz' },
  { label: 'Xagacía', value: 'Xagacía' },
  { label: 'Xagħra', value: 'Xagħra' },
  { label: 'Xai-Xai', value: 'Xai-Xai' },
  { label: 'Xalacapan De Lucke', value: 'Xalacapan De Lucke' },
  { label: 'Xalapa De Enríquez', value: 'Xalapa De Enríquez' },
  { label: 'Xalatlaco', value: 'Xalatlaco' },
  { label: 'Xalatlaco', value: 'Xalatlaco' },
  { label: 'Xalatzala', value: 'Xalatzala' },
  { label: 'Xalitla', value: 'Xalitla' },
  { label: 'Xalitzintla', value: 'Xalitzintla' },
  { label: 'Xaló', value: 'Xaló' },
  { label: 'Xalostoc', value: 'Xalostoc' },
  { label: 'Xalpatlahuac', value: 'Xalpatlahuac' },
  { label: 'Xaltepec', value: 'Xaltepec' },
  { label: 'Xaltepuxtla', value: 'Xaltepuxtla' },
  { label: 'Xaltianguis', value: 'Xaltianguis' },
  { label: 'Xalticpac', value: 'Xalticpac' },
  { label: 'Xaltocan', value: 'Xaltocan' },
  { label: 'Xam Neua', value: 'Xam Neua' },
  { label: 'Xam Nua', value: 'Xam Nua' },
  { label: 'Xambioá', value: 'Xambioá' },
  { label: 'Xambrê', value: 'Xambrê' },
  { label: 'Xanabá', value: 'Xanabá' },
  { label: 'Xangri-Lá', value: 'Xangri-Lá' },
  { label: 'Xanten', value: 'Xanten' },
  { label: 'Xánthi', value: 'Xánthi' },
  { label: 'Xanxerê', value: 'Xanxerê' },
  { label: 'Xapuri', value: 'Xapuri' },
  { label: 'Xarardheere', value: 'Xarardheere' },
  { label: 'Xàtiva', value: 'Xàtiva' },
  { label: 'Xavantina', value: 'Xavantina' },
  { label: 'Xaxim', value: 'Xaxim' },
  { label: 'Xaya', value: 'Xaya' },
  { label: 'Xayacatlán De Bravo', value: 'Xayacatlán De Bravo' },
  { label: 'Xbacab', value: 'Xbacab' },
  { label: 'X-Cán', value: 'X-Cán' },
  { label: 'Xcanatún', value: 'Xcanatún' },
  { label: 'Xemxija', value: 'Xemxija' },
  { label: 'Xenia', value: 'Xenia' },
  { label: 'Xeraco', value: 'Xeraco' },
  { label: 'Xeracojaraco', value: 'Xeracojaraco' },
  { label: 'Xeresa', value: 'Xeresa' },
  { label: 'Xermade', value: 'Xermade' },
  { label: 'Xerta', value: 'Xerta' },
  { label: 'Xertigny', value: 'Xertigny' },
  { label: 'Xewkija', value: 'Xewkija' },
  { label: 'Xexéu', value: 'Xexéu' },
  { label: 'Xgħajra', value: 'Xgħajra' },
  { label: 'Xhariep District Municipality', value: 'Xhariep District Municipality' },
  { label: 'X-Hazil Sur', value: 'X-Hazil Sur' },
  { label: 'Xhimojay', value: 'Xhimojay' },
  { label: 'Xhitey', value: 'Xhitey' },
  { label: 'Xhixhata', value: 'Xhixhata' },
  { label: 'Xi’An', value: 'Xi’An' },
  { label: 'Xiabaishi', value: 'Xiabaishi' },
  { label: 'Xiahu', value: 'Xiahu' },
  { label: 'Xiajiang', value: 'Xiajiang' },
  { label: 'Xialiang', value: 'Xialiang' },
  { label: 'Xiamen', value: 'Xiamen' },
  { label: 'Xiancun', value: 'Xiancun' },
  { label: 'Xiangcheng Chengguanzhen', value: 'Xiangcheng Chengguanzhen' },
  { label: 'Xiangtan', value: 'Xiangtan' },
  { label: 'Xiangxi Tujiazu Miaozu Zizhizhou', value: 'Xiangxi Tujiazu Miaozu Zizhizhou' },
  { label: 'Xiangxiang', value: 'Xiangxiang' },
  { label: 'Xiangyang', value: 'Xiangyang' },
  { label: 'Xiangyun', value: 'Xiangyun' },
  { label: 'Xianju', value: 'Xianju' },
  { label: 'Xianning', value: 'Xianning' },
  { label: 'Xianning Prefecture', value: 'Xianning Prefecture' },
  { label: 'Xiannü', value: 'Xiannü' },
  { label: 'Xianrenwan', value: 'Xianrenwan' },
  { label: 'Xiantan', value: 'Xiantan' },
  { label: 'Xiantao', value: 'Xiantao' },
  { label: 'Xianxi', value: 'Xianxi' },
  { label: 'Xianyang', value: 'Xianyang' },
  { label: 'Xiaogan', value: 'Xiaogan' },
  { label: 'Xiaohenglong', value: 'Xiaohenglong' },
  { label: 'Xiaolingwei', value: 'Xiaolingwei' },
  { label: 'Xiaolongmen', value: 'Xiaolongmen' },
  { label: 'Xiaoshajiang', value: 'Xiaoshajiang' },
  { label: 'Xiaoshan', value: 'Xiaoshan' },
  { label: 'Xiaoshi', value: 'Xiaoshi' },
  { label: 'Xiaoweizhai', value: 'Xiaoweizhai' },
  { label: 'Xiawuqi', value: 'Xiawuqi' },
  { label: 'Xiazhen', value: 'Xiazhen' },
  { label: 'Xiazhuang', value: 'Xiazhuang' },
  { label: 'Xibing', value: 'Xibing' },
  { label: 'Xichang', value: 'Xichang' },
  { label: 'Xichú', value: 'Xichú' },
  { label: 'Xico', value: 'Xico' },
  { label: 'Xico', value: 'Xico' },
  { label: 'Xicohtzinco', value: 'Xicohtzinco' },
  { label: 'Xicoténcatl', value: 'Xicoténcatl' },
  { label: 'Xicoténcatl', value: 'Xicoténcatl' },
  { label: 'Xicotepec', value: 'Xicotepec' },
  { label: 'Xicotepec De Juárez', value: 'Xicotepec De Juárez' },
  { label: 'Xicotlán', value: 'Xicotlán' },
  { label: 'Xiditou', value: 'Xiditou' },
  { label: 'Xifeng', value: 'Xifeng' },
  { label: 'Xihe', value: 'Xihe' },
  { label: 'Xiliguantun', value: 'Xiliguantun' },
  { label: 'Xilin Gol Meng', value: 'Xilin Gol Meng' },
  { label: 'Xilin Hot', value: 'Xilin Hot' },
  { label: 'Xiling', value: 'Xiling' },
  { label: 'Xilitla', value: 'Xilitla' },
  { label: 'Xıllı', value: 'Xıllı' },
  { label: 'Xilocuautla', value: 'Xilocuautla' },
  { label: 'Xilópolis', value: 'Xilópolis' },
  { label: 'Xiloxochico De Rafael Ávila Camacho', value: 'Xiloxochico De Rafael Ávila Camacho' },
  { label: 'Ximei', value: 'Ximei' },
  { label: 'Xinan', value: 'Xinan' },
  { label: 'Xincheng', value: 'Xincheng' },
  { label: 'Xindi', value: 'Xindi' },
  { label: 'Xindian', value: 'Xindian' },
  { label: 'Xindian', value: 'Xindian' },
  { label: 'Xingcheng', value: 'Xingcheng' },
  { label: 'Xinghua', value: 'Xinghua' },
  { label: 'Xinglongshan', value: 'Xinglongshan' },
  { label: 'Xingning', value: 'Xingning' },
  { label: 'Xingtai', value: 'Xingtai' },
  { label: 'Xinguara', value: 'Xinguara' },
  { label: 'Xinhualu', value: 'Xinhualu' },
  { label: 'Xinhui', value: 'Xinhui' },
  { label: 'Xining', value: 'Xining' },
  { label: 'Xinji', value: 'Xinji' },
  { label: 'Xinkaikou', value: 'Xinkaikou' },
  { label: 'Xinmin', value: 'Xinmin' },
  { label: 'Xinó Neró', value: 'Xinó Neró' },
  { label: 'Xinpu', value: 'Xinpu' },
  { label: 'Xinqing', value: 'Xinqing' },
  { label: 'Xinshi', value: 'Xinshi' },
  { label: 'Xintai', value: 'Xintai' },
  { label: 'Xintian', value: 'Xintian' },
  { label: 'Xinxiang', value: 'Xinxiang' },
  { label: 'Xinxiang Shi', value: 'Xinxiang Shi' },
  { label: 'Xinxing', value: 'Xinxing' },
  { label: 'Xinyang', value: 'Xinyang' },
  { label: 'Xinyi', value: 'Xinyi' },
  { label: 'Xinyu', value: 'Xinyu' },
  { label: 'Xinyuan', value: 'Xinyuan' },
  { label: 'Xinzhan', value: 'Xinzhan' },
  { label: 'Xinzhi', value: 'Xinzhi' },
  { label: 'Xinzhou', value: 'Xinzhou' },
  { label: 'Xinzhou', value: 'Xinzhou' },
  { label: 'Xinzo De Limia', value: 'Xinzo De Limia' },
  { label: 'Xiongzhou', value: 'Xiongzhou' },
  { label: 'Xique Xique', value: 'Xique Xique' },
  { label: 'Xique-Xique', value: 'Xique-Xique' },
  { label: 'Xiquila', value: 'Xiquila' },
  { label: 'Xireg', value: 'Xireg' },
  { label: 'Xirivella', value: 'Xirivella' },
  { label: 'Xiropótamos', value: 'Xiropótamos' },
  { label: 'Xishan', value: 'Xishan' },
  { label: 'Xishan', value: 'Xishan' },
  { label: 'Xitazhuang', value: 'Xitazhuang' },
  { label: 'Xiteje De Zapata', value: 'Xiteje De Zapata' },
  { label: 'Xitlama', value: 'Xitlama' },
  { label: 'Xiulin', value: 'Xiulin' },
  { label: 'Xiutetelco', value: 'Xiutetelco' },
  { label: 'Xiuyan', value: 'Xiuyan' },
  { label: 'Xiuying', value: 'Xiuying' },
  { label: 'Xixi', value: 'Xixi' },
  { label: 'Xixiang', value: 'Xixiang' },
  { label: 'Xiyan', value: 'Xiyan' },
  { label: 'Xmabén', value: 'Xmabén' },
  { label: 'Xo‘Jaobod', value: 'Xo‘Jaobod' },
  { label: 'Xoampolco', value: 'Xoampolco' },
  { label: 'Xocalı', value: 'Xocalı' },
  { label: 'Xocchel', value: 'Xocchel' },
  { label: 'Xocen', value: 'Xocen' },
  { label: 'Xochapa', value: 'Xochapa' },
  { label: 'Xochiapulco', value: 'Xochiapulco' },
  { label: 'Xochiatipan', value: 'Xochiatipan' },
  { label: 'Xochiatipan De Castillo', value: 'Xochiatipan De Castillo' },
  { label: 'Xochicalco (Cirenio Longares)', value: 'Xochicalco (Cirenio Longares)' },
  { label: 'Xochicoatlán', value: 'Xochicoatlán' },
  { label: 'Xochicuautla', value: 'Xochicuautla' },
  { label: 'Xochihuehuetlán', value: 'Xochihuehuetlán' },
  { label: 'Xochiltepec', value: 'Xochiltepec' },
  { label: 'Xochiltepec', value: 'Xochiltepec' },
  { label: 'Xochimanca', value: 'Xochimanca' },
  { label: 'Xochimilco', value: 'Xochimilco' },
  { label: 'Xochimilco', value: 'Xochimilco' },
  { label: 'Xochimilco', value: 'Xochimilco' },
  { label: 'Xochinanacatlán', value: 'Xochinanacatlán' },
  { label: 'Xochipala', value: 'Xochipala' },
  { label: 'Xochistlahuaca', value: 'Xochistlahuaca' },
  { label: 'Xochitepec', value: 'Xochitepec' },
  { label: 'Xochitepec', value: 'Xochitepec' },
  { label: 'Xochitepec', value: 'Xochitepec' },
  { label: 'Xochitlán', value: 'Xochitlán' },
  { label: 'Xochitlán', value: 'Xochitlán' },
  { label: 'Xochitlán', value: 'Xochitlán' },
  { label: 'Xochitlán De Vicente Suárez', value: 'Xochitlán De Vicente Suárez' },
  { label: 'Xochitlan Todos Santos', value: 'Xochitlan Todos Santos' },
  { label: 'Xochitlaxco (San Baltazar)', value: 'Xochitlaxco (San Baltazar)' },
  { label: 'Xochitonalco', value: 'Xochitonalco' },
  { label: 'Xococapa', value: 'Xococapa' },
  { label: 'Xoconoxtle El Grande', value: 'Xoconoxtle El Grande' },
  { label: 'Xocotepéc', value: 'Xocotepéc' },
  { label: 'Xocotla', value: 'Xocotla' },
  { label: 'Xocotlán', value: 'Xocotlán' },
  { label: 'Xocoyolzintla', value: 'Xocoyolzintla' },
  { label: 'Xocoyucan', value: 'Xocoyucan' },
  { label: 'Xohuayan', value: 'Xohuayan' },
  { label: 'Xoloateno', value: 'Xoloateno' },
  { label: 'Xolostitla De Morelos (Xolostitla)', value: 'Xolostitla De Morelos (Xolostitla)' },
  { label: 'Xolotla', value: 'Xolotla' },
  { label: 'Xometla', value: 'Xometla' },
  { label: 'Xonacatlan', value: 'Xonacatlan' },
  { label: 'Xonalpu', value: 'Xonalpu' },
  { label: 'Xonocuautla', value: 'Xonocuautla' },
  { label: 'Xonrupt-Longemer', value: 'Xonrupt-Longemer' },
  { label: 'Xopilapa', value: 'Xopilapa' },
  { label: 'Xoteapan', value: 'Xoteapan' },
  { label: 'Xove', value: 'Xove' },
  { label: 'Xoxocotla', value: 'Xoxocotla' },
  { label: 'Xoxocotla', value: 'Xoxocotla' },
  { label: 'Xoxolpa', value: 'Xoxolpa' },
  { label: 'Xoxonacatla', value: 'Xoxonacatla' },
  { label: 'X-Pichil', value: 'X-Pichil' },
  { label: 'Xpujil', value: 'Xpujil' },
  { label: 'Xuanzhou', value: 'Xuanzhou' },
  { label: 'Xuchang', value: 'Xuchang' },
  { label: 'Xuchang Shi', value: 'Xuchang Shi' },
  { label: 'Xuchapa', value: 'Xuchapa' },
  { label: 'Xucheng', value: 'Xucheng' },
  { label: 'Xudat', value: 'Xudat' },
  { label: 'Xuddur', value: 'Xuddur' },
  { label: 'Xujiaba', value: 'Xujiaba' },
  { label: 'Xul', value: 'Xul' },
  { label: 'Xul-Ha', value: 'Xul-Ha' },
  { label: 'Xunchang', value: 'Xunchang' },
  { label: 'Xunqueira De Ambía', value: 'Xunqueira De Ambía' },
  { label: 'Xunqueira De Espadanedo', value: 'Xunqueira De Espadanedo' },
  { label: 'Xuzhou', value: 'Xuzhou' },
  { label: 'Xylaganí', value: 'Xylaganí' },
  { label: 'Xylofágou', value: 'Xylofágou' },
  { label: 'Xylókastro', value: 'Xylókastro' },
  { label: 'Xylotymbou', value: 'Xylotymbou' },
  { label: 'Y', value: 'Y' },
  { label: 'Y Felinheli', value: 'Y Felinheli' },
  { label: 'Yaba', value: 'Yaba' },
  { label: 'Yabassi', value: 'Yabassi' },
  { label: 'Yabēlo', value: 'Yabēlo' },
  { label: 'Yablanitsa', value: 'Yablanitsa' },
  { label: 'Yablochnyy', value: 'Yablochnyy' },
  { label: 'Yablonovskiy', value: 'Yablonovskiy' },
  { label: 'Yablunets', value: 'Yablunets' },
  { label: 'Yabluniv', value: 'Yabluniv' },
  { label: 'Yablunytsya', value: 'Yablunytsya' },
  { label: 'Yabrūd', value: 'Yabrūd' },
  { label: 'Yabu', value: 'Yabu' },
  { label: 'Yabucoa', value: 'Yabucoa' },
  { label: 'Yabucoa', value: 'Yabucoa' },
  { label: 'Yabucoa Municipio', value: 'Yabucoa Municipio' },
  { label: 'Yabu-Shi', value: 'Yabu-Shi' },
  { label: 'Yabuth', value: 'Yabuth' },
  { label: 'Yachimata', value: 'Yachimata' },
  { label: 'Yachimata-Shi', value: 'Yachimata-Shi' },
  { label: 'Yachiyo-Shi', value: 'Yachiyo-Shi' },
  { label: 'Yacimiento Río Turbio', value: 'Yacimiento Río Turbio' },
  { label: 'Yackandandah', value: 'Yackandandah' },
  { label: 'Yacolt', value: 'Yacolt' },
  { label: 'Yacopí', value: 'Yacopí' },
  { label: 'Yacuanquer', value: 'Yacuanquer' },
  { label: 'Yacuiba', value: 'Yacuiba' },
  { label: 'Yadgir', value: 'Yadgir' },
  { label: 'Yadkin County', value: 'Yadkin County' },
  { label: 'Yadkinville', value: 'Yadkinville' },
  { label: 'Yadrin', value: 'Yadrin' },
  { label: 'Yadrinskiy Rayon', value: 'Yadrinskiy Rayon' },
  { label: 'Yafaa', value: 'Yafaa' },
  { label: 'Yafran', value: 'Yafran' },
  { label: 'Yaganiza', value: 'Yaganiza' },
  { label: 'Yağlıdere', value: 'Yağlıdere' },
  { label: 'Yago', value: 'Yago' },
  { label: 'Yagodnoye', value: 'Yagodnoye' },
  { label: 'Yagoona', value: 'Yagoona' },
  { label: 'Yagoua', value: 'Yagoua' },
  { label: 'Yagry', value: 'Yagry' },
  { label: 'Yaguachi Nuevo', value: 'Yaguachi Nuevo' },
  { label: 'Yaguacire', value: 'Yaguacire' },
  { label: 'Yaguajay', value: 'Yaguajay' },
  { label: 'Yaguará', value: 'Yaguará' },
  { label: 'Yaguarón', value: 'Yaguarón' },
  { label: 'Yaguate', value: 'Yaguate' },
  { label: 'Yagunovo', value: 'Yagunovo' },
  { label: 'Yagunovskiy', value: 'Yagunovskiy' },
  { label: 'Yahil’Nytsya', value: 'Yahil’Nytsya' },
  { label: 'Yahotyn', value: 'Yahotyn' },
  { label: 'Yahotyns’Kyy Rayon', value: 'Yahotyns’Kyy Rayon' },
  { label: 'Yahr', value: 'Yahr' },
  { label: 'Yahşihan', value: 'Yahşihan' },
  { label: 'Yahualica', value: 'Yahualica' },
  { label: 'Yahualica De González Gallo', value: 'Yahualica De González Gallo' },
  { label: 'Yahyalı', value: 'Yahyalı' },
  { label: 'Yainville', value: 'Yainville' },
  { label: 'Yaita', value: 'Yaita' },
  { label: 'Yaita-Shi', value: 'Yaita-Shi' },
  { label: 'Yaiza', value: 'Yaiza' },
  { label: 'Yaizu', value: 'Yaizu' },
  { label: 'Yaizu Shi', value: 'Yaizu Shi' },
  { label: 'Yajalón', value: 'Yajalón' },
  { label: 'Yajiwa', value: 'Yajiwa' },
  { label: 'Yakakent', value: 'Yakakent' },
  { label: 'Yakamia', value: 'Yakamia' },
  { label: 'Yakeshi', value: 'Yakeshi' },
  { label: 'Yakhroma', value: 'Yakhroma' },
  { label: 'Yakima', value: 'Yakima' },
  { label: 'Yakima County', value: 'Yakima County' },
  { label: 'Yakimovo', value: 'Yakimovo' },
  { label: 'Yako', value: 'Yako' },
  { label: 'Yakoruda', value: 'Yakoruda' },
  { label: 'Yakou', value: 'Yakou' },
  { label: 'Yakou', value: 'Yakou' },
  { label: 'Yakovlevka', value: 'Yakovlevka' },
  { label: 'Yakovlevo', value: 'Yakovlevo' },
  { label: 'Yakovlevo', value: 'Yakovlevo' },
  { label: 'Yakovlevskiy Rayon', value: 'Yakovlevskiy Rayon' },
  { label: 'Yakovlevskiy Rayon', value: 'Yakovlevskiy Rayon' },
  { label: 'Yakovlevskoye', value: 'Yakovlevskoye' },
  { label: 'Yaksatovo', value: 'Yaksatovo' },
  { label: 'Yakutat City And Borough', value: 'Yakutat City And Borough' },
  { label: 'Yakutiye', value: 'Yakutiye' },
  { label: 'Yakutsk', value: 'Yakutsk' },
  { label: 'Yakymivka', value: 'Yakymivka' },
  { label: 'Yakymivka Raion', value: 'Yakymivka Raion' },
  { label: 'Yal’Chikskiy Rayon', value: 'Yal’Chikskiy Rayon' },
  { label: 'Yala', value: 'Yala' },
  { label: 'Yala', value: 'Yala' },
  { label: 'Yalagüina', value: 'Yalagüina' },
  { label: 'Yalaha', value: 'Yalaha' },
  { label: 'Yalang ', value: 'Yalang ' },
  { label: 'Yalding', value: 'Yalding' },
  { label: 'Yale', value: 'Yale' },
  { label: 'Yale', value: 'Yale' },
  { label: 'Yalfan', value: 'Yalfan' },
  { label: 'Yalgoo', value: 'Yalgoo' },
  { label: 'Yalí', value: 'Yalí' },
  { label: 'Yalıhüyük', value: 'Yalıhüyük' },
  { label: 'Yalina', value: 'Yalina' },
  { label: 'Yalizava', value: 'Yalizava' },
  { label: 'Yalkhoy-Mokhk', value: 'Yalkhoy-Mokhk' },
  { label: 'Yalkoba', value: 'Yalkoba' },
  { label: 'Yallah', value: 'Yallah' },
  { label: 'Yallahs', value: 'Yallahs' },
  { label: 'Yallambie', value: 'Yallambie' },
  { label: 'Yallingup', value: 'Yallingup' },
  { label: 'Yallourn North', value: 'Yallourn North' },
  { label: 'Yalobusha County', value: 'Yalobusha County' },
  { label: 'Yalta', value: 'Yalta' },
  { label: 'Yalta', value: 'Yalta' },
  { label: 'Yaltem', value: 'Yaltem' },
  { label: 'Yalutorovsk', value: 'Yalutorovsk' },
  { label: 'Yalvaç', value: 'Yalvaç' },
  { label: 'Yalyalup', value: 'Yalyalup' },
  { label: 'Yam', value: 'Yam' },
  { label: 'Yamachiche', value: 'Yamachiche' },
  { label: 'Yamada', value: 'Yamada' },
  { label: 'Yamaga Shi', value: 'Yamaga Shi' },
  { label: 'Yamagata', value: 'Yamagata' },
  { label: 'Yamagata', value: 'Yamagata' },
  { label: 'Yamagata Shi', value: 'Yamagata Shi' },
  { label: 'Yamagata-Shi', value: 'Yamagata-Shi' },
  { label: 'Yamaguchi Shi', value: 'Yamaguchi Shi' },
  { label: 'Yamakita', value: 'Yamakita' },
  { label: 'Yamanashi-Shi', value: 'Yamanashi-Shi' },
  { label: 'Yamanouchi', value: 'Yamanouchi' },
  { label: 'Yamanto', value: 'Yamanto' },
  { label: 'Yamaranguila', value: 'Yamaranguila' },
  { label: 'Yamasá', value: 'Yamasá' },
  { label: 'Yamatokōriyama-Shi', value: 'Yamatokōriyama-Shi' },
  { label: 'Yamato-Shi', value: 'Yamato-Shi' },
  { label: 'Yamatotakada-Shi', value: 'Yamatotakada-Shi' },
  { label: 'Yamazakichō-Nakabirose', value: 'Yamazakichō-Nakabirose' },
  { label: 'Yamba', value: 'Yamba' },
  { label: 'Yambirno', value: 'Yambirno' },
  { label: 'Yambol', value: 'Yambol' },
  { label: 'Yamchi', value: 'Yamchi' },
  { label: 'Yame Shi', value: 'Yame Shi' },
  { label: 'Yamethin', value: 'Yamethin' },
  { label: 'Yamhill', value: 'Yamhill' },
  { label: 'Yamhill County', value: 'Yamhill County' },
  { label: 'Yamkino', value: 'Yamkino' },
  { label: 'Yamnytsya', value: 'Yamnytsya' },
  { label: 'Yamoto', value: 'Yamoto' },
  { label: 'Yamoussoukro', value: 'Yamoussoukro' },
  { label: 'Yampil’', value: 'Yampil’' },
  { label: 'Yampil’', value: 'Yampil’' },
  { label: 'Yamrat', value: 'Yamrat' },
  { label: 'Yamunanagar', value: 'Yamunanagar' },
  { label: 'Yan Na Wa', value: 'Yan Na Wa' },
  { label: 'Yanacancha', value: 'Yanacancha' },
  { label: 'Yanagawa', value: 'Yanagawa' },
  { label: 'Yanagawa Shi', value: 'Yanagawa Shi' },
  { label: 'Yanagawamachi-Saiwaichō', value: 'Yanagawamachi-Saiwaichō' },
  { label: 'Yanai Shi', value: 'Yanai Shi' },
  { label: 'Yanam', value: 'Yanam' },
  { label: 'Yanam', value: 'Yanam' },
  { label: 'Yanamalakuduru', value: 'Yanamalakuduru' },
  { label: 'Yanaoca', value: 'Yanaoca' },
  { label: 'Yanaul', value: 'Yanaul' },
  { label: 'Yanaul’Skiy Rayon', value: 'Yanaul’Skiy Rayon' },
  { label: 'Yanbian Chaoxianzu Zizhizhou', value: 'Yanbian Chaoxianzu Zizhizhou' },
  { label: 'Yanbu', value: 'Yanbu' },
  { label: 'Yancey County', value: 'Yancey County' },
  { label: 'Yanceyville', value: 'Yanceyville' },
  { label: 'Yancheng', value: 'Yancheng' },
  { label: 'Yancheng', value: 'Yancheng' },
  { label: 'Yanchep', value: 'Yanchep' },
  { label: 'Yanda Bayo', value: 'Yanda Bayo' },
  { label: 'Yandev', value: 'Yandev' },
  { label: 'Yandina', value: 'Yandina' },
  { label: 'Yandyki', value: 'Yandyki' },
  { label: 'Yang Talat', value: 'Yang Talat' },
  { label: 'Yanga', value: 'Yanga' },
  { label: 'Yangambi', value: 'Yangambi' },
  { label: 'Yangcheon-Gu', value: 'Yangcheon-Gu' },
  { label: 'Yangchun', value: 'Yangchun' },
  { label: 'Yangebup', value: 'Yangebup' },
  { label: 'Yanggu', value: 'Yanggu' },
  { label: 'Yanggu', value: 'Yanggu' },
  { label: 'Yanggu-Gun', value: 'Yanggu-Gun' },
  { label: 'Yanghou', value: 'Yanghou' },
  { label: 'Yangi Marg‘Ilon', value: 'Yangi Marg‘Ilon' },
  { label: 'Yangiobod', value: 'Yangiobod' },
  { label: 'Yangiqo‘Rg‘On', value: 'Yangiqo‘Rg‘On' },
  { label: 'Yangirabot', value: 'Yangirabot' },
  { label: 'Yangiyer', value: 'Yangiyer' },
  { label: 'Yangiyŭl', value: 'Yangiyŭl' },
  { label: 'Yangjiang', value: 'Yangjiang' },
  { label: 'Yangjinzhuang', value: 'Yangjinzhuang' },
  { label: 'Yangju', value: 'Yangju' },
  { label: 'Yangju-Si', value: 'Yangju-Si' },
  { label: 'Yangliuqing', value: 'Yangliuqing' },
  { label: 'Yangon', value: 'Yangon' },
  { label: 'Yangpyeong-Gun', value: 'Yangpyeong-Gun' },
  { label: 'Yangpyŏng', value: 'Yangpyŏng' },
  { label: 'Yangquan', value: 'Yangquan' },
  { label: 'Yangsan', value: 'Yangsan' },
  { label: 'Yangshuo', value: 'Yangshuo' },
  { label: 'Yangtou', value: 'Yangtou' },
  { label: 'Yanguas', value: 'Yanguas' },
  { label: 'Yanguas De Eresma', value: 'Yanguas De Eresma' },
  { label: 'Yangyang-Gun', value: 'Yangyang-Gun' },
  { label: 'Yangzhong', value: 'Yangzhong' },
  { label: 'Yangzhou', value: 'Yangzhou' },
  { label: 'Yangzhou', value: 'Yangzhou' },
  { label: 'Yanıqlı', value: 'Yanıqlı' },
  { label: 'Yanis’Yarvi', value: 'Yanis’Yarvi' },
  { label: 'Yanishpole', value: 'Yanishpole' },
  { label: 'Yanji', value: 'Yanji' },
  { label: 'Yanjiang', value: 'Yanjiang' },
  { label: 'Yankalilla', value: 'Yankalilla' },
  { label: 'Yankton', value: 'Yankton' },
  { label: 'Yankton County', value: 'Yankton County' },
  { label: 'Yanliang', value: 'Yanliang' },
  { label: 'Yanmen', value: 'Yanmen' },
  { label: 'Yanqul', value: 'Yanqul' },
  { label: 'Yanshi Chengguanzhen', value: 'Yanshi Chengguanzhen' },
  { label: 'Yanta', value: 'Yanta' },
  { label: 'Yantai', value: 'Yantai' },
  { label: 'Yantal’', value: 'Yantal’' },
  { label: 'Yantarnoye', value: 'Yantarnoye' },
  { label: 'Yantarnyy', value: 'Yantarnyy' },
  { label: 'Yantian', value: 'Yantian' },
  { label: 'Yantikovo', value: 'Yantikovo' },
  { label: 'Yantikovskiy Rayon', value: 'Yantikovskiy Rayon' },
  { label: 'Yantongshan', value: 'Yantongshan' },
  { label: 'Yantzaza', value: 'Yantzaza' },
  { label: 'Yanykurgan', value: 'Yanykurgan' },
  { label: 'Yanzhou', value: 'Yanzhou' },
  { label: 'Yao', value: 'Yao' },
  { label: 'Yaonahuac', value: 'Yaonahuac' },
  { label: 'Yaoshi', value: 'Yaoshi' },
  { label: 'Yao-Shi', value: 'Yao-Shi' },
  { label: 'Yaoundé', value: 'Yaoundé' },
  { label: 'Yapak', value: 'Yapak' },
  { label: 'Yape', value: 'Yape' },
  { label: 'Yapeyú', value: 'Yapeyú' },
  { label: 'Yaphank', value: 'Yaphank' },
  { label: 'Yapraklı', value: 'Yapraklı' },
  { label: 'Yapton', value: 'Yapton' },
  { label: 'Yar', value: 'Yar' },
  { label: 'Yara', value: 'Yara' },
  { label: 'Yarada', value: 'Yarada' },
  { label: 'Yaransk', value: 'Yaransk' },
  { label: 'Yaranskiy Rayon', value: 'Yaranskiy Rayon' },
  { label: 'Yardımlı', value: 'Yardımlı' },
  { label: 'Yardley', value: 'Yardley' },
  { label: 'Yardville', value: 'Yardville' },
  { label: 'Yarega', value: 'Yarega' },
  { label: 'Yaremchans’Ks Mis’Krada', value: 'Yaremchans’Ks Mis’Krada' },
  { label: 'Yaremche', value: 'Yaremche' },
  { label: 'Yaren', value: 'Yaren' },
  { label: 'Yarensk', value: 'Yarensk' },
  { label: 'Yarīm', value: 'Yarīm' },
  { label: 'Yaring', value: 'Yaring' },
  { label: 'Yaritagua', value: 'Yaritagua' },
  { label: 'Yarkovo', value: 'Yarkovo' },
  { label: 'Yarkovo', value: 'Yarkovo' },
  { label: 'Yarkoye', value: 'Yarkoye' },
  { label: 'Yarkoye Pole', value: 'Yarkoye Pole' },
  { label: 'Yarm', value: 'Yarm' },
  { label: 'Yarmolyntsi', value: 'Yarmolyntsi' },
  { label: 'Yarmouk', value: 'Yarmouk' },
  { label: 'Yarmouth', value: 'Yarmouth' },
  { label: 'Yarmouth', value: 'Yarmouth' },
  { label: 'Yarmouth', value: 'Yarmouth' },
  { label: 'Yarmouth', value: 'Yarmouth' },
  { label: 'Yarmouth Port', value: 'Yarmouth Port' },
  { label: 'Yarnton', value: 'Yarnton' },
  { label: 'Yarom Qayah', value: 'Yarom Qayah' },
  { label: 'Yaromjeh Bagh', value: 'Yaromjeh Bagh' },
  { label: 'Yaroomba', value: 'Yaroomba' },
  { label: 'Yaropolets', value: 'Yaropolets' },
  { label: 'Yaroslavichi', value: 'Yaroslavichi' },
  { label: 'Yaroslavl', value: 'Yaroslavl' },
  { label: 'Yaroslavskaya', value: 'Yaroslavskaya' },
  { label: 'Yaroslavskiy', value: 'Yaroslavskiy' },
  { label: 'Yaroslavskiy', value: 'Yaroslavskiy' },
  { label: 'Yaroslavskiy Rayon', value: 'Yaroslavskiy Rayon' },
  { label: 'Yarovoye', value: 'Yarovoye' },
  { label: 'Yarowilca', value: 'Yarowilca' },
  { label: 'Yarqā', value: 'Yarqā' },
  { label: 'Yarra', value: 'Yarra' },
  { label: 'Yarra Glen', value: 'Yarra Glen' },
  { label: 'Yarra Junction', value: 'Yarra Junction' },
  { label: 'Yarra Ranges', value: 'Yarra Ranges' },
  { label: 'Yarrabah', value: 'Yarrabah' },
  { label: 'Yarrabilba', value: 'Yarrabilba' },
  { label: 'Yarragon', value: 'Yarragon' },
  { label: 'Yarralumla', value: 'Yarralumla' },
  { label: 'Yarram', value: 'Yarram' },
  { label: 'Yarraman', value: 'Yarraman' },
  { label: 'Yarrambat', value: 'Yarrambat' },
  { label: 'Yarravel', value: 'Yarravel' },
  { label: 'Yarraville', value: 'Yarraville' },
  { label: 'Yarrawarrah', value: 'Yarrawarrah' },
  { label: 'Yarrawonga', value: 'Yarrawonga' },
  { label: 'Yarriambiack', value: 'Yarriambiack' },
  { label: 'Yarrow Point', value: 'Yarrow Point' },
  { label: 'Yar-Sale', value: 'Yar-Sale' },
  { label: 'Yartsevo', value: 'Yartsevo' },
  { label: 'Yaruchel', value: 'Yaruchel' },
  { label: 'Yarula', value: 'Yarula' },
  { label: 'Yarumal', value: 'Yarumal' },
  { label: 'Yarumela', value: 'Yarumela' },
  { label: 'Yarun', value: 'Yarun' },
  { label: 'Yasenevo', value: 'Yasenevo' },
  { label: 'Yasenskaya', value: 'Yasenskaya' },
  { label: 'Yasha', value: 'Yasha' },
  { label: 'Yashalta', value: 'Yashalta' },
  { label: 'Yashaltinskiy Rayon', value: 'Yashaltinskiy Rayon' },
  { label: 'Yashan', value: 'Yashan' },
  { label: 'Yashanal', value: 'Yashanal' },
  { label: 'Yashikera', value: 'Yashikera' },
  { label: 'Yashio', value: 'Yashio' },
  { label: 'Yashio-Shi', value: 'Yashio-Shi' },
  { label: 'Yashiro', value: 'Yashiro' },
  { label: 'Yashkino', value: 'Yashkino' },
  { label: 'Yashkul’', value: 'Yashkul’' },
  { label: 'Yasinya', value: 'Yasinya' },
  { label: 'Yasnaya Polyana', value: 'Yasnaya Polyana' },
  { label: 'Yasnogorsk', value: 'Yasnogorsk' },
  { label: 'Yasnogorskiy Rayon', value: 'Yasnogorskiy Rayon' },
  { label: 'Yasnopolyanskoye', value: 'Yasnopolyanskoye' },
  { label: 'Yasnyy', value: 'Yasnyy' },
  { label: 'Yasothon', value: 'Yasothon' },
  { label: 'Yass', value: 'Yass' },
  { label: 'Yass Valley', value: 'Yass Valley' },
  { label: 'Yasuda', value: 'Yasuda' },
  { label: 'Yasugi Shi', value: 'Yasugi Shi' },
  { label: 'Yasugichō', value: 'Yasugichō' },
  { label: 'Yasuj', value: 'Yasuj' },
  { label: 'Yasuoka', value: 'Yasuoka' },
  { label: 'Yasu-Shi', value: 'Yasu-Shi' },
  { label: 'Yasynuvata', value: 'Yasynuvata' },
  { label: 'Yasynuvata Raion', value: 'Yasynuvata Raion' },
  { label: 'Yatağan', value: 'Yatağan' },
  { label: 'Yataity Calle', value: 'Yataity Calle' },
  { label: 'Yataity Del Norte', value: 'Yataity Del Norte' },
  { label: 'Yatala', value: 'Yatala' },
  { label: 'Yate', value: 'Yate' },
  { label: 'Yateley', value: 'Yateley' },
  { label: 'Yates Center', value: 'Yates Center' },
  { label: 'Yates County', value: 'Yates County' },
  { label: 'Yatinuwara', value: 'Yatinuwara' },
  { label: 'Yatomi-Shi', value: 'Yatomi-Shi' },
  { label: 'Yatou', value: 'Yatou' },
  { label: 'Yátova', value: 'Yátova' },
  { label: 'Yatsuomachi-Higashikumisaka', value: 'Yatsuomachi-Higashikumisaka' },
  { label: 'Yatsushiro Shi', value: 'Yatsushiro Shi' },
  { label: 'Yatton', value: 'Yatton' },
  { label: 'Yatunpu', value: 'Yatunpu' },
  { label: 'Yatzachi', value: 'Yatzachi' },
  { label: 'Yauco', value: 'Yauco' },
  { label: 'Yauco', value: 'Yauco' },
  { label: 'Yauhquemehcan', value: 'Yauhquemehcan' },
  { label: 'Yaurel', value: 'Yaurel' },
  { label: 'Yautepec', value: 'Yautepec' },
  { label: 'Yauya', value: 'Yauya' },
  { label: 'Yauyupe', value: 'Yauyupe' },
  { label: 'Yaval', value: 'Yaval' },
  { label: 'Yavapai County', value: 'Yavapai County' },
  { label: 'Yavaraté', value: 'Yavaraté' },
  { label: 'Yavaros', value: 'Yavaros' },
  { label: 'Yavas', value: 'Yavas' },
  { label: 'Yavatmal', value: 'Yavatmal' },
  { label: 'Yaviza', value: 'Yaviza' },
  { label: 'Yavlenka', value: 'Yavlenka' },
  { label: 'Yavné', value: 'Yavné' },
  { label: 'Yavne’El', value: 'Yavne’El' },
  { label: 'Yavoriv', value: 'Yavoriv' },
  { label: 'Yavuzeli', value: 'Yavuzeli' },
  { label: 'Yawata', value: 'Yawata' },
  { label: 'Yawata', value: 'Yawata' },
  { label: 'Yawata Shi', value: 'Yawata Shi' },
  { label: 'Yawatahama-Shi', value: 'Yawatahama-Shi' },
  { label: 'Yaxcabá', value: 'Yaxcabá' },
  { label: 'Yaxcopoil', value: 'Yaxcopoil' },
  { label: 'Yaxe', value: 'Yaxe' },
  { label: 'Yaxhachen', value: 'Yaxhachen' },
  { label: 'Yaxkukul', value: 'Yaxkukul' },
  { label: 'Yaxley', value: 'Yaxley' },
  { label: 'Yaya', value: 'Yaya' },
  { label: 'Yayas De Viajama', value: 'Yayas De Viajama' },
  { label: 'Yaycı', value: 'Yaycı' },
  { label: 'Yayladağı', value: 'Yayladağı' },
  { label: 'Yayladere', value: 'Yayladere' },
  { label: 'Yaypan', value: 'Yaypan' },
  { label: 'Yayva', value: 'Yayva' },
  { label: 'Yazd', value: 'Yazd' },
  { label: 'Yazdan Shahr', value: 'Yazdan Shahr' },
  { label: 'Yazıhan', value: 'Yazıhan' },
  { label: 'Yazman', value: 'Yazman' },
  { label: 'Yazoo City', value: 'Yazoo City' },
  { label: 'Yazoo County', value: 'Yazoo County' },
  { label: 'Yazykovo', value: 'Yazykovo' },
  { label: 'Yazykovo', value: 'Yazykovo' },
  { label: 'Ybbs An Der Donau', value: 'Ybbs An Der Donau' },
  { label: 'Ybbsitz', value: 'Ybbsitz' },
  { label: 'Ybycuí', value: 'Ybycuí' },
  { label: 'Ychoux', value: 'Ychoux' },
  { label: 'Ydes', value: 'Ydes' },
  { label: 'Ýdra', value: 'Ýdra' },
  { label: 'Yea', value: 'Yea' },
  { label: 'Yeadon', value: 'Yeadon' },
  { label: 'Yeadon', value: 'Yeadon' },
  { label: 'Yeagertown', value: 'Yeagertown' },
  { label: 'Yealmpton', value: 'Yealmpton' },
  { label: 'Yebaishou', value: 'Yebaishou' },
  { label: 'Yeban Norte', value: 'Yeban Norte' },
  { label: 'Yeban Norte', value: 'Yeban Norte' },
  { label: 'Yébenes Los', value: 'Yébenes Los' },
  { label: 'Yebes', value: 'Yebes' },
  { label: 'Yébleron', value: 'Yébleron' },
  { label: 'Yebra', value: 'Yebra' },
  { label: 'Yebra De Basa', value: 'Yebra De Basa' },
  { label: 'Yecapixteca', value: 'Yecapixteca' },
  { label: 'Yecapixtla', value: 'Yecapixtla' },
  { label: 'Yecheon-Gun', value: 'Yecheon-Gun' },
  { label: 'Yecla', value: 'Yecla' },
  { label: 'Yecla De Yeltes', value: 'Yecla De Yeltes' },
  { label: 'Yécora', value: 'Yécora' },
  { label: 'Yécora/Iekora', value: 'Yécora/Iekora' },
  { label: 'Yecuatla', value: 'Yecuatla' },
  { label: 'Yedisu', value: 'Yedisu' },
  { label: 'Yedogon', value: 'Yedogon' },
  { label: 'Yedrovo', value: 'Yedrovo' },
  { label: 'Yeed', value: 'Yeed' },
  { label: 'Yeerongpilly', value: 'Yeerongpilly' },
  { label: 'Yefimovskiy', value: 'Yefimovskiy' },
  { label: 'Yefremov', value: 'Yefremov' },
  { label: 'Yeganeh', value: 'Yeganeh' },
  { label: 'Yeghegis', value: 'Yeghegis' },
  { label: 'Yeghegnadzor', value: 'Yeghegnadzor' },
  { label: 'Yeghegnavan', value: 'Yeghegnavan' },
  { label: 'Yeghegnut', value: 'Yeghegnut' },
  { label: 'Yeghegnut', value: 'Yeghegnut' },
  { label: 'Yeghvard', value: 'Yeghvard' },
  { label: 'Yegor’Yevsk', value: 'Yegor’Yevsk' },
  { label: 'Yegor’Yevskiy Rayon', value: 'Yegor’Yevskiy Rayon' },
  { label: 'Yegorlykskaya', value: 'Yegorlykskaya' },
  { label: 'Yegros', value: 'Yegros' },
  { label: 'Yehualtepec', value: 'Yehualtepec' },
  { label: 'Yehud', value: 'Yehud' },
  { label: 'Yehud-Monosson', value: 'Yehud-Monosson' },
  { label: 'Yekanabad', value: 'Yekanabad' },
  { label: 'Yekaterinburg', value: 'Yekaterinburg' },
  { label: 'Yekaterinoslavka', value: 'Yekaterinoslavka' },
  { label: 'Yekaterinovka', value: 'Yekaterinovka' },
  { label: 'Yekaterinovka', value: 'Yekaterinovka' },
  { label: 'Yekimovichi', value: 'Yekimovichi' },
  { label: 'Yekleh', value: 'Yekleh' },
  { label: 'Yel’Digino', value: 'Yel’Digino' },
  { label: 'Yel’Nikovskiy Rayon', value: 'Yel’Nikovskiy Rayon' },
  { label: 'Yel’Nya', value: 'Yel’Nya' },
  { label: 'Yel’Sk', value: 'Yel’Sk' },
  { label: 'Yel’Tsovskiy Rayon', value: 'Yel’Tsovskiy Rayon' },
  { label: 'Yelabuga', value: 'Yelabuga' },
  { label: 'Yelabuzhskiy Rayon', value: 'Yelabuzhskiy Rayon' },
  { label: 'Yelahanka', value: 'Yelahanka' },
  { label: 'Yélamos De Abajo', value: 'Yélamos De Abajo' },
  { label: 'Yélamos De Arriba', value: 'Yélamos De Arriba' },
  { label: 'Yelan', value: 'Yelan' },
  { label: 'Yelan’-Koleno', value: 'Yelan’-Koleno' },
  { label: 'Yelan’-Kolenovskiy', value: 'Yelan’-Kolenovskiy' },
  { label: 'Yelandur', value: 'Yelandur' },
  { label: 'Yelanskiy', value: 'Yelanskiy' },
  { label: 'Yelantsy', value: 'Yelantsy' },
  { label: 'Yelat’Ma', value: 'Yelat’Ma' },
  { label: 'Yelbarga', value: 'Yelbarga' },
  { label: 'Yelenendorf', value: 'Yelenendorf' },
  { label: 'Yeles', value: 'Yeles' },
  { label: 'Yelets', value: 'Yelets' },
  { label: 'Yeletskiy Rayon', value: 'Yeletskiy Rayon' },
  { label: 'Yelizavetinka', value: 'Yelizavetinka' },
  { label: 'Yelizavetino', value: 'Yelizavetino' },
  { label: 'Yelizavetinskaya', value: 'Yelizavetinskaya' },
  { label: 'Yelizavetinskoye', value: 'Yelizavetinskoye' },
  { label: 'Yelizovo', value: 'Yelizovo' },
  { label: 'Yelizovskiy Rayon', value: 'Yelizovskiy Rayon' },
  { label: 'Yelkhovka', value: 'Yelkhovka' },
  { label: 'Yëlkino', value: 'Yëlkino' },
  { label: 'Yell County', value: 'Yell County' },
  { label: 'Yellandu', value: 'Yellandu' },
  { label: 'Yellapur', value: 'Yellapur' },
  { label: 'Yellow Medicine County', value: 'Yellow Medicine County' },
  { label: 'Yellow Springs', value: 'Yellow Springs' },
  { label: 'Yellowknife', value: 'Yellowknife' },
  { label: 'Yellowstone County', value: 'Yellowstone County' },
  { label: 'Yellville', value: 'Yellville' },
  { label: 'Yelm', value: 'Yelm' },
  { label: 'Yelo', value: 'Yelo' },
  { label: 'Yelovo', value: 'Yelovo' },
  { label: 'Yelshanka', value: 'Yelshanka' },
  { label: 'Yelverton', value: 'Yelverton' },
  { label: 'Yelwa', value: 'Yelwa' },
  { label: 'Yelyzavethradka', value: 'Yelyzavethradka' },
  { label: 'Yem', value: 'Yem' },
  { label: 'Yemanzhelinka', value: 'Yemanzhelinka' },
  { label: 'Yemanzhelinsk', value: 'Yemanzhelinsk' },
  { label: 'Yémeda', value: 'Yémeda' },
  { label: 'Yemel’Yanovo', value: 'Yemel’Yanovo' },
  { label: 'Yemel’Yanovskiy Rayon', value: 'Yemel’Yanovskiy Rayon' },
  { label: 'Yemelyanovka', value: 'Yemelyanovka' },
  { label: 'Yemetsk', value: 'Yemetsk' },
  { label: 'Yemil’Chyne', value: 'Yemil’Chyne' },
  { label: 'Yemilchyne', value: 'Yemilchyne' },
  { label: 'Yemtsa', value: 'Yemtsa' },
  { label: 'Yemva', value: 'Yemva' },
  { label: 'Yên Bái', value: 'Yên Bái' },
  { label: 'Yên Vinh', value: 'Yên Vinh' },
  { label: 'Yenagoa', value: 'Yenagoa' },
  { label: 'Yenakiyeve', value: 'Yenakiyeve' },
  { label: 'Yenangyaung', value: 'Yenangyaung' },
  { label: 'Yenda', value: 'Yenda' },
  { label: 'Yendi', value: 'Yendi' },
  { label: 'Yengema', value: 'Yengema' },
  { label: 'Yeni Suraxanı', value: 'Yeni Suraxanı' },
  { label: 'Yeniçağa', value: 'Yeniçağa' },
  { label: 'Yenice', value: 'Yenice' },
  { label: 'Yenice', value: 'Yenice' },
  { label: 'Yenifakılı', value: 'Yenifakılı' },
  { label: 'Yenimahalle', value: 'Yenimahalle' },
  { label: 'Yenipazar', value: 'Yenipazar' },
  { label: 'Yenipazar', value: 'Yenipazar' },
  { label: 'Yenişarbademli', value: 'Yenişarbademli' },
  { label: 'Yenişehir', value: 'Yenişehir' },
  { label: 'Yenişehir', value: 'Yenişehir' },
  { label: 'Yenişehir', value: 'Yenişehir' },
  { label: 'Yeniseysk', value: 'Yeniseysk' },
  { label: 'Yeniseyskiy Rayon', value: 'Yeniseyskiy Rayon' },
  { label: 'Yenne', value: 'Yenne' },
  { label: 'Yennora', value: 'Yennora' },
  { label: 'Yenotayevka', value: 'Yenotayevka' },
  { label: 'Yeoju-Si', value: 'Yeoju-Si' },
  { label: 'Yeola', value: 'Yeola' },
  { label: 'Yeoncheon-Gun', value: 'Yeoncheon-Gun' },
  { label: 'Yeongam', value: 'Yeongam' },
  { label: 'Yeongam-Gun', value: 'Yeongam-Gun' },
  { label: 'Yeongcheon-Si', value: 'Yeongcheon-Si' },
  { label: 'Yeongdeok-Gun', value: 'Yeongdeok-Gun' },
  { label: 'Yeongdeungpo-Gu', value: 'Yeongdeungpo-Gu' },
  { label: 'Yeongdo-Gu', value: 'Yeongdo-Gu' },
  { label: 'Yeongdong', value: 'Yeongdong' },
  { label: 'Yeongdong-Gun', value: 'Yeongdong-Gun' },
  { label: 'Yeonggwang', value: 'Yeonggwang' },
  { label: 'Yeonggwang-Gun', value: 'Yeonggwang-Gun' },
  { label: 'Yeongju-Si', value: 'Yeongju-Si' },
  { label: 'Yeongyang-Gun', value: 'Yeongyang-Gun' },
  { label: 'Yeonil', value: 'Yeonil' },
  { label: 'Yeonsu-Gu', value: 'Yeonsu-Gu' },
  { label: 'Yeosu', value: 'Yeosu' },
  { label: 'Yeovil', value: 'Yeovil' },
  { label: 'Yepes', value: 'Yepes' },
  { label: 'Yepifan’', value: 'Yepifan’' },
  { label: 'Yepocapa', value: 'Yepocapa' },
  { label: 'Yeppoon', value: 'Yeppoon' },
  { label: 'Yeppoon City Centre', value: 'Yeppoon City Centre' },
  { label: 'Yeraliyev', value: 'Yeraliyev' },
  { label: 'Yeranos', value: 'Yeranos' },
  { label: 'Yeraskhahun', value: 'Yeraskhahun' },
  { label: 'Yerazgavors', value: 'Yerazgavors' },
  { label: 'Yerba Buena', value: 'Yerba Buena' },
  { label: 'Yerbabuena', value: 'Yerbabuena' },
  { label: 'Yerbabuena', value: 'Yerbabuena' },
  { label: 'Yerbas Buenas', value: 'Yerbas Buenas' },
  { label: 'Yerbogachen', value: 'Yerbogachen' },
  { label: 'Yerevan', value: 'Yerevan' },
  { label: 'Yerington', value: 'Yerington' },
  { label: 'Yerköy', value: 'Yerköy' },
  { label: 'Yerky', value: 'Yerky' },
  { label: 'Yermakovo', value: 'Yermakovo' },
  { label: 'Yermakovskoye', value: 'Yermakovskoye' },
  { label: 'Yermekeyevo', value: 'Yermekeyevo' },
  { label: 'Yermentau', value: 'Yermentau' },
  { label: 'Yermish’', value: 'Yermish’' },
  { label: 'Yermolayevo', value: 'Yermolayevo' },
  { label: 'Yermolino', value: 'Yermolino' },
  { label: 'Yermolino', value: 'Yermolino' },
  { label: 'Yernes Y Tameza', value: 'Yernes Y Tameza' },
  { label: 'Yerofey Pavlovich', value: 'Yerofey Pavlovich' },
  { label: 'Yeroẖam', value: 'Yeroẖam' },
  { label: 'Yeronga', value: 'Yeronga' },
  { label: 'Yerres', value: 'Yerres' },
  { label: 'Yerri', value: 'Yerri' },
  { label: 'Yerrinbool', value: 'Yerrinbool' },
  { label: 'Yerseke', value: 'Yerseke' },
  { label: 'Yershichi', value: 'Yershichi' },
  { label: 'Yershov', value: 'Yershov' },
  { label: 'Yershovo', value: 'Yershovo' },
  { label: 'Yertarskiy', value: 'Yertarskiy' },
  { label: 'Yertsevo', value: 'Yertsevo' },
  { label: 'Yerville', value: 'Yerville' },
  { label: 'Yesa', value: 'Yesa' },
  { label: 'Yesa La', value: 'Yesa La' },
  { label: 'Yesan', value: 'Yesan' },
  { label: 'Yesan-Gun', value: 'Yesan-Gun' },
  { label: 'Yeserlu', value: 'Yeserlu' },
  { label: 'Yésero', value: 'Yésero' },
  { label: 'Yeşilhisar', value: 'Yeşilhisar' },
  { label: 'Yeşilli', value: 'Yeşilli' },
  { label: 'Yeşilova', value: 'Yeşilova' },
  { label: 'Yeşilyurt', value: 'Yeşilyurt' },
  { label: 'Yeşilyurt', value: 'Yeşilyurt' },
  { label: 'Yesman’', value: 'Yesman’' },
  { label: 'Yessentuki', value: 'Yessentuki' },
  { label: 'Yessentukskaya', value: 'Yessentukskaya' },
  { label: 'Yeste', value: 'Yeste' },
  { label: 'Yetkul’', value: 'Yetkul’' },
  { label: 'Yetla', value: 'Yetla' },
  { label: 'Yetla De Juárez', value: 'Yetla De Juárez' },
  { label: 'Yetlancingo', value: 'Yetlancingo' },
  { label: 'Yetminster', value: 'Yetminster' },
  { label: 'Yevlakh', value: 'Yevlakh' },
  { label: 'Yevpatoriya', value: 'Yevpatoriya' },
  { label: 'Yèvres', value: 'Yèvres' },
  { label: 'Yextla', value: 'Yextla' },
  { label: 'Yeysk', value: 'Yeysk' },
  { label: 'Yeyskoye Ukrepleniye', value: 'Yeyskoye Ukrepleniye' },
  { label: 'Yffiniac', value: 'Yffiniac' },
  { label: 'Ygatimi', value: 'Ygatimi' },
  { label: 'Ygos-Saint-Saturnin', value: 'Ygos-Saint-Saturnin' },
  { label: 'Yguazú', value: 'Yguazú' },
  { label: 'Yhú', value: 'Yhú' },
  { label: 'Yibeljoj', value: 'Yibeljoj' },
  { label: 'Yibin', value: 'Yibin' },
  { label: 'Yichang', value: 'Yichang' },
  { label: 'Yicheng', value: 'Yicheng' },
  { label: 'Yicheng', value: 'Yicheng' },
  { label: 'Yichun', value: 'Yichun' },
  { label: 'Yichun', value: 'Yichun' },
  { label: 'Yığılca', value: 'Yığılca' },
  { label: 'Yigou', value: 'Yigou' },
  { label: 'Yilan', value: 'Yilan' },
  { label: 'Yilan', value: 'Yilan' },
  { label: 'Yıldırım', value: 'Yıldırım' },
  { label: 'Yıldızeli', value: 'Yıldızeli' },
  { label: 'Yilgarn', value: 'Yilgarn' },
  { label: 'Yima', value: 'Yima' },
  { label: 'Yimnón', value: 'Yimnón' },
  { label: 'Yinchuan', value: 'Yinchuan' },
  { label: 'Yingcheng', value: 'Yingcheng' },
  { label: 'Yingchuan', value: 'Yingchuan' },
  { label: 'Yingdu', value: 'Yingdu' },
  { label: 'Yingkou', value: 'Yingkou' },
  { label: 'Yinglin', value: 'Yinglin' },
  { label: 'Yingshang Chengguanzhen', value: 'Yingshang Chengguanzhen' },
  { label: 'Yinliu', value: 'Yinliu' },
  { label: 'Yinzhu', value: 'Yinzhu' },
  { label: 'Yirga ‘Alem', value: 'Yirga ‘Alem' },
  { label: 'Yirol', value: 'Yirol' },
  { label: 'Yishui', value: 'Yishui' },
  { label: 'Yishun', value: 'Yishun' },
  { label: 'Yiwu', value: 'Yiwu' },
  { label: 'Yixingfu', value: 'Yixingfu' },
  { label: 'Yiyang', value: 'Yiyang' },
  { label: 'Yizhivtsi', value: 'Yizhivtsi' },
  { label: 'Ylämaa', value: 'Ylämaa' },
  { label: 'Yläne', value: 'Yläne' },
  { label: 'Ylihärmä', value: 'Ylihärmä' },
  { label: 'Yli-Ii', value: 'Yli-Ii' },
  { label: 'Ylikiiminki', value: 'Ylikiiminki' },
  { label: 'Ylistaro', value: 'Ylistaro' },
  { label: 'Ylitornio', value: 'Ylitornio' },
  { label: 'Ylivieska', value: 'Ylivieska' },
  { label: 'Ylöjärvi', value: 'Ylöjärvi' },
  { label: 'Ymare', value: 'Ymare' },
  { label: 'Ymittos', value: 'Ymittos' },
  { label: 'Ynysybwl', value: 'Ynysybwl' },
  { label: 'Yoakum', value: 'Yoakum' },
  { label: 'Yoakum County', value: 'Yoakum County' },
  { label: 'Yobain', value: 'Yobain' },
  { label: 'Yocón', value: 'Yocón' },
  { label: 'Yoe', value: 'Yoe' },
  { label: 'Yogyakarta', value: 'Yogyakarta' },
  { label: 'Yoichi', value: 'Yoichi' },
  { label: 'Yokadouma', value: 'Yokadouma' },
  { label: 'Yōkaichiba', value: 'Yōkaichiba' },
  { label: 'Yokine', value: 'Yokine' },
  { label: 'Yokkaichi-Shi', value: 'Yokkaichi-Shi' },
  { label: 'Yoko', value: 'Yoko' },
  { label: 'Yokohama', value: 'Yokohama' },
  { label: 'Yokohama Shi', value: 'Yokohama Shi' },
  { label: 'Yokosuka', value: 'Yokosuka' },
  { label: 'Yokosuka Shi', value: 'Yokosuka Shi' },
  { label: 'Yokote', value: 'Yokote' },
  { label: 'Yokote-Shi', value: 'Yokote-Shi' },
  { label: 'Yol', value: 'Yol' },
  { label: 'Yola', value: 'Yola' },
  { label: 'Yolo County', value: 'Yolo County' },
  { label: 'Yolombó', value: 'Yolombó' },
  { label: 'Yolöten', value: 'Yolöten' },
  { label: 'Yolotepec', value: 'Yolotepec' },
  { label: 'Yoloxóchitl', value: 'Yoloxóchitl' },
  { label: 'Yŏmju-Ŭp', value: 'Yŏmju-Ŭp' },
  { label: 'Yomou', value: 'Yomou' },
  { label: 'Yomra', value: 'Yomra' },
  { label: 'Yonago Shi', value: 'Yonago Shi' },
  { label: 'Yonakuni', value: 'Yonakuni' },
  { label: 'Yŏnan-Ŭp', value: 'Yŏnan-Ŭp' },
  { label: 'Yoncalla', value: 'Yoncalla' },
  { label: 'Yondó', value: 'Yondó' },
  { label: 'Yonedacho Sendo', value: 'Yonedacho Sendo' },
  { label: 'Yonezawa', value: 'Yonezawa' },
  { label: 'Yonezawa Shi', value: 'Yonezawa Shi' },
  { label: 'Yong Peng', value: 'Yong Peng' },
  { label: 'Yŏngbyŏn', value: 'Yŏngbyŏn' },
  { label: 'Yongchuan', value: 'Yongchuan' },
  { label: 'Yongfeng', value: 'Yongfeng' },
  { label: 'Yonggang-Ŭp', value: 'Yonggang-Ŭp' },
  { label: 'Yŏnggwang-Ŭp', value: 'Yŏnggwang-Ŭp' },
  { label: 'Yongin-Si', value: 'Yongin-Si' },
  { label: 'Yongning', value: 'Yongning' },
  { label: 'Yongsan-Dong', value: 'Yongsan-Dong' },
  { label: 'Yongsan-Gu', value: 'Yongsan-Gu' },
  { label: 'Yongzhou', value: 'Yongzhou' },
  { label: 'Yonibana', value: 'Yonibana' },
  { label: 'Yonkers', value: 'Yonkers' },
  { label: 'Yŏnmu', value: 'Yŏnmu' },
  { label: 'Yono', value: 'Yono' },
  { label: 'Yoogali', value: 'Yoogali' },
  { label: 'Yook', value: 'Yook' },
  { label: 'Yopal', value: 'Yopal' },
  { label: 'Yopi', value: 'Yopi' },
  { label: 'Yorba Linda', value: 'Yorba Linda' },
  { label: 'Yorii', value: 'Yorii' },
  { label: 'Yorito', value: 'Yorito' },
  { label: 'York', value: 'York' },
  { label: 'York', value: 'York' },
  { label: 'York', value: 'York' },
  { label: 'York', value: 'York' },
  { label: 'York', value: 'York' },
  { label: 'York', value: 'York' },
  { label: 'York', value: 'York' },
  { label: 'York', value: 'York' },
  { label: 'York Beach', value: 'York Beach' },
  { label: 'York Castle', value: 'York Castle' },
  { label: 'York County', value: 'York County' },
  { label: 'York County', value: 'York County' },
  { label: 'York County', value: 'York County' },
  { label: 'York County', value: 'York County' },
  { label: 'York County', value: 'York County' },
  { label: 'York Harbor', value: 'York Harbor' },
  { label: 'York Town', value: 'York Town' },
  { label: 'Yorke Hill', value: 'Yorke Hill' },
  { label: 'Yorke Peninsula', value: 'Yorke Peninsula' },
  { label: 'Yorketown', value: 'Yorketown' },
  { label: 'Yorkeys Knob', value: 'Yorkeys Knob' },
  { label: 'Yorklyn', value: 'Yorklyn' },
  { label: 'Yorkshire', value: 'Yorkshire' },
  { label: 'Yorkshire', value: 'Yorkshire' },
  { label: 'Yorkton', value: 'Yorkton' },
  { label: 'Yorktown', value: 'Yorktown' },
  { label: 'Yorktown', value: 'Yorktown' },
  { label: 'Yorktown', value: 'Yorktown' },
  { label: 'Yorktown Heights', value: 'Yorktown Heights' },
  { label: 'Yorkville', value: 'Yorkville' },
  { label: 'Yorkville', value: 'Yorkville' },
  { label: 'Yorkville', value: 'Yorkville' },
  { label: 'Yoro', value: 'Yoro' },
  { label: 'Yorosso', value: 'Yorosso' },
  { label: 'Yosemite Lakes', value: 'Yosemite Lakes' },
  { label: 'Yosemite Valley', value: 'Yosemite Valley' },
  { label: 'Yoshib', value: 'Yoshib' },
  { label: 'Yoshida-Kasugachō', value: 'Yoshida-Kasugachō' },
  { label: 'Yoshii', value: 'Yoshii' },
  { label: 'Yoshikawa', value: 'Yoshikawa' },
  { label: 'Yoshikawa Shi', value: 'Yoshikawa Shi' },
  { label: 'Yoshino-Chō', value: 'Yoshino-Chō' },
  { label: 'Yoshinogawa Shi', value: 'Yoshinogawa Shi' },
  { label: 'Yoshitomi', value: 'Yoshitomi' },
  { label: 'Yoshkar-Ola', value: 'Yoshkar-Ola' },
  { label: 'Yosphí', value: 'Yosphí' },
  { label: 'Yotholin', value: 'Yotholin' },
  { label: 'Yotoco', value: 'Yotoco' },
  { label: 'Yotsukaidō', value: 'Yotsukaidō' },
  { label: 'Yotsukaidō-Shi', value: 'Yotsukaidō-Shi' },
  { label: 'Youghal', value: 'Youghal' },
  { label: 'Youguzhuang', value: 'Youguzhuang' },
  { label: 'Youhao', value: 'Youhao' },
  { label: 'Youkaichi', value: 'Youkaichi' },
  { label: 'Youkounkoun', value: 'Youkounkoun' },
  { label: 'Youlgreave', value: 'Youlgreave' },
  { label: 'Young', value: 'Young' },
  { label: 'Young', value: 'Young' },
  { label: 'Young America (Historical)', value: 'Young America (Historical)' },
  { label: 'Young County', value: 'Young County' },
  { label: 'Young Harris', value: 'Young Harris' },
  { label: 'Youngstown', value: 'Youngstown' },
  { label: 'Youngstown', value: 'Youngstown' },
  { label: 'Youngstown', value: 'Youngstown' },
  { label: 'Youngsville', value: 'Youngsville' },
  { label: 'Youngsville', value: 'Youngsville' },
  { label: 'Youngsville', value: 'Youngsville' },
  { label: 'Youngtown', value: 'Youngtown' },
  { label: 'Youngtown', value: 'Youngtown' },
  { label: 'Youngwood', value: 'Youngwood' },
  { label: 'Yountville', value: 'Yountville' },
  { label: 'Youssoufia', value: 'Youssoufia' },
  { label: 'Youx', value: 'Youx' },
  { label: 'Youyupu', value: 'Youyupu' },
  { label: 'Yovon', value: 'Yovon' },
  { label: 'Yowie Bay', value: 'Yowie Bay' },
  { label: 'Yoxall', value: 'Yoxall' },
  { label: 'Ypacarai', value: 'Ypacarai' },
  { label: 'Ypäjä', value: 'Ypäjä' },
  { label: 'Ypenburg', value: 'Ypenburg' },
  { label: 'Yport', value: 'Yport' },
  { label: 'Ypsilanti', value: 'Ypsilanti' },
  { label: 'Ýpsonas', value: 'Ýpsonas' },
  { label: 'Yreka', value: 'Yreka' },
  { label: 'Yrghyz', value: 'Yrghyz' },
  { label: 'Ysper', value: 'Ysper' },
  { label: 'Ysselsteyn', value: 'Ysselsteyn' },
  { label: 'Yssingeaux', value: 'Yssingeaux' },
  { label: 'Ystad', value: 'Ystad' },
  { label: 'Ystalyfera', value: 'Ystalyfera' },
  { label: 'Ysyk-Ata', value: 'Ysyk-Ata' },
  { label: 'Ytrac', value: 'Ytrac' },
  { label: 'Ytre Arna', value: 'Ytre Arna' },
  { label: 'Ytrebygda', value: 'Ytrebygda' },
  { label: 'Ytyk-Kyuyël’', value: 'Ytyk-Kyuyël’' },
  { label: 'Yuanlin', value: 'Yuanlin' },
  { label: 'Yuanping', value: 'Yuanping' },
  { label: 'Yuba City', value: 'Yuba City' },
  { label: 'Yuba County', value: 'Yuba County' },
  { label: 'Yūbari', value: 'Yūbari' },
  { label: 'Yūbari-Shi', value: 'Yūbari-Shi' },
  { label: 'Yubileyny', value: 'Yubileyny' },
  { label: 'Yubileynyy', value: 'Yubileynyy' },
  { label: 'Yubilyeyny', value: 'Yubilyeyny' },
  { label: 'Yubo', value: 'Yubo' },
  { label: 'Yucaipa', value: 'Yucaipa' },
  { label: 'Yucca Valley', value: 'Yucca Valley' },
  { label: 'Yucheng', value: 'Yucheng' },
  { label: 'Yucheng', value: 'Yucheng' },
  { label: 'Yuci', value: 'Yuci' },
  { label: 'Yucuita', value: 'Yucuita' },
  { label: 'Yudomari', value: 'Yudomari' },
  { label: 'Yudong', value: 'Yudong' },
  { label: 'Yuelongzhuang', value: 'Yuelongzhuang' },
  { label: 'Yuen Long', value: 'Yuen Long' },
  { label: 'Yueyang', value: 'Yueyang' },
  { label: 'Yueyang Shi', value: 'Yueyang Shi' },
  { label: 'Yufu-Shi', value: 'Yufu-Shi' },
  { label: 'Yug', value: 'Yug' },
  { label: 'Yuganets', value: 'Yuganets' },
  { label: 'Yugawara', value: 'Yugawara' },
  { label: 'Yugo-Kamskiy', value: 'Yugo-Kamskiy' },
  { label: 'Yugorsk', value: 'Yugorsk' },
  { label: 'Yuguzhuang', value: 'Yuguzhuang' },
  { label: 'Yujing', value: 'Yujing' },
  { label: 'Yukamenskoye', value: 'Yukamenskoye' },
  { label: 'Yukhary-Dashkesan', value: 'Yukhary-Dashkesan' },
  { label: 'Yukhnov', value: 'Yukhnov' },
  { label: 'Yukhnovskiy Rayon', value: 'Yukhnovskiy Rayon' },
  { label: 'Yūki', value: 'Yūki' },
  { label: 'Yūki-Shi', value: 'Yūki-Shi' },
  { label: 'Yukon', value: 'Yukon' },
  { label: 'Yukon-Koyukuk Census Area', value: 'Yukon-Koyukuk Census Area' },
  { label: 'Yüksekova', value: 'Yüksekova' },
  { label: 'Yuktae-Dong', value: 'Yuktae-Dong' },
  { label: 'Yukuhashi', value: 'Yukuhashi' },
  { label: 'Yukuhashi Shi', value: 'Yukuhashi Shi' },
  { label: 'Yulara', value: 'Yulara' },
  { label: 'Yulee', value: 'Yulee' },
  { label: 'Yuli', value: 'Yuli' },
  { label: 'Yulin', value: 'Yulin' },
  { label: 'Yulinshi', value: 'Yulinshi' },
  { label: 'Yuma', value: 'Yuma' },
  { label: 'Yuma', value: 'Yuma' },
  { label: 'Yuma County', value: 'Yuma County' },
  { label: 'Yuma County', value: 'Yuma County' },
  { label: 'Yumaguzino', value: 'Yumaguzino' },
  { label: 'Yumani', value: 'Yumani' },
  { label: 'Yumbe', value: 'Yumbe' },
  { label: 'Yumbel', value: 'Yumbel' },
  { label: 'Yumbing', value: 'Yumbing' },
  { label: 'Yumbo', value: 'Yumbo' },
  { label: 'Yumurtalık', value: 'Yumurtalık' },
  { label: 'Yunak', value: 'Yunak' },
  { label: 'Yuncheng', value: 'Yuncheng' },
  { label: 'Yuncler', value: 'Yuncler' },
  { label: 'Yunclillos', value: 'Yunclillos' },
  { label: 'Yuncos', value: 'Yuncos' },
  { label: 'Yunes', value: 'Yunes' },
  { label: 'Yunesi', value: 'Yunesi' },
  { label: 'Yunfu', value: 'Yunfu' },
  { label: 'Yungaburra', value: 'Yungaburra' },
  { label: 'Yungay', value: 'Yungay' },
  { label: 'Yungay', value: 'Yungay' },
  { label: 'Yunguyo', value: 'Yunguyo' },
  { label: 'Yuni', value: 'Yuni' },
  { label: 'Yunji', value: 'Yunji' },
  { label: 'Yunlin', value: 'Yunlin' },
  { label: 'Yunmeng Chengguanzhen', value: 'Yunmeng Chengguanzhen' },
  { label: 'Yunquera', value: 'Yunquera' },
  { label: 'Yunquera De Henares', value: 'Yunquera De Henares' },
  { label: 'Yunta La', value: 'Yunta La' },
  { label: 'Yunusemre', value: 'Yunusemre' },
  { label: 'Yunyang', value: 'Yunyang' },
  { label: 'Yunyoo-Nasuan', value: 'Yunyoo-Nasuan' },
  { label: 'Yupiltepeque', value: 'Yupiltepeque' },
  { label: 'Yur’Yanskiy Rayon', value: 'Yur’Yanskiy Rayon' },
  { label: 'Yur’Yevets', value: 'Yur’Yevets' },
  { label: 'Yur’Yevetskiy Rayon', value: 'Yur’Yevetskiy Rayon' },
  { label: 'Yur’Yev-Pol’Skiy', value: 'Yur’Yev-Pol’Skiy' },
  { label: 'Yur’Yev-Pol’Skiy Rayon', value: 'Yur’Yev-Pol’Skiy Rayon' },
  { label: 'Yur”Yivs’Kyy Rayon', value: 'Yur”Yivs’Kyy Rayon' },
  { label: 'Yura', value: 'Yura' },
  { label: 'Yurécuaro', value: 'Yurécuaro' },
  { label: 'Yüreğir', value: 'Yüreğir' },
  { label: 'Yurga', value: 'Yurga' },
  { label: 'Yurgamysh', value: 'Yurgamysh' },
  { label: 'Yurginskiy Rayon', value: 'Yurginskiy Rayon' },
  { label: 'Yurginskoye', value: 'Yurginskoye' },
  { label: 'Yurihonjō', value: 'Yurihonjō' },
  { label: 'Yurihonjō-Shi', value: 'Yurihonjō-Shi' },
  { label: 'Yurimaguas', value: 'Yurimaguas' },
  { label: 'Yurino', value: 'Yurino' },
  { label: 'Yurinskiy Rayon', value: 'Yurinskiy Rayon' },
  { label: 'Yuriria', value: 'Yuriria' },
  { label: 'Yurkivka', value: 'Yurkivka' },
  { label: 'Yurla', value: 'Yurla' },
  { label: 'Yurovka', value: 'Yurovka' },
  { label: 'Yurty', value: 'Yurty' },
  { label: 'Yuryuzan’', value: 'Yuryuzan’' },
  { label: 'Yus’Vinskiy Rayon', value: 'Yus’Vinskiy Rayon' },
  { label: 'Yuscarán', value: 'Yuscarán' },
  { label: 'Yuseong-Gu', value: 'Yuseong-Gu' },
  { label: 'Yushala', value: 'Yushala' },
  { label: 'Yushan', value: 'Yushan' },
  { label: 'Yushan', value: 'Yushan' },
  { label: 'Yushkozero', value: 'Yushkozero' },
  { label: 'Yushu', value: 'Yushu' },
  { label: 'Yushu Zangzu Zizhizhou', value: 'Yushu Zangzu Zizhizhou' },
  { label: 'Yustis', value: 'Yustis' },
  { label: 'Yusufeli', value: 'Yusufeli' },
  { label: 'Yusufpur', value: 'Yusufpur' },
  { label: 'Yusuhara', value: 'Yusuhara' },
  { label: 'Yutan', value: 'Yutan' },
  { label: 'Yutan', value: 'Yutan' },
  { label: 'Yutanduchi De Guerrero', value: 'Yutanduchi De Guerrero' },
  { label: 'Yutazinskiy Rayon', value: 'Yutazinskiy Rayon' },
  { label: 'Yuto', value: 'Yuto' },
  { label: 'Yutsa', value: 'Yutsa' },
  { label: 'Yuty', value: 'Yuty' },
  { label: 'Yutz', value: 'Yutz' },
  { label: 'Yuxarı Aran', value: 'Yuxarı Aran' },
  { label: 'Yuxi', value: 'Yuxi' },
  { label: 'Yuxia', value: 'Yuxia' },
  { label: 'Yuyao', value: 'Yuyao' },
  { label: 'Yuza', value: 'Yuza' },
  { label: 'Yuzawa', value: 'Yuzawa' },
  { label: 'Yuzawa-Shi', value: 'Yuzawa-Shi' },
  { label: 'Yuzha', value: 'Yuzha' },
  { label: 'Yuzhne', value: 'Yuzhne' },
  { label: 'Yuzhno-Kurilsk', value: 'Yuzhno-Kurilsk' },
  { label: 'Yuzhno-Kurilsky District', value: 'Yuzhno-Kurilsky District' },
  { label: 'Yuzhno-Sakhalinsk', value: 'Yuzhno-Sakhalinsk' },
  { label: 'Yuzhno-Sukhokumsk', value: 'Yuzhno-Sukhokumsk' },
  { label: 'Yuzhnoukrainsk', value: 'Yuzhnoukrainsk' },
  { label: 'Yuzhnoural’Sk', value: 'Yuzhnoural’Sk' },
  { label: 'Yuzhnyy', value: 'Yuzhnyy' },
  { label: 'Yuzhnyy', value: 'Yuzhnyy' },
  { label: 'Yuzhnyy', value: 'Yuzhnyy' },
  { label: 'Yuzhnyy-Kospashskiy', value: 'Yuzhnyy-Kospashskiy' },
  { label: 'Yuzhong District', value: 'Yuzhong District' },
  { label: 'Yuzhskiy Rayon', value: 'Yuzhskiy Rayon' },
  { label: 'Yvelines', value: 'Yvelines' },
  { label: 'Yverdon-Les-Bains', value: 'Yverdon-Les-Bains' },
  { label: 'Yves', value: 'Yves' },
  { label: 'Yvetot', value: 'Yvetot' },
  { label: 'Yvignac-La-Tour', value: 'Yvignac-La-Tour' },
  { label: 'Yvoir', value: 'Yvoir' },
  { label: 'Yvonand', value: 'Yvonand' },
  { label: 'Yvrac', value: 'Yvrac' },
  { label: 'Yvré-Le-Pôlin', value: 'Yvré-Le-Pôlin' },
  { label: 'Yylanly', value: 'Yylanly' },
  { label: 'Yzernay', value: 'Yzernay' },
  { label: 'Yzeure', value: 'Yzeure' },
  { label: 'Yzeures-Sur-Creuse', value: 'Yzeures-Sur-Creuse' },
  { label: 'Z·Hurivs’Kyy Rayon', value: 'Z·Hurivs’Kyy Rayon' },
  { label: 'Zaamslag', value: 'Zaamslag' },
  { label: 'Zaandam', value: 'Zaandam' },
  { label: 'Zaandijk', value: 'Zaandijk' },
  { label: 'Zaanstad', value: 'Zaanstad' },
  { label: 'Zăbala', value: 'Zăbala' },
  { label: 'Žabalj', value: 'Žabalj' },
  { label: 'Zabalza/Zabaltza', value: 'Zabalza/Zabaltza' },
  { label: 'Zabaro-Davidivka', value: 'Zabaro-Davidivka' },
  { label: 'Zabaykal’Sk', value: 'Zabaykal’Sk' },
  { label: 'Żabbar', value: 'Żabbar' },
  { label: 'Žabčice', value: 'Žabčice' },
  { label: 'Zabelê', value: 'Zabelê' },
  { label: 'Zabeltitz', value: 'Zabeltitz' },
  { label: 'Zaberfeld', value: 'Zaberfeld' },
  { label: 'Żabia Wola', value: 'Żabia Wola' },
  { label: 'Zabīd', value: 'Zabīd' },
  { label: 'Żabieniec', value: 'Żabieniec' },
  { label: 'Zabierzów', value: 'Zabierzów' },
  { label: 'Zabierzów Bocheński', value: 'Zabierzów Bocheński' },
  { label: 'Ząbki', value: 'Ząbki' },
  { label: 'Ząbkowice Śląskie', value: 'Ząbkowice Śląskie' },
  { label: 'Žabljak', value: 'Žabljak' },
  { label: 'Zabłocie', value: 'Zabłocie' },
  { label: 'Zabłudów', value: 'Zabłudów' },
  { label: 'Żabnica', value: 'Żabnica' },
  { label: 'Żabno', value: 'Żabno' },
  { label: 'Zabok', value: 'Zabok' },
  { label: 'Zabol', value: 'Zabol' },
  { label: 'Zaboli', value: 'Zaboli' },
  { label: 'Zabolotiv', value: 'Zabolotiv' },
  { label: 'Zabolotovka', value: 'Zabolotovka' },
  { label: 'Zabór', value: 'Zabór' },
  { label: 'Zaborze', value: 'Zaborze' },
  { label: 'Zaborze', value: 'Zaborze' },
  { label: 'Zăbrani', value: 'Zăbrani' },
  { label: 'Zabrat', value: 'Zabrat' },
  { label: 'Zábřeh', value: 'Zábřeh' },
  { label: 'Zabrišće', value: 'Zabrišće' },
  { label: 'Zabrušany', value: 'Zabrušany' },
  { label: 'Zabrze', value: 'Zabrze' },
  { label: 'Zabrzeg', value: 'Zabrzeg' },
  { label: 'Zabukovica', value: 'Zabukovica' },
  { label: 'Zacacoyuca', value: 'Zacacoyuca' },
  { label: 'Zacacuautla', value: 'Zacacuautla' },
  { label: 'Zacalaca', value: 'Zacalaca' },
  { label: 'Zacamixtle', value: 'Zacamixtle' },
  { label: 'Zacamulpa', value: 'Zacamulpa' },
  { label: 'Zacamulpa', value: 'Zacamulpa' },
  { label: 'Zacamulpa Tlalmimilolpan', value: 'Zacamulpa Tlalmimilolpan' },
  { label: 'Zacango', value: 'Zacango' },
  { label: 'Zacaola', value: 'Zacaola' },
  { label: 'Zacapala', value: 'Zacapala' },
  { label: 'Zacapalco', value: 'Zacapalco' },
  { label: 'Zacapechpan', value: 'Zacapechpan' },
  { label: 'Zacapoaxtla', value: 'Zacapoaxtla' },
  { label: 'Zacapu', value: 'Zacapu' },
  { label: 'Zacapuato', value: 'Zacapuato' },
  { label: 'Zacarias', value: 'Zacarias' },
  { label: 'Zacatal', value: 'Zacatal' },
  { label: 'Zacate Colorado', value: 'Zacate Colorado' },
  { label: 'Zacate Colorado Segundo (Fraternidad)', value: 'Zacate Colorado Segundo (Fraternidad)' },
  { label: 'Zacatecas', value: 'Zacatecas' },
  { label: 'Zacatecoluca', value: 'Zacatecoluca' },
  { label: 'Zacatelco', value: 'Zacatelco' },
  { label: 'Zacatepec', value: 'Zacatepec' },
  { label: 'Zacatepec', value: 'Zacatepec' },
  { label: 'Zacatla', value: 'Zacatla' },
  { label: 'Zacatlán', value: 'Zacatlán' },
  { label: 'Zacatonal De Juárez', value: 'Zacatonal De Juárez' },
  { label: 'Zacatula', value: 'Zacatula' },
  { label: 'Zacazonapan', value: 'Zacazonapan' },
  { label: 'Zaccanopoli', value: 'Zaccanopoli' },
  { label: 'Zacháro', value: 'Zacháro' },
  { label: 'Zachary', value: 'Zachary' },
  { label: 'Zachenberg', value: 'Zachenberg' },
  { label: 'Zachepylivka', value: 'Zachepylivka' },
  { label: 'Zacisze', value: 'Zacisze' },
  { label: 'Žacléř', value: 'Žacléř' },
  { label: 'Zacoalco De Torres', value: 'Zacoalco De Torres' },
  { label: 'Zacoalpan', value: 'Zacoalpan' },
  { label: 'Zacualpa', value: 'Zacualpa' },
  { label: 'Zacualpa', value: 'Zacualpa' },
  { label: 'Zacualpan', value: 'Zacualpan' },
  { label: 'Zacualpan', value: 'Zacualpan' },
  { label: 'Zacualpan', value: 'Zacualpan' },
  { label: 'Zacualpan', value: 'Zacualpan' },
  { label: 'Zacualpan', value: 'Zacualpan' },
  { label: 'Zacualpan De Amilpas', value: 'Zacualpan De Amilpas' },
  { label: 'Zacualtipán', value: 'Zacualtipán' },
  { label: 'Zacualtipanito', value: 'Zacualtipanito' },
  { label: 'Zadar', value: 'Zadar' },
  { label: 'Zădăreni', value: 'Zădăreni' },
  { label: 'Zadawa', value: 'Zadawa' },
  { label: 'Zadie', value: 'Zadie' },
  { label: 'Zadní Mostek', value: 'Zadní Mostek' },
  { label: 'Zadonsk', value: 'Zadonsk' },
  { label: 'Zadonskiy Rayon', value: 'Zadonskiy Rayon' },
  { label: 'Zadovga', value: 'Zadovga' },
  { label: 'Zadvorsko', value: 'Zadvorsko' },
  { label: 'Zadzim', value: 'Zadzim' },
  { label: 'Zael', value: 'Zael' },
  { label: 'Zafar', value: 'Zafar' },
  { label: 'Zafarabad', value: 'Zafarabad' },
  { label: 'Zafarraya', value: 'Zafarraya' },
  { label: 'Zafarwal', value: 'Zafarwal' },
  { label: 'Zafferana Etnea', value: 'Zafferana Etnea' },
  { label: 'Zafra', value: 'Zafra' },
  { label: 'Zafra De Záncara', value: 'Zafra De Záncara' },
  { label: 'Zafrilla', value: 'Zafrilla' },
  { label: 'Żagań', value: 'Żagań' },
  { label: 'Zagăr', value: 'Zagăr' },
  { label: 'Žagarė', value: 'Žagarė' },
  { label: 'Zagarise', value: 'Zagarise' },
  { label: 'Zagarolo', value: 'Zagarolo' },
  { label: 'Zagavia', value: 'Zagavia' },
  { label: 'Zagazig', value: 'Zagazig' },
  { label: 'Zagersdorf', value: 'Zagersdorf' },
  { label: 'Zagheh', value: 'Zagheh' },
  { label: 'Zagheh-Ye Anuch', value: 'Zagheh-Ye Anuch' },
  { label: 'Zagheh-Ye Tasbandi', value: 'Zagheh-Ye Tasbandi' },
  { label: 'Zaghlijeh', value: 'Zaghlijeh' },
  { label: 'Zaghouan', value: 'Zaghouan' },
  { label: 'Zagnańsk', value: 'Zagnańsk' },
  { label: 'Zagnitkiv', value: 'Zagnitkiv' },
  { label: 'Zagon', value: 'Zagon' },
  { label: 'Zagor’Ye', value: 'Zagor’Ye' },
  { label: 'Zagora', value: 'Zagora' },
  { label: 'Zagorá', value: 'Zagorá' },
  { label: 'Zagorje Ob Savi', value: 'Zagorje Ob Savi' },
  { label: 'Zagórnik', value: 'Zagórnik' },
  { label: 'Zagórów', value: 'Zagórów' },
  { label: 'Zagoryanskiy', value: 'Zagoryanskiy' },
  { label: 'Zagórz', value: 'Zagórz' },
  { label: 'Zagórze', value: 'Zagórze' },
  { label: 'Zagórzyce', value: 'Zagórzyce' },
  { label: 'Zagra', value: 'Zagra' },
  { label: 'Zagra', value: 'Zagra' },
  { label: 'Zagreb', value: 'Zagreb' },
  { label: 'Zagrodno', value: 'Zagrodno' },
  { label: 'Zagvozd', value: 'Zagvozd' },
  { label: 'Zagyvarékas', value: 'Zagyvarékas' },
  { label: 'Zagyvaszántó', value: 'Zagyvaszántó' },
  { label: 'Zahak', value: 'Zahak' },
  { label: 'Zahānah', value: 'Zahānah' },
  { label: 'Zaḩar', value: 'Zaḩar' },
  { label: 'Zahara', value: 'Zahara' },
  { label: 'Zahedan', value: 'Zahedan' },
  { label: 'Zahedshahr', value: 'Zahedshahr' },
  { label: 'Zahinos', value: 'Zahinos' },
  { label: 'Zahir Pir', value: 'Zahir Pir' },
  { label: 'Zahirabad', value: 'Zahirabad' },
  { label: 'Zahlé', value: 'Zahlé' },
  { label: 'Zahna', value: 'Zahna' },
  { label: 'Záhony', value: 'Záhony' },
  { label: 'Záhonyi Járás', value: 'Záhonyi Járás' },
  { label: 'Záhorovice', value: 'Záhorovice' },
  { label: 'Zahreh', value: 'Zahreh' },
  { label: 'Zahuatlán De Morelos (San José)', value: 'Zahuatlán De Morelos (San José)' },
  { label: 'Zahvizdya', value: 'Zahvizdya' },
  { label: 'Zaida', value: 'Zaida' },
  { label: 'Zaida La', value: 'Zaida La' },
  { label: 'Zaidie Gardens', value: 'Zaidie Gardens' },
  { label: 'Zaidín', value: 'Zaidín' },
  { label: 'Zaidpur', value: 'Zaidpur' },
  { label: 'Zaigrayevo', value: 'Zaigrayevo' },
  { label: 'Zainsk', value: 'Zainsk' },
  { label: 'Zainskiy Rayon', value: 'Zainskiy Rayon' },
  { label: 'Zaïo', value: 'Zaïo' },
  { label: 'Zaisenhausen', value: 'Zaisenhausen' },
  { label: 'Zajas', value: 'Zajas' },
  { label: 'Zaječar', value: 'Zaječar' },
  { label: 'Zaječí', value: 'Zaječí' },
  { label: 'Zaječov', value: 'Zaječov' },
  { label: 'Zakamensk', value: 'Zakamensk' },
  { label: 'Zákányszék', value: 'Zákányszék' },
  { label: 'Zakan-Yurt', value: 'Zakan-Yurt' },
  { label: 'Zakharivka', value: 'Zakharivka' },
  { label: 'Zakharovo', value: 'Zakharovo' },
  { label: 'Zakharovskiy Rayon', value: 'Zakharovskiy Rayon' },
  { label: 'Zaki Biam', value: 'Zaki Biam' },
  { label: 'Zakliczyn', value: 'Zakliczyn' },
  { label: 'Zaklików', value: 'Zaklików' },
  { label: 'Zakopane', value: 'Zakopane' },
  { label: 'Zakrinichchia', value: 'Zakrinichchia' },
  { label: 'Zakroczym', value: 'Zakroczym' },
  { label: 'Zakrzew', value: 'Zakrzew' },
  { label: 'Zakrzew', value: 'Zakrzew' },
  { label: 'Zakrzewo', value: 'Zakrzewo' },
  { label: 'Zakrzów', value: 'Zakrzów' },
  { label: 'Zakrzów', value: 'Zakrzów' },
  { label: 'Zakrzówek', value: 'Zakrzówek' },
  { label: 'Zakupne', value: 'Zakupne' },
  { label: 'Zákupy', value: 'Zákupy' },
  { label: 'Zakynthos', value: 'Zakynthos' },
  { label: 'Zalaegerszeg', value: 'Zalaegerszeg' },
  { label: 'Zalaegerszegi Járás', value: 'Zalaegerszegi Járás' },
  { label: 'Zalakomár', value: 'Zalakomár' },
  { label: 'Zalalövő', value: 'Zalalövő' },
  { label: 'Zalamea', value: 'Zalamea' },
  { label: 'Zalamea De La Serena', value: 'Zalamea De La Serena' },
  { label: 'Zalamea La Real', value: 'Zalamea La Real' },
  { label: 'Zalanga', value: 'Zalanga' },
  { label: 'Zalari', value: 'Zalari' },
  { label: 'Zalarinskiy Rayon', value: 'Zalarinskiy Rayon' },
  { label: 'Zalas', value: 'Zalas' },
  { label: 'Zalaszentgrót', value: 'Zalaszentgrót' },
  { label: 'Zalaszentgróti Járás', value: 'Zalaszentgróti Járás' },
  { label: 'Zalău', value: 'Zalău' },
  { label: 'Zaldibar', value: 'Zaldibar' },
  { label: 'Zaldibia', value: 'Zaldibia' },
  { label: 'Zalduondo', value: 'Zalduondo' },
  { label: 'Žalec', value: 'Žalec' },
  { label: 'Zalegoshch’', value: 'Zalegoshch’' },
  { label: 'Zalesie', value: 'Zalesie' },
  { label: 'Zalesie Górne', value: 'Zalesie Górne' },
  { label: 'Zalesie I Stadion', value: 'Zalesie I Stadion' },
  { label: 'Zalesnoye', value: 'Zalesnoye' },
  { label: 'Zalesovo', value: 'Zalesovo' },
  { label: 'Zalesye', value: 'Zalesye' },
  { label: 'Zaleszany', value: 'Zaleszany' },
  { label: 'Zalewo', value: 'Zalewo' },
  { label: 'Zalha', value: 'Zalha' },
  { label: 'Zalingei', value: 'Zalingei' },
  { label: 'Zalishchyky', value: 'Zalishchyky' },
  { label: 'Zalissia', value: 'Zalissia' },
  { label: 'Zaliznia', value: 'Zaliznia' },
  { label: 'Zaliznychne', value: 'Zaliznychne' },
  { label: 'Zaliznychne', value: 'Zaliznychne' },
  { label: 'Zaliznyy Port', value: 'Zaliznyy Port' },
  { label: 'Zaliztsi', value: 'Zaliztsi' },
  { label: 'Zalla', value: 'Zalla' },
  { label: 'Zalţan', value: 'Zalţan' },
  { label: 'Zaltbommel', value: 'Zaltbommel' },
  { label: 'Zalukokoazhe', value: 'Zalukokoazhe' },
  { label: 'Załuski', value: 'Załuski' },
  { label: 'Zaluzhne', value: 'Zaluzhne' },
  { label: 'Zam', value: 'Zam' },
  { label: 'Zama', value: 'Zama' },
  { label: 'Zama Shi', value: 'Zama Shi' },
  { label: 'Zamachihue', value: 'Zamachihue' },
  { label: 'Zamakh Wa Manwakh', value: 'Zamakh Wa Manwakh' },
  { label: 'Zamanabad-E Mohammadabad', value: 'Zamanabad-E Mohammadabad' },
  { label: 'Zamania', value: 'Zamania' },
  { label: 'Zamárdi', value: 'Zamárdi' },
  { label: 'Zamarra', value: 'Zamarra' },
  { label: 'Zamarski', value: 'Zamarski' },
  { label: 'Zamayón', value: 'Zamayón' },
  { label: 'Zambana', value: 'Zambana' },
  { label: 'Žamberk', value: 'Žamberk' },
  { label: 'Zambezi', value: 'Zambezi' },
  { label: 'Zamboanga', value: 'Zamboanga' },
  { label: 'Zamboanguita', value: 'Zamboanguita' },
  { label: 'Zambrana', value: 'Zambrana' },
  { label: 'Zambrano', value: 'Zambrano' },
  { label: 'Zambrano', value: 'Zambrano' },
  { label: 'Zâmbreasca', value: 'Zâmbreasca' },
  { label: 'Zambrone', value: 'Zambrone' },
  { label: 'Zambrów', value: 'Zambrów' },
  { label: 'Zambujeira Do Mar', value: 'Zambujeira Do Mar' },
  { label: 'Zamch', value: 'Zamch' },
  { label: 'Zamishevo', value: 'Zamishevo' },
  { label: 'Zámoly', value: 'Zámoly' },
  { label: 'Zamora', value: 'Zamora' },
  { label: 'Zamora', value: 'Zamora' },
  { label: 'Zamora', value: 'Zamora' },
  { label: 'Zamora', value: 'Zamora' },
  { label: 'Zamora', value: 'Zamora' },
  { label: 'Zamora Pico De Oro', value: 'Zamora Pico De Oro' },
  { label: 'Zamorano', value: 'Zamorano' },
  { label: 'Zamość', value: 'Zamość' },
  { label: 'Zamoskvorech’Ye', value: 'Zamoskvorech’Ye' },
  { label: 'Zamostea', value: 'Zamostea' },
  { label: 'Zamostochye', value: 'Zamostochye' },
  { label: 'Zams', value: 'Zams' },
  { label: 'Zamudio', value: 'Zamudio' },
  { label: 'Zandak', value: 'Zandak' },
  { label: 'Zandberg', value: 'Zandberg' },
  { label: 'Zandhoven', value: 'Zandhoven' },
  { label: 'Zandobbio', value: 'Zandobbio' },
  { label: 'Žandov', value: 'Žandov' },
  { label: 'Zandt', value: 'Zandt' },
  { label: 'Zandvoort', value: 'Zandvoort' },
  { label: 'Zanè', value: 'Zanè' },
  { label: 'Zăneşti', value: 'Zăneşti' },
  { label: 'Zanesville', value: 'Zanesville' },
  { label: 'Zangakatun', value: 'Zangakatun' },
  { label: 'Zangarro (Zangarro Nuevo)', value: 'Zangarro (Zangarro Nuevo)' },
  { label: 'Zangberg', value: 'Zangberg' },
  { label: 'Zangeneh', value: 'Zangeneh' },
  { label: 'Zangeneh-Ye Sofla', value: 'Zangeneh-Ye Sofla' },
  { label: 'Zangguy', value: 'Zangguy' },
  { label: 'Zangiabad', value: 'Zangiabad' },
  { label: 'Zangilan', value: 'Zangilan' },
  { label: 'Zanica', value: 'Zanica' },
  { label: 'Zaniemyśl', value: 'Zaniemyśl' },
  { label: 'Zanjan', value: 'Zanjan' },
  { label: 'Zánka', value: 'Zánka' },
  { label: 'Zănoaga', value: 'Zănoaga' },
  { label: 'Zanzibar', value: 'Zanzibar' },
  { label: 'Zaojiacheng', value: 'Zaojiacheng' },
  { label: 'Zaokskiy', value: 'Zaokskiy' },
  { label: 'Zaokskiy Rayon', value: 'Zaokskiy Rayon' },
  { label: 'Zaorejas', value: 'Zaorejas' },
  { label: 'Zaouiet Djedidi', value: 'Zaouiet Djedidi' },
  { label: 'Zaoyang', value: 'Zaoyang' },
  { label: 'Zaozërsk', value: 'Zaozërsk' },
  { label: 'Zaozhuang', value: 'Zaozhuang' },
  { label: 'Zaozyornoye', value: 'Zaozyornoye' },
  { label: 'Zapadnaya Dvina', value: 'Zapadnaya Dvina' },
  { label: 'Zapadnia', value: 'Zapadnia' },
  { label: 'Zapala', value: 'Zapala' },
  { label: 'Zapallar', value: 'Zapallar' },
  { label: 'Zapardiel De La Cañada', value: 'Zapardiel De La Cañada' },
  { label: 'Zapardiel De La Ribera', value: 'Zapardiel De La Ribera' },
  { label: 'Zapata', value: 'Zapata' },
  { label: 'Zapata County', value: 'Zapata County' },
  { label: 'Zapatoca', value: 'Zapatoca' },
  { label: 'Zapayán', value: 'Zapayán' },
  { label: 'Zapfendorf', value: 'Zapfendorf' },
  { label: 'Zaplavnoye', value: 'Zaplavnoye' },
  { label: 'Zapoapan', value: 'Zapoapan' },
  { label: 'Zapoapan De Amapan', value: 'Zapoapan De Amapan' },
  { label: 'Zapoapan De Cabañas', value: 'Zapoapan De Cabañas' },
  { label: 'Zăpodea', value: 'Zăpodea' },
  { label: 'Zăpodeni', value: 'Zăpodeni' },
  { label: 'Zapolice', value: 'Zapolice' },
  { label: 'Zapolyarnyy', value: 'Zapolyarnyy' },
  { label: 'Zapolyarnyy', value: 'Zapolyarnyy' },
  { label: 'Zapon', value: 'Zapon' },
  { label: 'Zapopan', value: 'Zapopan' },
  { label: 'Zaporiz’Ka Mis’Krada', value: 'Zaporiz’Ka Mis’Krada' },
  { label: 'Zaporizhia', value: 'Zaporizhia' },
  { label: 'Zapotán', value: 'Zapotán' },
  { label: 'Zapotanito', value: 'Zapotanito' },
  { label: 'Zapote De Barajas', value: 'Zapote De Barajas' },
  { label: 'Zapote De Cestao', value: 'Zapote De Cestao' },
  { label: 'Zapote De Palomas', value: 'Zapote De Palomas' },
  { label: 'Zapote De Peralta', value: 'Zapote De Peralta' },
  { label: 'Zapotillo', value: 'Zapotillo' },
  { label: 'Zapotillo De Mogotes', value: 'Zapotillo De Mogotes' },
  { label: 'Zapotiltic', value: 'Zapotiltic' },
  { label: 'Zapotitán De Hidalgo', value: 'Zapotitán De Hidalgo' },
  { label: 'Zapotitlán', value: 'Zapotitlán' },
  { label: 'Zapotitlán', value: 'Zapotitlán' },
  { label: 'Zapotitlán De Vadillo', value: 'Zapotitlán De Vadillo' },
  { label: 'Zapotitlán Lagunas', value: 'Zapotitlán Lagunas' },
  { label: 'Zapotitlán Palmas', value: 'Zapotitlán Palmas' },
  { label: 'Zapotitlán Tablas', value: 'Zapotitlán Tablas' },
  { label: 'Zapotlán', value: 'Zapotlán' },
  { label: 'Zapotlán De Juárez', value: 'Zapotlán De Juárez' },
  { label: 'Zapotlán Del Rey', value: 'Zapotlán Del Rey' },
  { label: 'Zapotlán El Grande Municipality', value: 'Zapotlán El Grande Municipality' },
  { label: 'Zapotlanejo', value: 'Zapotlanejo' },
  { label: 'Zappendorf', value: 'Zappendorf' },
  { label: 'Zapponeta', value: 'Zapponeta' },
  { label: 'Zaprešić', value: 'Zaprešić' },
  { label: 'Zaprudnya', value: 'Zaprudnya' },
  { label: 'Zapuyo', value: 'Zapuyo' },
  { label: 'Zaqatala', value: 'Zaqatala' },
  { label: 'Zara', value: 'Zara' },
  { label: 'Zarābād', value: 'Zarābād' },
  { label: 'Zarach', value: 'Zarach' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza', value: 'Zaragoza' },
  { label: 'Zaragoza (Puerto De Medina)', value: 'Zaragoza (Puerto De Medina)' },
  { label: 'Zaragoza De Guadalupe', value: 'Zaragoza De Guadalupe' },
  { label: 'Zaragoza La Montaña', value: 'Zaragoza La Montaña' },
  { label: 'Zaṟah Sharan', value: 'Zaṟah Sharan' },
  { label: 'Zaramin-E Sofla', value: 'Zaramin-E Sofla' },
  { label: 'Zarand', value: 'Zarand' },
  { label: 'Zărand', value: 'Zărand' },
  { label: 'Zarandiyeh', value: 'Zarandiyeh' },
  { label: 'Zaranj', value: 'Zaranj' },
  { label: 'Zarapicos', value: 'Zarapicos' },
  { label: 'Zarasai', value: 'Zarasai' },
  { label: 'Zaratamo', value: 'Zaratamo' },
  { label: 'Zaratán', value: 'Zaratán' },
  { label: 'Zarautz', value: 'Zarautz' },
  { label: 'Zaraysk', value: 'Zaraysk' },
  { label: 'Zaraza', value: 'Zaraza' },
  { label: 'Zarcero', value: 'Zarcero' },
  { label: 'Zardob', value: 'Zardob' },
  { label: 'Zarech’Ye', value: 'Zarech’Ye' },
  { label: 'Zarech’Ye', value: 'Zarech’Ye' },
  { label: 'Zarechnoye', value: 'Zarechnoye' },
  { label: 'Zarechnyy', value: 'Zarechnyy' },
  { label: 'Zarechnyy', value: 'Zarechnyy' },
  { label: 'Zarechnyy', value: 'Zarechnyy' },
  { label: 'Zarechnyy', value: 'Zarechnyy' },
  { label: 'Zarechnyy', value: 'Zarechnyy' },
  { label: 'Zargarmahalleh', value: 'Zargarmahalleh' },
  { label: 'Zarghan', value: 'Zarghan' },
  { label: 'Zarghūn Shahr', value: 'Zarghūn Shahr' },
  { label: 'Zaria', value: 'Zaria' },
  { label: 'Zarichchia', value: 'Zarichchia' },
  { label: 'Zarichchya', value: 'Zarichchya' },
  { label: 'Zarichne', value: 'Zarichne' },
  { label: 'Zarinshahr', value: 'Zarinshahr' },
  { label: 'Zarinsk', value: 'Zarinsk' },
  { label: 'Zarinskiy Rayon', value: 'Zarinskiy Rayon' },
  { label: 'Żarki', value: 'Żarki' },
  { label: 'Żarki', value: 'Żarki' },
  { label: 'Żarki-Letnisko', value: 'Żarki-Letnisko' },
  { label: 'Zárkos', value: 'Zárkos' },
  { label: 'Zarnaq', value: 'Zarnaq' },
  { label: 'Zarneh', value: 'Zarneh' },
  { label: 'Zărneşti', value: 'Zărneşti' },
  { label: 'Zărnești', value: 'Zărnești' },
  { label: 'Zărneștii De Slănic', value: 'Zărneștii De Slănic' },
  { label: 'Žarnovica', value: 'Žarnovica' },
  { label: 'Żarnów', value: 'Żarnów' },
  { label: 'Zarós', value: 'Zarós' },
  { label: 'Żarów', value: 'Żarów' },
  { label: 'Zarpen', value: 'Zarpen' },
  { label: 'Zarqa', value: 'Zarqa' },
  { label: 'Zarr', value: 'Zarr' },
  { label: 'Zarra', value: 'Zarra' },
  { label: 'Zarraga', value: 'Zarraga' },
  { label: 'Zarratón', value: 'Zarratón' },
  { label: 'Zarrendorf', value: 'Zarrendorf' },
  { label: 'Zarrentin', value: 'Zarrentin' },
  { label: 'Zarrin Abad', value: 'Zarrin Abad' },
  { label: 'Zarrin Bagh', value: 'Zarrin Bagh' },
  { label: 'Zarrin Dasht', value: 'Zarrin Dasht' },
  { label: 'Zarrin Rood', value: 'Zarrin Rood' },
  { label: 'Zarrin Shahr', value: 'Zarrin Shahr' },
  { label: 'Zarrineh ', value: 'Zarrineh ' },
  { label: 'Zarrit’Ap’', value: 'Zarrit’Ap’' },
  { label: 'Zarszyn', value: 'Zarszyn' },
  { label: 'Zarubino', value: 'Zarubino' },
  { label: 'Zarubino', value: 'Zarubino' },
  { label: 'Zarudintsi', value: 'Zarudintsi' },
  { label: 'Zaruma', value: 'Zaruma' },
  { label: 'Zarumilla', value: 'Zarumilla' },
  { label: 'Żary', value: 'Żary' },
  { label: 'Zarya', value: 'Zarya' },
  { label: 'Zarya', value: 'Zarya' },
  { label: 'Zarza De Granadilla', value: 'Zarza De Granadilla' },
  { label: 'Zarza De Montánchez', value: 'Zarza De Montánchez' },
  { label: 'Zarza De Pumareda La', value: 'Zarza De Pumareda La' },
  { label: 'Zarza De Tajo', value: 'Zarza De Tajo' },
  { label: 'Zarza La', value: 'Zarza La' },
  { label: 'Zarza La', value: 'Zarza La' },
  { label: 'Zarza La Mayor', value: 'Zarza La Mayor' },
  { label: 'Zarza-Capilla', value: 'Zarza-Capilla' },
  { label: 'Zarzal', value: 'Zarzal' },
  { label: 'Zarzal', value: 'Zarzal' },
  { label: 'Zarzalejo', value: 'Zarzalejo' },
  { label: 'Zarzecze', value: 'Zarzecze' },
  { label: 'Zarzecze', value: 'Zarzecze' },
  { label: 'Zarzecze', value: 'Zarzecze' },
  { label: 'Zarzis', value: 'Zarzis' },
  { label: 'Zarzosa', value: 'Zarzosa' },
  { label: 'Zarzosa De Río Pisuerga', value: 'Zarzosa De Río Pisuerga' },
  { label: 'Zarzuela', value: 'Zarzuela' },
  { label: 'Zarzuela De Jadraque', value: 'Zarzuela De Jadraque' },
  { label: 'Zarzuela Del Monte', value: 'Zarzuela Del Monte' },
  { label: 'Zarzuela Del Pinar', value: 'Zarzuela Del Pinar' },
  { label: 'Zas', value: 'Zas' },
  { label: 'Zasechnoye', value: 'Zasechnoye' },
  { label: 'Zasip', value: 'Zasip' },
  { label: 'Zaslawye', value: 'Zaslawye' },
  { label: 'Zásmuky', value: 'Zásmuky' },
  { label: 'Zasosna', value: 'Zasosna' },
  { label: 'Zašová', value: 'Zašová' },
  { label: 'Zastávka', value: 'Zastávka' },
  { label: 'Zastavna', value: 'Zastavna' },
  { label: 'Zastron', value: 'Zastron' },
  { label: 'Žatec', value: 'Žatec' },
  { label: 'Zaterechnyy', value: 'Zaterechnyy' },
  { label: 'Zatoka', value: 'Zatoka' },
  { label: 'Zaton', value: 'Zaton' },
  { label: 'Zator', value: 'Zator' },
  { label: 'Zátor', value: 'Zátor' },
  { label: 'Zatory', value: 'Zatory' },
  { label: 'Zătreni', value: 'Zătreni' },
  { label: 'Zatyshshya', value: 'Zatyshshya' },
  { label: 'Zau De Câmpie', value: 'Zau De Câmpie' },
  { label: 'Zăuan', value: 'Zăuan' },
  { label: 'Zautla', value: 'Zautla' },
  { label: 'Zav’Yalovo', value: 'Zav’Yalovo' },
  { label: 'Zav’Yalovskiy Rayon', value: 'Zav’Yalovskiy Rayon' },
  { label: 'Zav’Yalovskiy Rayon', value: 'Zav’Yalovskiy Rayon' },
  { label: 'Zăval', value: 'Zăval' },
  { label: 'Zavala County', value: 'Zavala County' },
  { label: 'Zavallya', value: 'Zavallya' },
  { label: 'Zavareh', value: 'Zavareh' },
  { label: 'Zavattarello', value: 'Zavattarello' },
  { label: 'Zaveh', value: 'Zaveh' },
  { label: 'Zaventem', value: 'Zaventem' },
  { label: 'Zavet', value: 'Zavet' },
  { label: 'Zavet-Leninskiy', value: 'Zavet-Leninskiy' },
  { label: 'Zavetnoye', value: 'Zavetnoye' },
  { label: 'Zavetnoye', value: 'Zavetnoye' },
  { label: 'Zavetnoye', value: 'Zavetnoye' },
  { label: 'Zavetnyy', value: 'Zavetnyy' },
  { label: 'Zavety Il’Icha', value: 'Zavety Il’Icha' },
  { label: 'Zavidovići', value: 'Zavidovići' },
  { label: 'Zavidovo', value: 'Zavidovo' },
  { label: 'Zavieh', value: 'Zavieh' },
  { label: 'Zavitinsk', value: 'Zavitinsk' },
  { label: 'Zavitinskiy Rayon', value: 'Zavitinskiy Rayon' },
  { label: 'Zăvoaia', value: 'Zăvoaia' },
  { label: 'Zavodoukovsk', value: 'Zavodoukovsk' },
  { label: 'Zavodouspenskoye', value: 'Zavodouspenskoye' },
  { label: 'Zavodske', value: 'Zavodske' },
  { label: 'Zavodski Rayon', value: 'Zavodski Rayon' },
  { label: 'Zavodskoy', value: 'Zavodskoy' },
  { label: 'Zavodskoy', value: 'Zavodskoy' },
  { label: 'Zăvoi', value: 'Zăvoi' },
  { label: 'Zăvoiu', value: 'Zăvoiu' },
  { label: 'Zavolzh’Ye', value: 'Zavolzh’Ye' },
  { label: 'Zavolzhsk', value: 'Zavolzhsk' },
  { label: 'Zavolzhskiy Rayon', value: 'Zavolzhskiy Rayon' },
  { label: 'Zavoronezhskoye', value: 'Zavoronezhskoye' },
  { label: 'Zavrč', value: 'Zavrč' },
  { label: 'Zawadzkie', value: 'Zawadzkie' },
  { label: 'Zawichost', value: 'Zawichost' },
  { label: 'Zawidów', value: 'Zawidów' },
  { label: 'Zawidz', value: 'Zawidz' },
  { label: 'Zawiercie', value: 'Zawiercie' },
  { label: 'Zawiya', value: 'Zawiya' },
  { label: 'Zawoja', value: 'Zawoja' },
  { label: 'Zawyat An Nwaçer', value: 'Zawyat An Nwaçer' },
  { label: 'Zawyat Ech Cheïkh', value: 'Zawyat Ech Cheïkh' },
  { label: 'Zaxo', value: 'Zaxo' },
  { label: 'Zayandehrod', value: 'Zayandehrod' },
  { label: 'Zayed City', value: 'Zayed City' },
  { label: 'Zaykovo', value: 'Zaykovo' },
  { label: 'Zaymishche', value: 'Zaymishche' },
  { label: 'Zaysan', value: 'Zaysan' },
  { label: 'Zayukovo', value: 'Zayukovo' },
  { label: 'Zaza Del Medio', value: 'Zaza Del Medio' },
  { label: 'Zažablje', value: 'Zažablje' },
  { label: 'Zazeran', value: 'Zazeran' },
  { label: 'Zäziwil', value: 'Zäziwil' },
  { label: 'Zazuar', value: 'Zazuar' },
  { label: 'Zbarazh', value: 'Zbarazh' },
  { label: 'Zbąszyń', value: 'Zbąszyń' },
  { label: 'Zbąszynek', value: 'Zbąszynek' },
  { label: 'Zbiroh', value: 'Zbiroh' },
  { label: 'Zblewo', value: 'Zblewo' },
  { label: 'Zbójna', value: 'Zbójna' },
  { label: 'Zbójno', value: 'Zbójno' },
  { label: 'Zborište', value: 'Zborište' },
  { label: 'Zboriv', value: 'Zboriv' },
  { label: 'Zborovice', value: 'Zborovice' },
  { label: 'Zbranki', value: 'Zbranki' },
  { label: 'Zbraslav', value: 'Zbraslav' },
  { label: 'Zbraslavice', value: 'Zbraslavice' },
  { label: 'Zbrosławice', value: 'Zbrosławice' },
  { label: 'Zbůch', value: 'Zbůch' },
  { label: 'Zbuczyn', value: 'Zbuczyn' },
  { label: 'Zbýšov', value: 'Zbýšov' },
  { label: 'Zbytków', value: 'Zbytków' },
  { label: 'Ždánice', value: 'Ždánice' },
  { label: 'Žďár', value: 'Žďár' },
  { label: 'Žďár Nad Sázavou', value: 'Žďár Nad Sázavou' },
  { label: 'Žďár Nad Sázavou Druhy', value: 'Žďár Nad Sázavou Druhy' },
  { label: 'Zdenci', value: 'Zdenci' },
  { label: 'Zdenci Brdovečki', value: 'Zdenci Brdovečki' },
  { label: 'Ždiar', value: 'Ždiar' },
  { label: 'Zdiby', value: 'Zdiby' },
  { label: 'Zdice', value: 'Zdice' },
  { label: 'Zdíkov', value: 'Zdíkov' },
  { label: 'Zdolbuniv', value: 'Zdolbuniv' },
  { label: 'Zdorovets', value: 'Zdorovets' },
  { label: 'Zdounky', value: 'Zdounky' },
  { label: 'Zdovbytsya', value: 'Zdovbytsya' },
  { label: 'Ždralovi', value: 'Ždralovi' },
  { label: 'Zdrapți', value: 'Zdrapți' },
  { label: 'Zduńska Wola', value: 'Zduńska Wola' },
  { label: 'Zduny', value: 'Zduny' },
  { label: 'Zdvinsk', value: 'Zdvinsk' },
  { label: 'Zdziechowice Drugie', value: 'Zdziechowice Drugie' },
  { label: 'Zdzieszowice', value: 'Zdzieszowice' },
  { label: 'Zé Doca', value: 'Zé Doca' },
  { label: 'Zeanuri', value: 'Zeanuri' },
  { label: 'Żebbuġ', value: 'Żebbuġ' },
  { label: 'Zeberio', value: 'Zeberio' },
  { label: 'Zebil', value: 'Zebil' },
  { label: 'Zębowice', value: 'Zębowice' },
  { label: 'Žebrák', value: 'Žebrák' },
  { label: 'Zebrzydowice', value: 'Zebrzydowice' },
  { label: 'Zebrzydowice', value: 'Zebrzydowice' },
  { label: 'Zebulon', value: 'Zebulon' },
  { label: 'Zebulon', value: 'Zebulon' },
  { label: 'Zeccone', value: 'Zeccone' },
  { label: 'Zeddam', value: 'Zeddam' },
  { label: 'Zeddiani', value: 'Zeddiani' },
  { label: 'Zedelgem', value: 'Zedelgem' },
  { label: 'Zederhaus', value: 'Zederhaus' },
  { label: 'Zeebrugge', value: 'Zeebrugge' },
  { label: 'Zeeland', value: 'Zeeland' },
  { label: 'Zeeland', value: 'Zeeland' },
  { label: 'Zeelst', value: 'Zeelst' },
  { label: 'Zeerust', value: 'Zeerust' },
  { label: 'Zeewolde', value: 'Zeewolde' },
  { label: 'Zefta', value: 'Zefta' },
  { label: 'Zefyri', value: 'Zefyri' },
  { label: 'Zegama', value: 'Zegama' },
  { label: 'Zegerscappel', value: 'Zegerscappel' },
  { label: 'Zegge', value: 'Zegge' },
  { label: 'Żegocina', value: 'Żegocina' },
  { label: 'Zehak', value: 'Zehak' },
  { label: 'Zehdenick', value: 'Zehdenick' },
  { label: 'Zeh-E Kalut', value: 'Zeh-E Kalut' },
  { label: 'Zehlendorf', value: 'Zehlendorf' },
  { label: 'Žehra', value: 'Žehra' },
  { label: 'Zeigler', value: 'Zeigler' },
  { label: 'Zeil', value: 'Zeil' },
  { label: 'Zeilarn', value: 'Zeilarn' },
  { label: 'Zeilberg', value: 'Zeilberg' },
  { label: 'Zeillern', value: 'Zeillern' },
  { label: 'Zeiselmauer', value: 'Zeiselmauer' },
  { label: 'Zeiskam', value: 'Zeiskam' },
  { label: 'Zeist', value: 'Zeist' },
  { label: 'Zeithain', value: 'Zeithain' },
  { label: 'Zeitlarn', value: 'Zeitlarn' },
  { label: 'Zeitlofs', value: 'Zeitlofs' },
  { label: 'Zeitz', value: 'Zeitz' },
  { label: 'Żejtun', value: 'Żejtun' },
  { label: 'Zel’Va', value: 'Zel’Va' },
  { label: 'Zelarino', value: 'Zelarino' },
  { label: 'Żelazków', value: 'Żelazków' },
  { label: 'Zelbio', value: 'Zelbio' },
  { label: 'Zele', value: 'Zele' },
  { label: 'Żelechlinek', value: 'Żelechlinek' },
  { label: 'Żelechów', value: 'Żelechów' },
  { label: 'Zelenchukskaya', value: 'Zelenchukskaya' },
  { label: 'Zeleneč', value: 'Zeleneč' },
  { label: 'Zelenets', value: 'Zelenets' },
  { label: 'Zelenikovo', value: 'Zelenikovo' },
  { label: 'Zelenoborsk', value: 'Zelenoborsk' },
  { label: 'Zelenoborskiy', value: 'Zelenoborskiy' },
  { label: 'Zelenodol’Skiy Rayon', value: 'Zelenodol’Skiy Rayon' },
  { label: 'Zelenodolsk', value: 'Zelenodolsk' },
  { label: 'Zelenogorsk', value: 'Zelenogorsk' },
  { label: 'Zelenogorsk', value: 'Zelenogorsk' },
  { label: 'Zelenogorskiy', value: 'Zelenogorskiy' },
  { label: 'Zelenogorskoye', value: 'Zelenogorskoye' },
  { label: 'Zelenograd', value: 'Zelenograd' },
  { label: 'Zelenogradsk', value: 'Zelenogradsk' },
  { label: 'Zelenogradskiy', value: 'Zelenogradskiy' },
  { label: 'Zelenokumsk', value: 'Zelenokumsk' },
  { label: 'Zelënyy Bor', value: 'Zelënyy Bor' },
  { label: 'Želešice', value: 'Želešice' },
  { label: 'Želetava', value: 'Želetava' },
  { label: 'Železná Ruda', value: 'Železná Ruda' },
  { label: 'Železnice', value: 'Železnice' },
  { label: 'Železniki', value: 'Železniki' },
  { label: 'Železný Brod', value: 'Železný Brod' },
  { label: 'Zelhem', value: 'Zelhem' },
  { label: 'Zelienople', value: 'Zelienople' },
  { label: 'Želiezovce', value: 'Želiezovce' },
  { label: 'Zelino', value: 'Zelino' },
  { label: 'Żelistrzewo', value: 'Żelistrzewo' },
  { label: 'Želiv', value: 'Želiv' },
  { label: 'Željezno Polje', value: 'Željezno Polje' },
  { label: 'Zell', value: 'Zell' },
  { label: 'Zell', value: 'Zell' },
  { label: 'Zell', value: 'Zell' },
  { label: 'Zell', value: 'Zell' },
  { label: 'Zell', value: 'Zell' },
  { label: 'Zell Am Main', value: 'Zell Am Main' },
  { label: 'Zell Am Moos', value: 'Zell Am Moos' },
  { label: 'Zell Am Pettenfirst', value: 'Zell Am Pettenfirst' },
  { label: 'Zell Am See', value: 'Zell Am See' },
  { label: 'Zell Am Ziller', value: 'Zell Am Ziller' },
  { label: 'Zell An Der Pram', value: 'Zell An Der Pram' },
  { label: 'Zell Im Fichtelgebirge', value: 'Zell Im Fichtelgebirge' },
  { label: 'Zell Im Wiesental', value: 'Zell Im Wiesental' },
  { label: 'Zell Unter Aichelberg', value: 'Zell Unter Aichelberg' },
  { label: 'Zella-Mehlis', value: 'Zella-Mehlis' },
  { label: 'Zell-Arzberg', value: 'Zell-Arzberg' },
  { label: 'Zellberg', value: 'Zellberg' },
  { label: 'Zellerndorf', value: 'Zellerndorf' },
  { label: 'Zellingen', value: 'Zellingen' },
  { label: 'Zell-Markt', value: 'Zell-Markt' },
  { label: 'Zellwood', value: 'Zellwood' },
  { label: 'Zelmeņi', value: 'Zelmeņi' },
  { label: 'Zelo Buon Persico', value: 'Zelo Buon Persico' },
  { label: 'Zelo Surrigone', value: 'Zelo Surrigone' },
  { label: 'Zelocotitlán', value: 'Zelocotitlán' },
  { label: 'Zelów', value: 'Zelów' },
  { label: 'Zeltingen-Rachtig', value: 'Zeltingen-Rachtig' },
  { label: 'Zeltweg', value: 'Zeltweg' },
  { label: 'Zelyonoye', value: 'Zelyonoye' },
  { label: 'Zelyony Gorod', value: 'Zelyony Gorod' },
  { label: 'Zelzate', value: 'Zelzate' },
  { label: 'Žemaičių Naumiestis', value: 'Žemaičių Naumiestis' },
  { label: 'Zembrzyce', value: 'Zembrzyce' },
  { label: 'Zeme', value: 'Zeme' },
  { label: 'Zemen', value: 'Zemen' },
  { label: 'Zemeş', value: 'Zemeş' },
  { label: 'Zemetchino', value: 'Zemetchino' },
  { label: 'Zemio', value: 'Zemio' },
  { label: 'Zemlyanichnoye', value: 'Zemlyanichnoye' },
  { label: 'Zemlyansk', value: 'Zemlyansk' },
  { label: 'Zemmer', value: 'Zemmer' },
  { label: 'Zemoura', value: 'Zemoura' },
  { label: 'Zempoala', value: 'Zempoala' },
  { label: 'Zempoala', value: 'Zempoala' },
  { label: 'Zemst', value: 'Zemst' },
  { label: 'Zemun', value: 'Zemun' },
  { label: 'Zena', value: 'Zena' },
  { label: 'Zencani', value: 'Zencani' },
  { label: 'Zenevredo', value: 'Zenevredo' },
  { label: 'Zengjia', value: 'Zengjia' },
  { label: 'Zenica', value: 'Zenica' },
  { label: 'Zenon', value: 'Zenon' },
  { label: 'Zenson Di Piave', value: 'Zenson Di Piave' },
  { label: 'Zenting', value: 'Zenting' },
  { label: 'Zentla', value: 'Zentla' },
  { label: 'Zentlalpan', value: 'Zentlalpan' },
  { label: 'Zentsūji Shi', value: 'Zentsūji Shi' },
  { label: 'Zenzeli', value: 'Zenzeli' },
  { label: 'Žepče', value: 'Žepče' },
  { label: 'Zephyrhills', value: 'Zephyrhills' },
  { label: 'Zephyrhills North', value: 'Zephyrhills North' },
  { label: 'Zephyrhills South', value: 'Zephyrhills South' },
  { label: 'Zephyrhills West', value: 'Zephyrhills West' },
  { label: 'Zepponami', value: 'Zepponami' },
  { label: 'Zequentic', value: 'Zequentic' },
  { label: 'Zerain', value: 'Zerain' },
  { label: 'Zeralda', value: 'Zeralda' },
  { label: 'Zeraq', value: 'Zeraq' },
  { label: 'Žeravice', value: 'Žeravice' },
  { label: 'Zerba', value: 'Zerba' },
  { label: 'Zerbo', value: 'Zerbo' },
  { label: 'Zerbolò', value: 'Zerbolò' },
  { label: 'Zerbst', value: 'Zerbst' },
  { label: 'Zerf', value: 'Zerf' },
  { label: 'Zerfaliu', value: 'Zerfaliu' },
  { label: 'Zeri', value: 'Zeri' },
  { label: 'Zeribet El Oued', value: 'Zeribet El Oued' },
  { label: 'Zerind', value: 'Zerind' },
  { label: 'Żerków', value: 'Żerków' },
  { label: 'Zerkten', value: 'Zerkten' },
  { label: 'Zerlach', value: 'Zerlach' },
  { label: 'Zermatt', value: 'Zermatt' },
  { label: 'Zermeghedo', value: 'Zermeghedo' },
  { label: 'Zernez', value: 'Zernez' },
  { label: 'Żernica', value: 'Żernica' },
  { label: 'Zernien', value: 'Zernien' },
  { label: 'Żerniki Wrocławskie', value: 'Żerniki Wrocławskie' },
  { label: 'Zernograd', value: 'Zernograd' },
  { label: 'Zernovoye', value: 'Zernovoye' },
  { label: 'Zero Branco', value: 'Zero Branco' },
  { label: 'Zerouala', value: 'Zerouala' },
  { label: 'Zesgehuchten', value: 'Zesgehuchten' },
  { label: 'Zestap’Oni', value: 'Zestap’Oni' },
  { label: 'Zestoa', value: 'Zestoa' },
  { label: 'Žetale', value: 'Žetale' },
  { label: 'Zetaquira', value: 'Zetaquira' },
  { label: 'Zetea', value: 'Zetea' },
  { label: 'Zetel', value: 'Zetel' },
  { label: 'Zetland', value: 'Zetland' },
  { label: 'Zetten', value: 'Zetten' },
  { label: 'Zettling', value: 'Zettling' },
  { label: 'Zeulenroda', value: 'Zeulenroda' },
  { label: 'Zeuthen', value: 'Zeuthen' },
  { label: 'Zeutschach', value: 'Zeutschach' },
  { label: 'Zeven', value: 'Zeven' },
  { label: 'Zevenaar', value: 'Zevenaar' },
  { label: 'Zevenbergschen Hoek', value: 'Zevenbergschen Hoek' },
  { label: 'Zevenhoven', value: 'Zevenhoven' },
  { label: 'Zevenhuizen', value: 'Zevenhuizen' },
  { label: 'Zevgolateió', value: 'Zevgolateió' },
  { label: 'Zevio', value: 'Zevio' },
  { label: 'Zeya', value: 'Zeya' },
  { label: 'Zeydabad', value: 'Zeydabad' },
  { label: 'Zeyskiy Rayon', value: 'Zeyskiy Rayon' },
  { label: 'Zeytinburnu', value: 'Zeytinburnu' },
  { label: 'Zgierz', value: 'Zgierz' },
  { label: 'Zgłobień', value: 'Zgłobień' },
  { label: 'Zgornja Kungota', value: 'Zgornja Kungota' },
  { label: 'Zgornja Polskava', value: 'Zgornja Polskava' },
  { label: 'Zgornje Bitnje', value: 'Zgornje Bitnje' },
  { label: 'Zgornje Gorje', value: 'Zgornje Gorje' },
  { label: 'Zgornje Jezersko', value: 'Zgornje Jezersko' },
  { label: 'Zgornje Pirniče', value: 'Zgornje Pirniče' },
  { label: 'Zgornji Duplek', value: 'Zgornji Duplek' },
  { label: 'Zgorzelec', value: 'Zgorzelec' },
  { label: 'Zgorzelisko', value: 'Zgorzelisko' },
  { label: 'Zgurovka', value: 'Zgurovka' },
  { label: 'Zhabagly', value: 'Zhabagly' },
  { label: 'Zhabei', value: 'Zhabei' },
  { label: 'Zhabinka', value: 'Zhabinka' },
  { label: 'Zhabinkawski Rayon', value: 'Zhabinkawski Rayon' },
  { label: 'Zhadovka', value: 'Zhadovka' },
  { label: 'Zhaishi Miaozu Dongzuxiang', value: 'Zhaishi Miaozu Dongzuxiang' },
  { label: 'Zhalantun', value: 'Zhalantun' },
  { label: 'Zhalghyztobe', value: 'Zhalghyztobe' },
  { label: 'Zhaludok', value: 'Zhaludok' },
  { label: 'Zhambyl', value: 'Zhambyl' },
  { label: 'Zhanaozen', value: 'Zhanaozen' },
  { label: 'Zhanga Buqtyrma', value: 'Zhanga Buqtyrma' },
  { label: 'Zhangatas', value: 'Zhangatas' },
  { label: 'Zhangjiagang', value: 'Zhangjiagang' },
  { label: 'Zhangjiajie', value: 'Zhangjiajie' },
  { label: 'Zhangjiakou', value: 'Zhangjiakou' },
  { label: 'Zhangjiakou Shi', value: 'Zhangjiakou Shi' },
  { label: 'Zhangjiakou Shi Xuanhua Qu', value: 'Zhangjiakou Shi Xuanhua Qu' },
  { label: 'Zhangjiawo', value: 'Zhangjiawo' },
  { label: 'Zhangwan', value: 'Zhangwan' },
  { label: 'Zhangye', value: 'Zhangye' },
  { label: 'Zhangye Shi', value: 'Zhangye Shi' },
  { label: 'Zhangzhou', value: 'Zhangzhou' },
  { label: 'Zhänibek', value: 'Zhänibek' },
  { label: 'Zhanjiang', value: 'Zhanjiang' },
  { label: 'Zhankhoteko', value: 'Zhankhoteko' },
  { label: 'Zhaobaoshan', value: 'Zhaobaoshan' },
  { label: 'Zhaodong', value: 'Zhaodong' },
  { label: 'Zhaogezhuang', value: 'Zhaogezhuang' },
  { label: 'Zhaoguli', value: 'Zhaoguli' },
  { label: 'Zhaoqing', value: 'Zhaoqing' },
  { label: 'Zhaotong', value: 'Zhaotong' },
  { label: 'Zhaoyuan', value: 'Zhaoyuan' },
  { label: 'Zhaoyuan', value: 'Zhaoyuan' },
  { label: 'Zhaozhou', value: 'Zhaozhou' },
  { label: 'Zhaqsy', value: 'Zhaqsy' },
  { label: 'Zharikovo', value: 'Zharikovo' },
  { label: 'Zharkent', value: 'Zharkent' },
  { label: 'Zharkovskiy', value: 'Zharkovskiy' },
  { label: 'Zharyk', value: 'Zharyk' },
  { label: 'Zhashkiv', value: 'Zhashkiv' },
  { label: 'Zhatay', value: 'Zhatay' },
  { label: 'Zhavoronki', value: 'Zhavoronki' },
  { label: 'Zhdankovskiy', value: 'Zhdankovskiy' },
  { label: 'Zhdanovichy', value: 'Zhdanovichy' },
  { label: 'Zhelezinka', value: 'Zhelezinka' },
  { label: 'Zheleznodorozhnoye', value: 'Zheleznodorozhnoye' },
  { label: 'Zheleznodorozhnyy', value: 'Zheleznodorozhnyy' },
  { label: 'Zheleznodorozhnyy', value: 'Zheleznodorozhnyy' },
  { label: 'Zheleznodorozhnyy', value: 'Zheleznodorozhnyy' },
  { label: 'Zheleznodorozhnyy', value: 'Zheleznodorozhnyy' },
  { label: 'Zheleznogorsk', value: 'Zheleznogorsk' },
  { label: 'Zheleznogorsk', value: 'Zheleznogorsk' },
  { label: 'Zheleznogorsk-Ilimskiy', value: 'Zheleznogorsk-Ilimskiy' },
  { label: 'Zheleznovodsk', value: 'Zheleznovodsk' },
  { label: 'Zhelyabovka', value: 'Zhelyabovka' },
  { label: 'Zhemchug', value: 'Zhemchug' },
  { label: 'Zhemchuzhina', value: 'Zhemchuzhina' },
  { label: 'Zhemgang', value: 'Zhemgang' },
  { label: 'Zhemtala', value: 'Zhemtala' },
  { label: 'Zhengjiatun', value: 'Zhengjiatun' },
  { label: 'Zhengzhou', value: 'Zhengzhou' },
  { label: 'Zhenhaicun', value: 'Zhenhaicun' },
  { label: 'Zhenjiang', value: 'Zhenjiang' },
  { label: 'Zhenjiang', value: 'Zhenjiang' },
  { label: 'Zhenlai', value: 'Zhenlai' },
  { label: 'Zhenzhou', value: 'Zhenzhou' },
  { label: 'Zherdeli', value: 'Zherdeli' },
  { label: 'Zherdevka', value: 'Zherdevka' },
  { label: 'Zheshart', value: 'Zheshart' },
  { label: 'Zhetibay', value: 'Zhetibay' },
  { label: 'Zhezkent', value: 'Zhezkent' },
  { label: 'Zhezqazghan', value: 'Zhezqazghan' },
  { label: 'Zhicheng', value: 'Zhicheng' },
  { label: 'Zhicheng', value: 'Zhicheng' },
  { label: 'Zhigalovo', value: 'Zhigalovo' },
  { label: 'Zhigalovskiy Rayon', value: 'Zhigalovskiy Rayon' },
  { label: 'Zhigansk', value: 'Zhigansk' },
  { label: 'Zhigansky District', value: 'Zhigansky District' },
  { label: 'Zhigulevsk', value: 'Zhigulevsk' },
  { label: 'Zhiguli', value: 'Zhiguli' },
  { label: 'Zhijiang', value: 'Zhijiang' },
  { label: 'Zhiletovo', value: 'Zhiletovo' },
  { label: 'Zhilëvo', value: 'Zhilëvo' },
  { label: 'Zhinvali', value: 'Zhinvali' },
  { label: 'Zhireken', value: 'Zhireken' },
  { label: 'Zhirnov', value: 'Zhirnov' },
  { label: 'Zhirnovsk', value: 'Zhirnovsk' },
  { label: 'Zhirovichi', value: 'Zhirovichi' },
  { label: 'Zhiryatino', value: 'Zhiryatino' },
  { label: 'Zhiryatinskiy Rayon', value: 'Zhiryatinskiy Rayon' },
  { label: 'Zhitintsi', value: 'Zhitintsi' },
  { label: 'Zhitnevo', value: 'Zhitnevo' },
  { label: 'Zhizdra', value: 'Zhizdra' },
  { label: 'Zhizdrinskiy Rayon', value: 'Zhizdrinskiy Rayon' },
  { label: 'Zhlobin', value: 'Zhlobin' },
  { label: 'Zhlobinski Rayon', value: 'Zhlobinski Rayon' },
  { label: 'Zhmerynka', value: 'Zhmerynka' },
  { label: 'Zhob', value: 'Zhob' },
  { label: 'Zhob District', value: 'Zhob District' },
  { label: 'Zholymbet', value: 'Zholymbet' },
  { label: 'Zhongba', value: 'Zhongba' },
  { label: 'Zhongchao', value: 'Zhongchao' },
  { label: 'Zhongfang', value: 'Zhongfang' },
  { label: 'Zhongfang', value: 'Zhongfang' },
  { label: 'Zhongshan', value: 'Zhongshan' },
  { label: 'Zhongshan Prefecture', value: 'Zhongshan Prefecture' },
  { label: 'Zhongshu', value: 'Zhongshu' },
  { label: 'Zhongwei', value: 'Zhongwei' },
  { label: 'Zhongxiang', value: 'Zhongxiang' },
  { label: 'Zhongxing', value: 'Zhongxing' },
  { label: 'Zhongxing New Village', value: 'Zhongxing New Village' },
  { label: 'Zhongzhai', value: 'Zhongzhai' },
  { label: 'Zhosaly', value: 'Zhosaly' },
  { label: 'Zhoucheng', value: 'Zhoucheng' },
  { label: 'Zhoucun', value: 'Zhoucun' },
  { label: 'Zhoukou', value: 'Zhoukou' },
  { label: 'Zhoushan', value: 'Zhoushan' },
  { label: 'Zhouzhuang', value: 'Zhouzhuang' },
  { label: 'Zhovkva', value: 'Zhovkva' },
  { label: 'Zhovte', value: 'Zhovte' },
  { label: 'Zhovti Vody', value: 'Zhovti Vody' },
  { label: 'Zhovtii Brid', value: 'Zhovtii Brid' },
  { label: 'Zhu Cheng City', value: 'Zhu Cheng City' },
  { label: 'Zhuanghe', value: 'Zhuanghe' },
  { label: 'Zhuangyuan', value: 'Zhuangyuan' },
  { label: 'Zhuhai', value: 'Zhuhai' },
  { label: 'Zhuji', value: 'Zhuji' },
  { label: 'Zhujiachang', value: 'Zhujiachang' },
  { label: 'Zhujiajiao', value: 'Zhujiajiao' },
  { label: 'Zhukovka', value: 'Zhukovka' },
  { label: 'Zhukovka', value: 'Zhukovka' },
  { label: 'Zhukovo', value: 'Zhukovo' },
  { label: 'Zhukovskiy', value: 'Zhukovskiy' },
  { label: 'Zhukovskiy Rayon', value: 'Zhukovskiy Rayon' },
  { label: 'Zhukovskiy Rayon', value: 'Zhukovskiy Rayon' },
  { label: 'Zhulebino', value: 'Zhulebino' },
  { label: 'Zhumadian', value: 'Zhumadian' },
  { label: 'Zhumadian Shi', value: 'Zhumadian Shi' },
  { label: 'Zhuoyang', value: 'Zhuoyang' },
  { label: 'Zhupanivka', value: 'Zhupanivka' },
  { label: 'Zhuqi', value: 'Zhuqi' },
  { label: 'Zhuravka', value: 'Zhuravka' },
  { label: 'Zhuravki', value: 'Zhuravki' },
  { label: 'Zhuravli', value: 'Zhuravli' },
  { label: 'Zhuravlyovka', value: 'Zhuravlyovka' },
  { label: 'Zhurbintsi', value: 'Zhurbintsi' },
  { label: 'Zhushi', value: 'Zhushi' },
  { label: 'Zhuzhou', value: 'Zhuzhou' },
  { label: 'Zhuzhou Shi', value: 'Zhuzhou Shi' },
  { label: 'Zhuzhoujiang Miaozuxiang', value: 'Zhuzhoujiang Miaozuxiang' },
  { label: 'Zhvanets', value: 'Zhvanets' },
  { label: 'Zhydachiv', value: 'Zhydachiv' },
  { label: 'Zhytkavichy', value: 'Zhytkavichy' },
  { label: 'Zhytomyr', value: 'Zhytomyr' },
  { label: 'Zhytomyr', value: 'Zhytomyr' },
  { label: 'Zhytomyr Raion', value: 'Zhytomyr Raion' },
  { label: 'Ziaabad', value: 'Ziaabad' },
  { label: 'Ziabar', value: 'Ziabar' },
  { label: 'Ziano Di Fiemme', value: 'Ziano Di Fiemme' },
  { label: 'Ziano Piacentino', value: 'Ziano Piacentino' },
  { label: 'Ziar', value: 'Ziar' },
  { label: 'Žiar Nad Hronom', value: 'Žiar Nad Hronom' },
  { label: 'Ziarat', value: 'Ziarat' },
  { label: 'Ziarat', value: 'Ziarat' },
  { label: 'Ziārat District', value: 'Ziārat District' },
  { label: 'Zibashahr', value: 'Zibashahr' },
  { label: 'Zibello', value: 'Zibello' },
  { label: 'Zibido San Giacomo', value: 'Zibido San Giacomo' },
  { label: 'Zibo', value: 'Zibo' },
  { label: 'Zicuicho', value: 'Zicuicho' },
  { label: 'Zicuirán', value: 'Zicuirán' },
  { label: 'Židlochovice', value: 'Židlochovice' },
  { label: 'Ziduri', value: 'Ziduri' },
  { label: 'Zidurile', value: 'Zidurile' },
  { label: 'Ziebach County', value: 'Ziebach County' },
  { label: 'Ziębice', value: 'Ziębice' },
  { label: 'Zielitz', value: 'Zielitz' },
  { label: 'Zielona Góra', value: 'Zielona Góra' },
  { label: 'Zielonka', value: 'Zielonka' },
  { label: 'Zielonki', value: 'Zielonki' },
  { label: 'Ziemetshausen', value: 'Ziemetshausen' },
  { label: 'Zierbena', value: 'Zierbena' },
  { label: 'Zierenberg', value: 'Zierenberg' },
  { label: 'Zierikzee', value: 'Zierikzee' },
  { label: 'Ziersdorf', value: 'Ziersdorf' },
  { label: 'Ziertheim', value: 'Ziertheim' },
  { label: 'Ziesar', value: 'Ziesar' },
  { label: 'Ziesendorf', value: 'Ziesendorf' },
  { label: 'Žiežmariai', value: 'Žiežmariai' },
  { label: 'Zigoitia', value: 'Zigoitia' },
  { label: 'Zigong', value: 'Zigong' },
  { label: 'Ziguinchor', value: 'Ziguinchor' },
  { label: 'Žihle', value: 'Žihle' },
  { label: 'Zihuateutla', value: 'Zihuateutla' },
  { label: 'Zijinglu', value: 'Zijinglu' },
  { label: 'Zijtaart', value: 'Zijtaart' },
  { label: 'Zilacayotitlán', value: 'Zilacayotitlán' },
  { label: 'Zilair', value: 'Zilair' },
  { label: 'Zile', value: 'Zile' },
  { label: 'Žilina', value: 'Žilina' },
  { label: 'Zillah', value: 'Zillah' },
  { label: 'Zillingdorf', value: 'Zillingdorf' },
  { label: 'Zillingtal', value: 'Zillingtal' },
  { label: 'Zillisheim', value: 'Zillisheim' },
  { label: 'Zillmere', value: 'Zillmere' },
  { label: 'Ziltendorf', value: 'Ziltendorf' },
  { label: 'Zilupe', value: 'Zilupe' },
  { label: 'Zilwaukee', value: 'Zilwaukee' },
  { label: 'Zilzie', value: 'Zilzie' },
  { label: 'Zima', value: 'Zima' },
  { label: 'Zimandu Nou', value: 'Zimandu Nou' },
  { label: 'Zimapan', value: 'Zimapan' },
  { label: 'Zimatepec', value: 'Zimatepec' },
  { label: 'Zimatlán De Álvarez', value: 'Zimatlán De Álvarez' },
  { label: 'Zimbor', value: 'Zimbor' },
  { label: 'Zimino', value: 'Zimino' },
  { label: 'Ziminskiy Rayon', value: 'Ziminskiy Rayon' },
  { label: 'Zimmerman', value: 'Zimmerman' },
  { label: 'Zimmern Ob Rottweil', value: 'Zimmern Ob Rottweil' },
  { label: 'Zimmersheim', value: 'Zimmersheim' },
  { label: 'Zimmi', value: 'Zimmi' },
  { label: 'Zimnicea', value: 'Zimnicea' },
  { label: 'Zimnicele', value: 'Zimnicele' },
  { label: 'Zimone', value: 'Zimone' },
  { label: 'Zimovniki', value: 'Zimovniki' },
  { label: 'Zimți', value: 'Zimți' },
  { label: 'Zinacantán', value: 'Zinacantán' },
  { label: 'Zinacantepec', value: 'Zinacantepec' },
  { label: 'Zinacatepec', value: 'Zinacatepec' },
  { label: 'Zináparo', value: 'Zináparo' },
  { label: 'Zinapécuaro', value: 'Zinapécuaro' },
  { label: 'Zinasco Vecchio', value: 'Zinasco Vecchio' },
  { label: 'Zinat', value: 'Zinat' },
  { label: 'Zindah Jān', value: 'Zindah Jān' },
  { label: 'Zinder', value: 'Zinder' },
  { label: 'Zingem', value: 'Zingem' },
  { label: 'Zingibar', value: 'Zingibar' },
  { label: 'Zingst', value: 'Zingst' },
  { label: 'Ziniaré', value: 'Ziniaré' },
  { label: 'Zinjibār', value: 'Zinjibār' },
  { label: 'Zinkiv', value: 'Zinkiv' },
  { label: 'Zinna', value: 'Zinna' },
  { label: 'Zintan', value: 'Zintan' },
  { label: 'Zion', value: 'Zion' },
  { label: 'Zion', value: 'Zion' },
  { label: 'Zions Hill', value: 'Zions Hill' },
  { label: 'Zions Hill', value: 'Zions Hill' },
  { label: 'Zionsville', value: 'Zionsville' },
  { label: 'Ziordia', value: 'Ziordia' },
  { label: 'Ziortza-Bolibar', value: 'Ziortza-Bolibar' },
  { label: 'Zipacón', value: 'Zipacón' },
  { label: 'Zipaquirá', value: 'Zipaquirá' },
  { label: 'Zipári', value: 'Zipári' },
  { label: 'Zipiajo', value: 'Zipiajo' },
  { label: 'Ziquítaro', value: 'Ziquítaro' },
  { label: 'Zira', value: 'Zira' },
  { label: 'Zirab', value: 'Zirab' },
  { label: 'Zirabiyeh', value: 'Zirabiyeh' },
  { label: 'Ziracuaretiro', value: 'Ziracuaretiro' },
  { label: 'Zirahuato De Los Bernal', value: 'Zirahuato De Los Bernal' },
  { label: 'Zirahuén', value: 'Zirahuén' },
  { label: 'Zirándaro', value: 'Zirándaro' },
  { label: 'Zirándaro De Los Chávez', value: 'Zirándaro De Los Chávez' },
  { label: 'Zirc', value: 'Zirc' },
  { label: 'Zirci Járás', value: 'Zirci Járás' },
  { label: 'Zirgan', value: 'Zirgan' },
  { label: 'Žiri', value: 'Žiri' },
  { label: 'Zirimícuaro', value: 'Zirimícuaro' },
  { label: 'Zirkuh', value: 'Zirkuh' },
  { label: 'Zirl', value: 'Zirl' },
  { label: 'Zirndorf', value: 'Zirndorf' },
  { label: 'Ziro', value: 'Ziro' },
  { label: 'Ziroudani', value: 'Ziroudani' },
  { label: 'Žirovnica', value: 'Žirovnica' },
  { label: 'Žirovnice', value: 'Žirovnice' },
  { label: 'Zistersdorf', value: 'Zistersdorf' },
  { label: 'Žitenice', value: 'Žitenice' },
  { label: 'Žitište', value: 'Žitište' },
  { label: 'Zitlala', value: 'Zitlala' },
  { label: 'Zitlaltépec', value: 'Zitlaltépec' },
  { label: 'Žitoše', value: 'Žitoše' },
  { label: 'Zittau', value: 'Zittau' },
  { label: 'Živinice', value: 'Živinice' },
  { label: 'Živinice', value: 'Živinice' },
  { label: 'Ziway', value: 'Ziway' },
  { label: 'Ziyārat Ali', value: 'Ziyārat Ali' },
  { label: 'Ziyouna', value: 'Ziyouna' },
  { label: 'Žiželice', value: 'Žiželice' },
  { label: 'Zizers', value: 'Zizers' },
  { label: 'Zizicazapa', value: 'Zizicazapa' },
  { label: 'Zizin', value: 'Zizin' },
  { label: 'Zizur Mayor/Zizur Nagusia', value: 'Zizur Mayor/Zizur Nagusia' },
  { label: 'Zizurkil', value: 'Zizurkil' },
  { label: 'Zlatar', value: 'Zlatar' },
  { label: 'Zlatar Bistrica', value: 'Zlatar Bistrica' },
  { label: 'Zlătărei', value: 'Zlătărei' },
  { label: 'Zlataritsa', value: 'Zlataritsa' },
  { label: 'Zlaté Hory', value: 'Zlaté Hory' },
  { label: 'Zlaté Moravce', value: 'Zlaté Moravce' },
  { label: 'Zlatibor', value: 'Zlatibor' },
  { label: 'Zlatitsa', value: 'Zlatitsa' },
  { label: 'Zlatna', value: 'Zlatna' },
  { label: 'Zlatni Pyasatsi', value: 'Zlatni Pyasatsi' },
  { label: 'Zlatograd', value: 'Zlatograd' },
  { label: 'Zlatoust', value: 'Zlatoust' },
  { label: 'Zlătunoaia', value: 'Zlătunoaia' },
  { label: 'Zławieś Wielka', value: 'Zławieś Wielka' },
  { label: 'Žleby', value: 'Žleby' },
  { label: 'Zlechov', value: 'Zlechov' },
  { label: 'Zletovo', value: 'Zletovo' },
  { label: 'Zlín', value: 'Zlín' },
  { label: 'Zliten', value: 'Zliten' },
  { label: 'Zliv', value: 'Zliv' },
  { label: 'Zlobichi', value: 'Zlobichi' },
  { label: 'Złocieniec', value: 'Złocieniec' },
  { label: 'Złoczew', value: 'Złoczew' },
  { label: 'Zlonice', value: 'Zlonice' },
  { label: 'Złota', value: 'Złota' },
  { label: 'Złota', value: 'Złota' },
  { label: 'Złotniki', value: 'Złotniki' },
  { label: 'Złotniki', value: 'Złotniki' },
  { label: 'Złotniki Kujawskie', value: 'Złotniki Kujawskie' },
  { label: 'Złotoryja', value: 'Złotoryja' },
  { label: 'Złotów', value: 'Złotów' },
  { label: 'Złoty Stok', value: 'Złoty Stok' },
  { label: 'Žlutava', value: 'Žlutava' },
  { label: 'Žlutice', value: 'Žlutice' },
  { label: 'Zlynka', value: 'Zlynka' },
  { label: 'Zlynka', value: 'Zlynka' },
  { label: 'Zmajevo', value: 'Zmajevo' },
  { label: 'Zmeinogorsk', value: 'Zmeinogorsk' },
  { label: 'Zmeu', value: 'Zmeu' },
  { label: 'Zmeyka', value: 'Zmeyka' },
  { label: 'Zmeyskaya', value: 'Zmeyskaya' },
  { label: 'Zmiennica', value: 'Zmiennica' },
  { label: 'Żmigród', value: 'Żmigród' },
  { label: 'Zmijavci', value: 'Zmijavci' },
  { label: 'Žminj', value: 'Žminj' },
  { label: 'Zminjak', value: 'Zminjak' },
  { label: 'Zmiyëvka', value: 'Zmiyëvka' },
  { label: 'Zmiyiv', value: 'Zmiyiv' },
  { label: 'Znamenka', value: 'Znamenka' },
  { label: 'Znamenka', value: 'Znamenka' },
  { label: 'Znamenka', value: 'Znamenka' },
  { label: 'Znamensk', value: 'Znamensk' },
  { label: 'Znamensk', value: 'Znamensk' },
  { label: 'Znamenskiy Rayon', value: 'Znamenskiy Rayon' },
  { label: 'Znamenskoye', value: 'Znamenskoye' },
  { label: 'Znamenskoye', value: 'Znamenskoye' },
  { label: 'Znamenskoye', value: 'Znamenskoye' },
  { label: 'Żnin', value: 'Żnin' },
  { label: 'Znojmo', value: 'Znojmo' },
  { label: 'Znomenka', value: 'Znomenka' },
  { label: 'Zoagli', value: 'Zoagli' },
  { label: 'Zoar', value: 'Zoar' },
  { label: 'Zoatecpan', value: 'Zoatecpan' },
  { label: 'Zöbern', value: 'Zöbern' },
  { label: 'Zöblen', value: 'Zöblen' },
  { label: 'Zöblitz', value: 'Zöblitz' },
  { label: 'Zocca', value: 'Zocca' },
  { label: 'Zoccorino-Vergo', value: 'Zoccorino-Vergo' },
  { label: 'Zocea', value: 'Zocea' },
  { label: 'Zocoteaca De León', value: 'Zocoteaca De León' },
  { label: 'Zoelen', value: 'Zoelen' },
  { label: 'Zoersel', value: 'Zoersel' },
  { label: 'Zoetermeer', value: 'Zoetermeer' },
  { label: 'Zoeterwoude-Dorp', value: 'Zoeterwoude-Dorp' },
  { label: 'Zofingen', value: 'Zofingen' },
  { label: 'Zogno', value: 'Zogno' },
  { label: 'Zográfos', value: 'Zográfos' },
  { label: 'Zohaan', value: 'Zohaan' },
  { label: 'Zoh-Laguna', value: 'Zoh-Laguna' },
  { label: 'Zol’Noye', value: 'Zol’Noye' },
  { label: 'Zol’Skiy Rayon', value: 'Zol’Skiy Rayon' },
  { label: 'Zola Predosa', value: 'Zola Predosa' },
  { label: 'Zolfo Springs', value: 'Zolfo Springs' },
  { label: 'Żoliborz', value: 'Żoliborz' },
  { label: 'Żółkiewka', value: 'Żółkiewka' },
  { label: 'Zolla', value: 'Zolla' },
  { label: 'Zollikerberg', value: 'Zollikerberg' },
  { label: 'Zollikofen', value: 'Zollikofen' },
  { label: 'Zollikon', value: 'Zollikon' },
  { label: 'Zolling', value: 'Zolling' },
  { label: 'Zollino', value: 'Zollino' },
  { label: 'Zolochiv', value: 'Zolochiv' },
  { label: 'Zolochiv', value: 'Zolochiv' },
  { label: 'Zolonquiapa', value: 'Zolonquiapa' },
  { label: 'Zolotkovo', value: 'Zolotkovo' },
  { label: 'Zolotonosha', value: 'Zolotonosha' },
  { label: 'Zolotoye Pole', value: 'Zolotoye Pole' },
  { label: 'Zolotukhino', value: 'Zolotukhino' },
  { label: 'Zolotukhinskiy Rayon', value: 'Zolotukhinskiy Rayon' },
  { label: 'Zolotyy Potik', value: 'Zolotyy Potik' },
  { label: 'Żołynia', value: 'Żołynia' },
  { label: 'Zoma La', value: 'Zoma La' },
  { label: 'Zomba', value: 'Zomba' },
  { label: 'Zomba', value: 'Zomba' },
  { label: 'Zomba District', value: 'Zomba District' },
  { label: 'Zombodze Ikhundla', value: 'Zombodze Ikhundla' },
  { label: 'Zomergem', value: 'Zomergem' },
  { label: 'Zomin', value: 'Zomin' },
  { label: 'Zona Bananera', value: 'Zona Bananera' },
  { label: 'Zona Industriale', value: 'Zona Industriale' },
  { label: 'Zona Industriale', value: 'Zona Industriale' },
  { label: 'Zona Urbana Ejidal', value: 'Zona Urbana Ejidal' },
  { label: 'Zona Urbana Ejido Isla Mujeres', value: 'Zona Urbana Ejido Isla Mujeres' },
  { label: 'Zonal’Noye', value: 'Zonal’Noye' },
  { label: 'Zonderwijk', value: 'Zonderwijk' },
  { label: 'Zone', value: 'Zone' },
  { label: 'Zongolica', value: 'Zongolica' },
  { label: 'Zongozotla', value: 'Zongozotla' },
  { label: 'Zonhoven', value: 'Zonhoven' },
  { label: 'Zonianá', value: 'Zonianá' },
  { label: 'Zonnebeke', value: 'Zonnebeke' },
  { label: 'Zonnemaat', value: 'Zonnemaat' },
  { label: 'Zonouz', value: 'Zonouz' },
  { label: 'Zontecomatlán De López Y Fuentes', value: 'Zontecomatlán De López Y Fuentes' },
  { label: 'Zonza', value: 'Zonza' },
  { label: 'Zopilotepe', value: 'Zopilotepe' },
  { label: 'Zopoco', value: 'Zopoco' },
  { label: 'Zoppè Di Cadore', value: 'Zoppè Di Cadore' },
  { label: 'Zoppola', value: 'Zoppola' },
  { label: 'Zoquiapa', value: 'Zoquiapa' },
  { label: 'Zoquiapan', value: 'Zoquiapan' },
  { label: 'Zoquiapan', value: 'Zoquiapan' },
  { label: 'Zoquiopan', value: 'Zoquiopan' },
  { label: 'Zoquite', value: 'Zoquite' },
  { label: 'Zoquitipán', value: 'Zoquitipán' },
  { label: 'Zoquitlán', value: 'Zoquitlán' },
  { label: 'Zoquitlán', value: 'Zoquitlán' },
  { label: 'Zorak', value: 'Zorak' },
  { label: 'Zoravan', value: 'Zoravan' },
  { label: 'Żórawina', value: 'Żórawina' },
  { label: 'Zörbig', value: 'Zörbig' },
  { label: 'Zorești', value: 'Zorești' },
  { label: 'Zorge', value: 'Zorge' },
  { label: 'Zorgo', value: 'Zorgo' },
  { label: 'Zoriane', value: 'Zoriane' },
  { label: 'Zorile', value: 'Zorile' },
  { label: 'Zorita', value: 'Zorita' },
  { label: 'Zorita De La Frontera', value: 'Zorita De La Frontera' },
  { label: 'Zorita De Los Canes', value: 'Zorita De Los Canes' },
  { label: 'Zorita Del Maestrazgo', value: 'Zorita Del Maestrazgo' },
  { label: 'Zorkino', value: 'Zorkino' },
  { label: 'Zorleni', value: 'Zorleni' },
  { label: 'Zorlenţu Mare', value: 'Zorlenţu Mare' },
  { label: 'Zorlesco', value: 'Zorlesco' },
  { label: 'Zorneding', value: 'Zorneding' },
  { label: 'Zornheim', value: 'Zornheim' },
  { label: 'Zorokiv', value: 'Zorokiv' },
  { label: 'Zorraquín', value: 'Zorraquín' },
  { label: 'Zorritos', value: 'Zorritos' },
  { label: 'Zortéa', value: 'Zortéa' },
  { label: 'Żory', value: 'Żory' },
  { label: 'Zorya', value: 'Zorya' },
  { label: 'Zoryns’K', value: 'Zoryns’K' },
  { label: 'Zöschen', value: 'Zöschen' },
  { label: 'Zosimivka', value: 'Zosimivka' },
  { label: 'Zossen', value: 'Zossen' },
  { label: 'Zotes Del Páramo', value: 'Zotes Del Páramo' },
  { label: 'Zothé', value: 'Zothé' },
  { label: 'Zotoltitlán', value: 'Zotoltitlán' },
  { label: 'Zotoluca', value: 'Zotoluca' },
  { label: 'Zottegem', value: 'Zottegem' },
  { label: 'Zoucheng', value: 'Zoucheng' },
  { label: 'Zouérat', value: 'Zouérat' },
  { label: 'Zouerate', value: 'Zouerate' },
  { label: 'Zouila', value: 'Zouila' },
  { label: 'Zoumi', value: 'Zoumi' },
  { label: 'Zoundweogo Province', value: 'Zoundweogo Province' },
  { label: 'Zoutelande', value: 'Zoutelande' },
  { label: 'Zoutkamp', value: 'Zoutkamp' },
  { label: 'Zoutleeuw', value: 'Zoutleeuw' },
  { label: 'Zovaber', value: 'Zovaber' },
  { label: 'Zovencedo', value: 'Zovencedo' },
  { label: 'Zovuni', value: 'Zovuni' },
  { label: 'Zoyatitla', value: 'Zoyatitla' },
  { label: 'Zoyatlán', value: 'Zoyatlán' },
  { label: 'Zoyatzingo', value: 'Zoyatzingo' },
  { label: 'Zoyotla', value: 'Zoyotla' },
  { label: 'Zozocolco De Guerrero', value: 'Zozocolco De Guerrero' },
  { label: 'Zozocolco De Hidalgo', value: 'Zozocolco De Hidalgo' },
  { label: 'Zreče', value: 'Zreče' },
  { label: 'Zrenjanin', value: 'Zrenjanin' },
  { label: 'Zriba', value: 'Zriba' },
  { label: 'Zrinski Topolovac', value: 'Zrinski Topolovac' },
  { label: 'Zrnovci', value: 'Zrnovci' },
  { label: 'Žrnovnica', value: 'Žrnovnica' },
  { label: 'Žrnovo', value: 'Žrnovo' },
  { label: 'Zruč Nad Sázavou', value: 'Zruč Nad Sázavou' },
  { label: 'Zsámbék', value: 'Zsámbék' },
  { label: 'Zsámbok', value: 'Zsámbok' },
  { label: 'Zschadrass', value: 'Zschadrass' },
  { label: 'Zschepplin', value: 'Zschepplin' },
  { label: 'Zscherben', value: 'Zscherben' },
  { label: 'Zscherndorf', value: 'Zscherndorf' },
  { label: 'Zschopau', value: 'Zschopau' },
  { label: 'Zschorlau', value: 'Zschorlau' },
  { label: 'Zschornewitz', value: 'Zschornewitz' },
  { label: 'Zschortau', value: 'Zschortau' },
  { label: 'Zsombó', value: 'Zsombó' },
  { label: 'Zuba', value: 'Zuba' },
  { label: 'Zubia La', value: 'Zubia La' },
  { label: 'Zubiena', value: 'Zubiena' },
  { label: 'Zubieta', value: 'Zubieta' },
  { label: 'Zubivshchina', value: 'Zubivshchina' },
  { label: 'Zubova Polyana', value: 'Zubova Polyana' },
  { label: 'Zubovo', value: 'Zubovo' },
  { label: 'Zubovo-Polyanskiy Rayon', value: 'Zubovo-Polyanskiy Rayon' },
  { label: 'Zubří', value: 'Zubří' },
  { label: 'Zubrzyca Dolna', value: 'Zubrzyca Dolna' },
  { label: 'Zubrzyca Górna', value: 'Zubrzyca Górna' },
  { label: 'Zubtsov', value: 'Zubtsov' },
  { label: 'Zubtsovskiy Rayon', value: 'Zubtsovskiy Rayon' },
  { label: 'Zubutli-Miatli', value: 'Zubutli-Miatli' },
  { label: 'Zucaina', value: 'Zucaina' },
  { label: 'Zuccarello', value: 'Zuccarello' },
  { label: 'Zuccoli', value: 'Zuccoli' },
  { label: 'Zuchwil', value: 'Zuchwil' },
  { label: 'Zuclo', value: 'Zuclo' },
  { label: 'Zudilovo', value: 'Zudilovo' },
  { label: 'Zuénoula', value: 'Zuénoula' },
  { label: 'Zuera', value: 'Zuera' },
  { label: 'Zufre', value: 'Zufre' },
  { label: 'Zug', value: 'Zug' },
  { label: 'Zugarramurdi', value: 'Zugarramurdi' },
  { label: 'Zugdidi', value: 'Zugdidi' },
  { label: 'Zugliano', value: 'Zugliano' },
  { label: 'Zugliano-Terenzano-Cargnacco', value: 'Zugliano-Terenzano-Cargnacco' },
  { label: 'Zuglio', value: 'Zuglio' },
  { label: 'Zugló', value: 'Zugló' },
  { label: 'Zuheros', value: 'Zuheros' },
  { label: 'Zuhres', value: 'Zuhres' },
  { label: 'Zuia', value: 'Zuia' },
  { label: 'Zuid-Beijerland', value: 'Zuid-Beijerland' },
  { label: 'Zuid-Berghuizen', value: 'Zuid-Berghuizen' },
  { label: 'Zuidbroek', value: 'Zuidbroek' },
  { label: 'Zuiderburen', value: 'Zuiderburen' },
  { label: 'Zuidermeer', value: 'Zuidermeer' },
  { label: 'Zuidhorn', value: 'Zuidhorn' },
  { label: 'Zuidlaren', value: 'Zuidlaren' },
  { label: 'Zuid-Scharwoude', value: 'Zuid-Scharwoude' },
  { label: 'Zuidwijk', value: 'Zuidwijk' },
  { label: 'Zuienkerke', value: 'Zuienkerke' },
  { label: 'Zuilichem', value: 'Zuilichem' },
  { label: 'Zújar', value: 'Zújar' },
  { label: 'Žujince', value: 'Žujince' },
  { label: 'Żukowo', value: 'Żukowo' },
  { label: 'Zula', value: 'Zula' },
  { label: 'Zula', value: 'Zula' },
  { label: 'Žulová', value: 'Žulová' },
  { label: 'Zulpich', value: 'Zulpich' },
  { label: 'Zulte', value: 'Zulte' },
  { label: 'Zululand District Municipality', value: 'Zululand District Municipality' },
  { label: 'Zumaglia', value: 'Zumaglia' },
  { label: 'Zumaia', value: 'Zumaia' },
  { label: 'Zumarraga', value: 'Zumarraga' },
  { label: 'Zumarraga', value: 'Zumarraga' },
  { label: 'Zumarraga', value: 'Zumarraga' },
  { label: 'Zumbrota', value: 'Zumbrota' },
  { label: 'Zumikon', value: 'Zumikon' },
  { label: 'Zumpahuacán', value: 'Zumpahuacán' },
  { label: 'Zumpango', value: 'Zumpango' },
  { label: 'Zumpango', value: 'Zumpango' },
  { label: 'Zumpango', value: 'Zumpango' },
  { label: 'Zumpango Del Río', value: 'Zumpango Del Río' },
  { label: 'Zumpano', value: 'Zumpano' },
  { label: 'Zundert', value: 'Zundert' },
  { label: 'Zuñeda', value: 'Zuñeda' },
  { label: 'Zungeru', value: 'Zungeru' },
  { label: 'Zungoli', value: 'Zungoli' },
  { label: 'Zungri', value: 'Zungri' },
  { label: 'Zunheboto', value: 'Zunheboto' },
  { label: 'Zunhua', value: 'Zunhua' },
  { label: 'Zuni', value: 'Zuni' },
  { label: 'Zuni Pueblo', value: 'Zuni Pueblo' },
  { label: 'Zúñiga', value: 'Zúñiga' },
  { label: 'Zunil', value: 'Zunil' },
  { label: 'Zunilito', value: 'Zunilito' },
  { label: 'Zunyi', value: 'Zunyi' },
  { label: 'Zunzgen', value: 'Zunzgen' },
  { label: 'Zuoz', value: 'Zuoz' },
  { label: 'Župa Dubrovačka', value: 'Župa Dubrovačka' },
  { label: 'Županja', value: 'Županja' },
  { label: 'Ẕur Hadassa', value: 'Ẕur Hadassa' },
  { label: 'Ẕur Moshe', value: 'Ẕur Moshe' },
  { label: 'Zurabad', value: 'Zurabad' },
  { label: 'Żurawica', value: 'Żurawica' },
  { label: 'Żurawiczki', value: 'Żurawiczki' },
  { label: 'Zurgena', value: 'Zurgena' },
  { label: 'Zürich', value: 'Zürich' },
  { label: 'Zurndorf', value: 'Zurndorf' },
  { label: 'Żuromin', value: 'Żuromin' },
  { label: 'Zurow', value: 'Zurow' },
  { label: 'Żurowa', value: 'Żurowa' },
  { label: 'Żurrieq', value: 'Żurrieq' },
  { label: 'Zuru', value: 'Zuru' },
  { label: 'Zurumbeneo', value: 'Zurumbeneo' },
  { label: 'Zusamaltheim', value: 'Zusamaltheim' },
  { label: 'Zushi Shi', value: 'Zushi Shi' },
  { label: 'Zusmarshausen', value: 'Zusmarshausen' },
  { label: 'Züssow', value: 'Züssow' },
  { label: 'Zutendaal', value: 'Zutendaal' },
  { label: 'Zutkerque', value: 'Zutkerque' },
  { label: 'Zutphen', value: 'Zutphen' },
  { label: 'Zuwārah', value: 'Zuwārah' },
  { label: 'Zuya', value: 'Zuya' },
  { label: 'Zuydcoote', value: 'Zuydcoote' },
  { label: 'Zuyevka', value: 'Zuyevka' },
  { label: 'Žužemberk', value: 'Žužemberk' },
  { label: 'Zuzenhausen', value: 'Zuzenhausen' },
  { label: 'Zuzwil', value: 'Zuzwil' },
  { label: 'Zvečka', value: 'Zvečka' },
  { label: 'Zvenigorod', value: 'Zvenigorod' },
  { label: 'Zvenigovo', value: 'Zvenigovo' },
  { label: 'Zvenigovskiy Rayon', value: 'Zvenigovskiy Rayon' },
  { label: 'Zvenihorodka', value: 'Zvenihorodka' },
  { label: 'Zverevo', value: 'Zverevo' },
  { label: 'Zverinogolovskoye', value: 'Zverinogolovskoye' },
  { label: 'Zvëzdnyy', value: 'Zvëzdnyy' },
  { label: 'Zvëzdnyy', value: 'Zvëzdnyy' },
  { label: 'Zvishavane', value: 'Zvishavane' },
  { label: 'Zvishavane District', value: 'Zvishavane District' },
  { label: 'Zvizdal', value: 'Zvizdal' },
  { label: 'Zvole', value: 'Zvole' },
  { label: 'Zvolen', value: 'Zvolen' },
  { label: 'Zvoriştea', value: 'Zvoriştea' },
  { label: 'Zvornik', value: 'Zvornik' },
  { label: 'Zwaag', value: 'Zwaag' },
  { label: 'Zwaagdijk-Oost', value: 'Zwaagdijk-Oost' },
  { label: 'Zwaagdijk-West', value: 'Zwaagdijk-West' },
  { label: 'Zwaanshoek', value: 'Zwaanshoek' },
  { label: 'Zwedru', value: 'Zwedru' },
  { label: 'Zweibrücken', value: 'Zweibrücken' },
  { label: 'Zweiflingen', value: 'Zweiflingen' },
  { label: 'Zweisimmen', value: 'Zweisimmen' },
  { label: 'Zwenkau', value: 'Zwenkau' },
  { label: 'Zwentendorf', value: 'Zwentendorf' },
  { label: 'Zwettl An Der Rodl', value: 'Zwettl An Der Rodl' },
  { label: 'Zwettl Stadt', value: 'Zwettl Stadt' },
  { label: 'Zwevegem', value: 'Zwevegem' },
  { label: 'Zwickau', value: 'Zwickau' },
  { label: 'Zwiefalten', value: 'Zwiefalten' },
  { label: 'Zwierzyń', value: 'Zwierzyń' },
  { label: 'Zwierzyniec', value: 'Zwierzyniec' },
  { label: 'Zwiesel', value: 'Zwiesel' },
  { label: 'Zwijndrecht', value: 'Zwijndrecht' },
  { label: 'Zwijndrecht', value: 'Zwijndrecht' },
  { label: 'Zwingen', value: 'Zwingen' },
  { label: 'Zwingenberg', value: 'Zwingenberg' },
  { label: 'Zwochau', value: 'Zwochau' },
  { label: 'Zwoleń', value: 'Zwoleń' },
  { label: 'Zwölfaxing', value: 'Zwölfaxing' },
  { label: 'Zwolle', value: 'Zwolle' },
  { label: 'Zwolle', value: 'Zwolle' },
  { label: 'Zwönitz', value: 'Zwönitz' },
  { label: 'Zwota', value: 'Zwota' },
  { label: 'Zyablikovo', value: 'Zyablikovo' },
  { label: 'Zybiny', value: 'Zybiny' },
  { label: 'Żychlin', value: 'Żychlin' },
  { label: 'Zyembin', value: 'Zyembin' },
  { label: 'Zygós', value: 'Zygós' },
  { label: 'Zykovo', value: 'Zykovo' },
  { label: 'Zykovo', value: 'Zykovo' },
  { label: 'Zymohiria', value: 'Zymohiria' },
  { label: 'Żyraków', value: 'Żyraków' },
  { label: 'Żyrardów', value: 'Żyrardów' },
  { label: 'Zyrya', value: 'Zyrya' },
  { label: 'Zyryanka', value: 'Zyryanka' },
  { label: 'Zyryanovsk', value: 'Zyryanovsk' },
  { label: 'Zyryanskoye', value: 'Zyryanskoye' },
  { label: 'Żyrzyn', value: 'Żyrzyn' },
  { label: 'Zyukayka', value: 'Zyukayka' },
  { label: 'Zyuzel’Skiy', value: 'Zyuzel’Skiy' },
  { label: 'Zyuzino', value: 'Zyuzino' },
  { label: 'Żywiec', value: 'Żywiec' },
  
];

const CityNames = ({ selectedCities, handleSelectChange }) => {
  const animatedComponents = makeAnimated();

  return (
    <div>
      <Select
        isMulti
        value={selectedCities}
        onChange={handleSelectChange}
        options={cityNames}
        placeholder="Select Cities..."
        components={animatedComponents}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default CityNames;





// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const CityNames = ({ data, selectedCities, handleSelectChange }) => {
//   const cityNames = [...new Set(data.map((item) => item.City))].map((city) => ({
//     value: city,
//     label: city,
//   })).filter((city) => city.label !== null); // Add this filter

//   // Sort the city names in alphabetical order
//   cityNames.sort((a, b) => a.label.localeCompare(b.label));

//   const animatedComponents = makeAnimated();   

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedCities}
//         onChange={handleSelectChange}
//         options={cityNames}
//         placeholder="Select Cities..."
//         components={animatedComponents}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default CityNames;