import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const industries = [
  { label: 'Aerospace And Aviation', value: 'Aerospace And Aviation' },
  { label: 'Agriculture And Forestry', value: 'Agriculture And Forestry' },
  { label: 'Banking', value: 'Banking' },
  { label: 'Biotechnology', value: 'Biotechnology' },
  { label: 'Computer And Electronics', value: 'Computer And Electronics' },
  { label: 'Construction And Engineering', value: 'Construction And Engineering' },
  { label: 'Education', value: 'Education' },
  { label: 'Energy, Raw Materials And Utilities', value: 'Energy, Raw Materials And Utilities' },
  { label: 'Entertainment And Gaming', value: 'Entertainment And Gaming' },
  { label: 'Financial Activities', value: 'Financial Activities' },
  { label: 'Food And Beverage', value: 'Food And Beverage' },
  { label: 'Government And Public Administration', value: 'Government And Public Administration' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Information Technology', value: 'Information Technology' },
  { label: 'Legal Solutions', value: 'Legal Solutions' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Marketing & Advertising', value: 'Marketing & Advertising' },
  { label: 'Media And Publishing', value: 'Media And Publishing' },
  { label: 'Non-Profit', value: 'Non-Profit' },
  { label: 'Professional Services', value: 'Professional Services' },
  { label: 'Public Relations', value: 'Public Relations' },
  { label: 'Real Estate', value: 'Real Estate' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'Travel And Hospitality', value: 'Travel And Hospitality' },
  { label: 'Shipping And Distribution', value: 'Shipping And Distribution' },
  { label: 'VAR/VAD/System Integrator', value: 'Var/Vad/System Integrator' },
  { label: 'Wholesale', value: 'Wholesale' },
];

const Industry = ({ selectedIndustry, handleSelectChange }) => {
  const animatedComponents = makeAnimated();

  return (
    <div>
      <Select
        isMulti
        value={selectedIndustry}
        onChange={handleSelectChange}
        options={industries}
        placeholder="Select Industry..."
        components={animatedComponents}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default Industry;


// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const Industry = ({ data, selectedIndustry, handleSelectChange }) => {
//   const industryNames = [...new Set(data.map((item) => item.OriginalIndustry))].map((industry) => ({
//     value: industry,
//     label: industry,
//   }));

//   // Sort the city names in alphabetical order
//   industryNames.sort((a, b) => a.label.localeCompare(b.label));

//   const animatedComponents = makeAnimated();   

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedIndustry}
//         onChange={handleSelectChange}
//         options={industryNames}
//         placeholder="Select Industry..."
//         components={animatedComponents}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default Industry;