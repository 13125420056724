import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const subIndustries = [
{ label: 'Accounting Services', value: 'Accounting Services' },
{ label: 'Administration Of Justice', value: 'Administration Of Justice' },
{ label: 'Government Administration', value: 'Government Administration' },
{ label: 'Advertising & Marketing', value: 'Advertising & Marketing' },
{ label: 'Aerospace & Defense', value: 'Aerospace & Defense' },
{ label: 'Aggregates, Concrete & Cement', value: 'Aggregates, Concrete & Cement' },
{ label: 'Agriculture', value: 'Agriculture' },
{ label: 'Food & Agriculture', value: 'Food & Agriculture' },
{ label: 'Airlines And Aviation', value: 'Airlines And Aviation' },
{ label: 'Alternative Dispute Resolution', value: 'Alternative Dispute Resolution' },
{ label: 'Alternative Medicine', value: 'Alternative Medicine' },
{ label: 'Amusement Parks And Arcades', value: 'Amusement Parks And Arcades' },
{ label: 'Animal Feed Manufacturing', value: 'Animal Feed Manufacturing' },
{ label: 'Animals & Livestock', value: 'Animals & Livestock' },
{ label: 'Animation And Post-Production', value: 'Animation And Post-Production' },
{ label: 'Apparel & Accessories Retail', value: 'Apparel & Accessories Retail' },
{ label: 'Apparel & Fashion', value: 'Apparel & Fashion' },
{ label: 'Appliances', value: 'Appliances' },
{ label: 'Architecture & Planning', value: 'Architecture & Planning' },
{ label: 'Architecture, Engineering & Design', value: 'Architecture, Engineering & Design' },
{ label: 'Armed Forces', value: 'Armed Forces' },
{ label: 'Artists And Writers', value: 'Artists And Writers' },
{ label: 'Audio And Video Equipment Manufacturing', value: 'Audio And Video Equipment Manufacturing' },
{ label: 'Automation', value: 'Automation' },
{ label: 'Automation Machinery Manufacturing', value: 'Automation Machinery Manufacturing' },
{ label: 'Automobile Parts Stores', value: 'Automobile Parts Stores' },
{ label: 'Automotive', value: 'Automotive' },
{ label: 'Automotive Service & Collision Repair', value: 'Automotive Service & Collision Repair' },
{ label: 'Aviation & Aerospace', value: 'Aviation & Aerospace' },
{ label: 'Banking', value: 'Banking' },
{ label: 'Batteries, Power Storage Equipment & Generators', value: 'Batteries, Power Storage Equipment & Generators' },
{ label: 'Beverage Manufacturing', value: 'Beverage Manufacturing' },
{ label: 'Bfsi', value: 'Bfsi' },
{ label: 'Biomass Electric Power Generation', value: 'Biomass Electric Power Generation' },
{ label: 'Biotechnology', value: 'Biotechnology' },
{ label: 'Blockchain Services', value: 'Blockchain Services' },
{ label: 'Boats & Submarines', value: 'Boats & Submarines' },
{ label: 'Book And Periodical Publishing', value: 'Book And Periodical Publishing' },
{ label: 'Broadcast Media', value: 'Broadcast Media' },
{ label: 'Brokerage', value: 'Brokerage' },
{ label: 'Building Construction', value: 'Building Construction' },
{ label: 'Building Materials', value: 'Building Materials' },
{ label: 'Business Consulting And Services', value: 'Business Consulting And Services' },
{ label: 'Business Intelligence', value: 'Business Intelligence' },
{ label: 'Business Services', value: 'Business Services' },
{ label: 'Business Supplies & Equipment', value: 'Business Supplies & Equipment' },
{ label: 'Capital Markets', value: 'Capital Markets' },
{ label: 'Chambers Of Commerce', value: 'Chambers Of Commerce' },
{ label: 'Charitable Organizations & Foundations', value: 'Charitable Organizations & Foundations' },
{ label: 'Chemicals', value: 'Chemicals' },
{ label: 'Child Day Care Services', value: 'Child Day Care Services' },
{ label: 'Chris Shepherd Post Holdings', value: 'Chris Shepherd Post Holdings' },
{ label: 'Cities, Towns & Municipalities', value: 'Cities, Towns & Municipalities' },
{ label: 'Civic & Social Organization', value: 'Civic & Social Organization' },
{ label: 'Civil Engineering', value: 'Civil Engineering' },
{ label: 'Clay And Refractory Products Manufacturing', value: 'Clay And Refractory Products Manufacturing' },
{ label: 'Climate Technology Product Manufacturing', value: 'Climate Technology Product Manufacturing' },
{ label: 'Coal Mining', value: 'Coal Mining' },
{ label: 'Colleges & Universities', value: 'Colleges & Universities' },
{ label: 'Commercial & Residential Construction', value: 'Commercial & Residential Construction' },
{ label: 'Commercial And Industrial Machinery Maintenance', value: 'Commercial And Industrial Machinery Maintenance' },
{ label: 'Commercial Printing', value: 'Commercial Printing' },
{ label: 'Commercial Real Estate', value: 'Commercial Real Estate' },
{ label: 'Communications Equipment Manufacturing', value: 'Communications Equipment Manufacturing' },
{ label: 'Community Development And Urban Planning', value: 'Community Development And Urban Planning' },
{ label: 'Community Services', value: 'Community Services' },
{ label: 'Computer & Network Security', value: 'Computer & Network Security' },
{ label: 'Computer Equipment & Peripherals', value: 'Computer Equipment & Peripherals' },
{ label: 'Computer Games', value: 'Computer Games' },
{ label: 'Computer Hardware', value: 'Computer Hardware' },
{ label: 'Computer Hardware Manufacturing', value: 'Computer Hardware Manufacturing' },
{ label: 'Computer Hardware/Software/Technology', value: 'Computer Hardware/Software/Technology' },
{ label: 'Computer Networking', value: 'Computer Networking' },
{ label: 'Computer Services', value: 'Computer Services' },
{ label: 'Computer Software', value: 'Computer Software' },
{ label: 'Computer Storage Equipment', value: 'Computer Storage Equipment' },
{ label: 'Computers And Electronics', value: 'Computers And Electronics' },
{ label: 'Construction', value: 'Construction' },
{ label: 'Construction And Real Estate', value: 'Construction And Real Estate' },
{ label: 'Consumer Electronics', value: 'Consumer Electronics' },
{ label: 'Consumer Goods', value: 'Consumer Goods' },
{ label: 'Consumer Services', value: 'Consumer Services' },
{ label: 'Content & Collaboration Software', value: 'Content & Collaboration Software' },
{ label: 'Cosmetics', value: 'Cosmetics' },
{ label: 'Credit Cards & Transaction Processing', value: 'Credit Cards & Transaction Processing' },
{ label: 'Crops', value: 'Crops' },
{ label: 'Cybersecurity', value: 'Cybersecurity' },
{ label: 'Dairy Product Manufacturing', value: 'Dairy Product Manufacturing' },
{ label: 'Data Analytics', value: 'Data Analytics' },
{ label: 'Database & File Management Software', value: 'Database & File Management Software' },
{ label: 'Debt Collection', value: 'Debt Collection' },
{ label: 'Defense & Space', value: 'Defense & Space' },
{ label: 'Design Services', value: 'Design Services' },
{ label: 'Digital Media', value: 'Digital Media' },
{ label: 'Drug Manufacturing & Research', value: 'Drug Manufacturing & Research' },
{ label: 'Drug Stores & Pharmacies', value: 'Drug Stores & Pharmacies' },
{ label: 'Ecommerce', value: 'Ecommerce' },
{ label: 'Education', value: 'Education' },
{ label: 'Education Administration Programs', value: 'Education Administration Programs' },
{ label: 'Education K-12', value: 'Education K-12' },
{ label: 'Education Management', value: 'Education Management' },
{ label: 'Educational Institution', value: 'Educational Institution' },
{ label: 'E-Learning', value: 'E-Learning' },
{ label: 'Electrical & Electronic Manufacturing', value: 'Electrical & Electronic Manufacturing' },
{ label: 'Electricity, Oil & Gas', value: 'Electricity, Oil & Gas' },
{ label: 'Embedded Software Products', value: 'Embedded Software Products' },
{ label: 'Emergency Medical Transportation & Services', value: 'Emergency Medical Transportation & Services' },
{ label: 'Energy, Utilities & Mining', value: 'Energy, Utilities & Mining' },
{ label: 'Energy, Utilities & Waste', value: 'Energy, Utilities & Waste' },
{ label: 'Engineering Services', value: 'Engineering Services' },
{ label: 'Engineering Software', value: 'Engineering Software' },
{ label: 'Enterprise Resource Planning', value: 'Enterprise Resource Planning' },
{ label: 'Entertainment', value: 'Entertainment' },
{ label: 'Environmental Services', value: 'Environmental Services' },
{ label: 'Events Services', value: 'Events Services' },
{ label: 'Executive Offices', value: 'Executive Offices' },
{ label: 'Fabricated Metal Products', value: 'Fabricated Metal Products' },
{ label: 'Facilities Management & Commercial Cleaning', value: 'Facilities Management & Commercial Cleaning' },
{ label: 'Facilities Services', value: 'Facilities Services' },
{ label: 'Farming', value: 'Farming' },
{ label: 'Film/Video Production & Services', value: 'Film/Video Production & Services' },
{ label: 'Finance', value: 'Finance' },
{ label: 'Financial Services', value: 'Financial Services' },
{ label: 'Financial, Legal & Hr Software', value: 'Financial, Legal & Hr Software' },
{ label: 'Fine Art', value: 'Fine Art' },
{ label: 'Fisheries', value: 'Fisheries' },
{ label: 'Fitness & Dance Facilities', value: 'Fitness & Dance Facilities' },
{ label: 'Food & Beverages', value: 'Food & Beverages' },
{ label: 'Food Manufacturing', value: 'Food Manufacturing' },
{ label: 'Food Production', value: 'Food Production' },
{ label: 'Freight & Logistics Services', value: 'Freight & Logistics Services' },
{ label: 'Fuel Cell Manufacturing', value: 'Fuel Cell Manufacturing' },
{ label: 'Fundraising', value: 'Fundraising' },
{ label: 'Funeral Homes & Funeral Related Services', value: 'Funeral Homes & Funeral Related Services' },
{ label: 'Furniture And Home Furnishings Manufacturing', value: 'Furniture And Home Furnishings Manufacturing' },
{ label: 'Furniture - Retail', value: 'Furniture - Retail' },
{ label: 'Gambling & Casinos', value: 'Gambling & Casinos' },
{ label: 'Gas Stations, Convenience & Liquor Stores', value: 'Gas Stations, Convenience & Liquor Stores' },
{ label: 'Glass Product Manufacturing', value: 'Glass Product Manufacturing' },
{ label: 'Glass, Ceramics And Concrete Manufacturing', value: 'Glass, Ceramics And Concrete Manufacturing' },
{ label: 'Government', value: 'Government' },
{ label: 'Government Agency', value: 'Government Agency' },
{ label: 'Government Relations Services', value: 'Government Relations Services' },
{ label: 'Graphic Design', value: 'Graphic Design' },
{ label: 'Grocery Retail', value: 'Grocery Retail' },
{ label: 'Hand, Power And Lawn-Care Tools', value: 'Hand, Power And Lawn-Care Tools' },
{ label: 'Hardware Supplier For Retail', value: 'Hardware Supplier For Retail' },
{ label: 'Healthcare', value: 'Healthcare' },
{ label: 'Health, Wellness & Fitness', value: 'Health, Wellness & Fitness' },
{ label: 'Healthcare & Medical', value: 'Healthcare & Medical' },
{ label: 'Healthcare Software', value: 'Healthcare Software' },
{ label: 'Higher Education', value: 'Higher Education' },
{ label: 'Holding Companies & Conglomerates', value: 'Holding Companies & Conglomerates' },
{ label: 'Home Improvement & Hardware Retail', value: 'Home Improvement & Hardware Retail' },
{ label: 'Horticulture', value: 'Horticulture' },
{ label: 'Hospital & Health Care', value: 'Hospital & Health Care' },
{ label: 'Hospitality & Recreation', value: 'Hospitality & Recreation' },
{ label: 'Household Goods', value: 'Household Goods' },
{ label: 'Human Resources Services', value: 'Human Resources Services' },
{ label: 'Hvac And Refrigeration Equipment Manufacturing', value: 'Hvac And Refrigeration Equipment Manufacturing' },
{ label: 'Import & Export', value: 'Import & Export' },
{ label: 'Individual & Family Services', value: 'Individual & Family Services' },
{ label: 'Industrial Automation', value: 'Industrial Automation' },
{ label: 'Industrial Machinery & Equipment', value: 'Industrial Machinery & Equipment' },
{ label: 'Industrial Machinery Manufacturing', value: 'Industrial Machinery Manufacturing' },
{ label: 'Information Collection & Delivery', value: 'Information Collection & Delivery' },
{ label: 'Information Services', value: 'Information Services' },
{ label: 'Information Technology & Services', value: 'Information Technology & Services' },
{ label: 'Insurance', value: 'Insurance' },
{ label: 'Interior Design', value: 'Interior Design' },
{ label: 'International Affairs', value: 'International Affairs' },
{ label: 'International Trade & Development', value: 'International Trade & Development' },
{ label: 'Internet Service Providers', value: 'Internet Service Providers' },
{ label: 'Internet Publishing', value: 'Internet Publishing' },
{ label: 'Investment Banking', value: 'Investment Banking' },
{ label: 'Investment Management', value: 'Investment Management' },
{ label: 'It Services And It Consulting', value: 'It Services And It Consulting' },
{ label: 'Jewelry & Watch Retail', value: 'Jewelry & Watch Retail' },
{ label: 'Judiciary', value: 'Judiciary' },
{ label: 'Law Enforcement', value: 'Law Enforcement' },
{ label: 'Law Firms & Legal Services', value: 'Law Firms & Legal Services' },
{ label: 'Law Practice', value: 'Law Practice' },
{ label: 'Leasing Real Estate', value: 'Leasing Real Estate' },
{ label: 'Legislative Offices', value: 'Legislative Offices' },
{ label: 'Leisure, Travel & Tourism', value: 'Leisure, Travel & Tourism' },
{ label: 'Libraries', value: 'Libraries' },
{ label: 'Loan Agency', value: 'Loan Agency' },
{ label: 'Lodging & Resorts', value: 'Lodging & Resorts' },
{ label: 'Logistics & Supply Chain', value: 'Logistics & Supply Chain' },
{ label: 'Luxury Goods & Jewelry', value: 'Luxury Goods & Jewelry' },
{ label: 'Machinery', value: 'Machinery' },
{ label: 'Machinery Manufacturing', value: 'Machinery Manufacturing' },
{ label: 'Magnetic And Optical Media Manufacturing', value: 'Magnetic And Optical Media Manufacturing' },
{ label: 'Management Consulting', value: 'Management Consulting' },
{ label: 'Manufacturing', value: 'Manufacturing' },
{ label: 'Manufacturing - Consumer Goods', value: 'Manufacturing - Consumer Goods' },
{ label: 'Manufacturing - Durables', value: 'Manufacturing - Durables' },
{ label: 'Manufacturing - Non-Durables', value: 'Manufacturing - Non-Durables' },
{ label: 'Manufacturing / Industrial Automation', value: 'Manufacturing / Industrial Automation' },
{ label: 'Manufacturing / Medical Devices & Equipment', value: 'Manufacturing / Medical Devices & Equipment' },
{ label: 'Manufacturing, Construction & Building Materials', value: 'Manufacturing, Construction & Building Materials' },
{ label: 'Marine Shipping & Transportation', value: 'Marine Shipping & Transportation' },
{ label: 'Maritime Transportation', value: 'Maritime Transportation' },
{ label: 'Market Research', value: 'Market Research' },
{ label: 'Measuring And Control Instrument Manufacturing', value: 'Measuring And Control Instrument Manufacturing' },
{ label: 'Mechanical Or Industrial Engineering', value: 'Mechanical Or Industrial Engineering' },
{ label: 'Media & Entertainment', value: 'Media & Entertainment' },
{ label: 'Media & Internet', value: 'Media & Internet' },
{ label: 'Media Production', value: 'Media Production' },
{ label: 'Medical And Diagnostic Laboratories', value: 'Medical And Diagnostic Laboratories' },
{ label: 'Medical Devices', value: 'Medical Devices' },
{ label: 'Medical Equipment Manufacturing', value: 'Medical Equipment Manufacturing' },
{ label: 'Medical Practice', value: 'Medical Practice' },
{ label: 'Medical Testing & Clinical Laboratories', value: 'Medical Testing & Clinical Laboratories' },
{ label: 'Membership Organizations', value: 'Membership Organizations' },
{ label: 'Mental Health Care', value: 'Mental Health Care' },
{ label: 'Metal Ore Mining', value: 'Metal Ore Mining' },
{ label: 'Metal Treatments', value: 'Metal Treatments' },
{ label: 'Metals & Minerals', value: 'Metals & Minerals' },
{ label: 'Metals & Mining', value: 'Metals & Mining' },
{ label: 'Miscellaneous Building Materials', value: 'Miscellaneous Building Materials' },
{ label: 'Mobile Games', value: 'Mobile Games' },
{ label: 'Motion Pictures & Film', value: 'Motion Pictures & Film' },
{ label: 'Motor Vehicle Dealers', value: 'Motor Vehicle Dealers' },
{ label: 'Motor Vehicle Manufacturing', value: 'Motor Vehicle Manufacturing' },
{ label: 'Motor Vehicle Parts', value: 'Motor Vehicle Parts' },
{ label: 'Motor Vehicles', value: 'Motor Vehicles' },
{ label: 'Movies And Sound Recording', value: 'Movies And Sound Recording' },
{ label: 'Multimedia & Graphic Design', value: 'Multimedia & Graphic Design' },
{ label: 'Multimedia, Games And Graphics Software', value: 'Multimedia, Games And Graphics Software' },
{ label: 'Museums & Institutions', value: 'Museums & Institutions' },
{ label: 'Music', value: 'Music' },
{ label: 'Music & Music Related Services', value: 'Music & Music Related Services' },
{ label: 'Musicians', value: 'Musicians' },
{ label: 'Nanotechnology', value: 'Nanotechnology' },
{ label: 'Networking Software', value: 'Networking Software' },
{ label: 'Newspaper Publishing', value: 'Newspaper Publishing' },
{ label: 'Non Profit Organizations', value: 'Non Profit Organizations' },
{ label: 'Office Products Retail & Distribution', value: 'Office Products Retail & Distribution' },
{ label: 'Oil & Energy', value: 'Oil & Energy' },
{ label: 'Online Audio And Video Media', value: 'Online Audio And Video Media' },
{ label: 'Online Media', value: 'Online Media' },
{ label: 'Organizations', value: 'Organizations' },
{ label: 'Other', value: 'Other' },
{ label: 'Other Information Services', value: 'Other Information Services' },
{ label: 'Other Rental Stores (Furniture, A/V, Construction & Industrial Equipment)', value: 'Other Rental Stores (Furniture, A/V, Construction & Industrial Equipment)' },
{ label: 'Outsourcing And Offshoring Consulting', value: 'Outsourcing And Offshoring Consulting' },
{ label: 'Packaging & Containers', value: 'Packaging & Containers' },
{ label: 'Paint, Coating, And Adhesive Manufacturing', value: 'Paint, Coating, And Adhesive Manufacturing' },
{ label: 'Paper & Forest Products', value: 'Paper & Forest Products' },
{ label: 'Performing Arts', value: 'Performing Arts' },
{ label: 'Periodical Publishing', value: 'Periodical Publishing' },
{ label: 'Personal Care Product Manufacturing', value: 'Personal Care Product Manufacturing' },
{ label: 'Personal Computers & Peripherals', value: 'Personal Computers & Peripherals' },
{ label: 'Pet Services', value: 'Pet Services' },
{ label: 'Pharmaceutical Manufacturing', value: 'Pharmaceutical Manufacturing' },
{ label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
{ label: 'Philanthropic Fundraising Services', value: 'Philanthropic Fundraising Services' },
{ label: 'Philanthropy', value: 'Philanthropy' },
{ label: 'Photography', value: 'Photography' },
{ label: 'Photography Studio', value: 'Photography Studio' },
{ label: 'Physical, Occupational And Speech Therapists', value: 'Physical, Occupational And Speech Therapists' },
{ label: 'Plastics', value: 'Plastics' },
{ label: 'Plastics And Rubber Product Manufacturing', value: 'Plastics And Rubber Product Manufacturing' },
{ label: 'Plastics Manufacturing', value: 'Plastics Manufacturing' },
{ label: 'Plumbing & Hvac Equipment', value: 'Plumbing & Hvac Equipment' },
{ label: 'Political Organizations', value: 'Political Organizations' },
{ label: 'Power Conversion & Protection Equipment', value: 'Power Conversion & Protection Equipment' },
{ label: 'Primary And Secondary Education', value: 'Primary And Secondary Education' },
{ label: 'Primary Metal Manufacturing', value: 'Primary Metal Manufacturing' },
{ label: 'Print & Digital Media', value: 'Print & Digital Media' },
{ label: 'Printing And Publishing', value: 'Printing And Publishing' },
{ label: 'Privately Held', value: 'Privately Held' },
{ label: 'Professional Services', value: 'Professional Services' },
{ label: 'Professional Training & Coaching', value: 'Professional Training & Coaching' },
{ label: 'Public Company', value: 'Public Company' },
{ label: 'Public Health', value: 'Public Health' },
{ label: 'Public Policy', value: 'Public Policy' },
{ label: 'Public Relations & Communications', value: 'Public Relations & Communications' },
{ label: 'Public Safety', value: 'Public Safety' },
{ label: 'Rail Transportation', value: 'Rail Transportation' },
{ label: 'Railroad Equipment Manufacturing', value: 'Railroad Equipment Manufacturing' },
{ label: 'Railroad Manufacturing', value: 'Railroad Manufacturing' },
{ label: 'Real Estate & Construction', value: 'Real Estate & Construction' },
{ label: 'Recreational Facilities & Services', value: 'Recreational Facilities & Services' },
{ label: 'Religious Institutions', value: 'Religious Institutions' },
{ label: 'Religious Organizations', value: 'Religious Organizations' },
{ label: 'Renewables & Environment', value: 'Renewables & Environment' },
{ label: 'Repair And Maintenance', value: 'Repair And Maintenance' },
{ label: 'Research Services', value: 'Research Services' },
{ label: 'Restaurants', value: 'Restaurants' },
{ label: 'Retail', value: 'Retail' },
{ label: 'Retail & Distribution', value: 'Retail & Distribution' },
{ label: 'Retail & Wholesale', value: 'Retail & Wholesale' },
{ label: 'Retail Apparel And Fashion', value: 'Retail Apparel And Fashion' },
{ label: 'Retail Appliances, Electrical, And Electronic Equipment', value: 'Retail Appliances, Electrical, And Electronic Equipment' },
{ label: 'Retail Art Dealers', value: 'Retail Art Dealers' },
{ label: 'Retail Art Supplies', value: 'Retail Art Supplies' },
{ label: 'Retail Books And Printed News', value: 'Retail Books And Printed News' },
{ label: 'Retail Florists', value: 'Retail Florists' },
{ label: 'Retail Furniture And Home Furnishings', value: 'Retail Furniture And Home Furnishings' },
{ label: 'Retail Gasoline', value: 'Retail Gasoline' },
{ label: 'Retail General', value: 'Retail General' },
{ label: 'Retail Automotive', value: 'Retail Automotive' },
{ label: 'Retail Groceries', value: 'Retail Groceries' },
{ label: 'Retail Health And Personal Care Products', value: 'Retail Health And Personal Care Products' },
{ label: 'Retail Luxury Goods And Jewelry', value: 'Retail Luxury Goods And Jewelry' },
{ label: 'Retail Motor Vehicles', value: 'Retail Motor Vehicles' },
{ label: 'Retail Office Equipment', value: 'Retail Office Equipment' },
{ label: 'Retail Office Supplies And Gifts', value: 'Retail Office Supplies And Gifts' },
{ label: 'Retail Recyclable Materials & Used Merchandise', value: 'Retail Recyclable Materials & Used Merchandise' },
{ label: 'Retail Consumer Services', value: 'Retail Consumer Services' },
{ label: 'Robotics Engineering', value: 'Robotics Engineering' },
{ label: 'Satellite Telecommunications', value: 'Satellite Telecommunications' },
{ label: 'Search Engines & Internet Portals', value: 'Search Engines & Internet Portals' },
{ label: 'Security & Investigations', value: 'Security & Investigations' },
{ label: 'Security Products & Services', value: 'Security Products & Services' },
{ label: 'Security Software', value: 'Security Software' },
{ label: 'Semiconductor', value: 'Semiconductor' },
{ label: 'Shipbuilding', value: 'Shipbuilding' },
{ label: 'Shuttles And Special Needs Transportation Services', value: 'Shuttles And Special Needs Transportation Services' },
{ label: 'Skiing Facilities', value: 'Skiing Facilities' },
{ label: 'Soap And Cleaning Product Manufacturing', value: 'Soap And Cleaning Product Manufacturing' },
{ label: 'Social Networking Platforms', value: 'Social Networking Platforms' },
{ label: 'Software & Internet', value: 'Software & Internet' },
{ label: 'Software & Technical Consulting', value: 'Software & Technical Consulting' },
{ label: 'Software Development & Design', value: 'Software Development & Design' },
{ label: 'Solar Energy', value: 'Solar Energy' },
{ label: 'Specialty Trade Contractors', value: 'Specialty Trade Contractors' },
{ label: 'Spectator Sports', value: 'Spectator Sports' },
{ label: 'Sporting & Recreational Equipment Retail', value: 'Sporting & Recreational Equipment Retail' },
{ label: 'Sporting Goods', value: 'Sporting Goods' },
{ label: 'Sports', value: 'Sports' },
{ label: 'Sports Teams & Leagues', value: 'Sports Teams & Leagues' },
{ label: 'Staffing & Recruiting', value: 'Staffing & Recruiting' },
{ label: 'Storage & System Management Software', value: 'Storage & System Management Software' },
{ label: 'Strategic Management Services', value: 'Strategic Management Services' },
{ label: 'Supermarkets', value: 'Supermarkets' },
{ label: 'Supply Chain Management (Scm) Software', value: 'Supply Chain Management (Scm) Software' },
{ label: 'Telecommunications', value: 'Telecommunications' },
{ label: 'Telecommunication Equipment', value: 'Telecommunication Equipment' },
{ label: 'Telephony & Wireless', value: 'Telephony & Wireless' },
{ label: 'Television Stations', value: 'Television Stations' },
{ label: 'Textile Manufacturing', value: 'Textile Manufacturing' },
{ label: 'Textiles', value: 'Textiles' },
{ label: 'Tobacco', value: 'Tobacco' },
{ label: 'Tobacco Manufacturing', value: 'Tobacco Manufacturing' },
{ label: 'Toys & Games', value: 'Toys & Games' },
{ label: 'Training', value: 'Training' },
{ label: 'Translation & Localization', value: 'Translation & Localization' },
{ label: 'Transportation', value: 'Transportation' },
{ label: 'Transportation & Distribution', value: 'Transportation & Distribution' },
{ label: 'Transportation And Storage', value: 'Transportation And Storage' },
{ label: 'Transportation Equipment Manufacturing', value: 'Transportation Equipment Manufacturing' },
{ label: 'Transportation, Logistics, Supply Chain And Storage', value: 'Transportation, Logistics, Supply Chain And Storage' },
{ label: 'Transportation/Trucking/Railroad', value: 'Transportation/Trucking/Railroad' },
{ label: 'Travel & Hospitality', value: 'Travel & Hospitality' },
{ label: 'Travel Agencies & Services', value: 'Travel Agencies & Services' },
{ label: 'Travel Arrangements', value: 'Travel Arrangements' },
{ label: 'Travel, Recreation, And Leisure', value: 'Travel, Recreation, And Leisure' },
{ label: 'Truck Transportation', value: 'Truck Transportation' },
{ label: 'Trucking, Moving & Storage', value: 'Trucking, Moving & Storage' },
{ label: 'Turned Products And Fastener Manufacturing', value: 'Turned Products And Fastener Manufacturing' },
{ label: 'Urban Transit Services', value: 'Urban Transit Services' },
{ label: 'Utilities', value: 'Utilities' },
{ label: 'Vehicle Repair And Maintenance', value: 'Vehicle Repair And Maintenance' },
{ label: 'Venture Capital & Private Equity', value: 'Venture Capital & Private Equity' },
{ label: 'Veterinary Services', value: 'Veterinary Services' },
{ label: 'Warehousing And Storage', value: 'Warehousing And Storage' },
{ label: 'Waste Collection', value: 'Waste Collection' },
{ label: 'Wellness And Fitness Services', value: 'Wellness And Fitness Services' },
{ label: 'Wholesale', value: 'Wholesale' },
{ label: 'Wholesale & Distribution', value: 'Wholesale & Distribution' },
{ label: 'Wholesale Alcoholic Beverages', value: 'Wholesale Alcoholic Beverages' },
{ label: 'Wholesale Apparel And Sewing Supplies', value: 'Wholesale Apparel And Sewing Supplies' },
{ label: 'Wholesale Appliances, Electrical, And Electronics', value: 'Wholesale Appliances, Electrical, And Electronics' },
{ label: 'Wholesale Building Materials', value: 'Wholesale Building Materials' },
{ label: 'Wholesale Chemical And Allied Products', value: 'Wholesale Chemical And Allied Products' },
{ label: 'Wholesale Drugs And Sundries', value: 'Wholesale Drugs And Sundries' },
{ label: 'Wholesale Import And Export', value: 'Wholesale Import And Export' },
{ label: 'Wholesale Luxury Goods And Jewelry', value: 'Wholesale Luxury Goods And Jewelry' },
{ label: 'Wholesale Machinery', value: 'Wholesale Machinery' },
{ label: 'Wholesale Metals And Minerals', value: 'Wholesale Metals And Minerals' },
{ label: 'Wholesale Motor Vehicles And Parts', value: 'Wholesale Motor Vehicles And Parts' },
{ label: 'Wholesale Paper Products', value: 'Wholesale Paper Products' },
{ label: 'Wholesale Petroleum And Petroleum Products', value: 'Wholesale Petroleum And Petroleum Products' },
{ label: 'Wholesale Recyclable Materials', value: 'Wholesale Recyclable Materials' },
{ label: 'Wine & Spirits', value: 'Wine & Spirits' },
{ label: 'Wood Product Manufacturing', value: 'Wood Product Manufacturing' },
{ label: 'Writing And Editing', value: 'Writing And Editing' }, 
];

const SubIndustry = ({ selectedSubIndustry, handleSelectChange }) => {
  const animatedComponents = makeAnimated();

  return (
    <div>
      <Select
        isMulti
        value={selectedSubIndustry}
        onChange={handleSelectChange}
        options={subIndustries}
        placeholder="Select Sub Industry..."
        components={animatedComponents}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default SubIndustry;





// import React from 'react';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// const SubIndustry = ({ data, selectedSubIndustry, handleSelectChange }) => {
//   const subindustryNames = [...new Set(data.map((item) => item.SubIndustry))].map((subindustry) => ({
//     value: subindustry,
//     label: subindustry,
//   }));

//   // Sort the city names in alphabetical order
//   subindustryNames.sort((a, b) => a.label.localeCompare(b.label));

//   const animatedComponents = makeAnimated();   

//   return (
//     <div>
//       <Select
//         isMulti
//         value={selectedSubIndustry}
//         onChange={handleSelectChange}
//         options={subindustryNames}
//         placeholder="Select Sub Industry..."
//         components={animatedComponents}
//         closeMenuOnSelect={false}
//       />
//     </div>
//   );
// };

// export default SubIndustry;